export const streams4Yadkin = {
  type: "FeatureCollection",
  name: "Yadkin_UI_StreamsSO4+_Simple",
  crs: {
    type: "name",
    properties: { name: "urn:ogc:def:crs:EPSG::4269" },
  },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID: 265303.0,
        COMID: 9211286,
        FDATE: "2008/11/06 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: "1251573",
        GNIS_NAME: "Pee Dee River",
        LENGTHKM: 0.938,
        REACHCODE: "03040104000950",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049437,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010261868185620001,
        StreamLeve: 2,
        StreamOrde: 7,
        StreamCalc: 7,
        FromNode: 250044706.0,
        ToNode: 250044710.0,
        Hydroseq: 250005285.0,
        LevelPathI: 250002974.0,
        Pathlength: 323.184,
        TerminalPa: 250002851.0,
        ArbolateSu: 14281.698,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250002974.0,
        UpHydroseq: 250005296.0,
        DnLevelPat: 250002974.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005273.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3402,
        TotDASqKM: 16457.2506,
        DivDASqKM: 16453.9296,
        Tidal: 0,
        TOTMA: 0.025322323042000001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 5449.0,
        MAXELEVSMO: 5465.0,
        MINELEVSMO: 5465.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.93800002336502097,
        QA_MA: 7570.046,
        VA_MA: 1.40558,
        QC_MA: 7570.046,
        VC_MA: 1.40558,
        QE_MA: 7657.11,
        VE_MA: 1.4066,
        QA_01: 14350.898,
        VA_01: 1.84932,
        QC_01: 14350.898,
        VC_01: 1.84932,
        QE_01: 12587.259,
        VE_01: 1.7383,
        QA_02: 14865.047,
        VA_02: 1.8784,
        QC_02: 14865.047,
        VC_02: 1.8784,
        QE_02: 12779.037,
        VE_02: 1.74983,
        QA_03: 16262.306,
        VA_03: 1.95511,
        QC_03: 16262.306,
        VC_03: 1.95511,
        QE_03: 14390.126,
        VE_03: 1.84364,
        QA_04: 11359.058,
        VA_04: 1.66942,
        QC_04: 11359.058,
        VC_04: 1.66942,
        QE_04: 10490.756,
        VE_04: 1.60645,
        QA_05: 7363.748,
        VA_05: 1.3896,
        QC_05: 7363.748,
        VC_05: 1.3896,
        QE_05: 7513.254,
        VE_05: 1.39559,
        QA_06: 4334.913,
        VA_06: 1.12444,
        QC_06: 4334.913,
        VC_06: 1.12444,
        QE_06: 5712.828,
        VE_06: 1.24841,
        QA_07: 2816.713,
        VA_07: 0.95746,
        QC_07: 2816.713,
        VC_07: 0.95746,
        QE_07: 4249.606,
        VE_07: 1.11173,
        QA_08: 1909.024,
        VA_08: 0.83634,
        QC_08: 1909.024,
        VC_08: 0.83634,
        QE_08: 3133.663,
        VE_08: 0.99175,
        QA_09: 1718.643,
        VA_09: 0.80769,
        QC_09: 1718.643,
        VC_09: 0.80769,
        QE_09: 2672.431,
        VE_09: 0.93635,
        QA_10: 1990.308,
        VA_10: 0.84816,
        QC_10: 1990.308,
        VC_10: 0.84816,
        QE_10: 4157.273,
        VE_10: 1.10242,
        QA_11: 4426.163,
        VA_11: 1.13352,
        QC_11: 4426.163,
        VC_11: 1.13352,
        QE_11: 4777.046,
        VE_11: 1.16322,
        QA_12: 9492.71,
        VA_12: 1.54569,
        QC_12: 9492.71,
        VC_12: 1.54569,
        QE_12: 8467.673,
        VE_12: 1.46687,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [-80.342870985731679, 34.835315279261977, 0.0],
            [-80.337007185740845, 34.84087941258673, 0.0],
          ],
          [
            [-80.337007185740845, 34.84087941258673, 0.0],
            [-80.330921785750263, 34.843534279249241, 0.0],
          ],
          [
            [-80.330921785750263, 34.843534279249241, 0.0],
            [-80.328837319086915, 34.8452284125799, 0.0],
          ],
          [
            [-80.322387519096822, 34.85223447923579, 0.0],
            [-80.319271585768377, 34.858142279226513, 0.0],
            [-80.315379119107774, 34.861553279221312, 0.0],
          ],
          [
            [-80.328837319086915, 34.8452284125799, 0.0],
            [-80.323137919095757, 34.851799612569721, 0.0],
          ],
          [
            [-80.323137919095757, 34.851799612569721, 0.0],
            [-80.322387519096822, 34.85223447923579, 0.0],
          ],
          [
            [-80.3077547191196, 34.874581812534359, 0.0],
            [-80.300409785797683, 34.883189479187763, 0.0],
          ],
          [
            [-80.398798985644987, 34.874827279200701, 0.0],
            [-80.395325518983668, 34.876522879197978, 0.0],
            [-80.39118571899013, 34.883829879186749, 0.0],
            [-80.389017785660087, 34.884471479185606, 0.0],
            [-80.391742785655879, 34.890426279176495, 0.0],
          ],
          [
            [-80.300409785797683, 34.883189479187763, 0.0],
            [-80.296709919136731, 34.88653121251582, 0.0],
            [-80.29720718580262, 34.889692279177609, 0.0],
          ],
          [
            [-80.419831985612291, 34.864674812549708, 0.0],
            [-80.4178885189487, 34.868019479211171, 0.0],
            [-80.413719718955122, 34.867356679212321, 0.0],
            [-80.408940585629239, 34.868755212543419, 0.0],
          ],
          [
            [-80.315379119107774, 34.861553279221312, 0.0],
            [-80.310927319114739, 34.868926212543158, 0.0],
          ],
          [
            [-80.310927319114739, 34.868926212543158, 0.0],
            [-80.3077547191196, 34.874581812534359, 0.0],
          ],
          [
            [-80.408940585629239, 34.868755212543419, 0.0],
            [-80.406218785633371, 34.873268079203172, 0.0],
            [-80.401633318973836, 34.873085879203359, 0.0],
            [-80.398798985644987, 34.874827279200701, 0.0],
          ],
          [
            [-80.273487519172761, 34.897278812499167, 0.0],
            [-80.264335985853677, 34.901485212492616, 0.0],
          ],
          [
            [-80.383765785668288, 34.899474279162405, 0.0],
            [-80.380902519006042, 34.902703879157457, 0.0],
            [-80.378038985677108, 34.899863879161728, 0.0],
            [-80.374536319015931, 34.900551079160721, 0.0],
            [-80.377010319012015, 34.904742612487553, 0.0],
          ],
          [
            [-80.264335985853677, 34.901485212492616, 0.0],
            [-80.261686319191085, 34.907643879149816, 0.0],
          ],
          [
            [-80.377010319012015, 34.904742612487553, 0.0],
            [-80.375509119014453, 34.906941279150828, 0.0],
            [-80.368975319024514, 34.909735479146491, 0.0],
          ],
          [
            [-80.263829319187835, 34.926129412454429, 0.0],
            [-80.265966385851129, 34.929177679116265, 0.0],
            [-80.263543185854871, 34.931901012445451, 0.0],
            [-80.26501031918599, 34.936918279104304, 0.0],
          ],
          [
            [-80.26501031918599, 34.936918279104304, 0.0],
            [-80.264144319187324, 34.939620212433454, 0.0],
            [-80.255984985866633, 34.946506479089351, 0.0],
            [-80.254729119201897, 34.949299612418429, 0.0],
            [-80.250777385874756, 34.950555279083119, 0.0],
            [-80.25216598587258, 34.951885212414425, 0.0],
            [-80.250044119209065, 34.956967679073159, 0.0],
          ],
          [
            [-80.368975319024514, 34.909735479146491, 0.0],
            [-80.363275185700047, 34.911865079143126, 0.0],
            [-80.36188571903557, 34.908314879148691, 0.0],
            [-80.358131385708134, 34.912162279142649, 0.0],
            [-80.351986319050923, 34.913443879140686, 0.0],
          ],
          [
            [-80.261686319191085, 34.907643879149816, 0.0],
            [-80.264704319186421, 34.916716679135618, 0.0],
          ],
          [
            [-80.351986319050923, 34.913443879140686, 0.0],
            [-80.35095671905259, 34.916054679136778, 0.0],
            [-80.344144585729737, 34.915869879136949, 0.0],
          ],
          [
            [-80.264704319186421, 34.916716679135618, 0.0],
            [-80.263829319187835, 34.926129412454429, 0.0],
          ],
          [
            [-80.344144585729737, 34.915869879136949, 0.0],
            [-80.342336585732596, 34.917266612468097, 0.0],
            [-80.344505119062546, 34.918252012466667, 0.0],
            [-80.344559119062467, 34.922168612460496, 0.0],
            [-80.337523319073398, 34.923174479125635, 0.0],
            [-80.339911185736355, 34.931054079113437, 0.0],
            [-80.335547185743167, 34.926792679120013, 0.0],
            [-80.333544385746279, 34.927708079118474, 0.0],
            [-80.335240919076909, 34.927502479118914, 0.0],
            [-80.335155919077067, 34.930594679114165, 0.0],
          ],
          [
            [-80.293312119142058, 34.893171079172134, 0.0],
            [-80.28238698582561, 34.893873412504433, 0.0],
            [-80.280355785828817, 34.895543679168441, 0.0],
          ],
          [
            [-80.29720718580262, 34.889692279177609, 0.0],
            [-80.293312119142058, 34.893171079172134, 0.0],
          ],
          [
            [-80.391742785655879, 34.890426279176495, 0.0],
            [-80.390742185657473, 34.890815812509231, 0.0],
          ],
          [
            [-80.390742185657473, 34.890815812509231, 0.0],
            [-80.383515119002027, 34.891755612507723, 0.0],
            [-80.383765785668288, 34.899474279162405, 0.0],
          ],
          [
            [-80.280355785828817, 34.895543679168441, 0.0],
            [-80.273487519172761, 34.897278812499167, 0.0],
          ],
          [
            [-80.223939985916331, 34.970416479052233, 0.0],
            [-80.223578185916949, 34.970416812385622, 0.0],
          ],
          [
            [-80.231561719237845, 34.968944612387929, 0.0],
            [-80.223939985916331, 34.970416479052233, 0.0],
          ],
          [
            [-80.220467519255067, 34.975435012377829, 0.0],
            [-80.220523785921728, 34.975984612376976, 0.0],
          ],
          [
            [-80.223578185916949, 34.970416812385622, 0.0],
            [-80.220467519255067, 34.975435012377829, 0.0],
          ],
          [
            [-80.308729585784761, 34.971420679050766, 0.0],
            [-80.308477385785125, 34.973619212380754, 0.0],
            [-80.306003985789005, 34.970159479052654, 0.0],
            [-80.303611319125991, 34.970066412386075, 0.0],
            [-80.303880585792285, 34.979846479037747, 0.0],
            [-80.301711119128981, 34.978883212372523, 0.0],
            [-80.300297319131232, 34.973202212381238, 0.0],
          ],
          [
            [-80.220467519255067, 34.975435012377829, 0.0],
            [-80.220523785921728, 34.975984612376976, 0.0],
          ],
          [
            [-80.300297319131232, 34.973202212381238, 0.0],
            [-80.298126585801242, 34.973613212380769, 0.0],
            [-80.29920591913276, 34.979797812371146, 0.0],
            [-80.292587385809838, 34.975647879044175, 0.0],
            [-80.293220119142063, 34.982588212366807, 0.0],
            [-80.28759891915081, 34.982630012366712, 0.0],
            [-80.290406119146553, 34.985838612361647, 0.0],
          ],
          [
            [-80.220523785921728, 34.975984612376976, 0.0],
            [-80.219773985922814, 34.977382279041478, 0.0],
            [-80.221139319254007, 34.978984612372415, 0.0],
            [-80.219832719256033, 34.980176412370497, 0.0],
            [-80.215631919262592, 34.980958212369273, 0.0],
            [-80.216745919260859, 34.981988079034295, 0.0],
            [-80.215634119262575, 34.983179879032548, 0.0],
            [-80.212155985934658, 34.983525812365372, 0.0],
          ],
          [
            [-80.191878585966094, 34.995333012346975, 0.0],
            [-80.188179785971897, 34.999503212340471, 0.0],
          ],
          [
            [-79.890403319767358, 34.993641012349656, 0.0],
            [-79.879787986381984, 34.987686972570714, 0.0],
          ],
          [
            [-80.192627719298343, 34.992332279018342, 0.0],
            [-80.191904385966097, 34.992744812350963, 0.0],
          ],
          [
            [-80.192627719298343, 34.992332279018342, 0.0],
            [-80.191904385966097, 34.992744812350963, 0.0],
          ],
          [
            [-80.198834119288676, 34.992603812351206, 0.0],
            [-80.197527585957346, 34.994413879015042, 0.0],
          ],
          [
            [-80.191904385966097, 34.992744812350963, 0.0],
            [-80.190262785968571, 34.993249479016924, 0.0],
            [-80.191822385966248, 34.994714479014533, 0.0],
          ],
          [
            [-80.191822385966248, 34.994714479014533, 0.0],
            [-80.191878585966094, 34.995333012346975, 0.0],
          ],
          [
            [-80.191822385966248, 34.994714479014533, 0.0],
            [-80.191878585966094, 34.995333012346975, 0.0],
          ],
          [
            [-80.206929719276104, 34.989255079023053, 0.0],
            [-80.20567931927809, 34.991294212353182, 0.0],
          ],
          [
            [-80.206929719276104, 34.989255079023053, 0.0],
            [-80.198527719289132, 34.99226027901841, 0.0],
          ],
          [
            [-80.290406119146553, 34.985838612361647, 0.0],
            [-80.285200119154638, 34.987621279025575, 0.0],
          ],
          [
            [-80.212155985934658, 34.983525812365372, 0.0],
            [-80.212324185934449, 34.984739479030111, 0.0],
            [-80.209458185938786, 34.985062079029603, 0.0],
            [-80.209406519272306, 34.98911607902329, 0.0],
            [-80.20837511927391, 34.987353212359437, 0.0],
            [-80.206929719276104, 34.989255079023053, 0.0],
          ],
          [
            [-80.508995385473952, 34.981642879034837, 0.0],
            [-80.509678918806173, 34.990232479021529, 0.0],
          ],
          [
            [-80.20567931927809, 34.991294212353182, 0.0],
            [-80.198834119288676, 34.992603812351206, 0.0],
          ],
          [
            [-80.198527719289132, 34.99226027901841, 0.0],
            [-80.192627719298343, 34.992332279018342, 0.0],
          ],
          [
            [-80.198834119288676, 34.992603812351206, 0.0],
            [-80.198527719289132, 34.99226027901841, 0.0],
          ],
          [
            [-80.513082918800933, 34.96344121239639, 0.0],
            [-80.510909785470972, 34.965932479059234, 0.0],
          ],
          [
            [-80.250044119209065, 34.956967679073159, 0.0],
            [-80.247486919213202, 34.956951479073211, 0.0],
            [-80.245517319216162, 34.960847079067094, 0.0],
          ],
          [
            [-80.335155919077067, 34.930594679114165, 0.0],
            [-80.326952985756463, 34.928621879117202, 0.0],
            [-80.323279385762135, 34.932926279110404, 0.0],
            [-80.323329785762041, 34.940690812431797, 0.0],
            [-80.320492385766556, 34.941376612430645, 0.0],
            [-80.321965785764121, 34.942430812429052, 0.0],
            [-80.321852719097762, 34.945179279091462, 0.0],
            [-80.32060038576634, 34.94602627909012, 0.0],
            [-80.314371985776006, 34.943183079094524, 0.0],
            [-80.311672119113496, 34.94560947909082, 0.0],
            [-80.310528785782026, 34.948953012418883, 0.0],
            [-80.314836319108565, 34.954177279077442, 0.0],
            [-80.311497985780477, 34.954473279077092, 0.0],
            [-80.310108385782655, 34.952846412412953, 0.0],
            [-80.306462985788187, 34.954218612410841, 0.0],
            [-80.31213218577949, 34.961367679066313, 0.0],
            [-80.304092185791944, 34.961202879066548, 0.0],
            [-80.308735185784769, 34.964893079060971, 0.0],
          ],
          [
            [-80.308735185784769, 34.964893079060971, 0.0],
            [-80.30973338578309, 34.968901812387969, 0.0],
            [-80.308729585784761, 34.971420679050766, 0.0],
          ],
          [
            [-80.245517319216162, 34.960847079067094, 0.0],
            [-80.241741319222058, 34.966347412391883, 0.0],
            [-80.235040319232496, 34.969537079053737, 0.0],
            [-80.231561719237845, 34.968944612387929, 0.0],
          ],
          [
            [-80.510909785470972, 34.965932479059234, 0.0],
            [-80.50859258547456, 34.971450079050726, 0.0],
            [-80.510174118805423, 34.974658212378927, 0.0],
            [-80.508995385473952, 34.981642879034837, 0.0],
          ],
          [
            [-80.259347319194717, 35.010829278989661, 0.0],
            [-80.253554385870416, 35.012679012320064, 0.0],
            [-80.252153719205864, 35.018563612310857, 0.0],
            [-80.250121719209005, 35.018332478977925, 0.0],
            [-80.248775385877764, 35.01458187898379, 0.0],
            [-80.245824785882405, 35.014653612317034, 0.0],
            [-80.245776585882425, 35.01976087897566, 0.0],
          ],
          [
            [-79.892655186430545, 35.02424281230202, 0.0],
            [-79.894399386427892, 35.01674061231364, 0.0],
            [-79.900647319751556, 35.008719078992897, 0.0],
            [-79.901299319750535, 35.005635678997635, 0.0],
            [-79.899455719753405, 35.003222612334696, 0.0],
          ],
          [
            [-80.185596585975929, 35.000956479004856, 0.0],
            [-80.181369185982419, 35.004920678998701, 0.0],
            [-80.17928158598562, 35.004944612332054, 0.0],
            [-80.179172385985908, 35.007578412327916, 0.0],
            [-80.176137719323947, 35.006640678996064, 0.0],
            [-80.170461785999294, 35.009895012324364, 0.0],
            [-80.170769985998959, 35.012460012320446, 0.0],
            [-80.165370119340594, 35.013148878985987, 0.0],
            [-80.160083319348757, 35.016265212314465, 0.0],
            [-80.153931786025055, 35.016060678981432, 0.0],
            [-80.153124919359641, 35.017297612312859, 0.0],
            [-80.151231386029281, 35.015969678981548, 0.0],
          ],
          [
            [-80.460093385549783, 35.048045412265026, 0.0],
            [-80.457949118886461, 35.048207212264913, 0.0],
            [-80.453181118893838, 35.04234721227408, 0.0],
            [-80.450312318898341, 35.041982612274637, 0.0],
            [-80.448030318901885, 35.043610212272029, 0.0],
          ],
          [
            [-79.886805119773044, 35.028891612294956, 0.0],
            [-79.892655186430545, 35.02424281230202, 0.0],
          ],
          [
            [-80.151231386029281, 35.015969678981548, 0.0],
            [-80.152318186027514, 35.019542212309375, 0.0],
            [-80.149618719365037, 35.022543012304709, 0.0],
            [-80.148671919366564, 35.021947678972197, 0.0],
            [-80.147086719368986, 35.028337812295661, 0.0],
            [-80.148590186033289, 35.02813127896269, 0.0],
            [-80.147588586034885, 35.031062878958153, 0.0],
            [-80.149566186031734, 35.033787878953945, 0.0],
          ],
          [
            [-80.245776585882425, 35.01976087897566, 0.0],
            [-80.242353519221126, 35.020634478974387, 0.0],
            [-80.236528919230182, 35.016334278980992, 0.0],
            [-80.233383519235019, 35.016589012313887, 0.0],
            [-80.230103785906806, 35.02064561230776, 0.0],
            [-80.230472385906239, 35.02584427896636, 0.0],
            [-80.2231267859176, 35.029926878959884, 0.0],
            [-80.217593319259549, 35.03634401228328, 0.0],
            [-80.219655719256309, 35.038037078947411, 0.0],
          ],
          [
            [-80.477690518855866, 35.002293012336111, 0.0],
            [-80.474020785528239, 35.005892012330492, 0.0],
            [-80.474112518861375, 35.012327878987207, 0.0],
            [-80.470730385533273, 35.023416012303414, 0.0],
            [-80.471937785531395, 35.031454212290839, 0.0],
            [-80.469662518868233, 35.038052212280718, 0.0],
          ],
          [
            [-80.149566186031734, 35.033787878953945, 0.0],
            [-80.147589919368158, 35.035735012284249, 0.0],
            [-80.141018319378304, 35.035415412284806, 0.0],
          ],
          [
            [-80.141018319378304, 35.035415412284806, 0.0],
            [-80.141157319378237, 35.03406407895352, 0.0],
            [-80.138595719382124, 35.034820212285638, 0.0],
            [-80.137593586050343, 35.036629612282923, 0.0],
          ],
          [
            [-80.141018319378304, 35.035415412284806, 0.0],
            [-80.139626319380454, 35.036583612282868, 0.0],
          ],
          [
            [-80.137593586050343, 35.036629612282923, 0.0],
            [-80.139626319380454, 35.036583612282868, 0.0],
          ],
          [
            [-80.469662518868233, 35.038052212280718, 0.0],
            [-80.467246318872071, 35.044009012271431, 0.0],
            [-80.465017385542183, 35.043048678939442, 0.0],
            [-80.463731518877466, 35.038514812279914, 0.0],
            [-80.460195785549672, 35.039456478945112, 0.0],
            [-80.460093385549783, 35.048045412265026, 0.0],
          ],
          [
            [-80.139626319380454, 35.036583612282868, 0.0],
            [-80.139153119381263, 35.037591278948014, 0.0],
          ],
          [
            [-80.139626319380454, 35.036583612282868, 0.0],
            [-80.139153119381263, 35.037591278948014, 0.0],
          ],
          [
            [-79.872885986461313, 35.054152278922402, 0.0],
            [-79.877481119787433, 35.053648678923196, 0.0],
          ],
          [
            [-80.128934319397217, 35.056600612251884, 0.0],
            [-80.126316319401212, 35.055455478920237, 0.0],
            [-80.119436919411896, 35.056875212251498, 0.0],
            [-80.120160786077463, 35.059669278913816, 0.0],
            [-80.118823519412842, 35.062898478908664, 0.0],
            [-80.113503119421068, 35.065188078905237, 0.0],
          ],
          [
            [-80.06288998616634, 35.066408612236671, 0.0],
            [-80.061108519502454, 35.064964812238884, 0.0],
            [-80.058601319506352, 35.065352812238302, 0.0],
            [-80.058209986173665, 35.06686401223601, 0.0],
            [-80.054951586178674, 35.066243878903606, 0.0],
            [-80.053589386180818, 35.063197078908331, 0.0],
          ],
          [
            [-80.106986119431269, 35.060767078912079, 0.0],
            [-80.106623986098441, 35.060790012245377, 0.0],
          ],
          [
            [-80.106623986098441, 35.060790012245377, 0.0],
            [-80.10297511943736, 35.06099547891165, 0.0],
          ],
          [
            [-80.106986119431269, 35.060767078912079, 0.0],
            [-80.106623986098441, 35.060790012245377, 0.0],
          ],
          [
            [-80.113503119421068, 35.065188078905237, 0.0],
            [-80.111163919424769, 35.061500612244288, 0.0],
            [-80.106986119431269, 35.060767078912079, 0.0],
          ],
          [
            [-80.10297511943736, 35.06099547891165, 0.0],
            [-80.101860586105886, 35.06246101224275, 0.0],
            [-80.09851818611105, 35.062070812243348, 0.0],
            [-80.099268319443183, 35.067979812234171, 0.0],
          ],
          [
            [-80.095786319448564, 35.068047612234125, 0.0],
            [-80.094673319450351, 35.064726478905925, 0.0],
            [-80.085982786130444, 35.064517812239558, 0.0],
            [-80.087933186127486, 35.063373278907989, 0.0],
            [-80.079631919473684, 35.064675812239329, 0.0],
          ],
          [
            [-80.063391119498874, 35.066729478902744, 0.0],
            [-80.064255986164255, 35.06508101223875, 0.0],
            [-80.06288998616634, 35.066408612236671, 0.0],
          ],
          [
            [-80.099268319443183, 35.067979812234171, 0.0],
            [-80.095786319448564, 35.068047612234125, 0.0],
          ],
          [
            [-80.063391119498874, 35.066729478902744, 0.0],
            [-80.06288998616634, 35.066408612236671, 0.0],
          ],
          [
            [-80.079631919473684, 35.064675812239329, 0.0],
            [-80.079519386140532, 35.06669107890292, 0.0],
            [-80.077876186143044, 35.066163678903706, 0.0],
            [-80.077011786144453, 35.067560278901453, 0.0],
            [-80.073864986149374, 35.066299412236731, 0.0],
            [-80.074726919481293, 35.06870447889969, 0.0],
            [-80.069742919489045, 35.065770812237588, 0.0],
            [-80.065591186162123, 35.067555078901592, 0.0],
            [-80.066231119494489, 35.068448678900211, 0.0],
            [-80.063391119498874, 35.066729478902744, 0.0],
          ],
          [
            [-79.910353319736373, 35.069722412231442, 0.0],
            [-79.906666519742203, 35.064135612240136, 0.0],
            [-79.901633719749952, 35.061089278911595, 0.0],
          ],
          [
            [-80.041272786199841, 35.068135678900603, 0.0],
            [-80.039490786202691, 35.067561878901415, 0.0],
            [-80.03940551953616, 35.06902761223256, 0.0],
          ],
          [
            [-80.041272786199841, 35.068135678900603, 0.0],
            [-80.041076386200189, 35.069395212231939, 0.0],
            [-80.03940551953616, 35.06902761223256, 0.0],
          ],
          [
            [-79.874428386458931, 35.045076878936413, 0.0],
            [-79.874506786458767, 35.044706878937063, 0.0],
          ],
          [
            [-80.448030318901885, 35.043610212272029, 0.0],
            [-80.447366118902892, 35.048466078931199, 0.0],
            [-80.438178918917174, 35.052937478924207, 0.0],
          ],
          [
            [-79.883703919777872, 35.051693012259534, 0.0],
            [-79.874506786458767, 35.044706878937063, 0.0],
          ],
          [
            [-79.879848319783832, 35.052892812257539, 0.0],
            [-79.874428386458931, 35.045076878936413, 0.0],
          ],
          [
            [-79.874506786458767, 35.044706878937063, 0.0],
            [-79.873619586460109, 35.041854678941377, 0.0],
          ],
          [
            [-79.873619586460109, 35.041854678941377, 0.0],
            [-79.876287919789263, 35.035559678951131, 0.0],
            [-79.886805119773044, 35.028891612294956, 0.0],
          ],
          [
            [-80.219655719256309, 35.038037078947411, 0.0],
            [-80.217013785927122, 35.041153812275866, 0.0],
          ],
          [
            [-80.139153119381263, 35.037591278948014, 0.0],
            [-80.139209386047867, 35.041713678941619, 0.0],
            [-80.136118719385991, 35.044439278937432, 0.0],
            [-80.136174986052595, 35.048951012263785, 0.0],
            [-80.131468519393195, 35.050233812261752, 0.0],
            [-80.13258258605822, 35.051516212259685, 0.0],
            [-80.131078719393884, 35.052936278924278, 0.0],
            [-80.132833386057769, 35.053371278923521, 0.0],
          ],
          [
            [-79.877202719787931, 35.052938678924306, 0.0],
            [-79.873674719793428, 35.047892212265367, 0.0],
            [-79.874428386458931, 35.045076878936413, 0.0],
          ],
          [
            [-79.877481119787433, 35.053648678923196, 0.0],
            [-79.879848319783832, 35.052892812257539, 0.0],
          ],
          [
            [-79.877481119787433, 35.053648678923196, 0.0],
            [-79.877202719787931, 35.052938678924306, 0.0],
          ],
          [
            [-80.132833386057769, 35.053371278923521, 0.0],
            [-80.129658319396071, 35.053600478923272, 0.0],
            [-80.128934319397217, 35.056600612251884, 0.0],
          ],
          [
            [-80.217013785927122, 35.041153812275866, 0.0],
            [-80.207935119274566, 35.040587812276669, 0.0],
            [-80.207326585942099, 35.044893812270004, 0.0],
            [-80.209865985938166, 35.049907678928946, 0.0],
            [-80.203067319282127, 35.046499678934197, 0.0],
            [-80.202989385948911, 35.052431478924973, 0.0],
            [-80.198645185955684, 35.053006612257377, 0.0],
            [-80.195529385960526, 35.057199612250997, 0.0],
            [-80.189513785969837, 35.058027412249658, 0.0],
          ],
          [
            [-79.901633719749952, 35.061089278911595, 0.0],
            [-79.883703919777872, 35.051693012259534, 0.0],
          ],
          [
            [-80.188179785971897, 34.999503212340471, 0.0],
            [-80.186570119307589, 35.000171279006111, 0.0],
          ],
          [
            [-80.482781318847913, 35.000157612339365, 0.0],
            [-80.481500918849974, 35.000158212339443, 0.0],
          ],
          [
            [-79.894866119760479, 35.000161679006055, 0.0],
            [-79.890403319767358, 34.993641012349656, 0.0],
          ],
          [
            [-80.509678918806173, 34.990232479021529, 0.0],
            [-80.508050718808704, 34.999850612339912, 0.0],
            [-80.504961585480089, 34.999481079007126, 0.0],
            [-80.503712785482094, 34.997052012344284, 0.0],
            [-80.497919118824484, 34.999251879007488, 0.0],
            [-80.495828918827669, 34.997055212344151, 0.0],
            [-80.491515918834409, 34.996807479011295, 0.0],
            [-80.486397985509029, 34.998736079008324, 0.0],
            [-80.486483385508791, 35.00015601233946, 0.0],
          ],
          [
            [-80.285200119154638, 34.987621279025575, 0.0],
            [-80.281163985827561, 34.988282412357989, 0.0],
            [-80.279547185830097, 34.991144079020216, 0.0],
            [-80.27962378582987, 34.99840481234213, 0.0],
            [-80.274085319171775, 34.998560479008574, 0.0],
            [-80.27322031917322, 35.000164812339449, 0.0],
          ],
          [
            [-80.486483385508791, 35.00015601233946, 0.0],
            [-80.486095385509373, 35.001460812337427, 0.0],
            [-80.482781318847913, 35.000157612339365, 0.0],
          ],
          [
            [-79.899455719753405, 35.003222612334696, 0.0],
            [-79.894866119760479, 35.000161679006055, 0.0],
          ],
          [
            [-80.481500918849974, 35.000158212339443, 0.0],
            [-80.477690518855866, 35.002293012336111, 0.0],
          ],
          [
            [-80.267601119181961, 35.000162879006155, 0.0],
            [-80.266543385850241, 35.000162479006121, 0.0],
          ],
          [
            [-80.186570119307589, 35.000171279006111, 0.0],
            [-80.185596585975929, 35.000956479004856, 0.0],
          ],
          [
            [-80.27322031917322, 35.000164812339449, 0.0],
            [-80.27291598584037, 35.001130412338, 0.0],
          ],
          [
            [-80.27291598584037, 35.001130412338, 0.0],
            [-80.270298585844444, 35.00183827900355, 0.0],
            [-80.267601119181961, 35.000162879006155, 0.0],
          ],
          [
            [-80.266543385850241, 35.000162479006121, 0.0],
            [-80.265758519184828, 35.004262012333129, 0.0],
            [-80.261637585857898, 35.00524321233155, 0.0],
            [-80.262522385856471, 35.009526678991676, 0.0],
            [-80.259347319194717, 35.010829278989661, 0.0],
          ],
          [
            [-79.835798519852233, 35.089687412200419, 0.0],
            [-79.834573786520764, 35.087740278870115, 0.0],
            [-79.840145786512153, 35.088200212202821, 0.0],
            [-79.842320519842076, 35.084834478874654, 0.0],
            [-79.845301986504126, 35.084079612209166, 0.0],
          ],
          [
            [-79.845301986504126, 35.084079612209166, 0.0],
            [-79.847530986500658, 35.083347412210401, 0.0],
            [-79.842769186508065, 35.078468212217899, 0.0],
            [-79.848703586498743, 35.077095678886621, 0.0],
          ],
          [
            [-80.398692785645153, 35.081260412213567, 0.0],
            [-80.397077318981019, 35.083482278876829, 0.0],
            [-80.393650118986272, 35.08279587887796, 0.0],
            [-80.394904985650896, 35.087216012204351, 0.0],
            [-80.390976785657074, 35.090812412198829, 0.0],
            [-80.387549585662441, 35.091614412197544, 0.0],
            [-80.386741985663662, 35.094385812193252, 0.0],
          ],
          [
            [-80.339946119069566, 35.114902412161314, 0.0],
            [-80.337214385740594, 35.115153478827608, 0.0],
            [-80.335595719076423, 35.119183878821275, 0.0],
          ],
          [
            [-79.952341786337968, 35.115795012160049, 0.0],
            [-79.948000786344721, 35.11756327882398, 0.0],
          ],
          [
            [-80.069735319489098, 35.11548407882708, 0.0],
            [-80.069584919489273, 35.11470221216166, 0.0],
          ],
          [
            [-80.335595719076423, 35.119183878821275, 0.0],
            [-80.33227771908156, 35.120854612152186, 0.0],
            [-80.327373919089098, 35.117577812157265, 0.0],
            [-80.320040385767243, 35.121353678818025, 0.0],
            [-80.313882519110052, 35.118373278822617, 0.0],
            [-80.311873585779892, 35.120777012152132, 0.0],
            [-80.315440119107734, 35.122771278815776, 0.0],
            [-80.311227919114231, 35.126387878810192, 0.0],
            [-80.301867519128791, 35.119855278820353, 0.0],
            [-80.297987319134791, 35.125670212144712, 0.0],
            [-80.292163385810454, 35.123490612148032, 0.0],
          ],
          [
            [-79.897241386423502, 35.122235078816686, 0.0],
            [-79.898605586421354, 35.115158478827709, 0.0],
          ],
          [
            [-79.948000786344721, 35.11756327882398, 0.0],
            [-79.937960386360203, 35.118972078821685, 0.0],
          ],
          [
            [-80.292163385810454, 35.123490612148032, 0.0],
            [-80.289570785814533, 35.123626212147713, 0.0],
            [-80.289007119148664, 35.129580478805224, 0.0],
            [-80.286215919152994, 35.132647278800505, 0.0],
            [-80.291982119143995, 35.13698001212714, 0.0],
          ],
          [
            [-80.070367586154703, 35.125214278812052, 0.0],
            [-80.068851786157097, 35.121200412151666, 0.0],
            [-80.069735319489098, 35.11548407882708, 0.0],
          ],
          [
            [-79.903235319747466, 35.126104212143844, 0.0],
            [-79.897241386423502, 35.122235078816686, 0.0],
          ],
          [
            [-80.070367586154703, 35.125214278812052, 0.0],
            [-80.069811919488984, 35.125910612144366, 0.0],
          ],
          [
            [-79.914723186396373, 35.134025078798345, 0.0],
            [-79.910260719736527, 35.128324012140467, 0.0],
            [-79.903235319747466, 35.126104212143844, 0.0],
          ],
          [
            [-79.915476786395118, 35.136108812128384, 0.0],
            [-79.914723186396373, 35.134025078798345, 0.0],
          ],
          [
            [-79.905234119744364, 35.098874812186295, 0.0],
            [-79.910694119735865, 35.094613812192904, 0.0],
          ],
          [
            [-79.902281386415666, 35.102768478846883, 0.0],
            [-79.905234119744364, 35.098874812186295, 0.0],
          ],
          [
            [-79.981137386293199, 35.098262878853859, 0.0],
            [-79.961115319657551, 35.112007678832583, 0.0],
          ],
          [
            [-80.02520878622488, 35.091115212198304, 0.0],
            [-80.015057986240606, 35.089342212201018, 0.0],
          ],
          [
            [-80.386741985663662, 35.094385812193252, 0.0],
            [-80.382227919003981, 35.099012478852615, 0.0],
            [-80.376487319012881, 35.100180678850904, 0.0],
            [-80.375985585680382, 35.104761412177027, 0.0],
            [-80.373142919018164, 35.105975278841811, 0.0],
          ],
          [
            [-80.044155786195461, 35.104117278844797, 0.0],
            [-80.02520878622488, 35.091115212198304, 0.0],
          ],
          [
            [-79.986242719618645, 35.088938478868386, 0.0],
            [-79.983251586289896, 35.09195401219705, 0.0],
            [-79.981137386293199, 35.098262878853859, 0.0],
          ],
          [
            [-79.910694119735865, 35.094613812192904, 0.0],
            [-79.911806586400871, 35.094040278860462, 0.0],
          ],
          [
            [-79.919850919721682, 35.104289812177797, 0.0],
            [-79.913667186397959, 35.098612878853316, 0.0],
            [-79.911806586400871, 35.094040278860462, 0.0],
          ],
          [
            [-80.015589719573143, 35.088886478868346, 0.0],
            [-80.015057986240606, 35.089342212201018, 0.0],
          ],
          [
            [-79.99977958626431, 35.085660478873365, 0.0],
            [-79.994377786272651, 35.085360612207296, 0.0],
            [-79.986242719618645, 35.088938478868386, 0.0],
          ],
          [
            [-80.015057986240606, 35.089342212201018, 0.0],
            [-79.99977958626431, 35.085660478873365, 0.0],
          ],
          [
            [-79.898966586420727, 35.10906681217034, 0.0],
            [-79.897544786422998, 35.107074812173437, 0.0],
            [-79.902281386415666, 35.102768478846883, 0.0],
          ],
          [
            [-80.052955719515012, 35.1070984121734, 0.0],
            [-80.044155786195461, 35.104117278844797, 0.0],
          ],
          [
            [-79.922650586384009, 35.109626278836231, 0.0],
            [-79.919850919721682, 35.104289812177797, 0.0],
          ],
          [
            [-80.373142919018164, 35.105975278841811, 0.0],
            [-80.370077119022881, 35.106547812174369, 0.0],
            [-80.368766985691536, 35.109135678837049, 0.0],
          ],
          [
            [-80.05430418617965, 35.107409812173046, 0.0],
            [-80.052955719515012, 35.1070984121734, 0.0],
          ],
          [
            [-80.055272186178115, 35.107288478839791, 0.0],
            [-80.05430418617965, 35.107409812173046, 0.0],
          ],
          [
            [-80.368766985691536, 35.109135678837049, 0.0],
            [-80.368209185692365, 35.112021412165802, 0.0],
            [-80.365979585695925, 35.1123420121653, 0.0],
            [-80.359374785706052, 35.108173012171733, 0.0],
            [-80.352908719049537, 35.108584212171138, 0.0],
            [-80.351876785717764, 35.110874278834331, 0.0],
            [-80.353548119048526, 35.114516212161902, 0.0],
            [-80.349450519054926, 35.115133812160934, 0.0],
            [-80.345549119060934, 35.113048678830921, 0.0],
            [-80.339946119069566, 35.114902412161314, 0.0],
          ],
          [
            [-80.069584919489273, 35.11470221216166, 0.0],
            [-80.066286119494407, 35.110952012167559, 0.0],
            [-80.055272186178115, 35.107288478839791, 0.0],
          ],
          [
            [-80.0578665861741, 35.110468612168233, 0.0],
            [-80.05430418617965, 35.107409812173046, 0.0],
          ],
          [
            [-79.898605586421354, 35.115158478827709, 0.0],
            [-79.900694986418102, 35.111310812166835, 0.0],
            [-79.898966586420727, 35.10906681217034, 0.0],
          ],
          [
            [-79.961115319657551, 35.112007678832583, 0.0],
            [-79.952341786337968, 35.115795012160049, 0.0],
          ],
          [
            [-79.937960386360203, 35.118972078821685, 0.0],
            [-79.925722386379277, 35.116650212158675, 0.0],
            [-79.922810319717144, 35.113329212163876, 0.0],
            [-79.922650586384009, 35.109626278836231, 0.0],
          ],
          [
            [-80.069811919488984, 35.125910612144366, 0.0],
            [-80.060062519504015, 35.117500678823944, 0.0],
            [-80.0578665861741, 35.110468612168233, 0.0],
          ],
          [
            [-80.455303918890536, 35.164442812084474, 0.0],
            [-80.453882318892795, 35.165337012083114, 0.0],
          ],
          [
            [-79.904275586412552, 35.157363612095423, 0.0],
            [-79.903968586412986, 35.156378878763576, 0.0],
            [-79.905780986410264, 35.155806012097969, 0.0],
          ],
          [
            [-79.904275586412552, 35.157363612095423, 0.0],
            [-79.906534119742332, 35.156744812096406, 0.0],
          ],
          [
            [-79.906534119742332, 35.156744812096406, 0.0],
            [-79.913031519732272, 35.156651478763195, 0.0],
          ],
          [
            [-79.905530786410623, 35.158645678760138, 0.0],
            [-79.904275586412552, 35.157363612095423, 0.0],
          ],
          [
            [-80.225422719247376, 35.156483012096771, 0.0],
            [-80.213262919266299, 35.155324078765261, 0.0],
            [-80.207268385942257, 35.156290012097145, 0.0],
          ],
          [
            [-80.08916878612547, 35.16024787875773, 0.0],
            [-80.086688319462723, 35.157384278761981, 0.0],
          ],
          [
            [-80.060501186170029, 35.160283278757618, 0.0],
            [-80.063207386165857, 35.158622878760241, 0.0],
          ],
          [
            [-80.207268385942257, 35.156290012097145, 0.0],
            [-80.206076385944016, 35.16375681208541, 0.0],
          ],
          [
            [-80.505249118813026, 35.185432812051943, 0.0],
            [-80.50355278548227, 35.181789612057628, 0.0],
          ],
          [
            [-80.061419186168564, 35.200124078695694, 0.0],
            [-80.060951586169381, 35.19485801203723, 0.0],
          ],
          [
            [-79.92676971971099, 35.190059212044616, 0.0],
            [-79.92506618638032, 35.186670478716565, 0.0],
          ],
          [
            [-79.929950919706073, 35.191615212042279, 0.0],
            [-79.92676971971099, 35.190059212044616, 0.0],
          ],
          [
            [-79.929950919706073, 35.191615212042279, 0.0],
            [-79.92676971971099, 35.190059212044616, 0.0],
          ],
          [
            [-80.292954185809265, 35.194534078704407, 0.0],
            [-80.288130785816691, 35.191645012042272, 0.0],
            [-80.28436378582262, 35.19216887870806, 0.0],
          ],
          [
            [-80.114428386086388, 35.194514212037745, 0.0],
            [-80.111554986090766, 35.193597812039172, 0.0],
          ],
          [
            [-80.12254731940709, 35.192339412041122, 0.0],
            [-80.122295986074164, 35.193392812039463, 0.0],
          ],
          [
            [-80.12254731940709, 35.192339412041122, 0.0],
            [-80.114428386086388, 35.194514212037745, 0.0],
          ],
          [
            [-80.122295986074164, 35.193392812039463, 0.0],
            [-80.114428386086388, 35.194514212037745, 0.0],
          ],
          [
            [-79.935007386364873, 35.202216012025872, 0.0],
            [-79.935869586363538, 35.198162212032059, 0.0],
            [-79.929950919706073, 35.191615212042279, 0.0],
          ],
          [
            [-80.206076385944016, 35.16375681208541, 0.0],
            [-80.202871585949026, 35.16655281208125, 0.0],
            [-80.197600585957275, 35.166762012080937, 0.0],
          ],
          [
            [-80.477856585522261, 35.178763078728934, 0.0],
            [-80.476593785524244, 35.172534612071843, 0.0],
            [-80.470172518867457, 35.167340812079999, 0.0],
            [-80.467634185538145, 35.167067878746991, 0.0],
            [-80.463704985544155, 35.170414612075149, 0.0],
            [-80.459627918883882, 35.165607878749313, 0.0],
            [-80.455303918890536, 35.164442812084474, 0.0],
          ],
          [
            [-80.412966585622996, 35.164028012085112, 0.0],
            [-80.408532185629838, 35.163960612085248, 0.0],
            [-80.403261985638039, 35.16723707874678, 0.0],
          ],
          [
            [-80.403261985638039, 35.16723707874678, 0.0],
            [-80.389316518992928, 35.165773612082489, 0.0],
          ],
          [
            [-80.453882318892795, 35.165337012083114, 0.0],
            [-80.447327785569598, 35.164974478750366, 0.0],
            [-80.439432118915249, 35.161429012089116, 0.0],
            [-80.435920785587314, 35.16511807875014, 0.0],
            [-80.429562718930583, 35.166518078747856, 0.0],
            [-80.418772185613932, 35.172797878738095, 0.0],
            [-80.412966585622996, 35.164028012085112, 0.0],
          ],
          [
            [-80.054469319512805, 35.181537412057878, 0.0],
            [-80.053152919514787, 35.178819678728871, 0.0],
            [-80.053405919514432, 35.171621612073409, 0.0],
            [-80.05656498617617, 35.164059478751767, 0.0],
            [-80.060501186170029, 35.160283278757618, 0.0],
          ],
          [
            [-80.360143119038298, 35.166712412080926, 0.0],
            [-80.355318319045807, 35.165589278749337, 0.0],
            [-80.351579919051574, 35.169367612076826, 0.0],
            [-80.346252719059862, 35.16922907874374, 0.0],
          ],
          [
            [-79.920261586387767, 35.173046212071199, 0.0],
            [-79.905530786410623, 35.158645678760138, 0.0],
          ],
          [
            [-80.099400719443054, 35.167831078745849, 0.0],
            [-80.08916878612547, 35.16024787875773, 0.0],
          ],
          [
            [-80.197600585957275, 35.166762012080937, 0.0],
            [-80.194977585961283, 35.165206078749975, 0.0],
            [-80.193689785963272, 35.158817212093084, 0.0],
            [-80.19050818596827, 35.155658412098035, 0.0],
          ],
          [
            [-80.242049185888277, 35.18097381205888, 0.0],
            [-80.225422719247376, 35.156483012096771, 0.0],
          ],
          [
            [-79.922243586384695, 35.176503612065744, 0.0],
            [-79.920261586387767, 35.173046212071199, 0.0],
          ],
          [
            [-80.50355278548227, 35.181789612057628, 0.0],
            [-80.493924918830601, 35.17929821206144, 0.0],
            [-80.4883129855059, 35.175547478733961, 0.0],
            [-80.482600785514933, 35.180293612059813, 0.0],
            [-80.477856585522261, 35.178763078728934, 0.0],
          ],
          [
            [-80.366612385694907, 35.176561078732391, 0.0],
            [-80.364521319031496, 35.170858212074563, 0.0],
            [-80.360143119038298, 35.166712412080926, 0.0],
          ],
          [
            [-80.389316518992928, 35.165773612082489, 0.0],
            [-80.380614919006518, 35.172118278739276, 0.0],
            [-80.376542585679488, 35.177729478730498, 0.0],
            [-80.371242585687753, 35.17981361206057, 0.0],
          ],
          [
            [-80.346252719059862, 35.16922907874374, 0.0],
            [-80.340588519068604, 35.173991212069609, 0.0],
            [-80.335623785742996, 35.173760612069941, 0.0],
            [-80.329039185753231, 35.176872878731899, 0.0],
            [-80.321371119098501, 35.172930612071241, 0.0],
          ],
          [
            [-80.321371119098501, 35.172930612071241, 0.0],
            [-80.321005919099036, 35.176801012065312, 0.0],
          ],
          [
            [-80.371242585687753, 35.17981361206057, 0.0],
            [-80.366612385694907, 35.176561078732391, 0.0],
          ],
          [
            [-80.370963585688173, 35.179905212060532, 0.0],
            [-80.366612385694907, 35.176561078732391, 0.0],
          ],
          [
            [-80.371242585687753, 35.17981361206057, 0.0],
            [-80.370963585688173, 35.179905212060532, 0.0],
          ],
          [
            [-79.92506618638032, 35.186670478716565, 0.0],
            [-79.925399786379671, 35.184471812053403, 0.0],
            [-79.928634786374744, 35.182959212055607, 0.0],
            [-79.922412786384427, 35.180098878726881, 0.0],
            [-79.922243586384695, 35.176503612065744, 0.0],
          ],
          [
            [-80.157803986019019, 35.172801078738189, 0.0],
            [-80.152923519359945, 35.174748878735215, 0.0],
            [-80.142379119376301, 35.168452412078182, 0.0],
            [-80.135211386054152, 35.168040812078914, 0.0],
            [-80.131585786059702, 35.173560212070186, 0.0],
            [-80.124529119403974, 35.17472821206843, 0.0],
            [-80.129298586063271, 35.185766812051327, 0.0],
            [-80.12254731940709, 35.192339412041122, 0.0],
          ],
          [
            [-80.111554986090766, 35.193597812039172, 0.0],
            [-80.108264186095937, 35.189017012046349, 0.0],
            [-80.107961186096361, 35.174062278736301, 0.0],
            [-80.099400719443054, 35.167831078745849, 0.0],
          ],
          [
            [-80.060951586169381, 35.19485801203723, 0.0],
            [-80.060411319503487, 35.188439878713893, 0.0],
            [-80.054469319512805, 35.181537412057878, 0.0],
          ],
          [
            [-80.241307985889364, 35.189654278711885, 0.0],
            [-80.239937385891437, 35.186999012049341, 0.0],
            [-80.242049185888277, 35.18097381205888, 0.0],
          ],
          [
            [-80.321005919099036, 35.176801012065312, 0.0],
            [-80.318213985770058, 35.180189212060043, 0.0],
            [-80.305743319122712, 35.182129212056964, 0.0],
            [-80.303729119125876, 35.188357478714011, 0.0],
            [-80.298760985800243, 35.190736078710302, 0.0],
            [-80.295632785805026, 35.19432981203812, 0.0],
            [-80.292954185809265, 35.194534078704407, 0.0],
          ],
          [
            [-80.08013911947296, 35.150603078772576, 0.0],
            [-80.076796586144781, 35.145082412114505, 0.0],
          ],
          [
            [-80.076211786145677, 35.143868412116376, 0.0],
            [-80.07522658614721, 35.144023212116224, 0.0],
          ],
          [
            [-80.076796586144781, 35.145082412114505, 0.0],
            [-80.076211786145677, 35.143868412116376, 0.0],
          ],
          [
            [-80.291982119143995, 35.13698001212714, 0.0],
            [-80.289247785815007, 35.139016478790495, 0.0],
            [-80.283810185823484, 35.139722412122921, 0.0],
            [-80.278730519164696, 35.144138612116024, 0.0],
            [-80.276305185835042, 35.143793212116577, 0.0],
          ],
          [
            [-80.07522658614721, 35.144023212116224, 0.0],
            [-80.075124786147398, 35.135507812129333, 0.0],
            [-80.070367586154703, 35.125214278812052, 0.0],
          ],
          [
            [-79.91615411972748, 35.155322412098656, 0.0],
            [-79.918635186390247, 35.153901678767511, 0.0],
            [-79.916235186394033, 35.149322412107949, 0.0],
            [-79.917096786392563, 35.143024278784424, 0.0],
            [-79.915476786395118, 35.136108812128384, 0.0],
          ],
          [
            [-80.08013911947296, 35.150603078772576, 0.0],
            [-80.07958331947367, 35.147373612110982, 0.0],
            [-80.076796586144781, 35.145082412114505, 0.0],
          ],
          [
            [-80.178959719319437, 35.149823812107115, 0.0],
            [-80.17435791932661, 35.147879012110195, 0.0],
            [-80.165769586006718, 35.147584212110644, 0.0],
            [-80.160109986015414, 35.149349212107836, 0.0],
          ],
          [
            [-80.182418519314183, 35.151654478770922, 0.0],
            [-80.178959719319437, 35.149823812107115, 0.0],
          ],
          [
            [-79.913031519732272, 35.156651478763195, 0.0],
            [-79.913141986398841, 35.154086612100514, 0.0],
            [-79.91615411972748, 35.155322412098656, 0.0],
          ],
          [
            [-79.913031519732272, 35.156651478763195, 0.0],
            [-79.915429719728536, 35.156811212096216, 0.0],
            [-79.91615411972748, 35.155322412098656, 0.0],
          ],
          [
            [-80.063207386165857, 35.158622878760241, 0.0],
            [-80.068797986157165, 35.155199412098796, 0.0],
            [-80.07522658614721, 35.144023212116224, 0.0],
          ],
          [
            [-80.276305185835042, 35.143793212116577, 0.0],
            [-80.2744083191713, 35.144501478782161, 0.0],
            [-80.274429519171292, 35.149860612107148, 0.0],
            [-80.266840185849787, 35.153312412101798, 0.0],
            [-80.263904785854322, 35.158691678760022, 0.0],
            [-80.258080785863342, 35.155617278764851, 0.0],
            [-80.256893785865259, 35.147417212110895, 0.0],
            [-80.2526817192051, 35.148466412109201, 0.0],
            [-80.246277585881614, 35.15351021210148, 0.0],
            [-80.244975185883732, 35.159259812092444, 0.0],
            [-80.240790785890169, 35.15837061209379, 0.0],
            [-80.237856719228034, 35.153999078767356, 0.0],
            [-80.23239891923663, 35.160049878757832, 0.0],
            [-80.225422719247376, 35.156483012096771, 0.0],
          ],
          [
            [-80.086688319462723, 35.157384278761981, 0.0],
            [-80.08013911947296, 35.150603078772576, 0.0],
          ],
          [
            [-80.19050818596827, 35.155658412098035, 0.0],
            [-80.187744919305942, 35.152155812103615, 0.0],
            [-80.182418519314183, 35.151654478770922, 0.0],
          ],
          [
            [-80.19050818596827, 35.155658412098035, 0.0],
            [-80.182418519314183, 35.151654478770922, 0.0],
          ],
          [
            [-80.160109986015414, 35.149349212107836, 0.0],
            [-80.160306386015122, 35.152372212103216, 0.0],
            [-80.166136919339465, 35.15786687876124, 0.0],
            [-80.167783919336898, 35.161530612088995, 0.0],
            [-80.165805186006651, 35.164439878751068, 0.0],
            [-80.15925231935006, 35.167258612080104, 0.0],
            [-80.157803986019019, 35.172801078738189, 0.0],
          ],
          [
            [-79.863468986475937, 35.072701412226934, 0.0],
            [-79.864444186474373, 35.071808278894821, 0.0],
            [-79.862941119810102, 35.066174612237035, 0.0],
            [-79.867286719803189, 35.06438881223977, 0.0],
            [-79.870935319797695, 35.065144678905199, 0.0],
            [-79.87015591979889, 35.061137012244785, 0.0],
            [-79.876506186455686, 35.060427278912584, 0.0],
            [-79.876366986455878, 35.058022612249658, 0.0],
            [-79.872857986461327, 35.055709612253111, 0.0],
            [-79.872885986461313, 35.054152278922402, 0.0],
          ],
          [
            [-80.189513785969837, 35.058027412249658, 0.0],
            [-80.193136385964124, 35.060109612246436, 0.0],
            [-80.196421719292459, 35.058321278915798, 0.0],
            [-80.200214319286488, 35.063517878907874, 0.0],
            [-80.196957585958216, 35.066199412236983, 0.0],
            [-80.190548585968259, 35.063363012241382, 0.0],
            [-80.187206985973376, 35.064784678905767, 0.0],
            [-80.187904985972239, 35.06693707890247, 0.0],
            [-80.194285785962393, 35.069109412232422, 0.0],
            [-80.195568785960404, 35.070849278896503, 0.0],
            [-80.194400185962195, 35.07249901222724, 0.0],
          ],
          [
            [-80.053589386180818, 35.063197078908331, 0.0],
            [-80.048684986188391, 35.065438412238109, 0.0],
            [-80.047288986190551, 35.068758078899691, 0.0],
            [-80.043777919529305, 35.069923812231252, 0.0],
            [-80.041272786199841, 35.068135678900603, 0.0],
          ],
          [
            [-79.910108119736776, 35.069993412231156, 0.0],
            [-79.910353319736373, 35.069722412231442, 0.0],
          ],
          [
            [-80.03940551953616, 35.06902761223256, 0.0],
            [-80.037512586205708, 35.0679956789009, 0.0],
            [-80.035170786209392, 35.069688612231516, 0.0],
            [-80.036869386206774, 35.070308278897187, 0.0],
          ],
          [
            [-79.910980786402092, 35.071199612229179, 0.0],
            [-79.910108119736776, 35.069993412231156, 0.0],
          ],
          [
            [-80.031381786215263, 35.070166478897363, 0.0],
            [-80.031186386215609, 35.070532878896984, 0.0],
          ],
          [
            [-80.031381786215263, 35.070166478897363, 0.0],
            [-80.031186386215609, 35.070532878896984, 0.0],
          ],
          [
            [-80.036756519540234, 35.071453078895502, 0.0],
            [-80.031381786215263, 35.070166478897363, 0.0],
          ],
          [
            [-80.438178918917174, 35.052937478924207, 0.0],
            [-80.43536751892151, 35.055206412254108, 0.0],
            [-80.43152358559405, 35.054979278920996, 0.0],
            [-80.431218985594683, 35.057567478917008, 0.0],
            [-80.427598785600253, 35.058668478915195, 0.0],
            [-80.423506785606548, 35.063182278908357, 0.0],
            [-80.422618585608006, 35.068564812233376, 0.0],
            [-80.418886318947045, 35.069734412231355, 0.0],
          ],
          [
            [-79.848703586498743, 35.077095678886621, 0.0],
            [-79.850987786495295, 35.077187812219961, 0.0],
            [-79.859736586481688, 35.071303878895719, 0.0],
            [-79.863468986475937, 35.072701412226934, 0.0],
          ],
          [
            [-80.418886318947045, 35.069734412231355, 0.0],
            [-80.412619918956864, 35.073286478892612, 0.0],
            [-80.408189385630408, 35.070722812230031, 0.0],
            [-80.405961118967127, 35.071502078895435, 0.0],
            [-80.398468918978836, 35.078077012218557, 0.0],
            [-80.398692785645153, 35.081260412213567, 0.0],
          ],
          [
            [-80.031186386215609, 35.070532878896984, 0.0],
            [-80.030042519550705, 35.072020478894615, 0.0],
            [-80.02689631955559, 35.070712612229897, 0.0],
          ],
          [
            [-80.036869386206774, 35.070308278897187, 0.0],
            [-80.038707719537229, 35.070378278897124, 0.0],
            [-80.037202319539574, 35.071453478895535, 0.0],
          ],
          [
            [-80.036869386206774, 35.070308278897187, 0.0],
            [-80.036756519540234, 35.071453078895502, 0.0],
          ],
          [
            [-80.037202319539574, 35.071453478895535, 0.0],
            [-80.036756519540234, 35.071453078895502, 0.0],
          ],
          [
            [-80.02689631955559, 35.070712612229897, 0.0],
            [-80.026420186222992, 35.072796278893463, 0.0],
            [-80.024165786226433, 35.071145478895971, 0.0],
            [-80.023410319560867, 35.073801278891892, 0.0],
            [-80.02638458622306, 35.079117078883542, 0.0],
            [-80.024876586225389, 35.081955612212482, 0.0],
            [-80.01702071957078, 35.081399078879997, 0.0],
            [-80.012901186243937, 35.078761612217477, 0.0],
            [-80.011143519580003, 35.080500412214803, 0.0],
            [-80.016124386238971, 35.084947878874459, 0.0],
            [-80.015589719573143, 35.088886478868346, 0.0],
          ],
          [
            [-79.911806586400871, 35.094040278860462, 0.0],
            [-79.912506719733074, 35.076620478887492, 0.0],
            [-79.910980786402092, 35.071199612229179, 0.0],
          ],
          [
            [-80.194400185962195, 35.07249901222724, 0.0],
            [-80.186935919307189, 35.074701412223646, 0.0],
            [-80.191340985966917, 35.079096478883628, 0.0],
            [-80.180477719317139, 35.082330812211865, 0.0],
            [-80.180618585983666, 35.084895812207947, 0.0],
            [-80.183405919312577, 35.086520678872091, 0.0],
            [-80.187891185972376, 35.085694012206659, 0.0],
            [-80.190121119302148, 35.086792212204955, 0.0],
            [-80.189484519303221, 35.092266078863247, 0.0],
            [-80.193194585964136, 35.097669078854835, 0.0],
            [-80.189462985969897, 35.100717012183452, 0.0],
            [-80.179234519319152, 35.0990270788526, 0.0],
            [-80.182107119314651, 35.102438212180743, 0.0],
            [-80.18244538598077, 35.108140678838538, 0.0],
            [-80.184508719310941, 35.109559612169733, 0.0],
            [-80.185072185976708, 35.118147678822936, 0.0],
            [-80.190318319301866, 35.125931678810844, 0.0],
            [-80.187924185972236, 35.130467478803837, 0.0],
            [-80.182852119313509, 35.132828878800296, 0.0],
            [-80.178959719319437, 35.149823812107115, 0.0],
          ],
          [
            [-80.26577831918479, 35.23619387863971, 0.0],
            [-80.265668385851654, 35.234911211975088, 0.0],
          ],
          [
            [-80.07704798614435, 35.225349611989884, 0.0],
            [-80.07311338615051, 35.219357078665951, 0.0],
          ],
          [
            [-80.265668385851654, 35.234911211975088, 0.0],
            [-80.25767538586399, 35.222559478660912, 0.0],
            [-80.254174185869488, 35.212502012009907, 0.0],
          ],
          [
            [-79.911411719734758, 35.273890211914477, 0.0],
            [-79.912779386399393, 35.272263878583715, 0.0],
          ],
          [
            [-79.883179986445327, 35.299612478541405, 0.0],
            [-79.886782986439698, 35.293543611884161, 0.0],
          ],
          [
            [-79.874072786459351, 35.307925411861788, 0.0],
            [-79.879939586450234, 35.299429411874826, 0.0],
            [-79.883179986445327, 35.299612478541405, 0.0],
          ],
          [
            [-79.891248386432721, 35.285266011896852, 0.0],
            [-79.893876319762057, 35.28495507856411, 0.0],
          ],
          [
            [-80.487820518840124, 35.281368878569708, 0.0],
            [-80.485523118843673, 35.276057611911199, 0.0],
            [-80.490847918835357, 35.269250411921746, 0.0],
            [-80.487440785507374, 35.269207811921774, 0.0],
          ],
          [
            [-79.89976971975284, 35.285938811895846, 0.0],
            [-79.901499119750213, 35.277763011908633, 0.0],
            [-79.90769758640721, 35.274074478580928, 0.0],
            [-79.911411719734758, 35.273890211914477, 0.0],
          ],
          [
            [-79.886782986439698, 35.293543611884161, 0.0],
            [-79.891248386432721, 35.285266011896852, 0.0],
          ],
          [
            [-79.893876319762057, 35.28495507856411, 0.0],
            [-79.895217319759979, 35.286305878561961, 0.0],
          ],
          [
            [-79.899616719753055, 35.285973811895758, 0.0],
            [-79.89976971975284, 35.285938811895846, 0.0],
          ],
          [
            [-79.895217319759979, 35.286305878561961, 0.0],
            [-79.895255919759791, 35.286296678561939, 0.0],
          ],
          [
            [-79.895255919759791, 35.286296678561939, 0.0],
            [-79.899616719753055, 35.285973811895758, 0.0],
          ],
          [
            [-80.085962119463829, 35.300657611873078, 0.0],
            [-80.094955319449866, 35.28856107855853, 0.0],
          ],
          [
            [-79.856466919820093, 35.326541611832852, 0.0],
            [-79.857976786484414, 35.32331287850451, 0.0],
          ],
          [
            [-80.60092151866445, 35.337643411815634, 0.0],
            [-80.599747918666367, 35.336543878484065, 0.0],
          ],
          [
            [-79.833361919855975, 35.367801078435377, 0.0],
            [-79.833866386521834, 35.365465478439148, 0.0],
            [-79.838394119848147, 35.368146278435006, 0.0],
          ],
          [
            [-79.841665719843093, 35.367025278436756, 0.0],
            [-79.844995186504548, 35.36136987844543, 0.0],
            [-79.851174319828317, 35.3586464117829, 0.0],
          ],
          [
            [-79.838394119848147, 35.368146278435006, 0.0],
            [-79.841665719843093, 35.367025278436756, 0.0],
          ],
          [
            [-79.838394119848147, 35.368146278435006, 0.0],
            [-79.841665719843093, 35.367025278436756, 0.0],
          ],
          [
            [-79.855649119821351, 35.351685678460569, 0.0],
            [-79.852800519825735, 35.344242678472142, 0.0],
          ],
          [
            [-79.852800519825735, 35.344242678472142, 0.0],
            [-79.854310586490101, 35.341792611809183, 0.0],
            [-79.852578719826113, 35.338632078480771, 0.0],
            [-79.852524519826261, 35.333662811821796, 0.0],
            [-79.856466919820093, 35.326541611832852, 0.0],
          ],
          [
            [-80.631220585284211, 35.358876811782636, 0.0],
            [-80.630018185286076, 35.35356321179097, 0.0],
            [-80.627166718623812, 35.350517278462291, 0.0],
            [-80.625852785292523, 35.352761678458819, 0.0],
            [-80.623252985296517, 35.352235078459671, 0.0],
            [-80.624594918627793, 35.349555411797212, 0.0],
            [-80.622162918631602, 35.349303411797507, 0.0],
            [-80.623504718629533, 35.347127611800829, 0.0],
            [-80.620905318633561, 35.343944078472475, 0.0],
            [-80.614951585309427, 35.343829211806053, 0.0],
            [-80.613134385312208, 35.345913078469493, 0.0],
            [-80.612128318647194, 35.344630478471345, 0.0],
            [-80.613134918645585, 35.342706811807716, 0.0],
            [-80.60092151866445, 35.337643411815634, 0.0],
          ],
          [
            [-80.53297271876994, 35.364590078440415, 0.0],
            [-80.529983718774702, 35.362274611777423, 0.0],
            [-80.534850185433754, 35.360698078446489, 0.0],
            [-80.533401118769234, 35.356620278452851, 0.0],
            [-80.534828985433762, 35.354697478455762, 0.0],
            [-80.530246785440909, 35.352587011792423, 0.0],
            [-80.527254185445543, 35.35375261179064, 0.0],
            [-80.5266757187797, 35.346812611801397, 0.0],
            [-80.53445558543433, 35.338848478480372, 0.0],
            [-80.538061918762139, 35.338255678481232, 0.0],
          ],
          [
            [-79.851174319828317, 35.3586464117829, 0.0],
            [-79.855649119821351, 35.351685678460569, 0.0],
          ],
          [
            [-80.43125791892794, 35.35900301178242, 0.0],
            [-80.4332401189248, 35.355039878455273, 0.0],
            [-80.437011985585684, 35.352083678459906, 0.0],
            [-80.43835438558358, 35.352999078458367, 0.0],
          ],
          [
            [-80.636169318609802, 35.360456678446951, 0.0],
            [-80.631220585284211, 35.358876811782636, 0.0],
          ],
          [
            [-80.707638385165524, 35.368949011767086, 0.0],
            [-80.699895518510914, 35.371519211763029, 0.0],
            [-80.691698918523571, 35.36694341177008, 0.0],
            [-80.687309518530469, 35.367495411769369, 0.0],
            [-80.686220918532172, 35.369694678432552, 0.0],
            [-80.681915985205535, 35.371322878429964, 0.0],
            [-80.680795785207181, 35.368826878433936, 0.0],
            [-80.674336518550604, 35.368188411768301, 0.0],
            [-80.673830185218037, 35.363127078442687, 0.0],
            [-80.6784715852109, 35.363468678442132, 0.0],
            [-80.679644718542363, 35.36200241177778, 0.0],
            [-80.678497318544146, 35.360170611780632, 0.0],
            [-80.675561718548693, 35.360080278447413, 0.0],
            [-80.674552918550148, 35.356301678453292, 0.0],
            [-80.670415985223315, 35.357379878451752, 0.0],
            [-80.671084918555664, 35.353692211790644, 0.0],
            [-80.672733985219793, 35.353302078458057, 0.0],
            [-80.66697291856201, 35.348815278464997, 0.0],
            [-80.667055585228582, 35.346662478468261, 0.0],
            [-80.668452585226419, 35.345264878470402, 0.0],
            [-80.672563585219905, 35.348515478465401, 0.0],
            [-80.672058185220806, 35.344736678471293, 0.0],
            [-80.669876518557487, 35.342195278475231, 0.0],
            [-80.666103985230052, 35.344097611805523, 0.0],
            [-80.660596785238624, 35.342771011807713, 0.0],
            [-80.663558718567288, 35.34057141181114, 0.0],
            [-80.663278118567803, 35.338235411814708, 0.0],
          ],
          [
            [-80.051086986184714, 35.371390611763218, 0.0],
            [-80.054787119512241, 35.365044278439825, 0.0],
            [-80.065225386162751, 35.360377411780291, 0.0],
            [-80.067137319493099, 35.353533478457678, 0.0],
            [-80.067168119492976, 35.343642211806241, 0.0],
          ],
          [
            [-80.432075385593237, 35.369171278433328, 0.0],
            [-80.433946718923664, 35.366193211771247, 0.0],
            [-80.4337201189241, 35.361956211777908, 0.0],
            [-80.43125791892794, 35.35900301178242, 0.0],
          ],
          [
            [-79.869992119799122, 35.324642411835725, 0.0],
            [-79.873010119794458, 35.322054878506435, 0.0],
            [-79.866835319804011, 35.319535478510431, 0.0],
            [-79.871473986463457, 35.315642811849784, 0.0],
            [-79.874072786459351, 35.307925411861788, 0.0],
          ],
          [
            [-80.59550718533967, 35.316319611848769, 0.0],
            [-80.594248985341608, 35.318472078512002, 0.0],
            [-80.589079985349542, 35.31730261184714, 0.0],
            [-80.589217718682733, 35.321906211840087, 0.0],
            [-80.587484585352115, 35.323027878505059, 0.0],
            [-80.578907785365345, 35.319039811844505, 0.0],
            [-80.573765118706717, 35.320892811841702, 0.0],
            [-80.565633985386, 35.320133278509445, 0.0],
            [-80.564629185387503, 35.318575411845188, 0.0],
          ],
          [
            [-80.597460185336558, 35.325023211835287, 0.0],
            [-80.596511318671446, 35.321541611840644, 0.0],
            [-80.598440718668428, 35.318015078512758, 0.0],
            [-80.59550718533967, 35.316319611848769, 0.0],
          ],
          [
            [-80.564629185387503, 35.318575411845188, 0.0],
            [-80.561807585391989, 35.317634878513445, 0.0],
            [-80.559990185394724, 35.319099678511009, 0.0],
            [-80.56088018539333, 35.324413678502879, 0.0],
          ],
          [
            [-80.503956718815004, 35.323957678503518, 0.0],
            [-80.500217918820852, 35.320152078509409, 0.0],
            [-80.495055985495469, 35.320777878508466, 0.0],
            [-80.487983918839802, 35.318998411844632, 0.0],
            [-80.476323985524573, 35.31287067852071, 0.0],
          ],
          [
            [-80.06910758615669, 35.335914011818375, 0.0],
            [-80.081914786136849, 35.322942211838438, 0.0],
            [-80.082588119469165, 35.319735411843453, 0.0],
            [-80.078909386141504, 35.311749278522427, 0.0],
            [-80.080585719472253, 35.306014878531471, 0.0],
            [-80.085962119463829, 35.300657611873078, 0.0],
          ],
          [
            [-80.476323985524573, 35.31287067852071, 0.0],
            [-80.466679385539635, 35.308366478527603, 0.0],
          ],
          [
            [-80.466679385539635, 35.308366478527603, 0.0],
            [-80.47050098553359, 35.303073011869174, 0.0],
            [-80.474604785527276, 35.300504611873237, 0.0],
            [-80.47348071886239, 35.294985878548516, 0.0],
            [-80.482600518848244, 35.283526611899674, 0.0],
            [-80.487820518840124, 35.281368878569708, 0.0],
          ],
          [
            [-79.857976786484414, 35.32331287850451, 0.0],
            [-79.85993298648134, 35.322465878505852, 0.0],
            [-79.861692919811958, 35.324344011836274, 0.0],
            [-79.869992119799122, 35.324642411835725, 0.0],
          ],
          [
            [-80.519193785458071, 35.318475411845441, 0.0],
            [-80.515441518797218, 35.324358078502883, 0.0],
            [-80.513485718800325, 35.324035611836678, 0.0],
            [-80.511451185470094, 35.32014021184284, 0.0],
            [-80.503956718815004, 35.323957678503518, 0.0],
          ],
          [
            [-80.599747918666367, 35.336543878484065, 0.0],
            [-80.594859985340634, 35.328206011830218, 0.0],
            [-80.597460185336558, 35.325023211835287, 0.0],
          ],
          [
            [-80.448010318901936, 35.334099011821138, 0.0],
            [-80.447869185568834, 35.332427211823699, 0.0],
            [-80.461936318880191, 35.314645411851302, 0.0],
            [-80.466070585540592, 35.313703411852771, 0.0],
            [-80.463609718877706, 35.311758411855806, 0.0],
            [-80.466679385539635, 35.308366478527603, 0.0],
          ],
          [
            [-80.533824318768723, 35.32879361182944, 0.0],
            [-80.53016271877442, 35.329248878495264, 0.0],
            [-80.524967185449157, 35.327068878498721, 0.0],
            [-80.519193785458071, 35.318475411845441, 0.0],
          ],
          [
            [-80.56088018539333, 35.324413678502879, 0.0],
            [-80.558447985397152, 35.325534611834428, 0.0],
            [-80.551716185407543, 35.322232678506225, 0.0],
            [-80.55076191874241, 35.326629478499342, 0.0],
            [-80.547715385413767, 35.327062811832093, 0.0],
            [-80.544618718751906, 35.321792878506983, 0.0],
            [-80.541405985423637, 35.320874678508289, 0.0],
            [-80.536121385431841, 35.323825411837049, 0.0],
            [-80.533824318768723, 35.32879361182944, 0.0],
          ],
          [
            [-80.663278118567803, 35.338235411814708, 0.0],
            [-80.653634385249404, 35.335398011818995, 0.0],
            [-80.653104718583563, 35.339497811812748, 0.0],
            [-80.645556918595275, 35.335308078485866, 0.0],
            [-80.638681385272662, 35.334621878487042, 0.0],
            [-80.638233918606659, 35.332560811823555, 0.0],
            [-80.633287118614362, 35.331988478491155, 0.0],
            [-80.633007718614749, 35.333454411822174, 0.0],
            [-80.628368185288537, 35.333660678488513, 0.0],
            [-80.623253785296583, 35.329332078495213, 0.0],
            [-80.620486785300784, 35.330866478492851, 0.0],
            [-80.619117585302945, 35.329102878495576, 0.0],
            [-80.615763918641505, 35.330087411827435, 0.0],
            [-80.613612718644788, 35.324934078502054, 0.0],
            [-80.608443585319492, 35.321360678507631, 0.0],
            [-80.604978318658254, 35.322047211839845, 0.0],
            [-80.605146385324701, 35.320123411842872, 0.0],
            [-80.601596985330161, 35.321794678506933, 0.0],
            [-80.600114385332461, 35.327153678498632, 0.0],
            [-80.597460185336558, 35.325023211835287, 0.0],
          ],
          [
            [-80.538061918762139, 35.338255678481232, 0.0],
            [-80.537368185429898, 35.333560011822044, 0.0],
            [-80.533824318768723, 35.32879361182944, 0.0],
          ],
          [
            [-80.067168119492976, 35.343642211806241, 0.0],
            [-80.06910758615669, 35.335914011818375, 0.0],
          ],
          [
            [-80.43835438558358, 35.352999078458367, 0.0],
            [-80.440504985580162, 35.350478611795609, 0.0],
            [-80.437706518917935, 35.346334811802194, 0.0],
            [-80.44569438557221, 35.338520611814317, 0.0],
            [-80.448010318901936, 35.334099011821138, 0.0],
          ],
          [
            [-80.475198185526381, 35.260240611935842, 0.0],
            [-80.473212118862818, 35.25724201194032, 0.0],
          ],
          [
            [-79.909002386405234, 35.253646678612768, 0.0],
            [-79.916204719727375, 35.251927011948737, 0.0],
          ],
          [
            [-80.473212118862818, 35.25724201194032, 0.0],
            [-80.472929518863225, 35.25437941194491, 0.0],
            [-80.476248918858118, 35.251696878615746, 0.0],
            [-80.475789918858823, 35.246442878623839, 0.0],
            [-80.482377985515143, 35.246070878624323, 0.0],
            [-80.485138985510957, 35.243846878627892, 0.0],
            [-80.484354118845545, 35.241351211965082, 0.0],
            [-80.478205785521652, 35.235951478640231, 0.0],
          ],
          [
            [-79.916204719727375, 35.251927011948737, 0.0],
            [-79.908594119739121, 35.232820011978276, 0.0],
            [-79.909625719737619, 35.229132811984073, 0.0],
            [-79.914425119730083, 35.225902611989113, 0.0],
            [-79.911436119734731, 35.217933878668077, 0.0],
          ],
          [
            [-80.097199786113151, 35.251567678615856, 0.0],
            [-80.095733919448662, 35.246547011957091, 0.0],
            [-80.096314986114464, 35.239267611968387, 0.0],
            [-80.08914431945891, 35.231439478647076, 0.0],
            [-80.07704798614435, 35.225349611989884, 0.0],
          ],
          [
            [-80.487440785507374, 35.269207811921774, 0.0],
            [-80.475198185526381, 35.260240611935842, 0.0],
          ],
          [
            [-79.912779386399393, 35.272263878583715, 0.0],
            [-79.903340319747372, 35.266881078592064, 0.0],
            [-79.902076119749267, 35.264676211928929, 0.0],
            [-79.902943519747851, 35.259846211936406, 0.0],
            [-79.906984919741717, 35.257865011939487, 0.0],
            [-79.909002386405234, 35.253646678612768, 0.0],
          ],
          [
            [-80.094955319449866, 35.28856107855853, 0.0],
            [-80.095578119448987, 35.284839878564128, 0.0],
            [-80.092365186120617, 35.27463367858013, 0.0],
            [-80.093944786118186, 35.266348678592919, 0.0],
            [-80.097481186112645, 35.260740478601576, 0.0],
            [-80.09854491944435, 35.254161278611832, 0.0],
            [-80.097199786113151, 35.251567678615856, 0.0],
          ],
          [
            [-80.727820785134213, 35.386866611739151, 0.0],
            [-80.716596985151625, 35.378928211751486, 0.0],
          ],
          [
            [-79.827392186531938, 35.3899428117345, 0.0],
            [-79.831700719858532, 35.387173678405475, 0.0],
          ],
          [
            [-80.073456786149904, 35.392439678397295, 0.0],
            [-80.069599119489283, 35.390124811734097, 0.0],
          ],
          [
            [-80.069599119489283, 35.390124811734097, 0.0],
            [-80.067992719491656, 35.390693678399884, 0.0],
          ],
          [
            [-80.070923919487257, 35.393249411729187, 0.0],
            [-80.067992719491656, 35.390693678399884, 0.0],
          ],
          [
            [-80.067992719491656, 35.390693678399884, 0.0],
            [-80.060883586169439, 35.384016678410376, 0.0],
          ],
          [
            [-80.070923919487257, 35.393249411729187, 0.0],
            [-80.073456786149904, 35.392439678397295, 0.0],
          ],
          [
            [-80.020875519564925, 35.394969211726732, 0.0],
            [-80.024402586226131, 35.392567611730328, 0.0],
            [-80.027416919554753, 35.397287811723004, 0.0],
            [-80.032195919547291, 35.400337478384984, 0.0],
            [-80.034270319544135, 35.396423078391138, 0.0],
            [-80.031280119548796, 35.394405478394162, 0.0],
            [-80.03050198621662, 35.390465878400391, 0.0],
            [-80.036567986207217, 35.392669078396864, 0.0],
            [-80.041608119532668, 35.387176678405353, 0.0],
          ],
          [
            [-79.819159786544674, 35.403930678379368, 0.0],
            [-79.822406786539659, 35.400611811717908, 0.0],
            [-79.821737919873954, 35.397222411723078, 0.0],
            [-79.818829719878465, 35.396602611724063, 0.0],
            [-79.8194463865442, 35.394931211726771, 0.0],
            [-79.822581386539355, 35.391154278399256, 0.0],
            [-79.824510386536417, 35.392117011731102, 0.0],
            [-79.827392186531938, 35.3899428117345, 0.0],
          ],
          [
            [-79.999769319597704, 35.483851478255417, 0.0],
            [-79.999769386264347, 35.481698878258669, 0.0],
          ],
          [
            [-80.161729919346271, 35.475459611601764, 0.0],
            [-80.148762519366414, 35.473325211605072, 0.0],
            [-80.142796786042368, 35.475435078268333, 0.0],
            [-80.137146119384397, 35.473782211604373, 0.0],
          ],
          [
            [-80.504321785481125, 35.488696411581259, 0.0],
            [-80.501921385484934, 35.483747078255476, 0.0],
            [-80.500990385486261, 35.470371078276173, 0.0],
          ],
          [
            [-80.17678311932292, 35.484821078253731, 0.0],
            [-80.1672725193377, 35.480647678260311, 0.0],
            [-80.161729919346271, 35.475459611601764, 0.0],
          ],
          [
            [-80.73325031845917, 35.495306011570847, 0.0],
            [-80.730221785130539, 35.492148078242508, 0.0],
            [-80.727561718467939, 35.492379411575484, 0.0],
            [-80.727809918467642, 35.489378878246839, 0.0],
            [-80.722594985142337, 35.48395527825528, 0.0],
            [-80.716797785151357, 35.483181211589795, 0.0],
          ],
          [
            [-80.177776919321389, 35.487773811582542, 0.0],
            [-80.17678311932292, 35.484821078253731, 0.0],
          ],
          [
            [-79.999769386264347, 35.481698878258669, 0.0],
            [-80.009050986249918, 35.483262611589566, 0.0],
            [-80.011687319579153, 35.480288211594143, 0.0],
          ],
          [
            [-80.772402185065005, 35.457451811629653, 0.0],
            [-80.765628118408927, 35.45753747829616, 0.0],
          ],
          [
            [-80.011687319579153, 35.480288211594143, 0.0],
            [-80.009087186249815, 35.47797267826445, 0.0],
            [-80.013767119575846, 35.474862878269334, 0.0],
            [-80.011173186246651, 35.468448078279266, 0.0],
            [-80.012186319578348, 35.464785078284933, 0.0],
            [-80.006117186254528, 35.461550011623274, 0.0],
            [-80.007159719586184, 35.457062611630136, 0.0],
            [-80.004868519589706, 35.454587011634203, 0.0],
          ],
          [
            [-80.716797785151357, 35.483181211589795, 0.0],
            [-80.713625985156284, 35.475969278267598, 0.0],
            [-80.712796785157593, 35.459800678292595, 0.0],
          ],
          [
            [-79.813332319887081, 35.415560611694616, 0.0],
            [-79.813924319886155, 35.409927611703381, 0.0],
            [-79.81747751988064, 35.409082211704629, 0.0],
            [-79.819521519877469, 35.406495611708749, 0.0],
            [-79.819159786544674, 35.403930678379368, 0.0],
          ],
          [
            [-80.08891078612595, 35.415232278361827, 0.0],
            [-80.089802519457919, 35.414465278363025, 0.0],
          ],
          [
            [-80.092625386120233, 35.419796211688038, 0.0],
            [-80.089802519457919, 35.414465278363025, 0.0],
          ],
          [
            [-80.091397986122161, 35.420500278353643, 0.0],
            [-80.08891078612595, 35.415232278361827, 0.0],
          ],
          [
            [-80.092814519453214, 35.419995211687819, 0.0],
            [-80.092625386120233, 35.419796211688038, 0.0],
          ],
          [
            [-80.093025386119564, 35.420217211687486, 0.0],
            [-80.091397986122161, 35.420500278353643, 0.0],
          ],
          [
            [-80.092846719453121, 35.420029078354446, 0.0],
            [-80.092814519453214, 35.419995211687819, 0.0],
          ],
          [
            [-80.093025386119564, 35.420217211687486, 0.0],
            [-80.092846719453121, 35.420029078354446, 0.0],
          ],
          [
            [-80.023414119560982, 35.420780611686496, 0.0],
            [-80.019035319567649, 35.411112478368238, 0.0],
            [-80.025641919557472, 35.407248211707611, 0.0],
            [-80.026345586223101, 35.403813611712792, 0.0],
            [-80.020450719565474, 35.398862011720553, 0.0],
            [-80.020875519564925, 35.394969211726732, 0.0],
          ],
          [
            [-80.754026585093584, 35.44747221164522, 0.0],
            [-80.747744718436707, 35.431394011670079, 0.0],
            [-80.730649718463212, 35.411668011700613, 0.0],
            [-80.728009518467331, 35.403379411713672, 0.0],
            [-80.725377785138051, 35.401572278383014, 0.0],
            [-80.723551318474165, 35.394771678393568, 0.0],
            [-80.724299918473037, 35.389297278402125, 0.0],
            [-80.727820785134213, 35.386866611739151, 0.0],
          ],
          [
            [-80.089802519457919, 35.414465278363025, 0.0],
            [-80.093481919452245, 35.405959011709683, 0.0],
            [-80.092626519453574, 35.401022278383891, 0.0],
            [-80.087722986127801, 35.399594478386177, 0.0],
            [-80.084948586132157, 35.395538411725852, 0.0],
            [-80.075619319479983, 35.395664611725635, 0.0],
            [-80.070923919487257, 35.393249411729187, 0.0],
          ],
          [
            [-80.01423391957519, 35.423451611682481, 0.0],
            [-80.01328631957665, 35.420931478353054, 0.0],
            [-80.015079519573931, 35.419124078355765, 0.0],
            [-80.023414119560982, 35.420780611686496, 0.0],
          ],
          [
            [-80.01479058624102, 35.425467411679222, 0.0],
            [-80.01423391957519, 35.423451611682481, 0.0],
          ],
          [
            [-79.809906586559066, 35.429893678339113, 0.0],
            [-79.814997986551134, 35.430972811670756, 0.0],
            [-79.816649986548555, 35.429508211673067, 0.0],
            [-79.811448786556639, 35.426047478345083, 0.0],
            [-79.811340719890154, 35.42171941168516, 0.0],
            [-79.815058986551037, 35.424767078347145, 0.0],
            [-79.817746519880075, 35.422799211683525, 0.0],
            [-79.813332319887081, 35.415560611694616, 0.0],
          ],
          [
            [-80.430662518928784, 35.432588611668223, 0.0],
            [-80.427135985601012, 35.431559611669798, 0.0],
            [-80.419078118946743, 35.433303211667123, 0.0],
            [-80.411633185625078, 35.429458011673148, 0.0],
            [-80.410036518960794, 35.424877878346877, 0.0],
            [-80.405250718968318, 35.420940011686412, 0.0],
          ],
          [
            [-80.098290386111387, 35.428494011674559, 0.0],
            [-80.093025386119564, 35.420217211687486, 0.0],
          ],
          [
            [-80.015628986239619, 35.426132211678294, 0.0],
            [-80.01423391957519, 35.423451611682481, 0.0],
          ],
          [
            [-80.015628986239619, 35.426132211678294, 0.0],
            [-80.01479058624102, 35.425467411679222, 0.0],
          ],
          [
            [-80.003026319592664, 35.433974611666031, 0.0],
            [-80.005604186255255, 35.431595611669707, 0.0],
            [-80.015844186239406, 35.432178211668884, 0.0],
            [-80.017245986237242, 35.430164278338566, 0.0],
            [-80.015628986239619, 35.426132211678294, 0.0],
          ],
          [
            [-80.765628118408927, 35.45753747829616, 0.0],
            [-80.756678585089446, 35.452147011637862, 0.0],
            [-80.754026585093584, 35.44747221164522, 0.0],
          ],
          [
            [-80.706314118500927, 35.443200811651877, 0.0],
            [-80.703175918505849, 35.439263611657964, 0.0],
            [-80.697380918514796, 35.437068411661244, 0.0],
            [-80.692114318523011, 35.430292278338527, 0.0],
            [-80.687634785196622, 35.427202811676636, 0.0],
            [-80.686597718531573, 35.424821411680341, 0.0],
            [-80.688553785195211, 35.421568278352083, 0.0],
            [-80.6829823852039, 35.416532478359784, 0.0],
            [-80.683343918536593, 35.413486211697943, 0.0],
            [-80.67973518520887, 35.413716878364141, 0.0],
            [-80.678446118544173, 35.410396611702708, 0.0],
            [-80.674724318550034, 35.408428478372457, 0.0],
          ],
          [
            [-79.7999119865745, 35.463480811620286, 0.0],
            [-79.797900119910935, 35.460113211625526, 0.0],
            [-79.803055719903, 35.454391878301067, 0.0],
            [-79.799923519907907, 35.452214278304382, 0.0],
            [-79.800373186573779, 35.450291078307487, 0.0],
            [-79.805549386565701, 35.451531011638735, 0.0],
            [-79.804768786567024, 35.44837041164368, 0.0],
            [-79.808131919895118, 35.443174278318509, 0.0],
            [-79.807547986562668, 35.439189411658049, 0.0],
            [-79.808639986560991, 35.438274211659405, 0.0],
            [-79.810932919890774, 35.440061678323332, 0.0],
            [-79.814404919885419, 35.437567478327253, 0.0],
            [-79.809906586559066, 35.429893678339113, 0.0],
          ],
          [
            [-80.712796785157593, 35.459800678292595, 0.0],
            [-80.706314118500927, 35.443200811651877, 0.0],
          ],
          [
            [-80.097327319446208, 35.43946241165753, 0.0],
            [-80.099220519443236, 35.42969861167262, 0.0],
            [-80.098290386111387, 35.428494011674559, 0.0],
          ],
          [
            [-80.438418718916751, 35.439478611657592, 0.0],
            [-80.43612038558706, 35.434532878331993, 0.0],
            [-80.430662518928784, 35.432588611668223, 0.0],
          ],
          [
            [-80.454120718892455, 35.44221787831998, 0.0],
            [-80.446507385570897, 35.440665078322468, 0.0],
            [-80.443737785575081, 35.44201781165367, 0.0],
            [-80.439595318914996, 35.441195611654905, 0.0],
            [-80.438418718916751, 35.439478611657592, 0.0],
          ],
          [
            [-80.004868519589706, 35.454587011634203, 0.0],
            [-80.00451058625697, 35.450899678306484, 0.0],
            [-80.011676386245824, 35.450082478307763, 0.0],
            [-80.009192386249708, 35.445660211647919, 0.0],
            [-80.012499519577887, 35.442365811653133, 0.0],
            [-80.008727586250416, 35.438469011659151, 0.0],
            [-80.004700319590029, 35.437022078327971, 0.0],
            [-80.003026319592664, 35.433974611666031, 0.0],
          ],
          [
            [-80.500990385486261, 35.470371078276173, 0.0],
            [-80.497295718825399, 35.456336411631355, 0.0],
            [-80.493651385497742, 35.452446611637413, 0.0],
            [-80.479705985519388, 35.447122878312314, 0.0],
            [-80.47494978552669, 35.448546811643553, 0.0],
            [-80.474280518861121, 35.450654278306956, 0.0],
            [-80.468210318870547, 35.453659211635625, 0.0],
            [-80.461820785547161, 35.446060278313894, 0.0],
            [-80.458824718885126, 35.444573678316317, 0.0],
            [-80.457477918887093, 35.441093478321648, 0.0],
            [-80.454120718892455, 35.44221787831998, 0.0],
          ],
          [
            [-80.137146119384397, 35.473782211604373, 0.0],
            [-80.128362919398057, 35.465997011616366, 0.0],
            [-80.117241186081969, 35.463347878287152, 0.0],
            [-80.113287319421488, 35.458879611627367, 0.0],
            [-80.114920119418855, 35.45034901164064, 0.0],
            [-80.107725719430107, 35.443284278318345, 0.0],
            [-80.099546186109421, 35.441666478320769, 0.0],
            [-80.097327319446208, 35.43946241165753, 0.0],
          ],
          [
            [-79.797274319911992, 35.506442211553633, 0.0],
            [-79.794893319915673, 35.506852811553017, 0.0],
          ],
          [
            [-79.989939186279571, 35.514589211541022, 0.0],
            [-79.994279586272853, 35.513852278208674, 0.0],
          ],
          [
            [-79.811856919889351, 35.51668701153767, 0.0],
            [-79.810624519891292, 35.516732078204313, 0.0],
          ],
          [
            [-79.99600371960355, 35.50549187822179, 0.0],
            [-79.999768519597694, 35.503057411558871, 0.0],
          ],
          [
            [-79.996487186269462, 35.510415011547536, 0.0],
            [-79.99600371960355, 35.50549187822179, 0.0],
          ],
          [
            [-79.80746311989617, 35.512448011544336, 0.0],
            [-79.802759719903406, 35.510475878214095, 0.0],
            [-79.79940291990863, 35.506145811554006, 0.0],
            [-79.797274319911992, 35.506442211553633, 0.0],
          ],
          [
            [-79.994279586272853, 35.513852278208674, 0.0],
            [-79.996487186269462, 35.510415011547536, 0.0],
          ],
          [
            [-79.810624519891292, 35.516732078204313, 0.0],
            [-79.80746311989617, 35.512448011544336, 0.0],
          ],
          [
            [-79.990949719611365, 35.535954278174358, 0.0],
            [-79.990807186278289, 35.534237011510413, 0.0],
            [-79.984216586288483, 35.529136211518392, 0.0],
          ],
          [
            [-79.980907386293552, 35.54823801148882, 0.0],
            [-79.977650786298625, 35.543317278163101, 0.0],
            [-79.977928386298231, 35.541118478166482, 0.0],
            [-79.990949719611365, 35.535954278174358, 0.0],
          ],
          [
            [-80.195197185960978, 35.527536011520851, 0.0],
            [-80.190541385968174, 35.52487921152499, 0.0],
          ],
          [
            [-79.983709319622619, 35.526846678188576, 0.0],
            [-79.984882119620806, 35.52402881152625, 0.0],
            [-79.990398786278888, 35.522787211528282, 0.0],
            [-79.989939186279571, 35.514589211541022, 0.0],
          ],
          [
            [-79.811596186556358, 35.527266411521282, 0.0],
            [-79.813475986553442, 35.523534811527099, 0.0],
            [-79.811856919889351, 35.51668701153767, 0.0],
          ],
          [
            [-79.818090119879628, 35.534368611510274, 0.0],
            [-79.818147786546263, 35.531987078180634, 0.0],
            [-79.811596186556358, 35.527266411521282, 0.0],
          ],
          [
            [-79.984216586288483, 35.529136211518392, 0.0],
            [-79.983709319622619, 35.526846678188576, 0.0],
          ],
          [
            [-79.970228719643501, 35.570414078121075, 0.0],
            [-79.971206786308642, 35.567871478124914, 0.0],
          ],
          [
            [-80.207684519274949, 35.572841411450611, 0.0],
            [-80.207692319274884, 35.566755678126754, 0.0],
          ],
          [
            [-79.968357986313094, 35.576804678111046, 0.0],
            [-79.970228719643501, 35.570414078121075, 0.0],
          ],
          [
            [-80.207830319274763, 35.578947878107726, 0.0],
            [-80.207684519274949, 35.572841411450611, 0.0],
          ],
          [
            [-80.227722519243855, 35.59341607808517, 0.0],
            [-80.221653985919829, 35.589176678091803, 0.0],
          ],
          [
            [-79.972415786306726, 35.595053011416098, 0.0],
            [-79.965738186317139, 35.590729878089405, 0.0],
          ],
          [
            [-80.236764785896469, 35.606803878064568, 0.0],
            [-80.23389311923421, 35.598683211410446, 0.0],
            [-80.227722519243855, 35.59341607808517, 0.0],
          ],
          [
            [-79.969570186311103, 35.607741678063121, 0.0],
            [-79.973746719638086, 35.606272878065397, 0.0],
            [-79.970462719643137, 35.603550411402807, 0.0],
            [-79.970204186310241, 35.598008811411489, 0.0],
            [-79.972415786306726, 35.595053011416098, 0.0],
          ],
          [
            [-79.963123519654573, 35.583793011433556, 0.0],
            [-79.97226071964036, 35.582274811435866, 0.0],
            [-79.968357986313094, 35.576804678111046, 0.0],
          ],
          [
            [-80.216865719260568, 35.58637701142959, 0.0],
            [-80.212179785934666, 35.583844611433562, 0.0],
            [-80.207830319274763, 35.578947878107726, 0.0],
          ],
          [
            [-79.965738186317139, 35.590729878089405, 0.0],
            [-79.963409586320779, 35.589151478091878, 0.0],
            [-79.963123519654573, 35.583793011433556, 0.0],
          ],
          [
            [-80.221653985919829, 35.589176678091803, 0.0],
            [-80.216865719260568, 35.58637701142959, 0.0],
          ],
          [
            [-80.256189985866342, 35.625891411368173, 0.0],
            [-80.242929519220183, 35.610674478058513, 0.0],
            [-80.240572185890585, 35.60985081139313, 0.0],
          ],
          [
            [-80.302955319127079, 35.652692811326574, 0.0],
            [-80.300313985797857, 35.650184611330587, 0.0],
          ],
          [
            [-80.281778785826646, 35.632871811357404, 0.0],
            [-80.25934771919475, 35.628979611363491, 0.0],
            [-80.256657919198858, 35.625956411368065, 0.0],
          ],
          [
            [-80.293212185808784, 35.640233611345877, 0.0],
            [-80.288982585815404, 35.635484011353299, 0.0],
            [-80.281778785826646, 35.632871811357404, 0.0],
          ],
          [
            [-79.952761986337293, 35.626164811367687, 0.0],
            [-79.952619719670849, 35.623874878038009, 0.0],
            [-79.955985786332235, 35.624285011370603, 0.0],
          ],
          [
            [-80.256657919198858, 35.625956411368065, 0.0],
            [-80.256189985866342, 35.625891411368173, 0.0],
          ],
          [
            [-79.958398386328554, 35.653709677991742, 0.0],
            [-79.960752386324828, 35.650777011329524, 0.0],
            [-79.965183319651373, 35.648873211332614, 0.0],
            [-79.96444998631921, 35.645232611338145, 0.0],
            [-79.967199519648204, 35.645299278004813, 0.0],
            [-79.965818919650246, 35.639896011346536, 0.0],
            [-79.962480186322239, 35.639371678013902, 0.0],
            [-79.964945519651678, 35.636255611352112, 0.0],
            [-79.959167319660708, 35.636603078018197, 0.0],
            [-79.957760919662803, 35.632756811357467, 0.0],
            [-79.951252986339625, 35.632417411358006, 0.0],
            [-79.952761986337293, 35.626164811367687, 0.0],
          ],
          [
            [-80.300313985797857, 35.650184611330587, 0.0],
            [-80.293212185808784, 35.640233611345877, 0.0],
          ],
          [
            [-80.23765198589507, 35.60793721139612, 0.0],
            [-80.236764785896469, 35.606803878064568, 0.0],
          ],
          [
            [-79.968421386313025, 35.608544078061811, 0.0],
            [-79.969570186311103, 35.607741678063121, 0.0],
          ],
          [
            [-80.240572185890585, 35.60985081139313, 0.0],
            [-80.23765198589507, 35.60793721139612, 0.0],
          ],
          [
            [-79.955985786332235, 35.624285011370603, 0.0],
            [-79.958565786328279, 35.624054278037761, 0.0],
            [-79.960021386326048, 35.621190878042228, 0.0],
            [-79.956684119664544, 35.621422078041803, 0.0],
            [-79.953904186335535, 35.617851411380627, 0.0],
            [-79.956794586331057, 35.619635811377975, 0.0],
            [-79.962119119656052, 35.615510211384276, 0.0],
            [-79.964897186318467, 35.617019678048621, 0.0],
            [-79.970278786310132, 35.61445081138595, 0.0],
            [-79.971816519641038, 35.610327811392267, 0.0],
            [-79.967274519648129, 35.611132678057857, 0.0],
            [-79.968421386313025, 35.608544078061811, 0.0],
          ],
          [
            [-79.976772119633324, 35.55781381147392, 0.0],
            [-79.973544319638393, 35.553602811480459, 0.0],
          ],
          [
            [-79.973544319638393, 35.553602811480459, 0.0],
            [-79.974409986303669, 35.551083011484195, 0.0],
            [-79.980239519628014, 35.551834011483095, 0.0],
            [-79.980907386293552, 35.54823801148882, 0.0],
          ],
          [
            [-80.206921785942825, 35.548518078155041, 0.0],
            [-80.199371319287877, 35.545345278159857, 0.0],
            [-80.195900385959931, 35.537015078172772, 0.0],
            [-80.197805985956961, 35.529409411517918, 0.0],
            [-80.195197185960978, 35.527536011520851, 0.0],
          ],
          [
            [-80.207692319274884, 35.566755678126754, 0.0],
            [-80.210986919269828, 35.554011011479759, 0.0],
            [-80.206921785942825, 35.548518078155041, 0.0],
          ],
          [
            [-79.971206786308642, 35.567871478124914, 0.0],
            [-79.97235211964022, 35.564641678129988, 0.0],
            [-79.976636786300276, 35.561798478134392, 0.0],
            [-79.976772119633324, 35.55781381147392, 0.0],
          ],
          [
            [-80.734210718457632, 35.50014487823006, 0.0],
            [-80.73325031845917, 35.495306011570847, 0.0],
          ],
          [
            [-80.005222186255878, 35.496563811568876, 0.0],
            [-80.007722719585274, 35.491207811577283, 0.0],
            [-79.999769319597704, 35.483851478255417, 0.0],
          ],
          [
            [-80.181428319315671, 35.500155878230089, 0.0],
            [-80.177776919321389, 35.487773811582542, 0.0],
          ],
          [
            [-79.788944119924963, 35.500151078230033, 0.0],
            [-79.782123319935522, 35.491725011576364, 0.0],
            [-79.786913586594778, 35.489919878245928, 0.0],
            [-79.785266986597264, 35.486644011584417, 0.0],
            [-79.788747986591829, 35.481013478259683, 0.0],
            [-79.789204519924397, 35.475449411601801, 0.0],
            [-79.796850986579273, 35.472638411606169, 0.0],
            [-79.797726519911237, 35.465517411617213, 0.0],
            [-79.7999119865745, 35.463480811620286, 0.0],
          ],
          [
            [-80.005164586256001, 35.500141278230103, 0.0],
            [-80.005222186255878, 35.496563811568876, 0.0],
          ],
          [
            [-80.737640518452338, 35.508188211550873, 0.0],
            [-80.734210718457632, 35.50014487823006, 0.0],
          ],
          [
            [-79.794893319915673, 35.506852811553017, 0.0],
            [-79.794449186583051, 35.504379278223553, 0.0],
            [-79.791366319921167, 35.505407678221957, 0.0],
            [-79.791123986588218, 35.502476278226482, 0.0],
            [-79.788604986592077, 35.501879078227375, 0.0],
            [-79.788944119924963, 35.500151078230033, 0.0],
          ],
          [
            [-79.999768519597694, 35.503057411558871, 0.0],
            [-80.005164586256001, 35.500141278230103, 0.0],
          ],
          [
            [-80.190541385968174, 35.52487921152499, 0.0],
            [-80.18502218597672, 35.518047478202334, 0.0],
            [-80.185115185976599, 35.507787678218165, 0.0],
            [-80.181428319315671, 35.500155878230089, 0.0],
          ],
          [
            [-79.825864186534318, 35.375126011757516, 0.0],
            [-79.826621386533134, 35.37162267842956, 0.0],
            [-79.834059519854918, 35.369999678432066, 0.0],
            [-79.833361919855975, 35.367801078435377, 0.0],
          ],
          [
            [-80.715865585152756, 35.3749666784243, 0.0],
            [-80.707336185166014, 35.3743772784253, 0.0],
            [-80.705627985168633, 35.371859078429225, 0.0],
            [-80.707638385165524, 35.368949011767086, 0.0],
          ],
          [
            [-80.425007985604282, 35.380556878415575, 0.0],
            [-80.432075385593237, 35.369171278433328, 0.0],
          ],
          [
            [-80.058955386172443, 35.379694411750393, 0.0],
            [-80.057204119508413, 35.376030411755949, 0.0],
            [-80.051086986184714, 35.371390611763218, 0.0],
          ],
          [
            [-80.653674985249381, 35.372294678428489, 0.0],
            [-80.649928318588536, 35.372776411760981, 0.0],
            [-80.651018118586705, 35.37073787843093, 0.0],
            [-80.649451718589262, 35.368677011767488, 0.0],
            [-80.645509185261972, 35.368357011768012, 0.0],
            [-80.645061185262762, 35.365998078438281, 0.0],
            [-80.647605185258669, 35.364325611774291, 0.0],
            [-80.647325185259206, 35.362997411776291, 0.0],
            [-80.642908118599394, 35.364555611773937, 0.0],
            [-80.641425985268313, 35.363433478442289, 0.0],
            [-80.643214985265615, 35.361852878444722, 0.0],
            [-80.641732918601178, 35.359952211781035, 0.0],
            [-80.636169318609802, 35.360456678446951, 0.0],
          ],
          [
            [-80.536669785430945, 35.383854078410593, 0.0],
            [-80.535780718765636, 35.378723211751947, 0.0],
            [-80.538222785428502, 35.370182278431855, 0.0],
            [-80.53297271876994, 35.364590078440415, 0.0],
          ],
          [
            [-80.716596985151625, 35.378928211751486, 0.0],
            [-80.715865585152756, 35.3749666784243, 0.0],
          ],
          [
            [-80.059184119505346, 35.380106078416475, 0.0],
            [-80.058955386172443, 35.379694411750393, 0.0],
          ],
          [
            [-79.832515386523937, 35.380899411748487, 0.0],
            [-79.828209919863923, 35.379752678416935, 0.0],
            [-79.825864186534318, 35.375126011757516, 0.0],
          ],
          [
            [-80.057189319508495, 35.381988278413417, 0.0],
            [-80.057620586174551, 35.381542278414145, 0.0],
          ],
          [
            [-80.059987786170893, 35.3810766784149, 0.0],
            [-80.059184119505346, 35.380106078416475, 0.0],
          ],
          [
            [-80.057620586174551, 35.381542278414145, 0.0],
            [-80.058955386172443, 35.379694411750393, 0.0],
          ],
          [
            [-80.060883586169439, 35.384016678410376, 0.0],
            [-80.059987786170893, 35.3810766784149, 0.0],
          ],
          [
            [-79.831700719858532, 35.387173678405475, 0.0],
            [-79.833547786522331, 35.3846554784094, 0.0],
            [-79.832515386523937, 35.380899411748487, 0.0],
          ],
          [
            [-80.041608119532668, 35.387176678405353, 0.0],
            [-80.050250586185939, 35.385945878407256, 0.0],
            [-80.057189319508495, 35.381988278413417, 0.0],
          ],
          [
            [-80.674724318550034, 35.408428478372457, 0.0],
            [-80.667644318560974, 35.403827678379571, 0.0],
            [-80.670774518556129, 35.399062811720285, 0.0],
            [-80.667498985227894, 35.393246811729341, 0.0],
            [-80.669287718558394, 35.390749678399914, 0.0],
            [-80.667216585228346, 35.388002078404213, 0.0],
            [-80.668249585226647, 35.384566278409466, 0.0],
            [-80.659326918573925, 35.38097327841507, 0.0],
            [-80.657953585242694, 35.373118078427183, 0.0],
            [-80.653674985249381, 35.372294678428489, 0.0],
          ],
          [
            [-80.405250718968318, 35.420940011686412, 0.0],
            [-80.409388118961772, 35.411938411700248, 0.0],
            [-80.408911585629312, 35.409808611703625, 0.0],
            [-80.416631718950555, 35.40781381170666, 0.0],
            [-80.412097918957613, 35.402937011714357, 0.0],
            [-80.419001185613638, 35.39127747839899, 0.0],
            [-80.426046985602682, 35.387908078404223, 0.0],
            [-80.424646185604786, 35.383465678411142, 0.0],
            [-80.423471785606694, 35.383580678411079, 0.0],
            [-80.425007985604282, 35.380556878415575, 0.0],
          ],
          [
            [-80.505474318812674, 35.673537011294229, 0.0],
            [-80.499619718821805, 35.675366611291452, 0.0],
          ],
          [
            [-80.381794185671367, 35.677672277954514, 0.0],
            [-80.379452185675007, 35.677339811288221, 0.0],
          ],
          [
            [-80.386060585664723, 35.679573011284901, 0.0],
            [-80.381794185671367, 35.677672277954514, 0.0],
          ],
          [
            [-79.972342386306877, 35.702521877915899, 0.0],
            [-79.97267651963972, 35.700002811253114, 0.0],
          ],
          [
            [-80.625347985293274, 35.685002811276377, 0.0],
            [-80.623663585295958, 35.687751011272155, 0.0],
            [-80.614426985310274, 35.692445411264998, 0.0],
          ],
          [
            [-79.917759586391639, 35.68991081126876, 0.0],
            [-79.920595986387184, 35.692749477931045, 0.0],
            [-79.928902119707629, 35.687708477938827, 0.0],
          ],
          [
            [-79.928902119707629, 35.687708477938827, 0.0],
            [-79.934263386366013, 35.68775207793891, 0.0],
            [-79.935496786364126, 35.685347011275951, 0.0],
            [-79.939175119691754, 35.686971011273329, 0.0],
          ],
          [
            [-80.499619718821805, 35.675366611291452, 0.0],
            [-80.496534785493282, 35.677064411288711, 0.0],
            [-80.495286385495149, 35.687165277939755, 0.0],
            [-80.489597718837331, 35.694293077928705, 0.0],
          ],
          [
            [-80.334075719078726, 35.687905677938659, 0.0],
            [-80.332328919081476, 35.681841477947955, 0.0],
          ],
          [
            [-80.614426985310274, 35.692445411264998, 0.0],
            [-80.608754318652359, 35.700575011252283, 0.0],
          ],
          [
            [-79.97267651963972, 35.700002811253114, 0.0],
            [-79.975697986301725, 35.691023677933742, 0.0],
            [-79.974124519637485, 35.689673877935888, 0.0],
            [-79.97320031963892, 35.691598211266296, 0.0],
            [-79.968847586312336, 35.690113011268465, 0.0],
            [-79.965896986316864, 35.686932011273484, 0.0],
            [-79.966484986315891, 35.685534677942314, 0.0],
          ],
          [
            [-80.470590518866857, 35.693210011263773, 0.0],
            [-80.463543118877794, 35.692230411265314, 0.0],
          ],
          [
            [-80.463543118877794, 35.692230411265314, 0.0],
            [-80.451359185563319, 35.692009411265474, 0.0],
            [-80.448272785568122, 35.694026477929071, 0.0],
          ],
          [
            [-80.484069118845923, 35.695763877926424, 0.0],
            [-80.480055318852123, 35.696454411258628, 0.0],
            [-80.470590518866857, 35.693210011263773, 0.0],
          ],
          [
            [-80.489597718837331, 35.694293077928705, 0.0],
            [-80.484069118845923, 35.695763877926424, 0.0],
          ],
          [
            [-80.448272785568122, 35.694026477929071, 0.0],
            [-80.443955385574895, 35.70133447791784, 0.0],
          ],
          [
            [-80.775452718393694, 35.729208811207741, 0.0],
            [-80.768346785071344, 35.729271477874477, 0.0],
            [-80.760710518416488, 35.726951677878105, 0.0],
            [-80.75621551842346, 35.727771811210005, 0.0],
          ],
          [
            [-79.879498919784396, 35.728364677875788, 0.0],
            [-79.881211986448307, 35.725685411213249, 0.0],
            [-79.885649119774826, 35.725479277880254, 0.0],
            [-79.888625719770118, 35.723601411216464, 0.0],
          ],
          [
            [-79.895027986426953, 35.72124207788687, 0.0],
            [-79.897329319756636, 35.714440811230702, 0.0],
          ],
          [
            [-79.897329319756636, 35.714440811230702, 0.0],
            [-79.898592986421363, 35.715127611229661, 0.0],
          ],
          [
            [-80.427258718934127, 35.71586201122858, 0.0],
            [-80.42663878560171, 35.716175811228084, 0.0],
          ],
          [
            [-79.888625719770118, 35.723601411216464, 0.0],
            [-79.895027986426953, 35.72124207788687, 0.0],
          ],
          [
            [-79.97947218629588, 35.724591477881745, 0.0],
            [-79.977251386299315, 35.722601077884747, 0.0],
          ],
          [
            [-80.414611385620447, 35.723074211217295, 0.0],
            [-80.4030727189716, 35.722621811218062, 0.0],
          ],
          [
            [-80.4030727189716, 35.722621811218062, 0.0],
            [-80.394720518984514, 35.725126077880873, 0.0],
          ],
          [
            [-80.394720518984514, 35.725126077880873, 0.0],
            [-80.392279718988448, 35.723154411217138, 0.0],
          ],
          [
            [-80.608754318652359, 35.700575011252283, 0.0],
            [-80.607799118653872, 35.702658877915724, 0.0],
          ],
          [
            [-80.443955385574895, 35.70133447791784, 0.0],
            [-80.439493118915152, 35.703672811247486, 0.0],
          ],
          [
            [-79.97591431963474, 35.707900477907629, 0.0],
            [-79.975263319635701, 35.703321077914723, 0.0],
            [-79.972342386306877, 35.702521877915899, 0.0],
          ],
          [
            [-79.934558319698851, 35.70930027790547, 0.0],
            [-79.935764719696976, 35.707880011240832, 0.0],
            [-79.934725119698612, 35.706735411242789, 0.0],
            [-79.937418719694392, 35.70435267791305, 0.0],
            [-79.939300186358196, 35.705015877911933, 0.0],
          ],
          [
            [-79.901090986417444, 35.710570211236757, 0.0],
            [-79.902999586414523, 35.708440211240145, 0.0],
            [-79.900921319751035, 35.705257477911687, 0.0],
            [-79.910158186403407, 35.706011277910477, 0.0],
            [-79.90973638640412, 35.703996277913575, 0.0],
            [-79.915208919728855, 35.699094277921176, 0.0],
            [-79.913383919731757, 35.698110077922649, 0.0],
            [-79.914927119729327, 35.696163277925734, 0.0],
            [-79.914476786396733, 35.693209411263695, 0.0],
            [-79.917759586391639, 35.68991081126876, 0.0],
          ],
          [
            [-79.939300186358196, 35.705015877911933, 0.0],
            [-79.940138586356852, 35.699725677920298, 0.0],
            [-79.945131786349179, 35.694685211261458, 0.0],
            [-79.943053986352311, 35.693976477929198, 0.0],
            [-79.94271551968626, 35.691938477932354, 0.0],
            [-79.941059986355469, 35.692855277930903, 0.0],
            [-79.939683586357603, 35.691665211266127, 0.0],
            [-79.939175119691754, 35.686971011273329, 0.0],
          ],
          [
            [-80.32615198575769, 35.703202411248185, 0.0],
            [-80.333642319079445, 35.695327211260405, 0.0],
            [-80.334075719078726, 35.687905677938659, 0.0],
          ],
          [
            [-80.2501067192091, 35.715731477895474, 0.0],
            [-80.248909185877665, 35.704033611246984, 0.0],
            [-80.256481385865868, 35.701117811251379, 0.0],
            [-80.258535719196061, 35.698857277921604, 0.0],
            [-80.256124519199773, 35.697520011257097, 0.0],
            [-80.251094719207515, 35.698114011256166, 0.0],
            [-80.250840519207884, 35.694783477927899, 0.0],
            [-80.246201919215082, 35.687442077939352, 0.0],
          ],
          [
            [-80.607799118653872, 35.702658877915724, 0.0],
            [-80.605440185324142, 35.704422011246265, 0.0],
            [-80.602939185328125, 35.710925611236178, 0.0],
            [-80.599456518666784, 35.713375411232448, 0.0],
          ],
          [
            [-79.930347519705379, 35.71042407790361, 0.0],
            [-79.934558319698851, 35.70930027790547, 0.0],
          ],
          [
            [-80.439493118915152, 35.703672811247486, 0.0],
            [-80.435312585588235, 35.708026077907334, 0.0],
            [-80.431298118927884, 35.709218811238827, 0.0],
            [-80.429867918930086, 35.711967677901328, 0.0],
          ],
          [
            [-80.429867918930086, 35.711967677901328, 0.0],
            [-80.428548585598719, 35.712930011233084, 0.0],
          ],
          [
            [-80.428548585598719, 35.712930011233084, 0.0],
            [-80.428577185598783, 35.71366281123187, 0.0],
          ],
          [
            [-79.898592986421363, 35.715127611229661, 0.0],
            [-79.901090986417444, 35.710570211236757, 0.0],
          ],
          [
            [-80.359240985706265, 35.718384077891301, 0.0],
            [-80.336603785741488, 35.721455277886605, 0.0],
            [-80.334806319077643, 35.719441077889599, 0.0],
            [-80.334907585744133, 35.715905211228403, 0.0],
            [-80.325613985758594, 35.706553411243021, 0.0],
            [-80.32615198575769, 35.703202411248185, 0.0],
          ],
          [
            [-80.599456518666784, 35.713375411232448, 0.0],
            [-80.595045118673681, 35.719512011222946, 0.0],
            [-80.589173918682832, 35.723243211217039, 0.0],
          ],
          [
            [-80.428577185598783, 35.71366281123187, 0.0],
            [-80.427258718934127, 35.71586201122858, 0.0],
          ],
          [
            [-79.977251386299315, 35.722601077884747, 0.0],
            [-79.97699618629963, 35.720654677887865, 0.0],
            [-79.979885186295235, 35.71776707789229, 0.0],
            [-79.97591431963474, 35.707900477907629, 0.0],
          ],
          [
            [-80.379436585674966, 35.714097211231262, 0.0],
            [-80.362807119034187, 35.716450011227664, 0.0],
            [-80.359240985706265, 35.718384077891301, 0.0],
          ],
          [
            [-79.932460719702135, 35.722376811218396, 0.0],
            [-79.928384719708447, 35.714982011230006, 0.0],
            [-79.931107319704211, 35.713194677899423, 0.0],
            [-79.930347519705379, 35.71042407790361, 0.0],
          ],
          [
            [-80.392279718988448, 35.723154411217138, 0.0],
            [-80.387088718996438, 35.717648677892441, 0.0],
            [-80.379436585674966, 35.714097211231262, 0.0],
          ],
          [
            [-80.248253319211983, 35.723090211217368, 0.0],
            [-80.2501067192091, 35.715731477895474, 0.0],
          ],
          [
            [-80.726242118470054, 35.735392277864889, 0.0],
            [-80.717758385149864, 35.734643211199455, 0.0],
            [-80.715959118486012, 35.733133011201687, 0.0],
            [-80.706496518500671, 35.736345811196827, 0.0],
          ],
          [
            [-80.442272518910784, 35.734799611199094, 0.0],
            [-80.441789585578192, 35.734784277865913, 0.0],
          ],
          [
            [-80.57405791870616, 35.733108211201795, 0.0],
            [-80.567454385383144, 35.736700677862814, 0.0],
          ],
          [
            [-80.444383385574213, 35.73525421119848, 0.0],
            [-80.442272518910784, 35.734799611199094, 0.0],
          ],
          [
            [-80.706496518500671, 35.736345811196827, 0.0],
            [-80.702621518506703, 35.737951477860975, 0.0],
          ],
          [
            [-80.567454385383144, 35.736700677862814, 0.0],
            [-80.563858118722067, 35.737775211194617, 0.0],
          ],
          [
            [-80.75621551842346, 35.727771811210005, 0.0],
            [-80.752984385095203, 35.728524411208866, 0.0],
          ],
          [
            [-79.981387586292897, 35.729238611207734, 0.0],
            [-79.97947218629588, 35.724591477881745, 0.0],
          ],
          [
            [-80.589173918682832, 35.723243211217039, 0.0],
            [-80.583104985358887, 35.728142211209558, 0.0],
          ],
          [
            [-80.42663878560171, 35.716175811228084, 0.0],
            [-80.42453571893833, 35.717164677893265, 0.0],
            [-80.423597918939777, 35.724771877881437, 0.0],
            [-80.422341185608445, 35.72549261121361, 0.0],
          ],
          [
            [-79.938556986359345, 35.72601501121278, 0.0],
            [-79.936113786363137, 35.725947477879572, 0.0],
            [-79.932460719702135, 35.722376811218396, 0.0],
          ],
          [
            [-80.422341185608445, 35.72549261121361, 0.0],
            [-80.414611385620447, 35.723074211217295, 0.0],
          ],
          [
            [-80.24223991922122, 35.731412877871151, 0.0],
            [-80.248253319211983, 35.723090211217368, 0.0],
          ],
          [
            [-80.752984385095203, 35.728524411208866, 0.0],
            [-80.738227585118125, 35.729221211207914, 0.0],
          ],
          [
            [-80.738227585118125, 35.729221211207914, 0.0],
            [-80.731887918461325, 35.73531881119834, 0.0],
            [-80.726242118470054, 35.735392277864889, 0.0],
          ],
          [
            [-80.441789585578192, 35.734784277865913, 0.0],
            [-80.422341185608445, 35.72549261121361, 0.0],
          ],
          [
            [-80.583104985358887, 35.728142211209558, 0.0],
            [-80.57405791870616, 35.733108211201795, 0.0],
          ],
          [
            [-80.732013985127764, 35.764360677819923, 0.0],
            [-80.727800185134299, 35.765234477818524, 0.0],
          ],
          [
            [-80.792228585034252, 35.776309411134719, 0.0],
            [-80.790682785036665, 35.776377011134628, 0.0],
          ],
          [
            [-80.800996385020653, 35.776795677800578, 0.0],
            [-80.792228585034252, 35.776309411134719, 0.0],
          ],
          [
            [-80.790682785036665, 35.776377011134628, 0.0],
            [-80.787843985041036, 35.776741411134026, 0.0],
          ],
          [
            [-80.765849918408549, 35.768777211146357, 0.0],
            [-80.764813585076865, 35.766417411150087, 0.0],
            [-80.754722918425841, 35.768308811147222, 0.0],
          ],
          [
            [-80.754722918425841, 35.768308811147222, 0.0],
            [-80.751773385097067, 35.767664677814764, 0.0],
            [-80.746438118438732, 35.77043881114389, 0.0],
          ],
          [
            [-80.727800185134299, 35.765234477818524, 0.0],
            [-80.723166385141496, 35.767414077815204, 0.0],
          ],
          [
            [-80.746438118438732, 35.77043881114389, 0.0],
            [-80.735778918455253, 35.764105277820306, 0.0],
            [-80.732013985127764, 35.764360677819923, 0.0],
          ],
          [
            [-80.704369718503926, 35.769809677811452, 0.0],
            [-80.699199185178713, 35.769950411144691, 0.0],
          ],
          [
            [-80.723166385141496, 35.767414077815204, 0.0],
            [-80.716708518484779, 35.771953677808142, 0.0],
            [-80.712241185158405, 35.772827211140225, 0.0],
          ],
          [
            [-80.712241185158405, 35.772827211140225, 0.0],
            [-80.704369718503926, 35.769809677811452, 0.0],
          ],
          [
            [-80.787843985041036, 35.776741411134026, 0.0],
            [-80.782167918383266, 35.776004477801905, 0.0],
            [-80.775146785060826, 35.772311877807624, 0.0],
            [-80.770032518402104, 35.772193077807742, 0.0],
            [-80.769979718402169, 35.769605211145063, 0.0],
            [-80.765849918408549, 35.768777211146357, 0.0],
          ],
          [
            [-79.990262786279118, 35.762598877822654, 0.0],
            [-79.987364319616972, 35.75937287782773, 0.0],
            [-79.989828986279804, 35.754011877836035, 0.0],
            [-79.987030386284118, 35.749110477843487, 0.0],
            [-79.98499178628731, 35.750261011175212, 0.0],
            [-79.979023986296511, 35.748750877844145, 0.0],
          ],
          [
            [-80.665665585230727, 35.752103277838899, 0.0],
            [-80.661928718569811, 35.751646411172942, 0.0],
          ],
          [
            [-80.661928718569811, 35.751646411172942, 0.0],
            [-80.658866385241311, 35.751395411173348, 0.0],
            [-80.657462185243503, 35.752884411171124, 0.0],
            [-80.658249718575576, 35.754991077834461, 0.0],
            [-80.650609718587475, 35.76147407782446, 0.0],
            [-80.645832985261507, 35.761841411157263, 0.0],
            [-80.642489918600063, 35.764521277819711, 0.0],
          ],
          [
            [-80.236165585897425, 35.756163477832729, 0.0],
            [-80.234927385899312, 35.755028811167733, 0.0],
          ],
          [
            [-80.544883185418257, 35.755266611167485, 0.0],
            [-80.540750518757989, 35.757737211163544, 0.0],
            [-80.535579918766018, 35.758420411162547, 0.0],
          ],
          [
            [-80.234927385899312, 35.755028811167733, 0.0],
            [-80.236343185897113, 35.751288277840217, 0.0],
            [-80.238808719226597, 35.750858011174159, 0.0],
          ],
          [
            [-80.535579918766018, 35.758420411162547, 0.0],
            [-80.531839518771733, 35.761486277824417, 0.0],
          ],
          [
            [-80.239491785892142, 35.763877877820676, 0.0],
            [-80.239120585892692, 35.759664611160474, 0.0],
            [-80.236165585897425, 35.756163477832729, 0.0],
          ],
          [
            [-79.998692386266043, 35.763208877821796, 0.0],
            [-79.996611386269251, 35.761905677823734, 0.0],
            [-79.99762491960098, 35.76325581115492, 0.0],
          ],
          [
            [-79.998692386266043, 35.763208877821796, 0.0],
            [-79.99762491960098, 35.76325581115492, 0.0],
          ],
          [
            [-80.531839518771733, 35.761486277824417, 0.0],
            [-80.527931118777872, 35.763842077820755, 0.0],
            [-80.525656785448007, 35.762443211156267, 0.0],
            [-80.521187785455027, 35.763469877821365, 0.0],
          ],
          [
            [-80.521187785455027, 35.763469877821365, 0.0],
            [-80.515484718797154, 35.762800677822327, 0.0],
          ],
          [
            [-79.99762491960098, 35.76325581115492, 0.0],
            [-79.995801319603856, 35.765021011152328, 0.0],
            [-79.990262786279118, 35.762598877822654, 0.0],
          ],
          [
            [-79.999763586264351, 35.764326677819952, 0.0],
            [-79.998692386266043, 35.763208877821796, 0.0],
          ],
          [
            [-80.004346919590603, 35.765038211152159, 0.0],
            [-79.999763586264351, 35.764326677819952, 0.0],
          ],
          [
            [-80.642489918600063, 35.764521277819711, 0.0],
            [-80.629901785286279, 35.767934811147711, 0.0],
          ],
          [
            [-80.487820785506813, 35.766258411150261, 0.0],
            [-80.486641518841964, 35.766877811149243, 0.0],
            [-80.486733985508465, 35.772832077806754, 0.0],
            [-80.484714785511585, 35.775788077802133, 0.0],
            [-80.478615585521084, 35.77499201113676, 0.0],
            [-80.471637985531913, 35.768196077813968, 0.0],
            [-80.468702985536424, 35.757389011164037, 0.0],
            [-80.462205718879829, 35.750867211174238, 0.0],
          ],
          [
            [-80.515484718797154, 35.762800677822327, 0.0],
            [-80.509809585472624, 35.762314611156455, 0.0],
            [-80.505985385478539, 35.764234477820139, 0.0],
            [-80.499784985488134, 35.762445077822917, 0.0],
            [-80.487820785506813, 35.766258411150261, 0.0],
          ],
          [
            [-79.99145491961059, 35.771025011143024, 0.0],
            [-79.99198458627643, 35.768139077814055, 0.0],
            [-79.993614919607239, 35.768549611146739, 0.0],
            [-79.99425778627284, 35.766304877816822, 0.0],
            [-79.990262786279118, 35.762598877822654, 0.0],
          ],
          [
            [-80.235540185898401, 35.771164011142787, 0.0],
            [-80.239491785892142, 35.763877877820676, 0.0],
          ],
          [
            [-80.015742786239514, 35.773247411139494, 0.0],
            [-80.011136319580032, 35.771914811141642, 0.0],
            [-80.004346919590603, 35.765038211152159, 0.0],
          ],
          [
            [-80.699199185178713, 35.769950411144691, 0.0],
            [-80.69481631851886, 35.770891877809845, 0.0],
            [-80.690941785191455, 35.775199611136316, 0.0],
            [-80.687851185196337, 35.776117277801632, 0.0],
          ],
          [
            [-80.812263385003178, 35.779893611129125, 0.0],
            [-80.811700985004052, 35.780259677795357, 0.0],
          ],
          [
            [-80.589032318683053, 35.793395611108167, 0.0],
            [-80.577958318700155, 35.791559677777627, 0.0],
          ],
          [
            [-79.998395786266428, 35.789681277780687, 0.0],
            [-79.996202786269919, 35.78917981111482, 0.0],
            [-79.99937258626494, 35.785564811120366, 0.0],
          ],
          [
            [-80.836849584965023, 35.790644077779177, 0.0],
            [-80.828811984977506, 35.788374211115979, 0.0],
            [-80.817265384995324, 35.780445611128243, 0.0],
            [-80.812263385003178, 35.779893611129125, 0.0],
          ],
          [
            [-80.04115858620014, 35.786939211118238, 0.0],
            [-80.038796719537117, 35.787945077783206, 0.0],
            [-80.033739986211629, 35.786017811119621, 0.0],
          ],
          [
            [-79.999763319597719, 35.793638477774493, 0.0],
            [-79.998395786266428, 35.789681277780687, 0.0],
          ],
          [
            [-80.577958318700155, 35.791559677777627, 0.0],
            [-80.57540091870419, 35.790917411112048, 0.0],
            [-80.573263185374117, 35.793046277775431, 0.0],
            [-80.567476518716433, 35.787890877783354, 0.0],
          ],
          [
            [-80.535711118765676, 35.791673011110959, 0.0],
            [-80.533631718769016, 35.791190477778173, 0.0],
            [-80.533946385435172, 35.786381611119168, 0.0],
          ],
          [
            [-80.871871918243926, 35.806451477754649, 0.0],
            [-80.858518318264714, 35.803839877758662, 0.0],
          ],
          [
            [-80.85500491827014, 35.801045477762955, 0.0],
            [-80.854218118271376, 35.800037677764522, 0.0],
          ],
          [
            [-80.858518318264714, 35.803839877758662, 0.0],
            [-80.85500491827014, 35.801045477762955, 0.0],
          ],
          [
            [-79.974346319637164, 35.819930877733782, 0.0],
            [-79.977833919631735, 35.820889677732282, 0.0],
            [-79.983707919622589, 35.819121411068352, 0.0],
          ],
          [
            [-80.59332171867635, 35.82083241106568, 0.0],
            [-80.59053938534737, 35.817556811070688, 0.0],
            [-80.587248718685828, 35.819456477734491, 0.0],
            [-80.589834585348399, 35.821678677730915, 0.0],
            [-80.584660185356483, 35.822707677729397, 0.0],
            [-80.570379918711978, 35.816472811072458, 0.0],
            [-80.56900418538072, 35.813472211076999, 0.0],
          ],
          [
            [-79.983707919622589, 35.819121411068352, 0.0],
            [-79.989136519614192, 35.820696611065785, 0.0],
          ],
          [
            [-80.875161184905551, 35.809108011083765, 0.0],
            [-80.871871918243926, 35.806451477754649, 0.0],
          ],
          [
            [-80.878956718232985, 35.80952007774988, 0.0],
            [-80.875161184905551, 35.809108011083765, 0.0],
          ],
          [
            [-80.56900418538072, 35.813472211076999, 0.0],
            [-80.564619985387537, 35.811065477747377, 0.0],
          ],
          [
            [-79.989136519614192, 35.820696611065785, 0.0],
            [-79.992615786275451, 35.815724011073598, 0.0],
            [-79.989774986279883, 35.814879477741613, 0.0],
            [-79.994722319605444, 35.814347877742364, 0.0],
            [-79.994775786272101, 35.81256181107841, 0.0],
            [-79.999044319598795, 35.80935147775017, 0.0],
          ],
          [
            [-80.228492985909327, 35.819513277734359, 0.0],
            [-80.228012119243374, 35.81731507773776, 0.0],
            [-80.222805385918093, 35.81299141107786, 0.0],
          ],
          [
            [-80.562483785390839, 35.810377277748501, 0.0],
            [-80.560938785393319, 35.808773411084303, 0.0],
          ],
          [
            [-80.561949385391756, 35.810514477748313, 0.0],
            [-80.560938785393319, 35.808773411084303, 0.0],
          ],
          [
            [-80.562483785390839, 35.810377277748501, 0.0],
            [-80.561949385391756, 35.810514477748313, 0.0],
          ],
          [
            [-80.564619985387537, 35.811065477747377, 0.0],
            [-80.562483785390839, 35.810377277748501, 0.0],
          ],
          [
            [-80.589846585348425, 35.795250877772048, 0.0],
            [-80.589032318683053, 35.793395611108167, 0.0],
          ],
          [
            [-79.999763186264374, 35.795882611104332, 0.0],
            [-79.999763319597719, 35.793638477774493, 0.0],
          ],
          [
            [-79.999279986265094, 35.798084611100876, 0.0],
            [-79.999763186264374, 35.795882611104332, 0.0],
          ],
          [
            [-80.470697385533299, 35.800004811097949, 0.0],
            [-80.474456118860815, 35.797522477768496, 0.0],
            [-80.476911385523692, 35.792523811109561, 0.0],
          ],
          [
            [-80.844857918285868, 35.797425077768594, 0.0],
            [-80.839207384961355, 35.798065011100903, 0.0],
            [-80.836849584965023, 35.790644077779177, 0.0],
          ],
          [
            [-80.470241985534074, 35.799457211098854, 0.0],
            [-80.473457785529092, 35.785622411120301, 0.0],
          ],
          [
            [-80.558776585396629, 35.805474477756206, 0.0],
            [-80.554395385403438, 35.800548211097066, 0.0],
            [-80.547200718747945, 35.798643011100012, 0.0],
            [-80.542962185421118, 35.792571411109407, 0.0],
            [-80.537286718763369, 35.79039167777961, 0.0],
            [-80.535711118765676, 35.791673011110959, 0.0],
          ],
          [
            [-80.233789919234368, 35.806684411087531, 0.0],
            [-80.236093519230735, 35.805606011089253, 0.0],
            [-80.237241585895674, 35.802307277760974, 0.0],
            [-80.234422185900144, 35.796241211103791, 0.0],
            [-80.232312385903413, 35.794937677772566, 0.0],
          ],
          [
            [-79.997461386267901, 35.803674011092255, 0.0],
            [-79.999279986265094, 35.798084611100876, 0.0],
          ],
          [
            [-80.854218118271376, 35.800037677764522, 0.0],
            [-80.844857918285868, 35.797425077768594, 0.0],
          ],
          [
            [-80.637464918607861, 35.796492077770097, 0.0],
            [-80.633754385280156, 35.797523011101873, 0.0],
            [-80.63189931861649, 35.799973611098039, 0.0],
            [-80.623268918629833, 35.801782877761809, 0.0],
            [-80.617955985304718, 35.799653011098485, 0.0],
            [-80.612529985313188, 35.802835677760299, 0.0],
            [-80.602298585329038, 35.797200477769024, 0.0],
            [-80.596816785337523, 35.798161211100819, 0.0],
            [-80.589846585348425, 35.795250877772048, 0.0],
          ],
          [
            [-80.470478785533714, 35.799912611098136, 0.0],
            [-80.470241985534074, 35.799457211098854, 0.0],
          ],
          [
            [-80.470478785533714, 35.799912611098136, 0.0],
            [-80.470697385533299, 35.800004811097949, 0.0],
          ],
          [
            [-79.999044319598795, 35.80935147775017, 0.0],
            [-79.997461386267901, 35.803674011092255, 0.0],
          ],
          [
            [-80.464970185542256, 35.807423211086473, 0.0],
            [-80.465113185542009, 35.802119477761266, 0.0],
            [-80.470478785533714, 35.799912611098136, 0.0],
          ],
          [
            [-80.222805385918093, 35.81299141107786, 0.0],
            [-80.224012185916251, 35.811135411080613, 0.0],
            [-80.231009919238772, 35.809228677750298, 0.0],
            [-80.231483185904608, 35.80572461108909, 0.0],
            [-80.233789919234368, 35.806684411087531, 0.0],
          ],
          [
            [-80.560938785393319, 35.808773411084303, 0.0],
            [-80.558776585396629, 35.805474477756206, 0.0],
          ],
          [
            [-80.561949385391756, 35.810514477748313, 0.0],
            [-80.558776585396629, 35.805474477756206, 0.0],
          ],
          [
            [-80.811222985004804, 35.780419811128411, 0.0],
            [-80.800996385020653, 35.776795677800578, 0.0],
          ],
          [
            [-80.518072985459867, 35.781376011126895, 0.0],
            [-80.513721718799957, 35.777822277799032, 0.0],
          ],
          [
            [-80.022534119562351, 35.780100411128842, 0.0],
            [-80.015742786239514, 35.773247411139494, 0.0],
          ],
          [
            [-80.687851185196337, 35.776117277801632, 0.0],
            [-80.680795918540525, 35.774311611137819, 0.0],
            [-80.668995718558847, 35.779675211129529, 0.0],
          ],
          [
            [-80.513721718799957, 35.777822277799032, 0.0],
            [-80.507824185475783, 35.774908277803604, 0.0],
            [-80.501713318818588, 35.783100677790799, 0.0],
          ],
          [
            [-79.999401319598235, 35.785535411120406, 0.0],
            [-79.994734986272192, 35.784853211121515, 0.0],
            [-79.995883386270407, 35.782333077792089, 0.0],
            [-79.993681719607082, 35.775671477802405, 0.0],
            [-79.994859186271981, 35.773952811138315, 0.0],
            [-79.990419719612191, 35.774163277804632, 0.0],
            [-79.991851319609907, 35.773016811139769, 0.0],
            [-79.99145491961059, 35.771025011143024, 0.0],
          ],
          [
            [-80.241059985889819, 35.77963207779618, 0.0],
            [-80.242492585887476, 35.779127011130356, 0.0],
            [-80.242094585888196, 35.776058677801757, 0.0],
            [-80.237033385896098, 35.773956611138317, 0.0],
            [-80.235540185898401, 35.771164011142787, 0.0],
          ],
          [
            [-80.501713318818588, 35.783100677790799, 0.0],
            [-80.495796985494337, 35.782350211125276, 0.0],
            [-80.492448318832942, 35.779399277796642, 0.0],
            [-80.487820785506813, 35.766258411150261, 0.0],
          ],
          [
            [-80.629901785286279, 35.767934811147711, 0.0],
            [-80.625911518625799, 35.767751677814715, 0.0],
            [-80.628328318622039, 35.771164011142787, 0.0],
            [-80.627091918623933, 35.773248011139515, 0.0],
            [-80.624281585294909, 35.77262967780706, 0.0],
            [-80.6247875186275, 35.77132441114253, 0.0],
            [-80.619363785302539, 35.770912011143082, 0.0],
            [-80.615850518641309, 35.776682811134151, 0.0],
            [-80.612168985313758, 35.776499211134421, 0.0],
            [-80.610538518649605, 35.779384677796656, 0.0],
            [-80.606041918656615, 35.778811411130903, 0.0],
            [-80.606490385322559, 35.783872677789645, 0.0],
            [-80.598930385334313, 35.782657611124932, 0.0],
          ],
          [
            [-80.473786918861947, 35.785395277787359, 0.0],
            [-80.454957785557781, 35.773024877806563, 0.0],
          ],
          [
            [-79.99937258626494, 35.785564811120366, 0.0],
            [-79.999401319598235, 35.785535411120406, 0.0],
          ],
          [
            [-80.668995718558847, 35.779675211129529, 0.0],
            [-80.659721518573292, 35.780319611128505, 0.0],
          ],
          [
            [-80.811700985004052, 35.780259677795357, 0.0],
            [-80.811222985004804, 35.780419811128411, 0.0],
          ],
          [
            [-80.234884519232764, 35.784790477788306, 0.0],
            [-80.238756119226764, 35.780046477795622, 0.0],
            [-80.241059985889819, 35.77963207779618, 0.0],
          ],
          [
            [-80.033739986211629, 35.786017811119621, 0.0],
            [-80.034783319543351, 35.783087411124257, 0.0],
            [-80.031528586215074, 35.778504877797957, 0.0],
            [-80.028349186219884, 35.781708277792973, 0.0],
            [-80.025990919556932, 35.77996601112909, 0.0],
            [-80.022534119562351, 35.780100411128842, 0.0],
          ],
          [
            [-80.551713918740973, 35.782019611125804, 0.0],
            [-80.551404918741412, 35.781790411126167, 0.0],
          ],
          [
            [-80.55264031873952, 35.783027811124327, 0.0],
            [-80.551713918740973, 35.782019611125804, 0.0],
          ],
          [
            [-80.55264031873952, 35.783027811124327, 0.0],
            [-80.551713918740973, 35.782019611125804, 0.0],
          ],
          [
            [-80.52301491878552, 35.784838277788197, 0.0],
            [-80.520654385455828, 35.784561411121899, 0.0],
            [-80.518072985459867, 35.781376011126895, 0.0],
          ],
          [
            [-80.551404918741412, 35.781790411126167, 0.0],
            [-80.547891718746769, 35.781879811126146, 0.0],
            [-80.545949518749921, 35.784809811121477, 0.0],
            [-80.537410185429849, 35.780338277795124, 0.0],
            [-80.535272585433177, 35.781848211126203, 0.0],
            [-80.535830585432279, 35.785535611120395, 0.0],
            [-80.533946385435172, 35.786381611119168, 0.0],
          ],
          [
            [-80.533946385435172, 35.786381611119168, 0.0],
            [-80.528468785443636, 35.783766477789754, 0.0],
            [-80.52301491878552, 35.784838277788197, 0.0],
          ],
          [
            [-80.473457785529092, 35.785622411120301, 0.0],
            [-80.473786918861947, 35.785395277787359, 0.0],
          ],
          [
            [-80.598930385334313, 35.782657611124932, 0.0],
            [-80.595472318673046, 35.785382011120646, 0.0],
            [-80.596455385338174, 35.787191411117817, 0.0],
            [-80.592406318677718, 35.791014877778593, 0.0],
            [-80.5918993186786, 35.793488077774668, 0.0],
            [-80.589032318683053, 35.793395611108167, 0.0],
          ],
          [
            [-80.567476518716433, 35.787890877783354, 0.0],
            [-80.567135385383665, 35.79304347777537, 0.0],
            [-80.563930718721963, 35.793591477774498, 0.0],
            [-80.561767585391976, 35.791758277777376, 0.0],
            [-80.56131611872604, 35.79400227777387, 0.0],
            [-80.559488918728903, 35.794138811107132, 0.0],
            [-80.557045518732707, 35.791687011110866, 0.0],
            [-80.555000185402491, 35.784311677788992, 0.0],
            [-80.553818918737591, 35.785158277787673, 0.0],
            [-80.55264031873952, 35.783027811124327, 0.0],
          ],
          [
            [-80.476911385523692, 35.792523811109561, 0.0],
            [-80.476672385524012, 35.789070677781524, 0.0],
            [-80.473786918861947, 35.785395277787359, 0.0],
          ],
          [
            [-80.659721518573292, 35.780319611128505, 0.0],
            [-80.654523318581312, 35.78357287779005, 0.0],
            [-80.648451918590808, 35.781925411125997, 0.0],
            [-80.648425318590853, 35.787055211118059, 0.0],
            [-80.644687185263251, 35.787834477783463, 0.0],
            [-80.644209985264069, 35.790605677779183, 0.0],
            [-80.638420118606405, 35.792988211108764, 0.0],
            [-80.637464918607861, 35.796492077770097, 0.0],
          ],
          [
            [-80.232312385903413, 35.794937677772566, 0.0],
            [-80.235788585898035, 35.788293477782759, 0.0],
            [-80.234884519232764, 35.784790477788306, 0.0],
          ],
          [
            [-80.457898918886485, 35.741096277856116, 0.0],
            [-80.45334231889359, 35.734791077865907, 0.0],
            [-80.444383385574213, 35.73525421119848, 0.0],
          ],
          [
            [-80.563858118722067, 35.737775211194617, 0.0],
            [-80.559474718728779, 35.739559211191761, 0.0],
          ],
          [
            [-80.702621518506703, 35.737951477860975, 0.0],
            [-80.696053385183518, 35.743635211185506, 0.0],
          ],
          [
            [-79.939071519691879, 35.73881561119299, 0.0],
            [-79.935947986363431, 35.730000677873306, 0.0],
            [-79.938556986359345, 35.72601501121278, 0.0],
          ],
          [
            [-79.976522519633761, 35.747676677845845, 0.0],
            [-79.979694919628798, 35.746094077848284, 0.0],
            [-79.982188786291658, 35.741168477855865, 0.0],
            [-79.981387586292897, 35.729238611207734, 0.0],
          ],
          [
            [-80.243643785885808, 35.741363277855669, 0.0],
            [-80.245112919216808, 35.734333477866414, 0.0],
            [-80.24223991922122, 35.731412877871151, 0.0],
          ],
          [
            [-79.93517191969795, 35.745572811182399, 0.0],
            [-79.938007119693509, 35.743075411186226, 0.0],
            [-79.935253319697836, 35.741473677855367, 0.0],
            [-79.939071519691879, 35.73881561119299, 0.0],
          ],
          [
            [-80.696053385183518, 35.743635211185506, 0.0],
            [-80.687489918530218, 35.749708811176049, 0.0],
            [-80.682488118538004, 35.748176677845095, 0.0],
          ],
          [
            [-80.559474718728779, 35.739559211191761, 0.0],
            [-80.554020118737355, 35.744960811183432, 0.0],
          ],
          [
            [-79.979023986296511, 35.748750877844145, 0.0],
            [-79.976522519633761, 35.747676677845845, 0.0],
          ],
          [
            [-80.554020118737355, 35.744960811183432, 0.0],
            [-80.554131118737132, 35.746518211181012, 0.0],
          ],
          [
            [-80.459757318883646, 35.745904277848581, 0.0],
            [-80.457898918886485, 35.741096277856116, 0.0],
          ],
          [
            [-80.238808719226597, 35.750858011174159, 0.0],
            [-80.241609919222299, 35.748063811178497, 0.0],
            [-80.243643785885808, 35.741363277855669, 0.0],
          ],
          [
            [-80.682488118538004, 35.748176677845095, 0.0],
            [-80.68321591853686, 35.744283077851037, 0.0],
            [-80.681726718539039, 35.743986011184859, 0.0],
            [-80.678216918544592, 35.746461011181111, 0.0],
            [-80.666839585228843, 35.746992077846926, 0.0],
            [-80.665665585230727, 35.752103277838899, 0.0],
          ],
          [
            [-80.554131118737132, 35.746518211181012, 0.0],
            [-80.546572318748872, 35.751878277839296, 0.0],
            [-80.544883185418257, 35.755266611167485, 0.0],
          ],
          [
            [-80.462205718879829, 35.750867211174238, 0.0],
            [-80.459757318883646, 35.745904277848581, 0.0],
          ],
          [
            [-80.454957785557781, 35.773024877806563, 0.0],
            [-80.450758585564358, 35.766513677816533, 0.0],
            [-80.452566585561442, 35.758514811162343, 0.0],
            [-80.457206718887562, 35.75443867783531, 0.0],
            [-80.459757318883646, 35.745904277848581, 0.0],
          ],
          [
            [-80.340288319069089, 35.660890011313825, 0.0],
            [-80.337579119073268, 35.660356811314614, 0.0],
          ],
          [
            [-80.337579119073268, 35.660356811314614, 0.0],
            [-80.332448919081287, 35.658405011317825, 0.0],
            [-80.327909785754969, 35.659701211315792, 0.0],
            [-80.314290185776144, 35.651417877995243, 0.0],
            [-80.302955319127079, 35.652692811326574, 0.0],
          ],
          [
            [-80.313907719110091, 35.671524011297322, 0.0],
            [-80.306776519121172, 35.66474007797467, 0.0],
            [-80.306007119122341, 35.656270011321112, 0.0],
            [-80.302955319127079, 35.652692811326574, 0.0],
          ],
          [
            [-79.944117586350728, 35.654359477990624, 0.0],
            [-79.946276186347404, 35.652022477994251, 0.0],
            [-79.947877386344885, 35.654288677990792, 0.0],
            [-79.955958119665695, 35.654466877990444, 0.0],
            [-79.957587786329782, 35.65693921131998, 0.0],
            [-79.958398386328554, 35.653709677991742, 0.0],
          ],
          [
            [-80.242008985888333, 35.669636611300291, 0.0],
            [-80.24591598588222, 35.659256611316493, 0.0],
            [-80.249303785876918, 35.655907277988319, 0.0],
            [-80.2526683858718, 35.648877811332454, 0.0],
            [-80.253313185870809, 35.643512211340919, 0.0],
            [-80.260801585859156, 35.641726678010286, 0.0],
            [-80.261238719191795, 35.639629811346879, 0.0],
            [-80.25708831919826, 35.634509078021551, 0.0],
            [-80.257911985863586, 35.629040678030037, 0.0],
            [-80.256657919198858, 35.625956411368065, 0.0],
          ],
          [
            [-79.97204318630736, 35.661348477979857, 0.0],
            [-79.970443386309796, 35.661074877980298, 0.0],
            [-79.968819186312373, 35.664030211308955, 0.0],
            [-79.966574786315846, 35.664466877974974, 0.0],
            [-79.967329986314724, 35.662291011311765, 0.0],
            [-79.960338919658852, 35.658334211317822, 0.0],
            [-79.961600586323584, 35.657211211319577, 0.0],
            [-79.958398386328554, 35.653709677991742, 0.0],
          ],
          [
            [-80.359793319038829, 35.665344411307046, 0.0],
            [-80.345326319061314, 35.663417611309967, 0.0],
            [-80.340288319069089, 35.660890011313825, 0.0],
          ],
          [
            [-79.972219919640452, 35.66867627796853, 0.0],
            [-79.975246186302343, 35.664758011307867, 0.0],
            [-79.97204318630736, 35.661348477979857, 0.0],
          ],
          [
            [-80.368185519025758, 35.671404877964278, 0.0],
            [-80.359793319038829, 35.665344411307046, 0.0],
          ],
          [
            [-80.379452185675007, 35.677339811288221, 0.0],
            [-80.377757385677626, 35.675593411291004, 0.0],
            [-80.37278038568536, 35.675185077958361, 0.0],
            [-80.368185519025758, 35.671404877964278, 0.0],
          ],
          [
            [-80.332328919081476, 35.681841477947955, 0.0],
            [-80.32642651909066, 35.675223011291621, 0.0],
            [-80.313907719110091, 35.671524011297322, 0.0],
          ],
          [
            [-79.939175119691754, 35.686971011273329, 0.0],
            [-79.937877319693769, 35.677628677954658, 0.0],
            [-79.944727186349724, 35.679983877950917, 0.0],
            [-79.944583719683351, 35.676022277957088, 0.0],
            [-79.94702451967953, 35.674944677958706, 0.0],
            [-79.946209919680769, 35.674075011293439, 0.0],
            [-79.949069919676276, 35.670936077964882, 0.0],
            [-79.946849319679757, 35.666540611305095, 0.0],
            [-79.948671586343664, 35.664615877974711, 0.0],
            [-79.946339919680611, 35.661434277979652, 0.0],
            [-79.948191186344388, 35.66056301131448, 0.0],
            [-79.945833319681412, 35.659671211315811, 0.0],
            [-79.944989119682702, 35.656442877987502, 0.0],
            [-79.946840519679824, 35.656029611321458, 0.0],
            [-79.944117586350728, 35.654359477990624, 0.0],
          ],
          [
            [-79.966484986315891, 35.685534677942314, 0.0],
            [-79.970297786310084, 35.681478677948689, 0.0],
            [-79.966445319649381, 35.674726077959122, 0.0],
            [-79.972219919640452, 35.66867627796853, 0.0],
          ],
          [
            [-80.246201919215082, 35.687442077939352, 0.0],
            [-80.252032185872793, 35.678461011286686, 0.0],
            [-80.242008985888333, 35.669636611300291, 0.0],
          ],
          [
            [-79.926330186378323, 35.202700412025138, 0.0],
            [-79.929399386373518, 35.202722078691806, 0.0],
          ],
          [
            [-79.933807786366742, 35.20267441202509, 0.0],
            [-79.935007386364873, 35.202216012025872, 0.0],
          ],
          [
            [-80.065685986162066, 35.205274612021014, 0.0],
            [-80.061419186168564, 35.200124078695694, 0.0],
          ],
          [
            [-80.489670985503949, 35.20983201201409, 0.0],
            [-80.496220118827068, 35.203871012023228, 0.0],
            [-80.499114185489191, 35.198485812031663, 0.0],
            [-80.505430785479462, 35.194548412037705, 0.0],
            [-80.506298918811467, 35.192419412040977, 0.0],
            [-80.504157518814736, 35.187836612048159, 0.0],
            [-80.505249118813026, 35.185432812051943, 0.0],
          ],
          [
            [-80.250942785874486, 35.208926012015468, 0.0],
            [-80.251174919207415, 35.203246612024316, 0.0],
            [-80.241307985889364, 35.189654278711885, 0.0],
          ],
          [
            [-80.28436378582262, 35.19216887870806, 0.0],
            [-80.28182078582654, 35.195716812035982, 0.0],
            [-80.275402985836536, 35.196330012034991, 0.0],
            [-80.271210919176326, 35.201754212026572, 0.0],
            [-80.265655785851607, 35.203627212023662, 0.0],
            [-80.263807319187777, 35.208549412015941, 0.0],
            [-80.253311519204146, 35.210989612012156, 0.0],
          ],
          [
            [-79.926330186378323, 35.202700412025138, 0.0],
            [-79.929399386373518, 35.202722078691806, 0.0],
          ],
          [
            [-79.929399386373518, 35.202722078691806, 0.0],
            [-79.933807786366742, 35.20267441202509, 0.0],
          ],
          [
            [-80.066714452827114, 35.206531878685837, 0.0],
            [-80.065685986162066, 35.205274612021014, 0.0],
          ],
          [
            [-80.478205785521652, 35.235951478640231, 0.0],
            [-80.47792198552213, 35.232104011979402, 0.0],
            [-80.482637318848163, 35.230473878648695, 0.0],
            [-80.486952585508163, 35.222202078661496, 0.0],
            [-80.483061918847511, 35.213433678675131, 0.0],
            [-80.489670985503949, 35.20983201201409, 0.0],
          ],
          [
            [-80.253311519204146, 35.210989612012156, 0.0],
            [-80.250942785874486, 35.208926012015468, 0.0],
          ],
          [
            [-79.911436119734731, 35.217933878668077, 0.0],
            [-79.911015986402106, 35.213926478674352, 0.0],
            [-79.909229719738164, 35.212530012009722, 0.0],
            [-79.910567786402737, 35.208453412016183, 0.0],
            [-79.926330186378323, 35.202700412025138, 0.0],
          ],
          [
            [-80.254174185869488, 35.212502012009907, 0.0],
            [-80.253311519204146, 35.210989612012156, 0.0],
          ],
          [
            [-80.07311338615051, 35.219357078665951, 0.0],
            [-80.066714452827114, 35.206531878685837, 0.0],
          ],
          [
            [-79.964019386319819, 35.838212477705383, 0.0],
            [-79.963904786320029, 35.836174477708539, 0.0],
          ],
          [
            [-79.966721919648933, 35.84068367770152, 0.0],
            [-79.964019386319819, 35.838212477705383, 0.0],
          ],
          [
            [-80.707882985165156, 35.852961677682288, 0.0],
            [-80.701523385175051, 35.850721477685966, 0.0],
          ],
          [
            [-80.671675785221396, 35.846637411025654, 0.0],
            [-80.660648718571849, 35.844717411028569, 0.0],
            [-80.654939118580728, 35.845543411027279, 0.0],
          ],
          [
            [-80.469724518868247, 35.837587677706267, 0.0],
            [-80.470200318867455, 35.837386411039859, 0.0],
          ],
          [
            [-80.642365785266918, 35.844194611029366, 0.0],
            [-80.636543585275945, 35.845225811027831, 0.0],
          ],
          [
            [-80.462639785545889, 35.839974611035871, 0.0],
            [-80.468689118869861, 35.842054211032575, 0.0],
            [-80.46784211887109, 35.839237877703738, 0.0],
            [-80.469724518868247, 35.837587677706267, 0.0],
          ],
          [
            [-80.192719719298168, 35.843285677697395, 0.0],
            [-80.193700785963301, 35.839346277703442, 0.0],
            [-80.200139119286689, 35.837006811040567, 0.0],
          ],
          [
            [-79.97727391963258, 35.845209477694425, 0.0],
            [-79.974148319637379, 35.842212077699173, 0.0],
            [-79.972293119640312, 35.842969277697875, 0.0],
            [-79.971362786308418, 35.841069477700898, 0.0],
            [-79.966721919648933, 35.84068367770152, 0.0],
          ],
          [
            [-80.464527785542941, 35.843271011030765, 0.0],
            [-80.462639785545889, 35.839974611035871, 0.0],
          ],
          [
            [-80.654939118580728, 35.845543411027279, 0.0],
            [-80.650073318588284, 35.846414677692508, 0.0],
            [-80.642365785266918, 35.844194611029366, 0.0],
          ],
          [
            [-80.38961538565917, 35.843843011029946, 0.0],
            [-80.394857318984464, 35.84149587770014, 0.0],
            [-80.400179385642843, 35.841820611032972, 0.0],
            [-80.407382518964937, 35.84530907769431, 0.0],
            [-80.412041985624455, 35.849601077687566, 0.0],
          ],
          [
            [-80.477761118855767, 35.854367477680114, 0.0],
            [-80.472518918863898, 35.846012877693227, 0.0],
            [-80.466695585539526, 35.845124277694651, 0.0],
            [-80.464527785542941, 35.843271011030765, 0.0],
          ],
          [
            [-80.412041985624455, 35.849601077687566, 0.0],
            [-80.41367711895515, 35.851274877685, 0.0],
          ],
          [
            [-80.701523385175051, 35.850721477685966, 0.0],
            [-80.699244985178552, 35.850745877685824, 0.0],
            [-80.696576118516077, 35.854617677679869, 0.0],
            [-80.693227985187946, 35.853932611014272, 0.0],
            [-80.69194171852331, 35.863437277666208, 0.0],
          ],
          [
            [-80.715348785153594, 35.863078611000049, 0.0],
            [-80.711040518493633, 35.859417477672366, 0.0],
            [-80.711514985159511, 35.855982011011008, 0.0],
            [-80.707069585166494, 35.855137811012355, 0.0],
            [-80.707882985165156, 35.852961677682288, 0.0],
          ],
          [
            [-80.69194171852331, 35.863437277666208, 0.0],
            [-80.683300985203346, 35.858472211007268, 0.0],
          ],
          [
            [-80.683300985203346, 35.858472211007268, 0.0],
            [-80.67677291854676, 35.856024811010968, 0.0],
            [-80.674270185217381, 35.857743411008414, 0.0],
            [-80.672131118554034, 35.855957877677781, 0.0],
            [-80.668924385225637, 35.856302677677206, 0.0],
            [-80.669794118557661, 35.852042811017043, 0.0],
            [-80.672887118552808, 35.849774277687345, 0.0],
            [-80.671675785221396, 35.846637411025654, 0.0],
          ],
          [
            [-80.495357118828395, 35.864198677664888, 0.0],
            [-80.491835318833921, 35.860629611003844, 0.0],
            [-80.487927785506599, 35.862763077667296, 0.0],
            [-80.484290118845593, 35.856308277677101, 0.0],
            [-80.479869985519088, 35.853610011014723, 0.0],
            [-80.477761118855767, 35.854367477680114, 0.0],
          ],
          [
            [-79.982100319625033, 35.858303677674201, 0.0],
            [-79.980916386293586, 35.856335411010548, 0.0],
            [-79.97723291963257, 35.85748347767543, 0.0],
            [-79.979984519628317, 35.853428011014955, 0.0],
            [-79.978798986296908, 35.850246011019919, 0.0],
            [-79.97632231963405, 35.848965677688625, 0.0],
            [-79.97842918629749, 35.846925877691831, 0.0],
            [-79.97727391963258, 35.845209477694425, 0.0],
          ],
          [
            [-80.192088719299136, 35.8627512110005, 0.0],
            [-80.1925073859652, 35.858743477673443, 0.0],
            [-80.197089319291365, 35.854916611012698, 0.0],
            [-80.200139119286689, 35.837006811040567, 0.0],
          ],
          [
            [-80.618174985304449, 35.858989677673037, 0.0],
            [-80.618175385304482, 35.855096411012482, 0.0],
            [-80.626305185291812, 35.849875277687147, 0.0],
            [-80.618401185304094, 35.846898011025189, 0.0],
            [-80.612354785313528, 35.841034677700861, 0.0],
            [-80.610441785316425, 35.842546011031914, 0.0],
          ],
          [
            [-80.167729986003678, 35.870433410988653, 0.0],
            [-80.176139985990574, 35.866445677661488, 0.0],
            [-80.178160785987473, 35.858887811006639, 0.0],
            [-80.181675919315353, 35.857100077676023, 0.0],
            [-80.181195985982754, 35.85469581101313, 0.0],
            [-80.184936185976824, 35.853182677682128, 0.0],
            [-80.184231719311356, 35.85137381101822, 0.0],
            [-80.189266585970245, 35.85139427768479, 0.0],
            [-80.190978985967433, 35.847042277691685, 0.0],
            [-80.189880519302619, 35.845279611027706, 0.0],
            [-80.192719719298168, 35.843285677697395, 0.0],
          ],
          [
            [-80.387371185662687, 35.860782077670365, 0.0],
            [-80.385487518998957, 35.854575811013319, 0.0],
            [-80.38961538565917, 35.843843011029946, 0.0],
          ],
          [
            [-79.987712186282977, 35.868580610991501, 0.0],
            [-79.984505786287968, 35.869155877657249, 0.0],
            [-79.98275911962412, 35.867325610993532, 0.0],
            [-79.982277986291479, 35.865173410996817, 0.0],
            [-79.985653919619494, 35.865193277663366, 0.0],
            [-79.985986986285752, 35.861849811001946, 0.0],
            [-79.983088519623607, 35.861073677669765, 0.0],
            [-79.982100319625033, 35.858303677674201, 0.0],
          ],
          [
            [-80.723294318474586, 35.872645010985138, 0.0],
            [-80.721854585143547, 35.868661410991422, 0.0],
            [-80.715348785153594, 35.863078611000049, 0.0],
          ],
          [
            [-80.193864319296381, 35.866643277661126, 0.0],
            [-80.192088719299136, 35.8627512110005, 0.0],
          ],
          [
            [-80.494157785496895, 35.87129907765393, 0.0],
            [-80.490639718835723, 35.870501010988562, 0.0],
            [-80.495357118828395, 35.864198677664888, 0.0],
          ],
          [
            [-79.99255238627552, 35.869446210990134, 0.0],
            [-79.990158519612578, 35.867547810993074, 0.0],
            [-79.987712186282977, 35.868580610991501, 0.0],
          ],
          [
            [-80.192320319298688, 35.870880677654668, 0.0],
            [-80.193864319296381, 35.866643277661126, 0.0],
          ],
          [
            [-79.997794586267389, 35.875646877647227, 0.0],
            [-79.992303919609299, 35.872743877651601, 0.0],
            [-79.99255238627552, 35.869446210990134, 0.0],
          ],
          [
            [-80.166577119338683, 35.87155581098682, 0.0],
            [-80.167729986003678, 35.870433410988653, 0.0],
          ],
          [
            [-81.017717918017638, 35.889671610958771, 0.0],
            [-81.012144118026299, 35.890697077623827, 0.0],
            [-81.010796584694901, 35.888314077627513, 0.0],
            [-81.012487918025727, 35.886369010963904, 0.0],
            [-81.011221984694373, 35.886001410964411, 0.0],
            [-81.006799184701208, 35.889043077626411, 0.0],
            [-81.002774718040826, 35.888993277626525, 0.0],
          ],
          [
            [-80.999799318045461, 35.888394877627491, 0.0],
            [-80.994622384720174, 35.884804610966285, 0.0],
          ],
          [
            [-81.002774718040826, 35.888993277626525, 0.0],
            [-80.999799318045461, 35.888394877627491, 0.0],
          ],
          [
            [-81.025825784671724, 35.887502810962133, 0.0],
            [-81.023401718008813, 35.890546677623945, 0.0],
            [-81.020784584679518, 35.890429877624229, 0.0],
            [-81.020842984679462, 35.888781077626902, 0.0],
            [-81.017717918017638, 35.889671610958771, 0.0],
          ],
          [
            [-81.067714917940009, 35.908965277595371, 0.0],
            [-81.066590584608434, 35.906491477599275, 0.0],
            [-81.062537984614607, 35.905458877600836, 0.0],
            [-81.061133117950249, 35.902206077605967, 0.0],
            [-81.05654618462404, 35.900921210941362, 0.0],
            [-81.056774717957012, 35.896867877614284, 0.0],
          ],
          [
            [-80.838219118296252, 35.926014010902293, 0.0],
            [-80.835799584966651, 35.922372077574721, 0.0],
            [-80.832928384971069, 35.921180210909824, 0.0],
            [-80.827972184978819, 35.922918877573807, 0.0],
          ],
          [
            [-80.823951318318393, 35.914879077586249, 0.0],
            [-80.816804184996158, 35.910020810927051, 0.0],
            [-80.816606118329787, 35.911486477591552, 0.0],
            [-80.818519784993498, 35.912128610923844, 0.0],
            [-80.817026184995768, 35.914280610920514, 0.0],
            [-80.811761985003955, 35.913957410920943, 0.0],
            [-80.812129718336735, 35.911850610924262, 0.0],
            [-80.807202318344309, 35.912924410922699, 0.0],
          ],
          [
            [-80.673489985218566, 35.918728810913763, 0.0],
            [-80.669939385224097, 35.912524010923335, 0.0],
            [-80.659550585240197, 35.910145677593562, 0.0],
            [-80.660028185239469, 35.908038677596892, 0.0],
            [-80.657747718576388, 35.906917210931908, 0.0],
            [-80.654398785248247, 35.909551610927849, 0.0],
          ],
          [
            [-80.827972184978819, 35.922918877573807, 0.0],
            [-80.823354118319344, 35.92383307757234, 0.0],
            [-80.81927418499231, 35.92019007757807, 0.0],
          ],
          [
            [-80.124765919403671, 35.92636121090186, 0.0],
            [-80.130509319394719, 35.921392010909585, 0.0],
            [-80.131691519392916, 35.917430277582412, 0.0],
          ],
          [
            [-80.81927418499231, 35.92019007757807, 0.0],
            [-80.824736718317183, 35.919093210913161, 0.0],
            [-80.822570318320459, 35.917077010916159, 0.0],
            [-80.823951318318393, 35.914879077586249, 0.0],
          ],
          [
            [-80.680138318541538, 35.924680077570997, 0.0],
            [-80.679573185209165, 35.921772010908853, 0.0],
            [-80.681795785205736, 35.919366410912687, 0.0],
            [-80.675011185216249, 35.920079277578282, 0.0],
            [-80.673489985218566, 35.918728810913763, 0.0],
          ],
          [
            [-81.03724591798732, 35.892801810953983, 0.0],
            [-81.038150384652454, 35.88932147762597, 0.0],
            [-81.03324938466011, 35.892821810953876, 0.0],
            [-81.031983584662157, 35.892271210954675, 0.0],
            [-81.032381117994873, 35.889202677626258, 0.0],
            [-81.02832711800113, 35.890390410957707, 0.0],
            [-81.025825784671724, 35.887502810962133, 0.0],
          ],
          [
            [-81.042341317979322, 35.891591610955857, 0.0],
            [-81.03724591798732, 35.892801810953983, 0.0],
          ],
          [
            [-81.056774717957012, 35.896867877614284, 0.0],
            [-81.053229184629174, 35.895881077615741, 0.0],
            [-81.053987117961356, 35.898148677612255, 0.0],
            [-81.050186317967245, 35.899291477610518, 0.0],
            [-81.048273384636843, 35.898122410945689, 0.0],
            [-81.049541917968213, 35.896199410948611, 0.0],
            [-81.048672384636177, 35.89306127762012, 0.0],
            [-81.042341317979322, 35.891591610955857, 0.0],
          ],
          [
            [-80.649103118589721, 35.897163410947201, 0.0],
            [-80.640067985270434, 35.895103810950332, 0.0],
          ],
          [
            [-80.375025519015196, 35.914150077587408, 0.0],
            [-80.371517185687253, 35.904306610936032, 0.0],
            [-80.366133519029006, 35.896622077614722, 0.0],
          ],
          [
            [-80.131691519392916, 35.917430277582412, 0.0],
            [-80.135238319387383, 35.912987610922585, 0.0],
            [-80.136645319385252, 35.90808701093016, 0.0],
            [-80.144975586038981, 35.901101677607699, 0.0],
            [-80.145594386037942, 35.899040610944212, 0.0],
            [-80.142947986042088, 35.894873210950777, 0.0],
          ],
          [
            [-80.654398785248247, 35.909551610927849, 0.0],
            [-80.651217185253131, 35.907605810930988, 0.0],
            [-80.649103118589721, 35.897163410947201, 0.0],
          ],
          [
            [-80.807202318344309, 35.912924410922699, 0.0],
            [-80.808639918342124, 35.910703810926009, 0.0],
            [-80.806530718345414, 35.908275077596556, 0.0],
            [-80.80898131834158, 35.906467210932703, 0.0],
            [-80.806391585012307, 35.906351277599583, 0.0],
            [-80.797699118358992, 35.900804077608143, 0.0],
          ],
          [
            [-80.498986118822756, 35.919179610913034, 0.0],
            [-80.500207118820867, 35.918237210914469, 0.0],
            [-80.498223185490644, 35.9172566775826, 0.0],
            [-80.498558585490116, 35.915767877584813, 0.0],
            [-80.496390518826843, 35.915472410918767, 0.0],
            [-80.499311318822265, 35.910866410925792, 0.0],
            [-80.503432185482495, 35.908095477596817, 0.0],
            [-80.503804385481942, 35.904042477603014, 0.0],
            [-80.507551585476165, 35.901733277606638, 0.0],
            [-80.507612585476068, 35.898573077611616, 0.0],
            [-80.510654918804676, 35.896812677614321, 0.0],
          ],
          [
            [-80.983163718071239, 35.881128477638754, 0.0],
            [-80.979781518076493, 35.877009277645016, 0.0],
            [-80.972523918087745, 35.878985010975384, 0.0],
          ],
          [
            [-80.989475518061454, 35.886939677629641, 0.0],
            [-80.985506384734265, 35.88611887763102, 0.0],
            [-80.983163718071239, 35.881128477638754, 0.0],
          ],
          [
            [-80.972523918087745, 35.878985010975384, 0.0],
            [-80.969374384759305, 35.880728210972677, 0.0],
            [-80.968837184760162, 35.878507210976181, 0.0],
            [-80.966557318097045, 35.878005077643536, 0.0],
            [-80.96470371809994, 35.881167010971865, 0.0],
            [-80.960283518106792, 35.879269277641583, 0.0],
            [-80.957049784778462, 35.881516010971438, 0.0],
            [-80.954796784781934, 35.879685410974332, 0.0],
            [-80.951364584787257, 35.880649610972796, 0.0],
            [-80.952487518118801, 35.877946477643661, 0.0],
            [-80.950911318121257, 35.877512410977715, 0.0],
            [-80.948749584791301, 35.88284981096939, 0.0],
            [-80.9422505848014, 35.88486901096627, 0.0],
            [-80.935721384811586, 35.88432301096708, 0.0],
          ],
          [
            [-80.752001918430096, 35.873720477650181, 0.0],
            [-80.747128185104316, 35.873699610983579, 0.0],
            [-80.749373118434107, 35.869758410989675, 0.0],
            [-80.745884918439572, 35.87017407765569, 0.0],
            [-80.743525385109876, 35.872970410984692, 0.0],
            [-80.74443118510851, 35.876542077645809, 0.0],
            [-80.741897785112428, 35.87588041098013, 0.0],
            [-80.737033985119979, 35.878908010975408, 0.0],
          ],
          [
            [-80.416561185617354, 35.880614610972884, 0.0],
            [-80.411771785624865, 35.876939610978468, 0.0],
          ],
          [
            [-80.737033985119979, 35.878908010975408, 0.0],
            [-80.73005578513073, 35.879257810974877, 0.0],
          ],
          [
            [-80.63018858528568, 35.884272277633841, 0.0],
            [-80.627740318622955, 35.882554877636494, 0.0],
            [-80.628893985287789, 35.880860210972457, 0.0],
            [-80.62855631862169, 35.879142677641767, 0.0],
            [-80.626811718624367, 35.879280077641567, 0.0],
            [-80.626783518624336, 35.876417410979286, 0.0],
          ],
          [
            [-80.413996918954695, 35.88610507763093, 0.0],
            [-80.416561185617354, 35.880614610972884, 0.0],
          ],
          [
            [-80.73005578513073, 35.879257810974877, 0.0],
            [-80.730276718463699, 35.876051410979926, 0.0],
            [-80.738173985118181, 35.869082410990757, 0.0],
            [-80.728523518466545, 35.869182610990492, 0.0],
            [-80.727434185134882, 35.875595810980599, 0.0],
            [-80.723294318474586, 35.872645010985138, 0.0],
          ],
          [
            [-80.626783518624336, 35.876417410979286, 0.0],
            [-80.623012918630252, 35.876005210979997, 0.0],
            [-80.62216891863153, 35.873989877649763, 0.0],
          ],
          [
            [-80.499774718821584, 35.882835477636092, 0.0],
            [-80.502491985484028, 35.879262610974877, 0.0],
            [-80.500832985486568, 35.878413610976224, 0.0],
            [-80.502411518817439, 35.876743477645562, 0.0],
            [-80.495183518828696, 35.879840077640665, 0.0],
            [-80.494157785496895, 35.87129907765393, 0.0],
          ],
          [
            [-80.148237919367261, 35.890796277623679, 0.0],
            [-80.152205186027686, 35.888688810960275, 0.0],
            [-80.152710919360231, 35.885803210964696, 0.0],
            [-80.158789919350795, 35.887496477628815, 0.0],
            [-80.162559986011615, 35.885617610965141, 0.0],
            [-80.162726719344732, 35.880900210972356, 0.0],
            [-80.165399186007278, 35.879594077641116, 0.0],
            [-80.166354186005719, 35.876135877646448, 0.0],
            [-80.169421319334276, 35.87631807764609, 0.0],
            [-80.166577119338683, 35.87155581098682, 0.0],
          ],
          [
            [-80.181158585982757, 35.894250010951623, 0.0],
            [-80.190611185968066, 35.887024277629507, 0.0],
            [-80.190666385968029, 35.884496077633514, 0.0],
            [-80.193732319296601, 35.881230810971829, 0.0],
            [-80.192965319297798, 35.878085210976792, 0.0],
            [-80.194540785962033, 35.87360567765046, 0.0],
            [-80.192320319298688, 35.870880677654668, 0.0],
          ],
          [
            [-80.62216891863153, 35.873989877649763, 0.0],
            [-80.624785785294193, 35.870967077654541, 0.0],
            [-80.622732118630722, 35.866112010995266, 0.0],
            [-80.627936918622595, 35.858692211006769, 0.0],
            [-80.62503938529369, 35.856608077676697, 0.0],
            [-80.622282385298092, 35.856699677676659, 0.0],
            [-80.623745118629074, 35.859608211005479, 0.0],
            [-80.619693918635392, 35.861577477669016, 0.0],
            [-80.618709318636888, 35.861004877669927, 0.0],
            [-80.619834718635218, 35.859379011005728, 0.0],
            [-80.618174985304449, 35.858989677673037, 0.0],
          ],
          [
            [-80.411771785624865, 35.876939610978468, 0.0],
            [-80.39190791898892, 35.870254810988968, 0.0],
            [-80.389307318992962, 35.867502277659867, 0.0],
            [-80.387371185662687, 35.860782077670365, 0.0],
          ],
          [
            [-80.366746119027994, 35.890619810957332, 0.0],
            [-80.372451719019125, 35.884532477633343, 0.0],
            [-80.379527385674919, 35.886357677630485, 0.0],
          ],
          [
            [-80.633228318614442, 35.889447810959098, 0.0],
            [-80.629850985286339, 35.88715787762942, 0.0],
            [-80.63018858528568, 35.884272277633841, 0.0],
          ],
          [
            [-80.994622384720174, 35.884804610966285, 0.0],
            [-80.989475518061454, 35.886939677629641, 0.0],
          ],
          [
            [-80.819073384992578, 35.886044410964416, 0.0],
            [-80.814149985000256, 35.884004010967544, 0.0],
          ],
          [
            [-80.814149985000256, 35.884004010967544, 0.0],
            [-80.805986518346231, 35.886999677629603, 0.0],
            [-80.800274185021806, 35.88635507763064, 0.0],
          ],
          [
            [-80.836606118298732, 35.885684677631673, 0.0],
            [-80.828979584977162, 35.885865277631353, 0.0],
          ],
          [
            [-80.935721384811586, 35.88432301096708, 0.0],
            [-80.91844291817165, 35.885178010965717, 0.0],
            [-80.911971384848357, 35.887905477628181, 0.0],
          ],
          [
            [-80.828979584977162, 35.885865277631353, 0.0],
            [-80.82661371831415, 35.888795677626717, 0.0],
            [-80.823661118318796, 35.885130410965871, 0.0],
            [-80.819073384992578, 35.886044410964416, 0.0],
          ],
          [
            [-80.800274185021806, 35.88635507763064, 0.0],
            [-80.795491185029221, 35.885230010965756, 0.0],
            [-80.795487385029162, 35.888939877626569, 0.0],
            [-80.79402331836485, 35.889580210958854, 0.0],
          ],
          [
            [-80.79402331836485, 35.889580210958854, 0.0],
            [-80.790987985036224, 35.885662077631594, 0.0],
          ],
          [
            [-80.911971384848357, 35.887905477628181, 0.0],
            [-80.909889584851612, 35.889326077625981, 0.0],
          ],
          [
            [-80.882871918226897, 35.888941877626564, 0.0],
            [-80.872374518243248, 35.889675210958728, 0.0],
            [-80.870939118245417, 35.891438410955971, 0.0],
          ],
          [
            [-80.142947986042088, 35.894873210950777, 0.0],
            [-80.148237919367261, 35.890796277623679, 0.0],
          ],
          [
            [-80.502865918816781, 35.892156077621621, 0.0],
            [-80.499423585488785, 35.892385410954546, 0.0],
            [-80.497810985491242, 35.886822210963203, 0.0],
            [-80.499774718821584, 35.882835477636092, 0.0],
          ],
          [
            [-80.790987985036224, 35.885662077631594, 0.0],
            [-80.783724518380723, 35.888198877627758, 0.0],
            [-80.779498385054069, 35.891974277621841, 0.0],
            [-80.774350918395271, 35.88977167762522, 0.0],
            [-80.775255118393943, 35.886841077629811, 0.0],
            [-80.771426118399916, 35.88816621096106, 0.0],
            [-80.767713918405661, 35.886285077630703, 0.0],
            [-80.769447518403013, 35.89453087761791, 0.0],
            [-80.766996785073388, 35.896063210948853, 0.0],
            [-80.765424718409179, 35.893176210953357, 0.0],
            [-80.758114118420508, 35.888383077627395, 0.0],
            [-80.757337385088476, 35.880916677638993, 0.0],
            [-80.754161185093267, 35.878531877642729, 0.0],
            [-80.754701985092538, 35.874433210982431, 0.0],
            [-80.752001918430096, 35.873720477650181, 0.0],
          ],
          [
            [-80.862496118258548, 35.889422810959161, 0.0],
            [-80.860497584928339, 35.891346277622802, 0.0],
            [-80.856529318267746, 35.8908418776237, 0.0],
            [-80.857599518266056, 35.888025210961302, 0.0],
            [-80.85498258493692, 35.886834010963128, 0.0],
            [-80.851914518274953, 35.888551077627255, 0.0],
            [-80.85194178494163, 35.891345010956229, 0.0],
            [-80.849352518278977, 35.891481877622653, 0.0],
            [-80.84682118494959, 35.88719887762926, 0.0],
            [-80.841896518290525, 35.886487677630441, 0.0],
            [-80.838520518295752, 35.884196610967308, 0.0],
            [-80.836606118298732, 35.885684677631673, 0.0],
          ],
          [
            [-80.366133519029006, 35.896622077614722, 0.0],
            [-80.365128585697164, 35.894587677617778, 0.0],
            [-80.366746119027994, 35.890619810957332, 0.0],
          ],
          [
            [-80.398403718978841, 35.892850010953907, 0.0],
            [-80.40898758562912, 35.89114547762324, 0.0],
            [-80.413996918954695, 35.88610507763093, 0.0],
          ],
          [
            [-80.379527385674919, 35.886357677630485, 0.0],
            [-80.389797318992294, 35.891900610955304, 0.0],
            [-80.398403718978841, 35.892850010953907, 0.0],
          ],
          [
            [-80.889429918216706, 35.892949010953771, 0.0],
            [-80.882871918226897, 35.888941877626564, 0.0],
          ],
          [
            [-80.870939118245417, 35.891438410955971, 0.0],
            [-80.869278518247995, 35.89249201095447, 0.0],
            [-80.862496118258548, 35.889422810959161, 0.0],
          ],
          [
            [-80.640067985270434, 35.895103810950332, 0.0],
            [-80.633228318614442, 35.889447810959098, 0.0],
          ],
          [
            [-80.909889584851612, 35.889326077625981, 0.0],
            [-80.907977118187944, 35.89244121095453, 0.0],
            [-80.899900918200501, 35.896428077614985, 0.0],
          ],
          [
            [-80.510654918804676, 35.896812677614321, 0.0],
            [-80.506181985478293, 35.895434277616459, 0.0],
            [-80.502865918816781, 35.892156077621621, 0.0],
          ],
          [
            [-80.899900918200501, 35.896428077614985, 0.0],
            [-80.889429918216706, 35.892949010953771, 0.0],
          ],
          [
            [-80.797699118358992, 35.900804077608143, 0.0],
            [-80.794691985030397, 35.896153277615326, 0.0],
            [-80.793508585032328, 35.8973662109467, 0.0],
            [-80.790217518370753, 35.895463210949856, 0.0],
            [-80.79402331836485, 35.889580210958854, 0.0],
          ],
          [
            [-80.710320585161412, 35.951044410863517, 0.0],
            [-80.704463318503826, 35.952147610861743, 0.0],
            [-80.705104518502878, 35.945276877539186, 0.0],
            [-80.701781718508016, 35.945874610871499, 0.0],
          ],
          [
            [-80.347423519057998, 35.960458077515568, 0.0],
            [-80.350723785719538, 35.94674161087022, 0.0],
          ],
          [
            [-80.719248718480912, 35.950854610863814, 0.0],
            [-80.717842785149742, 35.953008410860491, 0.0],
            [-80.719929518479773, 35.955090677523913, 0.0],
            [-80.717199118484075, 35.956604210854948, 0.0],
            [-80.713986718489082, 35.955415877523478, 0.0],
            [-80.710320585161412, 35.951044410863517, 0.0],
          ],
          [
            [-80.378030919010484, 35.96232681084598, 0.0],
            [-80.374134785683225, 35.953573277526289, 0.0],
            [-80.374925985682012, 35.945947410871497, 0.0],
          ],
          [
            [-80.413748185621728, 35.98225707748179, 0.0],
            [-80.399495318977245, 35.974417810827276, 0.0],
          ],
          [
            [-80.33490671907748, 35.9798736108188, 0.0],
            [-80.340937985734797, 35.975432877492324, 0.0],
            [-80.341645519067015, 35.968517277503111, 0.0],
          ],
          [
            [-80.549332918744597, 35.976949877489915, 0.0],
            [-80.545928118749941, 35.972825477496315, 0.0],
            [-80.544039918752787, 35.973465410828794, 0.0],
          ],
          [
            [-80.399495318977245, 35.974417810827276, 0.0],
            [-80.395579518983197, 35.973393210828817, 0.0],
          ],
          [
            [-80.559274985395859, 35.980711477484078, 0.0],
            [-80.554316518736925, 35.980479477484437, 0.0],
            [-80.553023385405595, 35.97729561082275, 0.0],
            [-80.549332918744597, 35.976949877489915, 0.0],
          ],
          [
            [-80.580432985363018, 35.983492277479684, 0.0],
            [-80.559274985395859, 35.980711477484078, 0.0],
          ],
          [
            [-80.744749585108025, 35.996954010792308, 0.0],
            [-80.74339431844345, 35.995214877461649, 0.0],
          ],
          [
            [-80.416167918951373, 35.998923477455833, 0.0],
            [-80.419693585612549, 35.996474277459583, 0.0],
          ],
          [
            [-80.745263385107194, 36.000137010787284, 0.0],
            [-80.744749585108025, 35.996954010792308, 0.0],
          ],
          [
            [-80.414109785621179, 36.00015061078733, 0.0],
            [-80.416167918951373, 35.998923477455833, 0.0],
          ],
          [
            [-80.340312185735684, 36.000149810787264, 0.0],
            [-80.339404519070513, 36.000150010787252, 0.0],
          ],
          [
            [-80.580686318695996, 35.983813010812582, 0.0],
            [-80.580432985363018, 35.983492277479684, 0.0],
          ],
          [
            [-80.330589719084116, 35.990360210802351, 0.0],
            [-80.332960119080496, 35.984429877478419, 0.0],
          ],
          [
            [-80.42275058560773, 35.990922010801626, 0.0],
            [-80.421870718942444, 35.988271477472438, 0.0],
            [-80.417293318949532, 35.98468941081137, 0.0],
          ],
          [
            [-80.73360731845861, 35.988880610804813, 0.0],
            [-80.736558918453966, 35.983702410812839, 0.0],
            [-80.731950185127801, 35.971660810831509, 0.0],
          ],
          [
            [-80.332960119080496, 35.984429877478419, 0.0],
            [-80.333130785746903, 35.981384277483073, 0.0],
            [-80.33490671907748, 35.9798736108188, 0.0],
          ],
          [
            [-80.417293318949532, 35.98468941081137, 0.0],
            [-80.413748185621728, 35.98225707748179, 0.0],
          ],
          [
            [-80.734198918457651, 35.988811277471427, 0.0],
            [-80.73360731845861, 35.988880610804813, 0.0],
          ],
          [
            [-80.74339431844345, 35.995214877461649, 0.0],
            [-80.738228585118122, 35.988990810804694, 0.0],
            [-80.734198918457651, 35.988811277471427, 0.0],
          ],
          [
            [-80.419693585612549, 35.996474277459583, 0.0],
            [-80.42275058560773, 35.990922010801626, 0.0],
          ],
          [
            [-80.339404519070513, 36.000150010787252, 0.0],
            [-80.335603719076289, 35.99338481079775, 0.0],
            [-80.330589719084116, 35.990360210802351, 0.0],
          ],
          [
            [-80.3309249190836, 36.000151677453914, 0.0],
            [-80.330589719084116, 35.990360210802351, 0.0],
          ],
          [
            [-80.588400318684023, 36.000149210787185, 0.0],
            [-80.583836318690999, 35.997371277458342, 0.0],
            [-80.585079918689189, 35.988348877472276, 0.0],
            [-80.580686318695996, 35.983813010812582, 0.0],
          ],
          [
            [-80.606971785321832, 36.011476677436292, 0.0],
            [-80.601616518663491, 36.010926077437148, 0.0],
            [-80.599558518666697, 36.012299810768468, 0.0],
          ],
          [
            [-80.287552119150916, 36.00464901078027, 0.0],
            [-80.294935919139505, 36.004608410780293, 0.0],
          ],
          [
            [-80.286424519152661, 36.00490001077992, 0.0],
            [-80.287552119150916, 36.00464901078027, 0.0],
          ],
          [
            [-80.294935919139505, 36.004608410780293, 0.0],
            [-80.302459719127796, 36.005826810778444, 0.0],
          ],
          [
            [-80.277655785833019, 36.007984810775099, 0.0],
            [-80.286424519152661, 36.00490001077992, 0.0],
          ],
          [
            [-80.302459719127796, 36.005826810778444, 0.0],
            [-80.305728385789394, 36.006492810777388, 0.0],
            [-80.311704719113436, 36.004618277447037, 0.0],
          ],
          [
            [-80.270267719177696, 36.010932677437154, 0.0],
            [-80.277655785833019, 36.007984810775099, 0.0],
          ],
          [
            [-80.745077385107436, 36.006447410777525, 0.0],
            [-80.745263385107194, 36.000137010787284, 0.0],
          ],
          [
            [-80.324865785759641, 36.001120677452377, 0.0],
            [-80.3309249190836, 36.000151677453914, 0.0],
          ],
          [
            [-80.341945119066565, 36.003897477448163, 0.0],
            [-80.340312185735684, 36.000149810787264, 0.0],
          ],
          [
            [-80.413845318954941, 36.005142877446247, 0.0],
            [-80.414109785621179, 36.00015061078733, 0.0],
          ],
          [
            [-80.590767318680321, 36.00535867744577, 0.0],
            [-80.588456318683882, 36.002793077449894, 0.0],
            [-80.588400318684023, 36.000149210787185, 0.0],
          ],
          [
            [-80.745960318439415, 36.01235501076826, 0.0],
            [-80.744576985108154, 36.011005210770406, 0.0],
            [-80.745077385107436, 36.006447410777525, 0.0],
          ],
          [
            [-80.599558518666697, 36.012299810768468, 0.0],
            [-80.595276385340014, 36.006298877444351, 0.0],
            [-80.590767318680321, 36.00535867744577, 0.0],
          ],
          [
            [-80.417372585616079, 36.012261210768486, 0.0],
            [-80.413845318954941, 36.005142877446247, 0.0],
          ],
          [
            [-80.350876585719334, 36.012212277435196, 0.0],
            [-80.34622831905989, 36.005387077445846, 0.0],
            [-80.341945119066565, 36.003897477448163, 0.0],
          ],
          [
            [-80.311704719113436, 36.004618277447037, 0.0],
            [-80.317508519104422, 36.00622421077793, 0.0],
            [-80.324865785759641, 36.001120677452377, 0.0],
          ],
          [
            [-80.344717785728847, 35.964419010842676, 0.0],
            [-80.347423519057998, 35.960458077515568, 0.0],
          ],
          [
            [-80.387717718995532, 35.966904010838846, 0.0],
            [-80.378030919010484, 35.96232681084598, 0.0],
          ],
          [
            [-80.83938318496098, 35.966113877506757, 0.0],
            [-80.832707318304699, 35.965424810841171, 0.0],
            [-80.829020918310448, 35.958484477518709, 0.0],
          ],
          [
            [-80.731937985127843, 35.962363210845979, 0.0],
            [-80.730865918462882, 35.961013010848092, 0.0],
            [-80.722697718475558, 35.961638077513726, 0.0],
            [-80.72105851847806, 35.957082210854139, 0.0],
            [-80.722658718475543, 35.952523677527893, 0.0],
            [-80.719248718480912, 35.950854610863814, 0.0],
          ],
          [
            [-80.54075071875792, 35.967050877505415, 0.0],
            [-80.539095318760474, 35.961072810847895, 0.0],
            [-80.540647118758045, 35.958761010851504, 0.0],
            [-80.538819918760964, 35.955462010856706, 0.0],
            [-80.539694918759551, 35.953745077525923, 0.0],
            [-80.537247118763332, 35.951476210862836, 0.0],
            [-80.538067518762148, 35.948133277534623, 0.0],
          ],
          [
            [-80.341645519067015, 35.968517277503111, 0.0],
            [-80.344717785728847, 35.964419010842676, 0.0],
          ],
          [
            [-80.731950185127801, 35.971660810831509, 0.0],
            [-80.731749718461515, 35.969164810835309, 0.0],
            [-80.729296318465288, 35.967220410838422, 0.0],
            [-80.729715118464696, 35.964197077509823, 0.0],
            [-80.731937985127843, 35.962363210845979, 0.0],
          ],
          [
            [-80.395579518983197, 35.973393210828817, 0.0],
            [-80.392100118988651, 35.971032077499103, 0.0],
            [-80.390319118991329, 35.967374877504881, 0.0],
            [-80.387717718995532, 35.966904010838846, 0.0],
          ],
          [
            [-80.544039918752787, 35.973465410828794, 0.0],
            [-80.54021178542547, 35.970371210833548, 0.0],
            [-80.54075071875792, 35.967050877505415, 0.0],
          ],
          [
            [-80.523806118784194, 35.934702210888929, 0.0],
            [-80.522624785452763, 35.933670677557132, 0.0],
          ],
          [
            [-80.525345785448565, 35.941756877544549, 0.0],
            [-80.525941185447493, 35.938597210882733, 0.0],
            [-80.522620985452647, 35.936487477552646, 0.0],
            [-80.523806118784194, 35.934702210888929, 0.0],
          ],
          [
            [-80.368524385691956, 35.930759877561741, 0.0],
            [-80.368513319025283, 35.930687677561821, 0.0],
          ],
          [
            [-80.514659518798453, 35.931075810894583, 0.0],
            [-80.513252585467342, 35.93041041089549, 0.0],
          ],
          [
            [-80.514659518798453, 35.931075810894583, 0.0],
            [-80.513252585467342, 35.93041041089549, 0.0],
          ],
          [
            [-80.51606718546293, 35.931237410894255, 0.0],
            [-80.514659518798453, 35.931075810894583, 0.0],
          ],
          [
            [-80.522624785452763, 35.933670677557132, 0.0],
            [-80.519244185457978, 35.934858477555224, 0.0],
            [-80.51606718546293, 35.931237410894255, 0.0],
          ],
          [
            [-80.354273585714111, 35.942414210876848, 0.0],
            [-80.357765919041981, 35.940720277546177, 0.0],
            [-80.360414319037886, 35.934743677555446, 0.0],
            [-80.368524385691956, 35.930759877561741, 0.0],
          ],
          [
            [-80.368811585691503, 35.930309210895643, 0.0],
            [-80.368935319024672, 35.924369010904911, 0.0],
            [-80.375025519015196, 35.914150077587408, 0.0],
          ],
          [
            [-80.813494318334619, 35.930698810895024, 0.0],
            [-80.814144185000259, 35.927882410899485, 0.0],
            [-80.808768318341833, 35.92524601090355, 0.0],
            [-80.81927418499231, 35.92019007757807, 0.0],
          ],
          [
            [-80.113530186087758, 35.936802477552249, 0.0],
            [-80.116317786083414, 35.937535610884424, 0.0],
            [-80.124117986071269, 35.931101410894371, 0.0],
            [-80.124765919403671, 35.92636121090186, 0.0],
          ],
          [
            [-80.507762185475826, 35.930221877562531, 0.0],
            [-80.506130518811631, 35.929304210897214, 0.0],
            [-80.506557585477651, 35.926190210902121, 0.0],
            [-80.499968718821208, 35.926458210901728, 0.0],
            [-80.500175985487601, 35.92009207757809, 0.0],
            [-80.498986118822756, 35.919179610913034, 0.0],
          ],
          [
            [-80.368513319025283, 35.930687677561821, 0.0],
            [-80.368811585691503, 35.930309210895643, 0.0],
          ],
          [
            [-80.513252585467342, 35.93041041089549, 0.0],
            [-80.507762185475826, 35.930221877562531, 0.0],
          ],
          [
            [-80.686965785197685, 35.943066210875941, 0.0],
            [-80.683668185202805, 35.939312077548323, 0.0],
            [-80.686397585198449, 35.936379477552975, 0.0],
            [-80.685860185199431, 35.933288077557791, 0.0],
            [-80.687294918530426, 35.931363677560739, 0.0],
            [-80.681408118539593, 35.928870477564601, 0.0],
            [-80.680138318541538, 35.924680077570997, 0.0],
          ],
          [
            [-80.375540385681063, 35.942226810877173, 0.0],
            [-80.374764519015628, 35.938322010883269, 0.0],
            [-80.368811585691503, 35.930309210895643, 0.0],
          ],
          [
            [-80.10384191943615, 35.942731810876353, 0.0],
            [-80.109868786093443, 35.940534610879808, 0.0],
            [-80.113530186087758, 35.936802477552249, 0.0],
          ],
          [
            [-80.690933718524832, 35.939537410881314, 0.0],
            [-80.686965785197685, 35.943066210875941, 0.0],
          ],
          [
            [-80.701781718508016, 35.945874610871499, 0.0],
            [-80.695130585184984, 35.940496877546536, 0.0],
            [-80.690933718524832, 35.939537410881314, 0.0],
          ],
          [
            [-80.829020918310448, 35.958484477518709, 0.0],
            [-80.828543384977934, 35.956537810854968, 0.0],
            [-80.82564118498243, 35.957956410852773, 0.0],
            [-80.818629318326657, 35.955296877523551, 0.0],
            [-80.817617118328201, 35.952937610860658, 0.0],
            [-80.819731718324874, 35.949892810865208, 0.0],
            [-80.824435384984326, 35.949482610865914, 0.0],
            [-80.825567318315848, 35.941307410878551, 0.0],
            [-80.821881584988262, 35.936130277553332, 0.0],
            [-80.81723498499548, 35.936448810886191, 0.0],
            [-80.819293318325549, 35.932739810891917, 0.0],
            [-80.818168918327387, 35.930128610895963, 0.0],
            [-80.813494318334619, 35.930698810895024, 0.0],
          ],
          [
            [-80.374925985682012, 35.945947410871497, 0.0],
            [-80.375540385681063, 35.942226810877173, 0.0],
          ],
          [
            [-80.350723785719538, 35.94674161087022, 0.0],
            [-80.354273585714111, 35.942414210876848, 0.0],
          ],
          [
            [-80.538067518762148, 35.948133277534623, 0.0],
            [-80.534634585434105, 35.945542877538799, 0.0],
            [-80.52613231878064, 35.943337677542161, 0.0],
            [-80.525345785448565, 35.941756877544549, 0.0],
          ],
          [
            [-80.644405985263688, 36.023064010751739, 0.0],
            [-80.639867118604116, 36.021805210753655, 0.0],
          ],
          [
            [-81.478853983968463, 36.036846877397068, 0.0],
            [-81.473666383976592, 36.037103277396625, 0.0],
            [-81.464930117323433, 36.04075141072434, 0.0],
          ],
          [
            [-81.49192078394816, 36.026506810746355, 0.0],
            [-81.487976583954378, 36.028251010743588, 0.0],
          ],
          [
            [-80.217635119259455, 36.032358077403842, 0.0],
            [-80.230909185905602, 36.028866477409281, 0.0],
            [-80.235387785898581, 36.026045677413777, 0.0],
          ],
          [
            [-80.432492118925893, 36.024392677416245, 0.0],
            [-80.432367918926161, 36.024300077416399, 0.0],
          ],
          [
            [-80.315180919108059, 36.028962877409299, 0.0],
            [-80.3200609857671, 36.02466001074913, 0.0],
          ],
          [
            [-80.215071585930048, 36.034054610734586, 0.0],
            [-80.217635119259455, 36.032358077403842, 0.0],
          ],
          [
            [-81.487976583954378, 36.028251010743588, 0.0],
            [-81.4847131839594, 36.033131877402695, 0.0],
          ],
          [
            [-80.312160385779464, 36.033907677401487, 0.0],
            [-80.315180919108059, 36.028962877409299, 0.0],
          ],
          [
            [-80.823876118318481, 36.031388077405495, 0.0],
            [-80.817026318329113, 36.029690477408053, 0.0],
          ],
          [
            [-80.817026318329113, 36.029690477408053, 0.0],
            [-80.813896985000667, 36.029666077408137, 0.0],
            [-80.814628184999492, 36.031887810738056, 0.0],
            [-80.813189185001761, 36.033444277402225, 0.0],
          ],
          [
            [-81.4847131839594, 36.033131877402695, 0.0],
            [-81.478853983968463, 36.036846877397068, 0.0],
          ],
          [
            [-80.358481385707535, 36.036579410730667, 0.0],
            [-80.352563119049989, 36.027968010744132, 0.0],
          ],
          [
            [-80.271303119176139, 36.059389677361935, 0.0],
            [-80.274716119170932, 36.059026277362591, 0.0],
          ],
          [
            [-80.286791985818809, 36.054203877370014, 0.0],
            [-80.289953585813862, 36.05125207737467, 0.0],
            [-80.293535385808354, 36.050819477375285, 0.0],
          ],
          [
            [-80.506635318810936, 36.053906677370492, 0.0],
            [-80.50629838547809, 36.053229010704911, 0.0],
          ],
          [
            [-80.274716119170932, 36.059026277362591, 0.0],
            [-80.279033185830826, 36.057174877365355, 0.0],
            [-80.282078119159394, 36.057864277364331, 0.0],
            [-80.286791985818809, 36.054203877370014, 0.0],
          ],
          [
            [-81.44658891735196, 36.05665667736622, 0.0],
            [-81.442727517357866, 36.059223810695585, 0.0],
          ],
          [
            [-80.892223318212359, 36.054641677369318, 0.0],
            [-80.886441984888052, 36.056314210700123, 0.0],
          ],
          [
            [-81.421828184056949, 36.058180477363862, 0.0],
            [-81.415085584067469, 36.0542670773699, 0.0],
            [-81.412717584071117, 36.056306077366855, 0.0],
          ],
          [
            [-81.412717584071117, 36.056306077366855, 0.0],
            [-81.411845584072523, 36.060886410693001, 0.0],
            [-81.412945984070745, 36.06157307735856, 0.0],
          ],
          [
            [-81.442727517357866, 36.059223810695585, 0.0],
            [-81.435705984035451, 36.060578677360127, 0.0],
            [-81.43240431737388, 36.058015610697396, 0.0],
            [-81.421828184056949, 36.058180477363862, 0.0],
          ],
          [
            [-80.475342518859463, 36.056133877367017, 0.0],
            [-80.471383785532282, 36.061442010692076, 0.0],
            [-80.463676985544225, 36.062582810690344, 0.0],
          ],
          [
            [-80.304036519125304, 36.038689277394099, 0.0],
            [-80.306125185788744, 36.036148610731459, 0.0],
            [-80.312160385779464, 36.033907677401487, 0.0],
          ],
          [
            [-80.363386785699959, 36.03985467739227, 0.0],
            [-80.358481385707535, 36.036579410730667, 0.0],
          ],
          [
            [-81.464930117323433, 36.04075141072434, 0.0],
            [-81.459179583999003, 36.042267010721844, 0.0],
          ],
          [
            [-80.845976318284102, 36.042227477388565, 0.0],
            [-80.844933384952469, 36.041059277390502, 0.0],
          ],
          [
            [-80.452126185562122, 36.042754410721102, 0.0],
            [-80.450963785563999, 36.04146101072314, 0.0],
          ],
          [
            [-80.450963785563999, 36.04146101072314, 0.0],
            [-80.446407385571092, 36.037756810728979, 0.0],
            [-80.442141918911034, 36.030732810739835, 0.0],
            [-80.432492118925893, 36.024392677416245, 0.0],
          ],
          [
            [-80.844933384952469, 36.041059277390502, 0.0],
            [-80.839180784961286, 36.041790610722728, 0.0],
            [-80.838393384962558, 36.037279010729549, 0.0],
            [-80.833008518304325, 36.036246677397855, 0.0],
            [-80.830866718307675, 36.034253477400966, 0.0],
            [-80.829118184977006, 36.034962810733134, 0.0],
            [-80.823876118318481, 36.031388077405495, 0.0],
          ],
          [
            [-80.77440151839528, 36.049311610711015, 0.0],
            [-80.7751129183942, 36.044228277385571, 0.0],
            [-80.771983985065731, 36.043309610720314, 0.0],
            [-80.77190138506586, 36.041821010722572, 0.0],
            [-80.775879118392993, 36.040358610724866, 0.0],
            [-80.774191518395583, 36.037036610729956, 0.0],
            [-80.769340385069768, 36.038108677395087, 0.0],
            [-80.769007385070267, 36.034123677401283, 0.0],
            [-80.77030658506834, 36.032476010737184, 0.0],
            [-80.773885585062771, 36.033738677401743, 0.0],
            [-80.772366985065105, 36.030714410739733, 0.0],
            [-80.773667185063005, 36.028127677410509, 0.0],
          ],
          [
            [-80.296047585804445, 36.0482336107126, 0.0],
            [-80.304036519125304, 36.038689277394099, 0.0],
          ],
          [
            [-81.459179583999003, 36.042267010721844, 0.0],
            [-81.451429784011111, 36.046806610714782, 0.0],
          ],
          [
            [-80.491178718834931, 36.042946610720833, 0.0],
            [-80.487222585507709, 36.043250677387107, 0.0],
          ],
          [
            [-80.865742518253512, 36.045940477382828, 0.0],
            [-80.860385118261775, 36.043214810720485, 0.0],
            [-80.858382718264863, 36.045046610717691, 0.0],
            [-80.85232071827437, 36.042457877388301, 0.0],
            [-80.845976318284102, 36.042227477388565, 0.0],
          ],
          [
            [-80.865996184919766, 36.046513077382031, 0.0],
            [-80.865742518253512, 36.045940477382828, 0.0],
          ],
          [
            [-80.487222585507709, 36.043250677387107, 0.0],
            [-80.47937198551989, 36.048174410712704, 0.0],
          ],
          [
            [-80.499842585488125, 36.049088210711375, 0.0],
            [-80.491178718834931, 36.042946610720833, 0.0],
          ],
          [
            [-80.293535385808354, 36.050819477375285, 0.0],
            [-80.293650119141489, 36.048987610711379, 0.0],
            [-80.296047585804445, 36.0482336107126, 0.0],
          ],
          [
            [-80.371196119021135, 36.054145010703451, 0.0],
            [-80.364062919032222, 36.043679277386332, 0.0],
            [-80.363386785699959, 36.03985467739227, 0.0],
          ],
          [
            [-80.886441984888052, 36.056314210700123, 0.0],
            [-80.884383118224548, 36.054322077369875, 0.0],
            [-80.884918718223673, 36.052650210705735, 0.0],
            [-80.878234918234114, 36.05187207737373, 0.0],
            [-80.876288984903738, 36.049330077377647, 0.0],
            [-80.865996184919766, 36.046513077382031, 0.0],
          ],
          [
            [-81.451429784011111, 36.046806610714782, 0.0],
            [-81.449428984014162, 36.048113210712756, 0.0],
            [-81.443788984022945, 36.047841677379893, 0.0],
            [-81.44658891735196, 36.05665667736622, 0.0],
          ],
          [
            [-80.50629838547809, 36.053229010704911, 0.0],
            [-80.499842585488125, 36.049088210711375, 0.0],
          ],
          [
            [-80.768274385071436, 36.054963010702238, 0.0],
            [-80.769071385070163, 36.049490277377402, 0.0],
            [-80.77440151839528, 36.049311610711015, 0.0],
          ],
          [
            [-80.47937198551989, 36.048174410712704, 0.0],
            [-80.474376785527681, 36.050812277375371, 0.0],
            [-80.475342518859463, 36.056133877367017, 0.0],
          ],
          [
            [-80.463676985544225, 36.062582810690344, 0.0],
            [-80.458794185551824, 36.057759477364527, 0.0],
            [-80.457800918886733, 36.051040210708379, 0.0],
            [-80.452126185562122, 36.042754410721102, 0.0],
          ],
          [
            [-80.509467718806548, 36.071761677342863, 0.0],
            [-80.509020718807221, 36.068535810681055, 0.0],
          ],
          [
            [-80.509806785472676, 36.07319967734054, 0.0],
            [-80.509467718806548, 36.071761677342863, 0.0],
          ],
          [
            [-80.510273185471931, 36.074021010672709, 0.0],
            [-80.509806785472676, 36.07319967734054, 0.0],
          ],
          [
            [-80.220266519255404, 36.087772877317946, 0.0],
            [-80.224213985915981, 36.085892077320807, 0.0],
          ],
          [
            [-80.80392091834949, 36.08773727731807, 0.0],
            [-80.79921438502339, 36.082215277326554, 0.0],
          ],
          [
            [-80.238231785894186, 36.083521277324508, 0.0],
            [-80.242035185888255, 36.080059810663272, 0.0],
            [-80.246941785880722, 36.07879547733188, 0.0],
            [-80.254531385868916, 36.072129077342197, 0.0],
            [-80.259950185860475, 36.070073410678788, 0.0],
          ],
          [
            [-81.388128984109301, 36.075708077336685, 0.0],
            [-81.385731584112932, 36.078387677332444, 0.0],
            [-81.384433584114959, 36.075594010670159, 0.0],
            [-81.380540784121138, 36.07573181067005, 0.0],
            [-81.379327984122995, 36.079441610664162, 0.0],
          ],
          [
            [-81.416005784066101, 36.086350077320162, 0.0],
            [-81.413718184069637, 36.081908210660401, 0.0],
          ],
          [
            [-80.79921438502339, 36.082215277326554, 0.0],
            [-80.800006985022208, 36.079399010664361, 0.0],
            [-80.793438718365735, 36.074723010671448, 0.0],
            [-80.794399918364263, 36.072708410674579, 0.0],
            [-80.788923985039446, 36.07584201066976, 0.0],
            [-80.783990318380404, 36.07309041067407, 0.0],
          ],
          [
            [-80.515433718797283, 36.093700677308732, 0.0],
            [-80.516356318795829, 36.082292810659737, 0.0],
            [-80.510273185471931, 36.074021010672709, 0.0],
          ],
          [
            [-80.224213985915981, 36.085892077320807, 0.0],
            [-80.231973519237215, 36.086870210652648, 0.0],
            [-80.238231785894186, 36.083521277324508, 0.0],
          ],
          [
            [-80.815824184997666, 36.091728410645146, 0.0],
            [-80.812187985003334, 36.087169210652178, 0.0],
            [-80.806040918346184, 36.083226877324989, 0.0],
            [-80.805303718347204, 36.087371677318515, 0.0],
            [-80.80392091834949, 36.08773727731807, 0.0],
          ],
          [
            [-81.423260184054811, 36.091843410644913, 0.0],
            [-81.421340384057771, 36.090058010647624, 0.0],
            [-81.422410184056162, 36.086508010653233, 0.0],
            [-81.420264917392728, 36.084516477323007, 0.0],
            [-81.416005784066101, 36.086350077320162, 0.0],
          ],
          [
            [-80.360923719037089, 36.092547610643862, 0.0],
            [-80.36495858569748, 36.09071621064669, 0.0],
            [-80.367300785693828, 36.086823210652824, 0.0],
          ],
          [
            [-80.515646385463526, 36.094443810640882, 0.0],
            [-80.515433718797283, 36.093700677308732, 0.0],
          ],
          [
            [-80.823976918318351, 36.094067877308248, 0.0],
            [-80.815824184997666, 36.091728410645146, 0.0],
          ],
          [
            [-80.508489185474673, 36.106940677288151, 0.0],
            [-80.508013518808809, 36.106537677288713, 0.0],
          ],
          [
            [-80.573536785373733, 36.106802877288487, 0.0],
            [-80.567101585383739, 36.108242810619458, 0.0],
          ],
          [
            [-80.685902118532681, 36.096011877305102, 0.0],
            [-80.676816785213418, 36.096726010637326, 0.0],
            [-80.674024985217784, 36.099521077299698, 0.0],
            [-80.669057718558747, 36.097759677302463, 0.0],
            [-80.666462518562867, 36.099226277300204, 0.0],
          ],
          [
            [-80.836274918299239, 36.102660210628073, 0.0],
            [-80.83080058497444, 36.102291877295386, 0.0],
            [-80.826598518314313, 36.098007810635352, 0.0],
            [-80.821125184989342, 36.096608677304232, 0.0],
            [-80.823976918318351, 36.094067877308248, 0.0],
          ],
          [
            [-81.437774184032264, 36.105759877290097, 0.0],
            [-81.431983584041291, 36.098389010634776, 0.0],
            [-81.423260184054811, 36.091843410644913, 0.0],
          ],
          [
            [-80.694422918519365, 36.095343010639397, 0.0],
            [-80.691940118523291, 36.095596277305731, 0.0],
          ],
          [
            [-80.691940118523291, 36.095596277305731, 0.0],
            [-80.685902118532681, 36.096011877305102, 0.0],
          ],
          [
            [-80.522155585453504, 36.101022010630743, 0.0],
            [-80.519366585457817, 36.097561677302792, 0.0],
            [-80.515558785463725, 36.096550610637735, 0.0],
            [-80.515646385463526, 36.094443810640882, 0.0],
          ],
          [
            [-81.379327984122995, 36.079441610664162, 0.0],
            [-81.364629584145803, 36.086471810653279, 0.0],
            [-81.357660784156622, 36.086974877319108, 0.0],
            [-81.353258384163439, 36.091668877311861, 0.0],
            [-81.348969384170061, 36.092698610643708, 0.0],
            [-81.348404184170988, 36.09549221063935, 0.0],
          ],
          [
            [-80.358636185707269, 36.102394277295332, 0.0],
            [-80.362446519034734, 36.097494210636228, 0.0],
            [-80.360218119038109, 36.094104810641397, 0.0],
            [-80.360923719037089, 36.092547610643862, 0.0],
          ],
          [
            [-80.508183718808482, 36.102632610628291, 0.0],
            [-80.510593718804785, 36.097120677303394, 0.0],
            [-80.515433718797283, 36.093700677308732, 0.0],
          ],
          [
            [-80.666462518562867, 36.099226277300204, 0.0],
            [-80.662512718568962, 36.100784810631012, 0.0],
          ],
          [
            [-80.662512718568962, 36.100784810631012, 0.0],
            [-80.662569585235588, 36.101723810629665, 0.0],
          ],
          [
            [-81.348404184170988, 36.09549221063935, 0.0],
            [-81.348206317504662, 36.096820610637167, 0.0],
            [-81.342026117514138, 36.0987884773009, 0.0],
            [-81.344056117510945, 36.103208810627279, 0.0],
          ],
          [
            [-80.662569585235588, 36.101723810629665, 0.0],
            [-80.654190185248581, 36.105756610623359, 0.0],
          ],
          [
            [-81.441050784027198, 36.109215877284726, 0.0],
            [-81.437774184032264, 36.105759877290097, 0.0],
          ],
          [
            [-80.508013518808809, 36.106537677288713, 0.0],
            [-80.508183718808482, 36.102632610628291, 0.0],
          ],
          [
            [-80.267149985849301, 36.064836210686906, 0.0],
            [-80.270114185844704, 36.062777877356609, 0.0],
            [-80.26918651917947, 36.060464210693738, 0.0],
            [-80.271303119176139, 36.059389677361935, 0.0],
          ],
          [
            [-80.508592985474479, 36.062905210689848, 0.0],
            [-80.506635318810936, 36.053906677370492, 0.0],
          ],
          [
            [-80.366767519027917, 36.06433541068759, 0.0],
            [-80.372662385685544, 36.057557210698178, 0.0],
            [-80.371196119021135, 36.054145010703451, 0.0],
          ],
          [
            [-81.412945984070745, 36.06157307735856, 0.0],
            [-81.413059517404008, 36.063015677356418, 0.0],
            [-81.407673584078964, 36.065788277352056, 0.0],
            [-81.404034384084639, 36.063842810688357, 0.0],
            [-81.399098517425614, 36.064210277354562, 0.0],
          ],
          [
            [-80.767894785072031, 36.064603877353875, 0.0],
            [-80.769392918403014, 36.062177677357624, 0.0],
            [-80.76666131840733, 36.059060677362595, 0.0],
            [-80.770023785068759, 36.054414810703065, 0.0],
            [-80.768274385071436, 36.054963010702238, 0.0],
          ],
          [
            [-80.908641184853593, 36.06443967735413, 0.0],
            [-80.903253918195219, 36.064189277354558, 0.0],
            [-80.900658184865961, 36.061876810691444, 0.0],
            [-80.898542984869209, 36.062678810690102, 0.0],
            [-80.899162318201661, 36.058487877363348, 0.0],
            [-80.896145184872978, 36.061511410692106, 0.0],
            [-80.892140318212512, 36.062015810691207, 0.0],
            [-80.892223318212359, 36.054641677369318, 0.0],
          ],
          [
            [-80.259950185860475, 36.070073410678788, 0.0],
            [-80.267149985849301, 36.064836210686906, 0.0],
          ],
          [
            [-81.399098517425614, 36.064210277354562, 0.0],
            [-81.395685317430946, 36.062837010689861, 0.0],
            [-81.388128984109301, 36.075708077336685, 0.0],
          ],
          [
            [-80.77234891839845, 36.066393877351175, 0.0],
            [-80.772548985064873, 36.064287210687667, 0.0],
            [-80.767894785072031, 36.064603877353875, 0.0],
          ],
          [
            [-80.509020718807221, 36.068535810681055, 0.0],
            [-80.508592985474479, 36.062905210689848, 0.0],
          ],
          [
            [-80.783990318380404, 36.07309041067407, 0.0],
            [-80.781055985051637, 36.073729277339737, 0.0],
            [-80.776489318392066, 36.071115010677033, 0.0],
            [-80.776039785059368, 36.069740477345988, 0.0],
            [-80.778920985054924, 36.066422277351023, 0.0],
            [-80.77788011838993, 36.064177210687774, 0.0],
            [-80.774407585061908, 36.066739277350621, 0.0],
            [-80.773986118395896, 36.065479277352608, 0.0],
            [-80.77234891839845, 36.066393877351175, 0.0],
          ],
          [
            [-81.413718184069637, 36.081908210660401, 0.0],
            [-81.409652517409256, 36.075291410670729, 0.0],
            [-81.406775584080322, 36.075956277336275, 0.0],
            [-81.400904784089391, 36.067438877349559, 0.0],
            [-81.398281584093581, 36.067279210683182, 0.0],
            [-81.399098517425614, 36.064210277354562, 0.0],
          ],
          [
            [-80.367300785693828, 36.086823210652824, 0.0],
            [-80.364029119032182, 36.081395610661161, 0.0],
            [-80.366767519027917, 36.06433541068759, 0.0],
          ],
          [
            [-81.249800784324066, 36.128168610588546, 0.0],
            [-81.239673917672974, 36.12836127725501, 0.0],
          ],
          [
            [-81.22677011769315, 36.134787010578236, 0.0],
            [-81.222881317699148, 36.136515677242244, 0.0],
          ],
          [
            [-81.221837117700659, 36.136768410575201, 0.0],
            [-81.215653117710417, 36.135674077243664, 0.0],
          ],
          [
            [-81.222881317699148, 36.136515677242244, 0.0],
            [-81.221837117700659, 36.136768410575201, 0.0],
          ],
          [
            [-81.239673917672974, 36.12836127725501, 0.0],
            [-81.227113584359188, 36.134634277245198, 0.0],
          ],
          [
            [-81.227113584359188, 36.134634277245198, 0.0],
            [-81.22677011769315, 36.134787010578236, 0.0],
          ],
          [
            [-81.215653117710417, 36.135674077243664, 0.0],
            [-81.2096655177196, 36.133319810580645, 0.0],
          ],
          [
            [-81.260468517640788, 36.12481587726046, 0.0],
            [-81.249800784324066, 36.128168610588546, 0.0],
          ],
          [
            [-81.294220984255048, 36.131376610583629, 0.0],
            [-81.294219517588431, 36.128507610588031, 0.0],
            [-81.290767784260481, 36.125042210593506, 0.0],
          ],
          [
            [-80.44464911890708, 36.13293407724791, 0.0],
            [-80.451384718896634, 36.12615447725841, 0.0],
          ],
          [
            [-81.181246317763794, 36.145176477228745, 0.0],
            [-81.177519184436278, 36.14508667722896, 0.0],
          ],
          [
            [-80.437349785585127, 36.172328010519948, 0.0],
            [-80.437158585585451, 36.166348010529305, 0.0],
          ],
          [
            [-80.438333385583633, 36.173724410517934, 0.0],
            [-80.437349785585127, 36.172328010519948, 0.0],
          ],
          [
            [-81.136750384499521, 36.157052210543725, 0.0],
            [-81.131865317840436, 36.159617477206439, 0.0],
          ],
          [
            [-81.177519184436278, 36.14508667722896, 0.0],
            [-81.176362184438005, 36.146003210560878, 0.0],
            [-81.177718984435955, 36.148224010557385, 0.0],
            [-81.177043117770211, 36.150743277220215, 0.0],
            [-81.170889517779756, 36.154158077214845, 0.0],
            [-81.152787984474571, 36.151507410552313, 0.0],
            [-81.146830317817148, 36.152493477217433, 0.0],
          ],
          [
            [-81.146830317817148, 36.152493477217433, 0.0],
            [-81.143781384488591, 36.154944277213644, 0.0],
            [-81.139319917828857, 36.155586277212763, 0.0],
          ],
          [
            [-81.139319917828857, 36.155586277212763, 0.0],
            [-81.136750384499521, 36.157052210543725, 0.0],
          ],
          [
            [-81.136750384499521, 36.157052210543725, 0.0],
            [-81.131865317840436, 36.159617477206439, 0.0],
          ],
          [
            [-81.169176117782513, 36.169593610524316, 0.0],
            [-81.165023517788939, 36.168175410526487, 0.0],
            [-81.159767184463817, 36.161535877203391, 0.0],
            [-81.156915117801475, 36.161971877202859, 0.0],
            [-81.151745717809604, 36.158217477208552, 0.0],
            [-81.146830317817148, 36.152493477217433, 0.0],
          ],
          [
            [-80.437158585585451, 36.166348010529305, 0.0],
            [-80.438995118915841, 36.160951410537734, 0.0],
            [-80.442884518909864, 36.156014877211987, 0.0],
          ],
          [
            [-81.049795517967766, 36.194403877152467, 0.0],
            [-81.047787384637559, 36.196669810482319, 0.0],
          ],
          [
            [-81.070905917935079, 36.185896077165637, 0.0],
            [-81.067401784607057, 36.187520410496404, 0.0],
          ],
          [
            [-81.070905917935079, 36.185896077165637, 0.0],
            [-81.070000117936502, 36.188643677161338, 0.0],
          ],
          [
            [-81.070000117936502, 36.188643677161338, 0.0],
            [-81.068163384606009, 36.189284210493668, 0.0],
            [-81.067401784607057, 36.187520410496404, 0.0],
          ],
          [
            [-81.080201317920626, 36.183335010502901, 0.0],
            [-81.07576778459412, 36.181066210506515, 0.0],
            [-81.072659517932379, 36.182576277170767, 0.0],
          ],
          [
            [-81.080201317920626, 36.183335010502901, 0.0],
            [-81.072659517932379, 36.182576277170767, 0.0],
          ],
          [
            [-81.072659517932379, 36.182576277170767, 0.0],
            [-81.070905917935079, 36.185896077165637, 0.0],
          ],
          [
            [-81.175967117771961, 36.188598077161487, 0.0],
            [-81.17413051777487, 36.188026410495638, 0.0],
            [-81.176280784438177, 36.192994877154547, 0.0],
            [-81.175123984439892, 36.195239610484407, 0.0],
          ],
          [
            [-81.067401784607057, 36.187520410496404, 0.0],
            [-81.063588717946402, 36.186579677164502, 0.0],
            [-81.055223984626082, 36.189895810492771, 0.0],
            [-81.049795517967766, 36.194403877152467, 0.0],
          ],
          [
            [-80.439086718915689, 36.17623067718057, 0.0],
            [-80.438333385583633, 36.173724410517934, 0.0],
          ],
          [
            [-81.170051984447809, 36.170463610522916, 0.0],
            [-81.169176117782513, 36.169593610524316, 0.0],
          ],
          [
            [-81.131865317840436, 36.159617477206439, 0.0],
            [-81.134803117835872, 36.1650218105313, 0.0],
            [-81.132685317839162, 36.16886927719213, 0.0],
            [-81.123901717852789, 36.171663410521091, 0.0],
            [-81.111191784539074, 36.171204877188416, 0.0],
            [-81.103960917883683, 36.172967210519062, 0.0],
          ],
          [
            [-81.175123984439892, 36.195239610484407, 0.0],
            [-81.166385984453484, 36.180884477173379, 0.0],
            [-81.166130117787247, 36.177793077178194, 0.0],
            [-81.170140584447608, 36.176944410512931, 0.0],
            [-81.170051984447809, 36.170463610522916, 0.0],
          ],
          [
            [-81.103960917883683, 36.172967210519062, 0.0],
            [-81.100147584556339, 36.173791010517732, 0.0],
            [-81.098734584558542, 36.176309810513885, 0.0],
            [-81.089834584572372, 36.182147210504809, 0.0],
            [-81.080201317920626, 36.183335010502901, 0.0],
          ],
          [
            [-81.300312784245648, 36.137769810573673, 0.0],
            [-81.294220984255048, 36.131376610583629, 0.0],
          ],
          [
            [-80.439152785582337, 36.140636810569276, 0.0],
            [-80.439287518915364, 36.136536677242191, 0.0],
            [-80.44464911890708, 36.13293407724791, 0.0],
          ],
          [
            [-81.2096655177196, 36.133319810580645, 0.0],
            [-81.203231517729648, 36.135660077243585, 0.0],
            [-81.201318384399315, 36.142462810566371, 0.0],
          ],
          [
            [-81.301574517577023, 36.147526010558465, 0.0],
            [-81.299119784247466, 36.145578010561564, 0.0],
            [-81.301577117576983, 36.144594677229634, 0.0],
            [-81.299885784246271, 36.141548077234461, 0.0],
            [-81.300312784245648, 36.137769810573673, 0.0],
          ],
          [
            [-81.201318384399315, 36.142462810566371, 0.0],
            [-81.18881438441872, 36.146684077226496, 0.0],
            [-81.181246317763794, 36.145176477228745, 0.0],
          ],
          [
            [-80.442884518909864, 36.156014877211987, 0.0],
            [-80.442744185576714, 36.149788077221672, 0.0],
            [-80.439152785582337, 36.140636810569276, 0.0],
          ],
          [
            [-80.43175711892718, 36.217267077117015, 0.0],
            [-80.431730185593892, 36.216630077117941, 0.0],
          ],
          [
            [-80.972568584754299, 36.214299810454747, 0.0],
            [-80.961735118104514, 36.216291010451812, 0.0],
          ],
          [
            [-80.932657318149609, 36.214927010453891, 0.0],
            [-80.926425318159318, 36.214260810454903, 0.0],
            [-80.922390718165559, 36.216912210450801, 0.0],
          ],
          [
            [-81.206839317724075, 36.210129810461297, 0.0],
            [-81.202114917731421, 36.204820077136276, 0.0],
            [-81.199742784401735, 36.206172677134134, 0.0],
            [-81.202572184397354, 36.209834877128401, 0.0],
            [-81.201498984398995, 36.21056841046061, 0.0],
            [-81.197028984405904, 36.204914877136105, 0.0],
            [-81.189877184417071, 36.201438210474862, 0.0],
            [-81.175967117771961, 36.188598077161487, 0.0],
          ],
          [
            [-80.999799318045461, 36.201187210475268, 0.0],
            [-80.995804784718246, 36.198384877146282, 0.0],
          ],
          [
            [-81.047787384637559, 36.196669810482319, 0.0],
            [-81.046711784639285, 36.198684410479189, 0.0],
            [-81.044790584642215, 36.198614477145952, 0.0],
            [-81.033070384660391, 36.194140610486215, 0.0],
            [-81.01958398468139, 36.201618210474521, 0.0],
            [-81.008706118031569, 36.201379277141655, 0.0],
          ],
          [
            [-81.008706118031569, 36.201379277141655, 0.0],
            [-80.999799318045461, 36.201187210475268, 0.0],
          ],
          [
            [-80.444025718908051, 36.201142277141912, 0.0],
            [-80.439329185582096, 36.189249077160468, 0.0],
            [-80.443757718908444, 36.181722610505403, 0.0],
            [-80.439086718915689, 36.17623067718057, 0.0],
          ],
          [
            [-80.995804784718246, 36.198384877146282, 0.0],
            [-80.990716118059538, 36.198373077146243, 0.0],
            [-80.984328118069413, 36.203259610471946, 0.0],
            [-80.984119318069702, 36.205891210467996, 0.0],
          ],
          [
            [-80.954473184782444, 36.202572677139642, 0.0],
            [-80.948369118125242, 36.203437410471736, 0.0],
          ],
          [
            [-80.446234385571302, 36.208147277131047, 0.0],
            [-80.444025718908051, 36.201142277141912, 0.0],
          ],
          [
            [-80.948369118125242, 36.203437410471736, 0.0],
            [-80.942685584800756, 36.206201610467531, 0.0],
            [-80.945771584795921, 36.21064461046052, 0.0],
            [-80.943947984798797, 36.214969877120552, 0.0],
            [-80.932657318149609, 36.214927010453891, 0.0],
          ],
          [
            [-80.984119318069702, 36.205891210467996, 0.0],
            [-80.977763984746275, 36.21021187712779, 0.0],
          ],
          [
            [-80.961735118104514, 36.216291010451812, 0.0],
            [-80.960229584773515, 36.215518877119621, 0.0],
            [-80.960587918106285, 36.212146610458149, 0.0],
            [-80.964954184766157, 36.206245210467443, 0.0],
            [-80.963549318101741, 36.202142810473674, 0.0],
            [-80.961525584771493, 36.201126410475354, 0.0],
            [-80.959373118108203, 36.203131610472155, 0.0],
            [-80.954473184782444, 36.202572677139642, 0.0],
          ],
          [
            [-80.431730185593892, 36.216630077117941, 0.0],
            [-80.443245785575925, 36.212569010457571, 0.0],
            [-80.446234385571302, 36.208147277131047, 0.0],
          ],
          [
            [-80.977763984746275, 36.21021187712779, 0.0],
            [-80.972568584754299, 36.214299810454747, 0.0],
          ],
          [
            [-81.444692184021562, 36.110084010616731, 0.0],
            [-81.441050784027198, 36.109215877284726, 0.0],
          ],
          [
            [-80.615839118641418, 36.106744677288475, 0.0],
            [-80.588747785350165, 36.106350410622383, 0.0],
            [-80.583412385358372, 36.109646410617245, 0.0],
            [-80.581466585361341, 36.107057810621427, 0.0],
            [-80.573536785373733, 36.106802877288487, 0.0],
          ],
          [
            [-80.645696985261736, 36.110224010616378, 0.0],
            [-80.639262918605084, 36.112331877279814, 0.0],
            [-80.633759185280269, 36.109172210617999, 0.0],
            [-80.629385118620405, 36.111874677280525, 0.0],
            [-80.627211985290387, 36.109744877283902, 0.0],
            [-80.620184785301376, 36.108943477285095, 0.0],
            [-80.615839118641418, 36.106744677288475, 0.0],
          ],
          [
            [-81.464057117324842, 36.114307477276839, 0.0],
            [-81.462251183994226, 36.1145836106096, 0.0],
            [-81.459687383998187, 36.118593010603433, 0.0],
            [-81.455986317337306, 36.115252077275215, 0.0],
            [-81.453362317341373, 36.115963810607582, 0.0],
            [-81.449292717347646, 36.110516277282727, 0.0],
            [-81.444692184021562, 36.110084010616731, 0.0],
          ],
          [
            [-80.491367785501325, 36.114744277276031, 0.0],
            [-80.505079518813318, 36.109193810618024, 0.0],
            [-80.508013518808809, 36.106537677288713, 0.0],
          ],
          [
            [-81.316000984221262, 36.110320610616213, 0.0],
            [-81.313174784225623, 36.112009810613699, 0.0],
          ],
          [
            [-80.567101585383739, 36.108242810619458, 0.0],
            [-80.563573918722511, 36.108332610619414, 0.0],
            [-80.560099785394584, 36.111903210613775, 0.0],
            [-80.556741118733157, 36.112290677279816, 0.0],
          ],
          [
            [-80.556741118733157, 36.112290677279816, 0.0],
            [-80.553946918737495, 36.112472277279551, 0.0],
            [-80.554170385403836, 36.114831210609282, 0.0],
            [-80.551742185407591, 36.11597481060744, 0.0],
            [-80.546297318749339, 36.113864410610802, 0.0],
            [-80.539100318760461, 36.114065677277097, 0.0],
            [-80.53425051876809, 36.110146077283162, 0.0],
          ],
          [
            [-80.507647985475955, 36.122191677264482, 0.0],
            [-80.509232118806892, 36.119857410601469, 0.0],
            [-80.508489185474673, 36.106940677288151, 0.0],
          ],
          [
            [-81.313174784225623, 36.112009810613699, 0.0],
            [-81.308987984232203, 36.11149021061442, 0.0],
            [-81.305105384238232, 36.108604277285622, 0.0],
            [-81.302169317576102, 36.108998810618402, 0.0],
            [-81.301724184243312, 36.110461810616016, 0.0],
            [-81.305179784238078, 36.112282610613192, 0.0],
            [-81.303576717573947, 36.113981277277219, 0.0],
            [-81.303985317573279, 36.117574610605004, 0.0],
            [-81.297486384249964, 36.115881877274262, 0.0],
            [-81.292627717590847, 36.121224677266014, 0.0],
            [-81.28435398427041, 36.113996677277214, 0.0],
            [-81.282117717607207, 36.113655410611102, 0.0],
          ],
          [
            [-80.353158785715834, 36.112423477279776, 0.0],
            [-80.358636185707269, 36.102394277295332, 0.0],
          ],
          [
            [-80.53425051876809, 36.110146077283162, 0.0],
            [-80.526013185447482, 36.107460210620786, 0.0],
            [-80.522155585453504, 36.101022010630743, 0.0],
          ],
          [
            [-81.344056117510945, 36.103208810627279, 0.0],
            [-81.330880317531523, 36.098120877301824, 0.0],
            [-81.323512517542952, 36.103179077293987, 0.0],
            [-81.322747784210719, 36.107598610620471, 0.0],
            [-81.314903517556331, 36.106014810623037, 0.0],
            [-81.316000984221262, 36.110320610616213, 0.0],
          ],
          [
            [-80.838162318296327, 36.109599677283995, 0.0],
            [-80.837316784964287, 36.107469677287327, 0.0],
            [-80.83398751830282, 36.105934210623104, 0.0],
            [-80.836274918299239, 36.102660210628073, 0.0],
          ],
          [
            [-80.654190185248581, 36.105756610623359, 0.0],
            [-80.645696985261736, 36.110224010616378, 0.0],
          ],
          [
            [-81.468066517318562, 36.115815677274441, 0.0],
            [-81.464057117324842, 36.114307477276839, 0.0],
          ],
          [
            [-81.493811583945273, 36.119090077269334, 0.0],
            [-81.491217983949355, 36.121085010599472, 0.0],
            [-81.488224117287302, 36.119782677268347, 0.0],
          ],
          [
            [-81.487712317288128, 36.117195410605575, 0.0],
            [-81.487146583955678, 36.116417277273399, 0.0],
          ],
          [
            [-81.487712317288128, 36.117195410605575, 0.0],
            [-81.484776583959331, 36.116992210605986, 0.0],
          ],
          [
            [-80.482650185514842, 36.116134477273988, 0.0],
            [-80.491367785501325, 36.114744277276031, 0.0],
          ],
          [
            [-81.470015983982194, 36.117417210605311, 0.0],
            [-81.468066517318562, 36.115815677274441, 0.0],
          ],
          [
            [-81.488224117287302, 36.119782677268347, 0.0],
            [-81.487712317288128, 36.117195410605575, 0.0],
          ],
          [
            [-80.474186185527913, 36.11874047726991, 0.0],
            [-80.482650185514842, 36.116134477273988, 0.0],
          ],
          [
            [-80.465391185541648, 36.11728921060552, 0.0],
            [-80.467662985538027, 36.11784101060465, 0.0],
          ],
          [
            [-80.467662985538027, 36.11784101060465, 0.0],
            [-80.474186185527913, 36.11874047726991, 0.0],
          ],
          [
            [-81.484776583959331, 36.116992210605986, 0.0],
            [-81.480690717298955, 36.121713277265201, 0.0],
            [-81.477242517304262, 36.118189677270664, 0.0],
            [-81.470015983982194, 36.117417210605311, 0.0],
          ],
          [
            [-80.348555719056264, 36.121399410599054, 0.0],
            [-80.352339319050373, 36.116224677273806, 0.0],
            [-80.353158785715834, 36.112423477279776, 0.0],
          ],
          [
            [-80.852410518274212, 36.123045677263178, 0.0],
            [-80.844510784953115, 36.113403010611535, 0.0],
            [-80.844229918286942, 36.110082277283254, 0.0],
            [-80.838162318296327, 36.109599677283995, 0.0],
          ],
          [
            [-81.282117717607207, 36.113655410611102, 0.0],
            [-81.278055117613462, 36.117768210604652, 0.0],
            [-81.273970117619797, 36.119157877269231, 0.0],
          ],
          [
            [-80.451384718896634, 36.12615447725841, 0.0],
            [-80.453841718892818, 36.122359677264342, 0.0],
            [-80.465391185541648, 36.11728921060552, 0.0],
          ],
          [
            [-81.273970117619797, 36.119157877269231, 0.0],
            [-81.267330984296791, 36.120609210600264, 0.0],
          ],
          [
            [-80.345138985728283, 36.124925010593586, 0.0],
            [-80.348555719056264, 36.121399410599054, 0.0],
          ],
          [
            [-81.267330984296791, 36.120609210600264, 0.0],
            [-81.261908117638598, 36.122338077264317, 0.0],
            [-81.260468517640788, 36.12481587726046, 0.0],
          ],
          [
            [-81.290767784260481, 36.125042210593506, 0.0],
            [-81.28929938426262, 36.125239277259766, 0.0],
            [-81.29000771759496, 36.128110810588737, 0.0],
            [-81.288835184263462, 36.129230277253555, 0.0],
            [-81.286352784267308, 36.124873077260361, 0.0],
            [-81.279538784277804, 36.124785010593882, 0.0],
            [-81.273970117619797, 36.119157877269231, 0.0],
          ],
          [
            [-81.247108784328191, 36.229101877098628, 0.0],
            [-81.241142317670835, 36.227321677101259, 0.0],
            [-81.236170984345222, 36.229570677097797, 0.0],
          ],
          [
            [-80.447866185568842, 36.237881010418334, 0.0],
            [-80.438498518916674, 36.230822677095887, 0.0],
          ],
          [
            [-81.236170984345222, 36.229570677097797, 0.0],
            [-81.233801517682139, 36.232893477092773, 0.0],
            [-81.228065117691131, 36.23408941042419, 0.0],
            [-81.229304117689196, 36.230722077096061, 0.0],
            [-81.225398584361869, 36.226946877101852, 0.0],
            [-81.22197591770049, 36.224774277105269, 0.0],
            [-81.219235317704829, 36.225578010437403, 0.0],
          ],
          [
            [-81.269233184293853, 36.243316810409794, 0.0],
            [-81.261495917639195, 36.237218610419291, 0.0],
            [-81.261025717639995, 36.229798477097461, 0.0],
            [-81.251864384320754, 36.232056677094079, 0.0],
            [-81.25090818432227, 36.228872677098821, 0.0],
            [-81.247108784328191, 36.229101877098628, 0.0],
          ],
          [
            [-80.892722651545, 36.23562907708839, 0.0],
            [-80.875688184904732, 36.23539281042207, 0.0],
          ],
          [
            [-80.875688184904732, 36.23539281042207, 0.0],
            [-80.872545584909574, 36.236764010420018, 0.0],
          ],
          [
            [-80.872545584909574, 36.236764010420018, 0.0],
            [-80.86811711824987, 36.238983810416642, 0.0],
            [-80.85831271826504, 36.240554810414153, 0.0],
          ],
          [
            [-80.852935118273422, 36.241213077079863, 0.0],
            [-80.852663918273777, 36.2409974770801, 0.0],
          ],
          [
            [-80.85831271826504, 36.240554810414153, 0.0],
            [-80.852663918273777, 36.2409974770801, 0.0],
          ],
          [
            [-80.455902918889649, 36.244066810408754, 0.0],
            [-80.447866185568842, 36.237881010418334, 0.0],
          ],
          [
            [-81.041915517980044, 36.246336810405126, 0.0],
            [-81.037737784653132, 36.24047141041433, 0.0],
            [-81.031834517995662, 36.236161677087694, 0.0],
          ],
          [
            [-80.852969784940001, 36.241349477079552, 0.0],
            [-80.852935118273422, 36.241213077079863, 0.0],
          ],
          [
            [-80.45559811889018, 36.244419677074802, 0.0],
            [-80.455902918889649, 36.244066810408754, 0.0],
          ],
          [
            [-80.852663918273777, 36.2409974770801, 0.0],
            [-80.84338811828809, 36.243166477076784, 0.0],
          ],
          [
            [-80.455621518890098, 36.244533010407906, 0.0],
            [-80.45559811889018, 36.244419677074802, 0.0],
          ],
          [
            [-80.84338811828809, 36.243166477076784, 0.0],
            [-80.83495298496797, 36.245505810406314, 0.0],
          ],
          [
            [-80.486209118842623, 36.255830477057032, 0.0],
            [-80.485097585511028, 36.255811610390424, 0.0],
          ],
          [
            [-80.50384731881519, 36.259152010385208, 0.0],
            [-80.486209118842623, 36.255830477057032, 0.0],
          ],
          [
            [-80.485097585511028, 36.255811610390424, 0.0],
            [-80.480705918851186, 36.257010010388456, 0.0],
            [-80.478204985521756, 36.259849410384163, 0.0],
          ],
          [
            [-80.53414171876824, 36.25829647705325, 0.0],
            [-80.518527985459116, 36.258284277053292, 0.0],
          ],
          [
            [-80.518527985459116, 36.258284277053292, 0.0],
            [-80.510306718805168, 36.257969810387067, 0.0],
          ],
          [
            [-80.510306718805168, 36.257969810387067, 0.0],
            [-80.50384731881519, 36.259152010385208, 0.0],
          ],
          [
            [-81.045189317974973, 36.250780610398351, 0.0],
            [-81.044258917976435, 36.249429877067087, 0.0],
          ],
          [
            [-80.675108518549393, 36.253813210393616, 0.0],
            [-80.672538585219968, 36.251581210397092, 0.0],
            [-80.667203585228322, 36.250902743731444, 0.0],
          ],
          [
            [-80.802484185018329, 36.252488277062241, 0.0],
            [-80.796405785027787, 36.247910277069423, 0.0],
            [-80.785524785044629, 36.252085010396286, 0.0],
          ],
          [
            [-80.821636918321929, 36.25010201039936, 0.0],
            [-80.816058518330578, 36.251126210397786, 0.0],
          ],
          [
            [-80.461538785547532, 36.251077210397796, 0.0],
            [-80.460678585548919, 36.247543810403158, 0.0],
          ],
          [
            [-80.831193718307077, 36.247556810403353, 0.0],
            [-80.821636918321929, 36.25010201039936, 0.0],
          ],
          [
            [-81.044258917976435, 36.249429877067087, 0.0],
            [-81.041915517980044, 36.246336810405126, 0.0],
          ],
          [
            [-80.858339718265029, 36.247235610403777, 0.0],
            [-80.852969784940001, 36.241349477079552, 0.0],
          ],
          [
            [-80.460678585548919, 36.247543810403158, 0.0],
            [-80.455902918889649, 36.244066810408754, 0.0],
          ],
          [
            [-80.83495298496797, 36.245505810406314, 0.0],
            [-80.831193718307077, 36.247556810403353, 0.0],
          ],
          [
            [-80.448954785567082, 36.249987277066168, 0.0],
            [-80.449037118900321, 36.247193410403838, 0.0],
            [-80.451298785563495, 36.247146277070556, 0.0],
            [-80.452545518894908, 36.250145410399227, 0.0],
            [-80.455944985556243, 36.25127167706404, 0.0],
            [-80.455621518890098, 36.244533010407906, 0.0],
          ],
          [
            [-80.449053185566981, 36.257916877053788, 0.0],
            [-80.448954785567082, 36.249987277066168, 0.0],
          ],
          [
            [-80.816058518330578, 36.251126210397786, 0.0],
            [-80.809163918341255, 36.254104277059696, 0.0],
            [-80.802484185018329, 36.252488277062241, 0.0],
          ],
          [
            [-80.667203585228322, 36.250902743731444, 0.0],
            [-80.65545378524655, 36.251776277063357, 0.0],
            [-80.650296118587903, 36.254401077059356, 0.0],
          ],
          [
            [-80.785524785044629, 36.252085010396286, 0.0],
            [-80.779499985053917, 36.255869610390391, 0.0],
          ],
          [
            [-80.465847518874114, 36.259496810384803, 0.0],
            [-80.461805785547142, 36.255166210391337, 0.0],
            [-80.461538785547532, 36.251077210397796, 0.0],
          ],
          [
            [-80.634813385278619, 36.272404877031306, 0.0],
            [-80.630972518617796, 36.272096877031913, 0.0],
          ],
          [
            [-80.874936318239179, 36.27508247702724, 0.0],
            [-80.877792584901499, 36.274051877028853, 0.0],
          ],
          [
            [-80.769531585069501, 36.275449210360023, 0.0],
            [-80.769146318403386, 36.274671877027913, 0.0],
          ],
          [
            [-80.874285784906931, 36.276502210358331, 0.0],
            [-80.873889918240877, 36.27483061036088, 0.0],
            [-80.874936318239179, 36.27508247702724, 0.0],
          ],
          [
            [-80.533517385435857, 36.305143010313827, 0.0],
            [-80.533263518769559, 36.304524476981555, 0.0],
          ],
          [
            [-80.896860784871876, 36.297934210325081, 0.0],
            [-80.895417718207455, 36.297064276993126, 0.0],
          ],
          [
            [-80.902887918195859, 36.302077676985334, 0.0],
            [-80.900992518198791, 36.302146876985205, 0.0],
            [-80.896860784871876, 36.297934210325081, 0.0],
          ],
          [
            [-80.41195258562459, 36.306411210311978, 0.0],
            [-80.41492298561991, 36.305860676979478, 0.0],
            [-80.41622178561795, 36.30098281032042, 0.0],
            [-80.41361658562198, 36.295671076995234, 0.0],
          ],
          [
            [-80.694579785185852, 36.30327747698351, 0.0],
            [-80.694605518519097, 36.300231876988221, 0.0],
          ],
          [
            [-80.533263518769559, 36.304524476981555, 0.0],
            [-80.530241518774176, 36.300308810321326, 0.0],
          ],
          [
            [-80.696846918515632, 36.307260676977194, 0.0],
            [-80.694579785185852, 36.30327747698351, 0.0],
          ],
          [
            [-80.570333118712028, 36.280620410351958, 0.0],
            [-80.561405718725837, 36.282561210348945, 0.0],
          ],
          [
            [-80.561405718725837, 36.282561210348945, 0.0],
            [-80.55389278540423, 36.282279077016085, 0.0],
            [-80.547592118747389, 36.285004477011682, 0.0],
          ],
          [
            [-81.052311184630582, 36.284837277012059, 0.0],
            [-81.055877917958298, 36.281450077017269, 0.0],
            [-81.055342384625874, 36.27936601035384, 0.0],
            [-81.05076378463292, 36.27601987702576, 0.0],
            [-81.055854317958392, 36.276229010358804, 0.0],
            [-81.057270384622825, 36.273848077029186, 0.0],
          ],
          [
            [-80.619215585302811, 36.2831734770146, 0.0],
            [-80.615062518642617, 36.279341677020454, 0.0],
            [-80.601723518663221, 36.273683410362764, 0.0],
          ],
          [
            [-80.630972518617796, 36.272096877031913, 0.0],
            [-80.626317918625148, 36.274976077027304, 0.0],
            [-80.626536718624777, 36.277790810356407, 0.0],
            [-80.628820985287916, 36.279860077019862, 0.0],
          ],
          [
            [-80.879405118232285, 36.279822610353108, 0.0],
            [-80.874285784906931, 36.276502210358331, 0.0],
          ],
          [
            [-80.698604318512878, 36.280719210351776, 0.0],
            [-80.700348918510258, 36.271627010365819, 0.0],
            [-80.697012118515431, 36.271652010365926, 0.0],
            [-80.695798518517336, 36.274332010361718, 0.0],
            [-80.69362078518725, 36.274241677028556, 0.0],
          ],
          [
            [-80.547592118747389, 36.285004477011682, 0.0],
            [-80.539722118759585, 36.282205010349514, 0.0],
            [-80.537101918763597, 36.279456477020403, 0.0],
          ],
          [
            [-80.579889518697144, 36.277557610356666, 0.0],
            [-80.570333118712028, 36.280620410351958, 0.0],
          ],
          [
            [-80.773871918396139, 36.287246077008376, 0.0],
            [-80.769522118402847, 36.282639610348838, 0.0],
            [-80.770688718401061, 36.277373810357062, 0.0],
            [-80.769531585069501, 36.275449210360023, 0.0],
          ],
          [
            [-80.628820985287916, 36.279860077019862, 0.0],
            [-80.631476385283804, 36.284003810346633, 0.0],
            [-80.631071185284441, 36.28597761034365, 0.0],
          ],
          [
            [-80.437062385585591, 36.289890877004268, 0.0],
            [-80.437316318918533, 36.289203677005275, 0.0],
          ],
          [
            [-80.884836518223892, 36.28834087700659, 0.0],
            [-80.879405118232285, 36.279822610353108, 0.0],
          ],
          [
            [-80.547965185413375, 36.285551210344295, 0.0],
            [-80.547592118747389, 36.285004477011682, 0.0],
          ],
          [
            [-80.631071185284441, 36.28597761034365, 0.0],
            [-80.628672985288176, 36.287566410341185, 0.0],
            [-80.625723318626058, 36.287183877008374, 0.0],
            [-80.619215585302811, 36.2831734770146, 0.0],
          ],
          [
            [-80.885373918223081, 36.288730210339224, 0.0],
            [-80.884836518223892, 36.28834087700659, 0.0],
          ],
          [
            [-81.098382917892422, 36.299282610322962, 0.0],
            [-81.096121184562492, 36.294702076996771, 0.0],
          ],
          [
            [-80.430587585595561, 36.293626476998384, 0.0],
            [-80.431748518927179, 36.295160076996126, 0.0],
          ],
          [
            [-80.41361658562198, 36.295671076995234, 0.0],
            [-80.413643918955245, 36.294068010330932, 0.0],
            [-80.416642785617285, 36.294731210330042, 0.0],
            [-80.417323385616214, 36.297890876991858, 0.0],
            [-80.420351318944881, 36.299744810322295, 0.0],
            [-80.420998985610538, 36.294706676996611, 0.0],
            [-80.427812918933284, 36.289803477004284, 0.0],
            [-80.430587585595561, 36.293626476998384, 0.0],
          ],
          [
            [-81.041132317981237, 36.290623677003055, 0.0],
            [-81.04056838464885, 36.288928677005629, 0.0],
            [-81.042322117979438, 36.288975677005624, 0.0],
            [-81.046194317973402, 36.292138477000663, 0.0],
            [-81.047215184638446, 36.289505677004684, 0.0],
            [-81.048912317969211, 36.28955247700469, 0.0],
            [-81.049473984635029, 36.293789276998154, 0.0],
            [-81.051878117964577, 36.294340410330733, 0.0],
            [-81.052473384630332, 36.292943810332815, 0.0],
            [-81.049196784635456, 36.287858077007456, 0.0],
            [-81.05358158462866, 36.287425610341245, 0.0],
            [-81.052311184630582, 36.284837277012059, 0.0],
          ],
          [
            [-80.696974118515413, 36.291757610334628, 0.0],
            [-80.700540318509923, 36.293908010331393, 0.0],
            [-80.701982118507715, 36.293128410332599, 0.0],
            [-80.697079985181972, 36.284040610346665, 0.0],
            [-80.698604318512878, 36.280719210351776, 0.0],
          ],
          [
            [-80.795167385029686, 36.290674077002961, 0.0],
            [-80.789593518371703, 36.292113010334049, 0.0],
            [-80.789425785038588, 36.290120610337226, 0.0],
            [-80.784676318379354, 36.287850077007363, 0.0],
            [-80.784810585045761, 36.294033076997721, 0.0],
            [-80.778279385055953, 36.291371677001905, 0.0],
            [-80.776897385058021, 36.288118810340336, 0.0],
            [-80.773871918396139, 36.287246077008376, 0.0],
          ],
          [
            [-81.041089984647954, 36.303813876982588, 0.0],
            [-81.039765584650013, 36.299004076990002, 0.0],
            [-81.035974384655901, 36.299276210322944, 0.0],
            [-81.039401984650567, 36.294996410329588, 0.0],
            [-81.044862384642101, 36.294427610330501, 0.0],
            [-81.041132317981237, 36.290623677003055, 0.0],
          ],
          [
            [-80.895417718207455, 36.297064276993126, 0.0],
            [-80.891908518212801, 36.291248277002182, 0.0],
            [-80.8848371848905, 36.293470410331963, 0.0],
            [-80.883535584892513, 36.291111877002265, 0.0],
            [-80.885373918223081, 36.288730210339224, 0.0],
          ],
          [
            [-80.528238318777426, 36.295841876994984, 0.0],
            [-80.533105585436488, 36.294403010330598, 0.0],
            [-80.537097785430262, 36.29113127700225, 0.0],
            [-80.546519985415671, 36.288366877006581, 0.0],
            [-80.547965185413375, 36.285551210344295, 0.0],
          ],
          [
            [-80.431748518927179, 36.295160076996126, 0.0],
            [-80.437062385585591, 36.289890877004268, 0.0],
          ],
          [
            [-80.694605518519097, 36.300231876988221, 0.0],
            [-80.698222118513513, 36.295443676995603, 0.0],
            [-80.696974118515413, 36.291757610334628, 0.0],
          ],
          [
            [-80.802873985017754, 36.307968076976181, 0.0],
            [-80.796259518361296, 36.301895476985521, 0.0],
            [-80.797986385025354, 36.300660076987583, 0.0],
            [-80.798726185024123, 36.296355476994165, 0.0],
            [-80.795167385029686, 36.290674077002961, 0.0],
          ],
          [
            [-80.530241518774176, 36.300308810321326, 0.0],
            [-80.528238318777426, 36.295841876994984, 0.0],
          ],
          [
            [-80.650296118587903, 36.254401077059356, 0.0],
            [-80.648083185257974, 36.256647410389235, 0.0],
            [-80.64721518525937, 36.264766877043257, 0.0],
          ],
          [
            [-80.478204985521756, 36.259849410384163, 0.0],
            [-80.465847518874114, 36.259496810384803, 0.0],
          ],
          [
            [-80.536888385430643, 36.260428277049868, 0.0],
            [-80.53414171876824, 36.25829647705325, 0.0],
          ],
          [
            [-80.440181518914017, 36.265364277042238, 0.0],
            [-80.432711318925556, 36.258589877052827, 0.0],
            [-80.443481518908868, 36.255950810390175, 0.0],
            [-80.447470518902776, 36.258627677052743, 0.0],
            [-80.449053185566981, 36.257916877053788, 0.0],
          ],
          [
            [-80.779499985053917, 36.255869610390391, 0.0],
            [-80.778067118389572, 36.258217877053312, 0.0],
            [-80.779993185053229, 36.264416277043722, 0.0],
            [-80.779770985053517, 36.270212077034785, 0.0],
          ],
          [
            [-80.870411718246203, 36.265693477041737, 0.0],
            [-80.870383584912929, 36.262350210380362, 0.0],
            [-80.868178118249602, 36.261640210381302, 0.0],
          ],
          [
            [-80.64721518525937, 36.264766877043257, 0.0],
            [-80.64601258526119, 36.267523877039025, 0.0],
          ],
          [
            [-80.713589385156354, 36.266634210373695, 0.0],
            [-80.692389918522565, 36.266684077040225, 0.0],
          ],
          [
            [-80.692681185188803, 36.267097477039613, 0.0],
            [-80.692389918522565, 36.266684077040225, 0.0],
          ],
          [
            [-81.061430584616403, 36.270278010368088, 0.0],
            [-81.045189317974973, 36.250780610398351, 0.0],
          ],
          [
            [-80.868178118249602, 36.261640210381302, 0.0],
            [-80.867725784917127, 36.259716610384316, 0.0],
            [-80.863315184923977, 36.257334677054814, 0.0],
            [-80.865068384921244, 36.256418877056149, 0.0],
            [-80.858339718265029, 36.247235610403777, 0.0],
          ],
          [
            [-80.692389918522565, 36.266684077040225, 0.0],
            [-80.685625918533106, 36.268596410370662, 0.0],
            [-80.679921785208592, 36.265326477042322, 0.0],
            [-80.675108518549393, 36.253813210393616, 0.0],
          ],
          [
            [-80.537101918763597, 36.279456477020403, 0.0],
            [-80.535607718765903, 36.272801477030725, 0.0],
            [-80.537497585429719, 36.265194610375943, 0.0],
            [-80.536888385430643, 36.260428277049868, 0.0],
          ],
          [
            [-81.076731717925952, 36.267537010372223, 0.0],
            [-81.073987584596921, 36.269184810369609, 0.0],
            [-81.076784784592576, 36.27333067702989, 0.0],
            [-81.075992384593803, 36.274177677028604, 0.0],
            [-81.07053751793552, 36.269183277036348, 0.0],
            [-81.066888917941299, 36.269868477035288, 0.0],
            [-81.064798184611163, 36.267142410372742, 0.0],
          ],
          [
            [-80.64601258526119, 36.267523877039025, 0.0],
            [-80.641899585267595, 36.272816677030733, 0.0],
            [-80.634813385278619, 36.272404877031306, 0.0],
          ],
          [
            [-80.722861385141925, 36.269602410369032, 0.0],
            [-80.71869931848164, 36.269699010368811, 0.0],
            [-80.713589385156354, 36.266634210373695, 0.0],
          ],
          [
            [-81.064798184611163, 36.267142410372742, 0.0],
            [-81.063155784613741, 36.270072810368333, 0.0],
            [-81.061430584616403, 36.270278010368088, 0.0],
          ],
          [
            [-80.874794784906157, 36.269632277035669, 0.0],
            [-80.871825584910766, 36.268716277037129, 0.0],
            [-80.870411718246203, 36.265693477041737, 0.0],
          ],
          [
            [-80.769146318403386, 36.274671877027913, 0.0],
            [-80.752840585095441, 36.263205810378849, 0.0],
          ],
          [
            [-80.752840585095441, 36.263205810378849, 0.0],
            [-80.750130718432956, 36.263996810377705, 0.0],
            [-80.744557118441605, 36.270243877034773, 0.0],
            [-80.742754518444428, 36.27596321035918, 0.0],
          ],
          [
            [-81.057270384622825, 36.273848077029186, 0.0],
            [-81.057075584623078, 36.270184077034799, 0.0],
            [-81.061430584616403, 36.270278010368088, 0.0],
          ],
          [
            [-80.877792584901499, 36.274051877028853, 0.0],
            [-80.878669184900104, 36.272884010363953, 0.0],
            [-80.874794784906157, 36.269632277035669, 0.0],
          ],
          [
            [-80.69362078518725, 36.274241677028556, 0.0],
            [-80.692290718522713, 36.273280677030016, 0.0],
            [-80.692681185188803, 36.267097477039613, 0.0],
          ],
          [
            [-80.601723518663221, 36.273683410362764, 0.0],
            [-80.597368785336755, 36.270837677033796, 0.0],
            [-80.591755985345458, 36.270234810368038, 0.0],
          ],
          [
            [-80.779770985053517, 36.270212077034785, 0.0],
            [-80.775322185060475, 36.274072410362066, 0.0],
            [-80.769146318403386, 36.274671877027913, 0.0],
          ],
          [
            [-80.591755985345458, 36.270234810368038, 0.0],
            [-80.579889518697144, 36.277557610356666, 0.0],
          ],
          [
            [-80.437316318918533, 36.289203677005275, 0.0],
            [-80.430781985595218, 36.288657277006109, 0.0],
            [-80.431601318927392, 36.287260010341583, 0.0],
            [-80.429247518930993, 36.278490677021807, 0.0],
            [-80.43544018558805, 36.277297077023775, 0.0],
            [-80.435492918921227, 36.27225927703148, 0.0],
            [-80.441994318911213, 36.26916441036974, 0.0],
            [-80.440181518914017, 36.265364277042238, 0.0],
          ],
          [
            [-80.742754518444428, 36.27596321035918, 0.0],
            [-80.74005718511529, 36.279034877021047, 0.0],
            [-80.736519985120708, 36.279972410352855, 0.0],
            [-80.732260518460691, 36.27663807702487, 0.0],
            [-80.734646718457043, 36.268841410370158, 0.0],
            [-80.733836718458235, 36.267344210372585, 0.0],
            [-80.730421585130216, 36.266904277039828, 0.0],
            [-80.722861385141925, 36.269602410369032, 0.0],
          ],
          [
            [-81.096121184562492, 36.294702076996771, 0.0],
            [-81.094169917898967, 36.293556676998492, 0.0],
            [-81.087662317909064, 36.296211276994484, 0.0],
            [-81.088653584574161, 36.293738476998215, 0.0],
            [-81.085545317912306, 36.286432477009612, 0.0],
            [-81.083057317916143, 36.284302010346266, 0.0],
            [-81.085294317912712, 36.279173210354259, 0.0],
            [-81.079782517921274, 36.273400610363183, 0.0],
            [-81.076731717925952, 36.267537010372223, 0.0],
          ],
          [
            [-80.919316984836996, 36.337406676930414, 0.0],
            [-80.917389984839929, 36.333285610270195, 0.0],
            [-80.911333318182756, 36.333012810270532, 0.0],
          ],
          [
            [-80.417288718949578, 36.338582410261893, 0.0],
            [-80.409276518962031, 36.332562410271294, 0.0],
            [-80.409443118961804, 36.32544087694896, 0.0],
          ],
          [
            [-80.917784318172664, 36.329713010275725, 0.0],
            [-80.916791718174295, 36.32588921028173, 0.0],
          ],
          [
            [-80.911333318182756, 36.333012810270532, 0.0],
            [-80.917247384840209, 36.331156010273389, 0.0],
            [-80.917784318172664, 36.329713010275725, 0.0],
          ],
          [
            [-80.819998718324484, 36.338044076929464, 0.0],
            [-80.815163584998686, 36.331584210272808, 0.0],
            [-80.816608518329701, 36.329455210276024, 0.0],
            [-80.81556311833134, 36.327187610279736, 0.0],
          ],
          [
            [-80.54216458542237, 36.368258876882521, 0.0],
            [-80.541603185423185, 36.363610010223056, 0.0],
            [-80.534474585434396, 36.358223610231448, 0.0],
          ],
          [
            [-80.679774585208861, 36.37109001021156, 0.0],
            [-80.675751585215096, 36.367657010216874, 0.0],
            [-80.678524518544009, 36.364701810221391, 0.0],
            [-80.675662718548551, 36.361588676892836, 0.0],
          ],
          [
            [-80.539326318760175, 36.37446247687302, 0.0],
            [-80.5385653187613, 36.371324810211092, 0.0],
            [-80.54216458542237, 36.368258876882521, 0.0],
          ],
          [
            [-80.685443918533338, 36.378964476865917, 0.0],
            [-80.679582118542498, 36.379310810198774, 0.0],
            [-80.680628918540833, 36.377867676867709, 0.0],
            [-80.684168785201962, 36.378232410200496, 0.0],
            [-80.679774585208861, 36.37109001021156, 0.0],
          ],
          [
            [-80.544841585418283, 36.380649010196692, 0.0],
            [-80.539326318760175, 36.37446247687302, 0.0],
          ],
          [
            [-80.547040718748121, 36.333684676936116, 0.0],
            [-80.541779985422977, 36.330566876941077, 0.0],
          ],
          [
            [-80.68371471853601, 36.339006276927933, 0.0],
            [-80.678782185210309, 36.327971276945163, 0.0],
            [-80.681412785206305, 36.326184010281224, 0.0],
          ],
          [
            [-80.5537091854045, 36.345481676917984, 0.0],
            [-80.555494185401756, 36.343444676921081, 0.0],
            [-80.550487785409473, 36.339663410260187, 0.0],
            [-80.547040718748121, 36.333684676936116, 0.0],
          ],
          [
            [-80.520842518788754, 36.348182876913768, 0.0],
            [-80.520136918789945, 36.346533610249537, 0.0],
            [-80.521610318787623, 36.345458676918042, 0.0],
            [-80.525230985448673, 36.347385210248262, 0.0],
            [-80.532477785437493, 36.347184876915207, 0.0],
            [-80.533442518769277, 36.345078876918592, 0.0],
            [-80.529765985441713, 36.342465610255999, 0.0],
            [-80.53047558544057, 36.340863076925075, 0.0],
            [-80.533928318768517, 36.34118641025799, 0.0],
            [-80.538226385428516, 36.344990810251943, 0.0],
            [-80.540802118757824, 36.345038410251959, 0.0],
            [-80.545107585417895, 36.342178876923072, 0.0],
            [-80.5537091854045, 36.345481676917984, 0.0],
          ],
          [
            [-80.675662718548551, 36.361588676892836, 0.0],
            [-80.674867318549786, 36.357307010232887, 0.0],
            [-80.678971918543368, 36.356847076900237, 0.0],
            [-80.67803518521157, 36.353183676905985, 0.0],
            [-80.680156185208261, 36.350160076910754, 0.0],
            [-80.679162185209805, 36.345351676918085, 0.0],
            [-80.685586718533159, 36.344020276920162, 0.0],
            [-80.685980318532529, 36.340585276925538, 0.0],
            [-80.68371471853601, 36.339006276927933, 0.0],
          ],
          [
            [-80.534474585434396, 36.358223610231448, 0.0],
            [-80.529211718775741, 36.355861010235117, 0.0],
            [-80.528851185443045, 36.349815410244503, 0.0],
            [-80.520842518788754, 36.348182876913768, 0.0],
          ],
          [
            [-80.474319718861011, 36.405963276823968, 0.0],
            [-80.474205185527865, 36.404933010158913, 0.0],
            [-80.47973038551936, 36.406096210157102, 0.0],
            [-80.480011518852223, 36.404447276826431, 0.0],
            [-80.483324718847143, 36.403642810161045, 0.0],
          ],
          [
            [-80.483324718847143, 36.403642810161045, 0.0],
            [-80.483491918846767, 36.401627610164098, 0.0],
            [-80.486321385509143, 36.399426810167483, 0.0],
            [-80.487682718840347, 36.400593410165754, 0.0],
            [-80.490765585502231, 36.3974304101705, 0.0],
            [-80.49518271882863, 36.396487276838741, 0.0],
            [-80.495831518827686, 36.394746410174776, 0.0],
          ],
          [
            [-80.701286785175455, 36.421547276799743, 0.0],
            [-80.697093585181847, 36.421778876799578, 0.0],
          ],
          [
            [-80.697093585181847, 36.421778876799578, 0.0],
            [-80.694827318518719, 36.422238410132195, 0.0],
          ],
          [
            [-80.571059918710944, 36.428407410122475, 0.0],
            [-80.56769451871611, 36.419475276803098, 0.0],
            [-80.569594185379799, 36.41773587680575, 0.0],
          ],
          [
            [-80.715977718485931, 36.4334672767813, 0.0],
            [-80.713083318490476, 36.430103276786667, 0.0],
            [-80.714127385155507, 36.42620967679261, 0.0],
            [-80.712371718491511, 36.427172810124432, 0.0],
            [-80.705513118502154, 36.425987010126335, 0.0],
            [-80.702762585173161, 36.424134010129137, 0.0],
            [-80.704659385170203, 36.422484010131711, 0.0],
            [-80.701286785175455, 36.421547276799743, 0.0],
          ],
          [
            [-80.690708518525184, 36.409783676818165, 0.0],
            [-80.693453318520938, 36.406141210157102, 0.0],
            [-80.696569585182715, 36.406391210156812, 0.0],
            [-80.698069118513729, 36.404421010159695, 0.0],
            [-80.69647578518294, 36.396727876838384, 0.0],
          ],
          [
            [-80.565322185386492, 36.409490276818588, 0.0],
            [-80.561841385391858, 36.405091876825395, 0.0],
          ],
          [
            [-80.569594185379799, 36.41773587680575, 0.0],
            [-80.569992185379249, 36.41553787680914, 0.0],
            [-80.565322185386492, 36.409490276818588, 0.0],
          ],
          [
            [-80.694827318518719, 36.422238410132195, 0.0],
            [-80.69264558518887, 36.422331210131858, 0.0],
            [-80.692811918521954, 36.418049010138702, 0.0],
            [-80.6888687851947, 36.411960076814751, 0.0],
            [-80.690708518525184, 36.409783676818165, 0.0],
          ],
          [
            [-80.744949585107634, 36.458721810075417, 0.0],
            [-80.742900385110829, 36.453274010083874, 0.0],
          ],
          [
            [-80.737879585118606, 36.450737076754478, 0.0],
            [-80.735155318456236, 36.448404010091451, 0.0],
            [-80.736144985121314, 36.446800076760553, 0.0],
            [-80.734241118457589, 36.443344076765925, 0.0],
          ],
          [
            [-80.742900385110829, 36.453274010083874, 0.0],
            [-80.737879585118606, 36.450737076754478, 0.0],
          ],
          [
            [-80.665797918563896, 36.500147076677877, 0.0],
            [-80.662392385235762, 36.496193076683937, 0.0],
            [-80.658109985242504, 36.496446210016927, 0.0],
            [-80.656037918578988, 36.49303487668891, 0.0],
            [-80.648439318590761, 36.497273010015704, 0.0],
            [-80.645574718595242, 36.497044476682618, 0.0],
            [-80.648267118591093, 36.492258210023522, 0.0],
            [-80.644125785264123, 36.490083610026772, 0.0],
            [-80.643501118598465, 36.487610610030686, 0.0],
            [-80.647327918592509, 36.483190610037468, 0.0],
            [-80.645625118595149, 36.478199010045273, 0.0],
          ],
          [
            [-80.6394429186048, 36.472910410053544, 0.0],
            [-80.634367785279323, 36.473414676719301, 0.0],
            [-80.634764518611973, 36.470071276724582, 0.0],
            [-80.633006718614752, 36.469842610058208, 0.0],
            [-80.63017118528586, 36.465469076731779, 0.0],
          ],
          [
            [-80.605872918656871, 36.476092676715098, 0.0],
            [-80.601734785329995, 36.471008610056458, 0.0],
            [-80.603408318660684, 36.468329676727194, 0.0],
          ],
          [
            [-80.645625118595149, 36.478199010045273, 0.0],
            [-80.644093185264182, 36.475176610049914, 0.0],
            [-80.6394429186048, 36.472910410053544, 0.0],
          ],
          [
            [-80.628129985288979, 36.462629676736128, 0.0],
            [-80.623140718630054, 36.462561210069452, 0.0],
            [-80.619965918634989, 36.458118676743027, 0.0],
          ],
          [
            [-80.585892318687911, 36.459211676741404, 0.0],
            [-80.581586585361208, 36.453760410083248, 0.0],
          ],
          [
            [-80.63017118528586, 36.465469076731779, 0.0],
            [-80.630086118619317, 36.462973210068924, 0.0],
            [-80.628129985288979, 36.462629676736128, 0.0],
          ],
          [
            [-80.619965918634989, 36.458118676743027, 0.0],
            [-80.617613185305231, 36.457706410077037, 0.0],
            [-80.617924718638051, 36.460133676739929, 0.0],
            [-80.614211185310637, 36.459652476740757, 0.0],
            [-80.614182318643884, 36.463407876734948, 0.0],
            [-80.612566185313142, 36.464484010066542, 0.0],
            [-80.609901185317256, 36.465308010065257, 0.0],
            [-80.605535785324037, 36.463979410067225, 0.0],
            [-80.603408318660684, 36.468329676727194, 0.0],
          ],
          [
            [-80.603408318660684, 36.468329676727194, 0.0],
            [-80.597171385336935, 36.467595676728479, 0.0],
            [-80.585892318687911, 36.459211676741404, 0.0],
          ],
          [
            [-80.599534985333378, 36.436568610109759, 0.0],
            [-80.592196585344709, 36.43301747678214, 0.0],
            [-80.587378918685602, 36.433863410114043, 0.0],
            [-80.576981985368377, 36.428753276788655, 0.0],
            [-80.571059918710944, 36.428407410122475, 0.0],
          ],
          [
            [-80.72797818513402, 36.443807810098576, 0.0],
            [-80.729703585131347, 36.441218676769324, 0.0],
            [-80.728821985132583, 36.438929476772842, 0.0],
            [-80.722330985142719, 36.437973276774358, 0.0],
            [-80.72550078513774, 36.434650410112909, 0.0],
            [-80.723486118474284, 36.432568276782774, 0.0],
            [-80.715977718485931, 36.4334672767813, 0.0],
          ],
          [
            [-80.602623118661882, 36.439866610104843, 0.0],
            [-80.599534985333378, 36.436568610109759, 0.0],
          ],
          [
            [-80.734241118457589, 36.443344076765925, 0.0],
            [-80.732737585126586, 36.442292210101073, 0.0],
            [-80.729368718465139, 36.445134676763303, 0.0],
            [-80.72797818513402, 36.443807810098576, 0.0],
          ],
          [
            [-80.581586585361208, 36.453760410083248, 0.0],
            [-80.583460318691664, 36.448379876758224, 0.0],
            [-80.592274585344683, 36.449527476756373, 0.0],
            [-80.588790718683413, 36.444465876764241, 0.0],
            [-80.600834918664646, 36.448201410091713, 0.0],
            [-80.606615985322378, 36.452163810085608, 0.0],
            [-80.610811185315924, 36.45145447675344, 0.0],
            [-80.610784385315924, 36.443188076766205, 0.0],
            [-80.606986718655151, 36.442729610100287, 0.0],
            [-80.602623118661882, 36.439866610104843, 0.0],
          ],
          [
            [-80.680380385207911, 36.386935810186799, 0.0],
            [-80.678228518544472, 36.387600876852559, 0.0],
            [-80.67511158521603, 36.385472610189197, 0.0],
          ],
          [
            [-80.505128318813263, 36.387462810186037, 0.0],
            [-80.511893185469432, 36.390125610181997, 0.0],
            [-80.512059585469103, 36.392575876844774, 0.0],
            [-80.513419985467067, 36.391913076845924, 0.0],
          ],
          [
            [-80.521411185454667, 36.388783210184044, 0.0],
            [-80.522799718785848, 36.38825761018478, 0.0],
            [-80.522909985452316, 36.390524810181432, 0.0],
            [-80.524552518783082, 36.390732210181, 0.0],
            [-80.526311918780323, 36.38796287685193, 0.0],
          ],
          [
            [-80.551343518741476, 36.392766476844599, 0.0],
            [-80.546762518748551, 36.38543601018921, 0.0],
            [-80.543904185419763, 36.383533610192217, 0.0],
            [-80.544841585418283, 36.380649010196692, 0.0],
          ],
          [
            [-80.526311918780323, 36.38796287685193, 0.0],
            [-80.52988278544143, 36.386042276855051, 0.0],
          ],
          [
            [-80.67511158521603, 36.385472610189197, 0.0],
            [-80.677969518544955, 36.381601410195231, 0.0],
            [-80.686154718532293, 36.382765410193315, 0.0],
            [-80.685443918533338, 36.378964476865917, 0.0],
          ],
          [
            [-80.52988278544143, 36.386042276855051, 0.0],
            [-80.532463718770828, 36.382792610193405, 0.0],
            [-80.537846118762445, 36.3814226101955, 0.0],
            [-80.535442318766172, 36.378398210200203, 0.0],
            [-80.537368785429862, 36.377689810201275, 0.0],
            [-80.538414918761589, 36.37927061019883, 0.0],
            [-80.539380185426751, 36.376889810202556, 0.0],
            [-80.538079985428681, 36.374805010205762, 0.0],
            [-80.539326318760175, 36.37446247687302, 0.0],
          ],
          [
            [-80.513419985467067, 36.391913076845924, 0.0],
            [-80.514584185465253, 36.38989921018225, 0.0],
            [-80.517159985461205, 36.390931876847333, 0.0],
            [-80.521411185454667, 36.388783210184044, 0.0],
          ],
          [
            [-80.495831518827686, 36.394746410174776, 0.0],
            [-80.502260385484419, 36.392291610178575, 0.0],
            [-80.505260985479708, 36.393302076843781, 0.0],
            [-80.503227785482807, 36.389361476849899, 0.0],
            [-80.505128318813263, 36.387462810186037, 0.0],
          ],
          [
            [-80.69647578518294, 36.396727876838384, 0.0],
            [-80.696980118515455, 36.390613610181163, 0.0],
            [-80.692850918521913, 36.397600210170367, 0.0],
            [-80.685533718533236, 36.385376210189406, 0.0],
            [-80.680380385207911, 36.386935810186799, 0.0],
          ],
          [
            [-80.561841385391858, 36.405091876825395, 0.0],
            [-80.555363918735281, 36.394463410175319, 0.0],
            [-80.551343518741476, 36.392766476844599, 0.0],
          ],
          [
            [-80.686654585198198, 36.510506676661748, 0.0],
            [-80.684358518535021, 36.512454409992131, 0.0],
            [-80.67621578521431, 36.510260076662121, 0.0],
          ],
          [
            [-80.67621578521431, 36.510260076662121, 0.0],
            [-80.671591318554874, 36.509002609997481, 0.0],
            [-80.667787385227484, 36.503966410005319, 0.0],
          ],
          [
            [-80.667644785227708, 36.502707076673858, 0.0],
            [-80.665797918563896, 36.500147076677877, 0.0],
          ],
          [
            [-80.667787385227484, 36.503966410005319, 0.0],
            [-80.667644785227708, 36.502707076673858, 0.0],
          ],
          [
            [-80.902553718196259, 36.31664201029605, 0.0],
            [-80.906145584857427, 36.312473410302516, 0.0],
          ],
          [
            [-80.401062518974811, 36.312207276969559, 0.0],
            [-80.403467718971058, 36.312893810301773, 0.0],
            [-80.408417385630003, 36.309114210307712, 0.0],
            [-80.409518118961671, 36.303160210316946, 0.0],
            [-80.41195258562459, 36.306411210311978, 0.0],
          ],
          [
            [-80.53593231876539, 36.321265810288935, 0.0],
            [-80.533163718769742, 36.317347876961662, 0.0],
            [-80.534471918767679, 36.311647076970473, 0.0],
            [-80.533517385435857, 36.305143010313827, 0.0],
          ],
          [
            [-80.818849784992835, 36.321853610287974, 0.0],
            [-80.813163985001722, 36.319011276958918, 0.0],
            [-80.802873985017754, 36.307968076976181, 0.0],
          ],
          [
            [-80.691429718524034, 36.324987876949649, 0.0],
            [-80.691173185191133, 36.322675210286718, 0.0],
            [-80.693972185186738, 36.319925676957666, 0.0],
            [-80.69294991852172, 36.315758610297394, 0.0],
            [-80.697815985180853, 36.314954210298708, 0.0],
            [-80.69990751851094, 36.312456876969236, 0.0],
            [-80.696226385183195, 36.309367810307208, 0.0],
            [-80.696846918515632, 36.307260676977194, 0.0],
          ],
          [
            [-81.092914117900932, 36.321242210288915, 0.0],
            [-81.091274117903424, 36.317898410294163, 0.0],
            [-81.094952917897729, 36.317578676961205, 0.0],
            [-81.097755784559979, 36.313388676967634, 0.0],
            [-81.101631517887313, 36.315977210297035, 0.0],
            [-81.102113517886607, 36.312381876969255, 0.0],
            [-81.10366991788419, 36.311901276969991, 0.0],
            [-81.102849917885464, 36.30951967697365, 0.0],
            [-81.100105317889756, 36.310160276972681, 0.0],
            [-81.097955384559782, 36.308579810308629, 0.0],
            [-81.099993317889869, 36.306038276979052, 0.0],
            [-81.098382917892422, 36.299282610322962, 0.0],
          ],
          [
            [-80.906145584857427, 36.312473410302516, 0.0],
            [-80.902125184863621, 36.305352476980261, 0.0],
            [-80.902887918195859, 36.302077676985334, 0.0],
          ],
          [
            [-80.40191511897342, 36.323381876952169, 0.0],
            [-80.398717318978413, 36.322787010286504, 0.0],
            [-80.393566185653015, 36.318551676959657, 0.0],
            [-80.401062518974811, 36.312207276969559, 0.0],
          ],
          [
            [-80.916791718174295, 36.32588921028173, 0.0],
            [-80.905752318191333, 36.319228876958618, 0.0],
            [-80.904506118193297, 36.316595676962834, 0.0],
            [-80.902553718196259, 36.31664201029605, 0.0],
          ],
          [
            [-81.090422317904711, 36.324928410283235, 0.0],
            [-81.092914117900932, 36.321242210288915, 0.0],
          ],
          [
            [-80.81556311833134, 36.327187610279736, 0.0],
            [-80.819554984991839, 36.324785076949922, 0.0],
            [-80.818849784992835, 36.321853610287974, 0.0],
          ],
          [
            [-80.409443118961804, 36.32544087694896, 0.0],
            [-80.40191511897342, 36.323381876952169, 0.0],
          ],
          [
            [-80.541779985422977, 36.330566876941077, 0.0],
            [-80.53593231876539, 36.321265810288935, 0.0],
          ],
          [
            [-80.681412785206305, 36.326184010281224, 0.0],
            [-80.691291185190948, 36.328468810277741, 0.0],
            [-80.692365985189213, 36.327781010278727, 0.0],
            [-80.691429718524034, 36.324987876949649, 0.0],
          ],
          [
            [-81.108953584542689, 36.350808476909663, 0.0],
            [-81.108415917876869, 36.349457410245066, 0.0],
            [-81.11096398453958, 36.347785876914315, 0.0],
            [-81.103378984551227, 36.341968476923455, 0.0],
            [-81.10051991788896, 36.342815210255424, 0.0],
            [-81.093387717900157, 36.34050087692566, 0.0],
            [-81.09319111790046, 36.336378810265387, 0.0],
            [-81.091606317902915, 36.335783010266368, 0.0],
            [-81.096788717894924, 36.328067076944933, 0.0],
            [-81.090422317904711, 36.324928410283235, 0.0],
          ],
          [
            [-80.432560585592455, 36.218766410447927, 0.0],
            [-80.43175711892718, 36.217267077117015, 0.0],
          ],
          [
            [-80.922390718165559, 36.216912210450801, 0.0],
            [-80.91880271817115, 36.220506410445182, 0.0],
            [-80.913333718179615, 36.221582410443602, 0.0],
          ],
          [
            [-81.212872517714686, 36.222491410442046, 0.0],
            [-81.207608517722861, 36.215900010452344, 0.0],
            [-81.206839317724075, 36.210129810461297, 0.0],
          ],
          [
            [-81.026848984670096, 36.221685010443366, 0.0],
            [-81.021544318011649, 36.215154010453489, 0.0],
            [-81.015243518021464, 36.214186677121688, 0.0],
            [-81.008706118031569, 36.201379277141655, 0.0],
          ],
          [
            [-80.438498518916674, 36.230822677095887, 0.0],
            [-80.432560585592455, 36.218766410447927, 0.0],
          ],
          [
            [-81.219235317704829, 36.225578010437403, 0.0],
            [-81.216693784375366, 36.227389077101122, 0.0],
            [-81.212872517714686, 36.222491410442046, 0.0],
          ],
          [
            [-80.913333718179615, 36.221582410443602, 0.0],
            [-80.908168918187641, 36.221937410442933, 0.0],
            [-80.900730984865845, 36.225673677103828, 0.0],
            [-80.895883318206756, 36.223663277107107, 0.0],
            [-80.896089318206293, 36.220271477112306, 0.0],
          ],
          [
            [-80.896089318206293, 36.220271477112306, 0.0],
            [-80.895357184874172, 36.219165410447374, 0.0],
            [-80.892110318212531, 36.220270610445709, 0.0],
            [-80.894128784876102, 36.233947010424458, 0.0],
            [-80.892722651545, 36.23562907708839, 0.0],
          ],
          [
            [-81.031834517995662, 36.236161677087694, 0.0],
            [-81.026848984670096, 36.221685010443366, 0.0],
          ],
          [
            [-81.508296317256111, 36.014762610764592, 0.0],
            [-81.496166717274889, 36.019426410757376, 0.0],
          ],
          [
            [-80.246682385881002, 36.019668810757025, 0.0],
            [-80.251402185873701, 36.017258077427414, 0.0],
          ],
          [
            [-80.766239518407929, 36.01708287742764, 0.0],
            [-80.755778518424165, 36.01899681075804, 0.0],
            [-80.753872585093802, 36.01187267743569, 0.0],
            [-80.751955518430066, 36.012099677435458, 0.0],
            [-80.749095185101226, 36.016336610762153, 0.0],
          ],
          [
            [-80.77520211839402, 36.018212877425924, 0.0],
            [-80.766239518407929, 36.01708287742764, 0.0],
          ],
          [
            [-80.265242785852251, 36.016721877428154, 0.0],
            [-80.26716018584932, 36.016242677429034, 0.0],
            [-80.270267719177696, 36.010932677437154, 0.0],
          ],
          [
            [-80.251402185873701, 36.017258077427414, 0.0],
            [-80.265242785852251, 36.016721877428154, 0.0],
          ],
          [
            [-80.425245318937243, 36.021029610754852, 0.0],
            [-80.41923498561323, 36.017068810761089, 0.0],
            [-80.417372585616079, 36.012261210768486, 0.0],
          ],
          [
            [-80.749095185101226, 36.016336610762153, 0.0],
            [-80.74917471843446, 36.013107410767248, 0.0],
            [-80.745960318439415, 36.01235501076826, 0.0],
          ],
          [
            [-80.619655185302122, 36.018210677425941, 0.0],
            [-80.615567785308485, 36.01811881075929, 0.0],
            [-80.60815498531997, 36.014293610765208, 0.0],
            [-80.606971785321832, 36.011476677436292, 0.0],
          ],
          [
            [-80.354849719046456, 36.016060277429233, 0.0],
            [-80.350876585719334, 36.012212277435196, 0.0],
          ],
          [
            [-80.329987785751769, 36.01788567742642, 0.0],
            [-80.332838585747311, 36.010604410771009, 0.0],
            [-80.341945119066565, 36.003897477448163, 0.0],
          ],
          [
            [-81.496166717274889, 36.019426410757376, 0.0],
            [-81.494026783944889, 36.021031477421502, 0.0],
            [-81.494341117277827, 36.023756410750593, 0.0],
            [-81.49192078394816, 36.026506810746355, 0.0],
          ],
          [
            [-80.787631118374691, 36.020398010755855, 0.0],
            [-80.776721985058373, 36.020046077423103, 0.0],
            [-80.77520211839402, 36.018212877425924, 0.0],
          ],
          [
            [-80.624418918628066, 36.021119210754819, 0.0],
            [-80.619655185302122, 36.018210677425941, 0.0],
          ],
          [
            [-80.627632585289746, 36.019195477424319, 0.0],
            [-80.624418918628066, 36.021119210754819, 0.0],
          ],
          [
            [-80.639867118604116, 36.021805210753655, 0.0],
            [-80.635948718610223, 36.021576677420569, 0.0],
            [-80.630620585285101, 36.018760077425043, 0.0],
            [-80.627632585289746, 36.019195477424319, 0.0],
          ],
          [
            [-80.432367918926161, 36.024300077416399, 0.0],
            [-80.425245318937243, 36.021029610754852, 0.0],
          ],
          [
            [-80.235387785898581, 36.026045677413777, 0.0],
            [-80.246682385881002, 36.019668810757025, 0.0],
          ],
          [
            [-80.3200609857671, 36.02466001074913, 0.0],
            [-80.325223119092527, 36.019463877423959, 0.0],
            [-80.329987785751769, 36.01788567742642, 0.0],
          ],
          [
            [-80.352563119049989, 36.027968010744132, 0.0],
            [-80.356906185709988, 36.021144477421444, 0.0],
            [-80.354849719046456, 36.016060277429233, 0.0],
          ],
          [
            [-80.773667185063005, 36.028127677410509, 0.0],
            [-80.772625918398035, 36.02666121074617, 0.0],
            [-80.769776918402442, 36.027895277410835, 0.0],
            [-80.771391118399947, 36.022423410752765, 0.0],
            [-80.767052585073372, 36.020266877422728, 0.0],
            [-80.766239518407929, 36.01708287742764, 0.0],
          ],
          [
            [-80.813189185001761, 36.033444277402225, 0.0],
            [-80.810512985005857, 36.030626210739911, 0.0],
            [-80.805833385013102, 36.030005277407611, 0.0],
            [-80.805271918347387, 36.027394210745001, 0.0],
            [-80.803101718350717, 36.026545610746382, 0.0],
            [-80.793823785031748, 36.029219077408811, 0.0],
            [-80.789454318371895, 36.028781010742875, 0.0],
            [-80.789033385039261, 36.026948677412292, 0.0],
            [-80.79399791836488, 36.024455877416187, 0.0],
            [-80.794649185030437, 36.021479277420895, 0.0],
            [-80.792790718366746, 36.019622877423785, 0.0],
            [-80.787631118374691, 36.020398010755855, 0.0],
          ],
          [
            [-80.599815785332964, 35.825002011059098, 0.0],
            [-80.599055785334144, 35.827383477722094, 0.0],
            [-80.594050185341928, 35.827473877721957, 0.0],
            [-80.594191518674961, 35.825756411057966, 0.0],
          ],
          [
            [-79.969767986310842, 35.823941877727407, 0.0],
            [-79.974346319637164, 35.819930877733782, 0.0],
          ],
          [
            [-80.594191518674961, 35.825756411057966, 0.0],
            [-80.59332171867635, 35.82083241106568, 0.0],
          ],
          [
            [-80.222124919252508, 35.831632677715561, 0.0],
            [-80.222344385918859, 35.827052477722589, 0.0],
            [-80.218432985924949, 35.824673877726354, 0.0],
            [-80.223632985916879, 35.823021077728868, 0.0],
            [-80.225231785914389, 35.819836611067103, 0.0],
            [-80.228492985909327, 35.819513277734359, 0.0],
          ],
          [
            [-80.48777498550686, 35.833619611045776, 0.0],
            [-80.486148718842742, 35.83074427771686, 0.0],
            [-80.478180585521613, 35.830961677716516, 0.0],
            [-80.472101318864532, 35.825091411059077, 0.0],
            [-80.464970185542256, 35.807423211086473, 0.0],
          ],
          [
            [-79.963904786320029, 35.836174477708539, 0.0],
            [-79.966461119649352, 35.833814011045547, 0.0],
            [-79.963926119653308, 35.829968811051401, 0.0],
            [-79.969269186311692, 35.830216677717772, 0.0],
            [-79.96743778631452, 35.827035077722599, 0.0],
            [-79.969767986310842, 35.823941877727407, 0.0],
          ],
          [
            [-80.214165585931539, 35.830814277716797, 0.0],
            [-80.218891985924245, 35.832459677714155, 0.0],
            [-80.222124919252508, 35.831632677715561, 0.0],
          ],
          [
            [-80.430032385596519, 35.829295277719154, 0.0],
            [-80.43607138558707, 35.828446011053813, 0.0],
            [-80.437953385584194, 35.831609477715574, 0.0],
            [-80.446419785571038, 35.836656411041076, 0.0],
            [-80.453832918892886, 35.828384011053856, 0.0],
            [-80.457684985553556, 35.826611411056547, 0.0],
            [-80.463006318878683, 35.826933277722844, 0.0],
            [-80.470200318867455, 35.837386411039859, 0.0],
          ],
          [
            [-80.610441785316425, 35.842546011031914, 0.0],
            [-80.606982385321714, 35.841972877699448, 0.0],
            [-80.599699518666455, 35.83649827770796, 0.0],
            [-80.600994118664403, 35.833704677712376, 0.0],
            [-80.603609518660392, 35.834277677711498, 0.0],
            [-80.602120918662706, 35.827544477721915, 0.0],
            [-80.599815785332964, 35.825002011059098, 0.0],
          ],
          [
            [-80.636543585275945, 35.845225811027831, 0.0],
            [-80.631143185284259, 35.845249077694291, 0.0],
            [-80.630889585284706, 35.838630677704657, 0.0],
            [-80.623183185296682, 35.839272077703697, 0.0],
            [-80.611203385315264, 35.831370277715905, 0.0],
            [-80.610022718650441, 35.829080077719482, 0.0],
            [-80.612385385313473, 35.825759677724704, 0.0],
            [-80.609939385317261, 35.823629611061222, 0.0],
            [-80.604062318659714, 35.823811877727678, 0.0],
            [-80.603667985326979, 35.826262277723856, 0.0],
            [-80.602065785329501, 35.82365121106119, 0.0],
            [-80.599815785332964, 35.825002011059098, 0.0],
          ],
          [
            [-80.200139119286689, 35.837006811040567, 0.0],
            [-80.203681119281157, 35.835584677709335, 0.0],
            [-80.209307319272455, 35.837161211040211, 0.0],
            [-80.211190319269519, 35.835969077708796, 0.0],
            [-80.211242385936089, 35.83214467771478, 0.0],
            [-80.214165585931539, 35.830814277716797, 0.0],
          ],
          [
            [-80.470200318867455, 35.837386411039859, 0.0],
            [-80.473758985528605, 35.842417477698746, 0.0],
            [-80.476975585523519, 35.843728411030099, 0.0],
            [-80.482957318847639, 35.840081277702438, 0.0],
            [-80.48777498550686, 35.833619611045776, 0.0],
          ],
          [
            [-80.41367711895515, 35.851274877685, 0.0],
            [-80.419965785612078, 35.852677011016226, 0.0],
            [-80.424077385605699, 35.851193011018495, 0.0],
            [-80.427571518933632, 35.833532277712493, 0.0],
            [-80.430032385596519, 35.829295277719154, 0.0],
          ],
        ],
      },
    },
  ],
};
