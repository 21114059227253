export const squaresTuck = {
"type": "FeatureCollection",
"name": "tuck_squares",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "GAGE_TUC", "DisplayName": "Below Lakes (Local)", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.267975756330593, "LON": -83.131785532592048 }, "geometry": { "type": "Point", "coordinates": [ -83.140222618285435, 35.284261466119588 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "CANE_TUC", "DisplayName": "Caney Fork", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.32336076357339, "LON": -83.069477399443898 }, "geometry": { "type": "Point", "coordinates": [ -83.141500074284011, 35.306442747549312 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "SAVA_TUC", "DisplayName": "Savannah Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.30259138585734, "LON": -83.264623010900948 }, "geometry": { "type": "Point", "coordinates": [ -83.23417370036212, 35.334779044244875 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "CULL_TUC", "DisplayName": "Cullowhee Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.269706537806925, "LON": -83.200151400907387 }, "geometry": { "type": "Point", "coordinates": [ -83.184933578235402, 35.305978218095291 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "BELW_TUC", "DisplayName": "Below Cullowhee", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.300860604381, "LON": -83.161208817689783 }, "geometry": { "type": "Point", "coordinates": [ -83.170068635706571, 35.312249365724639 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "DILL_TUC", "DisplayName": "Dillsboro", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.34499553202761, "LON": -83.204045659229138}, "geometry": { "type": "Point", "coordinates": [ -83.246875677620636, 35.362766943849948 ] } }
]
}