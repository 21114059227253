import { AppBar, Toolbar, Typography, styled, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../Layouts/ProjectLayout.css";
import { PlotLegend } from "./PlotLegend";
import { Details } from "./Details";

const MyAppBar = styled(AppBar)({
  background: "#d5e3f2",
  maxHeight: "40px",
  marginTop: "0px",
});

const MyDiv = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  alignItems: "center",
});

export const WFPToolbar = ({
  handleBackToSummaryPg,
  timeOfRefresh,
  shortFlow,
  medFlow,
}) => {
  return (
    <MyAppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          sx={{ color: "black" }}
          onClick={handleBackToSummaryPg}
          size="small"
        >
          <ArrowBackIcon /> My Forecast Points
        </IconButton>

        <MyDiv>
          <Details shortFlow={shortFlow} medFlow={medFlow} />
          <Typography sx={{ m: 2, color: "black" }}>
            Last Updated: {timeOfRefresh.toLocaleString()}
          </Typography>
          <Typography sx={{ m: 2, color: "darkgrey" }}>Download </Typography>
          <PlotLegend />
        </MyDiv>
      </Toolbar>
    </MyAppBar>
  );
};
