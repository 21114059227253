import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "./Map.css";
import { basinsYadkin } from "../../GIS/Yadkin_UI_Basins_Simple";
import { streams4Yadkin } from "../../GIS/Yadkin_UI_StreamsSO4+_Simple";
import { reservoirsYadkin } from "../../GIS/Yadkin_UI_Reservoirs_Simple";
import { basinsCatawba } from "../../GIS/Catawba_Basins";
import { riversCatawba } from "../../GIS/Catawba_Rivers_Simplified";
import { reservoirsCatawba } from "../../GIS/Catawba_Reservoirs";
import { CircularProgress } from "@mui/material";

export const Map = (props) => {
  const { basinClick, watershed, thresholds } = props;

  let basins, streams, reservoirs, lat, lng;

  if (watershed === "Yadkin") {
    basins = basinsYadkin;
    streams = streams4Yadkin;
    reservoirs = reservoirsYadkin;
    lat = 35.75;
    lng = -80.6;
  } else if (watershed === "Catawba") {
    basins = basinsCatawba;
    streams = riversCatawba;
    reservoirs = reservoirsCatawba;
    lat = 35.4;
    lng = -81.2;
  }

  const zoom = 8;
  const position = [lat, lng];

  const watershedRef = useRef();
  const streamsRef = useRef();
  const resRef = useRef();

  const chooseBasinStyle = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1.5,
      opacity: 1,
    };

    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.Res_Basin;
    });

    if (!currentThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };

  const onEachBasin = (feature, layer) => {
    layer.on({
      click: () => {
        basinClick(feature.properties.Res_Basin);
      },
      mouseover: () => {
        layer.bindTooltip(feature.properties.BASIN_NAME, {
          direction: "center",
        });
        layer.openTooltip();
      },
      mouseout: () => {
        layer.closeTooltip();
      },
    });
  };

  const streamStyle = {
    fillColor: "blue",
    fillOpacity: 0.7,
    color: "blue",
    weight: 0.5,
    opacity: 1,
  };

  return (
    <>
      {!thresholds || basins.features.length < 1 ? (
        <CircularProgress />
      ) : (
        <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJSON
            ref={watershedRef}
            key={basins.features.properties}
            data={basins.features}
            style={chooseBasinStyle}
            onEachFeature={onEachBasin}
          />
          <GeoJSON
            ref={streamsRef}
            key={streams.features}
            data={streams.features}
            style={streamStyle}
          />

          <GeoJSON
            ref={resRef}
            key={reservoirs.features}
            data={reservoirs.features}
            style={streamStyle}
          />
        </MapContainer>
      )}
    </>
  );
};
