import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { Dashboard } from "./Components/Dashboard/Dashboard";
import { AuthProvider } from "./Providers/AuthProvider";
import { PrivateRoute } from "./Components/Routes/PrivateRoute";
import { Public } from "./Components/Public/Public";
import { ProjectsSummary } from "./Components/ProjectsSummary/ProjectsSummary";
// import {Sample} from "./Components/CustomDashboard/Sample";

const App = () => {
  return (
    // <div>
    // <Sample watershed={"CatawbaFilters"}></Sample>
    // </div>
    <>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Public />} />
            <Route
              path="/projects-summary"
              element={
                <PrivateRoute>
                  <ProjectsSummary />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </>
  );
};

export default App;
