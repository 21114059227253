import { Grid, Paper, styled } from "@mui/material";

import { ThumbnailPlot } from "./ThumbnailPlot.jsx";

const ThumbnailItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "40vh",
}));

export const ThumbnailContainer = ({ allTs, basinClick }) => {

  return allTs.map((basin) => {
    return (
      <Grid item xs={4} key={basin.name}>
        <ThumbnailItem>
          <ThumbnailPlot basin={basin} basinClick={basinClick} />
        </ThumbnailItem>
      </Grid>
    );
  });
};
