import { Paper } from "@mui/material";
import { Navigate } from "react-router-dom";
import { ProjectLayout } from "../Layouts/ProjectLayout";
import { Login } from "../Auth/Login";
import { useAuth } from "../../Hooks/useAuth";

export const Public = () => {
  const { user, token } = useAuth();

  if (user && token) {
    return <Navigate to="/projects-summary" replace />;
  }
  return (
    <ProjectLayout>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          marginTop: "5%",
          maxWidth: 400,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Login />
      </Paper>
    </ProjectLayout>
  );
};
