export const squaresYadkin = {
"type": "FeatureCollection",
"name": "yadkin_squares",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ABBO_YAD", "DisplayName": "Abbotts Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.920047677540701 , "LON": -80.138398969266191 }, "geometry": { "type": "Point", "coordinates": [ -80.225875854492188, 35.826934814453125 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ARAR_YAD", "DisplayName": "Ararat River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.524955803520669, "LON": -80.603979186400991 }, "geometry": { "type": "Point", "coordinates": [ -80.573196411132812, 36.413131713867188 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "REDD_YAD", "DisplayName": "Reddies River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.252357720997516 , "LON": -81.261676147409261 }, "geometry": { "type": "Point", "coordinates": [ -81.180122375488281, 36.191024780273438 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "IRSH_YAD", "DisplayName": "Rocky at Irish", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.347159008873 , "LON": -80.704364512028548 }, "geometry": { "type": "Point", "coordinates": [ -80.540786743164062, 35.327037811279297 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "MOCK_YAD", "DisplayName": "S. Yadkin Mocksville", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.88975900170481, "LON": -80.842827030135567 }, "geometry": { "type": "Point", "coordinates": [ -80.672698974609375, 35.852134704589844 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "PATT_YAD", "DisplayName": "Patterson", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.058510195647713, "LON": -81.594851581604232 }, "geometry": { "type": "Point", "coordinates": [ -81.568626403808594, 36.00262451171875 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ELKC_YAD", "DisplayName": "Elk Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.141587706511913, "LON": -81.510043289263692 }, "geometry": { "type": "Point", "coordinates": [ -81.42681884765625, 36.09112548828125 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "HUNT_YAD", "DisplayName": "Hunting Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.071491056720248, "LON": -80.853211718993592 }, "geometry": { "type": "Point", "coordinates": [ -80.750289916992188, 36.013629913330078 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "LITT_YAD", "DisplayName": "Little River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.509852467648727, "LON": -79.83205064795446 }, "geometry": { "type": "Point", "coordinates": [ -79.827568054199219, 35.38873291015625 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "LYAD_YAD", "DisplayName": "Little Yadkin", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.343223748505231 , "LON": -80.377246813000781 }, "geometry": { "type": "Point", "coordinates": [ -80.411888122558594, 36.303131103515625 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "SECO_YAD", "DisplayName": "Second Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.654372720922915, "LON": -80.687056697265191 }, "geometry": { "type": "Point", "coordinates": [ -80.599594116210938, 35.715232849121094 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "MITC_YAD", "DisplayName": "Mitchell River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.391685629842684, "LON": -80.879173441138647 }, "geometry": { "type": "Point", "coordinates": [ -80.814201354980469, 36.313526153564453 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ROAR_YAD", "DisplayName": "Roaring River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.333704450385376, "LON": -81.074751747964811 }, "geometry": { "type": "Point", "coordinates": [ -81.059814453125, 36.260829925537109 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "UWHA_YAD", "DisplayName": "Uwharrie River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.6379302968977, "LON": -79.982628636395845 }, "geometry": { "type": "Point", "coordinates": [ -80.016281127929688, 35.470737457275391 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "NORW_YAD", "DisplayName": "Rocky at Norwood", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.014848965416199, "LON": -80.377246813000767 }, "geometry": { "type": "Point", "coordinates": [ -80.184188842773438, 35.144847869873047 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ELKN_YAD", "DisplayName": "Elkin", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.206492011874573, "LON": -80.989943455624285 }, "geometry": { "type": "Point", "coordinates": [ -80.853599548339844, 36.240428924560547 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ENON_YAD", "DisplayName": "Enon", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.263607800593711, "LON": -80.470709012723006 }, "geometry": { "type": "Point", "coordinates": [ -80.446586608886719, 36.140434265136719 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "WILK_YAD", "DisplayName": "Wilkesboro", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.132068408392058 , "LON": -81.195041060570276 }, "geometry": { "type": "Point", "coordinates": [ -81.151924133300781, 36.155624389648438 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "YADC_YAD", "DisplayName": "Yadkin College", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 36.010048314310254, "LON": -80.382439157429786 }, "geometry": { "type": "Point", "coordinates": [ -80.408180236816406, 35.893630981445312 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "ROCK_YAD", "DisplayName": "Rocky River", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.314274160822585, "LON": -80.380708375953446 }, "geometry": { "type": "Point", "coordinates": [ -80.261093139648438, 35.21484375 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "SYAD_YAD", "DisplayName": "S. Yadkin", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.79110445755358, "LON": -80.584507894792196 }, "geometry": { "type": "Point", "coordinates": [ -80.473091125488281, 35.762435913085938 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "CONF_YAD", "DisplayName": "Confluence", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.843893292581861, "LON": -80.467247449770312 }, "geometry": { "type": "Point", "coordinates": [ -80.452048734020892, 35.761159016858564 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "FISH_YAD", "DisplayName": "Fisher River", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 36.405531881653381, "LON": -80.725133889744612 }, "geometry": { "type": "Point", "coordinates": [ -80.702301025390625, 36.278633117675781 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "UPPR_YAD", "DisplayName": "Upper Yadkin", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 36.0368754271935, "LON": -81.478889222689617}, "geometry": { "type": "Point", "coordinates": [ -81.4122314453125, 36.062026977539062 ] } }
]
}