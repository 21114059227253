import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  styled,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Map } from "../Map/Map";
import { MainPlot } from "../Plots/MainPlot";
import { WFPToolbar } from "../WFPToolbar/WFPToolbar";
import Request from "../../Utils/Request";
import {
  extractThresholds,
  extractShortFlowForecastDateTime,
  extractMedFlowForecastDateTime,
  addCorrectPrecipTs,
} from "../../Utils/helpers";
import { REACT_APP_FEWS_PREFIX } from "../../Utils/constants";
import { ThumbnailContainer } from "../Thumbnails/ThumbnailContainer";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.primary,
  height: "75vh",
}));

export const Dashboard = ({ watershed, handleBackToSummaryPg }) => {
  const [timeOfRefresh, setTimeOfRefresh] = useState(new Date());
  const [thresholds, setThresholds] = useState(["initial state"]);
  const [selectedBasin, setSelectedBasin] = useState("");
  const [error, setError] = useState("");
  const [allWatershedTimeseries, setAllWatershedTimeseries] = useState([]);
  const [shortFlowForecastDateTime, setShortFlowForecastDateTime] = useState({
    date: "",
    time: "",
  });
  const [medFlowForecastDateTime, setMedFlowForecastDateTime] = useState({
    date: "",
    time: "",
  });
  const [allCorrectShortPptTimeseries, setAllCorrectShortPptTimeseries] =
    useState([]);
  const [allCorrectMedPptTimeseries, setAllCorrectMedPptTimeseries] = useState(
    []
  );

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  const basinClick = (basinId) => {
    setSelectedBasin(basinId);
    handleScrollUp();
  };


  useEffect(() => {
    // fetch ALL timeseries for ALL basins in the watershed
    const today = Date.now();
    const startTime = new Date(today - 86400000 * 2).toISOString();
    const endTime = new Date(today + 86400000 * 10.25).toISOString();

    setError("");
    const url =
      REACT_APP_FEWS_PREFIX +
      `timeseries?filterId=${watershed}Filters&startTime=${startTime}&endTime=${endTime}&useDisplayUnits=true&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;

    const fetchAllTimeseries = async () => {
      const res = await new Request().get(url);

      if (res.response.status !== 200) {
        setError(
          "Sorry, there was an error: " +
            res.response.status +
            res.response.statusText
        );
      } else {
        // function to extract short range flow forecast time
        setShortFlowForecastDateTime(
          extractShortFlowForecastDateTime(res.data.timeSeries)
        );
        // function to extract lastmed flow forecast time
        setMedFlowForecastDateTime(
          extractMedFlowForecastDateTime(res.data.timeSeries)
        );
        setAllWatershedTimeseries(res.data.timeSeries);
      }
    };
    fetchAllTimeseries();
    // re-fetch data every 5 minutes to get most recent data
    const timer = setInterval(fetchAllTimeseries, 300000);
    // clean up interval so it doesn't create a memory leak on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [watershed]);

  useEffect(() => {
    // fetch ALL short precip forecast timeseries for ALL basins in the watershed
    // according to correct forecast date/times

    const today = Date.now();
    const startTime = new Date(today - 86400000 * 2).toISOString();
    const endTime = new Date(today + 86400000 * 10.25).toISOString();

    setError("");
    const url =
      REACT_APP_FEWS_PREFIX +
      `timeseries?filterId=${watershed}Filters&parameterIds=FMAP&&moduleInstanceIds=PreprocessHRRR&externalForecastTimes=${shortFlowForecastDateTime.date}T${shortFlowForecastDateTime.time}Z&startTime=${startTime}&endTime=${endTime}&useDisplayUnits=true&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;

    const fetchCorrectShortPptTs = async () => {
      const res = await new Request().get(url);

      if (res.response.status !== 200) {
        setError(
          "Sorry, there was an error: " +
            res.response.status +
            res.response.statusText
        );
      } else {
        setAllCorrectShortPptTimeseries(res.data.timeSeries);
      }
    };

    shortFlowForecastDateTime.date && fetchCorrectShortPptTs();
  }, [watershed, shortFlowForecastDateTime]);

  useEffect(() => {
    // fetch ALL med precip forecast timeseries for ALL basins in the watershed
    // according to correct forecast date/times

    const today = Date.now();
    const startTime = new Date(today - 86400000 * 2).toISOString();
    const endTime = new Date(today + 86400000 * 10.25).toISOString();

    setError("");
    const url =
      REACT_APP_FEWS_PREFIX +
      `timeseries?filterId=${watershed}Filters&parameterIds=FMAP&moduleInstanceIds=PreprocessGFS&externalForecastTimes=${medFlowForecastDateTime.date}T${medFlowForecastDateTime.time}Z&startTime=${startTime}&endTime=${endTime}&useDisplayUnits=true&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;

    const fetchCorrectMedPptTs = async () => {
      const res = await new Request().get(url);

      if (res.response.status !== 200) {
        setError(
          "Sorry, there was an error: " +
            res.response.status +
            res.response.statusText
        );
      } else {
        setAllCorrectMedPptTimeseries(res.data.timeSeries);
      }
    };

    medFlowForecastDateTime.date && fetchCorrectMedPptTs();
  }, [watershed, medFlowForecastDateTime]);

  useEffect(() => {
    // Get threshold exceedences

    // start time is -48 hours from right now
    const today = Date.now();
    const startTime = new Date(today - 86400000 * 2).toISOString();

    // end time is + 10 days from right now
    const endTime = new Date(today + 86400000 * 10).toISOString();
    setError("");

    const url =
      REACT_APP_FEWS_PREFIX +
      `timeseries?filterId=Filter_${watershed}_Thresholds&startTime=${startTime}&endTime=${endTime}&qualifierIds=BooleanThreshold&useDisplayUnits=false&showThresholds=true&showProducts=false&omitMissing=true&onlyHeaders=false&showEnsembleMemberIds=false&documentVersion=1.26&documentFormat=PI_JSON&forecastCount=1`;

    // note: not all basins have thresholds! but that's ok as long as the default
    // 'highlight' basin doesn't have to be one of the basins without thresholds
    const fetchThresholds = async () => {
      const res = await new Request().get(url);

      if (res?.data.timeSeries) {
        const processedThresholds = extractThresholds(res.data.timeSeries);

        setThresholds(processedThresholds);
        setSelectedBasin(processedThresholds[0].name);
        setTimeOfRefresh(new Date());
      } else {
        setError("ERROR: " + res.response.status);
      }
    };
    // re-fetch data every 5 minutes to get most recent data
    fetchThresholds();
    const timer = setInterval(fetchThresholds, 300000);
    // clean up interval so it doesn't create a memory leak on component unmount
    return () => {
      clearInterval(timer);
    };
  }, [watershed]);

  return (
    <>
      {shortFlowForecastDateTime && medFlowForecastDateTime ? (
        <WFPToolbar
          handleBackToSummaryPg={handleBackToSummaryPg}
          timeOfRefresh={timeOfRefresh}
          shortFlow={shortFlowForecastDateTime}
          medFlow={medFlowForecastDateTime}
        />
      ) : (
        <>
          <CircularProgress />
          <Typography>Loading plot data...</Typography>
        </>
      )}

      <Box
        sx={{
          margin: "2%",
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item xs={5}>
            <Item>
              <Map
                basinClick={basinClick}
                watershed={watershed}
                thresholds={thresholds}
              />
            </Item>
          </Grid>
          <Grid item xs={7}>
            <Item>
              {error ? (
                <Typography color="error">
                  {error} Your session may have expired. Please logout and
                  re-enter your credentials.
                </Typography>
              ) : allWatershedTimeseries.length > 0 &&
                selectedBasin &&
                allCorrectShortPptTimeseries.length > 0 &&
                allCorrectMedPptTimeseries.length > 0 ? (
                <MainPlot
                  selectedBasin={selectedBasin}
                  allTs={addCorrectPrecipTs(
                    allCorrectMedPptTimeseries,
                    allCorrectShortPptTimeseries,
                    allWatershedTimeseries
                  )}
                  thresholds={thresholds}
                />
              ) : (
                <>
                  <CircularProgress />
                  <Typography>Loading plot data...</Typography>
                </>
              )}
            </Item>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          {allCorrectShortPptTimeseries &&
          allCorrectShortPptTimeseries.length > 0 &&
          allCorrectMedPptTimeseries &&
          allCorrectMedPptTimeseries.length > 0 ? (
            <ThumbnailContainer
              allTs={addCorrectPrecipTs(
                allCorrectMedPptTimeseries,
                allCorrectShortPptTimeseries,
                allWatershedTimeseries
              )}
              basinClick={basinClick}
            />
          ) : null}
        </Grid>
      </Box>
    </>
  );
};
