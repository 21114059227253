import {
  createMedCumulativePrecipValuesArray,
  createShortCumulativePrecipValuesArray,
  createPastCumulativePrecipValuesArray,
  thresholdVisible,
  findThresholdValue,
} from "./PlotHelpers";

export const createAnnotations = (timeseries, thresholds, selectedBasin) => {
  const annotations = [
    {
      wfpType: "accumulation_title",
      visible: true,
      xref: "paper",
      yref: "paper",
      y: 0.7,
      xanchor: "left",
      x: 0.99,
      yanchor: "middle",
      text: "(in)",
      showarrow: false,
      font: {
        color: "black",
        size: 12,
      },
    },
    {
      wfpType: "accumulation_title",
      visible: true,
      xref: "paper",
      yref: "paper",
      y: 0.98,
      xanchor: "left",
      x: 0.99,
      yanchor: "middle",
      text: "(in)",
      showarrow: false,
      font: {
        color: "black",
        size: 12,
      },
    },
    {
      wfpType: "threshold_anno",
      visible: thresholdVisible(thresholds, selectedBasin),
      xref: "paper",
      yref: "y",
      y: findThresholdValue(timeseries),
      xanchor: "left",
      x: 0,
      yanchor: "top",
      text: "Threshold",
      showarrow: false,
      font: {
        color: "black",
        size: 11,
      },
    },
  ];

  const medCumPptTs = timeseries.find((ts) => {
    return (
      !ts.header?.qualifierId && ts.header?.moduleInstanceId === "PreprocessGFS"
    );
  });

  const medCumPpt = createMedCumulativePrecipValuesArray(medCumPptTs);

  const shortCumPptTs = timeseries.find((ts) => {
    return (
      !ts?.header?.qualifierId &&
      ts?.header?.moduleInstanceId === "PreprocessHRRR"
    );
  });

  const shortCumPpt = createShortCumulativePrecipValuesArray(shortCumPptTs);

  const pastCumPptTs = timeseries.find((ts) => {
    return ts?.header?.qualifierId && ts?.header?.qualifierId[0] === "merged";
  });

  const pastCumPpt = createPastCumulativePrecipValuesArray(pastCumPptTs);

  if ((medCumPpt, shortCumPpt, pastCumPpt)) {
    return annotations.concat(medCumPpt, shortCumPpt, pastCumPpt);
  } else if (annotations) {
    return annotations;
  } else {
    return [];
  }
};
