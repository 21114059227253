import { useState } from "react";
import { Typography, Box, Button, Modal, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "../Layouts/ProjectLayout.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
  width: "30%",
};

export const Details = ({ shortFlow, medFlow }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button size="small" sx={{ m: 2, color: "black" }} onClick={handleOpen}>
        <InfoOutlinedIcon />
        Details
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Plot-legend-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar sx={{ background: "#496D99" }}>
            <Toolbar
              sx={{ background: "#496D99", justifyContent: "space-between" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Forecast Details
              </Typography>
              <Button
                size="large"
                sx={{ m: 1, color: "white" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>
          <div style={{ margin: "5%", paddingTop: "15%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="h6">
                Short-term flow forecast time:{" "}
              </Typography>
              <Typography>
                {shortFlow.date} {shortFlow.time}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Typography variant="h6">
                Medium-term flow forecast time:{" "}
              </Typography>
              <Typography>
                {medFlow.date} {medFlow.time}
              </Typography>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
