import { Button } from "@mui/material";
import { useAuth } from "../../Hooks/useAuth";

export const Logout = () => {
  const { logout } = useAuth();

  return (
    <Button
      variant="contained"
      size="small"
      style={{ background: "#bfbfbf", color: "black" }}
      onClick={logout}
    >
      Logout
    </Button>
  );
};
