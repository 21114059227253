export const reservoirsCatawba = {
  type: "FeatureCollection",
  name: "Catawba_Reservoirs",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID: 24,
        ComID: 12033723,
        FDate: "1999/10/12",
        Resolution: 3,
        GNIS_ID: "00998494",
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.391,
        Elevation: 0.0,
        ReachCode: null,
        FType: 436,
        FCode: 43606,
        Shape_Leng: 0.03770559078,
        Shape_Area: 3.86994e-5,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.010028584434082, 35.184246478922255, 0.0],
              [-81.009442918535044, 35.184131479069002, 0.0],
              [-81.008382384707417, 35.184474079142504, 0.0],
              [-81.007489517663998, 35.184564878818101, 0.0],
              [-81.00662511793621, 35.184289212254555, 0.0],
              [-81.006683118375875, 35.182823411731405, 0.0],
              [-81.006599585019558, 35.182663012399438, 0.0],
              [-81.006712318506914, 35.181884412405715, 0.0],
              [-81.006852185084966, 35.181563878363569, 0.0],
              [-81.007298918385246, 35.181381078852056, 0.0],
              [-81.008582384453874, 35.181107478500451, 0.0],
              [-81.011009584089919, 35.180995211905959, 0.0],
              [-81.01293411829792, 35.18122587851353, 0.0],
              [-81.015499584727934, 35.18193827866331, 0.0],
              [-81.016838585178689, 35.182054011773886, 0.0],
              [-81.018233385074907, 35.182078011839806, 0.0],
              [-81.018818784511353, 35.182330478526183, 0.0],
              [-81.01890231776531, 35.182490878701813, 0.0],
              [-81.018511318340586, 35.182788278282196, 0.0],
              [-81.017785785107733, 35.183016678872143, 0.0],
              [-81.0153023847909, 35.183358079093956, 0.0],
              [-81.013935518473772, 35.183356878913955, 0.0],
              [-81.013265784185819, 35.183470678611883, 0.0],
              [-81.013153984818899, 35.183608078389803, 0.0],
              [-81.013181584185034, 35.183814212481302, 0.0],
              [-81.013348785293275, 35.183928879185942, 0.0],
              [-81.014241385002435, 35.184090012216025, 0.0],
              [-81.01446418514584, 35.184296411708047, 0.0],
              [-81.014547385278377, 35.184754478943624, 0.0],
              [-81.01683451816875, 35.185077212492409, 0.0],
              [-81.016889918560906, 35.185306412364945, 0.0],
              [-81.016778184812495, 35.185420678865022, 0.0],
              [-81.015605918435128, 35.185877812076605, 0.0],
              [-81.013680584634471, 35.186219611973833, 0.0],
              [-81.011392718538332, 35.186355011874042, 0.0],
              [-81.010974318282507, 35.186240012234357, 0.0],
              [-81.010611984926442, 35.185987811570932, 0.0],
              [-81.010250185030699, 35.185369011685452, 0.0],
              [-81.010167517600038, 35.18465901188442, 0.0],
              [-81.010028584434082, 35.184246478922255, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1630,
        ComID: 9755308,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Mountain Island Lake",
        AreaSqKm: 8.084,
        Elevation: 0.0,
        ReachCode: "03050101002512",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.73065635584,
        Shape_Area: 0.00080168159,
        Reservoir: 31,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.942999784755258, 35.378024478017004, 0.0],
              [-80.945040784869832, 35.377565278699329, 0.0],
              [-80.945515785228011, 35.377152678653822, 0.0],
              [-80.945543585173112, 35.376855012138712, 0.0],
              [-80.945235784564403, 35.376763611280012, 0.0],
              [-80.945235717904325, 35.376557411309015, 0.0],
              [-80.945682384672978, 35.375755611656459, 0.0],
              [-80.945681918637689, 35.375137212172426, 0.0],
              [-80.945597917739391, 35.374976878074342, 0.0],
              [-80.945178184825451, 35.374725211497832, 0.0],
              [-80.945121384637929, 35.373740411501899, 0.0],
              [-80.944841584304527, 35.373580278900143, 0.0],
              [-80.94400258442829, 35.373466278516432, 0.0],
              [-80.943806785335426, 35.37328307855082, 0.0],
              [-80.943750584306088, 35.372985411726702, 0.0],
              [-80.943414584584673, 35.372504611707015, 0.0],
              [-80.943162785273785, 35.372298611863442, 0.0],
              [-80.943246384996144, 35.371977878561481, 0.0],
              [-80.943246117654795, 35.371657278321507, 0.0],
              [-80.943022318093597, 35.371611678130968, 0.0],
              [-80.942603184840095, 35.37184087829899, 0.0],
              [-80.942295984526339, 35.372276211914816, 0.0],
              [-80.942044318442427, 35.372276411718815, 0.0],
              [-80.941260785139747, 35.371635611968102, 0.0],
              [-80.940505785407566, 35.371613078895628, 0.0],
              [-80.93949938483415, 35.371842678368502, 0.0],
              [-80.938044984610684, 35.37145407867105, 0.0],
              [-80.937849184816997, 35.37129387843644, 0.0],
              [-80.937820985029887, 35.371087678450607, 0.0],
              [-80.93829631829577, 35.370904278385673, 0.0],
              [-80.939497985108233, 35.370125011300573, 0.0],
              [-80.939525784513151, 35.369918811295932, 0.0],
              [-80.938966117722842, 35.369392278678866, 0.0],
              [-80.938965784408254, 35.368911411904911, 0.0],
              [-80.939049517632455, 35.368613611529192, 0.0],
              [-80.939356717551362, 35.368224078211739, 0.0],
              [-80.939356384577465, 35.36799507847887, 0.0],
              [-80.937817918102667, 35.367171278066294, 0.0],
              [-80.936698917677077, 35.3664390121303, 0.0],
              [-80.935020918444678, 35.365913077978931, 0.0],
              [-80.933146984397482, 35.364998012109318, 0.0],
              [-80.932028585205387, 35.364838211954584, 0.0],
              [-80.932028717578987, 35.365044278612146, 0.0],
              [-80.932280384321828, 35.365158811363855, 0.0],
              [-80.932728118718032, 35.365662278513149, 0.0],
              [-80.93258878486165, 35.366395278882308, 0.0],
              [-80.93247698462396, 35.366532811584463, 0.0],
              [-80.93242151857568, 35.366990878852327, 0.0],
              [-80.932533385128437, 35.367174078594495, 0.0],
              [-80.932338184423713, 35.36786127812973, 0.0],
              [-80.932310517838303, 35.368319278838143, 0.0],
              [-80.932646984729459, 35.369578811421682, 0.0],
              [-80.933262384489325, 35.369967812204003, 0.0],
              [-80.933290518089564, 35.370174012188706, 0.0],
              [-80.933039184902611, 35.370655078259681, 0.0],
              [-80.932675984408903, 35.371044611641743, 0.0],
              [-80.932508184596216, 35.371044678698716, 0.0],
              [-80.93200431863518, 35.370243278489447, 0.0],
              [-80.930465718407277, 35.369098811504102, 0.0],
              [-80.929347184489004, 35.369122278359804, 0.0],
              [-80.928228984543566, 35.369443478153322, 0.0],
              [-80.927474384836188, 35.370016412172987, 0.0],
              [-80.926971317826528, 35.370222678875372, 0.0],
              [-80.926859784704604, 35.370841211763896, 0.0],
              [-80.926608184492238, 35.370841278876263, 0.0],
              [-80.925741117715006, 35.37029187817209, 0.0],
              [-80.924091385101548, 35.370452878607821, 0.0],
              [-80.924175185219426, 35.370246811911336, 0.0],
              [-80.924538518093769, 35.369971811987803, 0.0],
              [-80.92492991828766, 35.369811411443308, 0.0],
              [-80.925964185109891, 35.369696411299671, 0.0],
              [-80.927893118654097, 35.36893981173354, 0.0],
              [-80.928368318557602, 35.368664678899776, 0.0],
              [-80.92906678492767, 35.367977211997271, 0.0],
              [-80.930101384698446, 35.368022611846825, 0.0],
              [-80.930828385035696, 35.367907678605711, 0.0],
              [-80.931219718702081, 35.36770147796782, 0.0],
              [-80.931275385258175, 35.367426611825223, 0.0],
              [-80.930743584486862, 35.366556478169699, 0.0],
              [-80.93018411863126, 35.366259078165697, 0.0],
              [-80.93007198465034, 35.36577807840618, 0.0],
              [-80.930071584606964, 35.365205611843152, 0.0],
              [-80.930434917596216, 35.364907678892557, 0.0],
              [-80.930490585081031, 35.364747278360291, 0.0],
              [-80.930015317778967, 35.36456427848421, 0.0],
              [-80.927806184407714, 35.364153012093958, 0.0],
              [-80.927694518380022, 35.364359211324626, 0.0],
              [-80.928114318398244, 35.365023211565756, 0.0],
              [-80.927555117762964, 35.365069411489486, 0.0],
              [-80.926883918362606, 35.364794811426876, 0.0],
              [-80.926799517941845, 35.36410767814526, 0.0],
              [-80.92663178464916, 35.364084878100435, 0.0],
              [-80.926296385066507, 35.364199611781046, 0.0],
              [-80.923864785336008, 35.365803811408625, 0.0],
              [-80.923529318317676, 35.365895478876659, 0.0],
              [-80.923529185201801, 35.365643611509284, 0.0],
              [-80.924004118531641, 35.364887611905445, 0.0],
              [-80.923780318149184, 35.364750278686977, 0.0],
              [-80.92375231805056, 35.364590011838082, 0.0],
              [-80.923836117987918, 35.364452477975647, 0.0],
              [-80.92498218487971, 35.364108478548701, 0.0],
              [-80.925065985298943, 35.364016811992343, 0.0],
              [-80.925093784752491, 35.363627478206283, 0.0],
              [-80.924897984909478, 35.363490211349422, 0.0],
              [-80.923723518088224, 35.36323867821875, 0.0],
              [-80.923387784427845, 35.362964011891719, 0.0],
              [-80.92285651760173, 35.362826811875102, 0.0],
              [-80.922520784632738, 35.362506278924094, 0.0],
              [-80.92252078460622, 35.362414678677155, 0.0],
              [-80.922101118048744, 35.361956811878763, 0.0],
              [-80.921569784453922, 35.361750811987996, 0.0],
              [-80.92092671797333, 35.36181981197074, 0.0],
              [-80.920675118634904, 35.361728412020703, 0.0],
              [-80.919136518599956, 35.360148678109475, 0.0],
              [-80.918800985159393, 35.359988478211456, 0.0],
              [-80.918465518417719, 35.360057211770645, 0.0],
              [-80.918381584888266, 35.360171811959511, 0.0],
              [-80.917794518622003, 35.360103278215099, 0.0],
              [-80.91720751786572, 35.360195078936471, 0.0],
              [-80.91600538427592, 35.360607812146938, 0.0],
              [-80.916005318649994, 35.3604016787665, 0.0],
              [-80.916787784648704, 35.359828878326525, 0.0],
              [-80.91698351821249, 35.359553878262446, 0.0],
              [-80.917905918424665, 35.359278812199896, 0.0],
              [-80.918241184912532, 35.35902667858484, 0.0],
              [-80.918269118006506, 35.358889278065874, 0.0],
              [-80.917681784384186, 35.358362678895666, 0.0],
              [-80.91740198528791, 35.357927678491848, 0.0],
              [-80.917177985176423, 35.357286411858439, 0.0],
              [-80.916842317908319, 35.357103411866618, 0.0],
              [-80.916869984672275, 35.356599478687421, 0.0],
              [-80.916730118455078, 35.356324678350035, 0.0],
              [-80.916422384521354, 35.356072878042504, 0.0],
              [-80.916226384638122, 35.355431611749935, 0.0],
              [-80.915946718142592, 35.355111078127642, 0.0],
              [-80.915946518700466, 35.354859078421669, 0.0],
              [-80.916170184577851, 35.354859078768797, 0.0],
              [-80.916645718179055, 35.355248278140678, 0.0],
              [-80.917037385414019, 35.355820678020841, 0.0],
              [-80.917681185066357, 35.357217478088735, 0.0],
              [-80.918044718508284, 35.357446478153257, 0.0],
              [-80.918995184877701, 35.35756061190154, 0.0],
              [-80.920309384503653, 35.357857811868875, 0.0],
              [-80.920532917600326, 35.357491278898159, 0.0],
              [-80.920560518117412, 35.357101878063027, 0.0],
              [-80.920420585425148, 35.356735478595049, 0.0],
              [-80.920895717981736, 35.35632307883278, 0.0],
              [-80.921006584710256, 35.354788478156784, 0.0],
              [-80.920586518291202, 35.353483211921265, 0.0],
              [-80.920782184541821, 35.35346027824999, 0.0],
              [-80.921061785203932, 35.353643411369546, 0.0],
              [-80.921313718557585, 35.353986878215075, 0.0],
              [-80.921593584597659, 35.354605078610859, 0.0],
              [-80.921621984535165, 35.355475411866486, 0.0],
              [-80.921733984924032, 35.355681478375018, 0.0],
              [-80.922041517740155, 35.355795811857327, 0.0],
              [-80.922433118220411, 35.356093478734714, 0.0],
              [-80.922656784914324, 35.356162078122026, 0.0],
              [-80.923019985279979, 35.355703878461668, 0.0],
              [-80.923299384942339, 35.355589278387576, 0.0],
              [-80.923383384709155, 35.355635012121489, 0.0],
              [-80.92338358432086, 35.355887012139121, 0.0],
              [-80.923467517609723, 35.356001412129913, 0.0],
              [-80.924446985101156, 35.357741678098762, 0.0],
              [-80.924810518447259, 35.357810278099116, 0.0],
              [-80.926934384656391, 35.356595478826613, 0.0],
              [-80.926850184962248, 35.35602281185421, 0.0],
              [-80.927017918264255, 35.355816678339536, 0.0],
              [-80.926933717625161, 35.355404478421725, 0.0],
              [-80.926737784413874, 35.355083878630793, 0.0],
              [-80.926681717668316, 35.354786211738983, 0.0],
              [-80.926793517636611, 35.354625811971481, 0.0],
              [-80.927072984413243, 35.354579878140136, 0.0],
              [-80.927184918097225, 35.354763078780024, 0.0],
              [-80.927409318119572, 35.35588521208804, 0.0],
              [-80.927661185230093, 35.356251477986369, 0.0],
              [-80.928583784611874, 35.356411478190687, 0.0],
              [-80.930456984345298, 35.356456411357954, 0.0],
              [-80.931519517780359, 35.356685011682337, 0.0],
              [-80.932022717616647, 35.356707478501825, 0.0],
              [-80.932609785387228, 35.356615678222859, 0.0],
              [-80.933615717681405, 35.356065478874413, 0.0],
              [-80.934370318590339, 35.355767411555533, 0.0],
              [-80.935795184708198, 35.354575678444768, 0.0],
              [-80.936130317737707, 35.353957211883099, 0.0],
              [-80.935738785231408, 35.35384287850539, 0.0],
              [-80.935347717814508, 35.354163678426438, 0.0],
              [-80.93484491856205, 35.354828211999859, 0.0],
              [-80.934593384688938, 35.35494281146751, 0.0],
              [-80.93450951851456, 35.354897012229834, 0.0],
              [-80.934481317864808, 35.354553478212161, 0.0],
              [-80.935039717857492, 35.353545478870338, 0.0],
              [-80.935207318064073, 35.353408011936551, 0.0],
              [-80.935262718099168, 35.352720878035527, 0.0],
              [-80.93570971840127, 35.352308411775546, 0.0],
              [-80.936184784416952, 35.352193678496967, 0.0],
              [-80.936967117572664, 35.351437411796987, 0.0],
              [-80.937078584222647, 35.351185278376065, 0.0],
              [-80.937022585121483, 35.35100221150266, 0.0],
              [-80.936770985355153, 35.350979411857111, 0.0],
              [-80.936435718295343, 35.351231611419571, 0.0],
              [-80.935988518634943, 35.351323411317324, 0.0],
              [-80.935122318406528, 35.351827812260836, 0.0],
              [-80.934647184896733, 35.352011211878221, 0.0],
              [-80.934060118101428, 35.352011478265361, 0.0],
              [-80.93330491836123, 35.351485078219, 0.0],
              [-80.932996918649877, 35.350821012049465, 0.0],
              [-80.932549518253509, 35.350546411722512, 0.0],
              [-80.930955984807014, 35.350501278237637, 0.0],
              [-80.930424784295951, 35.350409878839841, 0.0],
              [-80.930117117746804, 35.350181078385653, 0.0],
              [-80.930088984619445, 35.349997812258202, 0.0],
              [-80.929893184440729, 35.349860678814252, 0.0],
              [-80.929613918339399, 35.350044012246428, 0.0],
              [-80.929585984480767, 35.350181412037777, 0.0],
              [-80.929138784548741, 35.350227412009097, 0.0],
              [-80.928243917627171, 35.349838412227058, 0.0],
              [-80.927796518424486, 35.349518078206501, 0.0],
              [-80.927544518181548, 35.348991278190233, 0.0],
              [-80.927097117924077, 35.348854211895443, 0.0],
              [-80.926873717604906, 35.349037412120794, 0.0],
              [-80.926790184711137, 35.34967881180583, 0.0],
              [-80.926343117624342, 35.349885078120558, 0.0],
              [-80.925979918366494, 35.350343278103942, 0.0],
              [-80.925868317925847, 35.350618211808552, 0.0],
              [-80.925504918284446, 35.350641278897569, 0.0],
              [-80.925169318074538, 35.350435278813421, 0.0],
              [-80.924693984388128, 35.350366678362946, 0.0],
              [-80.924302517904096, 35.350046278830668, 0.0],
              [-80.92410658521591, 35.349657011432761, 0.0],
              [-80.923742984485685, 35.349382278652357, 0.0],
              [-80.922904184825043, 35.34913067889871, 0.0],
              [-80.922344384340064, 35.347894278614099, 0.0],
              [-80.921337718475442, 35.347161678613318, 0.0],
              [-80.920834385257464, 35.347024478923515, 0.0],
              [-80.920275185056752, 35.346749878839653, 0.0],
              [-80.919995384477915, 35.346429411620655, 0.0],
              [-80.919799584504801, 35.346040078113504, 0.0],
              [-80.919435918601167, 35.345719478229157, 0.0],
              [-80.918960584464273, 35.345490678727401, 0.0],
              [-80.918261985319148, 35.345811611459837, 0.0],
              [-80.91781478466379, 35.345834678237644, 0.0],
              [-80.915885917647898, 35.34551481163566, 0.0],
              [-80.915522184543832, 35.345217078235144, 0.0],
              [-80.915494184637765, 35.345056812182641, 0.0],
              [-80.915689784980387, 35.344781878176633, 0.0],
              [-80.915717584251141, 35.344552878534444, 0.0],
              [-80.915493985431922, 35.344369678807574, 0.0],
              [-80.914851184889073, 35.344851011401616, 0.0],
              [-80.914767518722797, 35.345057078503011, 0.0],
              [-80.914487985007739, 35.345217478623205, 0.0],
              [-80.914460518566528, 35.346019078284009, 0.0],
              [-80.914348918421254, 35.346271011682916, 0.0],
              [-80.914264984600791, 35.346294012038108, 0.0],
              [-80.914209185151449, 35.346454278213351, 0.0],
              [-80.913650318232627, 35.346729278490066, 0.0],
              [-80.91353871856667, 35.347095878757017, 0.0],
              [-80.912672317742917, 35.347531278455079, 0.0],
              [-80.911358518040004, 35.34787521180894, 0.0],
              [-80.910715585185429, 35.347944211551336, 0.0],
              [-80.910743518259125, 35.347783878490297, 0.0],
              [-80.910855317801889, 35.34764627868077, 0.0],
              [-80.911526117682996, 35.347417078141675, 0.0],
              [-80.912029117695113, 35.347142078595532, 0.0],
              [-80.912224718454254, 35.346844278588755, 0.0],
              [-80.911805317910662, 35.346638278104543, 0.0],
              [-80.911581718221854, 35.346615478951449, 0.0],
              [-80.91133018544167, 35.346913278200979, 0.0],
              [-80.911078584866431, 35.347005012259935, 0.0],
              [-80.910994718250208, 35.346936278686904, 0.0],
              [-80.911105984806881, 35.345676611992069, 0.0],
              [-80.911972384939418, 35.345264012251548, 0.0],
              [-80.912084118458878, 35.345080811332522, 0.0],
              [-80.912167585104925, 35.34457687887128, 0.0],
              [-80.912754385061916, 35.344027078075221, 0.0],
              [-80.913033584594402, 35.343500211512911, 0.0],
              [-80.913620518350186, 35.343110678697165, 0.0],
              [-80.914375118442337, 35.342904278628538, 0.0],
              [-80.915297517994972, 35.342881078184845, 0.0],
              [-80.917729384313432, 35.343040478635857, 0.0],
              [-80.91798111812173, 35.343338211527062, 0.0],
              [-80.918679917852998, 35.343269212254938, 0.0],
              [-80.91881991853441, 35.343704278490044, 0.0],
              [-80.919071718604172, 35.343933211525432, 0.0],
              [-80.919602785254554, 35.344001678546419, 0.0],
              [-80.919994117816259, 35.344139078469823, 0.0],
              [-80.920329717893054, 35.344413812165051, 0.0],
              [-80.920777118163883, 35.344619678888854, 0.0],
              [-80.921699584472705, 35.344756812294712, 0.0],
              [-80.92211911786201, 35.34512301209022, 0.0],
              [-80.922454918635481, 35.345581011951126, 0.0],
              [-80.923461184773231, 35.345511811595244, 0.0],
              [-80.92374058496587, 35.345397211674474, 0.0],
              [-80.924187584233223, 35.344870211410054, 0.0],
              [-80.924467118695276, 35.344870078269736, 0.0],
              [-80.924495185027325, 35.34514487823963, 0.0],
              [-80.924187985135703, 35.345603078815977, 0.0],
              [-80.924216184928724, 35.346015278530558, 0.0],
              [-80.92480338509678, 35.346221278233507, 0.0],
              [-80.925138917869347, 35.346473078599203, 0.0],
              [-80.925139184668183, 35.346976878525865, 0.0],
              [-80.925223184257248, 35.347091411525511, 0.0],
              [-80.925223385394176, 35.347549478273578, 0.0],
              [-80.9250837846634, 35.347755678875558, 0.0],
              [-80.925056117965752, 35.348099211579154, 0.0],
              [-80.925223917978471, 35.348328211711781, 0.0],
              [-80.925643317639285, 35.348488278271397, 0.0],
              [-80.927543918718257, 35.348052278573604, 0.0],
              [-80.927571918305347, 35.347937811399802, 0.0],
              [-80.928018785309163, 35.347525411960476, 0.0],
              [-80.928046118474256, 35.346632211800198, 0.0],
              [-80.928269518376226, 35.346265611540701, 0.0],
              [-80.928660718389168, 35.345990612289967, 0.0],
              [-80.929163918186092, 35.345944478766562, 0.0],
              [-80.930002584665942, 35.34608147867791, 0.0],
              [-80.930198118409848, 35.345852411763218, 0.0],
              [-80.930588185051548, 35.344248878425702, 0.0],
              [-80.930755317855855, 35.34321827853833, 0.0],
              [-80.931229984446219, 35.342691278893625, 0.0],
              [-80.931565385389632, 35.342599478563152, 0.0],
              [-80.931677517693046, 35.342943011539802, 0.0],
              [-80.931482117870701, 35.343401078922781, 0.0],
              [-80.931454918232674, 35.344477678712366, 0.0],
              [-80.931902717721215, 35.345233211366597, 0.0],
              [-80.932238318374644, 35.345507878183049, 0.0],
              [-80.932797384857821, 35.345599211463217, 0.0],
              [-80.932993318377896, 35.34582821218244, 0.0],
              [-80.932993717921391, 35.346561078734162, 0.0],
              [-80.93419631852143, 35.347339212136248, 0.0],
              [-80.934783184748383, 35.347201478555242, 0.0],
              [-80.93500718516735, 35.347522011453769, 0.0],
              [-80.935258784305745, 35.347567678778042, 0.0],
              [-80.935593584354152, 35.346651278962355, 0.0],
              [-80.935845184839749, 35.346651211731356, 0.0],
              [-80.935817917982376, 35.347681878237339, 0.0],
              [-80.936405184945059, 35.347956411784445, 0.0],
              [-80.93702018502816, 35.348047678472042, 0.0],
              [-80.937215784400166, 35.34791021164606, 0.0],
              [-80.93746698470234, 35.347452011371068, 0.0],
              [-80.937802384702366, 35.347383077992809, 0.0],
              [-80.937970184347577, 35.34742881210633, 0.0],
              [-80.938026584716766, 35.34811581177172, 0.0],
              [-80.937497185155877, 35.350085878714218, 0.0],
              [-80.937497518465477, 35.350521011909848, 0.0],
              [-80.937888918411232, 35.350566611920719, 0.0],
              [-80.938252118425581, 35.350337411607832, 0.0],
              [-80.938531185059432, 35.349902078663916, 0.0],
              [-80.938754318237613, 35.34914607825808, 0.0],
              [-80.939368117684865, 35.34765707820727, 0.0],
              [-80.939619518386095, 35.347519478847175, 0.0],
              [-80.939815384361907, 35.347634011804338, 0.0],
              [-80.939927385016972, 35.348023278817095, 0.0],
              [-80.940039318450602, 35.348091878799366, 0.0],
              [-80.940766117679999, 35.347999878104766, 0.0],
              [-80.941716185289266, 35.347655678792094, 0.0],
              [-80.942023784686441, 35.347838811762564, 0.0],
              [-80.942024384709455, 35.348525878621132, 0.0],
              [-80.941800985308689, 35.348778011314359, 0.0],
              [-80.941493584862513, 35.348938612063336, 0.0],
              [-80.940850718379266, 35.349007612039351, 0.0],
              [-80.940375784438146, 35.34937427849794, 0.0],
              [-80.939677518103124, 35.350199212202916, 0.0],
              [-80.939342717805275, 35.350794811330488, 0.0],
              [-80.939454585224098, 35.350886411298369, 0.0],
              [-80.94004138478752, 35.350657011459937, 0.0],
              [-80.940572118239743, 35.350084211937826, 0.0],
              [-80.940935317587673, 35.349854878097098, 0.0],
              [-80.941242784609912, 35.349786078925305, 0.0],
              [-80.941578584786029, 35.350221078355389, 0.0],
              [-80.942361184772892, 35.350151811934069, 0.0],
              [-80.942947384720881, 35.349189611458804, 0.0],
              [-80.943785384907969, 35.34847907877004, 0.0],
              [-80.943813384582242, 35.348341678157595, 0.0],
              [-80.94367338449176, 35.348250211820435, 0.0],
              [-80.943673318113483, 35.348089812166222, 0.0],
              [-80.943952717843999, 35.34797521176516, 0.0],
              [-80.943924717613754, 35.347791878066097, 0.0],
              [-80.943113984474664, 35.347700811811144, 0.0],
              [-80.942778318704683, 35.347540612017703, 0.0],
              [-80.942637985394413, 35.346876612254029, 0.0],
              [-80.94280571832536, 35.346807811532621, 0.0],
              [-80.943141318514492, 35.34701367852648, 0.0],
              [-80.943449118456613, 35.347357012032305, 0.0],
              [-80.944036184397632, 35.347471278221896, 0.0],
              [-80.944287918336499, 35.347677211991886, 0.0],
              [-80.944343984379344, 35.347906211401586, 0.0],
              [-80.944651718085481, 35.348066412230963, 0.0],
              [-80.944819317701871, 35.347974678592827, 0.0],
              [-80.945014718521449, 35.347585078095833, 0.0],
              [-80.94512598523049, 35.346943811990435, 0.0],
              [-80.944677784343284, 35.345936278730861, 0.0],
              [-80.944313984426898, 35.345409811343913, 0.0],
              [-80.94425651863574, 35.343646278882041, 0.0],
              [-80.944368317566145, 35.343623412238649, 0.0],
              [-80.944620118311178, 35.343829278109489, 0.0],
              [-80.944815984605768, 35.344241478088868, 0.0],
              [-80.945012918454196, 35.34540941150204, 0.0],
              [-80.94518078509941, 35.345707078158824, 0.0],
              [-80.945404517931209, 35.345798477984324, 0.0],
              [-80.945795717675566, 35.345660878151996, 0.0],
              [-80.945879717556522, 35.345821211596821, 0.0],
              [-80.945656784339207, 35.346645811339251, 0.0],
              [-80.945740718615525, 35.346737278935286, 0.0],
              [-80.945741785286572, 35.347836678314863, 0.0],
              [-80.94658038451162, 35.347882078786441, 0.0],
              [-80.94688798488589, 35.347996278323194, 0.0],
              [-80.946999984527636, 35.348202411457862, 0.0],
              [-80.946273385078996, 35.348409012211825, 0.0],
              [-80.946049917894811, 35.348661078069853, 0.0],
              [-80.946134384429286, 35.349279411338479, 0.0],
              [-80.946525985124126, 35.349668478215698, 0.0],
              [-80.946861518480887, 35.349759877986635, 0.0],
              [-80.947224917630749, 35.349759678002712, 0.0],
              [-80.947923718454163, 35.349553078624133, 0.0],
              [-80.948985918635529, 35.349506611493823, 0.0],
              [-80.950076784292321, 35.350261812168689, 0.0],
              [-80.951111185036495, 35.350375611455128, 0.0],
              [-80.951278784327926, 35.350146478262289, 0.0],
              [-80.951278518099912, 35.349871611492475, 0.0],
              [-80.951110585188957, 35.349757211341242, 0.0],
              [-80.949908317683395, 35.349391611793905, 0.0],
              [-80.949516718570038, 35.349185611472194, 0.0],
              [-80.949628118309704, 35.348635878563186, 0.0],
              [-80.949851717976514, 35.34863567839642, 0.0],
              [-80.949963584473977, 35.348818878727116, 0.0],
              [-80.950271184553912, 35.348841611880211, 0.0],
              [-80.950326918165686, 35.348635478300835, 0.0],
              [-80.950634317978782, 35.34861227805947, 0.0],
              [-80.950690584801734, 35.348910078382232, 0.0],
              [-80.951753585115824, 35.349733878279835, 0.0],
              [-80.95214498451513, 35.349733678195449, 0.0],
              [-80.952535985029101, 35.349458478914279, 0.0],
              [-80.953234718276789, 35.349252011552316, 0.0],
              [-80.954716317679939, 35.349273878378185, 0.0],
              [-80.955414985236672, 35.34915887810385, 0.0],
              [-80.955722518053278, 35.349227411917724, 0.0],
              [-80.956169784515467, 35.349181278868123, 0.0],
              [-80.957259517507339, 35.348745278354784, 0.0],
              [-80.957287385000768, 35.348607878379752, 0.0],
              [-80.956028318385421, 35.34748647859174, 0.0],
              [-80.955915984646765, 35.347005678782871, 0.0],
              [-80.955356518589255, 35.3464792121299, 0.0],
              [-80.95516058488289, 35.346387678851556, 0.0],
              [-80.954405917551313, 35.346365412176098, 0.0],
              [-80.95437738518477, 35.34588441156658, 0.0],
              [-80.953985784756611, 35.345587012026193, 0.0],
              [-80.953314384213172, 35.344969011638725, 0.0],
              [-80.952755318200374, 35.344923611750012, 0.0],
              [-80.952475518654524, 35.344740612217706, 0.0],
              [-80.952279517851935, 35.344351278604314, 0.0],
              [-80.951916117866432, 35.344282811578687, 0.0],
              [-80.95155238475391, 35.343939611648118, 0.0],
              [-80.950741718356156, 35.343917212219644, 0.0],
              [-80.950545984917028, 35.343848611587447, 0.0],
              [-80.950545584578848, 35.343527878045997, 0.0],
              [-80.950992717691989, 35.343275678557724, 0.0],
              [-80.951831185216548, 35.343183611867154, 0.0],
              [-80.952222317997894, 35.34300007885934, 0.0],
              [-80.952136984278511, 35.341397012056525, 0.0],
              [-80.952248318387163, 35.340916011429222, 0.0],
              [-80.951688384739924, 35.340000211586855, 0.0],
              [-80.951436717860091, 35.339862878797149, 0.0],
              [-80.951045385263598, 35.339863078256613, 0.0],
              [-80.950961517673264, 35.339794478419549, 0.0],
              [-80.950933184198377, 35.339519678317522, 0.0],
              [-80.951380184844879, 35.339198678906008, 0.0],
              [-80.95140778423638, 35.338901011485014, 0.0],
              [-80.951323785229548, 35.338763611978223, 0.0],
              [-80.950708718576365, 35.338557878232287, 0.0],
              [-80.950652584590756, 35.338374678909304, 0.0],
              [-80.951211517906216, 35.338168211822044, 0.0],
              [-80.951210584599153, 35.337252078608287, 0.0],
              [-80.951070384754729, 35.336817011397017, 0.0],
              [-80.950958517731195, 35.336725478244482, 0.0],
              [-80.95076238426303, 35.336198811376718, 0.0],
              [-80.95017511870563, 35.335741078805263, 0.0],
              [-80.950174584829298, 35.335283078304442, 0.0],
              [-80.950397918288928, 35.334939412197514, 0.0],
              [-80.950564784798274, 35.33413761159148, 0.0],
              [-80.950956117907808, 35.334137278045745, 0.0],
              [-80.951292184559293, 35.334801278156725, 0.0],
              [-80.951320917864038, 35.335602878338335, 0.0],
              [-80.951516784294057, 35.335946278127949, 0.0],
              [-80.951908385278728, 35.336243811604632, 0.0],
              [-80.952523184232987, 35.336106011585791, 0.0],
              [-80.953082584428373, 35.336563678061644, 0.0],
              [-80.953082917671779, 35.336884412280597, 0.0],
              [-80.952775517890444, 35.336884478922705, 0.0],
              [-80.952607584335951, 35.336770078007092, 0.0],
              [-80.952328118186713, 35.336747478227174, 0.0],
              [-80.952272517967259, 35.337045211516447, 0.0],
              [-80.952917118608994, 35.338899878302634, 0.0],
              [-80.952833784828641, 35.339381011620361, 0.0],
              [-80.952749984549186, 35.339449678535992, 0.0],
              [-80.95275051796969, 35.33993067836964, 0.0],
              [-80.952890517670539, 35.34018247833562, 0.0],
              [-80.953533984322476, 35.340869211481717, 0.0],
              [-80.953646384430741, 35.341395878356479, 0.0],
              [-80.953731184738061, 35.342312011534268, 0.0],
              [-80.953843184411753, 35.342563812015811, 0.0],
              [-80.953956384936006, 35.343960811746058, 0.0],
              [-80.95490738491641, 35.344647278919247, 0.0],
              [-80.955438985147779, 35.345219478923262, 0.0],
              [-80.955914318000126, 35.345242078266047, 0.0],
              [-80.955998118035552, 35.345173412255981, 0.0],
              [-80.955603785114349, 35.342196211757624, 0.0],
              [-80.955239984284134, 35.341875812056429, 0.0],
              [-80.955183985228885, 35.341715478829748, 0.0],
              [-80.955770984643067, 35.341669278783606, 0.0],
              [-80.95588218452383, 35.341188278642207, 0.0],
              [-80.956244918062879, 35.340569678842265, 0.0],
              [-80.956524384853594, 35.340569478513437, 0.0],
              [-80.956496785107731, 35.340867278542568, 0.0],
              [-80.956329585091154, 35.341394078510419, 0.0],
              [-80.956302718485844, 35.342356078581631, 0.0],
              [-80.956443118175685, 35.343020211712499, 0.0],
              [-80.956583117958488, 35.343226211860404, 0.0],
              [-80.957030318660074, 35.343225878683221, 0.0],
              [-80.957337717894447, 35.343134011946965, 0.0],
              [-80.958231385360435, 35.342423478555951, 0.0],
              [-80.958678585265531, 35.342308678610578, 0.0],
              [-80.958762518496954, 35.342514677996512, 0.0],
              [-80.95775738445343, 35.34345447856834, 0.0],
              [-80.95725451804492, 35.343706678239194, 0.0],
              [-80.956975317859346, 35.34405047846613, 0.0],
              [-80.956975717722599, 35.344394011525857, 0.0],
              [-80.957478784456129, 35.344439478777801, 0.0],
              [-80.957562717828864, 35.344508078078569, 0.0],
              [-80.957590984324113, 35.344760078680629, 0.0],
              [-80.956613384284225, 35.345470678383649, 0.0],
              [-80.956613718169947, 35.345837211851077, 0.0],
              [-80.95669771849272, 35.345905811649423, 0.0],
              [-80.956810184358972, 35.346730278357136, 0.0],
              [-80.956922318444313, 35.346936212104488, 0.0],
              [-80.957453518381357, 35.347004611510606, 0.0],
              [-80.957648984234496, 35.346821278113978, 0.0],
              [-80.957900118482328, 35.346363011845725, 0.0],
              [-80.958123584228957, 35.346271212097434, 0.0],
              [-80.958823117710622, 35.34684327830815, 0.0],
              [-80.959186517778434, 35.346911811695492, 0.0],
              [-80.959661584971187, 35.346774078475335, 0.0],
              [-80.96010858474915, 35.346544678229137, 0.0],
              [-80.960359918525839, 35.346269611739963, 0.0],
              [-80.960359718019433, 35.346040611923613, 0.0],
              [-80.960135784639419, 35.34581167802817, 0.0],
              [-80.959576518205466, 35.3456518787806, 0.0],
              [-80.959240784685193, 35.345377278485401, 0.0],
              [-80.959212584716312, 35.34512541133622, 0.0],
              [-80.959464117891287, 35.345079411582148, 0.0],
              [-80.960303118568007, 35.345422278616404, 0.0],
              [-80.96091811766847, 35.345421812083359, 0.0],
              [-80.961113918270868, 35.345536211466296, 0.0],
              [-80.96142138417062, 35.345536011620688, 0.0],
              [-80.962035985264563, 35.345237811652019, 0.0],
              [-80.962343118550763, 35.344825278820792, 0.0],
              [-80.962678117723797, 35.344550211684101, 0.0],
              [-80.962872984503775, 35.343863011813824, 0.0],
              [-80.962788985077296, 35.343679811448609, 0.0],
              [-80.962481184962215, 35.343428211915061, 0.0],
              [-80.962480984525556, 35.343244878335959, 0.0],
              [-80.962844318087576, 35.343198811700198, 0.0],
              [-80.96323571829987, 35.343290212118134, 0.0],
              [-80.963431318158698, 35.343221278111514, 0.0],
              [-80.963598718317627, 35.342923478812793, 0.0],
              [-80.963681918493705, 35.342282078198345, 0.0],
              [-80.96396138435459, 35.342281878586185, 0.0],
              [-80.964017584250229, 35.342442211675646, 0.0],
              [-80.963935784790195, 35.344411878225628, 0.0],
              [-80.964187584820934, 35.344594878418086, 0.0],
              [-80.964271917829308, 35.345030012080059, 0.0],
              [-80.964803517733245, 35.345373212189237, 0.0],
              [-80.96533531851523, 35.346105678321557, 0.0],
              [-80.965503718205497, 35.346517811491033, 0.0],
              [-80.965839185303295, 35.346609212271808, 0.0],
              [-80.965978785026778, 35.346517478951164, 0.0],
              [-80.966034384262343, 35.346311278385258, 0.0],
              [-80.965586185346197, 35.345464211800248, 0.0],
              [-80.965389584328733, 35.344708478122016, 0.0],
              [-80.965640518396214, 35.344044078723933, 0.0],
              [-80.96586411775867, 35.344044011622799, 0.0],
              [-80.965948184778455, 35.344181278917837, 0.0],
              [-80.965921184515736, 35.345097411892908, 0.0],
              [-80.966313184459125, 35.345601011694662, 0.0],
              [-80.966341317499143, 35.345761278260184, 0.0],
              [-80.966677318459048, 35.346242078825355, 0.0],
              [-80.966984984418545, 35.346470811725425, 0.0],
              [-80.966985118333511, 35.346654012227248, 0.0],
              [-80.966622117630166, 35.346883278420918, 0.0],
              [-80.966594585195651, 35.347181078870591, 0.0],
              [-80.967825718184585, 35.348279611314929, 0.0],
              [-80.968888518256051, 35.348668012080203, 0.0],
              [-80.969672184414378, 35.349560612078115, 0.0],
              [-80.969923784629927, 35.349583411568489, 0.0],
              [-80.970398917780443, 35.349491412197914, 0.0],
              [-80.970762385346788, 35.349582678688392, 0.0],
              [-80.971237185272813, 35.349261678021655, 0.0],
              [-80.971207784429154, 35.34802501185272, 0.0],
              [-80.971123917953889, 35.347956278623371, 0.0],
              [-80.97020138513227, 35.348002811329238, 0.0],
              [-80.970005584868801, 35.347842677991139, 0.0],
              [-80.969949318436221, 35.347567878043407, 0.0],
              [-80.970424384380138, 35.347475878450901, 0.0],
              [-80.970899384407673, 35.347246478914826, 0.0],
              [-80.971011117876131, 35.347086078080814, 0.0],
              [-80.970898585018716, 35.346559411332329, 0.0],
              [-80.971485584482366, 35.346559011318917, 0.0],
              [-80.971736984815095, 35.34637547852158, 0.0],
              [-80.97173651816442, 35.345986212101209, 0.0],
              [-80.97165251806436, 35.345894612136611, 0.0],
              [-80.971652118431564, 35.345528211899321, 0.0],
              [-80.972657718651121, 35.344977612157408, 0.0],
              [-80.972936917519931, 35.344679612101778, 0.0],
              [-80.973187584250965, 35.343992278829461, 0.0],
              [-80.97355071800429, 35.343648478897435, 0.0],
              [-80.974191984157088, 35.342411211907717, 0.0],
              [-80.974610718675237, 35.342021478175255, 0.0],
              [-80.97463838476493, 35.341815278431945, 0.0],
              [-80.974554517755621, 35.34174667880022, 0.0],
              [-80.973883718470319, 35.34172441225455, 0.0],
              [-80.973379918131315, 35.341243811750793, 0.0],
              [-80.97335171849474, 35.341060612146023, 0.0],
              [-80.974134317724975, 35.340922478294765, 0.0],
              [-80.97438551827274, 35.340693278061352, 0.0],
              [-80.974637517639181, 35.341013678675566, 0.0],
              [-80.975000718416467, 35.34096761199114, 0.0],
              [-80.975251984975884, 35.340646812189512, 0.0],
              [-80.975279585337034, 35.340463478652133, 0.0],
              [-80.975055584768342, 35.340074411550894, 0.0],
              [-80.975026917835024, 35.339433078606774, 0.0],
              [-80.975138118132662, 35.339020811701516, 0.0],
              [-80.974465917612264, 35.337876078255221, 0.0],
              [-80.974465518650547, 35.337486811864665, 0.0],
              [-80.974716918007843, 35.337463678002869, 0.0],
              [-80.975024985186934, 35.337944411523175, 0.0],
              [-80.975584584605684, 35.33847067861327, 0.0],
              [-80.975640984973111, 35.338768411863278, 0.0],
              [-80.976228918008132, 35.339615278502997, 0.0],
              [-80.976285784592363, 35.340394011519294, 0.0],
              [-80.976174518326403, 35.34078347829054, 0.0],
              [-80.975923518579918, 35.341241678093617, 0.0],
              [-80.975672118243224, 35.341379411484475, 0.0],
              [-80.975672585344512, 35.341745812183973, 0.0],
              [-80.97642818497431, 35.342546811524294, 0.0],
              [-80.977071184910727, 35.34261501172071, 0.0],
              [-80.977070918354457, 35.342317211714843, 0.0],
              [-80.97695891769223, 35.342134078970247, 0.0],
              [-80.976902384795594, 35.341653078261842, 0.0],
              [-80.977097718379028, 35.341378078384182, 0.0],
              [-80.977265384161711, 35.341377878745682, 0.0],
              [-80.977321717607978, 35.34172141209239, 0.0],
              [-80.977489784652377, 35.342019011994203, 0.0],
              [-80.978580985304916, 35.342865477986486, 0.0],
              [-80.97969878530381, 35.342635612235313, 0.0],
              [-80.980257117703914, 35.342108211337802, 0.0],
              [-80.980731984928369, 35.341878811718786, 0.0],
              [-80.980927718181803, 35.341855678396996, 0.0],
              [-80.981486785035514, 35.341969811800091, 0.0],
              [-80.98190598440803, 35.341900678179698, 0.0],
              [-80.982185317751288, 35.341625611860586, 0.0],
              [-80.983273384137789, 35.340181678309889, 0.0],
              [-80.983356784261417, 35.339883878570078, 0.0],
              [-80.983160784357338, 35.339609211456143, 0.0],
              [-80.983132718124935, 35.339403078038757, 0.0],
              [-80.983356118084316, 35.339288411908932, 0.0],
              [-80.984166518414952, 35.339150211364228, 0.0],
              [-80.984221784637327, 35.338715012167746, 0.0],
              [-80.984528784525665, 35.338485678434203, 0.0],
              [-80.984500518143534, 35.338210812132232, 0.0],
              [-80.984220384555698, 35.337753078032506, 0.0],
              [-80.984080585200161, 35.337684478448679, 0.0],
              [-80.983828518160536, 35.337249611958462, 0.0],
              [-80.983800184494086, 35.33704341192044, 0.0],
              [-80.986172784414833, 35.334682278445946, 0.0],
              [-80.986228384821814, 35.334476078010468, 0.0],
              [-80.986396118071866, 35.334453078877203, 0.0],
              [-80.986626918334863, 35.334234212225631, 0.0],
              [-80.986758918305057, 35.33410907878136, 0.0],
              [-80.987066385206191, 35.334177611771388, 0.0],
              [-80.987681518560521, 35.334337412291966, 0.0],
              [-80.988100717738689, 35.334291011344554, 0.0],
              [-80.988799118165701, 35.334038478966818, 0.0],
              [-80.989050585106312, 35.334061078465602, 0.0],
              [-80.989274517944835, 35.334244211557788, 0.0],
              [-80.989582584515404, 35.334701878335174, 0.0],
              [-80.990086318207702, 35.335090812096396, 0.0],
              [-80.990785385149351, 35.335387812002985, 0.0],
              [-80.99086951783346, 35.335525211684391, 0.0],
              [-80.990870117714195, 35.335960411333105, 0.0],
              [-80.991457517908643, 35.336372078090463, 0.0],
              [-80.991458184931872, 35.336738478932361, 0.0],
              [-80.991234517591664, 35.336715812146053, 0.0],
              [-80.990171518006349, 35.336121411977231, 0.0],
              [-80.989920117463427, 35.336167411954257, 0.0],
              [-80.989948184219102, 35.336350611627147, 0.0],
              [-80.990368117978448, 35.336739478287079, 0.0],
              [-80.990648184689704, 35.337197278811153, 0.0],
              [-80.991235517791111, 35.337425811976956, 0.0],
              [-80.991542917816631, 35.337425478049539, 0.0],
              [-80.992101385081028, 35.337035611376955, 0.0],
              [-80.992604917518463, 35.337355811595678, 0.0],
              [-80.993275718460708, 35.337355078899812, 0.0],
              [-80.993834117948339, 35.336942411769229, 0.0],
              [-80.993833718579523, 35.336598811651662, 0.0],
              [-80.993693517901221, 35.336324078259288, 0.0],
              [-80.993357718656455, 35.336026678743615, 0.0],
              [-80.993357184904255, 35.335706011409385, 0.0],
              [-80.993776517730524, 35.335705611732557, 0.0],
              [-80.993859584263987, 35.33522461213542, 0.0],
              [-80.994167185058203, 35.335361678070853, 0.0],
              [-80.994307784320412, 35.335934078934812, 0.0],
              [-80.994475918177613, 35.336185877994943, 0.0],
              [-80.994671717682294, 35.33627721225325, 0.0],
              [-80.995090917473419, 35.336276812239433, 0.0],
              [-80.99534318443969, 35.336757478709671, 0.0],
              [-80.995539118166235, 35.336894678488733, 0.0],
              [-80.996154117807734, 35.337008612041238, 0.0],
              [-80.996015117479871, 35.337466878071609, 0.0],
              [-80.996238917872958, 35.337649812292192, 0.0],
              [-80.996239518304534, 35.338062078637492, 0.0],
              [-80.995792317768078, 35.338062478067528, 0.0],
              [-80.995568584346913, 35.33797121203559, 0.0],
              [-80.995205384195074, 35.338063078658728, 0.0],
              [-80.994869317975429, 35.337651278359935, 0.0],
              [-80.994533984662496, 35.337720411633335, 0.0],
              [-80.994227118386831, 35.338064211854814, 0.0],
              [-80.994088318132029, 35.338682678343893, 0.0],
              [-80.993808917685442, 35.338774611702057, 0.0],
              [-80.993053585052962, 35.338340212207129, 0.0],
              [-80.992606518025013, 35.338432278551224, 0.0],
              [-80.992383717761456, 35.338982078647199, 0.0],
              [-80.992440184319562, 35.339417278907248, 0.0],
              [-80.993167385218698, 35.33966841135782, 0.0],
              [-80.993223784489018, 35.340034878856954, 0.0],
              [-80.993391718245064, 35.340172078049022, 0.0],
              [-80.994090518220901, 35.340171411352578, 0.0],
              [-80.994230384801057, 35.340240012269611, 0.0],
              [-80.994202784489644, 35.340537811328538, 0.0],
              [-80.993561184348863, 35.341385811776384, 0.0],
              [-80.993421384694642, 35.341431678316674, 0.0],
              [-80.993197117883639, 35.340905212303511, 0.0],
              [-80.992805584991643, 35.340768212123706, 0.0],
              [-80.992134784270661, 35.340883278843492, 0.0],
              [-80.991883717927962, 35.341158412122581, 0.0],
              [-80.991828118325088, 35.341341678547245, 0.0],
              [-80.991493184668997, 35.341708478417615, 0.0],
              [-80.989705717518149, 35.342672211532829, 0.0],
              [-80.989091517754176, 35.343222411408817, 0.0],
              [-80.988728184686821, 35.34319981173077, 0.0],
              [-80.987973184811608, 35.34297161198694, 0.0],
              [-80.987693585304115, 35.342994678311456, 0.0],
              [-80.987134918010938, 35.343247078439724, 0.0],
              [-80.98626851840892, 35.343316611649826, 0.0],
              [-80.986017184873702, 35.343477212194976, 0.0],
              [-80.985933585239891, 35.34370627862333, 0.0],
              [-80.986549317798335, 35.344255411448948, 0.0],
              [-80.987165517500145, 35.345148078065577, 0.0],
              [-80.987530184431506, 35.346109678584419, 0.0],
              [-80.987753784363505, 35.346109412298922, 0.0],
              [-80.987865517817028, 35.345994811972588, 0.0],
              [-80.988033184831167, 35.346017611973551, 0.0],
              [-80.988173318208112, 35.34620067869993, 0.0],
              [-80.98825778424559, 35.346658678631499, 0.0],
              [-80.989488784146488, 35.347436212241576, 0.0],
              [-80.989628917706469, 35.347665078192421, 0.0],
              [-80.989489184693369, 35.347756878831184, 0.0],
              [-80.989069917636684, 35.347734278740447, 0.0],
              [-80.988678385223508, 35.347620212271707, 0.0],
              [-80.988230784268964, 35.347345811991403, 0.0],
              [-80.987839385262347, 35.347277478364745, 0.0],
              [-80.987532117898851, 35.347460878189693, 0.0],
              [-80.987364317623033, 35.34746107824899, 0.0],
              [-80.987196518037479, 35.347346811963391, 0.0],
              [-80.987168184901861, 35.347071878577765, 0.0],
              [-80.986804118134231, 35.346568412011244, 0.0],
              [-80.986552117739748, 35.346316678563753, 0.0],
              [-80.986132918369705, 35.346362878623978, 0.0],
              [-80.9858815185396, 35.346546412212348, 0.0],
              [-80.985545784728004, 35.346340478539808, 0.0],
              [-80.985433784942501, 35.346157411574644, 0.0],
              [-80.986132118271513, 35.345813211490757, 0.0],
              [-80.986047918257754, 35.345538478341723, 0.0],
              [-80.985320384239813, 35.344989411936233, 0.0],
              [-80.98504091827742, 35.345035478290775, 0.0],
              [-80.984845385145817, 35.345173077995938, 0.0],
              [-80.98464958453809, 35.345104612001521, 0.0],
              [-80.984648518599442, 35.34421141178089, 0.0],
              [-80.984424584578193, 35.344028278123247, 0.0],
              [-80.984145118057725, 35.344120211410591, 0.0],
              [-80.983782317479623, 35.344532878209321, 0.0],
              [-80.983698785285895, 35.34483067811108, 0.0],
              [-80.983755518346697, 35.345403212268764, 0.0],
              [-80.98425938486011, 35.345952412116745, 0.0],
              [-80.984231784338419, 35.346135678734264, 0.0],
              [-80.983840184659968, 35.346044412006698, 0.0],
              [-80.983448585217246, 35.345792878133985, 0.0],
              [-80.983196518485983, 35.345357878421268, 0.0],
              [-80.983168117768599, 35.345106012018931, 0.0],
              [-80.982804718046935, 35.345106278710929, 0.0],
              [-80.982805518603115, 35.345633078211165, 0.0],
              [-80.98316938466391, 35.346045011337665, 0.0],
              [-80.983393717712119, 35.346502811483511, 0.0],
              [-80.983561585071868, 35.346663011915766, 0.0],
              [-80.983561917694303, 35.346846211721207, 0.0],
              [-80.98339438489262, 35.347052478460611, 0.0],
              [-80.983506984392605, 35.347579211747828, 0.0],
              [-80.983367318247986, 35.34767101201642, 0.0],
              [-80.983171517641665, 35.347556611539886, 0.0],
              [-80.982807518452077, 35.347121812274338, 0.0],
              [-80.982611917712262, 35.347167678401888, 0.0],
              [-80.981998518659296, 35.348359212175872, 0.0],
              [-80.981746984961362, 35.348405278468782, 0.0],
              [-80.98163498529992, 35.348313811707179, 0.0],
              [-80.98169038443784, 35.347878611600251, 0.0],
              [-80.981885718109794, 35.347580678752792, 0.0],
              [-80.981911385210765, 35.34595447881027, 0.0],
              [-80.981799385081914, 35.345840078754421, 0.0],
              [-80.981268184716384, 35.345749012265529, 0.0],
              [-80.981044518257647, 35.345634612228913, 0.0],
              [-80.980569318635176, 35.345703811403936, 0.0],
              [-80.980206518394937, 35.346093478237577, 0.0],
              [-80.979032717686493, 35.346208877995821, 0.0],
              [-80.978669784943818, 35.346690211834883, 0.0],
              [-80.978306785275322, 35.346988411565185, 0.0],
              [-80.977691917801806, 35.347034678936325, 0.0],
              [-80.977160585175014, 35.346874878159376, 0.0],
              [-80.976880784344132, 35.346646011947215, 0.0],
              [-80.976796584764571, 35.346371278823995, 0.0],
              [-80.976572918068783, 35.346394278822793, 0.0],
              [-80.976517784911152, 35.347012812118628, 0.0],
              [-80.976881584860834, 35.347333078310101, 0.0],
              [-80.977273185031578, 35.347538878733246, 0.0],
              [-80.977721118551727, 35.348042411972138, 0.0],
              [-80.977721784829185, 35.348615011635076, 0.0],
              [-80.97797378462019, 35.348866678152895, 0.0],
              [-80.977974117846827, 35.34909567842687, 0.0],
              [-80.977722384727272, 35.349050078843973, 0.0],
              [-80.97691118499398, 35.348707278830204, 0.0],
              [-80.976379718669662, 35.348318411837766, 0.0],
              [-80.97615611810086, 35.348387278714178, 0.0],
              [-80.975904917510917, 35.348685212179014, 0.0],
              [-80.975905318248678, 35.349074612005538, 0.0],
              [-80.976129184746952, 35.349257678368794, 0.0],
              [-80.977331984513484, 35.349829211445346, 0.0],
              [-80.9775279843753, 35.35012681206824, 0.0],
              [-80.978170917912053, 35.350057478892708, 0.0],
              [-80.978506517949072, 35.350217478330414, 0.0],
              [-80.978506785104642, 35.350377811592253, 0.0],
              [-80.977919984375276, 35.350538678205041, 0.0],
              [-80.976997384440324, 35.35049367863747, 0.0],
              [-80.976829518082525, 35.350310611857765, 0.0],
              [-80.976605918413213, 35.350379478452837, 0.0],
              [-80.976578317869283, 35.350700078911075, 0.0],
              [-80.975740518657346, 35.351342078320179, 0.0],
              [-80.975741118574973, 35.351731478631073, 0.0],
              [-80.975601385028114, 35.351800278099851, 0.0],
              [-80.975042184875761, 35.351754878720563, 0.0],
              [-80.974734317913303, 35.351503278124582, 0.0],
              [-80.974734318121321, 35.35141167870222, 0.0],
              [-80.974398518309471, 35.351114211844376, 0.0],
              [-80.973308185142344, 35.351115211836813, 0.0],
              [-80.973056918113528, 35.351344411854647, 0.0],
              [-80.973030117541313, 35.352306278691657, 0.0],
              [-80.973253784739143, 35.352443612069052, 0.0],
              [-80.973757184879346, 35.352580611502859, 0.0],
              [-80.973869185218305, 35.352695011898021, 0.0],
              [-80.973841384608264, 35.352855411466173, 0.0],
              [-80.973534385185459, 35.353222011532736, 0.0],
              [-80.973563718641728, 35.354321411777995, 0.0],
              [-80.973340118006064, 35.354321612047897, 0.0],
              [-80.972976118072808, 35.353886678615936, 0.0],
              [-80.972639984848684, 35.353406011735757, 0.0],
              [-80.972444318302465, 35.353406211455059, 0.0],
              [-80.971913718071676, 35.353818878154087, 0.0],
              [-80.971690385036666, 35.354162478691983, 0.0],
              [-80.97043331808861, 35.354942278638916, 0.0],
              [-80.96979098429658, 35.355492411915108, 0.0],
              [-80.969092184708259, 35.355607478770992, 0.0],
              [-80.968057717608829, 35.355562612040615, 0.0],
              [-80.967527118433708, 35.355975211383594, 0.0],
              [-80.966996185035427, 35.356250478076639, 0.0],
              [-80.966940585310738, 35.356410878039391, 0.0],
              [-80.966633317869693, 35.356640078055946, 0.0],
              [-80.966577518424558, 35.356800478105299, 0.0],
              [-80.966270185112407, 35.356915278919487, 0.0],
              [-80.96613031867912, 35.356869478555289, 0.0],
              [-80.966129184886171, 35.355953411491186, 0.0],
              [-80.965514184504897, 35.355908011849841, 0.0],
              [-80.965513918575013, 35.355656077983184, 0.0],
              [-80.966603584511944, 35.355197278420135, 0.0],
              [-80.966827118579261, 35.355036678622412, 0.0],
              [-80.96671451795703, 35.354326811732214, 0.0],
              [-80.966014918068609, 35.353777678031179, 0.0],
              [-80.965734918281541, 35.35343441209217, 0.0],
              [-80.964978584691465, 35.351969078856648, 0.0],
              [-80.964586118689724, 35.351099078704074, 0.0],
              [-80.964557984460228, 35.35082427835296, 0.0],
              [-80.964137784735755, 35.350137478922946, 0.0],
              [-80.963774117854328, 35.349840078830624, 0.0],
              [-80.963633984406158, 35.34958827846561, 0.0],
              [-80.963661785171922, 35.34931327876599, 0.0],
              [-80.963801184474931, 35.349084278781831, 0.0],
              [-80.963800718341048, 35.348534611799217, 0.0],
              [-80.963688584964302, 35.348328478906133, 0.0],
              [-80.963324917791155, 35.348031011409823, 0.0],
              [-80.962765785081118, 35.348077212259504, 0.0],
              [-80.96251438490151, 35.348169012247091, 0.0],
              [-80.962151317603656, 35.348512812161154, 0.0],
              [-80.961397917969251, 35.349795878706352, 0.0],
              [-80.96058798446262, 35.350506611962871, 0.0],
              [-80.960336917899113, 35.350987678555434, 0.0],
              [-80.959555118700223, 35.351835678433304, 0.0],
              [-80.959471318418792, 35.352019011445265, 0.0],
              [-80.959499784635469, 35.352385411330353, 0.0],
              [-80.959947718682855, 35.353003478720382, 0.0],
              [-80.959892784352377, 35.353965412066223, 0.0],
              [-80.960032718634366, 35.354057012052202, 0.0],
              [-80.960591917829205, 35.354125211857657, 0.0],
              [-80.960759718711188, 35.354216812006875, 0.0],
              [-80.960731917645731, 35.354331278290537, 0.0],
              [-80.960368584423179, 35.354514811309123, 0.0],
              [-80.959950718274868, 35.355866412069069, 0.0],
              [-80.959727117969123, 35.355866478600468, 0.0],
              [-80.959419317980434, 35.355569011568242, 0.0],
              [-80.959083118237928, 35.354905078802638, 0.0],
              [-80.958803518459632, 35.354974011341461, 0.0],
              [-80.957909718700563, 35.355661611918187, 0.0],
              [-80.956679518061733, 35.355593811839299, 0.0],
              [-80.955952717692057, 35.355640212162349, 0.0],
              [-80.955393984478206, 35.356121478585322, 0.0],
              [-80.955170317572652, 35.356030078913278, 0.0],
              [-80.955057518618389, 35.354999478440853, 0.0],
              [-80.954917518232378, 35.354816278808642, 0.0],
              [-80.954665784741024, 35.354839411463033, 0.0],
              [-80.954414517594969, 35.355091478271916, 0.0],
              [-80.953827384373994, 35.355114811935337, 0.0],
              [-80.953296385306587, 35.355229678440935, 0.0],
              [-80.952429584930201, 35.355069878385983, 0.0],
              [-80.951618584282443, 35.354818478304161, 0.0],
              [-80.950584117696124, 35.354681678273948, 0.0],
              [-80.950443718527751, 35.354017611350365, 0.0],
              [-80.949884117547356, 35.353537011376872, 0.0],
              [-80.948625784248875, 35.353194277986617, 0.0],
              [-80.948038785155234, 35.353240478411855, 0.0],
              [-80.94770391761044, 35.353904878278691, 0.0],
              [-80.9472567185923, 35.353996678229642, 0.0],
              [-80.947033318338526, 35.354294611869868, 0.0],
              [-80.946809784788329, 35.35434047864684, 0.0],
              [-80.946697784500799, 35.35424901178903, 0.0],
              [-80.946613384890455, 35.35369927876264, 0.0],
              [-80.94703211786495, 35.352920478309862, 0.0],
              [-80.946808384621789, 35.352806011835483, 0.0],
              [-80.945158784543153, 35.352669611502847, 0.0],
              [-80.945158584365103, 35.35239467801469, 0.0],
              [-80.944710784290251, 35.351799478005773, 0.0],
              [-80.944487118570493, 35.351639412051995, 0.0],
              [-80.943928117896803, 35.351822878110518, 0.0],
              [-80.943061918146441, 35.352281411726111, 0.0],
              [-80.942922317853629, 35.352487811878468, 0.0],
              [-80.942223584495963, 35.352602678188482, 0.0],
              [-80.941860184848608, 35.352763212185266, 0.0],
              [-80.941580917644927, 35.353038078429726, 0.0],
              [-80.941469317711338, 35.353313011344554, 0.0],
              [-80.941581584388274, 35.353839678439464, 0.0],
              [-80.941749517994936, 35.354114478274056, 0.0],
              [-80.941721785037117, 35.35429767820925, 0.0],
              [-80.941470118266238, 35.35422907800271, 0.0],
              [-80.941134184234031, 35.353771278500467, 0.0],
              [-80.940994518074746, 35.353794278486298, 0.0],
              [-80.940547584276885, 35.354412878293296, 0.0],
              [-80.940184517903461, 35.354756611979816, 0.0],
              [-80.939737384359745, 35.355054612248658, 0.0],
              [-80.939542117602642, 35.355444011368206, 0.0],
              [-80.939206584478086, 35.355490011930428, 0.0],
              [-80.939178518172852, 35.355329678917165, 0.0],
              [-80.938982718428036, 35.355215277960028, 0.0],
              [-80.938619517739809, 35.35537581150971, 0.0],
              [-80.937920918272653, 35.355857211762149, 0.0],
              [-80.937446118038324, 35.356407078322505, 0.0],
              [-80.935908984373555, 35.357186611813034, 0.0],
              [-80.934735584437703, 35.358057478552595, 0.0],
              [-80.933114717834215, 35.359066078274253, 0.0],
              [-80.932583718649752, 35.359272412088842, 0.0],
              [-80.928921784725361, 35.360167412061152, 0.0],
              [-80.927440184763469, 35.360420012168277, 0.0],
              [-80.926881118005497, 35.360672078781541, 0.0],
              [-80.92665771785525, 35.360901411353161, 0.0],
              [-80.926517984862357, 35.361222012174252, 0.0],
              [-80.926546117682179, 35.361382411635034, 0.0],
              [-80.926741918226938, 35.361588478222671, 0.0],
              [-80.927385184252302, 35.361862878797361, 0.0],
              [-80.928224117771961, 35.361977078118272, 0.0],
              [-80.929733784243055, 35.362022212128146, 0.0],
              [-80.93180331839757, 35.362799878555784, 0.0],
              [-80.932222718560979, 35.362799678300412, 0.0],
              [-80.932837718005885, 35.362639078836835, 0.0],
              [-80.933871384846697, 35.361493411692422, 0.0],
              [-80.934514118727293, 35.361035012004464, 0.0],
              [-80.935520184648396, 35.36055347799396, 0.0],
              [-80.936051384621194, 35.360553278200392, 0.0],
              [-80.93647071792644, 35.36043861131725, 0.0],
              [-80.937113518198274, 35.36009467875467, 0.0],
              [-80.937700584881156, 35.360025678844536, 0.0],
              [-80.938679184645494, 35.360208411346399, 0.0],
              [-80.939014985043187, 35.360574611930403, 0.0],
              [-80.939322718559481, 35.36066607820343, 0.0],
              [-80.939825917577423, 35.360597078227173, 0.0],
              [-80.940328918658466, 35.360367678104083, 0.0],
              [-80.941894118050172, 35.360000411777825, 0.0],
              [-80.942313584789034, 35.360046078247315, 0.0],
              [-80.94270518493262, 35.360251878930058, 0.0],
              [-80.94312478496667, 35.360641011849403, 0.0],
              [-80.943628784930425, 35.361465278513364, 0.0],
              [-80.943909984385527, 35.363251478508275, 0.0],
              [-80.9441061849259, 35.363869811769725, 0.0],
              [-80.944330318482599, 35.364236211718023, 0.0],
              [-80.944386384759042, 35.364510878617018, 0.0],
              [-80.944778317635212, 35.365037478810905, 0.0],
              [-80.945085984955952, 35.365266278437254, 0.0],
              [-80.947043184948882, 35.365265078794863, 0.0],
              [-80.948608784754015, 35.365035211495659, 0.0],
              [-80.94919571800402, 35.364737011765563, 0.0],
              [-80.950061584397133, 35.36388907865939, 0.0],
              [-80.950173384556336, 35.363980677954117, 0.0],
              [-80.95014578444092, 35.364370012228797, 0.0],
              [-80.949671318699544, 35.365034478504541, 0.0],
              [-80.949140318357792, 35.365355478779399, 0.0],
              [-80.948497518269434, 35.365607812098226, 0.0],
              [-80.948609518023801, 35.365745211906322, 0.0],
              [-80.951182384366888, 35.366407678143737, 0.0],
              [-80.951629984483205, 35.366613611586018, 0.0],
              [-80.953280784899903, 35.367917878592166, 0.0],
              [-80.95389631762221, 35.368192278512204, 0.0],
              [-80.955126518052055, 35.368099878005658, 0.0],
              [-80.95515338424471, 35.367160878853134, 0.0],
              [-80.955069185236411, 35.366931878688391, 0.0],
              [-80.955459985149304, 35.366198678385693, 0.0],
              [-80.955543918500879, 35.366175811564567, 0.0],
              [-80.955571717751795, 35.366038411729015, 0.0],
              [-80.955823184979778, 35.365992411997567, 0.0],
              [-80.955935184971153, 35.366129678923997, 0.0],
              [-80.955879918347193, 35.366702412250405, 0.0],
              [-80.955991985241724, 35.366885478427321, 0.0],
              [-80.956970718253828, 35.367068011496784, 0.0],
              [-80.957250718353663, 35.367388478123594, 0.0],
              [-80.958201785333472, 35.367800078214657, 0.0],
              [-80.958845584843417, 35.368532612238056, 0.0],
              [-80.959070184948203, 35.369334011491844, 0.0],
              [-80.959490118328844, 35.369768812024382, 0.0],
              [-80.959686385196107, 35.37036421176213, 0.0],
              [-80.962903585039399, 35.371758878359088, 0.0],
              [-80.964078318459968, 35.372078678790039, 0.0],
              [-80.964637385022868, 35.371940877966651, 0.0],
              [-80.96525171777715, 35.371276278080167, 0.0],
              [-80.965837718197619, 35.370084878249884, 0.0],
              [-80.965977117701996, 35.369741078551172, 0.0],
              [-80.966172118456981, 35.369168411323315, 0.0],
              [-80.966797518296772, 35.369453678577983, 0.0],
              [-80.967375118370271, 35.369717078485223, 0.0],
              [-80.967347318419044, 35.369923278140597, 0.0],
              [-80.967319584223958, 35.370106611512014, 0.0],
              [-80.96771111811529, 35.370197811489405, 0.0],
              [-80.967851118538391, 35.370335212001478, 0.0],
              [-80.967962985133084, 35.370335078767305, 0.0],
              [-80.968745318663409, 35.369853611835346, 0.0],
              [-80.969192585295318, 35.369761478210499, 0.0],
              [-80.969416517776637, 35.369875878301492, 0.0],
              [-80.969444917735117, 35.370219478344445, 0.0],
              [-80.968801917590142, 35.370426078871787, 0.0],
              [-80.968438917900627, 35.370792811325607, 0.0],
              [-80.968187718553651, 35.37125101216386, 0.0],
              [-80.967880384420042, 35.371480278044935, 0.0],
              [-80.9676565843709, 35.371388812167631, 0.0],
              [-80.96718171856655, 35.371847278372776, 0.0],
              [-80.967209918427713, 35.371938812236664, 0.0],
              [-80.966288717565092, 35.373290878756883, 0.0],
              [-80.965031784488076, 35.374574411324481, 0.0],
              [-80.964221518329254, 35.375147612181472, 0.0],
              [-80.964109784423457, 35.375239278302239, 0.0],
              [-80.962264917743383, 35.375904811764116, 0.0],
              [-80.96125831752542, 35.375997078584852, 0.0],
              [-80.958825118368907, 35.375678278187173, 0.0],
              [-80.958797317698114, 35.37583867887956, 0.0],
              [-80.959328785086228, 35.376067278308966, 0.0],
              [-80.959412918425258, 35.376227478810556, 0.0],
              [-80.959105384516363, 35.376273612240169, 0.0],
              [-80.958462385208179, 35.376525878809794, 0.0],
              [-80.958295118167285, 35.37693827823071, 0.0],
              [-80.958379918075764, 35.377808611716773, 0.0],
              [-80.95793258515512, 35.37785467819041, 0.0],
              [-80.957764718001783, 35.377694478546324, 0.0],
              [-80.957875317558532, 35.376549278769367, 0.0],
              [-80.956588518449536, 35.37613787804456, 0.0],
              [-80.956392918245669, 35.376298278419092, 0.0],
              [-80.956225584971151, 35.37668781177895, 0.0],
              [-80.955974118559013, 35.376871211594164, 0.0],
              [-80.955386384655426, 35.376459412234141, 0.0],
              [-80.955385584398485, 35.375703612242404, 0.0],
              [-80.954713918682501, 35.375131477973305, 0.0],
              [-80.954573984387764, 35.374971278360945, 0.0],
              [-80.954573518182457, 35.374490278633843, 0.0],
              [-80.95423778459795, 35.374353078131037, 0.0],
              [-80.954265518030454, 35.374146878368798, 0.0],
              [-80.954824317774936, 35.373665612023679, 0.0],
              [-80.955103584260272, 35.373298878380709, 0.0],
              [-80.955298984765776, 35.372886478249114, 0.0],
              [-80.955186784314066, 35.372657611401912, 0.0],
              [-80.954515118385629, 35.371993811322554, 0.0],
              [-80.951577185334898, 35.369957478640657, 0.0],
              [-80.947940785224418, 35.36840227855582, 0.0],
              [-80.946961985219033, 35.368196878699528, 0.0],
              [-80.946066584560199, 35.367487411979546, 0.0],
              [-80.945003718144349, 35.367075678831299, 0.0],
              [-80.944164917838378, 35.367145011608166, 0.0],
              [-80.943913184802554, 35.366961878367739, 0.0],
              [-80.943409785118845, 35.366847611928065, 0.0],
              [-80.942570918593091, 35.366871012132719, 0.0],
              [-80.942571118472642, 35.367100012213534, 0.0],
              [-80.943549917571474, 35.367259811721745, 0.0],
              [-80.943550185075864, 35.367534611995708, 0.0],
              [-80.94313091761596, 35.367809678129397, 0.0],
              [-80.942851584412523, 35.368107612187764, 0.0],
              [-80.942767917993024, 35.368359612156262, 0.0],
              [-80.9431873850952, 35.368405078867156, 0.0],
              [-80.943551118447033, 35.36870267882324, 0.0],
              [-80.943551317845461, 35.368840078362815, 0.0],
              [-80.943215918242643, 35.369046411962515, 0.0],
              [-80.942628784955332, 35.369161212166311, 0.0],
              [-80.942349318575083, 35.369321678216444, 0.0],
              [-80.942293784220425, 35.36968821142225, 0.0],
              [-80.942489717815064, 35.369917078638643, 0.0],
              [-80.942713785389344, 35.370466612222877, 0.0],
              [-80.943105584772681, 35.370810011791285, 0.0],
              [-80.944112584740566, 35.371313278799121, 0.0],
              [-80.94461658467722, 35.371977211871233, 0.0],
              [-80.945315718021092, 35.372182878775661, 0.0],
              [-80.94553958433707, 35.372366011579452, 0.0],
              [-80.945735718248102, 35.372732411293178, 0.0],
              [-80.945903585241837, 35.372869678251433, 0.0],
              [-80.946015385390112, 35.372869611789525, 0.0],
              [-80.946490584490974, 35.372548678318893, 0.0],
              [-80.946770117901281, 35.372525611424166, 0.0],
              [-80.947133917857045, 35.372868878695044, 0.0],
              [-80.947050384874387, 35.373235412051557, 0.0],
              [-80.946910718410592, 35.373395812083245, 0.0],
              [-80.946883185181051, 35.373876812061766, 0.0],
              [-80.946743584961837, 35.374151678322242, 0.0],
              [-80.946744118096348, 35.374632678265272, 0.0],
              [-80.946911918136706, 35.374815811281842, 0.0],
              [-80.947555384803763, 35.37513607849332, 0.0],
              [-80.948338984458459, 35.375822678056991, 0.0],
              [-80.949345717766889, 35.375844878553998, 0.0],
              [-80.949373917990812, 35.376051012008553, 0.0],
              [-80.948786785289585, 35.376303411619304, 0.0],
              [-80.948535517767084, 35.37667001222453, 0.0],
              [-80.948227784626965, 35.376578478700544, 0.0],
              [-80.947556117952104, 35.375937678754987, 0.0],
              [-80.946884984390394, 35.375938078590032, 0.0],
              [-80.946102518531603, 35.376488211271678, 0.0],
              [-80.946103118452044, 35.377221078889328, 0.0],
              [-80.946634717555412, 35.377541478800431, 0.0],
              [-80.946439318016942, 35.377862211967219, 0.0],
              [-80.946383918029611, 35.378434811275177, 0.0],
              [-80.94627198521745, 35.37852647848927, 0.0],
              [-80.94585251855662, 35.378480878595262, 0.0],
              [-80.945432584819827, 35.377954278717603, 0.0],
              [-80.94439818525349, 35.378321411286379, 0.0],
              [-80.942720517644688, 35.378459811973741, 0.0],
              [-80.942581317677792, 35.379124078713758, 0.0],
              [-80.942105918592318, 35.37900981204195, 0.0],
              [-80.941965118697368, 35.378048011346557, 0.0],
              [-80.941982185102518, 35.378047611573542, 0.0],
              [-80.942999784755258, 35.378024478017004, 0.0],
            ],
            [
              [-80.958962118482603, 35.372907011378814, 0.0],
              [-80.959297718268033, 35.372975478291295, 0.0],
              [-80.960164518585898, 35.37288321201801, 0.0],
              [-80.960276318657904, 35.372814478720485, 0.0],
              [-80.960359717572473, 35.372516611828111, 0.0],
              [-80.961059118672992, 35.372790878431488, 0.0],
              [-80.961450584780621, 35.372790611508037, 0.0],
              [-80.963239717608346, 35.372331278165007, 0.0],
              [-80.963211585101348, 35.372171011665408, 0.0],
              [-80.962036918181681, 35.37194267825214, 0.0],
              [-80.961001784740915, 35.371393811587978, 0.0],
              [-80.960274384799177, 35.371142478019713, 0.0],
              [-80.959715185248839, 35.371142878471467, 0.0],
              [-80.96016331776579, 35.371852612056934, 0.0],
              [-80.95996778488005, 35.372035878741713, 0.0],
              [-80.9591287852476, 35.371922011559413, 0.0],
              [-80.959016385226576, 35.371441078053685, 0.0],
              [-80.958680784313287, 35.371235278432863, 0.0],
              [-80.958065384705463, 35.371144077976219, 0.0],
              [-80.957561784974487, 35.370869611608555, 0.0],
              [-80.957310318433059, 35.370915478491, 0.0],
              [-80.95714258512578, 35.371030077998945, 0.0],
              [-80.957142984595535, 35.371511078389418, 0.0],
              [-80.957059318506694, 35.371602811651506, 0.0],
              [-80.957059518434619, 35.371877611507948, 0.0],
              [-80.957451384338967, 35.372220878764757, 0.0],
              [-80.957479518706521, 35.372335412151287, 0.0],
              [-80.957759184546077, 35.372495478652176, 0.0],
              [-80.958374585021417, 35.372609612044094, 0.0],
              [-80.958962118482603, 35.372907011378814, 0.0],
            ],
            [
              [-80.92295806504373, 35.360411966922392, 0.0],
              [-80.922917906828943, 35.360208658155507, 0.0],
              [-80.922915798197195, 35.36026771425928, 0.0],
              [-80.922945144256744, 35.36041631732899, 0.0],
              [-80.92295806504373, 35.360411966922392, 0.0],
            ],
            [
              [-80.937666317796555, 35.351872212029825, 0.0],
              [-80.93749831763914, 35.351689078546229, 0.0],
              [-80.937302718636232, 35.351757811997381, 0.0],
              [-80.936464718502151, 35.352514078550634, 0.0],
              [-80.93646538490043, 35.353453078862607, 0.0],
              [-80.936688984708269, 35.353498878185086, 0.0],
              [-80.937415317828609, 35.352742612043059, 0.0],
              [-80.93836538516247, 35.352352811780413, 0.0],
              [-80.938616917653022, 35.352100678817834, 0.0],
              [-80.938616784446353, 35.351963278874791, 0.0],
              [-80.938476984451754, 35.351871678072015, 0.0],
              [-80.937666317796555, 35.351872212029825, 0.0],
            ],
            [
              [-80.988220918372676, 35.340337478843324, 0.0],
              [-80.988835518488216, 35.340085011386442, 0.0],
              [-80.989338718106865, 35.340153078253685, 0.0],
              [-80.990232718387546, 35.339831612285515, 0.0],
              [-80.990344318143045, 35.339717011531704, 0.0],
              [-80.990287918075083, 35.339327678443141, 0.0],
              [-80.989867918632541, 35.338870011972709, 0.0],
              [-80.988637584576423, 35.338481878249887, 0.0],
              [-80.988413984605202, 35.338505078063704, 0.0],
              [-80.988022984357414, 35.338734412071297, 0.0],
              [-80.9878001179205, 35.33919267840006, 0.0],
              [-80.986990718621087, 35.340086678473071, 0.0],
              [-80.98687931772001, 35.340315812055181, 0.0],
              [-80.986851784293833, 35.340636478251035, 0.0],
              [-80.986963717518051, 35.340727878185795, 0.0],
              [-80.987187318360213, 35.34072781201526, 0.0],
              [-80.987661984955352, 35.340429612140859, 0.0],
              [-80.988220918372676, 35.340337478843324, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1635,
        ComID: 9755320,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.521,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.07546694724,
        Shape_Area: 5.162401e-5,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999796718468275, 35.21119421208482, 0.0],
              [-80.999796584179961, 35.215375278436291, 0.0],
              [-80.999796584582384, 35.216576478355712, 0.0],
              [-80.99935058490243, 35.216691478395354, 0.0],
              [-80.998429584400583, 35.21673821180331, 0.0],
              [-80.997927517893245, 35.216876212412615, 0.0],
              [-80.997620784897151, 35.217059812391447, 0.0],
              [-80.997705184817264, 35.21751767866548, 0.0],
              [-80.998347917762246, 35.217998012213812, 0.0],
              [-80.998906917680785, 35.218615879023574, 0.0],
              [-80.99901891822212, 35.218844812191932, 0.0],
              [-80.998991384316994, 35.219119612421096, 0.0],
              [-80.998796118280154, 35.219142678913563, 0.0],
              [-80.998544718316765, 35.219005612272085, 0.0],
              [-80.997929785130353, 35.218410678182813, 0.0],
              [-80.997315318090187, 35.218113611511988, 0.0],
              [-80.996617584812597, 35.218114411956869, 0.0],
              [-80.996255118607849, 35.218343811585427, 0.0],
              [-80.996283585155439, 35.218778878324194, 0.0],
              [-80.996535717525617, 35.219305411586326, 0.0],
              [-80.996954918614861, 35.219694278795146, 0.0],
              [-80.996984584984986, 35.22093107836433, 0.0],
              [-80.996790184472729, 35.221572478732384, 0.0],
              [-80.99645591838248, 35.222099678773333, 0.0],
              [-80.996149318620311, 35.222374879126754, 0.0],
              [-80.995200917964453, 35.22274227848817, 0.0],
              [-80.994783118307765, 35.223361078670976, 0.0],
              [-80.994783784126668, 35.223773412222364, 0.0],
              [-80.994867718213769, 35.223956479018106, 0.0],
              [-80.994812385224691, 35.224277211557336, 0.0],
              [-80.99422658531121, 35.224529811885155, 0.0],
              [-80.993920318504109, 35.225011012059497, 0.0],
              [-80.994032518351133, 35.225400279027987, 0.0],
              [-80.993669718107171, 35.225469278592286, 0.0],
              [-80.993418984975065, 35.225790212105011, 0.0],
              [-80.993223718122692, 35.22588201209372, 0.0],
              [-80.992832918532173, 35.225836478785183, 0.0],
              [-80.992106918351681, 35.225608278195374, 0.0],
              [-80.991464785031084, 35.225563078297903, 0.0],
              [-80.990823118637934, 35.225746878609769, 0.0],
              [-80.990488184808967, 35.22572427887885, 0.0],
              [-80.990320117458111, 35.22531221166588, 0.0],
              [-80.990124518549436, 35.225129078564187, 0.0],
              [-80.989761784198961, 35.225152412441517, 0.0],
              [-80.989426984888198, 35.225335878938807, 0.0],
              [-80.989148184582476, 35.225633879015632, 0.0],
              [-80.989176518103534, 35.225908812295664, 0.0],
              [-80.989428384755513, 35.226412412016828, 0.0],
              [-80.989400918102717, 35.226664412227322, 0.0],
              [-80.989150118137346, 35.226985212271615, 0.0],
              [-80.987979317618283, 35.228040011815104, 0.0],
              [-80.987588717582724, 35.228223612316654, 0.0],
              [-80.987003317941088, 35.228773812272259, 0.0],
              [-80.986417317675119, 35.228957611608884, 0.0],
              [-80.986054784488289, 35.229209878547316, 0.0],
              [-80.985999984595367, 35.229919878572304, 0.0],
              [-80.985888584442677, 35.230126212428381, 0.0],
              [-80.985386784310208, 35.230561678197837, 0.0],
              [-80.985108117779063, 35.230951411627991, 0.0],
              [-80.984856985259313, 35.231043211503895, 0.0],
              [-80.984242784521797, 35.230929211856676, 0.0],
              [-80.984074785120839, 35.230585812033809, 0.0],
              [-80.984045918638643, 35.229784278418165, 0.0],
              [-80.984129517836521, 35.229692612188181, 0.0],
              [-80.98412891860221, 35.229303211715752, 0.0],
              [-80.983933317531751, 35.229166011711641, 0.0],
              [-80.983570584799978, 35.229212079033914, 0.0],
              [-80.983403917996426, 35.229784812191276, 0.0],
              [-80.982901517606635, 35.229854012272526, 0.0],
              [-80.982705984820896, 35.229762611934596, 0.0],
              [-80.98278911851925, 35.229281478959102, 0.0],
              [-80.983597518331223, 35.228525012410685, 0.0],
              [-80.984183317700882, 35.228203811890808, 0.0],
              [-80.984182717917378, 35.227631212077853, 0.0],
              [-80.984544984391704, 35.2272644115803, 0.0],
              [-80.984879784736719, 35.227103811982971, 0.0],
              [-80.985354317565566, 35.22710341164985, 0.0],
              [-80.98557791814936, 35.22735507908407, 0.0],
              [-80.985828984589944, 35.227354878206071, 0.0],
              [-80.985856785031075, 35.227217412100295, 0.0],
              [-80.985689118233111, 35.227011478431656, 0.0],
              [-80.985270118368092, 35.226782812261618, 0.0],
              [-80.985241984903297, 35.226645411691159, 0.0],
              [-80.985381518672014, 35.226530678980936, 0.0],
              [-80.98621851852073, 35.226323879006792, 0.0],
              [-80.987863584857806, 35.225016878290155, 0.0],
              [-80.98956538523619, 35.224465479069877, 0.0],
              [-80.990653784441989, 35.224395678786465, 0.0],
              [-80.991546518404064, 35.224005478341049, 0.0],
              [-80.992131784992893, 35.223432411918623, 0.0],
              [-80.992047317583896, 35.222951478457809, 0.0],
              [-80.992382184708106, 35.222859611995034, 0.0],
              [-80.992519584227097, 35.221324879054052, 0.0],
              [-80.992770184164058, 35.220981078346576, 0.0],
              [-80.993160918286804, 35.220889211764629, 0.0],
              [-80.993299984860769, 35.22065987911445, 0.0],
              [-80.993662784461321, 35.220659612268669, 0.0],
              [-80.994081985218642, 35.221002678437287, 0.0],
              [-80.994361118399226, 35.221002478368916, 0.0],
              [-80.994556384775635, 35.220887812306614, 0.0],
              [-80.994973918459877, 35.220108611894084, 0.0],
              [-80.994722117529321, 35.219719478890113, 0.0],
              [-80.994721718639553, 35.219490478714484, 0.0],
              [-80.99511231849354, 35.21935261152219, 0.0],
              [-80.995418984123148, 35.219077478565744, 0.0],
              [-80.995418384711755, 35.218756878722075, 0.0],
              [-80.995306584730628, 35.218596679015718, 0.0],
              [-80.995250185025967, 35.218230279007479, 0.0],
              [-80.995444784712561, 35.217657412260266, 0.0],
              [-80.995220584622516, 35.21706221188861, 0.0],
              [-80.994913184604826, 35.216833478819609, 0.0],
              [-80.994801317922281, 35.216604611842605, 0.0],
              [-80.996447917577967, 35.216602878874092, 0.0],
              [-80.996921718559761, 35.216121478482243, 0.0],
              [-80.997674918066807, 35.215937412387802, 0.0],
              [-80.998344117765626, 35.215478611897019, 0.0],
              [-80.998371384615311, 35.215112212071688, 0.0],
              [-80.998622117635776, 35.214814078598963, 0.0],
              [-80.999430718483268, 35.214332412190501, 0.0],
              [-80.999569984281905, 35.214126078383394, 0.0],
              [-80.999597517760691, 35.213897011697043, 0.0],
              [-80.999345784549959, 35.213553678936925, 0.0],
              [-80.999260785186252, 35.212660611776187, 0.0],
              [-80.999148918363389, 35.212546211715065, 0.0],
              [-80.99892558466118, 35.212500611826414, 0.0],
              [-80.998256184854029, 35.212799012267929, 0.0],
              [-80.997726118222545, 35.212868278226964, 0.0],
              [-80.997697985167221, 35.212662279085855, 0.0],
              [-80.997809317592839, 35.212410211932472, 0.0],
              [-80.998980184225303, 35.211584411733369, 0.0],
              [-80.999796718468275, 35.21119421208482, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1636,
        ComID: 9755324,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.105,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.02173026695,
        Shape_Area: 1.035511e-5,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999796717848454, 35.208835212215185, 0.0],
              [-80.999796717858516, 35.209336012370542, 0.0],
              [-80.999796717906833, 35.210094878936111, 0.0],
              [-80.99903331746728, 35.209774879142678, 0.0],
              [-80.998279718314791, 35.20975287841393, 0.0],
              [-80.996967518646358, 35.209364878598166, 0.0],
              [-80.99657638450411, 35.20906747902864, 0.0],
              [-80.995738784791939, 35.208793478224337, 0.0],
              [-80.994817185209541, 35.208336278541267, 0.0],
              [-80.99356098438156, 35.207994078817357, 0.0],
              [-80.993169918436067, 35.207719612277948, 0.0],
              [-80.992751385106615, 35.207788678486658, 0.0],
              [-80.992109918543818, 35.208018278310462, 0.0],
              [-80.992081784481897, 35.207881012074708, 0.0],
              [-80.992555785217988, 35.207582678531892, 0.0],
              [-80.992639117918941, 35.207330678753763, 0.0],
              [-80.99235998503714, 35.207308078718775, 0.0],
              [-80.991913785252763, 35.207537479056903, 0.0],
              [-80.99149538416124, 35.207629611935261, 0.0],
              [-80.991495118449947, 35.20746927823398, 0.0],
              [-80.991913318181801, 35.207148211660822, 0.0],
              [-80.991913118561627, 35.206987811718285, 0.0],
              [-80.991745384982806, 35.20680487862564, 0.0],
              [-80.991745117526605, 35.206552878743729, 0.0],
              [-80.992051785022866, 35.206461012503951, 0.0],
              [-80.993000717568862, 35.20652867882783, 0.0],
              [-80.993531118076817, 35.206642678906114, 0.0],
              [-80.993894318572259, 35.206940212118951, 0.0],
              [-80.994173385252438, 35.206985678634709, 0.0],
              [-80.994842985291669, 35.206893412247524, 0.0],
              [-80.995596718592139, 35.207052878311174, 0.0],
              [-80.997076918224835, 35.207853212192653, 0.0],
              [-80.997244784186265, 35.208127812040232, 0.0],
              [-80.997831184147444, 35.208470812428274, 0.0],
              [-80.998445585137446, 35.208699211750755, 0.0],
              [-80.999796717848454, 35.208835212215185, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1638,
        ComID: 9755328,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.058,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.01402873678,
        Shape_Area: 5.72536e-6,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999797317644394, 35.172212278751971, 0.0],
              [-80.999797185220984, 35.178144411588775, 0.0],
              [-80.99890111795564, 35.177549811856331, 0.0],
              [-80.998733184427721, 35.177183478363979, 0.0],
              [-80.998732718494409, 35.176908678361855, 0.0],
              [-80.998425518377061, 35.176519611867455, 0.0],
              [-80.997811584495508, 35.176405678286116, 0.0],
              [-80.9976433841817, 35.175856211699703, 0.0],
              [-80.997754784129796, 35.175741612234518, 0.0],
              [-80.998730517648866, 35.175328279128401, 0.0],
              [-80.998897517880707, 35.175076211905676, 0.0],
              [-80.998784585160806, 35.174228812173212, 0.0],
              [-80.999062918572193, 35.173770478367771, 0.0],
              [-80.999312784555499, 35.172991478514945, 0.0],
              [-80.999797317644394, 35.172212278751971, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1639,
        ComID: 9755330,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.173,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.02780068188,
        Shape_Area: 1.711223e-5,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999797384578997, 35.164539478816572, 0.0],
              [-80.999797384715293, 35.16520127875053, 0.0],
              [-80.999797384219832, 35.165377678274275, 0.0],
              [-80.999797384594245, 35.165776278653304, 0.0],
              [-80.999797385125134, 35.16657787850545, 0.0],
              [-80.999637984334498, 35.166669678966144, 0.0],
              [-80.999527584839015, 35.167402812243374, 0.0],
              [-80.999054185069852, 35.168044612202955, 0.0],
              [-80.999027517522578, 35.168823278378241, 0.0],
              [-80.998943984927109, 35.168983678675119, 0.0],
              [-80.998693317650321, 35.169167212101733, 0.0],
              [-80.998526384439742, 35.169465211897403, 0.0],
              [-80.998275584833465, 35.169602812047145, 0.0],
              [-80.997578385038835, 35.169695278921729, 0.0],
              [-80.997439784179477, 35.170267878304841, 0.0],
              [-80.997161184619046, 35.170451479184756, 0.0],
              [-80.996185385089561, 35.170818879156734, 0.0],
              [-80.995934384951397, 35.170819211857825, 0.0],
              [-80.995487517984515, 35.170430279195983, 0.0],
              [-80.994427384813832, 35.170316812312585, 0.0],
              [-80.993534917896838, 35.170363478799565, 0.0],
              [-80.993255717570008, 35.170180478780502, 0.0],
              [-80.993338984576667, 35.169882679237674, 0.0],
              [-80.993255184277217, 35.169814012211994, 0.0],
              [-80.992529918568067, 35.169746012157987, 0.0],
              [-80.992027517839134, 35.169540411872489, 0.0],
              [-80.99138558488302, 35.169151611910756, 0.0],
              [-80.990883185167505, 35.168991878270347, 0.0],
              [-80.990883118024271, 35.168900278821809, 0.0],
              [-80.991746718204908, 35.168189278338303, 0.0],
              [-80.992304518620998, 35.168097212489229, 0.0],
              [-80.992889917851855, 35.167890478879791, 0.0],
              [-80.993364384479591, 35.168096212492074, 0.0],
              [-80.993615184217816, 35.167958478487314, 0.0],
              [-80.994368184181511, 35.167889011926029, 0.0],
              [-80.994591384482334, 35.167934611789207, 0.0],
              [-80.995624784743953, 35.168964278887003, 0.0],
              [-80.995680917519749, 35.169147411946618, 0.0],
              [-80.995848518204724, 35.169284678492936, 0.0],
              [-80.996350718277796, 35.169398678532815, 0.0],
              [-80.996685384483541, 35.169352612188703, 0.0],
              [-80.997019584814467, 35.16905447858263, 0.0],
              [-80.997883517557071, 35.168549678979659, 0.0],
              [-80.99810558483594, 35.167770811833506, 0.0],
              [-80.99801991836722, 35.166396678816774, 0.0],
              [-80.998270585041666, 35.166213211738281, 0.0],
              [-80.998827118342632, 35.165342079088646, 0.0],
              [-80.998965584449635, 35.164723612167954, 0.0],
              [-80.999797384578997, 35.164539478816572, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1641,
        ComID: 9755334,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.012,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.0049123288,
        Shape_Area: 1.14778e-6,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999797518001756, 35.154851278413986, 0.0],
              [-80.999797517637518, 35.155538478537082, 0.0],
              [-80.999258917789248, 35.155699279000878, 0.0],
              [-80.998701785056809, 35.156158011820949, 0.0],
              [-80.998283718193974, 35.156272879065014, 0.0],
              [-80.998171984476599, 35.156181478612844, 0.0],
              [-80.998115584447859, 35.15574627828272, 0.0],
              [-80.998366118058755, 35.155494078686502, 0.0],
              [-80.999797518001756, 35.154851278413986, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1642,
        ComID: 9755336,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.007,
        Elevation: 0.0,
        ReachCode: "03050101002513",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.00370109165,
        Shape_Area: 6.5924e-7,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999797718367816, 35.144659212144106, 0.0],
              [-80.999797718341682, 35.145277612281781, 0.0],
              [-80.999075518543989, 35.14504927910324, 0.0],
              [-80.998434117536121, 35.14498121225553, 0.0],
              [-80.998405918117172, 35.144752212320419, 0.0],
              [-80.998795918250707, 35.144545678798814, 0.0],
              [-80.999797718367816, 35.144659212144106, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1643,
        ComID: 9755338,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.084,
        Elevation: 0.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.02002829807,
        Shape_Area: 8.27563e-6,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999797718291006, 35.141475612597041, 0.0],
              [-80.999797717450861, 35.143582678516914, 0.0],
              [-80.999351784619378, 35.143308278758589, 0.0],
              [-80.998904584780377, 35.14264461223528, 0.0],
              [-80.998708317534962, 35.141843078948376, 0.0],
              [-80.998345317923807, 35.141545679016048, 0.0],
              [-80.998122185037815, 35.14147727896038, 0.0],
              [-80.997341384753113, 35.141478078565626, 0.0],
              [-80.996699784850819, 35.141226678919949, 0.0],
              [-80.996782718278567, 35.140677011901687, 0.0],
              [-80.996085118282849, 35.140357012217329, 0.0],
              [-80.995248384465114, 35.140197478801895, 0.0],
              [-80.994801984713035, 35.139991812094877, 0.0],
              [-80.994131984903646, 35.139511611875776, 0.0],
              [-80.994131784183324, 35.1393740785239, 0.0],
              [-80.994382718140614, 35.139282278329269, 0.0],
              [-80.994968317465293, 35.139373412357536, 0.0],
              [-80.995526384825638, 35.139601811630719, 0.0],
              [-80.995916784344004, 35.139693011795075, 0.0],
              [-80.997199318172235, 35.139622878631407, 0.0],
              [-80.997616785289537, 35.139141479094263, 0.0],
              [-80.997783384812607, 35.138591679150515, 0.0],
              [-80.99853531770853, 35.138041278505135, 0.0],
              [-80.998758317998707, 35.137995212562046, 0.0],
              [-80.998814517767741, 35.138270011777152, 0.0],
              [-80.998285918308838, 35.139026411822634, 0.0],
              [-80.998148118151562, 35.140102878939253, 0.0],
              [-80.998287718189133, 35.140309011964185, 0.0],
              [-80.999208785018027, 35.140926412401299, 0.0],
              [-80.999797718291006, 35.141475612597041, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 4130,
        ComID: 19488976,
        FDate: "1999/10/08",
        Resolution: 3,
        GNIS_ID: "00991183",
        GNIS_Name: "Lookout Shoals",
        AreaSqKm: 3.74,
        Elevation: 255.0,
        ReachCode: "03050101002517",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 0.33262379296,
        Shape_Area: 0.00037281646,
        Reservoir: 53,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.088894985180957, 35.75738727809221, 0.0],
              [-81.090327918495547, 35.757319011095902, 0.0],
              [-81.090549384234535, 35.757196411312144, 0.0],
              [-81.091817317434646, 35.756495010849029, 0.0],
              [-81.091957918440059, 35.756311810944737, 0.0],
              [-81.092493962378754, 35.756378430461602, 0.0],
              [-81.094542584280788, 35.756633011117174, 0.0],
              [-81.095413517964161, 35.756976811014724, 0.0],
              [-81.095609984125844, 35.757297411477694, 0.0],
              [-81.096621318373906, 35.757778610877452, 0.0],
              [-81.097576384363222, 35.758443077351508, 0.0],
              [-81.097576317582252, 35.758626277524002, 0.0],
              [-81.097098717397145, 35.75874061163718, 0.0],
              [-81.097098584775381, 35.758946811571597, 0.0],
              [-81.097519917756884, 35.759359078037036, 0.0],
              [-81.097884983994575, 35.759542411631998, 0.0],
              [-81.098474984662971, 35.759679877779824, 0.0],
              [-81.098362584130172, 35.759977677400769, 0.0],
              [-81.097463384516189, 35.759885811310362, 0.0],
              [-81.097042118284676, 35.759725410864114, 0.0],
              [-81.095749718391517, 35.759519011470729, 0.0],
              [-81.094794318178799, 35.75954167825374, 0.0],
              [-81.094316584068551, 35.759770477795492, 0.0],
              [-81.09397938452372, 35.760068211046374, 0.0],
              [-81.093641984844908, 35.76066347758627, 0.0],
              [-81.093585717647315, 35.761030011624406, 0.0],
              [-81.09313571818015, 35.762106210912584, 0.0],
              [-81.092629585091785, 35.762953478198114, 0.0],
              [-81.092179917600674, 35.763342677954633, 0.0],
              [-81.09130871798682, 35.763663010666122, 0.0],
              [-81.091224318378579, 35.763937810737062, 0.0],
              [-81.091308517449789, 35.764006610764987, 0.0],
              [-81.091645717589074, 35.763915077875254, 0.0],
              [-81.091982917678934, 35.763938078243477, 0.0],
              [-81.092038784570136, 35.764808411137537, 0.0],
              [-81.092909317346638, 35.766045411329799, 0.0],
              [-81.093330718000473, 35.766343211558365, 0.0],
              [-81.094285984093474, 35.766709877493504, 0.0],
              [-81.094707517698254, 35.766687077713677, 0.0],
              [-81.096562184530541, 35.76618361142863, 0.0],
              [-81.09706818404463, 35.765794411454195, 0.0],
              [-81.097461584582916, 35.765886010975173, 0.0],
              [-81.09810791797814, 35.76579461083108, 0.0],
              [-81.098922784399349, 35.765520010817681, 0.0],
              [-81.099119584637307, 35.765382611301455, 0.0],
              [-81.099035584989181, 35.76453527759665, 0.0],
              [-81.099400917634227, 35.764237611159892, 0.0],
              [-81.099653717725275, 35.764352211522905, 0.0],
              [-81.099541117735157, 35.765062077390219, 0.0],
              [-81.099962517872157, 35.765451477652263, 0.0],
              [-81.100355917682151, 35.765543210944678, 0.0],
              [-81.101648517599727, 35.76542901121168, 0.0],
              [-81.102266784212603, 35.765245877340639, 0.0],
              [-81.102913117614875, 35.765177211366129, 0.0],
              [-81.103587517825147, 35.764971277868185, 0.0],
              [-81.104065184193303, 35.764673478212679, 0.0],
              [-81.104430584562209, 35.764169810735716, 0.0],
              [-81.104683718080494, 35.763368277729356, 0.0],
              [-81.104908584389818, 35.763093477756357, 0.0],
              [-81.105330118455001, 35.762818677582558, 0.0],
              [-81.105611117481843, 35.762818810961129, 0.0],
              [-81.105723518418998, 35.762979210938461, 0.0],
              [-81.105751385036911, 35.763368478144237, 0.0],
              [-81.105948117688598, 35.763643277363876, 0.0],
              [-81.106060517776356, 35.763666210900105, 0.0],
              [-81.106762984516237, 35.763460211080144, 0.0],
              [-81.106987917622263, 35.763277010986577, 0.0],
              [-81.107015984665708, 35.762956411625218, 0.0],
              [-81.107156517975923, 35.762864811179334, 0.0],
              [-81.107409384352451, 35.762887811170977, 0.0],
              [-81.107606118487766, 35.763139678189873, 0.0],
              [-81.107943317320562, 35.76313967816256, 0.0],
              [-81.109291985095354, 35.762659011127646, 0.0],
              [-81.109657317561172, 35.762269678288078, 0.0],
              [-81.10991031797964, 35.76178881076504, 0.0],
              [-81.110078984459193, 35.761285011011054, 0.0],
              [-81.110500384285587, 35.761285010970191, 0.0],
              [-81.111006118426602, 35.761491077681981, 0.0],
              [-81.111568118428224, 35.761353811541838, 0.0],
              [-81.112298717532184, 35.761056077804447, 0.0],
              [-81.112523384516606, 35.761170677932341, 0.0],
              [-81.112523384064616, 35.761491277704806, 0.0],
              [-81.110977917335219, 35.762407211025497, 0.0],
              [-81.110584517719829, 35.762727811306021, 0.0],
              [-81.110612585124628, 35.762888211184524, 0.0],
              [-81.110781185037936, 35.762979810694723, 0.0],
              [-81.111399384743279, 35.763002677847425, 0.0],
              [-81.111792583954596, 35.763369210762775, 0.0],
              [-81.112242184091087, 35.76357541115776, 0.0],
              [-81.112242184716052, 35.763804477603934, 0.0],
              [-81.110668718080987, 35.76368981090085, 0.0],
              [-81.109657117933395, 35.763849877334884, 0.0],
              [-81.10906691766175, 35.764078878294846, 0.0],
              [-81.106565917445877, 35.765521277340234, 0.0],
              [-81.106509584404506, 35.765681677502833, 0.0],
              [-81.106818717808608, 35.765796211325359, 0.0],
              [-81.107745984641483, 35.765796277958586, 0.0],
              [-81.108701384309185, 35.765269678198777, 0.0],
              [-81.108841918302232, 35.765361411129675, 0.0],
              [-81.108926117427899, 35.765636078257366, 0.0],
              [-81.109600518272771, 35.766117077935682, 0.0],
              [-81.110668184267595, 35.766300477404975, 0.0],
              [-81.110668117925869, 35.766483810828284, 0.0],
              [-81.110190518105455, 35.766598211287778, 0.0],
              [-81.109122717678488, 35.766643877995307, 0.0],
              [-81.108251518268176, 35.766849877521729, 0.0],
              [-81.10782998501206, 35.767307878260091, 0.0],
              [-81.10819498450509, 35.768109478299628, 0.0],
              [-81.1081949840503, 35.768338478122494, 0.0],
              [-81.108279317274224, 35.768384277517626, 0.0],
              [-81.108307317554519, 35.768842277830622, 0.0],
              [-81.10858818509378, 35.769483677361251, 0.0],
              [-81.108419518064792, 35.769529410697444, 0.0],
              [-81.106536784852295, 35.769460410867481, 0.0],
              [-81.10642438407406, 35.769529077538017, 0.0],
              [-81.106480517825091, 35.770010077390033, 0.0],
              [-81.107098584868211, 35.770674411136149, 0.0],
              [-81.107969584644792, 35.771384477997699, 0.0],
              [-81.108615718223689, 35.772300611553604, 0.0],
              [-81.109823917858463, 35.773148077707305, 0.0],
              [-81.11069498452008, 35.773514611116248, 0.0],
              [-81.111959584773118, 35.773423210855803, 0.0],
              [-81.112999384365366, 35.773514877621594, 0.0],
              [-81.112999384223841, 35.773743878259616, 0.0],
              [-81.11162231788245, 35.77406441137579, 0.0],
              [-81.111228917473028, 35.774247477707355, 0.0],
              [-81.111060184829697, 35.774499410875563, 0.0],
              [-81.111060117277361, 35.775049010770438, 0.0],
              [-81.111397184167103, 35.775736077632565, 0.0],
              [-81.111902984901477, 35.776263011606744, 0.0],
              [-81.113504718170063, 35.777568477869998, 0.0],
              [-81.114235318009847, 35.778278611103872, 0.0],
              [-81.11465678470897, 35.778461811359534, 0.0],
              [-81.115078318433746, 35.778988610675668, 0.0],
              [-81.115106384857981, 35.779148877712657, 0.0],
              [-81.116174317977212, 35.779652811019474, 0.0],
              [-81.116286785107292, 35.780179477564417, 0.0],
              [-81.116427317479776, 35.780339878041111, 0.0],
              [-81.117129918235094, 35.780477277851787, 0.0],
              [-81.117157917354859, 35.781027011171965, 0.0],
              [-81.117747984499857, 35.781691077600755, 0.0],
              [-81.118141518380142, 35.781828611414404, 0.0],
              [-81.118338184386005, 35.782011810676053, 0.0],
              [-81.118506784810961, 35.782653010821797, 0.0],
              [-81.118731717584865, 35.78288207811466, 0.0],
              [-81.119349917540518, 35.783111077820763, 0.0],
              [-81.1205023183783, 35.782927877846483, 0.0],
              [-81.12123298412321, 35.783088277500788, 0.0],
              [-81.122047984790825, 35.783615010625695, 0.0],
              [-81.123087917443343, 35.783638010704998, 0.0],
              [-81.12334091729079, 35.783752477442832, 0.0],
              [-81.123481384679465, 35.784256211295506, 0.0],
              [-81.124802317480203, 35.785447277324884, 0.0],
              [-81.125111584777173, 35.785653277311987, 0.0],
              [-81.125561184825372, 35.785813611181176, 0.0],
              [-81.12646071818601, 35.785859410715972, 0.0],
              [-81.126432584256932, 35.786432011512787, 0.0],
              [-81.126235785058839, 35.787004477818392, 0.0],
              [-81.126263917659529, 35.787302277859553, 0.0],
              [-81.12660118449422, 35.787645677322764, 0.0],
              [-81.12682611756351, 35.787737411279331, 0.0],
              [-81.128259584138192, 35.78782901154473, 0.0],
              [-81.128849783969599, 35.788012077880438, 0.0],
              [-81.129889784732669, 35.788561677819828, 0.0],
              [-81.130620717874777, 35.789180077323465, 0.0],
              [-81.130704984655566, 35.78938621151886, 0.0],
              [-81.13062078505547, 35.790325077617936, 0.0],
              [-81.130030583999428, 35.791561810718186, 0.0],
              [-81.129777584194656, 35.792523677994154, 0.0],
              [-81.129805917573719, 35.793623011380156, 0.0],
              [-81.129862184546226, 35.794264211436634, 0.0],
              [-81.129974584943241, 35.794584811273815, 0.0],
              [-81.130002784281999, 35.795317678066041, 0.0],
              [-81.13011518416036, 35.795432211307073, 0.0],
              [-81.130424517841689, 35.795409277932244, 0.0],
              [-81.130564985089734, 35.794813811145943, 0.0],
              [-81.130874184941817, 35.794905478043184, 0.0],
              [-81.131014784455317, 35.795248877288408, 0.0],
              [-81.131127184127919, 35.795867278219177, 0.0],
              [-81.131183717435704, 35.798775810842699, 0.0],
              [-81.130958784975178, 35.79884461061669, 0.0],
              [-81.130705785091209, 35.798524011149219, 0.0],
              [-81.130677517948925, 35.796371211014119, 0.0],
              [-81.130452584877489, 35.796371210735884, 0.0],
              [-81.130311984609421, 35.796554411526287, 0.0],
              [-81.130143518087948, 35.797241478223008, 0.0],
              [-81.130171583916606, 35.798409477677055, 0.0],
              [-81.129918783950558, 35.799233877930561, 0.0],
              [-81.12994691808548, 35.7998292775791, 0.0],
              [-81.130424784645214, 35.800882877993388, 0.0],
              [-81.13087471760754, 35.801959211579558, 0.0],
              [-81.132083584215792, 35.803081277449522, 0.0],
              [-81.132308584006267, 35.803379011217352, 0.0],
              [-81.132955317924228, 35.803837011062697, 0.0],
              [-81.134698384374531, 35.805577410933097, 0.0],
              [-81.135345118439801, 35.806058211302854, 0.0],
              [-81.136329184439091, 35.806493277876669, 0.0],
              [-81.135974983912575, 35.806932078135162, 0.0],
              [-81.135626517311124, 35.807363677581662, 0.0],
              [-81.133855317569498, 35.806653878054924, 0.0],
              [-81.132899184030563, 35.806058477660187, 0.0],
              [-81.131999517425925, 35.805783811014685, 0.0],
              [-81.130734384204331, 35.805486078032928, 0.0],
              [-81.130650118367299, 35.805096811265578, 0.0],
              [-81.130453317619171, 35.804753211142589, 0.0],
              [-81.130200184323328, 35.804524211581843, 0.0],
              [-81.129412985013715, 35.804112010950405, 0.0],
              [-81.128878917541954, 35.804089078195013, 0.0],
              [-81.12865398421016, 35.804157878248539, 0.0],
              [-81.128288384445497, 35.803837210752448, 0.0],
              [-81.127698117983769, 35.802967011275499, 0.0],
              [-81.127557384598958, 35.801524210812879, 0.0],
              [-81.128091518312928, 35.800058478136691, 0.0],
              [-81.12811971732377, 35.79964627783076, 0.0],
              [-81.128597517871469, 35.798776011118655, 0.0],
              [-81.128597384327392, 35.797562211520855, 0.0],
              [-81.128203917974531, 35.795913277742841, 0.0],
              [-81.128119517742263, 35.7958674782288, 0.0],
              [-81.127248118080402, 35.795821677424527, 0.0],
              [-81.126854584528488, 35.795546877580996, 0.0],
              [-81.126657784623035, 35.795272078063313, 0.0],
              [-81.126095584345094, 35.793646011473761, 0.0],
              [-81.126039117957717, 35.792042878190173, 0.0],
              [-81.125898584962201, 35.791905477435932, 0.0],
              [-81.125476984725594, 35.790875010632234, 0.0],
              [-81.125167783919807, 35.790485610926531, 0.0],
              [-81.12494291770507, 35.789867277644866, 0.0],
              [-81.124802384676684, 35.789798610852557, 0.0],
              [-81.124549384604308, 35.789455010846055, 0.0],
              [-81.124043518155162, 35.789157277961756, 0.0],
              [-81.122469518053038, 35.787943478110748, 0.0],
              [-81.12232898430527, 35.787622810907337, 0.0],
              [-81.122469518307653, 35.787141877754912, 0.0],
              [-81.123115984023926, 35.786638078158006, 0.0],
              [-81.123115918064713, 35.786363277329301, 0.0],
              [-81.122947384541263, 35.786271677555, 0.0],
              [-81.122469584285184, 35.786271677921064, 0.0],
              [-81.121991718273776, 35.786592277329682, 0.0],
              [-81.1217107172602, 35.786683877661659, 0.0],
              [-81.12165438438214, 35.786431877414145, 0.0],
              [-81.122610117740223, 35.7854242775065, 0.0],
              [-81.122497718174259, 35.785264011538416, 0.0],
              [-81.122244717919315, 35.785241077625784, 0.0],
              [-81.121710718095642, 35.785561678254233, 0.0],
              [-81.121457784806253, 35.785928077593411, 0.0],
              [-81.121120384308369, 35.786431877435597, 0.0],
              [-81.120754984953734, 35.786592211193557, 0.0],
              [-81.119939984722166, 35.786042611086188, 0.0],
              [-81.119152984340573, 35.785676211105219, 0.0],
              [-81.118871917486487, 35.785653210837879, 0.0],
              [-81.118506518075748, 35.786179878052145, 0.0],
              [-81.118141117681887, 35.786523410978674, 0.0],
              [-81.117719517599895, 35.786615011574803, 0.0],
              [-81.117578984363362, 35.78647747805757, 0.0],
              [-81.118000584143857, 35.785607277501946, 0.0],
              [-81.11802871751577, 35.785309677688801, 0.0],
              [-81.114740518300039, 35.784049878014791, 0.0],
              [-81.114094185075984, 35.783294011318695, 0.0],
              [-81.113222984279673, 35.782515277830818, 0.0],
              [-81.111817917718398, 35.781507477921828, 0.0],
              [-81.111564984205444, 35.781393011092497, 0.0],
              [-81.111368184514674, 35.781507477490194, 0.0],
              [-81.111340117924215, 35.782102877793228, 0.0],
              [-81.110496717911076, 35.782675277512347, 0.0],
              [-81.110271917639352, 35.782950077386701, 0.0],
              [-81.109962717762315, 35.783018811143293, 0.0],
              [-81.109906584126534, 35.782583611226222, 0.0],
              [-81.110075317862794, 35.782286011127852, 0.0],
              [-81.110525117719234, 35.781805078099744, 0.0],
              [-81.110553184435673, 35.781049278107247, 0.0],
              [-81.110412784952445, 35.780705810676665, 0.0],
              [-81.10982278463581, 35.779950011228856, 0.0],
              [-81.109035984060071, 35.779171211090166, 0.0],
              [-81.108136784806291, 35.777911477964011, 0.0],
              [-81.108164918116671, 35.777797011466831, 0.0],
              [-81.107855917319796, 35.777155610715077, 0.0],
              [-81.106591517396168, 35.776102011584911, 0.0],
              [-81.106226184859153, 35.775987477839266, 0.0],
              [-81.106057517914493, 35.776124878240481, 0.0],
              [-81.10600131799211, 35.776308011290503, 0.0],
              [-81.104820518320921, 35.778140011608095, 0.0],
              [-81.104679918006639, 35.778598077826452, 0.0],
              [-81.10453931826062, 35.778712477778264, 0.0],
              [-81.104342584945726, 35.778712477687961, 0.0],
              [-81.104230317291297, 35.778552210784333, 0.0],
              [-81.104230384107225, 35.778048411128736, 0.0],
              [-81.105354984331271, 35.776124810690504, 0.0],
              [-81.10538318417909, 35.775643810908861, 0.0],
              [-81.105017984086416, 35.775369011380604, 0.0],
              [-81.104146718170625, 35.77532301126729, 0.0],
              [-81.103219317832938, 35.775643410721877, 0.0],
              [-81.1027695845724, 35.775941077793824, 0.0],
              [-81.102403985076194, 35.776444877853976, 0.0],
              [-81.102235317549784, 35.777132011233114, 0.0],
              [-81.102010317867837, 35.777521211181494, 0.0],
              [-81.101672984327237, 35.77761267817823, 0.0],
              [-81.101560718327519, 35.777269210784482, 0.0],
              [-81.101645183995899, 35.776719477331191, 0.0],
              [-81.102235917989688, 35.775002077873097, 0.0],
              [-81.102404517681109, 35.774773011314579, 0.0],
              [-81.102657384314881, 35.774750077830952, 0.0],
              [-81.102741784236329, 35.774864678259704, 0.0],
              [-81.10299458439151, 35.774887611148827, 0.0],
              [-81.103837917840323, 35.774361078191419, 0.0],
              [-81.10389418421137, 35.77378861081641, 0.0],
              [-81.103753784073092, 35.773422077825074, 0.0],
              [-81.103416717806169, 35.772941010954774, 0.0],
              [-81.102601985024904, 35.77209347775073, 0.0],
              [-81.102461517565345, 35.771772877438764, 0.0],
              [-81.102433584511729, 35.771314877584715, 0.0],
              [-81.10254618400441, 35.770765211081724, 0.0],
              [-81.102321518476174, 35.770490277329898, 0.0],
              [-81.101281783944245, 35.770169477334385, 0.0],
              [-81.100326385041626, 35.769986078014199, 0.0],
              [-81.099483384702978, 35.769986011092598, 0.0],
              [-81.098302984701363, 35.770397878293942, 0.0],
              [-81.098190517492995, 35.770581077391988, 0.0],
              [-81.098274718055762, 35.770856011347085, 0.0],
              [-81.098639918225956, 35.771291211043312, 0.0],
              [-81.098920784730254, 35.771818011275847, 0.0],
              [-81.098948718182228, 35.772138678181165, 0.0],
              [-81.098639717433414, 35.772161478131153, 0.0],
              [-81.098442983975744, 35.771978277441733, 0.0],
              [-81.097993584983243, 35.771245277781055, 0.0],
              [-81.097572185150185, 35.771016077693673, 0.0],
              [-81.097094384783759, 35.770993211420006, 0.0],
              [-81.096869517700156, 35.771130610817401, 0.0],
              [-81.09664451775204, 35.77170301151547, 0.0],
              [-81.096615584509195, 35.774290877415901, 0.0],
              [-81.09650298439351, 35.774680211267544, 0.0],
              [-81.09619391742558, 35.774771677951485, 0.0],
              [-81.09608151812526, 35.774657210652904, 0.0],
              [-81.095941184337789, 35.773970077808407, 0.0],
              [-81.095856917424314, 35.77390141138158, 0.0],
              [-81.095463518288284, 35.773970010671313, 0.0],
              [-81.095351117636213, 35.773901211253978, 0.0],
              [-81.095294984617823, 35.773626477571149, 0.0],
              [-81.095829184296221, 35.773031077397242, 0.0],
              [-81.095941717598166, 35.772550210689921, 0.0],
              [-81.095970184126813, 35.771657010776671, 0.0],
              [-81.09605458483523, 35.771473811486324, 0.0],
              [-81.096054784796806, 35.770901277979888, 0.0],
              [-81.095886184351556, 35.770809678065774, 0.0],
              [-81.095689517307804, 35.770809610897487, 0.0],
              [-81.095380185152436, 35.771176011425126, 0.0],
              [-81.093806317757, 35.771633611440969, 0.0],
              [-81.093778317328912, 35.771427477794958, 0.0],
              [-81.094846518374595, 35.7706490782411, 0.0],
              [-81.094818517888939, 35.770282677988035, 0.0],
              [-81.09287951760335, 35.770282210909507, 0.0],
              [-81.092176917752454, 35.770534011543134, 0.0],
              [-81.091558718218437, 35.770487877722026, 0.0],
              [-81.090996517732151, 35.770854211526185, 0.0],
              [-81.090687184025114, 35.771518211306635, 0.0],
              [-81.090293717994186, 35.771586878106717, 0.0],
              [-81.090040784863589, 35.771495210897619, 0.0],
              [-81.089759918251758, 35.771266077621078, 0.0],
              [-81.089535185127602, 35.770922477858207, 0.0],
              [-81.089563518249093, 35.770601810915544, 0.0],
              [-81.089760184064303, 35.770418811338494, 0.0],
              [-81.090968984138996, 35.769777877699795, 0.0],
              [-81.091053317444462, 35.769617611582625, 0.0],
              [-81.090800517661719, 35.769411410709807, 0.0],
              [-81.090126118414318, 35.769342478224509, 0.0],
              [-81.089873184019424, 35.769250810746023, 0.0],
              [-81.089732784499304, 35.769067478132911, 0.0],
              [-81.089705118298113, 35.768197211455117, 0.0],
              [-81.089564584807505, 35.768082611185129, 0.0],
              [-81.088581185049307, 35.767853411403493, 0.0],
              [-81.088328184258913, 35.768013611078842, 0.0],
              [-81.087343784401313, 35.76986841078557, 0.0],
              [-81.086725517583673, 35.770120078238371, 0.0],
              [-81.086303784990847, 35.770440610806304, 0.0],
              [-81.086190917745569, 35.771585677961944, 0.0],
              [-81.085403318005703, 35.772959611496844, 0.0],
              [-81.085459384781274, 35.773348811031944, 0.0],
              [-81.085768184995871, 35.773829877960985, 0.0],
              [-81.086414317963417, 35.774494210676195, 0.0],
              [-81.086498517666925, 35.774860678111374, 0.0],
              [-81.08596438512869, 35.774883410671023, 0.0],
              [-81.085234118091975, 35.774104610971683, 0.0],
              [-81.084925117857523, 35.774035677947346, 0.0],
              [-81.084728518302185, 35.77382961078505, 0.0],
              [-81.084728584892488, 35.773623477661843, 0.0],
              [-81.084391718129169, 35.772890477313425, 0.0],
              [-81.084026583984567, 35.772569811308941, 0.0],
              [-81.083296117587935, 35.772386210656975, 0.0],
              [-81.083155584653824, 35.772203011228662, 0.0],
              [-81.083240118074869, 35.771836677655294, 0.0],
              [-81.08383051767089, 35.771218411221454, 0.0],
              [-81.084223984324993, 35.771172811214335, 0.0],
              [-81.08467338491991, 35.771402010710737, 0.0],
              [-81.085123118020832, 35.771402078141911, 0.0],
              [-81.085319918223107, 35.771196078263472, 0.0],
              [-81.085376384074735, 35.770829610744052, 0.0],
              [-81.084758317999515, 35.770371411582225, 0.0],
              [-81.084758518183037, 35.770096477711803, 0.0],
              [-81.085685918379454, 35.769707477371632, 0.0],
              [-81.086444985035484, 35.769204010753029, 0.0],
              [-81.08681038510052, 35.768837677981701, 0.0],
              [-81.087485385168847, 35.767715611540559, 0.0],
              [-81.087485583975933, 35.76734907774717, 0.0],
              [-81.087232784651832, 35.767143010683462, 0.0],
              [-81.086614584402454, 35.76698241101289, 0.0],
              [-81.086530384655831, 35.766867878116017, 0.0],
              [-81.086530517863551, 35.766570210849373, 0.0],
              [-81.086727384594852, 35.766135211017271, 0.0],
              [-81.086699584946814, 35.765402277983867, 0.0],
              [-81.086559317366564, 35.765310678175588, 0.0],
              [-81.085884784597724, 35.76535621071001, 0.0],
              [-81.085800517896985, 35.765264610896722, 0.0],
              [-81.085829384862208, 35.763821811553058, 0.0],
              [-81.085492517742068, 35.763157477648619, 0.0],
              [-81.084930717910652, 35.762722278199618, 0.0],
              [-81.084621718138706, 35.762630477544882, 0.0],
              [-81.084284518274345, 35.762630478181194, 0.0],
              [-81.083919184984225, 35.762790611379117, 0.0],
              [-81.083385118443189, 35.763202611493021, 0.0],
              [-81.083019718408565, 35.763362811111691, 0.0],
              [-81.081923784693373, 35.763476878092305, 0.0],
              [-81.081361784698686, 35.763660011232389, 0.0],
              [-81.080940118485955, 35.763980411508598, 0.0],
              [-81.079983984413516, 35.765262677690664, 0.0],
              [-81.079477984241919, 35.765697611059771, 0.0],
              [-81.079028317338697, 35.765834810836132, 0.0],
              [-81.078915917422108, 35.76576607739991, 0.0],
              [-81.078944384241268, 35.765216478300808, 0.0],
              [-81.079563117740236, 35.76430047816492, 0.0],
              [-81.079591517331522, 35.763796677495868, 0.0],
              [-81.079703985082006, 35.763590677738016, 0.0],
              [-81.081221917995634, 35.762537677600413, 0.0],
              [-81.082767584242845, 35.761942877903778, 0.0],
              [-81.083779518374612, 35.761279011400468, 0.0],
              [-81.08459438514501, 35.761027277561162, 0.0],
              [-81.084875518172936, 35.760752610939207, 0.0],
              [-81.086055917897724, 35.760249211428714, 0.0],
              [-81.086617984200174, 35.759882877899919, 0.0],
              [-81.08706771775249, 35.759448010824244, 0.0],
              [-81.087180317578557, 35.759012877397609, 0.0],
              [-81.088107718409859, 35.758555010919885, 0.0],
              [-81.088850645273311, 35.75745304741703, 0.0],
              [-81.088894985180957, 35.75738727809221, 0.0],
            ],
            [
              [-81.127219918049803, 35.795226277880943, 0.0],
              [-81.127641583956247, 35.79534067786539, 0.0],
              [-81.127978918276369, 35.795157411500533, 0.0],
              [-81.127978918056186, 35.794676477549039, 0.0],
              [-81.127332184960451, 35.790943611055134, 0.0],
              [-81.127107317484857, 35.790439677513888, 0.0],
              [-81.127079118307336, 35.789981677370591, 0.0],
              [-81.126966717804493, 35.789684011108385, 0.0],
              [-81.126573184538216, 35.789798478244187, 0.0],
              [-81.12637651808204, 35.789615277298061, 0.0],
              [-81.125533117758138, 35.787920611064663, 0.0],
              [-81.124802384959395, 35.787027477776029, 0.0],
              [-81.12421218416614, 35.786500678145003, 0.0],
              [-81.123846784308, 35.78647781105856, 0.0],
              [-81.1238749178398, 35.786752610968605, 0.0],
              [-81.124212184406389, 35.787187810946598, 0.0],
              [-81.124802384260718, 35.788653477427751, 0.0],
              [-81.124914784821954, 35.788928277288086, 0.0],
              [-81.125083518314597, 35.78904281127042, 0.0],
              [-81.125252118069596, 35.789340477552322, 0.0],
              [-81.125758118369603, 35.789798477843924, 0.0],
              [-81.125870517783895, 35.790737477590966, 0.0],
              [-81.126292184944973, 35.7918596777236, 0.0],
              [-81.126657517553014, 35.792386410694448, 0.0],
              [-81.126770118243329, 35.792936010780856, 0.0],
              [-81.12654511741826, 35.793188010927572, 0.0],
              [-81.126517183928613, 35.793485678009731, 0.0],
              [-81.126910717895356, 35.794791078030102, 0.0],
              [-81.127219918049803, 35.795226277880943, 0.0],
            ],
            [
              [-81.129131784523153, 35.803699810819353, 0.0],
              [-81.129412917585341, 35.80376847738458, 0.0],
              [-81.129694118161126, 35.803631011190532, 0.0],
              [-81.129694117399595, 35.803150077441693, 0.0],
              [-81.129525384426046, 35.80262341109421, 0.0],
              [-81.128878717447591, 35.801867677651643, 0.0],
              [-81.128597517899678, 35.801363877804363, 0.0],
              [-81.128260184875415, 35.801226477370797, 0.0],
              [-81.128147785066375, 35.801409610745594, 0.0],
              [-81.128147784956255, 35.802005077304372, 0.0],
              [-81.128400784288687, 35.802921078086129, 0.0],
              [-81.128569584018081, 35.803195878238824, 0.0],
              [-81.129131784523153, 35.803699810819353, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 4131,
        ComID: 120053864,
        FDate: "2004/03/16",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.232,
        Elevation: 0.0,
        ReachCode: "03050101002510",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.04412564646,
        Shape_Area: 2.306665e-5,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.996349717852226, 35.688899611516128, 0.0],
              [-80.996068317950645, 35.688441810808385, 0.0],
              [-80.995787318021314, 35.68825881097257, 0.0],
              [-80.995478518495418, 35.688236277458302, 0.0],
              [-80.994805118091378, 35.688420078213618, 0.0],
              [-80.993682185264646, 35.688467011399112, 0.0],
              [-80.993344985036799, 35.688238411569181, 0.0],
              [-80.992474384979403, 35.687987411416181, 0.0],
              [-80.991940517648615, 35.687621477847813, 0.0],
              [-80.991463317897768, 35.687599077454237, 0.0],
              [-80.9907905851843, 35.688378411626587, 0.0],
              [-80.990426117664555, 35.688676411044248, 0.0],
              [-80.99006131859997, 35.688768411552296, 0.0],
              [-80.989836518199965, 35.688585278160339, 0.0],
              [-80.989527117567917, 35.688196278432848, 0.0],
              [-80.989442584355217, 35.687944478166287, 0.0],
              [-80.989778918243459, 35.687600610929358, 0.0],
              [-80.989862784935312, 35.687371477651254, 0.0],
              [-80.989750318420619, 35.687142611096561, 0.0],
              [-80.989244184165514, 35.686730878058732, 0.0],
              [-80.989242918519096, 35.685837678415879, 0.0],
              [-80.989438584556979, 35.68526501097994, 0.0],
              [-80.990308318021278, 35.684920610789455, 0.0],
              [-80.990336584568553, 35.685058011193796, 0.0],
              [-80.990561518407659, 35.685286678108746, 0.0],
              [-80.99073078468858, 35.68592781137302, 0.0],
              [-80.991124517828084, 35.686316811443078, 0.0],
              [-80.991601917926559, 35.686362078204539, 0.0],
              [-80.991854984950663, 35.686751210798633, 0.0],
              [-80.993006717861874, 35.687231010979737, 0.0],
              [-80.993680918642099, 35.687619677609341, 0.0],
              [-80.994410984888646, 35.687756411698189, 0.0],
              [-80.995421585113334, 35.687709610998667, 0.0],
              [-80.9959265848142, 35.687525877741884, 0.0],
              [-80.996262984596754, 35.687250611132896, 0.0],
              [-80.996768118459499, 35.687112678055563, 0.0],
              [-80.997470718624896, 35.687592878327742, 0.0],
              [-80.997919984144147, 35.687707011119826, 0.0],
              [-80.998425784281963, 35.688027077636541, 0.0],
              [-80.998762717878719, 35.688095411205474, 0.0],
              [-80.999127318299045, 35.687843211732172, 0.0],
              [-80.999799384148787, 35.68770501146102, 0.0],
              [-80.999966518047174, 35.687682277828181, 0.0],
              [-81.000163918159359, 35.687109877552132, 0.0],
              [-81.000388717732648, 35.686972677786393, 0.0],
              [-81.000725384706413, 35.686996011377573, 0.0],
              [-81.001370717646068, 35.6872714116707, 0.0],
              [-81.00181971747854, 35.687363478075554, 0.0],
              [-81.001931318187928, 35.687798810818933, 0.0],
              [-81.002211784957083, 35.687913611495347, 0.0],
              [-81.003025985209391, 35.68789147751059, 0.0],
              [-81.004316718395913, 35.688327877554379, 0.0],
              [-81.005101717581852, 35.689015811602069, 0.0],
              [-81.005535984714001, 35.688959478421438, 0.0],
              [-81.005803584568085, 35.688924811233996, 0.0],
              [-81.005803384297153, 35.689085078428967, 0.0],
              [-81.005437984967301, 35.689382611085819, 0.0],
              [-81.004848317622447, 35.689496478007911, 0.0],
              [-81.004259584556706, 35.68894627754797, 0.0],
              [-81.003025185083388, 35.688441211274046, 0.0],
              [-81.0027165183253, 35.688395077516304, 0.0],
              [-81.001312117613878, 35.688897477460415, 0.0],
              [-81.000806585116536, 35.68903441164543, 0.0],
              [-81.000385584691486, 35.688919478323967, 0.0],
              [-81.000021118135408, 35.688644278009313, 0.0],
              [-80.999799384132416, 35.688644011564868, 0.0],
              [-80.99946538522633, 35.688667278106244, 0.0],
              [-80.998596517907302, 35.689492610829234, 0.0],
              [-80.998485517948694, 35.69034021132034, 0.0],
              [-80.99834538517554, 35.690523611460996, 0.0],
              [-80.997727984360338, 35.690638677536533, 0.0],
              [-80.997026584627392, 35.69093707797095, 0.0],
              [-80.996829984781229, 35.690845677532415, 0.0],
              [-80.996801184719374, 35.69045641104691, 0.0],
              [-80.996912584128992, 35.689769211511624, 0.0],
              [-80.996406318260227, 35.689174278214807, 0.0],
              [-80.996349717852226, 35.688899611516128, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1439,
        ComID: 9737130,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wateree",
        AreaSqKm: 45.348,
        Elevation: 0.0,
        ReachCode: "03050104002313",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 2.0411735826699999,
        Shape_Area: 0.00444632543,
        Reservoir: 57,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.892977784950517, 34.499983679917165, 0.0],
              [-80.892396585267093, 34.499663212985404, 0.0],
              [-80.890875117730488, 34.4997778802773, 0.0],
              [-80.890847317997881, 34.499503013107706, 0.0],
              [-80.891483585131681, 34.499044813396367, 0.0],
              [-80.891594317912194, 34.498678280224851, 0.0],
              [-80.892258185351665, 34.498128412669772, 0.0],
              [-80.894305384282063, 34.497097279835351, 0.0],
              [-80.894554518687087, 34.496891013518329, 0.0],
              [-80.894554384847154, 34.496616213146524, 0.0],
              [-80.894305385144833, 34.496524613241256, 0.0],
              [-80.893475384367406, 34.496547679938487, 0.0],
              [-80.89217511770795, 34.496754013575824, 0.0],
              [-80.891123984959066, 34.497120679664604, 0.0],
              [-80.890432318533328, 34.497189479977102, 0.0],
              [-80.88949191804717, 34.496823079778956, 0.0],
              [-80.889132117852583, 34.496800213507299, 0.0],
              [-80.888717185150796, 34.496891879740403, 0.0],
              [-80.888246917870902, 34.497166812931525, 0.0],
              [-80.887970317812858, 34.497441879963311, 0.0],
              [-80.885813184456353, 34.498701879556201, 0.0],
              [-80.884845717651103, 34.499480880185729, 0.0],
              [-80.884458518565225, 34.499618279761989, 0.0],
              [-80.88445838432628, 34.499274679949252, 0.0],
              [-80.884568917861856, 34.499091480258357, 0.0],
              [-80.886006384911369, 34.497854413001534, 0.0],
              [-80.886089385407374, 34.497671079531628, 0.0],
              [-80.885895718280963, 34.497533679363826, 0.0],
              [-80.88548078462513, 34.497694079953604, 0.0],
              [-80.884900185258203, 34.498175213378197, 0.0],
              [-80.883932718541601, 34.499183079765984, 0.0],
              [-80.883296384601323, 34.500149479857484, 0.0],
              [-80.882133384491468, 34.500149013417271, 0.0],
              [-80.879201518027301, 34.500147613463049, 0.0],
              [-80.879173718791378, 34.499985212998631, 0.0],
              [-80.879007785258011, 34.499802012666713, 0.0],
              [-80.878980118097601, 34.499641613307261, 0.0],
              [-80.879090785418143, 34.499481279985304, 0.0],
              [-80.880141918200763, 34.499252079595294, 0.0],
              [-80.880805984781361, 34.498931413069521, 0.0],
              [-80.881469918451529, 34.49824407962231, 0.0],
              [-80.881663518607198, 34.497854679382542, 0.0],
              [-80.881718717785532, 34.497488213506372, 0.0],
              [-80.88119278483984, 34.496892680261013, 0.0],
              [-80.881026784437694, 34.496526212920166, 0.0],
              [-80.880999184634248, 34.496274213027988, 0.0],
              [-80.881137517997189, 34.495976479414594, 0.0],
              [-80.881469518342684, 34.495884812797307, 0.0],
              [-80.881995184354182, 34.496136680116081, 0.0],
              [-80.882825185459566, 34.4968696794827, 0.0],
              [-80.883378585208959, 34.497007013182611, 0.0],
              [-80.883738318418878, 34.496846680034423, 0.0],
              [-80.884014917835458, 34.496548813026763, 0.0],
              [-80.885176784883285, 34.494555879691056, 0.0],
              [-80.885813117969974, 34.494120612773955, 0.0],
              [-80.886559984597426, 34.493753880168143, 0.0],
              [-80.887721918333241, 34.493639279465064, 0.0],
              [-80.88830291816511, 34.493730880053576, 0.0],
              [-80.889215985058442, 34.494120279919585, 0.0],
              [-80.890045917985319, 34.494142880135385, 0.0],
              [-80.890626917976988, 34.494005479704931, 0.0],
              [-80.891290785314823, 34.493570079552121, 0.0],
              [-80.891760984711908, 34.492997413282303, 0.0],
              [-80.892286584348213, 34.492539080018183, 0.0],
              [-80.893254918508447, 34.492149612731403, 0.0],
              [-80.893863385103955, 34.49146228018887, 0.0],
              [-80.894665517772935, 34.490889480227459, 0.0],
              [-80.894693118124337, 34.490362613352978, 0.0],
              [-80.895439784987389, 34.489079680031573, 0.0],
              [-80.896103584931879, 34.488346613362175, 0.0],
              [-80.896131184920108, 34.487957212919888, 0.0],
              [-80.895882118222019, 34.487613613539907, 0.0],
              [-80.89557778531713, 34.487430479585008, 0.0],
              [-80.895301117839523, 34.4874762793212, 0.0],
              [-80.895135184482214, 34.487774079659765, 0.0],
              [-80.895052318764513, 34.488346812651258, 0.0],
              [-80.894886517860613, 34.488736279732095, 0.0],
              [-80.894692718121163, 34.488873679651476, 0.0],
              [-80.894360784636802, 34.488896613050962, 0.0],
              [-80.89413938492666, 34.488530212882026, 0.0],
              [-80.894166918662336, 34.48752228020102, 0.0],
              [-80.894526318402711, 34.486468613463757, 0.0],
              [-80.894332584852989, 34.486285412920616, 0.0],
              [-80.893779317872969, 34.486010613526688, 0.0],
              [-80.893281318501764, 34.485438013363719, 0.0],
              [-80.893225918478535, 34.485140213388767, 0.0],
              [-80.893225584551146, 34.483949080212319, 0.0],
              [-80.89305951766255, 34.483238879326017, 0.0],
              [-80.892008184436321, 34.482460279483192, 0.0],
              [-80.891869785098436, 34.482070813471644, 0.0],
              [-80.891427118264517, 34.481658613184564, 0.0],
              [-80.891205784535984, 34.481727413178071, 0.0],
              [-80.890929317784412, 34.482094013609128, 0.0],
              [-80.890846318367736, 34.48239181292, 0.0],
              [-80.89065278500162, 34.482643680242091, 0.0],
              [-80.890320785439599, 34.482712479364018, 0.0],
              [-80.89018251784114, 34.482620879934984, 0.0],
              [-80.890127117802905, 34.482094080259792, 0.0],
              [-80.8900163848255, 34.482002413564679, 0.0],
              [-80.889297317619409, 34.482139879713507, 0.0],
              [-80.889214185129021, 34.482048279537246, 0.0],
              [-80.889324918718913, 34.481773479890592, 0.0],
              [-80.889905718577836, 34.481269479622568, 0.0],
              [-80.890624918093636, 34.480834079884744, 0.0],
              [-80.890763118651904, 34.480559212679374, 0.0],
              [-80.890818384400887, 34.480123880063516, 0.0],
              [-80.890237318699576, 34.479184880123341, 0.0],
              [-80.890015985461162, 34.479093212975179, 0.0],
              [-80.889656517669522, 34.479368079490534, 0.0],
              [-80.889518184808097, 34.479849280047752, 0.0],
              [-80.889213984618308, 34.480009680083278, 0.0],
              [-80.888854317943199, 34.480032613524756, 0.0],
              [-80.888522385032957, 34.479528680266554, 0.0],
              [-80.888522318560788, 34.479162213389024, 0.0],
              [-80.888660584611401, 34.479001879940135, 0.0],
              [-80.888964918608522, 34.478910212940313, 0.0],
              [-80.889186117693498, 34.478704012765157, 0.0],
              [-80.889241384327121, 34.478131279603758, 0.0],
              [-80.889739185024766, 34.477535613504394, 0.0],
              [-80.88979438498589, 34.477169212689709, 0.0],
              [-80.888909185370096, 34.476550813279658, 0.0],
              [-80.888687785077735, 34.475817813067209, 0.0],
              [-80.888438785249605, 34.47549707951368, 0.0],
              [-80.888217518022515, 34.475474213010656, 0.0],
              [-80.887553718440429, 34.475795013061635, 0.0],
              [-80.88708358514387, 34.476184413044372, 0.0],
              [-80.886668717903717, 34.47673441360795, 0.0],
              [-80.886170918024362, 34.477032213254525, 0.0],
              [-80.885645318764318, 34.477055213268542, 0.0],
              [-80.885651184696812, 34.477004812832256, 0.0],
              [-80.885728317983975, 34.476345079723799, 0.0],
              [-80.886142984314631, 34.475566212729426, 0.0],
              [-80.886142985384424, 34.475222479986165, 0.0],
              [-80.886032317762968, 34.47513087989158, 0.0],
              [-80.885091985113121, 34.475085279889932, 0.0],
              [-80.884842918615135, 34.474993679484584, 0.0],
              [-80.884815317893882, 34.474741679793567, 0.0],
              [-80.885921585250614, 34.474306213243729, 0.0],
              [-80.887083318577183, 34.474535213319449, 0.0],
              [-80.887636518369192, 34.474558079722655, 0.0],
              [-80.888023717953416, 34.474145813149853, 0.0],
              [-80.888051518361024, 34.473847880002864, 0.0],
              [-80.887774784906114, 34.473275279525005, 0.0],
              [-80.887082984807392, 34.471374013277739, 0.0],
              [-80.886806385165713, 34.471099212742189, 0.0],
              [-80.886419118638059, 34.471030612931528, 0.0],
              [-80.886031917948173, 34.471259679558131, 0.0],
              [-80.88547878537608, 34.471328412801626, 0.0],
              [-80.885174585397536, 34.471145279497954, 0.0],
              [-80.885174517896701, 34.470847479665544, 0.0],
              [-80.885838317675578, 34.47064121271216, 0.0],
              [-80.885948984819365, 34.470458013294049, 0.0],
              [-80.885976518111065, 34.470160213293063, 0.0],
              [-80.885838184607891, 34.469976880270252, 0.0],
              [-80.884704184915492, 34.469404413120323, 0.0],
              [-80.883819117644691, 34.468717280144872, 0.0],
              [-80.88348718460901, 34.468350812702631, 0.0],
              [-80.882906317767493, 34.468053013504736, 0.0],
              [-80.882519117972734, 34.468098880079417, 0.0],
              [-80.882159584881379, 34.468465412887738, 0.0],
              [-80.881827785373034, 34.469175480200221, 0.0],
              [-80.88141291813271, 34.469267212802315, 0.0],
              [-80.881302184976917, 34.469129679969704, 0.0],
              [-80.881385185414118, 34.468259212948475, 0.0],
              [-80.881302184495809, 34.468053079572535, 0.0],
              [-80.881025584353623, 34.467778212962777, 0.0],
              [-80.879172584616711, 34.466793280130993, 0.0],
              [-80.878785318464566, 34.466678879426844, 0.0],
              [-80.878481118732708, 34.466678813641835, 0.0],
              [-80.878121518692339, 34.466862212742171, 0.0],
              [-80.877817318185208, 34.467137079538809, 0.0],
              [-80.877153584446518, 34.467114213591771, 0.0],
              [-80.877042918472526, 34.466839279888973, 0.0],
              [-80.87751311843013, 34.465693879869285, 0.0],
              [-80.877540718562003, 34.464915079661559, 0.0],
              [-80.877817318097541, 34.464090479551267, 0.0],
              [-80.878010917910288, 34.462945080229538, 0.0],
              [-80.878010917836292, 34.462257879957299, 0.0],
              [-80.877900318680318, 34.461685280078612, 0.0],
              [-80.877457785132719, 34.460814813236787, 0.0],
              [-80.876904718139144, 34.460333680093434, 0.0],
              [-80.876434518680782, 34.46017341314991, 0.0],
              [-80.8754665846281, 34.460242213044801, 0.0],
              [-80.874968917908092, 34.459692480175001, 0.0],
              [-80.874775317796875, 34.459669613602109, 0.0],
              [-80.874498717904459, 34.459784080200563, 0.0],
              [-80.874028518573681, 34.460608680243098, 0.0],
              [-80.873669117728994, 34.460860612983559, 0.0],
              [-80.873088317767227, 34.461112612783786, 0.0],
              [-80.872811718317678, 34.461112612684374, 0.0],
              [-80.872562917728501, 34.460723213223659, 0.0],
              [-80.872562918350368, 34.459921480035852, 0.0],
              [-80.87261811878173, 34.459738212712175, 0.0],
              [-80.872922384963942, 34.459440480147087, 0.0],
              [-80.873807318361855, 34.458913613306919, 0.0],
              [-80.874443384795867, 34.458295213418722, 0.0],
              [-80.87447098521379, 34.457974413594748, 0.0],
              [-80.872922584530912, 34.45575247965305, 0.0],
              [-80.872231318242584, 34.455225480335116, 0.0],
              [-80.871235918801901, 34.454263479372798, 0.0],
              [-80.870765718823094, 34.454080213405504, 0.0],
              [-80.870378585356889, 34.45414888002562, 0.0],
              [-80.870129718330219, 34.454286413258593, 0.0],
              [-80.869991385450646, 34.454721679505518, 0.0],
              [-80.870101918064194, 34.455431680200746, 0.0],
              [-80.869991318076288, 34.455523413027684, 0.0],
              [-80.869438185072241, 34.45552328002529, 0.0],
              [-80.869299984326716, 34.455294279734538, 0.0],
              [-80.869106584724193, 34.454057280270199, 0.0],
              [-80.868857785466702, 34.453416012696657, 0.0],
              [-80.868525984430008, 34.453095213614759, 0.0],
              [-80.867945318781835, 34.452934879422024, 0.0],
              [-80.867226318538812, 34.453301279384753, 0.0],
              [-80.866534984460742, 34.453370012930847, 0.0],
              [-80.866424517721043, 34.453140879480358, 0.0],
              [-80.866424384795465, 34.452614013076314, 0.0],
              [-80.866701118228747, 34.451904013375746, 0.0],
              [-80.8667287178734, 34.451079279665258, 0.0],
              [-80.866590584576713, 34.450460813586496, 0.0],
              [-80.866120584856361, 34.449727679961981, 0.0],
              [-80.865705985050838, 34.448467813430852, 0.0],
              [-80.865567985189585, 34.447505813073434, 0.0],
              [-80.86487678436869, 34.446703879756505, 0.0],
              [-80.864019785297273, 34.44560441304332, 0.0],
              [-80.863494518699767, 34.445146213434114, 0.0],
              [-80.863052117886056, 34.445054613252438, 0.0],
              [-80.86249911796051, 34.445054479812534, 0.0],
              [-80.862167385013151, 34.44528347982218, 0.0],
              [-80.861392917915836, 34.446680812867321, 0.0],
              [-80.860922718184455, 34.44679527975731, 0.0],
              [-80.860591118109909, 34.446405880313328, 0.0],
              [-80.859927518823369, 34.446039213015503, 0.0],
              [-80.859927517703483, 34.445787280356946, 0.0],
              [-80.860397717982778, 34.445329080122995, 0.0],
              [-80.86072958508926, 34.444664879798921, 0.0],
              [-80.860757385356607, 34.44427548013779, 0.0],
              [-80.860619185202211, 34.443908879920059, 0.0],
              [-80.859845118407435, 34.443244613473105, 0.0],
              [-80.859264518513797, 34.443107079984117, 0.0],
              [-80.859043184945648, 34.443221479579435, 0.0],
              [-80.858794317839553, 34.44370261291634, 0.0],
              [-80.858600718811161, 34.44386287979848, 0.0],
              [-80.857522385324302, 34.443954412769322, 0.0],
              [-80.857190585291661, 34.443725279680287, 0.0],
              [-80.857190784626638, 34.443473413166565, 0.0],
              [-80.8581309185561, 34.443084013399833, 0.0],
              [-80.858545717993351, 34.442603013363502, 0.0],
              [-80.858573385405649, 34.442053213068263, 0.0],
              [-80.858352385423302, 34.441663813226967, 0.0],
              [-80.857993118335557, 34.441320213150213, 0.0],
              [-80.857882518472465, 34.440999479514034, 0.0],
              [-80.857938118455763, 34.440174880086893, 0.0],
              [-80.857357584891119, 34.439647879785191, 0.0],
              [-80.857136518150398, 34.439144012892939, 0.0],
              [-80.857108918740266, 34.438846212888173, 0.0],
              [-80.856666585469824, 34.438227480100224, 0.0],
              [-80.855864984455664, 34.437815213094083, 0.0],
              [-80.855229317690444, 34.437288213403555, 0.0],
              [-80.854786984366029, 34.437082012865325, 0.0],
              [-80.854427518405529, 34.437196480149694, 0.0],
              [-80.854233918173989, 34.437379679995637, 0.0],
              [-80.854095384604207, 34.438158479610429, 0.0],
              [-80.853597585012338, 34.438822613203499, 0.0],
              [-80.853237985245897, 34.439418213514422, 0.0],
              [-80.852546518790305, 34.44021981342847, 0.0],
              [-80.851993584460999, 34.440334279558677, 0.0],
              [-80.851882984675839, 34.440242680026415, 0.0],
              [-80.85191131783435, 34.440090679555176, 0.0],
              [-80.851938384759734, 34.439944812819668, 0.0],
              [-80.852823584498353, 34.438616412994968, 0.0],
              [-80.853155585013738, 34.43783761350921, 0.0],
              [-80.853349317775326, 34.437150412843302, 0.0],
              [-80.852575318413486, 34.436715079985873, 0.0],
              [-80.852519984636629, 34.436509013100597, 0.0],
              [-80.852962518077305, 34.436188280285599, 0.0],
              [-80.852990184669693, 34.43593627940529, 0.0],
              [-80.852879718531398, 34.435753079796413, 0.0],
              [-80.852105717882921, 34.435317680075592, 0.0],
              [-80.851331717857207, 34.435019813623924, 0.0],
              [-80.850889318013259, 34.435317479942903, 0.0],
              [-80.850335984530247, 34.436371013066314, 0.0],
              [-80.850225318744918, 34.43664588019692, 0.0],
              [-80.8500315851891, 34.437035280233921, 0.0],
              [-80.849672117996064, 34.437401813203664, 0.0],
              [-80.849506185478248, 34.437470480210301, 0.0],
              [-80.849202118456191, 34.437424612771139, 0.0],
              [-80.849174584928221, 34.437035213407903, 0.0],
              [-80.849866318134971, 34.435523480187094, 0.0],
              [-80.849893984836555, 34.434927813040161, 0.0],
              [-80.84975591875363, 34.434675879534502, 0.0],
              [-80.849507118147571, 34.434469680268251, 0.0],
              [-80.848760718198818, 34.434263280191601, 0.0],
              [-80.84560918480048, 34.433758680359382, 0.0],
              [-80.845249984374661, 34.433460812957861, 0.0],
              [-80.844890785500098, 34.432979679643793, 0.0],
              [-80.843923384740663, 34.432200613013762, 0.0],
              [-80.843729984377504, 34.431925679881033, 0.0],
              [-80.843647184824519, 34.431627879654989, 0.0],
              [-80.843703517940426, 34.429405880205628, 0.0],
              [-80.843592984993663, 34.429153879517997, 0.0],
              [-80.84337191813384, 34.428970679514691, 0.0],
              [-80.84306778545583, 34.428970479573266, 0.0],
              [-80.842874185326195, 34.429199479494017, 0.0],
              [-80.842763385160552, 34.429703480043017, 0.0],
              [-80.842099717706304, 34.430482079423207, 0.0],
              [-80.841297585363336, 34.43110048031938, 0.0],
              [-80.841048718206665, 34.431444012781682, 0.0],
              [-80.841020985204651, 34.431650080152977, 0.0],
              [-80.840467718243474, 34.432428812799088, 0.0],
              [-80.839527518083827, 34.432955412729974, 0.0],
              [-80.839084985027014, 34.433390480040046, 0.0],
              [-80.838476385242856, 34.434237879587634, 0.0],
              [-80.838476118115338, 34.434673079775408, 0.0],
              [-80.838669585176547, 34.434833612806372, 0.0],
              [-80.839388317821843, 34.435108679564252, 0.0],
              [-80.840272718127537, 34.435750279629204, 0.0],
              [-80.840493718237695, 34.436162613482004, 0.0],
              [-80.840465918310912, 34.436643679579845, 0.0],
              [-80.840244585121866, 34.436758079773377, 0.0],
              [-80.839802318819579, 34.436597679666946, 0.0],
              [-80.839194318458453, 34.436093613006854, 0.0],
              [-80.839000784644199, 34.436024812799758, 0.0],
              [-80.838281918437218, 34.436093279826281, 0.0],
              [-80.837839517818026, 34.43636807963663, 0.0],
              [-80.837618117990999, 34.436940680048458, 0.0],
              [-80.837507317852726, 34.437444613237865, 0.0],
              [-80.837313518335876, 34.437788079425474, 0.0],
              [-80.836926318336623, 34.438131679732251, 0.0],
              [-80.836953917759971, 34.438406480176027, 0.0],
              [-80.838253185531684, 34.438773413581224, 0.0],
              [-80.838557185055748, 34.439002479828424, 0.0],
              [-80.838474118583093, 34.439208680333408, 0.0],
              [-80.838252917971488, 34.439231613040789, 0.0],
              [-80.837285318795239, 34.439070813038292, 0.0],
              [-80.836732184950051, 34.439185412887092, 0.0],
              [-80.836234318709401, 34.439551613567303, 0.0],
              [-80.835874718615329, 34.440032613245094, 0.0],
              [-80.835763917929469, 34.440605213470441, 0.0],
              [-80.83579131882, 34.440994613155681, 0.0],
              [-80.835431384630283, 34.441842013640901, 0.0],
              [-80.835431185275468, 34.442414812784499, 0.0],
              [-80.835817918549395, 34.443216612789875, 0.0],
              [-80.835706918348762, 34.443858013135014, 0.0],
              [-80.835319518552353, 34.444545080039212, 0.0],
              [-80.834461784660704, 34.445506879474713, 0.0],
              [-80.833410385164541, 34.447110013332711, 0.0],
              [-80.833161118231871, 34.447682680204956, 0.0],
              [-80.832829118635672, 34.44809488018214, 0.0],
              [-80.832414318022416, 34.448071813336966, 0.0],
              [-80.832220917712604, 34.447865612915685, 0.0],
              [-80.832193518362971, 34.447453279404314, 0.0],
              [-80.832332118107757, 34.446949279564748, 0.0],
              [-80.832802718559407, 34.445895813134676, 0.0],
              [-80.832830517913877, 34.445506413091977, 0.0],
              [-80.832609584446558, 34.444956479831184, 0.0],
              [-80.832112318327859, 34.444360812924053, 0.0],
              [-80.83208491883309, 34.443833879712365, 0.0],
              [-80.832223385069312, 34.443398813298622, 0.0],
              [-80.832943118290032, 34.442001612758496, 0.0],
              [-80.833579318699933, 34.441268813679514, 0.0],
              [-80.83369011804109, 34.440925280169246, 0.0],
              [-80.83360718452353, 34.440787812772548, 0.0],
              [-80.833247784665147, 34.440673213550788, 0.0],
              [-80.832363117804022, 34.440558280069723, 0.0],
              [-80.831893318063635, 34.44026041334255, 0.0],
              [-80.831755185146477, 34.439916813657149, 0.0],
              [-80.831788385174775, 34.439875612831358, 0.0],
              [-80.831921185344953, 34.439710613060626, 0.0],
              [-80.832778385038949, 34.43950468011186, 0.0],
              [-80.833469717750461, 34.439253013638812, 0.0],
              [-80.833939917861528, 34.43886367964722, 0.0],
              [-80.834299785029543, 34.43819947941985, 0.0],
              [-80.834272385095019, 34.437512280345203, 0.0],
              [-80.834106718372212, 34.437214479481185, 0.0],
              [-80.834162318075528, 34.436733413128387, 0.0],
              [-80.834936585019847, 34.436435879980763, 0.0],
              [-80.836347118195548, 34.435313880129428, 0.0],
              [-80.836679118131045, 34.434741279930918, 0.0],
              [-80.836789784872593, 34.434351879454198, 0.0],
              [-80.836762985025146, 34.432977480293694, 0.0],
              [-80.837039918363914, 34.431878080055036, 0.0],
              [-80.837095584814136, 34.431053413097182, 0.0],
              [-80.836902184379852, 34.430664013635749, 0.0],
              [-80.83646011884278, 34.430297279910008, 0.0],
              [-80.835990184476913, 34.430136813019807, 0.0],
              [-80.835271384581191, 34.430067812826579, 0.0],
              [-80.835160918065569, 34.429930280114284, 0.0],
              [-80.835243984383069, 34.429701280317659, 0.0],
              [-80.835409985345834, 34.429586879528763, 0.0],
              [-80.836681718760275, 34.429312412994136, 0.0],
              [-80.836986118485498, 34.428946013124843, 0.0],
              [-80.837014118891148, 34.428212879907747, 0.0],
              [-80.836903717945603, 34.427686013116393, 0.0],
              [-80.836737985563843, 34.427296613669952, 0.0],
              [-80.836489318785368, 34.427067412856154, 0.0],
              [-80.834996718717804, 34.426631813191051, 0.0],
              [-80.834526985329632, 34.426425412756224, 0.0],
              [-80.834029584463011, 34.426013012792112, 0.0],
              [-80.833946784995234, 34.425783813104616, 0.0],
              [-80.83394718473491, 34.424775879623944, 0.0],
              [-80.834057785403445, 34.424707279996646, 0.0],
              [-80.834527717727667, 34.42479901322109, 0.0],
              [-80.835688385121585, 34.425578213428132, 0.0],
              [-80.83662798535758, 34.426105413509468, 0.0],
              [-80.837595517848342, 34.426288879910167, 0.0],
              [-80.837927184563867, 34.426289013542714, 0.0],
              [-80.838452518044008, 34.426151679599897, 0.0],
              [-80.838756717878397, 34.425785413287649, 0.0],
              [-80.838784585158791, 34.425327212968554, 0.0],
              [-80.838453185095247, 34.424616879581301, 0.0],
              [-80.8380939849133, 34.424273212844724, 0.0],
              [-80.836822718846165, 34.423700213400707, 0.0],
              [-80.835827718458447, 34.423379280287477, 0.0],
              [-80.835413184399243, 34.423081279716506, 0.0],
              [-80.835164718366514, 34.42269167983995, 0.0],
              [-80.834943918593638, 34.421844213185487, 0.0],
              [-80.834805784714078, 34.42161501323919, 0.0],
              [-80.834170184775019, 34.421271213239692, 0.0],
              [-80.833313318273653, 34.421316680265164, 0.0],
              [-80.833009384559972, 34.421041813328053, 0.0],
              [-80.832649985435523, 34.420972880389151, 0.0],
              [-80.832262918056415, 34.421247679983288, 0.0],
              [-80.831792784843543, 34.421820213227278, 0.0],
              [-80.831294984789224, 34.422117813341643, 0.0],
              [-80.830438118823835, 34.422186279415172, 0.0],
              [-80.830023518263943, 34.422094413356852, 0.0],
              [-80.829691985241737, 34.421819480196888, 0.0],
              [-80.829691984654417, 34.421636213082195, 0.0],
              [-80.829802718316472, 34.421544613348061, 0.0],
              [-80.830742518340273, 34.421499080193087, 0.0],
              [-80.83096378465595, 34.421361813708323, 0.0],
              [-80.830963784641824, 34.421247280244444, 0.0],
              [-80.830217785417346, 34.420651413512154, 0.0],
              [-80.830217985037336, 34.420147479907349, 0.0],
              [-80.830107717796267, 34.419895413415489, 0.0],
              [-80.829886584937341, 34.419712213219185, 0.0],
              [-80.827454718779364, 34.418680413650769, 0.0],
              [-80.825105585434812, 34.418083879987734, 0.0],
              [-80.821042318218389, 34.418128080031309, 0.0],
              [-80.820351384949745, 34.418036079656304, 0.0],
              [-80.818831518700847, 34.417623213576896, 0.0],
              [-80.818389184573704, 34.41760001351031, 0.0],
              [-80.817753318921817, 34.41787461323959, 0.0],
              [-80.817752984393223, 34.418241079601422, 0.0],
              [-80.818443718701843, 34.418745413239186, 0.0],
              [-80.818526518796403, 34.41892861288828, 0.0],
              [-80.818415785365033, 34.419111813183498, 0.0],
              [-80.817531184636351, 34.419225880006962, 0.0],
              [-80.817392918007044, 34.419409280025882, 0.0],
              [-80.817336784800005, 34.420325413198576, 0.0],
              [-80.817529317873024, 34.421906079484721, 0.0],
              [-80.816948585478556, 34.422157812856959, 0.0],
              [-80.816561717855407, 34.421951413337375, 0.0],
              [-80.815705517973768, 34.420989012848942, 0.0],
              [-80.81548531883557, 34.419729012742614, 0.0],
              [-80.815154118079207, 34.419247813093151, 0.0],
              [-80.814794918531575, 34.41897267973355, 0.0],
              [-80.814850385464538, 34.418675013589578, 0.0],
              [-80.815486718158283, 34.417896413400499, 0.0],
              [-80.815459318331406, 34.417621480353255, 0.0],
              [-80.815348785573079, 34.417461080318191, 0.0],
              [-80.815017184771449, 34.417438080121059, 0.0],
              [-80.813855918692425, 34.417918612917113, 0.0],
              [-80.812251584434932, 34.41922348015332, 0.0],
              [-80.812057785235197, 34.419773212883946, 0.0],
              [-80.811504718311639, 34.419956079632804, 0.0],
              [-80.810039518656481, 34.420253213356943, 0.0],
              [-80.809679918661615, 34.420665279757827, 0.0],
              [-80.809569118117693, 34.420963013484915, 0.0],
              [-80.809789517829671, 34.421856613647805, 0.0],
              [-80.810203585109463, 34.422521079517495, 0.0],
              [-80.810313718115466, 34.422979280023164, 0.0],
              [-80.810285718504787, 34.423483213617963, 0.0],
              [-80.809843118716955, 34.423964013037939, 0.0],
              [-80.809649518596913, 34.424009679614137, 0.0],
              [-80.809262584892352, 34.42384907992372, 0.0],
              [-80.809069384717404, 34.423528412843524, 0.0],
              [-80.80882118554328, 34.422841012956837, 0.0],
              [-80.808462185581618, 34.422314013669364, 0.0],
              [-80.808462384785642, 34.422199479637705, 0.0],
              [-80.807882585603352, 34.4213516134269, 0.0],
              [-80.806971318575819, 34.420366079511695, 0.0],
              [-80.806999317767406, 34.419793279538851, 0.0],
              [-80.807359118282889, 34.419220879909517, 0.0],
              [-80.807276518591422, 34.418900212989421, 0.0],
              [-80.807055384416827, 34.418785479885784, 0.0],
              [-80.803848785492704, 34.41919607961735, 0.0],
              [-80.8022451850848, 34.419630412886619, 0.0],
              [-80.801968718195738, 34.419767613408219, 0.0],
              [-80.801857785375233, 34.420134013706608, 0.0],
              [-80.80196811787124, 34.420317413027021, 0.0],
              [-80.803377784777197, 34.420432680159472, 0.0],
              [-80.803709385248084, 34.420593280064878, 0.0],
              [-80.803709118260343, 34.420891013136668, 0.0],
              [-80.803487784813555, 34.421097080387739, 0.0],
              [-80.803100584847598, 34.421211479514113, 0.0],
              [-80.802243718945462, 34.42130261336321, 0.0],
              [-80.801856718338144, 34.421187813423728, 0.0],
              [-80.80130451861875, 34.420591879775806, 0.0],
              [-80.801083518452231, 34.420500213516675, 0.0],
              [-80.800585718034654, 34.42059147956904, 0.0],
              [-80.800391985339076, 34.420774679745897, 0.0],
              [-80.800446918753806, 34.421255679903183, 0.0],
              [-80.800778318084568, 34.421691080111785, 0.0],
              [-80.800750318001974, 34.421988879675396, 0.0],
              [-80.800307985295987, 34.42208027956147, 0.0],
              [-80.800169918667279, 34.422034279606741, 0.0],
              [-80.800087185021255, 34.421736479701238, 0.0],
              [-80.799755718089244, 34.421530213335039, 0.0],
              [-80.799285918162937, 34.421346613358175, 0.0],
              [-80.79909251790518, 34.421140280224613, 0.0],
              [-80.799148185489869, 34.420796812755306, 0.0],
              [-80.799258984621005, 34.420476079482569, 0.0],
              [-80.799038118763903, 34.42029281341653, 0.0],
              [-80.798347117947813, 34.42026941358673, 0.0],
              [-80.797628184476252, 34.420406412895467, 0.0],
              [-80.796494718419737, 34.420726413722527, 0.0],
              [-80.794946585279988, 34.420885812971747, 0.0],
              [-80.794642317807757, 34.421000079535915, 0.0],
              [-80.794614584999636, 34.421229213098663, 0.0],
              [-80.795498784751487, 34.421527480023471, 0.0],
              [-80.795498585072721, 34.421756613584165, 0.0],
              [-80.795360318876959, 34.421825212920524, 0.0],
              [-80.794448184446722, 34.421710079722878, 0.0],
              [-80.79419931820577, 34.421847279767086, 0.0],
              [-80.794198918256569, 34.422259613137456, 0.0],
              [-80.794336918660946, 34.42246588006752, 0.0],
              [-80.794308918210689, 34.422809613437892, 0.0],
              [-80.794170585308777, 34.422947013693538, 0.0],
              [-80.793258117821779, 34.423198279802222, 0.0],
              [-80.791544185048394, 34.423197079532045, 0.0],
              [-80.791184918321292, 34.42310521312568, 0.0],
              [-80.79107458515621, 34.422830280262268, 0.0],
              [-80.791157918215589, 34.422647079754583, 0.0],
              [-80.792098185013344, 34.422143813326166, 0.0],
              [-80.792347184483262, 34.421869080217341, 0.0],
              [-80.792347718474815, 34.42145667987274, 0.0],
              [-80.792071518834447, 34.421181612876182, 0.0],
              [-80.791214918542849, 34.42088328019134, 0.0],
              [-80.789279985495099, 34.420904880353909, 0.0],
              [-80.788644117930815, 34.421087679611951, 0.0],
              [-80.788505584587654, 34.421293813307322, 0.0],
              [-80.788477518700105, 34.421729013480935, 0.0],
              [-80.788615385043684, 34.42195807943444, 0.0],
              [-80.788587584628289, 34.422210080011723, 0.0],
              [-80.788366184866163, 34.422347279979178, 0.0],
              [-80.787813518253103, 34.422209479887897, 0.0],
              [-80.787482185045036, 34.421934413260573, 0.0],
              [-80.787233784951752, 34.42154481321419, 0.0],
              [-80.786902984676061, 34.420788680143254, 0.0],
              [-80.78643351897378, 34.420376013526884, 0.0],
              [-80.786074318764832, 34.420192480021015, 0.0],
              [-80.785770184976457, 34.420192280183997, 0.0],
              [-80.785493784929443, 34.420283680355695, 0.0],
              [-80.785272318633545, 34.420489679737145, 0.0],
              [-80.785299585521045, 34.420879079715412, 0.0],
              [-80.78571338483475, 34.421658280086888, 0.0],
              [-80.786431118121683, 34.422506413387453, 0.0],
              [-80.786983518211343, 34.422896212817186, 0.0],
              [-80.786817385227693, 34.423171012766574, 0.0],
              [-80.786430384959161, 34.423147813272543, 0.0],
              [-80.785960718616735, 34.422964213599485, 0.0],
              [-80.785684117991025, 34.423055680241141, 0.0],
              [-80.785435185511815, 34.423284480337202, 0.0],
              [-80.785462385498917, 34.423559413005094, 0.0],
              [-80.786014918927208, 34.423972079655734, 0.0],
              [-80.787175584453465, 34.424247880120156, 0.0],
              [-80.787562384598047, 34.424408413393351, 0.0],
              [-80.787617517981445, 34.424568879787969, 0.0],
              [-80.787506584932615, 34.424912413061037, 0.0],
              [-80.786400518476896, 34.425186479728922, 0.0],
              [-80.784796518127195, 34.425872612879857, 0.0],
              [-80.784492118758166, 34.426147212778766, 0.0],
              [-80.784380984723072, 34.426628080022816, 0.0],
              [-80.784518918400664, 34.426903213592936, 0.0],
              [-80.785098784577585, 34.427499213084545, 0.0],
              [-80.785098384671983, 34.427819880023975, 0.0],
              [-80.784240985035268, 34.42830027995057, 0.0],
              [-80.783715185443839, 34.428712213485277, 0.0],
              [-80.782884184665974, 34.430109013407048, 0.0],
              [-80.782578918363441, 34.431299879473244, 0.0],
              [-80.782688318923988, 34.432399479821441, 0.0],
              [-80.782576918912696, 34.433040879631712, 0.0],
              [-80.782466185321098, 34.433201080107487, 0.0],
              [-80.78221738504368, 34.43320088005018, 0.0],
              [-80.78166518540182, 34.432490413448221, 0.0],
              [-80.781499385181675, 34.432421613005324, 0.0],
              [-80.780918717944857, 34.432581413513482, 0.0],
              [-80.780586584621858, 34.432879013613189, 0.0],
              [-80.780115384609545, 34.433932412931547, 0.0],
              [-80.779893985343932, 34.4341154799557, 0.0],
              [-80.778870585377192, 34.434481080083003, 0.0],
              [-80.778455718805631, 34.434755680092977, 0.0],
              [-80.776906518090271, 34.435510479510505, 0.0],
              [-80.77604878492744, 34.436105279649674, 0.0],
              [-80.775661718604184, 34.436059213377739, 0.0],
              [-80.775468384818396, 34.435944479423263, 0.0],
              [-80.774446184661912, 34.435302279805853, 0.0],
              [-80.774466518950575, 34.43525627991982, 0.0],
              [-80.774557184554126, 34.435050413298192, 0.0],
              [-80.775276118883752, 34.434867813604178, 0.0],
              [-80.775774184508379, 34.434501613468925, 0.0],
              [-80.776826318953027, 34.433196680020181, 0.0],
              [-80.777988984914074, 34.431983480337124, 0.0],
              [-80.780285318747701, 34.430404680034904, 0.0],
              [-80.780839585177887, 34.429191079991647, 0.0],
              [-80.780895985233542, 34.428297879447612, 0.0],
              [-80.781229118332661, 34.427106880093376, 0.0],
              [-80.781230318075373, 34.425984480147392, 0.0],
              [-80.781092518902895, 34.425640679940237, 0.0],
              [-80.780347318152494, 34.424701013539469, 0.0],
              [-80.779712118921722, 34.424242412892134, 0.0],
              [-80.77929758491797, 34.424058879836217, 0.0],
              [-80.778219718358301, 34.423851812885488, 0.0],
              [-80.778164718456509, 34.423691413495725, 0.0],
              [-80.778247918476893, 34.423416479659494, 0.0],
              [-80.779271117927124, 34.423096679588824, 0.0],
              [-80.779658317844806, 34.422890813679977, 0.0],
              [-80.779741517851093, 34.422707613601887, 0.0],
              [-80.779770117818828, 34.421791279765856, 0.0],
              [-80.779411717865997, 34.420920479748034, 0.0],
              [-80.7793571847875, 34.420325013409609, 0.0],
              [-80.779440384629623, 34.420210479494941, 0.0],
              [-80.779716785143279, 34.42016487988591, 0.0],
              [-80.780351518918039, 34.421081679541004, 0.0],
              [-80.780738318232565, 34.421173479624613, 0.0],
              [-80.781152918139696, 34.421173880150342, 0.0],
              [-80.781346585520325, 34.421036479494035, 0.0],
              [-80.781651118967801, 34.420670279978488, 0.0],
              [-80.781679318218082, 34.420235079993347, 0.0],
              [-80.781237985010776, 34.419295613613009, 0.0],
              [-80.781238784731215, 34.418631279873175, 0.0],
              [-80.781516317925863, 34.417715213179463, 0.0],
              [-80.781406118346155, 34.417302812927431, 0.0],
              [-80.780881584833736, 34.416661013280581, 0.0],
              [-80.780688718736059, 34.416317279989002, 0.0],
              [-80.780357385206287, 34.415973413130509, 0.0],
              [-80.779804785564039, 34.415789679987689, 0.0],
              [-80.779556317814226, 34.415537612996609, 0.0],
              [-80.779529517885678, 34.414919013448461, 0.0],
              [-80.779391717956997, 34.414552413499059, 0.0],
              [-80.779087984760054, 34.414162680366836, 0.0],
              [-80.778314984986523, 34.413360413350972, 0.0],
              [-80.777928385252181, 34.413153879423568, 0.0],
              [-80.777071784741111, 34.412947079938661, 0.0],
              [-80.777044518937345, 34.41267221318131, 0.0],
              [-80.77773651791658, 34.411825212904787, 0.0],
              [-80.777764718540155, 34.411458680289329, 0.0],
              [-80.777599318314458, 34.410977479430358, 0.0],
              [-80.777185584700803, 34.410198280361151, 0.0],
              [-80.776826718001701, 34.409991813207419, 0.0],
              [-80.776439718931769, 34.409968612999144, 0.0],
              [-80.776079984662573, 34.410266079648537, 0.0],
              [-80.775581717977104, 34.411044613019413, 0.0],
              [-80.775000385302633, 34.41166261306816, 0.0],
              [-80.774364184890473, 34.412097279743506, 0.0],
              [-80.772317718610381, 34.413149413514667, 0.0],
              [-80.772151918859734, 34.41305761299904, 0.0],
              [-80.772096918355231, 34.412828480334511, 0.0],
              [-80.772761585163067, 34.411706480076752, 0.0],
              [-80.773425784768747, 34.411020013341862, 0.0],
              [-80.773453918848759, 34.410676413738877, 0.0],
              [-80.772929318900424, 34.410286479460645, 0.0],
              [-80.772929718428074, 34.409988680323622, 0.0],
              [-80.774062918588399, 34.409898079729821, 0.0],
              [-80.774449985389481, 34.409738013625564, 0.0],
              [-80.774864984722612, 34.409394679682279, 0.0],
              [-80.77652551779741, 34.407471812988241, 0.0],
              [-80.776553718562269, 34.407197013567824, 0.0],
              [-80.776443318332099, 34.40696781364904, 0.0],
              [-80.775973784747919, 34.406715413284964, 0.0],
              [-80.775670185276283, 34.406394480325091, 0.0],
              [-80.77567198479133, 34.40488261294611, 0.0],
              [-80.774790584486013, 34.402522479866022, 0.0],
              [-80.774598184504072, 34.401583213080826, 0.0],
              [-80.774350185052043, 34.401101879891456, 0.0],
              [-80.773797985381208, 34.400620479907907, 0.0],
              [-80.772030185210298, 34.399886012790603, 0.0],
              [-80.771366918777431, 34.399977012823662, 0.0],
              [-80.770675518369416, 34.400388813170878, 0.0],
              [-80.769153518950432, 34.402082613607909, 0.0],
              [-80.769125584881536, 34.402220013306504, 0.0],
              [-80.768654985174564, 34.402769413416848, 0.0],
              [-80.768267784504417, 34.403089679653917, 0.0],
              [-80.768101785514503, 34.403135413561373, 0.0],
              [-80.767936118807427, 34.403066480117033, 0.0],
              [-80.767964318466582, 34.402562613417338, 0.0],
              [-80.768214118673882, 34.401829813347078, 0.0],
              [-80.769875318260546, 34.399403079689606, 0.0],
              [-80.769903718102924, 34.398784613457401, 0.0],
              [-80.769573118184766, 34.398074080061292, 0.0],
              [-80.76960158539228, 34.397432813588715, 0.0],
              [-80.770043785331907, 34.397341479448684, 0.0],
              [-80.770596184506047, 34.397548213457021, 0.0],
              [-80.770982585155167, 34.397937880207564, 0.0],
              [-80.771894384938605, 34.398007412953916, 0.0],
              [-80.772198318387311, 34.398145079873565, 0.0],
              [-80.772501918723705, 34.398488880353, 0.0],
              [-80.774297384911506, 34.398971480026013, 0.0],
              [-80.775070985544716, 34.399109479961055, 0.0],
              [-80.775706584503197, 34.3990414134093, 0.0],
              [-80.776923185202151, 34.398629879820234, 0.0],
              [-80.777503985655656, 34.398241013739138, 0.0],
              [-80.777504385208417, 34.397943213683277, 0.0],
              [-80.776841717964572, 34.397392879651051, 0.0],
              [-80.776814584630287, 34.397003480264686, 0.0],
              [-80.777313118806674, 34.396064680083285, 0.0],
              [-80.777562118035235, 34.395812879996683, 0.0],
              [-80.777893718715703, 34.395767280105083, 0.0],
              [-80.778086918173685, 34.395950680100597, 0.0],
              [-80.778306985231012, 34.396821480272031, 0.0],
              [-80.778527785210102, 34.397050679456207, 0.0],
              [-80.779052785568823, 34.397142680185937, 0.0],
              [-80.77924631865244, 34.397028280258226, 0.0],
              [-80.779689118421402, 34.396455879666675, 0.0],
              [-80.780574318259838, 34.395586213223616, 0.0],
              [-80.780602384625098, 34.395288413140172, 0.0],
              [-80.780464518276034, 34.395059213208, 0.0],
              [-80.779884518772036, 34.394829679567309, 0.0],
              [-80.77985718560096, 34.394531880215325, 0.0],
              [-80.780437917907591, 34.394165813705264, 0.0],
              [-80.780880718740946, 34.393524813751604, 0.0],
              [-80.780936518497569, 34.393089612957709, 0.0],
              [-80.780660585351455, 34.39269987970107, 0.0],
              [-80.78030158447261, 34.392539279789752, 0.0],
              [-80.779085918610249, 34.392400879494907, 0.0],
              [-80.778616384582079, 34.392240213703012, 0.0],
              [-80.777926185265798, 34.391712813721185, 0.0],
              [-80.77745658466813, 34.391529212805104, 0.0],
              [-80.77665538458298, 34.391391080388743, 0.0],
              [-80.776462117831215, 34.39129941292687, 0.0],
              [-80.776462518740615, 34.391001613105821, 0.0],
              [-80.776849584744923, 34.39084147993271, 0.0],
              [-80.777402318284615, 34.390796079713184, 0.0],
              [-80.777982118237759, 34.391140213048345, 0.0],
              [-80.778921185237024, 34.391461613667296, 0.0],
              [-80.779446184574468, 34.391485013456347, 0.0],
              [-80.779833518623391, 34.391210413459838, 0.0],
              [-80.780027118037054, 34.390958413168285, 0.0],
              [-80.780082784825836, 34.390614880422355, 0.0],
              [-80.779145318644538, 34.388873213409575, 0.0],
              [-80.778041318218058, 34.387818679991504, 0.0],
              [-80.776025585034972, 34.386694612847172, 0.0],
              [-80.774285984848632, 34.38584561284447, 0.0],
              [-80.773429785490933, 34.385547080414625, 0.0],
              [-80.773098318179763, 34.385547013409088, 0.0],
              [-80.772904718889535, 34.385638413686721, 0.0],
              [-80.771963785033108, 34.386782879844837, 0.0],
              [-80.771715118244387, 34.386805613396575, 0.0],
              [-80.771660517833922, 34.386278679996586, 0.0],
              [-80.771937784788605, 34.385546013295077, 0.0],
              [-80.771938385497251, 34.385064813154116, 0.0],
              [-80.771496784646217, 34.384675080451579, 0.0],
              [-80.770474918900462, 34.384330479465042, 0.0],
              [-80.769480918328966, 34.38384861337375, 0.0],
              [-80.768569784820656, 34.383275213593876, 0.0],
              [-80.767989785213885, 34.383137213602453, 0.0],
              [-80.767740985077353, 34.383228679589543, 0.0],
              [-80.767049185017697, 34.383984079992132, 0.0],
              [-80.766662184883074, 34.384189880011654, 0.0],
              [-80.766385917913979, 34.384212479949532, 0.0],
              [-80.766165318694405, 34.383868812817731, 0.0],
              [-80.766248584592674, 34.38357101298034, 0.0],
              [-80.766277518420495, 34.38254021324321, 0.0],
              [-80.766195185218706, 34.382150613757311, 0.0],
              [-80.765919584725779, 34.381646479682907, 0.0],
              [-80.765229385504199, 34.381233480060857, 0.0],
              [-80.764566318956625, 34.381278813093765, 0.0],
              [-80.763681584529365, 34.381644479616888, 0.0],
              [-80.762216184733632, 34.382467812922272, 0.0],
              [-80.761856585273819, 34.382742413054963, 0.0],
              [-80.761717985614339, 34.38306301304636, 0.0],
              [-80.761716984849883, 34.383864680020352, 0.0],
              [-80.761909585621311, 34.384391813257032, 0.0],
              [-80.762488385420909, 34.385377280301078, 0.0],
              [-80.763094118087537, 34.38698128034433, 0.0],
              [-80.763617184632736, 34.388470880405023, 0.0],
              [-80.763561318614336, 34.388928879873639, 0.0],
              [-80.763367584530172, 34.389157813666991, 0.0],
              [-80.762787385153004, 34.38908861329142, 0.0],
              [-80.762373785670917, 34.388378080249211, 0.0],
              [-80.761683985012127, 34.3877360128471, 0.0],
              [-80.761242184756483, 34.387529413220967, 0.0],
              [-80.76049611813059, 34.387597480159933, 0.0],
              [-80.760330718473142, 34.387299613726128, 0.0],
              [-80.760220919010763, 34.386726813409652, 0.0],
              [-80.759890385187774, 34.385993413287984, 0.0],
              [-80.759586918368129, 34.385649480089604, 0.0],
              [-80.759089918193112, 34.385420013372183, 0.0],
              [-80.758399185513227, 34.385304680107659, 0.0],
              [-80.758288918689971, 34.38519021377607, 0.0],
              [-80.758261718461881, 34.384915213301113, 0.0],
              [-80.758372384906764, 34.384755012942342, 0.0],
              [-80.759450918587874, 34.384114680092736, 0.0],
              [-80.759451518201004, 34.383771079512023, 0.0],
              [-80.759093118757107, 34.383152213179152, 0.0],
              [-80.758182518008411, 34.382326613714419, 0.0],
              [-80.75774071865024, 34.38214301331508, 0.0],
              [-80.756608185627627, 34.381935680014571, 0.0],
              [-80.755751718542442, 34.381957679769378, 0.0],
              [-80.755309384611905, 34.382163479627188, 0.0],
              [-80.754922518260685, 34.382231879990066, 0.0],
              [-80.754673985064258, 34.382048413375699, 0.0],
              [-80.754121718052261, 34.381864612954338, 0.0],
              [-80.753568984662678, 34.381955679663257, 0.0],
              [-80.753319985120939, 34.382184479752674, 0.0],
              [-80.753208985203628, 34.382505079652326, 0.0],
              [-80.753290918579495, 34.383192413406654, 0.0],
              [-80.753152585541102, 34.383329679908336, 0.0],
              [-80.752461585604749, 34.383489280153228, 0.0],
              [-80.752046584940274, 34.383878413096916, 0.0],
              [-80.751962318989257, 34.384748812908974, 0.0],
              [-80.752100118096536, 34.385023813354863, 0.0],
              [-80.75212678489028, 34.385642279908708, 0.0],
              [-80.751822185076406, 34.386077213566665, 0.0],
              [-80.751766318904302, 34.38655828013448, 0.0],
              [-80.751986718010826, 34.386970813124236, 0.0],
              [-80.752096518877494, 34.387429079801485, 0.0],
              [-80.751792118042189, 34.387818213453116, 0.0],
              [-80.751294384661392, 34.387909280433234, 0.0],
              [-80.750935384804833, 34.387771479854017, 0.0],
              [-80.750437985102039, 34.387793879913325, 0.0],
              [-80.750106118252191, 34.388045479989295, 0.0],
              [-80.749994984826017, 34.388389080354294, 0.0],
              [-80.749800785143847, 34.38864248021963, 0.0],
              [-80.749580117982148, 34.388826013425827, 0.0],
              [-80.749386718377934, 34.388803279979989, 0.0],
              [-80.749382184937147, 34.388693279926095, 0.0],
              [-80.749300785610444, 34.386672879818661, 0.0],
              [-80.749134185035544, 34.386192079875471, 0.0],
              [-80.749022718939671, 34.385413413291673, 0.0],
              [-80.749049318647309, 34.384771880100935, 0.0],
              [-80.749131918227079, 34.384565612837378, 0.0],
              [-80.749131385545368, 34.384199080169658, 0.0],
              [-80.748909985394079, 34.383901613298249, 0.0],
              [-80.748550518980252, 34.383627012977847, 0.0],
              [-80.746864584912814, 34.383285213607891, 0.0],
              [-80.746008118975226, 34.383331879730541, 0.0],
              [-80.74545578478714, 34.38351567988574, 0.0],
              [-80.744932185519502, 34.384432480421808, 0.0],
              [-80.744711185305519, 34.384547280013244, 0.0],
              [-80.744296718561017, 34.38450181371234, 0.0],
              [-80.742968785660636, 34.383289079956384, 0.0],
              [-80.742029118793653, 34.38306088042598, 0.0],
              [-80.741697318269615, 34.382900880004243, 0.0],
              [-80.741752118969274, 34.382534279980568, 0.0],
              [-80.742525785345762, 34.38260228032695, 0.0],
              [-80.743326918183783, 34.382509813057219, 0.0],
              [-80.744044518098676, 34.382028080207071, 0.0],
              [-80.744237585619445, 34.381753013251107, 0.0],
              [-80.744236918773723, 34.381226213014529, 0.0],
              [-80.743849784976732, 34.380974612944598, 0.0],
              [-80.743131518874094, 34.381021013028082, 0.0],
              [-80.74271598494677, 34.380219813200753, 0.0],
              [-80.742411985412346, 34.380105479493004, 0.0],
              [-80.741307118085743, 34.380312679704829, 0.0],
              [-80.740782518976502, 34.380565279697251, 0.0],
              [-80.740396184538227, 34.380909213711263, 0.0],
              [-80.740341118444846, 34.381069613000115, 0.0],
              [-80.740452385443376, 34.381619279900661, 0.0],
              [-80.740811985292098, 34.382054213295987, 0.0],
              [-80.74078478538874, 34.382306212864847, 0.0],
              [-80.740619118048457, 34.38244381367813, 0.0],
              [-80.740121785197346, 34.382421280292775, 0.0],
              [-80.739154384610558, 34.382124479617516, 0.0],
              [-80.738187518706738, 34.382217013241849, 0.0],
              [-80.737939118553768, 34.382400480381321, 0.0],
              [-80.737829185122621, 34.382858680031525, 0.0],
              [-80.737995185436176, 34.383041813238044, 0.0],
              [-80.73829918565464, 34.383156080296828, 0.0],
              [-80.73846531810554, 34.383339079722219, 0.0],
              [-80.738299718880739, 34.383499679788272, 0.0],
              [-80.737526118311777, 34.383546279918484, 0.0],
              [-80.737471385713604, 34.3838440803382, 0.0],
              [-80.738079784837595, 34.384347479677182, 0.0],
              [-80.737914585048728, 34.384782880249695, 0.0],
              [-80.736920184770923, 34.385012879656848, 0.0],
              [-80.736728318421243, 34.386135480168072, 0.0],
              [-80.736617985553991, 34.386250213435972, 0.0],
              [-80.736508317864107, 34.387006213453667, 0.0],
              [-80.737034318760138, 34.387830413771376, 0.0],
              [-80.736979385648041, 34.388059612895127, 0.0],
              [-80.73684138549622, 34.388174212901916, 0.0],
              [-80.736537384575996, 34.388151613555337, 0.0],
              [-80.735155118137712, 34.387534279553236, 0.0],
              [-80.734380918341358, 34.387054013749498, 0.0],
              [-80.733910984675717, 34.386871212857116, 0.0],
              [-80.733524184653277, 34.386894413772232, 0.0],
              [-80.733109984687786, 34.387169680024954, 0.0],
              [-80.732861719032755, 34.387559279869684, 0.0],
              [-80.732724184649712, 34.388040479859434, 0.0],
              [-80.732504785627427, 34.389323413544737, 0.0],
              [-80.732561785076001, 34.390652079830616, 0.0],
              [-80.732396318048146, 34.390904212929875, 0.0],
              [-80.732202985491796, 34.391018879787282, 0.0],
              [-80.730435184585971, 34.391524413467984, 0.0],
              [-80.730020584576138, 34.391501880065604, 0.0],
              [-80.729273985218157, 34.390998612836512, 0.0],
              [-80.72927811816227, 34.390925213690622, 0.0],
              [-80.729300984563693, 34.390517480071502, 0.0],
              [-80.72941151843699, 34.390380013749308, 0.0],
              [-80.730820584949868, 34.39033287950037, 0.0],
              [-80.731179584636763, 34.390195079851949, 0.0],
              [-80.731538518381043, 34.389942880227771, 0.0],
              [-80.731731518488601, 34.38966781336633, 0.0],
              [-80.731729718126488, 34.388155879695752, 0.0],
              [-80.731784185572536, 34.387606079537562, 0.0],
              [-80.732612185307431, 34.386712012895899, 0.0],
              [-80.732831718442924, 34.385543480046643, 0.0],
              [-80.733190518096137, 34.385222479779351, 0.0],
              [-80.733825985546588, 34.385199013326499, 0.0],
              [-80.735208984922707, 34.38641188000183, 0.0],
              [-80.735623385447425, 34.386411413199617, 0.0],
              [-80.735705985098491, 34.386159413092635, 0.0],
              [-80.735595384678163, 34.386022079577351, 0.0],
              [-80.735594785362977, 34.385678480123843, 0.0],
              [-80.736036517885438, 34.385288680317565, 0.0],
              [-80.736201784603651, 34.385013613420405, 0.0],
              [-80.73622898518137, 34.384624080401665, 0.0],
              [-80.735951718054451, 34.383822613722039, 0.0],
              [-80.735315585489232, 34.383365012885356, 0.0],
              [-80.733905985383359, 34.382931213026659, 0.0],
              [-80.733436318431487, 34.382908680425679, 0.0],
              [-80.733049584696587, 34.383000613315296, 0.0],
              [-80.732221385255613, 34.383574013702471, 0.0],
              [-80.731227185375005, 34.384010212828962, 0.0],
              [-80.729322518952387, 34.385432079813135, 0.0],
              [-80.728880585223521, 34.385569879669724, 0.0],
              [-80.728631919048397, 34.385524213755687, 0.0],
              [-80.72863138480794, 34.385157680337286, 0.0],
              [-80.729431918433505, 34.384492813313202, 0.0],
              [-80.729900717978651, 34.383690613471373, 0.0],
              [-80.729898918463419, 34.382178813356525, 0.0],
              [-80.730064384685903, 34.381995279809587, 0.0],
              [-80.731031385166702, 34.381971479967959, 0.0],
              [-80.731307384711712, 34.381719413120628, 0.0],
              [-80.731306584929655, 34.381032213171167, 0.0],
              [-80.731223518137327, 34.380917679552255, 0.0],
              [-80.730615184570894, 34.380574613772843, 0.0],
              [-80.730283718295155, 34.380574880461651, 0.0],
              [-80.729593318826176, 34.38087328026144, 0.0],
              [-80.72926191864704, 34.380942280258459, 0.0],
              [-80.729096118117894, 34.380896613560211, 0.0],
              [-80.728985384556111, 34.380759213042175, 0.0],
              [-80.72912238477484, 34.379865813115067, 0.0],
              [-80.729039519038352, 34.379751413298472, 0.0],
              [-80.728376119014769, 34.379477013281466, 0.0],
              [-80.725944918972147, 34.379547813301244, 0.0],
              [-80.725226184858002, 34.379227679605819, 0.0],
              [-80.725142718159034, 34.378632213362323, 0.0],
              [-80.725225318661671, 34.37847181350535, 0.0],
              [-80.72588791826665, 34.378035880088618, 0.0],
              [-80.726385118620357, 34.377943880065544, 0.0],
              [-80.727158984675029, 34.378241013029701, 0.0],
              [-80.727766918709946, 34.378286413298113, 0.0],
              [-80.728650519054085, 34.377873213784738, 0.0],
              [-80.729009185464307, 34.377598079989603, 0.0],
              [-80.729257518786184, 34.3771854799474, 0.0],
              [-80.729256984651286, 34.376910613339057, 0.0],
              [-80.728676718407357, 34.376727879967881, 0.0],
              [-80.72787571903342, 34.376934679990228, 0.0],
              [-80.727737518202815, 34.376797413421862, 0.0],
              [-80.727377518873297, 34.376018880237865, 0.0],
              [-80.727321785197745, 34.375652413504284, 0.0],
              [-80.727542584813435, 34.375468880383323, 0.0],
              [-80.728647184592887, 34.375147279861991, 0.0],
              [-80.728867985137299, 34.374963880403975, 0.0],
              [-80.72886771887886, 34.374689012965909, 0.0],
              [-80.728618784876716, 34.374483012970707, 0.0],
              [-80.727789785299649, 34.374300413333955, 0.0],
              [-80.727347518494355, 34.37407168026126, 0.0],
              [-80.726794918759168, 34.373957813780379, 0.0],
              [-80.72607711878959, 34.374485213408484, 0.0],
              [-80.72585618473407, 34.374508280076412, 0.0],
              [-80.725386118953963, 34.374142079736579, 0.0],
              [-80.72535811796817, 34.373821412897378, 0.0],
              [-80.726048184834397, 34.37333987967834, 0.0],
              [-80.726213318507774, 34.372789879892458, 0.0],
              [-80.725632518779705, 34.372240613399704, 0.0],
              [-80.72516278557616, 34.372126479516247, 0.0],
              [-80.724941985602172, 34.372332813348507, 0.0],
              [-80.72472178494958, 34.372997413071062, 0.0],
              [-80.724445585342011, 34.373043413432413, 0.0],
              [-80.724058518937611, 34.372814613359033, 0.0],
              [-80.724030384977311, 34.372242013606943, 0.0],
              [-80.723781385204774, 34.37203607982719, 0.0],
              [-80.723339518309388, 34.372082213303457, 0.0],
              [-80.723339785134101, 34.372357080406822, 0.0],
              [-80.723838785358382, 34.373960213615291, 0.0],
              [-80.723756185203214, 34.374097813281196, 0.0],
              [-80.723479918207829, 34.374189612980203, 0.0],
              [-80.72253978540769, 34.373365679796457, 0.0],
              [-80.722290518961032, 34.372884813651794, 0.0],
              [-80.722069385045742, 34.372747613010091, 0.0],
              [-80.721848584918291, 34.372908080192133, 0.0],
              [-80.721794784816325, 34.374145080384359, 0.0],
              [-80.721601985230933, 34.374740880449345, 0.0],
              [-80.721326184594346, 34.375153412995175, 0.0],
              [-80.720884917952176, 34.375818080363373, 0.0],
              [-80.72060938571974, 34.376505480242962, 0.0],
              [-80.720278385501075, 34.376895213070114, 0.0],
              [-80.719974718890242, 34.377032880146629, 0.0],
              [-80.719836318389568, 34.376872612959588, 0.0],
              [-80.719808184763252, 34.376483212932357, 0.0],
              [-80.720359319016396, 34.375154213068541, 0.0],
              [-80.720524585502844, 34.374741679827117, 0.0],
              [-80.720551785235074, 34.374398079722653, 0.0],
              [-80.720274985373251, 34.37394021290973, 0.0],
              [-80.720191718779972, 34.3735736131149, 0.0],
              [-80.720439584937495, 34.372725880013931, 0.0],
              [-80.720494318987662, 34.372244812978174, 0.0],
              [-80.720383518449353, 34.372061680338383, 0.0],
              [-80.719996385451182, 34.371741279944516, 0.0],
              [-80.720078584791978, 34.371122680178914, 0.0],
              [-80.719525718353779, 34.370779613671679, 0.0],
              [-80.719194184823323, 34.370779813695428, 0.0],
              [-80.718642119079249, 34.371101013531522, 0.0],
              [-80.718338118320034, 34.371055279732253, 0.0],
              [-80.71822751854755, 34.370895079629712, 0.0],
              [-80.718227184975859, 34.370574413167049, 0.0],
              [-80.718337385256206, 34.370368080049388, 0.0],
              [-80.718999984837254, 34.370001080475532, 0.0],
              [-80.719745517971788, 34.369679880313797, 0.0],
              [-80.720159718353429, 34.369587880316381, 0.0],
              [-80.720353185204104, 34.369679279676717, 0.0],
              [-80.72093418482055, 34.370411879978214, 0.0],
              [-80.721210585096699, 34.370526213049303, 0.0],
              [-80.721680318808694, 34.370571679528439, 0.0],
              [-80.722066718207117, 34.37034228017734, 0.0],
              [-80.722314985668675, 34.370021413654058, 0.0],
              [-80.722148985447859, 34.369769480451168, 0.0],
              [-80.721541118306476, 34.369655479534735, 0.0],
              [-80.721043384894202, 34.369289413221814, 0.0],
              [-80.720877385194541, 34.369014479858308, 0.0],
              [-80.720821518692887, 34.368373213081462, 0.0],
              [-80.720655718959947, 34.368281680300377, 0.0],
              [-80.719965318037211, 34.368488480265981, 0.0],
              [-80.719771785575659, 34.368374079838709, 0.0],
              [-80.719798919073895, 34.367938813796307, 0.0],
              [-80.719467118770055, 34.367641279714135, 0.0],
              [-80.718970118741439, 34.367893679639195, 0.0],
              [-80.718666718163078, 34.368260412879231, 0.0],
              [-80.718307785659348, 34.368421013439729, 0.0],
              [-80.717893384612751, 34.368421279527901, 0.0],
              [-80.717782719056217, 34.368261080064954, 0.0],
              [-80.717726985578096, 34.367848813212731, 0.0],
              [-80.717505584930407, 34.367482413062064, 0.0],
              [-80.717449385334305, 34.366589079604779, 0.0],
              [-80.716924318100553, 34.366429213742791, 0.0],
              [-80.716676118370714, 34.36670428011702, 0.0],
              [-80.716648585376063, 34.36684181319302, 0.0],
              [-80.716041717987977, 34.367621013098628, 0.0],
              [-80.715710518498597, 34.367850412829668, 0.0],
              [-80.715102718442168, 34.367896680061065, 0.0],
              [-80.714909718100543, 34.368148679555752, 0.0],
              [-80.714882917887365, 34.36901921288932, 0.0],
              [-80.71444098573906, 34.36906541328004, 0.0],
              [-80.714026584759779, 34.368928280069831, 0.0],
              [-80.713805184969004, 34.368676479676779, 0.0],
              [-80.713666717970582, 34.368287079759085, 0.0],
              [-80.713417785618404, 34.367989479585042, 0.0],
              [-80.713030985458261, 34.367852413718744, 0.0],
              [-80.712727118173007, 34.36785261352955, 0.0],
              [-80.712312918802624, 34.368082013067919, 0.0],
              [-80.711954185380534, 34.368448679646583, 0.0],
              [-80.711954584581022, 34.368815213388487, 0.0],
              [-80.712065318368801, 34.36909007979559, 0.0],
              [-80.712562984637657, 34.36952488017122, 0.0],
              [-80.713779184977312, 34.370280013315174, 0.0],
              [-80.713889784887726, 34.370440279545249, 0.0],
              [-80.713696785127183, 34.370761012868542, 0.0],
              [-80.713089118873853, 34.370830279924917, 0.0],
              [-80.71261971813891, 34.37096807983783, 0.0],
              [-80.712150518866792, 34.371426613036881, 0.0],
              [-80.711487385511987, 34.37135827976541, 0.0],
              [-80.711321784642465, 34.371450080439843, 0.0],
              [-80.711267185009675, 34.372206079996268, 0.0],
              [-80.711738718363378, 34.374038279683276, 0.0],
              [-80.711821718915999, 34.374129880049139, 0.0],
              [-80.711822185260587, 34.374588013167802, 0.0],
              [-80.711711785578274, 34.37472561296395, 0.0],
              [-80.711380385094955, 34.374794479694103, 0.0],
              [-80.711048518412611, 34.374428279957087, 0.0],
              [-80.710826718216751, 34.373695413444629, 0.0],
              [-80.710494718241549, 34.37314581370557, 0.0],
              [-80.709914318277157, 34.37289421310637, 0.0],
              [-80.709582918882219, 34.373009079710847, 0.0],
              [-80.709334518317533, 34.373192479916796, 0.0],
              [-80.709113784590386, 34.373559213662219, 0.0],
              [-80.709004118158944, 34.374315079529659, 0.0],
              [-80.708866118762884, 34.374544279970408, 0.0],
              [-80.707761184675959, 34.374613813148741, 0.0],
              [-80.707760185403004, 34.374600813008541, 0.0],
              [-80.707733118526932, 34.374224413199123, 0.0],
              [-80.708063385043985, 34.372987212856138, 0.0],
              [-80.708228918441208, 34.372735079885899, 0.0],
              [-80.708974518026579, 34.372368080077152, 0.0],
              [-80.709333318468651, 34.372047080191386, 0.0],
              [-80.709746985368525, 34.371359613656132, 0.0],
              [-80.709746185652335, 34.370489079579983, 0.0],
              [-80.709635584845486, 34.370351813026275, 0.0],
              [-80.708999984883917, 34.37012321365934, 0.0],
              [-80.708834118602525, 34.3699400132368, 0.0],
              [-80.708806318388667, 34.36971101380216, 0.0],
              [-80.70971731891342, 34.369183413766159, 0.0],
              [-80.710048518306408, 34.368839612984637, 0.0],
              [-80.710075918218976, 34.36865627998823, 0.0],
              [-80.709965184707528, 34.368450213769975, 0.0],
              [-80.709136118647763, 34.368107212850852, 0.0],
              [-80.70662251816492, 34.368223480027062, 0.0],
              [-80.706511918489824, 34.368132013181956, 0.0],
              [-80.706594318939665, 34.367742413009644, 0.0],
              [-80.70717418503304, 34.367467079746987, 0.0],
              [-80.70758851871021, 34.367375213174448, 0.0],
              [-80.709632584603554, 34.367213412935513, 0.0],
              [-80.710571184795157, 34.366685879597014, 0.0],
              [-80.711150785748444, 34.366227280181, 0.0],
              [-80.711481584887593, 34.365379613173005, 0.0],
              [-80.711702318597574, 34.365150280395355, 0.0],
              [-80.71255818573951, 34.364760213474035, 0.0],
              [-80.712778985407851, 34.36453101290185, 0.0],
              [-80.712861718252427, 34.364301880297411, 0.0],
              [-80.712723185667457, 34.364027080047812, 0.0],
              [-80.712336184687558, 34.363752480396435, 0.0],
              [-80.711977184647125, 34.363844413023934, 0.0],
              [-80.710983384929392, 34.364486479936218, 0.0],
              [-80.710348185336059, 34.364578613530917, 0.0],
              [-80.710071718049377, 34.364395479689023, 0.0],
              [-80.71007151888935, 34.364097680132026, 0.0],
              [-80.710899718151694, 34.363661880395732, 0.0],
              [-80.71136891893498, 34.363226280285211, 0.0],
              [-80.711368585361384, 34.362928479661122, 0.0],
              [-80.711174918843824, 34.362653813788562, 0.0],
              [-80.710345918284418, 34.362287812984292, 0.0],
              [-80.709406785579873, 34.362334413490053, 0.0],
              [-80.709102785051584, 34.362105479563169, 0.0],
              [-80.708881585512572, 34.361830813506749, 0.0],
              [-80.708522585163749, 34.361968480053335, 0.0],
              [-80.708357185384315, 34.362403813462684, 0.0],
              [-80.70810891857829, 34.362610079574914, 0.0],
              [-80.707860318277554, 34.36263321315343, 0.0],
              [-80.707749585286123, 34.362518680029744, 0.0],
              [-80.707638718950477, 34.36199201357077, 0.0],
              [-80.707169118053926, 34.361877813447123, 0.0],
              [-80.706589385474629, 34.362359079998122, 0.0],
              [-80.706119918053517, 34.362428279928714, 0.0],
              [-80.705125118404183, 34.362039479654108, 0.0],
              [-80.704075518359602, 34.361971413135585, 0.0],
              [-80.703495584712755, 34.36222381313722, 0.0],
              [-80.702446784990158, 34.363232479506905, 0.0],
              [-80.70208778551698, 34.363301413543866, 0.0],
              [-80.701673318733953, 34.363003880082736, 0.0],
              [-80.701452184716871, 34.36295821361221, 0.0],
              [-80.701203718209385, 34.363050013800894, 0.0],
              [-80.700872585558983, 34.363393879545306, 0.0],
              [-80.700541584778335, 34.36405828028834, 0.0],
              [-80.700486518896739, 34.364310413805249, 0.0],
              [-80.700155518357192, 34.364814479897916, 0.0],
              [-80.699934518222719, 34.364906279975919, 0.0],
              [-80.699575185039237, 34.364540012902062, 0.0],
              [-80.69960238480796, 34.364058879729157, 0.0],
              [-80.700071385386764, 34.363279813153056, 0.0],
              [-80.700070918928446, 34.362661279860824, 0.0],
              [-80.699600785335761, 34.362134813595659, 0.0],
              [-80.699600717930096, 34.361974279770664, 0.0],
              [-80.70208631887995, 34.361560480451786, 0.0],
              [-80.702445119033683, 34.361331080421543, 0.0],
              [-80.702582985090473, 34.361079079607023, 0.0],
              [-80.702637585191567, 34.36034601325229, 0.0],
              [-80.703051785596983, 34.360162480175184, 0.0],
              [-80.703548718336009, 34.359818479581051, 0.0],
              [-80.703852118055309, 34.359383080432131, 0.0],
              [-80.703879384575032, 34.359039479698744, 0.0],
              [-80.703713384713268, 34.358696013266034, 0.0],
              [-80.703436984766554, 34.358490013370236, 0.0],
              [-80.7030225848588, 34.358329880358916, 0.0],
              [-80.702552985307918, 34.358330213393657, 0.0],
              [-80.70191798549034, 34.358605479808361, 0.0],
              [-80.701448585256003, 34.358720413722658, 0.0],
              [-80.701337918908209, 34.358605879890142, 0.0],
              [-80.701448118480769, 34.358285080216056, 0.0],
              [-80.701834585031833, 34.358032813219275, 0.0],
              [-80.702773385406516, 34.357757413320414, 0.0],
              [-80.703077118801531, 34.357573879641144, 0.0],
              [-80.703352785540858, 34.356978080104803, 0.0],
              [-80.703545918253468, 34.356749013283903, 0.0],
              [-80.704401718973855, 34.356244480063786, 0.0],
              [-80.70440138566066, 34.355900879583551, 0.0],
              [-80.704097118053525, 34.355442880141531, 0.0],
              [-80.704179585008774, 34.355053280197808, 0.0],
              [-80.70453858504591, 34.354961480148681, 0.0],
              [-80.705394984802084, 34.355121280287747, 0.0],
              [-80.705946919055123, 34.354662812951446, 0.0],
              [-80.706057318131101, 34.354456613115381, 0.0],
              [-80.706995718886347, 34.35370001375896, 0.0],
              [-80.707216318333025, 34.353402013737536, 0.0],
              [-80.707271318185306, 34.353172880300335, 0.0],
              [-80.706884317999283, 34.352783813104679, 0.0],
              [-80.706166118410209, 34.352623880367318, 0.0],
              [-80.705613918079024, 34.352830413374726, 0.0],
              [-80.704951118050104, 34.352922479564356, 0.0],
              [-80.704840519010617, 34.352808013249721, 0.0],
              [-80.704867718087399, 34.35237281359209, 0.0],
              [-80.705088518714376, 34.352189279709023, 0.0],
              [-80.705668318107897, 34.351959880360518, 0.0],
              [-80.706745318661916, 34.351798813538423, 0.0],
              [-80.706855585492434, 34.351707079842271, 0.0],
              [-80.706772584627487, 34.351500880407556, 0.0],
              [-80.706468585146325, 34.351340879625745, 0.0],
              [-80.705805718270355, 34.351318280452205, 0.0],
              [-80.704397718756439, 34.351869080407901, 0.0],
              [-80.704011118666514, 34.351869412870734, 0.0],
              [-80.703817585745099, 34.351754880175285, 0.0],
              [-80.703817184802446, 34.351251013551959, 0.0],
              [-80.704479118030278, 34.35033421349074, 0.0],
              [-80.705803718872929, 34.349027680366639, 0.0],
              [-80.706079384965548, 34.348431880338751, 0.0],
              [-80.705995985235162, 34.34792801378569, 0.0],
              [-80.705636784845197, 34.347676213495973, 0.0],
              [-80.704973985385379, 34.347722412868386, 0.0],
              [-80.704808118978377, 34.347562213350713, 0.0],
              [-80.704834585211231, 34.346371079952974, 0.0],
              [-80.704613185546819, 34.345821479661964, 0.0],
              [-80.703755918243942, 34.344470412904101, 0.0],
              [-80.702899185543899, 34.343898280175452, 0.0],
              [-80.701738918008203, 34.343418080377184, 0.0],
              [-80.701517718110807, 34.343189213514755, 0.0],
              [-80.701213518038159, 34.342639480363054, 0.0],
              [-80.700798318794455, 34.341540213656522, 0.0],
              [-80.70025642396935, 34.340373945427459, 0.0],
              [-80.700149080557097, 34.339824064181428, 0.0],
              [-80.700080199381262, 34.339321117194409, 0.0],
              [-80.699897549668137, 34.338903588693192, 0.0],
              [-80.699736799564107, 34.338632614716751, 0.0],
              [-80.699719955268378, 34.338606909201054, 0.0],
              [-80.70090531901711, 34.337439679715487, 0.0],
              [-80.701051518132374, 34.337265813124027, 0.0],
              [-80.701925985084344, 34.336225013763823, 0.0],
              [-80.703196119021925, 34.336178280210049, 0.0],
              [-80.704686184733035, 34.335054813766156, 0.0],
              [-80.705734785424625, 34.334183680112211, 0.0],
              [-80.706369118652844, 34.333358480288439, 0.0],
              [-80.707031185674694, 34.332831279545118, 0.0],
              [-80.707390185217974, 34.332854013470019, 0.0],
              [-80.707721985696281, 34.33324321344832, 0.0],
              [-80.707832785481529, 34.333655413782999, 0.0],
              [-80.708191984651222, 34.333930013247368, 0.0],
              [-80.709158784581405, 34.33427301375481, 0.0],
              [-80.709297118495357, 34.334456080361996, 0.0],
              [-80.709214518789238, 34.334685213079581, 0.0],
              [-80.709104184974322, 34.334822813592332, 0.0],
              [-80.708193319041385, 34.335281479545777, 0.0],
              [-80.707200118439829, 34.336198479752689, 0.0],
              [-80.707089784602758, 34.336427680012818, 0.0],
              [-80.707173184700864, 34.336977413512251, 0.0],
              [-80.7078917851765, 34.337641213075997, 0.0],
              [-80.708361518578485, 34.337915813526678, 0.0],
              [-80.709990918803399, 34.338120813429988, 0.0],
              [-80.710819718100922, 34.338349279786904, 0.0],
              [-80.711288984608217, 34.338280280532466, 0.0],
              [-80.711619985579318, 34.337890479777947, 0.0],
              [-80.711398118577037, 34.336882813282635, 0.0],
              [-80.711397384850599, 34.336218480100051, 0.0],
              [-80.711562784920332, 34.335851813198033, 0.0],
              [-80.711976718428303, 34.335484880296562, 0.0],
              [-80.712280385340961, 34.335530613187132, 0.0],
              [-80.71242011879427, 34.337179813107092, 0.0],
              [-80.71255858469263, 34.337569213334788, 0.0],
              [-80.713691918383518, 34.33864488048129, 0.0],
              [-80.713664517925451, 34.338896879552451, 0.0],
              [-80.713305718816045, 34.33912627994841, 0.0],
              [-80.712311518617199, 34.338989613616683, 0.0],
              [-80.711206984753986, 34.339082013057393, 0.0],
              [-80.710848118656088, 34.339219813085144, 0.0],
              [-80.710737918799467, 34.339403013279131, 0.0],
              [-80.710765918818979, 34.339815280184602, 0.0],
              [-80.712121318413949, 34.342128012981917, 0.0],
              [-80.712342918628011, 34.342769279584438, 0.0],
              [-80.712702385600466, 34.343227213400908, 0.0],
              [-80.713061717950467, 34.343524680406752, 0.0],
              [-80.713503517927705, 34.343524412888996, 0.0],
              [-80.713889917981646, 34.343249213007454, 0.0],
              [-80.714193318560504, 34.342859613826164, 0.0],
              [-80.714496918782345, 34.342744813548563, 0.0],
              [-80.715021785473226, 34.342973413145081, 0.0],
              [-80.715408785313969, 34.343339680088384, 0.0],
              [-80.715491918483536, 34.343545813426587, 0.0],
              [-80.715465184799257, 34.344530879999013, 0.0],
              [-80.715990318518521, 34.344897013234849, 0.0],
              [-80.716819184958993, 34.345262880298044, 0.0],
              [-80.717040118859998, 34.345239813502189, 0.0],
              [-80.71745378494704, 34.344735479842257, 0.0],
              [-80.717729984870175, 34.344758080173818, 0.0],
              [-80.71808951890911, 34.34517021380821, 0.0],
              [-80.718117318780926, 34.345284680465141, 0.0],
              [-80.718448584763735, 34.345238680387084, 0.0],
              [-80.718696785690554, 34.344917813411278, 0.0],
              [-80.718724118338656, 34.344688679993588, 0.0],
              [-80.71891711797025, 34.344413679560532, 0.0],
              [-80.719193184691363, 34.344344813645542, 0.0],
              [-80.719497318199515, 34.344550613082632, 0.0],
              [-80.719526185066229, 34.345764680179251, 0.0],
              [-80.719747518359782, 34.346131013839418, 0.0],
              [-80.721294118586002, 34.346152680328956, 0.0],
              [-80.721515184811835, 34.346267079877897, 0.0],
              [-80.721515717966611, 34.346656479589413, 0.0],
              [-80.721018785542796, 34.346886013309572, 0.0],
              [-80.720107718121014, 34.347047079644426, 0.0],
              [-80.718008785381684, 34.347094479570337, 0.0],
              [-80.717649918928259, 34.347255080129514, 0.0],
              [-80.717539784899913, 34.34753008022205, 0.0],
              [-80.717540385125432, 34.348125813304286, 0.0],
              [-80.718204518564519, 34.349408013564847, 0.0],
              [-80.718811985285058, 34.349270213404111, 0.0],
              [-80.719613118124727, 34.349498479680271, 0.0],
              [-80.71955798560694, 34.34965901362655, 0.0],
              [-80.718840518925859, 34.350140612979551, 0.0],
              [-80.718896318868957, 34.350621613278165, 0.0],
              [-80.719227985562895, 34.350896079830292, 0.0],
              [-80.719918584944082, 34.351078880420239, 0.0],
              [-80.720581518917285, 34.351009680064813, 0.0],
              [-80.721050584945317, 34.350642813102745, 0.0],
              [-80.721353585311974, 34.350001079694977, 0.0],
              [-80.721380384908542, 34.349222279839488, 0.0],
              [-80.721766585427602, 34.348832480455528, 0.0],
              [-80.722456585406178, 34.348419613465111, 0.0],
              [-80.72262231824098, 34.348419413668978, 0.0],
              [-80.722898718108937, 34.348648279866346, 0.0],
              [-80.722927385190502, 34.349518680066069, 0.0],
              [-80.722320318316221, 34.350046212858743, 0.0],
              [-80.721989918262452, 34.350916879950674, 0.0],
              [-80.722239785469398, 34.352199479690029, 0.0],
              [-80.722544118617165, 34.352634480121637, 0.0],
              [-80.722378718396811, 34.352909613326837, 0.0],
              [-80.721577718785085, 34.352887280357372, 0.0],
              [-80.721329385022116, 34.353024880158372, 0.0],
              [-80.72132978527479, 34.353414280081822, 0.0],
              [-80.721412785152722, 34.353505813273017, 0.0],
              [-80.721385318124931, 34.353689213125143, 0.0],
              [-80.721274984636523, 34.353780880464363, 0.0],
              [-80.720915918045208, 34.35378121381779, 0.0],
              [-80.719672585585954, 34.353369813488911, 0.0],
              [-80.718871718835942, 34.353439080225144, 0.0],
              [-80.718733785669741, 34.353553813387911, 0.0],
              [-80.718789317901795, 34.353943213787232, 0.0],
              [-80.71914891840369, 34.35435521310815, 0.0],
              [-80.719397718685471, 34.354515413794559, 0.0],
              [-80.719868119066007, 34.355362680237882, 0.0],
              [-80.719978985184724, 34.355752013330772, 0.0],
              [-80.720200318150518, 34.356072480316563, 0.0],
              [-80.720476718013387, 34.356186813223481, 0.0],
              [-80.720918517910661, 34.356117813066, 0.0],
              [-80.721305118731664, 34.355957013341595, 0.0],
              [-80.721526118111001, 34.355979680020695, 0.0],
              [-80.721940585608806, 34.356254279785404, 0.0],
              [-80.722632585663746, 34.357605413171022, 0.0],
              [-80.722881517960616, 34.357903013623741, 0.0],
              [-80.723821384678971, 34.358566479791691, 0.0],
              [-80.724429985016016, 34.359344812994401, 0.0],
              [-80.724899985369078, 34.359710880019854, 0.0],
              [-80.725452519007419, 34.359939479616123, 0.0],
              [-80.726032718598077, 34.359984813794682, 0.0],
              [-80.726640185675365, 34.359892679571423, 0.0],
              [-80.726998985111862, 34.359548813408324, 0.0],
              [-80.727108984848527, 34.359067612942191, 0.0],
              [-80.726887184577336, 34.358472280326041, 0.0],
              [-80.726914718148834, 34.358334813794201, 0.0],
              [-80.727466917940802, 34.358196813512869, 0.0],
              [-80.728847785156773, 34.358058213306194, 0.0],
              [-80.728985985029084, 34.358126880101999, 0.0],
              [-80.728958784610143, 34.358447612912364, 0.0],
              [-80.72818611819477, 34.359020879586353, 0.0],
              [-80.727882784604958, 34.359456413339032, 0.0],
              [-80.727690585687668, 34.36046447954331, 0.0],
              [-80.727525518277773, 34.36106021373152, 0.0],
              [-80.727525918565746, 34.361403879878473, 0.0],
              [-80.727664185606756, 34.361518279858323, 0.0],
              [-80.728299585653531, 34.361586413788771, 0.0],
              [-80.728603718780164, 34.361769413267396, 0.0],
              [-80.729654584973702, 34.362845079922586, 0.0],
              [-80.729875518132886, 34.362799213544349, 0.0],
              [-80.730068718167345, 34.362592813314016, 0.0],
              [-80.730095517991501, 34.361997212901507, 0.0],
              [-80.730288785305035, 34.361859679553284, 0.0],
              [-80.73050978529173, 34.361882279590873, 0.0],
              [-80.730675584638803, 34.361996679724783, 0.0],
              [-80.730842585340767, 34.362958679791319, 0.0],
              [-80.731146785032479, 34.363302013318361, 0.0],
              [-80.731395385171083, 34.363416279684756, 0.0],
              [-80.731837318875378, 34.363347212998548, 0.0],
              [-80.732113118875731, 34.362957613475132, 0.0],
              [-80.732195118967397, 34.362178613791663, 0.0],
              [-80.73227771861778, 34.362018079647839, 0.0],
              [-80.733133385214956, 34.361536413456655, 0.0],
              [-80.733988585660228, 34.360665080415401, 0.0],
              [-80.7344855185695, 34.360458413747267, 0.0],
              [-80.734678717995337, 34.36029801378519, 0.0],
              [-80.734843984938749, 34.359954213738675, 0.0],
              [-80.735258318041588, 34.359976679656917, 0.0],
              [-80.735976985092236, 34.360365479764745, 0.0],
              [-80.736060118605877, 34.360594480295902, 0.0],
              [-80.734735518311538, 34.361466213549413, 0.0],
              [-80.734735918936579, 34.361878479998971, 0.0],
              [-80.734818918418043, 34.361947079802647, 0.0],
              [-80.734819385328862, 34.362336613218226, 0.0],
              [-80.73462651823877, 34.362749079590728, 0.0],
              [-80.733854318116826, 34.363780613100211, 0.0],
              [-80.733441317907079, 34.364811879904494, 0.0],
              [-80.733525184551169, 34.365613480432565, 0.0],
              [-80.734686785162367, 34.366780679526983, 0.0],
              [-80.735682118765411, 34.367421213785569, 0.0],
              [-80.735958318321337, 34.367375079606987, 0.0],
              [-80.736178785039428, 34.3670084129758, 0.0],
              [-80.73639971864425, 34.366962480367526, 0.0],
              [-80.736675984521298, 34.367008013078667, 0.0],
              [-80.738085785351331, 34.367716813234708, 0.0],
              [-80.738472585629509, 34.367831013324434, 0.0],
              [-80.738997384944355, 34.36769301293257, 0.0],
              [-80.73899711821592, 34.367441013540173, 0.0],
              [-80.737918984914387, 34.366915080137538, 0.0],
              [-80.737835585359804, 34.366548680274406, 0.0],
              [-80.738084118517506, 34.366388080303729, 0.0],
              [-80.73929958453553, 34.366524413042065, 0.0],
              [-80.739465718598723, 34.36673047989656, 0.0],
              [-80.739604518984606, 34.367280079840633, 0.0],
              [-80.73979818470869, 34.367509013127879, 0.0],
              [-80.740240118423529, 34.367462680305245, 0.0],
              [-80.740294985340086, 34.367233680114964, 0.0],
              [-80.740045984917842, 34.366936079731403, 0.0],
              [-80.739905918703585, 34.365309813625018, 0.0],
              [-80.73962871836568, 34.364599879993662, 0.0],
              [-80.739627985291619, 34.364118879814676, 0.0],
              [-80.739986517876048, 34.363660413434459, 0.0],
              [-80.73993058498462, 34.363156412922201, 0.0],
              [-80.739929318352893, 34.362240213473108, 0.0],
              [-80.740039718920698, 34.362102613540067, 0.0],
              [-80.740260518757822, 34.362033679964661, 0.0],
              [-80.740453985347912, 34.362193813554775, 0.0],
              [-80.740676118172956, 34.362972479810558, 0.0],
              [-80.741205985163788, 34.366797480261852, 0.0],
              [-80.741399518345546, 34.366888880385673, 0.0],
              [-80.741703318022857, 34.366797013121534, 0.0],
              [-80.741896317897954, 34.366544813333441, 0.0],
              [-80.742115184982325, 34.364986880035367, 0.0],
              [-80.742335185096877, 34.364230813705838, 0.0],
              [-80.742638718184836, 34.364001413315471, 0.0],
              [-80.743052785096211, 34.363863613504201, 0.0],
              [-80.744212784724326, 34.363702080410043, 0.0],
              [-80.744433584588748, 34.363541413264308, 0.0],
              [-80.74487458478211, 34.36285387961977, 0.0],
              [-80.745067985097805, 34.362876613455896, 0.0],
              [-80.745289318125486, 34.363128280054404, 0.0],
              [-80.745511318397092, 34.363792412936533, 0.0],
              [-80.744931718842409, 34.364228213607618, 0.0],
              [-80.744352118423933, 34.364503613482718, 0.0],
              [-80.74377271830042, 34.365008213726995, 0.0],
              [-80.743442318828556, 34.365879012892613, 0.0],
              [-80.743470918834575, 34.36656621283705, 0.0],
              [-80.743996318627751, 34.366932213166635, 0.0],
              [-80.744051918987083, 34.367184079535122, 0.0],
              [-80.743969118190492, 34.3672758799908, 0.0],
              [-80.743195785492929, 34.367368213194425, 0.0],
              [-80.742836918126642, 34.367529013389884, 0.0],
              [-80.742643785063933, 34.367781079506699, 0.0],
              [-80.742561385690365, 34.368124813402943, 0.0],
              [-80.742589718061197, 34.368605879966445, 0.0],
              [-80.742838718241941, 34.368880479706981, 0.0],
              [-80.745242384885316, 34.369198813714107, 0.0],
              [-80.746844584787681, 34.369151412842683, 0.0],
              [-80.747369118494618, 34.368921813411859, 0.0],
              [-80.748638518762888, 34.367912479977164, 0.0],
              [-80.748997518504083, 34.367843412938626, 0.0],
              [-80.749273985435792, 34.368026412981834, 0.0],
              [-80.749357317868615, 34.368278280419212, 0.0],
              [-80.749109185069187, 34.368690880247456, 0.0],
              [-80.748833184824221, 34.368897280178082, 0.0],
              [-80.748667784949646, 34.369149480003585, 0.0],
              [-80.748668317869914, 34.369516013072356, 0.0],
              [-80.748945118264672, 34.369859413176329, 0.0],
              [-80.74894551899807, 34.370111413068564, 0.0],
              [-80.748780318795852, 34.370478013057017, 0.0],
              [-80.748808585491432, 34.371004813506282, 0.0],
              [-80.749306585125495, 34.371439480049823, 0.0],
              [-80.749389585443595, 34.371622813508665, 0.0],
              [-80.749169518410156, 34.372287279496817, 0.0],
              [-80.749170185497846, 34.372745413442892, 0.0],
              [-80.749308718750029, 34.372951413731087, 0.0],
              [-80.749805985595188, 34.37301961325646, 0.0],
              [-80.75076358489865, 34.373477213570773, 0.0],
              [-80.75134371792376, 34.37356941368482, 0.0],
              [-80.752144785653257, 34.373570213652279, 0.0],
              [-80.753057118577331, 34.373135880428421, 0.0],
              [-80.753665518357181, 34.372655413124519, 0.0],
              [-80.753555584539029, 34.372334613515065, 0.0],
              [-80.753141918277706, 34.371898880133337, 0.0],
              [-80.752285917917106, 34.371646080043732, 0.0],
              [-80.751263718895103, 34.371645079614467, 0.0],
              [-80.751208918983565, 34.371347280196368, 0.0],
              [-80.751485385049179, 34.371164280025404, 0.0],
              [-80.752673518577069, 34.371028013057526, 0.0],
              [-80.75303291818058, 34.37091381312019, 0.0],
              [-80.753254184727723, 34.370616279655088, 0.0],
              [-80.753255585062021, 34.369700013744406, 0.0],
              [-80.753614718886254, 34.369700212964531, 0.0],
              [-80.753862317843002, 34.370433613062879, 0.0],
              [-80.754165584798514, 34.370800413599532, 0.0],
              [-80.754469385021736, 34.370869479612672, 0.0],
              [-80.754856118770775, 34.370892679789684, 0.0],
              [-80.75513251874581, 34.370801279735261, 0.0],
              [-80.756017317864135, 34.370252412876091, 0.0],
              [-80.756625785665236, 34.369703213450343, 0.0],
              [-80.757041385449696, 34.368810279680737, 0.0],
              [-80.758036518016937, 34.368444679791381, 0.0],
              [-80.75831251862455, 34.36862807960172, 0.0],
              [-80.758284318461193, 34.368971813641082, 0.0],
              [-80.757647584667254, 34.36991041338004, 0.0],
              [-80.757563985105833, 34.370483012978902, 0.0],
              [-80.757646118552387, 34.370964079833875, 0.0],
              [-80.757866985589189, 34.371078812869591, 0.0],
              [-80.759165384516976, 34.371034279537916, 0.0],
              [-80.759303718274495, 34.370942680416512, 0.0],
              [-80.759414518085379, 34.370713813654028, 0.0],
              [-80.759332317956222, 34.370278479718721, 0.0],
              [-80.759886318405577, 34.369133612828151, 0.0],
              [-80.760162784669035, 34.368996480024407, 0.0],
              [-80.760521385597485, 34.369340413551804, 0.0],
              [-80.760575385560131, 34.370325479582419, 0.0],
              [-80.76071291804071, 34.37071501305266, 0.0],
              [-80.760850918114514, 34.370852613142773, 0.0],
              [-80.761403185351412, 34.370899013161065, 0.0],
              [-80.762702784931477, 34.370029612882725, 0.0],
              [-80.763117385358171, 34.369915413239816, 0.0],
              [-80.763255318557128, 34.370007213577864, 0.0],
              [-80.763282584834599, 34.370282080468016, 0.0],
              [-80.762895185279135, 34.370808679742119, 0.0],
              [-80.763005118628797, 34.37124401359484, 0.0],
              [-80.7631431181712, 34.371335813128454, 0.0],
              [-80.763474518365285, 34.371381879510963, 0.0],
              [-80.765464318727624, 34.370810879972794, 0.0],
              [-80.765630384533395, 34.370582013107231, 0.0],
              [-80.765686184955172, 34.37014681346588, 0.0],
              [-80.765575985556538, 34.369871813438095, 0.0],
              [-80.765659584638129, 34.369413813001941, 0.0],
              [-80.765991118424694, 34.369368280239378, 0.0],
              [-80.766294585354899, 34.3696204797345, 0.0],
              [-80.766349585083404, 34.36984967957504, 0.0],
              [-80.76643238477341, 34.369895480370701, 0.0],
              [-80.766513985403407, 34.370926413159992, 0.0],
              [-80.76662398477319, 34.371247213399933, 0.0],
              [-80.766761985196993, 34.37136181309301, 0.0],
              [-80.768502318530892, 34.371455012937894, 0.0],
              [-80.768695984962477, 34.371226080293397, 0.0],
              [-80.768365385459191, 34.370492813034318, 0.0],
              [-80.767730718206664, 34.369965413001125, 0.0],
              [-80.767069985049162, 34.368246813197985, 0.0],
              [-80.767098318268978, 34.367719879587085, 0.0],
              [-80.767157184688955, 34.367646813460887, 0.0],
              [-80.767208918845157, 34.367582479940111, 0.0],
              [-80.767595717861468, 34.367628679908115, 0.0],
              [-80.768091784857376, 34.36847667999416, 0.0],
              [-80.768671118249344, 34.369164413004228, 0.0],
              [-80.769112584830779, 34.369439679729958, 0.0],
              [-80.769554585158531, 34.369531679688073, 0.0],
              [-80.770272918243236, 34.369417679519621, 0.0],
              [-80.770548785278677, 34.369715679933641, 0.0],
              [-80.770935117888342, 34.369991013505931, 0.0],
              [-80.771045385228007, 34.370151413055439, 0.0],
              [-80.77098951841505, 34.370678279816183, 0.0],
              [-80.771071985198532, 34.370999012848308, 0.0],
              [-80.77134811883866, 34.371090879839599, 0.0],
              [-80.772038785620069, 34.371091479726097, 0.0],
              [-80.772729917807169, 34.370679679820817, 0.0],
              [-80.773449185537658, 34.369970213581574, 0.0],
              [-80.773670584527267, 34.369558079990895, 0.0],
              [-80.773699118471058, 34.368870880457273, 0.0],
              [-80.773037185075168, 34.367999880173997, 0.0],
              [-80.772126518213753, 34.367220213121669, 0.0],
              [-80.772126985070187, 34.366876613257837, 0.0],
              [-80.772486117796987, 34.366785212877907, 0.0],
              [-80.773121384880852, 34.366877413276441, 0.0],
              [-80.773618385660939, 34.367061080391373, 0.0],
              [-80.774501785597153, 34.367565813252732, 0.0],
              [-80.775136918948718, 34.367726679493238, 0.0],
              [-80.775578984823099, 34.367635413521903, 0.0],
              [-80.775938584913206, 34.367269080029722, 0.0],
              [-80.776023718960658, 34.365459613693879, 0.0],
              [-80.776327917952017, 34.365162013578747, 0.0],
              [-80.776682585350656, 34.365107079599888, 0.0],
              [-80.776769918599754, 34.365093613415432, 0.0],
              [-80.77701751872327, 34.366033013112016, 0.0],
              [-80.777016385491876, 34.366857679746495, 0.0],
              [-80.776240918160923, 34.368552212969583, 0.0],
              [-80.776240518565558, 34.368918812850225, 0.0],
              [-80.776350918048422, 34.369010479904908, 0.0],
              [-80.776931318344012, 34.36880467979389, 0.0],
              [-80.77729011785874, 34.369079879791251, 0.0],
              [-80.777510518669118, 34.36951541331738, 0.0],
              [-80.777841784725055, 34.369698879676342, 0.0],
              [-80.778339185090545, 34.369607613621909, 0.0],
              [-80.779325718124255, 34.368963080386344, 0.0],
              [-80.779389718902337, 34.36892121346844, 0.0],
              [-80.779831585065409, 34.368990279525981, 0.0],
              [-80.779858785078702, 34.369402613787791, 0.0],
              [-80.779609784782124, 34.369700213482822, 0.0],
              [-80.778669584667242, 34.370432613608372, 0.0],
              [-80.777619317844511, 34.370935613387367, 0.0],
              [-80.776817984485348, 34.371026680126626, 0.0],
              [-80.775437517918732, 34.370384079868003, 0.0],
              [-80.774912585293904, 34.37047528017812, 0.0],
              [-80.774470318383081, 34.370704013745595, 0.0],
              [-80.774193585399445, 34.370978679734968, 0.0],
              [-80.774082784805927, 34.37127641285025, 0.0],
              [-80.774054518014452, 34.371895013220715, 0.0],
              [-80.774164385636652, 34.37235307990543, 0.0],
              [-80.774412584673541, 34.372674079996514, 0.0],
              [-80.775295985096307, 34.373247479750262, 0.0],
              [-80.775710118631252, 34.373408080384898, 0.0],
              [-80.776069384910102, 34.373316813619532, 0.0],
              [-80.776511918431368, 34.3729736134622, 0.0],
              [-80.776705318823176, 34.372973679846773, 0.0],
              [-80.776926118479238, 34.373134212860855, 0.0],
              [-80.77717391816833, 34.373867480377037, 0.0],
              [-80.777532118639186, 34.374509079993686, 0.0],
              [-80.777863318070516, 34.374784280120942, 0.0],
              [-80.778111918287635, 34.374830279811732, 0.0],
              [-80.778333117945962, 34.374647213786083, 0.0],
              [-80.778333584650227, 34.374280679650418, 0.0],
              [-80.778085784830694, 34.373593279566741, 0.0],
              [-80.777920918424698, 34.372883080464462, 0.0],
              [-80.77828018472637, 34.372723013100106, 0.0],
              [-80.778859985006562, 34.373044079817156, 0.0],
              [-80.779522918050759, 34.373136280063605, 0.0],
              [-80.779965117865615, 34.37295327953732, 0.0],
              [-80.779993118914788, 34.372701413491534, 0.0],
              [-80.779634384464643, 34.37222008017882, 0.0],
              [-80.779634718860407, 34.371991013468673, 0.0],
              [-80.779772985597958, 34.37183068034777, 0.0],
              [-80.780518918185948, 34.371785479937692, 0.0],
              [-80.78145891812494, 34.371213480085224, 0.0],
              [-80.781956185479757, 34.371145279714163, 0.0],
              [-80.78248091882439, 34.371328879844803, 0.0],
              [-80.783254318749186, 34.371421080003557, 0.0],
              [-80.783530918232273, 34.371054679875144, 0.0],
              [-80.783531918157422, 34.370344612856222, 0.0],
              [-80.78309071870639, 34.369611279733491, 0.0],
              [-80.78311858503892, 34.369267613332902, 0.0],
              [-80.78353378480486, 34.368580813229194, 0.0],
              [-80.783589385367094, 34.368237079845386, 0.0],
              [-80.783506784478746, 34.368053813461366, 0.0],
              [-80.783507318481014, 34.367458213452494, 0.0],
              [-80.783755984792109, 34.367435480200172, 0.0],
              [-80.784004318627296, 34.36766467971028, 0.0],
              [-80.784860384738678, 34.36798608024926, 0.0],
              [-80.78485971807082, 34.368558812941259, 0.0],
              [-80.784719318173615, 34.370689013378573, 0.0],
              [-80.78491231879002, 34.370987013144429, 0.0],
              [-80.785492185028559, 34.37128528004137, 0.0],
              [-80.78593418549444, 34.371216879928618, 0.0],
              [-80.787205718228307, 34.370576280118613, 0.0],
              [-80.78745498486056, 34.369980880221583, 0.0],
              [-80.787455385584053, 34.369591480071954, 0.0],
              [-80.787262385163331, 34.369224879831478, 0.0],
              [-80.787234984696994, 34.368927013588916, 0.0],
              [-80.787483985169814, 34.368629413397933, 0.0],
              [-80.787704985181264, 34.368560880364434, 0.0],
              [-80.788395384861218, 34.368813279634495, 0.0],
              [-80.788782318004436, 34.368676080444416, 0.0],
              [-80.789224584695759, 34.368378613762509, 0.0],
              [-80.789694984903463, 34.367623080240776, 0.0],
              [-80.789695585354195, 34.367096080148812, 0.0],
              [-80.788813384582454, 34.365400413048626, 0.0],
              [-80.788371785025547, 34.365033613207579, 0.0],
              [-80.788372184956913, 34.364644213200165, 0.0],
              [-80.788621118800748, 34.364461080314541, 0.0],
              [-80.788925385069305, 34.36395741290157, 0.0],
              [-80.788953385035668, 34.363590880377167, 0.0],
              [-80.78884318460203, 34.363361680369955, 0.0],
              [-80.788816384669758, 34.362628613485285, 0.0],
              [-80.789065118278955, 34.362514279522166, 0.0],
              [-80.789396518046445, 34.362583280443573, 0.0],
              [-80.78964478542153, 34.362835413627302, 0.0],
              [-80.789754984558897, 34.36308741333859, 0.0],
              [-80.790525584970467, 34.365859812997513, 0.0],
              [-80.790773918641406, 34.366180613794306, 0.0],
              [-80.790994785566554, 34.366295280230432, 0.0],
              [-80.791353785264391, 34.36634127971238, 0.0],
              [-80.792763318057808, 34.365655212972243, 0.0],
              [-80.793122518375824, 34.365586612848375, 0.0],
              [-80.793564385586777, 34.365770213439575, 0.0],
              [-80.79392311840131, 34.366114013135238, 0.0],
              [-80.795192784907428, 34.367122813656287, 0.0],
              [-80.795496584794861, 34.367260413021462, 0.0],
              [-80.795966118312037, 34.36728361287539, 0.0],
              [-80.796325518712948, 34.36694021363401, 0.0],
              [-80.796408985500378, 34.366275880065885, 0.0],
              [-80.796961785573316, 34.36590981306388, 0.0],
              [-80.797127985436035, 34.365497480174369, 0.0],
              [-80.797184185479068, 34.364535613201845, 0.0],
              [-80.797018785428861, 34.364123080212522, 0.0],
              [-80.79663258488192, 34.363527279903039, 0.0],
              [-80.796274784567231, 34.362267079545298, 0.0],
              [-80.79600011870761, 34.360617613173858, 0.0],
              [-80.796111318896465, 34.359861813293541, 0.0],
              [-80.796277318911976, 34.35963267956668, 0.0],
              [-80.797050785270258, 34.359541612938727, 0.0],
              [-80.797243918570473, 34.359770813495288, 0.0],
              [-80.797077585463313, 34.36041221362666, 0.0],
              [-80.79707711784981, 34.36096188031312, 0.0],
              [-80.797187518645643, 34.361076612873276, 0.0],
              [-80.79740838509079, 34.361053813442354, 0.0],
              [-80.79760631841846, 34.360744413516812, 0.0],
              [-80.797657385365341, 34.360664479508394, 0.0],
              [-80.797933585254114, 34.360779213716881, 0.0],
              [-80.797988185143339, 34.361466480205479, 0.0],
              [-80.798070918051934, 34.36162688015601, 0.0],
              [-80.798678117919707, 34.362131213107126, 0.0],
              [-80.799037118169906, 34.36226887956083, 0.0],
              [-80.800059185170227, 34.362177813642035, 0.0],
              [-80.800446318073142, 34.361720012870855, 0.0],
              [-80.800667318943113, 34.361628413289409, 0.0],
              [-80.800888318492468, 34.361651479689108, 0.0],
              [-80.80160591866543, 34.362339213794058, 0.0],
              [-80.802323985015491, 34.362477013027025, 0.0],
              [-80.803042118880015, 34.362500279676844, 0.0],
              [-80.802931318617723, 34.36291261364687, 0.0],
              [-80.802848318203843, 34.363027079703691, 0.0],
              [-80.803096185356935, 34.363691479916184, 0.0],
              [-80.803869118746022, 34.364287479500604, 0.0],
              [-80.803868918734651, 34.364516613169364, 0.0],
              [-80.803426718615455, 34.364905813430781, 0.0],
              [-80.802846718140927, 34.364813813505585, 0.0],
              [-80.801438784467422, 34.363736413775413, 0.0],
              [-80.80110738553887, 34.363736080212156, 0.0],
              [-80.800748118265233, 34.363850413001906, 0.0],
              [-80.800360918030364, 34.364445879951035, 0.0],
              [-80.800138784586409, 34.365774280268958, 0.0],
              [-80.800276384423356, 34.366255479807727, 0.0],
              [-80.800690184598679, 34.366897080482744, 0.0],
              [-80.80077291836497, 34.367126213738217, 0.0],
              [-80.800689585240846, 34.367515613114392, 0.0],
              [-80.8004411188125, 34.367492479659639, 0.0],
              [-80.799723385530925, 34.366896480306742, 0.0],
              [-80.798784184985806, 34.366873013360596, 0.0],
              [-80.798314385256376, 34.367010280409858, 0.0],
              [-80.797402117875308, 34.367651013549725, 0.0],
              [-80.797290585281345, 34.368796280195099, 0.0],
              [-80.797428117866517, 34.369437813473446, 0.0],
              [-80.797731384630652, 34.370056479862164, 0.0],
              [-80.797675918724238, 34.370285613488676, 0.0],
              [-80.79751011872645, 34.370354213364081, 0.0],
              [-80.797261585278378, 34.370262413290931, 0.0],
              [-80.796957984882042, 34.369941479971111, 0.0],
              [-80.79679271817767, 34.369529013365955, 0.0],
              [-80.796627118307669, 34.369368613737883, 0.0],
              [-80.796185118042985, 34.369391212899878, 0.0],
              [-80.795742784806123, 34.369665879524582, 0.0],
              [-80.795244917935833, 34.370238213448538, 0.0],
              [-80.793559384568638, 34.370764013075359, 0.0],
              [-80.792812984934585, 34.371175813231353, 0.0],
              [-80.792038717893206, 34.371954213397267, 0.0],
              [-80.791651584552326, 34.372228813812342, 0.0],
              [-80.790988518946193, 34.372388679478135, 0.0],
              [-80.789331318962638, 34.372021013303232, 0.0],
              [-80.788999785235035, 34.372043680060948, 0.0],
              [-80.78877858468384, 34.372181013394218, 0.0],
              [-80.788612384644395, 34.372593079561177, 0.0],
              [-80.788501185467169, 34.373257479994109, 0.0],
              [-80.788528118694558, 34.373921679699038, 0.0],
              [-80.788804118873443, 34.374311280229279, 0.0],
              [-80.789908718603996, 34.374678679517501, 0.0],
              [-80.790405518577288, 34.375137079676733, 0.0],
              [-80.790791585041589, 34.375778813669271, 0.0],
              [-80.790874318014374, 34.375824679792764, 0.0],
              [-80.791011785406042, 34.376557679658696, 0.0],
              [-80.7913705179609, 34.377039012957852, 0.0],
              [-80.791784918302213, 34.376970612838107, 0.0],
              [-80.792033785592395, 34.376787479986788, 0.0],
              [-80.792199918264885, 34.376421079756355, 0.0],
              [-80.792311518233504, 34.375413280036845, 0.0],
              [-80.792587918150176, 34.375230280432703, 0.0],
              [-80.792753718331454, 34.375253280121967, 0.0],
              [-80.792946918478833, 34.375436613108057, 0.0],
              [-80.793001384513147, 34.376101012927933, 0.0],
              [-80.793608585325103, 34.376674079755595, 0.0],
              [-80.793995318358256, 34.376766013345559, 0.0],
              [-80.794603185349729, 34.376674680377945, 0.0],
              [-80.795183785431163, 34.376194012844266, 0.0],
              [-80.795571385073927, 34.3754382800984, 0.0],
              [-80.795820385007445, 34.375140679985833, 0.0],
              [-80.796041585088219, 34.374911812957478, 0.0],
              [-80.796428784737827, 34.374522613700648, 0.0],
              [-80.796649718758218, 34.374545679573465, 0.0],
              [-80.796925784947661, 34.374729079886137, 0.0],
              [-80.796870185037861, 34.375118413493958, 0.0],
              [-80.796593584795247, 34.37555347957521, 0.0],
              [-80.796620918786715, 34.375851280286632, 0.0],
              [-80.797007318487985, 34.376286879934341, 0.0],
              [-80.797918185595591, 34.376951680140095, 0.0],
              [-80.798249318276447, 34.377478813557325, 0.0],
              [-80.798469584544549, 34.378257679914256, 0.0],
              [-80.798717918427755, 34.378647279589053, 0.0],
              [-80.799325318109211, 34.379151613025677, 0.0],
              [-80.799905184583082, 34.379381079953035, 0.0],
              [-80.800540784597288, 34.379289880278215, 0.0],
              [-80.800871985134094, 34.379587812885973, 0.0],
              [-80.801120317875103, 34.379977413798699, 0.0],
              [-80.801866384755613, 34.379840413466241, 0.0],
              [-80.802612584943986, 34.379543013489929, 0.0],
              [-80.803109784810346, 34.379635013103325, 0.0],
              [-80.803081918274628, 34.380001480303392, 0.0],
              [-80.802473584848499, 34.380573813088375, 0.0],
              [-80.802362718774333, 34.380986013670125, 0.0],
              [-80.802775784497925, 34.382292079831565, 0.0],
              [-80.802554718617557, 34.382589679667227, 0.0],
              [-80.802305985235051, 34.382589613406537, 0.0],
              [-80.801946985071723, 34.382291613390443, 0.0],
              [-80.801836718395791, 34.382062413146159, 0.0],
              [-80.801174184767305, 34.381374880150787, 0.0],
              [-80.800704985362714, 34.381053813689327, 0.0],
              [-80.800014185396989, 34.380984680424575, 0.0],
              [-80.798412185345214, 34.380502679991707, 0.0],
              [-80.79783238552335, 34.380135813717857, 0.0],
              [-80.797252918148502, 34.37933361295913, 0.0],
              [-80.797004784860462, 34.378783679632598, 0.0],
              [-80.796673717976006, 34.378371079960708, 0.0],
              [-80.79620418514628, 34.378187613484563, 0.0],
              [-80.794850518193229, 34.3781408802298, 0.0],
              [-80.794159517947932, 34.378484213057334, 0.0],
              [-80.791783518808444, 34.378413812973385, 0.0],
              [-80.790954717981691, 34.378482012848451, 0.0],
              [-80.789905118123954, 34.378183480144294, 0.0],
              [-80.789573517821054, 34.378274880055365, 0.0],
              [-80.789379785581517, 34.378572479493108, 0.0],
              [-80.789434785347112, 34.378801613732534, 0.0],
              [-80.790704185237914, 34.380085413765656, 0.0],
              [-80.791145584522837, 34.380772879735034, 0.0],
              [-80.79139378478996, 34.381277013694287, 0.0],
              [-80.791697317832629, 34.381597879909691, 0.0],
              [-80.79249831884907, 34.381896213417882, 0.0],
              [-80.792774318270489, 34.382194079943254, 0.0],
              [-80.793049985309253, 34.382721213701458, 0.0],
              [-80.79362958556456, 34.383408813708584, 0.0],
              [-80.794347384997039, 34.38402781340173, 0.0],
              [-80.794512985133522, 34.384279813424875, 0.0],
              [-80.795285785507559, 34.384944613548775, 0.0],
              [-80.795589718712861, 34.385036479697867, 0.0],
              [-80.796308118444202, 34.384968213733558, 0.0],
              [-80.796556518090782, 34.385197413594128, 0.0],
              [-80.796666385072598, 34.385976412923185, 0.0],
              [-80.797245718857681, 34.38687008019388, 0.0],
              [-80.798653518692518, 34.388359880108013, 0.0],
              [-80.798791385201795, 34.388703679805211, 0.0],
              [-80.79862538503113, 34.388932613641188, 0.0],
              [-80.798321518322226, 34.388909480068136, 0.0],
              [-80.797548185336225, 34.388496680224868, 0.0],
              [-80.795698518386132, 34.386800413603908, 0.0],
              [-80.7948147853309, 34.386295879564663, 0.0],
              [-80.793434385107076, 34.385149613595416, 0.0],
              [-80.792329918941206, 34.384438813078326, 0.0],
              [-80.791805118776438, 34.384278080435628, 0.0],
              [-80.791473518527525, 34.38436948039331, 0.0],
              [-80.791307318693995, 34.384644280088445, 0.0],
              [-80.791168584983851, 34.385285613502255, 0.0],
              [-80.790947184964153, 34.385674813553095, 0.0],
              [-80.790477318531345, 34.385834813259358, 0.0],
              [-80.789510317924339, 34.385765413131701, 0.0],
              [-80.789178784882196, 34.385673613483611, 0.0],
              [-80.788930385114099, 34.385444280182135, 0.0],
              [-80.788709918895435, 34.384894412841142, 0.0],
              [-80.789097718670646, 34.384024213101917, 0.0],
              [-80.789098384497791, 34.383382880345657, 0.0],
              [-80.788629385617412, 34.382695280250836, 0.0],
              [-80.788159984818918, 34.382397279502662, 0.0],
              [-80.787717918331737, 34.382488612808913, 0.0],
              [-80.787358318629316, 34.382809013063778, 0.0],
              [-80.78663791826159, 34.384686813144384, 0.0],
              [-80.78663738514318, 34.385167880395208, 0.0],
              [-80.78672018495638, 34.385213813667995, 0.0],
              [-80.786720118788537, 34.385351213373831, 0.0],
              [-80.788044984596667, 34.386703679708205, 0.0],
              [-80.788459118361587, 34.387024613714075, 0.0],
              [-80.788790585247099, 34.387070613208252, 0.0],
              [-80.789426318147434, 34.386933680173179, 0.0],
              [-80.790034385193749, 34.386705013115801, 0.0],
              [-80.790365918731865, 34.386728213683391, 0.0],
              [-80.791028717951107, 34.387049279573795, 0.0],
              [-80.792022785618599, 34.387668479762439, 0.0],
              [-80.792271118204297, 34.388012280038666, 0.0],
              [-80.792270918555047, 34.388195480237663, 0.0],
              [-80.791442118261941, 34.388011679870225, 0.0],
              [-80.791055517810761, 34.387828213585799, 0.0],
              [-80.790502985383583, 34.38771327951536, 0.0],
              [-80.78997778502027, 34.387873213519015, 0.0],
              [-80.789894917937985, 34.387964813499245, 0.0],
              [-80.789922184463833, 34.388331412935251, 0.0],
              [-80.790197984588076, 34.38865227966977, 0.0],
              [-80.790170185636754, 34.388858413675194, 0.0],
              [-80.789755518152603, 34.389064280269082, 0.0],
              [-80.789561918079656, 34.389247479915539, 0.0],
              [-80.789589118509838, 34.38963681282965, 0.0],
              [-80.791494918168127, 34.390417013413185, 0.0],
              [-80.792240518703878, 34.390875613737109, 0.0],
              [-80.792627118510111, 34.391196613605494, 0.0],
              [-80.792626918705608, 34.391379879989444, 0.0],
              [-80.792322784875267, 34.39149421295982, 0.0],
              [-80.791963584801692, 34.391471080270136, 0.0],
              [-80.790886184967178, 34.391172479863322, 0.0],
              [-80.789863985626496, 34.391034280200671, 0.0],
              [-80.789283718932353, 34.391125613255518, 0.0],
              [-80.788924385043472, 34.391262812933938, 0.0],
              [-80.788536985440743, 34.391674880109491, 0.0],
              [-80.788536517968382, 34.392224613742414, 0.0],
              [-80.78886758461806, 34.392568480029922, 0.0],
              [-80.790082918433797, 34.393073280381202, 0.0],
              [-80.790082717966555, 34.393256612832921, 0.0],
              [-80.789805985344145, 34.393645812930117, 0.0],
              [-80.788644385401582, 34.394607013046127, 0.0],
              [-80.788229318322109, 34.395202413284558, 0.0],
              [-80.788145718173681, 34.395889479955137, 0.0],
              [-80.788366518135192, 34.396141612808641, 0.0],
              [-80.78941598477671, 34.396715013044989, 0.0],
              [-80.790603717974932, 34.397105279847565, 0.0],
              [-80.791404518942684, 34.397701413274099, 0.0],
              [-80.791376784795318, 34.39781588000406, 0.0],
              [-80.790437384660848, 34.397723680295385, 0.0],
              [-80.789635784846496, 34.397791812822703, 0.0],
              [-80.789359385441884, 34.397952013166197, 0.0],
              [-80.789386718531091, 34.398226880231164, 0.0],
              [-80.790242584533928, 34.398960613046263, 0.0],
              [-80.790628984551887, 34.399441879781058, 0.0],
              [-80.7906287850832, 34.399648012845205, 0.0],
              [-80.790379917957964, 34.399831079931005, 0.0],
              [-80.789191318562359, 34.400059280374606, 0.0],
              [-80.788721184651351, 34.400402613554533, 0.0],
              [-80.788720385559557, 34.4011128135758, 0.0],
              [-80.789051118173205, 34.402006280158481, 0.0],
              [-80.789161518735384, 34.402098079637881, 0.0],
              [-80.789381984864889, 34.402716679851679, 0.0],
              [-80.789768185154301, 34.403427013582977, 0.0],
              [-80.790072118209281, 34.403473079689626, 0.0],
              [-80.791343785381201, 34.403107479761964, 0.0],
              [-80.791564718651827, 34.403153412976195, 0.0],
              [-80.791702785601572, 34.403268080313872, 0.0],
              [-80.791564318950634, 34.403634479525238, 0.0],
              [-80.791342985070941, 34.403840479858154, 0.0],
              [-80.791315118592635, 34.40406948005203, 0.0],
              [-80.791812118768959, 34.404528013599005, 0.0],
              [-80.792282118116816, 34.404413813234804, 0.0],
              [-80.792696584850432, 34.404437013538683, 0.0],
              [-80.794243585562128, 34.405102279884609, 0.0],
              [-80.794906717840504, 34.405286012933885, 0.0],
              [-80.796592318411541, 34.40542447952749, 0.0],
              [-80.798056718659268, 34.40579201323137, 0.0],
              [-80.798664517799224, 34.406136013254923, 0.0],
              [-80.80004511808626, 34.407327879955851, 0.0],
              [-80.801177918628056, 34.407786812818394, 0.0],
              [-80.801564918208854, 34.407718279831883, 0.0],
              [-80.801841518335735, 34.407512280401953, 0.0],
              [-80.802007584423208, 34.407191612945539, 0.0],
              [-80.802035518787008, 34.406939679903616, 0.0],
              [-80.802256784725571, 34.406687813666615, 0.0],
              [-80.802505585545291, 34.406688013326516, 0.0],
              [-80.80269891838762, 34.406848413564639, 0.0],
              [-80.802891984508463, 34.407192079552765, 0.0],
              [-80.803195784910244, 34.407467213709516, 0.0],
              [-80.803416784636838, 34.4075360137318, 0.0],
              [-80.804107918234251, 34.407376213353032, 0.0],
              [-80.804412184759471, 34.407078480263245, 0.0],
              [-80.804937918306777, 34.406277079940594, 0.0],
              [-80.804965718669351, 34.406071013716534, 0.0],
              [-80.80526991804004, 34.405796079948068, 0.0],
              [-80.805435585015474, 34.405979613494218, 0.0],
              [-80.805351918931464, 34.406895813698235, 0.0],
              [-80.805185518737375, 34.40767448004113, 0.0],
              [-80.80526751859361, 34.408568013197353, 0.0],
              [-80.805681784694229, 34.408866013392952, 0.0],
              [-80.807312385391441, 34.408981412867632, 0.0],
              [-80.80905391797873, 34.408822013667375, 0.0],
              [-80.809772518084955, 34.408639213752473, 0.0],
              [-80.810021384545806, 34.408479013154128, 0.0],
              [-80.810021985045609, 34.407791679460829, 0.0],
              [-80.809718385472038, 34.407264612943905, 0.0],
              [-80.809856917939229, 34.40678368032998, 0.0],
              [-80.810271318089434, 34.407013013222887, 0.0],
              [-80.810850984862327, 34.407929612839446, 0.0],
              [-80.811209985198246, 34.408250413342479, 0.0],
              [-80.811900917771183, 34.408342413664329, 0.0],
              [-80.812149518605011, 34.408525813367028, 0.0],
              [-80.812010984823175, 34.40889221298913, 0.0],
              [-80.810849585244227, 34.409624680037858, 0.0],
              [-80.810434517881944, 34.410197079477484, 0.0],
              [-80.810434184705983, 34.410678213165696, 0.0],
              [-80.810572184836971, 34.410953213628275, 0.0],
              [-80.810903584546992, 34.411182413092995, 0.0],
              [-80.811401118134796, 34.411136813058882, 0.0],
              [-80.813115118315736, 34.410610879806335, 0.0],
              [-80.814138118808302, 34.410153080158338, 0.0],
              [-80.815659118117679, 34.408985680419867, 0.0],
              [-80.815908185387983, 34.40859641315037, 0.0],
              [-80.815908518701988, 34.408115280037364, 0.0],
              [-80.815742918173385, 34.407748679593077, 0.0],
              [-80.815494318851393, 34.407542413612404, 0.0],
              [-80.815190385456987, 34.407565280166878, 0.0],
              [-80.814554184526443, 34.408114680026642, 0.0],
              [-80.814028984912795, 34.408274813276719, 0.0],
              [-80.813338185120884, 34.408114012832705, 0.0],
              [-80.812979118038072, 34.407770279861445, 0.0],
              [-80.812924185107093, 34.40733501356128, 0.0],
              [-80.813090385193462, 34.406877013259859, 0.0],
              [-80.813560785279293, 34.406167080109313, 0.0],
              [-80.813781918162221, 34.406006812904629, 0.0],
              [-80.814251584555777, 34.406144479444201, 0.0],
              [-80.814444984679554, 34.406304879756895, 0.0],
              [-80.816268718715861, 34.406809680094412, 0.0],
              [-80.816710918191049, 34.406787013552027, 0.0],
              [-80.816904517972603, 34.40658101321339, 0.0],
              [-80.817015318872777, 34.406191680112691, 0.0],
              [-80.817016184898591, 34.404977480123513, 0.0],
              [-80.816878385361633, 34.404565080342024, 0.0],
              [-80.816767985347752, 34.404473479541409, 0.0],
              [-80.816408518074084, 34.40458781308633, 0.0],
              [-80.815910518530856, 34.40534347955758, 0.0],
              [-80.815661718405792, 34.405526679762289, 0.0],
              [-80.815495918170072, 34.405480813390319, 0.0],
              [-80.815440918691792, 34.405137213433768, 0.0],
              [-80.815579385405485, 34.404656213550169, 0.0],
              [-80.816270785093238, 34.403877613299215, 0.0],
              [-80.815994784572567, 34.40353387996894, 0.0],
              [-80.815994918691331, 34.403213212873673, 0.0],
              [-80.816630984719922, 34.402709613411098, 0.0],
              [-80.816769318035469, 34.402480612895005, 0.0],
              [-80.816631317859162, 34.402159813212052, 0.0],
              [-80.815691785018316, 34.402136479669572, 0.0],
              [-80.814835585026643, 34.401403079647757, 0.0],
              [-80.813564785189868, 34.400806813686067, 0.0],
              [-80.812984917756495, 34.400394213447179, 0.0],
              [-80.81146558479, 34.399568813609882, 0.0],
              [-80.811410518095784, 34.399408413342343, 0.0],
              [-80.811880517896128, 34.399042213755642, 0.0],
              [-80.811880718029187, 34.39883587954013, 0.0],
              [-80.811659784491425, 34.39860668019989, 0.0],
              [-80.811659984620903, 34.398377613506163, 0.0],
              [-80.811825785428866, 34.398263213748351, 0.0],
              [-80.812157385434091, 34.398263413089865, 0.0],
              [-80.813428318899241, 34.398699213627751, 0.0],
              [-80.814008384986138, 34.399066080013618, 0.0],
              [-80.814477718646188, 34.399547412830728, 0.0],
              [-80.815168317858138, 34.400097479537244, 0.0],
              [-80.81552751834235, 34.400166412914977, 0.0],
              [-80.816577584472554, 34.400166879500127, 0.0],
              [-80.81737878482248, 34.400442079807512, 0.0],
              [-80.818925718647364, 34.401496479887271, 0.0],
              [-80.819201985113494, 34.401611213382793, 0.0],
              [-80.819975784750554, 34.401565679973551, 0.0],
              [-80.820805384834415, 34.40069561279887, 0.0],
              [-80.820833184738305, 34.400352080432341, 0.0],
              [-80.820612384678284, 34.39998541343828, 0.0],
              [-80.820557518710189, 34.399412679582113, 0.0],
              [-80.820806385528712, 34.399160813362982, 0.0],
              [-80.820972518447832, 34.398817280333411, 0.0],
              [-80.820835917974762, 34.396389080409314, 0.0],
              [-80.820725518183366, 34.396091213132948, 0.0],
              [-80.820698184950047, 34.395633080390148, 0.0],
              [-80.820312184926195, 34.394373079820568, 0.0],
              [-80.820257318567315, 34.393800280109588, 0.0],
              [-80.820312784481956, 34.3935254802754, 0.0],
              [-80.820616784624377, 34.393411079728502, 0.0],
              [-80.820892985391311, 34.39359448009845, 0.0],
              [-80.821389517977209, 34.394946213329881, 0.0],
              [-80.821941585188355, 34.395862680316654, 0.0],
              [-80.822162184831669, 34.396572879645738, 0.0],
              [-80.822410584871207, 34.397077012797943, 0.0],
              [-80.823017917745389, 34.397856080167323, 0.0],
              [-80.823652985174647, 34.398703880092583, 0.0],
              [-80.823956917872025, 34.398864480134741, 0.0],
              [-80.824399117909877, 34.398887479553089, 0.0],
              [-80.824620185318452, 34.398704279692303, 0.0],
              [-80.824730917787079, 34.39847527968621, 0.0],
              [-80.824676384983633, 34.397421480051065, 0.0],
              [-80.825063517769024, 34.397009412937976, 0.0],
              [-80.825146584468854, 34.396597080168576, 0.0],
              [-80.82536791880699, 34.396299413727931, 0.0],
              [-80.825671984919111, 34.396047480118227, 0.0],
              [-80.826528918290151, 34.395681212897387, 0.0],
              [-80.826667117752095, 34.395498080122096, 0.0],
              [-80.826667985482629, 34.394192413056047, 0.0],
              [-80.826916918603416, 34.393711480356764, 0.0],
              [-80.827718718693703, 34.393047480007809, 0.0],
              [-80.827939985197531, 34.392726813638397, 0.0],
              [-80.82813378554485, 34.392131280003518, 0.0],
              [-80.828576318406974, 34.391398413094826, 0.0],
              [-80.828824984869357, 34.391215280014151, 0.0],
              [-80.82959878497249, 34.391055280029491, 0.0],
              [-80.829902984574161, 34.390757480351652, 0.0],
              [-80.830344984961599, 34.390711879663996, 0.0],
              [-80.830566518023787, 34.390070480436528, 0.0],
              [-80.83001391824817, 34.3898870799988, 0.0],
              [-80.829903385325707, 34.389772479755266, 0.0],
              [-80.829820784953952, 34.389383012992155, 0.0],
              [-80.829904184581494, 34.388512613263345, 0.0],
              [-80.830014985427525, 34.388306479788575, 0.0],
              [-80.830180784789221, 34.388237880318158, 0.0],
              [-80.830650518764585, 34.388238080288367, 0.0],
              [-80.831285785079643, 34.388375679668457, 0.0],
              [-80.83142398492069, 34.388536080365974, 0.0],
              [-80.831395984935696, 34.389177479549751, 0.0],
              [-80.831229918028484, 34.38958968024626, 0.0],
              [-80.831007985454164, 34.391193212859356, 0.0],
              [-80.830758784959286, 34.392086479641804, 0.0],
              [-80.830178185504209, 34.392727613207242, 0.0],
              [-80.830150117955654, 34.393506480243076, 0.0],
              [-80.829320718386299, 34.39421628029509, 0.0],
              [-80.829071785087734, 34.394559879479125, 0.0],
              [-80.828960985238396, 34.394972080312478, 0.0],
              [-80.828960517879679, 34.395842613221888, 0.0],
              [-80.829098585274451, 34.396117613206123, 0.0],
              [-80.829512784525804, 34.396507079999473, 0.0],
              [-80.829567985196149, 34.396782013407105, 0.0],
              [-80.829291518613616, 34.397079613365932, 0.0],
              [-80.828296718352249, 34.396873213140609, 0.0],
              [-80.827937385221873, 34.397033413101838, 0.0],
              [-80.82766078528887, 34.397468613436267, 0.0],
              [-80.827660318855621, 34.39847641302763, 0.0],
              [-80.827494184930032, 34.398705479850861, 0.0],
              [-80.826913584463313, 34.399163279919115, 0.0],
              [-80.826802917814575, 34.399392280045696, 0.0],
              [-80.826913118353133, 34.400102613427784, 0.0],
              [-80.82732711833232, 34.401019013585064, 0.0],
              [-80.827492784884541, 34.401133612905234, 0.0],
              [-80.828017917995354, 34.401110879815612, 0.0],
              [-80.828764384388165, 34.400653013388286, 0.0],
              [-80.829427518078973, 34.40083647984256, 0.0],
              [-80.829869384885839, 34.401386479837939, 0.0],
              [-80.830449385076591, 34.401867679985365, 0.0],
              [-80.83141638502066, 34.402372013426685, 0.0],
              [-80.8326875851767, 34.40237241279717, 0.0],
              [-80.835229985091516, 34.402671012871295, 0.0],
              [-80.83583798471156, 34.402510812869025, 0.0],
              [-80.836086918284138, 34.40228187970478, 0.0],
              [-80.836169917811731, 34.402121613039718, 0.0],
              [-80.836031985118311, 34.40136561325199, 0.0],
              [-80.836114985138465, 34.401182413696596, 0.0],
              [-80.836750584886957, 34.401182479970167, 0.0],
              [-80.837082385340437, 34.400907679713853, 0.0],
              [-80.83735911795182, 34.400174813313484, 0.0],
              [-80.837608117908459, 34.399739613719198, 0.0],
              [-80.838133385316439, 34.399327613717304, 0.0],
              [-80.83863078495682, 34.399167280127514, 0.0],
              [-80.839045384632186, 34.398800880315321, 0.0],
              [-80.839571318775896, 34.397197480154126, 0.0],
              [-80.83976511796142, 34.396121013772067, 0.0],
              [-80.839958585253271, 34.396052279655024, 0.0],
              [-80.840372984598332, 34.396212813454497, 0.0],
              [-80.840621718580152, 34.396258612848712, 0.0],
              [-80.84075991856237, 34.396396213525115, 0.0],
              [-80.840731984562595, 34.396877213187217, 0.0],
              [-80.840455317916863, 34.397610079865558, 0.0],
              [-80.84048291841998, 34.397999479988634, 0.0],
              [-80.840703717999276, 34.398343213161191, 0.0],
              [-80.840593118615004, 34.398663880356914, 0.0],
              [-80.840150784822214, 34.399053213543262, 0.0],
              [-80.840095385415736, 34.399396813553288, 0.0],
              [-80.840261118822511, 34.399534279958949, 0.0],
              [-80.840260918416092, 34.40001541289184, 0.0],
              [-80.839569584789672, 34.400702279477969, 0.0],
              [-80.838657317974452, 34.401366413146711, 0.0],
              [-80.837219584394447, 34.403015279917469, 0.0],
              [-80.836500584647709, 34.404045813401311, 0.0],
              [-80.836500318505813, 34.404458213535477, 0.0],
              [-80.837550518275904, 34.404527212911596, 0.0],
              [-80.838103184544167, 34.404664813357812, 0.0],
              [-80.838821518285783, 34.405191879779558, 0.0],
              [-80.839484318170321, 34.40599381320456, 0.0],
              [-80.840396318381508, 34.406154413319825, 0.0],
              [-80.841059385405117, 34.40661281368363, 0.0],
              [-80.842164717928512, 34.406865012839248, 0.0],
              [-80.843021518534528, 34.406796480130318, 0.0],
              [-80.84338098474413, 34.406590413371546, 0.0],
              [-80.844293518484591, 34.405559879534671, 0.0],
              [-80.845537385481052, 34.404529280178693, 0.0],
              [-80.846117918558207, 34.404415013163245, 0.0],
              [-80.848908985233109, 34.404598813656314, 0.0],
              [-80.849848784769364, 34.404461479458732, 0.0],
              [-80.851064984844257, 34.403866212890527, 0.0],
              [-80.851424184573489, 34.403866279845062, 0.0],
              [-80.851921584909149, 34.403980879720407, 0.0],
              [-80.852557184663709, 34.403912279552408, 0.0],
              [-80.852805918634758, 34.404049812779093, 0.0],
              [-80.852888785194239, 34.404599480201455, 0.0],
              [-80.852584585029987, 34.404851480060586, 0.0],
              [-80.851285585424293, 34.405149013179489, 0.0],
              [-80.85081571879256, 34.405446613127104, 0.0],
              [-80.850539184938626, 34.405767413248, 0.0],
              [-80.850069385076893, 34.405996413459114, 0.0],
              [-80.849378385199941, 34.406156613700816, 0.0],
              [-80.848217718430504, 34.406156280397646, 0.0],
              [-80.846808318826461, 34.405949879895772, 0.0],
              [-80.846117184548049, 34.406064280242397, 0.0],
              [-80.84581331820732, 34.406224480098004, 0.0],
              [-80.845204918336634, 34.406728413675289, 0.0],
              [-80.844237518243119, 34.407025880299855, 0.0],
              [-80.843795118682792, 34.407392280063156, 0.0],
              [-80.843767318854844, 34.408010813731622, 0.0],
              [-80.843905385421778, 34.40823987980059, 0.0],
              [-80.844540918827278, 34.408812680298915, 0.0],
              [-80.844540718246805, 34.409179213303112, 0.0],
              [-80.843960184792593, 34.409683080099903, 0.0],
              [-80.843517918446537, 34.409660079907361, 0.0],
              [-80.842827118549536, 34.40927047995153, 0.0],
              [-80.842440184661115, 34.409155813186636, 0.0],
              [-80.841556117950674, 34.408399680256032, 0.0],
              [-80.840699584440856, 34.407987079686166, 0.0],
              [-80.83940071830223, 34.407643080235502, 0.0],
              [-80.839096784546328, 34.407459813314787, 0.0],
              [-80.838489118241441, 34.406772480342994, 0.0],
              [-80.838267985248265, 34.40665781368422, 0.0],
              [-80.837328317884811, 34.406840813631398, 0.0],
              [-80.836913785240952, 34.406817679598682, 0.0],
              [-80.836278185095566, 34.406611413491788, 0.0],
              [-80.835918917992274, 34.406634213184667, 0.0],
              [-80.835697784418201, 34.406748613616415, 0.0],
              [-80.835587118174644, 34.407000480167845, 0.0],
              [-80.835725117782118, 34.407481680411514, 0.0],
              [-80.835807918782734, 34.407527479924923, 0.0],
              [-80.836166785225117, 34.40844401352733, 0.0],
              [-80.836719385298778, 34.408879413521319, 0.0],
              [-80.837244318202238, 34.409154412820399, 0.0],
              [-80.838709118059811, 34.409338013242852, 0.0],
              [-80.839151185469859, 34.409475613109258, 0.0],
              [-80.840035318845509, 34.410208812945065, 0.0],
              [-80.84185898508963, 34.41091948021144, 0.0],
              [-80.842107718455225, 34.411217279426594, 0.0],
              [-80.842162717847472, 34.411721279490855, 0.0],
              [-80.841885918813446, 34.412820813033072, 0.0],
              [-80.841940984434913, 34.413164479788904, 0.0],
              [-80.842327718341664, 34.414080812963675, 0.0],
              [-80.842603917724375, 34.414493212986521, 0.0],
              [-80.843294584789703, 34.415157613242542, 0.0],
              [-80.84498031838541, 34.416028480335733, 0.0],
              [-80.84614111806269, 34.416395279652015, 0.0],
              [-80.846472584485852, 34.416784812961474, 0.0],
              [-80.846112984399653, 34.417403212961197, 0.0],
              [-80.846084985047838, 34.418640212778129, 0.0],
              [-80.846195318209823, 34.419121279478013, 0.0],
              [-80.846913717917687, 34.419923213631428, 0.0],
              [-80.847880918148562, 34.420679413389529, 0.0],
              [-80.848267917782394, 34.420633613437502, 0.0],
              [-80.849207918509691, 34.420106880384516, 0.0],
              [-80.849788384561762, 34.420129879429304, 0.0],
              [-80.850479318266508, 34.420290413160785, 0.0],
              [-80.850976918846953, 34.420221812846833, 0.0],
              [-80.851916784407635, 34.419718013068227, 0.0],
              [-80.852801518445702, 34.419443279757871, 0.0],
              [-80.852939718580188, 34.419283013483444, 0.0],
              [-80.853022984515746, 34.418229213672085, 0.0],
              [-80.853188984946456, 34.418046013209995, 0.0],
              [-80.85365878481673, 34.417817080388652, 0.0],
              [-80.853658984904712, 34.417290213597099, 0.0],
              [-80.852968184782398, 34.416327880305801, 0.0],
              [-80.852719518166055, 34.416167612937791, 0.0],
              [-80.852719585057429, 34.415984279939984, 0.0],
              [-80.852857918315635, 34.415846879557968, 0.0],
              [-80.85363191842039, 34.4156180134122, 0.0],
              [-80.853935917863907, 34.415640880085419, 0.0],
              [-80.854322718784616, 34.415938812995044, 0.0],
              [-80.855013518669438, 34.416786413267886, 0.0],
              [-80.855317584844386, 34.417084279497118, 0.0],
              [-80.855842584618301, 34.417290480017201, 0.0],
              [-80.860375585326295, 34.417978279682202, 0.0],
              [-80.861177117786767, 34.417886813737432, 0.0],
              [-80.862034117844644, 34.417176679645621, 0.0],
              [-80.862282984888864, 34.416787280260294, 0.0],
              [-80.862393584955868, 34.416123012983931, 0.0],
              [-80.862670118057721, 34.415733612792984, 0.0],
              [-80.863140118402114, 34.415344280314109, 0.0],
              [-80.864024717783053, 34.414955012792291, 0.0],
              [-80.864411718167176, 34.414955013455149, 0.0],
              [-80.864494518699644, 34.415138213367257, 0.0],
              [-80.86427331826691, 34.415504680111923, 0.0],
              [-80.863720384526431, 34.415985680134618, 0.0],
              [-80.863471584955775, 34.416352213629615, 0.0],
              [-80.863443718759328, 34.41742881317446, 0.0],
              [-80.863277784390363, 34.417726480388012, 0.0],
              [-80.86300138443606, 34.418001480004079, 0.0],
              [-80.862780185067891, 34.418711613304602, 0.0],
              [-80.862835518334208, 34.418871880262699, 0.0],
              [-80.863940984457827, 34.419192813684759, 0.0],
              [-80.86465971792046, 34.419169813682032, 0.0],
              [-80.86612478543546, 34.418780479589415, 0.0],
              [-80.866262984616512, 34.418917879689729, 0.0],
              [-80.866345784873317, 34.419192812847477, 0.0],
              [-80.865792984346143, 34.419651013541809, 0.0],
              [-80.865765318426, 34.420246613405418, 0.0],
              [-80.866013985475902, 34.420544413399533, 0.0],
              [-80.869247918319417, 34.421758613646638, 0.0],
              [-80.869607318428024, 34.421964812939095, 0.0],
              [-80.86993898446994, 34.421941813067001, 0.0],
              [-80.870077184645993, 34.421827280259379, 0.0],
              [-80.870077317749278, 34.421483680188459, 0.0],
              [-80.869939118059392, 34.421300480248817, 0.0],
              [-80.869690317997325, 34.420498679914367, 0.0],
              [-80.869745585087301, 34.420132212757096, 0.0],
              [-80.870049784901397, 34.420017679853977, 0.0],
              [-80.870298517754478, 34.420109280388004, 0.0],
              [-80.870519585187395, 34.420338412789199, 0.0],
              [-80.870630118722232, 34.420636213317756, 0.0],
              [-80.871265918326301, 34.421071413314259, 0.0],
              [-80.871680518328077, 34.421094279695019, 0.0],
              [-80.872841517736731, 34.420865279470576, 0.0],
              [-80.873311318789035, 34.420521680256627, 0.0],
              [-80.873117918166074, 34.419994813616135, 0.0],
              [-80.873117918455776, 34.419605413377298, 0.0],
              [-80.873228518572461, 34.419513813300604, 0.0],
              [-80.873449584916486, 34.419536613360755, 0.0],
              [-80.873698385037628, 34.419674079954696, 0.0],
              [-80.874140584678599, 34.419628279918619, 0.0],
              [-80.874306584636315, 34.419353479812777, 0.0],
              [-80.874444785174475, 34.418231013273463, 0.0],
              [-80.87452778492711, 34.418024813168593, 0.0],
              [-80.874776518704508, 34.417910279586543, 0.0],
              [-80.875329384644544, 34.418024880126104, 0.0],
              [-80.875826918235305, 34.418551679615362, 0.0],
              [-80.8763795845027, 34.419353413014449, 0.0],
              [-80.876656118484803, 34.41965121276413, 0.0],
              [-80.877872317863549, 34.41958241355843, 0.0],
              [-80.879447917968463, 34.419696880222403, 0.0],
              [-80.879917784358952, 34.419536479959497, 0.0],
              [-80.880221784634401, 34.419307480384802, 0.0],
              [-80.880498318428096, 34.418391212785572, 0.0],
              [-80.881106318385676, 34.417589279606702, 0.0],
              [-80.881603785128206, 34.417474680322002, 0.0],
              [-80.882488318638948, 34.417818279877245, 0.0],
              [-80.882985918338903, 34.418139012996619, 0.0],
              [-80.88351111785029, 34.418184812774406, 0.0],
              [-80.883676918489016, 34.41802441353493, 0.0],
              [-80.883593918562113, 34.417543412944731, 0.0],
              [-80.882985718372254, 34.416833279654305, 0.0],
              [-80.881658984381872, 34.41474888027286, 0.0],
              [-80.881299718498084, 34.414061679643055, 0.0],
              [-80.880940318510326, 34.413626480273273, 0.0],
              [-80.879530718813911, 34.412664480288818, 0.0],
              [-80.879420117896331, 34.412458280322255, 0.0],
              [-80.879530585235401, 34.412298013086719, 0.0],
              [-80.879806984738352, 34.412183413489821, 0.0],
              [-80.881465318749648, 34.412183279419224, 0.0],
              [-80.882018184566192, 34.412435213565296, 0.0],
              [-80.88248798478898, 34.412526813646124, 0.0],
              [-80.883565918448596, 34.412526812898932, 0.0],
              [-80.88378698483983, 34.412229013023136, 0.0],
              [-80.883869918387461, 34.411954079716025, 0.0],
              [-80.883731585280913, 34.411014880389402, 0.0],
              [-80.883593317940537, 34.410808813427856, 0.0],
              [-80.883482718822151, 34.410213213458007, 0.0],
              [-80.883565584377408, 34.410007012813054, 0.0],
              [-80.883842118164992, 34.409915279493276, 0.0],
              [-80.88406311853133, 34.410006879805202, 0.0],
              [-80.884781785452631, 34.410716879547415, 0.0],
              [-80.885472785173476, 34.411106280150378, 0.0],
              [-80.886246784473798, 34.411724813418708, 0.0],
              [-80.886827384554138, 34.412434812884861, 0.0],
              [-80.887518385088185, 34.41296147943244, 0.0],
              [-80.887849984312894, 34.412984480277252, 0.0],
              [-80.888071117994002, 34.412755280258644, 0.0],
              [-80.888153984849311, 34.412320080032458, 0.0],
              [-80.888485585023332, 34.412022280105248, 0.0],
              [-80.888817117897972, 34.411449612802976, 0.0],
              [-80.888955317862468, 34.410556212990528, 0.0],
              [-80.889231584894134, 34.410143879642575, 0.0],
              [-80.889314517868826, 34.4098002799592, 0.0],
              [-80.889093384959523, 34.409594212990498, 0.0],
              [-80.888595917902649, 34.409433812983885, 0.0],
              [-80.887600918254918, 34.4093880795375, 0.0],
              [-80.887324517955776, 34.409136212879815, 0.0],
              [-80.887213918426028, 34.408701012983457, 0.0],
              [-80.887655784518699, 34.406662213633169, 0.0],
              [-80.887655584369327, 34.405883413125594, 0.0],
              [-80.887517384860729, 34.405539679728335, 0.0],
              [-80.887489784500104, 34.405058813582343, 0.0],
              [-80.887987317695931, 34.404944079947391, 0.0],
              [-80.888374184820933, 34.404967013689365, 0.0],
              [-80.889065118753166, 34.405448013068323, 0.0],
              [-80.889313918569982, 34.405837280351641, 0.0],
              [-80.889230984389883, 34.406203880257962, 0.0],
              [-80.888816518205715, 34.406616279723011, 0.0],
              [-80.888705984388125, 34.406914012779019, 0.0],
              [-80.888761518151114, 34.407876080129348, 0.0],
              [-80.889037918291663, 34.40803641307722, 0.0],
              [-80.889258917645392, 34.407944812809937, 0.0],
              [-80.889369384301204, 34.407784412829663, 0.0],
              [-80.889452185133138, 34.407211813091692, 0.0],
              [-80.889700918573823, 34.406982679653375, 0.0],
              [-80.890640585335206, 34.406638879902054, 0.0],
              [-80.890896318546311, 34.406638880248316, 0.0],
              [-80.890944584914664, 34.406638879943969, 0.0],
              [-80.891220984824315, 34.40684508010753, 0.0],
              [-80.891220984775757, 34.407119879520138, 0.0],
              [-80.890668384385435, 34.407692612983098, 0.0],
              [-80.890668518303542, 34.408563079716174, 0.0],
              [-80.890779184416331, 34.409410612968813, 0.0],
              [-80.890862317972221, 34.410326880150926, 0.0],
              [-80.890807185221178, 34.411151613222152, 0.0],
              [-80.890475584621541, 34.412319879976778, 0.0],
              [-80.89017171802044, 34.412961212963111, 0.0],
              [-80.889232117935705, 34.413740213401049, 0.0],
              [-80.88710411847488, 34.415160680118909, 0.0],
              [-80.886274917973637, 34.415481412869028, 0.0],
              [-80.885418118163784, 34.415527412783128, 0.0],
              [-80.885086385261189, 34.415779413683964, 0.0],
              [-80.884948117688566, 34.4161688129523, 0.0],
              [-80.88500351861687, 34.417039279956356, 0.0],
              [-80.885279984683407, 34.417245479481188, 0.0],
              [-80.88566691783285, 34.417337013148412, 0.0],
              [-80.88663438532096, 34.417084880109186, 0.0],
              [-80.88687731822742, 34.417084880002413, 0.0],
              [-80.886938385161912, 34.417084880068728, 0.0],
              [-80.887104318170771, 34.417932479741921, 0.0],
              [-80.887657185209733, 34.418413479877884, 0.0],
              [-80.888265384984052, 34.418802812945948, 0.0],
              [-80.888541917972958, 34.4192150801343, 0.0],
              [-80.888514318569136, 34.419673212963211, 0.0],
              [-80.886911318066197, 34.421116479781617, 0.0],
              [-80.88602671835956, 34.422101613460647, 0.0],
              [-80.88575031774414, 34.422262079684344, 0.0],
              [-80.884976385362208, 34.422330813031287, 0.0],
              [-80.884561718804804, 34.422193413532739, 0.0],
              [-80.884202318087148, 34.421666613481442, 0.0],
              [-80.884174718129145, 34.421414613026222, 0.0],
              [-80.883925984367423, 34.421208479753716, 0.0],
              [-80.882764918139301, 34.421460479604846, 0.0],
              [-80.882018584931117, 34.421872879893357, 0.0],
              [-80.881327584676029, 34.422422613181311, 0.0],
              [-80.879724384594212, 34.423178813099163, 0.0],
              [-80.879199318191056, 34.423751413675575, 0.0],
              [-80.878895117884582, 34.42388888029793, 0.0],
              [-80.87856351792874, 34.423843079724229, 0.0],
              [-80.878148785050158, 34.423614013337499, 0.0],
              [-80.877761717853701, 34.423522413686136, 0.0],
              [-80.876932518124832, 34.423705679570631, 0.0],
              [-80.876047918755845, 34.424186813244262, 0.0],
              [-80.875909785239415, 34.424347213335778, 0.0],
              [-80.875937184882162, 34.424553280194324, 0.0],
              [-80.876296584323796, 34.424965613501634, 0.0],
              [-80.876490118795246, 34.425355012917144, 0.0],
              [-80.876462518019267, 34.425538212799758, 0.0],
              [-80.87624131823452, 34.425698612918254, 0.0],
              [-80.875964918679884, 34.425698612909315, 0.0],
              [-80.875412118590958, 34.425309213357991, 0.0],
              [-80.87480391786012, 34.425148879971488, 0.0],
              [-80.874389185458696, 34.425217613617299, 0.0],
              [-80.874195717649343, 34.425332079725585, 0.0],
              [-80.873228184699684, 34.426752413413205, 0.0],
              [-80.872813584850661, 34.427073080186418, 0.0],
              [-80.872315918619122, 34.427302079826063, 0.0],
              [-80.871514317785838, 34.427370813387668, 0.0],
              [-80.870629718757087, 34.427302013375048, 0.0],
              [-80.870104518711329, 34.425950613420781, 0.0],
              [-80.869081784930657, 34.424828080315592, 0.0],
              [-80.868556584755751, 34.424438679921707, 0.0],
              [-80.868197384995682, 34.423407879991146, 0.0],
              [-80.867976185165418, 34.42301841288333, 0.0],
              [-80.867616917671285, 34.422789279421352, 0.0],
              [-80.867285118414713, 34.42294968007262, 0.0],
              [-80.867091585429293, 34.423843013411769, 0.0],
              [-80.866676784716248, 34.424690479791415, 0.0],
              [-80.866593918454072, 34.424988413371892, 0.0],
              [-80.866593918452082, 34.42567561306835, 0.0],
              [-80.866289718574421, 34.425996213078136, 0.0],
              [-80.865073384837331, 34.425835812889702, 0.0],
              [-80.864354718296241, 34.425515013409047, 0.0],
              [-80.863525518740033, 34.425033880061129, 0.0],
              [-80.863110785468422, 34.425125479993575, 0.0],
              [-80.863110785048008, 34.425400413574813, 0.0],
              [-80.863580718074161, 34.425904413572702, 0.0],
              [-80.86377411787781, 34.426293813042548, 0.0],
              [-80.864022784846526, 34.426774879958863, 0.0],
              [-80.864492718128858, 34.427072680075383, 0.0],
              [-80.86562598495793, 34.427164413519804, 0.0],
              [-80.865985385454621, 34.427462279440888, 0.0],
              [-80.866234117864863, 34.427920412879736, 0.0],
              [-80.866372318720664, 34.428538813397651, 0.0],
              [-80.867007984538077, 34.429661413618682, 0.0],
              [-80.867007918242777, 34.430165280019516, 0.0],
              [-80.866593118384898, 34.431127413583269, 0.0],
              [-80.866620585404306, 34.432387213559345, 0.0],
              [-80.866703584389143, 34.432456013286867, 0.0],
              [-80.866731118376649, 34.432730813217901, 0.0],
              [-80.866813985168321, 34.432776612744, 0.0],
              [-80.867256185048291, 34.434013679926331, 0.0],
              [-80.867864317972959, 34.434838413626217, 0.0],
              [-80.869080718314763, 34.435411079840136, 0.0],
              [-80.869716585132849, 34.435960879896129, 0.0],
              [-80.870462984585004, 34.436785613116463, 0.0],
              [-80.870628917733981, 34.436854279796393, 0.0],
              [-80.871015917743492, 34.436785480054283, 0.0],
              [-80.871154184455463, 34.436648080132464, 0.0],
              [-80.871181918339104, 34.436029613532291, 0.0],
              [-80.871292518648076, 34.43582348003136, 0.0],
              [-80.871624318098824, 34.435594413431936, 0.0],
              [-80.872204784371263, 34.436121280347528, 0.0],
              [-80.873448917969498, 34.435800613087984, 0.0],
              [-80.874167718189895, 34.435915080141072, 0.0],
              [-80.874527184587492, 34.43580061331722, 0.0],
              [-80.874775918320495, 34.435548613687416, 0.0],
              [-80.874969517725887, 34.435319480042217, 0.0],
              [-80.875163117683712, 34.434678080108938, 0.0],
              [-80.875190718469099, 34.434105479610132, 0.0],
              [-80.875107785171977, 34.433899280048266, 0.0],
              [-80.875135384347516, 34.433372413706266, 0.0],
              [-80.875273718491627, 34.433189213541112, 0.0],
              [-80.875826585282709, 34.433051813264221, 0.0],
              [-80.876324185014113, 34.433189212853151, 0.0],
              [-80.877734184828228, 34.433257813604371, 0.0],
              [-80.87795531856672, 34.433349479417643, 0.0],
              [-80.878535917639383, 34.433853413260302, 0.0],
              [-80.878839984490455, 34.433990813660181, 0.0],
              [-80.879337585024373, 34.43401368008368, 0.0],
              [-80.879807718066203, 34.433670080157064, 0.0],
              [-80.879918185060106, 34.433463880243004, 0.0],
              [-80.880415784834, 34.432960013233568, 0.0],
              [-80.880747518816094, 34.432822479923821, 0.0],
              [-80.88132811821535, 34.43305161274094, 0.0],
              [-80.881853384946993, 34.433074480178973, 0.0],
              [-80.883014517900136, 34.432868212790339, 0.0],
              [-80.883594984524862, 34.432661879520033, 0.0],
              [-80.885779118025383, 34.433257280256448, 0.0],
              [-80.886442584530784, 34.43371541279042, 0.0],
              [-80.887161518415525, 34.433852812901108, 0.0],
              [-80.887852718324822, 34.434104680307208, 0.0],
              [-80.887948784433448, 34.434247879489504, 0.0],
              [-80.887990918027398, 34.434310813693543, 0.0],
              [-80.887769784335759, 34.434723213328446, 0.0],
              [-80.887769918206132, 34.435181279466335, 0.0],
              [-80.888184718454781, 34.435753880066066, 0.0],
              [-80.888848318418312, 34.436464013569051, 0.0],
              [-80.888710118702463, 34.436945013236674, 0.0],
              [-80.888295384631775, 34.437013679895379, 0.0],
              [-80.888267784855188, 34.437105279403021, 0.0],
              [-80.888129584602737, 34.437151213276813, 0.0],
              [-80.888350784908994, 34.437678013151576, 0.0],
              [-80.888350918639958, 34.438113213333459, 0.0],
              [-80.888046785429069, 34.438342479983795, 0.0],
              [-80.887632118447044, 34.438342479697113, 0.0],
              [-80.887106718024185, 34.437907280359049, 0.0],
              [-80.886443118290217, 34.437174413165174, 0.0],
              [-80.885585984882283, 34.436624679608755, 0.0],
              [-80.884977717945716, 34.436418479413412, 0.0],
              [-80.884811785167898, 34.436212412806043, 0.0],
              [-80.884701185037869, 34.4358460127699, 0.0],
              [-80.884231184893736, 34.435456613287485, 0.0],
              [-80.883622918334879, 34.435319212913328, 0.0],
              [-80.883180585470669, 34.435433679705646, 0.0],
              [-80.882710584687402, 34.435410812758555, 0.0],
              [-80.881798317914047, 34.435090213082042, 0.0],
              [-80.881328184891927, 34.435181879485143, 0.0],
              [-80.880830718164617, 34.435456679795188, 0.0],
              [-80.880471185450531, 34.435777413410619, 0.0],
              [-80.87986311831942, 34.435892080212433, 0.0],
              [-80.879780118135784, 34.435983679538552, 0.0],
              [-80.879780118409855, 34.436373079816214, 0.0],
              [-80.88011198529469, 34.436602212839269, 0.0],
              [-80.88011191763367, 34.436716679497927, 0.0],
              [-80.879724718556659, 34.43701447956002, 0.0],
              [-80.87950358520267, 34.437381013670283, 0.0],
              [-80.87928238435525, 34.437518480070672, 0.0],
              [-80.878867718656139, 34.43751848008759, 0.0],
              [-80.878121184688112, 34.437381079694838, 0.0],
              [-80.877734118282135, 34.437174880052915, 0.0],
              [-80.877042918428685, 34.436602279692586, 0.0],
              [-80.876711185392622, 34.436487812901007, 0.0],
              [-80.876130585065738, 34.436487680267845, 0.0],
              [-80.875715917849647, 34.436602413258527, 0.0],
              [-80.875494785007788, 34.436854279789529, 0.0],
              [-80.875826517853213, 34.438137080306745, 0.0],
              [-80.875632985044533, 34.438297412806293, 0.0],
              [-80.87480358494723, 34.4382286803664, 0.0],
              [-80.874444185416451, 34.438389013159664, 0.0],
              [-80.874388785503811, 34.438480680062924, 0.0],
              [-80.874388784612833, 34.438915880248189, 0.0],
              [-80.874554784715514, 34.439259479997482, 0.0],
              [-80.874803584485562, 34.439488479807736, 0.0],
              [-80.875328784814968, 34.43983207971575, 0.0],
              [-80.87571598481091, 34.440221613382846, 0.0],
              [-80.876047718819635, 34.440725480346529, 0.0],
              [-80.8763517849127, 34.441527279533865, 0.0],
              [-80.876296384604544, 34.441802213424545, 0.0],
              [-80.876130518191104, 34.441870879987725, 0.0],
              [-80.87590938440448, 34.441825079725845, 0.0],
              [-80.875549918376294, 34.441412813673914, 0.0],
              [-80.875522184510459, 34.441229479514838, 0.0],
              [-80.87530098467272, 34.440931812988964, 0.0],
              [-80.87502458488683, 34.44095468002692, 0.0],
              [-80.874803384796749, 34.441412813482842, 0.0],
              [-80.873973918028227, 34.441298279577161, 0.0],
              [-80.873669785241077, 34.440977613348394, 0.0],
              [-80.873642184503282, 34.440840079917237, 0.0],
              [-80.873089317899229, 34.440290412963648, 0.0],
              [-80.872840384362064, 34.440198680078304, 0.0],
              [-80.87242571765168, 34.440290279709664, 0.0],
              [-80.872149185407224, 34.440519413308586, 0.0],
              [-80.871706717927069, 34.441366880130332, 0.0],
              [-80.871762118086536, 34.441848013510302, 0.0],
              [-80.871927918555571, 34.442168679954776, 0.0],
              [-80.872702118827604, 34.442901679880933, 0.0],
              [-80.872950917962839, 34.443520212978953, 0.0],
              [-80.872784984860246, 34.443703479518632, 0.0],
              [-80.872536184960452, 34.443680613283391, 0.0],
              [-80.872287384370097, 34.443520279802861, 0.0],
              [-80.871817318553738, 34.442810080246581, 0.0],
              [-80.871457918224237, 34.442581012757728, 0.0],
              [-80.871208985292, 34.442626880243616, 0.0],
              [-80.871070784948117, 34.442787213245445, 0.0],
              [-80.871043118317473, 34.443428613203437, 0.0],
              [-80.871181385315737, 34.443818012833376, 0.0],
              [-80.87126418485704, 34.443863813546713, 0.0],
              [-80.871706517787445, 34.445100679800035, 0.0],
              [-80.871789385365474, 34.445192413531622, 0.0],
              [-80.872287118025483, 34.4468646134045, 0.0],
              [-80.872508318517163, 34.447185212952675, 0.0],
              [-80.872923118208703, 34.447551813059889, 0.0],
              [-80.874056585302526, 34.448124479895377, 0.0],
              [-80.874554318237458, 34.448720013098885, 0.0],
              [-80.874581985405158, 34.448903279564668, 0.0],
              [-80.874803318390448, 34.449109413405488, 0.0],
              [-80.875439184487362, 34.449842413640042, 0.0],
              [-80.875798718452359, 34.449796613036263, 0.0],
              [-80.8761027852197, 34.449498879923709, 0.0],
              [-80.876268785080754, 34.449109479403369, 0.0],
              [-80.87660058464563, 34.449109413557416, 0.0],
              [-80.877264184976013, 34.4494302135505, 0.0],
              [-80.877568318085892, 34.449086479923182, 0.0],
              [-80.87754071837, 34.44890328008443, 0.0],
              [-80.877098317764862, 34.448147279431645, 0.0],
              [-80.877125918508739, 34.447780812965149, 0.0],
              [-80.877430118122547, 34.447574679728177, 0.0],
              [-80.877651317873784, 34.447643413566311, 0.0],
              [-80.878287185047739, 34.44817021318481, 0.0],
              [-80.879476184947379, 34.448124280180721, 0.0],
              [-80.879752784431943, 34.448307613395862, 0.0],
              [-80.879697385306727, 34.44855947971363, 0.0],
              [-80.878867917656194, 34.449430079749625, 0.0],
              [-80.878480918801571, 34.450117279468948, 0.0],
              [-80.87784491869148, 34.450690013037374, 0.0],
              [-80.87704298453022, 34.45153747996293, 0.0],
              [-80.876683585151483, 34.451835413040342, 0.0],
              [-80.876655718650227, 34.452316479552273, 0.0],
              [-80.877568318403704, 34.452614213677677, 0.0],
              [-80.877955584996457, 34.452568413303617, 0.0],
              [-80.878287318580675, 34.451720679938788, 0.0],
              [-80.879614584573275, 34.450804412868379, 0.0],
              [-80.880582384529177, 34.450598213447677, 0.0],
              [-80.880886584449954, 34.450781480121456, 0.0],
              [-80.880803717888739, 34.451377079709758, 0.0],
              [-80.880831317869323, 34.451697679892071, 0.0],
              [-80.881107918593287, 34.451995479952352, 0.0],
              [-80.881439717995931, 34.452178813344773, 0.0],
              [-80.881605585269014, 34.45259108000171, 0.0],
              [-80.881439785203696, 34.453163812770327, 0.0],
              [-80.881246185044233, 34.453530279841672, 0.0],
              [-80.880831518548192, 34.453736412987439, 0.0],
              [-80.880471784790942, 34.453690680320832, 0.0],
              [-80.880084784444733, 34.453209612751124, 0.0],
              [-80.879808184902899, 34.453049280277199, 0.0],
              [-80.879421118139078, 34.453049213118391, 0.0],
              [-80.879172184612372, 34.453370013057025, 0.0],
              [-80.879172117979238, 34.453782413217169, 0.0],
              [-80.879946518229175, 34.454629879716961, 0.0],
              [-80.880776118162999, 34.45524827972217, 0.0],
              [-80.881080318342185, 34.455317013352492, 0.0],
              [-80.881467518156867, 34.455225280143431, 0.0],
              [-80.881467384353201, 34.454469413496994, 0.0],
              [-80.88171631792747, 34.454377679397204, 0.0],
              [-80.882020384641635, 34.45451521335368, 0.0],
              [-80.882656584823522, 34.455042012754888, 0.0],
              [-80.882960784388175, 34.455110679464774, 0.0],
              [-80.883320185350385, 34.455019012923202, 0.0],
              [-80.88359678468359, 34.45478988013528, 0.0],
              [-80.883734985022841, 34.454217280001728, 0.0],
              [-80.883900918002439, 34.454102813584086, 0.0],
              [-80.885394118404164, 34.453919279928002, 0.0],
              [-80.885974785431458, 34.454148279602371, 0.0],
              [-80.886168518036968, 34.454308612789269, 0.0],
              [-80.886085585082157, 34.454446079887404, 0.0],
              [-80.885338917928408, 34.454881413323037, 0.0],
              [-80.885173118558086, 34.455179279440188, 0.0],
              [-80.885200718057092, 34.45566027945447, 0.0],
              [-80.885421984531021, 34.456072613144251, 0.0],
              [-80.88539431880973, 34.456347479617548, 0.0],
              [-80.885117784774536, 34.456347479511678, 0.0],
              [-80.88495191839516, 34.456233012806003, 0.0],
              [-80.884868784531548, 34.45604968030019, 0.0],
              [-80.884592385094834, 34.455843613250444, 0.0],
              [-80.884039318060047, 34.455935213394696, 0.0],
              [-80.882877918131186, 34.456553812734384, 0.0],
              [-80.882380118294279, 34.456691279430174, 0.0],
              [-80.882269585213365, 34.456874613552486, 0.0],
              [-80.882269518784327, 34.457309813090838, 0.0],
              [-80.882739785150548, 34.45882161288371, 0.0],
              [-80.883126985323756, 34.459279813336906, 0.0],
              [-80.884731118659687, 34.460585280022592, 0.0],
              [-80.885450185303924, 34.461066213400102, 0.0],
              [-80.885754385020661, 34.461180812962645, 0.0],
              [-80.886252184949441, 34.461134880040319, 0.0],
              [-80.886390385419531, 34.461043279743983, 0.0],
              [-80.886500985399721, 34.460745413518843, 0.0],
              [-80.88627971836209, 34.460356013667599, 0.0],
              [-80.886334984628633, 34.45998947971443, 0.0],
              [-80.886500918708634, 34.459920813106926, 0.0],
              [-80.886722118480392, 34.460012412848577, 0.0],
              [-80.887551918190979, 34.460814012702485, 0.0],
              [-80.887717917853806, 34.461157613144806, 0.0],
              [-80.887386117621617, 34.461524212900279, 0.0],
              [-80.887303184391456, 34.461799012974204, 0.0],
              [-80.887469118512755, 34.462303079574703, 0.0],
              [-80.887773317750316, 34.462577813256608, 0.0],
              [-80.88818811832293, 34.462738080283458, 0.0],
              [-80.888769117957025, 34.463402279431634, 0.0],
              [-80.888796717665471, 34.463585679650933, 0.0],
              [-80.889488317780049, 34.464387280236032, 0.0],
              [-80.890096785185321, 34.464845213147441, 0.0],
              [-80.890373385072849, 34.464982679472719, 0.0],
              [-80.89095411784173, 34.464913813083669, 0.0],
              [-80.891396518178553, 34.464409812971653, 0.0],
              [-80.891728384679965, 34.464409879820622, 0.0],
              [-80.892558185221361, 34.465119813243099, 0.0],
              [-80.893000718570335, 34.465073879674534, 0.0],
              [-80.893221918704896, 34.464776079439844, 0.0],
              [-80.893221917893996, 34.464203479393952, 0.0],
              [-80.893000584611499, 34.463859879881291, 0.0],
              [-80.893000517618873, 34.46358501304465, 0.0],
              [-80.89360891821002, 34.463195480129166, 0.0],
              [-80.894078918259027, 34.462783013524259, 0.0],
              [-80.894383184584981, 34.462966279794813, 0.0],
              [-80.894549318307256, 34.463653412749238, 0.0],
              [-80.894991918404429, 34.464088612991013, 0.0],
              [-80.895351384527572, 34.464180213257329, 0.0],
              [-80.896761784778803, 34.463859212869856, 0.0],
              [-80.896900118514452, 34.463698813099697, 0.0],
              [-80.897038184356404, 34.463126212946968, 0.0],
              [-80.897453118767544, 34.463194813550189, 0.0],
              [-80.897619118545762, 34.463469612983808, 0.0],
              [-80.89761911799917, 34.463630012933201, 0.0],
              [-80.897701984781918, 34.463675812692863, 0.0],
              [-80.897563918222104, 34.464225613612555, 0.0],
              [-80.897564118284436, 34.464867012887382, 0.0],
              [-80.897813118209015, 34.465027279959543, 0.0],
              [-80.898642784546581, 34.465256213163464, 0.0],
              [-80.899278917759474, 34.465187413465898, 0.0],
              [-80.899914784785437, 34.464751879808055, 0.0],
              [-80.900163584778866, 34.464477013084938, 0.0],
              [-80.900163584492461, 34.463996013328099, 0.0],
              [-80.900052784494108, 34.463652413463024, 0.0],
              [-80.900273918088971, 34.462896413045314, 0.0],
              [-80.900356584534663, 34.461865613263555, 0.0],
              [-80.900716117691402, 34.461659413468389, 0.0],
              [-80.900964985154431, 34.461819612843314, 0.0],
              [-80.90160138449663, 34.463125212779083, 0.0],
              [-80.901629385162849, 34.464293412881403, 0.0],
              [-80.901878318783645, 34.464453679559163, 0.0],
              [-80.902265517647805, 34.464453612743469, 0.0],
              [-80.902956917696073, 34.464270213538157, 0.0],
              [-80.903316518478206, 34.464270080248724, 0.0],
              [-80.903537718253673, 34.464476213518829, 0.0],
              [-80.903399584768579, 34.465209279358, 0.0],
              [-80.903482784321, 34.465713213664017, 0.0],
              [-80.903759517771149, 34.465988013549939, 0.0],
              [-80.904589318609709, 34.46619401297572, 0.0],
              [-80.904782918440247, 34.466377213223382, 0.0],
              [-80.904921317944002, 34.46669781308912, 0.0],
              [-80.906277117850266, 34.468346813436867, 0.0],
              [-80.906747318028337, 34.468667213360533, 0.0],
              [-80.907051584771821, 34.468735880222205, 0.0],
              [-80.90760471842475, 34.468644079947687, 0.0],
              [-80.908240717934689, 34.468163012818195, 0.0],
              [-80.908821384526334, 34.468071080282932, 0.0],
              [-80.909568317666952, 34.468277079833193, 0.0],
              [-80.910536317911266, 34.468368479875814, 0.0],
              [-80.911393718164504, 34.46832227934258, 0.0],
              [-80.911642717692459, 34.468482479840326, 0.0],
              [-80.911864317767211, 34.469192613169511, 0.0],
              [-80.912666584772367, 34.469902480322986, 0.0],
              [-80.913053784338032, 34.469902279385728, 0.0],
              [-80.913413385322073, 34.469810613548738, 0.0],
              [-80.91399398485926, 34.469421013465478, 0.0],
              [-80.914630118052926, 34.469283413318557, 0.0],
              [-80.91656638478716, 34.469763680089571, 0.0],
              [-80.917103584612704, 34.469986012926114, 0.0],
              [-80.917119717876389, 34.469992613166788, 0.0],
              [-80.917368784438452, 34.470359013161271, 0.0],
              [-80.917894518415522, 34.470839813034367, 0.0],
              [-80.918115984771973, 34.471320813107184, 0.0],
              [-80.91723131830436, 34.472100012670062, 0.0],
              [-80.917397718420943, 34.472855813060562, 0.0],
              [-80.917397918429216, 34.473474279780405, 0.0],
              [-80.917176784681246, 34.473749280176861, 0.0],
              [-80.916817317827849, 34.473749412774559, 0.0],
              [-80.916623518058245, 34.473634879660118, 0.0],
              [-80.916319318725627, 34.47361221291618, 0.0],
              [-80.915849118350081, 34.473795479413752, 0.0],
              [-80.914383584462243, 34.474964280180252, 0.0],
              [-80.91410711802348, 34.474918613278547, 0.0],
              [-80.913885517930808, 34.474139813313514, 0.0],
              [-80.913747184938174, 34.473956680090843, 0.0],
              [-80.912004384807759, 34.473384479353662, 0.0],
              [-80.91067651855623, 34.472812212658809, 0.0],
              [-80.910289117769125, 34.472743679815501, 0.0],
              [-80.9093487184997, 34.472766880076939, 0.0],
              [-80.909265785282429, 34.472835612939079, 0.0],
              [-80.909265918611283, 34.473133413132999, 0.0],
              [-80.909348918419127, 34.473179212876097, 0.0],
              [-80.909459717883351, 34.473522813109398, 0.0],
              [-80.90981938542437, 34.474095279931319, 0.0],
              [-80.910594317951038, 34.475103012848862, 0.0],
              [-80.910594518536726, 34.475400679861622, 0.0],
              [-80.910456185115123, 34.475492412953123, 0.0],
              [-80.910124184459022, 34.47540081288448, 0.0],
              [-80.908989918612761, 34.474576613422784, 0.0],
              [-80.907080918226413, 34.47308821304641, 0.0],
              [-80.906776518042477, 34.472996612765691, 0.0],
              [-80.905670184996836, 34.473226079829125, 0.0],
              [-80.905089385280718, 34.473088679839854, 0.0],
              [-80.904785118447364, 34.472791079766793, 0.0],
              [-80.904342118699901, 34.471897812667436, 0.0],
              [-80.90389951841172, 34.471531412741939, 0.0],
              [-80.903429184659743, 34.471279613205837, 0.0],
              [-80.901741585063334, 34.470065879519481, 0.0],
              [-80.901520384964968, 34.46997428009896, 0.0],
              [-80.900939584886913, 34.470066079383912, 0.0],
              [-80.90063551778205, 34.470318013474824, 0.0],
              [-80.900386718168534, 34.470661680043861, 0.0],
              [-80.899999517956331, 34.470661813371279, 0.0],
              [-80.899473785180263, 34.470501480266783, 0.0],
              [-80.899114384921575, 34.470776612821133, 0.0],
              [-80.898921118296741, 34.47192201338158, 0.0],
              [-80.898699918532884, 34.472151013467112, 0.0],
              [-80.898312717844505, 34.472311480123516, 0.0],
              [-80.897372317914801, 34.472380279426588, 0.0],
              [-80.895906184947862, 34.472105879625346, 0.0],
              [-80.894882718237128, 34.471602013026725, 0.0],
              [-80.894716784794866, 34.471006479646384, 0.0],
              [-80.894495385349103, 34.471098213597465, 0.0],
              [-80.89413598537088, 34.471762479730948, 0.0],
              [-80.893831718434924, 34.471739613124605, 0.0],
              [-80.893776317822841, 34.471052479523962, 0.0],
              [-80.893582585298503, 34.470846413335622, 0.0],
              [-80.892974118666004, 34.470663212962528, 0.0],
              [-80.8921995845444, 34.469701280179926, 0.0],
              [-80.891922984598637, 34.46960967937688, 0.0],
              [-80.89167418539725, 34.469724213437495, 0.0],
              [-80.891674118278061, 34.469953212898524, 0.0],
              [-80.892835985180966, 34.471373279699677, 0.0],
              [-80.892891385362333, 34.471900213077781, 0.0],
              [-80.893887518102503, 34.473480612766757, 0.0],
              [-80.894025918539569, 34.473847013540123, 0.0],
              [-80.894053718185688, 34.474969479508992, 0.0],
              [-80.894468718700381, 34.475404679978233, 0.0],
              [-80.89538151776226, 34.475404413184876, 0.0],
              [-80.89579638537279, 34.475564679883007, 0.0],
              [-80.896432717757989, 34.476137279437417, 0.0],
              [-80.896958384884755, 34.476503680045781, 0.0],
              [-80.897650184599229, 34.477671679920505, 0.0],
              [-80.899254784753225, 34.478954212991106, 0.0],
              [-80.89966978442601, 34.479137279560653, 0.0],
              [-80.900361318196474, 34.479068613109824, 0.0],
              [-80.901273985202167, 34.478610213483847, 0.0],
              [-80.901633585084184, 34.478610013229471, 0.0],
              [-80.901799584735713, 34.478701613436442, 0.0],
              [-80.902186984469651, 34.479365812708529, 0.0],
              [-80.903238317609933, 34.479800813071542, 0.0],
              [-80.903597984525874, 34.480167213099485, 0.0],
              [-80.903266518748907, 34.481793813199197, 0.0],
              [-80.903101118224328, 34.483443079572226, 0.0],
              [-80.902603117693857, 34.483351479614441, 0.0],
              [-80.902381718518726, 34.483145412855357, 0.0],
              [-80.902021784617631, 34.482252213046088, 0.0],
              [-80.90174518427024, 34.481839879813435, 0.0],
              [-80.901357784267304, 34.481473412822702, 0.0],
              [-80.900804517612158, 34.481267479681847, 0.0],
              [-80.89994698455547, 34.481336280170694, 0.0],
              [-80.899172518151744, 34.481748879579222, 0.0],
              [-80.898508784866138, 34.48232167939576, 0.0],
              [-80.898398184464128, 34.482619479437922, 0.0],
              [-80.898398384511708, 34.48328381299585, 0.0],
              [-80.89850918495128, 34.483467013001871, 0.0],
              [-80.900003184399907, 34.484451679389984, 0.0],
              [-80.900501318665277, 34.485070013197607, 0.0],
              [-80.901027118027429, 34.485917413616534, 0.0],
              [-80.901193184539423, 34.486398413261561, 0.0],
              [-80.902050918678086, 34.48708547986265, 0.0],
              [-80.902189318391748, 34.487383213516559, 0.0],
              [-80.902134184795898, 34.487910079568877, 0.0],
              [-80.901636517790351, 34.488666012665604, 0.0],
              [-80.901553584379187, 34.488986813531447, 0.0],
              [-80.901636717961651, 34.48919287991464, 0.0],
              [-80.902079318630939, 34.489444813587795, 0.0],
              [-80.902466717988716, 34.489582079587677, 0.0],
              [-80.902356118439954, 34.489788280257343, 0.0],
              [-80.902162518090321, 34.489880012967184, 0.0],
              [-80.901304918393805, 34.489926079946088, 0.0],
              [-80.900945317949706, 34.490155213425616, 0.0],
              [-80.900060317800026, 34.490911279377173, 0.0],
              [-80.899866717657588, 34.491186212710296, 0.0],
              [-80.899673118555498, 34.491736013493934, 0.0],
              [-80.899673184935665, 34.492217213407841, 0.0],
              [-80.898401317802538, 34.494805813602014, 0.0],
              [-80.898345985430709, 34.495195212912627, 0.0],
              [-80.897737784343065, 34.496226080208686, 0.0],
              [-80.897378118531876, 34.496615679805913, 0.0],
              [-80.894169185238354, 34.498998479752444, 0.0],
              [-80.893947984322295, 34.49943367982695, 0.0],
              [-80.89383731854268, 34.500153679949264, 0.0],
              [-80.893251118153799, 34.500153479735872, 0.0],
              [-80.893010385269505, 34.500153479812056, 0.0],
              [-80.892977784950517, 34.499983679917165, 0.0],
            ],
            [
              [-80.89273051818671, 34.498586479862944, 0.0],
              [-80.892509118066371, 34.498586479701416, 0.0],
              [-80.892149384966743, 34.49879281357061, 0.0],
              [-80.892149518472593, 34.498976012732719, 0.0],
              [-80.892260184623012, 34.499067612897171, 0.0],
              [-80.892730518053142, 34.499090413421477, 0.0],
              [-80.892951918382622, 34.498952880232977, 0.0],
              [-80.892979385408253, 34.498723879863384, 0.0],
              [-80.89273051818671, 34.498586479862944, 0.0],
            ],
            [
              [-80.897930784603318, 34.494072813254036, 0.0],
              [-80.897764717976003, 34.49377507938754, 0.0],
              [-80.897488117957309, 34.493821013061478, 0.0],
              [-80.896188184543774, 34.495218613497627, 0.0],
              [-80.896298717640789, 34.495562213442874, 0.0],
              [-80.896852184528711, 34.495470413029864, 0.0],
              [-80.897405384513533, 34.494989280061091, 0.0],
              [-80.897930918688104, 34.494279013010207, 0.0],
              [-80.897930784603318, 34.494072813254036, 0.0],
            ],
            [
              [-80.896516117833372, 34.477603280165958, 0.0],
              [-80.896405518027123, 34.477603279531735, 0.0],
              [-80.896184118794679, 34.477786679629112, 0.0],
              [-80.896184184570828, 34.47803861360584, 0.0],
              [-80.897041917713153, 34.478908880283797, 0.0],
              [-80.897290784284706, 34.478908813430202, 0.0],
              [-80.897401385337631, 34.47881721314365, 0.0],
              [-80.897401384526091, 34.478244479830259, 0.0],
              [-80.896516117833372, 34.477603280165958, 0.0],
            ],
            [
              [-80.891258518788959, 34.465967480295014, 0.0],
              [-80.890898985054037, 34.465921813279849, 0.0],
              [-80.890235318122151, 34.465990679903634, 0.0],
              [-80.890124717924792, 34.466128079991826, 0.0],
              [-80.890124718240699, 34.466288479727893, 0.0],
              [-80.890373718577592, 34.466494612878414, 0.0],
              [-80.891341584781188, 34.466425679790824, 0.0],
              [-80.891535118146663, 34.466242413507594, 0.0],
              [-80.891535184729591, 34.466127813128878, 0.0],
              [-80.891258518788959, 34.465967480295014, 0.0],
            ],
            [
              [-80.867974317972966, 34.439694613664734, 0.0],
              [-80.868195584746687, 34.439511479811983, 0.0],
              [-80.868112718010309, 34.43912201364796, 0.0],
              [-80.86833378455205, 34.4390302796767, 0.0],
              [-80.868914518829925, 34.439144880106703, 0.0],
              [-80.869412117773734, 34.439534280360633, 0.0],
              [-80.869826784727778, 34.439465613442295, 0.0],
              [-80.869965118113313, 34.439351079965292, 0.0],
              [-80.869937584397334, 34.437999612799935, 0.0],
              [-80.86767071825119, 34.43586921307292, 0.0],
              [-80.867145385429538, 34.435915013577208, 0.0],
              [-80.867007184822697, 34.436052413669863, 0.0],
              [-80.867007185381894, 34.436625013546291, 0.0],
              [-80.86681351800128, 34.436831212744153, 0.0],
              [-80.866315918300629, 34.436716612895729, 0.0],
              [-80.866094784380181, 34.436579080116928, 0.0],
              [-80.866067317956848, 34.435731680131461, 0.0],
              [-80.866454518327771, 34.434426013260939, 0.0],
              [-80.866454518303897, 34.433738813076609, 0.0],
              [-80.866095185417947, 34.43316608008459, 0.0],
              [-80.865901584586126, 34.433074479847114, 0.0],
              [-80.865625184695617, 34.433211879620067, 0.0],
              [-80.865293518158651, 34.433166013241596, 0.0],
              [-80.865044718479936, 34.432959880257187, 0.0],
              [-80.864878785410767, 34.432662080216744, 0.0],
              [-80.864851185235636, 34.432249680363441, 0.0],
              [-80.864243318359783, 34.431562479435627, 0.0],
              [-80.864298585293724, 34.430989813378901, 0.0],
              [-80.864381517932983, 34.430921080218063, 0.0],
              [-80.864436918117505, 34.43041708009838, 0.0],
              [-80.864271118638527, 34.430119280274944, 0.0],
              [-80.863966984456752, 34.429958879918154, 0.0],
              [-80.863303518709557, 34.429844412736642, 0.0],
              [-80.862363584684459, 34.429867079694453, 0.0],
              [-80.862335917976026, 34.430325412845015, 0.0],
              [-80.862418784614391, 34.430371213354931, 0.0],
              [-80.862474118088286, 34.430669013202767, 0.0],
              [-80.862335784708051, 34.43098968028842, 0.0],
              [-80.862169917853507, 34.431127079547046, 0.0],
              [-80.861838117753862, 34.431081212755466, 0.0],
              [-80.861534118421972, 34.430829212878031, 0.0],
              [-80.861423585444655, 34.430187813210985, 0.0],
              [-80.861589585145239, 34.42952347979594, 0.0],
              [-80.861589717898468, 34.428607213034532, 0.0],
              [-80.861313384941241, 34.427782480011565, 0.0],
              [-80.861313518299823, 34.4274160133726, 0.0],
              [-80.861921717950068, 34.426843412930225, 0.0],
              [-80.861811318004186, 34.426270813030186, 0.0],
              [-80.861617918769468, 34.425995879568191, 0.0],
              [-80.860650585079838, 34.425400213501497, 0.0],
              [-80.860650585306942, 34.425033679975947, 0.0],
              [-80.860761184766702, 34.424827479611665, 0.0],
              [-80.860733584965303, 34.424621412978119, 0.0],
              [-80.8603743176769, 34.424369413703403, 0.0],
              [-80.859268584796183, 34.424163013215136, 0.0],
              [-80.858660385031286, 34.424254613545422, 0.0],
              [-80.857941718596464, 34.42452941320937, 0.0],
              [-80.857830985143508, 34.42464387969973, 0.0],
              [-80.857830918779143, 34.424987479993021, 0.0],
              [-80.858107184334287, 34.425354012821259, 0.0],
              [-80.858134918776997, 34.425606013094338, 0.0],
              [-80.857858385174268, 34.425857879896384, 0.0],
              [-80.857581985246242, 34.425835012954479, 0.0],
              [-80.856835584520496, 34.425560013082233, 0.0],
              [-80.855038784773583, 34.425651412941392, 0.0],
              [-80.854817584798923, 34.426132480346929, 0.0],
              [-80.854955318821453, 34.427896279634375, 0.0],
              [-80.855120985048643, 34.428858413373383, 0.0],
              [-80.855369385064193, 34.429797613426565, 0.0],
              [-80.855839185325934, 34.430393280259885, 0.0],
              [-80.857165918733358, 34.431447213191142, 0.0],
              [-80.857857118025308, 34.431447280099682, 0.0],
              [-80.85893531769652, 34.431126680349237, 0.0],
              [-80.859405317841976, 34.431058013336497, 0.0],
              [-80.859626384483505, 34.431172613126598, 0.0],
              [-80.859709318226038, 34.431378679618213, 0.0],
              [-80.859681584528062, 34.431676613650694, 0.0],
              [-80.858437518357874, 34.432157480012684, 0.0],
              [-80.858271517964553, 34.432409480041201, 0.0],
              [-80.858271385448262, 34.43286761281616, 0.0],
              [-80.858796585256997, 34.433692280056711, 0.0],
              [-80.859238784504058, 34.434081812986236, 0.0],
              [-80.860482784866903, 34.434608813544223, 0.0],
              [-80.860980385275241, 34.435021080043363, 0.0],
              [-80.861588518651217, 34.435708412957759, 0.0],
              [-80.861781918332184, 34.436281079692407, 0.0],
              [-80.861864918077998, 34.436326879614718, 0.0],
              [-80.862058184934511, 34.436785012780838, 0.0],
              [-80.862832118542102, 34.438067879726198, 0.0],
              [-80.863108518038388, 34.438342812977133, 0.0],
              [-80.864048517832828, 34.438778079656622, 0.0],
              [-80.866232585264299, 34.439625813648931, 0.0],
              [-80.867227918609004, 34.439625879495964, 0.0],
              [-80.867531984575521, 34.439717479940313, 0.0],
              [-80.867974317972966, 34.439694613664734, 0.0],
            ],
            [
              [-80.748961784650149, 34.381496213396488, 0.0],
              [-80.748961118305218, 34.381015213639003, 0.0],
              [-80.748601518153052, 34.380740679825152, 0.0],
              [-80.74752351797612, 34.380421080255495, 0.0],
              [-80.746638984533732, 34.380032479963717, 0.0],
              [-80.746224718271407, 34.380124679852067, 0.0],
              [-80.745811718600038, 34.381224612967358, 0.0],
              [-80.745674517928379, 34.381889013565655, 0.0],
              [-80.745758185575454, 34.382484479761061, 0.0],
              [-80.746172918899759, 34.382667413594739, 0.0],
              [-80.746836118921379, 34.382758279892052, 0.0],
              [-80.747913518669165, 34.382619679786316, 0.0],
              [-80.748134384978044, 34.382527879681945, 0.0],
              [-80.748713785040792, 34.382023413767186, 0.0],
              [-80.748961784650149, 34.381496213396488, 0.0],
            ],
            [
              [-80.739235385609206, 34.380658279903351, 0.0],
              [-80.739068918702387, 34.380108679757441, 0.0],
              [-80.73884731808127, 34.3796508137928, 0.0],
              [-80.738515585290131, 34.379490813781608, 0.0],
              [-80.738184117899976, 34.379536879532907, 0.0],
              [-80.737935717967545, 34.379789012889873, 0.0],
              [-80.737991918597686, 34.380476213485018, 0.0],
              [-80.738379385689768, 34.381002679522418, 0.0],
              [-80.738683718392437, 34.381254413129277, 0.0],
              [-80.738932319023789, 34.38125421378254, 0.0],
              [-80.739180785292589, 34.381139413690924, 0.0],
              [-80.739235385609206, 34.380658279903351, 0.0],
            ],
            [
              [-80.787997918592282, 34.379075479618571, 0.0],
              [-80.787831985530076, 34.379212813463646, 0.0],
              [-80.787803518163074, 34.379785612970124, 0.0],
              [-80.787996718867731, 34.380060480439816, 0.0],
              [-80.788991117908935, 34.380267412894227, 0.0],
              [-80.789212185311627, 34.380153080157861, 0.0],
              [-80.789212385532167, 34.379969813100622, 0.0],
              [-80.788108384937559, 34.379075479636512, 0.0],
              [-80.787997918592282, 34.379075479618571, 0.0],
            ],
            [
              [-80.762054118353959, 34.379764613150371, 0.0],
              [-80.761529385105177, 34.379558013348458, 0.0],
              [-80.760728118158212, 34.37962601375397, 0.0],
              [-80.76036878563113, 34.379763013138152, 0.0],
              [-80.760368385066997, 34.380106613677818, 0.0],
              [-80.760505985487924, 34.380496213109836, 0.0],
              [-80.760864585498595, 34.380817280330803, 0.0],
              [-80.761196185098981, 34.380817612840211, 0.0],
              [-80.7620807853733, 34.380451879882344, 0.0],
              [-80.76224691853966, 34.380245880414087, 0.0],
              [-80.762274918083989, 34.379971013601761, 0.0],
              [-80.762054118353959, 34.379764613150371, 0.0],
            ],
            [
              [-80.755588185405287, 34.380377013184997, 0.0],
              [-80.755035785445784, 34.380239013120487, 0.0],
              [-80.754648984633604, 34.380330280150567, 0.0],
              [-80.754565785232089, 34.380421879749363, 0.0],
              [-80.754758384511845, 34.381017680045609, 0.0],
              [-80.754896384864182, 34.381109413287135, 0.0],
              [-80.755476584930221, 34.381109879930733, 0.0],
              [-80.755808318981337, 34.380995813337037, 0.0],
              [-80.755919184915584, 34.380675079648356, 0.0],
              [-80.755588185405287, 34.380377013184997, 0.0],
            ],
            [
              [-80.753488385059271, 34.380352080211168, 0.0],
              [-80.75324018450938, 34.380168612820064, 0.0],
              [-80.752714918905639, 34.380328413461818, 0.0],
              [-80.752576518731573, 34.380488612825921, 0.0],
              [-80.752575985646757, 34.380832212853335, 0.0],
              [-80.752769118249503, 34.38096981365927, 0.0],
              [-80.753266718085129, 34.380901613279924, 0.0],
              [-80.753487985593395, 34.380649880373802, 0.0],
              [-80.753488385059271, 34.380352080211168, 0.0],
            ],
            [
              [-80.745909384865826, 34.371947013724395, 0.0],
              [-80.745908518440359, 34.371374279778294, 0.0],
              [-80.746212318808233, 34.371305280382316, 0.0],
              [-80.746516385688111, 34.371465280438251, 0.0],
              [-80.746765118431085, 34.371487879500805, 0.0],
              [-80.747013317853074, 34.371212813508471, 0.0],
              [-80.747123385682229, 34.370937813240317, 0.0],
              [-80.746569984516867, 34.370342812961603, 0.0],
              [-80.746017318194703, 34.370114280070389, 0.0],
              [-80.743999784842387, 34.369566480159314, 0.0],
              [-80.743281585689871, 34.369613079905719, 0.0],
              [-80.742977785688581, 34.36963627999647, 0.0],
              [-80.742784718656239, 34.369773880406164, 0.0],
              [-80.742729917943464, 34.370140479636973, 0.0],
              [-80.742896185633484, 34.370506813748349, 0.0],
              [-80.743089718028941, 34.370667013672644, 0.0],
              [-80.743642184847772, 34.370574813283092, 0.0],
              [-80.743890785431091, 34.3706662136509, 0.0],
              [-80.74483158472438, 34.371741879795707, 0.0],
              [-80.745274185632312, 34.372108013429042, 0.0],
              [-80.745660984869843, 34.37215348020019, 0.0],
              [-80.745909384865826, 34.371947013724395, 0.0],
            ],
            [
              [-80.739662785094978, 34.369502079882302, 0.0],
              [-80.739938585476878, 34.369249879907365, 0.0],
              [-80.739938518860399, 34.369112413468557, 0.0],
              [-80.739772518859894, 34.368952213041894, 0.0],
              [-80.739054185265644, 34.3688384132764, 0.0],
              [-80.737893985061518, 34.368931013036459, 0.0],
              [-80.73759031804596, 34.369068680146967, 0.0],
              [-80.737562985100539, 34.369252013436295, 0.0],
              [-80.737922585108009, 34.369595280433984, 0.0],
              [-80.739248518923191, 34.369617012994453, 0.0],
              [-80.739662785094978, 34.369502079882302, 0.0],
            ],
            [
              [-80.735213318464886, 34.368063079845705, 0.0],
              [-80.734992118713507, 34.367857080048218, 0.0],
              [-80.733250918343217, 34.367263079962015, 0.0],
              [-80.732864185309751, 34.367286280047665, 0.0],
              [-80.732560585265674, 34.36744701380394, 0.0],
              [-80.732560985367215, 34.367767679965503, 0.0],
              [-80.73280978512804, 34.367973613222354, 0.0],
              [-80.733445585142675, 34.36827088022072, 0.0],
              [-80.733749784780429, 34.368522480043538, 0.0],
              [-80.734329985102747, 34.368636613699728, 0.0],
              [-80.734910118107052, 34.368521480466427, 0.0],
              [-80.735213585528953, 34.368315080076485, 0.0],
              [-80.735213318464886, 34.368063079845705, 0.0],
            ],
            [
              [-80.718377785053733, 34.356440412926197, 0.0],
              [-80.718681384573742, 34.356188213755381, 0.0],
              [-80.718708784861448, 34.356004879671211, 0.0],
              [-80.718266118742108, 34.355295213060792, 0.0],
              [-80.717879117916681, 34.354951880313195, 0.0],
              [-80.717602919042477, 34.354952013781357, 0.0],
              [-80.717299118713271, 34.355066879636468, 0.0],
              [-80.717189319044678, 34.355616679718104, 0.0],
              [-80.717797718621028, 34.356326412901893, 0.0],
              [-80.718377785053733, 34.356440412926197, 0.0],
            ],
            [
              [-80.717431518970173, 34.349706413258154, 0.0],
              [-80.717127317982289, 34.349363080488139, 0.0],
              [-80.716906318688316, 34.349271612993896, 0.0],
              [-80.716354118145745, 34.349455213554634, 0.0],
              [-80.716023185144422, 34.349867879945869, 0.0],
              [-80.715995718609094, 34.35007408038237, 0.0],
              [-80.716327518806395, 34.350348680163009, 0.0],
              [-80.71704538534523, 34.350210680333497, 0.0],
              [-80.717376518420707, 34.349889680193563, 0.0],
              [-80.717431518970173, 34.349706413258154, 0.0],
            ],
            [
              [-80.715494118865792, 34.345653280223118, 0.0],
              [-80.714637718793739, 34.345470613455717, 0.0],
              [-80.714002518933995, 34.345494080096373, 0.0],
              [-80.713643718790877, 34.345677613755022, 0.0],
              [-80.713395518468758, 34.345952680374445, 0.0],
              [-80.71336838460708, 34.346433679870067, 0.0],
              [-80.713534185017551, 34.346616813771981, 0.0],
              [-80.714280318351726, 34.347028612973816, 0.0],
              [-80.714777517934508, 34.347074080053609, 0.0],
              [-80.715301984851422, 34.346890413467861, 0.0],
              [-80.715771118632162, 34.346500613370715, 0.0],
              [-80.715963984609814, 34.346065213078816, 0.0],
              [-80.715797985561068, 34.345836279953645, 0.0],
              [-80.715494118865792, 34.345653280223118, 0.0],
            ],
            [
              [-80.750165984941233, 34.38497601316778, 0.0],
              [-80.750552718746064, 34.385068012989962, 0.0],
              [-80.75082958500191, 34.384678879623735, 0.0],
              [-80.750858118533145, 34.384037613619313, 0.0],
              [-80.750720584697007, 34.383716679768689, 0.0],
              [-80.750582585104297, 34.383602080204838, 0.0],
              [-80.750361584897249, 34.383601813291918, 0.0],
              [-80.750029585495255, 34.383830612963408, 0.0],
              [-80.750028318607718, 34.384701013586138, 0.0],
              [-80.750165984941233, 34.38497601316778, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2386,
        ComID: 9750698,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.469,
        Elevation: 0.0,
        ReachCode: "03050101002296",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.06189906269,
        Shape_Area: 4.667141e-5,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.966396918314487, 35.615455411404263, 0.0],
              [-80.966060384796791, 35.615455611731115, 0.0],
              [-80.96432298524536, 35.616876878124756, 0.0],
              [-80.963930385358424, 35.616991811180078, 0.0],
              [-80.963873984964806, 35.616602411158624, 0.0],
              [-80.964181917937026, 35.616029678118423, 0.0],
              [-80.964938584544868, 35.615548211321169, 0.0],
              [-80.965246385203443, 35.614975278056342, 0.0],
              [-80.965890918512997, 35.614425211711783, 0.0],
              [-80.966058717813155, 35.613944078510144, 0.0],
              [-80.966534918358121, 35.613485678256289, 0.0],
              [-80.966842784727689, 35.61295861105048, 0.0],
              [-80.966870384275907, 35.612523478553818, 0.0],
              [-80.966561384149983, 35.612134410945217, 0.0],
              [-80.965943984922887, 35.611768411365382, 0.0],
              [-80.964877717965734, 35.611311211487426, 0.0],
              [-80.964400985117351, 35.611357278153335, 0.0],
              [-80.96392458434714, 35.611609611643495, 0.0],
              [-80.963503984365644, 35.611701611458152, 0.0],
              [-80.963391717513929, 35.611610078007949, 0.0],
              [-80.963307184816088, 35.611243677817164, 0.0],
              [-80.963082517751687, 35.611014811862901, 0.0],
              [-80.962997985072974, 35.61064847852505, 0.0],
              [-80.962661117715413, 35.610373878550995, 0.0],
              [-80.962660984394773, 35.61019067769319, 0.0],
              [-80.963025184328799, 35.609846811171884, 0.0],
              [-80.963220517601002, 35.608953411616781, 0.0],
              [-80.962995984761974, 35.608793278006985, 0.0],
              [-80.961790185262643, 35.608931611318617, 0.0],
              [-80.961228784176228, 35.608428078338946, 0.0],
              [-80.960892318225092, 35.608497078349991, 0.0],
              [-80.960415784246749, 35.608726611653523, 0.0],
              [-80.960191384188022, 35.608680877965419, 0.0],
              [-80.96016318434414, 35.608520677870381, 0.0],
              [-80.959854117681601, 35.608039877669725, 0.0],
              [-80.959853984303138, 35.607856611672297, 0.0],
              [-80.962149917670118, 35.604465411341458, 0.0],
              [-80.962374184311102, 35.604373611722117, 0.0],
              [-80.962542585197127, 35.604510878233228, 0.0],
              [-80.962654984661569, 35.604762677689337, 0.0],
              [-80.962712117970838, 35.605678678177057, 0.0],
              [-80.963273584438028, 35.60622801118766, 0.0],
              [-80.964311717831279, 35.606616610963684, 0.0],
              [-80.965629518287329, 35.606432410938524, 0.0],
              [-80.965825985282876, 35.606546811028501, 0.0],
              [-80.965826184485906, 35.606821611780731, 0.0],
              [-80.965378184634275, 35.607417410982571, 0.0],
              [-80.965378584324782, 35.607783811009114, 0.0],
              [-80.965799385330229, 35.607920878073415, 0.0],
              [-80.966388318337138, 35.607828811680506, 0.0],
              [-80.967172917953704, 35.607301478259863, 0.0],
              [-80.967901918282692, 35.607232211459326, 0.0],
              [-80.968154584373849, 35.607461011073759, 0.0],
              [-80.968771985171557, 35.607758211375327, 0.0],
              [-80.968772184661518, 35.607964411576418, 0.0],
              [-80.967819118006148, 35.608377411484142, 0.0],
              [-80.966865584913094, 35.608378211845263, 0.0],
              [-80.966585318190894, 35.608492877833442, 0.0],
              [-80.966557717701505, 35.608882210942205, 0.0],
              [-80.967342985258185, 35.608950278496934, 0.0],
              [-80.967483518100195, 35.609156411333572, 0.0],
              [-80.967483984203952, 35.609568611063168, 0.0],
              [-80.966895517547997, 35.610027078403867, 0.0],
              [-80.966896518620089, 35.610874478434852, 0.0],
              [-80.967093118359713, 35.611103411872257, 0.0],
              [-80.968130985322347, 35.611377411115626, 0.0],
              [-80.968748118516544, 35.611308078457419, 0.0],
              [-80.969420785036931, 35.611032811794658, 0.0],
              [-80.970205784882893, 35.610871811144449, 0.0],
              [-80.971130518695844, 35.610115411846657, 0.0],
              [-80.971466918088097, 35.610023411141228, 0.0],
              [-80.971607184682853, 35.610160811827193, 0.0],
              [-80.971355185026837, 35.610481611545694, 0.0],
              [-80.971327784830009, 35.6109854782434, 0.0],
              [-80.970823584787411, 35.611512611435998, 0.0],
              [-80.970571785209515, 35.611970811422175, 0.0],
              [-80.970544384729038, 35.612520611655654, 0.0],
              [-80.970909185051596, 35.612749211769298, 0.0],
              [-80.970909584357472, 35.613024078148193, 0.0],
              [-80.970012185240606, 35.613139411691819, 0.0],
              [-80.969367384197483, 35.613323078131678, 0.0],
              [-80.969115317945139, 35.613621011799971, 0.0],
              [-80.968834917697663, 35.613690011249062, 0.0],
              [-80.968694584525224, 35.613621411332971, 0.0],
              [-80.96838551865234, 35.613117677700423, 0.0],
              [-80.968104784572958, 35.612911878268008, 0.0],
              [-80.967880518257886, 35.613003610999286, 0.0],
              [-80.967600784911554, 35.61359927793724, 0.0],
              [-80.967601717858869, 35.614378011766291, 0.0],
              [-80.967714784478289, 35.615179478423684, 0.0],
              [-80.968135785135601, 35.615476877611883, 0.0],
              [-80.968165184992614, 35.616576211132006, 0.0],
              [-80.968053985227513, 35.61744661093406, 0.0],
              [-80.968222518280598, 35.617698411741124, 0.0],
              [-80.96825111811232, 35.618179411593751, 0.0],
              [-80.967914585146261, 35.618271278450159, 0.0],
              [-80.967521984571135, 35.618271477590682, 0.0],
              [-80.967324184847485, 35.616943278456446, 0.0],
              [-80.966930984844666, 35.616439811869391, 0.0],
              [-80.966649718585728, 35.615775811270296, 0.0],
              [-80.966396918314487, 35.615455411404263, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2391,
        ComID: 9750708,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.024,
        Elevation: 0.0,
        ReachCode: "03050101002300",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.00637034526,
        Shape_Area: 2.42559e-6,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.918903518392398, 35.596679811091633, 0.0],
              [-80.919295985418884, 35.59661087818273, 0.0],
              [-80.920557985424679, 35.596816611197546, 0.0],
              [-80.921118984294267, 35.597068277753209, 0.0],
              [-80.921062985158443, 35.597297277660424, 0.0],
              [-80.920109985339806, 35.598030610997512, 0.0],
              [-80.919801584487132, 35.598122210971169, 0.0],
              [-80.919436918435053, 35.597985011474918, 0.0],
              [-80.918791984664438, 35.597916478207729, 0.0],
              [-80.918791918721595, 35.597687477669815, 0.0],
              [-80.918931918228424, 35.597481278333525, 0.0],
              [-80.918903518392398, 35.596679811091633, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2397,
        ComID: 9750724,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.185,
        Elevation: 232.0,
        ReachCode: "03050101002309",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 0.03333685299,
        Shape_Area: 1.843884e-5,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.852203118352534, 35.571333411462973, 0.0],
              [-80.851754718083072, 35.571127211290829, 0.0],
              [-80.851250118462815, 35.571218678479525, 0.0],
              [-80.850689318309392, 35.571562078108528, 0.0],
              [-80.849596118124055, 35.571859677712361, 0.0],
              [-80.849512318221642, 35.571264211577891, 0.0],
              [-80.849820717703892, 35.570783211687726, 0.0],
              [-80.850353518493719, 35.570394010994782, 0.0],
              [-80.850297518867549, 35.570165011137547, 0.0],
              [-80.850073318597609, 35.569981678420667, 0.0],
              [-80.849877118632264, 35.569958811833018, 0.0],
              [-80.84942858490291, 35.570164878499277, 0.0],
              [-80.848923985042376, 35.570004411104954, 0.0],
              [-80.848279317706883, 35.570004277782466, 0.0],
              [-80.846821785439815, 35.569500078518438, 0.0],
              [-80.846765918844497, 35.569316811215096, 0.0],
              [-80.846806318652753, 35.569267411111419, 0.0],
              [-80.846821985327892, 35.569248211703226, 0.0],
              [-80.847410584809978, 35.569133811741771, 0.0],
              [-80.847775185545643, 35.568698811279809, 0.0],
              [-80.848896518379675, 35.568470011595338, 0.0],
              [-80.850325718134627, 35.569294811898189, 0.0],
              [-80.851306718213735, 35.569546811097062, 0.0],
              [-80.853240518020485, 35.570669278116156, 0.0],
              [-80.853716985406365, 35.570829811667494, 0.0],
              [-80.8540533855398, 35.57073821146853, 0.0],
              [-80.854810317965558, 35.570188678177168, 0.0],
              [-80.854978584492727, 35.569891011016843, 0.0],
              [-80.855034784448534, 35.5692496778614, 0.0],
              [-80.855539517860706, 35.568700078001456, 0.0],
              [-80.855595718589115, 35.567921411406068, 0.0],
              [-80.855904185501998, 35.567417611128185, 0.0],
              [-80.856436918447187, 35.567119877788137, 0.0],
              [-80.856633184410754, 35.566639011798109, 0.0],
              [-80.857333918668871, 35.566570411937107, 0.0],
              [-80.857221718864238, 35.567234478049343, 0.0],
              [-80.856772785469076, 35.568700211077172, 0.0],
              [-80.856772784517858, 35.569043811194945, 0.0],
              [-80.856660717945999, 35.569158278312536, 0.0],
              [-80.856099518521802, 35.571242411212936, 0.0],
              [-80.855398717902347, 35.571333877751258, 0.0],
              [-80.855062318398666, 35.571494078449049, 0.0],
              [-80.8548661182014, 35.571723077942664, 0.0],
              [-80.854893785100884, 35.572822478584825, 0.0],
              [-80.85450131863297, 35.57277667857899, 0.0],
              [-80.854109117992209, 35.572295610979765, 0.0],
              [-80.853772718078417, 35.572158211888372, 0.0],
              [-80.853127917726752, 35.57206641182011, 0.0],
              [-80.852203118352534, 35.571333411462973, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2399,
        ComID: 9750728,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 1.38,
        Elevation: 0.0,
        ReachCode: "03050101002511",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.1547706467,
        Shape_Area: 0.00013712859,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.853028384511262, 35.526718611065938, 0.0],
              [-80.852328118625366, 35.52637487816196, 0.0],
              [-80.851739784533848, 35.526466411493423, 0.0],
              [-80.851235384586829, 35.52671841148257, 0.0],
              [-80.850983118435096, 35.526947411103173, 0.0],
              [-80.850758984656565, 35.526993078274522, 0.0],
              [-80.850731184918573, 35.526466278151617, 0.0],
              [-80.851151584951495, 35.52598541148032, 0.0],
              [-80.852216385487679, 35.525458811571724, 0.0],
              [-80.852552585528315, 35.52518401148275, 0.0],
              [-80.852692718305448, 35.524886411865992, 0.0],
              [-80.852804985539237, 35.524084678468448, 0.0],
              [-80.852664984399908, 35.523603678260315, 0.0],
              [-80.8526651843586, 35.523122811986404, 0.0],
              [-80.852749318316825, 35.523077011040918, 0.0],
              [-80.852805384928203, 35.522825077786891, 0.0],
              [-80.852721717842186, 35.521565477839928, 0.0],
              [-80.852441718318488, 35.52126767859599, 0.0],
              [-80.851993385359108, 35.521359211942624, 0.0],
              [-80.851180785155876, 35.522000277932229, 0.0],
              [-80.850928718084702, 35.522000211595632, 0.0],
              [-80.85101278490896, 35.521702477744981, 0.0],
              [-80.851405117847762, 35.521244611927557, 0.0],
              [-80.851405118173176, 35.520969811846598, 0.0],
              [-80.852806117893081, 35.52023707826369, 0.0],
              [-80.8529181185135, 35.520099611271057, 0.0],
              [-80.852946318064909, 35.519801877874627, 0.0],
              [-80.852694118574647, 35.519618678016215, 0.0],
              [-80.852694318461758, 35.51909187817818, 0.0],
              [-80.852610318447915, 35.518954478608336, 0.0],
              [-80.852358318433645, 35.518839878642005, 0.0],
              [-80.850593384495113, 35.519022811525723, 0.0],
              [-80.850509318646715, 35.518954078302279, 0.0],
              [-80.850565384687812, 35.518747878668435, 0.0],
              [-80.851237785001729, 35.518473277715451, 0.0],
              [-80.852498385367497, 35.518267411230312, 0.0],
              [-80.852470585252647, 35.51780921190884, 0.0],
              [-80.853142985347134, 35.517488811077904, 0.0],
              [-80.853199118682639, 35.517145211258729, 0.0],
              [-80.853703518101938, 35.516778811747656, 0.0],
              [-80.85356358538202, 35.516206278024121, 0.0],
              [-80.853395518322444, 35.515908477829761, 0.0],
              [-80.853031385511812, 35.51567941143459, 0.0],
              [-80.85157491781618, 35.51542721174124, 0.0],
              [-80.850986718225073, 35.51519801120569, 0.0],
              [-80.850202318626387, 35.515335277725491, 0.0],
              [-80.849109718159156, 35.515816078159709, 0.0],
              [-80.848381384459856, 35.515884611266259, 0.0],
              [-80.847484917889403, 35.516228078511602, 0.0],
              [-80.847344785274274, 35.516182211675812, 0.0],
              [-80.847232917722721, 35.515678278024403, 0.0],
              [-80.847064918381577, 35.515540811838989, 0.0],
              [-80.846084518652347, 35.515494811687773, 0.0],
              [-80.845832518696412, 35.515380278305841, 0.0],
              [-80.845832585177803, 35.515151277962921, 0.0],
              [-80.84602858522284, 35.515059611786143, 0.0],
              [-80.846672918134274, 35.515059678539338, 0.0],
              [-80.847457318554746, 35.514830878494799, 0.0],
              [-80.848857918724207, 35.514762477949539, 0.0],
              [-80.849334317749921, 35.51451061166992, 0.0],
              [-80.850790985297792, 35.514075811447626, 0.0],
              [-80.851155184459742, 35.513686411260032, 0.0],
              [-80.851155518463017, 35.512953477963364, 0.0],
              [-80.851071518537438, 35.512861878181923, 0.0],
              [-80.851127518757849, 35.512610078269851, 0.0],
              [-80.851323584410537, 35.512655812001555, 0.0],
              [-80.851631584439048, 35.512953678249879, 0.0],
              [-80.852247584673677, 35.513892811776309, 0.0],
              [-80.852779784411936, 35.514076078149024, 0.0],
              [-80.853199984579888, 35.514076211285762, 0.0],
              [-80.853592118733573, 35.513961678120161, 0.0],
              [-80.853984385387733, 35.513595411118061, 0.0],
              [-80.85415238471829, 35.513618277739106, 0.0],
              [-80.854264385465171, 35.513755811123822, 0.0],
              [-80.854320185414267, 35.514190877850453, 0.0],
              [-80.854684318398455, 35.514465811409977, 0.0],
              [-80.854908384661016, 35.514534477851477, 0.0],
              [-80.855300585285789, 35.51446587823181, 0.0],
              [-80.855720784846866, 35.514191078587281, 0.0],
              [-80.856337117870581, 35.514282811071588, 0.0],
              [-80.856617185341577, 35.514122478610574, 0.0],
              [-80.857037518308601, 35.513412611403076, 0.0],
              [-80.856981518771107, 35.513069011503596, 0.0],
              [-80.856365518200917, 35.512336078485987, 0.0],
              [-80.856057585278137, 35.511671811163424, 0.0],
              [-80.855805517835279, 35.511213678530829, 0.0],
              [-80.855385517791873, 35.510893011796568, 0.0],
              [-80.854629317705388, 35.510801278450565, 0.0],
              [-80.853873184905027, 35.510411811345008, 0.0],
              [-80.853144918195369, 35.510480478473255, 0.0],
              [-80.852164717983769, 35.510228277788727, 0.0],
              [-80.851520384337576, 35.510182411202088, 0.0],
              [-80.851072318308965, 35.509976078104792, 0.0],
              [-80.850568184613067, 35.509884411764673, 0.0],
              [-80.850288118759664, 35.509609478333566, 0.0],
              [-80.849924117957897, 35.509449211773486, 0.0],
              [-80.849924118717681, 35.509266011890155, 0.0],
              [-80.850764385230093, 35.509220277744724, 0.0],
              [-80.851744784454908, 35.509312211729018, 0.0],
              [-80.852108784369904, 35.509495411862417, 0.0],
              [-80.852584984775916, 35.509587077755953, 0.0],
              [-80.85317318510269, 35.509335212003485, 0.0],
              [-80.853677384351116, 35.509312411425796, 0.0],
              [-80.854545584930861, 35.509449878448081, 0.0],
              [-80.854937784380482, 35.509175211576107, 0.0],
              [-80.856254184572393, 35.509175411640399, 0.0],
              [-80.85689838476884, 35.509267077799031, 0.0],
              [-80.857066517915612, 35.509175478125186, 0.0],
              [-80.857122584685712, 35.508900611548746, 0.0],
              [-80.856954585336567, 35.508671611988781, 0.0],
              [-80.85692658518208, 35.508350878604105, 0.0],
              [-80.857290784422531, 35.508213611963974, 0.0],
              [-80.857430917811882, 35.507801278338007, 0.0],
              [-80.857767118527278, 35.507457811947319, 0.0],
              [-80.857767185121929, 35.507205878540255, 0.0],
              [-80.857515118308939, 35.506931078516971, 0.0],
              [-80.857263184877596, 35.506427211488145, 0.0],
              [-80.856703118056529, 35.506060677942628, 0.0],
              [-80.856647117729651, 35.505900278631401, 0.0],
              [-80.855974984394209, 35.505671211138655, 0.0],
              [-80.854910585134704, 35.505968811981461, 0.0],
              [-80.854938718186418, 35.505671077919303, 0.0],
              [-80.855358985253304, 35.50502981161651, 0.0],
              [-80.855442984883936, 35.504571678512896, 0.0],
              [-80.855191118185616, 35.504251078498527, 0.0],
              [-80.855191118841887, 35.503999077943426, 0.0],
              [-80.855331184397116, 35.503770077963814, 0.0],
              [-80.855415318247722, 35.50335787793172, 0.0],
              [-80.855835517809766, 35.503266411790094, 0.0],
              [-80.85631158494823, 35.503335078366774, 0.0],
              [-80.856423584431383, 35.503701611391229, 0.0],
              [-80.856479318594808, 35.50445741106946, 0.0],
              [-80.856703318859346, 35.504869678675078, 0.0],
              [-80.856955385122319, 35.50484681195644, 0.0],
              [-80.857095517763042, 35.504732278031931, 0.0],
              [-80.857403785194691, 35.504114011785937, 0.0],
              [-80.857599718548471, 35.504159811244925, 0.0],
              [-80.857823584632968, 35.504869811579582, 0.0],
              [-80.857935717919872, 35.504961411395463, 0.0],
              [-80.858271784364433, 35.50503021114929, 0.0],
              [-80.85849578511629, 35.505213478072406, 0.0],
              [-80.858719784478453, 35.50564861203214, 0.0],
              [-80.859111784481968, 35.50592347781194, 0.0],
              [-80.860120118219299, 35.5063816778068, 0.0],
              [-80.860596317776086, 35.506244278203063, 0.0],
              [-80.860652317957403, 35.506015277800984, 0.0],
              [-80.860540317918122, 35.505580211699623, 0.0],
              [-80.860708584989581, 35.504618211174751, 0.0],
              [-80.860876584417312, 35.504366278438972, 0.0],
              [-80.861128717705768, 35.504435011103325, 0.0],
              [-80.861324584371445, 35.505511611613102, 0.0],
              [-80.861940784912207, 35.505809277850481, 0.0],
              [-80.861912718310236, 35.506038411900775, 0.0],
              [-80.861352385013603, 35.506954411968074, 0.0],
              [-80.861212185353821, 35.507366611436481, 0.0],
              [-80.861212118737839, 35.507847611526493, 0.0],
              [-80.861380117730079, 35.508145278390892, 0.0],
              [-80.861744185229512, 35.508420211872959, 0.0],
              [-80.86208038470177, 35.508511811972156, 0.0],
              [-80.862360385321935, 35.508420278703618, 0.0],
              [-80.862500517767131, 35.508191211264005, 0.0],
              [-80.86252878474788, 35.507092011389723, 0.0],
              [-80.862612718558253, 35.506862878457689, 0.0],
              [-80.862920784660858, 35.506794211314251, 0.0],
              [-80.863004918588018, 35.506885811939121, 0.0],
              [-80.863060918808785, 35.507527211704826, 0.0],
              [-80.86314478540794, 35.507573011298064, 0.0],
              [-80.86314478456201, 35.508122611511162, 0.0],
              [-80.863312784665567, 35.508305878656238, 0.0],
              [-80.863760917992749, 35.508489077948397, 0.0],
              [-80.864349118686391, 35.508420478118772, 0.0],
              [-80.864629184657787, 35.508214411165788, 0.0],
              [-80.864713318768537, 35.508054077958029, 0.0],
              [-80.864685385008656, 35.50773341192415, 0.0],
              [-80.864293318588906, 35.507252411554973, 0.0],
              [-80.864237317673229, 35.507000478472811, 0.0],
              [-80.864349317709795, 35.506725611398487, 0.0],
              [-80.865105585424686, 35.507183677923578, 0.0],
              [-80.865357718340462, 35.50709207832552, 0.0],
              [-80.865637785241574, 35.507160811606717, 0.0],
              [-80.865273118112327, 35.510642077914618, 0.0],
              [-80.865160985372597, 35.512566011679922, 0.0],
              [-80.864655985135215, 35.518062611258173, 0.0],
              [-80.864151384779802, 35.52037581201062, 0.0],
              [-80.86373111881629, 35.521017011438261, 0.0],
              [-80.863534917796173, 35.521887411954907, 0.0],
              [-80.863002585502173, 35.522093411049283, 0.0],
              [-80.862862585352246, 35.522070611295085, 0.0],
              [-80.863114918607707, 35.520971278420042, 0.0],
              [-80.863563118842123, 35.52030707854756, 0.0],
              [-80.863619184765142, 35.519963478486765, 0.0],
              [-80.863703184987742, 35.519894811801336, 0.0],
              [-80.863843384717185, 35.519299411948047, 0.0],
              [-80.863815517811531, 35.518749678566657, 0.0],
              [-80.863731517905052, 35.51865807848344, 0.0],
              [-80.863843717941435, 35.517512877809068, 0.0],
              [-80.863675717712752, 35.516528078422979, 0.0],
              [-80.863171718688093, 35.515726411078305, 0.0],
              [-80.862135384791173, 35.514856011932615, 0.0],
              [-80.861687185141207, 35.514878878391016, 0.0],
              [-80.861519118735458, 35.514993411334629, 0.0],
              [-80.861378917756113, 35.515336877937557, 0.0],
              [-80.861406717900437, 35.516917211983078, 0.0],
              [-80.860958518229054, 35.516940211728283, 0.0],
              [-80.860734518457008, 35.516573478018664, 0.0],
              [-80.860594385529097, 35.516482011881145, 0.0],
              [-80.860426384726011, 35.516573611117131, 0.0],
              [-80.860398184958257, 35.517375211731213, 0.0],
              [-80.85975378446949, 35.517672878352137, 0.0],
              [-80.859613718512492, 35.517878878409213, 0.0],
              [-80.859585718418941, 35.518268277823772, 0.0],
              [-80.859249518377922, 35.518359811058517, 0.0],
              [-80.859109318122321, 35.518795011543183, 0.0],
              [-80.858857184334084, 35.518818011307516, 0.0],
              [-80.858857384734492, 35.517947611974101, 0.0],
              [-80.859165718620474, 35.517008611850208, 0.0],
              [-80.859137718560945, 35.516596411740139, 0.0],
              [-80.858717717937949, 35.516275678388219, 0.0],
              [-80.857513118339156, 35.516435811932155, 0.0],
              [-80.857176917888367, 35.516710611380908, 0.0],
              [-80.857092784412217, 35.516916678406204, 0.0],
              [-80.855720118818311, 35.517557878543819, 0.0],
              [-80.855215718460016, 35.518153278126668, 0.0],
              [-80.855215518109787, 35.518473878603764, 0.0],
              [-80.855579585234963, 35.518932011160665, 0.0],
              [-80.855579518796688, 35.519183878612267, 0.0],
              [-80.85485118526563, 35.519367011505032, 0.0],
              [-80.854655118826187, 35.51955027782185, 0.0],
              [-80.854514918849418, 35.519916678398474, 0.0],
              [-80.854430518730709, 35.521222078627446, 0.0],
              [-80.854206184855926, 35.522069478670034, 0.0],
              [-80.854290118132099, 35.522825411538705, 0.0],
              [-80.854093784788688, 35.523214677915988, 0.0],
              [-80.854177717796532, 35.523993411409315, 0.0],
              [-80.853757385433894, 35.524588811941655, 0.0],
              [-80.853700918085593, 35.526077411889396, 0.0],
              [-80.853924918513243, 35.526375277826887, 0.0],
              [-80.853952785160445, 35.52676447779605, 0.0],
              [-80.854877117690592, 35.527657878220964, 0.0],
              [-80.854877118494827, 35.527909878256693, 0.0],
              [-80.854260718331176, 35.527955611530004, 0.0],
              [-80.854092518764119, 35.528390811762023, 0.0],
              [-80.853868318565048, 35.52848227851451, 0.0],
              [-80.853560384582224, 35.528161611390161, 0.0],
              [-80.853308518136487, 35.527062211556647, 0.0],
              [-80.853028384511262, 35.526718611065938, 0.0],
            ],
            [
              [-80.86185611826626, 35.509565411243216, 0.0],
              [-80.861743985253881, 35.509565411171003, 0.0],
              [-80.86129578446409, 35.509840212047301, 0.0],
              [-80.86059558449135, 35.51002327851176, 0.0],
              [-80.860315518085613, 35.51036681194028, 0.0],
              [-80.860231385040663, 35.510664612008561, 0.0],
              [-80.860231184544077, 35.511397477852512, 0.0],
              [-80.860399184435053, 35.511534877812267, 0.0],
              [-80.861519584894992, 35.511695411778653, 0.0],
              [-80.861883785445073, 35.511649611277093, 0.0],
              [-80.862191918662447, 35.511489278127335, 0.0],
              [-80.86247211873453, 35.510939611945936, 0.0],
              [-80.862416185352743, 35.510229611505622, 0.0],
              [-80.8621921845576, 35.509840278524898, 0.0],
              [-80.86185611826626, 35.509565411243216, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1338,
        ComID: 9736024,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Fishing Creek, Great Falls, Cedar Creek",
        AreaSqKm: 12.311,
        Elevation: 0.0,
        ReachCode: "03050103001794",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.76637244379000002,
        Shape_Area: 0.00120995443,
        Reservoir: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.891170585192853, 34.59536187974912, 0.0],
              [-80.891198384581074, 34.59501827986977, 0.0],
              [-80.891392184296805, 34.595018279380511, 0.0],
              [-80.894078984444135, 34.594216079999633, 0.0],
              [-80.894328184729886, 34.593918279339334, 0.0],
              [-80.894300318456956, 34.593162413432132, 0.0],
              [-80.893746118479982, 34.592108812874393, 0.0],
              [-80.892665585189064, 34.59091781251464, 0.0],
              [-80.891779184921845, 34.590207879623527, 0.0],
              [-80.890588117894282, 34.589635413434181, 0.0],
              [-80.889895518380811, 34.58887961338359, 0.0],
              [-80.889895385329837, 34.588650612722006, 0.0],
              [-80.890116984519821, 34.588581813165661, 0.0],
              [-80.890283185292915, 34.588696279538809, 0.0],
              [-80.890421717702793, 34.588696279456784, 0.0],
              [-80.890421585312296, 34.588421412881651, 0.0],
              [-80.890061517670105, 34.587757213342925, 0.0],
              [-80.890061518374281, 34.587253212675527, 0.0],
              [-80.890199918775608, 34.586886612526769, 0.0],
              [-80.890587584561629, 34.586359812890677, 0.0],
              [-80.890531984822005, 34.585878680061164, 0.0],
              [-80.890061118268505, 34.585420679939531, 0.0],
              [-80.889064118222294, 34.584939813041466, 0.0],
              [-80.888343784872589, 34.584413012964418, 0.0],
              [-80.887457385444407, 34.583474012864166, 0.0],
              [-80.887235918233586, 34.582970080049193, 0.0],
              [-80.887207984839364, 34.582374479445761, 0.0],
              [-80.887678785143962, 34.581229212492154, 0.0],
              [-80.887706384533203, 34.580725080025765, 0.0],
              [-80.886847784325994, 34.579763212822861, 0.0],
              [-80.885905984493959, 34.578915813443004, 0.0],
              [-80.885629118258763, 34.578526413111931, 0.0],
              [-80.885573584877037, 34.578205679224467, 0.0],
              [-80.88587818543435, 34.578068279724576, 0.0],
              [-80.885933517902984, 34.577930813313145, 0.0],
              [-80.885988917823909, 34.577770479511081, 0.0],
              [-80.886016584758096, 34.577381012625068, 0.0],
              [-80.886016584548997, 34.577083212886038, 0.0],
              [-80.885878118175938, 34.576945880072387, 0.0],
              [-80.885794918310964, 34.576235812895831, 0.0],
              [-80.88543491832101, 34.575892212854882, 0.0],
              [-80.88488078483465, 34.573899279298196, 0.0],
              [-80.884271517643711, 34.572044012546868, 0.0],
              [-80.884049918614025, 34.571311079529309, 0.0],
              [-80.884022118535825, 34.570830013486834, 0.0],
              [-80.884160518398801, 34.57050927942219, 0.0],
              [-80.884658985095669, 34.570074013039552, 0.0],
              [-80.884991318259111, 34.569478412882205, 0.0],
              [-80.884852717760324, 34.568699679468409, 0.0],
              [-80.884963385279605, 34.568447612786308, 0.0],
              [-80.885544784583388, 34.567920679717353, 0.0],
              [-80.885710984817024, 34.567439679659635, 0.0],
              [-80.885710785307452, 34.566111079430762, 0.0],
              [-80.885600118134008, 34.565927880135206, 0.0],
              [-80.88557231772306, 34.565378079405058, 0.0],
              [-80.885987518548333, 34.564645013306894, 0.0],
              [-80.886928918061727, 34.563911879872286, 0.0],
              [-80.887288784773574, 34.563476679542639, 0.0],
              [-80.887178117750295, 34.56246881295781, 0.0],
              [-80.887177918375741, 34.562010612957792, 0.0],
              [-80.887870118703148, 34.561918879260439, 0.0],
              [-80.888119184495082, 34.561254613324287, 0.0],
              [-80.888617718120358, 34.561231679642347, 0.0],
              [-80.888977584431757, 34.561094213492211, 0.0],
              [-80.889309718451301, 34.560773412761215, 0.0],
              [-80.889448185034666, 34.560429679469365, 0.0],
              [-80.889475918342981, 34.559857079945104, 0.0],
              [-80.88936498444815, 34.559444812886639, 0.0],
              [-80.88900498510813, 34.559147079673323, 0.0],
              [-80.888174384339663, 34.559055612945727, 0.0],
              [-80.887980584426629, 34.558918213469987, 0.0],
              [-80.888035918102304, 34.558803613203338, 0.0],
              [-80.888561918419896, 34.558505680033832, 0.0],
              [-80.888810984772746, 34.558207880127014, 0.0],
              [-80.889032584811858, 34.558185013295009, 0.0],
              [-80.889253985135426, 34.558368213305833, 0.0],
              [-80.889503384812514, 34.558757679490228, 0.0],
              [-80.889807917819667, 34.559009479624358, 0.0],
              [-80.890361718739385, 34.559169813120654, 0.0],
              [-80.890610918013266, 34.559169812999237, 0.0],
              [-80.890721584643302, 34.559009479212776, 0.0],
              [-80.890610784335195, 34.558299280123428, 0.0],
              [-80.890804518556394, 34.558184679325869, 0.0],
              [-80.89099838430613, 34.558322213372215, 0.0],
              [-80.891026184978926, 34.558597012612758, 0.0],
              [-80.891330718296288, 34.55891767923567, 0.0],
              [-80.89152458526641, 34.558826012545751, 0.0],
              [-80.891579784671563, 34.558161679861463, 0.0],
              [-80.892521118117429, 34.557840879978038, 0.0],
              [-80.893130185470469, 34.557543013412499, 0.0],
              [-80.893683918351172, 34.557130479639852, 0.0],
              [-80.894652584843627, 34.556168279627116, 0.0],
              [-80.894874118124022, 34.555595612610389, 0.0],
              [-80.894846184335634, 34.554931279599757, 0.0],
              [-80.893904785117115, 34.554084012789509, 0.0],
              [-80.891135785347302, 34.552068679409395, 0.0],
              [-80.89088671880188, 34.551954213168607, 0.0],
              [-80.890526785461873, 34.552023012835242, 0.0],
              [-80.890277585096882, 34.552412413181329, 0.0],
              [-80.890028385260948, 34.552527012831554, 0.0],
              [-80.889502384532463, 34.552412479527973, 0.0],
              [-80.889225518446921, 34.552458413061132, 0.0],
              [-80.888976518061241, 34.553122679571182, 0.0],
              [-80.888782718624213, 34.553306012630486, 0.0],
              [-80.888561184296037, 34.553306012864411, 0.0],
              [-80.888228918049336, 34.552916680079342, 0.0],
              [-80.887647518022334, 34.552641879844209, 0.0],
              [-80.886955318740007, 34.552504412796488, 0.0],
              [-80.886014118121977, 34.552642013237168, 0.0],
              [-80.885764785494189, 34.552435880133771, 0.0],
              [-80.88568178446431, 34.552206812878374, 0.0],
              [-80.884989584848825, 34.551611279901984, 0.0],
              [-80.884961784488581, 34.551359413076746, 0.0],
              [-80.885100184690785, 34.551221880166302, 0.0],
              [-80.885183318567414, 34.550649280133932, 0.0],
              [-80.885792184688711, 34.549939080154935, 0.0],
              [-80.885792118777559, 34.549320613216828, 0.0],
              [-80.885958318435499, 34.549068612842667, 0.0],
              [-80.886151785046337, 34.546961212828322, 0.0],
              [-80.885764185433445, 34.546319812702414, 0.0],
              [-80.88551478532996, 34.544853879599337, 0.0],
              [-80.885210317815364, 34.544647679895021, 0.0],
              [-80.884794984415578, 34.544647679724093, 0.0],
              [-80.883660118154509, 34.545380879581074, 0.0],
              [-80.883410984461335, 34.545312212830638, 0.0],
              [-80.88341098527313, 34.545060279383712, 0.0],
              [-80.88410298442821, 34.544372879281575, 0.0],
              [-80.884102918586862, 34.543960612653287, 0.0],
              [-80.883770718775281, 34.543754412771122, 0.0],
              [-80.882857184400933, 34.543594279297828, 0.0],
              [-80.882441985275776, 34.543663012774935, 0.0],
              [-80.881473118344928, 34.544212812871827, 0.0],
              [-80.881251718826945, 34.544625080125023, 0.0],
              [-80.88133478492189, 34.545426812790154, 0.0],
              [-80.881445518402671, 34.545518480145795, 0.0],
              [-80.881694785146735, 34.546137012562703, 0.0],
              [-80.881971584621382, 34.546457679595058, 0.0],
              [-80.881971718178718, 34.546640879888251, 0.0],
              [-80.881500918029928, 34.546824213424159, 0.0],
              [-80.88116871863528, 34.547190679885162, 0.0],
              [-80.880476718547342, 34.548290213427265, 0.0],
              [-80.880421385494643, 34.548565213292392, 0.0],
              [-80.880698184769926, 34.54902328019395, 0.0],
              [-80.881058118563715, 34.549458479306374, 0.0],
              [-80.88122431843874, 34.549847879597444, 0.0],
              [-80.881279785175266, 34.550649613154754, 0.0],
              [-80.881611785308252, 34.551061879318034, 0.0],
              [-80.881584185319639, 34.551268012802318, 0.0],
              [-80.880753717695725, 34.552230212705069, 0.0],
              [-80.880421518314208, 34.552711279436771, 0.0],
              [-80.880006384629652, 34.553856613246971, 0.0],
              [-80.879978584566928, 34.554589612716917, 0.0],
              [-80.87953558483845, 34.554864612563613, 0.0],
              [-80.879397185295801, 34.555208080152958, 0.0],
              [-80.879480318415659, 34.556238879477135, 0.0],
              [-80.879618784941968, 34.556651279978361, 0.0],
              [-80.879729385472615, 34.556697013491323, 0.0],
              [-80.880172517983624, 34.556513679278233, 0.0],
              [-80.880449317810076, 34.556536613434169, 0.0],
              [-80.880559985327793, 34.556628279948271, 0.0],
              [-80.880532385200112, 34.556788612568845, 0.0],
              [-80.879978718411678, 34.557384212992545, 0.0],
              [-80.880034117781619, 34.557682013414947, 0.0],
              [-80.8802003183891, 34.557888079803163, 0.0],
              [-80.880421585148525, 34.558964679653478, 0.0],
              [-80.881086318211629, 34.559308212790611, 0.0],
              [-80.88163998467968, 34.560018279763554, 0.0],
              [-80.881612318662832, 34.560270280081397, 0.0],
              [-80.881030984969399, 34.561255412696241, 0.0],
              [-80.880117384625265, 34.562332012979049, 0.0],
              [-80.879203584988261, 34.563798013454289, 0.0],
              [-80.879092784854649, 34.564141679864008, 0.0],
              [-80.879175918328457, 34.5646226801232, 0.0],
              [-80.879757518130234, 34.566203279500755, 0.0],
              [-80.88047751838134, 34.567234013480885, 0.0],
              [-80.881363584506389, 34.567989879970241, 0.0],
              [-80.88175118493389, 34.568493813156337, 0.0],
              [-80.881861985157926, 34.568768613165311, 0.0],
              [-80.881834318360944, 34.569455812969636, 0.0],
              [-80.881723584809521, 34.569822280074156, 0.0],
              [-80.881751318405122, 34.570486613188898, 0.0],
              [-80.881335984724871, 34.570624012763759, 0.0],
              [-80.881142185396783, 34.570303413175033, 0.0],
              [-80.880782184460841, 34.570211812526736, 0.0],
              [-80.880726718526873, 34.569593279442586, 0.0],
              [-80.880394384737556, 34.569524612596332, 0.0],
              [-80.879951385480567, 34.569730879898678, 0.0],
              [-80.879812917746207, 34.56966221331875, 0.0],
              [-80.879563717850047, 34.569753813483956, 0.0],
              [-80.879120718461635, 34.57028068004599, 0.0],
              [-80.878373118526966, 34.570372279972055, 0.0],
              [-80.878289917765883, 34.570326480145923, 0.0],
              [-80.8782899853218, 34.570143213476655, 0.0],
              [-80.877957585149446, 34.570120412577744, 0.0],
              [-80.877708317908812, 34.570234880098596, 0.0],
              [-80.877514518334976, 34.570212012886955, 0.0],
              [-80.875659318078206, 34.568585679410504, 0.0],
              [-80.875631585314423, 34.56833367927559, 0.0],
              [-80.875742318475503, 34.567852679742074, 0.0],
              [-80.875631584403834, 34.567806879289677, 0.0],
              [-80.875050184958496, 34.567875613456337, 0.0],
              [-80.875050118298461, 34.567554879186339, 0.0],
              [-80.87480091819765, 34.567554880157836, 0.0],
              [-80.874302384785068, 34.567715279865837, 0.0],
              [-80.87402551797426, 34.567600812763814, 0.0],
              [-80.873693318407106, 34.567257212537207, 0.0],
              [-80.873277984955266, 34.56627227948686, 0.0],
              [-80.872779517979765, 34.56588281257811, 0.0],
              [-80.870702784950865, 34.564943612962345, 0.0],
              [-80.870093718378968, 34.564348080081949, 0.0],
              [-80.869678517891685, 34.563798213361686, 0.0],
              [-80.869235517686988, 34.563019412650739, 0.0],
              [-80.868681784593136, 34.562423879365163, 0.0],
              [-80.867961918174359, 34.561828279705573, 0.0],
              [-80.866715984827138, 34.561072279338106, 0.0],
              [-80.865525584915872, 34.559445879618586, 0.0],
              [-80.865248785339745, 34.559423012581647, 0.0],
              [-80.864445784944309, 34.559606212600954, 0.0],
              [-80.864418118062943, 34.559400012965156, 0.0],
              [-80.864805784582657, 34.558896080062034, 0.0],
              [-80.864833517851423, 34.558712813209048, 0.0],
              [-80.865276517681039, 34.558506679429399, 0.0],
              [-80.865608784683445, 34.558231813111789, 0.0],
              [-80.86577511790766, 34.557773679873087, 0.0],
              [-80.866494918142877, 34.55786528018497, 0.0],
              [-80.869845317956646, 34.556353613198063, 0.0],
              [-80.870343718696617, 34.5559184133418, 0.0],
              [-80.870371384571456, 34.555483079924208, 0.0],
              [-80.870177518107155, 34.555025079365045, 0.0],
              [-80.870149918309778, 34.554521079321752, 0.0],
              [-80.870426917885666, 34.553811080064065, 0.0],
              [-80.870399385351249, 34.552642813259183, 0.0],
              [-80.870288584512053, 34.552436613295939, 0.0],
              [-80.869817917926071, 34.552024279316115, 0.0],
              [-80.869790317880586, 34.551589013125742, 0.0],
              [-80.870537784677978, 34.550695812856581, 0.0],
              [-80.871423784618287, 34.550077213516012, 0.0],
              [-80.871894584828766, 34.549367079504485, 0.0],
              [-80.874801385493953, 34.5475346134034, 0.0],
              [-80.875715118192787, 34.546687013036916, 0.0],
              [-80.875715118049087, 34.546320479428736, 0.0],
              [-80.875548917661646, 34.546114413400019, 0.0],
              [-80.875548985012713, 34.545931079929431, 0.0],
              [-80.876324117674002, 34.545221013318276, 0.0],
              [-80.876434918545911, 34.544969079899111, 0.0],
              [-80.87646251789252, 34.544556680100449, 0.0],
              [-80.876240984984292, 34.544075612609447, 0.0],
              [-80.875299785134004, 34.543296879718127, 0.0],
              [-80.875216718103687, 34.543113480191039, 0.0],
              [-80.875410718042687, 34.541991213282756, 0.0],
              [-80.875244518343379, 34.541739213268684, 0.0],
              [-80.875216785505202, 34.541372680126415, 0.0],
              [-80.87577051800919, 34.541372679547131, 0.0],
              [-80.875991918106578, 34.541808013370016, 0.0],
              [-80.876102718026274, 34.541853679628105, 0.0],
              [-80.876756585015286, 34.541735279637983, 0.0],
              [-80.877309517664045, 34.541635212689435, 0.0],
              [-80.877874384622288, 34.541532880102224, 0.0],
              [-80.878344918507395, 34.541418413024893, 0.0],
              [-80.877874318691596, 34.540296012716389, 0.0],
              [-80.87787438519274, 34.540158612995008, 0.0],
              [-80.878068184896279, 34.540021079363946, 0.0],
              [-80.878261917657312, 34.540044079788295, 0.0],
              [-80.878566384740452, 34.540456280124467, 0.0],
              [-80.878926185129899, 34.541510012887848, 0.0],
              [-80.879286117683762, 34.541830680072806, 0.0],
              [-80.879895118821381, 34.541991012840469, 0.0],
              [-80.880559384767054, 34.542013879665021, 0.0],
              [-80.881279317709811, 34.541899280176303, 0.0],
              [-80.881639184503797, 34.542151213496162, 0.0],
              [-80.88199898528589, 34.542288480050573, 0.0],
              [-80.882386584503209, 34.542311479360791, 0.0],
              [-80.88280178538723, 34.542219812590695, 0.0],
              [-80.883023317792151, 34.542059479512062, 0.0],
              [-80.883244585244938, 34.541647079622479, 0.0],
              [-80.883576784996436, 34.541555479684718, 0.0],
              [-80.884324318757692, 34.541784480116512, 0.0],
              [-80.884545718072999, 34.541784479470365, 0.0],
              [-80.884767184475834, 34.5416240128949, 0.0],
              [-80.885154585128106, 34.540753612661099, 0.0],
              [-80.885486718409396, 34.540547412617244, 0.0],
              [-80.886206585076025, 34.540364079915726, 0.0],
              [-80.886400318638053, 34.540226679698932, 0.0],
              [-80.886704718280768, 34.539608013024271, 0.0],
              [-80.886870784780612, 34.539493479262305, 0.0],
              [-80.88745211847268, 34.539493479834029, 0.0],
              [-80.887728784736112, 34.539378812969304, 0.0],
              [-80.888697584555075, 34.538393679782679, 0.0],
              [-80.888863518770904, 34.538004279271732, 0.0],
              [-80.889223518395653, 34.537683613174373, 0.0],
              [-80.890164585174645, 34.537660613435492, 0.0],
              [-80.890385917855667, 34.537179479458239, 0.0],
              [-80.890108984755713, 34.536583880201484, 0.0],
              [-80.890191985463105, 34.536446613007826, 0.0],
              [-80.890524184902901, 34.536377680088698, 0.0],
              [-80.891160918224116, 34.536560879881321, 0.0],
              [-80.89204658483591, 34.536331612841707, 0.0],
              [-80.893015518312581, 34.537018679538377, 0.0],
              [-80.894012118129197, 34.537408013332822, 0.0],
              [-80.894122785310671, 34.537545413497426, 0.0],
              [-80.893873784984777, 34.537682880058313, 0.0],
              [-80.89334778545583, 34.537774612705391, 0.0],
              [-80.892711184695685, 34.537774680136827, 0.0],
              [-80.891686984358643, 34.537935280111718, 0.0],
              [-80.891548718568217, 34.538347613040578, 0.0],
              [-80.891382717936835, 34.538530813398275, 0.0],
              [-80.890635318518491, 34.538622612562897, 0.0],
              [-80.890358518640312, 34.538828812670971, 0.0],
              [-80.89041398522825, 34.539699212754407, 0.0],
              [-80.890109518316592, 34.539928213248373, 0.0],
              [-80.889002318325097, 34.539951412873968, 0.0],
              [-80.888863985224091, 34.540134680134187, 0.0],
              [-80.888642585439854, 34.540730212624737, 0.0],
              [-80.888171984447837, 34.540959279769318, 0.0],
              [-80.887812184906934, 34.540936479455375, 0.0],
              [-80.887562984971638, 34.540776080162892, 0.0],
              [-80.887313784671946, 34.540845013121668, 0.0],
              [-80.887120118324333, 34.541142813166083, 0.0],
              [-80.886898584634565, 34.541303213195619, 0.0],
              [-80.886317318155434, 34.541509412636444, 0.0],
              [-80.886068185274098, 34.541875879665227, 0.0],
              [-80.886178984581449, 34.541967479439634, 0.0],
              [-80.886732518185354, 34.5417154801384, 0.0],
              [-80.886898717925106, 34.541738413286602, 0.0],
              [-80.886981718582504, 34.541830013442464, 0.0],
              [-80.886345117748164, 34.542746280157608, 0.0],
              [-80.886234518612696, 34.543021079414039, 0.0],
              [-80.886262318187093, 34.543502279589376, 0.0],
              [-80.886511384482887, 34.544120679251506, 0.0],
              [-80.886622184431943, 34.544189413387464, 0.0],
              [-80.886954518356148, 34.545036879424664, 0.0],
              [-80.887342118218157, 34.545838612991666, 0.0],
              [-80.88792358475169, 34.547167079370048, 0.0],
              [-80.887951518072015, 34.548381079875121, 0.0],
              [-80.888062184664719, 34.548724680015781, 0.0],
              [-80.887813318798464, 34.549961812748677, 0.0],
              [-80.887342584924937, 34.550167879452786, 0.0],
              [-80.886816718452081, 34.550649013354743, 0.0],
              [-80.886705984660409, 34.551015479579348, 0.0],
              [-80.886844384846455, 34.551290480046099, 0.0],
              [-80.887425784752864, 34.55149647985948, 0.0],
              [-80.889003785071736, 34.551335879981814, 0.0],
              [-80.890332785074477, 34.550877612828906, 0.0],
              [-80.890997184383082, 34.550900480153878, 0.0],
              [-80.891606384457162, 34.551221079409885, 0.0],
              [-80.892104584510633, 34.551335479403477, 0.0],
              [-80.893101317649808, 34.551243679834215, 0.0],
              [-80.893322918778111, 34.551449813224032, 0.0],
              [-80.89337818496503, 34.551610213011564, 0.0],
              [-80.893904317723511, 34.551999479219525, 0.0],
              [-80.89393211858291, 34.552366013458588, 0.0],
              [-80.894070584566961, 34.552549213098615, 0.0],
              [-80.894347517623444, 34.552709479567589, 0.0],
              [-80.89440291769877, 34.553144680159001, 0.0],
              [-80.896119785120746, 34.554770813508945, 0.0],
              [-80.896304317625024, 34.554796213337831, 0.0],
              [-80.896452118293908, 34.554816479909661, 0.0],
              [-80.896424584497538, 34.555480813261433, 0.0],
              [-80.896507718749561, 34.555686879886665, 0.0],
              [-80.896978385098535, 34.556167879410964, 0.0],
              [-80.897282984570936, 34.556603012710724, 0.0],
              [-80.897310717703689, 34.55680921286806, 0.0],
              [-80.897338518343503, 34.557175679566079, 0.0],
              [-80.897670917958038, 34.557519212917043, 0.0],
              [-80.898501584825183, 34.55804587943058, 0.0],
              [-80.899858518388228, 34.558526613499275, 0.0],
              [-80.900550718650607, 34.558389013088714, 0.0],
              [-80.900661518566679, 34.558526479889657, 0.0],
              [-80.901990384771423, 34.558571879385809, 0.0],
              [-80.902876517833946, 34.558365613463998, 0.0],
              [-80.903900718530721, 34.557861412908309, 0.0],
              [-80.90497998431384, 34.556280480003437, 0.0],
              [-80.90525678438496, 34.556143013190436, 0.0],
              [-80.906917917661005, 34.555982213185139, 0.0],
              [-80.908606984291168, 34.556325280060896, 0.0],
              [-80.909326918330905, 34.556600012778127, 0.0],
              [-80.90993618504443, 34.557057879961107, 0.0],
              [-80.910047117795216, 34.557287012926324, 0.0],
              [-80.909797917869469, 34.55735581254104, 0.0],
              [-80.908717917692911, 34.556783413501599, 0.0],
              [-80.908191784610551, 34.556623212846375, 0.0],
              [-80.906668985421007, 34.556463279648412, 0.0],
              [-80.905810718561071, 34.556486479691458, 0.0],
              [-80.905450785307252, 34.556624012927351, 0.0],
              [-80.9050355846922, 34.557013613220839, 0.0],
              [-80.904426784995579, 34.558158879981541, 0.0],
              [-80.904288517780827, 34.558365213124397, 0.0],
              [-80.903928584680301, 34.558640213157659, 0.0],
              [-80.902322918501241, 34.559029879920494, 0.0],
              [-80.900910718261215, 34.559007413507885, 0.0],
              [-80.898778717893038, 34.558687213265436, 0.0],
              [-80.897754118193859, 34.558206413180088, 0.0],
              [-80.896175584532259, 34.556694880143198, 0.0],
              [-80.895843318285074, 34.556580413485598, 0.0],
              [-80.895372718074924, 34.556626412536438, 0.0],
              [-80.893102718715227, 34.558253213398089, 0.0],
              [-80.892299784643612, 34.559123679703539, 0.0],
              [-80.892106118371601, 34.559558879934265, 0.0],
              [-80.892161517923952, 34.559673479546447, 0.0],
              [-80.892355385076911, 34.559765013400472, 0.0],
              [-80.892133984734414, 34.56026901320309, 0.0],
              [-80.892355518273561, 34.560566813482183, 0.0],
              [-80.893130784947687, 34.560749879472681, 0.0],
              [-80.893130984423408, 34.561689213076242, 0.0],
              [-80.892881918710955, 34.561986879966341, 0.0],
              [-80.892521984413818, 34.562193079271808, 0.0],
              [-80.891635784590761, 34.562376612628064, 0.0],
              [-80.890694518371674, 34.562422412945068, 0.0],
              [-80.889337918024935, 34.56336187978966, 0.0],
              [-80.888811985319407, 34.564140813480165, 0.0],
              [-80.888174985195732, 34.564713479540764, 0.0],
              [-80.887704385417692, 34.5657442794272, 0.0],
              [-80.887815318623112, 34.566225413486343, 0.0],
              [-80.887787717981254, 34.566889679954066, 0.0],
              [-80.887649318402609, 34.567164612842944, 0.0],
              [-80.886597117714047, 34.567645679996204, 0.0],
              [-80.886264917927846, 34.568264279311087, 0.0],
              [-80.886348118284985, 34.569203479529548, 0.0],
              [-80.88593271779547, 34.569409612593176, 0.0],
              [-80.885461984630538, 34.569501279588209, 0.0],
              [-80.885351317715163, 34.569638812975299, 0.0],
              [-80.885323585248159, 34.570257212933427, 0.0],
              [-80.88507438457755, 34.570784079541994, 0.0],
              [-80.885102117849641, 34.571333879570609, 0.0],
              [-80.885379118157161, 34.572066879785758, 0.0],
              [-80.885379184379772, 34.572731213106344, 0.0],
              [-80.88524078524209, 34.573235079294776, 0.0],
              [-80.885600918679373, 34.574357412585165, 0.0],
              [-80.885628718131116, 34.574884279192126, 0.0],
              [-80.885850318125321, 34.575502679909356, 0.0],
              [-80.885933385303687, 34.575548479711969, 0.0],
              [-80.886044118047366, 34.575915080062742, 0.0],
              [-80.886514917633676, 34.576212679201561, 0.0],
              [-80.886819585089484, 34.576785413456989, 0.0],
              [-80.88684738542284, 34.577289412661813, 0.0],
              [-80.887068985361395, 34.577839079293241, 0.0],
              [-80.887567518677969, 34.577930679475628, 0.0],
              [-80.887678318243445, 34.578022279787589, 0.0],
              [-80.888093717746983, 34.578113879928821, 0.0],
              [-80.889201584467372, 34.577884613095819, 0.0],
              [-80.890087718750664, 34.577907413352818, 0.0],
              [-80.890253984961461, 34.578021879335488, 0.0],
              [-80.890337118782824, 34.578503012521239, 0.0],
              [-80.890531118513636, 34.578892279902881, 0.0],
              [-80.890974184368815, 34.579052479364996, 0.0],
              [-80.892081918291794, 34.578915012621998, 0.0],
              [-80.892746517890117, 34.57893767927424, 0.0],
              [-80.893078918563191, 34.579166679931298, 0.0],
              [-80.89307898529492, 34.579395679720157, 0.0],
              [-80.892940584590278, 34.579716612990332, 0.0],
              [-80.89263598481736, 34.580037279910812, 0.0],
              [-80.892774518487755, 34.580151812805951, 0.0],
              [-80.89330071798058, 34.580151679241737, 0.0],
              [-80.893549984923354, 34.580289079199517, 0.0],
              [-80.893660785071006, 34.580449479402802, 0.0],
              [-80.893688584937479, 34.580930479211652, 0.0],
              [-80.893965718093355, 34.581457213440295, 0.0],
              [-80.893882718718217, 34.582259012980323, 0.0],
              [-80.893633585188823, 34.583266879452715, 0.0],
              [-80.893162984649777, 34.584710212788416, 0.0],
              [-80.893246184990033, 34.584801813335652, 0.0],
              [-80.893357118603731, 34.585351413303975, 0.0],
              [-80.893329384716409, 34.585740879615791, 0.0],
              [-80.892886318378672, 34.585924213190459, 0.0],
              [-80.892886384468056, 34.586107413256059, 0.0],
              [-80.893661985284751, 34.58688608006279, 0.0],
              [-80.893772784881833, 34.587115212609561, 0.0],
              [-80.893772918292996, 34.587596212939431, 0.0],
              [-80.892969918081832, 34.588398079409622, 0.0],
              [-80.892720785342718, 34.588879212627049, 0.0],
              [-80.892803917711362, 34.589360212492274, 0.0],
              [-80.893662585319959, 34.589818079443916, 0.0],
              [-80.894299784619975, 34.590551079999322, 0.0],
              [-80.894549184628289, 34.591169479914868, 0.0],
              [-80.894909384550914, 34.591742012870569, 0.0],
              [-80.895241918722931, 34.592429079594652, 0.0],
              [-80.89543598524638, 34.593185080043526, 0.0],
              [-80.895768517728627, 34.593803479350868, 0.0],
              [-80.895824118786095, 34.594559413325079, 0.0],
              [-80.895934985048598, 34.59490287988254, 0.0],
              [-80.896031322631785, 34.59590951779056, 0.0],
              [-80.896101584831754, 34.596643680072454, 0.0],
              [-80.896018517810973, 34.59701027970177, 0.0],
              [-80.895603118116412, 34.597216613347719, 0.0],
              [-80.895436918155198, 34.597125013357171, 0.0],
              [-80.895575117952404, 34.596437679919454, 0.0],
              [-80.895495112559317, 34.595842996994143, 0.0],
              [-80.895380985236955, 34.594994679256907, 0.0],
              [-80.89527018545013, 34.59483441340727, 0.0],
              [-80.895048584464959, 34.594811479600679, 0.0],
              [-80.894494717635993, 34.595201013006076, 0.0],
              [-80.893968384582763, 34.595842479968645, 0.0],
              [-80.893857717872379, 34.596300679853314, 0.0],
              [-80.893608517733867, 34.596392279632198, 0.0],
              [-80.893580784342717, 34.596483879604165, 0.0],
              [-80.89322078444998, 34.597010812793393, 0.0],
              [-80.893165518287233, 34.597354413286908, 0.0],
              [-80.893137784681826, 34.597491880057241, 0.0],
              [-80.89255618518969, 34.597858613005428, 0.0],
              [-80.892417785249506, 34.598225012595606, 0.0],
              [-80.892538454529301, 34.598734790549834, 0.0],
              [-80.889099105723787, 34.599372018855689, 0.0],
              [-80.888488587904092, 34.599463899659938, 0.0],
              [-80.887976543769014, 34.599553209177095, 0.0],
              [-80.888124184637206, 34.599119013094793, 0.0],
              [-80.888096318341098, 34.597492679662579, 0.0],
              [-80.888456385075685, 34.597446812782458, 0.0],
              [-80.888622517906995, 34.597309279498582, 0.0],
              [-80.888927318133156, 34.597286479997997, 0.0],
              [-80.889730518006346, 34.596782279472912, 0.0],
              [-80.890145984754071, 34.596690679859151, 0.0],
              [-80.890672317634539, 34.596278279426535, 0.0],
              [-80.890755317697611, 34.595911813210428, 0.0],
              [-80.890921384312691, 34.595591079544612, 0.0],
              [-80.891170585192853, 34.59536187974912, 0.0],
            ],
            [
              [-80.89202771829703, 34.586130479576582, 0.0],
              [-80.891861517886056, 34.585993079268306, 0.0],
              [-80.891639918119864, 34.586016079447944, 0.0],
              [-80.891473784692565, 34.586382613199589, 0.0],
              [-80.89144631846267, 34.587321679892774, 0.0],
              [-80.891723385027134, 34.588215013202635, 0.0],
              [-80.891917517725332, 34.588444079269081, 0.0],
              [-80.892277518368999, 34.588535612513738, 0.0],
              [-80.892498984806537, 34.588146213115635, 0.0],
              [-80.892498917808126, 34.587917080061459, 0.0],
              [-80.892166384594375, 34.587482012834258, 0.0],
              [-80.89202771829703, 34.586130479576582, 0.0],
            ],
            [
              [-80.88922998446948, 34.583794480075447, 0.0],
              [-80.888952784630732, 34.582740813236121, 0.0],
              [-80.888371317961315, 34.581664279640421, 0.0],
              [-80.888094118465986, 34.581618479643659, 0.0],
              [-80.88803878440369, 34.581985079988904, 0.0],
              [-80.888703718400564, 34.58347387985679, 0.0],
              [-80.88903618455781, 34.583863212638285, 0.0],
              [-80.88922998446948, 34.583794480075447, 0.0],
            ],
            [
              [-80.891805584996533, 34.582625879796637, 0.0],
              [-80.892331918150063, 34.583244279437885, 0.0],
              [-80.89258118533715, 34.583221279697497, 0.0],
              [-80.892608784384436, 34.583015213216278, 0.0],
              [-80.892359385050113, 34.582511279892493, 0.0],
              [-80.891860784663834, 34.581686612740135, 0.0],
              [-80.891694585094655, 34.581457612825908, 0.0],
              [-80.891472918001782, 34.580976613272284, 0.0],
              [-80.891251318046173, 34.580793413368646, 0.0],
              [-80.89108518437223, 34.580793413388321, 0.0],
              [-80.890891384629214, 34.581068412777142, 0.0],
              [-80.89108531856634, 34.581572279542875, 0.0],
              [-80.891805584996533, 34.582625879796637, 0.0],
            ],
            [
              [-80.896119985053531, 34.556007680007674, 0.0],
              [-80.895898584307631, 34.555893212903214, 0.0],
              [-80.895621584988589, 34.555939013184698, 0.0],
              [-80.895566318038291, 34.556076479440861, 0.0],
              [-80.895732518403392, 34.556259679264102, 0.0],
              [-80.89612011866096, 34.556191013096786, 0.0],
              [-80.896119985053531, 34.556007680007674, 0.0],
            ],
            [
              [-80.885874518125391, 34.542379879588914, 0.0],
              [-80.885708384460372, 34.542265279570387, 0.0],
              [-80.885293184476083, 34.542379879931744, 0.0],
              [-80.885237917977022, 34.542654879234377, 0.0],
              [-80.885348585037804, 34.542746479886063, 0.0],
              [-80.885597718740797, 34.542746479568642, 0.0],
              [-80.885819118048985, 34.542608879359591, 0.0],
              [-80.885874518125391, 34.542379879588914, 0.0],
            ],
            [
              [-80.879867517792306, 34.54419008003164, 0.0],
              [-80.880365784438453, 34.544350279972015, 0.0],
              [-80.88061498439474, 34.544235679534147, 0.0],
              [-80.880642585139086, 34.543960879507544, 0.0],
              [-80.880144318439321, 34.543709013483685, 0.0],
              [-80.879950518542401, 34.543709013503431, 0.0],
              [-80.879812185058299, 34.543800612557881, 0.0],
              [-80.879756785415879, 34.544029679321298, 0.0],
              [-80.879867517792306, 34.54419008003164, 0.0],
            ],
            [
              [-80.874413518068337, 34.556399479252462, 0.0],
              [-80.874468918096994, 34.558369413169338, 0.0],
              [-80.874801185359402, 34.558987880014747, 0.0],
              [-80.875631718786551, 34.559972879341736, 0.0],
              [-80.875659318330221, 34.560179012762724, 0.0],
              [-80.875548585404246, 34.560408079958528, 0.0],
              [-80.875770184456158, 34.560728679418524, 0.0],
              [-80.875825518580115, 34.561507613179693, 0.0],
              [-80.876046984846269, 34.56235507931823, 0.0],
              [-80.876517718149643, 34.562904879992161, 0.0],
              [-80.876794584471895, 34.562996479820825, 0.0],
              [-80.877154517719475, 34.562904812579902, 0.0],
              [-80.877736118653246, 34.562400813301977, 0.0],
              [-80.878483584890347, 34.561438812556453, 0.0],
              [-80.878926718131339, 34.560293413161915, 0.0],
              [-80.878954318154513, 34.559674879573045, 0.0],
              [-80.878594317788327, 34.559308413342812, 0.0],
              [-80.878621985392954, 34.558827412847926, 0.0],
              [-80.878511184517762, 34.558506679505903, 0.0],
              [-80.878206585195898, 34.55816321265408, 0.0],
              [-80.877486785112865, 34.557590479701119, 0.0],
              [-80.877182185338825, 34.556880413339137, 0.0],
              [-80.877071584607663, 34.556216213315466, 0.0],
              [-80.877182318220292, 34.554475213492275, 0.0],
              [-80.877237518039735, 34.553055079728914, 0.0],
              [-80.87690551876733, 34.55200127990544, 0.0],
              [-80.876573184704185, 34.551772279530063, 0.0],
              [-80.876130318575449, 34.551772279817818, 0.0],
              [-80.876130318421389, 34.551474479984471, 0.0],
              [-80.876683918542355, 34.550993413062471, 0.0],
              [-80.876739318466448, 34.550787213092576, 0.0],
              [-80.87654551769576, 34.550604013023161, 0.0],
              [-80.876157984636194, 34.550604013385225, 0.0],
              [-80.875576584668693, 34.550833080045386, 0.0],
              [-80.874801318773891, 34.551428679800935, 0.0],
              [-80.874219918178071, 34.552436613425186, 0.0],
              [-80.874053784628288, 34.552963480044106, 0.0],
              [-80.874109117743359, 34.554360812635814, 0.0],
              [-80.874191984448828, 34.555735079883874, 0.0],
              [-80.874413518068337, 34.556399479252462, 0.0],
            ],
            [
              [-80.87236471836458, 34.55775087951406, 0.0],
              [-80.872170918158986, 34.557544812890931, 0.0],
              [-80.87183858481437, 34.557636479884529, 0.0],
              [-80.871340118394883, 34.558094613384561, 0.0],
              [-80.871063184533512, 34.558529812568196, 0.0],
              [-80.870786318008257, 34.559262812622144, 0.0],
              [-80.871063184741942, 34.560041612913437, 0.0],
              [-80.872004518749648, 34.56127848007516, 0.0],
              [-80.871865918692222, 34.562378079252184, 0.0],
              [-80.871921318520066, 34.562721679668847, 0.0],
              [-80.872585784972898, 34.563248480049729, 0.0],
              [-80.874302518639183, 34.564210612911651, 0.0],
              [-80.874662518623296, 34.564119013406639, 0.0],
              [-80.874606984995765, 34.563706613142998, 0.0],
              [-80.874496517814123, 34.563523412782509, 0.0],
              [-80.874468718669021, 34.562973612668763, 0.0],
              [-80.874385718504072, 34.5629278130885, 0.0],
              [-80.874247184898934, 34.562171879324183, 0.0],
              [-80.873804185026714, 34.561370212968271, 0.0],
              [-80.873250518156865, 34.560614279578864, 0.0],
              [-80.873084384937002, 34.560339412544067, 0.0],
              [-80.873111985159952, 34.559835413437696, 0.0],
              [-80.872392317750695, 34.558094613491093, 0.0],
              [-80.87236471836458, 34.55775087951406, 0.0],
            ],
            [
              [-80.876766918321763, 34.566386679238825, 0.0],
              [-80.877320718493507, 34.567715213153797, 0.0],
              [-80.877514584492346, 34.568608612705411, 0.0],
              [-80.878234517997498, 34.569616413015524, 0.0],
              [-80.87851151871098, 34.569708013110549, 0.0],
              [-80.879009917874583, 34.569593479603334, 0.0],
              [-80.879342184595473, 34.569295679243197, 0.0],
              [-80.879480585394191, 34.569089479520258, 0.0],
              [-80.879480584513644, 34.568562613014734, 0.0],
              [-80.879342117780638, 34.56821901292691, 0.0],
              [-80.878622184995464, 34.567302812616639, 0.0],
              [-80.877459118578145, 34.566020079396438, 0.0],
              [-80.877320717803983, 34.565699413484488, 0.0],
              [-80.877237717827327, 34.565149679978717, 0.0],
              [-80.877016185429639, 34.564966412575366, 0.0],
              [-80.876489984606039, 34.565035079969824, 0.0],
              [-80.876406918118889, 34.565172613405579, 0.0],
              [-80.876766918321763, 34.566386679238825, 0.0],
            ],
          ],
          [
            [
              [-80.889713785390882, 34.671273479251724, 0.0],
              [-80.889519717832854, 34.671273612616005, 0.0],
              [-80.889103717645781, 34.671479812915116, 0.0],
              [-80.887994784524238, 34.671525679564581, 0.0],
              [-80.886900318252231, 34.670042213147752, 0.0],
              [-80.886362717775256, 34.669313479864691, 0.0],
              [-80.885831918689036, 34.668594013248629, 0.0],
              [-80.886192318450327, 34.668296213325618, 0.0],
              [-80.886303118097473, 34.667540279602527, 0.0],
              [-80.885997984833821, 34.666669812414661, 0.0],
              [-80.885110917948253, 34.665959879814153, 0.0],
              [-80.884999918341663, 34.66568487968685, 0.0],
              [-80.884556385263124, 34.665593412944148, 0.0],
              [-80.884445385317633, 34.665501879959898, 0.0],
              [-80.884417584316424, 34.66483747983898, 0.0],
              [-80.884223517690245, 34.664562612673329, 0.0],
              [-80.883308585413914, 34.6639672131396, 0.0],
              [-80.883308584388885, 34.663829812801708, 0.0],
              [-80.884168117852127, 34.663761013138206, 0.0],
              [-80.884334385120098, 34.663646413215858, 0.0],
              [-80.884334318738169, 34.663440279641769, 0.0],
              [-80.883946184956159, 34.663348612885478, 0.0],
              [-80.883142318429435, 34.663348679490795, 0.0],
              [-80.882255184772959, 34.663142679347814, 0.0],
              [-80.880979917788736, 34.662249479095784, 0.0],
              [-80.880647317897697, 34.661722679206733, 0.0],
              [-80.880064918158368, 34.660188012466691, 0.0],
              [-80.87975998466176, 34.659867413073286, 0.0],
              [-80.879371917760508, 34.659867280018972, 0.0],
              [-80.877514584360625, 34.66144801253192, 0.0],
              [-80.876072918343738, 34.662570279291295, 0.0],
              [-80.875740317762151, 34.662639079782096, 0.0],
              [-80.875795717960173, 34.66215801334193, 0.0],
              [-80.876294784758272, 34.661012812822023, 0.0],
              [-80.877133784536582, 34.659961479649667, 0.0],
              [-80.877154118262553, 34.65993607921709, 0.0],
              [-80.877375917779673, 34.659844479221817, 0.0],
              [-80.878096717712523, 34.659088479719422, 0.0],
              [-80.878900585453962, 34.658699079273035, 0.0],
              [-80.878928384725384, 34.658378413310189, 0.0],
              [-80.878623385208456, 34.657645413241561, 0.0],
              [-80.878623318688369, 34.657416279382673, 0.0],
              [-80.878983784817308, 34.656385613000317, 0.0],
              [-80.87964898480233, 34.655423479323034, 0.0],
              [-80.879704384879872, 34.654850813169944, 0.0],
              [-80.880231118776706, 34.65427807923065, 0.0],
              [-80.880424985082541, 34.653911679802341, 0.0],
              [-80.880951918488279, 34.653476279490185, 0.0],
              [-80.881090384904695, 34.652972412646186, 0.0],
              [-80.881256584380438, 34.652720412577004, 0.0],
              [-80.882282184975182, 34.651827079846576, 0.0],
              [-80.88239298499316, 34.651620879621618, 0.0],
              [-80.882393118605009, 34.6510710799378, 0.0],
              [-80.881284318159118, 34.650338212912779, 0.0],
              [-80.881256584472482, 34.650154879851904, 0.0],
              [-80.881589318096445, 34.649971679173689, 0.0],
              [-80.882088185097487, 34.649902879333808, 0.0],
              [-80.882198984866321, 34.649696812484649, 0.0],
              [-80.881866318444679, 34.649238679942272, 0.0],
              [-80.881866318254168, 34.6490784133291, 0.0],
              [-80.882115718567007, 34.649009612924118, 0.0],
              [-80.882670118592813, 34.649147013307726, 0.0],
              [-80.882919585162412, 34.64903247948935, 0.0],
              [-80.883085785061581, 34.648688812991637, 0.0],
              [-80.883972784858059, 34.647497613246394, 0.0],
              [-80.885164385233153, 34.646375012985608, 0.0],
              [-80.885912918418768, 34.645825279842249, 0.0],
              [-80.886079118710299, 34.64552747976834, 0.0],
              [-80.886938318470982, 34.645298213178464, 0.0],
              [-80.887437117666408, 34.644977479888226, 0.0],
              [-80.887437118715795, 34.644656813104234, 0.0],
              [-80.887132118120064, 34.644404879453568, 0.0],
              [-80.886522384389508, 34.644290479692387, 0.0],
              [-80.885663184872968, 34.64429048000558, 0.0],
              [-80.885247517724792, 34.644153212567403, 0.0],
              [-80.884637718233662, 34.643649279198243, 0.0],
              [-80.884554384938028, 34.643397413071284, 0.0],
              [-80.884859318053884, 34.643374413313822, 0.0],
              [-80.885579984859177, 34.64378661291321, 0.0],
              [-80.888157717883288, 34.643625879140806, 0.0],
              [-80.888711785327573, 34.643236479554666, 0.0],
              [-80.889210717794725, 34.642595012411505, 0.0],
              [-80.889681718492, 34.641793279571431, 0.0],
              [-80.89051311762573, 34.641564012580453, 0.0],
              [-80.891067385235345, 34.641060013146671, 0.0],
              [-80.891122718549241, 34.640189679080244, 0.0],
              [-80.891732185411442, 34.639342012911719, 0.0],
              [-80.891787585041968, 34.63897547962398, 0.0],
              [-80.891704385051156, 34.638906812522954, 0.0],
              [-80.89134418512235, 34.638883879161156, 0.0],
              [-80.89076218509156, 34.639136013042709, 0.0],
              [-80.890346384881639, 34.639136013160815, 0.0],
              [-80.890290985205624, 34.638746612624416, 0.0],
              [-80.890817518225916, 34.638448812407887, 0.0],
              [-80.891011517640166, 34.638219679589092, 0.0],
              [-80.891122318787382, 34.637601279089019, 0.0],
              [-80.891260785274739, 34.637303412686443, 0.0],
              [-80.891537918368329, 34.637234679290898, 0.0],
              [-80.891759717633462, 34.637555279746643, 0.0],
              [-80.891953718144777, 34.637555279731025, 0.0],
              [-80.892064518607796, 34.637463679097365, 0.0],
              [-80.892286117981897, 34.636890879837793, 0.0],
              [-80.892535517681225, 34.636547279724176, 0.0],
              [-80.8926463176211, 34.636180679116279, 0.0],
              [-80.892479918193885, 34.636066212486739, 0.0],
              [-80.891676317792303, 34.636112213020709, 0.0],
              [-80.891066517840002, 34.635722813272857, 0.0],
              [-80.891066385035984, 34.635425079929298, 0.0],
              [-80.891786918159639, 34.635012679816882, 0.0],
              [-80.891897718644657, 34.634829479608847, 0.0],
              [-80.892119318031519, 34.634279613081837, 0.0],
              [-80.89234098530801, 34.633959012667155, 0.0],
              [-80.892423784861037, 34.632149279159044, 0.0],
              [-80.892201984924114, 34.63169121255703, 0.0],
              [-80.891592318109318, 34.631095679117941, 0.0],
              [-80.891148718042714, 34.630317013277171, 0.0],
              [-80.890843917914765, 34.630065079723877, 0.0],
              [-80.890317317782163, 34.629927813200254, 0.0],
              [-80.890317185085621, 34.629744479822492, 0.0],
              [-80.89101011815697, 34.629675679395753, 0.0],
              [-80.89161971841844, 34.62946947971016, 0.0],
              [-80.892090718038347, 34.628919479296137, 0.0],
              [-80.892062917618759, 34.628392679447465, 0.0],
              [-80.89164718487703, 34.627797213029744, 0.0],
              [-80.891231384681859, 34.627453679925793, 0.0],
              [-80.890427784956813, 34.627316279721661, 0.0],
              [-80.889651784809104, 34.626698012526099, 0.0],
              [-80.888709584435446, 34.626377479975659, 0.0],
              [-80.888515584952813, 34.626056879497817, 0.0],
              [-80.888432384587603, 34.625255080056029, 0.0],
              [-80.888321518197159, 34.625140613000951, 0.0],
              [-80.888210718286814, 34.625026012570785, 0.0],
              [-80.886991384327516, 34.624362012941347, 0.0],
              [-80.886049184436601, 34.623720679111237, 0.0],
              [-80.885855118774288, 34.623216813401882, 0.0],
              [-80.885605717881688, 34.622942013016115, 0.0],
              [-80.885051584996134, 34.62289621246164, 0.0],
              [-80.884691184473965, 34.623171079338604, 0.0],
              [-80.884053984455718, 34.624018612491874, 0.0],
              [-80.883222784368613, 34.624843413421985, 0.0],
              [-80.883195118709551, 34.625141213170231, 0.0],
              [-80.883278384375359, 34.62553061332698, 0.0],
              [-80.883749385365704, 34.625713813368606, 0.0],
              [-80.88422051845852, 34.62575961319282, 0.0],
              [-80.884497584778359, 34.625942813065933, 0.0],
              [-80.884525385091862, 34.626194680064316, 0.0],
              [-80.883887984890762, 34.62628641250182, 0.0],
              [-80.882945784984599, 34.625965812457181, 0.0],
              [-80.882696385062758, 34.625988679724522, 0.0],
              [-80.882585518326863, 34.626172012435759, 0.0],
              [-80.882751785003421, 34.626653012701141, 0.0],
              [-80.882363984532901, 34.627202879305521, 0.0],
              [-80.882280784697471, 34.627477679447047, 0.0],
              [-80.882391718548988, 34.628210679471778, 0.0],
              [-80.882474918544801, 34.628371013399423, 0.0],
              [-80.883084518627911, 34.628783213158187, 0.0],
              [-80.883195517707321, 34.629035212473532, 0.0],
              [-80.883112318449008, 34.629172680051241, 0.0],
              [-80.882336385336188, 34.629356012704335, 0.0],
              [-80.882086917830264, 34.629516279837347, 0.0],
              [-80.882059318401488, 34.630363879495007, 0.0],
              [-80.88219791882463, 34.630707613054518, 0.0],
              [-80.882170117963398, 34.63105107919759, 0.0],
              [-80.882031718593367, 34.631371679606637, 0.0],
              [-80.881948517870114, 34.631921479286966, 0.0],
              [-80.881837718518881, 34.631990279173671, 0.0],
              [-80.881421985412075, 34.631784079979248, 0.0],
              [-80.881227985144804, 34.631348879774151, 0.0],
              [-80.881117184860329, 34.630638879122898, 0.0],
              [-80.880895384642074, 34.630386879702399, 0.0],
              [-80.880451984680661, 34.630478479367831, 0.0],
              [-80.880147184311681, 34.630455679677951, 0.0],
              [-80.87987011878684, 34.629997479825199, 0.0],
              [-80.879620718682929, 34.629768479499205, 0.0],
              [-80.879343517810696, 34.62965401245242, 0.0],
              [-80.879011118569977, 34.629699812525239, 0.0],
              [-80.878539985463334, 34.630158012861465, 0.0],
              [-80.878234985088426, 34.63022667966159, 0.0],
              [-80.877542184780623, 34.629951879591701, 0.0],
              [-80.877403717979561, 34.629654079723252, 0.0],
              [-80.877237384307023, 34.629585412922189, 0.0],
              [-80.876489184497828, 34.629654079642727, 0.0],
              [-80.875602518418248, 34.629470879657973, 0.0],
              [-80.874798785204632, 34.629539679905754, 0.0],
              [-80.874383185031803, 34.629722879347533, 0.0],
              [-80.873856718540154, 34.630112279728152, 0.0],
              [-80.87371798447623, 34.630616279253928, 0.0],
              [-80.87310838444894, 34.630937012726541, 0.0],
              [-80.872748118536023, 34.63128061306562, 0.0],
              [-80.872415584718155, 34.631372213266125, 0.0],
              [-80.872166117664619, 34.63134927948353, 0.0],
              [-80.872082984781528, 34.631188879402679, 0.0],
              [-80.872332517897732, 34.62931061308646, 0.0],
              [-80.874798917669253, 34.628463013213207, 0.0],
              [-80.875824117917517, 34.628279812528831, 0.0],
              [-80.876018118650663, 34.628508812698477, 0.0],
              [-80.87729291826075, 34.628783679449754, 0.0],
              [-80.877653185276102, 34.628692079856883, 0.0],
              [-80.878179718266239, 34.628966879601442, 0.0],
              [-80.879454384349017, 34.628898079760582, 0.0],
              [-80.880008585247154, 34.629035479474766, 0.0],
              [-80.880202585472489, 34.629035479379745, 0.0],
              [-80.880451918703912, 34.628920879881449, 0.0],
              [-80.8804795843226, 34.627981812603444, 0.0],
              [-80.880729118692756, 34.627523612630512, 0.0],
              [-80.880673518407278, 34.626172213064962, 0.0],
              [-80.880590517970504, 34.626034813214218, 0.0],
              [-80.880562718087504, 34.625393412911151, 0.0],
              [-80.88064578518194, 34.625141413347968, 0.0],
              [-80.880784317850356, 34.624591612445549, 0.0],
              [-80.879980718822011, 34.623881680038302, 0.0],
              [-80.879952918529156, 34.623698413138271, 0.0],
              [-80.880451718274017, 34.62360661325426, 0.0],
              [-80.881144517799115, 34.623148479684779, 0.0],
              [-80.88178178482579, 34.623079679227864, 0.0],
              [-80.882446784940754, 34.623171412693054, 0.0],
              [-80.882973317680822, 34.623056680058319, 0.0],
              [-80.883416585079672, 34.622804679623485, 0.0],
              [-80.883610518086329, 34.622575612916762, 0.0],
              [-80.883693718457849, 34.622002879451074, 0.0],
              [-80.883416517930556, 34.621774012563968, 0.0],
              [-80.882640718230448, 34.621522012738019, 0.0],
              [-80.88258531763833, 34.62122421301936, 0.0],
              [-80.882696118098863, 34.62106387981914, 0.0],
              [-80.883471917936632, 34.620972213321089, 0.0],
              [-80.883804518663524, 34.620720213396986, 0.0],
              [-80.883804385123327, 34.620376479516679, 0.0],
              [-80.883527318366774, 34.620101679881095, 0.0],
              [-80.882862318701825, 34.619735412845579, 0.0],
              [-80.882446585114778, 34.618956479677379, 0.0],
              [-80.882224984452279, 34.618819080088173, 0.0],
              [-80.881864718702843, 34.618773413324597, 0.0],
              [-80.881449118777624, 34.619231480075058, 0.0],
              [-80.881227517798649, 34.619231479826311, 0.0],
              [-80.881033518790545, 34.619025412749394, 0.0],
              [-80.88100578514134, 34.618636012737142, 0.0],
              [-80.881255184977803, 34.618154879981645, 0.0],
              [-80.881753784696414, 34.61758221265778, 0.0],
              [-80.881753718156531, 34.617124013097524, 0.0],
              [-80.881393517825003, 34.616826279727754, 0.0],
              [-80.881061118190587, 34.616803413353466, 0.0],
              [-80.880340784698745, 34.61737621325328, 0.0],
              [-80.879952918051373, 34.617444879354466, 0.0],
              [-80.879786717785905, 34.617353279975156, 0.0],
              [-80.879786585289153, 34.616895212866815, 0.0],
              [-80.880839385313678, 34.615543613315786, 0.0],
              [-80.880839318203414, 34.615337480094631, 0.0],
              [-80.880257518610733, 34.615200012672084, 0.0],
              [-80.880174385287191, 34.615039679456636, 0.0],
              [-80.880479185121757, 34.614764879681978, 0.0],
              [-80.881061118321668, 34.614512813363852, 0.0],
              [-80.882363184384573, 34.614237813173425, 0.0],
              [-80.882501717964573, 34.614054613096556, 0.0],
              [-80.882834118799423, 34.613000879519873, 0.0],
              [-80.882861785067647, 34.612382412673455, 0.0],
              [-80.882750984763902, 34.61206167923369, 0.0],
              [-80.882335385115908, 34.611741013041311, 0.0],
              [-80.881753585158151, 34.611603679559359, 0.0],
              [-80.880756118138564, 34.611535079954251, 0.0],
              [-80.880201917754988, 34.611351812842329, 0.0],
              [-80.879897185435425, 34.611328879358112, 0.0],
              [-80.879758717660522, 34.611420479899444, 0.0],
              [-80.879260118635273, 34.611512212710878, 0.0],
              [-80.878678184604453, 34.611328879341734, 0.0],
              [-80.877514584838508, 34.611855879150625, 0.0],
              [-80.877015918090436, 34.612176612896512, 0.0],
              [-80.876711118464925, 34.612520079664371, 0.0],
              [-80.876683318495481, 34.612886813004195, 0.0],
              [-80.876821984370778, 34.613047079681408, 0.0],
              [-80.87687731842756, 34.613367813051866, 0.0],
              [-80.876101518741564, 34.613848813152252, 0.0],
              [-80.875935384845775, 34.614169479684961, 0.0],
              [-80.875935318145039, 34.614856613057867, 0.0],
              [-80.875381117950013, 34.615200412484739, 0.0],
              [-80.875353384774982, 34.615658479277954, 0.0],
              [-80.875658184597498, 34.616093612672238, 0.0],
              [-80.875325718108826, 34.616185280063583, 0.0],
              [-80.874799318782706, 34.615979079905145, 0.0],
              [-80.874245185184265, 34.615933279673051, 0.0],
              [-80.874051185115405, 34.616162413015182, 0.0],
              [-80.873995718221508, 34.616551679909882, 0.0],
              [-80.874300584442281, 34.617307679769155, 0.0],
              [-80.874328318519005, 34.617674212548735, 0.0],
              [-80.873968118810836, 34.617674279429743, 0.0],
              [-80.873109184910817, 34.616735080091537, 0.0],
              [-80.872887585028224, 34.616047879283997, 0.0],
              [-80.87244431840675, 34.615933413074067, 0.0],
              [-80.872028584805378, 34.616231079847289, 0.0],
              [-80.871169584569543, 34.616437212511507, 0.0],
              [-80.870837118340887, 34.616666279107839, 0.0],
              [-80.870587784624234, 34.617101613327534, 0.0],
              [-80.870560118355982, 34.6174452130196, 0.0],
              [-80.870449184819634, 34.617582613284839, 0.0],
              [-80.870199917852318, 34.617651279141391, 0.0],
              [-80.870088985453606, 34.617536679433059, 0.0],
              [-80.870005918813021, 34.617239012536714, 0.0],
              [-80.869673517809147, 34.61687247933294, 0.0],
              [-80.868592785366715, 34.616986879556777, 0.0],
              [-80.868454318748817, 34.616895279104661, 0.0],
              [-80.868454318729235, 34.616757879271681, 0.0],
              [-80.868592984803925, 34.616574612800314, 0.0],
              [-80.869673518773638, 34.616116612803474, 0.0],
              [-80.870504717952755, 34.615521013319849, 0.0],
              [-80.871225184765237, 34.615337812842469, 0.0],
              [-80.87150231842088, 34.61513161339672, 0.0],
              [-80.871529918837496, 34.614948412868991, 0.0],
              [-80.870615718474568, 34.614055013082293, 0.0],
              [-80.870615718416005, 34.613917613204485, 0.0],
              [-80.870948318081389, 34.613871812604941, 0.0],
              [-80.871862384540776, 34.614215279760622, 0.0],
              [-80.872610518372667, 34.614261212931929, 0.0],
              [-80.873081584602673, 34.614100879969001, 0.0],
              [-80.873469518278938, 34.613665612632801, 0.0],
              [-80.874217584844246, 34.613505213307199, 0.0],
              [-80.874466918560387, 34.613322013011853, 0.0],
              [-80.87446698539496, 34.612703479685919, 0.0],
              [-80.87479938497772, 34.6125202793461, 0.0],
              [-80.874965718765878, 34.612428612587657, 0.0],
              [-80.875104185077674, 34.611924812804332, 0.0],
              [-80.875436718584766, 34.611466479944021, 0.0],
              [-80.875464318730721, 34.611145879371549, 0.0],
              [-80.87532578484182, 34.610962612624263, 0.0],
              [-80.874799385069025, 34.61084807945555, 0.0],
              [-80.874162318602018, 34.610642012561115, 0.0],
              [-80.87369118500682, 34.610344212513318, 0.0],
              [-80.873663584564113, 34.61022967936114, 0.0],
              [-80.874079117877372, 34.6101610127497, 0.0],
              [-80.874799518132761, 34.610298279131932, 0.0],
              [-80.875547584745206, 34.610367079548055, 0.0],
              [-80.876517184448261, 34.610115079407464, 0.0],
              [-80.877237517643408, 34.60981721258149, 0.0],
              [-80.87732058499283, 34.609679680004923, 0.0],
              [-80.877237518394125, 34.60938187973948, 0.0],
              [-80.876655718645708, 34.608855212863702, 0.0],
              [-80.876378585162513, 34.608213812982363, 0.0],
              [-80.875713718058805, 34.607343413055496, 0.0],
              [-80.875658384365394, 34.607114279652009, 0.0],
              [-80.875963118269851, 34.607045613412943, 0.0],
              [-80.877015784560726, 34.607916013104237, 0.0],
              [-80.877237518357461, 34.608305412561577, 0.0],
              [-80.877930118167399, 34.608923879904552, 0.0],
              [-80.879093717665185, 34.60970261285398, 0.0],
              [-80.879536984439667, 34.609840013172196, 0.0],
              [-80.879897318623094, 34.609862880082581, 0.0],
              [-80.880257385255589, 34.609771279449646, 0.0],
              [-80.880478985168708, 34.609588013185316, 0.0],
              [-80.880506718809571, 34.609221412977234, 0.0],
              [-80.8800911185632, 34.608832079328295, 0.0],
              [-80.880174184723018, 34.608557212692297, 0.0],
              [-80.880700584444583, 34.60853427962342, 0.0],
              [-80.88117158530433, 34.608763279894745, 0.0],
              [-80.881753385099927, 34.608763279850102, 0.0],
              [-80.881974918028789, 34.608648813010205, 0.0],
              [-80.882085784705666, 34.608488413028326, 0.0],
              [-80.882141117658506, 34.607755279873331, 0.0],
              [-80.882335185036922, 34.607572079541704, 0.0],
              [-80.883027718254667, 34.607228479945597, 0.0],
              [-80.883027584943633, 34.606907812879506, 0.0],
              [-80.882556718191395, 34.606060279836491, 0.0],
              [-80.882445784477994, 34.605602079189495, 0.0],
              [-80.882251784746003, 34.605419013171577, 0.0],
              [-80.881642385058484, 34.605166879157785, 0.0],
              [-80.881642384588829, 34.604731679249781, 0.0],
              [-80.881808517804245, 34.604388212964665, 0.0],
              [-80.882196385138187, 34.604296413068631, 0.0],
              [-80.882694985015391, 34.604685813098399, 0.0],
              [-80.883332317703861, 34.60580821252735, 0.0],
              [-80.88391418460138, 34.606335079166584, 0.0],
              [-80.884468185380925, 34.606678479961566, 0.0],
              [-80.885299318337118, 34.606884613126063, 0.0],
              [-80.885881117718597, 34.606884479393891, 0.0],
              [-80.886185784818807, 34.606747079594264, 0.0],
              [-80.886296718285962, 34.606403479431968, 0.0],
              [-80.886296584529248, 34.605716279925623, 0.0],
              [-80.886130384518722, 34.605304079492946, 0.0],
              [-80.886130184412991, 34.603860879243442, 0.0],
              [-80.88585311863433, 34.603425679159756, 0.0],
              [-80.885936185006017, 34.603196612874754, 0.0],
              [-80.886850317903239, 34.602486412572155, 0.0],
              [-80.887321118659528, 34.601409679978119, 0.0],
              [-80.887459585171584, 34.60131807924688, 0.0],
              [-80.887819784443508, 34.601295079772825, 0.0],
              [-80.887875117813408, 34.601226479935725, 0.0],
              [-80.88787498525457, 34.60097447970653, 0.0],
              [-80.888041185396318, 34.600745412867433, 0.0],
              [-80.888068918778174, 34.600470613251424, 0.0],
              [-80.887930318251748, 34.60024147934346, 0.0],
              [-80.887874918134955, 34.599852079684837, 0.0],
              [-80.88795148230011, 34.599626914349457, 0.0],
              [-80.888488492623068, 34.599533253089206, 0.0],
              [-80.889099012151618, 34.599441372266476, 0.0],
              [-80.892554176451881, 34.598801210529786, 0.0],
              [-80.892667185203251, 34.599278612546897, 0.0],
              [-80.892667184305267, 34.599599279272532, 0.0],
              [-80.892362584483493, 34.599759679782601, 0.0],
              [-80.892251785456182, 34.600469812563681, 0.0],
              [-80.892750585296682, 34.600630079188086, 0.0],
              [-80.893415385146852, 34.600721613194402, 0.0],
              [-80.893664717676899, 34.600904812782197, 0.0],
              [-80.893969718525852, 34.602302079998893, 0.0],
              [-80.893969984952619, 34.603058079712532, 0.0],
              [-80.893748517902324, 34.603653613255446, 0.0],
              [-80.893776117963597, 34.604020079670832, 0.0],
              [-80.894025517747195, 34.604409479466504, 0.0],
              [-80.893997985416135, 34.605005012553015, 0.0],
              [-80.893665718802126, 34.606013013135993, 0.0],
              [-80.892917917831454, 34.606792013119922, 0.0],
              [-80.892557984584585, 34.607341679748167, 0.0],
              [-80.892585584446323, 34.607754013335366, 0.0],
              [-80.892779585182893, 34.607937213097046, 0.0],
              [-80.893749384689286, 34.608166080039659, 0.0],
              [-80.893693918048129, 34.608349413207044, 0.0],
              [-80.893278384682063, 34.608738880072671, 0.0],
              [-80.893195317864652, 34.608990879259331, 0.0],
              [-80.893444784542709, 34.609655212612978, 0.0],
              [-80.893832718345095, 34.609952812914692, 0.0],
              [-80.893777385216936, 34.610136079865498, 0.0],
              [-80.893389585082431, 34.61052561279012, 0.0],
              [-80.893306518175891, 34.610731679988, 0.0],
              [-80.893334717686869, 34.612449680028732, 0.0],
              [-80.89344551793009, 34.612747479780488, 0.0],
              [-80.893777984599893, 34.613045213213077, 0.0],
              [-80.89369511846138, 34.614282212859401, 0.0],
              [-80.893916784973925, 34.614854813107556, 0.0],
              [-80.894388118078766, 34.615587680066994, 0.0],
              [-80.894859184574031, 34.616114479830706, 0.0],
              [-80.894831518724658, 34.616251879830259, 0.0],
              [-80.894554385113395, 34.616412279868058, 0.0],
              [-80.894083717756402, 34.617672279162676, 0.0],
              [-80.89411138512915, 34.617924212520037, 0.0],
              [-80.894305384453475, 34.618153213128764, 0.0],
              [-80.894915117645354, 34.618359279661199, 0.0],
              [-80.895580118147564, 34.618473680071226, 0.0],
              [-80.895441585278192, 34.619000612580734, 0.0],
              [-80.895469318645951, 34.619206679680957, 0.0],
              [-80.895746384997622, 34.619435813336473, 0.0],
              [-80.896716384314232, 34.619824880045002, 0.0],
              [-80.897298385412455, 34.620214213153055, 0.0],
              [-80.897298384902129, 34.62042028002152, 0.0],
              [-80.896356318668353, 34.620420613329287, 0.0],
              [-80.896384117798078, 34.620764213031464, 0.0],
              [-80.896966117638101, 34.621290879381121, 0.0],
              [-80.896966117844173, 34.621497013334029, 0.0],
              [-80.896827518347564, 34.621565812731767, 0.0],
              [-80.896134784905584, 34.621565879791405, 0.0],
              [-80.89425078529807, 34.622734479490724, 0.0],
              [-80.894112384870979, 34.623032279678327, 0.0],
              [-80.894251117895109, 34.623467480040262, 0.0],
              [-80.895997118475364, 34.624956079275364, 0.0],
              [-80.896274185143184, 34.625139279970448, 0.0],
              [-80.897548917811335, 34.625643013297861, 0.0],
              [-80.898269517834152, 34.625528212502509, 0.0],
              [-80.8989897848097, 34.625138679377017, 0.0],
              [-80.899460784420469, 34.624886612736823, 0.0],
              [-80.899737985412884, 34.624932412616907, 0.0],
              [-80.899682518268094, 34.625138479880505, 0.0],
              [-80.899239517631287, 34.625963213062505, 0.0],
              [-80.898796318465642, 34.626536012689627, 0.0],
              [-80.898796318000606, 34.626971213322015, 0.0],
              [-80.898934918261986, 34.627223213306983, 0.0],
              [-80.899350718768261, 34.627566679763085, 0.0],
              [-80.89973858443247, 34.627727013183446, 0.0],
              [-80.900874784642383, 34.627589213355073, 0.0],
              [-80.901096585314221, 34.627703679261295, 0.0],
              [-80.90109658485504, 34.627978479742509, 0.0],
              [-80.900209984964008, 34.628597279892986, 0.0],
              [-80.900182385461676, 34.629009612859946, 0.0],
              [-80.900681385076069, 34.629879879344216, 0.0],
              [-80.90140211795763, 34.630658612500056, 0.0],
              [-80.901402184723409, 34.631025079883663, 0.0],
              [-80.901291517848676, 34.631254079170681, 0.0],
              [-80.901291717615607, 34.631781012912121, 0.0],
              [-80.901762918025781, 34.632353479210387, 0.0],
              [-80.902760584680664, 34.632559479929526, 0.0],
              [-80.902899184904655, 34.632673879532504, 0.0],
              [-80.902871584332871, 34.632903012636085, 0.0],
              [-80.90256691816785, 34.633246679771794, 0.0],
              [-80.901846384681079, 34.633430079894914, 0.0],
              [-80.901597118248588, 34.633728012519676, 0.0],
              [-80.901237117789421, 34.635010813310579, 0.0],
              [-80.901431385231348, 34.635812479855261, 0.0],
              [-80.901653184451774, 34.636110079725036, 0.0],
              [-80.901653184649206, 34.636247612726187, 0.0],
              [-80.901348384614337, 34.636408012818819, 0.0],
              [-80.900655517688662, 34.636202079473676, 0.0],
              [-80.900267384786474, 34.635927279404328, 0.0],
              [-80.899879517995046, 34.635996079830527, 0.0],
              [-80.899657917848728, 34.636477213236105, 0.0],
              [-80.899436184448135, 34.636591679630996, 0.0],
              [-80.899131318712136, 34.636500213062497, 0.0],
              [-80.898854184988721, 34.636546012861452, 0.0],
              [-80.89874338444929, 34.636706412512126, 0.0],
              [-80.898632585004677, 34.63723321322977, 0.0],
              [-80.898383385403989, 34.637806013174718, 0.0],
              [-80.898577517949661, 34.638378612652197, 0.0],
              [-80.898799384628717, 34.638813812779979, 0.0],
              [-80.898883117939818, 34.641127279834485, 0.0],
              [-80.898523318452604, 34.642914079467644, 0.0],
              [-80.898385118243297, 34.64451747957272, 0.0],
              [-80.89855151824726, 34.644723679364077, 0.0],
              [-80.898967318532058, 34.644929679843493, 0.0],
              [-80.898994985037021, 34.645113012428737, 0.0],
              [-80.898191184886713, 34.645227679290436, 0.0],
              [-80.897830917973607, 34.645479679858724, 0.0],
              [-80.8966951182299, 34.64719801260506, 0.0],
              [-80.896556384729323, 34.647679013184934, 0.0],
              [-80.896778384720662, 34.648228679462846, 0.0],
              [-80.896916984339867, 34.648366079997658, 0.0],
              [-80.896806118200928, 34.648618013286502, 0.0],
              [-80.896307184315148, 34.648686879567968, 0.0],
              [-80.89586398470631, 34.64932841260903, 0.0],
              [-80.895171184334373, 34.649969880021395, 0.0],
              [-80.894672518428052, 34.651207012972215, 0.0],
              [-80.894422984298245, 34.651481812786336, 0.0],
              [-80.894090385389603, 34.651619279936376, 0.0],
              [-80.893979517907809, 34.651779612670488, 0.0],
              [-80.893979718243529, 34.652031612436083, 0.0],
              [-80.894256918629097, 34.652329412565443, 0.0],
              [-80.894866785298049, 34.652741479228773, 0.0],
              [-80.894839117769578, 34.653153879780312, 0.0],
              [-80.894367984674915, 34.653337213142308, 0.0],
              [-80.894312584384267, 34.653589212535408, 0.0],
              [-80.895033518144302, 34.654047079318239, 0.0],
              [-80.895088918572355, 34.654230413334737, 0.0],
              [-80.894784118180169, 34.654894679157195, 0.0],
              [-80.894867385266238, 34.655513212386481, 0.0],
              [-80.894784384789119, 34.656177479263413, 0.0],
              [-80.893177185332405, 34.659384679357657, 0.0],
              [-80.892401117855911, 34.660094879164355, 0.0],
              [-80.892373385482557, 34.660369813279679, 0.0],
              [-80.893260717901313, 34.661102679769314, 0.0],
              [-80.893371585261917, 34.661377479281541, 0.0],
              [-80.893343984825421, 34.66190441254934, 0.0],
              [-80.892734318122606, 34.663210079798951, 0.0],
              [-80.892678984462236, 34.664011812862952, 0.0],
              [-80.892318917734755, 34.665111279291104, 0.0],
              [-80.892402118492711, 34.665248679767586, 0.0],
              [-80.893649785236391, 34.666096079916308, 0.0],
              [-80.894176584903846, 34.666325013210248, 0.0],
              [-80.894703318072843, 34.666164612668837, 0.0],
              [-80.894814184470647, 34.666233279977817, 0.0],
              [-80.895174718499817, 34.66717241260627, 0.0],
              [-80.895424384854422, 34.667355612715127, 0.0],
              [-80.894870118354376, 34.668913279249672, 0.0],
              [-80.892874184663199, 34.670013079958913, 0.0],
              [-80.89140478497491, 34.670448679626247, 0.0],
              [-80.890489984931605, 34.670838080000991, 0.0],
              [-80.890323718591404, 34.671227612795803, 0.0],
              [-80.890517917810655, 34.671983412500964, 0.0],
              [-80.890185318530996, 34.672373012385414, 0.0],
              [-80.890018918448618, 34.672395879563581, 0.0],
              [-80.889796985291156, 34.672166812989943, 0.0],
              [-80.889713918293737, 34.671892012950764, 0.0],
              [-80.889713785390882, 34.671273479251724, 0.0],
            ],
            [
              [-80.892901318180463, 34.666416879653937, 0.0],
              [-80.892235784619288, 34.666302413343516, 0.0],
              [-80.891514984443873, 34.665982012561969, 0.0],
              [-80.890710784980271, 34.665203279550234, 0.0],
              [-80.890433584393207, 34.66513461305081, 0.0],
              [-80.890378385006173, 34.665959212909776, 0.0],
              [-80.890544917855152, 34.667310612896379, 0.0],
              [-80.890905518589506, 34.668341279347771, 0.0],
              [-80.891238318306264, 34.668501612804306, 0.0],
              [-80.891792585059662, 34.668409879499315, 0.0],
              [-80.892624318417248, 34.667928812845197, 0.0],
              [-80.893289517882636, 34.66728721245849, 0.0],
              [-80.893344984721409, 34.666897879113584, 0.0],
              [-80.892901318180463, 34.666416879653937, 0.0],
            ],
            [
              [-80.894003318468748, 34.63187408001496, 0.0],
              [-80.893615318391099, 34.631438879613171, 0.0],
              [-80.893448984865017, 34.631507813155913, 0.0],
              [-80.893476784370762, 34.631897213338256, 0.0],
              [-80.893698584811929, 34.632103279822999, 0.0],
              [-80.894003385073162, 34.632080413179324, 0.0],
              [-80.894003318468748, 34.63187408001496, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 841,
        ComID: 9713751,
        FDate: "1999/10/07",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wateree",
        AreaSqKm: 1.218,
        Elevation: 0.0,
        ReachCode: "03050104002040",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.11181536171000001,
        Shape_Area: 0.0001194629,
        Reservoir: 57,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.935445784230168, 34.453743879712846, 0.0],
              [-80.935556518135442, 34.453995679463766, 0.0],
              [-80.936166117718088, 34.453995479902304, 0.0],
              [-80.936330784763186, 34.453995413360396, 0.0],
              [-80.936248118390424, 34.454270213001706, 0.0],
              [-80.935308317902425, 34.454843412847254, 0.0],
              [-80.935142384571819, 34.455026679919577, 0.0],
              [-80.935142717617069, 34.455324479944338, 0.0],
              [-80.935779118691997, 34.455896879401855, 0.0],
              [-80.936608918381793, 34.456194212903227, 0.0],
              [-80.936719718196656, 34.456308680234741, 0.0],
              [-80.936719985289471, 34.45681261328378, 0.0],
              [-80.936443584462978, 34.456973080177988, 0.0],
              [-80.935642117798437, 34.457569080128039, 0.0],
              [-80.935614785003111, 34.458164679677864, 0.0],
              [-80.935366117608055, 34.458485613174275, 0.0],
              [-80.934868585208406, 34.458829412787651, 0.0],
              [-80.93381798532964, 34.459242212827711, 0.0],
              [-80.933514184917911, 34.459837879688344, 0.0],
              [-80.933431518652824, 34.460319013292512, 0.0],
              [-80.93354251803423, 34.460845813019681, 0.0],
              [-80.933874585241526, 34.461280812678133, 0.0],
              [-80.933847117775983, 34.461464213489613, 0.0],
              [-80.933736517673523, 34.461510012943997, 0.0],
              [-80.933432318447487, 34.461395613017785, 0.0],
              [-80.932934117907408, 34.460891879542928, 0.0],
              [-80.932436184729212, 34.460731813073423, 0.0],
              [-80.931523718442463, 34.460892680331277, 0.0],
              [-80.930445185013554, 34.460870213202568, 0.0],
              [-80.930362318731781, 34.461007679430217, 0.0],
              [-80.930362984689836, 34.462038479952163, 0.0],
              [-80.931358317982443, 34.46171741285449, 0.0],
              [-80.931794584768809, 34.461700679535696, 0.0],
              [-80.931966717797536, 34.461694212906281, 0.0],
              [-80.932796785239361, 34.462358079429066, 0.0],
              [-80.933737785258884, 34.463342613383809, 0.0],
              [-80.934069984553901, 34.463800479958358, 0.0],
              [-80.934180785204845, 34.464121212894526, 0.0],
              [-80.934153384377836, 34.4643960801871, 0.0],
              [-80.933849117871588, 34.464281680224872, 0.0],
              [-80.93265891782022, 34.462839213667067, 0.0],
              [-80.931967118751274, 34.462312613476939, 0.0],
              [-80.931413917984344, 34.462129613033071, 0.0],
              [-80.9311927178113, 34.462244279874604, 0.0],
              [-80.931220585062093, 34.462542013081077, 0.0],
              [-80.931718917592221, 34.463229013039509, 0.0],
              [-80.931940384245479, 34.463778612999896, 0.0],
              [-80.931940784811445, 34.464191013150185, 0.0],
              [-80.931719585264929, 34.464557613459618, 0.0],
              [-80.931139118508511, 34.465015879349266, 0.0],
              [-80.92914851811426, 34.466047812733052, 0.0],
              [-80.928789185068212, 34.466437279899154, 0.0],
              [-80.928789518694074, 34.466826679420841, 0.0],
              [-80.928927918121403, 34.466964079526335, 0.0],
              [-80.929702384299489, 34.467032479721851, 0.0],
              [-80.930725918189765, 34.4674442798919, 0.0],
              [-80.930947317800175, 34.467627479478061, 0.0],
              [-80.930975118185586, 34.467879413139343, 0.0],
              [-80.930781517709988, 34.467971079784469, 0.0],
              [-80.928762385077974, 34.467651279692866, 0.0],
              [-80.927987984872118, 34.467720479888939, 0.0],
              [-80.927324585265126, 34.468293412683408, 0.0],
              [-80.927324718442662, 34.468499480013122, 0.0],
              [-80.928016318506067, 34.468659679815637, 0.0],
              [-80.928016385165975, 34.469049079691203, 0.0],
              [-80.92743578464966, 34.469507412983688, 0.0],
              [-80.926744717745379, 34.469897079916024, 0.0],
              [-80.926800318761039, 34.470263613631616, 0.0],
              [-80.926938584729314, 34.470355213521806, 0.0],
              [-80.927629984753693, 34.470263213210721, 0.0],
              [-80.927795918268473, 34.470331879704212, 0.0],
              [-80.927740785124286, 34.470561013602961, 0.0],
              [-80.926966518358938, 34.470904880057269, 0.0],
              [-80.926690184316541, 34.471271613013855, 0.0],
              [-80.926690517572084, 34.471661013510676, 0.0],
              [-80.926884317991963, 34.472141813435094, 0.0],
              [-80.927050785063173, 34.472989479656761, 0.0],
              [-80.928130718107369, 34.474936013623875, 0.0],
              [-80.928241718366948, 34.475531479829677, 0.0],
              [-80.928490917701069, 34.475783413448191, 0.0],
              [-80.929210185342896, 34.476149612861114, 0.0],
              [-80.929293318357978, 34.476286880118828, 0.0],
              [-80.929182585249578, 34.476470279994096, 0.0],
              [-80.928823184258775, 34.476653680234733, 0.0],
              [-80.927910385315144, 34.476654080082135, 0.0],
              [-80.927107918702816, 34.476081812793495, 0.0],
              [-80.926361118579237, 34.47617367938922, 0.0],
              [-80.925530785219181, 34.475303613169167, 0.0],
              [-80.925088117751429, 34.475074680190552, 0.0],
              [-80.924534918424726, 34.474983279803652, 0.0],
              [-80.924175184846121, 34.474685679848761, 0.0],
              [-80.923870585154887, 34.474113080026115, 0.0],
              [-80.92345551790423, 34.473861413069415, 0.0],
              [-80.922072184645941, 34.473266412809387, 0.0],
              [-80.921601785392525, 34.472968812756662, 0.0],
              [-80.921491117996297, 34.472785479532966, 0.0],
              [-80.92143538484747, 34.472144213347178, 0.0],
              [-80.921877584468604, 34.471250612950563, 0.0],
              [-80.921766785225984, 34.470861212859468, 0.0],
              [-80.921074784408205, 34.469762013528523, 0.0],
              [-80.920604184692721, 34.469304013491374, 0.0],
              [-80.920189317923757, 34.469075212778357, 0.0],
              [-80.92005098508028, 34.469075212708709, 0.0],
              [-80.919857384953204, 34.469235679483184, 0.0],
              [-80.919829985004441, 34.469510612855835, 0.0],
              [-80.919664117771092, 34.46983127948409, 0.0],
              [-80.919249518559852, 34.470175013208284, 0.0],
              [-80.918613384485553, 34.470221079907574, 0.0],
              [-80.918253785360065, 34.470083813111771, 0.0],
              [-80.917561984274641, 34.469488479806984, 0.0],
              [-80.917538918091921, 34.469423612993957, 0.0],
              [-80.9172849849394, 34.468709812685923, 0.0],
              [-80.917284784742392, 34.468160013185582, 0.0],
              [-80.917422918757438, 34.467793412763108, 0.0],
              [-80.917726984447455, 34.467495612783352, 0.0],
              [-80.918501184627686, 34.467128812679832, 0.0],
              [-80.918805384800123, 34.467082680170222, 0.0],
              [-80.919081918171784, 34.466945212863699, 0.0],
              [-80.919385984947482, 34.466601612759383, 0.0],
              [-80.919413117832889, 34.465708213001832, 0.0],
              [-80.919578718319528, 34.464998079719621, 0.0],
              [-80.919772184909689, 34.464585613386951, 0.0],
              [-80.919799584458886, 34.464035879856866, 0.0],
              [-80.91957791875106, 34.463348813467299, 0.0],
              [-80.91866431759253, 34.461356280211085, 0.0],
              [-80.91866391768032, 34.46071301270505, 0.0],
              [-80.918663917734762, 34.460691880230073, 0.0],
              [-80.919134118001267, 34.46073761287181, 0.0],
              [-80.919631918725855, 34.460943479765596, 0.0],
              [-80.920378984373713, 34.461630479732825, 0.0],
              [-80.92065578441499, 34.462019680212997, 0.0],
              [-80.921818384610376, 34.463966279913251, 0.0],
              [-80.921901518202645, 34.464286879971695, 0.0],
              [-80.921874717813182, 34.465959279365521, 0.0],
              [-80.922456185074537, 34.467402079955249, 0.0],
              [-80.923452718247262, 34.468684479502066, 0.0],
              [-80.923978185077814, 34.468867479891017, 0.0],
              [-80.924642118528084, 34.468890079821151, 0.0],
              [-80.924863117684538, 34.46863801356654, 0.0],
              [-80.924917984828255, 34.467882079474251, 0.0],
              [-80.925111518175541, 34.467561279557891, 0.0],
              [-80.926078985051674, 34.466644679487921, 0.0],
              [-80.927046184817385, 34.465201080105622, 0.0],
              [-80.928068984836202, 34.464513479371476, 0.0],
              [-80.92873231798626, 34.463803079592047, 0.0],
              [-80.929174318150075, 34.463115679413775, 0.0],
              [-80.929201917628504, 34.462703279725723, 0.0],
              [-80.928399384360603, 34.462108079494257, 0.0],
              [-80.928150384564375, 34.461764680015733, 0.0],
              [-80.927900984898102, 34.460940079981832, 0.0],
              [-80.92781758459833, 34.460298679537949, 0.0],
              [-80.927927918373811, 34.459886279522557, 0.0],
              [-80.928176717559296, 34.459634279558436, 0.0],
              [-80.929365384411284, 34.458877813452155, 0.0],
              [-80.929613918621726, 34.458419613024205, 0.0],
              [-80.929641384435428, 34.4581676133838, 0.0],
              [-80.929972985360976, 34.457755080171495, 0.0],
              [-80.930830185345826, 34.457686012729788, 0.0],
              [-80.931548984785394, 34.457227613044353, 0.0],
              [-80.931991184741662, 34.456769213418795, 0.0],
              [-80.932543718700074, 34.455875613184126, 0.0],
              [-80.932791718425662, 34.454730012894402, 0.0],
              [-80.933095585352561, 34.454294679647383, 0.0],
              [-80.933454985344099, 34.454065480185676, 0.0],
              [-80.933814518477277, 34.454111080237361, 0.0],
              [-80.933869917986726, 34.454294279415798, 0.0],
              [-80.934091118067144, 34.454294213390931, 0.0],
              [-80.934781984995098, 34.453721213100224, 0.0],
              [-80.935169117845717, 34.453629480079478, 0.0],
              [-80.935396918631909, 34.45372361343933, 0.0],
              [-80.935445784230168, 34.453743879712846, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 911,
        ComID: 9756820,
        FDate: "1999/10/12",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 44.292,
        Elevation: 173.0,
        ReachCode: "03050101002505",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 3.5606430378199998,
        Shape_Area: 0.00437912672,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.999796584582384, 35.216576478355712, 0.0],
              [-80.999796584179961, 35.215375278436291, 0.0],
              [-80.999796718468275, 35.21119421208482, 0.0],
              [-80.999796717906833, 35.210094878936111, 0.0],
              [-80.999796717858516, 35.209336012370542, 0.0],
              [-80.999796717848454, 35.208835212215185, 0.0],
              [-81.000282584238718, 35.208904278230889, 0.0],
              [-81.001510518498719, 35.208882678281782, 0.0],
              [-81.001733918482444, 35.208699678192218, 0.0],
              [-81.002543318118555, 35.20867761232099, 0.0],
              [-81.002739117851405, 35.208380011962923, 0.0],
              [-81.002767584836974, 35.207944878969499, 0.0],
              [-81.002935784111017, 35.207418278743859, 0.0],
              [-81.003438784174207, 35.207006478843077, 0.0],
              [-81.003634717938766, 35.206548612107767, 0.0],
              [-81.003636384994692, 35.205403479146348, 0.0],
              [-81.003385985264615, 35.204899278775322, 0.0],
              [-81.00324731744476, 35.204441211995203, 0.0],
              [-81.003303718422501, 35.203960212339709, 0.0],
              [-81.003137785275044, 35.202975278955712, 0.0],
              [-81.003054184495241, 35.202906478870233, 0.0],
              [-81.003082317913922, 35.202746078882086, 0.0],
              [-81.002803917460639, 35.202310678709352, 0.0],
              [-81.002079718629361, 35.201439611735573, 0.0],
              [-81.001802318259635, 35.200477278199841, 0.0],
              [-81.001777718167276, 35.19823281168663, 0.0],
              [-81.001666185232111, 35.198141079107437, 0.0],
              [-81.001415117666824, 35.198117878934163, 0.0],
              [-81.000968584331275, 35.198232011635262, 0.0],
              [-81.000829184229673, 35.198140212486308, 0.0],
              [-81.000801517617631, 35.19800281187014, 0.0],
              [-81.000969184861603, 35.197751012494955, 0.0],
              [-81.001667185237167, 35.197454012396847, 0.0],
              [-81.001918717733574, 35.197248011923804, 0.0],
              [-81.001974785269255, 35.197064878571773, 0.0],
              [-81.001807518300495, 35.196950212072295, 0.0],
              [-81.000942518162603, 35.1969722122126, 0.0],
              [-81.000050585145601, 35.196307211808815, 0.0],
              [-80.999796918301911, 35.196215278565994, 0.0],
              [-80.999796917563131, 35.195367878992535, 0.0],
              [-81.00002431780338, 35.195345278953305, 0.0],
              [-81.001055184185475, 35.196262412044312, 0.0],
              [-81.001445584825944, 35.196377278360806, 0.0],
              [-81.001808517923266, 35.196308878320522, 0.0],
              [-81.002366517727623, 35.196332411802402, 0.0],
              [-81.003035184512427, 35.19699721201534, 0.0],
              [-81.003341917520345, 35.197112078691973, 0.0],
              [-81.003816318153611, 35.197089678605991, 0.0],
              [-81.003928185156028, 35.196906479073469, 0.0],
              [-81.003985184179115, 35.196059211806023, 0.0],
              [-81.003846918363834, 35.19525741247255, 0.0],
              [-81.003652184687553, 35.19482201202144, 0.0],
              [-81.003178518190182, 35.194409279164951, 0.0],
              [-81.00334678483577, 35.193791079165102, 0.0],
              [-81.003320717955603, 35.192600012264684, 0.0],
              [-81.002652118542571, 35.19193521165402, 0.0],
              [-81.00259691841859, 35.191477012425189, 0.0],
              [-81.002179118623559, 35.191178878884848, 0.0],
              [-81.001928384858715, 35.190880811990269, 0.0],
              [-81.001845118422224, 35.190560079170872, 0.0],
              [-81.002237184601057, 35.189529878341247, 0.0],
              [-81.002266184193601, 35.188865678838603, 0.0],
              [-81.002016917921182, 35.1877202115841, 0.0],
              [-81.001348518015888, 35.186872211642097, 0.0],
              [-81.001321917841224, 35.18600181251599, 0.0],
              [-81.001016318179907, 35.1851770791259, 0.0],
              [-81.000487184721791, 35.184512278728661, 0.0],
              [-80.999797118559329, 35.184213811786151, 0.0],
              [-80.999797117731049, 35.179701812202758, 0.0],
              [-81.000159918504721, 35.179679278415108, 0.0],
              [-81.000467118540527, 35.179473412454648, 0.0],
              [-81.000607118265961, 35.17913001163393, 0.0],
              [-81.000635585260937, 35.178786478875203, 0.0],
              [-81.000468517978121, 35.178511478378937, 0.0],
              [-81.00010631765538, 35.178213411993504, 0.0],
              [-80.999797185220984, 35.178144411588775, 0.0],
              [-80.999797317644394, 35.172212278751971, 0.0],
              [-80.999797318431106, 35.171456478868812, 0.0],
              [-81.00020098465437, 35.171113278619359, 0.0],
              [-81.000536584762997, 35.170541078693098, 0.0],
              [-81.000397584208514, 35.170151612477518, 0.0],
              [-81.002268784829084, 35.168573211945215, 0.0],
              [-81.002632384542324, 35.16797787890382, 0.0],
              [-81.00282918424935, 35.166901612187047, 0.0],
              [-81.003024918385719, 35.166581211876114, 0.0],
              [-81.004309784557549, 35.165368611789454, 0.0],
              [-81.004366118055174, 35.164979212308005, 0.0],
              [-81.004170984636232, 35.164864478809243, 0.0],
              [-81.003641118131497, 35.164864012156933, 0.0],
              [-81.002580584617121, 35.165275278975024, 0.0],
              [-81.00152071755349, 35.165297078289903, 0.0],
              [-80.999797384594245, 35.165776278653304, 0.0],
              [-80.999797384219832, 35.165377678274275, 0.0],
              [-80.999797384715293, 35.16520127875053, 0.0],
              [-80.999797384578997, 35.164539478816572, 0.0],
              [-80.999797385018255, 35.163577612374581, 0.0],
              [-80.999797384452847, 35.162569812202335, 0.0],
              [-81.002777318400376, 35.164290611794264, 0.0],
              [-81.003139918175947, 35.164290879140445, 0.0],
              [-81.004395384684841, 35.163971478666483, 0.0],
              [-81.005483184494537, 35.16401841257607, 0.0],
              [-81.005734384782727, 35.163858278313803, 0.0],
              [-81.005902518590304, 35.163331678727126, 0.0],
              [-81.005624117729894, 35.163010811837694, 0.0],
              [-81.005597318277026, 35.162232012322491, 0.0],
              [-81.004985184598439, 35.161269412052405, 0.0],
              [-81.004901984955552, 35.160880012536381, 0.0],
              [-81.005294517476003, 35.159597812574901, 0.0],
              [-81.00529598479946, 35.15861287920621, 0.0],
              [-81.005156917703047, 35.158292078646234, 0.0],
              [-81.004822784953816, 35.157948212567682, 0.0],
              [-81.004376784269155, 35.157764479119898, 0.0],
              [-81.003456784331618, 35.157580411865524, 0.0],
              [-81.003317384381319, 35.15746581213471, 0.0],
              [-81.003290517880885, 35.156870279041527, 0.0],
              [-81.00343011816112, 35.156755811941302, 0.0],
              [-81.003988117483175, 35.156596078407517, 0.0],
              [-81.004601384865722, 35.156780012209559, 0.0],
              [-81.005019718325201, 35.156803278904704, 0.0],
              [-81.005215117761381, 35.156643079185201, 0.0],
              [-81.006081585110792, 35.155246878509296, 0.0],
              [-81.006919718441296, 35.154262678989525, 0.0],
              [-81.008874184201275, 35.152592612601524, 0.0],
              [-81.008958184504905, 35.152409412377764, 0.0],
              [-81.008846917872191, 35.152203212014854, 0.0],
              [-81.008122518399787, 35.151721612165929, 0.0],
              [-81.008039185083462, 35.151469478938466, 0.0],
              [-81.008122985121048, 35.151378011988371, 0.0],
              [-81.009098984655139, 35.151356012516082, 0.0],
              [-81.009294384585516, 35.151218812086974, 0.0],
              [-81.009322717818378, 35.15092101185023, 0.0],
              [-81.009044184613501, 35.150737478700428, 0.0],
              [-81.007872984439558, 35.150713478679911, 0.0],
              [-81.007427384299049, 35.150323812034834, 0.0],
              [-81.006618985074269, 35.15009401217727, 0.0],
              [-81.006618385013539, 35.15048341209674, 0.0],
              [-81.00695271822417, 35.15075841164267, 0.0],
              [-81.006952518005335, 35.150895811655623, 0.0],
              [-81.006840718100307, 35.151010278402524, 0.0],
              [-81.006171384457645, 35.151101412459411, 0.0],
              [-81.005919984266484, 35.151376012261643, 0.0],
              [-81.004887784203049, 35.151604011735387, 0.0],
              [-81.00444111852299, 35.151947079244117, 0.0],
              [-81.004077984791735, 35.152359012353848, 0.0],
              [-81.004048717809198, 35.153320878946275, 0.0],
              [-81.004159584664833, 35.15373327873138, 0.0],
              [-81.004075584438951, 35.153962278839415, 0.0],
              [-81.003489118615519, 35.154557212218272, 0.0],
              [-81.002763318548844, 35.155037478978059, 0.0],
              [-81.000504118415932, 35.155310078748549, 0.0],
              [-80.999797517637518, 35.155538478537082, 0.0],
              [-80.999797518001756, 35.154851278413986, 0.0],
              [-81.00031011768904, 35.154554078712472, 0.0],
              [-81.000840118172107, 35.154417212489577, 0.0],
              [-81.001453518324269, 35.154555212230214, 0.0],
              [-81.002680785277875, 35.154327412178588, 0.0],
              [-81.002932118111161, 35.154007012293846, 0.0],
              [-81.002932984723017, 35.153549011831558, 0.0],
              [-81.002765985029427, 35.153342611746112, 0.0],
              [-81.002739184337642, 35.152586679266591, 0.0],
              [-81.00251671833125, 35.152151412042315, 0.0],
              [-81.002517117820133, 35.151968212337202, 0.0],
              [-81.002740517920756, 35.151762211779804, 0.0],
              [-81.003884384562184, 35.15137387886687, 0.0],
              [-81.004247385012576, 35.151007879210404, 0.0],
              [-81.004834318576371, 35.150115078709113, 0.0],
              [-81.004834717741829, 35.149840278798301, 0.0],
              [-81.004695517665411, 35.149725678394866, 0.0],
              [-81.003552318095515, 35.149633011773503, 0.0],
              [-81.003468717616713, 35.149564212347435, 0.0],
              [-81.00344171781903, 35.149014478443725, 0.0],
              [-81.003525517465263, 35.148900012306726, 0.0],
              [-81.003442184391886, 35.148602211751246, 0.0],
              [-81.003275184356681, 35.148395878499798, 0.0],
              [-81.002745784552999, 35.148143411782812, 0.0],
              [-81.002132518488935, 35.148028278300096, 0.0],
              [-81.001993384123409, 35.147867879190606, 0.0],
              [-81.001966717961309, 35.147066079243146, 0.0],
              [-81.001855718482176, 35.146699612357942, 0.0],
              [-81.002107184551733, 35.14633341178039, 0.0],
              [-81.002107517927527, 35.146127278736785, 0.0],
              [-81.001689718483661, 35.145897812221513, 0.0],
              [-81.001382985014033, 35.14582881237078, 0.0],
              [-81.001159717654005, 35.145943078293215, 0.0],
              [-81.000741518361622, 35.145942678925053, 0.0],
              [-81.000435118104576, 35.14573627851162, 0.0],
              [-81.00043538528638, 35.14555307894927, 0.0],
              [-81.001077518473068, 35.14500387842606, 0.0],
              [-81.001133584868057, 35.144820811660225, 0.0],
              [-81.000994317949178, 35.144729012300182, 0.0],
              [-80.999797718367816, 35.144659212144106, 0.0],
              [-80.999797717450861, 35.143582678516914, 0.0],
              [-80.999797718291006, 35.141475612597041, 0.0],
              [-81.000413118313418, 35.141819812332479, 0.0],
              [-81.000942917761066, 35.141889011817803, 0.0],
              [-81.001221917883768, 35.141705878624578, 0.0],
              [-81.001501384342106, 35.141316878808894, 0.0],
              [-81.002310584679151, 35.140951212037535, 0.0],
              [-81.00253338439741, 35.141043079092952, 0.0],
              [-81.002784384355735, 35.141043278849871, 0.0],
              [-81.003147185131411, 35.140837611769363, 0.0],
              [-81.003593517870485, 35.140723479023698, 0.0],
              [-81.003676984454657, 35.140792278542477, 0.0],
              [-81.003676384122485, 35.141204478826062, 0.0],
              [-81.002308784184223, 35.142073478309662, 0.0],
              [-81.001722318269486, 35.142691278402175, 0.0],
              [-81.001665918601148, 35.143172211959289, 0.0],
              [-81.002027918122508, 35.143447411703342, 0.0],
              [-81.002585517738694, 35.143539611759195, 0.0],
              [-81.00411898439684, 35.143495278708407, 0.0],
              [-81.004341984966914, 35.143587079021472, 0.0],
              [-81.004341718428833, 35.143816078347783, 0.0],
              [-81.003309917456818, 35.143975478545407, 0.0],
              [-81.002751917777999, 35.144181079267128, 0.0],
              [-81.002528385140252, 35.144478611636337, 0.0],
              [-81.001969518541699, 35.14525667881481, 0.0],
              [-81.002080718116673, 35.145440212573646, 0.0],
              [-81.002665717432905, 35.145715478387842, 0.0],
              [-81.00314018518624, 35.145464078947143, 0.0],
              [-81.003474917528109, 35.145418678671049, 0.0],
              [-81.003697784360426, 35.145556279037393, 0.0],
              [-81.003836784492691, 35.145808411767518, 0.0],
              [-81.003891717713117, 35.146426678996605, 0.0],
              [-81.003778984370499, 35.147159612258832, 0.0],
              [-81.003945918537781, 35.147480279003567, 0.0],
              [-81.00433571849257, 35.147824278831926, 0.0],
              [-81.004893384567879, 35.14782487838589, 0.0],
              [-81.005897984489295, 35.147321878530569, 0.0],
              [-81.006456118187089, 35.147162211922286, 0.0],
              [-81.006567384618492, 35.147253878973082, 0.0],
              [-81.006567184959863, 35.147391279030117, 0.0],
              [-81.005561717821081, 35.148466879206417, 0.0],
              [-81.005561384512262, 35.148718811892579, 0.0],
              [-81.005756184471096, 35.148993812228341, 0.0],
              [-81.006202118342117, 35.149177479000748, 0.0],
              [-81.007847517674577, 35.148972878755757, 0.0],
              [-81.008349118550655, 35.149293878907557, 0.0],
              [-81.009073984824724, 35.149340479023337, 0.0],
              [-81.009268984283452, 35.14950101208003, 0.0],
              [-81.009296585149741, 35.149730011925804, 0.0],
              [-81.009463584738228, 35.149913411677829, 0.0],
              [-81.009742318111208, 35.150005279183482, 0.0],
              [-81.009992918280318, 35.150280411681685, 0.0],
              [-81.010578184273285, 35.150555679023292, 0.0],
              [-81.010660517480105, 35.151426078286562, 0.0],
              [-81.01105051853618, 35.151770012268869, 0.0],
              [-81.011663984645153, 35.151770611639719, 0.0],
              [-81.012138317480989, 35.151610812196523, 0.0],
              [-81.01250111840983, 35.15138207877289, 0.0],
              [-81.01280858493557, 35.150786812120032, 0.0],
              [-81.012670518194852, 35.14989347917448, 0.0],
              [-81.012364517962069, 35.149274679209995, 0.0],
              [-81.01236511787738, 35.148885411963398, 0.0],
              [-81.012699718619757, 35.148862812324552, 0.0],
              [-81.012894584672125, 35.149069078328488, 0.0],
              [-81.013200384851402, 35.149825212108603, 0.0],
              [-81.013785384185098, 35.150260812573876, 0.0],
              [-81.013924317815508, 35.150650278904187, 0.0],
              [-81.014397584840268, 35.151269078781958, 0.0],
              [-81.014396984248989, 35.151681411678794, 0.0],
              [-81.014173784524687, 35.151750012354981, 0.0],
              [-81.013393184221286, 35.151588879235753, 0.0],
              [-81.013114184507401, 35.151703278592286, 0.0],
              [-81.013085917554804, 35.152000878486795, 0.0],
              [-81.013559518405842, 35.152322012113345, 0.0],
              [-81.014144917687062, 35.152505678873581, 0.0],
              [-81.014367984866666, 35.15250587860185, 0.0],
              [-81.014507784083207, 35.152277078462156, 0.0],
              [-81.014814585283204, 35.152208611996322, 0.0],
              [-81.015287984701317, 35.152690012388241, 0.0],
              [-81.015678118578663, 35.152804879178021, 0.0],
              [-81.016040918115735, 35.152713611952755, 0.0],
              [-81.016375918401835, 35.152416078537087, 0.0],
              [-81.017437584947189, 35.150905279177636, 0.0],
              [-81.017382384736337, 35.150493012055733, 0.0],
              [-81.017494517452946, 35.150058012073515, 0.0],
              [-81.017383117633656, 35.149874611713877, 0.0],
              [-81.017383718336276, 35.149416478787906, 0.0],
              [-81.016576184836666, 35.148614212338444, 0.0],
              [-81.016297984852088, 35.148133079216137, 0.0],
              [-81.016242717622447, 35.147812278766153, 0.0],
              [-81.016354384380634, 35.147675011946632, 0.0],
              [-81.016772718233256, 35.147652411942353, 0.0],
              [-81.017719584121494, 35.148409078296851, 0.0],
              [-81.018360985236967, 35.148455411688765, 0.0],
              [-81.019253917466997, 35.14802107903062, 0.0],
              [-81.019644384910791, 35.147906812344139, 0.0],
              [-81.019867384483277, 35.147998612095925, 0.0],
              [-81.019978718000175, 35.148181879138612, 0.0],
              [-81.019866718602984, 35.148502479030391, 0.0],
              [-81.019364184692833, 35.148960212171332, 0.0],
              [-81.018666584848219, 35.149326078460142, 0.0],
              [-81.018666118093194, 35.149646679024819, 0.0],
              [-81.018805517892574, 35.149738411805899, 0.0],
              [-81.019307384703211, 35.149761811947329, 0.0],
              [-81.019558517481059, 35.149647478858711, 0.0],
              [-81.019977385031865, 35.149258412188935, 0.0],
              [-81.020200985219205, 35.148800478762318, 0.0],
              [-81.020675717456854, 35.148297012495, 0.0],
              [-81.020787584177384, 35.1480452122372, 0.0],
              [-81.020230317672272, 35.147632479084649, 0.0],
              [-81.020314717645363, 35.147059878370541, 0.0],
              [-81.0210129176008, 35.146304678632397, 0.0],
              [-81.021069717747267, 35.145388612601607, 0.0],
              [-81.021181717478939, 35.145045078879619, 0.0],
              [-81.021182317798832, 35.144632812116221, 0.0],
              [-81.02062491854943, 35.144334612081344, 0.0],
              [-81.019760585217028, 35.1442422124139, 0.0],
              [-81.019203518597593, 35.143898278626786, 0.0],
              [-81.018423118364652, 35.143622812487102, 0.0],
              [-81.017753984565061, 35.14350761200491, 0.0],
              [-81.017614784929009, 35.143416011956489, 0.0],
              [-81.017531385108043, 35.143209811767576, 0.0],
              [-81.017643117696267, 35.14302661247271, 0.0],
              [-81.018535784193347, 35.142729612453309, 0.0],
              [-81.018786984903613, 35.142546612374211, 0.0],
              [-81.019038585145225, 35.142134612398053, 0.0],
              [-81.019596184222223, 35.142134879132009, 0.0],
              [-81.019707584568422, 35.142249612488854, 0.0],
              [-81.0198737846288, 35.143097078919588, 0.0],
              [-81.021127984219817, 35.14337307873128, 0.0],
              [-81.021963984563854, 35.143831812559867, 0.0],
              [-81.022465918343954, 35.143855211706011, 0.0],
              [-81.024362184673024, 35.143696412580105, 0.0],
              [-81.025950518502626, 35.144476278749408, 0.0],
              [-81.026507117863105, 35.145461612577073, 0.0],
              [-81.026869117741427, 35.145828411958725, 0.0],
              [-81.026980517764102, 35.146034612047778, 0.0],
              [-81.027453784158482, 35.146584678534424, 0.0],
              [-81.027955184723169, 35.147043212324391, 0.0],
              [-81.028735917486856, 35.147089612404443, 0.0],
              [-81.028986718205346, 35.147227078843201, 0.0],
              [-81.029432584661464, 35.147410678567603, 0.0],
              [-81.03038078450723, 35.147296879216668, 0.0],
              [-81.030631918570379, 35.147136811686813, 0.0],
              [-81.031078718500297, 35.146587412257531, 0.0],
              [-81.031246118219158, 35.146587479051803, 0.0],
              [-81.031357518474067, 35.14667921227683, 0.0],
              [-81.031468518578322, 35.147160412331125, 0.0],
              [-81.031858584431191, 35.147481278846378, 0.0],
              [-81.033336517644614, 35.147459412039531, 0.0],
              [-81.033727117856614, 35.147276478415279, 0.0],
              [-81.033894918325274, 35.146795611933186, 0.0],
              [-81.034229917846488, 35.146452278785638, 0.0],
              [-81.034369584273293, 35.146154678896359, 0.0],
              [-81.034732184134171, 35.146109078897624, 0.0],
              [-81.035094385245685, 35.146338411884322, 0.0],
              [-81.035066317942963, 35.146544611833825, 0.0],
              [-81.034898717794917, 35.146773478906404, 0.0],
              [-81.034898185138161, 35.147185678541447, 0.0],
              [-81.035260584612146, 35.147300412421849, 0.0],
              [-81.040893518535256, 35.147212678681186, 0.0],
              [-81.041088718307677, 35.147098278712832, 0.0],
              [-81.041702917977787, 35.146365678296142, 0.0],
              [-81.041731118007135, 35.146113878633948, 0.0],
              [-81.039528717666954, 35.145768878959657, 0.0],
              [-81.038915517651034, 35.145447812160057, 0.0],
              [-81.038525384201037, 35.145126812267044, 0.0],
              [-81.038302717638004, 35.144806079143123, 0.0],
              [-81.038219317694498, 35.144554078667305, 0.0],
              [-81.038303184230372, 35.144370878600789, 0.0],
              [-81.038805184824199, 35.144325412346198, 0.0],
              [-81.040589184731488, 35.1448992786136, 0.0],
              [-81.04162091758775, 35.144739478387237, 0.0],
              [-81.041872118071453, 35.144602278872526, 0.0],
              [-81.041927984123561, 35.144442012089392, 0.0],
              [-81.042206918380472, 35.144396411790375, 0.0],
              [-81.042792318051056, 35.144511278703838, 0.0],
              [-81.043043517968854, 35.144305412339875, 0.0],
              [-81.043490184401278, 35.143710211870577, 0.0],
              [-81.043741385182059, 35.143504211715239, 0.0],
              [-81.045219584398353, 35.143161478635498, 0.0],
              [-81.045582318149286, 35.142955611683625, 0.0],
              [-81.046028984482433, 35.142314612168875, 0.0],
              [-81.046448118029218, 35.141398679219478, 0.0],
              [-81.046476384362578, 35.140894879266511, 0.0],
              [-81.046365185150691, 35.140642811988464, 0.0],
              [-81.045891318113348, 35.140459278920709, 0.0],
              [-81.045891718171092, 35.140138612057648, 0.0],
              [-81.046338318121926, 35.139635011963534, 0.0],
              [-81.047621118072243, 35.139246411671103, 0.0],
              [-81.048402318239525, 35.138743078524023, 0.0],
              [-81.049016384375705, 35.138056278729962, 0.0],
              [-81.049574784579335, 35.137163278571457, 0.0],
              [-81.049575317872524, 35.136682278316705, 0.0],
              [-81.04921291843236, 35.136498878717546, 0.0],
              [-81.047512584296896, 35.136268812403827, 0.0],
              [-81.047066717376282, 35.136039478453469, 0.0],
              [-81.045924318024049, 35.135168412616736, 0.0],
              [-81.045394584585807, 35.135122411879927, 0.0],
              [-81.044502184432716, 35.135350812421756, 0.0],
              [-81.043637784616223, 35.135464811970898, 0.0],
              [-81.04347058474066, 35.13535007878135, 0.0],
              [-81.043442984474055, 35.135075278853435, 0.0],
              [-81.043861984052668, 35.134319812055708, 0.0],
              [-81.044029784749569, 35.133793078885525, 0.0],
              [-81.043974518214952, 35.133312012486243, 0.0],
              [-81.04313871827911, 35.132738812154159, 0.0],
              [-81.043027384930639, 35.132578478382243, 0.0],
              [-81.04300011797794, 35.132005878873244, 0.0],
              [-81.042582118139308, 35.131799412563637, 0.0],
              [-81.041299717828778, 35.131661212234064, 0.0],
              [-81.040602918329256, 35.131477478354832, 0.0],
              [-81.0400735847915, 35.131133611900005, 0.0],
              [-81.039823118375708, 35.130767012155367, 0.0],
              [-81.039711984180769, 35.130308812316429, 0.0],
              [-81.039433984762709, 35.129690212240533, 0.0],
              [-81.039016318292084, 35.129140278700646, 0.0],
              [-81.038626317687985, 35.128888079135166, 0.0],
              [-81.038319584717271, 35.128864878670413, 0.0],
              [-81.038152117610466, 35.129162612097126, 0.0],
              [-81.037733317882228, 35.129643278659074, 0.0],
              [-81.036087717500479, 35.130398012385939, 0.0],
              [-81.035641518496519, 35.130443478981903, 0.0],
              [-81.035585918118628, 35.13037467870619, 0.0],
              [-81.035753717536664, 35.129871012452277, 0.0],
              [-81.0370093180385, 35.12886401226816, 0.0],
              [-81.037149117812149, 35.128497678953892, 0.0],
              [-81.036954718335352, 35.127856212446879, 0.0],
              [-81.036759717751082, 35.127718678829631, 0.0],
              [-81.035923517487802, 35.127557812108975, 0.0],
              [-81.035421584654202, 35.127671879178322, 0.0],
              [-81.034724317974295, 35.128015011867916, 0.0],
              [-81.034194517955584, 35.128152011819139, 0.0],
              [-81.034055518393643, 35.127785478601474, 0.0],
              [-81.033776918214784, 35.127579211993819, 0.0],
              [-81.033777117913573, 35.127441678535163, 0.0],
              [-81.033916584474042, 35.127258611739109, 0.0],
              [-81.035088184535979, 35.126618078999961, 0.0],
              [-81.035283784096819, 35.12627481208483, 0.0],
              [-81.035088918283165, 35.126045479248916, 0.0],
              [-81.034448117965198, 35.125701479079936, 0.0],
              [-81.034392718072937, 35.125335011657839, 0.0],
              [-81.034476584222148, 35.125151812621098, 0.0],
              [-81.034532717911802, 35.124762478406844, 0.0],
              [-81.03391991823699, 35.124349812293531, 0.0],
              [-81.03392071754628, 35.12359401252742, 0.0],
              [-81.03367051788058, 35.122998278759205, 0.0],
              [-81.033419784431061, 35.122837678855376, 0.0],
              [-81.033057384325005, 35.122814611958056, 0.0],
              [-81.032862184459617, 35.122906012297435, 0.0],
              [-81.032778184752416, 35.123158012101918, 0.0],
              [-81.032722118410604, 35.123501479235813, 0.0],
              [-81.032358985002062, 35.124096678499363, 0.0],
              [-81.032302984064799, 35.124394478513651, 0.0],
              [-81.03199551764655, 35.125150011855176, 0.0],
              [-81.031492118374871, 35.126569678649105, 0.0],
              [-81.030877318301179, 35.127760278777671, 0.0],
              [-81.030820517905454, 35.128722211717161, 0.0],
              [-81.030680917794655, 35.128836478446942, 0.0],
              [-81.028868118573342, 35.129545278377158, 0.0],
              [-81.028868518274223, 35.129224612249637, 0.0],
              [-81.029063917965743, 35.128972812170538, 0.0],
              [-81.029733518325315, 35.128561011900572, 0.0],
              [-81.029985318346604, 35.127805412035073, 0.0],
              [-81.030069718341807, 35.127118412272651, 0.0],
              [-81.03037731781528, 35.126339812517308, 0.0],
              [-81.030657517860689, 35.125149012146899, 0.0],
              [-81.030881584445481, 35.124072678353436, 0.0],
              [-81.030798118477776, 35.124004011839595, 0.0],
              [-81.03035218416629, 35.123866078653712, 0.0],
              [-81.028009918265425, 35.124459878780208, 0.0],
              [-81.026560117752055, 35.124642078671471, 0.0],
              [-81.026364984978883, 35.124573211954342, 0.0],
              [-81.026421385011147, 35.124092278851123, 0.0],
              [-81.026644518381687, 35.124000812266345, 0.0],
              [-81.027787518488196, 35.123910012515836, 0.0],
              [-81.028512918069922, 35.123360878463586, 0.0],
              [-81.029990917842539, 35.122926879173249, 0.0],
              [-81.030966784684125, 35.122835879133973, 0.0],
              [-81.031301385092092, 35.122744611971157, 0.0],
              [-81.031552384592231, 35.122561612411026, 0.0],
              [-81.031720317921256, 35.122103611945334, 0.0],
              [-81.031163185056798, 35.121691012482117, 0.0],
              [-81.031163518495887, 35.121416078855475, 0.0],
              [-81.031721918043843, 35.120660678999357, 0.0],
              [-81.031750118103716, 35.120362878932873, 0.0],
              [-81.031415984944147, 35.119996212060585, 0.0],
              [-81.030691517726922, 35.119812412418504, 0.0],
              [-81.030412917460211, 35.119629011784795, 0.0],
              [-81.030413117964727, 35.11942287898875, 0.0],
              [-81.031361117412601, 35.119194611915539, 0.0],
              [-81.031612184288136, 35.119034479239879, 0.0],
              [-81.031751917732734, 35.118805478876318, 0.0],
              [-81.031780718006061, 35.118003878708002, 0.0],
              [-81.031474384379962, 35.117591412241673, 0.0],
              [-81.0311401175029, 35.117499478727368, 0.0],
              [-81.030498317476955, 35.118140278470811, 0.0],
              [-81.029912384883616, 35.11852921199025, 0.0],
              [-81.029382584328147, 35.118689212284529, 0.0],
              [-81.029243317710737, 35.118643278887639, 0.0],
              [-81.029243984950156, 35.117956212085609, 0.0],
              [-81.029383718423773, 35.117681412575742, 0.0],
              [-81.029829918472871, 35.117567211684978, 0.0],
              [-81.03002518506041, 35.117407078439356, 0.0],
              [-81.030081318017878, 35.117109279033599, 0.0],
              [-81.030527917812663, 35.116605812384982, 0.0],
              [-81.030528117596873, 35.116422478852755, 0.0],
              [-81.029580584534486, 35.116146879173151, 0.0],
              [-81.02958091847843, 35.115940812172305, 0.0],
              [-81.029915584386657, 35.115757812071465, 0.0],
              [-81.030138917605967, 35.115529012161474, 0.0],
              [-81.030139384395966, 35.115093811728634, 0.0],
              [-81.029554518037799, 35.114704011990838, 0.0],
              [-81.029499384613132, 35.114200012567608, 0.0],
              [-81.029165117867933, 35.114039611671522, 0.0],
              [-81.028830717438012, 35.114039279146866, 0.0],
              [-81.028467984093169, 35.11431387889855, 0.0],
              [-81.02821671746112, 35.114657212447653, 0.0],
              [-81.028020918032013, 35.115275478754128, 0.0],
              [-81.027825318374909, 35.11557301231678, 0.0],
              [-81.027490718439509, 35.11577901201116, 0.0],
              [-81.026403384956652, 35.115892611957484, 0.0],
              [-81.025818318371037, 35.115640212354485, 0.0],
              [-81.024647584138748, 35.115639279236966, 0.0],
              [-81.024424784390874, 35.115524612646333, 0.0],
              [-81.024425118149594, 35.115272678834174, 0.0],
              [-81.02462058450233, 35.115043812633708, 0.0],
              [-81.02484371741032, 35.114952278833925, 0.0],
              [-81.025679918193632, 35.114861412038174, 0.0],
              [-81.026348718489629, 35.115022279074878, 0.0],
              [-81.026655318491521, 35.11502247906008, 0.0],
              [-81.026878384366924, 35.114862279169436, 0.0],
              [-81.026962317919399, 35.114656278836101, 0.0],
              [-81.026850984987973, 35.114541612274166, 0.0],
              [-81.026851318526894, 35.114266678656129, 0.0],
              [-81.027157984583781, 35.11415247893833, 0.0],
              [-81.027520918240711, 35.113740478930431, 0.0],
              [-81.027521717715146, 35.113099211686574, 0.0],
              [-81.028135317573941, 35.112687411994933, 0.0],
              [-81.028191518248278, 35.112389678334004, 0.0],
              [-81.027606384150587, 35.112114412438956, 0.0],
              [-81.027606717821016, 35.111908279312473, 0.0],
              [-81.027829918143723, 35.111725211694235, 0.0],
              [-81.028443384308247, 35.111519478806692, 0.0],
              [-81.028582784936859, 35.111405212384859, 0.0],
              [-81.028638917751778, 35.111153278570747, 0.0],
              [-81.028109717836571, 35.110832079055783, 0.0],
              [-81.028109918580569, 35.110626012248815, 0.0],
              [-81.028305185059779, 35.110465878464893, 0.0],
              [-81.02852878517227, 35.109985011957257, 0.0],
              [-81.028863584259312, 35.109687611694163, 0.0],
              [-81.028808117932968, 35.109481412118505, 0.0],
              [-81.028640985211666, 35.109343878543328, 0.0],
              [-81.027860918490049, 35.109114212336586, 0.0],
              [-81.027665984097837, 35.108907878762992, 0.0],
              [-81.027555317764509, 35.108266479047387, 0.0],
              [-81.027081918207131, 35.10799127836318, 0.0],
              [-81.026301918079639, 35.10769301176537, 0.0],
              [-81.024880718037224, 35.107462812290592, 0.0],
              [-81.024016985232294, 35.107164278972796, 0.0],
              [-81.023710118284185, 35.107370212633796, 0.0],
              [-81.023625718109813, 35.108057279155439, 0.0],
              [-81.0235139849826, 35.108217612496162, 0.0],
              [-81.022900584077732, 35.108446078411504, 0.0],
              [-81.022872517806093, 35.108652212067234, 0.0],
              [-81.022314917719996, 35.10881201246881, 0.0],
              [-81.022203184654558, 35.10892647846152, 0.0],
              [-81.021757117924324, 35.109109278544018, 0.0],
              [-81.021478117484094, 35.109338078929447, 0.0],
              [-81.021282185108603, 35.109887678841332, 0.0],
              [-81.0208355846394, 35.110482879001268, 0.0],
              [-81.020389518239867, 35.110528278984006, 0.0],
              [-81.020278117949516, 35.110436612242729, 0.0],
              [-81.020306384969032, 35.11011587850696, 0.0],
              [-81.020669517689399, 35.109520812571745, 0.0],
              [-81.020586185249911, 35.109337478348834, 0.0],
              [-81.019248918314858, 35.108809478596385, 0.0],
              [-81.018719517879234, 35.108786078675365, 0.0],
              [-81.017910917952392, 35.108991679004717, 0.0],
              [-81.017520717444484, 35.108991278676029, 0.0],
              [-81.017521184176928, 35.108693611992287, 0.0],
              [-81.017855784156154, 35.108510612641375, 0.0],
              [-81.01844131854061, 35.108396612073534, 0.0],
              [-81.018720185058484, 35.108213478815863, 0.0],
              [-81.019361384450335, 35.108122478497705, 0.0],
              [-81.020113317746677, 35.108489612469171, 0.0],
              [-81.021032918089929, 35.108650678845187, 0.0],
              [-81.021702118198903, 35.108445012543051, 0.0],
              [-81.022232184556898, 35.108079011701925, 0.0],
              [-81.022288118488291, 35.107918812541747, 0.0],
              [-81.021563785276882, 35.107666212480922, 0.0],
              [-81.021368985180388, 35.107437079311893, 0.0],
              [-81.020811984966045, 35.107116011847722, 0.0],
              [-81.020784318609927, 35.106909812509826, 0.0],
              [-81.020895984997296, 35.106818278479743, 0.0],
              [-81.021230517688934, 35.106749811922292, 0.0],
              [-81.02265171848552, 35.106957078990739, 0.0],
              [-81.022735384900614, 35.106888478882333, 0.0],
              [-81.022707918317082, 35.106590678606189, 0.0],
              [-81.021343318420946, 35.105696278939888, 0.0],
              [-81.020981718285341, 35.10516927840311, 0.0],
              [-81.020675384389847, 35.104894212131043, 0.0],
              [-81.019811917511362, 35.104664411937456, 0.0],
              [-81.019812185032706, 35.104343811794841, 0.0],
              [-81.019923918267807, 35.1042292788214, 0.0],
              [-81.019924184759546, 35.103954478620921, 0.0],
              [-81.019673984456972, 35.103496211742005, 0.0],
              [-81.019423384743291, 35.103266878752315, 0.0],
              [-81.018197184102462, 35.103220079037449, 0.0],
              [-81.017863118601056, 35.10294487912649, 0.0],
              [-81.017891384810511, 35.102647278649762, 0.0],
              [-81.01819811857429, 35.102555878455391, 0.0],
              [-81.018532318495289, 35.102693478994524, 0.0],
              [-81.019591518467109, 35.102625678543184, 0.0],
              [-81.019758585129892, 35.102694612276927, 0.0],
              [-81.020148118102526, 35.103290412515321, 0.0],
              [-81.021150784289148, 35.103657678631301, 0.0],
              [-81.021512584430525, 35.104047278387384, 0.0],
              [-81.022069718239962, 35.104276812301819, 0.0],
              [-81.022236318311954, 35.104780878808036, 0.0],
              [-81.022458984401169, 35.104987212398257, 0.0],
              [-81.022849118141522, 35.105079079098154, 0.0],
              [-81.023461517803611, 35.105629278625351, 0.0],
              [-81.024157918277311, 35.105973412231499, 0.0],
              [-81.024603718330766, 35.106019612153325, 0.0],
              [-81.02479898425139, 35.105882412496697, 0.0],
              [-81.024715917720343, 35.105424212435281, 0.0],
              [-81.025022585051929, 35.105332811891756, 0.0],
              [-81.025524184702206, 35.105424878376368, 0.0],
              [-81.025663784729275, 35.105241678906069, 0.0],
              [-81.026137784696402, 35.104990212312799, 0.0],
              [-81.026555985013431, 35.104921679144702, 0.0],
              [-81.027196918195727, 35.105013812518102, 0.0],
              [-81.027754317495805, 35.105014278529254, 0.0],
              [-81.027865917899319, 35.10489987843161, 0.0],
              [-81.027838518009148, 35.104579212356157, 0.0],
              [-81.02725371796538, 35.104143612153592, 0.0],
              [-81.026222784610539, 35.103845011855157, 0.0],
              [-81.026279384198972, 35.103112012103665, 0.0],
              [-81.026502518568662, 35.103043479114021, 0.0],
              [-81.027226384146303, 35.103662478994082, 0.0],
              [-81.027922784848229, 35.103915012619332, 0.0],
              [-81.028229384356038, 35.103938212533592, 0.0],
              [-81.028536318392298, 35.103663612104704, 0.0],
              [-81.029121717613364, 35.103526478557576, 0.0],
              [-81.029233184408653, 35.103412078367704, 0.0],
              [-81.029121984699415, 35.103228812302824, 0.0],
              [-81.028425585065477, 35.103022211935134, 0.0],
              [-81.028426184682061, 35.102495412639421, 0.0],
              [-81.02806418451749, 35.102197411839853, 0.0],
              [-81.028009185092031, 35.101624678367237, 0.0],
              [-81.027647384459542, 35.101212079188407, 0.0],
              [-81.027647718424319, 35.101006011898377, 0.0],
              [-81.028038317450992, 35.100525279302914, 0.0],
              [-81.028178385197236, 35.099907011693716, 0.0],
              [-81.0278169851412, 35.099196678376821, 0.0],
              [-81.027678184284554, 35.09873847912435, 0.0],
              [-81.027789784131727, 35.098601212403445, 0.0],
              [-81.028040718345068, 35.098532679258184, 0.0],
              [-81.028347117505206, 35.098693212023058, 0.0],
              [-81.028708184633928, 35.09965541223891, 0.0],
              [-81.028735117958377, 35.100571612179778, 0.0],
              [-81.028818318536779, 35.100800679254704, 0.0],
              [-81.029124584808898, 35.101121612254829, 0.0],
              [-81.029068118587816, 35.10176287841216, 0.0],
              [-81.029541517696032, 35.101992278876821, 0.0],
              [-81.029903117573298, 35.102565212628448, 0.0],
              [-81.03048831826753, 35.102657212068735, 0.0],
              [-81.030655918112316, 35.102359611921287, 0.0],
              [-81.031213318473675, 35.102360012308388, 0.0],
              [-81.031380318064478, 35.102474611914957, 0.0],
              [-81.031324184195995, 35.102863878640591, 0.0],
              [-81.030570917852984, 35.103527612203699, 0.0],
              [-81.030570518331047, 35.103894078535987, 0.0],
              [-81.030904718470907, 35.104100478558614, 0.0],
              [-81.031350584597973, 35.104169478389778, 0.0],
              [-81.031740784283187, 35.104078211845476, 0.0],
              [-81.032270717550404, 35.103780812606793, 0.0],
              [-81.032521784216513, 35.103483278676478, 0.0],
              [-81.032467184269663, 35.10252127890066, 0.0],
              [-81.032718384607961, 35.102177878954294, 0.0],
              [-81.032718984104605, 35.101651079119769, 0.0],
              [-81.033666784690212, 35.101331078563916, 0.0],
              [-81.03377818451402, 35.1014456785513, 0.0],
              [-81.033805384851277, 35.101972479125394, 0.0],
              [-81.033944517954268, 35.102201612496167, 0.0],
              [-81.034334584674568, 35.102247678685366, 0.0],
              [-81.035199384181936, 35.101538279099913, 0.0],
              [-81.035395117793357, 35.100988812428113, 0.0],
              [-81.03609191799606, 35.100943411982698, 0.0],
              [-81.03623138414747, 35.100874811767035, 0.0],
              [-81.036343117847224, 35.100645812119645, 0.0],
              [-81.035758318059834, 35.100210279235107, 0.0],
              [-81.035758517426629, 35.099981212119864, 0.0],
              [-81.036093318119725, 35.099614878931668, 0.0],
              [-81.03651251848882, 35.098630412516776, 0.0],
              [-81.036791117400952, 35.098653478384499, 0.0],
              [-81.036901784689789, 35.099294879262175, 0.0],
              [-81.037041117746369, 35.099409478854497, 0.0],
              [-81.03765418425732, 35.099501611827741, 0.0],
              [-81.038350185149255, 35.100074678367385, 0.0],
              [-81.039185918558829, 35.100418812584394, 0.0],
              [-81.039492117609853, 35.100716678932528, 0.0],
              [-81.039854384349283, 35.100716878454492, 0.0],
              [-81.040189317412384, 35.100304879312425, 0.0],
              [-81.040662984830433, 35.100236478711324, 0.0],
              [-81.041165118129655, 35.099916079063121, 0.0],
              [-81.041388384567881, 35.099572812603384, 0.0],
              [-81.041388718289653, 35.099297878603231, 0.0],
              [-81.040553184650605, 35.098701878980371, 0.0],
              [-81.040553384421997, 35.098518678859435, 0.0],
              [-81.040804318452743, 35.09847301219606, 0.0],
              [-81.041946317746152, 35.098931812433825, 0.0],
              [-81.042308517676645, 35.098954879041194, 0.0],
              [-81.042670984804602, 35.098840678612881, 0.0],
              [-81.042866317560168, 35.098634612213942, 0.0],
              [-81.042782917879336, 35.098405611771341, 0.0],
              [-81.041947117416569, 35.098084412561214, 0.0],
              [-81.041891584932344, 35.097878212491516, 0.0],
              [-81.043173717969921, 35.09776447886788, 0.0],
              [-81.043591317921368, 35.098154078598782, 0.0],
              [-81.044009318139473, 35.098268812669566, 0.0],
              [-81.044287984820386, 35.098177478992845, 0.0],
              [-81.044594918090908, 35.097857012406351, 0.0],
              [-81.044622984192031, 35.097605012038912, 0.0],
              [-81.044985984105637, 35.096895279085679, 0.0],
              [-81.04498658495811, 35.09625401245404, 0.0],
              [-81.044819718273544, 35.095956212516668, 0.0],
              [-81.044318317447534, 35.095749679323106, 0.0],
              [-81.043203718544191, 35.095565678718721, 0.0],
              [-81.043064518131132, 35.095474012628529, 0.0],
              [-81.043092517399046, 35.095290811760769, 0.0],
              [-81.043482918518166, 35.094993212382661, 0.0],
              [-81.04351111758487, 35.094718478614652, 0.0],
              [-81.043400118138479, 35.094260212218209, 0.0],
              [-81.04373451779928, 35.094214679147441, 0.0],
              [-81.044792717534719, 35.094994079341383, 0.0],
              [-81.045322118028508, 35.095063212240397, 0.0],
              [-81.045656784372497, 35.094903011864957, 0.0],
              [-81.04582418412042, 35.094674079134947, 0.0],
              [-81.04582471788602, 35.09407861261694, 0.0],
              [-81.045574185087233, 35.093757678946766, 0.0],
              [-81.04476658490124, 35.0932304784751, 0.0],
              [-81.044739117660171, 35.092887012461119, 0.0],
              [-81.044822784509279, 35.092703811882387, 0.0],
              [-81.044739517840668, 35.09252047840026, 0.0],
              [-81.044182518007162, 35.092107812219375, 0.0],
              [-81.044182784414261, 35.091833012169637, 0.0],
              [-81.044266385150479, 35.091741412580454, 0.0],
              [-81.044600984828961, 35.091650012096544, 0.0],
              [-81.045241584074049, 35.091902279009823, 0.0],
              [-81.046411917841326, 35.092086278786027, 0.0],
              [-81.046801984621055, 35.092017812280609, 0.0],
              [-81.047387384779014, 35.091789212519743, 0.0],
              [-81.048307718554355, 35.090919279067613, 0.0],
              [-81.048781584461324, 35.090736412465425, 0.0],
              [-81.049395117977895, 35.090278679125234, 0.0],
              [-81.049395184837763, 35.090118412457102, 0.0],
              [-81.048670984579203, 35.089865878674075, 0.0],
              [-81.047389117602535, 35.089911012459424, 0.0],
              [-81.046915785130025, 35.089521279183131, 0.0],
              [-81.045244585220189, 35.088924812482091, 0.0],
              [-81.044910184442813, 35.088901678380026, 0.0],
              [-81.044575584476149, 35.0890160787648, 0.0],
              [-81.044157384463617, 35.089336412696092, 0.0],
              [-81.043654584987792, 35.090550012666348, 0.0],
              [-81.043347784219947, 35.090801812435863, 0.0],
              [-81.042957585097525, 35.09098467890292, 0.0],
              [-81.042706784982883, 35.090984478912645, 0.0],
              [-81.042623318559507, 35.090870012574783, 0.0],
              [-81.042735184260906, 35.090480678475799, 0.0],
              [-81.043516717695397, 35.089107012018999, 0.0],
              [-81.043544784236303, 35.088877879203487, 0.0],
              [-81.043433518223921, 35.088763412160944, 0.0],
              [-81.04337838495195, 35.088167811895353, 0.0],
              [-81.042960918453531, 35.087663612252065, 0.0],
              [-81.042487385243277, 35.087411412299026, 0.0],
              [-81.042097317529468, 35.087411079008604, 0.0],
              [-81.041651118021903, 35.087777279362243, 0.0],
              [-81.041622984870656, 35.088029278390984, 0.0],
              [-81.041817917899905, 35.088212612636809, 0.0],
              [-81.041872584053564, 35.089243412385805, 0.0],
              [-81.041733117567134, 35.089380678535683, 0.0],
              [-81.041538117704818, 35.089426412543453, 0.0],
              [-81.041315784203576, 35.088739079274504, 0.0],
              [-81.04092598502487, 35.08848687871987, 0.0],
              [-81.040368584306819, 35.088509412042193, 0.0],
              [-81.040257118409585, 35.088623812445725, 0.0],
              [-81.04022878416707, 35.089036078709761, 0.0],
              [-81.039866384968164, 35.089173279143438, 0.0],
              [-81.039643317831874, 35.089379278715711, 0.0],
              [-81.03939238417334, 35.089447879102217, 0.0],
              [-81.039281117625748, 35.089287411748117, 0.0],
              [-81.039393184274957, 35.088760812062056, 0.0],
              [-81.038557517485657, 35.088485279342002, 0.0],
              [-81.038363317671823, 35.087752278683624, 0.0],
              [-81.037666984965085, 35.087339479026703, 0.0],
              [-81.037304784520643, 35.087339212631967, 0.0],
              [-81.037081784397571, 35.087453611882694, 0.0],
              [-81.036997917843649, 35.087751279293876, 0.0],
              [-81.036886185215906, 35.08784287863029, 0.0],
              [-81.03641258522552, 35.087888411814987, 0.0],
              [-81.035939184273516, 35.087636012576759, 0.0],
              [-81.035576984088095, 35.087681612209515, 0.0],
              [-81.03554878497188, 35.08784187900843, 0.0],
              [-81.035325784798133, 35.087933279134511, 0.0],
              [-81.035130984969001, 35.087795812528988, 0.0],
              [-81.035131118544015, 35.08768127923593, 0.0],
              [-81.03485291811603, 35.087268812111816, 0.0],
              [-81.034490718276075, 35.087222812094112, 0.0],
              [-81.03396118430642, 35.087314078607086, 0.0],
              [-81.033821917881866, 35.087199412428475, 0.0],
              [-81.033934384385191, 35.086329079260743, 0.0],
              [-81.033795185187969, 35.086237412678052, 0.0],
              [-81.033209984923204, 35.086191212414604, 0.0],
              [-81.03284778407307, 35.086259612272649, 0.0],
              [-81.032708717845708, 35.086167879064675, 0.0],
              [-81.032625318055437, 35.085892878389835, 0.0],
              [-81.032291184624995, 35.08568667912094, 0.0],
              [-81.032012917531887, 35.085388678629172, 0.0],
              [-81.032083718444071, 35.084850278621062, 0.0],
              [-81.032097184455353, 35.084747412144011, 0.0],
              [-81.032459384234116, 35.084839212409172, 0.0],
              [-81.032626317670804, 35.085045478513635, 0.0],
              [-81.032737385026806, 35.085412078576034, 0.0],
              [-81.032932318115755, 35.085503878735295, 0.0],
              [-81.033182984417294, 35.08550407928206, 0.0],
              [-81.03343411751402, 35.085183611987993, 0.0],
              [-81.033434584400325, 35.084794211819748, 0.0],
              [-81.03290578512788, 35.084267012194204, 0.0],
              [-81.032600185075964, 35.0834422126389, 0.0],
              [-81.03260078517782, 35.08298407935763, 0.0],
              [-81.032907385059872, 35.082892678846513, 0.0],
              [-81.033185785207465, 35.083007479222964, 0.0],
              [-81.034187718351106, 35.084016012650032, 0.0],
              [-81.034661317717394, 35.084153811955318, 0.0],
              [-81.034715985006926, 35.085069878496917, 0.0],
              [-81.034966517671705, 35.085367812370755, 0.0],
              [-81.034993984102201, 35.085642678580015, 0.0],
              [-81.034854518126053, 35.085871679220681, 0.0],
              [-81.034853984310246, 35.086261012497701, 0.0],
              [-81.035299384801462, 35.086650678501961, 0.0],
              [-81.035912317604243, 35.086811478914157, 0.0],
              [-81.036413918052745, 35.0866974125694, 0.0],
              [-81.036832184224465, 35.086377011762139, 0.0],
              [-81.037417384898376, 35.086285678437783, 0.0],
              [-81.038086517908511, 35.085942679356123, 0.0],
              [-81.038337118530634, 35.086080211786474, 0.0],
              [-81.039033718530192, 35.086126478838572, 0.0],
              [-81.03928431771746, 35.086309878665766, 0.0],
              [-81.039339717766538, 35.086653478936597, 0.0],
              [-81.039701117840266, 35.087340812356494, 0.0],
              [-81.040007317895899, 35.087615878570809, 0.0],
              [-81.040285984482537, 35.087616078937423, 0.0],
              [-81.040843718333647, 35.087158412359365, 0.0],
              [-81.041485984087728, 35.085784612607029, 0.0],
              [-81.041514984524881, 35.084731078634135, 0.0],
              [-81.041292384238943, 35.084341478983468, 0.0],
              [-81.040624384126744, 35.083745612512587, 0.0],
              [-81.040429584147006, 35.083470479314904, 0.0],
              [-81.040429917406556, 35.08328727859093, 0.0],
              [-81.040569184949362, 35.08319581239445, 0.0],
              [-81.041515718249912, 35.084021012227012, 0.0],
              [-81.042518784615282, 35.08397581227554, 0.0],
              [-81.042546184774295, 35.084433812323468, 0.0],
              [-81.042462518381043, 35.084594212072332, 0.0],
              [-81.042461984127556, 35.084983612177403, 0.0],
              [-81.0426569183401, 35.085121078640007, 0.0],
              [-81.043158384527516, 35.085213012414556, 0.0],
              [-81.043157984881532, 35.085671079096414, 0.0],
              [-81.043241385044837, 35.085877278945674, 0.0],
              [-81.043464185050738, 35.08603781222341, 0.0],
              [-81.044466784729892, 35.086336078848518, 0.0],
              [-81.044689584848683, 35.086496611890837, 0.0],
              [-81.045023384705772, 35.087092278855614, 0.0],
              [-81.045273984437941, 35.08725281175662, 0.0],
              [-81.046778518245972, 35.08748281192382, 0.0],
              [-81.047670118404966, 35.087483278925774, 0.0],
              [-81.047976184884149, 35.087895679224268, 0.0],
              [-81.048449784517658, 35.087964812535063, 0.0],
              [-81.04878431749728, 35.087781812434116, 0.0],
              [-81.048895984377353, 35.087621478826989, 0.0],
              [-81.048896318342088, 35.087255012438355, 0.0],
              [-81.048701517564027, 35.08704881262387, 0.0],
              [-81.048701717401443, 35.086728078752181, 0.0],
              [-81.049565717996799, 35.086568279343041, 0.0],
              [-81.050345384914678, 35.087187078424762, 0.0],
              [-81.050651718184199, 35.087324678491804, 0.0],
              [-81.050874718479477, 35.087256212075687, 0.0],
              [-81.051153784309079, 35.086683812215966, 0.0],
              [-81.051822717392099, 35.086455012426043, 0.0],
              [-81.051934318062038, 35.086340612519727, 0.0],
              [-81.052046318208937, 35.085722212392831, 0.0],
              [-81.052047117914256, 35.084760278869446, 0.0],
              [-81.051935918332319, 35.084485412518873, 0.0],
              [-81.051183784205932, 35.084347479137818, 0.0],
              [-81.050710384591014, 35.084049479004797, 0.0],
              [-81.050487584393153, 35.084003611747825, 0.0],
              [-81.04967931780017, 35.084300812700796, 0.0],
              [-81.04912191797078, 35.084369078724919, 0.0],
              [-81.049066517825452, 35.084002678724296, 0.0],
              [-81.049651984640704, 35.083613678519413, 0.0],
              [-81.049707917548105, 35.083430479001777, 0.0],
              [-81.050627384458934, 35.083408212358869, 0.0],
              [-81.0509339179318, 35.083316679072276, 0.0],
              [-81.050962118212041, 35.083087611786155, 0.0],
              [-81.05085078457364, 35.082858612247179, 0.0],
              [-81.050349717727002, 35.082446012431149, 0.0],
              [-81.049096118247959, 35.082101678523003, 0.0],
              [-81.049096317378243, 35.081872612245881, 0.0],
              [-81.049263584437426, 35.081781079049961, 0.0],
              [-81.050127384287862, 35.081712878740277, 0.0],
              [-81.050461584894578, 35.081804812399689, 0.0],
              [-81.051296984595936, 35.082515212569788, 0.0],
              [-81.05185391768552, 35.082790412383488, 0.0],
              [-81.052438918143835, 35.082836479103449, 0.0],
              [-81.052661918435973, 35.082745079326983, 0.0],
              [-81.052829517807254, 35.082241278840378, 0.0],
              [-81.053303317541918, 35.08210401223095, 0.0],
              [-81.053554318424617, 35.081943879069286, 0.0],
              [-81.053638185093646, 35.081462878863491, 0.0],
              [-81.053972784755388, 35.081188278458306, 0.0],
              [-81.053973318257405, 35.080707278396154, 0.0],
              [-81.054670184346293, 35.080272478784458, 0.0],
              [-81.054865717535037, 35.079768679277286, 0.0],
              [-81.054782384756948, 35.079425078621504, 0.0],
              [-81.054559517715347, 35.079310411989283, 0.0],
              [-81.053918784061807, 35.079172678885563, 0.0],
              [-81.053472984833618, 35.079241079347796, 0.0],
              [-81.052748184797267, 35.079653078772921, 0.0],
              [-81.052525318497985, 35.079652878793624, 0.0],
              [-81.052163517966818, 35.079171678602492, 0.0],
              [-81.051717784701751, 35.079148611789698, 0.0],
              [-81.051048985168748, 35.079354278829136, 0.0],
              [-81.050826118311392, 35.079354211918698, 0.0],
              [-81.050464117658549, 35.079147812440524, 0.0],
              [-81.0501019182405, 35.079193479139541, 0.0],
              [-81.049516385167564, 35.079536679113723, 0.0],
              [-81.049042584077142, 35.079650878925591, 0.0],
              [-81.048875518190911, 35.079582078640541, 0.0],
              [-81.048792184196003, 35.079398812047529, 0.0],
              [-81.04890378511864, 35.079169812222382, 0.0],
              [-81.049294117465777, 35.078895212592485, 0.0],
              [-81.049879317786932, 35.078712412320094, 0.0],
              [-81.049907584938694, 35.078391679166863, 0.0],
              [-81.04921171761228, 35.077566678556956, 0.0],
              [-81.049212184812944, 35.077177278685639, 0.0],
              [-81.049462918467384, 35.077131679019494, 0.0],
              [-81.049685717414633, 35.077246412267968, 0.0],
              [-81.05007531807837, 35.077819212124638, 0.0],
              [-81.050381584505161, 35.077956812567045, 0.0],
              [-81.050910984529608, 35.077842612201181, 0.0],
              [-81.051245184914322, 35.078003079258991, 0.0],
              [-81.051941917556803, 35.077980479197578, 0.0],
              [-81.052164718343562, 35.077843279122554, 0.0],
              [-81.052192784229987, 35.077683011888261, 0.0],
              [-81.052025984968239, 35.077316412166319, 0.0],
              [-81.053000984089309, 35.077294078474935, 0.0],
              [-81.053335317458092, 35.077385812247378, 0.0],
              [-81.053474384787407, 35.077683679010505, 0.0],
              [-81.054059318141881, 35.077913079231379, 0.0],
              [-81.054700117602337, 35.077844678842034, 0.0],
              [-81.055591784921845, 35.07750161181449, 0.0],
              [-81.05589858433656, 35.077158211776023, 0.0],
              [-81.055898984384044, 35.076745878692513, 0.0],
              [-81.055731984468991, 35.076493879092347, 0.0],
              [-81.055342185022923, 35.076333278455827, 0.0],
              [-81.054255717771738, 35.076309879114795, 0.0],
              [-81.053168784209788, 35.076584011860533, 0.0],
              [-81.052862385086939, 35.076538078780104, 0.0],
              [-81.052695385060957, 35.076377678916813, 0.0],
              [-81.052361118060261, 35.076285812055943, 0.0],
              [-81.052054918480493, 35.076056678862209, 0.0],
              [-81.05205511766674, 35.075965078619966, 0.0],
              [-81.05188811814449, 35.075713078838334, 0.0],
              [-81.05133098471623, 35.075666879251685, 0.0],
              [-81.051163917660759, 35.075483611828894, 0.0],
              [-81.050216918482135, 35.075208211939376, 0.0],
              [-81.049325384639815, 35.075139012017516, 0.0],
              [-81.049046918282102, 35.075047212300454, 0.0],
              [-81.048294784212089, 35.075023812607554, 0.0],
              [-81.04812771857425, 35.074886279285671, 0.0],
              [-81.04812798438013, 35.074611412353732, 0.0],
              [-81.048574184839921, 35.074176479305706, 0.0],
              [-81.048824984822787, 35.074108012047652, 0.0],
              [-81.049465384171512, 35.074383211820802, 0.0],
              [-81.049771984072066, 35.074245879320102, 0.0],
              [-81.050551985212124, 35.074338012124628, 0.0],
              [-81.052139584359566, 35.074842811962171, 0.0],
              [-81.052752117650869, 35.075186679360478, 0.0],
              [-81.053030718163711, 35.0752098121133, 0.0],
              [-81.053197985212819, 35.075072478595359, 0.0],
              [-81.053254118289559, 35.074706011744567, 0.0],
              [-81.052892784062109, 35.073698012190356, 0.0],
              [-81.052447318338992, 35.073354211807967, 0.0],
              [-81.052336184885064, 35.073125078891707, 0.0],
              [-81.052419917455708, 35.072987679201937, 0.0],
              [-81.052754117780694, 35.072965012226945, 0.0],
              [-81.053116117772831, 35.073194279182609, 0.0],
              [-81.053394384474998, 35.073560878429582, 0.0],
              [-81.053616717875244, 35.074225278630315, 0.0],
              [-81.053895118158664, 35.074408678718662, 0.0],
              [-81.05408978437903, 35.074798079097384, 0.0],
              [-81.054312585105563, 35.074912678484466, 0.0],
              [-81.054841917865531, 35.074958812423183, 0.0],
              [-81.055900718534758, 35.074684612248589, 0.0],
              [-81.055984384317313, 35.074570078831712, 0.0],
              [-81.056068518171912, 35.07392881247219, 0.0],
              [-81.056041518148106, 35.072898079189429, 0.0],
              [-81.055707517407839, 35.072668811892186, 0.0],
              [-81.054871717514473, 35.072668412511533, 0.0],
              [-81.054537584349205, 35.072485011846027, 0.0],
              [-81.053395584121574, 35.072095012326933, 0.0],
              [-81.053284317664819, 35.071957612640553, 0.0],
              [-81.053395917503238, 35.071728612051906, 0.0],
              [-81.055262517913874, 35.071729478648379, 0.0],
              [-81.055541184938861, 35.071615211806034, 0.0],
              [-81.055680784933799, 35.071340412371981, 0.0],
              [-81.055597384351742, 35.071042612672017, 0.0],
              [-81.055430318199186, 35.070928012258207, 0.0],
              [-81.053953917746412, 35.070835479164941, 0.0],
              [-81.052421985150346, 35.07049121188502, 0.0],
              [-81.051140517887333, 35.07053627840731, 0.0],
              [-81.050973384081587, 35.070444478568987, 0.0],
              [-81.050973517463547, 35.070284212330556, 0.0],
              [-81.051447384138854, 35.070101212298866, 0.0],
              [-81.053620384112591, 35.069942011936014, 0.0],
              [-81.054260917439677, 35.070171479112084, 0.0],
              [-81.054567517446657, 35.070102879054261, 0.0],
              [-81.054706784816872, 35.069919812616661, 0.0],
              [-81.055237184833572, 35.068729078682047, 0.0],
              [-81.055042384231911, 35.068614479224479, 0.0],
              [-81.054429518366561, 35.068614078727073, 0.0],
              [-81.054123385184411, 35.06808707908629, 0.0],
              [-81.053204185153888, 35.067926411742512, 0.0],
              [-81.052953784736857, 35.067811678939727, 0.0],
              [-81.052954184887213, 35.067376479014285, 0.0],
              [-81.05348338431881, 35.067308012643899, 0.0],
              [-81.054402317900326, 35.067697879038484, 0.0],
              [-81.05526591769852, 35.067790012413404, 0.0],
              [-81.055572517366571, 35.067675612107429, 0.0],
              [-81.055879518008197, 35.066919878997005, 0.0],
              [-81.056269918303258, 35.066416279070985, 0.0],
              [-81.056604918068373, 35.065454478797236, 0.0],
              [-81.056493984583511, 35.064744412216896, 0.0],
              [-81.056299317765848, 35.064652679288258, 0.0],
              [-81.055825718059694, 35.064652411748369, 0.0],
              [-81.054516118528383, 35.065064078920919, 0.0],
              [-81.053846784934464, 35.065750812116022, 0.0],
              [-81.052871584181389, 35.066139678574224, 0.0],
              [-81.052760317682001, 35.066048011824172, 0.0],
              [-81.052816384701927, 35.065612812605522, 0.0],
              [-81.053457317828332, 35.065269478784835, 0.0],
              [-81.053791784504753, 35.064994878543317, 0.0],
              [-81.054070984654885, 35.064353679028123, 0.0],
              [-81.055073984675161, 35.06421681205908, 0.0],
              [-81.055519784550128, 35.063988012606835, 0.0],
              [-81.055993718204903, 35.063507278552805, 0.0],
              [-81.056021918408874, 35.063255412588504, 0.0],
              [-81.054518384251722, 35.062407212422677, 0.0],
              [-81.053849984666655, 35.062177678805092, 0.0],
              [-81.053432317805019, 35.062131679247805, 0.0],
              [-81.052847184106625, 35.062222879376243, 0.0],
              [-81.052484784616993, 35.062543412172531, 0.0],
              [-81.051815318205556, 35.063596678474056, 0.0],
              [-81.051479984120931, 35.06467301209851, 0.0],
              [-81.05125711835997, 35.064764412354137, 0.0],
              [-81.050923118365745, 35.064443612420327, 0.0],
              [-81.051174984471388, 35.063092411763215, 0.0],
              [-81.050868718383128, 35.063046411978803, 0.0],
              [-81.050199718368162, 35.063504078477749, 0.0],
              [-81.05017138498323, 35.063985078504501, 0.0],
              [-81.04986498472158, 35.064099412520882, 0.0],
              [-81.04905651745824, 35.064763079339031, 0.0],
              [-81.04855491779837, 35.06494607931166, 0.0],
              [-81.048443517443403, 35.064877411820973, 0.0],
              [-81.048443918256581, 35.064556612576887, 0.0],
              [-81.049085317817074, 35.063824212526164, 0.0],
              [-81.049308518319236, 35.063412012123962, 0.0],
              [-81.049308785064923, 35.063045478746595, 0.0],
              [-81.049030318012896, 35.06304541269369, 0.0],
              [-81.048333584948239, 35.063319812694658, 0.0],
              [-81.048138584634302, 35.063319679245332, 0.0],
              [-81.047915917534411, 35.063136279256447, 0.0],
              [-81.047358718314484, 35.063204678429123, 0.0],
              [-81.047247317793023, 35.063136011855626, 0.0],
              [-81.047247584083323, 35.062884012258522, 0.0],
              [-81.047888518342347, 35.062563679179512, 0.0],
              [-81.047916584971915, 35.062403412710076, 0.0],
              [-81.047805317885192, 35.062288812714421, 0.0],
              [-81.04724831746077, 35.062174012327183, 0.0],
              [-81.047220584676452, 35.061922011894026, 0.0],
              [-81.048724918449352, 35.061854078863441, 0.0],
              [-81.049226517943822, 35.061648279216342, 0.0],
              [-81.049672317767957, 35.061602812679354, 0.0],
              [-81.050006784482065, 35.061396879052019, 0.0],
              [-81.050480384868962, 35.061259611783726, 0.0],
              [-81.051065717870799, 35.060779012061666, 0.0],
              [-81.051093784934508, 35.060527012510718, 0.0],
              [-81.050954784394079, 35.060252211923427, 0.0],
              [-81.050426117802644, 35.059816679154054, 0.0],
              [-81.049757584319366, 35.059678878542243, 0.0],
              [-81.049283984729385, 35.059770212402334, 0.0],
              [-81.049200517583515, 35.059724411820433, 0.0],
              [-81.049368117919954, 35.05924347910554, 0.0],
              [-81.049368518161145, 35.058670878709371, 0.0],
              [-81.049117984250614, 35.058487478470617, 0.0],
              [-81.048728118319673, 35.058441412425026, 0.0],
              [-81.048310185060046, 35.058532812242383, 0.0],
              [-81.04819891809737, 35.058441079314598, 0.0],
              [-81.048226984140214, 35.05812047852104, 0.0],
              [-81.048087984677451, 35.057868412179218, 0.0],
              [-81.048088185042133, 35.057593611966396, 0.0],
              [-81.048366917396933, 35.057456278930729, 0.0],
              [-81.048784785183329, 35.057387878837545, 0.0],
              [-81.049007918549577, 35.057136078905813, 0.0],
              [-81.048757918301717, 35.056425878685751, 0.0],
              [-81.048173185000365, 35.056082012727856, 0.0],
              [-81.04783911846404, 35.056127479337164, 0.0],
              [-81.047448717580124, 35.056448012538446, 0.0],
              [-81.047141584423755, 35.057157878836833, 0.0],
              [-81.047058117602333, 35.05720361258826, 0.0],
              [-81.046946384848269, 35.057570012293517, 0.0],
              [-81.046832717917681, 35.059952012274046, 0.0],
              [-81.046720984355119, 35.060158012394034, 0.0],
              [-81.046163917603266, 35.060157678847595, 0.0],
              [-81.046052717964386, 35.059951479256213, 0.0],
              [-81.045858384386378, 35.059310078626069, 0.0],
              [-81.045803384120006, 35.058508411851207, 0.0],
              [-81.045691984953962, 35.058393812238847, 0.0],
              [-81.045079318230179, 35.058370611992714, 0.0],
              [-81.044800784491954, 35.058278812401916, 0.0],
              [-81.044466584988456, 35.058347278522469, 0.0],
              [-81.043769784894465, 35.058759078793123, 0.0],
              [-81.043657784760882, 35.059446078529405, 0.0],
              [-81.043546184147999, 35.059537611811869, 0.0],
              [-81.042961118550679, 35.059674812104248, 0.0],
              [-81.042765984207179, 35.059789078646787, 0.0],
              [-81.042765317604264, 35.060476212272562, 0.0],
              [-81.042486517696801, 35.06068221201199, 0.0],
              [-81.042513718404493, 35.06136941183432, 0.0],
              [-81.04231858418828, 35.061529478920832, 0.0],
              [-81.04184511826594, 35.06146047868868, 0.0],
              [-81.041622118208522, 35.061574879073973, 0.0],
              [-81.041649585103499, 35.061964279219239, 0.0],
              [-81.042094584196946, 35.062674611831618, 0.0],
              [-81.042790184700564, 35.063431012627298, 0.0],
              [-81.042789917493252, 35.063751611890041, 0.0],
              [-81.04237198502922, 35.063820012367771, 0.0],
              [-81.041286517846117, 35.06297201257177, 0.0],
              [-81.040896584623539, 35.062834211847282, 0.0],
              [-81.040450985111434, 35.062833878546435, 0.0],
              [-81.03994931858044, 35.063085479207714, 0.0],
              [-81.039419584399425, 35.063497479108413, 0.0],
              [-81.038888584084617, 35.065100412259902, 0.0],
              [-81.038693384783258, 35.065329279347807, 0.0],
              [-81.038497917944071, 35.065810212268325, 0.0],
              [-81.038247118518385, 35.065878679137434, 0.0],
              [-81.038163585006558, 35.065787012544369, 0.0],
              [-81.03810891741125, 35.064825012601624, 0.0],
              [-81.038472117723714, 35.063909078686819, 0.0],
              [-81.038472518265309, 35.063473878939291, 0.0],
              [-81.037915584814982, 35.06331321242358, 0.0],
              [-81.03774851818801, 35.063198478607198, 0.0],
              [-81.037637384121652, 35.062946479065729, 0.0],
              [-81.036857584766395, 35.062762812724891, 0.0],
              [-81.036858318138641, 35.062236012725599, 0.0],
              [-81.036496918230512, 35.06150281260205, 0.0],
              [-81.035968117852576, 35.061044412606314, 0.0],
              [-81.034937784986326, 35.060768812510567, 0.0],
              [-81.034464717927406, 35.060379212095995, 0.0],
              [-81.034520717507604, 35.060104278553702, 0.0],
              [-81.035300784282242, 35.059990278575398, 0.0],
              [-81.035607517975805, 35.059669879392708, 0.0],
              [-81.036081117410319, 35.059601478876523, 0.0],
              [-81.036637984549714, 35.059853812458435, 0.0],
              [-81.037138917568399, 35.06026641269294, 0.0],
              [-81.03747271793614, 35.060701812140586, 0.0],
              [-81.038000317422132, 35.062145079316693, 0.0],
              [-81.038250784913203, 35.062351478611106, 0.0],
              [-81.038835584967529, 35.062512212642552, 0.0],
              [-81.039169984202914, 35.062443679201813, 0.0],
              [-81.039505118345403, 35.061665078912007, 0.0],
              [-81.040146784445696, 35.060634879098799, 0.0],
              [-81.040063517435257, 35.060314212421709, 0.0],
              [-81.040676584486576, 35.060131411799532, 0.0],
              [-81.040983584747138, 35.059559012094219, 0.0],
              [-81.041652917520494, 35.058666079255119, 0.0],
              [-81.041820518181837, 35.058276878854656, 0.0],
              [-81.04187671819578, 35.057727212601641, 0.0],
              [-81.041681984964455, 35.057475079364451, 0.0],
              [-81.041208584517449, 35.057383212221986, 0.0],
              [-81.040568317744345, 35.057107878834678, 0.0],
              [-81.040039118068321, 35.057061812341061, 0.0],
              [-81.039845117451776, 35.056191278756337, 0.0],
              [-81.039566718454026, 35.055962078670198, 0.0],
              [-81.038787184324661, 35.055618012343643, 0.0],
              [-81.038787384252885, 35.055388878789039, 0.0],
              [-81.039428184055623, 35.055251878615664, 0.0],
              [-81.040068518509017, 35.055618812494771, 0.0],
              [-81.04026318430239, 35.055893879164749, 0.0],
              [-81.040374184757866, 35.056260412252136, 0.0],
              [-81.040707984770094, 35.056650012438894, 0.0],
              [-81.041654984277088, 35.056627679030953, 0.0],
              [-81.041850117634738, 35.056513278966143, 0.0],
              [-81.041878317883032, 35.05619261259357, 0.0],
              [-81.041766917865274, 35.056078012452851, 0.0],
              [-81.041739518159787, 35.055780278891177, 0.0],
              [-81.042101584760204, 35.055757611941885, 0.0],
              [-81.042936717881631, 35.056170412463082, 0.0],
              [-81.043605117733534, 35.05630821201747, 0.0],
              [-81.045471184777966, 35.056286411759068, 0.0],
              [-81.045972984264225, 35.055966079120871, 0.0],
              [-81.046084585001253, 35.05573707893469, 0.0],
              [-81.046112784857357, 35.055279011895266, 0.0],
              [-81.046029517888499, 35.055072812469241, 0.0],
              [-81.045556384292482, 35.054660278876717, 0.0],
              [-81.045027584735379, 35.054385078430606, 0.0],
              [-81.044777118403232, 35.054178878647242, 0.0],
              [-81.044777184728403, 35.054018612489763, 0.0],
              [-81.045195117408099, 35.053904211823316, 0.0],
              [-81.046252984196101, 35.054340078581077, 0.0],
              [-81.046364517708454, 35.054294279037428, 0.0],
              [-81.046392584340154, 35.054019612296329, 0.0],
              [-81.046003584531007, 35.053080211939118, 0.0],
              [-81.045669784219896, 35.052576078548384, 0.0],
              [-81.045252318460214, 35.052346811792539, 0.0],
              [-81.044722917589354, 35.052552679073692, 0.0],
              [-81.044527984677714, 35.052552478890441, 0.0],
              [-81.044249717599939, 35.05230041270373, 0.0],
              [-81.044249917810134, 35.052094278688244, 0.0],
              [-81.044751584754422, 35.051705212722702, 0.0],
              [-81.044863317927707, 35.051499078941085, 0.0],
              [-81.044863517880017, 35.05122421262265, 0.0],
              [-81.044696717742511, 35.050949279126094, 0.0],
              [-81.044390384729041, 35.050811811932384, 0.0],
              [-81.044028184915149, 35.050971878891119, 0.0],
              [-81.043860517587134, 35.051590211772478, 0.0],
              [-81.043470517683076, 35.051658611971867, 0.0],
              [-81.043275785021549, 35.05133781221366, 0.0],
              [-81.043025118279004, 35.051314678916405, 0.0],
              [-81.042774384447611, 35.051429012674589, 0.0],
              [-81.042412184281432, 35.051474679191195, 0.0],
              [-81.042273784596688, 35.050741678949983, 0.0],
              [-81.042802985108352, 35.050604612180791, 0.0],
              [-81.042998317720532, 35.050352678903359, 0.0],
              [-81.043499784215271, 35.050192678888806, 0.0],
              [-81.043750718277835, 35.050009611828941, 0.0],
              [-81.04377878439341, 35.04971201192653, 0.0],
              [-81.043667384178335, 35.04962021215308, 0.0],
              [-81.043082718409082, 35.04950527917336, 0.0],
              [-81.042915918024065, 35.049161679173281, 0.0],
              [-81.041969184796272, 35.048977878993924, 0.0],
              [-81.041439784929423, 35.049160678987512, 0.0],
              [-81.040742984171501, 35.049664078917864, 0.0],
              [-81.040101184829709, 35.050877679042351, 0.0],
              [-81.039293317475483, 35.051197812546064, 0.0],
              [-81.039098384512442, 35.051106012715152, 0.0],
              [-81.039098717803924, 35.050808278807651, 0.0],
              [-81.039516785051589, 35.050487879358791, 0.0],
              [-81.039739784905265, 35.050190411867675, 0.0],
              [-81.039795984961088, 35.049778079413429, 0.0],
              [-81.039685117647849, 35.049388612688468, 0.0],
              [-81.039267518235121, 35.049159278637383, 0.0],
              [-81.039156518587291, 35.048724079397687, 0.0],
              [-81.038516118327522, 35.048632012069838, 0.0],
              [-81.038404784877372, 35.048540278464941, 0.0],
              [-81.038627984700909, 35.048151078484878, 0.0],
              [-81.038739984545686, 35.047624278923507, 0.0],
              [-81.038322784391141, 35.047005612751029, 0.0],
              [-81.038016584377345, 35.046868012053849, 0.0],
              [-81.037097784617529, 35.046707079413935, 0.0],
              [-81.036679585066537, 35.047073279329439, 0.0],
              [-81.036316717400382, 35.047874612044431, 0.0],
              [-81.03631551747057, 35.048928211951811, 0.0],
              [-81.036175985222002, 35.049225879366261, 0.0],
              [-81.035591117836802, 35.049271279191601, 0.0],
              [-81.035507384919796, 35.049385812703797, 0.0],
              [-81.035228784699626, 35.049477212020705, 0.0],
              [-81.035006385206316, 35.049133479317739, 0.0],
              [-81.034811517998264, 35.048995879123417, 0.0],
              [-81.034811785142907, 35.048744012044615, 0.0],
              [-81.035258184309129, 35.048125878506092, 0.0],
              [-81.035342185124264, 35.047576212314162, 0.0],
              [-81.035649184973266, 35.047095479326416, 0.0],
              [-81.03567751759752, 35.046614479264171, 0.0],
              [-81.035900784699493, 35.046156612256908, 0.0],
              [-81.035622584644685, 35.045950212613327, 0.0],
              [-81.035622918145521, 35.045675412516132, 0.0],
              [-81.035790118350803, 35.045469412551526, 0.0],
              [-81.036319384794155, 35.045423878697164, 0.0],
              [-81.036458918009387, 35.045172079361627, 0.0],
              [-81.036347918417562, 35.04475967922464, 0.0],
              [-81.036125384845093, 35.044507611822993, 0.0],
              [-81.035512917996527, 35.044369812073455, 0.0],
              [-81.035234917449941, 35.04384281236559, 0.0],
              [-81.035012118003763, 35.043819679400166, 0.0],
              [-81.034566317423483, 35.04409421181208, 0.0],
              [-81.034176317966569, 35.044162679211034, 0.0],
              [-81.034092984684463, 35.043956479053065, 0.0],
              [-81.033786918050922, 35.043750079215187, 0.0],
              [-81.033926784932163, 35.043154678576798, 0.0],
              [-81.033815584682785, 35.043017212276993, 0.0],
              [-81.032813317861013, 35.042764611867604, 0.0],
              [-81.032284184808205, 35.042741278796825, 0.0],
              [-81.031615584782401, 35.04296981206906, 0.0],
              [-81.030862917797975, 35.043656411829389, 0.0],
              [-81.030723584429779, 35.043679279269668, 0.0],
              [-81.030500584868292, 35.043885212228439, 0.0],
              [-81.030361184732712, 35.044045412347522, 0.0],
              [-81.029608584709109, 35.044571678842352, 0.0],
              [-81.029580318554792, 35.044961012034186, 0.0],
              [-81.02966371825471, 35.045029678762546, 0.0],
              [-81.029691184390487, 35.045327611828895, 0.0],
              [-81.029161718067513, 35.045693678853283, 0.0],
              [-81.029161184304471, 35.046106012203502, 0.0],
              [-81.029466984764483, 35.046587212385184, 0.0],
              [-81.029465717759194, 35.047709478943261, 0.0],
              [-81.029715384852096, 35.04848841270168, 0.0],
              [-81.029742784674937, 35.048969411904416, 0.0],
              [-81.029519918513174, 35.049015078759588, 0.0],
              [-81.029241584960189, 35.048831612377072, 0.0],
              [-81.028935784288592, 35.048396211939647, 0.0],
              [-81.028824784487384, 35.048029611832256, 0.0],
              [-81.02877011813672, 35.047159212477744, 0.0],
              [-81.028519917815174, 35.046700879225703, 0.0],
              [-81.028185984815281, 35.046563278887056, 0.0],
              [-81.027935184881017, 35.046700479097311, 0.0],
              [-81.02760031834741, 35.047250012015247, 0.0],
              [-81.026931717442949, 35.047455612338027, 0.0],
              [-81.026792184842932, 35.047592878551548, 0.0],
              [-81.026791918523116, 35.047867812135898, 0.0],
              [-81.027069984420365, 35.048234478888425, 0.0],
              [-81.027403918408481, 35.04846381223981, 0.0],
              [-81.02740351809895, 35.048784479311465, 0.0],
              [-81.026707184509036, 35.048829678477844, 0.0],
              [-81.02640091787552, 35.048760679064749, 0.0],
              [-81.02609451847664, 35.048829212243547, 0.0],
              [-81.025508984363384, 35.049309812255416, 0.0],
              [-81.024645318453821, 35.049583878933134, 0.0],
              [-81.024394384175181, 35.049789812632504, 0.0],
              [-81.02439398451142, 35.050064679172387, 0.0],
              [-81.024783185247131, 35.050660478789368, 0.0],
              [-81.024838517475089, 35.050981279387685, 0.0],
              [-81.024142184407197, 35.051049411885558, 0.0],
              [-81.023696385113965, 35.051186612404081, 0.0],
              [-81.023278185124582, 35.051529811905226, 0.0],
              [-81.023249717775712, 35.052033612242013, 0.0],
              [-81.023610785165829, 35.052858478820326, 0.0],
              [-81.023777785170878, 35.052996012486361, 0.0],
              [-81.023805184258194, 35.053339479286556, 0.0],
              [-81.02349878489845, 35.053339278508396, 0.0],
              [-81.023137318266663, 35.052812212659688, 0.0],
              [-81.022525384656987, 35.052101678603215, 0.0],
              [-81.022330584871028, 35.052032812043429, 0.0],
              [-81.022079784377652, 35.052124212164692, 0.0],
              [-81.022023317626193, 35.052788411872413, 0.0],
              [-81.021577318363939, 35.053062879081935, 0.0],
              [-81.021521184969458, 35.05331487869811, 0.0],
              [-81.021688118383352, 35.053475412154953, 0.0],
              [-81.021687784316313, 35.053727278533707, 0.0],
              [-81.021381384415207, 35.053727012170228, 0.0],
              [-81.020936118213356, 35.053451812624715, 0.0],
              [-81.020769117760665, 35.053451612605151, 0.0],
              [-81.020657384895259, 35.053680612222124, 0.0],
              [-81.020128118178363, 35.053726012183553, 0.0],
              [-81.019877518043074, 35.053657012715199, 0.0],
              [-81.019292584456977, 35.053679479268823, 0.0],
              [-81.019317917909774, 35.053591211865687, 0.0],
              [-81.01940451806945, 35.053290278542953, 0.0],
              [-81.020073385248892, 35.052970011892711, 0.0],
              [-81.02051958488569, 35.052558079195997, 0.0],
              [-81.021244118447242, 35.052169479226038, 0.0],
              [-81.021495318612509, 35.05175727912335, 0.0],
              [-81.021523584303296, 35.05148261220527, 0.0],
              [-81.021690784986234, 35.051276478739737, 0.0],
              [-81.022498917779629, 35.051002278761423, 0.0],
              [-81.022777784227372, 35.050750612711767, 0.0],
              [-81.023001318607527, 35.050132412507203, 0.0],
              [-81.022946918332138, 35.049147411882302, 0.0],
              [-81.023058517449755, 35.048987212128473, 0.0],
              [-81.024646518326406, 35.04862187934355, 0.0],
              [-81.025036584464928, 35.048370278510426, 0.0],
              [-81.02517631822856, 35.04809561215307, 0.0],
              [-81.02517658428485, 35.047752011796554, 0.0],
              [-81.024982118208726, 35.047477012144391, 0.0],
              [-81.02503818488519, 35.04720221215036, 0.0],
              [-81.025845784407835, 35.04711121273418, 0.0],
              [-81.026068784340922, 35.047019679005473, 0.0],
              [-81.026347984485113, 35.046447412256619, 0.0],
              [-81.026320985007828, 35.04569147862118, 0.0],
              [-81.026572117676622, 35.045348079037119, 0.0],
              [-81.026767784970204, 35.044706879022577, 0.0],
              [-81.026684717568159, 35.044317479190362, 0.0],
              [-81.026517918455482, 35.044134212065387, 0.0],
              [-81.025599184874139, 35.043904412235996, 0.0],
              [-81.024875384724112, 35.043583212085387, 0.0],
              [-81.024875784190655, 35.043354212744411, 0.0],
              [-81.025071318555803, 35.042919211874214, 0.0],
              [-81.025433184782486, 35.04294227926168, 0.0],
              [-81.025822717995666, 35.043332011953204, 0.0],
              [-81.026267785200545, 35.043630078849553, 0.0],
              [-81.02676891802993, 35.043722079138071, 0.0],
              [-81.027019384987625, 35.043882612564033, 0.0],
              [-81.027687717873945, 35.043974679339534, 0.0],
              [-81.027854917799729, 35.043883212163109, 0.0],
              [-81.028078118264119, 35.043585612070615, 0.0],
              [-81.028357584943763, 35.042738412142498, 0.0],
              [-81.028190985068633, 35.042303079246317, 0.0],
              [-81.02788518479781, 35.041913478667475, 0.0],
              [-81.027857717715335, 35.041615678453212, 0.0],
              [-81.028219584722748, 35.041661812232647, 0.0],
              [-81.028915117828205, 35.042280812575434, 0.0],
              [-81.029499718373259, 35.042418612683456, 0.0],
              [-81.029722918307598, 35.04202941258071, 0.0],
              [-81.029751117464784, 35.041708679232286, 0.0],
              [-81.029528917457299, 35.041204678680259, 0.0],
              [-81.029501584419492, 35.040700679125749, 0.0],
              [-81.029808118346665, 35.040632278494151, 0.0],
              [-81.029863717456436, 35.040701079075504, 0.0],
              [-81.030085384219745, 35.04164027929113, 0.0],
              [-81.030447384984072, 35.041663412207711, 0.0],
              [-81.030642518068305, 35.041503278722004, 0.0],
              [-81.031227717894168, 35.041251812432186, 0.0],
              [-81.031450718005502, 35.04099987918508, 0.0],
              [-81.031507518061545, 35.040106679132386, 0.0],
              [-81.031897584394656, 35.03994667932372, 0.0],
              [-81.032509718376886, 35.040313611943724, 0.0],
              [-81.032760384556212, 35.04033667866522, 0.0],
              [-81.033150317706372, 35.040199612756567, 0.0],
              [-81.033651584958179, 35.040222812333319, 0.0],
              [-81.034402785150931, 35.040864612182993, 0.0],
              [-81.034736384712588, 35.041460412116599, 0.0],
              [-81.035348184302208, 35.042102078592336, 0.0],
              [-81.035626584214498, 35.042171011942138, 0.0],
              [-81.036267184748866, 35.042102812593583, 0.0],
              [-81.036573184556275, 35.042446479291186, 0.0],
              [-81.037241584694812, 35.04249281199705, 0.0],
              [-81.037909517847766, 35.042813878459732, 0.0],
              [-81.03804831750476, 35.04329501180213, 0.0],
              [-81.038326517604588, 35.043547078991814, 0.0],
              [-81.038632717406927, 35.043684811972859, 0.0],
              [-81.038966918283052, 35.043707878921879, 0.0],
              [-81.039161984172196, 35.043524812676822, 0.0],
              [-81.039190184946946, 35.043204212709931, 0.0],
              [-81.038911784839243, 35.043020678819907, 0.0],
              [-81.03874518453938, 35.04260827852189, 0.0],
              [-81.038383584497112, 35.04228741242386, 0.0],
              [-81.03824498480671, 35.041600212548737, 0.0],
              [-81.037688585093306, 35.041187478832818, 0.0],
              [-81.036519117560118, 35.040934879381375, 0.0],
              [-81.036352184869671, 35.040843078634516, 0.0],
              [-81.036463918338029, 35.040522479063398, 0.0],
              [-81.036937517668889, 35.040385411991387, 0.0],
              [-81.037104784179078, 35.040179412684076, 0.0],
              [-81.037160784113851, 35.03985881269417, 0.0],
              [-81.037077317529366, 35.039790012092581, 0.0],
              [-81.036994384640195, 35.03921741242192, 0.0],
              [-81.036716517543269, 35.038644612308154, 0.0],
              [-81.036549518528375, 35.038552812020775, 0.0],
              [-81.036270984850105, 35.038552612496332, 0.0],
              [-81.035936517494875, 35.038941678718381, 0.0],
              [-81.035295784455542, 35.039124611942611, 0.0],
              [-81.035101117754849, 35.039032812469792, 0.0],
              [-81.035073385149957, 35.038758011820931, 0.0],
              [-81.0354917851718, 35.038231412014255, 0.0],
              [-81.035324918372879, 35.038093812342794, 0.0],
              [-81.034851517640533, 35.038001878688959, 0.0],
              [-81.034489785168319, 35.03777261234918, 0.0],
              [-81.034545784495364, 35.037497812099929, 0.0],
              [-81.034907917718257, 35.03742941215404, 0.0],
              [-81.035631785197936, 35.0375672120555, 0.0],
              [-81.035965984874125, 35.037453011915595, 0.0],
              [-81.036189317902355, 35.036926412120323, 0.0],
              [-81.036302517603772, 35.035254412078963, 0.0],
              [-81.036163385083071, 35.035139812272568, 0.0],
              [-81.035829318182749, 35.035116678650525, 0.0],
              [-81.034937785037357, 35.035459612338272, 0.0],
              [-81.034937517513512, 35.035849012322323, 0.0],
              [-81.03507638522062, 35.03614687893856, 0.0],
              [-81.034964784219625, 35.03623841179774, 0.0],
              [-81.034574984848362, 35.036283878906822, 0.0],
              [-81.03443598517201, 35.036031878541692, 0.0],
              [-81.034018384803062, 35.035894212167378, 0.0],
              [-81.031985185094854, 35.036373679434213, 0.0],
              [-81.031065717503225, 35.036785279271939, 0.0],
              [-81.030146717895946, 35.036945012278878, 0.0],
              [-81.029923984717428, 35.036853078757794, 0.0],
              [-81.029924184147362, 35.036601212770556, 0.0],
              [-81.030035918527844, 35.036418012587582, 0.0],
              [-81.031372918112638, 35.035961012501808, 0.0],
              [-81.03137351760509, 35.035502878994436, 0.0],
              [-81.031902717422952, 35.035411678599104, 0.0],
              [-81.032320317682277, 35.035503611985611, 0.0],
              [-81.032849384708257, 35.035504012464266, 0.0],
              [-81.033016584754094, 35.035389478580782, 0.0],
              [-81.033072585006309, 35.035114678990013, 0.0],
              [-81.033573985175337, 35.034886012283074, 0.0],
              [-81.033462984488196, 35.034519479068805, 0.0],
              [-81.03360251794517, 35.03424467875756, 0.0],
              [-81.033936717934424, 35.034245012723893, 0.0],
              [-81.034186984451395, 35.03454287862003, 0.0],
              [-81.034521117675979, 35.034611879216271, 0.0],
              [-81.034688584444382, 35.034245612668364, 0.0],
              [-81.035384718482391, 35.034314612141763, 0.0],
              [-81.03555191739504, 35.034154412358149, 0.0],
              [-81.035691785203156, 35.033536211980476, 0.0],
              [-81.03610978461198, 35.033330412616465, 0.0],
              [-81.036444517905949, 35.032735078861599, 0.0],
              [-81.036444984315779, 35.032299878758955, 0.0],
              [-81.03636171857255, 35.032093678889517, 0.0],
              [-81.036111318512482, 35.031887412709295, 0.0],
              [-81.035554584583693, 35.03170367897733, 0.0],
              [-81.035053918287474, 35.031245278962636, 0.0],
              [-81.034469184535226, 35.031176212675128, 0.0],
              [-81.033633584368104, 35.031450412538703, 0.0],
              [-81.032992985171475, 35.031564478712603, 0.0],
              [-81.032965518531441, 35.031266679090287, 0.0],
              [-81.033383518330226, 35.030992212057541, 0.0],
              [-81.034386318282429, 35.030557679340482, 0.0],
              [-81.035194117953154, 35.030397878632407, 0.0],
              [-81.035722985056438, 35.030535678621419, 0.0],
              [-81.036140184252545, 35.030925411915554, 0.0],
              [-81.036836184512325, 35.030971612166695, 0.0],
              [-81.037114718031717, 35.030834412468778, 0.0],
              [-81.037142917392131, 35.030559478929689, 0.0],
              [-81.035863984890653, 35.028840879085919, 0.0],
              [-81.035084984513531, 35.028199012387823, 0.0],
              [-81.034389318583578, 35.027969612709647, 0.0],
              [-81.03399931818258, 35.028106611915575, 0.0],
              [-81.033358184105808, 35.028793279067607, 0.0],
              [-81.032912384396084, 35.028976212086285, 0.0],
              [-81.032828917715449, 35.028930279339981, 0.0],
              [-81.032885118468997, 35.028518078823957, 0.0],
              [-81.033248117935244, 35.02753347889346, 0.0],
              [-81.033248718108865, 35.027075411911049, 0.0],
              [-81.032915317666792, 35.026388011813893, 0.0],
              [-81.032608984543899, 35.026387879152161, 0.0],
              [-81.032162917530684, 35.026891412350096, 0.0],
              [-81.031940317854961, 35.026891278891675, 0.0],
              [-81.031690318110748, 35.026387212347935, 0.0],
              [-81.031133584406788, 35.026180679089983, 0.0],
              [-81.031133784327679, 35.025997412772924, 0.0],
              [-81.031552185021823, 35.025402212430137, 0.0],
              [-81.031552717454204, 35.024944078571366, 0.0],
              [-81.031441585019692, 35.024737878898193, 0.0],
              [-81.031107718592594, 35.024554412188969, 0.0],
              [-81.030411718109619, 35.024462278753454, 0.0],
              [-81.030244717947923, 35.024370612343915, 0.0],
              [-81.030189385029672, 35.023981212331485, 0.0],
              [-81.030273185247623, 35.023820879341308, 0.0],
              [-81.030273718587452, 35.023340012073845, 0.0],
              [-81.030162718338175, 35.023042078891685, 0.0],
              [-81.029217584109034, 35.0217816126654, 0.0],
              [-81.028744717839359, 35.021391812537786, 0.0],
              [-81.028243918529441, 35.021116679307212, 0.0],
              [-81.027158584235991, 35.020772279433885, 0.0],
              [-81.026824518306057, 35.020772012213392, 0.0],
              [-81.026295184904157, 35.02100067935168, 0.0],
              [-81.026127984266381, 35.021160878533948, 0.0],
              [-81.026071984701161, 35.021435678979444, 0.0],
              [-81.026377917695598, 35.021710812800222, 0.0],
              [-81.027490985181288, 35.022123878771531, 0.0],
              [-81.027490717511299, 35.022330011825339, 0.0],
              [-81.026655317885954, 35.022581412141776, 0.0],
              [-81.026432317786558, 35.02276447907218, 0.0],
              [-81.026264717707221, 35.02319947864563, 0.0],
              [-81.026207784885372, 35.024207212758753, 0.0],
              [-81.026401117883324, 35.025490078880885, 0.0],
              [-81.026094918385951, 35.025512678685011, 0.0],
              [-81.025844518481236, 35.025306279346253, 0.0],
              [-81.02537151840292, 35.025122678623887, 0.0],
              [-81.025206118147082, 35.023656678492131, 0.0],
              [-81.024872317488331, 35.023404479310962, 0.0],
              [-81.024816918072162, 35.02322127881947, 0.0],
              [-81.024957317960727, 35.022236412704132, 0.0],
              [-81.024846385199524, 35.021938611974022, 0.0],
              [-81.024651784248519, 35.021732278485963, 0.0],
              [-81.024178517702197, 35.021594612492748, 0.0],
              [-81.02373251822074, 35.022075212012538, 0.0],
              [-81.023676517713497, 35.022372878821443, 0.0],
              [-81.023118984844871, 35.022853412329503, 0.0],
              [-81.022700584168149, 35.023517412601002, 0.0],
              [-81.022003917539578, 35.023906078887698, 0.0],
              [-81.020193584599852, 35.024454412762189, 0.0],
              [-81.01958051759074, 35.024843279417752, 0.0],
              [-81.019552384744557, 35.025095078807567, 0.0],
              [-81.019886117655474, 35.025347412056028, 0.0],
              [-81.021889784166518, 35.026082012544194, 0.0],
              [-81.02244618465042, 35.026517679304249, 0.0],
              [-81.023530185033493, 35.028030078652627, 0.0],
              [-81.023807318600376, 35.02908387891739, 0.0],
              [-81.023695717852618, 35.029244212426939, 0.0],
              [-81.023305984413113, 35.029289611929961, 0.0],
              [-81.022944318438974, 35.02894581196103, 0.0],
              [-81.022499718329485, 35.028235478529247, 0.0],
              [-81.022277184103771, 35.028052079296543, 0.0],
              [-81.021720385118257, 35.027937078983484, 0.0],
              [-81.02158151834297, 35.027776678764063, 0.0],
              [-81.021582184459959, 35.027181078570131, 0.0],
              [-81.021471118054819, 35.026929012429257, 0.0],
              [-81.020886784598517, 35.026699612583968, 0.0],
              [-81.020775718135539, 35.026447478735363, 0.0],
              [-81.019467184523364, 35.026354879140804, 0.0],
              [-81.019244718049819, 35.026125611835916, 0.0],
              [-81.018520984800418, 35.025987678876618, 0.0],
              [-81.018075185050563, 35.026193412588803, 0.0],
              [-81.018185318131145, 35.027247078823336, 0.0],
              [-81.017823184816706, 35.027292611922881, 0.0],
              [-81.017573384305706, 35.02674261231035, 0.0],
              [-81.017128517425959, 35.026284212468319, 0.0],
              [-81.017017918439862, 35.02562001276813, 0.0],
              [-81.016851184475385, 35.025459479055421, 0.0],
              [-81.016377784743554, 35.025459011842266, 0.0],
              [-81.016071184093278, 35.025802278869392, 0.0],
              [-81.016070985126362, 35.025894012070381, 0.0],
              [-81.015374917834109, 35.025962012259022, 0.0],
              [-81.015095984586864, 35.026328412400431, 0.0],
              [-81.015095184788279, 35.026878078567051, 0.0],
              [-81.016569918100345, 35.027566412198226, 0.0],
              [-81.016764184096573, 35.028001811967535, 0.0],
              [-81.017570918258357, 35.028597878821103, 0.0],
              [-81.017570717927711, 35.028826878750976, 0.0],
              [-81.016818718315847, 35.02896367874343, 0.0],
              [-81.016095384856399, 35.028436278692745, 0.0],
              [-81.015705784932322, 35.028252811822291, 0.0],
              [-81.015399584342561, 35.02822961261186, 0.0],
              [-81.015343717461619, 35.028458612601376, 0.0],
              [-81.014730584711231, 35.028847479347668, 0.0],
              [-81.014729385144634, 35.029649212387746, 0.0],
              [-81.014840385277708, 35.029969879317875, 0.0],
              [-81.01486758410131, 35.030473812344944, 0.0],
              [-81.01503411858161, 35.030817479225703, 0.0],
              [-81.015284384697807, 35.0310238121561, 0.0],
              [-81.015729917821062, 35.031024211878773, 0.0],
              [-81.016147918166894, 35.030818412149436, 0.0],
              [-81.016788518196861, 35.03079607866821, 0.0],
              [-81.016843718359823, 35.031093879300307, 0.0],
              [-81.01634231781793, 35.031299612571495, 0.0],
              [-81.01600771769499, 35.031528278613294, 0.0],
              [-81.015979518535133, 35.031849012178668, 0.0],
              [-81.016118318231861, 35.032238412320815, 0.0],
              [-81.016145518019172, 35.032673679207043, 0.0],
              [-81.01628438429961, 35.032948611910655, 0.0],
              [-81.017229984300116, 35.033705212107179, 0.0],
              [-81.017507984874015, 35.034049012681059, 0.0],
              [-81.017479384193507, 35.034667412673407, 0.0],
              [-81.017840518201268, 35.035309012579894, 0.0],
              [-81.017867984090188, 35.035584012192508, 0.0],
              [-81.017672984793094, 35.035698279008948, 0.0],
              [-81.017450185005771, 35.035675212751471, 0.0],
              [-81.017060585239719, 35.03542301185405, 0.0],
              [-81.016644585194101, 35.034300279082267, 0.0],
              [-81.016199917890049, 35.03368147852413, 0.0],
              [-81.015421318520964, 35.032810479008823, 0.0],
              [-81.015310318255359, 35.032466678593586, 0.0],
              [-81.014892984659099, 35.032191678769813, 0.0],
              [-81.014503917942577, 35.031779012108458, 0.0],
              [-81.013947184619695, 35.031549478930771, 0.0],
              [-81.013668584671564, 35.031686678853468, 0.0],
              [-81.013473384290364, 35.031915478493012, 0.0],
              [-81.013500717588641, 35.032304879132511, 0.0],
              [-81.013722984530801, 35.032648612498086, 0.0],
              [-81.013805985070761, 35.03306101250157, 0.0],
              [-81.014000384607755, 35.033404678538211, 0.0],
              [-81.013999984284652, 35.033702478603168, 0.0],
              [-81.01386071759741, 35.033816878816033, 0.0],
              [-81.012830518604801, 35.033701478737399, 0.0],
              [-81.012579584645849, 35.033861611970181, 0.0],
              [-81.012495584633939, 35.03420507878306, 0.0],
              [-81.012578184270907, 35.034961012561865, 0.0],
              [-81.012382918575355, 35.035121212575781, 0.0],
              [-81.011937318195166, 35.035258212763871, 0.0],
              [-81.011407584106536, 35.035670012640637, 0.0],
              [-81.010850584734001, 35.035761078760785, 0.0],
              [-81.010627584440428, 35.035967079305649, 0.0],
              [-81.010626918204053, 35.036448079387739, 0.0],
              [-81.010320184387695, 35.036631012128524, 0.0],
              [-81.009373984782201, 35.036195012286882, 0.0],
              [-81.00892851762795, 35.036194612203666, 0.0],
              [-81.008538318496946, 35.036446212003924, 0.0],
              [-81.008203517956659, 35.036881012018263, 0.0],
              [-81.008174384708596, 35.03784301235676, 0.0],
              [-81.007895318582314, 35.038232079407678, 0.0],
              [-81.007671984726656, 35.038621212326582, 0.0],
              [-81.00764271861415, 35.03958321249069, 0.0],
              [-81.007335118560235, 35.040499012396076, 0.0],
              [-81.007248984422787, 35.042285478478611, 0.0],
              [-81.007359984764165, 35.042537612312053, 0.0],
              [-81.007610384486526, 35.042698078888201, 0.0],
              [-81.008724117752905, 35.042905278570984, 0.0],
              [-81.008862984314192, 35.043088679284452, 0.0],
              [-81.008862718339429, 35.043271879398638, 0.0],
              [-81.008305717625035, 35.043385879271405, 0.0],
              [-81.007191917701135, 35.043201612350614, 0.0],
              [-81.006746717802699, 35.04294927865017, 0.0],
              [-81.006385784785607, 35.042147278971527, 0.0],
              [-81.006052184704345, 35.04187221233196, 0.0],
              [-81.005885118482595, 35.041872011959171, 0.0],
              [-81.005383117570346, 35.042352479056042, 0.0],
              [-81.005019517886183, 35.043337078879105, 0.0],
              [-81.004656517903939, 35.044023878850524, 0.0],
              [-81.004433318288449, 35.044298412727315, 0.0],
              [-81.004098984418633, 35.044389811970518, 0.0],
              [-81.004015584853192, 35.044321012484943, 0.0],
              [-81.003877385287566, 35.043565012063233, 0.0],
              [-81.003766185107608, 35.043450412067585, 0.0],
              [-81.003766717470626, 35.043083879018113, 0.0],
              [-81.004157384192538, 35.042534612737043, 0.0],
              [-81.004102518295028, 35.041962011894256, 0.0],
              [-81.004297917694416, 35.041733079123048, 0.0],
              [-81.005329385056953, 35.04095527881929, 0.0],
              [-81.005664118188704, 35.040612011911769, 0.0],
              [-81.005775918197529, 35.040360211921559, 0.0],
              [-81.005776317791742, 35.040062478711192, 0.0],
              [-81.005275917556645, 35.039466412527609, 0.0],
              [-81.005331985271994, 35.039191678709301, 0.0],
              [-81.005916984256146, 35.039009012029808, 0.0],
              [-81.006084318384566, 35.038871679074475, 0.0],
              [-81.00641998455761, 35.037818479052071, 0.0],
              [-81.006420985235536, 35.037131278456869, 0.0],
              [-81.006226518508527, 35.036902078556572, 0.0],
              [-81.005753318073815, 35.036718411995579, 0.0],
              [-81.005948585283562, 35.036420878759394, 0.0],
              [-81.006645184299771, 35.036123679231487, 0.0],
              [-81.006618318482637, 35.035528212595509, 0.0],
              [-81.007454317644459, 35.035048012750387, 0.0],
              [-81.007565918290538, 35.034910611884442, 0.0],
              [-81.007566184773438, 35.034727412645168, 0.0],
              [-81.007427317806503, 35.034498212369108, 0.0],
              [-81.006731584137015, 35.03417701215178, 0.0],
              [-81.006731917574697, 35.033993678598335, 0.0],
              [-81.007094584694656, 35.033490279229859, 0.0],
              [-81.00720658464563, 35.033032278602349, 0.0],
              [-81.007540917637627, 35.033009612548447, 0.0],
              [-81.007651984197636, 35.033124212248197, 0.0],
              [-81.007957384931956, 35.033811612589261, 0.0],
              [-81.008124185102318, 35.033995012050916, 0.0],
              [-81.008902917879652, 35.034637078906293, 0.0],
              [-81.009570917725142, 35.034843812691065, 0.0],
              [-81.010155784407473, 35.034798612399555, 0.0],
              [-81.010379585065351, 35.034042879380699, 0.0],
              [-81.010686785278551, 35.033447679325384, 0.0],
              [-81.011272184347533, 35.03296707922717, 0.0],
              [-81.011467518189264, 35.032692412773507, 0.0],
              [-81.011495584403946, 35.032486412320921, 0.0],
              [-81.01138451832, 35.032280079429341, 0.0],
              [-81.010744718456536, 35.031867279400061, 0.0],
              [-81.010689717785368, 35.031363279261129, 0.0],
              [-81.010550584179782, 35.031248679233869, 0.0],
              [-81.010578717764943, 35.031042612742979, 0.0],
              [-81.011330785014081, 35.030860012306228, 0.0],
              [-81.011581918463278, 35.030470878965659, 0.0],
              [-81.011583184384037, 35.029531811806137, 0.0],
              [-81.012140717996701, 35.029074212203511, 0.0],
              [-81.012363984190685, 35.028776679388663, 0.0],
              [-81.0124203184469, 35.028341479221801, 0.0],
              [-81.012309184868059, 35.028066612692328, 0.0],
              [-81.012086784425662, 35.027814412509791, 0.0],
              [-81.010861918079115, 35.027675878906869, 0.0],
              [-81.010834385044134, 35.027401079367337, 0.0],
              [-81.011141117747187, 35.027057812122784, 0.0],
              [-81.01167038521929, 35.026943679169143, 0.0],
              [-81.01264558525331, 35.02641781264375, 0.0],
              [-81.012673717609005, 35.026188812772808, 0.0],
              [-81.012311985175046, 35.02605101208237, 0.0],
              [-81.012424518068158, 35.025226612341648, 0.0],
              [-81.012063518049374, 35.024516212505162, 0.0],
              [-81.011340517865094, 35.023851278874034, 0.0],
              [-81.010644784721165, 35.023644612647416, 0.0],
              [-81.009865917495048, 35.023117078502345, 0.0],
              [-81.009003117835135, 35.022910212466499, 0.0],
              [-81.008419185131501, 35.022428612321534, 0.0],
              [-81.008335917749918, 35.022245278633228, 0.0],
              [-81.008172332792356, 35.022159461019825, 0.0],
              [-81.008029784425446, 35.02208467900919, 0.0],
              [-81.007835785073624, 35.021511878538028, 0.0],
              [-81.007836717870603, 35.020847678542609, 0.0],
              [-81.007697984633737, 35.020526878890855, 0.0],
              [-81.007677584962451, 35.020356812448291, 0.0],
              [-81.007642917794556, 35.020068678600026, 0.0],
              [-81.007197917468829, 35.019793479159567, 0.0],
              [-81.007170184205378, 35.019679012621289, 0.0],
              [-81.00672498467506, 35.019609879379004, 0.0],
              [-81.006279784584606, 35.01938041210429, 0.0],
              [-81.006587517786357, 35.018327078659844, 0.0],
              [-81.006754918209509, 35.018166879059976, 0.0],
              [-81.007089184180899, 35.018029812382501, 0.0],
              [-81.0070895848975, 35.01773207940515, 0.0],
              [-81.006727984943794, 35.017456879426412, 0.0],
              [-81.006728229781501, 35.017294396564893, 0.0],
              [-81.006728984281239, 35.016792679213822, 0.0],
              [-81.006617784107121, 35.016678079123793, 0.0],
              [-81.006673984833284, 35.016311612728529, 0.0],
              [-81.006952784397129, 35.016037012134284, 0.0],
              [-81.007231317786605, 35.015945678505474, 0.0],
              [-81.007648784678295, 35.016060479240579, 0.0],
              [-81.007981918104164, 35.016679212100911, 0.0],
              [-81.008204384259471, 35.01686267942047, 0.0],
              [-81.008816985003079, 35.016794612084425, 0.0],
              [-81.009123384454043, 35.016611612197401, 0.0],
              [-81.009179318566538, 35.016474278563933, 0.0],
              [-81.008929385281292, 35.016084678681054, 0.0],
              [-81.008985317977618, 35.015832811891947, 0.0],
              [-81.009124784137398, 35.015718279180511, 0.0],
              [-81.00973738477731, 35.015535612545072, 0.0],
              [-81.010183517504345, 35.015146679390632, 0.0],
              [-81.010462584855901, 35.01464301214429, 0.0],
              [-81.010546918204454, 35.014001812740155, 0.0],
              [-81.010658517997854, 35.013910278551592, 0.0],
              [-81.010853317977833, 35.013956279146427, 0.0],
              [-81.010879318222408, 35.01519307859617, 0.0],
              [-81.01073971820918, 35.015445012572492, 0.0],
              [-81.010627118602983, 35.01636101215982, 0.0],
              [-81.010403317830793, 35.017116678698848, 0.0],
              [-81.009762384720119, 35.017642878498997, 0.0],
              [-81.009678384385481, 35.01794061277684, 0.0],
              [-81.009873117591425, 35.018101079395066, 0.0],
              [-81.010318518118765, 35.018078611830013, 0.0],
              [-81.011237718400523, 35.017712878540138, 0.0],
              [-81.012992184866832, 35.017256412569111, 0.0],
              [-81.013103518083668, 35.017325212304449, 0.0],
              [-81.013103118166043, 35.017623012768965, 0.0],
              [-81.012098918622726, 35.019087879215775, 0.0],
              [-81.012126317977931, 35.019431479074115, 0.0],
              [-81.012348518362003, 35.01970661209635, 0.0],
              [-81.013155184466498, 35.020211212178687, 0.0],
              [-81.013766918556826, 35.020853012604718, 0.0],
              [-81.014016918452441, 35.021311278578473, 0.0],
              [-81.014267118474891, 35.021517678525534, 0.0],
              [-81.015269117820026, 35.021724612564256, 0.0],
              [-81.01579831748262, 35.021610611903917, 0.0],
              [-81.01599338483075, 35.021496279200271, 0.0],
              [-81.016104917561705, 35.021290212793602, 0.0],
              [-81.016105584613527, 35.020832079012884, 0.0],
              [-81.016524184664448, 35.020099612416338, 0.0],
              [-81.016468918158168, 35.019778812739517, 0.0],
              [-81.015940717698328, 35.019137012559483, 0.0],
              [-81.015857985053472, 35.018610211837881, 0.0],
              [-81.015468785058502, 35.018128879096004, 0.0],
              [-81.015496984975996, 35.017831078885109, 0.0],
              [-81.015915118074417, 35.017510879140261, 0.0],
              [-81.016360517501283, 35.017580012587139, 0.0],
              [-81.016666917608859, 35.017419878684748, 0.0],
              [-81.016750917462019, 35.017053479041728, 0.0],
              [-81.016667718514228, 35.016893079422559, 0.0],
              [-81.016640518335095, 35.016389212174168, 0.0],
              [-81.016529317983441, 35.016183012403872, 0.0],
              [-81.016586784731174, 35.014854479181189, 0.0],
              [-81.016726317600927, 35.014602679359378, 0.0],
              [-81.016948985129119, 35.014648611913557, 0.0],
              [-81.017226917743628, 35.014969612299495, 0.0],
              [-81.017308917502319, 35.016114879418708, 0.0],
              [-81.017809717521374, 35.016413012459431, 0.0],
              [-81.018811518340499, 35.016734478747317, 0.0],
              [-81.019284918130182, 35.016666212312366, 0.0],
              [-81.019563784505777, 35.016185411987671, 0.0],
              [-81.020260717946002, 35.015567612072815, 0.0],
              [-81.02095698498718, 35.015316079146437, 0.0],
              [-81.021179984106439, 35.014995679119949, 0.0],
              [-81.021542584667955, 35.014515012453003, 0.0],
              [-81.021515118041023, 35.014217278626582, 0.0],
              [-81.021320518410889, 35.014010878860461, 0.0],
              [-81.021293118434386, 35.013690212089266, 0.0],
              [-81.021154317554036, 35.01339227900705, 0.0],
              [-81.020848384440924, 35.013094411947812, 0.0],
              [-81.020904584185175, 35.012773812126738, 0.0],
              [-81.021350384418071, 35.012499278892804, 0.0],
              [-81.021851118444971, 35.012682879421163, 0.0],
              [-81.021878717826354, 35.012820412187324, 0.0],
              [-81.0221845850552, 35.013118411999713, 0.0],
              [-81.022796784797592, 35.013302012297451, 0.0],
              [-81.022907584404109, 35.013737278516814, 0.0],
              [-81.023352517877683, 35.014172879024422, 0.0],
              [-81.023129184873497, 35.014676611916592, 0.0],
              [-81.023072784812072, 35.015317812189622, 0.0],
              [-81.02329531762777, 35.015409678885, 0.0],
              [-81.023712984864119, 35.015249678702389, 0.0],
              [-81.024131318387759, 35.01481481200284, 0.0],
              [-81.024326117744522, 35.014746279093465, 0.0],
              [-81.024409717637099, 35.014815012808079, 0.0],
              [-81.024464318593175, 35.015616678724207, 0.0],
              [-81.023878718505372, 35.016395078736203, 0.0],
              [-81.023265184695049, 35.017127478581152, 0.0],
              [-81.023264984474736, 35.017356612259206, 0.0],
              [-81.023487584095207, 35.017448279207606, 0.0],
              [-81.024211784512573, 35.017128212796216, 0.0],
              [-81.024713117674139, 35.017059878731963, 0.0],
              [-81.025270184620553, 35.016785479085797, 0.0],
              [-81.026188984731093, 35.016717479194412, 0.0],
              [-81.026356385003012, 35.016351078654374, 0.0],
              [-81.026440717958309, 35.015778612251047, 0.0],
              [-81.026747184195131, 35.015503879002047, 0.0],
              [-81.026969918031213, 35.015550012143457, 0.0],
              [-81.027052584458971, 35.016237211853834, 0.0],
              [-81.027219184670216, 35.016626678879582, 0.0],
              [-81.027580918462533, 35.016833079131764, 0.0],
              [-81.028109917571598, 35.016787679304258, 0.0],
              [-81.028333318078197, 35.016215079132998, 0.0],
              [-81.028611717804608, 35.01614667916057, 0.0],
              [-81.028750784819806, 35.016238412700915, 0.0],
              [-81.028806117630936, 35.016490412072201, 0.0],
              [-81.029028718391046, 35.016628012404816, 0.0],
              [-81.029334984078062, 35.016582412429237, 0.0],
              [-81.029558184972473, 35.016239011914735, 0.0],
              [-81.029586318032315, 35.015918411991279, 0.0],
              [-81.029669984876733, 35.015872679313404, 0.0],
              [-81.029698118088277, 35.015666478883738, 0.0],
              [-81.02992091817373, 35.015483478776012, 0.0],
              [-81.030477917804347, 35.015346412747071, 0.0],
              [-81.030867985206186, 35.015071812031408, 0.0],
              [-81.031146318414642, 35.015163679333298, 0.0],
              [-81.031619517995949, 35.015118278643321, 0.0],
              [-81.032148784346845, 35.014866612023539, 0.0],
              [-81.032510584764509, 35.014958479439876, 0.0],
              [-81.032370917790928, 35.015416478810671, 0.0],
              [-81.032231385214999, 35.015645412038936, 0.0],
              [-81.03225891773333, 35.016034812025381, 0.0],
              [-81.0324813840828, 35.01617241228287, 0.0],
              [-81.03484778429906, 35.016082478978404, 0.0],
              [-81.035153984916292, 35.016174278652372, 0.0],
              [-81.03526511786545, 35.016357679369023, 0.0],
              [-81.035627118231957, 35.016289079422691, 0.0],
              [-81.035961517510501, 35.016037478549379, 0.0],
              [-81.036240318150419, 35.015671212457121, 0.0],
              [-81.036964117612769, 35.015580078822033, 0.0],
              [-81.037632918483098, 35.015099478536932, 0.0],
              [-81.037911318297546, 35.015099678789596, 0.0],
              [-81.037827384149182, 35.015374478830068, 0.0],
              [-81.03707478462367, 35.016221412281709, 0.0],
              [-81.036572784175874, 35.017091479444176, 0.0],
              [-81.036572384124156, 35.017480878547126, 0.0],
              [-81.036989518541688, 35.017893412001683, 0.0],
              [-81.037323518568058, 35.017985278726378, 0.0],
              [-81.037629384991718, 35.018329012451112, 0.0],
              [-81.037879984501586, 35.018329212737854, 0.0],
              [-81.038631917850154, 35.018054812480592, 0.0],
              [-81.038882517757742, 35.018077879361307, 0.0],
              [-81.038882184852596, 35.018238212570189, 0.0],
              [-81.038241384665994, 35.018856212718042, 0.0],
              [-81.038240984100383, 35.019199812351886, 0.0],
              [-81.038352184670927, 35.019337279403182, 0.0],
              [-81.038296384993188, 35.019520412751341, 0.0],
              [-81.038129185072748, 35.01961187887418, 0.0],
              [-81.037238184987984, 35.019611411906304, 0.0],
              [-81.03684831852452, 35.019794411876966, 0.0],
              [-81.036486317995909, 35.019839879110741, 0.0],
              [-81.036458118539088, 35.020206278649802, 0.0],
              [-81.037487584868359, 35.020779679032337, 0.0],
              [-81.037681784596856, 35.021421079445901, 0.0],
              [-81.037876385028738, 35.021742012210375, 0.0],
              [-81.038265784282089, 35.021925412311624, 0.0],
              [-81.038850517851145, 35.02199447894106, 0.0],
              [-81.039128718428941, 35.022155078731828, 0.0],
              [-81.04052098480625, 35.0219956127657, 0.0],
              [-81.040882718376722, 35.022179078667627, 0.0],
              [-81.040798985196517, 35.022385211938825, 0.0],
              [-81.040353117762166, 35.022865879099683, 0.0],
              [-81.040408384133002, 35.023232412664925, 0.0],
              [-81.040992385213656, 35.02380527861488, 0.0],
              [-81.040963784213645, 35.024629879102321, 0.0],
              [-81.041186185079653, 35.024904879407266, 0.0],
              [-81.041492318296037, 35.025065479042553, 0.0],
              [-81.042299584442802, 35.025249078561039, 0.0],
              [-81.042410918192047, 35.025340812266307, 0.0],
              [-81.042243385106261, 35.025661412695968, 0.0],
              [-81.042243184851884, 35.025890479025392, 0.0],
              [-81.042354518433498, 35.026073678695866, 0.0],
              [-81.042883385145814, 35.02607407899405, 0.0],
              [-81.043384717690316, 35.025937012645976, 0.0],
              [-81.04394211854337, 35.025410612310743, 0.0],
              [-81.044165118526934, 35.025089878918656, 0.0],
              [-81.044861317895879, 35.025021679305496, 0.0],
              [-81.045139918152486, 35.024792878866215, 0.0],
              [-81.045224917396411, 35.023327078622806, 0.0],
              [-81.045475717807832, 35.023166878976603, 0.0],
              [-81.045781584487443, 35.023510612509668, 0.0],
              [-81.046087185065915, 35.024129079359113, 0.0],
              [-81.046448917910439, 35.024427211985959, 0.0],
              [-81.047701584393948, 35.024588211822561, 0.0],
              [-81.048369785068218, 35.024519878851038, 0.0],
              [-81.048899118274079, 35.024314078758138, 0.0],
              [-81.049289184588645, 35.023993678984397, 0.0],
              [-81.049345717449, 35.023077612197049, 0.0],
              [-81.048844918533064, 35.022756612528987, 0.0],
              [-81.048678117494674, 35.022367079347354, 0.0],
              [-81.047871118021746, 35.022023078827701, 0.0],
              [-81.047759984777898, 35.021839679346414, 0.0],
              [-81.047927385159099, 35.021427478557342, 0.0],
              [-81.046898118152185, 35.020510878947107, 0.0],
              [-81.046898318526118, 35.020281812268315, 0.0],
              [-81.047148918223058, 35.020190278680019, 0.0],
              [-81.04784478415452, 35.02046547866312, 0.0],
              [-81.048680118389356, 35.020351479199803, 0.0],
              [-81.048763717459664, 35.020397412590604, 0.0],
              [-81.048791184057293, 35.020580612537529, 0.0],
              [-81.04915291752863, 35.020878612709964, 0.0],
              [-81.049848785063332, 35.020993479323117, 0.0],
              [-81.049959784137911, 35.021383012715404, 0.0],
              [-81.05023798419559, 35.021635078996489, 0.0],
              [-81.050683384619191, 35.02177281222113, 0.0],
              [-81.051073184459966, 35.021658412094318, 0.0],
              [-81.051825718205507, 35.020811412001734, 0.0],
              [-81.052299784223678, 35.019895479154101, 0.0],
              [-81.053079384406701, 35.019804211872831, 0.0],
              [-81.053831784600561, 35.01907181200837, 0.0],
              [-81.054054384473631, 35.01911767876777, 0.0],
              [-81.054193517720705, 35.019232278799933, 0.0],
              [-81.054498784861963, 35.02028601231536, 0.0],
              [-81.054665784702124, 35.020469412688797, 0.0],
              [-81.054999717837006, 35.020629812766572, 0.0],
              [-81.055333784711195, 35.020630011830917, 0.0],
              [-81.055473384941763, 35.020149078942438, 0.0],
              [-81.055779984405191, 35.019714079447219, 0.0],
              [-81.0557525845322, 35.019187278663445, 0.0],
              [-81.055335384576892, 35.01879767927818, 0.0],
              [-81.055335517582037, 35.018545812610647, 0.0],
              [-81.055474917805597, 35.018454212670655, 0.0],
              [-81.056449317919061, 35.018454678521046, 0.0],
              [-81.056978318202795, 35.018317612755624, 0.0],
              [-81.057061917557846, 35.018203079036446, 0.0],
              [-81.056922984433697, 35.017882278888642, 0.0],
              [-81.057145984796506, 35.017584611863889, 0.0],
              [-81.057174184728368, 35.017149479051007, 0.0],
              [-81.056784918025372, 35.016691212538902, 0.0],
              [-81.056534385182047, 35.01657647858498, 0.0],
              [-81.056367584173216, 35.016347478981061, 0.0],
              [-81.055671718154557, 35.016209678990748, 0.0],
              [-81.055588384646654, 35.015843211872472, 0.0],
              [-81.055421517403644, 35.015659878653906, 0.0],
              [-81.054586717380857, 35.015292878988426, 0.0],
              [-81.054614718099018, 35.015018079434007, 0.0],
              [-81.055394718106839, 35.014491678698647, 0.0],
              [-81.055923784206101, 35.01442321246568, 0.0],
              [-81.055979517475848, 35.014331612542733, 0.0],
              [-81.055868384877314, 35.013965078519064, 0.0],
              [-81.055757117842688, 35.01389647938899, 0.0],
              [-81.055757318149361, 35.013644479201005, 0.0],
              [-81.055952184444635, 35.013575812669586, 0.0],
              [-81.056314184091278, 35.01362181185668, 0.0],
              [-81.057232517942509, 35.013988678679041, 0.0],
              [-81.058179317633162, 35.013691411853713, 0.0],
              [-81.058401985038486, 35.013691612577574, 0.0],
              [-81.05851318422414, 35.013806212530042, 0.0],
              [-81.058178784087403, 35.014172412733274, 0.0],
              [-81.057454584983816, 35.014698878910849, 0.0],
              [-81.057426318389773, 35.015225678688992, 0.0],
              [-81.05773231771758, 35.015523612100523, 0.0],
              [-81.057871118062906, 35.016027612653019, 0.0],
              [-81.058121517905633, 35.01623381239763, 0.0],
              [-81.058650184789954, 35.016463211824124, 0.0],
              [-81.059569118095666, 35.016303279432016, 0.0],
              [-81.059596585193546, 35.016601011822509, 0.0],
              [-81.058900184277576, 35.01719621225358, 0.0],
              [-81.058899984372871, 35.017471012236719, 0.0],
              [-81.059066984393084, 35.017562678831432, 0.0],
              [-81.060013518364428, 35.017563212350865, 0.0],
              [-81.060542784631622, 35.017242812639935, 0.0],
              [-81.061100117406696, 35.016464411855516, 0.0],
              [-81.061100384805712, 35.016143678544346, 0.0],
              [-81.060877917470592, 35.015822878818447, 0.0],
              [-81.060739117434622, 35.015341812375965, 0.0],
              [-81.060795117590899, 35.014998278653223, 0.0],
              [-81.060990117436276, 35.014838079092549, 0.0],
              [-81.06121278425384, 35.014861079358973, 0.0],
              [-81.061435185117801, 35.015204812564193, 0.0],
              [-81.061434317695984, 35.016395812342793, 0.0],
              [-81.061656918312238, 35.016579212411848, 0.0],
              [-81.062157917839556, 35.016648078905646, 0.0],
              [-81.06215758445704, 35.016900012566893, 0.0],
              [-81.061628384623333, 35.017403678700553, 0.0],
              [-81.06165598486588, 35.017701478515818, 0.0],
              [-81.06187871834284, 35.017793212240448, 0.0],
              [-81.061962118296137, 35.018022211819378, 0.0],
              [-81.062128918172561, 35.018159679428869, 0.0],
              [-81.062518718523847, 35.017999612646918, 0.0],
              [-81.062713784145501, 35.017816412187287, 0.0],
              [-81.062936517717176, 35.017862412279868, 0.0],
              [-81.063047717813831, 35.017999878984583, 0.0],
              [-81.063047717404629, 35.018183079154532, 0.0],
              [-81.063743117955212, 35.018870612361447, 0.0],
              [-81.063854184525823, 35.019214212787432, 0.0],
              [-81.063909185016072, 35.02017621237183, 0.0],
              [-81.064215118317847, 35.020657278917675, 0.0],
              [-81.064549118329495, 35.020657412169648, 0.0],
              [-81.065022718062508, 35.020268212048201, 0.0],
              [-81.065246117580145, 35.019375079008029, 0.0],
              [-81.065218918358795, 35.018504812321304, 0.0],
              [-81.065135384100543, 35.018413079407345, 0.0],
              [-81.065108318229861, 35.017451078491654, 0.0],
              [-81.065219917903477, 35.017176278842257, 0.0],
              [-81.065220184872175, 35.016809878676035, 0.0],
              [-81.06510898443112, 35.016603612061907, 0.0],
              [-81.065192784546369, 35.016145612303369, 0.0],
              [-81.06530418488957, 35.016008278551055, 0.0],
              [-81.065416318124818, 35.015069079397726, 0.0],
              [-81.065583584506513, 35.014679812035574, 0.0],
              [-81.065778384983204, 35.014679879270346, 0.0],
              [-81.065778185067387, 35.015046412614865, 0.0],
              [-81.065666584049097, 35.015367012771449, 0.0],
              [-81.065861118075574, 35.016008412363718, 0.0],
              [-81.065804985089557, 35.016512212435821, 0.0],
              [-81.065693518357776, 35.016718278606739, 0.0],
              [-81.065748784048694, 35.017245212071714, 0.0],
              [-81.065943384126598, 35.01758881234673, 0.0],
              [-81.066165117553595, 35.019100612360972, 0.0],
              [-81.066443384294303, 35.019329812549472, 0.0],
              [-81.066944384195679, 35.019375812386421, 0.0],
              [-81.067528784032348, 35.019696812222932, 0.0],
              [-81.067946384930707, 35.019651079183745, 0.0],
              [-81.068197184827341, 35.019468011822688, 0.0],
              [-81.069004718278322, 35.019239279022649, 0.0],
              [-81.069199717826095, 35.019102011973793, 0.0],
              [-81.069171918148626, 35.018918678495396, 0.0],
              [-81.068810318456485, 35.018597878876726, 0.0],
              [-81.068838317442697, 35.018208478513081, 0.0],
              [-81.069228184702908, 35.018071279241255, 0.0],
              [-81.070035584524973, 35.018048678888789, 0.0],
              [-81.070341984083669, 35.0177510122953, 0.0],
              [-81.07034211808184, 35.017567812505682, 0.0],
              [-81.070258584580586, 35.017499012079547, 0.0],
              [-81.070259118018015, 35.016857679457075, 0.0],
              [-81.069786118487471, 35.016353679392296, 0.0],
              [-81.06978618411199, 35.016147478702933, 0.0],
              [-81.069953317397946, 35.016056012524821, 0.0],
              [-81.070176117823863, 35.016078878596488, 0.0],
              [-81.071400584505625, 35.016697811873499, 0.0],
              [-81.072597584040338, 35.016767079208499, 0.0],
              [-81.072541718401681, 35.017064812046868, 0.0],
              [-81.072318917607603, 35.017362479437679, 0.0],
              [-81.071594917515, 35.017659878596113, 0.0],
              [-81.07151118469136, 35.017957612702901, 0.0],
              [-81.072012117642942, 35.018324278559689, 0.0],
              [-81.072011917642328, 35.018622012290066, 0.0],
              [-81.07103698482004, 35.019240078599921, 0.0],
              [-81.070758384991535, 35.019537812497973, 0.0],
              [-81.07067458513086, 35.019972878523461, 0.0],
              [-81.070701917871489, 35.020820411886532, 0.0],
              [-81.071731518466663, 35.021599612023458, 0.0],
              [-81.071926317792091, 35.021828678822494, 0.0],
              [-81.072788984036833, 35.022264212617969, 0.0],
              [-81.07373531760058, 35.022860078786458, 0.0],
              [-81.074709784236816, 35.022952012504668, 0.0],
              [-81.074792918383878, 35.023570478610232, 0.0],
              [-81.074987718041456, 35.023707878708031, 0.0],
              [-81.076574717324263, 35.023731412063498, 0.0],
              [-81.077131584160682, 35.023594212636084, 0.0],
              [-81.077688584726843, 35.023296678512139, 0.0],
              [-81.078496384743715, 35.02249521257113, 0.0],
              [-81.079109184043432, 35.022129012642353, 0.0],
              [-81.079304318127399, 35.021602278849876, 0.0],
              [-81.079499384115962, 35.021396278639578, 0.0],
              [-81.080195517441666, 35.021327678867607, 0.0],
              [-81.081838784036961, 35.019885212737172, 0.0],
              [-81.082117318501204, 35.019839612602894, 0.0],
              [-81.082117184614432, 35.020045678757661, 0.0],
              [-81.081309117853976, 35.021190612757714, 0.0],
              [-81.080919184620953, 35.021511211925329, 0.0],
              [-81.080250785076501, 35.021900278743388, 0.0],
              [-81.079944385036868, 35.022220879098747, 0.0],
              [-81.079609984921262, 35.022770479323569, 0.0],
              [-81.079025117567639, 35.023159679183742, 0.0],
              [-81.078551584715484, 35.023342678792019, 0.0],
              [-81.078300917483588, 35.023571678917612, 0.0],
              [-81.078300785013113, 35.02382361182481, 0.0],
              [-81.078523318233096, 35.02414441216488, 0.0],
              [-81.078801584619796, 35.02435061215126, 0.0],
              [-81.079720384665151, 35.024580012002168, 0.0],
              [-81.080081984375653, 35.025061079191836, 0.0],
              [-81.080416117642514, 35.025336078620512, 0.0],
              [-81.08086151778781, 35.025267411966325, 0.0],
              [-81.081279184377379, 35.025084411828388, 0.0],
              [-81.082002784187893, 35.025817479231911, 0.0],
              [-81.082392517891705, 35.0260238793133, 0.0],
              [-81.083283384965313, 35.026115679444622, 0.0],
              [-81.083394717893015, 35.02620727944381, 0.0],
              [-81.083533584852589, 35.026665478535996, 0.0],
              [-81.084062384751746, 35.02707787849333, 0.0],
              [-81.084786517709077, 35.027032278792653, 0.0],
              [-81.085343518145635, 35.0267576118137, 0.0],
              [-81.085984117692675, 35.026070678734499, 0.0],
              [-81.0866801847273, 35.026070811891536, 0.0],
              [-81.087209384265392, 35.025910679149447, 0.0],
              [-81.088629584029178, 35.025109279302299, 0.0],
              [-81.088880184636977, 35.025132279450204, 0.0],
              [-81.089130718356856, 35.025315678479103, 0.0],
              [-81.08982691772772, 35.025132612708575, 0.0],
              [-81.090244518258459, 35.025201278685891, 0.0],
              [-81.090216584396799, 35.025476212411483, 0.0],
              [-81.089297384216181, 35.02609447877041, 0.0],
              [-81.088879584282992, 35.026644011854934, 0.0],
              [-81.088907184836202, 35.026964678809541, 0.0],
              [-81.089157584548516, 35.027445679054445, 0.0],
              [-81.089407784882567, 35.028499412736529, 0.0],
              [-81.089630384164721, 35.028751478952138, 0.0],
              [-81.090688384210623, 35.029072412566293, 0.0],
              [-81.09079971777291, 35.029186878778859, 0.0],
              [-81.090827385025534, 35.029393079206748, 0.0],
              [-81.091217184060454, 35.029713812640779, 0.0],
              [-81.091188985081132, 35.03042381223289, 0.0],
              [-81.091383784981758, 35.030698812549922, 0.0],
              [-81.092330184605757, 35.031431812001138, 0.0],
              [-81.092858918311222, 35.032210678467656, 0.0],
              [-81.092691784472407, 35.032623012080315, 0.0],
              [-81.093192784201392, 35.032966679188242, 0.0],
              [-81.093109117965923, 35.033218611966966, 0.0],
              [-81.093192717498482, 35.03331021255714, 0.0],
              [-81.094000184532334, 35.033470812204513, 0.0],
              [-81.094111518087217, 35.03363121269264, 0.0],
              [-81.094000117630202, 35.033745678642731, 0.0],
              [-81.093136784937798, 35.033882879175586, 0.0],
              [-81.092384784188013, 35.034340679338442, 0.0],
              [-81.092189784855577, 35.034340812624876, 0.0],
              [-81.09180011821384, 35.034088678585121, 0.0],
              [-81.090881318288638, 35.034065612026218, 0.0],
              [-81.09046358471538, 35.033721878509553, 0.0],
              [-81.090268918083012, 35.033401212577452, 0.0],
              [-81.090102317984815, 35.032164278497319, 0.0],
              [-81.089768318200754, 35.031980879009083, 0.0],
              [-81.089545718194529, 35.031591612351477, 0.0],
              [-81.089322984357722, 35.031454079307565, 0.0],
              [-81.088515584430965, 35.031385212443134, 0.0],
              [-81.087624784168483, 35.03088101212677, 0.0],
              [-81.086566584152635, 35.030949478523048, 0.0],
              [-81.086037584178158, 35.030857679393392, 0.0],
              [-81.085647917897163, 35.030559811911544, 0.0],
              [-81.084840384034266, 35.030468012638863, 0.0],
              [-81.08458998433197, 35.030238879398283, 0.0],
              [-81.084089318286317, 35.029185078556466, 0.0],
              [-81.083838784809515, 35.028956012261233, 0.0],
              [-81.083254184089881, 35.028910011978219, 0.0],
              [-81.082808784363138, 35.028726679410944, 0.0],
              [-81.082335384880324, 35.028863879280898, 0.0],
              [-81.082029118176038, 35.028863878654128, 0.0],
              [-81.081778584762745, 35.028543079058643, 0.0],
              [-81.081444584348461, 35.028336811865536, 0.0],
              [-81.080971317899866, 35.028222278701854, 0.0],
              [-81.080665318512828, 35.027580812606196, 0.0],
              [-81.080387184281335, 35.027260078611562, 0.0],
              [-81.080025317518405, 35.027008012490725, 0.0],
              [-81.079496318310646, 35.026870411938297, 0.0],
              [-81.079134518262364, 35.02650381218632, 0.0],
              [-81.078215917546032, 35.026137012320923, 0.0],
              [-81.077770384969909, 35.026159679032659, 0.0],
              [-81.077631318485103, 35.026274278506364, 0.0],
              [-81.077658784814759, 35.026640679423544, 0.0],
              [-81.077881317469178, 35.027030078668197, 0.0],
              [-81.077853317423461, 35.027305011867007, 0.0],
              [-81.077686184079766, 35.027465212664175, 0.0],
              [-81.077352118435982, 35.027373479142561, 0.0],
              [-81.077213118239655, 35.027121478474257, 0.0],
              [-81.076990384719224, 35.027006878873657, 0.0],
              [-81.076823318090064, 35.027029811861453, 0.0],
              [-81.076683984031561, 35.027235879365428, 0.0],
              [-81.076655917771532, 35.027602278907544, 0.0],
              [-81.076822917825638, 35.027785612094235, 0.0],
              [-81.076822784713286, 35.028060478816741, 0.0],
              [-81.076544184498829, 35.028197812115792, 0.0],
              [-81.076349318244368, 35.028197812180011, 0.0],
              [-81.076182318199258, 35.028060279212475, 0.0],
              [-81.076099317586213, 35.027258612259175, 0.0],
              [-81.075987984826497, 35.027006611833187, 0.0],
              [-81.075793184651999, 35.026823279150875, 0.0],
              [-81.075459184029995, 35.026685679274387, 0.0],
              [-81.075097184819938, 35.026754279001516, 0.0],
              [-81.074623917559521, 35.026731278555104, 0.0],
              [-81.074178584330994, 35.026364612391426, 0.0],
              [-81.07376098501372, 35.026227012034802, 0.0],
              [-81.073231985005165, 35.026295479208322, 0.0],
              [-81.072842117858897, 35.026478679083546, 0.0],
              [-81.072480117811267, 35.026432679065607, 0.0],
              [-81.072480917879943, 35.025173012022641, 0.0],
              [-81.072091584896881, 35.024508612514751, 0.0],
              [-81.07139578504983, 35.024141812474902, 0.0],
              [-81.0711455180504, 35.023660679199708, 0.0],
              [-81.07078371735858, 35.023317011864094, 0.0],
              [-81.070199318295778, 35.022996212748964, 0.0],
              [-81.069753784103938, 35.022881479261166, 0.0],
              [-81.069280585019527, 35.022972812104236, 0.0],
              [-81.068723518182495, 35.02324741260432, 0.0],
              [-81.068556384848478, 35.023247412288875, 0.0],
              [-81.067888585084617, 35.02269741245815, 0.0],
              [-81.067109117505836, 35.022536679056564, 0.0],
              [-81.06674738489906, 35.022330412042521, 0.0],
              [-81.066218517620243, 35.022353079315742, 0.0],
              [-81.065772718017001, 35.022719411858105, 0.0],
              [-81.065354518088725, 35.023429212272248, 0.0],
              [-81.065326384864676, 35.023795678688494, 0.0],
              [-81.065103384358054, 35.024230679318379, 0.0],
              [-81.064880517694277, 35.024414012750007, 0.0],
              [-81.064546318255708, 35.024413812512933, 0.0],
              [-81.064129184864697, 35.023909679302804, 0.0],
              [-81.063572517769359, 35.023726212534285, 0.0],
              [-81.062764985009849, 35.023771679183099, 0.0],
              [-81.062041184429759, 35.023588012302362, 0.0],
              [-81.061206117713425, 35.02349601186593, 0.0],
              [-81.060983384116554, 35.023312612065453, 0.0],
              [-81.06090011794096, 35.02310641226795, 0.0],
              [-81.060956917923761, 35.021686412487981, 0.0],
              [-81.060817918061971, 35.021480212332882, 0.0],
              [-81.060483918150993, 35.021251078799743, 0.0],
              [-81.060177585217076, 35.021250879273701, 0.0],
              [-81.059926918095215, 35.021365279451913, 0.0],
              [-81.059787518409465, 35.021571411995303, 0.0],
              [-81.059787317685434, 35.021983679260458, 0.0],
              [-81.059898317985883, 35.022373078956157, 0.0],
              [-81.058868518261377, 35.022120612789131, 0.0],
              [-81.058506784908076, 35.021776878841834, 0.0],
              [-81.057921984060442, 35.021891078646277, 0.0],
              [-81.057448384374837, 35.022188612168691, 0.0],
              [-81.056974784740419, 35.022646612012807, 0.0],
              [-81.056417784318199, 35.022921012796985, 0.0],
              [-81.056194784824811, 35.02312707860402, 0.0],
              [-81.055970917745867, 35.024478278583032, 0.0],
              [-81.055720384768009, 35.024478078885444, 0.0],
              [-81.055497717378358, 35.024340612772214, 0.0],
              [-81.055247718141302, 35.023722079021709, 0.0],
              [-81.054913717578316, 35.02344707923411, 0.0],
              [-81.053076184283327, 35.023537679138691, 0.0],
              [-81.052797517893453, 35.023743679107092, 0.0],
              [-81.0524073846872, 35.024224478772261, 0.0],
              [-81.052379118363632, 35.024728279242943, 0.0],
              [-81.052518117854035, 35.02488881248135, 0.0],
              [-81.053325517775761, 35.024980812018768, 0.0],
              [-81.053742784874728, 35.025187212738921, 0.0],
              [-81.055051584517614, 35.025142078887136, 0.0],
              [-81.055413384400936, 35.025279679438654, 0.0],
              [-81.055802918309453, 35.025623412739733, 0.0],
              [-81.056693718287605, 35.025807212403059, 0.0],
              [-81.05683278514465, 35.025921812681418, 0.0],
              [-81.057222718247672, 35.025853279174257, 0.0],
              [-81.057250784334599, 35.025601278756618, 0.0],
              [-81.057056117670811, 35.025280611900236, 0.0],
              [-81.057056317913606, 35.025074478725742, 0.0],
              [-81.057752584605609, 35.024754078506575, 0.0],
              [-81.058003184069392, 35.024754212669173, 0.0],
              [-81.058253518323838, 35.025029212302833, 0.0],
              [-81.059005318400736, 35.025144079336762, 0.0],
              [-81.058837917561448, 35.025602079120652, 0.0],
              [-81.058837584032929, 35.025877012407683, 0.0],
              [-81.059060118286325, 35.025991679027122, 0.0],
              [-81.059477784894298, 35.025900079383788, 0.0],
              [-81.059728718372241, 35.025671212587149, 0.0],
              [-81.059896117386842, 35.025144478860845, 0.0],
              [-81.060230384611927, 35.024892812547243, 0.0],
              [-81.060342117983922, 35.024526279126384, 0.0],
              [-81.061010584190086, 35.024183212097455, 0.0],
              [-81.061233384458049, 35.024228878924788, 0.0],
              [-81.06140031786002, 35.024366478596747, 0.0],
              [-81.061566917717471, 35.024893412712871, 0.0],
              [-81.062206785044879, 35.025466279034767, 0.0],
              [-81.062206518073538, 35.025764012090725, 0.0],
              [-81.062011384027201, 35.026130412400803, 0.0],
              [-81.062011184482543, 35.026451079178976, 0.0],
              [-81.062456318340338, 35.026840679271636, 0.0],
              [-81.062539717383814, 35.027184278978289, 0.0],
              [-81.062511185115923, 35.028054612540942, 0.0],
              [-81.062760784137197, 35.029222879004749, 0.0],
              [-81.062815918284514, 35.030070279220304, 0.0],
              [-81.06295498412203, 35.03018501182725, 0.0],
              [-81.063400518117419, 35.03016227886242, 0.0],
              [-81.063790518517763, 35.029956411845454, 0.0],
              [-81.064347984225165, 35.029040411879059, 0.0],
              [-81.064431984074218, 35.028422012713328, 0.0],
              [-81.064599384424909, 35.028055612291354, 0.0],
              [-81.06493338414171, 35.028147412630126, 0.0],
              [-81.065072184840474, 35.028651279097581, 0.0],
              [-81.065183117715904, 35.029407212688589, 0.0],
              [-81.065294517667454, 35.029498879252529, 0.0],
              [-81.065684318241665, 35.02945327848218, 0.0],
              [-81.065990717867251, 35.029270078799307, 0.0],
              [-81.066324785168703, 35.02931607938924, 0.0],
              [-81.066129584866246, 35.029682412537937, 0.0],
              [-81.065210184541542, 35.030460879174022, 0.0],
              [-81.064959317730327, 35.030941678968375, 0.0],
              [-81.064986784770184, 35.031239479212445, 0.0],
              [-81.065404385195521, 35.031468678953914, 0.0],
              [-81.066657584559707, 35.031286012036595, 0.0],
              [-81.067186717431625, 35.031080079010181, 0.0],
              [-81.067910717891721, 35.030988878580381, 0.0],
              [-81.068272918048066, 35.030782812201565, 0.0],
              [-81.068858117533765, 35.030118812744469, 0.0],
              [-81.068941917422563, 35.029592011980839, 0.0],
              [-81.069081318491342, 35.02947761251923, 0.0],
              [-81.069304118348029, 35.029500679158204, 0.0],
              [-81.069526584345596, 35.029775612050464, 0.0],
              [-81.07033398443221, 35.029684211823529, 0.0],
              [-81.070528784324011, 35.029798878621058, 0.0],
              [-81.070473118170725, 35.029982078767418, 0.0],
              [-81.070222384175182, 35.030142279269484, 0.0],
              [-81.069609784340116, 35.030348212725826, 0.0],
              [-81.069219585083346, 35.030668679130699, 0.0],
              [-81.068940718019405, 35.03142441220588, 0.0],
              [-81.068968317360387, 35.031745079309822, 0.0],
              [-81.069079584942301, 35.031836811860387, 0.0],
              [-81.069051718331636, 35.032020012194494, 0.0],
              [-81.068773117565698, 35.032226012424815, 0.0],
              [-81.068772984561321, 35.032432211932026, 0.0],
              [-81.068856384229903, 35.032500812535375, 0.0],
              [-81.068828385093255, 35.032752811945606, 0.0],
              [-81.068020784067954, 35.032890012637964, 0.0],
              [-81.067881584147898, 35.032798212060833, 0.0],
              [-81.067770518396216, 35.032500479091965, 0.0],
              [-81.067492117566516, 35.032340012492533, 0.0],
              [-81.067074317532601, 35.032477212515069, 0.0],
              [-81.066684185073242, 35.032981012297434, 0.0],
              [-81.066266317719524, 35.033278611877741, 0.0],
              [-81.066154717708073, 35.033599212499048, 0.0],
              [-81.066210118284374, 35.034103078647057, 0.0],
              [-81.066599717554098, 35.034355279310454, 0.0],
              [-81.067852584901914, 35.034424479010333, 0.0],
              [-81.067963984676723, 35.03451607909259, 0.0],
              [-81.067908117395405, 35.03469941251187, 0.0],
              [-81.066933184763499, 35.035179878784149, 0.0],
              [-81.066738184011925, 35.03538601209786, 0.0],
              [-81.066737985007848, 35.035752412533689, 0.0],
              [-81.067183117686625, 35.036096078923322, 0.0],
              [-81.067266517849802, 35.036416812663063, 0.0],
              [-81.067182917572154, 35.036485479426275, 0.0],
              [-81.067154717964371, 35.036943612617506, 0.0],
              [-81.067766984621827, 35.037470679032218, 0.0],
              [-81.067822517828233, 35.037699812755513, 0.0],
              [-81.067989518155471, 35.037860212227379, 0.0],
              [-81.068184518007357, 35.037860279029765, 0.0],
              [-81.068490784385077, 35.03772287859978, 0.0],
              [-81.068964584131194, 35.037219279128941, 0.0],
              [-81.069410718354021, 35.036555211939039, 0.0],
              [-81.069633384508862, 35.036417812124256, 0.0],
              [-81.069856118310014, 35.036440879188646, 0.0],
              [-81.069967184736527, 35.037059278834711, 0.0],
              [-81.07038491756002, 35.037059478554738, 0.0],
              [-81.07116471791322, 35.036899479295805, 0.0],
              [-81.071554518488426, 35.036945412365078, 0.0],
              [-81.071526518509941, 35.037243212211344, 0.0],
              [-81.070217318437713, 35.037769478828629, 0.0],
              [-81.069938584394492, 35.03802127846906, 0.0],
              [-81.069938184090844, 35.038616812606335, 0.0],
              [-81.069659585184596, 35.038937411969364, 0.0],
              [-81.069659384473709, 35.039235078843426, 0.0],
              [-81.070132585119723, 35.03957881195835, 0.0],
              [-81.070271517920929, 35.039968279136168, 0.0],
              [-81.070521985009805, 35.040197479013116, 0.0],
              [-81.071301785081516, 35.040152011929685, 0.0],
              [-81.071496584469884, 35.040266612375547, 0.0],
              [-81.07166351777758, 35.040724812716022, 0.0],
              [-81.071802718464014, 35.040816411784135, 0.0],
              [-81.072164784243256, 35.040724812088122, 0.0],
              [-81.072359784822368, 35.04058761204557, 0.0],
              [-81.073250784740864, 35.040679612674147, 0.0],
              [-81.074030584955153, 35.040473679212234, 0.0],
              [-81.074170117347833, 35.040244612072598, 0.0],
              [-81.074226318047906, 35.039351411954158, 0.0],
              [-81.074644117389568, 35.039374478627103, 0.0],
              [-81.074866718074148, 35.039557812080695, 0.0],
              [-81.075200784310695, 35.039649612380408, 0.0],
              [-81.075451384962548, 35.039603878693633, 0.0],
              [-81.075730184048524, 35.039054212751118, 0.0],
              [-81.075980917416743, 35.038985612736582, 0.0],
              [-81.076231517761059, 35.039054411990946, 0.0],
              [-81.076565718011906, 35.039008812632829, 0.0],
              [-81.076788584494963, 35.038756812154382, 0.0],
              [-81.076900318398103, 35.038207212609045, 0.0],
              [-81.077150984555786, 35.03816147910112, 0.0],
              [-81.077317984889504, 35.038276078976864, 0.0],
              [-81.077429184001574, 35.038619612121749, 0.0],
              [-81.077596317663719, 35.038734212295317, 0.0],
              [-81.078013985169321, 35.038642678639484, 0.0],
              [-81.078236784327075, 35.038505412512492, 0.0],
              [-81.078738584950571, 35.037452012005915, 0.0],
              [-81.0789897173714, 35.036512878626439, 0.0],
              [-81.078962184353287, 35.035963211795945, 0.0],
              [-81.07887871840164, 35.035917411996927, 0.0],
              [-81.078878984905884, 35.035390612703182, 0.0],
              [-81.079129717969678, 35.035299078814099, 0.0],
              [-81.079296718385962, 35.03539081201297, 0.0],
              [-81.079463717379738, 35.035642812499489, 0.0],
              [-81.080243384346716, 35.035528612452865, 0.0],
              [-81.080354917678335, 35.035574411996635, 0.0],
              [-81.080187518071696, 35.035940812240199, 0.0],
              [-81.080020318283175, 35.036215611975379, 0.0],
              [-81.079853117458867, 35.036330012732954, 0.0],
              [-81.079490784694698, 35.036879612669885, 0.0],
              [-81.079351184753619, 35.037543679206863, 0.0],
              [-81.079378584378432, 35.038345412746821, 0.0],
              [-81.079629118417458, 35.038460078997758, 0.0],
              [-81.080409118016121, 35.038299879350276, 0.0],
              [-81.080743117685273, 35.038323012551466, 0.0],
              [-81.08116071820757, 35.038575079163209, 0.0],
              [-81.081606317929527, 35.038483478590351, 0.0],
              [-81.081717717904496, 35.038391878608493, 0.0],
              [-81.081857384233686, 35.037659078995375, 0.0],
              [-81.082052317492924, 35.037544612472658, 0.0],
              [-81.082525584614714, 35.037819679201192, 0.0],
              [-81.08277631780615, 35.037796812495195, 0.0],
              [-81.083194184539494, 35.037499079275065, 0.0],
              [-81.083667517963079, 35.037591011874774, 0.0],
              [-81.083945784775622, 35.037774278577281, 0.0],
              [-81.084363517658517, 35.037705679289822, 0.0],
              [-81.084474918326492, 35.037614078646016, 0.0],
              [-81.084642318466805, 35.037156079308929, 0.0],
              [-81.084864984689403, 35.037201879284915, 0.0],
              [-81.085059784088841, 35.037385278848838, 0.0],
              [-81.085087718422955, 35.037568479431755, 0.0],
              [-81.0853659848745, 35.037866212625239, 0.0],
              [-81.085922984836998, 35.037866412171205, 0.0],
              [-81.086229384976335, 35.037614612597082, 0.0],
              [-81.08659158510649, 35.037087878710103, 0.0],
              [-81.086981318302733, 35.037294079357707, 0.0],
              [-81.08706471793225, 35.03754607863543, 0.0],
              [-81.086730385027607, 35.037958278782099, 0.0],
              [-81.086368118054139, 35.038645278857757, 0.0],
              [-81.086367984128017, 35.03901181183263, 0.0],
              [-81.086534917460099, 35.039286612764357, 0.0],
              [-81.086868918049788, 35.039538679396081, 0.0],
              [-81.087230917761374, 35.039493011822778, 0.0],
              [-81.087537385121863, 35.03921827876313, 0.0],
              [-81.087843717496597, 35.039126612766722, 0.0],
              [-81.087927518115876, 35.038645679178458, 0.0],
              [-81.088038918375474, 35.038554212383836, 0.0],
              [-81.088261718271426, 35.038622878681601, 0.0],
              [-81.088595784509991, 35.038875012486528, 0.0],
              [-81.089180517381266, 35.038989679363496, 0.0],
              [-81.089180517875945, 35.039218679116701, 0.0],
              [-81.088372718015506, 35.0395620124891, 0.0],
              [-81.088177584145569, 35.039859678679939, 0.0],
              [-81.087341918062023, 35.040340612562233, 0.0],
              [-81.087091184406844, 35.04066107911288, 0.0],
              [-81.087035184729899, 35.041073278779443, 0.0],
              [-81.087397117855645, 35.041439879400521, 0.0],
              [-81.088121118430308, 35.041692078733305, 0.0],
              [-81.088705984662994, 35.041623479059389, 0.0],
              [-81.089708784831586, 35.040913811958163, 0.0],
              [-81.090154384081401, 35.04075347850091, 0.0],
              [-81.090795117982751, 35.040272612143262, 0.0],
              [-81.091351985038088, 35.040272811919593, 0.0],
              [-81.0917977183435, 35.040135478977312, 0.0],
              [-81.092020518269763, 35.040250012442016, 0.0],
              [-81.092020318421902, 35.040501878757475, 0.0],
              [-81.091825318308409, 35.040731012055538, 0.0],
              [-81.090961718067391, 35.041372078738029, 0.0],
              [-81.090933784860809, 35.041669879043923, 0.0],
              [-81.091239984947521, 35.042013478657495, 0.0],
              [-81.091239784666044, 35.042288278916907, 0.0],
              [-81.091128385033514, 35.042379878856295, 0.0],
              [-81.090710784299816, 35.042356879278756, 0.0],
              [-81.090042384916799, 35.042173478821589, 0.0],
              [-81.089457584119444, 35.042287878791761, 0.0],
              [-81.089234718093579, 35.042562679194042, 0.0],
              [-81.089262384416742, 35.042906278552252, 0.0],
              [-81.089540717741144, 35.043227012368007, 0.0],
              [-81.089735718456168, 35.043318678975211, 0.0],
              [-81.091155784226743, 35.043456412063243, 0.0],
              [-81.091378517496111, 35.043593879032535, 0.0],
              [-81.091517584829546, 35.044258079394098, 0.0],
              [-81.091628917438555, 35.044349878473788, 0.0],
              [-81.092185917857094, 35.044395812333562, 0.0],
              [-81.09224138498395, 35.044968412558809, 0.0],
              [-81.092714784101005, 35.045312079399125, 0.0],
              [-81.093159785046453, 35.046274212228511, 0.0],
              [-81.093410385134277, 35.046503278731599, 0.0],
              [-81.09380031828762, 35.04648041252743, 0.0],
              [-81.09399531789586, 35.04627427844423, 0.0],
              [-81.094078984333791, 35.046045278606968, 0.0],
              [-81.094329717796512, 35.045953678966569, 0.0],
              [-81.09455238442122, 35.04620567933393, 0.0],
              [-81.094580117369603, 35.046640878511681, 0.0],
              [-81.094691384811526, 35.046778411817371, 0.0],
              [-81.095304184490942, 35.046709812053898, 0.0],
              [-81.095526984404813, 35.046801479344055, 0.0],
              [-81.095805385124365, 35.047099278840228, 0.0],
              [-81.096250917950556, 35.047007679255557, 0.0],
              [-81.096808117826015, 35.046595612448357, 0.0],
              [-81.096975317762187, 35.046297878775896, 0.0],
              [-81.097978117823018, 35.045702478774992, 0.0],
              [-81.098172984975179, 35.045794079054566, 0.0],
              [-81.098200584573789, 35.046687412364221, 0.0],
              [-81.096111317473515, 35.048382012105812, 0.0],
              [-81.095944118041771, 35.048679679248757, 0.0],
              [-81.096472984614522, 35.04916067940848, 0.0],
              [-81.097224984617924, 35.049092212074996, 0.0],
              [-81.097781984176308, 35.049275612463106, 0.0],
              [-81.098004584269361, 35.049573411811416, 0.0],
              [-81.09825531747417, 35.049573412044978, 0.0],
              [-81.098951784619359, 35.049344412731585, 0.0],
              [-81.099480784453576, 35.049344478465812, 0.0],
              [-81.099703717858077, 35.049092678779644, 0.0],
              [-81.100010184237334, 35.048909478734132, 0.0],
              [-81.100288584393567, 35.049184412152279, 0.0],
              [-81.100372118338626, 35.049573812395558, 0.0],
              [-81.101374385029033, 35.050490078843872, 0.0],
              [-81.101959317679984, 35.050788012310726, 0.0],
              [-81.102543984213554, 35.050788012108448, 0.0],
              [-81.103128984619474, 35.050467411899184, 0.0],
              [-81.103574584578297, 35.050559079216555, 0.0],
              [-81.10376938420157, 35.051040079310326, 0.0],
              [-81.103880917417129, 35.051154678587565, 0.0],
              [-81.104354317794545, 35.051040211768452, 0.0],
              [-81.10460498408014, 35.051063212087762, 0.0],
              [-81.104715984359956, 35.052551878456519, 0.0],
              [-81.104604584309229, 35.05303287940707, 0.0],
              [-81.104242384425618, 35.053445078867632, 0.0],
              [-81.104186584996413, 35.053651211799497, 0.0],
              [-81.104437184896909, 35.053811611879588, 0.0],
              [-81.104994184013009, 35.053903279019742, 0.0],
              [-81.105467784805953, 35.053743078593449, 0.0],
              [-81.105829917383488, 35.053880612310849, 0.0],
              [-81.106386784419072, 35.053697278680467, 0.0],
              [-81.106999784452626, 35.052758278525999, 0.0],
              [-81.107278384582003, 35.05255221274534, 0.0],
              [-81.108810384716918, 35.052506612701926, 0.0],
              [-81.109200184868939, 35.052346278536305, 0.0],
              [-81.110147317749238, 35.051544679319804, 0.0],
              [-81.110453717593913, 35.051636412226955, 0.0],
              [-81.110369984482602, 35.052048612712682, 0.0],
              [-81.109896517829199, 35.052644078798835, 0.0],
              [-81.109896517486348, 35.052804412180272, 0.0],
              [-81.1099799844079, 35.052873211969477, 0.0],
              [-81.10997998445302, 35.05307927931468, 0.0],
              [-81.109756984236014, 35.053262478715297, 0.0],
              [-81.109784918223909, 35.053445812504968, 0.0],
              [-81.11028631847806, 35.053445812454761, 0.0],
              [-81.110926917399738, 35.053216812011243, 0.0],
              [-81.111456185058103, 35.053331279338508, 0.0],
              [-81.111863585106121, 35.053314612199244, 0.0],
              [-81.112013184565129, 35.053308478539229, 0.0],
              [-81.112124384826274, 35.053491812437827, 0.0],
              [-81.112040984264098, 35.053720811820035, 0.0],
              [-81.110843184455689, 35.053858079359379, 0.0],
              [-81.110648317839335, 35.054018411859232, 0.0],
              [-81.110648184779691, 35.054407678936514, 0.0],
              [-81.110843117404784, 35.054774212634769, 0.0],
              [-81.110815317600142, 35.054911811884821, 0.0],
              [-81.110285984038455, 35.05500327882406, 0.0],
              [-81.1100353844446, 35.054728412634717, 0.0],
              [-81.109868384140043, 35.054041279326341, 0.0],
              [-81.10931138478206, 35.05358321222139, 0.0],
              [-81.108754384037411, 35.053331078695066, 0.0],
              [-81.108030318009511, 35.053216612066379, 0.0],
              [-81.107779584258054, 35.053308079124776, 0.0],
              [-81.10758451731563, 35.05369747851406, 0.0],
              [-81.107110984199551, 35.053972212435546, 0.0],
              [-81.106888117725504, 35.054453212434716, 0.0],
              [-81.106553784230897, 35.054590612752122, 0.0],
              [-81.106386518365852, 35.055323611812533, 0.0],
              [-81.106163717780589, 35.055506812642683, 0.0],
              [-81.105940984361226, 35.055415079404824, 0.0],
              [-81.105634717590846, 35.054980012420337, 0.0],
              [-81.105328317583684, 35.054888211929764, 0.0],
              [-81.104854784288335, 35.054956878523519, 0.0],
              [-81.104102784545958, 35.054911012353834, 0.0],
              [-81.10357371826484, 35.054315412218443, 0.0],
              [-81.103545917564873, 35.053811611938471, 0.0],
              [-81.103323184748049, 35.053582412727287, 0.0],
              [-81.103434784955496, 35.052620612490053, 0.0],
              [-81.103295585077802, 35.052276878985062, 0.0],
              [-81.103072917441239, 35.052093612460979, 0.0],
              [-81.10282231830962, 35.052024878773075, 0.0],
              [-81.101819584439383, 35.052093411817083, 0.0],
              [-81.101318384358095, 35.051635212401564, 0.0],
              [-81.100844917741099, 35.051474879138958, 0.0],
              [-81.100204318482, 35.051589278998406, 0.0],
              [-81.099730717306443, 35.051886878578465, 0.0],
              [-81.099201384619349, 35.052665678774062, 0.0],
              [-81.099117384264247, 35.054017011992947, 0.0],
              [-81.098783117782901, 35.054085612005082, 0.0],
              [-81.09858818496032, 35.053925278581794, 0.0],
              [-81.098448984170091, 35.053558812083168, 0.0],
              [-81.098087117695243, 35.053146478647363, 0.0],
              [-81.098143118115246, 35.052367679313299, 0.0],
              [-81.098282384122541, 35.051886812350944, 0.0],
              [-81.098310517710132, 35.051291212636137, 0.0],
              [-81.097976517414878, 35.050718612163635, 0.0],
              [-81.097725917708715, 35.050466478645028, 0.0],
              [-81.097335984718342, 35.050352012533025, 0.0],
              [-81.096862517714612, 35.050374679262177, 0.0],
              [-81.096611718281949, 35.05069547934135, 0.0],
              [-81.096388984682136, 35.050764078575455, 0.0],
              [-81.095832117507058, 35.050489079308939, 0.0],
              [-81.093993784113991, 35.050374278907, 0.0],
              [-81.093826584247708, 35.050672011936385, 0.0],
              [-81.093492518126126, 35.050671878802362, 0.0],
              [-81.093297518364096, 35.050511479051607, 0.0],
              [-81.093047117545453, 35.050030411883156, 0.0],
              [-81.092852184821922, 35.049847212449976, 0.0],
              [-81.091933117614445, 35.049572078741285, 0.0],
              [-81.091626718397563, 35.049732412539498, 0.0],
              [-81.091403585150388, 35.050534012507867, 0.0],
              [-81.091347318058112, 35.052160211951119, 0.0],
              [-81.091068384893887, 35.052916012712672, 0.0],
              [-81.091012517768164, 35.053397012613928, 0.0],
              [-81.090900984365305, 35.053580212117012, 0.0],
              [-81.090622518415074, 35.05360301271439, 0.0],
              [-81.090371984535977, 35.053328012005977, 0.0],
              [-81.090316984675297, 35.051656078511336, 0.0],
              [-81.090233517354463, 35.051381212739727, 0.0],
              [-81.090373317632256, 35.05032761214526, 0.0],
              [-81.090206917903984, 35.048609611816389, 0.0],
              [-81.089705917538041, 35.047739279266558, 0.0],
              [-81.088981985029861, 35.047189278931839, 0.0],
              [-81.088870584997565, 35.047051879003973, 0.0],
              [-81.088759584503777, 35.046433478734826, 0.0],
              [-81.088481384438296, 35.045723412402246, 0.0],
              [-81.087813184541446, 35.045173412004374, 0.0],
              [-81.087534718285255, 35.045196279011066, 0.0],
              [-81.08722798401871, 35.045906278521201, 0.0],
              [-81.087144318208857, 35.046501812593711, 0.0],
              [-81.087338717440545, 35.047440878971749, 0.0],
              [-81.08756131750674, 35.047944812701445, 0.0],
              [-81.087728117736077, 35.048654878712931, 0.0],
              [-81.087699984716593, 35.049113011962369, 0.0],
              [-81.087476917871996, 35.049777078940217, 0.0],
              [-81.087142584658224, 35.050235211991392, 0.0],
              [-81.086696718275959, 35.050578478744107, 0.0],
              [-81.086334118039886, 35.051655011910256, 0.0],
              [-81.08613918449673, 35.051746611915313, 0.0],
              [-81.08586071849615, 35.051609078915057, 0.0],
              [-81.08588891756709, 35.051059412641663, 0.0],
              [-81.08600038495382, 35.050830412222702, 0.0],
              [-81.085972718380347, 35.050463878613165, 0.0],
              [-81.08566638497247, 35.050189011789548, 0.0],
              [-81.085666518219512, 35.050005811993913, 0.0],
              [-81.085917317902258, 35.049731011849637, 0.0],
              [-81.086725184222175, 35.049318879276306, 0.0],
              [-81.086808984997162, 35.048906612050708, 0.0],
              [-81.086558384311274, 35.048631678932018, 0.0],
              [-81.086308185008505, 35.047807078602631, 0.0],
              [-81.086057584440624, 35.047669479014523, 0.0],
              [-81.084804317840124, 35.047577612525245, 0.0],
              [-81.084804384041846, 35.047188279417391, 0.0],
              [-81.085389584212464, 35.046615678983322, 0.0],
              [-81.085501184677824, 35.046272211864952, 0.0],
              [-81.085473517937885, 35.045745412484266, 0.0],
              [-81.085334517538939, 35.045493411781919, 0.0],
              [-81.085362517824265, 35.045104012330647, 0.0],
              [-81.085474117738443, 35.044875011825361, 0.0],
              [-81.085362784010044, 35.044394012248063, 0.0],
              [-81.085084384316531, 35.044233612737628, 0.0],
              [-81.084248984829671, 35.044073079205219, 0.0],
              [-81.083580984870608, 35.04356901230387, 0.0],
              [-81.083107518385958, 35.043568812115076, 0.0],
              [-81.083023518197706, 35.044507812747447, 0.0],
              [-81.082911984312389, 35.044713878728125, 0.0],
              [-81.082382584793223, 35.045034479052262, 0.0],
              [-81.082270917427749, 35.045790279167349, 0.0],
              [-81.081964184176726, 35.046408612413195, 0.0],
              [-81.081602117622396, 35.046454278547138, 0.0],
              [-81.08146291848881, 35.046316878765495, 0.0],
              [-81.081491184241557, 35.045492279253921, 0.0],
              [-81.081602784244609, 35.045217478734401, 0.0],
              [-81.081575318514609, 35.044392879127052, 0.0],
              [-81.081352717352473, 35.044209611933077, 0.0],
              [-81.080684318137585, 35.044278212397408, 0.0],
              [-81.080043918053363, 35.043888479167329, 0.0],
              [-81.079570917972092, 35.043109612407974, 0.0],
              [-81.079236718235748, 35.043109479171513, 0.0],
              [-81.078846584914274, 35.043521679023769, 0.0],
              [-81.078373184192657, 35.043475679046146, 0.0],
              [-81.077844118248152, 35.043544211978116, 0.0],
              [-81.077509518269991, 35.044185412469758, 0.0],
              [-81.07748138449108, 35.044780878880481, 0.0],
              [-81.076896385093335, 35.044964012733644, 0.0],
              [-81.076812718229021, 35.04512427895682, 0.0],
              [-81.076783918523631, 35.04679627925379, 0.0],
              [-81.076672384334657, 35.046910812536929, 0.0],
              [-81.07647751800323, 35.04691067881884, 0.0],
              [-81.076338317648933, 35.046796078944062, 0.0],
              [-81.076310717928791, 35.046315212345093, 0.0],
              [-81.075865318005128, 35.045902678498585, 0.0],
              [-81.075977117708064, 35.045330079289364, 0.0],
              [-81.075949384758061, 35.045032412086876, 0.0],
              [-81.075865917483611, 35.044986478890252, 0.0],
              [-81.076061584342455, 35.043841412297837, 0.0],
              [-81.075838917542498, 35.043589279190577, 0.0],
              [-81.074892117756775, 35.043589012279483, 0.0],
              [-81.074418517700494, 35.043795012078569, 0.0],
              [-81.073833585101994, 35.043863479029554, 0.0],
              [-81.073749918066767, 35.044000879195963, 0.0],
              [-81.073749718044965, 35.044390278947262, 0.0],
              [-81.073666184656688, 35.044481811832227, 0.0],
              [-81.072969784335939, 35.0446418786984, 0.0],
              [-81.07283058476041, 35.044573078605794, 0.0],
              [-81.072691517925122, 35.044298212152007, 0.0],
              [-81.072329584657979, 35.044343878857312, 0.0],
              [-81.072217985178227, 35.044550012598393, 0.0],
              [-81.071744517357686, 35.044572678991955, 0.0],
              [-81.071660984810123, 35.044504012430316, 0.0],
              [-81.071271118398727, 35.044503678690361, 0.0],
              [-81.071075785200819, 35.044938879331788, 0.0],
              [-81.071075317655101, 35.045763412097308, 0.0],
              [-81.070852317525393, 35.046038212098374, 0.0],
              [-81.070852184977369, 35.046244412389527, 0.0],
              [-81.071158384900244, 35.046496411810054, 0.0],
              [-81.071213984239975, 35.046794212412451, 0.0],
              [-81.071130184450269, 35.046954478697636, 0.0],
              [-81.07062891845203, 35.047045878444614, 0.0],
              [-81.070517385032844, 35.047160411791289, 0.0],
              [-81.070544918482696, 35.047687212195825, 0.0],
              [-81.071017518080325, 35.048901412156347, 0.0],
              [-81.070989318076954, 35.049496811928357, 0.0],
              [-81.07087778412253, 35.049657079147821, 0.0],
              [-81.070654984699459, 35.049679879356582, 0.0],
              [-81.070515784128347, 35.049565412006345, 0.0],
              [-81.070460384427122, 35.049130079307496, 0.0],
              [-81.070209917827697, 35.04887807933838, 0.0],
              [-81.069931517663107, 35.048740478461355, 0.0],
              [-81.069932117829126, 35.047847279321871, 0.0],
              [-81.06959811839144, 35.047503479059245, 0.0],
              [-81.06934791751047, 35.046999479127265, 0.0],
              [-81.068902318422019, 35.046999412171523, 0.0],
              [-81.068623584706714, 35.047090878818885, 0.0],
              [-81.067788184804428, 35.04699901244998, 0.0],
              [-81.067899784913791, 35.046724079417743, 0.0],
              [-81.068847118363834, 35.046266412212901, 0.0],
              [-81.068847184193558, 35.046129011916157, 0.0],
              [-81.068513118320212, 35.045922812378471, 0.0],
              [-81.068541118023688, 35.045693679275715, 0.0],
              [-81.068736118188269, 35.045533412421008, 0.0],
              [-81.068764184863426, 35.045281479383242, 0.0],
              [-81.069321384383343, 35.044961078876071, 0.0],
              [-81.069432917776012, 35.044755012247073, 0.0],
              [-81.06929418511595, 35.043930412207061, 0.0],
              [-81.068960184879231, 35.043724078660887, 0.0],
              [-81.068486718498164, 35.043632278879549, 0.0],
              [-81.068124984736059, 35.043334412364921, 0.0],
              [-81.067261718091189, 35.043334012066381, 0.0],
              [-81.06729018434585, 35.042395012530925, 0.0],
              [-81.067067384023844, 35.042211612486462, 0.0],
              [-81.066677717445756, 35.042119812218765, 0.0],
              [-81.066483118352053, 35.041753278975882, 0.0],
              [-81.066232517582989, 35.041592812700884, 0.0],
              [-81.066121517651084, 35.040997211799059, 0.0],
              [-81.066010317377319, 35.040882612442154, 0.0],
              [-81.065620184095806, 35.040974212111983, 0.0],
              [-81.064951517679532, 35.041523478700022, 0.0],
              [-81.064728717924851, 35.041523478525903, 0.0],
              [-81.064589517913504, 35.041408812185068, 0.0],
              [-81.064868584967456, 35.04053861266901, 0.0],
              [-81.064868784660732, 35.04024087905438, 0.0],
              [-81.064173117525073, 35.039599211998983, 0.0],
              [-81.064229118393314, 35.03937021182621, 0.0],
              [-81.064396185197893, 35.039301612537869, 0.0],
              [-81.06503658421282, 35.039393478764246, 0.0],
              [-81.065398717432984, 35.039370812436964, 0.0],
              [-81.065510117440837, 35.039279078743725, 0.0],
              [-81.065593917473393, 35.039004278979874, 0.0],
              [-81.065371317527649, 35.038592012070389, 0.0],
              [-81.064536117911075, 35.038316678621044, 0.0],
              [-81.064480717353902, 35.037973212212187, 0.0],
              [-81.064285918224243, 35.037858479294897, 0.0],
              [-81.063812585200026, 35.037858278678961, 0.0],
              [-81.063701184423081, 35.037766678761706, 0.0],
              [-81.063896384642803, 35.037285678859838, 0.0],
              [-81.063840918257966, 35.037079479235892, 0.0],
              [-81.063367517938758, 35.037102212004513, 0.0],
              [-81.062420318008009, 35.037605678763121, 0.0],
              [-81.062057784381039, 35.038155278932642, 0.0],
              [-81.06175131770452, 35.038452878813487, 0.0],
              [-81.06169531753477, 35.038842212667774, 0.0],
              [-81.061500318188223, 35.038956612344819, 0.0],
              [-81.060525584643898, 35.039024878936011, 0.0],
              [-81.06002418450538, 35.039368212304986, 0.0],
              [-81.059077318049361, 35.039230279271784, 0.0],
              [-81.058770985090533, 35.039321812497676, 0.0],
              [-81.058380784265708, 35.039665079303383, 0.0],
              [-81.058102384410574, 35.039665012678071, 0.0],
              [-81.058130584387754, 35.039275612181861, 0.0],
              [-81.058241984327182, 35.039138211994384, 0.0],
              [-81.058242184900706, 35.038794679090906, 0.0],
              [-81.058075384908136, 35.038680078469532, 0.0],
              [-81.058075585075485, 35.038428211985376, 0.0],
              [-81.058799718548741, 35.038222412685975, 0.0],
              [-81.059969184191687, 35.038360412652054, 0.0],
              [-81.060192184389933, 35.038200212429565, 0.0],
              [-81.060387384348601, 35.037765078476475, 0.0],
              [-81.060303984507172, 35.037513078961389, 0.0],
              [-81.059997784154561, 35.037352611831352, 0.0],
              [-81.060025985021284, 35.037054879058402, 0.0],
              [-81.060276584990206, 35.03694047884251, 0.0],
              [-81.061084317836062, 35.036895079049557, 0.0],
              [-81.061334984876211, 35.036574478638599, 0.0],
              [-81.06130751773857, 35.036185078526238, 0.0],
              [-81.061029317996471, 35.036047479183715, 0.0],
              [-81.061057184541326, 35.035841412344837, 0.0],
              [-81.061196518300321, 35.035704012715122, 0.0],
              [-81.062254984755697, 35.035292279192973, 0.0],
              [-81.062700918152331, 35.034811479204244, 0.0],
              [-81.062701118069754, 35.034513679435825, 0.0],
              [-81.062506317771735, 35.03444501194442, 0.0],
              [-81.061809984730459, 35.034605012552483, 0.0],
              [-81.061559384789234, 35.034490278462052, 0.0],
              [-81.061448317439897, 35.034215411836456, 0.0],
              [-81.06111451800038, 35.033963278932688, 0.0],
              [-81.060696784555191, 35.033963011883195, 0.0],
              [-81.060278784148963, 35.034214878675108, 0.0],
              [-81.060000117668139, 35.034512479332179, 0.0],
              [-81.059582184311964, 35.034741279304939, 0.0],
              [-81.059248118462335, 35.034718212374059, 0.0],
              [-81.05916491793262, 35.034283011942343, 0.0],
              [-81.058663584150935, 35.03428281238152, 0.0],
              [-81.058524384508175, 35.034191212340616, 0.0],
              [-81.058608318399408, 35.033778879102059, 0.0],
              [-81.059583718292075, 35.032909011864085, 0.0],
              [-81.059695184906118, 35.032680012486239, 0.0],
              [-81.059639784541673, 35.032313478929552, 0.0],
              [-81.059528584912641, 35.032153079084097, 0.0],
              [-81.058442585186214, 35.03217547856341, 0.0],
              [-81.058220117979744, 35.031969212059337, 0.0],
              [-81.058164784138512, 35.031579811975007, 0.0],
              [-81.058081317958496, 35.031419412488049, 0.0],
              [-81.057468917563384, 35.031098412645036, 0.0],
              [-81.057469584407002, 35.030251011871094, 0.0],
              [-81.057386184717217, 35.030205212148701, 0.0],
              [-81.057275518309837, 35.029311811817884, 0.0],
              [-81.057025318401884, 35.028784879425196, 0.0],
              [-81.056663518060986, 35.028601412711645, 0.0],
              [-81.056329318197783, 35.028692878602293, 0.0],
              [-81.055576318407574, 35.030158411980743, 0.0],
              [-81.05560378503003, 35.030616478593707, 0.0],
              [-81.056020717488138, 35.031487078899943, 0.0],
              [-81.056131718001382, 35.031922279304567, 0.0],
              [-81.05610331753094, 35.03265527858504, 0.0],
              [-81.055796184348765, 35.033456678975575, 0.0],
              [-81.05557351802328, 35.033594078703103, 0.0],
              [-81.055406384978482, 35.033548211802646, 0.0],
              [-81.055323117860169, 35.03325027887437, 0.0],
              [-81.05532378454663, 35.032471612204951, 0.0],
              [-81.055018318339592, 35.031532412707762, 0.0],
              [-81.054907117526724, 35.031349012740449, 0.0],
              [-81.054544984230745, 35.031394612629562, 0.0],
              [-81.054210717813362, 35.031554879085675, 0.0],
              [-81.053987918379477, 35.03150901252738, 0.0],
              [-81.053793184851358, 35.031325612363531, 0.0],
              [-81.053375584643305, 35.031279612041828, 0.0],
              [-81.053208584126111, 35.031164879124525, 0.0],
              [-81.053069917375609, 35.030775412767433, 0.0],
              [-81.052847117705568, 35.030660812617541, 0.0],
              [-81.052345917659849, 35.030729211839898, 0.0],
              [-81.052122984416769, 35.030866612766268, 0.0],
              [-81.052094785101559, 35.031256012721933, 0.0],
              [-81.051927585081785, 35.031393278594528, 0.0],
              [-81.05128698477337, 35.031507412726278, 0.0],
              [-81.051091784827236, 35.031873812002289, 0.0],
              [-81.05084118502171, 35.031873611807242, 0.0],
              [-81.050618585169005, 35.031781812687846, 0.0],
              [-81.050507317430217, 35.031598612270187, 0.0],
              [-81.050229117847351, 35.031484012196643, 0.0],
              [-81.049783384811093, 35.031598212104853, 0.0],
              [-81.04947658480333, 35.032079012373735, 0.0],
              [-81.049225985191171, 35.032147479099528, 0.0],
              [-81.048279718280781, 35.031574478557587, 0.0],
              [-81.047778584346446, 35.031551211965862, 0.0],
              [-81.047694917794246, 35.03171147918394, 0.0],
              [-81.047778184343812, 35.031986412039842, 0.0],
              [-81.048556585156547, 35.033269612510523, 0.0],
              [-81.048973984240064, 35.03359041183878, 0.0],
              [-81.049419384945196, 35.033796812033813, 0.0],
              [-81.04941931847074, 35.033888412350734, 0.0],
              [-81.049753317683411, 35.034163412696422, 0.0],
              [-81.050365584723167, 35.034347012498536, 0.0],
              [-81.051005717949451, 35.034691012031651, 0.0],
              [-81.051757384691541, 35.034943279181753, 0.0],
              [-81.05184058483664, 35.035286878895249, 0.0],
              [-81.050364918152297, 35.035171611994684, 0.0],
              [-81.048499717758816, 35.034620812520394, 0.0],
              [-81.048082184617257, 35.034414412586607, 0.0],
              [-81.046858185187943, 35.033222611892327, 0.0],
              [-81.046329317499115, 35.032993278759449, 0.0],
              [-81.045994984924903, 35.033084812476751, 0.0],
              [-81.045938784667641, 35.033634412735097, 0.0],
              [-81.046829117453257, 35.034482412008032, 0.0],
              [-81.047858918342513, 35.035009812615485, 0.0],
              [-81.048972384421617, 35.035354012730657, 0.0],
              [-81.049668117531581, 35.035858412506116, 0.0],
              [-81.049779318360038, 35.036041611862757, 0.0],
              [-81.049751317689825, 35.036270612711256, 0.0],
              [-81.048720785199194, 35.036407478698457, 0.0],
              [-81.048553718120132, 35.036338679178328, 0.0],
              [-81.048498384910829, 35.03601801192341, 0.0],
              [-81.048220117409571, 35.035857479366818, 0.0],
              [-81.047802384932766, 35.035765611788683, 0.0],
              [-81.047495984209945, 35.03585707909982, 0.0],
              [-81.047439718315957, 35.036589879410364, 0.0],
              [-81.047244317873847, 35.037002078687273, 0.0],
              [-81.047327317937061, 35.037574812597398, 0.0],
              [-81.047131984996824, 35.037964078608518, 0.0],
              [-81.047131784801337, 35.038239012542398, 0.0],
              [-81.047437984232772, 35.038376479434888, 0.0],
              [-81.048134184227777, 35.03839981267609, 0.0],
              [-81.048886384745259, 35.038056812027534, 0.0],
              [-81.049304117436435, 35.038056878550094, 0.0],
              [-81.049582318034922, 35.038309079087156, 0.0],
              [-81.049582118104013, 35.038515212640441, 0.0],
              [-81.049832384384601, 35.038721412482744, 0.0],
              [-81.049887517536106, 35.039362811809475, 0.0],
              [-81.050026717933264, 35.039546079106778, 0.0],
              [-81.05086178427598, 35.039913079188679, 0.0],
              [-81.050833784391557, 35.0400734119318, 0.0],
              [-81.050638785030557, 35.040210679217175, 0.0],
              [-81.050415984246897, 35.040210612242326, 0.0],
              [-81.04972011834839, 35.039820812698004, 0.0],
              [-81.048745784515333, 35.039568278453757, 0.0],
              [-81.047965784158364, 35.039659412515654, 0.0],
              [-81.047520118236491, 35.039888211910963, 0.0],
              [-81.047408318136306, 35.040254679411667, 0.0],
              [-81.048215584236374, 35.040644478486804, 0.0],
              [-81.04910678515121, 35.040530478978219, 0.0],
              [-81.049218185200132, 35.04062227864766, 0.0],
              [-81.049273584804553, 35.040897078720285, 0.0],
              [-81.049607385164478, 35.04117221240589, 0.0],
              [-81.050108517543222, 35.04142427880695, 0.0],
              [-81.050748984577254, 35.041516278882519, 0.0],
              [-81.05219711779138, 35.041471278909931, 0.0],
              [-81.052531318184279, 35.041334012133156, 0.0],
              [-81.053505918073625, 35.041472012317008, 0.0],
              [-81.053812184285391, 35.04163247898525, 0.0],
              [-81.053811918180543, 35.0419532119571, 0.0],
              [-81.052920784982589, 35.04186107913246, 0.0],
              [-81.052168917761293, 35.041952278579373, 0.0],
              [-81.050998918295178, 35.04236387938581, 0.0],
              [-81.050636584064932, 35.042661478673281, 0.0],
              [-81.050664118482985, 35.04293627892752, 0.0],
              [-81.050998117946335, 35.043211412456067, 0.0],
              [-81.050997784527667, 35.043394612436757, 0.0],
              [-81.050830717711321, 35.043509012116637, 0.0],
              [-81.049828518012603, 35.043096278580002, 0.0],
              [-81.049661718440007, 35.042752612403014, 0.0],
              [-81.049132585002042, 35.042752279043192, 0.0],
              [-81.048352585033044, 35.04304947895281, 0.0],
              [-81.04762851762635, 35.043049079255759, 0.0],
              [-81.047683918041884, 35.043324012148183, 0.0],
              [-81.048045584399773, 35.04371347866747, 0.0],
              [-81.048239984492525, 35.044354879303334, 0.0],
              [-81.048406917509823, 35.044492479243381, 0.0],
              [-81.049771518118249, 35.044539212661938, 0.0],
              [-81.049910517701335, 35.044768279153907, 0.0],
              [-81.049882384725478, 35.045020211904848, 0.0],
              [-81.048851917939189, 35.045157012746046, 0.0],
              [-81.048517584073281, 35.045271412363476, 0.0],
              [-81.04837818519789, 35.045431612092855, 0.0],
              [-81.048378118503067, 35.045569011815388, 0.0],
              [-81.04860078435523, 35.045752412490671, 0.0],
              [-81.048711584803584, 35.046302079031989, 0.0],
              [-81.048961984049129, 35.046508411847903, 0.0],
              [-81.048989317383942, 35.047103878537619, 0.0],
              [-81.049239917541684, 35.047195678479255, 0.0],
              [-81.049546184718409, 35.047150079401547, 0.0],
              [-81.049964517524828, 35.046692278980913, 0.0],
              [-81.050549118507774, 35.046852878474169, 0.0],
              [-81.050771984502262, 35.046830078675328, 0.0],
              [-81.05146891769833, 35.046189212407107, 0.0],
              [-81.05152471771153, 35.046006012070613, 0.0],
              [-81.051775517667025, 35.045800011968801, 0.0],
              [-81.05197038516269, 35.045823012748066, 0.0],
              [-81.052137384094323, 35.045983479189701, 0.0],
              [-81.052109317663366, 35.046258279115676, 0.0],
              [-81.050771584147228, 35.04724227941616, 0.0],
              [-81.050409118047838, 35.047723212096841, 0.0],
              [-81.050408785013701, 35.048089679190475, 0.0],
              [-81.050603718351923, 35.048204279229253, 0.0],
              [-81.051327917902441, 35.04811307926839, 0.0],
              [-81.051829584387903, 35.047540678944856, 0.0],
              [-81.05299978501418, 35.046945878687062, 0.0],
              [-81.053501718373028, 35.0463506787017, 0.0],
              [-81.053780384440032, 35.046167612693459, 0.0],
              [-81.054253984096647, 35.046053279416768, 0.0],
              [-81.054699984594322, 35.045549611860174, 0.0],
              [-81.055034184837041, 35.045458212031782, 0.0],
              [-81.055312517866426, 35.045641611906468, 0.0],
              [-81.055534917404131, 35.046076879182152, 0.0],
              [-81.056425984346106, 35.046214811997011, 0.0],
              [-81.056565185193847, 35.046329412626889, 0.0],
              [-81.056592784954049, 35.046672878992482, 0.0],
              [-81.055562118262927, 35.046878611926424, 0.0],
              [-81.055172318529273, 35.046741012605992, 0.0],
              [-81.054698918438646, 35.046809279020088, 0.0],
              [-81.054364518399865, 35.046946612036258, 0.0],
              [-81.054057918470178, 35.047244279198353, 0.0],
              [-81.053695317795714, 35.047885411843019, 0.0],
              [-81.053694918265791, 35.048297678902806, 0.0],
              [-81.054168117471789, 35.048664411835752, 0.0],
              [-81.05427931735187, 35.048916278523613, 0.0],
              [-81.054167784698322, 35.049053678807987, 0.0],
              [-81.05397278510695, 35.049053612584693, 0.0],
              [-81.053137518487006, 35.048755411767296, 0.0],
              [-81.05266411768099, 35.048778078636417, 0.0],
              [-81.052301784724207, 35.048961079336337, 0.0],
              [-81.052190185210577, 35.049167212241599, 0.0],
              [-81.052050717783857, 35.049510678666081, 0.0],
              [-81.051382317547407, 35.049533079317605, 0.0],
              [-81.050992317820032, 35.04964747929354, 0.0],
              [-81.050824984938671, 35.04980767878132, 0.0],
              [-81.050880385124415, 35.050128411785721, 0.0],
              [-81.051297584450452, 35.050792812103268, 0.0],
              [-81.05199338491181, 35.05129707862379, 0.0],
              [-81.052605918500589, 35.051572279352428, 0.0],
              [-81.05352491816366, 35.051733212310523, 0.0],
              [-81.054221184322344, 35.051733611966789, 0.0],
              [-81.054332584919308, 35.051802278930168, 0.0],
              [-81.05427658431644, 35.052077212293149, 0.0],
              [-81.053579918464692, 35.052512012610492, 0.0],
              [-81.053384518045817, 35.052992812289915, 0.0],
              [-81.053133584086936, 35.053244679262079, 0.0],
              [-81.053133518325481, 35.053405078927469, 0.0],
              [-81.053300584293268, 35.05347381247018, 0.0],
              [-81.053857718322263, 35.053359611811601, 0.0],
              [-81.054358918214646, 35.053428479175999, 0.0],
              [-81.054776784031574, 35.053337211915995, 0.0],
              [-81.055055517645073, 35.053062479045494, 0.0],
              [-81.055724318253539, 35.052765012556961, 0.0],
              [-81.056560718169692, 35.051803612100578, 0.0],
              [-81.056923184099546, 35.051208278825037, 0.0],
              [-81.057313384653355, 35.050910612517598, 0.0],
              [-81.057814584487119, 35.050910878946929, 0.0],
              [-81.058511184314341, 35.050613479094011, 0.0],
              [-81.058706184282855, 35.050659412646468, 0.0],
              [-81.058622317545229, 35.050980012373621, 0.0],
              [-81.057925718027036, 35.051369011992143, 0.0],
              [-81.057507584228262, 35.051758078642102, 0.0],
              [-81.057228784053365, 35.052170412704243, 0.0],
              [-81.057144918164269, 35.052582612348985, 0.0],
              [-81.057255718356643, 35.053407278473237, 0.0],
              [-81.057171918360879, 35.053682012098967, 0.0],
              [-81.05739431848221, 35.053934078801412, 0.0],
              [-81.057839917754734, 35.054094479022119, 0.0],
              [-81.057839717511982, 35.054300678730947, 0.0],
              [-81.057532984190601, 35.054644212333486, 0.0],
              [-81.057532784587579, 35.054942012138049, 0.0],
              [-81.058423584441925, 35.055629478986461, 0.0],
              [-81.058144584705317, 35.056179012697434, 0.0],
              [-81.058199984099474, 35.056660012091839, 0.0],
              [-81.058506118171564, 35.056935078941208, 0.0],
              [-81.058895984595154, 35.056958078891313, 0.0],
              [-81.059146517713629, 35.057278878630214, 0.0],
              [-81.05998211847438, 35.057302278692276, 0.0],
              [-81.060121317756654, 35.057371078897965, 0.0],
              [-81.060093317957921, 35.057554278464892, 0.0],
              [-81.059619518455435, 35.057897679225654, 0.0],
              [-81.05922938515026, 35.058034812395569, 0.0],
              [-81.058532917982646, 35.058103212070868, 0.0],
              [-81.058282184552752, 35.058309212616827, 0.0],
              [-81.058253984138162, 35.058721479009115, 0.0],
              [-81.058921984889082, 35.059294412163354, 0.0],
              [-81.059089117542712, 35.059340278960654, 0.0],
              [-81.05978571844129, 35.059065879018291, 0.0],
              [-81.060426184535714, 35.059066212230682, 0.0],
              [-81.061345385149068, 35.059249812378617, 0.0],
              [-81.061958184603625, 35.059135612110794, 0.0],
              [-81.063769184472406, 35.058518011898137, 0.0],
              [-81.064243385220351, 35.05767081265374, 0.0],
              [-81.064410584526087, 35.057579211874263, 0.0],
              [-81.064800517789379, 35.057671012022801, 0.0],
              [-81.065190117700809, 35.058152078462619, 0.0],
              [-81.065496318327803, 35.058335612504941, 0.0],
              [-81.066053585077114, 35.058083878724517, 0.0],
              [-81.066165184454675, 35.057854878876526, 0.0],
              [-81.066109718240185, 35.057465478864422, 0.0],
              [-81.065998384723429, 35.057328011866595, 0.0],
              [-81.066054384219782, 35.056892878966757, 0.0],
              [-81.066528117688506, 35.056572412024977, 0.0],
              [-81.066667584272409, 35.056251678632755, 0.0],
              [-81.066667984229554, 35.055702011780163, 0.0],
              [-81.0668633179084, 35.055175278496264, 0.0],
              [-81.066891584104212, 35.054625679121898, 0.0],
              [-81.067002984389688, 35.054511211952757, 0.0],
              [-81.067783117938134, 35.054328412681613, 0.0],
              [-81.067977984452426, 35.054443012062393, 0.0],
              [-81.067642984784285, 35.055542212550755, 0.0],
              [-81.067614784193822, 35.056069012323285, 0.0],
              [-81.067698118156443, 35.056504212716163, 0.0],
              [-81.067613584462904, 35.057763878827984, 0.0],
              [-81.066609384727869, 35.059801878613243, 0.0],
              [-81.066135784217522, 35.059939078534278, 0.0],
              [-81.065801184104501, 35.060351212053796, 0.0],
              [-81.06521611774113, 35.060603012379815, 0.0],
              [-81.06507671768469, 35.060786212487578, 0.0],
              [-81.065076517472136, 35.061038078766657, 0.0],
              [-81.06527138406851, 35.06126721178061, 0.0],
              [-81.065549784241796, 35.061404678756013, 0.0],
              [-81.066970584598067, 35.061130611934203, 0.0],
              [-81.06708198436111, 35.061176412564606, 0.0],
              [-81.066858917370155, 35.061565678846939, 0.0],
              [-81.066858518310426, 35.062023679003751, 0.0],
              [-81.067192518177237, 35.062436212725949, 0.0],
              [-81.067219917968146, 35.063054612326681, 0.0],
              [-81.067442584953028, 35.063169278505761, 0.0],
              [-81.067832784810662, 35.063054879081278, 0.0],
              [-81.068167185196899, 35.062780079239701, 0.0],
              [-81.068445717659841, 35.062803079277536, 0.0],
              [-81.068417584044042, 35.06316961205858, 0.0],
              [-81.067608984159222, 35.064314479393929, 0.0],
              [-81.067525184684357, 35.064612278477384, 0.0],
              [-81.067580917902902, 35.064703878431722, 0.0],
              [-81.067942984082649, 35.064681212524739, 0.0],
              [-81.068193984605628, 35.064429279219468, 0.0],
              [-81.068444984104232, 35.063902478502953, 0.0],
              [-81.068863117387352, 35.063559211793894, 0.0],
              [-81.069587518172298, 35.063170078433181, 0.0],
              [-81.06978251833668, 35.063170278880378, 0.0],
              [-81.070200118322859, 35.063559678720971, 0.0],
              [-81.070172118357405, 35.063743012105334, 0.0],
              [-81.069280718376362, 35.064017478507985, 0.0],
              [-81.068946184324716, 35.064292079391883, 0.0],
              [-81.068945984312734, 35.064567011880769, 0.0],
              [-81.069391318279855, 35.064910812574809, 0.0],
              [-81.069419118042617, 35.065094079303947, 0.0],
              [-81.070115317381152, 35.065460812198666, 0.0],
              [-81.070198584788542, 35.065896079065055, 0.0],
              [-81.070922584089473, 35.066285611952011, 0.0],
              [-81.07120078443215, 35.066881279322494, 0.0],
              [-81.07147918410017, 35.067064612629714, 0.0],
              [-81.071869318003806, 35.06704187882341, 0.0],
              [-81.072092317737074, 35.06690447843652, 0.0],
              [-81.072092384820465, 35.066606812591615, 0.0],
              [-81.071980984331717, 35.066561012500983, 0.0],
              [-81.071897718209527, 35.066125678607477, 0.0],
              [-81.071257318018453, 35.065644478728103, 0.0],
              [-81.071341118001513, 35.065323879173349, 0.0],
              [-81.072093318188323, 35.065324212725102, 0.0],
              [-81.072232784803944, 35.064934878686778, 0.0],
              [-81.072344184361555, 35.064843278549276, 0.0],
              [-81.072511384595956, 35.064866278863406, 0.0],
              [-81.072399317456401, 35.065919811843543, 0.0],
              [-81.072649718235226, 35.066126012255005, 0.0],
              [-81.073151184070525, 35.066057412382044, 0.0],
              [-81.07340218486793, 35.065828478933227, 0.0],
              [-81.073708517556511, 35.06587447901849, 0.0],
              [-81.073819917765306, 35.065966079172561, 0.0],
              [-81.073958917564767, 35.066286812327128, 0.0],
              [-81.074237518174712, 35.066287012197535, 0.0],
              [-81.074571918092133, 35.066149612372783, 0.0],
              [-81.074628185085984, 35.065302212285083, 0.0],
              [-81.074795318442739, 35.065210679320685, 0.0],
              [-81.075379985107006, 35.065714812357122, 0.0],
              [-81.075407784912571, 35.06589801180867, 0.0],
              [-81.075574918203074, 35.066035479198213, 0.0],
              [-81.077134717419653, 35.066059012230049, 0.0],
              [-81.077580384568421, 35.066173612341778, 0.0],
              [-81.077635985004704, 35.066356812516503, 0.0],
              [-81.077385117369971, 35.066654612556924, 0.0],
              [-81.077357184976989, 35.066814878535503, 0.0],
              [-81.076354384393937, 35.066745812330865, 0.0],
              [-81.0760479177153, 35.066906012068088, 0.0],
              [-81.075462984588953, 35.066768412482773, 0.0],
              [-81.07504498517828, 35.066859879039256, 0.0],
              [-81.074822117563684, 35.067020078426275, 0.0],
              [-81.073735718045072, 35.067042612434882, 0.0],
              [-81.073317717831756, 35.06727141176011, 0.0],
              [-81.073150384946217, 35.06745467852803, 0.0],
              [-81.073149984702113, 35.068004412563347, 0.0],
              [-81.073372584905954, 35.068371012291237, 0.0],
              [-81.073344318353236, 35.06919547906196, 0.0],
              [-81.073539184686595, 35.069355878565908, 0.0],
              [-81.074319318531977, 35.069356212524589, 0.0],
              [-81.074514117897294, 35.069447879087939, 0.0],
              [-81.074848317727145, 35.069791612083279, 0.0],
              [-81.075433383997236, 35.069791678458685, 0.0],
              [-81.076213518143561, 35.069494212034385, 0.0],
              [-81.076324785169206, 35.069540078701351, 0.0],
              [-81.076268784803489, 35.070112678765938, 0.0],
              [-81.076575184074969, 35.070296012519577, 0.0],
              [-81.07835818426905, 35.070159212359862, 0.0],
              [-81.078553118279387, 35.070273811981643, 0.0],
              [-81.078525118224292, 35.070480012269364, 0.0],
              [-81.077131784016402, 35.071052078979676, 0.0],
              [-81.076797517337596, 35.071052012185618, 0.0],
              [-81.075906318095804, 35.070754012665844, 0.0],
              [-81.075460584731402, 35.070776678880769, 0.0],
              [-81.075042517397776, 35.071051411953434, 0.0],
              [-81.074847317332896, 35.071349012174515, 0.0],
              [-81.074707784866263, 35.071875679013743, 0.0],
              [-81.074763117906784, 35.072425478544567, 0.0],
              [-81.074985785035494, 35.07260881253589, 0.0],
              [-81.075654385015952, 35.07279227925148, 0.0],
              [-81.075765718180591, 35.072929811773406, 0.0],
              [-81.075737784109393, 35.073090079177526, 0.0],
              [-81.075180318114604, 35.073479279098088, 0.0],
              [-81.075068718054482, 35.073776879369056, 0.0],
              [-81.075318984128501, 35.07441827866181, 0.0],
              [-81.076237717585371, 35.075609812120227, 0.0],
              [-81.076348584332493, 35.076571811939381, 0.0],
              [-81.076599117773739, 35.076800879002441, 0.0],
              [-81.077184318082118, 35.076732412122645, 0.0],
              [-81.077574384148605, 35.076526278676603, 0.0],
              [-81.078131717870633, 35.076526478435738, 0.0],
              [-81.078633384479872, 35.076206079106349, 0.0],
              [-81.079886917583636, 35.076412612593096, 0.0],
              [-81.079998318150217, 35.076504279339403, 0.0],
              [-81.079970317886875, 35.076687478859235, 0.0],
              [-81.078382118072923, 35.07703047932759, 0.0],
              [-81.077713117506136, 35.077511278502378, 0.0],
              [-81.077684985129423, 35.078038079175307, 0.0],
              [-81.077852117990176, 35.078290078876812, 0.0],
              [-81.078659784186073, 35.078794211961764, 0.0],
              [-81.08005271736809, 35.079000878554353, 0.0],
              [-81.080498184502844, 35.079321679046842, 0.0],
              [-81.080999718406218, 35.079505078721027, 0.0],
              [-81.08113891794639, 35.079642611734528, 0.0],
              [-81.081110984061553, 35.079779878425789, 0.0],
              [-81.080609384980875, 35.08003181253175, 0.0],
              [-81.08033071833961, 35.080031679008293, 0.0],
              [-81.080024384238996, 35.07980247842309, 0.0],
              [-81.079495117993631, 35.079619078660784, 0.0],
              [-81.079216584389826, 35.079596078463467, 0.0],
              [-81.078882118303866, 35.079687612672004, 0.0],
              [-81.078686984825438, 35.079916612317717, 0.0],
              [-81.078742518274169, 35.080305878426003, 0.0],
              [-81.079382984645321, 35.081039212339419, 0.0],
              [-81.079884117534803, 35.081405679186268, 0.0],
              [-81.080886984506321, 35.081772612656444, 0.0],
              [-81.08149971742958, 35.082276612091249, 0.0],
              [-81.081499517904675, 35.082643078966399, 0.0],
              [-81.081318584985127, 35.08259567918941, 0.0],
              [-81.080886717730209, 35.082482612155836, 0.0],
              [-81.080468717469387, 35.082459612220539, 0.0],
              [-81.079521917908224, 35.081634679210154, 0.0],
              [-81.079103984239239, 35.081497079138771, 0.0],
              [-81.078519185133672, 35.081176278988586, 0.0],
              [-81.078101117978747, 35.081267812182446, 0.0],
              [-81.077655185147691, 35.081611212671085, 0.0],
              [-81.077376517327238, 35.081656878795094, 0.0],
              [-81.077376584530938, 35.08135907889492, 0.0],
              [-81.077850917658409, 35.080351479312739, 0.0],
              [-81.077850984329729, 35.080053679347543, 0.0],
              [-81.077711784696604, 35.079916278817521, 0.0],
              [-81.077461118024488, 35.079870411815591, 0.0],
              [-81.077461517569276, 35.079045879162237, 0.0],
              [-81.077099384660244, 35.078816679105181, 0.0],
              [-81.07651438496211, 35.078839279056673, 0.0],
              [-81.076402984801277, 35.078724812431986, 0.0],
              [-81.076431117936096, 35.078358278891194, 0.0],
              [-81.076626184533424, 35.07810641235001, 0.0],
              [-81.076598518250137, 35.077877412357431, 0.0],
              [-81.07576318458824, 35.077212812661514, 0.0],
              [-81.07537311782184, 35.077235678432373, 0.0],
              [-81.074787784676133, 35.077510278983141, 0.0],
              [-81.074481384944264, 35.077441478490485, 0.0],
              [-81.074481517972075, 35.077235278722902, 0.0],
              [-81.075178517843952, 35.076640078417917, 0.0],
              [-81.075234318168185, 35.076365078421958, 0.0],
              [-81.075122984192348, 35.076159079342794, 0.0],
              [-81.074760984241493, 35.075861211749697, 0.0],
              [-81.074148184966916, 35.075677679167413, 0.0],
              [-81.074036784547047, 35.075540278568788, 0.0],
              [-81.074009318499066, 35.074967612096785, 0.0],
              [-81.073647384396068, 35.074623879342575, 0.0],
              [-81.072588717784598, 35.074554678990722, 0.0],
              [-81.072449384445648, 35.074738012374489, 0.0],
              [-81.0723933173511, 35.075310611915491, 0.0],
              [-81.072253917883899, 35.075539479331844, 0.0],
              [-81.071947385003938, 35.07547067879063, 0.0],
              [-81.071919918271774, 35.074852212491344, 0.0],
              [-81.071195717407804, 35.074645812437424, 0.0],
              [-81.071112117422317, 35.074554212505461, 0.0],
              [-81.071167984172135, 35.074371011948791, 0.0],
              [-81.071418917778232, 35.07425661265605, 0.0],
              [-81.072338317646555, 35.074165278730575, 0.0],
              [-81.072784118189844, 35.073913479058248, 0.0],
              [-81.072979184433777, 35.073707412006783, 0.0],
              [-81.07306291836646, 35.07347841196114, 0.0],
              [-81.072840384837022, 35.073180612089331, 0.0],
              [-81.07242258407706, 35.073020079218452, 0.0],
              [-81.072144317834756, 35.072653478942648, 0.0],
              [-81.072255984658199, 35.072195412409485, 0.0],
              [-81.072339518197651, 35.072172611811453, 0.0],
              [-81.072339917999713, 35.071806078632832, 0.0],
              [-81.071977784712615, 35.071462412624967, 0.0],
              [-81.07197811834142, 35.071118878587129, 0.0],
              [-81.072061717701217, 35.070981478774371, 0.0],
              [-81.072034117943375, 35.070706611909635, 0.0],
              [-81.071560785065628, 35.070225478638235, 0.0],
              [-81.071449784237046, 35.06944661254628, 0.0],
              [-81.071227117649798, 35.069217478533076, 0.0],
              [-81.07080931849778, 35.069080012401244, 0.0],
              [-81.069444184073589, 35.069193879182407, 0.0],
              [-81.068720317375409, 35.068850079309968, 0.0],
              [-81.068637184299405, 35.067933879355643, 0.0],
              [-81.06835898484708, 35.067521478862396, 0.0],
              [-81.067885584831373, 35.067223478999338, 0.0],
              [-81.066715517627713, 35.067222878997256, 0.0],
              [-81.066520518489028, 35.06731447885619, 0.0],
              [-81.066408784408011, 35.067680878699093, 0.0],
              [-81.066520118286647, 35.067887212071987, 0.0],
              [-81.067605984745654, 35.068689212311376, 0.0],
              [-81.067912117385788, 35.069193211868992, 0.0],
              [-81.068023184451164, 35.069514012463713, 0.0],
              [-81.067855784953153, 35.069972012072299, 0.0],
              [-81.067577117728277, 35.070178012527514, 0.0],
              [-81.066908518156012, 35.070200612184813, 0.0],
              [-81.066824718290562, 35.07038381231137, 0.0],
              [-81.066601184715267, 35.071345679185555, 0.0],
              [-81.066127117770591, 35.072078412402028, 0.0],
              [-81.066126784206674, 35.072421878859835, 0.0],
              [-81.06623811761429, 35.072605212544524, 0.0],
              [-81.06623778467646, 35.073040412320928, 0.0],
              [-81.066488317916068, 35.073246678451277, 0.0],
              [-81.066488317879163, 35.073452812522625, 0.0],
              [-81.065345517518935, 35.074322678430022, 0.0],
              [-81.065205984544434, 35.074528812136109, 0.0],
              [-81.065093917383507, 35.075467679116123, 0.0],
              [-81.064759184385608, 35.076040278748025, 0.0],
              [-81.064563984158696, 35.076131679060126, 0.0],
              [-81.063644517714849, 35.0762230126048, 0.0],
              [-81.063477318004914, 35.07631447924166, 0.0],
              [-81.063477117410443, 35.076566479218528, 0.0],
              [-81.063839184261283, 35.076841478563857, 0.0],
              [-81.064368318322025, 35.077093612607939, 0.0],
              [-81.065064717926234, 35.077300079139853, 0.0],
              [-81.06542651823932, 35.077849878576359, 0.0],
              [-81.066345784383415, 35.077827478740609, 0.0],
              [-81.067487984821767, 35.078034079351404, 0.0],
              [-81.06751578455679, 35.078171478613498, 0.0],
              [-81.067849917527539, 35.078469478723299, 0.0],
              [-81.067849785172385, 35.078606878663727, 0.0],
              [-81.067598984523386, 35.078606678721435, 0.0],
              [-81.066540518034458, 35.078331412521919, 0.0],
              [-81.065063784319676, 35.078559812602883, 0.0],
              [-81.06400538409784, 35.07810127875009, 0.0],
              [-81.063531584690011, 35.07814687855651, 0.0],
              [-81.062834718545048, 35.078650412512715, 0.0],
              [-81.062444318205834, 35.079154079054071, 0.0],
              [-81.062416184120991, 35.079497612274714, 0.0],
              [-81.063112384446015, 35.079910278770235, 0.0],
              [-81.063279184404053, 35.080414279275601, 0.0],
              [-81.063752717366583, 35.080689278819086, 0.0],
              [-81.063919584022628, 35.081033012150385, 0.0],
              [-81.06495038409625, 35.081125012324406, 0.0],
              [-81.064866385010603, 35.08169761197464, 0.0],
              [-81.065061384379732, 35.081880878727439, 0.0],
              [-81.065423584623773, 35.081881012127546, 0.0],
              [-81.066844784724594, 35.08151527854271, 0.0],
              [-81.067652717640456, 35.08156141249016, 0.0],
              [-81.067596784912894, 35.081767478890903, 0.0],
              [-81.067345918331682, 35.081973478426491, 0.0],
              [-81.066482117674781, 35.082041879080464, 0.0],
              [-81.065673784087153, 35.082408011748747, 0.0],
              [-81.063918318285118, 35.082819478683213, 0.0],
              [-81.063472318114677, 35.08309407864224, 0.0],
              [-81.063471984099536, 35.083460612479307, 0.0],
              [-81.064669584951972, 35.084148278731689, 0.0],
              [-81.064947984695493, 35.084423278791078, 0.0],
              [-81.065197917943408, 35.085568612066169, 0.0],
              [-81.065754784552411, 35.086003878960732, 0.0],
              [-81.067008717361944, 35.086027479240329, 0.0],
              [-81.067287584580313, 35.08577567848149, 0.0],
              [-81.067287984093937, 35.085065679039587, 0.0],
              [-81.068068317653371, 35.084928479303997, 0.0],
              [-81.06809638476561, 35.08467661190339, 0.0],
              [-81.068402918091451, 35.084562078519717, 0.0],
              [-81.068848718424476, 35.08465407900772, 0.0],
              [-81.070604784489561, 35.08362407909744, 0.0],
              [-81.070827584037986, 35.083647012117986, 0.0],
              [-81.070994718055317, 35.08380741198291, 0.0],
              [-81.070966717434473, 35.083922012669518, 0.0],
              [-81.069851718042699, 35.084608678712044, 0.0],
              [-81.069015718461401, 35.084951812387601, 0.0],
              [-81.06868111748733, 35.085295279100087, 0.0],
              [-81.068764318207329, 35.085913679328023, 0.0],
              [-81.068680584903333, 35.085982412338829, 0.0],
              [-81.068095384899877, 35.086050812001588, 0.0],
              [-81.067900184363168, 35.08625701258773, 0.0],
              [-81.0677881846471, 35.086944011734104, 0.0],
              [-81.068233917379175, 35.087173279177335, 0.0],
              [-81.06926478481104, 35.087288078828777, 0.0],
              [-81.070184384725806, 35.08728861225638, 0.0],
              [-81.070183984310432, 35.087700879026833, 0.0],
              [-81.070824717959027, 35.088067611817216, 0.0],
              [-81.07079671843249, 35.088250812481228, 0.0],
              [-81.070573718261542, 35.088342279325921, 0.0],
              [-81.069180784947307, 35.087814879020456, 0.0],
              [-81.068149785193484, 35.087883278845283, 0.0],
              [-81.067676317628511, 35.087722678477157, 0.0],
              [-81.06739751845619, 35.087814212639721, 0.0],
              [-81.067480584882318, 35.088661679354068, 0.0],
              [-81.068009785148192, 35.088982612676006, 0.0],
              [-81.068009584343315, 35.089188678831647, 0.0],
              [-81.067535784329721, 35.089440479280533, 0.0],
              [-81.06722891747313, 35.090012878425178, 0.0],
              [-81.066141784092494, 35.090447611746185, 0.0],
              [-81.06605818486544, 35.090539212103486, 0.0],
              [-81.066113718529607, 35.090814012074752, 0.0],
              [-81.066308584801092, 35.090951612080239, 0.0],
              [-81.067228318025983, 35.090883212123813, 0.0],
              [-81.067311784735438, 35.090929078767303, 0.0],
              [-81.067172317843344, 35.091181012303508, 0.0],
              [-81.066726184904823, 35.091409812401835, 0.0],
              [-81.066196384349595, 35.091913479344299, 0.0],
              [-81.066335317599268, 35.09262361240534, 0.0],
              [-81.066502317461911, 35.092806878572553, 0.0],
              [-81.066725184350602, 35.092852812369593, 0.0],
              [-81.06692038461253, 35.092738412444618, 0.0],
              [-81.067115717494275, 35.092371878451047, 0.0],
              [-81.067422384833336, 35.092188811959616, 0.0],
              [-81.068230717816277, 35.091960212072571, 0.0],
              [-81.068537318250264, 35.091777078687556, 0.0],
              [-81.06864891749791, 35.091593878556608, 0.0],
              [-81.06892771794061, 35.091456678406722, 0.0],
              [-81.070181584256233, 35.091388412464724, 0.0],
              [-81.071157184895014, 35.091022412026774, 0.0],
              [-81.071630918046068, 35.091022412531146, 0.0],
              [-81.072271984416204, 35.090747878733389, 0.0],
              [-81.072550584931705, 35.090770878601091, 0.0],
              [-81.072550517732608, 35.090931279273299, 0.0],
              [-81.071853517471808, 35.091526478781624, 0.0],
              [-81.071240184560338, 35.091824012076181, 0.0],
              [-81.071072917513476, 35.09200721220013, 0.0],
              [-81.070849784972438, 35.092419412393447, 0.0],
              [-81.070236785029834, 35.092258812342862, 0.0],
              [-81.069707184513305, 35.092395878402016, 0.0],
              [-81.069706984872795, 35.092693678721041, 0.0],
              [-81.069818384297832, 35.092831212611621, 0.0],
              [-81.06973458447456, 35.093014412025546, 0.0],
              [-81.069595184664053, 35.093083079178164, 0.0],
              [-81.069149517661515, 35.092968412086364, 0.0],
              [-81.068787184201966, 35.093082678998108, 0.0],
              [-81.068508318453141, 35.093426211856354, 0.0],
              [-81.068340984806099, 35.093701012046211, 0.0],
              [-81.068089984677158, 35.09388407877794, 0.0],
              [-81.068089917874943, 35.094136012062471, 0.0],
              [-81.068869918123085, 35.094479879235045, 0.0],
              [-81.068925518482743, 35.094709012568636, 0.0],
              [-81.068563117548152, 35.094731678880059, 0.0],
              [-81.068117384039539, 35.094639878649232, 0.0],
              [-81.067643517888158, 35.09473127870384, 0.0],
              [-81.067531917625061, 35.094960412402358, 0.0],
              [-81.06758738516352, 35.095349678419979, 0.0],
              [-81.067893584513982, 35.095785011914579, 0.0],
              [-81.068004384743517, 35.096678278913828, 0.0],
              [-81.068227317957167, 35.096861678410839, 0.0],
              [-81.068366584057287, 35.096884612237417, 0.0],
              [-81.068951917841844, 35.096655812082354, 0.0],
              [-81.069954917914998, 35.097022678870204, 0.0],
              [-81.069954784241304, 35.097228878963008, 0.0],
              [-81.069313517787137, 35.097549279113544, 0.0],
              [-81.069341184194343, 35.097824078494661, 0.0],
              [-81.070706118136698, 35.098878278811839, 0.0],
              [-81.071430517677186, 35.099061811843946, 0.0],
              [-81.071792718427929, 35.099313812255687, 0.0],
              [-81.072266384014483, 35.09945147912466, 0.0],
              [-81.072516984343537, 35.099726478724449, 0.0],
              [-81.072990584250164, 35.099955678771238, 0.0],
              [-81.073826518280015, 35.10007047913966, 0.0],
              [-81.074356117474878, 35.100002011768247, 0.0],
              [-81.074802117476409, 35.099796078794526, 0.0],
              [-81.075025318289576, 35.09947547839397, 0.0],
              [-81.075025584061265, 35.098925812568652, 0.0],
              [-81.074802917637314, 35.098605078429827, 0.0],
              [-81.074440784480657, 35.098307212232861, 0.0],
              [-81.074440917989705, 35.097986479140395, 0.0],
              [-81.074831184168417, 35.097688878790407, 0.0],
              [-81.074942984198643, 35.097391212554051, 0.0],
              [-81.075221717537815, 35.097299612295821, 0.0],
              [-81.075388917899161, 35.097391278886597, 0.0],
              [-81.075500117800217, 35.097620479294392, 0.0],
              [-81.075555584400135, 35.09814721185343, 0.0],
              [-81.075945517544497, 35.098513811818755, 0.0],
              [-81.076419317771311, 35.098514012211126, 0.0],
              [-81.076781585011076, 35.098399612335299, 0.0],
              [-81.077004717674114, 35.098170679323516, 0.0],
              [-81.077701518272718, 35.097919012595106, 0.0],
              [-81.077980184973484, 35.097690078517992, 0.0],
              [-81.078091984601642, 35.097392278915414, 0.0],
              [-81.078649718021524, 35.096659611799005, 0.0],
              [-81.078789184201597, 35.09633887904225, 0.0],
              [-81.078956384963774, 35.096361878390063, 0.0],
              [-81.078984117804737, 35.096797078581453, 0.0],
              [-81.079346118138147, 35.097301211807661, 0.0],
              [-81.079345917341897, 35.097576012650173, 0.0],
              [-81.078565318505227, 35.097988012163349, 0.0],
              [-81.07806338426046, 35.098560478459198, 0.0],
              [-81.077422317523997, 35.098697678940042, 0.0],
              [-81.077171318185847, 35.098995278641155, 0.0],
              [-81.07705958419858, 35.099430412535355, 0.0],
              [-81.076613718315841, 35.099773812426051, 0.0],
              [-81.076669184204519, 35.100025812489122, 0.0],
              [-81.077755784896823, 35.100392611990451, 0.0],
              [-81.078257584444046, 35.100278279249643, 0.0],
              [-81.078814917367808, 35.100301478595824, 0.0],
              [-81.079901717413094, 35.100553679232121, 0.0],
              [-81.079985317847701, 35.100645412566799, 0.0],
              [-81.079901518376829, 35.100920212294078, 0.0],
              [-81.07976198490114, 35.101034611961985, 0.0],
              [-81.078117784949825, 35.100965412111982, 0.0],
              [-81.076835584384369, 35.101171012182427, 0.0],
              [-81.076306184202281, 35.101331212240602, 0.0],
              [-81.076110918231308, 35.101583079054002, 0.0],
              [-81.076138585178114, 35.101995411861189, 0.0],
              [-81.076528184597322, 35.102842878645511, 0.0],
              [-81.077335917449844, 35.103736479229006, 0.0],
              [-81.077474984270268, 35.104080012650989, 0.0],
              [-81.077642184272236, 35.104240412609059, 0.0],
              [-81.078450384646629, 35.104240678986677, 0.0],
              [-81.079369985142932, 35.104516011987023, 0.0],
              [-81.079955318137209, 35.10444747872301, 0.0],
              [-81.080401384790505, 35.104149812639299, 0.0],
              [-81.081209584148326, 35.104012678696847, 0.0],
              [-81.081599917699634, 35.103692078360574, 0.0],
              [-81.081795317573793, 35.10321127843207, 0.0],
              [-81.082185718384181, 35.102867678910428, 0.0],
              [-81.082966117681451, 35.102524412323994, 0.0],
              [-81.083969918121483, 35.101768879165142, 0.0],
              [-81.084276585005071, 35.101494212318023, 0.0],
              [-81.084778184474487, 35.101265278474422, 0.0],
              [-81.085307984363396, 35.100692879108138, 0.0],
              [-81.08586558459254, 35.100280679138152, 0.0],
              [-81.086200117972012, 35.100280879143099, 0.0],
              [-81.086199917966098, 35.100487011948303, 0.0],
              [-81.086060517410914, 35.100715878790233, 0.0],
              [-81.085279784447309, 35.101379878472081, 0.0],
              [-81.085335184263414, 35.102044212641133, 0.0],
              [-81.083801917539589, 35.103257612137988, 0.0],
              [-81.08380171782261, 35.103532479149564, 0.0],
              [-81.084553917356189, 35.104242811776572, 0.0],
              [-81.084776784967417, 35.104334478930106, 0.0],
              [-81.085585117469307, 35.104174278826626, 0.0],
              [-81.08578011800617, 35.104288878602915, 0.0],
              [-81.085779784639385, 35.104861478378531, 0.0],
              [-81.085695984176439, 35.105205011738605, 0.0],
              [-81.085863118054561, 35.105434078928035, 0.0],
              [-81.085946518212708, 35.105960878666714, 0.0],
              [-81.086698917516415, 35.106213078734939, 0.0],
              [-81.086893918098397, 35.106350612608814, 0.0],
              [-81.086893917468359, 35.106556678692939, 0.0],
              [-81.086141117385893, 35.106945812068396, 0.0],
              [-81.085890184696154, 35.107174878694423, 0.0],
              [-81.085778517996545, 35.10742681250607, 0.0],
              [-81.08583398468852, 35.108136878398525, 0.0],
              [-81.086028918131476, 35.108572078986477, 0.0],
              [-81.085945184685755, 35.108686612345103, 0.0],
              [-81.08558278410419, 35.108823878415244, 0.0],
              [-81.08538758508405, 35.1090528784882, 0.0],
              [-81.085387517819925, 35.109579678667849, 0.0],
              [-81.085805317734042, 35.109854611835807, 0.0],
              [-81.08622338408162, 35.109831811747682, 0.0],
              [-81.086725183991192, 35.109648812011024, 0.0],
              [-81.087505517817007, 35.109923812085839, 0.0],
              [-81.087979317653364, 35.109832279325957, 0.0],
              [-81.088481384435823, 35.10914541196189, 0.0],
              [-81.088704317894809, 35.109168278343795, 0.0],
              [-81.088759984995377, 35.10942027872558, 0.0],
              [-81.088508917645555, 35.110015678671985, 0.0],
              [-81.088536584457927, 35.110290612578332, 0.0],
              [-81.089038317914941, 35.110290678362531, 0.0],
              [-81.08948418461371, 35.110496878554748, 0.0],
              [-81.090961317906761, 35.110405679121719, 0.0],
              [-81.091323784301977, 35.110268279323066, 0.0],
              [-81.092131784255471, 35.110749611705465, 0.0],
              [-81.092912384191578, 35.110726679280944, 0.0],
              [-81.093414117803889, 35.110497878793289, 0.0],
              [-81.093999517957869, 35.110040012070719, 0.0],
              [-81.094362118110254, 35.109582012101129, 0.0],
              [-81.094752317356125, 35.109330012135061, 0.0],
              [-81.095281984182762, 35.109124012249161, 0.0],
              [-81.095226384694357, 35.108551411804548, 0.0],
              [-81.095505184644452, 35.108345279131967, 0.0],
              [-81.095505317637617, 35.108116278516597, 0.0],
              [-81.095282384528304, 35.107933011902354, 0.0],
              [-81.09525458464077, 35.107772678734932, 0.0],
              [-81.095589184594132, 35.107429212023696, 0.0],
              [-81.09570091746086, 35.106765012213131, 0.0],
              [-81.095701184183724, 35.106032078597096, 0.0],
              [-81.095924185009054, 35.105826011923391, 0.0],
              [-81.096593117533061, 35.10578021234911, 0.0],
              [-81.096955518148945, 35.105620012303362, 0.0],
              [-81.097373717364633, 35.105047478803925, 0.0],
              [-81.09820991837141, 35.104749879270607, 0.0],
              [-81.098460717976934, 35.104795811828112, 0.0],
              [-81.098572184018593, 35.10491027848812, 0.0],
              [-81.098627784379246, 35.105185211964375, 0.0],
              [-81.098460518342392, 35.105414212501707, 0.0],
              [-81.097874984456794, 35.105895078787661, 0.0],
              [-81.097874984518313, 35.106055411772573, 0.0],
              [-81.098097918447692, 35.106238678575977, 0.0],
              [-81.098404517675604, 35.106353278555048, 0.0],
              [-81.099156984931142, 35.106284679261805, 0.0],
              [-81.099463584334458, 35.10642207923059, 0.0],
              [-81.099992918356449, 35.106903212545305, 0.0],
              [-81.101804384175068, 35.107109612469905, 0.0],
              [-81.101971717291363, 35.107224212121558, 0.0],
              [-81.101887917711579, 35.107453212533223, 0.0],
              [-81.100466384388639, 35.107979812469587, 0.0],
              [-81.100104118008545, 35.10795681190752, 0.0],
              [-81.10004851785385, 35.107636212593015, 0.0],
              [-81.099351718130251, 35.107475679110017, 0.0],
              [-81.09887778473805, 35.107681812305827, 0.0],
              [-81.098431917853475, 35.107635878889205, 0.0],
              [-81.098013918088938, 35.107498412042538, 0.0],
              [-81.09742871735817, 35.107498279036669, 0.0],
              [-81.097261317956054, 35.107910478748451, 0.0],
              [-81.096453118409073, 35.107933278370638, 0.0],
              [-81.096118518193762, 35.108208079069321, 0.0],
              [-81.096118384648889, 35.108482878401752, 0.0],
              [-81.096201918049644, 35.108528679024502, 0.0],
              [-81.096675784992456, 35.108391412638213, 0.0],
              [-81.09698231837497, 35.108666279298745, 0.0],
              [-81.09728871759701, 35.109353411706842, 0.0],
              [-81.097623117680271, 35.109651212340147, 0.0],
              [-81.097622984924783, 35.109926078804392, 0.0],
              [-81.096953984201988, 35.110246678706282, 0.0],
              [-81.096786384033479, 35.11100247857712, 0.0],
              [-81.09664698420076, 35.111139878615518, 0.0],
              [-81.096396117418749, 35.111208479313788, 0.0],
              [-81.095699318231738, 35.11104801224694, 0.0],
              [-81.095532317309136, 35.110818878866432, 0.0],
              [-81.09508631839708, 35.110818878864876, 0.0],
              [-81.094445118180062, 35.111024878469827, 0.0],
              [-81.094305718042648, 35.111162278702928, 0.0],
              [-81.094166117365006, 35.111620411889788, 0.0],
              [-81.093357784375897, 35.112009612115834, 0.0],
              [-81.093134717642045, 35.112192678609347, 0.0],
              [-81.092855985123165, 35.112169812069432, 0.0],
              [-81.09252171770197, 35.111711612152185, 0.0],
              [-81.092047917737204, 35.111711478524242, 0.0],
              [-81.091629784957632, 35.11180301250392, 0.0],
              [-81.091462384171649, 35.112054878573552, 0.0],
              [-81.091489717482304, 35.11345201191525, 0.0],
              [-81.091322384671955, 35.113681011733867, 0.0],
              [-81.091155184157941, 35.113681012054379, 0.0],
              [-81.090820784061776, 35.113291612376237, 0.0],
              [-81.090793384317848, 35.112215012234614, 0.0],
              [-81.090431184592376, 35.111756878713472, 0.0],
              [-81.089929517893893, 35.111802612056394, 0.0],
              [-81.089594984361952, 35.112008679063742, 0.0],
              [-81.089009384661964, 35.112581078841899, 0.0],
              [-81.088618984147814, 35.11306201254817, 0.0],
              [-81.088451717921401, 35.11306187903746, 0.0],
              [-81.088256784448916, 35.112901478597507, 0.0],
              [-81.08806178457553, 35.112535012045619, 0.0],
              [-81.087978518306457, 35.111779078488659, 0.0],
              [-81.087867118235977, 35.111641679116502, 0.0],
              [-81.087616184299691, 35.111550011964894, 0.0],
              [-81.087142384248381, 35.111641612183341, 0.0],
              [-81.086947184546318, 35.111778878382935, 0.0],
              [-81.086835517438203, 35.112237012133647, 0.0],
              [-81.086695984246333, 35.112351478692297, 0.0],
              [-81.086333784375512, 35.112168079276373, 0.0],
              [-81.086222518112038, 35.111687078339166, 0.0],
              [-81.086083318236618, 35.111458011711747, 0.0],
              [-81.085776584518413, 35.111480812062034, 0.0],
              [-81.085525717349313, 35.111870078605151, 0.0],
              [-81.085302184843385, 35.112923611849126, 0.0],
              [-81.085134785085287, 35.11326721186289, 0.0],
              [-81.083907917580476, 35.114320412131832, 0.0],
              [-81.083656917710371, 35.114343212462721, 0.0],
              [-81.083322585133331, 35.114068211793089, 0.0],
              [-81.082848784328135, 35.114022412093242, 0.0],
              [-81.082876785078383, 35.113770279180848, 0.0],
              [-81.08432691827403, 35.112511079040956, 0.0],
              [-81.08429911770709, 35.112213279092138, 0.0],
              [-81.0831843850643, 35.11182361246226, 0.0],
              [-81.082961518473326, 35.111617412228561, 0.0],
              [-81.083073317838569, 35.110884479005072, 0.0],
              [-81.082989784696252, 35.110678278789884, 0.0],
              [-81.081875117966177, 35.110380212189, 0.0],
              [-81.081875317537367, 35.11012827932862, 0.0],
              [-81.082293517600945, 35.109830612518316, 0.0],
              [-81.08321318481299, 35.109876811995051, 0.0],
              [-81.083854584281724, 35.109418879129599, 0.0],
              [-81.083966184997834, 35.109212812133393, 0.0],
              [-81.083938385085631, 35.108937878726081, 0.0],
              [-81.083854785131948, 35.108892078780279, 0.0],
              [-81.083910717541968, 35.108594278734735, 0.0],
              [-81.084078117477461, 35.108227878554622, 0.0],
              [-81.083938917974848, 35.108136212156609, 0.0],
              [-81.082795984963113, 35.108227478903274, 0.0],
              [-81.082712517463193, 35.108135879325282, 0.0],
              [-81.082712517903516, 35.107861012020997, 0.0],
              [-81.082852118398833, 35.107632079060728, 0.0],
              [-81.082852117520133, 35.107448878931876, 0.0],
              [-81.082740718304066, 35.107288411905145, 0.0],
              [-81.082768717994043, 35.107174011960367, 0.0],
              [-81.083632984493349, 35.1066702786324, 0.0],
              [-81.083911784058103, 35.106303879054813, 0.0],
              [-81.083939917583407, 35.105914478787632, 0.0],
              [-81.083159918396333, 35.10525007891583, 0.0],
              [-81.082992718475154, 35.105181278597541, 0.0],
              [-81.082267918143174, 35.105272812273363, 0.0],
              [-81.081515185093892, 35.105776278710294, 0.0],
              [-81.08109678509544, 35.106348879120141, 0.0],
              [-81.080901384497665, 35.107333612466505, 0.0],
              [-81.08065031836712, 35.107608478624336, 0.0],
              [-81.080566384312462, 35.108158212130753, 0.0],
              [-81.079590918223033, 35.108203612148301, 0.0],
              [-81.079451518457418, 35.108272278405124, 0.0],
              [-81.078782517829751, 35.108226212004226, 0.0],
              [-81.078308384009475, 35.108913212449131, 0.0],
              [-81.078085317883406, 35.109096278407158, 0.0],
              [-81.077945918399479, 35.109027612497684, 0.0],
              [-81.077946118495021, 35.108729878339993, 0.0],
              [-81.078225117579606, 35.108249012576948, 0.0],
              [-81.078225384905139, 35.107905412590128, 0.0],
              [-81.077919118017704, 35.107332679234652, 0.0],
              [-81.07778011822279, 35.106485212545103, 0.0],
              [-81.077557384919118, 35.106187279037186, 0.0],
              [-81.076832784370851, 35.106210012520911, 0.0],
              [-81.076414584511681, 35.10641587899601, 0.0],
              [-81.075689717604448, 35.106965278994586, 0.0],
              [-81.075215385029267, 35.107652412601553, 0.0],
              [-81.075020318413536, 35.107652278467206, 0.0],
              [-81.074936717454676, 35.107583478494199, 0.0],
              [-81.074992717751968, 35.107308678845243, 0.0],
              [-81.075550517764043, 35.106484278457536, 0.0],
              [-81.075550917544561, 35.105957478399183, 0.0],
              [-81.075746117695033, 35.10577441236623, 0.0],
              [-81.075913584199711, 35.105385079105069, 0.0],
              [-81.07588591814158, 35.105064412481617, 0.0],
              [-81.075607317976605, 35.10476647848607, 0.0],
              [-81.075300784675747, 35.104789412020196, 0.0],
              [-81.075077717764174, 35.104903679142922, 0.0],
              [-81.074910517576001, 35.104903812215809, 0.0],
              [-81.074799118186633, 35.104766278840934, 0.0],
              [-81.074855117496156, 35.104376879297917, 0.0],
              [-81.075106118379225, 35.104079211979858, 0.0],
              [-81.07510631733831, 35.103712678449995, 0.0],
              [-81.074883584259823, 35.103437812151292, 0.0],
              [-81.074270584891892, 35.103071211990702, 0.0],
              [-81.074076184159892, 35.102063412263092, 0.0],
              [-81.073936917701715, 35.101834212005919, 0.0],
              [-81.073602517585158, 35.101902812529701, 0.0],
              [-81.073434984839309, 35.102223412527671, 0.0],
              [-81.073043917745011, 35.103712012046429, 0.0],
              [-81.072932384514587, 35.103780611948601, 0.0],
              [-81.072319318430417, 35.103826212131274, 0.0],
              [-81.071092584104804, 35.104512879294376, 0.0],
              [-81.070869317606991, 35.105085411725518, 0.0],
              [-81.070813117472895, 35.105635012337835, 0.0],
              [-81.070617784136559, 35.105978478576318, 0.0],
              [-81.070283318404918, 35.106092811869729, 0.0],
              [-81.070199717614926, 35.106024078762914, 0.0],
              [-81.070116517829817, 35.105497279299392, 0.0],
              [-81.070004985001518, 35.105405678550397, 0.0],
              [-81.069921584827298, 35.105062079121389, 0.0],
              [-81.07006131739297, 35.104718611702332, 0.0],
              [-81.07050738440644, 35.104237812669652, 0.0],
              [-81.071260384773922, 35.103642478622213, 0.0],
              [-81.071595117672345, 35.103093011881924, 0.0],
              [-81.071623184667516, 35.102909879330511, 0.0],
              [-81.071929984608857, 35.102497612075375, 0.0],
              [-81.07193011832311, 35.102245612557461, 0.0],
              [-81.071456317555786, 35.102222678505299, 0.0],
              [-81.070787317520768, 35.102520078931882, 0.0],
              [-81.070341518117957, 35.102497011690176, 0.0],
              [-81.070425184348622, 35.102222212630835, 0.0],
              [-81.070843518047027, 35.101878812065465, 0.0],
              [-81.071596185005276, 35.101443878371214, 0.0],
              [-81.072404584837784, 35.101283878696037, 0.0],
              [-81.072376784962429, 35.101077679302676, 0.0],
              [-81.072237584271775, 35.100963212064471, 0.0],
              [-81.071345584221305, 35.101191878757554, 0.0],
              [-81.071066918412583, 35.101191812404721, 0.0],
              [-81.070676918498648, 35.100916811834807, 0.0],
              [-81.069952518376496, 35.100618612121252, 0.0],
              [-81.068949118349778, 35.100709812155621, 0.0],
              [-81.068949384371777, 35.100343412021275, 0.0],
              [-81.069060984243649, 35.100114412418705, 0.0],
              [-81.068977517407689, 35.100045612100836, 0.0],
              [-81.06894991802703, 35.099564611899346, 0.0],
              [-81.068782784019476, 35.099518811753548, 0.0],
              [-81.068197517922641, 35.099656012576936, 0.0],
              [-81.067890718223126, 35.100022278477823, 0.0],
              [-81.067444784277356, 35.100022078630559, 0.0],
              [-81.067249785190711, 35.099953278828394, 0.0],
              [-81.066859584220339, 35.100044678917122, 0.0],
              [-81.066078717888914, 35.100754478997828, 0.0],
              [-81.065744184907047, 35.100731411839952, 0.0],
              [-81.065744584368161, 35.100204612540082, 0.0],
              [-81.065828384856545, 35.09997561196446, 0.0],
              [-81.066246584307535, 35.099632211894843, 0.0],
              [-81.067946984423486, 35.099152011821232, 0.0],
              [-81.068142184815486, 35.098991811772372, 0.0],
              [-81.068225984195209, 35.098694079122836, 0.0],
              [-81.068142584250651, 35.098533679194574, 0.0],
              [-81.067473784262276, 35.098350078768625, 0.0],
              [-81.067111718147729, 35.09814381248917, 0.0],
              [-81.066637984586578, 35.098006211694099, 0.0],
              [-81.066582584609137, 35.097548078812963, 0.0],
              [-81.066499118410675, 35.097456412143387, 0.0],
              [-81.065913784483755, 35.09750187908805, 0.0],
              [-81.065551385116407, 35.097662211690761, 0.0],
              [-81.065411917717086, 35.097845412487217, 0.0],
              [-81.06546718497907, 35.098395011706359, 0.0],
              [-81.065550584030461, 35.098738679197083, 0.0],
              [-81.065466918472339, 35.098944678427692, 0.0],
              [-81.064713984507051, 35.099585679267904, 0.0],
              [-81.064323917355765, 35.099539678534441, 0.0],
              [-81.064519518444683, 35.098761012379256, 0.0],
              [-81.064603184735162, 35.098646612657348, 0.0],
              [-81.064547718005102, 35.098440412032176, 0.0],
              [-81.063767184059131, 35.098577478406405, 0.0],
              [-81.063432917829005, 35.098485679135671, 0.0],
              [-81.063460917506276, 35.098279478899705, 0.0],
              [-81.063767717783264, 35.098027812228366, 0.0],
              [-81.063879384992788, 35.097730079281149, 0.0],
              [-81.064520517384153, 35.097409679090859, 0.0],
              [-81.064743584411389, 35.097203678468468, 0.0],
              [-81.064743984759914, 35.09679141264666, 0.0],
              [-81.064493317346177, 35.09663101210365, 0.0],
              [-81.06421451746921, 35.096722479044217, 0.0],
              [-81.063991317740062, 35.097043012574453, 0.0],
              [-81.063796184798179, 35.097111611828893, 0.0],
              [-81.062960318137627, 35.097019679310634, 0.0],
              [-81.062570317741162, 35.096721679038595, 0.0],
              [-81.062570517429791, 35.096446879184008, 0.0],
              [-81.06326751785447, 35.096080679264411, 0.0],
              [-81.063267785137043, 35.095668411846248, 0.0],
              [-81.062850118358554, 35.095233012282335, 0.0],
              [-81.062320717681445, 35.095003812435245, 0.0],
              [-81.061679984563966, 35.094843079084676, 0.0],
              [-81.061624384982224, 35.094682678871386, 0.0],
              [-81.061708318370449, 35.094270479218338, 0.0],
              [-81.06137418504494, 35.094064211745049, 0.0],
              [-81.060705184396724, 35.094155479078459, 0.0],
              [-81.060593784092148, 35.094086812301541, 0.0],
              [-81.060817317620405, 35.093399678818614, 0.0],
              [-81.0607897841615, 35.092941678938622, 0.0],
              [-81.060539184765886, 35.092666678501111, 0.0],
              [-81.059926317911902, 35.092460211937208, 0.0],
              [-81.059815384565994, 35.091773012147243, 0.0],
              [-81.059509117431389, 35.091498078924083, 0.0],
              [-81.059342117368772, 35.091223079240365, 0.0],
              [-81.059035784519921, 35.090994012489901, 0.0],
              [-81.0583947175178, 35.091222612687226, 0.0],
              [-81.056555784118018, 35.09096967918483, 0.0],
              [-81.055970517782882, 35.091061078474652, 0.0],
              [-81.055524517565331, 35.091312812550711, 0.0],
              [-81.055273517401403, 35.091564612070371, 0.0],
              [-81.055244984395841, 35.092297478936231, 0.0],
              [-81.054965784954675, 35.092869878469202, 0.0],
              [-81.054853518432608, 35.093969278858154, 0.0],
              [-81.054992584419878, 35.094129811976899, 0.0],
              [-81.055884518318763, 35.094038478998208, 0.0],
              [-81.056357718027755, 35.094611412328632, 0.0],
              [-81.056357384112772, 35.095023612231309, 0.0],
              [-81.056579984349867, 35.095298678568234, 0.0],
              [-81.056635384246874, 35.095733812375265, 0.0],
              [-81.057331917588016, 35.096146478993781, 0.0],
              [-81.05825138421757, 35.096032411961389, 0.0],
              [-81.05869691744509, 35.096444879122146, 0.0],
              [-81.058612718337045, 35.097246478550247, 0.0],
              [-81.058138985102815, 35.09717761236201, 0.0],
              [-81.058027717772674, 35.097085878930507, 0.0],
              [-81.057247584079931, 35.096879278396912, 0.0],
              [-81.05677378421079, 35.096924879280522, 0.0],
              [-81.056634385105184, 35.097085078511938, 0.0],
              [-81.056633984062017, 35.097382879285746, 0.0],
              [-81.057079584373213, 35.097841278825086, 0.0],
              [-81.057135184828056, 35.098093212530976, 0.0],
              [-81.057051384204271, 35.098253478616442, 0.0],
              [-81.056633117394838, 35.09845941199444, 0.0],
              [-81.056660718249987, 35.098825878450235, 0.0],
              [-81.057189584725421, 35.099536212073005, 0.0],
              [-81.057412384327762, 35.099696611919683, 0.0],
              [-81.057384118502725, 35.100200478614305, 0.0],
              [-81.057551185158985, 35.100338078804121, 0.0],
              [-81.05802498411137, 35.100292479182755, 0.0],
              [-81.058470985159161, 35.100155279116755, 0.0],
              [-81.059195585084368, 35.100155678462855, 0.0],
              [-81.05902791774237, 35.100819811810531, 0.0],
              [-81.059278384184324, 35.101232211830776, 0.0],
              [-81.059417184159472, 35.101781878727465, 0.0],
              [-81.059695717902173, 35.102171412303491, 0.0],
              [-81.059946318119785, 35.102400612305814, 0.0],
              [-81.059945917804797, 35.10285867874272, 0.0],
              [-81.059834317576517, 35.103019012274373, 0.0],
              [-81.059778184613009, 35.103431212402853, 0.0],
              [-81.059471718439084, 35.103431012044091, 0.0],
              [-81.058970385172017, 35.102972812151371, 0.0],
              [-81.058859517545045, 35.102262678383767, 0.0],
              [-81.058692717953079, 35.101827411734575, 0.0],
              [-81.058219317736473, 35.101323278784477, 0.0],
              [-81.057634317880968, 35.100956478680494, 0.0],
              [-81.056965784672045, 35.100750012194915, 0.0],
              [-81.056296917682928, 35.100749612636449, 0.0],
              [-81.055878584634996, 35.100932678603364, 0.0],
              [-81.055794584521578, 35.101436478950077, 0.0],
              [-81.055097318355365, 35.102192012226929, 0.0],
              [-81.054957918050036, 35.102191878613574, 0.0],
              [-81.054790784585137, 35.102054412195173, 0.0],
              [-81.054874717596135, 35.101665079049155, 0.0],
              [-81.055655784145799, 35.100795078417541, 0.0],
              [-81.055739984162926, 35.100131012133673, 0.0],
              [-81.055656718234232, 35.099718612191289, 0.0],
              [-81.055322718181799, 35.099260412178054, 0.0],
              [-81.055267917874374, 35.09816101228995, 0.0],
              [-81.055045184987677, 35.097817278906582, 0.0],
              [-81.054851118372085, 35.096580412031145, 0.0],
              [-81.054739984886197, 35.096351279311314, 0.0],
              [-81.054600718496232, 35.096282412106504, 0.0],
              [-81.054377717597646, 35.096328211784275, 0.0],
              [-81.054293984317027, 35.096419678588155, 0.0],
              [-81.054236384597772, 35.098458078415902, 0.0],
              [-81.054096984335686, 35.098664212050025, 0.0],
              [-81.05367878405184, 35.09884721203499, 0.0],
              [-81.052785384246491, 35.100816478714499, 0.0],
              [-81.052004518200704, 35.101457278882179, 0.0],
              [-81.051697384453263, 35.101938078612122, 0.0],
              [-81.051613584138579, 35.102258679002631, 0.0],
              [-81.051724918137865, 35.102373412014934, 0.0],
              [-81.052142917507638, 35.102533879245549, 0.0],
              [-81.051919118238999, 35.103381211842304, 0.0],
              [-81.05205818468572, 35.10377061243377, 0.0],
              [-81.052809984679186, 35.104366612579476, 0.0],
              [-81.053534517432681, 35.104435678982277, 0.0],
              [-81.05342251764678, 35.105214412348509, 0.0],
              [-81.053282985082689, 35.105260079221026, 0.0],
              [-81.052809385093724, 35.105053678916903, 0.0],
              [-81.052530785151774, 35.105122278703661, 0.0],
              [-81.052419118371674, 35.105305478664789, 0.0],
              [-81.052362717610322, 35.106084079110047, 0.0],
              [-81.052780518093812, 35.106290612501368, 0.0],
              [-81.05303098506775, 35.106611412605801, 0.0],
              [-81.053169984602008, 35.106977878424154, 0.0],
              [-81.053364317805958, 35.108008612202013, 0.0],
              [-81.053949184377004, 35.108398412568398, 0.0],
              [-81.053948918199552, 35.108764812152714, 0.0],
              [-81.053753584180157, 35.108902211732556, 0.0],
              [-81.053335584714119, 35.108856078482361, 0.0],
              [-81.052639384159377, 35.108237279262099, 0.0],
              [-81.052444784417645, 35.107618812345976, 0.0],
              [-81.052166318540472, 35.107481279166706, 0.0],
              [-81.052055384888675, 35.106862678885015, 0.0],
              [-81.051749118480544, 35.106564812014916, 0.0],
              [-81.051749184324038, 35.106427412161054, 0.0],
              [-81.051665585159668, 35.106381611975038, 0.0],
              [-81.051637984520255, 35.106015078927847, 0.0],
              [-81.051443118201149, 35.105808812189856, 0.0],
              [-81.051360118126027, 35.105282012287972, 0.0],
              [-81.050719784269987, 35.10436541260195, 0.0],
              [-81.050664717594216, 35.103678279196494, 0.0],
              [-81.050414118395551, 35.103449079082935, 0.0],
              [-81.049968317403241, 35.103311412418812, 0.0],
              [-81.048574784476884, 35.103471011862545, 0.0],
              [-81.048240118485865, 35.103676878758385, 0.0],
              [-81.048100317657187, 35.104157678812008, 0.0],
              [-81.048154984771301, 35.105348812525634, 0.0],
              [-81.048238185022839, 35.105761078785982, 0.0],
              [-81.048516318112817, 35.106173612264648, 0.0],
              [-81.048934118317021, 35.106540278950426, 0.0],
              [-81.049156118480511, 35.107548211675542, 0.0],
              [-81.049267518214094, 35.107685679158855, 0.0],
              [-81.049239317432324, 35.10798341192303, 0.0],
              [-81.049099918336779, 35.108075011894421, 0.0],
              [-81.048737584627702, 35.107983212653217, 0.0],
              [-81.048319984514947, 35.107501878528986, 0.0],
              [-81.048041984534507, 35.106906212605743, 0.0],
              [-81.047930517383534, 35.106814479256904, 0.0],
              [-81.04762478484497, 35.105966879243354, 0.0],
              [-81.0467059183174, 35.105027278742647, 0.0],
              [-81.046622517592482, 35.104844011970535, 0.0],
              [-81.046539918232767, 35.103836212521834, 0.0],
              [-81.046372785052057, 35.103630012650932, 0.0],
              [-81.04589898511729, 35.103652478532574, 0.0],
              [-81.045731317728382, 35.104133412128057, 0.0],
              [-81.04517351796359, 35.104499478882872, 0.0],
              [-81.045089584811791, 35.104843012035026, 0.0],
              [-81.045200118209721, 35.105850878851236, 0.0],
              [-81.045422718319074, 35.106171678596709, 0.0],
              [-81.045422384905592, 35.106515279317691, 0.0],
              [-81.044725317395176, 35.106927078512555, 0.0],
              [-81.044502385024956, 35.106812412512539, 0.0],
              [-81.044391184996741, 35.106468811979504, 0.0],
              [-81.044112917394784, 35.106193812255761, 0.0],
              [-81.043834117584836, 35.106239479298026, 0.0],
              [-81.0438057844501, 35.106743278939156, 0.0],
              [-81.043972717462538, 35.10706407882909, 0.0],
              [-81.043860784462908, 35.107453412186452, 0.0],
              [-81.043498384468492, 35.107521812186121, 0.0],
              [-81.043025118158312, 35.107017611896644, 0.0],
              [-81.042663917637313, 35.105918012283034, 0.0],
              [-81.042469118436969, 35.105620078699864, 0.0],
              [-81.04188411857281, 35.105321878741215, 0.0],
              [-81.040993118585391, 35.104474012485532, 0.0],
              [-81.040463717422824, 35.104473612243112, 0.0],
              [-81.040240517839649, 35.104656678507787, 0.0],
              [-81.040295317765427, 35.105527078369754, 0.0],
              [-81.04062838434362, 35.106764078697154, 0.0],
              [-81.040795384296757, 35.106947479274076, 0.0],
              [-81.040822985110196, 35.107359678868107, 0.0],
              [-81.040599784682755, 35.107474212212054, 0.0],
              [-81.040432584052027, 35.107474012290417, 0.0],
              [-81.04009838420852, 35.107267678527073, 0.0],
              [-81.039708917622036, 35.106717678944101, 0.0],
              [-81.039513784625044, 35.106580078409181, 0.0],
              [-81.039012117755547, 35.106671478467369, 0.0],
              [-81.038677384750599, 35.106923212161803, 0.0],
              [-81.038704784791747, 35.107358412210679, 0.0],
              [-81.038899517524001, 35.107770812570195, 0.0],
              [-81.0392057182559, 35.108114612141684, 0.0],
              [-81.039957584533795, 35.108527279288076, 0.0],
              [-81.039790118490117, 35.108824878375806, 0.0],
              [-81.039093184896572, 35.109076478692963, 0.0],
              [-81.038842384200152, 35.109007612033437, 0.0],
              [-81.038508384815088, 35.108640878370892, 0.0],
              [-81.038146917950556, 35.107861878480492, 0.0],
              [-81.037756984328709, 35.10756381183014, 0.0],
              [-81.037422384560571, 35.107586478981609, 0.0],
              [-81.03714351813008, 35.107769478539204, 0.0],
              [-81.036948117542281, 35.108067078927085, 0.0],
              [-81.036975518140949, 35.108479478775031, 0.0],
              [-81.037448984172229, 35.108846278820877, 0.0],
              [-81.037532385121793, 35.109052479228623, 0.0],
              [-81.037420717953751, 35.109189812196931, 0.0],
              [-81.037086185209716, 35.10918961265245, 0.0],
              [-81.036612717751495, 35.10893727885118, 0.0],
              [-81.036055185066886, 35.109005611870131, 0.0],
              [-81.035943517564519, 35.109165878999548, 0.0],
              [-81.036138117734353, 35.109601212470736, 0.0],
              [-81.037113318065963, 35.110014078514332, 0.0],
              [-81.037196717444417, 35.11019741266982, 0.0],
              [-81.037084984458176, 35.110357678985302, 0.0],
              [-81.036778384238573, 35.110449079268086, 0.0],
              [-81.035886317657145, 35.110471411837977, 0.0],
              [-81.035551584137309, 35.110677211939773, 0.0],
              [-81.035551184876923, 35.111066611922027, 0.0],
              [-81.035829717573577, 35.111387478888489, 0.0],
              [-81.036860184795088, 35.112098212497578, 0.0],
              [-81.03744551847717, 35.112075678970761, 0.0],
              [-81.037724384685717, 35.111915612641127, 0.0],
              [-81.038281784151252, 35.112007612274617, 0.0],
              [-81.038644184848465, 35.111916278979002, 0.0],
              [-81.03903478495144, 35.111687478815625, 0.0],
              [-81.039368985229515, 35.111710612346627, 0.0],
              [-81.04017698487516, 35.112100412617018, 0.0],
              [-81.040427918206248, 35.112100611772178, 0.0],
              [-81.040846118551002, 35.111963478460709, 0.0],
              [-81.041068985047147, 35.111986479168159, 0.0],
              [-81.041542584837543, 35.11233047878472, 0.0],
              [-81.04243431849811, 35.112422678776582, 0.0],
              [-81.042768717406972, 35.112560212075692, 0.0],
              [-81.042963384723961, 35.112995478725622, 0.0],
              [-81.043130385078058, 35.113087278958652, 0.0],
              [-81.043911184300114, 35.11281287912211, 0.0],
              [-81.044580584565821, 35.112309412122755, 0.0],
              [-81.045165985199418, 35.112241212597588, 0.0],
              [-81.045472784752704, 35.112103878932288, 0.0],
              [-81.045695785229299, 35.112126878731743, 0.0],
              [-81.045695518353497, 35.112378811688018, 0.0],
              [-81.045360718330002, 35.112699211736576, 0.0],
              [-81.044440384850716, 35.113179811711397, 0.0],
              [-81.044328784305208, 35.11331707891825, 0.0],
              [-81.044328518081045, 35.113592012470285, 0.0],
              [-81.044662584276452, 35.114027278680197, 0.0],
              [-81.045163918547587, 35.114417012564239, 0.0],
              [-81.045163718131462, 35.114714812512993, 0.0],
              [-81.045024118163866, 35.11482921221522, 0.0],
              [-81.044048518497121, 35.114897212011577, 0.0],
              [-81.043881317556952, 35.114828412160584, 0.0],
              [-81.043630918498309, 35.114370211835222, 0.0],
              [-81.043157517937729, 35.113888878604058, 0.0],
              [-81.041346317653023, 35.113452612203332, 0.0],
              [-81.040036118080337, 35.113543279211271, 0.0],
              [-81.03878138468275, 35.113908878770864, 0.0],
              [-81.038446717425401, 35.114229278502023, 0.0],
              [-81.038223384549696, 35.11450407885058, 0.0],
              [-81.037665784143741, 35.114686878475418, 0.0],
              [-81.037498317471048, 35.114870012168893, 0.0],
              [-81.037553384156269, 35.115488412298745, 0.0],
              [-81.037858918178856, 35.116565211927409, 0.0],
              [-81.038220518296114, 35.117138012293395, 0.0],
              [-81.03852678471047, 35.117390079307995, 0.0],
              [-81.038861317726997, 35.117459079119975, 0.0],
              [-81.041565317577039, 35.117346412572431, 0.0],
              [-81.04231751856021, 35.117736278920994, 0.0],
              [-81.042818984429402, 35.117896878780378, 0.0],
              [-81.042790984808207, 35.11810307865791, 0.0],
              [-81.042512118025613, 35.118194478953505, 0.0],
              [-81.041425118236575, 35.118216679135664, 0.0],
              [-81.040226584690387, 35.118055611991366, 0.0],
              [-81.039585317657142, 35.118123878395991, 0.0],
              [-81.039111318490797, 35.11837547865175, 0.0],
              [-81.038859784218786, 35.118833279139579, 0.0],
              [-81.038859184759602, 35.119497478683911, 0.0],
              [-81.03902578490333, 35.120093212249479, 0.0],
              [-81.039248584707408, 35.120276611671635, 0.0],
              [-81.039833984652375, 35.120277011872531, 0.0],
              [-81.042036384414928, 35.119934879076197, 0.0],
              [-81.042538184773903, 35.119958078352397, 0.0],
              [-81.042788984380792, 35.120049878382986, 0.0],
              [-81.042956117698424, 35.120210279187219, 0.0],
              [-81.043597118217292, 35.120348079289037, 0.0],
              [-81.043764318432352, 35.120485612372768, 0.0],
              [-81.04373618457754, 35.120691811738439, 0.0],
              [-81.042732385176564, 35.120851479178967, 0.0],
              [-81.042202585150449, 35.121125879057907, 0.0],
              [-81.0403907177989, 35.121124812418813, 0.0],
              [-81.040028117524216, 35.121216211783477, 0.0],
              [-81.039804984096804, 35.121399278905592, 0.0],
              [-81.039832385174762, 35.121788611932885, 0.0],
              [-81.040333785009295, 35.122270012235958, 0.0],
              [-81.04094678518355, 35.122499412063682, 0.0],
              [-81.041364918179184, 35.122499612196272, 0.0],
              [-81.041810984802538, 35.122385411976339, 0.0],
              [-81.042034184259066, 35.12220241206321, 0.0],
              [-81.042424518323557, 35.122202612619432, 0.0],
              [-81.04245178478088, 35.122660679167836, 0.0],
              [-81.043176184619512, 35.123119278698539, 0.0],
              [-81.044179785130865, 35.123119879121127, 0.0],
              [-81.044235517430266, 35.123211478613342, 0.0],
              [-81.043984384911312, 35.123417612493981, 0.0],
              [-81.043342985107671, 35.123600412374607, 0.0],
              [-81.041280118504687, 35.123621879072367, 0.0],
              [-81.041084785127765, 35.123736412183064, 0.0],
              [-81.040973118143086, 35.123919478900675, 0.0],
              [-81.041000584217102, 35.124354678672617, 0.0],
              [-81.041501585044841, 35.125156612256248, 0.0],
              [-81.041975118264645, 35.125523412408455, 0.0],
              [-81.042950718221874, 35.125638478840173, 0.0],
              [-81.043257185143588, 35.125799078921126, 0.0],
              [-81.043256984867241, 35.125936478496989, 0.0],
              [-81.042810517633541, 35.126325612097091, 0.0],
              [-81.04281031796701, 35.126577612388225, 0.0],
              [-81.043060917921437, 35.126829612021361, 0.0],
              [-81.043785384342144, 35.127196612064701, 0.0],
              [-81.044231318119884, 35.127334279214971, 0.0],
              [-81.044621584078442, 35.127357411765097, 0.0],
              [-81.044872518566393, 35.127288812584574, 0.0],
              [-81.045402584471859, 35.126945612281595, 0.0],
              [-81.045849118317008, 35.12646487857721, 0.0],
              [-81.046685518515702, 35.126373878566149, 0.0],
              [-81.047271118560914, 35.126122212338416, 0.0],
              [-81.047633717441428, 35.126030812272468, 0.0],
              [-81.047772984339318, 35.126168278604943, 0.0],
              [-81.047717118114932, 35.1263056784423, 0.0],
              [-81.046238917393694, 35.126923279201058, 0.0],
              [-81.045904184300596, 35.127152078924489, 0.0],
              [-81.045875918131344, 35.127610211833144, 0.0],
              [-81.046126584219223, 35.127747678358659, 0.0],
              [-81.046572584529812, 35.127839611769382, 0.0],
              [-81.047408984152497, 35.127817278494057, 0.0],
              [-81.048273518344729, 35.127451278581646, 0.0],
              [-81.048914585077256, 35.127566212465311, 0.0],
              [-81.049081784910769, 35.127749478368059, 0.0],
              [-81.047714784597943, 35.128641879215678, 0.0],
              [-81.047547318209539, 35.128871011997944, 0.0],
              [-81.04749131814458, 35.129145678920672, 0.0],
              [-81.047574917579809, 35.12921447895787, 0.0],
              [-81.048467117661588, 35.129146211869653, 0.0],
              [-81.048550518053545, 35.129398211962346, 0.0],
              [-81.048828985058179, 35.129673278814437, 0.0],
              [-81.049191384526438, 35.129627678802009, 0.0],
              [-81.049805118122052, 35.12928447888514, 0.0],
              [-81.05025118470293, 35.129193079002931, 0.0],
              [-81.050194917612473, 35.129857411807663, 0.0],
              [-81.05050138464911, 35.13006361245246, 0.0],
              [-81.050780118064722, 35.130086612019802, 0.0],
              [-81.051058985211228, 35.129972278455085, 0.0],
              [-81.051198717865944, 35.129583012216436, 0.0],
              [-81.052230585040121, 35.129285878654969, 0.0],
              [-81.052397984916908, 35.129079879181567, 0.0],
              [-81.052454117996945, 35.128759279039784, 0.0],
              [-81.053178984918475, 35.128599411853386, 0.0],
              [-81.053652385131556, 35.129149212182597, 0.0],
              [-81.053652185143946, 35.129538612356811, 0.0],
              [-81.053345184414525, 35.129905012056341, 0.0],
              [-81.05326118460718, 35.130294279148544, 0.0],
              [-81.053260717864887, 35.130912678403881, 0.0],
              [-81.053622784524407, 35.131210679231501, 0.0],
              [-81.054068917924496, 35.131142078454829, 0.0],
              [-81.054264184142752, 35.130913211803644, 0.0],
              [-81.054543717733694, 35.130134611955434, 0.0],
              [-81.054878518102967, 35.129814079209815, 0.0],
              [-81.055464117414544, 35.129585478716621, 0.0],
              [-81.055743184240512, 35.129310812530782, 0.0],
              [-81.056524118148786, 35.128967612387662, 0.0],
              [-81.056719517624785, 35.128669879130804, 0.0],
              [-81.056775918359833, 35.127891212282364, 0.0],
              [-81.056943318489402, 35.127753878843414, 0.0],
              [-81.057277918033293, 35.127708278652207, 0.0],
              [-81.05791858498236, 35.128258278390824, 0.0],
              [-81.05830891748532, 35.128235611943481, 0.0],
              [-81.059480517865396, 35.127251279116912, 0.0],
              [-81.059592118488894, 35.127068078444985, 0.0],
              [-81.05964831797084, 35.126587212025193, 0.0],
              [-81.059759984593114, 35.126404012009246, 0.0],
              [-81.060373385000204, 35.126244011965426, 0.0],
              [-81.060568717703163, 35.126106678504534, 0.0],
              [-81.060568984869235, 35.125648611648131, 0.0],
              [-81.060708784328668, 35.125144811883487, 0.0],
              [-81.060792918475343, 35.124618078476985, 0.0],
              [-81.061016117430626, 35.124297412340226, 0.0],
              [-81.061072184537039, 35.123862279308085, 0.0],
              [-81.061323117485045, 35.123862412002168, 0.0],
              [-81.061545917746756, 35.124045678477394, 0.0],
              [-81.06179598505156, 35.125145279159064, 0.0],
              [-81.062297517578443, 35.125580678634108, 0.0],
              [-81.063524117934506, 35.125581412213755, 0.0],
              [-81.064472118531441, 35.125261012281797, 0.0],
              [-81.064499984133562, 35.125146611768891, 0.0],
              [-81.064583984779659, 35.124757278353435, 0.0],
              [-81.064807184823835, 35.124551212176719, 0.0],
              [-81.065671384018799, 35.124505811732803, 0.0],
              [-81.065866717798656, 35.124276811658909, 0.0],
              [-81.066591585095978, 35.124139679043509, 0.0],
              [-81.066954118281629, 35.12402541222594, 0.0],
              [-81.067205117998924, 35.1237736122374, 0.0],
              [-81.067484318385894, 35.123292678927157, 0.0],
              [-81.067484584749394, 35.122857478824166, 0.0],
              [-81.06731751782938, 35.122605478775419, 0.0],
              [-81.067345718133907, 35.122239012238481, 0.0],
              [-81.067652318149939, 35.122193411962556, 0.0],
              [-81.068320918043881, 35.12285787839879, 0.0],
              [-81.068683184010595, 35.122858011907098, 0.0],
              [-81.068906317812861, 35.122743678736143, 0.0],
              [-81.069129585213162, 35.122308479158988, 0.0],
              [-81.069157585098367, 35.122079478592354, 0.0],
              [-81.069576317536914, 35.121209412052444, 0.0],
              [-81.069855117447673, 35.121186612132895, 0.0],
              [-81.07005011811053, 35.121415678986089, 0.0],
              [-81.070077584608796, 35.122011078344585, 0.0],
              [-81.069826317734694, 35.122652278730826, 0.0],
              [-81.069825917521683, 35.123247812064484, 0.0],
              [-81.07007658423187, 35.123499879228156, 0.0],
              [-81.070940717568789, 35.123591878473675, 0.0],
              [-81.071163584579423, 35.123775278403471, 0.0],
              [-81.071330117841441, 35.124851812628044, 0.0],
              [-81.071552984989367, 35.125149612027357, 0.0],
              [-81.07199891841762, 35.125401812363272, 0.0],
              [-81.072667984214462, 35.125333278487332, 0.0],
              [-81.073531917544955, 35.125700078830256, 0.0],
              [-81.074284518521807, 35.125837812305406, 0.0],
              [-81.074702517784104, 35.126135812097111, 0.0],
              [-81.074897385115989, 35.126502278691866, 0.0],
              [-81.07509251827824, 35.126617012202836, 0.0],
              [-81.075454918046631, 35.126594212366648, 0.0],
              [-81.075650118416476, 35.126479612484346, 0.0],
              [-81.07565018467632, 35.126250612543885, 0.0],
              [-81.075483117593393, 35.126044478960004, 0.0],
              [-81.07548331797436, 35.125769478992339, 0.0],
              [-81.076264184822264, 35.125151478337138, 0.0],
              [-81.07645958502782, 35.124762212515208, 0.0],
              [-81.076320518058679, 35.124304078663869, 0.0],
              [-81.076320717774024, 35.123960478693618, 0.0],
              [-81.076571585078455, 35.123937612574757, 0.0],
              [-81.077128985050621, 35.124167011775704, 0.0],
              [-81.077435518290258, 35.124464811781714, 0.0],
              [-81.078076384354574, 35.124739812210713, 0.0],
              [-81.07821551819471, 35.125152078520181, 0.0],
              [-81.078521984707862, 35.125518679233153, 0.0],
              [-81.078967918104809, 35.12574787905001, 0.0],
              [-81.07930238486307, 35.12574807840474, 0.0],
              [-81.079888118184556, 35.125496278605638, 0.0],
              [-81.08027838518295, 35.125152878956733, 0.0],
              [-81.080362317826541, 35.124832278482188, 0.0],
              [-81.080641184806268, 35.124580479256068, 0.0],
              [-81.081226718517527, 35.124466012275981, 0.0],
              [-81.081366118127576, 35.124557678593263, 0.0],
              [-81.081449585157372, 35.124855478762257, 0.0],
              [-81.08186758426875, 35.124878612174726, 0.0],
              [-81.082732118459575, 35.124237612103563, 0.0],
              [-81.08368018468849, 35.123665079296522, 0.0],
              [-81.084544518381932, 35.123436412402093, 0.0],
              [-81.085882584247429, 35.12336821174744, 0.0],
              [-81.0869699841884, 35.122887412365635, 0.0],
              [-81.087555384439568, 35.122841811771146, 0.0],
              [-81.087666785125748, 35.122956278674252, 0.0],
              [-81.087583118115901, 35.123162478691086, 0.0],
              [-81.087025317943983, 35.123643278498619, 0.0],
              [-81.086997384852395, 35.123986811682734, 0.0],
              [-81.086913584338774, 35.124124212100384, 0.0],
              [-81.085631184371366, 35.124513278997881, 0.0],
              [-81.08493411758603, 35.124902411846413, 0.0],
              [-81.084153317472683, 35.125154078862749, 0.0],
              [-81.083679384433765, 35.125268478598755, 0.0],
              [-81.082731184949836, 35.126069879193928, 0.0],
              [-81.081783318358305, 35.126298612067714, 0.0],
              [-81.081560384436969, 35.126023611849639, 0.0],
              [-81.081532718341677, 35.125863278874299, 0.0],
              [-81.081309718262531, 35.125634078778234, 0.0],
              [-81.080975118361351, 35.125657078598088, 0.0],
              [-81.080500985049554, 35.126114878447943, 0.0],
              [-81.080138517585837, 35.126275079060882, 0.0],
              [-81.079469318138962, 35.126412279195868, 0.0],
              [-81.079051117940168, 35.126641211689247, 0.0],
              [-81.077991584983252, 35.126686678740981, 0.0],
              [-81.07757371743385, 35.126549078925756, 0.0],
              [-81.077071918174951, 35.126571811832484, 0.0],
              [-81.076820917574906, 35.12670907842611, 0.0],
              [-81.076597317416201, 35.127648078417913, 0.0],
              [-81.076067184252025, 35.128312211732755, 0.0],
              [-81.075982984649585, 35.129113812619359, 0.0],
              [-81.075815784145831, 35.129228279182094, 0.0],
              [-81.075509184515539, 35.129090678674956, 0.0],
              [-81.075342184392838, 35.128540878382829, 0.0],
              [-81.074784918508513, 35.128128412066246, 0.0],
              [-81.074729317521104, 35.127739012112279, 0.0],
              [-81.074422918102826, 35.127509878856195, 0.0],
              [-81.073614384255251, 35.127418012302989, 0.0],
              [-81.073029317455649, 35.127097011992078, 0.0],
              [-81.072638985176525, 35.127119879111916, 0.0],
              [-81.072527317627873, 35.12723427853409, 0.0],
              [-81.072443517987708, 35.127623612532894, 0.0],
              [-81.072526984239119, 35.127921412335546, 0.0],
              [-81.072861184860358, 35.128402612545329, 0.0],
              [-81.072693585110514, 35.128952212175314, 0.0],
              [-81.072693318062264, 35.129364412617875, 0.0],
              [-81.072888318178784, 35.129547811994847, 0.0],
              [-81.073668584878362, 35.129960278672641, 0.0],
              [-81.07375218513242, 35.130097811765303, 0.0],
              [-81.073612517826788, 35.13057881195337, 0.0],
              [-81.073417185112646, 35.130739012492072, 0.0],
              [-81.073249917812603, 35.130739012261635, 0.0],
              [-81.073138584440073, 35.13064721226818, 0.0],
              [-81.073027318193667, 35.130189078657928, 0.0],
              [-81.072469918258875, 35.1300056785905, 0.0],
              [-81.071968385073234, 35.129318278427505, 0.0],
              [-81.071885185091872, 35.128745678835266, 0.0],
              [-81.071355718111988, 35.128379011992713, 0.0],
              [-81.071355785113852, 35.128172878902795, 0.0],
              [-81.071523317876199, 35.127989678862939, 0.0],
              [-81.071551385022431, 35.127554478588749, 0.0],
              [-81.071467918234518, 35.12741707891665, 0.0],
              [-81.070910518112512, 35.127119078462563, 0.0],
              [-81.070799184734099, 35.126935879081728, 0.0],
              [-81.070436784058145, 35.126752478706543, 0.0],
              [-81.069544784513027, 35.126683411725018, 0.0],
              [-81.069321918112493, 35.126545879147628, 0.0],
              [-81.069210584604036, 35.126087812475433, 0.0],
              [-81.069015584981372, 35.125858612000023, 0.0],
              [-81.068653318528902, 35.125721012286128, 0.0],
              [-81.067817118429872, 35.125720678346987, 0.0],
              [-81.067315384093547, 35.125491411897109, 0.0],
              [-81.066562717850644, 35.125536878908285, 0.0],
              [-81.066255784560468, 35.125903279124707, 0.0],
              [-81.065976917486367, 35.126109278547275, 0.0],
              [-81.065252118050154, 35.12592561175564, 0.0],
              [-81.064917584255113, 35.125971278509859, 0.0],
              [-81.064805984512716, 35.12608567837686, 0.0],
              [-81.064805718271629, 35.126429278540861, 0.0],
              [-81.065028584611923, 35.126727211959967, 0.0],
              [-81.065028384636008, 35.126933278993036, 0.0],
              [-81.064191984811288, 35.127001678787721, 0.0],
              [-81.064080518005056, 35.127139078404355, 0.0],
              [-81.06405231785439, 35.127482611973598, 0.0],
              [-81.063828984807316, 35.127803078702662, 0.0],
              [-81.06363378487778, 35.127894679076171, 0.0],
              [-81.062881117539348, 35.128031678778363, 0.0],
              [-81.062685585091344, 35.12837521209088, 0.0],
              [-81.062434717541549, 35.128397878350661, 0.0],
              [-81.062072717690569, 35.127985478784922, 0.0],
              [-81.06196178519707, 35.127138011653116, 0.0],
              [-81.061627184627881, 35.127160678433071, 0.0],
              [-81.061375984410958, 35.127527078746709, 0.0],
              [-81.061403584606595, 35.127916612196735, 0.0],
              [-81.061709985004882, 35.128237278584905, 0.0],
              [-81.061681917509034, 35.128512212419743, 0.0],
              [-81.060483184414906, 35.128442811775727, 0.0],
              [-81.06020418432476, 35.128717479242383, 0.0],
              [-81.060231517860217, 35.129381678355962, 0.0],
              [-81.059589985035288, 35.129862411667901, 0.0],
              [-81.058752784985927, 35.130869812616453, 0.0],
              [-81.058640584377187, 35.131671412509675, 0.0],
              [-81.05852891767212, 35.131831678560204, 0.0],
              [-81.058166717400283, 35.131785679104397, 0.0],
              [-81.057832317684571, 35.131510611871654, 0.0],
              [-81.056800584381776, 35.1317162122035, 0.0],
              [-81.056605184460963, 35.131991011743217, 0.0],
              [-81.056576584198652, 35.132861411976144, 0.0],
              [-81.055878984763908, 35.133479412474344, 0.0],
              [-81.055739384565413, 35.133822811760794, 0.0],
              [-81.055850584703265, 35.134166411980374, 0.0],
              [-81.055989984870763, 35.134235212120075, 0.0],
              [-81.05677071845615, 35.134189878438562, 0.0],
              [-81.056937784964816, 35.134281611848166, 0.0],
              [-81.057132718454938, 35.134693879017625, 0.0],
              [-81.057132384705895, 35.135014611884884, 0.0],
              [-81.057410985196498, 35.135335412036618, 0.0],
              [-81.058246917492497, 35.135839678960018, 0.0],
              [-81.058581117450146, 35.136298012298688, 0.0],
              [-81.059138784153461, 35.136229478434245, 0.0],
              [-81.059557184776679, 35.136046478791279, 0.0],
              [-81.060449384897339, 35.136047012112456, 0.0],
              [-81.06072831793584, 35.135909612149128, 0.0],
              [-81.061342384196138, 35.134856412142987, 0.0],
              [-81.061566184317911, 35.133917478684033, 0.0],
              [-81.06173358435737, 35.133780012477644, 0.0],
              [-81.062179718205741, 35.133780411760782, 0.0],
              [-81.062318984942493, 35.133940679033685, 0.0],
              [-81.061592784415637, 35.135589411846603, 0.0],
              [-81.061536784945474, 35.135887078597079, 0.0],
              [-81.061620385018344, 35.13593301250485, 0.0],
              [-81.0625405850475, 35.135819012040422, 0.0],
              [-81.062707918174127, 35.13591067879463, 0.0],
              [-81.06259591823985, 35.136391478450861, 0.0],
              [-81.062707384349167, 35.136574879182668, 0.0],
              [-81.06301391787467, 35.136735279156326, 0.0],
              [-81.063152917622702, 35.137101878796805, 0.0],
              [-81.063180584569125, 35.137491212069229, 0.0],
              [-81.06362631808193, 35.137903678541967, 0.0],
              [-81.064490584471798, 35.138087278916814, 0.0],
              [-81.064741118223253, 35.138545478979168, 0.0],
              [-81.065995517569149, 35.139050012213495, 0.0],
              [-81.066274185023389, 35.139325011985726, 0.0],
              [-81.066273918255121, 35.139554012537538, 0.0],
              [-81.0661065840509, 35.139668411935268, 0.0],
              [-81.065576784801138, 35.139668212479535, 0.0],
              [-81.063988184644188, 35.138682611642331, 0.0],
              [-81.063291384889453, 35.138453211645896, 0.0],
              [-81.062371784742936, 35.137811478847105, 0.0],
              [-81.061535717425883, 35.137375879182635, 0.0],
              [-81.061145385096466, 35.137261212083459, 0.0],
              [-81.060476118049053, 35.137375411750931, 0.0],
              [-81.060029984163748, 35.137581279151917, 0.0],
              [-81.059416384928852, 35.137741279141942, 0.0],
              [-81.059304717729503, 35.137878679163926, 0.0],
              [-81.059332384785606, 35.138130612589933, 0.0],
              [-81.059917385164908, 35.13881807839244, 0.0],
              [-81.059945184735327, 35.139001278644599, 0.0],
              [-81.060140118064425, 35.139344878472421, 0.0],
              [-81.060557584690343, 35.140307078352002, 0.0],
              [-81.060556984618799, 35.140994211803623, 0.0],
              [-81.060193984692262, 35.141658279237632, 0.0],
              [-81.060081918448915, 35.142368278797143, 0.0],
              [-81.059718917674559, 35.143032278411212, 0.0],
              [-81.059634984128991, 35.14339867896814, 0.0],
              [-81.059411717739337, 35.143650612180465, 0.0],
              [-81.059077117563305, 35.143627478480845, 0.0],
              [-81.058185117899384, 35.143329211900131, 0.0],
              [-81.058073717692778, 35.14307727862024, 0.0],
              [-81.058102117941587, 35.142550478777693, 0.0],
              [-81.058325584296227, 35.141932211909946, 0.0],
              [-81.058911917729986, 35.141107878611145, 0.0],
              [-81.058745184491514, 35.140283279190342, 0.0],
              [-81.058494717504004, 35.139710678374968, 0.0],
              [-81.058048918074405, 35.139344012338903, 0.0],
              [-81.057686385035353, 35.139389478644382, 0.0],
              [-81.057156318106252, 35.139870212017328, 0.0],
              [-81.056152184851939, 35.140281878326157, 0.0],
              [-81.055873184078024, 35.140510812324301, 0.0],
              [-81.055454718245386, 35.140693878629108, 0.0],
              [-81.05486918516354, 35.140808011620912, 0.0],
              [-81.053892584202316, 35.141540412578877, 0.0],
              [-81.052469784136704, 35.142341278409575, 0.0],
              [-81.052497384252447, 35.142730678299309, 0.0],
              [-81.052608917771366, 35.142822411744078, 0.0],
              [-81.054198184342454, 35.142892012234476, 0.0],
              [-81.055034784473463, 35.142732079178998, 0.0],
              [-81.056066317761193, 35.142915878599418, 0.0],
              [-81.056623518463326, 35.143534478679278, 0.0],
              [-81.056817918357027, 35.144496611655526, 0.0],
              [-81.056957184261691, 35.144611212263541, 0.0],
              [-81.057486984606996, 35.144542812099949, 0.0],
              [-81.0576541843988, 35.144634478356963, 0.0],
              [-81.057709785184471, 35.144909412560082, 0.0],
              [-81.057821184932777, 35.145001012349979, 0.0],
              [-81.058323117520104, 35.145047079045668, 0.0],
              [-81.058713185034165, 35.145390879024625, 0.0],
              [-81.058657118419532, 35.145734479187155, 0.0],
              [-81.058294117417262, 35.14651301242592, 0.0],
              [-81.058014517737277, 35.147314412523109, 0.0],
              [-81.057205585177698, 35.14756601258172, 0.0],
              [-81.057093984153823, 35.147680478348612, 0.0],
              [-81.056925717969463, 35.148940078659301, 0.0],
              [-81.057037117540162, 35.149031679204342, 0.0],
              [-81.05745538418347, 35.14903201217593, 0.0],
              [-81.057622517605154, 35.149192279117003, 0.0],
              [-81.057593717599048, 35.150383412598543, 0.0],
              [-81.058067584953363, 35.150681278691621, 0.0],
              [-81.058959584642992, 35.151048211749952, 0.0],
              [-81.059377917815851, 35.151048478889052, 0.0],
              [-81.060047317426978, 35.150865611751406, 0.0],
              [-81.060354117429881, 35.150911612002261, 0.0],
              [-81.060520984506013, 35.151301012121728, 0.0],
              [-81.060716184072888, 35.15141561209326, 0.0],
              [-81.061301718488679, 35.151553411631724, 0.0],
              [-81.061608184155133, 35.15180541211879, 0.0],
              [-81.061719517867203, 35.15219481165186, 0.0],
              [-81.061970185176705, 35.152424012502159, 0.0],
              [-81.063001917733416, 35.152561878348067, 0.0],
              [-81.06300178472533, 35.152790879007824, 0.0],
              [-81.061690984653353, 35.152996478770348, 0.0],
              [-81.061551584808257, 35.152904878462472, 0.0],
              [-81.061440184169655, 35.152675811732358, 0.0],
              [-81.060994317538672, 35.152331878320993, 0.0],
              [-81.0606597174379, 35.152240212145692, 0.0],
              [-81.060297184055699, 35.152331478399269, 0.0],
              [-81.059961784777087, 35.153201812331254, 0.0],
              [-81.059961584508883, 35.153499478501132, 0.0],
              [-81.060072985076403, 35.153614078987083, 0.0],
              [-81.060881584539374, 35.15391221211155, 0.0],
              [-81.061020785023771, 35.154210078634812, 0.0],
              [-81.06096358412907, 35.155927812475589, 0.0],
              [-81.061298184855005, 35.156179878794454, 0.0],
              [-81.061604718466214, 35.15627167879768, 0.0],
              [-81.061967317693728, 35.15622607858959, 0.0],
              [-81.062106784513915, 35.156111611688388, 0.0],
              [-81.062190585082888, 35.155997212259052, 0.0],
              [-81.063306317679434, 35.155722812088406, 0.0],
              [-81.063975517487179, 35.155883411910793, 0.0],
              [-81.064309918517381, 35.156204279144653, 0.0],
              [-81.064978985078767, 35.156479478577516, 0.0],
              [-81.065313517664819, 35.156708678562168, 0.0],
              [-81.065759784373725, 35.156663078905673, 0.0],
              [-81.066122517850189, 35.156411211607235, 0.0],
              [-81.066429784257906, 35.155724211842369, 0.0],
              [-81.066680984817751, 35.155426612163168, 0.0],
              [-81.066959784078122, 35.155426678666572, 0.0],
              [-81.066959385116022, 35.156045078499488, 0.0],
              [-81.066708118256585, 35.156617678707462, 0.0],
              [-81.066735584154472, 35.157007012018347, 0.0],
              [-81.067042317534998, 35.157190278686009, 0.0],
              [-81.067655784357115, 35.157190612322296, 0.0],
              [-81.068492517811379, 35.156962012359109, 0.0],
              [-81.069300918029299, 35.157603679170407, 0.0],
              [-81.069272784392325, 35.157855612417187, 0.0],
              [-81.069105384161873, 35.157924278316678, 0.0],
              [-81.068213118128583, 35.157717678882378, 0.0],
              [-81.06693018425608, 35.157969011672968, 0.0],
              [-81.066679118411301, 35.158129212084845, 0.0],
              [-81.066650918359755, 35.158495678441199, 0.0],
              [-81.066929584284651, 35.158724879086385, 0.0],
              [-81.067207985151782, 35.159549478655997, 0.0],
              [-81.067904718526606, 35.159939212391443, 0.0],
              [-81.06829458504167, 35.160809812280277, 0.0],
              [-81.069130784389444, 35.161543012508069, 0.0],
              [-81.069242318360693, 35.161703478602391, 0.0],
              [-81.069213984935885, 35.162367611794615, 0.0],
              [-81.069938717553811, 35.163100878459858, 0.0],
              [-81.069965584255826, 35.164475078641914, 0.0],
              [-81.069881517622051, 35.165093479061319, 0.0],
              [-81.069965117837455, 35.165208079080102, 0.0],
              [-81.070327717724112, 35.165139478421167, 0.0],
              [-81.070913717656893, 35.164773278298966, 0.0],
              [-81.07138771798158, 35.16504827837079, 0.0],
              [-81.071498984269638, 35.16534607918544, 0.0],
              [-81.071470517661339, 35.166285211937897, 0.0],
              [-81.071218518258149, 35.167728011700575, 0.0],
              [-81.071273917737656, 35.168392278296714, 0.0],
              [-81.071468917493775, 35.168781679151309, 0.0],
              [-81.071412785016165, 35.169377211829037, 0.0],
              [-81.071217317901073, 35.169789278263124, 0.0],
              [-81.070938317854797, 35.169789212336511, 0.0],
              [-81.070380784607892, 35.169239278668208, 0.0],
              [-81.070185584878317, 35.169170478861105, 0.0],
              [-81.069767117907304, 35.169262011730837, 0.0],
              [-81.068873784720765, 35.170406812525165, 0.0],
              [-81.068873518233559, 35.17093361199472, 0.0],
              [-81.069040784186711, 35.171025278956336, 0.0],
              [-81.069793784743752, 35.171117212108761, 0.0],
              [-81.070212118517432, 35.171277678459305, 0.0],
              [-81.070435117883932, 35.171598412458408, 0.0],
              [-81.07116018469938, 35.17171327851397, 0.0],
              [-81.071271718321626, 35.171896479024831, 0.0],
              [-81.070796517999938, 35.173408078428238, 0.0],
              [-81.070824117842136, 35.173820278439315, 0.0],
              [-81.071018984201714, 35.17420967923195, 0.0],
              [-81.070907117748277, 35.174851011690102, 0.0],
              [-81.070460784851193, 35.174736278385112, 0.0],
              [-81.070209784255411, 35.174782012487213, 0.0],
              [-81.070125917915618, 35.17501101191079, 0.0],
              [-81.070209184388617, 35.175789812186437, 0.0],
              [-81.070348384421564, 35.176110478488269, 0.0],
              [-81.070375917420193, 35.176683079201993, 0.0],
              [-81.070654517938962, 35.177095478926603, 0.0],
              [-81.071072717807922, 35.177485012078691, 0.0],
              [-81.071211984854017, 35.177759878250562, 0.0],
              [-81.071211384343812, 35.178607279118864, 0.0],
              [-81.071127718238102, 35.178790478639101, 0.0],
              [-81.070541917931081, 35.178698679104102, 0.0],
              [-81.070095784930444, 35.178377812228817, 0.0],
              [-81.069817184634857, 35.177828012316873, 0.0],
              [-81.069678517527947, 35.176682812343991, 0.0],
              [-81.069400117510639, 35.175926812400938, 0.0],
              [-81.069316385138436, 35.175881011925313, 0.0],
              [-81.069232984694622, 35.175491612067276, 0.0],
              [-81.069512384669977, 35.174919078810262, 0.0],
              [-81.069484918197148, 35.174186212264459, 0.0],
              [-81.069290518010021, 35.17304087908834, 0.0],
              [-81.069039584619645, 35.17281181166117, 0.0],
              [-81.068788584499231, 35.172742879092361, 0.0],
              [-81.068063184208327, 35.172765412313105, 0.0],
              [-81.067644785167388, 35.172879811595699, 0.0],
              [-81.067281984208265, 35.173131678919361, 0.0],
              [-81.06672438423243, 35.172948212560314, 0.0],
              [-81.066892318482061, 35.172009212551814, 0.0],
              [-81.066808917697799, 35.171803012020732, 0.0],
              [-81.066279184562305, 35.171321812425781, 0.0],
              [-81.066167784679052, 35.17113847890159, 0.0],
              [-81.066195917980508, 35.17074921225489, 0.0],
              [-81.067116784593466, 35.169970812169993, 0.0],
              [-81.067312118505725, 35.169993811653136, 0.0],
              [-81.067981184038317, 35.170406278664565, 0.0],
              [-81.068204517625034, 35.170269078900368, 0.0],
              [-81.068176784142466, 35.169948412555144, 0.0],
              [-81.067925984988008, 35.169558878754479, 0.0],
              [-81.068122117846826, 35.168390879213398, 0.0],
              [-81.067899317623016, 35.167932678424528, 0.0],
              [-81.067341784355591, 35.167405678544334, 0.0],
              [-81.067202517666857, 35.167176612000937, 0.0],
              [-81.067147317347263, 35.166420678381762, 0.0],
              [-81.066673384133097, 35.166099812319679, 0.0],
              [-81.065836784582757, 35.165847478485276, 0.0],
              [-81.06502811820836, 35.165686878497048, 0.0],
              [-81.064609917523441, 35.165526412210426, 0.0],
              [-81.06441471735836, 35.165343078606675, 0.0],
              [-81.0644429173459, 35.164953678788841, 0.0],
              [-81.064554584244476, 35.164816278959087, 0.0],
              [-81.064443385089589, 35.164381078887736, 0.0],
              [-81.064527184708552, 35.16417501192813, 0.0],
              [-81.064861984761222, 35.16396901198862, 0.0],
              [-81.064917984512476, 35.16369407916487, 0.0],
              [-81.064639518195733, 35.163167211742277, 0.0],
              [-81.063440985024698, 35.161975678893626, 0.0],
              [-81.063218317514952, 35.161563278426343, 0.0],
              [-81.063246584826089, 35.16089907846073, 0.0],
              [-81.063721384012695, 35.160120611688761, 0.0],
              [-81.063888718008343, 35.160006078531907, 0.0],
              [-81.064140117794935, 35.159616812132654, 0.0],
              [-81.064251917610335, 35.159227612487889, 0.0],
              [-81.064057518240148, 35.158082212138694, 0.0],
              [-81.063890184176927, 35.15799061186749, 0.0],
              [-81.06358338511707, 35.158082012371707, 0.0],
              [-81.063081118523868, 35.158494078844932, 0.0],
              [-81.062662184581583, 35.159272678387005, 0.0],
              [-81.062494784566425, 35.159387078747294, 0.0],
              [-81.061685784734507, 35.159569878422488, 0.0],
              [-81.0613229842686, 35.159867412586856, 0.0],
              [-81.061016318354092, 35.159981811678342, 0.0],
              [-81.060430717745405, 35.159889879113194, 0.0],
              [-81.060151918460363, 35.159660812088241, 0.0],
              [-81.059705717916998, 35.159660612260623, 0.0],
              [-81.059315184119356, 35.159774811947131, 0.0],
              [-81.058562118141111, 35.159797412170292, 0.0],
              [-81.057836718029634, 35.160186412113291, 0.0],
              [-81.057473918348421, 35.160552611659817, 0.0],
              [-81.057417518543005, 35.16128547914964, 0.0],
              [-81.056970918416738, 35.161606011777558, 0.0],
              [-81.056440784344218, 35.16183467860133, 0.0],
              [-81.056078317993013, 35.16181161183605, 0.0],
              [-81.056078717898785, 35.16135361243542, 0.0],
              [-81.056916384026152, 35.160071278315129, 0.0],
              [-81.057000517628026, 35.159590412175874, 0.0],
              [-81.057140185203082, 35.159315612422041, 0.0],
              [-81.057837984591544, 35.158560211944398, 0.0],
              [-81.057949917478467, 35.158193811687212, 0.0],
              [-81.058117384918035, 35.157964811937632, 0.0],
              [-81.058229318256977, 35.15757547878583, 0.0],
              [-81.058173784705943, 35.157209012064001, 0.0],
              [-81.058062318409185, 35.157071478401107, 0.0],
              [-81.058006984426783, 35.156544678592638, 0.0],
              [-81.057421717465701, 35.156086412082367, 0.0],
              [-81.057031384064985, 35.155948678606642, 0.0],
              [-81.056110984337636, 35.156085612191234, 0.0],
              [-81.054158384231712, 35.156634278356442, 0.0],
              [-81.053935384530291, 35.15654247876239, 0.0],
              [-81.053935584465677, 35.156290612082614, 0.0],
              [-81.054047318236371, 35.156153211877047, 0.0],
              [-81.054967918260431, 35.155787278953937, 0.0],
              [-81.055414518504463, 35.155237878469045, 0.0],
              [-81.05566578444315, 35.155077678477269, 0.0],
              [-81.056083985094233, 35.154986278720401, 0.0],
              [-81.058315184822177, 35.154758412429665, 0.0],
              [-81.058594584282545, 35.154208878855606, 0.0],
              [-81.058566984239704, 35.15386527844376, 0.0],
              [-81.057869984834383, 35.153544278672378, 0.0],
              [-81.057870518296738, 35.152903012072883, 0.0],
              [-81.058037984851794, 35.152651078537858, 0.0],
              [-81.058038584039053, 35.151941079096069, 0.0],
              [-81.057843585020208, 35.151780678315269, 0.0],
              [-81.057787984418496, 35.151505812426414, 0.0],
              [-81.057564984850387, 35.151414011737167, 0.0],
              [-81.056951384553386, 35.151436611817147, 0.0],
              [-81.056867718407659, 35.151551012259276, 0.0],
              [-81.055501317969913, 35.15161901171502, 0.0],
              [-81.055361984350057, 35.151527278945089, 0.0],
              [-81.055445918043333, 35.151252478292136, 0.0],
              [-81.056003917721668, 35.15086347839275, 0.0],
              [-81.056087717956913, 35.150703212103807, 0.0],
              [-81.055948717754646, 35.150176411658485, 0.0],
              [-81.0555863841074, 35.14994707910332, 0.0],
              [-81.054164184130684, 35.149831878485898, 0.0],
              [-81.054052784542804, 35.149763078634095, 0.0],
              [-81.054136917358861, 35.149190478722375, 0.0],
              [-81.054053317417939, 35.149144678697304, 0.0],
              [-81.054025584938231, 35.148938478663148, 0.0],
              [-81.053663384274358, 35.148663478349867, 0.0],
              [-81.051990584647825, 35.148227278965088, 0.0],
              [-81.051349317823011, 35.148158212593422, 0.0],
              [-81.050959118138948, 35.147974811866533, 0.0],
              [-81.05026211748384, 35.147928611672825, 0.0],
              [-81.04998338512209, 35.147768078734209, 0.0],
              [-81.049341984142004, 35.147767678375338, 0.0],
              [-81.049063384259455, 35.147561412184736, 0.0],
              [-81.049035584909177, 35.147378078753377, 0.0],
              [-81.048757118097527, 35.147034411686668, 0.0],
              [-81.048282984682601, 35.147079878954642, 0.0],
              [-81.047808717405672, 35.147308678698906, 0.0],
              [-81.047445917722428, 35.147720811773041, 0.0],
              [-81.047473317601899, 35.148201812491578, 0.0],
              [-81.047696117568407, 35.148499678418972, 0.0],
              [-81.048253517734352, 35.148751878640184, 0.0],
              [-81.048253317777238, 35.149026678799075, 0.0],
              [-81.048030118500492, 35.149118279103021, 0.0],
              [-81.046970384591802, 35.149140611667512, 0.0],
              [-81.046719317842857, 35.149277812431372, 0.0],
              [-81.046328518133819, 35.14973561202001, 0.0],
              [-81.046216184046827, 35.150514278432247, 0.0],
              [-81.045936917554428, 35.151040879267718, 0.0],
              [-81.045991917974405, 35.151796678968338, 0.0],
              [-81.046102918095926, 35.152346478869909, 0.0],
              [-81.046242317921596, 35.152484012061826, 0.0],
              [-81.046771918112583, 35.152644611628027, 0.0],
              [-81.046911184320962, 35.152782212450091, 0.0],
              [-81.046938584097688, 35.153309011867627, 0.0],
              [-81.047077784347792, 35.153561011773206, 0.0],
              [-81.047077517588377, 35.153858812440589, 0.0],
              [-81.045766917394829, 35.15372047831049, 0.0],
              [-81.04551631755217, 35.153399812404572, 0.0],
              [-81.04506998450023, 35.15342241198325, 0.0],
              [-81.04493051785316, 35.153513879058352, 0.0],
              [-81.044818518120266, 35.154017679254949, 0.0],
              [-81.044483918272462, 35.153948812204476, 0.0],
              [-81.044010317470338, 35.153627812417675, 0.0],
              [-81.043954784523066, 35.153330012285885, 0.0],
              [-81.043843318472213, 35.153215412254198, 0.0],
              [-81.043620318556535, 35.153169478696519, 0.0],
              [-81.043508584390835, 35.153329679238674, 0.0],
              [-81.043451984066948, 35.154200079079324, 0.0],
              [-81.043284517667686, 35.15429161204078, 0.0],
              [-81.042503918549926, 35.154039078678991, 0.0],
              [-81.042393118451272, 35.153329012512053, 0.0],
              [-81.042225917903025, 35.153145812348612, 0.0],
              [-81.041751784239437, 35.153145411986671, 0.0],
              [-81.041081784219983, 35.153877878908951, 0.0],
              [-81.040049985166732, 35.153785612493515, 0.0],
              [-81.039882917481364, 35.153670878746702, 0.0],
              [-81.039883518015145, 35.153098278740217, 0.0],
              [-81.039716318396614, 35.152915012031158, 0.0],
              [-81.039409517628428, 35.152914678418085, 0.0],
              [-81.038461117420169, 35.153234879207446, 0.0],
              [-81.038098718123749, 35.153188678587796, 0.0],
              [-81.037485384880853, 35.152913412001851, 0.0],
              [-81.036621118172633, 35.152821279054301, 0.0],
              [-81.036119317761091, 35.152568879162786, 0.0],
              [-81.034390717676203, 35.152270012395505, 0.0],
              [-81.033665584147968, 35.152315279050427, 0.0],
              [-81.033191184763211, 35.152589812212689, 0.0],
              [-81.033079385078665, 35.152795812564946, 0.0],
              [-81.03307911829566, 35.153047678378329, 0.0],
              [-81.034026717647194, 35.153575211688299, 0.0],
              [-81.03413798453164, 35.153827212413148, 0.0],
              [-81.034165317932562, 35.154308278736046, 0.0],
              [-81.034611317419106, 35.154537678303029, 0.0],
              [-81.035586317709146, 35.15543161244355, 0.0],
              [-81.035725517635981, 35.1557752786861, 0.0],
              [-81.035751384800264, 35.157584678655645, 0.0],
              [-81.036336185124156, 35.158363878472827, 0.0],
              [-81.036307984687213, 35.158615679073819, 0.0],
              [-81.035414784878455, 35.15937087870239, 0.0],
              [-81.035247117531966, 35.159668478776339, 0.0],
              [-81.035246784908466, 35.15989761246847, 0.0],
              [-81.035552784445755, 35.160630812195869, 0.0],
              [-81.035524184630006, 35.161386478713709, 0.0],
              [-81.035858384571256, 35.16168447903889, 0.0],
              [-81.035997384152765, 35.162074012146462, 0.0],
              [-81.036275985148819, 35.162303212132578, 0.0],
              [-81.036833918102559, 35.162257812381888, 0.0],
              [-81.037252384596655, 35.162097678995316, 0.0],
              [-81.037782384296392, 35.162098078670432, 0.0],
              [-81.03794958450662, 35.162189811666607, 0.0],
              [-81.037893518382475, 35.162418812471557, 0.0],
              [-81.036609985094572, 35.163036412172012, 0.0],
              [-81.03624691843099, 35.163425479028312, 0.0],
              [-81.036246784743739, 35.163631678834498, 0.0],
              [-81.03649758435823, 35.163838011664716, 0.0],
              [-81.036497184929232, 35.164112811955285, 0.0],
              [-81.036134318268012, 35.164478878377274, 0.0],
              [-81.036133918535441, 35.164753811845721, 0.0],
              [-81.037555918044262, 35.165281612051032, 0.0],
              [-81.03800171794542, 35.165625478998663, 0.0],
              [-81.038280317963853, 35.165992078763331, 0.0],
              [-81.038558984291797, 35.166175478761822, 0.0],
              [-81.038977384288188, 35.166107078566277, 0.0],
              [-81.03925678500886, 35.165717878756034, 0.0],
              [-81.039592585129782, 35.164618811962669, 0.0],
              [-81.039899785146915, 35.164252478397266, 0.0],
              [-81.03998391768819, 35.163794478696303, 0.0],
              [-81.040123518294379, 35.163680079182818, 0.0],
              [-81.040457984507469, 35.163794878298127, 0.0],
              [-81.040820318431258, 35.16409281169512, 0.0],
              [-81.041405784814856, 35.164253479132704, 0.0],
              [-81.042577118509556, 35.164300078954177, 0.0],
              [-81.043051185237331, 35.164414879147103, 0.0],
              [-81.043385984671971, 35.164300612291768, 0.0],
              [-81.044112118133, 35.163316212023467, 0.0],
              [-81.044670517486907, 35.162766878332164, 0.0],
              [-81.044949517661948, 35.162698279057501, 0.0],
              [-81.045172518039976, 35.162835878316621, 0.0],
              [-81.045144384077943, 35.163064878896378, 0.0],
              [-81.044809317432296, 35.163499812335239, 0.0],
              [-81.044027184823307, 35.164530078545745, 0.0],
              [-81.043915117907559, 35.16510261218577, 0.0],
              [-81.044165984845307, 35.165263079175972, 0.0],
              [-81.044751584079137, 35.165446678626502, 0.0],
              [-81.044667318236677, 35.166042078726676, 0.0],
              [-81.045140917576163, 35.166592078715929, 0.0],
              [-81.04514071774139, 35.166889812462593, 0.0],
              [-81.045001117811665, 35.167004278915648, 0.0],
              [-81.044722118102982, 35.166981079060974, 0.0],
              [-81.044303985097841, 35.166797811808152, 0.0],
              [-81.043690917906488, 35.16622481173264, 0.0],
              [-81.043021918197581, 35.165880811639767, 0.0],
              [-81.04238051787739, 35.165674211666541, 0.0],
              [-81.041655317640362, 35.165719478979049, 0.0],
              [-81.041376317997788, 35.16581087851003, 0.0],
              [-81.041097118229018, 35.16610847845471, 0.0],
              [-81.041068917394313, 35.16642907845268, 0.0],
              [-81.041347517647196, 35.166727078292169, 0.0],
              [-81.0417935850829, 35.166956411833588, 0.0],
              [-81.042044117911033, 35.167437611657576, 0.0],
              [-81.042545784250478, 35.16771281249018, 0.0],
              [-81.042629385026416, 35.167896012009862, 0.0],
              [-81.042210384324022, 35.168468412010959, 0.0],
              [-81.042070517588243, 35.168880478294462, 0.0],
              [-81.042125918074476, 35.16931567828621, 0.0],
              [-81.043269784672106, 35.168927079199385, 0.0],
              [-81.044078717753678, 35.168927678596894, 0.0],
              [-81.044831585021029, 35.169134279043526, 0.0],
              [-81.04530518434612, 35.169707078704377, 0.0],
              [-81.046336718040621, 35.170280412242057, 0.0],
              [-81.046698584159316, 35.170967678260624, 0.0],
              [-81.04666998518185, 35.171677678457982, 0.0],
              [-81.046334517814131, 35.172479078457869, 0.0],
              [-81.045775984622708, 35.173165811858659, 0.0],
              [-81.045329384705511, 35.173509211776889, 0.0],
              [-81.043962118061003, 35.174035078516063, 0.0],
              [-81.043487185058723, 35.174699011849775, 0.0],
              [-81.043039785092787, 35.1758438121906, 0.0],
              [-81.043039517923219, 35.176141611682446, 0.0],
              [-81.043262584949545, 35.176256278253639, 0.0],
              [-81.043848184840797, 35.17634827867397, 0.0],
              [-81.044183118017145, 35.176211078875816, 0.0],
              [-81.044574117666983, 35.175661678489028, 0.0],
              [-81.044992784902803, 35.17534127914324, 0.0],
              [-81.045383584350418, 35.175158278382625, 0.0],
              [-81.045801984926484, 35.17506701169232, 0.0],
              [-81.046387784442331, 35.175090211830806, 0.0],
              [-81.04700118516341, 35.175342478450204, 0.0],
              [-81.047614785023725, 35.175503212103244, 0.0],
              [-81.047781985231069, 35.175663611915645, 0.0],
              [-81.047809384355233, 35.176167612436821, 0.0],
              [-81.047223584394288, 35.176167212370267, 0.0],
              [-81.046136118397939, 35.17582307911848, 0.0],
              [-81.045605985152847, 35.175845611764494, 0.0],
              [-81.045215184132303, 35.176166012466624, 0.0],
              [-81.044991317576404, 35.176898812280761, 0.0],
              [-81.044628518491947, 35.176990212178993, 0.0],
              [-81.043819718201448, 35.177012479178622, 0.0],
              [-81.04365218504573, 35.177126879016463, 0.0],
              [-81.043512318208641, 35.177493278840743, 0.0],
              [-81.043065984688269, 35.177607479014398, 0.0],
              [-81.0427591182534, 35.177584412459275, 0.0],
              [-81.042285517468471, 35.177034479193772, 0.0],
              [-81.041950584890515, 35.177057078587254, 0.0],
              [-81.041838584438835, 35.177492212442132, 0.0],
              [-81.041837517520733, 35.178637411662478, 0.0],
              [-81.041669985118958, 35.178774678858019, 0.0],
              [-81.041391118384709, 35.178705811831186, 0.0],
              [-81.041279718108768, 35.178522479050322, 0.0],
              [-81.041197184832257, 35.177354278928938, 0.0],
              [-81.040890784415964, 35.176964812009032, 0.0],
              [-81.040555984731412, 35.177033279008228, 0.0],
              [-81.040304384186953, 35.17751401248217, 0.0],
              [-81.040248117568467, 35.177926279139776, 0.0],
              [-81.039996718358665, 35.178292611841329, 0.0],
              [-81.040024184922402, 35.178659078732984, 0.0],
              [-81.04097198437907, 35.179346811647299, 0.0],
              [-81.041194718004036, 35.17973627895686, 0.0],
              [-81.041696384720893, 35.180194678886942, 0.0],
              [-81.041696184273434, 35.18040087858359, 0.0],
              [-81.041333584372936, 35.180400612195001, 0.0],
              [-81.040747985143483, 35.180102478904296, 0.0],
              [-81.04046938449379, 35.179758678821877, 0.0],
              [-81.040051184783849, 35.179621012521217, 0.0],
              [-81.039799984900071, 35.17966667838347, 0.0],
              [-81.038514984691304, 35.181383611667229, 0.0],
              [-81.038514318409071, 35.18204781162077, 0.0],
              [-81.038681384847266, 35.182254078986404, 0.0],
              [-81.038904518289954, 35.182368678545821, 0.0],
              [-81.038904317987871, 35.182552011777211, 0.0],
              [-81.03879258525015, 35.182620612075787, 0.0],
              [-81.038067384189972, 35.182574278604008, 0.0],
              [-81.03753771797291, 35.182184611889809, 0.0],
              [-81.036589184367259, 35.182229678515917, 0.0],
              [-81.035863784846669, 35.18236667880624, 0.0],
              [-81.035612318414522, 35.182595478543888, 0.0],
              [-81.035276917470966, 35.183282411960057, 0.0],
              [-81.03527631786045, 35.183763278493814, 0.0],
              [-81.035638784702726, 35.183923878983265, 0.0],
              [-81.037480184302538, 35.183856478752894, 0.0],
              [-81.037842584383938, 35.184039878921574, 0.0],
              [-81.038093384371848, 35.184360811659126, 0.0],
              [-81.038455918517272, 35.184521411574785, 0.0],
              [-81.038678385105655, 35.185162878507086, 0.0],
              [-81.038733784990725, 35.185506478685639, 0.0],
              [-81.038817318202689, 35.185712679187482, 0.0],
              [-81.039291185196959, 35.186125211567905, 0.0],
              [-81.039430117956101, 35.186698012039017, 0.0],
              [-81.039178384174235, 35.187270412453685, 0.0],
              [-81.039178184819605, 35.187476478589822, 0.0],
              [-81.039401118491952, 35.18777441247498, 0.0],
              [-81.039819184908623, 35.188026612461925, 0.0],
              [-81.040209717556579, 35.188141411994209, 0.0],
              [-81.040683917889325, 35.188141678613889, 0.0],
              [-81.041269917828259, 35.188027612309398, 0.0],
              [-81.042414517490656, 35.187341212145441, 0.0],
              [-81.04308438513624, 35.187066811695949, 0.0],
              [-81.043559117750178, 35.186632012417412, 0.0],
              [-81.044256584647883, 35.186426212295785, 0.0],
              [-81.044842984828279, 35.185922678224479, 0.0],
              [-81.045094117918623, 35.185945812401876, 0.0],
              [-81.045177717944583, 35.186129012082034, 0.0],
              [-81.04503778479463, 35.186495412198035, 0.0],
              [-81.045037385167944, 35.186884678757153, 0.0],
              [-81.045455518038452, 35.187274412360225, 0.0],
              [-81.045455317734579, 35.187480478790768, 0.0],
              [-81.044395184622644, 35.187502811630694, 0.0],
              [-81.043948584574494, 35.187708679010811, 0.0],
              [-81.043753117525412, 35.187914678306285, 0.0],
              [-81.042999384975687, 35.188280611674671, 0.0],
              [-81.042189584171069, 35.189081678942863, 0.0],
              [-81.041519717949143, 35.189333211683085, 0.0],
              [-81.04129618439481, 35.189562079205196, 0.0],
              [-81.041323584849479, 35.190066012458928, 0.0],
              [-81.042104184746151, 35.190684878956191, 0.0],
              [-81.042104118357059, 35.190776478345057, 0.0],
              [-81.04246658474743, 35.190982878329216, 0.0],
              [-81.04291298495788, 35.190983212363214, 0.0],
              [-81.043303584198227, 35.190891878992609, 0.0],
              [-81.044447984735825, 35.190388812228285, 0.0],
              [-81.044922918025193, 35.189931012028339, 0.0],
              [-81.04495091842989, 35.189793478932792, 0.0],
              [-81.045257985192691, 35.189587478768082, 0.0],
              [-81.045397384788743, 35.189610611799729, 0.0],
              [-81.045480584946645, 35.190206079132217, 0.0],
              [-81.044949717910711, 35.190893011713563, 0.0],
              [-81.044949384665728, 35.191259411604484, 0.0],
              [-81.045535118499998, 35.191534611732799, 0.0],
              [-81.045701784514421, 35.19222181234467, 0.0],
              [-81.04598071817081, 35.192359278686247, 0.0],
              [-81.046566385207058, 35.192497212305476, 0.0],
              [-81.047095917664009, 35.193093012528486, 0.0],
              [-81.047542318550384, 35.193093278343838, 0.0],
              [-81.047765917789761, 35.192704078591348, 0.0],
              [-81.048017117618642, 35.19256667911376, 0.0],
              [-81.048212384073537, 35.19261267828945, 0.0],
              [-81.048518984032114, 35.192979411634596, 0.0],
              [-81.048490918019155, 35.193185478437016, 0.0],
              [-81.04835131759404, 35.193322811960641, 0.0],
              [-81.047876718191688, 35.193505811890596, 0.0],
              [-81.047457784107181, 35.193963478405088, 0.0],
              [-81.047457584285027, 35.194192612298124, 0.0],
              [-81.047680584476979, 35.194376012433885, 0.0],
              [-81.048628917484535, 35.194720212036152, 0.0],
              [-81.048712518001935, 35.19481167874558, 0.0],
              [-81.048712384558897, 35.19506367881273, 0.0],
              [-81.048963185069596, 35.195338678605019, 0.0],
              [-81.049325718540786, 35.195567878278624, 0.0],
              [-81.049381384464311, 35.1957970117152, 0.0],
              [-81.04991098495087, 35.196255411730334, 0.0],
              [-81.050413384686067, 35.196141278535237, 0.0],
              [-81.050748118077252, 35.196164411748974, 0.0],
              [-81.050748117640495, 35.196278878372375, 0.0],
              [-81.050329184368252, 35.196645078684384, 0.0],
              [-81.050105584073833, 35.197057212425484, 0.0],
              [-81.049686717387615, 35.197400478805754, 0.0],
              [-81.04965858404627, 35.197652411690996, 0.0],
              [-81.050104917493343, 35.197790078449046, 0.0],
              [-81.050523517546168, 35.197721612143965, 0.0],
              [-81.050774918353625, 35.197515678984892, 0.0],
              [-81.051026118084479, 35.197447078610629, 0.0],
              [-81.051109718526604, 35.1975158787467, 0.0],
              [-81.051108984111409, 35.198180079100887, 0.0],
              [-81.051220517434189, 35.198340478343873, 0.0],
              [-81.051750517937052, 35.198592612352677, 0.0],
              [-81.0522247176322, 35.1986158786667, 0.0],
              [-81.052727384092179, 35.198226678908703, 0.0],
              [-81.053062585051123, 35.197768878569285, 0.0],
              [-81.053620984945894, 35.197356878704731, 0.0],
              [-81.054039584696866, 35.197357212182453, 0.0],
              [-81.05440218475681, 35.197494812225379, 0.0],
              [-81.054457917933647, 35.19767807854145, 0.0],
              [-81.053983318299629, 35.197861078441228, 0.0],
              [-81.053229385080058, 35.198524812102043, 0.0],
              [-81.053089384131937, 35.199005679026342, 0.0],
              [-81.052698518243972, 35.199394811920101, 0.0],
              [-81.052530784693644, 35.199692478470205, 0.0],
              [-81.052668918168465, 35.201318679162249, 0.0],
              [-81.052808184392802, 35.201547811943747, 0.0],
              [-81.053393784934187, 35.201937478674679, 0.0],
              [-81.054677118308703, 35.202167412435657, 0.0],
              [-81.05509551811069, 35.202350811546225, 0.0],
              [-81.055653718539219, 35.202282412466836, 0.0],
              [-81.056602918388378, 35.20164167845094, 0.0],
              [-81.057021584334322, 35.201458478400099, 0.0],
              [-81.057189384966051, 35.201000611965675, 0.0],
              [-81.057580184893112, 35.200817611587446, 0.0],
              [-81.0579707842153, 35.200863611876827, 0.0],
              [-81.058193917687433, 35.201001211900042, 0.0],
              [-81.058305317369062, 35.201344811879572, 0.0],
              [-81.058500518237238, 35.201436478236396, 0.0],
              [-81.058918984653445, 35.201459612443387, 0.0],
              [-81.0593653845348, 35.201620212384242, 0.0],
              [-81.060453185083702, 35.202284878805948, 0.0],
              [-81.061206384213946, 35.202491478220125, 0.0],
              [-81.061373584718069, 35.202697612358776, 0.0],
              [-81.061483185006367, 35.205285812403268, 0.0],
              [-81.061734184825568, 35.205492078656079, 0.0],
              [-81.062041184110868, 35.205492278621627, 0.0],
              [-81.062487784142377, 35.20535501188229, 0.0],
              [-81.063102184714808, 35.20466821195528, 0.0],
              [-81.063827784556622, 35.204668611913419, 0.0],
              [-81.06435771843357, 35.204760411597086, 0.0],
              [-81.064497118354325, 35.204920878902222, 0.0],
              [-81.064469118110196, 35.205126878475916, 0.0],
              [-81.064301584872098, 35.205218479114407, 0.0],
              [-81.063603985080562, 35.205263878502052, 0.0],
              [-81.063185117956806, 35.205721812159368, 0.0],
              [-81.062068384158678, 35.206339678912585, 0.0],
              [-81.062068318250397, 35.206614612501731, 0.0],
              [-81.062207584084092, 35.206912278534283, 0.0],
              [-81.062151518111719, 35.20716427839821, 0.0],
              [-81.061397917704682, 35.207392879002015, 0.0],
              [-81.061230317659749, 35.207621812282788, 0.0],
              [-81.061062384981994, 35.208148478563388, 0.0],
              [-81.061117718528635, 35.208858611865985, 0.0],
              [-81.061257117400217, 35.209110678255101, 0.0],
              [-81.061954318334571, 35.209683612386605, 0.0],
              [-81.062121517555624, 35.209958478653206, 0.0],
              [-81.061925718308558, 35.210553878542228, 0.0],
              [-81.061981118309475, 35.211126478951591, 0.0],
              [-81.062204184815684, 35.211332812086802, 0.0],
              [-81.062873717441462, 35.211630811919392, 0.0],
              [-81.063041117875869, 35.211791278919137, 0.0],
              [-81.063040784180018, 35.212157679097523, 0.0],
              [-81.062342784126031, 35.212478012160659, 0.0],
              [-81.062342717724988, 35.212707011641825, 0.0],
              [-81.062872384254518, 35.213440211953234, 0.0],
              [-81.062899517949162, 35.214287678468665, 0.0],
              [-81.063206517508192, 35.214287811894366, 0.0],
              [-81.063541784524048, 35.213738278675791, 0.0],
              [-81.063737117628008, 35.213807078341056, 0.0],
              [-81.063736718072121, 35.214288078431345, 0.0],
              [-81.063568984448722, 35.214723212070034, 0.0],
              [-81.063596718060154, 35.214975079124933, 0.0],
              [-81.063791918144929, 35.215135611587009, 0.0],
              [-81.063819717738937, 35.21536461216904, 0.0],
              [-81.063261317992797, 35.215593411760501, 0.0],
              [-81.062926318519004, 35.215845078761681, 0.0],
              [-81.062674984259488, 35.215867878866291, 0.0],
              [-81.062396184823243, 35.215684478987747, 0.0],
              [-81.062172917491125, 35.215776012057887, 0.0],
              [-81.062088984368785, 35.215982079068205, 0.0],
              [-81.062144518423892, 35.216463078427623, 0.0],
              [-81.062617718528656, 35.217997878656512, 0.0],
              [-81.062979984713522, 35.218570678953554, 0.0],
              [-81.063482118244522, 35.219006078288785, 0.0],
              [-81.064849184932996, 35.219556478815534, 0.0],
              [-81.065100518476484, 35.219487878702644, 0.0],
              [-81.065240117447971, 35.219350478345802, 0.0],
              [-81.065212785152326, 35.218640479073663, 0.0],
              [-81.065045717560224, 35.218136478287164, 0.0],
              [-81.06499018454997, 35.217724212108109, 0.0],
              [-81.065688384597863, 35.217060412036965, 0.0],
              [-81.065995318222193, 35.21708341189531, 0.0],
              [-81.065994984728277, 35.217472811508387, 0.0],
              [-81.065548117786818, 35.217953611682404, 0.0],
              [-81.065575917563422, 35.218205479124435, 0.0],
              [-81.066105984509619, 35.218503478249715, 0.0],
              [-81.066133584211769, 35.218869878738744, 0.0],
              [-81.066468317800997, 35.219030412441029, 0.0],
              [-81.066942918189241, 35.218962011959725, 0.0],
              [-81.06705458465261, 35.218870411785105, 0.0],
              [-81.067361584504297, 35.218893412095575, 0.0],
              [-81.067445318052691, 35.218985079151146, 0.0],
              [-81.067416917979457, 35.219718011971146, 0.0],
              [-81.068253718156043, 35.220290878465065, 0.0],
              [-81.068978917565232, 35.221184478708018, 0.0],
              [-81.069090185008875, 35.221619678437335, 0.0],
              [-81.069815518503333, 35.222101012323492, 0.0],
              [-81.070429584684391, 35.221918011863295, 0.0],
              [-81.070876185136967, 35.22200987901509, 0.0],
              [-81.070931784150659, 35.22221601248043, 0.0],
              [-81.070792117435744, 35.222490811732719, 0.0],
              [-81.070819917607167, 35.222719811800211, 0.0],
              [-81.071656984219985, 35.223086612477054, 0.0],
              [-81.072019584479293, 35.22336167909954, 0.0],
              [-81.07221478443654, 35.223705278718249, 0.0],
              [-81.072242584431706, 35.22400301157117, 0.0],
              [-81.072688718287409, 35.224667411817222, 0.0],
              [-81.07316231754865, 35.226133478528617, 0.0],
              [-81.073748118208457, 35.226706278758314, 0.0],
              [-81.074082184546995, 35.228057678909693, 0.0],
              [-81.073970517549839, 35.228172211846839, 0.0],
              [-81.074026118352592, 35.228447078894206, 0.0],
              [-81.074667584625345, 35.229271878436393, 0.0],
              [-81.07508571787416, 35.230256878298306, 0.0],
              [-81.075448384267219, 35.230531878682505, 0.0],
              [-81.075867117466146, 35.230623678364196, 0.0],
              [-81.076201917629675, 35.230990212324599, 0.0],
              [-81.076145917395706, 35.231104812130219, 0.0],
              [-81.075671317496713, 35.231219011636639, 0.0],
              [-81.075531518333932, 35.231700012443511, 0.0],
              [-81.074833184788332, 35.232295212269058, 0.0],
              [-81.074749385073559, 35.232478412317285, 0.0],
              [-81.074302717990733, 35.232638611671469, 0.0],
              [-81.074051517777775, 35.232638479114328, 0.0],
              [-81.073409717417135, 35.232088612182118, 0.0],
              [-81.072516517971877, 35.232202678635218, 0.0],
              [-81.071790784408691, 35.232019079096801, 0.0],
              [-81.07086951821563, 35.232087478919631, 0.0],
              [-81.070450984983324, 35.231927012382179, 0.0],
              [-81.070228117961392, 35.231331278244475, 0.0],
              [-81.069278917664093, 35.231376812102795, 0.0],
              [-81.068469317586533, 35.23153667872986, 0.0],
              [-81.067101585188013, 35.231444611640271, 0.0],
              [-81.066375984383143, 35.231215078413157, 0.0],
              [-81.065789717796889, 35.231306478186212, 0.0],
              [-81.065342784117647, 35.231810211683879, 0.0],
              [-81.065007118102386, 35.232771879003629, 0.0],
              [-81.065006318478069, 35.233825478600117, 0.0],
              [-81.065340517860022, 35.234902078172013, 0.0],
              [-81.065731117630889, 35.235291611772794, 0.0],
              [-81.066038118172855, 35.235452212405711, 0.0],
              [-81.0664009182467, 35.235498078707082, 0.0],
              [-81.067768784373499, 35.235315478527987, 0.0],
              [-81.068383117883997, 35.235132612362534, 0.0],
              [-81.06938838473161, 35.234697878842432, 0.0],
              [-81.069751317818941, 35.234721011623357, 0.0],
              [-81.070169917601902, 35.234881411503146, 0.0],
              [-81.070420917795687, 35.235110611813447, 0.0],
              [-81.07106278481595, 35.235454411883289, 0.0],
              [-81.071564517937603, 35.236554012143195, 0.0],
              [-81.072177384278291, 35.238546811772153, 0.0],
              [-81.072372718301736, 35.23873007854661, 0.0],
              [-81.07298631790475, 35.239783878435539, 0.0],
              [-81.07326491810268, 35.24063147858233, 0.0],
              [-81.073348518457536, 35.24067727832621, 0.0],
              [-81.073515717986069, 35.241341612098871, 0.0],
              [-81.073599318008618, 35.241387411684784, 0.0],
              [-81.073961784876218, 35.242189279070267, 0.0],
              [-81.074045318304059, 35.242624478442259, 0.0],
              [-81.073082117883459, 35.242810012291919, 0.0],
              [-81.072621317749764, 35.242898812232148, 0.0],
              [-81.071784384452329, 35.241890611684859, 0.0],
              [-81.071310518526687, 35.24083687885274, 0.0],
              [-81.070836118106726, 35.240607612379044, 0.0],
              [-81.070668784574679, 35.240332811518584, 0.0],
              [-81.070418117600326, 35.239370678901025, 0.0],
              [-81.069804384562019, 35.238660411862057, 0.0],
              [-81.069274184219807, 35.238270811850057, 0.0],
              [-81.067851117656801, 35.237491412001127, 0.0],
              [-81.066930317739349, 35.236941278626567, 0.0],
              [-81.066678984701355, 35.2370098784886, 0.0],
              [-81.066483318179877, 35.237330412185443, 0.0],
              [-81.066371318291544, 35.237788478170302, 0.0],
              [-81.066370317730133, 35.239323012100542, 0.0],
              [-81.066034984631926, 35.239597811760468, 0.0],
              [-81.065978984234093, 35.239987079067596, 0.0],
              [-81.065392318108707, 35.240559278577962, 0.0],
              [-81.064805985078934, 35.240604878857525, 0.0],
              [-81.064582517808802, 35.240742211873446, 0.0],
              [-81.064359185034633, 35.240673411917669, 0.0],
              [-81.06435971852828, 35.240123678699739, 0.0],
              [-81.065085918286357, 35.239597278277103, 0.0],
              [-81.065365717504505, 35.238727078572651, 0.0],
              [-81.065366784134653, 35.237215411881181, 0.0],
              [-81.065646317808458, 35.236734478967648, 0.0],
              [-81.066204717701964, 35.236551612135045, 0.0],
              [-81.066260718480223, 35.236368411767423, 0.0],
              [-81.065786317428461, 35.236139078661608, 0.0],
              [-81.065367517508193, 35.236207612449562, 0.0],
              [-81.064752784700502, 35.236940278202752, 0.0],
              [-81.064417585057924, 35.237192078766853, 0.0],
              [-81.064082585087888, 35.237214812280321, 0.0],
              [-81.063971118215889, 35.237077278742426, 0.0],
              [-81.064082985140843, 35.236802478615395, 0.0],
              [-81.064976917357484, 35.236024212077162, 0.0],
              [-81.06497711813617, 35.235726478170321, 0.0],
              [-81.064502984705598, 35.235062012426908, 0.0],
              [-81.064363718334761, 35.234535211611416, 0.0],
              [-81.064140584606591, 35.234237278195508, 0.0],
              [-81.063973517768062, 35.233756279117259, 0.0],
              [-81.063946917539198, 35.232130078620656, 0.0],
              [-81.063835184907774, 35.232015612097392, 0.0],
              [-81.063193117712686, 35.232152611875335, 0.0],
              [-81.062830318481517, 35.232060812329443, 0.0],
              [-81.062942184718338, 35.231763078688921, 0.0],
              [-81.063193584558604, 35.231625811514625, 0.0],
              [-81.063389184230687, 35.231328212362371, 0.0],
              [-81.063333584081931, 35.230938811661979, 0.0],
              [-81.063026784561544, 35.230663811672784, 0.0],
              [-81.063054917974384, 35.230274478787592, 0.0],
              [-81.063752584333585, 35.230412278557822, 0.0],
              [-81.064590318319674, 35.230252279044315, 0.0],
              [-81.065064717615215, 35.230275478912048, 0.0],
              [-81.066348517521348, 35.23064247856292, 0.0],
              [-81.067381184164503, 35.230803411588575, 0.0],
              [-81.068832785033564, 35.230689412187289, 0.0],
              [-81.070145117457571, 35.23016321183156, 0.0],
              [-81.071262117802974, 35.229362078178902, 0.0],
              [-81.071569384291934, 35.22901867862921, 0.0],
              [-81.072240117472845, 35.227850812261977, 0.0],
              [-81.072463984017702, 35.226843212256931, 0.0],
              [-81.072464384358497, 35.226201878805121, 0.0],
              [-81.07224151824785, 35.225629212468817, 0.0],
              [-81.071042518305035, 35.223750479050111, 0.0],
              [-81.070317318203195, 35.223063079096796, 0.0],
              [-81.069731384603784, 35.222673478543889, 0.0],
              [-81.068392118135321, 35.222031678975171, 0.0],
              [-81.065741384990972, 35.220954012149789, 0.0],
              [-81.065155384044459, 35.220747612342883, 0.0],
              [-81.064792518464856, 35.220816078743177, 0.0],
              [-81.064792184800979, 35.221136678915258, 0.0],
              [-81.064875918187283, 35.221228411852415, 0.0],
              [-81.066466384369264, 35.221847611659065, 0.0],
              [-81.067359184328254, 35.222306012396899, 0.0],
              [-81.068056784393747, 35.222535411589647, 0.0],
              [-81.068475317697875, 35.222787612351695, 0.0],
              [-81.068949517418247, 35.223337479001565, 0.0],
              [-81.070260718364452, 35.224277078856723, 0.0],
              [-81.070204518435801, 35.22478101202173, 0.0],
              [-81.070036984965114, 35.224826678979554, 0.0],
              [-81.069785784663424, 35.224735011694236, 0.0],
              [-81.069144384429123, 35.223956012303823, 0.0],
              [-81.068028184832457, 35.22363481191006, 0.0],
              [-81.066298717483107, 35.222259678851486, 0.0],
              [-81.065573184335321, 35.221915878504966, 0.0],
              [-81.064205585139831, 35.221869411927457, 0.0],
              [-81.064094584776257, 35.221021878692284, 0.0],
              [-81.063815584181285, 35.220769811527852, 0.0],
              [-81.063452917711061, 35.220655078908628, 0.0],
              [-81.062197917665401, 35.219394878805403, 0.0],
              [-81.061946718515088, 35.219394811593446, 0.0],
              [-81.061946117429699, 35.220196411808786, 0.0],
              [-81.061470785006449, 35.221387078774761, 0.0],
              [-81.061163384210488, 35.221936678633575, 0.0],
              [-81.061163117789604, 35.222234412093151, 0.0],
              [-81.061525517886963, 35.222623878706237, 0.0],
              [-81.061525318218159, 35.222921679108929, 0.0],
              [-81.061274118095099, 35.222921479086523, 0.0],
              [-81.060939517585084, 35.222692278707314, 0.0],
              [-81.06043718414243, 35.222486012328432, 0.0],
              [-81.060297984980323, 35.22225681195372, 0.0],
              [-81.060326118203037, 35.221913278947127, 0.0],
              [-81.060689384422119, 35.221272078684663, 0.0],
              [-81.06091318496884, 35.220608078217282, 0.0],
              [-81.060885984538984, 35.219806478273853, 0.0],
              [-81.061193117944001, 35.219600479146891, 0.0],
              [-81.061193384176008, 35.219211078253373, 0.0],
              [-81.061081917807272, 35.219073678335207, 0.0],
              [-81.060523784750615, 35.218890212278552, 0.0],
              [-81.060524118535113, 35.218546611831073, 0.0],
              [-81.060831384578876, 35.218180279052618, 0.0],
              [-81.060747918011245, 35.217882479069239, 0.0],
              [-81.060580584036117, 35.217676278224516, 0.0],
              [-81.060413184061773, 35.217607479103052, 0.0],
              [-81.059575918099256, 35.217698678589578, 0.0],
              [-81.059464318052605, 35.217606878792068, 0.0],
              [-81.059520318007699, 35.217400878935912, 0.0],
              [-81.060357984612452, 35.216851678737193, 0.0],
              [-81.06063778485921, 35.215981478451731, 0.0],
              [-81.060889184199709, 35.215569412233194, 0.0],
              [-81.061391984734087, 35.215019812378294, 0.0],
              [-81.061280584660878, 35.214630411831145, 0.0],
              [-81.060806518082188, 35.214401212012916, 0.0],
              [-81.060639118458056, 35.214240812018055, 0.0],
              [-81.060444118263419, 35.213691011720741, 0.0],
              [-81.060304717964755, 35.213553479139939, 0.0],
              [-81.059383918102, 35.213323878345378, 0.0],
              [-81.059356384723358, 35.21284301185004, 0.0],
              [-81.05946818434937, 35.212636878737818, 0.0],
              [-81.059468584116985, 35.212224611984311, 0.0],
              [-81.058549118153849, 35.210391878411563, 0.0],
              [-81.058438518451652, 35.20917801167581, 0.0],
              [-81.058327117892134, 35.208811411566259, 0.0],
              [-81.058048384384932, 35.208376079125124, 0.0],
              [-81.05640311817892, 35.207000878687722, 0.0],
              [-81.055343917392349, 35.205603212109487, 0.0],
              [-81.054842384514174, 35.204618078493873, 0.0],
              [-81.054647117771779, 35.204526412270468, 0.0],
              [-81.054312184182919, 35.204617812521676, 0.0],
              [-81.0538931846785, 35.205144411961598, 0.0],
              [-81.053586118462462, 35.205281612108969, 0.0],
              [-81.052721117870931, 35.205349811533921, 0.0],
              [-81.052386117492205, 35.205464211893137, 0.0],
              [-81.051743518285321, 35.206357012297424, 0.0],
              [-81.051742985184276, 35.206929612187579, 0.0],
              [-81.051575384591132, 35.20725021179706, 0.0],
              [-81.051574984095183, 35.207547878513303, 0.0],
              [-81.051881784361882, 35.207823011972913, 0.0],
              [-81.051965118557732, 35.20818947899803, 0.0],
              [-81.052299584141096, 35.208533212463671, 0.0],
              [-81.052383117459613, 35.208785212349177, 0.0],
              [-81.052327118182177, 35.208922612184942, 0.0],
              [-81.05165751770393, 35.208922211953144, 0.0],
              [-81.051183184158688, 35.208899011809507, 0.0],
              [-81.050959784743625, 35.209127878997194, 0.0],
              [-81.050205918122771, 35.209448078740536, 0.0],
              [-81.049870785151725, 35.209722811935436, 0.0],
              [-81.049283917517855, 35.210707278405287, 0.0],
              [-81.048921184830888, 35.210615479045863, 0.0],
              [-81.048837584732723, 35.210455078219908, 0.0],
              [-81.048949917679224, 35.209745212137086, 0.0],
              [-81.049201384436074, 35.209378812015743, 0.0],
              [-81.049313317976683, 35.209012478558542, 0.0],
              [-81.050234784471598, 35.208371612065093, 0.0],
              [-81.050402984990285, 35.207501412212977, 0.0],
              [-81.050626718572971, 35.20706641249663, 0.0],
              [-81.05057171772205, 35.20624181159063, 0.0],
              [-81.051018784342119, 35.205417612207498, 0.0],
              [-81.05101951805517, 35.204730478255193, 0.0],
              [-81.050824584323394, 35.20418067879033, 0.0],
              [-81.050295317845567, 35.203287078684731, 0.0],
              [-81.050044718110485, 35.202599812427067, 0.0],
              [-81.050073784636822, 35.201385878242505, 0.0],
              [-81.050297584857361, 35.200630278904647, 0.0],
              [-81.050242318443537, 35.200126412392983, 0.0],
              [-81.050019185054111, 35.200011679019006, 0.0],
              [-81.049712184249003, 35.200103078777801, 0.0],
              [-81.049321185066987, 35.200423611693019, 0.0],
              [-81.049070117493201, 35.200423411901866, 0.0],
              [-81.048679785014571, 35.200102479072051, 0.0],
              [-81.048652318012259, 35.199644412307045, 0.0],
              [-81.048122384451446, 35.199346278527784, 0.0],
              [-81.048094784653046, 35.199094411884658, 0.0],
              [-81.047983317975422, 35.198956878944635, 0.0],
              [-81.047620584575839, 35.198865012302051, 0.0],
              [-81.047258317899562, 35.198429611880876, 0.0],
              [-81.04711878418523, 35.198383678939564, 0.0],
              [-81.04644898442011, 35.198520812436669, 0.0],
              [-81.045974518071176, 35.19872661192916, 0.0],
              [-81.045583784070232, 35.19868061195951, 0.0],
              [-81.045472384109459, 35.198588878215837, 0.0],
              [-81.045472718001747, 35.198199612418314, 0.0],
              [-81.046561984768104, 35.197078011827557, 0.0],
              [-81.04681351781862, 35.196620012145154, 0.0],
              [-81.046785918067059, 35.196299278963444, 0.0],
              [-81.046535118129754, 35.19607021155899, 0.0],
              [-81.04536358422024, 35.195771678918753, 0.0],
              [-81.045085117831619, 35.195267611750708, 0.0],
              [-81.044890384559707, 35.19458041158655, 0.0],
              [-81.044611584753156, 35.194351211890307, 0.0],
              [-81.044416584910209, 35.194053278769054, 0.0],
              [-81.044053984370663, 35.193846878782303, 0.0],
              [-81.043914784611445, 35.19357201251897, 0.0],
              [-81.043691918018055, 35.193411478481622, 0.0],
              [-81.043077984938563, 35.193479811778062, 0.0],
              [-81.042714918342071, 35.193708611737954, 0.0],
              [-81.041625918562275, 35.194669878960326, 0.0],
              [-81.04126311738672, 35.194669678829818, 0.0],
              [-81.041431318258233, 35.193913878292065, 0.0],
              [-81.041459784464649, 35.193387212445685, 0.0],
              [-81.041180917372273, 35.19320381169053, 0.0],
              [-81.040818317743629, 35.193089012216539, 0.0],
              [-81.039674318209833, 35.193134012166915, 0.0],
              [-81.039004918339629, 35.192950278223002, 0.0],
              [-81.037527118496669, 35.192193411949049, 0.0],
              [-81.037192317572803, 35.192216211917845, 0.0],
              [-81.03710831844694, 35.192513878690065, 0.0],
              [-81.03710751746614, 35.193246812527846, 0.0],
              [-81.037469917427785, 35.193544811845022, 0.0],
              [-81.037692184282591, 35.194232079008145, 0.0],
              [-81.037607918063571, 35.194781678695762, 0.0],
              [-81.037272584739895, 35.195262412144089, 0.0],
              [-81.037327718328129, 35.195858012167783, 0.0],
              [-81.037522784630397, 35.196110012521643, 0.0],
              [-81.037494784787896, 35.196270411706536, 0.0],
              [-81.037075717785953, 35.19672807860757, 0.0],
              [-81.036824385244458, 35.196888278790439, 0.0],
              [-81.036601318357157, 35.196819412247038, 0.0],
              [-81.036461985134594, 35.196636078985776, 0.0],
              [-81.036630584665104, 35.195513879169944, 0.0],
              [-81.036742384287436, 35.19530781248946, 0.0],
              [-81.036687184838186, 35.194872612411395, 0.0],
              [-81.036436584347285, 35.194391478635829, 0.0],
              [-81.036185718032129, 35.194162278915741, 0.0],
              [-81.036130318091622, 35.193772878538844, 0.0],
              [-81.035823718361755, 35.193474879167532, 0.0],
              [-81.035768584294175, 35.192856478359246, 0.0],
              [-81.035434184980417, 35.192512678796447, 0.0],
              [-81.035462584830341, 35.192031678404938, 0.0],
              [-81.035909784755177, 35.191253411642059, 0.0],
              [-81.035993984281063, 35.190864011943262, 0.0],
              [-81.035882717537959, 35.190612012308527, 0.0],
              [-81.035967384183451, 35.189695879022658, 0.0],
              [-81.035883717909655, 35.189604212443868, 0.0],
              [-81.035493185128757, 35.189649811924298, 0.0],
              [-81.03485058505467, 35.190382212378879, 0.0],
              [-81.034599117444458, 35.190794278572902, 0.0],
              [-81.033174784399648, 35.191961411870118, 0.0],
              [-81.032700317499078, 35.192190079071402, 0.0],
              [-81.032393384229962, 35.192189812258846, 0.0],
              [-81.032253984511286, 35.192075212219962, 0.0],
              [-81.032254584450712, 35.191571279059609, 0.0],
              [-81.032645717910114, 35.191113478414742, 0.0],
              [-81.033762785126711, 35.19015227830792, 0.0],
              [-81.034433384754777, 35.189168012360696, 0.0],
              [-81.034545384202985, 35.188870278851759, 0.0],
              [-81.034629918560412, 35.188068812450211, 0.0],
              [-81.034407385013608, 35.187496011604573, 0.0],
              [-81.034072917562227, 35.187266678953051, 0.0],
              [-81.033654317670539, 35.187358011571554, 0.0],
              [-81.032649317896542, 35.18795281208692, 0.0],
              [-81.032286584583957, 35.187952612018869, 0.0],
              [-81.032175118116214, 35.18781507880032, 0.0],
              [-81.032175517541731, 35.187517278280637, 0.0],
              [-81.033180784545252, 35.186670478855454, 0.0],
              [-81.03334851740351, 35.186395812135359, 0.0],
              [-81.033348785115919, 35.186189678959046, 0.0],
              [-81.03304238500678, 35.185685611872209, 0.0],
              [-81.033098984419482, 35.184906878423376, 0.0],
              [-81.032486517665021, 35.183898678432996, 0.0],
              [-81.032012784389678, 35.183394479169756, 0.0],
              [-81.031594317639801, 35.183485811647294, 0.0],
              [-81.030924317898197, 35.183874679172554, 0.0],
              [-81.030002517522604, 35.184835879142213, 0.0],
              [-81.029666984931978, 35.185476879087012, 0.0],
              [-81.029164517471997, 35.185820211888931, 0.0],
              [-81.027908184108611, 35.186529211574559, 0.0],
              [-81.026847517988443, 35.187009278292741, 0.0],
              [-81.026038117590346, 35.18728361211987, 0.0],
              [-81.020120117723835, 35.190050211759271, 0.0],
              [-81.018696318085034, 35.190758878314931, 0.0],
              [-81.0182777180393, 35.190850212331782, 0.0],
              [-81.016938518027857, 35.190826079179743, 0.0],
              [-81.013228718531167, 35.190181478518127, 0.0],
              [-81.012336384218941, 35.189837212223289, 0.0],
              [-81.011974117966645, 35.189607878471556, 0.0],
              [-81.011890717503732, 35.189355811687449, 0.0],
              [-81.012030517512059, 35.189149811831797, 0.0],
              [-81.012560517738734, 35.189196078971221, 0.0],
              [-81.01423351813493, 35.189861811726182, 0.0],
              [-81.016409118614149, 35.190298879094435, 0.0],
              [-81.017413517995948, 35.190299678627881, 0.0],
              [-81.018613384356271, 35.190186212194554, 0.0],
              [-81.019646117873805, 35.189797678965945, 0.0],
              [-81.021963317941058, 35.188608678729345, 0.0],
              [-81.024726717715311, 35.187374211806947, 0.0],
              [-81.025592184745094, 35.186893811936955, 0.0],
              [-81.027462384878291, 35.18604787905295, 0.0],
              [-81.029193184107015, 35.185064279119842, 0.0],
              [-81.02964031748094, 35.18449207895258, 0.0],
              [-81.029780985085765, 35.183507278814403, 0.0],
              [-81.030199784850367, 35.183141211839505, 0.0],
              [-81.030144385031747, 35.182751811657823, 0.0],
              [-81.029503318085759, 35.182361879118247, 0.0],
              [-81.029419784387358, 35.182155678465072, 0.0],
              [-81.029475718029815, 35.18201841249801, 0.0],
              [-81.029754984270355, 35.181812412088142, 0.0],
              [-81.030814984349334, 35.181904878307869, 0.0],
              [-81.031400918579919, 35.181859478769155, 0.0],
              [-81.032322517937587, 35.180989812226514, 0.0],
              [-81.032322585191935, 35.180806478490148, 0.0],
              [-81.032099718440946, 35.18062321244448, 0.0],
              [-81.031374585041306, 35.180393679140444, 0.0],
              [-81.030761385086407, 35.179935212330776, 0.0],
              [-81.030733784885257, 35.179683078554312, 0.0],
              [-81.030929184314815, 35.179591678364993, 0.0],
              [-81.032267785081885, 35.179959078803464, 0.0],
              [-81.032518717946388, 35.180119612320247, 0.0],
              [-81.033047985242746, 35.180761279073245, 0.0],
              [-81.033382518001616, 35.180990612193227, 0.0],
              [-81.034191717456011, 35.180853812284127, 0.0],
              [-81.034917184509993, 35.180602412399885, 0.0],
              [-81.035084917727076, 35.18030481230921, 0.0],
              [-81.035224917558637, 35.179892612347679, 0.0],
              [-81.035225518286367, 35.1793886789428, 0.0],
              [-81.035560984111726, 35.178633078999312, 0.0],
              [-81.035618317735256, 35.177327612023468, 0.0],
              [-81.035729985123155, 35.177121478506514, 0.0],
              [-81.036539118066017, 35.177076278337815, 0.0],
              [-81.037292717899035, 35.176573012257691, 0.0],
              [-81.037376718326058, 35.176321078907897, 0.0],
              [-81.037350184546142, 35.175084278460275, 0.0],
              [-81.03726671842692, 35.174946811786164, 0.0],
              [-81.037183984770792, 35.17400767830064, 0.0],
              [-81.036961117900887, 35.173709812406891, 0.0],
              [-81.036431517879791, 35.173342878304872, 0.0],
              [-81.036431918221211, 35.172976478978406, 0.0],
              [-81.036990117844695, 35.172679078296767, 0.0],
              [-81.037213784292703, 35.172083811819789, 0.0],
              [-81.037548985186831, 35.171671811655983, 0.0],
              [-81.037968384579273, 35.170755878894859, 0.0],
              [-81.037913118329357, 35.170320679128217, 0.0],
              [-81.037746117789752, 35.169977078607907, 0.0],
              [-81.037550984845367, 35.169793678756889, 0.0],
              [-81.037495918182074, 35.169175212383387, 0.0],
              [-81.037579784194236, 35.169037878754679, 0.0],
              [-81.037580117490776, 35.168648478739001, 0.0],
              [-81.03794311849208, 35.16825927830827, 0.0],
              [-81.038306517704498, 35.167549478703613, 0.0],
              [-81.038083584298491, 35.167389078342829, 0.0],
              [-81.036772717690667, 35.167388212017919, 0.0],
              [-81.036409785176929, 35.167662811780978, 0.0],
              [-81.035990518232765, 35.168418278408524, 0.0],
              [-81.035739317545136, 35.168670078589884, 0.0],
              [-81.034818184922798, 35.169150412138933, 0.0],
              [-81.033617385224872, 35.170455078766047, 0.0],
              [-81.0332265849636, 35.1707524785825, 0.0],
              [-81.032473117637636, 35.171072612088068, 0.0],
              [-81.031551584619365, 35.171942211742177, 0.0],
              [-81.030826118214534, 35.172285278976787, 0.0],
              [-81.029932584260678, 35.173040478373196, 0.0],
              [-81.029681585273508, 35.173040279055762, 0.0],
              [-81.029570117709525, 35.172948612278176, 0.0],
              [-81.029598184377193, 35.172742478892957, 0.0],
              [-81.030882784931634, 35.171460878697033, 0.0],
              [-81.031302117839729, 35.170705211973306, 0.0],
              [-81.031609185011177, 35.170407811729213, 0.0],
              [-81.032725717839085, 35.169629878704271, 0.0],
              [-81.033060784742815, 35.169263612072314, 0.0],
              [-81.033144918392779, 35.168965878582725, 0.0],
              [-81.032949718351603, 35.168828412433761, 0.0],
              [-81.032420118013519, 35.168736412391226, 0.0],
              [-81.032420317421227, 35.168484412112456, 0.0],
              [-81.032559918238746, 35.168347211676149, 0.0],
              [-81.034401318398579, 35.167753011633643, 0.0],
              [-81.034290117382326, 35.167478011658922, 0.0],
              [-81.033983384731087, 35.167386278909333, 0.0],
              [-81.033090984310704, 35.167316878840616, 0.0],
              [-81.032868184279877, 35.167064678927382, 0.0],
              [-81.032562718448062, 35.165896478459189, 0.0],
              [-81.032172718526922, 35.165506812184695, 0.0],
              [-81.031893584548314, 35.165598211660893, 0.0],
              [-81.031698117723479, 35.165804212229567, 0.0],
              [-81.031390584122889, 35.166445212420285, 0.0],
              [-81.031027917920028, 35.166582412047347, 0.0],
              [-81.030748518347735, 35.166994411967806, 0.0],
              [-81.030413717878801, 35.167085878670036, 0.0],
              [-81.030274318299675, 35.166948412391037, 0.0],
              [-81.030330584424064, 35.166581879090046, 0.0],
              [-81.03069358494993, 35.166169811765478, 0.0],
              [-81.030805518201333, 35.165895079088202, 0.0],
              [-81.03089031821176, 35.164956079100051, 0.0],
              [-81.031532317655987, 35.164452679171752, 0.0],
              [-81.032341718547556, 35.164064012491046, 0.0],
              [-81.032648918277687, 35.163697812311746, 0.0],
              [-81.032594518003776, 35.162415078838947, 0.0],
              [-81.032455184084867, 35.162300412173359, 0.0],
              [-81.031925184429284, 35.162391678351227, 0.0],
              [-81.030641518205911, 35.163032012397586, 0.0],
              [-81.029748918331236, 35.163100211934335, 0.0],
              [-81.029330385204887, 35.163214278654216, 0.0],
              [-81.028688917966804, 35.163259678955981, 0.0],
              [-81.028270384216924, 35.16344247898634, 0.0],
              [-81.02771258440896, 35.163419079029211, 0.0],
              [-81.027350317521964, 35.16316687907252, 0.0],
              [-81.027350584352916, 35.162937879214354, 0.0],
              [-81.027657718471147, 35.162754878934962, 0.0],
              [-81.029052317706018, 35.16248121206312, 0.0],
              [-81.030475184202658, 35.162047011672612, 0.0],
              [-81.031145117467275, 35.161681011852885, 0.0],
              [-81.031201518125215, 35.16106267869003, 0.0],
              [-81.031313184194445, 35.160925412007039, 0.0],
              [-81.031229784483131, 35.16069621246703, 0.0],
              [-81.030979118578657, 35.160512879113661, 0.0],
              [-81.030979385175257, 35.160238078288913, 0.0],
              [-81.031146785161198, 35.160123611842572, 0.0],
              [-81.032206184968004, 35.160490878792672, 0.0],
              [-81.032457317729737, 35.160422278597046, 0.0],
              [-81.032541717800967, 35.159804012468491, 0.0],
              [-81.032346784730919, 35.159460278437258, 0.0],
              [-81.031761585213175, 35.159047612571186, 0.0],
              [-81.031733984068168, 35.15886441184464, 0.0],
              [-81.031371718517065, 35.158658011782414, 0.0],
              [-81.030200517767781, 35.158519678468465, 0.0],
              [-81.029865984986998, 35.158404878270993, 0.0],
              [-81.029866317582574, 35.158175811904322, 0.0],
              [-81.030117384757489, 35.158015678305937, 0.0],
              [-81.031483984893825, 35.157879278881076, 0.0],
              [-81.031484318260112, 35.157581479239781, 0.0],
              [-81.031261784544569, 35.157215012520375, 0.0],
              [-81.031039384673136, 35.156596411682607, 0.0],
              [-81.030844518117306, 35.15629847887358, 0.0],
              [-81.030565584290756, 35.156344078720714, 0.0],
              [-81.030314517858173, 35.156481278968222, 0.0],
              [-81.029952117962281, 35.156297812472346, 0.0],
              [-81.029840918527327, 35.155999878985611, 0.0],
              [-81.030008784288498, 35.155496211931599, 0.0],
              [-81.030483917706945, 35.154672012447534, 0.0],
              [-81.030428385048921, 35.15435127846586, 0.0],
              [-81.030038384293945, 35.153984612250916, 0.0],
              [-81.029760184118629, 35.153434611840822, 0.0],
              [-81.029536984367624, 35.153480278963784, 0.0],
              [-81.029425318035493, 35.153617678477076, 0.0],
              [-81.029424718560819, 35.154190279110452, 0.0],
              [-81.029005784511014, 35.154671011746963, 0.0],
              [-81.028140717455216, 35.155128279191203, 0.0],
              [-81.02791738465028, 35.155380078743732, 0.0],
              [-81.027721584140494, 35.155906679014059, 0.0],
              [-81.026800517975417, 35.156547278442368, 0.0],
              [-81.026437917709757, 35.156501211899581, 0.0],
              [-81.026521984668889, 35.156157679158696, 0.0],
              [-81.026857184210215, 35.155745678944086, 0.0],
              [-81.026913717975773, 35.1551732123406, 0.0],
              [-81.027556117744709, 35.154326211869041, 0.0],
              [-81.027779784314689, 35.153822479199903, 0.0],
              [-81.027752384887421, 35.153364478795368, 0.0],
              [-81.027473584272755, 35.153295478330044, 0.0],
              [-81.026692784594601, 35.153340612484186, 0.0],
              [-81.026189985018092, 35.154050411928949, 0.0],
              [-81.025883317914733, 35.154027211878592, 0.0],
              [-81.025883717847918, 35.153637811896516, 0.0],
              [-81.025465718448018, 35.153431412144002, 0.0],
              [-81.025465984118625, 35.15313367897145, 0.0],
              [-81.025689517660496, 35.152881878557032, 0.0],
              [-81.026972917800478, 35.152356079224475, 0.0],
              [-81.02769811780567, 35.152150478575159, 0.0],
              [-81.027893517704015, 35.151990278654019, 0.0],
              [-81.027726984647884, 35.151234278631385, 0.0],
              [-81.027978184880723, 35.151142878992985, 0.0],
              [-81.028563318554475, 35.151578612334802, 0.0],
              [-81.028953718467378, 35.151556012060801, 0.0],
              [-81.029149318405445, 35.151212612570426, 0.0],
              [-81.029261584604441, 35.150525478689474, 0.0],
              [-81.029150317810078, 35.150365079142013, 0.0],
              [-81.028955184107801, 35.150296278940537, 0.0],
              [-81.027728184579942, 35.150341078528726, 0.0],
              [-81.02725418501322, 35.150272012460839, 0.0],
              [-81.02669631788001, 35.150454811884295, 0.0],
              [-81.026222318158133, 35.150385678621177, 0.0],
              [-81.025720585220839, 35.150179212495622, 0.0],
              [-81.024800318105278, 35.150247212086036, 0.0],
              [-81.023656585183318, 35.150566878324383, 0.0],
              [-81.021814917913247, 35.151481612402058, 0.0],
              [-81.021005385260295, 35.152168078385259, 0.0],
              [-81.020726118039178, 35.152534211617294, 0.0],
              [-81.020752784955221, 35.153496279153671, 0.0],
              [-81.020529518526629, 35.153610612403895, 0.0],
              [-81.01988811820371, 35.153541278502388, 0.0],
              [-81.019609184848008, 35.153632811661247, 0.0],
              [-81.019106517618468, 35.154182012151466, 0.0],
              [-81.019106117435271, 35.154479812255509, 0.0],
              [-81.019328984733249, 35.154663211751306, 0.0],
              [-81.01985858495182, 35.15489261222335, 0.0],
              [-81.019941918418724, 35.155144678641513, 0.0],
              [-81.019830318463264, 35.155236078727349, 0.0],
              [-81.019244518551531, 35.155281611690221, 0.0],
              [-81.018881785113408, 35.155441612376848, 0.0],
              [-81.018630917871263, 35.15537261239843, 0.0],
              [-81.017571118544495, 35.155440412385801, 0.0],
              [-81.015980584728808, 35.156057412124383, 0.0],
              [-81.015701317809672, 35.156354878940398, 0.0],
              [-81.015533517454173, 35.156698279150532, 0.0],
              [-81.015532585033895, 35.157408412433035, 0.0],
              [-81.015894517988372, 35.157912612473133, 0.0],
              [-81.016033184172144, 35.158462411956421, 0.0],
              [-81.016366785149515, 35.159218478690846, 0.0],
              [-81.017174785247747, 35.15990627881888, 0.0],
              [-81.017285985150949, 35.160089611627946, 0.0],
              [-81.017285518396704, 35.160501811793843, 0.0],
              [-81.017201718039829, 35.160593478416523, 0.0],
              [-81.016839184611371, 35.160570212496339, 0.0],
              [-81.016114785183731, 35.160019878779025, 0.0],
              [-81.015641317560181, 35.159515678382476, 0.0],
              [-81.015502518389951, 35.159034478494341, 0.0],
              [-81.014973318112098, 35.158507278899954, 0.0],
              [-81.014611718464934, 35.157796878774882, 0.0],
              [-81.014167585075342, 35.156262011935475, 0.0],
              [-81.013889184542492, 35.155941078759568, 0.0],
              [-81.013610385284665, 35.155940811860845, 0.0],
              [-81.013581985085295, 35.15619267848588, 0.0],
              [-81.013358984447365, 35.156192612209622, 0.0],
              [-81.013108184787356, 35.156077879055438, 0.0],
              [-81.012690584875401, 35.15559647845302, 0.0],
              [-81.012662917490388, 35.155459078650772, 0.0],
              [-81.012439984757265, 35.155275611939352, 0.0],
              [-81.011826317612446, 35.155320812078735, 0.0],
              [-81.011686784205679, 35.155435279140619, 0.0],
              [-81.011630184375676, 35.1560306791405, 0.0],
              [-81.011546384600408, 35.156145078818803, 0.0],
              [-81.011128117840087, 35.1560530790236, 0.0],
              [-81.010905384413093, 35.155755211912307, 0.0],
              [-81.010654718284925, 35.155663278538356, 0.0],
              [-81.010347785079674, 35.155731679099745, 0.0],
              [-81.010235985186341, 35.155846078817802, 0.0],
              [-81.010235517710413, 35.156235478506439, 0.0],
              [-81.010847984466011, 35.157014812011113, 0.0],
              [-81.010930517609822, 35.157770678684145, 0.0],
              [-81.011292584949857, 35.158160411647124, 0.0],
              [-81.011403584305867, 35.15850401193503, 0.0],
              [-81.011178917910954, 35.159694878716493, 0.0],
              [-81.011343384179582, 35.161687678994603, 0.0],
              [-81.011482518252961, 35.161893879110629, 0.0],
              [-81.013294517490962, 35.162582612228498, 0.0],
              [-81.013376984459413, 35.163453011601767, 0.0],
              [-81.013850318226872, 35.164094678812447, 0.0],
              [-81.013961117937001, 35.16455301183079, 0.0],
              [-81.014267518276512, 35.164873878993461, 0.0],
              [-81.014211384755825, 35.165125811891961, 0.0],
              [-81.014099784309323, 35.165171411638084, 0.0],
              [-81.013709384968223, 35.165056612498688, 0.0],
              [-81.012566917709108, 35.164368478544596, 0.0],
              [-81.012289184861231, 35.163406279026105, 0.0],
              [-81.011648584957172, 35.162856011769122, 0.0],
              [-81.011063318411217, 35.162557812281428, 0.0],
              [-81.010477784109611, 35.162396878812118, 0.0],
              [-81.010087184218634, 35.162488078585469, 0.0],
              [-81.010031118155723, 35.162671278463357, 0.0],
              [-81.009612384959084, 35.16301447864771, 0.0],
              [-81.009611718246859, 35.163449678738779, 0.0],
              [-81.009722785064781, 35.163770278796605, 0.0],
              [-81.010056918443553, 35.164205811700405, 0.0],
              [-81.010084317701185, 35.164549478904902, 0.0],
              [-81.010306985192017, 35.164824478470329, 0.0],
              [-81.010055318555089, 35.16539681168706, 0.0],
              [-81.009636584534945, 35.165602612163902, 0.0],
              [-81.009384585154265, 35.166335412311078, 0.0],
              [-81.008573784933475, 35.167640078616181, 0.0],
              [-81.008601317849312, 35.1679150119298, 0.0],
              [-81.009185984289431, 35.168671278544196, 0.0],
              [-81.00918571802724, 35.168785812215702, 0.0],
              [-81.009631517488941, 35.169175611992173, 0.0],
              [-81.009575384341346, 35.169427479137269, 0.0],
              [-81.009463784647266, 35.169496211971705, 0.0],
              [-81.009212718038626, 35.169450078261647, 0.0],
              [-81.008766984420532, 35.169083212109555, 0.0],
              [-81.008321717767629, 35.168418612515964, 0.0],
              [-81.007791584301188, 35.168463878745918, 0.0],
              [-81.006533184209999, 35.170730078639103, 0.0],
              [-81.006532384156316, 35.171302678446338, 0.0],
              [-81.006140917974875, 35.171989478569195, 0.0],
              [-81.005582585300388, 35.172240878637545, 0.0],
              [-81.005387118390161, 35.172446812401901, 0.0],
              [-81.00516258489219, 35.17333987848636, 0.0],
              [-81.005190184974239, 35.173546078441753, 0.0],
              [-81.005719384924177, 35.17409627825198, 0.0],
              [-81.00577351819669, 35.175264278977124, 0.0],
              [-81.00566151849543, 35.17551621223906, 0.0],
              [-81.005688984443296, 35.175814012056158, 0.0],
              [-81.005911384275464, 35.17629507877858, 0.0],
              [-81.006159917472587, 35.178036012356429, 0.0],
              [-81.005963918058868, 35.17851681192424, 0.0],
              [-81.005960384244574, 35.180898812290494, 0.0],
              [-81.005736784920785, 35.181196278648962, 0.0],
              [-81.005652718374719, 35.181516878369479, 0.0],
              [-81.005703117601627, 35.185273078659407, 0.0],
              [-81.005757318566822, 35.186349679189775, 0.0],
              [-81.005644185101431, 35.187357411952284, 0.0],
              [-81.005865318056905, 35.188731678653731, 0.0],
              [-81.006087984116419, 35.189029812538472, 0.0],
              [-81.00703598502669, 35.189488811731067, 0.0],
              [-81.007342584536744, 35.189741011902385, 0.0],
              [-81.007537317649977, 35.190061811900883, 0.0],
              [-81.007536518502633, 35.190634412194548, 0.0],
              [-81.007089584845957, 35.191000412016251, 0.0],
              [-81.007089317582157, 35.191206478743105, 0.0],
              [-81.007256384660963, 35.191435678989791, 0.0],
              [-81.007282784933508, 35.192489278656183, 0.0],
              [-81.008089185047311, 35.194368211940393, 0.0],
              [-81.008423317909077, 35.194849611832488, 0.0],
              [-81.008534384823903, 35.195170278285005, 0.0],
              [-81.00856151853219, 35.195720011984932, 0.0],
              [-81.009063117621551, 35.196178478823697, 0.0],
              [-81.009312584901863, 35.197255212183627, 0.0],
              [-81.010231984260415, 35.198286678255094, 0.0],
              [-81.010315184818054, 35.198653211901608, 0.0],
              [-81.009896718054264, 35.198675678670668, 0.0],
              [-81.008698184496723, 35.197735611563274, 0.0],
              [-81.008336117833906, 35.197277278972749, 0.0],
              [-81.008197318242921, 35.196773211805144, 0.0],
              [-81.007918717640564, 35.196498078268178, 0.0],
              [-81.007639384481138, 35.196612411853785, 0.0],
              [-81.007554584409618, 35.197459679169803, 0.0],
              [-81.007804518534556, 35.198307412144423, 0.0],
              [-81.008138517878763, 35.198834411957712, 0.0],
              [-81.007886384218835, 35.199544278510309, 0.0],
              [-81.007578585122218, 35.200162279020041, 0.0],
              [-81.007688984883572, 35.201055678484266, 0.0],
              [-81.007911584635139, 35.201468211612706, 0.0],
              [-81.008413185185432, 35.201880879061726, 0.0],
              [-81.009389318083308, 35.202225279173703, 0.0],
              [-81.009388984724879, 35.202454412157387, 0.0],
              [-81.009249385062603, 35.202545811908031, 0.0],
              [-81.008774984496696, 35.202614078396266, 0.0],
              [-81.008439917640146, 35.202751211558208, 0.0],
              [-81.007348384954241, 35.204971878643725, 0.0],
              [-81.007403318450088, 35.205613212457457, 0.0],
              [-81.007626118602062, 35.205842478786664, 0.0],
              [-81.007625717542425, 35.20616307884584, 0.0],
              [-81.006814985168603, 35.207170078187907, 0.0],
              [-81.006702985254933, 35.207467678496798, 0.0],
              [-81.006701917450414, 35.208154812154689, 0.0],
              [-81.00700791760076, 35.208865211678749, 0.0],
              [-81.007481784900364, 35.209277878669582, 0.0],
              [-81.008178917489786, 35.209622012130453, 0.0],
              [-81.008484917988582, 35.210240678461567, 0.0],
              [-81.008791384950712, 35.21060741220343, 0.0],
              [-81.009209917962238, 35.210653678583988, 0.0],
              [-81.009321384146304, 35.210745412474616, 0.0],
              [-81.009097385076601, 35.211340678588193, 0.0],
              [-81.009096384379944, 35.212050679087795, 0.0],
              [-81.009291118363606, 35.212486011594351, 0.0],
              [-81.009764918205235, 35.212921612104736, 0.0],
              [-81.010015918500486, 35.213036411926247, 0.0],
              [-81.011132118307415, 35.213037412374987, 0.0],
              [-81.011633985184346, 35.213335678288566, 0.0],
              [-81.012303917754267, 35.213290412096427, 0.0],
              [-81.012778517661175, 35.213130478852072, 0.0],
              [-81.013252984671965, 35.213153811804567, 0.0],
              [-81.013643918436159, 35.213016811565588, 0.0],
              [-81.01381118511037, 35.213108478765115, 0.0],
              [-81.013782385063493, 35.213749811651809, 0.0],
              [-81.013084118415307, 35.214184278398768, 0.0],
              [-81.013083584582944, 35.214505011877371, 0.0],
              [-81.014115784468473, 35.214895279124264, 0.0],
              [-81.014310784176288, 35.215078678256972, 0.0],
              [-81.014365918207091, 35.215674278444823, 0.0],
              [-81.014086784645087, 35.215674011850282, 0.0],
              [-81.0135009174316, 35.215490211533108, 0.0],
              [-81.013193918127016, 35.215535679024711, 0.0],
              [-81.013499584697769, 35.216429278813123, 0.0],
              [-81.013722117902432, 35.217002078978432, 0.0],
              [-81.013972717574177, 35.217483211707417, 0.0],
              [-81.014390784471544, 35.217918811860116, 0.0],
              [-81.015338784820003, 35.218538079065397, 0.0],
              [-81.015505384486431, 35.219179478570297, 0.0],
              [-81.015867785111681, 35.219592078474136, 0.0],
              [-81.016202384794141, 35.21977561197788, 0.0],
              [-81.017067518057701, 35.219776412004443, 0.0],
              [-81.017849184961563, 35.219570878666168, 0.0],
              [-81.01807251746834, 35.219593879008208, 0.0],
              [-81.018099984269227, 35.2199146121349, 0.0],
              [-81.017373584648865, 35.22041787914663, 0.0],
              [-81.016954384501261, 35.220852678889891, 0.0],
              [-81.016730717572258, 35.221219011532355, 0.0],
              [-81.016729718179775, 35.221951879113639, 0.0],
              [-81.017231318260087, 35.222547878951247, 0.0],
              [-81.017621784204195, 35.222731412474602, 0.0],
              [-81.01792838449532, 35.223121012306834, 0.0],
              [-81.01823511820028, 35.223304478337688, 0.0],
              [-81.01823451769836, 35.223716678948051, 0.0],
              [-81.017927517564871, 35.223716479155435, 0.0],
              [-81.017425517864623, 35.223487078487942, 0.0],
              [-81.016979184598981, 35.223211878935217, 0.0],
              [-81.016365918128926, 35.222684478582167, 0.0],
              [-81.015919185192601, 35.222707011937302, 0.0],
              [-81.015805985249344, 35.223989478808889, 0.0],
              [-81.016055984375825, 35.224791278388039, 0.0],
              [-81.016166718463268, 35.225547278672174, 0.0],
              [-81.016445517438939, 35.225845278525021, 0.0],
              [-81.01784098444827, 35.225938078788758, 0.0],
              [-81.017840718281818, 35.226144211976582, 0.0],
              [-81.017616784692692, 35.226533412300263, 0.0],
              [-81.017755385209441, 35.227266479049042, 0.0],
              [-81.017669984202939, 35.22854901234313, 0.0],
              [-81.018450717733685, 35.229190879025886, 0.0],
              [-81.018701584213744, 35.229511812215605, 0.0],
              [-81.01886838422179, 35.229947011640071, 0.0],
              [-81.019230917886844, 35.230222212098596, 0.0],
              [-81.020653717472811, 35.230796011968287, 0.0],
              [-81.020848917936505, 35.231002278454881, 0.0],
              [-81.020904318157534, 35.231300078593542, 0.0],
              [-81.020429585094547, 35.231482878426732, 0.0],
              [-81.020261784840898, 35.231643078648752, 0.0],
              [-81.019815384309595, 35.231505278644747, 0.0],
              [-81.018420984482177, 35.230519212426366, 0.0],
              [-81.017947317468256, 35.230037878727089, 0.0],
              [-81.017696718088885, 35.229488012032832, 0.0],
              [-81.016804584627693, 35.228639811839052, 0.0],
              [-81.016776985175213, 35.22845661169773, 0.0],
              [-81.016581984479487, 35.228158611929729, 0.0],
              [-81.016331718525493, 35.227448478476425, 0.0],
              [-81.015857984986098, 35.226829612310638, 0.0],
              [-81.015522918495222, 35.226898012129823, 0.0],
              [-81.015633918236745, 35.227424878383353, 0.0],
              [-81.015521118409907, 35.228272212064574, 0.0],
              [-81.014062917560551, 35.233149412383121, 0.0],
              [-81.013503317594086, 35.234087878646477, 0.0],
              [-81.013390584483886, 35.234889411723621, 0.0],
              [-81.013250918448932, 35.234980879040883, 0.0],
              [-81.012329517786952, 35.235140279046512, 0.0],
              [-81.012217584715899, 35.235231878860958, 0.0],
              [-81.012244517939564, 35.236033478336012, 0.0],
              [-81.012355984528909, 35.236102411818749, 0.0],
              [-81.013472584860978, 35.23621781186079, 0.0],
              [-81.013416184983427, 35.236584278377315, 0.0],
              [-81.013555518520079, 35.236790478615681, 0.0],
              [-81.013610718118869, 35.237294479096064, 0.0],
              [-81.014308184128495, 35.237547078161114, 0.0],
              [-81.0142799183172, 35.237844679040649, 0.0],
              [-81.014112184163864, 35.238005012134686, 0.0],
              [-81.013441917586121, 35.238256278396392, 0.0],
              [-81.013301984181467, 35.238416412095035, 0.0],
              [-81.013217784129864, 35.238782812060492, 0.0],
              [-81.012658718086328, 35.239400678739521, 0.0],
              [-81.012406784351796, 35.23981267830213, 0.0],
              [-81.012377584790386, 35.240705878331092, 0.0],
              [-81.012600717589791, 35.240958012265445, 0.0],
              [-81.013688517655467, 35.24157747912156, 0.0],
              [-81.013686985175369, 35.242745611875996, 0.0],
              [-81.013295185107083, 35.243501011566195, 0.0],
              [-81.012567717785075, 35.244576811857144, 0.0],
              [-81.012455585100952, 35.244874411518325, 0.0],
              [-81.011952785261229, 35.245080078577807, 0.0],
              [-81.01175751800551, 35.245011278889777, 0.0],
              [-81.011367184335541, 35.244575812315553, 0.0],
              [-81.011256184751034, 35.244140478427902, 0.0],
              [-81.011032918010883, 35.244094479069965, 0.0],
              [-81.010893185268955, 35.244208878346079, 0.0],
              [-81.010668917518672, 35.244781278461339, 0.0],
              [-81.010668185181828, 35.245262278234307, 0.0],
              [-81.010779785157013, 35.245376878737503, 0.0],
              [-81.011310385159845, 35.245285678173808, 0.0],
              [-81.011533384493703, 35.245469078364536, 0.0],
              [-81.011532785235474, 35.245927212012219, 0.0],
              [-81.01119711758173, 35.246430811689891, 0.0],
              [-81.011055918616037, 35.247598678975017, 0.0],
              [-81.010412184949871, 35.248697479024777, 0.0],
              [-81.010132984630857, 35.248651478620125, 0.0],
              [-81.010161784306177, 35.248078878835258, 0.0],
              [-81.010385917482381, 35.247483611570296, 0.0],
              [-81.010386785116523, 35.246842278967698, 0.0],
              [-81.010219584557817, 35.246658878212521, 0.0],
              [-81.00994031842869, 35.246704412269906, 0.0],
              [-81.009883917626112, 35.24713961202719, 0.0],
              [-81.00957571758903, 35.247963811702455, 0.0],
              [-81.009518785084197, 35.248628011563746, 0.0],
              [-81.009992584244301, 35.249246811778804, 0.0],
              [-81.009992184513465, 35.249498678851594, 0.0],
              [-81.010521917505855, 35.250140478529879, 0.0],
              [-81.010687784436584, 35.25044087910657, 0.0],
              [-81.011022518231982, 35.250716078784642, 0.0],
              [-81.012110918534049, 35.251037678299362, 0.0],
              [-81.013004118196775, 35.251176012327313, 0.0],
              [-81.013199518041148, 35.251267678835404, 0.0],
              [-81.013338385130439, 35.251748878866664, 0.0],
              [-81.013505584555986, 35.251955212426026, 0.0],
              [-81.014426384720096, 35.252414011754759, 0.0],
              [-81.014230585162764, 35.252711612102054, 0.0],
              [-81.013196985213071, 35.253031278202045, 0.0],
              [-81.011866118437538, 35.253361211793582, 0.0],
              [-81.010711184203004, 35.25364747847464, 0.0],
              [-81.010348117463209, 35.253693012155779, 0.0],
              [-81.009511184106231, 35.253165412286172, 0.0],
              [-81.009371518254198, 35.25327967896002, 0.0],
              [-81.009147318362466, 35.253852078614464, 0.0],
              [-81.008923517506702, 35.254149611854196, 0.0],
              [-81.008476718604001, 35.254217878150932, 0.0],
              [-81.008281384940631, 35.254057411894856, 0.0],
              [-81.008253785164271, 35.253828411890687, 0.0],
              [-81.00856211785522, 35.25307281241421, 0.0],
              [-81.008506918266946, 35.252523211735678, 0.0],
              [-81.007976917571398, 35.25227061146893, 0.0],
              [-81.007725918264143, 35.25201847864254, 0.0],
              [-81.007255117713115, 35.250137411859868, 0.0],
              [-81.006921384970752, 35.24926667861925, 0.0],
              [-81.006642584485419, 35.248968678926843, 0.0],
              [-81.005805384259446, 35.248716011744207, 0.0],
              [-81.005526518600036, 35.248532478207096, 0.0],
              [-81.005303984673233, 35.247936812332874, 0.0],
              [-81.005052984275025, 35.24768467909297, 0.0],
              [-81.00474598421286, 35.247638478715182, 0.0],
              [-81.004019317712547, 35.248141679032173, 0.0],
              [-81.003572384520112, 35.248278678600656, 0.0],
              [-81.003460784978685, 35.248187011485626, 0.0],
              [-81.003517118286794, 35.247935079026334, 0.0],
              [-81.004243985269838, 35.247317411829556, 0.0],
              [-81.004356518297783, 35.246699211843847, 0.0],
              [-81.004440384625013, 35.246607612179098, 0.0],
              [-81.004635918206432, 35.246584878685226, 0.0],
              [-81.004998517558462, 35.246768478227885, 0.0],
              [-81.005417317594336, 35.246768812144779, 0.0],
              [-81.005556985020476, 35.246700211513684, 0.0],
              [-81.005697385202936, 35.246127811782912, 0.0],
              [-81.006256717918632, 35.245647279106549, 0.0],
              [-81.006480118313462, 35.245556011718932, 0.0],
              [-81.006926518631957, 35.245716679066724, 0.0],
              [-81.006926118612853, 35.245991612342166, 0.0],
              [-81.006394717465881, 35.246655212070493, 0.0],
              [-81.006366317696717, 35.246998811557475, 0.0],
              [-81.006533518213899, 35.247250878331755, 0.0],
              [-81.006616185010813, 35.247938078448357, 0.0],
              [-81.006867518143608, 35.247961211536733, 0.0],
              [-81.007063118640986, 35.247824012345006, 0.0],
              [-81.007203517737906, 35.247251478617073, 0.0],
              [-81.007650718618137, 35.246885478309196, 0.0],
              [-81.007902517776614, 35.246496412296935, 0.0],
              [-81.007986984351845, 35.245992611912762, 0.0],
              [-81.00787558505391, 35.245786411687732, 0.0],
              [-81.007876184367404, 35.245442812190426, 0.0],
              [-81.008016584656104, 35.244939012006014, 0.0],
              [-81.007542184749624, 35.24475541154596, 0.0],
              [-81.007235517759327, 35.244480211593107, 0.0],
              [-81.007235917628606, 35.244205412437232, 0.0],
              [-81.007963385086782, 35.243015078836471, 0.0],
              [-81.008271518119983, 35.242305411621999, 0.0],
              [-81.008525185260964, 35.240656611602986, 0.0],
              [-81.008386784180772, 35.239809012455396, 0.0],
              [-81.008219784441181, 35.239442478930528, 0.0],
              [-81.00780178504256, 35.238961078290345, 0.0],
              [-81.007802984855516, 35.238090678679207, 0.0],
              [-81.00833551813831, 35.236625411845559, 0.0],
              [-81.008670985087022, 35.236259278442972, 0.0],
              [-81.009620184834247, 35.236214278874002, 0.0],
              [-81.00964951834041, 35.235183812319754, 0.0],
              [-81.00923091855779, 35.235114611622215, 0.0],
              [-81.00906358457253, 35.234977078673346, 0.0],
              [-81.008589384120896, 35.234793411545851, 0.0],
              [-81.007361117540455, 35.234838012419679, 0.0],
              [-81.006775184506438, 35.234608411731138, 0.0],
              [-81.006468717965035, 35.234264611869698, 0.0],
              [-81.006469185070969, 35.233898079009435, 0.0],
              [-81.006776385155391, 35.233806811815604, 0.0],
              [-81.007836517431357, 35.234265878356801, 0.0],
              [-81.00833891794089, 35.234312212132465, 0.0],
              [-81.008785784562633, 35.234129412116481, 0.0],
              [-81.009260917672592, 35.233740412457557, 0.0],
              [-81.009848117827147, 35.233031012309283, 0.0],
              [-81.009988184706913, 35.23266461188652, 0.0],
              [-81.010518918559484, 35.232367412061471, 0.0],
              [-81.010770584654395, 35.232092812353976, 0.0],
              [-81.011107118584277, 35.23101667862845, 0.0],
              [-81.011304118375179, 35.229802878158459, 0.0],
              [-81.010943184495574, 35.228405478655823, 0.0],
              [-81.01089011833497, 35.226481611498016, 0.0],
              [-81.011756184920088, 35.225818212134065, 0.0],
              [-81.012175985272364, 35.225039811917647, 0.0],
              [-81.01214971855363, 35.223894679034387, 0.0],
              [-81.011954784190806, 35.223528078855807, 0.0],
              [-81.010616784317364, 35.222335811840829, 0.0],
              [-81.01058971754145, 35.22176321234916, 0.0],
              [-81.010673785043934, 35.221534211695094, 0.0],
              [-81.010562917658206, 35.220984478781901, 0.0],
              [-81.010144918058927, 35.220480212268384, 0.0],
              [-81.010174184633584, 35.219449478480819, 0.0],
              [-81.010063318114391, 35.218968478252755, 0.0],
              [-81.009868184340476, 35.218739278446421, 0.0],
              [-81.009366318455591, 35.21844101190117, 0.0],
              [-81.009283518520235, 35.217822478880031, 0.0],
              [-81.010401117806325, 35.216861678918434, 0.0],
              [-81.010401984703009, 35.216243278288978, 0.0],
              [-81.010290784200109, 35.215945412159215, 0.0],
              [-81.009566517643918, 35.214982812026427, 0.0],
              [-81.009371384367213, 35.214868078742263, 0.0],
              [-81.009092185230614, 35.214959411898434, 0.0],
              [-81.009091917848792, 35.215165612174502, 0.0],
              [-81.009676584834054, 35.216128012486337, 0.0],
              [-81.009703785051514, 35.216609079145755, 0.0],
              [-81.009424317971792, 35.216883679088987, 0.0],
              [-81.009005584703743, 35.21692907869852, 0.0],
              [-81.008782717798937, 35.216699811893321, 0.0],
              [-81.008587918212626, 35.216287278732878, 0.0],
              [-81.007890518243372, 35.216057611773302, 0.0],
              [-81.00744438499251, 35.21575947899796, 0.0],
              [-81.007138384969181, 35.215117878209192, 0.0],
              [-81.006246917551934, 35.214040611789372, 0.0],
              [-81.00552218525543, 35.213444411677678, 0.0],
              [-81.005355584737032, 35.212871678328, 0.0],
              [-81.004881785075924, 35.212413211698525, 0.0],
              [-81.004101317740293, 35.211839812392228, 0.0],
              [-81.004045718314671, 35.211656478380021, 0.0],
              [-81.003822584669578, 35.211564678523509, 0.0],
              [-81.003348117512104, 35.211632878396735, 0.0],
              [-81.002454384854786, 35.212044279128278, 0.0],
              [-81.00234251763392, 35.212250411560191, 0.0],
              [-81.002313918459066, 35.212662612204198, 0.0],
              [-81.002202117721637, 35.212868612122371, 0.0],
              [-81.002201384868442, 35.213280878307152, 0.0],
              [-81.002312584867113, 35.213532878347863, 0.0],
              [-81.003009318180361, 35.214174878552534, 0.0],
              [-81.003036985151823, 35.214358078557204, 0.0],
              [-81.002897118087688, 35.214632878386908, 0.0],
              [-81.002589584947359, 35.214930412224625, 0.0],
              [-81.00197518502388, 35.215250412366217, 0.0],
              [-81.001834917580069, 35.21573121167846, 0.0],
              [-81.001248517963688, 35.215913812070383, 0.0],
              [-81.00102498447086, 35.216051011884488, 0.0],
              [-81.000968917613946, 35.216302879113329, 0.0],
              [-81.000577717789994, 35.216554478889684, 0.0],
              [-80.999796584582384, 35.216576478355712, 0.0],
            ],
            [
              [-81.011508784860723, 35.243224612119427, 0.0],
              [-81.011843584737221, 35.243316478230334, 0.0],
              [-81.012513984189482, 35.24301941173502, 0.0],
              [-81.012514784905491, 35.242492611474752, 0.0],
              [-81.012180584353558, 35.241873878290711, 0.0],
              [-81.011985518120113, 35.241690478595316, 0.0],
              [-81.011845918095204, 35.241690412012936, 0.0],
              [-81.011593184140594, 35.242675011656225, 0.0],
              [-81.0115093847173, 35.242743612327587, 0.0],
              [-81.011508784860723, 35.243224612119427, 0.0],
            ],
            [
              [-81.061086185143438, 35.213530878199677, 0.0],
              [-81.061309385011597, 35.213576812258971, 0.0],
              [-81.061532717938491, 35.213485411779416, 0.0],
              [-81.061449385046387, 35.213095878276917, 0.0],
              [-81.06119851772057, 35.212614812068026, 0.0],
              [-81.060111117794833, 35.211354612421211, 0.0],
              [-81.059748318488062, 35.21140027893145, 0.0],
              [-81.059775984099659, 35.21172101220256, 0.0],
              [-81.060417184053094, 35.21259147874288, 0.0],
              [-81.060612118235468, 35.213095478630301, 0.0],
              [-81.061086185143438, 35.213530878199677, 0.0],
            ],
            [
              [-81.073383918125614, 35.22890487857098, 0.0],
              [-81.072238118214827, 35.230851278829547, 0.0],
              [-81.072238118378266, 35.231057411714872, 0.0],
              [-81.072461184877369, 35.231263678317482, 0.0],
              [-81.073103317529245, 35.231149412191236, 0.0],
              [-81.07321498428108, 35.231057811602525, 0.0],
              [-81.073159384656464, 35.23066847873433, 0.0],
              [-81.073717917926075, 35.230485411926757, 0.0],
              [-81.073801584048582, 35.230348011522175, 0.0],
              [-81.074220517995812, 35.230187811687273, 0.0],
              [-81.074220784360975, 35.229569479141851, 0.0],
              [-81.073718784071517, 35.228882078509372, 0.0],
              [-81.073607117383318, 35.228836278696612, 0.0],
              [-81.073383918125614, 35.22890487857098, 0.0],
            ],
            [
              [-81.066874984652856, 35.236116678680624, 0.0],
              [-81.066902718158218, 35.236322879092143, 0.0],
              [-81.067321317392313, 35.236666612343463, 0.0],
              [-81.068325917850544, 35.237171012444364, 0.0],
              [-81.069944584169193, 35.237790078386993, 0.0],
              [-81.070279384633906, 35.238065078574117, 0.0],
              [-81.071896784665441, 35.240860078748803, 0.0],
              [-81.072231184729617, 35.241661811994099, 0.0],
              [-81.072482318214298, 35.241959611537098, 0.0],
              [-81.072984784872617, 35.24200567909449, 0.0],
              [-81.072985117481508, 35.241456011559478, 0.0],
              [-81.072817984667154, 35.240974878203538, 0.0],
              [-81.07234371768196, 35.240562478203273, 0.0],
              [-81.072148717970805, 35.239737812385002, 0.0],
              [-81.071507184313049, 35.238775612212734, 0.0],
              [-81.071033384106698, 35.237676078424741, 0.0],
              [-81.070643585036152, 35.236072612292084, 0.0],
              [-81.070197384098449, 35.235477012019032, 0.0],
              [-81.069611385183549, 35.235224678738518, 0.0],
              [-81.06947178419513, 35.235224678844439, 0.0],
              [-81.068969117658753, 35.235522212058513, 0.0],
              [-81.068382717835931, 35.23572801155337, 0.0],
              [-81.067126317669292, 35.236002278805053, 0.0],
              [-81.066874984652856, 35.236116678680624, 0.0],
            ],
            [
              [-81.033309384155956, 35.121784079213526, 0.0],
              [-81.032947317629407, 35.121600611812056, 0.0],
              [-81.032668385003888, 35.1216692124475, 0.0],
              [-81.032584584065845, 35.121783678661792, 0.0],
              [-81.032584317469414, 35.122104278968756, 0.0],
              [-81.032695718288707, 35.122264679076423, 0.0],
              [-81.033308918157445, 35.122333812059757, 0.0],
              [-81.033476318146796, 35.122150678975466, 0.0],
              [-81.033309384155956, 35.121784079213526, 0.0],
            ],
            [
              [-81.029423984677351, 35.107191412155643, 0.0],
              [-81.029509184639366, 35.105771412329027, 0.0],
              [-81.029425584887505, 35.105725478562654, 0.0],
              [-81.029146784768344, 35.105839812628773, 0.0],
              [-81.028700518045895, 35.106160212114723, 0.0],
              [-81.02775311775558, 35.106113612490851, 0.0],
              [-81.026861384072191, 35.105975478850837, 0.0],
              [-81.026610385212052, 35.106044012142391, 0.0],
              [-81.026247718598512, 35.106341479188401, 0.0],
              [-81.026052185007075, 35.106685012624219, 0.0],
              [-81.026079584784767, 35.107120212445444, 0.0],
              [-81.026441585134975, 35.107395211698709, 0.0],
              [-81.027918718158745, 35.107396411962597, 0.0],
              [-81.028643184717978, 35.10748861174222, 0.0],
              [-81.029200718590616, 35.107374479259761, 0.0],
              [-81.029423984677351, 35.107191412155643, 0.0],
            ],
            [
              [-81.041162917604169, 35.047603079411488, 0.0],
              [-81.041581117809457, 35.047145278578931, 0.0],
              [-81.041581718151349, 35.046664278876285, 0.0],
              [-81.04149831788456, 35.04645807873684, 0.0],
              [-81.040663184156784, 35.045999411983225, 0.0],
              [-81.040663318063324, 35.045907811796447, 0.0],
              [-81.040218184962356, 35.045541012542138, 0.0],
              [-81.039939718421053, 35.045586811826261, 0.0],
              [-81.039660984264984, 35.045792678676953, 0.0],
              [-81.039771784377905, 35.046296679277859, 0.0],
              [-81.039853984818919, 35.047602278864474, 0.0],
              [-81.040132184587108, 35.047785678986585, 0.0],
              [-81.041162917604169, 35.047603079411488, 0.0],
            ],
            [
              [-81.045525784565072, 35.028801411914323, 0.0],
              [-81.045776518431808, 35.028641212144066, 0.0],
              [-81.045776984995172, 35.028251812544504, 0.0],
              [-81.0456101182982, 35.028091412576281, 0.0],
              [-81.045442985098163, 35.028068412690367, 0.0],
              [-81.045080984193575, 35.028136812327226, 0.0],
              [-81.04491371828891, 35.028342878944571, 0.0],
              [-81.044941317587572, 35.028663478963068, 0.0],
              [-81.045080384529584, 35.028824012521504, 0.0],
              [-81.045525784565072, 35.028801411914323, 0.0],
            ],
            [
              [-81.02388618456456, 35.145276278898017, 0.0],
              [-81.023691118286848, 35.145276212337109, 0.0],
              [-81.023411785180116, 35.145619478613682, 0.0],
              [-81.022742318476986, 35.145825078827627, 0.0],
              [-81.022602784640256, 35.145939479240766, 0.0],
              [-81.022629985028018, 35.146443412087656, 0.0],
              [-81.022769185242623, 35.146558079049314, 0.0],
              [-81.023354917766412, 35.146489811689463, 0.0],
              [-81.024220184891405, 35.145734678804672, 0.0],
              [-81.024220584142526, 35.145528611738534, 0.0],
              [-81.02388618456456, 35.145276278898017, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2876,
        ComID: 9750684,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.015,
        Elevation: 0.0,
        ReachCode: "03050101002282",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.00542042749,
        Shape_Area: 1.52451e-6,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.923271518084562, 35.679791411079478, 0.0],
              [-80.923636185035349, 35.679562277897851, 0.0],
              [-80.924029185083882, 35.679608011449218, 0.0],
              [-80.924309985208865, 35.679791010791604, 0.0],
              [-80.924478984980809, 35.680729878218159, 0.0],
              [-80.924647585064534, 35.681027611138632, 0.0],
              [-80.924732118509255, 35.681416877668219, 0.0],
              [-80.92464791779075, 35.681531478087706, 0.0],
              [-80.924479384743975, 35.681531411127544, 0.0],
              [-80.924282917978786, 35.681394211726619, 0.0],
              [-80.924142384316781, 35.681142277920259, 0.0],
              [-80.923552584802565, 35.680661611306249, 0.0],
              [-80.923468184431584, 35.680272277734112, 0.0],
              [-80.923271585381542, 35.680089078051822, 0.0],
              [-80.923271518084562, 35.679791411079478, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2880,
        ComID: 9750692,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.024,
        Elevation: 0.0,
        ReachCode: "03050101002290",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.00852088165,
        Shape_Area: 2.4174e-6,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.862452385140969, 35.634752277742223, 0.0],
              [-80.862676918760314, 35.634614878039535, 0.0],
              [-80.862817184629648, 35.634660811825761, 0.0],
              [-80.862957384930084, 35.635164611726118, 0.0],
              [-80.862957185156731, 35.635897477534826, 0.0],
              [-80.861021385271357, 35.636676011213744, 0.0],
              [-80.860516385154654, 35.636996478279094, 0.0],
              [-80.860319917696032, 35.63724861106035, 0.0],
              [-80.860151584429445, 35.637271411310884, 0.0],
              [-80.860133918160443, 35.63715561106202, 0.0],
              [-80.860067585230752, 35.636721677599922, 0.0],
              [-80.860102784682141, 35.63669887774077, 0.0],
              [-80.861273984617881, 35.635943211290233, 0.0],
              [-80.862115718481789, 35.635233277756321, 0.0],
              [-80.862452385140969, 35.634752277742223, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 2881,
        ComID: 9750694,
        FDate: "1999/10/05",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.065,
        Elevation: 0.0,
        ReachCode: "03050101002291",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.01787000689,
        Shape_Area: 6.42693e-6,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.859508185501127, 35.628087411823479, 0.0],
              [-80.859087518316414, 35.627812611484671, 0.0],
              [-80.85802151867766, 35.627881078293022, 0.0],
              [-80.856759185251349, 35.627766411522408, 0.0],
              [-80.855104184852351, 35.627789078351235, 0.0],
              [-80.854627318153348, 35.627651678415575, 0.0],
              [-80.854318918404019, 35.627445411391768, 0.0],
              [-80.854318918070703, 35.627147677652346, 0.0],
              [-80.854543384426933, 35.62719361129497, 0.0],
              [-80.854879918787773, 35.627399811605237, 0.0],
              [-80.855216585377448, 35.627445477595394, 0.0],
              [-80.856170185324743, 35.627354077619763, 0.0],
              [-80.856647118201721, 35.627148078145375, 0.0],
              [-80.857011784362854, 35.627354211044256, 0.0],
              [-80.857348384458319, 35.627262677603632, 0.0],
              [-80.857600918420175, 35.627056611510632, 0.0],
              [-80.858049718031694, 35.627171078073722, 0.0],
              [-80.858161918243653, 35.627125278474104, 0.0],
              [-80.858218117892605, 35.626781878488515, 0.0],
              [-80.858863318807565, 35.626896278282942, 0.0],
              [-80.859284117818959, 35.626736077650698, 0.0],
              [-80.859536518506161, 35.626759011533764, 0.0],
              [-80.86034991827654, 35.627240078211841, 0.0],
              [-80.860938917802358, 35.627469078242413, 0.0],
              [-80.86124738458858, 35.627721078002111, 0.0],
              [-80.861527784347572, 35.6285228111783, 0.0],
              [-80.861695984456418, 35.629095411693598, 0.0],
              [-80.861723984434079, 35.629393078051734, 0.0],
              [-80.861387385035485, 35.629393011436449, 0.0],
              [-80.860658118835289, 35.628980811837906, 0.0],
              [-80.860377584720368, 35.628705878068352, 0.0],
              [-80.860040985307634, 35.628614277623491, 0.0],
              [-80.859508185501127, 35.628087411823479, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1541,
        ComID: 9755936,
        FDate: "1999/10/12",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Wylie",
        AreaSqKm: 0.031,
        Elevation: 0.0,
        ReachCode: "03050101002396",
        FType: 390,
        FCode: 39004,
        Shape_Leng: 0.00945852302,
        Shape_Area: 3.09421e-6,
        Reservoir: 35,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.00649811839024, 35.175746011631418, 0.0],
              [-81.006555318363027, 35.174738278765346, 0.0],
              [-81.006778518132677, 35.174738478480052, 0.0],
              [-81.007252384567977, 35.174922212272207, 0.0],
              [-81.008033384388483, 35.174945812454183, 0.0],
              [-81.008618717808673, 35.175198279161329, 0.0],
              [-81.00953898511176, 35.175405278463558, 0.0],
              [-81.010320118001971, 35.175360279046501, 0.0],
              [-81.010487317502083, 35.17545187908653, 0.0],
              [-81.010431317718954, 35.175681012201622, 0.0],
              [-81.00995671768402, 35.175886611813795, 0.0],
              [-81.009063918277704, 35.176000412214371, 0.0],
              [-81.009035784461403, 35.176137812244527, 0.0],
              [-81.00870118484309, 35.176114612190752, 0.0],
              [-81.00761371798535, 35.17581581224124, 0.0],
              [-81.006721118494937, 35.175815011595134, 0.0],
              [-81.00649811839024, 35.175746011631418, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1591,
        ComID: 9751274,
        FDate: "1999/10/08",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Hickory",
        AreaSqKm: 15.59,
        Elevation: 284.0,
        ReachCode: "03050101002270",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 1.53528398773,
        Shape_Area: 0.00155448497,
        Reservoir: 33,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.264739984442102, 35.830238810844946, 0.0],
              [-81.263671783987846, 35.829917210827766, 0.0],
              [-81.26277271805607, 35.829229277384414, 0.0],
              [-81.261957717625734, 35.828862077623008, 0.0],
              [-81.261003384186026, 35.827670411097174, 0.0],
              [-81.260160183833477, 35.827280210658678, 0.0],
              [-81.259823384460319, 35.826890611070652, 0.0],
              [-81.258474984496942, 35.82579001090717, 0.0],
              [-81.258025383805744, 35.825606410979596, 0.0],
              [-81.257829317562795, 35.825102278103131, 0.0],
              [-81.257379917215772, 35.824689610846889, 0.0],
              [-81.256789984158829, 35.824345610836438, 0.0],
              [-81.256311984666254, 35.824230677980928, 0.0],
              [-81.255777717834235, 35.824321677807369, 0.0],
              [-81.255608517813982, 35.824596278063858, 0.0],
              [-81.255299117675023, 35.824664811399238, 0.0],
              [-81.255272518248972, 35.823680011475567, 0.0],
              [-81.255047917636844, 35.823450678040665, 0.0],
              [-81.25445758450816, 35.823266877814739, 0.0],
              [-81.253950717891527, 35.823793211535545, 0.0],
              [-81.253162718054824, 35.824227478017988, 0.0],
              [-81.253134184165432, 35.82443361085565, 0.0],
              [-81.253752518114567, 35.824663211399212, 0.0],
              [-81.253808318241923, 35.824915211448946, 0.0],
              [-81.253047317478192, 35.826105410903509, 0.0],
              [-81.252708584096979, 35.826929411124461, 0.0],
              [-81.252707584920103, 35.827593611247956, 0.0],
              [-81.253241517766199, 35.827846011246095, 0.0],
              [-81.253241184644665, 35.828029210615604, 0.0],
              [-81.253016184195161, 35.828097678170671, 0.0],
              [-81.252453784202672, 35.828097077605648, 0.0],
              [-81.252087917776151, 35.828280010558743, 0.0],
              [-81.251354984115437, 35.829355611345349, 0.0],
              [-81.251241917939822, 35.82983641142129, 0.0],
              [-81.251493784698113, 35.830615410897977, 0.0],
              [-81.251071983802078, 35.830592010593655, 0.0],
              [-81.25022878467405, 35.830201811217741, 0.0],
              [-81.249805184288135, 35.82992501088281, 0.0],
              [-81.249607983914615, 35.829764878085513, 0.0],
              [-81.249579717819188, 35.829604677873384, 0.0],
              [-81.249803984441698, 35.829215077534215, 0.0],
              [-81.250090117378448, 35.82903367797806, 0.0],
              [-81.25009038481042, 35.828873410850129, 0.0],
              [-81.249803318057346, 35.828734077344691, 0.0],
              [-81.249549917956301, 35.828528211447747, 0.0],
              [-81.249802584225861, 35.828344677310007, 0.0],
              [-81.250822584502131, 35.828164210581278, 0.0],
              [-81.251385384258967, 35.827912810853192, 0.0],
              [-81.251582784860332, 35.827592410726837, 0.0],
              [-81.251639784101556, 35.827065677929731, 0.0],
              [-81.251386983921321, 35.826859410968616, 0.0],
              [-81.251387183804013, 35.826744877359118, 0.0],
              [-81.252090717691829, 35.826425011124812, 0.0],
              [-81.252203517913529, 35.826241877494304, 0.0],
              [-81.252149518102854, 35.824753210979893, 0.0],
              [-81.251615384166442, 35.824661011398305, 0.0],
              [-81.250996518019534, 35.824843610967037, 0.0],
              [-81.250968717277445, 35.824568811026751, 0.0],
              [-81.251981784194328, 35.824043077577258, 0.0],
              [-81.252291717767889, 35.82369987747343, 0.0],
              [-81.252489717909242, 35.822967277523709, 0.0],
              [-81.252377917049557, 35.822440411025433, 0.0],
              [-81.252181317213868, 35.822257010680524, 0.0],
              [-81.250860117494213, 35.822049477562793, 0.0],
              [-81.250494717286173, 35.821934677359835, 0.0],
              [-81.250495117239097, 35.821682811131616, 0.0],
              [-81.25072031721858, 35.821522610695375, 0.0],
              [-81.251817183925979, 35.821317677480742, 0.0],
              [-81.252014317126552, 35.821157611320835, 0.0],
              [-81.252015983918227, 35.820126878022784, 0.0],
              [-81.25218518445412, 35.819714877501568, 0.0],
              [-81.252157784486087, 35.819302610740046, 0.0],
              [-81.251595584904877, 35.819141810918765, 0.0],
              [-81.251483384249539, 35.819004277712395, 0.0],
              [-81.251596317582141, 35.818752478078032, 0.0],
              [-81.251933984038573, 35.8184778779505, 0.0],
              [-81.252608984124279, 35.818387077271261, 0.0],
              [-81.252609317427741, 35.818203877324166, 0.0],
              [-81.252160184190458, 35.817722478207308, 0.0],
              [-81.252048917266052, 35.816966611190431, 0.0],
              [-81.251542917921753, 35.816920277842463, 0.0],
              [-81.251374117406812, 35.816988810873553, 0.0],
              [-81.251148717497273, 35.817240477940139, 0.0],
              [-81.25036118441686, 35.817354210681259, 0.0],
              [-81.25010791804047, 35.817514210800603, 0.0],
              [-81.250079517768796, 35.817766077339449, 0.0],
              [-81.250247318215045, 35.818293011522641, 0.0],
              [-81.250134717472818, 35.818430277477702, 0.0],
              [-81.249815184232261, 35.818428410780953, 0.0],
              [-81.249505317133895, 35.818062277494981, 0.0],
              [-81.249504717282889, 35.817650077866183, 0.0],
              [-81.24916731735685, 35.817742011400433, 0.0],
              [-81.248550718083678, 35.819002210587442, 0.0],
              [-81.248101784572782, 35.819598210732387, 0.0],
              [-81.247511917407039, 35.81996521128773, 0.0],
              [-81.247146317553785, 35.820011411455972, 0.0],
              [-81.247145918093779, 35.819713677524284, 0.0],
              [-81.247510917668393, 35.819324010579898, 0.0],
              [-81.247818918171347, 35.818522077300152, 0.0],
              [-81.24840838394222, 35.817880211408031, 0.0],
              [-81.248463784483334, 35.817330477543592, 0.0],
              [-81.248013517655025, 35.817079077321267, 0.0],
              [-81.247057184124543, 35.816942677841503, 0.0],
              [-81.246157517550031, 35.816966410705909, 0.0],
              [-81.245567717191875, 35.817356410967086, 0.0],
              [-81.245314384334051, 35.817310877663175, 0.0],
              [-81.245285983802162, 35.817104677581632, 0.0],
              [-81.245482384811254, 35.816784011483186, 0.0],
              [-81.24539791756338, 35.816646678120399, 0.0],
              [-81.244891317612584, 35.816395211277431, 0.0],
              [-81.244075784921037, 35.816281477885894, 0.0],
              [-81.243654117621716, 35.816304877527926, 0.0],
              [-81.243204918250001, 35.816832010862818, 0.0],
              [-81.242923784731019, 35.816832277814626, 0.0],
              [-81.242782917818872, 35.816672211210481, 0.0],
              [-81.24275351722676, 35.815824810798475, 0.0],
              [-81.242303383778605, 35.815596210717629, 0.0],
              [-81.242190318054384, 35.815207077780833, 0.0],
              [-81.241964984323999, 35.814932411141733, 0.0],
              [-81.241683584805784, 35.814795277444695, 0.0],
              [-81.241093184489131, 35.814795877334966, 0.0],
              [-81.240868517211851, 35.814956410691742, 0.0],
              [-81.240644383766352, 35.8155292111121, 0.0],
              [-81.240672984337891, 35.815849678056594, 0.0],
              [-81.241095717815242, 35.816536277722115, 0.0],
              [-81.240955984289783, 35.817086277610173, 0.0],
              [-81.240957518219915, 35.818071010691213, 0.0],
              [-81.240732984571196, 35.818346010772807, 0.0],
              [-81.240507984367142, 35.818346210807661, 0.0],
              [-81.240366984870306, 35.818140277320943, 0.0],
              [-81.240422383836105, 35.817590478143799, 0.0],
              [-81.240309917270594, 35.817499010764095, 0.0],
              [-81.240000384631955, 35.817453610619481, 0.0],
              [-81.239944117676572, 35.817384877439991, 0.0],
              [-81.240055718164072, 35.816812277714156, 0.0],
              [-81.239942917332272, 35.816537610942952, 0.0],
              [-81.239633384033766, 35.816354610914992, 0.0],
              [-81.238902384122483, 35.81637827767743, 0.0],
              [-81.238817917119093, 35.816332477903458, 0.0],
              [-81.238817517206854, 35.816057677972722, 0.0],
              [-81.239520184568121, 35.815850811093092, 0.0],
              [-81.239519583955257, 35.815484410658819, 0.0],
              [-81.239013118193796, 35.815164277604332, 0.0],
              [-81.238197717172085, 35.815188011054225, 0.0],
              [-81.237466917381525, 35.815349011150992, 0.0],
              [-81.236988918018227, 35.815303677359729, 0.0],
              [-81.236173184816252, 35.815029610989825, 0.0],
              [-81.235779583767737, 35.815121611288497, 0.0],
              [-81.23566731765743, 35.815236210827464, 0.0],
              [-81.235667784585843, 35.815625611392349, 0.0],
              [-81.236344517141944, 35.816953077873968, 0.0],
              [-81.236316784252594, 35.817205078198626, 0.0],
              [-81.236204384206204, 35.817273878085985, 0.0],
              [-81.23564171798445, 35.81704541140342, 0.0],
              [-81.235359917840057, 35.81658767789488, 0.0],
              [-81.235331184534218, 35.816152610660495, 0.0],
              [-81.234880917523199, 35.815809477788598, 0.0],
              [-81.234543518207673, 35.81585561066057, 0.0],
              [-81.234150584183027, 35.816405478026333, 0.0],
              [-81.234151317588513, 35.816863610676613, 0.0],
              [-81.234263917929212, 35.817023677315468, 0.0],
              [-81.234264183846946, 35.817252811070311, 0.0],
              [-81.23384258440835, 35.817344810977865, 0.0],
              [-81.233646518094531, 35.817894611506446, 0.0],
              [-81.233423184007904, 35.819108677563932, 0.0],
              [-81.233254984208202, 35.819383610586748, 0.0],
              [-81.232974117621666, 35.819567077948193, 0.0],
              [-81.232777318133458, 35.819567210659564, 0.0],
              [-81.232664717740406, 35.819475677671797, 0.0],
              [-81.232692184184344, 35.81915507753196, 0.0],
              [-81.232916717180942, 35.818811410961899, 0.0],
              [-81.232916118111916, 35.818330477672461, 0.0],
              [-81.232803384229243, 35.8182390113843, 0.0],
              [-81.232353517204601, 35.818193478229212, 0.0],
              [-81.23235331822525, 35.818033210857926, 0.0],
              [-81.232718117186621, 35.817529010751947, 0.0],
              [-81.232717183970848, 35.816887811318004, 0.0],
              [-81.232632918134996, 35.816842077636522, 0.0],
              [-81.232660584677831, 35.816544278070779, 0.0],
              [-81.233053717519354, 35.81617761111719, 0.0],
              [-81.233193984306368, 35.815925477381121, 0.0],
              [-81.233193584889349, 35.815604878216917, 0.0],
              [-81.233052784884165, 35.815490610827446, 0.0],
              [-81.232630917516417, 35.815422211226064, 0.0],
              [-81.231084517413692, 35.815469411356943, 0.0],
              [-81.23060638485957, 35.815332410647066, 0.0],
              [-81.23049318486396, 35.814737011233412, 0.0],
              [-81.230380517468191, 35.814599811081393, 0.0],
              [-81.230070984397884, 35.81448547804694, 0.0],
              [-81.229593184834741, 35.814577610889337, 0.0],
              [-81.229200184965734, 35.815035878076728, 0.0],
              [-81.229256984142154, 35.815448077302186, 0.0],
              [-81.229454317671937, 35.815860077422606, 0.0],
              [-81.229426583856664, 35.816157877348346, 0.0],
              [-81.229258117478224, 35.816387077500451, 0.0],
              [-81.22894891815929, 35.816387278051536, 0.0],
              [-81.228835317603071, 35.815494211443841, 0.0],
              [-81.228553517538614, 35.81496781139532, 0.0],
              [-81.228215517286813, 35.814578677418119, 0.0],
              [-81.22759691749738, 35.814556410977779, 0.0],
              [-81.227596583760302, 35.814304478007571, 0.0],
              [-81.228242917759346, 35.814006211156872, 0.0],
              [-81.228326917220741, 35.813731277286436, 0.0],
              [-81.228185718192677, 35.813250411431042, 0.0],
              [-81.227453584474787, 35.812403810587639, 0.0],
              [-81.227453318219474, 35.812220611492044, 0.0],
              [-81.226777984171775, 35.811831811014166, 0.0],
              [-81.225822184579215, 35.811924211520946, 0.0],
              [-81.225372517904432, 35.812084878017053, 0.0],
              [-81.225007784320852, 35.812612010971186, 0.0],
              [-81.223968184308859, 35.813208211308556, 0.0],
              [-81.223968517293031, 35.813437277514922, 0.0],
              [-81.22416571737584, 35.813734810911264, 0.0],
              [-81.22425051790114, 35.814146877989003, 0.0],
              [-81.224447784045736, 35.814513278089663, 0.0],
              [-81.224420117666384, 35.814811011317069, 0.0],
              [-81.224195718026138, 35.815246410624326, 0.0],
              [-81.22419658428305, 35.815956277529843, 0.0],
              [-81.224534584761798, 35.816436877982717, 0.0],
              [-81.22453558436618, 35.817261277732904, 0.0],
              [-81.224451517750907, 35.817375877644622, 0.0],
              [-81.224423984721668, 35.817925610870937, 0.0],
              [-81.224311584135421, 35.818086011186075, 0.0],
              [-81.224086784925177, 35.818063210955607, 0.0],
              [-81.223974117981001, 35.817971811435861, 0.0],
              [-81.223944917119368, 35.817078677473965, 0.0],
              [-81.223606984513708, 35.816689610912597, 0.0],
              [-81.223409717983458, 35.816231677980838, 0.0],
              [-81.223128118068814, 35.815934211175858, 0.0],
              [-81.222818717304108, 35.815865810787258, 0.0],
              [-81.222846583848437, 35.815636810611693, 0.0],
              [-81.223155184183184, 35.815109811377134, 0.0],
              [-81.223013717723759, 35.814354210726357, 0.0],
              [-81.222675984434034, 35.814148277400349, 0.0],
              [-81.221945117323941, 35.814240610998617, 0.0],
              [-81.221439384837012, 35.814538677580586, 0.0],
              [-81.221214917588114, 35.814928077622369, 0.0],
              [-81.220738584643058, 35.816325477246451, 0.0],
              [-81.220626183868916, 35.816394277381526, 0.0],
              [-81.22017618413696, 35.816280077417638, 0.0],
              [-81.219922984025771, 35.816120011020509, 0.0],
              [-81.21961378459639, 35.816166078055552, 0.0],
              [-81.219473384611206, 35.816372278045272, 0.0],
              [-81.219530118078524, 35.816784477979319, 0.0],
              [-81.220290117262962, 35.817425210754607, 0.0],
              [-81.220908984140479, 35.817699610982388, 0.0],
              [-81.221246917833284, 35.818134411052846, 0.0],
              [-81.221275318038224, 35.818294678062109, 0.0],
              [-81.221613183835885, 35.818706610740215, 0.0],
              [-81.221613384793073, 35.818935610730485, 0.0],
              [-81.221332184886194, 35.81889007795705, 0.0],
              [-81.22082558490203, 35.818432477990967, 0.0],
              [-81.220797183822242, 35.8183178780479, 0.0],
              [-81.22037518464569, 35.81808927735667, 0.0],
              [-81.219587917695208, 35.818112811117444, 0.0],
              [-81.219391518282649, 35.818410611164708, 0.0],
              [-81.219419917407293, 35.818731277334656, 0.0],
              [-81.219645584449268, 35.819257810663984, 0.0],
              [-81.219758917711673, 35.819921811331149, 0.0],
              [-81.219646517235475, 35.820036410664429, 0.0],
              [-81.219449717810832, 35.820036610636606, 0.0],
              [-81.218464384408449, 35.819098477398214, 0.0],
              [-81.218210917894723, 35.818732211338663, 0.0],
              [-81.217311184818158, 35.818824477779003, 0.0],
              [-81.216777317346171, 35.819099677665491, 0.0],
              [-81.216440318042146, 35.819466410823892, 0.0],
              [-81.216356317223486, 35.819695477651045, 0.0],
              [-81.216441517197921, 35.820497011399141, 0.0],
              [-81.216948117962446, 35.8209318108037, 0.0],
              [-81.217398118068118, 35.820954277326024, 0.0],
              [-81.217314517205395, 35.821641410997692, 0.0],
              [-81.217708317120369, 35.821686877514097, 0.0],
              [-81.217709117702555, 35.822305277767803, 0.0],
              [-81.21787798454838, 35.822465477461435, 0.0],
              [-81.218384384049131, 35.822671211266297, 0.0],
              [-81.219649784812546, 35.82267007819511, 0.0],
              [-81.219565584312619, 35.822853410662603, 0.0],
              [-81.218834784224114, 35.82315167767473, 0.0],
              [-81.218778984539412, 35.823495277732704, 0.0],
              [-81.219257983921466, 35.824388078122354, 0.0],
              [-81.219596517646124, 35.825258077864333, 0.0],
              [-81.219934184222794, 35.825418011470369, 0.0],
              [-81.220637384949185, 35.825509210679805, 0.0],
              [-81.220553184945359, 35.825692411413357, 0.0],
              [-81.219850984058951, 35.826403010856652, 0.0],
              [-81.219597917128326, 35.826334477606551, 0.0],
              [-81.218893184921399, 35.824983677224672, 0.0],
              [-81.218752517697595, 35.824869411067162, 0.0],
              [-81.21849938390703, 35.824892477242209, 0.0],
              [-81.218274584341486, 35.825030011158951, 0.0],
              [-81.218049984721773, 35.825373810620448, 0.0],
              [-81.217797117396557, 35.825419811002511, 0.0],
              [-81.217712583848623, 35.825351078169582, 0.0],
              [-81.217768517978115, 35.825053410866033, 0.0],
              [-81.217908784512105, 35.824778411334783, 0.0],
              [-81.217880384981001, 35.824549478184821, 0.0],
              [-81.217627117804852, 35.824366411499973, 0.0],
              [-81.217317117142898, 35.823794078157462, 0.0],
              [-81.216754518247711, 35.823565611528657, 0.0],
              [-81.216754184411656, 35.823336610611499, 0.0],
              [-81.216866384452516, 35.823130277329483, 0.0],
              [-81.216866184068266, 35.822878411327771, 0.0],
              [-81.216330784048964, 35.822077277731502, 0.0],
              [-81.215739917755414, 35.821711277605012, 0.0],
              [-81.215402117417369, 35.82136801078191, 0.0],
              [-81.21486778418236, 35.821368477829971, 0.0],
              [-81.214446317960395, 35.821597810922022, 0.0],
              [-81.213996184230169, 35.821437810913785, 0.0],
              [-81.213855384780842, 35.82118601070907, 0.0],
              [-81.213630517600706, 35.82123201107725, 0.0],
              [-81.213124784705769, 35.821644610688757, 0.0],
              [-81.212618917911755, 35.821919677682516, 0.0],
              [-81.212394317710718, 35.822263477671228, 0.0],
              [-81.211691784739287, 35.822699077309402, 0.0],
              [-81.211523383887936, 35.822974077881618, 0.0],
              [-81.21158018458965, 35.823455011521517, 0.0],
              [-81.21217198439993, 35.824553811102604, 0.0],
              [-81.212256584897005, 35.824805678003727, 0.0],
              [-81.212341117276495, 35.824851410884143, 0.0],
              [-81.212736117549184, 35.826087810690453, 0.0],
              [-81.212623917210436, 35.826339677369042, 0.0],
              [-81.212455184584485, 35.826339878176263, 0.0],
              [-81.212117383913323, 35.825996610593293, 0.0],
              [-81.211722984894863, 35.825378611354566, 0.0],
              [-81.211131784963186, 35.824760677789001, 0.0],
              [-81.210653384155862, 35.82446327759903, 0.0],
              [-81.210653117822702, 35.824096877378217, 0.0],
              [-81.21051218469718, 35.823890878185843, 0.0],
              [-81.21014638396781, 35.823662077640741, 0.0],
              [-81.208965318006506, 35.823685811344134, 0.0],
              [-81.208374984438223, 35.82389227788498, 0.0],
              [-81.20798171817826, 35.824213277973215, 0.0],
              [-81.207813384079756, 35.82451107809991, 0.0],
              [-81.20812351735745, 35.825289478071333, 0.0],
              [-81.209136717429473, 35.826090278093673, 0.0],
              [-81.210149583904339, 35.826662210595224, 0.0],
              [-81.210459318285118, 35.82695961123737, 0.0],
              [-81.210600317643753, 35.827280278120952, 0.0],
              [-81.210658384957298, 35.828974877605248, 0.0],
              [-81.210883584807277, 35.829226611201143, 0.0],
              [-81.211052384393795, 35.829295211216078, 0.0],
              [-81.211024384675724, 35.829478477374145, 0.0],
              [-81.210518383796256, 35.829593277585822, 0.0],
              [-81.210068917187527, 35.830051677670475, 0.0],
              [-81.210013517814886, 35.830784477346612, 0.0],
              [-81.210492117247654, 35.831333877234009, 0.0],
              [-81.211617784456479, 35.832043078159778, 0.0],
              [-81.211786917640467, 35.832340678189198, 0.0],
              [-81.211731118010974, 35.832707077580146, 0.0],
              [-81.211366184488568, 35.83327987753173, 0.0],
              [-81.211141317254715, 35.833348677761087, 0.0],
              [-81.210859918101946, 35.833257410692802, 0.0],
              [-81.210859383990041, 35.832822210831054, 0.0],
              [-81.210971584228545, 35.832570211424738, 0.0],
              [-81.210971184642958, 35.83227247812934, 0.0],
              [-81.210858583962505, 35.832135211353012, 0.0],
              [-81.2102395181536, 35.831792077458395, 0.0],
              [-81.209704717270441, 35.831311611189307, 0.0],
              [-81.209451117259391, 35.83087661111994, 0.0],
              [-81.20942198386372, 35.829960477571319, 0.0],
              [-81.20885951804506, 35.829823477544387, 0.0],
              [-81.208859184769693, 35.829640277526813, 0.0],
              [-81.209168384196744, 35.829456877390498, 0.0],
              [-81.209927384853145, 35.829273077816048, 0.0],
              [-81.209870583862596, 35.828769411191473, 0.0],
              [-81.209701518252245, 35.828357277417403, 0.0],
              [-81.20941998445862, 35.828036811005859, 0.0],
              [-81.20849171784667, 35.8278542779559, 0.0],
              [-81.208379184271649, 35.82776267747127, 0.0],
              [-81.208378517298428, 35.827052811229585, 0.0],
              [-81.20820951814342, 35.826869677474754, 0.0],
              [-81.207168917354338, 35.826756011021914, 0.0],
              [-81.206943317838565, 35.826114877685988, 0.0],
              [-81.206690117191641, 35.825977611282013, 0.0],
              [-81.206408917264156, 35.825932011337144, 0.0],
              [-81.206043384561298, 35.826046810752338, 0.0],
              [-81.205734317996203, 35.826298877283563, 0.0],
              [-81.205060917345534, 35.827765011238391, 0.0],
              [-81.20506198417587, 35.828818411097643, 0.0],
              [-81.205287384048248, 35.829345077434624, 0.0],
              [-81.205287917724291, 35.829803210849782, 0.0],
              [-81.205063183819547, 35.830123877836449, 0.0],
              [-81.204669583855434, 35.83021581072115, 0.0],
              [-81.204613118155095, 35.829986810950359, 0.0],
              [-81.204697517352514, 35.829895210716288, 0.0],
              [-81.204696918064684, 35.829322611128021, 0.0],
              [-81.204612517814382, 35.829254011075164, 0.0],
              [-81.204412317839925, 35.825933410783648, 0.0],
              [-81.204243384913767, 35.825773210945897, 0.0],
              [-81.204018717459917, 35.826093877348313, 0.0],
              [-81.203934717652089, 35.826506211421638, 0.0],
              [-81.203850517782385, 35.826552010605347, 0.0],
              [-81.203794584698059, 35.82696441122102, 0.0],
              [-81.203401717212216, 35.827720277361664, 0.0],
              [-81.203092518237369, 35.827812210848748, 0.0],
              [-81.203007984466439, 35.827674811465037, 0.0],
              [-81.203232384909086, 35.827056277328296, 0.0],
              [-81.203259784071051, 35.826437877356319, 0.0],
              [-81.203118718024157, 35.82584261064747, 0.0],
              [-81.202893384833345, 35.825613677637463, 0.0],
              [-81.202387317283424, 35.825568210691969, 0.0],
              [-81.202162384814145, 35.825682877893193, 0.0],
              [-81.202163118199678, 35.826438677507113, 0.0],
              [-81.201825717582736, 35.826461810622142, 0.0],
              [-81.201544117818813, 35.826095611541461, 0.0],
              [-81.200699917952946, 35.825592211379764, 0.0],
              [-81.199856317256035, 35.825501077645647, 0.0],
              [-81.199293985009916, 35.825638878105359, 0.0],
              [-81.19909738445034, 35.825913877865631, 0.0],
              [-81.198845317430198, 35.826784277258213, 0.0],
              [-81.198592184110922, 35.826876077794239, 0.0],
              [-81.19752291784792, 35.826304078035641, 0.0],
              [-81.197607117398306, 35.826006277843966, 0.0],
              [-81.198028384137402, 35.825502277342828, 0.0],
              [-81.198027918148455, 35.824929677284743, 0.0],
              [-81.197915184401424, 35.824769477509335, 0.0],
              [-81.197268117265821, 35.824380678003088, 0.0],
              [-81.197155517243161, 35.824197411334701, 0.0],
              [-81.197042384152027, 35.823602077451092, 0.0],
              [-81.196760984453135, 35.823304611305424, 0.0],
              [-81.195748384793774, 35.822892877418205, 0.0],
              [-81.194904584990496, 35.822733077659699, 0.0],
              [-81.19337022664557, 35.822847573860152, 0.0],
              [-81.193358117843502, 35.822848477535324, 0.0],
              [-81.193161184568027, 35.822779878171936, 0.0],
              [-81.193132917855621, 35.82257381134314, 0.0],
              [-81.193042583384326, 35.822436963470011, 0.0],
              [-81.192851183858892, 35.82214701155953, 0.0],
              [-81.192513517399519, 35.821635278030719, 0.0],
              [-81.192400984073871, 35.821475011434934, 0.0],
              [-81.191950317180414, 35.820673677277377, 0.0],
              [-81.192512184684517, 35.820078011032834, 0.0],
              [-81.192933317585428, 35.819321877765489, 0.0],
              [-81.192961117206167, 35.818841010911804, 0.0],
              [-81.19282018491792, 35.818497477270071, 0.0],
              [-81.192678718076195, 35.817512877610483, 0.0],
              [-81.19293158446186, 35.817466877735235, 0.0],
              [-81.193325718260994, 35.81778727802827, 0.0],
              [-81.19369131724811, 35.817947410988488, 0.0],
              [-81.193972384655609, 35.817878611054695, 0.0],
              [-81.194225317343921, 35.817649277771537, 0.0],
              [-81.194195984769237, 35.816458477883984, 0.0],
              [-81.194308317762449, 35.816160677623735, 0.0],
              [-81.194476917957672, 35.816114678185471, 0.0],
              [-81.194814984365351, 35.816916210883555, 0.0],
              [-81.194845317819272, 35.819229211554379, 0.0],
              [-81.194985984353877, 35.819435211414429, 0.0],
              [-81.195576784844263, 35.81968681147989, 0.0],
              [-81.195970384207811, 35.819686610620437, 0.0],
              [-81.196195184110962, 35.819480277794881, 0.0],
              [-81.196391584468813, 35.81904501101809, 0.0],
              [-81.196390784920936, 35.81810607739564, 0.0],
              [-81.196052917208348, 35.81757961142926, 0.0],
              [-81.196136917956281, 35.817236010840141, 0.0],
              [-81.196473984614556, 35.81702967761592, 0.0],
              [-81.196839584771212, 35.817006610636348, 0.0],
              [-81.19723371833129, 35.817372811520237, 0.0],
              [-81.197458917185003, 35.817739077749209, 0.0],
              [-81.197488117692771, 35.818907011121013, 0.0],
              [-81.197600584068553, 35.818929811076337, 0.0],
              [-81.197937718231117, 35.81865481110372, 0.0],
              [-81.198162917781218, 35.818837877581394, 0.0],
              [-81.198415784349876, 35.818723277711719, 0.0],
              [-81.198443584202295, 35.818402610615998, 0.0],
              [-81.198077584410953, 35.817944811098535, 0.0],
              [-81.198302517324109, 35.817830077826471, 0.0],
              [-81.200439317639066, 35.817668410587665, 0.0],
              [-81.200889117853208, 35.817553610913201, 0.0],
              [-81.201141917735129, 35.817278677266678, 0.0],
              [-81.201507384780527, 35.817232610795621, 0.0],
              [-81.20215411714041, 35.817323877617348, 0.0],
              [-81.202435317759594, 35.817232011438307, 0.0],
              [-81.202687918322781, 35.816888411335434, 0.0],
              [-81.202687518075322, 35.816384477517531, 0.0],
              [-81.202377384186775, 35.815674811564584, 0.0],
              [-81.201561383925565, 35.814965410804, 0.0],
              [-81.200577118189912, 35.814759877531181, 0.0],
              [-81.199536717721486, 35.814760610744855, 0.0],
              [-81.199508384959799, 35.814577278134053, 0.0],
              [-81.199901718019817, 35.814210677350388, 0.0],
              [-81.201644783942058, 35.814140810797745, 0.0],
              [-81.201813518223247, 35.81407201098483, 0.0],
              [-81.202852917912864, 35.813109477476971, 0.0],
              [-81.203162117786292, 35.813040477762854, 0.0],
              [-81.203274718083549, 35.813132077891382, 0.0],
              [-81.203246784682378, 35.813452677425985, 0.0],
              [-81.203050184974501, 35.813659010779681, 0.0],
              [-81.202853784424704, 35.814140011247609, 0.0],
              [-81.202825984875417, 35.814437677427684, 0.0],
              [-81.203079784170228, 35.815147611125241, 0.0],
              [-81.203614917537607, 35.815948811355234, 0.0],
              [-81.203615384614849, 35.816384011297181, 0.0],
              [-81.203474984527645, 35.816635877824609, 0.0],
              [-81.203475717938872, 35.817391678002892, 0.0],
              [-81.203110984874883, 35.818170610738676, 0.0],
              [-81.202576918302981, 35.818331211452545, 0.0],
              [-81.201396117243405, 35.818515211333676, 0.0],
              [-81.200890184566759, 35.818721677594453, 0.0],
              [-81.200693917961331, 35.819317277732004, 0.0],
              [-81.200216518072679, 35.81984421128378, 0.0],
              [-81.200188584261198, 35.820119078108021, 0.0],
              [-81.200357517810872, 35.820325077678504, 0.0],
              [-81.201538717815694, 35.820530477896469, 0.0],
              [-81.202129584764847, 35.821056811502118, 0.0],
              [-81.202439184233853, 35.821194011216171, 0.0],
              [-81.203029717270994, 35.821193677932818, 0.0],
              [-81.203282517384665, 35.821056077449668, 0.0],
              [-81.20331031765491, 35.820689611011225, 0.0],
              [-81.202831583787969, 35.819980077355048, 0.0],
              [-81.202775117770585, 35.819751011040715, 0.0],
              [-81.202915518159884, 35.819499011527576, 0.0],
              [-81.203364984483528, 35.819223877863003, 0.0],
              [-81.2039555179893, 35.819154677785086, 0.0],
              [-81.205024718201955, 35.819772411127289, 0.0],
              [-81.205530718100292, 35.819680478192609, 0.0],
              [-81.206120917742638, 35.81940521143715, 0.0],
              [-81.206738917227767, 35.818786477674024, 0.0],
              [-81.207215984719582, 35.817938810635049, 0.0],
              [-81.207243384018696, 35.817274610973897, 0.0],
              [-81.207496384019095, 35.817205678186447, 0.0],
              [-81.208031183840475, 35.817777877322122, 0.0],
              [-81.208453184080781, 35.818006610869105, 0.0],
              [-81.2089593175603, 35.817937611385034, 0.0],
              [-81.210139717871726, 35.817432878218938, 0.0],
              [-81.210364318148734, 35.817157810733889, 0.0],
              [-81.210363717908663, 35.816631077303853, 0.0],
              [-81.210194717669737, 35.816402210851265, 0.0],
              [-81.210194517655097, 35.816196077798814, 0.0],
              [-81.210560117491838, 35.816195877547663, 0.0],
              [-81.21078518469497, 35.816356010691464, 0.0],
              [-81.210897918244555, 35.816584877958221, 0.0],
              [-81.211122983942772, 35.816768011288822, 0.0],
              [-81.211432383837746, 35.816790677490687, 0.0],
              [-81.211713517444679, 35.816721811390074, 0.0],
              [-81.212190983969094, 35.816240477676814, 0.0],
              [-81.212218584748754, 35.815851210981215, 0.0],
              [-81.212105984197834, 35.815713810772884, 0.0],
              [-81.210896584095735, 35.815371210800812, 0.0],
              [-81.210446517405728, 35.815142477722276, 0.0],
              [-81.210446317496491, 35.814959277270262, 0.0],
              [-81.211008584272633, 35.814890210632761, 0.0],
              [-81.211374184359101, 35.814958610581897, 0.0],
              [-81.211458383769326, 35.814912810666186, 0.0],
              [-81.211458184945485, 35.814683810831795, 0.0],
              [-81.210979717782692, 35.814340610900352, 0.0],
              [-81.210725717336558, 35.813447477902137, 0.0],
              [-81.210894384310279, 35.813401611112006, 0.0],
              [-81.211316184539257, 35.813515811027202, 0.0],
              [-81.21131518391806, 35.812645478081429, 0.0],
              [-81.211511984091914, 35.81259961095175, 0.0],
              [-81.211765384165034, 35.812828411106359, 0.0],
              [-81.212075984117561, 35.81408781130353, 0.0],
              [-81.212216784157889, 35.814248077349966, 0.0],
              [-81.212779184865866, 35.814339211294943, 0.0],
              [-81.21303271807254, 35.81470547741948, 0.0],
              [-81.213314917284507, 35.815575477611667, 0.0],
              [-81.213539984133661, 35.815735677804625, 0.0],
              [-81.214242984656664, 35.81589547751414, 0.0],
              [-81.214692984185646, 35.815918010777665, 0.0],
              [-81.215731784457958, 35.814588878165971, 0.0],
              [-81.216124584023618, 35.813924477819995, 0.0],
              [-81.21668691819248, 35.813855277941222, 0.0],
              [-81.216911584939197, 35.813603277999647, 0.0],
              [-81.216967183854592, 35.813236810730217, 0.0],
              [-81.216235584164039, 35.812664810893843, 0.0],
              [-81.216235384490119, 35.812481611042301, 0.0],
              [-81.217275784863631, 35.812641077546459, 0.0],
              [-81.217416317530748, 35.81259521132565, 0.0],
              [-81.21750038483934, 35.812320277289459, 0.0],
              [-81.21643131763598, 35.811725677940245, 0.0],
              [-81.216431117520898, 35.811542478031015, 0.0],
              [-81.216599584576286, 35.811405010957564, 0.0],
              [-81.216599317137721, 35.811130078237269, 0.0],
              [-81.216373984826234, 35.81076381124015, 0.0],
              [-81.216345584885204, 35.810489011288936, 0.0],
              [-81.215867184759219, 35.810168811015551, 0.0],
              [-81.214882584259612, 35.809688610807875, 0.0],
              [-81.214685517792304, 35.809413877681031, 0.0],
              [-81.214657117297079, 35.809116277770215, 0.0],
              [-81.214769384772637, 35.809001677978507, 0.0],
              [-81.215106717751439, 35.808932677727967, 0.0],
              [-81.215500517384072, 35.809092678132039, 0.0],
              [-81.21707631715914, 35.810282411366927, 0.0],
              [-81.217977184064438, 35.811335077385749, 0.0],
              [-81.218595917774451, 35.811472077275411, 0.0],
              [-81.219214118024951, 35.811196677662714, 0.0],
              [-81.219607118031774, 35.810738477307225, 0.0],
              [-81.219718984547143, 35.810142877857622, 0.0],
              [-81.219521517314547, 35.80952481106771, 0.0],
              [-81.219267784796031, 35.809112677848852, 0.0],
              [-81.218451784964969, 35.808449211258328, 0.0],
              [-81.218451584503171, 35.808243077292438, 0.0],
              [-81.218873117809835, 35.80810527823337, 0.0],
              [-81.219153918189932, 35.807853077879194, 0.0],
              [-81.219575584350181, 35.80782987772497, 0.0],
              [-81.219632183884158, 35.808196410903577, 0.0],
              [-81.219519984872065, 35.808311011144582, 0.0],
              [-81.219520184712991, 35.808494211380243, 0.0],
              [-81.220589517253927, 35.809340677741631, 0.0],
              [-81.22101118484143, 35.809317477673126, 0.0],
              [-81.222415318016076, 35.80798787755964, 0.0],
              [-81.222808183880318, 35.807323478041901, 0.0],
              [-81.22269398482706, 35.805995277660877, 0.0],
              [-81.222496583799185, 35.805514611101565, 0.0],
              [-81.222496118157366, 35.805079410728098, 0.0],
              [-81.222017784197291, 35.804713410685125, 0.0],
              [-81.221933117887872, 35.804461611053895, 0.0],
              [-81.222101317306894, 35.804049077585645, 0.0],
              [-81.222691584151519, 35.804002811565859, 0.0],
              [-81.222888584227107, 35.804185877307511, 0.0],
              [-81.222945784613074, 35.804918677888438, 0.0],
              [-81.223058517784906, 35.805216277414857, 0.0],
              [-81.22345278411926, 35.805742811047935, 0.0],
              [-81.223481918149133, 35.806475611171926, 0.0],
              [-81.223622784033353, 35.806841878050136, 0.0],
              [-81.223960784382683, 35.807299678229654, 0.0],
              [-81.224298317510701, 35.807482610749304, 0.0],
              [-81.224888717438446, 35.807482077436084, 0.0],
              [-81.225338317740949, 35.807229811145717, 0.0],
              [-81.225619384935499, 35.807183811442812, 0.0],
              [-81.226097717736181, 35.807526811510037, 0.0],
              [-81.226688517634116, 35.807755411014199, 0.0],
              [-81.227138184056841, 35.80777787805809, 0.0],
              [-81.228486984901053, 35.807227077521432, 0.0],
              [-81.228796317561347, 35.807226878022, 0.0],
              [-81.228909184384534, 35.807616077991021, 0.0],
              [-81.229190917528598, 35.808051011363276, 0.0],
              [-81.229444184009736, 35.80823401117275, 0.0],
              [-81.23006291761466, 35.808393677570884, 0.0],
              [-81.230456517412605, 35.808416277450846, 0.0],
              [-81.230934383953553, 35.808301277305475, 0.0],
              [-81.231186984834991, 35.808003277833961, 0.0],
              [-81.231635717597683, 35.807201411352359, 0.0],
              [-81.231635184176511, 35.806812011241341, 0.0],
              [-81.231241117814378, 35.806423078042748, 0.0],
              [-81.230566118044692, 35.806240477923772, 0.0],
              [-81.22913231827647, 35.806127211238739, 0.0],
              [-81.228709918161911, 35.805669610918635, 0.0],
              [-81.228681384436626, 35.805349011509875, 0.0],
              [-81.228793184266806, 35.804868010646366, 0.0],
              [-81.22868031719679, 35.804547411584572, 0.0],
              [-81.228567718223914, 35.80445587774981, 0.0],
              [-81.228426184819114, 35.803746078007585, 0.0],
              [-81.228285384289663, 35.80354021079166, 0.0],
              [-81.228312717565231, 35.80296761132378, 0.0],
              [-81.228509517410473, 35.802990277850753, 0.0],
              [-81.228988184713714, 35.803470810662823, 0.0],
              [-81.229044717451828, 35.803745478198564, 0.0],
              [-81.229157584277687, 35.804043211232852, 0.0],
              [-81.229889984429462, 35.805164678068685, 0.0],
              [-81.230115117648339, 35.805370677301681, 0.0],
              [-81.230818184237137, 35.805576077720765, 0.0],
              [-81.231268383865043, 35.805850477357325, 0.0],
              [-81.231521384198373, 35.805804477589071, 0.0],
              [-81.231519384420409, 35.804270077932664, 0.0],
              [-81.231828184048325, 35.804017877946244, 0.0],
              [-81.232081184238638, 35.804017677552601, 0.0],
              [-81.231914117571165, 35.805140010612789, 0.0],
              [-81.232364917613154, 35.805826678088074, 0.0],
              [-81.232506318108463, 35.806467677253572, 0.0],
              [-81.232618917595047, 35.806582211434055, 0.0],
              [-81.232871917314085, 35.806581877336733, 0.0],
              [-81.23360138473862, 35.805527810665502, 0.0],
              [-81.233797718236218, 35.80511541140725, 0.0],
              [-81.234949917620426, 35.804816477368696, 0.0],
              [-81.235174584112912, 35.804633210625589, 0.0],
              [-81.235173783874188, 35.804060610978809, 0.0],
              [-81.23508918459342, 35.803946210718635, 0.0],
              [-81.235145117498746, 35.803694277324702, 0.0],
              [-81.235285517982163, 35.803602478132554, 0.0],
              [-81.23562298397141, 35.80364801134845, 0.0],
              [-81.235735717210019, 35.803785277863575, 0.0],
              [-81.235821118065033, 35.804563810753436, 0.0],
              [-81.235933784440633, 35.804815677737224, 0.0],
              [-81.23627158432771, 35.805090211553043, 0.0],
              [-81.236693384767122, 35.805158410626348, 0.0],
              [-81.238548318019937, 35.804813211185838, 0.0],
              [-81.238941584738953, 35.804583811493679, 0.0],
              [-81.239306117577627, 35.803942277978308, 0.0],
              [-81.239699318107071, 35.803667078089326, 0.0],
              [-81.240824117594613, 35.803849211227551, 0.0],
              [-81.240964784097827, 35.803940677565109, 0.0],
              [-81.242032917975337, 35.803870877876584, 0.0],
              [-81.242482918154167, 35.804007877561517, 0.0],
              [-81.24251118479836, 35.804076477827905, 0.0],
              [-81.242398984118083, 35.804214077372357, 0.0],
              [-81.242033584418436, 35.804306077569436, 0.0],
              [-81.240122117853488, 35.804445277974303, 0.0],
              [-81.239728917500415, 35.804628877624765, 0.0],
              [-81.239448717952328, 35.805270411375588, 0.0],
              [-81.238999384674699, 35.805660077829366, 0.0],
              [-81.237818984403859, 35.805936011567802, 0.0],
              [-81.235063984622613, 35.805984611265245, 0.0],
              [-81.234811184535616, 35.80612221114513, 0.0],
              [-81.23464291778869, 35.806488677406755, 0.0],
              [-81.234672317759816, 35.807381811422346, 0.0],
              [-81.234167184295288, 35.808023478242227, 0.0],
              [-81.233858184315196, 35.808230011041076, 0.0],
              [-81.232116117890058, 35.80898741094849, 0.0],
              [-81.231751118158371, 35.809399877684605, 0.0],
              [-81.231723783925517, 35.809949478152539, 0.0],
              [-81.231864584054264, 35.810086811158335, 0.0],
              [-81.232427117969863, 35.810269477828996, 0.0],
              [-81.235856584137352, 35.809899877476163, 0.0],
              [-81.236812717648931, 35.810013477499567, 0.0],
              [-81.237880717293521, 35.809829277918972, 0.0],
              [-81.238555717547712, 35.809966077953277, 0.0],
              [-81.238696384158374, 35.810103410665171, 0.0],
              [-81.238725117735385, 35.81044687798456, 0.0],
              [-81.238865917427475, 35.810538278153565, 0.0],
              [-81.239062517353361, 35.810446477393441, 0.0],
              [-81.239202917470834, 35.810263077669553, 0.0],
              [-81.239511784049029, 35.810102610737232, 0.0],
              [-81.239877517296378, 35.810170877501101, 0.0],
              [-81.240018184141405, 35.810308210694757, 0.0],
              [-81.240131518141752, 35.810789011415153, 0.0],
              [-81.240300317765175, 35.81094921088981, 0.0],
              [-81.241115717936083, 35.810948278031162, 0.0],
              [-81.242183784208805, 35.810764211306413, 0.0],
              [-81.242971317769459, 35.810923677593941, 0.0],
              [-81.243477317698535, 35.810854477491979, 0.0],
              [-81.243842518161088, 35.810648011141872, 0.0],
              [-81.244123183859116, 35.810350011214979, 0.0],
              [-81.244402783734316, 35.809456611070111, 0.0],
              [-81.244543317323689, 35.809387677581199, 0.0],
              [-81.244712118240017, 35.80947921146555, 0.0],
              [-81.244713917920123, 35.810601277990024, 0.0],
              [-81.244854783857761, 35.81078441067428, 0.0],
              [-81.245135984210663, 35.810875677881441, 0.0],
              [-81.245136383856263, 35.81110481117711, 0.0],
              [-81.244209518142668, 35.811678211053568, 0.0],
              [-81.244097184322115, 35.811861477647433, 0.0],
              [-81.244125717396869, 35.812090477507667, 0.0],
              [-81.244942317799755, 35.812891211263896, 0.0],
              [-81.245279917146775, 35.81298241104794, 0.0],
              [-81.246095117872187, 35.81291301135348, 0.0],
              [-81.246207717326172, 35.813004410901264, 0.0],
              [-81.246292584452632, 35.81337081089125, 0.0],
              [-81.247052517797144, 35.813873877390996, 0.0],
              [-81.247924384190796, 35.81403327759584, 0.0],
              [-81.248739518227381, 35.81389501060265, 0.0],
              [-81.249554117223042, 35.81341327819122, 0.0],
              [-81.249807117809226, 35.813367078012348, 0.0],
              [-81.25019891778463, 35.813208811105973, 0.0],
              [-81.250087317750825, 35.812636210855317, 0.0],
              [-81.25022818413693, 35.812453077681852, 0.0],
              [-81.25031311768835, 35.812109678123647, 0.0],
              [-81.250285918222886, 35.811514211311781, 0.0],
              [-81.249977518084378, 35.810941410595731, 0.0],
              [-81.249922317501529, 35.810277077608426, 0.0],
              [-81.249802117908771, 35.81022961060421, 0.0],
              [-81.248958384684599, 35.810047277322496, 0.0],
              [-81.248873384171731, 35.809566477847866, 0.0],
              [-81.248563717307405, 35.80929201065301, 0.0],
              [-81.248085183925184, 35.809040611478025, 0.0],
              [-81.247916184444023, 35.808834610595547, 0.0],
              [-81.247999983793235, 35.808536811041357, 0.0],
              [-81.248281183905192, 35.808513611485147, 0.0],
              [-81.249801117363987, 35.80956547749966, 0.0],
              [-81.250401118018857, 35.809773810807258, 0.0],
              [-81.251160117392814, 35.809797477782219, 0.0],
              [-81.251497584271604, 35.809660410710045, 0.0],
              [-81.251497984349811, 35.80943141120634, 0.0],
              [-81.251076984323845, 35.809018810661577, 0.0],
              [-81.251021517814053, 35.808514878071357, 0.0],
              [-81.251218784323356, 35.808217411021403, 0.0],
              [-81.251865583946568, 35.808034810687737, 0.0],
              [-81.253384984257139, 35.807234811248001, 0.0],
              [-81.253526317315078, 35.806776811526063, 0.0],
              [-81.254172984640689, 35.806777477460649, 0.0],
              [-81.256366717610803, 35.806161411314164, 0.0],
              [-81.256648184703394, 35.805978411049779, 0.0],
              [-81.256564117635463, 35.80572641132617, 0.0],
              [-81.256199184239435, 35.805405411513611, 0.0],
              [-81.256227517378136, 35.805245211232481, 0.0],
              [-81.256677384741138, 35.8051998113433, 0.0],
              [-81.257716984419659, 35.805567210605666, 0.0],
              [-81.260247718197448, 35.80520327820954, 0.0],
              [-81.260922717087325, 35.805043611006802, 0.0],
              [-81.260922983861306, 35.804814611122772, 0.0],
              [-81.260361318181339, 35.804424610843704, 0.0],
              [-81.259658783759491, 35.804172010754549, 0.0],
              [-81.259630917089765, 35.804080411577068, 0.0],
              [-81.259969117384841, 35.803439478052304, 0.0],
              [-81.26030658447246, 35.803371078109144, 0.0],
              [-81.260305784840185, 35.803920811188149, 0.0],
              [-81.261092717628216, 35.804127611205452, 0.0],
              [-81.261541984392494, 35.804448611149034, 0.0],
              [-81.262104184561991, 35.804540810886486, 0.0],
              [-81.263201317064471, 35.803992211446868, 0.0],
              [-81.263454783879141, 35.803625877522698, 0.0],
              [-81.263651784378027, 35.803511610607828, 0.0],
              [-81.264635984508658, 35.80332927794317, 0.0],
              [-81.264861184512739, 35.803146277663132, 0.0],
              [-81.264917917880311, 35.802734210613998, 0.0],
              [-81.264749784503977, 35.802367611409402, 0.0],
              [-81.264722184367912, 35.801978210733871, 0.0],
              [-81.264806784660209, 35.801772210828766, 0.0],
              [-81.265340984812823, 35.801704011328042, 0.0],
              [-81.265425317676986, 35.801772811406522, 0.0],
              [-81.265424717645971, 35.802162078230872, 0.0],
              [-81.265508984342247, 35.802207877325465, 0.0],
              [-81.265592184291648, 35.802986677694413, 0.0],
              [-81.265676518066215, 35.803055411472002, 0.0],
              [-81.266041917471384, 35.803055811123158, 0.0],
              [-81.267054318086636, 35.802873477873661, 0.0],
              [-81.267898117453043, 35.802576477298203, 0.0],
              [-81.26823558408914, 35.802347877728856, 0.0],
              [-81.268349117925084, 35.801569210780706, 0.0],
              [-81.268265117630406, 35.801340078000138, 0.0],
              [-81.268265983940609, 35.800653077967752, 0.0],
              [-81.268491184208983, 35.800470077426205, 0.0],
              [-81.269559984468813, 35.800081611231398, 0.0],
              [-81.271134184124932, 35.800106010607763, 0.0],
              [-81.271471584789225, 35.799991810900629, 0.0],
              [-81.27172491759319, 35.799762878128895, 0.0],
              [-81.271725384135834, 35.799442278115279, 0.0],
              [-81.271444717176664, 35.799029877799299, 0.0],
              [-81.271423583755904, 35.798749211055416, 0.0],
              [-81.271417184573764, 35.798663477596399, 0.0],
              [-81.27164198395198, 35.798686478036174, 0.0],
              [-81.272316184669634, 35.799053477289497, 0.0],
              [-81.272849984325063, 35.799214277435127, 0.0],
              [-81.273355984587212, 35.799237611273043, 0.0],
              [-81.27403111759935, 35.798963410634201, 0.0],
              [-81.274931784478355, 35.798048011087339, 0.0],
              [-81.275606717853975, 35.797750810702809, 0.0],
              [-81.276056984026894, 35.797384810903317, 0.0],
              [-81.276197917958896, 35.797087210882452, 0.0],
              [-81.276113918227736, 35.796789411553497, 0.0],
              [-81.275917184779203, 35.796766277962895, 0.0],
              [-81.275691984457609, 35.796972211340901, 0.0],
              [-81.27535458419338, 35.797063611375656, 0.0],
              [-81.275129784333203, 35.796994677488783, 0.0],
              [-81.275017584229076, 35.796811410873374, 0.0],
              [-81.275186983917948, 35.796284811011951, 0.0],
              [-81.275468718163793, 35.79575827750967, 0.0],
              [-81.275637384374662, 35.795735610862472, 0.0],
              [-81.275890117576012, 35.795964677621996, 0.0],
              [-81.276536184028998, 35.79624007758305, 0.0],
              [-81.276873583995439, 35.796240277678336, 0.0],
              [-81.27791431792501, 35.795737410869499, 0.0],
              [-81.278420317755248, 35.795714877645835, 0.0],
              [-81.278785584436449, 35.79580681096455, 0.0],
              [-81.278982184817693, 35.795967211078583, 0.0],
              [-81.278245717517933, 35.796046611061648, 0.0],
              [-81.278244717916337, 35.796046811310156, 0.0],
              [-81.278138784855756, 35.796058211446727, 0.0],
              [-81.278134917679651, 35.796066077813492, 0.0],
              [-81.278082384741211, 35.796172610889151, 0.0],
              [-81.278616184490147, 35.796447811028884, 0.0],
              [-81.278418917469295, 35.796814211450062, 0.0],
              [-81.277040317178972, 35.797866477430453, 0.0],
              [-81.276646117239508, 35.798461611490765, 0.0],
              [-81.276308517452492, 35.798667477386502, 0.0],
              [-81.275942584601012, 35.799056411390282, 0.0],
              [-81.275323517306106, 35.799628611540037, 0.0],
              [-81.273832783766821, 35.80017687806739, 0.0],
              [-81.272313917965178, 35.800977211508524, 0.0],
              [-81.271779517405918, 35.801091211141845, 0.0],
              [-81.270542783956401, 35.800815277360599, 0.0],
              [-81.270149317896269, 35.800792077676782, 0.0],
              [-81.269952384546997, 35.800929410701215, 0.0],
              [-81.269980117692427, 35.80125001093446, 0.0],
              [-81.270625783868013, 35.801937611271022, 0.0],
              [-81.270822117888102, 35.802327078108682, 0.0],
              [-81.271046784823056, 35.802533411018686, 0.0],
              [-81.272170917051739, 35.802809211038223, 0.0],
              [-81.27447598488709, 35.802879811543264, 0.0],
              [-81.274953784541282, 35.802971810804763, 0.0],
              [-81.275459183714204, 35.803430211019084, 0.0],
              [-81.27621798436202, 35.803705677377231, 0.0],
              [-81.277763984076373, 35.803890211546033, 0.0],
              [-81.278242117998516, 35.803753077481574, 0.0],
              [-81.278579717666005, 35.803455677565424, 0.0],
              [-81.280491784520777, 35.803228211364399, 0.0],
              [-81.280519717656446, 35.803342677956138, 0.0],
              [-81.280125784431007, 35.803708811273644, 0.0],
              [-81.28009738369046, 35.803937810908245, 0.0],
              [-81.282402717447454, 35.803848011208807, 0.0],
              [-81.282880983852806, 35.803527811306424, 0.0],
              [-81.283528718057497, 35.802566411075347, 0.0],
              [-81.283669717395597, 35.802085611185852, 0.0],
              [-81.283585517365282, 35.801971010830314, 0.0],
              [-81.282882918164901, 35.801810211300655, 0.0],
              [-81.282854983909246, 35.801672810991263, 0.0],
              [-81.282967584014756, 35.801535410943394, 0.0],
              [-81.285414318153542, 35.800712677805528, 0.0],
              [-81.286370583728797, 35.800209611138577, 0.0],
              [-81.286708317833771, 35.799935010654821, 0.0],
              [-81.286708917535464, 35.799385411266535, 0.0],
              [-81.286878183972519, 35.798767210897651, 0.0],
              [-81.28710318429718, 35.798698678097367, 0.0],
              [-81.287468117092772, 35.799065277645965, 0.0],
              [-81.288058383995164, 35.799134477645666, 0.0],
              [-81.288423983730567, 35.798974410620076, 0.0],
              [-81.288480784180692, 35.798516410686787, 0.0],
              [-81.288593317869157, 35.798379077325158, 0.0],
              [-81.289043183749655, 35.798310811056325, 0.0],
              [-81.289296117124593, 35.798379611227837, 0.0],
              [-81.289998917022828, 35.798380077915354, 0.0],
              [-81.291376784388291, 35.797968677739121, 0.0],
              [-81.292473117554124, 35.79792367812616, 0.0],
              [-81.293653917131522, 35.7976954781549, 0.0],
              [-81.293963317047385, 35.797558277341331, 0.0],
              [-81.29399171779832, 35.79728347770169, 0.0],
              [-81.293683117524523, 35.796687878223189, 0.0],
              [-81.293514584722089, 35.796527410995729, 0.0],
              [-81.293514917145671, 35.796252610924228, 0.0],
              [-81.293627383685489, 35.796138211083125, 0.0],
              [-81.293880517027802, 35.796069677509742, 0.0],
              [-81.294077117274981, 35.796161477963594, 0.0],
              [-81.294104984684836, 35.796436277913934, 0.0],
              [-81.294357517267656, 35.796894411060805, 0.0],
              [-81.294638517431196, 35.797123611091351, 0.0],
              [-81.295144317144036, 35.797284211129515, 0.0],
              [-81.295537784064607, 35.797307410840126, 0.0],
              [-81.296325117417481, 35.797101811016567, 0.0],
              [-81.297786717897296, 35.797102811362862, 0.0],
              [-81.299023783985447, 35.796943278247511, 0.0],
              [-81.299389384384639, 35.796806077356607, 0.0],
              [-81.299698917570709, 35.796485610751517, 0.0],
              [-81.299755317681687, 35.796187877309549, 0.0],
              [-81.29936231739768, 35.79575247782023, 0.0],
              [-81.299362518123345, 35.795431877274034, 0.0],
              [-81.299784184352319, 35.795386410786065, 0.0],
              [-81.300486918143079, 35.795455477599276, 0.0],
              [-81.300683517954212, 35.795547211291023, 0.0],
              [-81.300739384269662, 35.796005277577358, 0.0],
              [-81.300935917631904, 35.796280277405799, 0.0],
              [-81.301329183850314, 35.796463677678794, 0.0],
              [-81.301666584323669, 35.796486811523963, 0.0],
              [-81.302032183948469, 35.79639541100768, 0.0],
              [-81.304338316996578, 35.795183011591106, 0.0],
              [-81.305069583660966, 35.794633877725715, 0.0],
              [-81.305322917904576, 35.794290411011389, 0.0],
              [-81.305351383643682, 35.793717877294135, 0.0],
              [-81.304958184434426, 35.793442877549957, 0.0],
              [-81.304958317341004, 35.793213810769494, 0.0],
              [-81.305464517743061, 35.792916277295511, 0.0],
              [-81.305408517604349, 35.792618611226281, 0.0],
              [-81.304482384607226, 35.79106081130157, 0.0],
              [-81.30448298450419, 35.790305010736972, 0.0],
              [-81.304539318102613, 35.790190611171198, 0.0],
              [-81.304848517436213, 35.790190810956737, 0.0],
              [-81.305045183721035, 35.790305411535641, 0.0],
              [-81.305016317291461, 35.791175610769258, 0.0],
              [-81.305409184804802, 35.791771211278494, 0.0],
              [-81.306139317876784, 35.792619077588299, 0.0],
              [-81.306335717040369, 35.793077077941682, 0.0],
              [-81.306616518066178, 35.793489478059278, 0.0],
              [-81.307206784233358, 35.793512677778168, 0.0],
              [-81.307319318153006, 35.79360441125943, 0.0],
              [-81.307318984197423, 35.793879211538389, 0.0],
              [-81.307009383966516, 35.794291411572026, 0.0],
              [-81.306981117466108, 35.794634877746816, 0.0],
              [-81.307093117956228, 35.795001278211934, 0.0],
              [-81.306868183639907, 35.795024211442282, 0.0],
              [-81.306699717155979, 35.794932477878596, 0.0],
              [-81.306362384843069, 35.794909277825724, 0.0],
              [-81.305996784646155, 35.795206877696899, 0.0],
              [-81.305040517781435, 35.795687210759702, 0.0],
              [-81.304871784041453, 35.795893277761543, 0.0],
              [-81.304871584602438, 35.796099411263093, 0.0],
              [-81.305545917441009, 35.796420411522092, 0.0],
              [-81.305517584666717, 35.796763878236973, 0.0],
              [-81.304308784513893, 35.796786078056229, 0.0],
              [-81.304280383804695, 35.797060877849965, 0.0],
              [-81.304195984179316, 35.797129477346488, 0.0],
              [-81.303436983766588, 35.797174877414086, 0.0],
              [-81.303211984664188, 35.797266277353579, 0.0],
              [-81.30298698392653, 35.797518211138147, 0.0],
              [-81.302958517927095, 35.797884610783385, 0.0],
              [-81.302789584515239, 35.79811361066804, 0.0],
              [-81.302339583721292, 35.798433878203426, 0.0],
              [-81.302254983810172, 35.7986628109403, 0.0],
              [-81.302648517843011, 35.798708877457912, 0.0],
              [-81.30416678389652, 35.798457811165356, 0.0],
              [-81.304841384529951, 35.798504078155297, 0.0],
              [-81.30495358440848, 35.798687278069053, 0.0],
              [-81.304869184730919, 35.798801677521581, 0.0],
              [-81.304053917183651, 35.798892877504649, 0.0],
              [-81.303575784551583, 35.799144611257759, 0.0],
              [-81.302759917480202, 35.799899811035985, 0.0],
              [-81.302731584450925, 35.80012881125446, 0.0],
              [-81.302899983991054, 35.800380811308749, 0.0],
              [-81.303574317063564, 35.800839277295331, 0.0],
              [-81.304473584070337, 35.801114611043161, 0.0],
              [-81.305625918108504, 35.801664878123006, 0.0],
              [-81.306131784519522, 35.801688077853228, 0.0],
              [-81.306834717026163, 35.801482277552182, 0.0],
              [-81.307200117129895, 35.801711611480208, 0.0],
              [-81.307621717096183, 35.801734610856883, 0.0],
              [-81.308493784389981, 35.801002411275263, 0.0],
              [-81.309787917505417, 35.799720478036654, 0.0],
              [-81.310575917984622, 35.79866747778226, 0.0],
              [-81.310548184250962, 35.798186611500441, 0.0],
              [-81.310239117889409, 35.797911477374647, 0.0],
              [-81.310323517641351, 35.797820011311508, 0.0],
              [-81.310548383938382, 35.797774277923445, 0.0],
              [-81.311532717263148, 35.797408411093855, 0.0],
              [-81.312151517705317, 35.796973610946466, 0.0],
              [-81.312882917694409, 35.796149477305285, 0.0],
              [-81.313473317062844, 35.795966678176896, 0.0],
              [-81.313473384763626, 35.795806277352959, 0.0],
              [-81.313164318128287, 35.795668810953259, 0.0],
              [-81.312742583626402, 35.79576021067372, 0.0],
              [-81.311533917968902, 35.795759477494457, 0.0],
              [-81.311505917931413, 35.795553410986926, 0.0],
              [-81.312433784605005, 35.79532487763138, 0.0],
              [-81.312686916982912, 35.79500427808842, 0.0],
              [-81.312883784191314, 35.794889877811073, 0.0],
              [-81.313614718009532, 35.794867411155245, 0.0],
              [-81.315132917011226, 35.794433011234794, 0.0],
              [-81.315470383878136, 35.794158411232502, 0.0],
              [-81.315526783998024, 35.793814877286479, 0.0],
              [-81.315836384295991, 35.793425677899762, 0.0],
              [-81.316229984158525, 35.793311410770357, 0.0],
              [-81.316398584227926, 35.7934260109229, 0.0],
              [-81.316538917535524, 35.793678010837588, 0.0],
              [-81.316791717255526, 35.793838411508091, 0.0],
              [-81.317072984769283, 35.793815610940044, 0.0],
              [-81.317410518015976, 35.793495211216374, 0.0],
              [-81.317438784549651, 35.793060077969272, 0.0],
              [-81.317270383754845, 35.792739411005115, 0.0],
              [-81.317439184095747, 35.792556210701157, 0.0],
              [-81.317832917906898, 35.792419078226708, 0.0],
              [-81.318057784282303, 35.792235810788263, 0.0],
              [-81.318170383760275, 35.791983877918227, 0.0],
              [-81.318395384473533, 35.791846677397167, 0.0],
              [-81.319491784591065, 35.79166401130275, 0.0],
              [-81.319716583677732, 35.791755677975566, 0.0],
              [-81.319716518138677, 35.791870211317281, 0.0],
              [-81.318675916984375, 35.792808811449667, 0.0],
              [-81.318506983668257, 35.793083477446551, 0.0],
              [-81.31715698475476, 35.79429661074348, 0.0],
              [-81.317016117354513, 35.794640011474328, 0.0],
              [-81.317128584802774, 35.794731678163465, 0.0],
              [-81.317746984272247, 35.794732010738564, 0.0],
              [-81.322469784486302, 35.794138678078099, 0.0],
              [-81.32261031768337, 35.794207478156011, 0.0],
              [-81.322638184275306, 35.794459411192307, 0.0],
              [-81.323087784182846, 35.794665678041461, 0.0],
              [-81.323902917194829, 35.794711811563054, 0.0],
              [-81.3240715837632, 35.794620278174619, 0.0],
              [-81.324268784116498, 35.793933411234889, 0.0],
              [-81.324493784259118, 35.793841810827381, 0.0],
              [-81.324634184024035, 35.793933477515047, 0.0],
              [-81.324746384566296, 35.794437277755492, 0.0],
              [-81.324858784590589, 35.794506077332464, 0.0],
              [-81.32862538410356, 35.794690678039238, 0.0],
              [-81.328878517622726, 35.794599211594871, 0.0],
              [-81.329019184041996, 35.794416077879987, 0.0],
              [-81.329019517565399, 35.793751877549127, 0.0],
              [-81.328766784202756, 35.793041878184496, 0.0],
              [-81.328738784137684, 35.792721277399004, 0.0],
              [-81.328851383754994, 35.79256101084065, 0.0],
              [-81.329160584103903, 35.792561078038467, 0.0],
              [-81.329216718025023, 35.792675677746125, 0.0],
              [-81.329469717672978, 35.792721611168048, 0.0],
              [-81.329638383761818, 35.792538477900138, 0.0],
              [-81.329750783994243, 35.792630011164, 0.0],
              [-81.329890784070045, 35.793912611139682, 0.0],
              [-81.330171717613254, 35.794164610677612, 0.0],
              [-81.330846318022481, 35.794256410908574, 0.0],
              [-81.331717716961847, 35.79416507761556, 0.0],
              [-81.332308117575352, 35.794005078056593, 0.0],
              [-81.332617518008675, 35.793638678126285, 0.0],
              [-81.332673783741626, 35.7934326111532, 0.0],
              [-81.332196184042246, 35.793134811223865, 0.0],
              [-81.332140117339847, 35.792928677784445, 0.0],
              [-81.33244931691614, 35.792768477362621, 0.0],
              [-81.332814917846108, 35.792402078123388, 0.0],
              [-81.332899384477926, 35.792058677881059, 0.0],
              [-81.332899784375456, 35.791188277619362, 0.0],
              [-81.332310184220916, 35.789699611471463, 0.0],
              [-81.332254917683954, 35.788142277899858, 0.0],
              [-81.332170717333739, 35.787844477965344, 0.0],
              [-81.33188991757217, 35.787455211457036, 0.0],
              [-81.33186191781553, 35.787157410939358, 0.0],
              [-81.331946317147313, 35.787042877922701, 0.0],
              [-81.33256471742736, 35.786974411423799, 0.0],
              [-81.333238917606664, 35.787615877544305, 0.0],
              [-81.333266784781742, 35.787936477729403, 0.0],
              [-81.333182383845838, 35.788119678149044, 0.0],
              [-81.333182117668272, 35.78878381100936, 0.0],
              [-81.333406584504473, 35.789448077436894, 0.0],
              [-81.333575117208156, 35.789700011152902, 0.0],
              [-81.333884317113032, 35.78983747799392, 0.0],
              [-81.334109116996203, 35.789700211125947, 0.0],
              [-81.334362117294603, 35.789677411597523, 0.0],
              [-81.334446317879113, 35.789791877797249, 0.0],
              [-81.334277717593039, 35.790181210667875, 0.0],
              [-81.334249317460745, 35.790524677576158, 0.0],
              [-81.333827317663662, 35.791440478120641, 0.0],
              [-81.333770717933191, 35.792242211268771, 0.0],
              [-81.33402338391295, 35.792769011600306, 0.0],
              [-81.334079317922672, 35.793410277889322, 0.0],
              [-81.333600917554477, 35.794371877488317, 0.0],
              [-81.333685117054273, 35.794692477326087, 0.0],
              [-81.33396611764401, 35.794875877677768, 0.0],
              [-81.334528184526889, 35.794967611237112, 0.0],
              [-81.335624517479317, 35.794990877602814, 0.0],
              [-81.336186784634691, 35.794876611476759, 0.0],
              [-81.33632738399271, 35.794762210698288, 0.0],
              [-81.33638378415273, 35.794258411030391, 0.0],
              [-81.336243517565265, 35.793708677527164, 0.0],
              [-81.336103117919066, 35.793502477678324, 0.0],
              [-81.336103317761214, 35.793136078055475, 0.0],
              [-81.336271983671338, 35.793090411260934, 0.0],
              [-81.336468717500935, 35.793205011018927, 0.0],
              [-81.337086584624743, 35.794189877864632, 0.0],
              [-81.337255184701633, 35.794304411240802, 0.0],
              [-81.337564317464953, 35.794350278239477, 0.0],
              [-81.33829538387468, 35.794052877664036, 0.0],
              [-81.338745183709818, 35.793640678254675, 0.0],
              [-81.33877358411975, 35.793068078089149, 0.0],
              [-81.339110917484376, 35.792999477913561, 0.0],
              [-81.339251384623907, 35.793159878095501, 0.0],
              [-81.339560584074533, 35.793297277538443, 0.0],
              [-81.340488383649415, 35.792679277819857, 0.0],
              [-81.340881984359399, 35.792610678009297, 0.0],
              [-81.340881917861537, 35.792816810989919, 0.0],
              [-81.340319584306243, 35.793435011217603, 0.0],
              [-81.339982117842311, 35.793686877482024, 0.0],
              [-81.339700917669219, 35.794053211008631, 0.0],
              [-81.339728784263471, 35.794396811206354, 0.0],
              [-81.340037983935289, 35.79439687759028, 0.0],
              [-81.341528117623895, 35.793778810920337, 0.0],
              [-81.342287317207962, 35.79334401149373, 0.0],
              [-81.343777317973903, 35.792313811140701, 0.0],
              [-81.344171118068402, 35.791901677470911, 0.0],
              [-81.344396184465097, 35.791237611380943, 0.0],
              [-81.345127117830444, 35.79110041120515, 0.0],
              [-81.345379984749499, 35.790917278211658, 0.0],
              [-81.345773917047666, 35.790253077817148, 0.0],
              [-81.346111583932526, 35.788673011453731, 0.0],
              [-81.34628031704851, 35.78839821098034, 0.0],
              [-81.346308583817432, 35.787917277984739, 0.0],
              [-81.346421117732305, 35.787619677795, 0.0],
              [-81.346562116997916, 35.7864516779849, 0.0],
              [-81.347208717803738, 35.785833411543734, 0.0],
              [-81.34748998457998, 35.785146477428782, 0.0],
              [-81.348164784177129, 35.784390877870059, 0.0],
              [-81.349204917687103, 35.783772810956052, 0.0],
              [-81.349233117698589, 35.783475077525395, 0.0],
              [-81.348980184333442, 35.783383411436283, 0.0],
              [-81.348024584185424, 35.78349767747855, 0.0],
              [-81.347968383912928, 35.783337411060693, 0.0],
              [-81.348052784632259, 35.783222878041698, 0.0],
              [-81.349036517439714, 35.782971211081104, 0.0],
              [-81.34962691705401, 35.782650610702674, 0.0],
              [-81.350526184416623, 35.782696610826839, 0.0],
              [-81.351032184504504, 35.782467677621462, 0.0],
              [-81.351650717244254, 35.781872411035131, 0.0],
              [-81.351734984730953, 35.781597611291538, 0.0],
              [-81.350920317122601, 35.780887477522235, 0.0],
              [-81.35089211803782, 35.780727211526667, 0.0],
              [-81.351173184688449, 35.78068141111261, 0.0],
              [-81.352128717899063, 35.780979277436259, 0.0],
              [-81.352353584629057, 35.780979410661303, 0.0],
              [-81.35271891803086, 35.780727611404444, 0.0],
              [-81.353534117131375, 35.780384210670626, 0.0],
              [-81.35375898425886, 35.780178077833511, 0.0],
              [-81.353815383705168, 35.779239078020673, 0.0],
              [-81.353478317358451, 35.778849678144866, 0.0],
              [-81.352185584589563, 35.778116611558886, 0.0],
              [-81.351370717904942, 35.777910277648743, 0.0],
              [-81.350977184781314, 35.777979011581785, 0.0],
              [-81.349459516944279, 35.778436811355093, 0.0],
              [-81.34895358428372, 35.778482477895722, 0.0],
              [-81.34870058452141, 35.778367877963248, 0.0],
              [-81.348700718016005, 35.778230411245538, 0.0],
              [-81.349347184637807, 35.778001478156888, 0.0],
              [-81.34990931776953, 35.777566610658809, 0.0],
              [-81.351089717738972, 35.777543811027613, 0.0],
              [-81.351483183908613, 35.777429410806761, 0.0],
              [-81.351792383611269, 35.777200477762584, 0.0],
              [-81.35193298391826, 35.776857010941605, 0.0],
              [-81.351483584009983, 35.77594087773118, 0.0],
              [-81.351118384000458, 35.775666011333371, 0.0],
              [-81.351287517437413, 35.773673611500193, 0.0],
              [-81.351006583608495, 35.773467411145397, 0.0],
              [-81.350753717195133, 35.773444410854594, 0.0],
              [-81.347746384277912, 35.774176678026102, 0.0],
              [-81.347690317069819, 35.773970611138061, 0.0],
              [-81.347887117694214, 35.77381027748347, 0.0],
              [-81.348730184316992, 35.773558477926819, 0.0],
              [-81.349770183856492, 35.773032011562293, 0.0],
              [-81.350613183577067, 35.772780210785122, 0.0],
              [-81.350922384169863, 35.772597077756778, 0.0],
              [-81.350922584777379, 35.77191007805893, 0.0],
              [-81.350641784625026, 35.771474877960124, 0.0],
              [-81.350445183639465, 35.771360410827612, 0.0],
              [-81.349686384105922, 35.771451811304239, 0.0],
              [-81.347241317777232, 35.77220701136897, 0.0],
              [-81.347185184261136, 35.772023811113705, 0.0],
              [-81.348028383709192, 35.771359811549345, 0.0],
              [-81.34830951720285, 35.770970611141372, 0.0],
              [-81.348337783869937, 35.770672877339109, 0.0],
              [-81.347860183605945, 35.770443677900538, 0.0],
              [-81.347832184521764, 35.769962810786929, 0.0],
              [-81.347466984383061, 35.769619277404082, 0.0],
              [-81.347129984350957, 35.769000677975185, 0.0],
              [-81.346849116955028, 35.768748677357642, 0.0],
              [-81.346849184716802, 35.768451011576346, 0.0],
              [-81.347102117384736, 35.768382411569249, 0.0],
              [-81.347439183981123, 35.768542810884774, 0.0],
              [-81.349546117830215, 35.770650211186201, 0.0],
              [-81.350276717643254, 35.770856477437547, 0.0],
              [-81.350670184089509, 35.770558811005642, 0.0],
              [-81.35145738356718, 35.769230677851958, 0.0],
              [-81.351963583648143, 35.767810877457435, 0.0],
              [-81.352469384086191, 35.767765211330783, 0.0],
              [-81.352469517770004, 35.767513278200553, 0.0],
              [-81.351991917994027, 35.767123810751777, 0.0],
              [-81.351710983928385, 35.766848877642857, 0.0],
              [-81.351710983737547, 35.766711611224402, 0.0],
              [-81.351879717052967, 35.766620010813639, 0.0],
              [-81.352272984303042, 35.766711677354515, 0.0],
              [-81.353031516973985, 35.767444677651895, 0.0],
              [-81.353565383570853, 35.767559277661853, 0.0],
              [-81.353902583735902, 35.76746767774592, 0.0],
              [-81.354464583573474, 35.767170077620563, 0.0],
              [-81.356459984767795, 35.765727611396301, 0.0],
              [-81.356488184336442, 35.765452810720461, 0.0],
              [-81.356235384016358, 35.765315277967709, 0.0],
              [-81.355560983831055, 35.76522361076966, 0.0],
              [-81.354914718046103, 35.765040278123919, 0.0],
              [-81.35474618401976, 35.76488001131456, 0.0],
              [-81.354746317123272, 35.764490677990821, 0.0],
              [-81.355167784351551, 35.764444877422477, 0.0],
              [-81.355926384306812, 35.764674010884185, 0.0],
              [-81.357752784592947, 35.764720077425153, 0.0],
              [-81.358427184680508, 35.7645370115182, 0.0],
              [-81.358764384460514, 35.764239278017804, 0.0],
              [-81.359073717053107, 35.763666810698133, 0.0],
              [-81.359916784125488, 35.76259047745026, 0.0],
              [-81.359916917797037, 35.76222401112458, 0.0],
              [-81.359523583720843, 35.76181181122891, 0.0],
              [-81.35966411808981, 35.761720211098137, 0.0],
              [-81.359692184361919, 35.761559877899295, 0.0],
              [-81.359523717372412, 35.761468277758887, 0.0],
              [-81.358540184706101, 35.761743011274127, 0.0],
              [-81.358567116895955, 35.761611678041092, 0.0],
              [-81.358568317719531, 35.761605610978776, 0.0],
              [-81.358764984146873, 35.761468211378066, 0.0],
              [-81.358877517048143, 35.761262077536728, 0.0],
              [-81.358764984331216, 35.761010211339226, 0.0],
              [-81.3588493174977, 35.760849877764947, 0.0],
              [-81.359355117092093, 35.760804077445648, 0.0],
              [-81.360169984180644, 35.760872878017516, 0.0],
              [-81.360282383996335, 35.760964610833362, 0.0],
              [-81.360366517691688, 35.761651610946366, 0.0],
              [-81.360984584073378, 35.761995211229255, 0.0],
              [-81.361068983957253, 35.762132610991344, 0.0],
              [-81.36104071699566, 35.762521877922268, 0.0],
              [-81.360169517919928, 35.763346277628351, 0.0],
              [-81.359944717291683, 35.763918810961101, 0.0],
              [-81.359551117770764, 35.764422677964944, 0.0],
              [-81.359129517674447, 35.765201210934684, 0.0],
              [-81.359129317176851, 35.766002810770935, 0.0],
              [-81.360225184592181, 35.766277678177879, 0.0],
              [-81.360927717864371, 35.766323611176368, 0.0],
              [-81.362107783564767, 35.766117610890348, 0.0],
              [-81.364215317630084, 35.766804877874002, 0.0],
              [-81.366350916933982, 35.766805078191283, 0.0],
              [-81.3673063176076, 35.766599011635982, 0.0],
              [-81.368570783971251, 35.765911878283625, 0.0],
              [-81.369217117832093, 35.765820411347043, 0.0],
              [-81.369779117625896, 35.765614277439191, 0.0],
              [-81.370453517747379, 35.765064611213319, 0.0],
              [-81.370734584338692, 35.764263078006231, 0.0],
              [-81.371998984289135, 35.763232610706709, 0.0],
              [-81.373572517325499, 35.761560677585599, 0.0],
              [-81.37480878375078, 35.759179010709708, 0.0],
              [-81.375314518010271, 35.758171277429447, 0.0],
              [-81.375342517574893, 35.756957611450389, 0.0],
              [-81.375623516974599, 35.756888877902313, 0.0],
              [-81.375988718020523, 35.757026211392535, 0.0],
              [-81.376269717215393, 35.756980477476198, 0.0],
              [-81.376438317469606, 35.756751478066988, 0.0],
              [-81.376466317759835, 35.756156011507557, 0.0],
              [-81.376662983877949, 35.755858277543943, 0.0],
              [-81.378376784132954, 35.755491810798461, 0.0],
              [-81.378938717849095, 35.75549167769632, 0.0],
              [-81.37905118391987, 35.755674877886499, 0.0],
              [-81.379275984147142, 35.755697811521131, 0.0],
              [-81.379725384127298, 35.755354278168689, 0.0],
              [-81.380343517816073, 35.755171077433765, 0.0],
              [-81.381776384092518, 35.754964810990899, 0.0],
              [-81.382254116900057, 35.755079410964655, 0.0],
              [-81.382422717606161, 35.755308411194918, 0.0],
              [-81.382197917308432, 35.755400011624161, 0.0],
              [-81.381551717577764, 35.755285477922612, 0.0],
              [-81.381130317803311, 35.755491611254214, 0.0],
              [-81.381130384016785, 35.755720611129355, 0.0],
              [-81.3848109174196, 35.756224211326533, 0.0],
              [-81.385063784312848, 35.756499011442045, 0.0],
              [-81.386019117796067, 35.75670507802522, 0.0],
              [-81.386243917192445, 35.756842477876361, 0.0],
              [-81.386328184086167, 35.757094410752956, 0.0],
              [-81.38649678383679, 35.757208878207045, 0.0],
              [-81.386833917154917, 35.757254611456936, 0.0],
              [-81.387114917434374, 35.757117210791584, 0.0],
              [-81.387648718008052, 35.757071278023886, 0.0],
              [-81.388070183540336, 35.757277411411437, 0.0],
              [-81.388126384410711, 35.757414811650058, 0.0],
              [-81.387873517200276, 35.757460611418857, 0.0],
              [-81.3879017171502, 35.757666678300893, 0.0],
              [-81.388716584363934, 35.757964277385504, 0.0],
              [-81.389081917341116, 35.75828487741159, 0.0],
              [-81.389615784278547, 35.758468011298653, 0.0],
              [-81.392284916847984, 35.758444811504582, 0.0],
              [-81.392537917147223, 35.758582078113662, 0.0],
              [-81.393689717197589, 35.758467478164164, 0.0],
              [-81.39368991793846, 35.758985077361878, 0.0],
              [-81.393690117236659, 35.759589611372498, 0.0],
              [-81.39000951767845, 35.760185677425959, 0.0],
              [-81.38933518449322, 35.760117011523846, 0.0],
              [-81.388520183823857, 35.759888078065671, 0.0],
              [-81.387789717676, 35.759567611274605, 0.0],
              [-81.386834183636722, 35.758857810754478, 0.0],
              [-81.38660958468823, 35.75910967735571, 0.0],
              [-81.386384716900778, 35.759155610898461, 0.0],
              [-81.38604751737536, 35.758812078139265, 0.0],
              [-81.385344984508237, 35.758606011003231, 0.0],
              [-81.384361717981122, 35.758422878153638, 0.0],
              [-81.381046317840116, 35.758217011462371, 0.0],
              [-81.380933917446441, 35.758468877738196, 0.0],
              [-81.3805123837368, 35.758560611176456, 0.0],
              [-81.379500917360033, 35.758560611286029, 0.0],
              [-81.378573784272689, 35.758262877614833, 0.0],
              [-81.3782927843083, 35.758240011067343, 0.0],
              [-81.378039917233565, 35.758354477854468, 0.0],
              [-81.377478118051172, 35.758835478132369, 0.0],
              [-81.37691611708405, 35.759614077978341, 0.0],
              [-81.376916184368866, 35.762293610773533, 0.0],
              [-81.376803784253653, 35.762316478031906, 0.0],
              [-81.376522784530891, 35.762133277454787, 0.0],
              [-81.37528651753334, 35.763095211547878, 0.0],
              [-81.375061717036786, 35.763438677967571, 0.0],
              [-81.374949317273902, 35.76414867734492, 0.0],
              [-81.374808783876588, 35.764194477674593, 0.0],
              [-81.374668383694171, 35.764446411508629, 0.0],
              [-81.37385338366181, 35.764973077761773, 0.0],
              [-81.373516317425839, 35.765362411531022, 0.0],
              [-81.373516184111665, 35.765637210997099, 0.0],
              [-81.373881584490888, 35.76579761143082, 0.0],
              [-81.373825383892836, 35.76591207812794, 0.0],
              [-81.373179117385149, 35.766186877982769, 0.0],
              [-81.37286998407005, 35.766851011638778, 0.0],
              [-81.372870117773772, 35.767629811420889, 0.0],
              [-81.373122983649736, 35.768179411136479, 0.0],
              [-81.373066784072108, 35.7684770773458, 0.0],
              [-81.372701517649503, 35.768339677745558, 0.0],
              [-81.37219558360205, 35.767996077972704, 0.0],
              [-81.371970784251317, 35.76792747798735, 0.0],
              [-81.371380718025307, 35.768041877959085, 0.0],
              [-81.371155984224842, 35.768225210791364, 0.0],
              [-81.371127783567829, 35.768797677840261, 0.0],
              [-81.370931184436969, 35.76891221125981, 0.0],
              [-81.370678317824684, 35.768912211040629, 0.0],
              [-81.370678317057695, 35.768339611324876, 0.0],
              [-81.370059917353515, 35.768408278041235, 0.0],
              [-81.369273117839853, 35.768889211371878, 0.0],
              [-81.3677837180296, 35.769278411554914, 0.0],
              [-81.366631584328275, 35.769759277749856, 0.0],
              [-81.366013383683836, 35.769873878270033, 0.0],
              [-81.364861117326853, 35.770698210830453, 0.0],
              [-81.364636317819887, 35.770950077339371, 0.0],
              [-81.364355317952317, 35.770950011155371, 0.0],
              [-81.364383384363506, 35.770606477729189, 0.0],
              [-81.364608317852458, 35.770400410664273, 0.0],
              [-81.365001784699913, 35.769828011430064, 0.0],
              [-81.365057984123354, 35.769392811574811, 0.0],
              [-81.36483318435441, 35.76920947783583, 0.0],
              [-81.363034917825331, 35.768751411257497, 0.0],
              [-81.361798518006978, 35.768751277612324, 0.0],
              [-81.361152317295108, 35.768499278231054, 0.0],
              [-81.359915784142174, 35.768567877383894, 0.0],
              [-81.358904183660826, 35.768338678013272, 0.0],
              [-81.358426384077944, 35.768361611129322, 0.0],
              [-81.358370184394346, 35.768888277315867, 0.0],
              [-81.358201583987366, 35.769025677793586, 0.0],
              [-81.358061117318385, 35.768979877652576, 0.0],
              [-81.35780818454333, 35.768498877892036, 0.0],
              [-81.357414784684451, 35.768292811030122, 0.0],
              [-81.357049517557485, 35.768292677941126, 0.0],
              [-81.35682478419119, 35.768407077572995, 0.0],
              [-81.356571717446769, 35.76891087787336, 0.0],
              [-81.356346783677822, 35.769002477842918, 0.0],
              [-81.355616184419333, 35.769071077807297, 0.0],
              [-81.355138517810246, 35.769300010929726, 0.0],
              [-81.354885517810871, 35.76968927807637, 0.0],
              [-81.354829184573049, 35.770055678225404, 0.0],
              [-81.354182784580829, 35.770628211156001, 0.0],
              [-81.354070317374862, 35.770834210993357, 0.0],
              [-81.35392958407796, 35.772002211096556, 0.0],
              [-81.353592117151848, 35.772849478128727, 0.0],
              [-81.3536761847337, 35.773651077491586, 0.0],
              [-81.353929117190717, 35.77397181091861, 0.0],
              [-81.354940584078847, 35.774521610701498, 0.0],
              [-81.356092717838251, 35.774865277786986, 0.0],
              [-81.356317517647611, 35.775048478241658, 0.0],
              [-81.356233117245921, 35.775208678020249, 0.0],
              [-81.355839717888642, 35.775208677422974, 0.0],
              [-81.355221517738642, 35.775071210850356, 0.0],
              [-81.354771784653366, 35.775116878000418, 0.0],
              [-81.354771717268022, 35.775414677893153, 0.0],
              [-81.355361783972583, 35.775964411314405, 0.0],
              [-81.355474117609432, 35.776216410773024, 0.0],
              [-81.355445917062042, 35.776697277533081, 0.0],
              [-81.355726917285978, 35.7770180110696, 0.0],
              [-81.358003317818444, 35.777201477936195, 0.0],
              [-81.358677717837494, 35.77754521137674, 0.0],
              [-81.358649584307315, 35.777682610893628, 0.0],
              [-81.358059384580741, 35.777659477401251, 0.0],
              [-81.357525517305916, 35.777545011512622, 0.0],
              [-81.35701958356961, 35.777682278109523, 0.0],
              [-81.356850917654072, 35.777842610955815, 0.0],
              [-81.356850916891958, 35.778163210980914, 0.0],
              [-81.356963184685668, 35.778346411004691, 0.0],
              [-81.35718798442575, 35.77843801100331, 0.0],
              [-81.357806318008372, 35.778484011595033, 0.0],
              [-81.358340183630744, 35.778667211560332, 0.0],
              [-81.35834018431224, 35.778850411291138, 0.0],
              [-81.357581384738566, 35.779010677586761, 0.0],
              [-81.357272184405417, 35.779216677815313, 0.0],
              [-81.357272117774883, 35.779445678190093, 0.0],
              [-81.357468784399444, 35.779629011522609, 0.0],
              [-81.357440583744491, 35.779926677800781, 0.0],
              [-81.357187583641149, 35.780407610904206, 0.0],
              [-81.35687838455506, 35.780751078143837, 0.0],
              [-81.356484584508792, 35.78182741136942, 0.0],
              [-81.356484584261779, 35.782193877407423, 0.0],
              [-81.356372117700843, 35.782399877395619, 0.0],
              [-81.356147183762644, 35.782422810864745, 0.0],
              [-81.355894317352849, 35.782331077790147, 0.0],
              [-81.35479811718605, 35.782972210666991, 0.0],
              [-81.354067184062131, 35.783773611009579, 0.0],
              [-81.353870317434186, 35.784323277452501, 0.0],
              [-81.353729717090857, 35.784575077898523, 0.0],
              [-81.353757717477649, 35.784804211240235, 0.0],
              [-81.354010717661566, 35.784758411123995, 0.0],
              [-81.354938384069285, 35.784231878084057, 0.0],
              [-81.355725317611032, 35.784231877985746, 0.0],
              [-81.356006518050265, 35.784323477859807, 0.0],
              [-81.356596517313164, 35.784942011491431, 0.0],
              [-81.356793117386246, 35.785423010662441, 0.0],
              [-81.356877316998165, 35.786155877708765, 0.0],
              [-81.356989717947215, 35.786293277526411, 0.0],
              [-81.357326917571072, 35.786293410865476, 0.0],
              [-81.358844917373773, 35.785148478175287, 0.0],
              [-81.359322716954821, 35.784942411175834, 0.0],
              [-81.359800717020249, 35.784484410735963, 0.0],
              [-81.359828784399809, 35.784118010731454, 0.0],
              [-81.360194384640607, 35.782995878085451, 0.0],
              [-81.360391117480418, 35.782812678064282, 0.0],
              [-81.360672317155476, 35.782835611046551, 0.0],
              [-81.360756516897737, 35.782950077678727, 0.0],
              [-81.360700317011279, 35.783202011262119, 0.0],
              [-81.360587784166839, 35.783362277332373, 0.0],
              [-81.360587783776481, 35.783637077663634, 0.0],
              [-81.360756384187923, 35.783728677951295, 0.0],
              [-81.361290383545239, 35.783545610755091, 0.0],
              [-81.362049317485443, 35.783133477649208, 0.0],
              [-81.362330384462098, 35.783156411379444, 0.0],
              [-81.362892384632673, 35.783431277606248, 0.0],
              [-81.363089184601805, 35.783729010700171, 0.0],
              [-81.363679317280756, 35.783729077695916, 0.0],
              [-81.363763717455512, 35.783797811032962, 0.0],
              [-81.363791583547979, 35.784370277629264, 0.0],
              [-81.363679184244873, 35.784530611087391, 0.0],
              [-81.363257584311668, 35.784782478095757, 0.0],
              [-81.363257584273427, 35.785011478078737, 0.0],
              [-81.364550384638264, 35.785034477712493, 0.0],
              [-81.365674717023893, 35.785194877665013, 0.0],
              [-81.367080117070074, 35.784943078266856, 0.0],
              [-81.367389183661658, 35.784530878110573, 0.0],
              [-81.369019517278005, 35.783477478005281, 0.0],
              [-81.369328583935996, 35.783500477656617, 0.0],
              [-81.369862716881016, 35.783981410754052, 0.0],
              [-81.37064958365157, 35.784302010661257, 0.0],
              [-81.370705716882995, 35.785103611570982, 0.0],
              [-81.370930583809937, 35.785859277324057, 0.0],
              [-81.371099117022894, 35.786134210761539, 0.0],
              [-81.371380183590148, 35.786317277451815, 0.0],
              [-81.371689383944457, 35.786248677655777, 0.0],
              [-81.371914317373083, 35.78592807772965, 0.0],
              [-81.372026584372236, 35.785584611146255, 0.0],
              [-81.372082983592648, 35.784210478038972, 0.0],
              [-81.372897984170166, 35.783454677363025, 0.0],
              [-81.37374118453512, 35.782927877445204, 0.0],
              [-81.373994117690955, 35.782538677758303, 0.0],
              [-81.374162717610901, 35.782424211595696, 0.0],
              [-81.374443783869367, 35.782034810794734, 0.0],
              [-81.374556184337038, 35.78162261103612, 0.0],
              [-81.374809183740666, 35.781210411055433, 0.0],
              [-81.375005916878294, 35.781187477759694, 0.0],
              [-81.375230718057693, 35.781347810891468, 0.0],
              [-81.375202584190291, 35.781760011111842, 0.0],
              [-81.374949717014985, 35.782309610942178, 0.0],
              [-81.374893518014431, 35.782721877594675, 0.0],
              [-81.374809184350795, 35.78283641090561, 0.0],
              [-81.373600717172607, 35.784164611157742, 0.0],
              [-81.373375783583967, 35.784622677313187, 0.0],
              [-81.373403783725678, 35.785630277537742, 0.0],
              [-81.373797517932232, 35.786706677328127, 0.0],
              [-81.373909917978779, 35.787645677933689, 0.0],
              [-81.37435958352458, 35.787760210880592, 0.0],
              [-81.374500117208498, 35.787920477828372, 0.0],
              [-81.374472116910383, 35.788149477345222, 0.0],
              [-81.373488317544158, 35.788470078183771, 0.0],
              [-81.373319583903324, 35.788607477325179, 0.0],
              [-81.372701184380645, 35.78995881072283, 0.0],
              [-81.372729317155887, 35.790393877835783, 0.0],
              [-81.372869917199836, 35.790622811338913, 0.0],
              [-81.373207183543684, 35.790645810839457, 0.0],
              [-81.3740223178376, 35.790164811212406, 0.0],
              [-81.374809317223864, 35.78995881068218, 0.0],
              [-81.375596317219618, 35.789890078046476, 0.0],
              [-81.377085984719685, 35.790004478105331, 0.0],
              [-81.377282784630069, 35.789958677562495, 0.0],
              [-81.377282784018178, 35.789867077621274, 0.0],
              [-81.377507584480583, 35.789683811350066, 0.0],
              [-81.377816783965187, 35.789775411090361, 0.0],
              [-81.378294584357818, 35.789775411230153, 0.0],
              [-81.378772518024462, 35.790164677471736, 0.0],
              [-81.380543384278056, 35.790737277972497, 0.0],
              [-81.380796317263815, 35.790691410875112, 0.0],
              [-81.381133584539498, 35.790324877958334, 0.0],
              [-81.381470916854425, 35.79027907756074, 0.0],
              [-81.381976917396045, 35.790714210902117, 0.0],
              [-81.382201716887394, 35.790714210951691, 0.0],
              [-81.382286117590667, 35.79064547790081, 0.0],
              [-81.382286117484497, 35.790416411185106, 0.0],
              [-81.381667584575183, 35.789729477651008, 0.0],
              [-81.381583317504948, 35.789454678120912, 0.0],
              [-81.381667584050973, 35.789363077416269, 0.0],
              [-81.381892517091472, 35.789363011213709, 0.0],
              [-81.382848117984523, 35.789820877338137, 0.0],
              [-81.383213517917568, 35.789843878025458, 0.0],
              [-81.38366331795622, 35.789752210802277, 0.0],
              [-81.38383191750826, 35.789912477600431, 0.0],
              [-81.383888183642057, 35.79039341100119, 0.0],
              [-81.384169317978788, 35.790805610968967, 0.0],
              [-81.384450384706824, 35.79082847748321, 0.0],
              [-81.384534717335171, 35.79073687745511, 0.0],
              [-81.384534717806261, 35.790439211432101, 0.0],
              [-81.384759384358702, 35.790072677680428, 0.0],
              [-81.385462117716528, 35.790026877965268, 0.0],
              [-81.385715117832831, 35.789935211498339, 0.0],
              [-81.385883784437112, 35.789523011540453, 0.0],
              [-81.386108517657846, 35.789271077781414, 0.0],
              [-81.386445783578509, 35.789202410839373, 0.0],
              [-81.386755117249592, 35.790003811529324, 0.0],
              [-81.387148584310978, 35.790141210801472, 0.0],
              [-81.389116183581152, 35.79014101152675, 0.0],
              [-81.389593984657907, 35.789957678133611, 0.0],
              [-81.389846916961275, 35.789591277613255, 0.0],
              [-81.390212317063032, 35.789545477461203, 0.0],
              [-81.390549583996503, 35.789751477512368, 0.0],
              [-81.39173031748544, 35.789728411149234, 0.0],
              [-81.392039317898551, 35.789476478187296, 0.0],
              [-81.39232038431517, 35.789362011417268, 0.0],
              [-81.392573317069989, 35.789361877858106, 0.0],
              [-81.392910583715221, 35.789545010640254, 0.0],
              [-81.393922517809173, 35.789521877860324, 0.0],
              [-81.394878117192647, 35.789292677479139, 0.0],
              [-81.39698598408593, 35.7889946779326, 0.0],
              [-81.399375384431153, 35.7894522109493, 0.0],
              [-81.400330984156113, 35.789452011597483, 0.0],
              [-81.400864983911774, 35.78924581069419, 0.0],
              [-81.400921117738534, 35.789108411198143, 0.0],
              [-81.401286384637004, 35.788810611028751, 0.0],
              [-81.401820384541182, 35.788558477707234, 0.0],
              [-81.40238258403032, 35.788512611581716, 0.0],
              [-81.403085317958016, 35.78899341073565, 0.0],
              [-81.403563184659205, 35.789039011497458, 0.0],
              [-81.404040917813063, 35.788672478016345, 0.0],
              [-81.404434384008411, 35.788672410743892, 0.0],
              [-81.404856184085759, 35.789130278202542, 0.0],
              [-81.404800317528455, 35.789634210878575, 0.0],
              [-81.403928916863322, 35.789359611106214, 0.0],
              [-81.403479117719769, 35.789382611037738, 0.0],
              [-81.403451117603538, 35.78974901112479, 0.0],
              [-81.403226317694177, 35.789840677517695, 0.0],
              [-81.40210191749064, 35.789863878254081, 0.0],
              [-81.400499917290105, 35.790299278077754, 0.0],
              [-81.399544317562643, 35.79066587731797, 0.0],
              [-81.398448317896907, 35.79094101108376, 0.0],
              [-81.397717517745491, 35.790941211159634, 0.0],
              [-81.396508783662824, 35.790689478175963, 0.0],
              [-81.395047184691734, 35.790804277384225, 0.0],
              [-81.394147784167217, 35.791010477335426, 0.0],
              [-81.393585583772705, 35.790919010661085, 0.0],
              [-81.3927985840037, 35.790987811219978, 0.0],
              [-81.39198358359171, 35.791308610894042, 0.0],
              [-81.391730583950618, 35.791491810682423, 0.0],
              [-81.39144951767247, 35.79146887798673, 0.0],
              [-81.391337116983834, 35.791308677678266, 0.0],
              [-81.390915517929585, 35.791308677324608, 0.0],
              [-81.390184717508774, 35.79160661098274, 0.0],
              [-81.389172783918653, 35.791675411100798, 0.0],
              [-81.388132917623963, 35.792087677330812, 0.0],
              [-81.387739383845854, 35.792431277996705, 0.0],
              [-81.387627117291331, 35.792683211591594, 0.0],
              [-81.387823783615431, 35.79318707817945, 0.0],
              [-81.387767718034979, 35.793736677366674, 0.0],
              [-81.38728991716178, 35.79369101109134, 0.0],
              [-81.387064983619254, 35.793439077588424, 0.0],
              [-81.386811917399697, 35.792729077806449, 0.0],
              [-81.386530583584644, 35.792477211291512, 0.0],
              [-81.385912317843449, 35.792637611236756, 0.0],
              [-81.384956517690412, 35.792340011148127, 0.0],
              [-81.38456298364946, 35.792088078137787, 0.0],
              [-81.383916517201257, 35.792179811038416, 0.0],
              [-81.38315771695477, 35.79254627752232, 0.0],
              [-81.382567384059541, 35.79305007734456, 0.0],
              [-81.381611784030397, 35.793485277493133, 0.0],
              [-81.381246316948435, 35.793416610712853, 0.0],
              [-81.380431117479901, 35.792729678008101, 0.0],
              [-81.380009516878161, 35.792477678072125, 0.0],
              [-81.379194383614319, 35.792523611374229, 0.0],
              [-81.376580183682208, 35.792981677942663, 0.0],
              [-81.37627111772025, 35.793187811528348, 0.0],
              [-81.376102517978211, 35.793829078177204, 0.0],
              [-81.376102517793328, 35.794699277714479, 0.0],
              [-81.376692783701031, 35.796233678198419, 0.0],
              [-81.376748984437725, 35.796668811099167, 0.0],
              [-81.376467916911324, 35.796668810917026, 0.0],
              [-81.376074383935432, 35.796531411033413, 0.0],
              [-81.375765183569087, 35.796302411483339, 0.0],
              [-81.375652717751251, 35.79609627796998, 0.0],
              [-81.375596516980536, 35.795546611129517, 0.0],
              [-81.375146717041034, 35.794424477625597, 0.0],
              [-81.375343517026806, 35.793187811171599, 0.0],
              [-81.374809384736082, 35.792912878209997, 0.0],
              [-81.374134584355986, 35.792890077376377, 0.0],
              [-81.373909983600498, 35.793737477991421, 0.0],
              [-81.373656917432754, 35.794103877444229, 0.0],
              [-81.373319583607596, 35.794035211118803, 0.0],
              [-81.37278551799443, 35.793393877997978, 0.0],
              [-81.371885984149728, 35.792706877380617, 0.0],
              [-81.371492517503199, 35.792546477698373, 0.0],
              [-81.370480584297567, 35.792500811557098, 0.0],
              [-81.369862183823571, 35.792638077951189, 0.0],
              [-81.368512984377986, 35.792546477537577, 0.0],
              [-81.368569183869965, 35.792363277803076, 0.0],
              [-81.369637383861786, 35.791790810715902, 0.0],
              [-81.370424384248651, 35.791470210912372, 0.0],
              [-81.370593117741876, 35.791332811191701, 0.0],
              [-81.370705516956306, 35.790989278009086, 0.0],
              [-81.371408317169013, 35.790691477622893, 0.0],
              [-81.37154891743225, 35.789821277959497, 0.0],
              [-81.371886117930941, 35.789523611350681, 0.0],
              [-81.371998583665331, 35.78924867826494, 0.0],
              [-81.371998584277549, 35.788309810906377, 0.0],
              [-81.372307717933325, 35.78801201146522, 0.0],
              [-81.372251517530813, 35.787599811592514, 0.0],
              [-81.372054783872315, 35.787370810973023, 0.0],
              [-81.370424517989122, 35.787187477899472, 0.0],
              [-81.370227783892588, 35.787004277824053, 0.0],
              [-81.369721983740021, 35.785790610727958, 0.0],
              [-81.369412917336689, 35.78485161075583, 0.0],
              [-81.36918798359784, 35.784714211359265, 0.0],
              [-81.368766517500958, 35.784805811307358, 0.0],
              [-81.368372917778018, 35.785195010939709, 0.0],
              [-81.367445383844625, 35.785790410775469, 0.0],
              [-81.366967583955528, 35.785836211385288, 0.0],
              [-81.366798917039361, 35.786042278135099, 0.0],
              [-81.366573983625912, 35.786042278176218, 0.0],
              [-81.366152517795172, 35.785813277837875, 0.0],
              [-81.36508451774165, 35.785813210721003, 0.0],
              [-81.364494184709088, 35.786042078119678, 0.0],
              [-81.36438178451283, 35.786225277793122, 0.0],
              [-81.364241184441724, 35.786981210650154, 0.0],
              [-81.364016317608389, 35.786981211533416, 0.0],
              [-81.363735183910592, 35.786660477739375, 0.0],
              [-81.363538583584102, 35.78627107802096, 0.0],
              [-81.363088784048912, 35.786431478092823, 0.0],
              [-81.363032517433354, 35.786797878245011, 0.0],
              [-81.362610984250992, 35.786706210979688, 0.0],
              [-81.362470384412134, 35.786271011469424, 0.0],
              [-81.362217717713932, 35.784645011438855, 0.0],
              [-81.36207718404053, 35.784461810807194, 0.0],
              [-81.361599384467326, 35.784507477983219, 0.0],
              [-81.360980917820214, 35.785080011549738, 0.0],
              [-81.360390583811977, 35.785904477675729, 0.0],
              [-81.360559117849022, 35.786889210805128, 0.0],
              [-81.36033411797797, 35.78737001123153, 0.0],
              [-81.360137384337861, 35.787347210885237, 0.0],
              [-81.359828317554829, 35.786728811164998, 0.0],
              [-81.359631584603719, 35.786545477982145, 0.0],
              [-81.359238117230575, 35.78663707741142, 0.0],
              [-81.358310517760245, 35.787095078187193, 0.0],
              [-81.357748318022004, 35.787759078171717, 0.0],
              [-81.357691983634481, 35.787965211055713, 0.0],
              [-81.357972784125522, 35.788858410970427, 0.0],
              [-81.357944717117277, 35.789133211210341, 0.0],
              [-81.357635583934183, 35.789133077868414, 0.0],
              [-81.357382584572122, 35.788675011235767, 0.0],
              [-81.356932983751818, 35.788423078116956, 0.0],
              [-81.356792583983008, 35.787804677731046, 0.0],
              [-81.356370983766027, 35.78736961151283, 0.0],
              [-81.35592158371324, 35.786316010996799, 0.0],
              [-81.355528317696226, 35.785651810785723, 0.0],
              [-81.35533158424505, 35.78546861095974, 0.0],
              [-81.354938117049571, 35.785514277839646, 0.0],
              [-81.354825583897423, 35.785743278087409, 0.0],
              [-81.354572717627548, 35.785926410988154, 0.0],
              [-81.353982317928569, 35.786132477988723, 0.0],
              [-81.353757318063614, 35.786544677768539, 0.0],
              [-81.35389791734562, 35.786727877428426, 0.0],
              [-81.354038384314038, 35.78691121071995, 0.0],
              [-81.354010183552944, 35.78707147739339, 0.0],
              [-81.353700984214839, 35.787071411477676, 0.0],
              [-81.353279517896468, 35.786933878007083, 0.0],
              [-81.352801584221183, 35.786979611528452, 0.0],
              [-81.352689183617656, 35.787368878211225, 0.0],
              [-81.352829517287276, 35.787712477742787, 0.0],
              [-81.353166784147476, 35.787895811503915, 0.0],
              [-81.353194583959024, 35.788811877989673, 0.0],
              [-81.353307117972861, 35.788972211136873, 0.0],
              [-81.353306983932939, 35.789201277971429, 0.0],
              [-81.352463717522568, 35.789338411037249, 0.0],
              [-81.35207011725619, 35.789933811273855, 0.0],
              [-81.352069917492543, 35.790369010667888, 0.0],
              [-81.352266584384324, 35.790712478012203, 0.0],
              [-81.35381231791547, 35.791605877813886, 0.0],
              [-81.354177717044323, 35.791995278268331, 0.0],
              [-81.354149584145162, 35.792178477990873, 0.0],
              [-81.353446717397944, 35.792086810909566, 0.0],
              [-81.352659784592902, 35.79169727825294, 0.0],
              [-81.352069583561374, 35.791582677395141, 0.0],
              [-81.350888917150982, 35.791811610989811, 0.0],
              [-81.350523517666716, 35.792177877752998, 0.0],
              [-81.350467117648307, 35.792544277736013, 0.0],
              [-81.349932917380002, 35.793071011296391, 0.0],
              [-81.349820383975242, 35.793322811210018, 0.0],
              [-81.349932716967032, 35.793712210875952, 0.0],
              [-81.350185517780318, 35.794078611445244, 0.0],
              [-81.350663383953915, 35.794399410725831, 0.0],
              [-81.351197518005421, 35.794605477432945, 0.0],
              [-81.351337784160421, 35.795178077843069, 0.0],
              [-81.352096517186965, 35.795956877361995, 0.0],
              [-81.352658584320324, 35.79639207790327, 0.0],
              [-81.353304984611569, 35.797102210774007, 0.0],
              [-81.353276783714222, 35.797262477629566, 0.0],
              [-81.352967583912161, 35.797399877463548, 0.0],
              [-81.352630317435953, 35.797308277334025, 0.0],
              [-81.351506184640172, 35.796254477719906, 0.0],
              [-81.350325717220471, 35.795750411562452, 0.0],
              [-81.349651184189327, 35.795338078206598, 0.0],
              [-81.349173384718029, 35.795337877269652, 0.0],
              [-81.349060917443495, 35.795452477952821, 0.0],
              [-81.349144783711125, 35.796666277520409, 0.0],
              [-81.348807383624234, 35.796712077564855, 0.0],
              [-81.347936384534535, 35.795956211392628, 0.0],
              [-81.347542784739829, 35.796024677795877, 0.0],
              [-81.346727383578312, 35.796665811257597, 0.0],
              [-81.345799583881174, 35.79705487736846, 0.0],
              [-81.343044517693997, 35.797603878157659, 0.0],
              [-81.340205183752317, 35.797740410621984, 0.0],
              [-81.33834978446302, 35.798152210696664, 0.0],
              [-81.337281517320804, 35.798312211393373, 0.0],
              [-81.336269317293088, 35.798632477954072, 0.0],
              [-81.334751384263882, 35.798494611175506, 0.0],
              [-81.333654917641852, 35.798952211495923, 0.0],
              [-81.332220583743549, 35.80025721119064, 0.0],
              [-81.332023517662819, 35.800692277837342, 0.0],
              [-81.331854517446388, 35.80142507789494, 0.0],
              [-81.332078984328604, 35.802180810811976, 0.0],
              [-81.332640917526064, 35.802868077922433, 0.0],
              [-81.332949984779503, 35.802982677741731, 0.0],
              [-81.333934183822379, 35.802639478069473, 0.0],
              [-81.334890184394112, 35.802158877381359, 0.0],
              [-81.336267717279455, 35.802205077365912, 0.0],
              [-81.3364643840669, 35.802273877672945, 0.0],
              [-81.336745583677924, 35.802205278079917, 0.0],
              [-81.336942518096748, 35.801999277385569, 0.0],
              [-81.337055384767268, 35.801106011450479, 0.0],
              [-81.337364717999961, 35.800739677707234, 0.0],
              [-81.338236117847458, 35.800808677685275, 0.0],
              [-81.338910918111509, 35.800694410915042, 0.0],
              [-81.340204317679465, 35.799916077763761, 0.0],
              [-81.341862917464525, 35.80014567819866, 0.0],
              [-81.342340717686682, 35.80032887814415, 0.0],
              [-81.343155717050152, 35.800970411086993, 0.0],
              [-81.343773984571314, 35.801176678212862, 0.0],
              [-81.343998917879105, 35.801451478235855, 0.0],
              [-81.344279717324881, 35.802207277311837, 0.0],
              [-81.345122717334661, 35.803238077887464, 0.0],
              [-81.344700984319786, 35.803260878162135, 0.0],
              [-81.34444798425767, 35.803100477939132, 0.0],
              [-81.344391917963677, 35.802940211169158, 0.0],
              [-81.343351984350122, 35.802161277465139, 0.0],
              [-81.342536917189122, 35.801794677828063, 0.0],
              [-81.341412717172858, 35.800992811157805, 0.0],
              [-81.34079418462494, 35.801061410692036, 0.0],
              [-81.340288183869177, 35.801244411449197, 0.0],
              [-81.339641383597737, 35.801679411312278, 0.0],
              [-81.339528917225891, 35.801954211288951, 0.0],
              [-81.339331584656378, 35.803122078232711, 0.0],
              [-81.339359517980384, 35.803488610684653, 0.0],
              [-81.339809117955227, 35.8039466776697, 0.0],
              [-81.339724717438031, 35.804129877655235, 0.0],
              [-81.338881184608297, 35.804244077992216, 0.0],
              [-81.338740717845241, 35.804427411170934, 0.0],
              [-81.33876858402283, 35.804656277975624, 0.0],
              [-81.338965317412402, 35.804954078099463, 0.0],
              [-81.338937117465676, 35.805160211459871, 0.0],
              [-81.338712184452234, 35.805160211329827, 0.0],
              [-81.338234384500041, 35.804770677854897, 0.0],
              [-81.337897183745469, 35.804633077952055, 0.0],
              [-81.336407117613604, 35.804747210729715, 0.0],
              [-81.335675917773315, 35.805273677333432, 0.0],
              [-81.33564771769575, 35.805525677610298, 0.0],
              [-81.336265984136801, 35.806006811101717, 0.0],
              [-81.336743783621685, 35.806213010908529, 0.0],
              [-81.336743717274544, 35.806396211284294, 0.0],
              [-81.336377984045271, 35.806945811453787, 0.0],
              [-81.33606871713782, 35.806991477693224, 0.0],
              [-81.335984183932311, 35.807174678208234, 0.0],
              [-81.336180584470313, 35.808228211328014, 0.0],
              [-81.337107383925968, 35.810358411499259, 0.0],
              [-81.337641183623916, 35.811114210950265, 0.0],
              [-81.33862538418083, 35.810931277816714, 0.0],
              [-81.339018917549396, 35.811023077301435, 0.0],
              [-81.338934517598361, 35.811206278090694, 0.0],
              [-81.338287584562806, 35.811778611104849, 0.0],
              [-81.338287383632334, 35.812076277527602, 0.0],
              [-81.338849317353151, 35.812992478024732, 0.0],
              [-81.338793117794197, 35.813175677987267, 0.0],
              [-81.338624317142745, 35.813267210640184, 0.0],
              [-81.336965317492627, 35.813564478171187, 0.0],
              [-81.336768517309679, 35.813839210988135, 0.0],
              [-81.336824517719151, 35.814114011527884, 0.0],
              [-81.33789238473112, 35.815145010985212, 0.0],
              [-81.33853898476417, 35.815397077639595, 0.0],
              [-81.339888584420052, 35.815695211329334, 0.0],
              [-81.340338317178578, 35.816061677911215, 0.0],
              [-81.340450583724902, 35.816290811198684, 0.0],
              [-81.340365984593092, 35.817138077856789, 0.0],
              [-81.340478383722541, 35.817229677358391, 0.0],
              [-81.340731517766912, 35.817184011402361, 0.0],
              [-81.340928317563538, 35.816978011356781, 0.0],
              [-81.341125117697842, 35.817000878019869, 0.0],
              [-81.341237516962167, 35.81748181139973, 0.0],
              [-81.341406117503666, 35.817665078121088, 0.0],
              [-81.341996584495746, 35.817573610681386, 0.0],
              [-81.342418517157128, 35.817367678029996, 0.0],
              [-81.342727717887669, 35.817413610956699, 0.0],
              [-81.342840117739868, 35.81750521127681, 0.0],
              [-81.342783784287576, 35.817757077489873, 0.0],
              [-81.342164984595271, 35.818375211075221, 0.0],
              [-81.342080384097073, 35.818901877250312, 0.0],
              [-81.341911717582065, 35.818993477652967, 0.0],
              [-81.341461917690822, 35.818856011321721, 0.0],
              [-81.340759117370723, 35.818352011528027, 0.0],
              [-81.340140717169902, 35.818008277308557, 0.0],
              [-81.339381518033932, 35.817847810974577, 0.0],
              [-81.338819183973271, 35.8179162778072, 0.0],
              [-81.338706584145456, 35.818236878197332, 0.0],
              [-81.338734583857658, 35.818397210785342, 0.0],
              [-81.339296717134573, 35.818969877730339, 0.0],
              [-81.339183917767841, 35.819679877545731, 0.0],
              [-81.339351784718644, 35.821466210562335, 0.0],
              [-81.339548317689832, 35.822428077762034, 0.0],
              [-81.339716983655151, 35.822725810719248, 0.0],
              [-81.33994191739545, 35.82281747810071, 0.0],
              [-81.34044798436264, 35.822703211381246, 0.0],
              [-81.340672984364474, 35.822542877902272, 0.0],
              [-81.341460784519398, 35.8215126109246, 0.0],
              [-81.341910784643488, 35.821398211312882, 0.0],
              [-81.343007517924647, 35.821306877703712, 0.0],
              [-81.343822984240276, 35.820986477569186, 0.0],
              [-81.344075984507882, 35.821032277484775, 0.0],
              [-81.344075984066464, 35.821307211414656, 0.0],
              [-81.343822783630742, 35.821604810845393, 0.0],
              [-81.343822584006745, 35.821994077847471, 0.0],
              [-81.343934984492137, 35.822360610963216, 0.0],
              [-81.345002984246022, 35.823666210905074, 0.0],
              [-81.345340383940339, 35.823780811428875, 0.0],
              [-81.345537183946064, 35.823964011299836, 0.0],
              [-81.345537116924987, 35.824147210976086, 0.0],
              [-81.345368317249381, 35.824238811519173, 0.0],
              [-81.344749717328213, 35.824330210904364, 0.0],
              [-81.344580984148067, 35.82449047782908, 0.0],
              [-81.344552383618677, 35.825269211305276, 0.0],
              [-81.344383717138584, 35.825429477563908, 0.0],
              [-81.344243184550905, 35.825360811436823, 0.0],
              [-81.344271517032269, 35.824788210951411, 0.0],
              [-81.344159117416694, 35.824582011384429, 0.0],
              [-81.343990384121739, 35.824627811084412, 0.0],
              [-81.343764983927429, 35.825658277875355, 0.0],
              [-81.343624317341849, 35.825749877511193, 0.0],
              [-81.343511917247696, 35.825658277938324, 0.0],
              [-81.343315583603584, 35.824192477955151, 0.0],
              [-81.34311898389798, 35.823803077945044, 0.0],
              [-81.342753517223528, 35.823482411548071, 0.0],
              [-81.342303584462186, 35.823436477692354, 0.0],
              [-81.342134784321132, 35.823573877261026, 0.0],
              [-81.342162316951971, 35.825154078083386, 0.0],
              [-81.341712184266953, 35.825703610724098, 0.0],
              [-81.341599583797546, 35.826024210988152, 0.0],
              [-81.341599384384878, 35.826413478176818, 0.0],
              [-81.341374383922414, 35.826482211006031, 0.0],
              [-81.341008984173612, 35.826230210915448, 0.0],
              [-81.340334117699186, 35.826184210965856, 0.0],
              [-81.339800117511061, 35.825428277660222, 0.0],
              [-81.339434717344716, 35.825016011163541, 0.0],
              [-81.33923811782121, 35.824557877426273, 0.0],
              [-81.339125917290815, 35.824008210692398, 0.0],
              [-81.338254984305166, 35.821786477650619, 0.0],
              [-81.337917783608262, 35.821442810819299, 0.0],
              [-81.337242916939104, 35.821511410771123, 0.0],
              [-81.3371305844788, 35.821236477672429, 0.0],
              [-81.337608583955941, 35.821007677812844, 0.0],
              [-81.337636984757566, 35.820572611461913, 0.0],
              [-81.336737784337359, 35.819473011019454, 0.0],
              [-81.336484917882075, 35.819014877901921, 0.0],
              [-81.336485117956926, 35.818465211011677, 0.0],
              [-81.336963517427165, 35.817595211133892, 0.0],
              [-81.337300984395526, 35.81743487761679, 0.0],
              [-81.338032184141255, 35.817320610815024, 0.0],
              [-81.339269517894522, 35.816885877578002, 0.0],
              [-81.339438316925907, 35.816679810660155, 0.0],
              [-81.339494718013711, 35.816267611476022, 0.0],
              [-81.339101118055396, 35.816061411055095, 0.0],
              [-81.338482717820526, 35.815878010813449, 0.0],
              [-81.33704858435938, 35.815946277525505, 0.0],
              [-81.335586718102604, 35.815327477481397, 0.0],
              [-81.335221517177345, 35.814869277361652, 0.0],
              [-81.335221916985347, 35.814022011010472, 0.0],
              [-81.335306383892913, 35.81388447735042, 0.0],
              [-81.336121917217667, 35.813541277280322, 0.0],
              [-81.337415584530277, 35.81264847818305, 0.0],
              [-81.337472118020017, 35.812282077656143, 0.0],
              [-81.336319717474098, 35.811320011362632, 0.0],
              [-81.336151183587901, 35.811068011211169, 0.0],
              [-81.335926583767431, 35.810083210935026, 0.0],
              [-81.335533517190981, 35.809121210930577, 0.0],
              [-81.335055783806851, 35.808548410923912, 0.0],
              [-81.33449371742968, 35.808067411341831, 0.0],
              [-81.333959584015474, 35.807952611373992, 0.0],
              [-81.33345358457251, 35.807998277767261, 0.0],
              [-81.332947384589957, 35.808295810721773, 0.0],
              [-81.332637783608845, 35.80879947737855, 0.0],
              [-81.33249698457837, 35.809303410767583, 0.0],
              [-81.332356317210511, 35.809509477687477, 0.0],
              [-81.331793917283733, 35.809829810767212, 0.0],
              [-81.33168131758363, 35.809990077786964, 0.0],
              [-81.331624384012358, 35.811158011534943, 0.0],
              [-81.331764783814236, 35.811684811208956, 0.0],
              [-81.331989517556053, 35.812097077363369, 0.0],
              [-81.332270184140896, 35.813150678015568, 0.0],
              [-81.332157384236623, 35.813769077423061, 0.0],
              [-81.331791583746167, 35.814295677858475, 0.0],
              [-81.331510384326478, 35.814318410987553, 0.0],
              [-81.331173184019747, 35.814089411245661, 0.0],
              [-81.330554584794697, 35.81408907774388, 0.0],
              [-81.329626717997797, 35.814386411312164, 0.0],
              [-81.328670317374687, 35.815164678009587, 0.0],
              [-81.328557583660327, 35.815370810799834, 0.0],
              [-81.327882717010951, 35.815622478108907, 0.0],
              [-81.32788271787409, 35.815449811192259, 0.0],
              [-81.327882783609155, 35.815233078136295, 0.0],
              [-81.328361317611311, 35.814386010674049, 0.0],
              [-81.328642584584387, 35.814111277391341, 0.0],
              [-81.329176984270489, 35.81381381099024, 0.0],
              [-81.330667184294938, 35.813516610848865, 0.0],
              [-81.33092051770636, 35.813402211555605, 0.0],
              [-81.331033118080455, 35.813035677575641, 0.0],
              [-81.3310333841969, 35.812325811004349, 0.0],
              [-81.33063998463858, 35.811844677605826, 0.0],
              [-81.330612317628891, 35.811157677548039, 0.0],
              [-81.330499983759324, 35.810882878223204, 0.0],
              [-81.330500584332043, 35.809921011040693, 0.0],
              [-81.330219583720961, 35.809531610893735, 0.0],
              [-81.329657584512063, 35.809142078087348, 0.0],
              [-81.329657717792571, 35.808958877898853, 0.0],
              [-81.330304317130867, 35.80905067766944, 0.0],
              [-81.331035316999206, 35.809005077627283, 0.0],
              [-81.331597584261672, 35.808730478098155, 0.0],
              [-81.331794718111865, 35.808364077434476, 0.0],
              [-81.332947783688908, 35.807471411254113, 0.0],
              [-81.33351011779834, 35.807265478046197, 0.0],
              [-81.333988517249608, 35.80669307766177, 0.0],
              [-81.334157317271746, 35.806212210805207, 0.0],
              [-81.334241983670552, 35.805479410934396, 0.0],
              [-81.334045517771401, 35.805067077302979, 0.0],
              [-81.333989717827762, 35.803899010695851, 0.0],
              [-81.333905384350686, 35.803830277706872, 0.0],
              [-81.333652384733682, 35.803853211483855, 0.0],
              [-81.332611983998078, 35.804379477704394, 0.0],
              [-81.331571517320455, 35.805043411455202, 0.0],
              [-81.331037317048796, 35.804997411405324, 0.0],
              [-81.331037383667777, 35.804722477519832, 0.0],
              [-81.331852917095773, 35.804402211305096, 0.0],
              [-81.332106118035597, 35.804196077444857, 0.0],
              [-81.332077984231717, 35.803944211081813, 0.0],
              [-81.331516117529915, 35.803417277957266, 0.0],
              [-81.330785317651561, 35.803050611222417, 0.0],
              [-81.330672984721332, 35.802821611259226, 0.0],
              [-81.330898183850238, 35.802203410941324, 0.0],
              [-81.330758516941458, 35.800897878222152, 0.0],
              [-81.330842984761063, 35.800439877581347, 0.0],
              [-81.331068117192586, 35.799959078006289, 0.0],
              [-81.33154638411186, 35.799455278060144, 0.0],
              [-81.332446183744366, 35.798906010630873, 0.0],
              [-81.332558784207592, 35.798699877986884, 0.0],
              [-81.332530717096844, 35.798539610684905, 0.0],
              [-81.332193383694758, 35.798379277596105, 0.0],
              [-81.331209518121398, 35.798264411463393, 0.0],
              [-81.329579116966556, 35.79821787788061, 0.0],
              [-81.329410517422389, 35.798149210859926, 0.0],
              [-81.328595184441809, 35.798240477302627, 0.0],
              [-81.327920517402475, 35.798446277302801, 0.0],
              [-81.327611117430251, 35.798675211292547, 0.0],
              [-81.327189517692517, 35.798675077532344, 0.0],
              [-81.326711784587133, 35.798377077410223, 0.0],
              [-81.326065184546835, 35.798445611503759, 0.0],
              [-81.325868117821557, 35.798857811452088, 0.0],
              [-81.325389984110132, 35.799315610664493, 0.0],
              [-81.32505271801773, 35.799246810899803, 0.0],
              [-81.324799983675774, 35.798697077492726, 0.0],
              [-81.324294317592262, 35.798170077327853, 0.0],
              [-81.323619784365903, 35.798215610826503, 0.0],
              [-81.323169517923318, 35.798971211425602, 0.0],
              [-81.322944584113444, 35.798948210635061, 0.0],
              [-81.322776184529417, 35.798444277270271, 0.0],
              [-81.322607717316899, 35.798329677308999, 0.0],
              [-81.321904917900696, 35.798375211257898, 0.0],
              [-81.321567517474634, 35.798466677926548, 0.0],
              [-81.321230184617207, 35.798375011528684, 0.0],
              [-81.320752184182467, 35.798420477688872, 0.0],
              [-81.320611517911161, 35.798672278006592, 0.0],
              [-81.319346384411503, 35.798717610699221, 0.0],
              [-81.318812317615212, 35.798831877971658, 0.0],
              [-81.317294317656689, 35.798877011005345, 0.0],
              [-81.316731983873581, 35.798968277364885, 0.0],
              [-81.316309917012802, 35.799380278244158, 0.0],
              [-81.316309717905852, 35.799838410824229, 0.0],
              [-81.31684331729322, 35.80047981158264, 0.0],
              [-81.316843117974472, 35.800731677914541, 0.0],
              [-81.316505784488271, 35.800754477814024, 0.0],
              [-81.31571911773726, 35.800273077534392, 0.0],
              [-81.315438584147429, 35.7993112115931, 0.0],
              [-81.314707584294752, 35.799379477461073, 0.0],
              [-81.314088984806787, 35.799654011305762, 0.0],
              [-81.312626317511061, 35.800775478108989, 0.0],
              [-81.312682384609488, 35.801073211423692, 0.0],
              [-81.313188517160299, 35.801004811124955, 0.0],
              [-81.313638383686396, 35.800684411081207, 0.0],
              [-81.313919517858636, 35.80077621066085, 0.0],
              [-81.314003517212981, 35.801257211317022, 0.0],
              [-81.313862583902875, 35.801760877369681, 0.0],
              [-81.31329971750506, 35.802516411037622, 0.0],
              [-81.313186983622629, 35.802882677623103, 0.0],
              [-81.313214784301948, 35.803409477341496, 0.0],
              [-81.313327117998028, 35.803638610818133, 0.0],
              [-81.313523717709771, 35.803798877508896, 0.0],
              [-81.314057718084172, 35.803936611211462, 0.0],
              [-81.315913317995864, 35.803823011464281, 0.0],
              [-81.316081917552026, 35.803891811495774, 0.0],
              [-81.31608178437574, 35.804006277476816, 0.0],
              [-81.315210117699749, 35.804395211445673, 0.0],
              [-81.31512558462687, 35.804601277835104, 0.0],
              [-81.315265917321383, 35.804899078147777, 0.0],
              [-81.315743517059204, 35.80528867771276, 0.0],
              [-81.315939984516376, 35.805632277515286, 0.0],
              [-81.315911784771671, 35.805815477950738, 0.0],
              [-81.315574517204993, 35.805861077637587, 0.0],
              [-81.314956384291435, 35.805288277365229, 0.0],
              [-81.314731517502253, 35.805311078200859, 0.0],
              [-81.31481538365999, 35.805906610718758, 0.0],
              [-81.314730916959164, 35.806066810755333, 0.0],
              [-81.314505918105183, 35.806158277420721, 0.0],
              [-81.314280984226002, 35.806135278122447, 0.0],
              [-81.313859517305772, 35.805883211363614, 0.0],
              [-81.313466517937997, 35.805173010837635, 0.0],
              [-81.312623784595417, 35.804256611481556, 0.0],
              [-81.31147138449812, 35.803797811457137, 0.0],
              [-81.310740917952927, 35.8032248775691, 0.0],
              [-81.309756984401005, 35.803270211527987, 0.0],
              [-81.309644517201761, 35.803361678126905, 0.0],
              [-81.309700517619433, 35.803613811465006, 0.0],
              [-81.310403117988173, 35.803843211118206, 0.0],
              [-81.311330184182268, 35.804553678186693, 0.0],
              [-81.311217584315543, 35.805011611006769, 0.0],
              [-81.311666784824254, 35.805721677700035, 0.0],
              [-81.311666584209732, 35.806019477989928, 0.0],
              [-81.311019717243852, 35.806316877974922, 0.0],
              [-81.310485584365907, 35.806247810914904, 0.0],
              [-81.309811517707715, 35.805331411007138, 0.0],
              [-81.309389783835087, 35.805331210640574, 0.0],
              [-81.309249117392298, 35.805491477306042, 0.0],
              [-81.309333183990702, 35.805789210711801, 0.0],
              [-81.309557984232114, 35.806109877846986, 0.0],
              [-81.309613917651461, 35.806545077995786, 0.0],
              [-81.309501318027372, 35.806636610919597, 0.0],
              [-81.309135917185685, 35.806590677994272, 0.0],
              [-81.308517583762352, 35.80613227740497, 0.0],
              [-81.307899383913636, 35.805834210807753, 0.0],
              [-81.306409517312034, 35.805695877389518, 0.0],
              [-81.306212983642965, 35.805466877357269, 0.0],
              [-81.306241183960864, 35.805237878112223, 0.0],
              [-81.306409984393696, 35.805123477888664, 0.0],
              [-81.307000384268107, 35.805123811405046, 0.0],
              [-81.307450317169398, 35.805009477685111, 0.0],
              [-81.307534717546517, 35.804917877791624, 0.0],
              [-81.307506784619562, 35.80457441139955, 0.0],
              [-81.307282184014809, 35.804345277530842, 0.0],
              [-81.306382317184529, 35.804573878104122, 0.0],
              [-81.305876316973638, 35.804688010961193, 0.0],
              [-81.305201183891839, 35.805054077343513, 0.0],
              [-81.304919783973801, 35.805420277931709, 0.0],
              [-81.304779184627847, 35.805420211326265, 0.0],
              [-81.304638717369144, 35.805282677617782, 0.0],
              [-81.304498917624628, 35.804458211388919, 0.0],
              [-81.304246317350717, 35.804023010749511, 0.0],
              [-81.303965384049704, 35.803725077710169, 0.0],
              [-81.30191418368652, 35.802533011078332, 0.0],
              [-81.301436717435038, 35.802028878074331, 0.0],
              [-81.30109938475114, 35.802074477613587, 0.0],
              [-81.300929984566835, 35.802853011391207, 0.0],
              [-81.300761117336023, 35.803081878256471, 0.0],
              [-81.30045171715669, 35.803219211347084, 0.0],
              [-81.300198783777091, 35.803150278077148, 0.0],
              [-81.299861717781695, 35.802898078027745, 0.0],
              [-81.299974717515198, 35.802302810859352, 0.0],
              [-81.299947184787399, 35.801615677817686, 0.0],
              [-81.299610384196626, 35.800951411171411, 0.0],
              [-81.299469983732536, 35.800836677329031, 0.0],
              [-81.299442118136483, 35.800630611342356, 0.0],
              [-81.298627584343066, 35.799897277674354, 0.0],
              [-81.297980917683986, 35.799965477779836, 0.0],
              [-81.297756517346585, 35.799438677803089, 0.0],
              [-81.297503717068679, 35.799301077873778, 0.0],
              [-81.296913117514308, 35.799392411346602, 0.0],
              [-81.296913117388044, 35.799621411564054, 0.0],
              [-81.297193917845476, 35.799896277788633, 0.0],
              [-81.297193517440107, 35.80028561120961, 0.0],
              [-81.296855918012568, 35.800560411500669, 0.0],
              [-81.296630984627399, 35.800560210606484, 0.0],
              [-81.29649098403145, 35.799964611264187, 0.0],
              [-81.295844784559293, 35.799597811009434, 0.0],
              [-81.295057517098911, 35.799688811556003, 0.0],
              [-81.294776183757193, 35.799872010947901, 0.0],
              [-81.29477598377909, 35.800101010832108, 0.0],
              [-81.295281517367599, 35.800696677426167, 0.0],
              [-81.29558831790591, 35.803147410911549, 0.0],
              [-81.29556011774919, 35.803239011130927, 0.0],
              [-81.295278984161897, 35.803170010964422, 0.0],
              [-81.294381317429668, 35.801337411371946, 0.0],
              [-81.294353384201187, 35.801108277342991, 0.0],
              [-81.293791517764561, 35.800672878206782, 0.0],
              [-81.293257583764486, 35.800489211556645, 0.0],
              [-81.292554717897744, 35.8006032778326, 0.0],
              [-81.292104717014212, 35.800809077890946, 0.0],
              [-81.29199211804324, 35.801015078216302, 0.0],
              [-81.292188118084439, 35.801679410845502, 0.0],
              [-81.292130917781549, 35.802801477980374, 0.0],
              [-81.292383317996155, 35.803305477421276, 0.0],
              [-81.292381784191491, 35.804817077663706, 0.0],
              [-81.292493984419124, 35.805114877364268, 0.0],
              [-81.292493384015003, 35.805733210964874, 0.0],
              [-81.292324517471869, 35.805916277879177, 0.0],
              [-81.292043317117987, 35.805961878234008, 0.0],
              [-81.291453517520807, 35.805389011063781, 0.0],
              [-81.291033117167373, 35.804174877628107, 0.0],
              [-81.290499184839859, 35.803853877776767, 0.0],
              [-81.290527517071126, 35.803602077431187, 0.0],
              [-81.290949583954372, 35.803213010782173, 0.0],
              [-81.290893917042965, 35.802754811023604, 0.0],
              [-81.290247917274527, 35.802113211307379, 0.0],
              [-81.289826384191102, 35.80197547763283, 0.0],
              [-81.289404717887251, 35.802043877547639, 0.0],
              [-81.288335517835122, 35.80291341122593, 0.0],
              [-81.288194584129954, 35.803211011534664, 0.0],
              [-81.288221917564016, 35.804012611442054, 0.0],
              [-81.288558584734005, 35.804539610688799, 0.0],
              [-81.288529983666692, 35.805112077470348, 0.0],
              [-81.288810584071456, 35.805639011081716, 0.0],
              [-81.288810383715486, 35.805799277753728, 0.0],
              [-81.288388384233457, 35.806028077992217, 0.0],
              [-81.288219383783044, 35.806325611050958, 0.0],
              [-81.288219184619251, 35.806554611052, 0.0],
              [-81.28796611747795, 35.806623077392246, 0.0],
              [-81.28782571795179, 35.806485611404717, 0.0],
              [-81.287573318195129, 35.805890077910398, 0.0],
              [-81.287123984589087, 35.805340077331302, 0.0],
              [-81.286814917234452, 35.805179611533049, 0.0],
              [-81.286449317719061, 35.805248011157119, 0.0],
              [-81.28580211730727, 35.805888810802053, 0.0],
              [-81.285407783735536, 35.80652981130838, 0.0],
              [-81.285378517501087, 35.807629011476322, 0.0],
              [-81.285181117631438, 35.808201410879533, 0.0],
              [-81.284927984114873, 35.808224077716972, 0.0],
              [-81.284815718098429, 35.808109477697627, 0.0],
              [-81.284760717610951, 35.806895810990305, 0.0],
              [-81.284564117032232, 35.806712410946538, 0.0],
              [-81.284226717337432, 35.806735011532922, 0.0],
              [-81.282398183877518, 35.807764210924837, 0.0],
              [-81.281469584511726, 35.808473411216596, 0.0],
              [-81.278826317551292, 35.808929277466575, 0.0],
              [-81.277251917463488, 35.808928211081813, 0.0],
              [-81.275930584230665, 35.80881247732902, 0.0],
              [-81.27534038370888, 35.808674677667021, 0.0],
              [-81.275059184253237, 35.808743077762976, 0.0],
              [-81.275198918073116, 35.809430277724019, 0.0],
              [-81.275451584110456, 35.809705277660264, 0.0],
              [-81.275816917655717, 35.809797211422968, 0.0],
              [-81.275985517389771, 35.809957677342837, 0.0],
              [-81.276069184842015, 35.810461611232917, 0.0],
              [-81.276462383880755, 35.810828277767499, 0.0],
              [-81.276462118084964, 35.81101161118589, 0.0],
              [-81.275899984302669, 35.810919411366804, 0.0],
              [-81.275590917998699, 35.81075887793407, 0.0],
              [-81.275338184844159, 35.810461010628408, 0.0],
              [-81.274551717433411, 35.809910677291313, 0.0],
              [-81.274383184716015, 35.809681611237821, 0.0],
              [-81.274355517837193, 35.809406678137115, 0.0],
              [-81.273990584363204, 35.808925478063607, 0.0],
              [-81.273681584811769, 35.808742011489223, 0.0],
              [-81.273315984203919, 35.808787477533734, 0.0],
              [-81.27309091801861, 35.808970477831799, 0.0],
              [-81.273005784653961, 35.809520077797579, 0.0],
              [-81.27275271724308, 35.809565677670861, 0.0],
              [-81.272527983827871, 35.809428077348713, 0.0],
              [-81.272416517928164, 35.808649277350924, 0.0],
              [-81.271376318105624, 35.80860267741906, 0.0],
              [-81.271404784770766, 35.808327877433463, 0.0],
              [-81.272107783978257, 35.80809941145106, 0.0],
              [-81.272304917030965, 35.8079164108849, 0.0],
              [-81.272305183876867, 35.807733210646504, 0.0],
              [-81.270816384623203, 35.806724211076663, 0.0],
              [-81.269636317356486, 35.806219277375916, 0.0],
              [-81.269383317572562, 35.806219078038325, 0.0],
              [-81.269326784877379, 35.806448077369225, 0.0],
              [-81.269045584525017, 35.806493677653975, 0.0],
              [-81.268652183826006, 35.806310078099145, 0.0],
              [-81.267387184583555, 35.806217277419833, 0.0],
              [-81.267077783834438, 35.806400411201849, 0.0],
              [-81.267245117926507, 35.807385278110537, 0.0],
              [-81.267777584483866, 35.808622411294706, 0.0],
              [-81.267973717439219, 35.809149410900972, 0.0],
              [-81.267916517651813, 35.809859211375603, 0.0],
              [-81.267438184822424, 35.810110677504397, 0.0],
              [-81.267269583918306, 35.810064677804604, 0.0],
              [-81.267130183878791, 35.809171410918516, 0.0],
              [-81.266681383881675, 35.808415277824444, 0.0],
              [-81.266119184863797, 35.808391878097012, 0.0],
              [-81.266204118149844, 35.80802547736883, 0.0],
              [-81.266176384424114, 35.807636211311404, 0.0],
              [-81.265811317642232, 35.807361077610913, 0.0],
              [-81.26524938380922, 35.807108610937924, 0.0],
              [-81.264659584253621, 35.806673010757478, 0.0],
              [-81.263956584424335, 35.806672411155965, 0.0],
              [-81.263872184170395, 35.806809678196849, 0.0],
              [-81.263815117673388, 35.807473811377122, 0.0],
              [-81.26370251809179, 35.807542411337408, 0.0],
              [-81.263224584722821, 35.807496077530274, 0.0],
              [-81.262633984009497, 35.80756427819081, 0.0],
              [-81.262521384777756, 35.80772447791297, 0.0],
              [-81.262407717466118, 35.808686211545869, 0.0],
              [-81.262462917034028, 35.809419077713585, 0.0],
              [-81.262574983930946, 35.809694077599588, 0.0],
              [-81.262546383867104, 35.809968877306147, 0.0],
              [-81.261983917223134, 35.810082877825067, 0.0],
              [-81.261674717387919, 35.81003681137409, 0.0],
              [-81.261562517530876, 35.809922211051116, 0.0],
              [-81.261507118091018, 35.809395411059164, 0.0],
              [-81.26111391755876, 35.809028610686546, 0.0],
              [-81.260720317193375, 35.809051077739518, 0.0],
              [-81.260607584625319, 35.809188478176807, 0.0],
              [-81.260213983868113, 35.809119277672501, 0.0],
              [-81.260129784534215, 35.809027677740112, 0.0],
              [-81.260102517420805, 35.808432211450594, 0.0],
              [-81.259258717433482, 35.808706077760618, 0.0],
              [-81.258920984154557, 35.808957878067233, 0.0],
              [-81.258639384103432, 35.809301077313556, 0.0],
              [-81.257880117392872, 35.809414877322837, 0.0],
              [-81.256670518096556, 35.809803011414729, 0.0],
              [-81.255910918146967, 35.810214477993647, 0.0],
              [-81.255544918215364, 35.810511811176283, 0.0],
              [-81.255516517911914, 35.810763677963763, 0.0],
              [-81.255628784236421, 35.810832477692649, 0.0],
              [-81.256360117724483, 35.810695810627536, 0.0],
              [-81.257034717893077, 35.810696477527912, 0.0],
              [-81.259339517429041, 35.811271211016923, 0.0],
              [-81.259311117593271, 35.811454411114099, 0.0],
              [-81.257568117154051, 35.811361077524424, 0.0],
              [-81.257230517221743, 35.811452411379335, 0.0],
              [-81.257230183791279, 35.811727210628661, 0.0],
              [-81.257567183960148, 35.811933678098804, 0.0],
              [-81.257566917216593, 35.812139811378081, 0.0],
              [-81.255177317995276, 35.811931277614896, 0.0],
              [-81.254361918057427, 35.811976277778804, 0.0],
              [-81.254361384376097, 35.81231987737322, 0.0],
              [-81.25539958473091, 35.81383241132837, 0.0],
              [-81.255708517342569, 35.814061811378629, 0.0],
              [-81.258658784615008, 35.815324210647788, 0.0],
              [-81.258939718051877, 35.815576278049583, 0.0],
              [-81.258911317683825, 35.815736610914179, 0.0],
              [-81.258573917892264, 35.815713477935503, 0.0],
              [-81.258152517508393, 35.815461077356893, 0.0],
              [-81.257702784485772, 35.815369077697063, 0.0],
              [-81.256550117780336, 35.815276410738193, 0.0],
              [-81.255988117814795, 35.815069610779233, 0.0],
              [-81.255313384924804, 35.815046077976582, 0.0],
              [-81.255060384757357, 35.81497707821687, 0.0],
              [-81.254161717397409, 35.814312077811294, 0.0],
              [-81.254020984054847, 35.814334810807814, 0.0],
              [-81.254187784840767, 35.815663277710073, 0.0],
              [-81.254328118247017, 35.815846677924661, 0.0],
              [-81.25463711797903, 35.815984410837224, 0.0],
              [-81.254551717123434, 35.816717210824827, 0.0],
              [-81.254185784808044, 35.816923010966704, 0.0],
              [-81.254016717775258, 35.817266277889523, 0.0],
              [-81.254044184699552, 35.817609810829921, 0.0],
              [-81.254324184130525, 35.818365878172237, 0.0],
              [-81.254463784312676, 35.819076011356131, 0.0],
              [-81.2543217840864, 35.819991877957939, 0.0],
              [-81.254095184463083, 35.821113877431181, 0.0],
              [-81.254291917571493, 35.821274411473212, 0.0],
              [-81.254657317531709, 35.821343410581122, 0.0],
              [-81.255669784565441, 35.821252678172613, 0.0],
              [-81.255922984477422, 35.821161411356613, 0.0],
              [-81.2561759840181, 35.821184610879214, 0.0],
              [-81.256202717974759, 35.822146477244061, 0.0],
              [-81.256427317994195, 35.822330010974127, 0.0],
              [-81.256680517206973, 35.822284411107212, 0.0],
              [-81.256877784563827, 35.822009811176393, 0.0],
              [-81.25716138432675, 35.820338210937962, 0.0],
              [-81.257442918162212, 35.820109477738811, 0.0],
              [-81.257695917649997, 35.820109610911459, 0.0],
              [-81.257498317423483, 35.820659211206539, 0.0],
              [-81.257497917056128, 35.820934010856298, 0.0],
              [-81.257582184186361, 35.820979811466842, 0.0],
              [-81.258425984341017, 35.820843277587528, 0.0],
              [-81.260226784922082, 35.819997611393767, 0.0],
              [-81.26093071725272, 35.819311211298974, 0.0],
              [-81.261437317450415, 35.819036877805523, 0.0],
              [-81.26216851768713, 35.818854410748948, 0.0],
              [-81.262730784587546, 35.818900677761036, 0.0],
              [-81.263208718216575, 35.819038611168601, 0.0],
              [-81.263742917718105, 35.819084810672081, 0.0],
              [-81.264950717906018, 35.819979077656441, 0.0],
              [-81.265793984750388, 35.820186011007898, 0.0],
              [-81.266018718209352, 35.820346477601952, 0.0],
              [-81.266018517843193, 35.820552611451241, 0.0],
              [-81.26717058386059, 35.821149078125003, 0.0],
              [-81.267198383713364, 35.82137807789416, 0.0],
              [-81.266270317537547, 35.821514677762494, 0.0],
              [-81.265847784701876, 35.821995211387801, 0.0],
              [-81.265622984696208, 35.821926278152588, 0.0],
              [-81.265538783861942, 35.821811678042799, 0.0],
              [-81.265341917856659, 35.821834410616638, 0.0],
              [-81.26452498431334, 35.822887077984745, 0.0],
              [-81.264383718015281, 35.823413677914466, 0.0],
              [-81.263539917561147, 35.823527477573634, 0.0],
              [-81.263201917520462, 35.823962411426919, 0.0],
              [-81.262047784692797, 35.824808678104411, 0.0],
              [-81.261371584691247, 35.8257468781445, 0.0],
              [-81.261258517422704, 35.826204810711879, 0.0],
              [-81.261342183996476, 35.826617077270505, 0.0],
              [-81.262971517419246, 35.827878411064361, 0.0],
              [-81.263308784700612, 35.827993077254916, 0.0],
              [-81.26378691747675, 35.827947677447867, 0.0],
              [-81.263842317049807, 35.828612010648079, 0.0],
              [-81.263982717583559, 35.828726610751119, 0.0],
              [-81.264545117349627, 35.828727077302844, 0.0],
              [-81.264657517526985, 35.828818811129906, 0.0],
              [-81.264685317452205, 35.829025011118738, 0.0],
              [-81.264966184880137, 35.829300010627826, 0.0],
              [-81.265500383826975, 35.829437877829449, 0.0],
              [-81.265500183942208, 35.829598211366118, 0.0],
              [-81.265668718097373, 35.829758677284083, 0.0],
              [-81.26603411822839, 35.829850611372166, 0.0],
              [-81.266624783981442, 35.829759610653937, 0.0],
              [-81.267272117044797, 35.829462411046514, 0.0],
              [-81.269044317869074, 35.829005811389358, 0.0],
              [-81.269128784255841, 35.828914277375411, 0.0],
              [-81.269185317528894, 35.828685411403377, 0.0],
              [-81.269494584580698, 35.828685677715974, 0.0],
              [-81.269634984164156, 35.828914810671407, 0.0],
              [-81.270000584798154, 35.828869277790723, 0.0],
              [-81.270900917438212, 35.82850361104019, 0.0],
              [-81.271604583975602, 35.828023411112667, 0.0],
              [-81.272195384885819, 35.827840611275441, 0.0],
              [-81.272702117419044, 35.827451810624204, 0.0],
              [-81.272926918104659, 35.827589277251256, 0.0],
              [-81.27278578455747, 35.827932677608295, 0.0],
              [-81.272869783784614, 35.828276278197777, 0.0],
              [-81.273460117968895, 35.828437078172634, 0.0],
              [-81.273459784729582, 35.82862041063143, 0.0],
              [-81.273234518157722, 35.828872077247873, 0.0],
              [-81.273290518205869, 35.829146878010803, 0.0],
              [-81.273543184739054, 35.829444878135149, 0.0],
              [-81.273710984408126, 35.83013201067407, 0.0],
              [-81.273935917978775, 35.830292477948426, 0.0],
              [-81.274245183725739, 35.830292811283734, 0.0],
              [-81.274836117978325, 35.83011001130032, 0.0],
              [-81.275117584840274, 35.8298812774756, 0.0],
              [-81.275286584347498, 35.829606610924309, 0.0],
              [-81.275540517573489, 35.828828210848997, 0.0],
              [-81.275906384435828, 35.828622277602079, 0.0],
              [-81.276666117772393, 35.828439677814679, 0.0],
              [-81.277594517399066, 35.828005411349324, 0.0],
              [-81.278325583711037, 35.828051810794804, 0.0],
              [-81.278550784719755, 35.827868811118776, 0.0],
              [-81.278635517618795, 35.827662810653777, 0.0],
              [-81.279085318090083, 35.827731811041446, 0.0],
              [-81.279197583937062, 35.827869277855207, 0.0],
              [-81.279141118044578, 35.828075278005258, 0.0],
              [-81.278775317624223, 35.828349810625333, 0.0],
              [-81.278775117860235, 35.828487210792751, 0.0],
              [-81.279393184876767, 35.828945811186117, 0.0],
              [-81.27958958413457, 35.829335210812587, 0.0],
              [-81.280235784592563, 35.829793810666672, 0.0],
              [-81.280517117807875, 35.829702277474411, 0.0],
              [-81.280517317691135, 35.829610678088088, 0.0],
              [-81.280686183808598, 35.829473477604104, 0.0],
              [-81.281023517106462, 35.829588277603243, 0.0],
              [-81.281107717012929, 35.829725811099486, 0.0],
              [-81.281641784275976, 35.829886411314575, 0.0],
              [-81.281725984761749, 35.829978077484633, 0.0],
              [-81.281697784352545, 35.830138410889546, 0.0],
              [-81.28085411753402, 35.830206477658585, 0.0],
              [-81.280685118169004, 35.830366610798933, 0.0],
              [-81.28068478449002, 35.830641410819418, 0.0],
              [-81.28214511781762, 35.832566411213712, 0.0],
              [-81.282313117195486, 35.833139011270198, 0.0],
              [-81.282903184521672, 35.833597478100323, 0.0],
              [-81.283099984563137, 35.833643477285776, 0.0],
              [-81.283606318089809, 35.833552210970765, 0.0],
              [-81.284702984020441, 35.833621678077471, 0.0],
              [-81.285490183698585, 35.833828410820452, 0.0],
              [-81.286305784791267, 35.833829011437743, 0.0],
              [-81.286615117429605, 35.83389801060266, 0.0],
              [-81.286755517763254, 35.834012610701578, 0.0],
              [-81.286754917040582, 35.834585077408981, 0.0],
              [-81.286529784692078, 35.834745210866103, 0.0],
              [-81.286417317661545, 35.83474507785526, 0.0],
              [-81.286356183933208, 35.834706010561938, 0.0],
              [-81.286278983709096, 35.834656477705209, 0.0],
              [-81.286023784163987, 35.834493010788385, 0.0],
              [-81.285517717410144, 35.83440101092954, 0.0],
              [-81.2835489171527, 35.834514010789924, 0.0],
              [-81.282367917328614, 35.83437567759669, 0.0],
              [-81.282030584704245, 35.834238077417425, 0.0],
              [-81.281440784843085, 35.833527610617544, 0.0],
              [-81.281328584662361, 35.833321411027228, 0.0],
              [-81.280373517790395, 35.832290077743487, 0.0],
              [-81.279726783893935, 35.832198078030977, 0.0],
              [-81.279615184716889, 35.831419211330619, 0.0],
              [-81.279362517950645, 35.831075611176239, 0.0],
              [-81.279053318108907, 35.83096081135001, 0.0],
              [-81.278378384234784, 35.830937411465484, 0.0],
              [-81.278181784657193, 35.830776877483906, 0.0],
              [-81.278041384087516, 35.830524877333865, 0.0],
              [-81.278041717499306, 35.830295877243294, 0.0],
              [-81.277957384403948, 35.830250010849291, 0.0],
              [-81.277817317460773, 35.829791811021394, 0.0],
              [-81.277620718097666, 35.829654278147487, 0.0],
              [-81.277254917648435, 35.829791411484315, 0.0],
              [-81.276437917956841, 35.831027410673407, 0.0],
              [-81.276071917156017, 35.831324878047973, 0.0],
              [-81.274609117111027, 35.8316672112256, 0.0],
              [-81.273484583979865, 35.831437277365922, 0.0],
              [-81.272894517060095, 35.831001610976926, 0.0],
              [-81.272388917484449, 35.830382878137335, 0.0],
              [-81.272136718056444, 35.829810077693566, 0.0],
              [-81.27165911820893, 35.829351677777474, 0.0],
              [-81.271405983861868, 35.829374277581351, 0.0],
              [-81.271180784689832, 35.82962601095231, 0.0],
              [-81.271095584834669, 35.830221478038332, 0.0],
              [-81.270842384633781, 35.830244077838522, 0.0],
              [-81.2704773181013, 35.82994621062624, 0.0],
              [-81.268846117850899, 35.8300134106779, 0.0],
              [-81.268199117402403, 35.830150277608134, 0.0],
              [-81.268114517854968, 35.830356277484029, 0.0],
              [-81.268029184638181, 35.831020277531174, 0.0],
              [-81.26822571731131, 35.831318210903945, 0.0],
              [-81.26825311737305, 35.831799211394632, 0.0],
              [-81.268533717680711, 35.832326210846233, 0.0],
              [-81.268983183999282, 35.832738810866658, 0.0],
              [-81.268926584608536, 35.832944810837482, 0.0],
              [-81.268279384394731, 35.833219077217215, 0.0],
              [-81.267857184020144, 35.833539278112156, 0.0],
              [-81.267603317161303, 35.834065878207213, 0.0],
              [-81.267489384632611, 35.835210810637648, 0.0],
              [-81.267011184590828, 35.835324877824227, 0.0],
              [-81.266391784390635, 35.835851077859104, 0.0],
              [-81.266054317841551, 35.835827877571781, 0.0],
              [-81.266077584056902, 35.835668011446785, 0.0],
              [-81.266110917204188, 35.835438611050002, 0.0],
              [-81.267041183955328, 35.833813477494573, 0.0],
              [-81.26802698420461, 35.832737878096168, 0.0],
              [-81.268027384690924, 35.832371477639008, 0.0],
              [-81.267437317272211, 35.832050411249469, 0.0],
              [-81.26743871809164, 35.830974011515153, 0.0],
              [-81.267298384213106, 35.830744877978979, 0.0],
              [-81.264739984442102, 35.830238810844946, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1596,
        ComID: 9751286,
        FDate: "1999/10/08",
        Resolution: 3,
        GNIS_ID: "01012434",
        GNIS_Name: "Lake James",
        AreaSqKm: 24.804,
        Elevation: 366.0,
        ReachCode: "03050101002294",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 1.91458583188,
        Shape_Area: 0.00247160876,
        Reservoir: 52,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.873868983514456, 35.794905477878146, 0.0],
              [-81.8730539170474, 35.793600277716315, 0.0],
              [-81.872238716219144, 35.793004811102854, 0.0],
              [-81.871423583278997, 35.792707210835708, 0.0],
              [-81.870046316638351, 35.79254681086929, 0.0],
              [-81.869146916267567, 35.792684211531395, 0.0],
              [-81.868303717003144, 35.792981877598812, 0.0],
              [-81.867544783566458, 35.793394078241015, 0.0],
              [-81.867376183656177, 35.793646011130171, 0.0],
              [-81.866898316865019, 35.793943611001204, 0.0],
              [-81.86639238392226, 35.793989477378958, 0.0],
              [-81.865970716647098, 35.793645878034091, 0.0],
              [-81.866251783360298, 35.793623011089089, 0.0],
              [-81.866757716154211, 35.793256611085837, 0.0],
              [-81.866813916501513, 35.793073478035566, 0.0],
              [-81.866364316228058, 35.792592477778726, 0.0],
              [-81.866251916266549, 35.791836811500978, 0.0],
              [-81.865970983315478, 35.791356011309169, 0.0],
              [-81.864959183989896, 35.790874878241503, 0.0],
              [-81.864706117209579, 35.790508477598301, 0.0],
              [-81.864706182845026, 35.789981677734524, 0.0],
              [-81.864959183540194, 35.78940927744096, 0.0],
              [-81.864987316655899, 35.788997077332574, 0.0],
              [-81.864706316830791, 35.788607810652174, 0.0],
              [-81.864678316148996, 35.788355877565706, 0.0],
              [-81.865858716330408, 35.788378877484234, 0.0],
              [-81.865999182791697, 35.788218477882957, 0.0],
              [-81.866055516617735, 35.78780627782826, 0.0],
              [-81.865886916593169, 35.787371278210458, 0.0],
              [-81.865662116311142, 35.787119278068666, 0.0],
              [-81.865127983468113, 35.787096277929308, 0.0],
              [-81.863553983480642, 35.787325278167074, 0.0],
              [-81.862992117162506, 35.786958810918463, 0.0],
              [-81.860968517190955, 35.786661010789878, 0.0],
              [-81.860687583573366, 35.786523610654747, 0.0],
              [-81.860743783562597, 35.786088410736639, 0.0],
              [-81.861362183300145, 35.785309811557596, 0.0],
              [-81.861362183331408, 35.784783211206076, 0.0],
              [-81.860996916597628, 35.784393810840498, 0.0],
              [-81.859760516229827, 35.783889877977067, 0.0],
              [-81.858608183761504, 35.783134010957959, 0.0],
              [-81.85793371712731, 35.78304241088447, 0.0],
              [-81.857624583425391, 35.783156811465723, 0.0],
              [-81.857090582910359, 35.783614810959499, 0.0],
              [-81.856893716584906, 35.784393411501348, 0.0],
              [-81.857174383101309, 35.786133810838479, 0.0],
              [-81.857118116499791, 35.786431477645415, 0.0],
              [-81.85610618356074, 35.787118410943144, 0.0],
              [-81.855937516362559, 35.787416077680099, 0.0],
              [-81.855599982880534, 35.788469611307143, 0.0],
              [-81.855543716241428, 35.789339678104078, 0.0],
              [-81.855262583864814, 35.789408278159193, 0.0],
              [-81.854953517303358, 35.789293810755439, 0.0],
              [-81.854897183905649, 35.789087677467037, 0.0],
              [-81.855347183683193, 35.787919811561942, 0.0],
              [-81.85540371689018, 35.786660277824083, 0.0],
              [-81.855684917255161, 35.785240478050397, 0.0],
              [-81.855179183234611, 35.785057211375033, 0.0],
              [-81.855179183550732, 35.784874011597076, 0.0],
              [-81.855572717112096, 35.784713811075399, 0.0],
              [-81.855741383595998, 35.784416211601666, 0.0],
              [-81.855657116832589, 35.784278678050811, 0.0],
              [-81.855657117208665, 35.78386647788669, 0.0],
              [-81.856247583752292, 35.782721477953352, 0.0],
              [-81.857624916624388, 35.781508078119693, 0.0],
              [-81.857737383764459, 35.781324878008334, 0.0],
              [-81.857821916510659, 35.780156877624634, 0.0],
              [-81.857456517231242, 35.77976761117602, 0.0],
              [-81.856220116968217, 35.779767411153699, 0.0],
              [-81.855517517130991, 35.779950411517575, 0.0],
              [-81.85259431690848, 35.781827877686766, 0.0],
              [-81.852453782935484, 35.7817362780347, 0.0],
              [-81.852397916885792, 35.780659877332369, 0.0],
              [-81.852482316482082, 35.780591211168478, 0.0],
              [-81.85335351730933, 35.780568477535475, 0.0],
              [-81.853971716642548, 35.780293811125453, 0.0],
              [-81.854786916913909, 35.779744278184609, 0.0],
              [-81.855208517100465, 35.779355077728184, 0.0],
              [-81.855489716863602, 35.778851277384184, 0.0],
              [-81.855489582897206, 35.778278811322195, 0.0],
              [-81.855180716290079, 35.777820677435741, 0.0],
              [-81.854899583174301, 35.777614610805259, 0.0],
              [-81.853606982855368, 35.777683077876162, 0.0],
              [-81.853494582897426, 35.77761427806724, 0.0],
              [-81.853550983184547, 35.776721278159947, 0.0],
              [-81.853410582901816, 35.776377811041975, 0.0],
              [-81.852932983663251, 35.775828077352863, 0.0],
              [-81.851752982833133, 35.774637010749352, 0.0],
              [-81.851387716351084, 35.774614011454013, 0.0],
              [-81.851162916497557, 35.774820077613782, 0.0],
              [-81.851134582820904, 35.775392678162802, 0.0],
              [-81.851246917243856, 35.775873611322531, 0.0],
              [-81.850909583443965, 35.775965077543738, 0.0],
              [-81.850797316148189, 35.7755986112915, 0.0],
              [-81.85040411724664, 35.77509481066334, 0.0],
              [-81.850066916558404, 35.775071877791575, 0.0],
              [-81.849448583563458, 35.775438077309261, 0.0],
              [-81.849167317213315, 35.776056410860626, 0.0],
              [-81.847930383662643, 35.777567477802727, 0.0],
              [-81.847761583954892, 35.778071277494099, 0.0],
              [-81.847536717091899, 35.778162811107059, 0.0],
              [-81.847452516972808, 35.778117077649391, 0.0],
              [-81.847452583426175, 35.777704878060753, 0.0],
              [-81.847846316388228, 35.776880611124213, 0.0],
              [-81.847986983040471, 35.776491211226812, 0.0],
              [-81.847762317104525, 35.776239277679146, 0.0],
              [-81.84773411716462, 35.776101877444162, 0.0],
              [-81.848436917035016, 35.775094411185194, 0.0],
              [-81.848465183594158, 35.774476077851489, 0.0],
              [-81.84826858393339, 35.774224211082284, 0.0],
              [-81.847678516155611, 35.774224010671233, 0.0],
              [-81.846807183702524, 35.774750478179747, 0.0],
              [-81.8457953839563, 35.775162610822612, 0.0],
              [-81.845177116500466, 35.775528811319624, 0.0],
              [-81.8447273839746, 35.775918011310281, 0.0],
              [-81.843097116784762, 35.776994010719228, 0.0],
              [-81.842844117312453, 35.777062611269876, 0.0],
              [-81.842282316551646, 35.776489877785004, 0.0],
              [-81.842282383455426, 35.776260878200247, 0.0],
              [-81.843350316713725, 35.776169611413465, 0.0],
              [-81.843940716411907, 35.775597211522133, 0.0],
              [-81.843996917303357, 35.775299478222884, 0.0],
              [-81.843491317043942, 35.774978811407763, 0.0],
              [-81.842704383268554, 35.774703811492806, 0.0],
              [-81.842226916642574, 35.774360211280481, 0.0],
              [-81.842198916946259, 35.774176877510314, 0.0],
              [-81.842311316484043, 35.774085477774399, 0.0],
              [-81.842788983760457, 35.774154211169666, 0.0],
              [-81.843378917088501, 35.774566477534393, 0.0],
              [-81.84464338335755, 35.775047811102567, 0.0],
              [-81.845064783826174, 35.775162410961606, 0.0],
              [-81.845261583650583, 35.774979211183187, 0.0],
              [-81.845430316274886, 35.774567077354064, 0.0],
              [-81.846076716503333, 35.774452677980619, 0.0],
              [-81.846667117279523, 35.773605477614772, 0.0],
              [-81.846695316242844, 35.77330781096672, 0.0],
              [-81.846442383215091, 35.773216210866721, 0.0],
              [-81.846077117281752, 35.772918411596606, 0.0],
              [-81.845992916196053, 35.772712277783192, 0.0],
              [-81.846077183192776, 35.772643611016001, 0.0],
              [-81.846414383686721, 35.772643678124126, 0.0],
              [-81.847650783952844, 35.773056211360043, 0.0],
              [-81.84787571703292, 35.772895810901375, 0.0],
              [-81.847988182900011, 35.772506477701349, 0.0],
              [-81.847903916316042, 35.772163011138474, 0.0],
              [-81.847622916969243, 35.771911078186925, 0.0],
              [-81.846751983219974, 35.771590277689356, 0.0],
              [-81.846105916707472, 35.771017611440449, 0.0],
              [-81.84540358378122, 35.770742610945049, 0.0],
              [-81.845038316973259, 35.770536477821828, 0.0],
              [-81.845038383880208, 35.770353278130322, 0.0],
              [-81.845797117069409, 35.770261810749957, 0.0],
              [-81.847763716734988, 35.771155411415862, 0.0],
              [-81.848269383524467, 35.771292877711197, 0.0],
              [-81.849196783839247, 35.771293077654626, 0.0],
              [-81.849618183489298, 35.771224478098311, 0.0],
              [-81.849899316738828, 35.771041278295357, 0.0],
              [-81.849899383009401, 35.770766478219855, 0.0],
              [-81.849759116726617, 35.770079477603247, 0.0],
              [-81.849927916605921, 35.76964441154346, 0.0],
              [-81.850405716306796, 35.768934610838237, 0.0],
              [-81.850714916571221, 35.768682611418086, 0.0],
              [-81.85189518289701, 35.768202011058186, 0.0],
              [-81.852204316306043, 35.767950211497428, 0.0],
              [-81.852344917006192, 35.767698278168119, 0.0],
              [-81.852401116688043, 35.767400611445737, 0.0],
              [-81.85189538293622, 35.767148611140932, 0.0],
              [-81.851052583556438, 35.767125478141772, 0.0],
              [-81.850883783880789, 35.76701101069775, 0.0],
              [-81.850883983366188, 35.766530077482287, 0.0],
              [-81.850771582861313, 35.7664156108752, 0.0],
              [-81.85051878393719, 35.766369811022741, 0.0],
              [-81.848916717002268, 35.767926611023256, 0.0],
              [-81.848523316816767, 35.768132677835062, 0.0],
              [-81.848438983707993, 35.768064011438376, 0.0],
              [-81.848607783344917, 35.767354011096714, 0.0],
              [-81.849169916141207, 35.766781677626291, 0.0],
              [-81.849226316270105, 35.766415278279304, 0.0],
              [-81.849001583294609, 35.766117477622906, 0.0],
              [-81.848917382970612, 35.765751210882584, 0.0],
              [-81.848692783943903, 35.765476278229755, 0.0],
              [-81.848720916889619, 35.765155677755835, 0.0],
              [-81.849170516337296, 35.764995410943499, 0.0],
              [-81.849507716177385, 35.764697810844197, 0.0],
              [-81.849479716938305, 35.764010811615094, 0.0],
              [-81.848580583759286, 35.764125077880841, 0.0],
              [-81.848383983638044, 35.764010477821252, 0.0],
              [-81.847990582854607, 35.763987611395549, 0.0],
              [-81.847484917278223, 35.764193611029086, 0.0],
              [-81.847400516631325, 35.764330877683925, 0.0],
              [-81.847428382938375, 35.765040878033687, 0.0],
              [-81.846809983527493, 35.766002611581555, 0.0],
              [-81.846472716626508, 35.76609401081231, 0.0],
              [-81.846304183107748, 35.765956678032452, 0.0],
              [-81.846191983575125, 35.765361211416639, 0.0],
              [-81.846445116120492, 35.764674277453956, 0.0],
              [-81.846388982808477, 35.764514011420097, 0.0],
              [-81.846079916425481, 35.764468077964935, 0.0],
              [-81.845602317102049, 35.764628278103068, 0.0],
              [-81.845545983273325, 35.76446801146966, 0.0],
              [-81.845742917279694, 35.764055811298128, 0.0],
              [-81.845827316158577, 35.76359781121424, 0.0],
              [-81.846192783039541, 35.762933810788617, 0.0],
              [-81.846220916719403, 35.762567277931581, 0.0],
              [-81.84602438398457, 35.762338277551372, 0.0],
              [-81.845715316755758, 35.762315411449855, 0.0],
              [-81.845068917047683, 35.76300221096367, 0.0],
              [-81.844450716304252, 35.763116610962456, 0.0],
              [-81.843944783539357, 35.763505678229798, 0.0],
              [-81.8436075165733, 35.763986610719229, 0.0],
              [-81.843410716662063, 35.76449027793236, 0.0],
              [-81.84341038326275, 35.765360678306926, 0.0],
              [-81.843887583089568, 35.766482877809551, 0.0],
              [-81.84399978384954, 35.767421677386395, 0.0],
              [-81.843915383460086, 35.767604877994607, 0.0],
              [-81.843662516724535, 35.767673611283328, 0.0],
              [-81.843465716287355, 35.767604811556971, 0.0],
              [-81.843269516556603, 35.766368210840589, 0.0],
              [-81.842764183520472, 35.765314677900157, 0.0],
              [-81.842623916729082, 35.764536011117684, 0.0],
              [-81.842258783805249, 35.76421521155391, 0.0],
              [-81.842343116491151, 35.764032077624904, 0.0],
              [-81.842736516906371, 35.763803211027479, 0.0],
              [-81.843383116250237, 35.763047611541573, 0.0],
              [-81.843383182955975, 35.762612610772564, 0.0],
              [-81.843158516374984, 35.762314810998298, 0.0],
              [-81.843242916542621, 35.762039878026592, 0.0],
              [-81.844395116203629, 35.761651011634768, 0.0],
              [-81.844591783110801, 35.761467678321601, 0.0],
              [-81.844591917092103, 35.761215878210983, 0.0],
              [-81.84383331639927, 35.761215677703518, 0.0],
              [-81.842540782948788, 35.761467210849737, 0.0],
              [-81.841922516791271, 35.761764878209455, 0.0],
              [-81.841557316764039, 35.761558677392628, 0.0],
              [-81.84133278337994, 35.761054677741235, 0.0],
              [-81.840995716611019, 35.760734078156652, 0.0],
              [-81.84082718328041, 35.760390477388206, 0.0],
              [-81.840546517286072, 35.760115610670375, 0.0],
              [-81.840181183509657, 35.760001011200288, 0.0],
              [-81.839815983978426, 35.76000087745642, 0.0],
              [-81.839647383599939, 35.760115277794675, 0.0],
              [-81.83953498279206, 35.760321477397717, 0.0],
              [-81.839590583907082, 35.761626810981568, 0.0],
              [-81.839955716270609, 35.761947478170065, 0.0],
              [-81.840011716247716, 35.762153677953854, 0.0],
              [-81.8395341170488, 35.762313811029955, 0.0],
              [-81.839084583743698, 35.762084677774652, 0.0],
              [-81.838831783849969, 35.762061677399672, 0.0],
              [-81.838634983380345, 35.762222011108847, 0.0],
              [-81.838662783954618, 35.762863211199068, 0.0],
              [-81.838971783899055, 35.763344210792759, 0.0],
              [-81.83908391680292, 35.764031210764834, 0.0],
              [-81.839926383191752, 35.764924611223307, 0.0],
              [-81.840010583695985, 35.765130611052392, 0.0],
              [-81.83958911689038, 35.76517627738658, 0.0],
              [-81.839027317168672, 35.764809811089222, 0.0],
              [-81.838718183915262, 35.764786811178411, 0.0],
              [-81.83849338314873, 35.764878278230903, 0.0],
              [-81.838324783739324, 35.765061610908866, 0.0],
              [-81.838296317183818, 35.765931810961135, 0.0],
              [-81.838464516748815, 35.766802011601897, 0.0],
              [-81.838801383835857, 35.767626478208236, 0.0],
              [-81.840683383467947, 35.769184277389442, 0.0],
              [-81.840711316671133, 35.769504877491784, 0.0],
              [-81.840345983513714, 35.76952767828223, 0.0],
              [-81.839334783238442, 35.768611478076984, 0.0],
              [-81.83896958301095, 35.768451011638206, 0.0],
              [-81.838238983330569, 35.768382077473696, 0.0],
              [-81.837957916258617, 35.768771410811382, 0.0],
              [-81.837676783167836, 35.76890867768558, 0.0],
              [-81.837508383909025, 35.768817010976768, 0.0],
              [-81.837480316933878, 35.768588011421151, 0.0],
              [-81.837761583204582, 35.767901078161081, 0.0],
              [-81.837733783332297, 35.767397211343074, 0.0],
              [-81.837480983753153, 35.767099410939402, 0.0],
              [-81.837143717247884, 35.76707641107744, 0.0],
              [-81.836469117224638, 35.767580077726784, 0.0],
              [-81.835963116545713, 35.768152410752926, 0.0],
              [-81.835822383975426, 35.768816411406469, 0.0],
              [-81.835597583202684, 35.768816410970686, 0.0],
              [-81.835148116980321, 35.768449810929823, 0.0],
              [-81.83506391721059, 35.768243677885131, 0.0],
              [-81.835120383920213, 35.767739878005088, 0.0],
              [-81.835288983519305, 35.767556810748594, 0.0],
              [-81.836047783486976, 35.7671676774901, 0.0],
              [-81.836244782893957, 35.766778477893162, 0.0],
              [-81.837087983961297, 35.766114610859042, 0.0],
              [-81.83708811670968, 35.765771077515474, 0.0],
              [-81.836751116591884, 35.765450410774989, 0.0],
              [-81.836723582883337, 35.764190877461793, 0.0],
              [-81.836836116334212, 35.763939011619456, 0.0],
              [-81.8374825167122, 35.763343678248567, 0.0],
              [-81.837454516230096, 35.763183411122263, 0.0],
              [-81.837257916263866, 35.763000211121863, 0.0],
              [-81.836864517180558, 35.763091677962343, 0.0],
              [-81.836246183670056, 35.763503611168986, 0.0],
              [-81.83464378309165, 35.765266610890237, 0.0],
              [-81.834109716583654, 35.765770211377223, 0.0],
              [-81.833716316806616, 35.765907410786198, 0.0],
              [-81.832845116923949, 35.766044611313362, 0.0],
              [-81.832817183671196, 35.765861411083186, 0.0],
              [-81.834222916911202, 35.76416721135557, 0.0],
              [-81.835066383605266, 35.76302227738919, 0.0],
              [-81.835094717340439, 35.762678877770192, 0.0],
              [-81.834954183546685, 35.762633077846459, 0.0],
              [-81.833745983641194, 35.762724278198696, 0.0],
              [-81.833745983993481, 35.76249521161472, 0.0],
              [-81.834476717279443, 35.762358078025741, 0.0],
              [-81.83509498361795, 35.761831611499161, 0.0],
              [-81.835095116712054, 35.761419411631586, 0.0],
              [-81.834954783767444, 35.76121321148765, 0.0],
              [-81.834954916423527, 35.760961277883339, 0.0],
              [-81.835404516349513, 35.760938611381384, 0.0],
              [-81.835797917221001, 35.76075541069244, 0.0],
              [-81.836303916673828, 35.76034341145867, 0.0],
              [-81.836303983492883, 35.759977011377863, 0.0],
              [-81.836135517087726, 35.759702211385651, 0.0],
              [-81.835826583002714, 35.759450077730655, 0.0],
              [-81.835489517243232, 35.759358478017688, 0.0],
              [-81.834646716633998, 35.759358210719299, 0.0],
              [-81.834393916660247, 35.759037478063689, 0.0],
              [-81.834393983172959, 35.758785610997535, 0.0],
              [-81.835096582984875, 35.758625611548553, 0.0],
              [-81.835236982928976, 35.75832787751164, 0.0],
              [-81.835574382838388, 35.757961611358404, 0.0],
              [-81.836164382836287, 35.758053277950289, 0.0],
              [-81.836642183932398, 35.757755811150183, 0.0],
              [-81.83689538349708, 35.756885610779257, 0.0],
              [-81.837457316869035, 35.756748411632593, 0.0],
              [-81.837625983644728, 35.756588077724231, 0.0],
              [-81.837626116378061, 35.756244677488638, 0.0],
              [-81.837373316932627, 35.756107211384503, 0.0],
              [-81.836783317092397, 35.756061211374181, 0.0],
              [-81.835940383446058, 35.756335810912184, 0.0],
              [-81.83571571663775, 35.756175278296361, 0.0],
              [-81.83540691723806, 35.755740211432084, 0.0],
              [-81.835407116287712, 35.755465411026854, 0.0],
              [-81.835547583457512, 35.755236411065709, 0.0],
              [-81.836334383738517, 35.755076278084957, 0.0],
              [-81.836615383537534, 35.75482447762959, 0.0],
              [-81.836671716742515, 35.754618411315725, 0.0],
              [-81.837205717341277, 35.754114677829264, 0.0],
              [-81.837261916216647, 35.75384001098886, 0.0],
              [-81.836250982981753, 35.753038211301821, 0.0],
              [-81.835688983813867, 35.753060877870638, 0.0],
              [-81.835183183440307, 35.753381277491037, 0.0],
              [-81.835182916385492, 35.754045410688086, 0.0],
              [-81.834929983727562, 35.75427427771956, 0.0],
              [-81.834592783571466, 35.754365811438241, 0.0],
              [-81.834227383049054, 35.754617678127204, 0.0],
              [-81.834227116569878, 35.755213077396341, 0.0],
              [-81.834929116514658, 35.756014810942652, 0.0],
              [-81.834984783592219, 35.757091078041654, 0.0],
              [-81.834787983617048, 35.757320011023722, 0.0],
              [-81.834563183388781, 35.757411677664493, 0.0],
              [-81.834310516875945, 35.757251278224835, 0.0],
              [-81.834142117341727, 35.756930477993805, 0.0],
              [-81.833439983653577, 35.756380677999068, 0.0],
              [-81.832849983347558, 35.756266011394743, 0.0],
              [-81.832625116246149, 35.756426278219088, 0.0],
              [-81.832596983439316, 35.756838477540953, 0.0],
              [-81.832905583309781, 35.757548477497373, 0.0],
              [-81.832933583839534, 35.757777478103378, 0.0],
              [-81.832512116272582, 35.757846011274076, 0.0],
              [-81.831922317075723, 35.757548211155523, 0.0],
              [-81.831585183493814, 35.757525078239134, 0.0],
              [-81.831556382972408, 35.758738877659383, 0.0],
              [-81.832230384017009, 35.759334478038454, 0.0],
              [-81.832511116755938, 35.75972401102797, 0.0],
              [-81.832623317004177, 35.760204810867485, 0.0],
              [-81.832398516168865, 35.760204811006894, 0.0],
              [-81.831078583081535, 35.759173811179679, 0.0],
              [-81.830685183667597, 35.759173678184631, 0.0],
              [-81.829280117314923, 35.759928877679656, 0.0],
              [-81.829279783634163, 35.76029541151582, 0.0],
              [-81.829475916509153, 35.761463277485255, 0.0],
              [-81.829222916968121, 35.761646411268444, 0.0],
              [-81.828323916188168, 35.761600277730786, 0.0],
              [-81.827986583765224, 35.761737478118782, 0.0],
              [-81.827592917004509, 35.762287078260101, 0.0],
              [-81.827620983144882, 35.762561878310187, 0.0],
              [-81.827873716357757, 35.762790877989282, 0.0],
              [-81.828295116526192, 35.762974277335111, 0.0],
              [-81.828294917359571, 35.76308881160017, 0.0],
              [-81.827901316362713, 35.763523811194439, 0.0],
              [-81.827788782982466, 35.763798477583727, 0.0],
              [-81.82778851720802, 35.764233611114584, 0.0],
              [-81.827956983375685, 35.764394010665363, 0.0],
              [-81.829727183875022, 35.764486277566931, 0.0],
              [-81.830008117345173, 35.764646677872797, 0.0],
              [-81.830007916173599, 35.764967277483002, 0.0],
              [-81.830148316520251, 35.765196277948228, 0.0],
              [-81.830513383561012, 35.765448277442452, 0.0],
              [-81.830541383900254, 35.765631610754966, 0.0],
              [-81.830176116829648, 35.765700077635117, 0.0],
              [-81.830091782885518, 35.76563141076538, 0.0],
              [-81.829754716657789, 35.765608411627689, 0.0],
              [-81.82938931726909, 35.765814477730295, 0.0],
              [-81.8290799837973, 35.766249411402491, 0.0],
              [-81.829051383643645, 35.767028010997862, 0.0],
              [-81.82896711685072, 35.767142478105185, 0.0],
              [-81.828966917324948, 35.767600477384704, 0.0],
              [-81.829584716262474, 35.768173211567699, 0.0],
              [-81.830174916593904, 35.768127611277194, 0.0],
              [-81.830259116205852, 35.76819641148429, 0.0],
              [-81.830287116959084, 35.768494077809969, 0.0],
              [-81.830090182899838, 35.76881461113696, 0.0],
              [-81.829977383131052, 35.76938707826114, 0.0],
              [-81.830145983081678, 35.769639077362712, 0.0],
              [-81.830792183093322, 35.769776677868187, 0.0],
              [-81.83157871676363, 35.770051810778789, 0.0],
              [-81.831831516308569, 35.770280877928371, 0.0],
              [-81.831887583357386, 35.770761810973639, 0.0],
              [-81.831241183301501, 35.770830277836353, 0.0],
              [-81.830342117136169, 35.770601011445379, 0.0],
              [-81.82986438372734, 35.770623678117012, 0.0],
              [-81.829273516403759, 35.772226477331479, 0.0],
              [-81.829048716802276, 35.772295211366163, 0.0],
              [-81.828346117354698, 35.77224907786627, 0.0],
              [-81.828318116676044, 35.772043011154835, 0.0],
              [-81.828543116754091, 35.771699478041327, 0.0],
              [-81.828711916170732, 35.771241611321152, 0.0],
              [-81.828712317028746, 35.770669011465188, 0.0],
              [-81.828403383219211, 35.770371211108824, 0.0],
              [-81.828319316402826, 35.770027677779296, 0.0],
              [-81.828431783783884, 35.769798678255007, 0.0],
              [-81.82846018307032, 35.769317811077144, 0.0],
              [-81.828291782968606, 35.768928478167723, 0.0],
              [-81.828123183145408, 35.768768078237024, 0.0],
              [-81.827898383100319, 35.768768011274439, 0.0],
              [-81.826071183509313, 35.769935277376852, 0.0],
              [-81.824975116208307, 35.770461611594364, 0.0],
              [-81.824188183467811, 35.770644410709771, 0.0],
              [-81.824328916253094, 35.770346811404586, 0.0],
              [-81.825481383877531, 35.769774811032228, 0.0],
              [-81.825734316527686, 35.769522877882252, 0.0],
              [-81.82646518375779, 35.769133810797818, 0.0],
              [-81.826521517285144, 35.768950677746737, 0.0],
              [-81.826015783794745, 35.768652810944701, 0.0],
              [-81.826100383840384, 35.768378011630261, 0.0],
              [-81.826943382948002, 35.768195078139598, 0.0],
              [-81.827533716245881, 35.767783211317685, 0.0],
              [-81.828180517226897, 35.76682161103983, 0.0],
              [-81.828236917331566, 35.766432278059526, 0.0],
              [-81.827590783602247, 35.766225877634703, 0.0],
              [-81.827928182888968, 35.765699410885944, 0.0],
              [-81.827928383600764, 35.765355877819459, 0.0],
              [-81.827731783962832, 35.765081010886249, 0.0],
              [-81.827226316242161, 35.764897611375382, 0.0],
              [-81.826523783376643, 35.764897277449542, 0.0],
              [-81.825764983121033, 35.765194677386205, 0.0],
              [-81.825652516892504, 35.765446478192082, 0.0],
              [-81.825680183895628, 35.76595027736218, 0.0],
              [-81.825483517309095, 35.766156410684303, 0.0],
              [-81.825230516258273, 35.766225010898111, 0.0],
              [-81.824781183086799, 35.765766811441608, 0.0],
              [-81.824556517067279, 35.765743811551744, 0.0],
              [-81.824078583446479, 35.766041410728938, 0.0],
              [-81.823122716783374, 35.767048611254303, 0.0],
              [-81.823066316312719, 35.767231811357085, 0.0],
              [-81.822925716197034, 35.76746067742009, 0.0],
              [-81.822728583910816, 35.768124811475147, 0.0],
              [-81.822447516827978, 35.768262077663898, 0.0],
              [-81.821773316495083, 35.768009878019605, 0.0],
              [-81.821267583989183, 35.767986811161265, 0.0],
              [-81.820649182956075, 35.768238410941947, 0.0],
              [-81.820227716279362, 35.768306878134929, 0.0],
              [-81.820199783579298, 35.768032011177517, 0.0],
              [-81.821549116707189, 35.767116677597663, 0.0],
              [-81.821661716420778, 35.766796077903372, 0.0],
              [-81.82166198333104, 35.766383878186311, 0.0],
              [-81.821409383663593, 35.766109011348135, 0.0],
              [-81.820369583989887, 35.76601687739953, 0.0],
              [-81.820369717082329, 35.765810811628768, 0.0],
              [-81.82067891616876, 35.765650611064999, 0.0],
              [-81.821521916187919, 35.765605211110511, 0.0],
              [-81.822224383452678, 35.765697077441651, 0.0],
              [-81.822533583066345, 35.765628610806331, 0.0],
              [-81.823686116691917, 35.764438210850123, 0.0],
              [-81.823995516436156, 35.763911610959816, 0.0],
              [-81.824052316165904, 35.762949810747955, 0.0],
              [-81.823715383850569, 35.762674878296906, 0.0],
              [-81.822956783882574, 35.762674611573587, 0.0],
              [-81.822169916311537, 35.762926078019561, 0.0],
              [-81.822085917187295, 35.762651277772235, 0.0],
              [-81.821945516502907, 35.762445077840589, 0.0],
              [-81.821945583806041, 35.762239011288571, 0.0],
              [-81.822479583155953, 35.761827010788544, 0.0],
              [-81.822479917309863, 35.761437677351431, 0.0],
              [-81.822732783379195, 35.761300277473374, 0.0],
              [-81.822985584000094, 35.761483677491285, 0.0],
              [-81.823603783971578, 35.761575477622578, 0.0],
              [-81.823996916734359, 35.761873411436248, 0.0],
              [-81.824305917311534, 35.761965077996372, 0.0],
              [-81.824896116513173, 35.761690610859453, 0.0],
              [-81.825851783007309, 35.760889411350881, 0.0],
              [-81.825851983964469, 35.76063747779083, 0.0],
              [-81.825262117290279, 35.760408277892054, 0.0],
              [-81.825402716969521, 35.759996078238537, 0.0],
              [-81.825402983268944, 35.759629677602234, 0.0],
              [-81.824812983510995, 35.759629477851441, 0.0],
              [-81.824672517134957, 35.75953781101132, 0.0],
              [-81.824672717070911, 35.75914861140366, 0.0],
              [-81.824813384023599, 35.758919611431402, 0.0],
              [-81.825347316584882, 35.758828211523991, 0.0],
              [-81.825853183143522, 35.758370410694127, 0.0],
              [-81.826949116245174, 35.758210478297258, 0.0],
              [-81.827370582961478, 35.758004611509492, 0.0],
              [-81.827482983564579, 35.757867211185768, 0.0],
              [-81.82753938384846, 35.75756947829322, 0.0],
              [-81.826471916898797, 35.75729421157012, 0.0],
              [-81.826471983455434, 35.757111011106744, 0.0],
              [-81.827174783145907, 35.756653277465745, 0.0],
              [-81.827399716844795, 35.756264211378259, 0.0],
              [-81.828495516840192, 35.756081278241226, 0.0],
              [-81.829001317041687, 35.755852478139282, 0.0],
              [-81.829338583588054, 35.755509077488078, 0.0],
              [-81.829366916918076, 35.755234278045549, 0.0],
              [-81.828664516254605, 35.755073678164919, 0.0],
              [-81.828664717099954, 35.75489047828237, 0.0],
              [-81.829029983305205, 35.75470747773803, 0.0],
              [-81.830097716186756, 35.754662010968822, 0.0],
              [-81.830547317243969, 35.754479011219296, 0.0],
              [-81.830547316426632, 35.754181210850014, 0.0],
              [-81.829873183959549, 35.753974877733839, 0.0],
              [-81.82987331670779, 35.753791678317263, 0.0],
              [-81.830154383339092, 35.753562811500217, 0.0],
              [-81.830997317165682, 35.753265411452716, 0.0],
              [-81.831418916472842, 35.752922078235969, 0.0],
              [-81.831475183994527, 35.752716011071122, 0.0],
              [-81.831082117243724, 35.752326477455668, 0.0],
              [-81.831335183838647, 35.751868611233157, 0.0],
              [-81.832122117139221, 35.751227610829076, 0.0],
              [-81.832431516236937, 35.750517810885427, 0.0],
              [-81.832439982825093, 35.750133010829863, 0.0],
              [-81.8322155166925, 35.749743678220298, 0.0],
              [-81.831934583308467, 35.749583211298351, 0.0],
              [-81.831176116584047, 35.749376877818484, 0.0],
              [-81.830417716301682, 35.749330877879849, 0.0],
              [-81.829771516602761, 35.749651410875224, 0.0],
              [-81.829265583361206, 35.749765677661117, 0.0],
              [-81.828984916298594, 35.749559478189767, 0.0],
              [-81.828985049799186, 35.749299316533502, 0.0],
              [-81.828985116142107, 35.749170210733972, 0.0],
              [-81.829153783544811, 35.748918278047739, 0.0],
              [-81.829547116525063, 35.748781011517821, 0.0],
              [-81.830137116320614, 35.74873527828634, 0.0],
              [-81.830417983370538, 35.74880421068768, 0.0],
              [-81.830614583180505, 35.748758477384897, 0.0],
              [-81.830586783645714, 35.748254678078709, 0.0],
              [-81.830951916271488, 35.748186011523934, 0.0],
              [-81.831766317044526, 35.748690077826687, 0.0],
              [-81.832271916405233, 35.74889627762817, 0.0],
              [-81.832833917126948, 35.748988077466926, 0.0],
              [-81.833564183003801, 35.74882801109527, 0.0],
              [-81.833958147535228, 35.748622655856899, 0.0],
              [-81.834266716986519, 35.748461810691886, 0.0],
              [-81.834407209142427, 35.748252058686496, 0.0],
              [-81.834604116923046, 35.747958077951409, 0.0],
              [-81.835222183059997, 35.747408677505398, 0.0],
              [-81.836552587810672, 35.746726321874682, 0.0],
              [-81.836739582844032, 35.746630411544636, 0.0],
              [-81.83727338346192, 35.746172610943042, 0.0],
              [-81.837357717297351, 35.745989277885521, 0.0],
              [-81.838236081440243, 35.744320042890777, 0.0],
              [-81.838454116544824, 35.743905677972506, 0.0],
              [-81.838735183207078, 35.743516477480902, 0.0],
              [-81.839072516301684, 35.743104211311525, 0.0],
              [-81.841066916895414, 35.742852811214163, 0.0],
              [-81.841600583003228, 35.742624011315172, 0.0],
              [-81.842977383689799, 35.741502077705412, 0.0],
              [-81.843679716182933, 35.741227411431495, 0.0],
              [-81.844051646689721, 35.741214326859762, 0.0],
              [-81.844325916588801, 35.741204677746673, 0.0],
              [-81.844353983534859, 35.74092987750339, 0.0],
              [-81.844029063004271, 35.740644473431921, 0.0],
              [-81.843988917301033, 35.740609210761654, 0.0],
              [-81.843988970106935, 35.74038900281424, 0.0],
              [-81.843988983157871, 35.74033441145319, 0.0],
              [-81.844241983955371, 35.739853477974158, 0.0],
              [-81.844213983997363, 35.739395478178608, 0.0],
              [-81.84411278033582, 35.739293267387232, 0.0],
              [-81.843624383816916, 35.738800011394972, 0.0],
              [-81.843596383686474, 35.738410678312803, 0.0],
              [-81.843175116983659, 35.738135811487489, 0.0],
              [-81.842978717285433, 35.737609010729152, 0.0],
              [-81.842388983974686, 35.737265411067433, 0.0],
              [-81.842276716904038, 35.737082078260009, 0.0],
              [-81.842332916465608, 35.73678447779686, 0.0],
              [-81.842445316257766, 35.7366928782467, 0.0],
              [-81.842641917292582, 35.736692878088391, 0.0],
              [-81.842810382891784, 35.736784477438938, 0.0],
              [-81.842866516297903, 35.736944878161623, 0.0],
              [-81.844158316483387, 35.737975677623247, 0.0],
              [-81.844579317204364, 35.738914611202517, 0.0],
              [-81.844719717174044, 35.738914677671829, 0.0],
              [-81.845225516525744, 35.73857127824504, 0.0],
              [-81.845787183630407, 35.738342277957045, 0.0],
              [-81.846320916550582, 35.738342411155443, 0.0],
              [-81.84662913028933, 35.738251130677597, 0.0],
              [-81.846629983234678, 35.738250877827774, 0.0],
              [-81.84663005887333, 35.738249587196165, 0.0],
              [-81.846658117192135, 35.737770010909649, 0.0],
              [-81.847388716635805, 35.73710601125407, 0.0],
              [-81.847407952430501, 35.737074664089519, 0.0],
              [-81.847669716943258, 35.736648077861794, 0.0],
              [-81.847725983898172, 35.736052611023382, 0.0],
              [-81.848119317156943, 35.735548878141458, 0.0],
              [-81.848119383372008, 35.735297011160853, 0.0],
              [-81.847907057793293, 35.735163794290052, 0.0],
              [-81.84775418375547, 35.7350678777537, 0.0],
              [-81.847670116231726, 35.734816011272763, 0.0],
              [-81.848260182982301, 35.733945811646663, 0.0],
              [-81.848680921809915, 35.733564863106487, 0.0],
              [-81.849018716313935, 35.733259010905158, 0.0],
              [-81.84963671715505, 35.732892677659137, 0.0],
              [-81.850395117199469, 35.73261801096718, 0.0],
              [-81.851771383755263, 35.732343411258213, 0.0],
              [-81.851979329493489, 35.732180950810395, 0.0],
              [-81.852445583776031, 35.731816677899964, 0.0],
              [-81.854355517222672, 35.731290210745527, 0.0],
              [-81.854636383062143, 35.731015477942748, 0.0],
              [-81.854973516494667, 35.730420078203856, 0.0],
              [-81.855787257766366, 35.729922596362101, 0.0],
              [-81.855984716766017, 35.729801878048619, 0.0],
              [-81.856068916134589, 35.729549878332044, 0.0],
              [-81.856040983533617, 35.728977411044816, 0.0],
              [-81.855956783801759, 35.728817211246387, 0.0],
              [-81.855963439085187, 35.728752321433142, 0.0],
              [-81.85598498394279, 35.728542277769755, 0.0],
              [-81.857248717037862, 35.728863077566793, 0.0],
              [-81.858091183770426, 35.728840278328938, 0.0],
              [-81.85848431628979, 35.729138011366103, 0.0],
              [-81.859270716987851, 35.72925247791899, 0.0],
              [-81.859523383368668, 35.72948161096221, 0.0],
              [-81.859495316356828, 35.729687677532624, 0.0],
              [-81.859473934518832, 35.72971164798313, 0.0],
              [-81.85927058387648, 35.729939611120976, 0.0],
              [-81.858540316641864, 35.730328811680614, 0.0],
              [-81.858517203211775, 35.731135986942036, 0.0],
              [-81.858512116302364, 35.731313611248275, 0.0],
              [-81.858348261254449, 35.731420480094272, 0.0],
              [-81.858090783534877, 35.731588410815355, 0.0],
              [-81.858090717108468, 35.731771611483644, 0.0],
              [-81.858371716632206, 35.731931878021982, 0.0],
              [-81.858520857077281, 35.731977476920939, 0.0],
              [-81.858821116963, 35.732069278055029, 0.0],
              [-81.859129983351764, 35.73197767813997, 0.0],
              [-81.85946698367286, 35.731725877838976, 0.0],
              [-81.860169182782613, 35.731519811450561, 0.0],
              [-81.860281583980253, 35.731084678003157, 0.0],
              [-81.860478316548537, 35.730947278251797, 0.0],
              [-81.860871383610643, 35.730993077482189, 0.0],
              [-81.861152183930514, 35.731130611481404, 0.0],
              [-81.861545382976445, 35.732458811306422, 0.0],
              [-81.861825234827222, 35.73258320998233, 0.0],
              [-81.861854183297027, 35.732596078271023, 0.0],
              [-81.862556383885007, 35.732596277735155, 0.0],
              [-81.863174316570678, 35.732298478043028, 0.0],
              [-81.863398983793829, 35.732298477683727, 0.0],
              [-81.863567516264624, 35.732458877477036, 0.0],
              [-81.863539383709977, 35.732756478122496, 0.0],
              [-81.862977582954358, 35.733351877655316, 0.0],
              [-81.862668716437383, 35.733581011550072, 0.0],
              [-81.861348516128544, 35.734130477672927, 0.0],
              [-81.8610788936045, 35.734423507207012, 0.0],
              [-81.861011383954633, 35.734496877955195, 0.0],
              [-81.860533917207775, 35.73468007802871, 0.0],
              [-81.860532500540742, 35.734679173782652, 0.0],
              [-81.859887983370896, 35.734267811646568, 0.0],
              [-81.859298183126768, 35.734359411671747, 0.0],
              [-81.858989183751021, 35.734473877973038, 0.0],
              [-81.858623983611707, 35.73495481090535, 0.0],
              [-81.85865198296159, 35.735366877447717, 0.0],
              [-81.859053489741768, 35.735632941467621, 0.0],
              [-81.85910138281686, 35.735664677735322, 0.0],
              [-81.859747383030737, 35.735939611161022, 0.0],
              [-81.85974732556258, 35.736414376003545, 0.0],
              [-81.85974731721069, 35.736489211490209, 0.0],
              [-81.85999998286988, 35.736603677740895, 0.0],
              [-81.860113881019714, 35.736574508683773, 0.0],
              [-81.860983182861901, 35.736351878269858, 0.0],
              [-81.862134783582377, 35.735893810897096, 0.0],
              [-81.862668583668608, 35.735596211631034, 0.0],
              [-81.863370782788465, 35.735367278305404, 0.0],
              [-81.863735916212306, 35.735367211079371, 0.0],
              [-81.863735916608647, 35.735619210865252, 0.0],
              [-81.863705318265204, 35.735652824569691, 0.0],
              [-81.863089783060886, 35.736329010870463, 0.0],
              [-81.863005517056067, 35.736626811166971, 0.0],
              [-81.86317398393463, 35.736832878085409, 0.0],
              [-81.863205555771913, 35.736843175712437, 0.0],
              [-81.864297383720384, 35.737199278288415, 0.0],
              [-81.864269316659758, 35.737382610853075, 0.0],
              [-81.863932316222659, 35.737611477778593, 0.0],
              [-81.863668172215228, 35.737701216129807, 0.0],
              [-81.862921116967726, 35.737955011138382, 0.0],
              [-81.862808716619398, 35.738069477392024, 0.0],
              [-81.862808782858281, 35.73829841091257, 0.0],
              [-81.862892954246078, 35.738332718973062, 0.0],
              [-81.863033517194225, 35.738390011509068, 0.0],
              [-81.863651317134085, 35.738390078027805, 0.0],
              [-81.863932316430649, 35.738527477969512, 0.0],
              [-81.864859117193973, 35.738481811048601, 0.0],
              [-81.865448983378201, 35.738298611284556, 0.0],
              [-81.865842183727395, 35.737978011596567, 0.0],
              [-81.866516383768314, 35.738000811649904, 0.0],
              [-81.867078182959119, 35.73779481144561, 0.0],
              [-81.86747151730566, 35.737520011286378, 0.0],
              [-81.86769618357981, 35.737520011256287, 0.0],
              [-81.867780383749079, 35.737611477612205, 0.0],
              [-81.867696117148398, 35.737840611059632, 0.0],
              [-81.867616842886022, 35.73790524941225, 0.0],
              [-81.867190582792574, 35.738252810821777, 0.0],
              [-81.865898516886944, 35.738985610852644, 0.0],
              [-81.865224183274009, 35.739603877737387, 0.0],
              [-81.86508391673695, 35.739855678333896, 0.0],
              [-81.865083783109071, 35.7401534782989, 0.0],
              [-81.865164873443035, 35.740303745022139, 0.0],
              [-81.865392782836565, 35.740726077649782, 0.0],
              [-81.865336582863591, 35.740909210955131, 0.0],
              [-81.86505571609257, 35.740886410748239, 0.0],
              [-81.864912911751901, 35.740823347640003, 0.0],
              [-81.864381517298213, 35.740588678323832, 0.0],
              [-81.863988317211223, 35.740634411080997, 0.0],
              [-81.863594983378576, 35.740840477536615, 0.0],
              [-81.86292091713733, 35.74063441142804, 0.0],
              [-81.862162516193081, 35.740748811099699, 0.0],
              [-81.861516382820994, 35.740451078209119, 0.0],
              [-81.860673917183291, 35.740359411096904, 0.0],
              [-81.860196316206867, 35.740519677699609, 0.0],
              [-81.859999716214986, 35.740725811154235, 0.0],
              [-81.860083982952844, 35.740931877682947, 0.0],
              [-81.860455330669353, 35.741067646378013, 0.0],
              [-81.860898516981933, 35.741229678061146, 0.0],
              [-81.861628716855307, 35.741367211366097, 0.0],
              [-81.862106183253658, 35.741642011088487, 0.0],
              [-81.862218582904021, 35.741779410748414, 0.0],
              [-81.862218516408447, 35.742374677421616, 0.0],
              [-81.862106183518478, 35.742557877386517, 0.0],
              [-81.86190872755212, 35.742730337847846, 0.0],
              [-81.861712783195969, 35.742901477505676, 0.0],
              [-81.861544316623323, 35.743153278047075, 0.0],
              [-81.861628517120266, 35.743359478286763, 0.0],
              [-81.861703205410848, 35.743393316371474, 0.0],
              [-81.861881317118559, 35.743474010823576, 0.0],
              [-81.863243810688772, 35.743859326825707, 0.0],
              [-81.863257783376596, 35.743863278284266, 0.0],
              [-81.863478775527341, 35.743823279834253, 0.0],
              [-81.864016183519482, 35.74372601146618, 0.0],
              [-81.864107124898609, 35.743792717699336, 0.0],
              [-81.864191278191413, 35.743854444887425, 0.0],
              [-81.864297116243321, 35.743932077582954, 0.0],
              [-81.864256506260347, 35.744130884043642, 0.0],
              [-81.864212916424734, 35.744344277606885, 0.0],
              [-81.864016183491003, 35.744710678087898, 0.0],
              [-81.864016116701862, 35.744916811466702, 0.0],
              [-81.864268917243763, 35.745077078144774, 0.0],
              [-81.864347562003204, 35.745085101469869, 0.0],
              [-81.864493716555614, 35.745100011568432, 0.0],
              [-81.864999316540022, 35.744710677876888, 0.0],
              [-81.865111716636832, 35.74418401132354, 0.0],
              [-81.865308316878156, 35.744046678072827, 0.0],
              [-81.86584198375202, 35.743955011296592, 0.0],
              [-81.866460116406643, 35.744092478068154, 0.0],
              [-81.867021783495602, 35.743932077571472, 0.0],
              [-81.86750105891845, 35.743500274469312, 0.0],
              [-81.867555516714674, 35.743451210774261, 0.0],
              [-81.867752182938631, 35.743016078058723, 0.0],
              [-81.867758581697032, 35.742833804044508, 0.0],
              [-81.867780316096102, 35.742214677378151, 0.0],
              [-81.867976916934708, 35.741756611394791, 0.0],
              [-81.868847717311453, 35.740726078162247, 0.0],
              [-81.869100517112727, 35.740748877846507, 0.0],
              [-81.869325116532409, 35.741298611282446, 0.0],
              [-81.86932518332658, 35.742100077529976, 0.0],
              [-81.86917088900654, 35.742467732110526, 0.0],
              [-81.868959983385977, 35.742970277484929, 0.0],
              [-81.868973495448714, 35.743244613496145, 0.0],
              [-81.868988183017265, 35.74354281125273, 0.0],
              [-81.869040069128374, 35.743588965686413, 0.0],
              [-81.869297183195584, 35.743817678231274, 0.0],
              [-81.869426788050674, 35.743741340386556, 0.0],
              [-81.869596876002248, 35.743641158342811, 0.0],
              [-81.869802783103324, 35.743519877835844, 0.0],
              [-81.869999383356728, 35.743222211661532, 0.0],
              [-81.870027516538315, 35.742100077781181, 0.0],
              [-81.870169421708937, 35.741637051487835, 0.0],
              [-81.870336316215699, 35.741092477649872, 0.0],
              [-81.870645383706886, 35.740955077794439, 0.0],
              [-81.870926317206298, 35.741000811594034, 0.0],
              [-81.871122983427796, 35.741138211467444, 0.0],
              [-81.871094917163262, 35.741321477596408, 0.0],
              [-81.870771995748925, 35.741742653150972, 0.0],
              [-81.870533116337953, 35.74205421148757, 0.0],
              [-81.870476916256663, 35.742558077607306, 0.0],
              [-81.87054490182085, 35.742738162691019, 0.0],
              [-81.870589316275627, 35.742855810795795, 0.0],
              [-81.870954383213032, 35.743153477910781, 0.0],
              [-81.871151116587967, 35.74322207757448, 0.0],
              [-81.871572382778339, 35.743130477378749, 0.0],
              [-81.872274716767691, 35.742809877585231, 0.0],
              [-81.8725060693193, 35.743229020509659, 0.0],
              [-81.872527517165238, 35.743267878247352, 0.0],
              [-81.872710407859941, 35.743353061970993, 0.0],
              [-81.87272418279376, 35.743359478308008, 0.0],
              [-81.873651116665144, 35.743474011591083, 0.0],
              [-81.873875783202777, 35.743359477557817, 0.0],
              [-81.874128516454832, 35.742993010777987, 0.0],
              [-81.874381317124403, 35.743153410840748, 0.0],
              [-81.874381382801118, 35.743519677632293, 0.0],
              [-81.874156716650688, 35.743748678334683, 0.0],
              [-81.874136931225735, 35.743759437686109, 0.0],
              [-81.873651116232381, 35.744023611302929, 0.0],
              [-81.871768983042614, 35.744573277973338, 0.0],
              [-81.87154438340383, 35.744710677365624, 0.0],
              [-81.871488183073197, 35.744985478214161, 0.0],
              [-81.871590284672777, 35.745165781347218, 0.0],
              [-81.871656783421344, 35.745283211072596, 0.0],
              [-81.87219051687913, 35.745535077613326, 0.0],
              [-81.872555717156914, 35.745626677722335, 0.0],
              [-81.873145583483563, 35.745512077667804, 0.0],
              [-81.873426383316342, 35.745214411230741, 0.0],
              [-81.87373551663481, 35.745237278236111, 0.0],
              [-81.874269317127911, 35.745558010820659, 0.0],
              [-81.874830983668687, 35.745764011153248, 0.0],
              [-81.875139983467548, 35.745741011385199, 0.0],
              [-81.876010783108995, 35.745557678167387, 0.0],
              [-81.876235582886991, 35.745305810823687, 0.0],
              [-81.87634778336971, 35.744802078127677, 0.0],
              [-81.876656783600666, 35.744389877663195, 0.0],
              [-81.877752317169765, 35.744298011416667, 0.0],
              [-81.87811751692503, 35.744160678001464, 0.0],
              [-81.878229783680808, 35.743931677574132, 0.0],
              [-81.878538782962067, 35.743885877859292, 0.0],
              [-81.878566529611888, 35.743930033262927, 0.0],
              [-81.879128716806875, 35.744824677856393, 0.0],
              [-81.879437783332975, 35.744962011664761, 0.0],
              [-81.879690516106507, 35.744962078132048, 0.0],
              [-81.879715848971713, 35.744944002118224, 0.0],
              [-81.879915317018671, 35.744801678036218, 0.0],
              [-81.880364716871028, 35.744114677554094, 0.0],
              [-81.880729716188043, 35.743954277387317, 0.0],
              [-81.881965783237632, 35.743931211379149, 0.0],
              [-81.8835231028702, 35.743500724921468, 0.0],
              [-81.883623116666598, 35.743473077705012, 0.0],
              [-81.885027317100423, 35.742259077630344, 0.0],
              [-81.885056685895563, 35.742139215863673, 0.0],
              [-81.885167583146313, 35.741686611006031, 0.0],
              [-81.885532582804558, 35.74111407818809, 0.0],
              [-81.885560716529, 35.740587277378985, 0.0],
              [-81.88511111657759, 35.740198077644976, 0.0],
              [-81.885251517168015, 35.73994621088098, 0.0],
              [-81.885785183707554, 35.739671277990716, 0.0],
              [-81.886355967631431, 35.739593602235146, 0.0],
              [-81.886627782959678, 35.739556611523803, 0.0],
              [-81.886768117063141, 35.73939641160672, 0.0],
              [-81.886758417120149, 35.739120705425954, 0.0],
              [-81.886739916913442, 35.738594877493554, 0.0],
              [-81.886846567179944, 35.738381325576377, 0.0],
              [-81.887180304293324, 35.737713060517315, 0.0],
              [-81.887357582912543, 35.737358078294257, 0.0],
              [-81.887957411986918, 35.736729028673771, 0.0],
              [-81.889323183475753, 35.735296677622422, 0.0],
              [-81.889336699929387, 35.7347023103613, 0.0],
              [-81.889350783258138, 35.734083011500047, 0.0],
              [-81.889490982750871, 35.732892077445271, 0.0],
              [-81.889575116750606, 35.732525678098128, 0.0],
              [-81.889602982840827, 35.7313806783364, 0.0],
              [-81.88970242219321, 35.731198048758365, 0.0],
              [-81.889715183606086, 35.731174611042661, 0.0],
              [-81.889967983081362, 35.73117461113732, 0.0],
              [-81.890305183048838, 35.731655411206084, 0.0],
              [-81.890557983828742, 35.731838610920349, 0.0],
              [-81.890923116733461, 35.731724010850577, 0.0],
              [-81.891484717208385, 35.731403211027185, 0.0],
              [-81.891765517044618, 35.731082611177548, 0.0],
              [-81.891737316419594, 35.730830677952781, 0.0],
              [-81.890922716575886, 35.730510278152892, 0.0],
              [-81.890810316674035, 35.730395811494624, 0.0],
              [-81.890810316651226, 35.730143877391079, 0.0],
              [-81.891090982869684, 35.72984607835609, 0.0],
              [-81.891315783175756, 35.729868877638189, 0.0],
              [-81.8927201828064, 35.730784678210981, 0.0],
              [-81.892664183494659, 35.731196877570028, 0.0],
              [-81.891962316188454, 35.732044277426162, 0.0],
              [-81.892046716943923, 35.732181810808939, 0.0],
              [-81.892411783674746, 35.732112878299496, 0.0],
              [-81.892720583571233, 35.731883877526315, 0.0],
              [-81.892889182814187, 35.731906811625308, 0.0],
              [-81.893226317211628, 35.732181478065549, 0.0],
              [-81.893394983008463, 35.732662278063756, 0.0],
              [-81.893647716703768, 35.7328454780749, 0.0],
              [-81.894209517162196, 35.732959878021539, 0.0],
              [-81.894658783337633, 35.732822411177509, 0.0],
              [-81.894771183524782, 35.732684878027364, 0.0],
              [-81.894686782919024, 35.732478877720276, 0.0],
              [-81.894237383890726, 35.732089678311411, 0.0],
              [-81.893591316865255, 35.731883678051737, 0.0],
              [-81.893534916195506, 35.731013478287238, 0.0],
              [-81.893871983670024, 35.73110507773869, 0.0],
              [-81.894124716092449, 35.73128821165416, 0.0],
              [-81.894349383795827, 35.731196610806734, 0.0],
              [-81.894433582840918, 35.730807278272806, 0.0],
              [-81.895135716564383, 35.730875677897721, 0.0],
              [-81.895725383253705, 35.730784011378994, 0.0],
              [-81.895612917253302, 35.730257278323222, 0.0],
              [-81.895247783490532, 35.730028411110183, 0.0],
              [-81.8943771170226, 35.729845411286277, 0.0],
              [-81.894376917104879, 35.729593477681682, 0.0],
              [-81.894629582962821, 35.729318611145167, 0.0],
              [-81.894629516391348, 35.729204077683107, 0.0],
              [-81.89474178274088, 35.729112610781122, 0.0],
              [-81.894938382957164, 35.729112477402531, 0.0],
              [-81.895331916896197, 35.72941007820625, 0.0],
              [-81.895893516059388, 35.729341277471136, 0.0],
              [-81.896932582938462, 35.729409677711672, 0.0],
              [-81.897129316643174, 35.729684477779472, 0.0],
              [-81.897466316827106, 35.729592811301707, 0.0],
              [-81.897690916885807, 35.729409610891757, 0.0],
              [-81.897690783481153, 35.729043078100887, 0.0],
              [-81.898083916333633, 35.728676611478114, 0.0],
              [-81.898196182861014, 35.72872241156837, 0.0],
              [-81.898308783051633, 35.72954667783371, 0.0],
              [-81.898505516841396, 35.729752811533984, 0.0],
              [-81.898842583006839, 35.729935877594848, 0.0],
              [-81.89881451645816, 35.730233677628782, 0.0],
              [-81.897859982785775, 35.731172811127699, 0.0],
              [-81.897495116037007, 35.731837010898708, 0.0],
              [-81.896624916920842, 35.733005210993568, 0.0],
              [-81.896428517080167, 35.733554811088808, 0.0],
              [-81.89642871630754, 35.733921211392158, 0.0],
              [-81.897917383101458, 35.734218611656253, 0.0],
              [-81.898170183174031, 35.734149811369875, 0.0],
              [-81.898338516534537, 35.733966610799094, 0.0],
              [-81.898310382735716, 35.733783411451881, 0.0],
              [-81.898085717234849, 35.733623078141036, 0.0],
              [-81.89808558356934, 35.733233810709741, 0.0],
              [-81.898225916122655, 35.733004810944415, 0.0],
              [-81.89873131699153, 35.732546611404018, 0.0],
              [-81.899264983113056, 35.732867077401309, 0.0],
              [-81.899377516147226, 35.733233477744314, 0.0],
              [-81.899854983116001, 35.733347810949539, 0.0],
              [-81.900332383092646, 35.733210277434758, 0.0],
              [-81.900950116592085, 35.732729277758253, 0.0],
              [-81.90106238360579, 35.732500211102128, 0.0],
              [-81.901034182772122, 35.732339878159657, 0.0],
              [-81.900781316226301, 35.732179678094823, 0.0],
              [-81.900331983381989, 35.731996611047066, 0.0],
              [-81.900247583813766, 35.731790478014403, 0.0],
              [-81.900275516259768, 35.73114927770559, 0.0],
              [-81.900499982987384, 35.731011878152913, 0.0],
              [-81.900724783770073, 35.731034677946305, 0.0],
              [-81.901033783724429, 35.731217677678153, 0.0],
              [-81.901455182910837, 35.731721478093938, 0.0],
              [-81.901736183274124, 35.731927478001289, 0.0],
              [-81.901960916162238, 35.73192747792298, 0.0],
              [-81.902382116524663, 35.731789877509357, 0.0],
              [-81.902606783378204, 35.731812678066909, 0.0],
              [-81.902719183298856, 35.731904277711756, 0.0],
              [-81.902887783141779, 35.732293610854313, 0.0],
              [-81.90243911719061, 35.733736477945662, 0.0],
              [-81.902046116173977, 35.73428607814693, 0.0],
              [-81.901316116736936, 35.734950478258021, 0.0],
              [-81.901203916419945, 35.735133677385775, 0.0],
              [-81.901456717063397, 35.735362611507846, 0.0],
              [-81.902130982894704, 35.735683078127373, 0.0],
              [-81.90325438323751, 35.735636877962563, 0.0],
              [-81.903226183141058, 35.73545381141966, 0.0],
              [-81.902776783060958, 35.735247811281987, 0.0],
              [-81.902804383883094, 35.734400410699003, 0.0],
              [-81.903113383703712, 35.734446211118176, 0.0],
              [-81.903731582928899, 35.734904011231833, 0.0],
              [-81.904517916686004, 35.734789211184271, 0.0],
              [-81.904995316308785, 35.734422811110001, 0.0],
              [-81.904995116150872, 35.734079211225513, 0.0],
              [-81.904601783753861, 35.733873210973321, 0.0],
              [-81.904011983222972, 35.733896277770249, 0.0],
              [-81.903590583891216, 35.733690277800285, 0.0],
              [-81.903477982913458, 35.733278077751933, 0.0],
              [-81.903618316456033, 35.733003277436282, 0.0],
              [-81.904180116748606, 35.733094810746657, 0.0],
              [-81.904825983530159, 35.733002877551208, 0.0],
              [-81.905022717010041, 35.732888277502731, 0.0],
              [-81.904966317081445, 35.732636477716639, 0.0],
              [-81.904600983293761, 35.732109878138019, 0.0],
              [-81.90437618343428, 35.731903810789476, 0.0],
              [-81.90403891682088, 35.73130847750312, 0.0],
              [-81.904066183216997, 35.729568011363952, 0.0],
              [-81.904290783385889, 35.729430611181343, 0.0],
              [-81.904627916376356, 35.729453411465279, 0.0],
              [-81.904796383228273, 35.729545011397171, 0.0],
              [-81.904824783793202, 35.730025878344613, 0.0],
              [-81.904740517095433, 35.73018621147417, 0.0],
              [-81.904768783554914, 35.730575477740622, 0.0],
              [-81.905190516114473, 35.731468611450403, 0.0],
              [-81.905808783705368, 35.732269811093175, 0.0],
              [-81.906792183891469, 35.733208411196166, 0.0],
              [-81.907016983415048, 35.733299877736947, 0.0],
              [-81.907662782903131, 35.733162278189319, 0.0],
              [-81.907971716701226, 35.733001878325254, 0.0],
              [-81.908196316670598, 35.732635477380832, 0.0],
              [-81.908420383709242, 35.731673611297566, 0.0],
              [-81.90900998367816, 35.7312382113249, 0.0],
              [-81.909431316157935, 35.731215211210333, 0.0],
              [-81.909543716152527, 35.731398411599223, 0.0],
              [-81.90951571661644, 35.731718877407189, 0.0],
              [-81.908701916903865, 35.732933077435881, 0.0],
              [-81.908561716142486, 35.733391011524269, 0.0],
              [-81.908561917146471, 35.733803278159186, 0.0],
              [-81.909516916299992, 35.73407767770366, 0.0],
              [-81.909938383156728, 35.734466877403484, 0.0],
              [-81.909854382770177, 35.734787477777687, 0.0],
              [-81.909433517227995, 35.735337211256535, 0.0],
              [-81.908759583107368, 35.735841410726955, 0.0],
              [-81.90735558302795, 35.736574611080059, 0.0],
              [-81.906793982937401, 35.737147277854731, 0.0],
              [-81.906513716599562, 35.738017611571983, 0.0],
              [-81.906682317138461, 35.738361011301585, 0.0],
              [-81.907216183880351, 35.738773077895253, 0.0],
              [-81.907693717170389, 35.738772878033515, 0.0],
              [-81.908114982803497, 35.738635411097135, 0.0],
              [-81.908367516160027, 35.738200077923167, 0.0],
              [-81.908592183412367, 35.738200011335827, 0.0],
              [-81.909097983893105, 35.738428877869026, 0.0],
              [-81.909547183434768, 35.738222677956593, 0.0],
              [-81.910866583837532, 35.736825278184959, 0.0],
              [-81.911034916789589, 35.73643587810097, 0.0],
              [-81.911455916384242, 35.735725811483206, 0.0],
              [-81.911315116357713, 35.735084677847546, 0.0],
              [-81.911876716744075, 35.734809677633848, 0.0],
              [-81.912241383146124, 35.733962211128159, 0.0],
              [-81.912915583867758, 35.73421387805287, 0.0],
              [-81.913392983612553, 35.734122078148701, 0.0],
              [-81.914487983464113, 35.733595011511945, 0.0],
              [-81.915077516502706, 35.733090877611374, 0.0],
              [-81.915694916929482, 35.732128877880875, 0.0],
              [-81.915947183735327, 35.731396011246801, 0.0],
              [-81.916508916476886, 35.731441611362328, 0.0],
              [-81.917239116439475, 35.731326677383301, 0.0],
              [-81.918530783262099, 35.730891077414, 0.0],
              [-81.918699183278676, 35.730753611213608, 0.0],
              [-81.918783316984943, 35.730501677671541, 0.0],
              [-81.919513516586477, 35.730570078283918, 0.0],
              [-81.920439982922375, 35.730203277809174, 0.0],
              [-81.920580717193744, 35.730546677439875, 0.0],
              [-81.920159716977793, 35.731050677790336, 0.0],
              [-81.919710583226717, 35.731371477834884, 0.0],
              [-81.917183583502151, 35.732586277755736, 0.0],
              [-81.916762583590611, 35.732952877942566, 0.0],
              [-81.916734783755999, 35.733365077427557, 0.0],
              [-81.916088982835973, 35.733777611515492, 0.0],
              [-81.915611916221067, 35.734235811355305, 0.0],
              [-81.915639983545091, 35.734464677968852, 0.0],
              [-81.915977116403383, 35.734487477964997, 0.0],
              [-81.916370183559664, 35.734212611434153, 0.0],
              [-81.917381316697984, 35.734097611321609, 0.0],
              [-81.917774316659958, 35.733914277633701, 0.0],
              [-81.918167316080215, 35.733501878175744, 0.0],
              [-81.918251383628288, 35.733295811129196, 0.0],
              [-81.918728383403675, 35.732700211546138, 0.0],
              [-81.919570716795377, 35.732081477831031, 0.0],
              [-81.920216583760848, 35.73189801113891, 0.0],
              [-81.921087116146339, 35.731760211561614, 0.0],
              [-81.921143383097359, 35.731897611633727, 0.0],
              [-81.91968338275683, 35.732768410803068, 0.0],
              [-81.919458916331564, 35.732997611294813, 0.0],
              [-81.919290583263717, 35.733341211678876, 0.0],
              [-81.919290783099115, 35.733547277640142, 0.0],
              [-81.919796383716701, 35.733661477367541, 0.0],
              [-81.920638916468633, 35.733638278085138, 0.0],
              [-81.920751383615809, 35.733707011236817, 0.0],
              [-81.920723383150417, 35.733958810912931, 0.0],
              [-81.920386383319524, 35.734119277484822, 0.0],
              [-81.919543916811833, 35.734028077730535, 0.0],
              [-81.918953983601909, 35.734142811417151, 0.0],
              [-81.918448716535806, 35.734394878254591, 0.0],
              [-81.918336583561953, 35.734738411459126, 0.0],
              [-81.91847718384173, 35.735036077363546, 0.0],
              [-81.919404383561414, 35.735631078135029, 0.0],
              [-81.919769516055425, 35.735539278333292, 0.0],
              [-81.919881783277418, 35.73558507758397, 0.0],
              [-81.920022382978658, 35.735836877606403, 0.0],
              [-81.920134782722869, 35.735882677401314, 0.0],
              [-81.920780716376157, 35.735699210979796, 0.0],
              [-81.921033516539154, 35.735744877880343, 0.0],
              [-81.921651582675707, 35.736088077689153, 0.0],
              [-81.921960583702415, 35.73606501109974, 0.0],
              [-81.922185316455071, 35.735904677625768, 0.0],
              [-81.922185116183996, 35.735767278230433, 0.0],
              [-81.921988383493755, 35.735675810861693, 0.0],
              [-81.921988316697124, 35.73553841122073, 0.0],
              [-81.922549916004996, 35.735332010862543, 0.0],
              [-81.92308318306128, 35.734850877669942, 0.0],
              [-81.923223316979758, 35.734347011178969, 0.0],
              [-81.923700916924474, 35.734415478079775, 0.0],
              [-81.923953583467082, 35.734300811039105, 0.0],
              [-81.924374383124501, 35.733659478295159, 0.0],
              [-81.924599117039733, 35.733705077778389, 0.0],
              [-81.924823982676429, 35.733979877735777, 0.0],
              [-81.924880383626686, 35.73427747773426, 0.0],
              [-81.925582783300314, 35.734552011479366, 0.0],
              [-81.9255547827876, 35.734689410878474, 0.0],
              [-81.924599916217204, 35.734873077620215, 0.0],
              [-81.923954183033956, 35.735216878083143, 0.0],
              [-81.923448983100997, 35.735629277389009, 0.0],
              [-81.92305611701947, 35.736293611061654, 0.0],
              [-81.922972183828932, 35.736637077493761, 0.0],
              [-81.92308471666145, 35.7369118777643, 0.0],
              [-81.923449916556876, 35.73711781133953, 0.0],
              [-81.923786917041028, 35.736957411581905, 0.0],
              [-81.924067783461368, 35.73695721091137, 0.0],
              [-81.924180183215739, 35.737025877488371, 0.0],
              [-81.924180316866256, 35.737232011214864, 0.0],
              [-81.923619116495672, 35.738079611237097, 0.0],
              [-81.923619183644092, 35.738217010825544, 0.0],
              [-81.924321583590441, 35.738606010957156, 0.0],
              [-81.925136316150216, 35.738788677624228, 0.0],
              [-81.926624917000893, 35.738513211662237, 0.0],
              [-81.927439183502102, 35.73821521082737, 0.0],
              [-81.92777638369833, 35.738444011401867, 0.0],
              [-81.927748516288801, 35.738581410953977, 0.0],
              [-81.925867182706284, 35.739429610832836, 0.0],
              [-81.925614783260727, 35.739910677501015, 0.0],
              [-81.925474316444038, 35.739910678241841, 0.0],
              [-81.925221316846304, 35.739681810945456, 0.0],
              [-81.92395678360387, 35.739018277433786, 0.0],
              [-81.922917316692335, 35.738629478243716, 0.0],
              [-81.922074316790685, 35.738126077702866, 0.0],
              [-81.921709316505783, 35.738194878291182, 0.0],
              [-81.921456516231274, 35.738332477937107, 0.0],
              [-81.921259916286104, 35.738286677889938, 0.0],
              [-81.921231716702934, 35.73805767744183, 0.0],
              [-81.92106311711072, 35.737874677412954, 0.0],
              [-81.920697916387823, 35.737920611354738, 0.0],
              [-81.92041718285725, 35.738149677768433, 0.0],
              [-81.920417383388937, 35.738470278071134, 0.0],
              [-81.920501716827118, 35.738561810795062, 0.0],
              [-81.920502183053486, 35.739317478189363, 0.0],
              [-81.9201377165206, 35.740233677531471, 0.0],
              [-81.920138116310568, 35.740897811209223, 0.0],
              [-81.919913382882726, 35.740966611336034, 0.0],
              [-81.919520116180337, 35.740898078080612, 0.0],
              [-81.919210983019525, 35.740692211354478, 0.0],
              [-81.919210917025211, 35.740463211080616, 0.0],
              [-81.919323182981685, 35.740371477790582, 0.0],
              [-81.919322983197191, 35.740211211264253, 0.0],
              [-81.919098316048533, 35.740005211414172, 0.0],
              [-81.919069916561241, 35.739478478089097, 0.0],
              [-81.918901116901651, 35.739249610918272, 0.0],
              [-81.918900983389719, 35.739043477636109, 0.0],
              [-81.919209916819227, 35.738745678180457, 0.0],
              [-81.919096983365293, 35.738173210946364, 0.0],
              [-81.918422983101266, 35.738219277453773, 0.0],
              [-81.918310582711925, 35.738127811278922, 0.0],
              [-81.918282183402269, 35.737761277936535, 0.0],
              [-81.917916983737129, 35.737578211380232, 0.0],
              [-81.91757991674487, 35.737647078033397, 0.0],
              [-81.917158916627386, 35.738059477424251, 0.0],
              [-81.916766383128447, 35.739273277759665, 0.0],
              [-81.916710583204988, 35.739891677647257, 0.0],
              [-81.916879316179191, 35.740235077786842, 0.0],
              [-81.916851383715525, 35.740510010911841, 0.0],
              [-81.916570516477293, 35.740510078205432, 0.0],
              [-81.916373783001347, 35.740349811090006, 0.0],
              [-81.916120716362997, 35.739869011629551, 0.0],
              [-81.916120383819759, 35.739296477797183, 0.0],
              [-81.915951717081029, 35.739021678258659, 0.0],
              [-81.915530383320942, 35.739067677922641, 0.0],
              [-81.915417982715837, 35.738976077899864, 0.0],
              [-81.915417916964969, 35.738792877702743, 0.0],
              [-81.915614382979115, 35.738495211393477, 0.0],
              [-81.915614182825735, 35.738128811569666, 0.0],
              [-81.915361316648273, 35.737968611338879, 0.0],
              [-81.91485571696667, 35.738037478173119, 0.0],
              [-81.914069716587591, 35.738701877510039, 0.0],
              [-81.91381718377022, 35.739274611051812, 0.0],
              [-81.913845517227585, 35.739549278093769, 0.0],
              [-81.914828916705261, 35.740373411431591, 0.0],
              [-81.914857183485978, 35.740510811335938, 0.0],
              [-81.915109982944898, 35.740716811208117, 0.0],
              [-81.915643783565159, 35.740808211501012, 0.0],
              [-81.915981117109823, 35.741288878227792, 0.0],
              [-81.915981316046285, 35.741586611433348, 0.0],
              [-81.915840983838194, 35.741655277902524, 0.0],
              [-81.914717183336222, 35.741426811339075, 0.0],
              [-81.914155316059691, 35.741198011015832, 0.0],
              [-81.913734117017995, 35.74126681105048, 0.0],
              [-81.913649782839201, 35.741473011627164, 0.0],
              [-81.913762316905192, 35.741656210813069, 0.0],
              [-81.91443651695306, 35.741816078139202, 0.0],
              [-81.915251583307338, 35.742457078331029, 0.0],
              [-81.916515916736785, 35.743052010970487, 0.0],
              [-81.916515983864741, 35.743212277442218, 0.0],
              [-81.916094783878123, 35.743349878028866, 0.0],
              [-81.915420517033311, 35.743167010700923, 0.0],
              [-81.914998983105605, 35.74291521085599, 0.0],
              [-81.914690116834308, 35.742938211417368, 0.0],
              [-81.914577783392389, 35.743098610988923, 0.0],
              [-81.914353116497622, 35.743144478291555, 0.0],
              [-81.913987583470288, 35.742824011216392, 0.0],
              [-81.913650716861568, 35.742892811146923, 0.0],
              [-81.913369983727392, 35.743190611161673, 0.0],
              [-81.913369983570888, 35.743419611441908, 0.0],
              [-81.913819783244321, 35.743717211203681, 0.0],
              [-81.914269716980812, 35.744587211573489, 0.0],
              [-81.914915916562506, 35.744999077778751, 0.0],
              [-81.91522491692109, 35.744838678003482, 0.0],
              [-81.915365183396887, 35.744655477855787, 0.0],
              [-81.915252583452684, 35.74422041105985, 0.0],
              [-81.915449116536152, 35.744060077757354, 0.0],
              [-81.915758117094953, 35.744082810851637, 0.0],
              [-81.91595511650992, 35.744655277399595, 0.0],
              [-81.916179983551075, 35.744975811311789, 0.0],
              [-81.916151983413698, 35.74520467807303, 0.0],
              [-81.915899383743124, 35.74552541140762, 0.0],
              [-81.915899716895311, 35.746120877542751, 0.0],
              [-81.916518183849647, 35.746945077374043, 0.0],
              [-81.916855583392604, 35.747219678092321, 0.0],
              [-81.917529782983465, 35.74735687804786, 0.0],
              [-81.917894917004759, 35.747265077594733, 0.0],
              [-81.918709317170681, 35.746852478045099, 0.0],
              [-81.919074316594987, 35.746440211199989, 0.0],
              [-81.91907411603367, 35.74623407800938, 0.0],
              [-81.918905517052949, 35.746119678282319, 0.0],
              [-81.918764916432465, 35.74582201144738, 0.0],
              [-81.918848916379019, 35.745364010710944, 0.0],
              [-81.919101583854641, 35.745386811069494, 0.0],
              [-81.919663783817455, 35.745913277757964, 0.0],
              [-81.919944716122245, 35.745913077869368, 0.0],
              [-81.920169382966748, 35.7457756114283, 0.0],
              [-81.920421916348005, 35.745409211668395, 0.0],
              [-81.920899383052614, 35.745363011626473, 0.0],
              [-81.920814916276584, 35.744927878285154, 0.0],
              [-81.920898982741718, 35.744790477641686, 0.0],
              [-81.921208116523161, 35.744904877577476, 0.0],
              [-81.921685916698237, 35.745248211633076, 0.0],
              [-81.922050916582961, 35.745110611454599, 0.0],
              [-81.922612516679891, 35.74476687825404, 0.0],
              [-81.922781116585909, 35.744858411440973, 0.0],
              [-81.922753516636689, 35.745522610956385, 0.0],
              [-81.922922116899642, 35.745659810681801, 0.0],
              [-81.92292231688343, 35.745888877682219, 0.0],
              [-81.921714516768802, 35.746095477374119, 0.0],
              [-81.921012516567984, 35.746462278282841, 0.0],
              [-81.920366717021054, 35.746989277798562, 0.0],
              [-81.920226516878216, 35.747287011497015, 0.0],
              [-81.9203669832673, 35.747515877442034, 0.0],
              [-81.921856183597029, 35.747904611418363, 0.0],
              [-81.921828383613857, 35.748271010725496, 0.0],
              [-81.921687983275689, 35.748408410768924, 0.0],
              [-81.919777716987426, 35.748409411318292, 0.0],
              [-81.919524916844679, 35.748546811243763, 0.0],
              [-81.919384716274863, 35.748821678308779, 0.0],
              [-81.919216183527396, 35.748936211528104, 0.0],
              [-81.918822916810129, 35.748890610733383, 0.0],
              [-81.918317382906523, 35.749005277548505, 0.0],
              [-81.917839983547665, 35.749371877554402, 0.0],
              [-81.917840117107275, 35.749600877444664, 0.0],
              [-81.918402316153163, 35.750127410967309, 0.0],
              [-81.919939517111615, 35.75076721153247, 0.0],
              [-81.920389183314512, 35.751156411497412, 0.0],
              [-81.920417317068583, 35.751270877702161, 0.0],
              [-81.920866983526878, 35.751683011403756, 0.0],
              [-81.921681917087369, 35.751934411176521, 0.0],
              [-81.922103116030783, 35.751751210758925, 0.0],
              [-81.922102983819968, 35.75143061099309, 0.0],
              [-81.921962516772382, 35.751339011441729, 0.0],
              [-81.922046517149326, 35.751041210817696, 0.0],
              [-81.922327516194613, 35.751064077884585, 0.0],
              [-81.922777183007838, 35.751476077447791, 0.0],
              [-81.923001982900956, 35.751544677828839, 0.0],
              [-81.924996516494758, 35.751337810767772, 0.0],
              [-81.925277316890913, 35.751223210997935, 0.0],
              [-81.925529917192947, 35.750856477795672, 0.0],
              [-81.92550171683483, 35.750650477518306, 0.0],
              [-81.925080116023338, 35.750421611071125, 0.0],
              [-81.924722983235867, 35.750124478246988, 0.0],
              [-81.924638716768015, 35.74998721100355, 0.0],
              [-81.924779117067914, 35.749895478207321, 0.0],
              [-81.925734317168718, 35.750124011467207, 0.0],
              [-81.926063316508021, 35.750238078053762, 0.0],
              [-81.927299383330819, 35.75039781093448, 0.0],
              [-81.92788931640456, 35.75030601100287, 0.0],
              [-81.928094116449472, 35.750123011542783, 0.0],
              [-81.928009716834808, 35.749939811571203, 0.0],
              [-81.92764438385133, 35.749642277342041, 0.0],
              [-81.926576516349755, 35.74927641095767, 0.0],
              [-81.926351516109477, 35.749001811066144, 0.0],
              [-81.926351117152763, 35.748429210678609, 0.0],
              [-81.926631983845823, 35.74836027746494, 0.0],
              [-81.927081716303618, 35.748657811480172, 0.0],
              [-81.927812183624084, 35.748749077351796, 0.0],
              [-81.928570916297161, 35.749023411528015, 0.0],
              [-81.928795583193704, 35.74904621134592, 0.0],
              [-81.929048382982657, 35.748931610933646, 0.0],
              [-81.92907631623514, 35.74874841097725, 0.0],
              [-81.928963783283706, 35.748542411123282, 0.0],
              [-81.928401716323904, 35.748153278085653, 0.0],
              [-81.928289116936014, 35.747947278241732, 0.0],
              [-81.928204183177186, 35.74721461158834, 0.0],
              [-81.927923183357692, 35.746962877764524, 0.0],
              [-81.927332982683424, 35.746688278105076, 0.0],
              [-81.926855316684808, 35.746299210719016, 0.0],
              [-81.926798783400116, 35.746047277403413, 0.0],
              [-81.927051716398822, 35.746024277676966, 0.0],
              [-81.92786658377689, 35.746367277682417, 0.0],
              [-81.928568783651556, 35.746344211143033, 0.0],
              [-81.928933716362039, 35.745885878134928, 0.0],
              [-81.929298716611513, 35.745885677848904, 0.0],
              [-81.92935511623152, 35.746069011069054, 0.0],
              [-81.929270916427299, 35.746137677544205, 0.0],
              [-81.929327516674718, 35.746710078202625, 0.0],
              [-81.929496116375006, 35.746824610892638, 0.0],
              [-81.929805183550926, 35.746824410999302, 0.0],
              [-81.93014218291458, 35.746572411541926, 0.0],
              [-81.930169916491437, 35.746343277716718, 0.0],
              [-81.930001183068924, 35.746091611549829, 0.0],
              [-81.930000983619024, 35.745862611190404, 0.0],
              [-81.930253717090821, 35.745702078165976, 0.0],
              [-81.931124583180008, 35.745633011207012, 0.0],
              [-81.931264916037023, 35.745564078350171, 0.0],
              [-81.93126438275165, 35.745037411086848, 0.0],
              [-81.930730383512923, 35.744533877490895, 0.0],
              [-81.930814183603317, 35.743961410730797, 0.0],
              [-81.931459716155317, 35.74331987801034, 0.0],
              [-81.931936716987735, 35.742632477371046, 0.0],
              [-81.932386116876899, 35.742563610896617, 0.0],
              [-81.932751383037228, 35.74274661079464, 0.0],
              [-81.93331298353138, 35.742563077636575, 0.0],
              [-81.933565583277598, 35.7423340109018, 0.0],
              [-81.933790383609008, 35.742288010851809, 0.0],
              [-81.934549183590931, 35.742699877934854, 0.0],
              [-81.935616582716847, 35.742882411548088, 0.0],
              [-81.936936716414323, 35.742721277382408, 0.0],
              [-81.937048982965891, 35.742629611279085, 0.0],
              [-81.937076782648958, 35.742332011048859, 0.0],
              [-81.936992516875108, 35.742240410757162, 0.0],
              [-81.936992182679944, 35.742011410930935, 0.0],
              [-81.937244982875484, 35.74194261138171, 0.0],
              [-81.93749791651814, 35.742057010871065, 0.0],
              [-81.937778983145634, 35.742308678261757, 0.0],
              [-81.937807383295834, 35.742606277438348, 0.0],
              [-81.937975983283906, 35.742720811442013, 0.0],
              [-81.938200717084797, 35.742720678061886, 0.0],
              [-81.938509383427686, 35.742422811375562, 0.0],
              [-81.938565516805852, 35.7422166107038, 0.0],
              [-81.938256183123471, 35.741850477447542, 0.0],
              [-81.938368316030477, 35.74159847794644, 0.0],
              [-81.938789583827813, 35.741552477868652, 0.0],
              [-81.938986316057097, 35.741689677514103, 0.0],
              [-81.939042716479818, 35.741850011085923, 0.0],
              [-81.939407916859821, 35.742055878242418, 0.0],
              [-81.939941116360913, 35.741414277904532, 0.0],
              [-81.940306182997901, 35.741391078258161, 0.0],
              [-81.940643383063758, 35.741574077470446, 0.0],
              [-81.941261383351929, 35.741596677657633, 0.0],
              [-81.942412916939645, 35.741344077855295, 0.0],
              [-81.94244078351916, 35.741183810882681, 0.0],
              [-81.941962916543744, 35.740817677494782, 0.0],
              [-81.941934716333279, 35.740634477932034, 0.0],
              [-81.942158983830709, 35.740268011183439, 0.0],
              [-81.942524116318154, 35.740176211257342, 0.0],
              [-81.943674917054537, 35.739259411173641, 0.0],
              [-81.943814916230579, 35.738801277960867, 0.0],
              [-81.944600516392512, 35.737976277679984, 0.0],
              [-81.944656383790345, 35.737701478040272, 0.0],
              [-81.944487783488711, 35.737495477744851, 0.0],
              [-81.94451498383664, 35.73664821160915, 0.0],
              [-81.944823583048077, 35.736281677614038, 0.0],
              [-81.945104382992156, 35.736189877541271, 0.0],
              [-81.945188783311167, 35.736304277621471, 0.0],
              [-81.945132916354396, 35.73669367832688, 0.0],
              [-81.945133716111897, 35.737472211161133, 0.0],
              [-81.945443183119323, 35.737930078287995, 0.0],
              [-81.945443516281046, 35.73827361148949, 0.0],
              [-81.944994716083073, 35.738937877734713, 0.0],
              [-81.944854717045885, 35.739304477871855, 0.0],
              [-81.94404118267822, 35.740404077393741, 0.0],
              [-81.94325591608704, 35.741710011144519, 0.0],
              [-81.942890983729754, 35.741916277355919, 0.0],
              [-81.942132583812636, 35.742008278313165, 0.0],
              [-81.941907982959179, 35.742100077501924, 0.0],
              [-81.941880183766088, 35.742443610793067, 0.0],
              [-81.942470116226417, 35.742511878277952, 0.0],
              [-81.942835116532237, 35.742328611628906, 0.0],
              [-81.94322858312637, 35.742511478219043, 0.0],
              [-81.943986916226123, 35.742533878124028, 0.0],
              [-81.944043783814649, 35.74322087783316, 0.0],
              [-81.944324916210732, 35.743403877949525, 0.0],
              [-81.944269383226782, 35.744205477429446, 0.0],
              [-81.945533582635946, 35.74429621079527, 0.0],
              [-81.945898982680518, 35.744547878128472, 0.0],
              [-81.946124316546573, 35.745120211354006, 0.0],
              [-81.946377183540292, 35.745280278265341, 0.0],
              [-81.948119316026592, 35.745737278071054, 0.0],
              [-81.95045198304021, 35.746880610746111, 0.0],
              [-81.951772382827542, 35.747063011199771, 0.0],
              [-81.952305983657581, 35.746948077970991, 0.0],
              [-81.952586583345905, 35.74669601069958, 0.0],
              [-81.952586383847787, 35.74651287830789, 0.0],
              [-81.952361716850461, 35.746490010854636, 0.0],
              [-81.952108983508751, 35.746581811260974, 0.0],
              [-81.952024583332232, 35.746490277528522, 0.0],
              [-81.95199618358609, 35.746169677916377, 0.0],
              [-81.951771382646228, 35.746055411319823, 0.0],
              [-81.950507316435235, 35.746056211053393, 0.0],
              [-81.950029116343785, 35.74552987741945, 0.0],
              [-81.949438783637163, 35.745049411290744, 0.0],
              [-81.948089716090109, 35.744248810885914, 0.0],
              [-81.947948583029614, 35.743539010713256, 0.0],
              [-81.947864183594689, 35.743447411019972, 0.0],
              [-81.947105583678109, 35.743196077410055, 0.0],
              [-81.947105316335481, 35.742921278008495, 0.0],
              [-81.947441716267591, 35.742417211078589, 0.0],
              [-81.947244783363089, 35.742050877905982, 0.0],
              [-81.947581716671479, 35.741959011402393, 0.0],
              [-81.947834383820648, 35.74179861092108, 0.0],
              [-81.947834116116212, 35.74150087761727, 0.0],
              [-81.947581116878538, 35.74127207793989, 0.0],
              [-81.947271982708173, 35.741157811382095, 0.0],
              [-81.947103316546148, 35.7409976116084, 0.0],
              [-81.947130982687824, 35.740585411673941, 0.0],
              [-81.946765583285014, 35.74028801085349, 0.0],
              [-81.946765317113787, 35.739967410838091, 0.0],
              [-81.947719983767485, 35.739737677558168, 0.0],
              [-81.948000716447453, 35.739485610878255, 0.0],
              [-81.948084717062812, 35.739256478290621, 0.0],
              [-81.947972116161935, 35.739004678025829, 0.0],
              [-81.947943716086286, 35.738707010923733, 0.0],
              [-81.948252583209779, 35.738638077570798, 0.0],
              [-81.948618183607891, 35.739073011495293, 0.0],
              [-81.948955383344853, 35.739187277579767, 0.0],
              [-81.949404583093326, 35.739026677417506, 0.0],
              [-81.949685182726071, 35.738751678044103, 0.0],
              [-81.950386383506853, 35.737789277439489, 0.0],
              [-81.951060183287296, 35.737399610909556, 0.0],
              [-81.951115582844324, 35.736735411484574, 0.0],
              [-81.951789716230834, 35.736712078010605, 0.0],
              [-81.951958183187926, 35.736643277903156, 0.0],
              [-81.951986116754142, 35.736505878008437, 0.0],
              [-81.951451916969432, 35.736002477506247, 0.0],
              [-81.951367516350047, 35.735865011567292, 0.0],
              [-81.951451583756693, 35.735658878323783, 0.0],
              [-81.951788516560015, 35.735590011013421, 0.0],
              [-81.9524347167157, 35.735795611469946, 0.0],
              [-81.952855916573967, 35.73574947750344, 0.0],
              [-81.953754316581708, 35.735290878002054, 0.0],
              [-81.953950582876786, 35.735084677791335, 0.0],
              [-81.954090783689068, 35.734764011184446, 0.0],
              [-81.954118316419923, 35.734283011672254, 0.0],
              [-81.953190382822072, 35.733299011043158, 0.0],
              [-81.953246383195776, 35.733092811101898, 0.0],
              [-81.953442916955993, 35.733024011574976, 0.0],
              [-81.95400491682274, 35.733229677680285, 0.0],
              [-81.954650782851402, 35.733229278361108, 0.0],
              [-81.955099517047685, 35.732541877561111, 0.0],
              [-81.955520716906406, 35.732450077381095, 0.0],
              [-81.955857982921742, 35.732747477536194, 0.0],
              [-81.9558583833781, 35.733113877441831, 0.0],
              [-81.955662183070999, 35.733526277814946, 0.0],
              [-81.955522383160371, 35.734075877486923, 0.0],
              [-81.955438183519163, 35.734144611509549, 0.0],
              [-81.955298516523243, 35.734854678224679, 0.0],
              [-81.955524116195278, 35.735724811287632, 0.0],
              [-81.957070116753755, 35.736777011214357, 0.0],
              [-81.957828383562941, 35.736753611644772, 0.0],
              [-81.958081116448753, 35.736661677787914, 0.0],
              [-81.958305516072926, 35.736386678043701, 0.0],
              [-81.959372316438575, 35.735973811296397, 0.0],
              [-81.959877783623952, 35.735904677669495, 0.0],
              [-81.959990517016195, 35.736179411425219, 0.0],
              [-81.958615516091982, 35.73730261086606, 0.0],
              [-81.95847531633396, 35.737554611503761, 0.0],
              [-81.958588117132791, 35.737943811558878, 0.0],
              [-81.959432116102519, 35.739019410869119, 0.0],
              [-81.95982571668489, 35.739385610992812, 0.0],
              [-81.960162783378138, 35.739499811189859, 0.0],
              [-81.960359316647626, 35.739339277395011, 0.0],
              [-81.960386783477588, 35.73885841112503, 0.0],
              [-81.961032782768982, 35.738858011331033, 0.0],
              [-81.961397783195835, 35.738720211649941, 0.0],
              [-81.961902783113345, 35.738261810939704, 0.0],
              [-81.963559916931715, 35.738191877972568, 0.0],
              [-81.965019916979486, 35.73770967789455, 0.0],
              [-81.965750116050245, 35.737686278265471, 0.0],
              [-81.966199783710323, 35.737960677357165, 0.0],
              [-81.96678998271409, 35.738189211462164, 0.0],
              [-81.966986583640619, 35.73818907772214, 0.0],
              [-81.967211116149883, 35.737937011453525, 0.0],
              [-81.967351116365052, 35.737593278312438, 0.0],
              [-81.96726491602945, 35.736173678304276, 0.0],
              [-81.967320716489027, 35.735784278249398, 0.0],
              [-81.967853316106329, 35.734959411099162, 0.0],
              [-81.967824916863322, 35.734661811629877, 0.0],
              [-81.967571783051781, 35.7344100109732, 0.0],
              [-81.967038116071421, 35.734341810853856, 0.0],
              [-81.966869317037762, 35.734135811409928, 0.0],
              [-81.966952983253776, 35.7337464781546, 0.0],
              [-81.967261317137215, 35.733150810997763, 0.0],
              [-81.967317117010111, 35.732853078155991, 0.0],
              [-81.967092316758325, 35.732761677919662, 0.0],
              [-81.966614782865022, 35.732762078068305, 0.0],
              [-81.965435583823918, 35.73310647812027, 0.0],
              [-81.96509858347774, 35.733106811674418, 0.0],
              [-81.964985983010919, 35.732992410930478, 0.0],
              [-81.964957583192188, 35.732717611576227, 0.0],
              [-81.965097783511325, 35.732534277804135, 0.0],
              [-81.965855716158742, 35.732167277522308, 0.0],
              [-81.966444982749238, 35.731754477901568, 0.0],
              [-81.966444182818037, 35.731136278014688, 0.0],
              [-81.966078916457334, 35.730838878037162, 0.0],
              [-81.965291983523258, 35.730450211198047, 0.0],
              [-81.964561583036286, 35.730267610958499, 0.0],
              [-81.964055583547278, 35.72992447765418, 0.0],
              [-81.963829783231148, 35.729031611505235, 0.0],
              [-81.963492182935354, 35.728528011176209, 0.0],
              [-81.963407183350157, 35.727864077899227, 0.0],
              [-81.963126182637311, 35.727658077485636, 0.0],
              [-81.962648582700268, 35.727498211115396, 0.0],
              [-81.962648183275121, 35.727269211118362, 0.0],
              [-81.963602983172393, 35.727245477511154, 0.0],
              [-81.964108916678228, 35.727565678217978, 0.0],
              [-81.964502116419268, 35.727588277800159, 0.0],
              [-81.96641078269765, 35.726739478064431, 0.0],
              [-81.96680331675843, 35.726235411364762, 0.0],
              [-81.96711218370281, 35.726166411578056, 0.0],
              [-81.967336983513306, 35.726280677408454, 0.0],
              [-81.968291716024396, 35.72618827812326, 0.0],
              [-81.968628583193052, 35.726073477576094, 0.0],
              [-81.9687687165207, 35.725867277417741, 0.0],
              [-81.968655316776292, 35.725020077594358, 0.0],
              [-81.968429383256179, 35.724012811514157, 0.0],
              [-81.968035783724346, 35.723623810881271, 0.0],
              [-81.967248983331615, 35.723166411293498, 0.0],
              [-81.966546516827009, 35.722892211324378, 0.0],
              [-81.966041116792283, 35.722892610905888, 0.0],
              [-81.965479516404713, 35.722984611705726, 0.0],
              [-81.965282783078365, 35.722916077431414, 0.0],
              [-81.965282516184104, 35.722732877530269, 0.0],
              [-81.965422783682399, 35.722572477687137, 0.0],
              [-81.966236983693108, 35.722434411590996, 0.0],
              [-81.966461382793, 35.722182277637906, 0.0],
              [-81.966375783185697, 35.721151878125291, 0.0],
              [-81.966263383093462, 35.72096867751997, 0.0],
              [-81.966010383688726, 35.720831611513702, 0.0],
              [-81.965448716190437, 35.720717477633052, 0.0],
              [-81.964185117109324, 35.720787210714249, 0.0],
              [-81.964044583768739, 35.720695811578956, 0.0],
              [-81.96415658359065, 35.720375078030287, 0.0],
              [-81.964661782989111, 35.720168478218312, 0.0],
              [-81.966430783492271, 35.720167078258477, 0.0],
              [-81.966795716146677, 35.720052411117578, 0.0],
              [-81.967076316409191, 35.719823078102351, 0.0],
              [-81.967075783268697, 35.719433677588341, 0.0],
              [-81.966625916499964, 35.718976077786373, 0.0],
              [-81.965839382631813, 35.718724877939664, 0.0],
              [-81.96544598306869, 35.718427478048682, 0.0],
              [-81.965361182970014, 35.718061078329853, 0.0],
              [-81.96493938280743, 35.717557677522059, 0.0],
              [-81.964349383438289, 35.717329210860889, 0.0],
              [-81.963787782623271, 35.717352611116333, 0.0],
              [-81.963310716216668, 35.717536078291758, 0.0],
              [-81.962805382753913, 35.717628211498798, 0.0],
              [-81.962692982665644, 35.717513811704052, 0.0],
              [-81.962692716626165, 35.717261877690419, 0.0],
              [-81.963141715983696, 35.716986611228151, 0.0],
              [-81.964067916342998, 35.71666527802472, 0.0],
              [-81.964376182815101, 35.71620707763428, 0.0],
              [-81.964712982607736, 35.71613807743725, 0.0],
              [-81.965162783638604, 35.716504077845634, 0.0],
              [-81.965387783414826, 35.71687041106793, 0.0],
              [-81.966568783194262, 35.718060211212894, 0.0],
              [-81.967102515959752, 35.718265877495355, 0.0],
              [-81.967692583737886, 35.718677611217039, 0.0],
              [-81.967833383488141, 35.718906411455436, 0.0],
              [-81.968170783479209, 35.719318410823178, 0.0],
              [-81.968760583119249, 35.71945527819009, 0.0],
              [-81.969237916365657, 35.719340411644509, 0.0],
              [-81.969574583288804, 35.719088277575345, 0.0],
              [-81.969630183085144, 35.718698877742781, 0.0],
              [-81.970611916746151, 35.717782011537807, 0.0],
              [-81.970920717088248, 35.71771307767321, 0.0],
              [-81.97142651600133, 35.7180102779134, 0.0],
              [-81.971426916305845, 35.718308011230974, 0.0],
              [-81.970921917088248, 35.718674811237427, 0.0],
              [-81.970781916619828, 35.718995677836155, 0.0],
              [-81.970473316066446, 35.719247811246994, 0.0],
              [-81.970501783157204, 35.719522611138146, 0.0],
              [-81.970698583300035, 35.719636878078305, 0.0],
              [-81.971344316244441, 35.719544811518908, 0.0],
              [-81.971596916330142, 35.719407211529763, 0.0],
              [-81.971764983518639, 35.719132211620618, 0.0],
              [-81.972326517087538, 35.719085877930461, 0.0],
              [-81.9731409166492, 35.71913101095592, 0.0],
              [-81.97389898364132, 35.718992878060376, 0.0],
              [-81.97479698379162, 35.718579877888885, 0.0],
              [-81.977547517107794, 35.717524011208184, 0.0],
              [-81.9777435826079, 35.717272011265202, 0.0],
              [-81.977771383756703, 35.717043011316584, 0.0],
              [-81.977545983667952, 35.716493611532286, 0.0],
              [-81.977713382623975, 35.715623211087845, 0.0],
              [-81.97802178340703, 35.715325211564945, 0.0],
              [-81.978414716096793, 35.715164478053197, 0.0],
              [-81.97863958354111, 35.715347477396165, 0.0],
              [-81.978611783591376, 35.715599477960524, 0.0],
              [-81.978359516082833, 35.715897410814399, 0.0],
              [-81.978247716988378, 35.716263877652473, 0.0],
              [-81.978276117044999, 35.716515677994344, 0.0],
              [-81.978613317081098, 35.716675677395514, 0.0],
              [-81.979118583664714, 35.716560810946227, 0.0],
              [-81.98071811669503, 35.715849478380463, 0.0],
              [-81.982878316779036, 35.714450477776928, 0.0],
              [-81.98315911600092, 35.71445021158079, 0.0],
              [-81.983580782783008, 35.714862078266798, 0.0],
              [-81.983834115950785, 35.715228210840088, 0.0],
              [-81.983722116877018, 35.715434411681635, 0.0],
              [-81.981224517004748, 35.716490211695572, 0.0],
              [-81.980298582947341, 35.717063478291259, 0.0],
              [-81.980102316676948, 35.717292611590722, 0.0],
              [-81.980018717033914, 35.717682077595406, 0.0],
              [-81.980272316270543, 35.718300211550321, 0.0],
              [-81.980862916638671, 35.718918011640163, 0.0],
              [-81.980862982979374, 35.719009611290019, 0.0],
              [-81.981509583346025, 35.719535610776504, 0.0],
              [-81.981623116376312, 35.720382810756597, 0.0],
              [-81.982719116525516, 35.721022878192805, 0.0],
              [-81.983421515959392, 35.721251278233694, 0.0],
              [-81.985332782668962, 35.722440211081981, 0.0],
              [-81.985979383200601, 35.722920478286603, 0.0],
              [-81.987496582886706, 35.723422811208259, 0.0],
              [-81.987945983474631, 35.723491077786903, 0.0],
              [-81.988226716752123, 35.723399210806832, 0.0],
              [-81.988282582615568, 35.723216011127576, 0.0],
              [-81.987719916042238, 35.722575411370805, 0.0],
              [-81.987297916053649, 35.72200321124356, 0.0],
              [-81.987072515965309, 35.721476678274968, 0.0],
              [-81.987015115997764, 35.720652411417952, 0.0],
              [-81.987126783363877, 35.720217210986497, 0.0],
              [-81.987687383554345, 35.719575477717946, 0.0],
              [-81.988304783211646, 35.719322810946508, 0.0],
              [-81.98864171691821, 35.719276678272138, 0.0],
              [-81.989454583100994, 35.71829127802674, 0.0],
              [-81.990772382841271, 35.71703041140519, 0.0],
              [-81.991276317084044, 35.715976610860466, 0.0],
              [-81.99172451658842, 35.715289077547183, 0.0],
              [-81.991724316389835, 35.715174611701926, 0.0],
              [-81.991836383503625, 35.715105811236377, 0.0],
              [-81.992031582714574, 35.714212411630882, 0.0],
              [-81.992396383222982, 35.713983010884725, 0.0],
              [-81.994249516107075, 35.71393541144964, 0.0],
              [-81.994586316437392, 35.713843411191135, 0.0],
              [-81.99565331686992, 35.713888010798257, 0.0],
              [-81.996243317043422, 35.714024811522499, 0.0],
              [-81.996608916718429, 35.714390811388434, 0.0],
              [-81.996609116142352, 35.71459701152763, 0.0],
              [-81.996749782762521, 35.714734211486778, 0.0],
              [-81.997171116424198, 35.714848277528191, 0.0],
              [-81.99728371689045, 35.715031410980508, 0.0],
              [-81.996469382597539, 35.714940677557728, 0.0],
              [-81.996385182570947, 35.714986477791243, 0.0],
              [-81.996470183549661, 35.715467278211648, 0.0],
              [-81.996301782958582, 35.715536210808452, 0.0],
              [-81.996020516078019, 35.715193011264248, 0.0],
              [-81.995627382677, 35.715193410760399, 0.0],
              [-81.995430983044869, 35.715353878199799, 0.0],
              [-81.995515782572028, 35.715651477554637, 0.0],
              [-81.996106783572969, 35.7165210776447, 0.0],
              [-81.996107317052918, 35.716864611336867, 0.0],
              [-81.995826516939601, 35.716819011105727, 0.0],
              [-81.995657783558883, 35.716704811549057, 0.0],
              [-81.995404383222379, 35.716292877932183, 0.0],
              [-81.995123517101803, 35.716201478360844, 0.0],
              [-81.995011315932047, 35.71629321150315, 0.0],
              [-81.995011716333238, 35.716545077565165, 0.0],
              [-81.995322316178104, 35.717644010979363, 0.0],
              [-81.995463183332191, 35.717850011115814, 0.0],
              [-81.995828383450885, 35.717941211013262, 0.0],
              [-81.997035782621197, 35.717962677799228, 0.0],
              [-81.998271782911303, 35.71828201136146, 0.0],
              [-81.999086716504422, 35.71862467771065, 0.0],
              [-81.999182116682334, 35.718715211346698, 0.0],
              [-81.99925538339015, 35.718784810901909, 0.0],
              [-81.998666116859823, 35.718945677390963, 0.0],
              [-81.998469382929585, 35.718923011424991, 0.0],
              [-81.997485916057911, 35.718489010984733, 0.0],
              [-81.996840183173049, 35.718535477998429, 0.0],
              [-81.996784316842593, 35.718718810737954, 0.0],
              [-81.99700971651383, 35.719222411080757, 0.0],
              [-81.997374983372836, 35.719382278262799, 0.0],
              [-81.997964716013428, 35.719381610810778, 0.0],
              [-81.998189582613307, 35.719495878083784, 0.0],
              [-81.998189716992272, 35.719633277875758, 0.0],
              [-81.997712916276527, 35.72000021145071, 0.0],
              [-81.997461116847433, 35.72055001163541, 0.0],
              [-81.997264716701181, 35.720664678242279, 0.0],
              [-81.997152317047167, 35.72064187817957, 0.0],
              [-81.997067182882063, 35.720092477745396, 0.0],
              [-81.996870182683949, 35.719772011262329, 0.0],
              [-81.996561116352069, 35.719635011468696, 0.0],
              [-81.996195916233432, 35.719589610996437, 0.0],
              [-81.996139183405091, 35.719200411447147, 0.0],
              [-81.99546538261302, 35.719292677455122, 0.0],
              [-81.99526871670237, 35.719201277981895, 0.0],
              [-81.995268116152474, 35.718834811062763, 0.0],
              [-81.995352182758552, 35.718720277522245, 0.0],
              [-81.995323716134436, 35.718514210944093, 0.0],
              [-81.994845583309711, 35.717988077563227, 0.0],
              [-81.994788516482828, 35.717461411493765, 0.0],
              [-81.994647582676507, 35.717118011169447, 0.0],
              [-81.994422583200091, 35.716889277919755, 0.0],
              [-81.993664117034029, 35.716684011219336, 0.0],
              [-81.993383516977318, 35.716753011394864, 0.0],
              [-81.992934916768647, 35.717211410796423, 0.0],
              [-81.991840583383947, 35.717762077891024, 0.0],
              [-81.991840782604712, 35.717876610784202, 0.0],
              [-81.992542916800573, 35.717990411299667, 0.0],
              [-81.992430916806228, 35.718173678350006, 0.0],
              [-81.991224182573646, 35.718678810953314, 0.0],
              [-81.990466716392191, 35.719137611105609, 0.0],
              [-81.990158383704014, 35.719504211520231, 0.0],
              [-81.990018982909518, 35.72016841104201, 0.0],
              [-81.990104316929703, 35.720832611442582, 0.0],
              [-81.990386716265689, 35.721885611485099, 0.0],
              [-81.990668183556608, 35.722274610735617, 0.0],
              [-81.990640182912898, 35.722366277536494, 0.0],
              [-81.991427982970208, 35.723396010881643, 0.0],
              [-81.991625315959524, 35.723853677648016, 0.0],
              [-81.99207738367943, 35.725593810816356, 0.0],
              [-81.992527183074557, 35.725891011606258, 0.0],
              [-81.993117316257027, 35.726096478180459, 0.0],
              [-81.993314116875112, 35.72625647812135, 0.0],
              [-81.993370982843729, 35.726829011674184, 0.0],
              [-81.993483783195174, 35.727103677530032, 0.0],
              [-81.993428316037679, 35.727515877693762, 0.0],
              [-81.993232316400508, 35.72788247802972, 0.0],
              [-81.993429516453546, 35.728225810854042, 0.0],
              [-81.993401582959976, 35.72838607758662, 0.0],
              [-81.992784982869395, 35.729142411224835, 0.0],
              [-81.992532516385197, 35.729280078262711, 0.0],
              [-81.991718316337568, 35.729510011418377, 0.0],
              [-81.99146598268257, 35.72976207799919, 0.0],
              [-81.991438582944227, 35.730220077732817, 0.0],
              [-81.991495383750873, 35.730632211203115, 0.0],
              [-81.990989782960625, 35.730564077640842, 0.0],
              [-81.990680516065055, 35.730312477925146, 0.0],
              [-81.990399915963792, 35.730495877714986, 0.0],
              [-81.990063582938518, 35.730977211231696, 0.0],
              [-81.989811116123292, 35.731160678317693, 0.0],
              [-81.989221716744197, 35.731367277430728, 0.0],
              [-81.986975316923079, 35.731690211101998, 0.0],
              [-81.984221983394264, 35.731051810920391, 0.0],
              [-81.984193516603597, 35.730754010830942, 0.0],
              [-81.985175982634786, 35.730409477718915, 0.0],
              [-81.986410783160878, 35.729744277412443, 0.0],
              [-81.986438382759445, 35.729446477578456, 0.0],
              [-81.986325983537128, 35.72937801136591, 0.0],
              [-81.98553991682499, 35.729516078150489, 0.0],
              [-81.983264783234091, 35.729403811311904, 0.0],
              [-81.981944916303263, 35.729542477897027, 0.0],
              [-81.98065391680322, 35.73009327828683, 0.0],
              [-81.98012051700006, 35.730231210932089, 0.0],
              [-81.979924116988101, 35.730437411456656, 0.0],
              [-81.979784783247169, 35.731193410861778, 0.0],
              [-81.979672716747629, 35.731330877786569, 0.0],
              [-81.979251516876303, 35.731422877833872, 0.0],
              [-81.979055383198499, 35.731766477944767, 0.0],
              [-81.979055782733795, 35.732110011591189, 0.0],
              [-81.979477982972611, 35.732750811459184, 0.0],
              [-81.979956182985546, 35.733208277741774, 0.0],
              [-81.981249516331232, 35.734169011160922, 0.0],
              [-81.981446383211463, 35.73444347815709, 0.0],
              [-81.981474983107532, 35.73480987774807, 0.0],
              [-81.98099711693898, 35.734512611569791, 0.0],
              [-81.97936651654274, 35.733300477383025, 0.0],
              [-81.978606783099423, 35.732339411449317, 0.0],
              [-81.978493983742851, 35.731996011199797, 0.0],
              [-81.978548916846719, 35.731125811370582, 0.0],
              [-81.978717117100388, 35.730805011522847, 0.0],
              [-81.979530382881777, 35.730025611644116, 0.0],
              [-81.981494916710162, 35.729039211274383, 0.0],
              [-81.982309316620473, 35.728969678258522, 0.0],
              [-81.982730716233903, 35.729060811060016, 0.0],
              [-81.983095716519955, 35.729014611106642, 0.0],
              [-81.983432382836597, 35.728876877829755, 0.0],
              [-81.983937316595217, 35.728395611139959, 0.0],
              [-81.984301716073801, 35.727914410933579, 0.0],
              [-81.984273116702099, 35.727548010950656, 0.0],
              [-81.983795116634724, 35.727136211127245, 0.0],
              [-81.9831769160688, 35.726862011172386, 0.0],
              [-81.982109583553651, 35.726725611647176, 0.0],
              [-81.981968917065913, 35.726519678025248, 0.0],
              [-81.981995716536048, 35.725741077693584, 0.0],
              [-81.982135583278136, 35.725351478045056, 0.0],
              [-81.982106916048949, 35.724893611281097, 0.0],
              [-81.981600516499626, 35.724252877875557, 0.0],
              [-81.981121916568497, 35.72342901119535, 0.0],
              [-81.980390916070689, 35.722765611669189, 0.0],
              [-81.979940516538349, 35.722056077577257, 0.0],
              [-81.979210583302091, 35.722171210939308, 0.0],
              [-81.979209916499343, 35.721690410814539, 0.0],
              [-81.978197716639258, 35.720729477975318, 0.0],
              [-81.977719583489375, 35.720157478048229, 0.0],
              [-81.97718538284532, 35.719814411438605, 0.0],
              [-81.976230517113791, 35.719632077692516, 0.0],
              [-81.974826516733089, 35.719724877843191, 0.0],
              [-81.972945716590715, 35.720115877841984, 0.0],
              [-81.9713457160302, 35.720598077584349, 0.0],
              [-81.969885716023114, 35.720805410794853, 0.0],
              [-81.969015516167914, 35.721081011657475, 0.0],
              [-81.968258116093068, 35.721631277767351, 0.0],
              [-81.968145983045716, 35.721814477658342, 0.0],
              [-81.968146316916972, 35.722089411186538, 0.0],
              [-81.968933317032736, 35.722729877453126, 0.0],
              [-81.970337983703558, 35.723186677535899, 0.0],
              [-81.970703716960372, 35.723758877882958, 0.0],
              [-81.971377983405176, 35.723941477768854, 0.0],
              [-81.972220383045567, 35.723826277566289, 0.0],
              [-81.972557516253289, 35.723963410782922, 0.0],
              [-81.972501583153289, 35.724169611019519, 0.0],
              [-81.972221183616611, 35.72439881119422, 0.0],
              [-81.972306183185665, 35.725017010839018, 0.0],
              [-81.972559182787137, 35.725245811089756, 0.0],
              [-81.972671983759653, 35.725635011677142, 0.0],
              [-81.972448583734874, 35.726620011371757, 0.0],
              [-81.971944582701582, 35.727742478140073, 0.0],
              [-81.971523716540645, 35.72806341150222, 0.0],
              [-81.970962316589819, 35.72829287784193, 0.0],
              [-81.970204316653167, 35.728476810727457, 0.0],
              [-81.967845382908621, 35.728593210851464, 0.0],
              [-81.967255916489421, 35.728776878126901, 0.0],
              [-81.966806983407565, 35.729143611411558, 0.0],
              [-81.966722983267331, 35.729418611280302, 0.0],
              [-81.966863717044419, 35.729624611186452, 0.0],
              [-81.967622717002143, 35.730173611293523, 0.0],
              [-81.968718516345817, 35.730584811185658, 0.0],
              [-81.969392716319533, 35.730698610862824, 0.0],
              [-81.970206916268808, 35.730423211692688, 0.0],
              [-81.970431583508272, 35.730445877589851, 0.0],
              [-81.970769116125268, 35.730880811361907, 0.0],
              [-81.970993782872483, 35.730880611381885, 0.0],
              [-81.971302582698158, 35.730765811099353, 0.0],
              [-81.972789783186968, 35.729848478323788, 0.0],
              [-81.973070583466736, 35.729756611257486, 0.0],
              [-81.973407583021384, 35.729779211019206, 0.0],
              [-81.973408116193994, 35.730145677996617, 0.0],
              [-81.973295982916667, 35.730329011434819, 0.0],
              [-81.971696516275017, 35.731406678200941, 0.0],
              [-81.971612516430383, 35.731612811593301, 0.0],
              [-81.971696916686, 35.731681478309923, 0.0],
              [-81.973129316554932, 35.731703211083811, 0.0],
              [-81.9732979161858, 35.731794611277465, 0.0],
              [-81.973298116293549, 35.73195487775655, 0.0],
              [-81.973129783042182, 35.732069478109715, 0.0],
              [-81.971922382879796, 35.732276611583401, 0.0],
              [-81.971754116729159, 35.732460078303305, 0.0],
              [-81.971670516776896, 35.733009678320187, 0.0],
              [-81.971362183467377, 35.733468011338616, 0.0],
              [-81.970970716291774, 35.734796478346603, 0.0],
              [-81.970999716924823, 35.735414811112371, 0.0],
              [-81.971421116648102, 35.735597677678975, 0.0],
              [-81.972460516609416, 35.735711211549003, 0.0],
              [-81.972994383060424, 35.735939678170332, 0.0],
              [-81.97364051639191, 35.736030677957658, 0.0],
              [-81.974512316158552, 35.736808611637969, 0.0],
              [-81.974400182825534, 35.736969011600223, 0.0],
              [-81.973782117033224, 35.736969611485854, 0.0],
              [-81.972967583158791, 35.736878678161389, 0.0],
              [-81.972602116116363, 35.736604211238166, 0.0],
              [-81.972011915996845, 35.736398677526282, 0.0],
              [-81.971562716796711, 35.736467678253597, 0.0],
              [-81.971422316688077, 35.736582277791392, 0.0],
              [-81.971142316786555, 35.737246611194429, 0.0],
              [-81.970891316347988, 35.738620878338011, 0.0],
              [-81.970499515985026, 35.739720411692055, 0.0],
              [-81.970499982676813, 35.740041011046181, 0.0],
              [-81.971510983627482, 35.73994861116303, 0.0],
              [-81.971820183337442, 35.740085678194774, 0.0],
              [-81.972606783643926, 35.740222410901005, 0.0],
              [-81.972550783726845, 35.740382810762711, 0.0],
              [-81.972326316549427, 35.740566210919958, 0.0],
              [-81.971736783570606, 35.740841411508903, 0.0],
              [-81.971849783563016, 35.741276411168883, 0.0],
              [-81.972355583401381, 35.741482011587074, 0.0],
              [-81.972861316355562, 35.741573211498874, 0.0],
              [-81.973816316478164, 35.741549611216641, 0.0],
              [-81.973873516123774, 35.742259410710176, 0.0],
              [-81.974492183178299, 35.74285421075988, 0.0],
              [-81.974885783465396, 35.743082877887801, 0.0],
              [-81.974830783307368, 35.744021877930585, 0.0],
              [-81.974550183453545, 35.744205277829344, 0.0],
              [-81.974213117117884, 35.744228478218737, 0.0],
              [-81.974100716067255, 35.744137011673111, 0.0],
              [-81.974043583252183, 35.743427210993524, 0.0],
              [-81.973537383506141, 35.742992411473345, 0.0],
              [-81.971794783345004, 35.742215411521066, 0.0],
              [-81.971232983581501, 35.742193011430629, 0.0],
              [-81.970614782687051, 35.741987477820111, 0.0],
              [-81.970249716824569, 35.742079277978426, 0.0],
              [-81.969997317022816, 35.742308477705876, 0.0],
              [-81.969997783329802, 35.742697811278887, 0.0],
              [-81.970195116520728, 35.74327007788154, 0.0],
              [-81.970083116619662, 35.743613678301358, 0.0],
              [-81.969802316095951, 35.743591077703108, 0.0],
              [-81.969689317112753, 35.743179011483022, 0.0],
              [-81.969492383467539, 35.742904277640207, 0.0],
              [-81.969071115998261, 35.742927611164149, 0.0],
              [-81.968509583142037, 35.743088277910822, 0.0],
              [-81.968341116206886, 35.743226010936148, 0.0],
              [-81.967723182988166, 35.743203611214973, 0.0],
              [-81.966683116694639, 35.742609010778395, 0.0],
              [-81.965418583618444, 35.742175011617064, 0.0],
              [-81.964912983679866, 35.742198211034072, 0.0],
              [-81.964800782776763, 35.742335677923144, 0.0],
              [-81.964829116124946, 35.7424730774419, 0.0],
              [-81.965138316036388, 35.742770611332688, 0.0],
              [-81.96606618297109, 35.743365211018961, 0.0],
              [-81.96707778278325, 35.743685011271111, 0.0],
              [-81.967274582882041, 35.743845077878127, 0.0],
              [-81.96758431604492, 35.744531810893946, 0.0],
              [-81.967950116773679, 35.744989477956423, 0.0],
              [-81.96792231707947, 35.745241477615231, 0.0],
              [-81.967529116944604, 35.745264610691173, 0.0],
              [-81.966938515984694, 35.744692611557305, 0.0],
              [-81.966488782834418, 35.744418211465273, 0.0],
              [-81.966039116301218, 35.744304077546985, 0.0],
              [-81.965561716351857, 35.744350278079288, 0.0],
              [-81.965252915962637, 35.744510878203407, 0.0],
              [-81.964972516262293, 35.744969210721209, 0.0],
              [-81.964917516542258, 35.745839411294469, 0.0],
              [-81.964412516752503, 35.74638927763224, 0.0],
              [-81.964412782667097, 35.746709877675457, 0.0],
              [-81.96466598284735, 35.747007411465539, 0.0],
              [-81.965761716104041, 35.747166877916555, 0.0],
              [-81.966745316190838, 35.74750947749542, 0.0],
              [-81.967054782870065, 35.747852811566851, 0.0],
              [-81.967195583539009, 35.748150277868028, 0.0],
              [-81.967224516321949, 35.74879147768295, 0.0],
              [-81.967927582929036, 35.749500877862381, 0.0],
              [-81.967872182961528, 35.750142077582808, 0.0],
              [-81.967275183728674, 35.751174477999911, 0.0],
              [-81.967360717044386, 35.752182078160004, 0.0],
              [-81.967727116008405, 35.753166611, 0.0],
              [-81.967671383190634, 35.753533011249623, 0.0],
              [-81.967334116605088, 35.753464478078378, 0.0],
              [-81.966771582778208, 35.752778011457679, 0.0],
              [-81.965393916592063, 35.751931677721856, 0.0],
              [-81.965168916770608, 35.751611277865166, 0.0],
              [-81.96533691651598, 35.751221878143703, 0.0],
              [-81.965448783371613, 35.750809610890073, 0.0],
              [-81.965336316746033, 35.750580611340212, 0.0],
              [-81.964754116424075, 35.750144611522991, 0.0],
              [-81.964500982893583, 35.749915811576081, 0.0],
              [-81.963376782964204, 35.749550277558981, 0.0],
              [-81.962870783696857, 35.749161478289224, 0.0],
              [-81.961633916089312, 35.748452477935778, 0.0],
              [-81.961015583385034, 35.748247011142865, 0.0],
              [-81.960481983497161, 35.74822447764668, 0.0],
              [-81.960229316947562, 35.748407811271981, 0.0],
              [-81.960145516620926, 35.748843077807763, 0.0],
              [-81.960370916734902, 35.749392410790747, 0.0],
              [-81.960427916899278, 35.750125077672088, 0.0],
              [-81.960447916693923, 35.750286610936691, 0.0],
              [-81.960813383090908, 35.750606877552748, 0.0],
              [-81.960785715945207, 35.750973410797407, 0.0],
              [-81.9605891836475, 35.751179678264563, 0.0],
              [-81.959887116361898, 35.751248877753788, 0.0],
              [-81.958960516656575, 35.751753278089453, 0.0],
              [-81.957247516243186, 35.752258411286547, 0.0],
              [-81.956601716764055, 35.752556477700132, 0.0],
              [-81.95618031626266, 35.752625410877329, 0.0],
              [-81.955674917101447, 35.752877677354832, 0.0],
              [-81.95519778355434, 35.753267278142701, 0.0],
              [-81.955057717025866, 35.753679677948497, 0.0],
              [-81.955198316464688, 35.753839878246147, 0.0],
              [-81.955170516535205, 35.754068877385016, 0.0],
              [-81.954496116334667, 35.753886211192217, 0.0],
              [-81.953962383302539, 35.753978078166256, 0.0],
              [-81.953457116425, 35.754299077513714, 0.0],
              [-81.952951316641617, 35.754207811418659, 0.0],
              [-81.952922715998966, 35.753749810873607, 0.0],
              [-81.952585516839093, 35.753543878204525, 0.0],
              [-81.951573582691807, 35.753178077985012, 0.0],
              [-81.951151983725552, 35.752949411360682, 0.0],
              [-81.950337116165585, 35.752766811404875, 0.0],
              [-81.949662716445332, 35.752469478199835, 0.0],
              [-81.949409916936887, 35.752492477854176, 0.0],
              [-81.949326383174608, 35.753294210974381, 0.0],
              [-81.949214116729863, 35.753431611282984, 0.0],
              [-81.94859611694082, 35.753523610951703, 0.0],
              [-81.94851198312557, 35.753638210752321, 0.0],
              [-81.948540382810393, 35.753912877718243, 0.0],
              [-81.949046583403984, 35.754576678255873, 0.0],
              [-81.949187382666764, 35.754851411397134, 0.0],
              [-81.949131983112494, 35.755607077483077, 0.0],
              [-81.948992316270193, 35.756408677895458, 0.0],
              [-81.948627516269113, 35.756958610898742, 0.0],
              [-81.94677338337678, 35.757051277446038, 0.0],
              [-81.946351917014368, 35.756937077574932, 0.0],
              [-81.946154983768793, 35.756754011152374, 0.0],
              [-81.946013916615584, 35.756112877456907, 0.0],
              [-81.946041182748743, 35.755196877445094, 0.0],
              [-81.945422583145643, 35.754556010878488, 0.0],
              [-81.945365916956419, 35.753960678300835, 0.0],
              [-81.945224982784126, 35.753594277873354, 0.0],
              [-81.945083916973559, 35.752930278185822, 0.0],
              [-81.94533571708584, 35.751716477929229, 0.0],
              [-81.945194916976334, 35.751327277596673, 0.0],
              [-81.944998183105426, 35.751190011628559, 0.0],
              [-81.944801516077845, 35.75116721159943, 0.0],
              [-81.944604982828338, 35.751350478180676, 0.0],
              [-81.944100316388756, 35.752404211538291, 0.0],
              [-81.943735916133889, 35.753297611653018, 0.0],
              [-81.943427116645154, 35.753572610945788, 0.0],
              [-81.943202116111038, 35.753412410927595, 0.0],
              [-81.943342182804486, 35.752885610729685, 0.0],
              [-81.943313582723832, 35.752313011011758, 0.0],
              [-81.942976316353111, 35.752015477421963, 0.0],
              [-81.942891716856636, 35.75174087809814, 0.0],
              [-81.943312982689648, 35.751626077703939, 0.0],
              [-81.943565715997963, 35.751465610902471, 0.0],
              [-81.94387438359999, 35.751053277726598, 0.0],
              [-81.943873983132534, 35.750709677627462, 0.0],
              [-81.943452316055172, 35.750412410756518, 0.0],
              [-81.943452183736241, 35.750275011486259, 0.0],
              [-81.94376938274479, 35.750114077929979, 0.0],
              [-81.943797316586966, 35.749976477639485, 0.0],
              [-81.943375717070907, 35.749724877814927, 0.0],
              [-81.943262782719898, 35.749129611353951, 0.0],
              [-81.94317851601194, 35.749038077397678, 0.0],
              [-81.942672716245312, 35.748946677753267, 0.0],
              [-81.942223716674675, 35.749405077423077, 0.0],
              [-81.941774316949648, 35.749519878180294, 0.0],
              [-81.941437516254069, 35.749749078327689, 0.0],
              [-81.941156916234249, 35.750115611025315, 0.0],
              [-81.940923982954573, 35.750276410743808, 0.0],
              [-81.94044638381564, 35.750253677982656, 0.0],
              [-81.940193583728174, 35.750345478236092, 0.0],
              [-81.940025183046387, 35.750528677614625, 0.0],
              [-81.939997916477878, 35.751421877646415, 0.0],
              [-81.940195916901459, 35.753001878093578, 0.0],
              [-81.940111716918537, 35.753093477664329, 0.0],
              [-81.93988698373218, 35.753116611601769, 0.0],
              [-81.939830583583529, 35.753025011440933, 0.0],
              [-81.939633117157996, 35.751948811543841, 0.0],
              [-81.939492516638083, 35.751742677486298, 0.0],
              [-81.939070917048141, 35.751376678246665, 0.0],
              [-81.938986316232885, 35.751147678247698, 0.0],
              [-81.938564583775687, 35.750712811624645, 0.0],
              [-81.938676916358617, 35.750598211253156, 0.0],
              [-81.93923851713636, 35.750368877759826, 0.0],
              [-81.939499516835042, 35.750116610746673, 0.0],
              [-81.939499183752559, 35.749796011063147, 0.0],
              [-81.939217916809724, 35.749498478182623, 0.0],
              [-81.93921771639971, 35.749246610973294, 0.0],
              [-81.940257183402395, 35.749223011489605, 0.0],
              [-81.940453716322651, 35.749131278189545, 0.0],
              [-81.940902783297176, 35.748741810801995, 0.0],
              [-81.940958783062811, 35.748535677761609, 0.0],
              [-81.940649583736501, 35.748375478186993, 0.0],
              [-81.940621316563764, 35.748169410898903, 0.0],
              [-81.94073358347049, 35.74805481095531, 0.0],
              [-81.94199758362528, 35.747939611623273, 0.0],
              [-81.942334516997903, 35.747664611114004, 0.0],
              [-81.942362317069197, 35.747412677662822, 0.0],
              [-81.941968717020131, 35.747092277448139, 0.0],
              [-81.941715383273788, 35.746611611473078, 0.0],
              [-81.941125116040894, 35.746085277785234, 0.0],
              [-81.940563116325919, 35.745925277865027, 0.0],
              [-81.938400183452345, 35.745995210991595, 0.0],
              [-81.937585717018024, 35.746087277457192, 0.0],
              [-81.937360783409403, 35.745904210756187, 0.0],
              [-81.937191716375821, 35.745331878099755, 0.0],
              [-81.93679811604342, 35.745011477496888, 0.0],
              [-81.936489116269854, 35.744874211321935, 0.0],
              [-81.934943917182963, 35.744623210956924, 0.0],
              [-81.934242183667138, 35.745173211057725, 0.0],
              [-81.934242583713143, 35.745677011517287, 0.0],
              [-81.93443938363761, 35.745860211281318, 0.0],
              [-81.934467783009751, 35.746249410860393, 0.0],
              [-81.934215316067977, 35.746753278282263, 0.0],
              [-81.934524583821997, 35.747028011152281, 0.0],
              [-81.934637183029864, 35.747325610759596, 0.0],
              [-81.935367916427253, 35.747714478095446, 0.0],
              [-81.935508516499965, 35.747874677522397, 0.0],
              [-81.935508717025158, 35.748103678076312, 0.0],
              [-81.934834916241968, 35.748607877826828, 0.0],
              [-81.93469491606524, 35.74904321114257, 0.0],
              [-81.934161583065347, 35.74961581155511, 0.0],
              [-81.933824916863898, 35.750119810789073, 0.0],
              [-81.93378878383453, 35.750555011089084, 0.0],
              [-81.934687916702401, 35.750852277605183, 0.0],
              [-81.93449158348831, 35.751218678241749, 0.0],
              [-81.93443578330141, 35.751608077983413, 0.0],
              [-81.934688917027643, 35.752088877961377, 0.0],
              [-81.934717517047076, 35.752592611467236, 0.0],
              [-81.934492916621409, 35.752707277988968, 0.0],
              [-81.93395871660239, 35.752432678073319, 0.0],
              [-81.933874183847749, 35.75208921076414, 0.0],
              [-81.933593116615839, 35.751768810930464, 0.0],
              [-81.933255916332556, 35.751700211243687, 0.0],
              [-81.933227783315559, 35.751608678160132, 0.0],
              [-81.932553317078089, 35.751219678118062, 0.0],
              [-81.932159982658959, 35.75124281075226, 0.0],
              [-81.932047783188878, 35.751403078270883, 0.0],
              [-81.930924117038103, 35.751495278264599, 0.0],
              [-81.930699516612492, 35.751678678195113, 0.0],
              [-81.930699716448117, 35.752068011608152, 0.0],
              [-81.930784183460389, 35.752228211470417, 0.0],
              [-81.930756316418893, 35.752457211034155, 0.0],
              [-81.930615917016695, 35.752548877650717, 0.0],
              [-81.930025916570145, 35.752549078312867, 0.0],
              [-81.930054316747757, 35.75300707819877, 0.0],
              [-81.930841716729432, 35.754083011426722, 0.0],
              [-81.931291583128839, 35.754449277506971, 0.0],
              [-81.931685183074947, 35.754930011111689, 0.0],
              [-81.931657316745714, 35.755319411133513, 0.0],
              [-81.931573117032656, 35.755411011343533, 0.0],
              [-81.931207916185727, 35.755411078154403, 0.0],
              [-81.931011183102171, 35.755319610731789, 0.0],
              [-81.930364583242181, 35.754724611631865, 0.0],
              [-81.929774582714472, 35.754747678085963, 0.0],
              [-81.929690382723166, 35.754908011388871, 0.0],
              [-81.929747783584602, 35.756465277465139, 0.0],
              [-81.930000716699539, 35.756648278204167, 0.0],
              [-81.930618917133543, 35.756808410794825, 0.0],
              [-81.932107982951919, 35.756922078293172, 0.0],
              [-81.93444078384303, 35.758111677421205, 0.0],
              [-81.935115516149693, 35.758706811360717, 0.0],
              [-81.93505951605772, 35.758890010954012, 0.0],
              [-81.934890916654268, 35.75895881162154, 0.0],
              [-81.934076183244954, 35.758959277344395, 0.0],
              [-81.933739182968694, 35.7592112778717, 0.0],
              [-81.93317751704916, 35.759440610729854, 0.0],
              [-81.932531383731273, 35.75953247755784, 0.0],
              [-81.932306716591611, 35.7596928775762, 0.0],
              [-81.932222716185606, 35.759967677638564, 0.0],
              [-81.932504983806695, 35.761753877868216, 0.0],
              [-81.933011517073098, 35.762852811156705, 0.0],
              [-81.932955583835422, 35.763287878210228, 0.0],
              [-81.932871383601309, 35.763356677706376, 0.0],
              [-81.932591117080079, 35.764318678224591, 0.0],
              [-81.932563583359936, 35.764868210708656, 0.0],
              [-81.932339116574099, 35.765234677768049, 0.0],
              [-81.932086116543786, 35.765189011550994, 0.0],
              [-81.931748316100922, 35.76438781094209, 0.0],
              [-81.931775783244333, 35.763586211590749, 0.0],
              [-81.932168716622328, 35.76294481156453, 0.0],
              [-81.932027783423948, 35.762326478163502, 0.0],
              [-81.931634182810043, 35.76189167798605, 0.0],
              [-81.931577783045455, 35.761571078106073, 0.0],
              [-81.931717316591019, 35.760563478297499, 0.0],
              [-81.931239383013832, 35.760151477614762, 0.0],
              [-81.931155116659497, 35.759945411392245, 0.0],
              [-81.930564716335979, 35.759441810800304, 0.0],
              [-81.928990916370651, 35.758938877599945, 0.0],
              [-81.928428983783803, 35.75896201158362, 0.0],
              [-81.928260716724679, 35.759236877392517, 0.0],
              [-81.928204716102627, 35.759649077480212, 0.0],
              [-81.928373716703874, 35.760129877408367, 0.0],
              [-81.929161317070012, 35.761411877983811, 0.0],
              [-81.929189716853145, 35.76184701137791, 0.0],
              [-81.9287401831713, 35.761824410704044, 0.0],
              [-81.928374582956636, 35.761458210834491, 0.0],
              [-81.927727783491363, 35.76049661116857, 0.0],
              [-81.92724971653638, 35.759993010764859, 0.0],
              [-81.926968783530995, 35.759947277527175, 0.0],
              [-81.926182516420639, 35.76042861124062, 0.0],
              [-81.92545251682499, 35.761276211495982, 0.0],
              [-81.925088183703167, 35.762581678247351, 0.0],
              [-81.925425716055074, 35.763177010932189, 0.0],
              [-81.925369716535911, 35.763383077421381, 0.0],
              [-81.92514491631232, 35.763451878039625, 0.0],
              [-81.924582716876998, 35.762879611414988, 0.0],
              [-81.924385916960986, 35.762856810756162, 0.0],
              [-81.923515516541954, 35.763658677708598, 0.0],
              [-81.923262716628713, 35.763773211512493, 0.0],
              [-81.922925517022648, 35.763750611126959, 0.0],
              [-81.922925383174885, 35.763613211323509, 0.0],
              [-81.923318516842144, 35.763246478041253, 0.0],
              [-81.923627183569238, 35.762582278229928, 0.0],
              [-81.92387998291467, 35.762467811633137, 0.0],
              [-81.924273116125306, 35.762124077410192, 0.0],
              [-81.924918116436743, 35.76042921112154, 0.0],
              [-81.925929116093258, 35.759787477934779, 0.0],
              [-81.926237983818538, 35.759398011599494, 0.0],
              [-81.926125183707953, 35.75873401112689, 0.0],
              [-81.926827516066737, 35.758756610842873, 0.0],
              [-81.927108383590507, 35.758596211179061, 0.0],
              [-81.927079983836023, 35.758092410953751, 0.0],
              [-81.927192316402738, 35.758000810820988, 0.0],
              [-81.927754183014414, 35.758137877806114, 0.0],
              [-81.928063316229171, 35.758137811069012, 0.0],
              [-81.928568583276586, 35.75761067803311, 0.0],
              [-81.928596516962457, 35.757244411585312, 0.0],
              [-81.928512183045328, 35.757198611165229, 0.0],
              [-81.928343183279353, 35.756694877597134, 0.0],
              [-81.928033916931369, 35.756305811379306, 0.0],
              [-81.927415783163724, 35.756306011136118, 0.0],
              [-81.927275182738626, 35.756214477954231, 0.0],
              [-81.92766791707804, 35.755275411325385, 0.0],
              [-81.927695916543669, 35.75497761121111, 0.0],
              [-81.927358516542398, 35.754634277559006, 0.0],
              [-81.925616582779213, 35.754452011517671, 0.0],
              [-81.925166716664947, 35.753902611495171, 0.0],
              [-81.924801516382914, 35.753856878136865, 0.0],
              [-81.924239783255302, 35.754131878161203, 0.0],
              [-81.923566116053891, 35.755071078235332, 0.0],
              [-81.923425982863236, 35.755506211655693, 0.0],
              [-81.92342618382817, 35.755804011222118, 0.0],
              [-81.923257582715223, 35.755827010804843, 0.0],
              [-81.923032516151594, 35.755437811522782, 0.0],
              [-81.922807716347137, 35.75525461082988, 0.0],
              [-81.922610983332262, 35.755323411637697, 0.0],
              [-81.922498916898036, 35.755552477590776, 0.0],
              [-81.922499382672655, 35.756239411080415, 0.0],
              [-81.922303583009452, 35.75763641114002, 0.0],
              [-81.922078782982709, 35.757705277598433, 0.0],
              [-81.921853916730726, 35.757453410678345, 0.0],
              [-81.921656983852699, 35.756972611418611, 0.0],
              [-81.92115091600472, 35.756377410919235, 0.0],
              [-81.921066316421573, 35.756011011429585, 0.0],
              [-81.920953916044496, 35.755919477476809, 0.0],
              [-81.920026716389685, 35.755782411145859, 0.0],
              [-81.919858183719853, 35.755874011235953, 0.0],
              [-81.919858382708142, 35.756080077756657, 0.0],
              [-81.920026983221277, 35.756286211018697, 0.0],
              [-81.919858516467116, 35.756354878030074, 0.0],
              [-81.919633716976335, 35.756286277606897, 0.0],
              [-81.919380716801257, 35.755897210924573, 0.0],
              [-81.918087916600641, 35.755073211015763, 0.0],
              [-81.917891316767921, 35.755210677846605, 0.0],
              [-81.917863583383223, 35.755852010889129, 0.0],
              [-81.918201316260507, 35.757042611485929, 0.0],
              [-81.918371116381792, 35.759080677912692, 0.0],
              [-81.91806211706789, 35.759149477476569, 0.0],
              [-81.917949717045289, 35.759058010881056, 0.0],
              [-81.917808983366882, 35.758737477652147, 0.0],
              [-81.917584116751399, 35.758600210980674, 0.0],
              [-81.917162716567844, 35.758554477640644, 0.0],
              [-81.91646038291131, 35.758646278096144, 0.0],
              [-81.916375983550182, 35.758554678222644, 0.0],
              [-81.916488316387259, 35.758463077420124, 0.0],
              [-81.917471516991583, 35.758188010747574, 0.0],
              [-81.917555783661996, 35.758096411321603, 0.0],
              [-81.917527383336818, 35.757615411397104, 0.0],
              [-81.917133716121072, 35.7569284778642, 0.0],
              [-81.917048582827249, 35.755440077560635, 0.0],
              [-81.916964183629062, 35.755302677342485, 0.0],
              [-81.916711316638086, 35.755188278201132, 0.0],
              [-81.916205516571623, 35.755165611295681, 0.0],
              [-81.915728116836547, 35.755326077829423, 0.0],
              [-81.915278783830217, 35.755669678127433, 0.0],
              [-81.914604716532253, 35.756059278205854, 0.0],
              [-81.913902716604028, 35.756792410760958, 0.0],
              [-81.913228717160408, 35.757319211539091, 0.0],
              [-81.912077117098448, 35.757823478298214, 0.0],
              [-81.911487316240141, 35.758190010989161, 0.0],
              [-81.911206316681742, 35.758258811432235, 0.0],
              [-81.911122117055044, 35.758190210659166, 0.0],
              [-81.911262183218227, 35.757411477509486, 0.0],
              [-81.911430583457729, 35.757296877556257, 0.0],
              [-81.912357783037706, 35.757159211492144, 0.0],
              [-81.912863316869363, 35.75693007793047, 0.0],
              [-81.913200317117528, 35.756655210827624, 0.0],
              [-81.91328418307846, 35.755945211018087, 0.0],
              [-81.913986516898788, 35.755715878272717, 0.0],
              [-81.914548182921905, 35.755349277672337, 0.0],
              [-81.914547916653987, 35.754960077379138, 0.0],
              [-81.914294716146571, 35.754204411382297, 0.0],
              [-81.914406983497116, 35.754112877999368, 0.0],
              [-81.915614916888231, 35.753906211296652, 0.0],
              [-81.916232782855218, 35.75347087817795, 0.0],
              [-81.916457383150529, 35.753150211287092, 0.0],
              [-81.916485316644255, 35.752806810757491, 0.0],
              [-81.916316583206367, 35.752486211069787, 0.0],
              [-81.916007316846319, 35.752211611026901, 0.0],
              [-81.915613916435078, 35.752097211359704, 0.0],
              [-81.91558578337272, 35.751891077581121, 0.0],
              [-81.915669983584053, 35.751730810718904, 0.0],
              [-81.915669917052142, 35.751478878057661, 0.0],
              [-81.915501183755907, 35.751295611424737, 0.0],
              [-81.91479878353708, 35.751181411253519, 0.0],
              [-81.913085516435785, 35.75180027763345, 0.0],
              [-81.911793583469844, 35.752716677967101, 0.0],
              [-81.909883717076042, 35.753495877697787, 0.0],
              [-81.909237716293603, 35.753839677396371, 0.0],
              [-81.908928916125035, 35.754160411614549, 0.0],
              [-81.908647983032765, 35.754229210859251, 0.0],
              [-81.908563516709933, 35.753931477417886, 0.0],
              [-81.908900516099791, 35.75347341158551, 0.0],
              [-81.909967583844747, 35.752694410974001, 0.0],
              [-81.911371916824223, 35.751984211536445, 0.0],
              [-81.911624716258061, 35.751686211536025, 0.0],
              [-81.911652716178736, 35.751411477853296, 0.0],
              [-81.911146716332937, 35.751068077883964, 0.0],
              [-81.91156811623533, 35.7509764112542, 0.0],
              [-81.911596117205519, 35.750816078120678, 0.0],
              [-81.911230783473883, 35.750495611045444, 0.0],
              [-81.910809317095911, 35.750404078300022, 0.0],
              [-81.910640716670258, 35.750289611135145, 0.0],
              [-81.910676982738764, 35.750130410944514, 0.0],
              [-81.911940982764818, 35.749832278149327, 0.0],
              [-81.912530717112332, 35.749511410867925, 0.0],
              [-81.912811516291185, 35.749190677979449, 0.0],
              [-81.912895582882811, 35.748870077489855, 0.0],
              [-81.913597716220551, 35.74857207778021, 0.0],
              [-81.91365378279481, 35.748389011205376, 0.0],
              [-81.913372716260156, 35.748114211385825, 0.0],
              [-81.912670383407587, 35.74800001077292, 0.0],
              [-81.912613782961671, 35.74740461078067, 0.0],
              [-81.912445183856917, 35.747221477960302, 0.0],
              [-81.912079916071733, 35.746923878136094, 0.0],
              [-81.911517982835804, 35.746832478075213, 0.0],
              [-81.911180983165721, 35.747084477852646, 0.0],
              [-81.911068916230334, 35.747405210899281, 0.0],
              [-81.910788783831805, 35.748870878278694, 0.0],
              [-81.910479983868768, 35.749351878106701, 0.0],
              [-81.910086916212961, 35.749695477918145, 0.0],
              [-81.909693716565613, 35.749878811101524, 0.0],
              [-81.909384783414183, 35.75013081143112, 0.0],
              [-81.908983316342699, 35.750565011394123, 0.0],
              [-81.9079161834958, 35.751183611341482, 0.0],
              [-81.906258916996535, 35.75159621161179, 0.0],
              [-81.906258782937101, 35.75139007793107, 0.0],
              [-81.906960916418981, 35.750863277433552, 0.0],
              [-81.908120716412427, 35.750131278212599, 0.0],
              [-81.908738317087341, 35.749375411010732, 0.0],
              [-81.908766316650642, 35.74914627738525, 0.0],
              [-81.907838982839749, 35.748734478277292, 0.0],
              [-81.907501916200175, 35.748688811281774, 0.0],
              [-81.907052516705491, 35.748826278071647, 0.0],
              [-81.907024316332368, 35.74866607816859, 0.0],
              [-81.90674331622175, 35.748414211192788, 0.0],
              [-81.906349983736916, 35.748391477675135, 0.0],
              [-81.905928717007981, 35.748597677794038, 0.0],
              [-81.905226716439586, 35.749124610982442, 0.0],
              [-81.904973916217401, 35.749193410809106, 0.0],
              [-81.905029982777108, 35.748872811563039, 0.0],
              [-81.905479183409582, 35.748391677923756, 0.0],
              [-81.905366716318227, 35.748162678315865, 0.0],
              [-81.905029383620573, 35.747819277476395, 0.0],
              [-81.904411316909702, 35.747430278331905, 0.0],
              [-81.904411183039315, 35.747292877557499, 0.0],
              [-81.904551716813444, 35.747178278244839, 0.0],
              [-81.905000983256031, 35.747178077665495, 0.0],
              [-81.905394583451397, 35.747521611135383, 0.0],
              [-81.906265383198871, 35.74770441072706, 0.0],
              [-81.909074183238033, 35.747085211621226, 0.0],
              [-81.909411116306103, 35.746810278096717, 0.0],
              [-81.90941098319621, 35.746466810740962, 0.0],
              [-81.908933316041811, 35.746329611309903, 0.0],
              [-81.908567916194443, 35.745894611085781, 0.0],
              [-81.907921783532458, 35.74562001087903, 0.0],
              [-81.907809382833037, 35.745505477491498, 0.0],
              [-81.907893582844295, 35.745413878023882, 0.0],
              [-81.90873611695234, 35.74511601096264, 0.0],
              [-81.908932583139034, 35.744886878014839, 0.0],
              [-81.908904383377205, 35.744474677843279, 0.0],
              [-81.908482917126904, 35.74429161115696, 0.0],
              [-81.907443717040024, 35.744360677882803, 0.0],
              [-81.907303183836504, 35.74426907829077, 0.0],
              [-81.907106116028217, 35.743467610831416, 0.0],
              [-81.906571917012201, 35.742620477417411, 0.0],
              [-81.90558838305715, 35.741613210751169, 0.0],
              [-81.905363516049263, 35.741224010801062, 0.0],
              [-81.905110316729605, 35.74046841132089, 0.0],
              [-81.904773182895624, 35.740102077454139, 0.0],
              [-81.904351783863106, 35.740079277844785, 0.0],
              [-81.904070916765093, 35.740193877957239, 0.0],
              [-81.903762116668474, 35.740652011372227, 0.0],
              [-81.903734383138357, 35.741247278350315, 0.0],
              [-81.904071716778915, 35.741819677513497, 0.0],
              [-81.904071783510744, 35.742094611609801, 0.0],
              [-81.903931383848914, 35.742186278068147, 0.0],
              [-81.903481916316963, 35.742026077439604, 0.0],
              [-81.903004382911746, 35.742003277560265, 0.0],
              [-81.902246316441676, 35.74253021115549, 0.0],
              [-81.901824583273552, 35.742301411458307, 0.0],
              [-81.901262716253925, 35.74170607773101, 0.0],
              [-81.900981716059235, 35.741591610876043, 0.0],
              [-81.900841316330514, 35.741683277672479, 0.0],
              [-81.90064491630109, 35.742095478225735, 0.0],
              [-81.900308316354156, 35.743217811146451, 0.0],
              [-81.900392716675228, 35.743813211322554, 0.0],
              [-81.90061778390421, 35.744362678286507, 0.0],
              [-81.900926917214278, 35.74466041158346, 0.0],
              [-81.900955116670971, 35.744935077429837, 0.0],
              [-81.90050558279853, 35.744981078293101, 0.0],
              [-81.899887583565857, 35.744683477924667, 0.0],
              [-81.898988516724486, 35.744569210859922, 0.0],
              [-81.898595383822467, 35.744729678147849, 0.0],
              [-81.898314517120255, 35.744981677922937, 0.0],
              [-81.897500383567078, 35.746516078191853, 0.0],
              [-81.897332116330332, 35.747157411651855, 0.0],
              [-81.897219783872657, 35.747294877443828, 0.0],
              [-81.89699511723947, 35.747340677599688, 0.0],
              [-81.89693858311918, 35.746607877554652, 0.0],
              [-81.896741983087196, 35.74640181138205, 0.0],
              [-81.896152116204888, 35.746333277706015, 0.0],
              [-81.895674583729871, 35.746425077740433, 0.0],
              [-81.894635316620921, 35.746860278286476, 0.0],
              [-81.894185916493399, 35.746860411275293, 0.0],
              [-81.89421391643296, 35.746700077806175, 0.0],
              [-81.894719516364347, 35.746287810753245, 0.0],
              [-81.894775583194473, 35.746036010939157, 0.0],
              [-81.894550783204366, 35.745761210889604, 0.0],
              [-81.894606783742276, 35.745623677728034, 0.0],
              [-81.895589983839301, 35.74553187740549, 0.0],
              [-81.895983117194845, 35.745279878136884, 0.0],
              [-81.89696598346454, 35.74438661084524, 0.0],
              [-81.897021983862629, 35.744066011127273, 0.0],
              [-81.896881517088076, 35.743585077685189, 0.0],
              [-81.897274582817815, 35.74344747776334, 0.0],
              [-81.897471183757673, 35.743241411473448, 0.0],
              [-81.897667583398814, 35.742302411053942, 0.0],
              [-81.897555116157022, 35.742188011246171, 0.0],
              [-81.896937183258373, 35.742142411355637, 0.0],
              [-81.896740516617783, 35.741936277650517, 0.0],
              [-81.896796516885672, 35.741730211457444, 0.0],
              [-81.897695182758881, 35.741134478336761, 0.0],
              [-81.897723182702308, 35.740859610800648, 0.0],
              [-81.897470317157484, 35.740562011649089, 0.0],
              [-81.896936383311456, 35.740356077632498, 0.0],
              [-81.896543183191994, 35.740310411378431, 0.0],
              [-81.896318516892606, 35.74042501099909, 0.0],
              [-81.896037716414867, 35.740676878154197, 0.0],
              [-81.895616383449905, 35.740768678115515, 0.0],
              [-81.895503983093377, 35.740700011380177, 0.0],
              [-81.895363117214046, 35.739325877558969, 0.0],
              [-81.895840516267299, 35.738753277804086, 0.0],
              [-81.895980783068367, 35.738455611060296, 0.0],
              [-81.89595258356961, 35.737997678056168, 0.0],
              [-81.89567171661848, 35.737814477831016, 0.0],
              [-81.895081783716464, 35.737791677408154, 0.0],
              [-81.894660583100503, 35.738043677449966, 0.0],
              [-81.894323583550019, 35.738135410981968, 0.0],
              [-81.8937053835147, 35.737494277730299, 0.0],
              [-81.893452517225967, 35.737379811015906, 0.0],
              [-81.893115516491903, 35.737357011517062, 0.0],
              [-81.892806583061272, 35.737471611184922, 0.0],
              [-81.892553783726157, 35.737723478125304, 0.0],
              [-81.892581983247183, 35.737975410760434, 0.0],
              [-81.892778783232245, 35.738433477457484, 0.0],
              [-81.89362171706442, 35.739074478016967, 0.0],
              [-81.893621916347342, 35.739784411237039, 0.0],
              [-81.894071517181047, 35.740746077947712, 0.0],
              [-81.89409991708024, 35.741593411369806, 0.0],
              [-81.893987716527278, 35.741868077749878, 0.0],
              [-81.89362238359837, 35.741914077845067, 0.0],
              [-81.893397717241015, 35.741845478317643, 0.0],
              [-81.893257316882853, 35.741685211671687, 0.0],
              [-81.893116716183869, 35.741273011301189, 0.0],
              [-81.893088517154069, 35.740700477688719, 0.0],
              [-81.892863783759267, 35.74065467798092, 0.0],
              [-81.892610983485795, 35.740860811086797, 0.0],
              [-81.892077516420457, 35.741685410975116, 0.0],
              [-81.891375782865936, 35.743288478210545, 0.0],
              [-81.891122983853833, 35.743563477803868, 0.0],
              [-81.890701716816636, 35.743678011347306, 0.0],
              [-81.890561316246263, 35.743517810824159, 0.0],
              [-81.889718582791602, 35.743609477657294, 0.0],
              [-81.88966231693253, 35.743334677854804, 0.0],
              [-81.890223982898505, 35.742945277949374, 0.0],
              [-81.891318916908503, 35.74072381092757, 0.0],
              [-81.891459183638105, 35.740219811030052, 0.0],
              [-81.891486916191752, 35.739281011219099, 0.0],
              [-81.891149783875392, 35.738800077840949, 0.0],
              [-81.890672316169542, 35.738731478276478, 0.0],
              [-81.890335183002975, 35.738891877505296, 0.0],
              [-81.890222916829785, 35.739143810949095, 0.0],
              [-81.890419716953986, 35.739624611093355, 0.0],
              [-81.890251183813731, 35.739785011213812, 0.0],
              [-81.889436716939429, 35.740014211004357, 0.0],
              [-81.889268182991771, 35.740197477906655, 0.0],
              [-81.889240316571218, 35.740586677778069, 0.0],
              [-81.889436916925575, 35.741067611287185, 0.0],
              [-81.88943711668864, 35.741571411616448, 0.0],
              [-81.88935291717776, 35.741663010848526, 0.0],
              [-81.888847316737255, 35.741754678190667, 0.0],
              [-81.888510116751917, 35.741548610929456, 0.0],
              [-81.888257317222084, 35.741525810890998, 0.0],
              [-81.887779783293283, 35.741823677524138, 0.0],
              [-81.887723782774145, 35.741983878305255, 0.0],
              [-81.886600383280708, 35.743152010822968, 0.0],
              [-81.886207183521918, 35.743289477432562, 0.0],
              [-81.885645383788059, 35.743220877458064, 0.0],
              [-81.884746716458224, 35.743793611005572, 0.0],
              [-81.884297316927231, 35.744572210764836, 0.0],
              [-81.882331383514298, 35.746541877625766, 0.0],
              [-81.881881983658886, 35.747022811396583, 0.0],
              [-81.881910117203546, 35.747366411487057, 0.0],
              [-81.882078716661638, 35.747480877971803, 0.0],
              [-81.883370983692032, 35.747801277467183, 0.0],
              [-81.883764316436782, 35.748099011600672, 0.0],
              [-81.883989183603092, 35.748442477802996, 0.0],
              [-81.884129783589742, 35.74896907787177, 0.0],
              [-81.884523116456663, 35.749472811128626, 0.0],
              [-81.884579316984414, 35.749747678153732, 0.0],
              [-81.884888383804949, 35.749999478264087, 0.0],
              [-81.885365916341755, 35.750045211352152, 0.0],
              [-81.885422116256549, 35.750136811425492, 0.0],
              [-81.886677983044535, 35.750409277557999, 0.0],
              [-81.887324183123312, 35.750707010721563, 0.0],
              [-81.887998517054143, 35.75068401122099, 0.0],
              [-81.888476116193516, 35.751096210825892, 0.0],
              [-81.888672783000885, 35.751439677563134, 0.0],
              [-81.889712383216448, 35.752241011343976, 0.0],
              [-81.889796716216637, 35.752538677580091, 0.0],
              [-81.888335916708499, 35.752424278323701, 0.0],
              [-81.888251583622591, 35.752515877376538, 0.0],
              [-81.888251716130824, 35.75283647780838, 0.0],
              [-81.887549383252349, 35.752905410766296, 0.0],
              [-81.887015583515648, 35.752539011355537, 0.0],
              [-81.886369382795067, 35.752493277525126, 0.0],
              [-81.885807582871365, 35.752356011508574, 0.0],
              [-81.884880316589076, 35.751829411629465, 0.0],
              [-81.884121783698035, 35.751623277667925, 0.0],
              [-81.883363317183836, 35.751623411143875, 0.0],
              [-81.882745317092642, 35.751715011095563, 0.0],
              [-81.881059783644659, 35.751211411480803, 0.0],
              [-81.88004831707093, 35.751074011514014, 0.0],
              [-81.879739317241018, 35.751303011499473, 0.0],
              [-81.879486383893962, 35.751371811172248, 0.0],
              [-81.879374116280204, 35.751211477714627, 0.0],
              [-81.879261716498192, 35.750570277439039, 0.0],
              [-81.878595783894255, 35.750137677907553, 0.0],
              [-81.878202383124233, 35.750023077892223, 0.0],
              [-81.877500116190788, 35.750023211407303, 0.0],
              [-81.877303516767071, 35.750137811616348, 0.0],
              [-81.877210982856937, 35.750181011427742, 0.0],
              [-81.875103983925911, 35.750249810777341, 0.0],
              [-81.874823116249033, 35.750341411040928, 0.0],
              [-81.874261182803352, 35.75047881107367, 0.0],
              [-81.87406451622013, 35.750662011581582, 0.0],
              [-81.873952183606193, 35.750959677954256, 0.0],
              [-81.873952316629143, 35.751898678023259, 0.0],
              [-81.874064582748076, 35.752471211011773, 0.0],
              [-81.87403651688706, 35.752974878119439, 0.0],
              [-81.873811782816873, 35.75304361092428, 0.0],
              [-81.873418516400093, 35.752677211191177, 0.0],
              [-81.873025183501966, 35.752104811189412, 0.0],
              [-81.872884717126439, 35.750524611348418, 0.0],
              [-81.872519516340333, 35.750227011148603, 0.0],
              [-81.872266717142608, 35.750318611296429, 0.0],
              [-81.872154382828526, 35.75043301104872, 0.0],
              [-81.87229478386638, 35.751188677644556, 0.0],
              [-81.872266583491609, 35.751761211057541, 0.0],
              [-81.872041917075322, 35.752059010943448, 0.0],
              [-81.871564316484907, 35.752219210889706, 0.0],
              [-81.871451982822904, 35.752723077362909, 0.0],
              [-81.871142983318236, 35.753089477790525, 0.0],
              [-81.871142983268157, 35.753730611517213, 0.0],
              [-81.871002516565255, 35.753822277390711, 0.0],
              [-81.870272116264161, 35.753913811001148, 0.0],
              [-81.869991117213488, 35.754097010787156, 0.0],
              [-81.869906916100248, 35.754371877732332, 0.0],
              [-81.869962983482282, 35.754852811602667, 0.0],
              [-81.870356316385156, 35.755631411494235, 0.0],
              [-81.870721517064013, 35.757028278191065, 0.0],
              [-81.870833916367559, 35.757142811002559, 0.0],
              [-81.871395716750825, 35.757280211191542, 0.0],
              [-81.872575717070916, 35.757257411075066, 0.0],
              [-81.872772316714489, 35.757326010821323, 0.0],
              [-81.872716183463851, 35.757509277355418, 0.0],
              [-81.871817116085595, 35.757944277367322, 0.0],
              [-81.871704783502977, 35.758150477581474, 0.0],
              [-81.871704783068139, 35.75853981120634, 0.0],
              [-81.871985716812716, 35.75895201136386, 0.0],
              [-81.873053383180036, 35.75934127746752, 0.0],
              [-81.873053316896957, 35.7597076775848, 0.0],
              [-81.872912916227619, 35.759936811018981, 0.0],
              [-81.872912917281425, 35.760120011220458, 0.0],
              [-81.873249983014659, 35.760417678238824, 0.0],
              [-81.873727717150231, 35.760623678063382, 0.0],
              [-81.874233383838458, 35.760577877717253, 0.0],
              [-81.874429983552503, 35.760440477753576, 0.0],
              [-81.874823383026637, 35.759639011088488, 0.0],
              [-81.875104316305865, 35.759410011014538, 0.0],
              [-81.875357183552154, 35.759410011336456, 0.0],
              [-81.875385316177159, 35.760005278152128, 0.0],
              [-81.875329117164085, 35.760142811442115, 0.0],
              [-81.87532911613647, 35.760692411477201, 0.0],
              [-81.875582116584667, 35.761058810893246, 0.0],
              [-81.876003517224802, 35.761219077969983, 0.0],
              [-81.876790182959581, 35.761104611042782, 0.0],
              [-81.877408183411177, 35.760875478314055, 0.0],
              [-81.877380183618314, 35.761081611319987, 0.0],
              [-81.876172116125275, 35.761929010857386, 0.0],
              [-81.876003516728161, 35.762203811626989, 0.0],
              [-81.876003517069051, 35.762570211137231, 0.0],
              [-81.876537383172248, 35.763554877787612, 0.0],
              [-81.876734182863984, 35.764196077381953, 0.0],
              [-81.877239982749046, 35.764631277443641, 0.0],
              [-81.877436583874584, 35.76470001110328, 0.0],
              [-81.877914183031066, 35.764585410713664, 0.0],
              [-81.878026583487468, 35.764493810658578, 0.0],
              [-81.87819518366814, 35.7640814777427, 0.0],
              [-81.878532383733486, 35.763829611448266, 0.0],
              [-81.87917858329655, 35.763783811289898, 0.0],
              [-81.879684316191728, 35.763898278131784, 0.0],
              [-81.880190117228693, 35.764333411022754, 0.0],
              [-81.88041491657728, 35.764837210810001, 0.0],
              [-81.880752183797156, 35.765203477596636, 0.0],
              [-81.880948783923571, 35.765249277532007, 0.0],
              [-81.881707383257663, 35.764951610800765, 0.0],
              [-81.882999783669362, 35.764012477426633, 0.0],
              [-81.883645983709229, 35.763737677485551, 0.0],
              [-81.884320383923622, 35.763646011578317, 0.0],
              [-81.884292316213674, 35.763920811212166, 0.0],
              [-81.882999916079072, 35.764859877788091, 0.0],
              [-81.8829437169031, 35.765272011015504, 0.0],
              [-81.883646517077011, 35.767172677813328, 0.0],
              [-81.883730916290034, 35.767768210976442, 0.0],
              [-81.883196982818021, 35.767699478141417, 0.0],
              [-81.882972183656591, 35.767516278011641, 0.0],
              [-81.882381916352031, 35.766233877901598, 0.0],
              [-81.882100916977322, 35.765982011108129, 0.0],
              [-81.881707516242727, 35.765844677432824, 0.0],
              [-81.880724116302744, 35.765707277347047, 0.0],
              [-81.879852916233745, 35.765020277607768, 0.0],
              [-81.879009983124931, 35.764814277463074, 0.0],
              [-81.878504316141999, 35.764883077483752, 0.0],
              [-81.878279516137468, 35.765089211406121, 0.0],
              [-81.878420183189604, 35.767035677794233, 0.0],
              [-81.878673116287615, 35.76774561157967, 0.0],
              [-81.87870131670239, 35.768982211351144, 0.0],
              [-81.878926316506352, 35.769417277392712, 0.0],
              [-81.878926317097097, 35.770310411262173, 0.0],
              [-81.878813916873654, 35.770425010974826, 0.0],
              [-81.878617183292846, 35.770402077485663, 0.0],
              [-81.878448583487668, 35.77028761097953, 0.0],
              [-81.87805498292434, 35.769531810959435, 0.0],
              [-81.877352583823395, 35.76916547778535, 0.0],
              [-81.876818716323783, 35.769234278129709, 0.0],
              [-81.875750983326128, 35.770150211295082, 0.0],
              [-81.874823783637908, 35.771157877511584, 0.0],
              [-81.874037117010602, 35.772051011189831, 0.0],
              [-81.873840183594098, 35.772073877504091, 0.0],
              [-81.873699783403922, 35.771959411136891, 0.0],
              [-81.873671583864763, 35.771661678084072, 0.0],
              [-81.873868383762385, 35.771341077824367, 0.0],
              [-81.874823716437319, 35.770333411391405, 0.0],
              [-81.87580711687643, 35.769005211202455, 0.0],
              [-81.877099583893468, 35.768547211401369, 0.0],
              [-81.877352582876398, 35.76834101093359, 0.0],
              [-81.877633517203463, 35.767585411242536, 0.0],
              [-81.877127717259043, 35.766898410886668, 0.0],
              [-81.877211917181725, 35.766417411604799, 0.0],
              [-81.877183783798145, 35.765799077811465, 0.0],
              [-81.876762383268314, 35.765524277360271, 0.0],
              [-81.876818383206114, 35.765020611618219, 0.0],
              [-81.876256583335234, 35.764310611009265, 0.0],
              [-81.87558211664124, 35.763623611189502, 0.0],
              [-81.874823516625185, 35.7631198106887, 0.0],
              [-81.873924383827131, 35.763074078057628, 0.0],
              [-81.873615316803253, 35.763257277490723, 0.0],
              [-81.873025317124558, 35.764219078172673, 0.0],
              [-81.872912917102411, 35.764425277582696, 0.0],
              [-81.872322782872658, 35.765180878224314, 0.0],
              [-81.87201378285387, 35.765226677633578, 0.0],
              [-81.871873316790868, 35.764951877850692, 0.0],
              [-81.872407183596422, 35.763875478125861, 0.0],
              [-81.87257571723444, 35.763074077626129, 0.0],
              [-81.872631916318255, 35.762341277947897, 0.0],
              [-81.871423783925579, 35.761677211164269, 0.0],
              [-81.871227116508152, 35.761654277494742, 0.0],
              [-81.870805582951931, 35.762043611068265, 0.0],
              [-81.870524583718321, 35.762112210823773, 0.0],
              [-81.870384316244468, 35.761402277479469, 0.0],
              [-81.870721383852299, 35.761081678276405, 0.0],
              [-81.870889983735623, 35.760784011219407, 0.0],
              [-81.870889983291832, 35.760394610880525, 0.0],
              [-81.870777583267056, 35.760303011221403, 0.0],
              [-81.870271916228873, 35.760257278115745, 0.0],
              [-81.869457117097099, 35.760967211348841, 0.0],
              [-81.868810916645259, 35.761150277454298, 0.0],
              [-81.868726582787318, 35.761081610917905, 0.0],
              [-81.868726516968394, 35.760921410710544, 0.0],
              [-81.869176116599263, 35.760417611284844, 0.0],
              [-81.869204183855359, 35.759913811049785, 0.0],
              [-81.869063782908526, 35.759616077346131, 0.0],
              [-81.867940116722693, 35.758287678220825, 0.0],
              [-81.867153316116671, 35.757852610935153, 0.0],
              [-81.866844316657165, 35.757852610736812, 0.0],
              [-81.866647583445371, 35.758150411600383, 0.0],
              [-81.866591316761244, 35.759822077448469, 0.0],
              [-81.866703582856502, 35.760967077346415, 0.0],
              [-81.866984517008476, 35.761631277376082, 0.0],
              [-81.866984583490463, 35.762020611415373, 0.0],
              [-81.866787783883197, 35.762020610683258, 0.0],
              [-81.866225916396914, 35.761287677667845, 0.0],
              [-81.86569231611756, 35.76034867822775, 0.0],
              [-81.864989917155611, 35.760188410681259, 0.0],
              [-81.865551916251107, 35.759570078189434, 0.0],
              [-81.865579916302295, 35.759134877588579, 0.0],
              [-81.865327116668723, 35.758791477492316, 0.0],
              [-81.864933716620584, 35.758745610803274, 0.0],
              [-81.864118917241115, 35.759043277779547, 0.0],
              [-81.862264717265091, 35.759409611328984, 0.0],
              [-81.86192751625984, 35.759180611512399, 0.0],
              [-81.861421916976184, 35.759066010686745, 0.0],
              [-81.86128151630848, 35.758928611277931, 0.0],
              [-81.86128151618496, 35.758745411604529, 0.0],
              [-81.861421917303105, 35.758699611316942, 0.0],
              [-81.86237711624662, 35.758768410888941, 0.0],
              [-81.862714316283061, 35.758630877754157, 0.0],
              [-81.86403478367707, 35.757760811112853, 0.0],
              [-81.864287783198094, 35.757257077803338, 0.0],
              [-81.864315916151213, 35.756913611337602, 0.0],
              [-81.863922582919614, 35.756615810980975, 0.0],
              [-81.86355731656775, 35.756547077521972, 0.0],
              [-81.861703116649295, 35.756638611276834, 0.0],
              [-81.861646982931816, 35.756501078075445, 0.0],
              [-81.861787382754912, 35.756180610719348, 0.0],
              [-81.862040317288702, 35.755905811391379, 0.0],
              [-81.862489783712505, 35.755814211503996, 0.0],
              [-81.862826916937479, 35.755997411331279, 0.0],
              [-81.863220316946339, 35.755951611522583, 0.0],
              [-81.863950783443158, 35.755470810930397, 0.0],
              [-81.864231783388476, 35.755218877695327, 0.0],
              [-81.864316116706974, 35.754967011302213, 0.0],
              [-81.864231717011847, 35.754898278173641, 0.0],
              [-81.863754183540365, 35.75487541076798, 0.0],
              [-81.863613783800758, 35.754783810968718, 0.0],
              [-81.863838516350697, 35.754234211505178, 0.0],
              [-81.863810516564143, 35.753547078264866, 0.0],
              [-81.863613783212202, 35.753363878122613, 0.0],
              [-81.863248583775984, 35.753341011551313, 0.0],
              [-81.862883383538914, 35.753501277867315, 0.0],
              [-81.862461983139511, 35.754463078075069, 0.0],
              [-81.862293383696795, 35.754554611460634, 0.0],
              [-81.862068583308229, 35.754554611457479, 0.0],
              [-81.861984383438539, 35.754371411119003, 0.0],
              [-81.861984383009229, 35.753684478252772, 0.0],
              [-81.862265516946223, 35.75237907772572, 0.0],
              [-81.862096983855125, 35.752172878185654, 0.0],
              [-81.861759917118789, 35.752058411023434, 0.0],
              [-81.861254316671591, 35.752035411344657, 0.0],
              [-81.860832783984335, 35.752172878194898, 0.0],
              [-81.860439516535422, 35.752447611395709, 0.0],
              [-81.860298782914967, 35.753661410724348, 0.0],
              [-81.859961717212059, 35.753890277985874, 0.0],
              [-81.859736782886444, 35.754302477567315, 0.0],
              [-81.859371583626299, 35.754417011019761, 0.0],
              [-81.859259316689119, 35.754325410760885, 0.0],
              [-81.859259317000109, 35.754165077834905, 0.0],
              [-81.85937158296646, 35.754027611195852, 0.0],
              [-81.859456117000576, 35.753638410882388, 0.0],
              [-81.859315582943552, 35.753546678156553, 0.0],
              [-81.8590065171839, 35.753500810705148, 0.0],
              [-81.857320716487692, 35.754325078179335, 0.0],
              [-81.856927383546946, 35.754439477933865, 0.0],
              [-81.856871382871532, 35.754233477978474, 0.0],
              [-81.857068116254197, 35.75377547775345, 0.0],
              [-81.857461382830081, 35.753661078010417, 0.0],
              [-81.857742316761218, 35.753454877546361, 0.0],
              [-81.857798582983037, 35.753203011575259, 0.0],
              [-81.857208716363971, 35.752447210762398, 0.0],
              [-81.857236916524471, 35.75212661141498, 0.0],
              [-81.857826782829946, 35.752264077566672, 0.0],
              [-81.858163783424985, 35.752516077362444, 0.0],
              [-81.85847291636621, 35.752561878182483, 0.0],
              [-81.85866958384463, 35.752355877416377, 0.0],
              [-81.858697783451944, 35.75171461157008, 0.0],
              [-81.858585516473454, 35.751577210728797, 0.0],
              [-81.857939316883787, 35.751302278287689, 0.0],
              [-81.857630383693348, 35.751073277516028, 0.0],
              [-81.857686583052399, 35.750798477915588, 0.0],
              [-81.858164182793232, 35.750729877520946, 0.0],
              [-81.858473116257016, 35.751027611003906, 0.0],
              [-81.859119183112682, 35.751371211671504, 0.0],
              [-81.859343983347117, 35.751302477928469, 0.0],
              [-81.860560517075527, 35.75013787801899, 0.0],
              [-81.860700916209268, 35.749817411122535, 0.0],
              [-81.860729183760128, 35.74915321117885, 0.0],
              [-81.860476382831692, 35.748970011055548, 0.0],
              [-81.860111116411204, 35.748924210927797, 0.0],
              [-81.859268383044821, 35.749473811109361, 0.0],
              [-81.858734583838057, 35.749725611418789, 0.0],
              [-81.858313182861394, 35.749725611437029, 0.0],
              [-81.85808851723128, 35.749290410814339, 0.0],
              [-81.857638983917141, 35.748740877685641, 0.0],
              [-81.85752671655375, 35.748374411636838, 0.0],
              [-81.857526783050332, 35.747847677703739, 0.0],
              [-81.858004383561493, 35.747389677926627, 0.0],
              [-81.857976317236577, 35.747000278016898, 0.0],
              [-81.857863983760268, 35.746931611180486, 0.0],
              [-81.857189782846291, 35.74745841137694, 0.0],
              [-81.856992983212336, 35.747847611489021, 0.0],
              [-81.856767983320282, 35.749679611024312, 0.0],
              [-81.856571316326551, 35.749862878178348, 0.0],
              [-81.856262383040658, 35.749908677910994, 0.0],
              [-81.856037716497084, 35.749748277652216, 0.0],
              [-81.855953383469625, 35.749519277427659, 0.0],
              [-81.855672516713383, 35.749359011630808, 0.0],
              [-81.855082582919238, 35.74919861094164, 0.0],
              [-81.854548783842176, 35.749290210702284, 0.0],
              [-81.854324183013432, 35.749129877871745, 0.0],
              [-81.85440851636649, 35.748763477534112, 0.0],
              [-81.854773783414728, 35.748259610977904, 0.0],
              [-81.855139317253887, 35.746610877811236, 0.0],
              [-81.85542031624756, 35.746198611257341, 0.0],
              [-81.855476516436795, 35.745969611371798, 0.0],
              [-81.855167516998733, 35.74587801117783, 0.0],
              [-81.854830382991622, 35.746038277819487, 0.0],
              [-81.852666983303735, 35.747938810830604, 0.0],
              [-81.852161317151783, 35.748053278078331, 0.0],
              [-81.851262316251024, 35.748007277415198, 0.0],
              [-81.851037583762647, 35.748098877478462, 0.0],
              [-81.851037516752555, 35.748304877616434, 0.0],
              [-81.851795983328415, 35.748763077407538, 0.0],
              [-81.851852182988665, 35.749060677838649, 0.0],
              [-81.851655383260052, 35.749518811592594, 0.0],
              [-81.851711517129573, 35.749839410727525, 0.0],
              [-81.852750917262554, 35.750022677516291, 0.0],
              [-81.852750782982412, 35.750137211584189, 0.0],
              [-81.853163783866336, 35.750339678133393, 0.0],
              [-81.853556917142456, 35.751187277913445, 0.0],
              [-81.854315316160537, 35.752034678175711, 0.0],
              [-81.854399516740628, 35.752332278178976, 0.0],
              [-81.854258982952089, 35.752469810755656, 0.0],
              [-81.853865716541776, 35.752401011428105, 0.0],
              [-81.853528583524195, 35.752126078035722, 0.0],
              [-81.853191516633018, 35.75201161113376, 0.0],
              [-81.853107316461305, 35.751530611353701, 0.0],
              [-81.852742183625125, 35.751210011036662, 0.0],
              [-81.852264583629079, 35.751209877786593, 0.0],
              [-81.851955583325775, 35.751324277525775, 0.0],
              [-81.851562183935911, 35.751622011375069, 0.0],
              [-81.851393517037266, 35.752011211223511, 0.0],
              [-81.85083151733312, 35.752652411578396, 0.0],
              [-81.850719116614954, 35.752950077563362, 0.0],
              [-81.850466183673234, 35.753018678052911, 0.0],
              [-81.850241516893178, 35.752766810707548, 0.0],
              [-81.850241716939536, 35.752194277604161, 0.0],
              [-81.8506915832712, 35.750934811081251, 0.0],
              [-81.850719783716087, 35.750545477804231, 0.0],
              [-81.850579383703916, 35.750362277395851, 0.0],
              [-81.849905116611083, 35.750224811247406, 0.0],
              [-81.848612583050908, 35.7510948106897, 0.0],
              [-81.847966383910631, 35.75136941160013, 0.0],
              [-81.847516916880295, 35.751460878286693, 0.0],
              [-81.847629516628089, 35.750590677473319, 0.0],
              [-81.847180116884658, 35.750407477539653, 0.0],
              [-81.847188516148364, 35.750136410712884, 0.0],
              [-81.847216717310033, 35.74983867824885, 0.0],
              [-81.847104316664556, 35.749701278279915, 0.0],
              [-81.846879716362963, 35.749678411572155, 0.0],
              [-81.846486316277947, 35.749838610961312, 0.0],
              [-81.845812116251267, 35.74995301158711, 0.0],
              [-81.845812183112869, 35.749678210949021, 0.0],
              [-81.846346116877001, 35.749105677549323, 0.0],
              [-81.84637418333206, 35.748830877443027, 0.0],
              [-81.846261916291382, 35.748739277410301, 0.0],
              [-81.845110116599727, 35.748624611527788, 0.0],
              [-81.844885383936614, 35.748532878244227, 0.0],
              [-81.844885517266007, 35.748166478262746, 0.0],
              [-81.845194582914075, 35.747891811530565, 0.0],
              [-81.846121716569243, 35.747594277897946, 0.0],
              [-81.846711782900499, 35.747113411240136, 0.0],
              [-81.846908583711539, 35.74665547805909, 0.0],
              [-81.847133383005172, 35.746495211299482, 0.0],
              [-81.847161516416932, 35.746357810853169, 0.0],
              [-81.846431116189933, 35.746128678299598, 0.0],
              [-81.846178316432443, 35.745831011180762, 0.0],
              [-81.845700917113859, 35.745693478135607, 0.0],
              [-81.844886316171994, 35.745601677378644, 0.0],
              [-81.844633517062618, 35.745739010906426, 0.0],
              [-81.844071517025313, 35.74621987810314, 0.0],
              [-81.843453517207962, 35.746311410970634, 0.0],
              [-81.843172517199633, 35.746425810749621, 0.0],
              [-81.842975783362618, 35.746609010778542, 0.0],
              [-81.842722916855379, 35.747066877492152, 0.0],
              [-81.841626916283758, 35.748165877752925, 0.0],
              [-81.841626783925392, 35.74853227736368, 0.0],
              [-81.841935782905182, 35.748532410791292, 0.0],
              [-81.842581983238219, 35.748349277384101, 0.0],
              [-81.842722317150887, 35.748486810716948, 0.0],
              [-81.842722316834141, 35.748715810824656, 0.0],
              [-81.841851116215736, 35.749471211283911, 0.0],
              [-81.841204917314045, 35.749791810880126, 0.0],
              [-81.840671183437351, 35.749906210692266, 0.0],
              [-81.840277917057861, 35.749814477478864, 0.0],
              [-81.839435183377432, 35.749768477864535, 0.0],
              [-81.8387607832247, 35.750134677887011, 0.0],
              [-81.838527583325813, 35.750496811269926, 0.0],
              [-81.838386917165053, 35.750931877885392, 0.0],
              [-81.838414917191145, 35.751252477490596, 0.0],
              [-81.838639517110806, 35.751550277632205, 0.0],
              [-81.840100183692925, 35.751733877512457, 0.0],
              [-81.840886782995668, 35.751642477963202, 0.0],
              [-81.841701584000049, 35.751345011330123, 0.0],
              [-81.841813983730574, 35.751436611370821, 0.0],
              [-81.841757783730074, 35.751619877805105, 0.0],
              [-81.841027183745879, 35.752260810797836, 0.0],
              [-81.840633716534853, 35.752787477876772, 0.0],
              [-81.840605516678337, 35.753062277952075, 0.0],
              [-81.84113898291892, 35.753451677423655, 0.0],
              [-81.841447983097609, 35.753543410981209, 0.0],
              [-81.842038116301566, 35.753474878194787, 0.0],
              [-81.842965183090385, 35.753177277709099, 0.0],
              [-81.843442983803385, 35.752834011366943, 0.0],
              [-81.843218383503753, 35.752375877584861, 0.0],
              [-81.843274716858957, 35.75212407790972, 0.0],
              [-81.843499517022053, 35.752055411298244, 0.0],
              [-81.844004916617109, 35.752605210973542, 0.0],
              [-81.844313917313897, 35.752719677784356, 0.0],
              [-81.844903916281282, 35.752605277987307, 0.0],
              [-81.845381583048393, 35.752239077820398, 0.0],
              [-81.845634583736697, 35.751689411157649, 0.0],
              [-81.845971783309523, 35.751506411411981, 0.0],
              [-81.846449316465694, 35.751712478224455, 0.0],
              [-81.84689858389585, 35.752468278045349, 0.0],
              [-81.846842316836927, 35.752857610753139, 0.0],
              [-81.846308316371434, 35.75336141098564, 0.0],
              [-81.846195716322086, 35.754071278063087, 0.0],
              [-81.844622116655813, 35.755055610768004, 0.0],
              [-81.844593916777328, 35.755215877457005, 0.0],
              [-81.845717583223561, 35.755353677816935, 0.0],
              [-81.846054782901419, 35.755239077558393, 0.0],
              [-81.847094516356947, 35.754575277777718, 0.0],
              [-81.847206983791665, 35.754415011517956, 0.0],
              [-81.847263383751624, 35.753819611622454, 0.0],
              [-81.847460116916693, 35.75354487770236, 0.0],
              [-81.84782531642341, 35.753499077496365, 0.0],
              [-81.84813431615062, 35.753705277547418, 0.0],
              [-81.848443183175249, 35.754048877428332, 0.0],
              [-81.848695983623386, 35.754140477666823, 0.0],
              [-81.848695916744745, 35.754392411296685, 0.0],
              [-81.848190183437708, 35.755010610828286, 0.0],
              [-81.846981717017812, 35.756247011471444, 0.0],
              [-81.846981583128823, 35.756498878278485, 0.0],
              [-81.847093916726024, 35.756636277872666, 0.0],
              [-81.847711983712387, 35.756636477543438, 0.0],
              [-81.848077183974752, 35.756499211592541, 0.0],
              [-81.848695517142971, 35.755972477920032, 0.0],
              [-81.84903271698353, 35.755926811373698, 0.0],
              [-81.849229183389966, 35.756041411401, 0.0],
              [-81.849257183483445, 35.756591010964684, 0.0],
              [-81.849004116390702, 35.757071878328404, 0.0],
              [-81.848526383623422, 35.757415210783336, 0.0],
              [-81.848526316550533, 35.757804478305559, 0.0],
              [-81.84869478358624, 35.757964877761694, 0.0],
              [-81.850408783778335, 35.757850678219484, 0.0],
              [-81.850774117023377, 35.757667611215126, 0.0],
              [-81.850858382946669, 35.757369878207648, 0.0],
              [-81.850774183656881, 35.757049211183237, 0.0],
              [-81.85038098383005, 35.756568211306416, 0.0],
              [-81.850380983037496, 35.756339211291419, 0.0],
              [-81.850886716817882, 35.756156077848964, 0.0],
              [-81.850774516626075, 35.755858411169385, 0.0],
              [-81.850858783443371, 35.755652277580275, 0.0],
              [-81.851392516592441, 35.755789811440046, 0.0],
              [-81.851841983369269, 35.756041811564621, 0.0],
              [-81.852122917181092, 35.756774677781586, 0.0],
              [-81.852572317118245, 35.756706078052162, 0.0],
              [-81.852797183029793, 35.756500011196216, 0.0],
              [-81.852600516944577, 35.755950278064724, 0.0],
              [-81.852628717096337, 35.755675477490286, 0.0],
              [-81.852909716726501, 35.755400810960744, 0.0],
              [-81.853134517321052, 35.755332078290515, 0.0],
              [-81.853274916404956, 35.755400811200204, 0.0],
              [-81.853359182961583, 35.755973411505622, 0.0],
              [-81.853611916367498, 35.756454277914848, 0.0],
              [-81.853864716684399, 35.756591677392414, 0.0],
              [-81.854482783692632, 35.756385677681919, 0.0],
              [-81.855100983084469, 35.755973611176003, 0.0],
              [-81.855381983627638, 35.755973677883397, 0.0],
              [-81.855353583947107, 35.75670641067272, 0.0],
              [-81.854763516671539, 35.75750801103905, 0.0],
              [-81.854735316273008, 35.758034678198534, 0.0],
              [-81.855016316469062, 35.758401077577432, 0.0],
              [-81.855016183026905, 35.758767477449027, 0.0],
              [-81.85406078345973, 35.759568810696884, 0.0],
              [-81.853835783638488, 35.759935278235595, 0.0],
              [-81.853863783060405, 35.760278810804095, 0.0],
              [-81.854313316192474, 35.760439077691132, 0.0],
              [-81.855015783628687, 35.760485077429315, 0.0],
              [-81.855718183184322, 35.760370611036571, 0.0],
              [-81.85608338374962, 35.76014167822953, 0.0],
              [-81.856336383624466, 35.759844011608102, 0.0],
              [-81.856504982947541, 35.759431811156588, 0.0],
              [-81.856533317260542, 35.758973878199257, 0.0],
              [-81.856757983585396, 35.758722010887247, 0.0],
              [-81.857010916377973, 35.758767811060949, 0.0],
              [-81.857123183325967, 35.758882278244442, 0.0],
              [-81.857094917084027, 35.759660877891136, 0.0],
              [-81.857572517210386, 35.76002741095509, 0.0],
              [-81.857516383106741, 35.760210611264988, 0.0],
              [-81.857038716266686, 35.760462411480503, 0.0],
              [-81.856532983251029, 35.760874478239671, 0.0],
              [-81.856504783001725, 35.761012011317021, 0.0],
              [-81.856645183664, 35.761195210923674, 0.0],
              [-81.858021916751994, 35.761401411248926, 0.0],
              [-81.858302783776537, 35.761538811155752, 0.0],
              [-81.858752383234844, 35.761287010807855, 0.0],
              [-81.859033316600176, 35.761332810754389, 0.0],
              [-81.859510917064839, 35.761722211211342, 0.0],
              [-81.859735716814868, 35.762500878020553, 0.0],
              [-81.85998838356285, 35.762707011042011, 0.0],
              [-81.861365316783846, 35.762157478207797, 0.0],
              [-81.861477583946026, 35.761951411469752, 0.0],
              [-81.861477782851466, 35.761676611475622, 0.0],
              [-81.861280982862652, 35.761379010720439, 0.0],
              [-81.861056382968584, 35.760737678017463, 0.0],
              [-81.861196782947516, 35.760417010679205, 0.0],
              [-81.861758716114736, 35.760691877563382, 0.0],
              [-81.862320583181372, 35.761699610894176, 0.0],
              [-81.862741983865774, 35.761882810891116, 0.0],
              [-81.862741983620168, 35.762066077573714, 0.0],
              [-81.861365116394509, 35.763188010936886, 0.0],
              [-81.860718717058404, 35.763897877590168, 0.0],
              [-81.860746783539113, 35.764447477406563, 0.0],
              [-81.861196183525422, 35.764905477324319, 0.0],
              [-81.862123516255124, 35.765203411391127, 0.0],
              [-81.862938316700365, 35.765363810747637, 0.0],
              [-81.863893517067694, 35.765753210948141, 0.0],
              [-81.863865383819871, 35.765936277584657, 0.0],
              [-81.863668716204572, 35.766005011181917, 0.0],
              [-81.863022516559269, 35.765798811033712, 0.0],
              [-81.862291982985411, 35.765821611376509, 0.0],
              [-81.861842383840226, 35.765981877731839, 0.0],
              [-81.861448983568977, 35.766279610725533, 0.0],
              [-81.861111716498186, 35.766897877355206, 0.0],
              [-81.861111583942048, 35.767287277908089, 0.0],
              [-81.861589316391886, 35.767516277849211, 0.0],
              [-81.862713183188134, 35.767241611022051, 0.0],
              [-81.862966116962554, 35.767264411151061, 0.0],
              [-81.863134716691164, 35.767379078147307, 0.0],
              [-81.863134716171515, 35.767562277424851, 0.0],
              [-81.861757717242213, 35.768134477702986, 0.0],
              [-81.861813982917397, 35.768615477687703, 0.0],
              [-81.861926316838421, 35.768913211136542, 0.0],
              [-81.863302917190964, 35.770058277591382, 0.0],
              [-81.863583916829512, 35.770401877703769, 0.0],
              [-81.863611983620601, 35.770676677568403, 0.0],
              [-81.863330983355539, 35.770676677986565, 0.0],
              [-81.862797182795433, 35.770401811407694, 0.0],
              [-81.861617183853909, 35.769462877993114, 0.0],
              [-81.86122378287952, 35.769302477705331, 0.0],
              [-81.860717983255881, 35.769210811502418, 0.0],
              [-81.86054938286108, 35.76868407760211, 0.0],
              [-81.859790917171452, 35.768248877431965, 0.0],
              [-81.859397383179996, 35.76829467754667, 0.0],
              [-81.859200783601395, 35.768615277483413, 0.0],
              [-81.858947517086065, 35.770607477855819, 0.0],
              [-81.858694582851328, 35.770973877406327, 0.0],
              [-81.858666383125652, 35.771409077494475, 0.0],
              [-81.858301116477534, 35.771821211562958, 0.0],
              [-81.858300983500285, 35.772050211139103, 0.0],
              [-81.85877858306695, 35.772485278037955, 0.0],
              [-81.859003383378862, 35.772531078063992, 0.0],
              [-81.860239783701189, 35.772371010943672, 0.0],
              [-81.860829983009538, 35.772394010937155, 0.0],
              [-81.860886116884018, 35.772829077827303, 0.0],
              [-81.861335583234549, 35.773035211194454, 0.0],
              [-81.861279383472677, 35.773630677448025, 0.0],
              [-81.860717316375755, 35.774111477352527, 0.0],
              [-81.860773516588992, 35.774477878020434, 0.0],
              [-81.861813116413643, 35.774867277878791, 0.0],
              [-81.861813116436394, 35.77505061084225, 0.0],
              [-81.861588183210685, 35.775119278199178, 0.0],
              [-81.860969983456727, 35.775050477365198, 0.0],
              [-81.860407983884556, 35.77511907777658, 0.0],
              [-81.860014583580963, 35.775348077463107, 0.0],
              [-81.860014582812227, 35.775645811262287, 0.0],
              [-81.86015498345607, 35.775897678090288, 0.0],
              [-81.860773316277346, 35.776126810925113, 0.0],
              [-81.861138516951357, 35.776310011095198, 0.0],
              [-81.861335183087775, 35.776561878129826, 0.0],
              [-81.862037716890839, 35.77679087773646, 0.0],
              [-81.862150116593696, 35.776905411268778, 0.0],
              [-81.862121917139902, 35.777615411595043, 0.0],
              [-81.862402916181509, 35.777867211502233, 0.0],
              [-81.862824383793992, 35.778027611004546, 0.0],
              [-81.863526983834461, 35.777981877778579, 0.0],
              [-81.864285783526554, 35.77759261148838, 0.0],
              [-81.865016383708337, 35.77750107746602, 0.0],
              [-81.865466117233808, 35.777249277738399, 0.0],
              [-81.867461316708116, 35.777066078196306, 0.0],
              [-81.867826582775038, 35.776974477514983, 0.0],
              [-81.868332516425028, 35.77658521135367, 0.0],
              [-81.868472982769163, 35.776585278042404, 0.0],
              [-81.868697783640556, 35.776722677771438, 0.0],
              [-81.867433183066197, 35.777821810886138, 0.0],
              [-81.867264583811021, 35.778073677558567, 0.0],
              [-81.867264583431435, 35.778440078213684, 0.0],
              [-81.868388583787791, 35.778852411065849, 0.0],
              [-81.868613516956998, 35.779150077772769, 0.0],
              [-81.868753916356596, 35.779539411317131, 0.0],
              [-81.868697716703679, 35.779882877433622, 0.0],
              [-81.868247983812935, 35.780478277735568, 0.0],
              [-81.868163716210105, 35.780844678118719, 0.0],
              [-81.868388517141526, 35.78107367786037, 0.0],
              [-81.869119116253302, 35.781256877686516, 0.0],
              [-81.869315916203846, 35.781577477528295, 0.0],
              [-81.869428317202775, 35.782012677336084, 0.0],
              [-81.869568782851758, 35.78219601081549, 0.0],
              [-81.870355716880255, 35.782516478207, 0.0],
              [-81.870692917138953, 35.782814211117199, 0.0],
              [-81.870945916251685, 35.783203477585168, 0.0],
              [-81.871283117132947, 35.783455411186473, 0.0],
              [-81.871732716482455, 35.78366147770911, 0.0],
              [-81.8722105833711, 35.783776077958599, 0.0],
              [-81.87266018303896, 35.784142478055976, 0.0],
              [-81.873194183499436, 35.784394410878825, 0.0],
              [-81.873531516356508, 35.784669210998096, 0.0],
              [-81.874824316973786, 35.784554477824457, 0.0],
              [-81.875217716967654, 35.784417211125472, 0.0],
              [-81.8758079163659, 35.783821811127368, 0.0],
              [-81.876116916619566, 35.783684411405815, 0.0],
              [-81.876622916396983, 35.783707277376529, 0.0],
              [-81.877184916717596, 35.783867477875603, 0.0],
              [-81.877437916949589, 35.784142278011899, 0.0],
              [-81.877409916150256, 35.784600277841712, 0.0],
              [-81.87667918341576, 35.786180477298352, 0.0],
              [-81.876707383918344, 35.78645527765601, 0.0],
              [-81.87710078330386, 35.787027811398822, 0.0],
              [-81.87777538323671, 35.787394077995927, 0.0],
              [-81.878000317142337, 35.787691810792055, 0.0],
              [-81.878168982952445, 35.78847041129292, 0.0],
              [-81.878927916871078, 35.789203211160675, 0.0],
              [-81.880417516589688, 35.789248878208362, 0.0],
              [-81.880811116474547, 35.789363411261945, 0.0],
              [-81.881092182745647, 35.789844277965003, 0.0],
              [-81.881626316330966, 35.790302210764324, 0.0],
              [-81.882328917169403, 35.790187677366163, 0.0],
              [-81.882806717157081, 35.790416677304933, 0.0],
              [-81.882778582770285, 35.790554011095331, 0.0],
              [-81.882553783816306, 35.790714411180673, 0.0],
              [-81.882750583313623, 35.791035010858479, 0.0],
              [-81.882497583910492, 35.791149411006259, 0.0],
              [-81.881935517088252, 35.791103678066726, 0.0],
              [-81.88171071677435, 35.790989211157097, 0.0],
              [-81.881120316237627, 35.790439677929498, 0.0],
              [-81.880867383578519, 35.790302210745665, 0.0],
              [-81.880530183115226, 35.790508411436257, 0.0],
              [-81.880389716494292, 35.791080877492426, 0.0],
              [-81.880473983382871, 35.79126421125126, 0.0],
              [-81.880642583897654, 35.791378678213, 0.0],
              [-81.883088116836788, 35.791721877430177, 0.0],
              [-81.883312916974745, 35.791905077470197, 0.0],
              [-81.883172383341289, 35.79206541125442, 0.0],
              [-81.881738916993513, 35.791928211162457, 0.0],
              [-81.880698916273957, 35.791676278053757, 0.0],
              [-81.880277316304372, 35.79169927763337, 0.0],
              [-81.88016491689649, 35.791790878092556, 0.0],
              [-81.880164916090152, 35.7920198774856, 0.0],
              [-81.880389716351573, 35.792157278150306, 0.0],
              [-81.881823317068367, 35.792431878203615, 0.0],
              [-81.881907583570637, 35.792500610626824, 0.0],
              [-81.881879583906112, 35.79261521126471, 0.0],
              [-81.880389716093859, 35.792523677520819, 0.0],
              [-81.879827716278768, 35.792661011349594, 0.0],
              [-81.879743383927178, 35.792752678064055, 0.0],
              [-81.88005251711246, 35.793050277694711, 0.0],
              [-81.880024516341365, 35.793302277797963, 0.0],
              [-81.879687183350981, 35.793554277397071, 0.0],
              [-81.879687316883178, 35.793783277395981, 0.0],
              [-81.879884116989828, 35.793966411292637, 0.0],
              [-81.879659182969206, 35.794332810828756, 0.0],
              [-81.879546916177091, 35.794745010720305, 0.0],
              [-81.879321983209223, 35.794722210847027, 0.0],
              [-81.878984716767476, 35.794470277583031, 0.0],
              [-81.87867538283345, 35.794539010934145, 0.0],
              [-81.878085183172104, 35.794997077832548, 0.0],
              [-81.877607382917844, 35.795111611158283, 0.0],
              [-81.876314317087093, 35.794791010913762, 0.0],
              [-81.875724116697882, 35.794882677330015, 0.0],
              [-81.875302516230576, 35.79513461069768, 0.0],
              [-81.874824716374889, 35.79522607777546, 0.0],
              [-81.874206316584392, 35.795180278231918, 0.0],
              [-81.873868983514456, 35.794905477878146, 0.0],
            ],
            [
              [-81.850560183729229, 35.7475492110147, 0.0],
              [-81.85089731640187, 35.747549277682644, 0.0],
              [-81.851122116827071, 35.747251611489354, 0.0],
              [-81.851150183541648, 35.746656077757116, 0.0],
              [-81.850813183139593, 35.746541477795112, 0.0],
              [-81.850560383216774, 35.746587411368381, 0.0],
              [-81.850307583212398, 35.746839277898481, 0.0],
              [-81.850279182937754, 35.747411811031903, 0.0],
              [-81.850560183729229, 35.7475492110147, 0.0],
            ],
            [
              [-81.865869916448744, 35.75013821116103, 0.0],
              [-81.8666481163078, 35.750524477966465, 0.0],
              [-81.867575183948645, 35.750410010788976, 0.0],
              [-81.867771916760063, 35.750547477661605, 0.0],
              [-81.867771783937329, 35.750776477468698, 0.0],
              [-81.86735038280834, 35.751165811092434, 0.0],
              [-81.867350517135804, 35.751371811219244, 0.0],
              [-81.867603316769873, 35.751486411324542, 0.0],
              [-81.868502182753389, 35.751509277613231, 0.0],
              [-81.868614583678323, 35.751600878273422, 0.0],
              [-81.868558382780918, 35.751898610940671, 0.0],
              [-81.868249316803457, 35.752196278151835, 0.0],
              [-81.868221182984172, 35.752654210732331, 0.0],
              [-81.868389716918202, 35.752883278008468, 0.0],
              [-81.868811116675573, 35.753043611507557, 0.0],
              [-81.869316783004365, 35.752974877566984, 0.0],
              [-81.869541583375138, 35.752860477782768, 0.0],
              [-81.870103516986362, 35.752883411007211, 0.0],
              [-81.870440583067406, 35.752723011225285, 0.0],
              [-81.871255316871142, 35.751646677550774, 0.0],
              [-81.871227317067294, 35.751326077542842, 0.0],
              [-81.870047383481307, 35.751600877896294, 0.0],
              [-81.869541717043901, 35.751509277638796, 0.0],
              [-81.869429316697634, 35.751349011638538, 0.0],
              [-81.869541783675075, 35.751119877676864, 0.0],
              [-81.870131716887997, 35.750501677688668, 0.0],
              [-81.870055782927864, 35.750138211310883, 0.0],
              [-81.869774716784775, 35.749748811502982, 0.0],
              [-81.869437583812697, 35.749496877737116, 0.0],
              [-81.868482583390886, 35.749107610936043, 0.0],
              [-81.867471316773276, 35.748855677958183, 0.0],
              [-81.866909382828055, 35.748809878075605, 0.0],
              [-81.866178983887281, 35.749038877412609, 0.0],
              [-81.86586991649574, 35.749290677634349, 0.0],
              [-81.865757583510486, 35.749542677917383, 0.0],
              [-81.865757583603596, 35.749977811047735, 0.0],
              [-81.865869916448744, 35.75013821116103, 0.0],
            ],
            [
              [-81.959048916999592, 35.747927877896196, 0.0],
              [-81.959161383314338, 35.748019411619623, 0.0],
              [-81.960172382628116, 35.747835411315073, 0.0],
              [-81.960312715974382, 35.747720810865822, 0.0],
              [-81.960480783785613, 35.747308411245243, 0.0],
              [-81.961434716603108, 35.746277211117366, 0.0],
              [-81.961378182661406, 35.746071211156426, 0.0],
              [-81.960788116430365, 35.745819811047085, 0.0],
              [-81.96017031696924, 35.745980477413134, 0.0],
              [-81.959945916396009, 35.746255478305201, 0.0],
              [-81.959103916541238, 35.746943211646702, 0.0],
              [-81.959048916999592, 35.747927877896196, 0.0],
            ],
            [
              [-81.960084516398268, 35.744675277627607, 0.0],
              [-81.960926382941025, 35.743987611522108, 0.0],
              [-81.960954316907021, 35.743758611529913, 0.0],
              [-81.960785583079016, 35.743644211472855, 0.0],
              [-81.959998983218767, 35.743530211665117, 0.0],
              [-81.959380582757873, 35.743210211049643, 0.0],
              [-81.958874583386219, 35.742844211195575, 0.0],
              [-81.958340583441824, 35.742569810780786, 0.0],
              [-81.957947183804308, 35.742547078079774, 0.0],
              [-81.957890783236195, 35.74224947825607, 0.0],
              [-81.957469116082081, 35.741883411178051, 0.0],
              [-81.956935183740953, 35.741700611004916, 0.0],
              [-81.956962983378077, 35.741494478018254, 0.0],
              [-81.957187515960456, 35.741311077924735, 0.0],
              [-81.957159116749636, 35.741036411260765, 0.0],
              [-81.956821715984589, 35.740693078159723, 0.0],
              [-81.955922182717245, 35.740052478023173, 0.0],
              [-81.955500183571999, 35.739480410905074, 0.0],
              [-81.954825916537857, 35.739251877801877, 0.0],
              [-81.954151716415424, 35.739183677594532, 0.0],
              [-81.953224783011009, 35.739253011541649, 0.0],
              [-81.952607116528853, 35.7394594774197, 0.0],
              [-81.951708982872901, 35.740124277511164, 0.0],
              [-81.950839383615843, 35.741247011448792, 0.0],
              [-81.950278583765765, 35.742232077468998, 0.0],
              [-81.95027918266986, 35.742758811650717, 0.0],
              [-81.951262983337358, 35.743559610717071, 0.0],
              [-81.951712382994657, 35.743490611480297, 0.0],
              [-81.952273916836745, 35.743192477814269, 0.0],
              [-81.952582516236845, 35.742825811164458, 0.0],
              [-81.952807183507204, 35.742825678106115, 0.0],
              [-81.953116516749262, 35.743146078214572, 0.0],
              [-81.954127783607333, 35.743008011389875, 0.0],
              [-81.954464917022406, 35.743145077633187, 0.0],
              [-81.954886116050957, 35.743007410833052, 0.0],
              [-81.955054383590621, 35.742778277589473, 0.0],
              [-81.955363183389267, 35.742617811159398, 0.0],
              [-81.955755983581696, 35.742251077402216, 0.0],
              [-81.956149715970724, 35.742617210807659, 0.0],
              [-81.9566553167911, 35.742639810840132, 0.0],
              [-81.957189317059303, 35.742959877837301, 0.0],
              [-81.958651516071271, 35.744241278345029, 0.0],
              [-81.959438583315944, 35.744744477923874, 0.0],
              [-81.960084516398268, 35.744675277627607, 0.0],
            ],
            [
              [-81.986581183695762, 35.731095211239953, 0.0],
              [-81.987058716307502, 35.731140478342333, 0.0],
              [-81.987423517044974, 35.730956878215807, 0.0],
              [-81.987788183224453, 35.730658878264535, 0.0],
              [-81.987900183440615, 35.730452677850231, 0.0],
              [-81.987787383003408, 35.730178011408334, 0.0],
              [-81.98750658333806, 35.730086811149256, 0.0],
              [-81.987281983500196, 35.730155677456146, 0.0],
              [-81.986580783582227, 35.730820410872425, 0.0],
              [-81.986581183695762, 35.731095211239953, 0.0],
            ],
            [
              [-81.994309316330458, 35.71624821101544, 0.0],
              [-81.99383178296965, 35.716111211639706, 0.0],
              [-81.993044783699943, 35.715700011146637, 0.0],
              [-81.992735983379902, 35.715700278104293, 0.0],
              [-81.992258782663797, 35.715815211319985, 0.0],
              [-81.991950182707058, 35.715975877648397, 0.0],
              [-81.99189438289136, 35.716227811689691, 0.0],
              [-81.992427983599114, 35.716273077777359, 0.0],
              [-81.992933316198091, 35.71613507743632, 0.0],
              [-81.993832316491435, 35.71645481127068, 0.0],
              [-81.994197316797468, 35.716454478317395, 0.0],
              [-81.994309316330458, 35.71624821101544, 0.0],
            ],
            [
              [-81.994055315985335, 35.715424011125755, 0.0],
              [-81.99399771702393, 35.714462278038845, 0.0],
              [-81.993772783588909, 35.714325210879764, 0.0],
              [-81.993407716941661, 35.714279677920246, 0.0],
              [-81.992789983187222, 35.714372011609086, 0.0],
              [-81.992677916452379, 35.714532410785225, 0.0],
              [-81.992734183365485, 35.714623878153169, 0.0],
              [-81.993043316272207, 35.714715211561341, 0.0],
              [-81.992987382959967, 35.714829677422074, 0.0],
              [-81.992481983576241, 35.714899011419917, 0.0],
              [-81.99237018284758, 35.715196811694334, 0.0],
              [-81.992566982787707, 35.715311011366346, 0.0],
              [-81.993184715956332, 35.715310411028725, 0.0],
              [-81.993858783273183, 35.715492877468471, 0.0],
              [-81.994055315985335, 35.715424011125755, 0.0],
            ],
            [
              [-81.850027516822209, 35.743404010870755, 0.0],
              [-81.849802716803197, 35.743266611662378, 0.0],
              [-81.849521916244839, 35.743312477441528, 0.0],
              [-81.849184716859128, 35.743587210811768, 0.0],
              [-81.849184583439481, 35.743930677752104, 0.0],
              [-81.84963398321203, 35.744182677960019, 0.0],
              [-81.849971116459727, 35.744068277978087, 0.0],
              [-81.850083517064121, 35.743701877588606, 0.0],
              [-81.850027516822209, 35.743404010870755, 0.0],
            ],
            [
              [-81.852556383189736, 35.739282411664611, 0.0],
              [-81.852668783844763, 35.738824277445609, 0.0],
              [-81.853062117239574, 35.73857247806373, 0.0],
              [-81.854550782986365, 35.738824611210482, 0.0],
              [-81.854634982846491, 35.738778811277889, 0.0],
              [-81.854578917229446, 35.738435211431792, 0.0],
              [-81.853764516252781, 35.737702277765095, 0.0],
              [-81.853483582955604, 35.737725277534338, 0.0],
              [-81.853034117255007, 35.737816811279515, 0.0],
              [-81.852781182903172, 35.738206010959154, 0.0],
              [-81.851854316153108, 35.73850361078064, 0.0],
              [-81.851601383114343, 35.738755477534347, 0.0],
              [-81.851404716631762, 35.739167810722044, 0.0],
              [-81.851432716925501, 35.739809011092802, 0.0],
              [-81.851685516983821, 35.740152477807314, 0.0],
              [-81.851853982982973, 35.740175410736093, 0.0],
              [-81.852050717214112, 35.740083811075884, 0.0],
              [-81.852275517042855, 35.739534210946722, 0.0],
              [-81.852556383189736, 35.739282411664611, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1597,
        ComID: 9751288,
        FDate: "1999/10/08",
        Resolution: 3,
        GNIS_ID: "01000779",
        GNIS_Name: "Rhodhiss Lake",
        AreaSqKm: 8.793,
        Elevation: 302.0,
        ReachCode: "03050101002508",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 0.88837638067000002,
        Shape_Area: 0.00087648957,
        Reservoir: 34,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.621246784156057, 35.789569878236406, 0.0],
              [-81.618998383993443, 35.789157610875634, 0.0],
              [-81.618885916568573, 35.789272078066979, 0.0],
              [-81.618492384119691, 35.789340677792481, 0.0],
              [-81.618183383282982, 35.788585011379453, 0.0],
              [-81.617705517492169, 35.788356011533637, 0.0],
              [-81.617480584317676, 35.788333011549966, 0.0],
              [-81.617002783393019, 35.788951411592677, 0.0],
              [-81.61680611747218, 35.788997077308856, 0.0],
              [-81.61663738392383, 35.788905611143157, 0.0],
              [-81.616665517345737, 35.788378810966321, 0.0],
              [-81.616834183261702, 35.78819561085843, 0.0],
              [-81.616834317659681, 35.788035277749309, 0.0],
              [-81.616384583994929, 35.78796661147517, 0.0],
              [-81.616300184289216, 35.78776041097683, 0.0],
              [-81.616777984244578, 35.787600211111695, 0.0],
              [-81.616834316971406, 35.787394078168354, 0.0],
              [-81.61615971730744, 35.786638411472055, 0.0],
              [-81.615906784060726, 35.786478011077044, 0.0],
              [-81.615457117538881, 35.786546677926239, 0.0],
              [-81.614529717347708, 35.785859611257131, 0.0],
              [-81.61416438351938, 35.785378677979921, 0.0],
              [-81.613799116783454, 35.785080811264478, 0.0],
              [-81.612562517327902, 35.784783078122643, 0.0],
              [-81.611100984127816, 35.784599810803897, 0.0],
              [-81.610257917595391, 35.784599611200605, 0.0],
              [-81.607447184362158, 35.785103077961843, 0.0],
              [-81.606491716726737, 35.785194610846609, 0.0],
              [-81.605620384320147, 35.785011277732387, 0.0],
              [-81.604327717009838, 35.784576011121835, 0.0],
              [-81.60089911671237, 35.784209011180451, 0.0],
              [-81.600758516629156, 35.784300477714453, 0.0],
              [-81.60070211747491, 35.784552478207367, 0.0],
              [-81.60092678430928, 35.785147810980135, 0.0],
              [-81.601825983999007, 35.786041210652471, 0.0],
              [-81.601853983693672, 35.786316011549324, 0.0],
              [-81.601516716505756, 35.786384610823134, 0.0],
              [-81.601010784208114, 35.786224211289237, 0.0],
              [-81.600673583833057, 35.786201277547164, 0.0],
              [-81.600645383770669, 35.786544877977846, 0.0],
              [-81.600813916611287, 35.786865477448174, 0.0],
              [-81.601263183285113, 35.788422811390035, 0.0],
              [-81.601487984342015, 35.788697678117344, 0.0],
              [-81.602752584344714, 35.78970547816931, 0.0],
              [-81.603483184006706, 35.789980477338531, 0.0],
              [-81.603623717540543, 35.790117877811674, 0.0],
              [-81.602752384031035, 35.790255211167477, 0.0],
              [-81.602218383803319, 35.790094677886408, 0.0],
              [-81.601206783883882, 35.789567877932619, 0.0],
              [-81.600869517640987, 35.789499011559172, 0.0],
              [-81.60044778372486, 35.789773877771829, 0.0],
              [-81.60030731679953, 35.789705211444662, 0.0],
              [-81.600251183865481, 35.788995077831743, 0.0],
              [-81.600166983978369, 35.788857677938346, 0.0],
              [-81.600167116792832, 35.788033278049966, 0.0],
              [-81.600054916708018, 35.787506610784519, 0.0],
              [-81.599268384385681, 35.785834677817483, 0.0],
              [-81.599240516790275, 35.785262011371472, 0.0],
              [-81.599353117615038, 35.784666677419025, 0.0],
              [-81.599156383255959, 35.784483411117641, 0.0],
              [-81.598762983901608, 35.784368811012818, 0.0],
              [-81.595053117111021, 35.78420767824862, 0.0],
              [-81.591399317436725, 35.784390010992006, 0.0],
              [-81.588813783920799, 35.784137411126601, 0.0],
              [-81.587886183738547, 35.784457677425273, 0.0],
              [-81.587633317151997, 35.784297411555933, 0.0],
              [-81.587408716992968, 35.783885077304298, 0.0],
              [-81.58695918330821, 35.783587278258196, 0.0],
              [-81.585778917506815, 35.783289211067512, 0.0],
              [-81.584064517551113, 35.78312841098343, 0.0],
              [-81.581619516554227, 35.783150410686908, 0.0],
              [-81.580972783683137, 35.783631011188298, 0.0],
              [-81.580916517207996, 35.783905811107203, 0.0],
              [-81.581112983995482, 35.784180677999828, 0.0],
              [-81.581562517565189, 35.784478610669325, 0.0],
              [-81.582236983692312, 35.784753611466662, 0.0],
              [-81.582545984251809, 35.784937010716583, 0.0],
              [-81.582602116981121, 35.785234811439146, 0.0],
              [-81.582545783549165, 35.785349277658298, 0.0],
              [-81.582348984090046, 35.785372077771314, 0.0],
              [-81.581477916574926, 35.785074078034938, 0.0],
              [-81.580213717053397, 35.784295011558633, 0.0],
              [-81.579820716883731, 35.783287077379136, 0.0],
              [-81.579511584183109, 35.783218278140538, 0.0],
              [-81.579229917435086, 35.784432010928384, 0.0],
              [-81.578976716579945, 35.784844078039136, 0.0],
              [-81.578835917371578, 35.785325011071038, 0.0],
              [-81.57861098391875, 35.785393611555364, 0.0],
              [-81.578526783642502, 35.785302011125538, 0.0],
              [-81.578668184077287, 35.78374481069401, 0.0],
              [-81.577965783662989, 35.783172010728741, 0.0],
              [-81.577431983404495, 35.783011477749575, 0.0],
              [-81.576082983740065, 35.782896477905339, 0.0],
              [-81.574818183309318, 35.783079211584585, 0.0],
              [-81.573441183341188, 35.782918277909459, 0.0],
              [-81.572850784314795, 35.783170011507018, 0.0],
              [-81.572710117422744, 35.783559077948652, 0.0],
              [-81.572597583638853, 35.783673611339843, 0.0],
              [-81.572372583949587, 35.783765077475003, 0.0],
              [-81.5722883174315, 35.783719278062804, 0.0],
              [-81.572288716993668, 35.783123877482581, 0.0],
              [-81.572092116723354, 35.782986411181689, 0.0],
              [-81.570574384236068, 35.78298587797326, 0.0],
              [-81.570461984334528, 35.783077411398608, 0.0],
              [-81.570377383941945, 35.783443678065971, 0.0],
              [-81.570096384221785, 35.783466477798207, 0.0],
              [-81.569675117273476, 35.783077011320977, 0.0],
              [-81.568326117207789, 35.782778678205936, 0.0],
              [-81.567735983446781, 35.782755477693271, 0.0],
              [-81.567482983727444, 35.78284687770681, 0.0],
              [-81.567089317092211, 35.783281878238022, 0.0],
              [-81.566582917145666, 35.783991477994803, 0.0],
              [-81.566245584300219, 35.78401427757057, 0.0],
              [-81.566217717481351, 35.783693678159736, 0.0],
              [-81.56633058360184, 35.78300667766316, 0.0],
              [-81.565965383683718, 35.782777477600298, 0.0],
              [-81.56515031697289, 35.782777077992122, 0.0],
              [-81.564840917720161, 35.783189210632017, 0.0],
              [-81.56447551746821, 35.783234810955889, 0.0],
              [-81.564335184398075, 35.782799611205029, 0.0],
              [-81.564110583619438, 35.78259341085392, 0.0],
              [-81.562396583267557, 35.782134611599467, 0.0],
              [-81.561778516868145, 35.781836477799551, 0.0],
              [-81.561272783379579, 35.781698877576183, 0.0],
              [-81.561047783414054, 35.781790277314798, 0.0],
              [-81.560990983756469, 35.782569077358382, 0.0],
              [-81.560709916594433, 35.782614611184989, 0.0],
              [-81.560541383263697, 35.782500077365746, 0.0],
              [-81.559389117433227, 35.782545211212764, 0.0],
              [-81.559389317485383, 35.782293277508238, 0.0],
              [-81.559810983483558, 35.782110411324602, 0.0],
              [-81.560092183915998, 35.781858610703232, 0.0],
              [-81.560120917418416, 35.781263211167797, 0.0],
              [-81.560345917449638, 35.780896877485397, 0.0],
              [-81.560430717516027, 35.780438877510633, 0.0],
              [-81.559588317557257, 35.779522410925999, 0.0],
              [-81.559222984363231, 35.779384811198391, 0.0],
              [-81.558098983367842, 35.779201011202602, 0.0],
              [-81.557143516702553, 35.779200478116124, 0.0],
              [-81.557030917117686, 35.779292011140647, 0.0],
              [-81.556974383266336, 35.779864477397467, 0.0],
              [-81.556580916713912, 35.779887211299759, 0.0],
              [-81.556412716927596, 35.779406077690282, 0.0],
              [-81.556047583573786, 35.779062411504519, 0.0],
              [-81.555850783888218, 35.779131011180993, 0.0],
              [-81.555850316569106, 35.779703610678467, 0.0],
              [-81.555681717606362, 35.779772211305982, 0.0],
              [-81.55545691738314, 35.779634677967159, 0.0],
              [-81.55534458369425, 35.779428477571102, 0.0],
              [-81.554249516994972, 35.778351478220756, 0.0],
              [-81.553350583353335, 35.777938678301545, 0.0],
              [-81.551495984457105, 35.777754411012765, 0.0],
              [-81.550371917553946, 35.777730877721091, 0.0],
              [-81.550259384000128, 35.777822410924514, 0.0],
              [-81.550146517570653, 35.77834907791663, 0.0],
              [-81.550230783870049, 35.778417811422273, 0.0],
              [-81.551101783689802, 35.778510010666402, 0.0],
              [-81.551551383417333, 35.778647610773021, 0.0],
              [-81.552618583981982, 35.779472677515336, 0.0],
              [-81.553292184149512, 35.780549411157267, 0.0],
              [-81.553713516810873, 35.780801611233208, 0.0],
              [-81.553741384443114, 35.78100767754767, 0.0],
              [-81.553319917274038, 35.781076211235074, 0.0],
              [-81.553347717381868, 35.781351011560275, 0.0],
              [-81.553488116833435, 35.781465611081913, 0.0],
              [-81.553572117360702, 35.781877811264835, 0.0],
              [-81.553571117644381, 35.782954278268811, 0.0],
              [-81.553429983692041, 35.783572477458272, 0.0],
              [-81.553092183609891, 35.784121877370055, 0.0],
              [-81.55250151731039, 35.784694078004044, 0.0],
              [-81.552051716797806, 35.784899877967057, 0.0],
              [-81.55140518432718, 35.784991211534994, 0.0],
              [-81.55050591708968, 35.784876078205571, 0.0],
              [-81.549971783686473, 35.785013277572936, 0.0],
              [-81.550027917124979, 35.784884078162023, 0.0],
              [-81.550140717012312, 35.784624011266544, 0.0],
              [-81.550506317399638, 35.784486811446882, 0.0],
              [-81.551068383708525, 35.784441277999143, 0.0],
              [-81.551743117738695, 35.784281411302572, 0.0],
              [-81.552502517368893, 35.783572010646381, 0.0],
              [-81.552868316825041, 35.783045477382807, 0.0],
              [-81.552897116626298, 35.78235841117278, 0.0],
              [-81.552419784128233, 35.781900211317051, 0.0],
              [-81.551352384006634, 35.781304078115276, 0.0],
              [-81.550509516953838, 35.781005878007797, 0.0],
              [-81.54786838402957, 35.780363011014472, 0.0],
              [-81.54775591728216, 35.780225611341365, 0.0],
              [-81.547644783463753, 35.778920010750035, 0.0],
              [-81.547448584070963, 35.778393210723848, 0.0],
              [-81.547308183594495, 35.778255677486648, 0.0],
              [-81.547083317538295, 35.778232611210079, 0.0],
              [-81.54671778345228, 35.778461411207331, 0.0],
              [-81.546380184416307, 35.778896411560304, 0.0],
              [-81.545845717706001, 35.779354077566218, 0.0],
              [-81.543962117527229, 35.780062678259483, 0.0],
              [-81.543258917256452, 35.780657678028547, 0.0],
              [-81.542527916699697, 35.781046477430358, 0.0],
              [-81.542526917295518, 35.782054211196822, 0.0],
              [-81.542189584018985, 35.782099810913898, 0.0],
              [-81.541487317508299, 35.781801611180406, 0.0],
              [-81.541094184445541, 35.781412011320803, 0.0],
              [-81.541122783779258, 35.7808166110486, 0.0],
              [-81.540898183981284, 35.780633278238298, 0.0],
              [-81.539858584086133, 35.780380611408603, 0.0],
              [-81.537723116906335, 35.780035611460448, 0.0],
              [-81.537273384313252, 35.780104011336647, 0.0],
              [-81.537132717619031, 35.780264210872737, 0.0],
              [-81.537132117279882, 35.780836677857856, 0.0],
              [-81.537328383591998, 35.781226078199836, 0.0],
              [-81.537889917715191, 35.781799011267921, 0.0],
              [-81.537917717112094, 35.78214267746624, 0.0],
              [-81.537805184304517, 35.782234211423066, 0.0],
              [-81.53729918354955, 35.782279611285084, 0.0],
              [-81.536034984200597, 35.78186647825823, 0.0],
              [-81.534264517438729, 35.781681877527546, 0.0],
              [-81.531060384233058, 35.781885678162674, 0.0],
              [-81.529374117789843, 35.781884411089734, 0.0],
              [-81.528924184068373, 35.78211307821681, 0.0],
              [-81.52847438351246, 35.782227211392708, 0.0],
              [-81.527772583637756, 35.781539611237193, 0.0],
              [-81.527182983510102, 35.781058211136411, 0.0],
              [-81.526677383713817, 35.780783011526978, 0.0],
              [-81.525272516779992, 35.780621477727408, 0.0],
              [-81.524822717362724, 35.780689878117855, 0.0],
              [-81.524344717504391, 35.780941411263292, 0.0],
              [-81.52417571744536, 35.781147278193657, 0.0],
              [-81.524147384103372, 35.78133061068182, 0.0],
              [-81.524287583657468, 35.781536811221642, 0.0],
              [-81.524286784186984, 35.782223678237806, 0.0],
              [-81.524539183800087, 35.782704878010705, 0.0],
              [-81.523724117112167, 35.782750077524099, 0.0],
              [-81.52349931684391, 35.782658277701685, 0.0],
              [-81.523331383459592, 35.782108410718102, 0.0],
              [-81.522910317081354, 35.781741678266293, 0.0],
              [-81.521196517183441, 35.781305277440623, 0.0],
              [-81.520830983357925, 35.781465277594911, 0.0],
              [-81.520521516656643, 35.781739811006595, 0.0],
              [-81.520239184116505, 35.78270127808733, 0.0],
              [-81.519957983609373, 35.782769810727366, 0.0],
              [-81.519593583789913, 35.782013678223983, 0.0],
              [-81.519228517437469, 35.781853077902326, 0.0],
              [-81.518104516746263, 35.78173761154639, 0.0],
              [-81.516867717766488, 35.781942611149852, 0.0],
              [-81.51664258332417, 35.782125610917397, 0.0],
              [-81.516725184147433, 35.783339611424196, 0.0],
              [-81.51641598334696, 35.783362211551712, 0.0],
              [-81.516275783717305, 35.783201811487928, 0.0],
              [-81.516136117509944, 35.782468811114597, 0.0],
              [-81.515996117763621, 35.782148011201002, 0.0],
              [-81.515434184151871, 35.782056011077763, 0.0],
              [-81.514675317709077, 35.782078211306185, 0.0],
              [-81.513438316832108, 35.782306078058376, 0.0],
              [-81.51335371696878, 35.782580811036986, 0.0],
              [-81.512903916987057, 35.782694877306298, 0.0],
              [-81.512426516753891, 35.782396677395312, 0.0],
              [-81.511976717629139, 35.782419077779061, 0.0],
              [-81.511779517165607, 35.782762477466378, 0.0],
              [-81.511526383607887, 35.782876677582735, 0.0],
              [-81.511499517282573, 35.782052277539286, 0.0],
              [-81.511359117339097, 35.781960610874499, 0.0],
              [-81.507902383478722, 35.781865677811062, 0.0],
              [-81.506328316883383, 35.782024477668323, 0.0],
              [-81.506018917506935, 35.782253211142894, 0.0],
              [-81.505596317470165, 35.782962810800939, 0.0],
              [-81.505567517484039, 35.783352010988132, 0.0],
              [-81.505735517122034, 35.783787277930116, 0.0],
              [-81.505454317356865, 35.7838558112594, 0.0],
              [-81.504865117402929, 35.783259811384106, 0.0],
              [-81.504134517615029, 35.78314447736043, 0.0],
              [-81.504134783747546, 35.782961277653044, 0.0],
              [-81.505344117360536, 35.78238987813522, 0.0],
              [-81.50534438435119, 35.782206677319039, 0.0],
              [-81.50520398453429, 35.782092078011885, 0.0],
              [-81.504248984042817, 35.781724677882899, 0.0],
              [-81.501523117571082, 35.781607477793472, 0.0],
              [-81.499819517072538, 35.781763011014121, 0.0],
              [-81.498948583649593, 35.78201581129219, 0.0],
              [-81.498948916895245, 35.78224481132608, 0.0],
              [-81.499708916920781, 35.782953877393496, 0.0],
              [-81.499652783543127, 35.783091410999049, 0.0],
              [-81.498894117513345, 35.78313801102118, 0.0],
              [-81.498444984489225, 35.783459077599652, 0.0],
              [-81.498107584504382, 35.783413611259085, 0.0],
              [-81.498107117731337, 35.783115877636149, 0.0],
              [-81.498191316717723, 35.783047077965115, 0.0],
              [-81.498105917453429, 35.782360211566541, 0.0],
              [-81.497627716881766, 35.782040011612153, 0.0],
              [-81.494423517273177, 35.78195167765832, 0.0],
              [-81.493917783338759, 35.782066677750358, 0.0],
              [-81.493777383600545, 35.78218127742872, 0.0],
              [-81.493806983442923, 35.783120211262471, 0.0],
              [-81.493582317327821, 35.783189211409109, 0.0],
              [-81.493328984522208, 35.783029078043519, 0.0],
              [-81.49299058375982, 35.782250811552615, 0.0],
              [-81.492765583504848, 35.782136478274509, 0.0],
              [-81.492484716712198, 35.782228411543564, 0.0],
              [-81.49226078358042, 35.782847010965241, 0.0],
              [-81.49144638396578, 35.783282878121305, 0.0],
              [-81.491305783411107, 35.78321427737923, 0.0],
              [-81.491332717350645, 35.782389810915063, 0.0],
              [-81.490713917782202, 35.782069877733427, 0.0],
              [-81.489421116681086, 35.782094011098934, 0.0],
              [-81.489224584408078, 35.782162878169132, 0.0],
              [-81.489084383974401, 35.782414877715915, 0.0],
              [-81.489085183399041, 35.782941611197657, 0.0],
              [-81.489394716953967, 35.783262011290425, 0.0],
              [-81.48933878333618, 35.78351401066783, 0.0],
              [-81.487936184026267, 35.785324478085435, 0.0],
              [-81.487684184250227, 35.786034610892088, 0.0],
              [-81.487686383964373, 35.787546210729083, 0.0],
              [-81.487405917780606, 35.787844211572995, 0.0],
              [-81.487406384239165, 35.78825641082998, 0.0],
              [-81.487687984327636, 35.788622477632835, 0.0],
              [-81.487997383383686, 35.788736678242714, 0.0],
              [-81.488615583566471, 35.788667411564653, 0.0],
              [-81.489121384419761, 35.788529611426547, 0.0],
              [-81.490076316862428, 35.788047677676211, 0.0],
              [-81.491031517281442, 35.787772011395639, 0.0],
              [-81.492407516855863, 35.786969077944512, 0.0],
              [-81.493222584135538, 35.786968277690107, 0.0],
              [-81.49361171751832, 35.786892410677652, 0.0],
              [-81.493812583734282, 35.786853077757186, 0.0],
              [-81.493700783765689, 35.787173877612311, 0.0],
              [-81.492774383914451, 35.787930478013422, 0.0],
              [-81.492634117470786, 35.78813681099075, 0.0],
              [-81.492803317011777, 35.788548877306539, 0.0],
              [-81.492775584042278, 35.788777877551873, 0.0],
              [-81.492522784238759, 35.788892677509722, 0.0],
              [-81.49162338405381, 35.788916477346795, 0.0],
              [-81.490892783877243, 35.789077477595733, 0.0],
              [-81.48912218349453, 35.789193678054744, 0.0],
              [-81.488307583459203, 35.789492211351579, 0.0],
              [-81.487886383373251, 35.789767411103249, 0.0],
              [-81.487717984386819, 35.790019410696281, 0.0],
              [-81.487662383356252, 35.790363011093206, 0.0],
              [-81.48732511710692, 35.790340477779914, 0.0],
              [-81.486986983638033, 35.789836877533737, 0.0],
              [-81.486732717835025, 35.788829478115041, 0.0],
              [-81.486366784133494, 35.788440611457645, 0.0],
              [-81.48586091765803, 35.788486810810603, 0.0],
              [-81.485467717142058, 35.78871621130952, 0.0],
              [-81.485214784308809, 35.788739277773978, 0.0],
              [-81.485102183484784, 35.788647811568367, 0.0],
              [-81.485101717015837, 35.788327211088102, 0.0],
              [-81.485887916830137, 35.787731078031797, 0.0],
              [-81.485999917878445, 35.787524878059081, 0.0],
              [-81.486055316784288, 35.786883611456219, 0.0],
              [-81.485942584030553, 35.786746277877917, 0.0],
              [-81.485211717611904, 35.786609611505639, 0.0],
              [-81.485211317140241, 35.786334677985948, 0.0],
              [-81.485688917711428, 35.786128077753744, 0.0],
              [-81.485913317577257, 35.785876011323715, 0.0],
              [-81.48602531680649, 35.78555527815849, 0.0],
              [-81.48633418364912, 35.785326011001452, 0.0],
              [-81.486333583399215, 35.784913811225053, 0.0],
              [-81.485995716970834, 35.784456011154042, 0.0],
              [-81.485292183947635, 35.783815477318242, 0.0],
              [-81.484982917392912, 35.783724077520148, 0.0],
              [-81.483971116813223, 35.783702211533338, 0.0],
              [-81.483521517661956, 35.783794211607507, 0.0],
              [-81.483183984530939, 35.783565478047848, 0.0],
              [-81.483014983781132, 35.78333661079914, 0.0],
              [-81.482312317676545, 35.783383077807564, 0.0],
              [-81.48172251724192, 35.783635477498059, 0.0],
              [-81.480936383395488, 35.784254478262909, 0.0],
              [-81.480403516809503, 35.785010678076695, 0.0],
              [-81.480122516847146, 35.785056810905743, 0.0],
              [-81.479981716703264, 35.784896610750927, 0.0],
              [-81.47981238443468, 35.784484611239336, 0.0],
              [-81.47916591708244, 35.784416411029163, 0.0],
              [-81.478041984162758, 35.784692078201935, 0.0],
              [-81.477705116949082, 35.784921611309578, 0.0],
              [-81.477396117200541, 35.785013411408585, 0.0],
              [-81.476552917762248, 35.785037077721526, 0.0],
              [-81.476384384358468, 35.785174611421716, 0.0],
              [-81.476244317252238, 35.785518211405616, 0.0],
              [-81.47658278339658, 35.786434011005014, 0.0],
              [-81.476442517004287, 35.786594411509675, 0.0],
              [-81.476105183942863, 35.786548877973885, 0.0],
              [-81.475654917280409, 35.786182878235032, 0.0],
              [-81.475485916930225, 35.785816610939534, 0.0],
              [-81.475007384214265, 35.785336011167118, 0.0],
              [-81.474670117136711, 35.785290611555098, 0.0],
              [-81.473377384027344, 35.785451877320888, 0.0],
              [-81.472422317144307, 35.785819078253354, 0.0],
              [-81.472281984548459, 35.786025277784972, 0.0],
              [-81.472170717457672, 35.786964278076844, 0.0],
              [-81.471777317386653, 35.787033411339117, 0.0],
              [-81.471608517791552, 35.786896077789841, 0.0],
              [-81.471467583608245, 35.78652981114007, 0.0],
              [-81.471073383571365, 35.785980478180392, 0.0],
              [-81.470679717014931, 35.785728877990991, 0.0],
              [-81.470061384526346, 35.785775211025715, 0.0],
              [-81.469724316843809, 35.785912877638843, 0.0],
              [-81.469191183488277, 35.786714810901991, 0.0],
              [-81.468881984225874, 35.786738078222868, 0.0],
              [-81.468741317924341, 35.78655487819627, 0.0],
              [-81.468712316868547, 35.785822077629334, 0.0],
              [-81.468374784474733, 35.785547477627112, 0.0],
              [-81.466519783992993, 35.785686277328907, 0.0],
              [-81.466126583644538, 35.785961411374323, 0.0],
              [-81.465930316782817, 35.786259411171699, 0.0],
              [-81.465790717293004, 35.786946611465872, 0.0],
              [-81.465593983454028, 35.786992477857545, 0.0],
              [-81.465425183414865, 35.786855210956837, 0.0],
              [-81.46539678400012, 35.786694877565303, 0.0],
              [-81.465030983737662, 35.786328811365173, 0.0],
              [-81.464131384377168, 35.78621487796233, 0.0],
              [-81.463569184264585, 35.786055077286782, 0.0],
              [-81.463428717338601, 35.786146811495747, 0.0],
              [-81.463204316881388, 35.786627810926809, 0.0],
              [-81.463345517894808, 35.787131610735422, 0.0],
              [-81.463289917776962, 35.787681211141006, 0.0],
              [-81.462841517438534, 35.788780810741976, 0.0],
              [-81.462588516891884, 35.788735211198876, 0.0],
              [-81.462503984558523, 35.788575011544573, 0.0],
              [-81.462418517100517, 35.787590278210821, 0.0],
              [-81.4623055175107, 35.78713227826394, 0.0],
              [-81.462192983660586, 35.786995011281597, 0.0],
              [-81.461967117784312, 35.786056278001993, 0.0],
              [-81.461657584039457, 35.785781678108087, 0.0],
              [-81.461123717167268, 35.785850811218452, 0.0],
              [-81.460702317261166, 35.786057210760312, 0.0],
              [-81.460590117776761, 35.786240410645526, 0.0],
              [-81.460787517135827, 35.786812811250073, 0.0],
              [-81.460759517373205, 35.786996077491914, 0.0],
              [-81.460534717655094, 35.78701907793296, 0.0],
              [-81.459859317629608, 35.786241010954576, 0.0],
              [-81.459353517071335, 35.786332877673466, 0.0],
              [-81.458510717868421, 35.786631211353992, 0.0],
              [-81.458258116859213, 35.787043611149564, 0.0],
              [-81.458033317330504, 35.787043810840181, 0.0],
              [-81.457611317638893, 35.786677610825187, 0.0],
              [-81.457273917244351, 35.786517477778055, 0.0],
              [-81.456767984500004, 35.786563677993804, 0.0],
              [-81.456683916756205, 35.786724077943987, 0.0],
              [-81.456712717633209, 35.787388211486451, 0.0],
              [-81.457473584188136, 35.789380078027534, 0.0],
              [-81.457952184303963, 35.790181410884394, 0.0],
              [-81.458036983603421, 35.790570610976097, 0.0],
              [-81.458009917046766, 35.791463677792912, 0.0],
              [-81.457757117413735, 35.791578477358755, 0.0],
              [-81.457560317194719, 35.791555678058884, 0.0],
              [-81.457138183508931, 35.791189478235417, 0.0],
              [-81.457137116971168, 35.790204811065067, 0.0],
              [-81.456996317340597, 35.789838478219444, 0.0],
              [-81.456742917398174, 35.789495211115934, 0.0],
              [-81.456208384565798, 35.789037477653977, 0.0],
              [-81.456039316834378, 35.788648278168068, 0.0],
              [-81.455448917926134, 35.788396810931587, 0.0],
              [-81.455195717432943, 35.78812221107961, 0.0],
              [-81.45519538351617, 35.787939011329023, 0.0],
              [-81.455363784401627, 35.787709811120436, 0.0],
              [-81.455306983798238, 35.787137211219722, 0.0],
              [-81.455109984121378, 35.786862611115097, 0.0],
              [-81.454660116857525, 35.786588011042312, 0.0],
              [-81.453114117636872, 35.786543277944212, 0.0],
              [-81.452608384084201, 35.786864210734137, 0.0],
              [-81.452468117302843, 35.787093410986188, 0.0],
              [-81.452468583531257, 35.787436878204488, 0.0],
              [-81.453058984047956, 35.787642677645586, 0.0],
              [-81.453424583584336, 35.787917211568676, 0.0],
              [-81.453537384454364, 35.788123210615325, 0.0],
              [-81.45356598427098, 35.788741477717416, 0.0],
              [-81.453425717897161, 35.788947677591985, 0.0],
              [-81.453229117789391, 35.789062277329741, 0.0],
              [-81.453060383651291, 35.789062477318971, 0.0],
              [-81.45241338406008, 35.788536211327809, 0.0],
              [-81.451738583942927, 35.788193077313224, 0.0],
              [-81.450726516993285, 35.788010478160338, 0.0],
              [-81.450726117737986, 35.787758611469684, 0.0],
              [-81.451512583548606, 35.787139810961776, 0.0],
              [-81.451568517069603, 35.786887810972054, 0.0],
              [-81.451427917130331, 35.786773410852135, 0.0],
              [-81.450753184125773, 35.786567811407203, 0.0],
              [-81.449291583448755, 35.786568677363718, 0.0],
              [-81.447830517733379, 35.786981810686179, 0.0],
              [-81.446903116837206, 35.787074011367238, 0.0],
              [-81.446341183482588, 35.787372078127696, 0.0],
              [-81.44606051746608, 35.78771567748386, 0.0],
              [-81.445780117239693, 35.788494477492861, 0.0],
              [-81.446033917489203, 35.789387478102462, 0.0],
              [-81.44575291708648, 35.789456410800852, 0.0],
              [-81.445612183474495, 35.789364810746072, 0.0],
              [-81.445330584388614, 35.788723811500176, 0.0],
              [-81.445160717448402, 35.787395611155816, 0.0],
              [-81.445272517910979, 35.786594011466107, 0.0],
              [-81.444850583530183, 35.786296477900493, 0.0],
              [-81.444428917231477, 35.786136477540964, 0.0],
              [-81.443726317419191, 35.786136877962889, 0.0],
              [-81.443192383791967, 35.78636621125338, 0.0],
              [-81.442630917414746, 35.78709927743521, 0.0],
              [-81.44254678394357, 35.787442878238231, 0.0],
              [-81.442013784149637, 35.788519611551827, 0.0],
              [-81.44181711680848, 35.788611410752644, 0.0],
              [-81.441704584236362, 35.788519811308674, 0.0],
              [-81.441788317357222, 35.787718077841895, 0.0],
              [-81.441703384128658, 35.787054010697993, 0.0],
              [-81.441534383973874, 35.786756477311599, 0.0],
              [-81.440887516831864, 35.786230077888888, 0.0],
              [-81.440184783881193, 35.786207611234644, 0.0],
              [-81.439735183643862, 35.786322277946915, 0.0],
              [-81.439089117530813, 35.786666211477915, 0.0],
              [-81.438302516821935, 35.787307877772179, 0.0],
              [-81.438302717301838, 35.787422411274136, 0.0],
              [-81.437712784503475, 35.787949410832347, 0.0],
              [-81.437544184173433, 35.788018277607037, 0.0],
              [-81.437318517328308, 35.786781611384399, 0.0],
              [-81.436896516873034, 35.786415411574346, 0.0],
              [-81.436277983717119, 35.786141010798211, 0.0],
              [-81.43610931691363, 35.785957811618793, 0.0],
              [-81.436193517638344, 35.785820411398738, 0.0],
              [-81.436867717619322, 35.785545211429486, 0.0],
              [-81.439228717137908, 35.785612677909626, 0.0],
              [-81.439931183503205, 35.785452011543271, 0.0],
              [-81.440380784473192, 35.785245677325989, 0.0],
              [-81.44111098440446, 35.784649811038953, 0.0],
              [-81.441532583598601, 35.784580877868692, 0.0],
              [-81.441898117653352, 35.784786811112468, 0.0],
              [-81.442207184629027, 35.784786611269993, 0.0],
              [-81.442263316947503, 35.784534678033587, 0.0],
              [-81.441700117945885, 35.783389877496631, 0.0],
              [-81.441221917904926, 35.782726011143836, 0.0],
              [-81.441530717731425, 35.782336477553677, 0.0],
              [-81.441586384155784, 35.781764010711768, 0.0],
              [-81.441191984532438, 35.780825211437211, 0.0],
              [-81.440967117897472, 35.780642077367958, 0.0],
              [-81.440376916771214, 35.780665411562666, 0.0],
              [-81.439983517774479, 35.780802877891659, 0.0],
              [-81.439449517876142, 35.78080327732814, 0.0],
              [-81.439449383889396, 35.780551411317134, 0.0],
              [-81.441078916998933, 35.780023677866957, 0.0],
              [-81.441247384423235, 35.779771610832739, 0.0],
              [-81.44163958399362, 35.778305811488863, 0.0],
              [-81.441638983973789, 35.777641677990125, 0.0],
              [-81.441442183435882, 35.777435611319248, 0.0],
              [-81.4407395170401, 35.777275677772145, 0.0],
              [-81.440346117348298, 35.777413278006655, 0.0],
              [-81.439896317302839, 35.777184611141308, 0.0],
              [-81.439867984167392, 35.77697847757883, 0.0],
              [-81.441188517337579, 35.776588410934806, 0.0],
              [-81.441385117634582, 35.776405077477719, 0.0],
              [-81.441384784587754, 35.77608447736003, 0.0],
              [-81.441075317457589, 35.775580811214638, 0.0],
              [-81.440119117449754, 35.774779811168067, 0.0],
              [-81.439781583613055, 35.774619677822464, 0.0],
              [-81.439303917089461, 35.774574211257665, 0.0],
              [-81.43893878403469, 35.774757477687693, 0.0],
              [-81.438629717467734, 35.774826411544439, 0.0],
              [-81.437913906862974, 35.774506996755498, 0.0],
              [-81.437758117034974, 35.774437477873903, 0.0],
              [-81.43767378415572, 35.774323011553065, 0.0],
              [-81.437947516946693, 35.773381077708599, 0.0],
              [-81.438082399383973, 35.77291701845251, 0.0],
              [-81.438206317487086, 35.77249067826277, 0.0],
              [-81.438458517776553, 35.771666211119125, 0.0],
              [-81.438598584419623, 35.771093410794485, 0.0],
              [-81.438625784043026, 35.76994827795756, 0.0],
              [-81.439327583484427, 35.7692380783066, 0.0],
              [-81.439327317043592, 35.768871677746908, 0.0],
              [-81.438765117764817, 35.768642877365224, 0.0],
              [-81.438764916835709, 35.768368077449281, 0.0],
              [-81.438849183883221, 35.768276478250385, 0.0],
              [-81.439832316937199, 35.767978211120628, 0.0],
              [-81.439944716841964, 35.767886610925082, 0.0],
              [-81.440000384319802, 35.767359811401811, 0.0],
              [-81.440112784472404, 35.767291011113393, 0.0],
              [-81.440365717039057, 35.767313810926133, 0.0],
              [-81.440674917815869, 35.767519811518369, 0.0],
              [-81.441096583856663, 35.767634011382036, 0.0],
              [-81.441433583437671, 35.767542210693797, 0.0],
              [-81.441938984335692, 35.766900611067953, 0.0],
              [-81.442444316884902, 35.76646527825018, 0.0],
              [-81.443062384274597, 35.766258810921698, 0.0],
              [-81.443399584112868, 35.766281477846796, 0.0],
              [-81.443511983582042, 35.766373077492311, 0.0],
              [-81.443455984096332, 35.766556277530874, 0.0],
              [-81.442669717136511, 35.767129277759409, 0.0],
              [-81.442529517706802, 35.767518677467351, 0.0],
              [-81.442642316933075, 35.767770477558607, 0.0],
              [-81.442923384032284, 35.767976411251119, 0.0],
              [-81.443822784397469, 35.76827367781361, 0.0],
              [-81.443823117705406, 35.768502677828529, 0.0],
              [-81.442614983717093, 35.768778211381694, 0.0],
              [-81.441744116894014, 35.769076411018588, 0.0],
              [-81.441659984561454, 35.769236811459514, 0.0],
              [-81.441688317527266, 35.769442878065099, 0.0],
              [-81.442447317799775, 35.769808877485815, 0.0],
              [-81.442503783620793, 35.770083677934537, 0.0],
              [-81.442026184533759, 35.770313011329563, 0.0],
              [-81.441941984253816, 35.770496211368531, 0.0],
              [-81.442505183466608, 35.771847010971904, 0.0],
              [-81.442898984014192, 35.772327811454801, 0.0],
              [-81.443545584527314, 35.772533478253557, 0.0],
              [-81.443714316775612, 35.772716678246454, 0.0],
              [-81.443715116826596, 35.773495278246408, 0.0],
              [-81.44408118449573, 35.774434077583194, 0.0],
              [-81.444925184565349, 35.775464077891918, 0.0],
              [-81.445515717090558, 35.77580727773811, 0.0],
              [-81.44593731783101, 35.775921610866376, 0.0],
              [-81.44663978347053, 35.775783677790905, 0.0],
              [-81.447004717859031, 35.775394211458632, 0.0],
              [-81.447116917532327, 35.775073478215681, 0.0],
              [-81.447510117246338, 35.774912877914005, 0.0],
              [-81.447846983617993, 35.774615011394104, 0.0],
              [-81.448099716843402, 35.774225610800968, 0.0],
              [-81.448295783641953, 35.773630011460909, 0.0],
              [-81.448210917486222, 35.772965877553396, 0.0],
              [-81.449138184186751, 35.772965277851597, 0.0],
              [-81.449728117921396, 35.772713011131941, 0.0],
              [-81.449840583723628, 35.772827410763831, 0.0],
              [-81.449812717032472, 35.773079411065183, 0.0],
              [-81.449279383839297, 35.773698010829229, 0.0],
              [-81.449167183666745, 35.773972877813115, 0.0],
              [-81.449167783608061, 35.774545477398149, 0.0],
              [-81.449280384155642, 35.774751477816984, 0.0],
              [-81.449786717130877, 35.775232210799558, 0.0],
              [-81.450320784268058, 35.775392078119452, 0.0],
              [-81.450910917888649, 35.775323078258822, 0.0],
              [-81.451275983836126, 35.775139611058563, 0.0],
              [-81.451809384402026, 35.774612611352389, 0.0],
              [-81.452062383848002, 35.774635211346109, 0.0],
              [-81.452596784587925, 35.77502427792453, 0.0],
              [-81.453094383415078, 35.775175878158755, 0.0],
              [-81.45327151711615, 35.775229877942884, 0.0],
              [-81.453159184443052, 35.775413211440288, 0.0],
              [-81.452737917477293, 35.775619610763833, 0.0],
              [-81.451782584431385, 35.775803411345514, 0.0],
              [-81.45155811776732, 35.776170011041302, 0.0],
              [-81.452092517906792, 35.776696278099941, 0.0],
              [-81.452008517070396, 35.776948278011147, 0.0],
              [-81.451783784326381, 35.777017211059665, 0.0],
              [-81.451502583579668, 35.776834077427168, 0.0],
              [-81.451333716958359, 35.776582278189089, 0.0],
              [-81.451024317196001, 35.776353477332428, 0.0],
              [-81.450546584577381, 35.776376678192285, 0.0],
              [-81.450125184379004, 35.776491477751961, 0.0],
              [-81.449844117750644, 35.776468811616155, 0.0],
              [-81.449253383706903, 35.775965277927639, 0.0],
              [-81.448888117185788, 35.775896878149133, 0.0],
              [-81.448466717830371, 35.776149011278612, 0.0],
              [-81.448101316890856, 35.776057611444983, 0.0],
              [-81.447848184407746, 35.775851677597878, 0.0],
              [-81.447342517906691, 35.775966477708593, 0.0],
              [-81.446864984616369, 35.776356077727279, 0.0],
              [-81.44678111743761, 35.776722611187537, 0.0],
              [-81.446781316939393, 35.777020278090902, 0.0],
              [-81.445853916761266, 35.776929211366451, 0.0],
              [-81.445685184237021, 35.776837677381039, 0.0],
              [-81.445432316877728, 35.776860811523186, 0.0],
              [-81.445263783825567, 35.777044077577898, 0.0],
              [-81.445208584357303, 35.778143411334256, 0.0],
              [-81.445068384623298, 35.77850987818757, 0.0],
              [-81.445124917574432, 35.778853411241478, 0.0],
              [-81.44633411674937, 35.779677077789657, 0.0],
              [-81.446334384571358, 35.779906077419767, 0.0],
              [-81.445969117604477, 35.779929211267792, 0.0],
              [-81.445069584220917, 35.779677811326835, 0.0],
              [-81.444985117338618, 35.779586277997694, 0.0],
              [-81.44470411713074, 35.779609410740704, 0.0],
              [-81.444704517312275, 35.780021611160507, 0.0],
              [-81.444873183891502, 35.780159011384271, 0.0],
              [-81.44490151721898, 35.780365011616922, 0.0],
              [-81.445464317697514, 35.781212077395779, 0.0],
              [-81.445464784592403, 35.781761677449339, 0.0],
              [-81.445268384199508, 35.782059611187094, 0.0],
              [-81.445268716867474, 35.782403078107301, 0.0],
              [-81.445859717840079, 35.783387410943348, 0.0],
              [-81.446337917746774, 35.783776477440135, 0.0],
              [-81.446675316924882, 35.783913677441269, 0.0],
              [-81.447096783810508, 35.783844678146785, 0.0],
              [-81.447517984514732, 35.783501010785841, 0.0],
              [-81.447826784603052, 35.783042677821186, 0.0],
              [-81.448078984277416, 35.782264011448234, 0.0],
              [-81.448416316807538, 35.782240877474251, 0.0],
              [-81.448753984057959, 35.782813077834199, 0.0],
              [-81.449457317828902, 35.783385277649415, 0.0],
              [-81.450244184458441, 35.783361878025495, 0.0],
              [-81.451087184492707, 35.783132277489493, 0.0],
              [-81.451536516828597, 35.782788610835539, 0.0],
              [-81.451760984578414, 35.782421878123913, 0.0],
              [-81.451732384575891, 35.781918077683301, 0.0],
              [-81.451450584029743, 35.7812542106725, 0.0],
              [-81.451562583791372, 35.780727410769892, 0.0],
              [-81.451787516995381, 35.780818811021078, 0.0],
              [-81.452349983555209, 35.781276477707202, 0.0],
              [-81.45330691762436, 35.782535411252098, 0.0],
              [-81.453616183617356, 35.782718410674448, 0.0],
              [-81.454571717466266, 35.782626078129695, 0.0],
              [-81.45516158417368, 35.782419677601439, 0.0],
              [-81.455807384326846, 35.781732210811988, 0.0],
              [-81.456003717642091, 35.781342677377175, 0.0],
              [-81.456256516758998, 35.781228077830015, 0.0],
              [-81.456368984137868, 35.781273810721039, 0.0],
              [-81.456397984107156, 35.782144077672896, 0.0],
              [-81.45653871679734, 35.782418677992005, 0.0],
              [-81.457073117422709, 35.78267027757456, 0.0],
              [-81.457719384439983, 35.782624010855294, 0.0],
              [-81.458000317250708, 35.78250927821049, 0.0],
              [-81.458252984333384, 35.782234411484751, 0.0],
              [-81.458280917044831, 35.782051077893449, 0.0],
              [-81.458477517429273, 35.781936478126589, 0.0],
              [-81.458646184593846, 35.781982078124415, 0.0],
              [-81.459349516772008, 35.782600010957957, 0.0],
              [-81.459630584144008, 35.782668477813111, 0.0],
              [-81.460810783688274, 35.782507277589474, 0.0],
              [-81.461654384555402, 35.782758610624462, 0.0],
              [-81.462356783566506, 35.78268947803403, 0.0],
              [-81.46258151762612, 35.782574811013809, 0.0],
              [-81.462552984430914, 35.782139611111241, 0.0],
              [-81.462018183901577, 35.781498878197674, 0.0],
              [-81.46204598422409, 35.781224011176711, 0.0],
              [-81.462664517798899, 35.781429610816957, 0.0],
              [-81.462917384207984, 35.781360811282823, 0.0],
              [-81.462944384385494, 35.780353010674247, 0.0],
              [-81.463141184581588, 35.780375810709366, 0.0],
              [-81.463422517737371, 35.7806962115597, 0.0],
              [-81.463507117673373, 35.780948010672937, 0.0],
              [-81.463788517079905, 35.781199677383505, 0.0],
              [-81.46412571767948, 35.781107877976481, 0.0],
              [-81.464322117307461, 35.780878811059118, 0.0],
              [-81.464378117553096, 35.780672610924242, 0.0],
              [-81.464658917089608, 35.780443277357335, 0.0],
              [-81.464602117172291, 35.780008277397812, 0.0],
              [-81.464348917375332, 35.779733677939006, 0.0],
              [-81.464095917921711, 35.779619411098082, 0.0],
              [-81.464095517465964, 35.779390411181765, 0.0],
              [-81.464235984116726, 35.779321611094275, 0.0],
              [-81.464769984009266, 35.779389811150516, 0.0],
              [-81.46516338423973, 35.77929801105185, 0.0],
              [-81.466202183880682, 35.778358211457579, 0.0],
              [-81.466229783668396, 35.777991810750358, 0.0],
              [-81.466848117900213, 35.778037077454869, 0.0],
              [-81.466960317414504, 35.777830811096074, 0.0],
              [-81.467297317169084, 35.777601611384327, 0.0],
              [-81.467746916742556, 35.777555411161963, 0.0],
              [-81.467747183635922, 35.777807277457377, 0.0],
              [-81.467382517758452, 35.778357410722549, 0.0],
              [-81.466006784286435, 35.779595010953827, 0.0],
              [-81.465052983523535, 35.781038477368398, 0.0],
              [-81.464969183603827, 35.781382077794447, 0.0],
              [-81.464576517717873, 35.782115277586612, 0.0],
              [-81.464661184254467, 35.782435811422943, 0.0],
              [-81.46488611711095, 35.782481478041277, 0.0],
              [-81.466065784098703, 35.781885211140889, 0.0],
              [-81.46679591678415, 35.781334878278138, 0.0],
              [-81.467442116852936, 35.781196877829572, 0.0],
              [-81.467554316871826, 35.780990811497837, 0.0],
              [-81.467581983464427, 35.780647211477579, 0.0],
              [-81.467919317425157, 35.780624077482379, 0.0],
              [-81.468453784227705, 35.781173277819939, 0.0],
              [-81.469325384140518, 35.781424478066413, 0.0],
              [-81.469662384168331, 35.781241010732572, 0.0],
              [-81.469662116893232, 35.781012010896731, 0.0],
              [-81.469549584454455, 35.780897610690005, 0.0],
              [-81.469549317152499, 35.780645677447531, 0.0],
              [-81.469886316716142, 35.780462277467493, 0.0],
              [-81.47008311779652, 35.780576611409217, 0.0],
              [-81.470111917390668, 35.781103278223476, 0.0],
              [-81.470252917327201, 35.781446678172273, 0.0],
              [-81.472417116812892, 35.781605211285687, 0.0],
              [-81.472669917094962, 35.781513410947625, 0.0],
              [-81.472725917269941, 35.781353077346218, 0.0],
              [-81.472332117562615, 35.781055677574606, 0.0],
              [-81.472275584408692, 35.780758010985686, 0.0],
              [-81.472753117510891, 35.780643077672671, 0.0],
              [-81.472724516866009, 35.780162210685432, 0.0],
              [-81.473033383618045, 35.780001677768972, 0.0],
              [-81.473201583844499, 35.779658078053835, 0.0],
              [-81.473228783920959, 35.778970877995214, 0.0],
              [-81.473397317305043, 35.778833278167006, 0.0],
              [-81.473650316960871, 35.778901811046133, 0.0],
              [-81.473819783759325, 35.779634477616575, 0.0],
              [-81.47396218385127, 35.781100210944992, 0.0],
              [-81.474102983482695, 35.781397811122048, 0.0],
              [-81.474862383440154, 35.78167201073375, 0.0],
              [-81.475621183517987, 35.781671277423122, 0.0],
              [-81.476182983751897, 35.781533411363597, 0.0],
              [-81.476323383814162, 35.781395878273571, 0.0],
              [-81.476435183446313, 35.780846210721165, 0.0],
              [-81.476631783977595, 35.780823077914846, 0.0],
              [-81.476772517518313, 35.780914611229065, 0.0],
              [-81.47677271711882, 35.781029077938122, 0.0],
              [-81.476969584195075, 35.781212210751363, 0.0],
              [-81.478851917024315, 35.780729611103347, 0.0],
              [-81.479104383943863, 35.780340011604082, 0.0],
              [-81.479356384240191, 35.779629811518767, 0.0],
              [-81.479271184252127, 35.779103211025223, 0.0],
              [-81.479130517103371, 35.778920210816736, 0.0],
              [-81.478736983493704, 35.778851811092117, 0.0],
              [-81.478680584169197, 35.778691477408486, 0.0],
              [-81.479326317225386, 35.778210011623955, 0.0],
              [-81.479353983985547, 35.777912277469447, 0.0],
              [-81.47907218398727, 35.777340011603506, 0.0],
              [-81.479268916944392, 35.777271210927353, 0.0],
              [-81.479803184502913, 35.777614210744979, 0.0],
              [-81.480899383481258, 35.777727677767075, 0.0],
              [-81.481124117279279, 35.777658878023963, 0.0],
              [-81.482949383389752, 35.776535011049923, 0.0],
              [-81.483510983643853, 35.776305410649314, 0.0],
              [-81.484494583440593, 35.776281610930504, 0.0],
              [-81.484803517036056, 35.776075277485155, 0.0],
              [-81.484802717564349, 35.775525677936891, 0.0],
              [-81.485280317696123, 35.775410678161158, 0.0],
              [-81.485504783975998, 35.775204411577256, 0.0],
              [-81.485897317619731, 35.774494077321627, 0.0],
              [-81.486009184075598, 35.774104610885303, 0.0],
              [-81.486205517493175, 35.77387541120634, 0.0],
              [-81.487273384147429, 35.773874478218005, 0.0],
              [-81.487413984444331, 35.773988678160045, 0.0],
              [-81.487498717491178, 35.774171878264596, 0.0],
              [-81.487779717236464, 35.77417161126283, 0.0],
              [-81.487807517132794, 35.774057078264519, 0.0],
              [-81.488086117615566, 35.77388207774549, 0.0],
              [-81.488172583531096, 35.773827811091927, 0.0],
              [-81.488117183438803, 35.774377478037565, 0.0],
              [-81.488033317794958, 35.774652411413818, 0.0],
              [-81.487780984224543, 35.775019077359829, 0.0],
              [-81.486994383394702, 35.775248810821495, 0.0],
              [-81.486798383609383, 35.775684077966901, 0.0],
              [-81.485843316865626, 35.776028478055622, 0.0],
              [-81.485394583925583, 35.776670077434389, 0.0],
              [-81.48494531691648, 35.776991210774156, 0.0],
              [-81.483849583409835, 35.777152477337964, 0.0],
              [-81.482389383510309, 35.77795541132933, 0.0],
              [-81.481884117804867, 35.778482611102895, 0.0],
              [-81.4812659167943, 35.778506010963461, 0.0],
              [-81.481041316797189, 35.778620811290637, 0.0],
              [-81.480901116909124, 35.778918611006169, 0.0],
              [-81.480901583886492, 35.779262077519284, 0.0],
              [-81.480677184474843, 35.779537077652748, 0.0],
              [-81.480593384461883, 35.779972278161608, 0.0],
              [-81.48084651703617, 35.780109477492402, 0.0],
              [-81.4817177171401, 35.780108678116179, 0.0],
              [-81.482335917201155, 35.780016477817298, 0.0],
              [-81.484921316871649, 35.779945478254056, 0.0],
              [-81.486719384227371, 35.779577411121075, 0.0],
              [-81.487927317363301, 35.779095410979004, 0.0],
              [-81.488151583954419, 35.778774411246012, 0.0],
              [-81.488206184425849, 35.777606411079923, 0.0],
              [-81.48834651731471, 35.777491811121315, 0.0],
              [-81.488571383681204, 35.777537410762982, 0.0],
              [-81.488796917673596, 35.778018078019088, 0.0],
              [-81.488825984238744, 35.778705078061996, 0.0],
              [-81.489022984299851, 35.778911011267994, 0.0],
              [-81.489219917611976, 35.778979477660691, 0.0],
              [-81.492058717031313, 35.779137077359358, 0.0],
              [-81.492957917855009, 35.779067478089715, 0.0],
              [-81.493491317258986, 35.778769277678492, 0.0],
              [-81.49413658368394, 35.77799001158651, 0.0],
              [-81.494277184216187, 35.778104411015391, 0.0],
              [-81.494502984379423, 35.778699611228774, 0.0],
              [-81.494671784244858, 35.778859810899952, 0.0],
              [-81.495262184320211, 35.779042277399043, 0.0],
              [-81.497032783887917, 35.77904047771564, 0.0],
              [-81.497509983936723, 35.778650810924205, 0.0],
              [-81.497734784279174, 35.778673477382576, 0.0],
              [-81.498297717765894, 35.779245410947958, 0.0],
              [-81.49866331783214, 35.779336611101819, 0.0],
              [-81.499815516841721, 35.779335411465652, 0.0],
              [-81.501582583395802, 35.779386211304761, 0.0],
              [-81.502369784129783, 35.77920367749816, 0.0],
              [-81.502651184442655, 35.77897501128902, 0.0],
              [-81.502651516829914, 35.778768877435446, 0.0],
              [-81.502258717709509, 35.778379078133774, 0.0],
              [-81.501360184436976, 35.777828610997979, 0.0],
              [-81.501332517759678, 35.777576677777418, 0.0],
              [-81.502147983661303, 35.77727981118263, 0.0],
              [-81.502373116929959, 35.777051011582145, 0.0],
              [-81.502402784428014, 35.77595181101568, 0.0],
              [-81.502712717811164, 35.775471210766973, 0.0],
              [-81.502769784308768, 35.774921477435669, 0.0],
              [-81.503135384150056, 35.774715810666876, 0.0],
              [-81.503811517802475, 35.773548478031302, 0.0],
              [-81.503868583729556, 35.772976011329902, 0.0],
              [-81.503728584121816, 35.77267821123575, 0.0],
              [-81.503138983650388, 35.772288411524457, 0.0],
              [-81.502942717522544, 35.77199041118002, 0.0],
              [-81.50294391709626, 35.771280411452871, 0.0],
              [-81.503140716984149, 35.771166077664276, 0.0],
              [-81.503252517141959, 35.771555611510806, 0.0],
              [-81.503420917576364, 35.771716078067662, 0.0],
              [-81.504600384176911, 35.772129410941304, 0.0],
              [-81.504937317615116, 35.772358677851138, 0.0],
              [-81.505049383909224, 35.772610878028445, 0.0],
              [-81.504738183336357, 35.774053277694875, 0.0],
              [-81.503695583989384, 35.775907277328272, 0.0],
              [-81.503723383567674, 35.776136411099181, 0.0],
              [-81.504396717317519, 35.776869810956192, 0.0],
              [-81.504311984162783, 35.777167477932245, 0.0],
              [-81.503974184128282, 35.77753347773529, 0.0],
              [-81.503944916652458, 35.778312077474297, 0.0],
              [-81.504057116782121, 35.778518411183278, 0.0],
              [-81.504478116759572, 35.778793611187822, 0.0],
              [-81.505657783440611, 35.779298611145222, 0.0],
              [-81.506556716672065, 35.779574277789933, 0.0],
              [-81.507315583324683, 35.779483410703513, 0.0],
              [-81.508692717584893, 35.779507677901869, 0.0],
              [-81.509311183899626, 35.779370811220545, 0.0],
              [-81.509621584326496, 35.778432077445636, 0.0],
              [-81.509818317618297, 35.77843227754169, 0.0],
              [-81.509958583626826, 35.778592810778832, 0.0],
              [-81.509958317128536, 35.778821811211301, 0.0],
              [-81.510098516998724, 35.77898221144811, 0.0],
              [-81.51060438417872, 35.77912007753168, 0.0],
              [-81.51223431749537, 35.77912161065349, 0.0],
              [-81.514931783789194, 35.779444677949385, 0.0],
              [-81.515409584139817, 35.779399277311164, 0.0],
              [-81.515662783519872, 35.779262211588488, 0.0],
              [-81.515382583759617, 35.778597810856212, 0.0],
              [-81.514989783657555, 35.778093610951387, 0.0],
              [-81.514990384474075, 35.777658411369352, 0.0],
              [-81.515159183538856, 35.777521078266403, 0.0],
              [-81.515692717449625, 35.777865210914172, 0.0],
              [-81.516283117153208, 35.77768247789173, 0.0],
              [-81.516535984496798, 35.777705611352104, 0.0],
              [-81.516816717570748, 35.777912010929768, 0.0],
              [-81.517462784311334, 35.77814161100374, 0.0],
              [-81.517462517763832, 35.778279011394631, 0.0],
              [-81.51670358357957, 35.778530210930434, 0.0],
              [-81.516534784123934, 35.778667478059226, 0.0],
              [-81.516534317042883, 35.779011011272253, 0.0],
              [-81.516702716732723, 35.77919441139079, 0.0],
              [-81.517236384438448, 35.779423878163755, 0.0],
              [-81.518303984126263, 35.779653810926781, 0.0],
              [-81.520355517290866, 35.779632611141608, 0.0],
              [-81.520721117294528, 35.779449678089307, 0.0],
              [-81.521340317384286, 35.77880907746313, 0.0],
              [-81.522492384005446, 35.778810077612199, 0.0],
              [-81.524825783788387, 35.778216478046907, 0.0],
              [-81.525163317272941, 35.777987811413695, 0.0],
              [-81.525135717324375, 35.777644210893172, 0.0],
              [-81.525023516755795, 35.77734647826783, 0.0],
              [-81.525023916823159, 35.776980077855043, 0.0],
              [-81.525136584437249, 35.776865611267745, 0.0],
              [-81.525137583798625, 35.77599541088297, 0.0],
              [-81.525053717052913, 35.775674677985378, 0.0],
              [-81.525138117531441, 35.775537277464728, 0.0],
              [-81.525334917331008, 35.775560477486074, 0.0],
              [-81.525811317834766, 35.776614277905516, 0.0],
              [-81.525979717636503, 35.776728878253451, 0.0],
              [-81.526457384017334, 35.776798011557347, 0.0],
              [-81.526344184012373, 35.777507877714591, 0.0],
              [-81.526456184322981, 35.777874277424068, 0.0],
              [-81.526680783746798, 35.778057677638181, 0.0],
              [-81.528338717019608, 35.778242211575332, 0.0],
              [-81.530558916673328, 35.778152411488101, 0.0],
              [-81.530924317553385, 35.778083877321293, 0.0],
              [-81.531233316976511, 35.778175677989253, 0.0],
              [-81.531682983374665, 35.778130277827131, 0.0],
              [-81.532132916982292, 35.777970277562396, 0.0],
              [-81.532582583486843, 35.777970677449282, 0.0],
              [-81.533368983561999, 35.778269010975293, 0.0],
              [-81.533959183413458, 35.778269411361777, 0.0],
              [-81.534605917704042, 35.777972211167125, 0.0],
              [-81.534999517388457, 35.777880878223613, 0.0],
              [-81.535111983944915, 35.777789411201042, 0.0],
              [-81.535506584305438, 35.776667477523581, 0.0],
              [-81.535956917630628, 35.77600367737525, 0.0],
              [-81.536688517486411, 35.775202677463412, 0.0],
              [-81.537307184073697, 35.774745210736967, 0.0],
              [-81.537419917494134, 35.774470411198649, 0.0],
              [-81.537365517727864, 35.772913010852967, 0.0],
              [-81.537140917478197, 35.772638010917639, 0.0],
              [-81.537141183338107, 35.77240901134283, 0.0],
              [-81.537563517747756, 35.7716764781009, 0.0],
              [-81.537900984367013, 35.771379010709587, 0.0],
              [-81.538209983672985, 35.77153961087253, 0.0],
              [-81.538462583758019, 35.771814478259415, 0.0],
              [-81.539024317009748, 35.77208981104269, 0.0],
              [-81.539783117002912, 35.772021611362618, 0.0],
              [-81.540570183776424, 35.771678611438404, 0.0],
              [-81.54073871710662, 35.771793210712111, 0.0],
              [-81.540878984108744, 35.772068211293856, 0.0],
              [-81.541468783865653, 35.772412077625546, 0.0],
              [-81.541861783347116, 35.772755877711845, 0.0],
              [-81.542029583814085, 35.773488810905612, 0.0],
              [-81.542450516855538, 35.77417607744006, 0.0],
              [-81.542478316778684, 35.774428011315116, 0.0],
              [-81.542309583397923, 35.774450877640291, 0.0],
              [-81.54118638430262, 35.773740077433409, 0.0],
              [-81.540175183880052, 35.77321267751541, 0.0],
              [-81.539585317201769, 35.773029077928769, 0.0],
              [-81.53927611670062, 35.773097610722054, 0.0],
              [-81.538910384079514, 35.773463678158194, 0.0],
              [-81.538516784278954, 35.773623677921648, 0.0],
              [-81.538291784230822, 35.773806811623039, 0.0],
              [-81.538290983322852, 35.774608411346101, 0.0],
              [-81.537362717127223, 35.775363478211901, 0.0],
              [-81.53618118367632, 35.776690810950441, 0.0],
              [-81.536152717015739, 35.776988610678856, 0.0],
              [-81.536461584003007, 35.777194811394715, 0.0],
              [-81.537360583835351, 35.777401610649669, 0.0],
              [-81.537698117145354, 35.777218677364573, 0.0],
              [-81.538035317171463, 35.777173077615053, 0.0],
              [-81.538428183755485, 35.777745878016376, 0.0],
              [-81.538849584176035, 35.777883610936726, 0.0],
              [-81.540479584082604, 35.777884811616865, 0.0],
              [-81.541632583478616, 35.777106878124812, 0.0],
              [-81.542756783519721, 35.776924478196861, 0.0],
              [-81.543122716630123, 35.776443677809667, 0.0],
              [-81.543516184110985, 35.776398211093593, 0.0],
              [-81.543965517623988, 35.776673410938898, 0.0],
              [-81.544780183999166, 35.77692581139987, 0.0],
              [-81.54522991743498, 35.776971877846989, 0.0],
              [-81.545510916718612, 35.776880477598986, 0.0],
              [-81.545792583472632, 35.776216477936799, 0.0],
              [-81.546045783378247, 35.775941877635475, 0.0],
              [-81.546833117226058, 35.775507277414853, 0.0],
              [-81.547170584397421, 35.775232677748107, 0.0],
              [-81.547198716726584, 35.775049410701357, 0.0],
              [-81.546861916617672, 35.774705677737018, 0.0],
              [-81.546721583921169, 35.774385010773884, 0.0],
              [-81.546750317393673, 35.773927011057168, 0.0],
              [-81.546862784448763, 35.773812611603574, 0.0],
              [-81.54764958395937, 35.773767210828936, 0.0],
              [-81.547762116679834, 35.77369861081273, 0.0],
              [-81.547931117015196, 35.773332411572305, 0.0],
              [-81.548409117152488, 35.772943278028862, 0.0],
              [-81.549027584122271, 35.772691810747673, 0.0],
              [-81.549758384132872, 35.772531878242702, 0.0],
              [-81.55105111725338, 35.772578477676049, 0.0],
              [-81.551612917177607, 35.772739077384202, 0.0],
              [-81.55206238397632, 35.772762277745322, 0.0],
              [-81.553130583995468, 35.772442278282348, 0.0],
              [-81.553158383908652, 35.772740011315783, 0.0],
              [-81.552455517417741, 35.773266277324282, 0.0],
              [-81.552033783652334, 35.77340347775462, 0.0],
              [-81.551275317247402, 35.773128211526675, 0.0],
              [-81.550010783271063, 35.773127611048842, 0.0],
              [-81.549139183419314, 35.7736078782061, 0.0],
              [-81.54874538430812, 35.774019877663541, 0.0],
              [-81.5487451834274, 35.774180077734577, 0.0],
              [-81.549194517669264, 35.774523877731937, 0.0],
              [-81.54972818373912, 35.774776210746076, 0.0],
              [-81.55113318369456, 35.775097677553347, 0.0],
              [-81.553044117715046, 35.775075877773737, 0.0],
              [-81.553802717624933, 35.775213811194959, 0.0],
              [-81.554420983406246, 35.775122477529706, 0.0],
              [-81.554505316887486, 35.775053811404369, 0.0],
              [-81.554421517772425, 35.774481211360467, 0.0],
              [-81.554534116868368, 35.774298078024664, 0.0],
              [-81.554702583810041, 35.774344010955886, 0.0],
              [-81.555039317303809, 35.775054078129038, 0.0],
              [-81.555348116916875, 35.77544361104232, 0.0],
              [-81.555965917609001, 35.77585621101381, 0.0],
              [-81.55694918349117, 35.776314878130528, 0.0],
              [-81.557398716732536, 35.776429477791396, 0.0],
              [-81.558803716860865, 35.776590610717086, 0.0],
              [-81.559730784387781, 35.777003277883715, 0.0],
              [-81.560489517169884, 35.777003678189438, 0.0],
              [-81.561416983696191, 35.776752277843343, 0.0],
              [-81.561669984035859, 35.776752477783567, 0.0],
              [-81.561641717365035, 35.77700427812092, 0.0],
              [-81.561500984226356, 35.777187477954385, 0.0],
              [-81.561528717330035, 35.777714077523463, 0.0],
              [-81.562118517537385, 35.77819541114156, 0.0],
              [-81.562933317407484, 35.778470610888711, 0.0],
              [-81.563579717487315, 35.778425077381002, 0.0],
              [-81.564788717146357, 35.777624210867209, 0.0],
              [-81.565238317590257, 35.777716077459331, 0.0],
              [-81.565181917337725, 35.777968011473163, 0.0],
              [-81.564675717204622, 35.77844861138054, 0.0],
              [-81.564591383995733, 35.778631811288172, 0.0],
              [-81.564675517516193, 35.77888367796649, 0.0],
              [-81.565152783263457, 35.779410678036015, 0.0],
              [-81.565883116858913, 35.780029410645867, 0.0],
              [-81.566248316620417, 35.78016687802829, 0.0],
              [-81.568299917421228, 35.780145011530877, 0.0],
              [-81.568749583422644, 35.780076478239302, 0.0],
              [-81.569648917254739, 35.780099877340199, 0.0],
              [-81.570267316650614, 35.779939811001938, 0.0],
              [-81.570604783707651, 35.779504877995713, 0.0],
              [-81.570942116612784, 35.779527877627203, 0.0],
              [-81.572318717503379, 35.780100878216807, 0.0],
              [-81.572740316758924, 35.780192677347031, 0.0],
              [-81.573161916915495, 35.780078411439085, 0.0],
              [-81.574398784223064, 35.779506411044132, 0.0],
              [-81.574623717156399, 35.77932321102881, 0.0],
              [-81.574792517034467, 35.778888211578028, 0.0],
              [-81.574961316567112, 35.778773811486218, 0.0],
              [-81.576057183737134, 35.778980411377248, 0.0],
              [-81.576563116766835, 35.778774411497764, 0.0],
              [-81.577041184405232, 35.778385278100451, 0.0],
              [-81.577209983834621, 35.778087610691031, 0.0],
              [-81.577125984080595, 35.777492211011207, 0.0],
              [-81.577407117032536, 35.77737781145143, 0.0],
              [-81.578109583214243, 35.777401011128127, 0.0],
              [-81.579374183641136, 35.777630410831328, 0.0],
              [-81.581060316739524, 35.777585211414113, 0.0],
              [-81.581256984140239, 35.777699810931423, 0.0],
              [-81.58122878397819, 35.77788301077107, 0.0],
              [-81.580469917053392, 35.778111677325839, 0.0],
              [-81.579401983786425, 35.77811141154482, 0.0],
              [-81.578221516573265, 35.778248278006721, 0.0],
              [-81.577940384404329, 35.778431411419014, 0.0],
              [-81.577462117542751, 35.779416011556123, 0.0],
              [-81.577377383946001, 35.780034278177581, 0.0],
              [-81.577152317620133, 35.780469411065233, 0.0],
              [-81.577320783841046, 35.780698477470757, 0.0],
              [-81.578360517304162, 35.780904877310434, 0.0],
              [-81.579540917090114, 35.78092821063602, 0.0],
              [-81.58162058438289, 35.780837278170068, 0.0],
              [-81.582182717299574, 35.780700210943586, 0.0],
              [-81.582463917003068, 35.7805628111646, 0.0],
              [-81.582660783223702, 35.78019647792312, 0.0],
              [-81.582829383717922, 35.78010487778262, 0.0],
              [-81.583082384086595, 35.780242410865959, 0.0],
              [-81.583250783470916, 35.780563077810818, 0.0],
              [-81.583503583794894, 35.780700611326843, 0.0],
              [-81.583981316855926, 35.780769411535225, 0.0],
              [-81.585133717216848, 35.780655410882112, 0.0],
              [-81.586454917284058, 35.780037477680018, 0.0],
              [-81.586707783957408, 35.780037477698478, 0.0],
              [-81.586848183901665, 35.780152011144523, 0.0],
              [-81.58690418394103, 35.780816211021111, 0.0],
              [-81.587634583406981, 35.781480478235473, 0.0],
              [-81.588477583714848, 35.7818242779177, 0.0],
              [-81.589770516530749, 35.781595610892303, 0.0],
              [-81.591204184101159, 35.780863211571528, 0.0],
              [-81.592356517339326, 35.780634478217628, 0.0],
              [-81.592496916523444, 35.78074907737556, 0.0],
              [-81.592468717199566, 35.780978011189205, 0.0],
              [-81.59154098406853, 35.78157321088019, 0.0],
              [-81.59156911677124, 35.781802210829888, 0.0],
              [-81.593114916562513, 35.781550677973726, 0.0],
              [-81.596684117283189, 35.781620277868562, 0.0],
              [-81.596880783375141, 35.781574477610278, 0.0],
              [-81.596993383769856, 35.781139478100606, 0.0],
              [-81.597190116936432, 35.781024877412115, 0.0],
              [-81.597471117184313, 35.781139477550255, 0.0],
              [-81.597499183722647, 35.781299877810461, 0.0],
              [-81.597808183259104, 35.78168921109166, 0.0],
              [-81.599100783894002, 35.782078878029232, 0.0],
              [-81.599578583947945, 35.782078810676687, 0.0],
              [-81.599803516664394, 35.781895677738291, 0.0],
              [-81.600084783473804, 35.781117210960346, 0.0],
              [-81.600141116839737, 35.780933878154556, 0.0],
              [-81.600309717595039, 35.780865277406278, 0.0],
              [-81.600450316852076, 35.780956877895619, 0.0],
              [-81.600815317280066, 35.781758478241144, 0.0],
              [-81.601264917376568, 35.782010410767562, 0.0],
              [-81.602557717159485, 35.782193877439099, 0.0],
              [-81.604356317186955, 35.782308678175163, 0.0],
              [-81.605368116904302, 35.782217277628192, 0.0],
              [-81.605564783245683, 35.782057011449588, 0.0],
              [-81.605536783413342, 35.781530411392495, 0.0],
              [-81.605817983708675, 35.781324211620763, 0.0],
              [-81.606070916797862, 35.781347077658175, 0.0],
              [-81.606408116783626, 35.781530411101357, 0.0],
              [-81.606773517128559, 35.781438810995837, 0.0],
              [-81.607560384051126, 35.781003811453154, 0.0],
              [-81.609331183177432, 35.780156678113428, 0.0],
              [-81.609640316618268, 35.780156811533324, 0.0],
              [-81.60978078384278, 35.780248411211723, 0.0],
              [-81.609752583940789, 35.780614811355505, 0.0],
              [-81.60927471733153, 35.780981211399755, 0.0],
              [-81.608234783865839, 35.781439011400344, 0.0],
              [-81.607475983784553, 35.781599211557314, 0.0],
              [-81.607419783371597, 35.781713677503056, 0.0],
              [-81.60750411765369, 35.781782411424459, 0.0],
              [-81.608656317417612, 35.781805477681054, 0.0],
              [-81.609639983321074, 35.781897210751694, 0.0],
              [-81.613714983806474, 35.78288227795975, 0.0],
              [-81.614867317101158, 35.783042811385457, 0.0],
              [-81.616216317226758, 35.78297421104245, 0.0],
              [-81.61703131650745, 35.783271877840434, 0.0],
              [-81.618183583857345, 35.784096477974096, 0.0],
              [-81.618576984186589, 35.784691811013595, 0.0],
              [-81.618520717407335, 35.784852211366612, 0.0],
              [-81.618239717477891, 35.784966677692942, 0.0],
              [-81.617537116717244, 35.784691811116851, 0.0],
              [-81.61686258382845, 35.784233677799598, 0.0],
              [-81.616637716570764, 35.784279610975986, 0.0],
              [-81.616722116709354, 35.784485611087497, 0.0],
              [-81.617199783935305, 35.784920810762664, 0.0],
              [-81.618351983815103, 35.786432278194361, 0.0],
              [-81.619026583779018, 35.787004877808265, 0.0],
              [-81.619841717263839, 35.787302478228071, 0.0],
              [-81.621050183417054, 35.787577411118185, 0.0],
              [-81.622314983486945, 35.787485810645691, 0.0],
              [-81.623045784191149, 35.787325477505306, 0.0],
              [-81.624254316729946, 35.786798877445193, 0.0],
              [-81.62481638433529, 35.786432277801154, 0.0],
              [-81.625406583762995, 35.785997211596431, 0.0],
              [-81.62540651756791, 35.785562077595515, 0.0],
              [-81.624816316987136, 35.785058277578621, 0.0],
              [-81.62425418337692, 35.784600277418136, 0.0],
              [-81.624226117026708, 35.784417077324498, 0.0],
              [-81.62453518359213, 35.784348411376662, 0.0],
              [-81.624816317195894, 35.784508677914836, 0.0],
              [-81.625856117111795, 35.78517281133297, 0.0],
              [-81.626614983588908, 35.784554477693135, 0.0],
              [-81.627036583628708, 35.784439877336546, 0.0],
              [-81.627092784180078, 35.785195677370616, 0.0],
              [-81.627654983315324, 35.785493411492581, 0.0],
              [-81.628245184185744, 35.785516277665621, 0.0],
              [-81.62922878384822, 35.785126877453067, 0.0],
              [-81.631786383632374, 35.784943477415915, 0.0],
              [-81.632011183844355, 35.78505801145073, 0.0],
              [-81.63201118329043, 35.785195411132342, 0.0],
              [-81.630521783783237, 35.785539011418685, 0.0],
              [-81.629172783276147, 35.785630678094194, 0.0],
              [-81.628666784031367, 35.785859810964325, 0.0],
              [-81.628610583154867, 35.786065878233565, 0.0],
              [-81.631336916524049, 35.786088610777384, 0.0],
              [-81.631927116818076, 35.786019810806287, 0.0],
              [-81.632854516830363, 35.785722011426479, 0.0],
              [-81.635552383570442, 35.784737077445683, 0.0],
              [-81.636142517505476, 35.784301877974237, 0.0],
              [-81.636620183432328, 35.783775077345162, 0.0],
              [-81.637828517102264, 35.782355211273227, 0.0],
              [-81.638446583708017, 35.781370210886912, 0.0],
              [-81.638811917424647, 35.781026678137863, 0.0],
              [-81.638811783894042, 35.780774811255398, 0.0],
              [-81.638390184261809, 35.780729011212976, 0.0],
              [-81.637968716683048, 35.780568811221805, 0.0],
              [-81.637940517647593, 35.780385611344357, 0.0],
              [-81.638249583285599, 35.780271011558327, 0.0],
              [-81.639795317056638, 35.780225077469908, 0.0],
              [-81.640385383496195, 35.779881411150257, 0.0],
              [-81.640750584307654, 35.779354610834019, 0.0],
              [-81.641059717352931, 35.779125611392899, 0.0],
              [-81.641115783167749, 35.778598877529994, 0.0],
              [-81.641368716579493, 35.778392677371315, 0.0],
              [-81.641424716472685, 35.777797410720204, 0.0],
              [-81.641002983761084, 35.776812610749978, 0.0],
              [-81.639962783944171, 35.775209677724128, 0.0],
              [-81.639962717040831, 35.775026477966875, 0.0],
              [-81.640412383905044, 35.774934810765018, 0.0],
              [-81.643110316718676, 35.775896211035096, 0.0],
              [-81.643082384096644, 35.776102278022627, 0.0],
              [-81.642632783254271, 35.776423077979992, 0.0],
              [-81.642492384006559, 35.776674877382163, 0.0],
              [-81.642548783440034, 35.777613810909756, 0.0],
              [-81.642239783765461, 35.778278077351793, 0.0],
              [-81.642239917154484, 35.778507077342589, 0.0],
              [-81.642548984064959, 35.778575811470638, 0.0],
              [-81.643616783275874, 35.778003011384314, 0.0],
              [-81.644965583650148, 35.777636411465352, 0.0],
              [-81.645668117236923, 35.777544611425398, 0.0],
              [-81.646426983837003, 35.777636077337853, 0.0],
              [-81.647279984183612, 35.777942611561507, 0.0],
              [-81.647382517076664, 35.777979477330142, 0.0],
              [-81.647270184143608, 35.778185611565014, 0.0],
              [-81.645556116492372, 35.778666811388746, 0.0],
              [-81.644909783990613, 35.77875847752567, 0.0],
              [-81.643448516844913, 35.779331410882328, 0.0],
              [-81.642717983675766, 35.7799956106387, 0.0],
              [-81.64283038312584, 35.780110077986443, 0.0],
              [-81.644263583717049, 35.779468611278567, 0.0],
              [-81.644713183229555, 35.779422810947722, 0.0],
              [-81.644853716539174, 35.779537211281259, 0.0],
              [-81.644797583156091, 35.779720477841423, 0.0],
              [-81.64462898395908, 35.779857878207238, 0.0],
              [-81.643476917610982, 35.780224411244149, 0.0],
              [-81.640245517145033, 35.782400611572463, 0.0],
              [-81.639149783840978, 35.784301477566999, 0.0],
              [-81.638700116737098, 35.784507678264973, 0.0],
              [-81.637800983392069, 35.785515411063031, 0.0],
              [-81.637098517274708, 35.786088078006479, 0.0],
              [-81.636564516511456, 35.786408811034363, 0.0],
              [-81.636423983515002, 35.78663781150766, 0.0],
              [-81.636424116998555, 35.786843878159068, 0.0],
              [-81.636592717362831, 35.787187278184852, 0.0],
              [-81.637154984291712, 35.787576611442859, 0.0],
              [-81.637183117126227, 35.787782677772292, 0.0],
              [-81.635665317503921, 35.787714211210726, 0.0],
              [-81.635524783263691, 35.787966210905736, 0.0],
              [-81.635215716899665, 35.787851677700715, 0.0],
              [-81.634934516757227, 35.787485210763478, 0.0],
              [-81.634512983804996, 35.787554011572048, 0.0],
              [-81.633754183967838, 35.787920477827868, 0.0],
              [-81.633669916656672, 35.788149477869688, 0.0],
              [-81.633669916798837, 35.788699211383822, 0.0],
              [-81.633445116993371, 35.788996877951114, 0.0],
              [-81.633107783457461, 35.788996877967023, 0.0],
              [-81.632967317036119, 35.788195410972513, 0.0],
              [-81.632854716485284, 35.788126677579619, 0.0],
              [-81.632180183808288, 35.788218277589429, 0.0],
              [-81.63060631690783, 35.788081010948403, 0.0],
              [-81.629200916651001, 35.787394078080872, 0.0],
              [-81.628610716471002, 35.787417010786626, 0.0],
              [-81.628582716705921, 35.787852077553687, 0.0],
              [-81.628470183814613, 35.787989477819373, 0.0],
              [-81.628245383863202, 35.787989477415408, 0.0],
              [-81.627767517177062, 35.787439877736567, 0.0],
              [-81.627542717306468, 35.787371211397463, 0.0],
              [-81.626924317167934, 35.78743987760928, 0.0],
              [-81.624816383579514, 35.788218678002949, 0.0],
              [-81.623888917619297, 35.788447611411982, 0.0],
              [-81.623888917624981, 35.788630878128828, 0.0],
              [-81.623973183840505, 35.788699611296472, 0.0],
              [-81.623888917374998, 35.788974411179517, 0.0],
              [-81.623158117240919, 35.789432477592648, 0.0],
              [-81.623158116883801, 35.789798877614672, 0.0],
              [-81.623973317182319, 35.790600411578211, 0.0],
              [-81.623888983816016, 35.790714877372409, 0.0],
              [-81.621977717099625, 35.791195811106093, 0.0],
              [-81.6218653166992, 35.791058410787578, 0.0],
              [-81.622202583669178, 35.790485877558218, 0.0],
              [-81.622258717107627, 35.790165277530953, 0.0],
              [-81.622033917181369, 35.789844677871812, 0.0],
              [-81.621246784156057, 35.789569878236406, 0.0],
            ],
            [
              [-81.641593917391475, 35.780179010820824, 0.0],
              [-81.642155916771927, 35.779995611115666, 0.0],
              [-81.643026917593673, 35.779262810949461, 0.0],
              [-81.643111116995541, 35.778965011035957, 0.0],
              [-81.642858316459893, 35.779033677401713, 0.0],
              [-81.6411723174553, 35.780041611071347, 0.0],
              [-81.64117231673022, 35.78017901131976, 0.0],
              [-81.641593917391475, 35.780179010820824, 0.0],
            ],
            [
              [-81.607419383671839, 35.783225278129599, 0.0],
              [-81.607447716734754, 35.78276721152595, 0.0],
              [-81.60727911728091, 35.782652678231443, 0.0],
              [-81.6068573831961, 35.782698411187809, 0.0],
              [-81.606014183951146, 35.783156411142372, 0.0],
              [-81.605620717113553, 35.783270811116751, 0.0],
              [-81.605620784220903, 35.783476877761274, 0.0],
              [-81.606014184368235, 35.783706011254189, 0.0],
              [-81.606857383847441, 35.783912211551367, 0.0],
              [-81.607419383825345, 35.783843610912065, 0.0],
              [-81.607728583825889, 35.783660410790603, 0.0],
              [-81.607756783522817, 35.783454277649035, 0.0],
              [-81.607419383671839, 35.783225278129599, 0.0],
            ],
            [
              [-81.571924717080464, 35.780948211160435, 0.0],
              [-81.572009183669095, 35.78074207809226, 0.0],
              [-81.571924917142994, 35.78065047757957, 0.0],
              [-81.570857184286794, 35.780352277327978, 0.0],
              [-81.570182717174731, 35.780374810807814, 0.0],
              [-81.569676583412729, 35.780626478090355, 0.0],
              [-81.569676583537486, 35.780763877669905, 0.0],
              [-81.569901183680443, 35.780924411313421, 0.0],
              [-81.57150311685065, 35.781062477557093, 0.0],
              [-81.571924717080464, 35.780948211160435, 0.0],
            ],
            [
              [-81.574847717496382, 35.78076607742608, 0.0],
              [-81.575522184178325, 35.780766410736426, 0.0],
              [-81.575831383660514, 35.780583277881433, 0.0],
              [-81.575831583582499, 35.780354277911862, 0.0],
              [-81.575466317251909, 35.780193877682407, 0.0],
              [-81.574876116801903, 35.780193611000804, 0.0],
              [-81.574679317260234, 35.780262210753328, 0.0],
              [-81.574650983596911, 35.780605811293526, 0.0],
              [-81.574847717496382, 35.78076607742608, 0.0],
            ],
            [
              [-81.536994517164075, 35.778111410883376, 0.0],
              [-81.537022783355269, 35.777928211173666, 0.0],
              [-81.536545316871596, 35.777698811117176, 0.0],
              [-81.536264317169426, 35.777698611116911, 0.0],
              [-81.536123717027763, 35.777790077931598, 0.0],
              [-81.536179583568966, 35.778087877919347, 0.0],
              [-81.536881984036498, 35.778180011398284, 0.0],
              [-81.536994517164075, 35.778111410883376, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1625,
        ComID: 120052294,
        FDate: "2004/03/16",
        Resolution: 3,
        GNIS_ID: null,
        GNIS_Name: "Lake Norman",
        AreaSqKm: 0.458,
        Elevation: 232.0,
        ReachCode: "03050101002324",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 0.06913682678,
        Shape_Area: 4.553108e-5,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.867150517866349, 35.498375278270039, 0.0],
              [-80.867094517885505, 35.498100477854997, 0.0],
              [-80.865778184953911, 35.498123277931256, 0.0],
              [-80.865414117785377, 35.498260677792487, 0.0],
              [-80.865189984347069, 35.498558411133224, 0.0],
              [-80.864937785106733, 35.499405678498889, 0.0],
              [-80.864685717851955, 35.499497278719765, 0.0],
              [-80.864573718335748, 35.499359877822684, 0.0],
              [-80.864545784670412, 35.499130878646596, 0.0],
              [-80.864405718670824, 35.499016411685218, 0.0],
              [-80.863873585186994, 35.499062078312797, 0.0],
              [-80.863761584731364, 35.49897047788663, 0.0],
              [-80.863817718394671, 35.498626878610018, 0.0],
              [-80.864573984676426, 35.498100278588808, 0.0],
              [-80.864573985230138, 35.497733877794843, 0.0],
              [-80.864321984936737, 35.497390211911878, 0.0],
              [-80.864069918210546, 35.497413078574212, 0.0],
              [-80.863957918041564, 35.497321611526068, 0.0],
              [-80.863985984345632, 35.497092478362809, 0.0],
              [-80.864574118708362, 35.496565878608983, 0.0],
              [-80.864798185290667, 35.496199411848629, 0.0],
              [-80.86463018543148, 35.496062011917182, 0.0],
              [-80.863902118064175, 35.495947411673313, 0.0],
              [-80.862977918737528, 35.495993077958424, 0.0],
              [-80.862109718031817, 35.496222078494029, 0.0],
              [-80.860709518447152, 35.495993011203907, 0.0],
              [-80.860009317902495, 35.496428011861255, 0.0],
              [-80.859729318341479, 35.496382077800291, 0.0],
              [-80.859729318474336, 35.496176011937159, 0.0],
              [-80.860205518770115, 35.495878277848519, 0.0],
              [-80.860597518133389, 35.495397478289654, 0.0],
              [-80.860681517852385, 35.495122611810771, 0.0],
              [-80.860317718175082, 35.494389677792185, 0.0],
              [-80.860261718834153, 35.494114878296131, 0.0],
              [-80.860737785215036, 35.494229411999598, 0.0],
              [-80.860737718172757, 35.494366811738381, 0.0],
              [-80.861437718543158, 35.495053878085386, 0.0],
              [-80.862081785331299, 35.494985278330489, 0.0],
              [-80.862249918621785, 35.494825011211965, 0.0],
              [-80.862921984523553, 35.494916611212581, 0.0],
              [-80.863173985163655, 35.494779278293862, 0.0],
              [-80.863061917663089, 35.494435811938637, 0.0],
              [-80.8632021185323, 35.494298277826736, 0.0],
              [-80.863398118829267, 35.494321278561159, 0.0],
              [-80.863565984428035, 35.494504478533095, 0.0],
              [-80.863901984774415, 35.494435811229422, 0.0],
              [-80.86398618544186, 35.494252611671911, 0.0],
              [-80.863650118658455, 35.493840278394721, 0.0],
              [-80.863650184937697, 35.493473878254093, 0.0],
              [-80.863370184961283, 35.493176078384437, 0.0],
              [-80.863314318637791, 35.492512011766884, 0.0],
              [-80.863118318458547, 35.492237078386552, 0.0],
              [-80.863118384895728, 35.491435478310336, 0.0],
              [-80.863314385020658, 35.491183611283709, 0.0],
              [-80.863314518332757, 35.490725611299872, 0.0],
              [-80.86286651784566, 35.490336211551934, 0.0],
              [-80.862782585528436, 35.489855211907539, 0.0],
              [-80.862418584818613, 35.48946581183796, 0.0],
              [-80.862418518576078, 35.489305477775282, 0.0],
              [-80.862922584729603, 35.488893278478848, 0.0],
              [-80.863286718848059, 35.489374278608921, 0.0],
              [-80.863482585228454, 35.48980947791717, 0.0],
              [-80.86365051862029, 35.490656878284419, 0.0],
              [-80.863818518091094, 35.491046211258059, 0.0],
              [-80.864042385171757, 35.491137878515325, 0.0],
              [-80.864378518411783, 35.491069211522827, 0.0],
              [-80.864882718333348, 35.49040507833989, 0.0],
              [-80.864882718140436, 35.490313478715244, 0.0],
              [-80.865190785213926, 35.490015678640631, 0.0],
              [-80.865526784781409, 35.490130277792773, 0.0],
              [-80.865274718618394, 35.490840278275002, 0.0],
              [-80.864742518194532, 35.491481477860425, 0.0],
              [-80.864686585326467, 35.491893678447248, 0.0],
              [-80.865134585112415, 35.492122811546018, 0.0],
              [-80.865022518636025, 35.492695278448821, 0.0],
              [-80.864882518098639, 35.492970211579184, 0.0],
              [-80.864938384336639, 35.493382411131151, 0.0],
              [-80.865078384535593, 35.49358861127385, 0.0],
              [-80.865610384340698, 35.493978011353917, 0.0],
              [-80.866198585037949, 35.494092478458285, 0.0],
              [-80.866310585175214, 35.494023811727288, 0.0],
              [-80.866422718359587, 35.492901478711389, 0.0],
              [-80.866562718689195, 35.492787012005458, 0.0],
              [-80.866842718163241, 35.492832877917962, 0.0],
              [-80.867038718511495, 35.493199211666493, 0.0],
              [-80.867374784957633, 35.493199277939254, 0.0],
              [-80.868438985108824, 35.492237411320389, 0.0],
              [-80.868915118192319, 35.491573277851742, 0.0],
              [-80.869139184593777, 35.490794611922723, 0.0],
              [-80.869139184899169, 35.490336611459689, 0.0],
              [-80.869615318526598, 35.490038878491397, 0.0],
              [-80.869615185373789, 35.489672478699433, 0.0],
              [-80.870231318645764, 35.489443477938778, 0.0],
              [-80.870987318489099, 35.488504478660005, 0.0],
              [-80.871351318497176, 35.488160877850262, 0.0],
              [-80.871771385508225, 35.488023478428033, 0.0],
              [-80.871911385084019, 35.488092211694962, 0.0],
              [-80.871855385023323, 35.488275411658087, 0.0],
              [-80.871211318493877, 35.48880221121474, 0.0],
              [-80.870483184636939, 35.489832811305398, 0.0],
              [-80.87045531863528, 35.490016012010869, 0.0],
              [-80.870231184300124, 35.490313811153158, 0.0],
              [-80.870203317728937, 35.491229811422414, 0.0],
              [-80.870091184922757, 35.491619211640682, 0.0],
              [-80.870119184877538, 35.491825277883414, 0.0],
              [-80.870483184428551, 35.491985611276831, 0.0],
              [-80.871155185006785, 35.491825277970534, 0.0],
              [-80.871407317930704, 35.491871211706503, 0.0],
              [-80.871407318736971, 35.492100211450818, 0.0],
              [-80.871183317885965, 35.492283412068055, 0.0],
              [-80.870539184895478, 35.492581078095235, 0.0],
              [-80.86986711800597, 35.492649811179092, 0.0],
              [-80.86958698539371, 35.492833011142324, 0.0],
              [-80.869166984342627, 35.493405478220083, 0.0],
              [-80.869082985184917, 35.494298678434184, 0.0],
              [-80.869334985350079, 35.494802611721511, 0.0],
              [-80.869334985284681, 35.495237678507429, 0.0],
              [-80.869250917982328, 35.495352211481105, 0.0],
              [-80.869250917960599, 35.495741611122526, 0.0],
              [-80.869418918154011, 35.495924811967207, 0.0],
              [-80.86720638434879, 35.500139677935429, 0.0],
              [-80.866730117764675, 35.501137411694259, 0.0],
              [-80.866478118195687, 35.501206078390332, 0.0],
              [-80.866282117819068, 35.501114411206594, 0.0],
              [-80.866141984319697, 35.500564811276675, 0.0],
              [-80.865973985253163, 35.500404478032202, 0.0],
              [-80.865861985084834, 35.500140277935046, 0.0],
              [-80.865862118258647, 35.499566211774805, 0.0],
              [-80.866142118285808, 35.499016478388462, 0.0],
              [-80.866870318539128, 35.498695877974129, 0.0],
              [-80.867150517866349, 35.498375278270039, 0.0],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 1626,
        ComID: 120052295,
        FDate: "2004/03/16",
        Resolution: 3,
        GNIS_ID: "00991183",
        GNIS_Name: "Lake Norman",
        AreaSqKm: 127.075,
        Elevation: 232.0,
        ReachCode: "03050101002517",
        FType: 390,
        FCode: 39009,
        Shape_Leng: 7.9367631455899996,
        Shape_Area: 0.01263077468,
        Reservoir: 32,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.091957918440059, 35.756311810944737, 0.0],
              [-81.091817317434646, 35.756495010849029, 0.0],
              [-81.090549384234535, 35.757196411312144, 0.0],
              [-81.090327918495547, 35.757319011095902, 0.0],
              [-81.088894985180957, 35.75738727809221, 0.0],
              [-81.088867118417383, 35.756631477932089, 0.0],
              [-81.088530184484682, 35.756173410759949, 0.0],
              [-81.087350718090477, 35.75502801142347, 0.0],
              [-81.08673298504047, 35.754180410901611, 0.0],
              [-81.08575011746052, 35.753309677824078, 0.0],
              [-81.084935585105285, 35.752828611429969, 0.0],
              [-81.083643384614803, 35.752461677686021, 0.0],
              [-81.082548184086619, 35.751568077624349, 0.0],
              [-81.082267317570128, 35.751499278307087, 0.0],
              [-81.082070517972383, 35.751682477874027, 0.0],
              [-81.082042184715434, 35.75209467743003, 0.0],
              [-81.081676984176553, 35.752209077958419, 0.0],
              [-81.081367984531823, 35.751980010763525, 0.0],
              [-81.081340118128139, 35.751705077672206, 0.0],
              [-81.081508717334245, 35.751453211549787, 0.0],
              [-81.081452718000477, 35.751109810786041, 0.0],
              [-81.080527385110187, 35.750123611079623, 0.0],
              [-81.080106317826306, 35.749573678248154, 0.0],
              [-81.079825717633412, 35.748909478178199, 0.0],
              [-81.07951691757286, 35.748657478288713, 0.0],
              [-81.079348318060312, 35.748634477933749, 0.0],
              [-81.078954784428078, 35.7489092113385, 0.0],
              [-81.078645717869719, 35.748954810965955, 0.0],
              [-81.078505384397815, 35.748840277534349, 0.0],
              [-81.078449384661823, 35.748496811224975, 0.0],
              [-81.078562185134217, 35.747786811460742, 0.0],
              [-81.078337585201552, 35.747328678289023, 0.0],
              [-81.077832318314464, 35.746687211290187, 0.0],
              [-81.077523385000802, 35.746458011002666, 0.0],
              [-81.076933718220275, 35.746297611375539, 0.0],
              [-81.075501717933506, 35.745060277722359, 0.0],
              [-81.075192717544866, 35.744899878318051, 0.0],
              [-81.074883784465584, 35.744876810834796, 0.0],
              [-81.074658917912387, 35.744968411438627, 0.0],
              [-81.073731384036208, 35.745769477551043, 0.0],
              [-81.073366117716404, 35.745815211104428, 0.0],
              [-81.073281918046348, 35.745746410706516, 0.0],
              [-81.073338184003916, 35.745609010771709, 0.0],
              [-81.074097117357326, 35.744922277936027, 0.0],
              [-81.074153384636489, 35.744670411138571, 0.0],
              [-81.072946518300967, 35.743158411499174, 0.0],
              [-81.070503784392741, 35.741279410879478, 0.0],
              [-81.068678718412983, 35.740133411127616, 0.0],
              [-81.068566584343529, 35.739767011226363, 0.0],
              [-81.067892518294144, 35.739675011424978, 0.0],
              [-81.067302384994321, 35.739926610948345, 0.0],
              [-81.066655984705903, 35.740407411667874, 0.0],
              [-81.066458917523732, 35.740979810783301, 0.0],
              [-81.067413384932138, 35.741850477617341, 0.0],
              [-81.067413184668311, 35.742056611388854, 0.0],
              [-81.066963784809815, 35.742056411547267, 0.0],
              [-81.06634611831565, 35.74166681141039, 0.0],
              [-81.066065185168767, 35.741620877416672, 0.0],
              [-81.065250317689376, 35.741895277684755, 0.0],
              [-81.06516611802931, 35.741826610898613, 0.0],
              [-81.065334917816543, 35.741460211344936, 0.0],
              [-81.065756584973656, 35.74107101095295, 0.0],
              [-81.066403718188496, 35.739674277480333, 0.0],
              [-81.06651651820755, 35.739056010811701, 0.0],
              [-81.066545317944417, 35.738025410865184, 0.0],
              [-81.066433317615832, 35.73743001160252, 0.0],
              [-81.06612491738214, 35.736582477605573, 0.0],
              [-81.06587238504973, 35.736193011177022, 0.0],
              [-81.065563584787753, 35.735963678145012, 0.0],
              [-81.065198517717604, 35.735940810902385, 0.0],
              [-81.064804984551358, 35.736123677878076, 0.0],
              [-81.06421511769129, 35.73621507785608, 0.0],
              [-81.06283891777133, 35.73607701087348, 0.0],
              [-81.062838984888202, 35.735848010751063, 0.0],
              [-81.063035785117236, 35.735756477930657, 0.0],
              [-81.064721118020813, 35.73571141128064, 0.0],
              [-81.065142584311459, 35.735482611054294, 0.0],
              [-81.065367584880022, 35.735162078351124, 0.0],
              [-81.065452585165019, 35.734085811397378, 0.0],
              [-81.065312584516519, 35.733513211445924, 0.0],
              [-81.064751984287597, 35.731978478291751, 0.0],
              [-81.063770184542264, 35.730260277384779, 0.0],
              [-81.063377918110533, 35.729206611370614, 0.0],
              [-81.063153184647277, 35.729000410873027, 0.0],
              [-81.062788184643324, 35.728954410748671, 0.0],
              [-81.062310517824798, 35.729137411569056, 0.0],
              [-81.062085784273023, 35.729137210867478, 0.0],
              [-81.061973717507783, 35.728954011500868, 0.0],
              [-81.061973984973079, 35.728610477689358, 0.0],
              [-81.062086384256574, 35.728427277601597, 0.0],
              [-81.062311118409667, 35.728381611066197, 0.0],
              [-81.062423384111838, 35.728564877825384, 0.0],
              [-81.062591917525239, 35.728565010919695, 0.0],
              [-81.062872984896117, 35.728221611671422, 0.0],
              [-81.062818384818598, 35.726206077622194, 0.0],
              [-81.062397184943464, 35.726022678321669, 0.0],
              [-81.061273918120406, 35.725930477598851, 0.0],
              [-81.060402985169816, 35.726159077888795, 0.0],
              [-81.060150517951882, 35.725884077802775, 0.0],
              [-81.060206917412316, 35.725632278356414, 0.0],
              [-81.060571984179262, 35.725518011076154, 0.0],
              [-81.061892184534685, 35.725404078340439, 0.0],
              [-81.062734518151615, 35.725770878070541, 0.0],
              [-81.063127584470649, 35.725862811382434, 0.0],
              [-81.064083718069, 35.724099811638048, 0.0],
              [-81.064084184484145, 35.723504278051685, 0.0],
              [-81.063915784194947, 35.723275211117688, 0.0],
              [-81.063916117951052, 35.72284007800873, 0.0],
              [-81.064675584117751, 35.721374811677727, 0.0],
              [-81.064732118449697, 35.720802211239295, 0.0],
              [-81.064423718144653, 35.720229478242196, 0.0],
              [-81.064396317572388, 35.719175878330951, 0.0],
              [-81.064284118224407, 35.719015611658079, 0.0],
              [-81.064088384874935, 35.717962010743442, 0.0],
              [-81.062742784348941, 35.714892411071325, 0.0],
              [-81.062069384487103, 35.714113477755397, 0.0],
              [-81.061704384663599, 35.713930011278528, 0.0],
              [-81.059149318113185, 35.713676678171403, 0.0],
              [-81.058672117409316, 35.713470411041413, 0.0],
              [-81.058672185142228, 35.713287210767149, 0.0],
              [-81.058840784351489, 35.713241478056638, 0.0],
              [-81.060469384840118, 35.713333877406583, 0.0],
              [-81.06080638463628, 35.713242478184789, 0.0],
              [-81.060806717931115, 35.71296767764936, 0.0],
              [-81.060666318259806, 35.712853210821393, 0.0],
              [-81.05889738483387, 35.712577277800847, 0.0],
              [-81.056567384666621, 35.711820277564193, 0.0],
              [-81.055612718565754, 35.71179687837278, 0.0],
              [-81.054910784623885, 35.71161327769385, 0.0],
              [-81.054658184415814, 35.711452811400378, 0.0],
              [-81.054012517926878, 35.711269210754516, 0.0],
              [-81.052299918270293, 35.711016277564454, 0.0],
              [-81.051092984139672, 35.710397211314593, 0.0],
              [-81.050980517894914, 35.710557411519666, 0.0],
              [-81.050643384102287, 35.71062601106248, 0.0],
              [-81.050334784628319, 35.710442478256859, 0.0],
              [-81.049520584807851, 35.710281677736297, 0.0],
              [-81.049099384265958, 35.710281477772007, 0.0],
              [-81.044326784889137, 35.709293610826471, 0.0],
              [-81.044130318322459, 35.709224811556858, 0.0],
              [-81.042249117504184, 35.709154811526801, 0.0],
              [-81.040985384334022, 35.709245478066087, 0.0],
              [-81.036660318481054, 35.709998278342916, 0.0],
              [-81.035283984220584, 35.710340878401105, 0.0],
              [-81.033963718303994, 35.710866477774843, 0.0],
              [-81.031351517514267, 35.711460210983326, 0.0],
              [-81.0281773180822, 35.712373677781223, 0.0],
              [-81.026801117591532, 35.712693211081152, 0.0],
              [-81.024300984643347, 35.713401211173, 0.0],
              [-81.02278431840999, 35.713651810846763, 0.0],
              [-81.021014984627442, 35.71381061170073, 0.0],
              [-81.016578184242562, 35.713783811675448, 0.0],
              [-81.015398984931295, 35.713553811423004, 0.0],
              [-81.011441318012999, 35.712382077805664, 0.0],
              [-81.010290317713995, 35.712152011419306, 0.0],
              [-81.010037517859175, 35.712197610817853, 0.0],
              [-81.009952718240953, 35.712564010852091, 0.0],
              [-81.010036918384188, 35.712655678209295, 0.0],
              [-81.011215784696319, 35.712977478240738, 0.0],
              [-81.011720918406709, 35.713229878252406, 0.0],
              [-81.012085517451268, 35.713573678368753, 0.0],
              [-81.012197518500301, 35.713779877556952, 0.0],
              [-81.012336518400673, 35.714764877444559, 0.0],
              [-81.012334118065667, 35.716505411362185, 0.0],
              [-81.01311931789418, 35.717284877866476, 0.0],
              [-81.013624317548803, 35.717651811346521, 0.0],
              [-81.014410184759868, 35.717950210840065, 0.0],
              [-81.014803385057277, 35.717927611650381, 0.0],
              [-81.015393585087054, 35.717561677478905, 0.0],
              [-81.015702517468341, 35.717607810766452, 0.0],
              [-81.015954985162907, 35.717791211365629, 0.0],
              [-81.016318784509508, 35.718707611372508, 0.0],
              [-81.016570984474882, 35.719120078268475, 0.0],
              [-81.016821385011568, 35.720883677906841, 0.0],
              [-81.017045918330325, 35.720998478350147, 0.0],
              [-81.017747984605023, 35.720999077701066, 0.0],
              [-81.018028717855245, 35.721068011212836, 0.0],
              [-81.018028517826508, 35.721182477474748, 0.0],
              [-81.017466384556229, 35.721571410993072, 0.0],
              [-81.016567318540012, 35.721868277534341, 0.0],
              [-81.016061718356909, 35.721936677806745, 0.0],
              [-81.015893385188477, 35.721776211632616, 0.0],
              [-81.015922117528334, 35.72131807762581, 0.0],
              [-81.016231584459916, 35.720929077495036, 0.0],
              [-81.016260118590338, 35.720585477541285, 0.0],
              [-81.016092118467981, 35.720219011701744, 0.0],
              [-81.016093117692037, 35.719509011720248, 0.0],
              [-81.015054985045367, 35.718798077603886, 0.0],
              [-81.013539117595698, 35.718384478196228, 0.0],
              [-81.01325858464287, 35.7181094114583, 0.0],
              [-81.012529118422165, 35.717604877782165, 0.0],
              [-81.01168731834295, 35.717123210911552, 0.0],
              [-81.011181717875473, 35.71719147797387, 0.0],
              [-81.011097584986857, 35.717122677651709, 0.0],
              [-81.011097917674959, 35.716939477991623, 0.0],
              [-81.011350985244405, 35.716664811206947, 0.0],
              [-81.011351384812087, 35.716321211502148, 0.0],
              [-81.010903318399542, 35.715496277401883, 0.0],
              [-81.010426584463119, 35.715106611302566, 0.0],
              [-81.009500117652436, 35.714945411116609, 0.0],
              [-81.008995317513197, 35.71446387811978, 0.0],
              [-81.008770718463182, 35.714440877857761, 0.0],
              [-81.008573784978154, 35.714623877809814, 0.0],
              [-81.008349118037856, 35.714646478358731, 0.0],
              [-81.008208918627389, 35.714509011584589, 0.0],
              [-81.008518517553554, 35.7140512782427, 0.0],
              [-81.008546917437556, 35.713776477488516, 0.0],
              [-81.008406785144444, 35.713547411209198, 0.0],
              [-81.008547784314032, 35.713203877866064, 0.0],
              [-81.009362717447161, 35.712769610832623, 0.0],
              [-81.009391518341914, 35.712311611698155, 0.0],
              [-81.009180984949353, 35.712127878386653, 0.0],
              [-81.008970785135105, 35.711944677938575, 0.0],
              [-81.005770318330121, 35.711483610774756, 0.0],
              [-81.004085584101063, 35.711413211213788, 0.0],
              [-81.002794184239249, 35.711205811655006, 0.0],
              [-81.001755917751595, 35.710769611251337, 0.0],
              [-81.000942585259679, 35.710173410759985, 0.0],
              [-81.000774585019698, 35.709806811627452, 0.0],
              [-81.000635384545163, 35.708982011289528, 0.0],
              [-81.000272184870823, 35.707859478071533, 0.0],
              [-80.999799517867899, 35.707515478323522, 0.0],
              [-80.999156917994583, 35.706920678389054, 0.0],
              [-80.998734918380862, 35.706440210930218, 0.0],
              [-80.997751385232817, 35.705937411475276, 0.0],
              [-80.997217317808975, 35.705502811189724, 0.0],
              [-80.996458584761228, 35.705160011259487, 0.0],
              [-80.995980385030379, 35.704633811015178, 0.0],
              [-80.994463317616521, 35.703994011238137, 0.0],
              [-80.994154518485502, 35.704040211665905, 0.0],
              [-80.994098517824284, 35.704131877431486, 0.0],
              [-80.994323785262239, 35.7045896776389, 0.0],
              [-80.994324584850489, 35.705070677761192, 0.0],
              [-80.993960384316182, 35.705666478081966, 0.0],
              [-80.993709318329749, 35.706766010882077, 0.0],
              [-80.993709785097181, 35.707109610910919, 0.0],
              [-80.993934584214401, 35.707269611303794, 0.0],
              [-80.994636784771032, 35.707406277788508, 0.0],
              [-80.994580984186712, 35.70756667749793, 0.0],
              [-80.993626518126916, 35.707750877940938, 0.0],
              [-80.993514384826085, 35.707911278377196, 0.0],
              [-80.993599985254903, 35.708781478057702, 0.0],
              [-80.993573118147054, 35.709606011236211, 0.0],
              [-80.993292517581125, 35.709697811251544, 0.0],
              [-80.992956384683254, 35.710316610789341, 0.0],
              [-80.992619584498428, 35.710477278170508, 0.0],
              [-80.992702384442197, 35.709446477983043, 0.0],
              [-80.992476918160207, 35.708874210924129, 0.0],
              [-80.992195718347403, 35.708622611183849, 0.0],
              [-80.992195117670036, 35.708141611478787, 0.0],
              [-80.992363184410607, 35.707912411030875, 0.0],
              [-80.992193184689583, 35.706882011374738, 0.0],
              [-80.992528984764618, 35.706125877561284, 0.0],
              [-80.992556184181538, 35.705438810761578, 0.0],
              [-80.992696384950946, 35.705324077822553, 0.0],
              [-80.99294458510893, 35.704673411446016, 0.0],
              [-80.992975984152167, 35.704591011538298, 0.0],
              [-80.992953718136491, 35.70442047802895, 0.0],
              [-80.992862384718933, 35.703720811201002, 0.0],
              [-80.992693317597656, 35.703331677739314, 0.0],
              [-80.99238418515634, 35.703103011340801, 0.0],
              [-80.991738317691272, 35.703012011311742, 0.0],
              [-80.991261118511602, 35.703104077612778, 0.0],
              [-80.991093184948355, 35.703562211538383, 0.0],
              [-80.990728385233638, 35.703700077451686, 0.0],
              [-80.990560184288967, 35.703860477906602, 0.0],
              [-80.990112384559211, 35.704891610788934, 0.0],
              [-80.989887918169956, 35.704983410781715, 0.0],
              [-80.989811517681574, 35.704890211023091, 0.0],
              [-80.989719117666922, 35.704777410934902, 0.0],
              [-80.98969091759389, 35.704617210787951, 0.0],
              [-80.989297517844236, 35.70438847786248, 0.0],
              [-80.989269117734608, 35.704205278394525, 0.0],
              [-80.989661585032636, 35.703769810804388, 0.0],
              [-80.990502984469998, 35.703127677870498, 0.0],
              [-80.990558518572087, 35.702692477669956, 0.0],
              [-80.990838517593517, 35.70214261147931, 0.0],
              [-80.990922184385525, 35.701776077611903, 0.0],
              [-80.990725184728689, 35.701387011630359, 0.0],
              [-80.99072351847299, 35.700241811731651, 0.0],
              [-80.9904699846075, 35.699715278035065, 0.0],
              [-80.990104717928446, 35.699509477827164, 0.0],
              [-80.989458984932696, 35.699464411735498, 0.0],
              [-80.989402384973431, 35.69921247805199, 0.0],
              [-80.990018918374687, 35.698364478245537, 0.0],
              [-80.990410717713672, 35.697493877832805, 0.0],
              [-80.99052191822679, 35.696715011237131, 0.0],
              [-80.990436717937214, 35.696051011347734, 0.0],
              [-80.990070584416259, 35.695272611341686, 0.0],
              [-80.989424185037379, 35.694792278024877, 0.0],
              [-80.989030984586435, 35.694632411610776, 0.0],
              [-80.988104385275776, 35.694587477984392, 0.0],
              [-80.987654784881641, 35.694290277575064, 0.0],
              [-80.987486385297146, 35.69429041170288, 0.0],
              [-80.987233918360047, 35.694428010886966, 0.0],
              [-80.986559385199513, 35.693924811708591, 0.0],
              [-80.985997917736896, 35.693925410991433, 0.0],
              [-80.985716118244397, 35.693192810803581, 0.0],
              [-80.984957517851072, 35.692735277910153, 0.0],
              [-80.984592718518286, 35.692827411492161, 0.0],
              [-80.984396518212989, 35.693125211455033, 0.0],
              [-80.984059984281515, 35.693308811662831, 0.0],
              [-80.983611917759859, 35.694133677641261, 0.0],
              [-80.983247118608773, 35.694202678192802, 0.0],
              [-80.983274518239583, 35.69372181085015, 0.0],
              [-80.982796984370822, 35.693584810775235, 0.0],
              [-80.982291917611946, 35.693768411249991, 0.0],
              [-80.981590184674985, 35.693860677607468, 0.0],
              [-80.981505918272688, 35.693814877639063, 0.0],
              [-80.98150571791696, 35.693654611117253, 0.0],
              [-80.981617784524929, 35.693540011179728, 0.0],
              [-80.982852518262874, 35.69308081102583, 0.0],
              [-80.983160985032583, 35.692874411580746, 0.0],
              [-80.983413118622238, 35.692439011733299, 0.0],
              [-80.984002318454245, 35.692163677984837, 0.0],
              [-80.98459111788101, 35.691682277968823, 0.0],
              [-80.984590718506794, 35.691315811413567, 0.0],
              [-80.984337318115493, 35.690835078131919, 0.0],
              [-80.983690985038336, 35.690286010975505, 0.0],
              [-80.983437384409527, 35.689736610804594, 0.0],
              [-80.982931784295232, 35.68950801139524, 0.0],
              [-80.982286184770572, 35.689554411356163, 0.0],
              [-80.98225778499544, 35.689348278250435, 0.0],
              [-80.982341918353953, 35.689256611112683, 0.0],
              [-80.982369518007076, 35.688890077994088, 0.0],
              [-80.982256984843644, 35.68866121094814, 0.0],
              [-80.981807384896683, 35.688363811682031, 0.0],
              [-80.980880918548237, 35.688341877879473, 0.0],
              [-80.980992384332865, 35.687677611275646, 0.0],
              [-80.980514384996212, 35.68715121138699, 0.0],
              [-80.980484517521049, 35.685708478296107, 0.0],
              [-80.980316118178294, 35.685685678159274, 0.0],
              [-80.979979384414307, 35.685869210908727, 0.0],
              [-80.979671117639342, 35.686190077842269, 0.0],
              [-80.979137985139104, 35.686396811744629, 0.0],
              [-80.978577184879597, 35.686878078210299, 0.0],
              [-80.978352584492697, 35.68687841138977, 0.0],
              [-80.978211585066234, 35.686397610823207, 0.0],
              [-80.978351584677981, 35.686145478373668, 0.0],
              [-80.978940518629429, 35.685618210805785, 0.0],
              [-80.980006384484241, 35.685090477678031, 0.0],
              [-80.980202717744135, 35.684907211295211, 0.0],
              [-80.98028638442473, 35.684517677792201, 0.0],
              [-80.980482518376746, 35.684219877769031, 0.0],
              [-80.980510117981652, 35.683876211058617, 0.0],
              [-80.980172584360318, 35.683349677810924, 0.0],
              [-80.979807318228978, 35.683098077937935, 0.0],
              [-80.979414384441213, 35.6830528108555, 0.0],
              [-80.978853384525323, 35.683396811172877, 0.0],
              [-80.978039384607072, 35.683443278222192, 0.0],
              [-80.977338118103205, 35.683764478237329, 0.0],
              [-80.97705778523617, 35.684154077913334, 0.0],
              [-80.976805317937732, 35.684268877516921, 0.0],
              [-80.97666491819426, 35.684223210812362, 0.0],
              [-80.976608517830641, 35.683994077772752, 0.0],
              [-80.976860718184426, 35.68360461165382, 0.0],
              [-80.976860318645322, 35.683352678120002, 0.0],
              [-80.976607317625778, 35.683078078170894, 0.0],
              [-80.976045718452113, 35.682826611005147, 0.0],
              [-80.975399918333821, 35.682804277719313, 0.0],
              [-80.974838585159048, 35.682873478080339, 0.0],
              [-80.973856117729596, 35.682805611295009, 0.0],
              [-80.973070385175816, 35.683035211368548, 0.0],
              [-80.972846117503096, 35.683218678400451, 0.0],
              [-80.972932118465664, 35.684707277626451, 0.0],
              [-80.97267951820082, 35.68477621161702, 0.0],
              [-80.972567184175261, 35.684684677971617, 0.0],
              [-80.972510717990929, 35.684409877647276, 0.0],
              [-80.972426385320219, 35.684364211701634, 0.0],
              [-80.972426184254417, 35.684226811403711, 0.0],
              [-80.972229517999438, 35.68397501111788, 0.0],
              [-80.971611584755294, 35.683815211768689, 0.0],
              [-80.971611518060939, 35.683632011393506, 0.0],
              [-80.972003985183008, 35.683311010854851, 0.0],
              [-80.972563984546156, 35.682050878339098, 0.0],
              [-80.972563384525017, 35.681592811066857, 0.0],
              [-80.97222578426225, 35.681043477909974, 0.0],
              [-80.972000317857265, 35.680265010850157, 0.0],
              [-80.971747584446888, 35.680059077766323, 0.0],
              [-80.971298184819787, 35.679853411547406, 0.0],
              [-80.970483384634875, 35.679212678038546, 0.0],
              [-80.969921718294756, 35.678961210789076, 0.0],
              [-80.969641117664764, 35.679007277881183, 0.0],
              [-80.969614384696996, 35.680129477934813, 0.0],
              [-80.970036318669742, 35.680976477919835, 0.0],
              [-80.970036718340126, 35.681205477814977, 0.0],
              [-80.969783984634176, 35.681205677745261, 0.0],
              [-80.969446718536247, 35.680816677729531, 0.0],
              [-80.969081718287512, 35.68070241097184, 0.0],
              [-80.969053118358218, 35.680244411225466, 0.0],
              [-80.968800117596274, 35.679946877662836, 0.0],
              [-80.967369118026468, 35.680383277788245, 0.0],
              [-80.965994918689361, 35.681437811512076, 0.0],
              [-80.965489784941667, 35.681690077917644, 0.0],
              [-80.9645639184467, 35.681919877968596, 0.0],
              [-80.964031118124339, 35.682469878235061, 0.0],
              [-80.964031584706362, 35.682950811188967, 0.0],
              [-80.964649985236363, 35.683637411042831, 0.0],
              [-80.96470631807162, 35.683797677809991, 0.0],
              [-80.964819118661993, 35.684278610997076, 0.0],
              [-80.964594717494109, 35.684439077823853, 0.0],
              [-80.963864985184955, 35.684577077761126, 0.0],
              [-80.963877118333343, 35.684497411605477, 0.0],
              [-80.963892785351717, 35.684393811690988, 0.0],
              [-80.964145117824955, 35.684050077902221, 0.0],
              [-80.963948318434987, 35.683729611491636, 0.0],
              [-80.963639185185997, 35.683432211064591, 0.0],
              [-80.96299331831176, 35.683249411082969, 0.0],
              [-80.962993185258085, 35.683112010871994, 0.0],
              [-80.963413784803748, 35.682699411202677, 0.0],
              [-80.963329585259203, 35.682630811333553, 0.0],
              [-80.963329318411937, 35.68235587764763, 0.0],
              [-80.963665784782933, 35.682080811622725, 0.0],
              [-80.963693584937118, 35.681897611563286, 0.0],
              [-80.963581318301138, 35.681783077997999, 0.0],
              [-80.963580985005748, 35.681577010805157, 0.0],
              [-80.964815584884789, 35.68116387759509, 0.0],
              [-80.965404785115879, 35.680888477751715, 0.0],
              [-80.965656984668442, 35.680544811404395, 0.0],
              [-80.965656317729838, 35.679903611260308, 0.0],
              [-80.966694717979763, 35.679811211086886, 0.0],
              [-80.966947184577407, 35.679650677833116, 0.0],
              [-80.967199384682644, 35.679307010945521, 0.0],
              [-80.967227117907143, 35.678986210886663, 0.0],
              [-80.967900717557683, 35.678940011750605, 0.0],
              [-80.968293384957519, 35.678664811379754, 0.0],
              [-80.968545384717061, 35.678183611747812, 0.0],
              [-80.968600585049728, 35.677336278305731, 0.0],
              [-80.968179118039586, 35.6769472114865, 0.0],
              [-80.968066318317184, 35.676443477909892, 0.0],
              [-80.967785385002827, 35.67632921096952, 0.0],
              [-80.967279584185832, 35.675871611437628, 0.0],
              [-80.966437717946064, 35.67596381162226, 0.0],
              [-80.966072518647579, 35.675689278045461, 0.0],
              [-80.966072317978927, 35.675574811064521, 0.0],
              [-80.966577118545715, 35.675162211214285, 0.0],
              [-80.966576918537953, 35.674978877530151, 0.0],
              [-80.966323984331353, 35.674772878379827, 0.0],
              [-80.966211184596432, 35.674246277554616, 0.0],
              [-80.965761584912599, 35.673811478335104, 0.0],
              [-80.965340384519408, 35.673651478151172, 0.0],
              [-80.965003184285877, 35.67335401136004, 0.0],
              [-80.964442118511627, 35.673514811741775, 0.0],
              [-80.964357917949556, 35.673446211567587, 0.0],
              [-80.964329117726948, 35.672827677790373, 0.0],
              [-80.964048384838321, 35.672759278035535, 0.0],
              [-80.963711584181212, 35.672874077767865, 0.0],
              [-80.962757984908677, 35.673493077558639, 0.0],
              [-80.962450184506011, 35.674249078251115, 0.0],
              [-80.962197585332063, 35.674249278363256, 0.0],
              [-80.962141318322622, 35.674112011679085, 0.0],
              [-80.961804384682338, 35.674020477611464, 0.0],
              [-80.96085071861549, 35.674593810974521, 0.0],
              [-80.960261384614256, 35.674777477690562, 0.0],
              [-80.960233185302002, 35.674594277686445, 0.0],
              [-80.960485184522071, 35.674044477779553, 0.0],
              [-80.961467118573864, 35.673539811519781, 0.0],
              [-80.961803385221515, 35.673127411102158, 0.0],
              [-80.962532785002068, 35.67285201122904, 0.0],
              [-80.962813184302263, 35.672554077560626, 0.0],
              [-80.963794985073505, 35.672049477784086, 0.0],
              [-80.963991185333199, 35.671866078253402, 0.0],
              [-80.963962117942728, 35.670904277590068, 0.0],
              [-80.963681118449074, 35.67056101146391, 0.0],
              [-80.96320371772984, 35.670378077934757, 0.0],
              [-80.963175384548634, 35.670126211074802, 0.0],
              [-80.963343184189668, 35.669668011103319, 0.0],
              [-80.963258984954635, 35.669599411135778, 0.0],
              [-80.963089917716587, 35.669027011419779, 0.0],
              [-80.962865384816382, 35.669004211483909, 0.0],
              [-80.962640984570896, 35.669096011579001, 0.0],
              [-80.961519384247595, 35.670058677493586, 0.0],
              [-80.961182584480468, 35.670104677737235, 0.0],
              [-80.96118238536657, 35.669852811746615, 0.0],
              [-80.961715118520388, 35.669279811465351, 0.0],
              [-80.9619111181172, 35.668844611193265, 0.0],
              [-80.962415918336475, 35.668569411653785, 0.0],
              [-80.962443784631446, 35.668409077489407, 0.0],
              [-80.962050717537281, 35.668226078385587, 0.0],
              [-80.962022518598246, 35.668065878061647, 0.0],
              [-80.962695784721376, 35.667882077704107, 0.0],
              [-80.962695384534513, 35.667538477556228, 0.0],
              [-80.962358384791912, 35.667309811741028, 0.0],
              [-80.962217718305297, 35.66696627807611, 0.0],
              [-80.961768518460673, 35.666806411708542, 0.0],
              [-80.961739917552237, 35.666279611425125, 0.0],
              [-80.961627385144538, 35.666096477865871, 0.0],
              [-80.961065918058168, 35.665845010875124, 0.0],
              [-80.960616184467256, 35.665227011623116, 0.0],
              [-80.959998717848038, 35.665158677544255, 0.0],
              [-80.959970584376038, 35.665067077971763, 0.0],
              [-80.959493318144297, 35.664952878419378, 0.0],
              [-80.958679785225684, 35.665297078260494, 0.0],
              [-80.958231184639047, 35.665755411354191, 0.0],
              [-80.957727518326024, 35.667084077620139, 0.0],
              [-80.95747498506239, 35.667175878011378, 0.0],
              [-80.957613917518088, 35.66571001126411, 0.0],
              [-80.95775378453753, 35.665389411772367, 0.0],
              [-80.958987317569125, 35.664105811690284, 0.0],
              [-80.958958917972453, 35.663739478189406, 0.0],
              [-80.958677718227037, 35.66323581090748, 0.0],
              [-80.958620517649365, 35.662205278107095, 0.0],
              [-80.958479984804896, 35.661999211003057, 0.0],
              [-80.958395318178475, 35.661518410870144, 0.0],
              [-80.958170384465859, 35.66119787811926, 0.0],
              [-80.957665184476255, 35.661106678063383, 0.0],
              [-80.95752491829262, 35.661015078062718, 0.0],
              [-80.957188185121524, 35.661084078383915, 0.0],
              [-80.956683517537371, 35.661565411559359, 0.0],
              [-80.95595491809398, 35.66257347818928, 0.0],
              [-80.955057384983974, 35.663192477904303, 0.0],
              [-80.9547771842076, 35.663582078192476, 0.0],
              [-80.954328584816196, 35.663948811743879, 0.0],
              [-80.953375785240212, 35.665392277495791, 0.0],
              [-80.952898917879523, 35.665621611504463, 0.0],
              [-80.952870518151769, 35.66534687806454, 0.0],
              [-80.953430518578784, 35.663949478164909, 0.0],
              [-80.95359871848504, 35.663766010923574, 0.0],
              [-80.953738584605546, 35.663308011202417, 0.0],
              [-80.954047184611539, 35.663170278344019, 0.0],
              [-80.955589384855529, 35.661886678380604, 0.0],
              [-80.956093385314858, 35.66081001152282, 0.0],
              [-80.956092917690228, 35.660329078158483, 0.0],
              [-80.95592431786929, 35.660054277976734, 0.0],
              [-80.955559185365757, 35.659734010837383, 0.0],
              [-80.954351984831774, 35.659230877671661, 0.0],
              [-80.953902718494533, 35.658933478204538, 0.0],
              [-80.953565784304388, 35.658750411791893, 0.0],
              [-80.952920384615354, 35.658773811075683, 0.0],
              [-80.95261198422169, 35.659048811094067, 0.0],
              [-80.952219584174884, 35.659621677694531, 0.0],
              [-80.951153317551459, 35.659645277582293, 0.0],
              [-80.949470384866672, 35.660539477736386, 0.0],
              [-80.949301984256422, 35.660448077619456, 0.0],
              [-80.949357984814384, 35.660310610911445, 0.0],
              [-80.950703984375011, 35.659347811319293, 0.0],
              [-80.950563518357967, 35.659141811190977, 0.0],
              [-80.950591384960703, 35.658958611188865, 0.0],
              [-80.951404917716587, 35.658706011105849, 0.0],
              [-80.95157318421424, 35.658568478445538, 0.0],
              [-80.951684918634257, 35.657995878226167, 0.0],
              [-80.95190931864434, 35.657812477941754, 0.0],
              [-80.95249818524249, 35.657514477841879, 0.0],
              [-80.952526117599234, 35.65723961094929, 0.0],
              [-80.952132518428598, 35.656736078387283, 0.0],
              [-80.952132384213641, 35.656552811244595, 0.0],
              [-80.952412784905377, 35.656369477926056, 0.0],
              [-80.952552784934994, 35.655957010988651, 0.0],
              [-80.952412117824196, 35.655636478365146, 0.0],
              [-80.952103184715867, 35.655316011139917, 0.0],
              [-80.951401317967068, 35.655018811790121, 0.0],
              [-80.951569384224911, 35.654720878208359, 0.0],
              [-80.951569318183928, 35.654537677966204, 0.0],
              [-80.951344584328979, 35.654331811236844, 0.0],
              [-80.95142831781726, 35.653782078308261, 0.0],
              [-80.951175518154528, 35.653484478294253, 0.0],
              [-80.950557984708979, 35.653370411094365, 0.0],
              [-80.950529784648197, 35.653187211141336, 0.0],
              [-80.951061984417521, 35.65224787770331, 0.0],
              [-80.951454785110656, 35.652201877758543, 0.0],
              [-80.952549385265996, 35.652498811234189, 0.0],
              [-80.952745718210579, 35.652361211634179, 0.0],
              [-80.952857717585687, 35.652155011710171, 0.0],
              [-80.952801118017959, 35.651559611185441, 0.0],
              [-80.952379784224789, 35.651216411412278, 0.0],
              [-80.952239318423992, 35.65091881163621, 0.0],
              [-80.952126917902731, 35.65082721107126, 0.0],
              [-80.951818518434621, 35.651079277512146, 0.0],
              [-80.951509917884295, 35.651079478230344, 0.0],
              [-80.951285184581621, 35.650965078380899, 0.0],
              [-80.951032384793649, 35.650575877725593, 0.0],
              [-80.950751918227141, 35.650667811521629, 0.0],
              [-80.950527584393512, 35.650896878152508, 0.0],
              [-80.949910784604924, 35.651378277505138, 0.0],
              [-80.94960271784646, 35.65199687765049, 0.0],
              [-80.949153984268605, 35.652272011766293, 0.0],
              [-80.948789584321716, 35.652753078137202, 0.0],
              [-80.948340917807897, 35.652982477991081, 0.0],
              [-80.948088984515081, 35.65366967766635, 0.0],
              [-80.948061185074351, 35.654036211638662, 0.0],
              [-80.947977185045445, 35.65412781141972, 0.0],
              [-80.947836918155062, 35.654127877509111, 0.0],
              [-80.947443917764062, 35.653922078011767, 0.0],
              [-80.947022984461, 35.654036677537093, 0.0],
              [-80.946770717557953, 35.654197211510898, 0.0],
              [-80.945788518475439, 35.65415201129079, 0.0],
              [-80.945816384508475, 35.653923011250178, 0.0],
              [-80.946040584780178, 35.653739678085955, 0.0],
              [-80.946545584915853, 35.65360187832183, 0.0],
              [-80.946741784660418, 35.653395677547579, 0.0],
              [-80.946769717650582, 35.653143678410018, 0.0],
              [-80.947050118116934, 35.652914611490267, 0.0],
              [-80.947611118546874, 35.652639411204554, 0.0],
              [-80.947834918566741, 35.651975011422437, 0.0],
              [-80.948227517920714, 35.651631278195332, 0.0],
              [-80.948227118123185, 35.651196211363072, 0.0],
              [-80.947861918459054, 35.650829877707899, 0.0],
              [-80.947889784219782, 35.650577878147054, 0.0],
              [-80.94878758479328, 35.650531611288642, 0.0],
              [-80.948955784260534, 35.65039407786432, 0.0],
              [-80.948899517969863, 35.650165211397962, 0.0],
              [-80.948730985295484, 35.649982011176625, 0.0],
              [-80.948169584608266, 35.64975327835031, 0.0],
              [-80.948056917863681, 35.649203611094443, 0.0],
              [-80.947130584548802, 35.648723411295649, 0.0],
              [-80.946204718232053, 35.648792678030695, 0.0],
              [-80.946260518297748, 35.648517811171864, 0.0],
              [-80.946456785049051, 35.648311611271239, 0.0],
              [-80.946933585054637, 35.648105011723999, 0.0],
              [-80.947606984425008, 35.648081811045955, 0.0],
              [-80.948308517883504, 35.648241678081817, 0.0],
              [-80.949038318297141, 35.648538878214538, 0.0],
              [-80.949992318363286, 35.648538277980037, 0.0],
              [-80.950525185127461, 35.648309011632513, 0.0],
              [-80.951029717746522, 35.647736011501785, 0.0],
              [-80.951253785235167, 35.647300811556214, 0.0],
              [-80.951197517698759, 35.647094611155353, 0.0],
              [-80.950720184481369, 35.646820211171836, 0.0],
              [-80.950663918660368, 35.646614011482001, 0.0],
              [-80.950495384577209, 35.646453811366783, 0.0],
              [-80.949653518643984, 35.64624827763231, 0.0],
              [-80.94895138457872, 35.645538678066124, 0.0],
              [-80.948755118683451, 35.645584611352902, 0.0],
              [-80.948587385377436, 35.646340478082671, 0.0],
              [-80.947858584690451, 35.647119811585512, 0.0],
              [-80.947662118401695, 35.647028211608536, 0.0],
              [-80.947324784399513, 35.646295610892736, 0.0],
              [-80.94724051776528, 35.646204011195941, 0.0],
              [-80.946903918540329, 35.646295878168885, 0.0],
              [-80.946483384961198, 35.646662477629121, 0.0],
              [-80.946090984586363, 35.647189478301463, 0.0],
              [-80.945361718338049, 35.647464810927765, 0.0],
              [-80.944548785286543, 35.648335611424997, 0.0],
              [-80.9442683849622, 35.648518877858358, 0.0],
              [-80.944128118607338, 35.648519010858941, 0.0],
              [-80.944043584758347, 35.648083878053853, 0.0],
              [-80.943790917658916, 35.64790081136853, 0.0],
              [-80.943846518422731, 35.647328277783942, 0.0],
              [-80.944154985189201, 35.647167810866385, 0.0],
              [-80.944547718713679, 35.647121811210255, 0.0],
              [-80.944912317873772, 35.646892477635994, 0.0],
              [-80.945108384800335, 35.646503011456986, 0.0],
              [-80.945388718601208, 35.646205077806997, 0.0],
              [-80.945977718702707, 35.64588407782243, 0.0],
              [-80.94704291845261, 35.644852811551061, 0.0],
              [-80.946986518311078, 35.644646811677731, 0.0],
              [-80.94578011813897, 35.644647478396941, 0.0],
              [-80.945779784644344, 35.644258210984972, 0.0],
              [-80.94563938426748, 35.64412087843565, 0.0],
              [-80.945639118218068, 35.643777277848145, 0.0],
              [-80.945751185239942, 35.643616878215916, 0.0],
              [-80.94569478459816, 35.643342077829104, 0.0],
              [-80.94524571870484, 35.643044678273071, 0.0],
              [-80.944516385066834, 35.643297078103018, 0.0],
              [-80.944375984417732, 35.643205611381788, 0.0],
              [-80.94437578537493, 35.642884878226077, 0.0],
              [-80.944543784684527, 35.642472477641064, 0.0],
              [-80.944627985306099, 35.64244961138472, 0.0],
              [-80.94462771814193, 35.642220611809527, 0.0],
              [-80.944403118612712, 35.642037411252126, 0.0],
              [-80.943954118637677, 35.642014810964817, 0.0],
              [-80.943953917642716, 35.641694077757457, 0.0],
              [-80.944149917810108, 35.641281811074087, 0.0],
              [-80.943953518536432, 35.641098677803043, 0.0],
              [-80.943924784648289, 35.640480411300864, 0.0],
              [-80.943812384939108, 35.640228477792071, 0.0],
              [-80.943447517678166, 35.640022611719111, 0.0],
              [-80.942858384969057, 35.640114611547794, 0.0],
              [-80.942269584809068, 35.640481277983639, 0.0],
              [-80.942297985384258, 35.641008011814151, 0.0],
              [-80.942494718039853, 35.641282811787967, 0.0],
              [-80.94249518536644, 35.641878277549729, 0.0],
              [-80.942747918183386, 35.642153011294013, 0.0],
              [-80.942832385248664, 35.642450610941594, 0.0],
              [-80.942833318514346, 35.643641477931858, 0.0],
              [-80.942581184839042, 35.644122611795879, 0.0],
              [-80.942244784274834, 35.644420477552018, 0.0],
              [-80.941964185212996, 35.644397811333064, 0.0],
              [-80.941963184936284, 35.643298478068736, 0.0],
              [-80.941766718411614, 35.643069611372688, 0.0],
              [-80.941766317914585, 35.642611478180633, 0.0],
              [-80.941345117957809, 35.642222410879036, 0.0],
              [-80.941288718044163, 35.64183321180159, 0.0],
              [-80.940979918268468, 35.641650078426117, 0.0],
              [-80.940475117571623, 35.641810678493925, 0.0],
              [-80.939802185243082, 35.642360677719857, 0.0],
              [-80.939802517553119, 35.64279587825034, 0.0],
              [-80.939886784956229, 35.643002010931021, 0.0],
              [-80.9398875845676, 35.644009677764323, 0.0],
              [-80.939607317605933, 35.644284611757762, 0.0],
              [-80.939523518309585, 35.644788477684187, 0.0],
              [-80.93924298535795, 35.644765811033245, 0.0],
              [-80.938456585317184, 35.643850078389747, 0.0],
              [-80.938232185173632, 35.643919011705989, 0.0],
              [-80.938064318601519, 35.64451447775356, 0.0],
              [-80.937700117925189, 35.64504147808924, 0.0],
              [-80.937672318604569, 35.645453677748293, 0.0],
              [-80.937363985337669, 35.645820410890174, 0.0],
              [-80.937364184889887, 35.646141011121173, 0.0],
              [-80.937672984481338, 35.646301077926495, 0.0],
              [-80.938542785175329, 35.646392211375208, 0.0],
              [-80.938739385099893, 35.646529611023446, 0.0],
              [-80.938795717591262, 35.64689601131554, 0.0],
              [-80.938992318147626, 35.647079078400054, 0.0],
              [-80.938936518419283, 35.647445610842048, 0.0],
              [-80.938150784865371, 35.647377277530751, 0.0],
              [-80.937645784472338, 35.647446277888854, 0.0],
              [-80.937505784694807, 35.647744077615229, 0.0],
              [-80.937056784491432, 35.647652811558999, 0.0],
              [-80.936523718522338, 35.647721811245205, 0.0],
              [-80.93632758438396, 35.64811121151876, 0.0],
              [-80.93557018497134, 35.648409278149629, 0.0],
              [-80.935429985400688, 35.648569611305554, 0.0],
              [-80.935402317583893, 35.64898187778762, 0.0],
              [-80.935795384802461, 35.649302277718341, 0.0],
              [-80.935852117732736, 35.650126811641968, 0.0],
              [-80.936637985109854, 35.650447011642406, 0.0],
              [-80.937002984312045, 35.650836211338557, 0.0],
              [-80.937676384688061, 35.650744211138395, 0.0],
              [-80.937928785257867, 35.650629611277694, 0.0],
              [-80.938209185053978, 35.650285811230219, 0.0],
              [-80.939022718475869, 35.650033478044776, 0.0],
              [-80.939247317704044, 35.65021661132208, 0.0],
              [-80.939612118235644, 35.650216411619276, 0.0],
              [-80.940060918467225, 35.650101678050305, 0.0],
              [-80.940005318546483, 35.65065141157946, 0.0],
              [-80.940791185274747, 35.650994478156917, 0.0],
              [-80.940875518384942, 35.651177611100096, 0.0],
              [-80.940398518375574, 35.651269478166412, 0.0],
              [-80.938995584944323, 35.651270211204974, 0.0],
              [-80.93860298538354, 35.651499478202417, 0.0],
              [-80.938631317988765, 35.651797278111658, 0.0],
              [-80.938827784244339, 35.652026078459237, 0.0],
              [-80.938800118343551, 35.652369811304574, 0.0],
              [-80.938631918292515, 35.652598877958553, 0.0],
              [-80.938688518318543, 35.653148411227335, 0.0],
              [-80.938015784634601, 35.654133611009044, 0.0],
              [-80.938072184860474, 35.654545811719217, 0.0],
              [-80.938464984924209, 35.654499811634174, 0.0],
              [-80.939783384999572, 35.653949411430737, 0.0],
              [-80.940091984485719, 35.653972211691617, 0.0],
              [-80.941158717789662, 35.65436101155224, 0.0],
              [-80.941158984623584, 35.65463581115803, 0.0],
              [-80.940345518557763, 35.655117077518959, 0.0],
              [-80.940345918451428, 35.655598078347829, 0.0],
              [-80.940542717556838, 35.656010211532028, 0.0],
              [-80.940991784971871, 35.656239011132392, 0.0],
              [-80.941132318061847, 35.656445011080251, 0.0],
              [-80.941637384436618, 35.656353078475206, 0.0],
              [-80.942254317661281, 35.655963411760929, 0.0],
              [-80.942450784274101, 35.655963278442449, 0.0],
              [-80.942450984640573, 35.656169477755199, 0.0],
              [-80.942282918627171, 35.656490211180767, 0.0],
              [-80.942227118647082, 35.656879477509349, 0.0],
              [-80.941357585034098, 35.65742967836411, 0.0],
              [-80.941385917635046, 35.657635811315842, 0.0],
              [-80.94155431769812, 35.65770441106713, 0.0],
              [-80.941358118453607, 35.657979411280003, 0.0],
              [-80.94135831771078, 35.658185478231765, 0.0],
              [-80.941695185373177, 35.658460211511063, 0.0],
              [-80.941723517646238, 35.658689077565555, 0.0],
              [-80.942116584225886, 35.658963811317186, 0.0],
              [-80.94307091825813, 35.659192277505944, 0.0],
              [-80.942987117887398, 35.659650277727685, 0.0],
              [-80.943689118574781, 35.660062211535667, 0.0],
              [-80.943689184781078, 35.660176677753562, 0.0],
              [-80.943436718570837, 35.660245611387587, 0.0],
              [-80.942931384555166, 35.660222877863454, 0.0],
              [-80.942341984287509, 35.660040010936775, 0.0],
              [-80.94203311808306, 35.659742477994932, 0.0],
              [-80.941640117799849, 35.65962807817516, 0.0],
              [-80.941471584440322, 35.659467877838374, 0.0],
              [-80.940994384537618, 35.659262077593041, 0.0],
              [-80.940377118475723, 35.659331211046513, 0.0],
              [-80.940096584713018, 35.659468677620715, 0.0],
              [-80.940012517860453, 35.659697877543472, 0.0],
              [-80.93945151862485, 35.659927078306566, 0.0],
              [-80.939451518597764, 35.660018678103647, 0.0],
              [-80.93891851840462, 35.660225077506119, 0.0],
              [-80.938497985127015, 35.660706278064033, 0.0],
              [-80.938582584292035, 35.661255877968621, 0.0],
              [-80.938583584372338, 35.66244687757461, 0.0],
              [-80.937938318511542, 35.66263047835708, 0.0],
              [-80.937966717693456, 35.662951011569874, 0.0],
              [-80.93838791783007, 35.663340211064984, 0.0],
              [-80.939314317971366, 35.663683210840361, 0.0],
              [-80.939426717660936, 35.663935078032317, 0.0],
              [-80.937714784836587, 35.663981810895223, 0.0],
              [-80.937490184821456, 35.663798677937741, 0.0],
              [-80.937433917650935, 35.663638411478217, 0.0],
              [-80.937209384366056, 35.663546877595138, 0.0],
              [-80.936788518290143, 35.66361587823463, 0.0],
              [-80.936564318184097, 35.66395961081983, 0.0],
              [-80.936395784887821, 35.664005411089285, 0.0],
              [-80.935806317852595, 35.663799611675188, 0.0],
              [-80.935244984772737, 35.663845811328763, 0.0],
              [-80.934627785108418, 35.664075077953129, 0.0],
              [-80.934628184234214, 35.664578878249941, 0.0],
              [-80.935274185124243, 35.66528847750061, 0.0],
              [-80.93634091798198, 35.665448277954383, 0.0],
              [-80.936789985299839, 35.665722878082853, 0.0],
              [-80.936874584645196, 35.666112211555529, 0.0],
              [-80.936734718448463, 35.666662011484483, 0.0],
              [-80.936285784525936, 35.666937078417568, 0.0],
              [-80.93606158479021, 35.667234811463047, 0.0],
              [-80.935752918184861, 35.667326611273459, 0.0],
              [-80.934349718407915, 35.667327277706661, 0.0],
              [-80.934265518413753, 35.667419011473029, 0.0],
              [-80.93429391867393, 35.66780827804179, 0.0],
              [-80.934462385209727, 35.667945610879841, 0.0],
              [-80.935472584438031, 35.667853477500842, 0.0],
              [-80.936005918021564, 35.667899011666606, 0.0],
              [-80.936454984893004, 35.668059078113323, 0.0],
              [-80.936988585208852, 35.668402411749341, 0.0],
              [-80.936960585023684, 35.668516877512019, 0.0],
              [-80.93586618504365, 35.668700678063132, 0.0],
              [-80.935641784818344, 35.668815278425569, 0.0],
              [-80.935305185218638, 35.669182011062574, 0.0],
              [-80.934912917617467, 35.669869278101707, 0.0],
              [-80.934520184564661, 35.670281677761153, 0.0],
              [-80.934548584670921, 35.670693878370756, 0.0],
              [-80.935222518541849, 35.671128677911362, 0.0],
              [-80.935334918245417, 35.671311878333377, 0.0],
              [-80.935391317712956, 35.671769810818184, 0.0],
              [-80.935756517564045, 35.672090277967826, 0.0],
              [-80.936093385132423, 35.672227478166214, 0.0],
              [-80.936991518582502, 35.672272877980937, 0.0],
              [-80.937103918118879, 35.672364478363995, 0.0],
              [-80.937076318724053, 35.672959877947306, 0.0],
              [-80.936908117968798, 35.673326411215754, 0.0],
              [-80.936852517792602, 35.67403641126166, 0.0],
              [-80.937021184245012, 35.674288411252476, 0.0],
              [-80.937386117645019, 35.674288077730047, 0.0],
              [-80.938648584971915, 35.673737811556343, 0.0],
              [-80.938845117546975, 35.673760611179667, 0.0],
              [-80.938761118279089, 35.673989611559755, 0.0],
              [-80.938172185421337, 35.674562478352456, 0.0],
              [-80.938340784691917, 35.6749518115722, 0.0],
              [-80.939520385179662, 35.675890078152577, 0.0],
              [-80.939717184762458, 35.676210677682171, 0.0],
              [-80.93974538493903, 35.676462477524339, 0.0],
              [-80.938706584361029, 35.676005078019159, 0.0],
              [-80.938088717979625, 35.675616010983916, 0.0],
              [-80.936516784379364, 35.67536487838801, 0.0],
              [-80.93623591812829, 35.675181878236877, 0.0],
              [-80.936151584302266, 35.675021677505981, 0.0],
              [-80.936151318437879, 35.674678077658001, 0.0],
              [-80.936235584494085, 35.674632277651526, 0.0],
              [-80.936235184692052, 35.674151277895362, 0.0],
              [-80.935224318402149, 35.673510611309965, 0.0],
              [-80.93438218458077, 35.673465211569464, 0.0],
              [-80.934157517745362, 35.673236210912123, 0.0],
              [-80.933848117999517, 35.67234327758802, 0.0],
              [-80.932893185171466, 35.671496411239218, 0.0],
              [-80.932808917937592, 35.671336077870258, 0.0],
              [-80.932864784842494, 35.671061210973498, 0.0],
              [-80.933033185242891, 35.670877877891321, 0.0],
              [-80.933145184349343, 35.670534410839842, 0.0],
              [-80.933369584690638, 35.670396677628659, 0.0],
              [-80.933846718144778, 35.670327811304624, 0.0],
              [-80.933846385382722, 35.669892677887901, 0.0],
              [-80.934575585265421, 35.669159410880063, 0.0],
              [-80.934659517861746, 35.668884611537941, 0.0],
              [-80.934434784318981, 35.668701478179287, 0.0],
              [-80.933564718184499, 35.66851867749596, 0.0],
              [-80.933480118645207, 35.667992011083378, 0.0],
              [-80.933367784990239, 35.66790041128256, 0.0],
              [-80.93317138451917, 35.667946411034528, 0.0],
              [-80.93308758500919, 35.668450277919696, 0.0],
              [-80.932947517907323, 35.668725078202584, 0.0],
              [-80.932582718738701, 35.668931411540548, 0.0],
              [-80.932190718376546, 35.670191277938791, 0.0],
              [-80.931433984555483, 35.671726077613343, 0.0],
              [-80.930872784986661, 35.671886678120948, 0.0],
              [-80.930760584530503, 35.672115811590466, 0.0],
              [-80.930760917603379, 35.672367677770623, 0.0],
              [-80.931097784786743, 35.672504877866999, 0.0],
              [-80.931238184620412, 35.672665211151909, 0.0],
              [-80.931098117909812, 35.67303167809542, 0.0],
              [-80.930031984523168, 35.673719278128715, 0.0],
              [-80.929555185410706, 35.674177610962722, 0.0],
              [-80.929499718651854, 35.675162410842212, 0.0],
              [-80.9290507843602, 35.675345811580023, 0.0],
              [-80.928994917837201, 35.675803878439311, 0.0],
              [-80.928208985230029, 35.675735478417813, 0.0],
              [-80.927844318312239, 35.675919010970794, 0.0],
              [-80.927338918647123, 35.67582761163851, 0.0],
              [-80.926665117887026, 35.675507211643719, 0.0],
              [-80.926047585301504, 35.675530411402001, 0.0],
              [-80.925150185291102, 35.676744677807186, 0.0],
              [-80.924364784847072, 35.677592411720561, 0.0],
              [-80.924224717935061, 35.678050411404776, 0.0],
              [-80.923467518324614, 35.679012677959058, 0.0],
              [-80.921109785212209, 35.679334211296037, 0.0],
              [-80.920857118288424, 35.679196877655741, 0.0],
              [-80.920901117594212, 35.679116077743117, 0.0],
              [-80.92096931818763, 35.678990811026011, 0.0],
              [-80.921586718497068, 35.678807278256016, 0.0],
              [-80.922428717628733, 35.678738277562942, 0.0],
              [-80.922989984874746, 35.678600678188772, 0.0],
              [-80.923466918002774, 35.678165211119143, 0.0],
              [-80.923887384966221, 35.67718027807193, 0.0],
              [-80.923999185223167, 35.67642447817736, 0.0],
              [-80.924251385343979, 35.675668477895712, 0.0],
              [-80.925008718709933, 35.674912411324165, 0.0],
              [-80.925232918625994, 35.67431701094646, 0.0],
              [-80.925007784783602, 35.673446677787219, 0.0],
              [-80.925232318054199, 35.673377878350166, 0.0],
              [-80.92649611852589, 35.674499477465723, 0.0],
              [-80.926720718355696, 35.674591078411176, 0.0],
              [-80.927197784680061, 35.674613678150358, 0.0],
              [-80.928039584235108, 35.674338610810032, 0.0],
              [-80.928460384649256, 35.674040678199525, 0.0],
              [-80.928796784886089, 35.673376411570885, 0.0],
              [-80.928796517945486, 35.672803677745762, 0.0],
              [-80.928908718243292, 35.67273501170628, 0.0],
              [-80.92952598436041, 35.672620077682566, 0.0],
              [-80.93017131795331, 35.672276411249378, 0.0],
              [-80.930283318069456, 35.671795411062881, 0.0],
              [-80.930170918002162, 35.671520611790378, 0.0],
              [-80.93056351856626, 35.671131011291308, 0.0],
              [-80.930647184487569, 35.670466811602573, 0.0],
              [-80.931292317935103, 35.669802277591728, 0.0],
              [-80.931488517684002, 35.669412878199225, 0.0],
              [-80.931600318668515, 35.668771610976229, 0.0],
              [-80.932105318439611, 35.668450677744787, 0.0],
              [-80.932609918409895, 35.667694677874437, 0.0],
              [-80.932749918665863, 35.667236610922622, 0.0],
              [-80.932889918267662, 35.666755478096903, 0.0],
              [-80.933002117723959, 35.666663811415383, 0.0],
              [-80.933871984909914, 35.666617611378705, 0.0],
              [-80.935022517939629, 35.66636507825875, 0.0],
              [-80.935106584584972, 35.666273411151536, 0.0],
              [-80.934292184314131, 35.665563877509328, 0.0],
              [-80.93417971868972, 35.665197477908166, 0.0],
              [-80.933618384421038, 35.665174878461741, 0.0],
              [-80.93347798479769, 35.665037611377414, 0.0],
              [-80.933196718324467, 35.664281877619402, 0.0],
              [-80.932129984917651, 35.663893077638043, 0.0],
              [-80.931540584650762, 35.663870478470272, 0.0],
              [-80.931316185341103, 35.663893477491818, 0.0],
              [-80.931259918416373, 35.66380187843081, 0.0],
              [-80.93137178528228, 35.663275078094074, 0.0],
              [-80.931090984570403, 35.66309201095855, 0.0],
              [-80.929940384546668, 35.662909277718065, 0.0],
              [-80.929350918443902, 35.662703477916104, 0.0],
              [-80.929350785109932, 35.662543211415588, 0.0],
              [-80.929996184773785, 35.662497078365817, 0.0],
              [-80.930220584874888, 35.662268011290614, 0.0],
              [-80.930753784406321, 35.662450877656156, 0.0],
              [-80.931988718463572, 35.662496077809934, 0.0],
              [-80.93240991773088, 35.662931011529665, 0.0],
              [-80.933027385030158, 35.662976477588991, 0.0],
              [-80.933392318578527, 35.663228278121501, 0.0],
              [-80.933841384377359, 35.663182277535107, 0.0],
              [-80.934234118355249, 35.662884277595239, 0.0],
              [-80.935244185347884, 35.662792278308515, 0.0],
              [-80.936085585348124, 35.662081811215621, 0.0],
              [-80.936029318583806, 35.661944411443145, 0.0],
              [-80.935187385220772, 35.661784477769672, 0.0],
              [-80.934625918379126, 35.661395477882991, 0.0],
              [-80.934345118597918, 35.661258211059781, 0.0],
              [-80.934344717609278, 35.660800077958683, 0.0],
              [-80.934120118518365, 35.660594211604518, 0.0],
              [-80.934175918687387, 35.660136077631144, 0.0],
              [-80.93274458450432, 35.659999278149193, 0.0],
              [-80.932688517678898, 35.659907811658201, 0.0],
              [-80.932828585125819, 35.659724478202492, 0.0],
              [-80.933810584544204, 35.659517878085644, 0.0],
              [-80.934006917595099, 35.659311678085402, 0.0],
              [-80.932967984993454, 35.658419011411965, 0.0],
              [-80.932911717709118, 35.65823581130838, 0.0],
              [-80.933332584424619, 35.658235611187081, 0.0],
              [-80.934988785304441, 35.658944811305481, 0.0],
              [-80.935353585293527, 35.658898678119158, 0.0],
              [-80.935858518652836, 35.65862367808446, 0.0],
              [-80.936868718163424, 35.658577277594041, 0.0],
              [-80.937177318524817, 35.658416810896021, 0.0],
              [-80.93807451838272, 35.657500210962233, 0.0],
              [-80.938046185417832, 35.657248278437137, 0.0],
              [-80.937821584606596, 35.65711101107442, 0.0],
              [-80.937260317606274, 35.65695087845554, 0.0],
              [-80.936895384271821, 35.656699277780341, 0.0],
              [-80.936081518462885, 35.656745611286944, 0.0],
              [-80.935015518308987, 35.657158278325646, 0.0],
              [-80.934566585095652, 35.657135611522975, 0.0],
              [-80.934594384975142, 35.656837877501253, 0.0],
              [-80.934762717956247, 35.656723278161138, 0.0],
              [-80.934762518733436, 35.656448477977186, 0.0],
              [-80.934565918562015, 35.656334011439817, 0.0],
              [-80.934201184492395, 35.656288411385255, 0.0],
              [-80.934200785213946, 35.65580747829803, 0.0],
              [-80.933723518115357, 35.655464210844549, 0.0],
              [-80.932965917851547, 35.655441678088771, 0.0],
              [-80.932657318617444, 35.655625011523711, 0.0],
              [-80.93248891764577, 35.655579277856631, 0.0],
              [-80.932488718316506, 35.655327411198364, 0.0],
              [-80.93271298506599, 35.655052477689765, 0.0],
              [-80.933021585227578, 35.654914877831878, 0.0],
              [-80.934228184567118, 35.654891411328002, 0.0],
              [-80.934592584976841, 35.654478878161008, 0.0],
              [-80.934676717557736, 35.654227011668659, 0.0],
              [-80.934395718608187, 35.65365447817252, 0.0],
              [-80.934171184744642, 35.653654677913181, 0.0],
              [-80.933778385304777, 35.653815078141868, 0.0],
              [-80.933581985056207, 35.653815278374701, 0.0],
              [-80.933469718071734, 35.653723677541024, 0.0],
              [-80.933581317742977, 35.6528762778687, 0.0],
              [-80.933188317964436, 35.652601611629017, 0.0],
              [-80.932122184314153, 35.65278527764859, 0.0],
              [-80.93175738484787, 35.652785478365338, 0.0],
              [-80.931785185050757, 35.65246487832961, 0.0],
              [-80.931925318477496, 35.652258811082589, 0.0],
              [-80.931896984564048, 35.651823611235699, 0.0],
              [-80.931587985279194, 35.651388611051637, 0.0],
              [-80.93144758489457, 35.651251278373493, 0.0],
              [-80.93122318442424, 35.651320077819854, 0.0],
              [-80.930690718095875, 35.652190677770463, 0.0],
              [-80.93057878528063, 35.652580077686501, 0.0],
              [-80.930101918064992, 35.652809277958681, 0.0],
              [-80.929849584521108, 35.653221610837434, 0.0],
              [-80.929569117680145, 35.65338207762202, 0.0],
              [-80.929428984637909, 35.653771477684536, 0.0],
              [-80.929260917889948, 35.653931878208923, 0.0],
              [-80.928615384248985, 35.65395521137382, 0.0],
              [-80.928334985359712, 35.654207211711878, 0.0],
              [-80.927689785346189, 35.654482278006618, 0.0],
              [-80.927718118458912, 35.654802877875163, 0.0],
              [-80.928083384609408, 35.655489811150801, 0.0],
              [-80.928083385053, 35.6557646783479, 0.0],
              [-80.927634384715873, 35.655604478098439, 0.0],
              [-80.927353585420647, 35.655284011788076, 0.0],
              [-80.927212984468298, 35.654917678291881, 0.0],
              [-80.926988585108205, 35.654871878222359, 0.0],
              [-80.926820184788554, 35.654986477685043, 0.0],
              [-80.926315718434637, 35.655834211170877, 0.0],
              [-80.925698785273383, 35.656498611563528, 0.0],
              [-80.925558784892786, 35.657025411669807, 0.0],
              [-80.925054185310103, 35.657827211083287, 0.0],
              [-80.924212518485703, 35.658125277844377, 0.0],
              [-80.924044118014095, 35.658033811653048, 0.0],
              [-80.924100117915415, 35.657827611086532, 0.0],
              [-80.924969584768306, 35.657163077593189, 0.0],
              [-80.925053718333089, 35.657002677965195, 0.0],
              [-80.924941184957362, 35.65649887830088, 0.0],
              [-80.924968918429244, 35.656132411797657, 0.0],
              [-80.925585917703302, 35.65562827793363, 0.0],
              [-80.925782185084771, 35.655330610927855, 0.0],
              [-80.925950184502, 35.654757877796044, 0.0],
              [-80.926005917862327, 35.654139411540868, 0.0],
              [-80.926230318167228, 35.653956211127912, 0.0],
              [-80.92693158465039, 35.653704011699389, 0.0],
              [-80.92735238463581, 35.653383211136457, 0.0],
              [-80.928222118061925, 35.653153678029838, 0.0],
              [-80.92844638426071, 35.652878678357411, 0.0],
              [-80.928614518692783, 35.652443478434122, 0.0],
              [-80.929063117701247, 35.651962411593736, 0.0],
              [-80.929231185119249, 35.651641677930009, 0.0],
              [-80.92923098542424, 35.651229411767751, 0.0],
              [-80.929062384344249, 35.650908878096658, 0.0],
              [-80.928641317811184, 35.650703010978454, 0.0],
              [-80.928304718521559, 35.650771811309596, 0.0],
              [-80.927434918037719, 35.650749278403786, 0.0],
              [-80.926256718369658, 35.6512536110613, 0.0],
              [-80.926144317836076, 35.651139211328839, 0.0],
              [-80.9261723182873, 35.650978811561096, 0.0],
              [-80.926929584658211, 35.650451811108496, 0.0],
              [-80.92864098454946, 35.650084478458709, 0.0],
              [-80.928949318035578, 35.649695077969369, 0.0],
              [-80.929286118721336, 35.649763678307167, 0.0],
              [-80.929622917578314, 35.649946677952293, 0.0],
              [-80.930099918145373, 35.649900611680685, 0.0],
              [-80.930239984912731, 35.649671611782537, 0.0],
              [-80.930239918742544, 35.649488411047415, 0.0],
              [-80.930071385231827, 35.649259478435575, 0.0],
              [-80.929734584693051, 35.64912221093897, 0.0],
              [-80.92950991815421, 35.648801611307007, 0.0],
              [-80.929144985386344, 35.648641478133534, 0.0],
              [-80.9292569851698, 35.6482978780179, 0.0],
              [-80.929846384279273, 35.648457877578245, 0.0],
              [-80.930631985146363, 35.648388811512831, 0.0],
              [-80.931164784400863, 35.647953411619056, 0.0],
              [-80.931080518446024, 35.647747278368485, 0.0],
              [-80.930799717850647, 35.647564211588481, 0.0],
              [-80.930266584555824, 35.647656077927195, 0.0],
              [-80.929761518525964, 35.647427277543663, 0.0],
              [-80.929088184915059, 35.647519277645365, 0.0],
              [-80.928807517874404, 35.647382011095196, 0.0],
              [-80.928134118752496, 35.64733647780001, 0.0],
              [-80.927853184662993, 35.646901478018023, 0.0],
              [-80.927319984758242, 35.646695611064466, 0.0],
              [-80.927347918081296, 35.646420811559835, 0.0],
              [-80.927908985103102, 35.646512010943631, 0.0],
              [-80.928133517702392, 35.646420410976319, 0.0],
              [-80.92978911838631, 35.64674027791488, 0.0],
              [-80.930462518619635, 35.646671211216386, 0.0],
              [-80.930967318178915, 35.646396210974309, 0.0],
              [-80.931191385027532, 35.646006811023248, 0.0],
              [-80.931415984890776, 35.646052411461724, 0.0],
              [-80.931949317588163, 35.646487278269831, 0.0],
              [-80.932314185377891, 35.64662447843498, 0.0],
              [-80.932959518183239, 35.646555477796745, 0.0],
              [-80.933099584522907, 35.646349278474119, 0.0],
              [-80.932678384241598, 35.645845677925571, 0.0],
              [-80.932145118587243, 35.645525277714029, 0.0],
              [-80.932172785389085, 35.644952811375695, 0.0],
              [-80.932060384218147, 35.644815411328985, 0.0],
              [-80.931134317853477, 35.644518011000827, 0.0],
              [-80.929814984525791, 35.643579677783173, 0.0],
              [-80.9298709842992, 35.64339647791747, 0.0],
              [-80.930881318269741, 35.643808211033111, 0.0],
              [-80.931610785102805, 35.643830811290151, 0.0],
              [-80.932116117778719, 35.644197011683829, 0.0],
              [-80.932593184711905, 35.644311278499003, 0.0],
              [-80.933013918696744, 35.644219478370779, 0.0],
              [-80.933125985248068, 35.64405901088702, 0.0],
              [-80.932704718712529, 35.643440811547137, 0.0],
              [-80.932704584450491, 35.643280477961895, 0.0],
              [-80.933265784285496, 35.643257411670135, 0.0],
              [-80.933630585383582, 35.643325878192279, 0.0],
              [-80.933882917607505, 35.643211278414434, 0.0],
              [-80.933854584618146, 35.642799011347833, 0.0],
              [-80.934359384382986, 35.642523877749554, 0.0],
              [-80.934359317645118, 35.642432277658308, 0.0],
              [-80.934583717815826, 35.6422490781653, 0.0],
              [-80.935425184969489, 35.642088211241017, 0.0],
              [-80.935509317983559, 35.641973678205957, 0.0],
              [-80.935284717913603, 35.641744811019571, 0.0],
              [-80.934386585263965, 35.641378811800351, 0.0],
              [-80.934470717806249, 35.641195477700293, 0.0],
              [-80.934751118730802, 35.64110381092928, 0.0],
              [-80.937079985004658, 35.641514810937942, 0.0],
              [-80.937388584899665, 35.641423077590098, 0.0],
              [-80.937668984586622, 35.641193878381117, 0.0],
              [-80.93848231777838, 35.640872811224767, 0.0],
              [-80.938650517670553, 35.640666611141569, 0.0],
              [-80.938594184411343, 35.640368877885358, 0.0],
              [-80.938004785302368, 35.639979877849356, 0.0],
              [-80.937443584334844, 35.639934278057545, 0.0],
              [-80.937330984904222, 35.639499211499945, 0.0],
              [-80.936685384720633, 35.639064477569292, 0.0],
              [-80.935984118648449, 35.639110611486586, 0.0],
              [-80.933908517978708, 35.639707078358015, 0.0],
              [-80.933207118597636, 35.639776277890903, 0.0],
              [-80.933206917546372, 35.639524277868887, 0.0],
              [-80.93334711839023, 35.639386811198726, 0.0],
              [-80.93474951864745, 35.63888227802385, 0.0],
              [-80.935394518164699, 35.638561210938008, 0.0],
              [-80.936011318179254, 35.638102878059456, 0.0],
              [-80.937021118135547, 35.637713077707055, 0.0],
              [-80.936964784361678, 35.637529811451138, 0.0],
              [-80.936599918142306, 35.637232411616488, 0.0],
              [-80.93657158492752, 35.636842877636468, 0.0],
              [-80.936318784968975, 35.636614077529686, 0.0],
              [-80.934859918165614, 35.636408810966849, 0.0],
              [-80.934915717767481, 35.636111011248637, 0.0],
              [-80.935139984482973, 35.635881811751609, 0.0],
              [-80.936374118697699, 35.635491811078467, 0.0],
              [-80.937299718048308, 35.635331011601302, 0.0],
              [-80.937215517968383, 35.635147811461124, 0.0],
              [-80.935840318416695, 35.634347011229394, 0.0],
              [-80.935475518576411, 35.634301278197178, 0.0],
              [-80.934465917858304, 35.634828611050096, 0.0],
              [-80.934017518088652, 35.63537861141787, 0.0],
              [-80.933962118161872, 35.636294677984417, 0.0],
              [-80.933765984545616, 35.636638278437637, 0.0],
              [-80.933738317815397, 35.637325277667365, 0.0],
              [-80.933626317688621, 35.637417078468417, 0.0],
              [-80.933457918233429, 35.637394211023221, 0.0],
              [-80.933289384726152, 35.63707367810251, 0.0],
              [-80.933008584594191, 35.636890611433252, 0.0],
              [-80.932447717979898, 35.637028277903426, 0.0],
              [-80.932055118415846, 35.637303411471279, 0.0],
              [-80.931718918083121, 35.638036411603757, 0.0],
              [-80.931214185137961, 35.638311411483883, 0.0],
              [-80.930709785320971, 35.639204877883813, 0.0],
              [-80.930429184445686, 35.639182077834661, 0.0],
              [-80.930148318638359, 35.638632677970278, 0.0],
              [-80.929811518463822, 35.638541077854313, 0.0],
              [-80.929895584450563, 35.638197478190747, 0.0],
              [-80.929811185386313, 35.638037211033812, 0.0],
              [-80.929839118310198, 35.637739478477606, 0.0],
              [-80.930540184836687, 35.637349878217108, 0.0],
              [-80.930708385394624, 35.637166610874274, 0.0],
              [-80.930679917549952, 35.636525278052467, 0.0],
              [-80.931549384739327, 35.636227078271439, 0.0],
              [-80.931661517632648, 35.636089611302353, 0.0],
              [-80.931633185141067, 35.635723278418304, 0.0],
              [-80.931520918579125, 35.635563011038691, 0.0],
              [-80.930735117935228, 35.635242678513613, 0.0],
              [-80.930819117938285, 35.635036611485091, 0.0],
              [-80.931295917866066, 35.634784411813413, 0.0],
              [-80.932726318693767, 35.634279877776123, 0.0],
              [-80.933118717986162, 35.633867411266856, 0.0],
              [-80.933483184971692, 35.63361527778661, 0.0],
              [-80.933651318291041, 35.63329461136756, 0.0],
              [-80.933903584919577, 35.633088278162404, 0.0],
              [-80.933818984583453, 35.632447078050767, 0.0],
              [-80.934099184262493, 35.631966011832169, 0.0],
              [-80.93418291770169, 35.63137047769176, 0.0],
              [-80.93373411859659, 35.631370678302261, 0.0],
              [-80.933089184813269, 35.631783411418795, 0.0],
              [-80.932416117620036, 35.632012611780105, 0.0],
              [-80.932191785300859, 35.632264678091914, 0.0],
              [-80.931799585146166, 35.633020677564232, 0.0],
              [-80.931238784265901, 35.633364478211995, 0.0],
              [-80.930818384919348, 35.634028877640681, 0.0],
              [-80.930510118163596, 35.634280878253826, 0.0],
              [-80.929500384707268, 35.634647811788533, 0.0],
              [-80.929303917711948, 35.634625078226762, 0.0],
              [-80.929668117600883, 35.633914878314393, 0.0],
              [-80.929808384236381, 35.633800277972774, 0.0],
              [-80.929808318524309, 35.63357127823317, 0.0],
              [-80.929471584233042, 35.63359427835622, 0.0],
              [-80.928938985350158, 35.634350410905469, 0.0],
              [-80.928714584226014, 35.634350477953582, 0.0],
              [-80.928685984536955, 35.633365677959404, 0.0],
              [-80.928825984423526, 35.633113677575579, 0.0],
              [-80.92944298495236, 35.632724078253332, 0.0],
              [-80.929919518271788, 35.632174211425706, 0.0],
              [-80.930676585122995, 35.631692811344642, 0.0],
              [-80.930676185205741, 35.631211878172799, 0.0],
              [-80.931404984941025, 35.630455811006769, 0.0],
              [-80.93148878534069, 35.630066411243824, 0.0],
              [-80.9313203183046, 35.629677211402786, 0.0],
              [-80.930983385214262, 35.629425410930928, 0.0],
              [-80.930029784359746, 35.629448811695639, 0.0],
              [-80.929608918553427, 35.629311610998279, 0.0],
              [-80.929160118347539, 35.629403411124954, 0.0],
              [-80.928711584217268, 35.629930277635601, 0.0],
              [-80.928347384234215, 35.630640477607066, 0.0],
              [-80.928319917778268, 35.631327477828123, 0.0],
              [-80.928404185178792, 35.631487811403034, 0.0],
              [-80.928376318475657, 35.631854277842024, 0.0],
              [-80.928067917586276, 35.63206047834187, 0.0],
              [-80.92697391815139, 35.632106811370548, 0.0],
              [-80.92624478461245, 35.632473611094134, 0.0],
              [-80.925880118084663, 35.6324508776706, 0.0],
              [-80.9257397853535, 35.632313478062486, 0.0],
              [-80.925767717584023, 35.63217607824344, 0.0],
              [-80.92638451832714, 35.63151167796169, 0.0],
              [-80.926608584641286, 35.631145078031444, 0.0],
              [-80.926692517714784, 35.630870210925707, 0.0],
              [-80.92635578431387, 35.630664277747073, 0.0],
              [-80.926299385259185, 35.630366610864918, 0.0],
              [-80.926046784463679, 35.63004601162789, 0.0],
              [-80.926074184860639, 35.629152811564232, 0.0],
              [-80.925765718326673, 35.629198811460903, 0.0],
              [-80.925597584905532, 35.629382078307515, 0.0],
              [-80.925373185209267, 35.6293820782099, 0.0],
              [-80.925176584559765, 35.629221877606369, 0.0],
              [-80.925147984660697, 35.628351678160421, 0.0],
              [-80.925007518708355, 35.627847811203878, 0.0],
              [-80.924782984950895, 35.627687611205374, 0.0],
              [-80.924362184865132, 35.62768787792465, 0.0],
              [-80.923997785003991, 35.628054411812982, 0.0],
              [-80.923857718761397, 35.628329278432922, 0.0],
              [-80.923886118650188, 35.62899341098867, 0.0],
              [-80.923269318773691, 35.629405878465896, 0.0],
              [-80.922708985308418, 35.630688811648945, 0.0],
              [-80.922681385239542, 35.631375810956079, 0.0],
              [-80.922344985277022, 35.631696611268694, 0.0],
              [-80.922373185262941, 35.631971411066012, 0.0],
              [-80.92282218440883, 35.632246077590771, 0.0],
              [-80.922570117909757, 35.632841611176978, 0.0],
              [-80.922598518158296, 35.633299678132403, 0.0],
              [-80.922710717700824, 35.633391278118616, 0.0],
              [-80.923047318084485, 35.633391077538988, 0.0],
              [-80.923720384439846, 35.633138811627674, 0.0],
              [-80.923916918104538, 35.633161678392803, 0.0],
              [-80.924113318636415, 35.633413478433212, 0.0],
              [-80.92383298445678, 35.63378007755496, 0.0],
              [-80.923833118066909, 35.633986278508452, 0.0],
              [-80.924703384610538, 35.634787478100392, 0.0],
              [-80.924703385045788, 35.634970678474879, 0.0],
              [-80.924591318566698, 35.635085211170917, 0.0],
              [-80.924002184240265, 35.635085478420095, 0.0],
              [-80.922430918390461, 35.634651011487087, 0.0],
              [-80.921392985338358, 35.6346514112455, 0.0],
              [-80.920972184858215, 35.634834877762032, 0.0],
              [-80.921000718735755, 35.635430211528245, 0.0],
              [-80.921421584361241, 35.635727878417477, 0.0],
              [-80.921618185082835, 35.636002611704718, 0.0],
              [-80.922375584662163, 35.636116811688694, 0.0],
              [-80.922487984448125, 35.636322811160994, 0.0],
              [-80.922712518552501, 35.636483078456543, 0.0],
              [-80.923806584591617, 35.636482611438282, 0.0],
              [-80.92428378525841, 35.636780077764961, 0.0],
              [-80.924900984688861, 35.636871478262869, 0.0],
              [-80.925658385036911, 35.636871077846379, 0.0],
              [-80.925966984744235, 35.636985611788717, 0.0],
              [-80.925855118391866, 35.637397811311168, 0.0],
              [-80.926500985187175, 35.638382278342988, 0.0],
              [-80.926529184835957, 35.638565477782159, 0.0],
              [-80.926809984384718, 35.638954811426885, 0.0],
              [-80.926754118032164, 35.639206811628618, 0.0],
              [-80.926529585096915, 35.639206878493383, 0.0],
              [-80.925911984863731, 35.638611677947118, 0.0],
              [-80.925406917684967, 35.638405811330827, 0.0],
              [-80.924368784458878, 35.638200078494286, 0.0],
              [-80.923891718097124, 35.638017011810163, 0.0],
              [-80.923274584418678, 35.638063078200631, 0.0],
              [-80.923078317828029, 35.638223478202363, 0.0],
              [-80.922854118349676, 35.638704477789787, 0.0],
              [-80.922938584820272, 35.639277010859352, 0.0],
              [-80.923668318162299, 35.63964321131737, 0.0],
              [-80.923612317780865, 35.63987227842707, 0.0],
              [-80.923275784959131, 35.640147211090031, 0.0],
              [-80.922658584663125, 35.640216277737423, 0.0],
              [-80.922349984281695, 35.640330878488761, 0.0],
              [-80.922322184710282, 35.640743077929855, 0.0],
              [-80.922630984908551, 35.640994878072988, 0.0],
              [-80.923248384860372, 35.641315278237535, 0.0],
              [-80.923585385113157, 35.641887677888107, 0.0],
              [-80.923725917664356, 35.64232267793048, 0.0],
              [-80.924062718446507, 35.642528678480836, 0.0],
              [-80.924764384685233, 35.642757477622588, 0.0],
              [-80.924652318754539, 35.643078211525399, 0.0],
              [-80.92473671776817, 35.643444611490487, 0.0],
              [-80.924848984739683, 35.643559011729849, 0.0],
              [-80.924792984966246, 35.64378807814132, 0.0],
              [-80.924372117597613, 35.643696611478866, 0.0],
              [-80.923782784872998, 35.643422211197851, 0.0],
              [-80.923361784227836, 35.643376478037851, 0.0],
              [-80.923025117990989, 35.643124678401442, 0.0],
              [-80.922211185358094, 35.642804411131273, 0.0],
              [-80.921004384371088, 35.642163677840742, 0.0],
              [-80.920611718570058, 35.642209611486791, 0.0],
              [-80.920163117607359, 35.642713611714264, 0.0],
              [-80.919658318593619, 35.643057277943939, 0.0],
              [-80.919546518730982, 35.643836077919481, 0.0],
              [-80.920107718110771, 35.644179411775205, 0.0],
              [-80.920164118326696, 35.644660277877989, 0.0],
              [-80.920248318049659, 35.644729011757178, 0.0],
              [-80.92044518524375, 35.645484678002575, 0.0],
              [-80.920950517782174, 35.645988278350494, 0.0],
              [-80.922016985426183, 35.646537610929329, 0.0],
              [-80.922017118728547, 35.646697877949244, 0.0],
              [-80.921343717760124, 35.646789811547912, 0.0],
              [-80.92137191824304, 35.647018678297499, 0.0],
              [-80.921989585249875, 35.647705611207094, 0.0],
              [-80.922158517987711, 35.648530011210255, 0.0],
              [-80.92229891778139, 35.648873611790059, 0.0],
              [-80.922523518579581, 35.64914827842135, 0.0],
              [-80.922495785331293, 35.649675078103627, 0.0],
              [-80.922271318641208, 35.649720878252374, 0.0],
              [-80.922159118734456, 35.649629277943006, 0.0],
              [-80.922130918607735, 35.649446211374702, 0.0],
              [-80.921625518357956, 35.648942611123864, 0.0],
              [-80.921316584362927, 35.648393011765229, 0.0],
              [-80.920755118467326, 35.64786647814514, 0.0],
              [-80.920193584709509, 35.647156678344196, 0.0],
              [-80.919940984350617, 35.646996477644208, 0.0],
              [-80.919239384425907, 35.646790611646132, 0.0],
              [-80.918706184518413, 35.646401478117092, 0.0],
              [-80.918397518692672, 35.646401610929303, 0.0],
              [-80.918060918253545, 35.646745211070431, 0.0],
              [-80.917864784652153, 35.647134677982862, 0.0],
              [-80.917781118707893, 35.647959211092441, 0.0],
              [-80.918062118068647, 35.64894387781537, 0.0],
              [-80.91814631799636, 35.649012611130971, 0.0],
              [-80.918174784464881, 35.649722478112238, 0.0],
              [-80.91851191773921, 35.650386611343706, 0.0],
              [-80.91851191839271, 35.650546878200359, 0.0],
              [-80.918596184958275, 35.650592611567916, 0.0],
              [-80.918680718153738, 35.651279678420423, 0.0],
              [-80.918456317999514, 35.65130267798915, 0.0],
              [-80.917557585142333, 35.650089211440275, 0.0],
              [-80.917164317669062, 35.649013011195265, 0.0],
              [-80.916827518003629, 35.648692478333111, 0.0],
              [-80.916518784488872, 35.648646678208664, 0.0],
              [-80.915817584279509, 35.649196611712085, 0.0],
              [-80.915705517760045, 35.649402677671446, 0.0],
              [-80.91576191805369, 35.65015861103771, 0.0],
              [-80.915846185346936, 35.650250211210263, 0.0],
              [-80.915902518131318, 35.650708210851448, 0.0],
              [-80.915986784931334, 35.650754011121521, 0.0],
              [-80.916099317677293, 35.651234877567674, 0.0],
              [-80.916492384957849, 35.651990478189994, 0.0],
              [-80.917194384340107, 35.652952078305532, 0.0],
              [-80.917419184928363, 35.653547477581789, 0.0],
              [-80.917250984771471, 35.653753677838331, 0.0],
              [-80.916970318624081, 35.653662211587644, 0.0],
              [-80.916072118003498, 35.652952477698939, 0.0],
              [-80.915707185112026, 35.652815278414572, 0.0],
              [-80.915258184747529, 35.652838411672519, 0.0],
              [-80.914977784307823, 35.653296477714619, 0.0],
              [-80.914613184326598, 35.653571411554353, 0.0],
              [-80.914304718520711, 35.654075411568812, 0.0],
              [-80.914080384570738, 35.654167077804352, 0.0],
              [-80.913939985258153, 35.654098411211422, 0.0],
              [-80.913967918455711, 35.653777678236395, 0.0],
              [-80.914528585346361, 35.652724078221752, 0.0],
              [-80.914640585233514, 35.652174411795407, 0.0],
              [-80.914612117771583, 35.651327011420577, 0.0],
              [-80.914443585311815, 35.651006410949776, 0.0],
              [-80.913966385169488, 35.650640077514112, 0.0],
              [-80.91348938442286, 35.650709011758693, 0.0],
              [-80.91309678515799, 35.651075478270982, 0.0],
              [-80.912900984740375, 35.652312411124058, 0.0],
              [-80.912312118091378, 35.653274411302881, 0.0],
              [-80.912087584701695, 35.653205811469583, 0.0],
              [-80.911834984613634, 35.652976877775281, 0.0],
              [-80.911666585085328, 35.652999811161223, 0.0],
              [-80.911498317663288, 35.653160277615967, 0.0],
              [-80.910684584325921, 35.653137611618099, 0.0],
              [-80.910572385194897, 35.653297877638231, 0.0],
              [-80.910432384820098, 35.653916411731728, 0.0],
              [-80.910432717712624, 35.654626278393913, 0.0],
              [-80.910629184773384, 35.654740811135859, 0.0],
              [-80.911583318443107, 35.654809211798487, 0.0],
              [-80.912116518093953, 35.655038077922782, 0.0],
              [-80.912144718033858, 35.655289878330095, 0.0],
              [-80.91147118468686, 35.655198478071696, 0.0],
              [-80.911106384418858, 35.655290211172158, 0.0],
              [-80.91099431830024, 35.655473477824472, 0.0],
              [-80.911022385184495, 35.655610877940575, 0.0],
              [-80.911246985062817, 35.655839878336131, 0.0],
              [-80.911584118506951, 35.656435278251962, 0.0],
              [-80.911696384653553, 35.656847478003769, 0.0],
              [-80.912145717746526, 35.657442811694317, 0.0],
              [-80.912145984931911, 35.658198477587916, 0.0],
              [-80.912005918125971, 35.658450478216949, 0.0],
              [-80.911781318660033, 35.658381877751651, 0.0],
              [-80.9113319175963, 35.657443077894094, 0.0],
              [-80.911107318495695, 35.657237011063486, 0.0],
              [-80.910602117920121, 35.65712261126658, 0.0],
              [-80.910040785271178, 35.6567334779064, 0.0],
              [-80.908974584802735, 35.656939877499632, 0.0],
              [-80.908525584588844, 35.657146211753798, 0.0],
              [-80.908273185310009, 35.657489811788217, 0.0],
              [-80.908273384878953, 35.658062410827114, 0.0],
              [-80.909171918315565, 35.659344677531003, 0.0],
              [-80.909256385191398, 35.660054611441872, 0.0],
              [-80.909817984427946, 35.660764278389479, 0.0],
              [-80.909734118741923, 35.66145147753636, 0.0],
              [-80.909593917947035, 35.661657678425634, 0.0],
              [-80.909537984687987, 35.662138611796223, 0.0],
              [-80.909453918671232, 35.66220727826385, 0.0],
              [-80.909229318318822, 35.662138677978987, 0.0],
              [-80.909116718107583, 35.661405877761823, 0.0],
              [-80.908891984260421, 35.661039477914336, 0.0],
              [-80.908863784375484, 35.660673077621325, 0.0],
              [-80.908639184294714, 35.660467078427381, 0.0],
              [-80.908358318710739, 35.659642611054153, 0.0],
              [-80.907965317780679, 35.65939067769105, 0.0],
              [-80.907404118134266, 35.659436811200763, 0.0],
              [-80.906674785104485, 35.660490477695262, 0.0],
              [-80.906619318784223, 35.6619104783152, 0.0],
              [-80.906422984712833, 35.662070811489052, 0.0],
              [-80.906282584916369, 35.662025078323737, 0.0],
              [-80.906142118141844, 35.661452478239205, 0.0],
              [-80.906057784281288, 35.661406678174139, 0.0],
              [-80.905945384657684, 35.660834211201113, 0.0],
              [-80.906141584635932, 35.660353211628404, 0.0],
              [-80.906197717917465, 35.659941011655249, 0.0],
              [-80.90611331793805, 35.659689077949459, 0.0],
              [-80.905944918805844, 35.6595974784573, 0.0],
              [-80.905944784670069, 35.65932267799176, 0.0],
              [-80.906056985139841, 35.659185211003987, 0.0],
              [-80.90605671769913, 35.658566877973016, 0.0],
              [-80.905832184748888, 35.65836081144748, 0.0],
              [-80.905102585023016, 35.658498411688782, 0.0],
              [-80.904793984991969, 35.658704611386696, 0.0],
              [-80.904148717838055, 35.658888011686152, 0.0],
              [-80.903447384597456, 35.659621077845308, 0.0],
              [-80.902128584682188, 35.660193878402175, 0.0],
              [-80.901876184837775, 35.660491677565126, 0.0],
              [-80.901764118757995, 35.660789478276271, 0.0],
              [-80.901146784783478, 35.661247811693613, 0.0],
              [-80.900725984275013, 35.661843211189158, 0.0],
              [-80.900782318593173, 35.662324211513123, 0.0],
              [-80.900922718085909, 35.66262187795197, 0.0],
              [-80.900838718364326, 35.662782211318842, 0.0],
              [-80.900305384390634, 35.66269067786574, 0.0],
              [-80.899940584596393, 35.662759477777996, 0.0],
              [-80.899463584273633, 35.663011611687736, 0.0],
              [-80.899154984534405, 35.663561410911761, 0.0],
              [-80.899014917694188, 35.664202611102532, 0.0],
              [-80.899155385178616, 35.664912611721803, 0.0],
              [-80.899155717847279, 35.665782878296767, 0.0],
              [-80.899239918181181, 35.665943211462157, 0.0],
              [-80.900138317891731, 35.666767411482944, 0.0],
              [-80.900166518018011, 35.667294211278282, 0.0],
              [-80.900082318373137, 35.667340010814854, 0.0],
              [-80.899464785420605, 35.667111077986576, 0.0],
              [-80.898903384616631, 35.666561477727171, 0.0],
              [-80.898622718758332, 35.666447211464067, 0.0],
              [-80.898313918554663, 35.666149411322017, 0.0],
              [-80.897976584412916, 35.664523411558839, 0.0],
              [-80.897667785449627, 35.664134211097128, 0.0],
              [-80.897359117767124, 35.664203011382128, 0.0],
              [-80.896854118294755, 35.664775611171464, 0.0],
              [-80.896321117644348, 35.665233811139387, 0.0],
              [-80.89536691765413, 35.665554477919116, 0.0],
              [-80.894889918479407, 35.66580661095221, 0.0],
              [-80.894356717769639, 35.665875478410612, 0.0],
              [-80.894104185108787, 35.666104411234862, 0.0],
              [-80.893683318201951, 35.665990010999167, 0.0],
              [-80.893121985359784, 35.66615047763127, 0.0],
              [-80.893037784449533, 35.666379477779273, 0.0],
              [-80.892588784747602, 35.666746077665415, 0.0],
              [-80.892616918767999, 35.666975077896048, 0.0],
              [-80.89348718450961, 35.667845211795715, 0.0],
              [-80.893515317948768, 35.668097077942875, 0.0],
              [-80.892785584536824, 35.668188877944814, 0.0],
              [-80.892785717799413, 35.668509478373146, 0.0],
              [-80.893066385146042, 35.668898677534209, 0.0],
              [-80.893066517940454, 35.66915061143726, 0.0],
              [-80.892869984534286, 35.669150678355358, 0.0],
              [-80.892589318484241, 35.668944611079532, 0.0],
              [-80.892196384777179, 35.668967477879065, 0.0],
              [-80.891747384629085, 35.669334211253734, 0.0],
              [-80.891719385023649, 35.66997541127764, 0.0],
              [-80.891186517653921, 35.671051877847766, 0.0],
              [-80.889811318607059, 35.671533077917822, 0.0],
              [-80.889193918378552, 35.671945411499365, 0.0],
              [-80.889053585048345, 35.672174277536911, 0.0],
              [-80.889025585388964, 35.672747010983613, 0.0],
              [-80.888885317828496, 35.672815610819328, 0.0],
              [-80.888351984587075, 35.672815877505727, 0.0],
              [-80.887397917970247, 35.673113611033578, 0.0],
              [-80.887433784648465, 35.67294707784486, 0.0],
              [-80.887481984450815, 35.672724277832522, 0.0],
              [-80.887987117950601, 35.672060010914208, 0.0],
              [-80.888155385357081, 35.671556210865546, 0.0],
              [-80.888828784359418, 35.670342278028812, 0.0],
              [-80.889782917873404, 35.670113078443542, 0.0],
              [-80.890259985131976, 35.669723677856886, 0.0],
              [-80.890652918475965, 35.669151078323438, 0.0],
              [-80.890680785143005, 35.668830477991996, 0.0],
              [-80.891157784373306, 35.667960011639764, 0.0],
              [-80.891185718605769, 35.667479077673789, 0.0],
              [-80.890596117923621, 35.665807410830432, 0.0],
              [-80.890034585103166, 35.665120277701845, 0.0],
              [-80.88961371844286, 35.664868478179585, 0.0],
              [-80.889389118058048, 35.664387611422192, 0.0],
              [-80.889136517619249, 35.664089811342699, 0.0],
              [-80.88787351834344, 35.663563278147926, 0.0],
              [-80.887761185443125, 35.663380011583527, 0.0],
              [-80.887805917809672, 35.663307010934126, 0.0],
              [-80.888013718015117, 35.662967678213072, 0.0],
              [-80.888018784426635, 35.662968011011053, 0.0],
              [-80.888378518116198, 35.662990611716452, 0.0],
              [-80.888715318044163, 35.663105011394322, 0.0],
              [-80.88955718446357, 35.663127878100667, 0.0],
              [-80.88975371796792, 35.663265211585149, 0.0],
              [-80.890174785384588, 35.663860611608939, 0.0],
              [-80.891409717713827, 35.664822278059297, 0.0],
              [-80.891774717899409, 35.665211611409781, 0.0],
              [-80.892111385274291, 35.665234478092401, 0.0],
              [-80.892420184892387, 35.665142878317191, 0.0],
              [-80.892841184815566, 35.665509211092406, 0.0],
              [-80.893318185394421, 35.665371678395225, 0.0],
              [-80.893289984614128, 35.664913678191233, 0.0],
              [-80.893458384875188, 35.664799211402865, 0.0],
              [-80.894272317773797, 35.664570011452028, 0.0],
              [-80.895085917626275, 35.664203410846923, 0.0],
              [-80.895787585408755, 35.663997078319277, 0.0],
              [-80.895787385002805, 35.663539077977511, 0.0],
              [-80.895675118468674, 35.663401678044224, 0.0],
              [-80.895674985425885, 35.662897811006708, 0.0],
              [-80.894917184922576, 35.662233811507633, 0.0],
              [-80.895057384644417, 35.662096278064048, 0.0],
              [-80.896123918287529, 35.662073210919111, 0.0],
              [-80.897611118494538, 35.662279077549108, 0.0],
              [-80.897975985175364, 35.662027077673748, 0.0],
              [-80.898368585378819, 35.661408611784921, 0.0],
              [-80.898985984631111, 35.661156478388158, 0.0],
              [-80.899154185383139, 35.660698411337897, 0.0],
              [-80.899771385142586, 35.66028607816272, 0.0],
              [-80.899771184606763, 35.659484411648172, 0.0],
              [-80.899378118179129, 35.65886621145242, 0.0],
              [-80.89912551762319, 35.658637277685784, 0.0],
              [-80.898760718477902, 35.658545678282572, 0.0],
              [-80.89884478515539, 35.658362477885156, 0.0],
              [-80.899321785404268, 35.658270810887359, 0.0],
              [-80.899939384746332, 35.658728611499811, 0.0],
              [-80.900612984878052, 35.659026278352783, 0.0],
              [-80.90083738538668, 35.658957478064117, 0.0],
              [-80.901398517965347, 35.658384878108379, 0.0],
              [-80.902071918395677, 35.658201411575106, 0.0],
              [-80.90254891831556, 35.657949411562797, 0.0],
              [-80.902829317746836, 35.657628678276538, 0.0],
              [-80.902885318460491, 35.657216477867891, 0.0],
              [-80.903670985402201, 35.65689567803355, 0.0],
              [-80.904231918140837, 35.656116677834859, 0.0],
              [-80.905298118212514, 35.655933277828481, 0.0],
              [-80.905662784766804, 35.655543877516799, 0.0],
              [-80.906420318346292, 35.65520001129218, 0.0],
              [-80.906728784387525, 35.654810678473929, 0.0],
              [-80.906728717901217, 35.654375477994918, 0.0],
              [-80.906110918388208, 35.653436677546509, 0.0],
              [-80.905633784776114, 35.653047478249007, 0.0],
              [-80.905268918524115, 35.652933011544334, 0.0],
              [-80.904651584290576, 35.652933211384607, 0.0],
              [-80.903248784309852, 35.653322878009959, 0.0],
              [-80.902435184982195, 35.653689611633894, 0.0],
              [-80.902266785185873, 35.65364381174502, 0.0],
              [-80.902238518579082, 35.652888078076053, 0.0],
              [-80.901424517988815, 35.652063677829922, 0.0],
              [-80.900442384260245, 35.652224277706949, 0.0],
              [-80.899264118497598, 35.652682611222097, 0.0],
              [-80.898899385206036, 35.652751277621171, 0.0],
              [-80.898815185093355, 35.652682611174107, 0.0],
              [-80.899376118136161, 35.652087077946206, 0.0],
              [-80.901003385390055, 35.651582877715086, 0.0],
              [-80.901508385421508, 35.651055877847504, 0.0],
              [-80.9016767181717, 35.651010078446433, 0.0],
              [-80.901901318534456, 35.651468078381484, 0.0],
              [-80.902181918322952, 35.651674211520209, 0.0],
              [-80.902603118329381, 35.652246610881889, 0.0],
              [-80.902911785324761, 35.652406811423056, 0.0],
              [-80.903753518125058, 35.652452478230238, 0.0],
              [-80.904090184259758, 35.652177611169279, 0.0],
              [-80.904454785003679, 35.651673678182817, 0.0],
              [-80.904707318115697, 35.651604877625331, 0.0],
              [-80.905128184997253, 35.651696411448683, 0.0],
              [-80.90540878428213, 35.651627611779148, 0.0],
              [-80.905717385370934, 35.651398411113121, 0.0],
              [-80.905745317909222, 35.651054878107551, 0.0],
              [-80.906138117637724, 35.651009011017969, 0.0],
              [-80.908130585004173, 35.651695477728666, 0.0],
              [-80.908579517776445, 35.651718278062091, 0.0],
              [-80.908972317881876, 35.651535011517716, 0.0],
              [-80.909308918600189, 35.651260011239636, 0.0],
              [-80.909448984925632, 35.650847677505702, 0.0],
              [-80.909420718728811, 35.650252277548105, 0.0],
              [-80.909729118353525, 35.649862811777851, 0.0],
              [-80.909757118234197, 35.649542211772484, 0.0],
              [-80.909925317746072, 35.649290278267699, 0.0],
              [-80.909953317670329, 35.649038278162926, 0.0],
              [-80.909335717653079, 35.648534678285678, 0.0],
              [-80.909195317983901, 35.648236878164418, 0.0],
              [-80.90874631867122, 35.647962211244746, 0.0],
              [-80.907988717846393, 35.647710477837165, 0.0],
              [-80.905771985068469, 35.647642411304773, 0.0],
              [-80.905014517896404, 35.647757078174216, 0.0],
              [-80.904818118264117, 35.647665477769529, 0.0],
              [-80.904846117731552, 35.647528078011305, 0.0],
              [-80.905491318276319, 35.647070011580283, 0.0],
              [-80.906248718652876, 35.646772011340055, 0.0],
              [-80.906388918363675, 35.646428410864431, 0.0],
              [-80.906416717665067, 35.64590161101335, 0.0],
              [-80.906697318567112, 35.645855811397141, 0.0],
              [-80.907398917943695, 35.646428078460907, 0.0],
              [-80.907707585130495, 35.646519611609811, 0.0],
              [-80.90871758531425, 35.646427811728714, 0.0],
              [-80.909306917647967, 35.646496277947954, 0.0],
              [-80.909755784934816, 35.646656477930037, 0.0],
              [-80.909896118651744, 35.646610611280238, 0.0],
              [-80.909952184678161, 35.646427411568553, 0.0],
              [-80.909671385049663, 35.646038211395769, 0.0],
              [-80.909474785065754, 35.645488611570649, 0.0],
              [-80.909783385004971, 35.645534211433684, 0.0],
              [-80.910821985403658, 35.646747677755222, 0.0],
              [-80.910878318533022, 35.646931011056466, 0.0],
              [-80.911102784472902, 35.647091211674159, 0.0],
              [-80.911523718391166, 35.647114011596486, 0.0],
              [-80.91180431847603, 35.647045211534419, 0.0],
              [-80.912365518583954, 35.647296878362489, 0.0],
              [-80.91289878428303, 35.647686077635782, 0.0],
              [-80.913095184257756, 35.647617211820936, 0.0],
              [-80.913094985236015, 35.647273677761952, 0.0],
              [-80.912842318705543, 35.646861610879824, 0.0],
              [-80.912842185150126, 35.646563811436302, 0.0],
              [-80.913459384883836, 35.646494877531325, 0.0],
              [-80.913767917625492, 35.64628881099992, 0.0],
              [-80.913936118048738, 35.645899411644542, 0.0],
              [-80.914047918201618, 35.645051811813317, 0.0],
              [-80.914160118449999, 35.644983077508257, 0.0],
              [-80.914721318429287, 35.644983011807938, 0.0],
              [-80.914889584706572, 35.644914210964288, 0.0],
              [-80.914917518161801, 35.644662211167443, 0.0],
              [-80.914720784747601, 35.644021077668405, 0.0],
              [-80.914860717944492, 35.643471411642913, 0.0],
              [-80.914103184593785, 35.643402878357243, 0.0],
              [-80.913906784412589, 35.643311410930892, 0.0],
              [-80.913794384310847, 35.643036611547195, 0.0],
              [-80.913261318474937, 35.642853477927524, 0.0],
              [-80.913092918615064, 35.642716077579372, 0.0],
              [-80.91258778525598, 35.642556011296591, 0.0],
              [-80.912559585081695, 35.642327011399964, 0.0],
              [-80.912699785143403, 35.642143811820091, 0.0],
              [-80.913204718287304, 35.641960411131798, 0.0],
              [-80.914214717806587, 35.641891210874839, 0.0],
              [-80.914860118513673, 35.641982678471919, 0.0],
              [-80.915561584329367, 35.642303077999436, 0.0],
              [-80.915870185211119, 35.642325811681175, 0.0],
              [-80.916234784997741, 35.642096678171576, 0.0],
              [-80.916234785223139, 35.641936410984357, 0.0],
              [-80.915701517594258, 35.641684611055233, 0.0],
              [-80.915701518031838, 35.641547211266079, 0.0],
              [-80.915813717762347, 35.641455611818472, 0.0],
              [-80.916739384737767, 35.641249078065144, 0.0],
              [-80.916767184986583, 35.640584878066811, 0.0],
              [-80.916374318380946, 35.640470611006151, 0.0],
              [-80.916458318265953, 35.640195678323494, 0.0],
              [-80.916906984678789, 35.639783277611265, 0.0],
              [-80.916906717870319, 35.639325211125914, 0.0],
              [-80.917186985365944, 35.638752611410133, 0.0],
              [-80.916962585200324, 35.638638210889475, 0.0],
              [-80.916401384497775, 35.638775810886713, 0.0],
              [-80.91558831862919, 35.639577678109951, 0.0],
              [-80.914802985417225, 35.639921478297325, 0.0],
              [-80.913456318669674, 35.640128011580622, 0.0],
              [-80.912979585276815, 35.640426011434975, 0.0],
              [-80.9121101847783, 35.641250811634286, 0.0],
              [-80.911857785318247, 35.641296610880381, 0.0],
              [-80.911829585251908, 35.641067611756625, 0.0],
              [-80.912250184403945, 35.640517811535467, 0.0],
              [-80.912249984717434, 35.640242878049769, 0.0],
              [-80.912081584841104, 35.640128477897449, 0.0],
              [-80.911548585028271, 35.64010567805871, 0.0],
              [-80.910763184797432, 35.640289278469034, 0.0],
              [-80.910763117804279, 35.64008321153603, 0.0],
              [-80.911099584524536, 35.639808078394871, 0.0],
              [-80.911941185369272, 35.639647477664482, 0.0],
              [-80.912081317884557, 35.639418477772132, 0.0],
              [-80.912389918254433, 35.639349678484571, 0.0],
              [-80.912894917632968, 35.639464077834802, 0.0],
              [-80.913343785183329, 35.639463877794888, 0.0],
              [-80.91438171856106, 35.639097010989559, 0.0],
              [-80.915251184831646, 35.638684477802045, 0.0],
              [-80.916064317685994, 35.637699477671404, 0.0],
              [-80.916092317593112, 35.637516277548826, 0.0],
              [-80.915783518654123, 35.637333078157972, 0.0],
              [-80.915503118591118, 35.637379077781389, 0.0],
              [-80.914773784874541, 35.637814478140001, 0.0],
              [-80.91435298544441, 35.637814611697763, 0.0],
              [-80.914829718524899, 35.637150278146628, 0.0],
              [-80.914829184939791, 35.63641727765178, 0.0],
              [-80.915979318415381, 35.636142078176043, 0.0],
              [-80.916287984794337, 35.636233678165411, 0.0],
              [-80.916512384822951, 35.636210677905588, 0.0],
              [-80.916708584442404, 35.635844211255133, 0.0],
              [-80.917213384937185, 35.635546278102524, 0.0],
              [-80.917774184398212, 35.634950477619817, 0.0],
              [-80.917689918516885, 35.634698611019353, 0.0],
              [-80.917549585185739, 35.634607078224036, 0.0],
              [-80.917212984603012, 35.634584277650546, 0.0],
              [-80.916792118283382, 35.634699011812671, 0.0],
              [-80.916511717803402, 35.634859411397983, 0.0],
              [-80.916118985421889, 35.634974011506493, 0.0],
              [-80.91603478443227, 35.634905410975435, 0.0],
              [-80.916062785000037, 35.634653478098386, 0.0],
              [-80.916258984712343, 35.634332677821, 0.0],
              [-80.916174718408058, 35.634172411262192, 0.0],
              [-80.915613518527579, 35.633989411045334, 0.0],
              [-80.915501318403102, 35.633806211502453, 0.0],
              [-80.915669518593333, 35.633714611640222, 0.0],
              [-80.916903918452519, 35.633759877890981, 0.0],
              [-80.917156184658552, 35.633576678508369, 0.0],
              [-80.917520984296999, 35.633439011536069, 0.0],
              [-80.917689117613008, 35.633278678223959, 0.0],
              [-80.917660718567788, 35.632477211707545, 0.0],
              [-80.917183384814862, 35.632065077694648, 0.0],
              [-80.916930984710319, 35.632111011431661, 0.0],
              [-80.916454518512737, 35.632683677585469, 0.0],
              [-80.916117918220849, 35.632844078226142, 0.0],
              [-80.916005517713671, 35.632340277968225, 0.0],
              [-80.915528384914722, 35.632111411584489, 0.0],
              [-80.915135784347513, 35.632363477889257, 0.0],
              [-80.914855518482938, 35.63273001134867, 0.0],
              [-80.914322584431162, 35.632821811229, 0.0],
              [-80.913481184599362, 35.633371811779, 0.0],
              [-80.913116917820602, 35.634150611850288, 0.0],
              [-80.912836518400042, 35.634379678211836, 0.0],
              [-80.912724185040304, 35.634288078306682, 0.0],
              [-80.912611718213398, 35.63360107842297, 0.0],
              [-80.912835917986939, 35.633120078022444, 0.0],
              [-80.912751585435231, 35.632914011178087, 0.0],
              [-80.912162385395931, 35.632799677922009, 0.0],
              [-80.912190185093891, 35.632501878068453, 0.0],
              [-80.912049917790384, 35.632295878404797, 0.0],
              [-80.912049785374649, 35.632135477812447, 0.0],
              [-80.913368317686036, 35.631974678446305, 0.0],
              [-80.913396185428027, 35.631768611362752, 0.0],
              [-80.914125385354779, 35.631493477621333, 0.0],
              [-80.914349717611458, 35.631264477682599, 0.0],
              [-80.914349585320309, 35.630989678470122, 0.0],
              [-80.914181185149275, 35.630783611023979, 0.0],
              [-80.91418111786686, 35.630600410874308, 0.0],
              [-80.914433385218928, 35.630371277716655, 0.0],
              [-80.914349184405012, 35.630165211700714, 0.0],
              [-80.913956318696535, 35.629844678185471, 0.0],
              [-80.913619717733724, 35.629684477646919, 0.0],
              [-80.913507317917961, 35.629524210956184, 0.0],
              [-80.913563384276983, 35.629432611465688, 0.0],
              [-80.913928117696543, 35.629386478149357, 0.0],
              [-80.914910117940295, 35.629890011810808, 0.0],
              [-80.915499385338208, 35.630325010953449, 0.0],
              [-80.91586418508524, 35.630485210942723, 0.0],
              [-80.916453318083896, 35.630485011228679, 0.0],
              [-80.916705718592553, 35.630347478216414, 0.0],
              [-80.916621385061362, 35.630095677850868, 0.0],
              [-80.916031985062304, 35.629591878461106, 0.0],
              [-80.916031785246901, 35.629133877758306, 0.0],
              [-80.916957518099565, 35.629133611487312, 0.0],
              [-80.917069584860556, 35.628950277966602, 0.0],
              [-80.916929117919679, 35.628446477543385, 0.0],
              [-80.916564184245175, 35.628080211003073, 0.0],
              [-80.91628358428089, 35.627919877928285, 0.0],
              [-80.916283517599339, 35.627668010926214, 0.0],
              [-80.91662011812538, 35.627645077679503, 0.0],
              [-80.917517918669404, 35.627850811020075, 0.0],
              [-80.918415584366528, 35.628171077729675, 0.0],
              [-80.918808384638055, 35.628193877610947, 0.0],
              [-80.919088718228394, 35.627804411198888, 0.0],
              [-80.919565517587358, 35.627529478214832, 0.0],
              [-80.920434784780525, 35.627277077689612, 0.0],
              [-80.920602984820377, 35.627162610910432, 0.0],
              [-80.920883385114465, 35.626750211291366, 0.0],
              [-80.920770984488357, 35.626521211314852, 0.0],
              [-80.920462318594957, 35.626383878038915, 0.0],
              [-80.919733118639698, 35.626361278182522, 0.0],
              [-80.919340385138739, 35.626498811494109, 0.0],
              [-80.918975985417362, 35.626819678033335, 0.0],
              [-80.918555318169325, 35.626911411421773, 0.0],
              [-80.918442717886606, 35.626293077752166, 0.0],
              [-80.919199718111074, 35.625743211166956, 0.0],
              [-80.919367917911543, 35.625445411166069, 0.0],
              [-80.919227517901078, 35.625147677961685, 0.0],
              [-80.918329717877228, 35.624850278215476, 0.0],
              [-80.917376118073577, 35.624827677772103, 0.0],
              [-80.917095384572505, 35.624575877620259, 0.0],
              [-80.917207518625233, 35.624461411541212, 0.0],
              [-80.918581918583925, 35.624392211769354, 0.0],
              [-80.918862384425836, 35.624277611370545, 0.0],
              [-80.920405118600286, 35.624116610970169, 0.0],
              [-80.921807717671868, 35.624413811300641, 0.0],
              [-80.922172385138126, 35.62441361089256, 0.0],
              [-80.922620985201107, 35.624207411764743, 0.0],
              [-80.922957384773312, 35.623932411705944, 0.0],
              [-80.922957318499726, 35.623817877666539, 0.0],
              [-80.922704785418631, 35.623634811138537, 0.0],
              [-80.921750985051006, 35.623451877571242, 0.0],
              [-80.921330118322615, 35.623245878339866, 0.0],
              [-80.921330117570918, 35.623131411360077, 0.0],
              [-80.922171318096716, 35.622581411789895, 0.0],
              [-80.922086784752963, 35.622100478375032, 0.0],
              [-80.922170984894777, 35.621986011046701, 0.0],
              [-80.92219878485291, 35.621550678358709, 0.0],
              [-80.921918117971089, 35.62132187791282, 0.0],
              [-80.921693518269024, 35.620932610935576, 0.0],
              [-80.920458985330924, 35.620268877962118, 0.0],
              [-80.919533317607389, 35.620131878299034, 0.0],
              [-80.919112584909399, 35.620338211669853, 0.0],
              [-80.918327584347708, 35.620979811805022, 0.0],
              [-80.917486318164364, 35.621071611187098, 0.0],
              [-80.91720591863151, 35.621209211317385, 0.0],
              [-80.91717811796326, 35.621781677667421, 0.0],
              [-80.917318518488571, 35.622262611110877, 0.0],
              [-80.917767584466347, 35.622903811836665, 0.0],
              [-80.917767718019434, 35.623041211276444, 0.0],
              [-80.917459318144296, 35.623178811490924, 0.0],
              [-80.917066585282797, 35.623087211555742, 0.0],
              [-80.916841985015409, 35.622789611458344, 0.0],
              [-80.916336985426511, 35.622537878314247, 0.0],
              [-80.916000185090098, 35.622148677804638, 0.0],
              [-80.915354984554682, 35.622034411668267, 0.0],
              [-80.915298784382401, 35.62182827772169, 0.0],
              [-80.915046185209249, 35.621484811110136, 0.0],
              [-80.914737585325227, 35.621393278490288, 0.0],
              [-80.914120718523179, 35.621714077646821, 0.0],
              [-80.913054984937432, 35.621943478352293, 0.0],
              [-80.912914785311742, 35.622195477853744, 0.0],
              [-80.912971184409514, 35.622745211419549, 0.0],
              [-80.913139585359588, 35.623019878284602, 0.0],
              [-80.913224118267919, 35.623752811474823, 0.0],
              [-80.91356091799841, 35.624187877753378, 0.0],
              [-80.91370158443695, 35.625126811850215, 0.0],
              [-80.913701718281288, 35.625310011216719, 0.0],
              [-80.913505385240342, 35.625332877835703, 0.0],
              [-80.913252784641543, 35.62512687821868, 0.0],
              [-80.912635384594751, 35.624440011663033, 0.0],
              [-80.912494985369975, 35.624142411115223, 0.0],
              [-80.91196178445766, 35.623661611634539, 0.0],
              [-80.91179338542517, 35.623341011840935, 0.0],
              [-80.911372518292978, 35.622951811580812, 0.0],
              [-80.910783517930795, 35.622997678029407, 0.0],
              [-80.910418717646195, 35.622906277796908, 0.0],
              [-80.909998184544691, 35.623135411661536, 0.0],
              [-80.909942384810748, 35.624120278290107, 0.0],
              [-80.909689984987295, 35.62418907773479, 0.0],
              [-80.909044784394126, 35.623914411618486, 0.0],
              [-80.908259518619147, 35.624304011506744, 0.0],
              [-80.907445918390948, 35.623983611792752, 0.0],
              [-80.907193585044155, 35.624075278386847, 0.0],
              [-80.907137517931645, 35.624327211807504, 0.0],
              [-80.90719371770868, 35.624464611486026, 0.0],
              [-80.907222117737817, 35.625128811599886, 0.0],
              [-80.906857584761298, 35.62581601125958, 0.0],
              [-80.906857718553866, 35.626045011393309, 0.0],
              [-80.907026118686133, 35.62622821147896, 0.0],
              [-80.906913985204369, 35.626686277696088, 0.0],
              [-80.906970318548062, 35.627098411414458, 0.0],
              [-80.907475517761384, 35.627625011796333, 0.0],
              [-80.909018785233769, 35.628838477865408, 0.0],
              [-80.909046984767102, 35.629044477592316, 0.0],
              [-80.908906718000907, 35.629113411647751, 0.0],
              [-80.907952784618317, 35.628815877544689, 0.0],
              [-80.907475917702286, 35.628655677854674, 0.0],
              [-80.906521984823669, 35.628129211486581, 0.0],
              [-80.906045118555838, 35.628152278348765, 0.0],
              [-80.905876918318754, 35.628335611107545, 0.0],
              [-80.905652718133823, 35.629137077541941, 0.0],
              [-80.905596918737203, 35.629870078165659, 0.0],
              [-80.905681117690079, 35.630099077555379, 0.0],
              [-80.906298517867697, 35.630648611673308, 0.0],
              [-80.906410784553429, 35.630923411493164, 0.0],
              [-80.906186517845029, 35.631198278301852, 0.0],
              [-80.906523518189843, 35.632114211274001, 0.0],
              [-80.906832518052084, 35.633121878362324, 0.0],
              [-80.906945185443149, 35.63410681129897, 0.0],
              [-80.906748784944511, 35.634198410976111, 0.0],
              [-80.906496185247576, 35.634015277896403, 0.0],
              [-80.906327718083318, 35.633534411197928, 0.0],
              [-80.906046984517445, 35.633213811306312, 0.0],
              [-80.905990785128409, 35.63298467825696, 0.0],
              [-80.905401517929846, 35.632595478209467, 0.0],
              [-80.904812185220408, 35.631977277593528, 0.0],
              [-80.904391318560698, 35.631794210955547, 0.0],
              [-80.903802318494456, 35.631909011684563, 0.0],
              [-80.903577984815485, 35.632046411495871, 0.0],
              [-80.903157385279485, 35.632848078491008, 0.0],
              [-80.902849118755185, 35.633695477778161, 0.0],
              [-80.90290538512194, 35.63419941151264, 0.0],
              [-80.902232384393614, 35.634863811329218, 0.0],
              [-80.902176384693007, 35.635230078196557, 0.0],
              [-80.901615584405363, 35.635780010921849, 0.0],
              [-80.901671918389724, 35.636238011686835, 0.0],
              [-80.901784118430626, 35.636398411306331, 0.0],
              [-80.901784384775667, 35.63719987816588, 0.0],
              [-80.902121185459436, 35.637703678116402, 0.0],
              [-80.90212131862944, 35.637978478129618, 0.0],
              [-80.901896784450884, 35.637978611080477, 0.0],
              [-80.901363718439754, 35.63777261092099, 0.0],
              [-80.900970784527601, 35.637131411822509, 0.0],
              [-80.900746184776523, 35.636925277616164, 0.0],
              [-80.90038151873523, 35.636765077848878, 0.0],
              [-80.900157117595128, 35.636856811185055, 0.0],
              [-80.900045185098477, 35.637864411514343, 0.0],
              [-80.899680717959754, 35.638528811682342, 0.0],
              [-80.899484517894948, 35.639124210871138, 0.0],
              [-80.899175984851055, 35.639628211543872, 0.0],
              [-80.899148184469809, 35.640544211577755, 0.0],
              [-80.899232517975705, 35.640773277648819, 0.0],
              [-80.898895785292368, 35.640727611142076, 0.0],
              [-80.898166118436279, 35.640063478234779, 0.0],
              [-80.897689318476083, 35.64008661170822, 0.0],
              [-80.897156185197119, 35.64026987758524, 0.0],
              [-80.896847718462254, 35.640613477919878, 0.0],
              [-80.896735584965555, 35.640957011540372, 0.0],
              [-80.896791784678399, 35.641346278474408, 0.0],
              [-80.897296985404537, 35.641895811341094, 0.0],
              [-80.897353184464947, 35.64217067823666, 0.0],
              [-80.896792118219494, 35.64262887756167, 0.0],
              [-80.896511584978171, 35.642720477808119, 0.0],
              [-80.89550151764557, 35.642606211381604, 0.0],
              [-80.895539385419724, 35.642482478416667, 0.0],
              [-80.895557584283466, 35.642423011645946, 0.0],
              [-80.896034518583704, 35.641964877864574, 0.0],
              [-80.896034384616129, 35.641506878490532, 0.0],
              [-80.895893985314672, 35.641323678197786, 0.0],
              [-80.895949918323439, 35.640155611665293, 0.0],
              [-80.895725317914483, 35.639857811668406, 0.0],
              [-80.895725317721698, 35.639674611586408, 0.0],
              [-80.8972681843495, 35.639468277618157, 0.0],
              [-80.897829185320205, 35.639147411591786, 0.0],
              [-80.898361984933985, 35.638048078477219, 0.0],
              [-80.898866918305529, 35.637612811514707, 0.0],
              [-80.899175317692126, 35.637177611762546, 0.0],
              [-80.899679918582692, 35.635986611277524, 0.0],
              [-80.899371185283769, 35.635734677883306, 0.0],
              [-80.898304985021142, 35.635185277706526, 0.0],
              [-80.897940184873875, 35.635162411220612, 0.0],
              [-80.897715785301443, 35.635070877539533, 0.0],
              [-80.897687718547942, 35.63486481171941, 0.0],
              [-80.897238584578076, 35.634383878501424, 0.0],
              [-80.89726671767491, 35.634177811769561, 0.0],
              [-80.897631385447369, 35.634177677755808, 0.0],
              [-80.898080318089669, 35.634658477536924, 0.0],
              [-80.898921918122255, 35.634589678478413, 0.0],
              [-80.899875918473171, 35.634887211402834, 0.0],
              [-80.900100185132359, 35.634795477595731, 0.0],
              [-80.900156318639077, 35.634589411595442, 0.0],
              [-80.900689117659425, 35.633833411222589, 0.0],
              [-80.900716985353881, 35.633283810921512, 0.0],
              [-80.901081384524659, 35.632527878433564, 0.0],
              [-80.900912918709082, 35.632069877881335, 0.0],
              [-80.900632318152617, 35.631818010934403, 0.0],
              [-80.900211518550137, 35.631634877625878, 0.0],
              [-80.898808918205418, 35.631543677645382, 0.0],
              [-80.898444184824072, 35.631406277559584, 0.0],
              [-80.898387984551476, 35.631223078307464, 0.0],
              [-80.89981858521611, 35.631154011516159, 0.0],
              [-80.900351517790753, 35.630879078512471, 0.0],
              [-80.9020627180752, 35.630924610909787, 0.0],
              [-80.902315184562951, 35.630764211130803, 0.0],
              [-80.902371118011345, 35.630306011306516, 0.0],
              [-80.902258918163511, 35.630122811740208, 0.0],
              [-80.901389118739672, 35.629550477811847, 0.0],
              [-80.90122071871015, 35.629344411641505, 0.0],
              [-80.901276717702714, 35.629252811187683, 0.0],
              [-80.902538984549565, 35.629229611290882, 0.0],
              [-80.902875585174101, 35.629137878166503, 0.0],
              [-80.903211984694536, 35.628519478028622, 0.0],
              [-80.90321178474899, 35.627832278041488, 0.0],
              [-80.9029311843355, 35.627557610938865, 0.0],
              [-80.902313984640429, 35.627420411700292, 0.0],
              [-80.902285918232621, 35.627168411179646, 0.0],
              [-80.902201785453883, 35.627122611358644, 0.0],
              [-80.902201718148817, 35.626985211436605, 0.0],
              [-80.902818585318869, 35.626687277897389, 0.0],
              [-80.903014917998675, 35.626275078234983, 0.0],
              [-80.902790318448197, 35.625977411665353, 0.0],
              [-80.902425718520519, 35.625817078084239, 0.0],
              [-80.902117118248583, 35.625794277665662, 0.0],
              [-80.901584118440169, 35.625908878025967, 0.0],
              [-80.901584185324197, 35.625634077923614, 0.0],
              [-80.902229118348387, 35.625336278438297, 0.0],
              [-80.902341385471004, 35.625130078285778, 0.0],
              [-80.902313184852034, 35.624786610899037, 0.0],
              [-80.903014318714511, 35.62462607757768, 0.0],
              [-80.903378784880772, 35.624236611695117, 0.0],
              [-80.903631118466635, 35.62368687761564, 0.0],
              [-80.90357491802493, 35.623320478074042, 0.0],
              [-80.903238318282988, 35.623137278162076, 0.0],
              [-80.902649185343805, 35.623343611598742, 0.0],
              [-80.902648985334054, 35.622839811209396, 0.0],
              [-80.902424584608397, 35.62265661127379, 0.0],
              [-80.901947717801718, 35.62247347849646, 0.0],
              [-80.901470917968808, 35.622565211200708, 0.0],
              [-80.901190384939554, 35.622427811858842, 0.0],
              [-80.900769585263404, 35.622382077915702, 0.0],
              [-80.900545185172277, 35.622565411057906, 0.0],
              [-80.900377118228789, 35.622954877964553, 0.0],
              [-80.900265118191015, 35.623687677794699, 0.0],
              [-80.899816384852031, 35.623664878497799, 0.0],
              [-80.899675918413507, 35.623046611449112, 0.0],
              [-80.899760117858605, 35.622932078300259, 0.0],
              [-80.899787785243674, 35.622176277909645, 0.0],
              [-80.899507317613953, 35.621878611075523, 0.0],
              [-80.899394984523013, 35.621443410915511, 0.0],
              [-80.899086318760837, 35.621054211622095, 0.0],
              [-80.898076518558497, 35.620985610999668, 0.0],
              [-80.897711785443889, 35.620688011553668, 0.0],
              [-80.897207117844744, 35.621077478097007, 0.0],
              [-80.896898717651794, 35.621993678426939, 0.0],
              [-80.896590585011339, 35.623551078329527, 0.0],
              [-80.896394385130463, 35.623711478011828, 0.0],
              [-80.896225984972475, 35.623574011223837, 0.0],
              [-80.895917184533971, 35.622978677751661, 0.0],
              [-80.895187918058525, 35.622841411601478, 0.0],
              [-80.895159918101697, 35.622589478502583, 0.0],
              [-80.895832917933291, 35.622108278379777, 0.0],
              [-80.896113318585023, 35.621741878108317, 0.0],
              [-80.896169318679554, 35.6213296116225, 0.0],
              [-80.895944918029628, 35.621169411446964, 0.0],
              [-80.895299718164395, 35.621077811141923, 0.0],
              [-80.895299718154604, 35.620940411104264, 0.0],
              [-80.895860518733755, 35.620344877910455, 0.0],
              [-80.895916518288118, 35.620001277893742, 0.0],
              [-80.89563591869728, 35.619543277589081, 0.0],
              [-80.895495584465223, 35.619428877964282, 0.0],
              [-80.8949347185183, 35.619406011684369, 0.0],
              [-80.894766385009561, 35.619314478041439, 0.0],
              [-80.892886985138759, 35.619085810985212, 0.0],
              [-80.892438384576579, 35.619406411824002, 0.0],
              [-80.892410517948832, 35.62002481120301, 0.0],
              [-80.89252278497041, 35.620597411477412, 0.0],
              [-80.892747184633023, 35.621009611569789, 0.0],
              [-80.892466718736983, 35.621078411631046, 0.0],
              [-80.891989917869481, 35.620689078073205, 0.0],
              [-80.891568984778047, 35.619933411772969, 0.0],
              [-80.891007985427407, 35.619475411739202, 0.0],
              [-80.890615185019854, 35.619361011592282, 0.0],
              [-80.890418785166162, 35.619223611229103, 0.0],
              [-80.890390718276379, 35.618399011331341, 0.0],
              [-80.890530717716786, 35.617414211854872, 0.0],
              [-80.889745384345716, 35.6172540115206, 0.0],
              [-80.889577184474092, 35.617712077694449, 0.0],
              [-80.889381118275594, 35.61890307757831, 0.0],
              [-80.889184784700205, 35.619246611553557, 0.0],
              [-80.888511584671377, 35.619338278067943, 0.0],
              [-80.888231184818693, 35.619521478521996, 0.0],
              [-80.88778238529909, 35.619659011087769, 0.0],
              [-80.887473784936546, 35.619361278406338, 0.0],
              [-80.887053118615398, 35.619178211788977, 0.0],
              [-80.886632384397231, 35.619109411835659, 0.0],
              [-80.886043517897363, 35.619750877642062, 0.0],
              [-80.88590331817376, 35.620048611292958, 0.0],
              [-80.885847184853858, 35.620781411845854, 0.0],
              [-80.885987518671797, 35.620918878257768, 0.0],
              [-80.88595951765717, 35.621262411548599, 0.0],
              [-80.885594918807143, 35.621583078272096, 0.0],
              [-80.885594917823795, 35.621766277962713, 0.0],
              [-80.885819385333235, 35.62204121176098, 0.0],
              [-80.88581938429634, 35.622613678122327, 0.0],
              [-80.886324518395682, 35.623529811679525, 0.0],
              [-80.885987784969842, 35.623575611573685, 0.0],
              [-80.884977984317288, 35.623048878302903, 0.0],
              [-80.884529185398705, 35.623575811827223, 0.0],
              [-80.884473385404348, 35.624949877825678, 0.0],
              [-80.884613584666852, 35.625133211825734, 0.0],
              [-80.884866118479167, 35.625408011092198, 0.0],
              [-80.884922185461406, 35.625591078312716, 0.0],
              [-80.884641718511702, 35.625636878077664, 0.0],
              [-80.884361184686867, 35.625545411856038, 0.0],
              [-80.88391238457065, 35.62556827771192, 0.0],
              [-80.883884384384245, 35.626324077595264, 0.0],
              [-80.883491784659356, 35.626873878092205, 0.0],
              [-80.88346378434143, 35.627446411141726, 0.0],
              [-80.883576117920825, 35.62801901170301, 0.0],
              [-80.883491918094251, 35.628156411250821, 0.0],
              [-80.883239517712155, 35.628225078210974, 0.0],
              [-80.883127185065419, 35.628110611728992, 0.0],
              [-80.883127185172128, 35.627950277838188, 0.0],
              [-80.88265031850591, 35.627286078013555, 0.0],
              [-80.882622185102804, 35.626393011527107, 0.0],
              [-80.882453918212363, 35.626232611236844, 0.0],
              [-80.882425784969953, 35.626026611142713, 0.0],
              [-80.882930584493181, 35.62561421138831, 0.0],
              [-80.883070784433698, 35.625133211496582, 0.0],
              [-80.883295185473344, 35.624079811745098, 0.0],
              [-80.882930518180189, 35.623759078443733, 0.0],
              [-80.88293038486519, 35.623484278324852, 0.0],
              [-80.883238918575245, 35.622980410921407, 0.0],
              [-80.883266918145139, 35.621972677606166, 0.0],
              [-80.883743717931054, 35.621171077569848, 0.0],
              [-80.883771584748672, 35.620415277703913, 0.0],
              [-80.884051984574242, 35.619613611158869, 0.0],
              [-80.884108117778908, 35.619132611451533, 0.0],
              [-80.883939718270966, 35.618880678405091, 0.0],
              [-80.883462918178537, 35.618560211213733, 0.0],
              [-80.883126384491447, 35.618537277847537, 0.0],
              [-80.882705584545448, 35.618766278444063, 0.0],
              [-80.882565384637928, 35.618972478368676, 0.0],
              [-80.882481317829743, 35.619338878196977, 0.0],
              [-80.882116718056082, 35.620117677764107, 0.0],
              [-80.881948517815147, 35.620255077688761, 0.0],
              [-80.88175211874713, 35.620255078054264, 0.0],
              [-80.881135117806707, 35.620117811798657, 0.0],
              [-80.880602118444529, 35.619820077991115, 0.0],
              [-80.880237517802598, 35.619774278118761, 0.0],
              [-80.88018138543913, 35.61988867811899, 0.0],
              [-80.880321718533494, 35.621217211427009, 0.0],
              [-80.880153518773199, 35.621308811820874, 0.0],
              [-80.879985118389257, 35.621217211632043, 0.0],
              [-80.879844917956802, 35.620827811409022, 0.0],
              [-80.879480185031326, 35.620438611823687, 0.0],
              [-80.87903138454098, 35.620232411390965, 0.0],
              [-80.878694918562317, 35.619957611675076, 0.0],
              [-80.87818991880637, 35.619705677609346, 0.0],
              [-80.877713118106797, 35.61986601095154, 0.0],
              [-80.877292384365248, 35.620163811535718, 0.0],
              [-80.877152184488864, 35.620415678452886, 0.0],
              [-80.877039984773148, 35.621079877881172, 0.0],
              [-80.876366785239867, 35.621286011774743, 0.0],
              [-80.876394785093723, 35.622225011039141, 0.0],
              [-80.87569371840371, 35.622591477773184, 0.0],
              [-80.875525384684565, 35.622980810956037, 0.0],
              [-80.874796118061823, 35.623347278127639, 0.0],
              [-80.874066785342663, 35.623759611650904, 0.0],
              [-80.873617984787373, 35.624126011036019, 0.0],
              [-80.873477717911172, 35.624332011262034, 0.0],
              [-80.873505784444347, 35.624835878489975, 0.0],
              [-80.873730117980912, 35.625133677605234, 0.0],
              [-80.874066718644002, 35.625431411238175, 0.0],
              [-80.874150917832694, 35.625637478122378, 0.0],
              [-80.87409478488803, 35.625912410920414, 0.0],
              [-80.873673984972982, 35.626187211118193, 0.0],
              [-80.87325331859094, 35.626187211201305, 0.0],
              [-80.871850718259864, 35.625935277631527, 0.0],
              [-80.871373918321723, 35.626049810979914, 0.0],
              [-80.870981184522847, 35.626278810982711, 0.0],
              [-80.870756784993716, 35.626210077717808, 0.0],
              [-80.870308118215604, 35.625729078365474, 0.0],
              [-80.869971518716497, 35.625591677995075, 0.0],
              [-80.869550718109792, 35.625683410891625, 0.0],
              [-80.869466517969002, 35.625774878418405, 0.0],
              [-80.869017717654089, 35.625912411503535, 0.0],
              [-80.86831638472573, 35.62600387832839, 0.0],
              [-80.867951718326367, 35.626210011818827, 0.0],
              [-80.86778338524428, 35.626461877846175, 0.0],
              [-80.866941918545663, 35.626461811169079, 0.0],
              [-80.866745517950477, 35.626736811183299, 0.0],
              [-80.86674551789892, 35.626874211237066, 0.0],
              [-80.867054118695052, 35.627103211766872, 0.0],
              [-80.866492917780036, 35.627996411632111, 0.0],
              [-80.86646471873172, 35.629187277639282, 0.0],
              [-80.866212318726454, 35.62950801127954, 0.0],
              [-80.86621218450118, 35.629668277575377, 0.0],
              [-80.866436585101567, 35.629897277544238, 0.0],
              [-80.866520718274828, 35.630332411602375, 0.0],
              [-80.867109718711461, 35.630492810941938, 0.0],
              [-80.867221984577768, 35.630607278072411, 0.0],
              [-80.867221984370332, 35.630790477633496, 0.0],
              [-80.866492584820961, 35.630973678294438, 0.0],
              [-80.866324185239989, 35.631065277608556, 0.0],
              [-80.866295984715663, 35.632462411471842, 0.0],
              [-80.866436317671742, 35.632691411220129, 0.0],
              [-80.866520384399124, 35.632828811059262, 0.0],
              [-80.866520385446009, 35.633218078099809, 0.0],
              [-80.866127718788945, 35.63363041151073, 0.0],
              [-80.866043384694365, 35.634157078045384, 0.0],
              [-80.866267717672756, 35.634821277920359, 0.0],
              [-80.866295784932333, 35.635210678302379, 0.0],
              [-80.866379918300964, 35.635302277578681, 0.0],
              [-80.866351718101413, 35.636218411767416, 0.0],
              [-80.866211517887592, 35.636218411789677, 0.0],
              [-80.865958918106685, 35.636378677836568, 0.0],
              [-80.865678384800802, 35.636424478399746, 0.0],
              [-80.865257718798915, 35.635645811160011, 0.0],
              [-80.865201584437841, 35.635050411649779, 0.0],
              [-80.865117584362253, 35.634912811639609, 0.0],
              [-80.864696785000987, 35.63472961183389, 0.0],
              [-80.864472384461664, 35.634500611795623, 0.0],
              [-80.86410758444535, 35.634477677548354, 0.0],
              [-80.863939584439549, 35.632668277903662, 0.0],
              [-80.864023784380208, 35.632462211023501, 0.0],
              [-80.864500718602386, 35.632118810987777, 0.0],
              [-80.864500784475155, 35.63182101093161, 0.0],
              [-80.8643043846848, 35.631614877566797, 0.0],
              [-80.864304518237631, 35.630904877958635, 0.0],
              [-80.864641185073552, 35.630492611695281, 0.0],
              [-80.864669318206595, 35.630240677939462, 0.0],
              [-80.864444918275296, 35.6299886782747, 0.0],
              [-80.864416918570583, 35.629141277690834, 0.0],
              [-80.864304718123847, 35.629072677691624, 0.0],
              [-80.863238784478199, 35.62925581142175, 0.0],
              [-80.863042517690829, 35.628980877839254, 0.0],
              [-80.862930317902524, 35.628499877629444, 0.0],
              [-80.862818118439279, 35.628431278237272, 0.0],
              [-80.862818118619074, 35.628429077941881, 0.0],
              [-80.86281818512019, 35.627996078053059, 0.0],
              [-80.863070785216564, 35.627469411793214, 0.0],
              [-80.863547717794063, 35.627171611050827, 0.0],
              [-80.863884385242983, 35.626805278141568, 0.0],
              [-80.864361184981092, 35.626576278205583, 0.0],
              [-80.865146584439216, 35.626484678348376, 0.0],
              [-80.866100318166872, 35.62664507760114, 0.0],
              [-80.866464984427438, 35.626209878316295, 0.0],
              [-80.866184584363197, 35.625958011261019, 0.0],
              [-80.866409118118341, 35.625454211180383, 0.0],
              [-80.866409118368836, 35.62513361126652, 0.0],
              [-80.866409185055829, 35.624675477965269, 0.0],
              [-80.866857984917246, 35.623988411043648, 0.0],
              [-80.867475184395218, 35.623667811473595, 0.0],
              [-80.867615384474504, 35.623530411437429, 0.0],
              [-80.867503317685163, 35.623095211125872, 0.0],
              [-80.867362985342353, 35.622912010949399, 0.0],
              [-80.867306984811421, 35.622087478131327, 0.0],
              [-80.866914385349901, 35.621263077834186, 0.0],
              [-80.866381517810552, 35.620713411352838, 0.0],
              [-80.866381584751309, 35.62048427829798, 0.0],
              [-80.866549784507839, 35.62043847817143, 0.0],
              [-80.867279117916027, 35.620919478467869, 0.0],
              [-80.867699785048856, 35.621079811636065, 0.0],
              [-80.868344785327508, 35.622087611626128, 0.0],
              [-80.868569118837584, 35.622728877822794, 0.0],
              [-80.869691118685907, 35.623599211494827, 0.0],
              [-80.871570318158319, 35.623874011675277, 0.0],
              [-80.87168251816982, 35.623782411442392, 0.0],
              [-80.87187891786445, 35.622866277983562, 0.0],
              [-80.872243718560142, 35.622522811604547, 0.0],
              [-80.872467985448083, 35.622156411512506, 0.0],
              [-80.872468117795762, 35.621606677666684, 0.0],
              [-80.87286071767339, 35.62091961094761, 0.0],
              [-80.872888785420457, 35.620644811775087, 0.0],
              [-80.874263184433204, 35.619889011343496, 0.0],
              [-80.87459978510627, 35.619339211628194, 0.0],
              [-80.874711918387547, 35.618949877735162, 0.0],
              [-80.874627784335502, 35.618560611476312, 0.0],
              [-80.874487584934542, 35.618377411194864, 0.0],
              [-80.873702184545493, 35.61801101125107, 0.0],
              [-80.873730318747505, 35.617873477956103, 0.0],
              [-80.873954718219665, 35.617759078141304, 0.0],
              [-80.874796118580406, 35.617644477825579, 0.0],
              [-80.875020518276344, 35.617598811338617, 0.0],
              [-80.875076584954073, 35.617507077885655, 0.0],
              [-80.875020584368968, 35.617255211560185, 0.0],
              [-80.874796118265166, 35.617071877709009, 0.0],
              [-80.874319318589457, 35.616705477648473, 0.0],
              [-80.873534117837977, 35.616407878042075, 0.0],
              [-80.873477918047897, 35.616316211057786, 0.0],
              [-80.873477918825088, 35.616155811803004, 0.0],
              [-80.87479618537219, 35.616201611695985, 0.0],
              [-80.876002184414418, 35.616499278100839, 0.0],
              [-80.876226518069714, 35.616178810902277, 0.0],
              [-80.877067985023572, 35.615903877986916, 0.0],
              [-80.877376517624739, 35.61569767767098, 0.0],
              [-80.877572717958827, 35.615399878227969, 0.0],
              [-80.877825184579208, 35.615262477907585, 0.0],
              [-80.878666584508494, 35.615445811324612, 0.0],
              [-80.878975118299138, 35.615743411086292, 0.0],
              [-80.879367718276598, 35.615812211433187, 0.0],
              [-80.879732318808976, 35.615468611543406, 0.0],
              [-80.880096984714854, 35.615285277730216, 0.0],
              [-80.880433518370566, 35.614712810941349, 0.0],
              [-80.880854117792339, 35.614392077820114, 0.0],
              [-80.88203211772354, 35.614048477930133, 0.0],
              [-80.882761184460193, 35.6133842114793, 0.0],
              [-80.882845318786579, 35.61310941167357, 0.0],
              [-80.882705117814268, 35.612926078392448, 0.0],
              [-80.882592918431897, 35.612124611036151, 0.0],
              [-80.882396518381526, 35.611804011677215, 0.0],
              [-80.882172118071452, 35.611643677948621, 0.0],
              [-80.881499118450392, 35.611712411466129, 0.0],
              [-80.881555117766226, 35.611277278258193, 0.0],
              [-80.881695318457972, 35.611071211097162, 0.0],
              [-80.881723317883115, 35.610750611627914, 0.0],
              [-80.881134318307545, 35.61054441092125, 0.0],
              [-80.881274517786935, 35.610178011568465, 0.0],
              [-80.881358718733765, 35.609720011415178, 0.0],
              [-80.881190384849617, 35.609422211127402, 0.0],
              [-80.880769718059454, 35.609010011616753, 0.0],
              [-80.880545317770441, 35.608460277898409, 0.0],
              [-80.879844117917202, 35.608116811436005, 0.0],
              [-80.879143118240009, 35.608071077599135, 0.0],
              [-80.878554118041052, 35.607933678167676, 0.0],
              [-80.877824918791688, 35.608139810965966, 0.0],
              [-80.877460384484849, 35.607933811877658, 0.0],
              [-80.877067785071432, 35.608002411802637, 0.0],
              [-80.876647118466181, 35.608277278442024, 0.0],
              [-80.875216784509803, 35.608483477809955, 0.0],
              [-80.874796117756858, 35.608346077769397, 0.0],
              [-80.874459718107289, 35.608300278178248, 0.0],
              [-80.874179185185497, 35.608414811112674, 0.0],
              [-80.873898785414326, 35.608735411649342, 0.0],
              [-80.873337917965898, 35.608872810975157, 0.0],
              [-80.872636718517612, 35.609422411616968, 0.0],
              [-80.872047784425931, 35.609514211363333, 0.0],
              [-80.870757784324269, 35.609285011893093, 0.0],
              [-80.869776184928995, 35.608895677794735, 0.0],
              [-80.869467718694409, 35.608552078433398, 0.0],
              [-80.86896298538727, 35.608208611331222, 0.0],
              [-80.868906918111193, 35.608048277731683, 0.0],
              [-80.869243517927416, 35.608025411333891, 0.0],
              [-80.870982118065697, 35.608620878455504, 0.0],
              [-80.871543118435525, 35.608643678323652, 0.0],
              [-80.871907585183038, 35.608346011800762, 0.0],
              [-80.871991784618913, 35.607865078274394, 0.0],
              [-80.87232831837899, 35.607681811650039, 0.0],
              [-80.872636784802722, 35.607269677601181, 0.0],
              [-80.873590318428441, 35.606994811262304, 0.0],
              [-80.874375585397132, 35.607223811464763, 0.0],
              [-80.874796185491633, 35.60710927836918, 0.0],
              [-80.875272917942411, 35.607063477629346, 0.0],
              [-80.87600211780159, 35.607178011472222, 0.0],
              [-80.876254518363297, 35.607017611154411, 0.0],
              [-80.876450784726629, 35.606399278412631, 0.0],
              [-80.876983585175111, 35.605918278426628, 0.0],
              [-80.877179918591949, 35.605620611593217, 0.0],
              [-80.877039584536462, 35.60484181100108, 0.0],
              [-80.876899518550147, 35.604590011584449, 0.0],
              [-80.876815318134774, 35.604200611215134, 0.0],
              [-80.876927518695496, 35.603971611832357, 0.0],
              [-80.876759185034388, 35.603307411707021, 0.0],
              [-80.877011518462055, 35.603284477800862, 0.0],
              [-80.877263985490757, 35.603536411821651, 0.0],
              [-80.877488318761621, 35.604200611553047, 0.0],
              [-80.878413784515942, 35.605437277824912, 0.0],
              [-80.878638118698774, 35.605918210941923, 0.0],
              [-80.878862584619441, 35.606147211683549, 0.0],
              [-80.879619717960679, 35.606238878396177, 0.0],
              [-80.881722985419344, 35.606719611573929, 0.0],
              [-80.881919184731558, 35.606559210955588, 0.0],
              [-80.8824521182853, 35.606467611504357, 0.0],
              [-80.882956917985865, 35.606559211765472, 0.0],
              [-80.883377518604973, 35.606490478400474, 0.0],
              [-80.883573784770761, 35.606192677664183, 0.0],
              [-80.884555318158405, 35.605826211287152, 0.0],
              [-80.884835718463023, 35.605253611431458, 0.0],
              [-80.885368518433609, 35.604726811026012, 0.0],
              [-80.885676784933494, 35.604245811056138, 0.0],
              [-80.885424384441208, 35.603535877835498, 0.0],
              [-80.885648717991302, 35.60349001131712, 0.0],
              [-80.8863217182803, 35.60369601105846, 0.0],
              [-80.886602317975942, 35.604016678127579, 0.0],
              [-80.886966785361338, 35.604199811259427, 0.0],
              [-80.888032384540693, 35.604085211154761, 0.0],
              [-80.888032385379944, 35.604199677999013, 0.0],
              [-80.886938917911792, 35.604955677960319, 0.0],
              [-80.886854785381175, 35.60516181092698, 0.0],
              [-80.886125718713586, 35.605688610986846, 0.0],
              [-80.885508917795164, 35.606284211158709, 0.0],
              [-80.884919985071789, 35.607314811192232, 0.0],
              [-80.884920117724391, 35.608414078339614, 0.0],
              [-80.885396985026105, 35.609398877743395, 0.0],
              [-80.885733584631794, 35.609742477796487, 0.0],
              [-80.886098184462668, 35.609879811486749, 0.0],
              [-80.886266584707869, 35.610063078373045, 0.0],
              [-80.88671518539951, 35.610177478425236, 0.0],
              [-80.887191985382344, 35.610040011199935, 0.0],
              [-80.888818585349554, 35.609948211742783, 0.0],
              [-80.888846585451873, 35.610108478539338, 0.0],
              [-80.887388385364545, 35.610818677949808, 0.0],
              [-80.887107984926573, 35.611116478468524, 0.0],
              [-80.887108184927243, 35.6116202780292, 0.0],
              [-80.887220318403919, 35.611711877700863, 0.0],
              [-80.88733258521178, 35.612490478000097, 0.0],
              [-80.887697318176009, 35.612765278125778, 0.0],
              [-80.887809517953713, 35.613521078527469, 0.0],
              [-80.888061984291568, 35.613910411278219, 0.0],
              [-80.888146184825217, 35.614231078101817, 0.0],
              [-80.888370585246491, 35.614483010918271, 0.0],
              [-80.888847518513245, 35.614689078357557, 0.0],
              [-80.889324317631292, 35.615169878171677, 0.0],
              [-80.889772985297839, 35.61523847804699, 0.0],
              [-80.890249918811051, 35.615124011803289, 0.0],
              [-80.890277918668176, 35.615375878198115, 0.0],
              [-80.890081784638355, 35.616544011862111, 0.0],
              [-80.89069891785293, 35.616681411168344, 0.0],
              [-80.89109138461896, 35.615971211256081, 0.0],
              [-80.891848717836709, 35.615925411785263, 0.0],
              [-80.892157318008827, 35.616314611436174, 0.0],
              [-80.892605984534825, 35.616497811614465, 0.0],
              [-80.893138918545318, 35.616406077581836, 0.0],
              [-80.893643785294501, 35.616566278147189, 0.0],
              [-80.893812118807716, 35.616337278028425, 0.0],
              [-80.893755784480732, 35.615673211155602, 0.0],
              [-80.894036117644802, 35.615237877967189, 0.0],
              [-80.894091985206032, 35.614367611044962, 0.0],
              [-80.893923784398936, 35.614184411882917, 0.0],
              [-80.894007718220649, 35.613657611727916, 0.0],
              [-80.894204118404033, 35.613565877634919, 0.0],
              [-80.894428385295058, 35.613657611829233, 0.0],
              [-80.894512784619877, 35.614321678516269, 0.0],
              [-80.894737184820997, 35.614573611729973, 0.0],
              [-80.894765384346783, 35.615283478027671, 0.0],
              [-80.895045918145911, 35.61558121092834, 0.0],
              [-80.895073984839954, 35.61587901161829, 0.0],
              [-80.894961918342005, 35.616153877815549, 0.0],
              [-80.895074318276656, 35.616611877614645, 0.0],
              [-80.895270584911316, 35.616795077657734, 0.0],
              [-80.895494918239237, 35.616795077560617, 0.0],
              [-80.89574731781282, 35.616336878381311, 0.0],
              [-80.895803184944825, 35.615535278239527, 0.0],
              [-80.895999385417568, 35.615443677650681, 0.0],
              [-80.896279985062293, 35.61569561158759, 0.0],
              [-80.896476518515158, 35.6163596117484, 0.0],
              [-80.896700984746971, 35.616634411774477, 0.0],
              [-80.897065584637161, 35.616840478324733, 0.0],
              [-80.89863631771594, 35.617137877682197, 0.0],
              [-80.898944918569143, 35.617344011144347, 0.0],
              [-80.898945185129875, 35.61803101124611, 0.0],
              [-80.8994221182475, 35.618397411050736, 0.0],
              [-80.899534384404106, 35.619061477741781, 0.0],
              [-80.900095385013174, 35.618969811601836, 0.0],
              [-80.900600384495888, 35.619450611722741, 0.0],
              [-80.901385918335222, 35.619656611482483, 0.0],
              [-80.901666318673108, 35.619542011295628, 0.0],
              [-80.902227185255796, 35.619473211834794, 0.0],
              [-80.902844517692017, 35.62004547811285, 0.0],
              [-80.903180985199484, 35.620137077982776, 0.0],
              [-80.904106717829819, 35.620114011841423, 0.0],
              [-80.904667584464789, 35.6199764783061, 0.0],
              [-80.90483571806125, 35.619541211459783, 0.0],
              [-80.905228317711661, 35.619151678012258, 0.0],
              [-80.905312185338786, 35.618716611119154, 0.0],
              [-80.905648584973463, 35.618281277705648, 0.0],
              [-80.905788584424172, 35.617640078245934, 0.0],
              [-80.905788184588502, 35.61665521148376, 0.0],
              [-80.906152785223398, 35.616563478042103, 0.0],
              [-80.90634918449777, 35.616586278010928, 0.0],
              [-80.906741984446086, 35.617021411257433, 0.0],
              [-80.906994517849867, 35.617158811846039, 0.0],
              [-80.907218918411701, 35.617158677580115, 0.0],
              [-80.907863784496783, 35.616837878014337, 0.0],
              [-80.908228318523228, 35.616471411291833, 0.0],
              [-80.908284185291109, 35.616082011174676, 0.0],
              [-80.907470785174183, 35.615830277751911, 0.0],
              [-80.906096184245953, 35.615143611018581, 0.0],
              [-80.905759718296849, 35.615166611550812, 0.0],
              [-80.904665985037013, 35.615556077793521, 0.0],
              [-80.90466591806647, 35.615166810969512, 0.0],
              [-80.90458171785059, 35.615098078267316, 0.0],
              [-80.904861984437673, 35.614525478470675, 0.0],
              [-80.904385118140254, 35.614342477844573, 0.0],
              [-80.904637318509131, 35.61399887801813, 0.0],
              [-80.904721385353625, 35.613724011723122, 0.0],
              [-80.904581118654519, 35.613472077661804, 0.0],
              [-80.904384718056448, 35.613334810997294, 0.0],
              [-80.903711517649, 35.613220411719723, 0.0],
              [-80.903515185267864, 35.613083078470254, 0.0],
              [-80.903430984766075, 35.612854077878218, 0.0],
              [-80.902982117725614, 35.61244187815867, 0.0],
              [-80.902168318400541, 35.611319811819364, 0.0],
              [-80.901831585361052, 35.610632811092849, 0.0],
              [-80.902027984888846, 35.610541211057544, 0.0],
              [-80.902448717779066, 35.610930411845565, 0.0],
              [-80.903486918024072, 35.612281410983989, 0.0],
              [-80.903963718298058, 35.612487411857792, 0.0],
              [-80.904524717936496, 35.612556011231113, 0.0],
              [-80.904748918375162, 35.612372677721794, 0.0],
              [-80.904888984932185, 35.61184587802974, 0.0],
              [-80.905225385412578, 35.611731278292048, 0.0],
              [-80.905534318456404, 35.61262441103657, 0.0],
              [-80.906179584741551, 35.6132196777248, 0.0],
              [-80.906460318151446, 35.613769278061923, 0.0],
              [-80.907189784715399, 35.614456211439936, 0.0],
              [-80.908227584691431, 35.614684877720784, 0.0],
              [-80.908507985030667, 35.614684811035687, 0.0],
              [-80.908956585118091, 35.614249477841689, 0.0],
              [-80.909433184888499, 35.613997478195905, 0.0],
              [-80.909685385406334, 35.613516411552148, 0.0],
              [-80.909601184839346, 35.613218678117747, 0.0],
              [-80.90943291773462, 35.613035611395837, 0.0],
              [-80.909348185202091, 35.611798878429646, 0.0],
              [-80.909151718748092, 35.611524078514002, 0.0],
              [-80.909067517751083, 35.611226411711364, 0.0],
              [-80.909347918126826, 35.611134678242379, 0.0],
              [-80.909824784300909, 35.611478077781634, 0.0],
              [-80.909881118028821, 35.612004811264988, 0.0],
              [-80.910273985144215, 35.612394010927176, 0.0],
              [-80.910386318628738, 35.612668811186062, 0.0],
              [-80.910863184777924, 35.613080878481071, 0.0],
              [-80.91089138443354, 35.613309878450153, 0.0],
              [-80.910975517588071, 35.613355678200328, 0.0],
              [-80.911003718220996, 35.61365347814111, 0.0],
              [-80.911508584526331, 35.613744877916481, 0.0],
              [-80.912181584429717, 35.613538477740299, 0.0],
              [-80.912405984390418, 35.613675877595966, 0.0],
              [-80.912490317580477, 35.614156878199928, 0.0],
              [-80.91279891856982, 35.614294078333273, 0.0],
              [-80.912855518366442, 35.615095677701078, 0.0],
              [-80.913500784851507, 35.615759611568166, 0.0],
              [-80.914510585180949, 35.615873877686063, 0.0],
              [-80.915267917765448, 35.61621707836359, 0.0],
              [-80.915492518111321, 35.616446011747357, 0.0],
              [-80.916726784349038, 35.617041078389519, 0.0],
              [-80.917203984385353, 35.617499010935504, 0.0],
              [-80.917484384896497, 35.617430078271916, 0.0],
              [-80.917624384967397, 35.617201077719997, 0.0],
              [-80.917764318081396, 35.61649107755926, 0.0],
              [-80.918241117602037, 35.616467877749542, 0.0],
              [-80.918605984786893, 35.616857211621202, 0.0],
              [-80.918914517810194, 35.617040211612114, 0.0],
              [-80.919278918349818, 35.616559211420643, 0.0],
              [-80.919082385198308, 35.616353078360198, 0.0],
              [-80.918465318156251, 35.616009811866512, 0.0],
              [-80.918268784805406, 35.615803678502935, 0.0],
              [-80.918184384587221, 35.615254077893972, 0.0],
              [-80.917595318592234, 35.615094011344326, 0.0],
              [-80.917623318416389, 35.614865011217539, 0.0],
              [-80.917735318511973, 35.614704677859066, 0.0],
              [-80.917566918090131, 35.61447567791334, 0.0],
              [-80.91725831857535, 35.614338411780423, 0.0],
              [-80.916472585077926, 35.61365161158772, 0.0],
              [-80.916304117659635, 35.613056211554174, 0.0],
              [-80.915995518142296, 35.612804277741262, 0.0],
              [-80.914564984998478, 35.612392611761869, 0.0],
              [-80.914564785185306, 35.612209411760738, 0.0],
              [-80.914789184490687, 35.612094810928944, 0.0],
              [-80.915153584879135, 35.611705411257056, 0.0],
              [-80.915293585292318, 35.61133881173604, 0.0],
              [-80.914956917742288, 35.611064078017478, 0.0],
              [-80.914984717718511, 35.610583078111546, 0.0],
              [-80.914507784554658, 35.610193878482931, 0.0],
              [-80.914395517928526, 35.610010811513199, 0.0],
              [-80.914731918069876, 35.60987327801508, 0.0],
              [-80.914843984702031, 35.609735811663015, 0.0],
              [-80.914899984658987, 35.609346478125666, 0.0],
              [-80.91475958431441, 35.60902587843502, 0.0],
              [-80.914170317802331, 35.608361877872163, 0.0],
              [-80.914057985133113, 35.60806427817483, 0.0],
              [-80.91487131878857, 35.60799521183943, 0.0],
              [-80.915067518632156, 35.607857677687548, 0.0],
              [-80.915627785249711, 35.606712410973614, 0.0],
              [-80.915852185223542, 35.606620678319942, 0.0],
              [-80.915964384743504, 35.606712277602206, 0.0],
              [-80.915908517773445, 35.607124478535816, 0.0],
              [-80.915544317751127, 35.607903411147987, 0.0],
              [-80.915516717566277, 35.60884241095512, 0.0],
              [-80.915685118724326, 35.609140077817592, 0.0],
              [-80.916049918737144, 35.609506411786214, 0.0],
              [-80.916751184445758, 35.609826811097491, 0.0],
              [-80.916891718397636, 35.610239011085113, 0.0],
              [-80.917116118147092, 35.61051381126974, 0.0],
              [-80.917789385217063, 35.611017278348491, 0.0],
              [-80.917789717692088, 35.611406677590139, 0.0],
              [-80.918266718672371, 35.611933211218634, 0.0],
              [-80.918379117904749, 35.61234541139666, 0.0],
              [-80.918631785319775, 35.612734677881903, 0.0],
              [-80.919501584445115, 35.613490077699389, 0.0],
              [-80.919725918149638, 35.613490078127022, 0.0],
              [-80.920146385219525, 35.613123478237192, 0.0],
              [-80.920230318324073, 35.612642478204101, 0.0],
              [-80.919528784856894, 35.61200147776637, 0.0],
              [-80.919388518609864, 35.611749611294385, 0.0],
              [-80.920341918122205, 35.611520210966283, 0.0],
              [-80.920369785067791, 35.611336878105867, 0.0],
              [-80.919472118767032, 35.610902211125868, 0.0],
              [-80.91933171853789, 35.610650211483637, 0.0],
              [-80.920201118173026, 35.610627011598098, 0.0],
              [-80.920565718707238, 35.610535278496222, 0.0],
              [-80.920565585076972, 35.610352078138241, 0.0],
              [-80.920397184492089, 35.610168878283403, 0.0],
              [-80.920284784751715, 35.609596411842851, 0.0],
              [-80.920088384934104, 35.609459011261997, 0.0],
              [-80.919387118766679, 35.609367678283192, 0.0],
              [-80.919078518687897, 35.609138811164726, 0.0],
              [-80.918461384660361, 35.608864210929802, 0.0],
              [-80.918433318675383, 35.608703878014083, 0.0],
              [-80.918797785243171, 35.608474678023775, 0.0],
              [-80.918909718114477, 35.608222811501413, 0.0],
              [-80.919750984250001, 35.608062011109318, 0.0],
              [-80.919778785219137, 35.607741411267661, 0.0],
              [-80.919582384811022, 35.607443811114017, 0.0],
              [-80.918965318004325, 35.607215011835386, 0.0],
              [-80.918880984704145, 35.607031811639892, 0.0],
              [-80.920058785123402, 35.606939811860741, 0.0],
              [-80.920339184901621, 35.606710611798142, 0.0],
              [-80.920366984414017, 35.606183878212356, 0.0],
              [-80.920058185217158, 35.605886278545796, 0.0],
              [-80.920030184383592, 35.605703078139889, 0.0],
              [-80.920534918208119, 35.605611211765741, 0.0],
              [-80.920675117563363, 35.605748677853271, 0.0],
              [-80.920871785064747, 35.606435611301535, 0.0],
              [-80.921236584747803, 35.606779011201468, 0.0],
              [-80.920872518255464, 35.607626477635222, 0.0],
              [-80.920816784842629, 35.608359411059887, 0.0],
              [-80.920929318182431, 35.608817411634199, 0.0],
              [-80.921069718680201, 35.609023411850686, 0.0],
              [-80.9212659844342, 35.609115011611138, 0.0],
              [-80.921602518103455, 35.609137811385111, 0.0],
              [-80.922023117898263, 35.608885678317911, 0.0],
              [-80.922106917857576, 35.60824441138314, 0.0],
              [-80.922835984352005, 35.608175411609999, 0.0],
              [-80.922920184937681, 35.608335678493823, 0.0],
              [-80.922668184670044, 35.609045811143361, 0.0],
              [-80.92272458522288, 35.609526677683306, 0.0],
              [-80.922949184539164, 35.609893011795883, 0.0],
              [-80.924071118061136, 35.610167411354674, 0.0],
              [-80.924239118020623, 35.609869611616546, 0.0],
              [-80.924154917996603, 35.609549011631948, 0.0],
              [-80.924743384540918, 35.608884611588977, 0.0],
              [-80.924771118379908, 35.608357811800765, 0.0],
              [-80.924406384413004, 35.608106011283532, 0.0],
              [-80.92440631816207, 35.607968611026514, 0.0],
              [-80.924574384895422, 35.60776241136459, 0.0],
              [-80.92454618470525, 35.607418810910204, 0.0],
              [-80.924153317875195, 35.607052611613533, 0.0],
              [-80.924124917661061, 35.606525811404047, 0.0],
              [-80.923844385134643, 35.606251078422133, 0.0],
              [-80.922750384401368, 35.605770678099049, 0.0],
              [-80.922693984697219, 35.605381277818196, 0.0],
              [-80.922413318604228, 35.60480887822925, 0.0],
              [-80.922525317952321, 35.604602678020164, 0.0],
              [-80.922749585333207, 35.604511011424648, 0.0],
              [-80.922945984360382, 35.604579678474558, 0.0],
              [-80.923675517709853, 35.605289277583253, 0.0],
              [-80.924180318356164, 35.605312011512211, 0.0],
              [-80.924684718656124, 35.604693411797676, 0.0],
              [-80.924993184576579, 35.604693211383029, 0.0],
              [-80.924769385018976, 35.605472078215726, 0.0],
              [-80.924853717709624, 35.605884277924417, 0.0],
              [-80.925471184968231, 35.606685678201856, 0.0],
              [-80.925611584293435, 35.6071436114809, 0.0],
              [-80.925779985400624, 35.607303811365362, 0.0],
              [-80.926088384468088, 35.607120477600645, 0.0],
              [-80.926172184827251, 35.606662477955304, 0.0],
              [-80.925975584947594, 35.606204411619146, 0.0],
              [-80.926536518716034, 35.606158277755533, 0.0],
              [-80.926760784844362, 35.606066611708201, 0.0],
              [-80.927040984459524, 35.605768811696571, 0.0],
              [-80.927040518388267, 35.604944278002762, 0.0],
              [-80.927264785399998, 35.604875477621476, 0.0],
              [-80.927517385034704, 35.605127277973601, 0.0],
              [-80.92762971773189, 35.605402078423538, 0.0],
              [-80.928162718455809, 35.605608011373697, 0.0],
              [-80.927882718494175, 35.606318078455651, 0.0],
              [-80.927826918479226, 35.606776211591189, 0.0],
              [-80.928331784214706, 35.606890410957384, 0.0],
              [-80.929341317569097, 35.606821210946862, 0.0],
              [-80.929285385040984, 35.607096077863332, 0.0],
              [-80.929257585240933, 35.607577077925242, 0.0],
              [-80.928836984283691, 35.607623078200234, 0.0],
              [-80.928164117951653, 35.60782961168875, 0.0],
              [-80.927547384285219, 35.608242078500638, 0.0],
              [-80.927519785124701, 35.608883277579899, 0.0],
              [-80.927660118281267, 35.609043611175039, 0.0],
              [-80.928389385013446, 35.609203611796403, 0.0],
              [-80.928838318424908, 35.609478211824538, 0.0],
              [-80.928866384406263, 35.609638478498567, 0.0],
              [-80.92785698439171, 35.609913811325157, 0.0],
              [-80.927800984560179, 35.610074078510941, 0.0],
              [-80.928278117647835, 35.610554811875147, 0.0],
              [-80.927492984684619, 35.610807211555368, 0.0],
              [-80.927493317731376, 35.611356811369106, 0.0],
              [-80.928138718354973, 35.611837477683927, 0.0],
              [-80.928194918730199, 35.61197487841325, 0.0],
              [-80.928082985085069, 35.612455878032065, 0.0],
              [-80.92861611776739, 35.612753411044089, 0.0],
              [-80.929036784883479, 35.612821878468004, 0.0],
              [-80.929989985401861, 35.612340478436764, 0.0],
              [-80.930494918135551, 35.61247761181049, 0.0],
              [-80.93052318447522, 35.612729611882941, 0.0],
              [-80.930383118064142, 35.612890011566691, 0.0],
              [-80.930327318477438, 35.613416811264344, 0.0],
              [-80.930663984419056, 35.613599811624255, 0.0],
              [-80.931337118007761, 35.613622411186086, 0.0],
              [-80.931617384797661, 35.613370278407913, 0.0],
              [-80.931813384916467, 35.612980878143048, 0.0],
              [-80.932177918443202, 35.612820411617896, 0.0],
              [-80.932205718097933, 35.612408078465926, 0.0],
              [-80.932093385136113, 35.612156278313222, 0.0],
              [-80.93184078534513, 35.611881611826064, 0.0],
              [-80.931756384620854, 35.611515211613408, 0.0],
              [-80.931559918038317, 35.611309211527299, 0.0],
              [-80.931139117616041, 35.611103211561982, 0.0],
              [-80.931026785059956, 35.610943011325233, 0.0],
              [-80.931222917986958, 35.610759678131295, 0.0],
              [-80.931559384739742, 35.61069081114735, 0.0],
              [-80.932092718075694, 35.611240211672964, 0.0],
              [-80.932485385050214, 35.611400277988892, 0.0],
              [-80.933186384266307, 35.611285411350153, 0.0],
              [-80.93349518446179, 35.611560078063576, 0.0],
              [-80.93360771832468, 35.612063878029865, 0.0],
              [-80.934365317908458, 35.612498677881931, 0.0],
              [-80.934785985325433, 35.612544211418061, 0.0],
              [-80.935234518345695, 35.612269211570471, 0.0],
              [-80.936327984932419, 35.611810611578484, 0.0],
              [-80.936636184839102, 35.611466877605601, 0.0],
              [-80.93652391792844, 35.611329477886002, 0.0],
              [-80.935934784924967, 35.611077878267437, 0.0],
              [-80.935710317561629, 35.610871877792391, 0.0],
              [-80.935653985356765, 35.610665811251707, 0.0],
              [-80.934756384540407, 35.61039141153514, 0.0],
              [-80.934615918182558, 35.610070878063475, 0.0],
              [-80.934138784679988, 35.609704678115264, 0.0],
              [-80.933381384361027, 35.609361477741395, 0.0],
              [-80.933241118477454, 35.609178277870818, 0.0],
              [-80.93357738491207, 35.608903411628418, 0.0],
              [-80.933745518605789, 35.608628478420783, 0.0],
              [-80.933716785290272, 35.607849678520836, 0.0],
              [-80.933969318510322, 35.607826678450955, 0.0],
              [-80.934418385372012, 35.608399011721744, 0.0],
              [-80.934446518378721, 35.608559411435152, 0.0],
              [-80.934895585075466, 35.609131677826696, 0.0],
              [-80.935624784910388, 35.609200011619848, 0.0],
              [-80.936017718380953, 35.60949761090928, 0.0],
              [-80.936971518474266, 35.609909277894317, 0.0],
              [-80.9376727842766, 35.610023478159675, 0.0],
              [-80.938037317694025, 35.609885810923245, 0.0],
              [-80.938429584239501, 35.609519211645789, 0.0],
              [-80.93854158535197, 35.609244277793948, 0.0],
              [-80.938288985308191, 35.609038410950518, 0.0],
              [-80.938512918389904, 35.608488478285146, 0.0],
              [-80.937558917873716, 35.607893478322865, 0.0],
              [-80.937446585395861, 35.607710277889915, 0.0],
              [-80.938792718532852, 35.60761807809461, 0.0],
              [-80.938988784437342, 35.607503411030656, 0.0],
              [-80.938792318594494, 35.607137077816823, 0.0],
              [-80.938707718340893, 35.606541677861557, 0.0],
              [-80.939548784365584, 35.606243410948828, 0.0],
              [-80.941315184838729, 35.605899011227294, 0.0],
              [-80.942016118355696, 35.605692478529541, 0.0],
              [-80.942043918676447, 35.605348877935825, 0.0],
              [-80.941931384233158, 35.605074211653374, 0.0],
              [-80.941763117715041, 35.604982678139073, 0.0],
              [-80.939491984968527, 35.605304478545619, 0.0],
              [-80.939547718600423, 35.604938011142224, 0.0],
              [-80.939883784937791, 35.604388211539394, 0.0],
              [-80.939939718520748, 35.604090478079698, 0.0],
              [-80.939799385314032, 35.603953078240586, 0.0],
              [-80.939770785059551, 35.603334677623373, 0.0],
              [-80.939686584488086, 35.603266078266401, 0.0],
              [-80.939602117735333, 35.602739278419918, 0.0],
              [-80.939237317744315, 35.602510478314045, 0.0],
              [-80.939320784574647, 35.601777611000074, 0.0],
              [-80.939152385294747, 35.601571477747378, 0.0],
              [-80.937945984567961, 35.600701877596727, 0.0],
              [-80.937805784716815, 35.600793611766612, 0.0],
              [-80.937862785198348, 35.601915811191461, 0.0],
              [-80.937694785175083, 35.602190677869871, 0.0],
              [-80.937611184359255, 35.602900678131597, 0.0],
              [-80.937695385253832, 35.602992277872808, 0.0],
              [-80.937639518299278, 35.60329007827643, 0.0],
              [-80.937443385085942, 35.60347341186862, 0.0],
              [-80.937331518282207, 35.603908478432622, 0.0],
              [-80.9368551848344, 35.60438987829852, 0.0],
              [-80.936827517724566, 35.604825011380477, 0.0],
              [-80.936351118649455, 35.605352011326104, 0.0],
              [-80.935902518075579, 35.605489678015608, 0.0],
              [-80.935453717908857, 35.605352477677911, 0.0],
              [-80.935509718027944, 35.605192078191294, 0.0],
              [-80.936042185164396, 35.604665078474142, 0.0],
              [-80.936238185312305, 35.604344411793456, 0.0],
              [-80.936069184773487, 35.603519878347001, 0.0],
              [-80.936209184241378, 35.603061811832099, 0.0],
              [-80.935788318455209, 35.60269561177374, 0.0],
              [-80.935871584459747, 35.601733611320853, 0.0],
              [-80.935731318015513, 35.601550478206065, 0.0],
              [-80.935226384959321, 35.601344678036007, 0.0],
              [-80.934777785289086, 35.601436478260766, 0.0],
              [-80.933909117794087, 35.602307277875944, 0.0],
              [-80.93374111758952, 35.6025592780111, 0.0],
              [-80.933685518246165, 35.603383678176968, 0.0],
              [-80.933349518065199, 35.603979411656475, 0.0],
              [-80.933181718554039, 35.604643611673929, 0.0],
              [-80.932929384559984, 35.604758211180382, 0.0],
              [-80.93281711869021, 35.604643810908875, 0.0],
              [-80.932844117826477, 35.603132211609243, 0.0],
              [-80.933067985108082, 35.602399211453886, 0.0],
              [-80.933347117966406, 35.600727211525211, 0.0],
              [-80.933262984814618, 35.600635611700142, 0.0],
              [-80.933010517791274, 35.600589878152448, 0.0],
              [-80.932702185222382, 35.600796211588666, 0.0],
              [-80.932085984632224, 35.601758477628444, 0.0],
              [-80.932058184671817, 35.602056211895757, 0.0],
              [-80.931609785279306, 35.602491610917234, 0.0],
              [-80.931610118490056, 35.602926678443851, 0.0],
              [-80.931834917817014, 35.603590811076216, 0.0],
              [-80.931526518434993, 35.603659611277202, 0.0],
              [-80.931217784351929, 35.603339210961828, 0.0],
              [-80.930656918358665, 35.603270811081067, 0.0],
              [-80.930628584734265, 35.60290421144331, 0.0],
              [-80.930348117863829, 35.602812811643332, 0.0],
              [-80.929899185308827, 35.602492411693696, 0.0],
              [-80.929674917982723, 35.60258407793296, 0.0],
              [-80.929534984236568, 35.603019411711124, 0.0],
              [-80.929198717574323, 35.603317211071115, 0.0],
              [-80.928581985219495, 35.603546478190964, 0.0],
              [-80.927740518071062, 35.603294877933635, 0.0],
              [-80.927684318657271, 35.603111811410741, 0.0],
              [-80.928244784891248, 35.602653477794476, 0.0],
              [-80.928468984650152, 35.602378611157114, 0.0],
              [-80.928468784496872, 35.602103677862708, 0.0],
              [-80.927879918079469, 35.602058211260065, 0.0],
              [-80.926506117558233, 35.602425277788683, 0.0],
              [-80.926141517786846, 35.602448278481461, 0.0],
              [-80.92614138456851, 35.602310878040768, 0.0],
              [-80.926870317680937, 35.601967011527826, 0.0],
              [-80.927206584716046, 35.601669211633599, 0.0],
              [-80.928047717832328, 35.601462678078661, 0.0],
              [-80.928243918069725, 35.60118767771143, 0.0],
              [-80.928972917973809, 35.601095678521283, 0.0],
              [-80.929365385402946, 35.600935278006752, 0.0],
              [-80.929449384645864, 35.600706211495442, 0.0],
              [-80.92900051859327, 35.600408677913535, 0.0],
              [-80.929112184294937, 35.599744478430964, 0.0],
              [-80.929644917665755, 35.599583877929419, 0.0],
              [-80.929700918730219, 35.599423611215165, 0.0],
              [-80.930317584912217, 35.599148411766471, 0.0],
              [-80.930373584448233, 35.599010878270477, 0.0],
              [-80.93014898515429, 35.598553011764196, 0.0],
              [-80.930429184272754, 35.598369677616333, 0.0],
              [-80.931270185366913, 35.598071478342526, 0.0],
              [-80.931325984442992, 35.597636277984215, 0.0],
              [-80.931466118584737, 35.597430211539887, 0.0],
              [-80.931549785346178, 35.596949078238779, 0.0],
              [-80.931409517929055, 35.596834611557547, 0.0],
              [-80.931044984509981, 35.596811878021001, 0.0],
              [-80.930624585070248, 35.597087011761793, 0.0],
              [-80.930175984801153, 35.597178811197033, 0.0],
              [-80.929699118715163, 35.596881411120847, 0.0],
              [-80.929502918591623, 35.596927211008946, 0.0],
              [-80.929306918162098, 35.597270811064355, 0.0],
              [-80.928746185035749, 35.597614611233688, 0.0],
              [-80.928578318041858, 35.598118611207518, 0.0],
              [-80.928242117655827, 35.598485278130177, 0.0],
              [-80.928186185194093, 35.59880587770013, 0.0],
              [-80.927877984619627, 35.599058011210076, 0.0],
              [-80.927822118317053, 35.59935561151358, 0.0],
              [-80.927457917553227, 35.599859677852336, 0.0],
              [-80.926841184431041, 35.600295077687228, 0.0],
              [-80.92647691832471, 35.600684677961461, 0.0],
              [-80.926028317999609, 35.600799411214439, 0.0],
              [-80.925915984263852, 35.600593278382227, 0.0],
              [-80.925467317867842, 35.600410278495744, 0.0],
              [-80.924289318203222, 35.600158811883198, 0.0],
              [-80.924289184653233, 35.599975611861559, 0.0],
              [-80.924990184689889, 35.599883678178159, 0.0],
              [-80.925494785277465, 35.599654477692802, 0.0],
              [-80.926223717836947, 35.599470877960528, 0.0],
              [-80.926756185057741, 35.598943878005755, 0.0],
              [-80.926699917571028, 35.598485878287107, 0.0],
              [-80.927260318695573, 35.597981811808722, 0.0],
              [-80.927175918212839, 35.597615411523655, 0.0],
              [-80.926923384302953, 35.597409411856688, 0.0],
              [-80.926502718144562, 35.597272211575152, 0.0],
              [-80.925717585011924, 35.597249611704825, 0.0],
              [-80.92566151821832, 35.597135078078956, 0.0],
              [-80.925857718573909, 35.597020477978013, 0.0],
              [-80.927287584741165, 35.596790811617304, 0.0],
              [-80.927539918340614, 35.596561678383779, 0.0],
              [-80.927455585083209, 35.59637847853989, 0.0],
              [-80.926978717755205, 35.596103878492663, 0.0],
              [-80.926950584653639, 35.595920611040476, 0.0],
              [-80.927314918026752, 35.595577011277705, 0.0],
              [-80.927370718385433, 35.595348011237398, 0.0],
              [-80.927202517747418, 35.595210477648799, 0.0],
              [-80.927202384929302, 35.595004411491566, 0.0],
              [-80.928015117948021, 35.594431477980194, 0.0],
              [-80.928070984291764, 35.594087878156337, 0.0],
              [-80.927621917779817, 35.593698811562028, 0.0],
              [-80.927593718169831, 35.593424010989089, 0.0],
              [-80.928098118539538, 35.592919878394262, 0.0],
              [-80.928097918415972, 35.592599277944132, 0.0],
              [-80.928405918448547, 35.591980811908037, 0.0],
              [-80.928069185174238, 35.591591611015545, 0.0],
              [-80.927816184450393, 35.59051521128788, 0.0],
              [-80.927395317757288, 35.590126011188559, 0.0],
              [-80.926890518662489, 35.589897277711437, 0.0],
              [-80.926722385399401, 35.589943078314271, 0.0],
              [-80.926778984857236, 35.590928011364653, 0.0],
              [-80.926582985330299, 35.591363278012338, 0.0],
              [-80.926499184321656, 35.591844077854944, 0.0],
              [-80.926303118001371, 35.59211907819099, 0.0],
              [-80.926247318617413, 35.592508411749691, 0.0],
              [-80.926555985063231, 35.592897677669612, 0.0],
              [-80.926500118080583, 35.593195478163402, 0.0],
              [-80.926191918741679, 35.593424611003307, 0.0],
              [-80.925687185152142, 35.593562278431705, 0.0],
              [-80.925266584230869, 35.593379278339206, 0.0],
              [-80.924902184521059, 35.593448078395753, 0.0],
              [-80.924481918499836, 35.594020811059245, 0.0],
              [-80.92400531821869, 35.594112611036444, 0.0],
              [-80.923556517802055, 35.593998277836818, 0.0],
              [-80.923051784598314, 35.593998478104737, 0.0],
              [-80.922939784514725, 35.594113011371824, 0.0],
              [-80.922715784866597, 35.594685811880773, 0.0],
              [-80.922631718611967, 35.594708677616786, 0.0],
              [-80.922603917796394, 35.595006411766519, 0.0],
              [-80.922463784462551, 35.595258411473885, 0.0],
              [-80.922015317824616, 35.595533411606645, 0.0],
              [-80.921482584353612, 35.595671011650197, 0.0],
              [-80.921930784871776, 35.594846411836052, 0.0],
              [-80.92198651785759, 35.594205078049448, 0.0],
              [-80.921817985132435, 35.593930277790072, 0.0],
              [-80.921537718102087, 35.593793011910932, 0.0],
              [-80.920612384746462, 35.593907877715708, 0.0],
              [-80.920472118298605, 35.593839278143804, 0.0],
              [-80.920472117943902, 35.593701878388345, 0.0],
              [-80.920724185189954, 35.593358211692177, 0.0],
              [-80.920723917660467, 35.59283141146669, 0.0],
              [-80.920415384695701, 35.592533677957711, 0.0],
              [-80.920218918518657, 35.592167477949154, 0.0],
              [-80.919321585168007, 35.591961678404516, 0.0],
              [-80.919041184566709, 35.592076277678231, 0.0],
              [-80.918845184933602, 35.592442811396147, 0.0],
              [-80.918564785375722, 35.592626211009268, 0.0],
              [-80.918368318629618, 35.592122411052685, 0.0],
              [-80.918115784775736, 35.591916411163531, 0.0],
              [-80.917218784779124, 35.592260211613812, 0.0],
              [-80.916658318611965, 35.592787078260109, 0.0],
              [-80.916490318627297, 35.593199477952886, 0.0],
              [-80.915929584884424, 35.593497477627345, 0.0],
              [-80.915677384412135, 35.593841077924019, 0.0],
              [-80.9156497179154, 35.594413611210285, 0.0],
              [-80.915733984530775, 35.594688411123094, 0.0],
              [-80.916351318347949, 35.595673011259159, 0.0],
              [-80.916435584392289, 35.595970678355492, 0.0],
              [-80.916155185158232, 35.595993678399182, 0.0],
              [-80.915285517969664, 35.595032078049009, 0.0],
              [-80.915117185051173, 35.594963411482517, 0.0],
              [-80.914780784393457, 35.595055211223674, 0.0],
              [-80.914472385287482, 35.595284411465123, 0.0],
              [-80.914444585029131, 35.595536277592885, 0.0],
              [-80.913715784931313, 35.596109078518765, 0.0],
              [-80.913715918510619, 35.596429677850679, 0.0],
              [-80.913856185349118, 35.596704478100634, 0.0],
              [-80.913575984683504, 35.596796211171487, 0.0],
              [-80.913379584256887, 35.596590078508406, 0.0],
              [-80.912902785340393, 35.596407078132032, 0.0],
              [-80.912874584480733, 35.596109411405472, 0.0],
              [-80.913154918763311, 35.595903211125986, 0.0],
              [-80.913743384944297, 35.595193011893976, 0.0],
              [-80.913855318309857, 35.594712078452048, 0.0],
              [-80.914331784972575, 35.594322411068319, 0.0],
              [-80.914331585039065, 35.593818611461089, 0.0],
              [-80.913882718080345, 35.593429411086696, 0.0],
              [-80.913966784296704, 35.593337677953237, 0.0],
              [-80.915060184297701, 35.59303967822612, 0.0],
              [-80.915284384297095, 35.592810611351162, 0.0],
              [-80.915424384927576, 35.592490011098874, 0.0],
              [-80.915928917909682, 35.59214607790021, 0.0],
              [-80.916405384291068, 35.591619211177495, 0.0],
              [-80.917218184787856, 35.591092211693841, 0.0],
              [-80.917526384607854, 35.590679678343193, 0.0],
              [-80.918395118132679, 35.589923611783085, 0.0],
              [-80.91828278526161, 35.589465677988088, 0.0],
              [-80.917469185153308, 35.588733078530744, 0.0],
              [-80.916936517655941, 35.588664610959903, 0.0],
              [-80.915506718676653, 35.588939878445288, 0.0],
              [-80.915254518567863, 35.589100211001188, 0.0],
              [-80.914890118231, 35.589192077942499, 0.0],
              [-80.914665718457314, 35.589008878222181, 0.0],
              [-80.914216984301021, 35.588825878180671, 0.0],
              [-80.913936518420812, 35.588619878575848, 0.0],
              [-80.913375584637151, 35.588368077898423, 0.0],
              [-80.913095317616296, 35.588505611148335, 0.0],
              [-80.91317991806379, 35.589421611825408, 0.0],
              [-80.912843384919995, 35.589513411714798, 0.0],
              [-80.912170318124069, 35.588918211682291, 0.0],
              [-80.91180571784281, 35.588735011587175, 0.0],
              [-80.911468985435306, 35.58807101175347, 0.0],
              [-80.911160384333783, 35.587887810963529, 0.0],
              [-80.910543718175163, 35.587911011290778, 0.0],
              [-80.910207317862557, 35.588002611448168, 0.0],
              [-80.910067185253567, 35.588323278005255, 0.0],
              [-80.90978691874551, 35.588437877604683, 0.0],
              [-80.909225984798738, 35.588140411364641, 0.0],
              [-80.908973717794638, 35.588232078361614, 0.0],
              [-80.908889917598316, 35.588690211263135, 0.0],
              [-80.908721584284578, 35.588804677974892, 0.0],
              [-80.908637585373981, 35.589079478398979, 0.0],
              [-80.9089181845127, 35.589468811409112, 0.0],
              [-80.908721984289912, 35.589881077841667, 0.0],
              [-80.90880631841253, 35.590064411581515, 0.0],
              [-80.908469917603355, 35.590453678480969, 0.0],
              [-80.908470118604882, 35.590774277909887, 0.0],
              [-80.909283384683704, 35.591369611020006, 0.0],
              [-80.909283518670591, 35.591507078519093, 0.0],
              [-80.908554584927572, 35.591553078413042, 0.0],
              [-80.908358384694125, 35.591690611008389, 0.0],
              [-80.90827451871067, 35.59226321165626, 0.0],
              [-80.907853984752919, 35.592561011592906, 0.0],
              [-80.907769984951059, 35.592996078176697, 0.0],
              [-80.907181518186817, 35.593546011851807, 0.0],
              [-80.907125585134423, 35.594141477649188, 0.0],
              [-80.906621117745161, 35.594691277954965, 0.0],
              [-80.906593384889305, 35.595470011679097, 0.0],
              [-80.906425317677005, 35.59581367793367, 0.0],
              [-80.906004718372714, 35.595905411358629, 0.0],
              [-80.905948584411618, 35.595745011465354, 0.0],
              [-80.90606058453389, 35.595561811438962, 0.0],
              [-80.906060318099946, 35.5944624117949, 0.0],
              [-80.90631251855892, 35.594027211552721, 0.0],
              [-80.906396384357919, 35.593683678206197, 0.0],
              [-80.906536517741969, 35.593500478269583, 0.0],
              [-80.906564384902168, 35.592996477733713, 0.0],
              [-80.906928784607672, 35.592698611126004, 0.0],
              [-80.907489117884424, 35.591645011832291, 0.0],
              [-80.907600985382416, 35.591095277922292, 0.0],
              [-80.907180184384174, 35.590500011662094, 0.0],
              [-80.90715178496805, 35.589606678540605, 0.0],
              [-80.906983585050227, 35.589423477794419, 0.0],
              [-80.90698351861694, 35.589125811019251, 0.0],
              [-80.907095517671721, 35.588873878182213, 0.0],
              [-80.907150985097331, 35.587591277629258, 0.0],
              [-80.907262918537967, 35.587041611413348, 0.0],
              [-80.906898384429113, 35.586973011583254, 0.0],
              [-80.906393984912143, 35.587408277730418, 0.0],
              [-80.905693118445541, 35.587523011893232, 0.0],
              [-80.90538471847421, 35.587752078102689, 0.0],
              [-80.905328918471369, 35.588049811801504, 0.0],
              [-80.90502038443914, 35.588095678056611, 0.0],
              [-80.904543584931602, 35.58747747853581, 0.0],
              [-80.904263185071287, 35.587363077704694, 0.0],
              [-80.903590317905724, 35.58729447857425, 0.0],
              [-80.901599917993778, 35.588050811256856, 0.0],
              [-80.901235518397854, 35.588348610969959, 0.0],
              [-80.901179518666211, 35.588898277773339, 0.0],
              [-80.901432184863737, 35.589951811069, 0.0],
              [-80.900983918075966, 35.590799278196577, 0.0],
              [-80.900731718169865, 35.590913811126342, 0.0],
              [-80.900647518268642, 35.590845211849327, 0.0],
              [-80.900647384899855, 35.5905244776499, 0.0],
              [-80.900759385403717, 35.590226811791403, 0.0],
              [-80.900703118639754, 35.589471077683655, 0.0],
              [-80.900366584742244, 35.589150411781972, 0.0],
              [-80.900113984997489, 35.588532078344777, 0.0],
              [-80.899693517857429, 35.588417678565015, 0.0],
              [-80.899188718070334, 35.588165877816301, 0.0],
              [-80.898740184792004, 35.588463678431033, 0.0],
              [-80.898740318715483, 35.588555278225435, 0.0],
              [-80.898207518709057, 35.588738677743201, 0.0],
              [-80.897730918360196, 35.588647211563796, 0.0],
              [-80.897170118625283, 35.588303677664356, 0.0],
              [-80.896805584475985, 35.588235078216265, 0.0],
              [-80.896469184945275, 35.588532878165225, 0.0],
              [-80.896413318377355, 35.589288811767602, 0.0],
              [-80.896104984521159, 35.589678078244503, 0.0],
              [-80.895712718661045, 35.590823278431593, 0.0],
              [-80.895544518542863, 35.590869078067158, 0.0],
              [-80.895460518124366, 35.590846278466174, 0.0],
              [-80.895488118286849, 35.589586611238595, 0.0],
              [-80.89537578527387, 35.589037011066857, 0.0],
              [-80.895852385252581, 35.588349678015028, 0.0],
              [-80.895936185045343, 35.587594011246956, 0.0],
              [-80.895655784528287, 35.587319211276331, 0.0],
              [-80.895122985046697, 35.587090277999472, 0.0],
              [-80.893496917607592, 35.58688441124103, 0.0],
              [-80.893048317666299, 35.587251011787941, 0.0],
              [-80.892908318112191, 35.58773201121052, 0.0],
              [-80.89234751839173, 35.587892277600012, 0.0],
              [-80.89229138530392, 35.587617478088262, 0.0],
              [-80.892095185152826, 35.587503011034563, 0.0],
              [-80.891674584443493, 35.587732077962031, 0.0],
              [-80.89080551794315, 35.587800877805691, 0.0],
              [-80.890805518166587, 35.587503211769508, 0.0],
              [-80.891590318223749, 35.586564078351905, 0.0],
              [-80.891674184621863, 35.586060278527391, 0.0],
              [-80.891365918410017, 35.585808411154972, 0.0],
              [-80.890019985152804, 35.585327611470078, 0.0],
              [-80.889991984740647, 35.585213078273078, 0.0],
              [-80.890945118641085, 35.584892211851667, 0.0],
              [-80.891982384570824, 35.584823478368151, 0.0],
              [-80.892094518681048, 35.584686011334981, 0.0],
              [-80.892178518316996, 35.584205011295197, 0.0],
              [-80.892346718550627, 35.584113411654563, 0.0],
              [-80.892514984951532, 35.58420501155274, 0.0],
              [-80.892515117754968, 35.584365278488413, 0.0],
              [-80.892879585345142, 35.584663011239009, 0.0],
              [-80.893159918531154, 35.584685811525084, 0.0],
              [-80.893468317912735, 35.584502478301083, 0.0],
              [-80.893664517970691, 35.584548277989057, 0.0],
              [-80.893748785400518, 35.585029278428635, 0.0],
              [-80.89425351854031, 35.585624611737465, 0.0],
              [-80.894505917804295, 35.585739211484785, 0.0],
              [-80.895150784864711, 35.585647411622524, 0.0],
              [-80.895571385405802, 35.58592207804341, 0.0],
              [-80.896132118196135, 35.585945011104528, 0.0],
              [-80.89638438450676, 35.586265611477927, 0.0],
              [-80.896917317716813, 35.586654811133599, 0.0],
              [-80.897337918622952, 35.586723411900344, 0.0],
              [-80.897730384590488, 35.586631678350805, 0.0],
              [-80.897870584674337, 35.586906477969301, 0.0],
              [-80.899496785110159, 35.5871122112911, 0.0],
              [-80.900365784478126, 35.586608210957529, 0.0],
              [-80.900533918405216, 35.586402078079118, 0.0],
              [-80.901066584538796, 35.586310277922713, 0.0],
              [-80.902524318790725, 35.585737478516243, 0.0],
              [-80.903785917653693, 35.585576811356759, 0.0],
              [-80.905327917973452, 35.585507610962914, 0.0],
              [-80.905580118685023, 35.585072411799892, 0.0],
              [-80.906112517771049, 35.584453878112924, 0.0],
              [-80.906308184261661, 35.583194278458159, 0.0],
              [-80.90622418536681, 35.583148478462157, 0.0],
              [-80.906167717599772, 35.582392677828231, 0.0],
              [-80.905999384351716, 35.582095011767699, 0.0],
              [-80.905550784771734, 35.581728678132372, 0.0],
              [-80.904597384331893, 35.581339611911062, 0.0],
              [-80.904456984367371, 35.580927277721806, 0.0],
              [-80.903868318675634, 35.580652611133374, 0.0],
              [-80.903812118475301, 35.580492411165942, 0.0],
              [-80.903980118287805, 35.579919810951409, 0.0],
              [-80.903867918503238, 35.579782410994056, 0.0],
              [-80.902998784569661, 35.579393211884408, 0.0],
              [-80.901989384406747, 35.579141611145012, 0.0],
              [-80.901821185150482, 35.578981211787749, 0.0],
              [-80.900699718298711, 35.57845467827827, 0.0],
              [-80.900615384556389, 35.578202811584809, 0.0],
              [-80.900222784626763, 35.57779067792228, 0.0],
              [-80.900166718438342, 35.577492878025303, 0.0],
              [-80.900559118192646, 35.577424211334915, 0.0],
              [-80.901063918271973, 35.577973678463437, 0.0],
              [-80.90207331861447, 35.578477411035102, 0.0],
              [-80.903166718174219, 35.57866027803982, 0.0],
              [-80.903278718670833, 35.578500011341426, 0.0],
              [-80.903390718544983, 35.577927278483706, 0.0],
              [-80.903755118725513, 35.577904278315053, 0.0],
              [-80.903867517936902, 35.578522678488952, 0.0],
              [-80.90468051858619, 35.578637011268349, 0.0],
              [-80.904568585325904, 35.579186678216779, 0.0],
              [-80.904652718037994, 35.579255411084461, 0.0],
              [-80.905465517812573, 35.579209411078033, 0.0],
              [-80.90557771851914, 35.579255078105788, 0.0],
              [-80.905605785047257, 35.579415411737465, 0.0],
              [-80.905409917812065, 35.579804878041834, 0.0],
              [-80.905578184265252, 35.580010877715772, 0.0],
              [-80.905690317602591, 35.580354478605493, 0.0],
              [-80.906307384423542, 35.580789478456644, 0.0],
              [-80.906447718180146, 35.581155811784313, 0.0],
              [-80.906727984332278, 35.581339011131661, 0.0],
              [-80.907793184709618, 35.581270011183463, 0.0],
              [-80.9079333853755, 35.581407277735565, 0.0],
              [-80.907737585342943, 35.582048677615262, 0.0],
              [-80.907933985083517, 35.582300477956778, 0.0],
              [-80.907766185238188, 35.583468611574091, 0.0],
              [-80.907990584611767, 35.583743411194703, 0.0],
              [-80.908215118351819, 35.584476278293046, 0.0],
              [-80.908411518385847, 35.584682411419138, 0.0],
              [-80.909420918337347, 35.584773478196183, 0.0],
              [-80.909672985332861, 35.584407011552102, 0.0],
              [-80.910205718339455, 35.584269477802685, 0.0],
              [-80.910261718014297, 35.584406878385792, 0.0],
              [-80.910177985249248, 35.584979477729043, 0.0],
              [-80.910710917912013, 35.585574811388724, 0.0],
              [-80.911271584348754, 35.585666211785124, 0.0],
              [-80.911944384577112, 35.585528611392682, 0.0],
              [-80.912168984844456, 35.586169811018436, 0.0],
              [-80.912337318766987, 35.586307210999784, 0.0],
              [-80.913430718147538, 35.586306811509814, 0.0],
              [-80.913879118484246, 35.58605467825528, 0.0],
              [-80.914271918135654, 35.586604277806899, 0.0],
              [-80.914636718081979, 35.586901878151089, 0.0],
              [-80.915000984951078, 35.586924611104941, 0.0],
              [-80.915505317843184, 35.586237478044886, 0.0],
              [-80.915588718634211, 35.584817411166433, 0.0],
              [-80.915195985272902, 35.584405277676254, 0.0],
              [-80.915139518737533, 35.583489211368203, 0.0],
              [-80.91477478481265, 35.583260277885188, 0.0],
              [-80.914662118050046, 35.581954811318752, 0.0],
              [-80.914157118434716, 35.581084678448086, 0.0],
              [-80.91384858536675, 35.580787078395453, 0.0],
              [-80.913708118368078, 35.580306211027263, 0.0],
              [-80.913175317692691, 35.579871211899544, 0.0],
              [-80.912922584506632, 35.579275811826669, 0.0],
              [-80.913174918052718, 35.579207011521916, 0.0],
              [-80.913847917577215, 35.579550277981141, 0.0],
              [-80.914240584447683, 35.579893811601842, 0.0],
              [-80.914408918322493, 35.580168611857353, 0.0],
              [-80.91491371809893, 35.580557677953394, 0.0],
              [-80.915642718300603, 35.580672011589229, 0.0],
              [-80.915951184407817, 35.580855077894576, 0.0],
              [-80.915726984677917, 35.581198677622375, 0.0],
              [-80.915839585352472, 35.582114811371788, 0.0],
              [-80.916176184281966, 35.582320811441527, 0.0],
              [-80.916372584679479, 35.582595611362137, 0.0],
              [-80.916148384706787, 35.582893277786411, 0.0],
              [-80.916204718240749, 35.583397077893146, 0.0],
              [-80.916541384734828, 35.583809411776464, 0.0],
              [-80.917074184509829, 35.58417567763663, 0.0],
              [-80.91718691793848, 35.584908411599599, 0.0],
              [-80.917523384601736, 35.585114411094587, 0.0],
              [-80.917747985239373, 35.585618211031772, 0.0],
              [-80.918336918461506, 35.586076077806155, 0.0],
              [-80.919206184661675, 35.586373478571652, 0.0],
              [-80.91931858443364, 35.586762811500712, 0.0],
              [-80.91976738424836, 35.587152011147367, 0.0],
              [-80.920804784750601, 35.587220277701483, 0.0],
              [-80.921029117605642, 35.587311811779934, 0.0],
              [-80.921478118356205, 35.587907078385037, 0.0],
              [-80.921366184455891, 35.588433811385137, 0.0],
              [-80.921255317915154, 35.590563878092205, 0.0],
              [-80.921732385358226, 35.591113278068917, 0.0],
              [-80.922152984335199, 35.591159011686337, 0.0],
              [-80.922433118214997, 35.590952677800516, 0.0],
              [-80.923806984449868, 35.590906278565157, 0.0],
              [-80.924087318402513, 35.590814611896199, 0.0],
              [-80.924311385198919, 35.590471011435142, 0.0],
              [-80.924366918008914, 35.589646477946729, 0.0],
              [-80.924198518738493, 35.589165611079174, 0.0],
              [-80.923945918651626, 35.58884507802609, 0.0],
              [-80.923889718028249, 35.588638878121756, 0.0],
              [-80.924141784293624, 35.588363877782157, 0.0],
              [-80.924085717653867, 35.58827241136315, 0.0],
              [-80.923076385272878, 35.588204211858496, 0.0],
              [-80.922851985332386, 35.588066811841848, 0.0],
              [-80.92282391790522, 35.587883611258903, 0.0],
              [-80.923216184956971, 35.587700211661904, 0.0],
              [-80.923552517612876, 35.587402277866779, 0.0],
              [-80.924337384586423, 35.587104211678991, 0.0],
              [-80.924729385137468, 35.586371277807018, 0.0],
              [-80.924728918165826, 35.585500878268597, 0.0],
              [-80.924139984972896, 35.585272010946049, 0.0],
              [-80.923831385424904, 35.585020277965199, 0.0],
              [-80.923438918637501, 35.584928811701815, 0.0],
              [-80.922737717626177, 35.584448211429851, 0.0],
              [-80.922737718173948, 35.584333678062102, 0.0],
              [-80.923241985122033, 35.583944210968028, 0.0],
              [-80.923718118017248, 35.583165211859075, 0.0],
              [-80.923605785421714, 35.582730211795713, 0.0],
              [-80.923353317898773, 35.582478277658822, 0.0],
              [-80.922904584762222, 35.582249478292567, 0.0],
              [-80.921362718527035, 35.582204277686344, 0.0],
              [-80.921278517961269, 35.582135611512228, 0.0],
              [-80.921866984350444, 35.581791811030158, 0.0],
              [-80.922007118471655, 35.5815628117629, 0.0],
              [-80.923773384358867, 35.581722411566155, 0.0],
              [-80.924502518334336, 35.58197387842592, 0.0],
              [-80.925035384829812, 35.582431811042689, 0.0],
              [-80.925708785250379, 35.583462011802332, 0.0],
              [-80.925989318131101, 35.58357647785688, 0.0],
              [-80.926773984685795, 35.583095211790436, 0.0],
              [-80.92682978481001, 35.582637211216081, 0.0],
              [-80.92598798460277, 35.581492411219315, 0.0],
              [-80.925202918663629, 35.581263678181259, 0.0],
              [-80.92486618512757, 35.580874478116506, 0.0],
              [-80.924809784810364, 35.58032487817956, 0.0],
              [-80.924641518610358, 35.580187611463217, 0.0],
              [-80.924585117907995, 35.579615011308015, 0.0],
              [-80.924276518623714, 35.579409011112133, 0.0],
              [-80.924304385320482, 35.578996611294059, 0.0],
              [-80.924191984419224, 35.578584478277541, 0.0],
              [-80.924331717645074, 35.577897277892212, 0.0],
              [-80.923910918750678, 35.577325011383614, 0.0],
              [-80.923517985099906, 35.576569278587023, 0.0],
              [-80.922508384704059, 35.575859811126556, 0.0],
              [-80.922115918465508, 35.575859878573688, 0.0],
              [-80.921639718294159, 35.576524277695988, 0.0],
              [-80.920350385347675, 35.576799678035648, 0.0],
              [-80.920378318192974, 35.576685011828367, 0.0],
              [-80.920910584610368, 35.576203877908775, 0.0],
              [-80.921218718495339, 35.575585478426433, 0.0],
              [-80.921274317634925, 35.574921277785123, 0.0],
              [-80.920825718309402, 35.574600811047986, 0.0],
              [-80.920068718612669, 35.574303411863468, 0.0],
              [-80.919451984789475, 35.57446387812444, 0.0],
              [-80.919311584599697, 35.574028877992589, 0.0],
              [-80.9190591179009, 35.573639611481326, 0.0],
              [-80.918302117942119, 35.573571078215188, 0.0],
              [-80.917881584325372, 35.573227811769655, 0.0],
              [-80.917236785259163, 35.573205011409826, 0.0],
              [-80.917180584346028, 35.573136411682903, 0.0],
              [-80.917376518406869, 35.572495078271672, 0.0],
              [-80.917236317626902, 35.572243211716781, 0.0],
              [-80.916759517849627, 35.571785278482842, 0.0],
              [-80.916310784678529, 35.571579277803593, 0.0],
              [-80.916170718153822, 35.571396211755449, 0.0],
              [-80.916310718391003, 35.571304477912697, 0.0],
              [-80.917319985293034, 35.571533211039743, 0.0],
              [-80.917544318606289, 35.571647611134864, 0.0],
              [-80.917908984862237, 35.572128478403329, 0.0],
              [-80.918553985409773, 35.572723611462045, 0.0],
              [-80.91897458504792, 35.572929611255596, 0.0],
              [-80.91914271810937, 35.572860877840071, 0.0],
              [-80.919478917983056, 35.572425478026837, 0.0],
              [-80.919731184562522, 35.572379611408032, 0.0],
              [-80.919843718536242, 35.573043811027922, 0.0],
              [-80.920180185081563, 35.57334141170584, 0.0],
              [-80.921189517940874, 35.573524211609218, 0.0],
              [-80.921609917700579, 35.573386611485475, 0.0],
              [-80.921946517581176, 35.573730011031564, 0.0],
              [-80.92236698504243, 35.573913078223981, 0.0],
              [-80.922759384286451, 35.573844211930343, 0.0],
              [-80.922871518197468, 35.573683877767188, 0.0],
              [-80.922927118194565, 35.572882211061007, 0.0],
              [-80.922814517600074, 35.572012011683967, 0.0],
              [-80.923290918453972, 35.571920077832239, 0.0],
              [-80.923375384885048, 35.572721811805749, 0.0],
              [-80.923515785383231, 35.572904878303902, 0.0],
              [-80.923488318019821, 35.57382101157237, 0.0],
              [-80.923656584243716, 35.573958411108521, 0.0],
              [-80.923769184343527, 35.574645277817638, 0.0],
              [-80.924610518704597, 35.57540081166384, 0.0],
              [-80.92494698465228, 35.575538077708508, 0.0],
              [-80.925283585268716, 35.575789878327143, 0.0],
              [-80.925704118385497, 35.57583547829325, 0.0],
              [-80.925928317715986, 35.575675077957449, 0.0],
              [-80.92584391820678, 35.57514841148658, 0.0],
              [-80.925927785018985, 35.575056811403471, 0.0],
              [-80.92696491850154, 35.574827278407675, 0.0],
              [-80.927132984350123, 35.574598078015264, 0.0],
              [-80.927076785015359, 35.574392011028102, 0.0],
              [-80.926796317719408, 35.574277611766625, 0.0],
              [-80.926768318614592, 35.574094411259551, 0.0],
              [-80.927216584896058, 35.573888078590635, 0.0],
              [-80.927496785265589, 35.573590278081866, 0.0],
              [-80.927496184410629, 35.572720011442911, 0.0],
              [-80.926346385402141, 35.572056277810063, 0.0],
              [-80.92643051864205, 35.571895877936377, 0.0],
              [-80.927383384807015, 35.571758077704516, 0.0],
              [-80.927971985102985, 35.571528678182084, 0.0],
              [-80.928251985313821, 35.571185077826151, 0.0],
              [-80.928223117686585, 35.569925478573154, 0.0],
              [-80.927998517964866, 35.569261410989249, 0.0],
              [-80.927830184758889, 35.56905541170309, 0.0],
              [-80.92780198465627, 35.568872211403274, 0.0],
              [-80.927016317688413, 35.56756701164079, 0.0],
              [-80.926988118687262, 35.56724627797837, 0.0],
              [-80.927212317608337, 35.567223411580891, 0.0],
              [-80.927632917766644, 35.56752087793609, 0.0],
              [-80.928166117927759, 35.568322278528619, 0.0],
              [-80.928390717711039, 35.56889467793274, 0.0],
              [-80.928867585394144, 35.569513010975619, 0.0],
              [-80.928979985039646, 35.569902277874228, 0.0],
              [-80.929288517571578, 35.570291411895134, 0.0],
              [-80.929457185320089, 35.570932611482348, 0.0],
              [-80.929681517913821, 35.571070011276454, 0.0],
              [-80.930410518573012, 35.571161211041421, 0.0],
              [-80.930999184346518, 35.571321211190764, 0.0],
              [-80.931055384977867, 35.571435811896222, 0.0],
              [-80.930158384788371, 35.571619411913211, 0.0],
              [-80.92928951860921, 35.571688478451847, 0.0],
              [-80.9290653180513, 35.57178021170099, 0.0],
              [-80.92909358455772, 35.572123678275972, 0.0],
              [-80.929205918687558, 35.572306878192641, 0.0],
              [-80.929850985368475, 35.572787611063667, 0.0],
              [-80.929879118760525, 35.572947811738182, 0.0],
              [-80.929626717963089, 35.572993677932864, 0.0],
              [-80.928925918475571, 35.572925411421913, 0.0],
              [-80.928813917789313, 35.573108678363106, 0.0],
              [-80.928758184823508, 35.573589678356022, 0.0],
              [-80.929487384397419, 35.574116078156706, 0.0],
              [-80.92951558530136, 35.574299277888926, 0.0],
              [-80.92931951795579, 35.574505478314975, 0.0],
              [-80.928506718542877, 35.57478067784632, 0.0],
              [-80.928310584712875, 35.574986878600939, 0.0],
              [-80.92825478475585, 35.575284678587579, 0.0],
              [-80.927946517709643, 35.575445211640854, 0.0],
              [-80.927694384431589, 35.575743011658268, 0.0],
              [-80.927638517817172, 35.57594921158244, 0.0],
              [-80.927358318252715, 35.576269877990995, 0.0],
              [-80.927302518253228, 35.576659278082701, 0.0],
              [-80.927386585048538, 35.576773811226644, 0.0],
              [-80.927527785189625, 35.578239411254962, 0.0],
              [-80.927752317911853, 35.578583011875303, 0.0],
              [-80.927500385055268, 35.57915567795115, 0.0],
              [-80.927445118210628, 35.580438078556753, 0.0],
              [-80.927585718531404, 35.581010677821965, 0.0],
              [-80.92778211838035, 35.581308278470416, 0.0],
              [-80.927922584715063, 35.581789277817649, 0.0],
              [-80.928174984968564, 35.581926477804757, 0.0],
              [-80.928931585290769, 35.581445211335804, 0.0],
              [-80.929800518175384, 35.581055478293514, 0.0],
              [-80.930248718423982, 35.580597211093476, 0.0],
              [-80.930304518623061, 35.580116211164864, 0.0],
              [-80.930107918554015, 35.579681077916817, 0.0],
              [-80.930275785042085, 35.579131411851513, 0.0],
              [-80.930191584396212, 35.579062677726597, 0.0],
              [-80.930191384412041, 35.578902411488443, 0.0],
              [-80.930107317708732, 35.578833811343216, 0.0],
              [-80.930079118237444, 35.578513078116174, 0.0],
              [-80.930499517742959, 35.578352611300176, 0.0],
              [-80.931031785345525, 35.57796301150173, 0.0],
              [-80.931087584536698, 35.577619478529009, 0.0],
              [-80.930638717846179, 35.577001277890858, 0.0],
              [-80.930498184971171, 35.576428678271434, 0.0],
              [-80.930750384494246, 35.576359877705691, 0.0],
              [-80.931451585276932, 35.57690921169872, 0.0],
              [-80.931703918034103, 35.576932011786923, 0.0],
              [-80.93192771836388, 35.576153277804657, 0.0],
              [-80.931730985366301, 35.575580811272893, 0.0],
              [-80.931814984893876, 35.575420411808565, 0.0],
              [-80.9318425184738, 35.574641678239836, 0.0],
              [-80.932094584737442, 35.574366678007166, 0.0],
              [-80.932178584250153, 35.57413767845528, 0.0],
              [-80.933159118202482, 35.573312811356914, 0.0],
              [-80.933607518292135, 35.573175078057403, 0.0],
              [-80.933635718572233, 35.573427011010949, 0.0],
              [-80.933411585272879, 35.573656211220928, 0.0],
              [-80.933299784624481, 35.574068411871529, 0.0],
              [-80.932991584380929, 35.574412077774944, 0.0],
              [-80.932740184401098, 35.575580411160566, 0.0],
              [-80.932796585354637, 35.576015478021652, 0.0],
              [-80.932992784527187, 35.576107011314186, 0.0],
              [-80.933273184814936, 35.576061011459096, 0.0],
              [-80.933721118418035, 35.575236211518408, 0.0],
              [-80.933973317873523, 35.57514447802231, 0.0],
              [-80.934085517651212, 35.575236077755775, 0.0],
              [-80.934085717959462, 35.575419278270928, 0.0],
              [-80.934618518591492, 35.575648011344548, 0.0],
              [-80.934618585218075, 35.575854211146165, 0.0],
              [-80.934086318018728, 35.576197878440745, 0.0],
              [-80.934030317729977, 35.576427077853303, 0.0],
              [-80.93478731768576, 35.576586877753194, 0.0],
              [-80.934871584711374, 35.576793078533647, 0.0],
              [-80.933189985016881, 35.577297677949311, 0.0],
              [-80.932825717944965, 35.577618611325711, 0.0],
              [-80.932826117766055, 35.578076677941773, 0.0],
              [-80.933078584577686, 35.578351411497216, 0.0],
              [-80.933695317697314, 35.578282410969621, 0.0],
              [-80.93389171863862, 35.578488278071148, 0.0],
              [-80.93400411859659, 35.578831811122328, 0.0],
              [-80.934004184641864, 35.57908367840934, 0.0],
              [-80.933247385232193, 35.579130011624727, 0.0],
              [-80.932798918271033, 35.579267611689772, 0.0],
              [-80.932434718422911, 35.579657078013476, 0.0],
              [-80.932407117764555, 35.580321278112343, 0.0],
              [-80.932379785377734, 35.581283211835292, 0.0],
              [-80.932520184589308, 35.581466411164165, 0.0],
              [-80.932772517833527, 35.581489077996601, 0.0],
              [-80.933108718600423, 35.581214078015677, 0.0],
              [-80.933781318096791, 35.581030678106849, 0.0],
              [-80.934537718187357, 35.5803202781061, 0.0],
              [-80.935014185272095, 35.580068077821053, 0.0],
              [-80.936079517905554, 35.579975878352144, 0.0],
              [-80.936387717819102, 35.579769677993731, 0.0],
              [-80.937003718293823, 35.578967811671852, 0.0],
              [-80.937676384943387, 35.578738411049365, 0.0],
              [-80.937900384441079, 35.578394611377419, 0.0],
              [-80.937927985258, 35.577890811875044, 0.0],
              [-80.937843917661795, 35.577799211934888, 0.0],
              [-80.937759185021278, 35.57690607855352, 0.0],
              [-80.937955184291553, 35.576814411250709, 0.0],
              [-80.938319784463587, 35.577043077960006, 0.0],
              [-80.938432118572123, 35.577249211461925, 0.0],
              [-80.938852784970393, 35.57740927821731, 0.0],
              [-80.939245317630522, 35.577340411921007, 0.0],
              [-80.939329185171232, 35.577203011341958, 0.0],
              [-80.939244718169761, 35.576676278083539, 0.0],
              [-80.939160584901956, 35.576630478065383, 0.0],
              [-80.938823518410032, 35.575806078427384, 0.0],
              [-80.938795317632938, 35.575485477686463, 0.0],
              [-80.939019384394143, 35.575462411403059, 0.0],
              [-80.939412185124539, 35.575805810999391, 0.0],
              [-80.940449784383844, 35.576194611315117, 0.0],
              [-80.940954184835064, 35.576079811279428, 0.0],
              [-80.941066318429833, 35.575988078490631, 0.0],
              [-80.9412615849497, 35.574957478413808, 0.0],
              [-80.941457917819491, 35.574911478590842, 0.0],
              [-80.941654185206204, 35.57507167772355, 0.0],
              [-80.941654384501973, 35.575209078514447, 0.0],
              [-80.941766517949077, 35.575323611332955, 0.0],
              [-80.941851184799305, 35.576033477916468, 0.0],
              [-80.942131918041881, 35.576399811083178, 0.0],
              [-80.942188317561204, 35.57681201133046, 0.0],
              [-80.942076318588462, 35.576903611752748, 0.0],
              [-80.941291384201307, 35.576995677845353, 0.0],
              [-80.940758917658556, 35.577156278521151, 0.0],
              [-80.940450784875267, 35.577408477805726, 0.0],
              [-80.940394918015897, 35.577614678085581, 0.0],
              [-80.94005871866564, 35.578027078260199, 0.0],
              [-80.940030918251452, 35.578279010978783, 0.0],
              [-80.939554718278501, 35.578737278132579, 0.0],
              [-80.939526917866928, 35.578966411824865, 0.0],
              [-80.940087518361011, 35.579034678176349, 0.0],
              [-80.940115717578536, 35.579240811633035, 0.0],
              [-80.939639584947628, 35.579744877780378, 0.0],
              [-80.939639784532858, 35.579928078278989, 0.0],
              [-80.940032318541796, 35.580088278400112, 0.0],
              [-80.940088784811223, 35.580500478022856, 0.0],
              [-80.940817584201653, 35.580523011239002, 0.0],
              [-80.941294584303463, 35.580820411359412, 0.0],
              [-80.941238585352636, 35.581072411908082, 0.0],
              [-80.940706118203025, 35.581187277940884, 0.0],
              [-80.940061518487013, 35.58143961105516, 0.0],
              [-80.939949718224838, 35.581851878510292, 0.0],
              [-80.940034318380754, 35.582401478445952, 0.0],
              [-80.93983811784409, 35.582653477809245, 0.0],
              [-80.939810784880962, 35.583409278481753, 0.0],
              [-80.940259784741244, 35.583981611286994, 0.0],
              [-80.94051211825726, 35.584004411180537, 0.0],
              [-80.940735918035102, 35.583431678581007, 0.0],
              [-80.941745185137748, 35.583431077853128, 0.0],
              [-80.94210951835511, 35.583224811696397, 0.0],
              [-80.942165384431391, 35.582972811021946, 0.0],
              [-80.94196898510539, 35.582858477906285, 0.0],
              [-80.94194091810671, 35.58267521174885, 0.0],
              [-80.942445117959906, 35.582171078074509, 0.0],
              [-80.942528985229842, 35.581964811273195, 0.0],
              [-80.942332318039377, 35.581415411748026, 0.0],
              [-80.942920718118529, 35.581094411243676, 0.0],
              [-80.943284718446137, 35.580475811749992, 0.0],
              [-80.943396184525284, 35.579811611271964, 0.0],
              [-80.943620384468161, 35.579719878522027, 0.0],
              [-80.944125784947673, 35.580566877735201, 0.0],
              [-80.943902117939274, 35.581300011450502, 0.0],
              [-80.94401438532698, 35.581437277716077, 0.0],
              [-80.944183317706418, 35.582170011414405, 0.0],
              [-80.944323517749226, 35.582284478578906, 0.0],
              [-80.944800117551409, 35.582284211866686, 0.0],
              [-80.945164385282823, 35.582123678083782, 0.0],
              [-80.94524818498472, 35.581803011609765, 0.0],
              [-80.945051585090667, 35.581322077741504, 0.0],
              [-80.945135585286195, 35.581207611030742, 0.0],
              [-80.945640184595433, 35.581115811737121, 0.0],
              [-80.945920384386326, 35.580978011531009, 0.0],
              [-80.946340384440589, 35.580313678509668, 0.0],
              [-80.946367718271745, 35.579534878537913, 0.0],
              [-80.947432918259821, 35.579511411092938, 0.0],
              [-80.948077318201342, 35.578961278554424, 0.0],
              [-80.948244984638947, 35.578480278483667, 0.0],
              [-80.949309517934708, 35.577632211846897, 0.0],
              [-80.950010117577648, 35.57737981181031, 0.0],
              [-80.950513918502892, 35.576600811100647, 0.0],
              [-80.950513784633827, 35.576326011820207, 0.0],
              [-80.950261518005192, 35.576326078173842, 0.0],
              [-80.949336717723298, 35.576738878142493, 0.0],
              [-80.948748117910668, 35.576831011626808, 0.0],
              [-80.948495784818789, 35.57676247838927, 0.0],
              [-80.947206584494865, 35.577198278060997, 0.0],
              [-80.94653378463596, 35.577244477952341, 0.0],
              [-80.94586091857046, 35.577176211124979, 0.0],
              [-80.945916785015712, 35.576855477926202, 0.0],
              [-80.946729518079607, 35.576557411019706, 0.0],
              [-80.947065584812051, 35.576282278404761, 0.0],
              [-80.947120718556633, 35.575228678411555, 0.0],
              [-80.946699918688637, 35.574839611369946, 0.0],
              [-80.946615584592905, 35.574610611539335, 0.0],
              [-80.945802318155131, 35.574221811185282, 0.0],
              [-80.945773985173403, 35.573947011703126, 0.0],
              [-80.945970184358018, 35.573855278603695, 0.0],
              [-80.946474784368377, 35.573855011895631, 0.0],
              [-80.946979584334116, 35.574060811104367, 0.0],
              [-80.947484518106137, 35.574472678302506, 0.0],
              [-80.948101984757344, 35.57538841159424, 0.0],
              [-80.948410584509389, 35.575571478329422, 0.0],
              [-80.949083184931681, 35.5754336777471, 0.0],
              [-80.949363118630032, 35.574952610947996, 0.0],
              [-80.94936278472801, 35.574586078481587, 0.0],
              [-80.948913784842901, 35.574082478314509, 0.0],
              [-80.948493117905372, 35.573899611789443, 0.0],
              [-80.948408917642311, 35.57378507809964, 0.0],
              [-80.948492784768845, 35.573510278388262, 0.0],
              [-80.948632918051445, 35.573418478055764, 0.0],
              [-80.949557985032712, 35.573509411312237, 0.0],
              [-80.949810517715207, 35.573692611486749, 0.0],
              [-80.950511384954169, 35.573760811097166, 0.0],
              [-80.950903984916195, 35.573898011470078, 0.0],
              [-80.951268384495265, 35.573920678274519, 0.0],
              [-80.951380317822839, 35.573783078593721, 0.0],
              [-80.952080584626316, 35.573393411797277, 0.0],
              [-80.95241638472929, 35.57263741141864, 0.0],
              [-80.951911384370177, 35.572294211797562, 0.0],
              [-80.951742984788268, 35.571973611755894, 0.0],
              [-80.950957384255304, 35.571287077714892, 0.0],
              [-80.950032118238795, 35.570990011162252, 0.0],
              [-80.950003985387454, 35.57087541131262, 0.0],
              [-80.951097118108535, 35.570828877660887, 0.0],
              [-80.951545518303632, 35.570668278425387, 0.0],
              [-80.951881917984963, 35.570736811262421, 0.0],
              [-80.95249898476095, 35.5711486778712, 0.0],
              [-80.952807384969518, 35.571194210966802, 0.0],
              [-80.952919384420966, 35.571056811866391, 0.0],
              [-80.953815985235593, 35.570758411211095, 0.0],
              [-80.954124117539109, 35.570437611931133, 0.0],
              [-80.954123784235534, 35.570162811272333, 0.0],
              [-80.95367498419148, 35.569819478590738, 0.0],
              [-80.952945984760774, 35.569613811617586, 0.0],
              [-80.952861518509067, 35.569178677941899, 0.0],
              [-80.952272384837826, 35.568629411698652, 0.0],
              [-80.952328185054611, 35.568446077929195, 0.0],
              [-80.953393317586318, 35.568353877800135, 0.0],
              [-80.953925717984035, 35.568193211253536, 0.0],
              [-80.953953584779001, 35.568032878218801, 0.0],
              [-80.953757184631911, 35.567895611139363, 0.0],
              [-80.952860117878842, 35.567644210978884, 0.0],
              [-80.952299117650313, 35.567209478036737, 0.0],
              [-80.951373918246702, 35.567072611649195, 0.0],
              [-80.951121384430181, 35.566889611053163, 0.0],
              [-80.950560584359849, 35.566706811002241, 0.0],
              [-80.950055585063012, 35.566180277752402, 0.0],
              [-80.949887118000092, 35.565722278398198, 0.0],
              [-80.949970984206146, 35.565607811628944, 0.0],
              [-80.950671784840409, 35.565584410983774, 0.0],
              [-80.952606185115826, 35.565926677727546, 0.0],
              [-80.952914318673123, 35.565812010992417, 0.0],
              [-80.953138185147466, 35.565445411240596, 0.0],
              [-80.952408384426292, 35.564392277881581, 0.0],
              [-80.953276785162018, 35.563842077660119, 0.0],
              [-80.953444717548194, 35.563567211251033, 0.0],
              [-80.953472184714158, 35.563063278501168, 0.0],
              [-80.952967185311294, 35.562513877997418, 0.0],
              [-80.952966184378354, 35.561460411897535, 0.0],
              [-80.952292785210247, 35.560682211239225, 0.0],
              [-80.95181611802596, 35.560476278478042, 0.0],
              [-80.951647184659492, 35.559789411316956, 0.0],
              [-80.951226584386063, 35.559583611514718, 0.0],
              [-80.951141985359826, 35.559011078074199, 0.0],
              [-80.950917584638674, 35.558759211382224, 0.0],
              [-80.950860918640686, 35.558095078376738, 0.0],
              [-80.950720584512339, 35.557934878621744, 0.0],
              [-80.950663984958339, 35.557247811142815, 0.0],
              [-80.950383585028348, 35.55713347797974, 0.0],
              [-80.950298718180221, 35.556309011280725, 0.0],
              [-80.949345718625679, 35.556195078509433, 0.0],
              [-80.949289985082899, 35.556538678455702, 0.0],
              [-80.949598518143006, 35.556790411424053, 0.0],
              [-80.949654917665512, 35.557225611011901, 0.0],
              [-80.949570984992079, 35.557317278028826, 0.0],
              [-80.949628317985415, 35.558714211338604, 0.0],
              [-80.949404117810374, 35.558806010993244, 0.0],
              [-80.949039785255053, 35.558668811699121, 0.0],
              [-80.948590918342902, 35.558279678145944, 0.0],
              [-80.948534584918633, 35.55798207834092, 0.0],
              [-80.948001917672386, 35.557867877876696, 0.0],
              [-80.94769351800403, 35.557616078608937, 0.0],
              [-80.947413117912248, 35.55750181166659, 0.0],
              [-80.946964785108889, 35.557662411946318, 0.0],
              [-80.946824784454662, 35.557845611213025, 0.0],
              [-80.946685118155671, 35.558349611000182, 0.0],
              [-80.946349117716323, 35.558647611034075, 0.0],
              [-80.946321718408441, 35.559380478054628, 0.0],
              [-80.94668638457145, 35.559769611527521, 0.0],
              [-80.946686784272018, 35.560296411940982, 0.0],
              [-80.946883317834278, 35.56045661140125, 0.0],
              [-80.946911517566775, 35.560777210986714, 0.0],
              [-80.947135918728975, 35.560960278413916, 0.0],
              [-80.947024518567375, 35.561739011177565, 0.0],
              [-80.947136918456849, 35.562128411291383, 0.0],
              [-80.947417718361109, 35.562609211444901, 0.0],
              [-80.947894584313289, 35.563021078081221, 0.0],
              [-80.947950917752877, 35.563387611481275, 0.0],
              [-80.948035117858396, 35.563456211219631, 0.0],
              [-80.947783384343239, 35.564212077859054, 0.0],
              [-80.947897118590333, 35.565906877940499, 0.0],
              [-80.94764518509524, 35.566227611229507, 0.0],
              [-80.947617718716728, 35.566914811359432, 0.0],
              [-80.947477717696387, 35.567120877735157, 0.0],
              [-80.94745018419512, 35.567624811011882, 0.0],
              [-80.947843385092924, 35.56844907769689, 0.0],
              [-80.947843518629114, 35.568701011380917, 0.0],
              [-80.947367117948417, 35.568769878307975, 0.0],
              [-80.947226785320737, 35.568541011546436, 0.0],
              [-80.946974384511705, 35.568358011199926, 0.0],
              [-80.946049184713786, 35.568175411664484, 0.0],
              [-80.945712785074278, 35.568175477726697, 0.0],
              [-80.944731984351463, 35.568565478282778, 0.0],
              [-80.943919718319421, 35.569207211934931, 0.0],
              [-80.94330331777627, 35.569322078299983, 0.0],
              [-80.942014318102423, 35.569826678483587, 0.0],
              [-80.941425785083339, 35.569941611673286, 0.0],
              [-80.941341517616053, 35.569850011303025, 0.0],
              [-80.941425584407568, 35.569735478542825, 0.0],
              [-80.943246717950089, 35.568818277792765, 0.0],
              [-80.94347071801954, 35.568520411279898, 0.0],
              [-80.943834918022333, 35.568314077962683, 0.0],
              [-80.944058918184382, 35.568016211254381, 0.0],
              [-80.945095517951856, 35.56739721097324, 0.0],
              [-80.946160317759905, 35.567007211265626, 0.0],
              [-80.946159985137896, 35.56666367846357, 0.0],
              [-80.945823384788724, 35.566412011604385, 0.0],
              [-80.945738718232747, 35.565679078263216, 0.0],
              [-80.945541984756545, 35.565175411051847, 0.0],
              [-80.945878318553525, 35.565014878479325, 0.0],
              [-80.945849784591061, 35.564602678135671, 0.0],
              [-80.945401184587837, 35.564396811434854, 0.0],
              [-80.945064585294503, 35.564099278186291, 0.0],
              [-80.944391918296148, 35.564030878227442, 0.0],
              [-80.944363584423726, 35.563733277894237, 0.0],
              [-80.944475384919002, 35.563435411830376, 0.0],
              [-80.944391118479643, 35.563229277984895, 0.0],
              [-80.94413871834746, 35.563023278516326, 0.0],
              [-80.944110385378991, 35.562725611942199, 0.0],
              [-80.943829984712522, 35.562542611256731, 0.0],
              [-80.943577717820816, 35.562542678238387, 0.0],
              [-80.942400984243704, 35.563047277784342, 0.0],
              [-80.942149118531773, 35.563482611005973, 0.0],
              [-80.94178491769334, 35.563780477955881, 0.0],
              [-80.941561118096899, 35.564170011935914, 0.0],
              [-80.940720718232683, 35.564926278060859, 0.0],
              [-80.940412918705889, 35.565499011144311, 0.0],
              [-80.940160784350709, 35.565613678077547, 0.0],
              [-80.939992184433422, 35.565224411063916, 0.0],
              [-80.940243984492767, 35.564605878407413, 0.0],
              [-80.940299384321264, 35.563872878234541, 0.0],
              [-80.940103118351004, 35.56359821158847, 0.0],
              [-80.939542185147587, 35.563277878154182, 0.0],
              [-80.939738185055489, 35.56298007833886, 0.0],
              [-80.940746717887322, 35.562429811824337, 0.0],
              [-80.940970718448057, 35.56215487846783, 0.0],
              [-80.941082384990963, 35.561582211779843, 0.0],
              [-80.94161478499548, 35.561352810995977, 0.0],
              [-80.942034584505677, 35.560711277699127, 0.0],
              [-80.942398785384967, 35.56039047834885, 0.0],
              [-80.942762318321542, 35.559497211126747, 0.0],
              [-80.942677918524609, 35.55908501124437, 0.0],
              [-80.942285518697147, 35.558993611740114, 0.0],
              [-80.941865185068693, 35.559154077655116, 0.0],
              [-80.940436584721624, 35.560116811440032, 0.0],
              [-80.940156518267798, 35.560437611850489, 0.0],
              [-80.939652184575309, 35.560667011068887, 0.0],
              [-80.939372317589346, 35.561033478247126, 0.0],
              [-80.938951918254403, 35.561148277705257, 0.0],
              [-80.938503518564175, 35.561034011654634, 0.0],
              [-80.938026984307029, 35.56114881171959, 0.0],
              [-80.937467118471318, 35.561927677862535, 0.0],
              [-80.937159518021062, 35.562935678032545, 0.0],
              [-80.937132317747668, 35.563897611183165, 0.0],
              [-80.937272717954158, 35.564332611865588, 0.0],
              [-80.937581385160584, 35.564767678555221, 0.0],
              [-80.937469585118237, 35.565088278423325, 0.0],
              [-80.937273385002413, 35.565157077934707, 0.0],
              [-80.936628318605159, 35.564562011320263, 0.0],
              [-80.93640411857686, 35.564470478437805, 0.0],
              [-80.935114918640991, 35.564769011592027, 0.0],
              [-80.935114784739113, 35.564562878350806, 0.0],
              [-80.935702918381168, 35.564012811060216, 0.0],
              [-80.935758784548653, 35.563806678361104, 0.0],
              [-80.934889985294149, 35.563784278013671, 0.0],
              [-80.934945918351943, 35.563532278442793, 0.0],
              [-80.935870317632791, 35.562982077949265, 0.0],
              [-80.936262384551213, 35.562661277932236, 0.0],
              [-80.936318185066639, 35.562249011351433, 0.0],
              [-80.936009584654755, 35.561928611852935, 0.0],
              [-80.936009518178935, 35.56181407857504, 0.0],
              [-80.936457784308431, 35.56158487820899, 0.0],
              [-80.936485718538805, 35.561332811922462, 0.0],
              [-80.936148984490757, 35.561012278100229, 0.0],
              [-80.936148984879026, 35.560829078557028, 0.0],
              [-80.936877384756272, 35.560485211288928, 0.0],
              [-80.936848585005379, 35.559477478171786, 0.0],
              [-80.936259918021889, 35.559294611437146, 0.0],
              [-80.935783585274663, 35.559500878579136, 0.0],
              [-80.935167384246441, 35.560165411921886, 0.0],
              [-80.934298784509565, 35.560532411872536, 0.0],
              [-80.934214985078881, 35.5608300779583, 0.0],
              [-80.934018918123712, 35.561036411723279, 0.0],
              [-80.933290317715532, 35.561242878235412, 0.0],
              [-80.933066518601123, 35.561540611573129, 0.0],
              [-80.932422184508837, 35.562090678398633, 0.0],
              [-80.932170518583789, 35.562777878092959, 0.0],
              [-80.931946318008713, 35.562869611395939, 0.0],
              [-80.9317501178339, 35.56277821181839, 0.0],
              [-80.931049518667123, 35.562893011753175, 0.0],
              [-80.930993317767587, 35.562847077925248, 0.0],
              [-80.931076984939239, 35.562228677927664, 0.0],
              [-80.930544184587134, 35.56188547775885, 0.0],
              [-80.929534918313777, 35.561496611720983, 0.0],
              [-80.929478784638974, 35.561382211369313, 0.0],
              [-80.929786984314518, 35.561313278095547, 0.0],
              [-80.930936185022532, 35.561358611090867, 0.0],
              [-80.931608984547793, 35.56151847788886, 0.0],
              [-80.93197331809958, 35.561426678321205, 0.0],
              [-80.932393584871988, 35.561128678293976, 0.0],
              [-80.932589384308443, 35.560808011573954, 0.0],
              [-80.933401585196094, 35.55993741102295, 0.0],
              [-80.933429318073664, 35.559548011461736, 0.0],
              [-80.933176917885319, 35.559273211021257, 0.0],
              [-80.933120718227414, 35.559067077849427, 0.0],
              [-80.933933384420015, 35.558998011911065, 0.0],
              [-80.934661718733864, 35.558493811317696, 0.0],
              [-80.934885717926875, 35.558218878223222, 0.0],
              [-80.934913384295015, 35.557737877785229, 0.0],
              [-80.934436585154145, 35.557326011423115, 0.0],
              [-80.934408518337449, 35.557142678568738, 0.0],
              [-80.933987718247977, 35.556570277693879, 0.0],
              [-80.933595184729484, 35.556387277870314, 0.0],
              [-80.933370785137853, 35.556066677839119, 0.0],
              [-80.932697918482106, 35.555746477834234, 0.0],
              [-80.932389384938517, 35.555540411180367, 0.0],
              [-80.93185678422671, 35.555380411283615, 0.0],
              [-80.931464318509683, 35.555082878053248, 0.0],
              [-80.930062918017555, 35.554969078347071, 0.0],
              [-80.929978785288327, 35.554900411044741, 0.0],
              [-80.929950517645707, 35.554511077912267, 0.0],
              [-80.929641918431201, 35.554121877851962, 0.0],
              [-80.9299501843916, 35.55409881176719, 0.0],
              [-80.931295584635762, 35.554418811912235, 0.0],
              [-80.931884184723472, 35.554441411053247, 0.0],
              [-80.932472717721936, 35.554349477882035, 0.0],
              [-80.93255691781988, 35.554624278233426, 0.0],
              [-80.93275331822764, 35.554876077919829, 0.0],
              [-80.934210918147272, 35.555333411654502, 0.0],
              [-80.934659318683103, 35.555264478557561, 0.0],
              [-80.935416317997621, 35.555607611201339, 0.0],
              [-80.93561258507583, 35.555813678374797, 0.0],
              [-80.935865385258154, 35.556500611020283, 0.0],
              [-80.936370318516055, 35.557187411564271, 0.0],
              [-80.936818784957381, 35.557210077869833, 0.0],
              [-80.937042918293187, 35.557026811782563, 0.0],
              [-80.937070118706089, 35.555927411196251, 0.0],
              [-80.937378317815273, 35.555767011664074, 0.0],
              [-80.937994784266976, 35.555789478295381, 0.0],
              [-80.938190785054786, 35.555537411328991, 0.0],
              [-80.938470984466306, 35.555468611165644, 0.0],
              [-80.938527318058277, 35.555789211520846, 0.0],
              [-80.937995517716402, 35.556728611017803, 0.0],
              [-80.93799638454766, 35.557782078547255, 0.0],
              [-80.938444918146573, 35.557873478078612, 0.0],
              [-80.93889351775077, 35.558079278198527, 0.0],
              [-80.939061918407219, 35.558354077974542, 0.0],
              [-80.939230118565519, 35.558468477937865, 0.0],
              [-80.939482384452148, 35.558491277957302, 0.0],
              [-80.940210517580724, 35.557895411679596, 0.0],
              [-80.940462317689239, 35.557231011663653, 0.0],
              [-80.9409101847112, 35.556589477982158, 0.0],
              [-80.940909984990256, 35.556337611007557, 0.0],
              [-80.940713584387751, 35.556085811319264, 0.0],
              [-80.94068538436261, 35.555856811779513, 0.0],
              [-80.941049318444371, 35.555467277776302, 0.0],
              [-80.941104118024811, 35.55386387776737, 0.0],
              [-80.941356385235324, 35.553955411074028, 0.0],
              [-80.941552785066364, 35.554230211091117, 0.0],
              [-80.941777584740791, 35.554940011949306, 0.0],
              [-80.941973917763889, 35.555054411310742, 0.0],
              [-80.94247838481391, 35.555099877840696, 0.0],
              [-80.942814784229157, 35.555260011745375, 0.0],
              [-80.943291317847851, 35.555328477982165, 0.0],
              [-80.94368391798082, 35.555511411191318, 0.0],
              [-80.944468584861497, 35.555602611042112, 0.0],
              [-80.945057518115988, 35.555831278452494, 0.0],
              [-80.945421784697032, 35.555831077871737, 0.0],
              [-80.945477118009478, 35.554983678260491, 0.0],
              [-80.945364785276468, 35.554800478520718, 0.0],
              [-80.945308184498145, 35.554182211337384, 0.0],
              [-80.944887385177751, 35.553701477965717, 0.0],
              [-80.944550917561145, 35.553449611149752, 0.0],
              [-80.944410584458893, 35.553243611822666, 0.0],
              [-80.944550585019641, 35.553151878527501, 0.0],
              [-80.945335318487338, 35.553059878089336, 0.0],
              [-80.945699718475623, 35.55315127791873, 0.0],
              [-80.946316184768307, 35.553150878370694, 0.0],
              [-80.9466803185841, 35.552875877978877, 0.0],
              [-80.946763917598801, 35.552280411632516, 0.0],
              [-80.946538984395431, 35.551501811539126, 0.0],
              [-80.94665091847321, 35.551318411732609, 0.0],
              [-80.947043118011763, 35.551157877895577, 0.0],
              [-80.947323518060713, 35.551409677788811, 0.0],
              [-80.947632184708525, 35.55189041129988, 0.0],
              [-80.94808091821551, 35.552142078467803, 0.0],
              [-80.948529385071836, 35.55221047783467, 0.0],
              [-80.948809518128741, 35.552095811727199, 0.0],
              [-80.949510118103717, 35.552072478255397, 0.0],
              [-80.94987418429298, 35.551866078320124, 0.0],
              [-80.949901984381697, 35.551568411658458, 0.0],
              [-80.949761585237482, 35.551362411414182, 0.0],
              [-80.94863991761224, 35.550515611851701, 0.0],
              [-80.948611718023997, 35.550286678612196, 0.0],
              [-80.949396117526703, 35.550080011213346, 0.0],
              [-80.95037718453068, 35.550377078261214, 0.0],
              [-80.951050184271054, 35.550743078105057, 0.0],
              [-80.951330718714487, 35.550994878633396, 0.0],
              [-80.951667518490268, 35.551590078017021, 0.0],
              [-80.95214398426765, 35.551681411140812, 0.0],
              [-80.95259251867148, 35.551704011815957, 0.0],
              [-80.952984584766952, 35.551589277966151, 0.0],
              [-80.953040717784546, 35.551474678047839, 0.0],
              [-80.952703984635761, 35.551177211935247, 0.0],
              [-80.952619717803813, 35.5509254118802, 0.0],
              [-80.952030718329581, 35.550444678170152, 0.0],
              [-80.952058384526168, 35.550078278277454, 0.0],
              [-80.952618717568754, 35.54987187854632, 0.0],
              [-80.953599317508363, 35.549665011136923, 0.0],
              [-80.95438398534688, 35.549664411281654, 0.0],
              [-80.954439118385281, 35.548748411333463, 0.0],
              [-80.955111384466903, 35.548564677949393, 0.0],
              [-80.955167184715975, 35.548289811736062, 0.0],
              [-80.955335118499136, 35.548060678271483, 0.0],
              [-80.955390917643584, 35.547785811919844, 0.0],
              [-80.955867117527688, 35.547579278059033, 0.0],
              [-80.955894318147543, 35.546709011910352, 0.0],
              [-80.955725918480326, 35.546525878475499, 0.0],
              [-80.955697518577495, 35.546067810997286, 0.0],
              [-80.955613184503321, 35.54593047791758, 0.0],
              [-80.955108518377855, 35.545678878367553, 0.0],
              [-80.954883984455606, 35.545289811635215, 0.0],
              [-80.954155118618559, 35.544969611778569, 0.0],
              [-80.953426518280736, 35.54494707832572, 0.0],
              [-80.952922317653801, 35.545084878351467, 0.0],
              [-80.952614184884936, 35.545314078231023, 0.0],
              [-80.952222718368233, 35.546161811464913, 0.0],
              [-80.952167318151766, 35.546757277956324, 0.0],
              [-80.951943384633822, 35.547032277666347, 0.0],
              [-80.951747718101643, 35.547513411550611, 0.0],
              [-80.951327584365814, 35.547742611827047, 0.0],
              [-80.950907318338878, 35.54783447856596, 0.0],
              [-80.950766584382237, 35.547170478525366, 0.0],
              [-80.950430184572681, 35.54701041100364, 0.0],
              [-80.949953918603342, 35.547148011207248, 0.0],
              [-80.949533185055841, 35.546850611244125, 0.0],
              [-80.948776518247541, 35.546759611595547, 0.0],
              [-80.948804318234664, 35.546484611682793, 0.0],
              [-80.948944184884354, 35.546232611568826, 0.0],
              [-80.950120318563577, 35.545247011879873, 0.0],
              [-80.950399918250028, 35.544720077922889, 0.0],
              [-80.950483584995055, 35.544353611357273, 0.0],
              [-80.950174985040007, 35.543987411561858, 0.0],
              [-80.949754518047669, 35.543781477774701, 0.0],
              [-80.949334184660572, 35.543736011220588, 0.0],
              [-80.94846591822656, 35.544102877674845, 0.0],
              [-80.948353784791323, 35.544011478309244, 0.0],
              [-80.94829698423716, 35.543301477692147, 0.0],
              [-80.947931984376297, 35.542568678252387, 0.0],
              [-80.947679718550376, 35.542454478079271, 0.0],
              [-80.947315384322295, 35.542408878149168, 0.0],
              [-80.947119384330591, 35.542569411067042, 0.0],
              [-80.947091918228082, 35.543027411842509, 0.0],
              [-80.947316784474907, 35.543897478082897, 0.0],
              [-80.947346384898978, 35.54566107800877, 0.0],
              [-80.947346784797645, 35.546027477837136, 0.0],
              [-80.946758717994484, 35.546554611902849, 0.0],
              [-80.946758784946411, 35.546692078126412, 0.0],
              [-80.946674918282767, 35.546737877925295, 0.0],
              [-80.946674984510011, 35.546898211292699, 0.0],
              [-80.946675318583885, 35.547218877736285, 0.0],
              [-80.947208318224526, 35.547791077790293, 0.0],
              [-80.947208585373687, 35.548226211191377, 0.0],
              [-80.946872518502161, 35.548455478641628, 0.0],
              [-80.945779718676576, 35.548524878637025, 0.0],
              [-80.945443718361673, 35.548799878174499, 0.0],
              [-80.94493978444936, 35.549556011361204, 0.0],
              [-80.944267917874427, 35.550266478585819, 0.0],
              [-80.943987718414121, 35.550404011323486, 0.0],
              [-80.943371117726542, 35.550221077900261, 0.0],
              [-80.942586517857251, 35.55040481163212, 0.0],
              [-80.9422221848954, 35.55029047823728, 0.0],
              [-80.941549585226824, 35.550222211095921, 0.0],
              [-80.939588184490148, 35.550772877768999, 0.0],
              [-80.939140118719564, 35.551025077658096, 0.0],
              [-80.938383718092808, 35.551300278598561, 0.0],
              [-80.937346918172082, 35.551484078632832, 0.0],
              [-80.937318718387459, 35.55125507821316, 0.0],
              [-80.93880358464537, 35.550727477894853, 0.0],
              [-80.939251717591915, 35.550292211204145, 0.0],
              [-80.939195118500706, 35.549696678067676, 0.0],
              [-80.941156784744464, 35.549649811475817, 0.0],
              [-80.941520918598414, 35.549466411173832, 0.0],
              [-80.941632784239303, 35.549237277984126, 0.0],
              [-80.942024985370807, 35.549099677665538, 0.0],
              [-80.942669784890043, 35.549282478550218, 0.0],
              [-80.943314118172552, 35.549190478553527, 0.0],
              [-80.943650185180459, 35.548984211653526, 0.0],
              [-80.943761784441762, 35.548342811407814, 0.0],
              [-80.943593518084711, 35.548136811108989, 0.0],
              [-80.943481118168663, 35.547770478263892, 0.0],
              [-80.943901317854198, 35.547541211679714, 0.0],
              [-80.94429331782483, 35.547174477890394, 0.0],
              [-80.944320718065669, 35.546464478340944, 0.0],
              [-80.944656518411009, 35.545914611510348, 0.0],
              [-80.94465611761936, 35.545456611092163, 0.0],
              [-80.94412358527515, 35.54541101167387, 0.0],
              [-80.942554585340503, 35.545778411481471, 0.0],
              [-80.94216271772035, 35.546099278093237, 0.0],
              [-80.941910518289916, 35.546168211109972, 0.0],
              [-80.941714184295947, 35.545985078155013, 0.0],
              [-80.941069718089807, 35.546054077836125, 0.0],
              [-80.940032785357985, 35.545986011832596, 0.0],
              [-80.940060718133935, 35.545848611580304, 0.0],
              [-80.940396784620816, 35.545642211337473, 0.0],
              [-80.941601518117153, 35.545275077840422, 0.0],
              [-80.941713385390983, 35.545046011305196, 0.0],
              [-80.941152784256147, 35.544817211089601, 0.0],
              [-80.941152585282921, 35.544656877956982, 0.0],
              [-80.942525585394776, 35.544564478162499, 0.0],
              [-80.942889918620637, 35.544449811442831, 0.0],
              [-80.943197718203649, 35.544060411077538, 0.0],
              [-80.943253317758803, 35.543579411089155, 0.0],
              [-80.942804784931255, 35.543373478332114, 0.0],
              [-80.9417679847746, 35.543259611295532, 0.0],
              [-80.941739785113739, 35.543099211700671, 0.0],
              [-80.942075784864087, 35.542801411926405, 0.0],
              [-80.942243717907587, 35.54254941175639, 0.0],
              [-80.942747784341407, 35.542205411757003, 0.0],
              [-80.942775718447649, 35.541976411779075, 0.0],
              [-80.941598785012147, 35.541954211332396, 0.0],
              [-80.940394318423685, 35.542481611910901, 0.0],
              [-80.940338185352118, 35.542413011427023, 0.0],
              [-80.940477717724946, 35.541725811706243, 0.0],
              [-80.940141385270209, 35.541542811425963, 0.0],
              [-80.940084985044962, 35.541313678371438, 0.0],
              [-80.940392784436213, 35.540695211758255, 0.0],
              [-80.940756918422991, 35.540511811723867, 0.0],
              [-80.941597518068704, 35.540419678492952, 0.0],
              [-80.941849517868846, 35.540305011661019, 0.0],
              [-80.941961385385909, 35.540007277881095, 0.0],
              [-80.942353518330194, 35.539709277829743, 0.0],
              [-80.942437385090784, 35.539548878619485, 0.0],
              [-80.942353117531852, 35.539274077944974, 0.0],
              [-80.941848385264308, 35.538885078201957, 0.0],
              [-80.941484117582078, 35.538770811127385, 0.0],
              [-80.940587518633436, 35.538931611407882, 0.0],
              [-80.940335517877145, 35.539160811807839, 0.0],
              [-80.940195718143428, 35.539527211925012, 0.0],
              [-80.939579718285643, 35.539962678028338, 0.0],
              [-80.939495918021706, 35.540375011341894, 0.0],
              [-80.93910391780706, 35.540718878124508, 0.0],
              [-80.938964117985904, 35.541131077925179, 0.0],
              [-80.938460185237872, 35.541612411836418, 0.0],
              [-80.93837638487598, 35.542093411593136, 0.0],
              [-80.93795651772632, 35.542666211772968, 0.0],
              [-80.93795711806338, 35.543353278612059, 0.0],
              [-80.938658317655808, 35.544200277717898, 0.0],
              [-80.938686584261561, 35.544475211736227, 0.0],
              [-80.938406318584953, 35.544498211939505, 0.0],
              [-80.937593384277832, 35.54410927776911, 0.0],
              [-80.937172984671619, 35.54404081139252, 0.0],
              [-80.936724985136095, 35.544476078236535, 0.0],
              [-80.936781318293384, 35.544842478434916, 0.0],
              [-80.937426317868685, 35.545437678071259, 0.0],
              [-80.937538517752174, 35.545643811555045, 0.0],
              [-80.937538717662108, 35.545804078414378, 0.0],
              [-80.937230517908915, 35.545827211635476, 0.0],
              [-80.936305317847541, 35.545346811122407, 0.0],
              [-80.936137184817866, 35.545346811722943, 0.0],
              [-80.935661318192388, 35.546011211228794, 0.0],
              [-80.934849318128926, 35.54683621167851, 0.0],
              [-80.934344917702447, 35.546927878214007, 0.0],
              [-80.93557651847199, 35.545072278497457, 0.0],
              [-80.935604118398814, 35.54440801178216, 0.0],
              [-80.935463784289567, 35.544293611793279, 0.0],
              [-80.935015517567464, 35.544248011078551, 0.0],
              [-80.934399118331044, 35.544431611759769, 0.0],
              [-80.933306318690526, 35.544409211881117, 0.0],
              [-80.932605784834095, 35.544569878402655, 0.0],
              [-80.932521718698709, 35.544386677969094, 0.0],
              [-80.93274558509529, 35.544111811594988, 0.0],
              [-80.934314385286868, 35.543469678627659, 0.0],
              [-80.934874518338106, 35.543080011298706, 0.0],
              [-80.935042318588756, 35.542736411075197, 0.0],
              [-80.93498571836956, 35.541934877976949, 0.0],
              [-80.934985584825128, 35.541797478083197, 0.0],
              [-80.935321517967935, 35.541385011388009, 0.0],
              [-80.935293185330963, 35.540926878164655, 0.0],
              [-80.934480584871253, 35.540858678647197, 0.0],
              [-80.934256318576814, 35.54072141111164, 0.0],
              [-80.934256185062793, 35.540584011193921, 0.0],
              [-80.935292718498403, 35.54030847858877, 0.0],
              [-80.935376785241047, 35.540194011938766, 0.0],
              [-80.9353205183874, 35.54003367836367, 0.0],
              [-80.934844118163625, 35.539827878562825, 0.0],
              [-80.934787517718433, 35.539095011369177, 0.0],
              [-80.934675318704791, 35.538934811785843, 0.0],
              [-80.933862518029201, 35.538568677815299, 0.0],
              [-80.933469918745459, 35.538133677964318, 0.0],
              [-80.933133518490848, 35.538042278187547, 0.0],
              [-80.932769518034945, 35.538340211897115, 0.0],
              [-80.932545584697962, 35.538729678130352, 0.0],
              [-80.931732984535898, 35.538752877750184, 0.0],
              [-80.931256518096305, 35.538524211821994, 0.0],
              [-80.931088518306552, 35.538593011121051, 0.0],
              [-80.930864718237089, 35.539074011477069, 0.0],
              [-80.930640717749739, 35.539349011181251, 0.0],
              [-80.930500984270637, 35.539944477957818, 0.0],
              [-80.93013711875922, 35.540448611667522, 0.0],
              [-80.930081584531465, 35.541296011201823, 0.0],
              [-80.929857717706341, 35.54166261159326, 0.0],
              [-80.929773984447465, 35.542326811611559, 0.0],
              [-80.929438118319794, 35.542922411115313, 0.0],
              [-80.92943891844952, 35.54397587778373, 0.0],
              [-80.929242784925734, 35.544113477726178, 0.0],
              [-80.929187318570428, 35.54496087775469, 0.0],
              [-80.928907184298495, 35.545029677814441, 0.0],
              [-80.928822984401208, 35.544938211793664, 0.0],
              [-80.928458717730791, 35.544823811935672, 0.0],
              [-80.928206518547782, 35.54509881109486, 0.0],
              [-80.928094784583706, 35.545488077957501, 0.0],
              [-80.927898784484327, 35.545694278601914, 0.0],
              [-80.927843184647401, 35.546312811621739, 0.0],
              [-80.927507318666699, 35.546977077884449, 0.0],
              [-80.92750738445082, 35.547137411642062, 0.0],
              [-80.927367385054495, 35.547343678597805, 0.0],
              [-80.927255985097858, 35.548420078262637, 0.0],
              [-80.92672391769311, 35.548901278328131, 0.0],
              [-80.926611985324598, 35.549176211214537, 0.0],
              [-80.926303717874163, 35.54926801136908, 0.0],
              [-80.926247584711732, 35.549222078248803, 0.0],
              [-80.926387317827405, 35.548397611659041, 0.0],
              [-80.926611185006237, 35.547985211727479, 0.0],
              [-80.926750918246256, 35.547321011051054, 0.0],
              [-80.926806318556217, 35.546382011713554, 0.0],
              [-80.926722117637468, 35.546153011420373, 0.0],
              [-80.926413584650689, 35.545878278403009, 0.0],
              [-80.926133384633275, 35.545947077938145, 0.0],
              [-80.925769318750014, 35.546222077695916, 0.0],
              [-80.925461185058438, 35.546336677722898, 0.0],
              [-80.925404984998238, 35.546084811588358, 0.0],
              [-80.925740984950977, 35.545603677925293, 0.0],
              [-80.9270297850166, 35.545190811830672, 0.0],
              [-80.927029584690388, 35.544916011617772, 0.0],
              [-80.925376118055468, 35.544710678570951, 0.0],
              [-80.924955718186737, 35.544550477826945, 0.0],
              [-80.924927584807236, 35.544413211269848, 0.0],
              [-80.925067585222507, 35.544321411528806, 0.0],
              [-80.92610438458081, 35.544229411152521, 0.0],
              [-80.92627238482352, 35.544046078527856, 0.0],
              [-80.927449317835368, 35.544068478219636, 0.0],
              [-80.927561385166968, 35.543999678077689, 0.0],
              [-80.927645185414562, 35.543656077979691, 0.0],
              [-80.927925318005336, 35.54340407775112, 0.0],
              [-80.928344984745706, 35.542602211743549, 0.0],
              [-80.928288784552066, 35.542258677961883, 0.0],
              [-80.928092517641261, 35.542052677990284, 0.0],
              [-80.927223785043424, 35.542007278362327, 0.0],
              [-80.927111317939222, 35.54129727862292, 0.0],
              [-80.926718917993838, 35.541137211051861, 0.0],
              [-80.926298584373939, 35.541137411182604, 0.0],
              [-80.925878518273862, 35.541526877811727, 0.0],
              [-80.925626585003087, 35.541916411226481, 0.0],
              [-80.924533985189598, 35.542260411728876, 0.0],
              [-80.924561784710647, 35.542031411987807, 0.0],
              [-80.925121718347555, 35.541252411588161, 0.0],
              [-80.925233518412469, 35.540634011567747, 0.0],
              [-80.925653584892387, 35.540359011734324, 0.0],
              [-80.92587758441752, 35.539992478286777, 0.0],
              [-80.926437717907149, 35.539557078097332, 0.0],
              [-80.926437718325545, 35.539488411689078, 0.0],
              [-80.925877118081175, 35.539397011875373, 0.0],
              [-80.925652918689266, 35.539259678301626, 0.0],
              [-80.925400918240939, 35.539420077995487, 0.0],
              [-80.925204984345498, 35.539878211250553, 0.0],
              [-80.924588918565348, 35.540451011680695, 0.0],
              [-80.924252718096866, 35.54056567799212, 0.0],
              [-80.924000384516688, 35.540382611976206, 0.0],
              [-80.924028117737038, 35.539787077955921, 0.0],
              [-80.922935118036634, 35.539489811049748, 0.0],
              [-80.922794984335738, 35.539604411616168, 0.0],
              [-80.922122585386376, 35.539742211836426, 0.0],
              [-80.921170718283236, 35.541185411279791, 0.0],
              [-80.920890718720912, 35.541368678126496, 0.0],
              [-80.920806585091867, 35.54130001149975, 0.0],
              [-80.921113984485743, 35.53987987768911, 0.0],
              [-80.920721584936274, 35.539742677774399, 0.0],
              [-80.920665385142897, 35.5394448778477, 0.0],
              [-80.919712385104503, 35.538872677991151, 0.0],
              [-80.919628318604836, 35.538758278326881, 0.0],
              [-80.919796317737649, 35.538597877786117, 0.0],
              [-80.920188584393571, 35.538506211714683, 0.0],
              [-80.921421584729728, 35.53880341140605, 0.0],
              [-80.922458184830347, 35.538688411279445, 0.0],
              [-80.922738385272751, 35.538528011300691, 0.0],
              [-80.922850318174582, 35.538207277966066, 0.0],
              [-80.923158385085245, 35.538115611241736, 0.0],
              [-80.923999118337647, 35.538344278524356, 0.0],
              [-80.924335317568051, 35.538092211852344, 0.0],
              [-80.92509191768157, 35.538091811046613, 0.0],
              [-80.925175718499816, 35.537839877928526, 0.0],
              [-80.925343784443896, 35.537702411489562, 0.0],
              [-80.925792118584539, 35.537587611456615, 0.0],
              [-80.92688511856403, 35.537953611736818, 0.0],
              [-80.927137117548995, 35.537816077887626, 0.0],
              [-80.92733311831519, 35.537541211329859, 0.0],
              [-80.927893318723349, 35.537334811285604, 0.0],
              [-80.927865318256266, 35.537151678536191, 0.0],
              [-80.926912184410327, 35.536510811913082, 0.0],
              [-80.926912118149914, 35.536167278371423, 0.0],
              [-80.927752585203336, 35.536075211912298, 0.0],
              [-80.928565184718678, 35.536074878005117, 0.0],
              [-80.929265517770403, 35.535891277703023, 0.0],
              [-80.929489584815116, 35.535708011127802, 0.0],
              [-80.929516984233544, 35.534837678521157, 0.0],
              [-80.929852584899436, 35.533875477839331, 0.0],
              [-80.929544384936463, 35.533806878065981, 0.0],
              [-80.928199784267434, 35.534471811837321, 0.0],
              [-80.927499384717819, 35.534632477982534, 0.0],
              [-80.925510317611057, 35.534793611780721, 0.0],
              [-80.924753585352803, 35.534656478627376, 0.0],
              [-80.924361117715449, 35.534496477842325, 0.0],
              [-80.92433298540945, 35.534336011215537, 0.0],
              [-80.926014318504158, 35.534312478245702, 0.0],
              [-80.926602517639523, 35.534129011918402, 0.0],
              [-80.926602384502672, 35.533808278295353, 0.0],
              [-80.927666918276685, 35.533693411858351, 0.0],
              [-80.928115118710565, 35.533555811721627, 0.0],
              [-80.928255117764124, 35.533303678259678, 0.0],
              [-80.928787318477674, 35.533074411762833, 0.0],
              [-80.928899184657368, 35.532799611508928, 0.0],
              [-80.929375384306368, 35.532570277738543, 0.0],
              [-80.929459184364845, 35.532112211071109, 0.0],
              [-80.929823184586255, 35.531654011260351, 0.0],
              [-80.929850917851908, 35.531173011932012, 0.0],
              [-80.92976671855773, 35.531058477999977, 0.0],
              [-80.929234185400489, 35.530806811071443, 0.0],
              [-80.928953718553359, 35.530532078381363, 0.0],
              [-80.928168985328611, 35.530188878152465, 0.0],
              [-80.927356585093733, 35.530303811320806, 0.0],
              [-80.927300318187449, 35.530074878459303, 0.0],
              [-80.926992117747346, 35.529914678499537, 0.0],
              [-80.926235518617375, 35.529869211022273, 0.0],
              [-80.925423185174793, 35.529984077917689, 0.0],
              [-80.925002718067574, 35.529709411080844, 0.0],
              [-80.925002584577285, 35.52957201200693, 0.0],
              [-80.9251427184183, 35.529457411237452, 0.0],
              [-80.925870984884128, 35.529342611592007, 0.0],
              [-80.926319117779144, 35.529159078144055, 0.0],
              [-80.927187717679033, 35.529112878528153, 0.0],
              [-80.929373385169995, 35.529432611189726, 0.0],
              [-80.93010171759208, 35.529157478033738, 0.0],
              [-80.930578117848256, 35.529340411858023, 0.0],
              [-80.930942384580788, 35.529363211737554, 0.0],
              [-80.931334517978613, 35.529019478384541, 0.0],
              [-80.931278184837197, 35.52879027782123, 0.0],
              [-80.931053918180751, 35.528584411928762, 0.0],
              [-80.930381317974579, 35.528309878439337, 0.0],
              [-80.930297117677725, 35.528172478655456, 0.0],
              [-80.930240718573245, 35.527554077823318, 0.0],
              [-80.930604917555485, 35.527462411550282, 0.0],
              [-80.930941184998133, 35.527622477731441, 0.0],
              [-80.931473918090205, 35.528126078486714, 0.0],
              [-80.932342584991247, 35.528240211941736, 0.0],
              [-80.933323584498353, 35.528720678337748, 0.0],
              [-80.933519585052508, 35.528651878498259, 0.0],
              [-80.933659718203174, 35.528445678534275, 0.0],
              [-80.933547384717215, 35.528170878515041, 0.0],
              [-80.932650385056377, 35.527736278579745, 0.0],
              [-80.932510118416687, 35.527392678308715, 0.0],
              [-80.9321737848759, 35.5272554777628, 0.0],
              [-80.931921518629309, 35.527049477806685, 0.0],
              [-80.9316969180162, 35.526660211179603, 0.0],
              [-80.930772317571481, 35.526317211451534, 0.0],
              [-80.929147384215455, 35.526546877962701, 0.0],
              [-80.92878311827495, 35.526478411539685, 0.0],
              [-80.92861491791308, 35.526341077997166, 0.0],
              [-80.928670918072328, 35.526180678680554, 0.0],
              [-80.929931518670642, 35.526065611440615, 0.0],
              [-80.930211318282971, 35.525584478320717, 0.0],
              [-80.931135785235639, 35.525515277893021, 0.0],
              [-80.933041118238009, 35.525537278543908, 0.0],
              [-80.933264985225037, 35.525239411588444, 0.0],
              [-80.933068518636532, 35.524873211850839, 0.0],
              [-80.932704184427379, 35.524690077921285, 0.0],
              [-80.932311718607195, 35.524369611150107, 0.0],
              [-80.931555117969026, 35.524301277786755, 0.0],
              [-80.931470985345953, 35.524186811937717, 0.0],
              [-80.930546184230565, 35.523797878238682, 0.0],
              [-80.930209785207893, 35.523408678380271, 0.0],
              [-80.929789518610775, 35.523271478598687, 0.0],
              [-80.929200918036585, 35.522859478122825, 0.0],
              [-80.929172384986842, 35.52228701113151, 0.0],
              [-80.929396518480274, 35.522218211689925, 0.0],
              [-80.929676918005086, 35.522424211715339, 0.0],
              [-80.930237185199914, 35.522652878084948, 0.0],
              [-80.930909518679371, 35.522561011033972, 0.0],
              [-80.931217784869361, 35.522721211819231, 0.0],
              [-80.931497984516795, 35.522744011723383, 0.0],
              [-80.931805984938549, 35.522468877789976, 0.0],
              [-80.932226318717994, 35.52244581154811, 0.0],
              [-80.932282585272432, 35.522766411871181, 0.0],
              [-80.932702785111559, 35.522857811480328, 0.0],
              [-80.932954784504531, 35.522674478383358, 0.0],
              [-80.933122718430155, 35.522376677917585, 0.0],
              [-80.933346917763515, 35.522284878538933, 0.0],
              [-80.933571117736221, 35.52249101175854, 0.0],
              [-80.933711384341308, 35.522811478512054, 0.0],
              [-80.934019917855679, 35.522971677886453, 0.0],
              [-80.934355917627983, 35.522857011503397, 0.0],
              [-80.934579784494531, 35.522398811635483, 0.0],
              [-80.934495385398833, 35.521986611345667, 0.0],
              [-80.934298984210969, 35.521643211444633, 0.0],
              [-80.9339345186429, 35.521322811872885, 0.0],
              [-80.93385011871986, 35.520864811518472, 0.0],
              [-80.933429517841134, 35.520429677782381, 0.0],
              [-80.933429318732834, 35.520223611404624, 0.0],
              [-80.93415771847144, 35.520246078033885, 0.0],
              [-80.935222717798425, 35.520520478586, 0.0],
              [-80.935587184858917, 35.520772211454656, 0.0],
              [-80.935783785208642, 35.521184278001471, 0.0],
              [-80.936204118335752, 35.521298678586525, 0.0],
              [-80.936316384577722, 35.521573411572483, 0.0],
              [-80.93642858431663, 35.521687878475099, 0.0],
              [-80.93642858451264, 35.521871077868042, 0.0],
              [-80.936120784275857, 35.522306477799425, 0.0],
              [-80.936121184617633, 35.522787478618596, 0.0],
              [-80.936261518502363, 35.522970611809392, 0.0],
              [-80.936709784741055, 35.522970411656772, 0.0],
              [-80.937129784839257, 35.522649411432816, 0.0],
              [-80.937269785258763, 35.522351611568205, 0.0],
              [-80.937521784611405, 35.522122411943663, 0.0],
              [-80.937689717733448, 35.521847611220743, 0.0],
              [-80.937577318182903, 35.521412478322254, 0.0],
              [-80.937240718106366, 35.521092011807916, 0.0],
              [-80.937100385361774, 35.520771411734152, 0.0],
              [-80.936539784859448, 35.520611411508462, 0.0],
              [-80.93578298539839, 35.520199477796091, 0.0],
              [-80.935698518334235, 35.519718611287431, 0.0],
              [-80.935558185334287, 35.519535478380789, 0.0],
              [-80.935473718609657, 35.518871411187298, 0.0],
              [-80.935277385418246, 35.518642411972465, 0.0],
              [-80.93480091872496, 35.518482411614194, 0.0],
              [-80.934548717623684, 35.51827641201001, 0.0],
              [-80.934548517688341, 35.518093211757503, 0.0],
              [-80.935668984554297, 35.517726077941418, 0.0],
              [-80.936117517563844, 35.51800061194669, 0.0],
              [-80.936425985018886, 35.518458478252178, 0.0],
              [-80.936650318216593, 35.518595811601116, 0.0],
              [-80.937014585077279, 35.518549811219593, 0.0],
              [-80.937547118017832, 35.518664078510298, 0.0],
              [-80.937714984908311, 35.518526611302939, 0.0],
              [-80.93810731859395, 35.518411878550232, 0.0],
              [-80.938051784593767, 35.519144811550888, 0.0],
              [-80.93836031769824, 35.519396611098472, 0.0],
              [-80.938500718701022, 35.519717078529723, 0.0],
              [-80.938724984931341, 35.519877278417702, 0.0],
              [-80.938893118378331, 35.519831411575908, 0.0],
              [-80.93920111762344, 35.519533478682462, 0.0],
              [-80.939732918305253, 35.518617078199092, 0.0],
              [-80.93998498523743, 35.518525411991959, 0.0],
              [-80.940069185086273, 35.518731478360507, 0.0],
              [-80.939845384427414, 35.519189678496346, 0.0],
              [-80.939817784212124, 35.519785077935566, 0.0],
              [-80.939874517585352, 35.520517878606995, 0.0],
              [-80.939622584440087, 35.520953211309227, 0.0],
              [-80.939595118125894, 35.521480078528079, 0.0],
              [-80.940071384541938, 35.521479811231309, 0.0],
              [-80.940743317835725, 35.520563278074889, 0.0],
              [-80.940798784408884, 35.519853278185977, 0.0],
              [-80.941022918335293, 35.519784411582009, 0.0],
              [-80.941359317552383, 35.51985301139181, 0.0],
              [-80.941920384205318, 35.520539811408426, 0.0],
              [-80.942116518340455, 35.520654211415085, 0.0],
              [-80.942368718149609, 35.520654011276292, 0.0],
              [-80.942424517905778, 35.520310411385545, 0.0],
              [-80.942228318370908, 35.520104411599561, 0.0],
              [-80.942199984802613, 35.519783811267352, 0.0],
              [-80.942535918732361, 35.519417078526622, 0.0],
              [-80.942815984533212, 35.519302477975401, 0.0],
              [-80.942956184543434, 35.51943981112084, 0.0],
              [-80.943488784838877, 35.519599811698846, 0.0],
              [-80.943964984842822, 35.519347477757229, 0.0],
              [-80.944329517942563, 35.519553477789735, 0.0],
              [-80.944581718216284, 35.519805211796246, 0.0],
              [-80.945506517934305, 35.519896411336433, 0.0],
              [-80.9469353852202, 35.520261878180065, 0.0],
              [-80.947131518276521, 35.520124411243017, 0.0],
              [-80.947495585233469, 35.520055477862428, 0.0],
              [-80.947607784802941, 35.520170011664028, 0.0],
              [-80.947580317542773, 35.520902811624708, 0.0],
              [-80.947804585230529, 35.521292011332356, 0.0],
              [-80.947076717714197, 35.521956678455894, 0.0],
              [-80.947076984444493, 35.522483478205629, 0.0],
              [-80.947273184234319, 35.522620678033576, 0.0],
              [-80.948057584232885, 35.522551477812847, 0.0],
              [-80.948085785055966, 35.52273467801303, 0.0],
              [-80.948422118162327, 35.523032277780956, 0.0],
              [-80.948198184401406, 35.523261478324564, 0.0],
              [-80.948198385098763, 35.523536278178874, 0.0],
              [-80.948982985220709, 35.523856477815286, 0.0],
              [-80.948899584605257, 35.524887078049588, 0.0],
              [-80.949600184943208, 35.524978277816217, 0.0],
              [-80.950216917923797, 35.525550478074102, 0.0],
              [-80.950301318469414, 35.52607721198558, 0.0],
              [-80.950974317979188, 35.526855411327105, 0.0],
              [-80.951759184535391, 35.527542011257282, 0.0],
              [-80.952291718565419, 35.527633278541451, 0.0],
              [-80.953665384378013, 35.528914878391959, 0.0],
              [-80.954141985272258, 35.529212411951839, 0.0],
              [-80.954450117709911, 35.529189211045058, 0.0],
              [-80.954589984353944, 35.529006011618314, 0.0],
              [-80.954645784531948, 35.528685278297324, 0.0],
              [-80.954225318592464, 35.528410678498119, 0.0],
              [-80.953664718699343, 35.527930078511908, 0.0],
              [-80.953636518408587, 35.527701077941067, 0.0],
              [-80.954532785033521, 35.52737987795097, 0.0],
              [-80.954952917694456, 35.527081878173725, 0.0],
              [-80.955148384453295, 35.52632587828559, 0.0],
              [-80.95486811801554, 35.526165678347439, 0.0],
              [-80.953971785188756, 35.526487078570263, 0.0],
              [-80.95343931758299, 35.52625841162957, 0.0],
              [-80.952374785246178, 35.526465211285227, 0.0],
              [-80.951478185251958, 35.526145077928554, 0.0],
              [-80.951197717918291, 35.525870478238673, 0.0],
              [-80.951141518602398, 35.525504078018287, 0.0],
              [-80.951365317694538, 35.525137611377836, 0.0],
              [-80.952205917921773, 35.525091077973904, 0.0],
              [-80.952878118455672, 35.524861611349202, 0.0],
              [-80.953437785127946, 35.524036811527779, 0.0],
              [-80.953521584295459, 35.523670278075436, 0.0],
              [-80.95301691762883, 35.523006478072837, 0.0],
              [-80.952232184434905, 35.522686411042152, 0.0],
              [-80.95228771773175, 35.522067877789965, 0.0],
              [-80.95239971850755, 35.521999078276259, 0.0],
              [-80.953352384305333, 35.522044278547718, 0.0],
              [-80.953912917826273, 35.522318678569881, 0.0],
              [-80.954641384730422, 35.522547278416447, 0.0],
              [-80.955061584656391, 35.522569877718439, 0.0],
              [-80.955285717811506, 35.52245521164911, 0.0],
              [-80.955425585199634, 35.522249011179618, 0.0],
              [-80.955453318269463, 35.521882611359665, 0.0],
              [-80.955060985159392, 35.521608011346466, 0.0],
              [-80.955116584918542, 35.521127011699242, 0.0],
              [-80.954751784646433, 35.52044021136102, 0.0],
              [-80.953771118512805, 35.520028611948632, 0.0],
              [-80.953070717736054, 35.520006077939371, 0.0],
              [-80.952986317777246, 35.519639677730204, 0.0],
              [-80.952846384728332, 35.519594011343102, 0.0],
              [-80.95197818470217, 35.519983878409725, 0.0],
              [-80.951669984453801, 35.519892478637075, 0.0],
              [-80.950605317717532, 35.519801478363696, 0.0],
              [-80.950521184358919, 35.519710011951155, 0.0],
              [-80.951109185145043, 35.519251611515735, 0.0],
              [-80.951136985203689, 35.518908011854819, 0.0],
              [-80.950884584833091, 35.518656278554246, 0.0],
              [-80.950856518355209, 35.518427211687275, 0.0],
              [-80.950632117921586, 35.518129678114036, 0.0],
              [-80.950379985268526, 35.517992411280368, 0.0],
              [-80.949455518018951, 35.517970078563479, 0.0],
              [-80.949091318607117, 35.518130677893325, 0.0],
              [-80.948755118151396, 35.518153678044442, 0.0],
              [-80.948474984429922, 35.518039411287305, 0.0],
              [-80.947718585119489, 35.518223011477779, 0.0],
              [-80.947354318690316, 35.518017078409486, 0.0],
              [-80.946429517832485, 35.517788678534721, 0.0],
              [-80.945588984286616, 35.517697611113, 0.0],
              [-80.945252718057802, 35.517537477723998, 0.0],
              [-80.944692185308455, 35.517606611323636, 0.0],
              [-80.944467985255756, 35.517538011785525, 0.0],
              [-80.944467784647628, 35.517354811169064, 0.0],
              [-80.945335984411003, 35.516667211560836, 0.0],
              [-80.945475917962327, 35.51646087836783, 0.0],
              [-80.94550371845925, 35.516163211102572, 0.0],
              [-80.945391585400287, 35.516048678260866, 0.0],
              [-80.944102117596984, 35.515751811626778, 0.0],
              [-80.944017985328202, 35.515614411783048, 0.0],
              [-80.944101584642723, 35.515064677971274, 0.0],
              [-80.945502784955011, 35.514995211944481, 0.0],
              [-80.945978584564344, 35.514468011960552, 0.0],
              [-80.946062518026139, 35.514078611718574, 0.0],
              [-80.945893917943934, 35.513666477797898, 0.0],
              [-80.945641584342823, 35.513483411673839, 0.0],
              [-80.944940785302265, 35.513392211414484, 0.0],
              [-80.943875584876196, 35.51300347834006, 0.0],
              [-80.943371318387662, 35.513141211230241, 0.0],
              [-80.942558585395744, 35.513187478387479, 0.0],
              [-80.942474518579218, 35.513073011824389, 0.0],
              [-80.942922585031909, 35.512752078270083, 0.0],
              [-80.942950384679051, 35.512500211227447, 0.0],
              [-80.942838117933803, 35.512385678202754, 0.0],
              [-80.942837785297286, 35.511904678399148, 0.0],
              [-80.942557384836221, 35.51163007869355, 0.0],
              [-80.942585317550709, 35.5114468115246, 0.0],
              [-80.943201717628355, 35.511354878476929, 0.0],
              [-80.943790317763387, 35.511606478286673, 0.0],
              [-80.944574984295841, 35.511651811688075, 0.0],
              [-80.945303984663425, 35.512132411768548, 0.0],
              [-80.945864317619396, 35.511925878445659, 0.0],
              [-80.946116184389965, 35.511742611399214, 0.0],
              [-80.946171985339575, 35.511467611672153, 0.0],
              [-80.946003384795929, 35.510849411137464, 0.0],
              [-80.946367518114329, 35.510711677908837, 0.0],
              [-80.946788184631174, 35.511077877943748, 0.0],
              [-80.947376785058182, 35.511306611924731, 0.0],
              [-80.947573318415834, 35.511650011619317, 0.0],
              [-80.948050185086075, 35.512130611424212, 0.0],
              [-80.948134584873486, 35.512611478283638, 0.0],
              [-80.949031984312981, 35.513435611324468, 0.0],
              [-80.949872317567198, 35.513457811816444, 0.0],
              [-80.950012585050317, 35.513641011752817, 0.0],
              [-80.950125184819399, 35.514328077981396, 0.0],
              [-80.95032138485665, 35.514671478631392, 0.0],
              [-80.950685718344815, 35.514717077766754, 0.0],
              [-80.951525984194134, 35.514579011171449, 0.0],
              [-80.951918318611646, 35.514739078367484, 0.0],
              [-80.952338585320646, 35.514807611526059, 0.0],
              [-80.952450518104229, 35.514738811786962, 0.0],
              [-80.95250638481447, 35.514486811503048, 0.0],
              [-80.952394185084515, 35.514303611940676, 0.0],
              [-80.951777517661341, 35.513823211322538, 0.0],
              [-80.951665118164755, 35.513296478058898, 0.0],
              [-80.95116058453101, 35.512953278482236, 0.0],
              [-80.951104385283102, 35.512678411771972, 0.0],
              [-80.951440384788214, 35.512357478068296, 0.0],
              [-80.952252385088173, 35.511990478191578, 0.0],
              [-80.952392318330524, 35.511807211950206, 0.0],
              [-80.952419985246365, 35.511372077788188, 0.0],
              [-80.952223784465147, 35.511234678009998, 0.0],
              [-80.951607385346847, 35.511074811087227, 0.0],
              [-80.951158784640782, 35.510662877888514, 0.0],
              [-80.95026251801356, 35.510846811435542, 0.0],
              [-80.950094184781847, 35.510663611651395, 0.0],
              [-80.949449518641728, 35.510412078137094, 0.0],
              [-80.949169184391152, 35.510160211615634, 0.0],
              [-80.949168918308899, 35.509931211705435, 0.0],
              [-80.949336785041837, 35.509633411266172, 0.0],
              [-80.949700918096056, 35.509472811310431, 0.0],
              [-80.9512419182401, 35.509517811918776, 0.0],
              [-80.951830518696141, 35.509700611063529, 0.0],
              [-80.952950917576729, 35.509631078416334, 0.0],
              [-80.953847384392489, 35.509882477984682, 0.0],
              [-80.95465998422516, 35.510225478309124, 0.0],
              [-80.954996184231689, 35.510248011473713, 0.0],
              [-80.955135918220378, 35.509767011132368, 0.0],
              [-80.954855585118821, 35.509606878615671, 0.0],
              [-80.953622785371451, 35.509218278238023, 0.0],
              [-80.953314518336398, 35.509012478093666, 0.0],
              [-80.953314318611802, 35.508783411748681, 0.0],
              [-80.953733784350874, 35.508096078456909, 0.0],
              [-80.953565584273719, 35.507958811346626, 0.0],
              [-80.952725184687665, 35.507890678597811, 0.0],
              [-80.952500517942113, 35.507226611972804, 0.0],
              [-80.952388384981802, 35.507135011977915, 0.0],
              [-80.951771984907964, 35.506998011659888, 0.0],
              [-80.95163171759576, 35.506883611124117, 0.0],
              [-80.951575184886551, 35.506448478553963, 0.0],
              [-80.952078985071239, 35.505967211666494, 0.0],
              [-80.953619785100798, 35.505943211073522, 0.0],
              [-80.95406811867494, 35.506057411528445, 0.0],
              [-80.955469185199675, 35.5066520119844, 0.0],
              [-80.95667358530261, 35.506651211802996, 0.0],
              [-80.957346518228576, 35.507314878145593, 0.0],
              [-80.957962784614267, 35.507429011720781, 0.0],
              [-80.958130784853921, 35.50726847865274, 0.0],
              [-80.958158384190526, 35.506925011288061, 0.0],
              [-80.957962185091802, 35.506741877887606, 0.0],
              [-80.958017984985148, 35.506535811425913, 0.0],
              [-80.958353717922279, 35.506123278379242, 0.0],
              [-80.958465184412347, 35.505573477771129, 0.0],
              [-80.95916531846693, 35.505412611407124, 0.0],
              [-80.959753717960524, 35.505755811522981, 0.0],
              [-80.960258318090467, 35.506190611281063, 0.0],
              [-80.960594318286283, 35.506121611878768, 0.0],
              [-80.961014317839897, 35.50589227774789, 0.0],
              [-80.96109778460962, 35.505480011995537, 0.0],
              [-80.960593518164686, 35.505136811265189, 0.0],
              [-80.960592917542286, 35.504587077752994, 0.0],
              [-80.960172384562753, 35.504243878605124, 0.0],
              [-80.960227584237515, 35.503442278220064, 0.0],
              [-80.96003151850276, 35.503350811087124, 0.0],
              [-80.959667518429924, 35.503488477732134, 0.0],
              [-80.959107517938634, 35.503511811084685, 0.0],
              [-80.958799718489999, 35.50397007781838, 0.0],
              [-80.957399784653887, 35.504474877897934, 0.0],
              [-80.957314985325127, 35.503765011403743, 0.0],
              [-80.957090917650575, 35.503581878011872, 0.0],
              [-80.956390584215882, 35.503559411611477, 0.0],
              [-80.955858584970471, 35.503697211771573, 0.0],
              [-80.955662385278202, 35.50351421188487, 0.0],
              [-80.95566198466905, 35.503170611896508, 0.0],
              [-80.956165518175098, 35.502506077977706, 0.0],
              [-80.956893184785457, 35.501864411303899, 0.0],
              [-80.957565184239513, 35.501726411809905, 0.0],
              [-80.957732984598451, 35.501520078146761, 0.0],
              [-80.958376718571429, 35.501107411066052, 0.0],
              [-80.958236584714967, 35.500993011911994, 0.0],
              [-80.958236384721147, 35.500832678511095, 0.0],
              [-80.958712184960035, 35.500488877892181, 0.0],
              [-80.958879718344946, 35.50014921198639, 0.0],
              [-80.95866658456417, 35.499834278196808, 0.0],
              [-80.958303518382237, 35.499697211583324, 0.0],
              [-80.957833384547271, 35.499170811218704, 0.0],
              [-80.957777184502532, 35.498941678445711, 0.0],
              [-80.957496918325731, 35.498735811186037, 0.0],
              [-80.956797118612201, 35.499194278097015, 0.0],
              [-80.956405717581049, 35.499584011809738, 0.0],
              [-80.956435717614312, 35.500149678028123, 0.0],
              [-80.956520184486322, 35.500444611581926, 0.0],
              [-80.95660558425665, 35.500994211724482, 0.0],
              [-80.956413317754496, 35.501360811955706, 0.0],
              [-80.955880784551383, 35.501681811277983, 0.0],
              [-80.955045317995427, 35.502598478641907, 0.0],
              [-80.954513318062254, 35.502804878257706, 0.0],
              [-80.954345385083712, 35.503011211340386, 0.0],
              [-80.953869385167351, 35.503240611853258, 0.0],
              [-80.953309384923941, 35.503561611299318, 0.0],
              [-80.953050384227922, 35.50335567787328, 0.0],
              [-80.953468184718943, 35.502233077823853, 0.0],
              [-80.953216384259647, 35.501912611813125, 0.0],
              [-80.953160384379501, 35.501637677980476, 0.0],
              [-80.952542918492995, 35.501042678692158, 0.0],
              [-80.952542585250711, 35.500836611763098, 0.0],
              [-80.953354785138643, 35.500675678574233, 0.0],
              [-80.95374631767865, 35.500150078485532, 0.0],
              [-80.953746118114069, 35.499860611982641, 0.0],
              [-80.953409585154759, 35.499540211615873, 0.0],
              [-80.953521384199163, 35.499242411404872, 0.0],
              [-80.953576918419529, 35.498921678274705, 0.0],
              [-80.953352918040281, 35.498830278165158, 0.0],
              [-80.952709118537129, 35.499151278318379, 0.0],
              [-80.952624984729241, 35.499014011238366, 0.0],
              [-80.952568117678211, 35.498189478014666, 0.0],
              [-80.952428117848228, 35.498075078025636, 0.0],
              [-80.952063984593593, 35.498189878629233, 0.0],
              [-80.951700318543345, 35.498510677865355, 0.0],
              [-80.95142078506224, 35.499106278074841, 0.0],
              [-80.950497384860029, 35.499793878641299, 0.0],
              [-80.950357518008474, 35.500150611205591, 0.0],
              [-80.95044178524391, 35.500379811085686, 0.0],
              [-80.951198385305815, 35.500906212042196, 0.0],
              [-80.951170985007195, 35.501639077983945, 0.0],
              [-80.951311317574309, 35.50179941116216, 0.0],
              [-80.951395718548071, 35.502097078240368, 0.0],
              [-80.951676718178774, 35.502280078172461, 0.0],
              [-80.951732918527441, 35.502463278200793, 0.0],
              [-80.951144784706798, 35.502646812021361, 0.0],
              [-80.951089384660577, 35.503150678027659, 0.0],
              [-80.950193385383358, 35.503449011567987, 0.0],
              [-80.949997118294064, 35.503311811638824, 0.0],
              [-80.94985598464929, 35.503037011742244, 0.0],
              [-80.949407717959559, 35.502762411302726, 0.0],
              [-80.949183718631502, 35.502831278564372, 0.0],
              [-80.949156118685309, 35.503312211512231, 0.0],
              [-80.948820318659301, 35.503701878175185, 0.0],
              [-80.948821385380512, 35.504091211638354, 0.0],
              [-80.948933717612832, 35.504205678071713, 0.0],
              [-80.949045784647566, 35.504595011278013, 0.0],
              [-80.949353518142971, 35.50491541129248, 0.0],
              [-80.950031385202138, 35.505327211549634, 0.0],
              [-80.950509785326986, 35.505556011137926, 0.0],
              [-80.950734184579801, 35.505761878387524, 0.0],
              [-80.950734385351765, 35.506036678569728, 0.0],
              [-80.950622384710073, 35.506197211154209, 0.0],
              [-80.950202384469591, 35.50638061124365, 0.0],
              [-80.950034384701041, 35.506563878176905, 0.0],
              [-80.949698318032645, 35.506701611241716, 0.0],
              [-80.949445984545264, 35.506426878145021, 0.0],
              [-80.949193784542032, 35.506381278018928, 0.0],
              [-80.948409517626374, 35.50649627823158, 0.0],
              [-80.947814118724537, 35.506061478216026, 0.0],
              [-80.947533985197907, 35.506015877882184, 0.0],
              [-80.947338184820424, 35.506336611713387, 0.0],
              [-80.947310585288733, 35.506749011570108, 0.0],
              [-80.947625384261428, 35.50706941200113, 0.0],
              [-80.947709784552472, 35.507619011976104, 0.0],
              [-80.948326717965102, 35.508122478485909, 0.0],
              [-80.948298918376935, 35.50830567811682, 0.0],
              [-80.947654385185203, 35.508374811115758, 0.0],
              [-80.946589518084537, 35.508123478658938, 0.0],
              [-80.945860984227522, 35.508055211597693, 0.0],
              [-80.945608984882881, 35.508192878086021, 0.0],
              [-80.945357118375114, 35.508582411384282, 0.0],
              [-80.945273718544158, 35.509544278245734, 0.0],
              [-80.945133717639848, 35.509704677820054, 0.0],
              [-80.944069318630298, 35.510117611211882, 0.0],
              [-80.943593117738587, 35.510186478459474, 0.0],
              [-80.943368784704816, 35.510003478418355, 0.0],
              [-80.943368584596669, 35.509682811980603, 0.0],
              [-80.943508517944807, 35.509407878569519, 0.0],
              [-80.943559185055449, 35.508812477978168, 0.0],
              [-80.94339098464863, 35.508698011114696, 0.0],
              [-80.943027118463462, 35.508629478202607, 0.0],
              [-80.94229951819834, 35.50890467826143, 0.0],
              [-80.94196358431337, 35.509225611194005, 0.0],
              [-80.941403718034721, 35.509500811112105, 0.0],
              [-80.941067718661174, 35.50977581145672, 0.0],
              [-80.94064758432755, 35.51041727819036, 0.0],
              [-80.940339718473311, 35.510554878246467, 0.0],
              [-80.940394917679882, 35.509455478334267, 0.0],
              [-80.940254785190191, 35.509157878445009, 0.0],
              [-80.940394517955724, 35.508539411128403, 0.0],
              [-80.940674384202595, 35.50833307815055, 0.0],
              [-80.940870118302854, 35.508035278387808, 0.0],
              [-80.941514118581381, 35.507828811280227, 0.0],
              [-80.941821918558503, 35.507370611317519, 0.0],
              [-80.942689318588293, 35.506637211950213, 0.0],
              [-80.942716918710161, 35.506201878059763, 0.0],
              [-80.942464517983794, 35.505858478521525, 0.0],
              [-80.942016185204878, 35.505652611141194, 0.0],
              [-80.941316118092402, 35.505630211186578, 0.0],
              [-80.940755918429616, 35.505493211704348, 0.0],
              [-80.940755718377119, 35.505264011521504, 0.0],
              [-80.941399518470362, 35.50492007817072, 0.0],
              [-80.941679318666374, 35.504599278440232, 0.0],
              [-80.942547517704099, 35.504553011205068, 0.0],
              [-80.942715518572854, 35.504484211935548, 0.0],
              [-80.942911318022425, 35.504186411235636, 0.0],
              [-80.94268698444094, 35.503820078306298, 0.0],
              [-80.942742185219032, 35.502881011966011, 0.0],
              [-80.942658117972087, 35.502835211674693, 0.0],
              [-80.942657784749841, 35.502583278238873, 0.0],
              [-80.943469784189531, 35.502193477797725, 0.0],
              [-80.943469384723102, 35.501712612007005, 0.0],
              [-80.94181631867643, 35.50116387821506, 0.0],
              [-80.941423984441315, 35.500912077817553, 0.0],
              [-80.941143585149675, 35.500500011978438, 0.0],
              [-80.941031317646576, 35.500151277794167, 0.0],
              [-80.94122698530542, 35.499662078324462, 0.0],
              [-80.941282317797302, 35.498677278001473, 0.0],
              [-80.94178598537107, 35.498196077945074, 0.0],
              [-80.941953784252405, 35.497875411718887, 0.0],
              [-80.941505118586136, 35.497280078080223, 0.0],
              [-80.94142071761641, 35.496753411717741, 0.0],
              [-80.941671984717615, 35.495860078695969, 0.0],
              [-80.941391785336748, 35.495677011664, 0.0],
              [-80.941307717858876, 35.495470878142569, 0.0],
              [-80.940999518554435, 35.495310811700591, 0.0],
              [-80.940635518514483, 35.495471278156209, 0.0],
              [-80.940271718570912, 35.495746278596478, 0.0],
              [-80.940356118226347, 35.496250078370885, 0.0],
              [-80.94013258465084, 35.496891611163875, 0.0],
              [-80.940049518569225, 35.498036678530816, 0.0],
              [-80.940133784661398, 35.498426011179809, 0.0],
              [-80.940050385107085, 35.499158878533336, 0.0],
              [-80.939854584510798, 35.499456811193902, 0.0],
              [-80.939827118677215, 35.500151411970464, 0.0],
              [-80.939883185087382, 35.500523611827099, 0.0],
              [-80.939939785367088, 35.501119011341196, 0.0],
              [-80.939659784809081, 35.501233678680897, 0.0],
              [-80.939575717629396, 35.501165011881945, 0.0],
              [-80.93932818527783, 35.500523878284874, 0.0],
              [-80.938994517924073, 35.500151411385509, 0.0],
              [-80.938923118204997, 35.500052678524639, 0.0],
              [-80.938450918594256, 35.499938411278769, 0.0],
              [-80.938118318133448, 35.500053078407774, 0.0],
              [-80.938118718602482, 35.500151411071151, 0.0],
              [-80.93806878432234, 35.501509477967076, 0.0],
              [-80.938960785246664, 35.502585411873341, 0.0],
              [-80.939969318030535, 35.502882611343487, 0.0],
              [-80.939913384294613, 35.503134611094502, 0.0],
              [-80.938989784807106, 35.503936611085997, 0.0],
              [-80.938822117874111, 35.504165811709079, 0.0],
              [-80.938345918152706, 35.504166078611178, 0.0],
              [-80.937317718483484, 35.503731411990366, 0.0],
              [-80.936272518314269, 35.50297621196124, 0.0],
              [-80.935460385148787, 35.50311407857297, 0.0],
              [-80.935068518397571, 35.503412011855644, 0.0],
              [-80.934872785301152, 35.50393881123977, 0.0],
              [-80.934676984536196, 35.504190878692775, 0.0],
              [-80.934677584373404, 35.50515287860982, 0.0],
              [-80.935657717916655, 35.505495811839197, 0.0],
              [-80.937003518467051, 35.50673187842574, 0.0],
              [-80.937619985059058, 35.507029411321696, 0.0],
              [-80.938096584294925, 35.507441411218302, 0.0],
              [-80.938096584255561, 35.507578811540007, 0.0],
              [-80.938180717727491, 35.507624611943811, 0.0],
              [-80.93820898537156, 35.507830611859625, 0.0],
              [-80.938433318026924, 35.508128278569025, 0.0],
              [-80.938293784523879, 35.508586411495315, 0.0],
              [-80.937957518363874, 35.50856361129788, 0.0],
              [-80.937172984317939, 35.508335011783004, 0.0],
              [-80.936948584263305, 35.507945811472318, 0.0],
              [-80.936388118525898, 35.507785811282957, 0.0],
              [-80.936163917893069, 35.507488211816188, 0.0],
              [-80.935911784342821, 35.507373811447067, 0.0],
              [-80.934656784998353, 35.507466011187617, 0.0],
              [-80.934174718330155, 35.507741078383198, 0.0],
              [-80.933643384612665, 35.50785601162751, 0.0],
              [-80.933531517801001, 35.508085011138718, 0.0],
              [-80.93355931808631, 35.509001077912977, 0.0],
              [-80.933792918341183, 35.50939027773078, 0.0],
              [-80.934204984601195, 35.509779411292136, 0.0],
              [-80.934345584901791, 35.51023747805899, 0.0],
              [-80.935018185188397, 35.510649411474148, 0.0],
              [-80.935186385313699, 35.510878278537433, 0.0],
              [-80.935718784853506, 35.511061278602902, 0.0],
              [-80.935831318701375, 35.511404811389099, 0.0],
              [-80.936111585020072, 35.51165647822743, 0.0],
              [-80.936448185037307, 35.51243507823736, 0.0],
              [-80.936812584775637, 35.512732477945967, 0.0],
              [-80.936925184867292, 35.513121878393676, 0.0],
              [-80.93745918517476, 35.513648411358773, 0.0],
              [-80.937459518517599, 35.514083611738954, 0.0],
              [-80.938132384957484, 35.514632877735096, 0.0],
              [-80.937824184255092, 35.514816277959909, 0.0],
              [-80.937347917737341, 35.514793611569033, 0.0],
              [-80.93619871809797, 35.5141758116551, 0.0],
              [-80.935945384392241, 35.513740811821258, 0.0],
              [-80.935552717988514, 35.513557811830552, 0.0],
              [-80.935468384781402, 35.513260077986011, 0.0],
              [-80.934767785141702, 35.512687877877447, 0.0],
              [-80.93409558431992, 35.512848611333268, 0.0],
              [-80.93384351844675, 35.512734078234615, 0.0],
              [-80.93314251862148, 35.512047477730739, 0.0],
              [-80.932026518731348, 35.512139477966159, 0.0],
              [-80.932054918537261, 35.511933411933455, 0.0],
              [-80.932279185192073, 35.511681278382703, 0.0],
              [-80.932304518066047, 35.511360677995405, 0.0],
              [-80.93207638493584, 35.511154678027687, 0.0],
              [-80.931685185388858, 35.511040411984396, 0.0],
              [-80.931236518442191, 35.510490878551245, 0.0],
              [-80.931012385182342, 35.510399411855005, 0.0],
              [-80.930536384902013, 35.510514078438163, 0.0],
              [-80.930060318538878, 35.510766278372657, 0.0],
              [-80.9295005183005, 35.511224611194542, 0.0],
              [-80.928884384599556, 35.511545611552329, 0.0],
              [-80.928772517930923, 35.511728878559026, 0.0],
              [-80.928072385279776, 35.511889477755005, 0.0],
              [-80.928128384285316, 35.512072677906644, 0.0],
              [-80.928352718108798, 35.512255878221808, 0.0],
              [-80.928408984652833, 35.512668078085049, 0.0],
              [-80.929109717813844, 35.513102878614063, 0.0],
              [-80.929389984356646, 35.513469211660826, 0.0],
              [-80.929334184838211, 35.513721077800504, 0.0],
              [-80.928829785030288, 35.513538078569937, 0.0],
              [-80.927792918201121, 35.512966077928823, 0.0],
              [-80.927568584440365, 35.512508077856417, 0.0],
              [-80.926783917923217, 35.511890078094844, 0.0],
              [-80.926643584861026, 35.511684011572534, 0.0],
              [-80.926502984963648, 35.510813812011712, 0.0],
              [-80.926222585145965, 35.510470411844068, 0.0],
              [-80.925493984471302, 35.50994387825687, 0.0],
              [-80.925381584794721, 35.509279678653144, 0.0],
              [-80.924989118577344, 35.508753078399721, 0.0],
              [-80.924680984401689, 35.508867811986853, 0.0],
              [-80.924541184913167, 35.5094174779748, 0.0],
              [-80.924738185011009, 35.510837411131902, 0.0],
              [-80.924962584452288, 35.511180811614899, 0.0],
              [-80.924990917638112, 35.511593077903179, 0.0],
              [-80.925215118377608, 35.511799211476131, 0.0],
              [-80.925327585295449, 35.51253187831044, 0.0],
              [-80.925776117695662, 35.513035677806322, 0.0],
              [-80.925860318694518, 35.513402077889246, 0.0],
              [-80.925748585144049, 35.513699878230568, 0.0],
              [-80.925776918166619, 35.514203678222842, 0.0],
              [-80.926085185275539, 35.514478478489821, 0.0],
              [-80.9268419178903, 35.514867478136061, 0.0],
              [-80.927430318708161, 35.515004611470999, 0.0],
              [-80.927990918132792, 35.515622811888768, 0.0],
              [-80.928943585314002, 35.515988811361716, 0.0],
              [-80.929420184968961, 35.516377811535015, 0.0],
              [-80.930933318099505, 35.516812278485951, 0.0],
              [-80.931269784959156, 35.517132811626595, 0.0],
              [-80.931242118410239, 35.51761367791503, 0.0],
              [-80.930793984873986, 35.51782007777333, 0.0],
              [-80.929953317880845, 35.517545611422676, 0.0],
              [-80.929673384594508, 35.517774878342763, 0.0],
              [-80.929113184337808, 35.517981211871074, 0.0],
              [-80.928552784702617, 35.517706678016303, 0.0],
              [-80.927600184548851, 35.517752878308762, 0.0],
              [-80.927600384921334, 35.5179590116634, 0.0],
              [-80.928076917721995, 35.518325278128522, 0.0],
              [-80.928049118661093, 35.518577211671271, 0.0],
              [-80.927684984673846, 35.51882927801644, 0.0],
              [-80.926592518458719, 35.518509078296049, 0.0],
              [-80.926452184565605, 35.518326011162138, 0.0],
              [-80.926087984504321, 35.518165811677697, 0.0],
              [-80.92552778517279, 35.518257611821518, 0.0],
              [-80.925191718673219, 35.518395211082172, 0.0],
              [-80.924940384933038, 35.51967787803386, 0.0],
              [-80.92466051795202, 35.520113211948193, 0.0],
              [-80.92466071853265, 35.520342211273871, 0.0],
              [-80.924912918583132, 35.520548211295868, 0.0],
              [-80.924604984273444, 35.520937678317843, 0.0],
              [-80.924689584327183, 35.521808011761095, 0.0],
              [-80.924549917940325, 35.522334811295678, 0.0],
              [-80.924914385167625, 35.522907211571173, 0.0],
              [-80.925139184678486, 35.523983611689715, 0.0],
              [-80.925475717623982, 35.524487278336537, 0.0],
              [-80.926232384345482, 35.524967878300224, 0.0],
              [-80.926176385366333, 35.525082477973129, 0.0],
              [-80.924971784976762, 35.525128811392385, 0.0],
              [-80.924747784309417, 35.525380878078607, 0.0],
              [-80.924607984992477, 35.525839011627944, 0.0],
              [-80.924495985410616, 35.52590767839736, 0.0],
              [-80.924271785351408, 35.525793277692145, 0.0],
              [-80.924243384549868, 35.525152078630498, 0.0],
              [-80.924047185025742, 35.524968877961413, 0.0],
              [-80.923934784826571, 35.524396411475507, 0.0],
              [-80.923710518615479, 35.524167411521731, 0.0],
              [-80.923654318323557, 35.523892611208296, 0.0],
              [-80.923458118394777, 35.523709411579162, 0.0],
              [-80.922953785004566, 35.523663877698048, 0.0],
              [-80.922897118609384, 35.522610278383013, 0.0],
              [-80.922896584725592, 35.521579678217819, 0.0],
              [-80.922588185063944, 35.521282078017038, 0.0],
              [-80.922755785221199, 35.520343011984622, 0.0],
              [-80.922447384540135, 35.519930878155378, 0.0],
              [-80.922306584883273, 35.518831611555598, 0.0],
              [-80.921970184482859, 35.518282078248888, 0.0],
              [-80.921857717646063, 35.517617878080884, 0.0],
              [-80.921269118748555, 35.516931078244447, 0.0],
              [-80.921100718670232, 35.516427211209162, 0.0],
              [-80.920764384967825, 35.516129678419794, 0.0],
              [-80.920400185417918, 35.515946611687497, 0.0],
              [-80.920287585093376, 35.515099211946264, 0.0],
              [-80.919951318045534, 35.514664211173894, 0.0],
              [-80.920034784796627, 35.513908411388613, 0.0],
              [-80.919866718403057, 35.51372521198828, 0.0],
              [-80.919446384373742, 35.513542211235922, 0.0],
              [-80.919082118370511, 35.513244611282886, 0.0],
              [-80.918885918040019, 35.512878211115314, 0.0],
              [-80.918661317841483, 35.511962077771848, 0.0],
              [-80.918352984652188, 35.511664411611775, 0.0],
              [-80.91796078437001, 35.511664678485559, 0.0],
              [-80.91782078471509, 35.511779278558159, 0.0],
              [-80.917793118267738, 35.512420478058736, 0.0],
              [-80.917569118187643, 35.512374811808442, 0.0],
              [-80.917036585289921, 35.512077278417713, 0.0],
              [-80.916672584958036, 35.512169011583559, 0.0],
              [-80.91642058544042, 35.512444011989892, 0.0],
              [-80.915637118127748, 35.513978811681795, 0.0],
              [-80.915693385032341, 35.514711611756908, 0.0],
              [-80.915861718162873, 35.514963478258885, 0.0],
              [-80.916198184315107, 35.515787811146502, 0.0],
              [-80.916366385390162, 35.515971011369174, 0.0],
              [-80.916338785083369, 35.516772611401976, 0.0],
              [-80.917039384524173, 35.517253278334614, 0.0],
              [-80.917627585283071, 35.517344678567589, 0.0],
              [-80.917656118030834, 35.518009011054772, 0.0],
              [-80.918468718268031, 35.518558278375409, 0.0],
              [-80.918636918117244, 35.518787211845719, 0.0],
              [-80.918524984892727, 35.519108011104407, 0.0],
              [-80.918384984632766, 35.519199611056727, 0.0],
              [-80.917124384435823, 35.51926881189916, 0.0],
              [-80.916199918724018, 35.519017078646343, 0.0],
              [-80.915555718563624, 35.519154811141789, 0.0],
              [-80.915135718391127, 35.51968181171339, 0.0],
              [-80.915107918070419, 35.520162678551564, 0.0],
              [-80.915696384581622, 35.520529012008815, 0.0],
              [-80.915780585108919, 35.520895411894656, 0.0],
              [-80.916285185247659, 35.521513477721221, 0.0],
              [-80.916705584995398, 35.521811211108648, 0.0],
              [-80.918526785242804, 35.522291477891379, 0.0],
              [-80.918666917808068, 35.522543277872174, 0.0],
              [-80.919311384654449, 35.522886677742449, 0.0],
              [-80.919704118182878, 35.52357361149646, 0.0],
              [-80.920572984694431, 35.524329011250018, 0.0],
              [-80.920601185366266, 35.52469547862578, 0.0],
              [-80.919816585324369, 35.52451247783565, 0.0],
              [-80.919031918722837, 35.524031878483044, 0.0],
              [-80.918611717752043, 35.523917477780607, 0.0],
              [-80.917547117666416, 35.52394081126598, 0.0],
              [-80.917154984645748, 35.524124211950124, 0.0],
              [-80.916678717897057, 35.52410147856871, 0.0],
              [-80.91592211778908, 35.523827011938245, 0.0],
              [-80.915333917779748, 35.523964478451127, 0.0],
              [-80.915193917693117, 35.52417067846487, 0.0],
              [-80.915362318601595, 35.524491277895507, 0.0],
              [-80.915362718493427, 35.525338678487074, 0.0],
              [-80.915502784852123, 35.525590477967327, 0.0],
              [-80.915643384285005, 35.526529611621449, 0.0],
              [-80.916035917785237, 35.526895878198125, 0.0],
              [-80.916568185373123, 35.527147611321141, 0.0],
              [-80.917212784966964, 35.527674211813078, 0.0],
              [-80.917437118769456, 35.52776567823922, 0.0],
              [-80.918165384867734, 35.527673811552582, 0.0],
              [-80.918613985362583, 35.528292078326743, 0.0],
              [-80.919426785155736, 35.528749678465118, 0.0],
              [-80.920015184723354, 35.528978611216708, 0.0],
              [-80.920911785042549, 35.52904681117797, 0.0],
              [-80.921892517855767, 35.529275478475, 0.0],
              [-80.921724718293333, 35.529779478467852, 0.0],
              [-80.921192318105824, 35.529619411853382, 0.0],
              [-80.920547984836276, 35.529848678351946, 0.0],
              [-80.920268317707425, 35.530558811850959, 0.0],
              [-80.920268518394025, 35.530948078526336, 0.0],
              [-80.920352518471674, 35.530993878552252, 0.0],
              [-80.920240784766293, 35.531520611329775, 0.0],
              [-80.920325118718267, 35.532001611360215, 0.0],
              [-80.92021311786209, 35.532139078488605, 0.0],
              [-80.919848784830691, 35.532047678582984, 0.0],
              [-80.919400318101154, 35.531544011379268, 0.0],
              [-80.919231584930372, 35.530559211026848, 0.0],
              [-80.919091318564881, 35.530307211793129, 0.0],
              [-80.91802638536835, 35.529735078052909, 0.0],
              [-80.917830317647258, 35.529529078325915, 0.0],
              [-80.916877718343429, 35.529621011131198, 0.0],
              [-80.91645731805464, 35.529323411485827, 0.0],
              [-80.916121117758053, 35.529415078536104, 0.0],
              [-80.915925318604423, 35.530056611754254, 0.0],
              [-80.916065917958719, 35.531018411849978, 0.0],
              [-80.916234117817254, 35.53122441108669, 0.0],
              [-80.916234317767319, 35.531522211996773, 0.0],
              [-80.915393718745833, 35.5315912110417, 0.0],
              [-80.915309785357167, 35.531774411957223, 0.0],
              [-80.915113718354718, 35.531797411367904, 0.0],
              [-80.915001584292426, 35.531682877904196, 0.0],
              [-80.91505711788794, 35.530537811805047, 0.0],
              [-80.914832785296042, 35.530308811782724, 0.0],
              [-80.914384518320929, 35.530125678055313, 0.0],
              [-80.91416011877061, 35.52973647796555, 0.0],
              [-80.913627718673851, 35.529461811079344, 0.0],
              [-80.913571318435672, 35.528866411929712, 0.0],
              [-80.913403184285556, 35.528683278088764, 0.0],
              [-80.912590584594412, 35.528591878560817, 0.0],
              [-80.912282317646174, 35.528385811919691, 0.0],
              [-80.912142184393218, 35.528111077817599, 0.0],
              [-80.91205791814923, 35.527561411890069, 0.0],
              [-80.912001518564921, 35.526782678450587, 0.0],
              [-80.911497118555246, 35.526485078248569, 0.0],
              [-80.910824784881996, 35.526622811823415, 0.0],
              [-80.910348584452478, 35.527012277763866, 0.0],
              [-80.909900384421789, 35.526989478568616, 0.0],
              [-80.90936791846849, 35.526760678526038, 0.0],
              [-80.908835718112542, 35.526921078234444, 0.0],
              [-80.908275718723047, 35.527654077944256, 0.0],
              [-80.907659785030162, 35.528891211697847, 0.0],
              [-80.907604118000663, 35.529280478127887, 0.0],
              [-80.907716184601782, 35.529509478011477, 0.0],
              [-80.908164518445133, 35.529807077931558, 0.0],
              [-80.908080784931713, 35.530448411097161, 0.0],
              [-80.908192985116514, 35.530562811627235, 0.0],
              [-80.908865384302601, 35.530837478015592, 0.0],
              [-80.909257784888922, 35.531135078044734, 0.0],
              [-80.910294518535935, 35.531249278057679, 0.0],
              [-80.91093891878316, 35.531501078292862, 0.0],
              [-80.910911117622888, 35.531753011139386, 0.0],
              [-80.910322784651626, 35.532234077805498, 0.0],
              [-80.909650584485917, 35.53262381109387, 0.0],
              [-80.908978584464634, 35.53331101132575, 0.0],
              [-80.90897878430161, 35.533929411145827, 0.0],
              [-80.909287117687285, 35.534135411037205, 0.0],
              [-80.910295917576732, 35.534341211110956, 0.0],
              [-80.911108317669388, 35.534272278287339, 0.0],
              [-80.911528717621024, 35.534386611425369, 0.0],
              [-80.912172984541854, 35.534249078400372, 0.0],
              [-80.91248131856554, 35.534317611394847, 0.0],
              [-80.912649384864594, 35.534455011378213, 0.0],
              [-80.913293917733455, 35.534523477995691, 0.0],
              [-80.914162584763574, 35.534752278604714, 0.0],
              [-80.914946984679361, 35.534683211895207, 0.0],
              [-80.915591718382885, 35.535118211253852, 0.0],
              [-80.91660051833594, 35.535369677709568, 0.0],
              [-80.917104917948492, 35.535575678416265, 0.0],
              [-80.917104984396772, 35.535758878394432, 0.0],
              [-80.91486338498116, 35.535462011475019, 0.0],
              [-80.91435918490761, 35.535759877947811, 0.0],
              [-80.91281818494393, 35.535806211583548, 0.0],
              [-80.912678118570724, 35.535897878076369, 0.0],
              [-80.911893584435632, 35.536035477897876, 0.0],
              [-80.911501784435032, 35.5366770112851, 0.0],
              [-80.911473918202873, 35.537135077883015, 0.0],
              [-80.912314784521286, 35.537844678475217, 0.0],
              [-80.912314984791635, 35.53800507810427, 0.0],
              [-80.911278118624963, 35.537799278096017, 0.0],
              [-80.910773585203543, 35.537570411086278, 0.0],
              [-80.910409517869525, 35.537662078647386, 0.0],
              [-80.909988984322283, 35.537341611729168, 0.0],
              [-80.909932784534206, 35.536883611764232, 0.0],
              [-80.90954038495083, 35.536631811073612, 0.0],
              [-80.909148184475825, 35.536815078564366, 0.0],
              [-80.909148385421403, 35.537250278160492, 0.0],
              [-80.909400718172378, 35.537639477725591, 0.0],
              [-80.90926078495572, 35.538189277775075, 0.0],
              [-80.909344984355869, 35.538555678377186, 0.0],
              [-80.910045784998857, 35.539173811263886, 0.0],
              [-80.910242184747062, 35.539746411504289, 0.0],
              [-80.910578584827306, 35.540066878057608, 0.0],
              [-80.911447517719822, 35.54059341165906, 0.0],
              [-80.912063984586339, 35.540753478550705, 0.0],
              [-80.912708384547045, 35.540684611739302, 0.0],
              [-80.91324078500152, 35.54050127838655, 0.0],
              [-80.913688984371319, 35.540249077871358, 0.0],
              [-80.914249185386311, 35.540271811703469, 0.0],
              [-80.913773317598611, 35.540753011111384, 0.0],
              [-80.913717384323931, 35.541188077950707, 0.0],
              [-80.913885584269366, 35.541302611055968, 0.0],
              [-80.91447418437437, 35.541417011793676, 0.0],
              [-80.91436211839256, 35.541600211865159, 0.0],
              [-80.913857918435042, 35.541783611772701, 0.0],
              [-80.913045118302549, 35.541554811283575, 0.0],
              [-80.9116441178071, 35.541761278069401, 0.0],
              [-80.911419984565313, 35.541898878201316, 0.0],
              [-80.911420317741999, 35.542425611463699, 0.0],
              [-80.91161658474752, 35.542654611273569, 0.0],
              [-80.911700784961766, 35.543021011041226, 0.0],
              [-80.91234538432424, 35.543227011210824, 0.0],
              [-80.912485517820272, 35.543410078088122, 0.0],
              [-80.912850384446358, 35.544646678648455, 0.0],
              [-80.912962517708806, 35.544738277897402, 0.0],
              [-80.913663117744449, 35.54464647823059, 0.0],
              [-80.914139184920302, 35.5442112112828, 0.0],
              [-80.91450351865879, 35.544325478035177, 0.0],
              [-80.915091917894827, 35.544119211149358, 0.0],
              [-80.915484318489192, 35.544142011299051, 0.0],
              [-80.915372317649627, 35.54434807814021, 0.0],
              [-80.91439178450517, 35.545081411590623, 0.0],
              [-80.914392117706072, 35.545585277717478, 0.0],
              [-80.914560317669029, 35.545791411139042, 0.0],
              [-80.91439231780717, 35.546066211780335, 0.0],
              [-80.914392385173727, 35.546318211475587, 0.0],
              [-80.914784984292353, 35.546707278579696, 0.0],
              [-80.914953384442782, 35.547188211885022, 0.0],
              [-80.91559811867792, 35.547691878487711, 0.0],
              [-80.915822517834187, 35.548218478307916, 0.0],
              [-80.917083384760062, 35.547943278248695, 0.0],
              [-80.917867918654665, 35.547668211111208, 0.0],
              [-80.917980118133158, 35.547736811460766, 0.0],
              [-80.917952185116064, 35.547920078120214, 0.0],
              [-80.917531985226162, 35.548218011615695, 0.0],
              [-80.916215185028861, 35.548836877717889, 0.0],
              [-80.916103185143498, 35.549020077906214, 0.0],
              [-80.916103518586112, 35.549569811853445, 0.0],
              [-80.916495985096105, 35.550004678617462, 0.0],
              [-80.916748385016234, 35.550439877943596, 0.0],
              [-80.917084918725791, 35.550668811265133, 0.0],
              [-80.917813718719586, 35.550897478520866, 0.0],
              [-80.918150118354831, 35.551103477961128, 0.0],
              [-80.91806611804968, 35.551263811749003, 0.0],
              [-80.916748985354189, 35.551310211016641, 0.0],
              [-80.916664918200468, 35.551378878587443, 0.0],
              [-80.916806117681517, 35.553577478403326, 0.0],
              [-80.916470118567005, 35.55415021170522, 0.0],
              [-80.916273918054443, 35.554150210988873, 0.0],
              [-80.916161785137973, 35.553989877696289, 0.0],
              [-80.916021318602347, 35.553165477760544, 0.0],
              [-80.915740717912414, 35.552799211366967, 0.0],
              [-80.915796784797564, 35.552524411826866, 0.0],
              [-80.915908785281076, 35.552455611153135, 0.0],
              [-80.915852385087859, 35.551928878118638, 0.0],
              [-80.915572118252513, 35.551722811013292, 0.0],
              [-80.915095717661899, 35.551608477947227, 0.0],
              [-80.915067584548382, 35.551539811478648, 0.0],
              [-80.915683918181657, 35.551150211042284, 0.0],
              [-80.915683717650708, 35.550875411841204, 0.0],
              [-80.915599584320816, 35.55080667853445, 0.0],
              [-80.915515185269356, 35.549913478014687, 0.0],
              [-80.915038384325982, 35.549272411296172, 0.0],
              [-80.914421584886639, 35.548745811398632, 0.0],
              [-80.914281385308399, 35.54849401169453, 0.0],
              [-80.913720718442917, 35.548036077672307, 0.0],
              [-80.913524385220455, 35.547715611412542, 0.0],
              [-80.91315998471525, 35.547440811966368, 0.0],
              [-80.912823585280051, 35.547372278135363, 0.0],
              [-80.912459384718829, 35.547464011270421, 0.0],
              [-80.912095318062129, 35.547784678403112, 0.0],
              [-80.911955318628046, 35.548128277789182, 0.0],
              [-80.911871518169434, 35.54879247780444, 0.0],
              [-80.911395184596856, 35.548975878621647, 0.0],
              [-80.910582918311221, 35.549960878508294, 0.0],
              [-80.909994785339663, 35.550877211424385, 0.0],
              [-80.909686584486309, 35.550831478091929, 0.0],
              [-80.909770384509741, 35.550442211610303, 0.0],
              [-80.910050518238464, 35.549938211549595, 0.0],
              [-80.910134384979358, 35.549480211518251, 0.0],
              [-80.910022184831291, 35.549274078614253, 0.0],
              [-80.9096577845703, 35.548999277724391, 0.0],
              [-80.90878878468024, 35.548587278232553, 0.0],
              [-80.908900917869801, 35.548495678060007, 0.0],
              [-80.910133985128908, 35.548632811018649, 0.0],
              [-80.910778384926502, 35.548449278356777, 0.0],
              [-80.910918517765964, 35.548311811648944, 0.0],
              [-80.911170518611286, 35.547624677909347, 0.0],
              [-80.911646518120577, 35.546960278176478, 0.0],
              [-80.911730384508871, 35.546410611219358, 0.0],
              [-80.911562118193629, 35.54613587803221, 0.0],
              [-80.910384784931637, 35.54519727836297, 0.0],
              [-80.909515984501226, 35.54524327797732, 0.0],
              [-80.909207784317999, 35.545380811089217, 0.0],
              [-80.909067717985778, 35.545312211592766, 0.0],
              [-80.90895551787645, 35.545037278339407, 0.0],
              [-80.908675185077385, 35.544900011708044, 0.0],
              [-80.908702985153795, 35.544464878120408, 0.0],
              [-80.908815118472617, 35.544350278635143, 0.0],
              [-80.908506718514616, 35.544052678368928, 0.0],
              [-80.907918184569212, 35.543823811024843, 0.0],
              [-80.908030185450897, 35.543503077937082, 0.0],
              [-80.908254185183651, 35.543182411327592, 0.0],
              [-80.908057718700377, 35.542541078285154, 0.0],
              [-80.907300985101543, 35.54208327778133, 0.0],
              [-80.907188784433473, 35.541716877826047, 0.0],
              [-80.907048585043853, 35.541556611846261, 0.0],
              [-80.906823984964007, 35.540297011054122, 0.0],
              [-80.906179184832425, 35.539610211776548, 0.0],
              [-80.905534718764883, 35.539404211833286, 0.0],
              [-80.90533838524351, 35.539129411225069, 0.0],
              [-80.905198118649821, 35.538717078283682, 0.0],
              [-80.904861918145386, 35.538442411886173, 0.0],
              [-80.90410531879489, 35.538396878598995, 0.0],
              [-80.904384785004339, 35.536793478387409, 0.0],
              [-80.904188584667807, 35.5363126782946, 0.0],
              [-80.902675184326313, 35.535190811863522, 0.0],
              [-80.902562918452631, 35.53477847811051, 0.0],
              [-80.902058384932005, 35.534206077820684, 0.0],
              [-80.90211431859943, 35.533725011101126, 0.0],
              [-80.902254318755894, 35.533496011728616, 0.0],
              [-80.902198118535935, 35.533152477778053, 0.0],
              [-80.902029917808747, 35.532946411363326, 0.0],
              [-80.90200178435181, 35.532396811059257, 0.0],
              [-80.90186158453642, 35.532236477957909, 0.0],
              [-80.900992917987949, 35.531893078439381, 0.0],
              [-80.900740718398851, 35.531435078026362, 0.0],
              [-80.89992798427204, 35.530702411276266, 0.0],
              [-80.899563717865391, 35.530817077746576, 0.0],
              [-80.898919518085336, 35.53152707792632, 0.0],
              [-80.899031985212105, 35.532649411167178, 0.0],
              [-80.899956784980716, 35.533405011919974, 0.0],
              [-80.900068917726941, 35.533725611676246, 0.0],
              [-80.90057338460177, 35.534046078441101, 0.0],
              [-80.901049918495431, 35.534481211893194, 0.0],
              [-80.901217984878571, 35.534778878006172, 0.0],
              [-80.901274184465692, 35.535122411245375, 0.0],
              [-80.901946785321428, 35.5356948110296, 0.0],
              [-80.901947518495703, 35.537595678305181, 0.0],
              [-80.902059584420314, 35.537710277839558, 0.0],
              [-80.902088117931427, 35.539015677717202, 0.0],
              [-80.902340384779947, 35.539611078079901, 0.0],
              [-80.902424717834819, 35.540504411041276, 0.0],
              [-80.902649185460135, 35.541008078639777, 0.0],
              [-80.90267738441004, 35.541649411985567, 0.0],
              [-80.903097784667736, 35.542130211486942, 0.0],
              [-80.903462318334732, 35.542359211796999, 0.0],
              [-80.903462318106648, 35.542519477958805, 0.0],
              [-80.903294184986777, 35.542725677983711, 0.0],
              [-80.903294317725781, 35.542977611210752, 0.0],
              [-80.904106985088845, 35.54309187845562, 0.0],
              [-80.903910985071121, 35.543435478278049, 0.0],
              [-80.903434718084725, 35.543893611036772, 0.0],
              [-80.903434984275108, 35.544741077717575, 0.0],
              [-80.903603318072953, 35.545244878089392, 0.0],
              [-80.903827585446635, 35.545588411555507, 0.0],
              [-80.903771785009667, 35.546069411422735, 0.0],
              [-80.903575718222342, 35.546367211342819, 0.0],
              [-80.903463917606146, 35.546985478281883, 0.0],
              [-80.903575984570054, 35.547260411401176, 0.0],
              [-80.904276918546088, 35.54810767820404, 0.0],
              [-80.904248984951252, 35.548336678060828, 0.0],
              [-80.903968717765167, 35.54831381127385, 0.0],
              [-80.903211917745821, 35.547810211203668, 0.0],
              [-80.902651518321065, 35.547879011061291, 0.0],
              [-80.902623585432664, 35.548222611570118, 0.0],
              [-80.902063185215326, 35.548405878510472, 0.0],
              [-80.901783118773949, 35.549184678123098, 0.0],
              [-80.901812317645962, 35.55225367766559, 0.0],
              [-80.901700184996997, 35.552505611674142, 0.0],
              [-80.90175651850214, 35.553353011394172, 0.0],
              [-80.901868717940701, 35.553490411655744, 0.0],
              [-80.901952918707721, 35.553902677725006, 0.0],
              [-80.902093117905636, 35.554131611133258, 0.0],
              [-80.902065518318295, 35.555414078416021, 0.0],
              [-80.902177784866353, 35.556055478370993, 0.0],
              [-80.902009718028822, 35.556170011459372, 0.0],
              [-80.901785518098052, 35.555964011968747, 0.0],
              [-80.901785384410843, 35.555872411334086, 0.0],
              [-80.901420984908327, 35.555528877948952, 0.0],
              [-80.901476917676263, 35.555047811483519, 0.0],
              [-80.901560918495178, 35.554933277945899, 0.0],
              [-80.901476517602433, 35.553971411117551, 0.0],
              [-80.901111917735108, 35.553284411706102, 0.0],
              [-80.900803384766434, 35.552414278373305, 0.0],
              [-80.900719118036164, 35.55156687819786, 0.0],
              [-80.900578917716714, 35.551360677890528, 0.0],
              [-80.900242518060296, 35.551246211592392, 0.0],
              [-80.898533118517179, 35.551590211945594, 0.0],
              [-80.898448984748526, 35.551521477849903, 0.0],
              [-80.898476984744235, 35.551315411758893, 0.0],
              [-80.899905984893749, 35.550742478172218, 0.0],
              [-80.900270318690531, 35.55028441124503, 0.0],
              [-80.900634517815774, 35.550078211852131, 0.0],
              [-80.900718518113564, 35.549917811655668, 0.0],
              [-80.900634317937445, 35.549368211707296, 0.0],
              [-80.900409985458921, 35.54911627858305, 0.0],
              [-80.899569318484524, 35.54884167852795, 0.0],
              [-80.899681184504743, 35.548658411395976, 0.0],
              [-80.900269717880207, 35.548566678420485, 0.0],
              [-80.900885984437579, 35.547787811919598, 0.0],
              [-80.900969918098028, 35.547146478651335, 0.0],
              [-80.90116591795541, 35.546871611350859, 0.0],
              [-80.901165784979526, 35.54634481144582, 0.0],
              [-80.901025585172476, 35.546138811794918, 0.0],
              [-80.900184784908561, 35.545749678393072, 0.0],
              [-80.900184785259086, 35.545566411329517, 0.0],
              [-80.901053318805992, 35.545474611734839, 0.0],
              [-80.901193518119229, 35.545360011372424, 0.0],
              [-80.901165384379382, 35.544993478468868, 0.0],
              [-80.90091298510626, 35.544741677663069, 0.0],
              [-80.900744584655698, 35.543756878459284, 0.0],
              [-80.900436318125102, 35.543528011843975, 0.0],
              [-80.900043918283075, 35.543390611337017, 0.0],
              [-80.900043717774835, 35.542749278035537, 0.0],
              [-80.899735384860534, 35.542497478632299, 0.0],
              [-80.899623184293148, 35.54206241118591, 0.0],
              [-80.898922517632656, 35.541490011480832, 0.0],
              [-80.898978518175014, 35.541306677707468, 0.0],
              [-80.899454785355715, 35.540940078483182, 0.0],
              [-80.8993705845715, 35.54052787780676, 0.0],
              [-80.89900618428139, 35.540161478003853, 0.0],
              [-80.898921985386906, 35.539703477891898, 0.0],
              [-80.898473585378383, 35.539474477785433, 0.0],
              [-80.898473517964689, 35.539360011773702, 0.0],
              [-80.898249318705211, 35.539131078566861, 0.0],
              [-80.897941118573726, 35.539062411622332, 0.0],
              [-80.897660784450991, 35.538833478460511, 0.0],
              [-80.896764317711373, 35.539429078261044, 0.0],
              [-80.896652317676768, 35.539864278537486, 0.0],
              [-80.896147984475093, 35.540070478518089, 0.0],
              [-80.895951984841531, 35.540437011230672, 0.0],
              [-80.896008184268709, 35.54119281132602, 0.0],
              [-80.896288585309605, 35.541650678126352, 0.0],
              [-80.896372785341498, 35.542314877783241, 0.0],
              [-80.89648511865667, 35.542795811965313, 0.0],
              [-80.895644384632192, 35.542750278272415, 0.0],
              [-80.894635385108543, 35.542361078172732, 0.0],
              [-80.893906984599141, 35.542475811060314, 0.0],
              [-80.893822984445734, 35.542590278517245, 0.0],
              [-80.893935384920994, 35.543941411023262, 0.0],
              [-80.894299718078784, 35.544239077867701, 0.0],
              [-80.894383917682632, 35.544766011618343, 0.0],
              [-80.895084584581184, 35.545292611361582, 0.0],
              [-80.895112585289596, 35.545475811340957, 0.0],
              [-80.895308784993105, 35.545682011304677, 0.0],
              [-80.895421118051516, 35.54625441172815, 0.0],
              [-80.896009718313294, 35.546895677758457, 0.0],
              [-80.89600978497019, 35.547262078187444, 0.0],
              [-80.89595378500205, 35.547445277916971, 0.0],
              [-80.895533384740204, 35.547239211739431, 0.0],
              [-80.895000784496318, 35.546758411936331, 0.0],
              [-80.89446838449787, 35.546575211048626, 0.0],
              [-80.894356317637971, 35.546277611257764, 0.0],
              [-80.894075984495672, 35.546071477704828, 0.0],
              [-80.893487384778197, 35.545911277897687, 0.0],
              [-80.89323511801409, 35.545705211038452, 0.0],
              [-80.892198318679363, 35.545682411875923, 0.0],
              [-80.891806117697811, 35.545568011498709, 0.0],
              [-80.891105517651496, 35.545820078474542, 0.0],
              [-80.890965385089743, 35.545751411021335, 0.0],
              [-80.890488985388743, 35.545843011370366, 0.0],
              [-80.890236918249769, 35.546140811159972, 0.0],
              [-80.890236985050521, 35.546713411904229, 0.0],
              [-80.89040511834942, 35.546896611294237, 0.0],
              [-80.890349184543282, 35.547171411940667, 0.0],
              [-80.8893963843433, 35.547538011301867, 0.0],
              [-80.889256385143781, 35.54774407786168, 0.0],
              [-80.889172318080824, 35.548339611590144, 0.0],
              [-80.889144384739865, 35.548683211032241, 0.0],
              [-80.889032384950198, 35.548889277838455, 0.0],
              [-80.889060518121326, 35.549141277705843, 0.0],
              [-80.889452784957456, 35.549370211808238, 0.0],
              [-80.890433717910071, 35.549759411563727, 0.0],
              [-80.890798185227084, 35.550354877897178, 0.0],
              [-80.89124651837794, 35.550538011779352, 0.0],
              [-80.892339518352344, 35.550583678575499, 0.0],
              [-80.89312411865555, 35.550469010991215, 0.0],
              [-80.89329238499279, 35.550560611521099, 0.0],
              [-80.893152384460151, 35.551133211681197, 0.0],
              [-80.891302785093814, 35.551522811675852, 0.0],
              [-80.891078718252047, 35.5517976116043, 0.0],
              [-80.891106918376408, 35.552484811404412, 0.0],
              [-80.891274984757487, 35.55266801156435, 0.0],
              [-80.892143784828008, 35.552759478305781, 0.0],
              [-80.892143785458316, 35.552896877802091, 0.0],
              [-80.892003784549331, 35.553057211540569, 0.0],
              [-80.892003785299991, 35.553194678116611, 0.0],
              [-80.892592318122055, 35.55367547854506, 0.0],
              [-80.892564384652644, 35.553858677973096, 0.0],
              [-80.892256184648829, 35.553835877783619, 0.0],
              [-80.891751718569438, 35.553629811599421, 0.0],
              [-80.891275184415548, 35.553721478396554, 0.0],
              [-80.891219317974873, 35.55406501164628, 0.0],
              [-80.891611718561393, 35.554339811672243, 0.0],
              [-80.891555718406124, 35.554775011857728, 0.0],
              [-80.891723917794494, 35.554981011032893, 0.0],
              [-80.892004385293177, 35.555965877668953, 0.0],
              [-80.892340718564341, 35.556286478235194, 0.0],
              [-80.892312985349989, 35.557385811204853, 0.0],
              [-80.892789518476079, 35.557889611960057, 0.0],
              [-80.892761584717519, 35.558324677929527, 0.0],
              [-80.893266117871875, 35.558691011203578, 0.0],
              [-80.893434385353373, 35.559057411226945, 0.0],
              [-80.893686718499012, 35.559240677834552, 0.0],
              [-80.895620718414463, 35.560064811914273, 0.0],
              [-80.895592784955724, 35.560271011846893, 0.0],
              [-80.893939118425081, 35.559904878460664, 0.0],
              [-80.893490517943874, 35.559721678302182, 0.0],
              [-80.892845985303211, 35.559836278536515, 0.0],
              [-80.892649785190827, 35.560134078456322, 0.0],
              [-80.892509785343819, 35.560615078601799, 0.0],
              [-80.892453984936324, 35.561622811078678, 0.0],
              [-80.892341984646791, 35.561920611785347, 0.0],
              [-80.892426317959178, 35.562928278238559, 0.0],
              [-80.892258317996792, 35.56336341190574, 0.0],
              [-80.892230318368945, 35.563890211687763, 0.0],
              [-80.891949984306834, 35.563775811849681, 0.0],
              [-80.891529385254628, 35.562699411905129, 0.0],
              [-80.891529184456829, 35.562149678327387, 0.0],
              [-80.891641384962213, 35.562012278448606, 0.0],
              [-80.891584918618733, 35.560409077847389, 0.0],
              [-80.891332517887349, 35.559905277884695, 0.0],
              [-80.890996184562297, 35.55963041125321, 0.0],
              [-80.891164117838855, 35.558554078426376, 0.0],
              [-80.890995917902188, 35.558256277773744, 0.0],
              [-80.890995718329577, 35.557660811912264, 0.0],
              [-80.890350985068579, 35.557019611932546, 0.0],
              [-80.890294784430964, 35.556057677933048, 0.0],
              [-80.88970611778764, 35.555279077846514, 0.0],
              [-80.889033384421197, 35.554683811314092, 0.0],
              [-80.888949184848016, 35.554454678307408, 0.0],
              [-80.888612785055031, 35.554111277710042, 0.0],
              [-80.88866878460226, 35.553607411429645, 0.0],
              [-80.889173184827186, 35.552851411278013, 0.0],
              [-80.889088984312352, 35.552462078120065, 0.0],
              [-80.888808784567345, 35.552049878244439, 0.0],
              [-80.888360318557062, 35.551775211832187, 0.0],
              [-80.888163984802148, 35.551385877834093, 0.0],
              [-80.887883785236966, 35.551156811964582, 0.0],
              [-80.887687585221229, 35.551248411188347, 0.0],
              [-80.887883784901305, 35.551683611960904, 0.0],
              [-80.88779998527373, 35.552439411935381, 0.0],
              [-80.887659918739118, 35.552828811614368, 0.0],
              [-80.887407717796364, 35.55278307804295, 0.0],
              [-80.887211385096492, 35.551821077869924, 0.0],
              [-80.887099318745413, 35.551729477958034, 0.0],
              [-80.886622918697455, 35.552027278408815, 0.0],
              [-80.885894184408372, 35.551340278322705, 0.0],
              [-80.885641984965247, 35.551340277778969, 0.0],
              [-80.885445785094845, 35.551454811213517, 0.0],
              [-80.885445918315412, 35.551706811107969, 0.0],
              [-80.8859783185937, 35.552325077969137, 0.0],
              [-80.885894318650344, 35.55289767824064, 0.0],
              [-80.885642184951948, 35.553241278027556, 0.0],
              [-80.884913584572601, 35.553722211112742, 0.0],
              [-80.884437185280817, 35.554226278519586, 0.0],
              [-80.884269117976729, 35.554592678001377, 0.0],
              [-80.884269318386359, 35.555554611511681, 0.0],
              [-80.884437384476399, 35.555852278246704, 0.0],
              [-80.885866918384394, 35.556859811680972, 0.0],
              [-80.88642751770405, 35.5575010785955, 0.0],
              [-80.886651784463098, 35.557890411231988, 0.0],
              [-80.88682011832941, 35.558898078207491, 0.0],
              [-80.886483784359186, 35.558829478118319, 0.0],
              [-80.886063184873564, 35.558096678181293, 0.0],
              [-80.885558718769147, 35.557684478230996, 0.0],
              [-80.884717918023142, 35.55747847853371, 0.0],
              [-80.884185318136858, 35.55752427840887, 0.0],
              [-80.883877118463928, 35.557799078415862, 0.0],
              [-80.883400718599361, 35.557890811279677, 0.0],
              [-80.883456785409024, 35.559058811411582, 0.0],
              [-80.883624985130552, 35.559310678297756, 0.0],
              [-80.884241718785162, 35.559837411558306, 0.0],
              [-80.884269718437423, 35.560020678186362, 0.0],
              [-80.883400918154393, 35.560158211803554, 0.0],
              [-80.883148718662298, 35.559814678027571, 0.0],
              [-80.8822237182534, 35.559356611201814, 0.0],
              [-80.881775317647552, 35.559402477915192, 0.0],
              [-80.881663118806216, 35.559494077762132, 0.0],
              [-80.881046518822131, 35.559654478438105, 0.0],
              [-80.880794318100484, 35.559837677905143, 0.0],
              [-80.880766385443266, 35.560456078566176, 0.0],
              [-80.879505118617246, 35.56091421103914, 0.0],
              [-80.879280984785964, 35.561395211670373, 0.0],
              [-80.878524184624325, 35.561624278510472, 0.0],
              [-80.878327984359387, 35.561784611058144, 0.0],
              [-80.878524185373195, 35.562494611133296, 0.0],
              [-80.87849618451213, 35.562769411539527, 0.0],
              [-80.878159917755369, 35.563250411018579, 0.0],
              [-80.878215918719661, 35.563433611814808, 0.0],
              [-80.878580317961109, 35.563571011871772, 0.0],
              [-80.881243118102603, 35.564097478251895, 0.0],
              [-80.882364318190326, 35.564532678048074, 0.0],
              [-80.882728717670204, 35.564807411356433, 0.0],
              [-80.882728718618111, 35.564990611752336, 0.0],
              [-80.880626518478408, 35.5643954110013, 0.0],
              [-80.878720384520008, 35.564509877849773, 0.0],
              [-80.878580384691588, 35.564601611468831, 0.0],
              [-80.878580384734363, 35.564876411877989, 0.0],
              [-80.878832584356303, 35.565288611061355, 0.0],
              [-80.878888784369195, 35.566365211208492, 0.0],
              [-80.879112984540441, 35.567143810991446, 0.0],
              [-80.879337318580269, 35.567510211297972, 0.0],
              [-80.87939331875765, 35.567922478241272, 0.0],
              [-80.879785918314198, 35.568288878352973, 0.0],
              [-80.879841784837865, 35.56856381164787, 0.0],
              [-80.879925985168299, 35.568609611673537, 0.0],
              [-80.879925984439723, 35.568747011289844, 0.0],
              [-80.880010118588558, 35.56879281129612, 0.0],
              [-80.880150318390108, 35.569365278401037, 0.0],
              [-80.880430584369876, 35.569525611503607, 0.0],
              [-80.881607985154815, 35.569777411764889, 0.0],
              [-80.883373985372316, 35.570487277630754, 0.0],
              [-80.883570184995662, 35.570647611290291, 0.0],
              [-80.883514118570261, 35.570785077986066, 0.0],
              [-80.882280718011501, 35.570556078150481, 0.0],
              [-80.880963318438191, 35.570144011933898, 0.0],
              [-80.880318518240642, 35.57023561121494, 0.0],
              [-80.880122384587352, 35.570350078384507, 0.0],
              [-80.879982185081317, 35.571380811538141, 0.0],
              [-80.879393584540821, 35.572571811241957, 0.0],
              [-80.879253518336242, 35.573304611725561, 0.0],
              [-80.87911338523223, 35.573419211306259, 0.0],
              [-80.878917118342571, 35.573281678134933, 0.0],
              [-80.878888984502694, 35.571999277786297, 0.0],
              [-80.879225385205402, 35.571312077913248, 0.0],
              [-80.879281385145518, 35.570716611403356, 0.0],
              [-80.878972984966609, 35.570235678117207, 0.0],
              [-80.878860918221847, 35.569365411912784, 0.0],
              [-80.878580517633452, 35.568884477697665, 0.0],
              [-80.878244185401684, 35.568609678114463, 0.0],
              [-80.877767718661801, 35.568655411457165, 0.0],
              [-80.877263118010319, 35.568861611045136, 0.0],
              [-80.877094918176823, 35.568838678296089, 0.0],
              [-80.877094918232061, 35.568495211041302, 0.0],
              [-80.877571385273299, 35.567899677829317, 0.0],
              [-80.877543318096357, 35.567212611471255, 0.0],
              [-80.877262984544046, 35.56684607776122, 0.0],
              [-80.877206984385779, 35.566273611645428, 0.0],
              [-80.876758518170618, 35.565769678144662, 0.0],
              [-80.875945584918242, 35.565403278523135, 0.0],
              [-80.875497118391223, 35.565357477970046, 0.0],
              [-80.874796385303696, 35.565495011054104, 0.0],
              [-80.874263918374169, 35.565586611274512, 0.0],
              [-80.873955585221836, 35.565472011221665, 0.0],
              [-80.873394984582333, 35.56455601098844, 0.0],
              [-80.8732827854802, 35.564510077831173, 0.0],
              [-80.872890518397156, 35.56460167783802, 0.0],
              [-80.872722318718729, 35.564945278422911, 0.0],
              [-80.872666184364675, 35.56572401132081, 0.0],
              [-80.872750318825126, 35.565907211846827, 0.0],
              [-80.872694185224645, 35.566273677976731, 0.0],
              [-80.872526117714344, 35.566296611442802, 0.0],
              [-80.872161718001124, 35.56556367836567, 0.0],
              [-80.872049584391036, 35.564670478204214, 0.0],
              [-80.871629185411678, 35.564097811248764, 0.0],
              [-80.870536117692978, 35.564052011935374, 0.0],
              [-80.870199784738602, 35.56389167791113, 0.0],
              [-80.869751185113188, 35.563937478094914, 0.0],
              [-80.869274784950122, 35.564258211326631, 0.0],
              [-80.869246585357899, 35.565013878598045, 0.0],
              [-80.868658118290639, 35.565174211458782, 0.0],
              [-80.868686117939703, 35.565678211455008, 0.0],
              [-80.868882185107964, 35.566250677902723, 0.0],
              [-80.868882118511877, 35.567876677760673, 0.0],
              [-80.868966184576706, 35.568082811578655, 0.0],
              [-80.868965984490302, 35.569525878451728, 0.0],
              [-80.86966658512695, 35.571403811326995, 0.0],
              [-80.869610585192333, 35.571655678437807, 0.0],
              [-80.869386385468005, 35.571587011933929, 0.0],
              [-80.869021984665579, 35.571151877755753, 0.0],
              [-80.86882578442362, 35.57057927795568, 0.0],
              [-80.86832131837852, 35.569640278542089, 0.0],
              [-80.868209317892095, 35.568586811303867, 0.0],
              [-80.868041118526548, 35.568403477743828, 0.0],
              [-80.868013185129811, 35.56677741163243, 0.0],
              [-80.867760985274913, 35.566479611139862, 0.0],
              [-80.86753678458534, 35.566525478021624, 0.0],
              [-80.8674245848146, 35.566846078206574, 0.0],
              [-80.867396517983479, 35.568243211630346, 0.0],
              [-80.867200185140263, 35.568518011653325, 0.0],
              [-80.866835784962873, 35.56847221100211, 0.0],
              [-80.866751717962117, 35.568220278365999, 0.0],
              [-80.866948118203069, 35.566136077745305, 0.0],
              [-80.866808117996271, 35.564922211401218, 0.0],
              [-80.866667984486597, 35.564784811448632, 0.0],
              [-80.866079384773272, 35.564807678192118, 0.0],
              [-80.865995117913272, 35.566204811616004, 0.0],
              [-80.865798918046764, 35.566433677794748, 0.0],
              [-80.865210318372306, 35.566617011324702, 0.0],
              [-80.864845785509118, 35.566914678429718, 0.0],
              [-80.864509384376319, 35.567075011637556, 0.0],
              [-80.864313118560489, 35.567578811915006, 0.0],
              [-80.863836584370148, 35.568334477683081, 0.0],
              [-80.863668317876659, 35.568975811617314, 0.0],
              [-80.863724318626055, 35.569273611088036, 0.0],
              [-80.86439691772469, 35.569777477971101, 0.0],
              [-80.864396985143571, 35.570075211941365, 0.0],
              [-80.864172784779385, 35.570075211825063, 0.0],
              [-80.863499985246492, 35.56977741097495, 0.0],
              [-80.863275718602083, 35.569846078223236, 0.0],
              [-80.863303718026572, 35.570533211716246, 0.0],
              [-80.862883185153493, 35.570601811736921, 0.0],
              [-80.862882985362361, 35.571953077962704, 0.0],
              [-80.863023117788657, 35.572228011412271, 0.0],
              [-80.862966917799739, 35.572892078161352, 0.0],
              [-80.863835785272016, 35.573602211009991, 0.0],
              [-80.864172118825465, 35.574106077928079, 0.0],
              [-80.864172117791441, 35.574403811609798, 0.0],
              [-80.8635553845549, 35.574266411730783, 0.0],
              [-80.863162984741578, 35.574083077728332, 0.0],
              [-80.862490185245733, 35.574243411399316, 0.0],
              [-80.862406118009716, 35.574357877731387, 0.0],
              [-80.862377984709639, 35.574724278255957, 0.0],
              [-80.862041585238316, 35.575067811789843, 0.0],
              [-80.862069384985872, 35.575548811724168, 0.0],
              [-80.86232171790013, 35.576098478558201, 0.0],
              [-80.862321585526985, 35.576579478255148, 0.0],
              [-80.861732785454137, 35.577426811328849, 0.0],
              [-80.861704584452767, 35.578022277789799, 0.0],
              [-80.861368185467867, 35.578434477695147, 0.0],
              [-80.861115718174815, 35.579167411477378, 0.0],
              [-80.860891384667525, 35.579236011601502, 0.0],
              [-80.86081358452509, 35.579172478184276, 0.0],
              [-80.860807318751398, 35.579167278574253, 0.0],
              [-80.860891584841283, 35.578319878455595, 0.0],
              [-80.860751384581377, 35.578045078169112, 0.0],
              [-80.860443118018424, 35.577747278271687, 0.0],
              [-80.860443185046194, 35.577335011850039, 0.0],
              [-80.860919918097295, 35.576831277658968, 0.0],
              [-80.860919984522468, 35.576258678595494, 0.0],
              [-80.860807918670673, 35.576098411769259, 0.0],
              [-80.860892118121569, 35.575319611711187, 0.0],
              [-80.860723985003631, 35.575136411429149, 0.0],
              [-80.860695985469917, 35.574953211880697, 0.0],
              [-80.861144584793038, 35.574563877999879, 0.0],
              [-80.861340918038721, 35.573808078035569, 0.0],
              [-80.861397118566401, 35.573029410982194, 0.0],
              [-80.861200985313346, 35.572342278353332, 0.0],
              [-80.861425384556938, 35.57202167775403, 0.0],
              [-80.861453384624639, 35.57167821102064, 0.0],
              [-80.861313385280241, 35.571014011394759, 0.0],
              [-80.861397384874735, 35.570922411372791, 0.0],
              [-80.861369584994222, 35.570441477880159, 0.0],
              [-80.861790118042222, 35.569433677867195, 0.0],
              [-80.861678184500946, 35.56874661172958, 0.0],
              [-80.861734318805858, 35.56826567840416, 0.0],
              [-80.861594118397178, 35.568082411252796, 0.0],
              [-80.860697185517438, 35.568082278471387, 0.0],
              [-80.860052584472712, 35.567555477984335, 0.0],
              [-80.859744318172474, 35.567692878274649, 0.0],
              [-80.859576118747071, 35.56801347833639, 0.0],
              [-80.858202517928248, 35.568311077641901, 0.0],
              [-80.858959918030862, 35.56542541117912, 0.0],
              [-80.859576585383593, 35.565127678569972, 0.0],
              [-80.86002511885053, 35.564761411079765, 0.0],
              [-80.861286518814225, 35.564418011530094, 0.0],
              [-80.861903118029375, 35.564372277703477, 0.0],
              [-80.862743984633767, 35.564555477673849, 0.0],
              [-80.863108384949584, 35.564234878008932, 0.0],
              [-80.863697118510444, 35.563960077716644, 0.0],
              [-80.864425785403171, 35.563868611891088, 0.0],
              [-80.86450991821944, 35.563799877958026, 0.0],
              [-80.864509984999643, 35.563616678086774, 0.0],
              [-80.864173717942222, 35.563250078492281, 0.0],
              [-80.863865317843661, 35.563089811563181, 0.0],
              [-80.862548185306039, 35.562059078148579, 0.0],
              [-80.862043718324358, 35.561875878457499, 0.0],
              [-80.860446184925891, 35.561555011612029, 0.0],
              [-80.859857584925152, 35.561303011403595, 0.0],
              [-80.859829584572211, 35.561165611547935, 0.0],
              [-80.859969918686673, 35.560684678595422, 0.0],
              [-80.860670518527627, 35.560707678294179, 0.0],
              [-80.86170751885092, 35.561211611571125, 0.0],
              [-80.86246418531961, 35.561165811619865, 0.0],
              [-80.86280058528223, 35.561257477865773, 0.0],
              [-80.86310891818448, 35.561188811246922, 0.0],
              [-80.863277117825717, 35.560914011683217, 0.0],
              [-80.863192985185577, 35.560249811898011, 0.0],
              [-80.862912785194808, 35.559677211604722, 0.0],
              [-80.862940917953196, 35.559448277967185, 0.0],
              [-80.863193184500219, 35.559517011921848, 0.0],
              [-80.863865718751242, 35.56024987826919, 0.0],
              [-80.864061784843571, 35.560799611216162, 0.0],
              [-80.864117784862387, 35.561555277717403, 0.0],
              [-80.86448211864753, 35.561784411373843, 0.0],
              [-80.86479031841526, 35.562379811925588, 0.0],
              [-80.865126584998791, 35.562723478082759, 0.0],
              [-80.865406984840618, 35.562837878005951, 0.0],
              [-80.865574985483789, 35.56302121135586, 0.0],
              [-80.865799184899657, 35.563845811367699, 0.0],
              [-80.865911318368461, 35.563937411736589, 0.0],
              [-80.866583985116392, 35.563800011840279, 0.0],
              [-80.866556117703482, 35.563341877847236, 0.0],
              [-80.866331918760679, 35.562677678384993, 0.0],
              [-80.866444118780095, 35.562173811769611, 0.0],
              [-80.866332118212924, 35.561326478049438, 0.0],
              [-80.865967784984349, 35.560479011544835, 0.0],
              [-80.865883785145911, 35.559975211627794, 0.0],
              [-80.865967917776729, 35.559837678089217, 0.0],
              [-80.866163984339622, 35.559860678368111, 0.0],
              [-80.866388184613228, 35.560066811218526, 0.0],
              [-80.866808585319347, 35.560776811673307, 0.0],
              [-80.866836517717886, 35.560960011662772, 0.0],
              [-80.867284984435386, 35.561532611751744, 0.0],
              [-80.867649384945707, 35.561463877821694, 0.0],
              [-80.868013717811337, 35.56116621132891, 0.0],
              [-80.868518184873253, 35.561166278075852, 0.0],
              [-80.869106785253109, 35.561006011313076, 0.0],
              [-80.86975138444555, 35.561097478249081, 0.0],
              [-80.870059784482748, 35.561006010990809, 0.0],
              [-80.870143785059028, 35.5608686117153, 0.0],
              [-80.870143917740435, 35.559860811298591, 0.0],
              [-80.870255984680483, 35.559677611400296, 0.0],
              [-80.870424184405493, 35.559746277925875, 0.0],
              [-80.871517185260316, 35.561005877914695, 0.0],
              [-80.87235798542271, 35.561005878204035, 0.0],
              [-80.873058784418063, 35.561189211011758, 0.0],
              [-80.873731385362731, 35.561509878170448, 0.0],
              [-80.874796518757975, 35.561693011430243, 0.0],
              [-80.875441117646574, 35.561509811673396, 0.0],
              [-80.876309918365394, 35.560662411566526, 0.0],
              [-80.87645011793758, 35.560387477903106, 0.0],
              [-80.87653418461764, 35.559700411512722, 0.0],
              [-80.876394118471254, 35.559402677876619, 0.0],
              [-80.876449984473624, 35.558738611816494, 0.0],
              [-80.876365984751871, 35.558646878454788, 0.0],
              [-80.876449984533679, 35.558005611478947, 0.0],
              [-80.875973584918825, 35.557639211168024, 0.0],
              [-80.874796385442664, 35.557249878551794, 0.0],
              [-80.873955718330095, 35.556883477930974, 0.0],
              [-80.872526318532223, 35.556608611351912, 0.0],
              [-80.871853718211625, 35.556608611085117, 0.0],
              [-80.871965917837286, 35.556288011390201, 0.0],
              [-80.871853785304879, 35.555784211006085, 0.0],
              [-80.872246118485677, 35.555715478251273, 0.0],
              [-80.87331111798791, 35.556081811354169, 0.0],
              [-80.874039785326744, 35.555875678283435, 0.0],
              [-80.874796518055163, 35.556127611225435, 0.0],
              [-80.875356985041918, 35.556127677691507, 0.0],
              [-80.875973584309236, 35.556333811290223, 0.0],
              [-80.877795318347083, 35.556654411066248, 0.0],
              [-80.878439785147819, 35.555898478031715, 0.0],
              [-80.878467784847558, 35.555532011657554, 0.0],
              [-80.878355718449058, 35.555394678159935, 0.0],
              [-80.878299718416883, 35.555005278370132, 0.0],
              [-80.878579918297987, 35.55466181104908, 0.0],
              [-80.878635985380569, 35.554135011554372, 0.0],
              [-80.879000318653539, 35.553699811626842, 0.0],
              [-80.879084384807214, 35.553287477989826, 0.0],
              [-80.878972184426658, 35.553081411496571, 0.0],
              [-80.879028318772555, 35.552348611897528, 0.0],
              [-80.878916184778959, 35.552211078616111, 0.0],
              [-80.878803985349194, 35.551615678201699, 0.0],
              [-80.87866391874077, 35.551432478058402, 0.0],
              [-80.877346718455556, 35.551386678617803, 0.0],
              [-80.876786318625875, 35.551157611350391, 0.0],
              [-80.876421917632157, 35.551249278010303, 0.0],
              [-80.875665185402369, 35.551272210988586, 0.0],
              [-80.874796518351786, 35.551615811285963, 0.0],
              [-80.8745443181284, 35.551661611076376, 0.0],
              [-80.874432184714067, 35.551593011585801, 0.0],
              [-80.874404185390517, 35.550791278486145, 0.0],
              [-80.874151918693954, 35.550333211730255, 0.0],
              [-80.873647517678208, 35.550035477717294, 0.0],
              [-80.873451384998916, 35.550012611188841, 0.0],
              [-80.873058984801418, 35.550310411057666, 0.0],
              [-80.872218317821478, 35.550447811385609, 0.0],
              [-80.871966118368306, 35.550127211310496, 0.0],
              [-80.871629785025476, 35.550012678248287, 0.0],
              [-80.871601785485993, 35.549737811316504, 0.0],
              [-80.871405717647036, 35.549440011119238, 0.0],
              [-80.869836318023289, 35.549325478178126, 0.0],
              [-80.869051585402971, 35.54962321155012, 0.0],
              [-80.868351118408526, 35.54939407862161, 0.0],
              [-80.868014785095696, 35.549439878390238, 0.0],
              [-80.86767851881585, 35.549806277750726, 0.0],
              [-80.867342118299021, 35.550447678326528, 0.0],
              [-80.867089917946245, 35.550470611666533, 0.0],
              [-80.867033918206744, 35.550378878626255, 0.0],
              [-80.867314184748651, 35.54932527774389, 0.0],
              [-80.867566518489355, 35.548936011606003, 0.0],
              [-80.867454318464326, 35.548844477858239, 0.0],
              [-80.867033985009272, 35.548775611449948, 0.0],
              [-80.86641758497008, 35.548386277973627, 0.0],
              [-80.866277517849028, 35.548111478405787, 0.0],
              [-80.866333517653956, 35.547996877986911, 0.0],
              [-80.866641718347225, 35.547859611915648, 0.0],
              [-80.867090117757371, 35.548065678073506, 0.0],
              [-80.867706718094183, 35.54822607777016, 0.0],
              [-80.86815498503357, 35.548065811746234, 0.0],
              [-80.868743518450316, 35.548340677827092, 0.0],
              [-80.869331918540823, 35.548478077982473, 0.0],
              [-80.872582584647063, 35.548203278197583, 0.0],
              [-80.873423384924251, 35.548317811300173, 0.0],
              [-80.87364758520846, 35.548180411012147, 0.0],
              [-80.873759718713316, 35.547722278276389, 0.0],
              [-80.87373158434589, 35.547126878628298, 0.0],
              [-80.873535518033648, 35.54669167844439, 0.0],
              [-80.87375958471597, 35.546645811294113, 0.0],
              [-80.874236118752776, 35.547012278288605, 0.0],
              [-80.874404184872333, 35.547333011033373, 0.0],
              [-80.874460185307257, 35.54797421142576, 0.0],
              [-80.874796518472365, 35.548386477860305, 0.0],
              [-80.875076785040903, 35.548661278325113, 0.0],
              [-80.876169718480654, 35.549027678475909, 0.0],
              [-80.876477985301136, 35.548661278012432, 0.0],
              [-80.87673018529199, 35.548661211602159, 0.0],
              [-80.876786184388749, 35.548958877911844, 0.0],
              [-80.87703838462545, 35.549119278221674, 0.0],
              [-80.877514784943941, 35.549165078186277, 0.0],
              [-80.878355518755043, 35.548913077724507, 0.0],
              [-80.878663784685358, 35.548294811802151, 0.0],
              [-80.878999985144603, 35.548180278348234, 0.0],
              [-80.879560517687509, 35.548546678363032, 0.0],
              [-80.88037318459952, 35.548592411334759, 0.0],
              [-80.880877584718206, 35.54875261187123, 0.0],
              [-80.881325918821659, 35.548775478249034, 0.0],
              [-80.881606184776572, 35.548432011708918, 0.0],
              [-80.881662185007741, 35.547836477880999, 0.0],
              [-80.881802318584022, 35.547561678627957, 0.0],
              [-80.881858185361779, 35.546783011488166, 0.0],
              [-80.882222517796919, 35.546279011274351, 0.0],
              [-80.882306518180599, 35.5454546119472, 0.0],
              [-80.88241871862644, 35.545363011824477, 0.0],
              [-80.883147117724803, 35.54527127861104, 0.0],
              [-80.883371318583812, 35.54517967846995, 0.0],
              [-80.883511518501365, 35.544950611585328, 0.0],
              [-80.883539317755478, 35.544286411599174, 0.0],
              [-80.882978784314687, 35.543645078618894, 0.0],
              [-80.881577785498365, 35.543187211827551, 0.0],
              [-80.87992451790106, 35.543095678416435, 0.0],
              [-80.879868384927192, 35.543004078348751, 0.0],
              [-80.879448118739603, 35.542889678201995, 0.0],
              [-80.878159118783842, 35.54300421189761, 0.0],
              [-80.87815918469768, 35.542889678251782, 0.0],
              [-80.878467385114448, 35.54268347788615, 0.0],
              [-80.878775517836431, 35.542294211592989, 0.0],
              [-80.879251918524645, 35.542202611582248, 0.0],
              [-80.879616185420403, 35.542317077891092, 0.0],
              [-80.881269518382368, 35.542110811040736, 0.0],
              [-80.881465518050163, 35.542156611619141, 0.0],
              [-80.882782584720786, 35.542133677819933, 0.0],
              [-80.88295071838418, 35.541858811558669, 0.0],
              [-80.883230918056825, 35.541767078286533, 0.0],
              [-80.883623185178109, 35.541973278401741, 0.0],
              [-80.884519717800217, 35.542156411674043, 0.0],
              [-80.885136318055856, 35.542179078575153, 0.0],
              [-80.88606091840154, 35.541789677959464, 0.0],
              [-80.886032918569555, 35.541446211374051, 0.0],
              [-80.885780584583145, 35.541079811404657, 0.0],
              [-80.8857525186163, 35.540553011861896, 0.0],
              [-80.885892584418841, 35.540461411674315, 0.0],
              [-80.886873317839971, 35.540278078438568, 0.0],
              [-80.886957384802599, 35.540186411656272, 0.0],
              [-80.886648984786873, 35.539705478380704, 0.0],
              [-80.88664891822107, 35.538995478144955, 0.0],
              [-80.88701318434984, 35.538858078323692, 0.0],
              [-80.887321384525237, 35.539018278032096, 0.0],
              [-80.887349518039727, 35.539110011021705, 0.0],
              [-80.888022118015783, 35.539751078225287, 0.0],
              [-80.888302184913613, 35.539659478139264, 0.0],
              [-80.88833011802798, 35.538857878483981, 0.0],
              [-80.888554185360533, 35.538651811362257, 0.0],
              [-80.888806385061329, 35.538102011159637, 0.0],
              [-80.889730918479188, 35.537598077828427, 0.0],
              [-80.889814985285, 35.537529411036147, 0.0],
              [-80.889814784808962, 35.537002611081292, 0.0],
              [-80.888834118649399, 35.536338478062632, 0.0],
              [-80.888413784430554, 35.536269878565648, 0.0],
              [-80.887685385424064, 35.537117278057224, 0.0],
              [-80.88740518503225, 35.537140211083141, 0.0],
              [-80.887349117779337, 35.536567678422429, 0.0],
              [-80.886900784725739, 35.536407411588321, 0.0],
              [-80.886480518587661, 35.536544878112878, 0.0],
              [-80.886396518346345, 35.536705211556075, 0.0],
              [-80.886060317687623, 35.536842811257301, 0.0],
              [-80.885527917870718, 35.536705411476255, 0.0],
              [-80.88491151825842, 35.536682478637317, 0.0],
              [-80.884042917876471, 35.537278078136879, 0.0],
              [-80.883594585305701, 35.537369678616272, 0.0],
              [-80.883342385184648, 35.537163611253213, 0.0],
              [-80.882978118625289, 35.536407811992511, 0.0],
              [-80.882501717709218, 35.536018611310006, 0.0],
              [-80.881689318505863, 35.535972811281155, 0.0],
              [-80.881268985181606, 35.53578961183009, 0.0],
              [-80.880960718686865, 35.535949878197904, 0.0],
              [-80.8807647184031, 35.536201878658389, 0.0],
              [-80.880624585479964, 35.537347011414319, 0.0],
              [-80.880428517666672, 35.537507278206093, 0.0],
              [-80.880008184836271, 35.537599011966627, 0.0],
              [-80.879699984923363, 35.537438678501474, 0.0],
              [-80.879615918572469, 35.536431011140522, 0.0],
              [-80.87944778494689, 35.536041678171067, 0.0],
              [-80.87922358460952, 35.535927211925461, 0.0],
              [-80.878971518762029, 35.53730127834568, 0.0],
              [-80.878775318374196, 35.537392877680105, 0.0],
              [-80.878607184809624, 35.537278411155739, 0.0],
              [-80.878579184465622, 35.537095211553719, 0.0],
              [-80.878298984767611, 35.536728811051013, 0.0],
              [-80.878186918189229, 35.536339411413053, 0.0],
              [-80.877906584755806, 35.536110411791512, 0.0],
              [-80.877682518791602, 35.536156211213275, 0.0],
              [-80.877346385452697, 35.536545611503385, 0.0],
              [-80.87675791810085, 35.536591411032113, 0.0],
              [-80.876533718093015, 35.536683011244229, 0.0],
              [-80.876253518585258, 35.537026478216475, 0.0],
              [-80.876113518815401, 35.537484678411069, 0.0],
              [-80.875160784404756, 35.538446611287213, 0.0],
              [-80.87490858494084, 35.538584011328005, 0.0],
              [-80.874796518543661, 35.538309211699087, 0.0],
              [-80.875160784342114, 35.537370211439764, 0.0],
              [-80.875525118250962, 35.536774677684377, 0.0],
              [-80.875440985238313, 35.535148611400601, 0.0],
              [-80.875188785472474, 35.534828011390431, 0.0],
              [-80.874796518780244, 35.534690477950186, 0.0],
              [-80.873843918706953, 35.53480501128977, 0.0],
              [-80.872975318502753, 35.53462181171669, 0.0],
              [-80.871882718760773, 35.534026411745764, 0.0],
              [-80.871546384820363, 35.534026278560887, 0.0],
              [-80.870845985115309, 35.5344844116308, 0.0],
              [-80.870509717799038, 35.534621811249764, 0.0],
              [-80.869500984592634, 35.534690478326695, 0.0],
              [-80.869304984786808, 35.534919478537496, 0.0],
              [-80.869556985122131, 35.535308811493373, 0.0],
              [-80.869893317687797, 35.536064611653316, 0.0],
              [-80.869500984741819, 35.53606461169953, 0.0],
              [-80.869024718474407, 35.535858411359605, 0.0],
              [-80.868632385171679, 35.535858611881658, 0.0],
              [-80.868071918354602, 35.536316478239492, 0.0],
              [-80.867287384681106, 35.536591277870663, 0.0],
              [-80.867035185510673, 35.536820277886626, 0.0],
              [-80.866923118250455, 35.537163877686019, 0.0],
              [-80.86689491876939, 35.53851521113301, 0.0],
              [-80.866754784697306, 35.538606811934415, 0.0],
              [-80.866614784623295, 35.538561011171957, 0.0],
              [-80.866278517695491, 35.538080011266629, 0.0],
              [-80.865830184476152, 35.538011278628098, 0.0],
              [-80.865606118330817, 35.537782278604297, 0.0],
              [-80.865381984934359, 35.537713478283756, 0.0],
              [-80.865157785017445, 35.537988278027655, 0.0],
              [-80.864933585070247, 35.538102811178355, 0.0],
              [-80.864373185407572, 35.538079878033578, 0.0],
              [-80.863476584771561, 35.538354611657518, 0.0],
              [-80.861795317752325, 35.53844607797361, 0.0],
              [-80.861823384874853, 35.537896478002175, 0.0],
              [-80.862692118655445, 35.537575811960963, 0.0],
              [-80.863728785441054, 35.537507211521223, 0.0],
              [-80.863952985231123, 35.537347011055289, 0.0],
              [-80.864177185199992, 35.536934678031898, 0.0],
              [-80.864429385042129, 35.5366828113198, 0.0],
              [-80.864457384696166, 35.536408011943486, 0.0],
              [-80.864289385213624, 35.536178877862923, 0.0],
              [-80.864233385392069, 35.535904011341245, 0.0],
              [-80.864457517971772, 35.535835411399596, 0.0],
              [-80.865017917752098, 35.536018611146318, 0.0],
              [-80.86521398435481, 35.536018611174732, 0.0],
              [-80.865578385299003, 35.535652211660093, 0.0],
              [-80.86622278492716, 35.535537811313738, 0.0],
              [-80.86667111817421, 35.535240011593395, 0.0],
              [-80.867483718393444, 35.534942278330213, 0.0],
              [-80.867988118450356, 35.534415611671143, 0.0],
              [-80.868184185458219, 35.53391167831191, 0.0],
              [-80.867988185070573, 35.533636878125257, 0.0],
              [-80.868016318057357, 35.532926878673926, 0.0],
              [-80.868268384961908, 35.532881078630197, 0.0],
              [-80.868604584376683, 35.533247611904216, 0.0],
              [-80.86905291878135, 35.53322467803121, 0.0],
              [-80.869529184517006, 35.53294987843114, 0.0],
              [-80.87017358532006, 35.532766611255681, 0.0],
              [-80.870257717791233, 35.532423077879891, 0.0],
              [-80.871014185208793, 35.532308677779845, 0.0],
              [-80.871098184347588, 35.531759011216124, 0.0],
              [-80.871266384811946, 35.531713211455916, 0.0],
              [-80.871462517908796, 35.531850611751359, 0.0],
              [-80.871910785196519, 35.532446011761984, 0.0],
              [-80.872302984865001, 35.532652211329129, 0.0],
              [-80.873619784321093, 35.532698077984264, 0.0],
              [-80.873759985393235, 35.532491811644533, 0.0],
              [-80.874096185426779, 35.53242307848226, 0.0],
              [-80.874600385384525, 35.532606278583977, 0.0],
              [-80.874796584780526, 35.532537611900764, 0.0],
              [-80.87552498460839, 35.531896411091765, 0.0],
              [-80.875468984752231, 35.530018211388558, 0.0],
              [-80.875777184431655, 35.529972477723852, 0.0],
              [-80.876281518237292, 35.530613678159128, 0.0],
              [-80.876617718466122, 35.531300811230921, 0.0],
              [-80.876813784697134, 35.532056678477382, 0.0],
              [-80.877149985027273, 35.532400211271693, 0.0],
              [-80.877430318659705, 35.532377277902569, 0.0],
              [-80.877626385062811, 35.532148277754068, 0.0],
              [-80.877654384865892, 35.531575611302955, 0.0],
              [-80.878382784810441, 35.531415278060493, 0.0],
              [-80.878522918745631, 35.531232011054335, 0.0],
              [-80.878466918358143, 35.53065947837522, 0.0],
              [-80.87829871797166, 35.530132811716186, 0.0],
              [-80.878606984916985, 35.530064011455359, 0.0],
              [-80.878859118359898, 35.530384611677462, 0.0],
              [-80.879083184977759, 35.531025878502973, 0.0],
              [-80.879279384433787, 35.531232078289243, 0.0],
              [-80.880035918558249, 35.531277811461159, 0.0],
              [-80.88037211873106, 35.53180447824996, 0.0],
              [-80.88098851842031, 35.532193877804353, 0.0],
              [-80.881324717960283, 35.532537411164604, 0.0],
              [-80.882613518495646, 35.532720478321579, 0.0],
              [-80.883706118268364, 35.532537211618809, 0.0],
              [-80.884182518190329, 35.532857811806949, 0.0],
              [-80.884406717973064, 35.532857811894687, 0.0],
              [-80.884462584934269, 35.532056077786606, 0.0],
              [-80.884854784842815, 35.531712611472742, 0.0],
              [-80.884966785431317, 35.531437678152422, 0.0],
              [-80.884658585493113, 35.531231611481175, 0.0],
              [-80.884630518047345, 35.53091101181225, 0.0],
              [-80.884098184835892, 35.530750678020851, 0.0],
              [-80.884098184695901, 35.530590411984655, 0.0],
              [-80.884658384297865, 35.529651278227405, 0.0],
              [-80.884574384492851, 35.529284878210184, 0.0],
              [-80.884406185203346, 35.529078811667425, 0.0],
              [-80.883649718645955, 35.528597811644126, 0.0],
              [-80.883649717918516, 35.528460411525053, 0.0],
              [-80.884069917920698, 35.528300078130748, 0.0],
              [-80.884069918817573, 35.528116877913646, 0.0],
              [-80.883649584558384, 35.527796211759728, 0.0],
              [-80.883033185099293, 35.527521411075355, 0.0],
              [-80.882921184415281, 35.527315411278217, 0.0],
              [-80.882977184991859, 35.527246678014784, 0.0],
              [-80.884433984504057, 35.527315211855161, 0.0],
              [-80.884798185207003, 35.52761301142214, 0.0],
              [-80.886114984592609, 35.527841878638199, 0.0],
              [-80.886479318799829, 35.527727278098972, 0.0],
              [-80.886703318458274, 35.527383678048174, 0.0],
              [-80.886674984380647, 35.525253811499127, 0.0],
              [-80.887123318216908, 35.525162011767819, 0.0],
              [-80.887375385184868, 35.525643078452603, 0.0],
              [-80.88785178477228, 35.526123878445034, 0.0],
              [-80.888019984417951, 35.52667361139742, 0.0],
              [-80.888636385041352, 35.52703987864281, 0.0],
              [-80.888720518044536, 35.527223077772653, 0.0],
              [-80.889168718210982, 35.527337611517652, 0.0],
              [-80.889364784414738, 35.527108611162618, 0.0],
              [-80.889000585249306, 35.526719278065976, 0.0],
              [-80.888944384519903, 35.526192411594288, 0.0],
              [-80.889056385153992, 35.526055011300414, 0.0],
              [-80.889644718102318, 35.525803011339413, 0.0],
              [-80.889840718725509, 35.525115878496159, 0.0],
              [-80.889560517736712, 35.524841078356737, 0.0],
              [-80.889364384395662, 35.524726678447252, 0.0],
              [-80.888720117651445, 35.524818278412894, 0.0],
              [-80.888579917867972, 35.524772611674315, 0.0],
              [-80.888579918546881, 35.524543477803242, 0.0],
              [-80.888775985282251, 35.524154078506264, 0.0],
              [-80.888607784388597, 35.523902211483872, 0.0],
              [-80.888579785487821, 35.523673278252964, 0.0],
              [-80.888803918789463, 35.523375478643793, 0.0],
              [-80.889111918438033, 35.523146411425664, 0.0],
              [-80.889644185034911, 35.523077478469261, 0.0],
              [-80.889896385110632, 35.52316921173307, 0.0],
              [-80.890456585296505, 35.523214878075592, 0.0],
              [-80.891044984806754, 35.523100211038184, 0.0],
              [-80.891493118520273, 35.522367278357095, 0.0],
              [-80.891801118052101, 35.521519878312446, 0.0],
              [-80.892613384507086, 35.521336478522706, 0.0],
              [-80.892921385441639, 35.520924211229641, 0.0],
              [-80.893117385139661, 35.52035161118922, 0.0],
              [-80.893341518427803, 35.520260011609842, 0.0],
              [-80.893733917976164, 35.521061478132694, 0.0],
              [-80.894070318788707, 35.52204627776397, 0.0],
              [-80.894210384517919, 35.52220647778482, 0.0],
              [-80.894378518586848, 35.522183611163797, 0.0],
              [-80.894546518782875, 35.521725478100514, 0.0],
              [-80.894630317821154, 35.521038411374732, 0.0],
              [-80.894798384387443, 35.520901011555395, 0.0],
              [-80.894966318587151, 35.520580278433165, 0.0],
              [-80.895246518510575, 35.520511478363922, 0.0],
              [-80.895750917694002, 35.521175612015512, 0.0],
              [-80.897207984535996, 35.522435078379644, 0.0],
              [-80.897712317914269, 35.522549477920506, 0.0],
              [-80.897712318200647, 35.522366277957339, 0.0],
              [-80.897515984364333, 35.522137278645737, 0.0],
              [-80.897487717712991, 35.520969078578112, 0.0],
              [-80.897739785235686, 35.520740011411839, 0.0],
              [-80.898047918570498, 35.520602612011658, 0.0],
              [-80.898888317832871, 35.520510811096138, 0.0],
              [-80.899140384828883, 35.520556611623128, 0.0],
              [-80.899364318116824, 35.520075611333887, 0.0],
              [-80.898243784745873, 35.519961278609948, 0.0],
              [-80.89782351857049, 35.51977807815652, 0.0],
              [-80.897011118390012, 35.519572211690296, 0.0],
              [-80.896954984543427, 35.519434811775461, 0.0],
              [-80.897038984412745, 35.51929727860086, 0.0],
              [-80.897851317858922, 35.518976611965023, 0.0],
              [-80.897963318806418, 35.518862011682842, 0.0],
              [-80.897991318764142, 35.518564211065446, 0.0],
              [-80.897430918650358, 35.518083411744804, 0.0],
              [-80.89670258427644, 35.518129278119524, 0.0],
              [-80.896562518691482, 35.518037678146101, 0.0],
              [-80.896674384536041, 35.517717078063868, 0.0],
              [-80.897094584667485, 35.517488011778383, 0.0],
              [-80.898523117758742, 35.517006678462231, 0.0],
              [-80.900371784839052, 35.516868878042722, 0.0],
              [-80.900623985343472, 35.516754277905804, 0.0],
              [-80.900735985102401, 35.516594011736665, 0.0],
              [-80.900679584709366, 35.515586277969007, 0.0],
              [-80.900203318500814, 35.51526567842005, 0.0],
              [-80.899727117847277, 35.515334478040032, 0.0],
              [-80.899475118006066, 35.515792611748978, 0.0],
              [-80.897934584318875, 35.516251078442522, 0.0],
              [-80.897598184536221, 35.515266277866075, 0.0],
              [-80.897205984315519, 35.514899878541598, 0.0],
              [-80.896813718285443, 35.514739678605189, 0.0],
              [-80.896309517823667, 35.51469401128319, 0.0],
              [-80.895861318472257, 35.514877277991303, 0.0],
              [-80.895497384530231, 35.515495678543829, 0.0],
              [-80.895077185008716, 35.515724878622173, 0.0],
              [-80.8948813181904, 35.516205811163374, 0.0],
              [-80.894489185140301, 35.516412011788006, 0.0],
              [-80.89440518443925, 35.516686877999177, 0.0],
              [-80.894489518570168, 35.517694678562101, 0.0],
              [-80.894433384573418, 35.517786211719248, 0.0],
              [-80.893649118111625, 35.518015411285496, 0.0],
              [-80.893200984318256, 35.518359011383552, 0.0],
              [-80.892556718242787, 35.518519411492143, 0.0],
              [-80.892332718338523, 35.51847361187783, 0.0],
              [-80.892220584283166, 35.518313411482325, 0.0],
              [-80.892556717692074, 35.518038477916654, 0.0],
              [-80.892584584583076, 35.517855278666296, 0.0],
              [-80.892332518744524, 35.517740677969513, 0.0],
              [-80.892248384840457, 35.517191077997431, 0.0],
              [-80.892024184452183, 35.517030811447192, 0.0],
              [-80.891744117977652, 35.516985078404346, 0.0],
              [-80.891239918435474, 35.517443211245457, 0.0],
              [-80.891183984635646, 35.517832611455447, 0.0],
              [-80.891380118367195, 35.51829047805132, 0.0],
              [-80.891100117954352, 35.518565478207677, 0.0],
              [-80.890988118677413, 35.519138078117798, 0.0],
              [-80.890764118385349, 35.519252611381908, 0.0],
              [-80.890035585291713, 35.518565677739296, 0.0],
              [-80.889503317854292, 35.518359611410489, 0.0],
              [-80.888550718249576, 35.517558078193581, 0.0],
              [-80.888354718381862, 35.517603878210117, 0.0],
              [-80.88832671803091, 35.517855811793915, 0.0],
              [-80.888102717712954, 35.518039078473457, 0.0],
              [-80.887934584936218, 35.518520078603117, 0.0],
              [-80.887710585228092, 35.518611811190837, 0.0],
              [-80.887458385472627, 35.518359811841329, 0.0],
              [-80.887010118128217, 35.518199611329955, 0.0],
              [-80.886505917970567, 35.51813087817083, 0.0],
              [-80.886169784494655, 35.518245411629394, 0.0],
              [-80.88569358519976, 35.518703611592876, 0.0],
              [-80.885357517838486, 35.519436611187274, 0.0],
              [-80.885217584972494, 35.520032011456379, 0.0],
              [-80.884965517627549, 35.520490011446725, 0.0],
              [-80.88468538491226, 35.52062747844171, 0.0],
              [-80.88460131849898, 35.520581677832674, 0.0],
              [-80.884601318344266, 35.520329677879538, 0.0],
              [-80.884713384876505, 35.520100678086735, 0.0],
              [-80.884741318385565, 35.519551077769371, 0.0],
              [-80.884405118777607, 35.519253411738667, 0.0],
              [-80.884517118173093, 35.51897847775458, 0.0],
              [-80.884601118051407, 35.518474678474874, 0.0],
              [-80.884797118082417, 35.517993677881208, 0.0],
              [-80.884852984764677, 35.517214878489852, 0.0],
              [-80.884684985185615, 35.517054611148573, 0.0],
              [-80.884656918066156, 35.516848612026642, 0.0],
              [-80.884796918600685, 35.51627587858944, 0.0],
              [-80.884096584433451, 35.516047011890471, 0.0],
              [-80.883704317715896, 35.515749278630913, 0.0],
              [-80.883228118305666, 35.515680611710302, 0.0],
              [-80.882835985013074, 35.515841011623756, 0.0],
              [-80.882387918515661, 35.516276078022464, 0.0],
              [-80.881491385392536, 35.516459477712715, 0.0],
              [-80.881043317693468, 35.516711411337504, 0.0],
              [-80.880427118528317, 35.517833678514307, 0.0],
              [-80.88014698476303, 35.517971077939229, 0.0],
              [-80.879922784403917, 35.518246011523139, 0.0],
              [-80.879894918532159, 35.518726878098981, 0.0],
              [-80.880034985389116, 35.518978877832041, 0.0],
              [-80.880847384392965, 35.519665878338635, 0.0],
              [-80.880931518342521, 35.519986478214676, 0.0],
              [-80.880735384357806, 35.52016981123969, 0.0],
              [-80.880735384987858, 35.520398811789725, 0.0],
              [-80.880819517896683, 35.520467478050897, 0.0],
              [-80.880931584945387, 35.520971277756992, 0.0],
              [-80.880595385373979, 35.521063011323847, 0.0],
              [-80.880371317713951, 35.520971411337811, 0.0],
              [-80.879754917911598, 35.52092561178754, 0.0],
              [-80.879390784899613, 35.520559211109784, 0.0],
              [-80.879138584426059, 35.51980341157342, 0.0],
              [-80.878858385335178, 35.519666011184583, 0.0],
              [-80.877906117699382, 35.519643078497452, 0.0],
              [-80.877401784389633, 35.519734811695621, 0.0],
              [-80.876673517895512, 35.519345411742059, 0.0],
              [-80.876141317669536, 35.519322611934754, 0.0],
              [-80.875973185182772, 35.519185211729777, 0.0],
              [-80.875833118098143, 35.518841611294036, 0.0],
              [-80.875608984400543, 35.518566811219067, 0.0],
              [-80.875384917942895, 35.518475077824583, 0.0],
              [-80.875160784495677, 35.518589811996044, 0.0],
              [-80.875160785148253, 35.51916227794856, 0.0],
              [-80.875693117849792, 35.520124211922983, 0.0],
              [-80.876449518169949, 35.520765478466004, 0.0],
              [-80.876673584578782, 35.521406678544416, 0.0],
              [-80.876477518530805, 35.521498411148038, 0.0],
              [-80.876253317859479, 35.521429611481317, 0.0],
              [-80.875664985484548, 35.520925811705297, 0.0],
              [-80.875048785120114, 35.521040278020863, 0.0],
              [-80.874796584804088, 35.520925811059264, 0.0],
              [-80.874628518055303, 35.520880011080642, 0.0],
              [-80.874376517694714, 35.52097161128782, 0.0],
              [-80.874012184467176, 35.521361011674522, 0.0],
              [-80.873676118210966, 35.521475478350162, 0.0],
              [-80.873535917751667, 35.52138387796726, 0.0],
              [-80.8735359844393, 35.520765478218387, 0.0],
              [-80.873059784957036, 35.520467811429938, 0.0],
              [-80.872919718579823, 35.520192877861099, 0.0],
              [-80.872191318350218, 35.52010141169248, 0.0],
              [-80.871883184601302, 35.520192877719722, 0.0],
              [-80.871771118513365, 35.520376211097378, 0.0],
              [-80.871743118820305, 35.520788411841508, 0.0],
              [-80.871490985276779, 35.520880012016491, 0.0],
              [-80.87115478545681, 35.521200677946311, 0.0],
              [-80.870762585103904, 35.521292211103798, 0.0],
              [-80.870342518620646, 35.521246411092889, 0.0],
              [-80.869726118091819, 35.521612878150037, 0.0],
              [-80.869361785456562, 35.522093811814202, 0.0],
              [-80.868661384621774, 35.522780811625374, 0.0],
              [-80.868661317663936, 35.524773411674147, 0.0],
              [-80.868437184985922, 35.524842211511576, 0.0],
              [-80.868072985368329, 35.524155078458996, 0.0],
              [-80.867736917942693, 35.523880211438346, 0.0],
              [-80.86723251788348, 35.523948877952087, 0.0],
              [-80.866504184412719, 35.523788478550294, 0.0],
              [-80.866504185395812, 35.523582411817749, 0.0],
              [-80.867372717781521, 35.523353411180629, 0.0],
              [-80.867372784699185, 35.523170078085876, 0.0],
              [-80.867652917718488, 35.52291821147908, 0.0],
              [-80.867680918631066, 35.522712211245661, 0.0],
              [-80.868129185330275, 35.5223000111624, 0.0],
              [-80.867933118257923, 35.522002211408235, 0.0],
              [-80.868157317994729, 35.521406678405675, 0.0],
              [-80.867989317964188, 35.521177678167156, 0.0],
              [-80.868045384660746, 35.520353078677587, 0.0],
              [-80.868353518833089, 35.519940878360089, 0.0],
              [-80.868465584774611, 35.519093478108978, 0.0],
              [-80.869558184367207, 35.518154477794035, 0.0],
              [-80.86997851852982, 35.517169677914623, 0.0],
              [-80.870034517991442, 35.516757478221606, 0.0],
              [-80.869894517659446, 35.516505478168774, 0.0],
              [-80.869222318838482, 35.515703877858627, 0.0],
              [-80.869866517730557, 35.515474811478619, 0.0],
              [-80.870062584931304, 35.515337411563884, 0.0],
              [-80.870090718753701, 35.515154211054622, 0.0],
              [-80.86981058547704, 35.514512877717365, 0.0],
              [-80.869390384976711, 35.514169411453494, 0.0],
              [-80.86885818446585, 35.514009011290447, 0.0],
              [-80.86843798462418, 35.513986078188381, 0.0],
              [-80.867681785026576, 35.514283811462064, 0.0],
              [-80.867345518345502, 35.514810611768873, 0.0],
              [-80.867345384530907, 35.515406011567094, 0.0],
              [-80.867457518661055, 35.515520611784297, 0.0],
              [-80.867457518017346, 35.515772478020914, 0.0],
              [-80.8667849843943, 35.517719278312036, 0.0],
              [-80.86633671871877, 35.518039878092793, 0.0],
              [-80.866224718255253, 35.518223011898492, 0.0],
              [-80.865944518439875, 35.518314677832613, 0.0],
              [-80.865916584794618, 35.517650478636391, 0.0],
              [-80.866028717899525, 35.516826078640911, 0.0],
              [-80.866870185189995, 35.507092211491212, 0.0],
              [-80.867234318199436, 35.506634212013061, 0.0],
              [-80.867262318048262, 35.506496811071301, 0.0],
              [-80.867934584647145, 35.506244877907505, 0.0],
              [-80.867934518019013, 35.506634278437787, 0.0],
              [-80.86776638520854, 35.507046477756965, 0.0],
              [-80.867738385178782, 35.507527411959408, 0.0],
              [-80.867962518102871, 35.507802211638896, 0.0],
              [-80.86838271828114, 35.507939678349238, 0.0],
              [-80.86950298518309, 35.507894011512057, 0.0],
              [-80.87051131860045, 35.508329078672354, 0.0],
              [-80.871155518179052, 35.50842081143994, 0.0],
              [-80.872275985262476, 35.508100211365544, 0.0],
              [-80.872500118484737, 35.507871011489883, 0.0],
              [-80.872528117806752, 35.507435877966287, 0.0],
              [-80.872443985499984, 35.507321411782776, 0.0],
              [-80.871463717905385, 35.506611411974006, 0.0],
              [-80.871379718629214, 35.506290811094232, 0.0],
              [-80.87154778441311, 35.505970078549169, 0.0],
              [-80.871493584930136, 35.505351811801965, 0.0],
              [-80.87157811774577, 35.505168611131467, 0.0],
              [-80.872192318372115, 35.504641811536544, 0.0],
              [-80.872275718077759, 35.504481411958643, 0.0],
              [-80.871911718696609, 35.504275278353816, 0.0],
              [-80.870595318144566, 35.504435611669152, 0.0],
              [-80.870651384593415, 35.504252277998653, 0.0],
              [-80.871155584695927, 35.50381721121272, 0.0],
              [-80.871295384520323, 35.503588211991726, 0.0],
              [-80.871155385417026, 35.503450811574936, 0.0],
              [-80.871155517885512, 35.503244678454728, 0.0],
              [-80.872107585067354, 35.502763811413708, 0.0],
              [-80.872135585016551, 35.50258047858825, 0.0],
              [-80.871799518239854, 35.502053678284518, 0.0],
              [-80.871883518560693, 35.501297878248813, 0.0],
              [-80.871575384461963, 35.501023078228606, 0.0],
              [-80.871183318052502, 35.501091678478922, 0.0],
              [-80.870511118074049, 35.50164147777862, 0.0],
              [-80.870259118672735, 35.501962077763515, 0.0],
              [-80.869978985052285, 35.502053678297209, 0.0],
              [-80.869530917824846, 35.501572678665596, 0.0],
              [-80.86953091775942, 35.501389477766047, 0.0],
              [-80.870146985195049, 35.500977211441914, 0.0],
              [-80.870174985445317, 35.500839811501073, 0.0],
              [-80.869530918033973, 35.500335878043813, 0.0],
              [-80.869306785251624, 35.50031301140983, 0.0],
              [-80.868802717787219, 35.500519211945921, 0.0],
              [-80.868578585461918, 35.500771078217795, 0.0],
              [-80.868326585222022, 35.500839811958578, 0.0],
              [-80.868214518625464, 35.500748078443458, 0.0],
              [-80.868214518784598, 35.500473278087696, 0.0],
              [-80.868382584481083, 35.500139211102223, 0.0],
              [-80.869810985168385, 35.497596677925131, 0.0],
              [-80.870118985442915, 35.497207411531726, 0.0],
              [-80.87123911813454, 35.49727621177616, 0.0],
              [-80.871435185210856, 35.49736767853387, 0.0],
              [-80.87157518542422, 35.497894478269686, 0.0],
              [-80.872163318588633, 35.498238078257856, 0.0],
              [-80.872835385113746, 35.498764811966034, 0.0],
              [-80.873115384415641, 35.498879412002523, 0.0],
              [-80.873423384601793, 35.498787811930541, 0.0],
              [-80.873787517651252, 35.498260878633907, 0.0],
              [-80.873843584614747, 35.497802878372795, 0.0],
              [-80.873731585022199, 35.497665477773694, 0.0],
              [-80.873675585395659, 35.497230277792752, 0.0],
              [-80.874095584412913, 35.496726478695898, 0.0],
              [-80.874151518567928, 35.496543212002557, 0.0],
              [-80.873843518640712, 35.496222611245507, 0.0],
              [-80.873843385415043, 35.495924878152024, 0.0],
              [-80.874347584822388, 35.495833278439548, 0.0],
              [-80.874543585482613, 35.495283611553688, 0.0],
              [-80.874795584364662, 35.495169077962302, 0.0],
              [-80.875131585110339, 35.495306478552997, 0.0],
              [-80.874795584876381, 35.496566211599585, 0.0],
              [-80.874515517914915, 35.49700127857492, 0.0],
              [-80.874515585406755, 35.497230278129592, 0.0],
              [-80.874795718751955, 35.49739061135746, 0.0],
              [-80.875075718113507, 35.497322011525704, 0.0],
              [-80.875551717683891, 35.497001278543095, 0.0],
              [-80.875663784299135, 35.497047077801938, 0.0],
              [-80.8757197844226, 35.497505078036589, 0.0],
              [-80.875887918636451, 35.497619611485639, 0.0],
              [-80.877147985068376, 35.497184477783101, 0.0],
              [-80.877315985323577, 35.496932611586459, 0.0],
              [-80.877231917719854, 35.49569587857966, 0.0],
              [-80.877427917754204, 35.495283611757174, 0.0],
              [-80.877651984854936, 35.495214878332973, 0.0],
              [-80.877735918019511, 35.495283611961476, 0.0],
              [-80.877763984600875, 35.495970678496583, 0.0],
              [-80.877959984561556, 35.496176811883679, 0.0],
              [-80.878464185473561, 35.496451611705211, 0.0],
              [-80.878548117801074, 35.496611878414399, 0.0],
              [-80.878436185063023, 35.497321877805447, 0.0],
              [-80.877848117703095, 35.497505078358522, 0.0],
              [-80.877680118741083, 35.497848678137409, 0.0],
              [-80.877344118560117, 35.498100611449338, 0.0],
              [-80.87711998475622, 35.49860441125179, 0.0],
              [-80.876223984767748, 35.498879278261185, 0.0],
              [-80.876279984897707, 35.499154077855749, 0.0],
              [-80.876839984934151, 35.4994060783281, 0.0],
              [-80.877204117696039, 35.499749478708743, 0.0],
              [-80.87768018488093, 35.499818278581841, 0.0],
              [-80.878212184641768, 35.500047278411543, 0.0],
              [-80.878212185043495, 35.50013761110975, 0.0],
              [-80.879081584607292, 35.50013801145748, 0.0],
              [-80.879053718504906, 35.500450411347977, 0.0],
              [-80.87882971851414, 35.500542011158814, 0.0],
              [-80.87801731855437, 35.500610678169615, 0.0],
              [-80.876952917834103, 35.5010688119742, 0.0],
              [-80.876952918059274, 35.501206211864698, 0.0],
              [-80.877737184918587, 35.501320678374931, 0.0],
              [-80.877961185261782, 35.50143521110315, 0.0],
              [-80.877905117644232, 35.501710012016666, 0.0],
              [-80.877625917991381, 35.502030678529444, 0.0],
              [-80.87763711881216, 35.503061411767852, 0.0],
              [-80.877720717868954, 35.503153011734049, 0.0],
              [-80.878113384887769, 35.503015611193128, 0.0],
              [-80.878703184761576, 35.502672011824231, 0.0],
              [-80.879263917751629, 35.502511611747167, 0.0],
              [-80.879516784970278, 35.502168078506493, 0.0],
              [-80.879768984454927, 35.50207647793281, 0.0],
              [-80.879909184717377, 35.50214507774924, 0.0],
              [-80.879848918362669, 35.50283221149278, 0.0],
              [-80.878885584487179, 35.504137811164064, 0.0],
              [-80.878661585427864, 35.504252211380347, 0.0],
              [-80.878745584289263, 35.504458411217705, 0.0],
              [-80.879109718721367, 35.504710278384231, 0.0],
              [-80.879137717901699, 35.505122611779569, 0.0],
              [-80.879361784853558, 35.505397278617274, 0.0],
              [-80.879529984349176, 35.505970012037182, 0.0],
              [-80.879333984574259, 35.506771611229908, 0.0],
              [-80.879614117976672, 35.507069278136221, 0.0],
              [-80.880090184364917, 35.506977677967498, 0.0],
              [-80.881266517916544, 35.50608427797259, 0.0],
              [-80.881462384876627, 35.505420211327838, 0.0],
              [-80.881490384681754, 35.504939211273793, 0.0],
              [-80.881742384689176, 35.504389611818766, 0.0],
              [-80.881518184525589, 35.503427478038255, 0.0],
              [-80.881602184465365, 35.503198611076712, 0.0],
              [-80.881609318621983, 35.502648811857853, 0.0],
              [-80.881499718209525, 35.502534411987753, 0.0],
              [-80.881366585071234, 35.501801477932588, 0.0],
              [-80.880771785287749, 35.501206078442785, 0.0],
              [-80.880708184828535, 35.50083967851036, 0.0],
              [-80.880848317711099, 35.500679278602369, 0.0],
              [-80.880818518069816, 35.500138678322735, 0.0],
              [-80.880760318814097, 35.49938301160001, 0.0],
              [-80.880648385354462, 35.499131011228478, 0.0],
              [-80.880732318078415, 35.498947811799376, 0.0],
              [-80.881263384392327, 35.499405811612071, 0.0],
              [-80.881682984400598, 35.49997841147421, 0.0],
              [-80.881716185063723, 35.500139211841166, 0.0],
              [-80.88179598429069, 35.500679211493896, 0.0],
              [-80.882038585170662, 35.50083947865312, 0.0],
              [-80.882710584443942, 35.500518811388751, 0.0],
              [-80.88263791878677, 35.500139611133349, 0.0],
              [-80.882721717692405, 35.499932478632765, 0.0],
              [-80.883354784624004, 35.500139877869465, 0.0],
              [-80.883999185203422, 35.500472878473545, 0.0],
              [-80.883887517839511, 35.500862211156139, 0.0],
              [-80.883379318550979, 35.501824211471273, 0.0],
              [-80.883450718348769, 35.502648677960494, 0.0],
              [-80.88361871877467, 35.50287781184786, 0.0],
              [-80.884010584887676, 35.503152611439788, 0.0],
              [-80.884962984445806, 35.503450211459338, 0.0],
              [-80.884907118263996, 35.504114477828963, 0.0],
              [-80.884683118239536, 35.504480878418057, 0.0],
              [-80.884767184448989, 35.504824411088066, 0.0],
              [-80.884991184404683, 35.505076277750447, 0.0],
              [-80.885159384370994, 35.505603078385803, 0.0],
              [-80.885635584943984, 35.505946478706434, 0.0],
              [-80.885999584650264, 35.505694611949899, 0.0],
              [-80.886307585049096, 35.505213611108154, 0.0],
              [-80.886279317804622, 35.503473011885816, 0.0],
              [-80.886503318324415, 35.502900278070669, 0.0],
              [-80.886586985315176, 35.502007077881913, 0.0],
              [-80.886783718761677, 35.501732278553703, 0.0],
              [-80.886951384585032, 35.501732278125175, 0.0],
              [-80.887203184480313, 35.501869677933811, 0.0],
              [-80.887651384800932, 35.502465077784279, 0.0],
              [-80.887651718586326, 35.503656011365258, 0.0],
              [-80.888267785258719, 35.503976611931513, 0.0],
              [-80.888435984422301, 35.504549078585541, 0.0],
              [-80.888688117821403, 35.504686478100631, 0.0],
              [-80.888912118536339, 35.504594877945735, 0.0],
              [-80.889108118779077, 35.504091011561286, 0.0],
              [-80.889276118213843, 35.503793211101097, 0.0],
              [-80.889472118029389, 35.503678678259384, 0.0],
              [-80.889640184942834, 35.503816077943966, 0.0],
              [-80.889696318251808, 35.504113877936938, 0.0],
              [-80.889864384423717, 35.504388678441934, 0.0],
              [-80.889920517905267, 35.504892477830545, 0.0],
              [-80.890312718032803, 35.505304611880959, 0.0],
              [-80.890424784618276, 35.506037611339202, 0.0],
              [-80.890676985276457, 35.5063810782476, 0.0],
              [-80.891013118467697, 35.506289411070739, 0.0],
              [-80.891377118473315, 35.505945811793922, 0.0],
              [-80.891545117663142, 35.505602211837342, 0.0],
              [-80.89196518434288, 35.505396011350932, 0.0],
              [-80.891853118362349, 35.504938077754801, 0.0],
              [-80.8910685847694, 35.503678478211675, 0.0],
              [-80.890984384610363, 35.503266278046112, 0.0],
              [-80.891124518538732, 35.503105877944037, 0.0],
              [-80.891320518355414, 35.503105811946462, 0.0],
              [-80.891824718345717, 35.503357678216034, 0.0],
              [-80.892748917849062, 35.503449078225621, 0.0],
              [-80.89311298528736, 35.503563611430558, 0.0],
              [-80.893645318660532, 35.503952878696069, 0.0],
              [-80.895129718696722, 35.504342012030037, 0.0],
              [-80.895577918278391, 35.504548011269279, 0.0],
              [-80.896194118136535, 35.504593677730583, 0.0],
              [-80.896586318186436, 35.504753878338498, 0.0],
              [-80.897118385113615, 35.504708078593374, 0.0],
              [-80.898658718020741, 35.504387211544419, 0.0],
              [-80.898882585365627, 35.504135211898074, 0.0],
              [-80.89891058533621, 35.503974811228552, 0.0],
              [-80.899498584704304, 35.503470812012239, 0.0],
              [-80.899610518352858, 35.502921211075595, 0.0],
              [-80.899946518077726, 35.502577478544694, 0.0],
              [-80.899946385393591, 35.502485878674925, 0.0],
              [-80.899666384273473, 35.502486011276432, 0.0],
              [-80.897706118097815, 35.503310811678844, 0.0],
              [-80.896613918145505, 35.503150811772663, 0.0],
              [-80.896529784489417, 35.503105011574114, 0.0],
              [-80.896557717816933, 35.502784277898307, 0.0],
              [-80.896781717664268, 35.502555211298215, 0.0],
              [-80.896865518026672, 35.502120011604305, 0.0],
              [-80.897229585430381, 35.501799411832039, 0.0],
              [-80.897117385164293, 35.501639011312299, 0.0],
              [-80.8963333183369, 35.501570478716708, 0.0],
              [-80.895885185231791, 35.501318611778693, 0.0],
              [-80.895465185193842, 35.501456077929902, 0.0],
              [-80.895157118570324, 35.501708078635914, 0.0],
              [-80.894428984541548, 35.501983077942342, 0.0],
              [-80.893980785447681, 35.501845811260267, 0.0],
              [-80.89322458454609, 35.501937478041043, 0.0],
              [-80.893056584706613, 35.501685611201204, 0.0],
              [-80.892860518738047, 35.50159401116187, 0.0],
              [-80.892552385374088, 35.501548278238481, 0.0],
              [-80.892552385281775, 35.501410877947315, 0.0],
              [-80.892944517806725, 35.501090211775114, 0.0],
              [-80.893588518633081, 35.500838077789481, 0.0],
              [-80.89358851846184, 35.50054041190603, 0.0],
              [-80.893336385192583, 35.500143878033242, 0.0],
              [-80.893364384632932, 35.499725078136194, 0.0],
              [-80.893168317841543, 35.499587678179353, 0.0],
              [-80.892748118260783, 35.499542011363992, 0.0],
              [-80.891991718151687, 35.499885678483523, 0.0],
              [-80.891767384664277, 35.499771211088223, 0.0],
              [-80.891767518380178, 35.49947341191212, 0.0],
              [-80.892187918685934, 35.499152678363807, 0.0],
              [-80.892243718071001, 35.498465677887857, 0.0],
              [-80.892075717888289, 35.498397011473614, 0.0],
              [-80.891487518391784, 35.498649011239088, 0.0],
              [-80.890590118777226, 35.498740811994303, 0.0],
              [-80.890421718529765, 35.498672078496348, 0.0],
              [-80.89039378445014, 35.498534678086479, 0.0],
              [-80.891067184760686, 35.49750387833123, 0.0],
              [-80.891851185306351, 35.497022878242468, 0.0],
              [-80.89193518512792, 35.496725078034864, 0.0],
              [-80.891627184449945, 35.496564878732727, 0.0],
              [-80.889890118209479, 35.497114811964892, 0.0],
              [-80.889047518104249, 35.497572878059394, 0.0],
              [-80.887080384384873, 35.497641811829197, 0.0],
              [-80.88708091795344, 35.497367011853576, 0.0],
              [-80.888345785455186, 35.497137878509605, 0.0],
              [-80.889104918125369, 35.496748411464864, 0.0],
              [-80.889806585228911, 35.495855078010138, 0.0],
              [-80.889862384321219, 35.495488811908459, 0.0],
              [-80.889358184616, 35.494962077819643, 0.0],
              [-80.889358184547845, 35.494870477865845, 0.0],
              [-80.888713785138137, 35.493977277908606, 0.0],
              [-80.888293717947178, 35.49370247820341, 0.0],
              [-80.886554718427703, 35.493977611767747, 0.0],
              [-80.886188785349944, 35.49425241152251, 0.0],
              [-80.885653184783891, 35.494893678376393, 0.0],
              [-80.884866517692814, 35.495008278386337, 0.0],
              [-80.884838784604213, 35.494870811458419, 0.0],
              [-80.885148584527329, 35.494504411713649, 0.0],
              [-80.886274584786136, 35.493656877967972, 0.0],
              [-80.88635931781792, 35.493405011809102, 0.0],
              [-80.88596651844405, 35.493244678676959, 0.0],
              [-80.884533985108476, 35.493244811267765, 0.0],
              [-80.883972918759085, 35.493061611279806, 0.0],
              [-80.883945318517277, 35.49287841166678, 0.0],
              [-80.886304384534512, 35.492626277874031, 0.0],
              [-80.886893118586713, 35.492420077822935, 0.0],
              [-80.887845118728023, 35.491664277821329, 0.0],
              [-80.888096984536404, 35.491320678136503, 0.0],
              [-80.88815291762343, 35.490610678193292, 0.0],
              [-80.88806891810782, 35.490450411835376, 0.0],
              [-80.887816785068011, 35.49033587846062, 0.0],
              [-80.88764871846729, 35.489557211900063, 0.0],
              [-80.887228518534386, 35.489076278282795, 0.0],
              [-80.887536517913489, 35.488709811605929, 0.0],
              [-80.887620385303194, 35.488114411695236, 0.0],
              [-80.888880518249778, 35.488114211176722, 0.0],
              [-80.88916058545469, 35.487999677749471, 0.0],
              [-80.889720384625377, 35.48747281136535, 0.0],
              [-80.889748317945731, 35.487060611463711, 0.0],
              [-80.889580318431058, 35.486694077810107, 0.0],
              [-80.88865591831896, 35.485457478658617, 0.0],
              [-80.888403784559657, 35.484518478104448, 0.0],
              [-80.888123784690364, 35.483991811796955, 0.0],
              [-80.88837591844235, 35.483900211685167, 0.0],
              [-80.888767918683769, 35.484289478095349, 0.0],
              [-80.8891879846866, 35.484976478321364, 0.0],
              [-80.890196184903644, 35.486144411961682, 0.0],
              [-80.890476317889835, 35.486694077828467, 0.0],
              [-80.890700385292519, 35.48669401111718, 0.0],
              [-80.890952184596955, 35.485938211337, 0.0],
              [-80.891176184994848, 35.485800812072497, 0.0],
              [-80.891288184295533, 35.485846478581081, 0.0],
              [-80.891260317768243, 35.486327478081243, 0.0],
              [-80.891736318460588, 35.486281611563157, 0.0],
              [-80.891792317857536, 35.486350411922452, 0.0],
              [-80.891596385389093, 35.48671681187556, 0.0],
              [-80.891120518172372, 35.487129078333062, 0.0],
              [-80.890952385484425, 35.487381011672042, 0.0],
              [-80.890812584875249, 35.487999412035876, 0.0],
              [-80.890532584640468, 35.488343011229382, 0.0],
              [-80.890532717639886, 35.488480411801142, 0.0],
              [-80.891400784591099, 35.488617678680711, 0.0],
              [-80.891624784927828, 35.488778011525163, 0.0],
              [-80.891596784519436, 35.488869611950989, 0.0],
              [-80.890896918250803, 35.489236211851086, 0.0],
              [-80.890253118532399, 35.489763011297242, 0.0],
              [-80.890057117681962, 35.490289678091258, 0.0],
              [-80.890645385044252, 35.490885211200435, 0.0],
              [-80.891569585379287, 35.490999478521026, 0.0],
              [-80.891933584761276, 35.490862077791462, 0.0],
              [-80.892661384619572, 35.490289478288943, 0.0],
              [-80.892969317908381, 35.489900012035925, 0.0],
              [-80.893389118681398, 35.488960878221462, 0.0],
              [-80.893725117613215, 35.488800678352042, 0.0],
              [-80.893725318713791, 35.489258677990193, 0.0],
              [-80.893641184973816, 35.489350278585341, 0.0],
              [-80.89358558542483, 35.490243478082249, 0.0],
              [-80.893389717948679, 35.490816011150024, 0.0],
              [-80.893333918145032, 35.491571877769729, 0.0],
              [-80.893417985437594, 35.491663478713235, 0.0],
              [-80.893530184471388, 35.492396278449156, 0.0],
              [-80.893222317637409, 35.492923078249049, 0.0],
              [-80.893306584277866, 35.493701811276111, 0.0],
              [-80.893418584532967, 35.493976611703978, 0.0],
              [-80.89442698495192, 35.494503278326519, 0.0],
              [-80.894678918617231, 35.494549011236003, 0.0],
              [-80.894790984805582, 35.494411478406604, 0.0],
              [-80.894874784646248, 35.493610011190633, 0.0],
              [-80.895574917845238, 35.493357877847409, 0.0],
              [-80.895770718392356, 35.492991278712182, 0.0],
              [-80.895938717713264, 35.492876811793067, 0.0],
              [-80.89616271810651, 35.493037078205887, 0.0],
              [-80.8965267851123, 35.493037078213739, 0.0],
              [-80.896834784773191, 35.49289961138561, 0.0],
              [-80.89697478469877, 35.492693478219429, 0.0],
              [-80.897030585191743, 35.491937611573952, 0.0],
              [-80.897366584622944, 35.491846011401847, 0.0],
              [-80.897450718357973, 35.492006211265952, 0.0],
              [-80.897478784665878, 35.492578811947553, 0.0],
              [-80.897590918354283, 35.492624611767845, 0.0],
              [-80.898094918638066, 35.492510011980926, 0.0],
              [-80.89820698524511, 35.492716078278576, 0.0],
              [-80.898011118379486, 35.493151278351654, 0.0],
              [-80.897955318388341, 35.493929878379944, 0.0],
              [-80.898347584475104, 35.494502478227417, 0.0],
              [-80.898571784818017, 35.495052011750936, 0.0],
              [-80.898739918231641, 35.495235211421978, 0.0],
              [-80.898963918222051, 35.495235211789883, 0.0],
              [-80.899075585314336, 35.49475427814, 0.0],
              [-80.89921571878233, 35.494662611659862, 0.0],
              [-80.900055785034951, 35.494525011382422, 0.0],
              [-80.900251785019606, 35.494433411392592, 0.0],
              [-80.900307718053327, 35.494089811352339, 0.0],
              [-80.899803518145035, 35.493860878061383, 0.0],
              [-80.899775384963107, 35.493586077856747, 0.0],
              [-80.90005531860912, 35.493173811386427, 0.0],
              [-80.900138984422227, 35.492738611116899, 0.0],
              [-80.89997091825667, 35.49253247791593, 0.0],
              [-80.899802785186949, 35.492051612033755, 0.0],
              [-80.899578718008527, 35.491868478140368, 0.0],
              [-80.898542385056018, 35.491525077878713, 0.0],
              [-80.89857031831221, 35.491067011924507, 0.0],
              [-80.898766318055962, 35.490952477843109, 0.0],
              [-80.899858384581208, 35.490792012069342, 0.0],
              [-80.899998318143091, 35.490608678301179, 0.0],
              [-80.899830317779347, 35.490288078327701, 0.0],
              [-80.899745585239287, 35.488501811828854, 0.0],
              [-80.899409384684276, 35.488066677803047, 0.0],
              [-80.899325184539791, 35.487425411175742, 0.0],
              [-80.898625118042219, 35.4870590777982, 0.0],
              [-80.898372985051552, 35.486807211638848, 0.0],
              [-80.898288918204614, 35.48657821109942, 0.0],
              [-80.898624984447125, 35.486532411490593, 0.0],
              [-80.899829118445851, 35.486829811391097, 0.0],
              [-80.900053185153297, 35.487127411438934, 0.0],
              [-80.90069731806706, 35.487310478631763, 0.0],
              [-80.900781384288649, 35.487516611307008, 0.0],
              [-80.900641385299366, 35.487654078515611, 0.0],
              [-80.900613518124544, 35.488020478072045, 0.0],
              [-80.900697584494907, 35.488043411157072, 0.0],
              [-80.900977718155474, 35.488593011311771, 0.0],
              [-80.901033984903407, 35.489669411413928, 0.0],
              [-80.901286385318087, 35.490058678723017, 0.0],
              [-80.901370518309818, 35.490493812022265, 0.0],
              [-80.9016507847751, 35.491112078275926, 0.0],
              [-80.902070917821447, 35.491501411387794, 0.0],
              [-80.902462985426411, 35.49157001129759, 0.0],
              [-80.903079117607149, 35.491890477865752, 0.0],
              [-80.903163185074362, 35.492005011515005, 0.0],
              [-80.902323317735949, 35.492554811605032, 0.0],
              [-80.902183318719267, 35.492783878587446, 0.0],
              [-80.902183585402639, 35.493608411556323, 0.0],
              [-80.902267584607699, 35.493654211917814, 0.0],
              [-80.902267784667188, 35.493814477818418, 0.0],
              [-80.902407917768826, 35.494020478383348, 0.0],
              [-80.902687984696982, 35.494203678498096, 0.0],
              [-80.903499984278099, 35.494134877869811, 0.0],
              [-80.903612517762596, 35.494890611251975, 0.0],
              [-80.902828518288374, 35.495463411478333, 0.0],
              [-80.902828717693879, 35.495921411201714, 0.0],
              [-80.903641384294801, 35.496425011538413, 0.0],
              [-80.90459378533599, 35.49669967847548, 0.0],
              [-80.90473371819516, 35.496608011731119, 0.0],
              [-80.904873585413725, 35.496058277760007, 0.0],
              [-80.90548958432295, 35.495943611561913, 0.0],
              [-80.905909584911342, 35.495668811606173, 0.0],
              [-80.905937317706403, 35.495416811698099, 0.0],
              [-80.905488784341117, 35.495027477791304, 0.0],
              [-80.90543271861749, 35.49477567856453, 0.0],
              [-80.905152385365142, 35.494500877764587, 0.0],
              [-80.90509631789061, 35.494317678186114, 0.0],
              [-80.905516318313474, 35.494157277818651, 0.0],
              [-80.906412184981846, 35.493584478548883, 0.0],
              [-80.906440118073633, 35.493309611900635, 0.0],
              [-80.906328117895328, 35.493195211346041, 0.0],
              [-80.906243717626722, 35.49253107833092, 0.0],
              [-80.905627384981699, 35.491867011940414, 0.0],
              [-80.905542984391118, 35.490790611258696, 0.0],
              [-80.904394517809195, 35.489783211125108, 0.0],
              [-80.904394518310994, 35.489622878710605, 0.0],
              [-80.904758385289838, 35.489668611216111, 0.0],
              [-80.905318717693717, 35.490080678195547, 0.0],
              [-80.905934784411585, 35.490034678698898, 0.0],
              [-80.906158985333391, 35.490584411543374, 0.0],
              [-80.906635318397903, 35.49124827805781, 0.0],
              [-80.90711151879249, 35.491568812020802, 0.0],
              [-80.907783784382033, 35.492209878453849, 0.0],
              [-80.908091918687504, 35.492255677960415, 0.0],
              [-80.908315785277523, 35.492164011380517, 0.0],
              [-80.908567718776709, 35.491889011139214, 0.0],
              [-80.908595584616108, 35.491362278089269, 0.0],
              [-80.908483517982916, 35.491202011745841, 0.0],
              [-80.908483317973491, 35.49069807811663, 0.0],
              [-80.908706917905619, 35.490171278238677, 0.0],
              [-80.908958984316854, 35.490148412041741, 0.0],
              [-80.909015118178189, 35.490446078732461, 0.0],
              [-80.909407317745476, 35.490629078445991, 0.0],
              [-80.909547317720282, 35.490812411164661, 0.0],
              [-80.909659718122853, 35.491362011230152, 0.0],
              [-80.909827784956533, 35.491659678516911, 0.0],
              [-80.909996184240512, 35.492278011233722, 0.0],
              [-80.910584518040267, 35.492575611746908, 0.0],
              [-80.910696717934968, 35.492758811438677, 0.0],
              [-80.910612717584968, 35.49294201138494, 0.0],
              [-80.910585184287541, 35.493560412052737, 0.0],
              [-80.910781717918837, 35.494132877908548, 0.0],
              [-80.910473718466491, 35.49438487858454, 0.0],
              [-80.910249917809438, 35.494705611484115, 0.0],
              [-80.910222184791706, 35.495461278706983, 0.0],
              [-80.910474385261793, 35.495759011095551, 0.0],
              [-80.911006584448472, 35.496010811523, 0.0],
              [-80.91111871801813, 35.496148211158662, 0.0],
              [-80.911034784912573, 35.496400078063346, 0.0],
              [-80.910670918092805, 35.496789611595908, 0.0],
              [-80.910671118432845, 35.49727047811556, 0.0],
              [-80.911315584990135, 35.497774211703074, 0.0],
              [-80.912547718223905, 35.497773878191467, 0.0],
              [-80.912883784281235, 35.497957011488872, 0.0],
              [-80.913359917910867, 35.497956811383553, 0.0],
              [-80.914059585172708, 35.496834211203776, 0.0],
              [-80.914535385232298, 35.496444811984361, 0.0],
              [-80.91501138524103, 35.4962384781523, 0.0],
              [-80.915403384940461, 35.496169678159752, 0.0],
              [-80.915627585280149, 35.496261211498727, 0.0],
              [-80.915991785045975, 35.496604611534231, 0.0],
              [-80.916355784256879, 35.496604478400442, 0.0],
              [-80.916691918287228, 35.496489811319215, 0.0],
              [-80.916719717843065, 35.49621501117052, 0.0],
              [-80.916159384667452, 35.495871611555337, 0.0],
              [-80.916047184784119, 35.495345011687832, 0.0],
              [-80.915767118096369, 35.495253477843825, 0.0],
              [-80.914982985106818, 35.495391078227861, 0.0],
              [-80.914562784538219, 35.495047678455592, 0.0],
              [-80.91408658487218, 35.494887478079612, 0.0],
              [-80.912910317856728, 35.494819278015115, 0.0],
              [-80.912490318731926, 35.494888078078198, 0.0],
              [-80.912434318505959, 35.494819478478185, 0.0],
              [-80.912714184795263, 35.494338411479355, 0.0],
              [-80.912574118673817, 35.494109411977966, 0.0],
              [-80.912573785313526, 35.493674278634522, 0.0],
              [-80.912685717943532, 35.493376478269688, 0.0],
              [-80.912713118017692, 35.49278101136359, 0.0],
              [-80.912572985254485, 35.492574878190361, 0.0],
              [-80.912068718521724, 35.492414811436241, 0.0],
              [-80.911816518480009, 35.492208811859122, 0.0],
              [-80.911535984556451, 35.491430077795123, 0.0],
              [-80.911815717779774, 35.491017811747163, 0.0],
              [-80.911927318349527, 35.490239078480393, 0.0],
              [-80.912375385066269, 35.490101478459323, 0.0],
              [-80.913159584655716, 35.490490611744761, 0.0],
              [-80.913243918155715, 35.490765411845032, 0.0],
              [-80.91338391785446, 35.4908798117469, 0.0],
              [-80.913915984513153, 35.490948411346118, 0.0],
              [-80.914420118740267, 35.49081081161308, 0.0],
              [-80.915092117656329, 35.490879278695353, 0.0],
              [-80.915120585352852, 35.491474678189249, 0.0],
              [-80.915428784377454, 35.491657878053246, 0.0],
              [-80.916212784322184, 35.491588878137989, 0.0],
              [-80.917165385147271, 35.491955011328102, 0.0],
              [-80.917389384551086, 35.491977811332639, 0.0],
              [-80.917501317873459, 35.491863278167457, 0.0],
              [-80.917584784481889, 35.491382278473445, 0.0],
              [-80.917388317740233, 35.490947211996165, 0.0],
              [-80.916687918710835, 35.490214611093485, 0.0],
              [-80.916659784457025, 35.48996267806892, 0.0],
              [-80.917023717648775, 35.489687678686373, 0.0],
              [-80.917051584317676, 35.489458678662224, 0.0],
              [-80.916827384307467, 35.489298477989927, 0.0],
              [-80.9161273852081, 35.489367411169709, 0.0],
              [-80.915651117901675, 35.489046878573497, 0.0],
              [-80.914726785014679, 35.488703678200821, 0.0],
              [-80.914446718109062, 35.488474811297245, 0.0],
              [-80.914306518255202, 35.487879278301484, 0.0],
              [-80.914614117826204, 35.486802811868877, 0.0],
              [-80.91450198425656, 35.486711211115214, 0.0],
              [-80.914166118282651, 35.486803011295216, 0.0],
              [-80.91341011763005, 35.487398678116662, 0.0],
              [-80.913018117868404, 35.487582011362406, 0.0],
              [-80.912374185306462, 35.487696811985302, 0.0],
              [-80.912010184991246, 35.487857211286617, 0.0],
              [-80.911926385155368, 35.488361011569069, 0.0],
              [-80.911590518422557, 35.488452878448655, 0.0],
              [-80.911254118548086, 35.487788677982422, 0.0],
              [-80.911169918716709, 35.487353611708507, 0.0],
              [-80.911533718246375, 35.486735011206676, 0.0],
              [-80.911645517980389, 35.486162477810673, 0.0],
              [-80.911421318069657, 35.485773211219225, 0.0],
              [-80.911393118395125, 35.485177811221448, 0.0],
              [-80.911308984715106, 35.484994612007185, 0.0],
              [-80.911000918239964, 35.484811411690487, 0.0],
              [-80.909909118598108, 35.485269878127781, 0.0],
              [-80.909096984473777, 35.485361678536485, 0.0],
              [-80.908704918139463, 35.485018211956692, 0.0],
              [-80.907472984943311, 35.485499478722758, 0.0],
              [-80.907277118497845, 35.485705811293734, 0.0],
              [-80.906885117699304, 35.485774478365791, 0.0],
              [-80.906772984568448, 35.485660011188209, 0.0],
              [-80.906688918745317, 35.48511041191923, 0.0],
              [-80.90657678528089, 35.484950211919006, 0.0],
              [-80.906100584962033, 35.48469827792524, 0.0],
              [-80.904476718203924, 35.484882078546008, 0.0],
              [-80.904252584545304, 35.484790411521395, 0.0],
              [-80.904224518704254, 35.484492811292256, 0.0],
              [-80.905260385417307, 35.484148877814498, 0.0],
              [-80.905736185224427, 35.483644877836035, 0.0],
              [-80.906184317879394, 35.483736411263422, 0.0],
              [-80.90719211790946, 35.483278078749557, 0.0],
              [-80.907304118479402, 35.483026078440595, 0.0],
              [-80.906687985148835, 35.482888811812167, 0.0],
              [-80.906323984910884, 35.482637078413937, 0.0],
              [-80.90495198537208, 35.482797678338883, 0.0],
              [-80.904447985156821, 35.482752011797984, 0.0],
              [-80.903887918694338, 35.482500278321396, 0.0],
              [-80.903243917757578, 35.482569211683511, 0.0],
              [-80.903131985326368, 35.482912678216039, 0.0],
              [-80.901956117993947, 35.483233611793196, 0.0],
              [-80.901059984515896, 35.482821611293524, 0.0],
              [-80.900219984605855, 35.482982011103822, 0.0],
              [-80.899687785124115, 35.48263861142911, 0.0],
              [-80.89893191778468, 35.482593011428051, 0.0],
              [-80.897111984535556, 35.482845278527826, 0.0],
              [-80.896971918058654, 35.482776611607569, 0.0],
              [-80.896971917613783, 35.482662077869144, 0.0],
              [-80.897727784774489, 35.482501678289559, 0.0],
              [-80.89783978507198, 35.482387078310545, 0.0],
              [-80.897923717739445, 35.481952011445209, 0.0],
              [-80.897699518373386, 35.481448211198739, 0.0],
              [-80.897699384301802, 35.481173277906819, 0.0],
              [-80.897951384467703, 35.481173277793076, 0.0],
              [-80.897979517978214, 35.481516811183404, 0.0],
              [-80.8985115850016, 35.481997611508781, 0.0],
              [-80.898931718788646, 35.48215781176458, 0.0],
              [-80.899351718730657, 35.482157678172868, 0.0],
              [-80.903187584373697, 35.481721811198604, 0.0],
              [-80.904195517777893, 35.481332078421978, 0.0],
              [-80.904335318099086, 35.480782411850193, 0.0],
              [-80.902822917851836, 35.479592011516417, 0.0],
              [-80.902066918542786, 35.479386011965332, 0.0],
              [-80.901674784681873, 35.479019678556334, 0.0],
              [-80.90117071765826, 35.478813611835193, 0.0],
              [-80.901170718734988, 35.478722011304093, 0.0],
              [-80.901478585001513, 35.478584478085601, 0.0],
              [-80.90217858477213, 35.47844701116086, 0.0],
              [-80.902486584637387, 35.478584277832447, 0.0],
              [-80.902878518753155, 35.478630011388915, 0.0],
              [-80.903074517712682, 35.478423877785971, 0.0],
              [-80.903578385188325, 35.478263278023526, 0.0],
              [-80.903578585055413, 35.478813011466436, 0.0],
              [-80.904278718787893, 35.479019011962926, 0.0],
              [-80.90439078477479, 35.479568612020707, 0.0],
              [-80.904530918059493, 35.479683078058272, 0.0],
              [-80.905006917973139, 35.479683011778164, 0.0],
              [-80.905426785336914, 35.479385078489635, 0.0],
              [-80.905622718175593, 35.479110278583434, 0.0],
              [-80.906574384845513, 35.478629077927884, 0.0],
              [-80.907106384789003, 35.478468611481766, 0.0],
              [-80.907106518458605, 35.47876641180347, 0.0],
              [-80.906854718385759, 35.479224411215043, 0.0],
              [-80.906266784251088, 35.479453611188141, 0.0],
              [-80.906070784824351, 35.479705478745288, 0.0],
              [-80.906043118762625, 35.480392678321451, 0.0],
              [-80.906127184687222, 35.480644611471007, 0.0],
              [-80.90649138522059, 35.480965078471208, 0.0],
              [-80.906883385166068, 35.481079478200336, 0.0],
              [-80.907807385009235, 35.481102211787842, 0.0],
              [-80.908283117815145, 35.480575278401496, 0.0],
              [-80.908842984560522, 35.480208678635954, 0.0],
              [-80.90920698507874, 35.480139877872638, 0.0],
              [-80.909207185396227, 35.480506411407788, 0.0],
              [-80.908703384410416, 35.481101877949662, 0.0],
              [-80.908535518030746, 35.481605811689718, 0.0],
              [-80.908619517671752, 35.481674477937894, 0.0],
              [-80.909095518168954, 35.481582811977667, 0.0],
              [-80.90957158538896, 35.481903211093211, 0.0],
              [-80.909907718246586, 35.48188027864456, 0.0],
              [-80.910047518375649, 35.481696878162261, 0.0],
              [-80.91013131790524, 35.481124411297934, 0.0],
              [-80.91052331845583, 35.481078478268429, 0.0],
              [-80.911111585222287, 35.481673677984247, 0.0],
              [-80.911447585444122, 35.481719411496449, 0.0],
              [-80.911587518185613, 35.481513211764977, 0.0],
              [-80.911587118424521, 35.480620011825899, 0.0],
              [-80.911810984447001, 35.480482611166416, 0.0],
              [-80.912175385383648, 35.481238278124771, 0.0],
              [-80.912371384281968, 35.481398478719875, 0.0],
              [-80.912623384461057, 35.481421411221078, 0.0],
              [-80.912847318156352, 35.481123478266049, 0.0],
              [-80.912679117606956, 35.480757211652751, 0.0],
              [-80.912762585279651, 35.479818078579505, 0.0],
              [-80.912678517831807, 35.479772411852601, 0.0],
              [-80.912678117622605, 35.478833411504347, 0.0],
              [-80.912930118765246, 35.478764611286884, 0.0],
              [-80.91307018491176, 35.478924811349657, 0.0],
              [-80.913182518305703, 35.479634812022411, 0.0],
              [-80.913406585188199, 35.479909611788841, 0.0],
              [-80.914050717897467, 35.480275811398649, 0.0],
              [-80.914190984309997, 35.480665078359046, 0.0],
              [-80.914275518743636, 35.481375078315054, 0.0],
              [-80.914639518385371, 35.481626878040672, 0.0],
              [-80.915619517904332, 35.48155781200316, 0.0],
              [-80.91567598497457, 35.482153277878027, 0.0],
              [-80.91559198499769, 35.482267811897728, 0.0],
              [-80.915676318245801, 35.482794611652913, 0.0],
              [-80.915816317568854, 35.482977677993247, 0.0],
              [-80.916264518400368, 35.48322941155962, 0.0],
              [-80.917216584755138, 35.483572611586865, 0.0],
              [-80.917384718468128, 35.483503812020381, 0.0],
              [-80.917440385443015, 35.483137411785101, 0.0],
              [-80.916964185357386, 35.482519211827338, 0.0],
              [-80.91687971778822, 35.481877878745799, 0.0],
              [-80.917271784659548, 35.481946612010852, 0.0],
              [-80.917691918415713, 35.482335678199604, 0.0],
              [-80.918280117928887, 35.482427077995744, 0.0],
              [-80.919343718696695, 35.481877077899561, 0.0],
              [-80.919343584580929, 35.481693878477053, 0.0],
              [-80.91906351814194, 35.481442011555593, 0.0],
              [-80.918643384477932, 35.481281878421349, 0.0],
              [-80.917859117608813, 35.480640878426406, 0.0],
              [-80.917130917737069, 35.480297611547904, 0.0],
              [-80.9164585185992, 35.479748211169451, 0.0],
              [-80.916458385201864, 35.479610811634039, 0.0],
              [-80.916626385190781, 35.479450411313323, 0.0],
              [-80.916710118522644, 35.478877811606893, 0.0],
              [-80.917885785299632, 35.478442211556491, 0.0],
              [-80.918641384835183, 35.477754877815386, 0.0],
              [-80.918585185044421, 35.477457078152312, 0.0],
              [-80.917549118100595, 35.477068211156926, 0.0],
              [-80.915477185205319, 35.477046011208479, 0.0],
              [-80.914133117761054, 35.47670287870146, 0.0],
              [-80.913040985419102, 35.476245278030859, 0.0],
              [-80.912396918201608, 35.475856078572804, 0.0],
              [-80.912452784323492, 35.475741477864112, 0.0],
              [-80.912928718417447, 35.475626878473825, 0.0],
              [-80.914104918365368, 35.476084611388146, 0.0],
              [-80.914664917817248, 35.476153011493537, 0.0],
              [-80.915728584435527, 35.475900811863752, 0.0],
              [-80.916288384807274, 35.47548841196118, 0.0],
              [-80.916428318774777, 35.475282211884078, 0.0],
              [-80.916512118754454, 35.474847011470999, 0.0],
              [-80.915671918631304, 35.474435078543927, 0.0],
              [-80.915391784241152, 35.474137411336038, 0.0],
              [-80.915363518592457, 35.473587678014312, 0.0],
              [-80.915251385039255, 35.473381611626756, 0.0],
              [-80.914775184336094, 35.472786278146437, 0.0],
              [-80.913878984579156, 35.47212241126104, 0.0],
              [-80.9132629182416, 35.471802078600582, 0.0],
              [-80.913290784737399, 35.471733411913085, 0.0],
              [-80.914382584916652, 35.471618412086841, 0.0],
              [-80.914578518768309, 35.471664211944805, 0.0],
              [-80.915698718477103, 35.472305078116463, 0.0],
              [-80.917127384782702, 35.473701678023659, 0.0],
              [-80.917379584660708, 35.474136678407305, 0.0],
              [-80.91799558503638, 35.474296811106441, 0.0],
              [-80.918107784998313, 35.474480011510131, 0.0],
              [-80.920011785370065, 35.474937278243445, 0.0],
              [-80.920291718302451, 35.474799811837293, 0.0],
              [-80.919927118173405, 35.473883811383068, 0.0],
              [-80.919870917711933, 35.473402878189312, 0.0],
              [-80.920346718167835, 35.473173611277851, 0.0],
              [-80.920374584410652, 35.473082011329545, 0.0],
              [-80.92062651797518, 35.472967411511426, 0.0],
              [-80.921130585142265, 35.473127478511614, 0.0],
              [-80.921354985260251, 35.473837478264706, 0.0],
              [-80.921635117948824, 35.474089277818599, 0.0],
              [-80.9221389852013, 35.474112011982427, 0.0],
              [-80.922363117661504, 35.47420347833318, 0.0],
              [-80.922643317777613, 35.474478212035578, 0.0],
              [-80.922531384521164, 35.474753078526305, 0.0],
              [-80.921887718395197, 35.475142678164346, 0.0],
              [-80.921496117818904, 35.475623812057378, 0.0],
              [-80.921412317664192, 35.475921611391577, 0.0],
              [-80.922280584613276, 35.476448011388761, 0.0],
              [-80.922868585327492, 35.476608011674237, 0.0],
              [-80.923400784859268, 35.476905611769951, 0.0],
              [-80.923680785339414, 35.476951278276012, 0.0],
              [-80.923848784268969, 35.476882478387296, 0.0],
              [-80.924575785001679, 35.475737078296468, 0.0],
              [-80.924603584606956, 35.475279078366405, 0.0],
              [-80.924295184360304, 35.474683678198026, 0.0],
              [-80.92443438461757, 35.47333241148916, 0.0],
              [-80.924518384728231, 35.473263678687672, 0.0],
              [-80.925022318603055, 35.473194811529595, 0.0],
              [-80.925190718730249, 35.474110811470631, 0.0],
              [-80.925526985012695, 35.474454211543645, 0.0],
              [-80.925611318319952, 35.47509541157978, 0.0],
              [-80.925919384739203, 35.475209677789479, 0.0],
              [-80.926171185400165, 35.474843211875601, 0.0],
              [-80.926170784435698, 35.474133211176891, 0.0],
              [-80.926534518666728, 35.473858211401016, 0.0],
              [-80.926562384889024, 35.473674878617267, 0.0],
              [-80.926366318405599, 35.473468877849825, 0.0],
              [-80.926421984390842, 35.473033811291103, 0.0],
              [-80.926757917911857, 35.473033611318648, 0.0],
              [-80.926842185349386, 35.473262611448192, 0.0],
              [-80.927262318389154, 35.473651677886366, 0.0],
              [-80.927598317570471, 35.473628678164822, 0.0],
              [-80.927626117568821, 35.473376811825375, 0.0],
              [-80.927513984337637, 35.473216478371619, 0.0],
              [-80.927513585368743, 35.472552278241494, 0.0],
              [-80.927877385343777, 35.472368878184717, 0.0],
              [-80.928073317927613, 35.472162811272831, 0.0],
              [-80.928072984246839, 35.471796277825689, 0.0],
              [-80.927904784337002, 35.471384078686668, 0.0],
              [-80.928072585374565, 35.471109211896625, 0.0],
              [-80.928352384748393, 35.47088007796777, 0.0],
              [-80.929052184390613, 35.470605011511893, 0.0],
              [-80.929164184685334, 35.470719411452151, 0.0],
              [-80.929276585014648, 35.471337678019992, 0.0],
              [-80.929780984741754, 35.471887077790718, 0.0],
              [-80.92986511844056, 35.472116078125957, 0.0],
              [-80.930201184739914, 35.472345078071179, 0.0],
              [-80.93090118537225, 35.472413411856451, 0.0],
              [-80.931405384256152, 35.472756612068245, 0.0],
              [-80.93146171828019, 35.473352011545899, 0.0],
              [-80.93137791863731, 35.473466611267241, 0.0],
              [-80.931350518506846, 35.474428678465912, 0.0],
              [-80.931462584379162, 35.474566011822958, 0.0],
              [-80.931435185015175, 35.475505011999928, 0.0],
              [-80.931687385110877, 35.475711012045942, 0.0],
              [-80.93219138432741, 35.475779411860699, 0.0],
              [-80.932639317910514, 35.47568761193979, 0.0],
              [-80.933059318347958, 35.475801878132849, 0.0],
              [-80.933088318152045, 35.477038611148835, 0.0],
              [-80.933284318060743, 35.477244678331218, 0.0],
              [-80.933648384797124, 35.477359011777168, 0.0],
              [-80.933816184792903, 35.477106878505381, 0.0],
              [-80.934067318431531, 35.475824411761302, 0.0],
              [-80.934207118186123, 35.475549411581561, 0.0],
              [-80.934430984249047, 35.475526411212719, 0.0],
              [-80.934599184680337, 35.475755277849359, 0.0],
              [-80.934991317903055, 35.475961277977035, 0.0],
              [-80.935607318518649, 35.476006811861318, 0.0],
              [-80.936502784577556, 35.47531927871335, 0.0],
              [-80.93692238445756, 35.47483807819691, 0.0],
              [-80.937286318519014, 35.474837877834048, 0.0],
              [-80.937594517683593, 35.475135411727351, 0.0],
              [-80.93762278505028, 35.475387278428279, 0.0],
              [-80.938154984990589, 35.475799278439176, 0.0],
              [-80.938435385375669, 35.476280077820775, 0.0],
              [-80.938883385233524, 35.476348611411815, 0.0],
              [-80.93961118433748, 35.476210678370485, 0.0],
              [-80.940171384764383, 35.476485278577861, 0.0],
              [-80.940199517933536, 35.476645611735186, 0.0],
              [-80.940983917985292, 35.477149078469644, 0.0],
              [-80.94101211839768, 35.477423812030686, 0.0],
              [-80.940732385080494, 35.477675877962902, 0.0],
              [-80.940508985315475, 35.478500478298514, 0.0],
              [-80.941097318031396, 35.478866611542216, 0.0],
              [-80.94143318436295, 35.47879767814208, 0.0],
              [-80.941796918538088, 35.478454011176296, 0.0],
              [-80.941964718456703, 35.478133278621499, 0.0],
              [-80.942860318474757, 35.477697611897412, 0.0],
              [-80.942916185137335, 35.477537278292154, 0.0],
              [-80.942551917961751, 35.47721687793085, 0.0],
              [-80.942467718001694, 35.476896277884528, 0.0],
              [-80.943307585140658, 35.476827078007886, 0.0],
              [-80.94364338511194, 35.476712411222017, 0.0],
              [-80.943699117707411, 35.476437478263975, 0.0],
              [-80.943586984875182, 35.476231411323496, 0.0],
              [-80.94327891872021, 35.476071277858829, 0.0],
              [-80.942886917938353, 35.476048678355468, 0.0],
              [-80.942522985296279, 35.476209211757521, 0.0],
              [-80.942075118544324, 35.476278078061867, 0.0],
              [-80.941766984440562, 35.476026411640731, 0.0],
              [-80.941710717642422, 35.475797411351493, 0.0],
              [-80.941990584483534, 35.475614011406087, 0.0],
              [-80.942074318558639, 35.475430678370941, 0.0],
              [-80.941822185366362, 35.475247678427614, 0.0],
              [-80.941793985148308, 35.475087278538929, 0.0],
              [-80.941905918745491, 35.474949878633133, 0.0],
              [-80.941961718213207, 35.474652077840261, 0.0],
              [-80.941513518657857, 35.474377477858781, 0.0],
              [-80.941373318069665, 35.474057011234159, 0.0],
              [-80.941065185065227, 35.473896878273578, 0.0],
              [-80.940197185015478, 35.473874278181974, 0.0],
              [-80.939496784496299, 35.473370877906774, 0.0],
              [-80.939496585153989, 35.47307307838512, 0.0],
              [-80.939888184441173, 35.472660678249646, 0.0],
              [-80.941064118178232, 35.472545611447892, 0.0],
              [-80.941203984552487, 35.472453878200959, 0.0],
              [-80.941231518471284, 35.471858411140722, 0.0],
              [-80.941315317795954, 35.471698078135937, 0.0],
              [-80.941791118521408, 35.471491611227918, 0.0],
              [-80.942406984347826, 35.471422611794992, 0.0],
              [-80.943079184465191, 35.471788478133725, 0.0],
              [-80.943583185201689, 35.471834078341061, 0.0],
              [-80.944031117712498, 35.471742278180287, 0.0],
              [-80.945907117674565, 35.472038878515008, 0.0],
              [-80.946103117965208, 35.471947211187519, 0.0],
              [-80.946102718289666, 35.471649411442989, 0.0],
              [-80.944786385379373, 35.470940211713874, 0.0],
              [-80.943722185274453, 35.470711811461982, 0.0],
              [-80.943413918426401, 35.470254011755628, 0.0],
              [-80.943357585238871, 35.46979587858835, 0.0],
              [-80.943273384903051, 35.469681411271758, 0.0],
              [-80.942853384395775, 35.469567211132514, 0.0],
              [-80.942601318723945, 35.469384078531014, 0.0],
              [-80.942601117670151, 35.469200877848998, 0.0],
              [-80.942824784703419, 35.468925878652591, 0.0],
              [-80.942852718246527, 35.468742678368955, 0.0],
              [-80.942460717774736, 35.46860541164078, 0.0],
              [-80.941984784321704, 35.468789011246088, 0.0],
              [-80.941508984274563, 35.468812211277722, 0.0],
              [-80.941285117953797, 35.468903878050128, 0.0],
              [-80.941257317907159, 35.469087211941911, 0.0],
              [-80.940893584342476, 35.469476677898307, 0.0],
              [-80.940473718031654, 35.469568478612146, 0.0],
              [-80.940249584836664, 35.469339678413938, 0.0],
              [-80.939773518519885, 35.46913381163457, 0.0],
              [-80.939493717884432, 35.469225478259332, 0.0],
              [-80.939270184216909, 35.470004278081575, 0.0],
              [-80.93896271833141, 35.470577011301415, 0.0],
              [-80.938654984408657, 35.470806278092184, 0.0],
              [-80.93770338520136, 35.47117321146893, 0.0],
              [-80.936919584798559, 35.471311011551457, 0.0],
              [-80.9367233843804, 35.47105921129063, 0.0],
              [-80.937422784900562, 35.47050921168092, 0.0],
              [-80.937590718368696, 35.470257078136626, 0.0],
              [-80.937394384578383, 35.469867878518578, 0.0],
              [-80.937618185194211, 35.46968461184133, 0.0],
              [-80.937701985359894, 35.469409678222355, 0.0],
              [-80.937225918442721, 35.469226677970084, 0.0],
              [-80.936862118009756, 35.469410078139795, 0.0],
              [-80.936274317918901, 35.469479078341351, 0.0],
              [-80.935966517945417, 35.469800011744525, 0.0],
              [-80.93448291859319, 35.469984011840289, 0.0],
              [-80.93425871817341, 35.469663411171354, 0.0],
              [-80.934062585396319, 35.46957187854688, 0.0],
              [-80.932998984872839, 35.469755678340533, 0.0],
              [-80.932886784938106, 35.469549611722528, 0.0],
              [-80.934538118616103, 35.468930411847388, 0.0],
              [-80.934929784756179, 35.468632478417085, 0.0],
              [-80.935293717596593, 35.468472011600589, 0.0],
              [-80.936329518692489, 35.468379812016202, 0.0],
              [-80.93644131802823, 35.468265211620576, 0.0],
              [-80.936552918158114, 35.467692611354295, 0.0],
              [-80.9367767178706, 35.467463411688001, 0.0],
              [-80.937476385185519, 35.467279811210098, 0.0],
              [-80.937644118528041, 35.466913278624993, 0.0],
              [-80.937671784458985, 35.466478211307532, 0.0],
              [-80.937755717677504, 35.46636361157092, 0.0],
              [-80.938399384615934, 35.466157211410753, 0.0],
              [-80.939575518704245, 35.466591678123017, 0.0],
              [-80.940107518680165, 35.466660078303661, 0.0],
              [-80.940247384372114, 35.466591411792727, 0.0],
              [-80.940526585233926, 35.465789611132408, 0.0],
              [-80.940722318512655, 35.465445878446737, 0.0],
              [-80.94088958497467, 35.464667077909546, 0.0],
              [-80.94130918423447, 35.464346278139146, 0.0],
              [-80.941336918001682, 35.463865211673586, 0.0],
              [-80.941196785109611, 35.463773678581482, 0.0],
              [-80.941140184225674, 35.462926278373217, 0.0],
              [-80.940943984990554, 35.462651678138492, 0.0],
              [-80.94057998498748, 35.462514411596672, 0.0],
              [-80.940075785103929, 35.462171278706521, 0.0],
              [-80.939796117944027, 35.462423277984762, 0.0],
              [-80.939544918278031, 35.463362477817526, 0.0],
              [-80.939460918038776, 35.463385411409838, 0.0],
              [-80.939460985241141, 35.46349987844534, 0.0],
              [-80.939321117740263, 35.463637411359137, 0.0],
              [-80.939265518089599, 35.464118411488322, 0.0],
              [-80.939349517700236, 35.46418707857908, 0.0],
              [-80.939377785412361, 35.464553478736725, 0.0],
              [-80.939237985246066, 35.464713811867028, 0.0],
              [-80.93884618421319, 35.464805677842314, 0.0],
              [-80.938482117725272, 35.464714278318482, 0.0],
              [-80.937782984790601, 35.465447478397003, 0.0],
              [-80.93733511781852, 35.465562278478913, 0.0],
              [-80.937083117580386, 35.465447878340555, 0.0],
              [-80.936943118335989, 35.46549367875344, 0.0],
              [-80.936467718563108, 35.466020811224723, 0.0],
              [-80.935236118541297, 35.466433678320826, 0.0],
              [-80.934760718099923, 35.46696061132301, 0.0],
              [-80.934088984303088, 35.467235811984899, 0.0],
              [-80.933389118330268, 35.467259078602744, 0.0],
              [-80.933220985300991, 35.467121678210276, 0.0],
              [-80.933136784538632, 35.466824078359338, 0.0],
              [-80.932996784682189, 35.466732478549226, 0.0],
              [-80.932464984589856, 35.466824411794931, 0.0],
              [-80.932184784901395, 35.46657261209328, 0.0],
              [-80.932128585303445, 35.466366411682834, 0.0],
              [-80.932296518514377, 35.466137411402556, 0.0],
              [-80.932072384502149, 35.465908411931458, 0.0],
              [-80.932072317571041, 35.465771011479525, 0.0],
              [-80.932547718515096, 35.465038011856784, 0.0],
              [-80.933414917641997, 35.464190078416983, 0.0],
              [-80.933218717579834, 35.463800877812332, 0.0],
              [-80.933442385246352, 35.463480077851521, 0.0],
              [-80.933946117945453, 35.463250878773778, 0.0],
              [-80.934757384307161, 35.462654878168514, 0.0],
              [-80.935009185407125, 35.462357012022672, 0.0],
              [-80.935036784703385, 35.462059278760371, 0.0],
              [-80.934308584851777, 35.461555811165084, 0.0],
              [-80.934056717989407, 35.461510211677734, 0.0],
              [-80.933581185365853, 35.462060078739093, 0.0],
              [-80.933245384927858, 35.462151877909456, 0.0],
              [-80.933105318231455, 35.462060278330632, 0.0],
              [-80.933020918264049, 35.461442011620129, 0.0],
              [-80.932712784979657, 35.461281878735257, 0.0],
              [-80.931817517862243, 35.46199221203068, 0.0],
              [-80.931677918756378, 35.462221412092667, 0.0],
              [-80.931566585307621, 35.463206211667114, 0.0],
              [-80.931342784779147, 35.463458211233281, 0.0],
              [-80.931091318246899, 35.464030878061308, 0.0],
              [-80.931259784444578, 35.464740811893421, 0.0],
              [-80.931119985373314, 35.465015678259519, 0.0],
              [-80.930812117759828, 35.465107411601046, 0.0],
              [-80.930447984491806, 35.464878611342868, 0.0],
              [-80.930084184421261, 35.465016211990729, 0.0],
              [-80.929832518637468, 35.465497278635894, 0.0],
              [-80.929748984721314, 35.466115677982145, 0.0],
              [-80.929861118441721, 35.466321678458442, 0.0],
              [-80.929945518450481, 35.466894211346244, 0.0],
              [-80.929805518446088, 35.467008811187725, 0.0],
              [-80.928993717609629, 35.467055012018513, 0.0],
              [-80.928741584534421, 35.466734478445915, 0.0],
              [-80.928265517949143, 35.466574478742466, 0.0],
              [-80.928265385412004, 35.46634541208293, 0.0],
              [-80.928377317670964, 35.466185012013483, 0.0],
              [-80.928432985142749, 35.465841478687771, 0.0],
              [-80.928320918672441, 35.465727012020629, 0.0],
              [-80.927509184379829, 35.465910611354744, 0.0],
              [-80.927201117688355, 35.465635878287209, 0.0],
              [-80.927144718171959, 35.464948811979852, 0.0],
              [-80.926416518636131, 35.464582678403524, 0.0],
              [-80.926556384507393, 35.464353611819263, 0.0],
              [-80.927424118013974, 35.464101278303936, 0.0],
              [-80.927843917718576, 35.463895011232168, 0.0],
              [-80.92787171808267, 35.463643078086044, 0.0],
              [-80.927507717819822, 35.463574477819982, 0.0],
              [-80.925996118087141, 35.463689611399488, 0.0],
              [-80.925939918358125, 35.463277411210015, 0.0],
              [-80.927983118292616, 35.462864412041789, 0.0],
              [-80.928570918111589, 35.462658011247804, 0.0],
              [-80.928794518171671, 35.462199678441657, 0.0],
              [-80.928710318009223, 35.461856211184838, 0.0],
              [-80.92857018525109, 35.461718878360216, 0.0],
              [-80.927982317660224, 35.461467278539828, 0.0],
              [-80.926134384355038, 35.460987078706161, 0.0],
              [-80.92543471807349, 35.461079011194016, 0.0],
              [-80.924706984994856, 35.461377011981348, 0.0],
              [-80.924231185018243, 35.461446011179738, 0.0],
              [-80.923838918543865, 35.460850611830381, 0.0],
              [-80.923922384782003, 35.460117678613521, 0.0],
              [-80.924594117699272, 35.459796811187125, 0.0],
              [-80.927000984276447, 35.459246211827157, 0.0],
              [-80.927252717762883, 35.458902477837924, 0.0],
              [-80.927308318561401, 35.458421478762219, 0.0],
              [-80.927196317692136, 35.45833001174659, 0.0],
              [-80.926216518354153, 35.458193011957363, 0.0],
              [-80.925852384233195, 35.457849611898574, 0.0],
              [-80.925767585385003, 35.456475478494049, 0.0],
              [-80.925963517825906, 35.456406678209355, 0.0],
              [-80.926691318113399, 35.456543678106136, 0.0],
              [-80.927166917971675, 35.456200011578346, 0.0],
              [-80.927278718364377, 35.455787678209248, 0.0],
              [-80.927726518344713, 35.455718811380237, 0.0],
              [-80.928370385146195, 35.455787211973124, 0.0],
              [-80.928790118144988, 35.455695411621932, 0.0],
              [-80.929126118387799, 35.455832678652897, 0.0],
              [-80.930189918336893, 35.455946678067185, 0.0],
              [-80.93058178475205, 35.456129678377067, 0.0],
              [-80.930778118025685, 35.456610612088589, 0.0],
              [-80.931170184210487, 35.456816477892851, 0.0],
              [-80.931421984885546, 35.456656078730511, 0.0],
              [-80.931505718163407, 35.456220878400771, 0.0],
              [-80.931813384757874, 35.455968878342048, 0.0],
              [-80.931812984674025, 35.455442077872284, 0.0],
              [-80.932064718034241, 35.455006811449309, 0.0],
              [-80.931952184834131, 35.454388478207328, 0.0],
              [-80.932119984869956, 35.454159411192094, 0.0],
              [-80.933379518206621, 35.453952611914531, 0.0],
              [-80.93452711858383, 35.454181078229176, 0.0],
              [-80.935255184834816, 35.454478411453941, 0.0],
              [-80.935423318243295, 35.454707411680353, 0.0],
              [-80.935479718090576, 35.455142478296374, 0.0],
              [-80.935703784971167, 35.455417211931461, 0.0],
              [-80.936375717605671, 35.455577211358992, 0.0],
              [-80.936487917770251, 35.455874811748927, 0.0],
              [-80.937188118399305, 35.456424078606261, 0.0],
              [-80.938700185389195, 35.457133411688268, 0.0],
              [-80.939231984704506, 35.457110078265629, 0.0],
              [-80.939231784938585, 35.456766611508328, 0.0],
              [-80.938951117615673, 35.455942211886949, 0.0],
              [-80.938866384769625, 35.45518647803565, 0.0],
              [-80.938334518186537, 35.454934877825814, 0.0],
              [-80.938334317885918, 35.454705878294149, 0.0],
              [-80.938529918538833, 35.454201811368648, 0.0],
              [-80.938081717904012, 35.453973078718448, 0.0],
              [-80.937829984967152, 35.4541336114426, 0.0],
              [-80.937522184255855, 35.454225412072283, 0.0],
              [-80.937242118454492, 35.454042278732018, 0.0],
              [-80.936290518736882, 35.454111478630445, 0.0],
              [-80.935954585382618, 35.453997078111165, 0.0],
              [-80.935114384927942, 35.453333411406, 0.0],
              [-80.935253918298969, 35.452875211793575, 0.0],
              [-80.935953517572969, 35.452554211645825, 0.0],
              [-80.936792518173974, 35.45170647794982, 0.0],
              [-80.937519985343641, 35.451362611719809, 0.0],
              [-80.93833158512092, 35.451224611449064, 0.0],
              [-80.938974584304717, 35.450308211408263, 0.0],
              [-80.938974318544155, 35.449941678230807, 0.0],
              [-80.938806185252815, 35.449735678590471, 0.0],
              [-80.938721985023818, 35.449323478728033, 0.0],
              [-80.938077784557166, 35.448865811842893, 0.0],
              [-80.938133384273542, 35.44836181164824, 0.0],
              [-80.93776951794149, 35.448224678268467, 0.0],
              [-80.937545517832135, 35.448041477906521, 0.0],
              [-80.9375169842627, 35.447491878745524, 0.0],
              [-80.93723698431225, 35.447286011970128, 0.0],
              [-80.936397117775783, 35.447011611816791, 0.0],
              [-80.935893385206853, 35.447172211830512, 0.0],
              [-80.935641784565874, 35.447584612008747, 0.0],
              [-80.935726317724885, 35.448134211740047, 0.0],
              [-80.936118518640029, 35.448615011700788, 0.0],
              [-80.936006584319998, 35.448798211872123, 0.0],
              [-80.935558984328878, 35.449004611662431, 0.0],
              [-80.935559185266172, 35.449302278383058, 0.0],
              [-80.935755384992959, 35.449645811906876, 0.0],
              [-80.936679318145025, 35.450080411324215, 0.0],
              [-80.936707718164683, 35.450469811738373, 0.0],
              [-80.936455984992577, 35.450767611762032, 0.0],
              [-80.935728718334516, 35.451409211285259, 0.0],
              [-80.934917118542501, 35.451684478657995, 0.0],
              [-80.934637517588484, 35.451959411476594, 0.0],
              [-80.933853917722075, 35.452257478417145, 0.0],
              [-80.933714118555116, 35.452463811354924, 0.0],
              [-80.932790717643087, 35.452762011776741, 0.0],
              [-80.932567117698554, 35.453174278093385, 0.0],
              [-80.932175317581951, 35.45328907794844, 0.0],
              [-80.931923318539916, 35.453083078698704, 0.0],
              [-80.931698984927706, 35.452670878405492, 0.0],
              [-80.931474984866554, 35.452602411542756, 0.0],
              [-80.931251318643362, 35.452854278637581, 0.0],
              [-80.93111171832706, 35.453243811400895, 0.0],
              [-80.930859917677623, 35.453472878084305, 0.0],
              [-80.929068785294618, 35.453748478388448, 0.0],
              [-80.927977318327407, 35.454023878038782, 0.0],
              [-80.92649338486072, 35.453291611978727, 0.0],
              [-80.926493184597661, 35.452925212087976, 0.0],
              [-80.927079784247539, 35.451069811881844, 0.0],
              [-80.92685578504225, 35.450909478474031, 0.0],
              [-80.926323918160065, 35.45097847871326, 0.0],
              [-80.925931918698168, 35.450658011985212, 0.0],
              [-80.925540185078617, 35.450887278314433, 0.0],
              [-80.925428384856801, 35.451162211666826, 0.0],
              [-80.925232585310383, 35.451345478348678, 0.0],
              [-80.925232784959576, 35.451643211445017, 0.0],
              [-80.925596918141665, 35.452101078516861, 0.0],
              [-80.925709117758998, 35.452536278647422, 0.0],
              [-80.925513584281205, 35.452994412057208, 0.0],
              [-80.924506117790102, 35.453452811448521, 0.0],
              [-80.924226384324797, 35.453842211336955, 0.0],
              [-80.9241993183076, 35.45507907803109, 0.0],
              [-80.924059384287901, 35.455422611589917, 0.0],
              [-80.923695917904411, 35.455789278440179, 0.0],
              [-80.923668518544247, 35.456911478422377, 0.0],
              [-80.923388918578752, 35.457392478027657, 0.0],
              [-80.923305318444136, 35.458102477813419, 0.0],
              [-80.92308218435646, 35.459431011238649, 0.0],
              [-80.922326718592075, 35.459889278284635, 0.0],
              [-80.921570984683655, 35.460141611796388, 0.0],
              [-80.920843185194542, 35.460119011196575, 0.0],
              [-80.920423517596433, 35.460302411301008, 0.0],
              [-80.920479718477424, 35.460668811731757, 0.0],
              [-80.920759785414859, 35.460943477907364, 0.0],
              [-80.921011918393148, 35.461401478409606, 0.0],
              [-80.92098451861267, 35.462569612014015, 0.0],
              [-80.920284784527453, 35.462798811256576, 0.0],
              [-80.920200984275226, 35.462890478752513, 0.0],
              [-80.920145185359971, 35.463348478763024, 0.0],
              [-80.920033318099527, 35.463508878012924, 0.0],
              [-80.920033518251685, 35.463852478155687, 0.0],
              [-80.92053751844351, 35.464058411347132, 0.0],
              [-80.920845584526688, 35.464356011699458, 0.0],
              [-80.921042718637864, 35.466188078003341, 0.0],
              [-80.921350917775698, 35.466623211365977, 0.0],
              [-80.921435384411652, 35.467470478468428, 0.0],
              [-80.921659518496867, 35.46767647865569, 0.0],
              [-80.921659517923189, 35.467791078483721, 0.0],
              [-80.921323584304716, 35.46795147806268, 0.0],
              [-80.921211584713618, 35.467882811891499, 0.0],
              [-80.921071718279336, 35.467974478543383, 0.0],
              [-80.920931918475546, 35.468363877859467, 0.0],
              [-80.920679918409149, 35.46838687863815, 0.0],
              [-80.920455585046312, 35.467654078605179, 0.0],
              [-80.919867518565255, 35.467310811988142, 0.0],
              [-80.919922585244066, 35.465638878762292, 0.0],
              [-80.919642317603518, 35.465180878446297, 0.0],
              [-80.918998317908688, 35.464975011795808, 0.0],
              [-80.91852278485031, 35.465318678716514, 0.0],
              [-80.918243184575203, 35.466028811884662, 0.0],
              [-80.918187718595874, 35.46708241197323, 0.0],
              [-80.918075785107703, 35.467265611530166, 0.0],
              [-80.917739917711216, 35.467357411534984, 0.0],
              [-80.917347785074782, 35.467174278317124, 0.0],
              [-80.917011984681182, 35.467289011765644, 0.0],
              [-80.916676184296009, 35.467563878572804, 0.0],
              [-80.915360718263315, 35.46809107798645, 0.0],
              [-80.915388518715844, 35.467610211690598, 0.0],
              [-80.91701151765821, 35.466212478602472, 0.0],
              [-80.917011318166445, 35.466006411178988, 0.0],
              [-80.91681538438452, 35.465914878493031, 0.0],
              [-80.91645151862835, 35.466075411868616, 0.0],
              [-80.916031585267206, 35.466121211530876, 0.0],
              [-80.915835584668926, 35.465892411450405, 0.0],
              [-80.915275517996776, 35.465640611421442, 0.0],
              [-80.915275318679818, 35.465297011717453, 0.0],
              [-80.916702917954339, 35.465159212001581, 0.0],
              [-80.916954785048858, 35.465021677884685, 0.0],
              [-80.917178584578082, 35.46463221183221, 0.0],
              [-80.917401984678776, 35.463876278075979, 0.0],
              [-80.918073784454933, 35.463555411345382, 0.0],
              [-80.918325517672642, 35.463028477953415, 0.0],
              [-80.91838118537332, 35.462662012085552, 0.0],
              [-80.917821318732805, 35.46257067787122, 0.0],
              [-80.917009317872584, 35.462181611788488, 0.0],
              [-80.916057584468064, 35.462342278005345, 0.0],
              [-80.915469718455483, 35.462251012090547, 0.0],
              [-80.915217585036444, 35.462067811879884, 0.0],
              [-80.915189385279731, 35.461724277976181, 0.0],
              [-80.915357385083851, 35.461449411569198, 0.0],
              [-80.916308784307702, 35.460991011974251, 0.0],
              [-80.916336717773603, 35.460716211281778, 0.0],
              [-80.915832718379946, 35.460395677859971, 0.0],
              [-80.915804385090766, 35.459937678373414, 0.0],
              [-80.915580384673703, 35.45961707839686, 0.0],
              [-80.915356317672234, 35.459479811666341, 0.0],
              [-80.914880385217714, 35.459434077809775, 0.0],
              [-80.914712517818032, 35.459319611280215, 0.0],
              [-80.914460184682639, 35.458701278267924, 0.0],
              [-80.913956318571223, 35.458587011169264, 0.0],
              [-80.913732585020625, 35.458953478002243, 0.0],
              [-80.913648717597169, 35.459342812005822, 0.0],
              [-80.913508785015281, 35.459503211834459, 0.0],
              [-80.913593118393962, 35.460030011292154, 0.0],
              [-80.913789184832183, 35.460488011644244, 0.0],
              [-80.913705384930552, 35.460762811737645, 0.0],
              [-80.913341717706984, 35.461175211736453, 0.0],
              [-80.913174118028408, 35.461999678460408, 0.0],
              [-80.912978185266326, 35.462137211963132, 0.0],
              [-80.912838184561423, 35.462045678102875, 0.0],
              [-80.912725917720252, 35.461312812046863, 0.0],
              [-80.912025917613178, 35.460969478000159, 0.0],
              [-80.911913918569155, 35.460763411148797, 0.0],
              [-80.911465985342318, 35.460671878392894, 0.0],
              [-80.91112991804205, 35.460488811136472, 0.0],
              [-80.910597984292494, 35.46037441172053, 0.0],
              [-80.910402185088273, 35.460603612100883, 0.0],
              [-80.910374584784847, 35.461588411945449, 0.0],
              [-80.909926785225039, 35.46170307805022, 0.0],
              [-80.909562784517433, 35.461497011413762, 0.0],
              [-80.909394917693035, 35.461588678171303, 0.0],
              [-80.909367518617131, 35.462917011747486, 0.0],
              [-80.909535585213249, 35.463260611723321, 0.0],
              [-80.910067717781828, 35.463810011272244, 0.0],
              [-80.910067918213031, 35.464451278154705, 0.0],
              [-80.909816118523423, 35.464497211594441, 0.0],
              [-80.909059985162855, 35.464016478722066, 0.0],
              [-80.908808184629436, 35.464108212061625, 0.0],
              [-80.908808185236325, 35.464268478457093, 0.0],
              [-80.909732385224146, 35.465184278289406, 0.0],
              [-80.909760518070868, 35.465413278419376, 0.0],
              [-80.909480518671657, 35.465505011920463, 0.0],
              [-80.909116585375855, 35.465344811863091, 0.0],
              [-80.908808518226934, 35.465047212038108, 0.0],
              [-80.908052584716842, 35.464955812026759, 0.0],
              [-80.907800518049754, 35.464818411358664, 0.0],
              [-80.907492718751286, 35.464772678233928, 0.0],
              [-80.907128785341214, 35.464933077983105, 0.0],
              [-80.907128918423211, 35.465116278703626, 0.0],
              [-80.907576984721473, 35.465620011445509, 0.0],
              [-80.908249118759556, 35.466261077959238, 0.0],
              [-80.908277317857241, 35.466879478530494, 0.0],
              [-80.908837385076779, 35.467131278010122, 0.0],
              [-80.909061384435873, 35.467360211942164, 0.0],
              [-80.909173518149302, 35.467658011152473, 0.0],
              [-80.91085358477693, 35.468665211771011, 0.0],
              [-80.91088178491961, 35.468985811361961, 0.0],
              [-80.910769917793885, 35.469192011268014, 0.0],
              [-80.910742184542201, 35.469879078189138, 0.0],
              [-80.910490185444417, 35.469902011287608, 0.0],
              [-80.910154185324799, 35.469650211568229, 0.0],
              [-80.909622184393768, 35.469421277817453, 0.0],
              [-80.908866517690726, 35.469742078477594, 0.0],
              [-80.90872651801439, 35.469581878474088, 0.0],
              [-80.908585984260895, 35.468620011250088, 0.0],
              [-80.908389918596711, 35.468322411820523, 0.0],
              [-80.90754971870679, 35.467612478450235, 0.0],
              [-80.906877784420033, 35.467406611997973, 0.0],
              [-80.906513917676207, 35.467498278067858, 0.0],
              [-80.906177985062527, 35.467819078075856, 0.0],
              [-80.90620678462902, 35.469514011896436, 0.0],
              [-80.906906917916515, 35.470017612071814, 0.0],
              [-80.907046984467257, 35.470200811689111, 0.0],
              [-80.906907118615749, 35.470338278320327, 0.0],
              [-80.906403118314159, 35.470429878575828, 0.0],
              [-80.90629111839381, 35.47031547786213, 0.0],
              [-80.906067185231166, 35.470269678320328, 0.0],
              [-80.905563318599292, 35.470430211607429, 0.0],
              [-80.905424384289859, 35.473361811371959, 0.0],
              [-80.905284384538902, 35.473453412038126, 0.0],
              [-80.905116384478177, 35.473384678057222, 0.0],
              [-80.905060184438028, 35.472880877881515, 0.0],
              [-80.904723985028397, 35.472285478724764, 0.0],
              [-80.904359585144206, 35.471071811515131, 0.0],
              [-80.90416358478673, 35.471048878483543, 0.0],
              [-80.90276418475824, 35.47201107868996, 0.0],
              [-80.902008318326452, 35.472217411206763, 0.0],
              [-80.901924317691595, 35.472171677903489, 0.0],
              [-80.901924185201679, 35.472011277913992, 0.0],
              [-80.903463718340262, 35.470980278366682, 0.0],
              [-80.904303384360702, 35.470270078735339, 0.0],
              [-80.904555184284717, 35.469697611354505, 0.0],
              [-80.904218985405905, 35.469399878340411, 0.0],
              [-80.904106984725928, 35.46912507835814, 0.0],
              [-80.903183118372937, 35.469194012076059, 0.0],
              [-80.903015118457631, 35.468987878590362, 0.0],
              [-80.903098718016324, 35.467865678755807, 0.0],
              [-80.903294518770679, 35.467613678000305, 0.0],
              [-80.90379851797401, 35.467499077953576, 0.0],
              [-80.904050184800468, 35.467132611245042, 0.0],
              [-80.903993918153049, 35.466537078015079, 0.0],
              [-80.90371398503197, 35.466491412033776, 0.0],
              [-80.90346218509535, 35.466697612078178, 0.0],
              [-80.903294384284095, 35.467018278386703, 0.0],
              [-80.902734584721046, 35.467201611634209, 0.0],
              [-80.90234271767514, 35.467591011838081, 0.0],
              [-80.901894918391307, 35.467866011183915, 0.0],
              [-80.900411318004188, 35.468278611267053, 0.0],
              [-80.899935384446607, 35.468530612063596, 0.0],
              [-80.899739518075677, 35.468782611134898, 0.0],
              [-80.899711718803957, 35.469446811754452, 0.0],
              [-80.899599784596447, 35.469675812067564, 0.0],
              [-80.898732185133724, 35.470637877779438, 0.0],
              [-80.898732385081018, 35.471073011488663, 0.0],
              [-80.898256517659277, 35.471279278350586, 0.0],
              [-80.897976584664306, 35.471874811156795, 0.0],
              [-80.898032718759325, 35.472264078054749, 0.0],
              [-80.897640784314532, 35.472493211988592, 0.0],
              [-80.897248918009353, 35.472607811496054, 0.0],
              [-80.897304584306411, 35.471760278251203, 0.0],
              [-80.897500517909336, 35.471393878651618, 0.0],
              [-80.897556518151788, 35.471004611272036, 0.0],
              [-80.898200185056254, 35.470317277967411, 0.0],
              [-80.897556118746522, 35.469905278743575, 0.0],
              [-80.897500118525372, 35.469561677974845, 0.0],
              [-80.898255918544521, 35.469607411489747, 0.0],
              [-80.898423784803825, 35.469332478535442, 0.0],
              [-80.89842371834628, 35.468897411657863, 0.0],
              [-80.897863784508885, 35.468828811149635, 0.0],
              [-80.897499784943221, 35.468691411345979, 0.0],
              [-80.897499784572304, 35.468508211991576, 0.0],
              [-80.898227718256592, 35.46841647856543, 0.0],
              [-80.898955518615196, 35.468210078407289, 0.0],
              [-80.899291318134289, 35.467935278453091, 0.0],
              [-80.899375184315176, 35.467568878025787, 0.0],
              [-80.898115318269149, 35.466927811681458, 0.0],
              [-80.898171185000933, 35.466813211599629, 0.0],
              [-80.899318918144957, 35.466675611464261, 0.0],
              [-80.899626985053288, 35.466927478364298, 0.0],
              [-80.899990917750088, 35.46697327799891, 0.0],
              [-80.900186785143674, 35.466675411338329, 0.0],
              [-80.900466717762839, 35.466583677887002, 0.0],
              [-80.901250718794472, 35.466698011615591, 0.0],
              [-80.901474518358796, 35.466491878148716, 0.0],
              [-80.901474384613394, 35.466240011389779, 0.0],
              [-80.901250184267809, 35.465827677868894, 0.0],
              [-80.90125018488655, 35.465552878435211, 0.0],
              [-80.90197798431042, 35.465507011802814, 0.0],
              [-80.901977918591555, 35.465255077785535, 0.0],
              [-80.901837918379456, 35.465094811794359, 0.0],
              [-80.901809785098123, 35.464865811156884, 0.0],
              [-80.902537584606492, 35.464682412020764, 0.0],
              [-80.902761518572532, 35.464476211674523, 0.0],
              [-80.902761385003728, 35.464201411219285, 0.0],
              [-80.902397384460627, 35.463926611573562, 0.0],
              [-80.899849917932457, 35.464041611830112, 0.0],
              [-80.899262118217806, 35.463904478417888, 0.0],
              [-80.899121984736254, 35.463790011355385, 0.0],
              [-80.899485784897905, 35.463286011204886, 0.0],
              [-80.899877585411744, 35.46294227860389, 0.0],
              [-80.90029751863942, 35.462850611434412, 0.0],
              [-80.900885518150616, 35.463148212028699, 0.0],
              [-80.901165384298977, 35.463056611924436, 0.0],
              [-80.901501185229989, 35.462735811718943, 0.0],
              [-80.902004917624069, 35.46246101174156, 0.0],
              [-80.902116785300748, 35.462094411430172, 0.0],
              [-80.902340784939454, 35.461979878603529, 0.0],
              [-80.902480718460893, 35.462002811358204, 0.0],
              [-80.902564784370554, 35.462460811838149, 0.0],
              [-80.902704784840424, 35.462598211595981, 0.0],
              [-80.903152785009496, 35.46255221196018, 0.0],
              [-80.9031805854232, 35.462162877828007, 0.0],
              [-80.902816584276891, 35.461613278698223, 0.0],
              [-80.90225651854405, 35.461086677870512, 0.0],
              [-80.901920517642452, 35.460926411344097, 0.0],
              [-80.900968718128865, 35.460812078443894, 0.0],
              [-80.900520784395852, 35.460629011158801, 0.0],
              [-80.90052071842706, 35.460400011444932, 0.0],
              [-80.90169631827402, 35.459895878443902, 0.0],
              [-80.902032184283911, 35.460079011146298, 0.0],
              [-80.902368117753042, 35.460147678456892, 0.0],
              [-80.902508117687105, 35.460079012124737, 0.0],
              [-80.902535985126406, 35.459689611779211, 0.0],
              [-80.902423918601059, 35.4593918784078, 0.0],
              [-80.902535918715486, 35.459323077794792, 0.0],
              [-80.903263718597259, 35.459506211312046, 0.0],
              [-80.903235518579521, 35.459093878556573, 0.0],
              [-80.90334751863557, 35.458956477830071, 0.0],
              [-80.904159185092823, 35.459025077833999, 0.0],
              [-80.904103185324331, 35.45854421113399, 0.0],
              [-80.903711118612947, 35.458223611875148, 0.0],
              [-80.903710985330804, 35.458040278763526, 0.0],
              [-80.904354584692385, 35.457650811785413, 0.0],
              [-80.904410518271533, 35.457421678638035, 0.0],
              [-80.904158518233103, 35.457284411673413, 0.0],
              [-80.902311117955051, 35.457376478144639, 0.0],
              [-80.901135518414009, 35.457147678080226, 0.0],
              [-80.900099784724418, 35.456827278522361, 0.0],
              [-80.899595917648924, 35.456896078180058, 0.0],
              [-80.899259984909946, 35.457148078215901, 0.0],
              [-80.899092317762594, 35.45776647862052, 0.0],
              [-80.900240318266825, 35.458774011615802, 0.0],
              [-80.900128318609219, 35.458865611402871, 0.0],
              [-80.899652518518664, 35.458934411542508, 0.0],
              [-80.899120585442276, 35.458682611632646, 0.0],
              [-80.898420784655443, 35.458682677938413, 0.0],
              [-80.898561185081803, 35.46035467818033, 0.0],
              [-80.898085317730448, 35.460446411494111, 0.0],
              [-80.897861384808834, 35.46035481207052, 0.0],
              [-80.897609318069073, 35.459919678201608, 0.0],
              [-80.897385384411763, 35.459759411581743, 0.0],
              [-80.896937518616156, 35.460171677922737, 0.0],
              [-80.8965737843005, 35.460744412037158, 0.0],
              [-80.896518118182058, 35.462004012036971, 0.0],
              [-80.896210318685775, 35.462118611352651, 0.0],
              [-80.896098384773282, 35.462668278124923, 0.0],
              [-80.895566584316043, 35.463149278454317, 0.0],
              [-80.895454918469852, 35.463790612001908, 0.0],
              [-80.895175118706334, 35.464248678580987, 0.0],
              [-80.894643318127635, 35.464661078265422, 0.0],
              [-80.894615384626945, 35.465256478410097, 0.0],
              [-80.894335517797941, 35.465462678198939, 0.0],
              [-80.894223585198858, 35.465829278350427, 0.0],
              [-80.893943785435326, 35.466127011648751, 0.0],
              [-80.893411918520457, 35.466287411741341, 0.0],
              [-80.893383917758854, 35.466127078640973, 0.0],
              [-80.893887718586058, 35.465417011511711, 0.0],
              [-80.893803385163537, 35.464157411212717, 0.0],
              [-80.893663384840451, 35.464088678511892, 0.0],
              [-80.893215518040137, 35.464249078255925, 0.0],
              [-80.89153591859133, 35.464547078280788, 0.0],
              [-80.891451917729114, 35.464455477845874, 0.0],
              [-80.891535918155043, 35.464340878399703, 0.0],
              [-80.89206771794926, 35.464180478483179, 0.0],
              [-80.89310351859514, 35.463699411137291, 0.0],
              [-80.893719184902693, 35.463195411865037, 0.0],
              [-80.894334985012563, 35.463012078343731, 0.0],
              [-80.894446784767638, 35.462828878676561, 0.0],
              [-80.894138784357324, 35.462531078574365, 0.0],
              [-80.894110718095533, 35.461981478147855, 0.0],
              [-80.893718717686014, 35.461683811239489, 0.0],
              [-80.89374671774182, 35.461546411655718, 0.0],
              [-80.894194585344636, 35.461477678433916, 0.0],
              [-80.894166385145596, 35.460882212002161, 0.0],
              [-80.893942385460008, 35.460721877995113, 0.0],
              [-80.892430785206628, 35.460676411438349, 0.0],
              [-80.892318784389133, 35.460584811210687, 0.0],
              [-80.892346785128254, 35.460493211816228, 0.0],
              [-80.89371831829807, 35.459943211519288, 0.0],
              [-80.893746318586764, 35.459553877876019, 0.0],
              [-80.894081985444942, 35.459004211233747, 0.0],
              [-80.89475378499948, 35.458729211834878, 0.0],
              [-80.894949717838898, 35.458568878103954, 0.0],
              [-80.894977584475512, 35.458225412080296, 0.0],
              [-80.894361785110306, 35.4581796780055, 0.0],
              [-80.893325984932076, 35.45797367832246, 0.0],
              [-80.893297917619108, 35.457813412092584, 0.0],
              [-80.893465918581143, 35.457744611284753, 0.0],
              [-80.894865585240552, 35.457904678324994, 0.0],
              [-80.894893518310411, 35.457561211232822, 0.0],
              [-80.894501517968209, 35.457217677835068, 0.0],
              [-80.89380171843645, 35.457057478118315, 0.0],
              [-80.892010184880732, 35.457126611162757, 0.0],
              [-80.891926184805882, 35.457057878393613, 0.0],
              [-80.891982184345636, 35.456920478562814, 0.0],
              [-80.89368951868191, 35.456347478560204, 0.0],
              [-80.893717384803139, 35.456164277980491, 0.0],
              [-80.893577517842544, 35.455958278556956, 0.0],
              [-80.894417184853594, 35.455797811280725, 0.0],
              [-80.894445118513303, 35.455545877927065, 0.0],
              [-80.894081184358086, 35.455202412075877, 0.0],
              [-80.894053118032275, 35.454904611841407, 0.0],
              [-80.893633118417412, 35.454584078320117, 0.0],
              [-80.89326918532187, 35.454446678274422, 0.0],
              [-80.892877317921005, 35.454446811928072, 0.0],
              [-80.891561784643542, 35.454721811852259, 0.0],
              [-80.890414118779091, 35.454378478658448, 0.0],
              [-80.890330184820428, 35.454218212005017, 0.0],
              [-80.891085918113959, 35.454195078028647, 0.0],
              [-80.891337785278523, 35.454057611690068, 0.0],
              [-80.891477718664817, 35.453691212022115, 0.0],
              [-80.891729585349594, 35.45346207783389, 0.0],
              [-80.89242931867507, 35.453370412079416, 0.0],
              [-80.892793185308165, 35.453187211958273, 0.0],
              [-80.893352784410425, 35.453072477898779, 0.0],
              [-80.893856717752001, 35.453278477940778, 0.0],
              [-80.894556384425627, 35.453301411345372, 0.0],
              [-80.894584384434395, 35.453026478098366, 0.0],
              [-80.894780184624423, 35.452728811472532, 0.0],
              [-80.895032118196454, 35.452637077982196, 0.0],
              [-80.895647984896272, 35.453118011495711, 0.0],
              [-80.895983917691083, 35.4531178786631, 0.0],
              [-80.89648771786598, 35.452820077920798, 0.0],
              [-80.896851517930472, 35.452476411537774, 0.0],
              [-80.897074985419763, 35.4511022113156, 0.0],
              [-80.897354784772475, 35.450667011482317, 0.0],
              [-80.897242785280682, 35.450460878134876, 0.0],
              [-80.8964589850913, 35.450277878628178, 0.0],
              [-80.896290985275073, 35.450071811795205, 0.0],
              [-80.895087384448388, 35.449865877979789, 0.0],
              [-80.894639718232256, 35.449957478015136, 0.0],
              [-80.894387718457025, 35.449522412129163, 0.0],
              [-80.895003318220631, 35.449339078103904, 0.0],
              [-80.895003384979944, 35.449155877963975, 0.0],
              [-80.894135518691456, 35.448491878549859, 0.0],
              [-80.89410751817293, 35.44833161213203, 0.0],
              [-80.894219385309356, 35.448194078505281, 0.0],
              [-80.894583184519377, 35.448125411984677, 0.0],
              [-80.895199117879656, 35.44851461140945, 0.0],
              [-80.895590918445549, 35.44853747842393, 0.0],
              [-80.895674785117052, 35.448216811809679, 0.0],
              [-80.896038584701216, 35.448125011959441, 0.0],
              [-80.896458518660054, 35.448422678710315, 0.0],
              [-80.896962318725684, 35.448399678450336, 0.0],
              [-80.897102118528736, 35.447918678508621, 0.0],
              [-80.897297984281138, 35.44785001133075, 0.0],
              [-80.898361584663775, 35.447987211558008, 0.0],
              [-80.89864138543912, 35.447620811864994, 0.0],
              [-80.899201185431835, 35.447529011973486, 0.0],
              [-80.899620984462459, 35.447712211313451, 0.0],
              [-80.899649318013616, 35.448467878302331, 0.0],
              [-80.900041318254566, 35.449040411562613, 0.0],
              [-80.900321385445295, 35.449727478436536, 0.0],
              [-80.900797317942661, 35.450025078061991, 0.0],
              [-80.901021384719812, 35.450528878250253, 0.0],
              [-80.901497317852062, 35.450895211780754, 0.0],
              [-80.90186111764136, 35.450918011203335, 0.0],
              [-80.90208491838429, 35.450322411733993, 0.0],
              [-80.902084585331778, 35.449566612092283, 0.0],
              [-80.901748718449852, 35.449223278183553, 0.0],
              [-80.901608384421493, 35.448490278161472, 0.0],
              [-80.900796584575701, 35.447895078205832, 0.0],
              [-80.900824185306533, 35.447116478319252, 0.0],
              [-80.901131985207499, 35.446910211441406, 0.0],
              [-80.902419384685089, 35.446772478647567, 0.0],
              [-80.90359498529169, 35.446863811836003, 0.0],
              [-80.90384698430924, 35.447024011425704, 0.0],
              [-80.903987185430452, 35.447894411808903, 0.0],
              [-80.904434984511909, 35.44798587858196, 0.0],
              [-80.905134917958577, 35.448329211818958, 0.0],
              [-80.905610718407345, 35.448352011903559, 0.0],
              [-80.906086384859478, 35.448077077841738, 0.0],
              [-80.906674117840055, 35.447916611429605, 0.0],
              [-80.907793718015114, 35.448076478234945, 0.0],
              [-80.909192917965257, 35.44754941171626, 0.0],
              [-80.909220918637644, 35.44736621140401, 0.0],
              [-80.908856784458678, 35.446954011808103, 0.0],
              [-80.909108584813822, 35.446679078462751, 0.0],
              [-80.909668385132093, 35.446793478764697, 0.0],
              [-80.909780318542857, 35.446656011150147, 0.0],
              [-80.910507985135865, 35.446495478567861, 0.0],
              [-80.91061978486286, 35.44633507860285, 0.0],
              [-80.910255918598637, 35.446197811219719, 0.0],
              [-80.908856385356728, 35.446015011943793, 0.0],
              [-80.908492384548623, 35.445671477845003, 0.0],
              [-80.907820717979874, 35.445534277938911, 0.0],
              [-80.907176918226895, 35.445282478163143, 0.0],
              [-80.905777384896922, 35.444962278730003, 0.0],
              [-80.905777185016561, 35.444550078435086, 0.0],
              [-80.905693185207269, 35.444481411263183, 0.0],
              [-80.9050495187293, 35.444527278596325, 0.0],
              [-80.90457371810075, 35.444298478568868, 0.0],
              [-80.903650118482958, 35.444550612136148, 0.0],
              [-80.903342384420128, 35.444733878082502, 0.0],
              [-80.903370584403405, 35.445352278163661, 0.0],
              [-80.903202717950848, 35.445535611367518, 0.0],
              [-80.903174918099594, 35.445924877987885, 0.0],
              [-80.903034918152926, 35.445993611812909, 0.0],
              [-80.901663585427642, 35.446085477855718, 0.0],
              [-80.901607584710305, 35.446016878095222, 0.0],
              [-80.902027184435923, 35.445398411320376, 0.0],
              [-80.90191518482105, 35.445283878271312, 0.0],
              [-80.900207917704478, 35.445032411546059, 0.0],
              [-80.899843985146063, 35.44503247811371, 0.0],
              [-80.899256384886527, 35.44539907810799, 0.0],
              [-80.898724718708564, 35.445536611212539, 0.0],
              [-80.898556718611871, 35.445307611796068, 0.0],
              [-80.898556384865287, 35.444345678795827, 0.0],
              [-80.899759784502947, 35.44407061117991, 0.0],
              [-80.900151384809945, 35.443543678349648, 0.0],
              [-80.900039385000952, 35.44306287875694, 0.0],
              [-80.900123184323988, 35.442765011744513, 0.0],
              [-80.900291118486692, 35.442627612031124, 0.0],
              [-80.900626918254659, 35.442535878321969, 0.0],
              [-80.90085091871542, 35.442902211877303, 0.0],
              [-80.901410784701, 35.44331441183644, 0.0],
              [-80.90188658533711, 35.443314278638596, 0.0],
              [-80.9030617847489, 35.442810078452666, 0.0],
              [-80.903453585104103, 35.442420811975317, 0.0],
              [-80.904041184867907, 35.442145811848647, 0.0],
              [-80.904208984513701, 35.441893811536886, 0.0],
              [-80.904152718047314, 35.44118381126372, 0.0],
              [-80.904292585223473, 35.440977678438799, 0.0],
              [-80.905859717808994, 35.440931411771814, 0.0],
              [-80.906055584706081, 35.440793878670092, 0.0],
              [-80.906055385051857, 35.44026721197509, 0.0],
              [-80.905747318652445, 35.439900811272651, 0.0],
              [-80.90552351789097, 35.439855011625468, 0.0],
              [-80.905187785372974, 35.440130078365833, 0.0],
              [-80.904544117655362, 35.4402446784513, 0.0],
              [-80.904236184903766, 35.440176078781761, 0.0],
              [-80.903704385040967, 35.439878478572588, 0.0],
              [-80.902445184535907, 35.44010781128658, 0.0],
              [-80.90227731852346, 35.439947478415412, 0.0],
              [-80.902304918568092, 35.43923747816013, 0.0],
              [-80.903983518743701, 35.437817211264523, 0.0],
              [-80.906333384832593, 35.435251412008519, 0.0],
              [-80.906864918314852, 35.435091012043848, 0.0],
              [-80.906920984872031, 35.435526078695702, 0.0],
              [-80.90750891767928, 35.436006878024983, 0.0],
              [-80.907732984385035, 35.436350411909181, 0.0],
              [-80.908656584977891, 35.436464611788395, 0.0],
              [-80.909020185412331, 35.436304211583277, 0.0],
              [-80.909132117869163, 35.436143878444888, 0.0],
              [-80.909747718356513, 35.436052078351437, 0.0],
              [-80.90994351780401, 35.435868811337464, 0.0],
              [-80.909859384854073, 35.43552527827908, 0.0],
              [-80.909243585080446, 35.43497581198298, 0.0],
              [-80.909215518027523, 35.434609411382191, 0.0],
              [-80.909327317779329, 35.43456347817574, 0.0],
              [-80.909775117722774, 35.434655011266429, 0.0],
              [-80.909915118254588, 35.434838077988758, 0.0],
              [-80.910782784554684, 35.435181411705337, 0.0],
              [-80.910866984833717, 35.435822678646289, 0.0],
              [-80.911174918288268, 35.436166077965012, 0.0],
              [-80.91181871774296, 35.436257478074388, 0.0],
              [-80.91218251779398, 35.436417678120257, 0.0],
              [-80.912574517807329, 35.436944277873657, 0.0],
              [-80.913022385440115, 35.437241878774401, 0.0],
              [-80.913022585378982, 35.43765421154383, 0.0],
              [-80.912910785318786, 35.437860278430371, 0.0],
              [-80.91226731824699, 35.438318611416868, 0.0],
              [-80.912351985001393, 35.439784411884453, 0.0],
              [-80.912604118066128, 35.440265211992298, 0.0],
              [-80.912967984788551, 35.440654478227692, 0.0],
              [-80.913415917699893, 35.440791811322384, 0.0],
              [-80.913779918431018, 35.441043612057584, 0.0],
              [-80.913779985261968, 35.441341278222666, 0.0],
              [-80.913388384572286, 35.441707811896315, 0.0],
              [-80.913416518492696, 35.442005611479374, 0.0],
              [-80.914144185014621, 35.442051078405399, 0.0],
              [-80.914675784625061, 35.441959278165513, 0.0],
              [-80.915179518436219, 35.441776012100618, 0.0],
              [-80.915179384853843, 35.441638611404734, 0.0],
              [-80.914703518228563, 35.441318078588864, 0.0],
              [-80.914759384866059, 35.441043211996103, 0.0],
              [-80.91523491868972, 35.440676611378464, 0.0],
              [-80.91531858445677, 35.439897878187899, 0.0],
              [-80.915598385091116, 35.439829077863415, 0.0],
              [-80.915962184565956, 35.440103878092437, 0.0],
              [-80.916438117747219, 35.440126611235627, 0.0],
              [-80.916745984965786, 35.440286811340151, 0.0],
              [-80.917222118612813, 35.441019478525696, 0.0],
              [-80.917669918116985, 35.441179611474517, 0.0],
              [-80.918593518247945, 35.441087678671501, 0.0],
              [-80.919181118668831, 35.440904211705465, 0.0],
              [-80.919432917715383, 35.440698011476073, 0.0],
              [-80.919460518295068, 35.440079678753072, 0.0],
              [-80.919236518753948, 35.439782011519718, 0.0],
              [-80.919124118486025, 35.439095011880674, 0.0],
              [-80.918760184600316, 35.438911878048472, 0.0],
              [-80.918592184332496, 35.438614211768666, 0.0],
              [-80.918284185071812, 35.438408077966329, 0.0],
              [-80.917052518722301, 35.437881811250229, 0.0],
              [-80.916660585173389, 35.437561411846325, 0.0],
              [-80.915261318102651, 35.437401611815268, 0.0],
              [-80.915009384695807, 35.437264211490877, 0.0],
              [-80.914925384654367, 35.437126811634926, 0.0],
              [-80.915036984846068, 35.436485478025496, 0.0],
              [-80.915540184436821, 35.435615011745348, 0.0],
              [-80.915707717748901, 35.434744678083163, 0.0],
              [-80.91629518494122, 35.434584078150408, 0.0],
              [-80.917022784573831, 35.434560878676848, 0.0],
              [-80.917218584445578, 35.434492078751852, 0.0],
              [-80.917218384997483, 35.434148611687078, 0.0],
              [-80.916994384662075, 35.433873878004704, 0.0],
              [-80.915595184680669, 35.433599478099069, 0.0],
              [-80.91439178487532, 35.433508278270907, 0.0],
              [-80.913915984423639, 35.433348211505972, 0.0],
              [-80.911453384455854, 35.433119877962014, 0.0],
              [-80.911453318699969, 35.432982478001719, 0.0],
              [-80.911705118086616, 35.432753411947353, 0.0],
              [-80.913271718179672, 35.431928411720605, 0.0],
              [-80.914111117920129, 35.431859478751448, 0.0],
              [-80.914027118697433, 35.431378611940374, 0.0],
              [-80.91425078508054, 35.431195278517507, 0.0],
              [-80.914698518610379, 35.431263811267129, 0.0],
              [-80.915174318721398, 35.431584277840322, 0.0],
              [-80.915650118306019, 35.431652811873256, 0.0],
              [-80.915650118483498, 35.4314694781092, 0.0],
              [-80.914502118279088, 35.430027077881249, 0.0],
              [-80.914473918330486, 35.429637811226961, 0.0],
              [-80.914585584393606, 35.429362811920555, 0.0],
              [-80.915117185022055, 35.42895041124153, 0.0],
              [-80.915564585131918, 35.428492211567793, 0.0],
              [-80.915844385002501, 35.428377611445782, 0.0],
              [-80.91604098458663, 35.42986627797665, 0.0],
              [-80.916264984462032, 35.43020967852803, 0.0],
              [-80.916349184903254, 35.430553211754408, 0.0],
              [-80.91657311847321, 35.43078221144318, 0.0],
              [-80.917048918462456, 35.430896478178553, 0.0],
              [-80.917300918162724, 35.431171211215485, 0.0],
              [-80.917329118704245, 35.431652211301817, 0.0],
              [-80.91755298541149, 35.431858211401689, 0.0],
              [-80.918196717711425, 35.431903811526766, 0.0],
              [-80.918336718217006, 35.431972411271872, 0.0],
              [-80.918336784246662, 35.432293078171604, 0.0],
              [-80.91928838535695, 35.432567677848496, 0.0],
              [-80.920211718403166, 35.432315278820091, 0.0],
              [-80.920379584387021, 35.432177878808389, 0.0],
              [-80.920463385123639, 35.431948811733449, 0.0],
              [-80.920015384964842, 35.431651211472257, 0.0],
              [-80.919987384524191, 35.431490878668335, 0.0],
              [-80.920351117992311, 35.43133041190714, 0.0],
              [-80.920882918187772, 35.431513478161506, 0.0],
              [-80.921358584424723, 35.431513211404564, 0.0],
              [-80.9213025185406, 35.431261278590554, 0.0],
              [-80.92071451789964, 35.43068901216764, 0.0],
              [-80.920769918190373, 35.429818678199496, 0.0],
              [-80.92099371838006, 35.429727011666259, 0.0],
              [-80.921721317899454, 35.430001478344614, 0.0],
              [-80.922169118491908, 35.430070078181245, 0.0],
              [-80.922280984976695, 35.429909677930468, 0.0],
              [-80.922196717929069, 35.429428677992853, 0.0],
              [-80.922056718250829, 35.429199811631733, 0.0],
              [-80.921216918777901, 35.428535878745016, 0.0],
              [-80.921132917709713, 35.428375611298762, 0.0],
              [-80.920572984899778, 35.427963611637452, 0.0],
              [-80.919593518698122, 35.427574678303323, 0.0],
              [-80.919593318216513, 35.427483078582554, 0.0],
              [-80.919761184442365, 35.427414278196316, 0.0],
              [-80.920320784636104, 35.427482811920349, 0.0],
              [-80.921944117837526, 35.428123411351663, 0.0],
              [-80.923119784753425, 35.428833012150776, 0.0],
              [-80.923987717805716, 35.429702878029836, 0.0],
              [-80.924575518322499, 35.429817211214093, 0.0],
              [-80.925022985244922, 35.429519278595599, 0.0],
              [-80.925498584584801, 35.4293586779755, 0.0],
              [-80.925722518268032, 35.429358611344917, 0.0],
              [-80.925722918001469, 35.42999987801835, 0.0],
              [-80.92608678518792, 35.430251677932084, 0.0],
              [-80.926142985151031, 35.430572278802849, 0.0],
              [-80.926450784628813, 35.430618011425743, 0.0],
              [-80.927457918110733, 35.430342611656897, 0.0],
              [-80.927569784630961, 35.430159411706001, 0.0],
              [-80.928297184446549, 35.429952877923839, 0.0],
              [-80.928688584517417, 35.42956341191686, 0.0],
              [-80.93014378508532, 35.429585611395176, 0.0],
              [-80.930255384731822, 35.429196278088078, 0.0],
              [-80.930563117992278, 35.429058678745214, 0.0],
              [-80.930898918616265, 35.429127277861816, 0.0],
              [-80.931066985340323, 35.429333278614386, 0.0],
              [-80.931067718319426, 35.430295211494389, 0.0],
              [-80.932327118562043, 35.43061521141923, 0.0],
              [-80.932718517547187, 35.430111077901707, 0.0],
              [-80.932857984820572, 35.429630078681065, 0.0],
              [-80.932969918455981, 35.429538411397772, 0.0],
              [-80.93327778421731, 35.429607078226333, 0.0],
              [-80.933389984444759, 35.430042078215891, 0.0],
              [-80.933753917744994, 35.430271011403924, 0.0],
              [-80.934005785237645, 35.430316611269987, 0.0],
              [-80.934089785319614, 35.430225011496859, 0.0],
              [-80.934509385228523, 35.430064411942467, 0.0],
              [-80.934677384704074, 35.430247678334602, 0.0],
              [-80.934565917580301, 35.430820212123237, 0.0],
              [-80.93431431865811, 35.431232611724091, 0.0],
              [-80.933027518451041, 35.431805811458688, 0.0],
              [-80.93157291847703, 35.432631078230067, 0.0],
              [-80.931489985149341, 35.433936478189906, 0.0],
              [-80.931797918580912, 35.434165478253625, 0.0],
              [-80.932525585377817, 35.434348277989514, 0.0],
              [-80.932665384987317, 35.43411927872981, 0.0],
              [-80.932861318173295, 35.434073278432891, 0.0],
              [-80.933225385124231, 35.434531211728377, 0.0],
              [-80.9338133184106, 35.434966011488235, 0.0],
              [-80.934233518332945, 35.435446811403615, 0.0],
              [-80.934317984314944, 35.436225411920361, 0.0],
              [-80.934122584642537, 35.436798012054062, 0.0],
              [-80.933871117872243, 35.437325011596805, 0.0],
              [-80.933871318153493, 35.437622811233823, 0.0],
              [-80.934179318425805, 35.437828678121896, 0.0],
              [-80.93473898434732, 35.437851277862563, 0.0],
              [-80.934934785350322, 35.437759611177029, 0.0],
              [-80.935466184714826, 35.437278411925028, 0.0],
              [-80.935745585116535, 35.43682021147454, 0.0],
              [-80.935829184449048, 35.436407878315933, 0.0],
              [-80.935297118452368, 35.435812678322982, 0.0],
              [-80.934932717616832, 35.434965478640628, 0.0],
              [-80.934931918326697, 35.433911877952525, 0.0],
              [-80.935043718446579, 35.433728611930803, 0.0],
              [-80.935239518578413, 35.433705611778159, 0.0],
              [-80.935491585313315, 35.433934478729924, 0.0],
              [-80.936694984728547, 35.434025611782232, 0.0],
              [-80.936694785323283, 35.433727877844873, 0.0],
              [-80.93647051869722, 35.433338611889631, 0.0],
              [-80.936666384715281, 35.433224011194106, 0.0],
              [-80.937729918502484, 35.433452478737962, 0.0],
              [-80.937786384704921, 35.43400207823094, 0.0],
              [-80.937534718490014, 35.434322878376207, 0.0],
              [-80.937450984481572, 35.434689278506781, 0.0],
              [-80.93781538455552, 35.435490811647995, 0.0],
              [-80.938403184418661, 35.435696611670636, 0.0],
              [-80.938571384632297, 35.435856811407412, 0.0],
              [-80.93871151759987, 35.436246078464009, 0.0],
              [-80.939159318104757, 35.436268678552864, 0.0],
              [-80.939270584883118, 35.435467078109404, 0.0],
              [-80.939941117727642, 35.434092411973658, 0.0],
              [-80.940165117641797, 35.434138212145463, 0.0],
              [-80.940361384912919, 35.434664878167986, 0.0],
              [-80.940418318112123, 35.435878611861, 0.0],
              [-80.940558317742372, 35.436015878282312, 0.0],
              [-80.940838185146447, 35.436015811659111, 0.0],
              [-80.941369784841399, 35.435878078815371, 0.0],
              [-80.941649718077713, 35.435923811805537, 0.0],
              [-80.941761717882287, 35.436038278498096, 0.0],
              [-80.941790117813909, 35.436587811531723, 0.0],
              [-80.941510584654239, 35.436931612068044, 0.0],
              [-80.940642984828131, 35.436977878530101, 0.0],
              [-80.94008371834741, 35.437459211296805, 0.0],
              [-80.940028184908826, 35.438054678761169, 0.0],
              [-80.939608584941496, 35.438146478418915, 0.0],
              [-80.93958078537203, 35.438444278123406, 0.0],
              [-80.939804984572703, 35.438925078317673, 0.0],
              [-80.939805384802412, 35.439268611373286, 0.0],
              [-80.939721518535066, 35.439337412134748, 0.0],
              [-80.93972191832988, 35.439749611400742, 0.0],
              [-80.940253584276292, 35.439841011838382, 0.0],
              [-80.9405611843125, 35.439566012062492, 0.0],
              [-80.940869118706104, 35.439634411409955, 0.0],
              [-80.941624584807911, 35.439634011716677, 0.0],
              [-80.942016584668309, 35.439771211714451, 0.0],
              [-80.942268584762161, 35.440000078775363, 0.0],
              [-80.942353918259457, 35.441488678014082, 0.0],
              [-80.942577984489319, 35.441786411342029, 0.0],
              [-80.942746384597342, 35.442267211766797, 0.0],
              [-80.943110518274622, 35.44261047848827, 0.0],
              [-80.943670384718729, 35.442839278791638, 0.0],
              [-80.943978784799413, 35.443411677889074, 0.0],
              [-80.944230917744861, 35.443594811327877, 0.0],
              [-80.944734385022073, 35.443434078564842, 0.0],
              [-80.94493058438772, 35.443686011857451, 0.0],
              [-80.945462518697184, 35.443800211846053, 0.0],
              [-80.94599398430087, 35.443548011792316, 0.0],
              [-80.946049384199171, 35.442952411606115, 0.0],
              [-80.945937184760339, 35.44267761162061, 0.0],
              [-80.944845118233431, 35.441876678519911, 0.0],
              [-80.943697518410715, 35.441762811828525, 0.0],
              [-80.943585517711085, 35.441671278755479, 0.0],
              [-80.943585318145907, 35.441488077846785, 0.0],
              [-80.944172584457391, 35.441006678321628, 0.0],
              [-80.944200118408332, 35.440548678491297, 0.0],
              [-80.944088118273484, 35.440411278218257, 0.0],
              [-80.944059784256211, 35.440045011218352, 0.0],
              [-80.944618984740856, 35.439563612025623, 0.0],
              [-80.944674584582742, 35.439059811283101, 0.0],
              [-80.944898317887777, 35.438739011194151, 0.0],
              [-80.945149984224557, 35.438624278706286, 0.0],
              [-80.946940984436537, 35.438531611692177, 0.0],
              [-80.947248718110544, 35.438416878600641, 0.0],
              [-80.947724118151342, 35.438004411528425, 0.0],
              [-80.947975718504978, 35.437660677966143, 0.0],
              [-80.949094185004412, 35.436766811193429, 0.0],
              [-80.949317984888509, 35.436675011426452, 0.0],
              [-80.950885518506425, 35.437040478086445, 0.0],
              [-80.951333317609496, 35.437017278115349, 0.0],
              [-80.951780918649717, 35.43687961158632, 0.0],
              [-80.952284184285674, 35.436444012055873, 0.0],
              [-80.951780318416212, 35.436238211714965, 0.0],
              [-80.950520984975526, 35.436307877844321, 0.0],
              [-80.950492918428083, 35.436101678654268, 0.0],
              [-80.951107984444363, 35.435528811988824, 0.0],
              [-80.951526918619493, 35.434703877992142, 0.0],
              [-80.951946384967698, 35.434451678083938, 0.0],
              [-80.952729917530945, 35.434405411910518, 0.0],
              [-80.95432531827926, 35.434702078794722, 0.0],
              [-80.956004984792514, 35.435433878707386, 0.0],
              [-80.956536784464731, 35.435479411740076, 0.0],
              [-80.956620584702947, 35.435273211735264, 0.0],
              [-80.956844384198632, 35.435204278478622, 0.0],
              [-80.957879784521054, 35.435318078648699, 0.0],
              [-80.957912184647526, 35.435318878817164, 0.0],
              [-80.959670917871364, 35.435362611424253, 0.0],
              [-80.959894784375962, 35.435454011522374, 0.0],
              [-80.960006985125048, 35.435614278027188, 0.0],
              [-80.96093078439759, 35.435957278808949, 0.0],
              [-80.961574585302159, 35.436094211497782, 0.0],
              [-80.962022585002259, 35.436391611464714, 0.0],
              [-80.961995718700109, 35.437262011659577, 0.0],
              [-80.96188411758105, 35.437674278106144, 0.0],
              [-80.960571185097564, 35.439850877964872, 0.0],
              [-80.960543717552284, 35.440331878062764, 0.0],
              [-80.96062771802093, 35.440377611573233, 0.0],
              [-80.960627984246386, 35.440583812050548, 0.0],
              [-80.96079631813781, 35.441018811309547, 0.0],
              [-80.960909185216551, 35.441889011408009, 0.0],
              [-80.961245518121132, 35.442369678784189, 0.0],
              [-80.961189917604173, 35.442690411958822, 0.0],
              [-80.960574918429472, 35.443400878042141, 0.0],
              [-80.960604384902183, 35.444797878449748, 0.0],
              [-80.960297385064791, 35.445622611428092, 0.0],
              [-80.960410985065494, 35.447271478083614, 0.0],
              [-80.960915584521544, 35.447981078034772, 0.0],
              [-80.960916318142949, 35.448576611413458, 0.0],
              [-80.960832584880691, 35.448714078170639, 0.0],
              [-80.960917517777276, 35.44967587876166, 0.0],
              [-80.960301917985788, 35.449859677843875, 0.0],
              [-80.960218185182114, 35.450042878577101, 0.0],
              [-80.96038631864765, 35.450248878024027, 0.0],
              [-80.962037917708429, 35.450476678458784, 0.0],
              [-80.962681585266907, 35.45038467783354, 0.0],
              [-80.963101184494349, 35.450201078591135, 0.0],
              [-80.963493317844154, 35.45040701166441, 0.0],
              [-80.963577985258695, 35.451002411907048, 0.0],
              [-80.96374611750133, 35.451185477918536, 0.0],
              [-80.964082385268185, 35.451597477904983, 0.0],
              [-80.964166717898351, 35.451895078459273, 0.0],
              [-80.964054918069309, 35.452009678202351, 0.0],
              [-80.96296278470831, 35.451644077948465, 0.0],
              [-80.961675384181248, 35.451690877966008, 0.0],
              [-80.961479585119861, 35.451805477867353, 0.0],
              [-80.960723918293382, 35.451897678789827, 0.0],
              [-80.960416317975529, 35.452104078737527, 0.0],
              [-80.96038871779632, 35.452493411292075, 0.0],
              [-80.961060984223238, 35.452951011335813, 0.0],
              [-80.960977317884414, 35.453271678204636, 0.0],
              [-80.960922718143877, 35.454645878102959, 0.0],
              [-80.960810917594159, 35.454691811326903, 0.0],
              [-80.960586784253195, 35.454577411882532, 0.0],
              [-80.960446384566794, 35.454119477915249, 0.0],
              [-80.960137984211428, 35.453615811505522, 0.0],
              [-80.959297517895976, 35.452906478311256, 0.0],
              [-80.959073518093092, 35.452860811327959, 0.0],
              [-80.958457785043933, 35.452929878235373, 0.0],
              [-80.957869518038606, 35.452403478383147, 0.0],
              [-80.957029918433548, 35.452518678601614, 0.0],
              [-80.956498518169141, 35.452908411507202, 0.0],
              [-80.956190918518431, 35.453297878229336, 0.0],
              [-80.954763784330055, 35.453642477971989, 0.0],
              [-80.954540118491536, 35.453848678334097, 0.0],
              [-80.95440051806267, 35.454306878155457, 0.0],
              [-80.953812984797622, 35.454513277873481, 0.0],
              [-80.953757584902306, 35.455063078378593, 0.0],
              [-80.954093918003991, 35.455383478436602, 0.0],
              [-80.954149984461026, 35.455635411415237, 0.0],
              [-80.953870518240038, 35.456024878292951, 0.0],
              [-80.953507317841641, 35.456803811766171, 0.0],
              [-80.953535718336511, 35.457147411467851, 0.0],
              [-80.95375971851395, 35.457238811276476, 0.0],
              [-80.954235517829318, 35.4571468780834, 0.0],
              [-80.954403318066312, 35.456963611522553, 0.0],
              [-80.954514517630003, 35.456299278053621, 0.0],
              [-80.954990318328427, 35.456138678523658, 0.0],
              [-80.955662317916563, 35.456321411778355, 0.0],
              [-80.955802517729794, 35.456619077849219, 0.0],
              [-80.956501984510595, 35.456366678357355, 0.0],
              [-80.957005584608183, 35.456045677954194, 0.0],
              [-80.957228984475222, 35.455724811377067, 0.0],
              [-80.95770518498253, 35.455953611451157, 0.0],
              [-80.958040917714129, 35.455815878064676, 0.0],
              [-80.958152785181824, 35.455678411448446, 0.0],
              [-80.958964717810858, 35.45592981130828, 0.0],
              [-80.96016858508284, 35.456135078263308, 0.0],
              [-80.960168984294242, 35.456524411850332, 0.0],
              [-80.959021517651067, 35.456616811669363, 0.0],
              [-80.958517918490784, 35.456846211540167, 0.0],
              [-80.958070518028663, 35.457258812004746, 0.0],
              [-80.957790984625461, 35.457694078707313, 0.0],
              [-80.957791518116665, 35.458198011686427, 0.0],
              [-80.957875585053188, 35.458266611827781, 0.0],
              [-80.957904118275465, 35.45879341148575, 0.0],
              [-80.958240185363692, 35.45897641200812, 0.0],
              [-80.959919784880455, 35.459066811457276, 0.0],
              [-80.96011578455213, 35.459158278077012, 0.0],
              [-80.960032318062673, 35.45959347819781, 0.0],
              [-80.959528718679621, 35.459799878007487, 0.0],
              [-80.957485384219368, 35.459915878366566, 0.0],
              [-80.956645917612263, 35.460283011356516, 0.0],
              [-80.956562185176622, 35.460466211779348, 0.0],
              [-80.956759318115161, 35.461725811819207, 0.0],
              [-80.95692738487628, 35.461794278347902, 0.0],
              [-80.957850984177625, 35.461679077898914, 0.0],
              [-80.95804711841609, 35.461770611809563, 0.0],
              [-80.958550918167091, 35.461678677935581, 0.0],
              [-80.959306184559509, 35.461197211507638, 0.0],
              [-80.960033785307076, 35.461013477831912, 0.0],
              [-80.96067791789045, 35.461219078523243, 0.0],
              [-80.960650518575136, 35.461860478417464, 0.0],
              [-80.960146784515274, 35.46199821155529, 0.0],
              [-80.960147384959726, 35.462479078162971, 0.0],
              [-80.96048411762898, 35.463303278256163, 0.0],
              [-80.960541117994111, 35.464196611664391, 0.0],
              [-80.961101517756362, 35.464745811708092, 0.0],
              [-80.961829184876365, 35.464653678564325, 0.0],
              [-80.961828518072977, 35.463920811884826, 0.0],
              [-80.96263978440254, 35.463530877858886, 0.0],
              [-80.96263978518769, 35.463393478383225, 0.0],
              [-80.962303517864797, 35.463096011552665, 0.0],
              [-80.962274984235918, 35.462615011481624, 0.0],
              [-80.962638785273342, 35.462477411852994, 0.0],
              [-80.963394384459107, 35.462339411248543, 0.0],
              [-80.964234384624291, 35.462499078760743, 0.0],
              [-80.964822185164508, 35.462407011945992, 0.0],
              [-80.964821984344596, 35.462246677888245, 0.0],
              [-80.96470991864166, 35.462155078123118, 0.0],
              [-80.964737184258709, 35.46149087840525, 0.0],
              [-80.96482111825496, 35.461445078294652, 0.0],
              [-80.964568384400565, 35.460689478105373, 0.0],
              [-80.963839784798978, 35.460094478764141, 0.0],
              [-80.963755518689737, 35.45968227833599, 0.0],
              [-80.963923385355386, 35.459613477985094, 0.0],
              [-80.96532351806367, 35.460093412025849, 0.0],
              [-80.965911184308027, 35.45997847799503, 0.0],
              [-80.966526318328633, 35.459428278704081, 0.0],
              [-80.966637985062491, 35.459176278451636, 0.0],
              [-80.96649698423721, 35.458283278347025, 0.0],
              [-80.966972718610307, 35.458099678221778, 0.0],
              [-80.966944384718857, 35.457824878290324, 0.0],
              [-80.966692118203738, 35.457504411275742, 0.0],
              [-80.966690984425085, 35.456519611528421, 0.0],
              [-80.966802785260541, 35.456336278021872, 0.0],
              [-80.967054785159604, 35.456381878512637, 0.0],
              [-80.967251384400669, 35.457023011974727, 0.0],
              [-80.968007784724762, 35.45754921132913, 0.0],
              [-80.968763584188565, 35.45752567843374, 0.0],
              [-80.969407117644593, 35.457341878251036, 0.0],
              [-80.970134385020785, 35.45688327871418, 0.0],
              [-80.97080618433408, 35.456882678166693, 0.0],
              [-80.971170317557906, 35.457042811974944, 0.0],
              [-80.971310518093489, 35.457226011744773, 0.0],
              [-80.970807317619204, 35.45784467796647, 0.0],
              [-80.97083571779946, 35.458188211580143, 0.0],
              [-80.97005311784207, 35.459196478079932, 0.0],
              [-80.970081585131538, 35.45960881117243, 0.0],
              [-80.971089784278121, 35.460020211762661, 0.0],
              [-80.9709501843395, 35.460363878533627, 0.0],
              [-80.970362918472617, 35.460868211426622, 0.0],
              [-80.969888184456707, 35.461693078450004, 0.0],
              [-80.969972517668097, 35.461967812003891, 0.0],
              [-80.9701125178198, 35.462082211849541, 0.0],
              [-80.97072818429065, 35.461944278590515, 0.0],
              [-80.970951785181143, 35.461715078186955, 0.0],
              [-80.970755184918787, 35.461119811536797, 0.0],
              [-80.970978984710868, 35.460959278054226, 0.0],
              [-80.971342717787479, 35.46086741117707, 0.0],
              [-80.971426118523794, 35.460363477954871, 0.0],
              [-80.971537984743549, 35.460248878470573, 0.0],
              [-80.972181717941837, 35.460179678627142, 0.0],
              [-80.972573384224887, 35.45997321152813, 0.0],
              [-80.973021317900148, 35.460041611538905, 0.0],
              [-80.973300984707663, 35.459858078768654, 0.0],
              [-80.973636984992709, 35.459926611772545, 0.0],
              [-80.973581584414717, 35.460430478098083, 0.0],
              [-80.973246184968545, 35.46086587797727, 0.0],
              [-80.973106918296892, 35.461301077937762, 0.0],
              [-80.97319111806577, 35.461530078122912, 0.0],
              [-80.973107184997119, 35.461644678714237, 0.0],
              [-80.973135784472674, 35.462125611184412, 0.0],
              [-80.973360184342553, 35.462446011195318, 0.0],
              [-80.973639984601462, 35.462331278218535, 0.0],
              [-80.973779718116461, 35.46212507805015, 0.0],
              [-80.974367317984331, 35.46187267875289, 0.0],
              [-80.974757918487072, 35.460910411313847, 0.0],
              [-80.974954118379259, 35.461070611139469, 0.0],
              [-80.975095918357439, 35.462559078208223, 0.0],
              [-80.975235918471768, 35.462627811776038, 0.0],
              [-80.975767718234891, 35.46255847857276, 0.0],
              [-80.976186584692499, 35.461710812107761, 0.0],
              [-80.976326517749456, 35.461664812073636, 0.0],
              [-80.976943118470814, 35.462236878690803, 0.0],
              [-80.977278984779829, 35.462282477997348, 0.0],
              [-80.977642785047635, 35.462190478658862, 0.0],
              [-80.977726584510847, 35.462075878014168, 0.0],
              [-80.977670184402285, 35.461686611864074, 0.0],
              [-80.977529985125003, 35.461572278082755, 0.0],
              [-80.977250318223142, 35.461664078223087, 0.0],
              [-80.976942385236669, 35.46166427822309, 0.0],
              [-80.976662118520466, 35.461412678762301, 0.0],
              [-80.976577717523725, 35.461115011770175, 0.0],
              [-80.977024785346302, 35.460450411578833, 0.0],
              [-80.97705218417704, 35.45999241154307, 0.0],
              [-80.977275985150669, 35.459877678623563, 0.0],
              [-80.97755611790825, 35.459991877916408, 0.0],
              [-80.977780784899792, 35.460633011389227, 0.0],
              [-80.978229318677592, 35.461022011721951, 0.0],
              [-80.979544718164661, 35.460883478528856, 0.0],
              [-80.979628584229786, 35.460791811237755, 0.0],
              [-80.979683518518115, 35.459990078080445, 0.0],
              [-80.980103584661848, 35.460104278231626, 0.0],
              [-80.980495785006539, 35.460333011219049, 0.0],
              [-80.981195584669607, 35.460309411581754, 0.0],
              [-80.981754984707592, 35.460056877836202, 0.0],
              [-80.982202517758708, 35.459667212095887, 0.0],
              [-80.982313584679261, 35.459048678458785, 0.0],
              [-80.98225718430345, 35.458705278697764, 0.0],
              [-80.982369118388974, 35.458636411268195, 0.0],
              [-80.983377718006068, 35.45927681129708, 0.0],
              [-80.984021585007611, 35.459390677973168, 0.0],
              [-80.985392984611281, 35.45932081128116, 0.0],
              [-80.98606518526087, 35.459595011253448, 0.0],
              [-80.986933317727278, 35.459777278544145, 0.0],
              [-80.987604984521525, 35.45970801173771, 0.0],
              [-80.988416317911302, 35.459409478038303, 0.0],
              [-80.988920318368301, 35.459454811283294, 0.0],
              [-80.989116317801518, 35.459546277975036, 0.0],
              [-80.98911671772936, 35.459798212117612, 0.0],
              [-80.988557117863152, 35.459936211556034, 0.0],
              [-80.98858518474573, 35.460050611568136, 0.0],
              [-80.988809317598651, 35.460142078414357, 0.0],
              [-80.988781718372024, 35.460416878510337, 0.0],
              [-80.988697785299905, 35.460485678690404, 0.0],
              [-80.987857717649447, 35.460326211715007, 0.0],
              [-80.986682184453798, 35.460281478447371, 0.0],
              [-80.984414917845484, 35.46042101183761, 0.0],
              [-80.983603185136147, 35.460536211514984, 0.0],
              [-80.983351584558022, 35.46076547826312, 0.0],
              [-80.983184118060024, 35.461086277969692, 0.0],
              [-80.982568384714455, 35.461178411178288, 0.0],
              [-80.982400785225806, 35.461453411201205, 0.0],
              [-80.982849784322809, 35.462300411761376, 0.0],
              [-80.98321418509704, 35.462643678741792, 0.0],
              [-80.983187384592966, 35.46362847800826, 0.0],
              [-80.98355178497107, 35.463880078527311, 0.0],
              [-80.983747717654268, 35.463925678318923, 0.0],
              [-80.985008784516154, 35.464886478745541, 0.0],
              [-80.985653184180933, 35.465252278741907, 0.0],
              [-80.985765384138787, 35.465481278115099, 0.0],
              [-80.986017718537411, 35.465687211626708, 0.0],
              [-80.986885918355711, 35.465961211550855, 0.0],
              [-80.986803918563041, 35.467404078465194, 0.0],
              [-80.987448517671439, 35.46793027873359, 0.0],
              [-80.989100584873043, 35.468180611591094, 0.0],
              [-80.991174518616958, 35.469781811661839, 0.0],
              [-80.992071184468941, 35.470330611431457, 0.0],
              [-80.991931517763874, 35.470536811969069, 0.0],
              [-80.991707584686083, 35.470514211121817, 0.0],
              [-80.990446918138801, 35.469965678274974, 0.0],
              [-80.989521984148737, 35.469256611504029, 0.0],
              [-80.988625717851207, 35.46889101202818, 0.0],
              [-80.988065918254094, 35.468937477922545, 0.0],
              [-80.987925984274739, 35.46905207785268, 0.0],
              [-80.987927784636227, 35.470243011175505, 0.0],
              [-80.988096384146885, 35.470700877843541, 0.0],
              [-80.988040718269517, 35.470907078732345, 0.0],
              [-80.987844718456657, 35.470884411715353, 0.0],
              [-80.987648384470489, 35.470724211795215, 0.0],
              [-80.987507985179633, 35.470426612091359, 0.0],
              [-80.987366717720576, 35.469419078054663, 0.0],
              [-80.987198518138342, 35.469235878401619, 0.0],
              [-80.986973184801144, 35.4684346117809, 0.0],
              [-80.986496985121988, 35.468206011519626, 0.0],
              [-80.986328517769678, 35.467771011156294, 0.0],
              [-80.986104184669713, 35.467519278087124, 0.0],
              [-80.986075184302791, 35.466832211136456, 0.0],
              [-80.985738984838235, 35.466626412011429, 0.0],
              [-80.985123117908643, 35.466604078706538, 0.0],
              [-80.98489878452456, 35.466421077986908, 0.0],
              [-80.984311184426232, 35.466513211949945, 0.0],
              [-80.983946984811411, 35.46639901129484, 0.0],
              [-80.983359184450009, 35.46649107779627, 0.0],
              [-80.983107917482528, 35.466880678650426, 0.0],
              [-80.982883784649843, 35.466858011686867, 0.0],
              [-80.982743585191272, 35.466697811142346, 0.0],
              [-80.982659118264891, 35.466285611277641, 0.0],
              [-80.982434984952192, 35.466194278094768, 0.0],
              [-80.982211318594963, 35.466400478149751, 0.0],
              [-80.981959317683859, 35.46633207836048, 0.0],
              [-80.981818584581205, 35.465782611727079, 0.0],
              [-80.981090184386062, 35.465416811442125, 0.0],
              [-80.980642384502204, 35.465508811751462, 0.0],
              [-80.980447518252305, 35.46619601123335, 0.0],
              [-80.979691718507667, 35.46628827803297, 0.0],
              [-80.979495717825742, 35.466196878086777, 0.0],
              [-80.979438585340915, 35.465395411507401, 0.0],
              [-80.979102117564793, 35.464983412045811, 0.0],
              [-80.978738317953017, 35.465098211222703, 0.0],
              [-80.978150984791213, 35.465465211379403, 0.0],
              [-80.977061117938092, 35.467000478690274, 0.0],
              [-80.977145517815899, 35.467366878507605, 0.0],
              [-80.977649984850586, 35.467755811136449, 0.0],
              [-80.977622518475485, 35.468213878699224, 0.0],
              [-80.977034985254377, 35.468512211675183, 0.0],
              [-80.976726984504467, 35.468512477920939, 0.0],
              [-80.976586917555949, 35.468352278184611, 0.0],
              [-80.97625091819242, 35.468352478356081, 0.0],
              [-80.976251318466822, 35.468604478470908, 0.0],
              [-80.976447384238469, 35.468810411583341, 0.0],
              [-80.9764761846548, 35.46936007787145, 0.0],
              [-80.97728871803362, 35.469863211212314, 0.0],
              [-80.977232918388339, 35.470092211508216, 0.0],
              [-80.977037184178684, 35.470275811705932, 0.0],
              [-80.976953718588561, 35.470642211319458, 0.0],
              [-80.976869784435664, 35.470711011717313, 0.0],
              [-80.976673717681578, 35.470665411752336, 0.0],
              [-80.976561385112674, 35.470321878735263, 0.0],
              [-80.975944984309052, 35.469978878770924, 0.0],
              [-80.975888518478158, 35.469589611649965, 0.0],
              [-80.975524584163907, 35.469544078297531, 0.0],
              [-80.97524471861675, 35.469658878295178, 0.0],
              [-80.974880717535484, 35.469590411274893, 0.0],
              [-80.974908318058624, 35.469292677959757, 0.0],
              [-80.975048118000302, 35.469109278302632, 0.0],
              [-80.975047785004875, 35.468857411375595, 0.0],
              [-80.974347517720275, 35.468560211418911, 0.0],
              [-80.974095117526403, 35.468239812070323, 0.0],
              [-80.973647317505524, 35.468308877966784, 0.0],
              [-80.97317191837638, 35.468675811237105, 0.0],
              [-80.972919784475408, 35.468653077979013, 0.0],
              [-80.973003518160283, 35.468355277996857, 0.0],
              [-80.973254984538457, 35.468011478587073, 0.0],
              [-80.973170384680387, 35.467461878504821, 0.0],
              [-80.972833717691245, 35.46682087858801, 0.0],
              [-80.972497518511929, 35.466615011742896, 0.0],
              [-80.971965784856565, 35.466752877833429, 0.0],
              [-80.971769985019151, 35.466913411553023, 0.0],
              [-80.971378784631227, 35.467600678288157, 0.0],
              [-80.971407784202398, 35.46844807799544, 0.0],
              [-80.971491917993831, 35.468585478099754, 0.0],
              [-80.971436518139654, 35.469020611763355, 0.0],
              [-80.971324718535101, 35.469135278713011, 0.0],
              [-80.971044584686169, 35.469066878246053, 0.0],
              [-80.970680184510002, 35.468723612096682, 0.0],
              [-80.969363517567928, 35.467900078350532, 0.0],
              [-80.969167117897541, 35.467579611618071, 0.0],
              [-80.968942518078521, 35.466892811423733, 0.0],
              [-80.968633784479465, 35.466343278596554, 0.0],
              [-80.968465717603877, 35.466206011841528, 0.0],
              [-80.968437584764644, 35.46604581129121, 0.0],
              [-80.968017118156297, 35.465633811734939, 0.0],
              [-80.967821118180467, 35.465542411893296, 0.0],
              [-80.96726131835571, 35.465634411823778, 0.0],
              [-80.967037517571455, 35.465749078265354, 0.0],
              [-80.966869984250124, 35.466184411435314, 0.0],
              [-80.966898384485205, 35.466573678474859, 0.0],
              [-80.967122718438702, 35.466848411446129, 0.0],
              [-80.967235584915059, 35.467718477904505, 0.0],
              [-80.967459985231883, 35.468107677796546, 0.0],
              [-80.967376584609937, 35.468543011741431, 0.0],
              [-80.967236917995592, 35.468772011168113, 0.0],
              [-80.966537584776731, 35.469276411424332, 0.0],
              [-80.965473718020846, 35.46923147849472, 0.0],
              [-80.965054184734754, 35.469575411726396, 0.0],
              [-80.96435458523392, 35.469896478245097, 0.0],
              [-80.964158918352965, 35.470125811155711, 0.0],
              [-80.964187918060205, 35.471018878085893, 0.0],
              [-80.964664318165347, 35.471453678407293, 0.0],
              [-80.964748918490912, 35.472049078509528, 0.0],
              [-80.964889117712275, 35.472209278500387, 0.0],
              [-80.96631698443862, 35.472231078584635, 0.0],
              [-80.967044718054737, 35.472093078287614, 0.0],
              [-80.967380518368429, 35.471909611620198, 0.0],
              [-80.967632718474746, 35.472069677798238, 0.0],
              [-80.967717184778422, 35.472550611747586, 0.0],
              [-80.967829318278916, 35.472665078315075, 0.0],
              [-80.968781384998067, 35.472847611157142, 0.0],
              [-80.969061118580427, 35.472687011405618, 0.0],
              [-80.969284785020335, 35.472389077947334, 0.0],
              [-80.969817317549513, 35.472778011372242, 0.0],
              [-80.969845784991151, 35.4733276784916, 0.0],
              [-80.96998598463135, 35.473465011987997, 0.0],
              [-80.970321984306338, 35.473533411757749, 0.0],
              [-80.971049917658874, 35.473441278509682, 0.0],
              [-80.971469984521718, 35.473601211881821, 0.0],
              [-80.972169784546892, 35.473463211497481, 0.0],
              [-80.972477985253335, 35.473669078645813, 0.0],
              [-80.97301071813385, 35.474287011182746, 0.0],
              [-80.973430917668423, 35.474538611624958, 0.0],
              [-80.974858718179888, 35.474445811789387, 0.0],
              [-80.975278317719187, 35.474079011551616, 0.0],
              [-80.975614784249402, 35.474491011196335, 0.0],
              [-80.975614918671596, 35.474674211912102, 0.0],
              [-80.975951318549534, 35.474902878570951, 0.0],
              [-80.976763318066716, 35.475039678676723, 0.0],
              [-80.977827718071097, 35.475382411689033, 0.0],
              [-80.978108184966786, 35.475794278324329, 0.0],
              [-80.978388518639704, 35.476000212037135, 0.0],
              [-80.979172717585115, 35.476251411975312, 0.0],
              [-80.980770518658986, 35.477670011644079, 0.0],
              [-80.980742585038968, 35.477807411249756, 0.0],
              [-80.980434717716307, 35.477807678232196, 0.0],
              [-80.979678184870508, 35.477419011336586, 0.0],
              [-80.979146317717948, 35.477419478723732, 0.0],
              [-80.978977918252184, 35.47712187864618, 0.0],
              [-80.978137184339658, 35.476573011787799, 0.0],
              [-80.977156918179404, 35.476321878035556, 0.0],
              [-80.976456518549909, 35.475956011762008, 0.0],
              [-80.975672584807199, 35.476025411144789, 0.0],
              [-80.975140518627569, 35.475842611212414, 0.0],
              [-80.973460717750712, 35.475912811524871, 0.0],
              [-80.973237384588728, 35.476485411594702, 0.0],
              [-80.973322785193616, 35.477653411942534, 0.0],
              [-80.973070785003856, 35.477630678390859, 0.0],
              [-80.972846185063162, 35.477173011523497, 0.0],
              [-80.97245391814495, 35.476852611532607, 0.0],
              [-80.972509384474506, 35.476440278658444, 0.0],
              [-80.972117184920563, 35.476280278660433, 0.0],
              [-80.972116917935793, 35.476074211652445, 0.0],
              [-80.972256784586136, 35.475913678417058, 0.0],
              [-80.9722281844778, 35.475524478420539, 0.0],
              [-80.972004184185963, 35.475433011293639, 0.0],
              [-80.971220318627672, 35.475525211979466, 0.0],
              [-80.970632517638791, 35.475686011685035, 0.0],
              [-80.970213517659687, 35.476419278600929, 0.0],
              [-80.970213784617371, 35.47673987811249, 0.0],
              [-80.970578184320885, 35.477106078346061, 0.0],
              [-80.970578517860346, 35.477335077914375, 0.0],
              [-80.970242517918905, 35.477335278615421, 0.0],
              [-80.969738185053586, 35.477038011979033, 0.0],
              [-80.969038118443478, 35.476924012005384, 0.0],
              [-80.968953984262072, 35.476809611244882, 0.0],
              [-80.968868984282977, 35.475916411462116, 0.0],
              [-80.968588718493564, 35.475756278148957, 0.0],
              [-80.96811271822034, 35.475642211598, 0.0],
              [-80.967664384249943, 35.475367678341058, 0.0],
              [-80.967076518597125, 35.475436811661666, 0.0],
              [-80.966740718488651, 35.475597411428119, 0.0],
              [-80.966685718648606, 35.476422011146397, 0.0],
              [-80.96651791843675, 35.476605412073326, 0.0],
              [-80.964922385088613, 35.476973011561228, 0.0],
              [-80.964782584198659, 35.47708767837711, 0.0],
              [-80.964670918192525, 35.477385478681583, 0.0],
              [-80.964306985259711, 35.477385678080452, 0.0],
              [-80.964110784824513, 35.477271278476422, 0.0],
              [-80.963466918632847, 35.477317677980444, 0.0],
              [-80.96329878522009, 35.477157478458963, 0.0],
              [-80.963157185160455, 35.475806278251021, 0.0],
              [-80.963492784293194, 35.475485411257885, 0.0],
              [-80.963380584596209, 35.475325211564872, 0.0],
              [-80.96248431818708, 35.474959411480818, 0.0],
              [-80.962287918499186, 35.474547278332693, 0.0],
              [-80.961923785186244, 35.474364278529585, 0.0],
              [-80.961223585077775, 35.474273211282338, 0.0],
              [-80.960971784173793, 35.474365011641453, 0.0],
              [-80.96091678492607, 35.475235278127812, 0.0],
              [-80.960609318078497, 35.475579077834489, 0.0],
              [-80.960581784578579, 35.476174678320447, 0.0],
              [-80.960441918214485, 35.476243411553511, 0.0],
              [-80.95971351825537, 35.475831811836933, 0.0],
              [-80.959040985354065, 35.475259611592953, 0.0],
              [-80.958676918283842, 35.47509947853969, 0.0],
              [-80.958340984626275, 35.475168478064646, 0.0],
              [-80.957865585148056, 35.475833011535556, 0.0],
              [-80.957921984752687, 35.476153611418823, 0.0],
              [-80.958678185059824, 35.47642787829777, 0.0],
              [-80.958622784654892, 35.476977611441228, 0.0],
              [-80.958510785078275, 35.477069278202528, 0.0],
              [-80.95795098490737, 35.477184211583555, 0.0],
              [-80.957391118056762, 35.477184611142988, 0.0],
              [-80.95699891832237, 35.477070411374484, 0.0],
              [-80.956214385276127, 35.476498278225321, 0.0],
              [-80.955850584662912, 35.476590211589112, 0.0],
              [-80.955710918550807, 35.476865011637827, 0.0],
              [-80.955711184822107, 35.477231478586674, 0.0],
              [-80.956887984426558, 35.478009411887655, 0.0],
              [-80.958259984636342, 35.47816881186364, 0.0],
              [-80.958624384217487, 35.478535011577918, 0.0],
              [-80.958624517916334, 35.47862661195569, 0.0],
              [-80.959212917863738, 35.479038411515518, 0.0],
              [-80.960052784876211, 35.478946278658853, 0.0],
              [-80.960248917944895, 35.479060611834448, 0.0],
              [-80.960361584977534, 35.479724677961947, 0.0],
              [-80.96058591821587, 35.480045211845216, 0.0],
              [-80.960530718708753, 35.480778078294215, 0.0],
              [-80.960335117745188, 35.481190478555199, 0.0],
              [-80.960167184635253, 35.48128227862712, 0.0],
              [-80.959859185081271, 35.481282411462935, 0.0],
              [-80.958962184779963, 35.480366878333328, 0.0],
              [-80.957589917674554, 35.479978477930807, 0.0],
              [-80.957197917988097, 35.480070411106269, 0.0],
              [-80.957198184584527, 35.480253611757917, 0.0],
              [-80.957394318290554, 35.480390878228022, 0.0],
              [-80.957338718027046, 35.480757411346545, 0.0],
              [-80.957170785157004, 35.480917878000248, 0.0],
              [-80.956694917573429, 35.481009678028762, 0.0],
              [-80.956779584621671, 35.481673878527957, 0.0],
              [-80.956583984976959, 35.482017478215262, 0.0],
              [-80.956472584446956, 35.482659011124277, 0.0],
              [-80.956360918237749, 35.482865211232237, 0.0],
              [-80.956108985314188, 35.482957011806839, 0.0],
              [-80.955604918508186, 35.48291147838107, 0.0],
              [-80.955128584915585, 35.482522411909024, 0.0],
              [-80.95454058426391, 35.482614478409246, 0.0],
              [-80.954204318182406, 35.482317011351448, 0.0],
              [-80.953252317906845, 35.482363411537513, 0.0],
              [-80.953000718240958, 35.482684077868697, 0.0],
              [-80.952917117872332, 35.483188011602628, 0.0],
              [-80.952497717556824, 35.483875411230272, 0.0],
              [-80.95112631813204, 35.484494678020425, 0.0],
              [-80.951098918502595, 35.48513601173407, 0.0],
              [-80.951210984606192, 35.485227611993793, 0.0],
              [-80.951826984831129, 35.485204211566817, 0.0],
              [-80.952274585267702, 35.484791678021097, 0.0],
              [-80.952666517592391, 35.484631077936463, 0.0],
              [-80.953114584441494, 35.484676611267943, 0.0],
              [-80.953590984923309, 35.485157277839846, 0.0],
              [-80.954291318510812, 35.485454611229791, 0.0],
              [-80.954263718531664, 35.485798078365377, 0.0],
              [-80.953787984914257, 35.486119078137889, 0.0],
              [-80.952865184979331, 35.487516677868136, 0.0],
              [-80.95286571830411, 35.487906011833601, 0.0],
              [-80.953929518348374, 35.487699211537183, 0.0],
              [-80.954489318699942, 35.487447011281375, 0.0],
              [-80.954964984517019, 35.487034411695596, 0.0],
              [-80.95518878503681, 35.486942611179565, 0.0],
              [-80.955916784819493, 35.486873412008322, 0.0],
              [-80.955971584353676, 35.485613811421203, 0.0],
              [-80.956195384248815, 35.485361678697657, 0.0],
              [-80.956867585166179, 35.485544411639822, 0.0],
              [-80.957456117553889, 35.486070678579914, 0.0],
              [-80.957624585079174, 35.486505811956746, 0.0],
              [-80.9579607847392, 35.486688678563532, 0.0],
              [-80.958296785226963, 35.4866198112097, 0.0],
              [-80.958463718487252, 35.485566211210333, 0.0],
              [-80.958603584322034, 35.485382811454485, 0.0],
              [-80.958799585271706, 35.485451411104876, 0.0],
              [-80.959303517653993, 35.485313678564253, 0.0],
              [-80.959471317957153, 35.485107478225181, 0.0],
              [-80.959358518461656, 35.484328812054116, 0.0],
              [-80.959470184415977, 35.484031011674467, 0.0],
              [-80.959721984528045, 35.483847611736152, 0.0],
              [-80.96022611775669, 35.483984678250067, 0.0],
              [-80.960226518248632, 35.484259478130049, 0.0],
              [-80.960478718290048, 35.484465411635881, 0.0],
              [-80.960926518699168, 35.484373478330518, 0.0],
              [-80.961150918674647, 35.484602411479123, 0.0],
              [-80.961403785065343, 35.485518278409998, 0.0],
              [-80.961796117687015, 35.485792878590374, 0.0],
              [-80.962216118169138, 35.485792478406964, 0.0],
              [-80.962243784386345, 35.485517678605945, 0.0],
              [-80.962327918498517, 35.485448877885048, 0.0],
              [-80.962243318374618, 35.484968078301726, 0.0],
              [-80.961290518303997, 35.484235877899835, 0.0],
              [-80.961065984434825, 35.483869478341923, 0.0],
              [-80.960309384858775, 35.483343278384318, 0.0],
              [-80.96033678458906, 35.482862411733805, 0.0],
              [-80.960812718270418, 35.482701678054106, 0.0],
              [-80.961316918522414, 35.482884477988037, 0.0],
              [-80.962185718629229, 35.483616877980751, 0.0],
              [-80.963277984445455, 35.483799211221559, 0.0],
              [-80.963194717830959, 35.484486278495119, 0.0],
              [-80.963418918525605, 35.484669278295371, 0.0],
              [-80.963810785072894, 35.484577478496455, 0.0],
              [-80.964706117926553, 35.483935611773958, 0.0],
              [-80.964677718281138, 35.483454611101735, 0.0],
              [-80.96439738520337, 35.483225811199773, 0.0],
              [-80.964480918547423, 35.482813477970716, 0.0],
              [-80.96487311864098, 35.482950611394607, 0.0],
              [-80.965236984650545, 35.482927478089756, 0.0],
              [-80.96487198441433, 35.481988678489742, 0.0],
              [-80.964423384422815, 35.481485212057549, 0.0],
              [-80.964562785341457, 35.480912478258091, 0.0],
              [-80.965655518646912, 35.481507211940318, 0.0],
              [-80.965935784643932, 35.481804677985089, 0.0],
              [-80.96593651771461, 35.482377278524801, 0.0],
              [-80.966244584283686, 35.482537278371048, 0.0],
              [-80.96722431868001, 35.48226181146206, 0.0],
              [-80.967336517950272, 35.482399077863583, 0.0],
              [-80.967420985201059, 35.482857078264281, 0.0],
              [-80.967309384826905, 35.483154878569387, 0.0],
              [-80.967338117965582, 35.483842011385882, 0.0],
              [-80.967562384771924, 35.484047878094195, 0.0],
              [-80.967926318110614, 35.48400181187295, 0.0],
              [-80.967925785325221, 35.483543811492595, 0.0],
              [-80.967813717747987, 35.483406411273116, 0.0],
              [-80.967896718029181, 35.48255901162068, 0.0],
              [-80.968175518305728, 35.481459411670905, 0.0],
              [-80.968398917828424, 35.480978277937297, 0.0],
              [-80.968622984980541, 35.481115478024122, 0.0],
              [-80.968735318140077, 35.481321611245278, 0.0],
              [-80.968792718071469, 35.482558211711599, 0.0],
              [-80.969072917824732, 35.482764078003392, 0.0],
              [-80.969548918191407, 35.482695011984703, 0.0],
              [-80.969689118111802, 35.482786611546651, 0.0],
              [-80.96974518466115, 35.482946811805554, 0.0],
              [-80.969941318653696, 35.48301541113505, 0.0],
              [-80.970165118409057, 35.482923612077641, 0.0],
              [-80.970220584352376, 35.48244261117221, 0.0],
              [-80.970388518415987, 35.482305078604867, 0.0],
              [-80.970640584928148, 35.482488078601698, 0.0],
              [-80.970696985049145, 35.482739878670166, 0.0],
              [-80.970893185244776, 35.482991677767728, 0.0],
              [-80.971593317552291, 35.483037011565372, 0.0],
              [-80.972321117718806, 35.482853211538838, 0.0],
              [-80.97288111851455, 35.48285267840874, 0.0],
              [-80.973105384839087, 35.483035811557599, 0.0],
              [-80.972797717567218, 35.483310811380683, 0.0],
              [-80.972041984954771, 35.483632078417372, 0.0],
              [-80.971790385305169, 35.483861277792265, 0.0],
              [-80.971342317688453, 35.483815878438911, 0.0],
              [-80.971062117968899, 35.483678678325404, 0.0],
              [-80.969578318653376, 35.483908878030412, 0.0],
              [-80.969158517672938, 35.484115278703953, 0.0],
              [-80.969075184661236, 35.484710878507968, 0.0],
              [-80.968739585170951, 35.485077678698012, 0.0],
              [-80.968039985206218, 35.485444612016458, 0.0],
              [-80.966472385120909, 35.485812278649746, 0.0],
              [-80.966304917823194, 35.48620181186994, 0.0],
              [-80.965857184649053, 35.486568478566056, 0.0],
              [-80.965717517677277, 35.486843411135496, 0.0],
              [-80.965802117904985, 35.487324278504353, 0.0],
              [-80.965718317667026, 35.487553411620482, 0.0],
              [-80.965466385123705, 35.487645211711474, 0.0],
              [-80.965354518436499, 35.487782678588488, 0.0],
              [-80.965494984254704, 35.488217812029937, 0.0],
              [-80.965719317562986, 35.488400811442219, 0.0],
              [-80.966195184841752, 35.488285878275775, 0.0],
              [-80.966390517906063, 35.487644478056374, 0.0],
              [-80.966754717841496, 35.487735811124054, 0.0],
              [-80.966866984456829, 35.48803347833897, 0.0],
              [-80.967175318591259, 35.488331011542023, 0.0],
              [-80.967287585211466, 35.488559878326235, 0.0],
              [-80.967567785202903, 35.488720011955003, 0.0],
              [-80.968436184600591, 35.488948411949529, 0.0],
              [-80.969247984196954, 35.488741611275827, 0.0],
              [-80.969611718638603, 35.48846647810187, 0.0],
              [-80.969639118524725, 35.487871011667572, 0.0],
              [-80.969162785132582, 35.487688211930724, 0.0],
              [-80.969190385068828, 35.487413411375037, 0.0],
              [-80.969777984897945, 35.487023478273159, 0.0],
              [-80.969749518192387, 35.486634078342298, 0.0],
              [-80.969525117716174, 35.486359477851309, 0.0],
              [-80.969636984498763, 35.486199012059949, 0.0],
              [-80.970449184978889, 35.48631287829128, 0.0],
              [-80.970701384745283, 35.486564612009531, 0.0],
              [-80.970702718450596, 35.487595278649749, 0.0],
              [-80.970814784746722, 35.487686811827786, 0.0],
              [-80.971262785155744, 35.487594877763804, 0.0],
              [-80.971514517999751, 35.487411411926125, 0.0],
              [-80.971906584403101, 35.487479878022363, 0.0],
              [-80.97187938437996, 35.488121078210106, 0.0],
              [-80.971711917959865, 35.488487678403622, 0.0],
              [-80.971460317805409, 35.488831477945844, 0.0],
              [-80.971460785007821, 35.489243677856081, 0.0],
              [-80.971712984832877, 35.489312211321256, 0.0],
              [-80.972272784492105, 35.489220078490021, 0.0],
              [-80.972104585292172, 35.4889682785956, 0.0],
              [-80.972188184813547, 35.488670478233033, 0.0],
              [-80.973111918150664, 35.488509477953066, 0.0],
              [-80.973223918563946, 35.48841781175463, 0.0],
              [-80.973166717882364, 35.487455878418082, 0.0],
              [-80.973698518084632, 35.48727227855715, 0.0],
              [-80.974118784907986, 35.487455078691482, 0.0],
              [-80.976414717983388, 35.487269878646096, 0.0],
              [-80.976889917822334, 35.48651381163031, 0.0],
              [-80.9769175844513, 35.48633047820384, 0.0],
              [-80.97719731771474, 35.486032478345749, 0.0],
              [-80.977252717654636, 35.485574411710189, 0.0],
              [-80.976888117696447, 35.485093811411488, 0.0],
              [-80.976999718674293, 35.484818811308998, 0.0],
              [-80.977503985105344, 35.485024611569976, 0.0],
              [-80.977756317581736, 35.48525341186734, 0.0],
              [-80.977756718135581, 35.485574011962761, 0.0],
              [-80.97809298439384, 35.485802811279704, 0.0],
              [-80.978177318469278, 35.486054611598639, 0.0],
              [-80.978625585295759, 35.486214611941769, 0.0],
              [-80.978681785349025, 35.48637481201154, 0.0],
              [-80.978569918421599, 35.486489412038651, 0.0],
              [-80.977786185172675, 35.486764878249467, 0.0],
              [-80.977814517842972, 35.48699387815811, 0.0],
              [-80.978150917645564, 35.487222612008409, 0.0],
              [-80.978794785092035, 35.487153411284162, 0.0],
              [-80.97935418502864, 35.486672011800486, 0.0],
              [-80.979605984717423, 35.486442811191004, 0.0],
              [-80.979746118267101, 35.486534211960176, 0.0],
              [-80.979551117574985, 35.487290212039824, 0.0],
              [-80.979607384760527, 35.487587877827771, 0.0],
              [-80.980251917850453, 35.48786207851694, 0.0],
              [-80.980195985093502, 35.488068277825292, 0.0],
              [-80.979860118391528, 35.488137277805713, 0.0],
              [-80.978907717826303, 35.487909078690464, 0.0],
              [-80.978123917751844, 35.488024278202062, 0.0],
              [-80.97700378511388, 35.488002277923194, 0.0],
              [-80.976835917563236, 35.488185677913286, 0.0],
              [-80.976276185106656, 35.488438077826686, 0.0],
              [-80.975940517796118, 35.488781878439298, 0.0],
              [-80.974960918052901, 35.489126411747939, 0.0],
              [-80.974793118184351, 35.489355478699466, 0.0],
              [-80.974793784188876, 35.489790678359981, 0.0],
              [-80.975354517725322, 35.490294011928313, 0.0],
              [-80.974766917524192, 35.490683878039995, 0.0],
              [-80.973730584511117, 35.490524411678479, 0.0],
              [-80.973506717534704, 35.490639011538597, 0.0],
              [-80.972779584893104, 35.491395478557109, 0.0],
              [-80.972051517611362, 35.491487611514401, 0.0],
              [-80.971715717719235, 35.491625277995617, 0.0],
              [-80.971380118015261, 35.492083678212381, 0.0],
              [-80.971296517858875, 35.492427277927767, 0.0],
              [-80.97132658488465, 35.494122011111237, 0.0],
              [-80.971634918152503, 35.494350811157318, 0.0],
              [-80.972250717640094, 35.494121278438953, 0.0],
              [-80.972810718681544, 35.494075011776623, 0.0],
              [-80.973621517828349, 35.492929211847091, 0.0],
              [-80.974517185145444, 35.492562078376352, 0.0],
              [-80.9746853185205, 35.492653478365312, 0.0],
              [-80.974769584204637, 35.492859611673097, 0.0],
              [-80.97575051760046, 35.493500078089006, 0.0],
              [-80.976618517589088, 35.493407611875348, 0.0],
              [-80.977346318000485, 35.493086411226251, 0.0],
              [-80.977570518281766, 35.493269411077087, 0.0],
              [-80.977962517950871, 35.493223278475618, 0.0],
              [-80.9777655848103, 35.492536411571429, 0.0],
              [-80.977765184187078, 35.492215812007458, 0.0],
              [-80.978016917818906, 35.49189487864377, 0.0],
              [-80.97838078432666, 35.491825878620354, 0.0],
              [-80.979641717865618, 35.492374411484029, 0.0],
              [-80.980201584837801, 35.492259478243334, 0.0],
              [-80.980257317627235, 35.491938678006868, 0.0],
              [-80.980396984432701, 35.491847078732292, 0.0],
              [-80.980593184666716, 35.491984278726136, 0.0],
              [-80.980677718400003, 35.492236212053108, 0.0],
              [-80.980929984665039, 35.49246501188555, 0.0],
              [-80.981882584829648, 35.492853477992959, 0.0],
              [-80.982442718657339, 35.492807011535255, 0.0],
              [-80.983310185241237, 35.492485677812603, 0.0],
              [-80.983169118013521, 35.491684211146222, 0.0],
              [-80.98319671811835, 35.491340678734915, 0.0],
              [-80.983336584954586, 35.491271811085369, 0.0],
              [-80.983448785070351, 35.491363278349226, 0.0],
              [-80.983533118452542, 35.49163807779486, 0.0],
              [-80.983813517518428, 35.491866878703519, 0.0],
              [-80.984457517795661, 35.491797478623084, 0.0],
              [-80.98487698527444, 35.491385012047864, 0.0],
              [-80.984875784153601, 35.490537612035645, 0.0],
              [-80.98465118475589, 35.490125478015905, 0.0],
              [-80.984650518445932, 35.489621611523546, 0.0],
              [-80.98479038441549, 35.489552878285885, 0.0],
              [-80.984930717499509, 35.489644277815287, 0.0],
              [-80.985015385056258, 35.490148077859168, 0.0],
              [-80.985155717985236, 35.490399811091017, 0.0],
              [-80.985463718235039, 35.49039961165181, 0.0],
              [-80.986050984771126, 35.489826477931778, 0.0],
              [-80.986413917617725, 35.489001678023939, 0.0],
              [-80.986552584602734, 35.488039611206595, 0.0],
              [-80.986776784266951, 35.488199811598598, 0.0],
              [-80.987028784952827, 35.488153611327057, 0.0],
              [-80.987280384211147, 35.487878611251183, 0.0],
              [-80.987447784261661, 35.487397478452863, 0.0],
              [-80.987671385164205, 35.487099611448279, 0.0],
              [-80.988622517646704, 35.486549011869648, 0.0],
              [-80.98873458455347, 35.486617478601374, 0.0],
              [-80.988707318403669, 35.48691541138372, 0.0],
              [-80.988092118231876, 35.487557211100956, 0.0],
              [-80.987784184118865, 35.487694877846486, 0.0],
              [-80.987784718397023, 35.488015478153294, 0.0],
              [-80.987952917610869, 35.488152678069703, 0.0],
              [-80.988625384964024, 35.488427011680159, 0.0],
              [-80.98870991865094, 35.488839211936778, 0.0],
              [-80.987757717822234, 35.488702678380278, 0.0],
              [-80.987533718191003, 35.488817278123705, 0.0],
              [-80.987422384271724, 35.489275478584879, 0.0],
              [-80.986528717855833, 35.491017011315378, 0.0],
              [-80.986445518040242, 35.491589678235115, 0.0],
              [-80.986502318508386, 35.492185011113015, 0.0],
              [-80.986670517539849, 35.492299411258088, 0.0],
              [-80.987090317857408, 35.492207411910506, 0.0],
              [-80.987957785276279, 35.491679678231151, 0.0],
              [-80.988265718165508, 35.491610811717273, 0.0],
              [-80.988545985196964, 35.491793678347186, 0.0],
              [-80.988490384121505, 35.492091477954766, 0.0],
              [-80.98801491749748, 35.492458411982611, 0.0],
              [-80.988014984229437, 35.49255001168865, 0.0],
              [-80.988295317788712, 35.492755878657377, 0.0],
              [-80.989500184861626, 35.49323567862362, 0.0],
              [-80.991124784547509, 35.493554678091193, 0.0],
              [-80.991237317945448, 35.493783678145356, 0.0],
              [-80.990873517983559, 35.494013011135486, 0.0],
              [-80.991069985118273, 35.494310611934118, 0.0],
              [-80.991014317875738, 35.494562611349416, 0.0],
              [-80.9903979846287, 35.494357077910252, 0.0],
              [-80.990061385297167, 35.494059611929501, 0.0],
              [-80.98941698494103, 35.493831211273857, 0.0],
              [-80.988772785040922, 35.493740212047904, 0.0],
              [-80.98826838466816, 35.493511678086868, 0.0],
              [-80.987595984233309, 35.493329211218999, 0.0],
              [-80.987343784231641, 35.493169011943216, 0.0],
              [-80.986755784714205, 35.493261211890655, 0.0],
              [-80.986587784441824, 35.493353011937955, 0.0],
              [-80.985383918175572, 35.493491477844167, 0.0],
              [-80.985075985328294, 35.493629212051914, 0.0],
              [-80.984936518663034, 35.493881278481631, 0.0],
              [-80.984937318404647, 35.494476677882204, 0.0],
              [-80.985218117951547, 35.495026078408017, 0.0],
              [-80.986115184840244, 35.495781078010843, 0.0],
              [-80.986087584902805, 35.496010211672044, 0.0],
              [-80.985863785215159, 35.496193478360482, 0.0],
              [-80.985275317785934, 35.495873478284594, 0.0],
              [-80.985247117627651, 35.495758878241617, 0.0],
              [-80.984882518439704, 35.495415811151794, 0.0],
              [-80.984181985080539, 35.495118678709233, 0.0],
              [-80.983621317946501, 35.494706878082589, 0.0],
              [-80.983229384779563, 35.494776011828932, 0.0],
              [-80.981969785255643, 35.495258211117687, 0.0],
              [-80.981885984383752, 35.495372678178001, 0.0],
              [-80.98208258502585, 35.495853478182454, 0.0],
              [-80.981971118424667, 35.496243011420695, 0.0],
              [-80.981999518661922, 35.496586478151208, 0.0],
              [-80.982279917907803, 35.496815211491814, 0.0],
              [-80.983064585231347, 35.497226677984756, 0.0],
              [-80.982924984234771, 35.497570411570088, 0.0],
              [-80.981664318309726, 35.497228011713752, 0.0],
              [-80.981215384248998, 35.496518478675618, 0.0],
              [-80.980318384806708, 35.495900877919325, 0.0],
              [-80.97978611803093, 35.495763877786878, 0.0],
              [-80.97961778485336, 35.495557877744133, 0.0],
              [-80.979113785206152, 35.495604211766981, 0.0],
              [-80.97877791823403, 35.495787611120321, 0.0],
              [-80.977713784290586, 35.495811477861061, 0.0],
              [-80.976929917805364, 35.496064077927365, 0.0],
              [-80.976342384829366, 35.496568478710621, 0.0],
              [-80.975698184235355, 35.496523278380252, 0.0],
              [-80.975278117973048, 35.496409011653, 0.0],
              [-80.974857984413291, 35.496523877983059, 0.0],
              [-80.974858984534876, 35.497279677994115, 0.0],
              [-80.974775318348122, 35.497463011701434, 0.0],
              [-80.97483158429641, 35.497760611730747, 0.0],
              [-80.974943718191255, 35.497875078670781, 0.0],
              [-80.976511985015264, 35.497805078447882, 0.0],
              [-80.976736184628038, 35.497896478366435, 0.0],
              [-80.976736718592676, 35.498354611138041, 0.0],
              [-80.977073184331147, 35.498629078157286, 0.0],
              [-80.977857518476625, 35.498765811600052, 0.0],
              [-80.977801918065509, 35.499040678325279, 0.0],
              [-80.977606317756042, 35.499384477998539, 0.0],
              [-80.977830717696506, 35.49961321120842, 0.0],
              [-80.977802918166603, 35.499773611737247, 0.0],
              [-80.976822584552224, 35.499705811250884, 0.0],
              [-80.977046984197642, 35.49998041121593, 0.0],
              [-80.976906985237548, 35.500072078675061, 0.0],
              [-80.97690698442986, 35.500144277964814, 0.0],
              [-80.97673978432509, 35.500144411879127, 0.0],
              [-80.977076718450419, 35.500978211835374, 0.0],
              [-80.977469318488716, 35.501298611546922, 0.0],
              [-80.977526118551111, 35.501710678378004, 0.0],
              [-80.977806918068822, 35.502008278442318, 0.0],
              [-80.978451984406192, 35.502420011676143, 0.0],
              [-80.97850971791749, 35.503061211808422, 0.0],
              [-80.978789518118106, 35.503290011172297, 0.0],
              [-80.97937731784738, 35.503014611385943, 0.0],
              [-80.979907984883468, 35.502624811208968, 0.0],
              [-80.980355318413359, 35.502074811330246, 0.0],
              [-80.980550585103899, 35.501295878049596, 0.0],
              [-80.981894784576625, 35.501111411391634, 0.0],
              [-80.983155185188693, 35.50113321107748, 0.0],
              [-80.983099317814947, 35.501293611686556, 0.0],
              [-80.981951584776269, 35.501775478515306, 0.0],
              [-80.981419917702667, 35.502165411834994, 0.0],
              [-80.981280118543793, 35.502440411224271, 0.0],
              [-80.981336384388811, 35.502669411596187, 0.0],
              [-80.982457784488091, 35.503332611960694, 0.0],
              [-80.983550518300873, 35.50362927866675, 0.0],
              [-80.983998985208316, 35.503949477883438, 0.0],
              [-80.98447531814945, 35.504063611214576, 0.0],
              [-80.984476118316721, 35.504636211598793, 0.0],
              [-80.984924784770357, 35.505025077913437, 0.0],
              [-80.984952984472187, 35.505208278514353, 0.0],
              [-80.984477117598615, 35.505300411073968, 0.0],
              [-80.984112717765399, 35.505048811718687, 0.0],
              [-80.983775985282605, 35.50465981136584, 0.0],
              [-80.98290718491225, 35.504202478585029, 0.0],
              [-80.982094785120182, 35.504088678493872, 0.0],
              [-80.981786584481938, 35.50397441172607, 0.0],
              [-80.980610118425361, 35.503883878137451, 0.0],
              [-80.980190117814843, 35.503998811347486, 0.0],
              [-80.97985431767421, 35.504228077996487, 0.0],
              [-80.979630718610053, 35.504571811181322, 0.0],
              [-80.979576518476136, 35.505969011537374, 0.0],
              [-80.979352517862395, 35.506014878713849, 0.0],
              [-80.979128185335, 35.505831878313657, 0.0],
              [-80.978819117561272, 35.505053611370712, 0.0],
              [-80.97845458486421, 35.50475607791661, 0.0],
              [-80.977529918098639, 35.504436211063329, 0.0],
              [-80.97702591860525, 35.504597011143446, 0.0],
              [-80.976549784544559, 35.504551611832696, 0.0],
              [-80.975933318101895, 35.504300211753488, 0.0],
              [-80.975372718090554, 35.50393421113769, 0.0],
              [-80.975148718295415, 35.504003078250719, 0.0],
              [-80.975121785302846, 35.50494207813756, 0.0],
              [-80.975598517969345, 35.5053310780239, 0.0],
              [-80.97557091774226, 35.505651678264293, 0.0],
              [-80.975011384196449, 35.506224811982506, 0.0],
              [-80.974956117973719, 35.506797478596283, 0.0],
              [-80.974508517545715, 35.507233011314838, 0.0],
              [-80.974592984796047, 35.507622211170215, 0.0],
              [-80.974482385081174, 35.508767611573759, 0.0],
              [-80.975042984561085, 35.509133478214245, 0.0],
              [-80.974651517842076, 35.509637678002058, 0.0],
              [-80.974568318560799, 35.510370678182248, 0.0],
              [-80.974400784554746, 35.510737278693988, 0.0],
              [-80.974401317912935, 35.511195278608668, 0.0],
              [-80.974765785026491, 35.511469811553859, 0.0],
              [-80.974905918443014, 35.51149261161347, 0.0],
              [-80.975437918206524, 35.511309011355316, 0.0],
              [-80.975856918313966, 35.510438278182903, 0.0],
              [-80.976276718445931, 35.510094411924165, 0.0],
              [-80.978208985179592, 35.509817877730093, 0.0],
              [-80.978516784655326, 35.509565611257756, 0.0],
              [-80.9791607844829, 35.509427677920378, 0.0],
              [-80.979524784962706, 35.509267078504756, 0.0],
              [-80.979944917503317, 35.50928961159655, 0.0],
              [-80.979749317577514, 35.509633278423919, 0.0],
              [-80.978881384855825, 35.510000478674336, 0.0],
              [-80.978713584240765, 35.510138078406349, 0.0],
              [-80.978686118512343, 35.510527411667454, 0.0],
              [-80.979751384501512, 35.511282278116035, 0.0],
              [-80.980396518274901, 35.511877211666295, 0.0],
              [-80.980760784421292, 35.512060011589647, 0.0],
              [-80.98120971837227, 35.51254061150189, 0.0],
              [-80.981713984568728, 35.512654678229275, 0.0],
              [-80.982609984203521, 35.512402011296246, 0.0],
              [-80.982805717784956, 35.512127011380841, 0.0],
              [-80.983029985231582, 35.512218278629703, 0.0],
              [-80.983142518496649, 35.512607611321926, 0.0],
              [-80.983338784264618, 35.51276781165916, 0.0],
              [-80.98389918432477, 35.512904611136797, 0.0],
              [-80.984067718258927, 35.513271011853455, 0.0],
              [-80.984319984201605, 35.513385277729761, 0.0],
              [-80.984656118348695, 35.513293278175261, 0.0],
              [-80.984879518530406, 35.512812211361556, 0.0],
              [-80.98546738471461, 35.512582611199903, 0.0],
              [-80.98591511850465, 35.512215678014556, 0.0],
              [-80.986283918330514, 35.512148278416127, 0.0],
              [-80.986419118475126, 35.51212361113101, 0.0],
              [-80.986335718603911, 35.51249021176708, 0.0],
              [-80.985328518102349, 35.513453011977603, 0.0],
              [-80.985469184793672, 35.513865078601881, 0.0],
              [-80.98622638529217, 35.51462021172415, 0.0],
              [-80.986226784128277, 35.514849277910471, 0.0],
              [-80.985834517629101, 35.51475801166854, 0.0],
              [-80.985133517907286, 35.514209011945709, 0.0],
              [-80.984712984555742, 35.514026078168833, 0.0],
              [-80.983816585095852, 35.513958211412664, 0.0],
              [-80.983312184963978, 35.513844211705738, 0.0],
              [-80.982752184867039, 35.513959277900881, 0.0],
              [-80.982584984810984, 35.514669477951784, 0.0],
              [-80.981857917609403, 35.515494611948185, 0.0],
              [-80.981745784270132, 35.515517478562849, 0.0],
              [-80.981605584695359, 35.515357411426614, 0.0],
              [-80.981464118325022, 35.51432681178661, 0.0],
              [-80.981239784330327, 35.514189678478886, 0.0],
              [-80.979782984725972, 35.514007677844184, 0.0],
              [-80.979586718505601, 35.513824678405228, 0.0],
              [-80.979445717934354, 35.51309201124959, 0.0],
              [-80.979305384153989, 35.51290881176444, 0.0],
              [-80.978857384818369, 35.513046611374406, 0.0],
              [-80.978381917618762, 35.513619611345348, 0.0],
              [-80.978157784345129, 35.513596811476098, 0.0],
              [-80.97804551753795, 35.513390877805044, 0.0],
              [-80.978156384405523, 35.51245167833418, 0.0],
              [-80.977819717846302, 35.512016877740059, 0.0],
              [-80.976783384901807, 35.512109411135711, 0.0],
              [-80.976391784162928, 35.512544877731493, 0.0],
              [-80.975943784212291, 35.512705611986881, 0.0],
              [-80.975636317892949, 35.513163878435456, 0.0],
              [-80.97541211760489, 35.51314121177419, 0.0],
              [-80.975215785237921, 35.512981077913764, 0.0],
              [-80.975047384402956, 35.512683477925286, 0.0],
              [-80.974626985081059, 35.51245481200359, 0.0],
              [-80.974122584655575, 35.512363611320033, 0.0],
              [-80.973814317978537, 35.512157677825897, 0.0],
              [-80.973450384192688, 35.512318278262747, 0.0],
              [-80.973198585108506, 35.512639211163027, 0.0],
              [-80.973116384757944, 35.514082077936351, 0.0],
              [-80.972948518231675, 35.514242478581707, 0.0],
              [-80.972780384361428, 35.514151077952135, 0.0],
              [-80.972667384805419, 35.513418278509363, 0.0],
              [-80.972498985018703, 35.513212278153311, 0.0],
              [-80.971966384493314, 35.512846411774866, 0.0],
              [-80.971545518459834, 35.512297011479802, 0.0],
              [-80.971461117649255, 35.511930611358224, 0.0],
              [-80.971598985085521, 35.510121211518296, 0.0],
              [-80.971513718362729, 35.509090611972042, 0.0],
              [-80.970895518576526, 35.507442078473275, 0.0],
              [-80.969998184254365, 35.506526677740666, 0.0],
              [-80.969521718140257, 35.506229277784463, 0.0],
              [-80.968625317699491, 35.506138478278793, 0.0],
              [-80.967505384218526, 35.506505811969745, 0.0],
              [-80.967421718254556, 35.506780678700409, 0.0],
              [-80.967870184330422, 35.507123811871949, 0.0],
              [-80.967982584241739, 35.507444411539886, 0.0],
              [-80.968346985254286, 35.507741811193227, 0.0],
              [-80.969215784490828, 35.508061811393233, 0.0],
              [-80.969580384232998, 35.508588278465275, 0.0],
              [-80.969608784672019, 35.508863077783026, 0.0],
              [-80.969973585294838, 35.509389611456207, 0.0],
              [-80.96989038535996, 35.510168411787873, 0.0],
              [-80.969133985048501, 35.510054478124601, 0.0],
              [-80.968377784584376, 35.510169611653872, 0.0],
              [-80.968237985215595, 35.51028421159473, 0.0],
              [-80.968238584970223, 35.510833811949645, 0.0],
              [-80.968855717540549, 35.511566211514335, 0.0],
              [-80.969640317675996, 35.511817478376742, 0.0],
              [-80.969780985279769, 35.512435812020144, 0.0],
              [-80.96989318442823, 35.512573211978726, 0.0],
              [-80.969949917746675, 35.512962611713, 0.0],
              [-80.970146185102621, 35.513168478016055, 0.0],
              [-80.970258784812188, 35.513672211960149, 0.0],
              [-80.970651318118954, 35.5139696781278, 0.0],
              [-80.970652384464231, 35.514817078177131, 0.0],
              [-80.970316784432654, 35.515321212006526, 0.0],
              [-80.970036784585943, 35.515413011412718, 0.0],
              [-80.969167917610264, 35.514978611233452, 0.0],
              [-80.969027584932761, 35.514726678585816, 0.0],
              [-80.968466984966213, 35.514383678560698, 0.0],
              [-80.967037985286879, 35.514041211888774, 0.0],
              [-80.966785717695657, 35.513904011516637, 0.0],
              [-80.966281584405593, 35.514041878362789, 0.0],
              [-80.966057717700366, 35.514202278350716, 0.0],
              [-80.965609317619922, 35.513973677846188, 0.0],
              [-80.965160984798089, 35.513882411333277, 0.0],
              [-80.96476891835465, 35.513997211498584, 0.0],
              [-80.964321185075278, 35.51443261178553, 0.0],
              [-80.963508784890962, 35.514341678108579, 0.0],
              [-80.962976784248767, 35.514479478572142, 0.0],
              [-80.96258511772858, 35.514892011941981, 0.0],
              [-80.961884985239422, 35.515121611081526, 0.0],
              [-80.961241117540723, 35.515488478347585, 0.0],
              [-80.961213318252788, 35.515694611565081, 0.0],
              [-80.961633385344243, 35.515625611674281, 0.0],
              [-80.962726117613471, 35.515808078125922, 0.0],
              [-80.96328618443178, 35.515716011788399, 0.0],
              [-80.963902185284127, 35.515509411469793, 0.0],
              [-80.964658918587631, 35.51573787862889, 0.0],
              [-80.964939384814926, 35.51610407854357, 0.0],
              [-80.966536118619842, 35.516125811311696, 0.0],
              [-80.967769384691536, 35.516811877987884, 0.0],
              [-80.967769584789536, 35.516995078459395, 0.0],
              [-80.966509184829476, 35.51708767815505, 0.0],
              [-80.965781384355324, 35.517546411775854, 0.0],
              [-80.965557585254643, 35.517844278242549, 0.0],
              [-80.965614518083299, 35.518600078191163, 0.0],
              [-80.965810784666203, 35.518828877864387, 0.0],
              [-80.965754984783842, 35.519035077716765, 0.0],
              [-80.964073784991015, 35.518624011907725, 0.0],
              [-80.963289317796608, 35.518533011084635, 0.0],
              [-80.96270098524235, 35.518556477992519, 0.0],
              [-80.962421317858457, 35.51887727809742, 0.0],
              [-80.962449517606245, 35.519129211959232, 0.0],
              [-80.963570985049458, 35.519930011433004, 0.0],
              [-80.964019385296027, 35.520135811396102, 0.0],
              [-80.964328184807783, 35.520662211900344, 0.0],
              [-80.964888984498273, 35.521188611679754, 0.0],
              [-80.965001318535428, 35.521394678018062, 0.0],
              [-80.964945917594306, 35.52196721151909, 0.0],
              [-80.965395184413097, 35.522860078179633, 0.0],
              [-80.965675185093659, 35.522791211770091, 0.0],
              [-80.965898984921239, 35.522493278556325, 0.0],
              [-80.965954584744878, 35.522081011306639, 0.0],
              [-80.966402318465441, 35.521691411162401, 0.0],
              [-80.966738717575154, 35.521782678231247, 0.0],
              [-80.967019118655671, 35.522103211619211, 0.0],
              [-80.967439184246317, 35.522011211381461, 0.0],
              [-80.967747118212941, 35.521827677834224, 0.0],
              [-80.968167584905828, 35.521987678438279, 0.0],
              [-80.968979918144257, 35.521849611332343, 0.0],
              [-80.969260184376239, 35.522032611936019, 0.0],
              [-80.969680517513481, 35.522146811598596, 0.0],
              [-80.969932984204618, 35.522444277922759, 0.0],
              [-80.970185318020455, 35.522604411314198, 0.0],
              [-80.970745784785592, 35.522718478431145, 0.0],
              [-80.972034518619068, 35.522809077924741, 0.0],
              [-80.972062718031466, 35.522946478464043, 0.0],
              [-80.971698717999089, 35.523061278482196, 0.0],
              [-80.970718118341907, 35.52301621145088, 0.0],
              [-80.969793384318507, 35.522811012000325, 0.0],
              [-80.968868917749617, 35.522903211381973, 0.0],
              [-80.96872878423487, 35.522834678249538, 0.0],
              [-80.968000717931361, 35.522995478393447, 0.0],
              [-80.967468718641086, 35.52329367787469, 0.0],
              [-80.967300918222605, 35.523522811995846, 0.0],
              [-80.967301317548419, 35.523843411457982, 0.0],
              [-80.968114584235025, 35.52462147770823, 0.0],
              [-80.968086784360239, 35.524781811432632, 0.0],
              [-80.967274185286371, 35.524622277903099, 0.0],
              [-80.966601517538678, 35.524393678150041, 0.0],
              [-80.966237385015887, 35.524508611680162, 0.0],
              [-80.966013718555558, 35.524898011575765, 0.0],
              [-80.965958385155474, 35.525493611680474, 0.0],
              [-80.965874518283329, 35.525631078449059, 0.0],
              [-80.965706517639418, 35.525631211250726, 0.0],
              [-80.965397985086142, 35.525356478277551, 0.0],
              [-80.96525718477956, 35.524807011345167, 0.0],
              [-80.965088918072439, 35.524555211070542, 0.0],
              [-80.963798984372488, 35.523525611232671, 0.0],
              [-80.963519117526232, 35.523640277806273, 0.0],
              [-80.96312738430143, 35.524190211851817, 0.0],
              [-80.963213318268046, 35.525839211995546, 0.0],
              [-80.963045718683702, 35.526320277856428, 0.0],
              [-80.963102317735704, 35.526869877894555, 0.0],
              [-80.96321451775755, 35.527007211628593, 0.0],
              [-80.963075517605176, 35.527923411099934, 0.0],
              [-80.963160518227923, 35.528885411712537, 0.0],
              [-80.96344091771391, 35.529091277945184, 0.0],
              [-80.964169384248578, 35.529136478531271, 0.0],
              [-80.964841984951846, 35.529319211424735, 0.0],
              [-80.965290185219857, 35.52922727824037, 0.0],
              [-80.965570185086165, 35.529043811462401, 0.0],
              [-80.965682384492467, 35.529112411717335, 0.0],
              [-80.965710784448476, 35.529593411104237, 0.0],
              [-80.967811318257517, 35.528881811767064, 0.0],
              [-80.968259318320932, 35.528652478236417, 0.0],
              [-80.968903518658067, 35.528491478510922, 0.0],
              [-80.969379518441343, 35.52819341125781, 0.0],
              [-80.970555718610228, 35.527826011276908, 0.0],
              [-80.97122791755821, 35.527711011485742, 0.0],
              [-80.971591785037205, 35.527435878521139, 0.0],
              [-80.971816118417493, 35.527573211101384, 0.0],
              [-80.971844317962535, 35.527733411815802, 0.0],
              [-80.972068784280552, 35.52796227834223, 0.0],
              [-80.972068918073532, 35.528145478547401, 0.0],
              [-80.97086431770677, 35.528238078548142, 0.0],
              [-80.969828117494885, 35.528513677794628, 0.0],
              [-80.969548118440557, 35.528743011640735, 0.0],
              [-80.969212718393635, 35.529315878537055, 0.0],
              [-80.968596584520824, 35.529545411588884, 0.0],
              [-80.96851258438727, 35.5296600119053, 0.0],
              [-80.96859758453752, 35.530438611865705, 0.0],
              [-80.967897118108539, 35.530324611747915, 0.0],
              [-80.966972717663609, 35.53046267866565, 0.0],
              [-80.966216585231891, 35.530852678387376, 0.0],
              [-80.965544718222105, 35.531357078559118, 0.0],
              [-80.964816384249801, 35.531449211974113, 0.0],
              [-80.964452518195273, 35.531609811488174, 0.0],
              [-80.964003717846296, 35.531197811756357, 0.0],
              [-80.963723384381623, 35.531083611321677, 0.0],
              [-80.96319118485053, 35.531129811163879, 0.0],
              [-80.962827117989207, 35.53129041199869, 0.0],
              [-80.962546784410335, 35.531221811260814, 0.0],
              [-80.962210185230219, 35.530878611298192, 0.0],
              [-80.962069784240924, 35.53051227787045, 0.0],
              [-80.96128451774355, 35.529802811330697, 0.0],
              [-80.960948318437886, 35.529803077773643, 0.0],
              [-80.960920384380813, 35.529963411559045, 0.0],
              [-80.961425384612781, 35.530512678540155, 0.0],
              [-80.961425984644464, 35.531085211794938, 0.0],
              [-80.960641918239247, 35.531452278627768, 0.0],
              [-80.960390184181136, 35.531841878194868, 0.0],
              [-80.960306584597106, 35.532254211492344, 0.0],
              [-80.959802584804223, 35.532643877838851, 0.0],
              [-80.959803118535703, 35.533170611997164, 0.0],
              [-80.960139917973365, 35.53360547770481, 0.0],
              [-80.961373185099433, 35.534108478109914, 0.0],
              [-80.961485384887027, 35.534268678618325, 0.0],
              [-80.961401584946628, 35.534475011860103, 0.0],
              [-80.95932898505589, 35.535209277717811, 0.0],
              [-80.95907711756746, 35.535461411984777, 0.0],
              [-80.959021917547545, 35.536286011349596, 0.0],
              [-80.958937917516366, 35.536354811784285, 0.0],
              [-80.958181517953591, 35.536446878630571, 0.0],
              [-80.958097717684836, 35.536721811068482, 0.0],
              [-80.957845518563289, 35.536699078123704, 0.0],
              [-80.957509185182559, 35.536539011424267, 0.0],
              [-80.95714458442302, 35.536172811676799, 0.0],
              [-80.956303784450668, 35.536036011379018, 0.0],
              [-80.955855318168119, 35.535853011483809, 0.0],
              [-80.955182917587379, 35.535945078272604, 0.0],
              [-80.954762784975173, 35.536128611010504, 0.0],
              [-80.95423058476986, 35.536197678614371, 0.0],
              [-80.954202784751956, 35.536518278157551, 0.0],
              [-80.954175118005836, 35.536862011141203, 0.0],
              [-80.954063317685936, 35.537045277871464, 0.0],
              [-80.954063584861615, 35.53734301132981, 0.0],
              [-80.954512118374851, 35.537571678201843, 0.0],
              [-80.955520917717138, 35.53768561134865, 0.0],
              [-80.955857518007733, 35.538051811923701, 0.0],
              [-80.956221985046383, 35.538234678090866, 0.0],
              [-80.956446385141774, 35.538555211376092, 0.0],
              [-80.957483317680115, 35.538600277722061, 0.0],
              [-80.9578199177339, 35.538943677774242, 0.0],
              [-80.957680518005574, 35.539722411533084, 0.0],
              [-80.957765318431996, 35.540432277995393, 0.0],
              [-80.958885718198687, 35.540065078451249, 0.0],
              [-80.959081584823707, 35.539835878059897, 0.0],
              [-80.959164718060123, 35.538851011672456, 0.0],
              [-80.959248585297303, 35.538713478362375, 0.0],
              [-80.959303585076839, 35.537705811405438, 0.0],
              [-80.959835718508955, 35.537476411221583, 0.0],
              [-80.960087518475376, 35.537132678369389, 0.0],
              [-80.960367717904973, 35.537086611413471, 0.0],
              [-80.96056378417093, 35.537155078084972, 0.0],
              [-80.960956118612501, 35.53704041110322, 0.0],
              [-80.961236517663934, 35.537246278428277, 0.0],
              [-80.961349385288912, 35.538025011092344, 0.0],
              [-80.961994118321016, 35.538230611073608, 0.0],
              [-80.962890317980936, 35.537886278200716, 0.0],
              [-80.962945984962786, 35.537519878037493, 0.0],
              [-80.963674317793561, 35.537381878307016, 0.0],
              [-80.964122184875691, 35.536992211472118, 0.0],
              [-80.964261917536149, 35.536625811471446, 0.0],
              [-80.964176317788016, 35.535160011375204, 0.0],
              [-80.96454051830105, 35.535228411143308, 0.0],
              [-80.964709318617039, 35.53582367825463, 0.0],
              [-80.965270385083372, 35.536441677994539, 0.0],
              [-80.966139117935924, 35.536578411240541, 0.0],
              [-80.966839385031122, 35.536417611341705, 0.0],
              [-80.967203385329896, 35.536165411430261, 0.0],
              [-80.969193318699922, 35.536644811785955, 0.0],
              [-80.969053384579837, 35.536828211637022, 0.0],
              [-80.968212918106417, 35.536897478161357, 0.0],
              [-80.96656031776547, 35.537402611314683, 0.0],
              [-80.965608717721736, 35.538319478324119, 0.0],
              [-80.964936718700258, 35.538732211789373, 0.0],
              [-80.964657185006416, 35.539305011045343, 0.0],
              [-80.964349317804817, 35.539580077732374, 0.0],
              [-80.964433518553435, 35.539763211840508, 0.0],
              [-80.965862584680167, 35.539807878095615, 0.0],
              [-80.966254985291215, 35.539990878085923, 0.0],
              [-80.967123184659073, 35.539623811128493, 0.0],
              [-80.967235517520407, 35.539715278098292, 0.0],
              [-80.967292785076054, 35.540814611080265, 0.0],
              [-80.96757298442671, 35.540814411222769, 0.0],
              [-80.968273117497162, 35.540539011048132, 0.0],
              [-80.968327584762307, 35.539164811648611, 0.0],
              [-80.968579717985833, 35.539072877793771, 0.0],
              [-80.969056318287528, 35.539347411641948, 0.0],
              [-80.96939311842668, 35.539736611383333, 0.0],
              [-80.969701185221354, 35.539644611771507, 0.0],
              [-80.969952918517308, 35.539255011113653, 0.0],
              [-80.970456985001206, 35.539025678051971, 0.0],
              [-80.970848718139621, 35.538521478089187, 0.0],
              [-80.971100984317843, 35.538681611857307, 0.0],
              [-80.97118578490597, 35.539254077786758, 0.0],
              [-80.971297984526842, 35.539391411037315, 0.0],
              [-80.971214118022644, 35.539506011651959, 0.0],
              [-80.970653984957835, 35.539758278335242, 0.0],
              [-80.970206518585101, 35.54046867803558, 0.0],
              [-80.969478918556632, 35.541270878017784, 0.0],
              [-80.969311718107235, 35.542049811835447, 0.0],
              [-80.96897591799808, 35.542416477677193, 0.0],
              [-80.969172517970492, 35.542828477692431, 0.0],
              [-80.969480584905156, 35.54275961160134, 0.0],
              [-80.969760585045663, 35.54253041171296, 0.0],
              [-80.969816117492343, 35.542095078190847, 0.0],
              [-80.969900117595486, 35.542049278654382, 0.0],
              [-80.969927918475321, 35.541797411005668, 0.0],
              [-80.970291584855431, 35.541407678537873, 0.0],
              [-80.970655784164066, 35.541224211469235, 0.0],
              [-80.970823985134828, 35.541338478527855, 0.0],
              [-80.970965117660825, 35.542140078328387, 0.0],
              [-80.971385984500699, 35.542689411876687, 0.0],
              [-80.971862318062207, 35.542597411583863, 0.0],
              [-80.972394317743991, 35.542322078174109, 0.0],
              [-80.973066184763397, 35.541794811507486, 0.0],
              [-80.973206384947559, 35.541840411335023, 0.0],
              [-80.973375117774637, 35.542275411230129, 0.0],
              [-80.972479384572708, 35.543169411658646, 0.0],
              [-80.97236778442624, 35.543536011318977, 0.0],
              [-80.972368518399975, 35.544177211615846, 0.0],
              [-80.972928784484481, 35.54403927786349, 0.0],
              [-80.973152584660838, 35.543627011789489, 0.0],
              [-80.97374071774459, 35.543374478396728, 0.0],
              [-80.974272584406265, 35.542916078289252, 0.0],
              [-80.974832584476786, 35.542617678044856, 0.0],
              [-80.974944517883458, 35.542503211581376, 0.0],
              [-80.975672585148999, 35.542159011497432, 0.0],
              [-80.976260317513834, 35.541494411564614, 0.0],
              [-80.976231318590465, 35.540692678530256, 0.0],
              [-80.97609091789559, 35.540532478528888, 0.0],
              [-80.976118584870491, 35.540303478537687, 0.0],
              [-80.976790785084688, 35.540119678180844, 0.0],
              [-80.97687471798757, 35.540028011883891, 0.0],
              [-80.976846118655146, 35.539524211353836, 0.0],
              [-80.976733784569333, 35.539386811262169, 0.0],
              [-80.976929385132379, 35.538951477680229, 0.0],
              [-80.976929117763589, 35.538653811122408, 0.0],
              [-80.976732518062676, 35.538287478463992, 0.0],
              [-80.976423984893344, 35.538104611345098, 0.0],
              [-80.976143784764972, 35.538104811066077, 0.0],
              [-80.975415584522665, 35.53828867846525, 0.0],
              [-80.975163118285266, 35.538105611505671, 0.0],
              [-80.974378718332261, 35.538266677734995, 0.0],
              [-80.974350518547922, 35.538106278578503, 0.0],
              [-80.973929917516827, 35.537809011896307, 0.0],
              [-80.973957718354697, 35.537648611117611, 0.0],
              [-80.974125717753239, 35.537511011791331, 0.0],
              [-80.974854184667677, 35.537510411899468, 0.0],
              [-80.975134318504573, 35.537441478055193, 0.0],
              [-80.975189785158207, 35.53702921166586, 0.0],
              [-80.97440471844692, 35.536594677939242, 0.0],
              [-80.974320118165892, 35.53613667817342, 0.0],
              [-80.974179918577491, 35.536022411608464, 0.0],
              [-80.973787518505247, 35.535953878285099, 0.0],
              [-80.973730984997459, 35.535633411305746, 0.0],
              [-80.973450584748065, 35.535404478064073, 0.0],
              [-80.973142317729753, 35.535290411593834, 0.0],
              [-80.972889784520177, 35.535038611819409, 0.0],
              [-80.972272985184901, 35.534695611135966, 0.0],
              [-80.972244784987893, 35.534512278000875, 0.0],
              [-80.97305711786052, 35.534420078524533, 0.0],
              [-80.973392917784949, 35.534053278455758, 0.0],
              [-80.973533117562297, 35.534144811780394, 0.0],
              [-80.973589918431102, 35.534763077844559, 0.0],
              [-80.973954518690221, 35.535060478462711, 0.0],
              [-80.974767518419199, 35.535380478633449, 0.0],
              [-80.974991918344585, 35.535586411375782, 0.0],
              [-80.975972918038636, 35.535906211920334, 0.0],
              [-80.976309184813061, 35.535860211931997, 0.0],
              [-80.976448985332794, 35.535699811039819, 0.0],
              [-80.976673118286769, 35.535676611855152, 0.0],
              [-80.976981717687991, 35.53595127814426, 0.0],
              [-80.977037984557953, 35.536180211676523, 0.0],
              [-80.977570984143313, 35.536637811484859, 0.0],
              [-80.977795185150953, 35.536637611687148, 0.0],
              [-80.97793511783334, 35.536545878038694, 0.0],
              [-80.97796271756863, 35.536225211659229, 0.0],
              [-80.978102584291605, 35.536019011885983, 0.0],
              [-80.978130185221119, 35.535744078117283, 0.0],
              [-80.977821384747671, 35.535217611679734, 0.0],
              [-80.978128984352338, 35.534805011773308, 0.0],
              [-80.977792317824964, 35.534393078379566, 0.0],
              [-80.977538785287379, 35.53338561189117, 0.0],
              [-80.977790985315494, 35.533385411838339, 0.0],
              [-80.978351918668366, 35.533843011176415, 0.0],
              [-80.978491984845164, 35.533842811102474, 0.0],
              [-80.978715984323003, 35.533682411960321, 0.0],
              [-80.979108118437196, 35.533590411131506, 0.0],
              [-80.97919218478836, 35.533636078159972, 0.0],
              [-80.979108518633481, 35.533911011478402, 0.0],
              [-80.978800785263417, 35.534300611759896, 0.0],
              [-80.978773184230519, 35.534575477743758, 0.0],
              [-80.979137717798793, 35.53480421179826, 0.0],
              [-80.97919418453462, 35.535147611222214, 0.0],
              [-80.979390517585912, 35.535330678144959, 0.0],
              [-80.979391784364111, 35.536292677964461, 0.0],
              [-80.979812517960596, 35.536681678451387, 0.0],
              [-80.980232717651305, 35.536612611953913, 0.0],
              [-80.98040111842036, 35.536726878476038, 0.0],
              [-80.980401384401233, 35.537001811257333, 0.0],
              [-80.979953717744038, 35.53746021118446, 0.0],
              [-80.979954585072917, 35.538170211587349, 0.0],
              [-80.980375518267564, 35.538559211821166, 0.0],
              [-80.98040391798979, 35.538971411693957, 0.0],
              [-80.98085278469145, 35.539360278401638, 0.0],
              [-80.980993185007762, 35.539612211807885, 0.0],
              [-80.981833717771821, 35.539519811133474, 0.0],
              [-80.982590784485041, 35.539908411253947, 0.0],
              [-80.983291184875128, 35.53977041134516, 0.0],
              [-80.983373917920545, 35.538785477838836, 0.0],
              [-80.983233517482077, 35.538602478670953, 0.0],
              [-80.983232917506541, 35.538167211410645, 0.0],
              [-80.983372584463254, 35.537892211315373, 0.0],
              [-80.98306391820762, 35.537503211398985, 0.0],
              [-80.98306351763074, 35.537136811256026, 0.0],
              [-80.983483184582894, 35.53674707808721, 0.0],
              [-80.983734918275715, 35.536357477936136, 0.0],
              [-80.983762518020569, 35.536082677802263, 0.0],
              [-80.983425917673728, 35.535808078210742, 0.0],
              [-80.983313117611672, 35.535235678642792, 0.0],
              [-80.981882984344608, 35.534412411552047, 0.0],
              [-80.981938784669168, 35.534229211082312, 0.0],
              [-80.982218984945305, 35.534228811279249, 0.0],
              [-80.983732984938058, 35.534914477985843, 0.0],
              [-80.984349384696259, 35.534936877925389, 0.0],
              [-80.984685384713529, 35.534822078199873, 0.0],
              [-80.984797317696945, 35.534684611308329, 0.0],
              [-80.984768184560821, 35.533883011023235, 0.0],
              [-80.985019917775119, 35.533493411635874, 0.0],
              [-80.984990718588506, 35.532646011307477, 0.0],
              [-80.985242384398276, 35.532279411982486, 0.0],
              [-80.985353718474002, 35.531844011919667, 0.0],
              [-80.985689584940999, 35.531500211582916, 0.0],
              [-80.985604185122142, 35.530630011954862, 0.0],
              [-80.98490338423521, 35.530310011286431, 0.0],
              [-80.984483184137233, 35.530310411519181, 0.0],
              [-80.983811117972166, 35.530517078316031, 0.0],
              [-80.983726918198172, 35.530471411705555, 0.0],
              [-80.983642184485532, 35.529944677803435, 0.0],
              [-80.983529917534355, 35.529853211798752, 0.0],
              [-80.982801585321027, 35.529876811955837, 0.0],
              [-80.982353518263736, 35.530037478132932, 0.0],
              [-80.982185318014842, 35.529969011946449, 0.0],
              [-80.982016585003393, 35.529534011736665, 0.0],
              [-80.980727517476268, 35.529283078076077, 0.0],
              [-80.980587185096638, 35.52912301113556, 0.0],
              [-80.980586918281617, 35.528802277889604, 0.0],
              [-80.98131551803796, 35.529007811459692, 0.0],
              [-80.982127918600938, 35.528869611918758, 0.0],
              [-80.982772385198942, 35.529052278148455, 0.0],
              [-80.983164518289101, 35.528868678598904, 0.0],
              [-80.983192117912751, 35.528639678019481, 0.0],
              [-80.983079785250538, 35.528502411742764, 0.0],
              [-80.982350985100808, 35.528182411872109, 0.0],
              [-80.982014518472724, 35.527885011859524, 0.0],
              [-80.982098184210926, 35.527724611268106, 0.0],
              [-80.982294318500976, 35.527724411967327, 0.0],
              [-80.983555918195393, 35.528318811450823, 0.0],
              [-80.983556118270286, 35.52845621199814, 0.0],
              [-80.983780518100176, 35.528707811446687, 0.0],
              [-80.985378517568876, 35.529393477984875, 0.0],
              [-80.985742784871306, 35.529438878527756, 0.0],
              [-80.98616278428338, 35.529346878194445, 0.0],
              [-80.986722585016338, 35.528911211651852, 0.0],
              [-80.986918317747168, 35.528613278061542, 0.0],
              [-80.986945518456508, 35.528017812005587, 0.0],
              [-80.986383785115521, 35.527079277870875, 0.0],
              [-80.986187584136786, 35.526942077997184, 0.0],
              [-80.986074584664422, 35.526415477934137, 0.0],
              [-80.985092984847626, 35.52559181139096, 0.0],
              [-80.985232718528167, 35.525362611680329, 0.0],
              [-80.985681317594924, 35.525522478186844, 0.0],
              [-80.986382184940496, 35.525979878001507, 0.0],
              [-80.98663431767541, 35.525888078043266, 0.0],
              [-80.986857985267676, 35.525590211087938, 0.0],
              [-80.987053985084017, 35.525567078576763, 0.0],
              [-80.987027517906498, 35.52662061110675, 0.0],
              [-80.987560384150385, 35.527078211690394, 0.0],
              [-80.987645318462413, 35.527673611708423, 0.0],
              [-80.987841917554334, 35.528039877750381, 0.0],
              [-80.988038184409859, 35.528177077871085, 0.0],
              [-80.988402317587614, 35.528131011703138, 0.0],
              [-80.988737918513564, 35.527672478537809, 0.0],
              [-80.989326518470904, 35.527832278531172, 0.0],
              [-80.990167117941965, 35.527900211472478, 0.0],
              [-80.990447118182658, 35.527785411973262, 0.0],
              [-80.990530584775712, 35.527396011364928, 0.0],
              [-80.990838584910975, 35.527304078167361, 0.0],
              [-80.99114718474668, 35.527578611607652, 0.0],
              [-80.991176185336158, 35.528242678547947, 0.0],
              [-80.991372718402189, 35.528540278630999, 0.0],
              [-80.991821318476497, 35.528746011632286, 0.0],
              [-80.992157385109294, 35.52865401143427, 0.0],
              [-80.992269117858143, 35.52849361174875, 0.0],
              [-80.992324318265347, 35.527921011677741, 0.0],
              [-80.992771784194304, 35.527348011938848, 0.0],
              [-80.9927985178428, 35.526431811773634, 0.0],
              [-80.992882384617545, 35.526294278346192, 0.0],
              [-80.992881384564328, 35.52567601157731, 0.0],
              [-80.992712718493038, 35.525286811120871, 0.0],
              [-80.992824117842773, 35.524828611048129, 0.0],
              [-80.993132585046808, 35.525011611637261, 0.0],
              [-80.993357385268197, 35.525446411370396, 0.0],
              [-80.993583384170236, 35.526728811059002, 0.0],
              [-80.993891317739227, 35.526636811894193, 0.0],
              [-80.99419898525376, 35.52622441113418, 0.0],
              [-80.994310384663265, 35.525811878537702, 0.0],
              [-80.994787184776314, 35.526177877785386, 0.0],
              [-80.994759584266887, 35.526452811049261, 0.0],
              [-80.994228518000071, 35.527254878672558, 0.0],
              [-80.994173384658595, 35.52791907796496, 0.0],
              [-80.994061584880995, 35.528079611836269, 0.0],
              [-80.994033984607327, 35.528400211594438, 0.0],
              [-80.994202584149363, 35.528697878212391, 0.0],
              [-80.995100384958036, 35.529498478306692, 0.0],
              [-80.995548584859847, 35.52949807841253, 0.0],
              [-80.995772318508998, 35.529268878149615, 0.0],
              [-80.995826384506046, 35.527871678462219, 0.0],
              [-80.995910318567084, 35.527780011962825, 0.0],
              [-80.996162518594446, 35.527917077995362, 0.0],
              [-80.996303585018055, 35.528512411473272, 0.0],
              [-80.996415785143455, 35.528603877858032, 0.0],
              [-80.997255517942122, 35.528122078503465, 0.0],
              [-80.998094918273893, 35.527365477985498, 0.0],
              [-80.998486318640829, 35.526815278518427, 0.0],
              [-80.998681318597619, 35.52610521197964, 0.0],
              [-80.999184918396139, 35.525669477846144, 0.0],
              [-80.999408517969258, 35.525302878037444, 0.0],
              [-80.99940758440917, 35.524776078311632, 0.0],
              [-80.999323584318034, 35.524730411221483, 0.0],
              [-80.999350984171826, 35.52440967772133, 0.0],
              [-80.999798784160205, 35.524409212018753, 0.0],
              [-81.00033138502107, 35.523860078295954, 0.0],
              [-81.000835717481721, 35.523883477846788, 0.0],
              [-81.000807385205633, 35.524135411495088, 0.0],
              [-81.000385784414547, 35.524959478131542, 0.0],
              [-81.000216784327037, 35.525554811639516, 0.0],
              [-80.999798784479736, 35.526081078055242, 0.0],
              [-80.99960591782343, 35.526195811410332, 0.0],
              [-80.999242717679337, 35.526814611734515, 0.0],
              [-80.999299517868849, 35.527295478581941, 0.0],
              [-80.999411784147043, 35.52747861145091, 0.0],
              [-80.999412318601756, 35.527776277721522, 0.0],
              [-80.998712118235929, 35.527983278228156, 0.0],
              [-80.998544584193723, 35.528327011597234, 0.0],
              [-80.998657384300785, 35.528807811274305, 0.0],
              [-80.999798785164941, 35.52965401176791, 0.0],
              [-81.000182584543495, 35.529608611607621, 0.0],
              [-81.001080184505824, 35.528899478326757, 0.0],
              [-81.001640718582323, 35.528716878397638, 0.0],
              [-81.002229185191354, 35.528694611997068, 0.0],
              [-81.005394184244224, 35.529201611429663, 0.0],
              [-81.006318384406597, 35.529431477760582, 0.0],
              [-81.006850184414333, 35.529798478448505, 0.0],
              [-81.006877584991514, 35.530233678620007, 0.0],
              [-81.006652984398073, 35.530577011865937, 0.0],
              [-81.006288918164401, 35.530553811733682, 0.0],
              [-81.004328317797771, 35.530116611316636, 0.0],
              [-81.003432117675615, 35.529840878214699, 0.0],
              [-81.002003518341326, 35.529679211972386, 0.0],
              [-81.001835185190743, 35.529770611551072, 0.0],
              [-81.001694584283399, 35.530136878306038, 0.0],
              [-81.001861184143365, 35.53112187797128, 0.0],
              [-81.001636917481804, 35.531190278006534, 0.0],
              [-81.000909318236722, 35.530617011424589, 0.0],
              [-81.000292717563624, 35.53079961164331, 0.0],
              [-80.999798784861454, 35.53079907804932, 0.0],
              [-80.998940917664584, 35.530914478674312, 0.0],
              [-80.99832498529959, 35.531327477983616, 0.0],
              [-80.998212785240838, 35.531236011353329, 0.0],
              [-80.998155385154178, 35.530274011952343, 0.0],
              [-80.997762584297249, 35.52990801126532, 0.0],
              [-80.996894317832044, 35.530069277944861, 0.0],
              [-80.996026584143635, 35.530665678400766, 0.0],
              [-80.995466518242281, 35.53080361146651, 0.0],
              [-80.995298717974663, 35.530987011486509, 0.0],
              [-80.994149717531343, 35.530827811819165, 0.0],
              [-80.993701184128014, 35.530645011813021, 0.0],
              [-80.992972718379534, 35.530600011573711, 0.0],
              [-80.992356117869875, 35.530440277880842, 0.0],
              [-80.991263318198506, 35.530395478519786, 0.0],
              [-80.990226318479515, 35.530007211902628, 0.0],
              [-80.989750118116376, 35.530099277955443, 0.0],
              [-80.989162118551008, 35.530397611952289, 0.0],
              [-80.988685984793293, 35.530535478574514, 0.0],
              [-80.988714384138646, 35.530764478085729, 0.0],
              [-80.991517584377434, 35.531769411264385, 0.0],
              [-80.991966584205542, 35.532318678678671, 0.0],
              [-80.9929767182646, 35.533302478592766, 0.0],
              [-80.99297698461811, 35.533485811319636, 0.0],
              [-80.99252851772134, 35.533371678160222, 0.0],
              [-80.991939317906684, 35.532822611045766, 0.0],
              [-80.991238518107124, 35.532502611838936, 0.0],
              [-80.990285517814172, 35.532205811211398, 0.0],
              [-80.989725184699907, 35.532275078082328, 0.0],
              [-80.98969751804205, 35.532527077999632, 0.0],
              [-80.988856785037356, 35.53248207780949, 0.0],
              [-80.988184518439283, 35.532551411485088, 0.0],
              [-80.987848517856293, 35.532689211953503, 0.0],
              [-80.987933584224237, 35.533353278222663, 0.0],
              [-80.987345918040191, 35.533880478468468, 0.0],
              [-80.987346584683308, 35.534361478274214, 0.0],
              [-80.987991718275765, 35.534887678007415, 0.0],
              [-80.987992584906792, 35.535529011677774, 0.0],
              [-80.987908717906393, 35.535643611562776, 0.0],
              [-80.98793698526805, 35.535872611141947, 0.0],
              [-80.988105385252823, 35.536032811140423, 0.0],
              [-80.988077984865981, 35.536513678228488, 0.0],
              [-80.988806317858348, 35.536398478167925, 0.0],
              [-80.989030585245771, 35.536444078153096, 0.0],
              [-80.988974984272119, 35.536696078276456, 0.0],
              [-80.98866711774302, 35.537017011144655, 0.0],
              [-80.987827117887406, 35.537384211876407, 0.0],
              [-80.987238917959615, 35.537499278586758, 0.0],
              [-80.987015184135771, 35.537866011511127, 0.0],
              [-80.986904317571671, 35.538690611931997, 0.0],
              [-80.986680517692136, 35.53901141107449, 0.0],
              [-80.986680918271546, 35.539309211169858, 0.0],
              [-80.987157918292382, 35.539698078306607, 0.0],
              [-80.987074717510851, 35.540385278146736, 0.0],
              [-80.987578984921299, 35.540270278005153, 0.0],
              [-80.988390717727071, 35.539605411263608, 0.0],
              [-80.989006785307865, 35.539444478622848, 0.0],
              [-80.989370917881033, 35.539260811540451, 0.0],
              [-80.98976251806603, 35.538825278352292, 0.0],
              [-80.990182384285092, 35.5385500113982, 0.0],
              [-80.990238118005465, 35.538298011584224, 0.0],
              [-80.990461984368594, 35.538160411016932, 0.0],
              [-80.990658318339868, 35.538206078142011, 0.0],
              [-80.991022385196416, 35.538114011736582, 0.0],
              [-80.991973917687332, 35.537265678353769, 0.0],
              [-80.992394184893826, 35.53733401178529, 0.0],
              [-80.99236638525656, 35.537517211760715, 0.0],
              [-80.991974784536879, 35.537929878536438, 0.0],
              [-80.99079931842499, 35.538870078561601, 0.0],
              [-80.990519784700467, 35.539351278337506, 0.0],
              [-80.990548317649996, 35.539694811092659, 0.0],
              [-80.990912985174461, 35.540015078409787, 0.0],
              [-80.991025585227661, 35.54031281134403, 0.0],
              [-80.991923118456455, 35.540907411098893, 0.0],
              [-80.9919793176757, 35.541067611817162, 0.0],
              [-80.992540518430744, 35.541548011879918, 0.0],
              [-80.99262478430434, 35.541777011098119, 0.0],
              [-80.993297717821051, 35.54195947851354, 0.0],
              [-80.993325985249228, 35.54216561134804, 0.0],
              [-80.993774718006691, 35.54248581160585, 0.0],
              [-80.993859384521826, 35.542920878221615, 0.0],
              [-80.9940839175094, 35.54310387824885, 0.0],
              [-80.994476118408002, 35.543034811199746, 0.0],
              [-80.99520338438974, 35.542186611340867, 0.0],
              [-80.996547917613341, 35.541818811435647, 0.0],
              [-80.996883784122701, 35.541589411655345, 0.0],
              [-80.997527118344607, 35.540833011599197, 0.0],
              [-80.99763798519183, 35.540031211372352, 0.0],
              [-80.997890184466272, 35.540053877803039, 0.0],
              [-80.998226918346901, 35.540328411237986, 0.0],
              [-80.99856298461377, 35.540282211977114, 0.0],
              [-80.998842584143389, 35.539801011723554, 0.0],
              [-80.999150584118084, 35.539686211652558, 0.0],
              [-80.999430318213243, 35.53938821152326, 0.0],
              [-80.999798917520494, 35.539181678376387, 0.0],
              [-80.999971585302916, 35.539159011431565, 0.0],
              [-81.000112184458004, 35.538884078520034, 0.0],
              [-81.000225317672644, 35.538243011365068, 0.0],
              [-81.000450184274484, 35.537739411740461, 0.0],
              [-81.001264318058432, 35.536686678632321, 0.0],
              [-81.00148871809732, 35.536572411298337, 0.0],
              [-81.001712785045527, 35.536595611282841, 0.0],
              [-81.001712317599967, 35.536893277728936, 0.0],
              [-81.001178918377633, 35.537556878313445, 0.0],
              [-81.000981984661067, 35.538152211174825, 0.0],
              [-81.001008517470865, 35.53913701173154, 0.0],
              [-81.001204118631577, 35.53948081108738, 0.0],
              [-81.001596117612124, 35.539710211296274, 0.0],
              [-81.001820118562094, 35.539779211303035, 0.0],
              [-81.002772718145479, 35.539734411943975, 0.0],
              [-81.003472784956998, 35.540078611673621, 0.0],
              [-81.004620384350332, 35.540812611709448, 0.0],
              [-81.00464791862079, 35.541202011787981, 0.0],
              [-81.003947518208903, 35.54113247815495, 0.0],
              [-81.001790985167915, 35.540489211490844, 0.0],
              [-81.001006985111985, 35.540099011386985, 0.0],
              [-81.000418384274425, 35.540167078540996, 0.0],
              [-80.999798918050899, 35.540441278322056, 0.0],
              [-80.999040118335287, 35.540739811041, 0.0],
              [-80.998732184289068, 35.540992011477144, 0.0],
              [-80.998200185112225, 35.541175811728451, 0.0],
              [-80.998116384824684, 35.541404877925714, 0.0],
              [-80.998201117600345, 35.541817078435827, 0.0],
              [-80.998509784279506, 35.542114478533016, 0.0],
              [-80.99859471800319, 35.542687077810896, 0.0],
              [-80.999156384813247, 35.543465078620123, 0.0],
              [-80.999212717994922, 35.543671211025583, 0.0],
              [-80.99938111796655, 35.543854277905382, 0.0],
              [-80.999381318329966, 35.543991678322861, 0.0],
              [-80.999044918254711, 35.54392327770239, 0.0],
              [-80.998147318589147, 35.54323721179238, 0.0],
              [-80.998090918104225, 35.543008078433132, 0.0],
              [-80.997754118109796, 35.542665011607099, 0.0],
              [-80.997557717777781, 35.54257347799318, 0.0],
              [-80.996633117616597, 35.542643211331971, 0.0],
              [-80.996437385032252, 35.542872411340497, 0.0],
              [-80.995792984417747, 35.542964678427317, 0.0],
              [-80.995681384503513, 35.543239611665236, 0.0],
              [-80.995373585208412, 35.54351487843995, 0.0],
              [-80.995430518583902, 35.544087411538236, 0.0],
              [-80.995739517950241, 35.54470541119948, 0.0],
              [-80.995740384869919, 35.545232077778252, 0.0],
              [-80.99529171817133, 35.545003611564916, 0.0],
              [-80.995066917538168, 35.544614478237158, 0.0],
              [-80.99478638476495, 35.544408611832033, 0.0],
              [-80.994085918604739, 35.544500877756931, 0.0],
              [-80.993777384140046, 35.544249278226552, 0.0],
              [-80.993384718452504, 35.544066411798269, 0.0],
              [-80.993160184218425, 35.543769011505844, 0.0],
              [-80.992543318423756, 35.543494677748029, 0.0],
              [-80.992234585108278, 35.543197411967739, 0.0],
              [-80.991757984827885, 35.54301461182213, 0.0],
              [-80.990496718177852, 35.542786811754119, 0.0],
              [-80.990328585129177, 35.542832678427359, 0.0],
              [-80.990357117523928, 35.543107478604703, 0.0],
              [-80.990553584870483, 35.543382211179399, 0.0],
              [-80.990469984845944, 35.543657211216988, 0.0],
              [-80.989965917927478, 35.543932477685125, 0.0],
              [-80.989181185177131, 35.543864478391846, 0.0],
              [-80.988789118289816, 35.544002278027463, 0.0],
              [-80.988621318074252, 35.544231478187264, 0.0],
              [-80.988761917790427, 35.544597811293052, 0.0],
              [-80.988734317986086, 35.544895478557386, 0.0],
              [-80.98783838500016, 35.545469011470146, 0.0],
              [-80.98772678471164, 35.545835478101026, 0.0],
              [-80.98778431801064, 35.546797411459778, 0.0],
              [-80.987981318585909, 35.547484278012703, 0.0],
              [-80.98929991860328, 35.548559411093414, 0.0],
              [-80.989973518037132, 35.54924587842779, 0.0],
              [-80.991655984457708, 35.550000011421226, 0.0],
              [-80.99210478464687, 35.550297278331499, 0.0],
              [-80.992105318214286, 35.550526278344705, 0.0],
              [-80.990675318382145, 35.550023878051505, 0.0],
              [-80.989974518389815, 35.5499100780901, 0.0],
              [-80.989581917634268, 35.549704278644008, 0.0],
              [-80.989021318455428, 35.549613277732426, 0.0],
              [-80.988572718553101, 35.54943047798178, 0.0],
              [-80.988068384655406, 35.549545478212707, 0.0],
              [-80.987956184649619, 35.549476878500677, 0.0],
              [-80.987843184288536, 35.548812811094834, 0.0],
              [-80.9876185184131, 35.548469478117013, 0.0],
              [-80.987141784255655, 35.54821801165626, 0.0],
              [-80.98702891832329, 35.547668411028887, 0.0],
              [-80.986523318167627, 35.546913078287872, 0.0],
              [-80.986243117987229, 35.546890477893257, 0.0],
              [-80.986159118063441, 35.547005011083385, 0.0],
              [-80.986104718223359, 35.548012877704487, 0.0],
              [-80.985880518049001, 35.548013078499807, 0.0],
              [-80.984982718392658, 35.547281011099543, 0.0],
              [-80.984674318445826, 35.547166811619633, 0.0],
              [-80.984394184324586, 35.547281611033718, 0.0],
              [-80.984002984387374, 35.548129278407494, 0.0],
              [-80.983750918349983, 35.548198211697233, 0.0],
              [-80.983526184595704, 35.54783201113284, 0.0],
              [-80.983273718195363, 35.547626078593005, 0.0],
              [-80.98316091766479, 35.547076611803199, 0.0],
              [-80.982572785094433, 35.547329011019016, 0.0],
              [-80.982208518282533, 35.547329278240646, 0.0],
              [-80.981983584932451, 35.54684847818762, 0.0],
              [-80.981674985165711, 35.546528211184459, 0.0],
              [-80.981254385047023, 35.54632247801748, 0.0],
              [-80.981169518634502, 35.545681277915584, 0.0],
              [-80.981029318203724, 35.545658478474557, 0.0],
              [-80.980357117554675, 35.54593387819925, 0.0],
              [-80.980077318099802, 35.546186011040021, 0.0],
              [-80.979881117504476, 35.546209211563038, 0.0],
              [-80.979656585115606, 35.545980278530372, 0.0],
              [-80.979683918342985, 35.545407811094528, 0.0],
              [-80.980047717782426, 35.54497227796292, 0.0],
              [-80.980131385350646, 35.544697411976671, 0.0],
              [-80.979906917834057, 35.54451427805369, 0.0],
              [-80.979234117920413, 35.544285878560146, 0.0],
              [-80.978841984938086, 35.544400677906083, 0.0],
              [-80.978842185239372, 35.544629678207244, 0.0],
              [-80.979066918059146, 35.544973078180497, 0.0],
              [-80.97903978414692, 35.545728878238798, 0.0],
              [-80.978620118490113, 35.546210278305622, 0.0],
              [-80.978564717608862, 35.546691411699761, 0.0],
              [-80.978452585304211, 35.54673721170343, 0.0],
              [-80.977779918092239, 35.546577477746979, 0.0],
              [-80.977527785165833, 35.546669278354962, 0.0],
              [-80.977387918353315, 35.54685261127932, 0.0],
              [-80.977528584295129, 35.547379277877461, 0.0],
              [-80.977473184507119, 35.547837277789938, 0.0],
              [-80.977277384396302, 35.548135211256373, 0.0],
              [-80.977138717653872, 35.549189011837925, 0.0],
              [-80.97624191861712, 35.549166811740371, 0.0],
              [-80.975456784353227, 35.54884681100819, 0.0],
              [-80.974027584154157, 35.548848011490698, 0.0],
              [-80.973775584402219, 35.548962811248167, 0.0],
              [-80.973663718539882, 35.549123211955354, 0.0],
              [-80.973692584465155, 35.549879011864611, 0.0],
              [-80.974056985017413, 35.550016211452679, 0.0],
              [-80.974813918389927, 35.550107011676211, 0.0],
              [-80.975206384439929, 35.550244078120777, 0.0],
              [-80.976271318636421, 35.55028901166596, 0.0],
              [-80.976495585308484, 35.550426277845659, 0.0],
              [-80.976496517749126, 35.551113277862775, 0.0],
              [-80.976580718492016, 35.551182011149344, 0.0],
              [-80.97739351805501, 35.551272811857508, 0.0],
              [-80.978150718263564, 35.551684477685804, 0.0],
              [-80.978514917537552, 35.551638278378277, 0.0],
              [-80.978822984301303, 35.551500677859288, 0.0],
              [-80.979214784265537, 35.551065211235638, 0.0],
              [-80.979887184162905, 35.550881411079729, 0.0],
              [-80.980643917976266, 35.550880677781691, 0.0],
              [-80.980615984622574, 35.551063878078942, 0.0],
              [-80.980112117724389, 35.551453678627027, 0.0],
              [-80.979636584503893, 35.552141278246594, 0.0],
              [-80.978796784196575, 35.552874811591188, 0.0],
              [-80.978769318629588, 35.553264278600608, 0.0],
              [-80.979274384355193, 35.553721878037251, 0.0],
              [-80.979778585261613, 35.55358401150206, 0.0],
              [-80.980842317493753, 35.552621211758051, 0.0],
              [-80.981570517735634, 35.552322678090881, 0.0],
              [-80.981682784251063, 35.552437211462063, 0.0],
              [-80.98081498518259, 35.553193678473718, 0.0],
              [-80.980731384920517, 35.553537278108102, 0.0],
              [-80.980815584758801, 35.553628811650611, 0.0],
              [-80.980760184159635, 35.554109878157355, 0.0],
              [-80.980452384154034, 35.554522411970289, 0.0],
              [-80.980649118261752, 35.554888678579886, 0.0],
              [-80.98062158449352, 35.555300878130424, 0.0],
              [-80.980453718497742, 35.555553011851138, 0.0],
              [-80.980454184948755, 35.555873678475372, 0.0],
              [-80.980538384747817, 35.555942277851017, 0.0],
              [-80.981743117789549, 35.555689278367424, 0.0],
              [-80.981715784231397, 35.556147411670622, 0.0],
              [-80.981408118685934, 35.556674411904226, 0.0],
              [-80.981325517771452, 35.557705078091551, 0.0],
              [-80.981381585077187, 35.557773811149801, 0.0],
              [-80.982053784652393, 35.557429611065771, 0.0],
              [-80.98255711780719, 35.556581811139367, 0.0],
              [-80.982809317544422, 35.556489878278697, 0.0],
              [-80.983117717720802, 35.55667287789808, 0.0],
              [-80.983509984893061, 35.556580877757824, 0.0],
              [-80.983845918081983, 35.556328611649498, 0.0],
              [-80.983873918341573, 35.556214078540698, 0.0],
              [-80.983480917572763, 35.555825078312985, 0.0],
              [-80.983396385263006, 35.555412878568134, 0.0],
              [-80.983648384470882, 35.555321078371236, 0.0],
              [-80.98406911765278, 35.555526878424367, 0.0],
              [-80.984433317534766, 35.555457811146589, 0.0],
              [-80.984600984633104, 35.55511421182446, 0.0],
              [-80.984825317973375, 35.555136811705303, 0.0],
              [-80.985162384366944, 35.555709077853834, 0.0],
              [-80.985807117798046, 35.555800078303292, 0.0],
              [-80.986535518367816, 35.555662011149295, 0.0],
              [-80.986927584489877, 35.555432611973323, 0.0],
              [-80.987151984858343, 35.555592678521698, 0.0],
              [-80.987880718156674, 35.55559201162405, 0.0],
              [-80.988356984840607, 35.555477011639447, 0.0],
              [-80.988972785328031, 35.554926678251995, 0.0],
              [-80.989196985079985, 35.554926611196947, 0.0],
              [-80.989449317630474, 35.555040811109237, 0.0],
              [-80.989085784886882, 35.555567878555578, 0.0],
              [-80.989085984940289, 35.555751077984908, 0.0],
              [-80.989506718038683, 35.555911011406238, 0.0],
              [-80.989506917860737, 35.5560942118247, 0.0],
              [-80.989703317499433, 35.556231478302053, 0.0],
              [-80.989647384580863, 35.556369011121632, 0.0],
              [-80.98866658417549, 35.556415811600019, 0.0],
              [-80.987546117916736, 35.556806211367771, 0.0],
              [-80.986537118131551, 35.556807078099112, 0.0],
              [-80.985948985170651, 35.557059611447698, 0.0],
              [-80.985248984717984, 35.557564078375876, 0.0],
              [-80.984296785133367, 35.558068878206541, 0.0],
              [-80.983205117880843, 35.559146278583817, 0.0],
              [-80.982224985147695, 35.55971981134055, 0.0],
              [-80.982028985293894, 35.55994901185349, 0.0],
              [-80.98155298419347, 35.560224278498588, 0.0],
              [-80.98155318416903, 35.560430411759562, 0.0],
              [-80.981973984553136, 35.560704811813494, 0.0],
              [-80.982478318569051, 35.560589811847684, 0.0],
              [-80.983458317551182, 35.559787411813524, 0.0],
              [-80.983934384746888, 35.559557811714178, 0.0],
              [-80.984410318175705, 35.559168078045609, 0.0],
              [-80.984858584752857, 35.558984478329016, 0.0],
              [-80.986567918027504, 35.558799677726398, 0.0],
              [-80.986848517489932, 35.559005477860822, 0.0],
              [-80.986877184229584, 35.559532277944037, 0.0],
              [-80.987017718436178, 35.559784078428194, 0.0],
              [-80.986850118407105, 35.560104811349611, 0.0],
              [-80.986878384707964, 35.560379678531916, 0.0],
              [-80.987018785314547, 35.560562678145274, 0.0],
              [-80.987075717554276, 35.561135211893955, 0.0],
              [-80.987889784863398, 35.562050611185569, 0.0],
              [-80.987862584374525, 35.562668878260112, 0.0],
              [-80.987750784636575, 35.562944011425294, 0.0],
              [-80.987751717521007, 35.563539478610572, 0.0],
              [-80.987976184609295, 35.563745278047939, 0.0],
              [-80.987948517717513, 35.563974277798721, 0.0],
              [-80.987220917704519, 35.56482247783903, 0.0],
              [-80.98722138438896, 35.565166011316443, 0.0],
              [-80.987614185173697, 35.565509077932298, 0.0],
              [-80.987838517991008, 35.565509011686053, 0.0],
              [-80.988818984197039, 35.565141478295232, 0.0],
              [-80.990584717556644, 35.565048278063891, 0.0],
              [-80.991033317793139, 35.565139477860747, 0.0],
              [-80.99176178424328, 35.564932611378495, 0.0],
              [-80.991873318158085, 35.564474411642067, 0.0],
              [-80.991789118116316, 35.564382811632449, 0.0],
              [-80.991788317744493, 35.563901878489311, 0.0],
              [-80.992488185154855, 35.563305811414125, 0.0],
              [-80.992599784688124, 35.562962211344832, 0.0],
              [-80.99285178462398, 35.562755678234623, 0.0],
              [-80.993664318660237, 35.562525878475277, 0.0],
              [-80.994000185164722, 35.562273611312811, 0.0],
              [-80.994111918335051, 35.561998678316861, 0.0],
              [-80.994026784436656, 35.561243011826953, 0.0],
              [-80.993942518626369, 35.56112861119135, 0.0],
              [-80.993969985207627, 35.560762077712738, 0.0],
              [-80.994165718138134, 35.560464211662406, 0.0],
              [-80.993800184226188, 35.559685811618174, 0.0],
              [-80.993771518164394, 35.559227678428925, 0.0],
              [-80.993602918016407, 35.558884411161614, 0.0],
              [-80.992592785119641, 35.558152478335991, 0.0],
              [-80.992676585223407, 35.55801501193497, 0.0],
              [-80.99306911818833, 35.558060411146883, 0.0],
              [-80.993854384533321, 35.55842607767579, 0.0],
              [-80.994471585072787, 35.558837677903206, 0.0],
              [-80.995117318169534, 35.559570011149972, 0.0],
              [-80.995960318338064, 35.560943211216902, 0.0],
              [-80.996268917553323, 35.56117201191811, 0.0],
              [-80.996689118616032, 35.561057011130899, 0.0],
              [-80.996912384786924, 35.560507077696471, 0.0],
              [-80.996883184934845, 35.559705611884667, 0.0],
              [-80.997191118374872, 35.55940761100738, 0.0],
              [-80.997274385089469, 35.558926478544464, 0.0],
              [-80.997386318454431, 35.558743078018765, 0.0],
              [-80.997301584254103, 35.558353878058803, 0.0],
              [-80.996908584659792, 35.557942011493964, 0.0],
              [-80.996936318444199, 35.557713011741832, 0.0],
              [-80.99786138524621, 35.557872278133935, 0.0],
              [-80.998057384252292, 35.557803411448397, 0.0],
              [-80.998057184614083, 35.557597278368291, 0.0],
              [-80.997860718437551, 35.557391411486591, 0.0],
              [-80.997747985197051, 35.557002211415153, 0.0],
              [-80.997270518043734, 35.55636141122833, 0.0],
              [-80.996793517629627, 35.55594967825558, 0.0],
              [-80.996148317841786, 35.555583877938084, 0.0],
              [-80.99555951772048, 35.555378278595349, 0.0],
              [-80.995475184708283, 35.555241011715857, 0.0],
              [-80.995559118388115, 35.55517221191738, 0.0],
              [-80.996035785060428, 35.55526327789083, 0.0],
              [-80.997858317743137, 35.555879811246903, 0.0],
              [-80.998362717470641, 35.555787677674395, 0.0],
              [-80.998502718504128, 35.555673011046785, 0.0],
              [-80.998473318444681, 35.554802678281447, 0.0],
              [-80.998276784751852, 35.554596878252489, 0.0],
              [-80.998276517611345, 35.554413611462763, 0.0],
              [-80.998920917520707, 35.554252611528618, 0.0],
              [-80.998892318031622, 35.554023611551408, 0.0],
              [-80.998555585098615, 35.55368041180985, 0.0],
              [-80.997741918045648, 35.553108677934617, 0.0],
              [-80.997573385281072, 35.552857011102944, 0.0],
              [-80.997208784872058, 35.552628278081386, 0.0],
              [-80.99718038479287, 35.552422211812157, 0.0],
              [-80.997600918131582, 35.552513411024741, 0.0],
              [-80.998778984809206, 35.553245078229615, 0.0],
              [-80.999367518476106, 35.553129878204658, 0.0],
              [-80.999479717455827, 35.553244411446805, 0.0],
              [-80.999620384751651, 35.553633478049512, 0.0],
              [-80.999798918504382, 35.553679211821759, 0.0],
              [-81.000453784493914, 35.553588278172228, 0.0],
              [-81.000931184788925, 35.552947411538895, 0.0],
              [-81.001072718337099, 35.552054411288864, 0.0],
              [-81.001185318345662, 35.551710877747226, 0.0],
              [-81.001297517780557, 35.55164227804169, 0.0],
              [-81.001521718047201, 35.55166547776448, 0.0],
              [-81.001548917488165, 35.552215077669217, 0.0],
              [-81.001464584802676, 35.552329478039425, 0.0],
              [-81.001463718118174, 35.552925011612025, 0.0],
              [-81.001547584135054, 35.553085411077113, 0.0],
              [-81.00168758464676, 35.553154278352288, 0.0],
              [-81.002136118413517, 35.553063077993258, 0.0],
              [-81.003426517741971, 35.552194211570317, 0.0],
              [-81.003538918225459, 35.551988078593219, 0.0],
              [-81.003539385041762, 35.551690411213954, 0.0],
              [-81.002979584141571, 35.551254678371699, 0.0],
              [-81.002951784328019, 35.551117211289196, 0.0],
              [-81.003120184938268, 35.550957077880796, 0.0],
              [-81.003792384113979, 35.551141011155934, 0.0],
              [-81.005079917489113, 35.552287411794104, 0.0],
              [-81.005416317880474, 35.55219621101012, 0.0],
              [-81.005697517715177, 35.551578078516087, 0.0],
              [-81.005670784338065, 35.550661811594196, 0.0],
              [-81.005391318424998, 35.550157677942906, 0.0],
              [-81.005419785080349, 35.549860011740456, 0.0],
              [-81.005783984731877, 35.549952011142381, 0.0],
              [-81.006399584899242, 35.550525211777519, 0.0],
              [-81.006735917574233, 35.550525477852673, 0.0],
              [-81.007100517944934, 35.550342611979467, 0.0],
              [-81.008334984434015, 35.549381877952328, 0.0],
              [-81.009540984912817, 35.548650211724159, 0.0],
              [-81.011252384597825, 35.54716301171451, 0.0],
              [-81.011420784575975, 35.54707161162785, 0.0],
              [-81.011728917795992, 35.547094811313805, 0.0],
              [-81.011840784689213, 35.547255210998749, 0.0],
              [-81.011671918064195, 35.547850478539097, 0.0],
              [-81.01119471775138, 35.548376877978384, 0.0],
              [-81.010605518249449, 35.548857278160547, 0.0],
              [-81.010465117544726, 35.549200677735477, 0.0],
              [-81.010660317558589, 35.54979641188423, 0.0],
              [-81.010772317453913, 35.549888077766553, 0.0],
              [-81.010771984894618, 35.550117078128672, 0.0],
              [-81.010351584101954, 35.550070878525837, 0.0],
              [-81.009931717856091, 35.549841477705968, 0.0],
              [-81.009651385094131, 35.549887011532235, 0.0],
              [-81.009482584781622, 35.550322011874599, 0.0],
              [-81.009370384243539, 35.550390611432569, 0.0],
              [-81.008529584636634, 35.550481411614861, 0.0],
              [-81.008164984351922, 35.55066427786555, 0.0],
              [-81.005611184345199, 35.553066611043867, 0.0],
              [-81.00563891777611, 35.553318477663431, 0.0],
              [-81.00597458463821, 35.553708211845297, 0.0],
              [-81.005974184520099, 35.553914278391808, 0.0],
              [-81.005637518426752, 35.554188811695255, 0.0],
              [-81.005469518643039, 35.554119878383091, 0.0],
              [-81.005385717630261, 35.553913678614883, 0.0],
              [-81.004993918505676, 35.55359281146653, 0.0],
              [-81.004573718439119, 35.553409078261026, 0.0],
              [-81.003901318028042, 35.553271011456999, 0.0],
              [-81.003340717899036, 35.553385011048896, 0.0],
              [-81.002050518257221, 35.554070811505817, 0.0],
              [-81.001966118564866, 35.554299811490992, 0.0],
              [-81.002160718165726, 35.555261811210535, 0.0],
              [-81.00210438525329, 35.555490811329022, 0.0],
              [-81.001768184972633, 35.555398877993305, 0.0],
              [-81.001208784463458, 35.554642478574927, 0.0],
              [-81.001012785233698, 35.554550678249406, 0.0],
              [-81.000732318623236, 35.554710678014104, 0.0],
              [-81.00017071837415, 35.555511678118044, 0.0],
              [-81.00000138419388, 35.556175678251471, 0.0],
              [-81.000252785129121, 35.556748477978232, 0.0],
              [-81.001231717666215, 35.558032211042928, 0.0],
              [-81.001118717716736, 35.558696211729277, 0.0],
              [-81.000922518501312, 35.55869601105713, 0.0],
              [-81.000670918244367, 35.558283477799691, 0.0],
              [-81.000138384977632, 35.558282878043407, 0.0],
              [-80.999798985023844, 35.558351278183665, 0.0],
              [-80.999599984766007, 35.558466011633776, 0.0],
              [-80.999460317673339, 35.558786811249078, 0.0],
              [-80.999405185080747, 35.559382277720829, 0.0],
              [-80.998985317847726, 35.559703411072256, 0.0],
              [-80.999182184498835, 35.56016121149748, 0.0],
              [-80.999126318566752, 35.560253011080441, 0.0],
              [-80.998594117732054, 35.560505411350704, 0.0],
              [-80.99856711850299, 35.561100877922456, 0.0],
              [-80.998062918200446, 35.561353410973979, 0.0],
              [-80.997895518294584, 35.561857411411829, 0.0],
              [-80.996467318565522, 35.562683411749305, 0.0],
              [-80.99537558412122, 35.563600611319664, 0.0],
              [-80.995151784150195, 35.563921477708028, 0.0],
              [-80.995152117720494, 35.564081811447821, 0.0],
              [-80.995292385091545, 35.564196077813286, 0.0],
              [-80.99565658429988, 35.564104211906233, 0.0],
              [-80.995880385189295, 35.563829078108974, 0.0],
              [-80.996440718637714, 35.563576611919949, 0.0],
              [-80.996552518037802, 35.563370411587847, 0.0],
              [-80.996944317960441, 35.563072277661263, 0.0],
              [-80.997504718449463, 35.562888478154242, 0.0],
              [-80.997672985176393, 35.562957011592431, 0.0],
              [-80.998289385053795, 35.562796011235555, 0.0],
              [-80.99856931843604, 35.562612411538147, 0.0],
              [-80.998793717853474, 35.562681011539986, 0.0],
              [-80.99873878515757, 35.563459678362619, 0.0],
              [-80.999328117673102, 35.563871277805006, 0.0],
              [-80.999412585160044, 35.564192011805147, 0.0],
              [-80.999637117564916, 35.564329077736488, 0.0],
              [-80.999798918378417, 35.564306011742943, 0.0],
              [-81.00029811770149, 35.563756811370432, 0.0],
              [-81.000410785301924, 35.563344677915161, 0.0],
              [-81.000271118465392, 35.563001078375272, 0.0],
              [-81.000272117583165, 35.562405611428595, 0.0],
              [-81.000412585157491, 35.562176677713893, 0.0],
              [-81.000748918482032, 35.562154078063834, 0.0],
              [-81.001588984717884, 35.562704678560188, 0.0],
              [-81.00259811798162, 35.562636877768064, 0.0],
              [-81.003018984444921, 35.562339678284012, 0.0],
              [-81.003159718478983, 35.561973411636821, 0.0],
              [-81.003273117719857, 35.561126011400326, 0.0],
              [-81.003021118406721, 35.560919611134828, 0.0],
              [-81.003021384371834, 35.560736411665843, 0.0],
              [-81.003414184831996, 35.5605078114816, 0.0],
              [-81.003470784893082, 35.560095611513262, 0.0],
              [-81.003331518210373, 35.559545877919355, 0.0],
              [-81.003471717916867, 35.559454277917411, 0.0],
              [-81.003807984775648, 35.559523411342006, 0.0],
              [-81.004059718063928, 35.559935877884463, 0.0],
              [-81.00436758444917, 35.560119478520733, 0.0],
              [-81.004647918409873, 35.56014261190429, 0.0],
              [-81.005965784582699, 35.559617211734661, 0.0],
              [-81.006526984438707, 35.559205411293966, 0.0],
              [-81.006891518261781, 35.559160077900103, 0.0],
              [-81.006862517667855, 35.559778278226077, 0.0],
              [-81.007142517504647, 35.559984811887972, 0.0],
              [-81.007142118368691, 35.560168011782501, 0.0],
              [-81.005936517901404, 35.560464478143473, 0.0],
              [-81.005095318158865, 35.560807211203759, 0.0],
              [-81.004393918129907, 35.561356211099387, 0.0],
              [-81.00414111812232, 35.561699477723003, 0.0],
              [-81.003747184322265, 35.562706878042107, 0.0],
              [-81.003745718538667, 35.563691678442744, 0.0],
              [-81.004025184356564, 35.564150011041036, 0.0],
              [-81.004277318276692, 35.564356410983684, 0.0],
              [-81.004921518017014, 35.564586011874276, 0.0],
              [-81.005201785264333, 35.564586278006779, 0.0],
              [-81.005511318603723, 35.563830811596361, 0.0],
              [-81.005763517502459, 35.563831077999652, 0.0],
              [-81.005762118070635, 35.564793011124507, 0.0],
              [-81.005986318638506, 35.564816011720012, 0.0],
              [-81.006407184801915, 35.564541677832132, 0.0],
              [-81.006687585126443, 35.564496078398832, 0.0],
              [-81.00671511817778, 35.564816811855366, 0.0],
              [-81.006490384171514, 35.565114278495159, 0.0],
              [-81.005312384573543, 35.565640011890984, 0.0],
              [-81.005284184501221, 35.565754411313975, 0.0],
              [-81.005535384319771, 35.566441811918281, 0.0],
              [-81.006067117693945, 35.567037678027361, 0.0],
              [-81.006094984725507, 35.567198078273023, 0.0],
              [-81.006402718166285, 35.567587678124319, 0.0],
              [-81.006626185001664, 35.568068878267987, 0.0],
              [-81.00701818521371, 35.568367011680579, 0.0],
              [-81.007354518261991, 35.568367278244686, 0.0],
              [-81.007579118183656, 35.568184411513009, 0.0],
              [-81.007803317740525, 35.568138677768715, 0.0],
              [-81.007971384882254, 35.568253411649849, 0.0],
              [-81.008195785189088, 35.568185011804822, 0.0],
              [-81.008419985031935, 35.568208011027423, 0.0],
              [-81.00844651756708, 35.569215811388979, 0.0],
              [-81.008614517743496, 35.569376278117574, 0.0],
              [-81.009707518539656, 35.569560478588016, 0.0],
              [-81.009987984249719, 35.569400478040301, 0.0],
              [-81.010296917608699, 35.568965611940357, 0.0],
              [-81.01035371825688, 35.568461878439273, 0.0],
              [-81.010802317874379, 35.568393611588377, 0.0],
              [-81.012006517619625, 35.569173411553891, 0.0],
              [-81.012230584459587, 35.56921941145572, 0.0],
              [-81.012342784506131, 35.569150810977689, 0.0],
              [-81.012427384522354, 35.568830278588351, 0.0],
              [-81.01262371795174, 35.568784611747994, 0.0],
              [-81.013575718535833, 35.569495477934332, 0.0],
              [-81.014192184655769, 35.569633478186574, 0.0],
              [-81.014612917891256, 35.569381878145428, 0.0],
              [-81.014753518339177, 35.569107211672595, 0.0],
              [-81.014950717501804, 35.568328678013899, 0.0],
              [-81.015202984188804, 35.568306011211774, 0.0],
              [-81.015398917654736, 35.568512411042931, 0.0],
              [-81.015538384645367, 35.569016277701664, 0.0],
              [-81.015958118027612, 35.569589211740237, 0.0],
              [-81.015928784109647, 35.570551078222927, 0.0],
              [-81.016265118252022, 35.570574411559797, 0.0],
              [-81.016490517710665, 35.569750011271296, 0.0],
              [-81.016687385088517, 35.569269211352442, 0.0],
              [-81.017388917713845, 35.568697277757117, 0.0],
              [-81.017753317582944, 35.568674811656024, 0.0],
              [-81.017753117485157, 35.568858011220136, 0.0],
              [-81.017332184356832, 35.569178278020814, 0.0],
              [-81.017107517699188, 35.569567411205234, 0.0],
              [-81.017078917940992, 35.569865078467693, 0.0],
              [-81.017218718314126, 35.570163011374611, 0.0],
              [-81.017807118351598, 35.570415411296793, 0.0],
              [-81.01875998510323, 35.570507811685843, 0.0],
              [-81.019012317422536, 35.57043927798339, 0.0],
              [-81.019236917858848, 35.570256411159946, 0.0],
              [-81.019686317872086, 35.569569678556192, 0.0],
              [-81.019966585143649, 35.569524078541143, 0.0],
              [-81.020499118553218, 35.569639078330425, 0.0],
              [-81.020835517467859, 35.569639411161987, 0.0],
              [-81.020975917461001, 35.569433411618242, 0.0],
              [-81.02097631756132, 35.569067011298856, 0.0],
              [-81.020864384681943, 35.568883611791527, 0.0],
              [-81.021313785247742, 35.568174011117627, 0.0],
              [-81.021931317862752, 35.56757907793066, 0.0],
              [-81.02271611846605, 35.567533878397263, 0.0],
              [-81.023108784360957, 35.567328078261056, 0.0],
              [-81.024007384560718, 35.566046277651289, 0.0],
              [-81.024232318075988, 35.565496811740466, 0.0],
              [-81.024261117763075, 35.564924211936976, 0.0],
              [-81.024373584471221, 35.564557878376903, 0.0],
              [-81.025496784202915, 35.562864011014774, 0.0],
              [-81.025637385252224, 35.562497678472909, 0.0],
              [-81.025666784135964, 35.561375411952035, 0.0],
              [-81.025779118153295, 35.56123821182058, 0.0],
              [-81.026648184867753, 35.561032811896588, 0.0],
              [-81.027349784841263, 35.560300478611474, 0.0],
              [-81.027574517788793, 35.559865477804358, 0.0],
              [-81.027687917943339, 35.558811877935362, 0.0],
              [-81.028052784283119, 35.558308411918404, 0.0],
              [-81.028725985013025, 35.557919611805275, 0.0],
              [-81.028754384512553, 35.557599011842022, 0.0],
              [-81.028866584418438, 35.557438811107012, 0.0],
              [-81.029595518150046, 35.557324811663271, 0.0],
              [-81.030100384178553, 35.556935877756338, 0.0],
              [-81.03033258483876, 35.556895477746743, 0.0],
              [-81.030492784572715, 35.556867478351876, 0.0],
              [-81.030492517756159, 35.557165277930707, 0.0],
              [-81.029735117727199, 35.557737211335464, 0.0],
              [-81.02869678481251, 35.558881477752685, 0.0],
              [-81.028500185009065, 35.559247811324497, 0.0],
              [-81.028499985069487, 35.55940807820393, 0.0],
              [-81.028667918420751, 35.559614411816355, 0.0],
              [-81.028639184993395, 35.560164011265044, 0.0],
              [-81.027460584179792, 35.561354011319459, 0.0],
              [-81.027235784094884, 35.56185767821205, 0.0],
              [-81.027263117634362, 35.562453211697679, 0.0],
              [-81.027430784719371, 35.562796878225903, 0.0],
              [-81.028215117755423, 35.563255611563321, 0.0],
              [-81.02874698429072, 35.563759877849932, 0.0],
              [-81.029167585104361, 35.563668611738358, 0.0],
              [-81.029588318459275, 35.563462811413579, 0.0],
              [-81.031410384637482, 35.563235077985759, 0.0],
              [-81.032362984165516, 35.563533611360128, 0.0],
              [-81.033007317473434, 35.563900611359834, 0.0],
              [-81.033119117564624, 35.564175478158212, 0.0],
              [-81.032922584280584, 35.564358611488245, 0.0],
              [-81.032165784147324, 35.564335078231906, 0.0],
              [-81.031437318581723, 35.564128478268699, 0.0],
              [-81.030259985006012, 35.564219078363479, 0.0],
              [-81.029895517454335, 35.564333278186332, 0.0],
              [-81.029642984740164, 35.564608011271503, 0.0],
              [-81.029530384089625, 35.564928478377098, 0.0],
              [-81.02835298514772, 35.565087878172655, 0.0],
              [-81.02737258476165, 35.564560411630225, 0.0],
              [-81.02683998520591, 35.564628611482071, 0.0],
              [-81.026363117990641, 35.56492587851104, 0.0],
              [-81.025801918360017, 35.56558967840769, 0.0],
              [-81.02568938427352, 35.565841478116468, 0.0],
              [-81.025689117923406, 35.566139277673102, 0.0],
              [-81.023948917662395, 35.568015877931543, 0.0],
              [-81.022769785260508, 35.569457811932857, 0.0],
              [-81.022909584617466, 35.569778610966473, 0.0],
              [-81.02285331848573, 35.569915877774982, 0.0],
              [-81.021983984109923, 35.570167078263431, 0.0],
              [-81.021731384841004, 35.570373011243809, 0.0],
              [-81.021590718075529, 35.570808011171948, 0.0],
              [-81.021590117706396, 35.571334811711836, 0.0],
              [-81.02136591810924, 35.571265877934728, 0.0],
              [-81.0208061174921, 35.570578278346247, 0.0],
              [-81.020498118367357, 35.570371878127922, 0.0],
              [-81.020161717727859, 35.570417411240918, 0.0],
              [-81.019628517631986, 35.570875011202936, 0.0],
              [-81.019627984726256, 35.571218478033956, 0.0],
              [-81.020047784996677, 35.571768611737099, 0.0],
              [-81.020047518379812, 35.57197467770424, 0.0],
              [-81.018926584954897, 35.571744678338526, 0.0],
              [-81.018646117444902, 35.571836077903043, 0.0],
              [-81.017385117408637, 35.571560077765888, 0.0],
              [-81.017020384737535, 35.571720211771762, 0.0],
              [-81.016655518495071, 35.572109211430003, 0.0],
              [-81.016291184759481, 35.572063077832667, 0.0],
              [-81.015843118277161, 35.571742077686928, 0.0],
              [-81.015338518465015, 35.571810278484115, 0.0],
              [-81.014861717639619, 35.57201601131267, 0.0],
              [-81.014748785216895, 35.5725654779473, 0.0],
              [-81.014747318065318, 35.573641878576858, 0.0],
              [-81.014074384724225, 35.57382461165686, 0.0],
              [-81.0141855178951, 35.574488811707447, 0.0],
              [-81.014409718328025, 35.574603478482047, 0.0],
              [-81.015166717780019, 35.574489677943767, 0.0],
              [-81.015306784670059, 35.574535678323421, 0.0],
              [-81.015418584857173, 35.574833478484685, 0.0],
              [-81.015417318559699, 35.57574961188859, 0.0],
              [-81.014490584895, 35.576962611316908, 0.0],
              [-81.01449018430921, 35.577237411038851, 0.0],
              [-81.014658185037035, 35.577375011144213, 0.0],
              [-81.015471318210516, 35.577330010993073, 0.0],
              [-81.01591998455477, 35.577170011220332, 0.0],
              [-81.01673351776418, 35.576712678327723, 0.0],
              [-81.017771318318893, 35.576324278576848, 0.0],
              [-81.018472117776454, 35.576324877958484, 0.0],
              [-81.018752317457114, 35.576462478258506, 0.0],
              [-81.019200318256296, 35.576875211499427, 0.0],
              [-81.019535984589723, 35.577356411244764, 0.0],
              [-81.019675717661414, 35.577723011195722, 0.0],
              [-81.019956118200597, 35.577677411529599, 0.0],
              [-81.021078718466953, 35.576602011405306, 0.0],
              [-81.021892318379855, 35.576167411844672, 0.0],
              [-81.022734185145126, 35.575389478176284, 0.0],
              [-81.023071318399587, 35.574794411716596, 0.0],
              [-81.023324584628398, 35.574015878142092, 0.0],
              [-81.023549517922802, 35.573535078282795, 0.0],
              [-81.024672184346343, 35.572368011687068, 0.0],
              [-81.025176984978302, 35.572162278453924, 0.0],
              [-81.025878118082204, 35.572002478224483, 0.0],
              [-81.026550917774131, 35.572003077944601, 0.0],
              [-81.027195785253369, 35.571843278122273, 0.0],
              [-81.027924385170877, 35.571958278437698, 0.0],
              [-81.028204518134672, 35.572164811604296, 0.0],
              [-81.028597117534872, 35.572073411019005, 0.0],
              [-81.02882151804792, 35.571890411203448, 0.0],
              [-81.029213984506342, 35.571822011386359, 0.0],
              [-81.029998718000385, 35.57205161125539, 0.0],
              [-81.030362717466815, 35.572303811874534, 0.0],
              [-81.030586717680507, 35.572647611719511, 0.0],
              [-81.030837984989077, 35.573380611440307, 0.0],
              [-81.031454185055097, 35.573884878031535, 0.0],
              [-81.032322718564174, 35.574297877689283, 0.0],
              [-81.032490717690081, 35.574458277632786, 0.0],
              [-81.032434185008924, 35.574916410969358, 0.0],
              [-81.031535518007516, 35.57624401127056, 0.0],
              [-81.031142784233154, 35.576449878396438, 0.0],
              [-81.030918585254241, 35.576449678478909, 0.0],
              [-81.030806585131401, 35.576335077620186, 0.0],
              [-81.030752517734285, 35.57464027815336, 0.0],
              [-81.030500718330799, 35.574159011901259, 0.0],
              [-81.029184184161593, 35.573310611938197, 0.0],
              [-81.028483718248509, 35.573149811178382, 0.0],
              [-81.026381118466801, 35.573239677869118, 0.0],
              [-81.025567784896936, 35.573536811087074, 0.0],
              [-81.024950584349625, 35.573994277627676, 0.0],
              [-81.023771185278861, 35.575619411602446, 0.0],
              [-81.022985384999842, 35.576328678446757, 0.0],
              [-81.022760517953472, 35.576809478578916, 0.0],
              [-81.022787918166657, 35.577290611430428, 0.0],
              [-81.023011584761534, 35.57770287803379, 0.0],
              [-81.023767317730261, 35.57866547847636, 0.0],
              [-81.023822318513098, 35.579535878150637, 0.0],
              [-81.023738185195626, 35.579673211462762, 0.0],
              [-81.02351398479334, 35.579604278440286, 0.0],
              [-81.022758784709566, 35.578092077828551, 0.0],
              [-81.022338984169011, 35.577565011309524, 0.0],
              [-81.021834917745551, 35.577152278110816, 0.0],
              [-81.02110611744061, 35.577151678342616, 0.0],
              [-81.02088138480245, 35.577517878400016, 0.0],
              [-81.020796918417247, 35.577861411667094, 0.0],
              [-81.02009518479592, 35.578502078363869, 0.0],
              [-81.019870784725967, 35.578593411379288, 0.0],
              [-81.018749517852612, 35.578592411301145, 0.0],
              [-81.017796518438828, 35.5784084112993, 0.0],
              [-81.017124184621963, 35.578064278598411, 0.0],
              [-81.016787917685065, 35.577995278591814, 0.0],
              [-81.01617098461368, 35.578086411265183, 0.0],
              [-81.015834117965369, 35.578452477931251, 0.0],
              [-81.015889584408527, 35.578842011215229, 0.0],
              [-81.016085385059569, 35.579231478435737, 0.0],
              [-81.017009384541765, 35.580079611570177, 0.0],
              [-81.017932385018725, 35.581614878416531, 0.0],
              [-81.018156117470113, 35.582073211327781, 0.0],
              [-81.018209985249072, 35.583699278093853, 0.0],
              [-81.01809751801342, 35.58399701132516, 0.0],
              [-81.018180918254942, 35.584523811628131, 0.0],
              [-81.018348917656525, 35.584730078530953, 0.0],
              [-81.019105517886175, 35.584959811304877, 0.0],
              [-81.019469718310702, 35.585189077792826, 0.0],
              [-81.019888984280556, 35.586105611042939, 0.0],
              [-81.020197184823033, 35.586289011085078, 0.0],
              [-81.020898318154437, 35.586106411642753, 0.0],
              [-81.021150584602552, 35.586221078087846, 0.0],
              [-81.021149918406664, 35.586679211641794, 0.0],
              [-81.020616918407981, 35.586999478220513, 0.0],
              [-81.020196317558742, 35.586976211018502, 0.0],
              [-81.019495718120623, 35.586746611837668, 0.0],
              [-81.018935584945595, 35.586265078084566, 0.0],
              [-81.01840331851453, 35.585966878206683, 0.0],
              [-81.018067718054326, 35.585348211922209, 0.0],
              [-81.017731584586869, 35.585073078203926, 0.0],
              [-81.017171184903319, 35.584820611917941, 0.0],
              [-81.017003584809402, 35.584339478018322, 0.0],
              [-81.017005117565219, 35.583263077849509, 0.0],
              [-81.01711758504473, 35.582965477837078, 0.0],
              [-81.017118317542739, 35.582484477848936, 0.0],
              [-81.01686678517504, 35.581820078037403, 0.0],
              [-81.015550785045903, 35.580650877740837, 0.0],
              [-81.015495117547516, 35.580330211086071, 0.0],
              [-81.015299184695735, 35.580123877677039, 0.0],
              [-81.014962984239645, 35.580009077722096, 0.0],
              [-81.01482391808004, 35.579207411563303, 0.0],
              [-81.014711984468079, 35.579024077934349, 0.0],
              [-81.013227117666915, 35.578358611269699, 0.0],
              [-81.011545118358157, 35.578334077756153, 0.0],
              [-81.011573384349688, 35.578128011713474, 0.0],
              [-81.012218584125421, 35.577830811556758, 0.0],
              [-81.012499318200724, 35.577556277730274, 0.0],
              [-81.012809785127459, 35.576067878388827, 0.0],
              [-81.012782585002469, 35.575472411342716, 0.0],
              [-81.012194384947023, 35.575059611897551, 0.0],
              [-81.013372184804112, 35.574808811413796, 0.0],
              [-81.013372784316871, 35.574373611682432, 0.0],
              [-81.013177118276133, 35.57396107807326, 0.0],
              [-81.012448117556659, 35.574052077896958, 0.0],
              [-81.011804317424733, 35.573478811324961, 0.0],
              [-81.011636385204454, 35.57320401139728, 0.0],
              [-81.011610118112657, 35.57196707796961, 0.0],
              [-81.011163184888062, 35.570844478137076, 0.0],
              [-81.010855184398395, 35.570615278372472, 0.0],
              [-81.010490584758088, 35.570706477776795, 0.0],
              [-81.009704717618845, 35.571415678369327, 0.0],
              [-81.009480517543309, 35.571461278304966, 0.0],
              [-81.009396517877704, 35.57136961191329, 0.0],
              [-81.009397385065085, 35.570705478316313, 0.0],
              [-81.009061384153156, 35.570430278390347, 0.0],
              [-81.008557185179399, 35.570223677865542, 0.0],
              [-81.008249118051083, 35.569994411543057, 0.0],
              [-81.007267917965081, 35.570062078378768, 0.0],
              [-81.006903918344292, 35.569901411130758, 0.0],
              [-81.00679218431074, 35.569580677773089, 0.0],
              [-81.006400318422138, 35.569236678586421, 0.0],
              [-81.005895917827758, 35.569121677782661, 0.0],
              [-81.004466118047844, 35.569212011806904, 0.0],
              [-81.004241518305932, 35.569509478298833, 0.0],
              [-81.003933118031696, 35.56950921097016, 0.0],
              [-81.003569317437638, 35.56914241137617, 0.0],
              [-81.002812718457392, 35.569050011640989, 0.0],
              [-81.002644718630535, 35.568866611587673, 0.0],
              [-81.002393517982384, 35.568156278277442, 0.0],
              [-81.002141517884226, 35.567927011946139, 0.0],
              [-81.00169311800525, 35.567972411712809, 0.0],
              [-81.001412385292042, 35.568132411197176, 0.0],
              [-81.001327717846351, 35.568567477932845, 0.0],
              [-81.00129671844158, 35.57053707798287, 0.0],
              [-81.001519517487182, 35.571453411920913, 0.0],
              [-81.001490184180042, 35.572323811511076, 0.0],
              [-81.001320984391484, 35.572941878110925, 0.0],
              [-81.001319918199755, 35.573629011911024, 0.0],
              [-81.001431718651418, 35.573881011725888, 0.0],
              [-81.001879517537688, 35.574362478543925, 0.0],
              [-81.001849918318698, 35.575415877992818, 0.0],
              [-81.0020461183869, 35.575507811157905, 0.0],
              [-81.002803185036086, 35.575279478511874, 0.0],
              [-81.003223518328625, 35.575325678516812, 0.0],
              [-81.003979785220338, 35.575784477716709, 0.0],
              [-81.003951517709822, 35.575921877986033, 0.0],
              [-81.001623384282183, 35.576881611590551, 0.0],
              [-81.001679184886385, 35.577041877754368, 0.0],
              [-81.001987184619651, 35.577340011845941, 0.0],
              [-81.002126918092586, 35.577615011830936, 0.0],
              [-81.003052584654696, 35.577249411747623, 0.0],
              [-81.003192584802434, 35.5773412118835, 0.0],
              [-81.00316311772923, 35.578325878008819, 0.0],
              [-81.00327491802642, 35.578578011198509, 0.0],
              [-81.003582784806724, 35.578807278421571, 0.0],
              [-81.003834985212393, 35.578899077622722, 0.0],
              [-81.00484411778686, 35.578968878504078, 0.0],
              [-81.00571271863447, 35.579336211520165, 0.0],
              [-81.006049118560639, 35.579244878270359, 0.0],
              [-81.006245584109337, 35.57906187769796, 0.0],
              [-81.006385784474773, 35.579062011588405, 0.0],
              [-81.006777518605034, 35.579543278384833, 0.0],
              [-81.007842518315272, 35.57979627830538, 0.0],
              [-81.007842184701218, 35.579979478163104, 0.0],
              [-81.007729984232526, 35.580071011265275, 0.0],
              [-81.007337384887748, 35.580116411415517, 0.0],
              [-81.007001118476879, 35.580024477663294, 0.0],
              [-81.005851717642827, 35.580137877833444, 0.0],
              [-81.005626984983863, 35.580389678506897, 0.0],
              [-81.005234318399957, 35.580526677926564, 0.0],
              [-81.005065784177773, 35.580755478070721, 0.0],
              [-81.00503651778925, 35.581625811703042, 0.0],
              [-81.005260384232486, 35.581832211315941, 0.0],
              [-81.00584878476846, 35.582061677613666, 0.0],
              [-81.006240784679846, 35.582382810954343, 0.0],
              [-81.006212584408217, 35.582543077624763, 0.0],
              [-81.006100318082886, 35.582611611724658, 0.0],
              [-81.005623717580335, 35.582588277751775, 0.0],
              [-81.005091318553951, 35.582450411760902, 0.0],
              [-81.004390318312787, 35.582541278449959, 0.0],
              [-81.004109517892701, 35.582792877995075, 0.0],
              [-81.004052384325462, 35.583502877805401, 0.0],
              [-81.003602118491088, 35.58467047807148, 0.0],
              [-81.00388191746417, 35.585014277833885, 0.0],
              [-81.004274318052936, 35.58512907829067, 0.0],
              [-81.004891318473923, 35.584946478260143, 0.0],
              [-81.005564584635692, 35.584649477747703, 0.0],
              [-81.007331518297832, 35.584261877831402, 0.0],
              [-81.007640384419489, 35.583872811148872, 0.0],
              [-81.007892717773402, 35.583918811273904, 0.0],
              [-81.00814471788982, 35.584125211422275, 0.0],
              [-81.008198784120168, 35.585453611908342, 0.0],
              [-81.008366917802945, 35.585591211410879, 0.0],
              [-81.00878758414359, 35.585477078325162, 0.0],
              [-81.0097135841783, 35.584882611603689, 0.0],
              [-81.010078117809428, 35.584905811275398, 0.0],
              [-81.010386317985834, 35.585066478141215, 0.0],
              [-81.010974117745405, 35.585662411119891, 0.0],
              [-81.011028584409189, 35.586807611809576, 0.0],
              [-81.01128078470839, 35.586899411701502, 0.0],
              [-81.011869917598105, 35.586693877985432, 0.0],
              [-81.012290917981943, 35.586327811103175, 0.0],
              [-81.012796518201782, 35.585618278392651, 0.0],
              [-81.013273718036402, 35.585206477845354, 0.0],
              [-81.013553984903226, 35.585252478095207, 0.0],
              [-81.013497385032878, 35.585596011833722, 0.0],
              [-81.013244584900761, 35.586008011674679, 0.0],
              [-81.012991384654299, 35.58671781123406, 0.0],
              [-81.012905117419876, 35.588252211188625, 0.0],
              [-81.012622784933924, 35.589603211378609, 0.0],
              [-81.012930918306012, 35.589786678098982, 0.0],
              [-81.01343578449233, 35.58967267770128, 0.0],
              [-81.013856984483752, 35.589283811300305, 0.0],
              [-81.014081785048418, 35.58889447789916, 0.0],
              [-81.014474784405408, 35.588528478054101, 0.0],
              [-81.014670784787413, 35.588689010995616, 0.0],
              [-81.014614318459451, 35.589055277742311, 0.0],
              [-81.013996117631208, 35.590062477985306, 0.0],
              [-81.014023317758941, 35.590612211254033, 0.0],
              [-81.014191317844109, 35.590818477669472, 0.0],
              [-81.014415584752697, 35.590818678379719, 0.0],
              [-81.014892518392401, 35.590590077829773, 0.0],
              [-81.015004718561187, 35.590636011015029, 0.0],
              [-81.015004318377436, 35.590888011635329, 0.0],
              [-81.014274318519654, 35.591597211822489, 0.0],
              [-81.014133718569312, 35.591894877776127, 0.0],
              [-81.014161518230921, 35.592078077703363, 0.0],
              [-81.014609918222433, 35.592261678109161, 0.0],
              [-81.015254718478559, 35.592331011254231, 0.0],
              [-81.015506785028904, 35.592468678316273, 0.0],
              [-81.016095718512062, 35.592354611772826, 0.0],
              [-81.017443318392992, 35.591096278135865, 0.0],
              [-81.017471584773588, 35.590913078445595, 0.0],
              [-81.017892385276113, 35.590684411216635, 0.0],
              [-81.018060585249032, 35.590707477893993, 0.0],
              [-81.01814451751865, 35.590799211468592, 0.0],
              [-81.017835517427528, 35.591417211627565, 0.0],
              [-81.017862984990842, 35.591737878098769, 0.0],
              [-81.018198918453322, 35.592196211841987, 0.0],
              [-81.01864738459912, 35.592334077799556, 0.0],
              [-81.019264518418069, 35.592105611670092, 0.0],
              [-81.019572784103232, 35.592105811186258, 0.0],
              [-81.019712918550809, 35.592174677833519, 0.0],
              [-81.01971258507821, 35.592564010963265, 0.0],
              [-81.019628317921772, 35.592678411496557, 0.0],
              [-81.019655984130296, 35.592861611570001, 0.0],
              [-81.020188718070315, 35.592862077856033, 0.0],
              [-81.020328718311333, 35.592931011164168, 0.0],
              [-81.020216384367501, 35.593182811573016, 0.0],
              [-81.019542985084186, 35.593548611535311, 0.0],
              [-81.019318185175564, 35.593869011778203, 0.0],
              [-81.019204918462094, 35.59483087772643, 0.0],
              [-81.019400984885209, 35.594968477661908, 0.0],
              [-81.019765584731161, 35.594877211324736, 0.0],
              [-81.020439118568348, 35.594442678334417, 0.0],
              [-81.021168517841105, 35.594145478179456, 0.0],
              [-81.02172911789674, 35.594191811663286, 0.0],
              [-81.021981184453438, 35.594443878480348, 0.0],
              [-81.022036585102015, 35.595062411593716, 0.0],
              [-81.021923184086887, 35.595932611463468, 0.0],
              [-81.02200731801571, 35.596001278459632, 0.0],
              [-81.022034984448695, 35.596299211532134, 0.0],
              [-81.022118984135147, 35.596390811025863, 0.0],
              [-81.022315318285621, 35.596368077970553, 0.0],
              [-81.022988918198934, 35.595818878167684, 0.0],
              [-81.023129184410479, 35.595842011279174, 0.0],
              [-81.023072384272879, 35.596322877908158, 0.0],
              [-81.023688918107297, 35.596689878442049, 0.0],
              [-81.023660518111328, 35.596941678366264, 0.0],
              [-81.023239784616322, 35.597055877858807, 0.0],
              [-81.022174318203938, 35.597055011187116, 0.0],
              [-81.021836984497483, 35.597558478269576, 0.0],
              [-81.021611585119274, 35.598451677874415, 0.0],
              [-81.021666984579412, 35.59900127843752, 0.0],
              [-81.022535318106392, 35.59982647838325, 0.0],
              [-81.023460384768285, 35.599918878484928, 0.0],
              [-81.023572384328403, 35.600079278332458, 0.0],
              [-81.023739918061281, 35.600720677874129, 0.0],
              [-81.023711584632736, 35.600972677695388, 0.0],
              [-81.022813584224664, 35.601430011309731, 0.0],
              [-81.022532784471167, 35.601704477708481, 0.0],
              [-81.022083984565754, 35.601887411593481, 0.0],
              [-81.021298518535275, 35.602092877587069, 0.0],
              [-81.019868517844998, 35.601954278454222, 0.0],
              [-81.019643984978529, 35.602045611598356, 0.0],
              [-81.019166717936031, 35.602594877893523, 0.0],
              [-81.019333918215438, 35.603327878017659, 0.0],
              [-81.019445584891429, 35.603694411869768, 0.0],
              [-81.019444784821815, 35.604267011200733, 0.0],
              [-81.020032518498269, 35.605229478348946, 0.0],
              [-81.020424584740567, 35.605596211060856, 0.0],
              [-81.020592784126976, 35.605596411497601, 0.0],
              [-81.020873384383805, 35.60545921154835, 0.0],
              [-81.021238584332266, 35.605093011195038, 0.0],
              [-81.021490984154383, 35.605001678510703, 0.0],
              [-81.022640785273836, 35.604979678495276, 0.0],
              [-81.02289338453204, 35.604842477976582, 0.0],
              [-81.023118584796165, 35.604109811752792, 0.0],
              [-81.023343517609518, 35.603743611853048, 0.0],
              [-81.023567984819707, 35.603583411070431, 0.0],
              [-81.024577984645049, 35.603195011611845, 0.0],
              [-81.024858384903538, 35.603195211684593, 0.0],
              [-81.02511071779233, 35.603287010970583, 0.0],
              [-81.025642784681196, 35.603860010992349, 0.0],
              [-81.02606331803959, 35.603974878503699, 0.0],
              [-81.026539184355656, 35.604639411880697, 0.0],
              [-81.027155317761313, 35.605372811337084, 0.0],
              [-81.027519117400942, 35.605991411499659, 0.0],
              [-81.027630385186498, 35.606724411371147, 0.0],
              [-81.027742318256159, 35.606930611853826, 0.0],
              [-81.028246784339075, 35.607228811642919, 0.0],
              [-81.028583384887554, 35.607137478010202, 0.0],
              [-81.029397518276085, 35.606450878072373, 0.0],
              [-81.029846118578206, 35.606497077998718, 0.0],
              [-81.030126384252711, 35.606634811761019, 0.0],
              [-81.030434517647038, 35.606932678365098, 0.0],
              [-81.030546384877823, 35.607184811119723, 0.0],
              [-81.030517718119455, 35.60768861129857, 0.0],
              [-81.03015278451403, 35.60807767781121, 0.0],
              [-81.030151917674885, 35.608764811011092, 0.0],
              [-81.030319985115739, 35.608925211459209, 0.0],
              [-81.030656784617022, 35.608719411751885, 0.0],
              [-81.03088111848345, 35.608742411445562, 0.0],
              [-81.030880717988822, 35.609040077923467, 0.0],
              [-81.030459518153947, 35.609543678018632, 0.0],
              [-81.030515318277793, 35.609795611584602, 0.0],
              [-81.030823584544777, 35.60997907804304, 0.0],
              [-81.03301098456474, 35.6100494783824, 0.0],
              [-81.033235317632645, 35.610164077616204, 0.0],
              [-81.03312218524195, 35.610942677722086, 0.0],
              [-81.033290185235074, 35.611126077600332, 0.0],
              [-81.033626784128899, 35.611126277850758, 0.0],
              [-81.034075718103338, 35.611012211281746, 0.0],
              [-81.034637317905862, 35.610348478352194, 0.0],
              [-81.03514211814246, 35.610348811840204, 0.0],
              [-81.035168585229599, 35.611677211670525, 0.0],
              [-81.035252717665728, 35.611791678003662, 0.0],
              [-81.0356173183756, 35.611792011727758, 0.0],
              [-81.036712317608377, 35.61067061149771, 0.0],
              [-81.037104917758356, 35.610670878021672, 0.0],
              [-81.037385118494001, 35.610900077940656, 0.0],
              [-81.037917117504691, 35.611724878116824, 0.0],
              [-81.038365384553117, 35.612045878085063, 0.0],
              [-81.038617785185806, 35.612137678291525, 0.0],
              [-81.039262918053069, 35.612092411046604, 0.0],
              [-81.039739917481995, 35.611886610918724, 0.0],
              [-81.040132917897836, 35.611520410919901, 0.0],
              [-81.04021718471617, 35.611337277643941, 0.0],
              [-81.040217784938704, 35.610764678219233, 0.0],
              [-81.039994117503184, 35.610146211065548, 0.0],
              [-81.040051317671981, 35.609024011647058, 0.0],
              [-81.040556584126293, 35.608474611869546, 0.0],
              [-81.041369784480892, 35.608612678291237, 0.0],
              [-81.041762517602805, 35.608521277976038, 0.0],
              [-81.042043118347422, 35.608338211528107, 0.0],
              [-81.042043518583327, 35.607948878082929, 0.0],
              [-81.041931584875314, 35.607742678364282, 0.0],
              [-81.042044118236632, 35.607353411679249, 0.0],
              [-81.042212517726242, 35.60726201136103, 0.0],
              [-81.042492918465214, 35.607285077669644, 0.0],
              [-81.043558318377634, 35.607606410982932, 0.0],
              [-81.043501918058723, 35.607766677931437, 0.0],
              [-81.043108918527835, 35.608247411327504, 0.0],
              [-81.043024384233462, 35.608568011249503, 0.0],
              [-81.042631518494716, 35.608888278448489, 0.0],
              [-81.042406784242516, 35.609254611063541, 0.0],
              [-81.041229117595236, 35.609070611693703, 0.0],
              [-81.041060384296088, 35.609505611205087, 0.0],
              [-81.040947784824553, 35.609986610947438, 0.0],
              [-81.041086984859177, 35.611017278482677, 0.0],
              [-81.041170984771369, 35.611063078380639, 0.0],
              [-81.041310584787311, 35.611704411469958, 0.0],
              [-81.041309917621859, 35.612368610976169, 0.0],
              [-81.040973185252767, 35.612597411820261, 0.0],
              [-81.040131717763643, 35.612665478180411, 0.0],
              [-81.039317984837396, 35.612962611391652, 0.0],
              [-81.039233784694304, 35.61307707813269, 0.0],
              [-81.039345517621172, 35.613489611841189, 0.0],
              [-81.040522317443461, 35.614658277809312, 0.0],
              [-81.040633184978844, 35.61575767828468, 0.0],
              [-81.04080131745053, 35.615963877666466, 0.0],
              [-81.042287317457721, 35.616491678502108, 0.0],
              [-81.042763784266967, 35.61678981101813, 0.0],
              [-81.042959317534553, 35.617522811856531, 0.0],
              [-81.042930585060219, 35.618209877706526, 0.0],
              [-81.042508985067229, 35.619034077934622, 0.0],
              [-81.042172517749194, 35.618942210906859, 0.0],
              [-81.042089318373669, 35.618117677915613, 0.0],
              [-81.041921584679145, 35.617545011131675, 0.0],
              [-81.041529384475524, 35.617063811569899, 0.0],
              [-81.040744517876689, 35.616673877800658, 0.0],
              [-81.040240117861856, 35.616238411412112, 0.0],
              [-81.039931584982099, 35.616169411238879, 0.0],
              [-81.039090184532441, 35.616168877976556, 0.0],
              [-81.038725518407446, 35.616351878392301, 0.0],
              [-81.038248718168489, 35.616351478419226, 0.0],
              [-81.038277118585384, 35.615893411675472, 0.0],
              [-81.039315384806187, 35.615390278091596, 0.0],
              [-81.039399718006749, 35.615253011090019, 0.0],
              [-81.039288117947237, 35.614703278342546, 0.0],
              [-81.038784117881917, 35.613947077917686, 0.0],
              [-81.038616318537009, 35.613466078472115, 0.0],
              [-81.038392318060303, 35.613145278355596, 0.0],
              [-81.037915917638571, 35.612755477718757, 0.0],
              [-81.03741138435602, 35.61259481152743, 0.0],
              [-81.036850318405911, 35.612594477928944, 0.0],
              [-81.036541784712185, 35.612708811184142, 0.0],
              [-81.035699784578028, 35.61328081118485, 0.0],
              [-81.035306917919499, 35.613395011338206, 0.0],
              [-81.034634318390914, 35.612982278012673, 0.0],
              [-81.034101717671447, 35.612798611789188, 0.0],
              [-81.033400317775303, 35.612981278293574, 0.0],
              [-81.033232118427222, 35.612889610978648, 0.0],
              [-81.033261317631059, 35.611904811205228, 0.0],
              [-81.032588784763988, 35.611331677917391, 0.0],
              [-81.032337317401641, 35.610621477657574, 0.0],
              [-81.032169184104703, 35.610438077732567, 0.0],
              [-81.030486518071569, 35.610391078531073, 0.0],
              [-81.029336517471336, 35.610573411252915, 0.0],
              [-81.028158117862986, 35.611007611091942, 0.0],
              [-81.027709318127265, 35.611076010944856, 0.0],
              [-81.027400918220593, 35.610961278434132, 0.0],
              [-81.027288985231138, 35.610777877813796, 0.0],
              [-81.02700938499305, 35.610044811497531, 0.0],
              [-81.026981785183693, 35.609678277792376, 0.0],
              [-81.02717858451588, 35.609266211242058, 0.0],
              [-81.027684318303628, 35.608579611454317, 0.0],
              [-81.027881384319628, 35.607824010930251, 0.0],
              [-81.027545317785709, 35.607457278320162, 0.0],
              [-81.026115785049313, 35.606952278012365, 0.0],
              [-81.024657384141733, 35.60697401180434, 0.0],
              [-81.024432917556481, 35.607111211018257, 0.0],
              [-81.02423611808571, 35.607454478435521, 0.0],
              [-81.024207718138513, 35.607752278384439, 0.0],
              [-81.023898585277124, 35.608232878123992, 0.0],
              [-81.023757384151452, 35.609057278374557, 0.0],
              [-81.023866718177771, 35.611370611116712, 0.0],
              [-81.02406258522052, 35.61169141126129, 0.0],
              [-81.024090317401047, 35.611943411148026, 0.0],
              [-81.024174517424214, 35.611989211408087, 0.0],
              [-81.024230117916815, 35.612332811527999, 0.0],
              [-81.02431411782625, 35.612378611080246, 0.0],
              [-81.024929384934225, 35.613890811172737, 0.0],
              [-81.024928718358325, 35.614440411003905, 0.0],
              [-81.024703984947166, 35.614715077564654, 0.0],
              [-81.024451584921167, 35.614737811031532, 0.0],
              [-81.024143185086999, 35.614623011012952, 0.0],
              [-81.023890784107707, 35.614622811126793, 0.0],
              [-81.023329518044079, 35.614874211673303, 0.0],
              [-81.023245584502163, 35.614645078017475, 0.0],
              [-81.023611785075119, 35.613431611702929, 0.0],
              [-81.023528118098142, 35.613110810974455, 0.0],
              [-81.023304185035954, 35.612767211534795, 0.0],
              [-81.022884717975856, 35.611667478137164, 0.0],
              [-81.022408984588537, 35.610911278392166, 0.0],
              [-81.021792584762807, 35.610384011679209, 0.0],
              [-81.022129918289181, 35.609857611203871, 0.0],
              [-81.022270917735639, 35.609193477882002, 0.0],
              [-81.023029984914018, 35.607728278265448, 0.0],
              [-81.023283584661272, 35.606675077842482, 0.0],
              [-81.023312584602635, 35.605988011727725, 0.0],
              [-81.023228718040812, 35.605781811758881, 0.0],
              [-81.022976518506098, 35.605644211852848, 0.0],
              [-81.02255571748239, 35.605781278170006, 0.0],
              [-81.02196618449284, 35.606307477886716, 0.0],
              [-81.020674117856771, 35.607817878439313, 0.0],
              [-81.019972384455698, 35.608298277701891, 0.0],
              [-81.019523585170958, 35.60841241131935, 0.0],
              [-81.019242918362835, 35.608572611219955, 0.0],
              [-81.018709984311073, 35.608709478191578, 0.0],
              [-81.018569917686719, 35.608617811083818, 0.0],
              [-81.018542585201914, 35.60795347823322, 0.0],
              [-81.018739518220627, 35.607449811093581, 0.0],
              [-81.0198341848279, 35.606786678013222, 0.0],
              [-81.019946917915476, 35.606374478139308, 0.0],
              [-81.019667317565222, 35.605710077681934, 0.0],
              [-81.018939118356315, 35.604999477673033, 0.0],
              [-81.018630717832721, 35.6049304782847, 0.0],
              [-81.017089317451891, 35.604219077697358, 0.0],
              [-81.017763118038985, 35.603601411562103, 0.0],
              [-81.017959984528801, 35.603235078125167, 0.0],
              [-81.018241518138908, 35.602319210915155, 0.0],
              [-81.018774784299993, 35.601976211662191, 0.0],
              [-81.019308184452939, 35.601541477677991, 0.0],
              [-81.020402584339067, 35.600901078343945, 0.0],
              [-81.020402985284889, 35.600672078461095, 0.0],
              [-81.020150918471543, 35.600465810955754, 0.0],
              [-81.019590184434392, 35.600327877864622, 0.0],
              [-81.019113784264235, 35.600098411476672, 0.0],
              [-81.018889784335599, 35.5998464114546, 0.0],
              [-81.018778584512077, 35.59904461162634, 0.0],
              [-81.018134384709839, 35.598563077650859, 0.0],
              [-81.017994384698781, 35.598356877660379, 0.0],
              [-81.017882984604128, 35.597898811874735, 0.0],
              [-81.017686784422239, 35.597692478042347, 0.0],
              [-81.01715418416218, 35.597646211430742, 0.0],
              [-81.016845985273875, 35.597462678290235, 0.0],
              [-81.016902718479145, 35.597027477764229, 0.0],
              [-81.016734584633951, 35.596890011230649, 0.0],
              [-81.016426184606857, 35.596866677802858, 0.0],
              [-81.01586498429748, 35.597141211804313, 0.0],
              [-81.014742318356383, 35.597941677642339, 0.0],
              [-81.014461917559572, 35.597941478224847, 0.0],
              [-81.014574384440024, 35.597666678347728, 0.0],
              [-81.015613318383217, 35.596659878203099, 0.0],
              [-81.015866584429034, 35.595927278005497, 0.0],
              [-81.015754718290779, 35.595835478397632, 0.0],
              [-81.015221985001162, 35.595720477938059, 0.0],
              [-81.015081984200975, 35.595560211253229, 0.0],
              [-81.015166518174581, 35.59530827813316, 0.0],
              [-81.016065184762851, 35.59425561147772, 0.0],
              [-81.016065917675263, 35.59370587831139, 0.0],
              [-81.015813784323583, 35.593522478140436, 0.0],
              [-81.01542131859145, 35.59349927778586, 0.0],
              [-81.014888385012156, 35.593590278326943, 0.0],
              [-81.014664317847959, 35.593475678009732, 0.0],
              [-81.014580917553232, 35.592948811664343, 0.0],
              [-81.014048518469934, 35.592673411839414, 0.0],
              [-81.013796118505184, 35.592764877882686, 0.0],
              [-81.013290518125999, 35.593359877644758, 0.0],
              [-81.012672717461001, 35.594023477919635, 0.0],
              [-81.01208378477277, 35.594091611660552, 0.0],
              [-81.011971784774261, 35.594022811218679, 0.0],
              [-81.011971984664498, 35.59383961113987, 0.0],
              [-81.012477118365268, 35.593611077707756, 0.0],
              [-81.012757917843487, 35.59333647814082, 0.0],
              [-81.01292658519823, 35.592924411889236, 0.0],
              [-81.012983385019794, 35.592466411776229, 0.0],
              [-81.012731517906602, 35.592099677735128, 0.0],
              [-81.012367118178119, 35.592053477990326, 0.0],
              [-81.012142717661149, 35.592122011359571, 0.0],
              [-81.011946517615741, 35.592030278566405, 0.0],
              [-81.011919318560416, 35.591480478153699, 0.0],
              [-81.011779584626723, 35.591114010949028, 0.0],
              [-81.011471717890331, 35.590747211744393, 0.0],
              [-81.011191318047267, 35.590724077813711, 0.0],
              [-81.010714317526393, 35.590952611468765, 0.0],
              [-81.010264918561234, 35.591524811030986, 0.0],
              [-81.010096518432576, 35.59163907801161, 0.0],
              [-81.009731917874632, 35.591707477935373, 0.0],
              [-81.009704318254236, 35.591478478335091, 0.0],
              [-81.01020991827842, 35.590837678189537, 0.0],
              [-81.010238517606453, 35.590356810968657, 0.0],
              [-81.009958584624599, 35.590058811482002, 0.0],
              [-81.008781318620549, 35.589828611693861, 0.0],
              [-81.007996717980916, 35.589553011206988, 0.0],
              [-81.007828317803344, 35.589690211355972, 0.0],
              [-81.006846718190459, 35.58982667788964, 0.0],
              [-81.006622518568975, 35.589757811335708, 0.0],
              [-81.005978517679935, 35.589184610984063, 0.0],
              [-81.005894918470574, 35.588818077916891, 0.0],
              [-81.005979517658545, 35.588497611668586, 0.0],
              [-81.006625784568627, 35.587536277646329, 0.0],
              [-81.006654384275237, 35.587101078421782, 0.0],
              [-81.006514785246949, 35.586780278172753, 0.0],
              [-81.006262584920876, 35.586688478350098, 0.0],
              [-81.005925718455089, 35.586940011770594, 0.0],
              [-81.005589318449495, 35.586985611595225, 0.0],
              [-81.005196985013299, 35.586801878147561, 0.0],
              [-81.004720318406086, 35.586778611403382, 0.0],
              [-81.003738118330574, 35.587441811229226, 0.0],
              [-81.003373518171927, 35.587533078275968, 0.0],
              [-81.003149385283905, 35.587349678104481, 0.0],
              [-81.002897918277824, 35.586776878311248, 0.0],
              [-81.002729917897497, 35.586685078510968, 0.0],
              [-81.002449584739878, 35.586639011012416, 0.0],
              [-81.002140784713447, 35.586890610957731, 0.0],
              [-81.001916384602254, 35.58695901116716, 0.0],
              [-81.001356117688587, 35.58663781180195, 0.0],
              [-81.001301184237647, 35.585927677809345, 0.0],
              [-81.001189318569999, 35.585744411210086, 0.0],
              [-81.000965317688554, 35.585606678050794, 0.0],
              [-81.000824985040808, 35.585698211789804, 0.0],
              [-81.000711984810849, 35.586247877715607, 0.0],
              [-81.00065411858472, 35.587392877659269, 0.0],
              [-81.000849584913269, 35.587851078211813, 0.0],
              [-81.001492984890348, 35.588813678114441, 0.0],
              [-81.001660584944048, 35.58924901133831, 0.0],
              [-81.001940584200227, 35.589501211201195, 0.0],
              [-81.002416918318062, 35.589707811566143, 0.0],
              [-81.003930584921122, 35.589938411251204, 0.0],
              [-81.004826717864276, 35.590649211333442, 0.0],
              [-81.005246584851264, 35.591130678307721, 0.0],
              [-81.005330318117316, 35.591474278092939, 0.0],
              [-81.005498184477545, 35.591680611630586, 0.0],
              [-81.005636517559623, 35.592917411685661, 0.0],
              [-81.005720384154657, 35.593100678467422, 0.0],
              [-81.006364184183184, 35.593765478213285, 0.0],
              [-81.006895718554034, 35.594659211382243, 0.0],
              [-81.006894718584277, 35.595392211341007, 0.0],
              [-81.007118118290677, 35.595964877928019, 0.0],
              [-81.00706018450586, 35.597178678117146, 0.0],
              [-81.00686331854962, 35.597613611044963, 0.0],
              [-81.006776518383802, 35.59953747772326, 0.0],
              [-81.006579984195668, 35.599674611080133, 0.0],
              [-81.006103184205728, 35.599765811573207, 0.0],
              [-81.005625918551431, 35.600154611707254, 0.0],
              [-81.005485717686881, 35.600131611231561, 0.0],
              [-81.005430118331972, 35.599856678102164, 0.0],
              [-81.005823584801846, 35.599215877618832, 0.0],
              [-81.005824785031621, 35.598437211138737, 0.0],
              [-81.005600984390696, 35.598047478145631, 0.0],
              [-81.005798584966016, 35.597154477796082, 0.0],
              [-81.005800118032795, 35.596032277679782, 0.0],
              [-81.005492584632137, 35.595459477773218, 0.0],
              [-81.005128584499587, 35.595069877739185, 0.0],
              [-81.005073985136889, 35.594084877589985, 0.0],
              [-81.004878318591039, 35.593741211357198, 0.0],
              [-81.004457985122329, 35.593488810985903, 0.0],
              [-81.004066117593396, 35.593053278025977, 0.0],
              [-81.003870717611591, 35.592503411883563, 0.0],
              [-81.003534717834171, 35.59215947826727, 0.0],
              [-81.003311117925747, 35.591724211488327, 0.0],
              [-81.003116184969187, 35.590807878082217, 0.0],
              [-81.002807984301839, 35.590647277769826, 0.0],
              [-81.002415385047442, 35.590738478148985, 0.0],
              [-81.001909918516162, 35.591218877760653, 0.0],
              [-81.00162838529485, 35.591905678086654, 0.0],
              [-81.001094517639771, 35.592752477785595, 0.0],
              [-81.000814118278129, 35.592798078078914, 0.0],
              [-81.000702117659017, 35.59270641116624, 0.0],
              [-81.000730784916016, 35.59229407852542, 0.0],
              [-81.001068317811217, 35.591492878219007, 0.0],
              [-81.001097117716469, 35.591012011816488, 0.0],
              [-81.000957318299555, 35.590805811116411, 0.0],
              [-81.00092998426463, 35.590278877665035, 0.0],
              [-81.000509584571176, 35.590141077899226, 0.0],
              [-80.99979898420591, 35.589728078402452, 0.0],
              [-80.999228117482971, 35.589889011683169, 0.0],
              [-80.998947984249853, 35.590072478155072, 0.0],
              [-80.998751784319751, 35.590072678281715, 0.0],
              [-80.998751184432365, 35.589706278422817, 0.0],
              [-80.999030917687378, 35.589316678354244, 0.0],
              [-80.999030118156142, 35.588721078556176, 0.0],
              [-80.998945717573449, 35.588606677933257, 0.0],
              [-80.998945117664462, 35.58812581102071, 0.0],
              [-80.998860917710616, 35.588080078488368, 0.0],
              [-80.99883138415575, 35.587141077785517, 0.0],
              [-80.998494518178475, 35.586866611471542, 0.0],
              [-80.998130317773075, 35.587004411392485, 0.0],
              [-80.997402784399739, 35.587990011314979, 0.0],
              [-80.997207584939602, 35.588723078220774, 0.0],
              [-80.996928118539756, 35.589295877941481, 0.0],
              [-80.996535918517097, 35.58954827803651, 0.0],
              [-80.996424384181068, 35.589914811123315, 0.0],
              [-80.995556317633444, 35.590557011908956, 0.0],
              [-80.995192384883737, 35.591015411145023, 0.0],
              [-80.994521184294726, 35.592161211776897, 0.0],
              [-80.994045118025213, 35.59250521176115, 0.0],
              [-80.993878317636344, 35.59351307798967, 0.0],
              [-80.993542718452574, 35.59410887807671, 0.0],
              [-80.99343138461326, 35.594681478320176, 0.0],
              [-80.993319518446881, 35.594864878299198, 0.0],
              [-80.992927384444101, 35.595117278474596, 0.0],
              [-80.992815184248258, 35.595117411447532, 0.0],
              [-80.992869984535346, 35.594973477952706, 0.0],
              [-80.992954785309038, 35.594750811049373, 0.0],
              [-80.992981717577706, 35.593903411674489, 0.0],
              [-80.993205118117089, 35.59326187816351, 0.0],
              [-80.993064717595828, 35.593124611396526, 0.0],
              [-80.993064384386741, 35.592964277960661, 0.0],
              [-80.993287985054252, 35.59252887803104, 0.0],
              [-80.993539984512324, 35.592253811462506, 0.0],
              [-80.99418298522329, 35.590924811402786, 0.0],
              [-80.994070184956016, 35.590489811689345, 0.0],
              [-80.993929784767147, 35.590375410990958, 0.0],
              [-80.993929585049841, 35.590238011387122, 0.0],
              [-80.995050717969775, 35.589916277959226, 0.0],
              [-80.995162385000341, 35.589732878248512, 0.0],
              [-80.995722384776087, 35.589251277872748, 0.0],
              [-80.996086118152377, 35.588770011825424, 0.0],
              [-80.996139517831651, 35.586983611558722, 0.0],
              [-80.995690117694792, 35.58641147769886, 0.0],
              [-80.994988518408704, 35.585977011422401, 0.0],
              [-80.994847984147228, 35.585771078345147, 0.0],
              [-80.995016318470533, 35.585725011635787, 0.0],
              [-80.996895317711704, 35.586135278307012, 0.0],
              [-80.997231117606631, 35.585768611424186, 0.0],
              [-80.997146318511696, 35.585264811633742, 0.0],
              [-80.996893384569049, 35.584875678505533, 0.0],
              [-80.996387985104917, 35.58439527775203, 0.0],
              [-80.995686718508338, 35.584098278315395, 0.0],
              [-80.995602118159624, 35.58382347811304, 0.0],
              [-80.99526531818816, 35.583549011793238, 0.0],
              [-80.995236984467383, 35.583365811712937, 0.0],
              [-80.99557331768213, 35.583273877628045, 0.0],
              [-80.995881985004658, 35.583433878452674, 0.0],
              [-80.996302384383611, 35.583364810954471, 0.0],
              [-80.9973121845956, 35.5837760117793, 0.0],
              [-80.997788718189739, 35.583660878071854, 0.0],
              [-80.998265517748095, 35.583820811000749, 0.0],
              [-80.998742117781305, 35.583843211867958, 0.0],
              [-80.999106384991194, 35.583682478336399, 0.0],
              [-80.999798985062853, 35.583567278515908, 0.0],
              [-81.000127517662392, 35.583430211277374, 0.0],
              [-81.000408518175945, 35.583018211257581, 0.0],
              [-81.000325117928114, 35.582583011262166, 0.0],
              [-80.999933585231801, 35.581964078003352, 0.0],
              [-80.999906118532934, 35.581574811253986, 0.0],
              [-81.000495184584366, 35.581346411875792, 0.0],
              [-81.000916184385162, 35.581072011879947, 0.0],
              [-81.001112918185498, 35.580728677826194, 0.0],
              [-81.000973385267542, 35.580339078113006, 0.0],
              [-81.001114184244614, 35.579904211615521, 0.0],
              [-81.001058185003444, 35.579812478122491, 0.0],
              [-81.00038558419584, 35.579674411354496, 0.0],
              [-81.000357784585248, 35.579514011306522, 0.0],
              [-81.000134118024121, 35.579193211295411, 0.0],
              [-81.000106518372121, 35.578918278520547, 0.0],
              [-80.999882717456387, 35.578620278376263, 0.0],
              [-81.001397584671011, 35.577889011002966, 0.0],
              [-81.00103431834637, 35.577224477667656, 0.0],
              [-81.000697517498025, 35.577407278478042, 0.0],
              [-81.000613518239092, 35.577338478354321, 0.0],
              [-81.000782318545433, 35.576949411090752, 0.0],
              [-81.000698718565218, 35.576651478454053, 0.0],
              [-81.000390584767288, 35.57649101138157, 0.0],
              [-80.999798984291047, 35.576513211845672, 0.0],
              [-80.999207718310629, 35.576651278236888, 0.0],
              [-80.998675784577003, 35.577132811316488, 0.0],
              [-80.998199518133987, 35.577270611852427, 0.0],
              [-80.997975517647262, 35.57750001144354, 0.0],
              [-80.997723184718083, 35.57750027800833, 0.0],
              [-80.997862584767731, 35.577019011091245, 0.0],
              [-80.998141985084914, 35.576377477752061, 0.0],
              [-80.998113117656601, 35.575782078110471, 0.0],
              [-80.998000584679119, 35.575576011402497, 0.0],
              [-80.997635784831019, 35.575278811231911, 0.0],
              [-80.997299318512177, 35.5752102780258, 0.0],
              [-80.996962984750354, 35.575302277865433, 0.0],
              [-80.996487184439459, 35.575783678036821, 0.0],
              [-80.995704318115003, 35.577112878298152, 0.0],
              [-80.995509784215116, 35.57832687772656, 0.0],
              [-80.995286185144309, 35.578739411118406, 0.0],
              [-80.994866318068119, 35.579197877706655, 0.0],
              [-80.994585984720558, 35.579198277704627, 0.0],
              [-80.994752984336401, 35.578442211024417, 0.0],
              [-80.995003984144873, 35.577503011262479, 0.0],
              [-80.994639317493252, 35.577320077778658, 0.0],
              [-80.994078984664171, 35.577595411114189, 0.0],
              [-80.993602384329165, 35.577550211221627, 0.0],
              [-80.993629717783918, 35.577069211631013, 0.0],
              [-80.994973985092557, 35.576220477754752, 0.0],
              [-80.99550558440616, 35.57548707809508, 0.0],
              [-80.995588585056097, 35.574799811381048, 0.0],
              [-80.995336117760317, 35.574662677763236, 0.0],
              [-80.994271118016812, 35.574801211896059, 0.0],
              [-80.993681984298107, 35.574481211603604, 0.0],
              [-80.993653718489639, 35.57438961168571, 0.0],
              [-80.993905784401292, 35.574228877950446, 0.0],
              [-80.995082785250077, 35.573930078196199, 0.0],
              [-80.995222784492952, 35.573838277794671, 0.0],
              [-80.995222384449974, 35.573609277832709, 0.0],
              [-80.994240585147665, 35.573175078317512, 0.0],
              [-80.994240384946167, 35.573037678084319, 0.0],
              [-80.994660717747436, 35.572877011316159, 0.0],
              [-80.995445517733174, 35.572761678473213, 0.0],
              [-80.995725385198966, 35.572509411919448, 0.0],
              [-80.995724717490916, 35.572074277805861, 0.0],
              [-80.995528185248915, 35.571845411074243, 0.0],
              [-80.99549951791758, 35.5714102779922, 0.0],
              [-80.995050518103383, 35.571067211064083, 0.0],
              [-80.995019717640403, 35.569303678539093, 0.0],
              [-80.995103517721532, 35.569097478060776, 0.0],
              [-80.995551384147277, 35.568753611018238, 0.0],
              [-80.995522585068414, 35.5681810119004, 0.0],
              [-80.995213585316463, 35.567746078224033, 0.0],
              [-80.994708718240588, 35.567494678237374, 0.0],
              [-80.994595718378406, 35.566899411051544, 0.0],
              [-80.994987518580842, 35.566532611324035, 0.0],
              [-80.995041584578175, 35.565158411602184, 0.0],
              [-80.994564717588716, 35.564861078238692, 0.0],
              [-80.993864185145128, 35.565045077968463, 0.0],
              [-80.993472785148711, 35.565732477795883, 0.0],
              [-80.993557584260202, 35.566282011591817, 0.0],
              [-80.993726318394678, 35.566579611888692, 0.0],
              [-80.993812784481392, 35.568251478383289, 0.0],
              [-80.993253317801447, 35.568984877887239, 0.0],
              [-80.992917917646253, 35.569695211898036, 0.0],
              [-80.992721984126234, 35.569855811630219, 0.0],
              [-80.992581784434989, 35.569787211548721, 0.0],
              [-80.992692118145911, 35.568596078486372, 0.0],
              [-80.992355917912107, 35.568642211060315, 0.0],
              [-80.99213211834271, 35.569008878484418, 0.0],
              [-80.992021317667621, 35.569833611205226, 0.0],
              [-80.991125585098828, 35.570727611862232, 0.0],
              [-80.990929318616992, 35.570636211044736, 0.0],
              [-80.991152517894662, 35.569926011122895, 0.0],
              [-80.991376117456468, 35.569559277940421, 0.0],
              [-80.991345384849311, 35.567727211399408, 0.0],
              [-80.991513184578011, 35.567475078187044, 0.0],
              [-80.991484317864391, 35.566902478517356, 0.0],
              [-80.991315917586789, 35.56669661102935, 0.0],
              [-80.990979517566529, 35.566696878509958, 0.0],
              [-80.990699585184473, 35.56694907843832, 0.0],
              [-80.989971184770283, 35.567270411251869, 0.0],
              [-80.9896635174767, 35.567705878355113, 0.0],
              [-80.988907718012797, 35.568393678597637, 0.0],
              [-80.98868438469691, 35.568943611828914, 0.0],
              [-80.987955985013386, 35.569241878270354, 0.0],
              [-80.987705518551536, 35.570501878346036, 0.0],
              [-80.987650717791851, 35.57144101137412, 0.0],
              [-80.987426917511229, 35.571761811700405, 0.0],
              [-80.987230784385417, 35.571830678265144, 0.0],
              [-80.987116918649519, 35.570548278429804, 0.0],
              [-80.986976518183653, 35.570365211035615, 0.0],
              [-80.986499718469034, 35.570159477840811, 0.0],
              [-80.98658278425728, 35.56947227857264, 0.0],
              [-80.986526518094621, 35.569312078467206, 0.0],
              [-80.986217918522684, 35.569106211423204, 0.0],
              [-80.984620384396493, 35.569313878171549, 0.0],
              [-80.984565918623304, 35.570459011892765, 0.0],
              [-80.984061784804922, 35.570848877908347, 0.0],
              [-80.983641718189062, 35.571009611130414, 0.0],
              [-80.983529584501767, 35.571124211918196, 0.0],
              [-80.983333518518563, 35.571170211808187, 0.0],
              [-80.983249318582494, 35.571101611387903, 0.0],
              [-80.983332917937062, 35.570689278009347, 0.0],
              [-80.982828118216247, 35.570529277968305, 0.0],
              [-80.982995918542429, 35.570254278214215, 0.0],
              [-80.983107785294237, 35.570116878223551, 0.0],
              [-80.983079318164783, 35.569773211492119, 0.0],
              [-80.98268658534947, 35.569567478525379, 0.0],
              [-80.981789518026702, 35.569453878153851, 0.0],
              [-80.981704918537034, 35.569064611006297, 0.0],
              [-80.981620784641663, 35.569041811808518, 0.0],
              [-80.981704517536002, 35.568789677694589, 0.0],
              [-80.982516984166892, 35.568468411759724, 0.0],
              [-80.982543585142906, 35.567437811943002, 0.0],
              [-80.982234918402071, 35.567117411681437, 0.0],
              [-80.982178584617273, 35.566934211329702, 0.0],
              [-80.981870117798451, 35.566865678437516, 0.0],
              [-80.981029317943168, 35.566935211267811, 0.0],
              [-80.980665918089855, 35.567645478397424, 0.0],
              [-80.980413784516983, 35.567783078022885, 0.0],
              [-80.980273918010198, 35.567966611105618, 0.0],
              [-80.97982551856019, 35.568058611537786, 0.0],
              [-80.97951778506588, 35.568448077915079, 0.0],
              [-80.979434517912594, 35.569112411647595, 0.0],
              [-80.979154118508589, 35.56906687859238, 0.0],
              [-80.978732985345715, 35.568540411557173, 0.0],
              [-80.978648118351941, 35.567945011799154, 0.0],
              [-80.978311317700488, 35.567578877770906, 0.0],
              [-80.978590784285032, 35.5669832118529, 0.0],
              [-80.978476784711503, 35.565471678207537, 0.0],
              [-80.978083584248878, 35.564830811675449, 0.0],
              [-80.977662784865515, 35.564579211198897, 0.0],
              [-80.977018517546043, 35.564831677888492, 0.0],
              [-80.97659798425785, 35.56483201190369, 0.0],
              [-80.97626138502352, 35.564626211510252, 0.0],
              [-80.975532585244764, 35.564672677977946, 0.0],
              [-80.975168118267717, 35.564535611579373, 0.0],
              [-80.975195584346508, 35.564100278327189, 0.0],
              [-80.975279718430272, 35.564077411685844, 0.0],
              [-80.975195184319446, 35.563802611016698, 0.0],
              [-80.974746384213788, 35.563413611505602, 0.0],
              [-80.974633718497145, 35.562978611438538, 0.0],
              [-80.974493318208616, 35.562772611443151, 0.0],
              [-80.973456517712151, 35.563025411348065, 0.0],
              [-80.973316384562025, 35.562979611055113, 0.0],
              [-80.973455584934825, 35.562315411496087, 0.0],
              [-80.97309071851889, 35.561834677809003, 0.0],
              [-80.972754317916753, 35.561766277861736, 0.0],
              [-80.972249917701049, 35.561904211890301, 0.0],
              [-80.971914117550583, 35.562316678368603, 0.0],
              [-80.971914918378275, 35.563003677814478, 0.0],
              [-80.972392384484863, 35.563759211659011, 0.0],
              [-80.972392585212276, 35.564034011934446, 0.0],
              [-80.971972384486705, 35.564126011287314, 0.0],
              [-80.97186031765365, 35.564217611794191, 0.0],
              [-80.971776784463884, 35.564675811464042, 0.0],
              [-80.971244717985087, 35.565111411545821, 0.0],
              [-80.971245185222898, 35.565500678232482, 0.0],
              [-80.971918918183547, 35.56625601143881, 0.0],
              [-80.973319984449944, 35.566002811889462, 0.0],
              [-80.973824784429581, 35.566231477961409, 0.0],
              [-80.973797118139359, 35.566529211277135, 0.0],
              [-80.973545718231819, 35.567102078373793, 0.0],
              [-80.973069585243493, 35.567514677901016, 0.0],
              [-80.972929917856163, 35.567927011509248, 0.0],
              [-80.972733984579577, 35.568156211478232, 0.0],
              [-80.972790784867868, 35.56875167770427, 0.0],
              [-80.973015585229831, 35.569186611058896, 0.0],
              [-80.973183784381135, 35.569278077977366, 0.0],
              [-80.973660118001192, 35.569025811436113, 0.0],
              [-80.973883984367575, 35.568750678433652, 0.0],
              [-80.974247984282982, 35.568521411540083, 0.0],
              [-80.974556717901166, 35.568681478293279, 0.0],
              [-80.975789985101017, 35.568703278320321, 0.0],
              [-80.976210584555588, 35.568863277851101, 0.0],
              [-80.976995318078721, 35.568702211242247, 0.0],
              [-80.977191785311035, 35.568885278208896, 0.0],
              [-80.977416584560189, 35.56936607822243, 0.0],
              [-80.978903717624334, 35.570555810970028, 0.0],
              [-80.978959984437026, 35.570716078447425, 0.0],
              [-80.979437118600089, 35.571105011452602, 0.0],
              [-80.979969917608798, 35.571356411738556, 0.0],
              [-80.980306918288917, 35.571722611926319, 0.0],
              [-80.980783584849647, 35.571928277664753, 0.0],
              [-80.980671717649855, 35.57204287826282, 0.0],
              [-80.979746384572238, 35.57192921120113, 0.0],
              [-80.979128984878031, 35.571334277653591, 0.0],
              [-80.978006784613768, 35.570625278400556, 0.0],
              [-80.977165718472364, 35.570442678232133, 0.0],
              [-80.976800985343047, 35.57021407824287, 0.0],
              [-80.976436518109054, 35.570122811853075, 0.0],
              [-80.975287385040119, 35.570284010984984, 0.0],
              [-80.974923184177229, 35.570398811270707, 0.0],
              [-80.974307117978654, 35.570926211854506, 0.0],
              [-80.973858784535722, 35.571063878585868, 0.0],
              [-80.973242185096211, 35.571133078415954, 0.0],
              [-80.973046118522859, 35.571247878072697, 0.0],
              [-80.973046518367269, 35.571545611781175, 0.0],
              [-80.973383384942139, 35.571980478578745, 0.0],
              [-80.973439784543871, 35.572278078167152, 0.0],
              [-80.973580118689156, 35.572392611718939, 0.0],
              [-80.974449185263381, 35.572506411295009, 0.0],
              [-80.974533384332901, 35.57262081113754, 0.0],
              [-80.975093985075247, 35.572574477655579, 0.0],
              [-80.97610251800036, 35.572069811194105, 0.0],
              [-80.976214718470914, 35.572138411922133, 0.0],
              [-80.976327584844441, 35.572688011546717, 0.0],
              [-80.976776318013094, 35.572825011114659, 0.0],
              [-80.97669238461765, 35.573008277716561, 0.0],
              [-80.976160384962839, 35.573489678421716, 0.0],
              [-80.976048584341882, 35.573741678460479, 0.0],
              [-80.976133317962223, 35.574222611329432, 0.0],
              [-80.976498184396959, 35.574611611510477, 0.0],
              [-80.97652638528578, 35.574749011553365, 0.0],
              [-80.976947384883431, 35.575160877982924, 0.0],
              [-80.977311985308617, 35.575298011318687, 0.0],
              [-80.978181117821464, 35.575366011908706, 0.0],
              [-80.978209918093697, 35.575938477879355, 0.0],
              [-80.97860258473041, 35.576144277674636, 0.0],
              [-80.979163384389039, 35.576258278528726, 0.0],
              [-80.97921991847825, 35.576601811775006, 0.0],
              [-80.979388184908885, 35.57664741162931, 0.0],
              [-80.980648984473433, 35.576165411157255, 0.0],
              [-80.9808453841828, 35.576233878271665, 0.0],
              [-80.980761584835065, 35.576417211535997, 0.0],
              [-80.980145318111283, 35.576830011450916, 0.0],
              [-80.979921518182039, 35.577196611897705, 0.0],
              [-80.979893918357291, 35.577517278181332, 0.0],
              [-80.98025878531999, 35.577906278111115, 0.0],
              [-80.980371785295404, 35.578524678094659, 0.0],
              [-80.981101384806166, 35.579073611461169, 0.0],
              [-80.98171851775929, 35.579347811748704, 0.0],
              [-80.981746917771119, 35.579554011912101, 0.0],
              [-80.98219591856558, 35.579920078037489, 0.0],
              [-80.982308184926211, 35.580103210972922, 0.0],
              [-80.983318117465842, 35.580606077742516, 0.0],
              [-80.984243318485696, 35.580536478428236, 0.0],
              [-80.985139917833322, 35.580215077808347, 0.0],
              [-80.985560518536545, 35.580260478268976, 0.0],
              [-80.98550471800597, 35.580466678161791, 0.0],
              [-80.984972585205014, 35.580902277937561, 0.0],
              [-80.984720384445396, 35.581017011557307, 0.0],
              [-80.983599317808455, 35.581178478363221, 0.0],
              [-80.98317898473536, 35.581385011346974, 0.0],
              [-80.983067117929878, 35.581568278148623, 0.0],
              [-80.982955917486493, 35.582301211590583, 0.0],
              [-80.982592517968925, 35.583080211267621, 0.0],
              [-80.982537785197451, 35.584042277727782, 0.0],
              [-80.983127384553185, 35.584705877908171, 0.0],
              [-80.983127717894689, 35.584934877764987, 0.0],
              [-80.982875385088789, 35.584935078581012, 0.0],
              [-80.98200551784177, 35.584317478382893, 0.0],
              [-80.981500585353302, 35.584157678479087, 0.0],
              [-80.981528517973032, 35.584043211212538, 0.0],
              [-80.981892517853794, 35.583699277880747, 0.0],
              [-80.981947917981046, 35.583172477692848, 0.0],
              [-80.982143718443851, 35.582874611566083, 0.0],
              [-80.982254718230138, 35.582027011438697, 0.0],
              [-80.981804984886011, 35.581134211120592, 0.0],
              [-80.980373518304631, 35.579898811197346, 0.0],
              [-80.979251318251244, 35.579235611395681, 0.0],
              [-80.978241984628596, 35.579167678594274, 0.0],
              [-80.97829778507203, 35.578938678309335, 0.0],
              [-80.97854991753951, 35.578709478583896, 0.0],
              [-80.978605518496991, 35.578343011369036, 0.0],
              [-80.97846491800486, 35.578114078356109, 0.0],
              [-80.978015917595059, 35.577725078317698, 0.0],
              [-80.977595117569109, 35.577473611578817, 0.0],
              [-80.9773985850766, 35.577267611204881, 0.0],
              [-80.976501318213238, 35.577062211575125, 0.0],
              [-80.975996184644359, 35.576650411579699, 0.0],
              [-80.975771984512818, 35.576742277887448, 0.0],
              [-80.975632517568698, 35.577246211879434, 0.0],
              [-80.975408317645432, 35.577292211064332, 0.0],
              [-80.975155185099297, 35.576674078158803, 0.0],
              [-80.974256718003872, 35.575552611579695, 0.0],
              [-80.973667718060554, 35.575278211019913, 0.0],
              [-80.973218384148993, 35.574614411013364, 0.0],
              [-80.972965918125965, 35.574500077890377, 0.0],
              [-80.972601718150472, 35.574614877621649, 0.0],
              [-80.972266117882228, 35.575302278506591, 0.0],
              [-80.972210917519604, 35.576035211430415, 0.0],
              [-80.97195958423579, 35.576814078049445, 0.0],
              [-80.971735718348796, 35.577180811897634, 0.0],
              [-80.97179338425849, 35.578531877890079, 0.0],
              [-80.972102317653764, 35.578943878193698, 0.0],
              [-80.972018918687866, 35.579516678531284, 0.0],
              [-80.973955718334736, 35.581530478460301, 0.0],
              [-80.97395618448239, 35.581942677831819, 0.0],
              [-80.973760117506259, 35.581988678418512, 0.0],
              [-80.97328291813416, 35.581508077888643, 0.0],
              [-80.97280598428037, 35.581302411649631, 0.0],
              [-80.971739718526536, 35.580524611353596, 0.0],
              [-80.971318584505497, 35.580044011042183, 0.0],
              [-80.971038184177175, 35.579929678119804, 0.0],
              [-80.97056171826334, 35.580021611685176, 0.0],
              [-80.970590118370481, 35.580410877646578, 0.0],
              [-80.970842984328158, 35.580823011772672, 0.0],
              [-80.970899384591291, 35.58109781183051, 0.0],
              [-80.96988991790721, 35.580846611787798, 0.0],
              [-80.9697489849204, 35.58020547768642, 0.0],
              [-80.969131718148518, 35.579816677939895, 0.0],
              [-80.96859911750488, 35.579908677796723, 0.0],
              [-80.967814984249344, 35.580619278187086, 0.0],
              [-80.967255717582233, 35.581764811756344, 0.0],
              [-80.967144384925689, 35.582429078131518, 0.0],
              [-80.966920784549103, 35.583001878082989, 0.0],
              [-80.966696785312152, 35.583299811093298, 0.0],
              [-80.966753785116452, 35.584170011604748, 0.0],
              [-80.966586385162444, 35.584834277642074, 0.0],
              [-80.966334585175971, 35.585315478271056, 0.0],
              [-80.966083317550655, 35.586231811543172, 0.0],
              [-80.966002184536038, 35.586198811461024, 0.0],
              [-80.965746717531019, 35.586094677895346, 0.0],
              [-80.965773584266529, 35.585109811925399, 0.0],
              [-80.965912317764932, 35.583735611312122, 0.0],
              [-80.966024318300612, 35.583643878269172, 0.0],
              [-80.966163984904085, 35.583254411492319, 0.0],
              [-80.966245784556051, 35.581193077772916, 0.0],
              [-80.966469785338774, 35.580963811539768, 0.0],
              [-80.966553518652589, 35.580666077913328, 0.0],
              [-80.966384984408691, 35.580391411509723, 0.0],
              [-80.966440584738578, 35.58004781107212, 0.0],
              [-80.967085118565436, 35.579749611321603, 0.0],
              [-80.96770091857509, 35.578878677770199, 0.0],
              [-80.968148917658937, 35.578489078502535, 0.0],
              [-80.968541184324678, 35.578328477736981, 0.0],
              [-80.969101985217421, 35.578396678360846, 0.0],
              [-80.969746984336396, 35.578579477723132, 0.0],
              [-80.970643917795954, 35.578418411304931, 0.0],
              [-80.970643517864275, 35.578120611177205, 0.0],
              [-80.970306784656444, 35.577846011811168, 0.0],
              [-80.969717784866759, 35.577617478294769, 0.0],
              [-80.969353184926035, 35.577365811342801, 0.0],
              [-80.968820318150748, 35.577183078576347, 0.0],
              [-80.968848118373586, 35.577022678035981, 0.0],
              [-80.969604518092027, 35.576609811812283, 0.0],
              [-80.970108317569498, 35.575899611023765, 0.0],
              [-80.970163784375487, 35.575372678039969, 0.0],
              [-80.970331584200935, 35.57514361172602, 0.0],
              [-80.970134517926127, 35.574364877654752, 0.0],
              [-80.969797784770108, 35.574090411078117, 0.0],
              [-80.969769584692102, 35.573953011598817, 0.0],
              [-80.969067984794279, 35.573289411660511, 0.0],
              [-80.968170718105142, 35.573084011542292, 0.0],
              [-80.968506717588852, 35.572694411596032, 0.0],
              [-80.969572118285498, 35.572831011219918, 0.0],
              [-80.970188585139681, 35.572670211137918, 0.0],
              [-80.970328384804304, 35.572441011555298, 0.0],
              [-80.97041198517951, 35.571960011011107, 0.0],
              [-80.970215517996493, 35.571708211676359, 0.0],
              [-80.970074718134697, 35.571227411580722, 0.0],
              [-80.969402318249962, 35.571457011873903, 0.0],
              [-80.969065784850315, 35.57138847812913, 0.0],
              [-80.968869385339659, 35.571182611058177, 0.0],
              [-80.968756785165269, 35.570816211932957, 0.0],
              [-80.967887317485435, 35.570381677714636, 0.0],
              [-80.967943117666721, 35.57015261099049, 0.0],
              [-80.968699584159594, 35.569831411396223, 0.0],
              [-80.968306784853965, 35.569465211627524, 0.0],
              [-80.968362584601451, 35.569281877860043, 0.0],
              [-80.968838584373387, 35.568846477963419, 0.0],
              [-80.968809985336662, 35.568388411197475, 0.0],
              [-80.968529384200551, 35.568136678045946, 0.0],
              [-80.967940917951651, 35.56818301181135, 0.0],
              [-80.967660717885153, 35.568297677777842, 0.0],
              [-80.967072584849205, 35.568779077817027, 0.0],
              [-80.966484184727904, 35.56896287829484, 0.0],
              [-80.966427984631366, 35.568871277637783, 0.0],
              [-80.966595784466577, 35.568573411472421, 0.0],
              [-80.966567184591682, 35.567977878278143, 0.0],
              [-80.966819118451241, 35.567725811681655, 0.0],
              [-80.966846517803475, 35.567130278527038, 0.0],
              [-80.96740678523058, 35.566877878332711, 0.0],
              [-80.967378517990156, 35.566626078519896, 0.0],
              [-80.967181984580606, 35.566443011897029, 0.0],
              [-80.966621184404147, 35.566191477850815, 0.0],
              [-80.966424784487245, 35.565985611791859, 0.0],
              [-80.966424518426805, 35.565802411754106, 0.0],
              [-80.96667651781371, 35.565573078061476, 0.0],
              [-80.967572985043276, 35.565183011603352, 0.0],
              [-80.967460384392794, 35.564702211152962, 0.0],
              [-80.966759385353413, 35.564496611563037, 0.0],
              [-80.966675118672285, 35.564405011476872, 0.0],
              [-80.966730117768535, 35.563374411397746, 0.0],
              [-80.9668979184937, 35.562984878277533, 0.0],
              [-80.96692538420217, 35.562618478420134, 0.0],
              [-80.966785118569362, 35.562481077946131, 0.0],
              [-80.966308784264541, 35.562596011826436, 0.0],
              [-80.966224584980438, 35.562527411662309, 0.0],
              [-80.966139118248108, 35.561221877995358, 0.0],
              [-80.965689518383513, 35.560260411328237, 0.0],
              [-80.965576785242391, 35.559779477743916, 0.0],
              [-80.965380384650189, 35.559504811160558, 0.0],
              [-80.965099918205539, 35.559321811536527, 0.0],
              [-80.963810518173361, 35.559208211492113, 0.0],
              [-80.963417918179232, 35.558979477945378, 0.0],
              [-80.962800384164879, 35.558155477900776, 0.0],
              [-80.962575717534492, 35.557720478225441, 0.0],
              [-80.962631185119633, 35.55717081181762, 0.0],
              [-80.96277078455347, 35.556781277771236, 0.0],
              [-80.96274231864507, 35.556231810991392, 0.0],
              [-80.96246158460211, 35.555888411034097, 0.0],
              [-80.962181118100347, 35.555705411716474, 0.0],
              [-80.962096584877443, 35.555270211370882, 0.0],
              [-80.961647918127241, 35.554949878180977, 0.0],
              [-80.961422918114636, 35.554171411410806, 0.0],
              [-80.960889717796377, 35.55366787810307, 0.0],
              [-80.960468584286332, 35.553027011060919, 0.0],
              [-80.960020517581512, 35.553118878112784, 0.0],
              [-80.959712718660498, 35.553645811035587, 0.0],
              [-80.959404385307522, 35.553600411627009, 0.0],
              [-80.959263584544729, 35.552890411605922, 0.0],
              [-80.959010918202679, 35.552592811281805, 0.0],
              [-80.958002317671898, 35.552753878495977, 0.0],
              [-80.957553984871964, 35.552937477731867, 0.0],
              [-80.95702211824063, 35.553441678237121, 0.0],
              [-80.956910184608077, 35.553647878006331, 0.0],
              [-80.956994784627454, 35.554128811921217, 0.0],
              [-80.958145117562708, 35.555318878062728, 0.0],
              [-80.958509584881256, 35.555547678397744, 0.0],
              [-80.959322585278457, 35.555753211045733, 0.0],
              [-80.95996731780896, 35.555821411965766, 0.0],
              [-80.96049998529935, 35.556050078368138, 0.0],
              [-80.960864584812001, 35.556347611183803, 0.0],
              [-80.960836717952347, 35.556508011701069, 0.0],
              [-80.960332585053081, 35.556737278019689, 0.0],
              [-80.959519985364409, 35.556875278558401, 0.0],
              [-80.959156118034358, 35.557310811456972, 0.0],
              [-80.959072385227813, 35.557677278335774, 0.0],
              [-80.959101717689379, 35.558868078624911, 0.0],
              [-80.959410185166078, 35.559165678366192, 0.0],
              [-80.959942918295297, 35.559325611694078, 0.0],
              [-80.960111185165573, 35.559485811126265, 0.0],
              [-80.96013951789871, 35.55973781194038, 0.0],
              [-80.960616518087704, 35.560149611761958, 0.0],
              [-80.961176784603083, 35.559966011477712, 0.0],
              [-80.961288717524312, 35.55982847855492, 0.0],
              [-80.961288585350189, 35.559668211453143, 0.0],
              [-80.961428584467697, 35.559553611355277, 0.0],
              [-80.961427185354196, 35.558225277761743, 0.0],
              [-80.9613429180271, 35.558133677987541, 0.0],
              [-80.961398118597458, 35.557263277677194, 0.0],
              [-80.961594384283231, 35.557377678456831, 0.0],
              [-80.962156384319201, 35.55877441113995, 0.0],
              [-80.962970117769501, 35.559644078365544, 0.0],
              [-80.962970918492033, 35.560331211315933, 0.0],
              [-80.963111184696572, 35.560468411846529, 0.0],
              [-80.963391518426562, 35.560445278435225, 0.0],
              [-80.964063784683333, 35.560101411519021, 0.0],
              [-80.964400118589865, 35.560101010993954, 0.0],
              [-80.964764718147364, 35.560352677650187, 0.0],
              [-80.965045385027508, 35.560764677769512, 0.0],
              [-80.965214518479243, 35.56149747807914, 0.0],
              [-80.965244184889798, 35.562986078294735, 0.0],
              [-80.965104517655391, 35.563467211465067, 0.0],
              [-80.96490858524983, 35.56374221108252, 0.0],
              [-80.964909717958676, 35.564635411629986, 0.0],
              [-80.964797717729837, 35.564795810981117, 0.0],
              [-80.96443351853938, 35.564956411047525, 0.0],
              [-80.964321584467342, 35.565185611482704, 0.0],
              [-80.964378117800862, 35.56555187850001, 0.0],
              [-80.964826784591722, 35.565780611047792, 0.0],
              [-80.96496711830963, 35.565940811629631, 0.0],
              [-80.964995518197298, 35.566238478190485, 0.0],
              [-80.965191918492764, 35.566421611601825, 0.0],
              [-80.965192785041069, 35.567223211293857, 0.0],
              [-80.964940517705799, 35.567200477997254, 0.0],
              [-80.964015118262608, 35.566811878615724, 0.0],
              [-80.963566784409522, 35.566926678006666, 0.0],
              [-80.963623517730611, 35.567476411536923, 0.0],
              [-80.963735718674684, 35.567636611364861, 0.0],
              [-80.963567984520282, 35.568071811174384, 0.0],
              [-80.963904784473343, 35.568438077740531, 0.0],
              [-80.963848917529461, 35.568644211414096, 0.0],
              [-80.963316717840343, 35.569011011329998, 0.0],
              [-80.963316918042437, 35.569171277990698, 0.0],
              [-80.963485185077374, 35.569239878135932, 0.0],
              [-80.964494317829093, 35.569285011408155, 0.0],
              [-80.964578585104491, 35.569399411927812, 0.0],
              [-80.964466918198454, 35.569811677713162, 0.0],
              [-80.964887917634002, 35.570292411282502, 0.0],
              [-80.964888784587941, 35.571116878174088, 0.0],
              [-80.964019784614848, 35.571071811088302, 0.0],
              [-80.963851718212567, 35.5711634782338, 0.0],
              [-80.963824784974861, 35.572125478169923, 0.0],
              [-80.962787384526536, 35.571897211704297, 0.0],
              [-80.961918518246847, 35.571966478417728, 0.0],
              [-80.961245184460083, 35.571577611372867, 0.0],
              [-80.960712584701056, 35.571601011927015, 0.0],
              [-80.960236385305535, 35.571853277757867, 0.0],
              [-80.960180717664144, 35.57224261194429, 0.0],
              [-80.960601718661025, 35.572608811441292, 0.0],
              [-80.961274718042972, 35.572906078124454, 0.0],
              [-80.961386917912165, 35.573020477768857, 0.0],
              [-80.961331117690577, 35.573295278039538, 0.0],
              [-80.960546584721413, 35.573616477856824, 0.0],
              [-80.960350517774643, 35.573799878420303, 0.0],
              [-80.960378918298545, 35.574074678164294, 0.0],
              [-80.960659385158451, 35.574280611908755, 0.0],
              [-80.961528584847287, 35.574394478046706, 0.0],
              [-80.962089718332265, 35.574829278496381, 0.0],
              [-80.962650384772402, 35.574943278292665, 0.0],
              [-80.962931117749974, 35.575172211721764, 0.0],
              [-80.963407718603875, 35.575286277620627, 0.0],
              [-80.963520384623635, 35.575744278345205, 0.0],
              [-80.963884984990202, 35.575927211371059, 0.0],
              [-80.96382938476826, 35.576316678177328, 0.0],
              [-80.96441878432104, 35.576980411768702, 0.0],
              [-80.964503518628817, 35.577552877634226, 0.0],
              [-80.964671985049037, 35.577850477940395, 0.0],
              [-80.96467238421944, 35.578079611533944, 0.0],
              [-80.964504184820029, 35.578148411344323, 0.0],
              [-80.963493918484147, 35.5771872784184, 0.0],
              [-80.963129184919524, 35.577004278272042, 0.0],
              [-80.962540385257313, 35.576867277862924, 0.0],
              [-80.962400118206247, 35.576684211533141, 0.0],
              [-80.962147584226429, 35.576569877956274, 0.0],
              [-80.961586918561892, 35.57647861150614, 0.0],
              [-80.961250184280146, 35.576227011466315, 0.0],
              [-80.96105391769504, 35.576181411379544, 0.0],
              [-80.960297118179469, 35.576273411029547, 0.0],
              [-80.960044984712923, 35.576388078344237, 0.0],
              [-80.959681117827714, 35.576983811568596, 0.0],
              [-80.95895231838972, 35.577076078249696, 0.0],
              [-80.958868517734174, 35.577236478241751, 0.0],
              [-80.958896784874582, 35.57755701123844, 0.0],
              [-80.959065384973414, 35.5778088117615, 0.0],
              [-80.958869518519094, 35.578106811692216, 0.0],
              [-80.958925784778287, 35.578381477880185, 0.0],
              [-80.959318384462406, 35.578564411165168, 0.0],
              [-80.959654917570802, 35.578610078477745, 0.0],
              [-80.960467785152034, 35.578472011887023, 0.0],
              [-80.960831917904741, 35.578334411881961, 0.0],
              [-80.961084385095447, 35.578494478378772, 0.0],
              [-80.960944717841272, 35.578723611934592, 0.0],
              [-80.960804917625353, 35.579204611193063, 0.0],
              [-80.960468784183647, 35.579456811624226, 0.0],
              [-80.960328984580968, 35.579846278005867, 0.0],
              [-80.959992917703573, 35.580006811568133, 0.0],
              [-80.959964985261664, 35.58023581180899, 0.0],
              [-80.959572718620535, 35.580419411230295, 0.0],
              [-80.959517184338722, 35.58096907830349, 0.0],
              [-80.959237118581754, 35.581198278361143, 0.0],
              [-80.959237317746059, 35.581381478487145, 0.0],
              [-80.960078584774294, 35.581587078014486, 0.0],
              [-80.960162918487868, 35.581839011781256, 0.0],
              [-80.960499718532375, 35.582090611745933, 0.0],
              [-80.96044378418388, 35.582342611094269, 0.0],
              [-80.959799318636328, 35.582617877982763, 0.0],
              [-80.958902184378275, 35.582618477743729, 0.0],
              [-80.95865018440233, 35.582939277772695, 0.0],
              [-80.958819118535615, 35.583603411782761, 0.0],
              [-80.959155784824972, 35.583786411697638, 0.0],
              [-80.959184184553735, 35.584244411231303, 0.0],
              [-80.959661318146175, 35.584633478470323, 0.0],
              [-80.959885717579013, 35.584724878233231, 0.0],
              [-80.959801784485236, 35.585045611852983, 0.0],
              [-80.959213184295947, 35.585160478492092, 0.0],
              [-80.959381785256497, 35.585435211400181, 0.0],
              [-80.959325717607925, 35.58552687787526, 0.0],
              [-80.958484184951445, 35.585115211855992, 0.0],
              [-80.958315584574422, 35.584748877643179, 0.0],
              [-80.958034984536681, 35.584542878557706, 0.0],
              [-80.957754918124024, 35.584634811759891, 0.0],
              [-80.957839518442242, 35.585230211758528, 0.0],
              [-80.957502984769462, 35.585184611111437, 0.0],
              [-80.956577517698861, 35.584841678353598, 0.0],
              [-80.956634717534001, 35.585986811590928, 0.0],
              [-80.957167718192494, 35.586330011687721, 0.0],
              [-80.958009118361602, 35.586489811021558, 0.0],
              [-80.958121517801445, 35.586833211356215, 0.0],
              [-80.958991317709845, 35.58742801109954, 0.0],
              [-80.958991518086691, 35.58765707800314, 0.0],
              [-80.95868311823584, 35.587634411882981, 0.0],
              [-80.958178184560367, 35.58745161120239, 0.0],
              [-80.95761758449666, 35.587543478146102, 0.0],
              [-80.957112717982795, 35.587337810974503, 0.0],
              [-80.956299784338171, 35.587429877722506, 0.0],
              [-80.956328317638039, 35.587933678033842, 0.0],
              [-80.956973785069934, 35.588551677790043, 0.0],
              [-80.957170584948798, 35.589169878077698, 0.0],
              [-80.957563517573803, 35.589536211151746, 0.0],
              [-80.958236784889465, 35.589764611043485, 0.0],
              [-80.959834985139906, 35.58994667843745, 0.0],
              [-80.960003384743516, 35.590061078569406, 0.0],
              [-80.960003784968009, 35.590427611171876, 0.0],
              [-80.957788717984357, 35.590383278332062, 0.0],
              [-80.957311918451467, 35.590131811596095, 0.0],
              [-80.956694918153744, 35.590017677860516, 0.0],
              [-80.956273718647125, 35.589468278552765, 0.0],
              [-80.955348184800968, 35.589171211164384, 0.0],
              [-80.955235918267178, 35.589033878196879, 0.0],
              [-80.955179517633667, 35.588644478080148, 0.0],
              [-80.954702384535949, 35.58816401174672, 0.0],
              [-80.954617785125635, 35.587706011249722, 0.0],
              [-80.954393118185124, 35.587339611869311, 0.0],
              [-80.953439384272585, 35.586905077747019, 0.0],
              [-80.952962517775319, 35.586539078136383, 0.0],
              [-80.952793784398764, 35.586104011794788, 0.0],
              [-80.952569318326994, 35.585920877803453, 0.0],
              [-80.952261118537663, 35.586058611101571, 0.0],
              [-80.952149185159243, 35.586333411489434, 0.0],
              [-80.951925118684713, 35.586516811728032, 0.0],
              [-80.952009584781408, 35.586883211269964, 0.0],
              [-80.9521781183889, 35.587157877829121, 0.0],
              [-80.952739317772895, 35.58766127787873, 0.0],
              [-80.953048384847179, 35.588348278086613, 0.0],
              [-80.95388998485366, 35.588943077597825, 0.0],
              [-80.954002384383188, 35.589126277668448, 0.0],
              [-80.954086984975504, 35.589675878258561, 0.0],
              [-80.954479917984358, 35.59008781182343, 0.0],
              [-80.954592584256332, 35.590614611681488, 0.0],
              [-80.955125917903402, 35.591232610974046, 0.0],
              [-80.955182318097158, 35.591484477876513, 0.0],
              [-80.955855718454856, 35.591919211057949, 0.0],
              [-80.955996118263712, 35.592216811182098, 0.0],
              [-80.956360917879763, 35.592491411901541, 0.0],
              [-80.956473785229264, 35.593224278223964, 0.0],
              [-80.956894584807031, 35.593407211653513, 0.0],
              [-80.960203984584922, 35.594114811246392, 0.0],
              [-80.962559917824137, 35.594708477722733, 0.0],
              [-80.963429318207844, 35.594822411877949, 0.0],
              [-80.964579317916019, 35.595165078206605, 0.0],
              [-80.964831917807444, 35.595371010965422, 0.0],
              [-80.964803918052581, 35.595508411671688, 0.0],
              [-80.960905717587522, 35.594824211828296, 0.0],
              [-80.958129117681793, 35.594116277981222, 0.0],
              [-80.957512184429447, 35.594162477736326, 0.0],
              [-80.957457185286856, 35.595170211870425, 0.0],
              [-80.957598117705189, 35.595948811464794, 0.0],
              [-80.957486185252819, 35.596132211669506, 0.0],
              [-80.95723378496983, 35.59613241107607, 0.0],
              [-80.957008918025792, 35.595559811450087, 0.0],
              [-80.956923918029148, 35.594712478453786, 0.0],
              [-80.956755385080555, 35.594437811605957, 0.0],
              [-80.956054384905983, 35.594369611058347, 0.0],
              [-80.956053984731241, 35.593980211232441, 0.0],
              [-80.95571718456145, 35.593659811003462, 0.0],
              [-80.954931984247878, 35.593545878497984, 0.0],
              [-80.954679384709195, 35.593362811198574, 0.0],
              [-80.95481918534459, 35.592950411632188, 0.0],
              [-80.954678918606987, 35.592790277909508, 0.0],
              [-80.95394971835384, 35.592653277984219, 0.0],
              [-80.953865318360954, 35.592286878072201, 0.0],
              [-80.953584584763618, 35.591989411327859, 0.0],
              [-80.953584518131777, 35.591897811587998, 0.0],
              [-80.953163518052833, 35.591531611240534, 0.0],
              [-80.95282711816013, 35.591508877881672, 0.0],
              [-80.952294518165914, 35.591646678352653, 0.0],
              [-80.951818184974215, 35.592082277988744, 0.0],
              [-80.95162231773034, 35.592448811516974, 0.0],
              [-80.951566718331236, 35.592838211632298, 0.0],
              [-80.951454518711429, 35.592952678387654, 0.0],
              [-80.950921517812603, 35.59265527808536, 0.0],
              [-80.950921318657919, 35.592449210928265, 0.0],
              [-80.951033517951018, 35.592334678546507, 0.0],
              [-80.951144784780723, 35.591601811688228, 0.0],
              [-80.951284917693172, 35.591441211177234, 0.0],
              [-80.951424384290135, 35.590708211682276, 0.0],
              [-80.951059384355872, 35.590136011218434, 0.0],
              [-80.950974985261595, 35.589861211091097, 0.0],
              [-80.950021185163394, 35.58940367785317, 0.0],
              [-80.94960091839377, 35.58956427837127, 0.0],
              [-80.949432984258863, 35.589953678213234, 0.0],
              [-80.949377584415487, 35.590640877641135, 0.0],
              [-80.949153518539461, 35.590938810943726, 0.0],
              [-80.949097918082714, 35.591442678546201, 0.0],
              [-80.948621118218455, 35.591259811335966, 0.0],
              [-80.948396184694118, 35.590595678430738, 0.0],
              [-80.948171517770461, 35.590252411320712, 0.0],
              [-80.947750784967567, 35.590023611637662, 0.0],
              [-80.947358384328524, 35.590138278389766, 0.0],
              [-80.947274384367589, 35.590298678355545, 0.0],
              [-80.947247118017216, 35.59114601178576, 0.0],
              [-80.946994984509004, 35.591352277877618, 0.0],
              [-80.946742518053753, 35.591329611629035, 0.0],
              [-80.946209517603819, 35.590848878070894, 0.0],
              [-80.94581671787644, 35.590666011336822, 0.0],
              [-80.944779318280169, 35.590575077604953, 0.0],
              [-80.944694984455055, 35.590483477761502, 0.0],
              [-80.944778518125872, 35.589727611055572, 0.0],
              [-80.944554117711036, 35.589590278150361, 0.0],
              [-80.943881318696171, 35.589774011054338, 0.0],
              [-80.942732318245021, 35.590415878439998, 0.0],
              [-80.942536318347862, 35.590736611030415, 0.0],
              [-80.942536585381362, 35.591034278491115, 0.0],
              [-80.942648918403066, 35.591217477816905, 0.0],
              [-80.943097785265053, 35.591583677722106, 0.0],
              [-80.94307018536621, 35.592087477963673, 0.0],
              [-80.942958185060036, 35.592248011598841, 0.0],
              [-80.942172985264605, 35.592065278166075, 0.0],
              [-80.941668318685998, 35.592180011458645, 0.0],
              [-80.941696584949, 35.592477811555199, 0.0],
              [-80.941892984914844, 35.592683811863211, 0.0],
              [-80.941865184561991, 35.592844078324966, 0.0],
              [-80.940939717779187, 35.592615611668137, 0.0],
              [-80.93950931860978, 35.592066678314396, 0.0],
              [-80.938976185199849, 35.591677611137335, 0.0],
              [-80.938695784297948, 35.591632011758563, 0.0],
              [-80.938359518027767, 35.591723811208709, 0.0],
              [-80.938163384897123, 35.592021678507948, 0.0],
              [-80.93810791808734, 35.592708811744629, 0.0],
              [-80.937323184939558, 35.593304611784497, 0.0],
              [-80.937267518017975, 35.593716877973762, 0.0],
              [-80.937407785279547, 35.593854211762832, 0.0],
              [-80.937547984689246, 35.593877078199071, 0.0],
              [-80.938360985078859, 35.593647611798737, 0.0],
              [-80.939313918448306, 35.593189077740227, 0.0],
              [-80.939930785348963, 35.593211611420159, 0.0],
              [-80.940463717685304, 35.593463211276372, 0.0],
              [-80.940576118320351, 35.593760877674441, 0.0],
              [-80.940520518327588, 35.594287811153158, 0.0],
              [-80.93973571839598, 35.594746277702399, 0.0],
              [-80.939399585056435, 35.595181611752736, 0.0],
              [-80.939372184469519, 35.596029011181386, 0.0],
              [-80.939680918231872, 35.596280677590883, 0.0],
              [-80.940437918003326, 35.596211611648961, 0.0],
              [-80.941082985117546, 35.596371478531758, 0.0],
              [-80.941223317974845, 35.596486011172473, 0.0],
              [-80.94186798536623, 35.596187877881114, 0.0],
              [-80.942035984852666, 35.595935878301738, 0.0],
              [-80.942091718169294, 35.595615278334463, 0.0],
              [-80.94234398505931, 35.595454811818918, 0.0],
              [-80.943044984399165, 35.595431410947043, 0.0],
              [-80.943605517982022, 35.595179078073393, 0.0],
              [-80.944166717922243, 35.59547661151656, 0.0],
              [-80.944278918715113, 35.595659678183878, 0.0],
              [-80.944223384255238, 35.596392611231899, 0.0],
              [-80.943971384806204, 35.596713477663833, 0.0],
              [-80.943382784376055, 35.597011478403346, 0.0],
              [-80.943130718086081, 35.597286478163255, 0.0],
              [-80.942962718595538, 35.597653077701679, 0.0],
              [-80.941925784958528, 35.598340810993768, 0.0],
              [-80.941785984972569, 35.598867611814484, 0.0],
              [-80.942010584569431, 35.599073611190093, 0.0],
              [-80.942711518414285, 35.599004478222383, 0.0],
              [-80.942879784488468, 35.599096011385662, 0.0],
              [-80.94346858436586, 35.598889478571408, 0.0],
              [-80.944309584430371, 35.59879727793053, 0.0],
              [-80.944757984704964, 35.598430678319687, 0.0],
              [-80.9449539183789, 35.597972477659575, 0.0],
              [-80.945149984551122, 35.597789211198901, 0.0],
              [-80.945626718353097, 35.59776601156841, 0.0],
              [-80.945962917676525, 35.59749087797897, 0.0],
              [-80.945962184799313, 35.596712211571401, 0.0],
              [-80.945878118659977, 35.59662061125097, 0.0],
              [-80.945961917999313, 35.596277077908105, 0.0],
              [-80.946186185218139, 35.596185411477663, 0.0],
              [-80.94711178501764, 35.596551211445536, 0.0],
              [-80.947224117596349, 35.596757278340824, 0.0],
              [-80.94786918530356, 35.596940077932189, 0.0],
              [-80.947757717959092, 35.597718878321139, 0.0],
              [-80.947869918562461, 35.597810411239735, 0.0],
              [-80.949047585126934, 35.597741011467875, 0.0],
              [-80.949243984738246, 35.597832478413864, 0.0],
              [-80.949608318038344, 35.597649011779232, 0.0],
              [-80.949860384923355, 35.597328278380708, 0.0],
              [-80.950364917654966, 35.597167478067377, 0.0],
              [-80.9507851842571, 35.596800811661943, 0.0],
              [-80.950953517909738, 35.596800677779996, 0.0],
              [-80.951234184264266, 35.597098277704873, 0.0],
              [-80.950561984525095, 35.597946078402408, 0.0],
              [-80.950506118266006, 35.598152278422532, 0.0],
              [-80.94969358526518, 35.598816878170517, 0.0],
              [-80.948908718466498, 35.599160878005179, 0.0],
              [-80.94801158432584, 35.599322011835582, 0.0],
              [-80.947535184202806, 35.599711478255337, 0.0],
              [-80.947675984281801, 35.600398610969606, 0.0],
              [-80.948096918582721, 35.600673078395047, 0.0],
              [-80.94851771803576, 35.600810278515354, 0.0],
              [-80.948742184987736, 35.600993277668543, 0.0],
              [-80.94916278472428, 35.60097021170148, 0.0],
              [-80.950620384262052, 35.600373677767287, 0.0],
              [-80.9511245846208, 35.599938277668457, 0.0],
              [-80.951432784829549, 35.599548811084006, 0.0],
              [-80.951657117636202, 35.599548611870361, 0.0],
              [-80.951853717582438, 35.599937811097391, 0.0],
              [-80.952863317924894, 35.599960078539844, 0.0],
              [-80.952835384532378, 35.600097477859073, 0.0],
              [-80.952499184403052, 35.600372478524491, 0.0],
              [-80.951433985078282, 35.600854211636452, 0.0],
              [-80.950817517619868, 35.601289811587257, 0.0],
              [-80.949949384303935, 35.602481277872215, 0.0],
              [-80.948828318257142, 35.603123077957498, 0.0],
              [-80.94591291760932, 35.604247211563973, 0.0],
              [-80.945941184809072, 35.604636678439171, 0.0],
              [-80.94602538496828, 35.604705278485291, 0.0],
              [-80.947455517748338, 35.604544011202186, 0.0],
              [-80.94832458437817, 35.604245811108662, 0.0],
              [-80.948913785193255, 35.604634811186237, 0.0],
              [-80.949502585264867, 35.604565677620741, 0.0],
              [-80.950455784894956, 35.604198611378585, 0.0],
              [-80.950792584665848, 35.604450278193418, 0.0],
              [-80.950540918564826, 35.605252078394756, 0.0],
              [-80.950569317712493, 35.605595678480825, 0.0],
              [-80.950877918007279, 35.605755678203607, 0.0],
              [-80.950850184540272, 35.606076411529195, 0.0],
              [-80.950598185116775, 35.606511678061082, 0.0],
              [-80.950654718608988, 35.606946811692453, 0.0],
              [-80.950879184327164, 35.607107011209756, 0.0],
              [-80.951187717683453, 35.607198411856551, 0.0],
              [-80.951832518405794, 35.60696887791957, 0.0],
              [-80.952196785317469, 35.606579410989625, 0.0],
              [-80.952336384259638, 35.606052478324656, 0.0],
              [-80.952280117533505, 35.605732011465072, 0.0],
              [-80.952419984785379, 35.605456878552282, 0.0],
              [-80.952980317748356, 35.60486107848385, 0.0],
              [-80.953232184268529, 35.604288411374519, 0.0],
              [-80.953624185106122, 35.603761411299608, 0.0],
              [-80.953734985111282, 35.6024100114753, 0.0],
              [-80.953930584605303, 35.601745811519102, 0.0],
              [-80.954042584870578, 35.601608277964743, 0.0],
              [-80.954351318063431, 35.601722611034255, 0.0],
              [-80.954381318279246, 35.603646278088867, 0.0],
              [-80.954717985300547, 35.603920878180453, 0.0],
              [-80.955559384191062, 35.603943211410872, 0.0],
              [-80.956849584206225, 35.604148477880337, 0.0],
              [-80.957157918541128, 35.604010878467115, 0.0],
              [-80.95715738419409, 35.603575811198198, 0.0],
              [-80.956820517829868, 35.603278278115006, 0.0],
              [-80.956764318643323, 35.603072077899846, 0.0],
              [-80.957577584179518, 35.603209011562107, 0.0],
              [-80.958054185338369, 35.603071211517459, 0.0],
              [-80.958025784904251, 35.602796411358675, 0.0],
              [-80.957913518401725, 35.602682010997611, 0.0],
              [-80.957941185194784, 35.602338411485633, 0.0],
              [-80.958193385288183, 35.602109211068424, 0.0],
              [-80.958417185364567, 35.601628077762783, 0.0],
              [-80.958528518189809, 35.6008036112732, 0.0],
              [-80.958752917585002, 35.600826411834618, 0.0],
              [-80.959005584982776, 35.601192611888429, 0.0],
              [-80.959118984766619, 35.602406277958899, 0.0],
              [-80.959315517783381, 35.602497811585309, 0.0],
              [-80.960072317938426, 35.602268210984882, 0.0],
              [-80.960520718261733, 35.601993078431889, 0.0],
              [-80.961753317703796, 35.600847011251993, 0.0],
              [-80.962173118448376, 35.600045078235439, 0.0],
              [-80.963013584536213, 35.599288678315979, 0.0],
              [-80.9635455175668, 35.598555410964622, 0.0],
              [-80.963320318263726, 35.59773107781885, 0.0],
              [-80.963460118221221, 35.597410277961671, 0.0],
              [-80.963656518555851, 35.597501811654261, 0.0],
              [-80.963881317583031, 35.597868211634228, 0.0],
              [-80.963937784453293, 35.598349010936978, 0.0],
              [-80.964106384541381, 35.598577877901185, 0.0],
              [-80.964023117563926, 35.59940247857017, 0.0],
              [-80.962930718060619, 35.600479811664385, 0.0],
              [-80.962678718392866, 35.600846277630893, 0.0],
              [-80.962454518066821, 35.600961010974636, 0.0],
              [-80.962174784810585, 35.601579611263723, 0.0],
              [-80.961614918451204, 35.602450277899777, 0.0],
              [-80.961110384344295, 35.60277141166334, 0.0],
              [-80.961054918172948, 35.603252277999552, 0.0],
              [-80.960075384870393, 35.605039411612637, 0.0],
              [-80.959178984306618, 35.606024878156816, 0.0],
              [-80.95912331869404, 35.606391277804221, 0.0],
              [-80.958983184847995, 35.606528877628413, 0.0],
              [-80.958702785033566, 35.60652901184757, 0.0],
              [-80.958197718508401, 35.606140078408522, 0.0],
              [-80.957804917661079, 35.606003011851655, 0.0],
              [-80.957328318190591, 35.606140811483549, 0.0],
              [-80.956852384660863, 35.606942677734992, 0.0],
              [-80.956908984890916, 35.607423611572813, 0.0],
              [-80.956993185314118, 35.607492211067459, 0.0],
              [-80.957022117835876, 35.608339611290596, 0.0],
              [-80.957527184262787, 35.608728611673108, 0.0],
              [-80.958874184533641, 35.609529211811569, 0.0],
              [-80.958986517930725, 35.609666477969576, 0.0],
              [-80.958958717595294, 35.609941278471908, 0.0],
              [-80.957163318292857, 35.609347211116749, 0.0],
              [-80.956686518580312, 35.609324611816774, 0.0],
              [-80.95601298517569, 35.608935811061379, 0.0],
              [-80.955339517697851, 35.608409478138775, 0.0],
              [-80.95472251792043, 35.608455677649012, 0.0],
              [-80.95435831786321, 35.608776611707242, 0.0],
              [-80.954106317633247, 35.609257678164205, 0.0],
              [-80.953741984709481, 35.609486877777641, 0.0],
              [-80.953602118368806, 35.60978481154779, 0.0],
              [-80.953658784696671, 35.610540611359923, 0.0],
              [-80.954248184306053, 35.610952411164206, 0.0],
              [-80.954192184484157, 35.611112811660767, 0.0],
              [-80.953771784893206, 35.611319210983574, 0.0],
              [-80.953603918629, 35.611639878261634, 0.0],
              [-80.953463585347805, 35.611640011304779, 0.0],
              [-80.952930118078285, 35.611044878086382, 0.0],
              [-80.952565384971578, 35.610839011617969, 0.0],
              [-80.951892117777831, 35.610656278081613, 0.0],
              [-80.95169558441988, 35.610450278216405, 0.0],
              [-80.951526985206556, 35.610061011738225, 0.0],
              [-80.950937718437899, 35.609763611636595, 0.0],
              [-80.950909384314059, 35.6094660118939, 0.0],
              [-80.950825185063437, 35.609397278008949, 0.0],
              [-80.950236384759052, 35.609535011758723, 0.0],
              [-80.950012184568564, 35.609764210979925, 0.0],
              [-80.949648318316562, 35.610451611047466, 0.0],
              [-80.948918984740786, 35.610223077752863, 0.0],
              [-80.948889984918438, 35.609146611131976, 0.0],
              [-80.948721585049114, 35.609078011257388, 0.0],
              [-80.947936918454459, 35.609674010974686, 0.0],
              [-80.947740585020327, 35.609719878455543, 0.0],
              [-80.947318984968689, 35.608666611394391, 0.0],
              [-80.94740238516907, 35.607887877947569, 0.0],
              [-80.947261984262298, 35.607681878439706, 0.0],
              [-80.946616518410153, 35.60713261091496, 0.0],
              [-80.946560117814286, 35.606766211129127, 0.0],
              [-80.946279718557548, 35.606835011720356, 0.0],
              [-80.946195984564014, 35.607293078245171, 0.0],
              [-80.945887784887844, 35.607591011459149, 0.0],
              [-80.945411317996914, 35.60786621095275, 0.0],
              [-80.944907118023181, 35.608622278224445, 0.0],
              [-80.944346718000816, 35.60919507770668, 0.0],
              [-80.944318984754076, 35.609607410980004, 0.0],
              [-80.944655784697716, 35.609859210907203, 0.0],
              [-80.945328918148945, 35.609973278529601, 0.0],
              [-80.94661911835486, 35.610041211097588, 0.0],
              [-80.947376584280008, 35.610384211456243, 0.0],
              [-80.947460917930783, 35.610613211159176, 0.0],
              [-80.947377185069385, 35.611002611676298, 0.0],
              [-80.947741984936187, 35.611254278237055, 0.0],
              [-80.948303118628971, 35.611460077705281, 0.0],
              [-80.949256317770264, 35.611070211451178, 0.0],
              [-80.949592984452678, 35.611207411694998, 0.0],
              [-80.949957784555949, 35.611482011492484, 0.0],
              [-80.95085551845672, 35.611802011558886, 0.0],
              [-80.951136318720415, 35.612076678267258, 0.0],
              [-80.951417584806975, 35.613038411278936, 0.0],
              [-80.95172631762226, 35.613313011030392, 0.0],
              [-80.952764318714799, 35.613518611491529, 0.0],
              [-80.953072984704036, 35.613793077731749, 0.0],
              [-80.953129317594119, 35.613953411124875, 0.0],
              [-80.953325785254563, 35.614113611078196, 0.0],
              [-80.954111385242825, 35.614433678521415, 0.0],
              [-80.954111584515019, 35.614639811660375, 0.0],
              [-80.953774984260761, 35.614640077775398, 0.0],
              [-80.952877184430918, 35.614274211459481, 0.0],
              [-80.952821118262335, 35.614182611112398, 0.0],
              [-80.951951385351919, 35.613908411316729, 0.0],
              [-80.951334385152791, 35.614000411227131, 0.0],
              [-80.950520384692553, 35.61331381159151, 0.0],
              [-80.949931184838618, 35.613085210976529, 0.0],
              [-80.949426117608979, 35.612696211238031, 0.0],
              [-80.948837118246473, 35.612719411738915, 0.0],
              [-80.948612985220223, 35.612902678034501, 0.0],
              [-80.947575384727116, 35.613086678371943, 0.0],
              [-80.947294717704608, 35.612789078532998, 0.0],
              [-80.946397318007499, 35.612972877938972, 0.0],
              [-80.946229118190757, 35.613110411441767, 0.0],
              [-80.945865118573437, 35.613751878025298, 0.0],
              [-80.945921518099127, 35.61407241179905, 0.0],
              [-80.946118184686782, 35.614438811269871, 0.0],
              [-80.946370784376413, 35.614644677917866, 0.0],
              [-80.947969518656592, 35.61480407764536, 0.0],
              [-80.947857585348203, 35.614987411040609, 0.0],
              [-80.947212918061652, 35.61540007783902, 0.0],
              [-80.94704471834261, 35.615606277612542, 0.0],
              [-80.947044984951731, 35.615949810949083, 0.0],
              [-80.948027185130456, 35.616498878023563, 0.0],
              [-80.948167584192731, 35.616727811256219, 0.0],
              [-80.947354384961741, 35.616865811708628, 0.0],
              [-80.94735538493758, 35.617965078302717, 0.0],
              [-80.94757998477283, 35.618216811649994, 0.0],
              [-80.94839371863398, 35.618559877553011, 0.0],
              [-80.949543785271246, 35.618788211561366, 0.0],
              [-80.950834584776032, 35.619359878051029, 0.0],
              [-80.950806717550932, 35.619566011258904, 0.0],
              [-80.950498184970115, 35.619543411613805, 0.0],
              [-80.949319784206523, 35.619108878053325, 0.0],
              [-80.947861318528538, 35.619132811491284, 0.0],
              [-80.947608985245338, 35.619247478104761, 0.0],
              [-80.94744091838848, 35.619499478437085, 0.0],
              [-80.947301917647295, 35.620896611135777, 0.0],
              [-80.9470213181704, 35.620873877800697, 0.0],
              [-80.946936984749655, 35.620644878288672, 0.0],
              [-80.946823718138106, 35.619248011284071, 0.0],
              [-80.946683184648535, 35.619041878408474, 0.0],
              [-80.946206184410926, 35.618813211200788, 0.0],
              [-80.946121585386365, 35.618355211835357, 0.0],
              [-80.945447718685728, 35.617508211355954, 0.0],
              [-80.944886318513383, 35.617027611576972, 0.0],
              [-80.944634118170441, 35.617119411183694, 0.0],
              [-80.944578985088796, 35.618447677669273, 0.0],
              [-80.943991518612179, 35.620097011516968, 0.0],
              [-80.943767118189925, 35.620165877703407, 0.0],
              [-80.943654917543668, 35.620074277991378, 0.0],
              [-80.943597984468056, 35.619112411625601, 0.0],
              [-80.943372984682284, 35.618448277927399, 0.0],
              [-80.94317651798896, 35.618219477931511, 0.0],
              [-80.942839917842534, 35.618311277964956, 0.0],
              [-80.942559917667197, 35.618815411806253, 0.0],
              [-80.942419717565485, 35.618884078512586, 0.0],
              [-80.94230751799158, 35.618815478432957, 0.0],
              [-80.942222985191577, 35.618449077564385, 0.0],
              [-80.941773984339832, 35.618037078455572, 0.0],
              [-80.941801518072566, 35.617327011572357, 0.0],
              [-80.941464584339315, 35.616983678105214, 0.0],
              [-80.941183984348882, 35.616869411485176, 0.0],
              [-80.940622984998953, 35.616801010983991, 0.0],
              [-80.940482185038732, 35.616159811322731, 0.0],
              [-80.940229585039305, 35.615862078157221, 0.0],
              [-80.940005117871095, 35.615816477945891, 0.0],
              [-80.939584585332611, 35.615908411782065, 0.0],
              [-80.939192184262765, 35.616275010942537, 0.0],
              [-80.939192518537837, 35.616824678231424, 0.0],
              [-80.939557518248634, 35.617145078296836, 0.0],
              [-80.940230718402589, 35.617259278296558, 0.0],
              [-80.939978718052799, 35.617809078377441, 0.0],
              [-80.940063317567777, 35.618267011459778, 0.0],
              [-80.940231784552225, 35.61856467778108, 0.0],
              [-80.940428185389962, 35.618702011815259, 0.0],
              [-80.940513117744786, 35.619663811105113, 0.0],
              [-80.940316985124241, 35.619824278341433, 0.0],
              [-80.939980318181242, 35.61987027780652, 0.0],
              [-80.939503184397509, 35.619412478017736, 0.0],
              [-80.939222584794933, 35.619275278024361, 0.0],
              [-80.939110118569147, 35.618840078524009, 0.0],
              [-80.938997784215488, 35.618794411347601, 0.0],
              [-80.938689385277769, 35.618954877800874, 0.0],
              [-80.938549517782889, 35.619252811091116, 0.0],
              [-80.938522118463666, 35.620237478379529, 0.0],
              [-80.938045385011861, 35.620375278117528, 0.0],
              [-80.937681517548157, 35.621245677718711, 0.0],
              [-80.937569317832512, 35.621245678196011, 0.0],
              [-80.937344718564063, 35.620994011776588, 0.0],
              [-80.93734438509901, 35.620650411553463, 0.0],
              [-80.937540517545571, 35.620352478033581, 0.0],
              [-80.937455517548401, 35.619138811744591, 0.0],
              [-80.937595318028272, 35.618749411227938, 0.0],
              [-80.937510717777741, 35.618016611017232, 0.0],
              [-80.9370335178766, 35.617512877998315, 0.0],
              [-80.936360385225726, 35.617605011789188, 0.0],
              [-80.935743184395662, 35.617376211644427, 0.0],
              [-80.935490918731858, 35.617536677968118, 0.0],
              [-80.935295517636703, 35.618796411142625, 0.0],
              [-80.935015384469949, 35.619254477873675, 0.0],
              [-80.934762985200933, 35.619460878201608, 0.0],
              [-80.933725517610696, 35.619804877760018, 0.0],
              [-80.933248784855266, 35.620034210984173, 0.0],
              [-80.933024385357896, 35.61996561170276, 0.0],
              [-80.933024318266931, 35.619736477750791, 0.0],
              [-80.933444718077311, 35.619301211507533, 0.0],
              [-80.933472718389226, 35.619118011627073, 0.0],
              [-80.933332318696031, 35.618980677737987, 0.0],
              [-80.93268718490431, 35.619095477646368, 0.0],
              [-80.932322384956521, 35.618775011817199, 0.0],
              [-80.932181784594405, 35.618294211552694, 0.0],
              [-80.932602318105154, 35.617973278110661, 0.0],
              [-80.93265791840011, 35.61728607801755, 0.0],
              [-80.932825985218003, 35.616988278496152, 0.0],
              [-80.932713717728376, 35.616896811033222, 0.0],
              [-80.930722718410934, 35.61737881165287, 0.0],
              [-80.930106118223136, 35.618020278246298, 0.0],
              [-80.928901185307026, 35.619784410990682, 0.0],
              [-80.928873718746743, 35.620517210916809, 0.0],
              [-80.928593517871036, 35.620975411038572, 0.0],
              [-80.928593585114285, 35.621135811692064, 0.0],
              [-80.928761917824033, 35.62122727840454, 0.0],
              [-80.929407117814264, 35.62120407762081, 0.0],
              [-80.929827585340064, 35.620952010889816, 0.0],
              [-80.930360385101892, 35.620814277915876, 0.0],
              [-80.930753184792692, 35.620859877742085, 0.0],
              [-80.930837384638892, 35.620928611405979, 0.0],
              [-80.930978118616494, 35.62163847842131, 0.0],
              [-80.931062318230772, 35.621707210947378, 0.0],
              [-80.931651118502231, 35.621523678394396, 0.0],
              [-80.932380585328204, 35.62172947825016, 0.0],
              [-80.932492985303213, 35.62202707794615, 0.0],
              [-80.932689385249574, 35.622141477748485, 0.0],
              [-80.933081984556011, 35.622004011126897, 0.0],
              [-80.933783318206252, 35.622049411083118, 0.0],
              [-80.933783517766386, 35.622347211747417, 0.0],
              [-80.934148318493328, 35.622553011532972, 0.0],
              [-80.934036184420222, 35.622805078249272, 0.0],
              [-80.933027118049921, 35.623652878299872, 0.0],
              [-80.932915184353476, 35.624156811749188, 0.0],
              [-80.93302758490745, 35.624317010896114, 0.0],
              [-80.93361651851194, 35.624225211752581, 0.0],
              [-80.934008984984942, 35.623904277769704, 0.0],
              [-80.934457318184712, 35.623194211353152, 0.0],
              [-80.934906317569457, 35.623468811111145, 0.0],
              [-80.935046584678531, 35.623651878525187, 0.0],
              [-80.935018918369224, 35.624041211238975, 0.0],
              [-80.934206184526886, 35.624980678226009, 0.0],
              [-80.934178184332495, 35.625141011362835, 0.0],
              [-80.93465518539692, 35.625324010931052, 0.0],
              [-80.934963718496846, 35.625278011725598, 0.0],
              [-80.935075784223045, 35.62514047778518, 0.0],
              [-80.93569251798975, 35.624453211682081, 0.0],
              [-80.935832384324755, 35.624132410886723, 0.0],
              [-80.935803917880818, 35.623559878458899, 0.0],
              [-80.935635518179822, 35.62335387815201, 0.0],
              [-80.935746918348826, 35.622300210884028, 0.0],
              [-80.936504384225643, 35.622460211664162, 0.0],
              [-80.936981384544652, 35.62275767832579, 0.0],
              [-80.937066185251609, 35.62358207841055, 0.0],
              [-80.937627384645609, 35.623833678339082, 0.0],
              [-80.937375318531551, 35.624337678033278, 0.0],
              [-80.937375584469777, 35.624681277994519, 0.0],
              [-80.937572118437757, 35.624818611485715, 0.0],
              [-80.938216985015984, 35.624635011257524, 0.0],
              [-80.938665584459827, 35.624360011336975, 0.0],
              [-80.939309984479721, 35.623351877707236, 0.0],
              [-80.940178918559965, 35.622526877898295, 0.0],
              [-80.940711718566803, 35.622320477838201, 0.0],
              [-80.940823918732676, 35.622434877797225, 0.0],
              [-80.940095384930288, 35.623305678013978, 0.0],
              [-80.939843317890436, 35.623740878033885, 0.0],
              [-80.939731585284775, 35.624473811119422, 0.0],
              [-80.939871984492086, 35.624725678116, 0.0],
              [-80.939872384793887, 35.625138011129842, 0.0],
              [-80.939396185349594, 35.625894011074564, 0.0],
              [-80.939340384936273, 35.626237611471687, 0.0],
              [-80.939761384839358, 35.626695411399865, 0.0],
              [-80.939930117959946, 35.627130478400154, 0.0],
              [-80.94035118455497, 35.627519611246505, 0.0],
              [-80.940772118149653, 35.627771211571464, 0.0],
              [-80.940996584996057, 35.627817011054013, 0.0],
              [-80.941557517734921, 35.627702078337613, 0.0],
              [-80.942062118282692, 35.627266677742398, 0.0],
              [-80.942201985315435, 35.626877211431463, 0.0],
              [-80.942370185146189, 35.62676261141312, 0.0],
              [-80.942538584218894, 35.626854078015121, 0.0],
              [-80.942735318256808, 35.62715181088025, 0.0],
              [-80.943072118047553, 35.627357677549199, 0.0],
              [-80.943436585306756, 35.627311677588189, 0.0],
              [-80.943632718622794, 35.626968078371235, 0.0],
              [-80.94363258461469, 35.626876477803997, 0.0],
              [-80.94416538432084, 35.626647211341471, 0.0],
              [-80.944697718472781, 35.625890878420314, 0.0],
              [-80.94492211870552, 35.625982410914851, 0.0],
              [-80.944922585387317, 35.626463411133933, 0.0],
              [-80.944782718495389, 35.62687567841126, 0.0],
              [-80.944895318054463, 35.627333677657518, 0.0],
              [-80.94534418490521, 35.627379278201872, 0.0],
              [-80.945904984823144, 35.627264411262338, 0.0],
              [-80.946325585081908, 35.626966411069034, 0.0],
              [-80.94663331852766, 35.626118811098642, 0.0],
              [-80.948119118679656, 35.625133077703062, 0.0],
              [-80.948287584560831, 35.625316211215178, 0.0],
              [-80.947699185114217, 35.626003677754866, 0.0],
              [-80.947082918070834, 35.626988811537309, 0.0],
              [-80.947027184219465, 35.627492810896079, 0.0],
              [-80.94714011796097, 35.628179678477977, 0.0],
              [-80.947673517703706, 35.628614477908954, 0.0],
              [-80.948402985011796, 35.628911811287878, 0.0],
              [-80.948403185149061, 35.629117878235988, 0.0],
              [-80.948150918382808, 35.629209678446841, 0.0],
              [-80.947112784531342, 35.629004278354657, 0.0],
              [-80.946074517856317, 35.628546877812461, 0.0],
              [-80.945709918246038, 35.628592877851119, 0.0],
              [-80.945205117943843, 35.62879927824244, 0.0],
              [-80.944728318101397, 35.628868278454647, 0.0],
              [-80.944447917818479, 35.629005811184904, 0.0],
              [-80.944307917644267, 35.629212077755056, 0.0],
              [-80.944308184223843, 35.629647211275149, 0.0],
              [-80.945009784425466, 35.629875811740476, 0.0],
              [-80.945094318292419, 35.630333877647111, 0.0],
              [-80.946609784440398, 35.630997077742151, 0.0],
              [-80.946581918060005, 35.631134477962689, 0.0],
              [-80.946301385048415, 35.631226277767823, 0.0],
              [-80.945824518254256, 35.631203811682013, 0.0],
              [-80.945403518118596, 35.631020678510644, 0.0],
              [-80.944982784229609, 35.631112610979706, 0.0],
              [-80.9448707185621, 35.631204277656721, 0.0],
              [-80.944505985109217, 35.631181611029589, 0.0],
              [-80.944140985338009, 35.630838278233064, 0.0],
              [-80.943804318186963, 35.630724011000254, 0.0],
              [-80.943215318265985, 35.630793010964275, 0.0],
              [-80.942850784896237, 35.630999277570801, 0.0],
              [-80.942710784636006, 35.631274210949918, 0.0],
              [-80.942711118312985, 35.631732211586687, 0.0],
              [-80.94304791772494, 35.631961011076726, 0.0],
              [-80.944787584495785, 35.632418211758058, 0.0],
              [-80.94515251852448, 35.632624011517372, 0.0],
              [-80.945068784339028, 35.63315081139995, 0.0],
              [-80.945152984924846, 35.633288211081755, 0.0],
              [-80.945966784494786, 35.633516677771354, 0.0],
              [-80.945966984846066, 35.633700010953888, 0.0],
              [-80.945125717576502, 35.634181477762041, 0.0],
              [-80.944817584638287, 35.634662611292697, 0.0],
              [-80.944874117860934, 35.63514347818748, 0.0],
              [-80.945688185356502, 35.635692677754527, 0.0],
              [-80.945912584910303, 35.635738411316922, 0.0],
              [-80.946389384389477, 35.635669411177915, 0.0],
              [-80.946585585084364, 35.635463077664937, 0.0],
              [-80.947230517717841, 35.635096278311188, 0.0],
              [-80.947342585322048, 35.634890011144414, 0.0],
              [-80.947145584912207, 35.634226078081689, 0.0],
              [-80.947257784394807, 35.634134278012048, 0.0],
              [-80.947959317562905, 35.634340011190353, 0.0],
              [-80.948464184972181, 35.634293878457356, 0.0],
              [-80.94866038433355, 35.63411061127163, 0.0],
              [-80.948828384942004, 35.63372107758893, 0.0],
              [-80.948827517549233, 35.632805011808138, 0.0],
              [-80.948715117982147, 35.632553078260528, 0.0],
              [-80.948742585280201, 35.632026411597941, 0.0],
              [-80.948938985141808, 35.631980478440923, 0.0],
              [-80.949079384402154, 35.632049011188698, 0.0],
              [-80.949557384406418, 35.633354211357577, 0.0],
              [-80.949866317561572, 35.633628811215537, 0.0],
              [-80.949502317732183, 35.634453611597166, 0.0],
              [-80.949025784257216, 35.634957678460665, 0.0],
              [-80.948885984679393, 35.635370077748895, 0.0],
              [-80.948998317861339, 35.635507411654388, 0.0],
              [-80.94947531790919, 35.635575810876411, 0.0],
              [-80.949980184698418, 35.635506811295208, 0.0],
              [-80.950905184912685, 35.634727411705917, 0.0],
              [-80.952167318538216, 35.634383077758855, 0.0],
              [-80.952475584215833, 35.634062278236328, 0.0],
              [-80.952587385378564, 35.633558411468542, 0.0],
              [-80.952586317881156, 35.632436077783836, 0.0],
              [-80.951911918413089, 35.631291477777204, 0.0],
              [-80.952023784893726, 35.631016611057873, 0.0],
              [-80.952304585328108, 35.631199611782641, 0.0],
              [-80.95309098461351, 35.632229611673566, 0.0],
              [-80.953428584846833, 35.633214211475391, 0.0],
              [-80.953765184231671, 35.633191211176204, 0.0],
              [-80.955083117792256, 35.632549011644784, 0.0],
              [-80.955082718088079, 35.632228278111199, 0.0],
              [-80.954857984700141, 35.631862078493967, 0.0],
              [-80.954577117531159, 35.631587411801227, 0.0],
              [-80.954632984784908, 35.631381278114034, 0.0],
              [-80.955166185363836, 35.631518277712871, 0.0],
              [-80.955502584242325, 35.631266211443112, 0.0],
              [-80.955502184633048, 35.630831011239479, 0.0],
              [-80.954996918133105, 35.630487811352133, 0.0],
              [-80.954996584670766, 35.630258678416737, 0.0],
              [-80.955304985074108, 35.630006677938169, 0.0],
              [-80.955360585207117, 35.629479811793495, 0.0],
              [-80.956032985128971, 35.628883877860531, 0.0],
              [-80.956144584529468, 35.628196677762993, 0.0],
              [-80.956593185126351, 35.628013211252885, 0.0],
              [-80.956901785059529, 35.628035877721487, 0.0],
              [-80.956902118450742, 35.62831067814939, 0.0],
              [-80.956761985109054, 35.628539811682572, 0.0],
              [-80.95670638487222, 35.628929278372681, 0.0],
              [-80.956230585165713, 35.629937278126697, 0.0],
              [-80.956230784753885, 35.630166278112306, 0.0],
              [-80.956427517743634, 35.630418077563291, 0.0],
              [-80.956427985027247, 35.63101361088011, 0.0],
              [-80.956708784716398, 35.631311078015955, 0.0],
              [-80.957270118205514, 35.631402277989956, 0.0],
              [-80.957690785173241, 35.631333411316099, 0.0],
              [-80.958055117568165, 35.631104078348201, 0.0],
              [-80.958195584762649, 35.631195611311597, 0.0],
              [-80.958308118392921, 35.631539011037184, 0.0],
              [-80.958336718418096, 35.632065810920665, 0.0],
              [-80.95844898443427, 35.632157277852841, 0.0],
              [-80.958701384592345, 35.632065477844783, 0.0],
              [-80.958813118227411, 35.631538677639256, 0.0],
              [-80.959514184565535, 35.631332011696912, 0.0],
              [-80.959766317638227, 35.630965410936561, 0.0],
              [-80.95998978496884, 35.630209478298887, 0.0],
              [-80.960522384442939, 35.629819810934215, 0.0],
              [-80.961419718425205, 35.629406811511075, 0.0],
              [-80.961643718692656, 35.629063078053925, 0.0],
              [-80.961614984231034, 35.628559278281038, 0.0],
              [-80.962259918442982, 35.628261077543279, 0.0],
              [-80.962343584794155, 35.627940411084722, 0.0],
              [-80.962231184731621, 35.62768847827342, 0.0],
              [-80.962258918423913, 35.627413678386084, 0.0],
              [-80.962342984240806, 35.627345010944573, 0.0],
              [-80.962932117790402, 35.62734441100995, 0.0],
              [-80.963801117994805, 35.62683987846264, 0.0],
              [-80.96405358518264, 35.626839677723723, 0.0],
              [-80.964053917627993, 35.627160411398869, 0.0],
              [-80.96343738510744, 35.627710477720946, 0.0],
              [-80.963241318250567, 35.628031411067127, 0.0],
              [-80.963326784827473, 35.629061877715898, 0.0],
              [-80.963579518050352, 35.629359411690551, 0.0],
              [-80.964000584220699, 35.629565211031284, 0.0],
              [-80.965094717542499, 35.629679011370747, 0.0],
              [-80.965094918033458, 35.629862211222758, 0.0],
              [-80.964646518404464, 35.630251878286046, 0.0],
              [-80.96464671822892, 35.630412211394415, 0.0],
              [-80.964843384541055, 35.630686811160381, 0.0],
              [-80.96540478479713, 35.631029878387054, 0.0],
              [-80.965573784214314, 35.631716878195157, 0.0],
              [-80.96621958448965, 35.632174411502973, 0.0],
              [-80.966360117825261, 35.632403278402663, 0.0],
              [-80.966417318250635, 35.633365078403607, 0.0],
              [-80.966305584787321, 35.633846278000711, 0.0],
              [-80.966361785356213, 35.63391487791251, 0.0],
              [-80.96700698522703, 35.633799878156132, 0.0],
              [-80.96737131791015, 35.633570610919193, 0.0],
              [-80.967791718338489, 35.633158011583973, 0.0],
              [-80.968968985184972, 35.632424210946837, 0.0],
              [-80.969277318510279, 35.632103277561242, 0.0],
              [-80.970622718232391, 35.631186078253911, 0.0],
              [-80.970903318040513, 35.631231677746634, 0.0],
              [-80.970791318291816, 35.631506611381724, 0.0],
              [-80.970230984901306, 35.632033878237465, 0.0],
              [-80.970091118418281, 35.632377410950227, 0.0],
              [-80.968409384735565, 35.633707210924534, 0.0],
              [-80.968437718498009, 35.633867411152991, 0.0],
              [-80.969924984763352, 35.634255611568534, 0.0],
              [-80.970654318520943, 35.6341634783183, 0.0],
              [-80.970906784572804, 35.634277811417945, 0.0],
              [-80.970878985298796, 35.634392277896154, 0.0],
              [-80.969981784375946, 35.634851077686037, 0.0],
              [-80.969701184978945, 35.634828411684815, 0.0],
              [-80.968662784416964, 35.634439877790662, 0.0],
              [-80.96807358471716, 35.634417411101168, 0.0],
              [-80.96773691772789, 35.634303078231127, 0.0],
              [-80.96726011827964, 35.634372277966278, 0.0],
              [-80.967203985289032, 35.634509677924449, 0.0],
              [-80.967344918058785, 35.634967677727303, 0.0],
              [-80.967120584231694, 35.635082278232808, 0.0],
              [-80.966896117605344, 35.635036678456572, 0.0],
              [-80.966530984830428, 35.634716411509601, 0.0],
              [-80.966250384185344, 35.634602078424741, 0.0],
              [-80.96546478512002, 35.634579677851271, 0.0],
              [-80.96538058427609, 35.634511078258285, 0.0],
              [-80.965380117778395, 35.634030211441399, 0.0],
              [-80.964986917917642, 35.633641211417121, 0.0],
              [-80.964537318299563, 35.632954410941146, 0.0],
              [-80.963722984792582, 35.632267878192565, 0.0],
              [-80.963245584223259, 35.631741611560514, 0.0],
              [-80.962459717541464, 35.631444411223193, 0.0],
              [-80.962122917613144, 35.631192811751212, 0.0],
              [-80.961814118289865, 35.631078411604662, 0.0],
              [-80.961449518266775, 35.631147411306323, 0.0],
              [-80.960973385236855, 35.631857677849318, 0.0],
              [-80.960637117541538, 35.632132811483594, 0.0],
              [-80.960525118183739, 35.632316077858874, 0.0],
              [-80.960525584362543, 35.632865810899858, 0.0],
              [-80.960189384372541, 35.633301211003229, 0.0],
              [-80.960161917653522, 35.633782077852096, 0.0],
              [-80.960021918539965, 35.634011277743042, 0.0],
              [-80.960050384882962, 35.634446411270233, 0.0],
              [-80.960247117841519, 35.634789811352633, 0.0],
              [-80.960219384524606, 35.635110477561916, 0.0],
              [-80.960135384222056, 35.635179211238501, 0.0],
              [-80.959910784724954, 35.635087678009874, 0.0],
              [-80.959742184753821, 35.634767211602565, 0.0],
              [-80.959208518098563, 35.634126277805912, 0.0],
              [-80.958815385069428, 35.633851811554344, 0.0],
              [-80.957693384357867, 35.633944277685551, 0.0],
              [-80.957328384643461, 35.633738411501383, 0.0],
              [-80.95693591864017, 35.633830278472963, 0.0],
              [-80.956151117593066, 35.634563611098478, 0.0],
              [-80.955281584356655, 35.634724478094398, 0.0],
              [-80.954440784823362, 35.635481011753306, 0.0],
              [-80.954440918125826, 35.635732878010018, 0.0],
              [-80.954749785183893, 35.635961678238637, 0.0],
              [-80.954693984346434, 35.636167877694234, 0.0],
              [-80.953740717660381, 35.636855477845472, 0.0],
              [-80.953797784235249, 35.637817411615011, 0.0],
              [-80.953938384251202, 35.638160877660681, 0.0],
              [-80.954275517945689, 35.638618678297007, 0.0],
              [-80.954584118347682, 35.63861847769396, 0.0],
              [-80.954752318191922, 35.638503877811104, 0.0],
              [-80.954976384973435, 35.638114411252516, 0.0],
              [-80.955340785042168, 35.637839278156477, 0.0],
              [-80.955509118171548, 35.637862077994257, 0.0],
              [-80.955789984569421, 35.638090810895839, 0.0],
              [-80.956771718109295, 35.637952811582494, 0.0],
              [-80.957613118400843, 35.637677478159382, 0.0],
              [-80.958061917648237, 35.637700011216936, 0.0],
              [-80.958062118573352, 35.637860277662604, 0.0],
              [-80.957277185048852, 35.638456277550354, 0.0],
              [-80.957221318459716, 35.638616677827429, 0.0],
              [-80.955959717750517, 35.639442078278655, 0.0],
              [-80.955819518242137, 35.639625411389524, 0.0],
              [-80.955819984850834, 35.640106277550949, 0.0],
              [-80.955988518029343, 35.640243611836304, 0.0],
              [-80.95626951828595, 35.640747278487346, 0.0],
              [-80.956354717926573, 35.641663278283275, 0.0],
              [-80.956270784474199, 35.641961078440652, 0.0],
              [-80.956299184827358, 35.64241907825334, 0.0],
              [-80.956832917576079, 35.642968411341201, 0.0],
              [-80.957478318011297, 35.643105278292296, 0.0],
              [-80.958460185245897, 35.643036011140424, 0.0],
              [-80.959862718421661, 35.642645678138791, 0.0],
              [-80.960227117807207, 35.642393478347991, 0.0],
              [-80.960451518077022, 35.642416078047575, 0.0],
              [-80.960816518146075, 35.642599078356447, 0.0],
              [-80.961573985263996, 35.642598610909815, 0.0],
              [-80.962134984938118, 35.642483677581986, 0.0],
              [-80.963116317800853, 35.641841677598499, 0.0],
              [-80.963452918426171, 35.641841478215149, 0.0],
              [-80.963425117834248, 35.642047611339748, 0.0],
              [-80.963088918088076, 35.642437211694478, 0.0],
              [-80.963089118179596, 35.642689077785526, 0.0],
              [-80.963538384319804, 35.643009411070373, 0.0],
              [-80.96412771777544, 35.643100610866014, 0.0],
              [-80.964997917707592, 35.643420478387348, 0.0],
              [-80.965530984552245, 35.643534677700337, 0.0],
              [-80.965643385144304, 35.6436262108938, 0.0],
              [-80.965643517972779, 35.643832277762186, 0.0],
              [-80.96530691775807, 35.643901211214015, 0.0],
              [-80.964801917917555, 35.643878678058982, 0.0],
              [-80.963538984943312, 35.643536211810719, 0.0],
              [-80.961518784534491, 35.643469010895437, 0.0],
              [-80.960733518637355, 35.643675678213583, 0.0],
              [-80.960144318569604, 35.643676078192073, 0.0],
              [-80.959863918095365, 35.643836611749819, 0.0],
              [-80.959639917744965, 35.644203078037897, 0.0],
              [-80.958995185195064, 35.644799211494473, 0.0],
              [-80.958883318135065, 35.645165611161104, 0.0],
              [-80.958911784380788, 35.645623678207357, 0.0],
              [-80.960146517964077, 35.645737410879114, 0.0],
              [-80.960230718337684, 35.645828877630649, 0.0],
              [-80.960202784457039, 35.645943411236033, 0.0],
              [-80.95933331755549, 35.646127211800469, 0.0],
              [-80.959361784607651, 35.646631077917377, 0.0],
              [-80.958997185135701, 35.646791611076438, 0.0],
              [-80.958997385222531, 35.646974811806693, 0.0],
              [-80.959362518576683, 35.647272278482582, 0.0],
              [-80.960008118094137, 35.647500811648619, 0.0],
              [-80.959924184779851, 35.647775678303724, 0.0],
              [-80.958970117928175, 35.647707678031232, 0.0],
              [-80.958605585307765, 35.647937011762345, 0.0],
              [-80.958718718129575, 35.648761411116702, 0.0],
              [-80.959280584370944, 35.649494010977257, 0.0],
              [-80.959252718567456, 35.649631411638481, 0.0],
              [-80.95832691769732, 35.64970067782059, 0.0],
              [-80.958102585240809, 35.649907011689045, 0.0],
              [-80.958075584559111, 35.650937678472246, 0.0],
              [-80.958468917611739, 35.651349611503754, 0.0],
              [-80.959592118064919, 35.652196211619433, 0.0],
              [-80.95962091839499, 35.6529062117474, 0.0],
              [-80.960770918454841, 35.652401478013374, 0.0],
              [-80.961724585276897, 35.652148811808594, 0.0],
              [-80.962874317585161, 35.651506811321966, 0.0],
              [-80.963210984906453, 35.65150641089545, 0.0],
              [-80.963211318256597, 35.651689678441556, 0.0],
              [-80.962846984924724, 35.652125077880427, 0.0],
              [-80.962033985165348, 35.652858478434077, 0.0],
              [-80.962034185126143, 35.653087611364001, 0.0],
              [-80.962371185350165, 35.653247611300657, 0.0],
              [-80.962371384248598, 35.653476611033661, 0.0],
              [-80.962231585147066, 35.653820278384686, 0.0],
              [-80.962456185040708, 35.653888810921174, 0.0],
              [-80.963774917963278, 35.653865011509517, 0.0],
              [-80.964055384787315, 35.653773211494425, 0.0],
              [-80.964503984649156, 35.653406411735588, 0.0],
              [-80.96481278519181, 35.653520678456076, 0.0],
              [-80.964532918461387, 35.654208011377115, 0.0],
              [-80.964028717656348, 35.654964011134687, 0.0],
              [-80.964001784874753, 35.655949010851351, 0.0],
              [-80.963833584791999, 35.656201011255632, 0.0],
              [-80.963581317742097, 35.656407411176467, 0.0],
              [-80.963497918159149, 35.657094478358722, 0.0],
              [-80.962768784269727, 35.65764461117795, 0.0],
              [-80.962628784195971, 35.65789667750802, 0.0],
              [-80.962657184328663, 35.658148611312072, 0.0],
              [-80.963330518513089, 35.658033478029708, 0.0],
              [-80.964059517549501, 35.657483277969675, 0.0],
              [-80.965013318667758, 35.65716201102672, 0.0],
              [-80.965124785212836, 35.656543478469317, 0.0],
              [-80.965405184432171, 35.656314278059028, 0.0],
              [-80.965966184885886, 35.65613061090059, 0.0],
              [-80.966106384440977, 35.656016077849564, 0.0],
              [-80.966358184265857, 35.655374611739681, 0.0],
              [-80.966413384602021, 35.654618811037231, 0.0],
              [-80.967002518070728, 35.654435011470611, 0.0],
              [-80.967170585011871, 35.654205810921496, 0.0],
              [-80.967366117715954, 35.653381277788739, 0.0],
              [-80.967702384841331, 35.65306041091592, 0.0],
              [-80.967842784831433, 35.653151877799793, 0.0],
              [-80.967899184769522, 35.653335077850848, 0.0],
              [-80.968208318661667, 35.653678277586074, 0.0],
              [-80.968208384179107, 35.653838610919891, 0.0],
              [-80.968489318113996, 35.654021611267979, 0.0],
              [-80.969358917616219, 35.653860611129581, 0.0],
              [-80.96947131801663, 35.653952210873918, 0.0],
              [-80.969444118346729, 35.654753678090778, 0.0],
              [-80.969248318528955, 35.655280678191261, 0.0],
              [-80.96927691824753, 35.655670011368606, 0.0],
              [-80.969810517946797, 35.656081811774619, 0.0],
              [-80.970400118354874, 35.656310278207435, 0.0],
              [-80.97098918449359, 35.656264078341913, 0.0],
              [-80.971129384383502, 35.656103677976759, 0.0],
              [-80.971212984674494, 35.655622611380196, 0.0],
              [-80.972446518398172, 35.654682611050944, 0.0],
              [-80.972642585234865, 35.654407678166223, 0.0],
              [-80.972670518481024, 35.654224411740586, 0.0],
              [-80.972164985176732, 35.653927077664825, 0.0],
              [-80.97196771843295, 35.653217210829006, 0.0],
              [-80.971714917882466, 35.652942611626742, 0.0],
              [-80.97104078530711, 35.652416410940134, 0.0],
              [-80.971068518152109, 35.652141611630107, 0.0],
              [-80.971376985219734, 35.651981011179636, 0.0],
              [-80.971545118694394, 35.65179767834946, 0.0],
              [-80.971741584449234, 35.65184327813413, 0.0],
              [-80.971854117816179, 35.652049411813053, 0.0],
              [-80.971882717704062, 35.652461478317839, 0.0],
              [-80.972416317856144, 35.652873411595507, 0.0],
              [-80.972809784428861, 35.653422678318805, 0.0],
              [-80.973848584476201, 35.65392567814299, 0.0],
              [-80.974213384743308, 35.653925278234667, 0.0],
              [-80.974409718229651, 35.653833478006369, 0.0],
              [-80.975390718073442, 35.652893678161519, 0.0],
              [-80.976428184763591, 35.652411878025951, 0.0],
              [-80.977438385269537, 35.652411011241199, 0.0],
              [-80.978111584974627, 35.652250077572241, 0.0],
              [-80.978335718153517, 35.651998011717559, 0.0],
              [-80.97841898419405, 35.651288011507162, 0.0],
              [-80.978531185053427, 35.651196277541111, 0.0],
              [-80.978727717636943, 35.651219011190413, 0.0],
              [-80.978952717590246, 35.651676811051296, 0.0],
              [-80.978868917668819, 35.651951678396919, 0.0],
              [-80.979318118536341, 35.652180410959673, 0.0],
              [-80.978870117531542, 35.652913611418207, 0.0],
              [-80.978786517816573, 35.653417478037461, 0.0],
              [-80.978842984895465, 35.653692277927199, 0.0],
              [-80.979124117758403, 35.654012677536748, 0.0],
              [-80.978956184231194, 35.654379278212595, 0.0],
              [-80.979069318367038, 35.655020478037336, 0.0],
              [-80.979265785211808, 35.655066210847728, 0.0],
              [-80.979854918325316, 35.654928211133424, 0.0],
              [-80.980415584128679, 35.654607010936921, 0.0],
              [-80.98072431798397, 35.654561011539009, 0.0],
              [-80.981482518095774, 35.654995411672672, 0.0],
              [-80.982155717831247, 35.654903277854608, 0.0],
              [-80.982435917884715, 35.654605211635015, 0.0],
              [-80.982828517880236, 35.654353010930016, 0.0],
              [-80.983051984991164, 35.653665611802218, 0.0],
              [-80.983444384441782, 35.653298877690055, 0.0],
              [-80.983443384842303, 35.652520211335876, 0.0],
              [-80.98358338490668, 35.652336811178081, 0.0],
              [-80.984424718408661, 35.651923678095606, 0.0],
              [-80.984620385197942, 35.651442611782691, 0.0],
              [-80.984647984821763, 35.651099077516555, 0.0],
              [-80.984815918633757, 35.650801211179008, 0.0],
              [-80.985292584343199, 35.650525878015465, 0.0],
              [-80.985499318615211, 35.650085810999961, 0.0],
              [-80.985572384857107, 35.64993021095956, 0.0],
              [-80.985796918648589, 35.650044611671646, 0.0],
              [-80.985994718242139, 35.651052011652908, 0.0],
              [-80.986444118229244, 35.651280678443506, 0.0],
              [-80.986921118428029, 35.651280278202258, 0.0],
              [-80.987678318428863, 35.651027478033846, 0.0],
              [-80.987779385091144, 35.651027411692766, 0.0],
              [-80.987958918145864, 35.651027278199997, 0.0],
              [-80.987902984200204, 35.65121061094289, 0.0],
              [-80.987174117894583, 35.651692211219718, 0.0],
              [-80.987005984787913, 35.651898478293845, 0.0],
              [-80.987006718467313, 35.65237947843498, 0.0],
              [-80.987259984615847, 35.652906011524927, 0.0],
              [-80.987232517490853, 35.653272410874642, 0.0],
              [-80.987120384229044, 35.65338707768651, 0.0],
              [-80.986895785181787, 35.653295611209636, 0.0],
              [-80.986332918516254, 35.652059478112655, 0.0],
              [-80.985630385100066, 35.651396010829927, 0.0],
              [-80.985405918638889, 35.651304478326985, 0.0],
              [-80.9850413846885, 35.651533811119222, 0.0],
              [-80.984985917473864, 35.65206067837191, 0.0],
              [-80.984817984274102, 35.652312811475745, 0.0],
              [-80.984201384539631, 35.652794277822892, 0.0],
              [-80.984201917575817, 35.65320661085272, 0.0],
              [-80.984763784562972, 35.653732810982298, 0.0],
              [-80.984904385267853, 35.653961678097673, 0.0],
              [-80.984876718014391, 35.65423647812738, 0.0],
              [-80.984624717629799, 35.654603210886208, 0.0],
              [-80.984203985317038, 35.654809678425444, 0.0],
              [-80.983250318562369, 35.654993878003893, 0.0],
              [-80.983053917785512, 35.655108478423323, 0.0],
              [-80.982914184820075, 35.655543810845742, 0.0],
              [-80.982381184557113, 35.655567211708217, 0.0],
              [-80.982325184947825, 35.655750478323974, 0.0],
              [-80.981736585328022, 35.656209011570148, 0.0],
              [-80.980782184844429, 35.655912077893589, 0.0],
              [-80.980612985267371, 35.655293877832435, 0.0],
              [-80.979322785000136, 35.655799010878503, 0.0],
              [-80.97904251803719, 35.656028211334842, 0.0],
              [-80.978987184923895, 35.656669478335189, 0.0],
              [-80.978734584981467, 35.656669811371252, 0.0],
              [-80.978341585112858, 35.656486811221072, 0.0],
              [-80.978004318437073, 35.656074877718602, 0.0],
              [-80.977414718259666, 35.655800611000089, 0.0],
              [-80.977357917798798, 35.655296811610228, 0.0],
              [-80.976936385344544, 35.654793278044288, 0.0],
              [-80.976655518272295, 35.654610411250971, 0.0],
              [-80.976318784492719, 35.654633477589037, 0.0],
              [-80.976066517892434, 35.654794011035911, 0.0],
              [-80.975898385134201, 35.65502321148216, 0.0],
              [-80.975646784232978, 35.655733411631232, 0.0],
              [-80.975394717893224, 35.656145878338975, 0.0],
              [-80.975057917500649, 35.656077477765351, 0.0],
              [-80.974861184536621, 35.655917278315364, 0.0],
              [-80.974440384974699, 35.65594061115943, 0.0],
              [-80.973991917949149, 35.656307411108088, 0.0],
              [-80.973458984624941, 35.65651387795436, 0.0],
              [-80.973150585203655, 35.656812011177173, 0.0],
              [-80.973235918556171, 35.657636411422828, 0.0],
              [-80.973460517773233, 35.657819411661883, 0.0],
              [-80.973404717599195, 35.658048477601376, 0.0],
              [-80.972955717788139, 35.658003011765636, 0.0],
              [-80.972421717911189, 35.657362211509025, 0.0],
              [-80.971804517650682, 35.657477211559261, 0.0],
              [-80.971215784626935, 35.657958611536884, 0.0],
              [-80.971216517749539, 35.658531277910363, 0.0],
              [-80.971272917973181, 35.658783077928199, 0.0],
              [-80.971189185015987, 35.659264078146919, 0.0],
              [-80.971020917547534, 35.659333011169814, 0.0],
              [-80.970515385152325, 35.658944077750583, 0.0],
              [-80.970402718093382, 35.658554811616042, 0.0],
              [-80.970234185200553, 35.658440410925976, 0.0],
              [-80.969532717923826, 35.658486810928139, 0.0],
              [-80.969336117744447, 35.658395411381818, 0.0],
              [-80.969083184585003, 35.65800627808143, 0.0],
              [-80.968746117755202, 35.657777411718179, 0.0],
              [-80.967764185202796, 35.657938610914663, 0.0],
              [-80.967399784191045, 35.658328078037194, 0.0],
              [-80.967428184928877, 35.65862601139672, 0.0],
              [-80.967737184662553, 35.658900477507551, 0.0],
              [-80.968298784246883, 35.659151878079612, 0.0],
              [-80.967934585343855, 35.659724811388507, 0.0],
              [-80.967851317963081, 35.660526478339996, 0.0],
              [-80.968076118151046, 35.660732411763455, 0.0],
              [-80.968356917977289, 35.660846677873316, 0.0],
              [-80.96891851851646, 35.661235611302679, 0.0],
              [-80.968863118234438, 35.661808211714217, 0.0],
              [-80.968975518678633, 35.661922611302479, 0.0],
              [-80.968555118648396, 35.662472611366134, 0.0],
              [-80.968555917984688, 35.663091011615244, 0.0],
              [-80.968668318150151, 35.663251211591209, 0.0],
              [-80.96872571837082, 35.664350477823618, 0.0],
              [-80.968866518561043, 35.664716811709873, 0.0],
              [-80.968978785124676, 35.664808277858953, 0.0],
              [-80.969427717507571, 35.664739278432471, 0.0],
              [-80.969932585295254, 35.664532811731178, 0.0],
              [-80.97172718461303, 35.663202877920718, 0.0],
              [-80.971979784872303, 35.663202678166016, 0.0],
              [-80.972036318401749, 35.663569078401785, 0.0],
              [-80.972849784477035, 35.663316478066662, 0.0],
              [-80.973214584639479, 35.663316077896084, 0.0],
              [-80.973354918600293, 35.663247411709484, 0.0],
              [-80.973719784209493, 35.66329287819125, 0.0],
              [-80.973691985226324, 35.663521878212563, 0.0],
              [-80.973467717671397, 35.663705211192614, 0.0],
              [-80.97256998439012, 35.663912210921467, 0.0],
              [-80.972205518291801, 35.664233078392186, 0.0],
              [-80.971447984532347, 35.664439878182733, 0.0],
              [-80.971111785158271, 35.66482941146981, 0.0],
              [-80.97015831800033, 35.665494411248794, 0.0],
              [-80.970046184702525, 35.665677811718062, 0.0],
              [-80.970046584301329, 35.66590681165917, 0.0],
              [-80.970355518163686, 35.666066811421821, 0.0],
              [-80.970467985014665, 35.666250011303816, 0.0],
              [-80.969513917814083, 35.666456878139201, 0.0],
              [-80.969289584410816, 35.66657147826546, 0.0],
              [-80.969149585123191, 35.666846411095044, 0.0],
              [-80.969177985239781, 35.66714427834772, 0.0],
              [-80.969543184304356, 35.667395878139565, 0.0],
              [-80.969655718592136, 35.667624811572182, 0.0],
              [-80.97038551867341, 35.667784477918197, 0.0],
              [-80.971030984760475, 35.667783878179684, 0.0],
              [-80.971171384729885, 35.667875478454128, 0.0],
              [-80.971088117840452, 35.668562611379755, 0.0],
              [-80.971285117854777, 35.668951877858291, 0.0],
              [-80.971257584781085, 35.669455677799917, 0.0],
              [-80.971398117928004, 35.669638811646024, 0.0],
              [-80.971314184244306, 35.669845011212416, 0.0],
              [-80.971005384679543, 35.669753611061147, 0.0],
              [-80.970134317919246, 35.668884077996246, 0.0],
              [-80.969151318307055, 35.668358078266728, 0.0],
              [-80.96870231809288, 35.668358410839701, 0.0],
              [-80.968533984925557, 35.668450078184023, 0.0],
              [-80.968562318579075, 35.668656278315098, 0.0],
              [-80.969293117573869, 35.669525878262746, 0.0],
              [-80.969265584768465, 35.67007561168424, 0.0],
              [-80.968985784720275, 35.670763011317938, 0.0],
              [-80.968986185166614, 35.671152278361937, 0.0],
              [-80.969295318365553, 35.671381011207387, 0.0],
              [-80.970053317640307, 35.671655277943806, 0.0],
              [-80.97039051855262, 35.6719986110012, 0.0],
              [-80.971120384742377, 35.67213541178063, 0.0],
              [-80.972047318175655, 35.672661277774338, 0.0],
              [-80.972580585362394, 35.672775411704599, 0.0],
              [-80.973338384302835, 35.672683210909859, 0.0],
              [-80.974320385279867, 35.67245341102992, 0.0],
              [-80.975808984922111, 35.673185011085209, 0.0],
              [-80.976735584528853, 35.67343601145371, 0.0],
              [-80.977353318052906, 35.673687411196326, 0.0],
              [-80.977662384971723, 35.673962011115471, 0.0],
              [-80.977719184773392, 35.67446581083145, 0.0],
              [-80.977915984683278, 35.674717677644189, 0.0],
              [-80.97802891784238, 35.675244211364216, 0.0],
              [-80.978338318624225, 35.675747878358223, 0.0],
              [-80.978282717835839, 35.676137277761214, 0.0],
              [-80.977918584411398, 35.676824611699644, 0.0],
              [-80.977918984318393, 35.677122277702217, 0.0],
              [-80.978790118035022, 35.677854410819286, 0.0],
              [-80.97907071777982, 35.677831277740431, 0.0],
              [-80.979266985114904, 35.677647878116836, 0.0],
              [-80.979321984946836, 35.676823411529483, 0.0],
              [-80.979574384570427, 35.676571278037265, 0.0],
              [-80.97954591853096, 35.676296411153672, 0.0],
              [-80.979349117579261, 35.676021811720659, 0.0],
              [-80.979236384581668, 35.675769877912387, 0.0],
              [-80.979348318055344, 35.67538041089594, 0.0],
              [-80.979600984454436, 35.675448878169249, 0.0],
              [-80.979994384938607, 35.675837877736193, 0.0],
              [-80.981566584417308, 35.676065611623201, 0.0],
              [-80.982632784643343, 35.675812678280451, 0.0],
              [-80.983867184229396, 35.675353411097142, 0.0],
              [-80.98529818452181, 35.675054478154728, 0.0],
              [-80.985691185338936, 35.675077078189751, 0.0],
              [-80.985383518597502, 35.675901678056398, 0.0],
              [-80.984288917711822, 35.675971478194263, 0.0],
              [-80.983475784254225, 35.6764990117846, 0.0],
              [-80.982774317893089, 35.676728677847784, 0.0],
              [-80.982353917865666, 35.677118411436318, 0.0],
              [-80.981539584146276, 35.676935877638385, 0.0],
              [-80.981034517561113, 35.677005010849165, 0.0],
              [-80.98081011858541, 35.677188477831344, 0.0],
              [-80.980726385075357, 35.677486411744283, 0.0],
              [-80.980782918655734, 35.677806877890973, 0.0],
              [-80.980951717758003, 35.678058677742982, 0.0],
              [-80.981008918518668, 35.678837211101168, 0.0],
              [-80.981289785158211, 35.679111811195952, 0.0],
              [-80.982441184572693, 35.679500211753982, 0.0],
              [-80.983368384954559, 35.680140610984452, 0.0],
              [-80.983537384931068, 35.680621277834859, 0.0],
              [-80.983958984300486, 35.680964611719666, 0.0],
              [-80.984295785012591, 35.681010010992118, 0.0],
              [-80.985530784165675, 35.680894277506845, 0.0],
              [-80.98572738414309, 35.680985678106715, 0.0],
              [-80.985671517660137, 35.681169011629528, 0.0],
              [-80.985306985243639, 35.681512877584396, 0.0],
              [-80.985392118103334, 35.682154077845567, 0.0],
              [-80.985645184189352, 35.682520277760176, 0.0],
              [-80.986516384677316, 35.683206478404045, 0.0],
              [-80.986770184732208, 35.684030811122916, 0.0],
              [-80.987191784314504, 35.684465611627623, 0.0],
              [-80.987473784620278, 35.685381277479543, 0.0],
              [-80.987530785140351, 35.685999678243938, 0.0],
              [-80.987671718087441, 35.686411678311636, 0.0],
              [-80.988346918494543, 35.68737287818351, 0.0],
              [-80.988682318115309, 35.688232477868858, 0.0],
              [-80.98871318477326, 35.688311677788207, 0.0],
              [-80.989472918587396, 35.689524811139279, 0.0],
              [-80.989838784227814, 35.690211411048502, 0.0],
              [-80.990907785141573, 35.691722011457941, 0.0],
              [-80.992960184184142, 35.693895678304166, 0.0],
              [-80.9933261849487, 35.694582411450028, 0.0],
              [-80.993298517482913, 35.694765611496244, 0.0],
              [-80.992737385094529, 35.695063811394512, 0.0],
              [-80.992765984344715, 35.695430411242988, 0.0],
              [-80.992850318569722, 35.695521877995212, 0.0],
              [-80.993945384376659, 35.695589477493613, 0.0],
              [-80.994450918234676, 35.695703477895741, 0.0],
              [-80.99403078425307, 35.696390877844436, 0.0],
              [-80.994089384192392, 35.698039877930348, 0.0],
              [-80.994652518102342, 35.699092810993236, 0.0],
              [-80.994709518569081, 35.699688211262561, 0.0],
              [-80.997323985190775, 35.701884077489552, 0.0],
              [-80.998026518222375, 35.702249811550672, 0.0],
              [-80.998588318439147, 35.702386678138296, 0.0],
              [-80.999571117489154, 35.702454277507073, 0.0],
              [-80.999799518093511, 35.702591611068669, 0.0],
              [-81.000588518122811, 35.703050411119108, 0.0],
              [-81.00120598509011, 35.703188477856941, 0.0],
              [-81.002413318472165, 35.703143877814078, 0.0],
              [-81.002721917832119, 35.703396078144628, 0.0],
              [-81.002861784494172, 35.703671077730235, 0.0],
              [-81.003310384230616, 35.704129478273273, 0.0],
              [-81.003787918203315, 35.703969677543192, 0.0],
              [-81.004769384301326, 35.704841010899528, 0.0],
              [-81.00541438434071, 35.705299611653373, 0.0],
              [-81.006985784769526, 35.706056878207768, 0.0],
              [-81.007799184564959, 35.706584411187713, 0.0],
              [-81.008276384933538, 35.70676807803526, 0.0],
              [-81.008753918205969, 35.706585278184832, 0.0],
              [-81.009006584244105, 35.706608477879364, 0.0],
              [-81.009118185163643, 35.707066677853575, 0.0],
              [-81.009875517446929, 35.70763987781578, 0.0],
              [-81.010465117462786, 35.707777877944466, 0.0],
              [-81.011138984450056, 35.707732678239623, 0.0],
              [-81.011756984213861, 35.707550078034458, 0.0],
              [-81.012121917471973, 35.707596211733637, 0.0],
              [-81.012373584341276, 35.708306410839718, 0.0],
              [-81.012710385100448, 35.708490011104672, 0.0],
              [-81.013524917983673, 35.708353277459594, 0.0],
              [-81.014086784571447, 35.708124811522154, 0.0],
              [-81.01447978409945, 35.708170878246044, 0.0],
              [-81.015349317699531, 35.708858811564625, 0.0],
              [-81.0160511175701, 35.709065477686686, 0.0],
              [-81.016500384199531, 35.708997211075271, 0.0],
              [-81.016978518518883, 35.70847101151184, 0.0],
              [-81.017316317899528, 35.707784210820606, 0.0],
              [-81.017484718555025, 35.707784410801203, 0.0],
              [-81.017681117632605, 35.707921877765727, 0.0],
              [-81.017539984366792, 35.708379811683749, 0.0],
              [-81.017567717555636, 35.708746277439396, 0.0],
              [-81.017735984214553, 35.708906677528596, 0.0],
              [-81.018325785255271, 35.708746877651379, 0.0],
              [-81.018971384746123, 35.708907877694216, 0.0],
              [-81.019251917614469, 35.709091277647076, 0.0],
              [-81.020178517656376, 35.709092011214977, 0.0],
              [-81.022032317689437, 35.708658611737008, 0.0],
              [-81.022762384129081, 35.708659211461409, 0.0],
              [-81.023492117483713, 35.709003277852325, 0.0],
              [-81.024053784589597, 35.708912211333988, 0.0],
              [-81.024250518431373, 35.708729010923136, 0.0],
              [-81.024251184764523, 35.708225211486457, 0.0],
              [-81.024054918169611, 35.707950211572765, 0.0],
              [-81.024083584899557, 35.707583811143977, 0.0],
              [-81.024392385117849, 35.707652811354059, 0.0],
              [-81.024784917817939, 35.708042411082786, 0.0],
              [-81.025374317641663, 35.708271877713983, 0.0],
              [-81.026216518208088, 35.708410078320767, 0.0],
              [-81.026889984150998, 35.708777011234318, 0.0],
              [-81.028461984660353, 35.709144677991453, 0.0],
              [-81.030371584307346, 35.709008811146184, 0.0],
              [-81.030905317585123, 35.708871811501744, 0.0],
              [-81.031270518406856, 35.708597278091645, 0.0],
              [-81.031551718593263, 35.708254011047778, 0.0],
              [-81.032058318597862, 35.707384011247903, 0.0],
              [-81.032311184229442, 35.707109278093995, 0.0],
              [-81.032535784983978, 35.707109477726675, 0.0],
              [-81.03253538526333, 35.707498878104303, 0.0],
              [-81.032253984504365, 35.708094211357164, 0.0],
              [-81.032253717986819, 35.708254477667452, 0.0],
              [-81.032337985161305, 35.708300410742893, 0.0],
              [-81.03281538423461, 35.708209077813613, 0.0],
              [-81.033321117708482, 35.707911811418455, 0.0],
              [-81.034809918422397, 35.70740901140497, 0.0],
              [-81.035203317915958, 35.70711161119192, 0.0],
              [-81.03587871770219, 35.705806611059728, 0.0],
              [-81.035879317921612, 35.705234078190109, 0.0],
              [-81.035991985197157, 35.704844810843611, 0.0],
              [-81.036272584781386, 35.704982477467141, 0.0],
              [-81.036412584186678, 35.705463477695204, 0.0],
              [-81.036355785090521, 35.706036011684368, 0.0],
              [-81.036102384666094, 35.706631277824705, 0.0],
              [-81.036101917680867, 35.707089278277969, 0.0],
              [-81.036186117459494, 35.707158078349707, 0.0],
              [-81.036831917836636, 35.707044011101921, 0.0],
              [-81.037899384853048, 35.706678277462899, 0.0],
              [-81.038124184823531, 35.706495277527537, 0.0],
              [-81.03955671757997, 35.70599247758107, 0.0],
              [-81.040146518371515, 35.705947011074556, 0.0],
              [-81.040230517384572, 35.70606161099164, 0.0],
              [-81.040146184280985, 35.706176077871007, 0.0],
              [-81.039387784529566, 35.70645047790061, 0.0],
              [-81.039387718227815, 35.706587877602047, 0.0],
              [-81.039808784967505, 35.706588077573386, 0.0],
              [-81.041802584639044, 35.706337477695854, 0.0],
              [-81.042111717608563, 35.70622321091178, 0.0],
              [-81.042083785236471, 35.705994077647354, 0.0],
              [-81.04183191742689, 35.705261077977553, 0.0],
              [-81.041776317926747, 35.704642810816594, 0.0],
              [-81.041636317775229, 35.704322011408131, 0.0],
              [-81.041636717863142, 35.703955611636573, 0.0],
              [-81.041889784587156, 35.703520611529683, 0.0],
              [-81.042114584875151, 35.703360478219537, 0.0],
              [-81.042254918236125, 35.703452211100611, 0.0],
              [-81.042618517448048, 35.704895211067175, 0.0],
              [-81.043179185004874, 35.705765878167249, 0.0],
              [-81.043150784252632, 35.706040677853267, 0.0],
              [-81.042841517635239, 35.706338211635412, 0.0],
              [-81.042813317964445, 35.706521411303932, 0.0],
              [-81.043206184610369, 35.706704811029049, 0.0],
              [-81.045648917815896, 35.706889677568796, 0.0],
              [-81.046182518030506, 35.706798411674072, 0.0],
              [-81.046322984149242, 35.706684010934815, 0.0],
              [-81.046604784046991, 35.705584877844984, 0.0],
              [-81.046633517487919, 35.704943811394536, 0.0],
              [-81.04697118515071, 35.704165078171435, 0.0],
              [-81.047308585209677, 35.703592811710436, 0.0],
              [-81.047421517422507, 35.703043211293966, 0.0],
              [-81.047589917387214, 35.702997478377867, 0.0],
              [-81.047870518236351, 35.703272611247883, 0.0],
              [-81.047870117522791, 35.703661878207285, 0.0],
              [-81.047419584741462, 35.705104611457038, 0.0],
              [-81.047278384818355, 35.705860211058372, 0.0],
              [-81.04733411783468, 35.706387011558036, 0.0],
              [-81.047530517656227, 35.706570277831318, 0.0],
              [-81.049046317764649, 35.707029277735835, 0.0],
              [-81.050309917740378, 35.706938477642083, 0.0],
              [-81.050450185137933, 35.70700721142687, 0.0],
              [-81.050899585173568, 35.706961677525612, 0.0],
              [-81.051405584359529, 35.706297878393961, 0.0],
              [-81.051602184149928, 35.706206410992365, 0.0],
              [-81.05182678417755, 35.70622941101724, 0.0],
              [-81.051629718044083, 35.706779011048354, 0.0],
              [-81.051657717400829, 35.706985077456871, 0.0],
              [-81.052078785219322, 35.707099877833251, 0.0],
              [-81.052106584303957, 35.707306011651617, 0.0],
              [-81.0512641845188, 35.70737421168041, 0.0],
              [-81.051039384950741, 35.707488610849062, 0.0],
              [-81.050983184525521, 35.707671677755719, 0.0],
              [-81.051291784886274, 35.707855077674523, 0.0],
              [-81.053902584706009, 35.708520877680307, 0.0],
              [-81.054548584143703, 35.708406678345305, 0.0],
              [-81.055082318104695, 35.708178011718232, 0.0],
              [-81.055335118116702, 35.707926211703629, 0.0],
              [-81.056205718450258, 35.707812210936389, 0.0],
              [-81.056626984976262, 35.707675011600031, 0.0],
              [-81.056907917456854, 35.707698077454715, 0.0],
              [-81.056963784644822, 35.707973011668365, 0.0],
              [-81.056486184105026, 35.708201677441387, 0.0],
              [-81.055194184470764, 35.708567411440171, 0.0],
              [-81.054997517552863, 35.708704678095955, 0.0],
              [-81.055053584868659, 35.708865011183569, 0.0],
              [-81.056850384328229, 35.709255411444197, 0.0],
              [-81.057833117595479, 35.709301677916883, 0.0],
              [-81.059489584063201, 35.709531678052905, 0.0],
              [-81.060332117745432, 35.709486277866787, 0.0],
              [-81.060669117445812, 35.709371877832325, 0.0],
              [-81.06092198517284, 35.70907441158851, 0.0],
              [-81.061203318271467, 35.708318678010365, 0.0],
              [-81.061371785173705, 35.708341610777083, 0.0],
              [-81.061540118019593, 35.708502077930213, 0.0],
              [-81.061595384685148, 35.709830478028287, 0.0],
              [-81.061707517453328, 35.710059477595337, 0.0],
              [-81.062128384358459, 35.710449077736023, 0.0],
              [-81.062998517636274, 35.710907611180971, 0.0],
              [-81.063307518243235, 35.710816211681113, 0.0],
              [-81.063700784055371, 35.710449877821333, 0.0],
              [-81.064178318025043, 35.710289877569046, 0.0],
              [-81.064487185035588, 35.710312877555296, 0.0],
              [-81.06468358482995, 35.710496211470158, 0.0],
              [-81.064627117838157, 35.71095421111842, 0.0],
              [-81.063756384018831, 35.711228611656992, 0.0],
              [-81.06333491749875, 35.711571878201724, 0.0],
              [-81.063334785218032, 35.711800878217282, 0.0],
              [-81.063867917731784, 35.712350811032977, 0.0],
              [-81.064457384190789, 35.712603078336251, 0.0],
              [-81.065327785199727, 35.712718011343711, 0.0],
              [-81.066310784559093, 35.712603877702485, 0.0],
              [-81.066816384412093, 35.712283611489326, 0.0],
              [-81.067181984222827, 35.711550878331252, 0.0],
              [-81.067659318317894, 35.711574078297829, 0.0],
              [-81.068473384855437, 35.711872077820288, 0.0],
              [-81.068866584413882, 35.711872277411636, 0.0],
              [-81.06954051817317, 35.71166647756101, 0.0],
              [-81.069877784439214, 35.711277277604218, 0.0],
              [-81.0701587174797, 35.711208677750101, 0.0],
              [-81.070467385122072, 35.711483677851348, 0.0],
              [-81.071000784788382, 35.711575611029289, 0.0],
              [-81.071000317607528, 35.712537410865615, 0.0],
              [-81.071336918296169, 35.71311007810553, 0.0],
              [-81.071870184029379, 35.713476811336122, 0.0],
              [-81.072291517732978, 35.7134082782581, 0.0],
              [-81.072459918200209, 35.713293811569407, 0.0],
              [-81.072796918043181, 35.713316877974229, 0.0],
              [-81.073021318194051, 35.713706277543764, 0.0],
              [-81.072852517715617, 35.714255811227794, 0.0],
              [-81.073020717638585, 35.714736811605505, 0.0],
              [-81.073413584050442, 35.715034811555888, 0.0],
              [-81.074452384332062, 35.715424610862932, 0.0],
              [-81.07551951807919, 35.715379211042681, 0.0],
              [-81.0767553842457, 35.71503607748317, 0.0],
              [-81.078103517588644, 35.714441211501118, 0.0],
              [-81.078974384574039, 35.713914811440837, 0.0],
              [-81.079564184210895, 35.713708877678677, 0.0],
              [-81.080222117825571, 35.713619677786362, 0.0],
              [-81.080238118440022, 35.713617478262663, 0.0],
              [-81.080687518281536, 35.713434411648215, 0.0],
              [-81.080097584134379, 35.71389227757885, 0.0],
              [-81.07900211807646, 35.714372878066392, 0.0],
              [-81.077288718366219, 35.715288211156739, 0.0],
              [-81.076474117756021, 35.715837611277188, 0.0],
              [-81.075827917733605, 35.716135010891804, 0.0],
              [-81.075069718448461, 35.716295078160989, 0.0],
              [-81.073777917905588, 35.716271677462935, 0.0],
              [-81.073581317419837, 35.716202878114331, 0.0],
              [-81.073188584083468, 35.715767611461352, 0.0],
              [-81.072851584111049, 35.715584211109075, 0.0],
              [-81.072402317794058, 35.715675611596581, 0.0],
              [-81.072289784004639, 35.715904611477178, 0.0],
              [-81.072261517474629, 35.716316811604329, 0.0],
              [-81.072120985050546, 35.716385477578399, 0.0],
              [-81.071166385049168, 35.71608727775741, 0.0],
              [-81.070941984240122, 35.715835278156234, 0.0],
              [-81.070942384445786, 35.715079477524597, 0.0],
              [-81.070324984031203, 35.714552410927283, 0.0],
              [-81.070212984085245, 35.714094411016099, 0.0],
              [-81.069595584948303, 35.713430011639339, 0.0],
              [-81.069118384557314, 35.713132011587149, 0.0],
              [-81.068388384231113, 35.713017210851504, 0.0],
              [-81.067658318144495, 35.713085611314526, 0.0],
              [-81.067377317907727, 35.713337277552633, 0.0],
              [-81.066983384567649, 35.714344877674691, 0.0],
              [-81.06670251797533, 35.714619610743597, 0.0],
              [-81.065943985075407, 35.714916877840665, 0.0],
              [-81.06574718508331, 35.715237477661297, 0.0],
              [-81.065886784817678, 35.716268077913604, 0.0],
              [-81.065970984405723, 35.716336811403949, 0.0],
              [-81.066166918011405, 35.7172988111192, 0.0],
              [-81.066473985192843, 35.719841078217307, 0.0],
              [-81.066894117344248, 35.721421611158469, 0.0],
              [-81.066444518483863, 35.721856611663256, 0.0],
              [-81.065882117336884, 35.722680677813862, 0.0],
              [-81.065656984438974, 35.723436411252408, 0.0],
              [-81.065656518038338, 35.723963078014151, 0.0],
              [-81.06590911742154, 35.724169410904544, 0.0],
              [-81.066133584447982, 35.724581611251828, 0.0],
              [-81.066498518249503, 35.724925411010759, 0.0],
              [-81.066470317508077, 35.725131610739318, 0.0],
              [-81.066189317899571, 35.7252688115368, 0.0],
              [-81.065964717409315, 35.725222877902603, 0.0],
              [-81.06565591839086, 35.725039611588215, 0.0],
              [-81.065459518320068, 35.724787610738588, 0.0],
              [-81.065234784657932, 35.724856077840272, 0.0],
              [-81.065122184139881, 35.725085077922579, 0.0],
              [-81.065121517652457, 35.726069877628241, 0.0],
              [-81.065008717756328, 35.726596611451953, 0.0],
              [-81.064699385110885, 35.727283477538428, 0.0],
              [-81.064670917570908, 35.727764410878699, 0.0],
              [-81.065372784706952, 35.728131211063726, 0.0],
              [-81.065625318332295, 35.728406078057404, 0.0],
              [-81.066804318120134, 35.72925407755487, 0.0],
              [-81.067506184497006, 35.729597877786226, 0.0],
              [-81.067618518071427, 35.729735477754659, 0.0],
              [-81.067674384177295, 35.730079011541726, 0.0],
              [-81.067112717679308, 35.730124477945573, 0.0],
              [-81.065119584292788, 35.728635011286677, 0.0],
              [-81.064782584885165, 35.728703477531106, 0.0],
              [-81.064670117989778, 35.728840878262361, 0.0],
              [-81.064697917624855, 35.729184410786722, 0.0],
              [-81.065258784311098, 35.730306877640587, 0.0],
              [-81.065988184761963, 35.731543877742588, 0.0],
              [-81.066521584676025, 35.73191061096361, 0.0],
              [-81.067841318261188, 35.732483678231858, 0.0],
              [-81.068627318298013, 35.733125277929524, 0.0],
              [-81.068626984658678, 35.733423077909904, 0.0],
              [-81.068430384270783, 35.733514677817396, 0.0],
              [-81.068065318305855, 35.733445811036539, 0.0],
              [-81.06736331793698, 35.733079077590055, 0.0],
              [-81.06685771801277, 35.733170411541792, 0.0],
              [-81.066773317845147, 35.733468077750295, 0.0],
              [-81.067165784129486, 35.734269811046268, 0.0],
              [-81.068737317388297, 35.736514878098546, 0.0],
              [-81.069074118470809, 35.736858478057286, 0.0],
              [-81.069074118546297, 35.736950211340165, 0.0],
              [-81.06935478460791, 35.737248210710916, 0.0],
              [-81.069579184638769, 35.737660410995005, 0.0],
              [-81.070701917561195, 35.739012078311326, 0.0],
              [-81.071965384466324, 35.739997477868535, 0.0],
              [-81.072161717956504, 35.740455611649892, 0.0],
              [-81.072582784624402, 35.74095961097612, 0.0],
              [-81.074407917358698, 35.742082611570403, 0.0],
              [-81.075615184014978, 35.743136611222717, 0.0],
              [-81.075952184937776, 35.743251211623935, 0.0],
              [-81.076795118070024, 35.743022478124821, 0.0],
              [-81.077132117844783, 35.743137078264631, 0.0],
              [-81.077244317772227, 35.743412077645466, 0.0],
              [-81.077216118268282, 35.743549478327644, 0.0],
              [-81.076429584140229, 35.743640810799008, 0.0],
              [-81.076429517596949, 35.743801077445937, 0.0],
              [-81.077103384229858, 35.744259278240371, 0.0],
              [-81.077777184226306, 35.744923811384027, 0.0],
              [-81.079911317381544, 35.746733811099659, 0.0],
              [-81.080584984724183, 35.74746687826012, 0.0],
              [-81.08128658397969, 35.748933011030076, 0.0],
              [-81.082184984274107, 35.749803477670326, 0.0],
              [-81.082493984274052, 35.750124210882497, 0.0],
              [-81.084514984780895, 35.751225278258119, 0.0],
              [-81.085498117367464, 35.751546211654905, 0.0],
              [-81.085947384375388, 35.752027277718184, 0.0],
              [-81.085975385115887, 35.752210610692678, 0.0],
              [-81.086480784616114, 35.752829078075777, 0.0],
              [-81.087519984526963, 35.75344781105639, 0.0],
              [-81.088446984183349, 35.753814410860592, 0.0],
              [-81.088615517858386, 35.753974810741283, 0.0],
              [-81.088699584475549, 35.75454727745749, 0.0],
              [-81.088952318194956, 35.754684811587801, 0.0],
              [-81.089823317436156, 35.754776677865657, 0.0],
              [-81.090890585130865, 35.755372411323812, 0.0],
              [-81.091030918381733, 35.755876411139269, 0.0],
              [-81.091171318098631, 35.75601387759113, 0.0],
              [-81.091873717292103, 35.756197277486734, 0.0],
              [-81.091957918440059, 35.756311810944737, 0.0],
            ],
            [
              [-81.067670318099601, 35.735918878349302, 0.0],
              [-81.067333384922421, 35.735666877888413, 0.0],
              [-81.067108717995083, 35.735758411393569, 0.0],
              [-81.067164518383223, 35.736193477901928, 0.0],
              [-81.067865718377803, 35.73754521092031, 0.0],
              [-81.068230584621759, 35.737980478126431, 0.0],
              [-81.068623717677468, 35.738186678270722, 0.0],
              [-81.068820517757189, 35.738095211143737, 0.0],
              [-81.068876784632494, 35.737866210939423, 0.0],
              [-81.068175117875654, 35.737110211596047, 0.0],
              [-81.068006983998259, 35.736468677408268, 0.0],
              [-81.067670318099601, 35.735918878349302, 0.0],
            ],
            [
              [-81.026832985128024, 35.709372410808349, 0.0],
              [-81.026889517632156, 35.709166277865265, 0.0],
              [-81.026805317767099, 35.709097477423008, 0.0],
              [-81.025401317646526, 35.709210877458048, 0.0],
              [-81.024642784882488, 35.709393610912407, 0.0],
              [-81.024417984524078, 35.709576610803509, 0.0],
              [-81.024389584569178, 35.709805611599215, 0.0],
              [-81.024950984986475, 35.709966410968939, 0.0],
              [-81.025737385036777, 35.709852478218281, 0.0],
              [-81.026692584143248, 35.709486811017712, 0.0],
              [-81.026832985128024, 35.709372410808349, 0.0],
            ],
            [
              [-81.023350718362224, 35.709736077519992, 0.0],
              [-81.023126117577434, 35.709735811483213, 0.0],
              [-81.022760718479887, 35.710033278356228, 0.0],
              [-81.02259118479931, 35.710903411484637, 0.0],
              [-81.022899984572106, 35.710949478128718, 0.0],
              [-81.024248318283725, 35.710561211357664, 0.0],
              [-81.02430458523645, 35.710378077961408, 0.0],
              [-81.023350718362224, 35.709736077519992, 0.0],
            ],
            [
              [-81.018913585122945, 35.710259011627052, 0.0],
              [-81.018576784354096, 35.71009841112415, 0.0],
              [-81.017959185153885, 35.709983278351181, 0.0],
              [-81.017790585146471, 35.710074811382711, 0.0],
              [-81.017930718320059, 35.710326810807935, 0.0],
              [-81.018295517888575, 35.710556211056726, 0.0],
              [-81.0187727852488, 35.710556477599539, 0.0],
              [-81.018997517802248, 35.710442278285029, 0.0],
              [-81.018913585122945, 35.710259011627052, 0.0],
            ],
            [
              [-81.020766984844244, 35.710077278090658, 0.0],
              [-81.021244318586355, 35.710169410984442, 0.0],
              [-81.021356718536396, 35.710123678072257, 0.0],
              [-81.021356917572433, 35.709871811443755, 0.0],
              [-81.020992184461022, 35.709619477699789, 0.0],
              [-81.020655318113626, 35.709619211500218, 0.0],
              [-81.020514917641748, 35.709710677791051, 0.0],
              [-81.020542717459548, 35.709871077537244, 0.0],
              [-81.020766984844244, 35.710077278090658, 0.0],
            ],
            [
              [-81.008721118334577, 35.709883277422065, 0.0],
              [-81.008440518433289, 35.709699811283429, 0.0],
              [-81.006475384267958, 35.709377211433122, 0.0],
              [-81.006082184399304, 35.70942267743083, 0.0],
              [-81.005857518142335, 35.709536878227759, 0.0],
              [-81.0058851844027, 35.709720211578166, 0.0],
              [-81.006221984329372, 35.709903810839002, 0.0],
              [-81.00843998499721, 35.710089078354166, 0.0],
              [-81.008692784335096, 35.709997811564158, 0.0],
              [-81.008721118334577, 35.709883277422065, 0.0],
            ],
            [
              [-81.005295984605752, 35.709444810796683, 0.0],
              [-81.005268784927637, 35.708895211090095, 0.0],
              [-81.005128517873928, 35.708780478018546, 0.0],
              [-81.004791518549368, 35.708780077414566, 0.0],
              [-81.004650985180803, 35.708871611214448, 0.0],
              [-81.00465058528944, 35.709192211652272, 0.0],
              [-81.005043117885464, 35.70955907809563, 0.0],
              [-81.005183517540701, 35.709559210847772, 0.0],
              [-81.005295984605752, 35.709444810796683, 0.0],
            ],
            [
              [-81.00380991856774, 35.708046277425275, 0.0],
              [-81.003108784885839, 35.707473077435694, 0.0],
              [-81.002715918516586, 35.707289411633035, 0.0],
              [-81.002406917761277, 35.707357877938627, 0.0],
              [-81.002406717861703, 35.707541077577879, 0.0],
              [-81.002546784942993, 35.707724411184458, 0.0],
              [-81.003332184116886, 35.708229011448168, 0.0],
              [-81.003697317845038, 35.708229477748723, 0.0],
              [-81.003809718267973, 35.708160810889112, 0.0],
              [-81.00380991856774, 35.708046277425275, 0.0],
            ],
            [
              [-80.999799518430265, 35.704813011604827, 0.0],
              [-80.99890011805789, 35.704310078050312, 0.0],
              [-80.998619517828033, 35.704424877635134, 0.0],
              [-80.99861998526292, 35.704745610866304, 0.0],
              [-80.99892951863994, 35.70518041157252, 0.0],
              [-80.999799518512901, 35.70575201080559, 0.0],
              [-81.000190718399054, 35.706073078214892, 0.0],
              [-81.000499384513546, 35.7061420777015, 0.0],
              [-81.000527917946499, 35.705913011071601, 0.0],
              [-81.00013498472029, 35.705798211571569, 0.0],
              [-81.000107317900742, 35.705546211725768, 0.0],
              [-81.000612917675952, 35.705432211256642, 0.0],
              [-81.000641517690553, 35.70506581101079, 0.0],
              [-81.000164585230692, 35.70481341140232, 0.0],
              [-80.999799518430265, 35.704813011604827, 0.0],
            ],
            [
              [-80.983824317976286, 35.685178611272363, 0.0],
              [-80.983655918519275, 35.685178811447948, 0.0],
              [-80.983628184421036, 35.685499477561251, 0.0],
              [-80.984274984442237, 35.686346211634643, 0.0],
              [-80.985174985068795, 35.687605011630303, 0.0],
              [-80.986102784477325, 35.688634678081961, 0.0],
              [-80.986552985290302, 35.689298478039525, 0.0],
              [-80.986806518566866, 35.689893811273897, 0.0],
              [-80.987004318170008, 35.690809677734393, 0.0],
              [-80.987369385314011, 35.69092387761706, 0.0],
              [-80.987678184518288, 35.690923477600315, 0.0],
              [-80.987846384368339, 35.690763011106085, 0.0],
              [-80.987817384411699, 35.690098877806257, 0.0],
              [-80.985510184532302, 35.686413811621108, 0.0],
              [-80.98534171856096, 35.686299477776899, 0.0],
              [-80.985172918073999, 35.686024810810913, 0.0],
              [-80.984835585290014, 35.685727411073394, 0.0],
              [-80.983824317976286, 35.685178611272363, 0.0],
            ],
            [
              [-80.977924184194691, 35.68117601134297, 0.0],
              [-80.977446517554128, 35.680787077685864, 0.0],
              [-80.976660118500448, 35.680444210950377, 0.0],
              [-80.974862184748019, 35.679232010866507, 0.0],
              [-80.974553517667886, 35.679323811093049, 0.0],
              [-80.974610118059616, 35.67966741130742, 0.0],
              [-80.975256318007354, 35.680170610852514, 0.0],
              [-80.97604278455421, 35.680559277514369, 0.0],
              [-80.976997917830872, 35.681176810832696, 0.0],
              [-80.977587584973392, 35.6813824781552, 0.0],
              [-80.977924384867379, 35.681359210888665, 0.0],
              [-80.977924184194691, 35.68117601134297, 0.0],
            ],
            [
              [-80.964600984389818, 35.66497201084708, 0.0],
              [-80.964123585057692, 35.664674677652918, 0.0],
              [-80.963562118042546, 35.664514811372435, 0.0],
              [-80.96277551867756, 35.66375961113139, 0.0],
              [-80.96266331803659, 35.663736677560848, 0.0],
              [-80.962129517943779, 35.663256211794035, 0.0],
              [-80.961792118072353, 35.662592277622601, 0.0],
              [-80.961398718191646, 35.662134611790478, 0.0],
              [-80.961201584896813, 35.661630811795888, 0.0],
              [-80.960358918117592, 35.660829878190825, 0.0],
              [-80.959881717739336, 35.660601211728128, 0.0],
              [-80.959685317810042, 35.660670010881127, 0.0],
              [-80.959685717686568, 35.661013478323383, 0.0],
              [-80.9604441850759, 35.66179167778477, 0.0],
              [-80.96058491865243, 35.662180877556395, 0.0],
              [-80.960501784433276, 35.663234478230059, 0.0],
              [-80.960670584331581, 35.663578011436314, 0.0],
              [-80.961260584320868, 35.664264610836554, 0.0],
              [-80.961429518149103, 35.664630878027786, 0.0],
              [-80.96201918499797, 35.665111411549383, 0.0],
              [-80.962693185248327, 35.665431611382822, 0.0],
              [-80.963254918540926, 35.665889211197545, 0.0],
              [-80.963985518024487, 35.666667278129154, 0.0],
              [-80.964350918319752, 35.667148011511003, 0.0],
              [-80.964547318555404, 35.667147877558477, 0.0],
              [-80.964771718089139, 35.667033078058587, 0.0],
              [-80.964855585070268, 35.666804077852618, 0.0],
              [-80.96482598531864, 35.66536121119983, 0.0],
              [-80.964600984389818, 35.66497201084708, 0.0],
            ],
            [
              [-80.96485098478577, 35.662658677922096, 0.0],
              [-80.965103584214248, 35.662658477632142, 0.0],
              [-80.965215784582398, 35.662566811026359, 0.0],
              [-80.965131185279247, 35.662269078164066, 0.0],
              [-80.964540917999813, 35.661376411537397, 0.0],
              [-80.964427918480581, 35.660689411638927, 0.0],
              [-80.964259317528715, 35.660437611449993, 0.0],
              [-80.96308011858406, 35.65993461099432, 0.0],
              [-80.962546984959232, 35.660049611575786, 0.0],
              [-80.962434917779262, 35.660187077696456, 0.0],
              [-80.962491318095417, 35.660484678381486, 0.0],
              [-80.962940784380038, 35.660988210953462, 0.0],
              [-80.96392398422303, 35.661789077978405, 0.0],
              [-80.964289317859738, 35.66222401087817, 0.0],
              [-80.96485098478577, 35.662658677922096, 0.0],
            ],
            [
              [-80.957573984992649, 35.654373411052106, 0.0],
              [-80.956843385185863, 35.653251678250122, 0.0],
              [-80.956618718065045, 35.653114411639429, 0.0],
              [-80.956141717929299, 35.653137677786837, 0.0],
              [-80.955889517792926, 35.65338981149916, 0.0],
              [-80.955890117575549, 35.654053878084675, 0.0],
              [-80.956536318429485, 35.654946610876436, 0.0],
              [-80.957013984326025, 35.655404411526348, 0.0],
              [-80.957743717955978, 35.65554127778163, 0.0],
              [-80.958080384329719, 35.655449478074104, 0.0],
              [-80.958079718157677, 35.654945611389898, 0.0],
              [-80.957630384813513, 35.654602411628879, 0.0],
              [-80.957573984992649, 35.654373411052106, 0.0],
            ],
            [
              [-80.941146117764802, 35.638924611074216, 0.0],
              [-80.941258118486459, 35.638741277876321, 0.0],
              [-80.94120158467021, 35.63830621128907, 0.0],
              [-80.940359517543754, 35.637711211421035, 0.0],
              [-80.940106984692932, 35.637619678216666, 0.0],
              [-80.939882584988013, 35.637688611044382, 0.0],
              [-80.939882717651997, 35.637871811677357, 0.0],
              [-80.94013538423485, 35.638169411521424, 0.0],
              [-80.940135717622738, 35.638444211285396, 0.0],
              [-80.939603117538383, 35.638994077674909, 0.0],
              [-80.939519184364698, 35.639292011580373, 0.0],
              [-80.939631584554931, 35.639498077672037, 0.0],
              [-80.939940184913667, 35.639543610855171, 0.0],
              [-80.940585317993126, 35.6393830109063, 0.0],
              [-80.941146117764802, 35.638924611074216, 0.0],
            ],
            [
              [-80.924299584481048, 35.617061011855263, 0.0],
              [-80.924551784801082, 35.61669441140053, 0.0],
              [-80.924663784346222, 35.616305077807354, 0.0],
              [-80.924916117917178, 35.61607587832453, 0.0],
              [-80.925673184270778, 35.615800811147473, 0.0],
              [-80.925757117776541, 35.615457077993497, 0.0],
              [-80.925448518645794, 35.615319877791869, 0.0],
              [-80.925139985434029, 35.615343011535764, 0.0],
              [-80.924018385344482, 35.615824277931779, 0.0],
              [-80.922728318548934, 35.615985211491363, 0.0],
              [-80.921915185101298, 35.616283411337335, 0.0],
              [-80.921410584837787, 35.616649877598967, 0.0],
              [-80.921214384692377, 35.616924877634148, 0.0],
              [-80.921186584777999, 35.617428678170803, 0.0],
              [-80.921803917962009, 35.617909411595235, 0.0],
              [-80.921775984799339, 35.618092611323327, 0.0],
              [-80.920822784445861, 35.618894678430081, 0.0],
              [-80.920710785380578, 35.619192411274597, 0.0],
              [-80.921412318198449, 35.619810477985524, 0.0],
              [-80.922141584931254, 35.61971861154062, 0.0],
              [-80.92259018515017, 35.619535211338629, 0.0],
              [-80.923066785101142, 35.619076877584881, 0.0],
              [-80.923767384885238, 35.618160478108464, 0.0],
              [-80.924131584661396, 35.617542011119333, 0.0],
              [-80.924299584481048, 35.617061011855263, 0.0],
            ],
            [
              [-80.945387784930318, 35.613156678442699, 0.0],
              [-80.94594831827041, 35.612629611381998, 0.0],
              [-80.946004117661317, 35.6122860110045, 0.0],
              [-80.945835785093465, 35.612217410982211, 0.0],
              [-80.94507851759343, 35.612172077881567, 0.0],
              [-80.944966184312491, 35.612011811185745, 0.0],
              [-80.944657717923192, 35.611989077694801, 0.0],
              [-80.944573584445266, 35.612080811200464, 0.0],
              [-80.944573784553072, 35.612401410958064, 0.0],
              [-80.944966785128557, 35.612744677715142, 0.0],
              [-80.944995118686961, 35.613042411456064, 0.0],
              [-80.945107384645368, 35.613156811528576, 0.0],
              [-80.945387784930318, 35.613156678442699, 0.0],
            ],
            [
              [-80.929327984719365, 35.587102010986037, 0.0],
              [-80.92918758531313, 35.58689601144107, 0.0],
              [-80.929159384298259, 35.586598277770733, 0.0],
              [-80.92957978518524, 35.586437811208526, 0.0],
              [-80.929635718108045, 35.586231610937901, 0.0],
              [-80.929972118260238, 35.586025277982053, 0.0],
              [-80.930112118735394, 35.585750411538235, 0.0],
              [-80.930448384727299, 35.585658611825515, 0.0],
              [-80.930644718242206, 35.585796011772004, 0.0],
              [-80.930757185188241, 35.586231078493057, 0.0],
              [-80.931037584341766, 35.586368277708701, 0.0],
              [-80.931206184609266, 35.586757478015159, 0.0],
              [-80.931374517922237, 35.586917811726657, 0.0],
              [-80.931682985043295, 35.586963477872573, 0.0],
              [-80.932075318503564, 35.586780011682571, 0.0],
              [-80.932215318087756, 35.58657387848622, 0.0],
              [-80.932327118205777, 35.586069877730374, 0.0],
              [-80.932719517860178, 35.585817877793673, 0.0],
              [-80.93283138426105, 35.585565878374481, 0.0],
              [-80.932775117893456, 35.585107811318316, 0.0],
              [-80.932578584370447, 35.584947611277855, 0.0],
              [-80.931120718709906, 35.584833811665185, 0.0],
              [-80.930531984468118, 35.584925678004872, 0.0],
              [-80.930083184498756, 35.584582411774107, 0.0],
              [-80.929858984418303, 35.584628277743981, 0.0],
              [-80.929298718255595, 35.585246878251567, 0.0],
              [-80.929046317921859, 35.585292811539531, 0.0],
              [-80.928233318165439, 35.585178611755161, 0.0],
              [-80.927924984551254, 35.58547661116917, 0.0],
              [-80.927953318627587, 35.585934678186305, 0.0],
              [-80.927673184671136, 35.586209611233201, 0.0],
              [-80.927673318020155, 35.586347011322374, 0.0],
              [-80.928009985167975, 35.586667477913579, 0.0],
              [-80.928038118074937, 35.586827811654182, 0.0],
              [-80.927561584587707, 35.587057011817791, 0.0],
              [-80.92747771780499, 35.587331877615242, 0.0],
              [-80.927225384351175, 35.587469411295771, 0.0],
              [-80.92705738454751, 35.587836011697149, 0.0],
              [-80.926384718391617, 35.588042411478064, 0.0],
              [-80.926384918225608, 35.588386011240203, 0.0],
              [-80.926581184742616, 35.588546078302322, 0.0],
              [-80.927057917932345, 35.588637611291318, 0.0],
              [-80.927506718367624, 35.588935077899414, 0.0],
              [-80.92829158455568, 35.588705811705026, 0.0],
              [-80.928291518533356, 35.588522477687818, 0.0],
              [-80.928179185158754, 35.588362211285407, 0.0],
              [-80.928291117670426, 35.587881211851716, 0.0],
              [-80.929019917936245, 35.587697611859163, 0.0],
              [-80.929327984719365, 35.587102010986037, 0.0],
            ],
            [
              [-80.934428784916335, 35.584557278103617, 0.0],
              [-80.934793318477546, 35.584602878417577, 0.0],
              [-80.935858518011997, 35.584487878378425, 0.0],
              [-80.935914384994319, 35.584235878012038, 0.0],
              [-80.935353317582596, 35.583801078309683, 0.0],
              [-80.93515698505135, 35.583549278467927, 0.0],
              [-80.934764585181028, 35.58380141105151, 0.0],
              [-80.934372584255343, 35.584328277735622, 0.0],
              [-80.934428784916335, 35.584557278103617, 0.0],
            ],
            [
              [-80.940333185007091, 35.536229078127349, 0.0],
              [-80.941201384596766, 35.536045411076664, 0.0],
              [-80.941172985087377, 35.535472878140574, 0.0],
              [-80.94100471824008, 35.535129478611665, 0.0],
              [-80.940724118511724, 35.534831877868591, 0.0],
              [-80.940415917856285, 35.534717478389801, 0.0],
              [-80.939827585354337, 35.53485527781887, 0.0],
              [-80.939603784611577, 35.535084478534252, 0.0],
              [-80.939548318393364, 35.535771478401422, 0.0],
              [-80.939884785219917, 35.536137811101412, 0.0],
              [-80.940333185007091, 35.536229078127349, 0.0],
            ],
            [
              [-80.948396984888575, 35.530063611121996, 0.0],
              [-80.947668385395957, 35.529857878335996, 0.0],
              [-80.947023985084115, 35.529949811758435, 0.0],
              [-80.94677211817627, 35.530179011797109, 0.0],
              [-80.946856585245541, 35.530637011230667, 0.0],
              [-80.947893918228672, 35.531438011561434, 0.0],
              [-80.948006117569861, 35.531598211322667, 0.0],
              [-80.948118718641169, 35.532170677822442, 0.0],
              [-80.94915638510939, 35.533292278084183, 0.0],
              [-80.951118718522991, 35.534436211230791, 0.0],
              [-80.952071184530453, 35.534321078039092, 0.0],
              [-80.952211118303211, 35.534000411867986, 0.0],
              [-80.951986717624308, 35.533702811128727, 0.0],
              [-80.951257984599692, 35.533474211608528, 0.0],
              [-80.951145717970505, 35.533291011469601, 0.0],
              [-80.950753185029569, 35.533108078036058, 0.0],
              [-80.950248918439286, 35.53299387802209, 0.0],
              [-80.950108585073991, 35.532856611572257, 0.0],
              [-80.950052384322603, 35.53262761130609, 0.0],
              [-80.949407585270535, 35.532215678044857, 0.0],
              [-80.949239318434422, 35.531986811879577, 0.0],
              [-80.949238584752152, 35.53127681136759, 0.0],
              [-80.949350518142168, 35.53107067849237, 0.0],
              [-80.949266117894197, 35.530635611907826, 0.0],
              [-80.949069718269783, 35.530383811820691, 0.0],
              [-80.948396984888575, 35.530063611121996, 0.0],
            ],
            [
              [-80.960889718152515, 35.5275128784508, 0.0],
              [-80.960469118608501, 35.527192478665363, 0.0],
              [-80.960301117744564, 35.527215611371105, 0.0],
              [-80.960049518236914, 35.527650878161126, 0.0],
              [-80.960049984530826, 35.528063078223383, 0.0],
              [-80.96038658493724, 35.528429278656844, 0.0],
              [-80.960526985212809, 35.528818478400531, 0.0],
              [-80.960835317554015, 35.528955811681634, 0.0],
              [-80.961059317666738, 35.528772411180384, 0.0],
              [-80.961086518197078, 35.528108211970128, 0.0],
              [-80.960889718152515, 35.5275128784508, 0.0],
            ],
            [
              [-80.970222984276788, 35.504236211722528, 0.0],
              [-80.97016578461357, 35.503297277764901, 0.0],
              [-80.970417385353656, 35.503045078218335, 0.0],
              [-80.970388185326485, 35.502220477994797, 0.0],
              [-80.970582917860568, 35.501373011898885, 0.0],
              [-80.970357985325151, 35.500800678482044, 0.0],
              [-80.970385718152031, 35.50014641181388, 0.0],
              [-80.970181717890483, 35.499321877752529, 0.0],
              [-80.969032584620763, 35.498452477949854, 0.0],
              [-80.969144385132608, 35.497879811969185, 0.0],
              [-80.968332185364929, 35.497514011875737, 0.0],
              [-80.968080118685947, 35.49703327837053, 0.0],
              [-80.96799631767972, 35.496552411217749, 0.0],
              [-80.967716118054383, 35.496323611713301, 0.0],
              [-80.967128117673866, 35.496072011276873, 0.0],
              [-80.967044117853192, 35.496415611880458, 0.0],
              [-80.966091784674489, 35.496485078685986, 0.0],
              [-80.965811717714161, 35.496599811362806, 0.0],
              [-80.965475717511765, 35.496897878111348, 0.0],
              [-80.965447785150616, 35.49712701144685, 0.0],
              [-80.966035785057187, 35.49717221149244, 0.0],
              [-80.966651917906773, 35.49708007832934, 0.0],
              [-80.967043984920352, 35.497194277924713, 0.0],
              [-80.967464185260923, 35.497858211169245, 0.0],
              [-80.968024317847849, 35.49817841159291, 0.0],
              [-80.968024518207855, 35.498430278591485, 0.0],
              [-80.967716717513952, 35.49863667871832, 0.0],
              [-80.967716985091087, 35.498934411824628, 0.0],
              [-80.968249785187254, 35.499369211337125, 0.0],
              [-80.968418118256622, 35.499689678618523, 0.0],
              [-80.968586317770502, 35.49989567807075, 0.0],
              [-80.968648984807118, 35.500146878196553, 0.0],
              [-80.968565317879907, 35.50034401160849, 0.0],
              [-80.968649984595061, 35.500779078676963, 0.0],
              [-80.968790318078078, 35.500985077870304, 0.0],
              [-80.96873478485341, 35.501328611241419, 0.0],
              [-80.968399518556538, 35.501878677947687, 0.0],
              [-80.968427985046347, 35.502153477802622, 0.0],
              [-80.968792384903338, 35.502473877851259, 0.0],
              [-80.96890618470394, 35.503893678485497, 0.0],
              [-80.969775184732441, 35.504603078512822, 0.0],
              [-80.970139317747751, 35.504488211211431, 0.0],
              [-80.970222984276788, 35.504236211722528, 0.0],
            ],
            [
              [-80.927385317905944, 35.490485077913803, 0.0],
              [-80.927944984239176, 35.489866611873012, 0.0],
              [-80.927944518531348, 35.489019211847435, 0.0],
              [-80.928028317745614, 35.488858811415867, 0.0],
              [-80.928027985144638, 35.488355011921286, 0.0],
              [-80.927915784955943, 35.488126011852259, 0.0],
              [-80.927607718397198, 35.487965811210096, 0.0],
              [-80.927075917685059, 35.488240878185536, 0.0],
              [-80.92634811829852, 35.488836612043805, 0.0],
              [-80.925424318301282, 35.489180611300455, 0.0],
              [-80.9250603852838, 35.489478411990426, 0.0],
              [-80.925060584563255, 35.489730411658712, 0.0],
              [-80.925761118519972, 35.490440011097412, 0.0],
              [-80.926097317608125, 35.490600212006029, 0.0],
              [-80.927161384764133, 35.490622678723803, 0.0],
              [-80.927385317905944, 35.490485077913803, 0.0],
            ],
            [
              [-80.922677918548516, 35.485700478612266, 0.0],
              [-80.922873784366558, 35.485425611191474, 0.0],
              [-80.922901584819073, 35.48510487836014, 0.0],
              [-80.922761584474159, 35.484944611394909, 0.0],
              [-80.922341385334192, 35.48473867812821, 0.0],
              [-80.92192098529992, 35.484166278438572, 0.0],
              [-80.921640784670146, 35.483937478283075, 0.0],
              [-80.920212585283238, 35.483640278546311, 0.0],
              [-80.91982058461744, 35.483640411284561, 0.0],
              [-80.919708785048925, 35.483732078538957, 0.0],
              [-80.919793185194649, 35.484579411551884, 0.0],
              [-80.919961184436787, 35.484625211861008, 0.0],
              [-80.920213384718735, 35.484899877863292, 0.0],
              [-80.920213717900694, 35.485380811619201, 0.0],
              [-80.920577718349719, 35.485563878277851, 0.0],
              [-80.921389785173403, 35.485449078328159, 0.0],
              [-80.921697917980239, 35.485586411278057, 0.0],
              [-80.922398384438779, 35.486364811578078, 0.0],
              [-80.922874518671321, 35.486433277810633, 0.0],
              [-80.922930318685772, 35.486181277811667, 0.0],
              [-80.922677918548516, 35.485700478612266, 0.0],
            ],
            [
              [-80.947217717671776, 35.466266611354264, 0.0],
              [-80.946741784816993, 35.466267011122937, 0.0],
              [-80.946434118064502, 35.46656487845987, 0.0],
              [-80.946520318581094, 35.468992478647621, 0.0],
              [-80.946604384569625, 35.469061078575315, 0.0],
              [-80.94817198444342, 35.468968477941679, 0.0],
              [-80.948955717796295, 35.468716078125638, 0.0],
              [-80.948871185205377, 35.468258078165533, 0.0],
              [-80.948479117704395, 35.467937811180498, 0.0],
              [-80.948478785341578, 35.467640011919471, 0.0],
              [-80.948926384235861, 35.467342011247062, 0.0],
              [-80.948982317897602, 35.467158678195339, 0.0],
              [-80.948394117871388, 35.466884278165928, 0.0],
              [-80.947917918361796, 35.466495211450443, 0.0],
              [-80.947469917832251, 35.466472611784759, 0.0],
              [-80.947217717671776, 35.466266611354264, 0.0],
            ],
            [
              [-80.930629784766126, 35.444334812138976, 0.0],
              [-80.930489785267724, 35.444289011984466, 0.0],
              [-80.930265917888946, 35.444380678582903, 0.0],
              [-80.929930384884059, 35.444953478307077, 0.0],
              [-80.929930785212051, 35.445457411224851, 0.0],
              [-80.93007091818555, 35.445663478650054, 0.0],
              [-80.930155318150014, 35.44628181117659, 0.0],
              [-80.930631185083783, 35.446487611837753, 0.0],
              [-80.931358984997871, 35.446624678472638, 0.0],
              [-80.932478384508215, 35.446509678756662, 0.0],
              [-80.932618185286458, 35.446372077993537, 0.0],
              [-80.93259011763864, 35.446120278694828, 0.0],
              [-80.932309985121407, 35.44577687836486, 0.0],
              [-80.931945984542466, 35.445570878366908, 0.0],
              [-80.930994184670709, 35.445296478511779, 0.0],
              [-80.930909784364857, 35.444655278720617, 0.0],
              [-80.930629784766126, 35.444334812138976, 0.0],
            ],
            [
              [-80.928080384428569, 35.440648611351214, 0.0],
              [-80.926484518744672, 35.439572878618975, 0.0],
              [-80.926232318130957, 35.439023278214542, 0.0],
              [-80.925868384467975, 35.438840277941452, 0.0],
              [-80.925308584414395, 35.438748878550562, 0.0],
              [-80.925028584403691, 35.438474211537461, 0.0],
              [-80.924944118598518, 35.43771841179332, 0.0],
              [-80.924636318024866, 35.437604011913969, 0.0],
              [-80.924384518118487, 35.437695678059129, 0.0],
              [-80.923992918049521, 35.43806241207578, 0.0],
              [-80.923741517940599, 35.438749611699876, 0.0],
              [-80.923769717643964, 35.439299212034214, 0.0],
              [-80.924301718054807, 35.439779878358905, 0.0],
              [-80.924581918085252, 35.44021501190722, 0.0],
              [-80.925141718260207, 35.440512477991874, 0.0],
              [-80.925226185347455, 35.441176611314155, 0.0],
              [-80.925814184362608, 35.441657278218102, 0.0],
              [-80.927045717713668, 35.441931678728231, 0.0],
              [-80.92724178458333, 35.442114812053497, 0.0],
              [-80.927493984801046, 35.442595611993326, 0.0],
              [-80.927857784236764, 35.442526811207827, 0.0],
              [-80.928109517980133, 35.442251811331843, 0.0],
              [-80.928332918184452, 35.44161041121049, 0.0],
              [-80.928304518655921, 35.440969212117828, 0.0],
              [-80.928080384428569, 35.440648611351214, 0.0],
            ],
            [
              [-80.921751185013093, 35.433070412038305, 0.0],
              [-80.921387318721756, 35.432772878775516, 0.0],
              [-80.921107384362742, 35.43286467823765, 0.0],
              [-80.920827918752877, 35.433277011345261, 0.0],
              [-80.920352318299791, 35.433529078385519, 0.0],
              [-80.920268384615667, 35.433712412098892, 0.0],
              [-80.920296584887097, 35.434010078684068, 0.0],
              [-80.920884517949162, 35.434376277885377, 0.0],
              [-80.921472117705505, 35.434261611809944, 0.0],
              [-80.921527584793424, 35.433643078681271, 0.0],
              [-80.921835318022971, 35.433322278727623, 0.0],
              [-80.921751185013093, 35.433070412038305, 0.0],
            ],
            [
              [-80.920447718262139, 35.453912611180058, 0.0],
              [-80.91994398442246, 35.454027212129802, 0.0],
              [-80.919888118398973, 35.454210478079304, 0.0],
              [-80.920672185088407, 35.454897278327003, 0.0],
              [-80.92070038525938, 35.455172077986276, 0.0],
              [-80.920784385314704, 35.45524081193696, 0.0],
              [-80.92081251872527, 35.455492677985575, 0.0],
              [-80.921316584603872, 35.455973411567243, 0.0],
              [-80.921596784438663, 35.456500078401319, 0.0],
              [-80.921765784905347, 35.457965811209739, 0.0],
              [-80.922410117899844, 35.458812811452212, 0.0],
              [-80.922689985324524, 35.458812678419889, 0.0],
              [-80.922745518467622, 35.45803407849364, 0.0],
              [-80.92224111873783, 35.457324212065139, 0.0],
              [-80.9222967176742, 35.456637211462436, 0.0],
              [-80.922183784477397, 35.454873678532621, 0.0],
              [-80.92198771804685, 35.454690611250449, 0.0],
              [-80.921371917713486, 35.454576278605948, 0.0],
              [-80.92123171848101, 35.454187011765704, 0.0],
              [-80.921063584989383, 35.454026811211847, 0.0],
              [-80.920447718262139, 35.453912611180058, 0.0],
            ],
            [
              [-80.897395385127709, 35.495418811624894, 0.0],
              [-80.897871184902783, 35.494731478661713, 0.0],
              [-80.897787185319999, 35.494525411366311, 0.0],
              [-80.897535117781601, 35.49427361164021, 0.0],
              [-80.897394918152614, 35.493953012065461, 0.0],
              [-80.897254984768693, 35.493998811591737, 0.0],
              [-80.896722984615351, 35.494571411589689, 0.0],
              [-80.896779118098181, 35.494892078461177, 0.0],
              [-80.897143384340694, 35.495487478634381, 0.0],
              [-80.897395385127709, 35.495418811624894, 0.0],
            ],
            [
              [-80.928290185139844, 35.50190367863879, 0.0],
              [-80.928738584592423, 35.502384478325922, 0.0],
              [-80.928850785113426, 35.50275087841495, 0.0],
              [-80.929411318731297, 35.503162811338854, 0.0],
              [-80.929607317666481, 35.503071078120421, 0.0],
              [-80.929466718240718, 35.502269678202119, 0.0],
              [-80.929354584295041, 35.502155211752722, 0.0],
              [-80.92924231838083, 35.501697078386272, 0.0],
              [-80.929046118265177, 35.501513878563131, 0.0],
              [-80.928373918154648, 35.501605878476482, 0.0],
              [-80.928261984479747, 35.501720478664701, 0.0],
              [-80.928290185139844, 35.50190367863879, 0.0],
            ],
            [
              [-80.930672385101701, 35.504215811764986, 0.0],
              [-80.930784785020165, 35.504811078342662, 0.0],
              [-80.931177184248185, 35.505131611240117, 0.0],
              [-80.93184951827746, 35.505360278244538, 0.0],
              [-80.932605584925568, 35.50519967819826, 0.0],
              [-80.932969517794731, 35.504947477860348, 0.0],
              [-80.933025318210653, 35.504649811429644, 0.0],
              [-80.93260498502103, 35.5043064779804, 0.0],
              [-80.932548784872893, 35.504077477973595, 0.0],
              [-80.932324718177298, 35.503894411250336, 0.0],
              [-80.93072798453683, 35.503666078340487, 0.0],
              [-80.930447984349811, 35.50375781130635, 0.0],
              [-80.930476185250242, 35.50403261125242, 0.0],
              [-80.930672385101701, 35.504215811764986, 0.0],
            ],
            [
              [-80.890174718584831, 35.513366611314297, 0.0],
              [-80.890006718440887, 35.513733078435592, 0.0],
              [-80.889950784498382, 35.514443078233668, 0.0],
              [-80.890146918537496, 35.5147408784376, 0.0],
              [-80.890455118761977, 35.514855211353776, 0.0],
              [-80.890707118107215, 35.514740678427302, 0.0],
              [-80.890763118438898, 35.514351278093265, 0.0],
              [-80.890650985462003, 35.514168211099992, 0.0],
              [-80.890678784996297, 35.513252011474243, 0.0],
              [-80.890566785162022, 35.513160477741913, 0.0],
              [-80.890454717789467, 35.513160478419607, 0.0],
              [-80.890174718584831, 35.513366611314297, 0.0],
            ],
            [
              [-80.88664771873475, 35.53026947844905, 0.0],
              [-80.887039984685643, 35.530429677918036, 0.0],
              [-80.887348184353144, 35.530292278197201, 0.0],
              [-80.887600185320593, 35.530017477897275, 0.0],
              [-80.887516118404065, 35.529765477827965, 0.0],
              [-80.887235918256238, 35.529467811446658, 0.0],
              [-80.886899784488989, 35.529284611452198, 0.0],
              [-80.886647585099155, 35.529284678196632, 0.0],
              [-80.886367384945331, 35.529513678461214, 0.0],
              [-80.886339384446018, 35.529903011825787, 0.0],
              [-80.88664771873475, 35.53026947844905, 0.0],
            ],
            [
              [-80.944746918317563, 35.520973211818877, 0.0],
              [-80.944466517817418, 35.520607011235974, 0.0],
              [-80.944270384716091, 35.52065287833782, 0.0],
              [-80.943990384620818, 35.520882078000419, 0.0],
              [-80.943850917817173, 35.521454811828185, 0.0],
              [-80.944271318018039, 35.521775211100511, 0.0],
              [-80.944439785079808, 35.522164411386676, 0.0],
              [-80.944999985117235, 35.522049611491944, 0.0],
              [-80.945447985100955, 35.521866011861817, 0.0],
              [-80.945475917625458, 35.521682811542497, 0.0],
              [-80.945335718475263, 35.521568411669598, 0.0],
              [-80.945223318349505, 35.52122487863511, 0.0],
              [-80.944746918317563, 35.520973211818877, 0.0],
            ],
            [
              [-80.937842584841675, 35.540261477975911, 0.0],
              [-80.938066917873059, 35.540330078280398, 0.0],
              [-80.938374984809229, 35.540284078532352, 0.0],
              [-80.938850917850274, 35.539688277772299, 0.0],
              [-80.938906718261222, 35.539436278445386, 0.0],
              [-80.938794384276463, 35.539047011601802, 0.0],
              [-80.938626184667513, 35.538978411520851, 0.0],
              [-80.938429985253734, 35.539001411005621, 0.0],
              [-80.938318117833546, 35.539116011829243, 0.0],
              [-80.938290185108571, 35.539390878241413, 0.0],
              [-80.937898384402132, 35.539963811045659, 0.0],
              [-80.937842584841675, 35.540261477975911, 0.0],
            ],
            [
              [-81.016511517621808, 35.721570611620763, 0.0],
              [-81.01673618420719, 35.721593677702081, 0.0],
              [-81.016932918127111, 35.721525077847183, 0.0],
              [-81.016961117592331, 35.721387677960045, 0.0],
              [-81.016792917829022, 35.721181411012878, 0.0],
              [-81.016371518561328, 35.721272677970646, 0.0],
              [-81.016343184093742, 35.72138721163968, 0.0],
              [-81.016511517621808, 35.721570611620763, 0.0],
            ],
          ],
        ],
      },
    },
  ],
};
