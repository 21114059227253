import {
  findHindcastEnd,
  findHindcastStart,
  findPreMediumForecastEnd,
  findPostShortTermStart,
  findPostShortTermEnd,
  thresholdVisible,
  findThresholdValue,
  calculateMedPrecipBarShapePositions,
} from "./PlotHelpers";

export const createShapesMain = (
  plotableTS,
  timeseries,
  thresholds,
  selectedBasin
) => {
  const shapes = [
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0,
      x1: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 0.4,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.75,
      x1: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 0.95,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "before_medium_range_forcast",
      layer: "above",
      type: "rect",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.47,
      x1: findPreMediumForecastEnd(plotableTS, "PreprocessGFS"),
      y1: 0.67,
      fillcolor: "rgb(96, 96, 96)",
      opacity: 1,
      line: {
        width: 0,
        color: "black",
      },
    },
    {
      wfpType: "beyond_short_term_forcast",
      layer: "above",
      type: "rect",
      xref: "x",
      yref: "paper",
      x0: findPostShortTermStart(timeseries, "PreprocessHRRR"),
      y0: 0.75,
      x1: findPostShortTermEnd(timeseries, "PreprocessHRRR"),
      y1: 0.95,
      fillcolor: "rgb(96, 96, 96)",
      opacity: 1,
      line: {
        width: 0,
        color: "black",
      },
    },

    {
      wfpType: "threshold",
      visible: thresholdVisible(thresholds, selectedBasin),
      type: "line",
      xref: "paper",
      yref: "y1",
      x0: 0,
      y0: findThresholdValue(timeseries),
      x1: 1,
      y1: findThresholdValue(timeseries),
      line: {
        color: "black",
        width: 3,
      },
    },
    {
      wfpType: "accumulation_line",
      aggregation_name: "MRPB",
      visible: true,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: findPreMediumForecastEnd(plotableTS, "PreprocessGFS"),
      y0: 0.68,
      x1: findPreMediumForecastEnd(plotableTS, "PreprocessGFS"),
      y1: 0.72,
      line: {
        color: "rgb(33, 97, 140)",
        width: 1,
      },
    },
    {
      wfpType: "accumulation_line",
      aggregation_name: "SRPB",
      visible: true,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.96,
      x1: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 1.0,
      line: {
        color: "rgb(80, 80, 80)",
        width: 1,
      },
    },
    {
      wfpType: "accumulation_line",
      aggregation_name: "HPB",
      visible: true,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: findPostShortTermStart(timeseries, "PreprocessHRRR"),
      y0: 0.96,
      x1: findPostShortTermStart(timeseries, "PreprocessHRRR"),
      y1: 1.0,
      line: {
        color: "rgb(80, 80, 80)",
        width: 1,
      },
    },
    {
      wfpType: "accumulation_line",
      aggregation_name: "HPB10",
      visible: true,
      type: "line",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.96,
      x1: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 1.0,
      line: {
        color: "rgb(80, 80, 80)",
        width: 1,
      },
    },
  ];

  const testTs = timeseries.find((ts) => {
    return (
      !ts.header.qualifierId && ts.header.moduleInstanceId === "PreprocessGFS"
    );
  });
  const cumulMedPptLines = calculateMedPrecipBarShapePositions(testTs);

  if (cumulMedPptLines) {
    return shapes.concat(cumulMedPptLines);
  } else {
    return [];
  }
};

export const createShapesThumbnail = (plotableTS, timeseries) => {
  const shapes = [
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0,
      x1: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 0.4,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "hindcast",
      type: "rect",
      layer: "below",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.75,
      x1: findHindcastEnd(plotableTS, "Preprocess_NWMAnalysis"),
      y1: 0.95,
      fillcolor: "grey",
      opacity: 0.2,
      line: {
        width: 0,
      },
    },
    {
      wfpType: "before_medium_range_forcast",
      layer: "above",
      type: "rect",
      xref: "x",
      yref: "paper",
      x0: findHindcastStart(plotableTS, "Preprocess_NWMAnalysis"),
      y0: 0.47,
      x1: findPreMediumForecastEnd(plotableTS, "PreprocessGFS"),
      y1: 0.67,
      fillcolor: "rgb(96, 96, 96)",
      opacity: 1,
      line: {
        width: 0,
        color: "black",
      },
    },
    {
      wfpType: "beyond_short_term_forcast",
      layer: "above",
      type: "rect",
      xref: "x",
      yref: "paper",
      x0: findPostShortTermStart(timeseries, "PreprocessHRRR"),
      y0: 0.75,
      x1: findPostShortTermEnd(timeseries, "PreprocessHRRR"),
      y1: 0.95,
      fillcolor: "rgb(96, 96, 96)",
      opacity: 1,
      line: {
        width: 0,
        color: "black",
      },
    },
  ];

  if (shapes) {
    return shapes;
  } else {
    return [];
  }
};
