import { AppBar, Toolbar, Typography, styled, IconButton, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../Layouts/ProjectLayout.css";
import { PlotLegendCustom } from "./PlotLegendCustom";
// import { DetailsCustom } from "./DetailsCustom";
// import { getT0 } from "../../Utils/customHelpers";

const MyAppBar = styled(AppBar)({
  background: "#d5e3f2",
  maxHeight: "40px",
  marginTop: "0px",
});

const MyDiv = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  alignItems: "center",
});

const EvaluateDiv = styled("div")({
  display: "flex",
  flexDirection: "row-reverse",
  justifyContent: "flex-end",
  alignItems: "center",
});

const navButton = (evaluate) => {
  let navStatement;
  switch (evaluate) {
    case "EVALUATE":
      navStatement = "Back to Inflow Forecasts";
      break;
    case null:
      navStatement = "Evaluate Forecasts";
      break;
  }
  return navStatement;
};


export const WFPToolbarCustom = ({
  handleBackToSummaryPg,
  timeOfRefresh,
  goToEvaluateForecasts,
  goToInflowForecasts,
  evaluate
}) => {
  // console.log("WFP Toolbar Custom evaluate:", evaluate);
  
  return (
    <MyAppBar position="static">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <IconButton
          sx={{ color: "black" }}
          onClick={handleBackToSummaryPg}
          size="small"
        >
          <ArrowBackIcon /> My Forecast Points
        </IconButton>

        
        {evaluate && (
          <EvaluateDiv>
            {/* <IconButton
              sx={{ color: "black" }}
              onClick={goToInflowForecasts} 
              size="small"
            >
              {navButton(evaluate)}
            </IconButton> */}
            <Button variant="outlined"
              // sx={{ color: "black" }}
              onClick={goToInflowForecasts} 
              size="small"
            >
              {navButton(evaluate)}
            </Button>
          </EvaluateDiv>
        )}
        {!evaluate && (
          <EvaluateDiv>
            <Button variant="contained"
              onClick={goToEvaluateForecasts} 
              size="small"
  
            >
              {navButton(evaluate)}
            </Button>
            {/* <IconButton
              sx={{ color: "black" }}
              onClick={goToEvaluateForecasts} 
              size="small"
  
            >
              {navButton(evaluate)}
            </IconButton> */}
          </EvaluateDiv>
        )}


        <MyDiv>
          {/* <DetailsCustom t0={Date.now()} /> */}
          <Typography sx={{ m: 2, color: "black" }}>
            Last Updated: {timeOfRefresh.toLocaleString()}
          </Typography>
          {/* <Typography sx={{ m: 2, color: "darkgrey" }}>Download </Typography> */}
          <PlotLegendCustom />
        </MyDiv>
      </Toolbar>
    </MyAppBar>
  );
};