import { useState } from "react";
import { Typography, Box, Button, Modal, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import "../Layouts/ProjectLayout.css";
import legend from "../../Images/wfp-legend.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
};

export const PlotLegend = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button size="small" sx={{ m: 2, color: "black" }} onClick={handleOpen}>
        <LegendToggleIcon />
        Plot Legend
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="Plot-legend-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar sx={{ background: "#496D99" }}>
            <Toolbar
              sx={{ background: "#496D99", justifyContent: "space-between" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Plot Legend
              </Typography>
              <Button
                size="large"
                sx={{ m: 1, color: "white" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>

          <img
            src={legend}
            alt="Legend: looks like this..."
            style={{ marginTop: "15%" }}
          />
        </Box>
      </Modal>
    </div>
  );
};
