export const basinsCatawba = {
  type: "FeatureCollection",
  name: "Catawba_Basins",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        reservoir_: 31,
        ORIG_FID: 0,
        AAPC_Examp: 2,
        Area_mile2: 69,
        BASIN_NAME: "Mountain Island Lake",
        Res_Basin: "MountainIsland",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.870825912534315, 35.48405922457433],
              [-80.868191431025949, 35.483719301855139],
              [-80.86813854796776, 35.483983920490282],
              [-80.867150620488033, 35.483856433613781],
              [-80.867203506682884, 35.483591815401141],
              [-80.866215585442248, 35.483464320901653],
              [-80.866162696110763, 35.483728938691804],
              [-80.86550408121569, 35.483643937613181],
              [-80.865556973719436, 35.48337931918654],
              [-80.865227667283591, 35.483336817455694],
              [-80.865174774836206, 35.483601435724459],
              [-80.862869638690057, 35.483303895688444],
              [-80.86292253845609, 35.48303927840562],
              [-80.860617427605135, 35.482741694635784],
              [-80.860670335467333, 35.482477079183909],
              [-80.860341036330254, 35.482434563267134],
              [-80.860288127422578, 35.482699178578102],
              [-80.858641632825879, 35.482486586666617],
              [-80.858800375277397, 35.48169274181037],
              [-80.858471080300433, 35.481650220811993],
              [-80.858576909328306, 35.481120990685646],
              [-80.858247616787096, 35.481078469075278],
              [-80.858353446505632, 35.480549238171271],
              [-80.858024157501973, 35.480506715931931],
              [-80.858129986850557, 35.4799774860696],
              [-80.857800700282581, 35.479934963218298],
              [-80.85785361547461, 35.479670347918102],
              [-80.857524330296911, 35.479627824313809],
              [-80.857577247291275, 35.479363209098182],
              [-80.857247962401999, 35.479320684757838],
              [-80.857300880096844, 35.479056069643526],
              [-80.856313033939927, 35.478928491631791],
              [-80.856365954425911, 35.478663876900761],
              [-80.856036674108807, 35.478621349250147],
              [-80.856089594193392, 35.478356734637366],
              [-80.855760316368361, 35.478314206216922],
              [-80.855813237153356, 35.478049591705584],
              [-80.855483960718558, 35.478007062532321],
              [-80.855536882203964, 35.477742448122413],
              [-80.855207607138723, 35.477699917295041],
              [-80.855313452267296, 35.477170688621797],
              [-80.854984179658175, 35.477128158083694],
              [-80.855037103280722, 35.476863542919055],
              [-80.854707830960095, 35.476821011645008],
              [-80.854760755303403, 35.476556397483101],
              [-80.854431485454199, 35.476513864538028],
              [-80.854484410497946, 35.476249250477593],
              [-80.854155140957744, 35.476206717697863],
              [-80.854208066681238, 35.47594210283772],
              [-80.853878799633009, 35.475899569288224],
              [-80.853931726056828, 35.475634954529504],
              [-80.85360246039879, 35.475592420227194],
              [-80.85370831432266, 35.475063191774609],
              [-80.853379051079472, 35.475020655959263],
              [-80.853431978558916, 35.474756041363996],
              [-80.853102716726383, 35.474713505697125],
              [-80.853208572740996, 35.474184276670435],
              [-80.852879314445673, 35.474141740374819],
              [-80.852932242980728, 35.473877125943254],
              [-80.852602984973686, 35.473834588911757],
              [-80.852708843099421, 35.473305360212279],
              [-80.852379588608883, 35.473262821650899],
              [-80.852485447445076, 35.472733593075702],
              [-80.852156195389938, 35.472691053902537],
              [-80.852262054936645, 35.472161825451671],
              [-80.851932805337299, 35.472119286567988],
              [-80.851985735617816, 35.471854671523182],
              [-80.851656487408562, 35.471812131886743],
              [-80.851709418389277, 35.471547516943531],
              [-80.851380171570085, 35.471504976554293],
              [-80.851433103250955, 35.471240361712681],
              [-80.85077461271716, 35.471155277633812],
              [-80.850721678945959, 35.471419892193445],
              [-80.850392433141124, 35.471377348221829],
              [-80.850445367957334, 35.471112733803224],
              [-80.85011612356314, 35.471070189980075],
              [-80.850063187681272, 35.471334803356399],
              [-80.849733942586838, 35.471292258498622],
              [-80.849786880595005, 35.471027644345227],
              [-80.849128393510981, 35.470942551329159],
              [-80.849234271591499, 35.470413324386413],
              [-80.8489050317486, 35.470370776352368],
              [-80.849116787929418, 35.469312321657213],
              [-80.848787552611768, 35.46926977329354],
              [-80.849052245846423, 35.467946704510794],
              [-80.848723016098887, 35.467904155958564],
              [-80.848775954090755, 35.467639542054854],
              [-80.848446725733211, 35.467596992750039],
              [-80.848552603873685, 35.467067765089972],
              [-80.848223379052783, 35.467025215156731],
              [-80.848276318099693, 35.466760601417171],
              [-80.847947093567086, 35.466718050748113],
              [-80.848000034395127, 35.466453436192225],
              [-80.847670811252485, 35.466410884770532],
              [-80.847829634842569, 35.465617043092834],
              [-80.847500415179695, 35.465574491200613],
              [-80.847606297931264, 35.465045263200771],
              [-80.847277080703336, 35.465002710696965],
              [-80.847330022596267, 35.464738096328574],
              [-80.8470008078598, 35.464695543055434],
              [-80.847053750473151, 35.464430929690074],
              [-80.846724536025036, 35.464388375681111],
              [-80.846777479317794, 35.464123761516298],
              [-80.846448267361211, 35.464081206737895],
              [-80.846501211353868, 35.463816592674895],
              [-80.846172000787092, 35.463774037144013],
              [-80.846277889827249, 35.463244809182413],
              [-80.845948681695219, 35.463202253040045],
              [-80.846054571445109, 35.462673025203728],
              [-80.845725365747811, 35.462630468449767],
              [-80.845778311150141, 35.462365854613815],
              [-80.845449106842693, 35.462323297107375],
              [-80.845502052944767, 35.462058683373307],
              [-80.845172851108401, 35.462016124196317],
              [-80.845225797910288, 35.461751510564092],
              [-80.844896596382625, 35.461708951552524],
              [-80.844949544985937, 35.461444338005485],
              [-80.844620344827533, 35.461401777340157],
              [-80.844726240885507, 35.460872550444094],
              [-80.844397044263431, 35.460829989150582],
              [-80.844449992819676, 35.460565375784817],
              [-80.844120797607872, 35.460522814640143],
              [-80.844173746843438, 35.460258200474996],
              [-80.843844553021427, 35.460215638577822],
              [-80.84389750295675, 35.459951024514623],
              [-80.843239118416818, 35.459865897420407],
              [-80.843292071218755, 35.459601284484719],
              [-80.842304500120719, 35.459473587114438],
              [-80.842463363614655, 35.458679747573257],
              [-80.842134178188076, 35.458637181003482],
              [-80.842398949333813, 35.457314113516844],
              [-80.842069769475899, 35.457271546758932],
              [-80.842228630936575, 35.456477706224263],
              [-80.84189945453717, 35.456435138094882],
              [-80.842058316006174, 35.455641296730448],
              [-80.841729143106193, 35.455598729032225],
              [-80.841888005705371, 35.454804887722744],
              [-80.841558836263872, 35.454762318653017],
              [-80.841770651662856, 35.453703863001707],
              [-80.841441486744799, 35.453661293602963],
              [-80.841494441317082, 35.453396679274668],
              [-80.841823604089058, 35.453439248549067],
              [-80.842035412567185, 35.452380791215994],
              [-80.842364571505783, 35.452423359032444],
              [-80.842470471575595, 35.451894129399079],
              [-80.842141313624523, 35.451851561881561],
              [-80.842300163724573, 35.451057717570691],
              [-80.842629317420119, 35.451100283780427],
              [-80.842682265351812, 35.450835668796657],
              [-80.843011418368164, 35.450878234873123],
              [-80.843329093507649, 35.449290541486782],
              [-80.843658239500471, 35.449333105839841],
              [-80.843764125970608, 35.448803872921218],
              [-80.843434982087217, 35.448761309751582],
              [-80.84369969636775, 35.447438228397743],
              [-80.843370558031012, 35.447395664138938],
              [-80.843635269991552, 35.446072581600333],
              [-80.843964402004673, 35.446115145170353],
              [-80.844123221888324, 35.445321293856402],
              [-80.844452351113247, 35.445363856109758],
              [-80.844558227215586, 35.44483462112359],
              [-80.844887354696539, 35.444877182201388],
              [-80.845046162018818, 35.444083328573747],
              [-80.845099096382555, 35.443818711232311],
              [-80.844769973079082, 35.443776150718811],
              [-80.844822908121742, 35.443511532578299],
              [-80.844493786227972, 35.4434689722139],
              [-80.844599658468951, 35.442939736081925],
              [-80.844270538988241, 35.4428971742051],
              [-80.844376411958891, 35.44236793910148],
              [-80.84404729491169, 35.442325376613489],
              [-80.844153169672353, 35.441796140719021],
              [-80.843824055058647, 35.441753577619835],
              [-80.843876991864647, 35.441488959772272],
              [-80.843547879741337, 35.441446395904194],
              [-80.843653755508726, 35.440917160358353],
              [-80.843324644717512, 35.440874595895856],
              [-80.843377583658338, 35.440609977296305],
              [-80.843048475357691, 35.440567412064873],
              [-80.84310141391704, 35.440302794485646],
              [-80.842772307005518, 35.440260228502076],
              [-80.842931125929226, 35.4394663750207],
              [-80.8426020224952, 35.439423808567177],
              [-80.842654962844392, 35.43915919019728],
              [-80.842325859698263, 35.439116623008282],
              [-80.842378800767236, 35.438852005642062],
              [-80.842049700111488, 35.43880943768422],
              [-80.842102641859356, 35.438544819519137],
              [-80.840786246841574, 35.438374538418159],
              [-80.840733300916824, 35.438639156018624],
              [-80.839746005137314, 35.438511435164706],
              [-80.839798954194549, 35.438246817987618],
              [-80.839469857342237, 35.438204243024607],
              [-80.839522808199987, 35.437939625933311],
              [-80.839193712736801, 35.437897050218211],
              [-80.839511412519954, 35.436309348672843],
              [-80.839182324747398, 35.436266771993523],
              [-80.839394121500163, 35.435208303492693],
              [-80.839447069550744, 35.434943686275119],
              [-80.840105234958941, 35.435028835526573],
              [-80.840158180576452, 35.434764217987976],
              [-80.840487262774261, 35.43480679203401],
              [-80.840540208083596, 35.434542173397688],
              [-80.841198371405071, 35.43462731762645],
              [-80.841251313200544, 35.434362699587197],
              [-80.841580394324396, 35.434405269769996],
              [-80.841633333629687, 35.434140651567688],
              [-80.841962414054279, 35.434183220716136],
              [-80.842015353051423, 35.433918601416103],
              [-80.84267351286725, 35.434003737652233],
              [-80.843149925167126, 35.431622161545185],
              [-80.842820854493567, 35.431579595594577],
              [-80.843032588241712, 35.430521116089423],
              [-80.842703523169746, 35.430478548892069],
              [-80.842756457339377, 35.430213929052286],
              [-80.842862322441903, 35.429684689290497],
              [-80.842533260846693, 35.429642121623431],
              [-80.842639127738735, 35.429112881071951],
              [-80.842310069697845, 35.429070313678466],
              [-80.842627667241175, 35.427482591062713],
              [-80.842298615808701, 35.427440023622935],
              [-80.842457413037138, 35.426646161317926],
              [-80.842128365081066, 35.426603593408494],
              [-80.842340093700784, 35.425545110367153],
              [-80.842011050244651, 35.425502541227878],
              [-80.842116915124137, 35.424973299750576],
              [-80.84178787410039, 35.424930730000504],
              [-80.84194667249561, 35.424136867010418],
              [-80.841617634948136, 35.424094296790699],
              [-80.841723500534869, 35.423565054592849],
              [-80.841394465440345, 35.423522484663543],
              [-80.841500331735645, 35.422993242594501],
              [-80.84117130015413, 35.422950671136412],
              [-80.841277167157941, 35.422421429196241],
              [-80.84094813800867, 35.422378857127462],
              [-80.841106938478916, 35.421584993009404],
              [-80.840777912826127, 35.421542421372301],
              [-80.840830847404547, 35.42127780005297],
              [-80.840501823119851, 35.421235226762718],
              [-80.840660625715344, 35.420441363031756],
              [-80.840331606007751, 35.420398789255238],
              [-80.84038454019344, 35.420134168081667],
              [-80.840055521874319, 35.420091593553209],
              [-80.840161392379358, 35.419562350455664],
              [-80.839832375391367, 35.419519775333214],
              [-80.839885311731209, 35.419255154310392],
              [-80.839556296131704, 35.419212578436152],
              [-80.83966216984399, 35.418683335656802],
              [-80.839333156676616, 35.418640759171829],
              [-80.839491967800512, 35.417846895589896],
              [-80.839820976735908, 35.417889471668026],
              [-80.839926845879276, 35.417360227621366],
              [-80.840255853072122, 35.417402802524158],
              [-80.8403087855325, 35.417138180310658],
              [-80.840637792026456, 35.417180754179235],
              [-80.840690724219968, 35.41691613267087],
              [-80.841019729994599, 35.416958704604014],
              [-80.841125589037588, 35.416429460322405],
              [-80.841454593069756, 35.416472031080197],
              [-80.841666303904901, 35.41541353967451],
              [-80.84199530412765, 35.41545610987594],
              [-80.842418698641211, 35.413339121431918],
              [-80.842089707867956, 35.41329655234307],
              [-80.842142631682336, 35.413031929094934],
              [-80.841813642297367, 35.412989359254368],
              [-80.841972414762282, 35.412195487902117],
              [-80.841643428852493, 35.412152917592039],
              [-80.841855124794009, 35.411094421285014],
              [-80.841526143402987, 35.411051850646743],
              [-80.841579067570109, 35.410787226615838],
              [-80.84163199249366, 35.410522602530186],
              [-80.841303013534173, 35.410480031281274],
              [-80.841408862211821, 35.409950782410156],
              [-80.841079886784911, 35.409908210534013],
              [-80.84113281166043, 35.409643586633116],
              [-80.840803837621635, 35.409601014005247],
              [-80.84085676319593, 35.409336390207436],
              [-80.840527790545138, 35.409293816827848],
              [-80.840633642726289, 35.408764568499144],
              [-80.840304672507017, 35.408721994509008],
              [-80.840357599113773, 35.408457369978123],
              [-80.840028630282674, 35.408414795236276],
              [-80.840134484569418, 35.407885547244085],
              [-80.839805519270584, 35.407842971874956],
              [-80.840017228962211, 35.406784475080926],
              [-80.839688267060268, 35.406741898499114],
              [-80.839847049120493, 35.40594802614919],
              [-80.839518090693275, 35.405905449098086],
              [-80.83957101802693, 35.405640824751735],
              [-80.839242062109065, 35.405598247833623],
              [-80.839294990141397, 35.405333623590558],
              [-80.838966035591014, 35.405291045019467],
              [-80.839018965422923, 35.405026420862825],
              [-80.838690011180105, 35.40498384245813],
              [-80.839060513190958, 35.403131470650216],
              [-80.83873156769674, 35.40308889232444],
              [-80.838837423432011, 35.402559642161563],
              [-80.838508481469646, 35.402517063208684],
              [-80.838667266738483, 35.401723188988207],
              [-80.838338327129392, 35.401680608681602],
              [-80.838550040875703, 35.40062210854235],
              [-80.838221106905266, 35.400579528792299],
              [-80.838432818188949, 35.399521027727154],
              [-80.838103888715494, 35.399478446747942],
              [-80.838262671959185, 35.398684570745701],
              [-80.837933745980123, 35.398641990198634],
              [-80.837986673701394, 35.398377364334813],
              [-80.837657750210951, 35.398334783019543],
              [-80.837816535557522, 35.397540906509278],
              [-80.837487614419942, 35.397498323840331],
              [-80.837593472054436, 35.396969073221655],
              [-80.837264554448439, 35.396926489925825],
              [-80.837317483231189, 35.396661864258668],
              [-80.836988567012895, 35.396619280211318],
              [-80.837041496494194, 35.39635465464761],
              [-80.836383667197964, 35.396269485058383],
              [-80.836436599521477, 35.39600485972273],
              [-80.835778773669603, 35.395919685943774],
              [-80.835831707734584, 35.395655060852818],
              [-80.835502796368729, 35.395612472765571],
              [-80.835555730031416, 35.395347847794675],
              [-80.835226821153853, 35.395305258939281],
              [-80.835279755514904, 35.395040634071869],
              [-80.834950848025159, 35.394998044465034],
              [-80.835003783084574, 35.394733419701097],
              [-80.834674876982405, 35.394690829342814],
              [-80.834780749253966, 35.394161579967545],
              [-80.834451845582578, 35.394118988999011],
              [-80.834557718541163, 35.393589738853954],
              [-80.834228817300399, 35.393547147275321],
              [-80.834440564354182, 35.392488647080711],
              [-80.834111667609548, 35.392446054273123],
              [-80.834270477124576, 35.391652178708355],
              [-80.834599370719928, 35.391694770190888],
              [-80.834705240661378, 35.391165519632665],
              [-80.835034131414858, 35.39120810995658],
              [-80.835245861828582, 35.390149605135647],
              [-80.834916974146125, 35.390107015393426],
              [-80.835181633803387, 35.388783884463855],
              [-80.835510515170881, 35.388826473516495],
              [-80.835563444348978, 35.38856184636532],
              [-80.835892326139174, 35.388604435268697],
              [-80.835945253929808, 35.388339807938635],
              [-80.836274133900673, 35.38838239492344],
              [-80.836591688292586, 35.386794631091696],
              [-80.836262815679262, 35.386752044937772],
              [-80.836209888855521, 35.3870166725101],
              [-80.834894394354833, 35.386846317551878],
              [-80.834947324249271, 35.386581690561208],
              [-80.834289581712582, 35.386496508451309],
              [-80.834342514448622, 35.386231881688978],
              [-80.834013644189639, 35.386189289444701],
              [-80.834066577623958, 35.385924662786074],
              [-80.833737708732031, 35.385882068889181],
              [-80.833843575551626, 35.385352815758331],
              [-80.833514710210892, 35.385310222136141],
              [-80.833620577737548, 35.384780969137509],
              [-80.833291714806521, 35.38473837400403],
              [-80.833344649645966, 35.384473747581389],
              [-80.833015788122708, 35.384431152597834],
              [-80.833121657732363, 35.383901899037802],
              [-80.83279279863919, 35.383859303444339],
              [-80.832845734509917, 35.383594676290322],
              [-80.832516876804007, 35.383552079945581],
              [-80.832993290256596, 35.381170436995397],
              [-80.832664443379969, 35.381127841013061],
              [-80.832982043049952, 35.379540076104199],
              [-80.833310883691013, 35.37958267214006],
              [-80.833575533882367, 35.378259531049203],
              [-80.833904369655073, 35.378302125486016],
              [-80.833957297168752, 35.378037496660461],
              [-80.834286132243307, 35.378080090063477],
              [-80.834339058369849, 35.377815461059242],
              [-80.835325561412375, 35.377943234605006],
              [-80.835378484066652, 35.377678605139423],
              [-80.835707318413881, 35.377721193788744],
              [-80.83581316062407, 35.377191935364223],
              [-80.836470825101529, 35.377277110338156],
              [-80.836523743594555, 35.377012480336667],
              [-80.836852575847516, 35.377055065884633],
              [-80.836905491853031, 35.376790435721176],
              [-80.837234323407799, 35.376833020235381],
              [-80.837287239126823, 35.37656838987651],
              [-80.840904392032812, 35.377036760961325],
              [-80.840851488882848, 35.377301392857191],
              [-80.8411803245708, 35.377343966661506],
              [-80.841127422119087, 35.377608598661233],
              [-80.842113933276536, 35.377736315159709],
              [-80.842061034688271, 35.378000946627616],
              [-80.842389872738522, 35.378043517161075],
              [-80.842336974869056, 35.378308149634186],
              [-80.844967709871668, 35.378648680948089],
              [-80.845073488583807, 35.378119413705299],
              [-80.845402329790744, 35.378161976048254],
              [-80.845455217587656, 35.37789734222968],
              [-80.845784059196788, 35.377939903522112],
              [-80.845889831672395, 35.377410635490385],
              [-80.846218670440024, 35.377453195624696],
              [-80.846324439453241, 35.376923927161265],
              [-80.846653276459378, 35.376966485219398],
              [-80.846811923168858, 35.376172581837217],
              [-80.84714075739133, 35.376215138579418],
              [-80.847193638263519, 35.3759505038973],
              [-80.847522471807977, 35.375993060506993],
              [-80.847575350172477, 35.375728424761874],
              [-80.847904183018542, 35.375770980337911],
              [-80.847957061096537, 35.375506344397465],
              [-80.848943556350989, 35.375634004479501],
              [-80.848996430956888, 35.375369368078303],
              [-80.849982927281644, 35.375497019687494],
              [-80.849930055783119, 35.375761655610866],
              [-80.850258889975549, 35.375804203897005],
              [-80.850206020296355, 35.376068840808763],
              [-80.851192526965988, 35.376196480752199],
              [-80.851139660050194, 35.3764611171488],
              [-80.851468497724994, 35.376503663048354],
              [-80.851521363577703, 35.376239025609358],
              [-80.852507875497977, 35.376366655727146],
              [-80.852560736798637, 35.376102018745556],
              [-80.853218411033794, 35.37618710014501],
              [-80.853271270984806, 35.375922461925832],
              [-80.853600107575915, 35.375965001145637],
              [-80.853652965060249, 35.375700363666141],
              [-80.853981800952695, 35.375742901852284],
              [-80.854034658150724, 35.375478264177531],
              [-80.853705823300942, 35.375435726132409],
              [-80.853864393716535, 35.374641811538787],
              [-80.854193225438436, 35.374684349160489],
              [-80.854351789629433, 35.373890433808043],
              [-80.854680618567514, 35.373932970113898],
              [-80.854786323475295, 35.373403692154106],
              [-80.854457497722962, 35.373361156113617],
              [-80.854986015305542, 35.370714763638723],
              [-80.854657200323032, 35.370672228116888],
              [-80.854710052006027, 35.370407588974381],
              [-80.854381238389436, 35.370365051799865],
              [-80.854592642509118, 35.369306493670386],
              [-80.85492145083461, 35.369349029396005],
              [-80.855079997449494, 35.368555109530469],
              [-80.85540880299159, 35.368597643940326],
              [-80.855461649759064, 35.368333003474902],
              [-80.855790454602555, 35.368375536851232],
              [-80.855843299983647, 35.368110896207568],
              [-80.856172104128632, 35.368153428550329],
              [-80.85627779177382, 35.367624146869503],
              [-80.856606594177819, 35.367666678037565],
              [-80.85665943644193, 35.367402037000325],
              [-80.857317040196932, 35.36748709637714],
              [-80.857369878911314, 35.367222454136183],
              [-80.857698680262203, 35.367264982344878],
              [-80.857751518711098, 35.367000340810137],
              [-80.858080319363438, 35.367042867985305],
              [-80.858133155325518, 35.366778226289235],
              [-80.858461955279424, 35.366820752430918],
              [-80.858567625166629, 35.366291467727258],
              [-80.858896422279187, 35.366333992711134],
              [-80.85900208870585, 35.365804707576984],
              [-80.859330885177712, 35.365847231369465],
              [-80.859383716272305, 35.365582588163477],
              [-80.85971251204549, 35.365625110922501],
              [-80.859818171138869, 35.365095825018528],
              [-80.860146964050116, 35.365138345718506],
              [-80.860305446973541, 35.364344415710825],
              [-80.860634238222374, 35.364386935979667],
              [-80.861109656760419, 35.362005139116398],
              [-80.861438437871143, 35.362047657240048],
              [-80.861491259617807, 35.361783012013206],
              [-80.861820040030054, 35.361825529103463],
              [-80.861872859290315, 35.361560883715491],
              [-80.862201639003985, 35.361603399772278],
              [-80.862254456898725, 35.361338755107589],
              [-80.863240794954521, 35.361466296599453],
              [-80.863293609357854, 35.361201650573321],
              [-80.863622388361904, 35.361244162778561],
              [-80.863675201379024, 35.360979516574432],
              [-80.864003979684355, 35.361022027746287],
              [-80.864056791315363, 35.360757381364152],
              [-80.864714345772484, 35.360842400765655],
              [-80.864767154975056, 35.360577754064536],
              [-80.865095932206273, 35.360620261375914],
              [-80.865148740043438, 35.360355615398063],
              [-80.865477515475703, 35.360398121693017],
              [-80.86553032190622, 35.36013347463593],
              [-80.865859097760733, 35.360175980781818],
              [-80.865911902805195, 35.359911333546769],
              [-80.866898227082785, 35.360038845339943],
              [-80.866845426245007, 35.360303492079815],
              [-80.867502979926371, 35.360388495456192],
              [-80.867714174994447, 35.359329903507813],
              [-80.868042947641385, 35.359372403752552],
              [-80.86820133663629, 35.358578458087898],
              [-80.868530107600051, 35.358620957000184],
              [-80.868582901842217, 35.358356308536656],
              [-80.868911670986449, 35.358398805531301],
              [-80.868964463842687, 35.358134156889875],
              [-80.8692932322882, 35.358176652851228],
              [-80.869398815964786, 35.357647354257438],
              [-80.869727582669157, 35.357689849044405],
              [-80.869780372409963, 35.357425200010383],
              [-80.8701091384157, 35.357467693764086],
              [-80.870161926770606, 35.357203044552136],
              [-80.870490692077496, 35.357245537272505],
              [-80.870543479025898, 35.356980886981425],
              [-80.871201007506414, 35.357065870381405],
              [-80.871253792026636, 35.356801219771583],
              [-80.871582556279577, 35.356843709532924],
              [-80.87163533943459, 35.356579059646549],
              [-80.872292866886667, 35.356664036210354],
              [-80.872345646492619, 35.356399385120824],
              [-80.873003173254816, 35.356484358734647],
              [-80.873055950432644, 35.356219707326431],
              [-80.873384713276351, 35.356262192203282],
              [-80.87343748906838, 35.355997540617217],
              [-80.874095013701577, 35.356082507411912],
              [-80.874042239994168, 35.35634715927992],
              [-80.877329894149582, 35.356771939320389],
              [-80.87727713162225, 35.357036592543935],
              [-80.877934668646802, 35.357121537585087],
              [-80.877987430210425, 35.356856884963861],
              [-80.878316198184947, 35.356899355554361],
              [-80.878368957241904, 35.356634701871172],
              [-80.87869772453827, 35.356677172329682],
              [-80.878750483309673, 35.356412518451712],
              [-80.879079249907079, 35.356454987876944],
              [-80.879132006192492, 35.356190333838363],
              [-80.879460772090994, 35.356232802230281],
              [-80.879513526990692, 35.355968148014],
              [-80.879842292169386, 35.356010614471366],
              [-80.879895045683369, 35.355745960077293],
              [-80.880223810183907, 35.355788426402782],
              [-80.880276562312218, 35.355523771831088],
              [-80.880605326092905, 35.355566236221961],
              [-80.880658076835516, 35.355301581472574],
              [-80.88098683993806, 35.355344045731513],
              [-80.881039589295014, 35.355079390804441],
              [-80.881697113303403, 35.355164314578055],
              [-80.881749860232389, 35.354899659332439],
              [-80.882078622280176, 35.354942120632565],
              [-80.882131367802657, 35.354677464307905],
              [-80.882788889722349, 35.354762383065335],
              [-80.882736147363829, 35.355027038753299],
              [-80.883393673841539, 35.355111954205924],
              [-80.883340932124284, 35.355376610156071],
              [-80.884655993347252, 35.355546430015472],
              [-80.884708729794909, 35.355281773518932],
              [-80.885037494911231, 35.355324225886775],
              [-80.885142965792269, 35.35479491248465],
              [-80.88547172806696, 35.354837363695445],
              [-80.885577194369361, 35.354308048980521],
              [-80.885905956002674, 35.354350499000127],
              [-80.886169609430397, 35.353027210431982],
              [-80.885840854108935, 35.352984761099428],
              [-80.885946315606503, 35.352455445870348],
              [-80.887261331713276, 35.352625236702366],
              [-80.887314057767597, 35.35236057801864],
              [-80.887971566345357, 35.35244546779046],
              [-80.887918842375669, 35.352710126755916],
              [-80.888247598771684, 35.352752570435563],
              [-80.888300320598944, 35.352487911346515],
              [-80.888629076295771, 35.352530353993011],
              [-80.888681796758505, 35.352265695627729],
              [-80.889010550655954, 35.352308137258198],
              [-80.889063270812514, 35.352043477796954],
              [-80.88939202401086, 35.352085918394295],
              [-80.889497459009618, 35.351556599114403],
              [-80.889826211545596, 35.351599037619323],
              [-80.890142502033001, 35.350011077153354],
              [-80.890471247579171, 35.350053514839466],
              [-80.890523960420836, 35.349788854642966],
              [-80.890852705267818, 35.349831291295921],
              [-80.890905415603129, 35.349566630037863],
              [-80.891234159750894, 35.349609065657731],
              [-80.891286869800965, 35.349344404205056],
              [-80.891615613249513, 35.349386838791901],
              [-80.891668320835151, 35.349122178080179],
              [-80.891997063584498, 35.349164611633903],
              [-80.892313298836868, 35.347576641192383],
              [-80.892642035675763, 35.347619073008964],
              [-80.892747442591002, 35.347089748653758],
              [-80.893076176588423, 35.34713217931364],
              [-80.893128877939333, 35.34686751694894],
              [-80.893457612316567, 35.346909945657281],
              [-80.893510312282274, 35.346645283115201],
              [-80.893839044881219, 35.34668771170896],
              [-80.893891743461765, 35.346423048989635],
              [-80.894220476440509, 35.346465475631788],
              [-80.894273173635895, 35.34620081273512],
              [-80.895588101574319, 35.346370512573422],
              [-80.895535408547786, 35.346635176033239],
              [-80.895864142141107, 35.346677598231302],
              [-80.895811449834895, 35.346942262696658],
              [-80.896468921255874, 35.347027105581539],
              [-80.896521611477738, 35.346762440834631],
              [-80.897836554265638, 35.346932115333964],
              [-80.897889238896624, 35.346667450905976],
              [-80.898546710263403, 35.346752282520441],
              [-80.898599392445888, 35.346487616873041],
              [-80.898928128161913, 35.346530031643177],
              [-80.898980808980369, 35.346265366719756],
              [-80.89930954287594, 35.346307779572719],
              [-80.899362222288261, 35.346043113570829],
              [-80.90001969014439, 35.346127937202581],
              [-80.900072367150557, 35.345863271783948],
              [-80.901058567797662, 35.345990498774029],
              [-80.90111124133422, 35.345725832896591],
              [-80.901439974159871, 35.345768239973779],
              [-80.901492646290407, 35.34550357301783],
              [-80.901821378437802, 35.345545979963276],
              [-80.901768708428449, 35.345810646141331],
              [-80.90275491109071, 35.345937859325034],
              [-80.90280757797332, 35.34567319272481],
              [-80.903136312570638, 35.345715595182547],
              [-80.903188978047069, 35.345450927503805],
              [-80.904175178568892, 35.345578129135781],
              [-80.904385825661564, 35.344519457251387],
              [-80.905372015660973, 35.344646649181698],
              [-80.90542467317033, 35.344381980476314],
              [-80.907397057247152, 35.344636338482879],
              [-80.907502357631003, 35.344106999291732],
              [-80.907831087245413, 35.34414938904164],
              [-80.907936384174207, 35.343620049423329],
              [-80.908265112046891, 35.343662437999612],
              [-80.908317759505053, 35.343397767986424],
              [-80.908646486656963, 35.343440154628475],
              [-80.9086991316303, 35.343175484455514],
              [-80.908370405520614, 35.343133097954173],
              [-80.908423051172406, 35.342868426984218],
              [-80.908094326447653, 35.34282603973125],
              [-80.908199619928951, 35.342296698847306],
              [-80.907870897631241, 35.342254310983414],
              [-80.908081484651333, 35.341195629341392],
              [-80.907752766843728, 35.341153240246705],
              [-80.907805414345006, 35.340888570327898],
              [-80.90813412998935, 35.340930958397962],
              [-80.908186776105765, 35.340666288302018],
              [-80.908515491050537, 35.340708675339172],
              [-80.908462847097297, 35.340973346459748],
              [-80.909448998255826, 35.341100504424929],
              [-80.90955427958778, 35.340571162204753],
              [-80.909882995960572, 35.340613545514969],
              [-80.90993563398122, 35.340348874677595],
              [-80.910264349654383, 35.340391256954923],
              [-80.910369624762311, 35.339861913953854],
              [-80.910698337593857, 35.339904295074973],
              [-80.911172045828465, 35.337522243600517],
              [-80.911829453803051, 35.33760700243689],
              [-80.911882083502519, 35.33734232879835],
              [-80.91221078696141, 35.337384706737524],
              [-80.912316044369689, 35.33685535995432],
              [-80.91264474496576, 35.336897735836061],
              [-80.912697372674685, 35.336633062691362],
              [-80.913026072571142, 35.336675437540364],
              [-80.913078697795626, 35.336410764236028],
              [-80.913407398092346, 35.336453138034834],
              [-80.913460021932295, 35.336188464553565],
              [-80.913788721529457, 35.336230837319533],
              [-80.913841343963583, 35.335966162760194],
              [-80.914170041782342, 35.336008535411914],
              [-80.914222662832032, 35.335743860675684],
              [-80.914551361029908, 35.33578623137592],
              [-80.914603980695006, 35.335521556462801],
              [-80.914932677114564, 35.335563927048966],
              [-80.914985295395169, 35.335299251958922],
              [-80.91531399109374, 35.335341620610997],
              [-80.915366607989981, 35.335076945344063],
              [-80.917010088650869, 35.335288776286212],
              [-80.916957476965024, 35.335553452256129],
              [-80.91728617581785, 35.335595815537694],
              [-80.917233564831747, 35.335860491611967],
              [-80.918219665470687, 35.335987576542557],
              [-80.918167058368525, 35.336252252984998],
              [-80.91915316521488, 35.336379330307544],
              [-80.919205769190782, 35.336114653443339],
              [-80.919534470382104, 35.336157010637429],
              [-80.919587072973457, 35.335892333596405],
              [-80.920244476498752, 35.335977044992013],
              [-80.920349675386291, 35.335447690256295],
              [-80.921007075012312, 35.335532397538209],
              [-80.920954478373915, 35.335797075196638],
              [-80.921611881453273, 35.335881779190871],
              [-80.921559285456979, 35.336146457111575],
              [-80.922216693068279, 35.336231156899331],
              [-80.922164098814008, 35.336495835064994],
              [-80.922492804185794, 35.336538184211697],
              [-80.922440211731313, 35.336802862464211],
              [-80.922768918466303, 35.336845209958007],
              [-80.922716325611844, 35.337109888332186],
              [-80.923045033752473, 35.337152235975644],
              [-80.923097624464859, 35.336887557478299],
              [-80.923426331905659, 35.336929904088969],
              [-80.92347892233353, 35.336665225397418],
              [-80.923807629074389, 35.336707570975257],
              [-80.92386021701806, 35.336442892124381],
              [-80.924188923037548, 35.336485235768144],
              [-80.924241509618099, 35.336220557641788],
              [-80.924570214937816, 35.336262900252869],
              [-80.924675384945289, 35.335733541807713],
              [-80.925004088544043, 35.33577588414672],
              [-80.925056671992422, 35.335511204729322],
              [-80.925385373770098, 35.335553545151775],
              [-80.925437955834056, 35.335288865557629],
              [-80.926095360552694, 35.335373544311196],
              [-80.926147939090328, 35.335108864417279],
              [-80.926805343093235, 35.335193539320954],
              [-80.926857919204409, 35.334928859109709],
              [-80.92718662068765, 35.334971195533377],
              [-80.927239195393284, 35.334706514244232],
              [-80.927896597280423, 35.334791183232532],
              [-80.927949169580899, 35.334526502527375],
              [-80.928606569652445, 35.334611167683391],
              [-80.928553999436176, 35.334875848669498],
              [-80.930854919353749, 35.335172147314438],
              [-80.930907482274776, 35.334907465344827],
              [-80.931564888482313, 35.334992113539613],
              [-80.931617448977036, 35.334727431252865],
              [-80.932603558276838, 35.334854396423061],
              [-80.932551002008623, 35.335119079113603],
              [-80.93287970720138, 35.335161399187193],
              [-80.932827150533484, 35.335426081999572],
              [-80.934141978893663, 35.335595353933208],
              [-80.934194530292856, 35.33533067057656],
              [-80.934523237195705, 35.335372986188951],
              [-80.934680887293354, 35.334578934561684],
              [-80.935009590311694, 35.33462124887798],
              [-80.935114686942086, 35.334091880358486],
              [-80.936100793973864, 35.334218816175017],
              [-80.93615333811033, 35.333954131899162],
              [-80.936810742049758, 35.33403875074027],
              [-80.936758199997584, 35.334303435297009],
              [-80.937415608510406, 35.334388051733761],
              [-80.937363068200739, 35.334652736535269],
              [-80.938020480144047, 35.33473734878261],
              [-80.937967941598487, 35.335002034730017],
              [-80.93829664911452, 35.335044339205133],
              [-80.938349186639343, 35.334779654018533],
              [-80.938677893455036, 35.334821957460974],
              [-80.938940567397466, 35.333498528534058],
              [-80.938611865771335, 35.333456224892373],
              [-80.93882200225049, 35.332397480410911],
              [-80.93849330623479, 35.33235517642111],
              [-80.938650908389974, 35.3315611174217],
              [-80.938322215864076, 35.331518813862196],
              [-80.938479818047398, 35.330724754057947],
              [-80.938808507468281, 35.330767058097507],
              [-80.938913571056659, 35.330237684144777],
              [-80.939242258735263, 35.330279987011295],
              [-80.939189728143404, 35.330544673695435],
              [-80.940833176831163, 35.33075617354455],
              [-80.941095800276472, 35.329432734450442],
              [-80.94142448471618, 35.32947503051858],
              [-80.941477007346336, 35.329210342811777],
              [-80.941805692185483, 35.329252637829633],
              [-80.941858212310464, 35.328987949062764],
              [-80.942186896470801, 35.329030243949305],
              [-80.942239416311693, 35.328765554988379],
              [-80.942568098650383, 35.328807847958721],
              [-80.942620617128952, 35.328543159722656],
              [-80.942949299867053, 35.328585451642859],
              [-80.943054331472283, 35.328056073014629],
              [-80.943383012489463, 35.328098364663092],
              [-80.94343552673736, 35.327833675154643],
              [-80.943764207032629, 35.327875964869328],
              [-80.943816719896731, 35.327611275184481],
              [-80.944802757528436, 35.327738139490897],
              [-80.944855266924677, 35.327473449348936],
              [-80.945183946502553, 35.32751573521422],
              [-80.945236454514941, 35.327251044895846],
              [-80.945565132292657, 35.32729332974624],
              [-80.945617638921277, 35.327028639251544],
              [-80.945288962185629, 35.326986354541596],
              [-80.945498988330641, 35.325927591881182],
              [-80.945170316104907, 35.325885306840426],
              [-80.945275329750629, 35.325355925115723],
              [-80.945603999892285, 35.325398209875743],
              [-80.945656505149799, 35.325133518368538],
              [-80.945985174612616, 35.325175802997229],
              [-80.946090180919015, 35.324646419611966],
              [-80.946418848617867, 35.324688702166533],
              [-80.946523851495286, 35.324159319258165],
              [-80.946852517451617, 35.324201600639874],
              [-80.946957516856955, 35.323672216405882],
              [-80.947286181092551, 35.323714497516036],
              [-80.947338678690912, 35.323449805213684],
              [-80.947996004960032, 35.323534362692122],
              [-80.947943509445594, 35.323799055275174],
              [-80.949258171350436, 35.323968160070166],
              [-80.949310662696703, 35.323703466925707],
              [-80.94963932825975, 35.323745740749295],
              [-80.94974430784373, 35.323216354072194],
              [-80.950401634726575, 35.323300898499426],
              [-80.950454121922277, 35.323036204826415],
              [-80.95144011223185, 35.323163014346491],
              [-80.951387628162308, 35.323427708440441],
              [-80.952044958438719, 35.323512243045045],
              [-80.952097440424311, 35.323247548670466],
              [-80.952426105593332, 35.323289814935968],
              [-80.952478585095704, 35.323025120402903],
              [-80.953464578404436, 35.323151912542315],
              [-80.953412102028167, 35.323416607496256],
              [-80.954069435418035, 35.323501131096783],
              [-80.954016959684679, 35.323765826313249],
              [-80.954345627944676, 35.323808087366167],
              [-80.954293154011737, 35.324072782669255],
              [-80.954621823655145, 35.324115042970362],
              [-80.954569350401172, 35.324379737476555],
              [-80.955555364484781, 35.324506512546478],
              [-80.955502894037267, 35.324771208338881],
              [-80.95681758951568, 35.324940229217546],
              [-80.956922520632901, 35.324410836492454],
              [-80.957579866780264, 35.324495340107532],
              [-80.957632329214377, 35.324230644320799],
              [-80.957961001208218, 35.324272894658662],
              [-80.957803614951615, 35.32506698415235],
              [-80.958460966237652, 35.325151483894537],
              [-80.958408505927338, 35.325416180895559],
              [-80.95873718311374, 35.32545842911798],
              [-80.958474879409778, 35.326781912519216],
              [-80.958803563248011, 35.326824160532816],
              [-80.958646180258157, 35.327618249838622],
              [-80.958317493293478, 35.327576001404303],
              [-80.958265029831466, 35.32784069733885],
              [-80.957936342165894, 35.327798447872006],
              [-80.957831413233166, 35.32832783933506],
              [-80.957502723824703, 35.32828558869555],
              [-80.957345324188481, 35.329079675208405],
              [-80.957674016723672, 35.329121926268698],
              [-80.957569084376814, 35.32965131737204],
              [-80.957240388657496, 35.329609066048981],
              [-80.957187920358081, 35.329873760514069],
              [-80.956859225037647, 35.329831508140778],
              [-80.956754285373492, 35.330360898484784],
              [-80.956096890466654, 35.330276389617097],
              [-80.955834524864954, 35.331599861641848],
              [-80.955505822151693, 35.331557604726491],
              [-80.955453346973371, 35.331822299243647],
              [-80.955782050707242, 35.331864555397992],
              [-80.955677101367243, 35.332393942801986],
              [-80.956005806449014, 35.332436199263618],
              [-80.955900857827572, 35.332965586803901],
              [-80.956229566413555, 35.333007841734776],
              [-80.956177092633013, 35.33327253559105],
              [-80.95650580260272, 35.333314789769894],
              [-80.956453329522986, 35.333579483730375],
              [-80.957110753671401, 35.333663989673838],
              [-80.95705828233487, 35.333928683878732],
              [-80.957386995413643, 35.333970935661185],
              [-80.957334524777977, 35.334235629970337],
              [-80.95799195514455, 35.334320131121039],
              [-80.95793948625213, 35.334584825674476],
              [-80.958268203539831, 35.334627075042768],
              [-80.958215735348332, 35.334891769700313],
              [-80.959530610961821, 35.3350607579277],
              [-80.959425681893421, 35.335590148256451],
              [-80.960411849262371, 35.335716880660371],
              [-80.960359387321574, 35.335981575289942],
              [-80.961345560912122, 35.33610830098484],
              [-80.961293102857027, 35.336372995981094],
              [-80.961950555687537, 35.336457474695173],
              [-80.96205546726506, 35.335928083204386],
              [-80.96238419211835, 35.335970321393191],
              [-80.962436646341658, 35.335705625002817],
              [-80.962765370493997, 35.335747862159096],
              [-80.962817823333594, 35.335483165592343],
              [-80.964461443959706, 35.335694335485151],
              [-80.964408996332352, 35.335959032752761],
              [-80.964737722510918, 35.336001263653763],
              [-80.964685276662777, 35.33626596010631],
              [-80.965014004246967, 35.336308191156412],
              [-80.964961558, 35.33657288773086],
              [-80.965619017355252, 35.336657346515175],
              [-80.965566572851998, 35.336922043333956],
              [-80.966552767011308, 35.337048724796517],
              [-80.96660520728733, 35.336784027575227],
              [-80.967262670033762, 35.336868476534747],
              [-80.967315107883465, 35.336603778997002],
              [-80.967972569909989, 35.336688224106801],
              [-80.968025005333558, 35.336423526252666],
              [-80.967696274620366, 35.336381303391718],
              [-80.967801146506801, 35.335851906954133],
              [-80.967472419341561, 35.335809684364513],
              [-80.967629728828115, 35.335015589389322],
              [-80.967958452865986, 35.335057811558407],
              [-80.968063320218292, 35.334528413876548],
              [-80.968720765169991, 35.334612855878454],
              [-80.968773196799305, 35.334348156694197],
              [-80.969101918733642, 35.334390375765842],
              [-80.969416492868348, 35.332802178196694],
              [-80.96908777718852, 35.332759959965898],
              [-80.969140206429472, 35.332495260529242],
              [-80.969468919967099, 35.332537478637867],
              [-80.969573775318636, 35.33200807847512],
              [-80.970888625641521, 35.332176940847461],
              [-80.970836203416638, 35.332441641940093],
              [-80.971493632159863, 35.332526068061618],
              [-80.971441211656796, 35.332790768497297],
              [-80.971769928682306, 35.332832980341749],
              [-80.971665087659474, 35.333362382304379],
              [-80.971993807089234, 35.333404592635489],
              [-80.971888967886542, 35.333933994716318],
              [-80.972546410882984, 35.334018415082497],
              [-80.972493992843951, 35.334283115898472],
              [-80.973151440367829, 35.334367532056312],
              [-80.973099024072582, 35.334632233116373],
              [-80.973427749399562, 35.334674440447571],
              [-80.973375333805663, 35.334939141611656],
              [-80.973704060516241, 35.33498134819051],
              [-80.973651645623704, 35.335246049458718],
              [-80.973980373696108, 35.335288254384139],
              [-80.973927959504863, 35.335552955756306],
              [-80.975242880540918, 35.33572176978042],
              [-80.975190471278466, 35.335986471658977],
              [-80.976834136247206, 35.33619746822049],
              [-80.976729326002712, 35.336726872386812],
              [-80.977058062777743, 35.33676906983208],
              [-80.977005657625625, 35.33703377155944],
              [-80.977663134262144, 35.337118163151807],
              [-80.977610731975901, 35.337382866006486],
              [-80.97793947073751, 35.337425060171469],
              [-80.977834667204903, 35.337954465151391],
              [-80.979149638423834, 35.338123234837042],
              [-80.979202036179302, 35.33785853135425],
              [-80.981174496971533, 35.338111657819553],
              [-80.981226887052316, 35.3378469543797],
              [-80.981555630664801, 35.337889138710274],
              [-80.981608020440035, 35.337624434175133],
              [-80.98292299412941, 35.337793162013064],
              [-80.982975378315203, 35.337528457801028],
              [-80.98330412154732, 35.337570637389412],
              [-80.983513651744957, 35.336511816898529],
              [-80.983842390047585, 35.336553995052604],
              [-80.983947151554773, 35.336024583851056],
              [-80.984275888113132, 35.33606676083263],
              [-80.984328266762859, 35.335802055046827],
              [-80.984657003719391, 35.335844230978083],
              [-80.98481413339556, 35.335050113002339],
              [-80.985142867565315, 35.335092287621222],
              [-80.985195242766295, 35.334827581411169],
              [-80.985523975134512, 35.334869755015688],
              [-80.985576348952065, 35.334605048629662],
              [-80.985905080596552, 35.334647220300553],
              [-80.985957453030693, 35.334382513738575],
              [-80.986286183995489, 35.33442468527835],
              [-80.986338555046316, 35.334159978540292],
              [-80.986996015891179, 35.334244317761403],
              [-80.987048384516072, 35.333979610707459],
              [-80.987377114396111, 35.334021778388873],
              [-80.987429480537813, 35.333757071176919],
              [-80.987758209738203, 35.333799238727259],
              [-80.987810575574258, 35.33353453042001],
              [-80.988139304072817, 35.333576696937996],
              [-80.988191667425795, 35.333311988472943],
              [-80.988520395222679, 35.333354153958517],
              [-80.988468032912266, 35.333618862563611],
              [-80.988796762070436, 35.333661026395482],
              [-80.988849123360254, 35.333396318551777],
              [-80.991478963917785, 35.333733598611417],
              [-80.991426610990487, 35.333998308475998],
              [-80.991755344315195, 35.334040464258415],
              [-80.991702992089856, 35.334305174226934],
              [-80.992031726797791, 35.334347329256872],
              [-80.992084076880772, 35.334082619166423],
              [-80.992412809809039, 35.334124774083378],
              [-80.992465159608571, 35.333860063799001],
              [-80.99345135836559, 35.333986520955051],
              [-80.993503703618899, 35.33372181113419],
              [-80.994161169426974, 35.333806110556232],
              [-80.994265854719032, 35.33327668934578],
              [-80.994594586598822, 35.33331883742931],
              [-80.994646927680179, 35.333054126179569],
              [-80.99629058259822, 35.333264853485474],
              [-80.99623824780754, 35.33352956451526],
              [-80.996566980631243, 35.333571707262536],
              [-80.996514646564606, 35.333836419297612],
              [-80.997172116418795, 35.333920702376552],
              [-80.997224448400189, 35.333655990061693],
              [-80.997553183345417, 35.333698130113653],
              [-80.998210653157955, 35.333782407558374],
              [-80.998158324282315, 35.334047119391599],
              [-80.998487060192161, 35.334089256924315],
              [-80.998382403526662, 35.334618681663734],
              [-80.998711141862273, 35.334660818583792],
              [-80.998658814050302, 35.334925530588777],
              [-80.998987554868975, 35.334967666738052],
              [-80.998935227759119, 35.335232378846968],
              [-80.999263969961007, 35.335274514243778],
              [-80.999159315705, 35.335803938651367],
              [-80.999488060332922, 35.335846073435469],
              [-80.999383407899913, 35.336375497960717],
              [-80.999712154953784, 35.336417632131997],
              [-80.999345866887339, 35.338270616931375],
              [-80.999017112534375, 35.338228481781059],
              [-80.998860123056474, 35.339022616658696],
              [-80.998531364815918, 35.338980480214389],
              [-80.998426701001407, 35.339509902711718],
              [-80.998097941015928, 35.339467765095179],
              [-80.99788860510624, 35.340526608195951],
              [-80.997559841312381, 35.340484470028784],
              [-80.997507504885135, 35.340749180357605],
              [-80.997178740366977, 35.340707040256824],
              [-80.997074064404842, 35.34123646052636],
              [-80.996745298141732, 35.341194319253269],
              [-80.996640618731334, 35.341723739098498],
              [-80.996311850723345, 35.34168159665321],
              [-80.99620716786464, 35.342211016074188],
              [-80.995878398111586, 35.342168872456575],
              [-80.995773711804461, 35.34269829145309],
              [-80.995444939206493, 35.342656146681392],
              [-80.995340249428793, 35.343185564352204],
              [-80.995669024112459, 35.34322770940377],
              [-80.995564333957674, 35.343757127227853],
              [-80.995235558288243, 35.343714981878399],
              [-80.99492147446729, 35.345303232723332],
              [-80.995250257471781, 35.345345377992643],
              [-80.995145562943449, 35.345874794318078],
              [-80.995474348396598, 35.345916939875892],
              [-80.995369654591087, 35.346446356336159],
              [-80.995698442470911, 35.34648850128125],
              [-80.995384357990019, 35.348076748846772],
              [-80.995713152446214, 35.348118892837306],
              [-80.995503759767956, 35.349177723786795],
              [-80.995832559836785, 35.349219867426129],
              [-80.995623165856401, 35.350278697453],
              [-80.99529436159338, 35.350236552352989],
              [-80.994980252458191, 35.351824794139382],
              [-80.994651442277629, 35.351782647307409],
              [-80.994494379408053, 35.352576766397924],
              [-80.994165565338903, 35.352534618271854],
              [-80.994060853328335, 35.353064030230776],
              [-80.99373203753602, 35.353021881833484],
              [-80.993627320953763, 35.3535512924839],
              [-80.993298503416, 35.353509142914184],
              [-80.993193784484291, 35.354038553121079],
              [-80.992864964101116, 35.353996402397044],
              [-80.99281260253079, 35.354261107315054],
              [-80.992483782523308, 35.354218954639109],
              [-80.992379056273904, 35.354748364085943],
              [-80.992707878367639, 35.354790517041693],
              [-80.992550789566366, 35.355584631358518],
              [-80.992879616208441, 35.355626782921867],
              [-80.99282725363615, 35.355891487761163],
              [-80.993484908951046, 35.35597578940903],
              [-80.993432548124147, 35.356240494491566],
              [-80.993761377897215, 35.356282644557993],
              [-80.99370901777263, 35.356547349743948],
              [-80.993380186956472, 35.356505199537615],
              [-80.993223101385951, 35.357299313555913],
              [-80.992894267780997, 35.357257162037257],
              [-80.992841904206642, 35.357521866797526],
              [-80.992513069899388, 35.357479714246367],
              [-80.992408339619416, 35.358009122476446],
              [-80.992737176013094, 35.358051275307396],
              [-80.992632446433873, 35.358580682769983],
              [-80.992961285254808, 35.358622834988083],
              [-80.992908920997138, 35.358887538804467],
              [-80.993895443691002, 35.359013988701435],
              [-80.993843082244268, 35.359278693802189],
              [-80.994500768183102, 35.359362988942827],
              [-80.994448408459817, 35.359627693385562],
              [-80.994777252455847, 35.359669840666989],
              [-80.994724893435063, 35.359934545213029],
              [-80.995053739893223, 35.359976690822215],
              [-80.995106097870817, 35.359711986136226],
              [-80.995434942526344, 35.359754130730934],
              [-80.995382585592125, 35.360018835556808],
              [-80.996040280234894, 35.360103123212696],
              [-80.995987925046407, 35.360367828281653],
              [-80.996974471563263, 35.360494252155775],
              [-80.996922120264045, 35.360758957589624],
              [-80.997250971216801, 35.360801098130587],
              [-80.997198619497723, 35.361065802784601],
              [-80.997527471812475, 35.361107941671413],
              [-80.997422771683148, 35.361637352915785],
              [-80.997093916181427, 35.361595213767423],
              [-80.997041564540098, 35.36185991829371],
              [-80.996712709436082, 35.361817778094618],
              [-80.996608001988193, 35.362347188578319],
              [-80.99627914511629, 35.362305046305494],
              [-80.996226789287675, 35.362569751361704],
              [-80.995897931735286, 35.362527608957535],
              [-80.995688501872351, 35.363586425534876],
              [-80.996017364698432, 35.36362856848033],
              [-80.995965007165424, 35.363893273353469],
              [-80.996293870275508, 35.363935415564121],
              [-80.996189157329738, 35.364464823659617],
              [-80.996518023967724, 35.364506965239038],
              [-80.996465667476784, 35.364771669380715],
              [-80.996794535499063, 35.364813810207224],
              [-80.996689824681752, 35.365343218642174],
              [-80.997018695131644, 35.365385358855562],
              [-80.996913983937489, 35.365914767441822],
              [-80.997242856815205, 35.365956907042069],
              [-80.997190502828374, 35.36622161050061],
              [-80.997519377090399, 35.366263749347866],
              [-80.997467022728017, 35.366528453828977],
              [-80.997795899474667, 35.366570591905116],
              [-80.997743545814913, 35.366835296489377],
              [-80.998072422845581, 35.366877433830652],
              [-80.997758304302749, 35.368465657727299],
              [-80.998087187935184, 35.3685077950147],
              [-80.998034835054554, 35.368772498702342],
              [-80.998363721171771, 35.368814635218577],
              [-80.998311367893592, 35.369079339027437],
              [-80.998640255395117, 35.369121474790674],
              [-80.998587902819509, 35.369386178702641],
              [-80.998916791705525, 35.36942831371281],
              [-80.998864440933048, 35.369693017709722],
              [-80.999193331203358, 35.369735151966822],
              [-80.999140981133593, 35.36999985606689],
              [-80.999469872766156, 35.370041988669605],
              [-80.999417522298685, 35.370306692890885],
              [-80.999746416438271, 35.370348825623651],
              [-80.999694066673626, 35.370613529947981],
              [-81.000022962197548, 35.370655661927678],
              [-80.999970613113476, 35.370920365453792],
              [-81.000299510021833, 35.370962496680406],
              [-81.000194814040768, 35.37149190478501],
              [-81.000523713377277, 35.371534035398405],
              [-81.000471366480326, 35.371798739976875],
              [-81.000800266078656, 35.371840868954067],
              [-81.00069557332742, 35.372370277379048],
              [-81.001024476476502, 35.372412406626026],
              [-81.000972130622216, 35.372677110472459],
              [-81.001301034055317, 35.372719238984487],
              [-81.001248688903772, 35.372983942933914],
              [-81.001577594821796, 35.373026070674641],
              [-81.00147290558364, 35.373555478742716],
              [-81.001801813929745, 35.373597605870032],
              [-81.001749469843148, 35.373862309988667],
              [-81.002078379551662, 35.373904435461682],
              [-81.001973692465469, 35.374433844769172],
              [-81.002302604602107, 35.374475969628797],
              [-81.002250262680732, 35.374740673898259],
              [-81.002579176201991, 35.374782798004723],
              [-81.00252683388311, 35.375047502395304],
              [-81.002855748811101, 35.37508962664986],
              [-81.002751066338831, 35.375619035581927],
              [-81.00307998367289, 35.37566115832179],
              [-81.003027642419028, 35.375925862881388],
              [-81.003356562237954, 35.375967984849886],
              [-81.003251880773192, 35.376497393236818],
              [-81.003580803042752, 35.376539515493157],
              [-81.003528462831753, 35.376804219320427],
              [-81.003857386463693, 35.376846339922295],
              [-81.003805046977874, 35.377111044753683],
              [-81.004133971994392, 35.377153164602284],
              [-81.004081634311987, 35.377417869518418],
              [-81.004410560713012, 35.377459988613872],
              [-81.004462897351615, 35.377195283557953],
              [-81.004791823049743, 35.377237401620661],
              [-81.004739486354495, 35.377502106834442],
              [-81.005726268624841, 35.377628456083798],
              [-81.005673935798441, 35.377893160760557],
              [-81.006331794436804, 35.377977389407967],
              [-81.006279463379613, 35.378242095228494],
              [-81.006608394253718, 35.378284208352468],
              [-81.006556063877255, 35.378548913374658],
              [-81.006884996136051, 35.378591025745301],
              [-81.00683266646277, 35.378855730870299],
              [-81.007161600106073, 35.378897842487675],
              [-81.007056941824843, 35.379427252906275],
              [-81.007385878997169, 35.379469363891957],
              [-81.007333549288788, 35.379734069203792],
              [-81.00766248784565, 35.379776179436149],
              [-81.007610159940953, 35.380040884832539],
              [-81.007939099882449, 35.380082994311636],
              [-81.007886772680891, 35.38034769981082],
              [-81.008215712906562, 35.380389808554803],
              [-81.008268039064319, 35.380125102915905],
              [-81.008925920628812, 35.380209316507582],
              [-81.008873596558871, 35.380474022425915],
              [-81.009531480473598, 35.380558232743176],
              [-81.009479159251299, 35.380822938885743],
              [-81.010465991371817, 35.380949248524374],
              [-81.010794936445407, 35.380991349344299],
              [-81.010742619058036, 35.381256056008674],
              [-81.011071565538543, 35.381298156976484],
              [-81.011019247731653, 35.381562862860619],
              [-81.012006093427118, 35.381689159905164],
              [-81.011953778411495, 35.381953866171465],
              [-81.012282728342541, 35.3819959638464],
              [-81.012125787729559, 35.382790084590134],
              [-81.012454741110901, 35.382832180889721],
              [-81.01240242792106, 35.383096887269211],
              [-81.012731382709376, 35.383138983716641],
              [-81.012417501624086, 35.38472722387521],
              [-81.012088539471051, 35.384685126607998],
              [-81.011983906902856, 35.385214539731294],
              [-81.011654943002512, 35.385172441291552],
              [-81.011550306961112, 35.38570185308609],
              [-81.011221341335599, 35.385659754375141],
              [-81.011064381035624, 35.386453871379182],
              [-81.010735412596418, 35.386411770454799],
              [-81.010683090404115, 35.386676475915024],
              [-81.010354121283754, 35.386634374859199],
              [-81.010092501046316, 35.38795789908508],
              [-81.009763525923518, 35.387915795554676],
              [-81.009606546981971, 35.38870990849761],
              [-81.009935524136552, 35.388752012465424],
              [-81.009464574938534, 35.391134347897328],
              [-81.009412245545846, 35.391399051648385],
              [-81.009741235661735, 35.3914411551822],
              [-81.009688906972528, 35.391705859035795],
              [-81.010017897372791, 35.391747961834298],
              [-81.009703924278881, 35.393336182309234],
              [-81.010032921285372, 35.39337828505262],
              [-81.009718942165335, 35.394966504124362],
              [-81.009389937770436, 35.39492439965975],
              [-81.009232939353438, 35.395718507390711],
              [-81.009561946858966, 35.395760611373092],
              [-81.009300281946935, 35.397084123297347],
              [-81.009629296115577, 35.397126227066643],
              [-81.00947229576191, 35.397920333839437],
              [-81.009143278437932, 35.397878228749825],
              [-81.008933934934902, 35.398937036110311],
              [-81.009262956414091, 35.398979140857413],
              [-81.009158285784551, 35.39950854367347],
              [-81.009487309693384, 35.399550647806656],
              [-81.009434973811025, 35.399815349316604],
              [-81.009764000205877, 35.399857452677843],
              [-81.009711665027055, 35.400122154290145],
              [-81.01004069280728, 35.400164256897774],
              [-81.009936024616394, 35.400693660271337],
              [-81.010265054826363, 35.400735762265008],
              [-81.010212720713682, 35.401000464044429],
              [-81.010541752308967, 35.401042565284563],
              [-81.010437087329109, 35.401571968072915],
              [-81.010766120253408, 35.401614068717315],
              [-81.010713788307882, 35.401878770645723],
              [-81.011042823718185, 35.401920870518175],
              [-81.010938160871106, 35.402450273640767],
              [-81.011267198711238, 35.402492372899275],
              [-81.011162535488936, 35.403021776169766],
              [-81.01149157575901, 35.403063874814272],
              [-81.011439245220259, 35.40332857607325],
              [-81.011768286875608, 35.403370673964083],
              [-81.011663626886957, 35.403900077550361],
              [-81.011992672050766, 35.403942173907559],
              [-81.011940342600809, 35.404206876234852],
              [-81.012269388049148, 35.40424897185671],
              [-81.012164730193604, 35.404778375776885],
              [-81.012493779172686, 35.404820470766317],
              [-81.012389122020352, 35.405349873914709],
              [-81.012718172351029, 35.405391969209667],
              [-81.012404198958876, 35.406980177691835],
              [-81.012733256976375, 35.407022272011744],
              [-81.012576268801368, 35.407816375714752],
              [-81.012905330315945, 35.407858470461463],
              [-81.012853001272234, 35.40812317115963],
              [-81.012523938713016, 35.408081076273291],
              [-81.012419277536083, 35.408610478178872],
              [-81.012748342184608, 35.408652573344504],
              [-81.012643681711154, 35.409181974477931],
              [-81.012972749890821, 35.409224069011124],
              [-81.012868090143215, 35.4097534702736],
              [-81.013197160753037, 35.409795564192564],
              [-81.013144830311703, 35.410060264925391],
              [-81.013473902284872, 35.410102357189324],
              [-81.013264585693136, 35.411161160230172],
              [-81.012935509518755, 35.411119066506444],
              [-81.012883178473842, 35.411383767032973],
              [-81.011895948906769, 35.411257481884853],
              [-81.011843614364537, 35.411522181053648],
              [-81.011185460927493, 35.411437985907988],
              [-81.011133122876302, 35.411702685679394],
              [-81.010804045613256, 35.411660586176176],
              [-81.010751707277194, 35.411925285752076],
              [-81.01009355058379, 35.411841083804198],
              [-81.009988867609081, 35.41237048230284],
              [-81.010317947302042, 35.412412583110637],
              [-81.010213265030643, 35.412941980836813],
              [-81.01054234717634, 35.412984081931796],
              [-81.010490007113305, 35.413248780418343],
              [-81.010819090644716, 35.413290880759526],
              [-81.010766751285487, 35.413555579347992],
              [-81.011095836202657, 35.413597678935353],
              [-81.010938818112436, 35.414391774912147],
              [-81.011267906504983, 35.414433874024901],
              [-81.011058547723096, 35.415492668703905],
              [-81.011387641714506, 35.415534766562217],
              [-81.01128296236746, 35.416064163954807],
              [-81.011612058811693, 35.416106262100229],
              [-81.011507380168169, 35.416635658719976],
              [-81.011836479043083, 35.41667775625119],
              [-81.011731801125478, 35.417207152999325],
              [-81.012060903509749, 35.417249248996683],
              [-81.012008564544956, 35.417513947913584],
              [-81.012337668315169, 35.417556043157134],
              [-81.012232992531111, 35.418085440237355],
              [-81.012562098732076, 35.418127534866564],
              [-81.01235274725218, 35.419186329132465],
              [-81.012023636849221, 35.419144233043511],
              [-81.011971296520272, 35.419408930934402],
              [-81.01164218653669, 35.419366834695268],
              [-81.011589844821813, 35.419631532408765],
              [-81.010931621563557, 35.419547336105815],
              [-81.010826934031968, 35.420076730842716],
              [-81.010497819734596, 35.420034631540588],
              [-81.010393128749158, 35.420564025847007],
              [-81.010064013803913, 35.420521925353711],
              [-81.009645223389612, 35.422639497318727],
              [-81.009974346694563, 35.422681598929067],
              [-81.009869647764873, 35.423210991595916],
              [-81.010198774624257, 35.423253093474962],
              [-81.010041726773423, 35.424047181258118],
              [-81.00998937680761, 35.424311877110007],
              [-81.010318508188178, 35.424353978654096],
              [-81.010161459379717, 35.425148066401505],
              [-81.010490594214218, 35.425190166569543],
              [-81.010385895393341, 35.425719558490911],
              [-81.01071503265905, 35.425761658044621],
              [-81.010557985005008, 35.426555746061055],
              [-81.010887126848203, 35.42659784512184],
              [-81.010730079237518, 35.427391932314372],
              [-81.011059223456058, 35.427434030918654],
              [-81.010954525426357, 35.427963422169498],
              [-81.011283673177417, 35.428005520141056],
              [-81.011021927348764, 35.429328997850931],
              [-81.011351080666756, 35.429371095627019],
              [-81.011298731514003, 35.429635790834148],
              [-81.010969577150931, 35.429593692918445],
              [-81.010864875731883, 35.430123082939417],
              [-81.010535719619412, 35.430080983850807],
              [-81.010483366251947, 35.430345678682933],
              [-81.010154209435484, 35.430303578561023],
              [-81.010049501766701, 35.430832966894144],
              [-81.009720342099797, 35.430790865617553],
              [-81.009667986719606, 35.431055560038097],
              [-81.009338826348525, 35.43101345772827],
              [-81.009286469582065, 35.431278151971064],
              [-81.008957308484611, 35.431236047726657],
              [-81.008747871601713, 35.432294822894164],
              [-81.008418706686783, 35.432252718098717],
              [-81.008156897090998, 35.433576183935244],
              [-81.00782772726869, 35.433534076646723],
              [-81.007618267494266, 35.434592847560005],
              [-81.007289093854411, 35.434550739720457],
              [-81.007131992288237, 35.435344816410243],
              [-81.006802815831435, 35.435302706356772],
              [-81.006698077657276, 35.435832090346658],
              [-81.006368898372116, 35.435789980039566],
              [-81.006316527727819, 35.43605467183766],
              [-81.005987347716356, 35.436012559595909],
              [-81.00588260331331, 35.436541942798257],
              [-81.005553421552463, 35.436499829383536],
              [-81.005501047793814, 35.436764520787762],
              [-81.004842683227409, 35.436680291898973],
              [-81.004790307036799, 35.436944982985764],
              [-81.004131942846115, 35.43686075022439],
              [-81.004079563100007, 35.437125440110627],
              [-81.00375037993183, 35.437083322709363],
              [-81.003697998799169, 35.437348012417758],
              [-81.003368816028114, 35.437305893964904],
              [-81.003264050670268, 35.437835273888858],
              [-81.002934865026504, 35.437793153379836],
              [-81.00283009731389, 35.438322532853519],
              [-81.002500908819499, 35.438280411189574],
              [-81.002396137650692, 35.438809790230934],
              [-81.002066947406945, 35.43876766739394],
              [-81.001909783272282, 35.439561733925544],
              [-81.001580591357254, 35.439519610658799],
              [-81.001528201584208, 35.43978429928648],
              [-81.001199008942976, 35.439742174085019],
              [-81.001146616681979, 35.440006862552977],
              [-81.000488230332877, 35.439922609189232],
              [-81.000435835639706, 35.440187297339541],
              [-80.99944825446147, 35.440060911091066],
              [-80.999500652290777, 35.439796223359849],
              [-80.999171459551391, 35.439754092674271],
              [-80.99911906177789, 35.440018780247513],
              [-80.998789868356738, 35.439976649429646],
              [-80.998737469174344, 35.44024133592373],
              [-80.997420697959953, 35.440072800397658],
              [-80.997368293175498, 35.440337487213931],
              [-80.997039099348768, 35.440295350746517],
              [-80.997091505178574, 35.440030664069965],
              [-80.99643312284276, 35.439946388697074],
              [-80.996380714922225, 35.440211075094226],
              [-80.995722330760429, 35.440126795884964],
              [-80.995669920385524, 35.440391481063088],
              [-80.995340728322248, 35.440349339969103],
              [-80.995288315481233, 35.440614025888571],
              [-80.994959122714107, 35.440571883761017],
              [-80.994906708486198, 35.440836569502352],
              [-80.994248321863637, 35.440752281385265],
              [-80.994195905203554, 35.441016966808895],
              [-80.993866711370345, 35.440974821720516],
              [-80.993814293301128, 35.441239506064875],
              [-80.993485098764125, 35.441197359942997],
              [-80.993432679330084, 35.441462045010425],
              [-80.993103484067177, 35.441419896953846],
              [-80.99305106324627, 35.441684581843262],
              [-80.992392670575228, 35.441600283688473],
              [-80.992340247321991, 35.44186496826007],
              [-80.992011049913856, 35.441822818161917],
              [-80.991958625251527, 35.442087501654143],
              [-80.991300230448658, 35.442003197577741],
              [-80.991247803375956, 35.442267881653258],
              [-80.990918604879951, 35.442225727693064],
              [-80.990866176398242, 35.442490410689217],
              [-80.990536978321813, 35.442448256578579],
              [-80.990327254616247, 35.443506988558184],
              [-80.990222390702527, 35.444036353867105],
              [-80.990551596152571, 35.44407850879837],
              [-80.990446734064264, 35.444607874215293],
              [-80.990775941946808, 35.444650028532351],
              [-80.990723510334291, 35.444914711341028],
              [-80.991052720705284, 35.444956864885974],
              [-80.99100028979656, 35.445221547796116],
              [-80.991329501554645, 35.445263700587041],
              [-80.991014919560897, 35.446851796204918],
              [-80.990685701507871, 35.446809641674164],
              [-80.990528402739756, 35.447603687643657],
              [-80.990857623929287, 35.447645842593786],
              [-80.990700324099109, 35.448439887753423],
              [-80.991029548744805, 35.448482041327793],
              [-80.99092468404244, 35.449011404830593],
              [-80.991253911143104, 35.449053558691915],
              [-80.991149046041556, 35.449582921437141],
              [-80.991478275574934, 35.449625074684157],
              [-80.991425844096796, 35.449889755679173],
              [-80.991755075017522, 35.449931908171997],
              [-80.991650213149526, 35.45046127122729],
              [-80.991979446480912, 35.450503422204534],
              [-80.991927016090969, 35.450768104264775],
              [-80.992256250809746, 35.450810254487983],
              [-80.992151391074003, 35.451339617871156],
              [-80.991822153162687, 35.451297467386489],
              [-80.99171728886752, 35.451826830354086],
              [-80.992046528870063, 35.451868981118238],
              [-80.99188923354113, 35.45266302524017],
              [-80.991559990401569, 35.452620874056748],
              [-80.991455122689302, 35.453150236638812],
              [-80.991125877800243, 35.453108084282022],
              [-80.990968569948095, 35.453902126103692],
              [-80.990639322263689, 35.453859972433712],
              [-80.990534446929331, 35.45438933316963],
              [-80.990205196393944, 35.454347178344179],
              [-80.990152757719244, 35.454611858505466],
              [-80.989823506457753, 35.454569701745086],
              [-80.989771065294121, 35.454834381746053],
              [-80.989112562850835, 35.454750067048472],
              [-80.989060119254063, 35.45501474673118],
              [-80.988730868050169, 35.454972587892648],
              [-80.988678423043751, 35.455237266495821],
              [-80.988019918468552, 35.45515294587554],
              [-80.987967471051022, 35.455417625061671],
              [-80.987638217657633, 35.455375462378669],
              [-80.987533318604306, 35.455904820373547],
              [-80.987204064562874, 35.455862656498986],
              [-80.987099162028755, 35.456392013158478],
              [-80.986769905158212, 35.456349849029586],
              [-80.986717452872441, 35.456614526701962],
              [-80.986388195298133, 35.45657236153933],
              [-80.986335740523216, 35.456837039051273],
              [-80.985677225410171, 35.456752705746595],
              [-80.985624768202044, 35.457017382940222],
              [-80.98463699438679, 35.45689087496708],
              [-80.984689454710022, 35.456626197291641],
              [-80.984360198141275, 35.456584026625684],
              [-80.984412659168285, 35.456319349051384],
              [-80.984083403987142, 35.4562771776313],
              [-80.984135865717946, 35.456012500158174],
              [-80.983806611924379, 35.45597032798392],
              [-80.983859074358946, 35.455705650612018],
              [-80.983200572069876, 35.45562130292415],
              [-80.983148106442485, 35.455885980034459],
              [-80.982818854754072, 35.455843804259175],
              [-80.982766388818561, 35.456108480271766],
              [-80.982437135346913, 35.456066304382063],
              [-80.982384668023798, 35.456330980216073],
              [-80.982055413826529, 35.456288802391356],
              [-80.982002945137779, 35.456553478948074],
              [-80.981673690236704, 35.456511300089552],
              [-80.981621220138322, 35.456775975566593],
              [-80.98129196455551, 35.456733796575506],
              [-80.981239493069481, 35.456998471874037],
              [-80.980910236760991, 35.45695629094795],
              [-80.980857762785789, 35.457220966085913],
              [-80.980528506897187, 35.457178785009305],
              [-80.980476031534224, 35.457443459968708],
              [-80.980146773818419, 35.457401276975077],
              [-80.980094297067765, 35.457665951755928],
              [-80.978777266565487, 35.457497211165958],
              [-80.978724785290069, 35.457761885348603],
              [-80.978395527193015, 35.457719697605626],
              [-80.978448010615537, 35.457455023544846],
              [-80.978118753906216, 35.457412835047741],
              [-80.978171236930848, 35.457148161106055],
              [-80.976854218776353, 35.45697939781892],
              [-80.976524965924426, 35.456937204974231],
              [-80.976472477671479, 35.457201878216409],
              [-80.976143223014574, 35.457159684355858],
              [-80.976090732272283, 35.457424357437411],
              [-80.974115208523514, 35.457171173707131],
              [-80.974167705539642, 35.456906501464957],
              [-80.973838453255624, 35.456864300445787],
              [-80.973785955215746, 35.457128973449223],
              [-80.973456703329717, 35.4570867713783],
              [-80.973509202437029, 35.456822099415945],
              [-80.973179950859191, 35.456779897510195],
              [-80.973232450670068, 35.456515225649056],
              [-80.972903201559504, 35.456473022070085],
              [-80.972955702073847, 35.456208350310177],
              [-80.97229720699238, 35.456123941651732],
              [-80.972349709255752, 35.455859270133075],
              [-80.972020463262879, 35.455817064152271],
              [-80.972072966229661, 35.455552392734745],
              [-80.97075598988441, 35.455383561333939],
              [-80.97080849669122, 35.455118890437483],
              [-80.968833049825037, 35.454865617792393],
              [-80.968780535643432, 35.455130287866993],
              [-80.96845129446038, 35.455088072193512],
              [-80.968398778912828, 35.455352742990733],
              [-80.967740295482471, 35.455268308682321],
              [-80.967792813143035, 35.455003639066305],
              [-80.967134334274277, 35.454919201444497],
              [-80.967186853661801, 35.454654531168465],
              [-80.966199140511591, 35.454527868460694],
              [-80.966251662715209, 35.454263199467178],
              [-80.965922426361331, 35.454220976922869],
              [-80.965869903112278, 35.454485645776309],
              [-80.96488219406956, 35.454358972342362],
              [-80.964934721556574, 35.454094303891011],
              [-80.964605486571983, 35.454052077771223],
              [-80.964710541465053, 35.453522739247894],
              [-80.965039774358658, 35.453564965087587],
              [-80.965092299717284, 35.453300296538139],
              [-80.965421530806353, 35.453342521361925],
              [-80.965474055856845, 35.453077851714639],
              [-80.965803286242647, 35.453120075504607],
              [-80.966118417599716, 35.451532056960133],
              [-80.9657891934866, 35.451489834010211],
              [-80.9658417161193, 35.45122516319126],
              [-80.965512493393902, 35.451182939487531],
              [-80.965565015650114, 35.450918269689076],
              [-80.96523579431242, 35.45087604523146],
              [-80.965288317271771, 35.450611375534478],
              [-80.964959098401366, 35.450569149403975],
              [-80.965011622063855, 35.45030447980831],
              [-80.964353186382866, 35.450220026966328],
              [-80.964405711772045, 35.449955356710838],
              [-80.964076496041073, 35.449913129080279],
              [-80.964129022155149, 35.449648459827486],
              [-80.963799807790053, 35.449606230541931],
              [-80.963852334607282, 35.449341561390533],
              [-80.963523121651548, 35.449299332252373],
              [-80.963575649150044, 35.449034662301202],
              [-80.962917226334483, 35.448950200722408],
              [-80.962969756704567, 35.448685531896153],
              [-80.96264054574327, 35.448643299473353],
              [-80.962745607525278, 35.448113961084012],
              [-80.962416400120205, 35.448071728930849],
              [-80.962679051554119, 35.446748380765577],
              [-80.962349849717768, 35.446706148418833],
              [-80.962507439826666, 35.445912139473911],
              [-80.962178242548035, 35.44586990573454],
              [-80.962230773312044, 35.445605236143265],
              [-80.961901576341475, 35.445563002569187],
              [-80.962164229108538, 35.444239652951261],
              [-80.96183503878602, 35.444197418264586],
              [-80.96215021618795, 35.442609397767441],
              [-80.961821032478881, 35.442567163027398],
              [-80.961873561147939, 35.442302492511274],
              [-80.962202743811929, 35.44234472711134],
              [-80.962255272194852, 35.442080056398908],
              [-80.961926090597885, 35.442037822840135],
              [-80.962188730401763, 35.44071446943785],
              [-80.961859555452307, 35.44067223476663],
              [-80.961964610847929, 35.440142893420614],
              [-80.961635438330944, 35.440100658135762],
              [-80.961898075461974, 35.438777303005828],
              [-80.962227242753585, 35.438819537590355],
              [-80.962384818653575, 35.438025522896723],
              [-80.962713982050872, 35.438067756185404],
              [-80.962924073538034, 35.437009068541997],
              [-80.962976594998182, 35.436744396318907],
              [-80.962647435724861, 35.436702163748272],
              [-80.962699958989049, 35.436437491609176],
              [-80.962370801102963, 35.436395258284968],
              [-80.962475847593595, 35.435865914189762],
              [-80.961488385584076, 35.435739210543666],
              [-80.961540910900382, 35.43547453886768],
              [-80.9585785526926, 35.435094378232122],
              [-80.958526016870309, 35.435359048665312],
              [-80.955234531956251, 35.434936558928612],
              [-80.955287078228039, 35.434671889896414],
              [-80.953970499331945, 35.434502868799868],
              [-80.954023049441005, 35.43423820028994],
              [-80.949744221046046, 35.433688782530261],
              [-80.949796786598398, 35.433424115768439],
              [-80.948809376163908, 35.433297305195467],
              [-80.948756807498654, 35.433561972437992],
              [-80.947440261235258, 35.433392878598035],
              [-80.947387688026538, 35.433657544340342],
              [-80.947058551547485, 35.433615268501875],
              [-80.946953402012682, 35.434144599591214],
              [-80.946624263786475, 35.434102322578916],
              [-80.946519109712213, 35.434631654153641],
              [-80.946189969738782, 35.434589375967427],
              [-80.946137390581598, 35.434854041115308],
              [-80.945808249884379, 35.434811760994094],
              [-80.945755669339675, 35.435076425963459],
              [-80.945426527961757, 35.435034145709686],
              [-80.945479108450002, 35.43476948089819],
              [-80.943504271421787, 35.434515780586722],
              [-80.943451684664467, 35.434780444557028],
              [-80.942135128555748, 35.434611291394553],
              [-80.942082536175178, 35.434875954783315],
              [-80.941753397521168, 35.434833664560436],
              [-80.941805990946492, 35.434569001311992],
              [-80.94147685365833, 35.434526709434564],
              [-80.941529447785896, 35.434262046288076],
              [-80.941200311906854, 35.434219754558612],
              [-80.941410690249114, 35.433161101231349],
              [-80.941081558891909, 35.433118809169422],
              [-80.941239341353793, 35.432324818980604],
              [-80.939922834026319, 35.432155640741691],
              [-80.939870234949183, 35.432420304233638],
              [-80.939541108474501, 35.432378007065843],
              [-80.93948850798833, 35.432642669478021],
              [-80.939159379731933, 35.432600372195331],
              [-80.939106778959584, 35.43286503441135],
              [-80.938448521363753, 35.432780435983645],
              [-80.938501125305265, 35.43251577312931],
              [-80.938171998055466, 35.432473472264803],
              [-80.93822460272051, 35.432208810413641],
              [-80.937566352439717, 35.432124206267211],
              [-80.937618958851502, 35.431859544658401],
              [-80.937289834719238, 35.431817241394],
              [-80.937342441833096, 35.431552579887111],
              [-80.937013320189436, 35.431510275852006],
              [-80.937065927983781, 35.431245613545869],
              [-80.936736806626399, 35.431203308775174],
              [-80.936789415144204, 35.430938647472317],
              [-80.936460296275413, 35.430896341930762],
              [-80.936618122863308, 35.430102356411247],
              [-80.936289007471359, 35.430060050397081],
              [-80.936394225557976, 35.429530726205876],
              [-80.936065112598001, 35.429488419578945],
              [-80.935735999980864, 35.429446112058478],
              [-80.935788610599317, 35.429181450186199],
              [-80.935130389583591, 35.429096832728],
              [-80.935183001948658, 35.428832171098115],
              [-80.934195675557646, 35.428705237738541],
              [-80.934248290713725, 35.428440576491347],
              [-80.933590077304103, 35.428355950660062],
              [-80.93364269530781, 35.428091289637756],
              [-80.932326276737513, 35.427922026013078],
              [-80.932378897475559, 35.427657365531317],
              [-80.932049795295882, 35.427615047973759],
              [-80.931997172412039, 35.427879708332732],
              [-80.930351660267647, 35.427668103756034],
              [-80.930299031839652, 35.427932764276335],
              [-80.929311725088624, 35.427805790215167],
              [-80.929259092061542, 35.42807044939245],
              [-80.928929989812346, 35.428028122471943],
              [-80.928877356498859, 35.428292781453159],
              [-80.926902741777553, 35.42803880216406],
              [-80.926955382459383, 35.427774144007586],
              [-80.926297182003395, 35.427689476783137],
              [-80.926244539232485, 35.427954134658854],
              [-80.925915439046335, 35.427911800458638],
              [-80.925862794887863, 35.428176458155761],
              [-80.925533693978764, 35.428134122020488],
              [-80.925481048432886, 35.428398779538995],
              [-80.925151946822112, 35.428356442369925],
              [-80.925204593412616, 35.428091784991885],
              [-80.923229996116874, 35.427837744076292],
              [-80.923282648631528, 35.427573087502374],
              [-80.922953551510702, 35.427530744360482],
              [-80.923006205806658, 35.427266086970022],
              [-80.922677110073337, 35.42722374307516],
              [-80.922729763990887, 35.426959086705615],
              [-80.922400669645157, 35.426916742057806],
              [-80.922453325365296, 35.426652085773014],
              [-80.922124232407057, 35.426609740372186],
              [-80.922176887706215, 35.426345083305911],
              [-80.92184779723658, 35.426302737134655],
              [-80.921900453258502, 35.42603808107188],
              [-80.920913185918621, 35.425911036735535],
              [-80.920965844730858, 35.425646381055792],
              [-80.918333154878795, 35.425307556779288],
              [-80.918280486610044, 35.425572211352844],
              [-80.917622315213706, 35.4254874960737],
              [-80.917569643433112, 35.42575215124689],
              [-80.916911472400315, 35.425667431194718],
              [-80.916858798187846, 35.425932086048753],
              [-80.916200625337552, 35.42584736215975],
              [-80.916147949794208, 35.426112016677159],
              [-80.915818862288774, 35.426069653260903],
              [-80.915766184235508, 35.426334306715823],
              [-80.915437097129924, 35.426291942248284],
              [-80.915384417710328, 35.426556596425762],
              [-80.915055329903581, 35.42651423092439],
              [-80.914949967903439, 35.427043537081104],
              [-80.914620877249902, 35.427001170422876],
              [-80.914568194690673, 35.427265823303436],
              [-80.91423910443693, 35.427223455593911],
              [-80.914133735098901, 35.427752760976624],
              [-80.913804643120713, 35.427710392993994],
              [-80.913699270299617, 35.428239697041555],
              [-80.913370175453494, 35.428197327000682],
              [-80.91326480029295, 35.428726631497987],
              [-80.912935703701137, 35.428684260282829],
              [-80.912883014561615, 35.428948912333524],
              [-80.912224820318713, 35.428864166942041],
              [-80.912172127624629, 35.429128817789632],
              [-80.911843029973568, 35.429086443613301],
              [-80.911790337014125, 35.429351095165984],
              [-80.91113213955181, 35.429266343869593],
              [-80.911026747302884, 35.429795645414025],
              [-80.910697647548233, 35.429753268136032],
              [-80.910539552905519, 35.430547219301943],
              [-80.910210449259566, 35.430504840726414],
              [-80.910105048298504, 35.431034141342991],
              [-80.909775943986887, 35.430991760674409],
              [-80.909723241957451, 35.431256411235282],
              [-80.909394135864673, 35.431214030451386],
              [-80.909341432426231, 35.431478679932106],
              [-80.909012326733546, 35.431436298096962],
              [-80.908959621907158, 35.431700947398802],
              [-80.90830140834052, 35.431616179883285],
              [-80.90824869998066, 35.431880828883095],
              [-80.907919593228968, 35.431838444086623],
              [-80.907866883481233, 35.432103092907518],
              [-80.907537776028406, 35.432060707077142],
              [-80.907485064892796, 35.432325355719136],
              [-80.907155956738933, 35.432282968854807],
              [-80.907103244194232, 35.432547616416606],
              [-80.906774135339191, 35.432505228518409],
              [-80.906721421427719, 35.432769876802489],
              [-80.906392310770457, 35.432727487887554],
              [-80.906286880908198, 35.433256783141019],
              [-80.905957768505218, 35.433214393051578],
              [-80.905852334079086, 35.433743687887663],
              [-80.905523221031757, 35.433701296606394],
              [-80.905365063672434, 35.434495237700069],
              [-80.905035946733733, 35.434452845120994],
              [-80.904930504694804, 35.434982139010614],
              [-80.904601385989309, 35.434939744355788],
              [-80.904495939386138, 35.435469037827836],
              [-80.904166818956114, 35.435426642899635],
              [-80.904061369969952, 35.435955935018256],
              [-80.903732246671922, 35.435913538031521],
              [-80.90357406640257, 35.436707476417766],
              [-80.903244941415522, 35.436665078098628],
              [-80.903139483714654, 35.437194369287489],
              [-80.902810355901792, 35.437151970712307],
              [-80.902704894716479, 35.43768126056478],
              [-80.902375766259183, 35.437638860797648],
              [-80.902270300509187, 35.438168150232237],
              [-80.901941170306202, 35.438125749290542],
              [-80.901888435860755, 35.438390393358887],
              [-80.901559303855436, 35.438347991400349],
              [-80.901506568021674, 35.438612635289594],
              [-80.901177435315304, 35.438570232296996],
              [-80.901071961650359, 35.439099520562614],
              [-80.900742827177112, 35.439057115494194],
              [-80.900584609306236, 35.43985104675712],
              [-80.900913746913346, 35.439893452247333],
              [-80.900702789113353, 35.440952026986785],
              [-80.900373646226257, 35.440909620951331],
              [-80.900268163164711, 35.441438907357828],
              [-80.899939019654155, 35.441396501031747],
              [-80.899886276563251, 35.441661144036502],
              [-80.899227986239055, 35.441576326654662],
              [-80.89917524071501, 35.441840969339538],
              [-80.896871231438865, 35.44154408112469],
              [-80.896818478258425, 35.441808722786732],
              [-80.896489333928116, 35.441766306219876],
              [-80.896383822244005, 35.442295589181327],
              [-80.896054677269461, 35.442253171422493],
              [-80.895738127657538, 35.443841016733238],
              [-80.895408975657077, 35.443798597254393],
              [-80.895356215126583, 35.444063237262803],
              [-80.895685368171783, 35.4441056568823],
              [-80.895632608342282, 35.444370296992609],
              [-80.895303454273375, 35.444327878133748],
              [-80.895250693055374, 35.444592518064852],
              [-80.894921537163199, 35.444550097287689],
              [-80.894868774556599, 35.444814737039486],
              [-80.894539619064815, 35.444772315210976],
              [-80.89443408965063, 35.445301595235648],
              [-80.894104932413043, 35.445259172232319],
              [-80.894052166685682, 35.445523811586774],
              [-80.89372300874723, 35.445481387549272],
              [-80.893617473070123, 35.446010665877864],
              [-80.893288312284582, 35.445968240682809],
              [-80.893077232625572, 35.447026796312841],
              [-80.892748068004764, 35.446984369661621],
              [-80.892642524001616, 35.447513646061694],
              [-80.892313357635061, 35.44747121823562],
              [-80.892207809065539, 35.448000494216593],
              [-80.891878642054621, 35.447958065198478],
              [-80.891667536610043, 35.449016616132639],
              [-80.891996708901644, 35.449059045696302],
              [-80.891891156205816, 35.449588321212083],
              [-80.892220330930968, 35.449630750163521],
              [-80.892009225550154, 35.45068929948971],
              [-80.891680046645448, 35.450646869975643],
              [-80.891627268395652, 35.450911507069428],
              [-80.890968908447846, 35.450826645997708],
              [-80.890916127743409, 35.45109128187017],
              [-80.890257767060604, 35.451006416041487],
              [-80.890204983943377, 35.451271052495052],
              [-80.889875802522269, 35.451228618108203],
              [-80.88982301801633, 35.451493254382264],
              [-80.889493835894186, 35.451450818961142],
              [-80.889441049999419, 35.451715455055684],
              [-80.889111867176339, 35.451673018600353],
              [-80.889059079892746, 35.451937654515312],
              [-80.888400713167925, 35.451852777741202],
              [-80.888295132287709, 35.452382048909065],
              [-80.88796594734103, 35.452339608449599],
              [-80.887754776150814, 35.453398148872004],
              [-80.887425587368512, 35.453355706956124],
              [-80.887214405372092, 35.454414245309451],
              [-80.886885212775042, 35.454371802838438],
              [-80.886832415360402, 35.454636436738205],
              [-80.887819998420113, 35.454763761875171],
              [-80.887767203817234, 35.45502839705938],
              [-80.888096399536337, 35.455070837130918],
              [-80.888043605613447, 35.455335471515525],
              [-80.889031200172496, 35.455462786756051],
              [-80.889083989858875, 35.455198151966435],
              [-80.889742385494472, 35.455283024049656],
              [-80.889584019470163, 35.456076929180007],
              [-80.889254818896219, 35.45603449315459],
              [-80.889202028121346, 35.456299127985233],
              [-80.88887282684631, 35.4562566909255],
              [-80.888820034682524, 35.456521325576539],
              [-80.888490831604983, 35.456478887499642],
              [-80.888438038052087, 35.456743521971013],
              [-80.888108835375164, 35.456701082842713],
              [-80.888056040412195, 35.456965716233114],
              [-80.887726835932767, 35.456923276087615],
              [-80.887674039601805, 35.457187910199707],
              [-80.888003245126257, 35.457230350485887],
              [-80.887950449496273, 35.457494984699714],
              [-80.888279656409765, 35.457537424232918],
              [-80.888226861480774, 35.457802058548445],
              [-80.88888527843001, 35.457886935215328],
              [-80.888832485226018, 35.45815156887199],
              [-80.889161695261762, 35.458194006005634],
              [-80.889108902779853, 35.458458640665143],
              [-80.889767327074836, 35.458543512515561],
              [-80.889608954894953, 35.459337415284665],
              [-80.889279738889456, 35.459294979392837],
              [-80.889121360478256, 35.460088881388934],
              [-80.888792141681421, 35.46004644418138],
              [-80.888739347871933, 35.460311077649507],
              [-80.888410128374119, 35.460268639407566],
              [-80.888357332073909, 35.46053327271315],
              [-80.888028111874974, 35.460490833436928],
              [-80.887975314185596, 35.460755466562695],
              [-80.887646094387279, 35.460713026234949],
              [-80.887593295308648, 35.460977659181005],
              [-80.887922516152159, 35.461020099649495],
              [-80.887764119987381, 35.461813998675495],
              [-80.888093345411818, 35.461856438655197],
              [-80.888040547047339, 35.462121071726365],
              [-80.888369773860916, 35.462163510953019],
              [-80.888316976176569, 35.462428143224592],
              [-80.888646204379455, 35.462470581698135],
              [-80.888593407417048, 35.462735214972575],
              [-80.888922637009159, 35.46277765269307],
              [-80.888658652199027, 35.464100816478357],
              [-80.888329417401877, 35.464058378955791],
              [-80.888118218065472, 35.465116908193586],
              [-80.888447458165942, 35.465159347163073],
              [-80.888236257483882, 35.466217875436151],
              [-80.888565502109415, 35.466260314074468],
              [-80.888459902345176, 35.466789578248864],
              [-80.888789149405341, 35.466832016274701],
              [-80.88873635003138, 35.467096647542554],
              [-80.889065598480897, 35.467139084815393],
              [-80.889012799829089, 35.467403717085908],
              [-80.889342049668087, 35.467446153605614],
              [-80.889289251696425, 35.467710785076413],
              [-80.889618502924804, 35.467753220842901],
              [-80.889565705675295, 35.468017853316418],
              [-80.889894959373692, 35.46806028741144],
              [-80.889842162825516, 35.468324919986365],
              [-80.890171416832672, 35.468367354246645],
              [-80.890065824773643, 35.468896618658981],
              [-80.890395082296251, 35.468939051388332],
              [-80.890342286806387, 35.46920368412691],
              [-80.890671545739323, 35.469246117004374],
              [-80.890565955776268, 35.469775380842783],
              [-80.890895217143978, 35.469817813107646],
              [-80.890842422691705, 35.470082445108645],
              [-80.891171685448953, 35.470124876620289],
              [-80.891118891697872, 35.470389508722718],
              [-80.891448155823497, 35.470431938579992],
              [-80.891395363896507, 35.470696571667901],
              [-80.891724629411598, 35.4707390007719],
              [-80.891671837063328, 35.471003633077096],
              [-80.892001103988861, 35.471046062329165],
              [-80.891948313443478, 35.471310694718547],
              [-80.892277581737559, 35.471353122316195],
              [-80.891696865674092, 35.474264075888428],
              [-80.892026146913949, 35.474306504121962],
              [-80.891920559178658, 35.474835767613953],
              [-80.892249842874492, 35.474878196135997],
              [-80.892038668537737, 35.475936722291948],
              [-80.89236795784052, 35.475979149564147],
              [-80.892262370137132, 35.476508412245089],
              [-80.89193307984506, 35.476465984674455],
              [-80.891880284431707, 35.4767306158152],
              [-80.891550992336391, 35.476688187227218],
              [-80.891498195533387, 35.476952818188032],
              [-80.890839611364768, 35.47686795713053],
              [-80.890681212617523, 35.477661848932819],
              [-80.891010508206904, 35.477704279871247],
              [-80.890852109499079, 35.478498171740775],
              [-80.890522810752088, 35.478455739479202],
              [-80.890258799650951, 35.479778889318141],
              [-80.889929494939153, 35.479736456377893],
              [-80.889876690848595, 35.48000108554254],
              [-80.889547386537302, 35.479958651550611],
              [-80.889494579955098, 35.480223280552316],
              [-80.888177361948379, 35.480053534181074],
              [-80.888124550839507, 35.48031816258073],
              [-80.887465940697851, 35.480233283316657],
              [-80.887413128255375, 35.4804979113784],
              [-80.887083822114903, 35.480455470723967],
              [-80.887031008282477, 35.480720098605573],
              [-80.886701701440629, 35.480677656916498],
              [-80.886648885116571, 35.480942284635127],
              [-80.886319578654266, 35.48089984099304],
              [-80.88626676094033, 35.481164468531482],
              [-80.885937453797624, 35.481122024756061],
              [-80.885884633591843, 35.481386652131484],
              [-80.885226018227101, 35.481301760716228],
              [-80.885173195585864, 35.481566387770798],
              [-80.884843887363843, 35.481523940130963],
              [-80.884791064434538, 35.481788566988165],
              [-80.884461754409259, 35.481746118330818],
              [-80.884408930090004, 35.482010745007827],
              [-80.883750310104119, 35.481925845613951],
              [-80.883697482247342, 35.482190471987259],
              [-80.883368172265747, 35.482148020349555],
              [-80.883315343019063, 35.482412646542556],
              [-80.882986032336092, 35.482370193870224],
              [-80.882933201699444, 35.482634819883074],
              [-80.882603889213414, 35.482592366193103],
              [-80.882551057186745, 35.482856992025717],
              [-80.881892432258425, 35.482772081665424],
              [-80.881839597796088, 35.483036707177057],
              [-80.881180972153928, 35.482951792959504],
              [-80.88123380870752, 35.48268716772921],
              [-80.88024587541338, 35.482559790128882],
              [-80.880298715861215, 35.482295165264127],
              [-80.879969406130314, 35.482252704422471],
              [-80.880022246198422, 35.481988080577302],
              [-80.879034324391966, 35.481860692175466],
              [-80.87898148120783, 35.482125316499783],
              [-80.878322865947808, 35.48204038675393],
              [-80.878270020328145, 35.482305010757166],
              [-80.877940711597432, 35.482262543509897],
              [-80.877887864587706, 35.482527167332947],
              [-80.877558555176719, 35.482484699952167],
              [-80.87750570677683, 35.482749323594909],
              [-80.876847087978135, 35.482664384951789],
              [-80.87679423604078, 35.482929008290554],
              [-80.876464925551218, 35.482886537045168],
              [-80.876412072223673, 35.483151160203619],
              [-80.875753451309578, 35.483066215633784],
              [-80.875700595546391, 35.483330838471161],
              [-80.875371285121801, 35.483288365146628],
              [-80.875318427947676, 35.483552986902424],
              [-80.874989115720197, 35.483510512560208],
              [-80.874936257155937, 35.483775134135669],
              [-80.873289698120828, 35.483562746475101],
              [-80.873236832882199, 35.483827367324118],
              [-80.872248899324148, 35.483699922843421],
              [-80.872196030624863, 35.483964544131751],
              [-80.870878785781457, 35.483794604790042],
              [-80.870825912534315, 35.484059224574331],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 32,
        ORIG_FID: 1,
        AAPC_Examp: 7,
        Area_mile2: 344,
        BASIN_NAME: "Lake Norman",
        Res_Basin: "Norman",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.05989427257515, 35.82844287222516],
              [-81.059999180588292, 35.827913614404537],
              [-81.059668274555335, 35.827871665943306],
              [-81.059337368865783, 35.827829716583224],
              [-81.059389825575806, 35.827565087861657],
              [-81.059058920179083, 35.827523137759975],
              [-81.059111377601539, 35.827258509130395],
              [-81.058449572163326, 35.827174606470244],
              [-81.058502031354323, 35.826909978071441],
              [-81.057840229401691, 35.82682607209302],
              [-81.057892689254473, 35.826561443943632],
              [-81.057230891893965, 35.826477534628296],
              [-81.057283354621745, 35.826212906690955],
              [-81.056290662641885, 35.826087035519322],
              [-81.056343128193788, 35.8258224079513],
              [-81.055681338468531, 35.825738489300797],
              [-81.055733805788819, 35.825473861963616],
              [-81.055402913061897, 35.825431901869962],
              [-81.055455381094575, 35.825167274624853],
              [-81.055124489744173, 35.825125312869865],
              [-81.055176958489213, 35.824860685716985],
              [-81.054846067454577, 35.824818724121606],
              [-81.054898538018378, 35.824554097042224],
              [-81.054567648360162, 35.824512133785532],
              [-81.054620119636311, 35.824247506798272],
              [-81.05428923137751, 35.824205542781449],
              [-81.054341703366006, 35.823940915886404],
              [-81.054010817635813, 35.823898951991858],
              [-81.054063289207235, 35.82363432430656],
              [-81.053732404876357, 35.823592359651833],
              [-81.053784877182977, 35.823327732959868],
              [-81.053837350229756, 35.823063105301621],
              [-81.053506468354001, 35.823021140025411],
              [-81.053558941006514, 35.822756512478101],
              [-81.053228060530031, 35.822714546441638],
              [-81.053280535001377, 35.822449918967926],
              [-81.052949655924166, 35.82240795217141],
              [-81.053002131107689, 35.822143324789913],
              [-81.052671253429764, 35.822101357233301],
              [-81.052776203794295, 35.821572103528098],
              [-81.052445328548501, 35.821530134448878],
              [-81.052497804813058, 35.821265507205474],
              [-81.052166932095773, 35.821223538248645],
              [-81.052219407966106, 35.820958911116158],
              [-81.049572444691364, 35.820623122845703],
              [-81.049624929770204, 35.820358496757592],
              [-81.049294062233443, 35.820316519088081],
              [-81.049346548047254, 35.820051893993508],
              [-81.048023085558796, 35.819883974882849],
              [-81.048075576335378, 35.8196193493769],
              [-81.047744712627917, 35.819577367491057],
              [-81.04784969307147, 35.819048117555937],
              [-81.047518832925334, 35.819006135030172],
              [-81.04762381408284, 35.818476884285801],
              [-81.047292956391559, 35.818434901138751],
              [-81.047450429824323, 35.817641025072049],
              [-81.04711957564345, 35.817599041442449],
              [-81.047172066787255, 35.81733441613914],
              [-81.046510360461127, 35.817250446479868],
              [-81.046562854478992, 35.81698582138911],
              [-81.046232003417316, 35.816943834439705],
              [-81.046179508343869, 35.817208459391786],
              [-81.045848656593037, 35.817166472306106],
              [-81.045901151615666, 35.816901847511311],
              [-81.045239451256023, 35.816817870921327],
              [-81.045291949152457, 35.816553246339119],
              [-81.044961100520837, 35.816511255933655],
              [-81.045013599129192, 35.816246631443938],
              [-81.044682751919566, 35.81620464117966],
              [-81.044735250133513, 35.815940016800965],
              [-81.04440440430028, 35.81589802487558],
              [-81.044456904332421, 35.815633400570668],
              [-81.044126061027526, 35.815591408767958],
              [-81.044178560665173, 35.815326784574133],
              [-81.043847718736657, 35.815284791110329],
              [-81.04390022019264, 35.815020166990408],
              [-81.042907697468308, 35.814894183462684],
              [-81.04285519284592, 35.815158807166405],
              [-81.04186267002548, 35.81503281423317],
              [-81.041915177814346, 35.814768190945856],
              [-81.041584338263448, 35.814726192249815],
              [-81.041689356004781, 35.814196944894093],
              [-81.041358519992144, 35.81415494465729],
              [-81.041463537386008, 35.813625698313665],
              [-81.041132703827984, 35.813583697455755],
              [-81.041237723041561, 35.813054450284952],
              [-81.040906891938121, 35.813012448806049],
              [-81.040959401542963, 35.812747825749859],
              [-81.040628571838639, 35.812705823511038],
              [-81.040681083261632, 35.812441200528887],
              [-81.040350254956337, 35.81239919753024],
              [-81.040455277752955, 35.811869950822128],
              [-81.040124451902187, 35.811827947202431],
              [-81.040176963275712, 35.811563323476548],
              [-81.039846139953283, 35.811521319979626],
              [-81.039898652038488, 35.811256696346241],
              [-81.039567830092324, 35.811214691188461],
              [-81.039620342912016, 35.810950068548777],
              [-81.03928952236484, 35.810908062631135],
              [-81.03934203587346, 35.810643439182968],
              [-81.039011216725356, 35.810601432505479],
              [-81.039063732051929, 35.810336809131343],
              [-81.038732913196682, 35.810294801712672],
              [-81.038785429257516, 35.810030179332273],
              [-81.038454612907628, 35.809988171135281],
              [-81.038507128551132, 35.809723547964865],
              [-81.038176313600317, 35.809681539008025],
              [-81.03822883106163, 35.809416915911797],
              [-81.037898017509846, 35.809374906195181],
              [-81.037950535682839, 35.809110283191593],
              [-81.037619722423727, 35.809068272733668],
              [-81.037672241308286, 35.808803649822693],
              [-81.037341430554605, 35.808761638586567],
              [-81.03739395015063, 35.808497015768246],
              [-81.037063140795965, 35.808455003772387],
              [-81.037168181067457, 35.807925758274827],
              [-81.036837375273379, 35.807883745639636],
              [-81.036889895948732, 35.807619122960411],
              [-81.036559090447369, 35.807577109583917],
              [-81.036611611834331, 35.807312486997425],
              [-81.036280807731885, 35.807270472861134],
              [-81.036385851562258, 35.806741226926277],
              [-81.036055051020398, 35.806699212150697],
              [-81.036107573486589, 35.8064345897034],
              [-81.035776774343688, 35.806392574168171],
              [-81.035829297498566, 35.806127950912455],
              [-81.035498499754652, 35.806085934617464],
              [-81.034506107456551, 35.805959879458698],
              [-81.034558634511171, 35.805695257613387],
              [-81.033897044204991, 35.80561121631326],
              [-81.033949573026206, 35.805346594699557],
              [-81.03361877945531, 35.805304573291181],
              [-81.033671310071483, 35.805039950850599],
              [-81.03334051789956, 35.804997928682582],
              [-81.033393048143623, 35.804733307254381],
              [-81.033062257347979, 35.80469128342547],
              [-81.033114789409552, 35.804426662071592],
              [-81.030137699520154, 35.804048411996419],
              [-81.030190241817664, 35.803783790926886],
              [-81.028536321278764, 35.803573621026736],
              [-81.02820553775112, 35.803531583998129],
              [-81.02825808605796, 35.803266964617137],
              [-81.026934961819222, 35.803098808036779],
              [-81.026987514002457, 35.802834189165381],
              [-81.026656735410725, 35.802792147903837],
              [-81.026814391910705, 35.801998289645923],
              [-81.02648361682806, 35.801956247902758],
              [-81.026588721168082, 35.801427008517116],
              [-81.026257949645597, 35.801384966134904],
              [-81.026363054742575, 35.800855727744079],
              [-81.026032284567847, 35.800813684759753],
              [-81.026137391482649, 35.800284445543042],
              [-81.025806623761838, 35.800242401938171],
              [-81.025859177758434, 35.799977782399793],
              [-81.025528411436525, 35.799935738035288],
              [-81.02563352050781, 35.799406499098339],
              [-81.02530275774582, 35.799364454094999],
              [-81.025355312820579, 35.799099834696392],
              [-81.025024550328865, 35.799057788050888],
              [-81.025077106114551, 35.798793168745235],
              [-81.024746346150408, 35.798751122222924],
              [-81.024798902647106, 35.798486503010302],
              [-81.024137385860527, 35.798402407548416],
              [-81.024189944123222, 35.798137788567715],
              [-81.023197674665056, 35.798011638152602],
              [-81.023250235748534, 35.797747019542804],
              [-81.022919480990211, 35.79770496714584],
              [-81.02286691887447, 35.797969586517894],
              [-81.022536163405306, 35.797927533083573],
              [-81.022483598762008, 35.798192151387802],
              [-81.022152842604356, 35.798150097817306],
              [-81.022100277668315, 35.798414715918213],
              [-81.021769520799864, 35.798372661310296],
              [-81.021716953358663, 35.798637279244645],
              [-81.021055438521387, 35.798553166154463],
              [-81.021002868626454, 35.798817783764868],
              [-81.02001059579716, 35.798691607425226],
              [-81.020063168856893, 35.798426990231881],
              [-81.018409392933435, 35.798216677858157],
              [-81.018461970923454, 35.797952061313758],
              [-81.018131217816858, 35.797909995922375],
              [-81.018183797623806, 35.797645379452767],
              [-81.017191543555541, 35.79751917920656],
              [-81.017244126183073, 35.797254563108005],
              [-81.016913376562414, 35.797212494401172],
              [-81.016965959900688, 35.796947878395656],
              [-81.016304463801447, 35.796863738565008],
              [-81.016357048905277, 35.796599122791505],
              [-81.016026303532811, 35.796557051649309],
              [-81.016078888241125, 35.796292435987269],
              [-81.01574814426759, 35.796250364085758],
              [-81.015800729686703, 35.795985748516763],
              [-81.015139244881453, 35.795901602296794],
              [-81.015191832043598, 35.79563698605876],
              [-81.014199610581088, 35.795510760408348],
              [-81.014252201691889, 35.795246145424315],
              [-81.013921462947465, 35.79520406854995],
              [-81.013974054768994, 35.794939453659005],
              [-81.013643317423487, 35.794897376025361],
              [-81.013695908826989, 35.794632760344683],
              [-81.013365173986472, 35.794590681933435],
              [-81.013680727084548, 35.793002989430327],
              [-81.013349998916496, 35.792960910955102],
              [-81.01340259002049, 35.792696295231181],
              [-81.013071863251284, 35.792654215996734],
              [-81.013124456194419, 35.792389601248757],
              [-81.012793730801519, 35.792347520353943],
              [-81.012846324455211, 35.792082905699225],
              [-81.011854152444386, 35.791956659862819],
              [-81.011906748895512, 35.791692044678292],
              [-81.010914584252191, 35.791565791156359],
              [-81.010967183523007, 35.791301176343204],
              [-81.010305744272642, 35.791217003716085],
              [-81.010358345308632, 35.790952389135171],
              [-81.009696909521793, 35.790868212292068],
              [-81.009749513428901, 35.790603597925298],
              [-81.009418797117547, 35.790561508745995],
              [-81.009471401735055, 35.790296894472384],
              [-81.009140686800208, 35.79025480363277],
              [-81.009193292128018, 35.789990189452496],
              [-81.00853186542254, 35.789906006257851],
              [-81.008584472515338, 35.789641392309939],
              [-81.008253760722312, 35.789599299053791],
              [-81.008306368525453, 35.789334685199144],
              [-81.007975659259628, 35.789292592066765],
              [-81.008028266667097, 35.789027978323652],
              [-81.007697558777679, 35.788985883531005],
              [-81.007750168001593, 35.788721269862855],
              [-81.007088754258561, 35.788637077879429],
              [-81.007141365247236, 35.788372464443711],
              [-81.006810660521879, 35.788330368135767],
              [-81.006863272198572, 35.788065753892269],
              [-81.006201865867411, 35.787981557958936],
              [-81.006254479331261, 35.78771694484896],
              [-81.005593076463867, 35.787632744699849],
              [-81.005645691692379, 35.787368131822355],
              [-81.00498429231105, 35.78728392835869],
              [-81.005036909304295, 35.787019315713678],
              [-81.004375514514948, 35.786935108917291],
              [-81.004428133250613, 35.786670495603616],
              [-81.00376674196977, 35.78658628639382],
              [-81.003819362470011, 35.786321673312763],
              [-81.003488667836336, 35.786279567058294],
              [-81.003541290175036, 35.786014954953508],
              [-81.002879905155467, 35.785930740010052],
              [-81.002932529236446, 35.785666127236745],
              [-81.002601837744564, 35.785624018565976],
              [-81.002707085898876, 35.78509479407959],
              [-81.002759710001342, 35.784830181308166],
              [-81.003090397223986, 35.784872289579589],
              [-81.003143019950159, 35.784607677524257],
              [-81.003473706462671, 35.784649784758336],
              [-81.003631567133084, 35.783855945233491],
              [-81.00396225193272, 35.783898051133633],
              [-81.004014870066925, 35.783633438635277],
              [-81.004345553050484, 35.783675543516374],
              [-81.004398169763704, 35.783410929931932],
              [-81.004728852037047, 35.7834530337757],
              [-81.004834083427895, 35.782923806173166],
              [-81.004503403263328, 35.782881702607725],
              [-81.004556018943873, 35.782617088886177],
              [-81.004225340177925, 35.782574984561812],
              [-81.004277956568487, 35.782310370933665],
              [-81.003947280307074, 35.782268265832023],
              [-81.004052514164187, 35.781739038717042],
              [-81.003721840355695, 35.7816969329957],
              [-81.003774457822303, 35.781432319508795],
              [-81.003443785412401, 35.781390213028409],
              [-81.00354902139901, 35.780860985294851],
              [-81.003218351441987, 35.780818878194736],
              [-81.003323589288527, 35.780289651439809],
              [-81.002992921784269, 35.780247543719874],
              [-81.0031507785598, 35.779453702770574],
              [-81.003481442901119, 35.779495810073001],
              [-81.003534059699959, 35.779231195598008],
              [-81.003864723331233, 35.779273301863149],
              [-81.004180411742894, 35.777685615905668],
              [-81.003849754437127, 35.777643510475627],
              [-81.003902368826473, 35.777378895681323],
              [-81.002910404348782, 35.777252574383738],
              [-81.002963021556553, 35.77698795996146],
              [-81.002632368841873, 35.776945851199216],
              [-81.002684986759533, 35.776681236870523],
              [-81.002354335443485, 35.776639127349412],
              [-81.0024595734603, 35.776109898815442],
              [-81.002128924596761, 35.776067788674624],
              [-81.002339399610491, 35.77500933163487],
              [-81.002008755307841, 35.774967221152835],
              [-81.002061375084281, 35.77470260690454],
              [-81.002392018332699, 35.774744717247465],
              [-81.00260248646201, 35.773686259296959],
              [-81.002933124732493, 35.773728368203173],
              [-81.003038355176457, 35.77319913866733],
              [-81.003368991682834, 35.77324124639722],
              [-81.003579443096115, 35.772182785339865],
              [-81.003248809700224, 35.772140678184883],
              [-81.003354034885874, 35.771611447220174],
              [-81.002692775532381, 35.771527229835492],
              [-81.002745389728162, 35.771262615013825],
              [-81.002084133859441, 35.771178394315463],
              [-81.002136749796918, 35.770913778825665],
              [-81.001144872854468, 35.770787441893248],
              [-81.001197491632126, 35.770522827676693],
              [-81.000536244527382, 35.770438598537176],
              [-81.000483622535995, 35.770703212493402],
              [-80.998499880948117, 35.770450502724209],
              [-80.998552509241279, 35.770185888702152],
              [-80.997891267644547, 35.770101645195041],
              [-80.997943897701504, 35.76983703140592],
              [-80.996952040486505, 35.769710659386661],
              [-80.997004673361019, 35.769446045969914],
              [-80.996343440528932, 35.76936179402194],
              [-80.996012823512487, 35.769319666268586],
              [-80.995960187475987, 35.769584279267654],
              [-80.995629569749923, 35.769542150477086],
              [-80.995524293408153, 35.770071375177288],
              [-80.99519367502424, 35.770029245192106],
              [-80.995141034741152, 35.770293857794378],
              [-80.994479795792572, 35.770209594869804],
              [-80.994427154140752, 35.770474206228826],
              [-80.993104675054454, 35.770305669065131],
              [-80.993157322027898, 35.77004105824512],
              [-80.992826705000581, 35.769998921835004],
              [-80.992879352661362, 35.769734310207646],
              [-80.990895661819863, 35.769481472860853],
              [-80.990948315482001, 35.769216862924964],
              [-80.989625866364207, 35.769048286084562],
              [-80.98967852500337, 35.768783676642357],
              [-80.989017305171828, 35.768699383104263],
              [-80.989069965552034, 35.768434772994034],
              [-80.988739357217966, 35.768392625468039],
              [-80.988792017201931, 35.768128015469728],
              [-80.988461410266112, 35.768085867185043],
              [-80.988514072065229, 35.767821257262675],
              [-80.988183466505603, 35.767779107318241],
              [-80.988288791200148, 35.767249888516936],
              [-80.988619394629836, 35.767292037281528],
              [-80.988777373704039, 35.766498207906558],
              [-80.989107974316681, 35.766540355355296],
              [-80.98916063299518, 35.766275745021076],
              [-80.989491231792698, 35.766317891450583],
              [-80.989596544902895, 35.765788670385369],
              [-80.989265948212903, 35.765746524234515],
              [-80.989318604752356, 35.765481913782224],
              [-80.988988009438529, 35.765439765971557],
              [-80.989040666687259, 35.765175155613143],
              [-80.988710072793822, 35.765133007945082],
              [-80.988815389449073, 35.764603786451715],
              [-80.988484798007661, 35.764561638164459],
              [-80.988537457436308, 35.764297027930539],
              [-80.988206867370948, 35.764254877983646],
              [-80.98831218619685, 35.763725657676574],
              [-80.987981598583346, 35.7636835071104],
              [-80.988034259086263, 35.763418897019193],
              [-80.987703672870964, 35.763376745694494],
              [-80.987756334083173, 35.763112135697305],
              [-80.987425749266137, 35.763069983614095],
              [-80.987478411187524, 35.762805373710918],
              [-80.98681724579987, 35.762721067111627],
              [-80.986869909484298, 35.762456457441722],
              [-80.986539327808003, 35.762414302943768],
              [-80.986591992201681, 35.762149693367881],
              [-80.985930833095637, 35.762065381939053],
              [-80.985983499252129, 35.761800772596665],
              [-80.985652921822393, 35.761758615665791],
              [-80.985705588665951, 35.761494005516234],
              [-80.985044436969375, 35.761409690140987],
              [-80.985097105575804, 35.761145080224914],
              [-80.984766531286851, 35.76110292087931],
              [-80.984819200602445, 35.760838311057242],
              [-80.984488627733754, 35.760796151854443],
              [-80.984541297758568, 35.760531542126451],
              [-80.97759937531319, 35.759645971765089],
              [-80.9776520682246, 35.759381364901351],
              [-80.977321506127211, 35.759339185306835],
              [-80.977374199747544, 35.759074578537174],
              [-80.977043639048247, 35.759032398184317],
              [-80.977096333377574, 35.758767791508852],
              [-80.976765774054527, 35.758725609496423],
              [-80.97687116491376, 35.758196397171922],
              [-80.976540608042427, 35.758154214540639],
              [-80.976645999608081, 35.757625001412755],
              [-80.976315445210417, 35.757582819063778],
              [-80.976526229503605, 35.756524391903959],
              [-80.976195679664542, 35.756482209215029],
              [-80.976301071286443, 35.755952995201028],
              [-80.975639977939593, 35.755868626768816],
              [-80.975692675904213, 35.755604020414509],
              [-80.975362130237471, 35.75556183454961],
              [-80.975414828910999, 35.755297228289635],
              [-80.974753741823832, 35.755212854127763],
              [-80.97480644225945, 35.754948248101464],
              [-80.974475900839124, 35.754906059804483],
              [-80.974581301655618, 35.754376847012054],
              [-80.974250762708763, 35.754334658997436],
              [-80.974408866156139, 35.753540839410967],
              [-80.974078330692137, 35.753498650015906],
              [-80.974183733381153, 35.752969437311826],
              [-80.973853200368481, 35.752927247298032],
              [-80.973905902260384, 35.752662641468369],
              [-80.97225324873969, 35.752451678613802],
              [-80.972200541603016, 35.752716284647235],
              [-80.970878420976902, 35.752547496742842],
              [-80.970825709282281, 35.752812102173138],
              [-80.966528843589799, 35.752263440672657],
              [-80.966581571188527, 35.751998837020459],
              [-80.965920522450105, 35.751914413883789],
              [-80.965973250705048, 35.751649810483308],
              [-80.964981685012631, 35.751523169888451],
              [-80.965034416082517, 35.751258566861566],
              [-80.964373376111098, 35.751174135288863],
              [-80.964478842534817, 35.750644929640011],
              [-80.964148325183814, 35.75060271323693],
              [-80.964201059484367, 35.750338110475511],
              [-80.963870543509572, 35.750295892413206],
              [-80.963923278518465, 35.750031289746246],
              [-80.963592763963632, 35.749989071827073],
              [-80.963645499680865, 35.749724469254566],
              [-80.96298447366847, 35.749640029200272],
              [-80.963037211147238, 35.749375426861754],
              [-80.962706699733417, 35.749333206528895],
              [-80.962759437920482, 35.749068604284929],
              [-80.962098419295529, 35.748984159385536],
              [-80.962151159244144, 35.74871955737558],
              [-80.961820652054882, 35.748677333710141],
              [-80.961873392711666, 35.748412731794744],
              [-80.961542886920526, 35.748370507371298],
              [-80.961595628285579, 35.748105905550432],
              [-80.960934618738861, 35.748021454307917],
              [-80.96098736186525, 35.747756852721068],
              [-80.960656859215035, 35.747714625884122],
              [-80.960709603049665, 35.747450024391867],
              [-80.960048600890403, 35.74736556830451],
              [-80.96010134648634, 35.747100967046364],
              [-80.959109848993648, 35.746974276602401],
              [-80.95916259848768, 35.746709674799128],
              [-80.958501603990086, 35.746625210294624],
              [-80.958554355267069, 35.746360609626635],
              [-80.957232375707392, 35.746191669486521],
              [-80.957285130851687, 35.745927069332097],
              [-80.956624144684142, 35.745842593714308],
              [-80.956676901589631, 35.745577993794129],
              [-80.956346411170898, 35.745535754310211],
              [-80.956399168784429, 35.745271154484648],
              [-80.955407700590072, 35.745144432904645],
              [-80.955460462101343, 35.744879832533975],
              [-80.955129974085096, 35.744837590658264],
              [-80.955235497094776, 35.744308390980187],
              [-80.955565981899582, 35.744350632594433],
              [-80.955671500317905, 35.743821432474739],
              [-80.955341017618991, 35.743779191139829],
              [-80.955393777352811, 35.743514590701601],
              [-80.954732815074252, 35.743430104717419],
              [-80.954680053234583, 35.743694704876404],
              [-80.954019090230148, 35.743610215022983],
              [-80.954071854175766, 35.743345615143291],
              [-80.953741374233203, 35.743303368559374],
              [-80.953846904322546, 35.742774169828003],
              [-80.954177382137431, 35.742816415231324],
              [-80.954335669855922, 35.742022615953843],
              [-80.954005196283404, 35.741980370050598],
              [-80.954057959233609, 35.741715770941894],
              [-80.953727485953721, 35.741673524298534],
              [-80.953780250695473, 35.7414089243657],
              [-80.953449778813521, 35.741366676964503],
              [-80.953502544263159, 35.741102077126413],
              [-80.953172073800886, 35.741059829868618],
              [-80.953330370133045, 35.740266030521347],
              [-80.95299990315263, 35.740223781883962],
              [-80.953158199516466, 35.739429981650169],
              [-80.952827737166871, 35.739387733417907],
              [-80.952986032478776, 35.738593933216691],
              [-80.952655573610869, 35.738551683604797],
              [-80.95270833871642, 35.738287083893958],
              [-80.952047425226823, 35.738202582239509],
              [-80.952100193198319, 35.737937982745436],
              [-80.951439282089012, 35.737853477798495],
              [-80.951492051821077, 35.737588878538858],
              [-80.95116159783683, 35.737546624858993],
              [-80.951214368254924, 35.737282024792989],
              [-80.950553464532433, 35.737197515002634],
              [-80.950606236732767, 35.73693291607244],
              [-80.950275786994439, 35.736890659961979],
              [-80.950328559880759, 35.736626060225454],
              [-80.949998110435018, 35.736583803374998],
              [-80.950050884050853, 35.736319204634434],
              [-80.949720437108155, 35.736276947008598],
              [-80.949773211409877, 35.736012347461795],
              [-80.949112320665222, 35.735927829797468],
              [-80.949165096727427, 35.735663230485173],
              [-80.948834652925399, 35.735620970446703],
              [-80.948887429716976, 35.735356372130461],
              [-80.948556987291113, 35.735314110433151],
              [-80.948609764790234, 35.735049512211809],
              [-80.947948883100779, 35.734964987305617],
              [-80.947896103517934, 35.735229586148662],
              [-80.946904781530037, 35.735102792072645],
              [-80.946851997338612, 35.735367390469236],
              [-80.946521557057622, 35.735325124131421],
              [-80.94646877146846, 35.73558972234342],
              [-80.944486125122538, 35.735336102017747],
              [-80.944433332871952, 35.735600699346342],
              [-80.944102892901611, 35.735558426447426],
              [-80.944050099231617, 35.735823022690198],
              [-80.943389215988759, 35.735738473054781],
              [-80.943336419889832, 35.736003069874322],
              [-80.942345094445599, 35.735876237820932],
              [-80.942397894807968, 35.735611641403018],
              [-80.941406575650461, 35.735484802593966],
              [-80.941353773213862, 35.735749397673608],
              [-80.941023333118025, 35.735707115535227],
              [-80.940970529305105, 35.73597171133148],
              [-80.940309648065536, 35.735887144984218],
              [-80.940256840675104, 35.736151739572421],
              [-80.939926400615008, 35.736109454444723],
              [-80.93987359184824, 35.736374049749365],
              [-80.939543149975535, 35.736331763602195],
              [-80.939490339789103, 35.736596357820936],
              [-80.93915989723061, 35.736554071537704],
              [-80.938829454995812, 35.736511783455988],
              [-80.938882267265882, 35.736247188615593],
              [-80.938551827534383, 35.736204899758654],
              [-80.938657453165561, 35.735675711124102],
              [-80.937996576760042, 35.735591132213891],
              [-80.938049391152177, 35.735326537658402],
              [-80.937718955614443, 35.735284246528884],
              [-80.937983027701847, 35.73396127557988],
              [-80.937652597772598, 35.733918984252227],
              [-80.937811040013216, 35.733125200482718],
              [-80.937480613587027, 35.733082908677233],
              [-80.937586242126073, 35.732553719553145],
              [-80.937255818150319, 35.732511427130007],
              [-80.937308634059718, 35.732246832622941],
              [-80.936978211481815, 35.732204539442328],
              [-80.937031026993182, 35.731939945047998],
              [-80.93670060581313, 35.731897651109833],
              [-80.936806239010295, 35.731368462448749],
              [-80.936475820280606, 35.731326167893009],
              [-80.936528636861112, 35.731061573643956],
              [-80.936198220612937, 35.731019277411995],
              [-80.936251037900661, 35.730754683258013],
              [-80.935920623071866, 35.73071238716981],
              [-80.935973441066764, 35.730447793110891],
              [-80.935312613423221, 35.730363197638695],
              [-80.935365434283014, 35.730098603797174],
              [-80.935035021489824, 35.730056305314449],
              [-80.935087843035305, 35.729791710666753],
              [-80.934427022800435, 35.729707111254072],
              [-80.934479845021727, 35.72944251776017],
              [-80.934149436453282, 35.729400215946555],
              [-80.934202260486913, 35.729135622530229],
              [-80.933871853316234, 35.729093319959212],
              [-80.933924676951747, 35.728828726655479],
              [-80.933263866877994, 35.728744119985194],
              [-80.93331669227301, 35.728479526916544],
              [-80.932655885685548, 35.728394916936999],
              [-80.932708713923731, 35.728130323184551],
              [-80.932378311658653, 35.728088017448151],
              [-80.932325482346542, 35.728352610159504],
              [-80.931995079374445, 35.72831030338596],
              [-80.931942248664555, 35.728574895912622],
              [-80.931281442735198, 35.728490278475363],
              [-80.931228609575101, 35.728754870677513],
              [-80.930898205534348, 35.728712560932458],
              [-80.930951039725358, 35.728447967969025],
              [-80.930620637082427, 35.728405657466709],
              [-80.930673473085648, 35.728141064580804],
              [-80.930343071840525, 35.728098753321149],
              [-80.930395907445501, 35.727834160547971],
              [-80.929404711107068, 35.727707219967769],
              [-80.929351871218245, 35.727971811437449],
              [-80.929021472460036, 35.727929496571356],
              [-80.928968631173404, 35.728194087856409],
              [-80.92863823168679, 35.728151771051891],
              [-80.9286910729206, 35.727887179924274],
              [-80.928360674831723, 35.72784486236246],
              [-80.928413516772437, 35.727580271330027],
              [-80.927422326630662, 35.727453313698604],
              [-80.927369480427672, 35.727717904328735],
              [-80.927039083742144, 35.727675584079314],
              [-80.927091930976133, 35.72741099268783],
              [-80.926761535666984, 35.727368670779924],
              [-80.926814382502528, 35.727104079501146],
              [-80.926483988612759, 35.727061757737154],
              [-80.926536837260343, 35.726797166536166],
              [-80.925876052579042, 35.726712518793931],
              [-80.925928902985774, 35.726447927828119],
              [-80.925598512215615, 35.726405602751129],
              [-80.925651363329266, 35.726141011880593],
              [-80.924990584930143, 35.726056359314939],
              [-80.925043437802785, 35.725791768679564],
              [-80.924052275952448, 35.725664783522134],
              [-80.923999419922851, 35.725929373737642],
              [-80.923669032278212, 35.725887043417551],
              [-80.92356331595137, 35.726416222550746],
              [-80.923232926547612, 35.726373891053449],
              [-80.92318006735546, 35.726638479953763],
              [-80.922849677245054, 35.726596147419315],
              [-80.922796815571175, 35.726860737053556],
              [-80.921805644135063, 35.726733732746006],
              [-80.921752778937375, 35.726998321014335],
              [-80.921422388104631, 35.726955984611209],
              [-80.921369521509149, 35.727220572694812],
              [-80.920708738754598, 35.727135896015689],
              [-80.920655869730112, 35.72740048467584],
              [-80.920325477808319, 35.727358144399915],
              [-80.919334304159506, 35.727231119991401],
              [-80.919387177393062, 35.7269665318913],
              [-80.918726398786191, 35.726881844445941],
              [-80.918779273800027, 35.726617257482296],
              [-80.918448886056723, 35.726574912103239],
              [-80.918501762882343, 35.726310325217497],
              [-80.917840990537172, 35.726225632047949],
              [-80.917893869121613, 35.72596104539744],
              [-80.917563484519832, 35.725918697606971],
              [-80.917616363789477, 35.725654110150543],
              [-80.916955597748711, 35.725569413059283],
              [-80.917008478777234, 35.725304825838194],
              [-80.916347717328847, 35.72522012542094],
              [-80.916400600116006, 35.724955538435296],
              [-80.916070220399277, 35.724913186579037],
              [-80.916123103893042, 35.724648599688692],
              [-80.915792726700701, 35.724606247959123],
              [-80.91584561090086, 35.724341661164146],
              [-80.915515235085181, 35.724299307776285],
              [-80.91556811888664, 35.724034721094036],
              [-80.914246623289671, 35.723865299131511],
              [-80.914299512059245, 35.723600712947324],
              [-80.913969140076532, 35.72355835535388],
              [-80.914022029552527, 35.72329376926514],
              [-80.913691658967707, 35.723251410914614],
              [-80.913797440091884, 35.722722238865849],
              [-80.913467071957072, 35.722679879898344],
              [-80.913519962500104, 35.722415293955727],
              [-80.913189595763072, 35.722372934231174],
              [-80.913401159791434, 35.721314590556901],
              [-80.913070798692445, 35.72127222957694],
              [-80.913123689610885, 35.721007643691216],
              [-80.912793329931048, 35.720965282855431],
              [-80.912846221555881, 35.720700697065126],
              [-80.912515863252565, 35.720658334571155],
              [-80.912568755583735, 35.720393748876312],
              [-80.911908042160945, 35.720309023279626],
              [-80.911960936229178, 35.720044436919125],
              [-80.911630581088517, 35.720002072915307],
              [-80.911683475884317, 35.719737487551399],
              [-80.911353122120076, 35.719695121889337],
              [-80.91145891386239, 35.719165950389481],
              [-80.911128562569232, 35.719123585011708],
              [-80.911234356137598, 35.718594413596286],
              [-80.910904007294221, 35.718552047601669],
              [-80.911009800457165, 35.718022875404181],
              [-80.910679454063541, 35.717980508792678],
              [-80.910732351730843, 35.717715922758536],
              [-80.906437902448943, 35.717165070553278],
              [-80.906384990001058, 35.717429654783217],
              [-80.905724308305196, 35.717344893726512],
              [-80.905777222856841, 35.717080309776797],
              [-80.905116545774476, 35.716995546296189],
              [-80.905063629118899, 35.717260129965545],
              [-80.903411936048485, 35.717048202629755],
              [-80.903464857963513, 35.716783619661172],
              [-80.903134521660121, 35.716741231639666],
              [-80.903081598693191, 35.717005814468159],
              [-80.902751261683818, 35.716963425409446],
              [-80.902420925020294, 35.716921035453751],
              [-80.902473850091013, 35.71665645290566],
              [-80.901813179905645, 35.716571670583662],
              [-80.90186610673419, 35.716307088271293],
              [-80.901535773212359, 35.716264695904982],
              [-80.901588700746814, 35.716000113688239],
              [-80.899606714275833, 35.715745740575983],
              [-80.899659648880487, 35.715481159138626],
              [-80.8986686640133, 35.71535396043457],
              [-80.89872160142744, 35.715089379373296],
              [-80.897730622829698, 35.714962173017085],
              [-80.897783563053352, 35.714697592331873],
              [-80.897122914849945, 35.714612783583803],
              [-80.897175856831055, 35.714348203134527],
              [-80.895854569888883, 35.7141785754186],
              [-80.895907515731082, 35.71391399548569],
              [-80.895577196178195, 35.713871585999634],
              [-80.895524249284136, 35.714136165792368],
              [-80.892551378374122, 35.713754441514105],
              [-80.892604335838854, 35.713489862966512],
              [-80.891943704001093, 35.713405025158714],
              [-80.891996663223139, 35.713140446847042],
              [-80.891666348875233, 35.713098026738045],
              [-80.891825227598616, 35.712304291055759],
              [-80.892155537686506, 35.712346710761111],
              [-80.892261453015394, 35.711817553737347],
              [-80.892591762451019, 35.711859972248142],
              [-80.892644718523542, 35.711595392627991],
              [-80.892975026148505, 35.711637810118766],
              [-80.893027980844295, 35.711373231215056],
              [-80.893358287763775, 35.711415647668673],
              [-80.893464192887649, 35.710886488563347],
              [-80.893794499154765, 35.710928903822349],
              [-80.893900400791068, 35.710399744258638],
              [-80.894230704195948, 35.710442158357409],
              [-80.894283653985212, 35.710177577909441],
              [-80.894613956684609, 35.710219990970984],
              [-80.894719852037781, 35.709690830579596],
              [-80.895050152958717, 35.709733241562603],
              [-80.895103098522739, 35.709468661619141],
              [-80.89477279863226, 35.709426249875243],
              [-80.895037527540211, 35.708103346571001],
              [-80.894707233253882, 35.708060934631405],
              [-80.894813125152524, 35.707531772908617],
              [-80.894482833315521, 35.70748936035266],
              [-80.894535779244919, 35.707224779573544],
              [-80.894205489931466, 35.707182367145002],
              [-80.894258436566403, 35.706917786461737],
              [-80.893928148629556, 35.706875372375379],
              [-80.893981095970034, 35.706610791787995],
              [-80.893320522132569, 35.706525961222539],
              [-80.893373472335028, 35.70626138085413],
              [-80.893043186434411, 35.706218964374969],
              [-80.893096137321393, 35.705954383201266],
              [-80.892435570871342, 35.705869547798549],
              [-80.892488522431378, 35.705604967779429],
              [-80.892158240776936, 35.705562548873097],
              [-80.892211194147336, 35.705297968932719],
              [-80.891880913890574, 35.70525554926985],
              [-80.891933866861507, 35.704990969442562],
              [-80.891603587981166, 35.704948548121941],
              [-80.891656542762519, 35.704683968373438],
              [-80.891326265300805, 35.704641547197483],
              [-80.891432175907028, 35.704112386946846],
              [-80.891101900894469, 35.704069965154645],
              [-80.891154856740457, 35.703805385553721],
              [-80.890494309835333, 35.703720538658366],
              [-80.89054726743818, 35.70345595929372],
              [-80.890216996650523, 35.703413534173336],
              [-80.890269954958796, 35.70314895490467],
              [-80.889939684484858, 35.703106529946133],
              [-80.889992643477342, 35.702841949872273],
              [-80.889662375505893, 35.702799524140104],
              [-80.889715335203718, 35.702534944162252],
              [-80.889385068629693, 35.702492517673591],
              [-80.889438029032846, 35.702227937791733],
              [-80.889107762751564, 35.702185510563773],
              [-80.889160724964896, 35.701920930760778],
              [-80.888500196627206, 35.701836072976782],
              [-80.888553159492361, 35.701571493427373],
              [-80.888222897457069, 35.701529063772576],
              [-80.888275862132346, 35.701264484301973],
              [-80.887945601494451, 35.70122205389071],
              [-80.887998565770118, 35.700957474533332],
              [-80.887668306508814, 35.700915042464416],
              [-80.887721271510756, 35.700650464104207],
              [-80.887060757255, 35.700565598440932],
              [-80.887113723992499, 35.700301019415988],
              [-80.886783468977043, 35.700258584920427],
              [-80.886836436419756, 35.699994005991499],
              [-80.886506181697044, 35.699951570756653],
              [-80.886559150949765, 35.699686991906674],
              [-80.886228897645523, 35.699644556816629],
              [-80.886175928446306, 35.69990913550906],
              [-80.88452466398634, 35.699696942266712],
              [-80.884471688079529, 35.699961520230239],
              [-80.884141435854403, 35.699919079461871],
              [-80.884088458529121, 35.700183656339668],
              [-80.883427951889118, 35.700098770947882],
              [-80.883374972135968, 35.700363348401872],
              [-80.883044717721134, 35.700320903778447],
              [-80.882991736570418, 35.700585481047831],
              [-80.882331227781435, 35.700500588812915],
              [-80.882278243077124, 35.700765165774371],
              [-80.881947988703104, 35.700722718162886],
              [-80.881895002601098, 35.70098729493958],
              [-80.881564746417169, 35.700944846308069],
              [-80.881670719272691, 35.700415693008232],
              [-80.881340466642584, 35.700373243743648],
              [-80.881393453049839, 35.700108667176231],
              [-80.881063201817213, 35.700066217155303],
              [-80.881116188929596, 35.699801640684001],
              [-80.88045568960581, 35.699716738232823],
              [-80.880402699286094, 35.699981314440421],
              [-80.880072449092296, 35.699938861729322],
              [-80.880019457375028, 35.700203437752307],
              [-80.879689205371463, 35.700160984021153],
              [-80.879028704591846, 35.700076072933378],
              [-80.879134692903847, 35.699546922603879],
              [-80.878804445125681, 35.699504465545154],
              [-80.878857440355446, 35.699239889995326],
              [-80.878196947982289, 35.699154975270829],
              [-80.878249944968246, 35.698890399957563],
              [-80.877259212138725, 35.698763020665638],
              [-80.87731221193215, 35.698498445729363],
              [-80.876981969406486, 35.698455984612792],
              [-80.877034969883795, 35.698191408871537],
              [-80.876374487974033, 35.698106484229143],
              [-80.876427491333132, 35.697841909608712],
              [-80.87609725192813, 35.697799445181715],
              [-80.875767012890279, 35.697756980759223],
              [-80.875820016879743, 35.697492405491204],
              [-80.875489780344338, 35.697449940295456],
              [-80.875542785059494, 35.697185366024861],
              [-80.875212549900667, 35.697142899171652],
              [-80.875265555320652, 35.69687832499725],
              [-80.874935321559448, 35.696835857387839],
              [-80.875041334567399, 35.696306709170308],
              [-80.87471110215003, 35.696264240962122],
              [-80.874817116958781, 35.69573509193043],
              [-80.874486887010917, 35.695692624007712],
              [-80.874539894936404, 35.695428049115428],
              [-80.874592902536364, 35.695163475080157],
              [-80.874262675016283, 35.695121005640502],
              [-80.874315683320987, 35.694856431701503],
              [-80.873985458303252, 35.69481396148862],
              [-80.874091474850118, 35.694284812874777],
              [-80.873761253385709, 35.69424234202922],
              [-80.874026294467882, 35.692919469063881],
              [-80.873696077521231, 35.692876998942261],
              [-80.873749086187345, 35.692612424170207],
              [-80.873418871722066, 35.692569952374143],
              [-80.873524889012529, 35.692040802995614],
              [-80.873194676995595, 35.691998330583885],
              [-80.873247685619944, 35.691733755977317],
              [-80.872917475000506, 35.691691282809536],
              [-80.872970485434209, 35.691426708282414],
              [-80.871979857723133, 35.691299284738932],
              [-80.872032870963565, 35.691034710589143],
              [-80.871372457045965, 35.690949756746058],
              [-80.870381838767798, 35.690822319257009],
              [-80.870328820272434, 35.69108689270432],
              [-80.869338201959167, 35.690959446724513],
              [-80.869285179964535, 35.691224019706219],
              [-80.868624767365063, 35.691139050054602],
              [-80.868571741817405, 35.691403622728146],
              [-80.868241534996784, 35.691361136867592],
              [-80.868294561595519, 35.691096564334472],
              [-80.867964356172351, 35.691054077717844],
              [-80.868017383475646, 35.690789505281224],
              [-80.867687179449916, 35.690747017908464],
              [-80.867740206352892, 35.690482445585211],
              [-80.867410004808605, 35.690439956538235],
              [-80.867516060781696, 35.689910812023555],
              [-80.867185861706446, 35.689868323262274],
              [-80.867238889671839, 35.689603751087802],
              [-80.866578493537261, 35.689518770272372],
              [-80.866631524362703, 35.689254198317933],
              [-80.866301328407971, 35.68921170624693],
              [-80.866354359937802, 35.688947134388869],
              [-80.866024164296661, 35.688904642480047],
              [-80.865971131715924, 35.689169214197463],
              [-80.865640936454213, 35.68912672033337],
              [-80.865587902496827, 35.689391292767411],
              [-80.865257705426146, 35.689348797883099],
              [-80.865204670050588, 35.689613369231381],
              [-80.864874473400874, 35.689570874194253],
              [-80.86498054588651, 35.689041730833559],
              [-80.864650351685128, 35.688999235181058],
              [-80.864862496701676, 35.687940948493079],
              [-80.864532307050297, 35.687898452506182],
              [-80.864638380685761, 35.687369309162086],
              [-80.864308193461923, 35.687326811658544],
              [-80.864414266708295, 35.686797668436235],
              [-80.864084081953322, 35.686755171218593],
              [-80.864137120201917, 35.686490599214387],
              [-80.863806936823565, 35.686448100339589],
              [-80.863913013298685, 35.685918957398535],
              [-80.863582832368451, 35.685876457908279],
              [-80.863635870574328, 35.685611886070063],
              [-80.863305692166904, 35.685569386708252],
              [-80.863358731077028, 35.685304814966692],
              [-80.863028554046082, 35.685262313947661],
              [-80.863081593660453, 35.684997742302663],
              [-80.862751418047637, 35.684955241429016],
              [-80.862804458366242, 35.684690669880538],
              [-80.862474284129902, 35.684648167349692],
              [-80.862527325152712, 35.684383595897842],
              [-80.861866979821258, 35.684298588427964],
              [-80.861920023703647, 35.684034017196424],
              [-80.861589852608446, 35.683991512257322],
              [-80.861642896090487, 35.683726941139277],
              [-80.861312726413274, 35.683684436345509],
              [-80.861365771683282, 35.68341986440597],
              [-80.861035603403352, 35.683377358856333],
              [-80.861088649377507, 35.683112787013428],
              [-80.860758482474239, 35.683070279806785],
              [-80.860811529173247, 35.682805708961666],
              [-80.860481363667148, 35.682763200999162],
              [-80.860534411070262, 35.682498630250748],
              [-80.860204246961516, 35.682456121532326],
              [-80.860257295068735, 35.682191550880567],
              [-80.859596971096735, 35.682106531018817],
              [-80.859650020958824, 35.681841960604231],
              [-80.85931985997037, 35.681799448576577],
              [-80.859372910536422, 35.681534878258688],
              [-80.858712592846686, 35.681449853580752],
              [-80.858765645167438, 35.681185283500234],
              [-80.859095803340608, 35.681227796138387],
              [-80.859308004913885, 35.680169513012096],
              [-80.858977850943134, 35.680127000936487],
              [-80.859137001625399, 35.679333288102079],
              [-80.858806852257686, 35.67929077553503],
              [-80.858912953067033, 35.678761633106397],
              [-80.859243100333586, 35.678804145392306],
              [-80.859667480220125, 35.676687573716769],
              [-80.859997618324272, 35.67673008399823],
              [-80.860050663335585, 35.676465512086097],
              [-80.858730116074895, 35.67629546526031],
              [-80.858677066861418, 35.676560036609885],
              [-80.857686656621951, 35.676432491435719],
              [-80.857739708986827, 35.676167920508036],
              [-80.857079440057163, 35.676082885879765],
              [-80.857132494176369, 35.67581831518946],
              [-80.856802361292281, 35.675775797122071],
              [-80.856855417219961, 35.675511226511809],
              [-80.856525285712181, 35.675468706787463],
              [-80.856578341239242, 35.675204136290859],
              [-80.856908471675879, 35.675246654973364],
              [-80.856961525805886, 35.674982084292274],
              [-80.85729165553856, 35.675024601937807],
              [-80.857556915696421, 35.673701745370181],
              [-80.857887040544171, 35.673744262317328],
              [-80.857940090473107, 35.673479690191137],
              [-80.858270213491835, 35.67352220521704],
              [-80.858376310230071, 35.672993061453255],
              [-80.858706431494383, 35.673035575301576],
              [-80.858812524745645, 35.672506431081558],
              [-80.859142644255755, 35.672548943752297],
              [-80.859301777267831, 35.671755226238695],
              [-80.859631893973145, 35.671797737591241],
              [-80.859737979536987, 35.671268591470223],
              [-80.860068094508804, 35.671311102546461],
              [-80.860227214875195, 35.670517381747899],
              [-80.860557327042272, 35.670559891505967],
              [-80.86066340271033, 35.670030743518083],
              [-80.860993513123134, 35.670073252098625],
              [-80.861311726635236, 35.668485807127752],
              [-80.861641831071822, 35.668528313067021],
              [-80.861853961533214, 35.66747001439488],
              [-80.862184061010552, 35.667512518892465],
              [-80.862237091719933, 35.667247944425398],
              [-80.862567190493266, 35.66729044788601],
              [-80.862620219785228, 35.667025872333497],
              [-80.862950317854768, 35.667068374757285],
              [-80.863003345770764, 35.66680379992178],
              [-80.863333443136412, 35.66684630130878],
              [-80.863386469635003, 35.666581725387857],
              [-80.862726277350788, 35.666496721998904],
              [-80.862779305603496, 35.666232146315615],
              [-80.86211911680536, 35.666147139622808],
              [-80.862172146812313, 35.665882564177139],
              [-80.861842053983139, 35.665840059627051],
              [-80.862107204073283, 35.664517182448009],
              [-80.862437290546978, 35.664559686312046],
              [-80.862490318475537, 35.664295110604982],
              [-80.862160231947797, 35.664252606898486],
              [-80.862213260580305, 35.663988031288476],
              [-80.862319316785459, 35.663458879036646],
              [-80.861989234859081, 35.663416374838881],
              [-80.862095290695521, 35.662887223612209],
              [-80.861765211195063, 35.662844717898331],
              [-80.861818240196087, 35.662580142351914],
              [-80.861488162112906, 35.662537636783576],
              [-80.861541191797002, 35.662273060433073],
              [-80.86088103877016, 35.662188046889156],
              [-80.860934070208202, 35.661923470776379],
              [-80.859943846395552, 35.661795943259747],
              [-80.859996881741935, 35.661531367508474],
              [-80.859336736379603, 35.661446345565196],
              [-80.859389772375522, 35.661181770068595],
              [-80.859059702347679, 35.661139256975311],
              [-80.859112739067996, 35.660874682477008],
              [-80.858782670436753, 35.660832168628183],
              [-80.858835707860763, 35.660567594227139],
              [-80.858175572654034, 35.660482565040347],
              [-80.858334690210981, 35.659688840600865],
              [-80.858004626819167, 35.65964632462611],
              [-80.858057665987147, 35.659381749571921],
              [-80.857727603991918, 35.659339232841695],
              [-80.857780644988367, 35.65907465876905],
              [-80.857120524137414, 35.658989622901551],
              [-80.857173565762594, 35.658725048182454],
              [-80.856843506927419, 35.658682529946354],
              [-80.85689655036046, 35.658417955307598],
              [-80.856236435788304, 35.658332914625966],
              [-80.856289480974951, 35.658068340225149],
              [-80.855959425258519, 35.65802581868077],
              [-80.856012471148702, 35.657761244377198],
              [-80.85568241684949, 35.657718722978686],
              [-80.85573546344321, 35.657454148772288],
              [-80.855405410519694, 35.657411625717117],
              [-80.855299314885769, 35.657940773804988],
              [-80.854969261313016, 35.657898249555508],
              [-80.854916211926351, 35.658162823393624],
              [-80.854586156545878, 35.658120298124103],
              [-80.854480054633058, 35.658649445388633],
              [-80.854149997498993, 35.658606918941665],
              [-80.854096944972824, 35.658871492368085],
              [-80.853766887135251, 35.658828964884329],
              [-80.853713833191918, 35.659093537225374],
              [-80.853383774650837, 35.659051008704722],
              [-80.853330718227411, 35.659315581779673],
              [-80.852670600084807, 35.659230521768656],
              [-80.852617542318981, 35.659495094501899],
              [-80.850307132365941, 35.659197354349274],
              [-80.850360198586102, 35.658932782584131],
              [-80.849370032333184, 35.658805166110128],
              [-80.849423101356706, 35.658540594723881],
              [-80.848762993631908, 35.658455513487418],
              [-80.848816065513034, 35.658190942322328],
              [-80.84848601319996, 35.65814839959944],
              [-80.848539084680155, 35.657883828548414],
              [-80.848209034888583, 35.657841285954639],
              [-80.848262107072102, 35.657576715000978],
              [-80.847602009503717, 35.657491626522209],
              [-80.847655083440486, 35.657227055806523],
              [-80.846994990462875, 35.657141964008211],
              [-80.847048066152851, 35.6568773935306],
              [-80.846718021244158, 35.65683484687866],
              [-80.846771097637401, 35.656570276498393],
              [-80.846441054104801, 35.656527728189978],
              [-80.846494132305537, 35.656263157890209],
              [-80.84616409016941, 35.656220608826409],
              [-80.846270246526444, 35.655691468395055],
              [-80.84594020683673, 35.655648918716814],
              [-80.845993284993114, 35.655384348585102],
              [-80.845663247804183, 35.655341798134806],
              [-80.845716326663677, 35.655077228100538],
              [-80.845386289787555, 35.655034677812857],
              [-80.845439370433951, 35.654770106957983],
              [-80.845109334933923, 35.654727555013828],
              [-80.845162416303907, 35.654462985157572],
              [-80.844832382200394, 35.654420432458146],
              [-80.844938546000222, 35.653891292897036],
              [-80.844608514342937, 35.653848739583204],
              [-80.844661596752147, 35.653584168977162],
              [-80.84433156759556, 35.653541614891267],
              [-80.844384650728415, 35.653277045283865],
              [-80.844054621864103, 35.653234490459482],
              [-80.844107705700182, 35.652969920949509],
              [-80.84377767933654, 35.652927365353136],
              [-80.843830763855109, 35.65266279503939],
              [-80.84350073888794, 35.652620238687852],
              [-80.843553824109591, 35.652355668471564],
              [-80.8432238005594, 35.652313112265979],
              [-80.843542309824002, 35.65072569182081],
              [-80.843872327096207, 35.650768248082834],
              [-80.844031573336778, 35.649974536410966],
              [-80.843701560317882, 35.649931980554598],
              [-80.843860806567349, 35.649138268014319],
              [-80.843530797044195, 35.649095711684375],
              [-80.843636961222174, 35.64856657066241],
              [-80.843306954145035, 35.648524013718166],
              [-80.843413120119365, 35.647994871886887],
              [-80.843083115488199, 35.647952314328236],
              [-80.843136197911164, 35.647687743955878],
              [-80.842806195780526, 35.647645185625422],
              [-80.842912362769397, 35.647116044114547],
              [-80.842582361980476, 35.647073485186425],
              [-80.842635446546353, 35.646808914048023],
              [-80.842305448257818, 35.646766354348095],
              [-80.842411618469427, 35.646237213124422],
              [-80.842081621522581, 35.646194652826942],
              [-80.842134707147693, 35.645930081840518],
              [-80.841804711597291, 35.645887520787838],
              [-80.841857797925329, 35.645622949899014],
              [-80.841527804875184, 35.645580388074521],
              [-80.841580891906148, 35.645315817283333],
              [-80.841250900252334, 35.645273254703838],
              [-80.841303987986194, 35.645008684010193],
              [-80.840973997728511, 35.644966120675612],
              [-80.841186349395457, 35.643907838032831],
              [-80.840856363682647, 35.643865274365645],
              [-80.84096253953517, 35.64333613306686],
              [-80.841015626941441, 35.643071562352773],
              [-80.838705760845386, 35.642773591817928],
              [-80.838758856355497, 35.642509022029905],
              [-80.838428877593756, 35.642466450857746],
              [-80.838481972702567, 35.642201881184114],
              [-80.838151996461505, 35.642159310141551],
              [-80.838258188821641, 35.641630170028641],
              [-80.837928215005746, 35.641587597470718],
              [-80.838034408098295, 35.641058458368313],
              [-80.837704436727989, 35.641015885196161],
              [-80.837916822909378, 35.639957605235097],
              [-80.837586856103911, 35.639915032631805],
              [-80.835607063554178, 35.639659573677022],
              [-80.835713269256047, 35.639130435410117],
              [-80.835383308060003, 35.63908785589998],
              [-80.835436411981988, 35.638823286383882],
              [-80.834446531434935, 35.638695543835581],
              [-80.834499638158206, 35.638430974699155],
              [-80.833179807189708, 35.638260638111127],
              [-80.833232918867537, 35.637996069478469],
              [-80.830593283055109, 35.637655353513587],
              [-80.829933378403425, 35.637570165777333],
              [-80.829986500227378, 35.637305598510871],
              [-80.829326599041337, 35.637220406572162],
              [-80.829379722617062, 35.636955839544605],
              [-80.829049773603728, 35.636913242823177],
              [-80.829102897881839, 35.636648675893539],
              [-80.828772950264636, 35.636606078417216],
              [-80.828826075245132, 35.636341511585485],
              [-80.82849612900371, 35.636298912453114],
              [-80.828549254686664, 35.636034345719303],
              [-80.828219310965679, 35.63599174671684],
              [-80.82827243735089, 35.63572718008087],
              [-80.827942493901702, 35.635684579438987],
              [-80.82799562098927, 35.635420012901015],
              [-80.827665680060463, 35.635377412388891],
              [-80.827718807830024, 35.635112845047658],
              [-80.82705892798721, 35.635027640733547],
              [-80.827112057528709, 35.634763074532437],
              [-80.826782119738994, 35.63472047161504],
              [-80.826835249962357, 35.634455904610661],
              [-80.826175377501656, 35.634370695469435],
              [-80.826228509496787, 35.634106129605264],
              [-80.8245788385276, 35.633893090446591],
              [-80.824631976525538, 35.633628525227756],
              [-80.824302044638458, 35.633585914666334],
              [-80.824355183338497, 35.633321349545447],
              [-80.824025252847505, 35.633278738229315],
              [-80.824078392229538, 35.633014172305188],
              [-80.823748463134521, 35.632971560234346],
              [-80.823801603238991, 35.632706995309583],
              [-80.824131531284891, 35.632749607239482],
              [-80.824237808334402, 35.632220476077705],
              [-80.824567733504821, 35.632263085945247],
              [-80.824939682668258, 35.63041112322054],
              [-80.824609764821119, 35.630368513438761],
              [-80.824822303898884, 35.629310247611969],
              [-80.824492390594841, 35.629267637498501],
              [-80.824598659598408, 35.628738504617992],
              [-80.824268748739286, 35.628695893890878],
              [-80.824321884321762, 35.628431327519024],
              [-80.823991974858572, 35.62838871603725],
              [-80.82404511114305, 35.628124149763522],
              [-80.82371520307575, 35.628081537527194],
              [-80.823768340062315, 35.627816971351599],
              [-80.823438433390905, 35.627774358360561],
              [-80.823491571059265, 35.62750979138189],
              [-80.823161665783758, 35.627467177636234],
              [-80.82326794219793, 35.626938044733571],
              [-80.822938039367273, 35.626895430374375],
              [-80.823044316491689, 35.626366297582265],
              [-80.82337421722444, 35.626408911659489],
              [-80.823693031750352, 35.624821510541224],
              [-80.824022925432615, 35.6248641228932],
              [-80.824129191707144, 35.624334988868647],
              [-80.824459083638388, 35.624377600043019],
              [-80.824565346407368, 35.623848464663894],
              [-80.824895236587764, 35.623891074660797],
              [-80.824948365851185, 35.623626506774301],
              [-80.825278255329536, 35.62366911573455],
              [-80.825331383197238, 35.623404547664272],
              [-80.82599116109661, 35.623489762615968],
              [-80.826044286499382, 35.623225193319719],
              [-80.826374174920474, 35.623267799311172],
              [-80.826480422628435, 35.622738662111004],
              [-80.82681030929858, 35.622781266924818],
              [-80.826916553501277, 35.622252128370434],
              [-80.827246437316688, 35.622294732023349],
              [-80.827299557859149, 35.622030162991685],
              [-80.827629440972373, 35.62207276560796],
              [-80.827682560098879, 35.621808195491347],
              [-80.8280124436139, 35.621850797054549],
              [-80.828065560261322, 35.621586227672069],
              [-80.828395443074257, 35.62162882819861],
              [-80.828448558305737, 35.621364257731209],
              [-80.828778440416556, 35.621406857221224],
              [-80.828831554252346, 35.621142286570119],
              [-80.829161435661163, 35.621184885023659],
              [-80.829214548121698, 35.620920315090103],
              [-80.829544427704192, 35.620962911622399],
              [-80.829703762046421, 35.620169200208217],
              [-80.830033638829121, 35.620211795422051],
              [-80.830192965780356, 35.619418082316194],
              [-80.83052283978364, 35.619460677112784],
              [-80.830682159344121, 35.618666962315444],
              [-80.830352289590991, 35.618624367925108],
              [-80.830405396486938, 35.618359797282906],
              [-80.830075527025528, 35.618317202154522],
              [-80.829745657910834, 35.618274606130591],
              [-80.829798767641037, 35.618010034809842],
              [-80.829468899921878, 35.617967438031435],
              [-80.829415790246557, 35.618232009194621],
              [-80.82875605487483, 35.618146813553835],
              [-80.828702941651599, 35.618411384409036],
              [-80.827383470832956, 35.618240980014683],
              [-80.827330353067936, 35.618505550263286],
              [-80.827000484629465, 35.61846294679313],
              [-80.826947365468911, 35.61872751685808],
              [-80.826617497432196, 35.618684912334899],
              [-80.826564376876092, 35.618949482216216],
              [-80.825574769492775, 35.618821662883491],
              [-80.825627893195261, 35.618557093425181],
              [-80.825298025441896, 35.618514485336469],
              [-80.82535115094997, 35.61824991595995],
              [-80.824691419684882, 35.618164697361308],
              [-80.82474454583992, 35.617900128240741],
              [-80.824084818059617, 35.617814906341998],
              [-80.82413794596529, 35.617550337460813],
              [-80.823478222753039, 35.617465111344337],
              [-80.823531352409347, 35.617200542702598],
              [-80.823201492382296, 35.617157928892773],
              [-80.823254623844178, 35.616893360332838],
              [-80.822594906927961, 35.616808130308648],
              [-80.822648039016428, 35.616543561103455],
              [-80.822318182127262, 35.616500944889168],
              [-80.82237131604154, 35.616236376667153],
              [-80.822041460547879, 35.616193759698412],
              [-80.822147728309261, 35.615664622523788],
              [-80.821817875259782, 35.615622004941642],
              [-80.822083545502451, 35.614299162390637],
              [-80.821753698022818, 35.614256543717005],
              [-80.821806832308297, 35.613991975577278],
              [-80.821476987327699, 35.613949356132771],
              [-80.821530121211268, 35.613684788108067],
              [-80.820540591516632, 35.613556925726094],
              [-80.820593728178892, 35.613292357180754],
              [-80.819934045554064, 35.613207111698095],
              [-80.820040324259793, 35.612677975912092],
              [-80.819710485554737, 35.612635351659151],
              [-80.819816764970213, 35.612106215985172],
              [-80.820146601557909, 35.612148839054719],
              [-80.8201997396867, 35.611884270562236],
              [-80.819540068934913, 35.611799022908507],
              [-80.819593208813899, 35.611534454655619],
              [-80.818933542650171, 35.611449203685829],
              [-80.818986684279366, 35.611184635672601],
              [-80.817667359184725, 35.611014122667655],
              [-80.817720505764598, 35.6107495551597],
              [-80.817390676125768, 35.610706924589827],
              [-80.817443823407331, 35.610442357180474],
              [-80.817113995163808, 35.610399725856276],
              [-80.817220289679554, 35.609870591208711],
              [-80.816890464983501, 35.609827959254908],
              [-80.816943612217685, 35.609563392016732],
              [-80.816613788916982, 35.609520759308538],
              [-80.816720085524949, 35.608991624069191],
              [-80.816390264668058, 35.60894899074782],
              [-80.816443412958421, 35.608684423664421],
              [-80.81611359349688, 35.608641789588859],
              [-80.816166743572168, 35.608377221686325],
              [-80.815836925505963, 35.608334586856444],
              [-80.815996375732368, 35.607540885136402],
              [-80.815666561158295, 35.607498249834542],
              [-80.815719711942748, 35.607233682044345],
              [-80.815389898763982, 35.607191045988259],
              [-80.81544305027009, 35.606926479198044],
              [-80.815113238486632, 35.606883842387639],
              [-80.815166390674108, 35.60661927479488],
              [-80.814506771348263, 35.606533998753861],
              [-80.814613077772123, 35.606004864022005],
              [-80.814942885716562, 35.606047502199722],
              [-80.815261790392327, 35.604460097040729],
              [-80.814931989841355, 35.604417459693344],
              [-80.814985141003206, 35.604152892001046],
              [-80.814655341827262, 35.604110252998247],
              [-80.814708493690503, 35.603845685404806],
              [-80.814378695929932, 35.603803046548968],
              [-80.814431848494664, 35.603538479054301],
              [-80.814102052129272, 35.603495839444285],
              [-80.814261509762872, 35.602702136244702],
              [-80.813931717992631, 35.602659496146408],
              [-80.813984870142491, 35.6023949278798],
              [-80.81431466198805, 35.602437568721719],
              [-80.81436781384626, 35.602173000255164],
              [-80.814038023069102, 35.602130360455604],
              [-80.814091175628676, 35.601865792087835],
              [-80.813761387350155, 35.601823151517593],
              [-80.813814539527712, 35.601558584166348],
              [-80.813484752624177, 35.601515941940796],
              [-80.813537906606626, 35.601251374671897],
              [-80.812878335956896, 35.601166088718436],
              [-80.812825179878104, 35.601430655705038],
              [-80.81249539403548, 35.601388011694851],
              [-80.812548551142328, 35.601123443948147],
              [-80.811559197717031, 35.600995506084708],
              [-80.810899631230598, 35.600910209388083],
              [-80.810952794334895, 35.600645642288384],
              [-80.810623012670405, 35.600602993189028],
              [-80.810676175372535, 35.600338426204573],
              [-80.809686836668362, 35.600210472139437],
              [-80.809740002168013, 35.599945905536288],
              [-80.809410223987655, 35.599903253138265],
              [-80.809080447237633, 35.599860598927258],
              [-80.809133614486612, 35.599596032564136],
              [-80.808803839131741, 35.599553377599101],
              [-80.808857007101977, 35.599288812236104],
              [-80.808527233142229, 35.59924615651709],
              [-80.808580401793492, 35.598981590351755],
              [-80.808250629228851, 35.598938933878621],
              [-80.808303799684708, 35.598674367795788],
              [-80.807974027411731, 35.598631710585025],
              [-80.808133538738133, 35.597838012522871],
              [-80.807803771080003, 35.597795355725403],
              [-80.80791011304099, 35.597266222842848],
              [-80.807580347826004, 35.597223565432479],
              [-80.807686689391673, 35.596694432679762],
              [-80.808016452530623, 35.596737090708899],
              [-80.808069622306675, 35.596472524570402],
              [-80.80773986021579, 35.596429866682456],
              [-80.807846199699483, 35.595900733676345],
              [-80.807516440051685, 35.595858075175535],
              [-80.807622781346936, 35.595328942266534],
              [-80.807293024142155, 35.59528628315303],
              [-80.807399366145475, 35.59475715035753],
              [-80.80706961138371, 35.594714490631269],
              [-80.807122782922946, 35.594449924762081],
              [-80.806793030659577, 35.594407264265463],
              [-80.806846201776295, 35.594142697610401],
              [-80.806516450907907, 35.594100036359833],
              [-80.806569623829034, 35.59383546978745],
              [-80.806239874355612, 35.593792807782883],
              [-80.806293047997769, 35.593528242210787],
              [-80.805963299919327, 35.593485579452349],
              [-80.806016473138968, 35.593221013094379],
              [-80.805686726455491, 35.593178349582011],
              [-80.805899424469416, 35.592120084228149],
              [-80.806229166961145, 35.592162747175642],
              [-80.806335511790195, 35.591633613963381],
              [-80.806005771394382, 35.59159095129835],
              [-80.806058944336371, 35.591326384770234],
              [-80.805729205335368, 35.591283721351239],
              [-80.805995068956534, 35.58996088790024],
              [-80.805335604660954, 35.589875558855198],
              [-80.805388778664664, 35.589610992498116],
              [-80.805059048095231, 35.589568327224747],
              [-80.80511222277984, 35.589303760065555],
              [-80.80478249360516, 35.589261094038442],
              [-80.804835668990606, 35.588996526978406],
              [-80.804176213777993, 35.588911192521287],
              [-80.804229390912113, 35.588646625701649],
              [-80.80389966487418, 35.588603957271673],
              [-80.803846486672185, 35.588868523048809],
              [-80.802857307477069, 35.588740511063513],
              [-80.802910488802354, 35.588475944808891],
              [-80.802580764152495, 35.588433272798596],
              [-80.80263394728189, 35.588168706626782],
              [-80.802304224026869, 35.588126033862764],
              [-80.802410590236804, 35.587596901691803],
              [-80.802740310312885, 35.587639575090947],
              [-80.802846673059165, 35.587110443370506],
              [-80.803176392470178, 35.58715311467445],
              [-80.80322957171326, 35.586888548167479],
              [-80.803559289340143, 35.586931219352792],
              [-80.803612467188543, 35.586666652662508],
              [-80.803942185197926, 35.586709321893842],
              [-80.803995361651559, 35.586444755020281],
              [-80.804325077876911, 35.58648742413299],
              [-80.804378252935791, 35.586222857076166],
              [-80.805037685415172, 35.586308191416229],
              [-80.805090858031562, 35.586043624034893],
              [-80.805420573182261, 35.58608628927864],
              [-80.805473744403926, 35.585821721714055],
              [-80.806792605408376, 35.585992374058556],
              [-80.806739439481063, 35.586256942171687],
              [-80.807398873618851, 35.586342263714883],
              [-80.807452038553905, 35.586077695302933],
              [-80.808111473067001, 35.586163012065796],
              [-80.80816463445629, 35.585898443345805],
              [-80.808494350633751, 35.585941100251695],
              [-80.808547510628387, 35.585676531348511],
              [-80.808877227208242, 35.585719187201605],
              [-80.808983544061334, 35.585190048986831],
              [-80.809313257789128, 35.585232703678976],
              [-80.809419571159225, 35.584703565013854],
              [-80.809749283138473, 35.584746218528466],
              [-80.809802438235366, 35.584481648090502],
              [-80.810132149513692, 35.584524300568866],
              [-80.810185303236111, 35.584259730848984],
              [-80.810515013813514, 35.584302382291185],
              [-80.810568166141351, 35.584037812388154],
              [-80.810897876017847, 35.584080462794098],
              [-80.810951026930994, 35.583815891806594],
              [-80.811280736106539, 35.583858541176326],
              [-80.811333884541909, 35.583593970923431],
              [-80.811663593016618, 35.583636619256879],
              [-80.811716741140557, 35.583372047903161],
              [-80.81204644891433, 35.583414695200432],
              [-80.812099594540584, 35.583150123680063],
              [-80.812429301613207, 35.583192769941057],
              [-80.812482445865101, 35.582928199138827],
              [-80.812812152216708, 35.582970843462427],
              [-80.81286529507409, 35.582706272477104],
              [-80.813195000744869, 35.582748916665658],
              [-80.813248142187632, 35.582484344595983],
              [-80.813907552473992, 35.582569630005821],
              [-80.813960691474549, 35.582305057611826],
              [-80.814290396090115, 35.582347698833033],
              [-80.814343532592957, 35.582083126272515],
              [-80.814673236487351, 35.582125765556192],
              [-80.814726372719164, 35.581861193697407],
              [-80.815056075912537, 35.581903831944963],
              [-80.815109210729673, 35.581639259001875],
              [-80.815438912138958, 35.58168189713092],
              [-80.815492045561754, 35.581417324004754],
              [-80.815821746270132, 35.581459961097714],
              [-80.81587487829853, 35.581195387788526],
              [-80.816204579408918, 35.58123802382871],
              [-80.816257708939688, 35.580973450353021],
              [-80.81658740932896, 35.58101608445579],
              [-80.816746793801386, 35.580222363337619],
              [-80.817076490290972, 35.580264996138446],
              [-80.81712961744293, 35.580000422196505],
              [-80.817459313251661, 35.5800430548625],
              [-80.817512437906075, 35.579778480754079],
              [-80.817842134096779, 35.579821111466117],
              [-80.817895257356867, 35.579556537174696],
              [-80.818224951743289, 35.579599166867091],
              [-80.818490557565752, 35.578276292274616],
              [-80.818820247080637, 35.578318921267595],
              [-80.819085838959523, 35.57699604312176],
              [-80.819415523582691, 35.577038670514121],
              [-80.819521754701697, 35.576509518337076],
              [-80.81985143757629, 35.576552144552274],
              [-80.819904552119922, 35.576287568251743],
              [-80.820234233170055, 35.576330192546038],
              [-80.820287346319517, 35.576065616062635],
              [-80.820617026688822, 35.576108240222176],
              [-80.820723248749772, 35.57557908686438],
              [-80.821052928453312, 35.575621708928871],
              [-80.821106037364814, 35.575357132054513],
              [-80.821435716387512, 35.575399753984236],
              [-80.821541931055563, 35.574870598926857],
              [-80.821871608329573, 35.574913219679402],
              [-80.821924714086137, 35.574648641496339],
              [-80.822254389555923, 35.574691261229368],
              [-80.822307493938652, 35.574426683764791],
              [-80.822637168687081, 35.574469301560441],
              [-80.822690271675668, 35.574204723913063],
              [-80.823019946826207, 35.574247340656186],
              [-80.823073048400573, 35.573982761924761],
              [-80.823732395459714, 35.574067993378222],
              [-80.823785494612622, 35.573803415224141],
              [-80.824115167604248, 35.573846029016771],
              [-80.824168265342877, 35.573581449778601],
              [-80.824497937653547, 35.573624063436426],
              [-80.824551032895144, 35.573359484032139],
              [-80.824880704484372, 35.573402095752613],
              [-80.824933799455465, 35.57313751705032],
              [-80.82559314157794, 35.573222737524262],
              [-80.825646232984013, 35.572958157613471],
              [-80.825975903517318, 35.573000766366945],
              [-80.826028994652816, 35.572736187157972],
              [-80.826358664484943, 35.572778794875532],
              [-80.826411753103102, 35.572514214599082],
              [-80.826741422234036, 35.572556821280607],
              [-80.826953770176146, 35.571498500079045],
              [-80.827283435463229, 35.571541105301307],
              [-80.827336520254477, 35.571276524534596],
              [-80.827666183737279, 35.571319128737514],
              [-80.8278254329107, 35.570525386665729],
              [-80.828155094680014, 35.570567988632725],
              [-80.828261256955429, 35.57003882616052],
              [-80.827931597260843, 35.569996223574414],
              [-80.827984678926569, 35.569731642416976],
              [-80.827655021728873, 35.569689039060414],
              [-80.82776118611639, 35.569159876902965],
              [-80.827431531359991, 35.569117272933617],
              [-80.827643860128518, 35.568058946881443],
              [-80.827973510695031, 35.568101550286457],
              [-80.82823890863672, 35.566778641531599],
              [-80.828568553209294, 35.566821243353104],
              [-80.828727783536991, 35.566027495573017],
              [-80.829057426416611, 35.566070096059867],
              [-80.829163575794908, 35.565540931290251],
              [-80.82949321582295, 35.565583530616671],
              [-80.82965243467234, 35.564789781367708],
              [-80.829982071904439, 35.564832379376121],
              [-80.830194353911182, 35.563774045009239],
              [-80.830300491724088, 35.563244877142978],
              [-80.831289386645025, 35.56337266326036],
              [-80.831342451900284, 35.563108079292569],
              [-80.832001713693828, 35.563193264396602],
              [-80.832160901078083, 35.562399510497187],
              [-80.832490529913727, 35.562442101726205],
              [-80.832702769607309, 35.561383761465486],
              [-80.833032394579405, 35.561426350334308],
              [-80.833138509703232, 35.560897179681739],
              [-80.833468131844256, 35.560939768291583],
              [-80.833521188380971, 35.560675182303285],
              [-80.833574244571565, 35.560410596273627],
              [-80.833903864964086, 35.560453183706606],
              [-80.83395691865833, 35.560188597511207],
              [-80.834286539452592, 35.560231183891645],
              [-80.834339591753434, 35.559966597513863],
              [-80.834669210723177, 35.560009181973925],
              [-80.834775313294969, 35.559480008795575],
              [-80.835104930536602, 35.559522592980088],
              [-80.835157979704618, 35.559258006196146],
              [-80.835487596245045, 35.559300589344851],
              [-80.835540645102256, 35.559036001460711],
              [-80.835870260941462, 35.559078583573537],
              [-80.835923307322815, 35.558813996425037],
              [-80.836252922440366, 35.558856576600803],
              [-80.836305967428402, 35.5585919892699],
              [-80.836965195524598, 35.558677147573064],
              [-80.837018238072048, 35.558412559918828],
              [-80.838336696433331, 35.558582865190274],
              [-80.838389734445755, 35.558318276930777],
              [-80.838719347740579, 35.558360850436763],
              [-80.838825420612324, 35.557831672610632],
              [-80.839155032179093, 35.557874245841106],
              [-80.839261100469585, 35.557345067584535],
              [-80.839590711370249, 35.557387638720229],
              [-80.839643743408033, 35.557123049848009],
              [-80.839973353607377, 35.557165619947966],
              [-80.840079415633014, 35.556636440879792],
              [-80.840409022980836, 35.556679009819796],
              [-80.840515080425448, 35.556149830321317],
              [-80.840844687127671, 35.556192398067886],
              [-80.840950741094034, 35.555663218122433],
              [-80.841280344944821, 35.555705784708977],
              [-80.841810583427929, 35.553059875690806],
              [-80.842140176049753, 35.553102439989722],
              [-80.842193196950618, 35.552837848810512],
              [-80.842852383363663, 35.55292297530999],
              [-80.842905400701014, 35.552658382923084],
              [-80.843234993368895, 35.552700944239213],
              [-80.843288009333747, 35.552436352571455],
              [-80.843617601300281, 35.552478912851889],
              [-80.843670615872142, 35.552214321002019],
              [-80.844000207137356, 35.552256880246716],
              [-80.844053220295748, 35.551992287313468],
              [-80.844382810880191, 35.552034846423815],
              [-80.844435822645607, 35.55177025330849],
              [-80.844765412508011, 35.551812810481877],
              [-80.844818422880593, 35.551548217184482],
              [-80.845148012062211, 35.551590774223392],
              [-80.845201021041746, 35.551326180743928],
              [-80.84553060839869, 35.551368735862745],
              [-80.845636623246662, 35.550839549399655],
              [-80.845966209957908, 35.550882103325179],
              [-80.846655256787187, 35.547442378750048],
              [-80.846984828026308, 35.547484929982268],
              [-80.847037828312139, 35.547220334521299],
              [-80.847090828272783, 35.546955739920463],
              [-80.846761258025083, 35.546913188986771],
              [-80.846867257880078, 35.546383999059366],
              [-80.847238247003816, 35.54453183166121],
              [-80.847567806725777, 35.54457438134331],
              [-80.847832782377253, 35.543251401422928],
              [-80.848162337209928, 35.543293949505752],
              [-80.848215329815332, 35.543029353264579],
              [-80.848544883946687, 35.543071900311936],
              [-80.848703856527422, 35.5422781100187],
              [-80.848374306639585, 35.542235563377297],
              [-80.848427297862358, 35.541970966972364],
              [-80.848097749367085, 35.541928419577339],
              [-80.848150741270615, 35.54166382237112],
              [-80.847821194167892, 35.541621274222337],
              [-80.847874186793234, 35.541356678017365],
              [-80.84721509569799, 35.541271578416648],
              [-80.847268090071509, 35.541006982452608],
              [-80.846938546099508, 35.540964431901948],
              [-80.846991541153756, 35.540699835136657],
              [-80.846661998574291, 35.54065728383226],
              [-80.846714995432421, 35.540392687150337],
              [-80.848033163657107, 35.540562887337757],
              [-80.848245127595817, 35.539504498897657],
              [-80.848574665220767, 35.539547045935798],
              [-80.848733631686216, 35.538753253974875],
              [-80.849063166516203, 35.538795799695691],
              [-80.849169139589335, 35.538266603979352],
              [-80.849498673773866, 35.538309148506947],
              [-80.849604642268375, 35.537779952362115],
              [-80.849275111259601, 35.537737407198243],
              [-80.849328096586646, 35.537472809197148],
              [-80.848669036637901, 35.537387718286816],
              [-80.848722023712995, 35.537123120526751],
              [-80.848062968342816, 35.537038026303307],
              [-80.848115957145382, 35.536773427882935],
              [-80.84745690525115, 35.536688330363077],
              [-80.847562886027191, 35.536159134864945],
              [-80.847233362692023, 35.536116585045065],
              [-80.847339344179204, 35.535587389665523],
              [-80.847009824385751, 35.535544839216278],
              [-80.847062815647448, 35.535280241155647],
              [-80.846733296143725, 35.535237689969485],
              [-80.846839280846893, 35.534708494892307],
              [-80.846509763761745, 35.534665942192277],
              [-80.846615749175939, 35.534136747233781],
              [-80.846286234550334, 35.534094194822444],
              [-80.846339227224192, 35.533829596980745],
              [-80.846009715072981, 35.533787042897757],
              [-80.846115702600343, 35.533257848258621],
              [-80.84578619178555, 35.533215293579765],
              [-80.845839186618548, 35.532950695880984],
              [-80.845509678319104, 35.532908141333095],
              [-80.845615667939029, 35.532378946112047],
              [-80.845286162058017, 35.53233639005034],
              [-80.845498140234625, 35.531277999700073],
              [-80.845168638885795, 35.531235443307729],
              [-80.845274628544999, 35.530706248161863],
              [-80.844945130737528, 35.530663691140276],
              [-80.845051121087138, 35.530134495212309],
              [-80.844721624636449, 35.530091938496135],
              [-80.844827615696644, 35.529562742687482],
              [-80.844498122766765, 35.529520184440756],
              [-80.844604114537773, 35.528990988751318],
              [-80.844274622944127, 35.528948429908937],
              [-80.84443361168934, 35.52815463603374],
              [-80.84410412468354, 35.52811207670301],
              [-80.844210117184645, 35.527582881171035],
              [-80.843880632617413, 35.527540321227832],
              [-80.843986625808853, 35.527011124913997],
              [-80.843657143680161, 35.526968564358405],
              [-80.843763138684707, 35.526439368147336],
              [-80.843433659015091, 35.526396807880474],
              [-80.843539653607252, 35.525867610904513],
              [-80.843210177458175, 35.525825049107226],
              [-80.843263174741381, 35.525560451158292],
              [-80.842933699984385, 35.525517888607695],
              [-80.843092695084039, 35.524724094005663],
              [-80.842763223832549, 35.52468153188488],
              [-80.842869220556153, 35.524152335267829],
              [-80.84253975172264, 35.524109771633327],
              [-80.842592751153475, 35.523845172946046],
              [-80.842263283711887, 35.523802608558171],
              [-80.842369282547651, 35.52327341226178],
              [-80.842039817544489, 35.523230847261537],
              [-80.84214581819306, 35.522701651068331],
              [-80.841816355628296, 35.522659085455764],
              [-80.841922356966649, 35.522129888481132],
              [-80.841592896840396, 35.522087322256269],
              [-80.841645898037541, 35.521822723849176],
              [-80.841316440405578, 35.521780156854277],
              [-80.841422442753228, 35.521250960217323],
              [-80.841092987559577, 35.521208392610113],
              [-80.841145989812674, 35.520943794363461],
              [-80.840816534908441, 35.520901226019646],
              [-80.840869537862162, 35.520636627873593],
              [-80.840540085452361, 35.520594058759741],
              [-80.84059308910679, 35.520329460714237],
              [-80.839934187396224, 35.520244319184265],
              [-80.839987192797636, 35.519979721380317],
              [-80.839657743517307, 35.519937149865477],
              [-80.839710749619385, 35.519672552162177],
              [-80.839381301710546, 35.519629978992789],
              [-80.839434308513262, 35.51936538138996],
              [-80.839104863119147, 35.519322808351859],
              [-80.8391578706225, 35.519058210849693],
              [-80.838828425497482, 35.519015636173769],
              [-80.839040457301152, 35.517957244505148],
              [-80.838711016727189, 35.517914670400437],
              [-80.838764025676824, 35.517650072060064],
              [-80.838434586494714, 35.517607497202093],
              [-80.838540604367566, 35.517078301600137],
              [-80.83821116760312, 35.51703572522873],
              [-80.838264177628673, 35.516771127950449],
              [-80.838529218060557, 35.515448136513513],
              [-80.83819978904161, 35.515405560978657],
              [-80.838305804446392, 35.514876364409957],
              [-80.837976377844981, 35.514833787361646],
              [-80.838082395061889, 35.514304590896955],
              [-80.837752970898393, 35.514262013236483],
              [-80.837805980585699, 35.51399741507629],
              [-80.83747655783435, 35.513954837563958],
              [-80.837529567099438, 35.513690238619887],
              [-80.837200146842136, 35.513647660337647],
              [-80.837253156807719, 35.513383061494373],
              [-80.837359176845112, 35.512853865472472],
              [-80.837688593964117, 35.512896443331464],
              [-80.837741601867137, 35.512631845127309],
              [-80.83807101828566, 35.512674421951111],
              [-80.838177032044612, 35.512145224221833],
              [-80.838506446736972, 35.512187800770519],
              [-80.838877472775806, 35.510335604266437],
              [-80.839206879389067, 35.51037817895034],
              [-80.839365882218146, 35.509584379035026],
              [-80.839695286018454, 35.509626951500259],
              [-80.839748285224346, 35.509362351307018],
              [-80.840077688344707, 35.5094049236383],
              [-80.840183683607435, 35.508875721947589],
              [-80.839854281497296, 35.508833150816358],
              [-80.839960277469999, 35.508303949246809],
              [-80.83963087779739, 35.508261377503494],
              [-80.839683876321601, 35.507996777250071],
              [-80.840013273846012, 35.508039348869048],
              [-80.840119266643256, 35.507510147074953],
              [-80.840448663543796, 35.507552718402252],
              [-80.840501657816844, 35.507288116861552],
              [-80.840831052914666, 35.50733068717043],
              [-80.840884045796258, 35.5070660854485],
              [-80.841213440193627, 35.507108654722174],
              [-80.841266432786057, 35.506844052802236],
              [-80.842584009605559, 35.507014318588716],
              [-80.842636996566313, 35.506749716081281],
              [-80.843295784956879, 35.506834843327539],
              [-80.843348769480244, 35.506570240497872],
              [-80.843678163137483, 35.506612802188087],
              [-80.843731146289997, 35.506348200078591],
              [-80.844060538144475, 35.506390760750371],
              [-80.844113519885056, 35.506126157558363],
              [-80.844442912141176, 35.506168717178312],
              [-80.844495892510849, 35.505904114706397],
              [-80.844825284066431, 35.505946673291206],
              [-80.844878261922062, 35.505682069753618],
              [-80.845207652777148, 35.505724627303202],
              [-80.845260630343617, 35.505460023567778],
              [-80.845919409916149, 35.505545136620611],
              [-80.845972385045329, 35.505280532562978],
              [-80.84630177374234, 35.505323087164889],
              [-80.846354747480191, 35.505058482926138],
              [-80.846684135476622, 35.505101036493009],
              [-80.846948989326734, 35.503778013158282],
              [-80.846619606560012, 35.503735460296355],
              [-80.846990394293812, 35.501883225495256],
              [-80.846661019193675, 35.501840672726232],
              [-80.846713988365352, 35.501576067222729],
              [-80.847043362419612, 35.501618619850838],
              [-80.847096330200188, 35.501354014166289],
              [-80.846766958293856, 35.501311461662482],
              [-80.846872894931209, 35.500782251356412],
              [-80.84654352435976, 35.500739698257256],
              [-80.846596493196017, 35.500475092734561],
              [-80.846267125097427, 35.500432537964279],
              [-80.846479001193288, 35.499374116938533],
              [-80.846808366190203, 35.499416670226886],
              [-80.846861333299941, 35.499152064504116],
              [-80.847190696494238, 35.499194616774155],
              [-80.847243662212875, 35.498930010870382],
              [-80.847573024706662, 35.498972562105315],
              [-80.847625989034228, 35.49870795602056],
              [-80.847955350827576, 35.498750506220496],
              [-80.848008313763984, 35.49848589995478],
              [-80.848337674856779, 35.498528449119654],
              [-80.848602477988308, 35.497205414700808],
              [-80.848931834197373, 35.497247962266648],
              [-80.84898479184821, 35.496983354594747],
              [-80.849972859430054, 35.497110992406419],
              [-80.849919904895998, 35.497375599600062],
              [-80.850907978701528, 35.497503228886892],
              [-80.850960930098239, 35.49723862127037],
              [-80.85161964558236, 35.497323701808234],
              [-80.85156669629778, 35.497588310607853],
              [-80.851896055613693, 35.497630850127273],
              [-80.851843107009202, 35.49789545812672],
              [-80.852501828768112, 35.497980534765198],
              [-80.852831191267413, 35.498023071726479],
              [-80.85288413671401, 35.497758462402956],
              [-80.853542858452229, 35.497843533395184],
              [-80.853648743045142, 35.497314314982603],
              [-80.85397810234025, 35.497356848855773],
              [-80.854083984542527, 35.496827629083455],
              [-80.854189864282773, 35.496298410069507],
              [-80.854519219739913, 35.496340942484871],
              [-80.854678033042802, 35.495547111898432],
              [-80.855007385707609, 35.495589642996862],
              [-80.855219127396481, 35.494531201098241],
              [-80.854889780016663, 35.494488670546581],
              [-80.855048586052106, 35.493694838221231],
              [-80.855377930294722, 35.493737368350033],
              [-80.855483797528805, 35.493208146012556],
              [-80.855813140025063, 35.493250674965573],
              [-80.855866071538046, 35.492986064055266],
              [-80.856195413313017, 35.49302859107199],
              [-80.856248343435254, 35.492763979980857],
              [-80.855919002685383, 35.49272145220371],
              [-80.856024863965573, 35.492192229282608],
              [-80.856354202624047, 35.492234756777918],
              [-80.856407131690673, 35.491970144666027],
              [-80.856736469648496, 35.492012671126325],
              [-80.85678939732432, 35.491748058833714],
              [-80.857118734581462, 35.491790584259192],
              [-80.857171660866598, 35.49152597178584],
              [-80.857500997423159, 35.491568496176399],
              [-80.857553922317521, 35.491303883522335],
              [-80.857883258173374, 35.491346406877881],
              [-80.858094947914637, 35.490287955333912],
              [-80.857765617364151, 35.490245433426381],
              [-80.858030227537441, 35.488922367896343],
              [-80.857700902560083, 35.48887984579931],
              [-80.857912588206389, 35.487821392148724],
              [-80.857583267756311, 35.487778869721424],
              [-80.857636189891807, 35.487514255891305],
              [-80.857306870811669, 35.487471731809656],
              [-80.857359792566356, 35.48720711899891],
              [-80.857030475978888, 35.487164594147366],
              [-80.857083398434085, 35.486899981437752],
              [-80.85675408323705, 35.486857455833203],
              [-80.856807006372321, 35.486592842323631],
              [-80.856859930285196, 35.486328229658682],
              [-80.856530616422276, 35.486285703458876],
              [-80.856636465263051, 35.485756476489321],
              [-80.856965775932963, 35.485799002424066],
              [-80.857071621323342, 35.485269775915192],
              [-80.856742311642563, 35.485227250279088],
              [-80.856848157723547, 35.484698022992141],
              [-80.857177464211404, 35.484740548363177],
              [-80.857336226507357, 35.483946706729405],
              [-80.857665530183141, 35.483989229882575],
              [-80.85777136753083, 35.483460001652261],
              [-80.85810066948109, 35.483502524530934],
              [-80.858259419207869, 35.482708680572664],
              [-80.858588718366633, 35.482751202134729],
              [-80.858641632825879, 35.482486586666617],
              [-80.860288127422578, 35.482699178578102],
              [-80.860341036330254, 35.482434563267134],
              [-80.860670335467333, 35.482477079183909],
              [-80.860617427605135, 35.482741694635784],
              [-80.86292253845609, 35.48303927840562],
              [-80.862869638690057, 35.483303895688444],
              [-80.865174774836206, 35.483601435724459],
              [-80.865227667283591, 35.483336817455694],
              [-80.865556973719436, 35.48337931918654],
              [-80.86550408121569, 35.483643937613181],
              [-80.866162696110763, 35.483728938691804],
              [-80.866215585442248, 35.483464320901653],
              [-80.867203506682884, 35.483591815401141],
              [-80.867150620488033, 35.483856433613781],
              [-80.86813854796776, 35.483983920490282],
              [-80.868191431025949, 35.483719301855139],
              [-80.870825912534315, 35.484059224574331],
              [-80.870878785781457, 35.483794604790042],
              [-80.872196030624863, 35.483964544131751],
              [-80.872248899324148, 35.483699922843421],
              [-80.873236832882199, 35.483827367324118],
              [-80.873289698120828, 35.483562746475101],
              [-80.874936257155937, 35.483775134135669],
              [-80.874989115720197, 35.483510512560208],
              [-80.875318427947676, 35.483552986902424],
              [-80.875371285121801, 35.483288365146628],
              [-80.875700595546391, 35.483330838471161],
              [-80.875753451309578, 35.483066215633784],
              [-80.876412072223673, 35.483151160203619],
              [-80.876464925551218, 35.482886537045168],
              [-80.87679423604078, 35.482929008290554],
              [-80.876847087978135, 35.482664384951789],
              [-80.87750570677683, 35.482749323594909],
              [-80.877558555176719, 35.482484699952167],
              [-80.877887864587706, 35.482527167332947],
              [-80.877940711597432, 35.482262543509897],
              [-80.878270020328145, 35.482305010757166],
              [-80.878322865947808, 35.48204038675393],
              [-80.87898148120783, 35.482125316499783],
              [-80.879034324391966, 35.481860692175466],
              [-80.880022246198422, 35.481988080577302],
              [-80.879969406130314, 35.482252704422471],
              [-80.880298715861215, 35.482295165264127],
              [-80.88024587541338, 35.482559790128882],
              [-80.88123380870752, 35.48268716772921],
              [-80.881180972153928, 35.482951792959504],
              [-80.881839597796088, 35.483036707177057],
              [-80.881892432258425, 35.482772081665424],
              [-80.882551057186745, 35.482856992025717],
              [-80.882603889213414, 35.482592366193103],
              [-80.882933201699444, 35.482634819883074],
              [-80.882986032336092, 35.482370193870224],
              [-80.883315343019063, 35.482412646542556],
              [-80.883368172265747, 35.482148020349555],
              [-80.883697482247342, 35.482190471987259],
              [-80.883750310104119, 35.481925845613951],
              [-80.884408930090004, 35.482010745007827],
              [-80.884461754409259, 35.481746118330818],
              [-80.884791064434538, 35.481788566988165],
              [-80.884843887363843, 35.481523940130963],
              [-80.885173195585864, 35.481566387770798],
              [-80.885226018227101, 35.481301760716228],
              [-80.885884633591843, 35.481386652131484],
              [-80.885937453797624, 35.481122024756061],
              [-80.88626676094033, 35.481164468531482],
              [-80.886319578654266, 35.48089984099304],
              [-80.886648885116571, 35.480942284635127],
              [-80.886701701440629, 35.480677656916498],
              [-80.887031008282477, 35.480720098605573],
              [-80.887083822114903, 35.480455470723967],
              [-80.887413128255375, 35.4804979113784],
              [-80.887465940697851, 35.480233283316657],
              [-80.888124550839507, 35.48031816258073],
              [-80.888177361948379, 35.480053534181074],
              [-80.889494579955098, 35.480223280552316],
              [-80.889547386537302, 35.479958651550611],
              [-80.889876690848595, 35.48000108554254],
              [-80.889929494939153, 35.479736456377893],
              [-80.890258799650951, 35.479778889318141],
              [-80.890522810752088, 35.478455739479202],
              [-80.890852109499079, 35.478498171740775],
              [-80.891010508206904, 35.477704279871247],
              [-80.890681212617523, 35.477661848932819],
              [-80.890839611364768, 35.47686795713053],
              [-80.891498195533387, 35.476952818188032],
              [-80.891550992336391, 35.476688187227218],
              [-80.891880284431707, 35.4767306158152],
              [-80.89193307984506, 35.476465984674455],
              [-80.892262370137132, 35.476508412245089],
              [-80.89236795784052, 35.475979149564147],
              [-80.892038668537737, 35.475936722291948],
              [-80.892249842874492, 35.474878196135997],
              [-80.891920559178658, 35.474835767613953],
              [-80.892026146913949, 35.474306504121962],
              [-80.891696865674092, 35.474264075888428],
              [-80.892277581737559, 35.471353122316195],
              [-80.891948313443478, 35.471310694718547],
              [-80.892001103988861, 35.471046062329165],
              [-80.891671837063328, 35.471003633077096],
              [-80.891724629411598, 35.4707390007719],
              [-80.891395363896507, 35.470696571667901],
              [-80.891448155823497, 35.470431938579992],
              [-80.891118891697872, 35.470389508722718],
              [-80.891171685448953, 35.470124876620289],
              [-80.890842422691705, 35.470082445108645],
              [-80.890895217143978, 35.469817813107646],
              [-80.890565955776268, 35.469775380842783],
              [-80.890671545739323, 35.469246117004374],
              [-80.890342286806387, 35.46920368412691],
              [-80.890395082296251, 35.468939051388332],
              [-80.890065824773643, 35.468896618658981],
              [-80.890171416832672, 35.468367354246645],
              [-80.889842162825516, 35.468324919986365],
              [-80.889894959373692, 35.46806028741144],
              [-80.889565705675295, 35.468017853316418],
              [-80.889618502924804, 35.467753220842901],
              [-80.889289251696425, 35.467710785076413],
              [-80.889342049668087, 35.467446153605614],
              [-80.889012799829089, 35.467403717085908],
              [-80.889065598480897, 35.467139084815393],
              [-80.88873635003138, 35.467096647542554],
              [-80.888789149405341, 35.466832016274701],
              [-80.888459902345176, 35.466789578248864],
              [-80.888565502109415, 35.466260314074468],
              [-80.888236257483882, 35.466217875436151],
              [-80.888447458165942, 35.465159347163073],
              [-80.888118218065472, 35.465116908193586],
              [-80.888329417401877, 35.464058378955791],
              [-80.888658652199027, 35.464100816478357],
              [-80.888922637009159, 35.46277765269307],
              [-80.888593407417048, 35.462735214972575],
              [-80.888646204379455, 35.462470581698135],
              [-80.888316976176569, 35.462428143224592],
              [-80.888369773860916, 35.462163510953019],
              [-80.888040547047339, 35.462121071726365],
              [-80.888093345411818, 35.461856438655197],
              [-80.887764119987381, 35.461813998675495],
              [-80.887922516152159, 35.461020099649495],
              [-80.887593295308648, 35.460977659181005],
              [-80.887646094387279, 35.460713026234949],
              [-80.887975314185596, 35.460755466562695],
              [-80.888028111874974, 35.460490833436928],
              [-80.888357332073909, 35.46053327271315],
              [-80.888410128374119, 35.460268639407566],
              [-80.888739347871933, 35.460311077649507],
              [-80.888792141681421, 35.46004644418138],
              [-80.889121360478256, 35.460088881388934],
              [-80.889279738889456, 35.459294979392837],
              [-80.889608954894953, 35.459337415284665],
              [-80.889767327074836, 35.458543512515561],
              [-80.889108902779853, 35.458458640665143],
              [-80.889161695261762, 35.458194006005634],
              [-80.888832485226018, 35.45815156887199],
              [-80.88888527843001, 35.457886935215328],
              [-80.888226861480774, 35.457802058548445],
              [-80.888279656409765, 35.457537424232918],
              [-80.887950449496273, 35.457494984699714],
              [-80.888003245126257, 35.457230350485887],
              [-80.887674039601805, 35.457187910199707],
              [-80.887726835932767, 35.456923276087615],
              [-80.888056040412195, 35.456965716233114],
              [-80.888108835375164, 35.456701082842713],
              [-80.888438038052087, 35.456743521971013],
              [-80.888490831604983, 35.456478887499642],
              [-80.888820034682524, 35.456521325576539],
              [-80.88887282684631, 35.4562566909255],
              [-80.889202028121346, 35.456299127985233],
              [-80.889254818896219, 35.45603449315459],
              [-80.889584019470163, 35.456076929180007],
              [-80.889742385494472, 35.455283024049656],
              [-80.889083989858875, 35.455198151966435],
              [-80.889031200172496, 35.455462786756051],
              [-80.888043605613447, 35.455335471515525],
              [-80.888096399536337, 35.455070837130918],
              [-80.887767203817234, 35.45502839705938],
              [-80.887819998420113, 35.454763761875171],
              [-80.886832415360402, 35.454636436738205],
              [-80.886885212775042, 35.454371802838438],
              [-80.887214405372092, 35.454414245309451],
              [-80.887425587368512, 35.453355706956124],
              [-80.887754776150814, 35.453398148872004],
              [-80.88796594734103, 35.452339608449599],
              [-80.888295132287709, 35.452382048909065],
              [-80.888400713167925, 35.451852777741202],
              [-80.889059079892746, 35.451937654515312],
              [-80.889111867176339, 35.451673018600353],
              [-80.889441049999419, 35.451715455055684],
              [-80.889493835894186, 35.451450818961142],
              [-80.88982301801633, 35.451493254382264],
              [-80.889875802522269, 35.451228618108203],
              [-80.890204983943377, 35.451271052495052],
              [-80.890257767060604, 35.451006416041487],
              [-80.890916127743409, 35.45109128187017],
              [-80.890968908447846, 35.450826645997708],
              [-80.891627268395652, 35.450911507069428],
              [-80.891680046645448, 35.450646869975643],
              [-80.892009225550154, 35.45068929948971],
              [-80.892220330930968, 35.449630750163521],
              [-80.891891156205816, 35.449588321212083],
              [-80.891996708901644, 35.449059045696302],
              [-80.891667536610043, 35.449016616132639],
              [-80.891878642054621, 35.447958065198478],
              [-80.892207809065539, 35.448000494216593],
              [-80.892313357635061, 35.44747121823562],
              [-80.892642524001616, 35.447513646061694],
              [-80.892748068004764, 35.446984369661621],
              [-80.893077232625572, 35.447026796312841],
              [-80.893288312284582, 35.445968240682809],
              [-80.893617473070123, 35.446010665877864],
              [-80.89372300874723, 35.445481387549272],
              [-80.894052166685682, 35.445523811586774],
              [-80.894104932413043, 35.445259172232319],
              [-80.89443408965063, 35.445301595235648],
              [-80.894539619064815, 35.444772315210976],
              [-80.894868774556599, 35.444814737039486],
              [-80.894921537163199, 35.444550097287689],
              [-80.895250693055374, 35.444592518064852],
              [-80.895303454273375, 35.444327878133748],
              [-80.895632608342282, 35.444370296992609],
              [-80.895685368171783, 35.4441056568823],
              [-80.895356215126583, 35.444063237262803],
              [-80.895408975657077, 35.443798597254393],
              [-80.895738127657538, 35.443841016733238],
              [-80.896054677269461, 35.442253171422493],
              [-80.896383822244005, 35.442295589181327],
              [-80.896489333928116, 35.441766306219876],
              [-80.896818478258425, 35.441808722786732],
              [-80.896871231438865, 35.44154408112469],
              [-80.89917524071501, 35.441840969339538],
              [-80.899227986239055, 35.441576326654662],
              [-80.899886276563251, 35.441661144036502],
              [-80.899939019654155, 35.441396501031747],
              [-80.900268163164711, 35.441438907357828],
              [-80.900373646226257, 35.440909620951331],
              [-80.900702789113353, 35.440952026986785],
              [-80.900913746913346, 35.439893452247333],
              [-80.900584609306236, 35.43985104675712],
              [-80.900742827177112, 35.439057115494194],
              [-80.901071961650359, 35.439099520562614],
              [-80.901177435315304, 35.438570232296996],
              [-80.901506568021674, 35.438612635289594],
              [-80.901559303855436, 35.438347991400349],
              [-80.901888435860755, 35.438390393358887],
              [-80.901941170306202, 35.438125749290542],
              [-80.902270300509187, 35.438168150232237],
              [-80.902375766259183, 35.437638860797648],
              [-80.902704894716479, 35.43768126056478],
              [-80.902810355901792, 35.437151970712307],
              [-80.903139483714654, 35.437194369287489],
              [-80.903244941415522, 35.436665078098628],
              [-80.90357406640257, 35.436707476417766],
              [-80.903732246671922, 35.435913538031521],
              [-80.904061369969952, 35.435955935018256],
              [-80.904166818956114, 35.435426642899635],
              [-80.904495939386138, 35.435469037827836],
              [-80.904601385989309, 35.434939744355788],
              [-80.904930504694804, 35.434982139010614],
              [-80.905035946733733, 35.434452845120994],
              [-80.905365063672434, 35.434495237700069],
              [-80.905523221031757, 35.433701296606394],
              [-80.905852334079086, 35.433743687887663],
              [-80.905957768505218, 35.433214393051578],
              [-80.906286880908198, 35.433256783141019],
              [-80.906392310770457, 35.432727487887554],
              [-80.906721421427719, 35.432769876802489],
              [-80.906774135339191, 35.432505228518409],
              [-80.907103244194232, 35.432547616416606],
              [-80.907155956738933, 35.432282968854807],
              [-80.907485064892796, 35.432325355719136],
              [-80.907537776028406, 35.432060707077142],
              [-80.907866883481233, 35.432103092907518],
              [-80.907919593228968, 35.431838444086623],
              [-80.90824869998066, 35.431880828883095],
              [-80.90830140834052, 35.431616179883285],
              [-80.908959621907158, 35.431700947398802],
              [-80.909012326733546, 35.431436298096962],
              [-80.909341432426231, 35.431478679932106],
              [-80.909394135864673, 35.431214030451386],
              [-80.909723241957451, 35.431256411235282],
              [-80.909775943986887, 35.430991760674409],
              [-80.910105048298504, 35.431034141342991],
              [-80.910210449259566, 35.430504840726414],
              [-80.910539552905519, 35.430547219301943],
              [-80.910697647548233, 35.429753268136032],
              [-80.911026747302884, 35.429795645414025],
              [-80.91113213955181, 35.429266343869593],
              [-80.911790337014125, 35.429351095165984],
              [-80.911843029973568, 35.429086443613301],
              [-80.912172127624629, 35.429128817789632],
              [-80.912224820318713, 35.428864166942041],
              [-80.912883014561615, 35.428948912333524],
              [-80.912935703701137, 35.428684260282829],
              [-80.91326480029295, 35.428726631497987],
              [-80.913370175453494, 35.428197327000682],
              [-80.913699270299617, 35.428239697041555],
              [-80.913804643120713, 35.427710392993994],
              [-80.914133735098901, 35.427752760976624],
              [-80.91423910443693, 35.427223455593911],
              [-80.914568194690673, 35.427265823303436],
              [-80.914620877249902, 35.427001170422876],
              [-80.914949967903439, 35.427043537081104],
              [-80.915055329903581, 35.42651423092439],
              [-80.915384417710328, 35.426556596425762],
              [-80.915437097129924, 35.426291942248284],
              [-80.915766184235508, 35.426334306715823],
              [-80.915818862288774, 35.426069653260903],
              [-80.916147949794208, 35.426112016677159],
              [-80.916200625337552, 35.42584736215975],
              [-80.916858798187846, 35.425932086048753],
              [-80.916911472400315, 35.425667431194718],
              [-80.917569643433112, 35.42575215124689],
              [-80.917622315213706, 35.4254874960737],
              [-80.918280486610044, 35.425572211352844],
              [-80.918333154878795, 35.425307556779288],
              [-80.920965844730858, 35.425646381055792],
              [-80.920913185918621, 35.425911036735535],
              [-80.921900453258502, 35.42603808107188],
              [-80.92184779723658, 35.426302737134655],
              [-80.922176887706215, 35.426345083305911],
              [-80.922124232407057, 35.426609740372186],
              [-80.922453325365296, 35.426652085773014],
              [-80.922400669645157, 35.426916742057806],
              [-80.922729763990887, 35.426959086705615],
              [-80.922677110073337, 35.42722374307516],
              [-80.923006205806658, 35.427266086970022],
              [-80.922953551510702, 35.427530744360482],
              [-80.923282648631528, 35.427573087502374],
              [-80.923229996116874, 35.427837744076292],
              [-80.925204593412616, 35.428091784991885],
              [-80.925151946822112, 35.428356442369925],
              [-80.925481048432886, 35.428398779538995],
              [-80.925533693978764, 35.428134122020488],
              [-80.925862794887863, 35.428176458155761],
              [-80.925915439046335, 35.427911800458638],
              [-80.926244539232485, 35.427954134658854],
              [-80.926297182003395, 35.427689476783137],
              [-80.926955382459383, 35.427774144007586],
              [-80.926902741777553, 35.42803880216406],
              [-80.928877356498859, 35.428292781453159],
              [-80.928929989812346, 35.428028122471943],
              [-80.929259092061542, 35.42807044939245],
              [-80.929311725088624, 35.427805790215167],
              [-80.930299031839652, 35.427932764276335],
              [-80.930351660267647, 35.427668103756034],
              [-80.931997172412039, 35.427879708332732],
              [-80.932049795295882, 35.427615047973759],
              [-80.932378897475559, 35.427657365531317],
              [-80.932326276737513, 35.427922026013078],
              [-80.93364269530781, 35.428091289637756],
              [-80.933590077304103, 35.428355950660062],
              [-80.934248290713725, 35.428440576491347],
              [-80.934195675557646, 35.428705237738541],
              [-80.935183001948658, 35.428832171098115],
              [-80.935130389583591, 35.429096832728],
              [-80.935788610599317, 35.429181450186199],
              [-80.935735999980864, 35.429446112058478],
              [-80.936065112598001, 35.429488419578945],
              [-80.936394225557976, 35.429530726205876],
              [-80.936289007471359, 35.430060050397081],
              [-80.936618122863308, 35.430102356411247],
              [-80.936460296275413, 35.430896341930762],
              [-80.936789415144204, 35.430938647472317],
              [-80.936736806626399, 35.431203308775174],
              [-80.937065927983781, 35.431245613545869],
              [-80.937013320189436, 35.431510275852006],
              [-80.937342441833096, 35.431552579887111],
              [-80.937289834719238, 35.431817241394],
              [-80.937618958851502, 35.431859544658401],
              [-80.937566352439717, 35.432124206267211],
              [-80.93822460272051, 35.432208810413641],
              [-80.938171998055466, 35.432473472264803],
              [-80.938501125305265, 35.43251577312931],
              [-80.938448521363753, 35.432780435983645],
              [-80.939106778959584, 35.43286503441135],
              [-80.939159379731933, 35.432600372195331],
              [-80.93948850798833, 35.432642669478021],
              [-80.939541108474501, 35.432378007065843],
              [-80.939870234949183, 35.432420304233638],
              [-80.939922834026319, 35.432155640741691],
              [-80.941239341353793, 35.432324818980604],
              [-80.941081558891909, 35.433118809169422],
              [-80.941410690249114, 35.433161101231349],
              [-80.941200311906854, 35.434219754558612],
              [-80.941529447785896, 35.434262046288076],
              [-80.94147685365833, 35.434526709434564],
              [-80.941805990946492, 35.434569001311992],
              [-80.941753397521168, 35.434833664560436],
              [-80.942082536175178, 35.434875954783315],
              [-80.942135128555748, 35.434611291394553],
              [-80.943451684664467, 35.434780444557028],
              [-80.943504271421787, 35.434515780586722],
              [-80.945479108450002, 35.43476948089819],
              [-80.945426527961757, 35.435034145709686],
              [-80.945755669339675, 35.435076425963459],
              [-80.945808249884379, 35.434811760994094],
              [-80.946137390581598, 35.434854041115308],
              [-80.946189969738782, 35.434589375967427],
              [-80.946519109712213, 35.434631654153641],
              [-80.946624263786475, 35.434102322578916],
              [-80.946953402012682, 35.434144599591214],
              [-80.947058551547485, 35.433615268501875],
              [-80.947387688026538, 35.433657544340342],
              [-80.947440261235258, 35.433392878598035],
              [-80.948756807498654, 35.433561972437992],
              [-80.948809376163908, 35.433297305195467],
              [-80.949796786598398, 35.433424115768439],
              [-80.949744221046046, 35.433688782530261],
              [-80.954023049441005, 35.43423820028994],
              [-80.953970499331945, 35.434502868799868],
              [-80.955287078228039, 35.434671889896414],
              [-80.955234531956251, 35.434936558928612],
              [-80.958526016870309, 35.435359048665312],
              [-80.9585785526926, 35.435094378232122],
              [-80.961540910900382, 35.43547453886768],
              [-80.961488385584076, 35.435739210543666],
              [-80.962475847593595, 35.435865914189762],
              [-80.962370801102963, 35.436395258284968],
              [-80.962699958989049, 35.436437491609176],
              [-80.962647435724861, 35.436702163748272],
              [-80.962976594998182, 35.436744396318907],
              [-80.962924073538034, 35.437009068541997],
              [-80.962713982050872, 35.438067756185404],
              [-80.962384818653575, 35.438025522896723],
              [-80.962227242753585, 35.438819537590355],
              [-80.961898075461974, 35.438777303005828],
              [-80.961635438330944, 35.440100658135762],
              [-80.961964610847929, 35.440142893420614],
              [-80.961859555452307, 35.44067223476663],
              [-80.962188730401763, 35.44071446943785],
              [-80.961926090597885, 35.442037822840135],
              [-80.962255272194852, 35.442080056398908],
              [-80.962202743811929, 35.44234472711134],
              [-80.961873561147939, 35.442302492511274],
              [-80.961821032478881, 35.442567163027398],
              [-80.96215021618795, 35.442609397767441],
              [-80.96183503878602, 35.444197418264586],
              [-80.962164229108538, 35.444239652951261],
              [-80.961901576341475, 35.445563002569187],
              [-80.962230773312044, 35.445605236143265],
              [-80.962178242548035, 35.44586990573454],
              [-80.962507439826666, 35.445912139473911],
              [-80.962349849717768, 35.446706148418833],
              [-80.962679051554119, 35.446748380765577],
              [-80.962416400120205, 35.448071728930849],
              [-80.962745607525278, 35.448113961084012],
              [-80.96264054574327, 35.448643299473353],
              [-80.962969756704567, 35.448685531896153],
              [-80.962917226334483, 35.448950200722408],
              [-80.963575649150044, 35.449034662301202],
              [-80.963523121651548, 35.449299332252373],
              [-80.963852334607282, 35.449341561390533],
              [-80.963799807790053, 35.449606230541931],
              [-80.964129022155149, 35.449648459827486],
              [-80.964076496041073, 35.449913129080279],
              [-80.964405711772045, 35.449955356710838],
              [-80.964353186382866, 35.450220026966328],
              [-80.965011622063855, 35.45030447980831],
              [-80.964959098401366, 35.450569149403975],
              [-80.965288317271771, 35.450611375534478],
              [-80.96523579431242, 35.45087604523146],
              [-80.965565015650114, 35.450918269689076],
              [-80.965512493393902, 35.451182939487531],
              [-80.9658417161193, 35.45122516319126],
              [-80.9657891934866, 35.451489834010211],
              [-80.966118417599716, 35.451532056960133],
              [-80.965803286242647, 35.453120075504607],
              [-80.965474055856845, 35.453077851714639],
              [-80.965421530806353, 35.453342521361925],
              [-80.965092299717284, 35.453300296538139],
              [-80.965039774358658, 35.453564965087587],
              [-80.964710541465053, 35.453522739247894],
              [-80.964605486571983, 35.454052077771223],
              [-80.964934721556574, 35.454094303891011],
              [-80.96488219406956, 35.454358972342362],
              [-80.965869903112278, 35.454485645776309],
              [-80.965922426361331, 35.454220976922869],
              [-80.966251662715209, 35.454263199467178],
              [-80.966199140511591, 35.454527868460694],
              [-80.967186853661801, 35.454654531168465],
              [-80.967134334274277, 35.454919201444497],
              [-80.967792813143035, 35.455003639066305],
              [-80.967740295482471, 35.455268308682321],
              [-80.968398778912828, 35.455352742990733],
              [-80.96845129446038, 35.455088072193512],
              [-80.968780535643432, 35.455130287866993],
              [-80.968833049825037, 35.454865617792393],
              [-80.97080849669122, 35.455118890437483],
              [-80.97075598988441, 35.455383561333939],
              [-80.972072966229661, 35.455552392734745],
              [-80.972020463262879, 35.455817064152271],
              [-80.972349709255752, 35.455859270133075],
              [-80.97229720699238, 35.456123941651732],
              [-80.972955702073847, 35.456208350310177],
              [-80.972903201559504, 35.456473022070085],
              [-80.973232450670068, 35.456515225649056],
              [-80.973179950859191, 35.456779897510195],
              [-80.973509202437029, 35.456822099415945],
              [-80.973456703329717, 35.4570867713783],
              [-80.973785955215746, 35.457128973449223],
              [-80.973838453255624, 35.456864300445787],
              [-80.974167705539642, 35.456906501464957],
              [-80.974115208523514, 35.457171173707131],
              [-80.976090732272283, 35.457424357437411],
              [-80.976143223014574, 35.457159684355858],
              [-80.976472477671479, 35.457201878216409],
              [-80.976524965924426, 35.456937204974231],
              [-80.976854218776353, 35.45697939781892],
              [-80.978171236930848, 35.457148161106055],
              [-80.978118753906216, 35.457412835047741],
              [-80.978448010615537, 35.457455023544846],
              [-80.978395527193015, 35.457719697605626],
              [-80.978724785290069, 35.457761885348603],
              [-80.978777266565487, 35.457497211165958],
              [-80.980094297067765, 35.457665951755928],
              [-80.980146773818419, 35.457401276975077],
              [-80.980476031534224, 35.457443459968708],
              [-80.980528506897187, 35.457178785009305],
              [-80.980857762785789, 35.457220966085913],
              [-80.980910236760991, 35.45695629094795],
              [-80.981239493069481, 35.456998471874037],
              [-80.98129196455551, 35.456733796575506],
              [-80.981621220138322, 35.456775975566593],
              [-80.981673690236704, 35.456511300089552],
              [-80.982002945137779, 35.456553478948074],
              [-80.982055413826529, 35.456288802391356],
              [-80.982384668023798, 35.456330980216073],
              [-80.982437135346913, 35.456066304382063],
              [-80.982766388818561, 35.456108480271766],
              [-80.982818854754072, 35.455843804259175],
              [-80.983148106442485, 35.455885980034459],
              [-80.983200572069876, 35.45562130292415],
              [-80.983859074358946, 35.455705650612018],
              [-80.983806611924379, 35.45597032798392],
              [-80.984135865717946, 35.456012500158174],
              [-80.984083403987142, 35.4562771776313],
              [-80.984412659168285, 35.456319349051384],
              [-80.984360198141275, 35.456584026625684],
              [-80.984689454710022, 35.456626197291641],
              [-80.98463699438679, 35.45689087496708],
              [-80.985624768202044, 35.457017382940222],
              [-80.985677225410171, 35.456752705746595],
              [-80.986335740523216, 35.456837039051273],
              [-80.986388195298133, 35.45657236153933],
              [-80.986717452872441, 35.456614526701962],
              [-80.986769905158212, 35.456349849029586],
              [-80.987099162028755, 35.456392013158478],
              [-80.987204064562874, 35.455862656498986],
              [-80.987533318604306, 35.455904820373547],
              [-80.987638217657633, 35.455375462378669],
              [-80.987967471051022, 35.455417625061671],
              [-80.988019918468552, 35.45515294587554],
              [-80.988678423043751, 35.455237266495821],
              [-80.988730868050169, 35.454972587892648],
              [-80.989060119254063, 35.45501474673118],
              [-80.989112562850835, 35.454750067048472],
              [-80.989771065294121, 35.454834381746053],
              [-80.989823506457753, 35.454569701745086],
              [-80.990152757719244, 35.454611858505466],
              [-80.99010031757912, 35.454876537744894],
              [-80.990429569126519, 35.454918693769208],
              [-80.990377129690415, 35.455183373109868],
              [-80.990706383704733, 35.455225527460676],
              [-80.990653944994577, 35.455490207803663],
              [-80.991312456139724, 35.455574514103027],
              [-80.991260019179336, 35.455839194686909],
              [-80.992247790495824, 35.455965647868666],
              [-80.992195357410282, 35.456230327913858],
              [-80.992524616612144, 35.456272477320304],
              [-80.992472184252748, 35.456537158367901],
              [-80.992801444842073, 35.456579307020149],
              [-80.992749012063101, 35.456843987285637],
              [-80.993078274039917, 35.456886135183666],
              [-80.993025843088503, 35.457150816433455],
              [-80.993355106430784, 35.457192962676018],
              [-80.993250244470801, 35.457722324455901],
              [-80.993579511369916, 35.457764470967128],
              [-80.993474650134658, 35.458293832871803],
              [-80.993803919444957, 35.458335977867179],
              [-80.993699058934538, 35.458865339896676],
              [-80.994028330678191, 35.458907484277546],
              [-80.993713748255473, 35.460495567553714],
              [-80.994043026638039, 35.460537712780273],
              [-80.99393816590063, 35.461067072928778],
              [-80.993608885448438, 35.461024928324001],
              [-80.993451586449623, 35.461818968323755],
              [-80.993122303179291, 35.461776821504529],
              [-80.993069869169872, 35.462041500674331],
              [-80.992740584116135, 35.461999353740687],
              [-80.992425963346761, 35.46358742896355],
              [-80.992755254698238, 35.463629577636851],
              [-80.992440626754444, 35.465217650517033],
              [-80.992111329127241, 35.465175501005362],
              [-80.991744238805708, 35.467028248175403],
              [-80.992073543754984, 35.467070398665214],
              [-80.991968660598943, 35.467599754530077],
              [-80.992627276786536, 35.467684052467128],
              [-80.99257483622597, 35.467948730179124],
              [-80.993562766770978, 35.468075171239732],
              [-80.99361520309175, 35.467810493126663],
              [-80.994603133552545, 35.467936924819924],
              [-80.994550700370056, 35.468201603352156],
              [-80.994880012635264, 35.468243746196229],
              [-80.994775146235341, 35.468773103441443],
              [-80.995104460934485, 35.468815245670733],
              [-80.995052028818833, 35.469079924365666],
              [-80.995381344883711, 35.469122064939391],
              [-80.995328912370695, 35.469386743753184],
              [-80.995658229845588, 35.469428884473714],
              [-80.995553368089745, 35.469958242227833],
              [-80.99588268797649, 35.470000381432321],
              [-80.995830257632079, 35.470265060390695],
              [-80.996159578906713, 35.470307198840814],
              [-80.996054719284786, 35.470836556920048],
              [-80.996384043015624, 35.470878695656673],
              [-80.996331613715967, 35.471143373876373],
              [-80.996660938834822, 35.471185511858465],
              [-80.996608510239639, 35.471450190178906],
              [-80.996937837847952, 35.471492327388361],
              [-80.996885409957173, 35.471757005809536],
              [-80.997214737829722, 35.47179914138151],
              [-80.997162310665445, 35.472063820804557],
              [-80.997491641027551, 35.472105955603993],
              [-80.997439214567819, 35.472370635127781],
              [-80.997768546317857, 35.472412769172635],
              [-80.997716120562572, 35.472677448797192],
              [-80.998045453700584, 35.472719582087485],
              [-80.997993028627505, 35.472984260911488],
              [-80.99766369444329, 35.472942127481431],
              [-80.997611268004391, 35.473206807028014],
              [-80.997940603234923, 35.473248940597728],
              [-80.997783324962199, 35.474042977619973],
              [-80.998112663673098, 35.474085110714526],
              [-80.997955385441202, 35.474879146903469],
              [-80.998284728734362, 35.474921279504599],
              [-80.998022596744022, 35.476244673070639],
              [-80.997693248219321, 35.476202539771052],
              [-80.997640819749677, 35.476467218227455],
              [-80.997311470520458, 35.476425083893972],
              [-80.997259040662726, 35.476689762171631],
              [-80.996600342239006, 35.476605490524541],
              [-80.996547908845102, 35.476870168501932],
              [-80.99621855965043, 35.476828031188354],
              [-80.996270992988983, 35.476563353368803],
              [-80.995282948391434, 35.476436936517992],
              [-80.995230510790179, 35.476701613035296],
              [-80.994571814375632, 35.476617330378232],
              [-80.994519374362099, 35.47688200747838],
              [-80.993860677200232, 35.476797720063772],
              [-80.993808234752251, 35.477062396845419],
              [-80.993478885098057, 35.477020252116837],
              [-80.993164216886143, 35.478608308443668],
              [-80.993493572818196, 35.478650454010555],
              [-80.99344112795481, 35.478915129617278],
              [-80.993111770976412, 35.478872983910655],
              [-80.992901983940797, 35.479931686288396],
              [-80.992572623096791, 35.479889538227447],
              [-80.992467724343996, 35.48041888937076],
              [-80.992310374146001, 35.481212913529802],
              [-80.992639741323714, 35.481255062271146],
              [-80.992534842301978, 35.481784411481584],
              [-80.995169807518721, 35.482121565163375],
              [-80.994855153600085, 35.483709619442457],
              [-80.995184533306286, 35.483751760333469],
              [-80.994974760603569, 35.484810461470452],
              [-80.995304144837633, 35.484852602025839],
              [-80.995199258516749, 35.485381952187538],
              [-80.994869873291563, 35.485339811334704],
              [-80.994817428571778, 35.485604486217007],
              [-80.995146814843508, 35.485646627209555],
              [-80.995041927573041, 35.486175977117632],
              [-80.995371317381654, 35.486218117477144],
              [-80.995266429734713, 35.486747467525504],
              [-80.995595821978327, 35.486789607269962],
              [-80.995543378688581, 35.487054282374977],
              [-80.995872772320681, 35.487096421364775],
              [-80.995820330815349, 35.487361095650748],
              [-80.99614972583592, 35.487403233885928],
              [-80.996097283955407, 35.487667909191543],
              [-80.996426680364479, 35.487710046672035],
              [-80.996374240268494, 35.487974721158714],
              [-80.996703638066066, 35.488016857884432],
              [-80.996598757861719, 35.488546207938548],
              [-80.996269356891176, 35.48850407185278],
              [-80.996216914667443, 35.488768746239643],
              [-80.995558112716495, 35.488684469285168],
              [-80.995453223079039, 35.489213818283325],
              [-80.995123821074216, 35.489171678176135],
              [-80.995071374707081, 35.489436352927115],
              [-80.995400777758533, 35.48947849317404],
              [-80.995348333175784, 35.489743167106056],
              [-80.995018927975764, 35.489701026737578],
              [-80.994861585752403, 35.490495048833971],
              [-80.995190992990388, 35.490537189639603],
              [-80.995138545937891, 35.49080186343226],
              [-80.995467956768437, 35.490844003446853],
              [-80.995415510420727, 35.49110867733981],
              [-80.995744921515779, 35.491150815716615],
              [-80.995482689881143, 35.492474185288792],
              [-80.995812106573737, 35.492516324370776],
              [-80.995602319253223, 35.493575018075205],
              [-80.995931741576655, 35.493617156803232],
              [-80.995879294949475, 35.49388183072103],
              [-80.9955498715793, 35.49383969185331],
              [-80.995392526461174, 35.49463371114863],
              [-80.99506310031488, 35.494591571868668],
              [-80.995010650204023, 35.494856244448094],
              [-80.994681223330815, 35.494814103232798],
              [-80.994314052367727, 35.496666810161216],
              [-80.994643487670487, 35.496708952336022],
              [-80.994486127001835, 35.497502969026137],
              [-80.994815564662858, 35.497545109842378],
              [-80.994763111836718, 35.497809782126986],
              [-80.995092550908751, 35.497851923089691],
              [-80.994987646302036, 35.498381266918337],
              [-80.995317088911946, 35.49842340724777],
              [-80.995212183928771, 35.498952751215654],
              [-80.995541628974351, 35.498994890929907],
              [-80.995489177556607, 35.49925956253454],
              [-80.995818623991084, 35.499301701493948],
              [-80.995766173278113, 35.499566373198604],
              [-80.996095621101489, 35.499608511403146],
              [-80.99604317109349, 35.49987318320796],
              [-80.996372620305792, 35.499915320657557],
              [-80.996320171002594, 35.500179992562451],
              [-80.996649621603893, 35.500222129257253],
              [-80.996597173005597, 35.500486801262198],
              [-80.997256077305721, 35.500571071346222],
              [-80.997203630459353, 35.500835743590876],
              [-80.997533085282527, 35.500877877863175],
              [-80.997480639141116, 35.501142550207952],
              [-80.997810095330948, 35.501184682824096],
              [-80.997757649894496, 35.501449355268853],
              [-80.998746022577222, 35.501575749088509],
              [-80.99869357993957, 35.501840421912533],
              [-80.999352498505175, 35.501924680571925],
              [-80.999300058721701, 35.50218935361741],
              [-80.999958980726916, 35.502273608076599],
              [-80.999854103219846, 35.502802954625352],
              [-81.000183567380432, 35.502845080783281],
              [-81.000131129712102, 35.503109754128687],
              [-81.000460595261643, 35.503151879531664],
              [-81.000355719890933, 35.503681226401085],
              [-81.000685187876485, 35.503723351188682],
              [-81.000580313232049, 35.504252698178647],
              [-81.000909783653626, 35.504294822350907],
              [-81.00085734739433, 35.504559495015641],
              [-81.001186819205003, 35.504601618432893],
              [-81.001134383673062, 35.504866292098889],
              [-81.001463857974798, 35.504908414742985],
              [-81.001411422045905, 35.505173088526995],
              [-81.001740897714456, 35.505215209514851],
              [-81.001688463592771, 35.50547988338068],
              [-81.002017939570493, 35.505522004532921],
              [-81.00191307237337, 35.506051351523418],
              [-81.002242550787116, 35.506093472060236],
              [-81.002190117711606, 35.506358145184976],
              [-81.002849079863466, 35.506442383817486],
              [-81.002796648540354, 35.506707057181686],
              [-81.003126131176288, 35.50674917529556],
              [-81.003073700558375, 35.50701384875962],
              [-81.003732668950292, 35.5070980825828],
              [-81.003680240084648, 35.507362756286291],
              [-81.004009725818364, 35.507404871094266],
              [-81.003904869178015, 35.507934219562436],
              [-81.004234358450148, 35.507976333736622],
              [-81.004181930653218, 35.508241007600112],
              [-81.004511420234493, 35.508283121938746],
              [-81.004406565709161, 35.508812469825621],
              [-81.004736058828911, 35.508854583530443],
              [-81.004683632078383, 35.509119256652603],
              [-81.004354137933589, 35.509077143709376],
              [-81.004196852533326, 35.509871164221089],
              [-81.003867356691131, 35.509829049946298],
              [-81.003814926478512, 35.510093722630288],
              [-81.003485428828796, 35.510051607339534],
              [-81.003380565305491, 35.510580953210201],
              [-81.003051065880925, 35.510538835844329],
              [-81.002684023231552, 35.512391543747022],
              [-81.003013529964548, 35.512433661188666],
              [-81.002961095307455, 35.512698334236319],
              [-81.00329060342969, 35.512740450922749],
              [-81.003185734059556, 35.513269796294622],
              [-81.003515245720621, 35.513311912347362],
              [-81.003410377054962, 35.513841256937667],
              [-81.003080864401539, 35.513799140587608],
              [-81.002923556225198, 35.514593155845212],
              [-81.002594039669432, 35.514551038199905],
              [-81.002541601129707, 35.514815709144422],
              [-81.002212084970964, 35.514773590446708],
              [-81.002159645064026, 35.515038262112924],
              [-81.002489163372388, 35.515080380932098],
              [-81.002436724170892, 35.515345052698002],
              [-81.002766243890846, 35.515387171663271],
              [-81.002608929536393, 35.516181185301726],
              [-81.002938452740338, 35.516223303790937],
              [-81.002728698949127, 35.51728198925457],
              [-81.003058227764811, 35.517324106487798],
              [-81.002953350911895, 35.517853449259206],
              [-81.004271475813823, 35.518021912200069],
              [-81.004323910761585, 35.517757239816405],
              [-81.005312505449012, 35.517883577649762],
              [-81.005260074723537, 35.518148249532665],
              [-81.006248675608632, 35.518274578830464],
              [-81.006196247683619, 35.518539251092008],
              [-81.006525783403362, 35.518581358901457],
              [-81.006473356183776, 35.518846031262591],
              [-81.007132430766305, 35.518930245377319],
              [-81.007080005299812, 35.519194917977565],
              [-81.007739083322747, 35.51927912789094],
              [-81.007686660711656, 35.519543800712036],
              [-81.008016201283411, 35.519585904466069],
              [-81.007858932336305, 35.520379923144411],
              [-81.008188476392604, 35.52042202642221],
              [-81.008031207488415, 35.521216044257983],
              [-81.008360756131594, 35.521258147041252],
              [-81.008255910963214, 35.521787492938898],
              [-81.008585460941063, 35.521829595124615],
              [-81.008533039420385, 35.522094267242487],
              [-81.008862591890093, 35.522136368654621],
              [-81.008810169995073, 35.522401041791525],
              [-81.00913972385429, 35.522443142448346],
              [-81.009087302642612, 35.522707814783487],
              [-81.009416857891338, 35.522749914684887],
              [-81.009364438509948, 35.523014588002489],
              [-81.011012225097872, 35.523225073883125],
              [-81.010959810590393, 35.523489746956926],
              [-81.011618929722957, 35.523573935684603],
              [-81.011671342112862, 35.523309261430605],
              [-81.012000902246726, 35.523351354294448],
              [-81.012053313269575, 35.523086680762148],
              [-81.013701108271917, 35.523297129249826],
              [-81.013648703589638, 35.523561803461085],
              [-81.014307826074287, 35.523645976690773],
              [-81.014255423167839, 35.523910652042197],
              [-81.014584985959331, 35.523952737003498],
              [-81.014532583736354, 35.524217411553089],
              [-81.015191712462524, 35.524301579971258],
              [-81.015139311993096, 35.524566254759705],
              [-81.015468879030124, 35.524608338198242],
              [-81.015416478164141, 35.524873013104369],
              [-81.015746046568381, 35.524915094886246],
              [-81.015693646430677, 35.525179770792967],
              [-81.016023216224312, 35.52522185181931],
              [-81.015918419179101, 35.525751201952353],
              [-81.016247990307519, 35.525793282381059],
              [-81.016195592319988, 35.52605795752681],
              [-81.016525165962747, 35.526100038082944],
              [-81.016472768681083, 35.526364713328149],
              [-81.017791070771267, 35.526533023556254],
              [-81.017738677338869, 35.52679769931904],
              [-81.01872741176318, 35.526923923894586],
              [-81.018675021109814, 35.527188599134298],
              [-81.019004600630069, 35.527230672949301],
              [-81.018952210682741, 35.527495348288241],
              [-81.019281792672459, 35.527537420428182],
              [-81.019229403453608, 35.527802096767701],
              [-81.019558985730399, 35.52784416817034],
              [-81.019454209443268, 35.528373520088287],
              [-81.019783794157306, 35.528415590874879],
              [-81.019679018598765, 35.528944942911117],
              [-81.019349430686603, 35.528902871864069],
              [-81.019297040806833, 35.529167548142354],
              [-81.01962662976689, 35.529209619328803],
              [-81.019574240570762, 35.529474294805063],
              [-81.019903830920512, 35.529516365235992],
              [-81.01985144355541, 35.52978104169437],
              [-81.020181034169738, 35.529823110486795],
              [-81.020128647510745, 35.530087787044472],
              [-81.021447022054062, 35.530256056452195],
              [-81.021499404544045, 35.529991380238194],
              [-81.021828997991918, 35.53003344543864],
              [-81.02188138017226, 35.52976876812545],
              [-81.022540565997474, 35.529852895562662],
              [-81.022592944637907, 35.529588217948998],
              [-81.023252129734004, 35.529672341527572],
              [-81.02330450593702, 35.529407663595045],
              [-81.02363409794981, 35.529449723902431],
              [-81.023686472763288, 35.529185045790484],
              [-81.024345655717909, 35.529269163441661],
              [-81.024398028094097, 35.529004485010795],
              [-81.025057209194586, 35.529088597920619],
              [-81.025109579155824, 35.528823920072135],
              [-81.025439169710623, 35.528865974585457],
              [-81.02538680081966, 35.529130653474418],
              [-81.026375577699937, 35.529256812963702],
              [-81.026323212713493, 35.529521492212169],
              [-81.026652806730468, 35.529563543424239],
              [-81.026600441347924, 35.529828222790385],
              [-81.026930037856886, 35.529870273228376],
              [-81.02687767315804, 35.53013495179249],
              [-81.027207269976515, 35.530177002394389],
              [-81.027154907086583, 35.530441681039271],
              [-81.027484505294566, 35.530483730885599],
              [-81.027432143110872, 35.53074840962968],
              [-81.027761742685854, 35.530790457819066],
              [-81.02770938120851, 35.531055136662232],
              [-81.028368584604181, 35.531139231517471],
              [-81.028316224881138, 35.531403910599195],
              [-81.0289754317389, 35.531488002152948],
              [-81.028923073770315, 35.531752681473108],
              [-81.029582284067601, 35.531836768824185],
              [-81.029529927853403, 35.532101448382797],
              [-81.030189142715443, 35.532185532414033],
              [-81.030518750658771, 35.532227573087084],
              [-81.030466397246883, 35.532492253023335],
              [-81.031455226270111, 35.532618370090233],
              [-81.031402875660717, 35.532883050404166],
              [-81.032062098813071, 35.532967124252124],
              [-81.03211444732645, 35.532702443659566],
              [-81.034421731757803, 35.532996672057195],
              [-81.034369391683256, 35.533261353606214],
              [-81.035028620959636, 35.533345410441981],
              [-81.034976282639633, 35.533610092229303],
              [-81.035965133372997, 35.533736171620035],
              [-81.035912797855858, 35.534000853784825],
              [-81.036572035841345, 35.534084902208541],
              [-81.036519702078863, 35.534349584611704],
              [-81.036849323171765, 35.534391607160266],
              [-81.036796990116031, 35.53465628966245],
              [-81.037456234342372, 35.53474033327268],
              [-81.0374039041439, 35.535005015994614],
              [-81.039711278646408, 35.535299140029949],
              [-81.039658955443457, 35.535563823686182],
              [-81.040647839420743, 35.535689863047018],
              [-81.040595519020968, 35.535954547080621],
              [-81.041254778808735, 35.536038569124017],
              [-81.041202460163973, 35.536303253395808],
              [-81.042191355550216, 35.536429280165237],
              [-81.042139039685892, 35.536693963913066],
              [-81.04246867431479, 35.536735971166614],
              [-81.042416359180024, 35.537000655914667],
              [-81.043405266053696, 35.537126672759115],
              [-81.043352953699554, 35.537391356983228],
              [-81.04368259178996, 35.537433360934614],
              [-81.04363028014285, 35.537698045257649],
              [-81.043959919622679, 35.537740048453045],
              [-81.043907608682503, 35.538004732874924],
              [-81.044237249551941, 35.53804673531436],
              [-81.044132629869452, 35.538576105198437],
              [-81.044462273153954, 35.538618106119749],
              [-81.044357654202841, 35.539147476121109],
              [-81.044687299925158, 35.539189476425584],
              [-81.044634990423248, 35.539454161063652],
              [-81.044964637557825, 35.539496161513405],
              [-81.044912329865539, 35.539760846231765],
              [-81.045571627255086, 35.539844844724001],
              [-81.045519321318196, 35.540109529680358],
              [-81.045848971550456, 35.540151526821688],
              [-81.045796666320527, 35.540416211876888],
              [-81.046126317965076, 35.540458209163404],
              [-81.045969402247408, 35.541252263621779],
              [-81.046299058480827, 35.541294260411988],
              [-81.046194448017644, 35.541823629833317],
              [-81.046524106689034, 35.541865626006576],
              [-81.046367192119874, 35.542659679793651],
              [-81.04603753142878, 35.542617684085613],
              [-81.045932917530166, 35.543147052202293],
              [-81.046262581443315, 35.543189049071259],
              [-81.046157968299084, 35.54371841820592],
              [-81.046487634627653, 35.54376041355669],
              [-81.046539940884756, 35.543495729311701],
              [-81.048517939800462, 35.543747684485496],
              [-81.048465640936499, 35.544012369546664],
              [-81.048795309653826, 35.544054358630831],
              [-81.048743011497066, 35.544319043790729],
              [-81.049072681626825, 35.544361033019953],
              [-81.048968087489243, 35.544890403478171],
              [-81.049297760034364, 35.544932391189214],
              [-81.049245462950907, 35.545197076506128],
              [-81.049575138011051, 35.54523906434359],
              [-81.049470546019975, 35.545768435115811],
              [-81.049800222392946, 35.545810421453673],
              [-81.049643334441186, 35.546604477735514],
              [-81.049973015403651, 35.546646463576771],
              [-81.049920719418182, 35.546911149068443],
              [-81.050250401770285, 35.546953134153554],
              [-81.050198106469324, 35.547217818842697],
              [-81.051516843506704, 35.547385751687862],
              [-81.051569134590778, 35.547121065541134],
              [-81.052228503048113, 35.547205025863256],
              [-81.052176214061205, 35.547469712288098],
              [-81.052835587083138, 35.54755366928859],
              [-81.052783299829258, 35.547818355049998],
              [-81.0531129884519, 35.547860332337059],
              [-81.053060703030937, 35.548125019079656],
              [-81.053390391940667, 35.548166995629067],
              [-81.053338107227106, 35.548431682470252],
              [-81.053667797503579, 35.548473657362287],
              [-81.05356322912742, 35.549003030300192],
              [-81.053892921865128, 35.549045005476252],
              [-81.053840638225353, 35.549309692474083],
              [-81.054170333432751, 35.549351665974022],
              [-81.054118050500321, 35.549616353070526],
              [-81.05576653476048, 35.549826210576541],
              [-81.055818812472637, 35.549561523686016],
              [-81.056148509749576, 35.549603492715228],
              [-81.056200784946412, 35.549338804762634],
              [-81.05751957214548, 35.549506670487474],
              [-81.057571842806695, 35.549241981938295],
              [-81.057901539691869, 35.549283946212789],
              [-81.058231236895338, 35.549325908690768],
              [-81.058178967228642, 35.549590597536692],
              [-81.059168064054191, 35.5497164809168],
              [-81.059115798272245, 35.5499811692194],
              [-81.059775199966126, 35.550065087574502],
              [-81.059722935940556, 35.55032977611463],
              [-81.060712044173712, 35.550455646898733],
              [-81.060659782953039, 35.55072033581537],
              [-81.060989487443408, 35.550762291692408],
              [-81.06093722693042, 35.551026980707562],
              [-81.061596640088581, 35.551110888232607],
              [-81.061544381354977, 35.55137557838637],
              [-81.061874088942901, 35.55141753095419],
              [-81.061769573606867, 35.551946909597177],
              [-81.06209928363333, 35.551988861547613],
              [-81.062047027053936, 35.552253550937955],
              [-81.062376738469922, 35.55229550213199],
              [-81.062272225283024, 35.552824881087794],
              [-81.062601940240313, 35.552866831645609],
              [-81.062497427786809, 35.553396210717231],
              [-81.062827145182737, 35.553438160657677],
              [-81.062774888941902, 35.553702850280935],
              [-81.063104607727496, 35.553744799464937],
              [-81.06300009740022, 35.554274177947995],
              [-81.063329818624467, 35.554316126514529],
              [-81.063225309030798, 35.554845505113263],
              [-81.063555032693571, 35.554887453062243],
              [-81.063450524936357, 35.555416831757952],
              [-81.063780251037869, 35.555458779089435],
              [-81.063675742911514, 35.555988157919394],
              [-81.064005471451708, 35.55603010463345],
              [-81.063953218488734, 35.556294794567719],
              [-81.064282949498661, 35.556336739605371],
              [-81.064178443521769, 35.556866118747578],
              [-81.064508176993357, 35.5569080640688],
              [-81.064455923979352, 35.557172753276703],
              [-81.064785658840833, 35.557214697841538],
              [-81.064733407637561, 35.557479387129],
              [-81.065063143888921, 35.557521330937341],
              [-81.065115394043289, 35.557256641510826],
              [-81.06775328676585, 35.55759215594454],
              [-81.067701045002821, 35.557856846482451],
              [-81.068030784299808, 35.557898781330429],
              [-81.067978543244678, 35.558163471966544],
              [-81.068308283954494, 35.558205406959182],
              [-81.068203804022247, 35.558734788368731],
              [-81.068533547170858, 35.558776722743723],
              [-81.068481308270862, 35.559041412615841],
              [-81.068811052809352, 35.559083346234274],
              [-81.0687588135374, 35.559348037124572],
              [-81.071726543438402, 35.559725395160996],
              [-81.07167431434641, 35.559990086340733],
              [-81.072993319812298, 35.560157777972755],
              [-81.072941094598463, 35.560422470568618],
              [-81.073270848136119, 35.560464391146624],
              [-81.073218623607445, 35.560729082939496],
              [-81.073878133803163, 35.560812921686846],
              [-81.073825911054783, 35.561077614617965],
              [-81.074485424711852, 35.561161450060879],
              [-81.074433204823663, 35.561426143210205],
              [-81.075092721942113, 35.56150997534882],
              [-81.075040502685312, 35.561774667852774],
              [-81.075700023265071, 35.561858496686874],
              [-81.075647806868545, 35.562123189409014],
              [-81.076637094544253, 35.562248926341276],
              [-81.076584880954258, 35.562513619439315],
              [-81.078893238823639, 35.562806973427165],
              [-81.078841032236809, 35.563071667456207],
              [-81.079500568369113, 35.563155474710037],
              [-81.079448363539854, 35.563420168975959],
              [-81.079778133729292, 35.563462071839496],
              [-81.079725929608372, 35.563726766203466],
              [-81.080055701187689, 35.563768668310217],
              [-81.080003497775238, 35.564033362772356],
              [-81.080333270721312, 35.564075263221113],
              [-81.080281069143382, 35.564339958663645],
              [-81.080610843479334, 35.564381858355553],
              [-81.080558641483933, 35.564646553013908],
              [-81.081218193299335, 35.564730350889818],
              [-81.081165994164579, 35.564995045766061],
              [-81.081495771621007, 35.56503694304886],
              [-81.081547969706634, 35.564772248033826],
              [-81.083856415244824, 35.565065504748603],
              [-81.083804224503808, 35.565330200734977],
              [-81.084463785207802, 35.565413980075334],
              [-81.084411597327488, 35.565678676279632],
              [-81.085071161492465, 35.565762452315056],
              [-81.085018975370048, 35.566027148756064],
              [-81.085348759012703, 35.5660690355692],
              [-81.085400945188823, 35.565804338970352],
              [-81.086060511397335, 35.56588810963207],
              [-81.086008328422707, 35.566152806489391],
              [-81.086338113087166, 35.566194690615561],
              [-81.087657258436508, 35.56636221720585],
              [-81.087605079264762, 35.56662691473506],
              [-81.08925402414188, 35.566836303735386],
              [-81.089306196964174, 35.566571605531507],
              [-81.089635986113933, 35.566613479781203],
              [-81.089688157546391, 35.56634878139787],
              [-81.090017944907515, 35.566390655533368],
              [-81.090070114950166, 35.566125956970588],
              [-81.090399902705542, 35.566167830052684],
              [-81.090452070255424, 35.565903131329499],
              [-81.09078185727877, 35.56594500247607],
              [-81.090834024541735, 35.565680303554473],
              [-81.091163809776546, 35.565722174586867],
              [-81.091493596431562, 35.565764043803547],
              [-81.091441431267114, 35.566028743002633],
              [-81.091771219335129, 35.566070612363546],
              [-81.091719054879619, 35.566335311660588],
              [-81.092048844314178, 35.566377179363286],
              [-81.091996681670395, 35.566641878739361],
              [-81.092326471414921, 35.566683746605307],
              [-81.092274309480104, 35.566948446079238],
              [-81.092604101694235, 35.566990312268089],
              [-81.092499777798736, 35.567519711390084],
              [-81.092829572452032, 35.567561576960642],
              [-81.09277741159444, 35.56782627658967],
              [-81.093107207637559, 35.567868141403252],
              [-81.09305504748896, 35.568132841130144],
              [-81.0933848449219, 35.568174705186713],
              [-81.093280524598939, 35.568704104814628],
              [-81.09361032557409, 35.568745968233891],
              [-81.093506005964969, 35.569275367074816],
              [-81.093835809402535, 35.569317230776868],
              [-81.093731490530431, 35.569846629731984],
              [-81.094061296384112, 35.569888491914504],
              [-81.094009138026621, 35.570153191009389],
              [-81.094668751774464, 35.570236913884962],
              [-81.09461659517541, 35.570501613216365],
              [-81.094946404149283, 35.570543472989151],
              [-81.094894248259351, 35.570808172418396],
              [-81.095553868247507, 35.570891890474314],
              [-81.09550171411604, 35.571156590140021],
              [-81.096161338644563, 35.571240303970221],
              [-81.096109187374566, 35.571505003853382],
              [-81.096439001198974, 35.571546859563483],
              [-81.096334697554056, 35.572076260423891],
              [-81.096664513817871, 35.572118115515543],
              [-81.096560211989953, 35.572647515569528],
              [-81.096890030693189, 35.572689370042823],
              [-81.096785728499711, 35.573218770229644],
              [-81.097115550722165, 35.573260623164202],
              [-81.097063400164288, 35.573525323334955],
              [-81.097393223800012, 35.573567176413633],
              [-81.097288923762235, 35.57409657690966],
              [-81.097618749837366, 35.574138429369818],
              [-81.097514451616846, 35.574667829059386],
              [-81.097844280131625, 35.574709680901044],
              [-81.097687832856508, 35.5755037810045],
              [-81.098017664860464, 35.575545632366357],
              [-81.097965515754382, 35.575810331863146],
              [-81.098295349148273, 35.575852182467735],
              [-81.098138904062651, 35.576646282010742],
              [-81.098468742049008, 35.576688132116345],
              [-81.098155848961127, 35.578276330027165],
              [-81.098485693585843, 35.578318180068678],
              [-81.098433544224434, 35.578582879571776],
              [-81.09876339023927, 35.578624728856113],
              [-81.098711241587139, 35.578889428456904],
              [-81.100030633202564, 35.579056815388029],
              [-81.099978488408908, 35.579321515502258],
              [-81.103277003954233, 35.579739920568045],
              [-81.10332913604438, 35.579475219106115],
              [-81.104978401307534, 35.579684386450779],
              [-81.105030528911769, 35.579419684235944],
              [-81.105690234848907, 35.579503344272936],
              [-81.105742360013352, 35.579238641740027],
              [-81.106072212442612, 35.579280470276188],
              [-81.10612433511379, 35.579015767582874],
              [-81.10645418683356, 35.579057595084635],
              [-81.106506308114831, 35.578792892211716],
              [-81.106836160228227, 35.578834718659984],
              [-81.106940399670435, 35.578305312514338],
              [-81.107270248921452, 35.578347137808784],
              [-81.107478719500889, 35.577288323570691],
              [-81.107808563790087, 35.577330147434303],
              [-81.107860679517159, 35.577065442958009],
              [-81.110169598313874, 35.577358182934255],
              [-81.110499445377897, 35.577399999473542],
              [-81.110447338048701, 35.577664705058005],
              [-81.110777186479169, 35.577706519938793],
              [-81.110725080962681, 35.577971225601665],
              [-81.111054930783041, 35.578013039725015],
              [-81.111002825976186, 35.578277745485458],
              [-81.111332677186439, 35.57831955885154],
              [-81.111280573089218, 35.578584264709541],
              [-81.111610425689378, 35.578626077318248],
              [-81.111558322301903, 35.578890783273806],
              [-81.111888176291885, 35.578932595125167],
              [-81.111836073590595, 35.57919730027708],
              [-81.112165928993988, 35.579239112272226],
              [-81.112113827002347, 35.579503817521669],
              [-81.112443683795661, 35.579545628759469],
              [-81.112391582513709, 35.579810334106355],
              [-81.112721441776642, 35.579852143666386],
              [-81.112669341204381, 35.580116849110865],
              [-81.112999200777679, 35.580158658833874],
              [-81.112947102018211, 35.580423364356683],
              [-81.113276962957968, 35.580465172421093],
              [-81.113224863805044, 35.580729878060573],
              [-81.113554727261416, 35.580771686249584],
              [-81.113502628818225, 35.581036391986551],
              [-81.113832493641056, 35.581078198516877],
              [-81.113780395907639, 35.581342904351331],
              [-81.114110262120434, 35.581384710124318],
              [-81.114006067733129, 35.581914121947115],
              [-81.114335936385686, 35.581955927101149],
              [-81.114283840834872, 35.582220633070293],
              [-81.114613710877521, 35.582262437466895],
              [-81.114561614933436, 35.582527143552674],
              [-81.114891486365991, 35.582568947191803],
              [-81.11473520072235, 35.583363064698027],
              [-81.115065076748024, 35.583404867837366],
              [-81.115012981543288, 35.583669574035937],
              [-81.115342857855808, 35.583711376437059],
              [-81.115290764440587, 35.583976081812651],
              [-81.115620642143142, 35.584017883456283],
              [-81.115516455312729, 35.584547295281631],
              [-81.11584633655842, 35.584589096287019],
              [-81.115742149364351, 35.585118508244186],
              [-81.116072033050173, 35.585160308630527],
              [-81.116019941084389, 35.585425014216156],
              [-81.116349826160175, 35.585466813844988],
              [-81.116297733801176, 35.585731519547103],
              [-81.116627620267138, 35.585773318418489],
              [-81.116575529744708, 35.586038025099924],
              [-81.116905417600762, 35.586079823213737],
              [-81.116801236485429, 35.586609234947183],
              [-81.117131126781601, 35.586651032441885],
              [-81.117079037315449, 35.586915738375758],
              [-81.117408929001726, 35.586957535112923],
              [-81.117304750022583, 35.587486946252184],
              [-81.117634645252195, 35.587528742351083],
              [-81.117530467036389, 35.588058154503955],
              [-81.117860364706303, 35.588099949983551],
              [-81.117808275586668, 35.588364656146354],
              [-81.118138174623184, 35.588406449967266],
              [-81.118086087316811, 35.588671156208086],
              [-81.118415987767051, 35.588712950172614],
              [-81.118363900067436, 35.588977656529963],
              [-81.118693801907838, 35.589019449736888],
              [-81.118641715997981, 35.589284155271066],
              [-81.118971619228546, 35.589325947720326],
              [-81.118919534028734, 35.589590653351749],
              [-81.119249438649476, 35.589632445043506],
              [-81.119197354183285, 35.589897151673391],
              [-81.119527260194218, 35.58993894260751],
              [-81.119475176414539, 35.590203648433409],
              [-81.119805083815663, 35.590245438609927],
              [-81.119753000746073, 35.590510144533077],
              [-81.120082909537388, 35.59055193395195],
              [-81.120030827177914, 35.590816639972331],
              [-81.120360737359434, 35.590858428633553],
              [-81.120308655710062, 35.591123134751165],
              [-81.120638568385075, 35.591164922635421],
              [-81.120482324547311, 35.591959041156251],
              [-81.120152408745298, 35.591917253757813],
              [-81.12004824238609, 35.592446665622191],
              [-81.119718325903506, 35.592404876130551],
              [-81.119197459843974, 35.595051929848928],
              [-81.119527389035554, 35.595093720686123],
              [-81.11942321338752, 35.595623130678362],
              [-81.119093283198836, 35.595581339545049],
              [-81.118989104089806, 35.596110749095381],
              [-81.118659171037223, 35.596068956808466],
              [-81.118607080485717, 35.596333661824197],
              [-81.118277146723045, 35.596291868502774],
              [-81.118225054757559, 35.596556572437585],
              [-81.117895120284643, 35.596514778081662],
              [-81.117790932152914, 35.597044186470946],
              [-81.117460995919544, 35.597002390942059],
              [-81.117304707456341, 35.597796502359735],
              [-81.11697476841222, 35.597754705519492],
              [-81.116662174825123, 35.599342923723455],
              [-81.116332229842669, 35.599301126057739],
              [-81.116280128466926, 35.599565828060719],
              [-81.115950181647477, 35.599524028478449],
              [-81.11579387239847, 35.600318136527257],
              [-81.11546392387153, 35.600276335614375],
              [-81.115255501424357, 35.601335143429431],
              [-81.1149255479327, 35.601293341085892],
              [-81.114717115816532, 35.602352146783154],
              [-81.114387158463401, 35.602310342989682],
              [-81.113918153778343, 35.604692651063068],
              [-81.114248120585529, 35.60473445610252],
              [-81.114091782317402, 35.605528557266751],
              [-81.114421753719839, 35.605570361806002],
              [-81.114265415540586, 35.606364463011921],
              [-81.114213302877033, 35.606629163618322],
              [-81.114543278798251, 35.606670966913931],
              [-81.114491165741512, 35.606935667636449],
              [-81.114821143077179, 35.606977471075453],
              [-81.114716919123694, 35.607506871752328],
              [-81.115046898877253, 35.607548673670756],
              [-81.114994786900596, 35.607813374545458],
              [-81.115324769148557, 35.607855175686872],
              [-81.115272657882173, 35.608119876658428],
              [-81.115932624420111, 35.608203477449663],
              [-81.115880514891074, 35.608468177755299],
              [-81.116210500284595, 35.608509977385971],
              [-81.116158391465859, 35.608774677788425],
              [-81.116488378250182, 35.608816476661353],
              [-81.116436270141847, 35.609081177160547],
              [-81.116766258317142, 35.609122975275554],
              [-81.116714152022595, 35.609387675852417],
              [-81.117044140461729, 35.609429472327584],
              [-81.116939928976592, 35.609958874534492],
              [-81.117269920960794, 35.610000670370965],
              [-81.117165709088837, 35.610530071806252],
              [-81.117495703538054, 35.610571867924449],
              [-81.117443598670505, 35.610836567807269],
              [-81.117773594510766, 35.610878363167551],
              [-81.11766938590327, 35.611407764887431],
              [-81.117999384161593, 35.611449558727045],
              [-81.117790965939008, 35.612508361338627],
              [-81.118120969843986, 35.612550154816255],
              [-81.11796465547441, 35.613344256532123],
              [-81.118294662871833, 35.613386049528621],
              [-81.117356732119887, 35.618150645399474],
              [-81.11768675987561, 35.618192439971288],
              [-81.117582540123649, 35.618721837861123],
              [-81.117912570321351, 35.618763631813401],
              [-81.117860460445641, 35.619028331294288],
              [-81.118520523987527, 35.619111917687533],
              [-81.118312092728885, 35.620170713538386],
              [-81.118642129225634, 35.620212506394523],
              [-81.118537914196281, 35.620741903885516],
              [-81.118867953111632, 35.62078369522083],
              [-81.11919799239098, 35.620825486560904],
              [-81.11914588646772, 35.621090185520366],
              [-81.119475928218378, 35.621131975181946],
              [-81.119423821901933, 35.621396674257198],
              [-81.119753865043961, 35.621438463160715],
              [-81.119701760541872, 35.621703162313203],
              [-81.120361848845064, 35.621786737726858],
              [-81.120309746128171, 35.62205143801544],
              [-81.120639792393078, 35.622093224506315],
              [-81.12058769036328, 35.622357923990222],
              [-81.120917736915743, 35.622399709742254],
              [-81.120865636700373, 35.622664409303376],
              [-81.121195684644121, 35.622706194297315],
              [-81.12114358513945, 35.622970893954907],
              [-81.121473634450822, 35.623012677289559],
              [-81.12142153565695, 35.623277377043522],
              [-81.122081639657324, 35.623360943064156],
              [-81.1220295415214, 35.623625643072181],
              [-81.122359595083068, 35.623667424876132],
              [-81.1223074976579, 35.623932124980591],
              [-81.12263755371464, 35.623973906007144],
              [-81.122585456976623, 35.624238605306857],
              [-81.123245570005537, 35.624322164985749],
              [-81.123193476156715, 35.624586865402058],
              [-81.123853593728853, 35.624670420851309],
              [-81.123801500538164, 35.624935121521617],
              [-81.124461621573545, 35.625018673661522],
              [-81.124409531248531, 35.625283374547315],
              [-81.125399719070288, 35.625408696889394],
              [-81.125347630430937, 35.62567339726629],
              [-81.126337824468052, 35.625798711954822],
              [-81.126285739745583, 35.62606341268539],
              [-81.126615806531987, 35.626105182887386],
              [-81.126511638192412, 35.626634585400502],
              [-81.126841707397574, 35.626676354081496],
              [-81.126789623780411, 35.626941055864066],
              [-81.126459553500453, 35.626899286143612],
              [-81.126303297407006, 35.627693389022156],
              [-81.125973224337358, 35.627651618891477],
              [-81.125921137233348, 35.627916319328349],
              [-81.126251211354173, 35.627958089597328],
              [-81.126042863764269, 35.629016892379347],
              [-81.126372942430081, 35.629058662304956],
              [-81.126320855727286, 35.629323362808464],
              [-81.126650936911901, 35.629365132857679],
              [-81.126546763460667, 35.629894533133374],
              [-81.127206931031921, 35.629978070194127],
              [-81.127154846462204, 35.630242770986449],
              [-81.12748493179754, 35.630284537859666],
              [-81.127432847938749, 35.630549238748252],
              [-81.127762934689315, 35.630591005764359],
              [-81.127815016393029, 35.630326304756835],
              [-81.129465449897779, 35.630535122092766],
              [-81.129413372346548, 35.630799823810968],
              [-81.129743461114629, 35.630841584546459],
              [-81.129795537614498, 35.630576882690022],
              [-81.130785802805818, 35.630702159102256],
              [-81.130837874707979, 35.630437456808352],
              [-81.131498051100351, 35.630520969657191],
              [-81.131550120559822, 35.630256267044793],
              [-81.132210296210175, 35.630339776030837],
              [-81.132262363226957, 35.630075073099931],
              [-81.13292253813529, 35.630158578223195],
              [-81.132974602709325, 35.629893874973824],
              [-81.133304689622378, 35.629935626052365],
              [-81.133356752805128, 35.629670922622694],
              [-81.134347012430837, 35.629796170064175],
              [-81.134399071015778, 35.629531466197292],
              [-81.135059243673666, 35.629614958831041],
              [-81.135111299816003, 35.629350254645828],
              [-81.135771471731417, 35.629433743416875],
              [-81.135823526535063, 35.629169038893764],
              [-81.136153610859637, 35.629210782266235],
              [-81.136205664271998, 35.628946077562958],
              [-81.136535747861572, 35.628987818999462],
              [-81.136639850459801, 35.628458409209991],
              [-81.136969933390546, 35.628500149454062],
              [-81.137021982576627, 35.628235444367959],
              [-81.137352063716065, 35.628277184497875],
              [-81.137456158941816, 35.627747773022072],
              [-81.138776481753283, 35.62791472161021],
              [-81.138724438503559, 35.628179427471238],
              [-81.140704938674943, 35.628429824295146],
              [-81.140652902496839, 35.628694530923816],
              [-81.141313074778452, 35.628777989622577],
              [-81.14164316141715, 35.628819717176476],
              [-81.141695194441425, 35.628555010133326],
              [-81.142025280368813, 35.628596736652483],
              [-81.142129343271236, 35.628067321262876],
              [-81.142459427435895, 35.628109046609083],
              [-81.142563486876327, 35.6275796307755],
              [-81.142233404814249, 35.627537905705417],
              [-81.142389494567766, 35.626743781604375],
              [-81.142719573476029, 35.626785506259907],
              [-81.142875657017058, 35.625991381367243],
              [-81.143535807361872, 35.62607482809986],
              [-81.143587832418703, 35.625810119141768],
              [-81.143917907037704, 35.62585184057459],
              [-81.143969930703548, 35.625587131436561],
              [-81.14463007996217, 35.625670571316604],
              [-81.14468210008198, 35.625405861879869],
              [-81.145342248597288, 35.625489297897502],
              [-81.145394266274806, 35.625224588142665],
              [-81.146054412943002, 35.625308020317327],
              [-81.146106429282028, 35.625043310224811],
              [-81.146436502074565, 35.625085024829261],
              [-81.146488515918733, 35.624820314576276],
              [-81.147148660420626, 35.62490374081915],
              [-81.147200672926402, 35.624639030228643],
              [-81.147530744635617, 35.624680741867166],
              [-81.147582754646677, 35.624416031116205],
              [-81.147912825644482, 35.624457741720057],
              [-81.147964834264585, 35.624193030789144],
              [-81.148294904526892, 35.624234739457194],
              [-81.148346910652307, 35.623970028365846],
              [-81.148676981330794, 35.624011736880817],
              [-81.148728986065294, 35.623747025609632],
              [-81.149389124107429, 35.623830438811673],
              [-81.149719193649929, 35.623872144518508],
              [-81.149667192069231, 35.624136856204039],
              [-81.149997263002646, 35.624178561152405],
              [-81.149893259820885, 35.624707984693686],
              [-81.150223333196479, 35.624749689021655],
              [-81.150119330758557, 35.625279112671812],
              [-81.149789255280339, 35.625237408067584],
              [-81.149737251972567, 35.625502120602761],
              [-81.150067328478144, 35.625543824443746],
              [-81.14985931816733, 35.626602671074188],
              [-81.150189400321551, 35.626644374551503],
              [-81.150137397394019, 35.626909086252169],
              [-81.150467480963044, 35.62695078987214],
              [-81.150415478747163, 35.627215501669149],
              [-81.150745563683387, 35.62725720362937],
              [-81.150693563282928, 35.627521915503031],
              [-81.151023649610167, 35.627563616704698],
              [-81.150971648817602, 35.627828328694079],
              [-81.15163182461761, 35.627911729585044],
              [-81.151579826691844, 35.628176441789044],
              [-81.151909916140809, 35.628218140577147],
              [-81.151857918926751, 35.628482852877276],
              [-81.152518100946381, 35.628566248039739],
              [-81.152466105495407, 35.628830960574078],
              [-81.152796198065985, 35.628872656948467],
              [-81.152744203326762, 35.629137369578949],
              [-81.153074297288484, 35.629179065194755],
              [-81.153022303261011, 35.629443777921345],
              [-81.153682495409868, 35.62952716671964],
              [-81.153630503145678, 35.629791879680404],
              [-81.154290698755915, 35.629875265168259],
              [-81.154238708230892, 35.630139977461994],
              [-81.154898907326427, 35.630223360540455],
              [-81.154846919668572, 35.630488073048745],
              [-81.155507122201485, 35.630571451915621],
              [-81.155455135226973, 35.630836165578835],
              [-81.155785238606256, 35.63087785379544],
              [-81.155733253423449, 35.631142566633898],
              [-81.15639346219983, 35.631225940672969],
              [-81.156341478780377, 35.631490653745487],
              [-81.156671585281373, 35.631532339548272],
              [-81.156619601470013, 35.631797052736431],
              [-81.156949710465824, 35.631838737760958],
              [-81.156897727366456, 35.632103451045154],
              [-81.157557946272263, 35.632186818719404],
              [-81.157505966040077, 35.632451532218063],
              [-81.157836077053901, 35.632493214848168],
              [-81.15778409753365, 35.632757928442835],
              [-81.158114209938574, 35.632799610314272],
              [-81.158062231130288, 35.633064324004934],
              [-81.15839234603007, 35.633106005098092],
              [-81.158340366829904, 35.633370718904352],
              [-81.158670483120872, 35.633412399238821],
              [-81.158618505712653, 35.633677112220298],
              [-81.158948622290779, 35.63371879181566],
              [-81.158896645618469, 35.633983505794291],
              [-81.159226764691681, 35.634025184611367],
              [-81.159122811303661, 35.634554611836982],
              [-81.159452932819491, 35.634596290033294],
              [-81.159400957231767, 35.634861004161849],
              [-81.15973108011481, 35.634902680698268],
              [-81.159627129999734, 35.635432108204029],
              [-81.159957255349539, 35.635473785020871],
              [-81.159853305979496, 35.636003212634428],
              [-81.16018343377209, 35.63604488883049],
              [-81.160131459617261, 35.636309602261591],
              [-81.162112241972466, 35.636559639592811],
              [-81.162060273788072, 35.636824353809644],
              [-81.164041075731305, 35.637074357863732],
              [-81.164093037605667, 35.636809642819209],
              [-81.164423170839328, 35.636851306594451],
              [-81.164371211144626, 35.637116022658425],
              [-81.165361618245385, 35.637241008978059],
              [-81.165413574809108, 35.636976293401439],
              [-81.165743709400644, 35.637017953589641],
              [-81.165691753912697, 35.637282670205373],
              [-81.166021890999275, 35.637324329615019],
              [-81.165969936199531, 35.637589045425464],
              [-81.166300074677153, 35.637630704076251],
              [-81.166144211420658, 35.638424852569983],
              [-81.166474353392985, 35.638466510737821],
              [-81.166266535140338, 35.639525374156825],
              [-81.166596682762943, 35.639567031959878],
              [-81.166544728403068, 35.639831747847253],
              [-81.166874876337033, 35.639873405812267],
              [-81.166615104680488, 35.641196984405752],
              [-81.166945259292717, 35.641238641242659],
              [-81.166737440035902, 35.64229750282896],
              [-81.167067599194965, 35.642339159320656],
              [-81.16685977871424, 35.643398020783785],
              [-81.167189942420208, 35.643439676930285],
              [-81.167086032246857, 35.643969107684505],
              [-81.167416199500039, 35.644010763190273],
              [-81.167312290048542, 35.644540193150277],
              [-81.167642458640969, 35.644581848054663],
              [-81.16743464097614, 35.645640707999931],
              [-81.167764814115685, 35.64568236255905],
              [-81.167712859626761, 35.645947077582505],
              [-81.168043035261732, 35.645988731362969],
              [-81.167991081485283, 35.646253446482199],
              [-81.168321258511583, 35.646295099503646],
              [-81.168217352044337, 35.646824529890964],
              [-81.168547531489878, 35.646866181390166],
              [-81.168495579903052, 35.647130896638615],
              [-81.168825760764236, 35.647172548279947],
              [-81.1687738087859, 35.647437263643639],
              [-81.169103991014396, 35.647478913624845],
              [-81.168948137302692, 35.648273059856372],
              [-81.168617950790008, 35.648231408580116],
              [-81.168565998533765, 35.648496122853842],
              [-81.168235811332735, 35.648454471443941],
              [-81.168131901513846, 35.648983900529281],
              [-81.167801713628194, 35.648942247025914],
              [-81.167749757145444, 35.649206960916572],
              [-81.167419567467306, 35.649165307299235],
              [-81.167315651379255, 35.649694734677965],
              [-81.166985461040539, 35.649653079868159],
              [-81.166933500331155, 35.649917793375799],
              [-81.166603309255777, 35.64987613663002],
              [-81.166551347154936, 35.650140849957509],
              [-81.166221155367069, 35.650099192176924],
              [-81.16611722917078, 35.650628619310353],
              [-81.165787034514238, 35.650586960376728],
              [-81.165527208336997, 35.651910525417854],
              [-81.165197008759691, 35.65186886489775],
              [-81.165093073553223, 35.652398289084104],
              [-81.165041104900567, 35.652663001574076],
              [-81.165371307634075, 35.652704662507908],
              [-81.165267372518372, 35.653234087616745],
              [-81.165597577695749, 35.653275747929449],
              [-81.165493643302, 35.653805172243288],
              [-81.165823852027245, 35.653846831915153],
              [-81.165771884833077, 35.654111544606742],
              [-81.166762515074595, 35.654236517772659],
              [-81.166814479136434, 35.653971805568538],
              [-81.167474898514797, 35.654055115958002],
              [-81.167422937685515, 35.654319829319299],
              [-81.168413573020132, 35.654444789030492],
              [-81.168465530692913, 35.654180075255468],
              [-81.168795742474146, 35.654221726854487],
              [-81.168743785829406, 35.654486439866218],
              [-81.169404211386365, 35.654569739767588],
              [-81.169456165926761, 35.65430502648011],
              [-81.170116591841492, 35.654388322498093],
              [-81.170064639429597, 35.654653036962436],
              [-81.171055283992317, 35.654777974224913],
              [-81.171003334397525, 35.655042689060394],
              [-81.171333550974779, 35.65508433285801],
              [-81.171281602068674, 35.655349046887764],
              [-81.171611821165925, 35.655390690807664],
              [-81.17155987297248, 35.655655404932801],
              [-81.17189009235733, 35.655697048113268],
              [-81.171838145980828, 35.655961762314064],
              [-81.172168366757361, 35.656003404735337],
              [-81.172116421093548, 35.656268119031566],
              [-81.17277686574532, 35.656351400557561],
              [-81.172828810384559, 35.656086685064793],
              [-81.173159032155169, 35.656128323893846],
              [-81.173210974322728, 35.655863609141697],
              [-81.173541195380523, 35.655905246935802],
              [-81.173593137260582, 35.655640531983657],
              [-81.174253578290106, 35.65572380460533],
              [-81.17430551659784, 35.65545908845364],
              [-81.174635736593643, 35.655500724182289],
              [-81.174583799313865, 35.655765439570615],
              [-81.175244243483831, 35.655848706790408],
              [-81.175192307993356, 35.656113423313123],
              [-81.175852755625371, 35.656196687220422],
              [-81.175800821875853, 35.65646140307512],
              [-81.176131047805328, 35.656503033811148],
              [-81.176079114792927, 35.656767750662361],
              [-81.176739568670243, 35.656851009738645],
              [-81.176687637398871, 35.657115725921827],
              [-81.17734809584239, 35.657198981665786],
              [-81.177296166336333, 35.657463698082097],
              [-81.177956628241773, 35.657546950513407],
              [-81.177904700501017, 35.657811667162825],
              [-81.178234933015062, 35.657853292170671],
              [-81.178183007091405, 35.658118008895592],
              [-81.179503944726108, 35.658284499606324],
              [-81.179452022672592, 35.658549216839894],
              [-81.180442732612306, 35.658674075641684],
              [-81.180390813400763, 35.658938794147211],
              [-81.181051291591245, 35.659022028818356],
              [-81.180999374120901, 35.659286746655738],
              [-81.181329614213269, 35.659328362342741],
              [-81.181277697456039, 35.659593080275393],
              [-81.18160794006846, 35.659634696084431],
              [-81.181556024024445, 35.659899414112303],
              [-81.181886266900051, 35.659941028280635],
              [-81.181782437003079, 35.660470464464531],
              [-81.182112682347153, 35.660512078912411],
              [-81.182008853173684, 35.661041514300621],
              [-81.182339102066123, 35.66108312810708],
              [-81.182235272536232, 35.661612563620338],
              [-81.182565523872753, 35.661654176805072],
              [-81.182513610767742, 35.661918895066464],
              [-81.182843863471803, 35.661960506590717],
              [-81.182791949975766, 35.662225224967052],
              [-81.183122204071552, 35.662266835731913],
              [-81.183070291288729, 35.662531554203397],
              [-81.183400547880424, 35.662573164189055],
              [-81.183348635786544, 35.662837881854529],
              [-81.18400915101283, 35.662921100330848],
              [-81.183957240709077, 35.663185819130362],
              [-81.184617759373069, 35.663269033392389],
              [-81.184565850810699, 35.663533751523623],
              [-81.186547421283535, 35.663783374505449],
              [-81.187207947480019, 35.663866574688328],
              [-81.187156049231618, 35.664131294740137],
              [-81.187816578889937, 35.664214491609819],
              [-81.187764681278864, 35.664479211013052],
              [-81.188425215503287, 35.664562404549535],
              [-81.188373319658211, 35.66482712418555],
              [-81.189033857344455, 35.664910314408608],
              [-81.188981963265377, 35.665175034277468],
              [-81.189312233669767, 35.665216628180929],
              [-81.189208447703763, 35.665746068046097],
              [-81.189538720552548, 35.665787661327705],
              [-81.189434935335171, 35.666317101297743],
              [-81.189765210628337, 35.66635869395752],
              [-81.18966142615956, 35.666888134032362],
              [-81.189991705001546, 35.666929726050441],
              [-81.189939812758936, 35.667194446171429],
              [-81.190270092992648, 35.667236037429952],
              [-81.190218201463551, 35.667500757646039],
              [-81.190548483064802, 35.667542347243774],
              [-81.190496592249204, 35.66780706755484],
              [-81.190826875266566, 35.667848657294179],
              [-81.190774986268849, 35.668113377680378],
              [-81.191105269549411, 35.668154965778854],
              [-81.191053381265249, 35.668419686260059],
              [-81.191383667066262, 35.668461274480215],
              [-81.191331779495755, 35.668725995056356],
              [-81.191662066664222, 35.668767581615811],
              [-81.191610178702874, 35.669032302306817],
              [-81.191940467263265, 35.669073888106631],
              [-81.191836694637331, 35.6696033295962],
              [-81.192166985642302, 35.669644914773983],
              [-81.192115099873405, 35.66990963559239],
              [-81.192445392270145, 35.669951220010645],
              [-81.192393507214874, 35.670215940923832],
              [-81.19371468555731, 35.67038227195728],
              [-81.193662804349572, 35.670646992477202],
              [-81.193993101651714, 35.67068857266441],
              [-81.193941221182172, 35.670953294180478],
              [-81.195923015144047, 35.671202758247929],
              [-81.195871140652116, 35.671467480546966],
              [-81.197522651844523, 35.671675342501658],
              [-81.197470783382059, 35.671940065425936],
              [-81.198461696882632, 35.672064772252021],
              [-81.198513562186477, 35.671800048914996],
              [-81.199504475579502, 35.671924747252653],
              [-81.19945261451403, 35.672189470081285],
              [-81.200773841696531, 35.672355722512833],
              [-81.200825699630471, 35.672090998212838],
              [-81.201156006496674, 35.672132558934898],
              [-81.201104149615617, 35.672397283372455],
              [-81.2017647675506, 35.672480401478602],
              [-81.201712912411864, 35.672745125247417],
              [-81.202043222400746, 35.672786683552623],
              [-81.202095076486501, 35.672521959646303],
              [-81.20275569535076, 35.672605072388535],
              [-81.202807545887509, 35.67234034818437],
              [-81.203137854762758, 35.672381902621318],
              [-81.203189703907739, 35.672117178236981],
              [-81.203520012069092, 35.672158731639151],
              [-81.203468164001436, 35.672423457062187],
              [-81.20412878347102, 35.672506562350804],
              [-81.204076937145828, 35.672771287105178],
              [-81.204407249558543, 35.672812838971574],
              [-81.204355403947389, 35.673077563820598],
              [-81.205016029661877, 35.673160664275343],
              [-81.205067873167209, 35.672895939151289],
              [-81.205398185467516, 35.672937487444571],
              [-81.206389125530649, 35.673062127821481],
              [-81.206337287341412, 35.6733268534757],
              [-81.20699791822652, 35.673409943142389],
              [-81.20694608069995, 35.673674669048779],
              [-81.207276398268121, 35.673716213114247],
              [-81.207224561455661, 35.673980939115467],
              [-81.207554880391157, 35.674022481519835],
              [-81.207451208960123, 35.674551933649006],
              [-81.207781530364841, 35.674593476332163],
              [-81.207729694641856, 35.674858202480124],
              [-81.208060017413914, 35.67489974350228],
              [-81.208008183509534, 35.675164469724926],
              [-81.208338507673304, 35.675206009987185],
              [-81.208286673378666, 35.675470736324577],
              [-81.208616998934346, 35.675512275826961],
              [-81.208565166458342, 35.675777002239045],
              [-81.208895493405748, 35.675818540981524],
              [-81.208843661643968, 35.676083267488366],
              [-81.209173989983285, 35.676124805470955],
              [-81.209122158935742, 35.676389532072392],
              [-81.209452488666798, 35.67643106929507],
              [-81.209400658333493, 35.676695795991208],
              [-81.209730989456475, 35.676737332453961],
              [-81.209679159837421, 35.677002059244771],
              [-81.210009493456624, 35.677043594927547],
              [-81.209957664527238, 35.677308320911884],
              [-81.210287998433927, 35.677349855854779],
              [-81.210236170243419, 35.677614582834913],
              [-81.21056650664643, 35.677656116997795],
              [-81.210514679170117, 35.677920844072595],
              [-81.210845015835943, 35.677962376594451],
              [-81.210793189074039, 35.678227103763874],
              [-81.211123528260785, 35.678268636406877],
              [-81.211071702213133, 35.678533363671001],
              [-81.211402042767148, 35.678574894652868],
              [-81.211350217433903, 35.678839622011537],
              [-81.211680559404329, 35.678881153134583],
              [-81.211628734760822, 35.679145879686729],
              [-81.211959078123243, 35.679187410049813],
              [-81.211699956243578, 35.680511044637186],
              [-81.212030306314972, 35.680552574769969],
              [-81.21192665683644, 35.681082027866807],
              [-81.212257008248486, 35.681123557397143],
              [-81.21220518515878, 35.681388283998587],
              [-81.21253553796268, 35.68142981276889],
              [-81.212483714482985, 35.681694539484965],
              [-81.213144425397886, 35.681777593666759],
              [-81.213092603685752, 35.682042320614869],
              [-81.213422960716954, 35.682083846947585],
              [-81.213371139719271, 35.682348573990147],
              [-81.213701498117743, 35.682390098661628],
              [-81.213649677834525, 35.682654825798743],
              [-81.213980037649705, 35.682696350611344],
              [-81.21392821918549, 35.682961077822846],
              [-81.214258580367925, 35.683002600974227],
              [-81.21420676151368, 35.683267328300346],
              [-81.214537124112852, 35.683308851592827],
              [-81.214433488574542, 35.683838305469855],
              [-81.214763853618891, 35.683879828139659],
              [-81.214712036959483, 35.684144555591701],
              [-81.21466021993669, 35.684409282099708],
              [-81.214990587426442, 35.68445080414687],
              [-81.214938771118199, 35.68471553074928],
              [-81.215269139999876, 35.684757052036311],
              [-81.215165507393877, 35.685286506308316],
              [-81.215495879800912, 35.685328026051415],
              [-81.21582625146668, 35.685369545818247],
              [-81.215774437302159, 35.685634272704],
              [-81.216104811464547, 35.685675791690599],
              [-81.216052998014732, 35.685940518670826],
              [-81.21638337246462, 35.685982036917423],
              [-81.216435184836541, 35.68571730889871],
              [-81.216765559651719, 35.685758825189033],
              [-81.21671374833322, 35.686023553345137],
              [-81.21704412454045, 35.686065068875472],
              [-81.216992313936515, 35.686329797126],
              [-81.217653068369316, 35.686412825788807],
              [-81.217601259508697, 35.686677553369982],
              [-81.217931638863433, 35.686719067383557],
              [-81.217828022232865, 35.687248522691661],
              [-81.21749764079604, 35.68720700930448],
              [-81.217186776479664, 35.688795374273397],
              [-81.217517165341576, 35.688836888464778],
              [-81.217361731783271, 35.689631071231041],
              [-81.217692123039512, 35.689672584957123],
              [-81.217173998434291, 35.692319853731568],
              [-81.217504401668791, 35.692361367913669],
              [-81.217400775000868, 35.69289082106166],
              [-81.217731180681071, 35.69293233462097],
              [-81.217420298843777, 35.694520692952608],
              [-81.217750711184053, 35.694562206438562],
              [-81.217698897404162, 35.694826932513102],
              [-81.217368482905528, 35.69478541890993],
              [-81.217264852222272, 35.69531487065484],
              [-81.217595268852762, 35.695356385433904],
              [-81.217543452951872, 35.695621111399063],
              [-81.217873870950058, 35.6956626245167],
              [-81.217770242448537, 35.696192076551981],
              [-81.218100662892638, 35.696233589046621],
              [-81.217945220735757, 35.697027766286475],
              [-81.218275644679608, 35.697069278295601],
              [-81.218120202634566, 35.69786345555859],
              [-81.218450630078181, 35.697904967082081],
              [-81.21829518814522, 35.698699144368028],
              [-81.218625619088542, 35.698740655405999],
              [-81.218573805495339, 35.6990053815192],
              [-81.218904238935878, 35.699046891776661],
              [-81.218800612815656, 35.699576343246946],
              [-81.219131048702408, 35.699617852881417],
              [-81.219079236175503, 35.699882578238409],
              [-81.219409673454749, 35.699924087112507],
              [-81.219357861642891, 35.700188812563546],
              [-81.21968830031463, 35.700230320677299],
              [-81.219636489217635, 35.700495046222422],
              [-81.219966929281895, 35.700536553575795],
              [-81.219863308203813, 35.701066005712093],
              [-81.220193750689731, 35.701107511541203],
              [-81.220090131468879, 35.701636963758823],
              [-81.220420576425823, 35.701678469866089],
              [-81.220368766796184, 35.701943195606759],
              [-81.220057901904639, 35.703531549140934],
              [-81.220388355657093, 35.703573054390453],
              [-81.220284733423981, 35.704102505496465],
              [-81.220615189647603, 35.704144011024113],
              [-81.220563380156804, 35.704408735728066],
              [-81.220232921799237, 35.704367230984381],
              [-81.220025671862928, 35.705426130700062],
              [-81.21969521073359, 35.705384624489106],
              [-81.219643396336281, 35.7056493487216],
              [-81.219591580520031, 35.705914073832112],
              [-81.219922044886658, 35.70595558119868],
              [-81.21971478568949, 35.707014478991852],
              [-81.220045254610568, 35.707055986009962],
              [-81.21999344028815, 35.707320710256724],
              [-81.221315322446841, 35.707486729019685],
              [-81.221263512001357, 35.707751453772261],
              [-81.221924457761503, 35.707834457580816],
              [-81.221872649085142, 35.708099182564595],
              [-81.222203124644551, 35.708140683689841],
              [-81.222151316683338, 35.708405408767504],
              [-81.222812268692067, 35.708488407738358],
              [-81.222760462500034, 35.708753133047146],
              [-81.223090941159086, 35.70879463085236],
              [-81.222987329866697, 35.709324081614326],
              [-81.223317810997173, 35.709365579697455],
              [-81.223266005896818, 35.70963030515049],
              [-81.223596488395316, 35.709671801571922],
              [-81.223544684010236, 35.709936527118785],
              [-81.22420565105152, 35.710019518464001],
              [-81.22415384951583, 35.710284243320622],
              [-81.224814820020185, 35.710367231349011],
              [-81.224763020278615, 35.710631957337945],
              [-81.225093506528069, 35.710673449702149],
              [-81.225041707501816, 35.710938175784818],
              [-81.225702685334852, 35.711021158053754],
              [-81.225650888078093, 35.711285884367477],
              [-81.225981377451859, 35.711327374312624],
              [-81.225929580910346, 35.711592100720118],
              [-81.226260072781841, 35.711633589884435],
              [-81.226208276955688, 35.711898316385785],
              [-81.226869263823104, 35.711981292295299],
              [-81.226817469766416, 35.712246019027582],
              [-81.227478461201812, 35.712328991599861],
              [-81.227426668889763, 35.712593717662003],
              [-81.227757165617206, 35.712635202748658],
              [-81.22770537404547, 35.712899929805658],
              [-81.228366371729535, 35.712982897539526],
              [-81.22831458190258, 35.713247623926449],
              [-81.228645082859202, 35.713289106573598],
              [-81.228593293772519, 35.713553833955359],
              [-81.228923796097163, 35.713595314940761],
              [-81.228872007725954, 35.713860042416144],
              [-81.229202510363407, 35.713901523562264],
              [-81.229150723787797, 35.714166250209971],
              [-81.229811733291925, 35.714249210062725],
              [-81.229759948486006, 35.714513936941266],
              [-81.230090455352681, 35.714555415647816],
              [-81.230038670157342, 35.714820142640441],
              [-81.230369178416879, 35.714861620586298],
              [-81.230317393937071, 35.715126347672502],
              [-81.230647903589457, 35.715167824857637],
              [-81.230596120930159, 35.715432552017234],
              [-81.230926631975521, 35.715474028441662],
              [-81.230874850031796, 35.715738755694908],
              [-81.231205362469936, 35.715780231358622],
              [-81.231153580136819, 35.716044958725838],
              [-81.231484095072901, 35.716086433608552],
              [-81.231432313455286, 35.716351161069326],
              [-81.231762829759418, 35.716392634290152],
              [-81.231711048857505, 35.716657361844518],
              [-81.232041566579284, 35.71669883520574],
              [-81.23198978749798, 35.716963562833485],
              [-81.23232030550767, 35.717005035454278],
              [-81.232268527141983, 35.717269763175622],
              [-81.232599046519837, 35.717311234134456],
              [-81.232547268869808, 35.71757596194935],
              [-81.232877790770317, 35.717617433028281],
              [-81.232826013835947, 35.717882160936782],
              [-81.233156537104534, 35.717923630353788],
              [-81.233104759780844, 35.718188358376153],
              [-81.233435284442336, 35.718229827032381],
              [-81.233331733097813, 35.718759283180091],
              [-81.233662260206572, 35.718800751212719],
              [-81.233610485080661, 35.719065479358271],
              [-81.233941013607236, 35.719106947531216],
              [-81.233889239172171, 35.719371674869173],
              [-81.234550299325065, 35.719454607894306],
              [-81.234498527789995, 35.719719336343907],
              [-81.234829059416214, 35.719760801196088],
              [-81.234777287491866, 35.720025529759418],
              [-81.235438354973965, 35.720108457023777],
              [-81.235386584819764, 35.720373185817813],
              [-81.236047655764878, 35.720456109764442],
              [-81.236378191192784, 35.720497570400852],
              [-81.236429958208717, 35.720232842096458],
              [-81.236760492920908, 35.720274301697678],
              [-81.236864022673487, 35.719744842901555],
              [-81.237194555615375, 35.719786301330444],
              [-81.237246318401859, 35.719521572640936],
              [-81.239229519046276, 35.719770303492169],
              [-81.239281275143028, 35.719505573035036],
              [-81.239942342992165, 35.719588475853541],
              [-81.239890588979165, 35.719853205683805],
              [-81.240551660291047, 35.719936105184445],
              [-81.240758663591791, 35.718877181841691],
              [-81.242411326128973, 35.719084411244275],
              [-81.242359580512314, 35.719349142165363],
              [-81.244673329864966, 35.719639226026715],
              [-81.244725066970858, 35.719374493265036],
              [-81.247038826578077, 35.719664530340204],
              [-81.247090554859554, 35.719399796595773],
              [-81.248082168340062, 35.719524084054186],
              [-81.248133893119899, 35.719259349854859],
              [-81.248464429823855, 35.719300776787733],
              [-81.248516154315951, 35.719036042387401],
              [-81.248846690303751, 35.719077468285164],
              [-81.248898412298118, 35.718812733724569],
              [-81.249228948674855, 35.718854158566813],
              [-81.249280669276501, 35.71858942382557],
              [-81.249611203831975, 35.718630847653131],
              [-81.24966292304093, 35.718366112731232],
              [-81.249993457985227, 35.718407535503275],
              [-81.250045174696496, 35.718142800421084],
              [-81.250375708924707, 35.718184222158122],
              [-81.250323992162862, 35.718448957397641],
              [-81.250654526678659, 35.718490378394115],
              [-81.250602810633026, 35.718755113727156],
              [-81.250933347671563, 35.718796534843236],
              [-81.250881632342143, 35.719061270269734],
              [-81.251212170748303, 35.719102689723691],
              [-81.251160456135111, 35.719367425243689],
              [-81.251490995934063, 35.719408843936549],
              [-81.251439282037026, 35.719673579549962],
              [-81.25176982322867, 35.71971499748178],
              [-81.251718110047975, 35.71997973318863],
              [-81.252048652632254, 35.7200211503595],
              [-81.251996940142746, 35.720285885258562],
              [-81.252327484119732, 35.720327301668377],
              [-81.252275773476597, 35.720592037541515],
              [-81.252606318846404, 35.720633453190288],
              [-81.252658028435036, 35.720368717180136],
              [-81.252988573088444, 35.720410131793827],
              [-81.253040281284356, 35.720145395603019],
              [-81.253370825221467, 35.720186809181612],
              [-81.253422533129708, 35.719922072789721],
              [-81.254083618804557, 35.720004896999079],
              [-81.254135323160597, 35.719740160310003],
              [-81.254465865978148, 35.719781570019229],
              [-81.254517568941509, 35.719516833149463],
              [-81.255178653532383, 35.719599651402007],
              [-81.255230354048607, 35.719334914214627],
              [-81.255560895771595, 35.719376320955618],
              [-81.255612594895183, 35.71911158358774],
              [-81.255943134822004, 35.719152990215264],
              [-81.255994832552972, 35.71888825266678],
              [-81.256655912391722, 35.718971062032075],
              [-81.25670760767558, 35.718706324166014],
              [-81.25703814758765, 35.718747726903771],
              [-81.259021392024778, 35.718996127215199],
              [-81.258969705252596, 35.71926086692072],
              [-81.260291879533298, 35.71942644880675],
              [-81.260240196615925, 35.719691188115476],
              [-81.261562381628195, 35.719856756159437],
              [-81.261459024321113, 35.720386235721044],
              [-81.261789573522009, 35.720427625535386],
              [-81.261686216971853, 35.72095710519654],
              [-81.262016768645196, 35.720998495287809],
              [-81.261965090340041, 35.721263234299009],
              [-81.262295644510914, 35.721304623608553],
              [-81.262243966922341, 35.721569362713055],
              [-81.262574521380813, 35.721610751281986],
              [-81.262471168429087, 35.72214023051459],
              [-81.262801726414722, 35.722181617537672],
              [-81.262698373140296, 35.722711097791247],
              [-81.263028933573196, 35.722752484190046],
              [-81.262977258588634, 35.723017224367688],
              [-81.263307819309119, 35.723058610025802],
              [-81.263256145016115, 35.723323349395649],
              [-81.264908964486537, 35.723530264795613],
              [-81.264960633481024, 35.723265523840077],
              [-81.265291197334435, 35.723306904088716],
              [-81.26523952939462, 35.723571645181167],
              [-81.265570094640466, 35.723613024668595],
              [-81.265621761525694, 35.723348283439222],
              [-81.265952326054915, 35.723389661891581],
              [-81.265900660199222, 35.723654402356701],
              [-81.26623122612105, 35.723695780047791],
              [-81.266179561007348, 35.72396052150728],
              [-81.266510128321912, 35.724001898437194],
              [-81.266458463899653, 35.724266639088782],
              [-81.266789032606738, 35.724308015257357],
              [-81.266685705988323, 35.72483749758387],
              [-81.267016277142872, 35.724878873128105],
              [-81.266964613816342, 35.725143613922455],
              [-81.267295187468548, 35.725184988684681],
              [-81.267243524858813, 35.725449729572304],
              [-81.26757409879859, 35.725491103593889],
              [-81.267522436905679, 35.7257558445746],
              [-81.267853013343228, 35.725797217814318],
              [-81.26780135216714, 35.726061958888231],
              [-81.268462508140246, 35.726144702045787],
              [-81.268410848735783, 35.726409443349731],
              [-81.269072008195408, 35.726492184089288],
              [-81.269020350562599, 35.726756925623278],
              [-81.26935093182874, 35.726798293881473],
              [-81.269299274912825, 35.727063035508614],
              [-81.269629857596925, 35.727104403906679],
              [-81.269578202503126, 35.727369145606353],
              [-81.270239370969321, 35.727451879080306],
              [-81.270187717647175, 35.727716621009954],
              [-81.270518303429284, 35.727757986085905],
              [-81.270466650849372, 35.728022729009872],
              [-81.272450181201748, 35.728270902283882],
              [-81.27250182747801, 35.728006159440085],
              [-81.272832415625075, 35.728047518228458],
              [-81.272780770403756, 35.728312261209091],
              [-81.273772541166991, 35.728436333475848],
              [-81.273824183223624, 35.728171590084756],
              [-81.275146545454021, 35.728337005435932],
              [-81.275094907616861, 35.728601749374306],
              [-81.278070253520809, 35.728973881666334],
              [-81.278018625945009, 35.729238626771469],
              [-81.279341015257174, 35.729403995586196],
              [-81.279392638613373, 35.729139249933972],
              [-81.281706826323116, 35.729428608677253],
              [-81.281655211456751, 35.72969335526615],
              [-81.281985812249914, 35.729734688255199],
              [-81.282037426061407, 35.729469941529615],
              [-81.283029227327305, 35.729593935598174],
              [-81.283080837636234, 35.729329188418646],
              [-81.283411437695392, 35.729370518442273],
              [-81.28346304658632, 35.729105770181164],
              [-81.284124245582163, 35.72918842635903],
              [-81.284175852051021, 35.728923678681852],
              [-81.284506450987593, 35.728965004836077],
              [-81.284454845573705, 35.729229752649985],
              [-81.284785445928264, 35.729271078943867],
              [-81.284733841231755, 35.729535826850778],
              [-81.285064442953413, 35.729577151481877],
              [-81.28501284007946, 35.729841899461036],
              [-81.285343443193668, 35.729883223330589],
              [-81.28524023633274, 35.730412719472646],
              [-81.285570842999661, 35.7304540426966],
              [-81.285467636898474, 35.730983538937018],
              [-81.285798246012988, 35.731024861536092],
              [-81.285746643511061, 35.731289609727362],
              [-81.286077254018153, 35.731330931564827],
              [-81.286025652233704, 35.731595679849129],
              [-81.286356264133488, 35.731637000924977],
              [-81.286304663066431, 35.731901749302132],
              [-81.288949576645678, 35.732232286704573],
              [-81.288897984761363, 35.732497035209647],
              [-81.289559219194047, 35.73257966083996],
              [-81.289610808942811, 35.732314911160486],
              [-81.290272042615285, 35.732397532924104],
              [-81.290323629916301, 35.732132782927501],
              [-81.290984861723416, 35.732215400845256],
              [-81.291036446576783, 35.731950650531552],
              [-81.292358911074132, 35.732115873217374],
              [-81.292410491369708, 35.731851122313209],
              [-81.293071724110831, 35.731933728883583],
              [-81.293020145899931, 35.732198479159862],
              [-81.294012000682059, 35.732322381786588],
              [-81.293960426429777, 35.732587133309302],
              [-81.294291046403345, 35.732628431924162],
              [-81.294342619600485, 35.732363680264733],
              [-81.296987584275229, 35.73269403843188],
              [-81.297039148693969, 35.73242928563581],
              [-81.297700390855923, 35.732511866151299],
              [-81.297751951721892, 35.732247113059138],
              [-81.298082573346022, 35.732288401361338],
              [-81.298134132819541, 35.73202364808882],
              [-81.29846475262066, 35.73206493537684],
              [-81.298516310701743, 35.731800181923994],
              [-81.298846929785029, 35.731841468177159],
              [-81.298898486473789, 35.731576714543955],
              [-81.299229104839213, 35.731617999762172],
              [-81.29928066013548, 35.731353245948647],
              [-81.300933751745987, 35.731559657881562],
              [-81.300882202830579, 35.731824412357071],
              [-81.301212823219515, 35.731865692185274],
              [-81.301264372184932, 35.731600937552187],
              [-81.301925611864178, 35.731683494240166],
              [-81.301874065009045, 35.731948249146342],
              [-81.302865931327617, 35.732072077829379],
              [-81.302814387300785, 35.732336833101442],
              [-81.303475635652333, 35.732419380430045],
              [-81.303424094503853, 35.73268413591056],
              [-81.303754719700777, 35.732725408824834],
              [-81.3037031792703, 35.732990164398146],
              [-81.304364433865615, 35.733072706882652],
              [-81.304312894103191, 35.733337462706231],
              [-81.304643523527233, 35.733378733177439],
              [-81.304591985562396, 35.733643488171801],
              [-81.305253246427213, 35.733726026713192],
              [-81.305201710235664, 35.733990781936782],
              [-81.305532342781731, 35.734032049985949],
              [-81.305480807333836, 35.734296806203488],
              [-81.305811441246647, 35.734338072589679],
              [-81.30575990651694, 35.734602828899902],
              [-81.306090541822044, 35.734644094524171],
              [-81.306039007810469, 35.734908850927141],
              [-81.306369644533675, 35.734950116690747],
              [-81.306318111214438, 35.735214872285312],
              [-81.307310026521378, 35.735338662792664],
              [-81.307258497161854, 35.735603419633158],
              [-81.308581060941933, 35.735768461289837],
              [-81.308911702472656, 35.735809720138995],
              [-81.308860176921627, 35.736074476738025],
              [-81.309190820924272, 35.736115733903169],
              [-81.308830144513607, 35.73796903244461],
              [-81.309160796240775, 35.73801028966674],
              [-81.309109270129341, 35.738275047089267],
              [-81.308778617321082, 35.738233788829525],
              [-81.308727090922432, 35.738498546050735],
              [-81.308396437395899, 35.738457286756059],
              [-81.308344908499393, 35.738722043817887],
              [-81.308014254254616, 35.738680783488313],
              [-81.307962725045243, 35.738945539447698],
              [-81.307632070107914, 35.738904278984201],
              [-81.307580538400643, 35.739169034784162],
              [-81.307249882745083, 35.739127773285702],
              [-81.307198349645375, 35.739392528905306],
              [-81.30686769327157, 35.739351266371877],
              [-81.306816158779313, 35.739616021811038],
              [-81.306485501661498, 35.739574757341522],
              [-81.306433965776677, 35.739839512600398],
              [-81.306103307966424, 35.739798247996987],
              [-81.30605177068901, 35.740063003075377],
              [-81.305721111029584, 35.740021736556741],
              [-81.305669572359591, 35.740286491454789],
              [-81.305338913112905, 35.740245224781404],
              [-81.305287373050206, 35.740509979498952],
              [-81.304956711954375, 35.740468710910378],
              [-81.30490517049914, 35.740733465447505],
              [-81.304574509790314, 35.740692195802993],
              [-81.304522965837151, 35.740956950180603],
              [-81.304192304435901, 35.740915680402182],
              [-81.30408921448668, 35.7414451878306],
              [-81.303986123188466, 35.74197469508325],
              [-81.303655456797358, 35.741933423881093],
              [-81.303603910126768, 35.742198176843914],
              [-81.303273243017571, 35.742156904606794],
              [-81.303221693848968, 35.742421657410105],
              [-81.302229693537925, 35.742297833054103],
              [-81.302281244767514, 35.742033080681288],
              [-81.29996659582325, 35.741744126406111],
              [-81.299915036100415, 35.742008877843837],
              [-81.299584371620497, 35.741967594685647],
              [-81.299532810530437, 35.742232346843963],
              [-81.299863474960546, 35.74227363015963],
              [-81.299760353853685, 35.742803133716478],
              [-81.299429686207503, 35.742761850148504],
              [-81.299378124079851, 35.743026601273883],
              [-81.299047455715609, 35.742985316670897],
              [-81.298995891115453, 35.743250068537634],
              [-81.297342551288281, 35.743043631320674],
              [-81.297290981047979, 35.743308381559423],
              [-81.295968311479996, 35.743143215058296],
              [-81.29601988591638, 35.742878464464845],
              [-81.295358555267754, 35.742795876096487],
              [-81.295306977589746, 35.743060625536529],
              [-81.294976312268915, 35.743019329857567],
              [-81.294873154920325, 35.743548829212664],
              [-81.2945424867208, 35.7435075323829],
              [-81.29443932699138, 35.744037030366719],
              [-81.294108657018398, 35.743995732365256],
              [-81.294057074486702, 35.744260481175402],
              [-81.29372640487567, 35.744219181216884],
              [-81.29362323781983, 35.744748679312039],
              [-81.292961893203312, 35.744666077095765],
              [-81.2929103064929, 35.744930825363902],
              [-81.292248961115604, 35.744848219280378],
              [-81.292197371956703, 35.745112967231215],
              [-81.291536025818687, 35.745030357280413],
              [-81.291484434211455, 35.745295104914007],
              [-81.291153760606051, 35.74525379890477],
              [-81.291102167605729, 35.745518546357594],
              [-81.29077149325704, 35.745477238412093],
              [-81.290668304132708, 35.746006732912271],
              [-81.290337628010704, 35.745965423794992],
              [-81.290028043941319, 35.747553903657106],
              [-81.290358727501612, 35.747595213573476],
              [-81.290307129302192, 35.747859960513658],
              [-81.290637814229996, 35.747901268767002],
              [-81.290483022932293, 35.74869550788906],
              [-81.290152334837956, 35.748654199225761],
              [-81.29004913758591, 35.749183691766312],
              [-81.289718447692636, 35.74914238103014],
              [-81.289563643892109, 35.749936618690988],
              [-81.289232951169865, 35.749895306646209],
              [-81.289129745213927, 35.750424798219477],
              [-81.288799050718239, 35.750383485002885],
              [-81.288644235494274, 35.751177720280168],
              [-81.288313538194956, 35.751136406656194],
              [-81.288261931020912, 35.75140115153048],
              [-81.287931231872861, 35.751359835990925],
              [-81.287879624411005, 35.751624580663595],
              [-81.28754892457053, 35.751583264989911],
              [-81.287497314609965, 35.75184800950246],
              [-81.287166615131497, 35.751806691871693],
              [-81.287115003777629, 35.752071436203444],
              [-81.287445704286228, 35.752112753069802],
              [-81.287342482676451, 35.752642241873858],
              [-81.287011780031321, 35.752600923833064],
              [-81.286753713688157, 35.75392464350557],
              [-81.286423004997189, 35.753883323903622],
              [-81.286371389885829, 35.754148067744758],
              [-81.286040680476987, 35.754106747107521],
              [-81.285989063972323, 35.754371490767703],
              [-81.285327644056636, 35.754288846524105],
              [-81.285276023997369, 35.754553589887415],
              [-81.284614603321486, 35.754470941776034],
              [-81.284666225517384, 35.754206199587202],
              [-81.283674100203839, 35.754082221090712],
              [-81.283725725203354, 35.753817478366479],
              [-81.283064313258805, 35.753734822696316],
              [-81.283115940031621, 35.753470080201282],
              [-81.282454530418704, 35.753387420329759],
              [-81.282506158964893, 35.753122678063825],
              [-81.281844753919827, 35.753040014850697],
              [-81.281793123262389, 35.753304756843214],
              [-81.281131717457498, 35.753222089762332],
              [-81.281080084325637, 35.753486830535991],
              [-81.280087974432689, 35.753362822775564],
              [-81.280036337821642, 35.753627563995892],
              [-81.279374931147743, 35.753544886481279],
              [-81.279323290982177, 35.753809627404557],
              [-81.278661883548637, 35.753726946022169],
              [-81.278610240934015, 35.753991686627707],
              [-81.27794883274106, 35.753909001377515],
              [-81.277897187652002, 35.754173740764251],
              [-81.277566482466696, 35.754132397115377],
              [-81.277514835984221, 35.754397136321082],
              [-81.277184131186772, 35.754355791616199],
              [-81.276667636162728, 35.757003181690806],
              [-81.276336920041658, 35.756961834740487],
              [-81.276285266624711, 35.757226573205763],
              [-81.275954549760741, 35.757185224319038],
              [-81.275851240907073, 35.757714700822234],
              [-81.275520522269815, 35.757673350763369],
              [-81.275468866832981, 35.757938088801296],
              [-81.275138146397893, 35.757896738628745],
              [-81.275086489567485, 35.75816147648559],
              [-81.274755768389426, 35.758120124376639],
              [-81.274704109059783, 35.758384862072909],
              [-81.27404266673733, 35.758302155766039],
              [-81.273991006063852, 35.758566893123735],
              [-81.271675960603957, 35.758277390444427],
              [-81.271727629772514, 35.758012654023588],
              [-81.271066194641008, 35.757929931521033],
              [-81.271117865582724, 35.757665195329409],
              [-81.270787149567013, 35.757623832416414],
              [-81.270735476464225, 35.757888568491936],
              [-81.269743327279201, 35.757764473951099],
              [-81.269691650671348, 35.758029209571745],
              [-81.269360933897033, 35.757987842790762],
              [-81.269102542667653, 35.759311517700347],
              [-81.269433264720135, 35.759352885165399],
              [-81.269071509270759, 35.761206028065679],
              [-81.268740778723, 35.761164659663542],
              [-81.268637415345552, 35.761694128509681],
              [-81.268968148004717, 35.761735497185491],
              [-81.268813101872908, 35.762529699210852],
              [-81.269143839143041, 35.762571067377834],
              [-81.268885428543541, 35.763894737193588],
              [-81.269216170325024, 35.763936105166579],
              [-81.269112806467291, 35.76446557214139],
              [-81.268782061493908, 35.7644242048165],
              [-81.268678693066207, 35.764953671360082],
              [-81.268347946294199, 35.764912301961772],
              [-81.268244574427257, 35.765441768954688],
              [-81.267913825881891, 35.765400398384052],
              [-81.267862138359988, 35.765665130775695],
              [-81.267200640172391, 35.765582386664704],
              [-81.267148950225874, 35.765847119639268],
              [-81.266487451279659, 35.765764371659849],
              [-81.266435757777614, 35.76602910433683],
              [-81.265774258047799, 35.765946351587772],
              [-81.265722562095831, 35.766211083946487],
              [-81.265391811695196, 35.766169706537681],
              [-81.265340115429439, 35.766434437793222],
              [-81.264347861984433, 35.766310299784671],
              [-81.264296162212943, 35.766575030585102],
              [-81.263965409948341, 35.766533649328359],
              [-81.263913707702358, 35.766798380868991],
              [-81.263582955800729, 35.766756997655051],
              [-81.26353125216059, 35.767021729014303],
              [-81.262869747285919, 35.766938960517663],
              [-81.262818041195885, 35.767203691558571],
              [-81.262487288197647, 35.767162305374818],
              [-81.262383871759823, 35.767691766168412],
              [-81.262053117013565, 35.767650379713338],
              [-81.26200140666576, 35.767915109465989],
              [-81.261670651202152, 35.767873721975334],
              [-81.261618940565782, 35.768138451525971],
              [-81.26128818325391, 35.768097062119168],
              [-81.261236471223299, 35.76836179148826],
              [-81.260905713219202, 35.768320401947001],
              [-81.260802284925362, 35.768849860298097],
              [-81.26047152625361, 35.768808469563851],
              [-81.260419809990438, 35.769073198545762],
              [-81.260089049470523, 35.769031805895381],
              [-81.260037332918557, 35.769296534675213],
              [-81.259706571706431, 35.769255141890277],
              [-81.259654852654563, 35.769519870509093],
              [-81.259324091805567, 35.769478475766903],
              [-81.259272371359387, 35.769743204204147],
              [-81.25894160871249, 35.769701809347971],
              [-81.2586108463789, 35.769660412691969],
              [-81.258559123482527, 35.769925140810756],
              [-81.256905313874597, 35.769718145126667],
              [-81.25685358425477, 35.769982872536772],
              [-81.25619206087066, 35.769900066973257],
              [-81.256243791521598, 35.769635340758633],
              [-81.253597719999277, 35.769304084552871],
              [-81.253649459838741, 35.769039358467325],
              [-81.253318704027592, 35.768997947411499],
              [-81.253370444584249, 35.768733221418323],
              [-81.253039689061808, 35.76869180962121],
              [-81.253091430335658, 35.768427083720297],
              [-81.252429924629439, 35.768344257663003],
              [-81.252481667676207, 35.768079531991233],
              [-81.252150916386839, 35.768038117751537],
              [-81.251820165411004, 35.767996701712022],
              [-81.25171667365008, 35.768526152483425],
              [-81.251385920926438, 35.768484736172411],
              [-81.25112718087189, 35.769808361623816],
              [-81.250796423207973, 35.769766943729394],
              [-81.250692922457731, 35.770296392426047],
              [-81.250362161915191, 35.770254973379323],
              [-81.250258657698637, 35.770784421623247],
              [-81.249927895383067, 35.770743001403901],
              [-81.249772632810334, 35.771537173484099],
              [-81.249441867640911, 35.771495751053976],
              [-81.249390112462322, 35.771760475140979],
              [-81.249059346600859, 35.771719052576294],
              [-81.248955830854101, 35.772248498580751],
              [-81.248625063219762, 35.772207074843315],
              [-81.248521544031419, 35.772736521295847],
              [-81.248190774624121, 35.772695096385803],
              [-81.248139013453468, 35.772959818957389],
              [-81.247808243329061, 35.772918393011622],
              [-81.247756479658037, 35.773183115421773],
              [-81.247425708791482, 35.773141687539159],
              [-81.247373944856619, 35.773406410648271],
              [-81.247043173272985, 35.77336498172992],
              [-81.246991406837722, 35.773629704677603],
              [-81.246660634537008, 35.773588274723615],
              [-81.246608867787714, 35.773852996568024],
              [-81.246278094794945, 35.773811566479324],
              [-81.246226325545422, 35.774076288162291],
              [-81.245895551810463, 35.774034856136765],
              [-81.245843781191169, 35.774299578539086],
              [-81.245513006739259, 35.774258145477759],
              [-81.245461234700244, 35.774522866797149],
              [-81.245130459556293, 35.77448143360121],
              [-81.245078686122568, 35.774746154738708],
              [-81.244417133583624, 35.774663283594762],
              [-81.244365357699294, 35.774928004413475],
              [-81.244034581460099, 35.774886568247211],
              [-81.243982804181016, 35.775151288884054],
              [-81.243652026094026, 35.775109850801364],
              [-81.243600247420204, 35.775374571256272],
              [-81.243269469721881, 35.775333132117453],
              [-81.243217689653278, 35.775597852390476],
              [-81.242886910157253, 35.775556413137437],
              [-81.242835128668887, 35.775821132327451],
              [-81.242504348455853, 35.775779692038562],
              [-81.242452565572691, 35.776044411046691],
              [-81.242121784642663, 35.776002969721993],
              [-81.242070000364691, 35.776267688548245],
              [-81.241408438490396, 35.776184802007059],
              [-81.241356650680899, 35.776449521435879],
              [-81.240695088025532, 35.7763666301245],
              [-81.240643297765288, 35.77663134923425],
              [-81.240312515337237, 35.776589902103566],
              [-81.240260724762948, 35.776854620109923],
              [-81.239268376369836, 35.776730273815332],
              [-81.23932017119256, 35.776465555298593],
              [-81.23865861049299, 35.776382652928099],
              [-81.23871040708859, 35.776117934640681],
              [-81.238048848750964, 35.776035028969574],
              [-81.237056515998546, 35.77591066368192],
              [-81.237004714123671, 35.776175381283942],
              [-81.236673935424534, 35.776133924267015],
              [-81.236622132129753, 35.776398640785992],
              [-81.236291352713792, 35.776357182733221],
              [-81.236187741914478, 35.77688661628379],
              [-81.235856960725727, 35.776845157058098],
              [-81.235701539381552, 35.77763930707625],
              [-81.235370755339389, 35.777597845639441],
              [-81.235267136925884, 35.778127278192414],
              [-81.234936351135914, 35.778085816483831],
              [-81.234780916133687, 35.778879964144949],
              [-81.234450127490177, 35.778838500225071],
              [-81.234398314105107, 35.779103216485893],
              [-81.234067524744788, 35.779061751530193],
              [-81.233912079337728, 35.77985589782925],
              [-81.233581287173621, 35.779814432464576],
              [-81.233529470295309, 35.780079147370145],
              [-81.233198677389524, 35.780037680068489],
              [-81.233146858010386, 35.780302394812317],
              [-81.232816064412688, 35.780260927375885],
              [-81.232764244744274, 35.780525641917258],
              [-81.232102655323743, 35.780442703192982],
              [-81.232050833204269, 35.78070741741513],
              [-81.231389243028531, 35.780624474821472],
              [-81.231337417352151, 35.78088918874473],
              [-81.231006622282464, 35.780847716403009],
              [-81.230902968881196, 35.781377142918778],
              [-81.230572170932959, 35.78133566942423],
              [-81.230520342127036, 35.78160038293818],
              [-81.230189544543066, 35.781558907486392],
              [-81.229982222784301, 35.782617759621758],
              [-81.229651420209805, 35.782576282742895],
              [-81.229288584712705, 35.784429270194366],
              [-81.228957775085419, 35.78438779145673],
              [-81.228854102631544, 35.784917215634131],
              [-81.228523290150392, 35.784875736644658],
              [-81.228419614202465, 35.785405159466272],
              [-81.228088799923697, 35.785363678402639],
              [-81.228036960409653, 35.785628390509785],
              [-81.227706146519964, 35.785586908389938],
              [-81.227654304479728, 35.785851619433849],
              [-81.227323489873399, 35.785810136278023],
              [-81.227271646462597, 35.786074848040755],
              [-81.22694083113953, 35.786033363848937],
              [-81.226837142244108, 35.786562785142351],
              [-81.226506324042333, 35.786521299797585],
              [-81.226298936849176, 35.787580141314152],
              [-81.22596811589311, 35.787538655402862],
              [-81.225864417570349, 35.788068074724791],
              [-81.225533593710807, 35.788026586759365],
              [-81.225429891943122, 35.788556006527422],
              [-81.22443741606422, 35.788431536414919],
              [-81.22438556150297, 35.788696245819629],
              [-81.224054735832709, 35.788654753847204],
              [-81.22400287987594, 35.788919463069625],
              [-81.223672053488997, 35.788877970061172],
              [-81.223568337314163, 35.789407387214915],
              [-81.223237509154444, 35.789365893033242],
              [-81.223185648961149, 35.789630601865845],
              [-81.222978208061903, 35.790689434880953],
              [-81.222647373854883, 35.790647939134658],
              [-81.222543648703251, 35.791177355106512],
              [-81.222212813829486, 35.791135858166768],
              [-81.222109085207506, 35.791665273683243],
              [-81.221778247454907, 35.791623775590395],
              [-81.221674515337867, 35.792153189750266],
              [-81.22134367581242, 35.792111690484134],
              [-81.221291807635453, 35.792376397369011],
              [-81.220960967393324, 35.792334897066773],
              [-81.22090909782068, 35.792599603769233],
              [-81.220578256861899, 35.792558102430895],
              [-81.220526385893507, 35.792822808950788],
              [-81.220195544218086, 35.792781306576344],
              [-81.22014367185399, 35.79304601291382],
              [-81.219481987409395, 35.792963005193691],
              [-81.219430112593344, 35.793227711211436],
              [-81.219099269824198, 35.793186205865837],
              [-81.219047393612414, 35.79345091170098],
              [-81.218716550101917, 35.793409404418142],
              [-81.218664672494356, 35.793674110070825],
              [-81.21833382718593, 35.793632602673135],
              [-81.218281948182593, 35.793897308143173],
              [-81.217951103263601, 35.793855799689211],
              [-81.217899221758429, 35.794120504996847],
              [-81.217568376098129, 35.794078994605613],
              [-81.217516494303169, 35.794343699710652],
              [-81.217185646844953, 35.794302189204522],
              [-81.217133763654161, 35.794566894126902],
              [-81.216802916560709, 35.794525381663377],
              [-81.216751030868053, 35.794790086423362],
              [-81.216420183082704, 35.794748573824819],
              [-81.216316408541317, 35.795277982033042],
              [-81.215985558983192, 35.795236468261081],
              [-81.215933670134461, 35.795501171709319],
              [-81.215602818753709, 35.795459656921381],
              [-81.215550928533773, 35.795724361088155],
              [-81.215220077517827, 35.795682844342736],
              [-81.215168185902002, 35.795947548326865],
              [-81.214837333063443, 35.795906030565398],
              [-81.21478544005177, 35.796170734366989],
              [-81.214454586496586, 35.796129215569323],
              [-81.214123734411942, 35.796087696753752],
              [-81.214175629536157, 35.795822993226658],
              [-81.21318307733371, 35.795698430016046],
              [-81.213234975311551, 35.795433727756574],
              [-81.212573277269144, 35.795350680802038],
              [-81.212625178125705, 35.795085978751636],
              [-81.212294331223319, 35.795044454053233],
              [-81.21234623166562, 35.794779751213625],
              [-81.21201538615918, 35.794738225753605],
              [-81.212067288448637, 35.794473523887014],
              [-81.21140560056719, 35.794390470544727],
              [-81.211457503498551, 35.794125768026319],
              [-81.211126661123657, 35.794084240144137],
              [-81.211178565902102, 35.793819538598704],
              [-81.210847724923113, 35.793778009954927],
              [-81.210899630393328, 35.793513307600257],
              [-81.210237951566924, 35.793430247890711],
              [-81.210289858809617, 35.793165545765262],
              [-81.209628184560501, 35.793082482714645],
              [-81.209680093575685, 35.792817780818538],
              [-81.208687587365816, 35.792693179922999],
              [-81.208739499209514, 35.792428478393418],
              [-81.205762010621854, 35.792054627462754],
              [-81.205813932736461, 35.791789927103814],
              [-81.204821446178229, 35.791665293752125],
              [-81.204769520895454, 35.791929993699036],
              [-81.203777035333957, 35.791805351825673],
              [-81.203725106543175, 35.792070051315264],
              [-81.203063447975381, 35.791986951683725],
              [-81.203011516732573, 35.792251650853196],
              [-81.202680687443248, 35.792210099091214],
              [-81.202628753698448, 35.792474798098048],
              [-81.202297923692996, 35.792433245299861],
              [-81.202245989658238, 35.792697944103928],
              [-81.201915157855197, 35.792656391190647],
              [-81.201811285523164, 35.793185787506914],
              [-81.201480453053989, 35.793144233400042],
              [-81.201324638297393, 35.793938327582602],
              [-81.200993801869387, 35.793896771283606],
              [-81.200941862626678, 35.794161469403377],
              [-81.200611025482573, 35.794119912068204],
              [-81.200559083737758, 35.794384610025197],
              [-81.200228246983599, 35.794343051633732],
              [-81.200176303842696, 35.79460774940793],
              [-81.199845465266421, 35.794566190000197],
              [-81.199741575827872, 35.795095584236577],
              [-81.199410735479418, 35.795054023655197],
              [-81.199358789206272, 35.795318721018496],
              [-81.198697108523319, 35.795235596864359],
              [-81.19864515979792, 35.795500293907374],
              [-81.196990955995773, 35.795292467154617],
              [-81.196939001649852, 35.795557163465197],
              [-81.195946480281208, 35.795432456214876],
              [-81.195998438901469, 35.7951677602966],
              [-81.195667600181537, 35.795126189552839],
              [-81.195771517456677, 35.794596799677031],
              [-81.195440682270572, 35.794555227388202],
              [-81.19549264200711, 35.794290532509656],
              [-81.195161807135577, 35.794248960380465],
              [-81.195213767563601, 35.793984264692781],
              [-81.194882934088213, 35.793942691802229],
              [-81.194934895256623, 35.793677997107721],
              [-81.194273232544219, 35.793594848884908],
              [-81.194221269263863, 35.793859543304571],
              [-81.193559606905964, 35.793776391191592],
              [-81.193507641173412, 35.7940410852909],
              [-81.192515144807558, 35.793916350007628],
              [-81.192184314078901, 35.793874768867809],
              [-81.192132343806534, 35.794139463273027],
              [-81.19147068017601, 35.794056298943048],
              [-81.191418707426919, 35.794320992126792],
              [-81.191087874525152, 35.794279408936575],
              [-81.191035900404259, 35.794544102838564],
              [-81.190705066762263, 35.79450251771091],
              [-81.190653091245238, 35.794767211430084],
              [-81.189991424000311, 35.794684039084501],
              [-81.189939444900446, 35.794948731602013],
              [-81.189277776904405, 35.794865555386131],
              [-81.189225795352272, 35.795130247583316],
              [-81.18889496026776, 35.795088658450041],
              [-81.188842977319325, 35.795353350464303],
              [-81.188512142600729, 35.795311759373796],
              [-81.188356188570182, 35.79610583563241],
              [-81.188025349917666, 35.796064243250441],
              [-81.187921376758695, 35.796593627220972],
              [-81.187590536334284, 35.796552033665165],
              [-81.187538547070076, 35.796816725013976],
              [-81.187207707036094, 35.796775130401954],
              [-81.187155716399801, 35.79703982246901],
              [-81.186824874543987, 35.796998226840465],
              [-81.186876866260704, 35.79673353581213],
              [-81.186215185682158, 35.796650342133503],
              [-81.186267179170571, 35.796385651334624],
              [-81.185936340472182, 35.796344054185631],
              [-81.185988333570307, 35.796079363498698],
              [-81.185657497350007, 35.796037764667417],
              [-81.185917466309533, 35.794714310277612],
              [-81.185586634603538, 35.794672711254918],
              [-81.185638628877768, 35.794408020550463],
              [-81.185307799674135, 35.794366420746528],
              [-81.185515775330785, 35.793307656261817],
              [-81.185184950691351, 35.793266056109225],
              [-81.185236945375863, 35.793001365452646],
              [-81.184906122132602, 35.792959764538743],
              [-81.184958116426785, 35.792695073994196],
              [-81.184627294579869, 35.792653472319031],
              [-81.18473128642222, 35.792124089526844],
              [-81.18440046705183, 35.79208248812899],
              [-81.184452463518483, 35.791817796802349],
              [-81.184121645519966, 35.791776193742209],
              [-81.184173642702092, 35.791511502507618],
              [-81.183842826099919, 35.791469898686294],
              [-81.183894824021934, 35.791205208445092],
              [-81.183564009921909, 35.791163603842563],
              [-81.183616008535211, 35.790898912792386],
              [-81.181300324519725, 35.790607655510854],
              [-81.181352330183628, 35.790342965378194],
              [-81.181021520918236, 35.790301353860677],
              [-81.18107352729767, 35.790036663820189],
              [-81.180742719428508, 35.789995051541439],
              [-81.180846733277875, 35.789465671599586],
              [-81.179854319129277, 35.789340829275481],
              [-81.179802308101443, 35.789605518876151],
              [-81.178148285304374, 35.789397429386078],
              [-81.178200301611298, 35.789132740473313],
              [-81.177869498917431, 35.789091120381073],
              [-81.178129579518313, 35.787767675844918],
              [-81.177798782419572, 35.787726054640792],
              [-81.177902814830631, 35.787196676776375],
              [-81.177572021289762, 35.787155054928974],
              [-81.177624036934461, 35.786890366086219],
              [-81.177293244814024, 35.786848744378815],
              [-81.177501311504813, 35.785789988144856],
              [-81.177170523923394, 35.785748365187985],
              [-81.177274557878434, 35.785218986614098],
              [-81.176943772772987, 35.785177363935006],
              [-81.176995790283399, 35.784912674267176],
              [-81.176665006549896, 35.784871049925812],
              [-81.1767170247998, 35.784606361251548],
              [-81.176386243568331, 35.784564736129376],
              [-81.176438262533424, 35.784300047547461],
              [-81.176107481592126, 35.784258421683987],
              [-81.176159501272338, 35.783993733194393],
              [-81.175828722833145, 35.783952106550103],
              [-81.175880743204289, 35.783687417251855],
              [-81.175549965055168, 35.783645789866277],
              [-81.17560198616583, 35.783381101561545],
              [-81.174940435211937, 35.783297844330178],
              [-81.174992458093357, 35.783033156255371],
              [-81.174661684182752, 35.78299152642937],
              [-81.174713707755075, 35.782726837545923],
              [-81.174382935240544, 35.782685206958888],
              [-81.174434959528, 35.782420518167868],
              [-81.174104188433745, 35.782378887720959],
              [-81.17415621343622, 35.782114199022317],
              [-81.173825443713795, 35.782072566913264],
              [-81.173877470537349, 35.781807878287403],
              [-81.173215934225084, 35.781724611648713],
              [-81.173267961713364, 35.781459923272593],
              [-81.172937195123524, 35.781418288742941],
              [-81.172989224432769, 35.781153600439644],
              [-81.171996930978636, 35.781028691852235],
              [-81.171944898502801, 35.781293379742635],
              [-81.171283369493565, 35.781210102576068],
              [-81.171335402974606, 35.780945414980707],
              [-81.171004640589132, 35.780903775177364],
              [-81.171056675891037, 35.78063908765477],
              [-81.170725913795934, 35.780597447110132],
              [-81.170777949812731, 35.780332759680107],
              [-81.170447190219448, 35.780291118354839],
              [-81.170655333973443, 35.779232367852188],
              [-81.17032457894264, 35.779190726179024],
              [-81.170428651452454, 35.778661351372932],
              [-81.170097898848823, 35.778619708175398],
              [-81.170149935648396, 35.778355020842255],
              [-81.169819184465027, 35.778313377784968],
              [-81.169871221979491, 35.778048690544452],
              [-81.169540472167924, 35.778007045825142],
              [-81.169592510397194, 35.777742358677081],
              [-81.168600266220409, 35.777617421343152],
              [-81.168652308381311, 35.777352734543392],
              [-81.167990816068993, 35.777269438469666],
              [-81.167938772903113, 35.777534124974267],
              [-81.167608026214467, 35.777492475902413],
              [-81.167660071517446, 35.777227788614624],
              [-81.166337093447822, 35.77706118207054],
              [-81.166285045029085, 35.777325868787777],
              [-81.164300582339607, 35.777075930277846],
              [-81.164352638196348, 35.776811244367316],
              [-81.163691155845697, 35.77672792491164],
              [-81.163743213472586, 35.776463239231461],
              [-81.163081735700999, 35.776379916437506],
              [-81.163133795097934, 35.776115230987635],
              [-81.162803057766467, 35.776073567930091],
              [-81.162855117902168, 35.775808883474021],
              [-81.162193646371833, 35.775725554938752],
              [-81.162141583043876, 35.775990240040024],
              [-81.161810846734369, 35.775948574286907],
              [-81.161480110765652, 35.775906907635161],
              [-81.16142804496225, 35.776171591514746],
              [-81.161097308278841, 35.776129923826772],
              [-81.161045242209298, 35.776394608405013],
              [-81.160714503705449, 35.776352939700296],
              [-81.160662436215787, 35.776617623194532],
              [-81.159339484704148, 35.77645093878084],
              [-81.159391557496946, 35.776186254917043],
              [-81.159060821802697, 35.776144581700187],
              [-81.15911289533409, 35.775879898830105],
              [-81.158782159930013, 35.77583822487221],
              [-81.158834234151897, 35.775573541193687],
              [-81.158172769794206, 35.775490190798976],
              [-81.158224845785895, 35.775225507350996],
              [-81.15789411462049, 35.775183830953488],
              [-81.15794619135076, 35.774919148499343],
              [-81.157615461557441, 35.774877470439989],
              [-81.15771961608209, 35.774348104770795],
              [-81.157388889869821, 35.774306426970078],
              [-81.157545122254717, 35.773512378091127],
              [-81.157214399549005, 35.773470699805351],
              [-81.157266477376297, 35.773206016592994],
              [-81.156274313339622, 35.773080975876681],
              [-81.156222232322648, 35.77334565777447],
              [-81.155230068187606, 35.773220608558489],
              [-81.155177982582444, 35.77348529091848],
              [-81.154185818325047, 35.773360232301791],
              [-81.154133729213541, 35.773624914203225],
              [-81.153472285608473, 35.77354153733102],
              [-81.153420195127481, 35.77380621799103],
              [-81.152428029651688, 35.773681145070292],
              [-81.152375933476648, 35.773945826211921],
              [-81.15171449014133, 35.773862438882929],
              [-81.15166239151489, 35.774127119703898],
              [-81.15067022482144, 35.774002032479046],
              [-81.150618123770329, 35.774266711920589],
              [-81.150287400420638, 35.77422501395683],
              [-81.15033950363275, 35.773960334633614],
              [-81.15000878278498, 35.773918635889736],
              [-81.15006088558151, 35.773653955777718],
              [-81.149730166129871, 35.773612256273246],
              [-81.149834373940337, 35.773082897071205],
              [-81.149503656939984, 35.773041196944007],
              [-81.149555761929875, 35.772776516952973],
              [-81.149225046349457, 35.772734816966334],
              [-81.149329256310949, 35.772205457144246],
              [-81.148998543157816, 35.772163755633784],
              [-81.14910275386579, 35.771634395907235],
              [-81.148772044269705, 35.77159269375467],
              [-81.148824149638457, 35.771328014872573],
              [-81.148493441438319, 35.771286311959386],
              [-81.148545547497292, 35.771021632269054],
              [-81.148214840693299, 35.770979928595352],
              [-81.148266948572157, 35.770715248978355],
              [-81.147936242058464, 35.770673544563607],
              [-81.147988350651374, 35.770408865039435],
              [-81.147657646615514, 35.770367158943607],
              [-81.147709754816901, 35.770102479531793],
              [-81.147379052201018, 35.77006077357651],
              [-81.147431162222134, 35.769796094238153],
              [-81.147100461002367, 35.769754387522369],
              [-81.147152571737564, 35.769489708276843],
              [-81.14682187191373, 35.769448000800644],
              [-81.146873983362994, 35.769183321647986],
              [-81.146212585718672, 35.769099903394419],
              [-81.146264700042849, 35.768835224453177],
              [-81.145934003352011, 35.768793514557558],
              [-81.145986117260591, 35.768528834827571],
              [-81.145655421965742, 35.768487124171479],
              [-81.145707536612164, 35.768222445435477],
              [-81.145046149131559, 35.768139020802863],
              [-81.145098266652695, 35.767874342278326],
              [-81.144767574490928, 35.767832629202935],
              [-81.144819691596453, 35.767567949889688],
              [-81.144158311487516, 35.767484520399073],
              [-81.144210430385868, 35.76721984221782],
              [-81.143879742462673, 35.767178126703755],
              [-81.14393186205119, 35.766913447714337],
              [-81.143601175523983, 35.76687173143975],
              [-81.143653295826425, 35.76660705254335],
              [-81.143322610695165, 35.766565335508425],
              [-81.143374732817136, 35.766300656685516],
              [-81.143044047976161, 35.766258938909644],
              [-81.143096170812129, 35.765994260179745],
              [-81.142765488472804, 35.765952541623989],
              [-81.142817610916879, 35.765687863006491],
              [-81.142156249347281, 35.765604422574775],
              [-81.142208374665927, 35.765339744168834],
              [-81.139893627263874, 35.765047673974614],
              [-81.139841494560983, 35.765312351414842],
              [-81.139510816663332, 35.765270623112642],
              [-81.139458682540607, 35.765535299468802],
              [-81.138797325682688, 35.765451840794739],
              [-81.138745188003526, 35.765716516849437],
              [-81.137091796494275, 35.765507851500765],
              [-81.135107739047299, 35.765257423418511],
              [-81.135159888306177, 35.764992747980571],
              [-81.132845179505878, 35.764700540140154],
              [-81.132897335831117, 35.764435866524629],
              [-81.13025198384932, 35.764101860357904],
              [-81.130199818003689, 35.764366533789634],
              [-81.128215813104831, 35.764115989688044],
              [-81.128267985279138, 35.763851317084786],
              [-81.127937320406062, 35.763809556421812],
              [-81.127989494423062, 35.763544884793525],
              [-81.127658829840442, 35.763503123389704],
              [-81.127711004547209, 35.763238450953466],
              [-81.127380342466353, 35.76319668877013],
              [-81.127432516804475, 35.762932017347488],
              [-81.127101856119694, 35.762890254403914],
              [-81.127154032253245, 35.762625582153966],
              [-81.126823372964452, 35.762583818450324],
              [-81.126875548729416, 35.762319147214036],
              [-81.124560952861032, 35.762026777065643],
              [-81.12461313674936, 35.761762105830719],
              [-81.124282482301112, 35.761720335217341],
              [-81.12438685116301, 35.761190992888949],
              [-81.124056200271212, 35.761149221634362],
              [-81.124212753061954, 35.760355208238813],
              [-81.123882105675321, 35.760313436500297],
              [-81.124038659662986, 35.759519423095988],
              [-81.123708015781716, 35.759477650873542],
              [-81.123864569836982, 35.758683636578894],
              [-81.123533929460933, 35.758641863872697],
              [-81.123638299225391, 35.758112520761223],
              [-81.123307661299904, 35.758070747433095],
              [-81.123359846736491, 35.757806076398843],
              [-81.123029211312542, 35.757764302291399],
              [-81.123133583246911, 35.75723495946351],
              [-81.122802949167905, 35.757193184753433],
              [-81.12285513566566, 35.756928512959874],
              [-81.122193871746518, 35.756844961102239],
              [-81.122246061117607, 35.756580289520876],
              [-81.121584800649416, 35.756496733445736],
              [-81.121636990706222, 35.756232063016284],
              [-81.12064510739836, 35.756106723003541],
              [-81.120697300253639, 35.755842052042617],
              [-81.119374798158944, 35.755674919403454],
              [-81.119426994890645, 35.755410248950419],
              [-81.119096371286915, 35.75536846413403],
              [-81.11904417347705, 35.755633133547761],
              [-81.118713549160333, 35.755591347695059],
              [-81.118661348849074, 35.755856016945089],
              [-81.118000099107405, 35.755772441367867],
              [-81.118052301504122, 35.755507771493029],
              [-81.117060432604532, 35.75538240135505],
              [-81.117008227044508, 35.755647070815314],
              [-81.116346980694345, 35.755563485355609],
              [-81.116294772683787, 35.755828154494722],
              [-81.115964148966583, 35.755786360279423],
              [-81.115911938454559, 35.756051029254749],
              [-81.115581314024439, 35.756009234003116],
              [-81.115529103198284, 35.756273901875154],
              [-81.115198478055163, 35.75623210558723],
              [-81.11514626475109, 35.756496774196513],
              [-81.114815638895152, 35.75645497687222],
              [-81.114763425276934, 35.756719644378244],
              [-81.114432797602447, 35.756677846036823],
              [-81.114380582611844, 35.756942514260864],
              [-81.113719327284315, 35.756858914587987],
              [-81.113667109819602, 35.757123581589703],
              [-81.11234460015163, 35.756956370894187],
              [-81.11229237702176, 35.757221037317407],
              [-81.109977991680353, 35.756928382138945],
              [-81.109925759722145, 35.75719304756872],
              [-81.109264508056157, 35.757109423695852],
              [-81.109212274752906, 35.75737408878512],
              [-81.108551021215774, 35.757290460161151],
              [-81.108498785461919, 35.757555124929141],
              [-81.108168157610393, 35.757513309591836],
              [-81.108115920460349, 35.757777974176634],
              [-81.107785291896107, 35.757736157802938],
              [-81.107733052244313, 35.758000822223806],
              [-81.107402424073001, 35.757959004794586],
              [-81.107350183024906, 35.75822366903224],
              [-81.107019554117414, 35.758181849665455],
              [-81.106967311673202, 35.758446513720067],
              [-81.106636682076385, 35.758404694218015],
              [-81.106532192948023, 35.758934021935083],
              [-81.105870929432911, 35.758850378801412],
              [-81.105818682776487, 35.759115041405288],
              [-81.103834895213282, 35.758864089600216],
              [-81.103782640806116, 35.759128752249708],
              [-81.103452010044279, 35.759086923210113],
              [-81.103399754240812, 35.759351585676399],
              [-81.102738490528338, 35.759267924645386],
              [-81.102077228206667, 35.759184260922908],
              [-81.102129488228044, 35.758919599009864],
              [-81.101798859175602, 35.758877765479632],
              [-81.101851119909455, 35.758613103659954],
              [-81.101189864939101, 35.75852943418181],
              [-81.101294389598948, 35.758000110961135],
              [-81.100963764735368, 35.757958275151509],
              [-81.101016027606917, 35.757693613612183],
              [-81.100354782119965, 35.757609939556211],
              [-81.100407046758335, 35.757345278248685],
              [-81.099745803642222, 35.757261600896008],
              [-81.099798071153018, 35.756996939801198],
              [-81.092855132944138, 35.756118107135471],
              [-81.092802843292148, 35.756382765324474],
              [-81.092141616989679, 35.756299045739674],
              [-81.092089323781337, 35.756563703625986],
              [-81.091758710653252, 35.756521842789176],
              [-81.091706416048623, 35.756786500492105],
              [-81.091375801079536, 35.756744637736617],
              [-81.091323505078407, 35.75700929525626],
              [-81.090992890502676, 35.756967431445304],
              [-81.090940593105131, 35.757232088781713],
              [-81.090609976711619, 35.757190223953245],
              [-81.090505378781657, 35.757719538214438],
              [-81.088521678293475, 35.75746832956721],
              [-81.088469371383738, 35.75773298581877],
              [-81.0881387542223, 35.757691114288477],
              [-81.088086447021652, 35.757955770337702],
              [-81.087425212722238, 35.757872024287131],
              [-81.08737290196521, 35.758136680033502],
              [-81.086050433232188, 35.757969175700396],
              [-81.085998116810131, 35.758233830866963],
              [-81.085667499986727, 35.758191952615839],
              [-81.085615183273802, 35.758456607580072],
              [-81.084623329459674, 35.758330966159654],
              [-81.084571009241458, 35.758595620663513],
              [-81.084240390572873, 35.758553738562107],
              [-81.084135745872317, 35.759083046275883],
              [-81.083805125437365, 35.759041162999438],
              [-81.083752802060502, 35.759305816190405],
              [-81.083091560108826, 35.759222046666288],
              [-81.083039234281031, 35.759486699535273],
              [-81.082708612764335, 35.759444813287601],
              [-81.082656284434407, 35.759709465992103],
              [-81.082325662205591, 35.759667578707855],
              [-81.082273333584638, 35.759932231209902],
              [-81.08194271064383, 35.759890342889079],
              [-81.081890379520601, 35.760154995226571],
              [-81.081559755867886, 35.760113105869237],
              [-81.081507423347915, 35.760377758023232],
              [-81.081176798983208, 35.76033586762923],
              [-81.081124465066551, 35.760600519599812],
              [-81.080793839989866, 35.760558628169235],
              [-81.08074150467651, 35.760823279956192],
              [-81.080410878864669, 35.760781386587887],
              [-81.08030620401982, 35.76131069066993],
              [-81.079975576441683, 35.761268796126487],
              [-81.079923237992517, 35.761533447501499],
              [-81.07959260972558, 35.761491552822569],
              [-81.07948792858582, 35.762020855179301],
              [-81.079157298552573, 35.761978959325255],
              [-81.079104955861851, 35.762243610306946],
              [-81.078774325093491, 35.762201712515143],
              [-81.078721981005998, 35.76246636331328],
              [-81.078391349525702, 35.762424464484923],
              [-81.07833900404141, 35.762689115099462],
              [-81.078008371872329, 35.762647216135541],
              [-81.077956024991295, 35.762911866566583],
              [-81.077625392087057, 35.762869965664876],
              [-81.077520695188824, 35.763399266114583],
              [-81.077190059412729, 35.763357364056638],
              [-81.077137710501233, 35.763622014056487],
              [-81.076807074013118, 35.763580110961819],
              [-81.076702370842511, 35.764109410586983],
              [-81.0763717325882, 35.764067506317119],
              [-81.076319379434821, 35.764332155923583],
              [-81.076650018720315, 35.764374059430757],
              [-81.076440606876261, 35.765432657058362],
              [-81.076109963349793, 35.765390752095875],
              [-81.076005252146587, 35.76592004993023],
              [-81.075674606853838, 35.765878143792406],
              [-81.07556989327739, 35.766407441150314],
              [-81.075239246218288, 35.766365533837202],
              [-81.075134528057021, 35.766894830756272],
              [-81.074803879231553, 35.76685292226805],
              [-81.074699157591255, 35.767382218729338],
              [-81.074368505893716, 35.767340309084716],
              [-81.074159054261358, 35.768398900009934],
              [-81.073828398688363, 35.768356988912842],
              [-81.073409468262795, 35.770474164819603],
              [-81.073740132271851, 35.77051607702515],
              [-81.073530663043172, 35.771574663996716],
              [-81.073199993710119, 35.771532751255769],
              [-81.073095255472268, 35.772062043732838],
              [-81.072764584372621, 35.772020129816617],
              [-81.072712213143859, 35.77228477630868],
              [-81.072381542438137, 35.772242861336792],
              [-81.072276795714544, 35.772772153025677],
              [-81.071946123242299, 35.772730236878466],
              [-81.071841373038779, 35.77325952810903],
              [-81.071510698800012, 35.773217610786439],
              [-81.071458321565302, 35.773482255754089],
              [-81.071127646614599, 35.773440337394632],
              [-81.071075268005586, 35.77370498307954],
              [-81.070744592342976, 35.773663063683372],
              [-81.070692212313574, 35.773927708283203],
              [-81.070030857695656, 35.773843866556618],
              [-81.069978476320117, 35.774108510815132],
              [-81.069647798470612, 35.774066588465999],
              [-81.069595414592044, 35.774331232559362],
              [-81.069264736030661, 35.774289309173327],
              [-81.069212351860443, 35.774553953063993],
              [-81.068881672587196, 35.774512028641134],
              [-81.068829285913807, 35.774776672366748],
              [-81.068498605928681, 35.774734746907065],
              [-81.068341440688215, 35.775528677395833],
              [-81.068236662147541, 35.776057964161552],
              [-81.06856734740586, 35.776099890314235],
              [-81.068462569603298, 35.77662917717565],
              [-81.068793258419547, 35.77667110268839],
              [-81.068688481355252, 35.777200389645436],
              [-81.069019172623655, 35.777242314537183],
              [-81.068966784632082, 35.777506958086263],
              [-81.069297476192077, 35.777548882237106],
              [-81.069192701266886, 35.778078168574893],
              [-81.069523396384909, 35.778120092085857],
              [-81.069471009474356, 35.778384735775802],
              [-81.069801705989889, 35.778426658527067],
              [-81.06974931976832, 35.778691301409083],
              [-81.070080017681335, 35.778733223400565],
              [-81.070027633300583, 35.778997867258099],
              [-81.070358332611193, 35.779039788489925],
              [-81.070253562696109, 35.7795690754822],
              [-81.070584264458958, 35.779610996092813],
              [-81.070531881136404, 35.779875639190024],
              [-81.070862584296691, 35.779917559040989],
              [-81.070810200603418, 35.780182203151192],
              [-81.071140905138165, 35.780224121341256],
              [-81.071036139915662, 35.780753408782552],
              [-81.071366848031701, 35.780795327233712],
              [-81.071314465396682, 35.781059970583591],
              [-81.071645174910287, 35.781101888275067],
              [-81.071592792987232, 35.781366531717957],
              [-81.07192350389839, 35.781408448649664],
              [-81.071871122687512, 35.781673092185713],
              [-81.072201834996235, 35.781715008357565],
              [-81.072149454497335, 35.781979651986681],
              [-81.07248016820364, 35.782021567398843],
              [-81.072427789522848, 35.782286211102118],
              [-81.072758504626734, 35.782328125754482],
              [-81.072653747240651, 35.782857413320535],
              [-81.072984464797059, 35.782899327351579],
              [-81.072932086091782, 35.78316397121435],
              [-81.073262806151646, 35.783205884466831],
              [-81.073158050928654, 35.783735172313996],
              [-81.07348877231199, 35.78377708406282],
              [-81.073384018933808, 35.784306371986141],
              [-81.073714742792774, 35.784348284014875],
              [-81.073662366621349, 35.784612927145666],
              [-81.073993091878037, 35.784654838414497],
              [-81.073940716441925, 35.784919482539408],
              [-81.074932898492605, 35.785045209550283],
              [-81.074985271892928, 35.784780565892156],
              [-81.076308182601451, 35.784948188462359],
              [-81.076255814549896, 35.785212833556869],
              [-81.076917274081651, 35.785296640179162],
              [-81.076864908903389, 35.785561285486374],
              [-81.077195639673661, 35.785603187147338],
              [-81.077143275207703, 35.785867832547382],
              [-81.07780474099215, 35.78595163343229],
              [-81.077752378293411, 35.786216279063773],
              [-81.079075320429155, 35.786383871470093],
              [-81.079022960501803, 35.786648517628848],
              [-81.079353699047573, 35.786690413378722],
              [-81.079301339809433, 35.786955058729333],
              [-81.080624298343707, 35.787122634256228],
              [-81.081285780207793, 35.78720641616907],
              [-81.081233428063214, 35.787471062286059],
              [-81.081564170011191, 35.787512952042768],
              [-81.081459466827638, 35.788042245317897],
              [-81.081790212334397, 35.7880841344342],
              [-81.081685509890576, 35.788613427803973],
              [-81.082016257850057, 35.788655316298737],
              [-81.081911556146068, 35.789184609762962],
              [-81.082242306558328, 35.789226497636207],
              [-81.082189956235965, 35.789491143987725],
              [-81.08252070804582, 35.789533031100973],
              [-81.082468358435975, 35.789797677545401],
              [-81.08279911164361, 35.789839563898589],
              [-81.082694413506459, 35.790368856927302],
              [-81.083025169166916, 35.790410742658949],
              [-81.082972821745628, 35.790675389224369],
              [-81.082642063947318, 35.790633504274332],
              [-81.082589715128307, 35.790898150655757],
              [-81.082258956594217, 35.790856263767644],
              [-81.08204955035383, 35.791914848339452],
              [-81.082711075099866, 35.791998622343641],
              [-81.082658724517174, 35.792263268654004],
              [-81.083320252720739, 35.792347038439978],
              [-81.083267903905721, 35.79261168498153],
              [-81.083598670141214, 35.792653569106314],
              [-81.083546322038913, 35.792918215740585],
              [-81.084207856518773, 35.79300198068929],
              [-81.084155511290376, 35.793266627535829],
              [-81.085147819309682, 35.793392269076413],
              [-81.085095476881193, 35.793656915391452],
              [-81.08542624833774, 35.793698794540347],
              [-81.085373906645088, 35.793963441849257],
              [-81.08570467947618, 35.794005319336968],
              [-81.085547653273395, 35.794799260541176],
              [-81.085216877276608, 35.794757382638217],
              [-81.085112189678981, 35.795286675962174],
              [-81.084781411914108, 35.795244796883821],
              [-81.08462437421808, 35.796038735670379],
              [-81.084955156278156, 35.796080616046112],
              [-81.084745771731932, 35.79713920071373],
              [-81.085076558355951, 35.797181080744664],
              [-81.084919519774246, 35.797975018499258],
              [-81.084588730007425, 35.797933138954228],
              [-81.084431685176412, 35.798727075881416],
              [-81.084100892586221, 35.798685195022522],
              [-81.083996191470945, 35.799214485813984],
              [-81.08366539708932, 35.799172602878521],
              [-81.083613044962419, 35.799437248067065],
              [-81.083282249891354, 35.799395364995725],
              [-81.083177542498774, 35.799924654958453],
              [-81.082846745636374, 35.799882769810608],
              [-81.08279439037068, 35.800147414584742],
              [-81.08246359168939, 35.800105528418705],
              [-81.082411235025745, 35.800370173008616],
              [-81.082080436737783, 35.800328285786563],
              [-81.082028077569902, 35.800592930211103],
              [-81.081697278592529, 35.800551042853215],
              [-81.081644919109422, 35.800815686173301],
              [-81.080983318942586, 35.800731907602696],
              [-81.080930955923023, 35.80099655152015],
              [-81.080600155839747, 35.800954660288369],
              [-81.080547791422163, 35.801219304021487],
              [-81.080216990626099, 35.801177411752732],
              [-81.080164624787201, 35.801442054400368],
              [-81.079833822195567, 35.801400162014559],
              [-81.079781456064609, 35.801664804458902],
              [-81.079450652737123, 35.801622910134896],
              [-81.079345915170251, 35.802152196449065],
              [-81.079015111180865, 35.802110300930664],
              [-81.078648511419146, 35.803962797009888],
              [-81.078317699278159, 35.803920899642506],
              [-81.078212951273287, 35.80445018277377],
              [-81.077882137364256, 35.80440828423081],
              [-81.07762025388628, 35.805731491501014],
              [-81.078281894814509, 35.805815289035216],
              [-81.078229518533163, 35.806079930459362],
              [-81.079221984533817, 35.806205619553779],
              [-81.079169612182383, 35.806470261328442],
              [-81.080162085564069, 35.806595943632466],
              [-81.0800573461662, 35.807125227874479],
              [-81.080388173400252, 35.807167119554158],
              [-81.080073954396397, 35.808754971086032],
              [-81.080404788329901, 35.8087968635989],
              [-81.080195306698499, 35.809855429711803],
              [-81.07986446745943, 35.809813537565191],
              [-81.079759721922528, 35.810342820077764],
              [-81.079707348628219, 35.81060746081431],
              [-81.079376506541848, 35.810565566452503],
              [-81.079271756836746, 35.811094848411656],
              [-81.078940912981906, 35.811052952874206],
              [-81.078888536570986, 35.81131759409682],
              [-81.078557692003329, 35.811275697522191],
              [-81.078505314170741, 35.811540337659075],
              [-81.078174468890268, 35.811498440047281],
              [-81.078122089682424, 35.811763080900661],
              [-81.077791243689148, 35.811721182251674],
              [-81.077738861953193, 35.811985822038189],
              [-81.077408015247116, 35.811943922352093],
              [-81.077146098381817, 35.813267120763854],
              [-81.077476951472661, 35.81330902112348],
              [-81.07737218464969, 35.813838300077819],
              [-81.077041329447439, 35.81379639944128],
              [-81.076831786357431, 35.814854956260881],
              [-81.076500928381435, 35.814813054152864],
              [-81.076396152091135, 35.815342331116895],
              [-81.075734431838796, 35.815258523669542],
              [-81.075682041079375, 35.815523162255907],
              [-81.075020318980975, 35.81543935095538],
              [-81.074967925766998, 35.815703989218584],
              [-81.074637064176486, 35.81566208208168],
              [-81.07458466956389, 35.815926720160107],
              [-81.073592084766162, 35.815800992021401],
              [-81.073539685536957, 35.816065629656983],
              [-81.07287796121976, 35.815981806740254],
              [-81.072825560619322, 35.816246443132719],
              [-81.07216383556279, 35.816162616343888],
              [-81.07211143253086, 35.816427253314195],
              [-81.071780569438374, 35.816385337532061],
              [-81.07172816390127, 35.816649974336435],
              [-81.071066436656906, 35.816566140699813],
              [-81.071014028665232, 35.816830777180982],
              [-81.070683164490347, 35.816788858425419],
              [-81.070630755099955, 35.817053494721755],
              [-81.070525935266772, 35.817582766274633],
              [-81.07019506826768, 35.817540846204672],
              [-81.070090244950634, 35.81807011729542],
              [-81.069759376206179, 35.818028196950785],
              [-81.069654549382108, 35.818557466678229],
              [-81.069323677762711, 35.818515545176524],
              [-81.069271262780262, 35.818780179832274],
              [-81.06894039155469, 35.818738257274553],
              [-81.068835557342354, 35.819267526188817],
              [-81.068504683241912, 35.819225602474106],
              [-81.068399845545443, 35.819754870925962],
              [-81.068068970759896, 35.819712945115448],
              [-81.068016549787941, 35.819977579142751],
              [-81.067685674312713, 35.819935653195976],
              [-81.067318712699247, 35.821788088181307],
              [-81.066987829070015, 35.82174616038472],
              [-81.066882977045637, 35.822275426196079],
              [-81.066552091624843, 35.822233496322426],
              [-81.066499664053467, 35.822498129470524],
              [-81.066168777943147, 35.822456199460611],
              [-81.066116348949635, 35.822720831522716],
              [-81.065785462126584, 35.822678900475445],
              [-81.065733031733984, 35.822943532352596],
              [-81.065402144198302, 35.822901600267933],
              [-81.065297279188286, 35.823430864525704],
              [-81.064966389861056, 35.823388930363954],
              [-81.064809087168797, 35.824182826427993],
              [-81.064478195017159, 35.824140890951739],
              [-81.064320884938169, 35.824934785299888],
              [-81.063989989962096, 35.82489284850908],
              [-81.063832673625868, 35.825686742023407],
              [-81.063501774718816, 35.825644803936861],
              [-81.06334445212498, 35.826438696617274],
              [-81.063013550393421, 35.82639675721606],
              [-81.062856221518942, 35.827190648161348],
              [-81.062525316962848, 35.827148707445552],
              [-81.062472871893149, 35.827413337535276],
              [-81.062141966647417, 35.827371396683226],
              [-81.062037073343149, 35.827900655544816],
              [-81.061706166328833, 35.827858713516733],
              [-81.061653718094135, 35.828123342288613],
              [-81.061322810367216, 35.828081399222988],
              [-81.061217909672365, 35.828610657269898],
              [-81.05989427257515, 35.82844287222516],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 33,
        ORIG_FID: 2,
        AAPC_Examp: 4,
        Area_mile2: 220,
        BASIN_NAME: "Lake Hickory",
        Res_Basin: "Hickory",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.330640602748915, 35.996923791592288],
              [-81.328318065488972, 35.996635446617518],
              [-81.32836969884471, 35.996370734049343],
              [-81.328037910862633, 35.996329538555337],
              [-81.32814117979116, 35.995800113521355],
              [-81.327477607984406, 35.995717720392911],
              [-81.327529244619413, 35.995453008063159],
              [-81.327197460815142, 35.99541180937063],
              [-81.327249098173624, 35.995147097127344],
              [-81.326917314687805, 35.995105898591142],
              [-81.326968952769647, 35.994841186434407],
              [-81.32663717179382, 35.994799987111243],
              [-81.326792087193269, 35.994005850752629],
              [-81.326460309717291, 35.993964650034521],
              [-81.326511948568395, 35.993699937952144],
              [-81.326180172519514, 35.993658737369259],
              [-81.326231812067931, 35.993394024472444],
              [-81.325900037420226, 35.993352823123764],
              [-81.325951677718123, 35.993088111214647],
              [-81.325619904445702, 35.993046908199062],
              [-81.325671545466889, 35.992782196376581],
              [-81.325339774730267, 35.992740993475124],
              [-81.325391416448923, 35.99247628083819],
              [-81.325059646004732, 35.992435077192056],
              [-81.325317854903332, 35.991111516650022],
              [-81.324986091217937, 35.991070312761018],
              [-81.325192656475096, 35.990011463622977],
              [-81.324529138592837, 35.989929053326591],
              [-81.324580781197241, 35.989664341641301],
              [-81.32424902381365, 35.989623134825976],
              [-81.324300668224069, 35.989358422305237],
              [-81.323968912267418, 35.989317215625242],
              [-81.32402055629241, 35.989052503212314],
              [-81.323688801736907, 35.989011295766581],
              [-81.323740447593821, 35.988746583419349],
              [-81.32340869441343, 35.988705374306818],
              [-81.323460339910852, 35.988440662968408],
              [-81.323128589240184, 35.988399453069086],
              [-81.323593395061167, 35.98601704368118],
              [-81.322598173785025, 35.985893413146449],
              [-81.322546527115335, 35.98615812528508],
              [-81.32221478629711, 35.986116912867168],
              [-81.322266434028862, 35.985852200864535],
              [-81.321602956667775, 35.985769774475614],
              [-81.321551305677332, 35.986034485326329],
              [-81.320556088424297, 35.985910838132568],
              [-81.320504433934673, 35.986175549426953],
              [-81.319840954580243, 35.986093112597473],
              [-81.319789297601645, 35.986357822669589],
              [-81.319125818587651, 35.986275381940274],
              [-81.31907415917189, 35.986540092592129],
              [-81.318410678281097, 35.986457648005157],
              [-81.318359016402255, 35.986722358335761],
              [-81.31670031477195, 35.986516229508148],
              [-81.31664864721796, 35.986780938208042],
              [-81.314658211191869, 35.986533553203223],
              [-81.314606535817987, 35.986798261058489],
              [-81.314274796424527, 35.986757027086149],
              [-81.314223119649526, 35.987021734756084],
              [-81.313891380641891, 35.986980499725021],
              [-81.313943058453077, 35.986715791290159],
              [-81.313611319737902, 35.986674555514455],
              [-81.313662998271994, 35.986409847166435],
              [-81.313331260957867, 35.986368610625256],
              [-81.313486297737313, 35.985574486594793],
              [-81.313154565031184, 35.985533248638184],
              [-81.313206244332804, 35.98526854036534],
              [-81.313537975977042, 35.985309778185822],
              [-81.31364133143903, 35.984780361220196],
              [-81.313973062406959, 35.984821597845865],
              [-81.314179763905315, 35.983762762800652],
              [-81.314511489855988, 35.983803998001349],
              [-81.31461483700113, 35.983274579900915],
              [-81.314946561141028, 35.98331581302682],
              [-81.314998232034455, 35.983051103787709],
              [-81.314666508956478, 35.983009870797908],
              [-81.314821525005527, 35.982215743151848],
              [-81.314489806534993, 35.982174508746652],
              [-81.314541478196048, 35.98190979958305],
              [-81.314209761152412, 35.981868565313391],
              [-81.314261433510467, 35.981603855335756],
              [-81.31392971786768, 35.981562620300529],
              [-81.313981392082994, 35.981297911289857],
              [-81.313649676706817, 35.981256674609128],
              [-81.313701351644923, 35.980991965685426],
              [-81.313369638804005, 35.980950729119215],
              [-81.313421314439168, 35.980686019381437],
              [-81.312757890763962, 35.98060354293667],
              [-81.312809568209488, 35.980338834322907],
              [-81.312477858483774, 35.980297594423277],
              [-81.311151021914696, 35.980132627632074],
              [-81.311202704304478, 35.979867918748809],
              [-81.309212467606613, 35.97962044049865],
              [-81.309264157162474, 35.979355733263034],
              [-81.308600749757957, 35.979273233272572],
              [-81.308652441098232, 35.979008526260131],
              [-81.308320738966543, 35.978967275048753],
              [-81.308424121618799, 35.978437860268741],
              [-81.308092423058255, 35.978396608407174],
              [-81.308144115504561, 35.978131901519809],
              [-81.307812417236491, 35.978090648913813],
              [-81.307864110405362, 35.977825942113412],
              [-81.307532413538112, 35.977784688742055],
              [-81.307635800956149, 35.977255274365334],
              [-81.307304107660059, 35.977214020343808],
              [-81.307459188306936, 35.976419898389658],
              [-81.307127498535223, 35.976378643875123],
              [-81.307385966661286, 35.975055107250157],
              [-81.307717650016187, 35.975096361104818],
              [-81.307769341551946, 35.974831653136143],
              [-81.307437658150207, 35.974790399438561],
              [-81.307644424065785, 35.973731568540366],
              [-81.307312746358249, 35.973690314465017],
              [-81.307416128837261, 35.973160898554241],
              [-81.307084453592182, 35.973119643849763],
              [-81.307136145938827, 35.972854935946557],
              [-81.306804472094498, 35.972813680476797],
              [-81.30685616405512, 35.972548972681729],
              [-81.30619281948124, 35.972466458409158],
              [-81.306244514334338, 35.972201750816524],
              [-81.305581173266006, 35.972119234111588],
              [-81.305632869903107, 35.971854526742348],
              [-81.305301200939297, 35.9718132671739],
              [-81.305352898298793, 35.971548559891971],
              [-81.305021230709869, 35.971507298657151],
              [-81.305072928791731, 35.971242591462435],
              [-81.304741262629364, 35.971201330363428],
              [-81.304844661007095, 35.970671916078558],
              [-81.304512997281151, 35.970630653449561],
              [-81.304564696468645, 35.970365946380397],
              [-81.304233035277562, 35.97032468386621],
              [-81.304284735187295, 35.970059976884329],
              [-81.303953074262836, 35.970018712724894],
              [-81.304004776003325, 35.969754005809293],
              [-81.30367311650518, 35.969712741785685],
              [-81.303724817859631, 35.969448034978271],
              [-81.303061504195057, 35.969365503556418],
              [-81.303113207307533, 35.969100796071451],
              [-81.302781552058377, 35.969059529595164],
              [-81.30283325591877, 35.968794823098555],
              [-81.302501602044643, 35.968753554956045],
              [-81.302553307735707, 35.968488848525858],
              [-81.301558351358608, 35.968365039999711],
              [-81.301610058786366, 35.968100333950169],
              [-81.3009467583625, 35.968017790998658],
              [-81.300998468656601, 35.967753084250766],
              [-81.299008582978132, 35.967505433658843],
              [-81.298956866289871, 35.967770138688188],
              [-81.298293571294138, 35.967687581314799],
              [-81.298241852143789, 35.967952286022843],
              [-81.297578557465187, 35.967869723849567],
              [-81.297526834769968, 35.968134429158056],
              [-81.296531891558573, 35.968010578739616],
              [-81.296583616355221, 35.967745874761782],
              [-81.295588679382021, 35.96762201663995],
              [-81.295640408106237, 35.967357312100113],
              [-81.295308763819051, 35.967316024400041],
              [-81.295360493290914, 35.967051320848661],
              [-81.295028851512853, 35.967010032362644],
              [-81.295080580572787, 35.966745328018462],
              [-81.294748940195433, 35.966704038767489],
              [-81.294800671111261, 35.966439335390923],
              [-81.294469031026324, 35.966398045395678],
              [-81.294520762638527, 35.966133341205463],
              [-81.293857487826074, 35.966050758741879],
              [-81.293909221221554, 35.965786054775386],
              [-81.293577585385677, 35.965744762327901],
              [-81.293629319528705, 35.9654800593499],
              [-81.293681053306742, 35.965215355422096],
              [-81.293349419932937, 35.965174062345795],
              [-81.293608089090185, 35.963850544457472],
              [-81.29393971715767, 35.963891836852177],
              [-81.294612201167169, 35.960450680019008],
              [-81.294280588003886, 35.960409389375378],
              [-81.294384043339619, 35.959879979910077],
              [-81.29405243372068, 35.959838687715902],
              [-81.294104161386542, 35.959573983056949],
              [-81.293109335583949, 35.959450103319547],
              [-81.293161067202192, 35.959185398999892],
              [-81.29216624874536, 35.959061511539559],
              [-81.292217983207649, 35.958796807580136],
              [-81.291554774344107, 35.958714211413003],
              [-81.291606510589276, 35.958449507677507],
              [-81.29127490826896, 35.958408207917572],
              [-81.291326645235856, 35.958143504269707],
              [-81.29099504434167, 35.958102204645769],
              [-81.291046782004713, 35.957837500184574],
              [-81.290715182510908, 35.957796199795844],
              [-81.290766920921229, 35.957531496323256],
              [-81.289440530507591, 35.957366283598446],
              [-81.289492272822784, 35.95710158062252],
              [-81.287834298875111, 35.956895045776186],
              [-81.287886046130566, 35.956630342532399],
              [-81.287554454296668, 35.956589032618908],
              [-81.287606203407506, 35.956324330342966],
              [-81.287274611865911, 35.956283019685429],
              [-81.287326361698319, 35.95601831749719],
              [-81.28699477155709, 35.955977006074832],
              [-81.287046522111069, 35.955712303974316],
              [-81.287150022175155, 35.955182898726328],
              [-81.287481609133394, 35.955224209739647],
              [-81.287533356474285, 35.954959506476435],
              [-81.287864942710883, 35.955000816452241],
              [-81.287968435386333, 35.954471410387676],
              [-81.288300019814827, 35.954512718288441],
              [-81.288403509010593, 35.953983311756559],
              [-81.28906667358487, 35.954065926132031],
              [-81.289118414984856, 35.953801222080379],
              [-81.28978157766096, 35.953883831698249],
              [-81.289833317733411, 35.95361912820556],
              [-81.290164898506816, 35.953660431075797],
              [-81.290371847757896, 35.952601613432677],
              [-81.290040271228293, 35.952560311107746],
              [-81.29019548304413, 35.951766197093285],
              [-81.289863910011178, 35.951724893375207],
              [-81.289915648404133, 35.951460188729065],
              [-81.290247219267997, 35.951501492331573],
              [-81.290350691805912, 35.950972082641755],
              [-81.291013831390728, 35.951054685676155],
              [-81.29106556447482, 35.95078998049609],
              [-81.29206027378109, 35.950913878320421],
              [-81.292112003343163, 35.950649172682809],
              [-81.292443571973706, 35.950690469432161],
              [-81.292495300135613, 35.950425763609729],
              [-81.292826868070222, 35.950467060222678],
              [-81.292982046203846, 35.949672941174896],
              [-81.29265048147758, 35.949631645872003],
              [-81.292702208282378, 35.949366939855437],
              [-81.292370644930571, 35.949325642886706],
              [-81.292629276928054, 35.948002111898816],
              [-81.292297719219846, 35.947960814710648],
              [-81.292401172662423, 35.947431402592571],
              [-81.290080295322966, 35.947142300714255],
              [-81.290132028960954, 35.946877595536911],
              [-81.289800477149413, 35.946836291549133],
              [-81.289852211508887, 35.94657158645969],
              [-81.289520662205433, 35.946530281686414],
              [-81.289572397286378, 35.946265576684887],
              [-81.289240849383106, 35.946224271146924],
              [-81.289292585159927, 35.945959565332338],
              [-81.288961037548518, 35.945918259050451],
              [-81.289012775180453, 35.945653554203979],
              [-81.288349684205286, 35.945570939189018],
              [-81.288401423619362, 35.945306234566871],
              [-81.287738336122956, 35.94522361622046],
              [-81.287790077293607, 35.944958910921585],
              [-81.287458535669145, 35.944917600522828],
              [-81.287510276478713, 35.944652896233798],
              [-81.287178736254276, 35.944611585070319],
              [-81.28723047886767, 35.944346879947368],
              [-81.285572789247581, 35.944140311275994],
              [-81.2856245357425, 35.943875607708435],
              [-81.285293000758259, 35.943834290489825],
              [-81.28534474908254, 35.943569586989582],
              [-81.285013214415685, 35.943528269928109],
              [-81.28506496343573, 35.943263565614792],
              [-81.284733430169013, 35.943222247788789],
              [-81.284836929312249, 35.942692839289748],
              [-81.284505399614176, 35.94265152081482],
              [-81.284608899521231, 35.942122112395005],
              [-81.284277372283768, 35.942080793291908],
              [-81.284484372267542, 35.941021976417709],
              [-81.284815895288077, 35.941063295876205],
              [-81.28486764226696, 35.940798591421071],
              [-81.28453612141503, 35.940757272078251],
              [-81.284743113503296, 35.939698454257936],
              [-81.284411596150562, 35.939657135481589],
              [-81.284566840640636, 35.938863021101618],
              [-81.284898354837239, 35.938904340369739],
              [-81.285001846229605, 35.938374929989109],
              [-81.285053591983527, 35.938110225166497],
              [-81.285385102204486, 35.938151542244213],
              [-81.285540333115236, 35.937357426197252],
              [-81.285871840493911, 35.937398741964714],
              [-81.286182286765452, 35.935810506848341],
              [-81.286513787012666, 35.935851820917186],
              [-81.286617263898577, 35.935322408559962],
              [-81.287280263384858, 35.935405033407825],
              [-81.287331998631231, 35.935140326443914],
              [-81.288326496115829, 35.935264256570278],
              [-81.288378227867142, 35.934999550050016],
              [-81.289372725197978, 35.935123470758015],
              [-81.28942445342868, 35.934858763780397],
              [-81.290087451954477, 35.934941372494094],
              [-81.290139176617117, 35.934676665216401],
              [-81.290470675315518, 35.934717967635002],
              [-81.290522399686552, 35.934453260151855],
              [-81.290853896555731, 35.934494561554025],
              [-81.291009063293046, 35.933700436625095],
              [-81.290677568471594, 35.933659134751842],
              [-81.290884456175419, 35.932600300197038],
              [-81.291215946755059, 35.9326416015248],
              [-81.291422823682723, 35.93158276567496],
              [-81.291091338452574, 35.93154146487187],
              [-81.291143057620047, 35.93127675593071],
              [-81.290148608253347, 35.93115284850446],
              [-81.290200330262749, 35.930888139924328],
              [-81.289537368204151, 35.930805530735043],
              [-81.289589091995111, 35.930540822379456],
              [-81.289257613088893, 35.930499516559507],
              [-81.289309337600926, 35.930234808292234],
              [-81.288977860094235, 35.930193501707805],
              [-81.289081311303946, 35.929664084379965],
              [-81.288749836257068, 35.929622777167488],
              [-81.288801562980183, 35.929358069007939],
              [-81.288470088224841, 35.929316761051766],
              [-81.288521815669071, 35.929052052980609],
              [-81.288190343421164, 35.929010744239172],
              [-81.288242070453009, 35.928746035376037],
              [-81.287579129095249, 35.928663415463589],
              [-81.287630859041968, 35.928398707705426],
              [-81.286967921136025, 35.928316083561405],
              [-81.287019652864075, 35.928051376028009],
              [-81.286688185492892, 35.928010063191707],
              [-81.286739917916449, 35.927745354845683],
              [-81.286408451944837, 35.92770404124488],
              [-81.286718845775326, 35.926115792780202],
              [-81.286387387612095, 35.926074479076448],
              [-81.286542583128693, 35.925280354605043],
              [-81.286211128485149, 35.925239040409757],
              [-81.286262860697292, 35.924974331992665],
              [-81.285931406345384, 35.924933017053782],
              [-81.285983140386307, 35.924668308704376],
              [-81.285651687433685, 35.924626993001134],
              [-81.285755155510429, 35.924097576452056],
              [-81.28542370612513, 35.924056260100144],
              [-81.285578907215012, 35.923262135357142],
              [-81.285910353419823, 35.923303451299745],
              [-81.286013816996785, 35.92277403429177],
              [-81.286345261420635, 35.922815349060755],
              [-81.286396991640231, 35.922550640348469],
              [-81.286728435368673, 35.922591954981357],
              [-81.286987074945216, 35.921268409138214],
              [-81.286655636542861, 35.921227096088508],
              [-81.28670736470194, 35.920962386187632],
              [-81.286044493250287, 35.920879757617378],
              [-81.286096223190313, 35.920615047941361],
              [-81.285764787925473, 35.920573732462344],
              [-81.285816518611924, 35.920309023776014],
              [-81.285153653409964, 35.920226389446015],
              [-81.285101920603438, 35.920491097859532],
              [-81.284770487463888, 35.920449779657609],
              [-81.284718753258133, 35.920714487886755],
              [-81.284387318290086, 35.920673168668387],
              [-81.284335582684989, 35.920937876713225],
              [-81.283672713836324, 35.920855234358896],
              [-81.28362097466426, 35.921119942103594],
              [-81.283289539701499, 35.921078619889435],
              [-81.283237800237927, 35.921343327429135],
              [-81.282906363421105, 35.921302003297448],
              [-81.282854622583628, 35.921566711553737],
              [-81.282523185045989, 35.921525386384872],
              [-81.28247144278383, 35.921790093555799],
              [-81.282140004550769, 35.921748768250794],
              [-81.282088259781517, 35.92201347525797],
              [-81.28175682190998, 35.921972147994012],
              [-81.281705075741485, 35.922236854816845],
              [-81.281373637149159, 35.922195526515679],
              [-81.281321889581221, 35.922460233154027],
              [-81.28032757046978, 35.92233624337922],
              [-81.280379321217893, 35.922071537150231],
              [-81.280047884008198, 35.922030206147205],
              [-81.280099635477058, 35.921765500006813],
              [-81.279768199641353, 35.921724167338503],
              [-81.27981995183093, 35.921459461286602],
              [-81.279157083297449, 35.921376793520807],
              [-81.27920883726776, 35.921112087693928],
              [-81.278877404569968, 35.921070752596457],
              [-81.278929159260983, 35.920806046858232],
              [-81.278597727962534, 35.92076471099648],
              [-81.278649483374224, 35.920500005346824],
              [-81.278318053475218, 35.920458668720833],
              [-81.278369810715304, 35.920193963139091],
              [-81.278038382215527, 35.920152625749019],
              [-81.278090139068524, 35.919887920276452],
              [-81.277758713075897, 35.919846582101513],
              [-81.277810470624104, 35.91958187581649],
              [-81.27747904492297, 35.91954053689804],
              [-81.277530804324968, 35.919275831582105],
              [-81.27719938002312, 35.919234491899459],
              [-81.277302898795824, 35.918705080516474],
              [-81.276971478061057, 35.918663740185515],
              [-81.277023237457158, 35.918399035019561],
              [-81.276028979360845, 35.918275009052877],
              [-81.27597721567723, 35.918539713829873],
              [-81.274982957455492, 35.918415679347653],
              [-81.27493119025273, 35.91868038366718],
              [-81.273936931880414, 35.91855633976828],
              [-81.273885161183884, 35.91882104453147],
              [-81.273553741697654, 35.91877969401969],
              [-81.273501968494017, 35.919044398618979],
              [-81.27317054828741, 35.919003047070014],
              [-81.27311877368443, 35.919267751484945],
              [-81.272787352757234, 35.919226398898751],
              [-81.272839127337775, 35.918961695542066],
              [-81.272176288647032, 35.91887898884189],
              [-81.272228065007937, 35.918614285710412],
              [-81.27189664724429, 35.918572931596422],
              [-81.271948424300277, 35.918308227652652],
              [-81.271617007935973, 35.918266872774545],
              [-81.271668786845467, 35.918002169799792],
              [-81.271337370747545, 35.917960813277197],
              [-81.271389150377445, 35.917696110391134],
              [-81.271057736786489, 35.917654753083823],
              [-81.271109517136779, 35.91739005028645],
              [-81.270778104945265, 35.917348692215143],
              [-81.270881665614084, 35.916819285869515],
              [-81.270550255906997, 35.916777928071696],
              [-81.270705598084888, 35.915983818143303],
              [-81.270374193004457, 35.915942459833978],
              [-81.270425974472076, 35.915677756258248],
              [-81.270757378518027, 35.91571911533206],
              [-81.271068051671492, 35.914130893896861],
              [-81.270736653984372, 35.91408953564251],
              [-81.27094376612321, 35.913030720656089],
              [-81.270612373014458, 35.912989362047419],
              [-81.27076770726849, 35.912195250029612],
              [-81.270436317703712, 35.912153891831231],
              [-81.270488096538458, 35.911889187859437],
              [-81.270156708347585, 35.911847827995999],
              [-81.270260266010411, 35.911318420203045],
              [-81.269597495993352, 35.91123569830021],
              [-81.269649276988829, 35.910970994594948],
              [-81.269317893020627, 35.910929633340665],
              [-81.269369674711001, 35.910664928823273],
              [-81.26903829324965, 35.910623566784444],
              [-81.269090075685568, 35.910358863256917],
              [-81.268758694490288, 35.910317499573651],
              [-81.268965825052661, 35.909258684631851],
              [-81.269297200901747, 35.909300047789301],
              [-81.269348981615082, 35.90903534312222],
              [-81.269680355636368, 35.909076705263175],
              [-81.269732134975982, 35.908812001312803],
              [-81.270063509359261, 35.908853361494941],
              [-81.270218841029717, 35.908059248067694],
              [-81.270550211491397, 35.908100607861208],
              [-81.270601987328774, 35.907835902545884],
              [-81.269939247781764, 35.907753183253227],
              [-81.269991024291315, 35.907488478184028],
              [-81.269659655545496, 35.907447117784336],
              [-81.269711433882819, 35.907182412783563],
              [-81.269380067618144, 35.907141050698293],
              [-81.269483624311434, 35.906611641748547],
              [-81.269152259397742, 35.906570279056574],
              [-81.269255817960129, 35.90604087016893],
              [-81.268924455504973, 35.905999506849682],
              [-81.2689762353492, 35.9057348029216],
              [-81.268644875400554, 35.905693438817785],
              [-81.268696655939564, 35.90542873407761],
              [-81.268365297389877, 35.905387369210025],
              [-81.268417077541429, 35.905122664579359],
              [-81.268085720416096, 35.90508129984898],
              [-81.268137502395291, 35.904816595286825],
              [-81.26780614664348, 35.904775228891502],
              [-81.267857929342725, 35.904510524418264],
              [-81.267526574989873, 35.904469157259129],
              [-81.267578358409168, 35.9042044528749],
              [-81.267247005455232, 35.904163084951918],
              [-81.267298789594676, 35.903898380656727],
              [-81.266967439147194, 35.90385701194937],
              [-81.26707100855181, 35.903327604390604],
              [-81.266739659455155, 35.903286235076571],
              [-81.266895014131364, 35.902492121538984],
              [-81.267226358942139, 35.902533490463647],
              [-81.267329924927083, 35.90200408152824],
              [-81.267661267958445, 35.902045449279356],
              [-81.267764830466859, 35.901516039880526],
              [-81.268096171718696, 35.901557406457798],
              [-81.268458619018034, 35.899704469368238],
              [-81.268665723955976, 35.898645646362716],
              [-81.268997053894097, 35.898687010536861],
              [-81.269152375289806, 35.897892891934298],
              [-81.269483701281828, 35.897934254818807],
              [-81.269639016445439, 35.897140135379288],
              [-81.269970339598743, 35.897181496953571],
              [-81.270229185366532, 35.895857962335896],
              [-81.269897867509698, 35.895816601444608],
              [-81.269949636478032, 35.895551894339327],
              [-81.269618321127126, 35.89551053266382],
              [-81.269670090815424, 35.895245825647741],
              [-81.269338775755671, 35.895204463229028],
              [-81.269442317340236, 35.894675049307459],
              [-81.269111004738306, 35.894633686261621],
              [-81.26916277552715, 35.894368979376644],
              [-81.268831465431276, 35.894327615546501],
              [-81.268935008109466, 35.893798201907735],
              [-81.26860370047126, 35.893756837450532],
              [-81.268810787074742, 35.892698009229562],
              [-81.269142090476151, 35.892739373140287],
              [-81.269349167413935, 35.891680543615443],
              [-81.269017868249236, 35.891639180251154],
              [-81.269121406234333, 35.891109765037847],
              [-81.268790110634569, 35.891068401025976],
              [-81.268841880189996, 35.890803693935567],
              [-81.268179291019393, 35.890720963504727],
              [-81.268231062328454, 35.890456255739238],
              [-81.267899768757516, 35.890414889320347],
              [-81.267951541919047, 35.890150182524579],
              [-81.267620249746628, 35.89010881534206],
              [-81.267672023602557, 35.889844107734653],
              [-81.268003313608602, 35.889885474801133],
              [-81.268469245836982, 35.887503105824983],
              [-81.26813796536257, 35.887461739988204],
              [-81.268914471132291, 35.883491112319881],
              [-81.268251945185568, 35.883408382003815],
              [-81.268303711570326, 35.883143673510119],
              [-81.267309929453418, 35.883019570772788],
              [-81.267361698675685, 35.882754862641853],
              [-81.266699181439122, 35.882672122948797],
              [-81.266750952439935, 35.882407415044042],
              [-81.266088438678352, 35.882324672023564],
              [-81.266140211457682, 35.882059964345096],
              [-81.265808956685632, 35.882018591160353],
              [-81.265860730184556, 35.881753883571463],
              [-81.265529476835951, 35.881712510524366],
              [-81.265581251029175, 35.881447802123866],
              [-81.265249999078719, 35.881406428313284],
              [-81.265301775098834, 35.881141719981862],
              [-81.264970523439302, 35.881100345428379],
              [-81.265022300178885, 35.880835637186458],
              [-81.262703562017222, 35.880545987336724],
              [-81.262755345854472, 35.880281280905614],
              [-81.262424100109214, 35.880239898502666],
              [-81.262475884666017, 35.879975192161261],
              [-81.262144641426019, 35.879933808974229],
              [-81.262248211588684, 35.879404394621382],
              [-81.261916969723671, 35.879363011729339],
              [-81.262020540646503, 35.87883359746165],
              [-81.261689302345673, 35.878792213922353],
              [-81.261792874028643, 35.878262799739858],
              [-81.261461637052165, 35.878221414693321],
              [-81.26151342344339, 35.877956707668261],
              [-81.26018848651276, 35.877791159867321],
              [-81.259857253399119, 35.877749770436438],
              [-81.259805460607041, 35.878014476798477],
              [-81.258149293728565, 35.877807515479383],
              [-81.258097495303474, 35.878072221110706],
              [-81.257766262108817, 35.878030826005755],
              [-81.257714462285477, 35.878295531453205],
              [-81.257383228371523, 35.878254135311394],
              [-81.257331427150106, 35.878518840575119],
              [-81.256668957115764, 35.878436045338219],
              [-81.256617153412265, 35.878700749380208],
              [-81.255954682618409, 35.878617950269351],
              [-81.256006489521255, 35.878353245579333],
              [-81.255344022202721, 35.878270443141545],
              [-81.255395829776418, 35.878005738698462],
              [-81.25473336704033, 35.877922932913435],
              [-81.254785176391934, 35.877658228696767],
              [-81.254453946591909, 35.877616824590909],
              [-81.254505757769778, 35.877352120443554],
              [-81.254174529367916, 35.877310715574311],
              [-81.254226340157885, 35.877046011537182],
              [-81.253895113128976, 35.877004605003606],
              [-81.253998736940005, 35.876475197942327],
              [-81.253336289267637, 35.87639238360277],
              [-81.253388102215041, 35.876127679834816],
              [-81.251400773698563, 35.875879216035422],
              [-81.251452593765308, 35.8756145130208],
              [-81.250458936700596, 35.875490268940482],
              [-81.250510759603685, 35.875225566289252],
              [-81.248854679172013, 35.87501847575799],
              [-81.248958334545051, 35.874489071682852],
              [-81.248627121806024, 35.874447650785633],
              [-81.248730779044905, 35.873918246775766],
              [-81.248399568762579, 35.873876825252196],
              [-81.248451398484804, 35.873612123303374],
              [-81.248120189600613, 35.873570701016625],
              [-81.248172018934568, 35.873305999178157],
              [-81.247509604302081, 35.873223152178227],
              [-81.247561436520556, 35.872958450546037],
              [-81.246899026470771, 35.872875600199464],
              [-81.246950859359544, 35.872610898814436],
              [-81.245957249453909, 35.87248661696529],
              [-81.246009086260543, 35.872221915022308],
              [-81.244021883078034, 35.871973327825096],
              [-81.244073727002927, 35.871708626635979],
              [-81.243411330776482, 35.871625757310824],
              [-81.243463175396499, 35.871361057270086],
              [-81.242800783727688, 35.871278183697235],
              [-81.242852630124872, 35.871013483883331],
              [-81.242521435845944, 35.870972045433348],
              [-81.242573284068925, 35.87070734568897],
              [-81.242242091213143, 35.870665907377003],
              [-81.24229393902273, 35.870401206841841],
              [-81.241962747565054, 35.870359767766793],
              [-81.242014597200424, 35.870095067301357],
              [-81.241352217421124, 35.870012186725226],
              [-81.241404068833532, 35.86974748648656],
              [-81.241072880511823, 35.869706044985513],
              [-81.241124731535791, 35.869441344857258],
              [-81.240793545719256, 35.869399902572816],
              [-81.240845397461882, 35.869135202534494],
              [-81.240514213043426, 35.869093759487022],
              [-81.24056606661172, 35.86882905951844],
              [-81.238910151511377, 35.868621828824445],
              [-81.238962010056596, 35.8683571303946],
              [-81.238630829768468, 35.86831568132034],
              [-81.238682689007177, 35.868050982079446],
              [-81.234708562613548, 35.867553527382114],
              [-81.234760434212092, 35.867288829737745],
              [-81.234429260458128, 35.867247369238584],
              [-81.234533006991185, 35.866717974943725],
              [-81.234201835693511, 35.866676513818618],
              [-81.234461200200556, 35.865353025713794],
              [-81.234130035640902, 35.865311564353405],
              [-81.234233780516902, 35.864782169069777],
              [-81.234564944067159, 35.864823630135874],
              [-81.234616815490725, 35.864558932276701],
              [-81.234285652998679, 35.864517471347504],
              [-81.234389396942717, 35.863988075762585],
              [-81.234058238013858, 35.863946614187199],
              [-81.234213853423753, 35.863152519988859],
              [-81.233882698009211, 35.863111057924655],
              [-81.233934570568294, 35.862846360195505],
              [-81.234265724924668, 35.862887822122815],
              [-81.23436946577516, 35.862358425369479],
              [-81.234700618355092, 35.862399886122986],
              [-81.23501182753408, 35.860811694799494],
              [-81.234680681302848, 35.860770234867879],
              [-81.234732549720619, 35.860505535679785],
              [-81.23440140488718, 35.860464074985344],
              [-81.234505141737273, 35.859934677664391],
              [-81.234174000466822, 35.859893216323819],
              [-81.234225869981245, 35.859628517269527],
              [-81.233894730108744, 35.859587055166195],
              [-81.233998469152183, 35.859057658112832],
              [-81.233336194633793, 35.858974730853511],
              [-81.232342785442071, 35.858850334567798],
              [-81.232290910234212, 35.859115031992431],
              [-81.231297500926914, 35.85899062719767],
              [-81.231245622205037, 35.859255324164529],
              [-81.230583348916753, 35.859172382861757],
              [-81.229921076963194, 35.859089437058749],
              [-81.229972960999092, 35.858824739718564],
              [-81.229310692521111, 35.858741790590535],
              [-81.229362578333223, 35.858477093477788],
              [-81.229031445674451, 35.858435618151653],
              [-81.229083331097726, 35.858170921149473],
              [-81.228752200943774, 35.858129445040404],
              [-81.228804087085123, 35.857864748128478],
              [-81.228472958304209, 35.857823270355588],
              [-81.228524845163619, 35.857558573534014],
              [-81.228193717805425, 35.85751709589956],
              [-81.228297493727354, 35.856987702370269],
              [-81.227966368824966, 35.856946224110054],
              [-81.228070145503139, 35.85641683066811],
              [-81.227739023031702, 35.856375350881201],
              [-81.227790912472287, 35.856110654217247],
              [-81.227459791398672, 35.85606917366762],
              [-81.227511680450377, 35.855804477114191],
              [-81.227180560799439, 35.855762996703042],
              [-81.227232451676016, 35.855498300219836],
              [-81.226901333398061, 35.855456818144901],
              [-81.226953224992613, 35.855192121752054],
              [-81.22662210811248, 35.855150638914445],
              [-81.226674000424978, 35.854885942612015],
              [-81.226342884942667, 35.854844459011829],
              [-81.226394777973113, 35.854579762799837],
              [-81.22606366388861, 35.854538278436912],
              [-81.226323129206378, 35.853214796462098],
              [-81.226654238001629, 35.853256280139604],
              [-81.226706128967621, 35.852991582746924],
              [-81.226375021255109, 35.852950100107499],
              [-81.226478804308016, 35.852420706357485],
              [-81.226147700132998, 35.852379222171187],
              [-81.2261995922198, 35.852114525813974],
              [-81.224875182043291, 35.851948580640446],
              [-81.224927078021352, 35.851683884784968],
              [-81.224595977661551, 35.85164239637443],
              [-81.224647874332632, 35.851377699708358],
              [-81.224316775370554, 35.851336210535223],
              [-81.224368673891078, 35.851071514840534],
              [-81.224037576326722, 35.85103002490488],
              [-81.224089474433427, 35.850765328419783],
              [-81.223758378266865, 35.850723837721517],
              [-81.223862176707442, 35.850194445766846],
              [-81.223531082996217, 35.850152954443146],
              [-81.223582983305363, 35.84988825807249],
              [-81.223251890991833, 35.849846765986243],
              [-81.223355691598883, 35.849317373399629],
              [-81.223024601740732, 35.849275880687941],
              [-81.223076503170219, 35.8490111853529],
              [-81.222745414684951, 35.848969690977604],
              [-81.222797316832171, 35.848704995733165],
              [-81.222466230851339, 35.848663500575128],
              [-81.22251813260948, 35.848398805441541],
              [-81.221524879929547, 35.848274316783282],
              [-81.221576784495952, 35.848009621113434],
              [-81.220252456587971, 35.847843624489919],
              [-81.220304366151751, 35.847578929301889],
              [-81.217986813882817, 35.847288400389559],
              [-81.218038731616645, 35.847023706094809],
              [-81.217045503818312, 35.846899179966208],
              [-81.216993582911584, 35.84716387384951],
              [-81.216000355001043, 35.847039339213161],
              [-81.21605227910527, 35.846774646642444],
              [-81.215721204495708, 35.846733133141335],
              [-81.215773129292714, 35.846468439760294],
              [-81.215110984315828, 35.846385410313573],
              [-81.215162910887869, 35.846120717160325],
              [-81.214831839438489, 35.84607920213594],
              [-81.214883766727979, 35.845814509073385],
              [-81.214552697758307, 35.845772992365482],
              [-81.214604625765247, 35.84550829939365],
              [-81.214273558193341, 35.845466781923285],
              [-81.214325486917588, 35.845202089042239],
              [-81.213994419636506, 35.84516057082962],
              [-81.214046349078274, 35.844895878039225],
              [-81.213715284301671, 35.844854359044113],
              [-81.213767214460745, 35.844589666344547],
              [-81.213436151081694, 35.844548146587101],
              [-81.21338421986502, 35.844812839149469],
              [-81.212059965520339, 35.844646750575443],
              [-81.212008029733482, 35.844911442542653],
              [-81.210352714168806, 35.844703810899375],
              [-81.210404656349866, 35.844439119598157],
              [-81.210073595314995, 35.844397590708098],
              [-81.210125538188692, 35.844132898596555],
              [-81.209132360297886, 35.844008306940907],
              [-81.209184306003792, 35.843743615194676],
              [-81.208191135454001, 35.843619015834953],
              [-81.208243083992031, 35.843354324454005],
              [-81.207580974477168, 35.843271253984405],
              [-81.207632924789834, 35.843006562831441],
              [-81.206970819857531, 35.842923489018247],
              [-81.207022771944793, 35.842658798093439],
              [-81.206691721046454, 35.842617259974851],
              [-81.206743673850866, 35.842352569140772],
              [-81.206081574058487, 35.842269489598557],
              [-81.204426333311588, 35.84206177540235],
              [-81.204478293177473, 35.84179708548276],
              [-81.203485155965581, 35.841672445862237],
              [-81.203537119794419, 35.841407757189138],
              [-81.202543988792911, 35.841283108984037],
              [-81.202595955453546, 35.841018420676484],
              [-81.202264914631087, 35.840976869593057],
              [-81.202420815718355, 35.840182803902785],
              [-81.202089777301552, 35.840141252351856],
              [-81.202245678473091, 35.839347185754868],
              [-81.201914644675199, 35.839305633696476],
              [-81.202018579186074, 35.838776255407225],
              [-81.201687546760965, 35.838734703645024],
              [-81.201843449146423, 35.837940636275377],
              [-81.201512421315556, 35.837899083104581],
              [-81.201668323809812, 35.837105015729939],
              [-81.201337300597629, 35.83706346205166],
              [-81.201389268496797, 35.836798773872481],
              [-81.201058245575538, 35.836757219452103],
              [-81.201162182393688, 35.836227840526256],
              [-81.200831161927184, 35.836186285481084],
              [-81.201039036863804, 35.835127529405312],
              [-81.200708020966388, 35.835085974010035],
              [-81.200915894661804, 35.834027216842038],
              [-81.200584884439934, 35.833985661076689],
              [-81.200896689419579, 35.832397523439539],
              [-81.200565685880733, 35.832355967598765],
              [-81.200669620265614, 35.831826588291214],
              [-81.200338620287724, 35.831785031805786],
              [-81.200390588014514, 35.831520341769441],
              [-81.200059588327548, 35.831478784541964],
              [-81.200163525980855, 35.830949404585517],
              [-81.199832528748331, 35.830907846733275],
              [-81.199936466072458, 35.830378467787739],
              [-81.199605472376362, 35.830336908389889],
              [-81.199657441597253, 35.830072219435777],
              [-81.199326449298511, 35.83003065927592],
              [-81.199378419235998, 35.829765970412986],
              [-81.19904742833468, 35.829724409491085],
              [-81.199099400070878, 35.829459719798244],
              [-81.198768410591427, 35.829418159015567],
              [-81.198820381937864, 35.829153469433891],
              [-81.198158406113762, 35.829070345445295],
              [-81.198210380340285, 35.82880565607212],
              [-81.197879393971306, 35.828764091965127],
              [-81.197931367807939, 35.828499402703137],
              [-81.197269399360337, 35.828416273848276],
              [-81.197321376077156, 35.828151584794917],
              [-81.19665940997389, 35.828068451736392],
              [-81.196711388464266, 35.827803762911685],
              [-81.196049426942864, 35.827720626510832],
              [-81.196153386023781, 35.827191249292412],
              [-81.195822408428498, 35.827149679557294],
              [-81.195926369368152, 35.826620302409239],
              [-81.195595393120698, 35.826578732069528],
              [-81.195803314012295, 35.825519976888529],
              [-81.195472344545649, 35.825478406159206],
              [-81.195576304521936, 35.824949029027501],
              [-81.195245336402991, 35.824907457693641],
              [-81.195297317490642, 35.824642769186106],
              [-81.194966350768837, 35.824601197090409],
              [-81.195018332548585, 35.824336507773097],
              [-81.194025438726953, 35.824211787383852],
              [-81.194129407630754, 35.823682410336488],
              [-81.194460370160911, 35.823723984717773],
              [-81.194512353033176, 35.823459295537035],
              [-81.194181391584308, 35.823417722194314],
              [-81.194233375173027, 35.823153033104902],
              [-81.193902416203329, 35.823111458079424],
              [-81.193954400532817, 35.822846769982498],
              [-81.193623442960401, 35.822805194195112],
              [-81.193675427981944, 35.822540505288536],
              [-81.193344471831054, 35.822498929640467],
              [-81.193396457568909, 35.822234240825175],
              [-81.192734548352959, 35.822151085304043],
              [-81.19278653586403, 35.821886396717652],
              [-81.19245558284787, 35.821844818646795],
              [-81.192507571075311, 35.821580130151709],
              [-81.192176619431862, 35.821538550417991],
              [-81.192228608375643, 35.821273862014394],
              [-81.191897659235778, 35.821232281498972],
              [-81.192105616940069, 35.82017352795431],
              [-81.192209593196651, 35.819644150916055],
              [-81.191878650737479, 35.819602570326005],
              [-81.192034615739374, 35.818808504836213],
              [-81.191703676790723, 35.818766923759263],
              [-81.191807653869986, 35.818237546811133],
              [-81.191476717374968, 35.818195965109894],
              [-81.191632684692991, 35.817401898834547],
              [-81.191301751708295, 35.817360316646386],
              [-81.191769643635752, 35.814978114796006],
              [-81.19143872160717, 35.814936532925699],
              [-81.191490708697131, 35.81467184334322],
              [-81.191159788065491, 35.814630260711333],
              [-81.191263763361803, 35.814100882584761],
              [-81.190932845183639, 35.814059299328576],
              [-81.190984833365576, 35.813794609883402],
              [-81.190653916584324, 35.813753025865637],
              [-81.190705905482332, 35.813488336512066],
              [-81.19037499120428, 35.813446751712689],
              [-81.190478970092386, 35.812917373142945],
              [-81.190148057161437, 35.81287578773938],
              [-81.190200048257736, 35.812611098503233],
              [-81.189869136723672, 35.812569512338094],
              [-81.189921127429685, 35.812304823213488],
              [-81.189590218374377, 35.812263235365684],
              [-81.189642209796673, 35.811998546332788],
              [-81.189311302162636, 35.8119569586244],
              [-81.189415287205037, 35.811427580676167],
              [-81.189084380918032, 35.811385992363675],
              [-81.189136374538279, 35.81112130344836],
              [-81.18880546962373, 35.811079713473084],
              [-81.188909456849672, 35.810550335799938],
              [-81.188578555518973, 35.810508746081801],
              [-81.188630550206625, 35.810244056403022],
              [-81.188299650272825, 35.810202465923318],
              [-81.188455633298545, 35.809408398866672],
              [-81.18812473798063, 35.809366807880487],
              [-81.188228727435856, 35.808837429581871],
              [-81.187897833464945, 35.808795837991475],
              [-81.188001823671357, 35.808266459784868],
              [-81.187670933235367, 35.808224866649411],
              [-81.187722928343462, 35.807960178075518],
              [-81.187392039304214, 35.807918584178537],
              [-81.187496031693783, 35.807389206247471],
              [-81.1871651451319, 35.807347612627588],
              [-81.187269138248183, 35.806818233887562],
              [-81.187425125947144, 35.806024166325898],
              [-81.187094244982873, 35.80598257159339],
              [-81.187198236832216, 35.805453193572539],
              [-81.18686735832101, 35.805411598215954],
              [-81.186971352003212, 35.804882219366434],
              [-81.186640475969313, 35.804840624286989],
              [-81.186848462367834, 35.803781866609889],
              [-81.187179333070532, 35.803823461159297],
              [-81.187491294436143, 35.802235321578507],
              [-81.187822160272404, 35.802276915285233],
              [-81.187926141436662, 35.801747534204239],
              [-81.187595278843332, 35.801705941653751],
              [-81.187751253405764, 35.800911869612627],
              [-81.188082111748784, 35.800953462671643],
              [-81.188290068121603, 35.799894699376203],
              [-81.187959212897155, 35.799853106886999],
              [-81.188167166918959, 35.798794342531508],
              [-81.187836317389753, 35.798752750574643],
              [-81.18794029390574, 35.798223367958187],
              [-81.187609446804657, 35.798181774476205],
              [-81.187713425202176, 35.797652392833953],
              [-81.187382580553603, 35.797610798728144],
              [-81.18743456973273, 35.797346107535567],
              [-81.187103726480544, 35.797304512668475],
              [-81.187155716399801, 35.79703982246901],
              [-81.187207707036094, 35.796775130401954],
              [-81.187538547070076, 35.796816725013976],
              [-81.187590536334284, 35.796552033665165],
              [-81.187921376758695, 35.796593627220972],
              [-81.188025349917666, 35.796064243250441],
              [-81.188356188570182, 35.79610583563241],
              [-81.188512142600729, 35.795311759373796],
              [-81.188842977319325, 35.795353350464303],
              [-81.18889496026776, 35.795088658450041],
              [-81.189225795352272, 35.795130247583316],
              [-81.189277776904405, 35.794865555386131],
              [-81.189939444900446, 35.794948731602013],
              [-81.189991424000311, 35.794684039084501],
              [-81.190653091245238, 35.794767211430084],
              [-81.190705066762263, 35.79450251771091],
              [-81.191035900404259, 35.794544102838564],
              [-81.191087874525152, 35.794279408936575],
              [-81.191418707426919, 35.794320992126792],
              [-81.19147068017601, 35.794056298943048],
              [-81.192132343806534, 35.794139463273027],
              [-81.192184314078901, 35.793874768867809],
              [-81.192515144807558, 35.793916350007628],
              [-81.193507641173412, 35.7940410852909],
              [-81.193559606905964, 35.793776391191592],
              [-81.194221269263863, 35.793859543304571],
              [-81.194273232544219, 35.793594848884908],
              [-81.194934895256623, 35.793677997107721],
              [-81.194882934088213, 35.793942691802229],
              [-81.195213767563601, 35.793984264692781],
              [-81.195161807135577, 35.794248960380465],
              [-81.19549264200711, 35.794290532509656],
              [-81.195440682270572, 35.794555227388202],
              [-81.195771517456677, 35.794596799677031],
              [-81.195667600181537, 35.795126189552839],
              [-81.195998438901469, 35.7951677602966],
              [-81.195946480281208, 35.795432456214876],
              [-81.196939001649852, 35.795557163465197],
              [-81.196990955995773, 35.795292467154617],
              [-81.19864515979792, 35.795500293907374],
              [-81.198697108523319, 35.795235596864359],
              [-81.199358789206272, 35.795318721018496],
              [-81.199410735479418, 35.795054023655197],
              [-81.199741575827872, 35.795095584236577],
              [-81.199845465266421, 35.794566190000197],
              [-81.200176303842696, 35.79460774940793],
              [-81.200228246983599, 35.794343051633732],
              [-81.200559083737758, 35.794384610025197],
              [-81.200611025482573, 35.794119912068204],
              [-81.200941862626678, 35.794161469403377],
              [-81.200993801869387, 35.793896771283606],
              [-81.201324638297393, 35.793938327582602],
              [-81.201480453053989, 35.793144233400042],
              [-81.201811285523164, 35.793185787506914],
              [-81.201915157855197, 35.792656391190647],
              [-81.202245989658238, 35.792697944103928],
              [-81.202297923692996, 35.792433245299861],
              [-81.202628753698448, 35.792474798098048],
              [-81.202680687443248, 35.792210099091214],
              [-81.203011516732573, 35.792251650853196],
              [-81.203063447975381, 35.791986951683725],
              [-81.203725106543175, 35.792070051315264],
              [-81.203777035333957, 35.791805351825673],
              [-81.204769520895454, 35.791929993699036],
              [-81.204821446178229, 35.791665293752125],
              [-81.205813932736461, 35.791789927103814],
              [-81.205762010621854, 35.792054627462754],
              [-81.208739499209514, 35.792428478393418],
              [-81.208687587365816, 35.792693179922999],
              [-81.209680093575685, 35.792817780818538],
              [-81.209628184560501, 35.793082482714645],
              [-81.210289858809617, 35.793165545765262],
              [-81.210237951566924, 35.793430247890711],
              [-81.210899630393328, 35.793513307600257],
              [-81.210847724923113, 35.793778009954927],
              [-81.211178565902102, 35.793819538598704],
              [-81.211126661123657, 35.794084240144137],
              [-81.211457503498551, 35.794125768026319],
              [-81.21140560056719, 35.794390470544727],
              [-81.212067288448637, 35.794473523887014],
              [-81.21201538615918, 35.794738225753605],
              [-81.21234623166562, 35.794779751213625],
              [-81.212294331223319, 35.795044454053233],
              [-81.212625178125705, 35.795085978751636],
              [-81.212573277269144, 35.795350680802038],
              [-81.213234975311551, 35.795433727756574],
              [-81.21318307733371, 35.795698430016046],
              [-81.214175629536157, 35.795822993226658],
              [-81.214123734411942, 35.796087696753752],
              [-81.214454586496586, 35.796129215569323],
              [-81.21478544005177, 35.796170734366989],
              [-81.214837333063443, 35.795906030565398],
              [-81.215168185902002, 35.795947548326865],
              [-81.215220077517827, 35.795682844342736],
              [-81.215550928533773, 35.795724361088155],
              [-81.215602818753709, 35.795459656921381],
              [-81.215933670134461, 35.795501171709319],
              [-81.215985558983192, 35.795236468261081],
              [-81.216316408541317, 35.795277982033042],
              [-81.216420183082704, 35.794748573824819],
              [-81.216751030868053, 35.794790086423362],
              [-81.216802916560709, 35.794525381663377],
              [-81.217133763654161, 35.794566894126902],
              [-81.217185646844953, 35.794302189204522],
              [-81.217516494303169, 35.794343699710652],
              [-81.217568376098129, 35.794078994605613],
              [-81.217899221758429, 35.794120504996847],
              [-81.217951103263601, 35.793855799689211],
              [-81.218281948182593, 35.793897308143173],
              [-81.21833382718593, 35.793632602673135],
              [-81.218664672494356, 35.793674110070825],
              [-81.218716550101917, 35.793409404418142],
              [-81.219047393612414, 35.79345091170098],
              [-81.219099269824198, 35.793186205865837],
              [-81.219430112593344, 35.793227711211436],
              [-81.219481987409395, 35.792963005193691],
              [-81.22014367185399, 35.79304601291382],
              [-81.220195544218086, 35.792781306576344],
              [-81.220526385893507, 35.792822808950788],
              [-81.220578256861899, 35.792558102430895],
              [-81.22090909782068, 35.792599603769233],
              [-81.220960967393324, 35.792334897066773],
              [-81.221291807635453, 35.792376397369011],
              [-81.22134367581242, 35.792111690484134],
              [-81.221674515337867, 35.792153189750266],
              [-81.221778247454907, 35.791623775590395],
              [-81.222109085207506, 35.791665273683243],
              [-81.222212813829486, 35.791135858166768],
              [-81.222543648703251, 35.791177355106512],
              [-81.222647373854883, 35.790647939134658],
              [-81.222978208061903, 35.790689434880953],
              [-81.223185648961149, 35.789630601865845],
              [-81.223237509154444, 35.789365893033242],
              [-81.223568337314163, 35.789407387214915],
              [-81.223672053488997, 35.788877970061172],
              [-81.22400287987594, 35.788919463069625],
              [-81.224054735832709, 35.788654753847204],
              [-81.22438556150297, 35.788696245819629],
              [-81.22443741606422, 35.788431536414919],
              [-81.225429891943122, 35.788556006527422],
              [-81.225533593710807, 35.788026586759365],
              [-81.225864417570349, 35.788068074724791],
              [-81.22596811589311, 35.787538655402862],
              [-81.226298936849176, 35.787580141314152],
              [-81.226506324042333, 35.786521299797585],
              [-81.226837142244108, 35.786562785142351],
              [-81.22694083113953, 35.786033363848937],
              [-81.227271646462597, 35.786074848040755],
              [-81.227323489873399, 35.785810136278023],
              [-81.227654304479728, 35.785851619433849],
              [-81.227706146519964, 35.785586908389938],
              [-81.228036960409653, 35.785628390509785],
              [-81.228088799923697, 35.785363678402639],
              [-81.228419614202465, 35.785405159466272],
              [-81.228523290150392, 35.784875736644658],
              [-81.228854102631544, 35.784917215634131],
              [-81.228957775085419, 35.78438779145673],
              [-81.229288584712705, 35.784429270194366],
              [-81.229651420209805, 35.782576282742895],
              [-81.229982222784301, 35.782617759621758],
              [-81.230189544543066, 35.781558907486392],
              [-81.230520342127036, 35.78160038293818],
              [-81.230572170932959, 35.78133566942423],
              [-81.230902968881196, 35.781377142918778],
              [-81.231006622282464, 35.780847716403009],
              [-81.231337417352151, 35.78088918874473],
              [-81.231389243028531, 35.780624474821472],
              [-81.232050833204269, 35.78070741741513],
              [-81.232102655323743, 35.780442703192982],
              [-81.232764244744274, 35.780525641917258],
              [-81.232816064412688, 35.780260927375885],
              [-81.233146858010386, 35.780302394812317],
              [-81.233198677389524, 35.780037680068489],
              [-81.233529470295309, 35.780079147370145],
              [-81.233581287173621, 35.779814432464576],
              [-81.233912079337728, 35.77985589782925],
              [-81.234067524744788, 35.779061751530193],
              [-81.234398314105107, 35.779103216485893],
              [-81.234450127490177, 35.778838500225071],
              [-81.234780916133687, 35.778879964144949],
              [-81.234936351135914, 35.778085816483831],
              [-81.235267136925884, 35.778127278192414],
              [-81.235370755339389, 35.777597845639441],
              [-81.235701539381552, 35.77763930707625],
              [-81.235856960725727, 35.776845157058098],
              [-81.236187741914478, 35.77688661628379],
              [-81.236291352713792, 35.776357182733221],
              [-81.236622132129753, 35.776398640785992],
              [-81.236673935424534, 35.776133924267015],
              [-81.237004714123671, 35.776175381283942],
              [-81.237056515998546, 35.77591066368192],
              [-81.238048848750964, 35.776035028969574],
              [-81.23871040708859, 35.776117934640681],
              [-81.23865861049299, 35.776382652928099],
              [-81.23932017119256, 35.776465555298593],
              [-81.239268376369836, 35.776730273815332],
              [-81.240260724762948, 35.776854620109923],
              [-81.240312515337237, 35.776589902103566],
              [-81.240643297765288, 35.77663134923425],
              [-81.240695088025532, 35.7763666301245],
              [-81.241356650680899, 35.776449521435879],
              [-81.241408438490396, 35.776184802007059],
              [-81.242070000364691, 35.776267688548245],
              [-81.242121784642663, 35.776002969721993],
              [-81.242452565572691, 35.776044411046691],
              [-81.242504348455853, 35.775779692038562],
              [-81.242835128668887, 35.775821132327451],
              [-81.242886910157253, 35.775556413137437],
              [-81.243217689653278, 35.775597852390476],
              [-81.243269469721881, 35.775333132117453],
              [-81.243600247420204, 35.775374571256272],
              [-81.243652026094026, 35.775109850801364],
              [-81.243982804181016, 35.775151288884054],
              [-81.244034581460099, 35.774886568247211],
              [-81.244365357699294, 35.774928004413475],
              [-81.244417133583624, 35.774663283594762],
              [-81.245078686122568, 35.774746154738708],
              [-81.245130459556293, 35.77448143360121],
              [-81.245461234700244, 35.774522866797149],
              [-81.245513006739259, 35.774258145477759],
              [-81.245843781191169, 35.774299578539086],
              [-81.245895551810463, 35.774034856136765],
              [-81.246226325545422, 35.774076288162291],
              [-81.246278094794945, 35.773811566479324],
              [-81.246608867787714, 35.773852996568024],
              [-81.246660634537008, 35.773588274723615],
              [-81.246991406837722, 35.773629704677603],
              [-81.247043173272985, 35.77336498172992],
              [-81.247373944856619, 35.773406410648271],
              [-81.247425708791482, 35.773141687539159],
              [-81.247756479658037, 35.773183115421773],
              [-81.247808243329061, 35.772918393011622],
              [-81.248139013453468, 35.772959818957389],
              [-81.248190774624121, 35.772695096385803],
              [-81.248521544031419, 35.772736521295847],
              [-81.248625063219762, 35.772207074843315],
              [-81.248955830854101, 35.772248498580751],
              [-81.249059346600859, 35.771719052576294],
              [-81.249390112462322, 35.771760475140979],
              [-81.249441867640911, 35.771495751053976],
              [-81.249772632810334, 35.771537173484099],
              [-81.249927895383067, 35.770743001403901],
              [-81.250258657698637, 35.770784421623247],
              [-81.250362161915191, 35.770254973379323],
              [-81.250692922457731, 35.770296392426047],
              [-81.250796423207973, 35.769766943729394],
              [-81.25112718087189, 35.769808361623816],
              [-81.251385920926438, 35.768484736172411],
              [-81.25171667365008, 35.768526152483425],
              [-81.251820165411004, 35.767996701712022],
              [-81.252150916386839, 35.768038117751537],
              [-81.252481667676207, 35.768079531991233],
              [-81.252429924629439, 35.768344257663003],
              [-81.253091430335658, 35.768427083720297],
              [-81.253039689061808, 35.76869180962121],
              [-81.253370444584249, 35.768733221418323],
              [-81.253318704027592, 35.768997947411499],
              [-81.253649459838741, 35.769039358467325],
              [-81.253597719999277, 35.769304084552871],
              [-81.256243791521598, 35.769635340758633],
              [-81.25619206087066, 35.769900066973257],
              [-81.25685358425477, 35.769982872536772],
              [-81.256905313874597, 35.769718145126667],
              [-81.258559123482527, 35.769925140810756],
              [-81.2586108463789, 35.769660412691969],
              [-81.25894160871249, 35.769701809347971],
              [-81.259272371359387, 35.769743204204147],
              [-81.259324091805567, 35.769478475766903],
              [-81.259654852654563, 35.769519870509093],
              [-81.259706571706431, 35.769255141890277],
              [-81.260037332918557, 35.769296534675213],
              [-81.260089049470523, 35.769031805895381],
              [-81.260419809990438, 35.769073198545762],
              [-81.26047152625361, 35.768808469563851],
              [-81.260802284925362, 35.768849860298097],
              [-81.260905713219202, 35.768320401947001],
              [-81.261236471223299, 35.76836179148826],
              [-81.26128818325391, 35.768097062119168],
              [-81.261618940565782, 35.768138451525971],
              [-81.261670651202152, 35.767873721975334],
              [-81.26200140666576, 35.767915109465989],
              [-81.262053117013565, 35.767650379713338],
              [-81.262383871759823, 35.767691766168412],
              [-81.262487288197647, 35.767162305374818],
              [-81.262818041195885, 35.767203691558571],
              [-81.262869747285919, 35.766938960517663],
              [-81.26353125216059, 35.767021729014303],
              [-81.263582955800729, 35.766756997655051],
              [-81.263913707702358, 35.766798380868991],
              [-81.263965409948341, 35.766533649328359],
              [-81.264296162212943, 35.766575030585102],
              [-81.264347861984433, 35.766310299784671],
              [-81.265340115429439, 35.766434437793222],
              [-81.265391811695196, 35.766169706537681],
              [-81.265722562095831, 35.766211083946487],
              [-81.265774258047799, 35.765946351587772],
              [-81.266435757777614, 35.76602910433683],
              [-81.266487451279659, 35.765764371659849],
              [-81.267148950225874, 35.765847119639268],
              [-81.267200640172391, 35.765582386664704],
              [-81.267862138359988, 35.765665130775695],
              [-81.267913825881891, 35.765400398384052],
              [-81.268244574427257, 35.765441768954688],
              [-81.268347946294199, 35.764912301961772],
              [-81.268678693066207, 35.764953671360082],
              [-81.268782061493908, 35.7644242048165],
              [-81.269112806467291, 35.76446557214139],
              [-81.269216170325024, 35.763936105166579],
              [-81.268885428543541, 35.763894737193588],
              [-81.269143839143041, 35.762571067377834],
              [-81.268813101872908, 35.762529699210852],
              [-81.268968148004717, 35.761735497185491],
              [-81.268637415345552, 35.761694128509681],
              [-81.268740778723, 35.761164659663542],
              [-81.269071509270759, 35.761206028065679],
              [-81.269433264720135, 35.759352885165399],
              [-81.269102542667653, 35.759311517700347],
              [-81.269360933897033, 35.757987842790762],
              [-81.269691650671348, 35.758029209571745],
              [-81.269743327279201, 35.757764473951099],
              [-81.270735476464225, 35.757888568491936],
              [-81.270787149567013, 35.757623832416414],
              [-81.271117865582724, 35.757665195329409],
              [-81.271066194641008, 35.757929931521033],
              [-81.271727629772514, 35.758012654023588],
              [-81.271675960603957, 35.758277390444427],
              [-81.273991006063852, 35.758566893123735],
              [-81.27404266673733, 35.758302155766039],
              [-81.274704109059783, 35.758384862072909],
              [-81.274755768389426, 35.758120124376639],
              [-81.275086489567485, 35.75816147648559],
              [-81.275138146397893, 35.757896738628745],
              [-81.275468866832981, 35.757938088801296],
              [-81.275520522269815, 35.757673350763369],
              [-81.275851240907073, 35.757714700822234],
              [-81.275954549760741, 35.757185224319038],
              [-81.276285266624711, 35.757226573205763],
              [-81.276336920041658, 35.756961834740487],
              [-81.276667636162728, 35.757003181690806],
              [-81.277184131186772, 35.754355791616199],
              [-81.277514835984221, 35.754397136321082],
              [-81.277566482466696, 35.754132397115377],
              [-81.277897187652002, 35.754173740764251],
              [-81.27794883274106, 35.753909001377515],
              [-81.278610240934015, 35.753991686627707],
              [-81.278661883548637, 35.753726946022169],
              [-81.279323290982177, 35.753809627404557],
              [-81.279374931147743, 35.753544886481279],
              [-81.280036337821642, 35.753627563995892],
              [-81.280087974432689, 35.753362822775564],
              [-81.281080084325637, 35.753486830535991],
              [-81.281131717457498, 35.753222089762332],
              [-81.281793123262389, 35.753304756843214],
              [-81.281844753919827, 35.753040014850697],
              [-81.282506158964893, 35.753122678063825],
              [-81.282454530418704, 35.753387420329759],
              [-81.283115940031621, 35.753470080201282],
              [-81.283064313258805, 35.753734822696316],
              [-81.283725725203354, 35.753817478366479],
              [-81.283674100203839, 35.754082221090712],
              [-81.284666225517384, 35.754206199587202],
              [-81.284614603321486, 35.754470941776034],
              [-81.285276023997369, 35.754553589887415],
              [-81.285327644056636, 35.754288846524105],
              [-81.285989063972323, 35.754371490767703],
              [-81.286040680476987, 35.754106747107521],
              [-81.286371389885829, 35.754148067744758],
              [-81.286423004997189, 35.753883323903622],
              [-81.286753713688157, 35.75392464350557],
              [-81.287011780031321, 35.752600923833064],
              [-81.287342482676451, 35.752642241873858],
              [-81.287445704286228, 35.752112753069802],
              [-81.287115003777629, 35.752071436203444],
              [-81.287166615131497, 35.751806691871693],
              [-81.287497314609965, 35.75184800950246],
              [-81.28754892457053, 35.751583264989911],
              [-81.287879624411005, 35.751624580663595],
              [-81.287931231872861, 35.751359835990925],
              [-81.288261931020912, 35.75140115153048],
              [-81.288313538194956, 35.751136406656194],
              [-81.288644235494274, 35.751177720280168],
              [-81.288799050718239, 35.750383485002885],
              [-81.289129745213927, 35.750424798219477],
              [-81.289232951169865, 35.749895306646209],
              [-81.289563643892109, 35.749936618690988],
              [-81.289718447692636, 35.74914238103014],
              [-81.29004913758591, 35.749183691766312],
              [-81.290152334837956, 35.748654199225761],
              [-81.290483022932293, 35.74869550788906],
              [-81.290637814229996, 35.747901268767002],
              [-81.290307129302192, 35.747859960513658],
              [-81.290358727501612, 35.747595213573476],
              [-81.290028043941319, 35.747553903657106],
              [-81.290337628010704, 35.745965423794992],
              [-81.290668304132708, 35.746006732912271],
              [-81.29077149325704, 35.745477238412093],
              [-81.291102167605729, 35.745518546357594],
              [-81.291153760606051, 35.74525379890477],
              [-81.291484434211455, 35.745295104914007],
              [-81.291536025818687, 35.745030357280413],
              [-81.292197371956703, 35.745112967231215],
              [-81.292248961115604, 35.744848219280378],
              [-81.2929103064929, 35.744930825363902],
              [-81.292961893203312, 35.744666077095765],
              [-81.29362323781983, 35.744748679312039],
              [-81.29372640487567, 35.744219181216884],
              [-81.294057074486702, 35.744260481175402],
              [-81.294108657018398, 35.743995732365256],
              [-81.29443932699138, 35.744037030366719],
              [-81.2945424867208, 35.7435075323829],
              [-81.294873154920325, 35.743548829212664],
              [-81.294976312268915, 35.743019329857567],
              [-81.295306977589746, 35.743060625536529],
              [-81.295358555267754, 35.742795876096487],
              [-81.29601988591638, 35.742878464464845],
              [-81.295968311479996, 35.743143215058296],
              [-81.297290981047979, 35.743308381559423],
              [-81.297342551288281, 35.743043631320674],
              [-81.298995891115453, 35.743250068537634],
              [-81.299047455715609, 35.742985316670897],
              [-81.299378124079851, 35.743026601273883],
              [-81.299429686207503, 35.742761850148504],
              [-81.299760353853685, 35.742803133716478],
              [-81.299863474960546, 35.74227363015963],
              [-81.299532810530437, 35.742232346843963],
              [-81.299584371620497, 35.741967594685647],
              [-81.299915036100415, 35.742008877843837],
              [-81.29996659582325, 35.741744126406111],
              [-81.302281244767514, 35.742033080681288],
              [-81.302229693537925, 35.742297833054103],
              [-81.303221693848968, 35.742421657410105],
              [-81.303273243017571, 35.742156904606794],
              [-81.303603910126768, 35.742198176843914],
              [-81.303655456797358, 35.741933423881093],
              [-81.303986123188466, 35.74197469508325],
              [-81.30408921448668, 35.7414451878306],
              [-81.304192304435901, 35.740915680402182],
              [-81.304522965837151, 35.740956950180603],
              [-81.304574509790314, 35.740692195802993],
              [-81.30490517049914, 35.740733465447505],
              [-81.304956711954375, 35.740468710910378],
              [-81.305287373050206, 35.740509979498952],
              [-81.305338913112905, 35.740245224781404],
              [-81.305669572359591, 35.740286491454789],
              [-81.305721111029584, 35.740021736556741],
              [-81.30605177068901, 35.740063003075377],
              [-81.306103307966424, 35.739798247996987],
              [-81.306433965776677, 35.739839512600398],
              [-81.306485501661498, 35.739574757341522],
              [-81.306816158779313, 35.739616021811038],
              [-81.30686769327157, 35.739351266371877],
              [-81.307198349645375, 35.739392528905306],
              [-81.307249882745083, 35.739127773285702],
              [-81.307580538400643, 35.739169034784162],
              [-81.307632070107914, 35.738904278984201],
              [-81.307962725045243, 35.738945539447698],
              [-81.308014254254616, 35.738680783488313],
              [-81.308344908499393, 35.738722043817887],
              [-81.308396437395899, 35.738457286756059],
              [-81.308727090922432, 35.738498546050735],
              [-81.308778617321082, 35.738233788829525],
              [-81.309109270129341, 35.738275047089267],
              [-81.309439923249002, 35.738316303549489],
              [-81.309388398935383, 35.738581060142558],
              [-81.309719053447537, 35.738622315840743],
              [-81.309667528746942, 35.738887072547399],
              [-81.312643445154478, 35.739258335507991],
              [-81.312591931826077, 35.73952309335705],
              [-81.315898550166693, 35.739935520021554],
              [-81.315950054045871, 35.739670760787099],
              [-81.317603371115297, 35.739876939282311],
              [-81.317654868274886, 35.73961217934292],
              [-81.318316195566112, 35.739694643994227],
              [-81.318367690277825, 35.739429883738154],
              [-81.319359680009612, 35.73955357312704],
              [-81.319411171217993, 35.739288812417918],
              [-81.319741833703247, 35.739330039987458],
              [-81.319793323519264, 35.739065279098099],
              [-81.320123985285832, 35.739106505632741],
              [-81.320175472604234, 35.738841744584228],
              [-81.320506134757451, 35.738882970062889],
              [-81.32055762068363, 35.738618208834168],
              [-81.320888281012969, 35.73865943329902],
              [-81.320939766651975, 35.738394671868974],
              [-81.3212704262627, 35.738435895298899],
              [-81.321321909404148, 35.73817113370972],
              [-81.32165256829623, 35.738212356104853],
              [-81.321704050045483, 35.737947594335488],
              [-81.322034709324186, 35.737988815674647],
              [-81.322086189655209, 35.737724052823985],
              [-81.323078163055982, 35.737847712005689],
              [-81.323129639883817, 35.737582948702126],
              [-81.323460297293536, 35.737624166195644],
              [-81.32340882152117, 35.737888929635574],
              [-81.323739481428404, 35.737930146345917],
              [-81.32368800634886, 35.73819490897727],
              [-81.32467998907309, 35.738318555069434],
              [-81.324731460960322, 35.738053791127903],
              [-81.325062121109369, 35.738095004265254],
              [-81.325165060683247, 35.737565476900308],
              [-81.325495719058182, 35.737606688866379],
              [-81.325650122161463, 35.736812396641511],
              [-81.325980777706619, 35.736853607300148],
              [-81.326032243301867, 35.73658884270796],
              [-81.326362898128281, 35.736630052331762],
              [-81.32651729085832, 35.7358357587635],
              [-81.326847942855096, 35.735876967079818],
              [-81.327002328257421, 35.73508267182882],
              [-81.327332976319397, 35.735123878858772],
              [-81.327487355525264, 35.734329582805273],
              [-81.327818000757759, 35.734370788527777],
              [-81.327920916408289, 35.733841256433038],
              [-81.328251560971751, 35.733882460963358],
              [-81.328457381827135, 35.732823396647618],
              [-81.328126742590399, 35.732782192641466],
              [-81.328435471198915, 35.731193595203848],
              [-81.328589830932913, 35.730399294994633],
              [-81.328920459566433, 35.730440497794966],
              [-81.328971911442281, 35.730175730527577],
              [-81.329302539357215, 35.73021693229316],
              [-81.329353989841152, 35.729952164845827],
              [-81.329684617037273, 35.729993365576696],
              [-81.329736066129414, 35.729728597949432],
              [-81.330066692606792, 35.729769797645574],
              [-81.330118139201844, 35.72950502985946],
              [-81.331110019671812, 35.729628622205041],
              [-81.331161462764484, 35.729363853966504],
              [-81.334137111718221, 35.72973458038188],
              [-81.334085678123486, 35.729999349847027],
              [-81.335077571787068, 35.730122908927051],
              [-81.335026141021913, 35.73038767875736],
              [-81.335687407240059, 35.730470046657004],
              [-81.335738834789382, 35.730205276575298],
              [-81.336400100268534, 35.730287641509697],
              [-81.336348674829949, 35.730552411863925],
              [-81.337009943739645, 35.730634772575947],
              [-81.336958520075271, 35.730899543159076],
              [-81.337950429129052, 35.731023077897561],
              [-81.337899008294414, 35.731287848845803],
              [-81.338890923542024, 35.731411375906845],
              [-81.338839506642472, 35.731676147199003],
              [-81.340162070342132, 35.731840837892243],
              [-81.34011065730175, 35.732105608784487],
              [-81.342094519801151, 35.73235261777576],
              [-81.342043112783145, 35.732617390342817],
              [-81.343035052831837, 35.732740882645686],
              [-81.343086456683352, 35.732476109670031],
              [-81.344078396592352, 35.732599593478],
              [-81.344129795862088, 35.732334820972625],
              [-81.344791089433329, 35.732417138431828],
              [-81.344842486229751, 35.732152364709378],
              [-81.345834424823764, 35.732275833321715],
              [-81.345885818143728, 35.732011060048237],
              [-81.347539050221442, 35.73221682272559],
              [-81.347590437901559, 35.731952047826603],
              [-81.349574321561931, 35.732198931302129],
              [-81.349625702599042, 35.731934156443792],
              [-81.353262843150432, 35.732386688975076],
              [-81.353314211109421, 35.73212191169609],
              [-81.35496746535614, 35.732327570771425],
              [-81.355018827701485, 35.73206279276836],
              [-81.355349478729664, 35.732103921927738],
              [-81.355298117439887, 35.732368700066878],
              [-81.355959422589308, 35.732450955061083],
              [-81.356290075681116, 35.732492081660936],
              [-81.356341433804175, 35.732227303113575],
              [-81.35667208617653, 35.732268428678928],
              [-81.356723441802856, 35.732003649973215],
              [-81.358046050377212, 35.732168140902658],
              [-81.35799469897303, 35.732432920152554],
              [-81.360309284288547, 35.732720745187457],
              [-81.360565998794314, 35.731396843783443],
              [-81.360235348030599, 35.731355729683308],
              [-81.360338034476825, 35.730826169071648],
              [-81.360668682024297, 35.730867282921153],
              [-81.360771363910686, 35.730337721884752],
              [-81.361102010788102, 35.730378834542229],
              [-81.361153349619329, 35.730114053833411],
              [-81.361483995777093, 35.730155165456338],
              [-81.361535333216992, 35.729890384567966],
              [-81.361865978655089, 35.729931495156357],
              [-81.361917314703504, 35.729666714088353],
              [-81.362578603371119, 35.729748932319069],
              [-81.362629936972709, 35.729484150935605],
              [-81.362960581307306, 35.729525258556421],
              [-81.363011913517539, 35.729260476993417],
              [-81.363342556027234, 35.72930158360117],
              [-81.363393886819807, 35.729036800957537],
              [-81.363724529741333, 35.729077907410421],
              [-81.363775858037442, 35.728813124608664],
              [-81.364106500212955, 35.728854229126],
              [-81.364157828249063, 35.728589447024397],
              [-81.364488468599745, 35.728630550528607],
              [-81.364591120422233, 35.728100985043454],
              [-81.364921760102789, 35.728142087355685],
              [-81.365252399014125, 35.728183188790887],
              [-81.365201074816028, 35.728447971387489],
              [-81.365531716223657, 35.72848907203872],
              [-81.365429070062802, 35.729018638252924],
              [-81.365759712812135, 35.729059738299128],
              [-81.365657067419178, 35.729589304611409],
              [-81.365987713720585, 35.729630404009619],
              [-81.365936391036556, 35.729895187697757],
              [-81.366597685626232, 35.729977383191013],
              [-81.3665463658227, 35.730242167086139],
              [-81.366877015242451, 35.730283264060745],
              [-81.36682569502733, 35.730548047168739],
              [-81.367156345838424, 35.730589143380762],
              [-81.367105027448261, 35.730853926559803],
              [-81.367435679650697, 35.730895022009292],
              [-81.367384361980385, 35.731159805280697],
              [-81.368045669503672, 35.731241993756001],
              [-81.368096985062792, 35.730977210212664],
              [-81.369088945672658, 35.731100484877565],
              [-81.369140257702909, 35.730835699981704],
              [-81.369470910864123, 35.730876789904009],
              [-81.369522221529365, 35.73061200572976],
              [-81.369852873944282, 35.730653093716448],
              [-81.36990418321831, 35.730388309362745],
              [-81.370234834939708, 35.730429397216049],
              [-81.370337449237809, 35.729899827226646],
              [-81.370668099157385, 35.729940913008399],
              [-81.370719404207989, 35.729676128273809],
              [-81.371050053433933, 35.729717213922186],
              [-81.371101358172083, 35.729452428085537],
              [-81.371432005572984, 35.729493512720978],
              [-81.371483308919807, 35.729228726704825],
              [-81.371813956705893, 35.729269810284336],
              [-81.37191655807132, 35.728740237892865],
              [-81.372247204081873, 35.728781320301898],
              [-81.372349802020068, 35.728251748365878],
              [-81.375986902431336, 35.72870358544958],
              [-81.376038188167385, 35.728438797492316],
              [-81.376368835038178, 35.72847986871124],
              [-81.376420120488106, 35.72821508055307],
              [-81.376750765507367, 35.728256149857955],
              [-81.37680204956618, 35.727991361520417],
              [-81.377132693865278, 35.728032429790993],
              [-81.37718397653299, 35.727767641274141],
              [-81.37883720001976, 35.72797296932756],
              [-81.378785923735123, 35.728237758502246],
              [-81.379447218878369, 35.728319882418155],
              [-81.379395944369492, 35.728584671821231],
              [-81.380057242992322, 35.728666793315824],
              [-81.380005970259234, 35.728931582947197],
              [-81.380997922744712, 35.729054757978069],
              [-81.380946653948115, 35.729319547951881],
              [-81.381607960148017, 35.729401661004403],
              [-81.381556691995669, 35.729666450327038],
              [-81.383209969721975, 35.72987171699009],
              [-81.383261231464346, 35.729606926108758],
              [-81.383922543438388, 35.729689025658722],
              [-81.383973802734019, 35.729424234462343],
              [-81.384965768398899, 35.729547377123467],
              [-81.385017024192024, 35.729282585476177],
              [-81.385347679037622, 35.729323631397293],
              [-81.38529642427352, 35.729588422279306],
              [-81.385957738160556, 35.729670510774781],
              [-81.386008990813195, 35.72940571962107],
              [-81.387000959320588, 35.729528844799432],
              [-81.387052208444274, 35.729264052293857],
              [-81.387713520329868, 35.729346130380847],
              [-81.387764767033545, 35.729081338461327],
              [-81.389087390605368, 35.729245483309263],
              [-81.389138631645793, 35.728980690824841],
              [-81.390130600782868, 35.729103789596785],
              [-81.390181838294325, 35.728838995760498],
              [-81.391173807281305, 35.728962086037839],
              [-81.391225040185333, 35.728697291772541],
              [-81.392547665602848, 35.728861399026499],
              [-81.392598895053951, 35.728596604152955],
              [-81.393260207109833, 35.728678652127762],
              [-81.393311433009217, 35.728413856961119],
              [-81.393642089589235, 35.728454879443262],
              [-81.39369331409776, 35.728190084097498],
              [-81.39402396882555, 35.72823110466588],
              [-81.39407519194323, 35.727966309140974],
              [-81.394405845977005, 35.728007329576101],
              [-81.394457067703925, 35.727742533872011],
              [-81.395118375743849, 35.727824570852334],
              [-81.39516959391915, 35.727559774855152],
              [-81.395500247951958, 35.727600792301423],
              [-81.395551464736371, 35.72733599612522],
              [-81.396212769459069, 35.72741802719284],
              [-81.396263984902077, 35.727153230680045],
              [-81.39692528885368, 35.727235257881958],
              [-81.396976501850204, 35.726970461054364],
              [-81.397307153259504, 35.72701147272118],
              [-81.397358363760205, 35.726746675736301],
              [-81.398019665499319, 35.726828697003903],
              [-81.398070873553564, 35.726563899704274],
              [-81.398732174521413, 35.726645917106161],
              [-81.398783380102543, 35.726381118590666],
              [-81.399444680298942, 35.726463132126902],
              [-81.39949588346046, 35.726198334197754],
              [-81.400487833087169, 35.726321346905273],
              [-81.400539032719777, 35.726056547624587],
              [-81.401200332043317, 35.726138551632395],
              [-81.401251528124476, 35.725873752058902],
              [-81.401582177246297, 35.725914753029791],
              [-81.401633373041975, 35.725649953255491],
              [-81.401964021416291, 35.725690952291025],
              [-81.402015214742917, 35.725426153260635],
              [-81.402345862396473, 35.725467151261952],
              [-81.402397054305808, 35.725202351151495],
              [-81.403058348533065, 35.725284345088163],
              [-81.403109537995988, 35.725019544663077],
              [-81.404101477536472, 35.725142527092032],
              [-81.404152664629322, 35.724877727095937],
              [-81.404483310020169, 35.724918719320669],
              [-81.404534494590521, 35.724653918266377],
              [-81.404865140365686, 35.724694909435158],
              [-81.405172234448557, 35.723106102287744],
              [-81.405502873119389, 35.723147091765831],
              [-81.405656412433345, 35.722352685849692],
              [-81.405987048272053, 35.722393674022307],
              [-81.406038225221934, 35.722128872150357],
              [-81.406368860339811, 35.722169859288812],
              [-81.406420037004338, 35.72190505721607],
              [-81.406750671401383, 35.721946043320443],
              [-81.406801846675478, 35.721681241068922],
              [-81.407132480351706, 35.721722226139043],
              [-81.407183653103473, 35.72145742282936],
              [-81.407514286058856, 35.721498406865308],
              [-81.407565458552099, 35.721233604256113],
              [-81.407896090786636, 35.721274587257994],
              [-81.40794726075768, 35.721009783590731],
              [-81.408277892271343, 35.72105076555836],
              [-81.408329060878728, 35.720785962613313],
              [-81.40865969167163, 35.720826943546868],
              [-81.408710858861824, 35.720562139521988],
              [-81.409041488933752, 35.720603119421334],
              [-81.409092654733684, 35.720338315217575],
              [-81.409423284084738, 35.720379294082854],
              [-81.409474448521053, 35.720114490601489],
              [-81.40980507601941, 35.720155467553347],
              [-81.409856239065491, 35.719890663893118],
              [-81.410186866974769, 35.719931640690135],
              [-81.4102380286037, 35.719666835950107],
              [-81.410568655792076, 35.719707811712908],
              [-81.410619814925624, 35.719443006815936],
              [-81.410950441393197, 35.719483981544691],
              [-81.411001600241434, 35.719219176447091],
              [-81.411332224883012, 35.719260150163564],
              [-81.411383382340986, 35.718995344887368],
              [-81.411714006261747, 35.719036317569717],
              [-81.411765162329431, 35.718771512114614],
              [-81.412095785502331, 35.718812482861871],
              [-81.412146940206725, 35.718547678129212],
              [-81.412477562658665, 35.718588647842182],
              [-81.412579867793582, 35.718059036196038],
              [-81.412910488495953, 35.718100005640537],
              [-81.413012790181071, 35.71757039355073],
              [-81.413343409106943, 35.717611361825526],
              [-81.413496855405896, 35.716816942971775],
              [-81.413827471472729, 35.716857909040264],
              [-81.41398091048876, 35.716063489413834],
              [-81.414311523723569, 35.716104454177177],
              [-81.414362668337475, 35.71583964741324],
              [-81.41469328085131, 35.71588061114246],
              [-81.414744424075195, 35.715615804199992],
              [-81.415075034763163, 35.715656766917057],
              [-81.415126176596956, 35.715391959795966],
              [-81.415456787668859, 35.715432921457072],
              [-81.415507928112575, 35.715168114157414],
              [-81.415838537358596, 35.715209074806346],
              [-81.416043090433888, 35.714149843755635],
              [-81.416373695792501, 35.714190802963913],
              [-81.416475967646136, 35.7136611864697],
              [-81.416806572333073, 35.713702144486419],
              [-81.416857706136682, 35.71343733559975],
              [-81.417188310129646, 35.71347829348359],
              [-81.417239442543263, 35.713213484418262],
              [-81.417570044683359, 35.713254440388987],
              [-81.417621176838892, 35.712989632024268],
              [-81.417951778257986, 35.713030586960954],
              [-81.418002907891662, 35.712765777538557],
              [-81.418333508589839, 35.712806731441248],
              [-81.418384636860509, 35.712541922741423],
              [-81.419045838254078, 35.712623827558431],
              [-81.419096964052414, 35.712359017643443],
              [-81.420088764932174, 35.71248186773493],
              [-81.420139886124772, 35.712217057392436],
              [-81.420470485663174, 35.712258005497752],
              [-81.420521606597688, 35.711993195855861],
              [-81.420852205414931, 35.712034142927202],
              [-81.421005561860142, 35.711239710655711],
              [-81.421107797423048, 35.710710088032876],
              [-81.421438391297386, 35.710751033528254],
              [-81.421489506982823, 35.710486222478366],
              [-81.421820100136188, 35.710527166939876],
              [-81.421922329498003, 35.709997544418421],
              [-81.422252920874769, 35.71003848771047],
              [-81.422304033446238, 35.709773676260795],
              [-81.422634624075059, 35.709814617617724],
              [-81.422965213960268, 35.709855558999394],
              [-81.423016324086532, 35.709590747235886],
              [-81.423346914355577, 35.709631687561533],
              [-81.423398023092091, 35.709366875619637],
              [-81.424059201390392, 35.709448752425587],
              [-81.42411030768173, 35.709183940169787],
              [-81.426755028039693, 35.709511411229798],
              [-81.426806124448532, 35.709246597869161],
              [-81.430442639037025, 35.70969677349408],
              [-81.430391554238383, 35.709961588344626],
              [-81.431383341721627, 35.71008434471436],
              [-81.431332260860046, 35.710349159906237],
              [-81.431662858429718, 35.71039007763536],
              [-81.431713939313468, 35.710125261384874],
              [-81.432044536134626, 35.710166177179019],
              [-81.431993456306387, 35.710430993564934],
              [-81.433315852259227, 35.710594648276683],
              [-81.433264777423787, 35.710859465139279],
              [-81.433925978963643, 35.710941287386142],
              [-81.433874905877971, 35.711206103575542],
              [-81.434866714964983, 35.711328830591455],
              [-81.434917784883922, 35.711064013995873],
              [-81.43590959488877, 35.711186731595994],
              [-81.435858528136421, 35.711451548597879],
              [-81.436850343236799, 35.711574259441043],
              [-81.436901406822457, 35.711309442033112],
              [-81.437232011072993, 35.711350342821426],
              [-81.437283073268958, 35.711085525235205],
              [-81.437613676825194, 35.711126425890761],
              [-81.437664737604422, 35.710861607225198],
              [-81.437995340439102, 35.710902506846878],
              [-81.438097457806677, 35.710372870039549],
              [-81.438758661360595, 35.710454666023978],
              [-81.438809716866359, 35.710189846845715],
              [-81.439140317536285, 35.710230743365884],
              [-81.439191371652427, 35.70996592400946],
              [-81.439521971600868, 35.710006819495774],
              [-81.439573024327473, 35.709741999961146],
              [-81.439903624659081, 35.709782894391495],
              [-81.439954675996262, 35.709518074678648],
              [-81.440285274501448, 35.709558968097326],
              [-81.440336324449135, 35.709294148206261],
              [-81.440666922232737, 35.709335040591185],
              [-81.440717970790928, 35.709070220521852],
              [-81.443693357338972, 35.709438205808723],
              [-81.443744394958372, 35.709173384500808],
              [-81.444074995047657, 35.709214266706979],
              [-81.444126031277619, 35.708949445220824],
              [-81.444456629567483, 35.708990327316535],
              [-81.444507664408121, 35.708725505652332],
              [-81.444838261949087, 35.708766385813092],
              [-81.44488929540023, 35.708501563970742],
              [-81.449187083429464, 35.709032923374657],
              [-81.44923810171953, 35.70876809975298],
              [-81.450560505211328, 35.708931562684505],
              [-81.450611518945365, 35.708666738478932],
              [-81.452264526376069, 35.708871046459514],
              [-81.452315534498382, 35.708606221534893],
              [-81.452646135466196, 35.708647079588296],
              [-81.452697142199156, 35.708382254485635],
              [-81.453027742472329, 35.708423112406464],
              [-81.453078746711284, 35.708158287147945],
              [-81.453409347340028, 35.708199143111706],
              [-81.453460350189715, 35.707934317675175],
              [-81.454121548154703, 35.708016027582261],
              [-81.454172549664293, 35.707751201810325],
              [-81.455494946480044, 35.707914610279325],
              [-81.455545942328797, 35.707649783945996],
              [-81.456868340232447, 35.707813176597092],
              [-81.456817348606066, 35.708078003471357],
              [-81.45714794971579, 35.708118850204499],
              [-81.457096959916356, 35.708383677148809],
              [-81.457427562387949, 35.708424522217612],
              [-81.457376572205675, 35.708689349276632],
              [-81.45803778139306, 35.708771037867741],
              [-81.45798679409333, 35.709035865132073],
              [-81.458317400781795, 35.709076707753418],
              [-81.458266413099366, 35.709341535132445],
              [-81.458927629588061, 35.709423217949805],
              [-81.45887664368351, 35.709688045556405],
              [-81.459207253483854, 35.709728885752554],
              [-81.459258237227886, 35.70946405803307],
              [-81.460250065463057, 35.709586572824591],
              [-81.460352025616672, 35.709056916446229],
              [-81.460682633584028, 35.709097752642656],
              [-81.460733612104818, 35.708832924254125],
              [-81.46106421824517, 35.708873759439228],
              [-81.461115195376891, 35.708608930872749],
              [-81.462107014841536, 35.708731430586766],
              [-81.462157988472669, 35.708466601571956],
              [-81.46248859380826, 35.708507431991634],
              [-81.462539566050268, 35.708242602798911],
              [-81.462870171795842, 35.708283433063656],
              [-81.462819200609417, 35.708548262391453],
              [-81.463480414107238, 35.708629920517915],
              [-81.4635313831823, 35.708365090919749],
              [-81.46452320328774, 35.708487570052782],
              [-81.464574167757462, 35.708222740028887],
              [-81.464904774420347, 35.708263563866169],
              [-81.465006700248324, 35.707733903419857],
              [-81.465337305133332, 35.70777472608836],
              [-81.465388266491601, 35.70750989566595],
              [-81.465718869576861, 35.707550718224283],
              [-81.465769829518578, 35.707285886722929],
              [-81.466100432986423, 35.707326708225239],
              [-81.466049474072861, 35.707591538960671],
              [-81.466710682987639, 35.707673178661238],
              [-81.466659725879524, 35.707938010525169],
              [-81.466990332431266, 35.70797882870103],
              [-81.466939376018132, 35.708243659756356],
              [-81.467600591155659, 35.70832529460619],
              [-81.46754963765278, 35.708590126767596],
              [-81.467880247315932, 35.708630942518049],
              [-81.467829293403241, 35.708895773893197],
              [-81.468159904482917, 35.708936589781302],
              [-81.468108951292521, 35.709201421248721],
              [-81.468439563733881, 35.709242235472303],
              [-81.468388612370717, 35.709507067009611],
              [-81.468719226228615, 35.709547881370916],
              [-81.468668274483036, 35.709812713022984],
              [-81.468998889702434, 35.709853525719659],
              [-81.468947939784115, 35.710118357441615],
              [-81.469278556392695, 35.710159169374869],
              [-81.469227606092005, 35.710424001211578],
              [-81.469558225221874, 35.710464813259954],
              [-81.469507275643736, 35.710729645188934],
              [-81.47016851585515, 35.710811265080494],
              [-81.470117569160124, 35.711076097214431],
              [-81.470448190269153, 35.711116905958605],
              [-81.470397244296535, 35.711381738184791],
              [-81.470727867899498, 35.711422546143027],
              [-81.470676921544651, 35.711687378484015],
              [-81.471007546536725, 35.71172818567873],
              [-81.470956602009153, 35.711993018089451],
              [-81.471287228390437, 35.712033824520724],
              [-81.47123628348055, 35.712298657046169],
              [-81.471566911250861, 35.712339462713821],
              [-81.47151596816839, 35.712604295309049],
              [-81.471846597327925, 35.71264510021318],
              [-81.471795654940493, 35.712909931999548],
              [-81.472126285489153, 35.712950736140208],
              [-81.472024401881228, 35.713480401656717],
              [-81.472355034874809, 35.713521205168902],
              [-81.472304093599377, 35.713786037096845],
              [-81.472634727982111, 35.713826839845389],
              [-81.472532846570644, 35.714356504743932],
              [-81.472863484503321, 35.714397306841576],
              [-81.47281254433976, 35.714662138911052],
              [-81.473143182556726, 35.714702940267493],
              [-81.473092243143441, 35.714967773330258],
              [-81.473422883854468, 35.715008573900725],
              [-81.473371945136321, 35.715273406154544],
              [-81.478000960333645, 35.71584451815842],
              [-81.47833160707134, 35.715885304483074],
              [-81.478433449181367, 35.715355635949287],
              [-81.478764094140175, 35.715396421105197],
              [-81.478815014191994, 35.715131586627848],
              [-81.479145658427967, 35.715172370750139],
              [-81.479247494287677, 35.714642700518077],
              [-81.47957813677256, 35.714683484372785],
              [-81.479781800243131, 35.713624141953616],
              [-81.480112438837239, 35.713664924369745],
              [-81.480163352256881, 35.713400088308852],
              [-81.480493990100555, 35.713440868790286],
              [-81.480544902158783, 35.713176033452669],
              [-81.480875538174743, 35.713216812922994],
              [-81.480926449921213, 35.712951976483986],
              [-81.481587720868148, 35.713033533359919],
              [-81.481638629064832, 35.712768696630668],
              [-81.482630535906424, 35.712891024326716],
              [-81.482681440602292, 35.712626187149766],
              [-81.488302286014388, 35.713319217228111],
              [-81.48835317132118, 35.713054377736995],
              [-81.488683810744533, 35.71309513509761],
              [-81.488632927598601, 35.713359974701078],
              [-81.489294210716352, 35.713441488775814],
              [-81.489243328244626, 35.713706328628859],
              [-81.489573971883615, 35.713747083540731],
              [-81.489523090134995, 35.71401192348587],
              [-81.489853735190508, 35.714052678535126],
              [-81.48990461477824, 35.713787838477693],
              [-81.490235259082922, 35.713828591592225],
              [-81.49033701628025, 35.713298911957644],
              [-81.490667658805776, 35.713339663903703],
              [-81.49071853528288, 35.713074823448096],
              [-81.491379819275977, 35.713156326176644],
              [-81.491328943778356, 35.713421166023565],
              [-81.49165958733019, 35.713461916174772],
              [-81.491608713688336, 35.713726756992259],
              [-81.491939357496307, 35.713767505501153],
              [-81.491888484577643, 35.714032346410697],
              [-81.492219130907188, 35.714073095034351],
              [-81.492168258683861, 35.714337935134914],
              [-81.493160201705095, 35.714460175139735],
              [-81.493211071837436, 35.714195333710883],
              [-81.493541718365663, 35.714236077861266],
              [-81.493592587109148, 35.71397123625465],
              [-81.493923232941896, 35.714011980272659],
              [-81.493974099191576, 35.71374713851101],
              [-81.495627333480414, 35.713950840728771],
              [-81.495678193012225, 35.713685998272801],
              [-81.49667013442162, 35.713808208775127],
              [-81.496619278030082, 35.714073050734548],
              [-81.497280576088102, 35.714154520479269],
              [-81.497331430339742, 35.713889677349123],
              [-81.497992727588624, 35.713971142328433],
              [-81.498043579395315, 35.713706298885839],
              [-81.498704875862984, 35.71378776000067],
              [-81.498755725224854, 35.713522916245672],
              [-81.499086372901601, 35.713563645320221],
              [-81.499137220874815, 35.713298801387623],
              [-81.499798515114335, 35.713380256571114],
              [-81.499849360614832, 35.713115411425044],
              [-81.500180007177562, 35.713156137533922],
              [-81.500129162705207, 35.713420981913714],
              [-81.500459810656565, 35.713461707258688],
              [-81.500408966907628, 35.713726551730588],
              [-81.500739616247714, 35.713767276311664],
              [-81.50063793096902, 35.714296965373876],
              [-81.500968582754027, 35.714337689325937],
              [-81.50086689822902, 35.71486737758552],
              [-81.501197552458876, 35.714908100908367],
              [-81.501095868743363, 35.715437790167456],
              [-81.501757183502335, 35.715519233732493],
              [-81.501706342705532, 35.715784078578757],
              [-81.502367660907325, 35.715865518818447],
              [-81.502316822994985, 35.716130363868771],
              [-81.50430079302518, 35.716374663804089],
              [-81.504351624628327, 35.716109818846149],
              [-81.50501294922887, 35.716191243782283],
              [-81.505063778387125, 35.715926398512089],
              [-81.506717088245338, 35.716129945397185],
              [-81.506767911790249, 35.715865099410337],
              [-81.50809056210619, 35.716027920368639],
              [-81.508141379960946, 35.715763072921824],
              [-81.510456024229086, 35.716047973360865],
              [-81.510405213739716, 35.716312820849708],
              [-81.513381220844963, 35.716679055804335],
              [-81.513330419557221, 35.716943905363642],
              [-81.514322431795591, 35.717065967292051],
              [-81.514271634449145, 35.717330817189826],
              [-81.513610290124987, 35.717249442607134],
              [-81.513559490333733, 35.717514292192753],
              [-81.513228818166468, 35.7174736034071],
              [-81.513178015881536, 35.717738452837992],
              [-81.512516669326757, 35.717657072323945],
              [-81.512465865701785, 35.717921921419808],
              [-81.5121351913143, 35.717881229691343],
              [-81.512084386300629, 35.718146078609678],
              [-81.511753711189186, 35.718105385847643],
              [-81.511702904758963, 35.718370233687487],
              [-81.511372228951714, 35.718329540793043],
              [-81.511168992394119, 35.719388932154331],
              [-81.510838312666124, 35.71934823692132],
              [-81.510736689741535, 35.71987793253728],
              [-81.510406008233474, 35.71983723613598],
              [-81.510355195202123, 35.720102083294492],
              [-81.510024514075184, 35.720061385836971],
              [-81.509973698578051, 35.720326233741666],
              [-81.509312334104067, 35.720244835883534],
              [-81.509261516133847, 35.720509682574921],
              [-81.508930833891782, 35.720468982151502],
              [-81.508880014532807, 35.720733828665203],
              [-81.508549330461889, 35.72069312723108],
              [-81.508498510819038, 35.720957973544408],
              [-81.508167826024334, 35.720917271076779],
              [-81.508117003887563, 35.721182117235223],
              [-81.507786318369241, 35.721141413734081],
              [-81.50773549594858, 35.721406259692117],
              [-81.507404809706387, 35.721365555157334],
              [-81.507303159516866, 35.721895245819773],
              [-81.506972471494564, 35.721854540116787],
              [-81.506718336066058, 35.723178766676455],
              [-81.50704903047513, 35.723219473030404],
              [-81.506998203232598, 35.723484318532968],
              [-81.506667507767133, 35.723443612044363],
              [-81.50651502195204, 35.724238146966307],
              [-81.506184323650004, 35.724197439174588],
              [-81.506133492936399, 35.724462283334731],
              [-81.50580279391059, 35.724421574509506],
              [-81.50575196180796, 35.724686418491913],
              [-81.505421263163385, 35.724645708610332],
              [-81.505370428594617, 35.724910553338823],
              [-81.505039729198359, 35.72486984152259],
              [-81.50498889324065, 35.725134686073339],
              [-81.504658193120534, 35.725093973223501],
              [-81.504353168358179, 35.726683035120921],
              [-81.504683875893903, 35.726723747855388],
              [-81.504582200065585, 35.727253435387659],
              [-81.504912910046997, 35.727294147492834],
              [-81.504760397489704, 35.728088677509533],
              [-81.505091112078176, 35.728129389097241],
              [-81.505040275318564, 35.728394232477228],
              [-81.505370990191125, 35.728434943323506],
              [-81.505269317786997, 35.728964630223665],
              [-81.505600036210311, 35.729005340417778],
              [-81.505549199460646, 35.729270183960658],
              [-81.505218479980812, 35.729229473631804],
              [-81.505065966748532, 35.730024002651113],
              [-81.504735244431785, 35.72998329101911],
              [-81.50468440531553, 35.730248133196284],
              [-81.504353682274925, 35.730207420530668],
              [-81.504302840692318, 35.73047226345377],
              [-81.503972116927883, 35.730431549754471],
              [-81.50392127503315, 35.73069639157594],
              [-81.503590550544857, 35.730655676842972],
              [-81.503488861461818, 35.731185361033496],
              [-81.50381958916833, 35.731226076013222],
              [-81.503514521350596, 35.732815127433973],
              [-81.503845255757227, 35.732855843224328],
              [-81.50374356665418, 35.733385526339426],
              [-81.503082093975095, 35.733304094221893],
              [-81.503031246286469, 35.733568936357599],
              [-81.502369773930312, 35.733487500352012],
              [-81.502115519790237, 35.734811704529612],
              [-81.501784778289519, 35.734770984966495],
              [-81.501683071085594, 35.735300666088257],
              [-81.500360099901769, 35.735137775965654],
              [-81.500309241021455, 35.735402615933921],
              [-81.498986270936342, 35.735239710888763],
              [-81.498884544801257, 35.735769388693626],
              [-81.498553799907597, 35.735728660388595],
              [-81.49845207032763, 35.736258337750812],
              [-81.498121324730889, 35.736217607353389],
              [-81.498070457846254, 35.736482446296748],
              [-81.497739710420561, 35.736441714888301],
              [-81.497688842146346, 35.736706553653512],
              [-81.497358095102172, 35.736665821188545],
              [-81.49730722543832, 35.736930659775688],
              [-81.496976477670387, 35.736889926277108],
              [-81.496925605511848, 35.737154764708826],
              [-81.495933359930135, 35.737032558437086],
              [-81.495882485373855, 35.737297396398446],
              [-81.495220987502364, 35.737215920793695],
              [-81.495170110499771, 35.737480758442132],
              [-81.494839360454222, 35.737440019167707],
              [-81.49478848203421, 35.737704855737043],
              [-81.494126981961642, 35.7376233751001],
              [-81.494076099990124, 35.737888211379044],
              [-81.493083848614447, 35.737765981936519],
              [-81.493032964245145, 35.738030817745077],
              [-81.49336371469056, 35.738071561941481],
              [-81.493211060572023, 35.738866070457917],
              [-81.493541814520597, 35.738906814159812],
              [-81.493490929827374, 35.739171650087265],
              [-81.493821686270778, 35.739212393002354],
              [-81.493770803406477, 35.739477228998744],
              [-81.493669034468496, 35.740006900906565],
              [-81.493338274882717, 35.73996615848808],
              [-81.493236502471348, 35.740495829051717],
              [-81.492905741105133, 35.740455085464568],
              [-81.492854853329376, 35.740719920095962],
              [-81.49252409123946, 35.740679175474938],
              [-81.492473202073853, 35.740944009928128],
              [-81.491480913443738, 35.7408217675858],
              [-81.491430020802582, 35.741086602492153],
              [-81.491099257903699, 35.74104585310431],
              [-81.490946573678656, 35.741840356280051],
              [-81.490615806837368, 35.741799605611178],
              [-81.490463116444189, 35.742594107991657],
              [-81.490132346765719, 35.742553356019293],
              [-81.490081448622647, 35.742818190217214],
              [-81.489750678220517, 35.742777437210947],
              [-81.489547074718601, 35.743836771294468],
              [-81.489216301500051, 35.743796015926051],
              [-81.488707257143361, 35.746444344975266],
              [-81.488376471479086, 35.74640358740438],
              [-81.488325564035236, 35.746668419667095],
              [-81.48601007198215, 35.746383094944825],
              [-81.486060986823972, 35.746118263626215],
              [-81.485730204958429, 35.746077499741141],
              [-81.485781121629046, 35.74581266849134],
              [-81.484788780041725, 35.74569037006696],
              [-81.484737861333613, 35.745955201790586],
              [-81.484407079668031, 35.745914433430812],
              [-81.484356159542074, 35.746179264074961],
              [-81.484025378258337, 35.746138494658666],
              [-81.483974455637082, 35.746403325146929],
              [-81.483643672552134, 35.746362555620294],
              [-81.483592749645993, 35.746627385907587],
              [-81.482269619644981, 35.746464295499749],
              [-81.482218689967581, 35.74672912524894],
              [-81.48188790751324, 35.746688350033537],
              [-81.481836977551097, 35.74695317958173],
              [-81.481506194400893, 35.746912404233385],
              [-81.481404330229765, 35.747442062050794],
              [-81.481073545271641, 35.747401284632389],
              [-81.48097167768114, 35.74793094290677],
              [-81.480640889837346, 35.747890164342024],
              [-81.480335272640787, 35.74947913460629],
              [-81.480666065720413, 35.749519914003137],
              [-81.480564192768156, 35.750049570678982],
              [-81.480233396469487, 35.75000879103473],
              [-81.480182459002876, 35.750273619611626],
              [-81.479520865291761, 35.750192057356024],
              [-81.479469924244967, 35.750456884740835],
              [-81.479139126832635, 35.750416102129414],
              [-81.47908818442319, 35.750680930236676],
              [-81.478757386287398, 35.750640146591259],
              [-81.478706442459924, 35.750904973618873],
              [-81.478375643600671, 35.750864188939396],
              [-81.478324698382835, 35.751129015788486],
              [-81.477993897722328, 35.751088230998462],
              [-81.477942951114144, 35.751353057668886],
              [-81.478273751753861, 35.751393843517349],
              [-81.478222805869123, 35.751658670279134],
              [-81.478553609004535, 35.751699455340933],
              [-81.478502663843258, 35.751964282193924],
              [-81.478171859650928, 35.751923496997172],
              [-81.47812091309936, 35.752188323671554],
              [-81.478451718348651, 35.752229109003331],
              [-81.478196984740293, 35.753553241516229],
              [-81.477866174206127, 35.753512455509714],
              [-81.477815225653714, 35.753777281922041],
              [-81.477484414396173, 35.753736494881444],
              [-81.477433464453213, 35.754001321115098],
              [-81.477102652472183, 35.753960533040491],
              [-81.477051701138834, 35.7542253590954],
              [-81.476720888434215, 35.754184569986705],
              [-81.476669934604942, 35.754449395885416],
              [-81.476339121176949, 35.754408605742611],
              [-81.476237211481632, 35.754938256215382],
              [-81.475906396273189, 35.7548974649035],
              [-81.475855440407116, 35.755162289477553],
              [-81.472878109000661, 35.754795125092727],
              [-81.472929075457458, 35.754530300809904],
              [-81.472267453150977, 35.754448699281596],
              [-81.472318421387996, 35.754183875225067],
              [-81.471656802529878, 35.754102270370133],
              [-81.471707772547276, 35.753837446539869],
              [-81.471046157109981, 35.753755837457362],
              [-81.47109712890753, 35.753491013853342],
              [-81.470435516918641, 35.75340940144438],
              [-81.47048649162933, 35.75314457894531],
              [-81.469163276988411, 35.752981343855701],
              [-81.469112298050035, 35.753246165814666],
              [-81.468781493351841, 35.753205355126816],
              [-81.468730513022891, 35.753470176907044],
              [-81.468399708706798, 35.753429365162646],
              [-81.468348726987244, 35.753694186764122],
              [-81.467687116109445, 35.753612559429243],
              [-81.467636131969883, 35.753877381617968],
              [-81.467305325408176, 35.753836566027481],
              [-81.467254339878053, 35.754101388037448],
              [-81.466923533698605, 35.754060571390326],
              [-81.466821558390379, 35.754590214130268],
              [-81.466490749325303, 35.754549396336422],
              [-81.46643976068016, 35.754814217945025],
              [-81.464785717315195, 35.754610115669877],
              [-81.464734721918788, 35.754874935680725],
              [-81.464073105005568, 35.754793287841828],
              [-81.46402210718891, 35.755058108439869],
              [-81.463691297623356, 35.755017283047763],
              [-81.46364029838854, 35.755282102565936],
              [-81.463309489205329, 35.755241276117289],
              [-81.463207487647935, 35.755770915653152],
              [-81.462876675579267, 35.755730088057668],
              [-81.462825673229119, 35.755994907174248],
              [-81.462164047979428, 35.755913249015649],
              [-81.462215052415985, 35.755648429268156],
              [-81.461884242454289, 35.755607598952928],
              [-81.461833235855366, 35.755872418587693],
              [-81.46117161368683, 35.755790754110969],
              [-81.461120604612873, 35.756055572530684],
              [-81.460458981693336, 35.755973905088297],
              [-81.460407970171659, 35.756238723194038],
              [-81.460077157603124, 35.756197888000273],
              [-81.460026145795993, 35.756462705904696],
              [-81.459695332504438, 35.756421869676707],
              [-81.459593303563764, 35.756951505128562],
              [-81.459262488492257, 35.756910667731219],
              [-81.459160457207531, 35.757440302715217],
              [-81.458829640328688, 35.757399463247438],
              [-81.458778622575693, 35.757664280549747],
              [-81.458447805001242, 35.757623440948777],
              [-81.458396785857445, 35.7578882580721],
              [-81.458065966454512, 35.757847417459189],
              [-81.457963925023691, 35.758377050403048],
              [-81.457633104946197, 35.758336208598365],
              [-81.457480036032536, 35.759130657752586],
              [-81.457149213118129, 35.759089814643467],
              [-81.45709818877225, 35.759354630197159],
              [-81.456436540726131, 35.759272941033416],
              [-81.456385512826728, 35.75953775629533],
              [-81.456054688800435, 35.759496910218495],
              [-81.456003659510031, 35.759761725301267],
              [-81.455342009212814, 35.759680029300988],
              [-81.455035816065077, 35.761268919912737],
              [-81.454984782322498, 35.761533734417895],
              [-81.454653949736723, 35.761492884584648],
              [-81.454551879135224, 35.762022513192761],
              [-81.45422104476954, 35.761981662190038],
              [-81.454170007910704, 35.762246476293058],
              [-81.453839172794744, 35.762205623354944],
              [-81.453686057069731, 35.763000065895675],
              [-81.453355219116744, 35.762959211652969],
              [-81.45325313816393, 35.763488838922569],
              [-81.453583978231052, 35.763529693435565],
              [-81.453379815438808, 35.764588948009624],
              [-81.452718126076533, 35.764507237025391],
              [-81.452769169113495, 35.764242423723779],
              [-81.452438326570743, 35.764201567907996],
              [-81.452489370330753, 35.763936754697532],
              [-81.452158529151873, 35.763895897216557],
              [-81.452209573634732, 35.763631084097248],
              [-81.451878733847067, 35.763590225852319],
              [-81.45192978015848, 35.763325412801933],
              [-81.451598940683667, 35.763284554716236],
              [-81.451649987717985, 35.763019741757013],
              [-81.450988314041581, 35.762938021311882],
              [-81.451039361750162, 35.762673208601221],
              [-81.450377691524452, 35.762591484829606],
              [-81.450428742118561, 35.762326672323226],
              [-81.450097908563976, 35.762285809223791],
              [-81.450046856912849, 35.76255062159516],
              [-81.449716022635428, 35.762509757461359],
              [-81.449767075343559, 35.762244945225277],
              [-81.449436242457324, 35.762204080327592],
              [-81.449385188719461, 35.762468893329569],
              [-81.449054355110405, 35.762428027397441],
              [-81.449003299954171, 35.76269283931925],
              [-81.448672465622309, 35.76265197235282],
              [-81.448621409074747, 35.762916784095367],
              [-81.447628904886145, 35.762794176494388],
              [-81.447679965710165, 35.76252936513513],
              [-81.447349132715175, 35.762488494572025],
              [-81.447451253263637, 35.761958872036537],
              [-81.44712042271685, 35.761918000844666],
              [-81.447171484652159, 35.761653189623999],
              [-81.446840655496572, 35.761612317668195],
              [-81.44689171704907, 35.761347506560909],
              [-81.446560890363088, 35.761306632917872],
              [-81.446611952638293, 35.761041821901927],
              [-81.446281127370654, 35.76100094839596],
              [-81.44633219036858, 35.760736137471262],
              [-81.446001366464884, 35.760695262300374],
              [-81.446052430185588, 35.760430451466952],
              [-81.445390784443774, 35.760348700522499],
              [-81.445339718609205, 35.760613511085488],
              [-81.44500889573554, 35.760572634118262],
              [-81.444957827404352, 35.760837444524327],
              [-81.444627002675233, 35.760796565643766],
              [-81.444575934085506, 35.761061376749524],
              [-81.443914284649068, 35.760979616899498],
              [-81.443965356485336, 35.760714806943177],
              [-81.443634532759106, 35.760673925365211],
              [-81.443685605318095, 35.760409115500181],
              [-81.443023963220568, 35.760327350774197],
              [-81.443075037559069, 35.760062541135639],
              [-81.442413397807172, 35.759980773105838],
              [-81.442362321354892, 35.760245582473821],
              [-81.441700681932033, 35.760163810554808],
              [-81.441751760498107, 35.759899001457299],
              [-81.440759307028372, 35.75977633679058],
              [-81.440708225291743, 35.760041145482298],
              [-81.440377405965776, 35.760000255048375],
              [-81.440275238297204, 35.760529872952503],
              [-81.439944418297372, 35.760488981326759],
              [-81.439893333443763, 35.760753789615976],
              [-81.439842247150324, 35.761018597883385],
              [-81.440173069263963, 35.761059489779761],
              [-81.439713290403589, 35.763442763358036],
              [-81.439051626380405, 35.763360976253395],
              [-81.439000535685935, 35.7636257839455],
              [-81.438338869808149, 35.763543993896825],
              [-81.438287776638063, 35.763808800373326],
              [-81.437956943130288, 35.763767903414454],
              [-81.43790584859596, 35.764032710612618],
              [-81.437575014365663, 35.763991812619288],
              [-81.437472822179842, 35.764521426612902],
              [-81.437141986170076, 35.76448052744982],
              [-81.436886492274297, 35.765804558327289],
              [-81.437217334674614, 35.765845458144788],
              [-81.437012937306662, 35.766904682062638],
              [-81.437343784269601, 35.766945581522087],
              [-81.437190486462072, 35.767739999617405],
              [-81.436859636327981, 35.767699099752093],
              [-81.43675743361635, 35.768228710731023],
              [-81.436426581702662, 35.768187809695903],
              [-81.436273272507947, 35.768982225416337],
              [-81.436604127592872, 35.769023126857384],
              [-81.43639971414099, 35.770082347755725],
              [-81.436730573788878, 35.770123248838694],
              [-81.43667947006665, 35.770388053198083],
              [-81.437341193585951, 35.770469852914871],
              [-81.437290092776351, 35.770734658379624],
              [-81.437620955542116, 35.770775557035456],
              [-81.437518755006394, 35.77130516720193],
              [-81.437849620220973, 35.771346065229089],
              [-81.437798520494965, 35.771610869930733],
              [-81.438129388206903, 35.771651767171811],
              [-81.437260650942065, 35.77615344731278],
              [-81.436929764151557, 35.77611254779405],
              [-81.436827551215274, 35.77664215558174],
              [-81.436496662644757, 35.776601254893116],
              [-81.436445554064605, 35.776866058596234],
              [-81.436114664798552, 35.776825157774077],
              [-81.436063554799276, 35.777089960396495],
              [-81.435732664810516, 35.777049058539717],
              [-81.435681553419414, 35.777313860982488],
              [-81.435350662707961, 35.77727295809111],
              [-81.435299549924849, 35.777537760354264],
              [-81.434968658490718, 35.777496856428236],
              [-81.434661967396622, 35.779085670989268],
              [-81.43499286630761, 35.779126576606025],
              [-81.434941750103448, 35.779391379481872],
              [-81.43461085124089, 35.779350473707616],
              [-81.434406382111035, 35.780409682357188],
              [-81.434075478247905, 35.780368775164497],
              [-81.4339732389803, 35.780898378062183],
              [-81.433642334470008, 35.780857470578582],
              [-81.433540091748682, 35.781387073027972],
              [-81.433209184325392, 35.781346163495314],
              [-81.432953565852145, 35.782670169076539],
              [-81.432622653476514, 35.782629257967962],
              [-81.432571527272557, 35.782894058644203],
              [-81.432240614174233, 35.782853146500997],
              [-81.432189486578011, 35.783117946997365],
              [-81.431858572757008, 35.783077033819453],
              [-81.431807443768705, 35.78334183413601],
              [-81.430483786469637, 35.783178171010157],
              [-81.430534919687645, 35.782913371234905],
              [-81.429873094622252, 35.782831535007873],
              [-81.429821959289399, 35.783096334512543],
              [-81.429491046754407, 35.783055414903622],
              [-81.429388771863515, 35.783585013530711],
              [-81.429057857521414, 35.783544091850651],
              [-81.429006719069221, 35.7838088909511],
              [-81.42933763444168, 35.783849811865416],
              [-81.429184219142769, 35.784644209327837],
              [-81.428853299465061, 35.784603287128455],
              [-81.428802159673552, 35.784868086050849],
              [-81.429133079275772, 35.784909007506549],
              [-81.428826236441381, 35.786497798280827],
              [-81.428495309388509, 35.786456876035132],
              [-81.428341880817726, 35.787251269953039],
              [-81.428672811070285, 35.787292193505856],
              [-81.42846823730541, 35.788351384149117],
              [-81.42879917212295, 35.788392307343877],
              [-81.428594598336247, 35.789451497792804],
              [-81.428925538797785, 35.78949241970632],
              [-81.428772107561386, 35.790286812491473],
              [-81.429103050424629, 35.790327733933601],
              [-81.429000763743119, 35.790857329163813],
              [-81.429331710162401, 35.790898249955085],
              [-81.429229424229561, 35.791427844376265],
              [-81.429560373098965, 35.79146876453872],
              [-81.429458087968939, 35.791998359952913],
              [-81.430119992048901, 35.792080198098674],
              [-81.430068850543876, 35.792344996020418],
              [-81.430399803590859, 35.792385913890449],
              [-81.43024638239747, 35.793180305947274],
              [-81.429915425071513, 35.793139387693422],
              [-81.429813140126512, 35.793668982211578],
              [-81.429482182126023, 35.793628062765507],
              [-81.429379892620105, 35.794157656856207],
              [-81.429710853841968, 35.79419857655089],
              [-81.429608566217851, 35.79472817071121],
              [-81.429939529890049, 35.794769089777084],
              [-81.429888386066892, 35.79503388648596],
              [-81.430550316531424, 35.795115722189649],
              [-81.430448033217274, 35.795645315992367],
              [-81.430117065729149, 35.795604399220771],
              [-81.430065921958928, 35.795869195930699],
              [-81.429734953720867, 35.795828277223166],
              [-81.429683808558082, 35.796093073753092],
              [-81.429352839597172, 35.796052154010617],
              [-81.429301693041722, 35.79631695036047],
              [-81.428970723357878, 35.796276029583119],
              [-81.428919575409736, 35.796540825752849],
              [-81.428868427126659, 35.796805621877731],
              [-81.429199400031877, 35.796846542903609],
              [-81.429097104576371, 35.797376135289724],
              [-81.429428079932279, 35.797417055686765],
              [-81.42937693275988, 35.797681851947914],
              [-81.431362796035657, 35.797927355393796],
              [-81.431311654875202, 35.798192152422097],
              [-81.432635576120603, 35.798355803605972],
              [-81.432584439962653, 35.798620601108475],
              [-81.43357738757966, 35.798743330231297],
              [-81.433526254260428, 35.79900812809494],
              [-81.434519208064557, 35.799130849527259],
              [-81.434468077556971, 35.799395646850854],
              [-81.435461038654267, 35.799518360570559],
              [-81.435409911012727, 35.799783159156135],
              [-81.436733867647376, 35.799946763901183],
              [-81.436784991084551, 35.799681965675575],
              [-81.437115979738991, 35.799722864257866],
              [-81.437064858493045, 35.799987663497603],
              [-81.437395848540092, 35.800028561315465],
              [-81.437446968728224, 35.799763761940468],
              [-81.438439939943777, 35.799886450447495],
              [-81.438491056623292, 35.799621650621894],
              [-81.43915303663195, 35.799703438499115],
              [-81.438897459305565, 35.801027436750879],
              [-81.439228455668228, 35.801068330455657],
              [-81.43917734046164, 35.801333129561598],
              [-81.439508338189924, 35.801374021600878],
              [-81.439457223733726, 35.801638821698326],
              [-81.439788221748543, 35.801679712995337],
              [-81.439737108015592, 35.801944513183187],
              [-81.441061112078785, 35.802108069827042],
              [-81.441010003321892, 35.802372869587565],
              [-81.442003013057743, 35.802495528029652],
              [-81.441951906061405, 35.802760329074218],
              [-81.442282912112987, 35.802801212634236],
              [-81.442231805839882, 35.803066013769119],
              [-81.442562812205296, 35.803106897487879],
              [-81.442358390128874, 35.804166099371592],
              [-81.442689402167289, 35.804206982709374],
              [-81.442587191223453, 35.804736583201667],
              [-81.442918204606599, 35.804777465932155],
              [-81.442815994440139, 35.805307066515191],
              [-81.442484978968054, 35.805266184415302],
              [-81.442433871771669, 35.805530984515272],
              [-81.442764889435111, 35.805571867629304],
              [-81.44271378296213, 35.805836667819527],
              [-81.444699902265981, 35.806081946618562],
              [-81.444648802913179, 35.806346747552752],
              [-81.44497982503573, 35.806387624639086],
              [-81.445030923330407, 35.806122823569737],
              [-81.445361945808372, 35.806163698697922],
              [-81.44531084857185, 35.806428499902445],
              [-81.44597289449014, 35.806510249576576],
              [-81.445921799035261, 35.806775051006468],
              [-81.447245900171893, 35.806938538274252],
              [-81.447194808587668, 35.807203339298745],
              [-81.448849949750183, 35.807407678325781],
              [-81.449180978339129, 35.807448543985899],
              [-81.449129893902892, 35.807713346655106],
              [-81.449791955252479, 35.807795073722069],
              [-81.449740872598099, 35.808059876616561],
              [-81.450071905386039, 35.808100738924438],
              [-81.450020822349231, 35.808365541931309],
              [-81.450351856529963, 35.808406403474592],
              [-81.450300775322916, 35.808671206549349],
              [-81.450631810896425, 35.808712067327882],
              [-81.450580730413165, 35.808976870492778],
              [-81.451242804680248, 35.809058589620832],
              [-81.45119172597893, 35.809323393010999],
              [-81.4518538037008, 35.809405108810182],
              [-81.451802726781395, 35.809669912425512],
              [-81.453126890472504, 35.809833333766846],
              [-81.453075818557934, 35.810098137855391],
              [-81.45506208266444, 35.810343242738561],
              [-81.455011015659011, 35.810608047614849],
              [-81.455673108837459, 35.810689741706177],
              [-81.455622044720528, 35.810954546785304],
              [-81.45661519073829, 35.811077082018301],
              [-81.456564129434568, 35.811341886556527],
              [-81.457226230218126, 35.811423573091055],
              [-81.457175170696672, 35.811688377854352],
              [-81.457837276013606, 35.811770060136332],
              [-81.457786218301763, 35.812034866025684],
              [-81.458448327073128, 35.812116544978501],
              [-81.458397271116255, 35.812381350191941],
              [-81.459721496905331, 35.812544697839556],
              [-81.459670444875087, 35.812809504449142],
              [-81.460001504309275, 35.8128503387736],
              [-81.460052554202292, 35.812585532952461],
              [-81.460714669744476, 35.8126671986765],
              [-81.460663621995693, 35.812932005668785],
              [-81.461656802125574, 35.813054498789739],
              [-81.461605756084083, 35.813319305263327],
              [-81.461936818582856, 35.813360134323695],
              [-81.461885773292991, 35.813624941788291],
              [-81.464203224263301, 35.813910721927947],
              [-81.464254261037681, 35.813645913540739],
              [-81.465578521523923, 35.813809195632096],
              [-81.46562955265135, 35.813544387583413],
              [-81.466622748903475, 35.813666839287201],
              [-81.466571719845263, 35.813931647763177],
              [-81.46690278735835, 35.813972463325591],
              [-81.466953814251838, 35.81370765473693],
              [-81.467947014487251, 35.813830094741178],
              [-81.467895990769165, 35.814094903734564],
              [-81.468227059619153, 35.814135715697361],
              [-81.468176036625351, 35.814400524780737],
              [-81.468838176338707, 35.814482146298936],
              [-81.468787155127856, 35.81474695560717],
              [-81.469118227097397, 35.81478776514016],
              [-81.469067206610873, 35.815052574538157],
              [-81.469398279973234, 35.815093383306284],
              [-81.469347260210967, 35.815358192794172],
              [-81.469678334966062, 35.815399000797257],
              [-81.469576297662456, 35.815928619885305],
              [-81.469907374868868, 35.815969427258416],
              [-81.469856356221158, 35.816234236880938],
              [-81.470187434792791, 35.816275042587932],
              [-81.470136417975851, 35.816539852277835],
              [-81.470467497967775, 35.816580658120962],
              [-81.470416480768876, 35.816845467923144],
              [-81.471740810470152, 35.817008680988913],
              [-81.472071894270144, 35.817049481543755],
              [-81.472020882030208, 35.817314291975499],
              [-81.472351967250518, 35.817355092666354],
              [-81.471688798039906, 35.820797623086975],
              [-81.472019898435946, 35.820838423708175],
              [-81.471917869578704, 35.821368042878326],
              [-81.472248971319999, 35.821408842891792],
              [-81.472146943243516, 35.821938462150932],
              [-81.472478048570295, 35.821979262412917],
              [-81.47237602124703, 35.822508880859964],
              [-81.472707129025594, 35.822549680491647],
              [-81.472656115368309, 35.822814489793402],
              [-81.472987224512252, 35.822855287758941],
              [-81.472936212713407, 35.823120098029065],
              [-81.473267323250312, 35.823160895229485],
              [-81.473216311042236, 35.823425704710566],
              [-81.47354742297199, 35.82346650114593],
              [-81.473496412594926, 35.823731310694221],
              [-81.473827525945211, 35.823772107265505],
              [-81.473776516292787, 35.824036916903424],
              [-81.474107631008565, 35.824077711808428],
              [-81.474056622080695, 35.824342521536018],
              [-81.474387738189449, 35.824383315675952],
              [-81.474336729986263, 35.824648125493113],
              [-81.476323442249054, 35.824892873097028],
              [-81.476272441171261, 35.825157683655661],
              [-81.476603561736638, 35.825198471765056],
              [-81.476552560277113, 35.825463282435798],
              [-81.476883683314256, 35.825504068856361],
              [-81.476832682579484, 35.825768879616732],
              [-81.477163807037229, 35.825809666173186],
              [-81.477112807027225, 35.826074477023134],
              [-81.477443932877961, 35.826115262814355],
              [-81.477341933973619, 35.826644884647934],
              [-81.477673062276168, 35.826685669808853],
              [-81.477622064488173, 35.826950480770051],
              [-81.47795319418374, 35.826991265165759],
              [-81.477902195986516, 35.827256075337921],
              [-81.478233327075188, 35.82729685896831],
              [-81.478182330736757, 35.827561670108636],
              [-81.478844596006596, 35.827643234037801],
              [-81.478793601451983, 35.827908045402481],
              [-81.47912473566079, 35.827948826602274],
              [-81.479073740724587, 35.828213638078914],
              [-81.479404877405244, 35.828254417589982],
              [-81.479353883194023, 35.8285192291561],
              [-81.480347297366052, 35.828641564517596],
              [-81.48029630710414, 35.828906376420356],
              [-81.480958586629029, 35.828987928804324],
              [-81.480907598123395, 35.829252740030299],
              [-81.481238738906555, 35.829293515468748],
              [-81.481187751125773, 35.829558326784216],
              [-81.482181181948121, 35.829680647134502],
              [-81.482130197010463, 35.829945458809036],
              [-81.482792486897822, 35.830027001200399],
              [-81.482741504878348, 35.830291813977681],
              [-81.483734947037604, 35.830414120733757],
              [-81.483836905522466, 35.829884495203579],
              [-81.484168051449558, 35.829925261752138],
              [-81.484219028592676, 35.829660449245992],
              [-81.485874757970095, 35.829864268713735],
              [-81.485823786122182, 35.830129081893681],
              [-81.486154934053062, 35.830169843041404],
              [-81.486103962957998, 35.830434657211804],
              [-81.486766261939763, 35.830516177076255],
              [-81.486817230944325, 35.830251363537357],
              [-81.488472980053118, 35.830455146749607],
              [-81.488523943400736, 35.830190331590558],
              [-81.489186242820722, 35.830271838314047],
              [-81.48913528156352, 35.830536652841502],
              [-81.490791043464768, 35.830740404525848],
              [-81.490841998292154, 35.830475588446227],
              [-81.491173150172273, 35.830516335956986],
              [-81.491224104713268, 35.83025151967469],
              [-81.491555255868121, 35.830292266150565],
              [-81.491606207909641, 35.830027449710826],
              [-81.4919373583392, 35.830068195151846],
              [-81.491988308987899, 35.829803378532077],
              [-81.492319459798537, 35.829844122915524],
              [-81.492370409054345, 35.829579306115775],
              [-81.495681915937425, 35.829986694770575],
              [-81.495732853189764, 35.829721877502273],
              [-81.496726309014008, 35.82984407568194],
              [-81.496777242727347, 35.829579257063202],
              [-81.497108393898941, 35.829619987561991],
              [-81.497159327326017, 35.829355168740577],
              [-81.497490477800014, 35.829395899105627],
              [-81.497541408727741, 35.829131080126899],
              [-81.497872558448478, 35.829171808556069],
              [-81.497923487983499, 35.828906989397396],
              [-81.501234995190615, 35.829314223688662],
              [-81.501566148628612, 35.829354942058629],
              [-81.501515230744047, 35.829619762698307],
              [-81.502177539632655, 35.82970119702965],
              [-81.502126624639274, 35.829966017870561],
              [-81.502788936980437, 35.830047448870395],
              [-81.50273802377194, 35.830312269935241],
              [-81.503069180920974, 35.830352983329853],
              [-81.503018268437984, 35.830617804484028],
              [-81.504674069019813, 35.830821362140142],
              [-81.504724976206788, 35.830556540312969],
              [-81.505387295975183, 35.830637956277883],
              [-81.505438200738169, 35.830373135037156],
              [-81.505769360602741, 35.830413841072883],
              [-81.505820262838625, 35.830149018773973],
              [-81.506151422005331, 35.830189724675854],
              [-81.506202322848523, 35.829924902197135],
              [-81.506533481289608, 35.829965607064281],
              [-81.506584380740023, 35.829700784405695],
              [-81.506915538427478, 35.82974148733706],
              [-81.5069664364853, 35.829476664498543],
              [-81.507297593474959, 35.829517367296113],
              [-81.507348490140046, 35.829252544277807],
              [-81.507679645269576, 35.829293245162319],
              [-81.507730541648556, 35.829028421941423],
              [-81.508061696080304, 35.829069122692232],
              [-81.508112589960206, 35.828804299314243],
              [-81.50910605312896, 35.828926393937039],
              [-81.509156943497885, 35.828661570110114],
              [-81.510150407596242, 35.828783656204678],
              [-81.510201293347762, 35.828518831951619],
              [-81.510532448014246, 35.828559525073686],
              [-81.510583332373187, 35.828294700640754],
              [-81.511907947951556, 35.82845746453529],
              [-81.511857067829368, 35.828722289506373],
              [-81.51318169295007, 35.828885038634908],
              [-81.51313081673139, 35.829149864098795],
              [-81.513461974350932, 35.829190549276326],
              [-81.513411098886181, 35.829455375730603],
              [-81.5137422590048, 35.829496060119645],
              [-81.513691384237845, 35.829760885762113],
              [-81.514022545749029, 35.829801569385452],
              [-81.513971671708106, 35.830066395117207],
              [-81.514633997848648, 35.830147759932267],
              [-81.51468486977106, 35.829882933931572],
              [-81.515347193991886, 35.829964293998536],
              [-81.515398063462399, 35.829699467683582],
              [-81.515729225580742, 35.829740146671327],
              [-81.515780093658805, 35.829475320176591],
              [-81.516111253944715, 35.829515998152552],
              [-81.516212985876024, 35.828986344781136],
              [-81.516544145455086, 35.829027020663943],
              [-81.516645873934891, 35.828497366842555],
              [-81.517970506087337, 35.828660063021715],
              [-81.517919645697759, 35.828924890504418],
              [-81.519244288525073, 35.829087572795224],
              [-81.519295142464756, 35.828822744820421],
              [-81.519626302924166, 35.828863412332304],
              [-81.519677156577856, 35.828598584154932],
              [-81.520008316311291, 35.828639250632129],
              [-81.520059168572544, 35.828374422275076],
              [-81.520390326501555, 35.828415088641499],
              [-81.520593726815122, 35.827355772444058],
              [-81.520924880812217, 35.827396436471417],
              [-81.520975729289219, 35.827131607619442],
              [-81.521306882560225, 35.827172270612131],
              [-81.521357729644762, 35.826907441580403],
              [-81.52202003620225, 35.826988765474532],
              [-81.522070879700777, 35.826723935250655],
              [-81.523064338290268, 35.826845913497905],
              [-81.52301349796943, 35.827110744125072],
              [-81.523344653286287, 35.827151401559213],
              [-81.52329381258528, 35.827416232298638],
              [-81.525280755651565, 35.827660159752561],
              [-81.525229922079475, 35.82792499123039],
              [-81.526554563144941, 35.82808759143505],
              [-81.526503733448706, 35.828352422504274],
              [-81.526834895606953, 35.828393070439212],
              [-81.526784066665257, 35.828657902498769],
              [-81.529433385471918, 35.828983053721167],
              [-81.529484204860921, 35.828718221510584],
              [-81.530146535475907, 35.828799499374981],
              [-81.53019735241331, 35.828534666850437],
              [-81.530528517175028, 35.828575304748384],
              [-81.530630146797634, 35.82804563841686],
              [-81.532285964950347, 35.828248811257318],
              [-81.53233677450477, 35.827983976890017],
              [-81.532667938086007, 35.828024608983412],
              [-81.532718745141707, 35.827759774459587],
              [-81.533381071155915, 35.827841034775894],
              [-81.533431875788423, 35.827576200839232],
              [-81.53376303822138, 35.82761682906218],
              [-81.533813841433371, 35.82735199404496],
              [-81.534145003168092, 35.827392622134361],
              [-81.534195804988016, 35.827127786937673],
              [-81.534526965996264, 35.827168413992474],
              [-81.536182775945079, 35.827371533059925],
              [-81.536131980452893, 35.827636368161563],
              [-81.536463144517427, 35.827676989949289],
              [-81.536107576495155, 35.82953083889447],
              [-81.536438748279807, 35.829571459821025],
              [-81.536387953378608, 35.829836295707885],
              [-81.536719126555383, 35.829876915868503],
              [-81.536668331246148, 35.830141750966533],
              [-81.536999506949968, 35.830182371239168],
              [-81.536948712367249, 35.830447206426307],
              [-81.537279889435013, 35.830487825031952],
              [-81.537229096713659, 35.830752661186075],
              [-81.537560274067204, 35.830793279048834],
              [-81.5375094820442, 35.83105811439102],
              [-81.537840661896439, 35.831098731464664],
              [-81.537688284824441, 35.831893238570046],
              [-81.538019468187855, 35.831933855146261],
              [-81.537968676952588, 35.832198690576128],
              [-81.538299861708282, 35.832239306386413],
              [-81.538249070121509, 35.832504142829372],
              [-81.538580257375969, 35.832544757850535],
              [-81.538529466487589, 35.832809593481592],
              [-81.538860654027857, 35.832850207759741],
              [-81.538759075584707, 35.83337987910847],
              [-81.539090265576775, 35.833420492754875],
              [-81.538937897488296, 35.834214999864251],
              [-81.538887107828685, 35.834479835469196],
              [-81.539218302391674, 35.834520448752379],
              [-81.538710393576224, 35.837168801040818],
              [-81.538379187310909, 35.83712818643783],
              [-81.538277598095561, 35.837657855750145],
              [-81.538608807586655, 35.837698470598639],
              [-81.538253244464954, 35.839552312205647],
              [-81.538584461706478, 35.839592927093712],
              [-81.538482871816797, 35.840122595202082],
              [-81.538432075819372, 35.840387429199623],
              [-81.538763296572824, 35.840428043590187],
              [-81.538661706805527, 35.840957711694585],
              [-81.538992930011162, 35.840998325453207],
              [-81.538891341031857, 35.841527993644391],
              [-81.539222566689887, 35.841568606771126],
              [-81.539171773313967, 35.841833440921327],
              [-81.53950300036459, 35.841874053281899],
              [-81.539452207715556, 35.842138887520903],
              [-81.540114666041461, 35.842220109786204],
              [-81.540063874072473, 35.84248494427127],
              [-81.541057567761811, 35.842606770406142],
              [-81.541006779745942, 35.842871605225525],
              [-81.541669246717518, 35.842952818099292],
              [-81.541618459381795, 35.8432176531647],
              [-81.542943401586058, 35.843380069544693],
              [-81.542892619235118, 35.843644904177488],
              [-81.543223856685003, 35.84368550638083],
              [-81.543173075089442, 35.843950342003382],
              [-81.543835553078935, 35.844031543075957],
              [-81.543784774376817, 35.844296378898449],
              [-81.544116014916412, 35.844336977767789],
              [-81.544065235834736, 35.844601813702063],
              [-81.544396477766952, 35.844642411805061],
              [-81.544345700519017, 35.844907247804997],
              [-81.544676943843839, 35.84494784514176],
              [-81.544626166216347, 35.845212681253436],
              [-81.545288657119059, 35.845293874371848],
              [-81.54523788235683, 35.845558709782374],
              [-81.545569128827964, 35.845599305587079],
              [-81.545518354792975, 35.84586414108621],
              [-81.545849602656844, 35.84590473612468],
              [-81.545646505272146, 35.846964080051151],
              [-81.545315252062068, 35.84692348449903],
              [-81.545264476391608, 35.847188320671521],
              [-81.54493322245429, 35.847147724084685],
              [-81.544882444284553, 35.847412560100508],
              [-81.54455118962008, 35.847371962478938],
              [-81.544500410057537, 35.84763679831498],
              [-81.54383789963552, 35.847555601002838],
              [-81.543787117591961, 35.847820435623873],
              [-81.54345586183436, 35.847779835933295],
              [-81.543405078398152, 35.848044670374534],
              [-81.54274256573315, 35.84796346712232],
              [-81.542691779844148, 35.848228301249513],
              [-81.542360522965126, 35.848187698588823],
              [-81.54230973568346, 35.848452532536292],
              [-81.541978478048989, 35.848411927939885],
              [-81.541927688267748, 35.848676761730509],
              [-81.541596429934614, 35.848636157000293],
              [-81.541494849467099, 35.849165824130218],
              [-81.541163588211774, 35.849125217353084],
              [-81.540909623704621, 35.850449383754501],
              [-81.540578357510753, 35.850408776306679],
              [-81.540324378246353, 35.851732940017946],
              [-81.539993107057072, 35.851692330097265],
              [-81.539739113034656, 35.853016491117614],
              [-81.540070389524104, 35.85305710170951],
              [-81.539867192763737, 35.854116429875262],
              [-81.540198473798029, 35.854157039202541],
              [-81.540096874987, 35.854686703291115],
              [-81.540046075096186, 35.854951535717355],
              [-81.540377360750568, 35.854992144523699],
              [-81.540326561586909, 35.85525697703838],
              [-81.540657848634339, 35.855297585078425],
              [-81.540607050169541, 35.855562416780572],
              [-81.540938338638341, 35.855603024955258],
              [-81.540836742830251, 35.856132688490838],
              [-81.541830616566514, 35.856254507515345],
              [-81.541779822450366, 35.856519339594129],
              [-81.543104996314625, 35.856681752287521],
              [-81.543054204999393, 35.856946584880511],
              [-81.544048093166168, 35.85706838487291],
              [-81.54399730580532, 35.857333217799578],
              [-81.544991200148516, 35.857455010088742],
              [-81.54494041563548, 35.857719843372315],
              [-81.545271715125736, 35.857760439743281],
              [-81.545220931340026, 35.858025273115267],
              [-81.545552233301805, 35.858065867795631],
              [-81.545501450243464, 35.85833070125593],
              [-81.545832752491478, 35.858371295193002],
              [-81.545781970160519, 35.858636128741729],
              [-81.546775884296125, 35.858757905505065],
              [-81.546725105919833, 35.859022739387484],
              [-81.547056411619749, 35.859063329990136],
              [-81.547719026231647, 35.859144508447265],
              [-81.547668249596455, 35.859409342709512],
              [-81.547617473735301, 35.859674176902736],
              [-81.547948783661042, 35.859714765048544],
              [-81.547745677780554, 35.860774101068344],
              [-81.54807699338717, 35.86081468882702],
              [-81.547924663932776, 35.861609190532],
              [-81.548255981946227, 35.861649777815565],
              [-81.547798986231911, 35.864033280736471],
              [-81.548130315228363, 35.864073868303613],
              [-81.547977980097357, 35.864868367359534],
              [-81.548309312607969, 35.864908954428394],
              [-81.548106198341188, 35.86596828730513],
              [-81.548437535426302, 35.866008874009879],
              [-81.548285199668058, 35.866803372905835],
              [-81.547953859401488, 35.866762785798571],
              [-81.547852297706129, 35.867292451246442],
              [-81.547520954544524, 35.867251862993271],
              [-81.54747017324398, 35.867516695490906],
              [-81.547138829354864, 35.86747610620278],
              [-81.547088045553963, 35.867740938543399],
              [-81.546425356653359, 35.86765975699646],
              [-81.546374570398598, 35.867924589022735],
              [-81.545380537469981, 35.86780280952069],
              [-81.545329746593865, 35.86806764112157],
              [-81.544998401492307, 35.868027045125167],
              [-81.544947610329842, 35.868291876522797],
              [-81.544616264529111, 35.868251280392371],
              [-81.544565471973215, 35.868516111609807],
              [-81.544234125444945, 35.868475514444405],
              [-81.544183330360156, 35.868740344603722],
              [-81.543851983104361, 35.868699746403337],
              [-81.543801187761545, 35.868964577260371],
              [-81.543469839778226, 35.868923978024995],
              [-81.543419041906503, 35.869188807823846],
              [-81.542093649033049, 35.869026400436745],
              [-81.542042846614692, 35.869291230553777],
              [-81.541711498147052, 35.86925062564508],
              [-81.541660694335249, 35.869515455581791],
              [-81.541329345168492, 35.869474850539078],
              [-81.541278539934737, 35.869739679394549],
              [-81.540947190040526, 35.869699073316838],
              [-81.540896382306315, 35.869963902015051],
              [-81.54056503168465, 35.869923294902257],
              [-81.540463415338223, 35.870452952747051],
              [-81.540132061793344, 35.87041234358712],
              [-81.540081252074174, 35.870677172756643],
              [-81.539749898908781, 35.87063656253865],
              [-81.539699086689126, 35.870901391551016],
              [-81.539367732796279, 35.870860780297953],
              [-81.539418544997886, 35.870595952343912],
              [-81.538755840360949, 35.870514728304798],
              [-81.538705024931303, 35.870779556013431],
              [-81.537710966171474, 35.870657712368541],
              [-81.537660148362534, 35.870922540506356],
              [-81.537328794300862, 35.870881923737258],
              [-81.537277973963057, 35.871146750816706],
              [-81.536615266960453, 35.871065515162499],
              [-81.536564444168476, 35.87133034192739],
              [-81.536233089013535, 35.871289723088324],
              [-81.535978963646187, 35.872613855572737],
              [-81.535647604628892, 35.872573235138539],
              [-81.535596777716748, 35.872838061358223],
              [-81.535265416836694, 35.872797439010938],
              [-81.53501126976127, 35.874121568791502],
              [-81.535342635915839, 35.874162190909061],
              [-81.53519014707507, 35.874956668621536],
              [-81.535521516772903, 35.874997291142051],
              [-81.535419857603543, 35.875526942094638],
              [-81.535751229755803, 35.875567563982706],
              [-81.535649571374876, 35.876097215018994],
              [-81.535980947088603, 35.876137836251772],
              [-81.535879289496094, 35.876667487371634],
              [-81.536210667664292, 35.876708107972043],
              [-81.53610901086023, 35.877237759175557],
              [-81.536440391483069, 35.877278379143512],
              [-81.536287907499116, 35.878072855542761],
              [-81.536619291637209, 35.878113475012547],
              [-81.53656846439651, 35.878378300212731],
              [-81.536899849928446, 35.878418918915855],
              [-81.536798195461571, 35.878948569468839],
              [-81.53712958344812, 35.878989187539553],
              [-81.537027930905268, 35.879518839053908],
              [-81.537359321318277, 35.879559455591171],
              [-81.537308495593564, 35.879824280962687],
              [-81.537971280676032, 35.879905512480967],
              [-81.537920456767921, 35.880170338975589],
              [-81.538251850302046, 35.880210953078624],
              [-81.538150203551197, 35.880740604395498],
              [-81.537818806788408, 35.880699990047056],
              [-81.537767981852355, 35.880964815501748],
              [-81.537717156583213, 35.881229640910213],
              [-81.538048555495763, 35.881270256428053],
              [-81.537997730954174, 35.881535081924419],
              [-81.537666330980727, 35.881494466272287],
              [-81.537463024131554, 35.882553767280463],
              [-81.53713162027644, 35.882513151091537],
              [-81.537080791918797, 35.882777975186677],
              [-81.537412196862959, 35.882818592410899],
              [-81.537361368153952, 35.883083417517959],
              [-81.537310540218968, 35.883348242555726],
              [-81.536979133153068, 35.88330762506304],
              [-81.536826643030466, 35.884102098617277],
              [-81.536495232036401, 35.884061480745061],
              [-81.536393567788167, 35.884591128988177],
              [-81.536062156112948, 35.884550509923386],
              [-81.535960487302603, 35.885080157735459],
              [-81.53562907381054, 35.885039536600289],
              [-81.53552740154538, 35.885569183958246],
              [-81.535195986293161, 35.885528562554555],
              [-81.535145148585556, 35.885793385579191],
              [-81.534813731470365, 35.885752762262342],
              [-81.534762892396841, 35.886017586007235],
              [-81.533768641003974, 35.88589571112594],
              [-81.533819483260046, 35.885630887783719],
              [-81.533488067506454, 35.885590261763944],
              [-81.533538911597361, 35.885325438486554],
              [-81.530887610286925, 35.885000392568038],
              [-81.530836757709253, 35.885265214771181],
              [-81.52984252255105, 35.885143306032468],
              [-81.529791666457371, 35.88540812778637],
              [-81.529460253615213, 35.885367489919503],
              [-81.529409396099126, 35.88563231059166],
              [-81.528746570400656, 35.885551031863535],
              [-81.528644850152901, 35.886080673432289],
              [-81.528313436235777, 35.886040032436654],
              [-81.528262574536797, 35.886304852566589],
              [-81.52793115878508, 35.886264210558686],
              [-81.527829432264923, 35.88679385041047],
              [-81.527498014724941, 35.886753207232907],
              [-81.527396283641892, 35.887282846653157],
              [-81.527064864313701, 35.887242202306062],
              [-81.527013997764712, 35.887507021800623],
              [-81.526682577709153, 35.887466376418239],
              [-81.526631709765695, 35.887731195732087],
              [-81.525637448389489, 35.88760925287486],
              [-81.525586575822544, 35.887874071762191],
              [-81.525255154979433, 35.887833422507192],
              [-81.525204282125415, 35.888098241190697],
              [-81.524872860554964, 35.888057590900409],
              [-81.524669357130847, 35.889116864723775],
              [-81.524337931622171, 35.889076212094047],
              [-81.524236175786896, 35.889605848457911],
              [-81.523904748490011, 35.88956519465858],
              [-81.52385386901112, 35.889830012636409],
              [-81.523522439907779, 35.889789358725594],
              [-81.523471559034391, 35.890054176522447],
              [-81.523140130282869, 35.890013520652417],
              [-81.523038364306032, 35.89054315586079],
              [-81.522375502452888, 35.890461840903122],
              [-81.522324617395881, 35.890726658157377],
              [-81.521993186490619, 35.890686000082283],
              [-81.522044072608651, 35.890421182962392],
              [-81.521712643069819, 35.89038052321964],
              [-81.521661754783565, 35.890645340227984],
              [-81.520004604631751, 35.890442029174885],
              [-81.519953710679147, 35.890706844563887],
              [-81.519622281020531, 35.890666179146265],
              [-81.519520490048734, 35.891195811317772],
              [-81.5191890574947, 35.891155144753249],
              [-81.518730971913513, 35.893538481503427],
              [-81.51906241398801, 35.893579148376261],
              [-81.518807914480959, 35.894903222309843],
              [-81.518476465994141, 35.894862554787977],
              [-81.518425564246286, 35.895127369115606],
              [-81.517762667203158, 35.895046029274958],
              [-81.517711762999497, 35.895310843287135],
              [-81.51738031447131, 35.895270171869377],
              [-81.517329407765502, 35.89553498572333],
              [-81.516997958510089, 35.89549431327012],
              [-81.516947050409456, 35.895759126943005],
              [-81.516284149670568, 35.895677779087542],
              [-81.516233239114072, 35.895942592444925],
              [-81.515901788737949, 35.895901917019749],
              [-81.5155703375884, 35.895861240716407],
              [-81.515621250266918, 35.895596427627844],
              [-81.51528980161946, 35.89555575053501],
              [-81.515238887879931, 35.895820563489174],
              [-81.514907437398051, 35.895779885383632],
              [-81.514958352198605, 35.895515072563946],
              [-81.514295455465557, 35.895433713895805],
              [-81.514244538543096, 35.89569852644663],
              [-81.512918745918796, 35.895535796836199],
              [-81.512969667085329, 35.895270984823043],
              [-81.511975329050074, 35.895148929238943],
              [-81.512128102309674, 35.894354494083473],
              [-81.51179666048975, 35.894313806581415],
              [-81.511847584564478, 35.894048994919181],
              [-81.511516144167359, 35.894008307551559],
              [-81.511567070076538, 35.893743495954276],
              [-81.511235631046333, 35.893702806919045],
              [-81.511286556575129, 35.893437995432429],
              [-81.51095512007511, 35.89339730650876],
              [-81.511006046330905, 35.893132495109953],
              [-81.51034317531898, 35.893051113950328],
              [-81.510011739746204, 35.893010421579937],
              [-81.509960809200351, 35.893275232598214],
              [-81.50962937403591, 35.893234540070573],
              [-81.509578442095119, 35.89349935090771],
              [-81.508915568403751, 35.893417962025012],
              [-81.508864634007168, 35.893682772546541],
              [-81.508533196615559, 35.893642077069721],
              [-81.508380388239374, 35.894436507950999],
              [-81.508711828813844, 35.894477203831244],
              [-81.508609957014968, 35.895006824454242],
              [-81.508941400073297, 35.895047520603391],
              [-81.508890464733838, 35.895312330979273],
              [-81.508839529060495, 35.895577141308479],
              [-81.50917097454672, 35.895617835924554],
              [-81.509120039600276, 35.895882646341548],
              [-81.509451486509434, 35.895923341092029],
              [-81.509298682821182, 35.896717771564951],
              [-81.509196812016526, 35.897247391346177],
              [-81.506876649186651, 35.896962507258323],
              [-81.506774761112581, 35.897492125893727],
              [-81.506443308007235, 35.897451423917353],
              [-81.506392362933909, 35.897716232118064],
              [-81.505729455631325, 35.897634826094091],
              [-81.505678508129904, 35.897899634880027],
              [-81.505015600013735, 35.897818224081867],
              [-81.504964648920819, 35.898083031673785],
              [-81.504633195438245, 35.8980423256669],
              [-81.504582242950221, 35.898307133077573],
              [-81.503587879053256, 35.898185007490348],
              [-81.503536921940722, 35.898449814473473],
              [-81.503205467644662, 35.89840910369233],
              [-81.503154509137005, 35.898673910494047],
              [-81.502160142883554, 35.898551773287103],
              [-81.502109181966247, 35.898816579615847],
              [-81.501446269922539, 35.898735149451312],
              [-81.501395305441577, 35.898999955486815],
              [-81.501063849967338, 35.898959238895756],
              [-81.501012884091296, 35.899224044750021],
              [-81.500349969808667, 35.899142608640979],
              [-81.500299001476336, 35.899407414179343],
              [-81.49963608751591, 35.899325974174275],
              [-81.49958511672736, 35.899590779396668],
              [-81.498922200874404, 35.899509335541069],
              [-81.498871227629607, 35.899774140447505],
              [-81.498539769143534, 35.899733417033552],
              [-81.497876853146138, 35.899651966601425],
              [-81.497825876384113, 35.899916771057399],
              [-81.497162959629634, 35.899835317652851],
              [-81.497213938513568, 35.89957051346601],
              [-81.496882482778318, 35.899529784622985],
              [-81.496831501725907, 35.899794588697986],
              [-81.496500044184415, 35.899753859743115],
              [-81.496449062844135, 35.900018663613984],
              [-81.495454689014423, 35.899896470038136],
              [-81.495505674617235, 35.89963166564727],
              [-81.495174218412942, 35.899590933088156],
              [-81.495123231721308, 35.899855736443435],
              [-81.494460319273117, 35.899774267429152],
              [-81.494409330125308, 35.900039070468338],
              [-81.492752047876195, 35.899835381074801],
              [-81.492701051981641, 35.900100183416775],
              [-81.492369596348809, 35.900059442321833],
              [-81.492318599058905, 35.90032424448232],
              [-81.491987141592091, 35.900283502374322],
              [-81.491936144014232, 35.900548304330826],
              [-81.491604685848642, 35.90050756208818],
              [-81.49155368687542, 35.900772363863098],
              [-81.490890769369813, 35.900690874603406],
              [-81.490839766832778, 35.900955676084827],
              [-81.489513931590864, 35.900792686214174],
              [-81.48946292447549, 35.901057487110215],
              [-81.488800007846748, 35.900975986476659],
              [-81.488748998275071, 35.901240787056615],
              [-81.488086079754765, 35.901159282572173],
              [-81.488035067726656, 35.901424082835938],
              [-81.487703607907264, 35.901383329107546],
              [-81.487652593376154, 35.90164812921229],
              [-81.487321132830388, 35.901607374448169],
              [-81.487270117983485, 35.901872173447821],
              [-81.486938656711246, 35.901831417647927],
              [-81.486836622840073, 35.902361017061722],
              [-81.48617369702734, 35.902279501319256],
              [-81.486122676914306, 35.902544300697684],
              [-81.485459750345612, 35.902462781982599],
              [-81.485408727748208, 35.902727580143832],
              [-81.485077263904671, 35.902686819300079],
              [-81.485026239939543, 35.902951618180658],
              [-81.484694775341964, 35.902910855400116],
              [-81.48464374998116, 35.903175654099094],
              [-81.484312283577339, 35.903134891206413],
              [-81.484261256793332, 35.90339968882278],
              [-81.48392979077056, 35.903358924871746],
              [-81.483878762590862, 35.903623722306492],
              [-81.483547295841802, 35.90358295731977],
              [-81.483394205032354, 35.904377349861619],
              [-81.483343174480993, 35.904642147874831],
              [-81.483674646581576, 35.904682913377741],
              [-81.483623615621426, 35.904947710600162],
              [-81.484286562976351, 35.905029240073105],
              [-81.484235534938776, 35.90529403839632],
              [-81.484567010165392, 35.905334801465393],
              [-81.484515982826537, 35.905599598975222],
              [-81.484847458341321, 35.905640361300428],
              [-81.484796431756635, 35.905905159798991],
              [-81.48512790977459, 35.905945921335118],
              [-81.485025858775714, 35.906475516636071],
              [-81.485357339278067, 35.90651627844143],
              [-81.485306313784108, 35.906781076167242],
              [-81.485969277859411, 35.906862595542023],
              [-81.485765182161501, 35.907921787930839],
              [-81.485433694576784, 35.907881027276943],
              [-81.48538266847082, 35.908145824902235],
              [-81.485051181294949, 35.908105064091032],
              [-81.485000153793237, 35.90836986153468],
              [-81.484337177215437, 35.908288336962023],
              [-81.484286147256924, 35.908553134089189],
              [-81.483954657827312, 35.908512369426788],
              [-81.483903626473023, 35.908777166372303],
              [-81.483572136344634, 35.908736401575098],
              [-81.483521103594555, 35.909001198338785],
              [-81.482858123299309, 35.908919664848014],
              [-81.482807086984749, 35.909184461317878],
              [-81.482475596845617, 35.909143693525301],
              [-81.482424559135211, 35.909408489813401],
              [-81.481761576603589, 35.909326950377107],
              [-81.481710536436125, 35.909591746348703],
              [-81.481379044043535, 35.909550974704892],
              [-81.481328003587848, 35.909815770472136],
              [-81.480996510468941, 35.909774997792532],
              [-81.480945467509756, 35.910039793400564],
              [-81.480282481288867, 35.909958245946939],
              [-81.480231435872724, 35.910223041238396],
              [-81.479899941635708, 35.910182265585938],
              [-81.479848894823576, 35.910447060695667],
              [-81.479517399860086, 35.910406284007294],
              [-81.479415303081637, 35.910935872914827],
              [-81.479083806358346, 35.910895095956995],
              [-81.478981705010852, 35.911424684429093],
              [-81.478650206472324, 35.911383905399646],
              [-81.478548101663407, 35.911913493413749],
              [-81.478216601364977, 35.911872714114708],
              [-81.478165547937053, 35.912137507454517],
              [-81.47783404688451, 35.912096726218621],
              [-81.477782990980742, 35.912361520300109],
              [-81.477119987757803, 35.912279954855187],
              [-81.477068929396751, 35.912544748620085],
              [-81.476737427226368, 35.912503964411215],
              [-81.47663530734981, 35.91303355062891],
              [-81.476303803391801, 35.912992765249435],
              [-81.476150617840517, 35.913787142466276],
              [-81.475819111061384, 35.913746356682445],
              [-81.475716983563743, 35.914275940988212],
              [-81.476048493600487, 35.91431672792006],
              [-81.475946366885807, 35.91484631230643],
              [-81.475614854726587, 35.914805525105088],
              [-81.475563789805634, 35.915070317092727],
              [-81.475232276920053, 35.915029528855413],
              [-81.474976940878122, 35.916353487433618],
              [-81.475308459070121, 35.916394276344597],
              [-81.475104189339419, 35.917453442877481],
              [-81.475435712083893, 35.917494230525151],
              [-81.475333577331725, 35.918023813777324],
              [-81.475002051329341, 35.917983024981638],
              [-81.474848843477616, 35.91877739819995],
              [-81.474517314626155, 35.918736608098726],
              [-81.47446624362027, 35.919001399242504],
              [-81.473803184799479, 35.918919816066918],
              [-81.473649962241026, 35.919714186626138],
              [-81.473318430176633, 35.919673394183214],
              [-81.473114122641519, 35.92073255346417],
              [-81.472782586639113, 35.920691758679972],
              [-81.4721185349146, 35.924134018257021],
              [-81.472450085823269, 35.924174814770574],
              [-81.472399003463039, 35.924439603636522],
              [-81.47173589985772, 35.924358009438649],
              [-81.471684815039495, 35.924622797987567],
              [-81.469695505868629, 35.924377992053273],
              [-81.469746598190966, 35.924113205191581],
              [-81.469083499919236, 35.924031595671948],
              [-81.468088856146494, 35.923909175061048],
              [-81.468037758544497, 35.924173962149837],
              [-81.467706210976729, 35.924133153627565],
              [-81.467655111978161, 35.924397940534114],
              [-81.466992013482184, 35.92431631965993],
              [-81.466940912025493, 35.924581106249398],
              [-81.466609363312855, 35.924540293852644],
              [-81.466456052639742, 35.925334652954376],
              [-81.466124499970078, 35.925293839274239],
              [-81.466022289794495, 35.925823411486839],
              [-81.465690735337091, 35.925782596635784],
              [-81.465435197561789, 35.927106523882102],
              [-81.465766757326534, 35.927147339407135],
              [-81.465511215343085, 35.928471265260704],
              [-81.465842781830929, 35.928512079635119],
              [-81.465740564598008, 35.929041650098],
              [-81.466072133544159, 35.929082463840601],
              [-81.46602102605415, 35.929347249574846],
              [-81.466352596397073, 35.929388062550913],
              [-81.466301488498061, 35.929652847493742],
              [-81.466633060265309, 35.929693660604265],
              [-81.466530847800428, 35.930223230571976],
              [-81.466199273937448, 35.930182418092862],
              [-81.465943729384875, 35.931506341549962],
              [-81.465612150549205, 35.931465527495419],
              [-81.465509928036695, 35.931995096279799],
              [-81.46517834630508, 35.931954281076727],
              [-81.465229459344556, 35.931689496832],
              [-81.46257683145663, 35.931362939219191],
              [-81.462627952653577, 35.931098156005369],
              [-81.461633226047979, 35.930975682317012],
              [-81.461684351202209, 35.93071089943777],
              [-81.461021203544064, 35.930629245481882],
              [-81.460970077374469, 35.930894028069176],
              [-81.460306930069791, 35.930812371116872],
              [-81.46025580033394, 35.931077153409205],
              [-81.459924226109308, 35.931036322995702],
              [-81.459873096084266, 35.931301105083136],
              [-81.459209945410237, 35.931219441304677],
              [-81.459158812926773, 35.931484223074754],
              [-81.458827237585254, 35.931443389687459],
              [-81.458776102596872, 35.931708171297373],
              [-81.458444526529178, 35.931667336873943],
              [-81.458393390143812, 35.931932118301333],
              [-81.458061813349858, 35.931891282841541],
              [-81.458010675567593, 35.932156064086492],
              [-81.457679096939714, 35.932115227612819],
              [-81.457474538182353, 35.933174350675316],
              [-81.457142956751326, 35.933133512738507],
              [-81.457091815141254, 35.933398292574346],
              [-81.456760231876189, 35.933357453623493],
              [-81.456709088896474, 35.933622234177797],
              [-81.456377504905248, 35.933581394190696],
              [-81.456275215788679, 35.934110953985389],
              [-81.455943630009799, 35.934070112827136],
              [-81.455892482765023, 35.934334892089822],
              [-81.455560897367903, 35.934294049873102],
              [-81.455509748753428, 35.934558829854154],
              [-81.454846575707052, 35.93447714156801],
              [-81.45464197045429, 35.935536258111632],
              [-81.454310379647538, 35.935495412538479],
              [-81.454156919263212, 35.936289748584208],
              [-81.453493731151198, 35.936208053045711],
              [-81.453442575652147, 35.936472831948151],
              [-81.4531109804702, 35.936431982252437],
              [-81.453059822465988, 35.936696760994487],
              [-81.452728227665887, 35.936655910240276],
              [-81.452677068264421, 35.936920688799567],
              [-81.452345472738244, 35.936879837009045],
              [-81.452294311912141, 35.937144614484616],
              [-81.451962714579309, 35.937103762581032],
              [-81.451911552356052, 35.937368539874001],
              [-81.451248357654322, 35.937286832169413],
              [-81.451197192972003, 35.937551609144755],
              [-81.450865595630589, 35.937510754244769],
              [-81.45081442844301, 35.937775531059621],
              [-81.450151231508343, 35.937693817406888],
              [-81.450100062969724, 35.937958593881831],
              [-81.449768463376586, 35.937917735129069],
              [-81.449614950346017, 35.938712063906593],
              [-81.44928334903868, 35.938671204726361],
              [-81.44923217592617, 35.938935980762423],
              [-81.448900572757509, 35.938895119667052],
              [-81.448849398247603, 35.939159895520262],
              [-81.448517795460901, 35.939119033366303],
              [-81.448466619553614, 35.939383809036812],
              [-81.448135014960243, 35.939342946769642],
              [-81.44808383765556, 35.939607722257342],
              [-81.447752232308957, 35.939566858052785],
              [-81.44770105360675, 35.939831633357699],
              [-81.44670623750406, 35.93970903490851],
              [-81.446757419418617, 35.939444260909482],
              [-81.446425815414912, 35.93940339309929],
              [-81.44637463246589, 35.939668167864369],
              [-81.445379820392148, 35.939545558598091],
              [-81.445328632814295, 35.939810332932616],
              [-81.444997028030244, 35.939769461246698],
              [-81.444945840162887, 35.940034235376217],
              [-81.444614234652931, 35.939993362653986],
              [-81.444563044252831, 35.940258135721727],
              [-81.444231438016971, 35.940217261963085],
              [-81.444180247354723, 35.940482035726887],
              [-81.443848640392858, 35.940441160931769],
              [-81.443695059854804, 35.941235480696378],
              [-81.443363450043634, 35.941194604594941],
              [-81.443312254806699, 35.94145937791933],
              [-81.442980644269625, 35.941418500781474],
              [-81.442929447607852, 35.941683273021937],
              [-81.442597836344873, 35.941642394847626],
              [-81.44254663831282, 35.941907167806306],
              [-81.442215026296708, 35.941866287694573],
              [-81.442163825759039, 35.942131060492436],
              [-81.441832213044421, 35.942090180245224],
              [-81.441781012189807, 35.942354951937055],
              [-81.441449398749327, 35.942314070653445],
              [-81.44139819541634, 35.94257884308545],
              [-81.441066581222728, 35.94253795986436],
              [-81.441015376492118, 35.942802732113485],
              [-81.440683761599914, 35.942761848756881],
              [-81.440581350061095, 35.943291391017027],
              [-81.439918115929316, 35.943209621081728],
              [-81.439866906978551, 35.943474391880891],
              [-81.439535289908761, 35.943433505414973],
              [-81.439484079560316, 35.943698276031164],
              [-81.439152461764706, 35.943657388528763],
              [-81.439101250018581, 35.943922158962032],
              [-81.438438012176476, 35.943840380103886],
              [-81.437443157392622, 35.943717705517351],
              [-81.437391940001746, 35.9439824752275],
              [-81.4367287033959, 35.943900687084735],
              [-81.436677483545537, 35.944165456476817],
              [-81.434687775421736, 35.943920069603521],
              [-81.434636548864901, 35.944184838137254],
              [-81.434304930391008, 35.944143936595452],
              [-81.434202471929524, 35.944673473293079],
              [-81.433870852803494, 35.944632571458634],
              [-81.43381962200688, 35.944897339600331],
              [-81.433488001019953, 35.944856435850404],
              [-81.433385536295049, 35.945385971719737],
              [-81.433053914628758, 35.945345066776142],
              [-81.433002680700383, 35.945609834503756],
              [-81.432339434063508, 35.945528022587133],
              [-81.432288197648433, 35.945792789095528],
              [-81.431624951340552, 35.945710973280825],
              [-81.431573711384928, 35.945975740394111],
              [-81.431242087105758, 35.945934830559956],
              [-81.431293328123161, 35.945670063581836],
              [-81.430630082696879, 35.94558824147942],
              [-81.430578840664026, 35.945853008165386],
              [-81.429583972968601, 35.945730267823485],
              [-81.429532726306192, 35.94599503407818],
              [-81.429201102982319, 35.945954118700229],
              [-81.429098607606477, 35.946483649872086],
              [-81.42876698249529, 35.946442733322471],
              [-81.428715732700795, 35.946707499162713],
              [-81.428384106864044, 35.946666581576523],
              [-81.428332855671584, 35.946931347233594],
              [-81.42800122908217, 35.946890427709867],
              [-81.427949976491803, 35.947155193183754],
              [-81.427618349203854, 35.947114273524392],
              [-81.427515840863734, 35.947643803156758],
              [-81.427184211788472, 35.947602882325683],
              [-81.427081698871831, 35.948132411517491],
              [-81.426750068009326, 35.948091489514702],
              [-81.426698810552352, 35.948356254342791],
              [-81.426035546577268, 35.948274406483527],
              [-81.425984286660523, 35.948539170993236],
              [-81.425652654656176, 35.948498245114735],
              [-81.425601392233148, 35.948763009463327],
              [-81.425269759503195, 35.94872208254813],
              [-81.425218495682174, 35.948986846713474],
              [-81.424886862253899, 35.948945919662727],
              [-81.424835597007601, 35.949210682743711],
              [-81.424172327901118, 35.949128824788481],
              [-81.424069793287728, 35.949658351144549],
              [-81.423738156547728, 35.949617420104929],
              [-81.423327991659107, 35.951735520808178],
              [-81.423659636893817, 35.951776452929046],
              [-81.423557094418257, 35.952305977442926],
              [-81.42322544705992, 35.952265045051746],
              [-81.423174173147501, 35.952529807123206],
              [-81.422842525090715, 35.952488874596376],
              [-81.42279125088821, 35.95275363646256],
              [-81.422459602105917, 35.952712702899035],
              [-81.422408326478092, 35.952977463680753],
              [-81.42207667697032, 35.952936529080539],
              [-81.422025398863084, 35.953201290601896],
              [-81.421693748602777, 35.953160354064067],
              [-81.421642469097236, 35.953425115402027],
              [-81.421310818138494, 35.953384178728449],
              [-81.421259538342923, 35.953648939861033],
              [-81.420927885523412, 35.953608001271711],
              [-81.420876604329507, 35.953872762220975],
              [-81.420544950784517, 35.953831822594864],
              [-81.420493668192279, 35.954096583360716],
              [-81.419830361123175, 35.954014702012756],
              [-81.419779074935477, 35.954279461580867],
              [-81.418784114210155, 35.954156631930374],
              [-81.418732824527297, 35.954421391945715],
              [-81.418401170923715, 35.954380446482944],
              [-81.418349878734176, 35.954645206336778],
              [-81.418018224405202, 35.954604259837311],
              [-81.417966931925449, 35.954869019485834],
              [-81.417635275762876, 35.954828071971484],
              [-81.417583981884803, 35.955092831436509],
              [-81.417252326104958, 35.955051882863557],
              [-81.417149734080198, 35.955581400499284],
              [-81.416818075404876, 35.955540450776297],
              [-81.416766778393352, 35.95580520982611],
              [-81.416435118992638, 35.955764259066321],
              [-81.416332520700948, 35.956293775871536],
              [-81.416000859512934, 35.956252823939778],
              [-81.41589825775111, 35.956782340281357],
              [-81.415566594775783, 35.956741387177637],
              [-81.415515291760471, 35.957006144701189],
              [-81.415183628059793, 35.956965190560673],
              [-81.415132323672921, 35.957229948801711],
              [-81.414800659246893, 35.957188993624506],
              [-81.414749354542721, 35.957453750759036],
              [-81.414417689391442, 35.957412794544958],
              [-81.414366382180404, 35.957677551517882],
              [-81.414034716303789, 35.957636594267115],
              [-81.413932097666745, 35.95816610872032],
              [-81.413600430002816, 35.958125150297519],
              [-81.413549119657986, 35.958389906854933],
              [-81.41321745126875, 35.958348947395244],
              [-81.413166139525387, 35.958613703769061],
              [-81.412834469302481, 35.958572743294489],
              [-81.412783156160515, 35.958837499484716],
              [-81.412119816817324, 35.95875557551669],
              [-81.412068500106486, 35.959020331409896],
              [-81.411736829850952, 35.958979367037259],
              [-81.411685511768397, 35.959244123647956],
              [-81.411353840787569, 35.959203158238488],
              [-81.411302521279396, 35.959467913764435],
              [-81.410970849600332, 35.959426948219175],
              [-81.410868208558071, 35.959956458833588],
              [-81.410536533983475, 35.959915492138123],
              [-81.410382566154553, 35.960709757301956],
              [-81.410050888703765, 35.960668788398102],
              [-81.40984558782533, 35.961727807426811],
              [-81.40951390649002, 35.961686837981333],
              [-81.40910327888335, 35.963804869950003],
              [-81.408771589388365, 35.963763898520789],
              [-81.408720257373375, 35.964028651619444],
              [-81.408388567153196, 35.963987679153334],
              [-81.408285901123776, 35.964517185813882],
              [-81.407954209089311, 35.964476211274523],
              [-81.407902873966535, 35.964740964858215],
              [-81.407571181206805, 35.964699989282025],
              [-81.407519844685169, 35.964964742681865],
              [-81.406856456970473, 35.96488278947686],
              [-81.406805119069176, 35.965147541634764],
              [-81.406141730577687, 35.965065584552669],
              [-81.406090389107078, 35.965330336413309],
              [-81.405758693763829, 35.965289356845958],
              [-81.405810036269656, 35.965024604219607],
              [-81.405146649799349, 35.964942641727646],
              [-81.405095305142467, 35.96520739318246],
              [-81.404431917895522, 35.965125426813387],
              [-81.40438057077759, 35.965390177949061],
              [-81.402058719078582, 35.965103264045496],
              [-81.402110073631064, 35.964838513857075],
              [-81.401446693530275, 35.964756530335926],
              [-81.401498049869915, 35.964491780369571],
              [-81.399839609690162, 35.964286805585985],
              [-81.399788246958693, 35.964551555798487],
              [-81.399456559098724, 35.964510558177444],
              [-81.399507922892127, 35.964245808100316],
              [-81.398512864520256, 35.964122810233597],
              [-81.398461497513992, 35.964387559003633],
              [-81.398129811001979, 35.964346557776203],
              [-81.398078441488337, 35.964611306384029],
              [-81.397746754251358, 35.964570304119626],
              [-81.397695384446976, 35.964835052521892],
              [-81.397363696485101, 35.964794049220487],
              [-81.397312324173214, 35.965058797460536],
              [-81.396648947136498, 35.964976787882136],
              [-81.396700320437333, 35.964712039033579],
              [-81.395373574630156, 35.96454800869811],
              [-81.395424952950208, 35.964283260320705],
              [-81.394429900313682, 35.964160228283291],
              [-81.394481281509371, 35.963895481164563],
              [-81.393486235066092, 35.963772440517971],
              [-81.393434850684471, 35.964037187230495],
              [-81.393103168166846, 35.963996172343762],
              [-81.393154553583713, 35.963731424865593],
              [-81.392822873573422, 35.963690409190981],
              [-81.392925645546498, 35.963160915261014],
              [-81.392593967970541, 35.963119898054522],
              [-81.392799513227047, 35.962060910132308],
              [-81.392467839154392, 35.962019893488723],
              [-81.39257061243778, 35.961490399496526],
              [-81.392238941907678, 35.961449381299488],
              [-81.391907270606495, 35.961408362222642],
              [-81.391804494183773, 35.961937855651428],
              [-81.391472821122647, 35.961896836303126],
              [-81.391370040092454, 35.962426328387728],
              [-81.391038366352973, 35.962385307845359],
              [-81.390986973716096, 35.962650053690354],
              [-81.390655299225074, 35.962609031209986],
              [-81.39055251084261, 35.963138523384522],
              [-81.390220834564929, 35.963097499731745],
              [-81.390118042709801, 35.963626991441295],
              [-81.389786364645474, 35.963585966616066],
              [-81.389734967136803, 35.963850711812057],
              [-81.389403287239517, 35.963809685971526],
              [-81.389351888358263, 35.96407443188464],
              [-81.389020207736237, 35.964033405006994],
              [-81.388968807429237, 35.964298149835088],
              [-81.388305446181249, 35.964216093082605],
              [-81.388254043413014, 35.964480837591253],
              [-81.387922361678847, 35.964439807738053],
              [-81.387870957511467, 35.964704552062685],
              [-81.387539275052518, 35.964663521172497],
              [-81.387436462473715, 35.965193009426713],
              [-81.387104778228107, 35.965151977363938],
              [-81.387053370924988, 35.965416721271936],
              [-81.386721685954726, 35.965375688172195],
              [-81.386670276143988, 35.965640431917798],
              [-81.386338590449128, 35.965599397780927],
              [-81.386287179239119, 35.965864141342486],
              [-81.385292120992361, 35.965741033116103],
              [-81.385189292296886, 35.966270519258934],
              [-81.38485760473867, 35.966229480208284],
              [-81.384703354316883, 35.967023708683904],
              [-81.384371663936705, 35.966982669226304],
              [-81.384320245392729, 35.967247411818832],
              [-81.383656862385607, 35.967165329048441],
              [-81.383708283053565, 35.966900586726972],
              [-81.382381526470965, 35.966736411788482],
              [-81.382330101528368, 35.967001152667017],
              [-81.38166672259662, 35.96691905997853],
              [-81.381563866369689, 35.967448541970327],
              [-81.381232175840211, 35.967407493991821],
              [-81.381180745591038, 35.967672234339545],
              [-81.380517363393878, 35.967590134505876],
              [-81.380414497688491, 35.968119616315278],
              [-81.380746191837503, 35.968160666481836],
              [-81.380694759540589, 35.968425407449018],
              [-81.380363064303069, 35.968384356245942],
              [-81.38031163171506, 35.968649097007408],
              [-81.379316545976224, 35.968525938461397],
              [-81.379265108756414, 35.968790678789169],
              [-81.378270022869003, 35.968667511722067],
              [-81.378218582099123, 35.968932250693612],
              [-81.377886887146374, 35.968891192751158],
              [-81.377835443895151, 35.969155932461064],
              [-81.377503748218004, 35.969114873481388],
              [-81.377400859660682, 35.969644351561477],
              [-81.377069161088571, 35.969603291430673],
              [-81.376966269057107, 35.970132769044866],
              [-81.37663456869852, 35.970091707741354],
              [-81.376531673192758, 35.970621184889573],
              [-81.376863375675399, 35.970662246464094],
              [-81.376811929019055, 35.970926984639362],
              [-81.377143632901308, 35.970968045447805],
              [-81.377092185861017, 35.971232783731459],
              [-81.377423891142897, 35.971273843773808],
              [-81.377372445935521, 35.971538582122612],
              [-81.377704152617028, 35.971579641398947],
              [-81.377601262205502, 35.972109118243047],
              [-81.377269553426416, 35.972068059596722],
              [-81.377218106098013, 35.97233279782084],
              [-81.376886396567869, 35.972291737236333],
              [-81.376937843850001, 35.972026999169252],
              [-81.376606135719499, 35.97198593781863],
              [-81.376554687375389, 35.972250675750175],
              [-81.376222977412056, 35.972209613383946],
              [-81.376171527694723, 35.972474352032123],
              [-81.375508107765128, 35.972392224302091],
              [-81.375456654451057, 35.972656961751014],
              [-81.375124944484824, 35.972615896387111],
              [-81.374793233747752, 35.972574830143216],
              [-81.374844689185892, 35.972310092965387],
              [-81.374512980956865, 35.972269025933862],
              [-81.374667348431814, 35.971474814514643],
              [-81.374335643726667, 35.971433746988104],
              [-81.374387099911885, 35.97116900898542],
              [-81.374055396606423, 35.971127940692796],
              [-81.374106853542557, 35.970863203677908],
              [-81.373775151636664, 35.970822134619397],
              [-81.373826609297282, 35.970557397691309],
              [-81.3721681123331, 35.970352038607857],
              [-81.37211664828051, 35.970616775780513],
              [-81.371453249940231, 35.970534625019837],
              [-81.37129885044007, 35.97132883360841],
              [-81.370967148049431, 35.971287756930138],
              [-81.370915679433679, 35.971552492600004],
              [-81.370252273515092, 35.971470335366412],
              [-81.369994915858641, 35.972794011606837],
              [-81.370326623972502, 35.972835091803034],
              [-81.370223681334934, 35.973364562215963],
              [-81.369891969988586, 35.973323481770159],
              [-81.369789022767463, 35.973852951738166],
              [-81.369457310769661, 35.973811870999029],
              [-81.369405835590442, 35.974076605774172],
              [-81.368742409348954, 35.973994440440229],
              [-81.368793886652313, 35.973729705936215],
              [-81.368130463886047, 35.973647537266942],
              [-81.368181944084213, 35.973382802963869],
              [-81.367850233728831, 35.973341717873666],
              [-81.367798753577091, 35.973606452019631],
              [-81.367135332837563, 35.973524277940463],
              [-81.367083850223906, 35.973789011766371],
              [-81.366752138731314, 35.973747922798978],
              [-81.366391739055572, 35.975601055547109],
              [-81.366060020492711, 35.975559965629671],
              [-81.36600853302599, 35.975824697892527],
              [-81.366340251568786, 35.975865788868084],
              [-81.366185788163264, 35.9766599876152],
              [-81.36651751131231, 35.976701077173502],
              [-81.366311559236451, 35.977760008100859],
              [-81.365979831838857, 35.977718918000043],
              [-81.365928341622762, 35.97798365002879],
              [-81.365596613474366, 35.977942557989643],
              [-81.365545121884665, 35.978207290734943],
              [-81.365213391903552, 35.978166197679919],
              [-81.365110405559676, 35.97869566185134],
              [-81.363451753452949, 35.978490182531175],
              [-81.363400254476588, 35.978754914315807],
              [-81.362073334160698, 35.978590513374463],
              [-81.362021830597669, 35.978855244567519],
              [-81.361690099747207, 35.978814141952959],
              [-81.361638594757792, 35.97907887206037],
              [-81.360975133055049, 35.978996663833591],
              [-81.360923624494703, 35.979261393642084],
              [-81.360260162019543, 35.979179181537198],
              [-81.360208651023342, 35.97944391192646],
              [-81.359876919784938, 35.97940280437502],
              [-81.359825407362209, 35.979667533678608],
              [-81.359161942666532, 35.979585315620817],
              [-81.359110426672913, 35.979850044625444],
              [-81.358778694324187, 35.979808934097662],
              [-81.358675660307057, 35.980338391667217],
              [-81.358343926172083, 35.980297279966358],
              [-81.358189368870143, 35.98109146555668],
              [-81.357857631860369, 35.981050351645997],
              [-81.357754590213872, 35.981579808182673],
              [-81.357422850335666, 35.981538694021332],
              [-81.357371327388805, 35.981803422091168],
              [-81.357039586786414, 35.981762306892421],
              [-81.356988062439257, 35.98202703477746],
              [-81.356324580098132, 35.981944800502383],
              [-81.35627305439705, 35.982209528045622],
              [-81.355941312684678, 35.982168409870248],
              [-81.35588978444828, 35.982433136349123],
              [-81.355558042011836, 35.982392017136412],
              [-81.355454982453026, 35.982921471477539],
              [-81.355123237095313, 35.982880350212014],
              [-81.355020174059462, 35.983409804085554],
              [-81.354688428024176, 35.983368681625421],
              [-81.354636894936689, 35.983633408352873],
              [-81.354968642007989, 35.983674530047615],
              [-81.354865576916851, 35.984203982725631],
              [-81.354533826612467, 35.984162860780799],
              [-81.354482292510539, 35.984427587360997],
              [-81.354814043877099, 35.984468709441558],
              [-81.35465944156779, 35.985262887514004],
              [-81.354991196485599, 35.98530401000108],
              [-81.354939663129173, 35.985568735755216],
              [-81.354607907175307, 35.985527614033515],
              [-81.354504836241006, 35.986057066045475],
              [-81.354173078474545, 35.98601594224953],
              [-81.354121542005288, 35.986280668485954],
              [-81.353458025388591, 35.986198418818326],
              [-81.353406486430245, 35.986463143833134],
              [-81.353074727580037, 35.986422017961502],
              [-81.352868560815466, 35.987480917951387],
              [-81.352536798028098, 35.987439789733926],
              [-81.352485254145208, 35.987704514252883],
              [-81.352153490660044, 35.987663385899033],
              [-81.35210194537666, 35.987928110233049],
              [-81.351770181141063, 35.987886979940612],
              [-81.351718634483376, 35.988151704990734],
              [-81.350723340645771, 35.988028309196764],
              [-81.350671789327876, 35.988293032910711],
              [-81.350008259678361, 35.988210764568045],
              [-81.3499567070062, 35.988475487940008],
              [-81.349293175450384, 35.988393214838993],
              [-81.349241620315254, 35.988657937890245],
              [-81.348578088014392, 35.988575661811687],
              [-81.348526530416265, 35.988840384542137],
              [-81.348194763143624, 35.988799244574594],
              [-81.348143204144748, 35.98906396712011],
              [-81.347479669598584, 35.988981684186712],
              [-81.347376544119214, 35.989511128629665],
              [-81.347044775783303, 35.989469985528039],
              [-81.346993211473702, 35.98973470753991],
              [-81.346661442413847, 35.989693563400799],
              [-81.346609876703511, 35.989958285227637],
              [-81.346278105811137, 35.989917140072272],
              [-81.346226538700051, 35.990181861714021],
              [-81.345894767083735, 35.990140715521079],
              [-81.345843198571899, 35.990405436977852],
              [-81.34551142734027, 35.990364289725953],
              [-81.345459857401451, 35.990629010096669],
              [-81.345128084337219, 35.990587861828566],
              [-81.345076513023812, 35.990852582915252],
              [-81.344412965786177, 35.990770283401993],
              [-81.344258242306097, 35.991564444671802],
              [-81.3439264660079, 35.991523293155097],
              [-81.343771736294542, 35.992317453573953],
              [-81.343439957147623, 35.992276300748053],
              [-81.343388378080547, 35.992541020066781],
              [-81.34239303948381, 35.992417555770778],
              [-81.342341456891077, 35.992682274632692],
              [-81.341677897394419, 35.992599960321343],
              [-81.341574726969995, 35.993129398255036],
              [-81.341242945578486, 35.993088238573989],
              [-81.341191358809155, 35.993352957781518],
              [-81.340859576693916, 35.993311797062873],
              [-81.340807988523622, 35.993576516085234],
              [-81.340476204575722, 35.993535354350151],
              [-81.340424615004466, 35.993800073187415],
              [-81.340092831441595, 35.993758910393431],
              [-81.340041240469361, 35.994023629045415],
              [-81.33970945507383, 35.993982465235028],
              [-81.339657862700591, 35.994247183701923],
              [-81.338994291935592, 35.99416485398369],
              [-81.338942697072724, 35.994429571228544],
              [-81.33761555535645, 35.994264900426927],
              [-81.336951987638955, 35.994182559594094],
              [-81.336288420167222, 35.994100215175159],
              [-81.336236815357921, 35.994364931304958],
              [-81.335241464044643, 35.994241407044356],
              [-81.33518985570943, 35.994506122717155],
              [-81.334858071914269, 35.994464945716636],
              [-81.334806462178065, 35.99472966120419],
              [-81.334142892396343, 35.994647305148348],
              [-81.334091279087914, 35.994912020335939],
              [-81.333427709619002, 35.994829659478775],
              [-81.333376093847065, 35.9950943743452],
              [-81.333044308558399, 35.995053192428024],
              [-81.332992691411405, 35.995317908010186],
              [-81.33266090539901, 35.995276725055234],
              [-81.332609286824805, 35.995541439551076],
              [-81.332277500088878, 35.995500255558504],
              [-81.332225880113512, 35.995764969868937],
              [-81.331894091545209, 35.995723784859813],
              [-81.331842470168667, 35.995988498985014],
              [-81.331510681985435, 35.995947312916989],
              [-81.331407436091197, 35.996476740747255],
              [-81.331075645013257, 35.99643555352673],
              [-81.331024020495434, 35.996700267231873],
              [-81.330692228693906, 35.996659078973622],
              [-81.330640602748915, 35.996923791592288],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 34,
        ORIG_FID: 3,
        AAPC_Examp: 1,
        Area_mile2: 704,
        BASIN_NAME: "Lake Rhodhiss",
        Res_Basin: "Rhodhiss",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.694579255473784, 36.148272145168747],
              [-81.694629711852002, 36.148007305105722],
              [-81.693964411312677, 36.147926962084661],
              [-81.694166239746878, 36.146867599721425],
              [-81.693833594266394, 36.146827427833934],
              [-81.693984965846354, 36.146032905228161],
              [-81.694317608150016, 36.146073077619107],
              [-81.694368062869984, 36.145808236531032],
              [-81.694700704406429, 36.145848406983944],
              [-81.694751157725477, 36.145583565711746],
              [-81.695083797414114, 36.145623735151865],
              [-81.69518470202911, 36.145094052163216],
              [-81.69551733994183, 36.145134221334636],
              [-81.695820038297029, 36.143545170359147],
              [-81.696152670097661, 36.143585336930308],
              [-81.696304010477363, 36.142790810372951],
              [-81.695971381853354, 36.142750643298115],
              [-81.696274060035876, 36.14116158868427],
              [-81.69594143929811, 36.141121422375761],
              [-81.695991885574287, 36.140856580042204],
              [-81.695659265096324, 36.140816412085094],
              [-81.695760158790861, 36.140286727528206],
              [-81.695427541923323, 36.140246559807693],
              [-81.695528436397979, 36.139716874408634],
              [-81.695195821999988, 36.139676706048256],
              [-81.695347165555319, 36.138882178386602],
              [-81.695014554665576, 36.138842008618013],
              [-81.695165897330057, 36.138047480914594],
              [-81.694833290008646, 36.13800731153956],
              [-81.694883739059094, 36.137742468376196],
              [-81.694551133138319, 36.13770229822898],
              [-81.693885923372918, 36.137621954295291],
              [-81.693835472226766, 36.137886797036884],
              [-81.693170261651304, 36.137806449219681],
              [-81.693119806894842, 36.138071290768345],
              [-81.692454595509389, 36.137990939067592],
              [-81.692404138313719, 36.138255781200776],
              [-81.691738926118347, 36.138175425616495],
              [-81.691688467533709, 36.138440266507899],
              [-81.69002543693972, 36.138239360615167],
              [-81.689974971568645, 36.138504200817074],
              [-81.689642364950828, 36.138464017342336],
              [-81.68969283136046, 36.138199176372062],
              [-81.689360227254014, 36.138158992100848],
              [-81.689410694400522, 36.137894151211555],
              [-81.689078090553963, 36.137853965291661],
              [-81.689128559577966, 36.137589125359845],
              [-81.688795957132143, 36.137548938667877],
              [-81.688846426892908, 36.137284098817062],
              [-81.688513825847892, 36.137243911352975],
              [-81.688564296345291, 36.13697907158317],
              [-81.688231697811602, 36.136938883322493],
              [-81.688181226245391, 36.137203722959782],
              [-81.687848626974898, 36.137163533662061],
              [-81.687798155148656, 36.13742837399181],
              [-81.687465554030908, 36.137388183681281],
              [-81.687263656819482, 36.138447541301311],
              [-81.686931052899411, 36.138407350432622],
              [-81.686880576134911, 36.138672189363255],
              [-81.68521755380003, 36.138471216331716],
              [-81.685167071359757, 36.138736054548225],
              [-81.684501862390732, 36.138655658742962],
              [-81.684552346968459, 36.138390820791514],
              [-81.683554539736917, 36.138270219783493],
              [-81.683605027188804, 36.138005382178164],
              [-81.682939825815538, 36.137924977856791],
              [-81.68288933622631, 36.138189815196931],
              [-81.68222413515484, 36.138109406967629],
              [-81.682274626851438, 36.13784456899171],
              [-81.681609429245825, 36.137764157409016],
              [-81.681659922747684, 36.137499319646665],
              [-81.680329535767044, 36.13733848525527],
              [-81.680279036849839, 36.137603321610811],
              [-81.679613843200229, 36.137522898271904],
              [-81.679664344254789, 36.13725806218163],
              [-81.67933174901188, 36.137217849738249],
              [-81.679432752231762, 36.136688176767308],
              [-81.679100159458429, 36.136647963684517],
              [-81.679302167297081, 36.135588617630781],
              [-81.678969579130367, 36.135548404173768],
              [-81.679070582638502, 36.135018731115267],
              [-81.678737998021816, 36.134978516093753],
              [-81.678839002338393, 36.134448843094574],
              [-81.678506420221026, 36.134408628334583],
              [-81.678809431609849, 36.132819608897876],
              [-81.678476856206046, 36.132779393128061],
              [-81.678880861277889, 36.130660696925396],
              [-81.678548294784264, 36.130620482212692],
              [-81.678649294852633, 36.130090807462977],
              [-81.678316731938637, 36.130050592086697],
              [-81.678468232755407, 36.129256079974688],
              [-81.678135673378989, 36.129215864091798],
              [-81.678236674399614, 36.128686189358909],
              [-81.677904117462049, 36.128645971935768],
              [-81.678005119290916, 36.128116297262856],
              [-81.677672564852472, 36.128076080101351],
              [-81.67772306633708, 36.12781124282057],
              [-81.677390514379624, 36.127771023962026],
              [-81.677441016600469, 36.127506186762453],
              [-81.67710846493307, 36.127465967156425],
              [-81.67736097750732, 36.126141781027989],
              [-81.677693522722421, 36.12618199999519],
              [-81.677845022379941, 36.125387486418553],
              [-81.677512480399812, 36.125347268750097],
              [-81.677613480637504, 36.124817592740932],
              [-81.677280941126142, 36.124777374433222],
              [-81.677331441800121, 36.124512536033997],
              [-81.676998903689196, 36.124472316954474],
              [-81.677099906207388, 36.123942641168306],
              [-81.676767370565173, 36.123902421449699],
              [-81.676817872379331, 36.123637583161944],
              [-81.676485339218033, 36.123597361746285],
              [-81.67653584179827, 36.123332524440919],
              [-81.676203308926944, 36.123292302277761],
              [-81.676253812243303, 36.123027465053795],
              [-81.675921281882808, 36.12298724209456],
              [-81.675588750744026, 36.122947018255218],
              [-81.675639256975245, 36.122682181221037],
              [-81.673976614966278, 36.122481049949627],
              [-81.674027126176469, 36.122216212626554],
              [-81.673362073600643, 36.12213575369703],
              [-81.673412586644972, 36.12187091748897],
              [-81.673080062478718, 36.121830686788087],
              [-81.673130575118719, 36.121565849784957],
              [-81.671800487159103, 36.121404918444227],
              [-81.671851003769532, 36.121140082821427],
              [-81.66985588870152, 36.120898657661179],
              [-81.669805363491264, 36.121163493437258],
              [-81.669472844017946, 36.121123252020091],
              [-81.669422318517562, 36.121388087588009],
              [-81.669089798308377, 36.121347845133705],
              [-81.669039271407556, 36.121612680517735],
              [-81.668706750462491, 36.121572437026366],
              [-81.668656221050796, 36.121837272250716],
              [-81.667326137491258, 36.12167629048826],
              [-81.667376670095038, 36.121411456719876],
              [-81.666711633000702, 36.121330960665773],
              [-81.6667621684891, 36.121066126186527],
              [-81.666429650968283, 36.121025877398267],
              [-81.666480187192278, 36.120761043000542],
              [-81.665482640909005, 36.120640290682523],
              [-81.665533180005099, 36.120375456631876],
              [-81.665200667060347, 36.120335204467388],
              [-81.665251206892179, 36.120070370498233],
              [-81.664918695347879, 36.120030117562202],
              [-81.66496923591545, 36.119765283674631],
              [-81.664304214820035, 36.119684774478266],
              [-81.664354757221204, 36.119419941705871],
              [-81.664022248780384, 36.119379685421457],
              [-81.664072791887435, 36.119114851829771],
              [-81.66374028487688, 36.119074595674576],
              [-81.663790829829964, 36.11880976214038],
              [-81.663458324190046, 36.118769504312716],
              [-81.663508868798232, 36.118504671785146],
              [-81.663176364558765, 36.11846441318599],
              [-81.663226909902576, 36.118199580739983],
              [-81.662894408173898, 36.118159321345054],
              [-81.662944954223562, 36.117894488079841],
              [-81.661947452045183, 36.117773705816788],
              [-81.661998000966491, 36.117508872898703],
              [-81.661333003662634, 36.117428346525386],
              [-81.661000504954131, 36.117388081994399],
              [-81.66105105788715, 36.117123250300246],
              [-81.660386064713776, 36.117042718766733],
              [-81.660436619420551, 36.11677788638611],
              [-81.660104123845329, 36.116737619407736],
              [-81.660154679317387, 36.116472788009752],
              [-81.659822186252939, 36.116432520235762],
              [-81.659923298276084, 36.115902855750171],
              [-81.65959080768009, 36.115862587337475],
              [-81.659641364290565, 36.115597756051692],
              [-81.659793032068094, 36.114803260086035],
              [-81.660125518392462, 36.114843527967452],
              [-81.660176072575496, 36.114578695443662],
              [-81.660508558164366, 36.114618962288034],
              [-81.660710766220362, 36.113559632075898],
              [-81.661043247870069, 36.113599897484839],
              [-81.661194896947933, 36.112805399122593],
              [-81.661527374616085, 36.112845663253147],
              [-81.661931747429264, 36.110726995276217],
              [-81.662264216887039, 36.110767257440209],
              [-81.662314761480346, 36.11050242356869],
              [-81.661982293090404, 36.110462161537491],
              [-81.6620328373089, 36.110197327771971],
              [-81.661700370319124, 36.110157064969329],
              [-81.661750916353554, 36.109892230360536],
              [-81.661418450793704, 36.10985196768732],
              [-81.661468996453308, 36.10958713318459],
              [-81.661136532263924, 36.109546868839061],
              [-81.661187079798893, 36.109282035294832],
              [-81.660854617009662, 36.109241770177974],
              [-81.660905165280013, 36.108976936715585],
              [-81.660240242834135, 36.10889640403483],
              [-81.66029079290773, 36.108631570787082],
              [-81.65995833436132, 36.108591303199105],
              [-81.660008884030276, 36.108326469156459],
              [-81.659011512478344, 36.108205661389874],
              [-81.659062066158114, 36.1079408285715],
              [-81.658729611047093, 36.107900556731536],
              [-81.658780165462176, 36.107635723995038],
              [-81.658115257321768, 36.107555179694124],
              [-81.658165813510124, 36.10729034627149],
              [-81.657500909945213, 36.107209798595356],
              [-81.657551467966258, 36.106944966288381],
              [-81.656886567836636, 36.106864414360473],
              [-81.656937127660612, 36.106599582268096],
              [-81.656272230996109, 36.106519026989254],
              [-81.656322793733281, 36.106254195087658],
              [-81.65565790053391, 36.10617363645791],
              [-81.655607336771723, 36.106438468069761],
              [-81.653612659857416, 36.106196769659995],
              [-81.653562088246645, 36.106461600447815],
              [-81.651567416867266, 36.106219867791047],
              [-81.651516838518148, 36.106484697730792],
              [-81.649189730452633, 36.106202633008472],
              [-81.649240317385264, 36.105937803974946],
              [-81.648907875452451, 36.105897505409224],
              [-81.648958463120067, 36.105632676457702],
              [-81.647961144740492, 36.105511775686367],
              [-81.648011735248716, 36.105246946181829],
              [-81.646017113876241, 36.105005121025911],
              [-81.646067710457118, 36.104740292267962],
              [-81.645402842202898, 36.104659676899715],
              [-81.645453440615782, 36.104394849257659],
              [-81.645121008025583, 36.104354539449488],
              [-81.645171607173225, 36.104089711889436],
              [-81.644839176012937, 36.104049402210947],
              [-81.644889777005687, 36.10378457470906],
              [-81.644224917788407, 36.103703952004821],
              [-81.644275520583491, 36.103439124717966],
              [-81.643278237440569, 36.103318183829522],
              [-81.643328843105408, 36.103053356890662],
              [-81.641999142306176, 36.102892090824348],
              [-81.64204975190809, 36.102627264366404],
              [-81.641052484377795, 36.102506305242521],
              [-81.641103096849406, 36.102241479132644],
              [-81.640770675322273, 36.102201157465288],
              [-81.640821289638723, 36.101936331413611],
              [-81.640488869511813, 36.101896008975167],
              [-81.64053948345277, 36.101631183029582],
              [-81.640207065836194, 36.101590859796048],
              [-81.640257680511823, 36.101326033932672],
              [-81.639925264295428, 36.101285709928113],
              [-81.639975879676143, 36.101020883245994],
              [-81.639643464889488, 36.100980559371308],
              [-81.639694082114971, 36.100715732747304],
              [-81.639361668728469, 36.100675408101665],
              [-81.639462902095602, 36.100145755015085],
              [-81.639795313376865, 36.100186080295693],
              [-81.640149614516787, 36.098332294616085],
              [-81.639817210707022, 36.098291970266551],
              [-81.639867824562074, 36.098027143291461],
              [-81.639535423292017, 36.09798681904789],
              [-81.639586037881642, 36.097721992155037],
              [-81.639253637982122, 36.097681666239495],
              [-81.639304253306307, 36.097416839428938],
              [-81.638307058835764, 36.097295857558429],
              [-81.638357677029063, 36.09703103109608],
              [-81.637692884523759, 36.096950372562205],
              [-81.637743504518838, 36.096685546315165],
              [-81.637411109817833, 36.096645215374863],
              [-81.637461731657396, 36.096380389186159],
              [-81.636796945388397, 36.096299724859662],
              [-81.636847569029754, 36.096034898886337],
              [-81.636515177461646, 36.095994565500149],
              [-81.636565801866951, 36.095729740509981],
              [-81.636233411698896, 36.09568940635284],
              [-81.636284036809116, 36.09542458054414],
              [-81.635951648041029, 36.09538424561611],
              [-81.636002273885666, 36.095119419889699],
              [-81.635669886517789, 36.095079084190751],
              [-81.635771139342935, 36.09454943285175],
              [-81.635438755552343, 36.094509096491123],
              [-81.635489382532924, 36.094244270878583],
              [-81.63515699903212, 36.094203933770984],
              [-81.635207626747061, 36.093939108240797],
              [-81.63487524575639, 36.093898770338413],
              [-81.634976502322189, 36.093369119392086],
              [-81.63464412379868, 36.093328780851792],
              [-81.634745381138018, 36.092799129068013],
              [-81.635077757556687, 36.092839468243028],
              [-81.635330888679235, 36.091515338606953],
              [-81.63499851762559, 36.091475000998031],
              [-81.635049144117232, 36.091210174326449],
              [-81.634716775573594, 36.09116983592277],
              [-81.634767402828999, 36.090905010234444],
              [-81.634435034575247, 36.090864671083942],
              [-81.634586917516415, 36.090070193213478],
              [-81.634254552796733, 36.090029853558264],
              [-81.634305180855065, 36.089765027933588],
              [-81.633972818615788, 36.089724686582734],
              [-81.634074075868128, 36.089195035447695],
              [-81.633741716125201, 36.089154694360012],
              [-81.633842974150923, 36.088625042387854],
              [-81.633510616874872, 36.088584700662452],
              [-81.633611875703323, 36.088055048754072],
              [-81.633279520864662, 36.08801470549006],
              [-81.633330151971492, 36.087749880019629],
              [-81.632665445456084, 36.08766919194705],
              [-81.632716077254031, 36.087404366716136],
              [-81.632383726687223, 36.087364021883779],
              [-81.632434359189787, 36.087099195834455],
              [-81.632102008912838, 36.087058850255161],
              [-81.632152643259602, 36.086794024264556],
              [-81.631820294382536, 36.086753677914515],
              [-81.631870928353464, 36.086488852030286],
              [-81.630873889110276, 36.08636780700796],
              [-81.63092452705925, 36.086102981448583],
              [-81.630592182757297, 36.086062631725625],
              [-81.630642820330252, 36.085797806272687],
              [-81.629978134829457, 36.085717103480583],
              [-81.630028774232898, 36.085452279144263],
              [-81.629364092197306, 36.085371573003144],
              [-81.629414734482211, 36.085106747957703],
              [-81.628750055911937, 36.085026038467646],
              [-81.62880069999774, 36.084761213637996],
              [-81.628136024892825, 36.084680500799003],
              [-81.628186670779499, 36.08441567618501],
              [-81.627854334778561, 36.084375318092548],
              [-81.6279049814286, 36.084110494462088],
              [-81.627572647937342, 36.08407013557504],
              [-81.627673942313535, 36.083540486627157],
              [-81.62800627367109, 36.08358084524788],
              [-81.628056919293044, 36.083316020565043],
              [-81.628389251026547, 36.083356378125103],
              [-81.628591824837542, 36.082297079281325],
              [-81.628259498481341, 36.082256722230007],
              [-81.6284114282453, 36.081462247296976],
              [-81.62807910545169, 36.08142189064219],
              [-81.628231035420256, 36.080627415654646],
              [-81.628563355042928, 36.080667772810969],
              [-81.628664638601222, 36.08013812138401],
              [-81.628996955313355, 36.080178477394256],
              [-81.629148873888525, 36.079384000851711],
              [-81.629481188813685, 36.079424354634],
              [-81.629582463696195, 36.078894703095408],
              [-81.629250150904511, 36.078854349579366],
              [-81.629300789438759, 36.078589522954665],
              [-81.628636168067757, 36.078508812552776],
              [-81.628686808432036, 36.078243987045035],
              [-81.62835449874278, 36.078203630183161],
              [-81.628405139841021, 36.077938804758084],
              [-81.627740525814289, 36.077858088541724],
              [-81.627791167603149, 36.077593263356398],
              [-81.627126557070596, 36.077512544692468],
              [-81.627177201740309, 36.077247718798297],
              [-81.626180291623385, 36.077126633973705],
              [-81.626230939189597, 36.076861809329593],
              [-81.623904835742337, 36.07657924524927],
              [-81.623955491522679, 36.076314420562255],
              [-81.622958597763272, 36.076193308525305],
              [-81.623009256439886, 36.075928485088433],
              [-81.622676961362373, 36.075888112711453],
              [-81.6228289363453, 36.075093641644933],
              [-81.622496644771175, 36.075053267862927],
              [-81.622699279546325, 36.073993973196536],
              [-81.6223669925715, 36.07395359904374],
              [-81.622417650674521, 36.073688775627659],
              [-81.622749936553561, 36.073729148746416],
              [-81.622851250708081, 36.073199500572251],
              [-81.623515819198801, 36.073280243566053],
              [-81.623566474213192, 36.073015419575739],
              [-81.623898757891382, 36.073055789584053],
              [-81.624253320509851, 36.071202014927053],
              [-81.624585595945604, 36.07124238312322],
              [-81.624636245024789, 36.070977557587575],
              [-81.62496852083666, 36.07101792472313],
              [-81.625171109588152, 36.069958622446258],
              [-81.624838839151906, 36.069918255819573],
              [-81.624889486566801, 36.069653430032766],
              [-81.624557217529841, 36.069613062635604],
              [-81.624658513493969, 36.069083411177857],
              [-81.623993980684389, 36.069002674205528],
              [-81.624044630300034, 36.068737848667695],
              [-81.623712365460861, 36.068697478959415],
              [-81.623763015810155, 36.068432653504608],
              [-81.623430752370254, 36.068392283025844],
              [-81.623532055312566, 36.067862632208382],
              [-81.623199794338433, 36.067822261092516],
              [-81.623301098082052, 36.067292610340729],
              [-81.623633355813737, 36.067332981214101],
              [-81.623684006674722, 36.067068155617889],
              [-81.6240162636729, 36.067108525454458],
              [-81.624066912024972, 36.066843699698801],
              [-81.624399169399297, 36.066884068474799],
              [-81.624500462942862, 36.066354415645748],
              [-81.62416820770116, 36.066314047136018],
              [-81.624269502075464, 36.065784395273809],
              [-81.623604999075511, 36.065703656028973],
              [-81.623655647866741, 36.0654388293881],
              [-81.622991148330811, 36.065358086795513],
              [-81.623041798951178, 36.065093261271862],
              [-81.622709550748809, 36.065052888753527],
              [-81.622760203182864, 36.064788062388246],
              [-81.621431217937996, 36.064626562911435],
              [-81.621481873224056, 36.064361737953675],
              [-81.62048514208675, 36.064240604009775],
              [-81.620535801319264, 36.06397577847688],
              [-81.619871318080826, 36.063895018855845],
              [-81.619921978003134, 36.063630193563107],
              [-81.619589738504359, 36.063589812518678],
              [-81.61984304298042, 36.062265688577547],
              [-81.62017527714805, 36.062306068955834],
              [-81.620327252202173, 36.061511593780601],
              [-81.619995021203891, 36.061471212901083],
              [-81.620045680335338, 36.061206388168287],
              [-81.619713450736114, 36.061166006518519],
              [-81.619814768993862, 36.060636356292108],
              [-81.62014699643143, 36.06067673677439],
              [-81.620298968692254, 36.059882261098551],
              [-81.619966744423891, 36.059841880115044],
              [-81.62027068626729, 36.058252925510303],
              [-81.61993846875778, 36.058212545323698],
              [-81.619989125997733, 36.057947719356513],
              [-81.619656910996838, 36.057907338375919],
              [-81.619707567860488, 36.057642512515777],
              [-81.619043140960173, 36.057561747209817],
              [-81.619245776943274, 36.056502444264652],
              [-81.618913568242149, 36.056462060339094],
              [-81.619014885813783, 36.055932408845287],
              [-81.618682680716333, 36.055892025159999],
              [-81.618935974676077, 36.054567895305752],
              [-81.618603775241581, 36.05452751138327],
              [-81.618705092388026, 36.053997858823116],
              [-81.618372895418361, 36.053957474263846],
              [-81.61847421336536, 36.053427821770654],
              [-81.618142018860496, 36.053387436574518],
              [-81.618243338717377, 36.052857784124662],
              [-81.617911146648012, 36.052817397390754],
              [-81.618113785551245, 36.051758093350841],
              [-81.617781598078395, 36.05171770624662],
              [-81.61783225832265, 36.051452880464609],
              [-81.61750007224866, 36.051412492590252],
              [-81.617702714102293, 36.050353187695407],
              [-81.618034894803259, 36.050393575060475],
              [-81.61838949442857, 36.048539788265849],
              [-81.618057321188601, 36.048499401833723],
              [-81.618107978504668, 36.048234575587635],
              [-81.617775806663246, 36.048194188385395],
              [-81.617826464683148, 36.04792936132197],
              [-81.618158635458641, 36.047969748390834],
              [-81.618209290970299, 36.047704921168084],
              [-81.618541461012811, 36.047745307200472],
              [-81.618592115125935, 36.047480479794729],
              [-81.619256454048454, 36.047561247982095],
              [-81.61930710569716, 36.047296420260182],
              [-81.619971443848485, 36.047377185468847],
              [-81.620022093032787, 36.047112357430592],
              [-81.62035426040309, 36.047152737669649],
              [-81.62040490929823, 36.046887909424669],
              [-81.620737075964783, 36.046928289528019],
              [-81.620838368406396, 36.046398632670133],
              [-81.621170534383495, 36.046439011579906],
              [-81.621221179039395, 36.046174182943147],
              [-81.620889014128096, 36.04613380416658],
              [-81.620990304573155, 36.045604147010572],
              [-81.620658143264819, 36.045563768474317],
              [-81.620708789054078, 36.045298939954975],
              [-81.620376629114958, 36.045258559747836],
              [-81.620477921826762, 36.044728902826762],
              [-81.620810079634353, 36.044769282767398],
              [-81.620860724425398, 36.044504454099062],
              [-81.620528567683451, 36.044464074291682],
              [-81.620629858398829, 36.043934417072606],
              [-81.620297704121171, 36.043894036628451],
              [-81.620500285792687, 36.042834721226775],
              [-81.620832435807614, 36.042875101137959],
              [-81.620933721391609, 36.042345442446184],
              [-81.620601574646813, 36.042305063678704],
              [-81.620753505403343, 36.041510576083617],
              [-81.620421361049637, 36.041470195935453],
              [-81.620472005045158, 36.041205367071456],
              [-81.62080414719459, 36.041245746209206],
              [-81.62085478979175, 36.040980917162372],
              [-81.620522648707976, 36.040940538157876],
              [-81.620623935006108, 36.04041087928119],
              [-81.620291796357009, 36.040370498739051],
              [-81.620393083455681, 36.039840839930548],
              [-81.620060947270531, 36.039800458751742],
              [-81.620162235169616, 36.039270800011543],
              [-81.61983010147766, 36.039230419097024],
              [-81.619982034013091, 36.038435930564496],
              [-81.619649903821312, 36.03839554824571],
              [-81.619751192655727, 36.037865889543177],
              [-81.619419064927683, 36.037825506587772],
              [-81.619570998866095, 36.037031018112081],
              [-81.619238875776745, 36.036990634629447],
              [-81.618906753049387, 36.036950251144681],
              [-81.618957399151412, 36.036685421520012],
              [-81.617628915743211, 36.036523876379583],
              [-81.617679565774679, 36.036259047238538],
              [-81.617015328183257, 36.036178269065594],
              [-81.616964676020885, 36.036443097940037],
              [-81.615968318933028, 36.036321923507181],
              [-81.616018974291876, 36.036057095032618],
              [-81.615022624504633, 36.035935912847727],
              [-81.615073282756271, 36.03567108562455],
              [-81.613744824895164, 36.035509496374409],
              [-81.613694161301552, 36.035774323988988],
              [-81.613362047442067, 36.035733924492725],
              [-81.613412712101137, 36.035469097011543],
              [-81.613080598530672, 36.03542869676923],
              [-81.613131263922369, 36.035163869371864],
              [-81.612799152859409, 36.035123468336039],
              [-81.612900484775182, 36.03459381365947],
              [-81.612568375066587, 36.034553412011],
              [-81.612619042699876, 36.03428858470815],
              [-81.612286934389601, 36.0342481822899],
              [-81.612337602755488, 36.033983355070937],
              [-81.612005495843448, 36.033942951882921],
              [-81.612056164941905, 36.033678124747851],
              [-81.61172405939891, 36.03363771988915],
              [-81.611774729229793, 36.033372892837974],
              [-81.611110522440711, 36.033292082456093],
              [-81.611161194069595, 36.033027255622187],
              [-81.610829092765115, 36.032986848297213],
              [-81.610879764017312, 36.032722021570947],
              [-81.610547664140228, 36.032681614377182],
              [-81.60988346646468, 36.03260079635573],
              [-81.609934140579966, 36.03233596998011],
              [-81.609269946366567, 36.032255148613096],
              [-81.609371298969265, 36.031725496223444],
              [-81.610035488921341, 36.031806317056912],
              [-81.610238181580598, 36.030747009716926],
              [-81.610570273363777, 36.030787418684241],
              [-81.610620944083863, 36.030522591829552],
              [-81.610953035105595, 36.030562998859516],
              [-81.611054373387404, 36.030033343834248],
              [-81.611386462640553, 36.030073750595399],
              [-81.611437130202305, 36.029808922424856],
              [-81.611690464245186, 36.028484784412093],
              [-81.611358382492909, 36.028444378427373],
              [-81.611459715449797, 36.027914722612508],
              [-81.61112763729912, 36.027874316868726],
              [-81.611178304329002, 36.027609488570192],
              [-81.610514148938123, 36.027528674687645],
              [-81.610564818874778, 36.027263846582876],
              [-81.609900668025617, 36.027183028430393],
              [-81.609951338679892, 36.026918201467645],
              [-81.608955117528794, 36.026796967427828],
              [-81.609005792125672, 36.026532139891351],
              [-81.608673720898366, 36.02649172745695],
              [-81.608724395118529, 36.026226900028249],
              [-81.608060255763633, 36.026146071816356],
              [-81.608110931781283, 36.025881244605081],
              [-81.607778863682739, 36.025840829728565],
              [-81.607829541541818, 36.025576002577694],
              [-81.60749747481222, 36.025535586030578],
              [-81.607548153432703, 36.025270759864867],
              [-81.606884023102424, 36.025189924328572],
              [-81.606934702411181, 36.024925098403969],
              [-81.606602639919572, 36.024884679391143],
              [-81.60665332104054, 36.02461985262606],
              [-81.606321258837738, 36.024579432867348],
              [-81.606371940720052, 36.024314607087362],
              [-81.606039879915329, 36.024274186559104],
              [-81.606090562500725, 36.024009359962307],
              [-81.60575850309398, 36.023968938664432],
              [-81.605809186411491, 36.02370411215189],
              [-81.60414890194393, 36.023501993639101],
              [-81.604199591362956, 36.023237167720787],
              [-81.603867536083797, 36.023196741272073],
              [-81.603918226235052, 36.022931915437866],
              [-81.603586172353872, 36.022891488219727],
              [-81.603636863237242, 36.022626662469747],
              [-81.603304811863097, 36.022586234458458],
              [-81.603355502398486, 36.022321409717264],
              [-81.602359352330595, 36.022200119788785],
              [-81.602410046808174, 36.02193529447414],
              [-81.602077998895837, 36.021894863118071],
              [-81.60212869410546, 36.021630037887626],
              [-81.601796647591073, 36.021589605762024],
              [-81.602050121751631, 36.020265479586541],
              [-81.602382162941112, 36.020305911044844],
              [-81.602432856153072, 36.020041085519338],
              [-81.60210081602844, 36.020000654194519],
              [-81.602202202474388, 36.019471003286398],
              [-81.60187016592171, 36.019430571301946],
              [-81.601971553136693, 36.018900919563137],
              [-81.601639519046728, 36.018860486942728],
              [-81.59898325603784, 36.018536991721149],
              [-81.599033959299106, 36.018272167417983],
              [-81.59870192924177, 36.018231725904215],
              [-81.598752633234909, 36.017966901685398],
              [-81.598420604604513, 36.017926460303208],
              [-81.598471309300393, 36.017661635267686],
              [-81.598139282067862, 36.017621193116234],
              [-81.59818998863372, 36.017356369042453],
              [-81.597857962770021, 36.017315925220672],
              [-81.597908668958738, 36.01705110125485],
              [-81.597576644492804, 36.017010656663736],
              [-81.597627352522423, 36.016745832758687],
              [-81.596963306748407, 36.016664942035803],
              [-81.597014016545558, 36.016400117447709],
              [-81.596681995237461, 36.016359671316039],
              [-81.596834124774134, 36.015565199483227],
              [-81.596502106964479, 36.015524751948263],
              [-81.596603526740779, 36.014995103788777],
              [-81.5962715125317, 36.014954656495426],
              [-81.596372933105371, 36.014425008406647],
              [-81.595708909887364, 36.014344109843648],
              [-81.595759622373109, 36.014079286431397],
              [-81.595427612328393, 36.014038835929206],
              [-81.595478325516936, 36.013774011700455],
              [-81.595146316899005, 36.013733561329907],
              [-81.595197029710292, 36.013468737209259],
              [-81.594865022461136, 36.013428285168445],
              [-81.59491573714196, 36.013163462009665],
              [-81.594583731290584, 36.013123009199617],
              [-81.594634446674178, 36.012858185224381],
              [-81.594302443329497, 36.012817731621517],
              [-81.594403874146892, 36.012288084715429],
              [-81.594735876471105, 36.012328538027589],
              [-81.594786590855648, 36.012063713905235],
              [-81.595118591339286, 36.012104166204672],
              [-81.595169304325907, 36.011839341899716],
              [-81.59583330527191, 36.011920243499731],
              [-81.595884015796244, 36.011655418878725],
              [-81.596216015120575, 36.01169586730191],
              [-81.596266724276106, 36.011431043399334],
              [-81.596598722868848, 36.011471490786178],
              [-81.596700136913853, 36.0109418416897],
              [-81.596368140421447, 36.010901393668924],
              [-81.596418847440177, 36.010636568742001],
              [-81.596086852345536, 36.010596119951963],
              [-81.596137560095841, 36.010331295109545],
              [-81.595805566427842, 36.010290846451198],
              [-81.596059108834737, 36.008966722101718],
              [-81.596391096070789, 36.009007170116043],
              [-81.596441802932063, 36.008742344956275],
              [-81.59710577744066, 36.008823239788001],
              [-81.597156481810813, 36.008558413411407],
              [-81.59682449492918, 36.008517967493127],
              [-81.596875198951139, 36.00825314212566],
              [-81.597207184768081, 36.008293587910323],
              [-81.597409993238955, 36.00723428451596],
              [-81.597741975159508, 36.007274729764823],
              [-81.597792675387552, 36.007009903885752],
              [-81.597460695640436, 36.006969458746738],
              [-81.597612797491593, 36.006174980315627],
              [-81.597280821300103, 36.006134535575413],
              [-81.597534323010919, 36.004810403345552],
              [-81.597202352475009, 36.004769958370204],
              [-81.597303752963654, 36.004240305583515],
              [-81.59630785079122, 36.004118964217191],
              [-81.596257147505312, 36.00438378978388],
              [-81.595261245135802, 36.004262439892919],
              [-81.595210537214768, 36.004527265033836],
              [-81.594878569009353, 36.004486813739724],
              [-81.59492927796596, 36.004221987831478],
              [-81.594597311129078, 36.0041815348673],
              [-81.594648020846194, 36.003916709944662],
              [-81.594979985509767, 36.003957162798855],
              [-81.595132108302963, 36.003162685558948],
              [-81.595464070106132, 36.0032031371098],
              [-81.59561618560069, 36.002408659053472],
              [-81.595284226990842, 36.002368207903238],
              [-81.59543634265313, 36.00157372890731],
              [-81.5957682980698, 36.001614179656933],
              [-81.595869705116399, 36.001084527375134],
              [-81.596201658708083, 36.001124976054143],
              [-81.596556561480668, 35.999271188426953],
              [-81.596888507954574, 35.999311635268576],
              [-81.597091296832602, 35.998252325955491],
              [-81.596759355695696, 35.99821187872346],
              [-81.596810053424534, 35.997947051400999],
              [-81.596478113684924, 35.997906603399819],
              [-81.596528812145195, 35.99764177616224],
              [-81.596196873802697, 35.997601327392026],
              [-81.596247572994386, 35.997336500239285],
              [-81.595251763186283, 35.997215149814714],
              [-81.595302465237978, 35.996950323013913],
              [-81.594638595806373, 35.996869417884454],
              [-81.594689299624747, 35.996604590401041],
              [-81.594357366501569, 35.996564137516984],
              [-81.594408072159922, 35.996299310094898],
              [-81.591420700568108, 35.995935186569092],
              [-81.591471415471844, 35.995670360300537],
              [-81.591139488886341, 35.995629897733458],
              [-81.591190204550244, 35.995365072450674],
              [-81.590858278253151, 35.995324609138251],
              [-81.590908994619355, 35.995059783039451],
              [-81.590245147370098, 35.994978853927009],
              [-81.590295865560563, 35.994714028947769],
              [-81.589300099239097, 35.994592628033473],
              [-81.589350820260179, 35.994327802505353],
              [-81.58802314223837, 35.994165922787595],
              [-81.588073867183098, 35.99390109774523],
              [-81.587741949581599, 35.993860625918387],
              [-81.587843401738013, 35.993330976832461],
              [-81.587179571761723, 35.993250030104754],
              [-81.58728102795115, 35.992720381335317],
              [-81.586949115576402, 35.992679906434454],
              [-81.586999843141456, 35.9924150825845],
              [-81.586336022656809, 35.992334131219941],
              [-81.586386753096775, 35.992069306664099],
              [-81.586054843849411, 35.992028829323033],
              [-81.586105575049174, 35.99176400575319],
              [-81.585773668307624, 35.991723527619691],
              [-81.585824400238494, 35.991458704134963],
              [-81.585492493785438, 35.991418225256112],
              [-81.585543226418338, 35.99115340095544],
              [-81.584215611547847, 35.99099147778054],
              [-81.584317084355874, 35.990461831003586],
              [-81.583985183869018, 35.990421347989852],
              [-81.584289600625297, 35.98883240549322],
              [-81.58395770799298, 35.988791923256564],
              [-81.58405917969877, 35.988262275336339],
              [-81.583727288390108, 35.988221791586966],
              [-81.583778024807259, 35.987956967687758],
              [-81.583446136004241, 35.987916483146172],
              [-81.583496873152384, 35.987651659332101],
              [-81.583164985746322, 35.987611174021737],
              [-81.583266461171192, 35.987081526515318],
              [-81.58260269050561, 35.98700055374556],
              [-81.582653430954863, 35.986735730163453],
              [-81.581989663749624, 35.986654754051365],
              [-81.58204040599361, 35.986389930688183],
              [-81.581708524508755, 35.986349441400549],
              [-81.581759266374945, 35.986084618145924],
              [-81.581427386287217, 35.986044128089546],
              [-81.58147812999276, 35.985779304896695],
              [-81.581146251301945, 35.985738814071532],
              [-81.581196994629693, 35.985473990987401],
              [-81.580865117335961, 35.985433499393523],
              [-81.580915862503019, 35.985168676371181],
              [-81.580583986606186, 35.985128184008609],
              [-81.580685476931293, 35.984598537208264],
              [-81.581017350700307, 35.984639029303374],
              [-81.581068093759441, 35.98437420615906],
              [-81.581399966797719, 35.984414697218078],
              [-81.581450708459911, 35.98414987389156],
              [-81.582114452299621, 35.984230853058634],
              [-81.582215931448957, 35.983701204801172],
              [-81.582547801186436, 35.983741692775531],
              [-81.582598538657848, 35.983476868902649],
              [-81.582930408744247, 35.983517354916479],
              [-81.583082615939745, 35.982722881705364],
              [-81.582750750124504, 35.98268239516814],
              [-81.582801486263648, 35.982417571101578],
              [-81.582469621845192, 35.982377083795683],
              [-81.582520358686224, 35.982112258913439],
              [-81.582188495664724, 35.982071770838878],
              [-81.582239233265241, 35.981806946942882],
              [-81.581907371640582, 35.981766458099671],
              [-81.582008847912505, 35.981236808628026],
              [-81.581676988748384, 35.981196319149731],
              [-81.581727728585861, 35.980931495352635],
              [-81.581395870818653, 35.980891005105697],
              [-81.581497349347117, 35.980361355878536],
              [-81.58116549514898, 35.980320864973336],
              [-81.581368453601456, 35.979261567351003],
              [-81.581700303544764, 35.979302057721512],
              [-81.581751040977096, 35.979037232841968],
              [-81.582082891298157, 35.979077722152979],
              [-81.582184363065295, 35.978548072882546],
              [-81.582516210483504, 35.978588561047374],
              [-81.582566944789392, 35.978323735755495],
              [-81.582898791477021, 35.978364222884245],
              [-81.582949524386095, 35.978099397410425],
              [-81.583613216632926, 35.978180368693693],
              [-81.583663947081703, 35.977915542904185],
              [-81.583995792640877, 35.97795602705876],
              [-81.584046521692954, 35.977691201087225],
              [-81.584378366492572, 35.977731683304746],
              [-81.584581272899399, 35.976672378448072],
              [-81.584249432325549, 35.976631895864159],
              [-81.584300159712711, 35.976367069651282],
              [-81.583968320535462, 35.976326586298768],
              [-81.584120502781602, 35.975532107794869],
              [-81.583788667128374, 35.975491623941174],
              [-81.583890123131496, 35.974961970714681],
              [-81.583226458186871, 35.974881000811749],
              [-81.583277187830575, 35.974616174844016],
              [-81.582945356367134, 35.974575688684567],
              [-81.582996086712697, 35.974310861901301],
              [-81.582664257754246, 35.974270374949853],
              [-81.582714988859166, 35.974005549153027],
              [-81.58304681675412, 35.974046035970858],
              [-81.58314827470069, 35.973516383087656],
              [-81.583480100801452, 35.973556868735869],
              [-81.583683009553155, 35.972497561832888],
              [-81.583351187706711, 35.972457076719493],
              [-81.583401914308837, 35.972192250475018],
              [-81.583070094967283, 35.972151764569624],
              [-81.583120822271226, 35.971886937509687],
              [-81.583784461377022, 35.971967908104034],
              [-81.583835186220966, 35.971703080728638],
              [-81.58416700520975, 35.97174356453877],
              [-81.584217728686085, 35.971478737882428],
              [-81.584549546944288, 35.971519220656717],
              [-81.584600268995302, 35.971254392917544],
              [-81.584932086522997, 35.971294874655904],
              [-81.585033527499149, 35.970765218765798],
              [-81.585365343232624, 35.970805699334633],
              [-81.585416062157861, 35.970540871183658],
              [-81.585747876052451, 35.970581350740048],
              [-81.585798593581202, 35.970316522407316],
              [-81.586130407853545, 35.97035700090435],
              [-81.586181123985995, 35.970092172389812],
              [-81.586512936419268, 35.97013264987438],
              [-81.586665079628801, 35.969338163641083],
              [-81.586996889204542, 35.969378639822551],
              [-81.587098313111255, 35.968848981849334],
              [-81.587430120892904, 35.968889456861199],
              [-81.587632961566783, 35.967830139779565],
              [-81.587301158038983, 35.967789665302256],
              [-81.587351868715928, 35.967524836039466],
              [-81.587020066584486, 35.967484360793613],
              [-81.587121489066732, 35.966954702391128],
              [-81.587453287963001, 35.966995177393137],
              [-81.587605414862622, 35.966200688150515],
              [-81.587273619156591, 35.9661602135495],
              [-81.587375037173373, 35.965630553789062],
              [-81.587043243955762, 35.965590079454181],
              [-81.587093954068024, 35.965325249173389],
              [-81.586762161109505, 35.9652847731926],
              [-81.58681287198111, 35.965019943898369],
              [-81.586481081527097, 35.964979467125531],
              [-81.586430369620899, 35.96524429718707],
              [-81.585434997065661, 35.965122861054851],
              [-81.585485712190803, 35.964858032295304],
              [-81.584490345821294, 35.964736588444865],
              [-81.584541063774765, 35.964471759137375],
              [-81.584209276714049, 35.964431276183333],
              [-81.584259995426748, 35.964166447862532],
              [-81.583596425538744, 35.964085479491921],
              [-81.583545703591128, 35.964350307568786],
              [-81.582550347511742, 35.964228847857576],
              [-81.5826010715123, 35.963964019304285],
              [-81.582269288620424, 35.963923531047733],
              [-81.58242146298052, 35.963129047279558],
              [-81.582089683611514, 35.963088558521974],
              [-81.582140408406957, 35.962823730044192],
              [-81.581808630434139, 35.962783240518199],
              [-81.581859355959949, 35.962518412126158],
              [-81.581195803139593, 35.96243743063539],
              [-81.581246530459097, 35.962172602462822],
              [-81.58091475561146, 35.962132110497969],
              [-81.58096548476955, 35.961867282387693],
              [-81.579638391824545, 35.961705305164919],
              [-81.579587658413502, 35.961970132740277],
              [-81.578260567622209, 35.961808139624878],
              [-81.578209829596574, 35.962072965716288],
              [-81.577878055574175, 35.962032465522391],
              [-81.57792879574248, 35.961767638640268],
              [-81.576933482158765, 35.96164613207614],
              [-81.576984224075588, 35.961381305570711],
              [-81.576320685468275, 35.961300296651423],
              [-81.576371430286699, 35.961035470342253],
              [-81.576039662531443, 35.960994964212816],
              [-81.576090408108698, 35.960730138890369],
              [-81.575426875723622, 35.960649124192777],
              [-81.575477623094187, 35.960384299089945],
              [-81.574814094167621, 35.96030328105175],
              [-81.574763345807611, 35.960568106764569],
              [-81.574431580780725, 35.960527596258615],
              [-81.574380829916279, 35.960792421813053],
              [-81.573053769994956, 35.960630370210104],
              [-81.573003014544767, 35.96089519518145],
              [-81.572671249056881, 35.96085467989765],
              [-81.572569735002261, 35.961384328527956],
              [-81.572901502616403, 35.96142484407936],
              [-81.572596956620288, 35.963013789668366],
              [-81.572265182627007, 35.962973273314162],
              [-81.572214421978728, 35.963238096842232],
              [-81.571550872865032, 35.963157061159905],
              [-81.571500110894078, 35.963421885250078],
              [-81.570836559878288, 35.963340845715166],
              [-81.570785794310851, 35.963605668612047],
              [-81.570454018793569, 35.963565147346024],
              [-81.57040325182966, 35.963829970060985],
              [-81.569739699668389, 35.963748924554999],
              [-81.569688930244865, 35.964013746954372],
              [-81.569357152492429, 35.963973222737728],
              [-81.56930638167259, 35.964238044955231],
              [-81.568974604298504, 35.964197519679338],
              [-81.568923832082135, 35.964462341714963],
              [-81.568592053977952, 35.964421815403256],
              [-81.568388956419085, 35.965481102553106],
              [-81.568720739913203, 35.965521630277799],
              [-81.568669964922421, 35.965786452096452],
              [-81.568338181473436, 35.965745924214602],
              [-81.568236629473915, 35.966275567416609],
              [-81.567904843152036, 35.966235039289195],
              [-81.567650953667453, 35.967559145748481],
              [-81.567319161225413, 35.967518615172125],
              [-81.567217600307046, 35.9680482571653],
              [-81.566885807208678, 35.968007726296882],
              [-81.566784242801873, 35.968537366928864],
              [-81.566452447910166, 35.968496834890637],
              [-81.566401663574993, 35.968761654561682],
              [-81.566069867953274, 35.968721121487476],
              [-81.566019082250122, 35.96898594187752],
              [-81.56303292361639, 35.968621097973802],
              [-81.563083718917966, 35.968356279689417],
              [-81.562088342996034, 35.968234648894594],
              [-81.562037543424864, 35.96849946770152],
              [-81.561705751044911, 35.968458921864801],
              [-81.561451745030979, 35.969783012659214],
              [-81.561197730652736, 35.971107101347954],
              [-81.560865926864665, 35.971066553293504],
              [-81.560815121470512, 35.971331370401586],
              [-81.560764315771621, 35.971596188362511],
              [-81.560100706050733, 35.971515088988262],
              [-81.560049897862953, 35.971779905732191],
              [-81.559386286269529, 35.971698803405737],
              [-81.559335475621566, 35.971963619833645],
              [-81.557676447085868, 35.971760845307898],
              [-81.557625630816261, 35.972025661918899],
              [-81.556962019609941, 35.971944545525652],
              [-81.556911200851474, 35.972209360919685],
              [-81.556579394685329, 35.972168801235973],
              [-81.556528573421545, 35.972433616470944],
              [-81.556196766525616, 35.972393055751134],
              [-81.556145943864919, 35.972657870803992],
              [-81.555814136239192, 35.972617309048019],
              [-81.555661664174067, 35.973411753491888],
              [-81.555329853691731, 35.973371190431962],
              [-81.55527902757008, 35.973636005023863],
              [-81.554947216357974, 35.973595440927824],
              [-81.554896388839325, 35.973860255337513],
              [-81.554564575760509, 35.973819689327641],
              [-81.554513746844876, 35.974084503555069],
              [-81.554181934173243, 35.974043937386824],
              [-81.554131103860598, 35.974308751432019],
              [-81.55379929045921, 35.974268184227647],
              [-81.5536976255692, 35.974797811027301],
              [-81.553033994405453, 35.974716673399584],
              [-81.5529831599017, 35.974981486897903],
              [-81.551987712994119, 35.974859772375488],
              [-81.551936873829504, 35.975124584545874],
              [-81.551273241748916, 35.975043437360462],
              [-81.55122240012382, 35.975308249214571],
              [-81.550890584074935, 35.975267674123018],
              [-81.550941426734923, 35.975002861501977],
              [-81.550609610974718, 35.974962285665406],
              [-81.550558768331285, 35.975227097228405],
              [-81.55022695184131, 35.975186520355741],
              [-81.550277796628066, 35.974921708002583],
              [-81.549614167856191, 35.974840550894477],
              [-81.549563320942667, 35.975105362979598],
              [-81.549231506533843, 35.975064782476288],
              [-81.549180658223307, 35.975329594379154],
              [-81.548848841976508, 35.975289012862731],
              [-81.548797992268732, 35.975553824583294],
              [-81.548134359786474, 35.975472659453899],
              [-81.548083507589709, 35.975737469957117],
              [-81.547419874315906, 35.975656300951037],
              [-81.547470729719407, 35.975391489791789],
              [-81.545811658232978, 35.975188551696725],
              [-81.54576079748422, 35.975453361284913],
              [-81.545428983557073, 35.975412770455776],
              [-81.545378121439541, 35.975677580762664],
              [-81.544714491351598, 35.975596396152881],
              [-81.544663626773499, 35.975861206143264],
              [-81.544331811721065, 35.975820612339589],
              [-81.544280945717432, 35.976085421246594],
              [-81.543617313379102, 35.9760042306877],
              [-81.543566444943266, 35.976269040179325],
              [-81.542570996304093, 35.976147247161151],
              [-81.542520123207353, 35.976412055324339],
              [-81.541524674383254, 35.976290253784477],
              [-81.541473797762521, 35.976555061497145],
              [-81.540810164386627, 35.976473855699339],
              [-81.540759286413703, 35.97673866307241],
              [-81.540095652246791, 35.976657453397834],
              [-81.539432019387476, 35.976576239213699],
              [-81.539482902722156, 35.976311432353832],
              [-81.539151088396864, 35.976270823580528],
              [-81.539201972460987, 35.97600601680643],
              [-81.538870158452809, 35.975965408189055],
              [-81.53881927332543, 35.976230214829151],
              [-81.537492019648298, 35.976067768930974],
              [-81.53754291013739, 35.975802962804195],
              [-81.536547476285435, 35.975681118865566],
              [-81.536598369630411, 35.975416313092701],
              [-81.536266560437824, 35.975375696468326],
              [-81.536317454512144, 35.975110890781188],
              [-81.535985646716796, 35.975070273388809],
              [-81.536036540411928, 35.97480546781042],
              [-81.53504112335682, 35.974683611500701],
              [-81.535092020987832, 35.974418805352379],
              [-81.534760216683623, 35.974378185520628],
              [-81.534811115043823, 35.974113379457982],
              [-81.53447931210863, 35.974072757957309],
              [-81.534530211226226, 35.973807952881486],
              [-81.534198409688329, 35.973767330612766],
              [-81.534249309506876, 35.973502524721646],
              [-81.533917510502917, 35.973461902562995],
              [-81.533968411050623, 35.973197096757758],
              [-81.533636612307333, 35.973156472953079],
              [-81.533738414555145, 35.972626862366738],
              [-81.533406619380656, 35.972586237905233],
              [-81.533457521052554, 35.972321432223261],
              [-81.533125727275419, 35.972280806993872],
              [-81.533176629704613, 35.972016002298595],
              [-81.531849462189669, 35.971853492896642],
              [-81.53190036850917, 35.971588687788717],
              [-81.530904999570623, 35.97146679666794],
              [-81.530955909882124, 35.971201992792125],
              [-81.530624121967321, 35.971161360748489],
              [-81.530675031871198, 35.970896556080604],
              [-81.530343246462039, 35.970855923246191],
              [-81.530394157095003, 35.970591118664089],
              [-81.529730588296971, 35.970509850580278],
              [-81.529781500721938, 35.970245046218238],
              [-81.529117935358357, 35.970163773893645],
              [-81.529168850683845, 35.969898969728689],
              [-81.528505288811331, 35.969817694965286],
              [-81.528454371359643, 35.970082498861814],
              [-81.527127247344168, 35.969919937072625],
              [-81.527076325306041, 35.970184740384106],
              [-81.526744544607524, 35.970144096860331],
              [-81.526693621172015, 35.970408899989309],
              [-81.526361838664556, 35.970368256353233],
              [-81.526310913831736, 35.970633059299779],
              [-81.525979130567194, 35.970592413726443],
              [-81.525877276664403, 35.971122019229099],
              [-81.525545492716191, 35.971081372462301],
              [-81.525494563646177, 35.971346175018425],
              [-81.525162777889037, 35.971305528139375],
              [-81.525111848501766, 35.971570329589007],
              [-81.524780062015651, 35.971529681673665],
              [-81.524729130150916, 35.971794483864748],
              [-81.524397344016265, 35.971753833989155],
              [-81.524295477129456, 35.972283437056781],
              [-81.523963688122549, 35.9722427869347],
              [-81.523912753100461, 35.972507587811236],
              [-81.523580964473197, 35.972466936629949],
              [-81.523530028053784, 35.972731737323976],
              [-81.523198237589213, 35.972691085129227],
              [-81.523147299772276, 35.972955885640694],
              [-81.522815508578972, 35.972915232409747],
              [-81.522764569364568, 35.97318003273854],
              [-81.52210098471808, 35.973098722423771],
              [-81.522050043043095, 35.973363522435811],
              [-81.521718250726508, 35.973322866230191],
              [-81.521616364275488, 35.973852466741562],
              [-81.521284569030286, 35.973811808487277],
              [-81.52123362422607, 35.974076608085589],
              [-81.520901829360511, 35.974035948772126],
              [-81.520850882078506, 35.974300749111755],
              [-81.520519086484171, 35.974260088761888],
              [-81.520468138884908, 35.974524887994995],
              [-81.520136341481518, 35.974484227532663],
              [-81.520085392484717, 35.974749026583126],
              [-81.519753594324428, 35.974708364183527],
              [-81.519651692092779, 35.975237961893541],
              [-81.519319892168795, 35.975197299224341],
              [-81.519268940042352, 35.975462097860998],
              [-81.518937139361469, 35.975421433254461],
              [-81.518835229762175, 35.975951030159727],
              [-81.518171626259374, 35.97586969768038],
              [-81.518120668831671, 35.976134495791875],
              [-81.517788865992685, 35.976093828977255],
              [-81.51773790716733, 35.976358626905956],
              [-81.517406104679978, 35.976317958131133],
              [-81.517355143348624, 35.976582755900026],
              [-81.517023340132454, 35.976542086088756],
              [-81.516870453158646, 35.977336478656063],
              [-81.515543227527345, 35.977173787946363],
              [-81.515492259542029, 35.977438584849956],
              [-81.515160452637133, 35.977397910013984],
              [-81.515109483254008, 35.977662706734804],
              [-81.513782255807641, 35.977499996908747],
              [-81.513731281837167, 35.977764793043896],
              [-81.513399474478689, 35.977724113428721],
              [-81.513348499082483, 35.977988908480071],
              [-81.513016690995286, 35.97794822782857],
              [-81.512914737128781, 35.978477819319046],
              [-81.512582927221786, 35.978437136595815],
              [-81.512531948723392, 35.97870193213393],
              [-81.512200138115858, 35.978661249275298],
              [-81.51214915819159, 35.978926043729665],
              [-81.511817345746948, 35.978885359857308],
              [-81.51176636445291, 35.979150155029835],
              [-81.511102740629909, 35.979068783363637],
              [-81.511051755766388, 35.979333578241743],
              [-81.510388131155594, 35.979252202698468],
              [-81.510337143802872, 35.979516996358406],
              [-81.510005330950236, 35.979476307549724],
              [-81.509954343308152, 35.979741101003995],
              [-81.509290715343838, 35.97965971953348],
              [-81.509239724160253, 35.979924513594284],
              [-81.508907910157077, 35.97988382090967],
              [-81.508856917575585, 35.980148614787524],
              [-81.508525102843905, 35.980107921066434],
              [-81.508474108836452, 35.980372713860369],
              [-81.507810477170324, 35.980291324366924],
              [-81.507759480729746, 35.980556117744634],
              [-81.507095848248028, 35.980474723472931],
              [-81.507044849346158, 35.980739516533305],
              [-81.506049400498767, 35.98061741794541],
              [-81.505998398072578, 35.980882210554185],
              [-81.505666580657419, 35.980841509101488],
              [-81.505564571537661, 35.981371093026226],
              [-81.505232753439259, 35.98133039037981],
              [-81.505181746745677, 35.981595181695809],
              [-81.504849926810365, 35.981554478035726],
              [-81.504747911428964, 35.98208406113136],
              [-81.504416089701976, 35.982043356300302],
              [-81.504314070826879, 35.982572938031801],
              [-81.503982247308102, 35.982532232029925],
              [-81.50388022388664, 35.983061814221799],
              [-81.503548398576257, 35.983021107048927],
              [-81.503446371660957, 35.983550687876544],
              [-81.50311454455877, 35.983509979532833],
              [-81.503012514177541, 35.984039559896985],
              [-81.502680685283735, 35.983998850382264],
              [-81.50262966741893, 35.984263640379723],
              [-81.502297837796704, 35.984222929828519],
              [-81.502093762061421, 35.985282088725675],
              [-81.501761928492925, 35.985241375833738],
              [-81.501659885934956, 35.985770954732722],
              [-81.501328049494063, 35.985730241593529],
              [-81.501277027203699, 35.985995030824256],
              [-81.500945190034386, 35.985954316648396],
              [-81.500894166317849, 35.986219104795012],
              [-81.500562328420131, 35.986178389582655],
              [-81.500460276802954, 35.98670796728554],
              [-81.500128437113489, 35.986667250902094],
              [-81.500077409156873, 35.986932038656427],
              [-81.499745569847633, 35.986891321213776],
              [-81.499694540520636, 35.987156109685849],
              [-81.499030859643796, 35.987074670946875],
              [-81.498979826746535, 35.987339459124087],
              [-81.49864798628758, 35.987298737805069],
              [-81.498596953100687, 35.987563525776402],
              [-81.498265110832577, 35.987522804344515],
              [-81.49821407621944, 35.987787591231701],
              [-81.497882233222967, 35.987746868763118],
              [-81.497831197211397, 35.988011655467083],
              [-81.497499353486646, 35.98797093196189],
              [-81.497397277251125, 35.988500505878449],
              [-81.497065431734669, 35.988459781202195],
              [-81.497014392591311, 35.988724567490969],
              [-81.496682546346534, 35.988683841778105],
              [-81.496580463819441, 35.989213413963277],
              [-81.496248615782932, 35.989172687079254],
              [-81.496197572399083, 35.989437472975538],
              [-81.495865723634253, 35.989396745054833],
              [-81.495814678851943, 35.989661530767854],
              [-81.495482829358778, 35.989620801810602],
              [-81.49543178317802, 35.989885587340318],
              [-81.495099932928667, 35.989844856445409],
              [-81.495048885377415, 35.990109642692914],
              [-81.49471703439967, 35.990068910761273],
              [-81.494665985422046, 35.990333695924491],
              [-81.494334133743862, 35.99029296385725],
              [-81.494283083367748, 35.990557748837212],
              [-81.493619377753461, 35.990476280848753],
              [-81.493568324915444, 35.990741065510967],
              [-81.492572766357711, 35.990618855896464],
              [-81.492521709994278, 35.990883640106397],
              [-81.492853563214908, 35.990924377989131],
              [-81.492700394195325, 35.991718730751806],
              [-81.492368537784571, 35.991677992465704],
              [-81.492317478971842, 35.991942776502796],
              [-81.491985620724151, 35.991902037202628],
              [-81.491883501075094, 35.992431604838778],
              [-81.491551642172226, 35.99239086524571],
              [-81.491398456412256, 35.993185215040135],
              [-81.491066593545554, 35.993144474164083],
              [-81.491015529503585, 35.99340925660352],
              [-81.490683665908634, 35.993368514690658],
              [-81.490581535828781, 35.993898080032238],
              [-81.490249670414201, 35.993857336047192],
              [-81.490198603267572, 35.994122118971916],
              [-81.489866737124856, 35.994081373950188],
              [-81.489815668579439, 35.994346156691435],
              [-81.489151935172686, 35.994264663672205],
              [-81.489100863056464, 35.994529446118186],
              [-81.48876899579254, 35.994488698120755],
              [-81.488717923386218, 35.994753480360693],
              [-81.488386055394074, 35.994712731326416],
              [-81.488334981561295, 35.994977512481945],
              [-81.488003112840872, 35.994936762410894],
              [-81.487952036528313, 35.995201544306575],
              [-81.487620167079896, 35.995160793198828],
              [-81.487569089340752, 35.995425574009943],
              [-81.486905349322569, 35.995344068818738],
              [-81.486854269149035, 35.995608850212918],
              [-81.4865223985516, 35.99556809522835],
              [-81.485526787746153, 35.995445827586835],
              [-81.485475704064243, 35.995710607470379],
              [-81.485143832663795, 35.995669849665923],
              [-81.484811962707369, 35.995629090936546],
              [-81.484760875454512, 35.995893870524625],
              [-81.483765263270669, 35.995771587641151],
              [-81.483714172519768, 35.996036367677625],
              [-81.48338230175014, 35.99599560416921],
              [-81.48322902315806, 35.996789942702527],
              [-81.482897148424641, 35.9967491779108],
              [-81.482794959622453, 35.997278736103546],
              [-81.482131206935819, 35.997197204176103],
              [-81.482080109890774, 35.997461982479308],
              [-81.481084481308656, 35.997339677845346],
              [-81.481033380737586, 35.997604455695942],
              [-81.480037751956402, 35.997482141636929],
              [-81.479986646778414, 35.997746919958288],
              [-81.478991017798435, 35.997624596474232],
              [-81.478939909094379, 35.997889374342712],
              [-81.478608033114455, 35.997848597893942],
              [-81.478556921875025, 35.998113374700402],
              [-81.477561291676778, 35.997991040460171],
              [-81.477510176911352, 35.998255816813796],
              [-81.476514545406118, 35.998133473170917],
              [-81.47618266964794, 35.998092690137085],
              [-81.476233787558613, 35.99782791434437],
              [-81.475570039176048, 35.997746345839332],
              [-81.475621158878084, 35.9974815702668],
              [-81.475289286253584, 35.997440784795543],
              [-81.47534040779226, 35.997176009286115],
              [-81.47500853656679, 35.997135223047216],
              [-81.475059657696903, 35.996870446744914],
              [-81.474395918379841, 35.996788871829636],
              [-81.474447042437532, 35.996524096626082],
              [-81.474115174345826, 35.996483307949738],
              [-81.474166299131326, 35.996218532831769],
              [-81.473502566053938, 35.996136952140589],
              [-81.473553692630645, 35.995872177242923],
              [-81.473221827672603, 35.995831386129154],
              [-81.473324083027151, 35.9953018355327],
              [-81.472992220531495, 35.995261043785945],
              [-81.473043348228117, 35.994996269010606],
              [-81.472711487103851, 35.994955475595297],
              [-81.472762616636942, 35.994690700883197],
              [-81.472430756939275, 35.994649907601421],
              [-81.472481886063861, 35.994385132096347],
              [-81.471486314364483, 35.994262746295803],
              [-81.471435180941114, 35.994527521419279],
              [-81.470771465647701, 35.994445925812478],
              [-81.470720330843108, 35.994710699694302],
              [-81.469724756689828, 35.994588299575291],
              [-81.469775895793148, 35.994323526075071],
              [-81.467452910430168, 35.994037893624579],
              [-81.467504056613336, 35.993773120117183],
              [-81.466840352612081, 35.993691503104941],
              [-81.46689150061367, 35.993426730718973],
              [-81.466227800054426, 35.99334510946646],
              [-81.466278950955527, 35.99308033727857],
              [-81.465947102242808, 35.993039525433701],
              [-81.465998252735176, 35.992774752452988],
              [-81.465002711828873, 35.992652312810733],
              [-81.465053866284038, 35.992387540162767],
              [-81.463394644562484, 35.992183456099916],
              [-81.463445803997971, 35.991918684076666],
              [-81.461786597068894, 35.991714577210523],
              [-81.461735431208638, 35.991979348582412],
              [-81.461403590404785, 35.991938523453669],
              [-81.461454756247136, 35.99167375313985],
              [-81.461122916869925, 35.991632928144718],
              [-81.461225249645892, 35.991103386738871],
              [-81.460893412703328, 35.991062560210061],
              [-81.461098079586421, 35.990003477327811],
              [-81.460766247259954, 35.989962651336995],
              [-81.460817413913304, 35.989697880183961],
              [-81.460485582985839, 35.989657053425802],
              [-81.460536751475132, 35.989392282336375],
              [-81.459873092726554, 35.989310625482204],
              [-81.459924261925082, 35.989045855536673],
              [-81.459592434103968, 35.989005025440754],
              [-81.459643605110912, 35.988740254657763],
              [-81.45931177871627, 35.98869942469554],
              [-81.459414120740206, 35.988169883274871],
              [-81.459082297916268, 35.988129052657719],
              [-81.459184640722896, 35.987599511311089],
              [-81.459235811068041, 35.987334740575612],
              [-81.459567631838851, 35.987375571667116],
              [-81.459669970504166, 35.986846029757665],
              [-81.460001788348663, 35.98688685879879],
              [-81.460155290084273, 35.986092545175303],
              [-81.46048710618399, 35.9861333728877],
              [-81.460589436130192, 35.98560382997313],
              [-81.460921249331037, 35.985644656536358],
              [-81.461177064085518, 35.984320796798706],
              [-81.461508872307093, 35.984361621785951],
              [-81.461713512922032, 35.983302532416261],
              [-81.461381708924932, 35.983261707066987],
              [-81.46158634841936, 35.982202617456139],
              [-81.461918147028527, 35.982243441387595],
              [-81.461969304979192, 35.981978668052477],
              [-81.462301102861176, 35.982019490947174],
              [-81.462352259440522, 35.981754718329611],
              [-81.463015855194513, 35.981836361121118],
              [-81.463169315377044, 35.981042040387841],
              [-81.463501110014576, 35.981082860037489],
              [-81.463552260953662, 35.980818086843072],
              [-81.463884054863911, 35.980858905455975],
              [-81.46393520548547, 35.980594131154817],
              [-81.464266998668492, 35.98063494873098],
              [-81.464318146782901, 35.980370174268728],
              [-81.464649939238683, 35.980410990808082],
              [-81.464854525223686, 35.979351892811771],
              [-81.465518102639123, 35.979433522106767],
              [-81.465569245886329, 35.979168746996827],
              [-81.465901032926169, 35.979209560179036],
              [-81.465952174774827, 35.978944784885726],
              [-81.466283962195831, 35.978985597008752],
              [-81.466335102646056, 35.978720821532029],
              [-81.466998675260683, 35.978802442825149],
              [-81.467049813249503, 35.978537667030338],
              [-81.468708745871638, 35.978741702892606],
              [-81.468759878210278, 35.978476926375549],
              [-81.469423451244211, 35.978558533601067],
              [-81.469474581121474, 35.97829375676595],
              [-81.469806366538478, 35.978334559352781],
              [-81.469857493881207, 35.978069781455766],
              [-81.470521065812932, 35.978151383609145],
              [-81.47057219072191, 35.977886606295122],
              [-81.47090397499214, 35.977927405005524],
              [-81.470955098502699, 35.977662627508245],
              [-81.471286883153965, 35.977703425159554],
              [-81.471389127042912, 35.977173869749649],
              [-81.471720908823016, 35.977214667153127],
              [-81.47177202917365, 35.976949888339654],
              [-81.472103810226471, 35.976990684706379],
              [-81.47251275037263, 35.974872455921606],
              [-81.472844523230393, 35.974913249408011],
              [-81.47289563735518, 35.97464847081141],
              [-81.472563866668708, 35.974607677437298],
              [-81.47266609711852, 35.974078119444435],
              [-81.471339022849094, 35.973914936271242],
              [-81.47128790297262, 35.974179714313962],
              [-81.470956134998701, 35.974138915663772],
              [-81.470905013751562, 35.974403694424254],
              [-81.470241474495225, 35.974322095094699],
              [-81.470190350759481, 35.974586872636173],
              [-81.469858580585608, 35.974546071934292],
              [-81.469807455451445, 35.974810849292503],
              [-81.469475684522706, 35.974770046652964],
              [-81.469526811828018, 35.974505269407047],
              [-81.469195042297613, 35.974464466000207],
              [-81.469246169221847, 35.974199688862861],
              [-81.468914401089762, 35.974158884688869],
              [-81.469016657137885, 35.973629329614376],
              [-81.468684892575325, 35.973588524785484],
              [-81.468736020618408, 35.973323747771865],
              [-81.468404256345735, 35.973282942198161],
              [-81.468455386196894, 35.97301816434733],
              [-81.468123623350067, 35.97297735890745],
              [-81.468174753928551, 35.972712581142801],
              [-81.465520678193272, 35.972386099791308],
              [-81.465469539085404, 35.972650875576996],
              [-81.464474263854697, 35.972528429429921],
              [-81.464525406150685, 35.972263654048483],
              [-81.463530137101159, 35.972141199287634],
              [-81.463478991644592, 35.972405975165856],
              [-81.463147234912526, 35.97236515527397],
              [-81.463096086921681, 35.972629930090285],
              [-81.46210081552374, 35.97250746369815],
              [-81.462049665145173, 35.972772238940252],
              [-81.46138615042365, 35.972690589599459],
              [-81.461437302955119, 35.972425815528013],
              [-81.460110282899706, 35.972262506540595],
              [-81.460161439346152, 35.971997732959764],
              [-81.459829687051837, 35.971956903125708],
              [-81.459880844225339, 35.97169212963113],
              [-81.459549093329301, 35.971651299030093],
              [-81.459651410983682, 35.971121751219158],
              [-81.459319661467816, 35.971080920909202],
              [-81.459370820840292, 35.970816146615263],
              [-81.459039072695305, 35.970775314637237],
              [-81.459294869890059, 35.969451444916508],
              [-81.458963128502219, 35.9694106126883],
              [-81.45891196868304, 35.969675386954982],
              [-81.458580226568344, 35.969634553690135],
              [-81.458426741908781, 35.970428875794966],
              [-81.457431505826406, 35.970306370321644],
              [-81.457380340413863, 35.970571143855125],
              [-81.457048595391669, 35.970530306443628],
              [-81.456997427472473, 35.970795079816142],
              [-81.456333935202011, 35.970713402040225],
              [-81.456282765930098, 35.970978175072297],
              [-81.455951018681745, 35.970937334707912],
              [-81.455899848011455, 35.971202107556572],
              [-81.455568100036274, 35.971161266155484],
              [-81.455516927940224, 35.971426037919777],
              [-81.455185179265598, 35.971385196383011],
              [-81.45513400466271, 35.971649967986323],
              [-81.454802256342262, 35.971609124489568],
              [-81.454751080368354, 35.971873896810429],
              [-81.454419330212701, 35.971833052299267],
              [-81.454368152812918, 35.972097823535741],
              [-81.454036401957993, 35.972056978888865],
              [-81.453985223159648, 35.972321749942026],
              [-81.453653472658957, 35.972280903335154],
              [-81.453602292462151, 35.972545674204852],
              [-81.452607036489454, 35.972423129536288],
              [-81.452555852768953, 35.972687899952938],
              [-81.452224100378004, 35.972647049491194],
              [-81.452172915258998, 35.972911819724317],
              [-81.451841162141321, 35.972870968225841],
              [-81.451789975651167, 35.973135739176641],
              [-81.451458221806845, 35.97309488664137],
              [-81.451407032782242, 35.973359656529972],
              [-81.450080017594672, 35.973196235907601],
              [-81.449977630064055, 35.973725775360997],
              [-81.449645874711493, 35.973684917911804],
              [-81.449594679365873, 35.973949686980184],
              [-81.449262923286568, 35.973908828494245],
              [-81.44921172654243, 35.974173597379234],
              [-81.448879969709211, 35.974132736955447],
              [-81.448828771593796, 35.974397506557935],
              [-81.447833499976625, 35.974274921272809],
              [-81.447782298309946, 35.974539689521009],
              [-81.447450540695414, 35.97449882522001],
              [-81.4473481331475, 35.975028362223881],
              [-81.447016373743722, 35.974987496751218],
              [-81.446965168943578, 35.975252264583666],
              [-81.446633407731952, 35.97521139899743],
              [-81.446684614703202, 35.974946631277575],
              [-81.445689339543463, 35.974824027643663],
              [-81.445638129384264, 35.975088794958673],
              [-81.445306370625403, 35.9750479257427],
              [-81.4452551590675, 35.975312692874226],
              [-81.444923399554753, 35.975271821720376],
              [-81.444872186598218, 35.975536588668348],
              [-81.444208666482908, 35.97545484428624],
              [-81.444157451064996, 35.975719610915718],
              [-81.443162169102777, 35.975596986745423],
              [-81.443110950160715, 35.975861752921432],
              [-81.442779189476681, 35.97582087595152],
              [-81.442727968027455, 35.976085641966087],
              [-81.442396206616806, 35.976044763959436],
              [-81.442344984877266, 35.976309529768258],
              [-81.44201322163174, 35.976268650746874],
              [-81.441961998493298, 35.976533416372071],
              [-81.44163023562966, 35.976492536291666],
              [-81.441579009984025, 35.976757301755413],
              [-81.440915482058813, 35.976675539542313],
              [-81.440864255032892, 35.976940303764302],
              [-81.44020072632712, 35.97685853767382],
              [-81.44014949573122, 35.977123301599342],
              [-81.438490675023331, 35.976918867651662],
              [-81.438541910932457, 35.976654104401042],
              [-81.437878388015051, 35.976572324761108],
              [-81.437546627060641, 35.976531433588278],
              [-81.437495386855133, 35.9767961964561],
              [-81.437163625174435, 35.976755304246488],
              [-81.437112384705642, 35.977020067809484],
              [-81.43678062119011, 35.97697917458504],
              [-81.436729379295187, 35.977243937063356],
              [-81.436397615053281, 35.97720304280211],
              [-81.436295127048126, 35.977732568265672],
              [-81.435963362125733, 35.977691672810344],
              [-81.435809623867186, 35.978485960696908],
              [-81.435477854957341, 35.97844506305595],
              [-81.435375359338479, 35.978974587492196],
              [-81.435707130373686, 35.979015485403295],
              [-81.435502138215085, 35.980074533352138],
              [-81.435170362956413, 35.980033635802023],
              [-81.435119112729339, 35.980298397312623],
              [-81.434787336744193, 35.980257498725585],
              [-81.434684833155487, 35.980787021330414],
              [-81.434353055381379, 35.980746121571521],
              [-81.434301802019732, 35.981010882665657],
              [-81.433638244246623, 35.98092908019418],
              [-81.433586988423158, 35.981193840969524],
              [-81.433255209505546, 35.98115293733381],
              [-81.433203952310251, 35.981417698826405],
              [-81.432872171557662, 35.981376794175787],
              [-81.432820912936066, 35.981641554583554],
              [-81.432157352559145, 35.981559743163388],
              [-81.432054828974302, 35.982089264215475],
              [-81.431723046042777, 35.982048356454072],
              [-81.431620518959676, 35.982577876139679],
              [-81.430625168078876, 35.982455148391473],
              [-81.430471363676034, 35.983249425470405],
              [-81.430139577912428, 35.983208514305012],
              [-81.430037037854035, 35.983738032443966],
              [-81.429705249165679, 35.983697119227571],
              [-81.429653977569032, 35.983961878088671],
              [-81.428990400184773, 35.983880048657952],
              [-81.428887850622218, 35.984409565715495],
              [-81.428556060863642, 35.984368649366004],
              [-81.428504783960463, 35.984633407697437],
              [-81.428172992367067, 35.984592490332965],
              [-81.428121715173262, 35.984857248458397],
              [-81.427458130870448, 35.984775410753677],
              [-81.427406851214528, 35.985040168559983],
              [-81.427075058505125, 35.98499924821963],
              [-81.426972494949453, 35.985528763437621],
              [-81.426308906289307, 35.9854469195107],
              [-81.426257621326727, 35.985711676787119],
              [-81.425925826438686, 35.985670753335675],
              [-81.425874540049634, 35.985935509527167],
              [-81.425542744435489, 35.985894585038693],
              [-81.425491456674038, 35.986159341947094],
              [-81.425159660333705, 35.986118416421583],
              [-81.425057081648347, 35.986647928920497],
              [-81.424725283519052, 35.986607002222861],
              [-81.424673992595046, 35.986871757813326],
              [-81.424342193739562, 35.986830830078553],
              [-81.42439348572637, 35.986566074623227],
              [-81.423729891150742, 35.986484216718033],
              [-81.423781184899454, 35.986219460582952],
              [-81.422785798749459, 35.986096667365871],
              [-81.422837095376821, 35.985831912488102],
              [-81.422505301702557, 35.985790978845948],
              [-81.42260789715445, 35.985261468290851],
              [-81.422276105969445, 35.985220534918021],
              [-81.422378702200078, 35.984691024437531],
              [-81.422046913477217, 35.984650090432979],
              [-81.42209821269104, 35.984385334792954],
              [-81.421766425367593, 35.984344400021605],
              [-81.421817725307463, 35.984079644467798],
              [-81.421485939383416, 35.984038708929639],
              [-81.421537240049318, 35.983773953462077],
              [-81.421869023829103, 35.983814889788199],
              [-81.421971620916992, 35.983285378458135],
              [-81.422303402881084, 35.983326312711007],
              [-81.422508588793107, 35.982267289825138],
              [-81.422176811079837, 35.982226356112747],
              [-81.422484585075452, 35.980637819799583],
              [-81.422816355304249, 35.980678752723264],
              [-81.422918942187351, 35.980149239950713],
              [-81.422255405208205, 35.980067373763895],
              [-81.422306699715733, 35.979802617585626],
              [-81.421974932212422, 35.979761682384549],
              [-81.422026228554557, 35.97949692627062],
              [-81.421694462477433, 35.979455991203807],
              [-81.42174575954553, 35.979191235176216],
              [-81.421413994840591, 35.97915029844166],
              [-81.421465292634636, 35.978885542500485],
              [-81.421133529355956, 35.978844605900164],
              [-81.421184827875919, 35.97857985004525],
              [-81.420853067078042, 35.978538911755358],
              [-81.420904365215208, 35.978274156008794],
              [-81.420572605843574, 35.97823321785306],
              [-81.420623905815248, 35.977968462170949],
              [-81.420292147815772, 35.977927522347592],
              [-81.420548644557726, 35.976603742145095],
              [-81.420880397244673, 35.976644681292825],
              [-81.420931694060982, 35.976379924439115],
              [-81.421263446021953, 35.976420862549823],
              [-81.421519922012891, 35.975097078633034],
              [-81.421188175391421, 35.975056142098943],
              [-81.421290765756467, 35.974526627736395],
              [-81.420959022704992, 35.974485690548903],
              [-81.42116420256437, 35.973426662703055],
              [-81.420832464099163, 35.973385725154358],
              [-81.420781168860714, 35.973650482273442],
              [-81.420449429642659, 35.973609542786797],
              [-81.420398131896704, 35.973874299744004],
              [-81.419734652372725, 35.973792418696057],
              [-81.419785952243615, 35.973527662009083],
              [-81.419454214062455, 35.973486720718114],
              [-81.419505513550575, 35.973221964139611],
              [-81.419173777849991, 35.973181021159029],
              [-81.419276379076706, 35.972651509005409],
              [-81.418944644729123, 35.972610565415401],
              [-81.419047246733982, 35.972081053337497],
              [-81.418715515956222, 35.972040109094038],
              [-81.418766816948349, 35.971775352677774],
              [-81.418435087569577, 35.971734407667704],
              [-81.418486390422871, 35.971469652217024],
              [-81.418154661334697, 35.971428706462348],
              [-81.418308569872408, 35.970634437543019],
              [-81.418640295773557, 35.970675382892217],
              [-81.418742897288084, 35.97014586977329],
              [-81.419074620265789, 35.970186813071315],
              [-81.419279815006149, 35.969127785709659],
              [-81.418948096250574, 35.969086842051212],
              [-81.418999395710685, 35.968822085213596],
              [-81.418667678354012, 35.968781140788579],
              [-81.418770277280487, 35.968251627259832],
              [-81.418438562385091, 35.968210682203512],
              [-81.418489862959802, 35.967945925490433],
              [-81.417826437438663, 35.967864033822131],
              [-81.417775134739415, 35.968128790264835],
              [-81.417111708413273, 35.968046893818247],
              [-81.41716301323703, 35.967782137645919],
              [-81.416831301087015, 35.967741188216273],
              [-81.416882606636378, 35.967476432130653],
              [-81.416550896993627, 35.967435481912482],
              [-81.416602203268567, 35.967170725913505],
              [-81.415938786009107, 35.967088823064479],
              [-81.415990094071844, 35.966824067287277],
              [-81.415658387563411, 35.966783114634509],
              [-81.41560707843847, 35.967047870276502],
              [-81.415275371204572, 35.967006916586747],
              [-81.415326681391733, 35.966742161080028],
              [-81.414331563788323, 35.966619295028444],
              [-81.414280250441522, 35.966884051030625],
              [-81.413948544554287, 35.966843093734148],
              [-81.413999858963365, 35.966578337867254],
              [-81.412009637084353, 35.96633257596492],
              [-81.411958315166942, 35.966597330141418],
              [-81.411626611636862, 35.966556366533311],
              [-81.411523964694268, 35.967085875371168],
              [-81.410860554368654, 35.967003944007956],
              [-81.410911880146102, 35.966739190334252],
              [-81.409916770383532, 35.966616287383097],
              [-81.409968099010712, 35.966351534066668],
              [-81.409304696301689, 35.966269594227711],
              [-81.409356026716381, 35.966004841133206],
              [-81.408692628587104, 35.965922897936345],
              [-81.408743960762465, 35.965658144162774],
              [-81.408412262724411, 35.965617171808574],
              [-81.408463596760242, 35.965352419000915],
              [-81.407800203792007, 35.965270470957094],
              [-81.407851539588449, 35.96500571747044],
              [-81.407519844685169, 35.964964742681865],
              [-81.407571181206805, 35.964699989282025],
              [-81.407902873966535, 35.964740964858215],
              [-81.407954209089311, 35.964476211274523],
              [-81.408285901123776, 35.964517185813882],
              [-81.408388567153196, 35.963987679153334],
              [-81.408720257373375, 35.964028651619444],
              [-81.408771589388365, 35.963763898520789],
              [-81.40910327888335, 35.963804869950003],
              [-81.40951390649002, 35.961686837981333],
              [-81.40984558782533, 35.961727807426811],
              [-81.410050888703765, 35.960668788398102],
              [-81.410382566154553, 35.960709757301956],
              [-81.410536533983475, 35.959915492138123],
              [-81.410868208558071, 35.959956458833588],
              [-81.410970849600332, 35.959426948219175],
              [-81.411302521279396, 35.959467913764435],
              [-81.411353840787569, 35.959203158238488],
              [-81.411685511768397, 35.959244123647956],
              [-81.411736829850952, 35.958979367037259],
              [-81.412068500106486, 35.959020331409896],
              [-81.412119816817324, 35.95875557551669],
              [-81.412783156160515, 35.958837499484716],
              [-81.412834469302481, 35.958572743294489],
              [-81.413166139525387, 35.958613703769061],
              [-81.41321745126875, 35.958348947395244],
              [-81.413549119657986, 35.958389906854933],
              [-81.413600430002816, 35.958125150297519],
              [-81.413932097666745, 35.95816610872032],
              [-81.414034716303789, 35.957636594267115],
              [-81.414366382180404, 35.957677551517882],
              [-81.414417689391442, 35.957412794544958],
              [-81.414749354542721, 35.957453750759036],
              [-81.414800659246893, 35.957188993624506],
              [-81.415132323672921, 35.957229948801711],
              [-81.415183628059793, 35.956965190560673],
              [-81.415515291760471, 35.957006144701189],
              [-81.415566594775783, 35.956741387177637],
              [-81.41589825775111, 35.956782340281357],
              [-81.416000859512934, 35.956252823939778],
              [-81.416332520700948, 35.956293775871536],
              [-81.416435118992638, 35.955764259066321],
              [-81.416766778393352, 35.95580520982611],
              [-81.416818075404876, 35.955540450776297],
              [-81.417149734080198, 35.955581400499284],
              [-81.417252326104958, 35.955051882863557],
              [-81.417583981884803, 35.955092831436509],
              [-81.417635275762876, 35.954828071971484],
              [-81.417966931925449, 35.954869019485834],
              [-81.418018224405202, 35.954604259837311],
              [-81.418349878734176, 35.954645206336778],
              [-81.418401170923715, 35.954380446482944],
              [-81.418732824527297, 35.954421391945715],
              [-81.418784114210155, 35.954156631930374],
              [-81.419779074935477, 35.954279461580867],
              [-81.419830361123175, 35.954014702012756],
              [-81.420493668192279, 35.954096583360716],
              [-81.420544950784517, 35.953831822594864],
              [-81.420876604329507, 35.953872762220975],
              [-81.420927885523412, 35.953608001271711],
              [-81.421259538342923, 35.953648939861033],
              [-81.421310818138494, 35.953384178728449],
              [-81.421642469097236, 35.953425115402027],
              [-81.421693748602777, 35.953160354064067],
              [-81.422025398863084, 35.953201290601896],
              [-81.42207667697032, 35.952936529080539],
              [-81.422408326478092, 35.952977463680753],
              [-81.422459602105917, 35.952712702899035],
              [-81.42279125088821, 35.95275363646256],
              [-81.422842525090715, 35.952488874596376],
              [-81.423174173147501, 35.952529807123206],
              [-81.42322544705992, 35.952265045051746],
              [-81.423557094418257, 35.952305977442926],
              [-81.423659636893817, 35.951776452929046],
              [-81.423327991659107, 35.951735520808178],
              [-81.423738156547728, 35.949617420104929],
              [-81.424069793287728, 35.949658351144549],
              [-81.424172327901118, 35.949128824788481],
              [-81.424835597007601, 35.949210682743711],
              [-81.424886862253899, 35.948945919662727],
              [-81.425218495682174, 35.948986846713474],
              [-81.425269759503195, 35.94872208254813],
              [-81.425601392233148, 35.948763009463327],
              [-81.425652654656176, 35.948498245114735],
              [-81.425984286660523, 35.948539170993236],
              [-81.426035546577268, 35.948274406483527],
              [-81.426698810552352, 35.948356254342791],
              [-81.426750068009326, 35.948091489514702],
              [-81.427081698871831, 35.948132411517491],
              [-81.427184211788472, 35.947602882325683],
              [-81.427515840863734, 35.947643803156758],
              [-81.427618349203854, 35.947114273524392],
              [-81.427949976491803, 35.947155193183754],
              [-81.42800122908217, 35.946890427709867],
              [-81.428332855671584, 35.946931347233594],
              [-81.428384106864044, 35.946666581576523],
              [-81.428715732700795, 35.946707499162713],
              [-81.42876698249529, 35.946442733322471],
              [-81.429098607606477, 35.946483649872086],
              [-81.429201102982319, 35.945954118700229],
              [-81.429532726306192, 35.94599503407818],
              [-81.429583972968601, 35.945730267823485],
              [-81.430578840664026, 35.945853008165386],
              [-81.430630082696879, 35.94558824147942],
              [-81.431293328123161, 35.945670063581836],
              [-81.431242087105758, 35.945934830559956],
              [-81.431573711384928, 35.945975740394111],
              [-81.431624951340552, 35.945710973280825],
              [-81.432288197648433, 35.945792789095528],
              [-81.432339434063508, 35.945528022587133],
              [-81.433002680700383, 35.945609834503756],
              [-81.433053914628758, 35.945345066776142],
              [-81.433385536295049, 35.945385971719737],
              [-81.433488001019953, 35.944856435850404],
              [-81.43381962200688, 35.944897339600331],
              [-81.433870852803494, 35.944632571458634],
              [-81.434202471929524, 35.944673473293079],
              [-81.434304930391008, 35.944143936595452],
              [-81.434636548864901, 35.944184838137254],
              [-81.434687775421736, 35.943920069603521],
              [-81.436677483545537, 35.944165456476817],
              [-81.4367287033959, 35.943900687084735],
              [-81.437391940001746, 35.9439824752275],
              [-81.437443157392622, 35.943717705517351],
              [-81.438438012176476, 35.943840380103886],
              [-81.439101250018581, 35.943922158962032],
              [-81.439152461764706, 35.943657388528763],
              [-81.439484079560316, 35.943698276031164],
              [-81.439535289908761, 35.943433505414973],
              [-81.439866906978551, 35.943474391880891],
              [-81.439918115929316, 35.943209621081728],
              [-81.440581350061095, 35.943291391017027],
              [-81.440683761599914, 35.942761848756881],
              [-81.441015376492118, 35.942802732113485],
              [-81.441066581222728, 35.94253795986436],
              [-81.44139819541634, 35.94257884308545],
              [-81.441449398749327, 35.942314070653445],
              [-81.441781012189807, 35.942354951937055],
              [-81.441832213044421, 35.942090180245224],
              [-81.442163825759039, 35.942131060492436],
              [-81.442215026296708, 35.941866287694573],
              [-81.44254663831282, 35.941907167806306],
              [-81.442597836344873, 35.941642394847626],
              [-81.442929447607852, 35.941683273021937],
              [-81.442980644269625, 35.941418500781474],
              [-81.443312254806699, 35.94145937791933],
              [-81.443363450043634, 35.941194604594941],
              [-81.443695059854804, 35.941235480696378],
              [-81.443848640392858, 35.940441160931769],
              [-81.444180247354723, 35.940482035726887],
              [-81.444231438016971, 35.940217261963085],
              [-81.444563044252831, 35.940258135721727],
              [-81.444614234652931, 35.939993362653986],
              [-81.444945840162887, 35.940034235376217],
              [-81.444997028030244, 35.939769461246698],
              [-81.445328632814295, 35.939810332932616],
              [-81.445379820392148, 35.939545558598091],
              [-81.44637463246589, 35.939668167864369],
              [-81.446425815414912, 35.93940339309929],
              [-81.446757419418617, 35.939444260909482],
              [-81.44670623750406, 35.93970903490851],
              [-81.44770105360675, 35.939831633357699],
              [-81.447752232308957, 35.939566858052785],
              [-81.44808383765556, 35.939607722257342],
              [-81.448135014960243, 35.939342946769642],
              [-81.448466619553614, 35.939383809036812],
              [-81.448517795460901, 35.939119033366303],
              [-81.448849398247603, 35.939159895520262],
              [-81.448900572757509, 35.938895119667052],
              [-81.44923217592617, 35.938935980762423],
              [-81.44928334903868, 35.938671204726361],
              [-81.449614950346017, 35.938712063906593],
              [-81.449768463376586, 35.937917735129069],
              [-81.450100062969724, 35.937958593881831],
              [-81.450151231508343, 35.937693817406888],
              [-81.45081442844301, 35.937775531059621],
              [-81.450865595630589, 35.937510754244769],
              [-81.451197192972003, 35.937551609144755],
              [-81.451248357654322, 35.937286832169413],
              [-81.451911552356052, 35.937368539874001],
              [-81.451962714579309, 35.937103762581032],
              [-81.452294311912141, 35.937144614484616],
              [-81.452345472738244, 35.936879837009045],
              [-81.452677068264421, 35.936920688799567],
              [-81.452728227665887, 35.936655910240276],
              [-81.453059822465988, 35.936696760994487],
              [-81.4531109804702, 35.936431982252437],
              [-81.453442575652147, 35.936472831948151],
              [-81.453493731151198, 35.936208053045711],
              [-81.454156919263212, 35.936289748584208],
              [-81.454310379647538, 35.935495412538479],
              [-81.45464197045429, 35.935536258111632],
              [-81.454846575707052, 35.93447714156801],
              [-81.455509748753428, 35.934558829854154],
              [-81.455560897367903, 35.934294049873102],
              [-81.455892482765023, 35.934334892089822],
              [-81.455943630009799, 35.934070112827136],
              [-81.456275215788679, 35.934110953985389],
              [-81.456377504905248, 35.933581394190696],
              [-81.456709088896474, 35.933622234177797],
              [-81.456760231876189, 35.933357453623493],
              [-81.457091815141254, 35.933398292574346],
              [-81.457142956751326, 35.933133512738507],
              [-81.457474538182353, 35.933174350675316],
              [-81.457679096939714, 35.932115227612819],
              [-81.458010675567593, 35.932156064086492],
              [-81.458061813349858, 35.931891282841541],
              [-81.458393390143812, 35.931932118301333],
              [-81.458444526529178, 35.931667336873943],
              [-81.458776102596872, 35.931708171297373],
              [-81.458827237585254, 35.931443389687459],
              [-81.459158812926773, 35.931484223074754],
              [-81.459209945410237, 35.931219441304677],
              [-81.459873096084266, 35.931301105083136],
              [-81.459924226109308, 35.931036322995702],
              [-81.46025580033394, 35.931077153409205],
              [-81.460306930069791, 35.930812371116872],
              [-81.460970077374469, 35.930894028069176],
              [-81.461021203544064, 35.930629245481882],
              [-81.461684351202209, 35.93071089943777],
              [-81.461633226047979, 35.930975682317012],
              [-81.462627952653577, 35.931098156005369],
              [-81.46257683145663, 35.931362939219191],
              [-81.465229459344556, 35.931689496832],
              [-81.46517834630508, 35.931954281076727],
              [-81.465509928036695, 35.931995096279799],
              [-81.465612150549205, 35.931465527495419],
              [-81.465943729384875, 35.931506341549962],
              [-81.466199273937448, 35.930182418092862],
              [-81.466530847800428, 35.930223230571976],
              [-81.466633060265309, 35.929693660604265],
              [-81.466301488498061, 35.929652847493742],
              [-81.466352596397073, 35.929388062550913],
              [-81.46602102605415, 35.929347249574846],
              [-81.466072133544159, 35.929082463840601],
              [-81.465740564598008, 35.929041650098],
              [-81.465842781830929, 35.928512079635119],
              [-81.465511215343085, 35.928471265260704],
              [-81.465766757326534, 35.927147339407135],
              [-81.465435197561789, 35.927106523882102],
              [-81.465690735337091, 35.925782596635784],
              [-81.466022289794495, 35.925823411486839],
              [-81.466124499970078, 35.925293839274239],
              [-81.466456052639742, 35.925334652954376],
              [-81.466609363312855, 35.924540293852644],
              [-81.466940912025493, 35.924581106249398],
              [-81.466992013482184, 35.92431631965993],
              [-81.467655111978161, 35.924397940534114],
              [-81.467706210976729, 35.924133153627565],
              [-81.468037758544497, 35.924173962149837],
              [-81.468088856146494, 35.923909175061048],
              [-81.469083499919236, 35.924031595671948],
              [-81.469746598190966, 35.924113205191581],
              [-81.469695505868629, 35.924377992053273],
              [-81.471684815039495, 35.924622797987567],
              [-81.47173589985772, 35.924358009438649],
              [-81.472399003463039, 35.924439603636522],
              [-81.472450085823269, 35.924174814770574],
              [-81.4721185349146, 35.924134018257021],
              [-81.472782586639113, 35.920691758679972],
              [-81.473114122641519, 35.92073255346417],
              [-81.473318430176633, 35.919673394183214],
              [-81.473649962241026, 35.919714186626138],
              [-81.473803184799479, 35.918919816066918],
              [-81.47446624362027, 35.919001399242504],
              [-81.474517314626155, 35.918736608098726],
              [-81.474848843477616, 35.91877739819995],
              [-81.475002051329341, 35.917983024981638],
              [-81.475333577331725, 35.918023813777324],
              [-81.475435712083893, 35.917494230525151],
              [-81.475104189339419, 35.917453442877481],
              [-81.475308459070121, 35.916394276344597],
              [-81.474976940878122, 35.916353487433618],
              [-81.475232276920053, 35.915029528855413],
              [-81.475563789805634, 35.915070317092727],
              [-81.475614854726587, 35.914805525105088],
              [-81.475946366885807, 35.91484631230643],
              [-81.476048493600487, 35.91431672792006],
              [-81.475716983563743, 35.914275940988212],
              [-81.475819111061384, 35.913746356682445],
              [-81.476150617840517, 35.913787142466276],
              [-81.476303803391801, 35.912992765249435],
              [-81.47663530734981, 35.91303355062891],
              [-81.476737427226368, 35.912503964411215],
              [-81.477068929396751, 35.912544748620085],
              [-81.477119987757803, 35.912279954855187],
              [-81.477782990980742, 35.912361520300109],
              [-81.47783404688451, 35.912096726218621],
              [-81.478165547937053, 35.912137507454517],
              [-81.478216601364977, 35.911872714114708],
              [-81.478548101663407, 35.911913493413749],
              [-81.478650206472324, 35.911383905399646],
              [-81.478981705010852, 35.911424684429093],
              [-81.479083806358346, 35.910895095956995],
              [-81.479415303081637, 35.910935872914827],
              [-81.479517399860086, 35.910406284007294],
              [-81.479848894823576, 35.910447060695667],
              [-81.479899941635708, 35.910182265585938],
              [-81.480231435872724, 35.910223041238396],
              [-81.480282481288867, 35.909958245946939],
              [-81.480945467509756, 35.910039793400564],
              [-81.480996510468941, 35.909774997792532],
              [-81.481328003587848, 35.909815770472136],
              [-81.481379044043535, 35.909550974704892],
              [-81.481710536436125, 35.909591746348703],
              [-81.481761576603589, 35.909326950377107],
              [-81.482424559135211, 35.909408489813401],
              [-81.482475596845617, 35.909143693525301],
              [-81.482807086984749, 35.909184461317878],
              [-81.482858123299309, 35.908919664848014],
              [-81.483521103594555, 35.909001198338785],
              [-81.483572136344634, 35.908736401575098],
              [-81.483903626473023, 35.908777166372303],
              [-81.483954657827312, 35.908512369426788],
              [-81.484286147256924, 35.908553134089189],
              [-81.484337177215437, 35.908288336962023],
              [-81.485000153793237, 35.90836986153468],
              [-81.485051181294949, 35.908105064091032],
              [-81.48538266847082, 35.908145824902235],
              [-81.485433694576784, 35.907881027276943],
              [-81.485765182161501, 35.907921787930839],
              [-81.485969277859411, 35.906862595542023],
              [-81.485306313784108, 35.906781076167242],
              [-81.485357339278067, 35.90651627844143],
              [-81.485025858775714, 35.906475516636071],
              [-81.48512790977459, 35.905945921335118],
              [-81.484796431756635, 35.905905159798991],
              [-81.484847458341321, 35.905640361300428],
              [-81.484515982826537, 35.905599598975222],
              [-81.484567010165392, 35.905334801465393],
              [-81.484235534938776, 35.90529403839632],
              [-81.484286562976351, 35.905029240073105],
              [-81.483623615621426, 35.904947710600162],
              [-81.483674646581576, 35.904682913377741],
              [-81.483343174480993, 35.904642147874831],
              [-81.483394205032354, 35.904377349861619],
              [-81.483547295841802, 35.90358295731977],
              [-81.483878762590862, 35.903623722306492],
              [-81.48392979077056, 35.903358924871746],
              [-81.484261256793332, 35.90339968882278],
              [-81.484312283577339, 35.903134891206413],
              [-81.48464374998116, 35.903175654099094],
              [-81.484694775341964, 35.902910855400116],
              [-81.485026239939543, 35.902951618180658],
              [-81.485077263904671, 35.902686819300079],
              [-81.485408727748208, 35.902727580143832],
              [-81.485459750345612, 35.902462781982599],
              [-81.486122676914306, 35.902544300697684],
              [-81.48617369702734, 35.902279501319256],
              [-81.486836622840073, 35.902361017061722],
              [-81.486938656711246, 35.901831417647927],
              [-81.487270117983485, 35.901872173447821],
              [-81.487321132830388, 35.901607374448169],
              [-81.487652593376154, 35.90164812921229],
              [-81.487703607907264, 35.901383329107546],
              [-81.488035067726656, 35.901424082835938],
              [-81.488086079754765, 35.901159282572173],
              [-81.488748998275071, 35.901240787056615],
              [-81.488800007846748, 35.900975986476659],
              [-81.48946292447549, 35.901057487110215],
              [-81.489513931590864, 35.900792686214174],
              [-81.490839766832778, 35.900955676084827],
              [-81.490890769369813, 35.900690874603406],
              [-81.49155368687542, 35.900772363863098],
              [-81.491604685848642, 35.90050756208818],
              [-81.491936144014232, 35.900548304330826],
              [-81.491987141592091, 35.900283502374322],
              [-81.492318599058905, 35.90032424448232],
              [-81.492369596348809, 35.900059442321833],
              [-81.492701051981641, 35.900100183416775],
              [-81.492752047876195, 35.899835381074801],
              [-81.494409330125308, 35.900039070468338],
              [-81.494460319273117, 35.899774267429152],
              [-81.495123231721308, 35.899855736443435],
              [-81.495174218412942, 35.899590933088156],
              [-81.495505674617235, 35.89963166564727],
              [-81.495454689014423, 35.899896470038136],
              [-81.496449062844135, 35.900018663613984],
              [-81.496500044184415, 35.899753859743115],
              [-81.496831501725907, 35.899794588697986],
              [-81.496882482778318, 35.899529784622985],
              [-81.497213938513568, 35.89957051346601],
              [-81.497162959629634, 35.899835317652851],
              [-81.497825876384113, 35.899916771057399],
              [-81.497876853146138, 35.899651966601425],
              [-81.498539769143534, 35.899733417033552],
              [-81.498871227629607, 35.899774140447505],
              [-81.498922200874404, 35.899509335541069],
              [-81.49958511672736, 35.899590779396668],
              [-81.49963608751591, 35.899325974174275],
              [-81.500299001476336, 35.899407414179343],
              [-81.500349969808667, 35.899142608640979],
              [-81.501012884091296, 35.899224044750021],
              [-81.501063849967338, 35.898959238895756],
              [-81.501395305441577, 35.898999955486815],
              [-81.501446269922539, 35.898735149451312],
              [-81.502109181966247, 35.898816579615847],
              [-81.502160142883554, 35.898551773287103],
              [-81.503154509137005, 35.898673910494047],
              [-81.503205467644662, 35.89840910369233],
              [-81.503536921940722, 35.898449814473473],
              [-81.503587879053256, 35.898185007490348],
              [-81.504582242950221, 35.898307133077573],
              [-81.504633195438245, 35.8980423256669],
              [-81.504964648920819, 35.898083031673785],
              [-81.505015600013735, 35.897818224081867],
              [-81.505678508129904, 35.897899634880027],
              [-81.505729455631325, 35.897634826094091],
              [-81.506392362933909, 35.897716232118064],
              [-81.506443308007235, 35.897451423917353],
              [-81.506774761112581, 35.897492125893727],
              [-81.506876649186651, 35.896962507258323],
              [-81.509196812016526, 35.897247391346177],
              [-81.509298682821182, 35.896717771564951],
              [-81.509961585086614, 35.896799158245798],
              [-81.510012517852019, 35.89653434756562],
              [-81.51034396896442, 35.896575038958062],
              [-81.510394900362868, 35.89631022899777],
              [-81.510726349640763, 35.896350919377539],
              [-81.510777279616249, 35.896086108335027],
              [-81.511108728195111, 35.896126798580276],
              [-81.511159656775689, 35.895861987356554],
              [-81.511491104599557, 35.895902675665369],
              [-81.511542031785112, 35.8956378642605],
              [-81.511873478909962, 35.895678552434823],
              [-81.511975329050074, 35.895148929238943],
              [-81.512969667085329, 35.895270984823043],
              [-81.512918745918796, 35.895535796836199],
              [-81.514244538543096, 35.89569852644663],
              [-81.514295455465557, 35.895433713895805],
              [-81.514958352198605, 35.895515072563946],
              [-81.514907437398051, 35.895779885383632],
              [-81.515238887879931, 35.895820563489174],
              [-81.51528980161946, 35.89555575053501],
              [-81.515621250266918, 35.895596427627844],
              [-81.5155703375884, 35.895861240716407],
              [-81.515901788737949, 35.895901917019749],
              [-81.516233239114072, 35.895942592444925],
              [-81.516284149670568, 35.895677779087542],
              [-81.516947050409456, 35.895759126943005],
              [-81.516997958510089, 35.89549431327012],
              [-81.517329407765502, 35.89553498572333],
              [-81.51738031447131, 35.895270171869377],
              [-81.517711762999497, 35.895310843287135],
              [-81.517762667203158, 35.895046029274958],
              [-81.518425564246286, 35.895127369115606],
              [-81.518476465994141, 35.894862554787977],
              [-81.518807914480959, 35.894903222309843],
              [-81.51906241398801, 35.893579148376261],
              [-81.518730971913513, 35.893538481503427],
              [-81.5191890574947, 35.891155144753249],
              [-81.519520490048734, 35.891195811317772],
              [-81.519622281020531, 35.890666179146265],
              [-81.519953710679147, 35.890706844563887],
              [-81.520004604631751, 35.890442029174885],
              [-81.521661754783565, 35.890645340227984],
              [-81.521712643069819, 35.89038052321964],
              [-81.522044072608651, 35.890421182962392],
              [-81.521993186490619, 35.890686000082283],
              [-81.522324617395881, 35.890726658157377],
              [-81.522375502452888, 35.890461840903122],
              [-81.523038364306032, 35.89054315586079],
              [-81.523140130282869, 35.890013520652417],
              [-81.523471559034391, 35.890054176522447],
              [-81.523522439907779, 35.889789358725594],
              [-81.52385386901112, 35.889830012636409],
              [-81.523904748490011, 35.88956519465858],
              [-81.524236175786896, 35.889605848457911],
              [-81.524337931622171, 35.889076212094047],
              [-81.524669357130847, 35.889116864723775],
              [-81.524872860554964, 35.888057590900409],
              [-81.525204282125415, 35.888098241190697],
              [-81.525255154979433, 35.887833422507192],
              [-81.525586575822544, 35.887874071762191],
              [-81.525637448389489, 35.88760925287486],
              [-81.526631709765695, 35.887731195732087],
              [-81.526682577709153, 35.887466376418239],
              [-81.527013997764712, 35.887507021800623],
              [-81.527064864313701, 35.887242202306062],
              [-81.527396283641892, 35.887282846653157],
              [-81.527498014724941, 35.886753207232907],
              [-81.527829432264923, 35.88679385041047],
              [-81.52793115878508, 35.886264210558686],
              [-81.528262574536797, 35.886304852566589],
              [-81.528313436235777, 35.886040032436654],
              [-81.528644850152901, 35.886080673432289],
              [-81.528746570400656, 35.885551031863535],
              [-81.529409396099126, 35.88563231059166],
              [-81.529460253615213, 35.885367489919503],
              [-81.529791666457371, 35.88540812778637],
              [-81.52984252255105, 35.885143306032468],
              [-81.530836757709253, 35.885265214771181],
              [-81.530887610286925, 35.885000392568038],
              [-81.533538911597361, 35.885325438486554],
              [-81.533488067506454, 35.885590261763944],
              [-81.533819483260046, 35.885630887783719],
              [-81.533768641003974, 35.88589571112594],
              [-81.534762892396841, 35.886017586007235],
              [-81.534813731470365, 35.885752762262342],
              [-81.535145148585556, 35.885793385579191],
              [-81.535195986293161, 35.885528562554555],
              [-81.53552740154538, 35.885569183958246],
              [-81.53562907381054, 35.885039536600289],
              [-81.535960487302603, 35.885080157735459],
              [-81.536062156112948, 35.884550509923386],
              [-81.536393567788167, 35.884591128988177],
              [-81.536495232036401, 35.884061480745061],
              [-81.536826643030466, 35.884102098617277],
              [-81.536979133153068, 35.88330762506304],
              [-81.537310540218968, 35.883348242555726],
              [-81.537361368153952, 35.883083417517959],
              [-81.537412196862959, 35.882818592410899],
              [-81.537080791918797, 35.882777975186677],
              [-81.53713162027644, 35.882513151091537],
              [-81.537463024131554, 35.882553767280463],
              [-81.537666330980727, 35.881494466272287],
              [-81.537997730954174, 35.881535081924419],
              [-81.538048555495763, 35.881270256428053],
              [-81.537717156583213, 35.881229640910213],
              [-81.537767981852355, 35.880964815501748],
              [-81.537818806788408, 35.880699990047056],
              [-81.538150203551197, 35.880740604395498],
              [-81.538251850302046, 35.880210953078624],
              [-81.537920456767921, 35.880170338975589],
              [-81.537971280676032, 35.879905512480967],
              [-81.537308495593564, 35.879824280962687],
              [-81.537359321318277, 35.879559455591171],
              [-81.537027930905268, 35.879518839053908],
              [-81.53712958344812, 35.878989187539553],
              [-81.536798195461571, 35.878948569468839],
              [-81.536899849928446, 35.878418918915855],
              [-81.53656846439651, 35.878378300212731],
              [-81.536619291637209, 35.878113475012547],
              [-81.536287907499116, 35.878072855542761],
              [-81.536440391483069, 35.877278379143512],
              [-81.53610901086023, 35.877237759175557],
              [-81.536210667664292, 35.876708107972043],
              [-81.535879289496094, 35.876667487371634],
              [-81.535980947088603, 35.876137836251772],
              [-81.535649571374876, 35.876097215018994],
              [-81.535751229755803, 35.875567563982706],
              [-81.535419857603543, 35.875526942094638],
              [-81.535521516772903, 35.874997291142051],
              [-81.53519014707507, 35.874956668621536],
              [-81.535342635915839, 35.874162190909061],
              [-81.53501126976127, 35.874121568791502],
              [-81.535265416836694, 35.872797439010938],
              [-81.535596777716748, 35.872838061358223],
              [-81.535647604628892, 35.872573235138539],
              [-81.535978963646187, 35.872613855572737],
              [-81.536233089013535, 35.871289723088324],
              [-81.536564444168476, 35.87133034192739],
              [-81.536615266960453, 35.871065515162499],
              [-81.537277973963057, 35.871146750816706],
              [-81.537328794300862, 35.870881923737258],
              [-81.537660148362534, 35.870922540506356],
              [-81.537710966171474, 35.870657712368541],
              [-81.538705024931303, 35.870779556013431],
              [-81.538755840360949, 35.870514728304798],
              [-81.539418544997886, 35.870595952343912],
              [-81.539367732796279, 35.870860780297953],
              [-81.539699086689126, 35.870901391551016],
              [-81.539749898908781, 35.87063656253865],
              [-81.540081252074174, 35.870677172756643],
              [-81.540132061793344, 35.87041234358712],
              [-81.540463415338223, 35.870452952747051],
              [-81.54056503168465, 35.869923294902257],
              [-81.540896382306315, 35.869963902015051],
              [-81.540947190040526, 35.869699073316838],
              [-81.541278539934737, 35.869739679394549],
              [-81.541329345168492, 35.869474850539078],
              [-81.541660694335249, 35.869515455581791],
              [-81.541711498147052, 35.86925062564508],
              [-81.542042846614692, 35.869291230553777],
              [-81.542093649033049, 35.869026400436745],
              [-81.543419041906503, 35.869188807823846],
              [-81.543469839778226, 35.868923978024995],
              [-81.543801187761545, 35.868964577260371],
              [-81.543851983104361, 35.868699746403337],
              [-81.544183330360156, 35.868740344603722],
              [-81.544234125444945, 35.868475514444405],
              [-81.544565471973215, 35.868516111609807],
              [-81.544616264529111, 35.868251280392371],
              [-81.544947610329842, 35.868291876522797],
              [-81.544998401492307, 35.868027045125167],
              [-81.545329746593865, 35.86806764112157],
              [-81.545380537469981, 35.86780280952069],
              [-81.546374570398598, 35.867924589022735],
              [-81.546425356653359, 35.86765975699646],
              [-81.547088045553963, 35.867740938543399],
              [-81.547138829354864, 35.86747610620278],
              [-81.54747017324398, 35.867516695490906],
              [-81.547520954544524, 35.867251862993271],
              [-81.547852297706129, 35.867292451246442],
              [-81.547953859401488, 35.866762785798571],
              [-81.548285199668058, 35.866803372905835],
              [-81.548437535426302, 35.866008874009879],
              [-81.548106198341188, 35.86596828730513],
              [-81.548309312607969, 35.864908954428394],
              [-81.547977980097357, 35.864868367359534],
              [-81.548130315228363, 35.864073868303613],
              [-81.547798986231911, 35.864033280736471],
              [-81.548255981946227, 35.861649777815565],
              [-81.547924663932776, 35.861609190532],
              [-81.54807699338717, 35.86081468882702],
              [-81.547745677780554, 35.860774101068344],
              [-81.547948783661042, 35.859714765048544],
              [-81.547617473735301, 35.859674176902736],
              [-81.547668249596455, 35.859409342709512],
              [-81.547719026231647, 35.859144508447265],
              [-81.547056411619749, 35.859063329990136],
              [-81.546725105919833, 35.859022739387484],
              [-81.546775884296125, 35.858757905505065],
              [-81.545781970160519, 35.858636128741729],
              [-81.545832752491478, 35.858371295193002],
              [-81.545501450243464, 35.85833070125593],
              [-81.545552233301805, 35.858065867795631],
              [-81.545220931340026, 35.858025273115267],
              [-81.545271715125736, 35.857760439743281],
              [-81.54494041563548, 35.857719843372315],
              [-81.544991200148516, 35.857455010088742],
              [-81.54399730580532, 35.857333217799578],
              [-81.544048093166168, 35.85706838487291],
              [-81.543054204999393, 35.856946584880511],
              [-81.543104996314625, 35.856681752287521],
              [-81.541779822450366, 35.856519339594129],
              [-81.541830616566514, 35.856254507515345],
              [-81.540836742830251, 35.856132688490838],
              [-81.540938338638341, 35.855603024955258],
              [-81.540607050169541, 35.855562416780572],
              [-81.540657848634339, 35.855297585078425],
              [-81.540326561586909, 35.85525697703838],
              [-81.540377360750568, 35.854992144523699],
              [-81.540046075096186, 35.854951535717355],
              [-81.540096874987, 35.854686703291115],
              [-81.540198473798029, 35.854157039202541],
              [-81.539867192763737, 35.854116429875262],
              [-81.540070389524104, 35.85305710170951],
              [-81.539739113034656, 35.853016491117614],
              [-81.539993107057072, 35.851692330097265],
              [-81.540324378246353, 35.851732940017946],
              [-81.540578357510753, 35.850408776306679],
              [-81.540909623704621, 35.850449383754501],
              [-81.541163588211774, 35.849125217353084],
              [-81.541494849467099, 35.849165824130218],
              [-81.541596429934614, 35.848636157000293],
              [-81.541927688267748, 35.848676761730509],
              [-81.541978478048989, 35.848411927939885],
              [-81.54230973568346, 35.848452532536292],
              [-81.542360522965126, 35.848187698588823],
              [-81.542691779844148, 35.848228301249513],
              [-81.54274256573315, 35.84796346712232],
              [-81.543405078398152, 35.848044670374534],
              [-81.54345586183436, 35.847779835933295],
              [-81.543787117591961, 35.847820435623873],
              [-81.54383789963552, 35.847555601002838],
              [-81.544500410057537, 35.84763679831498],
              [-81.54455118962008, 35.847371962478938],
              [-81.544882444284553, 35.847412560100508],
              [-81.54493322245429, 35.847147724084685],
              [-81.545264476391608, 35.847188320671521],
              [-81.545315252062068, 35.84692348449903],
              [-81.545646505272146, 35.846964080051151],
              [-81.545849602656844, 35.84590473612468],
              [-81.545518354792975, 35.84586414108621],
              [-81.545569128827964, 35.845599305587079],
              [-81.54523788235683, 35.845558709782374],
              [-81.545288657119059, 35.845293874371848],
              [-81.544626166216347, 35.845212681253436],
              [-81.544676943843839, 35.84494784514176],
              [-81.544345700519017, 35.844907247804997],
              [-81.544396477766952, 35.844642411805061],
              [-81.544065235834736, 35.844601813702063],
              [-81.544116014916412, 35.844336977767789],
              [-81.543784774376817, 35.844296378898449],
              [-81.543835553078935, 35.844031543075957],
              [-81.543173075089442, 35.843950342003382],
              [-81.543223856685003, 35.84368550638083],
              [-81.542892619235118, 35.843644904177488],
              [-81.542943401586058, 35.843380069544693],
              [-81.541618459381795, 35.8432176531647],
              [-81.541669246717518, 35.842952818099292],
              [-81.541006779745942, 35.842871605225525],
              [-81.541057567761811, 35.842606770406142],
              [-81.540063874072473, 35.84248494427127],
              [-81.540114666041461, 35.842220109786204],
              [-81.539452207715556, 35.842138887520903],
              [-81.53950300036459, 35.841874053281899],
              [-81.539171773313967, 35.841833440921327],
              [-81.539222566689887, 35.841568606771126],
              [-81.538891341031857, 35.841527993644391],
              [-81.538992930011162, 35.840998325453207],
              [-81.538661706805527, 35.840957711694585],
              [-81.538763296572824, 35.840428043590187],
              [-81.538432075819372, 35.840387429199623],
              [-81.538482871816797, 35.840122595202082],
              [-81.538584461706478, 35.839592927093712],
              [-81.538253244464954, 35.839552312205647],
              [-81.538608807586655, 35.837698470598639],
              [-81.538277598095561, 35.837657855750145],
              [-81.538379187310909, 35.83712818643783],
              [-81.538710393576224, 35.837168801040818],
              [-81.539218302391674, 35.834520448752379],
              [-81.538887107828685, 35.834479835469196],
              [-81.538937897488296, 35.834214999864251],
              [-81.539090265576775, 35.833420492754875],
              [-81.538759075584707, 35.83337987910847],
              [-81.538860654027857, 35.832850207759741],
              [-81.538529466487589, 35.832809593481592],
              [-81.538580257375969, 35.832544757850535],
              [-81.538249070121509, 35.832504142829372],
              [-81.538299861708282, 35.832239306386413],
              [-81.537968676952588, 35.832198690576128],
              [-81.538019468187855, 35.831933855146261],
              [-81.537688284824441, 35.831893238570046],
              [-81.537840661896439, 35.831098731464664],
              [-81.5375094820442, 35.83105811439102],
              [-81.537560274067204, 35.830793279048834],
              [-81.537229096713659, 35.830752661186075],
              [-81.537279889435013, 35.830487825031952],
              [-81.536948712367249, 35.830447206426307],
              [-81.536999506949968, 35.830182371239168],
              [-81.536668331246148, 35.830141750966533],
              [-81.536719126555383, 35.829876915868503],
              [-81.536387953378608, 35.829836295707885],
              [-81.536438748279807, 35.829571459821025],
              [-81.536107576495155, 35.82953083889447],
              [-81.536463144517427, 35.827676989949289],
              [-81.536131980452893, 35.827636368161563],
              [-81.536182775945079, 35.827371533059925],
              [-81.534526965996264, 35.827168413992474],
              [-81.534195804988016, 35.827127786937673],
              [-81.534145003168092, 35.827392622134361],
              [-81.533813841433371, 35.82735199404496],
              [-81.53376303822138, 35.82761682906218],
              [-81.533431875788423, 35.827576200839232],
              [-81.533381071155915, 35.827841034775894],
              [-81.532718745141707, 35.827759774459587],
              [-81.532667938086007, 35.828024608983412],
              [-81.53233677450477, 35.827983976890017],
              [-81.532285964950347, 35.828248811257318],
              [-81.530630146797634, 35.82804563841686],
              [-81.530528517175028, 35.828575304748384],
              [-81.53019735241331, 35.828534666850437],
              [-81.530146535475907, 35.828799499374981],
              [-81.529484204860921, 35.828718221510584],
              [-81.529433385471918, 35.828983053721167],
              [-81.526784066665257, 35.828657902498769],
              [-81.526834895606953, 35.828393070439212],
              [-81.526503733448706, 35.828352422504274],
              [-81.526554563144941, 35.82808759143505],
              [-81.525229922079475, 35.82792499123039],
              [-81.525280755651565, 35.827660159752561],
              [-81.52329381258528, 35.827416232298638],
              [-81.523344653286287, 35.827151401559213],
              [-81.52301349796943, 35.827110744125072],
              [-81.523064338290268, 35.826845913497905],
              [-81.522070879700777, 35.826723935250655],
              [-81.52202003620225, 35.826988765474532],
              [-81.521357729644762, 35.826907441580403],
              [-81.521306882560225, 35.827172270612131],
              [-81.520975729289219, 35.827131607619442],
              [-81.520924880812217, 35.827396436471417],
              [-81.520593726815122, 35.827355772444058],
              [-81.520390326501555, 35.828415088641499],
              [-81.520059168572544, 35.828374422275076],
              [-81.520008316311291, 35.828639250632129],
              [-81.519677156577856, 35.828598584154932],
              [-81.519626302924166, 35.828863412332304],
              [-81.519295142464756, 35.828822744820421],
              [-81.519244288525073, 35.829087572795224],
              [-81.517919645697759, 35.828924890504418],
              [-81.517970506087337, 35.828660063021715],
              [-81.516645873934891, 35.828497366842555],
              [-81.516544145455086, 35.829027020663943],
              [-81.516212985876024, 35.828986344781136],
              [-81.516111253944715, 35.829515998152552],
              [-81.515780093658805, 35.829475320176591],
              [-81.515729225580742, 35.829740146671327],
              [-81.515398063462399, 35.829699467683582],
              [-81.515347193991886, 35.829964293998536],
              [-81.51468486977106, 35.829882933931572],
              [-81.514633997848648, 35.830147759932267],
              [-81.513971671708106, 35.830066395117207],
              [-81.514022545749029, 35.829801569385452],
              [-81.513691384237845, 35.829760885762113],
              [-81.5137422590048, 35.829496060119645],
              [-81.513411098886181, 35.829455375730603],
              [-81.513461974350932, 35.829190549276326],
              [-81.51313081673139, 35.829149864098795],
              [-81.51318169295007, 35.828885038634908],
              [-81.511857067829368, 35.828722289506373],
              [-81.511907947951556, 35.82845746453529],
              [-81.510583332373187, 35.828294700640754],
              [-81.510532448014246, 35.828559525073686],
              [-81.510201293347762, 35.828518831951619],
              [-81.510150407596242, 35.828783656204678],
              [-81.509156943497885, 35.828661570110114],
              [-81.50910605312896, 35.828926393937039],
              [-81.508112589960206, 35.828804299314243],
              [-81.508061696080304, 35.829069122692232],
              [-81.507730541648556, 35.829028421941423],
              [-81.507679645269576, 35.829293245162319],
              [-81.507348490140046, 35.829252544277807],
              [-81.507297593474959, 35.829517367296113],
              [-81.5069664364853, 35.829476664498543],
              [-81.506915538427478, 35.82974148733706],
              [-81.506584380740023, 35.829700784405695],
              [-81.506533481289608, 35.829965607064281],
              [-81.506202322848523, 35.829924902197135],
              [-81.506151422005331, 35.830189724675854],
              [-81.505820262838625, 35.830149018773973],
              [-81.505769360602741, 35.830413841072883],
              [-81.505438200738169, 35.830373135037156],
              [-81.505387295975183, 35.830637956277883],
              [-81.504724976206788, 35.830556540312969],
              [-81.504674069019813, 35.830821362140142],
              [-81.503018268437984, 35.830617804484028],
              [-81.503069180920974, 35.830352983329853],
              [-81.50273802377194, 35.830312269935241],
              [-81.502788936980437, 35.830047448870395],
              [-81.502126624639274, 35.829966017870561],
              [-81.502177539632655, 35.82970119702965],
              [-81.501515230744047, 35.829619762698307],
              [-81.501566148628612, 35.829354942058629],
              [-81.501234995190615, 35.829314223688662],
              [-81.497923487983499, 35.828906989397396],
              [-81.497872558448478, 35.829171808556069],
              [-81.497541408727741, 35.829131080126899],
              [-81.497490477800014, 35.829395899105627],
              [-81.497159327326017, 35.829355168740577],
              [-81.497108393898941, 35.829619987561991],
              [-81.496777242727347, 35.829579257063202],
              [-81.496726309014008, 35.82984407568194],
              [-81.495732853189764, 35.829721877502273],
              [-81.495681915937425, 35.829986694770575],
              [-81.492370409054345, 35.829579306115775],
              [-81.492319459798537, 35.829844122915524],
              [-81.491988308987899, 35.829803378532077],
              [-81.4919373583392, 35.830068195151846],
              [-81.491606207909641, 35.830027449710826],
              [-81.491555255868121, 35.830292266150565],
              [-81.491224104713268, 35.83025151967469],
              [-81.491173150172273, 35.830516335956986],
              [-81.490841998292154, 35.830475588446227],
              [-81.490791043464768, 35.830740404525848],
              [-81.48913528156352, 35.830536652841502],
              [-81.489186242820722, 35.830271838314047],
              [-81.488523943400736, 35.830190331590558],
              [-81.488472980053118, 35.830455146749607],
              [-81.486817230944325, 35.830251363537357],
              [-81.486766261939763, 35.830516177076255],
              [-81.486103962957998, 35.830434657211804],
              [-81.486154934053062, 35.830169843041404],
              [-81.485823786122182, 35.830129081893681],
              [-81.485874757970095, 35.829864268713735],
              [-81.484219028592676, 35.829660449245992],
              [-81.484168051449558, 35.829925261752138],
              [-81.483836905522466, 35.829884495203579],
              [-81.483734947037604, 35.830414120733757],
              [-81.482741504878348, 35.830291813977681],
              [-81.482792486897822, 35.830027001200399],
              [-81.482130197010463, 35.829945458809036],
              [-81.482181181948121, 35.829680647134502],
              [-81.481187751125773, 35.829558326784216],
              [-81.481238738906555, 35.829293515468748],
              [-81.480907598123395, 35.829252740030299],
              [-81.480958586629029, 35.828987928804324],
              [-81.48029630710414, 35.828906376420356],
              [-81.480347297366052, 35.828641564517596],
              [-81.479353883194023, 35.8285192291561],
              [-81.479404877405244, 35.828254417589982],
              [-81.479073740724587, 35.828213638078914],
              [-81.47912473566079, 35.827948826602274],
              [-81.478793601451983, 35.827908045402481],
              [-81.478844596006596, 35.827643234037801],
              [-81.478182330736757, 35.827561670108636],
              [-81.478233327075188, 35.82729685896831],
              [-81.477902195986516, 35.827256075337921],
              [-81.47795319418374, 35.826991265165759],
              [-81.477622064488173, 35.826950480770051],
              [-81.477673062276168, 35.826685669808853],
              [-81.477341933973619, 35.826644884647934],
              [-81.477443932877961, 35.826115262814355],
              [-81.477112807027225, 35.826074477023134],
              [-81.477163807037229, 35.825809666173186],
              [-81.476832682579484, 35.825768879616732],
              [-81.476883683314256, 35.825504068856361],
              [-81.476552560277113, 35.825463282435798],
              [-81.476603561736638, 35.825198471765056],
              [-81.476272441171261, 35.825157683655661],
              [-81.476323442249054, 35.824892873097028],
              [-81.474336729986263, 35.824648125493113],
              [-81.474387738189449, 35.824383315675952],
              [-81.474056622080695, 35.824342521536018],
              [-81.474107631008565, 35.824077711808428],
              [-81.473776516292787, 35.824036916903424],
              [-81.473827525945211, 35.823772107265505],
              [-81.473496412594926, 35.823731310694221],
              [-81.47354742297199, 35.82346650114593],
              [-81.473216311042236, 35.823425704710566],
              [-81.473267323250312, 35.823160895229485],
              [-81.472936212713407, 35.823120098029065],
              [-81.472987224512252, 35.822855287758941],
              [-81.472656115368309, 35.822814489793402],
              [-81.472707129025594, 35.822549680491647],
              [-81.47237602124703, 35.822508880859964],
              [-81.472478048570295, 35.821979262412917],
              [-81.472146943243516, 35.821938462150932],
              [-81.472248971319999, 35.821408842891792],
              [-81.471917869578704, 35.821368042878326],
              [-81.472019898435946, 35.820838423708175],
              [-81.471688798039906, 35.820797623086975],
              [-81.472351967250518, 35.817355092666354],
              [-81.472020882030208, 35.817314291975499],
              [-81.472071894270144, 35.817049481543755],
              [-81.471740810470152, 35.817008680988913],
              [-81.470416480768876, 35.816845467923144],
              [-81.470467497967775, 35.816580658120962],
              [-81.470136417975851, 35.816539852277835],
              [-81.470187434792791, 35.816275042587932],
              [-81.469856356221158, 35.816234236880938],
              [-81.469907374868868, 35.815969427258416],
              [-81.469576297662456, 35.815928619885305],
              [-81.469678334966062, 35.815399000797257],
              [-81.469347260210967, 35.815358192794172],
              [-81.469398279973234, 35.815093383306284],
              [-81.469067206610873, 35.815052574538157],
              [-81.469118227097397, 35.81478776514016],
              [-81.468787155127856, 35.81474695560717],
              [-81.468838176338707, 35.814482146298936],
              [-81.468176036625351, 35.814400524780737],
              [-81.468227059619153, 35.814135715697361],
              [-81.467895990769165, 35.814094903734564],
              [-81.467947014487251, 35.813830094741178],
              [-81.466953814251838, 35.81370765473693],
              [-81.46690278735835, 35.813972463325591],
              [-81.466571719845263, 35.813931647763177],
              [-81.466622748903475, 35.813666839287201],
              [-81.46562955265135, 35.813544387583413],
              [-81.465578521523923, 35.813809195632096],
              [-81.464254261037681, 35.813645913540739],
              [-81.464203224263301, 35.813910721927947],
              [-81.461885773292991, 35.813624941788291],
              [-81.461936818582856, 35.813360134323695],
              [-81.461605756084083, 35.813319305263327],
              [-81.461656802125574, 35.813054498789739],
              [-81.460663621995693, 35.812932005668785],
              [-81.460714669744476, 35.8126671986765],
              [-81.460052554202292, 35.812585532952461],
              [-81.460001504309275, 35.8128503387736],
              [-81.459670444875087, 35.812809504449142],
              [-81.459721496905331, 35.812544697839556],
              [-81.458397271116255, 35.812381350191941],
              [-81.458448327073128, 35.812116544978501],
              [-81.457786218301763, 35.812034866025684],
              [-81.457837276013606, 35.811770060136332],
              [-81.457175170696672, 35.811688377854352],
              [-81.457226230218126, 35.811423573091055],
              [-81.456564129434568, 35.811341886556527],
              [-81.45661519073829, 35.811077082018301],
              [-81.455622044720528, 35.810954546785304],
              [-81.455673108837459, 35.810689741706177],
              [-81.455011015659011, 35.810608047614849],
              [-81.45506208266444, 35.810343242738561],
              [-81.453075818557934, 35.810098137855391],
              [-81.453126890472504, 35.809833333766846],
              [-81.451802726781395, 35.809669912425512],
              [-81.4518538037008, 35.809405108810182],
              [-81.45119172597893, 35.809323393010999],
              [-81.451242804680248, 35.809058589620832],
              [-81.450580730413165, 35.808976870492778],
              [-81.450631810896425, 35.808712067327882],
              [-81.450300775322916, 35.808671206549349],
              [-81.450351856529963, 35.808406403474592],
              [-81.450020822349231, 35.808365541931309],
              [-81.450071905386039, 35.808100738924438],
              [-81.449740872598099, 35.808059876616561],
              [-81.449791955252479, 35.807795073722069],
              [-81.449129893902892, 35.807713346655106],
              [-81.449180978339129, 35.807448543985899],
              [-81.448849949750183, 35.807407678325781],
              [-81.447194808587668, 35.807203339298745],
              [-81.447245900171893, 35.806938538274252],
              [-81.445921799035261, 35.806775051006468],
              [-81.44597289449014, 35.806510249576576],
              [-81.44531084857185, 35.806428499902445],
              [-81.445361945808372, 35.806163698697922],
              [-81.445030923330407, 35.806122823569737],
              [-81.44497982503573, 35.806387624639086],
              [-81.444648802913179, 35.806346747552752],
              [-81.444699902265981, 35.806081946618562],
              [-81.44271378296213, 35.805836667819527],
              [-81.442764889435111, 35.805571867629304],
              [-81.442433871771669, 35.805530984515272],
              [-81.442484978968054, 35.805266184415302],
              [-81.442815994440139, 35.805307066515191],
              [-81.442918204606599, 35.804777465932155],
              [-81.442587191223453, 35.804736583201667],
              [-81.442689402167289, 35.804206982709374],
              [-81.442358390128874, 35.804166099371592],
              [-81.442562812205296, 35.803106897487879],
              [-81.442231805839882, 35.803066013769119],
              [-81.442282912112987, 35.802801212634236],
              [-81.441951906061405, 35.802760329074218],
              [-81.442003013057743, 35.802495528029652],
              [-81.441010003321892, 35.802372869587565],
              [-81.441061112078785, 35.802108069827042],
              [-81.439737108015592, 35.801944513183187],
              [-81.439788221748543, 35.801679712995337],
              [-81.439457223733726, 35.801638821698326],
              [-81.439508338189924, 35.801374021600878],
              [-81.43917734046164, 35.801333129561598],
              [-81.439228455668228, 35.801068330455657],
              [-81.438897459305565, 35.801027436750879],
              [-81.43915303663195, 35.799703438499115],
              [-81.438491056623292, 35.799621650621894],
              [-81.438439939943777, 35.799886450447495],
              [-81.437446968728224, 35.799763761940468],
              [-81.437395848540092, 35.800028561315465],
              [-81.437064858493045, 35.799987663497603],
              [-81.437115979738991, 35.799722864257866],
              [-81.436784991084551, 35.799681965675575],
              [-81.436733867647376, 35.799946763901183],
              [-81.435409911012727, 35.799783159156135],
              [-81.435461038654267, 35.799518360570559],
              [-81.434468077556971, 35.799395646850854],
              [-81.434519208064557, 35.799130849527259],
              [-81.433526254260428, 35.79900812809494],
              [-81.43357738757966, 35.798743330231297],
              [-81.432584439962653, 35.798620601108475],
              [-81.432635576120603, 35.798355803605972],
              [-81.431311654875202, 35.798192152422097],
              [-81.431362796035657, 35.797927355393796],
              [-81.42937693275988, 35.797681851947914],
              [-81.429428079932279, 35.797417055686765],
              [-81.429097104576371, 35.797376135289724],
              [-81.429199400031877, 35.796846542903609],
              [-81.428868427126659, 35.796805621877731],
              [-81.428919575409736, 35.796540825752849],
              [-81.428970723357878, 35.796276029583119],
              [-81.429301693041722, 35.79631695036047],
              [-81.429352839597172, 35.796052154010617],
              [-81.429683808558082, 35.796093073753092],
              [-81.429734953720867, 35.795828277223166],
              [-81.430065921958928, 35.795869195930699],
              [-81.430117065729149, 35.795604399220771],
              [-81.430448033217274, 35.795645315992367],
              [-81.430550316531424, 35.795115722189649],
              [-81.429888386066892, 35.79503388648596],
              [-81.429939529890049, 35.794769089777084],
              [-81.429608566217851, 35.79472817071121],
              [-81.429710853841968, 35.79419857655089],
              [-81.429379892620105, 35.794157656856207],
              [-81.429482182126023, 35.793628062765507],
              [-81.429813140126512, 35.793668982211578],
              [-81.429915425071513, 35.793139387693422],
              [-81.43024638239747, 35.793180305947274],
              [-81.430399803590859, 35.792385913890449],
              [-81.430068850543876, 35.792344996020418],
              [-81.430119992048901, 35.792080198098674],
              [-81.429458087968939, 35.791998359952913],
              [-81.429560373098965, 35.79146876453872],
              [-81.429229424229561, 35.791427844376265],
              [-81.429331710162401, 35.790898249955085],
              [-81.429000763743119, 35.790857329163813],
              [-81.429103050424629, 35.790327733933601],
              [-81.428772107561386, 35.790286812491473],
              [-81.428925538797785, 35.78949241970632],
              [-81.428594598336247, 35.789451497792804],
              [-81.42879917212295, 35.788392307343877],
              [-81.42846823730541, 35.788351384149117],
              [-81.428672811070285, 35.787292193505856],
              [-81.428341880817726, 35.787251269953039],
              [-81.428495309388509, 35.786456876035132],
              [-81.428826236441381, 35.786497798280827],
              [-81.429133079275772, 35.784909007506549],
              [-81.428802159673552, 35.784868086050849],
              [-81.428853299465061, 35.784603287128455],
              [-81.429184219142769, 35.784644209327837],
              [-81.42933763444168, 35.783849811865416],
              [-81.429006719069221, 35.7838088909511],
              [-81.429057857521414, 35.783544091850651],
              [-81.429388771863515, 35.783585013530711],
              [-81.429491046754407, 35.783055414903622],
              [-81.429821959289399, 35.783096334512543],
              [-81.429873094622252, 35.782831535007873],
              [-81.430534919687645, 35.782913371234905],
              [-81.430483786469637, 35.783178171010157],
              [-81.431807443768705, 35.78334183413601],
              [-81.431858572757008, 35.783077033819453],
              [-81.432189486578011, 35.783117946997365],
              [-81.432240614174233, 35.782853146500997],
              [-81.432571527272557, 35.782894058644203],
              [-81.432622653476514, 35.782629257967962],
              [-81.432953565852145, 35.782670169076539],
              [-81.433209184325392, 35.781346163495314],
              [-81.433540091748682, 35.781387073027972],
              [-81.433642334470008, 35.780857470578582],
              [-81.4339732389803, 35.780898378062183],
              [-81.434075478247905, 35.780368775164497],
              [-81.434406382111035, 35.780409682357188],
              [-81.43461085124089, 35.779350473707616],
              [-81.434941750103448, 35.779391379481872],
              [-81.43499286630761, 35.779126576606025],
              [-81.434661967396622, 35.779085670989268],
              [-81.434968658490718, 35.777496856428236],
              [-81.435299549924849, 35.777537760354264],
              [-81.435350662707961, 35.77727295809111],
              [-81.435681553419414, 35.777313860982488],
              [-81.435732664810516, 35.777049058539717],
              [-81.436063554799276, 35.777089960396495],
              [-81.436114664798552, 35.776825157774077],
              [-81.436445554064605, 35.776866058596234],
              [-81.436496662644757, 35.776601254893116],
              [-81.436827551215274, 35.77664215558174],
              [-81.436929764151557, 35.77611254779405],
              [-81.437260650942065, 35.77615344731278],
              [-81.438129388206903, 35.771651767171811],
              [-81.437798520494965, 35.771610869930733],
              [-81.437849620220973, 35.771346065229089],
              [-81.437518755006394, 35.77130516720193],
              [-81.437620955542116, 35.770775557035456],
              [-81.437290092776351, 35.770734658379624],
              [-81.437341193585951, 35.770469852914871],
              [-81.43667947006665, 35.770388053198083],
              [-81.436730573788878, 35.770123248838694],
              [-81.43639971414099, 35.770082347755725],
              [-81.436604127592872, 35.769023126857384],
              [-81.436273272507947, 35.768982225416337],
              [-81.436426581702662, 35.768187809695903],
              [-81.43675743361635, 35.768228710731023],
              [-81.436859636327981, 35.767699099752093],
              [-81.437190486462072, 35.767739999617405],
              [-81.437343784269601, 35.766945581522087],
              [-81.437012937306662, 35.766904682062638],
              [-81.437217334674614, 35.765845458144788],
              [-81.436886492274297, 35.765804558327289],
              [-81.437141986170076, 35.76448052744982],
              [-81.437472822179842, 35.764521426612902],
              [-81.437575014365663, 35.763991812619288],
              [-81.43790584859596, 35.764032710612618],
              [-81.437956943130288, 35.763767903414454],
              [-81.438287776638063, 35.763808800373326],
              [-81.438338869808149, 35.763543993896825],
              [-81.439000535685935, 35.7636257839455],
              [-81.439051626380405, 35.763360976253395],
              [-81.439713290403589, 35.763442763358036],
              [-81.440173069263963, 35.761059489779761],
              [-81.439842247150324, 35.761018597883385],
              [-81.439893333443763, 35.760753789615976],
              [-81.439944418297372, 35.760488981326759],
              [-81.440275238297204, 35.760529872952503],
              [-81.440377405965776, 35.760000255048375],
              [-81.440708225291743, 35.760041145482298],
              [-81.440759307028372, 35.75977633679058],
              [-81.441751760498107, 35.759899001457299],
              [-81.441700681932033, 35.760163810554808],
              [-81.442362321354892, 35.760245582473821],
              [-81.442413397807172, 35.759980773105838],
              [-81.443075037559069, 35.760062541135639],
              [-81.443023963220568, 35.760327350774197],
              [-81.443685605318095, 35.760409115500181],
              [-81.443634532759106, 35.760673925365211],
              [-81.443965356485336, 35.760714806943177],
              [-81.443914284649068, 35.760979616899498],
              [-81.444575934085506, 35.761061376749524],
              [-81.444627002675233, 35.760796565643766],
              [-81.444957827404352, 35.760837444524327],
              [-81.44500889573554, 35.760572634118262],
              [-81.445339718609205, 35.760613511085488],
              [-81.445390784443774, 35.760348700522499],
              [-81.446052430185588, 35.760430451466952],
              [-81.446001366464884, 35.760695262300374],
              [-81.44633219036858, 35.760736137471262],
              [-81.446281127370654, 35.76100094839596],
              [-81.446611952638293, 35.761041821901927],
              [-81.446560890363088, 35.761306632917872],
              [-81.44689171704907, 35.761347506560909],
              [-81.446840655496572, 35.761612317668195],
              [-81.447171484652159, 35.761653189623999],
              [-81.44712042271685, 35.761918000844666],
              [-81.447451253263637, 35.761958872036537],
              [-81.447349132715175, 35.762488494572025],
              [-81.447679965710165, 35.76252936513513],
              [-81.447628904886145, 35.762794176494388],
              [-81.448621409074747, 35.762916784095367],
              [-81.448672465622309, 35.76265197235282],
              [-81.449003299954171, 35.76269283931925],
              [-81.449054355110405, 35.762428027397441],
              [-81.449385188719461, 35.762468893329569],
              [-81.449436242457324, 35.762204080327592],
              [-81.449767075343559, 35.762244945225277],
              [-81.449716022635428, 35.762509757461359],
              [-81.450046856912849, 35.76255062159516],
              [-81.450097908563976, 35.762285809223791],
              [-81.450428742118561, 35.762326672323226],
              [-81.450377691524452, 35.762591484829606],
              [-81.451039361750162, 35.762673208601221],
              [-81.450988314041581, 35.762938021311882],
              [-81.451649987717985, 35.763019741757013],
              [-81.451598940683667, 35.763284554716236],
              [-81.45192978015848, 35.763325412801933],
              [-81.451878733847067, 35.763590225852319],
              [-81.452209573634732, 35.763631084097248],
              [-81.452158529151873, 35.763895897216557],
              [-81.452489370330753, 35.763936754697532],
              [-81.452438326570743, 35.764201567907996],
              [-81.452769169113495, 35.764242423723779],
              [-81.452718126076533, 35.764507237025391],
              [-81.453379815438808, 35.764588948009624],
              [-81.453583978231052, 35.763529693435565],
              [-81.45325313816393, 35.763488838922569],
              [-81.453355219116744, 35.762959211652969],
              [-81.453686057069731, 35.763000065895675],
              [-81.453839172794744, 35.762205623354944],
              [-81.454170007910704, 35.762246476293058],
              [-81.45422104476954, 35.761981662190038],
              [-81.454551879135224, 35.762022513192761],
              [-81.454653949736723, 35.761492884584648],
              [-81.454984782322498, 35.761533734417895],
              [-81.455035816065077, 35.761268919912737],
              [-81.455342009212814, 35.759680029300988],
              [-81.456003659510031, 35.759761725301267],
              [-81.456054688800435, 35.759496910218495],
              [-81.456385512826728, 35.75953775629533],
              [-81.456436540726131, 35.759272941033416],
              [-81.45709818877225, 35.759354630197159],
              [-81.457149213118129, 35.759089814643467],
              [-81.457480036032536, 35.759130657752586],
              [-81.457633104946197, 35.758336208598365],
              [-81.457963925023691, 35.758377050403048],
              [-81.458065966454512, 35.757847417459189],
              [-81.458396785857445, 35.7578882580721],
              [-81.458447805001242, 35.757623440948777],
              [-81.458778622575693, 35.757664280549747],
              [-81.458829640328688, 35.757399463247438],
              [-81.459160457207531, 35.757440302715217],
              [-81.459262488492257, 35.756910667731219],
              [-81.459593303563764, 35.756951505128562],
              [-81.459695332504438, 35.756421869676707],
              [-81.460026145795993, 35.756462705904696],
              [-81.460077157603124, 35.756197888000273],
              [-81.460407970171659, 35.756238723194038],
              [-81.460458981693336, 35.755973905088297],
              [-81.461120604612873, 35.756055572530684],
              [-81.46117161368683, 35.755790754110969],
              [-81.461833235855366, 35.755872418587693],
              [-81.461884242454289, 35.755607598952928],
              [-81.462215052415985, 35.755648429268156],
              [-81.462164047979428, 35.755913249015649],
              [-81.462825673229119, 35.755994907174248],
              [-81.462876675579267, 35.755730088057668],
              [-81.463207487647935, 35.755770915653152],
              [-81.463309489205329, 35.755241276117289],
              [-81.46364029838854, 35.755282102565936],
              [-81.463691297623356, 35.755017283047763],
              [-81.46402210718891, 35.755058108439869],
              [-81.464073105005568, 35.754793287841828],
              [-81.464734721918788, 35.754874935680725],
              [-81.464785717315195, 35.754610115669877],
              [-81.46643976068016, 35.754814217945025],
              [-81.466490749325303, 35.754549396336422],
              [-81.466821558390379, 35.754590214130268],
              [-81.466923533698605, 35.754060571390326],
              [-81.467254339878053, 35.754101388037448],
              [-81.467305325408176, 35.753836566027481],
              [-81.467636131969883, 35.753877381617968],
              [-81.467687116109445, 35.753612559429243],
              [-81.468348726987244, 35.753694186764122],
              [-81.468399708706798, 35.753429365162646],
              [-81.468730513022891, 35.753470176907044],
              [-81.468781493351841, 35.753205355126816],
              [-81.469112298050035, 35.753246165814666],
              [-81.469163276988411, 35.752981343855701],
              [-81.47048649162933, 35.75314457894531],
              [-81.470435516918641, 35.75340940144438],
              [-81.47109712890753, 35.753491013853342],
              [-81.471046157109981, 35.753755837457362],
              [-81.471707772547276, 35.753837446539869],
              [-81.471656802529878, 35.754102270370133],
              [-81.472318421387996, 35.754183875225067],
              [-81.472267453150977, 35.754448699281596],
              [-81.472929075457458, 35.754530300809904],
              [-81.472878109000661, 35.754795125092727],
              [-81.475855440407116, 35.755162289477553],
              [-81.475906396273189, 35.7548974649035],
              [-81.476237211481632, 35.754938256215382],
              [-81.476339121176949, 35.754408605742611],
              [-81.476669934604942, 35.754449395885416],
              [-81.476720888434215, 35.754184569986705],
              [-81.477051701138834, 35.7542253590954],
              [-81.477102652472183, 35.753960533040491],
              [-81.477433464453213, 35.754001321115098],
              [-81.477484414396173, 35.753736494881444],
              [-81.477815225653714, 35.753777281922041],
              [-81.477866174206127, 35.753512455509714],
              [-81.478196984740293, 35.753553241516229],
              [-81.478451718348651, 35.752229109003331],
              [-81.47812091309936, 35.752188323671554],
              [-81.478171859650928, 35.751923496997172],
              [-81.478502663843258, 35.751964282193924],
              [-81.478553609004535, 35.751699455340933],
              [-81.478222805869123, 35.751658670279134],
              [-81.478273751753861, 35.751393843517349],
              [-81.477942951114144, 35.751353057668886],
              [-81.477993897722328, 35.751088230998462],
              [-81.478324698382835, 35.751129015788486],
              [-81.478375643600671, 35.750864188939396],
              [-81.478706442459924, 35.750904973618873],
              [-81.478757386287398, 35.750640146591259],
              [-81.47908818442319, 35.750680930236676],
              [-81.479139126832635, 35.750416102129414],
              [-81.479469924244967, 35.750456884740835],
              [-81.479520865291761, 35.750192057356024],
              [-81.480182459002876, 35.750273619611626],
              [-81.480233396469487, 35.75000879103473],
              [-81.480564192768156, 35.750049570678982],
              [-81.480666065720413, 35.749519914003137],
              [-81.480335272640787, 35.74947913460629],
              [-81.480640889837346, 35.747890164342024],
              [-81.48097167768114, 35.74793094290677],
              [-81.481073545271641, 35.747401284632389],
              [-81.481404330229765, 35.747442062050794],
              [-81.481506194400893, 35.746912404233385],
              [-81.481836977551097, 35.74695317958173],
              [-81.48188790751324, 35.746688350033537],
              [-81.482218689967581, 35.74672912524894],
              [-81.482269619644981, 35.746464295499749],
              [-81.483592749645993, 35.746627385907587],
              [-81.483643672552134, 35.746362555620294],
              [-81.483974455637082, 35.746403325146929],
              [-81.484025378258337, 35.746138494658666],
              [-81.484356159542074, 35.746179264074961],
              [-81.484407079668031, 35.745914433430812],
              [-81.484737861333613, 35.745955201790586],
              [-81.484788780041725, 35.74569037006696],
              [-81.485781121629046, 35.74581266849134],
              [-81.485730204958429, 35.746077499741141],
              [-81.486060986823972, 35.746118263626215],
              [-81.48601007198215, 35.746383094944825],
              [-81.488325564035236, 35.746668419667095],
              [-81.488376471479086, 35.74640358740438],
              [-81.488707257143361, 35.746444344975266],
              [-81.489216301500051, 35.743796015926051],
              [-81.489547074718601, 35.743836771294468],
              [-81.489750678220517, 35.742777437210947],
              [-81.490081448622647, 35.742818190217214],
              [-81.490132346765719, 35.742553356019293],
              [-81.490463116444189, 35.742594107991657],
              [-81.490615806837368, 35.741799605611178],
              [-81.490946573678656, 35.741840356280051],
              [-81.491099257903699, 35.74104585310431],
              [-81.491430020802582, 35.741086602492153],
              [-81.491480913443738, 35.7408217675858],
              [-81.492473202073853, 35.740944009928128],
              [-81.49252409123946, 35.740679175474938],
              [-81.492854853329376, 35.740719920095962],
              [-81.492905741105133, 35.740455085464568],
              [-81.493236502471348, 35.740495829051717],
              [-81.493338274882717, 35.73996615848808],
              [-81.493669034468496, 35.740006900906565],
              [-81.493770803406477, 35.739477228998744],
              [-81.493821686270778, 35.739212393002354],
              [-81.493490929827374, 35.739171650087265],
              [-81.493541814520597, 35.738906814159812],
              [-81.493211060572023, 35.738866070457917],
              [-81.49336371469056, 35.738071561941481],
              [-81.493032964245145, 35.738030817745077],
              [-81.493083848614447, 35.737765981936519],
              [-81.494076099990124, 35.737888211379044],
              [-81.494126981961642, 35.7376233751001],
              [-81.49478848203421, 35.737704855737043],
              [-81.494839360454222, 35.737440019167707],
              [-81.495170110499771, 35.737480758442132],
              [-81.495220987502364, 35.737215920793695],
              [-81.495882485373855, 35.737297396398446],
              [-81.495933359930135, 35.737032558437086],
              [-81.496925605511848, 35.737154764708826],
              [-81.496976477670387, 35.736889926277108],
              [-81.49730722543832, 35.736930659775688],
              [-81.497358095102172, 35.736665821188545],
              [-81.497688842146346, 35.736706553653512],
              [-81.497739710420561, 35.736441714888301],
              [-81.498070457846254, 35.736482446296748],
              [-81.498121324730889, 35.736217607353389],
              [-81.49845207032763, 35.736258337750812],
              [-81.498553799907597, 35.735728660388595],
              [-81.498884544801257, 35.735769388693626],
              [-81.498986270936342, 35.735239710888763],
              [-81.500309241021455, 35.735402615933921],
              [-81.500360099901769, 35.735137775965654],
              [-81.501683071085594, 35.735300666088257],
              [-81.501784778289519, 35.734770984966495],
              [-81.502115519790237, 35.734811704529612],
              [-81.502369773930312, 35.733487500352012],
              [-81.503031246286469, 35.733568936357599],
              [-81.503082093975095, 35.733304094221893],
              [-81.50374356665418, 35.733385526339426],
              [-81.503845255757227, 35.732855843224328],
              [-81.503514521350596, 35.732815127433973],
              [-81.50381958916833, 35.731226076013222],
              [-81.503488861461818, 35.731185361033496],
              [-81.503590550544857, 35.730655676842972],
              [-81.50392127503315, 35.73069639157594],
              [-81.503972116927883, 35.730431549754471],
              [-81.504302840692318, 35.73047226345377],
              [-81.504353682274925, 35.730207420530668],
              [-81.50468440531553, 35.730248133196284],
              [-81.504735244431785, 35.72998329101911],
              [-81.505065966748532, 35.730024002651113],
              [-81.505218479980812, 35.729229473631804],
              [-81.505549199460646, 35.729270183960658],
              [-81.505600036210311, 35.729005340417778],
              [-81.505269317786997, 35.728964630223665],
              [-81.505370990191125, 35.728434943323506],
              [-81.505040275318564, 35.728394232477228],
              [-81.505091112078176, 35.728129389097241],
              [-81.504760397489704, 35.728088677509533],
              [-81.504912910046997, 35.727294147492834],
              [-81.504582200065585, 35.727253435387659],
              [-81.504683875893903, 35.726723747855388],
              [-81.504353168358179, 35.726683035120921],
              [-81.504658193120534, 35.725093973223501],
              [-81.50498889324065, 35.725134686073339],
              [-81.505039729198359, 35.72486984152259],
              [-81.505370428594617, 35.724910553338823],
              [-81.505421263163385, 35.724645708610332],
              [-81.50575196180796, 35.724686418491913],
              [-81.50580279391059, 35.724421574509506],
              [-81.506133492936399, 35.724462283334731],
              [-81.506184323650004, 35.724197439174588],
              [-81.50651502195204, 35.724238146966307],
              [-81.506667507767133, 35.723443612044363],
              [-81.506998203232598, 35.723484318532968],
              [-81.50704903047513, 35.723219473030404],
              [-81.506718336066058, 35.723178766676455],
              [-81.506972471494564, 35.721854540116787],
              [-81.507303159516866, 35.721895245819773],
              [-81.507404809706387, 35.721365555157334],
              [-81.50773549594858, 35.721406259692117],
              [-81.507786318369241, 35.721141413734081],
              [-81.508117003887563, 35.721182117235223],
              [-81.508167826024334, 35.720917271076779],
              [-81.508498510819038, 35.720957973544408],
              [-81.508549330461889, 35.72069312723108],
              [-81.508880014532807, 35.720733828665203],
              [-81.508930833891782, 35.720468982151502],
              [-81.509261516133847, 35.720509682574921],
              [-81.509312334104067, 35.720244835883534],
              [-81.509973698578051, 35.720326233741666],
              [-81.510024514075184, 35.720061385836971],
              [-81.510355195202123, 35.720102083294492],
              [-81.510406008233474, 35.71983723613598],
              [-81.510736689741535, 35.71987793253728],
              [-81.510838312666124, 35.71934823692132],
              [-81.511168992394119, 35.719388932154331],
              [-81.511372228951714, 35.718329540793043],
              [-81.511702904758963, 35.718370233687487],
              [-81.511753711189186, 35.718105385847643],
              [-81.512084386300629, 35.718146078609678],
              [-81.5121351913143, 35.717881229691343],
              [-81.512465865701785, 35.717921921419808],
              [-81.512516669326757, 35.717657072323945],
              [-81.513178015881536, 35.717738452837992],
              [-81.513228818166468, 35.7174736034071],
              [-81.513559490333733, 35.717514292192753],
              [-81.513610290124987, 35.717249442607134],
              [-81.514271634449145, 35.717330817189826],
              [-81.514322431795591, 35.717065967292051],
              [-81.515314447025176, 35.71718802113157],
              [-81.515365240870452, 35.716923170787048],
              [-81.516026583188108, 35.717004535865996],
              [-81.516077374588505, 35.716739685209376],
              [-81.516738717199829, 35.716821045500026],
              [-81.516789506155362, 35.716556194531314],
              [-81.517120176336761, 35.716596872754664],
              [-81.517170962826839, 35.716332022532406],
              [-81.518162973329026, 35.716454052284099],
              [-81.518213756289882, 35.716189200714055],
              [-81.519205766613737, 35.71631122197288],
              [-81.519256546073606, 35.716046369956182],
              [-81.520248556218817, 35.716168382722046],
              [-81.520147003304629, 35.716698087520413],
              [-81.520477675752431, 35.716738756621375],
              [-81.520426899853234, 35.717003609091002],
              [-81.520757573689636, 35.717044277427696],
              [-81.520706798514524, 35.717309129989147],
              [-81.521037474844206, 35.717349797538851],
              [-81.52098670039328, 35.717614650192132],
              [-81.523962808502716, 35.717980618972703],
              [-81.523912044331595, 35.718245472771656],
              [-81.524242724991055, 35.718286131625263],
              [-81.524191961544034, 35.718550985515982],
              [-81.525514696120581, 35.718713611487871],
              [-81.526506750533315, 35.718835571303735],
              [-81.526455994148961, 35.71910042609364],
              [-81.527117367521441, 35.719181728112069],
              [-81.527066612917693, 35.719446583128288],
              [-81.527397301144433, 35.719487232473334],
              [-81.527448054719912, 35.719222378223634],
              [-81.528109431189918, 35.719303674826151],
              [-81.528160181187403, 35.719038819386355],
              [-81.528490868325804, 35.719079466666834],
              [-81.52854161803964, 35.718814611026822],
              [-81.528872304453614, 35.718855257273852],
              [-81.528923052779064, 35.718590401456474],
              [-81.529253738468682, 35.718631046670119],
              [-81.529304484300738, 35.718366190698362],
              [-81.529635169237849, 35.718406833977582],
              [-81.529685914814536, 35.718141978706598],
              [-81.530016599027277, 35.71818262095244],
              [-81.530067342082461, 35.717917764626065],
              [-81.530728709419378, 35.71799904705329],
              [-81.530779450029726, 35.717734190415072],
              [-81.532763553641985, 35.717978014416403],
              [-81.532712819370175, 35.718242871861698],
              [-81.534366254544381, 35.718446033860005],
              [-81.534416982429207, 35.718181175765203],
              [-81.535078357361257, 35.718262433629413],
              [-81.535129082801276, 35.717997575222888],
              [-81.53545976915629, 35.718038202683694],
              [-81.535510493207966, 35.717773344099911],
              [-81.535841178838439, 35.717813970527381],
              [-81.535942624938158, 35.717284252939578],
              [-81.536273308787671, 35.71732487819925],
              [-81.536374750342105, 35.716795160194543],
              [-81.536705432410642, 35.716835784286424],
              [-81.536756151633639, 35.716570925085463],
              [-81.53708683297765, 35.716611548144037],
              [-81.537340417671231, 35.715287249046966],
              [-81.537671092960238, 35.715327870557459],
              [-81.53772180807934, 35.71506301083128],
              [-81.537391133846711, 35.715022389455235],
              [-81.537543279220657, 35.714227808644843],
              [-81.53820462278442, 35.714309049668231],
              [-81.538255334415012, 35.714044189659894],
              [-81.538586006190698, 35.71408480867732],
              [-81.538636716404852, 35.713819947590714],
              [-81.538967386351132, 35.713860565598011],
              [-81.538916677165062, 35.714125425917956],
              [-81.539247348499487, 35.714166043160844],
              [-81.539196640066336, 35.714430904473687],
              [-81.539527312788934, 35.714471520952216],
              [-81.539476605052016, 35.714736381455687],
              [-81.539807279162758, 35.714776997169835],
              [-81.539756572150523, 35.71504185776508],
              [-81.540087247649438, 35.715082472714919],
              [-81.540036541361729, 35.71534733340193],
              [-81.541028575228154, 35.715469173215531],
              [-81.54097787177777, 35.715734034263143],
              [-81.543623320198265, 35.716058898316582],
              [-81.543674014092261, 35.715794036216678],
              [-81.544335377677996, 35.715875242966931],
              [-81.544386070203899, 35.715610379631364],
              [-81.547031528200549, 35.715935167824306],
              [-81.547082210866648, 35.715670304295088],
              [-81.547412893683187, 35.715710898411821],
              [-81.547463574961299, 35.715446034705536],
              [-81.547794257052999, 35.715486627789033],
              [-81.547844936914686, 35.715221763004628],
              [-81.548175618281533, 35.715262355054875],
              [-81.548226295678646, 35.714997491017648],
              [-81.548556976320754, 35.715038082034759],
              [-81.548607653406236, 35.714773216896226],
              [-81.549269012467335, 35.714854395988226],
              [-81.549319687136816, 35.714589531439437],
              [-81.549650366660572, 35.71463011949119],
              [-81.549599693047625, 35.714894984174435],
              [-81.549930373959484, 35.714935571461702],
              [-81.549879702204606, 35.715200437114447],
              [-81.550541066031144, 35.715281609284347],
              [-81.550490396029247, 35.715546474262084],
              [-81.550821080048706, 35.715587059121468],
              [-81.551151764399705, 35.715627643082072],
              [-81.551050427655682, 35.716157374347972],
              [-81.551381114451118, 35.716197957678311],
              [-81.551330446624149, 35.716462822931064],
              [-81.551661134807716, 35.71650340549693],
              [-81.551610467734022, 35.716768271742389],
              [-81.551941157305649, 35.716808853543661],
              [-81.551839824219982, 35.717338584373081],
              [-81.552170516236188, 35.717379165544145],
              [-81.552119850252524, 35.717644031029096],
              [-81.552450544761712, 35.717684611412416],
              [-81.552501209688813, 35.717419745793116],
              [-81.552831902339648, 35.717460324265396],
              [-81.552882565878704, 35.717195458469014],
              [-81.553543951195152, 35.717276613326142],
              [-81.553594612289615, 35.7170117472184],
              [-81.556240158362414, 35.717336329616217],
              [-81.556290810673005, 35.717071462391409],
              [-81.557613587437942, 35.717233731479553],
              [-81.557664234057512, 35.716968862797039],
              [-81.557994928558884, 35.717009427225371],
              [-81.558045573818958, 35.716744559266971],
              [-81.558376266490285, 35.716785122685422],
              [-81.558578839933404, 35.715725648085801],
              [-81.558909528709904, 35.715766210068189],
              [-81.558960170171588, 35.71550134072686],
              [-81.559290858251316, 35.715541902577151],
              [-81.559392136962458, 35.715012163521266],
              [-81.560053510938005, 35.715093282162819],
              [-81.560002873308733, 35.715358151992248],
              [-81.56099493940259, 35.71547982452838],
              [-81.561656318804481, 35.715560934449563],
              [-81.561605686126626, 35.715825804907624],
              [-81.562928453132045, 35.715988014500162],
              [-81.562979080478854, 35.715723143528386],
              [-81.563971156594093, 35.715844790869987],
              [-81.564021780439717, 35.715579919452949],
              [-81.565013855233175, 35.715701557423962],
              [-81.565064475577927, 35.715436685561478],
              [-81.567048628901659, 35.715679937305524],
              [-81.567099241470814, 35.715415064618526],
              [-81.567760626429333, 35.715496140472872],
              [-81.567811236554121, 35.715231267474877],
              [-81.5681419279354, 35.715271804381651],
              [-81.568192536672441, 35.715006931206837],
              [-81.568523228404558, 35.715047466156179],
              [-81.568573835753867, 35.714782592804497],
              [-81.569235217022197, 35.714863660662431],
              [-81.56928582082233, 35.714598787023192],
              [-81.569947201301886, 35.714679851017145],
              [-81.569896598510056, 35.714944724948069],
              [-81.570888675420733, 35.715066314137481],
              [-81.57083807546762, 35.71533118842833],
              [-81.571499464589508, 35.715412243946496],
              [-81.571448866418535, 35.715677118463056],
              [-81.571779562518415, 35.715717644557344],
              [-81.571728966177858, 35.715982519142109],
              [-81.57239036040788, 35.716063569826701],
              [-81.572289170931569, 35.716593318503904],
              [-81.572619871194618, 35.716633842303935],
              [-81.572569275925886, 35.716898717186623],
              [-81.572899977576668, 35.716939240221791],
              [-81.572849384138436, 35.717204115172699],
              [-81.573841494247262, 35.717325679287043],
              [-81.573790903648003, 35.717590554597649],
              [-81.575775141353631, 35.717833659312575],
              [-81.575825725612674, 35.717568783197201],
              [-81.576487137979299, 35.717649810657882],
              [-81.576436556938589, 35.717914687018101],
              [-81.577759390946881, 35.718076730763883],
              [-81.577708813802118, 35.718341607617781],
              [-81.578370235443614, 35.718422623902818],
              [-81.578319660081377, 35.718687500982398],
              [-81.579642511562909, 35.71884952330096],
              [-81.579591940096762, 35.719114400874162],
              [-81.581245518850167, 35.719316909630976],
              [-81.581194953413217, 35.719581786907398],
              [-81.581856389780697, 35.719662784195677],
              [-81.581805825021533, 35.719927661721002],
              [-81.58213654477278, 35.719968159601279],
              [-81.582085980739535, 35.720233037217952],
              [-81.5824167018785, 35.720273534333224],
              [-81.58373959076188, 35.720435511077504],
              [-81.583790148377318, 35.720170631913028],
              [-81.584120870113793, 35.720211124399142],
              [-81.584171427446478, 35.719946245034414],
              [-81.5845021484282, 35.719986735586438],
              [-81.584603257806322, 35.719456977408889],
              [-81.584933977033984, 35.719497467694872],
              [-81.584984530155609, 35.719232587957528],
              [-81.585315248628504, 35.719273076309435],
              [-81.585365800391259, 35.719008197296453],
              [-81.58569651813815, 35.719048684615402],
              [-81.585747068484523, 35.718783804524634],
              [-81.586408501752231, 35.718864776220826],
              [-81.586459049682986, 35.718599896720484],
              [-81.587451200552167, 35.718721347084916],
              [-81.587501743847994, 35.71845646626236],
              [-81.588163177300942, 35.718537428408943],
              [-81.588213718152403, 35.718272547275724],
              [-81.588875150814744, 35.718353505558213],
              [-81.588925689221853, 35.718088624114365],
              [-81.589256404992256, 35.718129101773172],
              [-81.589306942011817, 35.717864220152642],
              [-81.589637657056116, 35.717904696778454],
              [-81.589688192688186, 35.717639814981403],
              [-81.590018907006353, 35.717680290574137],
              [-81.590069441250918, 35.717415408600488],
              [-81.590400153738003, 35.717455883183838],
              [-81.590450686595162, 35.71719100103352],
              [-81.590781399461108, 35.717231474560343],
              [-81.591442825051274, 35.717312418039356],
              [-81.591392295364614, 35.717577300591614],
              [-81.591723010279367, 35.717617771555368],
              [-81.59167248242386, 35.717882654175398],
              [-81.592333914225222, 35.71796359279633],
              [-81.592283388152637, 35.718228475641702],
              [-81.592614106172988, 35.71826894417643],
              [-81.592513052910903, 35.718798709153283],
              [-81.592843774480514, 35.718839177033296],
              [-81.592793248424812, 35.719104060042284],
              [-81.593123970248143, 35.719144526279798],
              [-81.593073446023567, 35.719409409356572],
              [-81.59472706654671, 35.719611729506795],
              [-81.594777585515757, 35.719346846661317],
              [-81.59510831042769, 35.719387307481028],
              [-81.59515882798037, 35.719122423557913],
              [-81.595820274501364, 35.719203343180546],
              [-81.595870789609592, 35.718938458946944],
              [-81.59620151229457, 35.718978916825229],
              [-81.596150998243218, 35.719243801192761],
              [-81.597143172586001, 35.719365170712777],
              [-81.597193683466756, 35.719100285943398],
              [-81.59752440750286, 35.719140741126623],
              [-81.597625426158942, 35.718610971192234],
              [-81.597956148382892, 35.718651424307545],
              [-81.598006655053339, 35.718386539166161],
              [-81.599329544160582, 35.718548342978679],
              [-81.599279041717693, 35.718813228655719],
              [-81.599940489801128, 35.718894124996829],
              [-81.599889989141573, 35.71915901089907],
              [-81.600220715840834, 35.719199457831401],
              [-81.600170214773854, 35.719464342947475],
              [-81.60050094286062, 35.719504789114644],
              [-81.600450443653855, 35.71976967519938],
              [-81.60078117202309, 35.719810120625006],
              [-81.600730673542813, 35.720075006801068],
              [-81.60106140440439, 35.720115451437898],
              [-81.601010906650671, 35.720380337705151],
              [-81.602664570065883, 35.720582547195072],
              [-81.602614077266651, 35.720847434089251],
              [-81.603275547484344, 35.720928311309386],
              [-81.603225056468546, 35.721193198428558],
              [-81.603555793115532, 35.721233635373302],
              [-81.603505302826306, 35.721498522583708],
              [-81.603836041994654, 35.721538959640654],
              [-81.604828258138298, 35.721660264586923],
              [-81.604777771717707, 35.721925151389065],
              [-81.605769995111018, 35.722046448621306],
              [-81.605719512636071, 35.722311335758675],
              [-81.607042485626195, 35.722473052756804],
              [-81.606992007078091, 35.722737941287903],
              [-81.608645737952983, 35.722940067482696],
              [-81.608696210110793, 35.72267517830619],
              [-81.609026956650055, 35.72271560034411],
              [-81.609127898994601, 35.722185822472767],
              [-81.609458642674227, 35.722226244268711],
              [-81.609509112831844, 35.721961354672899],
              [-81.609839855755553, 35.722001774534782],
              [-81.609940791890097, 35.721471995872413],
              [-81.610271533030058, 35.721512414567577],
              [-81.610322000082817, 35.72124752457627],
              [-81.610652740524884, 35.721287943139593],
              [-81.61080413432056, 35.720493272555871],
              [-81.611134871892887, 35.720533688917733],
              [-81.611235796617137, 35.720003908964735],
              [-81.61156653240559, 35.720044324159829],
              [-81.611667453665802, 35.719514542867877],
              [-81.611998187670437, 35.71955495689631],
              [-81.612099104390836, 35.719025175190154],
              [-81.61214956280817, 35.718760284261357],
              [-81.61181883307944, 35.718719870610599],
              [-81.611869292194484, 35.718454978871982],
              [-81.611538562748052, 35.718414564479573],
              [-81.61158902261883, 35.718149673733237],
              [-81.611258295664484, 35.71810925855192],
              [-81.611308756262204, 35.717844367896753],
              [-81.610647304324033, 35.71776353422716],
              [-81.610697766705357, 35.717498643797043],
              [-81.610036318231181, 35.717417807699903],
              [-81.610238173956404, 35.716358244798897],
              [-81.609907454994826, 35.716317825925152],
              [-81.610058846753631, 35.715523153247901],
              [-81.609728131292968, 35.715482733876463],
              [-81.609778595231148, 35.715217842740508],
              [-81.609447881157521, 35.715177422603837],
              [-81.609498346927111, 35.71491253153544],
              [-81.609167634240677, 35.714872110633564],
              [-81.609218099632059, 35.714607219680246],
              [-81.608887389437584, 35.714566797989498],
              [-81.608937855555581, 35.714301907127435],
              [-81.608607145643347, 35.714261484695172],
              [-81.608657613592783, 35.713996593900795],
              [-81.607996197948495, 35.713915745682264],
              [-81.607665491188698, 35.713875320663206],
              [-81.607715960873563, 35.713610430251521],
              [-81.60738525547184, 35.713570003566169],
              [-81.60748619709824, 35.713040223760437],
              [-81.607155494140429, 35.712999796443768],
              [-81.607256436530349, 35.712470015834917],
              [-81.606925736045412, 35.712429588788005],
              [-81.607077150847076, 35.711634917044968],
              [-81.606746453862627, 35.711594489500605],
              [-81.60684739825281, 35.71106470807802],
              [-81.606516703712245, 35.711024279902453],
              [-81.606567176483196, 35.710759389711818],
              [-81.60623648330062, 35.710718959870093],
              [-81.606286956798044, 35.710454069770982],
              [-81.605956265002476, 35.710413639164081],
              [-81.606107685549475, 35.709618968136553],
              [-81.605776998359133, 35.709578537008682],
              [-81.605827472648798, 35.709313647007718],
              [-81.606158158782534, 35.709354078001724],
              [-81.606259103124088, 35.708824296727471],
              [-81.606589788550281, 35.7088647256302],
              [-81.606791669043631, 35.707805162038099],
              [-81.606460988919906, 35.707764732746064],
              [-81.60656192929838, 35.707234950963631],
              [-81.607553964291654, 35.707356234438627],
              [-81.607604430248742, 35.707091342643693],
              [-81.60793510819326, 35.707131768837328],
              [-81.608086502018111, 35.706337092773339],
              [-81.608417176018136, 35.706377517690207],
              [-81.608669486436114, 35.705053056084061],
              [-81.609000155454211, 35.705093478531801],
              [-81.609353367171494, 35.703239227801831],
              [-81.609684029152774, 35.703279649314887],
              [-81.609784941689313, 35.70274986235809],
              [-81.610115601887514, 35.702790282704612],
              [-81.610166055484868, 35.702525388602155],
              [-81.610496714956753, 35.702565807915995],
              [-81.610698525162007, 35.701506231378758],
              [-81.61102917963305, 35.701546649282122],
              [-81.611130080075171, 35.701016860053997],
              [-81.611460733867943, 35.701057276767145],
              [-81.611511181985037, 35.700792381967851],
              [-81.612503139906835, 35.700913624558162],
              [-81.612452694959217, 35.701178519758898],
              [-81.613444660125751, 35.701299754637319],
              [-81.613495101903681, 35.701034859035154],
              [-81.614817721773804, 35.701196492135828],
              [-81.614868158995577, 35.70093159595632],
              [-81.614969032449082, 35.700401803470776],
              [-81.615299684512394, 35.700442208884034],
              [-81.615400553428231, 35.699912415986006],
              [-81.615731204842263, 35.699952821110216],
              [-81.615832070325268, 35.699423027775971],
              [-81.616162718822466, 35.699463430856476],
              [-81.616566159045675, 35.697344252962445],
              [-81.617227439021562, 35.69742505431018],
              [-81.617277865982274, 35.697160155928685],
              [-81.617608504856335, 35.697200555132468],
              [-81.617658929355201, 35.696935657500028],
              [-81.617989568607314, 35.69697605564739],
              [-81.618039991690694, 35.696711156938022],
              [-81.619693186512777, 35.696913133547653],
              [-81.619743603983977, 35.69664823412743],
              [-81.62040488144622, 35.696729018226343],
              [-81.62045529650382, 35.696464119397625],
              [-81.620785934091728, 35.696504509075901],
              [-81.620836347763017, 35.696239610071359],
              [-81.62116698462431, 35.696279998717088],
              [-81.621217396909316, 35.696015099536851],
              [-81.621878670642289, 35.696095874741644],
              [-81.621828261603966, 35.696360775066765],
              [-81.622489538768988, 35.69644154694376],
              [-81.62253994572356, 35.696176647252308],
              [-81.625846344084181, 35.696580451299447],
              [-81.625795947723688, 35.696845353229143],
              [-81.626126590636218, 35.69688572836867],
              [-81.626076196107007, 35.697150630366082],
              [-81.627068130021456, 35.697271751693819],
              [-81.627118521380993, 35.697006849295335],
              [-81.627449165612248, 35.697047220839558],
              [-81.627499556719414, 35.696782319142464],
              [-81.627830200223841, 35.696822689654169],
              [-81.627880588811053, 35.696557786904251],
              [-81.628541874725116, 35.696638525864742],
              [-81.628592260869539, 35.696373622805446],
              [-81.631898699506849, 35.69677725783535],
              [-81.631848323927755, 35.697042162231256],
              [-81.632509618828166, 35.697122878694458],
              [-81.632559991189609, 35.69685797405517],
              [-81.632890637525321, 35.696898330816758],
              [-81.632941008500566, 35.696633426001817],
              [-81.633932947419979, 35.696754490468699],
              [-81.633983314895985, 35.69648958521087],
              [-81.635636546941299, 35.696691338890936],
              [-81.63578763046857, 35.695896621786034],
              [-81.636118274457942, 35.695936968888851],
              [-81.636168634276672, 35.695672062661217],
              [-81.636499277568319, 35.695712409632613],
              [-81.63654963489634, 35.695447503253384],
              [-81.636880277431558, 35.695487848291215],
              [-81.636930633373566, 35.695222941736375],
              [-81.63726127521106, 35.695263286642827],
              [-81.637361985098295, 35.694733473116074],
              [-81.637031345373799, 35.694693128476857],
              [-81.637232765391545, 35.693633500553105],
              [-81.637563400889888, 35.693673844657958],
              [-81.637613753439894, 35.69340893677473],
              [-81.637944387106614, 35.693449279871118],
              [-81.638246490342453, 35.691859833568799],
              [-81.638577119074981, 35.691900174039809],
              [-81.638627467235466, 35.691635266496981],
              [-81.638958094136299, 35.691675605959531],
              [-81.63905878732686, 35.691145789580027],
              [-81.639389412444146, 35.69118612787662],
              [-81.639439757474221, 35.690921219039879],
              [-81.640101005508967, 35.691001893594589],
              [-81.640151348096765, 35.690736984448804],
              [-81.640481972118636, 35.690777320682884],
              [-81.640532313320662, 35.690512411361631],
              [-81.640862936586032, 35.690552745662316],
              [-81.640812596440469, 35.690817655117122],
              [-81.641143221121112, 35.690857989553677],
              [-81.641092882777713, 35.691122898175145],
              [-81.641423508844156, 35.69116323184646],
              [-81.641373170123359, 35.69142814058349],
              [-81.641703797575602, 35.691468473489586],
              [-81.641754135210448, 35.691203563717828],
              [-81.642415388930985, 35.691284224764232],
              [-81.642465723048531, 35.691019315608528],
              [-81.643788231319817, 35.691180626357308],
              [-81.643838560852686, 35.690915715724479],
              [-81.644169187411094, 35.690956041037154],
              [-81.644269843405581, 35.690426220279811],
              [-81.644600468180329, 35.690466544426592],
              [-81.64465079461246, 35.690201633401117],
              [-81.644981418659981, 35.690241956515564],
              [-81.645031742601873, 35.689977045338694],
              [-81.645692989571799, 35.690057688603964],
              [-81.645743312176066, 35.689792777094169],
              [-81.646404558349957, 35.689873416497221],
              [-81.646454877378119, 35.689608503801537],
              [-81.647116122755804, 35.689689139342335],
              [-81.647166439371304, 35.68942422723881],
              [-81.647497061497475, 35.68946454352745],
              [-81.647547376727331, 35.689199631248613],
              [-81.647877998126205, 35.689239946504927],
              [-81.647978626560629, 35.688710120629636],
              [-81.648309245071289, 35.688750434744279],
              [-81.648359557200749, 35.688485522072966],
              [-81.648690176059119, 35.688525834230134],
              [-81.648740486802993, 35.688260921383488],
              [-81.649732341138176, 35.688381853887925],
              [-81.649782648383578, 35.688116940599024],
              [-81.650443883761696, 35.688197557215375],
              [-81.650494188565204, 35.687932643617692],
              [-81.650824806228968, 35.68797294998167],
              [-81.650875108542351, 35.687708036232777],
              [-81.651205725508262, 35.687748342465447],
              [-81.651256026406614, 35.687483427640252],
              [-81.65158664264527, 35.687523732840738],
              [-81.651737541330476, 35.686728988590971],
              [-81.652068153624441, 35.686769292516303],
              [-81.652118452198124, 35.68650437723295],
              [-81.652449063735105, 35.686544679224987],
              [-81.652549656707663, 35.686014849191324],
              [-81.652880266460869, 35.686055150017644],
              [-81.652930560859673, 35.685790235267241],
              [-81.653261169885496, 35.685830535061314],
              [-81.65331146286934, 35.685565619234723],
              [-81.653642071197382, 35.685605918897537],
              [-81.653692363900262, 35.685341002871645],
              [-81.654022971471306, 35.685381300601179],
              [-81.654073261684331, 35.685116384424227],
              [-81.654734475730152, 35.685196977821214],
              [-81.654784762396957, 35.684932061359731],
              [-81.655115368871819, 35.684972357027199],
              [-81.65516565525769, 35.684707440366424],
              [-81.655496260975482, 35.684747734100533],
              [-81.65554654597598, 35.684482817264644],
              [-81.655877150966447, 35.684523109966527],
              [-81.655927433477089, 35.684258192979591],
              [-81.656588642361527, 35.684338776321347],
              [-81.656638923505426, 35.684073858100675],
              [-81.656969526294986, 35.684114148764671],
              [-81.657019806083099, 35.683849231269917],
              [-81.657681012686012, 35.683929807784232],
              [-81.657731288928048, 35.683664890005069],
              [-81.658061891666435, 35.683705176780748],
              [-81.658112166523182, 35.683440258826501],
              [-81.658442767429662, 35.683480544594033],
              [-81.658543315118663, 35.682950707368505],
              [-81.658873914241283, 35.682990991970513],
              [-81.65897445739752, 35.682461154335684],
              [-81.658643860387627, 35.682420870000499],
              [-81.658694132543587, 35.682155951704537],
              [-81.659024728497243, 35.682196235906304],
              [-81.659175539763723, 35.681401478566237],
              [-81.65884494587479, 35.681361194788934],
              [-81.658895216715308, 35.681096276326393],
              [-81.658564625315933, 35.681055991759642],
              [-81.658614896854218, 35.680791072487935],
              [-81.657953717154726, 35.680710500925052],
              [-81.658003990476843, 35.680445581878587],
              [-81.657012226533396, 35.680324717293132],
              [-81.657062502725168, 35.680059799506438],
              [-81.656070744910608, 35.679938927232811],
              [-81.656121025017015, 35.679674008879672],
              [-81.65512927333144, 35.679553128918073],
              [-81.655179556277702, 35.679288210923701],
              [-81.654187811825665, 35.679167323250113],
              [-81.654238097611724, 35.678902405614473],
              [-81.653576938759059, 35.678821809297155],
              [-81.653627225224284, 35.678556891911079],
              [-81.653296647885071, 35.678516592075255],
              [-81.653346936181904, 35.678251674756943],
              [-81.653677512464924, 35.678291974459214],
              [-81.653727799376682, 35.678027056965824],
              [-81.654058374902903, 35.678067354734992],
              [-81.654108660429429, 35.677802437066596],
              [-81.654439235257925, 35.677842734704591],
              [-81.654489519399306, 35.677577816861159],
              [-81.654820093470988, 35.677618112565959],
              [-81.654970939648791, 35.676823358410175],
              [-81.655301510880818, 35.676863652815946],
              [-81.655402070874274, 35.676333816245474],
              [-81.655732640322938, 35.67637410948565],
              [-81.6558834740962, 35.675579353029221],
              [-81.656214040705166, 35.675619644970354],
              [-81.656264316557426, 35.675354726243548],
              [-81.65659488243918, 35.675395017152589],
              [-81.656645156906436, 35.675130098250747],
              [-81.656975722060807, 35.675170388127782],
              [-81.657126540291429, 35.674375629871903],
              [-81.656795978275909, 35.674335339494235],
              [-81.656846251427751, 35.674070420426467],
              [-81.65651569190149, 35.674030129259435],
              [-81.656666512493132, 35.673235370405628],
              [-81.656335956464062, 35.673195078740392],
              [-81.656436504002968, 35.67266523986445],
              [-81.65676705789032, 35.672705530361796],
              [-81.656817330095748, 35.672440610277725],
              [-81.657147883285418, 35.672480900643983],
              [-81.657198154105842, 35.672215980385033],
              [-81.657859260404592, 35.672296556328675],
              [-81.657909527679891, 35.672031635785515],
              [-81.658570633211284, 35.672112208768873],
              [-81.658620898045513, 35.671847287917444],
              [-81.660273660646723, 35.67204870266751],
              [-81.660323919871772, 35.671783781107514],
              [-81.660985024647772, 35.671864340099653],
              [-81.661035282536247, 35.671599418207279],
              [-81.66235749180872, 35.671760524874465],
              [-81.66230723924896, 35.672025447276319],
              [-81.662637793444944, 35.672065721829917],
              [-81.662587541612581, 35.672330644323765],
              [-81.662918098297638, 35.672370918088063],
              [-81.663248654207123, 35.672411190977897],
              [-81.663198404158308, 35.672676113697122],
              [-81.663859520179855, 35.672756657023328],
              [-81.663909768116511, 35.672491734037415],
              [-81.664240325012031, 35.672532004231265],
              [-81.664290571563782, 35.67226708107048],
              [-81.664621127731849, 35.672307350232323],
              [-81.664671371794554, 35.672042426920918],
              [-81.665001928309863, 35.672082694125571],
              [-81.665052170987778, 35.671817770639322],
              [-81.665382725700908, 35.671858037737216],
              [-81.665432968098429, 35.671593114052094],
              [-81.665763522084063, 35.671633380117932],
              [-81.665813761962838, 35.671368455381213],
              [-81.667466532614398, 35.671569770638996],
              [-81.667416297986605, 35.67183469514152],
              [-81.667746855018478, 35.671874954890292],
              [-81.667696621147911, 35.672139880385835],
              [-81.668027178460264, 35.672180139393625],
              [-81.667976945287577, 35.67244506408008],
              [-81.668307505118619, 35.672485323199375],
              [-81.668357737235141, 35.672220398379501],
              [-81.669679973352657, 35.672381423583126],
              [-81.669629745460767, 35.67264634893629],
              [-81.669960306904912, 35.672686602661308],
              [-81.66991007974066, 35.672951528106395],
              [-81.670571204652418, 35.673032034052326],
              [-81.670520980376239, 35.673296959698455],
              [-81.671512673913796, 35.673417711814665],
              [-81.671562895021367, 35.6731527857686],
              [-81.672224024292873, 35.673233281802624],
              [-81.672274242959631, 35.672968355448504],
              [-81.672935371462444, 35.673048848522285],
              [-81.672985587658474, 35.672783920959127],
              [-81.673316150257008, 35.672824166489555],
              [-81.67336636506846, 35.672559238751596],
              [-81.675019182005158, 35.672760448555806],
              [-81.675069391207032, 35.672495520110083],
              [-81.675399953634098, 35.672535759080816],
              [-81.675450161481194, 35.672270831361487],
              [-81.675780723180495, 35.672311069300349],
              [-81.675830929642871, 35.672046141406319],
              [-81.676492053018521, 35.672126614297134],
              [-81.676542257010411, 35.671861685194138],
              [-81.676872817582492, 35.671901920170406],
              [-81.676923019115222, 35.671636991818133],
              [-81.677253580063834, 35.671677225738144],
              [-81.677303780182214, 35.671412296310301],
              [-81.677634340402918, 35.671452529198362],
              [-81.67768453916652, 35.671187600496857],
              [-81.67834565734664, 35.671268062433853],
              [-81.678395853669514, 35.671003133424477],
              [-81.67872641162883, 35.671043362473334],
              [-81.678826801147395, 35.670513503163022],
              [-81.679157357352565, 35.670553731947734],
              [-81.679207550548284, 35.670288801646898],
              [-81.679868662935348, 35.670369256229584],
              [-81.679918852586155, 35.670104325645234],
              [-81.680579964144172, 35.670184775465863],
              [-81.680630151384136, 35.669919845474674],
              [-81.680960707136691, 35.669960068441064],
              [-81.681010892962391, 35.669695137374291],
              [-81.681672002295187, 35.669775582171113],
              [-81.681722185680258, 35.669510650796575],
              [-81.682713848361146, 35.669631309062275],
              [-81.682764028249423, 35.669366377246789],
              [-81.683755689641515, 35.669487027950488],
              [-81.68380586603304, 35.669222095694032],
              [-81.68479752828479, 35.669342736984767],
              [-81.684847701179635, 35.66907780428739],
              [-81.685178254485692, 35.669118015828282],
              [-81.685228424891903, 35.668853082980817],
              [-81.686220084744662, 35.668973712688562],
              [-81.68627025165415, 35.668708779400234],
              [-81.68693135809913, 35.668789193836922],
              [-81.686981522568189, 35.668524260240964],
              [-81.687642627109128, 35.668604670841098],
              [-81.687692789137699, 35.668339736937511],
              [-81.688353893982907, 35.668420143652298],
              [-81.688504370658407, 35.667625340919393],
              [-81.688834919852596, 35.667665542541449],
              [-81.688885077400144, 35.667400608073372],
              [-81.68855452926222, 35.667360406584457],
              [-81.688604687507919, 35.66709547130727],
              [-81.688274140754231, 35.667055269052824],
              [-81.688324299757923, 35.666790334768621],
              [-81.687993755492641, 35.666750131724385],
              [-81.688094074598155, 35.66622026239758],
              [-81.688424616751249, 35.666260465175505],
              [-81.688675402373875, 35.66493579004559],
              [-81.688344865501449, 35.664895587933493],
              [-81.688445180013957, 35.664365718030119],
              [-81.688114645581763, 35.664325515285803],
              [-81.688214959760018, 35.663795644607383],
              [-81.68788442776804, 35.663755441230812],
              [-81.688034901399348, 35.662960635267559],
              [-81.687704372903596, 35.662920431391967],
              [-81.687854846750426, 35.662125625457946],
              [-81.687524321750857, 35.66208542108334],
              [-81.68777511018466, 35.660760743469197],
              [-81.688436147743715, 35.660841150036724],
              [-81.688486302340308, 35.660576214304449],
              [-81.688816820555857, 35.660616416107239],
              [-81.688866973768427, 35.660351480200688],
              [-81.689197491256124, 35.660391680971685],
              [-81.689247643084698, 35.660126744890867],
              [-81.689908676932035, 35.66020714347092],
              [-81.68995882629072, 35.65994220618164],
              [-81.690619859337986, 35.660022600901144],
              [-81.690670006286737, 35.65975766420555],
              [-81.692322590468649, 35.659958634589429],
              [-81.692372730675388, 35.659693696310427],
              [-81.692703247218802, 35.659733887563256],
              [-81.692753386041716, 35.659468949109979],
              [-81.693083901857051, 35.659509139331114],
              [-81.693134039325926, 35.65924420160475],
              [-81.69346455438334, 35.659284389893173],
              [-81.693514690468305, 35.659019451992549],
              [-81.693614960490535, 35.658489574290591],
              [-81.693945472737795, 35.658529762182141],
              [-81.694045739366572, 35.657999884950826],
              [-81.694376249799831, 35.658040070776487],
              [-81.694426382103416, 35.657775131503421],
              [-81.694476513005142, 35.657510193114852],
              [-81.694807021654384, 35.657550377775699],
              [-81.694907281406259, 35.657020498782792],
              [-81.695237788301526, 35.657060683179942],
              [-81.695287916077717, 35.656795743501121],
              [-81.69561842224482, 35.656835926866549],
              [-81.695668548637244, 35.656570987013616],
              [-81.695999052942312, 35.656611168470924],
              [-81.696049179054995, 35.656346228419388],
              [-81.69637968266187, 35.656386409746105],
              [-81.696429806286858, 35.656121469544907],
              [-81.697090813446238, 35.656201828310948],
              [-81.697140934631577, 35.655936887802618],
              [-81.697471438184053, 35.655977065242013],
              [-81.697521557985567, 35.655712124559628],
              [-81.697852059705994, 35.655752300991821],
              [-81.697902178123798, 35.655487360135361],
              [-81.698232679146003, 35.655527536437056],
              [-81.69828279615011, 35.655262594505501],
              [-81.698613296444165, 35.655302769775524],
              [-81.698663412094689, 35.65503782857099],
              [-81.698993911630595, 35.6550780019084],
              [-81.699044025897351, 35.65481306052984],
              [-81.700035524437908, 35.654933575628178],
              [-81.700085634075165, 35.654668632932861],
              [-81.700416132840317, 35.654708803311138],
              [-81.700466242197948, 35.654443860417288],
              [-81.700796740204765, 35.654484028862839],
              [-81.700846847104913, 35.654219086720566],
              [-81.702829837930139, 35.65446007857895],
              [-81.702879938167129, 35.654195135597355],
              [-81.70354093487235, 35.654275458478608],
              [-81.703591032639778, 35.654010514288956],
              [-81.704582528121236, 35.654130992349373],
              [-81.704632621289264, 35.653866047744216],
              [-81.705293618151117, 35.653946361083591],
              [-81.705343708879681, 35.653681416171445],
              [-81.705674206194104, 35.653721571372664],
              [-81.705724295539056, 35.653456626286598],
              [-81.706054793229214, 35.65349678043161],
              [-81.706104881220753, 35.653231836072663],
              [-81.706435377078535, 35.653271989210786],
              [-81.706485463656477, 35.653007043776881],
              [-81.706815958785882, 35.653047195883403],
              [-81.706866043980455, 35.652782250275592],
              [-81.707527033080027, 35.652862550626089],
              [-81.707677280392929, 35.652067713660465],
              [-81.708007772251349, 35.652107861638342],
              [-81.708107932253043, 35.65157796928154],
              [-81.708438422357233, 35.651618116995976],
              [-81.708638735280843, 35.650558330334746],
              [-81.708969220385185, 35.650598476643154],
              [-81.709269671939524, 35.649008794215497],
              [-81.709600152110099, 35.649048937901753],
              [-81.709650224625207, 35.648783990867337],
              [-81.709980702963406, 35.648824133546732],
              [-81.71003077409533, 35.64855918633863],
              [-81.71036125283922, 35.648599328862971],
              [-81.710411322557732, 35.648334380580003],
              [-81.710741799469332, 35.648374522097562],
              [-81.710791867804502, 35.648109573640838],
              [-81.711122343987824, 35.648149714126873],
              [-81.711172410939753, 35.647884765496386],
              [-81.711502886364428, 35.647924904050015],
              [-81.711653082118531, 35.647130058416188],
              [-81.711983554733379, 35.647170196573377],
              [-81.71203361826413, 35.646905247514042],
              [-81.712364090120431, 35.646945383738732],
              [-81.712464212984131, 35.646415485256405],
              [-81.7127946830864, 35.646455621217726],
              [-81.712844743523434, 35.646190671770285],
              [-81.713175211763257, 35.64623080582377],
              [-81.713225270817034, 35.645965856202565],
              [-81.713555739462606, 35.646005990101131],
              [-81.713605796029384, 35.645741040330869],
              [-81.714266731056853, 35.645821304290145],
              [-81.714316786258451, 35.645556353287617],
              [-81.714977720483631, 35.645636613387367],
              [-81.715027772172746, 35.645371663003878],
              [-81.717671514763239, 35.64569266457476],
              [-81.717721557678729, 35.645427713086974],
              [-81.718382494196376, 35.645507953785071],
              [-81.718432534673127, 35.645243001990728],
              [-81.718763002918948, 35.645283120846813],
              [-81.718863079644976, 35.644753215994008],
              [-81.719193545002597, 35.644793333710453],
              [-81.719593829267879, 35.642673711634494],
              [-81.719924286506682, 35.642713827389109],
              [-81.719974320186438, 35.642448873848018],
              [-81.720304776696779, 35.642488988571301],
              [-81.720354807889805, 35.642224034881295],
              [-81.720685263671513, 35.642264148573339],
              [-81.720735293511893, 35.641999195610872],
              [-81.721065748565138, 35.642039308271507],
              [-81.721115776992391, 35.641774354234485],
              [-81.721446231316975, 35.641814465863888],
              [-81.721496258361441, 35.641549511653366],
              [-81.721826711957462, 35.641589622251445],
              [-81.721926761849645, 35.641059713467513],
              [-81.722257213661379, 35.641099822901374],
              [-81.722357261207193, 35.640569912763702],
              [-81.722687710130842, 35.640610021058052],
              [-81.722787754287083, 35.640080111393395],
              [-81.723118201426473, 35.640120218523499],
              [-81.72321824102886, 35.639590307554514],
              [-81.723548687487778, 35.639630413495823],
              [-81.723598705205873, 35.63936545828053],
              [-81.7239291509362, 35.639405563190543],
              [-81.724029183249641, 35.638875651471558],
              [-81.724690070334802, 35.638955857188478],
              [-81.724740083904706, 35.638690901452961],
              [-81.72540097018674, 35.638771103310596],
              [-81.725651022513802, 35.637446320894682],
              [-81.725981460300019, 35.637486419373417],
              [-81.72613148458089, 35.636691548318645],
              [-81.726461919557551, 35.63673164640138],
              [-81.726561930874624, 35.636201731320874],
              [-81.726892364067055, 35.636241828239584],
              [-81.726942368731343, 35.635976870493423],
              [-81.727272801164901, 35.636016965479769],
              [-81.727372806297609, 35.635487049625119],
              [-81.727703236977263, 35.635527144348536],
              [-81.727803238691024, 35.634997228066439],
              [-81.727472810122549, 35.634957133608772],
              [-81.727722814484721, 35.633632341947298],
              [-81.72805323667157, 35.633672435765369],
              [-81.728253229576367, 35.63261260010632],
              [-81.728583647867993, 35.632652692494688],
              [-81.728683639113683, 35.632122773280962],
              [-81.728353222963449, 35.632082682059426],
              [-81.728603202872279, 35.630757884834871],
              [-81.728272792326322, 35.63071779337929],
              [-81.728722744350094, 35.628333155424102],
              [-81.728392344703437, 35.628293063340209],
              [-81.728492331791102, 35.627763142732753],
              [-81.728161934612359, 35.627723050917396],
              [-81.728211928706145, 35.627458090235606],
              [-81.728861824538669, 35.624013602167111],
              [-81.728531443534948, 35.623973510387884],
              [-81.728631424384517, 35.623443589025008],
              [-81.728301045818213, 35.623403496613335],
              [-81.728451017761657, 35.622608613314775],
              [-81.728781393131698, 35.622648704426723],
              [-81.728831382070609, 35.622383743114007],
              [-81.729161755638984, 35.622423834120795],
              [-81.729261730395308, 35.621893910208186],
              [-81.72959210328365, 35.621934000026265],
              [-81.729792043580531, 35.620874152113927],
              [-81.730122411440803, 35.620914239626025],
              [-81.730222376951161, 35.620384314725349],
              [-81.730552743058055, 35.620424401974709],
              [-81.730652706254304, 35.619894476623159],
              [-81.730983070547268, 35.61993456180759],
              [-81.731033049511694, 35.619669599414173],
              [-81.731133007503203, 35.619139672680177],
              [-81.73146336898759, 35.619179757468856],
              [-81.731513345886327, 35.61891479392132],
              [-81.731843706611883, 35.618954876778048],
              [-81.731893682158997, 35.618689913958647],
              [-81.732224042156176, 35.618729995784314],
              [-81.732323991202591, 35.61820006793284],
              [-81.732654348343019, 35.618240149520531],
              [-81.732754292868762, 35.617710221268027],
              [-81.733084649298661, 35.617750300766041],
              [-81.733134620016401, 35.617485336446862],
              [-81.733464975748134, 35.617525415815024],
              [-81.73351494508394, 35.617260451322991],
              [-81.733845298953312, 35.6173005287839],
              [-81.733895266907382, 35.617035564119021],
              [-81.73455597464978, 35.617115716957059],
              [-81.734605940166787, 35.616850751986576],
              [-81.735597000497791, 35.616970973671108],
              [-81.735646962492368, 35.61670600736101],
              [-81.736307668177048, 35.61678615071142],
              [-81.736357627765017, 35.61652118499677],
              [-81.737018333718879, 35.616601323563025],
              [-81.737068289766441, 35.616336357567583],
              [-81.737728994916367, 35.616416492275889],
              [-81.737679040979131, 35.616681458536895],
              [-81.738009394547333, 35.616721524689019],
              [-81.737959441369142, 35.616986491943891],
              [-81.738289797422581, 35.61702655730587],
              [-81.738239844942541, 35.617291523752499],
              [-81.738570201274257, 35.617331588373936],
              [-81.738520249522836, 35.617596554913355],
              [-81.738850608339803, 35.617636618744555],
              [-81.738800657317029, 35.617901585376771],
              [-81.739131017515746, 35.617941648442709],
              [-81.739081067221619, 35.618206615167665],
              [-81.739411428802129, 35.61824667746815],
              [-81.739311529344633, 35.618776611063602],
              [-81.739972258809743, 35.618856733476413],
              [-81.74002220548789, 35.61859176645801],
              [-81.740352569103294, 35.618631826196896],
              [-81.740402515503163, 35.618366858980821],
              [-81.740732878389849, 35.618406917688645],
              [-81.740782823407955, 35.618141950299808],
              [-81.741443546946954, 35.618222064780525],
              [-81.741493489528082, 35.617957097086112],
              [-81.742814938479782, 35.618117313787621],
              [-81.742864875379382, 35.617852344646018],
              [-81.74352559971895, 35.617932447792818],
              [-81.743625468348398, 35.617402509759657],
              [-81.743955828897356, 35.617442559720388],
              [-81.744005761682459, 35.61717759096161],
              [-81.744336121472131, 35.617217638990283],
              [-81.744386052875555, 35.616952670058787],
              [-81.744716410863475, 35.616992717982633],
              [-81.744766340854639, 35.616727747977357],
              [-81.745096699217328, 35.616767794845323],
              [-81.74514662782687, 35.616502824667414],
              [-81.745476984357268, 35.616542870529422],
              [-81.745526911585173, 35.616277900178751],
              [-81.746187624587606, 35.616357988016944],
              [-81.746237549409216, 35.616093018261893],
              [-81.746567904777848, 35.616133060263031],
              [-81.746617828217865, 35.615868090335326],
              [-81.746948182857565, 35.615908131305588],
              [-81.746998104916088, 35.615643161205199],
              [-81.747658814167721, 35.615723240160854],
              [-81.747708732685908, 35.615458269780042],
              [-81.749360506832161, 35.615658451676644],
              [-81.749310593620606, 35.615923423622085],
              [-81.751623099387515, 35.616203641103382],
              [-81.751673005212396, 35.615938668229212],
              [-81.752664080670101, 35.616058747182528],
              [-81.752713981930214, 35.615793774796423],
              [-81.753374699158414, 35.615873822659147],
              [-81.753424597951067, 35.615608849066767],
              [-81.754415671970762, 35.615728913751646],
              [-81.754465567271438, 35.615463939721401],
              [-81.754795925243045, 35.615503959345737],
              [-81.754945606022261, 35.614709036617512],
              [-81.755275960050867, 35.61474905497078],
              [-81.755375743495563, 35.614219106020506],
              [-81.755045390474066, 35.614179087957524],
              [-81.755195066360599, 35.613384164630894],
              [-81.754864716830937, 35.613344146067803],
              [-81.754964501234866, 35.612814197249349],
              [-81.7546341552451, 35.61277417802841],
              [-81.754684048013033, 35.612509203691928],
              [-81.754353702301188, 35.612469183730532],
              [-81.754403595798038, 35.612204209486947],
              [-81.754453488938182, 35.611939234302504],
              [-81.754783831436185, 35.611979254023666],
              [-81.754883614658198, 35.611449304170939],
              [-81.75521395426874, 35.611489322753734],
              [-81.755313734075742, 35.610959372476387],
              [-81.755644071902125, 35.610999389895689],
              [-81.755693960245949, 35.610734414114191],
              [-81.756024298446746, 35.610774430477704],
              [-81.756124070973073, 35.610244479456028],
              [-81.756454406286224, 35.610284494681167],
              [-81.756504291541404, 35.610019518514896],
              [-81.756834626125638, 35.610059532709329],
              [-81.756934392444151, 35.609529580017252],
              [-81.757264725244156, 35.609569593048363],
              [-81.757314606307517, 35.609304616522451],
              [-81.757644939481878, 35.609344628497794],
              [-81.757694819163945, 35.60907965179954],
              [-81.758685815240881, 35.609199681088157],
              [-81.758735691431554, 35.608934703952237],
              [-81.759066022696558, 35.608974712095026],
              [-81.759115897506163, 35.608709734786721],
              [-81.759776559976885, 35.608789747186641],
              [-81.759826431246779, 35.608524769598397],
              [-81.760487092911362, 35.608604778140752],
              [-81.760536962848391, 35.608339800222481],
              [-81.760867292578297, 35.608379803477014],
              [-81.760917160030843, 35.608114825411455],
              [-81.76124749010431, 35.608154826709097],
              [-81.76129735617576, 35.607889848471203],
              [-81.761627684416752, 35.60792984876332],
              [-81.761677550210464, 35.607664870328044],
              [-81.76233820559078, 35.607744868853779],
              [-81.762388067844995, 35.607479890138642],
              [-81.763709379369288, 35.607639874956597],
              [-81.763759237077139, 35.607374895671505],
              [-81.764089564434471, 35.607414889279163],
              [-81.764139420761282, 35.607149909821786],
              [-81.764469747389441, 35.607189902398808],
              [-81.764519601231896, 35.606924922794157],
              [-81.764849927130854, 35.606964914340594],
              [-81.764899780664919, 35.606699933637522],
              [-81.76589075718239, 35.606819903391553],
              [-81.76594060722536, 35.606554922251178],
              [-81.766270932348306, 35.606594910841117],
              [-81.766520169150965, 35.605270005758335],
              [-81.766850488190371, 35.605309991911497],
              [-81.766950178716428, 35.604780028609838],
              [-81.76728049707485, 35.604820013574695],
              [-81.767330340273134, 35.604555032645386],
              [-81.767660656798938, 35.604595016604883],
              [-81.767959703735215, 35.603005123970092],
              [-81.768290014256593, 35.603045106236195],
              [-81.768339853033481, 35.602780123863369],
              [-81.769661095260233, 35.602940043392117],
              [-81.769710929491168, 35.602675060449549],
              [-81.770701860558333, 35.602794989386943],
              [-81.770751691298528, 35.602530006007271],
              [-81.77108200126095, 35.602569980691399],
              [-81.771131829486308, 35.602304996263662],
              [-81.771462139822688, 35.602344969892172],
              [-81.771511966698114, 35.602079986193367],
              [-81.771842275201919, 35.602119958816481],
              [-81.771892100665823, 35.601854974044542],
              [-81.771561793247756, 35.601815002454991],
              [-81.771611620544135, 35.601550017750839],
              [-81.77128131447607, 35.601510044494731],
              [-81.771331141429386, 35.601245060809816],
              [-81.771000836741933, 35.601205086788184],
              [-81.771050664424521, 35.600940103196216],
              [-81.770720362221027, 35.60090012838392],
              [-81.770820018688156, 35.600370160445188],
              [-81.770489718920402, 35.600330184999905],
              [-81.770539547704914, 35.600065200653276],
              [-81.770209249348639, 35.600025225343579],
              [-81.770358738016071, 35.599230272438824],
              [-81.770028443119472, 35.599190295727681],
              [-81.770078272741884, 35.598925312388964],
              [-81.769747979225926, 35.598885334912424],
              [-81.769797809546759, 35.5986203507657],
              [-81.769467517411442, 35.598580372523593],
              [-81.769517349564694, 35.598315388444767],
              [-81.76918705881009, 35.598275409437299],
              [-81.769236890589212, 35.598010425476645],
              [-81.768246023444334, 35.597890483463864],
              [-81.76829585916569, 35.597625499836198],
              [-81.767965571823751, 35.597585517501898],
              [-81.768015408274238, 35.597320533967313],
              [-81.768345694561205, 35.597360516169111],
              [-81.768644697856899, 35.595770612486483],
              [-81.768974977037402, 35.595810593020154],
              [-81.769024809097942, 35.595545608944185],
              [-81.769355088652418, 35.595585588422182],
              [-81.76940491819856, 35.595320603298404],
              [-81.769735197023991, 35.595360581745872],
              [-81.769785025220287, 35.595095597351097],
              [-81.770445580635311, 35.595175551312437],
              [-81.770495406365484, 35.594910565712084],
              [-81.771155960973559, 35.594990515816519],
              [-81.771255607268486, 35.59446054486849],
              [-81.771585883502908, 35.594500518296186],
              [-81.771735347323116, 35.593705560730562],
              [-81.77188480821259, 35.592910602810335],
              [-81.772215077340675, 35.592950574570018],
              [-81.772264895920799, 35.592685588084976],
              [-81.772925433044378, 35.592765528645707],
              [-81.772975248086283, 35.592500541881414],
              [-81.773635784371891, 35.592580477684329],
              [-81.77368559808167, 35.592315490590487],
              [-81.775006670371539, 35.592475351792181],
              [-81.775056478433584, 35.592210364154148],
              [-81.776047282897224, 35.592330250004522],
              [-81.776097087469267, 35.592065261929683],
              [-81.776427355230439, 35.59210522225181],
              [-81.776477158422168, 35.591840234005133],
              [-81.777798228440915, 35.59200006398239],
              [-81.777748429468375, 35.592265052758904],
              [-81.778408968540319, 35.592344962624885],
              [-81.77845876430014, 35.592079973608662],
              [-81.779119302533502, 35.592159878716913],
              [-81.779169096961454, 35.591894889371105],
              [-81.780159903618852, 35.592014740802632],
              [-81.780209693453813, 35.591749751045441],
              [-81.782191309195483, 35.591989427968592],
              [-81.78214152568944, 35.59225441852049],
              [-81.78247179830241, 35.592294362389573],
              [-81.78252157961947, 35.592029370829529],
              [-81.782851851471975, 35.59206931276718],
              [-81.782901631439856, 35.591804321936344],
              [-81.78356217290829, 35.591884202878404],
              [-81.783512393977887, 35.592149194900387],
              [-81.784503210130609, 35.592269010013872],
              [-81.784453435111587, 35.592534001467477],
              [-81.784783708868062, 35.592573938175185],
              [-81.784733933475422, 35.592838929747039],
              [-81.785394485146327, 35.592918799807123],
              [-81.78534471267217, 35.593183792480218],
              [-81.786005267754049, 35.593263659213235],
              [-81.785955495930324, 35.593528651236014],
              [-81.786616054454129, 35.593608515542932],
              [-81.786566285518049, 35.593873507766013],
              [-81.788547976267566, 35.594113078102126],
              [-81.788597738874174, 35.593848085084929],
              [-81.789258303674814, 35.593927934097835],
              [-81.789308063846335, 35.593662940776468],
              [-81.790629190924548, 35.593822627547631],
              [-81.790678946551211, 35.593557633657547],
              [-81.791009228614925, 35.593597552509863],
              [-81.791058981758297, 35.593332558473293],
              [-81.792049825663824, 35.593452310171621],
              [-81.792000075685323, 35.593717304605171],
              [-81.792330359080879, 35.593757220766591],
              [-81.792380108004508, 35.593492226200702],
              [-81.793040672496119, 35.593572053788094],
              [-81.793090418984619, 35.593307058918207],
              [-81.793750983801175, 35.593386883524644],
              [-81.793800727823594, 35.593121887449819],
              [-81.794131008577097, 35.593161798749577],
              [-81.794180751219528, 35.592896802503006],
              [-81.794511032346264, 35.592936712747168],
              [-81.794610513473714, 35.592406720797868],
              [-81.794940792784658, 35.592446628978351],
              [-81.795040270501786, 35.591916636607223],
              [-81.795370546925056, 35.59195654365049],
              [-81.795420284808102, 35.591691547711491],
              [-81.795750560501375, 35.591731453724385],
              [-81.796198171624326, 35.589346482036326],
              [-81.796528438148741, 35.589386385960559],
              [-81.796727362855208, 35.588326395812409],
              [-81.796397100550067, 35.588286492417495],
              [-81.796645754086924, 35.586961503617026],
              [-81.796315498483949, 35.586921599960306],
              [-81.796365229056647, 35.586656602400254],
              [-81.796034974833489, 35.586616697977881],
              [-81.796432817099415, 35.584496712615746],
              [-81.796102571639423, 35.584456808354055],
              [-81.796152302468215, 35.584191809779874],
              [-81.796482545801638, 35.58423171483566],
              [-81.79663173096786, 35.583436718532262],
              [-81.796961971431102, 35.583476621392194],
              [-81.797011697747521, 35.583211622422382],
              [-81.797341937480951, 35.583251524252134],
              [-81.797391662417695, 35.582986525110847],
              [-81.797721902555509, 35.583026426786148],
              [-81.798268840407502, 35.580111429571168],
              [-81.798599068134905, 35.580151328017628],
              [-81.798648785928762, 35.579886327267403],
              [-81.798979012957574, 35.579926225584693],
              [-81.799028730474845, 35.579661224637825],
              [-81.799358955639988, 35.579701121049361],
              [-81.799408671777613, 35.579436119931081],
              [-81.799738896213057, 35.579476015312579],
              [-81.79978861100227, 35.579211014924063],
              [-81.80077928529991, 35.579330695233772],
              [-81.800828995466333, 35.579065693533764],
              [-81.801159219092099, 35.579105585059374],
              [-81.801208927879017, 35.578840583188097],
              [-81.801539151877961, 35.578880473658103],
              [-81.801588859285417, 35.578615471615628],
              [-81.801919081451686, 35.578655361081026],
              [-81.801968787479666, 35.578390358867175],
              [-81.802299010019098, 35.578430247277019],
              [-81.802629231780344, 35.578470134814523],
              [-81.802678935374374, 35.578205132297192],
              [-81.803009157508853, 35.578245018779143],
              [-81.802959454969439, 35.578510021428656],
              [-81.803619901187915, 35.578589791088028],
              [-81.803669601617997, 35.578324788173916],
              [-81.804330047057732, 35.578404554878411],
              [-81.804280348705618, 35.57866955715599],
              [-81.804610573518673, 35.578709439281049],
              [-81.804560875927663, 35.57897444255287],
              [-81.804891102120152, 35.57901432391234],
              [-81.804841406330752, 35.579279326350999],
              [-81.80517163390266, 35.579319206944803],
              [-81.805121937771375, 35.579584210403219],
              [-81.805452166691552, 35.579624089330451],
              [-81.805402472393098, 35.579889092856575],
              [-81.806393164297518, 35.580008725548851],
              [-81.806343472838307, 35.580273729432726],
              [-81.809315576683048, 35.58063258018958],
              [-81.80926589439143, 35.580897585224434],
              [-81.809596131800234, 35.580937452850613],
              [-81.809546450238614, 35.581202457978563],
              [-81.810206927036887, 35.581282190827238],
              [-81.810256605386058, 35.581017185460311],
              [-81.810586843800237, 35.581057051293818],
              [-81.810636520770004, 35.580792045755807],
              [-81.810966757319932, 35.580831909683724],
              [-81.811016434044589, 35.580566904849924],
              [-81.812007142477938, 35.580686490850049],
              [-81.812056814579563, 35.580421484705191],
              [-81.813047523873408, 35.580541062202343],
              [-81.813097192486211, 35.580276055622043],
              [-81.814087901537377, 35.580395624641717],
              [-81.814137566661344, 35.580130617625869],
              [-81.814467802238596, 35.580170472046369],
              [-81.814567128300197, 35.579640457658968],
              [-81.814897362092566, 35.579680310917333],
              [-81.814996684746518, 35.579150296109795],
              [-81.815326916754003, 35.579190148205896],
              [-81.815376577090845, 35.578925140598734],
              [-81.815706808368176, 35.578964991664861],
              [-81.815806124855968, 35.57843497609494],
              [-81.816136353245398, 35.578474826024497],
              [-81.81618601049928, 35.578209818036179],
              [-81.816846468316996, 35.578289513985176],
              [-81.816896122033938, 35.578024505719299],
              [-81.817886806942951, 35.578144042988448],
              [-81.817936457171399, 35.577879034287143],
              [-81.818266685415381, 35.577918877506534],
              [-81.818217036241748, 35.578183886339936],
              [-81.818877495875299, 35.578263572151315],
              [-81.818827848486507, 35.578528581210037],
              [-81.819158079813349, 35.578568421999968],
              [-81.819108434257856, 35.578833431126377],
              [-81.819438666995211, 35.578873272051595],
              [-81.819389021067053, 35.579138281296679],
              [-81.82104019590345, 35.579337470386605],
              [-81.820990556027553, 35.579602480227607],
              [-81.821651031010759, 35.579682150007464],
              [-81.821601391816927, 35.579947160099387],
              [-81.82193163138578, 35.579986993311167],
              [-81.821881994025262, 35.580252003470598],
              [-81.82221223387036, 35.580291835942177],
              [-81.82254247514274, 35.58033166749032],
              [-81.822492839567403, 35.580596677874993],
              [-81.822823081116113, 35.580636508682922],
              [-81.822723811101937, 35.581166529599422],
              [-81.82305405618763, 35.581206359747966],
              [-81.822954786954043, 35.581736379871742],
              [-81.823285034505176, 35.581776210287472],
              [-81.823185766083753, 35.582306230519428],
              [-81.823516016037516, 35.582346059400372],
              [-81.823367114121197, 35.583141089401472],
              [-81.823697367564051, 35.583180917780687],
              [-81.823647734018891, 35.583445928435772],
              [-81.823317479521265, 35.583406099924488],
              [-81.823218209285216, 35.583936119051295],
              [-81.825860275639997, 35.584254724009796],
              [-81.825810648474743, 35.584519734571167],
              [-81.826140909835175, 35.584559556269348],
              [-81.826091283400388, 35.5848245669237],
              [-81.826421546140196, 35.584864387856022],
              [-81.826371920436003, 35.585129398603385],
              [-81.826702184555117, 35.5851692187697],
              [-81.826652560684551, 35.585434229584394],
              [-81.82698282618297, 35.585474048984828],
              [-81.826933201939966, 35.585739059918083],
              [-81.827263468817705, 35.585778878552581],
              [-81.82721384640837, 35.586043889553224],
              [-81.827544114665429, 35.586083707421658],
              [-81.827494491883684, 35.586348718540926],
              [-81.82782476259159, 35.586388534716825],
              [-81.827775140540595, 35.586653545929025],
              [-81.829756773642657, 35.586892428624971],
              [-81.829707158700344, 35.587157440564532],
              [-81.830697982763027, 35.58727686928443],
              [-81.830648370661606, 35.587541881580911],
              [-81.83263003607874, 35.587780715514796],
              [-81.832679640746818, 35.58751570245208],
              [-81.833009918784867, 35.587555505424803],
              [-81.83310912503832, 35.587025478918122],
              [-81.833439401258914, 35.587065279827826],
              [-81.833538604105485, 35.586535252900894],
              [-81.834199152227697, 35.586614852425029],
              [-81.834248751054503, 35.586349838639023],
              [-81.834579023979643, 35.58638963648449],
              [-81.834678218550735, 35.585859608531344],
              [-81.835008490793015, 35.585899405189132],
              [-81.835058086537217, 35.585634391022012],
              [-81.835388356945614, 35.585674186675554],
              [-81.835487545320092, 35.58514415705924],
              [-81.835817813942512, 35.585183951550889],
              [-81.835916997838936, 35.584653922441305],
              [-81.836247264675507, 35.584693715771017],
              [-81.836346445133771, 35.584163685340435],
              [-81.836676710184548, 35.584203477508247],
              [-81.836775887236328, 35.583673446657741],
              [-81.83776667800322, 35.583792816981465],
              [-81.837717092237128, 35.584057832384424],
              [-81.838047357529348, 35.584097620828288],
              [-81.837997773628615, 35.584362637199398],
              [-81.838658307295219, 35.584442211656956],
              [-81.838608725148873, 35.58470722735197],
              [-81.839269262221848, 35.584786798481488],
              [-81.839219681861479, 35.585051814401318],
              [-81.839880222340767, 35.585131382202583],
              [-81.839929800559517, 35.584866365117854],
              [-81.840260070230258, 35.584906147539577],
              [-81.840309647101378, 35.584641131184974],
              [-81.840639917112583, 35.584680911649862],
              [-81.840689491501621, 35.584415895150102],
              [-81.841019759710392, 35.58445567551199],
              [-81.841118907580878, 35.5839256411791],
              [-81.841449173972038, 35.583965419478048],
              [-81.841498746381973, 35.583700402571694],
              [-81.841829012042112, 35.58374017984081],
              [-81.841878581970136, 35.583475162789355],
              [-81.842208846899183, 35.583514939028561],
              [-81.842307984744536, 35.582984903618197],
              [-81.843298774105861, 35.583104227984144],
              [-81.843348339913206, 35.582839209361367],
              [-81.844339128993568, 35.582958524348797],
              [-81.844289567454155, 35.58322354334134],
              [-81.844950097983315, 35.583303082723134],
              [-81.844900538230021, 35.583568101940543],
              [-81.845891339602559, 35.583687404223319],
              [-81.845940896190641, 35.583422384610266],
              [-81.846271162897096, 35.583462149809655],
              [-81.846320718137875, 35.58319713092682],
              [-81.846650984113182, 35.583236895096327],
              [-81.846700536840501, 35.582971875167452],
              [-81.847030802084575, 35.583011638307099],
              [-81.847080354567609, 35.582746619082606],
              [-81.847740883917396, 35.582826142404087],
              [-81.847790432831772, 35.582561122001756],
              [-81.84845096136705, 35.582640641467584],
              [-81.848500507847518, 35.582375620762612],
              [-81.849161035599664, 35.582455137273691],
              [-81.849210579646325, 35.582190116266119],
              [-81.849871106552058, 35.582269628020526],
              [-81.849970189453728, 35.581739585361241],
              [-81.849639927948957, 35.58169983019679],
              [-81.849739011633844, 35.581169786744475],
              [-81.849408752562908, 35.581130030945609],
              [-81.849507838197198, 35.580599988476592],
              [-81.849177581560042, 35.580560232043446],
              [-81.849227124363324, 35.580295209994283],
              [-81.849557379945381, 35.580334966295617],
              [-81.849606921401531, 35.580069944976778],
              [-81.849937175149563, 35.58010970027415],
              [-81.84988763478006, 35.580374722625848],
              [-81.852199430743852, 35.580652984507928],
              [-81.85224896375999, 35.580387962134438],
              [-81.852909477862923, 35.58046745743755],
              [-81.852959007310758, 35.580202433886441],
              [-81.853289264344241, 35.580242180046284],
              [-81.853388320190476, 35.579712133464952],
              [-81.853718575437668, 35.5797518784631],
              [-81.853768101836081, 35.579486855432911],
              [-81.855089118501539, 35.579645825095348],
              [-81.85503959632311, 35.579910848652823],
              [-81.855700109243514, 35.579990327896247],
              [-81.855650588883179, 35.580255352579471],
              [-81.855980847451292, 35.580295091423551],
              [-81.855931327790572, 35.58056011529866],
              [-81.856261587737364, 35.580599853376455],
              [-81.856311106311239, 35.580334828468544],
              [-81.856641364423723, 35.580374565542542],
              [-81.856690881619002, 35.580109540464015],
              [-81.857351397775943, 35.580189010727558],
              [-81.857400912569176, 35.579923986247543],
              [-81.857731169510757, 35.57996371946308],
              [-81.857780682925352, 35.579698694812436],
              [-81.857830194881743, 35.579433669248019],
              [-81.857499940050175, 35.579393936296093],
              [-81.857598966205046, 35.578863885288165],
              [-81.857268712704183, 35.578824151727801],
              [-81.857318226902663, 35.578559126284382],
              [-81.856987975851666, 35.578519391030781],
              [-81.857235545090404, 35.577194264843307],
              [-81.856905299637774, 35.577154529350615],
              [-81.857004326944661, 35.576624478512279],
              [-81.857334570287321, 35.57666421374141],
              [-81.857433593055291, 35.576134161609382],
              [-81.857763834611674, 35.576173895676959],
              [-81.857862855078309, 35.575643843100352],
              [-81.858193093745555, 35.575683576032382],
              [-81.858292110807952, 35.575153523037166],
              [-81.85862234768905, 35.575193254807736],
              [-81.858770867123923, 35.574398174191394],
              [-81.859761571285418, 35.574517362002787],
              [-81.859811073573667, 35.574252334366236],
              [-81.860141307529332, 35.574292062018095],
              [-81.860190808439256, 35.574027034211035],
              [-81.860851275179485, 35.57410648565638],
              [-81.860900773656013, 35.573841457547182],
              [-81.861231006472451, 35.573881182241685],
              [-81.861280503570683, 35.57361615396195],
              [-81.86194096800044, 35.573695598591783],
              [-81.861990462697207, 35.573430570910894],
              [-81.862320694342515, 35.573470291747171],
              [-81.862370186526192, 35.573205263020803],
              [-81.8627004185429, 35.573244982801384],
              [-81.862799399862041, 35.572714925870187],
              [-81.863129628989611, 35.57275464451542],
              [-81.863228607976239, 35.572224586238931],
              [-81.863558835317519, 35.572264303722818],
              [-81.863608322719358, 35.571999274407837],
              [-81.863938549329177, 35.572038990862076],
              [-81.864185976213179, 35.570713843048729],
              [-81.864846418751782, 35.570793271972214],
              [-81.864895901048655, 35.570528242030228],
              [-81.865226122299603, 35.570567955000385],
              [-81.865325082679007, 35.570037893862015],
              [-81.86565530214358, 35.570077605670825],
              [-81.865754259119981, 35.569547544114585],
              [-81.867405347436787, 35.569746088451488],
              [-81.867355874951713, 35.57001112034488],
              [-81.867686094198817, 35.570050826660783],
              [-81.867636622413372, 35.570315857746237],
              [-81.868297065089919, 35.570395267921548],
              [-81.86824759509085, 35.570660299231768],
              [-81.869568489613926, 35.570819108407093],
              [-81.869519023511103, 35.57108414020545],
              [-81.871830609575824, 35.571362022369257],
              [-81.871781150533934, 35.571627055050605],
              [-81.872111379629146, 35.571666749296263],
              [-81.872012463788096, 35.572196814780227],
              [-81.872342695316462, 35.572236508391263],
              [-81.872293238517429, 35.572501541193915],
              [-81.872623471391947, 35.572541233137535],
              [-81.872524557831298, 35.573071298916254],
              [-81.87285479317093, 35.573110991126327],
              [-81.872805337512091, 35.573376024076204],
              [-81.873135574197974, 35.573415714618939],
              [-81.873185029904619, 35.573150681511251],
              [-81.873515265858771, 35.573190371024396],
              [-81.873465812309846, 35.57345540423762],
              [-81.874126288432393, 35.573534781708162],
              [-81.87417573987139, 35.573269748231667],
              [-81.874505977897911, 35.573309435024861],
              [-81.874703776202054, 35.572249300205904],
              [-81.87437354349818, 35.572209613913124],
              [-81.874521891965713, 35.571414511887035],
              [-81.874852121504802, 35.57145419778486],
              [-81.874951016778823, 35.570924129911774],
              [-81.875281244531251, 35.570963814648437],
              [-81.875330690628601, 35.570698780522378],
              [-81.87566091764927, 35.570738464229478],
              [-81.875710362336619, 35.570473429032205],
              [-81.877031269463288, 35.570632155256078],
              [-81.876981828995582, 35.5708971909798],
              [-81.877312057631912, 35.570936870197322],
              [-81.877213178907951, 35.571466940865079],
              [-81.87754340997725, 35.57150661944786],
              [-81.87749397061809, 35.571771654418015],
              [-81.878154436938829, 35.571851009126718],
              [-81.878105000469375, 35.572116044295306],
              [-81.879425940104994, 35.572274743489736],
              [-81.879475372354591, 35.572009707794898],
              [-81.881787023116757, 35.572287394959062],
              [-81.881836447658557, 35.572022358304501],
              [-81.882496920236875, 35.572101688502116],
              [-81.882546341242787, 35.571836651572092],
              [-81.883206813003142, 35.571915977914742],
              [-81.883256232678988, 35.571650940656752],
              [-81.883916702518505, 35.571730263170622],
              [-81.883966119729365, 35.571465224709904],
              [-81.884296354094658, 35.571504884938925],
              [-81.88444459845087, 35.57070976988404],
              [-81.884774829974091, 35.570749428820399],
              [-81.88487365509576, 35.570219351978992],
              [-81.885203884799949, 35.570259008853391],
              [-81.885253295805398, 35.569993969792748],
              [-81.885913755207582, 35.570073281459379],
              [-81.885963162709473, 35.569808243024404],
              [-81.886623621293396, 35.569887550836185],
              [-81.886673026330399, 35.569622511198524],
              [-81.88700325448427, 35.569662163188603],
              [-81.887052659246208, 35.569397123354676],
              [-81.888373570935002, 35.569555723611415],
              [-81.889034028684449, 35.569635017451034],
              [-81.889083425690984, 35.569369976815743],
              [-81.889413655129857, 35.569409623131953],
              [-81.889463050758579, 35.569144582326622],
              [-81.889793278330515, 35.569184226738592],
              [-81.889842672581381, 35.568919185763228],
              [-81.890172899421174, 35.568958829145757],
              [-81.890222292294354, 35.568693788000395],
              [-81.890552518401904, 35.568733430353511],
              [-81.890898250956326, 35.566878139462617],
              [-81.891228470002119, 35.566917779997311],
              [-81.891277858151, 35.566652737379954],
              [-81.891608075362001, 35.566692376911561],
              [-81.89165746216527, 35.566427335025352],
              [-81.891987678644014, 35.566466973527497],
              [-81.892037064037666, 35.566201930570301],
              [-81.892367279816412, 35.566241568944093],
              [-81.892466047525488, 35.565711482651217],
              [-81.892796261485032, 35.565751118963192],
              [-81.892845642729995, 35.565486076554976],
              [-81.893175857060214, 35.565525711811226],
              [-81.893225236895319, 35.565260668332066],
              [-81.893885662208547, 35.565339935940543],
              [-81.893935040713998, 35.565074892133659],
              [-81.895255890993198, 35.565233416049878],
              [-81.895305263853686, 35.564968371705064],
              [-81.895635477278049, 35.565008000281615],
              [-81.895734219921167, 35.564477911213864],
              [-81.896064430455596, 35.564517538655828],
              [-81.896212537733234, 35.563722404398042],
              [-81.895882330363406, 35.563682777350316],
              [-81.896079807906204, 35.562622598293082],
              [-81.895749605078407, 35.562582970873329],
              [-81.895848343991602, 35.562052880476294],
              [-81.89617854360705, 35.562092507659536],
              [-81.89632664530609, 35.561297371858807],
              [-81.896656843182413, 35.561336997723494],
              [-81.896706207958829, 35.561071951665369],
              [-81.897036405103037, 35.561111576500707],
              [-81.897135132713274, 35.560581484881197],
              [-81.897465326967804, 35.560621108582112],
              [-81.897514688666874, 35.56035606214612],
              [-81.897844883291796, 35.560395684791459],
              [-81.897943603644833, 35.559865592442598],
              [-81.89827379534789, 35.559905213052552],
              [-81.8983725134038, 35.559375120261194],
              [-81.899032893590771, 35.559454359162906],
              [-81.899082249457948, 35.559189312009579],
              [-81.899412438996535, 35.559228930432667],
              [-81.899560501498584, 35.558433788348808],
              [-81.899230315124171, 35.558394170319907],
              [-81.899329023955772, 35.557864075668839],
              [-81.898998841148199, 35.557824457879704],
              [-81.899097550766953, 35.557294362437347],
              [-81.899427732599833, 35.557333980838393],
              [-81.899625142677962, 35.556273789896437],
              [-81.899294966166423, 35.556234171994753],
              [-81.899393671914964, 35.555704076093633],
              [-81.899063497867573, 35.55566445845799],
              [-81.899112851296707, 35.555399410130974],
              [-81.898782678594188, 35.55535979082795],
              [-81.898832032787681, 35.555094743495239],
              [-81.898501861462336, 35.555055123425838],
              [-81.898551216355784, 35.554790075285347],
              [-81.897230539633156, 35.554631587430379],
              [-81.897279898422624, 35.554366539777348],
              [-81.896949730819188, 35.554326915481958],
              [-81.896999091475493, 35.554061868796936],
              [-81.896668925249315, 35.554022243735147],
              [-81.896767646536517, 35.553492148737753],
              [-81.896437482742073, 35.553452523041081],
              [-81.896486844507692, 35.553187475603387],
              [-81.896156682090407, 35.553147849140494],
              [-81.896206044620328, 35.552882802697098],
              [-81.895875884682624, 35.552843175441502],
              [-81.895925246809853, 35.552578128216702],
              [-81.89559508824928, 35.552538500194906],
              [-81.895545723964929, 35.552803547314475],
              [-81.895215564640282, 35.552763917362427],
              [-81.895166198978785, 35.553028964312354],
              [-81.894175718770413, 35.552910071404561],
              [-81.894126349622624, 35.553175117921917],
              [-81.893466029722433, 35.553095851214131],
              [-81.893416658110866, 35.553360896529419],
              [-81.892756336289494, 35.553281625993833],
              [-81.892706962278211, 35.553546671909004],
              [-81.891716479321275, 35.553427758063904],
              [-81.891667100721193, 35.553692803572673],
              [-81.891006777875717, 35.553613522632524],
              [-81.891056158585044, 35.553348477386741],
              [-81.890725999268213, 35.55330883613901],
              [-81.89077537960685, 35.553043791012811],
              [-81.890445221635218, 35.553004148097742],
              [-81.890543984588163, 35.552474058868661],
              [-81.890213829048264, 35.552434415318835],
              [-81.890460736527572, 35.551109189970184],
              [-81.890130586615001, 35.551069547081234],
              [-81.890229349204503, 35.550539456042017],
              [-81.88989920172348, 35.55049981251836],
              [-81.890047346918507, 35.549704676505513],
              [-81.889717203993953, 35.549665031551434],
              [-81.890112250573026, 35.54754466705996],
              [-81.889782116406593, 35.547505022260346],
              [-81.889880876901543, 35.546974930118701],
              [-81.890211008959184, 35.547014574655272],
              [-81.890260387117195, 35.546749528409066],
              [-81.890590518443091, 35.546789171916366],
              [-81.890639895224027, 35.546524125500497],
              [-81.890970025786132, 35.5465637670777],
              [-81.891019401189979, 35.546298720492281],
              [-81.891349531052441, 35.546338361941302],
              [-81.891398905079399, 35.546073315186455],
              [-81.891729034177885, 35.546112954705208],
              [-81.891827779155278, 35.545582860818293],
              [-81.892157905365153, 35.545622499202658],
              [-81.892306017248856, 35.544827357692739],
              [-81.892636141720686, 35.544866994758671],
              [-81.892734879222814, 35.544336899064689],
              [-81.893065000838234, 35.54437653589725],
              [-81.893114368035157, 35.544111487411229],
              [-81.893444488918789, 35.544151123214753],
              [-81.893543219165792, 35.543621026793154],
              [-81.894203458753822, 35.54370029425391],
              [-81.894154095332894, 35.543965342309349],
              [-81.894484216097638, 35.544004974387434],
              [-81.894434854543121, 35.544270023411016],
              [-81.895095099149003, 35.544349285137216],
              [-81.895045739380677, 35.544614334385876],
              [-81.89570598848762, 35.544693592758072],
              [-81.895656629403064, 35.544958642257917],
              [-81.895986756045914, 35.544998270215878],
              [-81.896282893974004, 35.543407974692613],
              [-81.896613013510688, 35.543447600990412],
              [-81.896662368583847, 35.543182551900763],
              [-81.896992487388758, 35.543222177169376],
              [-81.897140544106591, 35.542427026654309],
              [-81.897470660103167, 35.54246665153201],
              [-81.89781610601041, 35.540611298108864],
              [-81.898146213814783, 35.54065092029402],
              [-81.89824490791797, 35.540120817803491],
              [-81.898575013968312, 35.540160439729163],
              [-81.898673704673385, 35.539630336824125],
              [-81.89834359966126, 35.539590716088775],
              [-81.898392945600577, 35.539325665161414],
              [-81.898723048456063, 35.539365285791618],
              [-81.898871080010082, 35.538570130612477],
              [-81.899201180025258, 35.538609749950751],
              [-81.899299863650867, 35.538079646354966],
              [-81.899629961879981, 35.538119264532746],
              [-81.899827319911665, 35.537059055486786],
              [-81.900157414246294, 35.537098672241292],
              [-81.900206751610554, 35.536833619535116],
              [-81.901197033385685, 35.536952464018817],
              [-81.901246367265045, 35.536687410880525],
              [-81.901576460780589, 35.536727023781893],
              [-81.901625793283443, 35.536461970474384],
              [-81.902285978070594, 35.536541193347873],
              [-81.902335308142796, 35.536276139739719],
              [-81.902665399414502, 35.536315749711861],
              [-81.902616071498812, 35.536580803424997],
              [-81.902946164146968, 35.536620412631009],
              [-81.902847508354853, 35.537150520233205],
              [-81.903177604503682, 35.537190127877082],
              [-81.903078948428657, 35.537720235617279],
              [-81.903409047040455, 35.537759843527432],
              [-81.903458374184922, 35.53749478954478],
              [-81.903788472064846, 35.537534396425933],
              [-81.903837797832821, 35.537269342274072],
              [-81.904167894980674, 35.53730894812626],
              [-81.904217218269835, 35.537043893831552],
              [-81.904877411391666, 35.537123101679313],
              [-81.904926732250146, 35.536858047083996],
              [-81.905256828256356, 35.536897649980617],
              [-81.905355468000451, 35.536367540387509],
              [-81.905685562188182, 35.536407141222732],
              [-81.90583351457181, 35.535611976195199],
              [-81.906163605951306, 35.535651576639694],
              [-81.906360867004054, 35.534591353746819],
              [-81.9066909533868, 35.534630952794565],
              [-81.906740266806437, 35.534365897296098],
              [-81.907070353527189, 35.534405494387507],
              [-81.907119665570605, 35.534140438719938],
              [-81.907449750457033, 35.534180034808777],
              [-81.907597680357227, 35.533384866311039],
              [-81.90792776350564, 35.533424461081729],
              [-81.908174301183195, 35.532099176747678],
              [-81.908504378313069, 35.53213877089145],
              [-81.908602988787621, 35.531608656276212],
              [-81.908701597973845, 35.531078541510212],
              [-81.90903167010714, 35.53111813425739],
              [-81.909080973681839, 35.530853075772335],
              [-81.909411045083033, 35.530892667490633],
              [-81.909460347313868, 35.530627609737628],
              [-81.910450562427684, 35.530746377258332],
              [-81.910499860071923, 35.530481319099962],
              [-81.911160002594215, 35.530560492104911],
              [-81.911110707058157, 35.530825550525982],
              [-81.912761074853506, 35.53102346887632],
              [-81.912810365118943, 35.530758409798601],
              [-81.913140439024389, 35.530797990453948],
              [-81.913189726811552, 35.530532931233672],
              [-81.913849873480089, 35.530612089589177],
              [-81.91389915883704, 35.530347030068569],
              [-81.914229231600117, 35.53038660776874],
              [-81.914179946195119, 35.530651667447117],
              [-81.914840094795494, 35.530730820417453],
              [-81.914790811209059, 35.530995881221891],
              [-81.915450963205529, 35.53107503086472],
              [-81.915401682475391, 35.531340090966637],
              [-81.916391916014675, 35.531458808192326],
              [-81.916342638124945, 35.531723868650545],
              [-81.917002797557487, 35.531803009843173],
              [-81.917052073338894, 35.53153794912236],
              [-81.918042311706984, 35.531656652884926],
              [-81.918091582901724, 35.531391591759089],
              [-81.918421661958249, 35.531431158053891],
              [-81.918470932879188, 35.531166096732669],
              [-81.920121327654002, 35.531363912284945],
              [-81.920170591879895, 35.531098850296203],
              [-81.921160830520833, 35.531217527715796],
              [-81.921210091262381, 35.530952465295634],
              [-81.921870249133079, 35.531031579143075],
              [-81.921919507412085, 35.530766515521826],
              [-81.922249585792088, 35.530806071418517],
              [-81.922200328534799, 35.531071134270015],
              [-81.922860488336184, 35.53115024273221],
              [-81.922909743485206, 35.530885179618153],
              [-81.923569902432874, 35.530964283326625],
              [-81.923520649424802, 35.531229347604111],
              [-81.923850730435419, 35.531268898243241],
              [-81.923899982389301, 35.531003833834454],
              [-81.924560143300099, 35.531082933058606],
              [-81.924609392856226, 35.530817869250761],
              [-81.924939472723807, 35.530857416934758],
              [-81.924988719769345, 35.530592352083637],
              [-81.925648878393687, 35.530671445397424],
              [-81.92574736843757, 35.530141315932028],
              [-81.926077446107726, 35.530180860529704],
              [-81.926126689025466, 35.529915795172123],
              [-81.926456765995653, 35.529955339642036],
              [-81.926506007537597, 35.529690274115687],
              [-81.927166160269564, 35.529769358298381],
              [-81.927215399381467, 35.529504292472026],
              [-81.927545474073597, 35.529543833112413],
              [-81.927643949256463, 35.529013701985704],
              [-81.927974023264142, 35.529053241439577],
              [-81.928220199170724, 35.527727910984623],
              [-81.928550267127065, 35.52776744891154],
              [-81.928599500503282, 35.527502382391212],
              [-81.929259636374653, 35.527581455263444],
              [-81.929210405106815, 35.527846522046069],
              [-81.929540475130125, 35.527886057253795],
              [-81.929392783628856, 35.528681256842646],
              [-81.929722857136284, 35.528720791546377],
              [-81.929673626690203, 35.528985858441274],
              [-81.930003701573497, 35.529025392378664],
              [-81.929954472962237, 35.52929045934065],
              [-81.930284549188684, 35.529329991610574],
              [-81.930235320207871, 35.529595058692806],
              [-81.930565397842656, 35.529634591097448],
              [-81.930516170696578, 35.529899658246599],
              [-81.93084624967473, 35.529939188983839],
              [-81.930600112121908, 35.531264523073361],
              [-81.930930196725271, 35.531304054469913],
              [-81.930831742404095, 35.531834187002403],
              [-81.931161829437656, 35.531873717763709],
              [-81.931112602848941, 35.532138784104767],
              [-81.931442691258283, 35.532178314099653],
              [-81.931393465402223, 35.532443380534197],
              [-81.931063375938592, 35.532403850408151],
              [-81.930915691105952, 35.533199050046896],
              [-81.930585598801088, 35.533159518629731],
              [-81.930437907878385, 35.533954716635215],
              [-81.930768004448538, 35.533994248419276],
              [-81.930718775286891, 35.53425931445927],
              [-81.931048873200353, 35.534298844575872],
              [-81.930950414918513, 35.534828976831726],
              [-81.931280515262372, 35.534868506313039],
              [-81.931231287244501, 35.53513357250236],
              [-81.931561388996897, 35.535173102118137],
              [-81.931512161679208, 35.535438167499855],
              [-81.932172368257454, 35.535517224300989],
              [-81.93212314272688, 35.535782289907402],
              [-81.932783353770049, 35.535861342452591],
              [-81.932734130059188, 35.536126409184632],
              [-81.933394344497643, 35.536205458401518],
              [-81.933345122541397, 35.536470524457137],
              [-81.933675231864768, 35.536510048287496],
              [-81.933527567262374, 35.537305247522497],
              [-81.933857680037988, 35.53734476994758],
              [-81.933611570083499, 35.538670101333103],
              [-81.933941689587868, 35.538709624390592],
              [-81.933892467900577, 35.538974690500034],
              [-81.934222587646161, 35.539014211916566],
              [-81.934025701898975, 35.540074476501459],
              [-81.934355826184017, 35.540113997544829],
              [-81.934158939476532, 35.541174261148846],
              [-81.934489069436296, 35.54121378269339],
              [-81.934144512624627, 35.543069241435383],
              [-81.934474650255297, 35.543108762099074],
              [-81.93437620467131, 35.543638893218755],
              [-81.93470634583511, 35.543678413220242],
              [-81.934657123598939, 35.543943478403889],
              [-81.934987265069239, 35.54398299856647],
              [-81.934938043566049, 35.544248063843341],
              [-81.935268187482393, 35.544287582311604],
              [-81.935169745620414, 35.544817713014027],
              [-81.935499890865103, 35.544857230873419],
              [-81.935450670506313, 35.54512229629897],
              [-81.936110966274853, 35.54520132953327],
              [-81.936061747703761, 35.545466395183169],
              [-81.937712498026031, 35.545663963213414],
              [-81.93776171022138, 35.545398896934806],
              [-81.938091860195954, 35.545438407716439],
              [-81.938141071015096, 35.545173341268743],
              [-81.93847122025646, 35.545212851021546],
              [-81.938569639923188, 35.544682717723717],
              [-81.938899787344226, 35.544722225415526],
              [-81.938801369819458, 35.545252359876542],
              [-81.939131519671363, 35.545291866932693],
              [-81.938934686019124, 35.546352134095649],
              [-81.93926484044394, 35.546391641679321],
              [-81.939117214684558, 35.547186841152495],
              [-81.939447373697419, 35.547226348204987],
              [-81.939398164798476, 35.547491415069445],
              [-81.939728325155059, 35.547530920454186],
              [-81.93958070186541, 35.548326120258743],
              [-81.939250539414431, 35.548286613553088],
              [-81.939201329229249, 35.548551680265831],
              [-81.938871166045161, 35.548512172531275],
              [-81.938772743670455, 35.549042304652964],
              [-81.938442577595836, 35.549002795785121],
              [-81.93834415182539, 35.549532927492898],
              [-81.938013983962804, 35.549493417465058],
              [-81.93791555476372, 35.550023547857769],
              [-81.937255215816961, 35.549944525485301],
              [-81.937205999176797, 35.550209590349162],
              [-81.93654565937419, 35.550130563222162],
              [-81.936496439200241, 35.550395627812513],
              [-81.936166268726694, 35.550356112771226],
              [-81.936117047176324, 35.550621177192447],
              [-81.935786875969541, 35.550581661122152],
              [-81.935737653042722, 35.550846725374313],
              [-81.935407481102899, 35.550807208275103],
              [-81.935309032174729, 35.551337336403215],
              [-81.935639206224252, 35.551376853764665],
              [-81.935491533583956, 35.552172046064939],
              [-81.935821711119587, 35.552211562921741],
              [-81.935772487285021, 35.552476626175881],
              [-81.936102666197058, 35.552516142266001],
              [-81.935954995993171, 35.553311333995389],
              [-81.936285179494064, 35.55335084955427],
              [-81.936235956482548, 35.553615912918296],
              [-81.936566141359975, 35.55365542771041],
              [-81.936516919114297, 35.553920492068578],
              [-81.936847105368159, 35.55396000609381],
              [-81.936748661956358, 35.554490133156058],
              [-81.93707885064174, 35.554529646545589],
              [-81.937029629508245, 35.554794710150667],
              [-81.937359819570105, 35.554834222773437],
              [-81.937310599202505, 35.55509928737245],
              [-81.93764079060837, 35.555138798327498],
              [-81.937591572043971, 35.555403862091808],
              [-81.937921764859055, 35.55544337318095],
              [-81.937774107125676, 35.556238565619665],
              [-81.938104304497088, 35.556278075276374],
              [-81.937956648103778, 35.557073266837641],
              [-81.93828684789176, 35.557112776917322],
              [-81.938139191735885, 35.557907967627564],
              [-81.937478783979032, 35.557828946738077],
              [-81.937429562150427, 35.558094010278943],
              [-81.936769154640245, 35.558014984607865],
              [-81.936719930379979, 35.558280047848427],
              [-81.936389725501087, 35.5582405335483],
              [-81.936340499864116, 35.558505596619518],
              [-81.936010294252029, 35.558466081290298],
              [-81.93576415479869, 35.559791393643245],
              [-81.936094365685946, 35.559830909627905],
              [-81.935897452286255, 35.560891158090882],
              [-81.935567236076253, 35.560851641608537],
              [-81.93546877587093, 35.561381764884686],
              [-81.935138557905091, 35.561342248143269],
              [-81.93499086091623, 35.5621374324041],
              [-81.935321082079838, 35.562176950439842],
              [-81.935271849426741, 35.562442011932426],
              [-81.934941627207962, 35.562402493765646],
              [-81.934843159896175, 35.562932615446258],
              [-81.935173384225152, 35.562972133875292],
              [-81.935025684609812, 35.563767317840643],
              [-81.935355912393206, 35.563806834863932],
              [-81.935060509637694, 35.565397200771791],
              [-81.934730274387974, 35.565357682087601],
              [-81.934681037840974, 35.565622742219716],
              [-81.934350802960594, 35.565583222479773],
              [-81.93425232682327, 35.566113343267844],
              [-81.933261616712031, 35.565994778800963],
              [-81.933212374978808, 35.566259838293618],
              [-81.932882137117474, 35.566220314594958],
              [-81.932783650575232, 35.566750433203069],
              [-81.932453410925362, 35.566710908344099],
              [-81.932404166148814, 35.566975968360552],
              [-81.932073925765707, 35.566936442472418],
              [-81.931926186241583, 35.567731619195207],
              [-81.931595943014642, 35.567692092015704],
              [-81.93149744517612, 35.568222209403295],
              [-81.93116720016063, 35.568182681063512],
              [-81.930970197470089, 35.569242913925855],
              [-81.931300446706416, 35.569282442790104],
              [-81.931251195432836, 35.569547500384978],
              [-81.931581447149085, 35.569587028455672],
              [-81.931532196641342, 35.56985208704419],
              [-81.932192702047359, 35.569931140780177],
              [-81.932143454398442, 35.570196198664924],
              [-81.932803963202559, 35.570275249070733],
              [-81.932754717342334, 35.570540307179243],
              [-81.933415229544508, 35.570619354254937],
              [-81.933365985472989, 35.570884412587205],
              [-81.933696243663618, 35.570923934895738],
              [-81.93364700032555, 35.571188993320582],
              [-81.933977259893254, 35.571228514862085],
              [-81.933928017321236, 35.571493574280638],
              [-81.934258278233628, 35.571533094154134],
              [-81.934209036395075, 35.571798153665242],
              [-81.934539298684541, 35.571837672771736],
              [-81.934490057546967, 35.572102731474509],
              [-81.934820321246221, 35.572142250714904],
              [-81.935150585267422, 35.572181769057266],
              [-81.935199824261701, 35.571916709191321],
              [-81.935860351158823, 35.571995742919519],
              [-81.935909586617854, 35.571730682779609],
              [-81.937230639987206, 35.571888737132348],
              [-81.937181408716938, 35.572153796895513],
              [-81.937511673935489, 35.572193308820005],
              [-81.937413212540335, 35.572723428492949],
              [-81.937743480191415, 35.572762939781406],
              [-81.937694250066542, 35.573027999691277],
              [-81.938354788412084, 35.573107018934849],
              [-81.938305560076202, 35.573372079068314],
              [-81.938966102889722, 35.573451094053759],
              [-81.938916876342986, 35.573716154410825],
              [-81.939247148769056, 35.573755661601766],
              [-81.939197922955856, 35.574020722051365],
              [-81.939528197829361, 35.57406022754742],
              [-81.939478972749868, 35.574325288089575],
              [-81.939809249000461, 35.574364792818592],
              [-81.939760024654646, 35.574629853453274],
              [-81.942402253615683, 35.574945860927485],
              [-81.94245146841503, 35.57468079927137],
              [-81.943442306785855, 35.574799285612549],
              [-81.943491518129747, 35.574534224426003],
              [-81.944152076530116, 35.574613210266939],
              [-81.944201285408781, 35.574348147878823],
              [-81.945192122304434, 35.574466620862516],
              [-81.945142916592204, 35.574731683643606],
              [-81.94580347820893, 35.574810660502813],
              [-81.945557455345579, 35.576135974239293],
              [-81.945227169115242, 35.576096486054418],
              [-81.945128755587731, 35.576626610476715],
              [-81.944798467535165, 35.576587120230634],
              [-81.944749259249861, 35.576852182703512],
              [-81.944418969360569, 35.576812691454975],
              [-81.944320549680981, 35.577342815122137],
              [-81.943990258002259, 35.577303322713419],
              [-81.943891834924969, 35.577833445964323],
              [-81.943561541456845, 35.577793952395488],
              [-81.943463113878792, 35.57832407525683],
              [-81.943132818621251, 35.578284580527786],
              [-81.943083603861851, 35.578549642206703],
              [-81.942753307870547, 35.578510146448451],
              [-81.94270409170116, 35.578775207056836],
              [-81.942373794975907, 35.578735710269306],
              [-81.942324576326328, 35.579000770734751],
              [-81.942654874107163, 35.579040267653291],
              [-81.942605657294337, 35.579305328184482],
              [-81.942935956452473, 35.579344824335806],
              [-81.94288673927062, 35.579609884986105],
              [-81.943217039805987, 35.579649380370135],
              [-81.943118607691204, 35.58017950179017],
              [-81.943448910659583, 35.580218996537923],
              [-81.9431043960316, 35.582074420293544],
              [-81.943434706711187, 35.582113915059999],
              [-81.943385490549161, 35.582378974789158],
              [-81.943715803708983, 35.582418468761695],
              [-81.943666587177944, 35.582683528609884],
              [-81.943996901715266, 35.582723021815049],
              [-81.943947685950903, 35.582988082656563],
              [-81.944278001832856, 35.583027574193508],
              [-81.944228787905402, 35.583292635100598],
              [-81.94455910519757, 35.583332126771161],
              [-81.944460677320521, 35.583862246955924],
              [-81.944790997045757, 35.583901737990239],
              [-81.944840210640905, 35.58363667777288],
              [-81.945170529599395, 35.583676166876927],
              [-81.945268951474475, 35.583146045216623],
              [-81.945599268676077, 35.583185534061485],
              [-81.945648478058999, 35.582920472591823],
              [-81.945978794526496, 35.582959960407415],
              [-81.946028002532032, 35.582694898768146],
              [-81.946358318265595, 35.582734385554481],
              [-81.946407524893758, 35.58246932374557],
              [-81.94739847082441, 35.582587777421061],
              [-81.947447672860875, 35.582322715207361],
              [-81.94810830296727, 35.582401679270681],
              [-81.948157503673613, 35.582136616729656],
              [-81.948487818169895, 35.582176097733466],
              [-81.948537016395989, 35.5819110350497],
              [-81.94919764420932, 35.581989993199585],
              [-81.949246840002402, 35.581724930215195],
              [-81.949907466957981, 35.58180388360919],
              [-81.949858272173344, 35.582068946882124],
              [-81.950188587189174, 35.582108422362616],
              [-81.950139394241631, 35.58237348570114],
              [-81.950800027382343, 35.582452435130072],
              [-81.950701643609662, 35.582982561340607],
              [-81.95103196386043, 35.583022034492238],
              [-81.950933580912917, 35.583552160809887],
              [-81.951263902493722, 35.583591633351837],
              [-81.951165521474394, 35.584121759749891],
              [-81.951495846591342, 35.584161231628507],
              [-81.951446656671507, 35.584426295350923],
              [-81.952107307775648, 35.584505236728766],
              [-81.952058120715961, 35.584770299746509],
              [-81.952388447806129, 35.584809769218793],
              [-81.952339260410525, 35.58507483325652],
              [-81.952669589981113, 35.585114301934638],
              [-81.95257121627121, 35.585644428353753],
              [-81.952901548274895, 35.585683896395338],
              [-81.952852361993422, 35.585948959677758],
              [-81.953182695374565, 35.585988426951829],
              [-81.95313350982741, 35.586253490326435],
              [-81.953463844585841, 35.586292956833127],
              [-81.95341465977296, 35.586558020299897],
              [-81.953744995908878, 35.586597486039146],
              [-81.953646628533193, 35.587127613091312],
              [-81.953976967102278, 35.587167078194],
              [-81.953927783436498, 35.587432141806232],
              [-81.954258123383084, 35.587471606141456],
              [-81.95420894155464, 35.587736669819066],
              [-81.954869624524122, 35.587815596056188],
              [-81.954820444453077, 35.588080659055706],
              [-81.955150787475944, 35.588120120957612],
              [-81.955101608172157, 35.588385184950333],
              [-81.955762298364533, 35.588464105392916],
              [-81.955713119748012, 35.588729169635357],
              [-81.956043466950277, 35.58876862907703],
              [-81.95599429017129, 35.589033693384799],
              [-81.956324637615126, 35.589073151184806],
              [-81.95627546157047, 35.589338215584583],
              [-81.959248620388124, 35.589693299950611],
              [-81.959297786962068, 35.589428235274212],
              [-81.959628138723602, 35.589467684063358],
              [-81.959824796379507, 35.588407421770299],
              [-81.960155143135708, 35.588446869164656],
              [-81.960302629567309, 35.587651671877353],
              [-81.960632973477118, 35.587691117980796],
              [-81.960682134278983, 35.587426051700817],
              [-81.961012477454162, 35.58746549677506],
              [-81.961061636878725, 35.587200430325503],
              [-81.961391979319444, 35.587239874370539],
              [-81.961441136263616, 35.586974807778255],
              [-81.961771479105721, 35.587014251668279],
              [-81.961820634639778, 35.586749184005441],
              [-81.962150975644235, 35.586788626893195],
              [-81.962200129833946, 35.586523559961911],
              [-81.962530471174006, 35.586563000892497],
              [-81.962776231957022, 35.585237663199244],
              [-81.963106567235869, 35.585277102604316],
              [-81.963204866078456, 35.584746966841308],
              [-81.963535199566863, 35.584786405086497],
              [-81.963584348018287, 35.584521337453211],
              [-81.964245013847361, 35.584600210986821],
              [-81.964294158729828, 35.584335142179263],
              [-81.964954823765268, 35.584414012758899],
              [-81.965003967317685, 35.584148943624093],
              [-81.965334298141968, 35.584188377012048],
              [-81.96538344031724, 35.583923307707806],
              [-81.96637443375603, 35.584041602035356],
              [-81.96642357133959, 35.583776532327093],
              [-81.966753902440075, 35.58381596183311],
              [-81.966704765912482, 35.58408103167222],
              [-81.968026096647421, 35.584238741263],
              [-81.968075228951506, 35.583973670900832],
              [-81.968735894652937, 35.584052519130154],
              [-81.968785024556965, 35.58378744936882],
              [-81.969115356817113, 35.583826871554713],
              [-81.969066228001893, 35.584091942347783],
              [-81.969396561672013, 35.584131364667044],
              [-81.96934743359148, 35.584396435552165],
              [-81.969677768605777, 35.584435856202823],
              [-81.969579514696008, 35.584965998091839],
              [-81.969909852143175, 35.585005418105538],
              [-81.969664216410337, 35.586330771016563],
              [-81.969994559458456, 35.586370190785694],
              [-81.969798048639788, 35.587430472596978],
              [-81.970128397335884, 35.5874698919637],
              [-81.970079269591977, 35.58773496200677],
              [-81.970739970092794, 35.587813799207431],
              [-81.970690844172736, 35.588078870374233],
              [-81.971021195944445, 35.588118287307218],
              [-81.970972071829209, 35.588383357638094],
              [-81.971302425011018, 35.588422774704298],
              [-81.971253300527621, 35.588687845154141],
              [-81.971914009933869, 35.588766675951938],
              [-81.971864888344385, 35.589031746597499],
              [-81.973186316304819, 35.589189397006756],
              [-81.973235433670126, 35.588924325838342],
              [-81.973565790122947, 35.588963735845368],
              [-81.973614906111095, 35.588698664507504],
              [-81.974605974261237, 35.588816889646871],
              [-81.974655086760123, 35.588551817878191],
              [-81.976306869232857, 35.588748840527401],
              [-81.97625776311726, 35.58901391292251],
              [-81.976588121403907, 35.589053314712949],
              [-81.976539014920306, 35.589318387227053],
              [-81.977530095977585, 35.589436587572642],
              [-81.977480993444217, 35.589701660413006],
              [-81.977811356230134, 35.589741058843906],
              [-81.977762253328805, 35.59000613180325],
              [-81.978422980872978, 35.590084926258172],
              [-81.978373880865888, 35.590349999412972],
              [-81.978704246693994, 35.590389394508989],
              [-81.978655147421989, 35.590654467755698],
              [-81.97898551355739, 35.590693863011836],
              [-81.978936415020513, 35.590958936350525],
              [-81.979266783636021, 35.590998330811829],
              [-81.979168587678387, 35.591528476733153],
              [-81.979498958727191, 35.591567870557277],
              [-81.979449861339674, 35.591832944040952],
              [-81.979780233732541, 35.591872336196253],
              [-81.97973113708008, 35.592137409771723],
              [-81.980061510883331, 35.592176802060187],
              [-81.98001241493283, 35.592441874826612],
              [-81.980342790113255, 35.592481266347143],
              [-81.980293694898052, 35.592746339205497],
              [-81.980624072558641, 35.592785729931101],
              [-81.980574978111747, 35.593050803782205],
              [-81.981235735372209, 35.593129581925311],
              [-81.981284826603613, 35.592864507840062],
              [-81.981615205227158, 35.592903895870158],
              [-81.981664295114527, 35.592638822516498],
              [-81.982325050076, 35.592717594746055],
              [-81.982374137496848, 35.592452520191365],
              [-81.982704514970962, 35.592491905264687],
              [-81.982753601014664, 35.592226830540589],
              [-81.983083976650363, 35.592266214611868],
              [-81.983133062419995, 35.592001139691234],
              [-81.983463437287355, 35.592040521832573],
              [-81.983512520609878, 35.591775447670528],
              [-81.983842894741969, 35.591814828782674],
              [-81.983891977790535, 35.59154975442425],
              [-81.984552724904958, 35.591628513691845],
              [-81.984601804417196, 35.591363439060515],
              [-81.984932177399514, 35.591402817215993],
              [-81.985030333282594, 35.590872665774008],
              [-81.985360704506689, 35.590912043670691],
              [-81.985409780910658, 35.590646967761003],
              [-81.985740151366258, 35.590686343727612],
              [-81.98578922639328, 35.590421267648523],
              [-81.986119595010436, 35.590460642613252],
              [-81.98607052214264, 35.590725718795809],
              [-81.986400893273156, 35.590765093866509],
              [-81.986351820037569, 35.591030170168025],
              [-81.986682192511921, 35.591069543569759],
              [-81.986633120011774, 35.591334619963135],
              [-81.986963493863172, 35.591373992597013],
              [-81.9868653510825, 35.591904144600548],
              [-81.987195727400348, 35.591943517498045],
              [-81.986950369580299, 35.593268897945471],
              [-81.987280752569959, 35.593308269669187],
              [-81.987329823044291, 35.593043193182574],
              [-81.987990587906921, 35.593121934574476],
              [-81.988039655947915, 35.592856857787908],
              [-81.988370037787561, 35.592896226554984],
              [-81.988419104451481, 35.592631149599086],
              [-81.989079865910981, 35.592709885104689],
              [-81.989030801326365, 35.592974961420744],
              [-81.98969156614838, 35.593053692692301],
              [-81.989642503388723, 35.593318770131667],
              [-81.989972886785594, 35.593358134563751],
              [-81.989874762383209, 35.593888288686223],
              [-81.990205149316623, 35.593927652453807],
              [-81.990107025710415, 35.594457805781225],
              [-81.989776636664416, 35.594418441752559],
              [-81.989678508592547, 35.594948595591788],
              [-81.98934811775483, 35.594909230403452],
              [-81.989299052716291, 35.595174306670138],
              [-81.988968661142991, 35.59513494045278],
              [-81.988821459863644, 35.595930169556475],
              [-81.989482249668981, 35.596008901876075],
              [-81.989433184403524, 35.596273978118148],
              [-81.989763580843714, 35.596313343060586],
              [-81.989616386291473, 35.5971085719451],
              [-81.989946786221665, 35.59714793638063],
              [-81.989897721752413, 35.597413011827314],
              [-81.99022812416284, 35.597452375467718],
              [-81.990179059359249, 35.597717451934194],
              [-81.990509463146878, 35.597756814806502],
              [-81.99041133686309, 35.59828696692864],
              [-81.990741743084442, 35.598326329163498],
              [-81.990692680534352, 35.59859140574725],
              [-81.991023088132835, 35.598630767213983],
              [-81.991121210799363, 35.598100613746588],
              [-81.9914516166059, 35.598139974053709],
              [-81.991598793960634, 35.597344744097427],
              [-81.99126839242642, 35.597305384154801],
              [-81.991366511884991, 35.59677523029859],
              [-81.991696911306448, 35.596814589980205],
              [-81.99179502736915, 35.59628443570751],
              [-81.99212542496528, 35.596323793328523],
              [-81.992419757980571, 35.594733327921439],
              [-81.992750149592723, 35.594772684761708],
              [-81.992799203072451, 35.594507606172122],
              [-81.993129593948922, 35.594546961983298],
              [-81.993178644981768, 35.594281884152537],
              [-81.99350903508946, 35.594321238033665],
              [-81.993558085848363, 35.594056160006474],
              [-81.993888475220359, 35.594095512858459],
              [-81.993839425551059, 35.594360591917216],
              [-81.995821776058733, 35.594596693618563],
              [-81.995870818320213, 35.594331614704949],
              [-81.996861995269015, 35.594449652569409],
              [-81.996812956176569, 35.594714731874511],
              [-81.997473743800768, 35.594793419870804],
              [-81.997424706533735, 35.595058500298968],
              [-81.997755102969549, 35.595097842602051],
              [-81.997607993450188, 35.595893082216243],
              [-81.997938392272701, 35.595932424039304],
              [-81.997889355802641, 35.596197503672286],
              [-81.998550158761887, 35.596276185730304],
              [-81.998599192016101, 35.596011105863596],
              [-81.998929592903579, 35.596050444963737],
              [-81.998978624747537, 35.595785364026753],
              [-81.999309024932543, 35.59582470299884],
              [-81.999358055399398, 35.595559621892548],
              [-82.000018853482075, 35.595638296006278],
              [-82.000067882618637, 35.595373214573051],
              [-82.00039828051618, 35.595412549714688],
              [-82.000349253539113, 35.595677631251135],
              [-82.001340454530762, 35.595795631621314],
              [-82.00114435489057, 35.596855956268577],
              [-82.001474760455707, 35.596895288440841],
              [-82.001278660982038, 35.597955612060417],
              [-82.001609071093569, 35.597994943855809],
              [-82.001560046480236, 35.598260024794058],
              [-82.001890457968713, 35.598299355821197],
              [-82.00184143409129, 35.598564436851014],
              [-82.0034935037976, 35.598761078204362],
              [-82.003444484882138, 35.599026159847369],
              [-82.004766151077348, 35.599183457085118],
              [-82.004815164663583, 35.598918374947623],
              [-82.006136831727432, 35.599075656384329],
              [-82.00618584190363, 35.598810574560055],
              [-82.006516257845931, 35.598849892105385],
              [-82.006467249862837, 35.599114974933748],
              [-82.00679766721565, 35.599154292611814],
              [-82.006748659935198, 35.599419374630621],
              [-82.007079079768076, 35.599458691513121],
              [-82.007030073257155, 35.599723774524513],
              [-82.007360494433613, 35.599763089737777],
              [-82.007311487555626, 35.600028172868001],
              [-82.007641910109115, 35.600067487312892],
              [-82.00759290507041, 35.600332570507327],
              [-82.007923329001017, 35.600371884184007],
              [-82.007874324698335, 35.600636967469924],
              [-82.008204750005973, 35.600676280378295],
              [-82.008155746439542, 35.600941363755616],
              [-82.008486173124112, 35.600980675895727],
              [-82.008241156804644, 35.602306091021639],
              [-82.008571589092739, 35.602345402914914],
              [-82.008522585683437, 35.602610485418595],
              [-82.008853020485276, 35.6026497974172],
              [-82.008804017812167, 35.602914880012257],
              [-82.009134452854525, 35.602954190368841],
              [-82.009085450917638, 35.603219273055394],
              [-82.010076763377, 35.603337199970376],
              [-82.010027765392891, 35.603602282981747],
              [-82.010688643724635, 35.603680897001574],
              [-82.01063964753348, 35.603945980234698],
              [-82.011300529226858, 35.604024590019328],
              [-82.011251534828688, 35.60428967347417],
              [-82.011912421020199, 35.604368279897336],
              [-82.011863427311852, 35.604633363601259],
              [-82.012193871944987, 35.604672665595075],
              [-82.012144880076193, 35.604937749363003],
              [-82.01247532608636, 35.604977050588339],
              [-82.012426334953901, 35.605242134447643],
              [-82.013417678076976, 35.605360033122402],
              [-82.013466667142438, 35.605094948844787],
              [-82.014788458197813, 35.60525213307298],
              [-82.014837441612713, 35.60498704826248],
              [-82.015167889128975, 35.605026342167619],
              [-82.015265852884298, 35.604496172169064],
              [-82.015596298607377, 35.604535464914839],
              [-82.015694257864851, 35.60400529452729],
              [-82.016355146011477, 35.604083875900358],
              [-82.016404123597866, 35.603818790373829],
              [-82.01673456711174, 35.60385808003246],
              [-82.016783542184356, 35.603592993463195],
              [-82.017113984961682, 35.60363228209274],
              [-82.018766203585258, 35.603828709957632],
              [-82.018717234819519, 35.604093796407696],
              [-82.019047680575085, 35.604133079775146],
              [-82.018949744196419, 35.604663252819016],
              [-82.01928019235217, 35.604702534647288],
              [-82.019231224772824, 35.604967622141977],
              [-82.019561674305606, 35.605006903201719],
              [-82.019463741335883, 35.605537076505577],
              [-82.019794193302445, 35.605576356927109],
              [-82.01969626006219, 35.606106530362943],
              [-82.021018082962087, 35.60626364582307],
              [-82.021067044411836, 35.605998558631057],
              [-82.022058411485773, 35.606116384954525],
              [-82.022107370548099, 35.605851297305321],
              [-82.023098737299534, 35.60596911424814],
              [-82.023049781407707, 35.606234202288078],
              [-82.02338023905638, 35.606273472925878],
              [-82.02333128500446, 35.606538561029566],
              [-82.023661744030136, 35.6065778308988],
              [-82.02327010627036, 35.608698533619915],
              [-82.023600574071196, 35.608737803632181],
              [-82.023453707481522, 35.609533065780099],
              [-82.023784179876642, 35.609572335256772],
              [-82.023735224050142, 35.609837423310445],
              [-82.024396171914759, 35.609915959827696],
              [-82.024347217848359, 35.610181047201898],
              [-82.025008169107394, 35.610259580384223],
              [-82.024812361439743, 35.611319931350607],
              [-82.024481882525521, 35.611280664660548],
              [-82.024335019941134, 35.612075926415578],
              [-82.02466550312954, 35.612115193468725],
              [-82.024616549719184, 35.612380280763276],
              [-82.02494703428475, 35.612419547047864],
              [-82.024849128617561, 35.612949721780062],
              [-82.028484508638797, 35.613381591608473],
              [-82.028435566918802, 35.613646679447022],
              [-82.028766059600386, 35.613685935308119],
              [-82.028717119754376, 35.61395102411123],
              [-82.02937810705501, 35.614029532523467],
              [-82.029329169003091, 35.614294621547806],
              [-82.029659663622041, 35.614333874099017],
              [-82.029610726307155, 35.614598963214398],
              [-82.029941223406638, 35.614638214969368],
              [-82.029892286828769, 35.614903304175755],
              [-82.030553284135934, 35.614981806150205],
              [-82.030504349318548, 35.615246894676787],
              [-82.032817858427634, 35.615521622803477],
              [-82.032866784741742, 35.615256533393413],
              [-82.033527788879155, 35.615335018258797],
              [-82.033576712758972, 35.615069928549325],
              [-82.033907213698797, 35.615109169517261],
              [-82.033956137271133, 35.614844078710036],
              [-82.03461713799652, 35.614922557688779],
              [-82.034666058064985, 35.614657467510767],
              [-82.035327059059213, 35.614735942606103],
              [-82.035375976659793, 35.614470851227686],
              [-82.036036975716172, 35.614549322494561],
              [-82.036085890882433, 35.614284230816814],
              [-82.036416389833406, 35.614323464971804],
              [-82.036367475724361, 35.614588556779651],
              [-82.036697976052395, 35.614627790165819],
              [-82.036600148954832, 35.615157973023372],
              [-82.036269646512494, 35.615118739377031],
              [-82.03587831576445, 35.617239468228227],
              [-82.036208826664264, 35.617278702915421],
              [-82.036110993168066, 35.617808884989905],
              [-82.036441507605673, 35.617848119010759],
              [-82.036294756979387, 35.618643390894981],
              [-82.036625274908673, 35.618682624407143],
              [-82.03657635881838, 35.618947715077631],
              [-82.036527442408143, 35.619212805708997],
              [-82.035866402674145, 35.619134338166269],
              [-82.035817483829405, 35.61939942849822],
              [-82.035486962264841, 35.619360192825347],
              [-82.035438042042855, 35.619625282987975],
              [-82.035107520844448, 35.619586046258448],
              [-82.035058598141688, 35.619851136279301],
              [-82.03472807620598, 35.619811898520695],
              [-82.034679153195725, 35.62007698744366],
              [-82.033357062217007, 35.619920026954013],
              [-82.033308134691381, 35.620185116218167],
              [-82.032977611137724, 35.620145873731978],
              [-82.033026540824039, 35.619880784570327],
              [-82.031704457315783, 35.61972380517237],
              [-82.031655523400417, 35.619988893813414],
              [-82.030994482020787, 35.619910398896963],
              [-82.030896607863554, 35.620440574667448],
              [-82.030566085539363, 35.620401325600426],
              [-82.030517147491963, 35.620666413733368],
              [-82.03018662329346, 35.620627162763768],
              [-82.030137683868688, 35.620892250727294],
              [-82.030468207987312, 35.620931500953581],
              [-82.030419269299699, 35.621196589007987],
              [-82.030749795899155, 35.621235838437912],
              [-82.030700856845286, 35.621500926610693],
              [-82.031031384822128, 35.621540175271683],
              [-82.030982447608878, 35.621805263507802],
              [-82.031312975859763, 35.621844511427547],
              [-82.031264039349935, 35.622109598853534],
              [-82.03159457008158, 35.622148845976859],
              [-82.031496697146693, 35.622679021899735],
              [-82.031827230313155, 35.622718268384254],
              [-82.031729359316003, 35.623248444382618],
              [-82.032059894917154, 35.623287690228452],
              [-82.032010959978848, 35.62355277784831],
              [-82.033994186685462, 35.623788235725229],
              [-82.033945258874809, 35.624053324058956],
              [-82.034275798831203, 35.624092563871699],
              [-82.034177942157541, 35.624622740736442],
              [-82.034508485652239, 35.62466197988288],
              [-82.034410629813181, 35.62519215685046],
              [-82.034741175742738, 35.625231395357993],
              [-82.034692248400546, 35.625496483912862],
              [-82.035022795707576, 35.625535721651481],
              [-82.034973869102771, 35.6258008102971],
              [-82.035304417787273, 35.625840047266777],
              [-82.035157640294628, 35.626635312429059],
              [-82.036149297785514, 35.626753019114446],
              [-82.036100374096591, 35.627018107221978],
              [-82.03643092829104, 35.627057341115709],
              [-82.036382006476842, 35.627322430187313],
              [-82.036712562048805, 35.62736166331203],
              [-82.036663640938301, 35.627626751573366],
              [-82.036994197887779, 35.627665983928992],
              [-82.036896355751935, 35.628196161522155],
              [-82.037226915136443, 35.628235393238917],
              [-82.037177995180883, 35.628500481642199],
              [-82.037508557046536, 35.628539712562251],
              [-82.037459636724861, 35.62880480108376],
              [-82.037790199968057, 35.628844031234884],
              [-82.037741281487428, 35.629109119819461],
              [-82.037410717220581, 35.629069890439254],
              [-82.037361797362493, 35.629334978854338],
              [-82.037031232324324, 35.629295747543942],
              [-82.036933388463154, 35.629825924924177],
              [-82.036602822733485, 35.629786692426968],
              [-82.036553898730432, 35.630051781392083],
              [-82.036223332263262, 35.630012547865675],
              [-82.036027629822499, 35.631072900080767],
              [-82.036358201589721, 35.631112133198918],
              [-82.036260351099102, 35.631642308865516],
              [-82.036590925335432, 35.631681542245708],
              [-82.036444150923529, 35.632476805825831],
              [-82.036113572410869, 35.63243757208302],
              [-82.036015718719568, 35.632967747354655],
              [-82.035685139515252, 35.632928512424904],
              [-82.035636210580051, 35.633193599885132],
              [-82.03530562953469, 35.633154363953686],
              [-82.035256700325377, 35.633419451216739],
              [-82.034926118542373, 35.633380214256057],
              [-82.034877186817965, 35.633645300476012],
              [-82.034546605434898, 35.633606063359544],
              [-82.034497672332748, 35.633871149409877],
              [-82.03416708910855, 35.633831911291608],
              [-82.034118154628615, 35.634096997172442],
              [-82.033787571736795, 35.63405775709645],
              [-82.033689698631264, 35.634587929407537],
              [-82.033359113944272, 35.634548688172146],
              [-82.033310175318746, 35.634813774602563],
              [-82.032979589894254, 35.634774532337872],
              [-82.032881710637284, 35.635304703891322],
              [-82.032551122313933, 35.635265460494814],
              [-82.032502181682474, 35.635530545617698],
              [-82.032171592621708, 35.63549130119187],
              [-82.032024763459589, 35.63628655688894],
              [-82.031694171545965, 35.636247311173577],
              [-82.031596282066999, 35.636777480570231],
              [-82.031265688358204, 35.636738233695453],
              [-82.03121674152888, 35.637003318218014],
              [-82.030886147082512, 35.636964070313915],
              [-82.03078825145144, 35.637494238952399],
              [-82.030457654106343, 35.637454989916371],
              [-82.030359755078919, 35.637985158136075],
              [-82.030029155938493, 35.637945907940605],
              [-82.029980204334876, 35.638210991874615],
              [-82.029649605527069, 35.638171739721322],
              [-82.02960065254544, 35.638436823485563],
              [-82.029270051930197, 35.638397571231373],
              [-82.029221097570584, 35.638662654825822],
              [-82.029172142890687, 35.638927738380609],
              [-82.031155761871659, 35.639163240033838],
              [-82.031204710205074, 35.638898155698286],
              [-82.031865916610585, 35.63897664880453],
              [-82.031914862508259, 35.638711564169093],
              [-82.032576068079237, 35.638790053418219],
              [-82.032625011541185, 35.638524968482912],
              [-82.033616818540253, 35.638642694334813],
              [-82.033567879355175, 35.638907779632873],
              [-82.033898484133417, 35.638947020197129],
              [-82.033849545686024, 35.639212105585607],
              [-82.034180150704714, 35.639251344507315],
              [-82.034082274964959, 35.639781515425504],
              [-82.034412883523075, 35.63982075368051],
              [-82.03426606954811, 35.640616009277061],
              [-82.034596682737273, 35.640655247896582],
              [-82.034498806855822, 35.641185417163896],
              [-82.034829422480826, 35.641224655144313],
              [-82.034682610016347, 35.642019910024878],
              [-82.035013229135075, 35.642059147496255],
              [-82.03496429131738, 35.642324232225171],
              [-82.035294911780497, 35.642363468026375],
              [-82.035245975837881, 35.642628553719057],
              [-82.035576597679039, 35.64266778875097],
              [-82.035674467195662, 35.642137618867778],
              [-82.036005086137408, 35.642176852767932],
              [-82.036054019357607, 35.64191176763655],
              [-82.036384638699232, 35.641951001380754],
              [-82.03643356940394, 35.641685915206367],
              [-82.036764188007737, 35.641725147921221],
              [-82.036813118438161, 35.641460061549353],
              [-82.037143735200516, 35.641499293262413],
              [-82.037241593019047, 35.640969121040634],
              [-82.037572207952124, 35.641008350693362],
              [-82.037621134236545, 35.640743264870871],
              [-82.037951749535509, 35.640782493466723],
              [-82.038000674441989, 35.640517407474441],
              [-82.038331289003196, 35.640556635041015],
              [-82.038429135706522, 35.640026461776515],
              [-82.038759748472131, 35.640065688183661],
              [-82.0388086703027, 35.63980060181234],
              [-82.039139281226838, 35.639839827217692],
              [-82.039237122882014, 35.639309653167501],
              [-82.039567732010681, 35.639348877413582],
              [-82.039665569132495, 35.638818702071241],
              [-82.039996176465493, 35.638857925158035],
              [-82.040045093522423, 35.638592838198328],
              [-82.040375700117551, 35.638632060255809],
              [-82.040424615762831, 35.638366972225498],
              [-82.040755220516601, 35.638406193281284],
              [-82.04085304873135, 35.637876016841709],
              [-82.041183652793194, 35.637915236710597],
              [-82.041232565362762, 35.637650148301375],
              [-82.04156316868692, 35.637689367140986],
              [-82.041612078775032, 35.637424278589727],
              [-82.042273284301473, 35.637502714212189],
              [-82.042322191953943, 35.637237625361301],
              [-82.042983395507221, 35.637316056253425],
              [-82.043032301827893, 35.637050967075282],
              [-82.044024106935893, 35.637168606265291],
              [-82.043975203788577, 35.637433695833479],
              [-82.044305807556768, 35.637472907219156],
              [-82.04425690625088, 35.637737996850106],
              [-82.044587511396898, 35.637777207466527],
              [-82.044636411644888, 35.637512117705583],
              [-82.045297619643463, 35.637590535107392],
              [-82.045395414948473, 35.637060354946776],
              [-82.045726017880725, 35.637099562475477],
              [-82.045872705231716, 35.636304290646756],
              [-82.046203304206941, 35.636343496913909],
              [-82.046252198270452, 35.636078405494565],
              [-82.047243996149106, 35.636196018455429],
              [-82.047292885615747, 35.635930926634245],
              [-82.047954083317293, 35.636009330527919],
              [-82.048002971452263, 35.635744238379587],
              [-82.048994766644242, 35.635861836198742],
              [-82.049043650216191, 35.635596744549574],
              [-82.049704846851014, 35.635675138030123],
              [-82.04965596539455, 35.635940229939195],
              [-82.049986565818273, 35.63597942589719],
              [-82.049888802992001, 35.636509610785218],
              [-82.050219405817046, 35.636548805202921],
              [-82.050170525550826, 35.63681389815418],
              [-82.050501129753656, 35.636853091802493],
              [-82.050452249121818, 35.637118184871831],
              [-82.050782854702192, 35.637157377750945],
              [-82.050733975878018, 35.637422469981992],
              [-82.05106458287004, 35.637461662992706],
              [-82.051015704784035, 35.637726755314127],
              [-82.051346313119652, 35.63776594665449],
              [-82.051297435805566, 35.638031039967238],
              [-82.051628045518925, 35.638070230538354],
              [-82.051481415833663, 35.638865507898188],
              [-82.051812029074355, 35.638904698860792],
              [-82.05176315256297, 35.639169791374059],
              [-82.052093767147312, 35.639208980666268],
              [-82.052044891374152, 35.639474073269902],
              [-82.052375508439852, 35.639513261765046],
              [-82.052326633404832, 35.639778354458898],
              [-82.052657251882152, 35.639817543085712],
              [-82.052608377551337, 35.640082634968913],
              [-82.052938996302757, 35.640121822854063],
              [-82.052890123847661, 35.640386915700809],
              [-82.053220743942816, 35.640426101915608],
              [-82.053074125591607, 35.641221379762165],
              [-82.053404750283974, 35.641260565439666],
              [-82.053355877562126, 35.641525658415382],
              [-82.053025252915489, 35.641486472580233],
              [-82.052976378781963, 35.641751564485432],
              [-82.052645752293415, 35.641712377648759],
              [-82.052596876816068, 35.641977470285319],
              [-82.052266249589252, 35.641938282419396],
              [-82.052217372700198, 35.64220337398541],
              [-82.051886744735256, 35.642164185090287],
              [-82.051691229816967, 35.643224552239722],
              [-82.051360597905926, 35.643185361024571],
              [-82.051262836411951, 35.643715544101276],
              [-82.051593470405038, 35.643754734675213],
              [-82.051397947777247, 35.644815099970756],
              [-82.051728586322369, 35.644854290165128],
              [-82.051679705915348, 35.645119381294379],
              [-82.052010346942012, 35.645158570691549],
              [-82.051863706976434, 35.64595384423054],
              [-82.051533062775562, 35.645914654443615],
              [-82.051435299083096, 35.646444836344266],
              [-82.051104653085915, 35.646405645398048],
              [-82.051055768564154, 35.646670735285554],
              [-82.050725121828734, 35.646631543310043],
              [-82.050578465445383, 35.647426814090643],
              [-82.050909116492832, 35.647466007329207],
              [-82.050860230691725, 35.647731097057587],
              [-82.051852186652141, 35.64784867002011],
              [-82.05180330484319, 35.648113760971796],
              [-82.053456577879885, 35.648309697032438],
              [-82.053309946372281, 35.649104970696051],
              [-82.052979287509288, 35.649065784904025],
              [-82.052832649914677, 35.649861056918539],
              [-82.053163311952304, 35.649900243100227],
              [-82.052967794903239, 35.650960605447658],
              [-82.052637128632909, 35.650921418746428],
              [-82.05258824750436, 35.651186508878524],
              [-82.051596247403808, 35.651068942087335],
              [-82.051547361676896, 35.651334031817527],
              [-82.051216694569604, 35.651294841258682],
              [-82.051167808568437, 35.651559930791358],
              [-82.050837139618963, 35.651520739230847],
              [-82.050641587078516, 35.65258109647074],
              [-82.050972259157078, 35.652620288578632],
              [-82.050825594257546, 35.653415555585205],
              [-82.050494917900366, 35.653376363115321],
              [-82.050006000524064, 35.656027247814365],
              [-82.04967531279992, 35.655988053173473],
              [-82.049626418889687, 35.656253140826557],
              [-82.049295730427161, 35.656213945156153],
              [-82.049197938426218, 35.656744120110197],
              [-82.048867248166999, 35.656704923280458],
              [-82.04881835007626, 35.656970010581396],
              [-82.048487660182587, 35.656930812694505],
              [-82.048292060386217, 35.657991160978376],
              [-82.047961365441282, 35.657951960798904],
              [-82.047863561506276, 35.658482134440874],
              [-82.047532864798512, 35.658442934002956],
              [-82.0474839601887, 35.658708020661642],
              [-82.047153263812646, 35.658668818265717],
              [-82.047104357824409, 35.658933904754335],
              [-82.046773660743895, 35.658894702229858],
              [-82.046724753343256, 35.659159787647589],
              [-82.046394054420446, 35.659120584121347],
              [-82.046345146779302, 35.659385670242308],
              [-82.046014447084275, 35.659346464785621],
              [-82.045965536960708, 35.659611550764211],
              [-82.045634836527356, 35.659572344278054],
              [-82.045585926129291, 35.659837430058943],
              [-82.045255224957614, 35.659798222543323],
              [-82.045157398876384, 35.660328393780247],
              [-82.04449599325973, 35.660249975530547],
              [-82.044447077622181, 35.660515060828935],
              [-82.043454968456601, 35.660397427218946],
              [-82.043406049323721, 35.660662512087306],
              [-82.043075344735158, 35.660623298682253],
              [-82.043124266064268, 35.66035821481718],
              [-82.042462862172329, 35.660279785513204],
              [-82.042511784194105, 35.660014701895612],
              [-82.04218108378673, 35.659975486024301],
              [-82.042230006512909, 35.659710401595639],
              [-82.041899307517923, 35.659671185855728],
              [-82.04194823208627, 35.659406101489367],
              [-82.041617534435886, 35.659366884079056],
              [-82.041715383658811, 35.658836716414633],
              [-82.04138468844539, 35.658797498364727],
              [-82.041433613066005, 35.658532414165741],
              [-82.041102920368871, 35.658493196219659],
              [-82.041151845727669, 35.658228112110621],
              [-82.040821154375209, 35.658188892494096],
              [-82.04087008047216, 35.657923808474969],
              [-82.039216631629984, 35.657727698506235],
              [-82.039167700207642, 35.657992780974347],
              [-82.038837009672378, 35.65795355598847],
              [-82.03888594322315, 35.657688472721809],
              [-82.038224567437766, 35.657610020256449],
              [-82.038273502818711, 35.657344938110761],
              [-82.037942815326915, 35.657305709785497],
              [-82.037991751445915, 35.65704062772987],
              [-82.037330381781132, 35.656962169486782],
              [-82.037379319662662, 35.656697086750142],
              [-82.036717953429246, 35.65661862607022],
              [-82.036669013431194, 35.656883708546786],
              [-82.036338329736608, 35.656844476727571],
              [-82.036289388360046, 35.657109559034062],
              [-82.03562801978137, 35.657031091536204],
              [-82.035579074863989, 35.65729617357011],
              [-82.034587021240199, 35.657178465637678],
              [-82.034538072827814, 35.657443547241286],
              [-82.034207387846294, 35.657404308734158],
              [-82.034158438055428, 35.657669390167783],
              [-82.033827752369731, 35.657630151532068],
              [-82.033778801166434, 35.65789523189455],
              [-82.033448114742882, 35.657855992229294],
              [-82.03339916216099, 35.658121072421636],
              [-82.033068474999482, 35.658081831726847],
              [-82.033019521072646, 35.658346912649975],
              [-82.032688833139488, 35.658307670024691],
              [-82.0326398778341, 35.65857275077768],
              [-82.032309188059074, 35.658533507150366],
              [-82.032260231375034, 35.658798587733187],
              [-82.031929541966036, 35.658759343048779],
              [-82.031880583903359, 35.659024423461418],
              [-82.030557823063859, 35.658867436164741],
              [-82.03060678649743, 35.658602357145881],
              [-82.029614722967239, 35.658484607830594],
              [-82.029565756358977, 35.65874968645916],
              [-82.028904380904947, 35.658671182440045],
              [-82.028855411859823, 35.658936260768265],
              [-82.028194035537382, 35.658857751990112],
              [-82.028145064088875, 35.659122830918975],
              [-82.027814374246162, 35.659083575078128],
              [-82.027152997729971, 35.659005060642876],
              [-82.027104022786304, 35.659270139141277],
              [-82.026773332846659, 35.659230880471824],
              [-82.02672435649049, 35.659495957899018],
              [-82.026393666917144, 35.659456698172463],
              [-82.026344689182196, 35.659721775429418],
              [-82.026013997767123, 35.659682514700698],
              [-82.025965018686961, 35.65994759268834],
              [-82.025634326534146, 35.659908330930058],
              [-82.025585346041709, 35.660173407846464],
              [-82.025254654255036, 35.660134145031087],
              [-82.025156689087879, 35.660664298510667],
              [-82.024825994401297, 35.66062503456299],
              [-82.02477700972662, 35.66089011112615],
              [-82.02444631540628, 35.660850846121441],
              [-82.024397329352681, 35.661115922514213],
              [-82.024066633190628, 35.661076656507262],
              [-82.0239686591093, 35.661606808884706],
              [-82.023637961151223, 35.661567541718064],
              [-82.023588972019397, 35.661832617730006],
              [-82.023258273289969, 35.661793348632742],
              [-82.023160293051944, 35.662323500248469],
              [-82.022829592560129, 35.662284230892283],
              [-82.022731607785332, 35.662814381213416],
              [-82.022400905497477, 35.662775110697389],
              [-82.022351911587705, 35.663040186117897],
              [-82.022021207458124, 35.663000914599735],
              [-82.021972212135765, 35.663265988948893],
              [-82.021641508372525, 35.663226716373622],
              [-82.021592511704824, 35.663491791453424],
              [-82.021261807170262, 35.66345251694743],
              [-82.021212808019541, 35.663717591884279],
              [-82.020882102780831, 35.663678317249541],
              [-82.020833103321536, 35.663943391087606],
              [-82.02050239734524, 35.663904115423215],
              [-82.020404394277548, 35.664434263646648],
              [-82.020073685367578, 35.664394985948817],
              [-82.019975678900451, 35.664925133750913],
              [-82.019644969332035, 35.664885855766727],
              [-82.019497952378146, 35.665681076367974],
              [-82.019167239921828, 35.665641796192773],
              [-82.019069224876532, 35.666171942197892],
              [-82.018738510657755, 35.666132661763726],
              [-82.018640492212782, 35.666662807347279],
              [-82.018309775093897, 35.666623525780615],
              [-82.018211753249176, 35.667153670942575],
              [-82.018162741845615, 35.667418743463102],
              [-82.01849346213973, 35.667458025420345],
              [-82.018444451473925, 35.667723098030756],
              [-82.018775173147219, 35.667762379218559],
              [-82.018677152970227, 35.668292524578739],
              [-82.019007877114717, 35.668331806028469],
              [-82.018909857738052, 35.668861950586667],
              [-82.01924058539079, 35.668901230468755],
              [-82.019191576296606, 35.669166303268028],
              [-82.019522304224253, 35.669205582408139],
              [-82.019473295867812, 35.669470655297246],
              [-82.019804026278962, 35.669509933640541],
              [-82.019706010720697, 35.670040079558014],
              [-82.020036743569506, 35.670079357262075],
              [-82.01993872881188, 35.670609502377452],
              [-82.020269464098419, 35.670648779442189],
              [-82.020171451278813, 35.671178924628947],
              [-82.01984071387524, 35.671139647303846],
              [-82.019546657988059, 35.672730079393638],
              [-82.019877402847072, 35.67276935747244],
              [-82.019730373765753, 35.673564573812605],
              [-82.020061121017122, 35.673603851409702],
              [-82.020012111729201, 35.673868923272067],
              [-82.020342861464073, 35.67390820007229],
              [-82.020293852913923, 35.674173272024341],
              [-82.020624604028256, 35.674212548055102],
              [-82.020575596216034, 35.674477620096795],
              [-82.020906348709644, 35.674516895358039],
              [-82.020857341635306, 35.674781967489508],
              [-82.021188095508293, 35.674821241981249],
              [-82.021139089171839, 35.675086314202424],
              [-82.021469844424203, 35.675125587924683],
              [-82.021420838825648, 35.675390660235507],
              [-82.0224131096831, 35.675508476394704],
              [-82.022364108043945, 35.675773549028051],
              [-82.023025625669888, 35.6758520888962],
              [-82.02297662468952, 35.67611716087584],
              [-82.023307386146428, 35.676156429563086],
              [-82.02325838593768, 35.676421502533323],
              [-82.023589148773993, 35.676460770450959],
              [-82.023540149269579, 35.676725842609969],
              [-82.024201676917571, 35.676804376033957],
              [-82.024152680347711, 35.677069449286122],
              [-82.024483445159319, 35.677108714792496],
              [-82.024434449293736, 35.677373787233321],
              [-82.024103682319307, 35.677334521624303],
              [-82.02405468510797, 35.677599594795453],
              [-82.023723918499627, 35.677560328129211],
              [-82.023674918771079, 35.677825400256125],
              [-82.023344151424737, 35.677786132559987],
              [-82.023295150350393, 35.678051205417333],
              [-82.022964382266039, 35.678011936691391],
              [-82.022915379778595, 35.678277008476933],
              [-82.022584610956244, 35.678237738721229],
              [-82.02238859470188, 35.679298025811214],
              [-82.022057821965305, 35.6792587546352],
              [-82.021910802377008, 35.680053968481175],
              [-82.021580025680663, 35.68001469604247],
              [-82.021482010004647, 35.680544838115878],
              [-82.021151231511425, 35.680505564517077],
              [-82.02110222158133, 35.68077063537644],
              [-82.020771442350309, 35.680731360747778],
              [-82.020722431040625, 35.680996431436405],
              [-82.019730090957793, 35.680878600888008],
              [-82.019681076150931, 35.68114367114562],
              [-82.019350296085506, 35.68110439265773],
              [-82.019301278794757, 35.681369462771805],
              [-82.018970497991376, 35.681330183254097],
              [-82.018725404676559, 35.682655530680506],
              [-82.018394618899919, 35.682616249612153],
              [-82.018296576982763, 35.683146387863161],
              [-82.017965788271553, 35.683107104761135],
              [-82.01791676634177, 35.683372174132245],
              [-82.017585976926185, 35.683332890901241],
              [-82.017536952478807, 35.683597959227981],
              [-82.017206163429577, 35.683558674939654],
              [-82.017157137602467, 35.683823743095537],
              [-82.01616476696158, 35.683705883595387],
              [-82.016115737636937, 35.683970951320006],
              [-82.015454155982795, 35.683892374179592],
              [-82.01540512421964, 35.684157441603148],
              [-82.015074332781595, 35.684118150652161],
              [-82.015025299672203, 35.684383218805777],
              [-82.014363715674847, 35.684304634845063],
              [-82.014314680093278, 35.68456970179664],
              [-82.013983887500444, 35.684530407886037],
              [-82.013934850572582, 35.684795475567554],
              [-82.013604057241892, 35.684756180626998],
              [-82.013555018900661, 35.685021247236655],
              [-82.01289343111803, 35.684942655296695],
              [-82.012844389233877, 35.685207721632636],
              [-82.012513594748427, 35.685168423732271],
              [-82.0124645514843, 35.685433489897271],
              [-82.011802961392476, 35.685354892037772],
              [-82.01175391679395, 35.685619957874238],
              [-82.011423121153982, 35.685580657014214],
              [-82.011374074071242, 35.685845722707072],
              [-82.010712481670254, 35.685767118928069],
              [-82.010663432148746, 35.686032184319693],
              [-82.010001837776485, 35.685953575807531],
              [-82.009952785816182, 35.686218640897977],
              [-82.009621988604891, 35.686179335148324],
              [-82.009327661471303, 35.687769722306108],
              [-82.00965846614011, 35.687809028809895],
              [-82.00951130141506, 35.688604222228967],
              [-82.009842109581697, 35.688643528223693],
              [-82.00979305543585, 35.688908592135618],
              [-82.00946224621029, 35.688869286010657],
              [-82.009364133766482, 35.689399414379849],
              [-82.009033322744202, 35.689360107094579],
              [-82.008935206898016, 35.689890235039996],
              [-82.008604394078972, 35.689850926594502],
              [-82.008555335150675, 35.690115989911483],
              [-82.008224521593831, 35.690076680435865],
              [-82.008175460214531, 35.690341744510114],
              [-82.007844645919874, 35.690302434004494],
              [-82.007746521117326, 35.690832559940723],
              [-82.008077337529883, 35.690871870706943],
              [-82.00802827515362, 35.691136933757655],
              [-82.007697457682085, 35.691097622861136],
              [-82.007648393925578, 35.69136268574082],
              [-82.006986757828102, 35.691284060987904],
              [-82.006937691632572, 35.691549123566134],
              [-82.006606873006646, 35.691509809709665],
              [-82.00655780543093, 35.691774872116753],
              [-82.005896165920333, 35.69169624147117],
              [-82.005847095905452, 35.691961303576761],
              [-82.005185455561787, 35.691882669071347],
              [-82.005136383107697, 35.692147730875568],
              [-82.004474741897596, 35.69206909160922],
              [-82.004523817607463, 35.691804030939252],
              [-82.003862179800322, 35.691725388334241],
              [-82.00391125727343, 35.69146032698292],
              [-82.003249622902686, 35.691381681940172],
              [-82.003298702172444, 35.691116620808629],
              [-82.002306257825907, 35.690998645944838],
              [-82.002355338846797, 35.690733585190586],
              [-82.001032089533979, 35.690576272668295],
              [-82.001081175573617, 35.690311212367163],
              [-82.000088744328011, 35.690193219389457],
              [-82.000039655111664, 35.690458279299563],
              [-81.999708844257967, 35.690418945776479],
              [-81.999757934533221, 35.690153885996629],
              [-81.99744227838103, 35.68987852967409],
              [-81.997393179589096, 35.690143588568752],
              [-81.997062371306384, 35.690104247847295],
              [-81.997013272238775, 35.690369306543531],
              [-81.99635165455318, 35.690290623041562],
              [-81.996302551908471, 35.690555680562397],
              [-81.995971743609999, 35.690516337754687],
              [-81.995922639618428, 35.690781396005299],
              [-81.995591829444749, 35.690742051293618],
              [-81.995542724072848, 35.691007109372968],
              [-81.995211913161754, 35.690967763631107],
              [-81.995162806409525, 35.691232821539195],
              [-81.994831994760972, 35.691193474767211],
              [-81.994782886595061, 35.691458531603054],
              [-81.994452074242432, 35.691419184701843],
              [-81.994402964696164, 35.69168424136641],
              [-81.99407215157278, 35.691644892534086],
              [-81.994023040679465, 35.691909949928409],
              [-81.993692226818666, 35.691870600065819],
              [-81.993643113440541, 35.692135657316015],
              [-81.991658230640752, 35.691899537561994],
              [-81.991756469815456, 35.691369425486414],
              [-81.991425659264038, 35.691330069455823],
              [-81.99147477941132, 35.691065013036614],
              [-81.991143970240401, 35.691025656236583],
              [-81.991193091124984, 35.690760599906881],
              [-81.990862283334465, 35.69072124233746],
              [-81.99091140495635, 35.690456186097272],
              [-81.990580599650627, 35.69041682773134],
              [-81.990629720905403, 35.690151771607823],
              [-81.989968113342982, 35.690073051536601],
              [-81.990017237498265, 35.689807995605861],
              [-81.989355632268598, 35.68972927312447],
              [-81.989404758219948, 35.689464217413693],
              [-81.987750757624198, 35.689267393373171],
              [-81.987419958710561, 35.689228026400961],
              [-81.984442781658259, 35.68887367778418],
              [-81.984491924274067, 35.688608623962082],
              [-81.983499541257544, 35.688490491943568],
              [-81.983450395465397, 35.688755545374256],
              [-81.982458012134714, 35.688637403965878],
              [-81.982507162207355, 35.688372350899606],
              [-81.982176369465947, 35.688332968761166],
              [-81.982225519171209, 35.688067915811573],
              [-81.981894727810257, 35.688028532903971],
              [-81.981993029477437, 35.687498427116019],
              [-81.981662241659976, 35.687459043542567],
              [-81.98176054415768, 35.686928937852258],
              [-81.981429757674974, 35.686889553667243],
              [-81.981478910018907, 35.686624499976816],
              [-81.978832643697814, 35.686309394114829],
              [-81.978783482884523, 35.686574446761128],
              [-81.978121918208018, 35.686495660341762],
              [-81.978072754922493, 35.686760711785212],
              [-81.977741970920505, 35.686721317572953],
              [-81.97769280625451, 35.68698636884502],
              [-81.977362022620028, 35.686946973575481],
              [-81.977263689100937, 35.687477075763027],
              [-81.976932903670942, 35.687437679332739],
              [-81.976883734817804, 35.687702730248184],
              [-81.976552948617851, 35.687663331886675],
              [-81.976454608933082, 35.688193433306907],
              [-81.976123819866373, 35.688154034712625],
              [-81.976074649034729, 35.688419085217312],
              [-81.975743859231187, 35.688379685592764],
              [-81.97559633938711, 35.689174835622879],
              [-81.975265546729361, 35.689135434706991],
              [-81.975118020812289, 35.689930583977272],
              [-81.974787225267193, 35.68989118086904],
              [-81.974688870214706, 35.69042128053546],
              [-81.974358072874054, 35.690381876266464],
              [-81.974308893806224, 35.690646925907622],
              [-81.972985703461049, 35.690489300212924],
              [-81.972936519836381, 35.690754349290977],
              [-81.972274924494442, 35.690675531234646],
              [-81.972127364165843, 35.691470675664647],
              [-81.972028988646429, 35.692000772055785],
              [-81.972359791433519, 35.692040181736225],
              [-81.972310604818361, 35.692305230437888],
              [-81.972641408986192, 35.692344639349173],
              [-81.972592223107824, 35.692609688140415],
              [-81.972923028656481, 35.692649096282452],
              [-81.97287384348185, 35.692914144262318],
              [-81.97320465154867, 35.692953552509231],
              [-81.973155466006332, 35.693218600605633],
              [-81.973486275454007, 35.693258008083362],
              [-81.973338722280758, 35.694053152464278],
              [-81.973007909656772, 35.694013744594862],
              [-81.972909537883453, 35.694543840364574],
              [-81.973240352625083, 35.694583248495093],
              [-81.973141980577068, 35.695113344388794],
              [-81.973472797725279, 35.695152750979716],
              [-81.97332524093413, 35.695947893989604],
              [-81.972994420609353, 35.69590848700701],
              [-81.972896045308431, 35.696438581589156],
              [-81.972565223187928, 35.696399173445634],
              [-81.9725160334268, 35.69666422010755],
              [-81.972185210569421, 35.696624810933713],
              [-81.97213601942741, 35.696889857423997],
              [-81.97180519583317, 35.696850447219724],
              [-81.971756003310261, 35.697115493538362],
              [-81.971425178979146, 35.697076082303703],
              [-81.971375985075341, 35.697341128450631],
              [-81.971045158902854, 35.697301716212593],
              [-81.970995964722619, 35.697566762160875],
              [-81.970665137780244, 35.697527347991489],
              [-81.970615941147628, 35.697792394696037],
              [-81.970285113468393, 35.697752979496244],
              [-81.970186717086122, 35.698283071619912],
              [-81.969855888715685, 35.69824365523219],
              [-81.969806688966003, 35.69850870065121],
              [-81.969475858787263, 35.698469284161028],
              [-81.969328253324591, 35.699264419806532],
              [-81.969659086713051, 35.699303837589476],
              [-81.969560684315041, 35.699833928057281],
              [-81.969891520143563, 35.699873345201553],
              [-81.969842319520325, 35.700138390504328],
              [-81.970173156729899, 35.70017780687936],
              [-81.970074756635071, 35.700707897622536],
              [-81.970405597356134, 35.700747312430991],
              [-81.970307196986354, 35.701277403297532],
              [-81.970638040180532, 35.701316818368227],
              [-81.970539641711881, 35.70184690840339],
              [-81.970208796432772, 35.701807493972467],
              [-81.970110394557736, 35.702337583581603],
              [-81.968787009195722, 35.702179913084912],
              [-81.968688597593214, 35.702710002385473],
              [-81.969019444715443, 35.702749420965034],
              [-81.968921033942337, 35.703279510361881],
              [-81.969251884609335, 35.70331892827587],
              [-81.969202678693989, 35.703583973069996],
              [-81.968871826935114, 35.703544554124413],
              [-81.968773413091654, 35.704074642399796],
              [-81.967780853426419, 35.703956380281312],
              [-81.967731643380105, 35.70422142350138],
              [-81.967400789732636, 35.704182000564515],
              [-81.967351577233543, 35.704447044540636],
              [-81.967020722849199, 35.704407620573349],
              [-81.966971508935842, 35.704672663476629],
              [-81.966640653814636, 35.704633238478792],
              [-81.966443790736008, 35.70569341005767],
              [-81.966112931700764, 35.705653983637511],
              [-81.966063714058237, 35.705919026073225],
              [-81.965732853181507, 35.70587959864941],
              [-81.965634414811646, 35.706409683135554],
              [-81.9653035532437, 35.706370254523812],
              [-81.965205110361978, 35.706900338610396],
              [-81.964874246998093, 35.706860908837442],
              [-81.96482502456702, 35.707125950674673],
              [-81.964494159361578, 35.707086519898169],
              [-81.964444934444586, 35.707351561590279],
              [-81.963452338689294, 35.707233263442426],
              [-81.963403111377261, 35.707498304674466],
              [-81.963072245342815, 35.707458870036987],
              [-81.963023015511851, 35.70772391022301],
              [-81.962692149845154, 35.707684474528094],
              [-81.962642918665765, 35.707949515443062],
              [-81.962312051157525, 35.707910078744568],
              [-81.962213586785325, 35.708440159261315],
              [-81.961882717481288, 35.708400721401532],
              [-81.961784248596786, 35.708930801518413],
              [-81.961453377496809, 35.708891362497411],
              [-81.961404141528405, 35.709156402813704],
              [-81.961073269658698, 35.709116961861092],
              [-81.961024032308785, 35.709382002005327],
              [-81.960362287451147, 35.709303118040125],
              [-81.960411526952271, 35.709038079058239],
              [-81.960080656049669, 35.708998635405962],
              [-81.960031415522366, 35.709263675158219],
              [-81.958377060648459, 35.709066445447441],
              [-81.958327813365543, 35.709331483630805],
              [-81.95799694231205, 35.70929203521866],
              [-81.957947693647597, 35.709557073229867],
              [-81.957285950356734, 35.709478172543427],
              [-81.957236700389203, 35.709743211126032],
              [-81.956905828151847, 35.709703758851745],
              [-81.956856575665157, 35.709968796388146],
              [-81.956194828967838, 35.709889889806256],
              [-81.956145574073432, 35.710154927940806],
              [-81.955814700685153, 35.710115472705382],
              [-81.95576544437624, 35.710380509766843],
              [-81.955103696481899, 35.710301597235784],
              [-81.95505443773223, 35.710566633994425],
              [-81.954723563193284, 35.710527175797793],
              [-81.954477257134656, 35.711852359270509],
              [-81.954146377622521, 35.711812899520353],
              [-81.954047850589063, 35.712342972002816],
              [-81.953716968176337, 35.712303511118222],
              [-81.953667703668941, 35.712568547153104],
              [-81.953336820519695, 35.712529085237762],
              [-81.953090484501473, 35.71385426331576],
              [-81.953421372946664, 35.713893725884695],
              [-81.953372105399183, 35.714158761697412],
              [-81.953702995193268, 35.714198222596117],
              [-81.953653729487272, 35.714463258471227],
              [-81.953984620695934, 35.714502719501638],
              [-81.953935354588879, 35.714767754591797],
              [-81.954597141197269, 35.7148466741872],
              [-81.95454787688621, 35.715111709497236],
              [-81.954878771181114, 35.715151168089129],
              [-81.954681718328033, 35.716211311158226],
              [-81.955012617149634, 35.716250768471888],
              [-81.954864827008208, 35.717045875285727],
              [-81.955195730467892, 35.71708533296561],
              [-81.95509720382266, 35.717615403636607],
              [-81.955428109723442, 35.71765486067796],
              [-81.955329583906618, 35.718184931443986],
              [-81.954998675887097, 35.718145474141345],
              [-81.954949411987869, 35.718410509317856],
              [-81.954618503264484, 35.718371051885384],
              [-81.954470704243718, 35.719166156826837],
              [-81.954801616145133, 35.719205614651429],
              [-81.954703084338774, 35.719735684656747],
              [-81.955033999819221, 35.719775142716813],
              [-81.954984733906088, 35.720040176900163],
              [-81.955315650768583, 35.720079634190888],
              [-81.955266385625251, 35.720344669364188],
              [-81.954935468808287, 35.72030521191602],
              [-81.954836935435083, 35.720835281876447],
              [-81.953844179491497, 35.72071690427515],
              [-81.953794909109916, 35.720981937899808],
              [-81.953463989333258, 35.720942477387048],
              [-81.953414717569672, 35.721207510839342],
              [-81.95308379816106, 35.721168049269032],
              [-81.952985251580174, 35.721698116688223],
              [-81.952654329237674, 35.721658653082279],
              [-81.952161565208868, 35.724308984593804],
              [-81.95183063259465, 35.724269518780723],
              [-81.95173207403198, 35.724799584675942],
              [-81.951401138516616, 35.724760117728032],
              [-81.95135185767532, 35.725025150031819],
              [-81.951020921423165, 35.724985682053124],
              [-81.950971639199707, 35.725250714184412],
              [-81.950309766649468, 35.725171775238529],
              [-81.950359050991906, 35.724906743368663],
              [-81.949035315139852, 35.724748856072729],
              [-81.949084603397452, 35.72448382468405],
              [-81.948753671568781, 35.724444350508627],
              [-81.948704381146925, 35.724709381793332],
              [-81.948373448581464, 35.724669906587081],
              [-81.948274864650472, 35.725199968769722],
              [-81.947943930288943, 35.725160492401969],
              [-81.947894636779765, 35.725425523299883],
              [-81.946901830210876, 35.725307087556111],
              [-81.946951128036147, 35.725042057924668],
              [-81.946620193828096, 35.725002577082314],
              [-81.946669491285121, 35.724737547566548],
              [-81.945676697156429, 35.724619101752829],
              [-81.94562739544898, 35.724884131803961],
              [-81.944965532180831, 35.72480516286582],
              [-81.944916228031659, 35.725070192613607],
              [-81.943592500059736, 35.724912244341041],
              [-81.943543191349946, 35.725177273523919],
              [-81.943212259389114, 35.725137784293409],
              [-81.943162949264249, 35.725402812402756],
              [-81.942832016566868, 35.725363322141277],
              [-81.942733393262415, 35.725893378873977],
              [-81.942402457664144, 35.725853887477541],
              [-81.942353144484457, 35.72611891610088],
              [-81.942022209221776, 35.72607942274594],
              [-81.941972894659713, 35.726344451196681],
              [-81.941641957555618, 35.72630495683751],
              [-81.941592641611166, 35.726569985115617],
              [-81.941261703770536, 35.726530489725533],
              [-81.941163068793983, 35.727060545894666],
              [-81.940832129157357, 35.727021049342945],
              [-81.940782810125157, 35.727286077233963],
              [-81.940451869752025, 35.727246579651265],
              [-81.940402548199728, 35.727511606495412],
              [-81.940071608227726, 35.727472108756018],
              [-81.940022285293068, 35.727737135427532],
              [-81.939691343479566, 35.727697636683935],
              [-81.939592695627155, 35.72822768961295],
              [-81.939261752017771, 35.728188189707623],
              [-81.93921242599508, 35.728453215991841],
              [-81.938881481616463, 35.728413714154605],
              [-81.938782827620727, 35.728943767210176],
              [-81.938451881446127, 35.728904264211174],
              [-81.938303893177675, 35.72969934267735],
              [-81.937972944147575, 35.729659838385807],
              [-81.937923613008238, 35.729924864026245],
              [-81.937592663241659, 35.729885358703697],
              [-81.937197991778177, 35.732005560424042],
              [-81.937148656671852, 35.7322705855563],
              [-81.936817696587298, 35.73223107818275],
              [-81.936768360098299, 35.732496103142218],
              [-81.936437399277352, 35.732456594737663],
              [-81.936388061405594, 35.732721619524348],
              [-81.935726138613816, 35.732642599753142],
              [-81.935676798267096, 35.732907623335201],
              [-81.935014874681286, 35.73282860060263],
              [-81.934965530787352, 35.733093623907578],
              [-81.934634568403141, 35.733054110609764],
              [-81.93468391335675, 35.732789087435584],
              [-81.93336007239499, 35.732631025739337],
              [-81.933310723203192, 35.732896048390032],
              [-81.931324964545823, 35.732658928069213],
              [-81.931374321167425, 35.732393905276204],
              [-81.931043363397777, 35.732354382207525],
              [-81.931092720755714, 35.732089359503455],
              [-81.930430808305147, 35.732010310927549],
              [-81.930480167458924, 35.731745288443307],
              [-81.930149212777991, 35.731705762936009],
              [-81.930198572668061, 35.731440740540691],
              [-81.929867620474781, 35.731401214237586],
              [-81.929916979996165, 35.731136191957802],
              [-81.928593177556195, 35.73097807739348],
              [-81.928543812659228, 35.73124309827525],
              [-81.927220011120951, 35.731084967884435],
              [-81.927269380255836, 35.730819947526371],
              [-81.92660748406513, 35.730740876277849],
              [-81.926656854995684, 35.730475856139677],
              [-81.926325908444781, 35.730436319296196],
              [-81.926375281183866, 35.730171298319497],
              [-81.926044336048307, 35.730131761607815],
              [-81.926093708418577, 35.729866740746751],
              [-81.925431821171131, 35.729787663083066],
              [-81.925481195369557, 35.729522643342861],
              [-81.924819312639201, 35.72944356231416],
              [-81.924868688600583, 35.729178541892921],
              [-81.923544931290138, 35.729020370459381],
              [-81.923594311165814, 35.728755350520068],
              [-81.922932436553225, 35.728676259214339],
              [-81.92298181932928, 35.728411239468436],
              [-81.922650884119889, 35.728371692583202],
              [-81.922700267631853, 35.728106672926437],
              [-81.922038399197007, 35.728027576744118],
              [-81.922087784504427, 35.727762557307386],
              [-81.921756852383893, 35.727723007983855],
              [-81.921806238427308, 35.727457988636218],
              [-81.91948972894923, 35.727181119113695],
              [-81.919440334385584, 35.727446137570887],
              [-81.919109404819821, 35.727406580145981],
              [-81.919060010010767, 35.727671599304756],
              [-81.918398148658483, 35.727592482420377],
              [-81.918348751374793, 35.727857500374242],
              [-81.918017820693223, 35.727817940888173],
              [-81.91796842092181, 35.728082958695609],
              [-81.917637489471886, 35.728043397277489],
              [-81.917538686840018, 35.728573432504568],
              [-81.917207753627267, 35.728533870825437],
              [-81.917158350766798, 35.728798888245066],
              [-81.916827416785836, 35.728759324633771],
              [-81.916778012542409, 35.729024341880454],
              [-81.916447076753116, 35.728984778165561],
              [-81.91639767112683, 35.729249795239326],
              [-81.916066735674164, 35.729210229565886],
              [-81.916017328665092, 35.729475246466606],
              [-81.914693583707361, 35.729316976103121],
              [-81.91464417213723, 35.729581992437737],
              [-81.913651363069491, 35.729463279384149],
              [-81.913601947997719, 35.729728295283664],
              [-81.913271010850465, 35.7296887220431],
              [-81.91322159439575, 35.729953737769605],
              [-81.9109050393904, 35.729676700689431],
              [-81.910954465470368, 35.729411685827536],
              [-81.910292597782345, 35.729332524019156],
              [-81.91034202458485, 35.729067510304802],
              [-81.906039935987224, 35.728552871454582],
              [-81.906089377309399, 35.728287858475284],
              [-81.905427524674408, 35.728208670163987],
              [-81.905476967791358, 35.727943657404914],
              [-81.904815119642464, 35.727864464828471],
              [-81.904864564586518, 35.727599453190756],
              [-81.904533641488314, 35.727559855246348],
              [-81.904583087135492, 35.72729484279683],
              [-81.903590325235768, 35.727176045706223],
              [-81.903540875305964, 35.727441057788646],
              [-81.902879033160858, 35.727361854674363],
              [-81.902829580788818, 35.727626866452553],
              [-81.902498659679154, 35.727587262950649],
              [-81.902449205924086, 35.727852274555659],
              [-81.902118283006189, 35.727812670949938],
              [-81.902068828940784, 35.728077681454579],
              [-81.901737905287575, 35.728038076817626],
              [-81.901540078300215, 35.729098119772935],
              [-81.901209150733862, 35.729058513711315],
              [-81.901110232626152, 35.729588534687878],
              [-81.900448374195719, 35.729509318439014],
              [-81.900497835513832, 35.729244307783588],
              [-81.8998359804974, 35.729165088196922],
              [-81.899885443610231, 35.728900077761843],
              [-81.899223593112779, 35.728820854811282],
              [-81.899273058020199, 35.728555844596499],
              [-81.898942133747923, 35.7285162314651],
              [-81.898991599422942, 35.728251222240566],
              [-81.898660677638645, 35.728211608313977],
              [-81.898710144016803, 35.727946598277676],
              [-81.898379223647893, 35.727906984483248],
              [-81.89842869076125, 35.727641974536198],
              [-81.897766851944226, 35.727562742734172],
              [-81.897717381607535, 35.727827752445137],
              [-81.896724623186429, 35.727708897585337],
              [-81.896675149348212, 35.727973906860797],
              [-81.896344229831385, 35.727934286634273],
              [-81.89629475457788, 35.728199294835491],
              [-81.895632913292971, 35.728120051446389],
              [-81.895583435629263, 35.728385060244115],
              [-81.895252513861081, 35.728345437081522],
              [-81.895301992583995, 35.728080428414962],
              [-81.894640153242932, 35.72800117962592],
              [-81.894689633792453, 35.727736172080711],
              [-81.894027797833473, 35.727656919053246],
              [-81.893978315197828, 35.727921927236892],
              [-81.893316478448739, 35.727842671247913],
              [-81.893266993338543, 35.728107678226216],
              [-81.892936074943336, 35.728068048737398],
              [-81.892886587344989, 35.728333055568662],
              [-81.892555668214726, 35.728293425048626],
              [-81.892456691064723, 35.728823439197441],
              [-81.89212576903509, 35.728783807541191],
              [-81.892076278346252, 35.729048813983951],
              [-81.89042166994102, 35.728850640593215],
              [-81.890372174737152, 35.72911564631152],
              [-81.890041252501433, 35.729076008091845],
              [-81.889843259820665, 35.730136031025445],
              [-81.889744261551641, 35.730666042690487],
              [-81.889413333284949, 35.730626402783301],
              [-81.889363832588927, 35.730891407971001],
              [-81.889032903619238, 35.730851767933444],
              [-81.888933898031397, 35.731381777946353],
              [-81.889264829119554, 35.73142141824647],
              [-81.888868804454219, 35.73354145671167],
              [-81.889199745445623, 35.733581097135705],
              [-81.889150241138267, 35.733846102017928],
              [-81.889100737611855, 35.734111106831818],
              [-81.889762624836408, 35.734190386405707],
              [-81.889614117498539, 35.734985399606536],
              [-81.889515110628224, 35.735515408505371],
              [-81.889184161573269, 35.73547576896258],
              [-81.889134657128906, 35.735740772753971],
              [-81.888472757840702, 35.735661489804677],
              [-81.888423249848501, 35.735926493317528],
              [-81.888092299647326, 35.735886850812072],
              [-81.887993281676628, 35.736416857422491],
              [-81.887662329681035, 35.736377213754338],
              [-81.887563307189708, 35.736907219959683],
              [-81.887232353399611, 35.736867575128976],
              [-81.887133327492663, 35.737397580902972],
              [-81.886802371875916, 35.737357934008571],
              [-81.886703342585292, 35.737887940252172],
              [-81.886372384068963, 35.737848292221415],
              [-81.886223835579912, 35.738643299498619],
              [-81.885892874209659, 35.738603650173815],
              [-81.885793837406808, 35.739133654568988],
              [-81.885131911401487, 35.739054352694041],
              [-81.885032869063835, 35.739584356395071],
              [-81.884701904428709, 35.739544703844885],
              [-81.884602857569888, 35.740074707140444],
              [-81.884271891140301, 35.740035053427484],
              [-81.884222366717594, 35.740300054867461],
              [-81.884553334174399, 35.740339707810783],
              [-81.884305710218484, 35.741664713283477],
              [-81.883974736327076, 35.741625058808872],
              [-81.883925209958292, 35.741890059995107],
              [-81.883594235331742, 35.741850404489064],
              [-81.883544707547131, 35.742115404600796],
              [-81.883213732185453, 35.7420757480633],
              [-81.883164201943885, 35.742340748928484],
              [-81.882502251179844, 35.742261432864311],
              [-81.882452719567979, 35.742526432497456],
              [-81.881790768014682, 35.742447113471115],
              [-81.881889836224886, 35.741917113760771],
              [-81.881558863590243, 35.741877452696471],
              [-81.881608398836875, 35.741612453346548],
              [-81.881277427586085, 35.741572791513462],
              [-81.881326963535585, 35.741307791351595],
              [-81.880003084909106, 35.741149135569628],
              [-81.880052624804108, 35.740884136791763],
              [-81.879390690108792, 35.740804803749761],
              [-81.879341148095037, 35.74106980226496],
              [-81.879010180174603, 35.741030134262417],
              [-81.878960635703834, 35.741295133531068],
              [-81.877305795639387, 35.741096778459216],
              [-81.877256245515099, 35.741361776127803],
              [-81.876594309622632, 35.741282426995035],
              [-81.876643861865745, 35.741017429589135],
              [-81.875981929390178, 35.740938077118692],
              [-81.875932375060216, 35.741203075162765],
              [-81.875601408233734, 35.741163396995482],
              [-81.875551852519862, 35.741428394865913],
              [-81.875220884958509, 35.741388715667185],
              [-81.872573156279756, 35.741071253277809],
              [-81.872622721560049, 35.740806257490981],
              [-81.872291759084263, 35.740766570296707],
              [-81.872341325099441, 35.740501574598959],
              [-81.872010364007622, 35.740461886636034],
              [-81.872059930757558, 35.740196891027381],
              [-81.871728971049677, 35.740157202295855],
              [-81.871778538534357, 35.73989220677624],
              [-81.871447580210429, 35.739852517276063],
              [-81.87154671739772, 35.739322525445758],
              [-81.87121576151705, 35.739282835308366],
              [-81.871265331251692, 35.739017839898686],
              [-81.870934375649739, 35.738978149018656],
              [-81.870983946119097, 35.7387131536981],
              [-81.870652993006331, 35.738673462023385],
              [-81.870702564178316, 35.738408465890998],
              [-81.870371612449375, 35.738368773447668],
              [-81.870421184388078, 35.73810377830543],
              [-81.870090234043047, 35.738064085093448],
              [-81.870139806684364, 35.737799089139422],
              [-81.869808857723228, 35.737759395158925],
              [-81.869858431099289, 35.737494399294036],
              [-81.869527483553981, 35.737454705445842],
              [-81.869577057664685, 35.737189709670162],
              [-81.869246111471313, 35.737150014152384],
              [-81.869295686316548, 35.736885018465898],
              [-81.868964741507043, 35.736845322179533],
              [-81.869014317086979, 35.736580326582256],
              [-81.868683374766434, 35.73654062950127],
              [-81.868732951080958, 35.736275633993209],
              [-81.868071068427142, 35.736196237420195],
              [-81.868021488889099, 35.736461232691433],
              [-81.86769054754248, 35.736421532910406],
              [-81.867640966620655, 35.736686528007915],
              [-81.867310023434413, 35.736646827221442],
              [-81.867260442233672, 35.736911822119296],
              [-81.865274785757464, 35.736673596758436],
              [-81.865324374418577, 35.736408602623477],
              [-81.864993433505177, 35.73636889553687],
              [-81.865043022900679, 35.736103901491092],
              [-81.864712084476352, 35.736064193610041],
              [-81.864761673501263, 35.735799199679576],
              [-81.864430736428844, 35.735759490128963],
              [-81.864529917833082, 35.735229502352247],
              [-81.864198983235639, 35.735189793065629],
              [-81.864248574509858, 35.734924799245412],
              [-81.863917641296311, 35.734885089190364],
              [-81.863967233304962, 35.73462009545937],
              [-81.863636301443307, 35.73458038373483],
              [-81.863884261879392, 35.73325541420364],
              [-81.864215188445328, 35.733295125270764],
              [-81.864314368209307, 35.73276513635934],
              [-81.863983443793472, 35.73272542645627],
              [-81.864082624376891, 35.732195437639191],
              [-81.863751702371971, 35.732155726198165],
              [-81.86380129325164, 35.731890732308436],
              [-81.863139454576171, 35.731811307838399],
              [-81.863189047249264, 35.731546314169499],
              [-81.862527210885816, 35.731466886388773],
              [-81.862576805320316, 35.731201892039756],
              [-81.862245889253259, 35.731162177368439],
              [-81.862295484422077, 35.730897183108816],
              [-81.861964569706828, 35.730857466768114],
              [-81.862014165641909, 35.730592473498852],
              [-81.861352339335582, 35.73051303928176],
              [-81.861401938136979, 35.730248045306375],
              [-81.860409204274276, 35.730128887175404],
              [-81.860458804823054, 35.729863893578532],
              [-81.860127895233404, 35.729824172232874],
              [-81.860177497653225, 35.729559179600429],
              [-81.859846589447343, 35.729519457486482],
              [-81.859896192569423, 35.729254464042498],
              [-81.859565285747294, 35.729214741160106],
              [-81.859614889635523, 35.728949748706533],
              [-81.859283984197134, 35.728910025055775],
              [-81.859333588787692, 35.728645031790684],
              [-81.858671782108118, 35.728565582026718],
              [-81.858721388491787, 35.728300588982535],
              [-81.858390486145694, 35.728260862895269],
              [-81.858440093263511, 35.727995869940628],
              [-81.85810919343794, 35.727956143960014],
              [-81.858158801289875, 35.727691151094838],
              [-81.857827901711161, 35.727651423470846],
              [-81.857877510297229, 35.727386430695169],
              [-81.857215716442141, 35.727306972958893],
              [-81.857265326821206, 35.727041980404259],
              [-81.856603536383219, 35.726962519331543],
              [-81.856553923886352, 35.727227511623099],
              [-81.856223028063908, 35.727187778704405],
              [-81.856173413078366, 35.727452770848188],
              [-81.855842516553764, 35.727413037798996],
              [-81.855792901289419, 35.727678029743196],
              [-81.85546200403067, 35.727638295662658],
              [-81.855412386277607, 35.727903287459114],
              [-81.855081488284824, 35.727863552347046],
              [-81.854932631004729, 35.728658527063288],
              [-81.85460173012828, 35.728618789755572],
              [-81.854502487462668, 35.729148772443303],
              [-81.854171584825025, 35.729109034873559],
              [-81.854072338710154, 35.729639016228695],
              [-81.85374143427957, 35.729599277495865],
              [-81.853592559509408, 35.730394249268201],
              [-81.853261651090122, 35.730354508365622],
              [-81.853162398595217, 35.730884488747122],
              [-81.852831488414694, 35.730844747582431],
              [-81.852781860052431, 35.731109737140777],
              [-81.852120038548307, 35.731030251848544],
              [-81.852070407743241, 35.731295241101527],
              [-81.85173949641964, 35.731255496973894],
              [-81.851640230611451, 35.731785475116077],
              [-81.85130931749498, 35.73174572982532],
              [-81.851259683596922, 35.732010718688535],
              [-81.850928769746346, 35.73197097236627],
              [-81.850879133359314, 35.732235961081578],
              [-81.850217304483536, 35.732156464573151],
              [-81.850167665653501, 35.732421452983026],
              [-81.849836750659975, 35.732381703697783],
              [-81.849787110445931, 35.732646691933816],
              [-81.849456194718428, 35.732606941617099],
              [-81.849406554225453, 35.732871929653435],
              [-81.849075636627248, 35.732832177429977],
              [-81.849025993645142, 35.733097165318341],
              [-81.848695076449729, 35.733057412938543],
              [-81.848645432083558, 35.733322400653066],
              [-81.848314514122464, 35.733282646340626],
              [-81.848215222297014, 35.733812621379727],
              [-81.847884302542994, 35.733772865904172],
              [-81.847785006194144, 35.734302840536863],
              [-81.847454084678873, 35.734263084799011],
              [-81.847156183622459, 35.735853005039573],
              [-81.846825254941365, 35.735813246736775],
              [-81.846775601657995, 35.736078233485799],
              [-81.846444672242868, 35.736038474151485],
              [-81.846395018680369, 35.736303460700718],
              [-81.846064088531321, 35.736263700334781],
              [-81.845964777176093, 35.736793672167899],
              [-81.845633845234047, 35.736753910638633],
              [-81.845435214385418, 35.737813853270595],
              [-81.845104277427083, 35.737774090340579],
              [-81.845004957901864, 35.738304060238256],
              [-81.844674019182065, 35.738264297046001],
              [-81.844624358425179, 35.738529281786747],
              [-81.84429341893987, 35.738489516661772],
              [-81.844243755693483, 35.738754501254355],
              [-81.843912816579362, 35.738714735071994],
              [-81.843863151948725, 35.738979719490551],
              [-81.843532211027266, 35.738939953203243],
              [-81.843283877699875, 35.740264874002207],
              [-81.842952931776281, 35.740225105255568],
              [-81.842803924667891, 35.741020055930328],
              [-81.842472975892065, 35.740980285888682],
              [-81.842423304051721, 35.741245269556543],
              [-81.841761405357516, 35.741165726509927],
              [-81.841711732178638, 35.74143070984622],
              [-81.841380782260273, 35.741390936841185],
              [-81.841331106591852, 35.741655920029167],
              [-81.841000155939611, 35.741616145992495],
              [-81.840950479960156, 35.741881128079527],
              [-81.840619527468888, 35.741841353036939],
              [-81.840569850136674, 35.742106335850899],
              [-81.840238898016693, 35.742066559750818],
              [-81.84018921816309, 35.742331541515448],
              [-81.839858265309246, 35.742291764383594],
              [-81.839808584102869, 35.742556746875053],
              [-81.839477630515148, 35.742516968711485],
              [-81.839427947892531, 35.742781950127871],
              [-81.839096992465798, 35.742742170958373],
              [-81.839047308458646, 35.743007152200533],
              [-81.838716353403242, 35.742967371973563],
              [-81.838666668043231, 35.743232353942474],
              [-81.838335712222403, 35.743192571782799],
              [-81.838236337270999, 35.743722534454704],
              [-81.837905379688735, 35.743682752032598],
              [-81.837756312180389, 35.74447769486332],
              [-81.837425350609081, 35.744437910270705],
              [-81.837325969272101, 35.744967871965194],
              [-81.836995005907724, 35.744928086209093],
              [-81.836945312570847, 35.745193066436059],
              [-81.836614349609548, 35.745153280523439],
              [-81.836514960946332, 35.745683240560723],
              [-81.836183995086571, 35.745643453510347],
              [-81.836134298655011, 35.745908433346322],
              [-81.835803332061545, 35.745868645264238],
              [-81.835703937177101, 35.746398603618374],
              [-81.835372968790566, 35.746358814372705],
              [-81.835323269264137, 35.746623793817662],
              [-81.83499230011212, 35.746584002639231],
              [-81.834942599200957, 35.746848981909814],
              [-81.834280659786586, 35.746769397490347],
              [-81.834230956431398, 35.747034376454884],
              [-81.833899986168873, 35.746994583213784],
              [-81.833850281397361, 35.74725956110305],
              [-81.833519309295923, 35.74721976685592],
              [-81.833419896689279, 35.747749723144196],
              [-81.833088923900036, 35.747709927707717],
              [-81.833039214928107, 35.747974905231473],
              [-81.83237726817606, 35.747895310493881],
              [-81.832426978161322, 35.747630333259366],
              [-81.831765034798451, 35.747550734284083],
              [-81.831814746576697, 35.747285757270525],
              [-81.831152806634563, 35.747206154958491],
              [-81.831202521279465, 35.746941177239201],
              [-81.83087155288743, 35.746901375765859],
              [-81.83092126716079, 35.746636398161478],
              [-81.830590300122125, 35.746596595018886],
              [-81.830540584789617, 35.746861572491554],
              [-81.830209617017204, 35.746821768317119],
              [-81.830159899194641, 35.747086745641113],
              [-81.829828930688649, 35.747046940434842],
              [-81.829779211481267, 35.747311917584511],
              [-81.829117273327583, 35.747232304208296],
              [-81.829067551676019, 35.747497281051814],
              [-81.828736582028341, 35.747457472881862],
              [-81.82868685896041, 35.747722448649917],
              [-81.828355888579196, 35.747682639448108],
              [-81.828156987772005, 35.748742543394954],
              [-81.828487962390454, 35.748782353123872],
              [-81.828338787356884, 35.749577280562214],
              [-81.828669765478722, 35.749617089786511],
              [-81.828271957303741, 35.751736894917329],
              [-81.827940969602011, 35.751697084664428],
              [-81.82754313090976, 35.753816883331986],
              [-81.827212133953381, 35.753777071150189],
              [-81.827112668604641, 35.754307019457052],
              [-81.826781669855151, 35.754267206111507],
              [-81.826632465674052, 35.755062128319025],
              [-81.826301465177366, 35.755022313652368],
              [-81.826251728349959, 35.755287286912115],
              [-81.825920726014218, 35.75524747123918],
              [-81.825870987833198, 35.755512445225349],
              [-81.825539984763793, 35.755472628520366],
              [-81.825440505242568, 35.756002574298797],
              [-81.825109500380023, 35.755962756430065],
              [-81.825059759102757, 35.756227730024357],
              [-81.82472875350652, 35.756187911123639],
              [-81.824679010812659, 35.756452883642268],
              [-81.824348004482772, 35.756413063709594],
              [-81.824298260403651, 35.756678036053678],
              [-81.823967253340101, 35.756638215089026],
              [-81.823917507875635, 35.756903187258409],
              [-81.823586498973057, 35.756863365287501],
              [-81.823536752123431, 35.757128337282261],
              [-81.823205743592567, 35.757088514253624],
              [-81.823155994252204, 35.757353486099454],
              [-81.82282498498769, 35.75731366203879],
              [-81.822775235367402, 35.757578633684325],
              [-81.822113214560233, 35.757498981723678],
              [-81.822063462495166, 35.757763953062671],
              [-81.821732450983873, 35.757724126063543],
              [-81.821682697533561, 35.757989097227835],
              [-81.821351685288661, 35.757949269196708],
              [-81.821301929347641, 35.758214240212013],
              [-81.820970917474497, 35.758174411123242],
              [-81.820921160116612, 35.758439381062871],
              [-81.820590147509861, 35.758399550942087],
              [-81.820540388798037, 35.758664521607948],
              [-81.820209374352416, 35.758624690480758],
              [-81.820159614223783, 35.758889660070899],
              [-81.81982860014989, 35.758849827886166],
              [-81.819729076858849, 35.759379768476151],
              [-81.819398059855061, 35.759339934252047],
              [-81.819348296661218, 35.759604904350944],
              [-81.819017278955258, 35.759565069995809],
              [-81.818917748333874, 35.760095008925781],
              [-81.818586729908844, 35.760055172480115],
              [-81.81818858590907, 35.762174925428759],
              [-81.817857558259703, 35.762135087954618],
              [-81.817708245134142, 35.762929993768715],
              [-81.817377214600668, 35.762890154097775],
              [-81.817227894257044, 35.763685059155243],
              [-81.816896860870827, 35.763645218188358],
              [-81.81679731020489, 35.764175153814151],
              [-81.816466275056868, 35.764135312584372],
              [-81.8164164981753, 35.764400280200796],
              [-81.816085461156888, 35.764360437063495],
              [-81.816035682889577, 35.764625404505111],
              [-81.81570464516895, 35.764585561236601],
              [-81.815555305200746, 35.765380462006895],
              [-81.815224264627304, 35.76534061744254],
              [-81.815124700888461, 35.765870550510186],
              [-81.815455743612532, 35.765910396239306],
              [-81.815356179583191, 35.766440329424256],
              [-81.81502513477119, 35.766400484332259],
              [-81.814975351744877, 35.766665450265009],
              [-81.814644306199199, 35.766625604140941],
              [-81.814445164446013, 35.767685468766935],
              [-81.81411411495651, 35.767645620313999],
              [-81.81381538618524, 35.769235415169376],
              [-81.813484329558122, 35.769195565050381],
              [-81.813285165145587, 35.77025542602226],
              [-81.813616226011632, 35.770295276668932],
              [-81.813317476472619, 35.77188506626004],
              [-81.813648545097664, 35.771924915871267],
              [-81.813598753057491, 35.772189880792475],
              [-81.814260892362711, 35.77226957940529],
              [-81.814211102084641, 35.772534543646159],
              [-81.816197535229293, 35.772773616840375],
              [-81.816147751015379, 35.773038582730344],
              [-81.816809900852121, 35.773118266538738],
              [-81.816760119537463, 35.773383232623694],
              [-81.817753348796458, 35.773502751554467],
              [-81.817703570335269, 35.773767717991909],
              [-81.818365727640824, 35.773847392423306],
              [-81.818315950973314, 35.774112359081172],
              [-81.818647031175118, 35.774152195078152],
              [-81.818597256346933, 35.774417161799164],
              [-81.819259419879899, 35.774496832256787],
              [-81.819209645708511, 35.774761798322807],
              [-81.819540729008182, 35.774801631882497],
              [-81.819490956707682, 35.775066598912545],
              [-81.819822041393394, 35.775106431703662],
              [-81.819772268721067, 35.77537139884793],
              [-81.820103355898368, 35.775411230844917],
              [-81.820053583928498, 35.775676197176836],
              [-81.82038467252319, 35.775716029306253],
              [-81.82033490128731, 35.775980995726762],
              [-81.820997080406201, 35.776060655771637],
              [-81.82094731210114, 35.776325623287953],
              [-81.821278402656773, 35.776365452104464],
              [-81.821228635054212, 35.776630418808402],
              [-81.821559728101548, 35.776670246830726],
              [-81.82150996015875, 35.776935214549816],
              [-81.822172149351147, 35.777014868156854],
              [-81.822122384276582, 35.777279835169779],
              [-81.822453480421828, 35.777319660754479],
              [-81.822403716081155, 35.777584627855916],
              [-81.823065911469669, 35.777664276587807],
              [-81.823016147817441, 35.777929243935183],
              [-81.824340547851918, 35.77808853109542],
              [-81.824290789219347, 35.778353498901133],
              [-81.826277405900214, 35.778592401604513],
              [-81.826939613351783, 35.778672029253244],
              [-81.826989363504012, 35.778407060393285],
              [-81.827982674988263, 35.778526492890165],
              [-81.827932929121971, 35.778791462119749],
              [-81.829588460491053, 35.778990500123847],
              [-81.829638201057065, 35.778725530235519],
              [-81.829969306787149, 35.778765334472986],
              [-81.830019045967248, 35.778500364409631],
              [-81.830350152068831, 35.778540167589128],
              [-81.830399888757341, 35.778275197376495],
              [-81.830730994124607, 35.778314999523865],
              [-81.83078073053278, 35.778050029110538],
              [-81.831111834060323, 35.778089830251361],
              [-81.831161569082582, 35.777824859663056],
              [-81.831492671875779, 35.777864659771801],
              [-81.831542404375085, 35.777599688133243],
              [-81.831873507571103, 35.777639488085015],
              [-81.832022701071168, 35.776844573389965],
              [-81.83235380030726, 35.776884372071912],
              [-81.832502987695037, 35.776089456592835],
              [-81.833165180721195, 35.77616904866305],
              [-81.833115454416884, 35.776434021087816],
              [-81.833446552478549, 35.776473815904041],
              [-81.833347099875468, 35.7770037600121],
              [-81.834340403481178, 35.777123139822621],
              [-81.834290680439764, 35.777388112644573],
              [-81.834952886960977, 35.777467694937521],
              [-81.834903165682505, 35.777732667078737],
              [-81.835896480534586, 35.777852034172774],
              [-81.835946198632797, 35.777587061636531],
              [-81.836277302806408, 35.777626848743871],
              [-81.836327019487229, 35.777361875131696],
              [-81.837651436303418, 35.777521014004044],
              [-81.837601724936931, 35.777785987216134],
              [-81.837932831844498, 35.777825769795463],
              [-81.837883120106824, 35.778090743121773],
              [-81.838545335913537, 35.778170305868059],
              [-81.838495627076099, 35.778435279388695],
              [-81.838826737080893, 35.778475059529974],
              [-81.838777028977944, 35.778740033139037],
              [-81.839439250979098, 35.778819591009231],
              [-81.83938954467088, 35.779084564838371],
              [-81.839720657772901, 35.779124342541493],
              [-81.839670952199114, 35.779389316459067],
              [-81.840664296607727, 35.779508643659483],
              [-81.840614593888915, 35.779773617928718],
              [-81.841276827232534, 35.779853164856156],
              [-81.841227126308482, 35.78011813934549],
              [-81.842220483068147, 35.780237453802528],
              [-81.842121087710055, 35.780767403415268],
              [-81.842452209011796, 35.780807172771169],
              [-81.842303116192255, 35.781602098174439],
              [-81.842634242105973, 35.781641866998896],
              [-81.842534847732921, 35.78217181670535],
              [-81.842865976092838, 35.782211584892472],
              [-81.842816278925284, 35.782476559825305],
              [-81.843147408702819, 35.782516328144453],
              [-81.843048016617203, 35.783046278117546],
              [-81.843379148809106, 35.783086044898504],
              [-81.843329452785355, 35.783351019964535],
              [-81.843660586363143, 35.783390785976486],
              [-81.84361089217991, 35.783655761105059],
              [-81.843942027143484, 35.783695526348076],
              [-81.843892333694996, 35.783960501564927],
              [-81.844223470044568, 35.784000266038994],
              [-81.844074389788972, 35.784795190949019],
              [-81.844405529645101, 35.784834954917329],
              [-81.844355837014874, 35.785099930224],
              [-81.844686978256917, 35.785139693423346],
              [-81.844637286361476, 35.785404668818259],
              [-81.846293006074163, 35.785603471887072],
              [-81.84634269266769, 35.785338495834303],
              [-81.847004980489672, 35.78541801049974],
              [-81.84695529601683, 35.785682986815765],
              [-81.847286442029358, 35.785722742916242],
              [-81.847236758291459, 35.785987719320381],
              [-81.847567905658082, 35.786027473750941],
              [-81.847518222655069, 35.786292450243295],
              [-81.847849371439423, 35.786332204805795],
              [-81.847650641455857, 35.78739210903985],
              [-81.847981794775691, 35.787431862327068],
              [-81.847932112265852, 35.787696838865564],
              [-81.848594422034665, 35.787776343902159],
              [-81.848544742426071, 35.788041320634512],
              [-81.848875898874965, 35.788081072383989],
              [-81.848826218863806, 35.788346048329409],
              [-81.849157377804545, 35.788385799283915],
              [-81.849107698528272, 35.788650775317457],
              [-81.848776539632837, 35.788611024205515],
              [-81.848677179088668, 35.789140975853385],
              [-81.848346017323735, 35.789101224504513],
              [-81.84824665335654, 35.789631175715513],
              [-81.847915489764404, 35.789591422301939],
              [-81.847816121268281, 35.790121373101854],
              [-81.848147286981416, 35.790161126778486],
              [-81.848097603305519, 35.790426102244872],
              [-81.848428770404709, 35.790465855152412],
              [-81.848379087463883, 35.790730830706764],
              [-81.848710255917382, 35.790770581944329],
              [-81.848660574817274, 35.791035557560939],
              [-81.849322914885335, 35.791115059399118],
              [-81.849273234475064, 35.791380035261206],
              [-81.84993557793463, 35.791459532858859],
              [-81.84988590042569, 35.79172450891469],
              [-81.850217073688412, 35.791764256043585],
              [-81.850167395808697, 35.792029232213224],
              [-81.850498571595082, 35.792068979448096],
              [-81.850448894450466, 35.792333955705857],
              [-81.851111247983297, 35.792413446861509],
              [-81.851011898034074, 35.792943398845814],
              [-81.851343077978711, 35.792983143773213],
              [-81.851293403007617, 35.793248119394086],
              [-81.851624584338381, 35.793287863552308],
              [-81.851525237784514, 35.79381781577581],
              [-81.851856420424284, 35.793857558421237],
              [-81.851707401350183, 35.794652487274959],
              [-81.852038588603236, 35.79469222938841],
              [-81.851988914892971, 35.794957206043627],
              [-81.85232010353225, 35.794996947387901],
              [-81.852220759436477, 35.795526899877878],
              [-81.852551950522511, 35.795566640584454],
              [-81.852452607246164, 35.796096593163256],
              [-81.85278380077915, 35.796136333232063],
              [-81.852684458322287, 35.796666285899555],
              [-81.853346851713326, 35.796745763835169],
              [-81.853297182134057, 35.797010740816965],
              [-81.853628379825608, 35.797050478578164],
              [-81.853578710949705, 35.797315454746915],
              [-81.855565914753299, 35.797553863984355],
              [-81.85622831938322, 35.797633327107505],
              [-81.856178658667872, 35.797898304284594],
              [-81.856509862515907, 35.797938034176006],
              [-81.856460203641845, 35.798203011414998],
              [-81.856791408875878, 35.798242740537056],
              [-81.856692091167261, 35.79877269517317],
              [-81.857023298848389, 35.798812423657459],
              [-81.856973640013749, 35.799077401054468],
              [-81.85730485018702, 35.799117128743525],
              [-81.857106218017833, 35.800177037468274],
              [-81.857437431653878, 35.800216764808312],
              [-81.857387774419763, 35.800481742224093],
              [-81.857718990548008, 35.800521468768792],
              [-81.857619676087594, 35.801051422857313],
              [-81.858282112457701, 35.801130873796119],
              [-81.858232457429978, 35.801395851475341],
              [-81.858894897223536, 35.801475299073786],
              [-81.858845243960303, 35.801740276071193],
              [-81.859838910932666, 35.801859441068174],
              [-81.859789260526739, 35.802124418416277],
              [-81.860120484562515, 35.802164138411669],
              [-81.860021184928371, 35.80269409414057],
              [-81.860352411411355, 35.802733813498065],
              [-81.860253112597491, 35.803263769314945],
              [-81.860584341495823, 35.803303487133491],
              [-81.860534693783819, 35.803568465532301],
              [-81.860865924068364, 35.803608182581456],
              [-81.860816275985954, 35.803873161093918],
              [-81.861147507656838, 35.803912877373641],
              [-81.861097861416013, 35.804177855947849],
              [-81.862091562701721, 35.804297000651026],
              [-81.862041918212398, 35.804561979601736],
              [-81.863035626715103, 35.804681116565391],
              [-81.862985986157653, 35.804946094939694],
              [-81.863648461883429, 35.805025515649533],
              [-81.863549184068333, 35.805555473693424],
              [-81.863880423972262, 35.805595182522396],
              [-81.863731506870963, 35.806390119229626],
              [-81.863400262646067, 35.80635040913149],
              [-81.863300981545862, 35.806880365836776],
              [-81.862969734449976, 35.806840655501915],
              [-81.862920092350905, 35.807105633657443],
              [-81.86258884559345, 35.807065921363403],
              [-81.86229097534391, 35.808655787791274],
              [-81.862622228467757, 35.808695500873817],
              [-81.862373999949185, 35.810020387955817],
              [-81.862705259810156, 35.810060100768389],
              [-81.862655613729856, 35.810325077654518],
              [-81.862605968462248, 35.810590055371655],
              [-81.862937230738908, 35.810629766645206],
              [-81.862887585069089, 35.810894743574906],
              [-81.863881377098721, 35.811013874185896],
              [-81.863831735393035, 35.811278851439901],
              [-81.864163001093559, 35.811318559372438],
              [-81.864113360155628, 35.811583537614915],
              [-81.864775894655097, 35.811662951039835],
              [-81.864726255514313, 35.811927929501209],
              [-81.865388794543534, 35.812007339558946],
              [-81.865339156061694, 35.812272317364233],
              [-81.866001698546683, 35.812351724981866],
              [-81.865952062968063, 35.812616702979966],
              [-81.86628333629686, 35.812656405105187],
              [-81.866233701454149, 35.812921383190748],
              [-81.866564976169499, 35.81296108454638],
              [-81.866515342094758, 35.813226063620334],
              [-81.866846617090445, 35.813265764232348],
              [-81.866796983719709, 35.813530742492802],
              [-81.86712826120808, 35.813570442309164],
              [-81.867078628573211, 35.813835420657057],
              [-81.867409907448234, 35.813875119703759],
              [-81.867211377900972, 35.814935033181492],
              [-81.867542662452507, 35.814974731826581],
              [-81.867443397849641, 35.81550468856441],
              [-81.86777468374305, 35.815544386597196],
              [-81.867526521849527, 35.816869278329158],
              [-81.867857814449138, 35.816908975190699],
              [-81.867808182155812, 35.817173953536518],
              [-81.868139476173965, 35.817213650529226],
              [-81.868089844616776, 35.817478628962334],
              [-81.86908373177539, 35.817597714027421],
              [-81.869034103077055, 35.817862692810557],
              [-81.870027996379434, 35.817981771061021],
              [-81.869978370507908, 35.818246749293166],
              [-81.87097226988999, 35.818365818927091],
              [-81.870922648015721, 35.818630798384149],
              [-81.87291046303632, 35.818868914084923],
              [-81.872860847173314, 35.819133893384823],
              [-81.873523457631734, 35.8192132580213],
              [-81.873473843598461, 35.819478238440652],
              [-81.8741364574485, 35.819557598834407],
              [-81.874086845212943, 35.819822579472373],
              [-81.874418154240388, 35.819862258435897],
              [-81.874368542741209, 35.820127239161039],
              [-81.87469985315532, 35.820166917354648],
              [-81.874650242392363, 35.820431898167115],
              [-81.87531286521282, 35.820511252139781],
              [-81.875263257353907, 35.820776233144628],
              [-81.875925883598185, 35.820855583775391],
              [-81.875876277537117, 35.821120564998665],
              [-81.876207592761645, 35.821160239080619],
              [-81.876157986298665, 35.821425219516243],
              [-81.876820619878202, 35.821504566140284],
              [-81.876671807120104, 35.822299507918025],
              [-81.877003127598442, 35.822339180722608],
              [-81.876903920327806, 35.822869141932529],
              [-81.877235243222287, 35.822908813197522],
              [-81.877136035667945, 35.823438774519587],
              [-81.877467362116988, 35.823478445119832],
              [-81.877318552084844, 35.824273387152871],
              [-81.877169739126245, 35.82506832878866],
              [-81.877501071163891, 35.825107999301359],
              [-81.877451467624496, 35.825372979880946],
              [-81.877782802155124, 35.825412649597546],
              [-81.877733199352221, 35.825677630264231],
              [-81.878395871512382, 35.82575696725641],
              [-81.878346270507549, 35.82602194814136],
              [-81.878677608137082, 35.82606161541694],
              [-81.878628007836667, 35.826326595487991],
              [-81.878959346853023, 35.826366261993641],
              [-81.878860147464337, 35.826896224067703],
              [-81.879522830719239, 35.82697555490023],
              [-81.879473232628484, 35.827240535232463],
              [-81.880467265347079, 35.827359525063756],
              [-81.880516860220482, 35.827094543436907],
              [-81.88084820331099, 35.827134204797723],
              [-81.880798609499209, 35.827399186555866],
              [-81.881792646086936, 35.827518164671545],
              [-81.881743055103115, 35.827783145878158],
              [-81.882074402723802, 35.827822803870482],
              [-81.88202481250886, 35.828087786064998],
              [-81.88235616148414, 35.828127442386339],
              [-81.882306573112089, 35.828392424641684],
              [-81.882969273087426, 35.828471735770187],
              [-81.882919686513844, 35.828736718243732],
              [-81.883251038587986, 35.828776372123691],
              [-81.88320145164478, 35.829041354710469],
              [-81.883532805105759, 35.829081007820392],
              [-81.883483220005616, 35.829345990467949],
              [-81.883814574853346, 35.829385642807907],
              [-81.883715405769422, 35.829915607331614],
              [-81.88404676309807, 35.829955259933612],
              [-81.88399717800786, 35.830220241836038],
              [-81.884328537797586, 35.830259892740841],
              [-81.884278954582641, 35.830524875604965],
              [-81.884610314652903, 35.830564525765872],
              [-81.884560732142688, 35.830829507815949],
              [-81.88522345652045, 35.830908806571109],
              [-81.885173875840934, 35.831173789740234],
              [-81.8858366036104, 35.831253084251706],
              [-81.885787024729666, 35.831518067638903],
              [-81.886118389610587, 35.831557713687012],
              [-81.886068812540898, 35.831822696233964],
              [-81.886731546540162, 35.831901986763285],
              [-81.886681970162769, 35.832166969554549],
              [-81.887013339248952, 35.832206613134929],
              [-81.886963763608435, 35.832471596012979],
              [-81.887295134081526, 35.832511238823137],
              [-81.887245559177998, 35.832776221788151],
              [-81.887576931070114, 35.832815864729191],
              [-81.887527358009763, 35.833080847754765],
              [-81.887858731256713, 35.833120489024687],
              [-81.887809157826993, 35.83338547216335],
              [-81.888140532460767, 35.8334251126631],
              [-81.88809096087428, 35.833690095862401],
              [-81.88842233689509, 35.833729735591966],
              [-81.888372764939263, 35.833994718904314],
              [-81.88870414345331, 35.834034357837496],
              [-81.888654572234344, 35.834299341236665],
              [-81.888985952135428, 35.834338979399639],
              [-81.888886811953, 35.834868946301071],
              [-81.889549575871513, 35.834948220473834],
              [-81.889599143448009, 35.834683236796138],
              [-81.891587437737272, 35.834921036869368],
              [-81.891537876532638, 35.835186021333833],
              [-81.891869260375898, 35.835225651673248],
              [-81.891819699876194, 35.835490635323453],
              [-81.892482472874264, 35.835569893508136],
              [-81.892432914205813, 35.835834878277154],
              [-81.892764302254321, 35.835874506148571],
              [-81.892714744290743, 35.836139490103392],
              [-81.893046133726173, 35.836179117204551],
              [-81.892996576531985, 35.836444102147155],
              [-81.893327968428537, 35.836483727550771],
              [-81.893278411971508, 35.836748712580118],
              [-81.893609804180855, 35.836788338140636],
              [-81.893560248428699, 35.837053322355693],
              [-81.893891642025011, 35.837092947146033],
              [-81.893842088148745, 35.837357932322547],
              [-81.894173483099678, 35.837397555441676],
              [-81.894123928854242, 35.837662540731102],
              [-81.894455326298683, 35.837702163053542],
              [-81.894405772790364, 35.837967148429762],
              [-81.894306665873302, 35.838497118121687],
              [-81.89397526631673, 35.838457494478796],
              [-81.893925710771612, 35.838722479590693],
              [-81.893594309371466, 35.838682854941503],
              [-81.89349519622418, 35.839212822941874],
              [-81.893163793056971, 35.839173198030082],
              [-81.893114234380576, 35.839438181845658],
              [-81.892782830443835, 35.839398555000514],
              [-81.8926837111308, 35.839928523110949],
              [-81.892352305394738, 35.839888895102241],
              [-81.89225318151891, 35.840418861898073],
              [-81.891921773983597, 35.840379232725674],
              [-81.891822645609295, 35.840909200008873],
              [-81.891491236274646, 35.840869569672876],
              [-81.891392105550366, 35.841399535588884],
              [-81.891060694416367, 35.841359904089266],
              [-81.890961559161383, 35.841889869591448],
              [-81.890630146227934, 35.841850236928231],
              [-81.89058057649747, 35.842115219494602],
              [-81.890249162826862, 35.842075585798916],
              [-81.89019959281579, 35.842340568163436],
              [-81.889868177301523, 35.842300933461416],
              [-81.889818605903145, 35.842565915650411],
              [-81.889487189651803, 35.842526279915816],
              [-81.889388042616844, 35.843056243023291],
              [-81.889056625672666, 35.843016606098885],
              [-81.889007050068273, 35.843281587918348],
              [-81.888675631280421, 35.8432419499876],
              [-81.88862605428875, 35.843506931631431],
              [-81.888294635870182, 35.843467292641847],
              [-81.888145899726027, 35.844262236011929],
              [-81.887814477339461, 35.844222595753763],
              [-81.887715316149354, 35.844752557215273],
              [-81.887383891931236, 35.844712914892447],
              [-81.887334309802398, 35.844977895875708],
              [-81.887002884879337, 35.844938253421326],
              [-81.886903717489758, 35.845468214090943],
              [-81.886572290767234, 35.845428570472855],
              [-81.886473118813839, 35.845958529827222],
              [-81.88614169029205, 35.845918885045386],
              [-81.886042516052427, 35.846448844833894],
              [-81.885711084592401, 35.846409198013575],
              [-81.885611905821065, 35.846939157387681],
              [-81.885280473700405, 35.84689951027827],
              [-81.88518129147171, 35.847429468310793],
              [-81.884849857551643, 35.847389820037655],
              [-81.884750670791064, 35.847919777655605],
              [-81.884419235071618, 35.847880128218662],
              [-81.884369640141301, 35.848145106829605],
              [-81.884038202545995, 35.848105455485275],
              [-81.883988606228073, 35.848370433920344],
              [-81.883657169034521, 35.848330782418195],
              [-81.88360757132908, 35.848595760677497],
              [-81.883276132259681, 35.848556107267861],
              [-81.88317693374853, 35.84908606339026],
              [-81.882845494018483, 35.849046409691582],
              [-81.882746290975135, 35.849576365399216],
              [-81.882414848306951, 35.849536709661947],
              [-81.882266039743413, 35.850331641554654],
              [-81.881934594245607, 35.850291985423397],
              [-81.881785778461023, 35.851086916546542],
              [-81.879465645539597, 35.850809292052816],
              [-81.879416031464018, 35.851074268403934],
              [-81.878421690941309, 35.850955272293326],
              [-81.878024740966666, 35.853075073839037],
              [-81.877693285160277, 35.853035405341963],
              [-81.877594042296039, 35.853565355243802],
              [-81.877262584690257, 35.853525685582866],
              [-81.877163337260868, 35.854055634168333],
              [-81.876831878962349, 35.854015963317316],
              [-81.876782253175875, 35.85428093832568],
              [-81.876450794140268, 35.854241266441818],
              [-81.87635154050885, 35.85477121423385],
              [-81.876020078567251, 35.854731541212132],
              [-81.875970449647568, 35.854996514922732],
              [-81.875638986968909, 35.854956840868248],
              [-81.875589357768135, 35.855221814376819],
              [-81.874926431262381, 35.855142463300766],
              [-81.87487679850453, 35.855407436528182],
              [-81.874545334677464, 35.855367759506606],
              [-81.87449570053171, 35.855632732557886],
              [-81.874164235967598, 35.855593054503601],
              [-81.874114600434012, 35.855858027378829],
              [-81.873783135132953, 35.855818348291692],
              [-81.87368386096432, 35.856348293645276],
              [-81.874015328390257, 35.856387972994895],
              [-81.873965691880088, 35.856652945735618],
              [-81.874297160693885, 35.85669262431491],
              [-81.874247524888716, 35.856957596241109],
              [-81.875241937577343, 35.857076626937165],
              [-81.875192305772785, 35.857341600087032],
              [-81.875523778013559, 35.857381275322439],
              [-81.875474145807232, 35.857646247683874],
              [-81.87779447354427, 35.857923948974758],
              [-81.877744848450021, 35.858188922209798],
              [-81.878076326526127, 35.858228590468819],
              [-81.87802670330781, 35.858493564664961],
              [-81.878358181633061, 35.858533231278827],
              [-81.87825893631323, 35.859063178898005],
              [-81.878590418227731, 35.859102845747458],
              [-81.878540796126231, 35.85936781917043],
              [-81.878872279428705, 35.859407485249385],
              [-81.878822658096468, 35.859672459659663],
              [-81.879154142754814, 35.859712124067322],
              [-81.87910452215975, 35.859977098563782],
              [-81.879436008206071, 35.860016762201035],
              [-81.880761956816599, 35.860175409509431],
              [-81.880613108953483, 35.860970332895249],
              [-81.880944600953583, 35.861009993292583],
              [-81.880498049292541, 35.8633947604024],
              [-81.880166547760709, 35.863355099725425],
              [-81.880067308346341, 35.863885047014975],
              [-81.880398812003563, 35.863924707954283],
              [-81.880349192870909, 35.864189681662737],
              [-81.880017688151085, 35.864150020592234],
              [-81.879868825580417, 35.86494494015318],
              [-81.879537316890989, 35.864905277813726],
              [-81.879438071446017, 35.865435224300874],
              [-81.87910656092447, 35.865395559896371],
              [-81.879007312020121, 35.865925505040245],
              [-81.879338823560317, 35.865965169733244],
              [-81.879289199698718, 35.866230142819312],
              [-81.87962071370174, 35.866269805814632],
              [-81.879571089470701, 35.866534779012973],
              [-81.880565636653074, 35.866653763883271],
              [-81.880516016391624, 35.86691873740385],
              [-81.880847532715208, 35.8669583970809],
              [-81.880797913158972, 35.867223369786657],
              [-81.881129432009658, 35.867263029567923],
              [-81.88107981319088, 35.86752800235984],
              [-81.881411333429782, 35.867567661370487],
              [-81.881312098080443, 35.868097607956152],
              [-81.881643620738345, 35.868137265426405],
              [-81.881594003068798, 35.868402238345439],
              [-81.881925527147089, 35.868441895946141],
              [-81.881875910214944, 35.868706868951172],
              [-81.882207435649264, 35.868746524880336],
              [-81.882157820593648, 35.869011498846191],
              [-81.882489347416211, 35.869051154004765],
              [-81.88243973195901, 35.869316127181889],
              [-81.882771261276716, 35.869355781543696],
              [-81.882721646589104, 35.8696207557078],
              [-81.883053177295082, 35.869660409298994],
              [-81.883003563312684, 35.869925382648212],
              [-81.883335095407034, 35.869965035468773],
              [-81.883285482194253, 35.870230009804928],
              [-81.884280082511367, 35.870348963275603],
              [-81.884230473236698, 35.87061393703295],
              [-81.884562008758294, 35.870653586508681],
              [-81.884512399114257, 35.870918560378193],
              [-81.885507010820149, 35.871037503788074],
              [-81.885457405146283, 35.871302477979697],
              [-81.885788944095296, 35.871342124110576],
              [-81.885739339159016, 35.871607098388075],
              [-81.887728587137431, 35.871844956095195],
              [-81.887678988253498, 35.872109931114167],
              [-81.888010531605786, 35.872149571194711],
              [-81.887960933459595, 35.872414546299616],
              [-81.888292479306983, 35.872454185583223],
              [-81.888044489010881, 35.873779061085983],
              [-81.888376040498571, 35.873818700123117],
              [-81.888028848904042, 35.875673523018818],
              [-81.888360408158348, 35.875713162071762],
              [-81.888261209427966, 35.876243110947115],
              [-81.888592772240727, 35.876282749334223],
              [-81.888543173434556, 35.876547723384661],
              [-81.888874736528734, 35.876587361027049],
              [-81.888676343474273, 35.877647259076475],
              [-81.888344775020585, 35.877607620935926],
              [-81.888295174587583, 35.877872594760184],
              [-81.887963605428425, 35.87783295648763],
              [-81.887914003607051, 35.878097930135468],
              [-81.887582433677835, 35.878058289929086],
              [-81.887483226964747, 35.878588237727747],
              [-81.887151655234717, 35.878548596357241],
              [-81.887102050343174, 35.8788135705081],
              [-81.886770477875316, 35.878773928104629],
              [-81.886572049598797, 35.879833821053971],
              [-81.886240473203728, 35.879794177224284],
              [-81.885843590246836, 35.881913958149191],
              [-81.885645139818706, 35.882973846648419],
              [-81.88597673007915, 35.883013492024951],
              [-81.885877505576303, 35.883543435800874],
              [-81.885545913221463, 35.88350379106312],
              [-81.885496298849006, 35.883768762314517],
              [-81.885827892299218, 35.883808408084349],
              [-81.885728665843644, 35.884338351587886],
              [-81.885397070266805, 35.884298705555835],
              [-81.885248223308011, 35.885093620566977],
              [-81.884916624866889, 35.885053973239685],
              [-81.884817389796325, 35.885583915790789],
              [-81.884569297514673, 35.886908770896447],
              [-81.884900903366827, 35.886948418240479],
              [-81.884702428137913, 35.888008301485122],
              [-81.885034039708287, 35.888047949326172],
              [-81.884885182204314, 35.888842861019121],
              [-81.884553567443703, 35.888803212784786],
              [-81.884454324246207, 35.88933315344979],
              [-81.884122707684412, 35.889293504051246],
              [-81.884023462165132, 35.889823444245813],
              [-81.883691842694816, 35.889783793709178],
              [-81.883542968783956, 35.890578703266172],
              [-81.883211346449045, 35.890539051434274],
              [-81.883112092313212, 35.891068990675045],
              [-81.882780469284185, 35.891029337652775],
              [-81.882681211687085, 35.891559275548133],
              [-81.883012837950275, 35.891598928806538],
              [-81.882963209189242, 35.891863898280526],
              [-81.882631581862583, 35.891824244891083],
              [-81.882482692585768, 35.892619150791994],
              [-81.882151062426473, 35.89257949700805],
              [-81.882051797655876, 35.893109433219365],
              [-81.881720165695228, 35.893069778271148],
              [-81.881670532865272, 35.893334746151147],
              [-81.881338899027384, 35.893295089295002],
              [-81.881289264808316, 35.893560056998197],
              [-81.880957630232686, 35.893520399108922],
              [-81.880907993549229, 35.893785367562494],
              [-81.880576359342939, 35.893745708613899],
              [-81.880526721238368, 35.894010675989655],
              [-81.880195086294165, 35.893971016007875],
              [-81.880145446800441, 35.894235983206976],
              [-81.87981381111841, 35.894196322192023],
              [-81.879764169128194, 35.894461289240475],
              [-81.879714527951734, 35.894726257118052],
              [-81.880046166867928, 35.894765918369181],
              [-81.879897243371232, 35.895560819444746],
              [-81.880228885803788, 35.895600480187191],
              [-81.880179245451671, 35.895865448144427],
              [-81.880510889273353, 35.895905108115841],
              [-81.88046124962689, 35.896170075257587],
              [-81.880792894837811, 35.896209734458033],
              [-81.880693616695069, 35.896739668866672],
              [-81.881025264358897, 35.896779327427268],
              [-81.880677786905977, 35.898634096460412],
              [-81.880346131796955, 35.898594436981924],
              [-81.880097918201784, 35.899919269728002],
              [-81.879766256993051, 35.89987960871791],
              [-81.879716612428936, 35.900144574272716],
              [-81.87938495048229, 35.900104912229473],
              [-81.879335304560925, 35.900369878508293],
              [-81.87867197948529, 35.90029055055215],
              [-81.878523032245468, 35.90108544745236],
              [-81.878191367021302, 35.901045782178137],
              [-81.878141715466455, 35.90131074700561],
              [-81.878473381754304, 35.901350412410977],
              [-81.87842373207684, 35.90161537819862],
              [-81.878755399754141, 35.901655042832907],
              [-81.87860644967941, 35.902449938564615],
              [-81.878938120873485, 35.902489602690224],
              [-81.87859056747071, 35.904344358393452],
              [-81.878922247544111, 35.904384022508552],
              [-81.878822944799865, 35.904913952066529],
              [-81.879154627326614, 35.904953615541771],
              [-81.879104977637013, 35.90521858035688],
              [-81.879436661553314, 35.905258243061041],
              [-81.879387011494373, 35.905523207987514],
              [-81.879718696800253, 35.905562869920608],
              [-81.879669048586706, 35.905827834906155],
              [-81.880000735282167, 35.905867496068133],
              [-81.879702842187896, 35.907457284981795],
              [-81.880034535592216, 35.907496946028218],
              [-81.879984887193686, 35.907761910053658],
              [-81.880316581987671, 35.907801570328957],
              [-81.880266934359355, 35.908066535340474],
              [-81.880598630542892, 35.908106194844684],
              [-81.880548983652773, 35.90837115994141],
              [-81.881212380232355, 35.908450476479047],
              [-81.881162735112099, 35.908715440891108],
              [-81.881494435508131, 35.908755097924548],
              [-81.881444791158088, 35.909020063322572],
              [-81.881776491804303, 35.909059718710154],
              [-81.881726848192415, 35.9093246841934],
              [-81.882390254869009, 35.909403994273454],
              [-81.882340613027139, 35.909668959071965],
              [-81.882672317917937, 35.909708612889659],
              [-81.882622676814179, 35.909973577773293],
              [-81.883286089700974, 35.910052882964095],
              [-81.883236450399352, 35.910317848063904],
              [-81.883568159502801, 35.9103574994108],
              [-81.883518520939418, 35.910622464595654],
              [-81.884181940036427, 35.910701764897091],
              [-81.884132304382803, 35.910966730271966],
              [-81.8844640154838, 35.911006379200373],
              [-81.884414379460893, 35.911271344686384],
              [-81.884746093059164, 35.911310992817405],
              [-81.884696457774524, 35.911575958388475],
              [-81.885028172762404, 35.911615605748267],
              [-81.884978538216146, 35.911880571404367],
              [-81.885310254561546, 35.911920217091989],
              [-81.885260620785814, 35.912185183734152],
              [-81.885592338520908, 35.912224828650437],
              [-81.885542705483488, 35.912489795377617],
              [-81.885874424608332, 35.912529439522665],
              [-81.885824792276964, 35.912794405433964],
              [-81.886156512823817, 35.912834049708721],
              [-81.88605725041954, 35.91336398162894],
              [-81.88575945542236, 35.914953777185353],
              [-81.886091184775282, 35.914993420705237],
              [-81.885942285502082, 35.915788317817125],
              [-81.886274019480453, 35.9158279608016],
              [-81.886224386432986, 35.916092926553191],
              [-81.886556121801206, 35.916132568766386],
              [-81.886506489492135, 35.916397534602808],
              [-81.886838226282421, 35.916437176945514],
              [-81.88678859578701, 35.916702141939822],
              [-81.887120333967246, 35.916741783511284],
              [-81.887070703134967, 35.917006749517576],
              [-81.887402442672752, 35.917046389416754],
              [-81.887352813686576, 35.917311355481743],
              [-81.887684554614211, 35.917350994609606],
              [-81.887585296653285, 35.917880925988584],
              [-81.887917040067208, 35.917920565377031],
              [-81.887668895355986, 35.919245394109318],
              [-81.888000645491985, 35.919285032323366],
              [-81.88795101595305, 35.919549997537494],
              [-81.8882827675112, 35.91958963588106],
              [-81.888233139818368, 35.919854601153887],
              [-81.888564891626672, 35.919894237851302],
              [-81.888465637424545, 35.920424169421295],
              [-81.888797391687049, 35.920463805478398],
              [-81.888648511117808, 35.921258702429427],
              [-81.888980270038743, 35.92129833885182],
              [-81.888632874728543, 35.923153096581011],
              [-81.888301108389769, 35.923113460142424],
              [-81.888251478591187, 35.923378425076422],
              [-81.888583245994411, 35.923418061646018],
              [-81.888483986441244, 35.92394799166339],
              [-81.888815757406405, 35.923987627566234],
              [-81.888517977315558, 35.925577417293653],
              [-81.888849754992748, 35.925617053080153],
              [-81.888750495560856, 35.926146981953757],
              [-81.889082275692587, 35.926186617099766],
              [-81.888983016011167, 35.926716546977232],
              [-81.889314798597596, 35.926756181482695],
              [-81.88921554081746, 35.927286110509684],
              [-81.889547325858501, 35.927325744374699],
              [-81.889448067828909, 35.92785567440545],
              [-81.889779856432284, 35.92789530760372],
              [-81.889630970627721, 35.928690200880155],
              [-81.889962762750187, 35.928729833568909],
              [-81.889913133844701, 35.928994798650706],
              [-81.8902449273575, 35.929034430567889],
              [-81.88989752872746, 35.930889181122964],
              [-81.890229330017618, 35.93092881305455],
              [-81.890179701710721, 35.931193777100056],
              [-81.890511504391256, 35.931233408260049],
              [-81.890461876823181, 35.931498372390017],
              [-81.890793682001672, 35.93153800275212],
              [-81.890744054096956, 35.931802967893859],
              [-81.891075860633606, 35.931842596583543],
              [-81.891026234575463, 35.932107561783518],
              [-81.891689849679565, 35.932186817644251],
              [-81.891640225392635, 35.932451782158815],
              [-81.891972035067838, 35.93249140930363],
              [-81.891922411519886, 35.932756373902784],
              [-81.892254222585422, 35.932796000275872],
              [-81.892204599776363, 35.933060964959552],
              [-81.892536411124482, 35.933100590587379],
              [-81.89248678905426, 35.933365555355465],
              [-81.892818602900576, 35.933405180185417],
              [-81.892719361020013, 35.933935109817916],
              [-81.893051177289053, 35.933974733106176],
              [-81.892951935127201, 35.934504662840958],
              [-81.892620116696648, 35.934465038389867],
              [-81.892471247817056, 35.935259932250396],
              [-81.892139425410434, 35.935220306529992],
              [-81.891940924719634, 35.936280162934843],
              [-81.891609098379931, 35.936240535787967],
              [-81.89131132895109, 35.937830317815376],
              [-81.890979496548795, 35.937790688980165],
              [-81.890780973169413, 35.938850541600601],
              [-81.890449135726129, 35.938810911364996],
              [-81.890399503262827, 35.939075873815703],
              [-81.890731340663137, 35.939115504208416],
              [-81.890681708971087, 35.939380467644412],
              [-81.891013547729813, 35.939420096364493],
              [-81.890963915668905, 35.939685059911085],
              [-81.891295756958343, 35.939724688734216],
              [-81.891246126712005, 35.939989651437926],
              [-81.891909811257548, 35.940068905763461],
              [-81.891860181739574, 35.940333869609617],
              [-81.892855714765759, 35.940452745157984],
              [-81.89280608919205, 35.940717708423271],
              [-81.893137935598247, 35.940757331322303],
              [-81.893088310795804, 35.941022295572765],
              [-81.895079401451994, 35.941260017524932],
              [-81.89498016469156, 35.94178994562909],
              [-81.895312016604848, 35.941829563502999],
              [-81.895262398247041, 35.94209452762918],
              [-81.895594251518602, 35.942134143830344],
              [-81.89554463389986, 35.942399108040796],
              [-81.895876489702346, 35.94243872434479],
              [-81.895826872822795, 35.94270368863949],
              [-81.896490586394421, 35.942782917926529],
              [-81.896440971318995, 35.943047882436417],
              [-81.896772830196014, 35.943087495393137],
              [-81.896723215859808, 35.943352459987338],
              [-81.897055076127558, 35.943392072172259],
              [-81.896955849715809, 35.943922001456073],
              [-81.896623987318264, 35.943882389009325],
              [-81.896127823894872, 35.946532029541522],
              [-81.896459696942244, 35.946571643297354],
              [-81.896410079436762, 35.946836607415207],
              [-81.896741954982843, 35.946876220372857],
              [-81.896642721092292, 35.947406147829085],
              [-81.896974599094179, 35.947445760145769],
              [-81.896875367138733, 35.94797568765059],
              [-81.897207246520836, 35.948015300253417],
              [-81.897157631657336, 35.948280263602975],
              [-81.897821394572233, 35.948359484533988],
              [-81.897722167052834, 35.948889412543465],
              [-81.898054051715306, 35.94892902280435],
              [-81.898004439069879, 35.949193986406137],
              [-81.898336325090838, 35.94923359499419],
              [-81.898187486212151, 35.950028486865087],
              [-81.898519375754148, 35.950068094942928],
              [-81.898469763936077, 35.950333058619329],
              [-81.898801654901277, 35.950372666826198],
              [-81.898652819580292, 35.951167557993287],
              [-81.89850398136015, 35.951962449639787],
              [-81.898835880117772, 35.952002056801803],
              [-81.898736654748518, 35.952531984001752],
              [-81.899068555962145, 35.952571590522737],
              [-81.899018943300064, 35.952836554196494],
              [-81.899350845904706, 35.952876159945518],
              [-81.899301235090135, 35.953141123676865],
              [-81.899633139085623, 35.953180728653884],
              [-81.899533917501714, 35.953710656264086],
              [-81.899865823953476, 35.953750260599968],
              [-81.899518547203641, 35.955605004832918],
              [-81.899186632187138, 35.955565399607323],
              [-81.898888946980279, 35.957155177512909],
              [-81.899220867280505, 35.957194783550101],
              [-81.89907202344277, 35.95798967136065],
              [-81.899403948372964, 35.958029276861197],
              [-81.899255104831852, 35.958824165542275],
              [-81.899587033283765, 35.958863770532489],
              [-81.899487804345753, 35.959393695154176],
              [-81.899155872654987, 35.95935408992856],
              [-81.898808553130323, 35.961208824621167],
              [-81.899140492279045, 35.961248430762737],
              [-81.898892402308491, 35.96257324076128],
              [-81.89856045783263, 35.962533633965371],
              [-81.89811386276574, 35.964918284462748],
              [-81.898445817939304, 35.964957892410204],
              [-81.89829694986831, 35.965752775162109],
              [-81.898628908564248, 35.965792382599041],
              [-81.89852966367468, 35.966322304159256],
              [-81.898861624827589, 35.966361910954959],
              [-81.898812002943373, 35.966626871344765],
              [-81.899143965487767, 35.966666477368307],
              [-81.899044724013635, 35.96719639914258],
              [-81.89970865434212, 35.967275609003885],
              [-81.899659034677143, 35.96754056964469],
              [-81.899991002503953, 35.967580173325267],
              [-81.899941383611207, 35.967845134950629],
              [-81.900273351721239, 35.967884737885363],
              [-81.900223734644044, 35.968149698667084],
              [-81.900555704145603, 35.968189300829629],
              [-81.900406851971979, 35.96898418423762],
              [-81.90073882610443, 35.969023785863307],
              [-81.900639592171927, 35.969553707551512],
              [-81.90097156876152, 35.969593308535799],
              [-81.900872335657155, 35.970123230296743],
              [-81.901204314704032, 35.970162830639595],
              [-81.901154698174622, 35.970427791593238],
              [-81.900856995513095, 35.972017557144078],
              [-81.900525007898096, 35.971977955911782],
              [-81.900475389130477, 35.972242916377276],
              [-81.900227285938627, 35.973567717199799],
              [-81.899231305897729, 35.973448906629919],
              [-81.899181680836861, 35.97371386554444],
              [-81.898849688205814, 35.973674260658385],
              [-81.898800061753292, 35.973939219394737],
              [-81.898136073055994, 35.973860004902235],
              [-81.898185701639946, 35.973595046427583],
              [-81.897521717486143, 35.973515828558071],
              [-81.897472086802964, 35.973780787671906],
              [-81.897140093595041, 35.973741177264714],
              [-81.897090460379587, 35.974006135325837],
              [-81.896426473918311, 35.973926911514091],
              [-81.896376838277746, 35.974191870167026],
              [-81.895712850988872, 35.974112642481046],
              [-81.895663212858395, 35.974377599923997],
              [-81.895331218082916, 35.974337984608582],
              [-81.895281578593114, 35.97460294277419],
              [-81.894949584186278, 35.974563326398503],
              [-81.894899943272293, 35.974828283484889],
              [-81.894567947017379, 35.974788666101553],
              [-81.894518304744039, 35.97505362391059],
              [-81.89418630771695, 35.975014004592261],
              [-81.894136664051828, 35.975278962223129],
              [-81.893804666317308, 35.975239342771687],
              [-81.893755021228074, 35.975504299323383],
              [-81.893091024573451, 35.975425056548403],
              [-81.89304137705885, 35.975690013691747],
              [-81.892709378170991, 35.975650391269198],
              [-81.892659728123917, 35.975915347359688],
              [-81.892327728496312, 35.975875723903016],
              [-81.892278078165745, 35.976140679788877],
              [-81.891946077798451, 35.97610105529818],
              [-81.891896424967669, 35.976366011032042],
              [-81.891232423047455, 35.976286758178603],
              [-81.891182767791392, 35.976551714504069],
              [-81.890850766238401, 35.976512086141241],
              [-81.890801109558183, 35.976777041387543],
              [-81.890137105331434, 35.976697782591536],
              [-81.890087446225692, 35.976962738429485],
              [-81.889755443519576, 35.976923107095402],
              [-81.88965612216596, 35.977453017466189],
              [-81.889324117654382, 35.977413384967114],
              [-81.88927445540638, 35.977678339500017],
              [-81.888942449079067, 35.977638706893991],
              [-81.888843121472974, 35.978168615555539],
              [-81.888511114416318, 35.978128980857441],
              [-81.888461447949922, 35.978393935012257],
              [-81.888129440185864, 35.978354300180904],
              [-81.888030105251119, 35.978884208060251],
              [-81.887698095649355, 35.978844571162966],
              [-81.887648426072758, 35.979109524913497],
              [-81.887316415763621, 35.979069887883036],
              [-81.887167402955072, 35.979864747529966],
              [-81.886835388666228, 35.979825109229694],
              [-81.886736043389831, 35.980355014852563],
              [-81.886404027295413, 35.980315375387221],
              [-81.886255003198343, 35.98111023353588],
              [-81.88592298420042, 35.981070591873618],
              [-81.885873307424376, 35.981335544672895],
              [-81.885541287686834, 35.981295901976566],
              [-81.885491609518553, 35.981560854597291],
              [-81.885159589041393, 35.981521210866717],
              [-81.88510990948096, 35.981786163309017],
              [-81.884777888264182, 35.981746518544334],
              [-81.884678526000556, 35.982276422123341],
              [-81.884346502978303, 35.982236776193623],
              [-81.884098085187162, 35.983561534130608],
              [-81.883766056053219, 35.983521886668946],
              [-81.883666684957575, 35.984051789058107],
              [-81.883334654018185, 35.984012140431275],
              [-81.883284966931029, 35.984277091873977],
              [-81.88295293525195, 35.984237442212986],
              [-81.882903245631852, 35.984502392602366],
              [-81.882571214321629, 35.984462741881018],
              [-81.882422141446142, 35.985257593245173],
              [-81.882090106155971, 35.985217941253829],
              [-81.881990720823268, 35.9857478410539],
              [-81.881658683727537, 35.985708187897366],
              [-81.881559293881068, 35.98623808817181],
              [-81.881227254979791, 35.986198433850134],
              [-81.881177558484836, 35.986463383334446],
              [-81.880845518843969, 35.986423727978568],
              [-81.880795820956635, 35.986688677284214],
              [-81.880463780544019, 35.986649019993195],
              [-81.88041408129645, 35.986913970021092],
              [-81.88008203903577, 35.986874311721984],
              [-81.879982637365089, 35.987404209570947],
              [-81.879650594439539, 35.987364550981368],
              [-81.87950148517865, 35.988159397030472],
              [-81.879169439381513, 35.988119737144565],
              [-81.878871205617031, 35.98970942626773],
              [-81.878539152641622, 35.989669764718649],
              [-81.878489444039602, 35.989934711874483],
              [-81.878439736251806, 35.990199659857289],
              [-81.878771791391586, 35.990239322569273],
              [-81.878473540782906, 35.991829007625171],
              [-81.878805603754188, 35.991868670193696],
              [-81.878656476670002, 35.99266351203228],
              [-81.87832441164106, 35.99262384994541],
              [-81.878274700513558, 35.992888796706787],
              [-81.878606767749361, 35.992928459799401],
              [-81.878457636747086, 35.993723301064229],
              [-81.878789707475718, 35.99376296274562],
              [-81.878690287687476, 35.99429285723668],
              [-81.87902236087497, 35.994332518276764],
              [-81.878972652095413, 35.994597465117998],
              [-81.879304726707787, 35.994637126286634],
              [-81.879155599391495, 35.995431966968603],
              [-81.879487678605528, 35.99547162669986],
              [-81.879338551581355, 35.996266468244869],
              [-81.879006470277361, 35.99622680809459],
              [-81.878857337083559, 35.997021647914877],
              [-81.878525251798735, 35.996981986494298],
              [-81.87807782384634, 35.999366501314313],
              [-81.878409919836372, 35.999406163887741],
              [-81.878360204810065, 35.999671109976049],
              [-81.878260773745751, 36.000201001107968],
              [-81.878592873293499, 36.000240664071868],
              [-81.878543158321634, 36.000505609246467],
              [-81.8798715617299, 36.000664249941437],
              [-81.879821851837804, 36.00092919646675],
              [-81.88015395401024, 36.000968854300751],
              [-81.880104244825802, 36.00123380000818],
              [-81.881100558750958, 36.001352769331262],
              [-81.880951437759251, 36.002147608271315],
              [-81.880619329232943, 36.00210795264978],
              [-81.880519911114092, 36.002637845083541],
              [-81.880187800781499, 36.002598188296624],
              [-81.880038667416471, 36.003393026657747],
              [-81.881035010100447, 36.003511995460677],
              [-81.880985302242721, 36.0037769412599],
              [-81.881649533725195, 36.003856249557238],
              [-81.881599827705926, 36.004121196471253],
              [-81.881931946112175, 36.004160849369569],
              [-81.881882239692004, 36.00442579549167],
              [-81.882214359491243, 36.00446544761747],
              [-81.882164654919663, 36.004730393796322],
              [-81.882496775003176, 36.004770045175768],
              [-81.88244707117164, 36.005034991437597],
              [-81.8821149489129, 36.004995339953432],
              [-81.881916124978588, 36.006055124923158],
              [-81.881583998780343, 36.006015472011619],
              [-81.881534291215289, 36.006280418671992],
              [-81.881202164277099, 36.006240764726051],
              [-81.881152454177908, 36.00650571033264],
              [-81.880820326499773, 36.006466055352206],
              [-81.880720904297476, 36.006995946133117],
              [-81.880388774812857, 36.006956289987251],
              [-81.880339062709723, 36.007221235161502],
              [-81.8800069313763, 36.007181578007327],
              [-81.879957217880033, 36.007446523002436],
              [-81.879625085806666, 36.007406864813852],
              [-81.879575370917266, 36.007671809629855],
              [-81.879243238104053, 36.0076321504067],
              [-81.879193521821492, 36.007897095043703],
              [-81.878861388268263, 36.007857434786075],
              [-81.878761950372578, 36.008387323705961],
              [-81.878429816089508, 36.008347661355828],
              [-81.878380095585143, 36.008612605612512],
              [-81.878712230902522, 36.00865226719273],
              [-81.878662511138003, 36.008917211532292],
              [-81.878330374754057, 36.008877549821115],
              [-81.878280653596306, 36.009142493981457],
              [-81.878612791046905, 36.009182155823623],
              [-81.878463628781404, 36.009976987507905],
              [-81.878795770899302, 36.010016649714267],
              [-81.878696329878224, 36.010546537224521],
              [-81.879028474423833, 36.010586197888394],
              [-81.878779870394894, 36.01191091695199],
              [-81.879112020600374, 36.011950577367188],
              [-81.878962858134742, 36.012745408784198],
              [-81.879627167034627, 36.012824727663606],
              [-81.879676886071181, 36.0125597842448],
              [-81.881005503523824, 36.012718410636829],
              [-81.880955788754008, 36.012983354579482],
              [-81.881287945554874, 36.013023009036367],
              [-81.881138803626598, 36.013817841842375],
              [-81.881470962845356, 36.013857495814761],
              [-81.88137153577388, 36.014387384664268],
              [-81.881039373312774, 36.014347730456123],
              [-81.880741075608611, 36.01593739421174],
              [-81.88040890707363, 36.015897738314294],
              [-81.880309469367702, 36.016427624992737],
              [-81.879961427106608, 36.018282226844732],
              [-81.879629248188081, 36.018242568891189],
              [-81.879579525491522, 36.018507511688497],
              [-81.879247345832951, 36.018467852700468],
              [-81.879197621742861, 36.018732795318471],
              [-81.878865441344075, 36.018693135295806],
              [-81.878815715860426, 36.018958077734474],
              [-81.87848353472161, 36.018918416677195],
              [-81.878433806735359, 36.01918335896265],
              [-81.877769443304246, 36.019104033875159],
              [-81.877719713934439, 36.019368974923353],
              [-81.877055349676624, 36.019289645959248],
              [-81.877005616769495, 36.019554587624022],
              [-81.876341251652931, 36.019475253882554],
              [-81.87624178169996, 36.020005136516964],
              [-81.875909597514237, 36.019965468479207],
              [-81.875859860394144, 36.020230408705011],
              [-81.875527675468277, 36.020190739632582],
              [-81.875428198145997, 36.020720620577812],
              [-81.875096010304347, 36.020680950365936],
              [-81.874946788685293, 36.021475771477959],
              [-81.874614599078797, 36.021436099943323],
              [-81.874564855688106, 36.021701039511612],
              [-81.874232665341566, 36.021661366942325],
              [-81.874083432258004, 36.022456185812231],
              [-81.873751237928545, 36.022416511972352],
              [-81.87370149220574, 36.022681451058766],
              [-81.873369297136335, 36.022641776184145],
              [-81.87331955001963, 36.022906715091025],
              [-81.87298735421021, 36.022867039181676],
              [-81.872937605699818, 36.023131977909159],
              [-81.872605409150353, 36.023092300965132],
              [-81.872456156270957, 36.023887117416528],
              [-81.872788356021204, 36.023926794753592],
              [-81.872738605094227, 36.024191733313174],
              [-81.872406404277044, 36.024152055845015],
              [-81.872356651956125, 36.024416994225071],
              [-81.872688853872347, 36.024456672725044],
              [-81.872589351492778, 36.024986549575551],
              [-81.872921555869823, 36.025026227433791],
              [-81.872871805811187, 36.025291165904214],
              [-81.873204011550015, 36.025330842088934],
              [-81.873154261122338, 36.025595780667871],
              [-81.873486469396184, 36.025635456954667],
              [-81.87323771990738, 36.026960149724182],
              [-81.872905506330611, 36.026920473683219],
              [-81.872557233818085, 36.02877503974964],
              [-81.872225013098955, 36.028735361887868],
              [-81.872125502359438, 36.029265236545285],
              [-81.872457725244828, 36.029304915569959],
              [-81.872208945252979, 36.030629602533267],
              [-81.871876717064211, 36.030589923754441],
              [-81.871777200638064, 36.031119797757029],
              [-81.871444969500871, 36.03108011693768],
              [-81.871395209746282, 36.031345054185373],
              [-81.871062977868945, 36.031305372331161],
              [-81.871013216720158, 36.031570309399264],
              [-81.870680984102705, 36.031530626510211],
              [-81.870531694351726, 36.032325436154473],
              [-81.870199459969001, 36.032285751942631],
              [-81.870050162932245, 36.033080559880666],
              [-81.869717924598049, 36.033040875298887],
              [-81.869618389818882, 36.033570746142757],
              [-81.869286149677478, 36.033531060395028],
              [-81.869236380175579, 36.033795995625908],
              [-81.868904139261886, 36.033756307942461],
              [-81.868854369507048, 36.034021243868438],
              [-81.868522126744097, 36.033981555176197],
              [-81.868472355562844, 36.034246490021509],
              [-81.868140112059891, 36.034206800294406],
              [-81.868090339516428, 36.034471735860762],
              [-81.867758095273302, 36.034432045098896],
              [-81.867708320194339, 36.034696979610615],
              [-81.867376076320269, 36.034657287787851],
              [-81.867326299847107, 36.034922222119782],
              [-81.86699405526501, 36.034882530162974],
              [-81.866794940722997, 36.035942266505067],
              [-81.867127190715124, 36.035981959860919],
              [-81.867077412606278, 36.036246893948999],
              [-81.866745161546902, 36.036207200462158],
              [-81.866595820946785, 36.037002002066664],
              [-81.866263568121951, 36.036962307256751],
              [-81.866114221375739, 36.037757108028778],
              [-81.865781964567191, 36.037717411947945],
              [-81.865732179857432, 36.037982345327229],
              [-81.865399922308796, 36.037942648211526],
              [-81.865001628686059, 36.040062110533654],
              [-81.865333894773016, 36.040101808697926],
              [-81.865234320213958, 36.040631673601489],
              [-81.865566589872031, 36.040671371097993],
              [-81.865467015029566, 36.041201236094103],
              [-81.865799287149542, 36.041240932948739],
              [-81.865749501398042, 36.041505865028093],
              [-81.866081774912558, 36.041545561109949],
              [-81.865932416742709, 36.042340359301399],
              [-81.86626469375453, 36.042380053971534],
              [-81.866214908861025, 36.042644987018228],
              [-81.866165122498998, 36.042909919141081],
              [-81.865832843320334, 36.042870223307872],
              [-81.865733268621341, 36.043400088019595],
              [-81.865400986525827, 36.043360391046399],
              [-81.865351198188648, 36.043625323635219],
              [-81.86501891535292, 36.04358562562696],
              [-81.864869542861143, 36.044380420055894],
              [-81.864537258259773, 36.044340720724634],
              [-81.86438787854317, 36.045135515246457],
              [-81.86405558995736, 36.045095814644071],
              [-81.863756815219105, 36.04668539977677],
              [-81.863707018283932, 36.046950330460611],
              [-81.863374722553118, 36.046910628036926],
              [-81.863275124456464, 36.047440489021483],
              [-81.86294282691793, 36.047400785431705],
              [-81.862893026865763, 36.047665715706323],
              [-81.862560728587042, 36.047626011081441],
              [-81.862461124256541, 36.048155871247417],
              [-81.862128824170142, 36.048116165456506],
              [-81.862079019891596, 36.048381095347793],
              [-81.861746719033022, 36.048341387620944],
              [-81.861696913359623, 36.048606317332101],
              [-81.861364611792965, 36.04856660947106],
              [-81.861264997328988, 36.049096468484052],
              [-81.860932693922564, 36.049056758555949],
              [-81.860882885132099, 36.049321687857756],
              [-81.860550581017478, 36.049281977795481],
              [-81.860450960287224, 36.049811835088711],
              [-81.860783267646283, 36.049851545387206],
              [-81.860733457873664, 36.050116474541717],
              [-81.861065766627661, 36.050156184067312],
              [-81.86086652961076, 36.051215899791629],
              [-81.860534216554456, 36.051176188840891],
              [-81.860434593375771, 36.05170604570867],
              [-81.860102278543678, 36.051666334492573],
              [-81.860052465411556, 36.051931263172136],
              [-81.859720149807387, 36.051891550020066],
              [-81.859670335280242, 36.052156478519301],
              [-81.858008755819043, 36.05195790127204],
              [-81.857958934485168, 36.052222828191773],
              [-81.857294303327023, 36.052143391412841],
              [-81.857244480639906, 36.052408317995265],
              [-81.856579847515164, 36.0523288764631],
              [-81.856629673446875, 36.052063950116988],
              [-81.855300417584758, 36.051905055776466],
              [-81.855250587414545, 36.052169982498917],
              [-81.853256707025821, 36.051931612630774],
              [-81.853206869013334, 36.052196538543178],
              [-81.852874555688786, 36.052156806786492],
              [-81.852824717358587, 36.052421731591799],
              [-81.85216008958902, 36.052342266005034],
              [-81.852110247686767, 36.052607190524739],
              [-81.851777933209888, 36.052567455793749],
              [-81.851728091021869, 36.052832380107219],
              [-81.851063459806397, 36.052752907696799],
              [-81.851013615187497, 36.053017832599643],
              [-81.850681299558246, 36.05297809489435],
              [-81.850631452402851, 36.05324301874181],
              [-81.849966818882905, 36.053163540382691],
              [-81.849916970374139, 36.053428463892729],
              [-81.849584652483358, 36.053388723238946],
              [-81.849534801469844, 36.053653646594462],
              [-81.848870166755006, 36.053574162260816],
              [-81.84882031327875, 36.053839085304659],
              [-81.848487994235853, 36.053799341676481],
              [-81.848438139364347, 36.054064264540024],
              [-81.848105820690975, 36.054024519850749],
              [-81.848055964424091, 36.054289442533879],
              [-81.847723643901418, 36.054249696835271],
              [-81.847673786239326, 36.054514619337979],
              [-81.847341464976779, 36.05447487260416],
              [-81.847291605919295, 36.054739794926419],
              [-81.846959283885155, 36.054700046256499],
              [-81.84680970043415, 36.055494812559921],
              [-81.846477375556674, 36.055455063493262],
              [-81.846277920215513, 36.056514749825524],
              [-81.845945592504876, 36.056474999304172],
              [-81.845795993514457, 36.0572697629364],
              [-81.84546366181911, 36.057230011143297],
              [-81.845164448582153, 36.058819536288958],
              [-81.844832110808596, 36.058779782804528],
              [-81.844582750939225, 36.060104383904367],
              [-81.844250408155006, 36.060064628859813],
              [-81.844050909846572, 36.061124307790756],
              [-81.843718562009755, 36.061084551343008],
              [-81.843618808214046, 36.061614390713132],
              [-81.843286458537804, 36.061574632197953],
              [-81.843136822720865, 36.062369390025474],
              [-81.842804470169227, 36.062329630212531],
              [-81.842654827088438, 36.06312438722734],
              [-81.842322470551807, 36.06308462614242],
              [-81.842172821316495, 36.063879382318639],
              [-81.841840463013966, 36.063839619910098],
              [-81.841790577831375, 36.064104538113575],
              [-81.841458217711164, 36.064064775596442],
              [-81.841408332242509, 36.064329693593443],
              [-81.841075971350662, 36.064289929140067],
              [-81.840976195074632, 36.064819764774818],
              [-81.840643832375108, 36.064779999154752],
              [-81.840593942677387, 36.065044916766702],
              [-81.840261580379348, 36.06500515098638],
              [-81.840211688176169, 36.065270068443368],
              [-81.839879325106523, 36.065230300726782],
              [-81.839779538690294, 36.065760135203988],
              [-81.839447172703387, 36.065720366346547],
              [-81.839397278490168, 36.06598528336675],
              [-81.839064911763515, 36.06594551347392],
              [-81.83896511910784, 36.06647534712905],
              [-81.838632750573424, 36.066435576069559],
              [-81.838483056449235, 36.067230324873172],
              [-81.838150685039352, 36.067190552515761],
              [-81.837951082171088, 36.068250216711839],
              [-81.837618706817651, 36.068210442925206],
              [-81.837518900708744, 36.068740274025743],
              [-81.837186523579291, 36.068700499973389],
              [-81.837136618949032, 36.068965414867542],
              [-81.836804239938374, 36.068925638904531],
              [-81.836754333943844, 36.069190554518777],
              [-81.836421955303024, 36.069150777494677],
              [-81.836372047880687, 36.069415692027086],
              [-81.836039667390409, 36.069375913993291],
              [-81.835989758603844, 36.069640829245706],
              [-81.836322140162039, 36.069680607410881],
              [-81.836222323708682, 36.070210437128679],
              [-81.836554706621186, 36.070250214677948],
              [-81.836454890959558, 36.070780043558941],
              [-81.836787277445723, 36.070819820440853],
              [-81.836587646520556, 36.071879479934211],
              [-81.836920037574885, 36.071919255536095],
              [-81.836770314123953, 36.072713999578077],
              [-81.836437919833855, 36.072674222681563],
              [-81.836338100433849, 36.073204051832327],
              [-81.836005703226149, 36.073164273794802],
              [-81.835955791950127, 36.07342918771392],
              [-81.835623394002539, 36.073389408640814],
              [-81.835523568329947, 36.073919236067589],
              [-81.835191168574468, 36.073879455827715],
              [-81.835141254177955, 36.074144369335301],
              [-81.834808853682603, 36.074104588059903],
              [-81.83475893678019, 36.074369501412207],
              [-81.834426536654675, 36.074329719075607],
              [-81.834376618355961, 36.074594632246907],
              [-81.834044216380889, 36.074554848900476],
              [-81.833894457421337, 36.075349587696422],
              [-81.834226862600559, 36.0753893714367],
              [-81.834127024159301, 36.075919197305979],
              [-81.834459431802756, 36.0759589804046],
              [-81.834409512603045, 36.076223893408731],
              [-81.834741922752599, 36.076263675708645],
              [-81.834642085472979, 36.076793500929504],
              [-81.834974498118626, 36.076833283488575],
              [-81.834874662772179, 36.077363108747129],
              [-81.835207076740801, 36.07740288978929],
              [-81.835107242249506, 36.077932716012235],
              [-81.835439659792343, 36.077972496387019],
              [-81.835389742535924, 36.078237408667015],
              [-81.835057323924929, 36.07819762816095],
              [-81.835007406413752, 36.078462541135174],
              [-81.834674987062812, 36.07842275959355],
              [-81.834625068155262, 36.07868767238655],
              [-81.833960228301763, 36.078608106327991],
              [-81.833860382978045, 36.079137930390061],
              [-81.833527962517195, 36.079098145716095],
              [-81.83347803827894, 36.07936305709066],
              [-81.833145615968306, 36.079323271406842],
              [-81.833095690365425, 36.079588183501166],
              [-81.83276326731486, 36.079548396781746],
              [-81.832563557284558, 36.080608042333907],
              [-81.832231129148013, 36.08056825330987],
              [-81.832131270026863, 36.081098075524281],
              [-81.831798841223787, 36.08105828620841],
              [-81.831698977543184, 36.081588107986448],
              [-81.831366546900412, 36.081548316602884],
              [-81.831316612960322, 36.081813227749052],
              [-81.830651750554793, 36.08173364290716],
              [-81.830601814150043, 36.081998553740895],
              [-81.829936949811582, 36.081918965044757],
              [-81.829887012020137, 36.082183874639398],
              [-81.829554578736222, 36.082144079266826],
              [-81.829504638438394, 36.082408988705964],
              [-81.829172205524443, 36.082369192272118],
              [-81.829122263830058, 36.082634101529933],
              [-81.82878983014453, 36.082594303159581],
              [-81.828689943665879, 36.083124122163831],
              [-81.829022379456234, 36.083163919895945],
              [-81.828872551574221, 36.083958647945856],
              [-81.829204990897665, 36.083998445167602],
              [-81.829155049411199, 36.084263354231624],
              [-81.828822607909643, 36.084223556904142],
              [-81.828672774945389, 36.085018283479528],
              [-81.828340330535866, 36.084978483952924],
              [-81.828240438114491, 36.085508301157368],
              [-81.827907990818559, 36.085468501390324],
              [-81.827808094946946, 36.085998318132241],
              [-81.827475646920888, 36.085958516271553],
              [-81.827225893901485, 36.087283055745395],
              [-81.827558348378929, 36.087322858236995],
              [-81.827408496040135, 36.088117581349664],
              [-81.827740954051066, 36.08815738333093],
              [-81.827641052605969, 36.088687198460214],
              [-81.827973513081986, 36.088726999799697],
              [-81.827923563474286, 36.088991906957318],
              [-81.828256025347244, 36.089031707523795],
              [-81.828156126204874, 36.089561522878022],
              [-81.828488590511427, 36.08960132190186],
              [-81.828438642086724, 36.089866230072822],
              [-81.828771107821751, 36.089906029224387],
              [-81.828621263719128, 36.090700752029015],
              [-81.828953732987884, 36.090740550670155],
              [-81.828853837424646, 36.091270366185817],
              [-81.829186310237048, 36.091310163258541],
              [-81.829136363015451, 36.091575070622106],
              [-81.829468836146447, 36.091614867848328],
              [-81.82936894288639, 36.092144683588543],
              [-81.829701419561005, 36.092184479246392],
              [-81.829601527124254, 36.092714295048758],
              [-81.829934006264281, 36.092754090064773],
              [-81.82988406060592, 36.093018997571583],
              [-81.830549022040117, 36.093098586053721],
              [-81.830499078190257, 36.093363493773083],
              [-81.830831560484341, 36.093403287239106],
              [-81.830781617374583, 36.093668195039633],
              [-81.831114101033961, 36.093707986831696],
              [-81.831064158664404, 36.093972894713374],
              [-81.831729130247126, 36.094052476721743],
              [-81.831679189686156, 36.094317384815845],
              [-81.832344164687939, 36.094396962568013],
              [-81.83229422707744, 36.094661871749686],
              [-81.832194348587819, 36.09519168821214],
              [-81.831861858272006, 36.095151899381641],
              [-81.831761976331592, 36.095681715380827],
              [-81.832094469894571, 36.095721504448171],
              [-81.832044529532055, 36.095986413404667],
              [-81.832377024491961, 36.096026201698891],
              [-81.832327084837971, 36.096291109835605],
              [-81.831994588809351, 36.096251321410151],
              [-81.8318947074888, 36.096781137244733],
              [-81.831229712142459, 36.096701557155129],
              [-81.831179768297346, 36.096966464747418],
              [-81.830847270016406, 36.0969266723137],
              [-81.830797324774224, 36.09719157972448],
              [-81.830132327124076, 36.097111993682681],
              [-81.830082379416098, 36.097376900780681],
              [-81.829749880015143, 36.097337106271716],
              [-81.829699930910053, 36.097602013188165],
              [-81.829034929814554, 36.097522420319024],
              [-81.828984978243724, 36.097787326922578],
              [-81.828652477690895, 36.097747529437584],
              [-81.828602523612986, 36.098012435885231],
              [-81.828270022288393, 36.097972636463538],
              [-81.828220067923368, 36.098237542703998],
              [-81.827887564780241, 36.098197743173017],
              [-81.827837609018076, 36.09846264923199],
              [-81.827505106213252, 36.098422847738554],
              [-81.827455147943766, 36.098687753641563],
              [-81.827122644398941, 36.098647951112376],
              [-81.827022724737134, 36.099177762505029],
              [-81.826690219415099, 36.09913795970953],
              [-81.82664025799113, 36.099402864298241],
              [-81.826307750818941, 36.099363060492479],
              [-81.82615786143279, 36.100157775364814],
              [-81.825825352461635, 36.100117969334043],
              [-81.825725422444961, 36.100647779335318],
              [-81.825392910555067, 36.100607972163075],
              [-81.825342943985106, 36.100872876956949],
              [-81.825010431355025, 36.100833068748763],
              [-81.824960463387882, 36.101097973360979],
              [-81.823962924294989, 36.10097854381609],
              [-81.823912952761447, 36.101243447083064],
              [-81.823247926215899, 36.10116382290137],
              [-81.823197952216347, 36.101428725855385],
              [-81.822865438383701, 36.101388912726762],
              [-81.822815461876715, 36.101653815524642],
              [-81.822150434138379, 36.101574185364683],
              [-81.822100455165426, 36.101839087849562],
              [-81.821767940149755, 36.101799270843742],
              [-81.821667979111965, 36.102329076300634],
              [-81.821335462287607, 36.102289258127534],
              [-81.821235497797502, 36.10281906312018],
              [-81.82090297916443, 36.102779243779892],
              [-81.820852995373428, 36.103044146519693],
              [-81.820520476000368, 36.103004326143385],
              [-81.820470490780366, 36.103269227800638],
              [-81.820137970667318, 36.103229406388401],
              [-81.820087982939739, 36.10349430788952],
              [-81.819755462086732, 36.103454485441411],
              [-81.81970547410333, 36.103719387636026],
              [-81.819372951400226, 36.103679564177433],
              [-81.819272972246537, 36.104209366350986],
              [-81.818940447734732, 36.104169541725234],
              [-81.818840464018436, 36.104699343459906],
              [-81.818507938808068, 36.104659517641132],
              [-81.818407951670551, 36.105189319812311],
              [-81.81807542354133, 36.105149492851758],
              [-81.818025427839842, 36.105414393292691],
              [-81.817692900080814, 36.105374565270594],
              [-81.817592905553866, 36.10590436573834],
              [-81.817260374875971, 36.105864536574437],
              [-81.817160378006236, 36.106394336551936],
              [-81.81682784548812, 36.1063545053198],
              [-81.816677842845777, 36.107149204551867],
              [-81.81634530748164, 36.107109372921812],
              [-81.816195298673094, 36.107904071305285],
              [-81.81586275932122, 36.107864238401987],
              [-81.81576274963534, 36.108394035908198],
              [-81.815430209584903, 36.108354201811899],
              [-81.815380202085407, 36.108619100833849],
              [-81.81504766129504, 36.108579265701536],
              [-81.814947644250395, 36.109109062404613],
              [-81.814615101651256, 36.10906922610485],
              [-81.814565091027376, 36.109334124712412],
              [-81.814232547656829, 36.10929428647573],
              [-81.814182535635084, 36.109559184901357],
              [-81.813849991556197, 36.109519346529517],
              [-81.813799978105209, 36.109784243872291],
              [-81.813467433286405, 36.109744404464422],
              [-81.813417418437609, 36.110009301625226],
              [-81.812752326507422, 36.109929618957487],
              [-81.81270230922344, 36.110194516705668],
              [-81.812369762143589, 36.110154674346674],
              [-81.812319744540545, 36.110419570986572],
              [-81.811987196720807, 36.110379727591351],
              [-81.811937176609717, 36.110644624074766],
              [-81.811604628050119, 36.110604779643637],
              [-81.811554606541165, 36.11086967594494],
              [-81.81188715620118, 36.110909521408473],
              [-81.811837135432185, 36.111174417790743],
              [-81.811787114365572, 36.111439315023304],
              [-81.811454562567818, 36.111399469296899],
              [-81.81140454010341, 36.11166436634737],
              [-81.806083743523075, 36.111026712192697],
              [-81.806133784230894, 36.110761818120956],
              [-81.804471058693082, 36.110562502597766],
              [-81.804521105525978, 36.110297609107484],
              [-81.804188562036813, 36.110257743610845],
              [-81.804238609577979, 36.109992849300625],
              [-81.803906067486878, 36.109952983030958],
              [-81.803956115767662, 36.109688088801647],
              [-81.803291036930858, 36.1096083537605],
              [-81.803341085909821, 36.109343459769192],
              [-81.802343473329671, 36.109223850817656],
              [-81.802393526295944, 36.108958957144836],
              [-81.802060990496315, 36.108919085816645],
              [-81.801063385041402, 36.108799465503822],
              [-81.801013327768786, 36.109064357749631],
              [-81.80068079221725, 36.109024482671572],
              [-81.800630733546683, 36.10928937473529],
              [-81.800298197255628, 36.109249498621203],
              [-81.800248137186998, 36.109514390502753],
              [-81.79991560015651, 36.109474513352453],
              [-81.799865537579677, 36.109739405077363],
              [-81.799532999809713, 36.109699526891013],
              [-81.799482936945168, 36.109964418408367],
              [-81.799150398435614, 36.109924539185819],
              [-81.799100333062739, 36.11018943054637],
              [-81.798435254862724, 36.110109668223579],
              [-81.798385187023143, 36.110374559270397],
              [-81.798052647363932, 36.110334677071044],
              [-81.798002578126201, 36.110599567935814],
              [-81.797670037727599, 36.11055968470022],
              [-81.797619967091805, 36.110824575382807],
              [-81.796622344602852, 36.110704918052129],
              [-81.79657227043154, 36.11096980828939],
              [-81.795907187181513, 36.110890032275115],
              [-81.795857110543352, 36.111154922198615],
              [-81.795192027552261, 36.111075141376645],
              [-81.795141947368165, 36.111340031912505],
              [-81.794809405297869, 36.111300140013064],
              [-81.794759324794683, 36.111565029440463],
              [-81.794426780874772, 36.11152513653024],
              [-81.794376698973437, 36.11179002577542],
              [-81.794044154314136, 36.111750131829005],
              [-81.793994071045901, 36.112015021792878],
              [-81.793661526757489, 36.11197512678482],
              [-81.79356135481224, 36.112504904546441],
              [-81.792896261838294, 36.112425111315474],
              [-81.79284617434422, 36.11268999983173],
              [-81.792513626727484, 36.112650101740407],
              [-81.792463536724952, 36.112914990099732],
              [-81.791465893755444, 36.112795289978045],
              [-81.79141580021701, 36.113060177891803],
              [-81.791083252890886, 36.113020275893568],
              [-81.791033157954203, 36.1132851636251],
              [-81.790700608778508, 36.113245260615898],
              [-81.790750704783889, 36.112980373015986],
              [-81.790085610637391, 36.112900563620911],
              [-81.790035512494455, 36.113165450957553],
              [-81.788040230502858, 36.112926002099115],
              [-81.787990125617796, 36.113190888595184],
              [-81.787325032468715, 36.113111064199977],
              [-81.787274924006226, 36.113375950407409],
              [-81.78594473738778, 36.113216290235137],
              [-81.785894624320761, 36.113481175865232],
              [-81.785229530851609, 36.11340134008816],
              [-81.785179414207221, 36.113666225429512],
              [-81.784514319887791, 36.113586384869897],
              [-81.784464201886522, 36.113851269871923],
              [-81.783799106779256, 36.113771426331375],
              [-81.78374898520056, 36.114036311044579],
              [-81.783416437612217, 36.113996387322722],
              [-81.783366314635117, 36.114261271853451],
              [-81.782036121114061, 36.11410156834463],
              [-81.78198599353226, 36.114366452297908],
              [-81.780655799869862, 36.114206732913075],
              [-81.780605667683361, 36.114471616288782],
              [-81.780273119855991, 36.114431684030443],
              [-81.780222985160734, 36.114696567248927],
              [-81.779890436594357, 36.114656633954269],
              [-81.779840301579711, 36.114921516064214],
              [-81.779507751164132, 36.114881581758446],
              [-81.77945761478216, 36.115146464586786],
              [-81.77912506362749, 36.115106529244727],
              [-81.779074924736605, 36.115371411915802],
              [-81.77874237395325, 36.1153314755122],
              [-81.778692233632839, 36.115596357099896],
              [-81.778359681000296, 36.11555641968517],
              [-81.77830954042264, 36.115821301966029],
              [-81.777976987051105, 36.115781363514998],
              [-81.777926843933585, 36.116046244737674],
              [-81.777594289823, 36.116006305250266],
              [-81.777544146448292, 36.11627118716612],
              [-81.777211591598729, 36.1162312466424],
              [-81.777161445683973, 36.116496127500049],
              [-81.776828890095558, 36.116456185940038],
              [-81.776778742813264, 36.116721067516032],
              [-81.77644618645482, 36.116681124018704],
              [-81.776396037774006, 36.116946005412082],
              [-81.776063480707592, 36.11690606177936],
              [-81.776013330596996, 36.117170942089366],
              [-81.775348214205934, 36.117091051871633],
              [-81.775298061628007, 36.11735593186733],
              [-81.774300387943441, 36.117236088422246],
              [-81.774250230718877, 36.117500967997024],
              [-81.773917672425426, 36.117461018541277],
              [-81.773867514912496, 36.117725897908294],
              [-81.773534955880152, 36.117685947416142],
              [-81.773484795858195, 36.117950826625737],
              [-81.773152236086986, 36.117910875097273],
              [-81.773102074666326, 36.118175754124195],
              [-81.772769514156153, 36.118135801559269],
              [-81.772669189297972, 36.118665559171987],
              [-81.772336625869798, 36.118625605464054],
              [-81.772286461321826, 36.118890484074932],
              [-81.771953898265124, 36.118850529305526],
              [-81.771903732318378, 36.119115407733673],
              [-81.771238603915975, 36.119035494341588],
              [-81.771188435501656, 36.119300372455378],
              [-81.768860489514665, 36.119020644800806],
              [-81.768810313288739, 36.119285521941151],
              [-81.768145186520101, 36.119205590837701],
              [-81.768095006747259, 36.119470468589462],
              [-81.767762443344395, 36.119430501536485],
              [-81.767712262141643, 36.119695378204725],
              [-81.767379698000028, 36.119655410115236],
              [-81.76732951539843, 36.119920286600745],
              [-81.766996949407726, 36.11988031749992],
              [-81.766946765407155, 36.120145193802713],
              [-81.766614199788179, 36.120105223640323],
              [-81.76656401438872, 36.1203700997604],
              [-81.765898880892991, 36.120290156483243],
              [-81.765848693025902, 36.120555032288649],
              [-81.76518355760264, 36.120475085154439],
              [-81.765233748717691, 36.120210209587526],
              [-81.764568617862778, 36.12013025907337],
              [-81.764618809674928, 36.119865383744326],
              [-81.764286245256272, 36.119825407274782],
              [-81.764236052375423, 36.120090282471949],
              [-81.763903488328495, 36.120050304940733],
              [-81.763853292938165, 36.120315179980246],
              [-81.763188162555977, 36.120235221064462],
              [-81.763137965808369, 36.12050009576425],
              [-81.762805399503961, 36.120460115280551],
              [-81.762705001770314, 36.120989864288731],
              [-81.762372433658484, 36.12094988263668],
              [-81.762272031356105, 36.121479631202455],
              [-81.761606892927304, 36.121399663731111],
              [-81.761657096381199, 36.121134789737418],
              [-81.761324528191281, 36.121094805239586],
              [-81.761374732383757, 36.120829931326874],
              [-81.760377035191311, 36.120709971850474],
              [-81.760427242259922, 36.12044509828246],
              [-81.759762114206495, 36.120365120746861],
              [-81.759711905000358, 36.120629994051114],
              [-81.759046776130475, 36.120550012633352],
              [-81.758996563346074, 36.120814885647938],
              [-81.758663998877552, 36.120774892987235],
              [-81.758613784694219, 36.121039765818971],
              [-81.755953269047268, 36.120719793690164],
              [-81.75590304598353, 36.120984665415556],
              [-81.755237917936839, 36.12090466240403],
              [-81.755187692405258, 36.121169533814687],
              [-81.75452256354238, 36.121089526920869],
              [-81.754572791211459, 36.120824655774079],
              [-81.753242542866388, 36.120664632534783],
              [-81.753292774480101, 36.120399761864803],
              [-81.752295095706174, 36.120279734638643],
              [-81.752244859776013, 36.120544604937713],
              [-81.751912299776805, 36.120504593921147],
              [-81.75186206241672, 36.120769463136355],
              [-81.751196941272241, 36.120689438125567],
              [-81.751247180738957, 36.120424568273329],
              [-81.750914621731027, 36.120384554543044],
              [-81.750964860825633, 36.120119684796798],
              [-81.750632303216946, 36.120079670293848],
              [-81.750682544160171, 36.11981480060372],
              [-81.750349987950514, 36.119774785328154],
              [-81.750450471013764, 36.119245046959882],
              [-81.74978536494018, 36.119165013297099],
              [-81.749735121451835, 36.119429882693218],
              [-81.749402567302226, 36.119389864836002],
              [-81.749352321304372, 36.119654734074089],
              [-81.749019767496165, 36.119614714254439],
              [-81.748919273482059, 36.120144452288933],
              [-81.748586716787386, 36.120104432226583],
              [-81.748486219283109, 36.12063416889243],
              [-81.748153660781639, 36.120594147661429],
              [-81.748103411004834, 36.12085901532302],
              [-81.747770851765196, 36.120818993055543],
              [-81.747720599509634, 36.121083861460001],
              [-81.747388039500976, 36.121043837254952],
              [-81.747337785846199, 36.121308705476359],
              [-81.746672664713913, 36.121228654989515],
              [-81.746622408591108, 36.121493522895989],
              [-81.744627045574262, 36.12125334805733],
              [-81.744677308139828, 36.12098848184376],
              [-81.744344750037527, 36.120948449365564],
              [-81.744395013341176, 36.120683583233117],
              [-81.743064788543421, 36.120523445703476],
              [-81.743014520965133, 36.120788311307706],
              [-81.741684297182744, 36.120628158776817],
              [-81.741634023919516, 36.120893024727728],
              [-81.739638692818133, 36.120652766625831],
              [-81.739588412812395, 36.120917631733356],
              [-81.739255857532612, 36.120877585422463],
              [-81.739155293250576, 36.121407314344353],
              [-81.738822736164224, 36.121367266864738],
              [-81.738671885705145, 36.122161858981073],
              [-81.73833932463306, 36.122121810225508],
              [-81.738289039437035, 36.122386674630697],
              [-81.738621600467368, 36.122426723543207],
              [-81.738571315978632, 36.122691587128621],
              [-81.738238753879628, 36.122651538084114],
              [-81.738188466881056, 36.122916401511198],
              [-81.738521031159152, 36.122956450662983],
              [-81.73847074492916, 36.123221315072087],
              [-81.738138180692772, 36.12318126576335],
              [-81.738087894143121, 36.123446129063566],
              [-81.737755328058398, 36.123406078742995],
              [-81.73770504010929, 36.123670941860077],
              [-81.737039907905142, 36.123590838216231],
              [-81.737090197991634, 36.123325975363322],
              [-81.735759942907421, 36.123165756823241],
              [-81.735810238017734, 36.122900893521937],
              [-81.734812553098791, 36.122780720296056],
              [-81.734862851114769, 36.122515858240938],
              [-81.734530291585713, 36.122475799113943],
              [-81.734580590308937, 36.122210936239078],
              [-81.734248032179352, 36.122170876339645],
              [-81.734197732356904, 36.122435738181572],
              [-81.733200055606346, 36.122315551783508],
              [-81.733149752247144, 36.122580413178035],
              [-81.732152076378753, 36.122460218217689],
              [-81.732202382913343, 36.122195356318677],
              [-81.731537269449191, 36.122115221786331],
              [-81.731486960777431, 36.122380083421064],
              [-81.729824177519006, 36.122179729248373],
              [-81.729874492674853, 36.121914869150487],
              [-81.729209383812915, 36.121834721053531],
              [-81.72915906655048, 36.122099581787921],
              [-81.728826511547325, 36.122059506250487],
              [-81.728776192854824, 36.122324365900774],
              [-81.72844363711404, 36.122284289326622],
              [-81.728393317052507, 36.122549149694635],
              [-81.728060760574081, 36.122509072083687],
              [-81.728010439082453, 36.122773931367583],
              [-81.727677881866484, 36.122733852719932],
              [-81.727627557864864, 36.122998711845341],
              [-81.72729499991118, 36.122958632160945],
              [-81.72724467565088, 36.123223491979225],
              [-81.726579558599312, 36.123143329632512],
              [-81.726529230729852, 36.123408188259205],
              [-81.726196671631968, 36.123368105596782],
              [-81.726247000570041, 36.123103247102335],
              [-81.725581885504653, 36.123023079328199],
              [-81.725632215107979, 36.122758220170923],
              [-81.72529965914039, 36.122718135059237],
              [-81.725400321772938, 36.122188418608083],
              [-81.725067768273632, 36.122148332856234],
              [-81.725168431688687, 36.121618615564351],
              [-81.724835880657452, 36.121578529172389],
              [-81.724886212952214, 36.121313671032482],
              [-81.725218762914892, 36.121353757292177],
              [-81.725420083368732, 36.120294322817365],
              [-81.725087538790575, 36.120254237061673],
              [-81.725137868289323, 36.119989377790468],
              [-81.724805325110694, 36.119949291262479],
              [-81.724855656487819, 36.119684432948674],
              [-81.724523114708717, 36.119644345648396],
              [-81.724573446792803, 36.119379486514859],
              [-81.722910747064603, 36.119179036230939],
              [-81.722961085300994, 36.118914178583722],
              [-81.72262854764513, 36.118874086120897],
              [-81.722578208340266, 36.11913894363596],
              [-81.721913132964815, 36.119058754806829],
              [-81.721862791222478, 36.119323612907316],
              [-81.721530252392554, 36.119283516565652],
              [-81.721479910360941, 36.119548374458191],
              [-81.721147370793545, 36.119508277079788],
              [-81.721097026252068, 36.119773134813784],
              [-81.720764485947399, 36.119733036398664],
              [-81.720663794814001, 36.120262750523473],
              [-81.720331252703389, 36.120222650939375],
              [-81.720280905031686, 36.120487508255785],
              [-81.719948362183729, 36.120447407634934],
              [-81.719898013081874, 36.120712263867169],
              [-81.719565469526998, 36.120672163110449],
              [-81.719515119025502, 36.120937019159413],
              [-81.719182574733281, 36.120896917365926],
              [-81.719132222832144, 36.121161773231627],
              [-81.71879967666186, 36.121121669525181],
              [-81.718648615796184, 36.121916237319795],
              [-81.718316067861863, 36.121876132287433],
              [-81.71826571249899, 36.122140987684233],
              [-81.718598261501825, 36.122181092848926],
              [-81.718547906876296, 36.122445948326956],
              [-81.718215355694525, 36.122405843054707],
              [-81.718114643312816, 36.122935553593017],
              [-81.71778209035557, 36.122895448052574],
              [-81.717731732599361, 36.12316030311284],
              [-81.717399178874388, 36.123120195634719],
              [-81.717248099302395, 36.123914760136628],
              [-81.716915542702992, 36.123874651357248],
              [-81.716663731078683, 36.125198923851514],
              [-81.716996292990586, 36.125239032391349],
              [-81.716895567937229, 36.125768741300547],
              [-81.716563002747264, 36.125728631619864],
              [-81.71646227423166, 36.12625834005987],
              [-81.716129708346187, 36.126218229185405],
              [-81.7160289752578, 36.126747937180845],
              [-81.715696407566369, 36.12670782513726],
              [-81.715646039426218, 36.12697267802502],
              [-81.715978609296755, 36.127012790176224],
              [-81.715777137789004, 36.128072204447015],
              [-81.716109712234953, 36.1281123153218],
              [-81.716059345716545, 36.128377169094342],
              [-81.715394194988107, 36.128296945274691],
              [-81.71534382486017, 36.12856179785522],
              [-81.715011248908993, 36.128521684005761],
              [-81.714960877411457, 36.128786537303661],
              [-81.714295724365726, 36.128706306626462],
              [-81.714245350399494, 36.128971159608476],
              [-81.713912773305054, 36.128931042780749],
              [-81.713761645040904, 36.129725599245518],
              [-81.713429065071665, 36.129685481116304],
              [-81.713227551085737, 36.130744889106182],
              [-81.712894967173042, 36.130704769543115],
              [-81.712794206023858, 36.131234472064961],
              [-81.712461619194684, 36.131194351357287],
              [-81.712159321442158, 36.132783458699478],
              [-81.711826728531804, 36.13274333629338],
              [-81.711776342778933, 36.133008186917181],
              [-81.711725956694636, 36.133273037489644],
              [-81.712058551742558, 36.133313160160384],
              [-81.711957781827707, 36.133842861391201],
              [-81.712290379344623, 36.133882983421813],
              [-81.71213922463788, 36.134677535292241],
              [-81.71247182569266, 36.134717656815148],
              [-81.712371056738846, 36.135247358061598],
              [-81.712703660262619, 36.135287478944434],
              [-81.712149416269099, 36.13820083274075],
              [-81.711816799877113, 36.138160710427336],
              [-81.711766410701742, 36.138425560420231],
              [-81.711433793572226, 36.138385437069758],
              [-81.711383402966135, 36.138650285977995],
              [-81.71105078398854, 36.138610161615084],
              [-81.711000392012366, 36.138875011240472],
              [-81.710667773408062, 36.138834885815953],
              [-81.710617380001054, 36.139099734356677],
              [-81.710284759548699, 36.139059607919812],
              [-81.709780793406395, 36.141708094651008],
              [-81.709448162595947, 36.141667965986237],
              [-81.709347362770302, 36.142197661378468],
              [-81.709014730153427, 36.142157531544321],
              [-81.708964328149278, 36.142422379944009],
              [-81.708631694764563, 36.142382248171906],
              [-81.708581292470527, 36.142647096363099],
              [-81.708248658378793, 36.142606964454814],
              [-81.708198253543046, 36.142871811585856],
              [-81.707865618713896, 36.142831678640505],
              [-81.707764805909704, 36.143361372483241],
              [-81.707432169274114, 36.143321238368451],
              [-81.707381761305626, 36.143586085080138],
              [-81.707049122821843, 36.143545949952923],
              [-81.706948303751957, 36.144075642956807],
              [-81.706615664572524, 36.144035506635582],
              [-81.706514842037933, 36.144565199168539],
              [-81.706182199941338, 36.14452506170236],
              [-81.706030960445091, 36.145319599717652],
              [-81.705698315472901, 36.145279460949759],
              [-81.705647899464353, 36.14554430674378],
              [-81.705315254835213, 36.145504166013339],
              [-81.705264837456227, 36.145769012524269],
              [-81.7049321920897, 36.145728870756699],
              [-81.704881773279666, 36.145993716182844],
              [-81.704549126095387, 36.145953574303498],
              [-81.704498706995224, 36.146218419521098],
              [-81.70383341142302, 36.146138130981626],
              [-81.703782988742347, 36.146402975907456],
              [-81.702785045835924, 36.146282537692471],
              [-81.702734619616308, 36.14654738216953],
              [-81.702069323085126, 36.146467084052766],
              [-81.702119751442908, 36.146202239840441],
              [-81.700789167778879, 36.146041634155388],
              [-81.700738735144867, 36.146306477838088],
              [-81.700073442595013, 36.146226168863961],
              [-81.700023007491112, 36.146491012230335],
              [-81.699025069088648, 36.146370542462464],
              [-81.698974629304828, 36.146635384503597],
              [-81.697976690651387, 36.146514905294651],
              [-81.69792624735878, 36.146779747787789],
              [-81.697260953703918, 36.14669942354282],
              [-81.69721050794135, 36.146964265719468],
              [-81.696877860542628, 36.146924102107334],
              [-81.696827413379154, 36.147188944099881],
              [-81.696494765243344, 36.147148779450646],
              [-81.696444316648751, 36.147413620358122],
              [-81.696111667775696, 36.147373454671609],
              [-81.69606121781031, 36.147638296295916],
              [-81.695728568200124, 36.147598129572273],
              [-81.695678116833776, 36.147862971012458],
              [-81.695345466456359, 36.14782280235066],
              [-81.695295013689034, 36.148087643606694],
              [-81.694962362604556, 36.148047474808564],
              [-81.694911907325462, 36.14831231590491],
              [-81.694579255473784, 36.148272145168747],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 35,
        ORIG_FID: 4,
        AAPC_Examp: 11,
        Area_mile2: 1155,
        BASIN_NAME: "Lake Wylie",
        Res_Basin: "Wylie",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.315898550166693, 35.739935520021554],
              [-81.312591931826077, 35.73952309335705],
              [-81.312643445154478, 35.739258335507991],
              [-81.309667528746942, 35.738887072547399],
              [-81.309719053447537, 35.738622315840743],
              [-81.309388398935383, 35.738581060142558],
              [-81.309439923249002, 35.738316303549489],
              [-81.309109270129341, 35.738275047089267],
              [-81.309160796240775, 35.73801028966674],
              [-81.308830144513607, 35.73796903244461],
              [-81.309190820924272, 35.736115733903169],
              [-81.308860176921627, 35.736074476738025],
              [-81.308911702472656, 35.735809720138995],
              [-81.308581060941933, 35.735768461289837],
              [-81.307258497161854, 35.735603419633158],
              [-81.307310026521378, 35.735338662792664],
              [-81.306318111214438, 35.735214872285312],
              [-81.306369644533675, 35.734950116690747],
              [-81.306039007810469, 35.734908850927141],
              [-81.306090541822044, 35.734644094524171],
              [-81.30575990651694, 35.734602828899902],
              [-81.305811441246647, 35.734338072589679],
              [-81.305480807333836, 35.734296806203488],
              [-81.305532342781731, 35.734032049985949],
              [-81.305201710235664, 35.733990781936782],
              [-81.305253246427213, 35.733726026713192],
              [-81.304591985562396, 35.733643488171801],
              [-81.304643523527233, 35.733378733177439],
              [-81.304312894103191, 35.733337462706231],
              [-81.304364433865615, 35.733072706882652],
              [-81.3037031792703, 35.732990164398146],
              [-81.303754719700777, 35.732725408824834],
              [-81.303424094503853, 35.73268413591056],
              [-81.303475635652333, 35.732419380430045],
              [-81.302814387300785, 35.732336833101442],
              [-81.302865931327617, 35.732072077829379],
              [-81.301874065009045, 35.731948249146342],
              [-81.301925611864178, 35.731683494240166],
              [-81.301264372184932, 35.731600937552187],
              [-81.301212823219515, 35.731865692185274],
              [-81.300882202830579, 35.731824412357071],
              [-81.300933751745987, 35.731559657881562],
              [-81.29928066013548, 35.731353245948647],
              [-81.299229104839213, 35.731617999762172],
              [-81.298898486473789, 35.731576714543955],
              [-81.298846929785029, 35.731841468177159],
              [-81.298516310701743, 35.731800181923994],
              [-81.29846475262066, 35.73206493537684],
              [-81.298134132819541, 35.73202364808882],
              [-81.298082573346022, 35.732288401361338],
              [-81.297751951721892, 35.732247113059138],
              [-81.297700390855923, 35.732511866151299],
              [-81.297039148693969, 35.73242928563581],
              [-81.296987584275229, 35.73269403843188],
              [-81.294342619600485, 35.732363680264733],
              [-81.294291046403345, 35.732628431924162],
              [-81.293960426429777, 35.732587133309302],
              [-81.294012000682059, 35.732322381786588],
              [-81.293020145899931, 35.732198479159862],
              [-81.293071724110831, 35.731933728883583],
              [-81.292410491369708, 35.731851122313209],
              [-81.292358911074132, 35.732115873217374],
              [-81.291036446576783, 35.731950650531552],
              [-81.290984861723416, 35.732215400845256],
              [-81.290323629916301, 35.732132782927501],
              [-81.290272042615285, 35.732397532924104],
              [-81.289610808942811, 35.732314911160486],
              [-81.289559219194047, 35.73257966083996],
              [-81.288897984761363, 35.732497035209647],
              [-81.288949576645678, 35.732232286704573],
              [-81.286304663066431, 35.731901749302132],
              [-81.286356264133488, 35.731637000924977],
              [-81.286025652233704, 35.731595679849129],
              [-81.286077254018153, 35.731330931564827],
              [-81.285746643511061, 35.731289609727362],
              [-81.285798246012988, 35.731024861536092],
              [-81.285467636898474, 35.730983538937018],
              [-81.285570842999661, 35.7304540426966],
              [-81.28524023633274, 35.730412719472646],
              [-81.285343443193668, 35.729883223330589],
              [-81.28501284007946, 35.729841899461036],
              [-81.285064442953413, 35.729577151481877],
              [-81.284733841231755, 35.729535826850778],
              [-81.284785445928264, 35.729271078943867],
              [-81.284454845573705, 35.729229752649985],
              [-81.284506450987593, 35.728965004836077],
              [-81.284175852051021, 35.728923678681852],
              [-81.284124245582163, 35.72918842635903],
              [-81.28346304658632, 35.729105770181164],
              [-81.283411437695392, 35.729370518442273],
              [-81.283080837636234, 35.729329188418646],
              [-81.283029227327305, 35.729593935598174],
              [-81.282037426061407, 35.729469941529615],
              [-81.281985812249914, 35.729734688255199],
              [-81.281655211456751, 35.72969335526615],
              [-81.281706826323116, 35.729428608677253],
              [-81.279392638613373, 35.729139249933972],
              [-81.279341015257174, 35.729403995586196],
              [-81.278018625945009, 35.729238626771469],
              [-81.278070253520809, 35.728973881666334],
              [-81.275094907616861, 35.728601749374306],
              [-81.275146545454021, 35.728337005435932],
              [-81.273824183223624, 35.728171590084756],
              [-81.273772541166991, 35.728436333475848],
              [-81.272780770403756, 35.728312261209091],
              [-81.272832415625075, 35.728047518228458],
              [-81.27250182747801, 35.728006159440085],
              [-81.272450181201748, 35.728270902283882],
              [-81.270466650849372, 35.728022729009872],
              [-81.270518303429284, 35.727757986085905],
              [-81.270187717647175, 35.727716621009954],
              [-81.270239370969321, 35.727451879080306],
              [-81.269578202503126, 35.727369145606353],
              [-81.269629857596925, 35.727104403906679],
              [-81.269299274912825, 35.727063035508614],
              [-81.26935093182874, 35.726798293881473],
              [-81.269020350562599, 35.726756925623278],
              [-81.269072008195408, 35.726492184089288],
              [-81.268410848735783, 35.726409443349731],
              [-81.268462508140246, 35.726144702045787],
              [-81.26780135216714, 35.726061958888231],
              [-81.267853013343228, 35.725797217814318],
              [-81.267522436905679, 35.7257558445746],
              [-81.26757409879859, 35.725491103593889],
              [-81.267243524858813, 35.725449729572304],
              [-81.267295187468548, 35.725184988684681],
              [-81.266964613816342, 35.725143613922455],
              [-81.267016277142872, 35.724878873128105],
              [-81.266685705988323, 35.72483749758387],
              [-81.266789032606738, 35.724308015257357],
              [-81.266458463899653, 35.724266639088782],
              [-81.266510128321912, 35.724001898437194],
              [-81.266179561007348, 35.72396052150728],
              [-81.26623122612105, 35.723695780047791],
              [-81.265900660199222, 35.723654402356701],
              [-81.265952326054915, 35.723389661891581],
              [-81.265621761525694, 35.723348283439222],
              [-81.265570094640466, 35.723613024668595],
              [-81.26523952939462, 35.723571645181167],
              [-81.265291197334435, 35.723306904088716],
              [-81.264960633481024, 35.723265523840077],
              [-81.264908964486537, 35.723530264795613],
              [-81.263256145016115, 35.723323349395649],
              [-81.263307819309119, 35.723058610025802],
              [-81.262977258588634, 35.723017224367688],
              [-81.263028933573196, 35.722752484190046],
              [-81.262698373140296, 35.722711097791247],
              [-81.262801726414722, 35.722181617537672],
              [-81.262471168429087, 35.72214023051459],
              [-81.262574521380813, 35.721610751281986],
              [-81.262243966922341, 35.721569362713055],
              [-81.262295644510914, 35.721304623608553],
              [-81.261965090340041, 35.721263234299009],
              [-81.262016768645196, 35.720998495287809],
              [-81.261686216971853, 35.72095710519654],
              [-81.261789573522009, 35.720427625535386],
              [-81.261459024321113, 35.720386235721044],
              [-81.261562381628195, 35.719856756159437],
              [-81.260240196615925, 35.719691188115476],
              [-81.260291879533298, 35.71942644880675],
              [-81.258969705252596, 35.71926086692072],
              [-81.259021392024778, 35.718996127215199],
              [-81.25703814758765, 35.718747726903771],
              [-81.25670760767558, 35.718706324166014],
              [-81.256655912391722, 35.718971062032075],
              [-81.255994832552972, 35.71888825266678],
              [-81.255943134822004, 35.719152990215264],
              [-81.255612594895183, 35.71911158358774],
              [-81.255560895771595, 35.719376320955618],
              [-81.255230354048607, 35.719334914214627],
              [-81.255178653532383, 35.719599651402007],
              [-81.254517568941509, 35.719516833149463],
              [-81.254465865978148, 35.719781570019229],
              [-81.254135323160597, 35.719740160310003],
              [-81.254083618804557, 35.720004896999079],
              [-81.253422533129708, 35.719922072789721],
              [-81.253370825221467, 35.720186809181612],
              [-81.253040281284356, 35.720145395603019],
              [-81.252988573088444, 35.720410131793827],
              [-81.252658028435036, 35.720368717180136],
              [-81.252606318846404, 35.720633453190288],
              [-81.252275773476597, 35.720592037541515],
              [-81.252327484119732, 35.720327301668377],
              [-81.251996940142746, 35.720285885258562],
              [-81.252048652632254, 35.7200211503595],
              [-81.251718110047975, 35.71997973318863],
              [-81.25176982322867, 35.71971499748178],
              [-81.251439282037026, 35.719673579549962],
              [-81.251490995934063, 35.719408843936549],
              [-81.251160456135111, 35.719367425243689],
              [-81.251212170748303, 35.719102689723691],
              [-81.250881632342143, 35.719061270269734],
              [-81.250933347671563, 35.718796534843236],
              [-81.250602810633026, 35.718755113727156],
              [-81.250654526678659, 35.718490378394115],
              [-81.250323992162862, 35.718448957397641],
              [-81.250375708924707, 35.718184222158122],
              [-81.250045174696496, 35.718142800421084],
              [-81.249993457985227, 35.718407535503275],
              [-81.24966292304093, 35.718366112731232],
              [-81.249611203831975, 35.718630847653131],
              [-81.249280669276501, 35.71858942382557],
              [-81.249228948674855, 35.718854158566813],
              [-81.248898412298118, 35.718812733724569],
              [-81.248846690303751, 35.719077468285164],
              [-81.248516154315951, 35.719036042387401],
              [-81.248464429823855, 35.719300776787733],
              [-81.248133893119899, 35.719259349854859],
              [-81.248082168340062, 35.719524084054186],
              [-81.247090554859554, 35.719399796595773],
              [-81.247038826578077, 35.719664530340204],
              [-81.244725066970858, 35.719374493265036],
              [-81.244673329864966, 35.719639226026715],
              [-81.242359580512314, 35.719349142165363],
              [-81.242411326128973, 35.719084411244275],
              [-81.240758663591791, 35.718877181841691],
              [-81.240551660291047, 35.719936105184445],
              [-81.239890588979165, 35.719853205683805],
              [-81.239942342992165, 35.719588475853541],
              [-81.239281275143028, 35.719505573035036],
              [-81.239229519046276, 35.719770303492169],
              [-81.237246318401859, 35.719521572640936],
              [-81.237194555615375, 35.719786301330444],
              [-81.236864022673487, 35.719744842901555],
              [-81.236760492920908, 35.720274301697678],
              [-81.236429958208717, 35.720232842096458],
              [-81.236378191192784, 35.720497570400852],
              [-81.236047655764878, 35.720456109764442],
              [-81.235386584819764, 35.720373185817813],
              [-81.235438354973965, 35.720108457023777],
              [-81.234777287491866, 35.720025529759418],
              [-81.234829059416214, 35.719760801196088],
              [-81.234498527789995, 35.719719336343907],
              [-81.234550299325065, 35.719454607894306],
              [-81.233889239172171, 35.719371674869173],
              [-81.233941013607236, 35.719106947531216],
              [-81.233610485080661, 35.719065479358271],
              [-81.233662260206572, 35.718800751212719],
              [-81.233331733097813, 35.718759283180091],
              [-81.233435284442336, 35.718229827032381],
              [-81.233104759780844, 35.718188358376153],
              [-81.233156537104534, 35.717923630353788],
              [-81.232826013835947, 35.717882160936782],
              [-81.232877790770317, 35.717617433028281],
              [-81.232547268869808, 35.71757596194935],
              [-81.232599046519837, 35.717311234134456],
              [-81.232268527141983, 35.717269763175622],
              [-81.23232030550767, 35.717005035454278],
              [-81.23198978749798, 35.716963562833485],
              [-81.232041566579284, 35.71669883520574],
              [-81.231711048857505, 35.716657361844518],
              [-81.231762829759418, 35.716392634290152],
              [-81.231432313455286, 35.716351161069326],
              [-81.231484095072901, 35.716086433608552],
              [-81.231153580136819, 35.716044958725838],
              [-81.231205362469936, 35.715780231358622],
              [-81.230874850031796, 35.715738755694908],
              [-81.230926631975521, 35.715474028441662],
              [-81.230596120930159, 35.715432552017234],
              [-81.230647903589457, 35.715167824857637],
              [-81.230317393937071, 35.715126347672502],
              [-81.230369178416879, 35.714861620586298],
              [-81.230038670157342, 35.714820142640441],
              [-81.230090455352681, 35.714555415647816],
              [-81.229759948486006, 35.714513936941266],
              [-81.229811733291925, 35.714249210062725],
              [-81.229150723787797, 35.714166250209971],
              [-81.229202510363407, 35.713901523562264],
              [-81.228872007725954, 35.713860042416144],
              [-81.228923796097163, 35.713595314940761],
              [-81.228593293772519, 35.713553833955359],
              [-81.228645082859202, 35.713289106573598],
              [-81.22831458190258, 35.713247623926449],
              [-81.228366371729535, 35.712982897539526],
              [-81.22770537404547, 35.712899929805658],
              [-81.227757165617206, 35.712635202748658],
              [-81.227426668889763, 35.712593717662003],
              [-81.227478461201812, 35.712328991599861],
              [-81.226817469766416, 35.712246019027582],
              [-81.226869263823104, 35.711981292295299],
              [-81.226208276955688, 35.711898316385785],
              [-81.226260072781841, 35.711633589884435],
              [-81.225929580910346, 35.711592100720118],
              [-81.225981377451859, 35.711327374312624],
              [-81.225650888078093, 35.711285884367477],
              [-81.225702685334852, 35.711021158053754],
              [-81.225041707501816, 35.710938175784818],
              [-81.225093506528069, 35.710673449702149],
              [-81.224763020278615, 35.710631957337945],
              [-81.224814820020185, 35.710367231349011],
              [-81.22415384951583, 35.710284243320622],
              [-81.22420565105152, 35.710019518464001],
              [-81.223544684010236, 35.709936527118785],
              [-81.223596488395316, 35.709671801571922],
              [-81.223266005896818, 35.70963030515049],
              [-81.223317810997173, 35.709365579697455],
              [-81.222987329866697, 35.709324081614326],
              [-81.223090941159086, 35.70879463085236],
              [-81.222760462500034, 35.708753133047146],
              [-81.222812268692067, 35.708488407738358],
              [-81.222151316683338, 35.708405408767504],
              [-81.222203124644551, 35.708140683689841],
              [-81.221872649085142, 35.708099182564595],
              [-81.221924457761503, 35.707834457580816],
              [-81.221263512001357, 35.707751453772261],
              [-81.221315322446841, 35.707486729019685],
              [-81.21999344028815, 35.707320710256724],
              [-81.220045254610568, 35.707055986009962],
              [-81.21971478568949, 35.707014478991852],
              [-81.219922044886658, 35.70595558119868],
              [-81.219591580520031, 35.705914073832112],
              [-81.219643396336281, 35.7056493487216],
              [-81.21969521073359, 35.705384624489106],
              [-81.220025671862928, 35.705426130700062],
              [-81.220232921799237, 35.704367230984381],
              [-81.220563380156804, 35.704408735728066],
              [-81.220615189647603, 35.704144011024113],
              [-81.220284733423981, 35.704102505496465],
              [-81.220388355657093, 35.703573054390453],
              [-81.220057901904639, 35.703531549140934],
              [-81.220368766796184, 35.701943195606759],
              [-81.220420576425823, 35.701678469866089],
              [-81.220090131468879, 35.701636963758823],
              [-81.220193750689731, 35.701107511541203],
              [-81.219863308203813, 35.701066005712093],
              [-81.219966929281895, 35.700536553575795],
              [-81.219636489217635, 35.700495046222422],
              [-81.21968830031463, 35.700230320677299],
              [-81.219357861642891, 35.700188812563546],
              [-81.219409673454749, 35.699924087112507],
              [-81.219079236175503, 35.699882578238409],
              [-81.219131048702408, 35.699617852881417],
              [-81.218800612815656, 35.699576343246946],
              [-81.218904238935878, 35.699046891776661],
              [-81.218573805495339, 35.6990053815192],
              [-81.218625619088542, 35.698740655405999],
              [-81.21829518814522, 35.698699144368028],
              [-81.218450630078181, 35.697904967082081],
              [-81.218120202634566, 35.69786345555859],
              [-81.218275644679608, 35.697069278295601],
              [-81.217945220735757, 35.697027766286475],
              [-81.218100662892638, 35.696233589046621],
              [-81.217770242448537, 35.696192076551981],
              [-81.217873870950058, 35.6956626245167],
              [-81.217543452951872, 35.695621111399063],
              [-81.217595268852762, 35.695356385433904],
              [-81.217264852222272, 35.69531487065484],
              [-81.217368482905528, 35.69478541890993],
              [-81.217698897404162, 35.694826932513102],
              [-81.217750711184053, 35.694562206438562],
              [-81.217420298843777, 35.694520692952608],
              [-81.217731180681071, 35.69293233462097],
              [-81.217400775000868, 35.69289082106166],
              [-81.217504401668791, 35.692361367913669],
              [-81.217173998434291, 35.692319853731568],
              [-81.217692123039512, 35.689672584957123],
              [-81.217361731783271, 35.689631071231041],
              [-81.217517165341576, 35.688836888464778],
              [-81.217186776479664, 35.688795374273397],
              [-81.21749764079604, 35.68720700930448],
              [-81.217828022232865, 35.687248522691661],
              [-81.217931638863433, 35.686719067383557],
              [-81.217601259508697, 35.686677553369982],
              [-81.217653068369316, 35.686412825788807],
              [-81.216992313936515, 35.686329797126],
              [-81.21704412454045, 35.686065068875472],
              [-81.21671374833322, 35.686023553345137],
              [-81.216765559651719, 35.685758825189033],
              [-81.216435184836541, 35.68571730889871],
              [-81.21638337246462, 35.685982036917423],
              [-81.216052998014732, 35.685940518670826],
              [-81.216104811464547, 35.685675791690599],
              [-81.215774437302159, 35.685634272704],
              [-81.21582625146668, 35.685369545818247],
              [-81.215495879800912, 35.685328026051415],
              [-81.215165507393877, 35.685286506308316],
              [-81.215269139999876, 35.684757052036311],
              [-81.214938771118199, 35.68471553074928],
              [-81.214990587426442, 35.68445080414687],
              [-81.21466021993669, 35.684409282099708],
              [-81.214712036959483, 35.684144555591701],
              [-81.214763853618891, 35.683879828139659],
              [-81.214433488574542, 35.683838305469855],
              [-81.214537124112852, 35.683308851592827],
              [-81.21420676151368, 35.683267328300346],
              [-81.214258580367925, 35.683002600974227],
              [-81.21392821918549, 35.682961077822846],
              [-81.213980037649705, 35.682696350611344],
              [-81.213649677834525, 35.682654825798743],
              [-81.213701498117743, 35.682390098661628],
              [-81.213371139719271, 35.682348573990147],
              [-81.213422960716954, 35.682083846947585],
              [-81.213092603685752, 35.682042320614869],
              [-81.213144425397886, 35.681777593666759],
              [-81.212483714482985, 35.681694539484965],
              [-81.21253553796268, 35.68142981276889],
              [-81.21220518515878, 35.681388283998587],
              [-81.212257008248486, 35.681123557397143],
              [-81.21192665683644, 35.681082027866807],
              [-81.212030306314972, 35.680552574769969],
              [-81.211699956243578, 35.680511044637186],
              [-81.211959078123243, 35.679187410049813],
              [-81.211628734760822, 35.679145879686729],
              [-81.211680559404329, 35.678881153134583],
              [-81.211350217433903, 35.678839622011537],
              [-81.211402042767148, 35.678574894652868],
              [-81.211071702213133, 35.678533363671001],
              [-81.211123528260785, 35.678268636406877],
              [-81.210793189074039, 35.678227103763874],
              [-81.210845015835943, 35.677962376594451],
              [-81.210514679170117, 35.677920844072595],
              [-81.21056650664643, 35.677656116997795],
              [-81.210236170243419, 35.677614582834913],
              [-81.210287998433927, 35.677349855854779],
              [-81.209957664527238, 35.677308320911884],
              [-81.210009493456624, 35.677043594927547],
              [-81.209679159837421, 35.677002059244771],
              [-81.209730989456475, 35.676737332453961],
              [-81.209400658333493, 35.676695795991208],
              [-81.209452488666798, 35.67643106929507],
              [-81.209122158935742, 35.676389532072392],
              [-81.209173989983285, 35.676124805470955],
              [-81.208843661643968, 35.676083267488366],
              [-81.208895493405748, 35.675818540981524],
              [-81.208565166458342, 35.675777002239045],
              [-81.208616998934346, 35.675512275826961],
              [-81.208286673378666, 35.675470736324577],
              [-81.208338507673304, 35.675206009987185],
              [-81.208008183509534, 35.675164469724926],
              [-81.208060017413914, 35.67489974350228],
              [-81.207729694641856, 35.674858202480124],
              [-81.207781530364841, 35.674593476332163],
              [-81.207451208960123, 35.674551933649006],
              [-81.207554880391157, 35.674022481519835],
              [-81.207224561455661, 35.673980939115467],
              [-81.207276398268121, 35.673716213114247],
              [-81.20694608069995, 35.673674669048779],
              [-81.20699791822652, 35.673409943142389],
              [-81.206337287341412, 35.6733268534757],
              [-81.206389125530649, 35.673062127821481],
              [-81.205398185467516, 35.672937487444571],
              [-81.205067873167209, 35.672895939151289],
              [-81.205016029661877, 35.673160664275343],
              [-81.204355403947389, 35.673077563820598],
              [-81.204407249558543, 35.672812838971574],
              [-81.204076937145828, 35.672771287105178],
              [-81.20412878347102, 35.672506562350804],
              [-81.203468164001436, 35.672423457062187],
              [-81.203520012069092, 35.672158731639151],
              [-81.203189703907739, 35.672117178236981],
              [-81.203137854762758, 35.672381902621318],
              [-81.202807545887509, 35.67234034818437],
              [-81.20275569535076, 35.672605072388535],
              [-81.202095076486501, 35.672521959646303],
              [-81.202043222400746, 35.672786683552623],
              [-81.201712912411864, 35.672745125247417],
              [-81.2017647675506, 35.672480401478602],
              [-81.201104149615617, 35.672397283372455],
              [-81.201156006496674, 35.672132558934898],
              [-81.200825699630471, 35.672090998212838],
              [-81.200773841696531, 35.672355722512833],
              [-81.19945261451403, 35.672189470081285],
              [-81.199504475579502, 35.671924747252653],
              [-81.198513562186477, 35.671800048914996],
              [-81.198461696882632, 35.672064772252021],
              [-81.197470783382059, 35.671940065425936],
              [-81.197522651844523, 35.671675342501658],
              [-81.195871140652116, 35.671467480546966],
              [-81.195923015144047, 35.671202758247929],
              [-81.193941221182172, 35.670953294180478],
              [-81.193993101651714, 35.67068857266441],
              [-81.193662804349572, 35.670646992477202],
              [-81.19371468555731, 35.67038227195728],
              [-81.192393507214874, 35.670215940923832],
              [-81.192445392270145, 35.669951220010645],
              [-81.192115099873405, 35.66990963559239],
              [-81.192166985642302, 35.669644914773983],
              [-81.191836694637331, 35.6696033295962],
              [-81.191940467263265, 35.669073888106631],
              [-81.191610178702874, 35.669032302306817],
              [-81.191662066664222, 35.668767581615811],
              [-81.191331779495755, 35.668725995056356],
              [-81.191383667066262, 35.668461274480215],
              [-81.191053381265249, 35.668419686260059],
              [-81.191105269549411, 35.668154965778854],
              [-81.190774986268849, 35.668113377680378],
              [-81.190826875266566, 35.667848657294179],
              [-81.190496592249204, 35.66780706755484],
              [-81.190548483064802, 35.667542347243774],
              [-81.190218201463551, 35.667500757646039],
              [-81.190270092992648, 35.667236037429952],
              [-81.189939812758936, 35.667194446171429],
              [-81.189991705001546, 35.666929726050441],
              [-81.18966142615956, 35.666888134032362],
              [-81.189765210628337, 35.66635869395752],
              [-81.189434935335171, 35.666317101297743],
              [-81.189538720552548, 35.665787661327705],
              [-81.189208447703763, 35.665746068046097],
              [-81.189312233669767, 35.665216628180929],
              [-81.188981963265377, 35.665175034277468],
              [-81.189033857344455, 35.664910314408608],
              [-81.188373319658211, 35.66482712418555],
              [-81.188425215503287, 35.664562404549535],
              [-81.187764681278864, 35.664479211013052],
              [-81.187816578889937, 35.664214491609819],
              [-81.187156049231618, 35.664131294740137],
              [-81.187207947480019, 35.663866574688328],
              [-81.186547421283535, 35.663783374505449],
              [-81.184565850810699, 35.663533751523623],
              [-81.184617759373069, 35.663269033392389],
              [-81.183957240709077, 35.663185819130362],
              [-81.18400915101283, 35.662921100330848],
              [-81.183348635786544, 35.662837881854529],
              [-81.183400547880424, 35.662573164189055],
              [-81.183070291288729, 35.662531554203397],
              [-81.183122204071552, 35.662266835731913],
              [-81.182791949975766, 35.662225224967052],
              [-81.182843863471803, 35.661960506590717],
              [-81.182513610767742, 35.661918895066464],
              [-81.182565523872753, 35.661654176805072],
              [-81.182235272536232, 35.661612563620338],
              [-81.182339102066123, 35.66108312810708],
              [-81.182008853173684, 35.661041514300621],
              [-81.182112682347153, 35.660512078912411],
              [-81.181782437003079, 35.660470464464531],
              [-81.181886266900051, 35.659941028280635],
              [-81.181556024024445, 35.659899414112303],
              [-81.18160794006846, 35.659634696084431],
              [-81.181277697456039, 35.659593080275393],
              [-81.181329614213269, 35.659328362342741],
              [-81.180999374120901, 35.659286746655738],
              [-81.181051291591245, 35.659022028818356],
              [-81.180390813400763, 35.658938794147211],
              [-81.180442732612306, 35.658674075641684],
              [-81.179452022672592, 35.658549216839894],
              [-81.179503944726108, 35.658284499606324],
              [-81.178183007091405, 35.658118008895592],
              [-81.178234933015062, 35.657853292170671],
              [-81.177904700501017, 35.657811667162825],
              [-81.177956628241773, 35.657546950513407],
              [-81.177296166336333, 35.657463698082097],
              [-81.17734809584239, 35.657198981665786],
              [-81.176687637398871, 35.657115725921827],
              [-81.176739568670243, 35.656851009738645],
              [-81.176079114792927, 35.656767750662361],
              [-81.176131047805328, 35.656503033811148],
              [-81.175800821875853, 35.65646140307512],
              [-81.175852755625371, 35.656196687220422],
              [-81.175192307993356, 35.656113423313123],
              [-81.175244243483831, 35.655848706790408],
              [-81.174583799313865, 35.655765439570615],
              [-81.174635736593643, 35.655500724182289],
              [-81.17430551659784, 35.65545908845364],
              [-81.174253578290106, 35.65572380460533],
              [-81.173593137260582, 35.655640531983657],
              [-81.173541195380523, 35.655905246935802],
              [-81.173210974322728, 35.655863609141697],
              [-81.173159032155169, 35.656128323893846],
              [-81.172828810384559, 35.656086685064793],
              [-81.17277686574532, 35.656351400557561],
              [-81.172116421093548, 35.656268119031566],
              [-81.172168366757361, 35.656003404735337],
              [-81.171838145980828, 35.655961762314064],
              [-81.17189009235733, 35.655697048113268],
              [-81.17155987297248, 35.655655404932801],
              [-81.171611821165925, 35.655390690807664],
              [-81.171281602068674, 35.655349046887764],
              [-81.171333550974779, 35.65508433285801],
              [-81.171003334397525, 35.655042689060394],
              [-81.171055283992317, 35.654777974224913],
              [-81.170064639429597, 35.654653036962436],
              [-81.170116591841492, 35.654388322498093],
              [-81.169456165926761, 35.65430502648011],
              [-81.169404211386365, 35.654569739767588],
              [-81.168743785829406, 35.654486439866218],
              [-81.168795742474146, 35.654221726854487],
              [-81.168465530692913, 35.654180075255468],
              [-81.168413573020132, 35.654444789030492],
              [-81.167422937685515, 35.654319829319299],
              [-81.167474898514797, 35.654055115958002],
              [-81.166814479136434, 35.653971805568538],
              [-81.166762515074595, 35.654236517772659],
              [-81.165771884833077, 35.654111544606742],
              [-81.165823852027245, 35.653846831915153],
              [-81.165493643302, 35.653805172243288],
              [-81.165597577695749, 35.653275747929449],
              [-81.165267372518372, 35.653234087616745],
              [-81.165371307634075, 35.652704662507908],
              [-81.165041104900567, 35.652663001574076],
              [-81.165093073553223, 35.652398289084104],
              [-81.165197008759691, 35.65186886489775],
              [-81.165527208336997, 35.651910525417854],
              [-81.165787034514238, 35.650586960376728],
              [-81.16611722917078, 35.650628619310353],
              [-81.166221155367069, 35.650099192176924],
              [-81.166551347154936, 35.650140849957509],
              [-81.166603309255777, 35.64987613663002],
              [-81.166933500331155, 35.649917793375799],
              [-81.166985461040539, 35.649653079868159],
              [-81.167315651379255, 35.649694734677965],
              [-81.167419567467306, 35.649165307299235],
              [-81.167749757145444, 35.649206960916572],
              [-81.167801713628194, 35.648942247025914],
              [-81.168131901513846, 35.648983900529281],
              [-81.168235811332735, 35.648454471443941],
              [-81.168565998533765, 35.648496122853842],
              [-81.168617950790008, 35.648231408580116],
              [-81.168948137302692, 35.648273059856372],
              [-81.169103991014396, 35.647478913624845],
              [-81.1687738087859, 35.647437263643639],
              [-81.168825760764236, 35.647172548279947],
              [-81.168495579903052, 35.647130896638615],
              [-81.168547531489878, 35.646866181390166],
              [-81.168217352044337, 35.646824529890964],
              [-81.168321258511583, 35.646295099503646],
              [-81.167991081485283, 35.646253446482199],
              [-81.168043035261732, 35.645988731362969],
              [-81.167712859626761, 35.645947077582505],
              [-81.167764814115685, 35.64568236255905],
              [-81.16743464097614, 35.645640707999931],
              [-81.167642458640969, 35.644581848054663],
              [-81.167312290048542, 35.644540193150277],
              [-81.167416199500039, 35.644010763190273],
              [-81.167086032246857, 35.643969107684505],
              [-81.167189942420208, 35.643439676930285],
              [-81.16685977871424, 35.643398020783785],
              [-81.167067599194965, 35.642339159320656],
              [-81.166737440035902, 35.64229750282896],
              [-81.166945259292717, 35.641238641242659],
              [-81.166615104680488, 35.641196984405752],
              [-81.166874876337033, 35.639873405812267],
              [-81.166544728403068, 35.639831747847253],
              [-81.166596682762943, 35.639567031959878],
              [-81.166266535140338, 35.639525374156825],
              [-81.166474353392985, 35.638466510737821],
              [-81.166144211420658, 35.638424852569983],
              [-81.166300074677153, 35.637630704076251],
              [-81.165969936199531, 35.637589045425464],
              [-81.166021890999275, 35.637324329615019],
              [-81.165691753912697, 35.637282670205373],
              [-81.165743709400644, 35.637017953589641],
              [-81.165413574809108, 35.636976293401439],
              [-81.165361618245385, 35.637241008978059],
              [-81.164371211144626, 35.637116022658425],
              [-81.164423170839328, 35.636851306594451],
              [-81.164093037605667, 35.636809642819209],
              [-81.164041075731305, 35.637074357863732],
              [-81.162060273788072, 35.636824353809644],
              [-81.162112241972466, 35.636559639592811],
              [-81.160131459617261, 35.636309602261591],
              [-81.16018343377209, 35.63604488883049],
              [-81.159853305979496, 35.636003212634428],
              [-81.159957255349539, 35.635473785020871],
              [-81.159627129999734, 35.635432108204029],
              [-81.15973108011481, 35.634902680698268],
              [-81.159400957231767, 35.634861004161849],
              [-81.159452932819491, 35.634596290033294],
              [-81.159122811303661, 35.634554611836982],
              [-81.159226764691681, 35.634025184611367],
              [-81.158896645618469, 35.633983505794291],
              [-81.158948622290779, 35.63371879181566],
              [-81.158618505712653, 35.633677112220298],
              [-81.158670483120872, 35.633412399238821],
              [-81.158340366829904, 35.633370718904352],
              [-81.15839234603007, 35.633106005098092],
              [-81.158062231130288, 35.633064324004934],
              [-81.158114209938574, 35.632799610314272],
              [-81.15778409753365, 35.632757928442835],
              [-81.157836077053901, 35.632493214848168],
              [-81.157505966040077, 35.632451532218063],
              [-81.157557946272263, 35.632186818719404],
              [-81.156897727366456, 35.632103451045154],
              [-81.156949710465824, 35.631838737760958],
              [-81.156619601470013, 35.631797052736431],
              [-81.156671585281373, 35.631532339548272],
              [-81.156341478780377, 35.631490653745487],
              [-81.15639346219983, 35.631225940672969],
              [-81.155733253423449, 35.631142566633898],
              [-81.155785238606256, 35.63087785379544],
              [-81.155455135226973, 35.630836165578835],
              [-81.155507122201485, 35.630571451915621],
              [-81.154846919668572, 35.630488073048745],
              [-81.154898907326427, 35.630223360540455],
              [-81.154238708230892, 35.630139977461994],
              [-81.154290698755915, 35.629875265168259],
              [-81.153630503145678, 35.629791879680404],
              [-81.153682495409868, 35.62952716671964],
              [-81.153022303261011, 35.629443777921345],
              [-81.153074297288484, 35.629179065194755],
              [-81.152744203326762, 35.629137369578949],
              [-81.152796198065985, 35.628872656948467],
              [-81.152466105495407, 35.628830960574078],
              [-81.152518100946381, 35.628566248039739],
              [-81.151857918926751, 35.628482852877276],
              [-81.151909916140809, 35.628218140577147],
              [-81.151579826691844, 35.628176441789044],
              [-81.15163182461761, 35.627911729585044],
              [-81.150971648817602, 35.627828328694079],
              [-81.151023649610167, 35.627563616704698],
              [-81.150693563282928, 35.627521915503031],
              [-81.150745563683387, 35.62725720362937],
              [-81.150415478747163, 35.627215501669149],
              [-81.150467480963044, 35.62695078987214],
              [-81.150137397394019, 35.626909086252169],
              [-81.150189400321551, 35.626644374551503],
              [-81.14985931816733, 35.626602671074188],
              [-81.150067328478144, 35.625543824443746],
              [-81.149737251972567, 35.625502120602761],
              [-81.149789255280339, 35.625237408067584],
              [-81.150119330758557, 35.625279112671812],
              [-81.150223333196479, 35.624749689021655],
              [-81.149893259820885, 35.624707984693686],
              [-81.149997263002646, 35.624178561152405],
              [-81.149667192069231, 35.624136856204039],
              [-81.149719193649929, 35.623872144518508],
              [-81.149389124107429, 35.623830438811673],
              [-81.148728986065294, 35.623747025609632],
              [-81.148676981330794, 35.624011736880817],
              [-81.148346910652307, 35.623970028365846],
              [-81.148294904526892, 35.624234739457194],
              [-81.147964834264585, 35.624193030789144],
              [-81.147912825644482, 35.624457741720057],
              [-81.147582754646677, 35.624416031116205],
              [-81.147530744635617, 35.624680741867166],
              [-81.147200672926402, 35.624639030228643],
              [-81.147148660420626, 35.62490374081915],
              [-81.146488515918733, 35.624820314576276],
              [-81.146436502074565, 35.625085024829261],
              [-81.146106429282028, 35.625043310224811],
              [-81.146054412943002, 35.625308020317327],
              [-81.145394266274806, 35.625224588142665],
              [-81.145342248597288, 35.625489297897502],
              [-81.14468210008198, 35.625405861879869],
              [-81.14463007996217, 35.625670571316604],
              [-81.143969930703548, 35.625587131436561],
              [-81.143917907037704, 35.62585184057459],
              [-81.143587832418703, 35.625810119141768],
              [-81.143535807361872, 35.62607482809986],
              [-81.142875657017058, 35.625991381367243],
              [-81.142719573476029, 35.626785506259907],
              [-81.142389494567766, 35.626743781604375],
              [-81.142233404814249, 35.627537905705417],
              [-81.142563486876327, 35.6275796307755],
              [-81.142459427435895, 35.628109046609083],
              [-81.142129343271236, 35.628067321262876],
              [-81.142025280368813, 35.628596736652483],
              [-81.141695194441425, 35.628555010133326],
              [-81.14164316141715, 35.628819717176476],
              [-81.141313074778452, 35.628777989622577],
              [-81.140652902496839, 35.628694530923816],
              [-81.140704938674943, 35.628429824295146],
              [-81.138724438503559, 35.628179427471238],
              [-81.138776481753283, 35.62791472161021],
              [-81.137456158941816, 35.627747773022072],
              [-81.137352063716065, 35.628277184497875],
              [-81.137021982576627, 35.628235444367959],
              [-81.136969933390546, 35.628500149454062],
              [-81.136639850459801, 35.628458409209991],
              [-81.136535747861572, 35.628987818999462],
              [-81.136205664271998, 35.628946077562958],
              [-81.136153610859637, 35.629210782266235],
              [-81.135823526535063, 35.629169038893764],
              [-81.135771471731417, 35.629433743416875],
              [-81.135111299816003, 35.629350254645828],
              [-81.135059243673666, 35.629614958831041],
              [-81.134399071015778, 35.629531466197292],
              [-81.134347012430837, 35.629796170064175],
              [-81.133356752805128, 35.629670922622694],
              [-81.133304689622378, 35.629935626052365],
              [-81.132974602709325, 35.629893874973824],
              [-81.13292253813529, 35.630158578223195],
              [-81.132262363226957, 35.630075073099931],
              [-81.132210296210175, 35.630339776030837],
              [-81.131550120559822, 35.630256267044793],
              [-81.131498051100351, 35.630520969657191],
              [-81.130837874707979, 35.630437456808352],
              [-81.130785802805818, 35.630702159102256],
              [-81.129795537614498, 35.630576882690022],
              [-81.129743461114629, 35.630841584546459],
              [-81.129413372346548, 35.630799823810968],
              [-81.129465449897779, 35.630535122092766],
              [-81.127815016393029, 35.630326304756835],
              [-81.127762934689315, 35.630591005764359],
              [-81.127432847938749, 35.630549238748252],
              [-81.12748493179754, 35.630284537859666],
              [-81.127154846462204, 35.630242770986449],
              [-81.127206931031921, 35.629978070194127],
              [-81.126546763460667, 35.629894533133374],
              [-81.126650936911901, 35.629365132857679],
              [-81.126320855727286, 35.629323362808464],
              [-81.126372942430081, 35.629058662304956],
              [-81.126042863764269, 35.629016892379347],
              [-81.126251211354173, 35.627958089597328],
              [-81.125921137233348, 35.627916319328349],
              [-81.125973224337358, 35.627651618891477],
              [-81.126303297407006, 35.627693389022156],
              [-81.126459553500453, 35.626899286143612],
              [-81.126789623780411, 35.626941055864066],
              [-81.126841707397574, 35.626676354081496],
              [-81.126511638192412, 35.626634585400502],
              [-81.126615806531987, 35.626105182887386],
              [-81.126285739745583, 35.62606341268539],
              [-81.126337824468052, 35.625798711954822],
              [-81.125347630430937, 35.62567339726629],
              [-81.125399719070288, 35.625408696889394],
              [-81.124409531248531, 35.625283374547315],
              [-81.124461621573545, 35.625018673661522],
              [-81.123801500538164, 35.624935121521617],
              [-81.123853593728853, 35.624670420851309],
              [-81.123193476156715, 35.624586865402058],
              [-81.123245570005537, 35.624322164985749],
              [-81.122585456976623, 35.624238605306857],
              [-81.12263755371464, 35.623973906007144],
              [-81.1223074976579, 35.623932124980591],
              [-81.122359595083068, 35.623667424876132],
              [-81.1220295415214, 35.623625643072181],
              [-81.122081639657324, 35.623360943064156],
              [-81.12142153565695, 35.623277377043522],
              [-81.121473634450822, 35.623012677289559],
              [-81.12114358513945, 35.622970893954907],
              [-81.121195684644121, 35.622706194297315],
              [-81.120865636700373, 35.622664409303376],
              [-81.120917736915743, 35.622399709742254],
              [-81.12058769036328, 35.622357923990222],
              [-81.120639792393078, 35.622093224506315],
              [-81.120309746128171, 35.62205143801544],
              [-81.120361848845064, 35.621786737726858],
              [-81.119701760541872, 35.621703162313203],
              [-81.119753865043961, 35.621438463160715],
              [-81.119423821901933, 35.621396674257198],
              [-81.119475928218378, 35.621131975181946],
              [-81.11914588646772, 35.621090185520366],
              [-81.11919799239098, 35.620825486560904],
              [-81.118867953111632, 35.62078369522083],
              [-81.118537914196281, 35.620741903885516],
              [-81.118642129225634, 35.620212506394523],
              [-81.118312092728885, 35.620170713538386],
              [-81.118520523987527, 35.619111917687533],
              [-81.117860460445641, 35.619028331294288],
              [-81.117912570321351, 35.618763631813401],
              [-81.117582540123649, 35.618721837861123],
              [-81.11768675987561, 35.618192439971288],
              [-81.117356732119887, 35.618150645399474],
              [-81.118294662871833, 35.613386049528621],
              [-81.11796465547441, 35.613344256532123],
              [-81.118120969843986, 35.612550154816255],
              [-81.117790965939008, 35.612508361338627],
              [-81.117999384161593, 35.611449558727045],
              [-81.11766938590327, 35.611407764887431],
              [-81.117773594510766, 35.610878363167551],
              [-81.117443598670505, 35.610836567807269],
              [-81.117495703538054, 35.610571867924449],
              [-81.117165709088837, 35.610530071806252],
              [-81.117269920960794, 35.610000670370965],
              [-81.116939928976592, 35.609958874534492],
              [-81.117044140461729, 35.609429472327584],
              [-81.116714152022595, 35.609387675852417],
              [-81.116766258317142, 35.609122975275554],
              [-81.116436270141847, 35.609081177160547],
              [-81.116488378250182, 35.608816476661353],
              [-81.116158391465859, 35.608774677788425],
              [-81.116210500284595, 35.608509977385971],
              [-81.115880514891074, 35.608468177755299],
              [-81.115932624420111, 35.608203477449663],
              [-81.115272657882173, 35.608119876658428],
              [-81.115324769148557, 35.607855175686872],
              [-81.114994786900596, 35.607813374545458],
              [-81.115046898877253, 35.607548673670756],
              [-81.114716919123694, 35.607506871752328],
              [-81.114821143077179, 35.606977471075453],
              [-81.114491165741512, 35.606935667636449],
              [-81.114543278798251, 35.606670966913931],
              [-81.114213302877033, 35.606629163618322],
              [-81.114265415540586, 35.606364463011921],
              [-81.114421753719839, 35.605570361806002],
              [-81.114091782317402, 35.605528557266751],
              [-81.114248120585529, 35.60473445610252],
              [-81.113918153778343, 35.604692651063068],
              [-81.114387158463401, 35.602310342989682],
              [-81.114717115816532, 35.602352146783154],
              [-81.1149255479327, 35.601293341085892],
              [-81.115255501424357, 35.601335143429431],
              [-81.11546392387153, 35.600276335614375],
              [-81.11579387239847, 35.600318136527257],
              [-81.115950181647477, 35.599524028478449],
              [-81.116280128466926, 35.599565828060719],
              [-81.116332229842669, 35.599301126057739],
              [-81.116662174825123, 35.599342923723455],
              [-81.11697476841222, 35.597754705519492],
              [-81.117304707456341, 35.597796502359735],
              [-81.117460995919544, 35.597002390942059],
              [-81.117790932152914, 35.597044186470946],
              [-81.117895120284643, 35.596514778081662],
              [-81.118225054757559, 35.596556572437585],
              [-81.118277146723045, 35.596291868502774],
              [-81.118607080485717, 35.596333661824197],
              [-81.118659171037223, 35.596068956808466],
              [-81.118989104089806, 35.596110749095381],
              [-81.119093283198836, 35.595581339545049],
              [-81.11942321338752, 35.595623130678362],
              [-81.119527389035554, 35.595093720686123],
              [-81.119197459843974, 35.595051929848928],
              [-81.119718325903506, 35.592404876130551],
              [-81.12004824238609, 35.592446665622191],
              [-81.120152408745298, 35.591917253757813],
              [-81.120482324547311, 35.591959041156251],
              [-81.120638568385075, 35.591164922635421],
              [-81.120308655710062, 35.591123134751165],
              [-81.120360737359434, 35.590858428633553],
              [-81.120030827177914, 35.590816639972331],
              [-81.120082909537388, 35.59055193395195],
              [-81.119753000746073, 35.590510144533077],
              [-81.119805083815663, 35.590245438609927],
              [-81.119475176414539, 35.590203648433409],
              [-81.119527260194218, 35.58993894260751],
              [-81.119197354183285, 35.589897151673391],
              [-81.119249438649476, 35.589632445043506],
              [-81.118919534028734, 35.589590653351749],
              [-81.118971619228546, 35.589325947720326],
              [-81.118641715997981, 35.589284155271066],
              [-81.118693801907838, 35.589019449736888],
              [-81.118363900067436, 35.588977656529963],
              [-81.118415987767051, 35.588712950172614],
              [-81.118086087316811, 35.588671156208086],
              [-81.118138174623184, 35.588406449967266],
              [-81.117808275586668, 35.588364656146354],
              [-81.117860364706303, 35.588099949983551],
              [-81.117530467036389, 35.588058154503955],
              [-81.117634645252195, 35.587528742351083],
              [-81.117304750022583, 35.587486946252184],
              [-81.117408929001726, 35.586957535112923],
              [-81.117079037315449, 35.586915738375758],
              [-81.117131126781601, 35.586651032441885],
              [-81.116801236485429, 35.586609234947183],
              [-81.116905417600762, 35.586079823213737],
              [-81.116575529744708, 35.586038025099924],
              [-81.116627620267138, 35.585773318418489],
              [-81.116297733801176, 35.585731519547103],
              [-81.116349826160175, 35.585466813844988],
              [-81.116019941084389, 35.585425014216156],
              [-81.116072033050173, 35.585160308630527],
              [-81.115742149364351, 35.585118508244186],
              [-81.11584633655842, 35.584589096287019],
              [-81.115516455312729, 35.584547295281631],
              [-81.115620642143142, 35.584017883456283],
              [-81.115290764440587, 35.583976081812651],
              [-81.115342857855808, 35.583711376437059],
              [-81.115012981543288, 35.583669574035937],
              [-81.115065076748024, 35.583404867837366],
              [-81.11473520072235, 35.583363064698027],
              [-81.114891486365991, 35.582568947191803],
              [-81.114561614933436, 35.582527143552674],
              [-81.114613710877521, 35.582262437466895],
              [-81.114283840834872, 35.582220633070293],
              [-81.114335936385686, 35.581955927101149],
              [-81.114006067733129, 35.581914121947115],
              [-81.114110262120434, 35.581384710124318],
              [-81.113780395907639, 35.581342904351331],
              [-81.113832493641056, 35.581078198516877],
              [-81.113502628818225, 35.581036391986551],
              [-81.113554727261416, 35.580771686249584],
              [-81.113224863805044, 35.580729878060573],
              [-81.113276962957968, 35.580465172421093],
              [-81.112947102018211, 35.580423364356683],
              [-81.112999200777679, 35.580158658833874],
              [-81.112669341204381, 35.580116849110865],
              [-81.112721441776642, 35.579852143666386],
              [-81.112391582513709, 35.579810334106355],
              [-81.112443683795661, 35.579545628759469],
              [-81.112113827002347, 35.579503817521669],
              [-81.112165928993988, 35.579239112272226],
              [-81.111836073590595, 35.57919730027708],
              [-81.111888176291885, 35.578932595125167],
              [-81.111558322301903, 35.578890783273806],
              [-81.111610425689378, 35.578626077318248],
              [-81.111280573089218, 35.578584264709541],
              [-81.111332677186439, 35.57831955885154],
              [-81.111002825976186, 35.578277745485458],
              [-81.111054930783041, 35.578013039725015],
              [-81.110725080962681, 35.577971225601665],
              [-81.110777186479169, 35.577706519938793],
              [-81.110447338048701, 35.577664705058005],
              [-81.110499445377897, 35.577399999473542],
              [-81.110169598313874, 35.577358182934255],
              [-81.107860679517159, 35.577065442958009],
              [-81.107808563790087, 35.577330147434303],
              [-81.107478719500889, 35.577288323570691],
              [-81.107270248921452, 35.578347137808784],
              [-81.106940399670435, 35.578305312514338],
              [-81.106836160228227, 35.578834718659984],
              [-81.106506308114831, 35.578792892211716],
              [-81.10645418683356, 35.579057595084635],
              [-81.10612433511379, 35.579015767582874],
              [-81.106072212442612, 35.579280470276188],
              [-81.105742360013352, 35.579238641740027],
              [-81.105690234848907, 35.579503344272936],
              [-81.105030528911769, 35.579419684235944],
              [-81.104978401307534, 35.579684386450779],
              [-81.10332913604438, 35.579475219106115],
              [-81.103277003954233, 35.579739920568045],
              [-81.099978488408908, 35.579321515502258],
              [-81.100030633202564, 35.579056815388029],
              [-81.098711241587139, 35.578889428456904],
              [-81.09876339023927, 35.578624728856113],
              [-81.098433544224434, 35.578582879571776],
              [-81.098485693585843, 35.578318180068678],
              [-81.098155848961127, 35.578276330027165],
              [-81.098468742049008, 35.576688132116345],
              [-81.098138904062651, 35.576646282010742],
              [-81.098295349148273, 35.575852182467735],
              [-81.097965515754382, 35.575810331863146],
              [-81.098017664860464, 35.575545632366357],
              [-81.097687832856508, 35.5755037810045],
              [-81.097844280131625, 35.574709680901044],
              [-81.097514451616846, 35.574667829059386],
              [-81.097618749837366, 35.574138429369818],
              [-81.097288923762235, 35.57409657690966],
              [-81.097393223800012, 35.573567176413633],
              [-81.097063400164288, 35.573525323334955],
              [-81.097115550722165, 35.573260623164202],
              [-81.096785728499711, 35.573218770229644],
              [-81.096890030693189, 35.572689370042823],
              [-81.096560211989953, 35.572647515569528],
              [-81.096664513817871, 35.572118115515543],
              [-81.096334697554056, 35.572076260423891],
              [-81.096439001198974, 35.571546859563483],
              [-81.096109187374566, 35.571505003853382],
              [-81.096161338644563, 35.571240303970221],
              [-81.09550171411604, 35.571156590140021],
              [-81.095553868247507, 35.570891890474314],
              [-81.094894248259351, 35.570808172418396],
              [-81.094946404149283, 35.570543472989151],
              [-81.09461659517541, 35.570501613216365],
              [-81.094668751774464, 35.570236913884962],
              [-81.094009138026621, 35.570153191009389],
              [-81.094061296384112, 35.569888491914504],
              [-81.093731490530431, 35.569846629731984],
              [-81.093835809402535, 35.569317230776868],
              [-81.093506005964969, 35.569275367074816],
              [-81.09361032557409, 35.568745968233891],
              [-81.093280524598939, 35.568704104814628],
              [-81.0933848449219, 35.568174705186713],
              [-81.09305504748896, 35.568132841130144],
              [-81.093107207637559, 35.567868141403252],
              [-81.09277741159444, 35.56782627658967],
              [-81.092829572452032, 35.567561576960642],
              [-81.092499777798736, 35.567519711390084],
              [-81.092604101694235, 35.566990312268089],
              [-81.092274309480104, 35.566948446079238],
              [-81.092326471414921, 35.566683746605307],
              [-81.091996681670395, 35.566641878739361],
              [-81.092048844314178, 35.566377179363286],
              [-81.091719054879619, 35.566335311660588],
              [-81.091771219335129, 35.566070612363546],
              [-81.091441431267114, 35.566028743002633],
              [-81.091493596431562, 35.565764043803547],
              [-81.091163809776546, 35.565722174586867],
              [-81.090834024541735, 35.565680303554473],
              [-81.09078185727877, 35.56594500247607],
              [-81.090452070255424, 35.565903131329499],
              [-81.090399902705542, 35.566167830052684],
              [-81.090070114950166, 35.566125956970588],
              [-81.090017944907515, 35.566390655533368],
              [-81.089688157546391, 35.56634878139787],
              [-81.089635986113933, 35.566613479781203],
              [-81.089306196964174, 35.566571605531507],
              [-81.08925402414188, 35.566836303735386],
              [-81.087605079264762, 35.56662691473506],
              [-81.087657258436508, 35.56636221720585],
              [-81.086338113087166, 35.566194690615561],
              [-81.086008328422707, 35.566152806489391],
              [-81.086060511397335, 35.56588810963207],
              [-81.085400945188823, 35.565804338970352],
              [-81.085348759012703, 35.5660690355692],
              [-81.085018975370048, 35.566027148756064],
              [-81.085071161492465, 35.565762452315056],
              [-81.084411597327488, 35.565678676279632],
              [-81.084463785207802, 35.565413980075334],
              [-81.083804224503808, 35.565330200734977],
              [-81.083856415244824, 35.565065504748603],
              [-81.081547969706634, 35.564772248033826],
              [-81.081495771621007, 35.56503694304886],
              [-81.081165994164579, 35.564995045766061],
              [-81.081218193299335, 35.564730350889818],
              [-81.080558641483933, 35.564646553013908],
              [-81.080610843479334, 35.564381858355553],
              [-81.080281069143382, 35.564339958663645],
              [-81.080333270721312, 35.564075263221113],
              [-81.080003497775238, 35.564033362772356],
              [-81.080055701187689, 35.563768668310217],
              [-81.079725929608372, 35.563726766203466],
              [-81.079778133729292, 35.563462071839496],
              [-81.079448363539854, 35.563420168975959],
              [-81.079500568369113, 35.563155474710037],
              [-81.078841032236809, 35.563071667456207],
              [-81.078893238823639, 35.562806973427165],
              [-81.076584880954258, 35.562513619439315],
              [-81.076637094544253, 35.562248926341276],
              [-81.075647806868545, 35.562123189409014],
              [-81.075700023265071, 35.561858496686874],
              [-81.075040502685312, 35.561774667852774],
              [-81.075092721942113, 35.56150997534882],
              [-81.074433204823663, 35.561426143210205],
              [-81.074485424711852, 35.561161450060879],
              [-81.073825911054783, 35.561077614617965],
              [-81.073878133803163, 35.560812921686846],
              [-81.073218623607445, 35.560729082939496],
              [-81.073270848136119, 35.560464391146624],
              [-81.072941094598463, 35.560422470568618],
              [-81.072993319812298, 35.560157777972755],
              [-81.07167431434641, 35.559990086340733],
              [-81.071726543438402, 35.559725395160996],
              [-81.0687588135374, 35.559348037124572],
              [-81.068811052809352, 35.559083346234274],
              [-81.068481308270862, 35.559041412615841],
              [-81.068533547170858, 35.558776722743723],
              [-81.068203804022247, 35.558734788368731],
              [-81.068308283954494, 35.558205406959182],
              [-81.067978543244678, 35.558163471966544],
              [-81.068030784299808, 35.557898781330429],
              [-81.067701045002821, 35.557856846482451],
              [-81.06775328676585, 35.55759215594454],
              [-81.065115394043289, 35.557256641510826],
              [-81.065063143888921, 35.557521330937341],
              [-81.064733407637561, 35.557479387129],
              [-81.064785658840833, 35.557214697841538],
              [-81.064455923979352, 35.557172753276703],
              [-81.064508176993357, 35.5569080640688],
              [-81.064178443521769, 35.556866118747578],
              [-81.064282949498661, 35.556336739605371],
              [-81.063953218488734, 35.556294794567719],
              [-81.064005471451708, 35.55603010463345],
              [-81.063675742911514, 35.555988157919394],
              [-81.063780251037869, 35.555458779089435],
              [-81.063450524936357, 35.555416831757952],
              [-81.063555032693571, 35.554887453062243],
              [-81.063225309030798, 35.554845505113263],
              [-81.063329818624467, 35.554316126514529],
              [-81.06300009740022, 35.554274177947995],
              [-81.063104607727496, 35.553744799464937],
              [-81.062774888941902, 35.553702850280935],
              [-81.062827145182737, 35.553438160657677],
              [-81.062497427786809, 35.553396210717231],
              [-81.062601940240313, 35.552866831645609],
              [-81.062272225283024, 35.552824881087794],
              [-81.062376738469922, 35.55229550213199],
              [-81.062047027053936, 35.552253550937955],
              [-81.06209928363333, 35.551988861547613],
              [-81.061769573606867, 35.551946909597177],
              [-81.061874088942901, 35.55141753095419],
              [-81.061544381354977, 35.55137557838637],
              [-81.061596640088581, 35.551110888232607],
              [-81.06093722693042, 35.551026980707562],
              [-81.060989487443408, 35.550762291692408],
              [-81.060659782953039, 35.55072033581537],
              [-81.060712044173712, 35.550455646898733],
              [-81.059722935940556, 35.55032977611463],
              [-81.059775199966126, 35.550065087574502],
              [-81.059115798272245, 35.5499811692194],
              [-81.059168064054191, 35.5497164809168],
              [-81.058178967228642, 35.549590597536692],
              [-81.058231236895338, 35.549325908690768],
              [-81.057901539691869, 35.549283946212789],
              [-81.057571842806695, 35.549241981938295],
              [-81.05751957214548, 35.549506670487474],
              [-81.056200784946412, 35.549338804762634],
              [-81.056148509749576, 35.549603492715228],
              [-81.055818812472637, 35.549561523686016],
              [-81.05576653476048, 35.549826210576541],
              [-81.054118050500321, 35.549616353070526],
              [-81.054170333432751, 35.549351665974022],
              [-81.053840638225353, 35.549309692474083],
              [-81.053892921865128, 35.549045005476252],
              [-81.05356322912742, 35.549003030300192],
              [-81.053667797503579, 35.548473657362287],
              [-81.053338107227106, 35.548431682470252],
              [-81.053390391940667, 35.548166995629067],
              [-81.053060703030937, 35.548125019079656],
              [-81.0531129884519, 35.547860332337059],
              [-81.052783299829258, 35.547818355049998],
              [-81.052835587083138, 35.54755366928859],
              [-81.052176214061205, 35.547469712288098],
              [-81.052228503048113, 35.547205025863256],
              [-81.051569134590778, 35.547121065541134],
              [-81.051516843506704, 35.547385751687862],
              [-81.050198106469324, 35.547217818842697],
              [-81.050250401770285, 35.546953134153554],
              [-81.049920719418182, 35.546911149068443],
              [-81.049973015403651, 35.546646463576771],
              [-81.049643334441186, 35.546604477735514],
              [-81.049800222392946, 35.545810421453673],
              [-81.049470546019975, 35.545768435115811],
              [-81.049575138011051, 35.54523906434359],
              [-81.049245462950907, 35.545197076506128],
              [-81.049297760034364, 35.544932391189214],
              [-81.048968087489243, 35.544890403478171],
              [-81.049072681626825, 35.544361033019953],
              [-81.048743011497066, 35.544319043790729],
              [-81.048795309653826, 35.544054358630831],
              [-81.048465640936499, 35.544012369546664],
              [-81.048517939800462, 35.543747684485496],
              [-81.046539940884756, 35.543495729311701],
              [-81.046487634627653, 35.54376041355669],
              [-81.046157968299084, 35.54371841820592],
              [-81.046262581443315, 35.543189049071259],
              [-81.045932917530166, 35.543147052202293],
              [-81.04603753142878, 35.542617684085613],
              [-81.046367192119874, 35.542659679793651],
              [-81.046524106689034, 35.541865626006576],
              [-81.046194448017644, 35.541823629833317],
              [-81.046299058480827, 35.541294260411988],
              [-81.045969402247408, 35.541252263621779],
              [-81.046126317965076, 35.540458209163404],
              [-81.045796666320527, 35.540416211876888],
              [-81.045848971550456, 35.540151526821688],
              [-81.045519321318196, 35.540109529680358],
              [-81.045571627255086, 35.539844844724001],
              [-81.044912329865539, 35.539760846231765],
              [-81.044964637557825, 35.539496161513405],
              [-81.044634990423248, 35.539454161063652],
              [-81.044687299925158, 35.539189476425584],
              [-81.044357654202841, 35.539147476121109],
              [-81.044462273153954, 35.538618106119749],
              [-81.044132629869452, 35.538576105198437],
              [-81.044237249551941, 35.53804673531436],
              [-81.043907608682503, 35.538004732874924],
              [-81.043959919622679, 35.537740048453045],
              [-81.04363028014285, 35.537698045257649],
              [-81.04368259178996, 35.537433360934614],
              [-81.043352953699554, 35.537391356983228],
              [-81.043405266053696, 35.537126672759115],
              [-81.042416359180024, 35.537000655914667],
              [-81.04246867431479, 35.536735971166614],
              [-81.042139039685892, 35.536693963913066],
              [-81.042191355550216, 35.536429280165237],
              [-81.041202460163973, 35.536303253395808],
              [-81.041254778808735, 35.536038569124017],
              [-81.040595519020968, 35.535954547080621],
              [-81.040647839420743, 35.535689863047018],
              [-81.039658955443457, 35.535563823686182],
              [-81.039711278646408, 35.535299140029949],
              [-81.0374039041439, 35.535005015994614],
              [-81.037456234342372, 35.53474033327268],
              [-81.036796990116031, 35.53465628966245],
              [-81.036849323171765, 35.534391607160266],
              [-81.036519702078863, 35.534349584611704],
              [-81.036572035841345, 35.534084902208541],
              [-81.035912797855858, 35.534000853784825],
              [-81.035965133372997, 35.533736171620035],
              [-81.034976282639633, 35.533610092229303],
              [-81.035028620959636, 35.533345410441981],
              [-81.034369391683256, 35.533261353606214],
              [-81.034421731757803, 35.532996672057195],
              [-81.03211444732645, 35.532702443659566],
              [-81.032062098813071, 35.532967124252124],
              [-81.031402875660717, 35.532883050404166],
              [-81.031455226270111, 35.532618370090233],
              [-81.030466397246883, 35.532492253023335],
              [-81.030518750658771, 35.532227573087084],
              [-81.030189142715443, 35.532185532414033],
              [-81.029529927853403, 35.532101448382797],
              [-81.029582284067601, 35.531836768824185],
              [-81.028923073770315, 35.531752681473108],
              [-81.0289754317389, 35.531488002152948],
              [-81.028316224881138, 35.531403910599195],
              [-81.028368584604181, 35.531139231517471],
              [-81.02770938120851, 35.531055136662232],
              [-81.027761742685854, 35.530790457819066],
              [-81.027432143110872, 35.53074840962968],
              [-81.027484505294566, 35.530483730885599],
              [-81.027154907086583, 35.530441681039271],
              [-81.027207269976515, 35.530177002394389],
              [-81.02687767315804, 35.53013495179249],
              [-81.026930037856886, 35.529870273228376],
              [-81.026600441347924, 35.529828222790385],
              [-81.026652806730468, 35.529563543424239],
              [-81.026323212713493, 35.529521492212169],
              [-81.026375577699937, 35.529256812963702],
              [-81.02538680081966, 35.529130653474418],
              [-81.025439169710623, 35.528865974585457],
              [-81.025109579155824, 35.528823920072135],
              [-81.025057209194586, 35.529088597920619],
              [-81.024398028094097, 35.529004485010795],
              [-81.024345655717909, 35.529269163441661],
              [-81.023686472763288, 35.529185045790484],
              [-81.02363409794981, 35.529449723902431],
              [-81.02330450593702, 35.529407663595045],
              [-81.023252129734004, 35.529672341527572],
              [-81.022592944637907, 35.529588217948998],
              [-81.022540565997474, 35.529852895562662],
              [-81.02188138017226, 35.52976876812545],
              [-81.021828997991918, 35.53003344543864],
              [-81.021499404544045, 35.529991380238194],
              [-81.021447022054062, 35.530256056452195],
              [-81.020128647510745, 35.530087787044472],
              [-81.020181034169738, 35.529823110486795],
              [-81.01985144355541, 35.52978104169437],
              [-81.019903830920512, 35.529516365235992],
              [-81.019574240570762, 35.529474294805063],
              [-81.01962662976689, 35.529209619328803],
              [-81.019297040806833, 35.529167548142354],
              [-81.019349430686603, 35.528902871864069],
              [-81.019679018598765, 35.528944942911117],
              [-81.019783794157306, 35.528415590874879],
              [-81.019454209443268, 35.528373520088287],
              [-81.019558985730399, 35.52784416817034],
              [-81.019229403453608, 35.527802096767701],
              [-81.019281792672459, 35.527537420428182],
              [-81.018952210682741, 35.527495348288241],
              [-81.019004600630069, 35.527230672949301],
              [-81.018675021109814, 35.527188599134298],
              [-81.01872741176318, 35.526923923894586],
              [-81.017738677338869, 35.52679769931904],
              [-81.017791070771267, 35.526533023556254],
              [-81.016472768681083, 35.526364713328149],
              [-81.016525165962747, 35.526100038082944],
              [-81.016195592319988, 35.52605795752681],
              [-81.016247990307519, 35.525793282381059],
              [-81.015918419179101, 35.525751201952353],
              [-81.016023216224312, 35.52522185181931],
              [-81.015693646430677, 35.525179770792967],
              [-81.015746046568381, 35.524915094886246],
              [-81.015416478164141, 35.524873013104369],
              [-81.015468879030124, 35.524608338198242],
              [-81.015139311993096, 35.524566254759705],
              [-81.015191712462524, 35.524301579971258],
              [-81.014532583736354, 35.524217411553089],
              [-81.014584985959331, 35.523952737003498],
              [-81.014255423167839, 35.523910652042197],
              [-81.014307826074287, 35.523645976690773],
              [-81.013648703589638, 35.523561803461085],
              [-81.013701108271917, 35.523297129249826],
              [-81.012053313269575, 35.523086680762148],
              [-81.012000902246726, 35.523351354294448],
              [-81.011671342112862, 35.523309261430605],
              [-81.011618929722957, 35.523573935684603],
              [-81.010959810590393, 35.523489746956926],
              [-81.011012225097872, 35.523225073883125],
              [-81.009364438509948, 35.523014588002489],
              [-81.009416857891338, 35.522749914684887],
              [-81.009087302642612, 35.522707814783487],
              [-81.00913972385429, 35.522443142448346],
              [-81.008810169995073, 35.522401041791525],
              [-81.008862591890093, 35.522136368654621],
              [-81.008533039420385, 35.522094267242487],
              [-81.008585460941063, 35.521829595124615],
              [-81.008255910963214, 35.521787492938898],
              [-81.008360756131594, 35.521258147041252],
              [-81.008031207488415, 35.521216044257983],
              [-81.008188476392604, 35.52042202642221],
              [-81.007858932336305, 35.520379923144411],
              [-81.008016201283411, 35.519585904466069],
              [-81.007686660711656, 35.519543800712036],
              [-81.007739083322747, 35.51927912789094],
              [-81.007080005299812, 35.519194917977565],
              [-81.007132430766305, 35.518930245377319],
              [-81.006473356183776, 35.518846031262591],
              [-81.006525783403362, 35.518581358901457],
              [-81.006196247683619, 35.518539251092008],
              [-81.006248675608632, 35.518274578830464],
              [-81.005260074723537, 35.518148249532665],
              [-81.005312505449012, 35.517883577649762],
              [-81.004323910761585, 35.517757239816405],
              [-81.004271475813823, 35.518021912200069],
              [-81.002953350911895, 35.517853449259206],
              [-81.003058227764811, 35.517324106487798],
              [-81.002728698949127, 35.51728198925457],
              [-81.002938452740338, 35.516223303790937],
              [-81.002608929536393, 35.516181185301726],
              [-81.002766243890846, 35.515387171663271],
              [-81.002436724170892, 35.515345052698002],
              [-81.002489163372388, 35.515080380932098],
              [-81.002159645064026, 35.515038262112924],
              [-81.002212084970964, 35.514773590446708],
              [-81.002541601129707, 35.514815709144422],
              [-81.002594039669432, 35.514551038199905],
              [-81.002923556225198, 35.514593155845212],
              [-81.003080864401539, 35.513799140587608],
              [-81.003410377054962, 35.513841256937667],
              [-81.003515245720621, 35.513311912347362],
              [-81.003185734059556, 35.513269796294622],
              [-81.00329060342969, 35.512740450922749],
              [-81.002961095307455, 35.512698334236319],
              [-81.003013529964548, 35.512433661188666],
              [-81.002684023231552, 35.512391543747022],
              [-81.003051065880925, 35.510538835844329],
              [-81.003380565305491, 35.510580953210201],
              [-81.003485428828796, 35.510051607339534],
              [-81.003814926478512, 35.510093722630288],
              [-81.003867356691131, 35.509829049946298],
              [-81.004196852533326, 35.509871164221089],
              [-81.004354137933589, 35.509077143709376],
              [-81.004683632078383, 35.509119256652603],
              [-81.004736058828911, 35.508854583530443],
              [-81.004406565709161, 35.508812469825621],
              [-81.004511420234493, 35.508283121938746],
              [-81.004181930653218, 35.508241007600112],
              [-81.004234358450148, 35.507976333736622],
              [-81.003904869178015, 35.507934219562436],
              [-81.004009725818364, 35.507404871094266],
              [-81.003680240084648, 35.507362756286291],
              [-81.003732668950292, 35.5070980825828],
              [-81.003073700558375, 35.50701384875962],
              [-81.003126131176288, 35.50674917529556],
              [-81.002796648540354, 35.506707057181686],
              [-81.002849079863466, 35.506442383817486],
              [-81.002190117711606, 35.506358145184976],
              [-81.002242550787116, 35.506093472060236],
              [-81.00191307237337, 35.506051351523418],
              [-81.002017939570493, 35.505522004532921],
              [-81.001688463592771, 35.50547988338068],
              [-81.001740897714456, 35.505215209514851],
              [-81.001411422045905, 35.505173088526995],
              [-81.001463857974798, 35.504908414742985],
              [-81.001134383673062, 35.504866292098889],
              [-81.001186819205003, 35.504601618432893],
              [-81.00085734739433, 35.504559495015641],
              [-81.000909783653626, 35.504294822350907],
              [-81.000580313232049, 35.504252698178647],
              [-81.000685187876485, 35.503723351188682],
              [-81.000355719890933, 35.503681226401085],
              [-81.000460595261643, 35.503151879531664],
              [-81.000131129712102, 35.503109754128687],
              [-81.000183567380432, 35.502845080783281],
              [-80.999854103219846, 35.502802954625352],
              [-80.999958980726916, 35.502273608076599],
              [-80.999300058721701, 35.50218935361741],
              [-80.999352498505175, 35.501924680571925],
              [-80.99869357993957, 35.501840421912533],
              [-80.998746022577222, 35.501575749088509],
              [-80.997757649894496, 35.501449355268853],
              [-80.997810095330948, 35.501184682824096],
              [-80.997480639141116, 35.501142550207952],
              [-80.997533085282527, 35.500877877863175],
              [-80.997203630459353, 35.500835743590876],
              [-80.997256077305721, 35.500571071346222],
              [-80.996597173005597, 35.500486801262198],
              [-80.996649621603893, 35.500222129257253],
              [-80.996320171002594, 35.500179992562451],
              [-80.996372620305792, 35.499915320657557],
              [-80.99604317109349, 35.49987318320796],
              [-80.996095621101489, 35.499608511403146],
              [-80.995766173278113, 35.499566373198604],
              [-80.995818623991084, 35.499301701493948],
              [-80.995489177556607, 35.49925956253454],
              [-80.995541628974351, 35.498994890929907],
              [-80.995212183928771, 35.498952751215654],
              [-80.995317088911946, 35.49842340724777],
              [-80.994987646302036, 35.498381266918337],
              [-80.995092550908751, 35.497851923089691],
              [-80.994763111836718, 35.497809782126986],
              [-80.994815564662858, 35.497545109842378],
              [-80.994486127001835, 35.497502969026137],
              [-80.994643487670487, 35.496708952336022],
              [-80.994314052367727, 35.496666810161216],
              [-80.994681223330815, 35.494814103232798],
              [-80.995010650204023, 35.494856244448094],
              [-80.99506310031488, 35.494591571868668],
              [-80.995392526461174, 35.49463371114863],
              [-80.9955498715793, 35.49383969185331],
              [-80.995879294949475, 35.49388183072103],
              [-80.995931741576655, 35.493617156803232],
              [-80.995602319253223, 35.493575018075205],
              [-80.995812106573737, 35.492516324370776],
              [-80.995482689881143, 35.492474185288792],
              [-80.995744921515779, 35.491150815716615],
              [-80.995415510420727, 35.49110867733981],
              [-80.995467956768437, 35.490844003446853],
              [-80.995138545937891, 35.49080186343226],
              [-80.995190992990388, 35.490537189639603],
              [-80.994861585752403, 35.490495048833971],
              [-80.995018927975764, 35.489701026737578],
              [-80.995348333175784, 35.489743167106056],
              [-80.995400777758533, 35.48947849317404],
              [-80.995071374707081, 35.489436352927115],
              [-80.995123821074216, 35.489171678176135],
              [-80.995453223079039, 35.489213818283325],
              [-80.995558112716495, 35.488684469285168],
              [-80.996216914667443, 35.488768746239643],
              [-80.996269356891176, 35.48850407185278],
              [-80.996598757861719, 35.488546207938548],
              [-80.996703638066066, 35.488016857884432],
              [-80.996374240268494, 35.487974721158714],
              [-80.996426680364479, 35.487710046672035],
              [-80.996097283955407, 35.487667909191543],
              [-80.99614972583592, 35.487403233885928],
              [-80.995820330815349, 35.487361095650748],
              [-80.995872772320681, 35.487096421364775],
              [-80.995543378688581, 35.487054282374977],
              [-80.995595821978327, 35.486789607269962],
              [-80.995266429734713, 35.486747467525504],
              [-80.995371317381654, 35.486218117477144],
              [-80.995041927573041, 35.486175977117632],
              [-80.995146814843508, 35.485646627209555],
              [-80.994817428571778, 35.485604486217007],
              [-80.994869873291563, 35.485339811334704],
              [-80.995199258516749, 35.485381952187538],
              [-80.995304144837633, 35.484852602025839],
              [-80.994974760603569, 35.484810461470452],
              [-80.995184533306286, 35.483751760333469],
              [-80.994855153600085, 35.483709619442457],
              [-80.995169807518721, 35.482121565163375],
              [-80.992534842301978, 35.481784411481584],
              [-80.992639741323714, 35.481255062271146],
              [-80.992310374146001, 35.481212913529802],
              [-80.992467724343996, 35.48041888937076],
              [-80.992572623096791, 35.479889538227447],
              [-80.992901983940797, 35.479931686288396],
              [-80.993111770976412, 35.478872983910655],
              [-80.99344112795481, 35.478915129617278],
              [-80.993493572818196, 35.478650454010555],
              [-80.993164216886143, 35.478608308443668],
              [-80.993478885098057, 35.477020252116837],
              [-80.993808234752251, 35.477062396845419],
              [-80.993860677200232, 35.476797720063772],
              [-80.994519374362099, 35.47688200747838],
              [-80.994571814375632, 35.476617330378232],
              [-80.995230510790179, 35.476701613035296],
              [-80.995282948391434, 35.476436936517992],
              [-80.996270992988983, 35.476563353368803],
              [-80.99621855965043, 35.476828031188354],
              [-80.996547908845102, 35.476870168501932],
              [-80.996600342239006, 35.476605490524541],
              [-80.997259040662726, 35.476689762171631],
              [-80.997311470520458, 35.476425083893972],
              [-80.997640819749677, 35.476467218227455],
              [-80.997693248219321, 35.476202539771052],
              [-80.998022596744022, 35.476244673070639],
              [-80.998284728734362, 35.474921279504599],
              [-80.997955385441202, 35.474879146903469],
              [-80.998112663673098, 35.474085110714526],
              [-80.997783324962199, 35.474042977619973],
              [-80.997940603234923, 35.473248940597728],
              [-80.997611268004391, 35.473206807028014],
              [-80.99766369444329, 35.472942127481431],
              [-80.997993028627505, 35.472984260911488],
              [-80.998045453700584, 35.472719582087485],
              [-80.997716120562572, 35.472677448797192],
              [-80.997768546317857, 35.472412769172635],
              [-80.997439214567819, 35.472370635127781],
              [-80.997491641027551, 35.472105955603993],
              [-80.997162310665445, 35.472063820804557],
              [-80.997214737829722, 35.47179914138151],
              [-80.996885409957173, 35.471757005809536],
              [-80.996937837847952, 35.471492327388361],
              [-80.996608510239639, 35.471450190178906],
              [-80.996660938834822, 35.471185511858465],
              [-80.996331613715967, 35.471143373876373],
              [-80.996384043015624, 35.470878695656673],
              [-80.996054719284786, 35.470836556920048],
              [-80.996159578906713, 35.470307198840814],
              [-80.995830257632079, 35.470265060390695],
              [-80.99588268797649, 35.470000381432321],
              [-80.995553368089745, 35.469958242227833],
              [-80.995658229845588, 35.469428884473714],
              [-80.995328912370695, 35.469386743753184],
              [-80.995381344883711, 35.469122064939391],
              [-80.995052028818833, 35.469079924365666],
              [-80.995104460934485, 35.468815245670733],
              [-80.994775146235341, 35.468773103441443],
              [-80.994880012635264, 35.468243746196229],
              [-80.994550700370056, 35.468201603352156],
              [-80.994603133552545, 35.467936924819924],
              [-80.99361520309175, 35.467810493126663],
              [-80.993562766770978, 35.468075171239732],
              [-80.99257483622597, 35.467948730179124],
              [-80.992627276786536, 35.467684052467128],
              [-80.991968660598943, 35.467599754530077],
              [-80.992073543754984, 35.467070398665214],
              [-80.991744238805708, 35.467028248175403],
              [-80.992111329127241, 35.465175501005362],
              [-80.992440626754444, 35.465217650517033],
              [-80.992755254698238, 35.463629577636851],
              [-80.992425963346761, 35.46358742896355],
              [-80.992740584116135, 35.461999353740687],
              [-80.993069869169872, 35.462041500674331],
              [-80.993122303179291, 35.461776821504529],
              [-80.993451586449623, 35.461818968323755],
              [-80.993608885448438, 35.461024928324001],
              [-80.99393816590063, 35.461067072928778],
              [-80.994043026638039, 35.460537712780273],
              [-80.993713748255473, 35.460495567553714],
              [-80.994028330678191, 35.458907484277546],
              [-80.993699058934538, 35.458865339896676],
              [-80.993803919444957, 35.458335977867179],
              [-80.993474650134658, 35.458293832871803],
              [-80.993579511369916, 35.457764470967128],
              [-80.993250244470801, 35.457722324455901],
              [-80.993355106430784, 35.457192962676018],
              [-80.993025843088503, 35.457150816433455],
              [-80.993078274039917, 35.456886135183666],
              [-80.992749012063101, 35.456843987285637],
              [-80.992801444842073, 35.456579307020149],
              [-80.992472184252748, 35.456537158367901],
              [-80.992524616612144, 35.456272477320304],
              [-80.992195357410282, 35.456230327913858],
              [-80.992247790495824, 35.455965647868666],
              [-80.991260019179336, 35.455839194686909],
              [-80.991312456139724, 35.455574514103027],
              [-80.990653944994577, 35.455490207803663],
              [-80.990706383704733, 35.455225527460676],
              [-80.990377129690415, 35.455183373109868],
              [-80.990429569126519, 35.454918693769208],
              [-80.99010031757912, 35.454876537744894],
              [-80.990152757719244, 35.454611858505466],
              [-80.990205196393944, 35.454347178344179],
              [-80.990534446929331, 35.45438933316963],
              [-80.990639322263689, 35.453859972433712],
              [-80.990968569948095, 35.453902126103692],
              [-80.991125877800243, 35.453108084282022],
              [-80.991455122689302, 35.453150236638812],
              [-80.991559990401569, 35.452620874056748],
              [-80.99188923354113, 35.45266302524017],
              [-80.992046528870063, 35.451868981118238],
              [-80.99171728886752, 35.451826830354086],
              [-80.991822153162687, 35.451297467386489],
              [-80.992151391074003, 35.451339617871156],
              [-80.992256250809746, 35.450810254487983],
              [-80.991927016090969, 35.450768104264775],
              [-80.991979446480912, 35.450503422204534],
              [-80.991650213149526, 35.45046127122729],
              [-80.991755075017522, 35.449931908171997],
              [-80.991425844096796, 35.449889755679173],
              [-80.991478275574934, 35.449625074684157],
              [-80.991149046041556, 35.449582921437141],
              [-80.991253911143104, 35.449053558691915],
              [-80.99092468404244, 35.449011404830593],
              [-80.991029548744805, 35.448482041327793],
              [-80.990700324099109, 35.448439887753423],
              [-80.990857623929287, 35.447645842593786],
              [-80.990528402739756, 35.447603687643657],
              [-80.990685701507871, 35.446809641674164],
              [-80.991014919560897, 35.446851796204918],
              [-80.991329501554645, 35.445263700587041],
              [-80.99100028979656, 35.445221547796116],
              [-80.991052720705284, 35.444956864885974],
              [-80.990723510334291, 35.444914711341028],
              [-80.990775941946808, 35.444650028532351],
              [-80.990446734064264, 35.444607874215293],
              [-80.990551596152571, 35.44407850879837],
              [-80.990222390702527, 35.444036353867105],
              [-80.990327254616247, 35.443506988558184],
              [-80.990536978321813, 35.442448256578579],
              [-80.990866176398242, 35.442490410689217],
              [-80.990918604879951, 35.442225727693064],
              [-80.991247803375956, 35.442267881653258],
              [-80.991300230448658, 35.442003197577741],
              [-80.991958625251527, 35.442087501654143],
              [-80.992011049913856, 35.441822818161917],
              [-80.992340247321991, 35.44186496826007],
              [-80.992392670575228, 35.441600283688473],
              [-80.99305106324627, 35.441684581843262],
              [-80.993103484067177, 35.441419896953846],
              [-80.993432679330084, 35.441462045010425],
              [-80.993485098764125, 35.441197359942997],
              [-80.993814293301128, 35.441239506064875],
              [-80.993866711370345, 35.440974821720516],
              [-80.994195905203554, 35.441016966808895],
              [-80.994248321863637, 35.440752281385265],
              [-80.994906708486198, 35.440836569502352],
              [-80.994959122714107, 35.440571883761017],
              [-80.995288315481233, 35.440614025888571],
              [-80.995340728322248, 35.440349339969103],
              [-80.995669920385524, 35.440391481063088],
              [-80.995722330760429, 35.440126795884964],
              [-80.996380714922225, 35.440211075094226],
              [-80.99643312284276, 35.439946388697074],
              [-80.997091505178574, 35.440030664069965],
              [-80.997039099348768, 35.440295350746517],
              [-80.997368293175498, 35.440337487213931],
              [-80.997420697959953, 35.440072800397658],
              [-80.998737469174344, 35.44024133592373],
              [-80.998789868356738, 35.439976649429646],
              [-80.99911906177789, 35.440018780247513],
              [-80.999171459551391, 35.439754092674271],
              [-80.999500652290777, 35.439796223359849],
              [-80.99944825446147, 35.440060911091066],
              [-81.000435835639706, 35.440187297339541],
              [-81.000488230332877, 35.439922609189232],
              [-81.001146616681979, 35.440006862552977],
              [-81.001199008942976, 35.439742174085019],
              [-81.001528201584208, 35.43978429928648],
              [-81.001580591357254, 35.439519610658799],
              [-81.001909783272282, 35.439561733925544],
              [-81.002066947406945, 35.43876766739394],
              [-81.002396137650692, 35.438809790230934],
              [-81.002500908819499, 35.438280411189574],
              [-81.00283009731389, 35.438322532853519],
              [-81.002934865026504, 35.437793153379836],
              [-81.003264050670268, 35.437835273888858],
              [-81.003368816028114, 35.437305893964904],
              [-81.003697998799169, 35.437348012417758],
              [-81.00375037993183, 35.437083322709363],
              [-81.004079563100007, 35.437125440110627],
              [-81.004131942846115, 35.43686075022439],
              [-81.004790307036799, 35.436944982985764],
              [-81.004842683227409, 35.436680291898973],
              [-81.005501047793814, 35.436764520787762],
              [-81.005553421552463, 35.436499829383536],
              [-81.00588260331331, 35.436541942798257],
              [-81.005987347716356, 35.436012559595909],
              [-81.006316527727819, 35.43605467183766],
              [-81.006368898372116, 35.435789980039566],
              [-81.006698077657276, 35.435832090346658],
              [-81.006802815831435, 35.435302706356772],
              [-81.007131992288237, 35.435344816410243],
              [-81.007289093854411, 35.434550739720457],
              [-81.007618267494266, 35.434592847560005],
              [-81.00782772726869, 35.433534076646723],
              [-81.008156897090998, 35.433576183935244],
              [-81.008418706686783, 35.432252718098717],
              [-81.008747871601713, 35.432294822894164],
              [-81.008957308484611, 35.431236047726657],
              [-81.009286469582065, 35.431278151971064],
              [-81.009338826348525, 35.43101345772827],
              [-81.009667986719606, 35.431055560038097],
              [-81.009720342099797, 35.430790865617553],
              [-81.010049501766701, 35.430832966894144],
              [-81.010154209435484, 35.430303578561023],
              [-81.010483366251947, 35.430345678682933],
              [-81.010535719619412, 35.430080983850807],
              [-81.010864875731883, 35.430123082939417],
              [-81.010969577150931, 35.429593692918445],
              [-81.011298731514003, 35.429635790834148],
              [-81.011351080666756, 35.429371095627019],
              [-81.011021927348764, 35.429328997850931],
              [-81.011283673177417, 35.428005520141056],
              [-81.010954525426357, 35.427963422169498],
              [-81.011059223456058, 35.427434030918654],
              [-81.010730079237518, 35.427391932314372],
              [-81.010887126848203, 35.42659784512184],
              [-81.010557985005008, 35.426555746061055],
              [-81.01071503265905, 35.425761658044621],
              [-81.010385895393341, 35.425719558490911],
              [-81.010490594214218, 35.425190166569543],
              [-81.010161459379717, 35.425148066401505],
              [-81.010318508188178, 35.424353978654096],
              [-81.00998937680761, 35.424311877110007],
              [-81.010041726773423, 35.424047181258118],
              [-81.010198774624257, 35.423253093474962],
              [-81.009869647764873, 35.423210991595916],
              [-81.009974346694563, 35.422681598929067],
              [-81.009645223389612, 35.422639497318727],
              [-81.010064013803913, 35.420521925353711],
              [-81.010393128749158, 35.420564025847007],
              [-81.010497819734596, 35.420034631540588],
              [-81.010826934031968, 35.420076730842716],
              [-81.010931621563557, 35.419547336105815],
              [-81.011589844821813, 35.419631532408765],
              [-81.01164218653669, 35.419366834695268],
              [-81.011971296520272, 35.419408930934402],
              [-81.012023636849221, 35.419144233043511],
              [-81.01235274725218, 35.419186329132465],
              [-81.012562098732076, 35.418127534866564],
              [-81.012232992531111, 35.418085440237355],
              [-81.012337668315169, 35.417556043157134],
              [-81.012008564544956, 35.417513947913584],
              [-81.012060903509749, 35.417249248996683],
              [-81.011731801125478, 35.417207152999325],
              [-81.011836479043083, 35.41667775625119],
              [-81.011507380168169, 35.416635658719976],
              [-81.011612058811693, 35.416106262100229],
              [-81.01128296236746, 35.416064163954807],
              [-81.011387641714506, 35.415534766562217],
              [-81.011058547723096, 35.415492668703905],
              [-81.011267906504983, 35.414433874024901],
              [-81.010938818112436, 35.414391774912147],
              [-81.011095836202657, 35.413597678935353],
              [-81.010766751285487, 35.413555579347992],
              [-81.010819090644716, 35.413290880759526],
              [-81.010490007113305, 35.413248780418343],
              [-81.01054234717634, 35.412984081931796],
              [-81.010213265030643, 35.412941980836813],
              [-81.010317947302042, 35.412412583110637],
              [-81.009988867609081, 35.41237048230284],
              [-81.01009355058379, 35.411841083804198],
              [-81.010751707277194, 35.411925285752076],
              [-81.010804045613256, 35.411660586176176],
              [-81.011133122876302, 35.411702685679394],
              [-81.011185460927493, 35.411437985907988],
              [-81.011843614364537, 35.411522181053648],
              [-81.011895948906769, 35.411257481884853],
              [-81.012883178473842, 35.411383767032973],
              [-81.012935509518755, 35.411119066506444],
              [-81.013264585693136, 35.411161160230172],
              [-81.013473902284872, 35.410102357189324],
              [-81.013144830311703, 35.410060264925391],
              [-81.013197160753037, 35.409795564192564],
              [-81.012868090143215, 35.4097534702736],
              [-81.012972749890821, 35.409224069011124],
              [-81.012643681711154, 35.409181974477931],
              [-81.012748342184608, 35.408652573344504],
              [-81.012419277536083, 35.408610478178872],
              [-81.012523938713016, 35.408081076273291],
              [-81.012853001272234, 35.40812317115963],
              [-81.012905330315945, 35.407858470461463],
              [-81.012576268801368, 35.407816375714752],
              [-81.012733256976375, 35.407022272011744],
              [-81.012404198958876, 35.406980177691835],
              [-81.012718172351029, 35.405391969209667],
              [-81.012389122020352, 35.405349873914709],
              [-81.012493779172686, 35.404820470766317],
              [-81.012164730193604, 35.404778375776885],
              [-81.012269388049148, 35.40424897185671],
              [-81.011940342600809, 35.404206876234852],
              [-81.011992672050766, 35.403942173907559],
              [-81.011663626886957, 35.403900077550361],
              [-81.011768286875608, 35.403370673964083],
              [-81.011439245220259, 35.40332857607325],
              [-81.01149157575901, 35.403063874814272],
              [-81.011162535488936, 35.403021776169766],
              [-81.011267198711238, 35.402492372899275],
              [-81.010938160871106, 35.402450273640767],
              [-81.011042823718185, 35.401920870518175],
              [-81.010713788307882, 35.401878770645723],
              [-81.010766120253408, 35.401614068717315],
              [-81.010437087329109, 35.401571968072915],
              [-81.010541752308967, 35.401042565284563],
              [-81.010212720713682, 35.401000464044429],
              [-81.010265054826363, 35.400735762265008],
              [-81.009936024616394, 35.400693660271337],
              [-81.01004069280728, 35.400164256897774],
              [-81.009711665027055, 35.400122154290145],
              [-81.009764000205877, 35.399857452677843],
              [-81.009434973811025, 35.399815349316604],
              [-81.009487309693384, 35.399550647806656],
              [-81.009158285784551, 35.39950854367347],
              [-81.009262956414091, 35.398979140857413],
              [-81.008933934934902, 35.398937036110311],
              [-81.009143278437932, 35.397878228749825],
              [-81.00947229576191, 35.397920333839437],
              [-81.009629296115577, 35.397126227066643],
              [-81.009300281946935, 35.397084123297347],
              [-81.009561946858966, 35.395760611373092],
              [-81.009232939353438, 35.395718507390711],
              [-81.009389937770436, 35.39492439965975],
              [-81.009718942165335, 35.394966504124362],
              [-81.010032921285372, 35.39337828505262],
              [-81.009703924278881, 35.393336182309234],
              [-81.010017897372791, 35.391747961834298],
              [-81.009688906972528, 35.391705859035795],
              [-81.009741235661735, 35.3914411551822],
              [-81.009412245545846, 35.391399051648385],
              [-81.009464574938534, 35.391134347897328],
              [-81.009935524136552, 35.388752012465424],
              [-81.009606546981971, 35.38870990849761],
              [-81.009763525923518, 35.387915795554676],
              [-81.010092501046316, 35.38795789908508],
              [-81.010354121283754, 35.386634374859199],
              [-81.010683090404115, 35.386676475915024],
              [-81.010735412596418, 35.386411770454799],
              [-81.011064381035624, 35.386453871379182],
              [-81.011221341335599, 35.385659754375141],
              [-81.011550306961112, 35.38570185308609],
              [-81.011654943002512, 35.385172441291552],
              [-81.011983906902856, 35.385214539731294],
              [-81.012088539471051, 35.384685126607998],
              [-81.012417501624086, 35.38472722387521],
              [-81.012731382709376, 35.383138983716641],
              [-81.01240242792106, 35.383096887269211],
              [-81.012454741110901, 35.382832180889721],
              [-81.012125787729559, 35.382790084590134],
              [-81.012282728342541, 35.3819959638464],
              [-81.011953778411495, 35.381953866171465],
              [-81.012006093427118, 35.381689159905164],
              [-81.011019247731653, 35.381562862860619],
              [-81.011071565538543, 35.381298156976484],
              [-81.010742619058036, 35.381256056008674],
              [-81.010794936445407, 35.380991349344299],
              [-81.010465991371817, 35.380949248524374],
              [-81.009479159251299, 35.380822938885743],
              [-81.009531480473598, 35.380558232743176],
              [-81.008873596558871, 35.380474022425915],
              [-81.008925920628812, 35.380209316507582],
              [-81.008268039064319, 35.380125102915905],
              [-81.008215712906562, 35.380389808554803],
              [-81.007886772680891, 35.38034769981082],
              [-81.007939099882449, 35.380082994311636],
              [-81.007610159940953, 35.380040884832539],
              [-81.00766248784565, 35.379776179436149],
              [-81.007333549288788, 35.379734069203792],
              [-81.007385878997169, 35.379469363891957],
              [-81.007056941824843, 35.379427252906275],
              [-81.007161600106073, 35.378897842487675],
              [-81.00683266646277, 35.378855730870299],
              [-81.006884996136051, 35.378591025745301],
              [-81.006556063877255, 35.378548913374658],
              [-81.006608394253718, 35.378284208352468],
              [-81.006279463379613, 35.378242095228494],
              [-81.006331794436804, 35.377977389407967],
              [-81.005673935798441, 35.377893160760557],
              [-81.005726268624841, 35.377628456083798],
              [-81.004739486354495, 35.377502106834442],
              [-81.004791823049743, 35.377237401620661],
              [-81.004462897351615, 35.377195283557953],
              [-81.004410560713012, 35.377459988613872],
              [-81.004081634311987, 35.377417869518418],
              [-81.004133971994392, 35.377153164602284],
              [-81.003805046977874, 35.377111044753683],
              [-81.003857386463693, 35.376846339922295],
              [-81.003528462831753, 35.376804219320427],
              [-81.003580803042752, 35.376539515493157],
              [-81.003251880773192, 35.376497393236818],
              [-81.003356562237954, 35.375967984849886],
              [-81.003027642419028, 35.375925862881388],
              [-81.00307998367289, 35.37566115832179],
              [-81.002751066338831, 35.375619035581927],
              [-81.002855748811101, 35.37508962664986],
              [-81.00252683388311, 35.375047502395304],
              [-81.002579176201991, 35.374782798004723],
              [-81.002250262680732, 35.374740673898259],
              [-81.002302604602107, 35.374475969628797],
              [-81.001973692465469, 35.374433844769172],
              [-81.002078379551662, 35.373904435461682],
              [-81.001749469843148, 35.373862309988667],
              [-81.001801813929745, 35.373597605870032],
              [-81.00147290558364, 35.373555478742716],
              [-81.001577594821796, 35.373026070674641],
              [-81.001248688903772, 35.372983942933914],
              [-81.001301034055317, 35.372719238984487],
              [-81.000972130622216, 35.372677110472459],
              [-81.001024476476502, 35.372412406626026],
              [-81.00069557332742, 35.372370277379048],
              [-81.000800266078656, 35.371840868954067],
              [-81.000471366480326, 35.371798739976875],
              [-81.000523713377277, 35.371534035398405],
              [-81.000194814040768, 35.37149190478501],
              [-81.000299510021833, 35.370962496680406],
              [-80.999970613113476, 35.370920365453792],
              [-81.000022962197548, 35.370655661927678],
              [-80.999694066673626, 35.370613529947981],
              [-80.999746416438271, 35.370348825623651],
              [-80.999417522298685, 35.370306692890885],
              [-80.999469872766156, 35.370041988669605],
              [-80.999140981133593, 35.36999985606689],
              [-80.999193331203358, 35.369735151966822],
              [-80.998864440933048, 35.369693017709722],
              [-80.998916791705525, 35.36942831371281],
              [-80.998587902819509, 35.369386178702641],
              [-80.998640255395117, 35.369121474790674],
              [-80.998311367893592, 35.369079339027437],
              [-80.998363721171771, 35.368814635218577],
              [-80.998034835054554, 35.368772498702342],
              [-80.998087187935184, 35.3685077950147],
              [-80.997758304302749, 35.368465657727299],
              [-80.998072422845581, 35.366877433830652],
              [-80.997743545814913, 35.366835296489377],
              [-80.997795899474667, 35.366570591905116],
              [-80.997467022728017, 35.366528453828977],
              [-80.997519377090399, 35.366263749347866],
              [-80.997190502828374, 35.36622161050061],
              [-80.997242856815205, 35.365956907042069],
              [-80.996913983937489, 35.365914767441822],
              [-80.997018695131644, 35.365385358855562],
              [-80.996689824681752, 35.365343218642174],
              [-80.996794535499063, 35.364813810207224],
              [-80.996465667476784, 35.364771669380715],
              [-80.996518023967724, 35.364506965239038],
              [-80.996189157329738, 35.364464823659617],
              [-80.996293870275508, 35.363935415564121],
              [-80.995965007165424, 35.363893273353469],
              [-80.996017364698432, 35.36362856848033],
              [-80.995688501872351, 35.363586425534876],
              [-80.995897931735286, 35.362527608957535],
              [-80.996226789287675, 35.362569751361704],
              [-80.99627914511629, 35.362305046305494],
              [-80.996608001988193, 35.362347188578319],
              [-80.996712709436082, 35.361817778094618],
              [-80.997041564540098, 35.36185991829371],
              [-80.997093916181427, 35.361595213767423],
              [-80.997422771683148, 35.361637352915785],
              [-80.997527471812475, 35.361107941671413],
              [-80.997198619497723, 35.361065802784601],
              [-80.997250971216801, 35.360801098130587],
              [-80.996922120264045, 35.360758957589624],
              [-80.996974471563263, 35.360494252155775],
              [-80.995987925046407, 35.360367828281653],
              [-80.996040280234894, 35.360103123212696],
              [-80.995382585592125, 35.360018835556808],
              [-80.995434942526344, 35.359754130730934],
              [-80.995106097870817, 35.359711986136226],
              [-80.995053739893223, 35.359976690822215],
              [-80.994724893435063, 35.359934545213029],
              [-80.994777252455847, 35.359669840666989],
              [-80.994448408459817, 35.359627693385562],
              [-80.994500768183102, 35.359362988942827],
              [-80.993843082244268, 35.359278693802189],
              [-80.993895443691002, 35.359013988701435],
              [-80.992908920997138, 35.358887538804467],
              [-80.992961285254808, 35.358622834988083],
              [-80.992632446433873, 35.358580682769983],
              [-80.992737176013094, 35.358051275307396],
              [-80.992408339619416, 35.358009122476446],
              [-80.992513069899388, 35.357479714246367],
              [-80.992841904206642, 35.357521866797526],
              [-80.992894267780997, 35.357257162037257],
              [-80.993223101385951, 35.357299313555913],
              [-80.993380186956472, 35.356505199537615],
              [-80.99370901777263, 35.356547349743948],
              [-80.993761377897215, 35.356282644557993],
              [-80.993432548124147, 35.356240494491566],
              [-80.993484908951046, 35.35597578940903],
              [-80.99282725363615, 35.355891487761163],
              [-80.992879616208441, 35.355626782921867],
              [-80.992550789566366, 35.355584631358518],
              [-80.992707878367639, 35.354790517041693],
              [-80.992379056273904, 35.354748364085943],
              [-80.992483782523308, 35.354218954639109],
              [-80.99281260253079, 35.354261107315054],
              [-80.992864964101116, 35.353996402397044],
              [-80.993193784484291, 35.354038553121079],
              [-80.993298503416, 35.353509142914184],
              [-80.993627320953763, 35.3535512924839],
              [-80.99373203753602, 35.353021881833484],
              [-80.994060853328335, 35.353064030230776],
              [-80.994165565338903, 35.352534618271854],
              [-80.994494379408053, 35.352576766397924],
              [-80.994651442277629, 35.351782647307409],
              [-80.994980252458191, 35.351824794139382],
              [-80.99529436159338, 35.350236552352989],
              [-80.995623165856401, 35.350278697453],
              [-80.995832559836785, 35.349219867426129],
              [-80.995503759767956, 35.349177723786795],
              [-80.995713152446214, 35.348118892837306],
              [-80.995384357990019, 35.348076748846772],
              [-80.995698442470911, 35.34648850128125],
              [-80.995369654591087, 35.346446356336159],
              [-80.995474348396598, 35.345916939875892],
              [-80.995145562943449, 35.345874794318078],
              [-80.995250257471781, 35.345345377992643],
              [-80.99492147446729, 35.345303232723332],
              [-80.995235558288243, 35.343714981878399],
              [-80.995564333957674, 35.343757127227853],
              [-80.995669024112459, 35.34322770940377],
              [-80.995340249428793, 35.343185564352204],
              [-80.995444939206493, 35.342656146681392],
              [-80.995773711804461, 35.34269829145309],
              [-80.995878398111586, 35.342168872456575],
              [-80.99620716786464, 35.342211016074188],
              [-80.996311850723345, 35.34168159665321],
              [-80.996640618731334, 35.341723739098498],
              [-80.996745298141732, 35.341194319253269],
              [-80.997074064404842, 35.34123646052636],
              [-80.997178740366977, 35.340707040256824],
              [-80.997507504885135, 35.340749180357605],
              [-80.997559841312381, 35.340484470028784],
              [-80.99788860510624, 35.340526608195951],
              [-80.998097941015928, 35.339467765095179],
              [-80.998426701001407, 35.339509902711718],
              [-80.998531364815918, 35.338980480214389],
              [-80.998860123056474, 35.339022616658696],
              [-80.999017112534375, 35.338228481781059],
              [-80.999345866887339, 35.338270616931375],
              [-80.999712154953784, 35.336417632131997],
              [-80.999383407899913, 35.336375497960717],
              [-80.999488060332922, 35.335846073435469],
              [-80.999159315705, 35.335803938651367],
              [-80.999263969961007, 35.335274514243778],
              [-80.998935227759119, 35.335232378846968],
              [-80.998987554868975, 35.334967666738052],
              [-80.998658814050302, 35.334925530588777],
              [-80.998711141862273, 35.334660818583792],
              [-80.998382403526662, 35.334618681663734],
              [-80.998487060192161, 35.334089256924315],
              [-80.998158324282315, 35.334047119391599],
              [-80.998210653157955, 35.333782407558374],
              [-80.997553183345417, 35.333698130113653],
              [-80.997224448400189, 35.333655990061693],
              [-80.997172116418795, 35.333920702376552],
              [-80.996514646564606, 35.333836419297612],
              [-80.996566980631243, 35.333571707262536],
              [-80.99623824780754, 35.33352956451526],
              [-80.99629058259822, 35.333264853485474],
              [-80.994646927680179, 35.333054126179569],
              [-80.994594586598822, 35.33331883742931],
              [-80.994265854719032, 35.33327668934578],
              [-80.994161169426974, 35.333806110556232],
              [-80.993503703618899, 35.33372181113419],
              [-80.99345135836559, 35.333986520955051],
              [-80.992465159608571, 35.333860063799001],
              [-80.992412809809039, 35.334124774083378],
              [-80.992084076880772, 35.334082619166423],
              [-80.992031726797791, 35.334347329256872],
              [-80.991702992089856, 35.334305174226934],
              [-80.991755344315195, 35.334040464258415],
              [-80.991426610990487, 35.333998308475998],
              [-80.991478963917785, 35.333733598611417],
              [-80.988849123360254, 35.333396318551777],
              [-80.988796762070436, 35.333661026395482],
              [-80.988468032912266, 35.333618862563611],
              [-80.988520395222679, 35.333354153958517],
              [-80.988191667425795, 35.333311988472943],
              [-80.988139304072817, 35.333576696937996],
              [-80.987810575574258, 35.33353453042001],
              [-80.987758209738203, 35.333799238727259],
              [-80.987429480537813, 35.333757071176919],
              [-80.987377114396111, 35.334021778388873],
              [-80.987048384516072, 35.333979610707459],
              [-80.986996015891179, 35.334244317761403],
              [-80.986338555046316, 35.334159978540292],
              [-80.986286183995489, 35.33442468527835],
              [-80.985957453030693, 35.334382513738575],
              [-80.985905080596552, 35.334647220300553],
              [-80.985576348952065, 35.334605048629662],
              [-80.985523975134512, 35.334869755015688],
              [-80.985195242766295, 35.334827581411169],
              [-80.985142867565315, 35.335092287621222],
              [-80.98481413339556, 35.335050113002339],
              [-80.984657003719391, 35.335844230978083],
              [-80.984328266762859, 35.335802055046827],
              [-80.984275888113132, 35.33606676083263],
              [-80.983947151554773, 35.336024583851056],
              [-80.983842390047585, 35.336553995052604],
              [-80.983513651744957, 35.336511816898529],
              [-80.98330412154732, 35.337570637389412],
              [-80.982975378315203, 35.337528457801028],
              [-80.98292299412941, 35.337793162013064],
              [-80.981608020440035, 35.337624434175133],
              [-80.981555630664801, 35.337889138710274],
              [-80.981226887052316, 35.3378469543797],
              [-80.981174496971533, 35.338111657819553],
              [-80.979202036179302, 35.33785853135425],
              [-80.979149638423834, 35.338123234837042],
              [-80.977834667204903, 35.337954465151391],
              [-80.97793947073751, 35.337425060171469],
              [-80.977610731975901, 35.337382866006486],
              [-80.977663134262144, 35.337118163151807],
              [-80.977005657625625, 35.33703377155944],
              [-80.977058062777743, 35.33676906983208],
              [-80.976729326002712, 35.336726872386812],
              [-80.976834136247206, 35.33619746822049],
              [-80.975190471278466, 35.335986471658977],
              [-80.975242880540918, 35.33572176978042],
              [-80.973927959504863, 35.335552955756306],
              [-80.973980373696108, 35.335288254384139],
              [-80.973651645623704, 35.335246049458718],
              [-80.973704060516241, 35.33498134819051],
              [-80.973375333805663, 35.334939141611656],
              [-80.973427749399562, 35.334674440447571],
              [-80.973099024072582, 35.334632233116373],
              [-80.973151440367829, 35.334367532056312],
              [-80.972493992843951, 35.334283115898472],
              [-80.972546410882984, 35.334018415082497],
              [-80.971888967886542, 35.333933994716318],
              [-80.971993807089234, 35.333404592635489],
              [-80.971665087659474, 35.333362382304379],
              [-80.971769928682306, 35.332832980341749],
              [-80.971441211656796, 35.332790768497297],
              [-80.971493632159863, 35.332526068061618],
              [-80.970836203416638, 35.332441641940093],
              [-80.970888625641521, 35.332176940847461],
              [-80.969573775318636, 35.33200807847512],
              [-80.969468919967099, 35.332537478637867],
              [-80.969140206429472, 35.332495260529242],
              [-80.96908777718852, 35.332759959965898],
              [-80.969416492868348, 35.332802178196694],
              [-80.969101918733642, 35.334390375765842],
              [-80.968773196799305, 35.334348156694197],
              [-80.968720765169991, 35.334612855878454],
              [-80.968063320218292, 35.334528413876548],
              [-80.967958452865986, 35.335057811558407],
              [-80.967629728828115, 35.335015589389322],
              [-80.967472419341561, 35.335809684364513],
              [-80.967801146506801, 35.335851906954133],
              [-80.967696274620366, 35.336381303391718],
              [-80.968025005333558, 35.336423526252666],
              [-80.967972569909989, 35.336688224106801],
              [-80.967315107883465, 35.336603778997002],
              [-80.967262670033762, 35.336868476534747],
              [-80.96660520728733, 35.336784027575227],
              [-80.966552767011308, 35.337048724796517],
              [-80.965566572851998, 35.336922043333956],
              [-80.965619017355252, 35.336657346515175],
              [-80.964961558, 35.33657288773086],
              [-80.965014004246967, 35.336308191156412],
              [-80.964685276662777, 35.33626596010631],
              [-80.964737722510918, 35.336001263653763],
              [-80.964408996332352, 35.335959032752761],
              [-80.964461443959706, 35.335694335485151],
              [-80.962817823333594, 35.335483165592343],
              [-80.962765370493997, 35.335747862159096],
              [-80.962436646341658, 35.335705625002817],
              [-80.96238419211835, 35.335970321393191],
              [-80.96205546726506, 35.335928083204386],
              [-80.961950555687537, 35.336457474695173],
              [-80.961293102857027, 35.336372995981094],
              [-80.961345560912122, 35.33610830098484],
              [-80.960359387321574, 35.335981575289942],
              [-80.960411849262371, 35.335716880660371],
              [-80.959425681893421, 35.335590148256451],
              [-80.959530610961821, 35.3350607579277],
              [-80.958215735348332, 35.334891769700313],
              [-80.958268203539831, 35.334627075042768],
              [-80.95793948625213, 35.334584825674476],
              [-80.95799195514455, 35.334320131121039],
              [-80.957334524777977, 35.334235629970337],
              [-80.957386995413643, 35.333970935661185],
              [-80.95705828233487, 35.333928683878732],
              [-80.957110753671401, 35.333663989673838],
              [-80.956453329522986, 35.333579483730375],
              [-80.95650580260272, 35.333314789769894],
              [-80.956177092633013, 35.33327253559105],
              [-80.956229566413555, 35.333007841734776],
              [-80.955900857827572, 35.332965586803901],
              [-80.956005806449014, 35.332436199263618],
              [-80.955677101367243, 35.332393942801986],
              [-80.955782050707242, 35.331864555397992],
              [-80.955453346973371, 35.331822299243647],
              [-80.955505822151693, 35.331557604726491],
              [-80.955834524864954, 35.331599861641848],
              [-80.956096890466654, 35.330276389617097],
              [-80.956754285373492, 35.330360898484784],
              [-80.956859225037647, 35.329831508140778],
              [-80.957187920358081, 35.329873760514069],
              [-80.957240388657496, 35.329609066048981],
              [-80.957569084376814, 35.32965131737204],
              [-80.957674016723672, 35.329121926268698],
              [-80.957345324188481, 35.329079675208405],
              [-80.957502723824703, 35.32828558869555],
              [-80.957831413233166, 35.32832783933506],
              [-80.957936342165894, 35.327798447872006],
              [-80.958265029831466, 35.32784069733885],
              [-80.958317493293478, 35.327576001404303],
              [-80.958646180258157, 35.327618249838622],
              [-80.958803563248011, 35.326824160532816],
              [-80.958474879409778, 35.326781912519216],
              [-80.95873718311374, 35.32545842911798],
              [-80.958408505927338, 35.325416180895559],
              [-80.958460966237652, 35.325151483894537],
              [-80.957803614951615, 35.32506698415235],
              [-80.957961001208218, 35.324272894658662],
              [-80.957632329214377, 35.324230644320799],
              [-80.957579866780264, 35.324495340107532],
              [-80.956922520632901, 35.324410836492454],
              [-80.95681758951568, 35.324940229217546],
              [-80.955502894037267, 35.324771208338881],
              [-80.955555364484781, 35.324506512546478],
              [-80.954569350401172, 35.324379737476555],
              [-80.954621823655145, 35.324115042970362],
              [-80.954293154011737, 35.324072782669255],
              [-80.954345627944676, 35.323808087366167],
              [-80.954016959684679, 35.323765826313249],
              [-80.954069435418035, 35.323501131096783],
              [-80.953412102028167, 35.323416607496256],
              [-80.953464578404436, 35.323151912542315],
              [-80.952478585095704, 35.323025120402903],
              [-80.952426105593332, 35.323289814935968],
              [-80.952097440424311, 35.323247548670466],
              [-80.952044958438719, 35.323512243045045],
              [-80.951387628162308, 35.323427708440441],
              [-80.95144011223185, 35.323163014346491],
              [-80.950454121922277, 35.323036204826415],
              [-80.950401634726575, 35.323300898499426],
              [-80.94974430784373, 35.323216354072194],
              [-80.94963932825975, 35.323745740749295],
              [-80.949310662696703, 35.323703466925707],
              [-80.949258171350436, 35.323968160070166],
              [-80.947943509445594, 35.323799055275174],
              [-80.947996004960032, 35.323534362692122],
              [-80.947338678690912, 35.323449805213684],
              [-80.947286181092551, 35.323714497516036],
              [-80.946957516856955, 35.323672216405882],
              [-80.946852517451617, 35.324201600639874],
              [-80.946523851495286, 35.324159319258165],
              [-80.946418848617867, 35.324688702166533],
              [-80.946090180919015, 35.324646419611966],
              [-80.945985174612616, 35.325175802997229],
              [-80.945656505149799, 35.325133518368538],
              [-80.945603999892285, 35.325398209875743],
              [-80.945275329750629, 35.325355925115723],
              [-80.945170316104907, 35.325885306840426],
              [-80.945498988330641, 35.325927591881182],
              [-80.945288962185629, 35.326986354541596],
              [-80.945617638921277, 35.327028639251544],
              [-80.945565132292657, 35.32729332974624],
              [-80.945236454514941, 35.327251044895846],
              [-80.945183946502553, 35.32751573521422],
              [-80.944855266924677, 35.327473449348936],
              [-80.944802757528436, 35.327738139490897],
              [-80.943816719896731, 35.327611275184481],
              [-80.943764207032629, 35.327875964869328],
              [-80.94343552673736, 35.327833675154643],
              [-80.943383012489463, 35.328098364663092],
              [-80.943054331472283, 35.328056073014629],
              [-80.942949299867053, 35.328585451642859],
              [-80.942620617128952, 35.328543159722656],
              [-80.942568098650383, 35.328807847958721],
              [-80.942239416311693, 35.328765554988379],
              [-80.942186896470801, 35.329030243949305],
              [-80.941858212310464, 35.328987949062764],
              [-80.941805692185483, 35.329252637829633],
              [-80.941477007346336, 35.329210342811777],
              [-80.94142448471618, 35.32947503051858],
              [-80.941095800276472, 35.329432734450442],
              [-80.940833176831163, 35.33075617354455],
              [-80.939189728143404, 35.330544673695435],
              [-80.939242258735263, 35.330279987011295],
              [-80.938913571056659, 35.330237684144777],
              [-80.938808507468281, 35.330767058097507],
              [-80.938479818047398, 35.330724754057947],
              [-80.938322215864076, 35.331518813862196],
              [-80.938650908389974, 35.3315611174217],
              [-80.93849330623479, 35.33235517642111],
              [-80.93882200225049, 35.332397480410911],
              [-80.938611865771335, 35.333456224892373],
              [-80.938940567397466, 35.333498528534058],
              [-80.938677893455036, 35.334821957460974],
              [-80.938349186639343, 35.334779654018533],
              [-80.93829664911452, 35.335044339205133],
              [-80.937967941598487, 35.335002034730017],
              [-80.938020480144047, 35.33473734878261],
              [-80.937363068200739, 35.334652736535269],
              [-80.937415608510406, 35.334388051733761],
              [-80.936758199997584, 35.334303435297009],
              [-80.936810742049758, 35.33403875074027],
              [-80.93615333811033, 35.333954131899162],
              [-80.936100793973864, 35.334218816175017],
              [-80.935114686942086, 35.334091880358486],
              [-80.935009590311694, 35.33462124887798],
              [-80.934680887293354, 35.334578934561684],
              [-80.934523237195705, 35.335372986188951],
              [-80.934194530292856, 35.33533067057656],
              [-80.934141978893663, 35.335595353933208],
              [-80.932827150533484, 35.335426081999572],
              [-80.93287970720138, 35.335161399187193],
              [-80.932551002008623, 35.335119079113603],
              [-80.932603558276838, 35.334854396423061],
              [-80.931617448977036, 35.334727431252865],
              [-80.931564888482313, 35.334992113539613],
              [-80.930907482274776, 35.334907465344827],
              [-80.930854919353749, 35.335172147314438],
              [-80.928553999436176, 35.334875848669498],
              [-80.928606569652445, 35.334611167683391],
              [-80.927949169580899, 35.334526502527375],
              [-80.927896597280423, 35.334791183232532],
              [-80.927239195393284, 35.334706514244232],
              [-80.92718662068765, 35.334971195533377],
              [-80.926857919204409, 35.334928859109709],
              [-80.926805343093235, 35.335193539320954],
              [-80.926147939090328, 35.335108864417279],
              [-80.926095360552694, 35.335373544311196],
              [-80.925437955834056, 35.335288865557629],
              [-80.925385373770098, 35.335553545151775],
              [-80.925056671992422, 35.335511204729322],
              [-80.925004088544043, 35.33577588414672],
              [-80.924675384945289, 35.335733541807713],
              [-80.924570214937816, 35.336262900252869],
              [-80.924241509618099, 35.336220557641788],
              [-80.924188923037548, 35.336485235768144],
              [-80.92386021701806, 35.336442892124381],
              [-80.923807629074389, 35.336707570975257],
              [-80.92347892233353, 35.336665225397418],
              [-80.923426331905659, 35.336929904088969],
              [-80.923097624464859, 35.336887557478299],
              [-80.923045033752473, 35.337152235975644],
              [-80.922716325611844, 35.337109888332186],
              [-80.922768918466303, 35.336845209958007],
              [-80.922440211731313, 35.336802862464211],
              [-80.922492804185794, 35.336538184211697],
              [-80.922164098814008, 35.336495835064994],
              [-80.922216693068279, 35.336231156899331],
              [-80.921559285456979, 35.336146457111575],
              [-80.921611881453273, 35.335881779190871],
              [-80.920954478373915, 35.335797075196638],
              [-80.921007075012312, 35.335532397538209],
              [-80.920349675386291, 35.335447690256295],
              [-80.920244476498752, 35.335977044992013],
              [-80.919587072973457, 35.335892333596405],
              [-80.919534470382104, 35.336157010637429],
              [-80.919205769190782, 35.336114653443339],
              [-80.91915316521488, 35.336379330307544],
              [-80.918167058368525, 35.336252252984998],
              [-80.918219665470687, 35.335987576542557],
              [-80.917233564831747, 35.335860491611967],
              [-80.91728617581785, 35.335595815537694],
              [-80.916957476965024, 35.335553452256129],
              [-80.917010088650869, 35.335288776286212],
              [-80.915366607989981, 35.335076945344063],
              [-80.91531399109374, 35.335341620610997],
              [-80.914985295395169, 35.335299251958922],
              [-80.914932677114564, 35.335563927048966],
              [-80.914603980695006, 35.335521556462801],
              [-80.914551361029908, 35.33578623137592],
              [-80.914222662832032, 35.335743860675684],
              [-80.914170041782342, 35.336008535411914],
              [-80.913841343963583, 35.335966162760194],
              [-80.913788721529457, 35.336230837319533],
              [-80.913460021932295, 35.336188464553565],
              [-80.913407398092346, 35.336453138034834],
              [-80.913078697795626, 35.336410764236028],
              [-80.913026072571142, 35.336675437540364],
              [-80.912697372674685, 35.336633062691362],
              [-80.91264474496576, 35.336897735836061],
              [-80.912316044369689, 35.33685535995432],
              [-80.91221078696141, 35.337384706737524],
              [-80.911882083502519, 35.33734232879835],
              [-80.911829453803051, 35.33760700243689],
              [-80.911172045828465, 35.337522243600517],
              [-80.910698337593857, 35.339904295074973],
              [-80.910369624762311, 35.339861913953854],
              [-80.910264349654383, 35.340391256954923],
              [-80.90993563398122, 35.340348874677595],
              [-80.909882995960572, 35.340613545514969],
              [-80.90955427958778, 35.340571162204753],
              [-80.909448998255826, 35.341100504424929],
              [-80.908462847097297, 35.340973346459748],
              [-80.908515491050537, 35.340708675339172],
              [-80.908186776105765, 35.340666288302018],
              [-80.90813412998935, 35.340930958397962],
              [-80.907805414345006, 35.340888570327898],
              [-80.907752766843728, 35.341153240246705],
              [-80.908081484651333, 35.341195629341392],
              [-80.907870897631241, 35.342254310983414],
              [-80.908199619928951, 35.342296698847306],
              [-80.908094326447653, 35.34282603973125],
              [-80.908423051172406, 35.342868426984218],
              [-80.908370405520614, 35.343133097954173],
              [-80.9086991316303, 35.343175484455514],
              [-80.908646486656963, 35.343440154628475],
              [-80.908317759505053, 35.343397767986424],
              [-80.908265112046891, 35.343662437999612],
              [-80.907936384174207, 35.343620049423329],
              [-80.907831087245413, 35.34414938904164],
              [-80.907502357631003, 35.344106999291732],
              [-80.907397057247152, 35.344636338482879],
              [-80.90542467317033, 35.344381980476314],
              [-80.905372015660973, 35.344646649181698],
              [-80.904385825661564, 35.344519457251387],
              [-80.904175178568892, 35.345578129135781],
              [-80.903188978047069, 35.345450927503805],
              [-80.903136312570638, 35.345715595182547],
              [-80.90280757797332, 35.34567319272481],
              [-80.90275491109071, 35.345937859325034],
              [-80.901768708428449, 35.345810646141331],
              [-80.901821378437802, 35.345545979963276],
              [-80.901492646290407, 35.34550357301783],
              [-80.901439974159871, 35.345768239973779],
              [-80.90111124133422, 35.345725832896591],
              [-80.901058567797662, 35.345990498774029],
              [-80.900072367150557, 35.345863271783948],
              [-80.90001969014439, 35.346127937202581],
              [-80.899362222288261, 35.346043113570829],
              [-80.89930954287594, 35.346307779572719],
              [-80.898980808980369, 35.346265366719756],
              [-80.898928128161913, 35.346530031643177],
              [-80.898599392445888, 35.346487616873041],
              [-80.898546710263403, 35.346752282520441],
              [-80.897889238896624, 35.346667450905976],
              [-80.897836554265638, 35.346932115333964],
              [-80.896521611477738, 35.346762440834631],
              [-80.896468921255874, 35.347027105581539],
              [-80.895811449834895, 35.346942262696658],
              [-80.895864142141107, 35.346677598231302],
              [-80.895535408547786, 35.346635176033239],
              [-80.895588101574319, 35.346370512573422],
              [-80.894273173635895, 35.34620081273512],
              [-80.894220476440509, 35.346465475631788],
              [-80.893891743461765, 35.346423048989635],
              [-80.893839044881219, 35.34668771170896],
              [-80.893510312282274, 35.346645283115201],
              [-80.893457612316567, 35.346909945657281],
              [-80.893128877939333, 35.34686751694894],
              [-80.893076176588423, 35.34713217931364],
              [-80.892747442591002, 35.347089748653758],
              [-80.892642035675763, 35.347619073008964],
              [-80.892313298836868, 35.347576641192383],
              [-80.891997063584498, 35.349164611633903],
              [-80.891668320835151, 35.349122178080179],
              [-80.891615613249513, 35.349386838791901],
              [-80.891286869800965, 35.349344404205056],
              [-80.891234159750894, 35.349609065657731],
              [-80.890905415603129, 35.349566630037863],
              [-80.890852705267818, 35.349831291295921],
              [-80.890523960420836, 35.349788854642966],
              [-80.890471247579171, 35.350053514839466],
              [-80.890142502033001, 35.350011077153354],
              [-80.889826211545596, 35.351599037619323],
              [-80.889497459009618, 35.351556599114403],
              [-80.88939202401086, 35.352085918394295],
              [-80.889063270812514, 35.352043477796954],
              [-80.889010550655954, 35.352308137258198],
              [-80.888681796758505, 35.352265695627729],
              [-80.888629076295771, 35.352530353993011],
              [-80.888300320598944, 35.352487911346515],
              [-80.888247598771684, 35.352752570435563],
              [-80.887918842375669, 35.352710126755916],
              [-80.887971566345357, 35.35244546779046],
              [-80.887314057767597, 35.35236057801864],
              [-80.887261331713276, 35.352625236702366],
              [-80.885946315606503, 35.352455445870348],
              [-80.885840854108935, 35.352984761099428],
              [-80.886169609430397, 35.353027210431982],
              [-80.885905956002674, 35.354350499000127],
              [-80.885577194369361, 35.354308048980521],
              [-80.88547172806696, 35.354837363695445],
              [-80.885142965792269, 35.35479491248465],
              [-80.885037494911231, 35.355324225886775],
              [-80.884708729794909, 35.355281773518932],
              [-80.884655993347252, 35.355546430015472],
              [-80.883340932124284, 35.355376610156071],
              [-80.883393673841539, 35.355111954205924],
              [-80.882736147363829, 35.355027038753299],
              [-80.882788889722349, 35.354762383065335],
              [-80.882131367802657, 35.354677464307905],
              [-80.882078622280176, 35.354942120632565],
              [-80.881749860232389, 35.354899659332439],
              [-80.881697113303403, 35.355164314578055],
              [-80.881039589295014, 35.355079390804441],
              [-80.88098683993806, 35.355344045731513],
              [-80.880658076835516, 35.355301581472574],
              [-80.880605326092905, 35.355566236221961],
              [-80.880276562312218, 35.355523771831088],
              [-80.880223810183907, 35.355788426402782],
              [-80.879895045683369, 35.355745960077293],
              [-80.879842292169386, 35.356010614471366],
              [-80.879513526990692, 35.355968148014],
              [-80.879460772090994, 35.356232802230281],
              [-80.879132006192492, 35.356190333838363],
              [-80.879079249907079, 35.356454987876944],
              [-80.878750483309673, 35.356412518451712],
              [-80.87869772453827, 35.356677172329682],
              [-80.878368957241904, 35.356634701871172],
              [-80.878316198184947, 35.356899355554361],
              [-80.877987430210425, 35.356856884963861],
              [-80.877934668646802, 35.357121537585087],
              [-80.87727713162225, 35.357036592543935],
              [-80.877329894149582, 35.356771939320389],
              [-80.874042239994168, 35.35634715927992],
              [-80.874095013701577, 35.356082507411912],
              [-80.87343748906838, 35.355997540617217],
              [-80.873384713276351, 35.356262192203282],
              [-80.873055950432644, 35.356219707326431],
              [-80.873003173254816, 35.356484358734647],
              [-80.872345646492619, 35.356399385120824],
              [-80.872292866886667, 35.356664036210354],
              [-80.87163533943459, 35.356579059646549],
              [-80.871582556279577, 35.356843709532924],
              [-80.871253792026636, 35.356801219771583],
              [-80.871201007506414, 35.357065870381405],
              [-80.870543479025898, 35.356980886981425],
              [-80.870490692077496, 35.357245537272505],
              [-80.870161926770606, 35.357203044552136],
              [-80.8701091384157, 35.357467693764086],
              [-80.869780372409963, 35.357425200010383],
              [-80.869727582669157, 35.357689849044405],
              [-80.869398815964786, 35.357647354257438],
              [-80.8692932322882, 35.358176652851228],
              [-80.868964463842687, 35.358134156889875],
              [-80.868911670986449, 35.358398805531301],
              [-80.868582901842217, 35.358356308536656],
              [-80.868530107600051, 35.358620957000184],
              [-80.86820133663629, 35.358578458087898],
              [-80.868042947641385, 35.359372403752552],
              [-80.867714174994447, 35.359329903507813],
              [-80.867502979926371, 35.360388495456192],
              [-80.866845426245007, 35.360303492079815],
              [-80.866898227082785, 35.360038845339943],
              [-80.865911902805195, 35.359911333546769],
              [-80.865859097760733, 35.360175980781818],
              [-80.86553032190622, 35.36013347463593],
              [-80.865477515475703, 35.360398121693017],
              [-80.865148740043438, 35.360355615398063],
              [-80.865095932206273, 35.360620261375914],
              [-80.864767154975056, 35.360577754064536],
              [-80.864714345772484, 35.360842400765655],
              [-80.864056791315363, 35.360757381364152],
              [-80.864003979684355, 35.361022027746287],
              [-80.863675201379024, 35.360979516574432],
              [-80.863622388361904, 35.361244162778561],
              [-80.863293609357854, 35.361201650573321],
              [-80.863240794954521, 35.361466296599453],
              [-80.862254456898725, 35.361338755107589],
              [-80.862201639003985, 35.361603399772278],
              [-80.861872859290315, 35.361560883715491],
              [-80.861820040030054, 35.361825529103463],
              [-80.861491259617807, 35.361783012013206],
              [-80.861438437871143, 35.362047657240048],
              [-80.861109656760419, 35.362005139116398],
              [-80.860634238222374, 35.364386935979667],
              [-80.860305446973541, 35.364344415710825],
              [-80.860146964050116, 35.365138345718506],
              [-80.859818171138869, 35.365095825018528],
              [-80.85971251204549, 35.365625110922501],
              [-80.859383716272305, 35.365582588163477],
              [-80.859330885177712, 35.365847231369465],
              [-80.85900208870585, 35.365804707576984],
              [-80.858896422279187, 35.366333992711134],
              [-80.858567625166629, 35.366291467727258],
              [-80.858461955279424, 35.366820752430918],
              [-80.858133155325518, 35.366778226289235],
              [-80.858080319363438, 35.367042867985305],
              [-80.857751518711098, 35.367000340810137],
              [-80.857698680262203, 35.367264982344878],
              [-80.857369878911314, 35.367222454136183],
              [-80.857317040196932, 35.36748709637714],
              [-80.85665943644193, 35.367402037000325],
              [-80.856606594177819, 35.367666678037565],
              [-80.85627779177382, 35.367624146869503],
              [-80.856172104128632, 35.368153428550329],
              [-80.855843299983647, 35.368110896207568],
              [-80.855790454602555, 35.368375536851232],
              [-80.855461649759064, 35.368333003474902],
              [-80.85540880299159, 35.368597643940326],
              [-80.855079997449494, 35.368555109530469],
              [-80.85492145083461, 35.369349029396005],
              [-80.854592642509118, 35.369306493670386],
              [-80.854381238389436, 35.370365051799865],
              [-80.854710052006027, 35.370407588974381],
              [-80.854657200323032, 35.370672228116888],
              [-80.854986015305542, 35.370714763638723],
              [-80.854457497722962, 35.373361156113617],
              [-80.854786323475295, 35.373403692154106],
              [-80.854680618567514, 35.373932970113898],
              [-80.854351789629433, 35.373890433808043],
              [-80.854193225438436, 35.374684349160489],
              [-80.853864393716535, 35.374641811538787],
              [-80.853705823300942, 35.375435726132409],
              [-80.854034658150724, 35.375478264177531],
              [-80.853981800952695, 35.375742901852284],
              [-80.853652965060249, 35.375700363666141],
              [-80.853600107575915, 35.375965001145637],
              [-80.853271270984806, 35.375922461925832],
              [-80.853218411033794, 35.37618710014501],
              [-80.852560736798637, 35.376102018745556],
              [-80.852507875497977, 35.376366655727146],
              [-80.851521363577703, 35.376239025609358],
              [-80.851468497724994, 35.376503663048354],
              [-80.851139660050194, 35.3764611171488],
              [-80.851192526965988, 35.376196480752199],
              [-80.850206020296355, 35.376068840808763],
              [-80.850258889975549, 35.375804203897005],
              [-80.849930055783119, 35.375761655610866],
              [-80.849982927281644, 35.375497019687494],
              [-80.848996430956888, 35.375369368078303],
              [-80.848943556350989, 35.375634004479501],
              [-80.847957061096537, 35.375506344397465],
              [-80.847904183018542, 35.375770980337911],
              [-80.847575350172477, 35.375728424761874],
              [-80.847522471807977, 35.375993060506993],
              [-80.847193638263519, 35.3759505038973],
              [-80.84714075739133, 35.376215138579418],
              [-80.846811923168858, 35.376172581837217],
              [-80.846653276459378, 35.376966485219398],
              [-80.846324439453241, 35.376923927161265],
              [-80.846218670440024, 35.377453195624696],
              [-80.845889831672395, 35.377410635490385],
              [-80.845784059196788, 35.377939903522112],
              [-80.845455217587656, 35.37789734222968],
              [-80.845402329790744, 35.378161976048254],
              [-80.845073488583807, 35.378119413705299],
              [-80.844967709871668, 35.378648680948089],
              [-80.842336974869056, 35.378308149634186],
              [-80.842389872738522, 35.378043517161075],
              [-80.842061034688271, 35.378000946627616],
              [-80.842113933276536, 35.377736315159709],
              [-80.841127422119087, 35.377608598661233],
              [-80.8411803245708, 35.377343966661506],
              [-80.840851488882848, 35.377301392857191],
              [-80.840904392032812, 35.377036760961325],
              [-80.837287239126823, 35.37656838987651],
              [-80.837234323407799, 35.376833020235381],
              [-80.836905491853031, 35.376790435721176],
              [-80.836852575847516, 35.377055065884633],
              [-80.836523743594555, 35.377012480336667],
              [-80.836470825101529, 35.377277110338156],
              [-80.83581316062407, 35.377191935364223],
              [-80.835707318413881, 35.377721193788744],
              [-80.835378484066652, 35.377678605139423],
              [-80.835325561412375, 35.377943234605006],
              [-80.834339058369849, 35.377815461059242],
              [-80.834286132243307, 35.378080090063477],
              [-80.833957297168752, 35.378037496660461],
              [-80.83401022545867, 35.377772868682065],
              [-80.833681391771137, 35.377730274527821],
              [-80.833787248281709, 35.377201017856848],
              [-80.833458417023948, 35.377158423092631],
              [-80.833564274241439, 35.376629166554686],
              [-80.833235446513768, 35.376586571164019],
              [-80.8336588687404, 35.374469540158039],
              [-80.833330049697395, 35.374426945005318],
              [-80.833435903053172, 35.373897686499511],
              [-80.833107087560293, 35.373855091621508],
              [-80.833318795387655, 35.372796573809353],
              [-80.832989984409096, 35.372753978604216],
              [-80.833148763948913, 35.371960089613879],
              [-80.832819956442236, 35.371917493940238],
              [-80.832872884409284, 35.371652864326919],
              [-80.832544077168777, 35.371610267017488],
              [-80.83264993305373, 35.371081007978241],
              [-80.832321129343015, 35.371038410042409],
              [-80.832374058361296, 35.370773780599912],
              [-80.832045256037375, 35.370731181912987],
              [-80.832098184653248, 35.370466552591196],
              [-80.831440583123765, 35.370381352822911],
              [-80.831493514580117, 35.370116723729879],
              [-80.829520726715856, 35.369861103822615],
              [-80.829467789005278, 35.370125732067699],
              [-80.827495007271352, 35.369870078285707],
              [-80.827547951235815, 35.369605450888734],
              [-80.826890362519279, 35.369520225498803],
              [-80.826943308223761, 35.369255598347351],
              [-80.826614515985, 35.369212984897622],
              [-80.826667462366899, 35.368948356948934],
              [-80.825023510413487, 35.368735275235707],
              [-80.824970557719766, 35.368999902494032],
              [-80.824312976602172, 35.368914663109287],
              [-80.824260021458997, 35.369179289146288],
              [-80.823602440735826, 35.369094045892766],
              [-80.82354948316349, 35.369358671609724],
              [-80.822563110426785, 35.369230799178645],
              [-80.822510149382822, 35.369495424434234],
              [-80.821523776621774, 35.369367543547767],
              [-80.820537406963339, 35.369239654630306],
              [-80.820590374261002, 35.368975030223204],
              [-80.81828886694143, 35.368676590365908],
              [-80.817960081560642, 35.368633952531333],
              [-80.818013057951674, 35.368369329201911],
              [-80.817355490309055, 35.36828405113868],
              [-80.817408468419544, 35.368019427153655],
              [-80.816750904240564, 35.367934145803993],
              [-80.816856864716343, 35.367404899172897],
              [-80.816528085228569, 35.367362257442572],
              [-80.816581065431166, 35.367097633770207],
              [-80.816252288430633, 35.367054991272553],
              [-80.816305269350693, 35.366790368605706],
              [-80.815976492616812, 35.366747724472418],
              [-80.81608245658694, 35.366218478392355],
              [-80.815753683402562, 35.366175834534438],
              [-80.815806665352284, 35.3659112111377],
              [-80.816135437494523, 35.365953854854119],
              [-80.81618841805728, 35.365689231278623],
              [-80.816517190602497, 35.365731873944817],
              [-80.81657016979841, 35.365467251091964],
              [-80.816898940545926, 35.365509892740917],
              [-80.816951918334766, 35.36524526880811],
              [-80.817280688385054, 35.365287909423323],
              [-80.817333664786943, 35.365023285311885],
              [-80.817662435240194, 35.365065924876802],
              [-80.817768384925344, 35.364536676259533],
              [-80.818097152538741, 35.364579314665839],
              [-80.818520926890542, 35.362462314908321],
              [-80.818849685411763, 35.362504951307187],
              [-80.81890265489487, 35.362240326362773],
              [-80.819231413818926, 35.362282961711493],
              [-80.819284380794898, 35.362018335703802],
              [-80.819613139021627, 35.362060970018796],
              [-80.819666104630883, 35.361796344733868],
              [-80.819994862160343, 35.361838978015157],
              [-80.820047826362654, 35.361574351650312],
              [-80.820376583194772, 35.36161698389801],
              [-80.820588433488993, 35.360558477485654],
              [-80.820259680825075, 35.360515845803789],
              [-80.820365606538289, 35.359986592649783],
              [-80.820036857403352, 35.359943960341987],
              [-80.820142783821751, 35.359414707322628],
              [-80.81981403711552, 35.359372074405549],
              [-80.819867000279388, 35.359107447088668],
              [-80.819538254959852, 35.359064813420865],
              [-80.819591219941515, 35.358800187093252],
              [-80.819262475988481, 35.358757551773301],
              [-80.819315440567109, 35.358492925566637],
              [-80.819972926693453, 35.358578193228759],
              [-80.820025888843531, 35.358313566702066],
              [-80.820683373164428, 35.358398830529048],
              [-80.820736333985991, 35.358134203665998],
              [-80.821065075071417, 35.358176834107987],
              [-80.821170991376022, 35.357647579119678],
              [-80.820842252374533, 35.357604948960571],
              [-80.820895211041716, 35.357340321101653],
              [-80.821223949001251, 35.357382951119412],
              [-80.821382819829239, 35.356589067797756],
              [-80.821711556107758, 35.356631696482488],
              [-80.821764511312466, 35.356367068192746],
              [-80.822093245793383, 35.356409695860414],
              [-80.822146199611538, 35.356145067392333],
              [-80.822474933395242, 35.356187694026332],
              [-80.822951489533196, 35.353806033124698],
              [-80.822622765126752, 35.353763407763566],
              [-80.822675715498775, 35.353498778925989],
              [-80.823046355033881, 35.351646370672093],
              [-80.823375070025676, 35.351688995819686],
              [-80.82348096168802, 35.351159735448626],
              [-80.823152248779849, 35.351117110583914],
              [-80.823311086264724, 35.350323220183448],
              [-80.822982376846738, 35.350280595752189],
              [-80.823035323761076, 35.350015965675368],
              [-80.822706615709222, 35.349973339592147],
              [-80.82275956222071, 35.349708709636559],
              [-80.822430856675567, 35.349666083687325],
              [-80.822748537999274, 35.348078301275578],
              [-80.823077237294001, 35.348120926376119],
              [-80.823183126228784, 35.34759166499493],
              [-80.8235118237846, 35.347634288920602],
              [-80.823617709237652, 35.347105026208105],
              [-80.823946405054443, 35.347147648958867],
              [-80.824052285966289, 35.346618386734306],
              [-80.824380980044168, 35.346661008310086],
              [-80.824433918931589, 35.346396376550565],
              [-80.824762612312242, 35.346438997092925],
              [-80.824815549813479, 35.346174365155299],
              [-80.825144242496904, 35.346216984664117],
              [-80.825197178611887, 35.345952352548338],
              [-80.82552587059817, 35.3459949710237],
              [-80.825578805326998, 35.345730338729737],
              [-80.825907496615955, 35.34577295617165],
              [-80.825960429958727, 35.345508323699548],
              [-80.826119226799833, 35.344714425178161],
              [-80.825790539657277, 35.344671807400559],
              [-80.825843472663948, 35.344407174923006],
              [-80.825514786927727, 35.344364557296053],
              [-80.825620653970631, 35.343835291612415],
              [-80.825291970641942, 35.343792672474997],
              [-80.825344904698483, 35.343528040170163],
              [-80.82501622387619, 35.343485421166854],
              [-80.825069158609693, 35.343220788065494],
              [-80.824740478073366, 35.343178168328137],
              [-80.824846348590299, 35.34264890229823],
              [-80.824517671581745, 35.342606281935154],
              [-80.824676477898578, 35.341812383039461],
              [-80.824347804359164, 35.341769762208749],
              [-80.824400740130571, 35.341505128410297],
              [-80.824072067977269, 35.341462506829025],
              [-80.82412500446604, 35.341197874036673],
              [-80.822810323422942, 35.341027377554681],
              [-80.82286326483343, 35.340762745274873],
              [-80.822534596485454, 35.340720119516533],
              [-80.82264047813598, 35.340190854261813],
              [-80.822311813315594, 35.340148227877819],
              [-80.822470636322322, 35.339354329694849],
              [-80.82279929913841, 35.339396956539211],
              [-80.823540417525194, 35.335692088713586],
              [-80.82386906388507, 35.335734711817587],
              [-80.824292519173824, 35.333617636055088],
              [-80.823963881124882, 35.333575013181353],
              [-80.824122674014774, 35.332781109146261],
              [-80.824451308939715, 35.332823731595688],
              [-80.825033509964115, 35.329912739785328],
              [-80.825362132658867, 35.329955358902275],
              [-80.825626749675237, 35.328632177180602],
              [-80.825955367507959, 35.328674794698586],
              [-80.826167048966482, 35.327616246685302],
              [-80.826495662998738, 35.327658863646946],
              [-80.826548581461424, 35.327394226411393],
              [-80.8268771947765, 35.327436841438541],
              [-80.82693011185367, 35.327172204025125],
              [-80.826601499579823, 35.32712958913946],
              [-80.826760251849237, 35.326335676204451],
              [-80.827088860999339, 35.326378290665808],
              [-80.827300520614401, 35.325319739328819],
              [-80.826971916708501, 35.325277124515218],
              [-80.827024832052558, 35.325012486051563],
              [-80.826696228452633, 35.324969871405507],
              [-80.82685497554283, 35.324175956221197],
              [-80.826526376510316, 35.32413334109102],
              [-80.826896780506942, 35.322280871164004],
              [-80.827225372271926, 35.322323486205335],
              [-80.827489929582995, 35.321000290468945],
              [-80.827701569888006, 35.319941732857828],
              [-80.827372988592472, 35.319899119072808],
              [-80.827425898014383, 35.319634479282783],
              [-80.827754478268787, 35.319677092926447],
              [-80.827860295097722, 35.319147812938532],
              [-80.828188873614337, 35.319190425407612],
              [-80.828241781021305, 35.318925785209636],
              [-80.82922751439304, 35.319053615957472],
              [-80.829174611208842, 35.319318256563008],
              [-80.829503191081841, 35.319360864563905],
              [-80.829556093224994, 35.319096223816864],
              [-80.830213251961865, 35.319181438663115],
              [-80.830266151678714, 35.318916797597197],
              [-80.830594730502114, 35.318959402639983],
              [-80.830647628833901, 35.318694761396422],
              [-80.83097620698048, 35.318737366307467],
              [-80.831029103906928, 35.318472723985174],
              [-80.8313576802569, 35.318515327879616],
              [-80.83141057581841, 35.31825068628109],
              [-80.831739152550924, 35.318293288224496],
              [-80.831844939460041, 35.317764004652879],
              [-80.832173514454496, 35.317806605421772],
              [-80.832596641018284, 35.315689464938366],
              [-80.832925206929261, 35.315732063701049],
              [-80.83297809558303, 35.315467421281333],
              [-80.833306660797064, 35.315510019010915],
              [-80.833465319311102, 35.314716088440605],
              [-80.833518204427961, 35.314451444850633],
              [-80.833846765842409, 35.314494042024386],
              [-80.833899649574306, 35.314229398256934],
              [-80.83422821027149, 35.314271993496298],
              [-80.834333974621316, 35.313742705570647],
              [-80.834662533580612, 35.31378529963559],
              [-80.834715414198655, 35.313520655477333],
              [-80.835043972460994, 35.313563248509226],
              [-80.835149730583112, 35.313033959802013],
              [-80.835478287107648, 35.313076551659478],
              [-80.835531164611822, 35.312811907110451],
              [-80.835859719339808, 35.312854497951498],
              [-80.835965471214237, 35.312325207561607],
              [-80.83629402420425, 35.312367797228312],
              [-80.836399772621093, 35.311838506411689],
              [-80.836728323873203, 35.31188109490396],
              [-80.83683406773298, 35.311351803677503],
              [-80.837162618346795, 35.311394390978691],
              [-80.837215488159998, 35.311129744727872],
              [-80.837544038076913, 35.311172330996044],
              [-80.83759690652559, 35.310907685469211],
              [-80.83792545464587, 35.310950270721143],
              [-80.83797832278897, 35.310685624098973],
              [-80.83830687021235, 35.310728208317904],
              [-80.838412601186107, 35.31019891471648],
              [-80.838741147971049, 35.310241497744315],
              [-80.83884687548759, 35.309712203716565],
              [-80.839175419435193, 35.309754785586776],
              [-80.839228281636679, 35.309490138377683],
              [-80.839556824887268, 35.309532719214907],
              [-80.839662546177266, 35.309003424406342],
              [-80.839991087689896, 35.309046004069245],
              [-80.840043946757959, 35.308781355568485],
              [-80.840372487573632, 35.308823934198479],
              [-80.840425344177788, 35.308559286438445],
              [-80.840753884296433, 35.308601864035481],
              [-80.840806740615449, 35.308337216081526],
              [-80.84113528003698, 35.308379792645624],
              [-80.841188133851531, 35.308115143629927],
              [-80.842173751056478, 35.308242867548387],
              [-80.842226601404604, 35.307978218072869],
              [-80.843212217481522, 35.308105933559474],
              [-80.84326506548328, 35.307841284508633],
              [-80.844250681510857, 35.307968990645492],
              [-80.844303524926389, 35.307704340250304],
              [-80.844960601562207, 35.307789473540673],
              [-80.8450134425523, 35.307524822827034],
              [-80.845999057490545, 35.30765251474174],
              [-80.846051895014384, 35.307387863568401],
              [-80.847694588973468, 35.307600664575695],
              [-80.847641756653985, 35.307865316455349],
              [-80.849941551430689, 35.308163200300655],
              [-80.849994375364432, 35.307898547448936],
              [-80.850980003722853, 35.308026197898137],
              [-80.851138463015417, 35.307232238774823],
              [-80.851467003359303, 35.307274786417175],
              [-80.851519821755133, 35.307010132857073],
              [-80.851848361401593, 35.307052679466544],
              [-80.851901177313479, 35.306788025746229],
              [-80.852558255575701, 35.306873116909024],
              [-80.852611069062348, 35.306608462870507],
              [-80.853925224405018, 35.306778632145765],
              [-80.853978033384607, 35.306513977506555],
              [-80.854306572313064, 35.306556517450836],
              [-80.85446499516739, 35.305762552877916],
              [-80.854793530217208, 35.30580509152383],
              [-80.855122066709981, 35.305847629261166],
              [-80.855174871172323, 35.305582973153896],
              [-80.855831942007001, 35.305668045688222],
              [-80.855884744064682, 35.305403390163988],
              [-80.857855958545372, 35.305658584619415],
              [-80.857803163832415, 35.30592324097389],
              [-80.858131702375346, 35.305965770545299],
              [-80.85807890726042, 35.30623042702203],
              [-80.858735987458132, 35.306315483772394],
              [-80.858788779371054, 35.306050826128882],
              [-80.859117318944087, 35.306093353025375],
              [-80.85917010949332, 35.305828696106239],
              [-80.859498648348236, 35.305871221068607],
              [-80.859604226334739, 35.305341906840688],
              [-80.859932763451198, 35.305384430629118],
              [-80.85998555088851, 35.305119773320357],
              [-80.860314087307415, 35.305162296076041],
              [-80.860366873360462, 35.304897638590269],
              [-80.861352479396785, 35.305025202920348],
              [-80.861405261963199, 35.304760544074149],
              [-80.862390869045498, 35.30488809903774],
              [-80.86244364816659, 35.304623440633677],
              [-80.863100718829841, 35.304708472841661],
              [-80.863153494405708, 35.304443813235153],
              [-80.863810564360278, 35.30452884159434],
              [-80.863757790845668, 35.304793500581738],
              [-80.864086327938878, 35.304836014007243],
              [-80.864033554022356, 35.305100673116989],
              [-80.865347708830072, 35.30527071577982],
              [-80.865294939854266, 35.305535376302679],
              [-80.865952021938796, 35.305620392107294],
              [-80.86589925470166, 35.305885052876711],
              [-80.866227797299786, 35.305927559582599],
              [-80.866175030739697, 35.306192219555996],
              [-80.866503574721932, 35.306234725511409],
              [-80.866450808880273, 35.306499386491446],
              [-80.866779354246788, 35.306541891696277],
              [-80.866621058822886, 35.30733587212432],
              [-80.866949607655528, 35.307378376860783],
              [-80.866896842502285, 35.307643037078662],
              [-80.867225393818757, 35.307685541047576],
              [-80.867278156831475, 35.307420880705479],
              [-80.867606707450065, 35.307463383641583],
              [-80.867659469078646, 35.30719872312261],
              [-80.867988018999512, 35.307241225025962],
              [-80.868040779243813, 35.306976564330206],
              [-80.868369328466869, 35.307019065200699],
              [-80.868422087306286, 35.306754403426794],
              [-80.868750635831503, 35.306796903264512],
              [-80.868697876933609, 35.307061565196541],
              [-80.869354975975355, 35.307146561594834],
              [-80.869407731712428, 35.306881900298926],
              [-80.870064830065587, 35.306966893749582],
              [-80.870117583356759, 35.306702231234539],
              [-80.8704461314573, 35.306744726489811],
              [-80.870498884484533, 35.306480064682184],
              [-80.870827431866473, 35.306522558003323],
              [-80.870880182410062, 35.306257896035945],
              [-80.871208729114855, 35.306300389225697],
              [-80.871261478253587, 35.306035726180212],
              [-80.871590024260499, 35.306078218337177],
              [-80.871642772015093, 35.305813555114881],
              [-80.8722998640757, 35.305898536454684],
              [-80.872352608305704, 35.305633872931502],
              [-80.872681153259904, 35.305676362131365],
              [-80.872733897205322, 35.305411698414403],
              [-80.873062441461599, 35.305454186581557],
              [-80.873115184022922, 35.305189522687897],
              [-80.873443727581275, 35.305232009822248],
              [-80.873549208493344, 35.304702681662661],
              [-80.873877750312786, 35.304745167623309],
              [-80.873930488663376, 35.304480503357468],
              [-80.87425902976419, 35.304522987384125],
              [-80.874311767830321, 35.304258322924461],
              [-80.875297389009717, 35.304385771050804],
              [-80.875350123609863, 35.304121106132364],
              [-80.875678662901265, 35.304163586327299],
              [-80.875889590409528, 35.303104925783934],
              [-80.87621812588003, 35.303147404523017],
              [-80.876323584944629, 35.302618073763867],
              [-80.876652119775528, 35.302660551312265],
              [-80.87670484775245, 35.302395885738186],
              [-80.877033380785932, 35.302438362270934],
              [-80.877138833608115, 35.301909029832558],
              [-80.877467364923547, 35.301951506092912],
              [-80.877520089768822, 35.301686839228609],
              [-80.877848620386274, 35.301729314456416],
              [-80.877954066966097, 35.301199980338872],
              [-80.878282595844624, 35.301242454392913],
              [-80.878335316479792, 35.300977787156796],
              [-80.878663845738913, 35.301020259259921],
              [-80.878769285018976, 35.300490925283043],
              [-80.879097811439664, 35.300533396229611],
              [-80.879203246146062, 35.30000406094414],
              [-80.879531770827896, 35.30004653071704],
              [-80.879900776391068, 35.298193854175096],
              [-80.880229294150283, 35.298236322970723],
              [-80.88033471850062, 35.2977069855799],
              [-80.880663233400838, 35.297749452317632],
              [-80.880715944031252, 35.297484783878623],
              [-80.881044459332827, 35.297527249566791],
              [-80.881097168558725, 35.297262580049875],
              [-80.881425682062911, 35.297305044722521],
              [-80.881478389925945, 35.2970403759305],
              [-80.881806902732094, 35.297082839570486],
              [-80.881859609190656, 35.296818169700693],
              [-80.884487720071832, 35.297157846428455],
              [-80.88443502191933, 35.297422516524719],
              [-80.884763538909894, 35.297464972123187],
              [-80.884816234922155, 35.297200301903068],
              [-80.885144751193721, 35.297242755567737],
              [-80.885250140087408, 35.296713413837736],
              [-80.885578653541472, 35.296755867247306],
              [-80.885684038961728, 35.296226524192178],
              [-80.886012551776147, 35.296268976411142],
              [-80.886065242931295, 35.296004304689426],
              [-80.886117933743733, 35.295739632932275],
              [-80.886446443719791, 35.295782083994794],
              [-80.886499133148675, 35.295517412061209],
              [-80.886827642426596, 35.295559862091217],
              [-80.886880330471939, 35.295295189981211],
              [-80.887208839051695, 35.29533763897868],
              [-80.887261525692722, 35.295072965791007],
              [-80.887590033574313, 35.295115413756029],
              [-80.887642717753181, 35.294850741310306],
              [-80.887971224936607, 35.294893188242838],
              [-80.888234638743867, 35.293569822924468],
              [-80.888563141065745, 35.293612268260652],
              [-80.888668500785201, 35.293082921443855],
              [-80.888997001347249, 35.293125364705347],
              [-80.889049679662648, 35.292860691553614],
              [-80.889378179526531, 35.292903133782666],
              [-80.889536210352261, 35.292109111872399],
              [-80.889207713589826, 35.29206666916479],
              [-80.889313068215614, 35.291537321624283],
              [-80.888984573877337, 35.291494878306935],
              [-80.889142605787711, 35.29070085581035],
              [-80.888814116013563, 35.29065841200709],
              [-80.888866793306093, 35.290393737917498],
              [-80.889195282018591, 35.290436180678483],
              [-80.889353308718569, 35.289642157774907],
              [-80.88902482310732, 35.289599714535299],
              [-80.889235525798, 35.288541015579881],
              [-80.888907044692232, 35.288498572012543],
              [-80.889012396613026, 35.28796922259621],
              [-80.889340874517373, 35.288011664997626],
              [-80.889498895048035, 35.287217639760861],
              [-80.889827371293023, 35.287260081732057],
              [-80.889932713406225, 35.286730730532227],
              [-80.890261187912557, 35.286773171330132],
              [-80.890313857943326, 35.286508494626574],
              [-80.890970803703823, 35.286593373300363],
              [-80.891023471331721, 35.286328697180934],
              [-80.891680416360074, 35.286413571105733],
              [-80.891733081564155, 35.286148894669303],
              [-80.892061553561916, 35.286191330604126],
              [-80.892114217382954, 35.285926653991538],
              [-80.89277116030361, 35.286011522003633],
              [-80.892823820601677, 35.285746845091268],
              [-80.893152291524558, 35.28578927716854],
              [-80.893257610090146, 35.285259922037703],
              [-80.893586079295261, 35.285302353843008],
              [-80.893691393310547, 35.284772998306678],
              [-80.89401986077678, 35.284815428938714],
              [-80.89407251621968, 35.284550750526201],
              [-80.894400982987733, 35.284593180125874],
              [-80.894506290764937, 35.284063822913495],
              [-80.894834755794264, 35.284106251339928],
              [-80.894887408128568, 35.283841572540076],
              [-80.895215872459715, 35.283883999934218],
              [-80.895373823187285, 35.283089962918048],
              [-80.895702284739031, 35.283132388998105],
              [-80.896018170685167, 35.281544312269951],
              [-80.896346625215671, 35.281586735729178],
              [-80.89655720409705, 35.280528016355234],
              [-80.896228753707717, 35.280485592558236],
              [-80.896281398068368, 35.280220913001784],
              [-80.896609847396164, 35.280263335756629],
              [-80.896662491473194, 35.279998656006995],
              [-80.896334043164899, 35.279956232491656],
              [-80.896386686819852, 35.279691551963737],
              [-80.896058241014558, 35.279649128581994],
              [-80.896321461576164, 35.278325726987248],
              [-80.895993020195561, 35.278283302534433],
              [-80.896098308316212, 35.277753941568818],
              [-80.896426747594788, 35.277796364838679],
              [-80.896479389551672, 35.277531684170974],
              [-80.89615095241254, 35.277489261024876],
              [-80.896256238458122, 35.276959898947759],
              [-80.895927803721207, 35.276917474290606],
              [-80.895980446731983, 35.276652793799641],
              [-80.896138374788265, 35.275858751198022],
              [-80.89580994455541, 35.275816326213125],
              [-80.895862588315282, 35.275551644804466],
              [-80.896191017507746, 35.27559406964847],
              [-80.896243658785934, 35.275329388081147],
              [-80.89591523065485, 35.27528696427931],
              [-80.8959678737301, 35.275022282800549],
              [-80.895639446960743, 35.274979857346956],
              [-80.895744733086957, 35.274450495484878],
              [-80.896073156655618, 35.274492919772712],
              [-80.89623107959649, 35.273698875852624],
              [-80.896559501485299, 35.273741298809249],
              [-80.896664778907081, 35.273211935149938],
              [-80.896993199078466, 35.273254357834702],
              [-80.897045836235549, 35.272989675811736],
              [-80.897374254588797, 35.273032096580181],
              [-80.897426890363263, 35.272767414381399],
              [-80.897755309138503, 35.272809835001794],
              [-80.897807943509434, 35.272545151725922],
              [-80.898136360487442, 35.272587571331229],
              [-80.898188993496802, 35.272322888780856],
              [-80.898517409755797, 35.272365306452691],
              [-80.898570041382499, 35.27210062372653],
              [-80.8988984569434, 35.272143040366238],
              [-80.899003715969485, 35.271613673643309],
              [-80.899332129812876, 35.271656090011255],
              [-80.899805770723987, 35.269273934484133],
              [-80.899477366243175, 35.269231519383915],
              [-80.899529992702099, 35.268966835196956],
              [-80.899201589603734, 35.268924419346369],
              [-80.899254216760639, 35.268659735265331],
              [-80.899582617719773, 35.268702150992382],
              [-80.899687867806591, 35.26817278156016],
              [-80.89935946892794, 35.268130366114853],
              [-80.899517344073558, 35.267336312587169],
              [-80.89918894865778, 35.267293896673173],
              [-80.899294199483748, 35.266764527456203],
              [-80.898965807610523, 35.266722111816712],
              [-80.899071059127067, 35.266192741840946],
              [-80.898742668556437, 35.266150324707979],
              [-80.898795294313274, 35.2658856407187],
              [-80.898466906223945, 35.265843222817999],
              [-80.898519532657659, 35.265578538033544],
              [-80.898191145950676, 35.265536119382482],
              [-80.898401654622461, 35.264477380425873],
              [-80.898073272418245, 35.264434961447094],
              [-80.898231153978031, 35.263640906377411],
              [-80.897902775257378, 35.263598487831338],
              [-80.897955402053086, 35.263333802290163],
              [-80.897627024693691, 35.263291382092511],
              [-80.89767965330725, 35.263026697541534],
              [-80.897351277330131, 35.262984276593492],
              [-80.897403906620596, 35.262719591247375],
              [-80.896747157773177, 35.262634746959542],
              [-80.896799788822619, 35.262370062761789],
              [-80.896143043424004, 35.262285215190929],
              [-80.896195676190487, 35.262020530338951],
              [-80.895538934240804, 35.261935679485077],
              [-80.895591568745104, 35.261670994880227],
              [-80.895919938508783, 35.261713420611798],
              [-80.896025204431737, 35.261184051917475],
              [-80.896353572457457, 35.26122647647616],
              [-80.896458833811678, 35.260697106477082],
              [-80.896787200099595, 35.260739529862768],
              [-80.89689245800507, 35.260210159443211],
              [-80.897220822555113, 35.260252581655905],
              [-80.897273449405091, 35.259987896261883],
              [-80.897601813257268, 35.260030317442457],
              [-80.897864939351805, 35.258706888312346],
              [-80.897536581777473, 35.258664466917651],
              [-80.89758920767342, 35.258399781298507],
              [-80.89726085150221, 35.258357360054923],
              [-80.897892341223027, 35.255181121806096],
              [-80.897563998971975, 35.255138701344883],
              [-80.898248067824468, 35.251697764648654],
              [-80.899233052852068, 35.251825017898597],
              [-80.899180437689253, 35.252089706307821],
              [-80.900165430034548, 35.25221695284479],
              [-80.900218042077896, 35.251952264012928],
              [-80.900874702891642, 35.252037089705375],
              [-80.900927312513289, 35.251772400557194],
              [-80.901583972615526, 35.251857222403515],
              [-80.901636579815502, 35.251592532939213],
              [-80.90426322556344, 35.251931782652946],
              [-80.90431582300404, 35.251667092044592],
              [-80.905300818218265, 35.251794294989786],
              [-80.905353412197371, 35.25152960392446],
              [-80.905681743952726, 35.251572003277865],
              [-80.905734336528894, 35.251307311135953],
              [-80.906062666487557, 35.251349709474709],
              [-80.906115257703149, 35.251085018058824],
              [-80.906443588041498, 35.251127414447097],
              [-80.906496176776514, 35.250862722873173],
              [-80.908466156513839, 35.251117084400754],
              [-80.908413574039002, 35.251381777720844],
              [-80.908741906770473, 35.251424167871413],
              [-80.908689324972556, 35.251688860396513],
              [-80.91000266241943, 35.251858414471016],
              [-80.9101603942878, 35.251064334201551],
              [-80.910488725549783, 35.251106719632482],
              [-80.910646451222334, 35.250312638630703],
              [-80.910974780805205, 35.250355022730702],
              [-80.91113249916225, 35.249560940113007],
              [-80.911460824868598, 35.249603322917004],
              [-80.911513396311221, 35.249338629096613],
              [-80.911841721319476, 35.249381010868674],
              [-80.911999429315259, 35.248586926092337],
              [-80.91232775156692, 35.248629307452276],
              [-80.912432886054887, 35.24809991625245],
              [-80.912761206568646, 35.248142296439852],
              [-80.912866337610154, 35.247612904821047],
              [-80.913194656385997, 35.247655283835769],
              [-80.913299783981046, 35.247125891798056],
              [-80.913628099899114, 35.247168268756241],
              [-80.913785785070729, 35.246374180479201],
              [-80.914114099309799, 35.246416556106659],
              [-80.914166658951331, 35.246151860111205],
              [-80.915479912967754, 35.246321353182466],
              [-80.915532468108481, 35.246056656589531],
              [-80.915860781976264, 35.246099027480319],
              [-80.91591333571445, 35.245834329811103],
              [-80.916569961296645, 35.245919068655333],
              [-80.916622512613529, 35.245654370670337],
              [-80.916950824327941, 35.245696738623792],
              [-80.917055922779937, 35.24516734318815],
              [-80.917384233855046, 35.245209709951773],
              [-80.917489328839793, 35.244680313196177],
              [-80.917817637078372, 35.244722678804685],
              [-80.91802781769124, 35.243663884336918],
              [-80.917699513611751, 35.243621519291331],
              [-80.917804604476373, 35.243092121223398],
              [-80.917476302817946, 35.243049755568357],
              [-80.917528848238959, 35.242785057083132],
              [-80.918185449817855, 35.242869787220812],
              [-80.918237992796634, 35.242605087518626],
              [-80.918894593662401, 35.242689813810806],
              [-80.918947134241321, 35.242425114694214],
              [-80.919275434146797, 35.242467476363025],
              [-80.919222894607586, 35.242732175620333],
              [-80.919551195894314, 35.242774536538946],
              [-80.919498657053296, 35.243039235902657],
              [-80.919826959721206, 35.243081596070937],
              [-80.91977442157841, 35.243346295541237],
              [-80.920102726726313, 35.243388654941711],
              [-80.920050189281753, 35.243653354518578],
              [-80.920378494712196, 35.243695713186149],
              [-80.920325957965872, 35.24396041286942],
              [-80.92065426477771, 35.244002770786729],
              [-80.920601729828292, 35.244267470559016],
              [-80.920930038021396, 35.244309827725928],
              [-80.920877502671601, 35.24457452762212],
              [-80.92153412323924, 35.244659238645639],
              [-80.92148159072616, 35.244923938771535],
              [-80.921809902023398, 35.244966293546696],
              [-80.921757370208624, 35.24523099377901],
              [-80.923398940269493, 35.245442753137816],
              [-80.923451466885268, 35.245178052202064],
              [-80.923779781308738, 35.245220400712142],
              [-80.923832306543119, 35.244955699601455],
              [-80.924160620289527, 35.244998047981177],
              [-80.924213144142641, 35.244733346695476],
              [-80.924541457169383, 35.244775693142167],
              [-80.924593979641315, 35.244510991681636],
              [-80.925578916573258, 35.244638026171906],
              [-80.925526397220864, 35.244902728054448],
              [-80.926183025269665, 35.244987413847028],
              [-80.926130508754198, 35.245252115959126],
              [-80.926458823770602, 35.245294457217483],
              [-80.926511340344902, 35.245029754947232],
              [-80.926839655761512, 35.24507209515636],
              [-80.926892170954673, 35.244807392711209],
              [-80.927220484574178, 35.244849731906214],
              [-80.927272998386002, 35.244585029286178],
              [-80.927601312405699, 35.244627367431875],
              [-80.927653823737771, 35.244362664654439],
              [-80.928310450721554, 35.244447337991446],
              [-80.928362959632523, 35.2441826348985],
              [-80.928691272596609, 35.244224970089796],
              [-80.928743780126368, 35.243960266821922],
              [-80.929400403900345, 35.244044934267585],
              [-80.929452909009115, 35.243780230684315],
              [-80.929781220917533, 35.243822562921075],
              [-80.929833724623805, 35.243557858261681],
              [-80.929505413755237, 35.243515526165531],
              [-80.929715430468079, 35.24245670953335],
              [-80.930043736078773, 35.242499041084585],
              [-80.930096238078534, 35.242234336254192],
              [-80.930752849342809, 35.242318996384682],
              [-80.930805348943053, 35.242054292140153],
              [-80.93113365349808, 35.242096620736923],
              [-80.931186151695897, 35.24183191541632],
              [-80.932171064288923, 35.241958895438707],
              [-80.93222355902607, 35.241694189662098],
              [-80.934193388263907, 35.241948123886083],
              [-80.934245875322475, 35.241683417249284],
              [-80.935230791966816, 35.24181037145086],
              [-80.935283275564657, 35.241545664358156],
              [-80.935939886646196, 35.241630295449859],
              [-80.935992367823317, 35.241365588041866],
              [-80.936977282247639, 35.241492528051843],
              [-80.937029759964062, 35.241227820188001],
              [-80.938014675416269, 35.241354750837843],
              [-80.938067148573495, 35.241090042535795],
              [-80.937738844473358, 35.241047732744086],
              [-80.937791319427987, 35.240783024530771],
              [-80.938119622488301, 35.240825334182013],
              [-80.938277039967275, 35.240031208049828],
              [-80.938605340227809, 35.240073515487047],
              [-80.938657810639839, 35.239808806873782],
              [-80.938986110201569, 35.239851113279407],
              [-80.939038580331285, 35.23958640447394],
              [-80.939366878095811, 35.239628709865535],
              [-80.939419346844574, 35.239364000885438],
              [-80.939747643910465, 35.239406305245495],
              [-80.939800111278387, 35.239141596090782],
              [-80.940128407645446, 35.239183899419203],
              [-80.940180872512485, 35.238919189206172],
              [-80.940509169300839, 35.238961492386785],
              [-80.940561632787151, 35.238696781999131],
              [-80.940889928855285, 35.238739083246877],
              [-80.940994852746527, 35.238209662989547],
              [-80.941323145977663, 35.23825196308281],
              [-80.941428066403887, 35.237722541506862],
              [-80.941756357917811, 35.237764841329373],
              [-80.942123559149778, 35.235911861990672],
              [-80.942451843726687, 35.235954159938473],
              [-80.942556752736124, 35.235424736304289],
              [-80.942885035552962, 35.235467032178768],
              [-80.942989941119137, 35.234937608127652],
              [-80.943318222197405, 35.234979902830005],
              [-80.943580473267886, 35.233656340079598],
              [-80.943908749510257, 35.233698634089755],
              [-80.944118539153763, 35.232639781108013],
              [-80.944446810479889, 35.232682073682732],
              [-80.944499256004192, 35.232417360437282],
              [-80.944170985717705, 35.232375068003037],
              [-80.944223431940841, 35.232110354864162],
              [-80.944551701166148, 35.232152646256587],
              [-80.944656590510291, 35.231623219596074],
              [-80.944984859095769, 35.231665509798859],
              [-80.945089743877219, 35.231136081838052],
              [-80.945456832588718, 35.229283081511916],
              [-80.945785091060088, 35.229325369576955],
              [-80.945889967605495, 35.22879593982416],
              [-80.945561711213273, 35.228753652040126],
              [-80.945614150563429, 35.228488937244663],
              [-80.945942404817657, 35.228531224905844],
              [-80.945994842787485, 35.22826650993602],
              [-80.946323097441393, 35.228308796548106],
              [-80.946427969159359, 35.227779365342307],
              [-80.947412726753456, 35.227906219926659],
              [-80.947465158422588, 35.227641503409806],
              [-80.947793411321058, 35.227683786036735],
              [-80.947740979592979, 35.227948502711733],
              [-80.950038763405672, 35.228244457190947],
              [-80.950091186758257, 35.227979739550243],
              [-80.950419442382795, 35.228022015049838],
              [-80.950471863278338, 35.227757298153854],
              [-80.950800118204043, 35.227799572622089],
              [-80.950852538795999, 35.227534854632758],
              [-80.951180791924443, 35.22757712808734],
              [-80.951233211157813, 35.227312410825093],
              [-80.951561463587382, 35.227354683248215],
              [-80.951613881418794, 35.227089964910462],
              [-80.951942133149586, 35.227132236302268],
              [-80.952099379305892, 35.226338081601725],
              [-80.951771130693842, 35.226295810631342],
              [-80.951823546064105, 35.226031092176115],
              [-80.952151794734974, 35.226073362988416],
              [-80.952256622374478, 35.225543925695959],
              [-80.953241361997129, 35.225670731980024],
              [-80.953188951466373, 35.225935451064423],
              [-80.954830195405904, 35.22614677596458],
              [-80.954882600738983, 35.22588205617798],
              [-80.955867347416756, 35.226008840196734],
              [-80.955919749290459, 35.225744119955202],
              [-80.956247997810252, 35.225786378771652],
              [-80.956405197170895, 35.224992217440914],
              [-80.956733442912679, 35.225034474945289],
              [-80.956785841344953, 35.224769754287905],
              [-80.958755316774415, 35.225023282466417],
              [-80.958702925677841, 35.225288003948421],
              [-80.959359422269372, 35.225372505593484],
              [-80.959411811286799, 35.22510778383068],
              [-80.959740060152129, 35.225150033158684],
              [-80.959792447789582, 35.224885311221726],
              [-80.960448942287528, 35.224969807861143],
              [-80.960501327483669, 35.224705084708496],
              [-80.960829574214571, 35.224747332002011],
              [-80.96088195803064, 35.22448260867526],
              [-80.961210204040825, 35.224524854036304],
              [-80.96126258540022, 35.224260131454514],
              [-80.960934340429588, 35.22421788623393],
              [-80.961091487736795, 35.223423716869554],
              [-80.96076324622527, 35.223381471179181],
              [-80.960868012212998, 35.222852025337112],
              [-80.960539773120999, 35.222809779036588],
              [-80.960592156632799, 35.222545055754821],
              [-80.960263918920873, 35.222502808703808],
              [-80.960421069433792, 35.221708639095382],
              [-80.96009283627906, 35.221666391556852],
              [-80.960197603298269, 35.22113694526977],
              [-80.959869372562949, 35.221094697121096],
              [-80.960026524184258, 35.220300526949231],
              [-80.959698296907703, 35.220258278330796],
              [-80.959855448561541, 35.219464107376268],
              [-80.959527225842137, 35.219421858270458],
              [-80.95963199362906, 35.218892411386598],
              [-80.959303772230655, 35.218850161688437],
              [-80.95935615719111, 35.218585437876968],
              [-80.958699718571083, 35.218500935169949],
              [-80.958752105269951, 35.218236211605713],
              [-80.95842388697163, 35.218193959515958],
              [-80.958528661426669, 35.217664512567538],
              [-80.958856876569527, 35.217706765295588],
              [-80.958961647584076, 35.217177317932133],
              [-80.958633434519939, 35.217135065484904],
              [-80.9586858205558, 35.216870341893262],
              [-80.958357607794895, 35.216828089614602],
              [-80.958409994529646, 35.216563366129812],
              [-80.958081784225143, 35.216521112181802],
              [-80.95813417165887, 35.216256388803878],
              [-80.957805962734156, 35.216214134105364],
              [-80.957858350866744, 35.215949410834341],
              [-80.957530143321847, 35.21590715538553],
              [-80.957582532153197, 35.215642432221379],
              [-80.957254325988075, 35.215600176022207],
              [-80.957359104686432, 35.215070728972826],
              [-80.957030900962053, 35.215028473064976],
              [-80.957083291927177, 35.214763749162053],
              [-80.956755089560914, 35.214721491602603],
              [-80.956912260258449, 35.213927321068731],
              [-80.957240459506878, 35.21396957820695],
              [-80.957292848033561, 35.213704855089127],
              [-80.957621045463299, 35.213747110312717],
              [-80.957935363130417, 35.212158765553909],
              [-80.958263554665109, 35.212201019044151],
              [-80.958787376400124, 35.209553768646543],
              [-80.959115557904994, 35.209596020743298],
              [-80.959220315623497, 35.209066568907652],
              [-80.959548494292235, 35.209108819850634],
              [-80.959600872150858, 35.208844093733461],
              [-80.959929050099035, 35.208886342744059],
              [-80.960033801618778, 35.208356890146604],
              [-80.960361977850695, 35.208399138886975],
              [-80.960414351511858, 35.208134412406629],
              [-80.960742528143143, 35.208176660098097],
              [-80.960794900424816, 35.207911933444095],
              [-80.961451250412068, 35.207996425008375],
              [-80.961503620275053, 35.20773169804022],
              [-80.961831795827308, 35.207773941877505],
              [-80.961884164310931, 35.207509214735644],
              [-80.961555989797802, 35.20746697103872],
              [-80.961608358958458, 35.20720224310265],
              [-80.961936532454132, 35.207244487560423],
              [-80.962041266599911, 35.206715032226562],
              [-80.961713096280619, 35.20667278803176],
              [-80.961922566841324, 35.205613877490336],
              [-80.961594401018743, 35.205571632966404],
              [-80.961803870269407, 35.204512721552568],
              [-80.961475710041512, 35.204470476681806],
              [-80.961632810409355, 35.203676292535107],
              [-80.961304653638805, 35.203634047194903],
              [-80.961461755137591, 35.202839862251082],
              [-80.961133601824486, 35.20279761644133],
              [-80.961185969356364, 35.202532888501089],
              [-80.961514121608857, 35.202575133269136],
              [-80.961566487761473, 35.202310405155266],
              [-80.963207249019234, 35.202521618538931],
              [-80.963364327610961, 35.201727430125274],
              [-80.963692477770536, 35.201769669889259],
              [-80.963849551259671, 35.200975479836423],
              [-80.963521404238804, 35.200933241394907],
              [-80.963626119909094, 35.200403781480453],
              [-80.963297975284661, 35.200361541527862],
              [-80.96340269167203, 35.199832081761507],
              [-80.962746408697711, 35.199747599728113],
              [-80.962798769007932, 35.199482870067051],
              [-80.962142489472384, 35.199398384752044],
              [-80.962194851520351, 35.199133655338613],
              [-80.961538575423504, 35.19904916674205],
              [-80.961590939208989, 35.198784437576279],
              [-80.96126280325899, 35.198742192073567],
              [-80.961367530767333, 35.19821273304003],
              [-80.961039397235297, 35.198170486927559],
              [-80.961091762077956, 35.197905757943182],
              [-80.96076362992504, 35.197863511080627],
              [-80.960815995444648, 35.197598781302261],
              [-80.960487864670782, 35.197556533689443],
              [-80.960540230889009, 35.197291804018377],
              [-80.960212101494363, 35.197249555655524],
              [-80.960264468432783, 35.196984826993052],
              [-80.959936341493574, 35.196942576960943],
              [-80.960041075307785, 35.196413118934053],
              [-80.960369201245612, 35.196455367766212],
              [-80.960421567141708, 35.196190638103147],
              [-80.960749692380929, 35.196232885904429],
              [-80.960854419976613, 35.195703426215999],
              [-80.960526297913049, 35.195661178677746],
              [-80.960631026225471, 35.195131719137919],
              [-80.959974787252619, 35.195047221050245],
              [-80.960027153525033, 35.194782491502657],
              [-80.959699034500659, 35.19474024173185],
              [-80.959751401471621, 35.194475512291596],
              [-80.95942328490257, 35.19443326085166],
              [-80.959475652572181, 35.194168531518798],
              [-80.959147537403823, 35.194126280230016],
              [-80.959199905750197, 35.193861550103236],
              [-80.958871791960874, 35.193819298064426],
              [-80.958924161005768, 35.193554568045023],
              [-80.958596049693512, 35.193512315238387],
              [-80.958648419458555, 35.193247586227656],
              [-80.958320308405618, 35.193205331787389],
              [-80.958948732350166, 35.190028571936161],
              [-80.958620635221564, 35.18998631917426],
              [-80.958882467644784, 35.188662665858416],
              [-80.9592105595801, 35.188704917918109],
              [-80.959524741218488, 35.187116530744369],
              [-80.95985282618615, 35.187158781990149],
              [-80.959905187720921, 35.186894049484359],
              [-80.959577103813615, 35.186851799280376],
              [-80.959629466068463, 35.186587067783492],
              [-80.959301382420421, 35.18654481594605],
              [-80.959406107986837, 35.186015353134522],
              [-80.959078027853977, 35.185973100669663],
              [-80.959130390067671, 35.185708369372826],
              [-80.958802311313576, 35.18566611615806],
              [-80.958854675323408, 35.185401384951035],
              [-80.958526597948065, 35.185359130986321],
              [-80.958578962656333, 35.185094399886857],
              [-80.958250886659727, 35.185052145172101],
              [-80.958303252044587, 35.184787413279032],
              [-80.957975177448375, 35.18474515871565],
              [-80.958027543531585, 35.184480426930094],
              [-80.95769947029244, 35.184438170715552],
              [-80.957751837095714, 35.184173439938903],
              [-80.957095694812807, 35.184088925101726],
              [-80.957043325954231, 35.184353656498722],
              [-80.956387182955197, 35.184269137819058],
              [-80.95643955389086, 35.184004406702968],
              [-80.95611148341294, 35.183962147078191],
              [-80.956163856144713, 35.183697416051999],
              [-80.955835787023801, 35.183655154776069],
              [-80.955888159355993, 35.183390423875274],
              [-80.955560092711721, 35.183348161831695],
              [-80.955821956656891, 35.182024507502362],
              [-80.956150018108843, 35.182066768843541],
              [-80.956411867215209, 35.180743111207114],
              [-80.956739922695803, 35.180785370072094],
              [-80.956897024307622, 35.179991174866458],
              [-80.956568971920532, 35.179948915521656],
              [-80.9566213394681, 35.179684183260548],
              [-80.956949390816703, 35.179726442464869],
              [-80.957054122814398, 35.179196977563542],
              [-80.957382173524167, 35.179239235578784],
              [-80.957643991254201, 35.177915571166793],
              [-80.95797203601451, 35.177957827607152],
              [-80.958076759096471, 35.177428361685891],
              [-80.958404802120242, 35.177470616955098],
              [-80.958718954690923, 35.175882213994306],
              [-80.958390917897034, 35.175839959567966],
              [-80.95891448676548, 35.173192616217015],
              [-80.959242512078887, 35.173234869256476],
              [-80.959294866607934, 35.172970134682764],
              [-80.959622891223191, 35.173012386691589],
              [-80.959675243254566, 35.172747651061336],
              [-80.960003267171544, 35.172789902039369],
              [-80.960055617846294, 35.172525167137429],
              [-80.96103968959892, 35.172651913389679],
              [-80.961092036819011, 35.172387178033723],
              [-80.961420060019691, 35.172429425169959],
              [-80.961472405839814, 35.172164688739684],
              [-80.961800428342286, 35.172206934845121],
              [-80.961957459550035, 35.171412724955509],
              [-80.962285479277824, 35.171454969749469],
              [-80.96233782166145, 35.171190232908067],
              [-80.962494845674641, 35.170396022206511],
              [-80.962822860491954, 35.170438265566304],
              [-80.96292754012282, 35.169908791309879],
              [-80.963255553182009, 35.169951032597282],
              [-80.96341256584472, 35.169156819664032],
              [-80.963740576151011, 35.169199060541217],
              [-80.963845247110243, 35.168669584514554],
              [-80.963517238880286, 35.168627343918295],
              [-80.963674245324327, 35.167833129614216],
              [-80.964002250439918, 35.167875369789243],
              [-80.964054583830119, 35.167610631188055],
              [-80.964382588247403, 35.167652870332461],
              [-80.96453958436183, 35.166858653894977],
              [-80.964867586004658, 35.166900891727934],
              [-80.964919915959015, 35.166636152716066],
              [-80.965247916881864, 35.166678388617115],
              [-80.965300245458124, 35.166413649432378],
              [-80.965628245704579, 35.166455885204016],
              [-80.965837551339192, 35.165396926695855],
              [-80.966165547772988, 35.165439161015755],
              [-80.966217872552008, 35.1651744204868],
              [-80.966545867190007, 35.165216653793891],
              [-80.966598190590986, 35.164951913092153],
              [-80.966270195915243, 35.164909680844609],
              [-80.966322520014543, 35.164644940250881],
              [-80.966650513673926, 35.164687173259288],
              [-80.966807479742599, 35.16389295178174],
              [-80.967135469508307, 35.163935182595409],
              [-80.967501703725659, 35.16208199494104],
              [-80.967829686586612, 35.162124224783],
              [-80.967882002736033, 35.161859483429943],
              [-80.968209984877106, 35.161901711340022],
              [-80.968314615178173, 35.161372228238349],
              [-80.968642594485374, 35.161414454995345],
              [-80.968694908636934, 35.161149713246743],
              [-80.969022887245998, 35.161191938973225],
              [-80.969075200019773, 35.160927197051919],
              [-80.968747221329537, 35.160884970582352],
              [-80.968799533704129, 35.160620228786918],
              [-80.969127511334605, 35.160662454214744],
              [-80.969493681046899, 35.158809254352576],
              [-80.96982165067557, 35.158851478826776],
              [-80.969978572333048, 35.158057249023081],
              [-80.969650605818217, 35.158015024970034],
              [-80.970225968140809, 35.155102841813097],
              [-80.970553923238697, 35.155145064321779],
              [-80.970606224922477, 35.154880319751776],
              [-80.970934179300485, 35.154922540328783],
              [-80.970986479606708, 35.154657795586225],
              [-80.971642386252057, 35.154742235609739],
              [-80.971694684121118, 35.154477489652962],
              [-80.972022637464079, 35.154519708180366],
              [-80.972074933977495, 35.154254962952344],
              [-80.972402885503016, 35.154297179565845],
              [-80.972455181736379, 35.154032434147467],
              [-80.972127230151301, 35.15399021769219],
              [-80.9722318225555, 35.153460726156339],
              [-80.971903874461262, 35.153418508172535],
              [-80.971956170653996, 35.153153762956435],
              [-80.971628223937316, 35.15311154422298],
              [-80.971942003393067, 35.151523069434916],
              [-80.971614063242853, 35.151480850653826],
              [-80.971666360209284, 35.151216104574445],
              [-80.971994298224416, 35.151258323233137],
              [-80.972098887965572, 35.150728830715295],
              [-80.972426824244693, 35.150771048203246],
              [-80.972479117019446, 35.150506301764793],
              [-80.972807053675965, 35.150548517303164],
              [-80.97285934507326, 35.150283770692241],
              [-80.973187279956136, 35.150325986119363],
              [-80.973239569976187, 35.150061239336026],
              [-80.97356750413897, 35.150103452831566],
              [-80.973776656650401, 35.149044463915104],
              [-80.974104587001762, 35.149086675959154],
              [-80.974209159133707, 35.148557180577292],
              [-80.974537087771068, 35.148599392351855],
              [-80.974589372279056, 35.148334644021979],
              [-80.974917300218223, 35.148376854766177],
              [-80.975074146397304, 35.147582609199091],
              [-80.975402071562655, 35.147624818632323],
              [-80.975558912670394, 35.146830572338239],
              [-80.975886833943065, 35.146872779577066],
              [-80.97640960217781, 35.144225284891888],
              [-80.976737513435182, 35.144267490738514],
              [-80.976842060029156, 35.143737990070399],
              [-80.977169969550744, 35.143780194746363],
              [-80.977222241300964, 35.143515444224157],
              [-80.977550150102502, 35.143557646968539],
              [-80.97765469050934, 35.143028145547667],
              [-80.97798259759702, 35.143070348022761],
              [-80.978087134570941, 35.14254084619364],
              [-80.978415039900852, 35.142583046596897],
              [-80.978571838584301, 35.141788792309733],
              [-80.978899741162678, 35.141830992303362],
              [-80.979265584825896, 35.139977727811555],
              [-80.979593479383453, 35.140019925950924],
              [-80.979802520950642, 35.138960915383571],
              [-80.979474630543223, 35.138918717805495],
              [-80.979526891672705, 35.138653965215475],
              [-80.979854781042604, 35.138696162653211],
              [-80.980011557066334, 35.13790190340621],
              [-80.979683670830838, 35.137859707290758],
              [-80.979735930581057, 35.13759495367232],
              [-80.97940804572238, 35.137552756807402],
              [-80.97946030617085, 35.137288003297535],
              [-80.979132422667121, 35.137245804781799],
              [-80.979184683835499, 35.136981052281818],
              [-80.977873159580142, 35.136812250746893],
              [-80.977925424559004, 35.136547498776523],
              [-80.977597544826992, 35.136505296092203],
              [-80.977649810482163, 35.136240543329187],
              [-80.977321933224047, 35.13619833987741],
              [-80.97737419959914, 35.135933588124246],
              [-80.977046323717929, 35.135891383923024],
              [-80.977150856383872, 35.135361878817612],
              [-80.976822982916872, 35.135319674007242],
              [-80.976927517397286, 35.134790169037707],
              [-80.976599646344368, 35.134747963618182],
              [-80.976913246724308, 35.133159447006406],
              [-80.976585383354106, 35.133117242422585],
              [-80.976637650101907, 35.132852489079546],
              [-80.976309786989432, 35.132810282863012],
              [-80.976362054435185, 35.132545529628672],
              [-80.976689916510423, 35.132587735704874],
              [-80.976846712624365, 35.13179347630998],
              [-80.977174570829831, 35.131835681093264],
              [-80.977226834843535, 35.131570927451641],
              [-80.977554692351021, 35.131613131204702],
              [-80.977606954988048, 35.131348377390985],
              [-80.977934811797539, 35.131390580114001],
              [-80.977987071960825, 35.131125826146267],
              [-80.978314929169429, 35.131168027821111],
              [-80.978419446403521, 35.1306385195101],
              [-80.978747300779631, 35.130680720032515],
              [-80.978799558947458, 35.13041596567124],
              [-80.979127412625658, 35.130458165163517],
              [-80.979179668298016, 35.130193409746951],
              [-80.979507521278109, 35.130235608209126],
              [-80.979612029574071, 35.129706098803076],
              [-80.980267732381094, 35.129790491595379],
              [-80.980319983945549, 35.129525736564275],
              [-80.981303537599501, 35.129652319109191],
              [-80.981355785712921, 35.129387563625528],
              [-80.982011486666039, 35.129471946976857],
              [-80.98206373236566, 35.129207191181024],
              [-80.982391582851335, 35.129249380922033],
              [-80.982443826077358, 35.128984624972318],
              [-80.982771675864939, 35.129026813683232],
              [-80.982823918811562, 35.128762057543547],
              [-80.983151766803971, 35.128804245242492],
              [-80.983204008352203, 35.12853948802961],
              [-80.983531855668417, 35.128581675599804],
              [-80.983584095840186, 35.128316918215063],
              [-80.98391194355537, 35.12835910473715],
              [-80.983964181253597, 35.128094347198555],
              [-80.984292027173609, 35.128136532708631],
              [-80.98434426459248, 35.127871774980179],
              [-80.984672109792442, 35.127913958558779],
              [-80.984724344737813, 35.127649200676586],
              [-80.98505219035863, 35.127691384108431],
              [-80.985104423927595, 35.127426626054422],
              [-80.985432268828333, 35.127468807554898],
              [-80.985484501020807, 35.127204049328967],
              [-80.985812344148385, 35.127246230718846],
              [-80.985864574942511, 35.126981471419811],
              [-80.986192417371953, 35.127023651779623],
              [-80.986244646811755, 35.126758893210074],
              [-80.987556017899365, 35.12692760245065],
              [-80.987608241753861, 35.126662843306526],
              [-80.988919614117663, 35.126831537750114],
              [-80.988867394412182, 35.127096297455232],
              [-80.989523084691953, 35.127180639168344],
              [-80.989575302322777, 35.126915879182711],
              [-80.989903146385444, 35.126958048573364],
              [-80.989955363736996, 35.126693288397909],
              [-80.990938895944126, 35.126819790792631],
              [-80.990886682823486, 35.127084552272152],
              [-80.991542375096884, 35.12716888302765],
              [-80.991490163689775, 35.127433643854793],
              [-80.991818011920756, 35.127475808029118],
              [-80.991870222290558, 35.127211047061671],
              [-80.992198068704155, 35.127253209322802],
              [-80.992145859393773, 35.127517971331692],
              [-80.992801557528637, 35.127602294330238],
              [-80.992905970608305, 35.127072771540554],
              [-80.993233818120544, 35.127114931875333],
              [-80.993338227747628, 35.126585407777924],
              [-80.993666073524295, 35.126627566942517],
              [-80.993822681773395, 35.125833280599366],
              [-80.99349484020577, 35.125791121837324],
              [-80.9937558526765, 35.12446730922823],
              [-80.993428016634141, 35.124425150277638],
              [-80.993480218705599, 35.124160387628663],
              [-80.99380805371068, 35.124202546439008],
              [-80.994016856630154, 35.123143494030579],
              [-80.994344687825304, 35.123185651390358],
              [-80.994449084091201, 35.122656125185934],
              [-80.994776913550709, 35.122698281375584],
              [-80.994933502252238, 35.121903989610274],
              [-80.99526132893898, 35.121946144489556],
              [-80.995313523450562, 35.121681380064274],
              [-80.995969174669369, 35.121765686891429],
              [-80.996021366767692, 35.121500922154418],
              [-80.99798832121391, 35.121753819558265],
              [-80.997936136413756, 35.122018584217017],
              [-80.998263964015862, 35.122060730966801],
              [-80.998211779936312, 35.122325496635582],
              [-80.999523101128474, 35.12249407344116],
              [-80.99957528105918, 35.122229307211668],
              [-80.999903111452213, 35.122271449494619],
              [-80.999850932536759, 35.122536214963077],
              [-81.000506595294524, 35.122620496257127],
              [-81.000558772135577, 35.122355730508332],
              [-81.002853597906892, 35.122650684632426],
              [-81.002905767126251, 35.12238591696989],
              [-81.003889265230598, 35.122512312252901],
              [-81.003837100220053, 35.122777080317576],
              [-81.005148441349718, 35.122945594239773],
              [-81.005096280127518, 35.12321036193228],
              [-81.005751954798924, 35.1232946138351],
              [-81.005804113946439, 35.12302984586232],
              [-81.006131950752845, 35.123071970338955],
              [-81.006184108502225, 35.122807201293391],
              [-81.006511944609983, 35.122849324740145],
              [-81.006564101005594, 35.122584556424364],
              [-81.006891936414675, 35.122626678841321],
              [-81.006996246071211, 35.122097140032899],
              [-81.007324079744308, 35.12213926127982],
              [-81.007376233027315, 35.121874491688544],
              [-81.007704066001622, 35.121916611905611],
              [-81.007756217931032, 35.12165184304417],
              [-81.008084049087486, 35.121693961348335],
              [-81.008136199641015, 35.121429192315382],
              [-81.008464031195743, 35.121471309571547],
              [-81.008516179254258, 35.121206539484191],
              [-81.009171841349328, 35.121290772849633],
              [-81.009223986994613, 35.121026002450705],
              [-81.010207479542373, 35.121152344504289],
              [-81.010259621737262, 35.120887573653782],
              [-81.010311763593734, 35.120622802771962],
              [-81.010639593052829, 35.120664915030645],
              [-81.010796013456968, 35.119870600875956],
              [-81.01046818820663, 35.119828489019319],
              [-81.010520328709006, 35.119563718012309],
              [-81.010192504834336, 35.119521605405943],
              [-81.010296786875841, 35.118992062677059],
              [-81.009968965414174, 35.11894994946114],
              [-81.010177529583828, 35.11789086418846],
              [-81.009849712609636, 35.117848750643184],
              [-81.009901854928728, 35.117583978918127],
              [-81.009574039330246, 35.117541864623242],
              [-81.009626181251093, 35.117277093025272],
              [-81.009298367028322, 35.117234977980779],
              [-81.00935050964793, 35.11697020649158],
              [-81.009022697897677, 35.116928090679316],
              [-81.009074841215934, 35.116663319299001],
              [-81.008747029744555, 35.11662120275539],
              [-81.008799174858368, 35.116356431465682],
              [-81.008143555005418, 35.116272195989573],
              [-81.008195701854916, 35.116007424948926],
              [-81.007867893473431, 35.115965306016435],
              [-81.007920040999423, 35.11570053418334],
              [-81.007592233993549, 35.115658414501333],
              [-81.007696530126267, 35.115128871923019],
              [-81.007368725533368, 35.115086751631601],
              [-81.007577316787902, 35.114027666679519],
              [-81.007249517778732, 35.113985546040581],
              [-81.007405959371965, 35.113191231090354],
              [-81.007078163812452, 35.11314910998221],
              [-81.007234606566968, 35.11235479515372],
              [-81.006906814435069, 35.112312672674946],
              [-81.007011110717386, 35.111783129568373],
              [-81.006683321020262, 35.111741007381596],
              [-81.00678761690358, 35.111211463547626],
              [-81.006459830693601, 35.111169339831974],
              [-81.00661627565006, 35.11037502470969],
              [-81.00628849288961, 35.110332900524739],
              [-81.006392789535653, 35.109803356940326],
              [-81.006065009209848, 35.109761233047408],
              [-81.006117157502587, 35.109496460907138],
              [-81.005789379627004, 35.109454335345248],
              [-81.005841528618078, 35.109189563314033],
              [-81.005513751043352, 35.109147437922218],
              [-81.005618052277498, 35.108617894010429],
              [-81.00529027709301, 35.108575767108015],
              [-81.005342428239274, 35.108310995245688],
              [-81.005014654452438, 35.108268868495124],
              [-81.005066806274826, 35.108004095840592],
              [-81.004739033863459, 35.107961968340554],
              [-81.004791186406379, 35.107697196696463],
              [-81.004463416445091, 35.107655067527595],
              [-81.004515569686461, 35.107390295992559],
              [-81.004187800003734, 35.107348166092429],
              [-81.004292107522517, 35.106818622308296],
              [-81.003964341348905, 35.106776491780799],
              [-81.004225108297774, 35.105452632045953],
              [-81.003897347647026, 35.105410501329629],
              [-81.003949501268536, 35.105145729065612],
              [-81.004277260882361, 35.105187859641767],
              [-81.004381565036113, 35.104658314740554],
              [-81.004709322914792, 35.104700444146808],
              [-81.004813622521809, 35.104170897958269],
              [-81.005141378665442, 35.104213026194799],
              [-81.005245674866956, 35.103683480503385],
              [-81.005573429253175, 35.103725606668718],
              [-81.005677722027201, 35.103196060573218],
              [-81.006005474678304, 35.10323818556882],
              [-81.00610976400263, 35.102708638167869],
              [-81.006437513844048, 35.10275076291321],
              [-81.006489656402266, 35.102485988584654],
              [-81.006817406642085, 35.102528112282144],
              [-81.006869547825048, 35.102263337782617],
              [-81.007197296269808, 35.102305460468607],
              [-81.007301576643471, 35.101775911078214],
              [-81.007629323353186, 35.101818032594444],
              [-81.007681461447191, 35.10155325772191],
              [-81.008009207458556, 35.101595378208522],
              [-81.008061344177364, 35.101330603164982],
              [-81.008389090564904, 35.101372721702532],
              [-81.008493360913803, 35.10084317124231],
              [-81.008821104491716, 35.100885289529643],
              [-81.008977503247976, 35.100090962304378],
              [-81.008649762780337, 35.10004884443746],
              [-81.008754029745361, 35.099519293668536],
              [-81.008426291689688, 35.099477175192405],
              [-81.008478425701256, 35.099212399901788],
              [-81.007822953775104, 35.099128160543152],
              [-81.007875089521789, 35.098863385501957],
              [-81.007547354554745, 35.098821264637621],
              [-81.007599490999638, 35.098556489705771],
              [-81.007271758504359, 35.098514368073907],
              [-81.007323895625362, 35.098249592349987],
              [-81.006996164505182, 35.098207469968905],
              [-81.007048302346647, 35.09794269525559],
              [-81.006720571504914, 35.097900572143409],
              [-81.006772711119211, 35.097635796619905],
              [-81.00644498165255, 35.09759367275845],
              [-81.00654926084249, 35.097064121917576],
              [-81.006221534884219, 35.097021997428669],
              [-81.006325813697146, 35.096492446763094],
              [-81.005998090150484, 35.096450321665131],
              [-81.006050230086004, 35.096185546426256],
              [-81.005722507914484, 35.096143420579004],
              [-81.005826789940031, 35.095613870271052],
              [-81.005171350758658, 35.095529616469044],
              [-81.005223493863213, 35.095264840638805],
              [-81.004895775816721, 35.095222712543794],
              [-81.00494791964158, 35.094957937724274],
              [-81.005275635554781, 35.09500006569727],
              [-81.005327777982487, 35.094735289805556],
              [-81.005655493197608, 35.094777416748954],
              [-81.005707633175859, 35.09451264160576],
              [-81.006035348789325, 35.094554767501485],
              [-81.006139625613017, 35.094025215039977],
              [-81.006467339491735, 35.094067339766013],
              [-81.006519476359443, 35.093802563348937],
              [-81.006847188443459, 35.093844687063658],
              [-81.006899323936139, 35.093579910475704],
              [-81.006571612888777, 35.093537786901209],
              [-81.006623749079736, 35.093273010422685],
              [-81.006296039407218, 35.093230886098901],
              [-81.006348176296356, 35.092966109729872],
              [-81.006020467998781, 35.092923984656942],
              [-81.006176879745368, 35.092129655785889],
              [-81.005849174895872, 35.092087530244072],
              [-81.005901312504534, 35.091822754032464],
              [-81.006229016317505, 35.091864879434191],
              [-81.006593962759226, 35.090011440507624],
              [-81.006921659655063, 35.090053564038833],
              [-81.007130187754655, 35.08899445331884],
              [-81.006802496101187, 35.088952330330024],
              [-81.007063154025062, 35.087628440134473],
              [-81.006735467892014, 35.087586316957044],
              [-81.00683973120033, 35.087056760757449],
              [-81.006512047478409, 35.087014636971048],
              [-81.006616311506221, 35.086485080929329],
              [-81.006288630195513, 35.086442956534015],
              [-81.006340762727135, 35.086178178156771],
              [-81.006013082791128, 35.086136053012282],
              [-81.006117348912213, 35.085606496446481],
              [-81.005789672483758, 35.085564370674888],
              [-81.005893939302069, 35.085034813365908],
              [-81.005238590509009, 35.084950559733741],
              [-81.005290725494461, 35.084685781764378],
              [-81.004963053167813, 35.0846436528442],
              [-81.00501518885126, 35.08437887498448],
              [-81.004687517921511, 35.084336746216529],
              [-81.004739654280684, 35.084071967565123],
              [-81.004411984725593, 35.084029838048096],
              [-81.004464121804887, 35.083765060407686],
              [-81.0041364536023, 35.083722929240302],
              [-81.004188591379631, 35.083458151709536],
              [-81.003205593029364, 35.083331754175383],
              [-81.003257733577186, 35.08306697703474],
              [-81.003309873764493, 35.082802198962227],
              [-81.002982211120582, 35.082760064640254],
              [-81.003242913281412, 35.081436175404484],
              [-81.003570571840513, 35.081478309007281],
              [-81.0036227099976, 35.081213530751896],
              [-81.003950366762382, 35.081255663343455],
              [-81.004054641085673, 35.080726106442597],
              [-81.004382296094136, 35.080768236963323],
              [-81.004434431163759, 35.080503458336082],
              [-81.004762085496523, 35.080545588728718],
              [-81.00481421916956, 35.080280809029496],
              [-81.005469527851801, 35.080365065947284],
              [-81.005521659136136, 35.080100286838565],
              [-81.005849312411399, 35.080142414283159],
              [-81.005901442299162, 35.079877634102587],
              [-81.006556747769878, 35.079961885142517],
              [-81.006661001311372, 35.079432325048849],
              [-81.006333350490337, 35.079390200704502],
              [-81.006385478327061, 35.079125420292392],
              [-81.006057828880586, 35.079083295199013],
              [-81.006162084515097, 35.078553734582201],
              [-81.005834438575576, 35.078511608861724],
              [-81.005990823147187, 35.077717268119919],
              [-81.005663179558056, 35.077675141948987],
              [-81.005819564193629, 35.076880801354179],
              [-81.00587169207229, 35.076616020187814],
              [-81.005544052965988, 35.076573893688547],
              [-81.005596181542529, 35.076309112631947],
              [-81.005268544906983, 35.076266985365493],
              [-81.005320674181363, 35.076002204418721],
              [-81.004665402878899, 35.075917946615554],
              [-81.004717533909357, 35.075653166820096],
              [-81.004389900338793, 35.075611036265251],
              [-81.004442032044849, 35.075346255678461],
              [-81.00411439984866, 35.075304124374568],
              [-81.004166532274652, 35.075039344798952],
              [-81.003838901452824, 35.074997212746155],
              [-81.003891034554471, 35.074732432379037],
              [-81.003563405129142, 35.074690300478622],
              [-81.003615538928642, 35.074425520221389],
              [-81.003287910855448, 35.074383386670739],
              [-81.003392179511721, 35.073853826345655],
              [-81.003719805512716, 35.073895959615832],
              [-81.003876202851757, 35.073101618480301],
              [-81.00354857995876, 35.073059485630765],
              [-81.003809240583024, 35.07173558293205],
              [-81.003481624304669, 35.07169344987625],
              [-81.003533756433001, 35.071428668664836],
              [-81.002550911639403, 35.071302264601684],
              [-81.002603047655725, 35.071037484663762],
              [-81.001947822265407, 35.070953210209957],
              [-81.001999958919157, 35.070688430540422],
              [-81.00167234777841, 35.0706462925707],
              [-81.001620210088788, 35.070911072100017],
              [-81.001292598250387, 35.070868933100982],
              [-81.001344736953939, 35.070604152810681],
              [-81.000689516364062, 35.070519872425955],
              [-81.000741657897379, 35.070255092367688],
              [-81.000414049145903, 35.070212950981926],
              [-81.000466190280633, 35.069948171051827],
              [-81.0001385839996, 35.069906028899076],
              [-81.000190725831928, 35.069641249078984],
              [-80.999863120925113, 35.069599106177407],
              [-80.999810976938605, 35.06986388497409],
              [-80.999155764971988, 35.069779596241609],
              [-80.99905147186216, 35.070309154084669],
              [-80.998723864862725, 35.070267008095158],
              [-80.998671716775405, 35.070531787281659],
              [-80.998016499505056, 35.070447491490036],
              [-80.998120799484951, 35.06991793364773],
              [-80.997465588808154, 35.069833634842766],
              [-80.997517740362269, 35.06956885615682],
              [-80.996534929433409, 35.069442401260751],
              [-80.996587084875131, 35.069177623848468],
              [-80.994621479443836, 35.068924689974338],
              [-80.994673640740359, 35.068659912472377],
              [-80.993690846260236, 35.068533434395789],
              [-80.993743010303831, 35.068268656383168],
              [-80.990794657686109, 35.067889172650951],
              [-80.991264203931323, 35.065506184130854],
              [-80.991591786894404, 35.065548351301182],
              [-80.991748292736744, 35.064754020535865],
              [-80.992075872931068, 35.064796186396151],
              [-80.992284538454669, 35.063737075622576],
              [-80.991956962403137, 35.063694910323598],
              [-80.992009127860925, 35.063430132037276],
              [-80.991353979940897, 35.06334579903492],
              [-80.991406148227739, 35.063081020981123],
              [-80.99107857526306, 35.063038853295922],
              [-80.99118291291478, 35.062509298279892],
              [-80.990855342359922, 35.062467129986366],
              [-80.990959680707121, 35.061937574229304],
              [-80.990632112561954, 35.061895405327576],
              [-80.990788620651372, 35.061101071887279],
              [-80.990461057047753, 35.061058902499575],
              [-80.990513226769494, 35.060794124766311],
              [-80.99018566451808, 35.060751953728726],
              [-80.990655186038225, 35.058368951336512],
              [-80.990982737851212, 35.058411120227795],
              [-80.991034904188567, 35.058146342194121],
              [-80.991362456400637, 35.058188510038107],
              [-80.991466785944866, 35.057658951797492],
              [-80.991794335350122, 35.057701119391318],
              [-80.991846498579022, 35.057436340085616],
              [-80.992174047265138, 35.057478505748904],
              [-80.992226209120076, 35.057213726272884],
              [-80.992553758227174, 35.05725589179017],
              [-80.992658077754854, 35.056726332485674],
              [-80.992985624011041, 35.056768495950152],
              [-80.993142097087997, 35.055974155455409],
              [-80.992814553938302, 35.055931992411935],
              [-80.992971027054466, 35.0551376511672],
              [-80.992315947961259, 35.05505332235429],
              [-80.992368107806328, 35.054788542749023],
              [-80.992040569791726, 35.054746376699015],
              [-80.992092729237811, 35.054481597222164],
              [-80.991765192597072, 35.054439430423663],
              [-80.991817353814312, 35.054174650137846],
              [-80.99148981856942, 35.05413248349214],
              [-80.991594140963613, 35.053602923129581],
              [-80.991921674159499, 35.053645090395889],
              [-80.992078152632558, 35.052850748296407],
              [-80.992405681964541, 35.052892914271041],
              [-80.992457839020048, 35.052628133683513],
              [-80.99213031072351, 35.052585967849311],
              [-80.992443254289711, 35.050997281799063],
              [-80.992770777469744, 35.051039446773125],
              [-80.992875086483664, 35.050509884248953],
              [-80.993202606813227, 35.050552047170335],
              [-80.993359065775479, 35.049757702270441],
              [-80.993031548530013, 35.049715538868696],
              [-80.993083701503423, 35.049450757021738],
              [-80.992756185631649, 35.049408592871536],
              [-80.992808340398085, 35.04914381111697],
              [-80.992480825921973, 35.049101647119642],
              [-80.992585134296775, 35.048572082936673],
              [-80.992257623303558, 35.048529917411877],
              [-80.992309778029806, 35.0482651358667],
              [-80.991982268410226, 35.048222969593454],
              [-80.992086578896291, 35.047693405793034],
              [-80.991759071707733, 35.047651239813135],
              [-80.991811227467551, 35.047386457557991],
              [-80.991483721630615, 35.047344289928276],
              [-80.991535879205344, 35.047079508666975],
              [-80.991863383985091, 35.047121675254907],
              [-80.991967693835178, 35.04659211149707],
              [-80.991640191104011, 35.046549944288458],
              [-80.991953120872395, 35.044961250402864],
              [-80.99162562578708, 35.044919083129557],
              [-80.99167778023434, 35.044654300857644],
              [-80.991350285426506, 35.044612132854148],
              [-80.99145459645041, 35.044082567582429],
              [-80.991782088091867, 35.044124735323301],
              [-80.991990700843417, 35.043065603880194],
              [-80.992318189778217, 35.043107770152837],
              [-80.992370340800264, 35.042842986631378],
              [-80.992697827942322, 35.042885151893053],
              [-80.99295857280238, 35.041561233138921],
              [-80.993286055106864, 35.041603396810139],
              [-80.993338202028909, 35.041338612830017],
              [-80.993665683636479, 35.041380775472227],
              [-80.993717829163117, 35.04111599042082],
              [-80.994700271813755, 35.041242472612481],
              [-80.994752413918818, 35.040977688011729],
              [-80.995079894808896, 35.041019846818592],
              [-80.995132034422667, 35.04075506116461],
              [-80.995459514615874, 35.040797218942402],
              [-80.995511653974233, 35.040532434001733],
              [-80.995839132352472, 35.04057458986739],
              [-80.99615195181407, 35.038985875208631],
              [-80.996479424341842, 35.039028030244907],
              [-80.996531558144738, 35.038763243933204],
              [-80.996859031071409, 35.038805397922374],
              [-80.996911163501139, 35.038540611440794],
              [-80.997566107180674, 35.038624915589409],
              [-80.997618237202062, 35.038360128797549],
              [-80.997945707976967, 35.038402279858332],
              [-80.997893578990784, 35.038667066790424],
              [-80.998548524699061, 35.038751365607169],
              [-80.998496397444924, 35.039016152790325],
              [-80.999478822207521, 35.03914259521283],
              [-80.999530945260332, 35.038877807626925],
              [-80.999858420194926, 35.038919953477823],
              [-80.999910542970468, 35.038655165703886],
              [-81.00023801608998, 35.03869730964275],
              [-81.00034225746073, 35.038167733743727],
              [-81.000669729943894, 35.038209876495102],
              [-81.000721848539129, 35.037945088370115],
              [-81.001049319251578, 35.037987231012075],
              [-81.001101437547305, 35.037722441797712],
              [-81.001428907562669, 35.037764583410677],
              [-81.001481023389502, 35.037499794044791],
              [-81.001808493803537, 35.037541934610651],
              [-81.001860608279316, 35.03727714597612],
              [-81.002188076878426, 35.03731928462998],
              [-81.002240189958968, 35.037054494924426],
              [-81.002567657883006, 35.037096633450567],
              [-81.002671880981964, 35.036567054571663],
              [-81.002999348247513, 35.036609191009163],
              [-81.003103566828372, 35.036079611749848],
              [-81.003431031265976, 35.036121747036354],
              [-81.00348313955125, 35.035856956762458],
              [-81.003810603313767, 35.03589909192133],
              [-81.004071133399279, 35.034575139426281],
              [-81.003743674812, 35.034533004968679],
              [-81.004056305051563, 35.032944260056041],
              [-81.004383757406387, 35.032986392770816],
              [-81.004644267720749, 35.031662435069371],
              [-81.004971714164853, 35.031704567113657],
              [-81.005023813517113, 35.031439775175592],
              [-81.005351259242076, 35.031481905289816],
              [-81.005403357221454, 35.031217113182031],
              [-81.005730802249246, 35.031259242267438],
              [-81.005834996220031, 35.030729657665091],
              [-81.006489883171668, 35.030813913510308],
              [-81.00654197703237, 35.030549120893724],
              [-81.00686941996895, 35.030591246892534],
              [-81.006921512434744, 35.030326453205014],
              [-81.007576396177825, 35.03041070317601],
              [-81.007628486258, 35.030145910079987],
              [-81.007955928138074, 35.030188033132575],
              [-81.008008016823268, 35.02992323896563],
              [-81.0083354580063, 35.029965360989422],
              [-81.008387544245053, 35.029700567572441],
              [-81.008714984730915, 35.029742688567431],
              [-81.008819155198694, 35.02921310044546],
              [-81.009146593952465, 35.02925522027148],
              [-81.009198678181519, 35.028990425566406],
              [-81.00887124046271, 35.02894830588064],
              [-81.00892332538892, 35.028683511286552],
              [-81.008595889042851, 35.028641390852513],
              [-81.009012561283981, 35.026523034221228],
              [-81.008685134628664, 35.026480913100897],
              [-81.008789301084704, 35.025951323034022],
              [-81.008461876859002, 35.025909202206869],
              [-81.008513961174188, 35.025644407711226],
              [-81.008566045129342, 35.025379612285157],
              [-81.008238623311229, 35.025337490849935],
              [-81.008342791182315, 35.0248079002086],
              [-81.008015372867348, 35.024765778147156],
              [-81.008171624697326, 35.02397139239573],
              [-81.00784420982454, 35.02392926986645],
              [-81.008156712720435, 35.0223404965772],
              [-81.007829304394264, 35.022298374000947],
              [-81.008141800177114, 35.020709597816939],
              [-81.007814399492759, 35.020667475175379],
              [-81.007866481424358, 35.020402679041815],
              [-81.007539082090332, 35.020360554750809],
              [-81.00764324810531, 35.019830962658446],
              [-81.006988453515945, 35.01974671289156],
              [-81.007040538634001, 35.019481917073051],
              [-81.006713142321871, 35.019439790555673],
              [-81.006765228136871, 35.019174994848349],
              [-81.006437834292711, 35.019132867564529],
              [-81.006489920804611, 35.018868071968356],
              [-81.006162527237308, 35.018825943954553],
              [-81.006422958741425, 35.017501963552654],
              [-81.006095572898602, 35.017459836216709],
              [-81.006355999991968, 35.01613585400456],
              [-81.006683381779482, 35.016177981522333],
              [-81.006735464492991, 35.0159131848634],
              [-81.007062845561407, 35.01595531045114],
              [-81.007114927998117, 35.015690513604689],
              [-81.007442307296259, 35.015732639083289],
              [-81.007494387265098, 35.015467842085769],
              [-81.007821766939827, 35.015509965616239],
              [-81.007873845536366, 35.015245168449418],
              [-81.008201224536364, 35.015287291852481],
              [-81.008305378624854, 35.014757696249873],
              [-81.008960132381787, 35.014841938946908],
              [-81.009116354785476, 35.014047544934478],
              [-81.009443728507904, 35.014089664088175],
              [-81.009599945842254, 35.013295268474522],
              [-81.009927316798553, 35.013337386319087],
              [-81.009979387160897, 35.013072587288583],
              [-81.010634126966238, 35.013156821852995],
              [-81.010686194921817, 35.01289202251305],
              [-81.011340935081904, 35.012976252323881],
              [-81.011393000630747, 35.012711452674559],
              [-81.012047740072234, 35.012795678651464],
              [-81.012099802118755, 35.012530878711011],
              [-81.012427171300232, 35.012572989775983],
              [-81.012583353384073, 35.011778588440649],
              [-81.01291071982169, 35.01182069909791],
              [-81.012962778426655, 35.011555897859907],
              [-81.013290144167215, 35.011598007488672],
              [-81.013342201400064, 35.011333206081467],
              [-81.013669566443653, 35.011375314681658],
              [-81.013721622304402, 35.011110513105287],
              [-81.014376351313118, 35.011194726458868],
              [-81.014428404767159, 35.010929924573176],
              [-81.014755767658897, 35.010972030246194],
              [-81.01480781974098, 35.010707228191372],
              [-81.015135183008866, 35.010749331916223],
              [-81.015187233741145, 35.010484530593672],
              [-81.015514595216615, 35.010526633308181],
              [-81.015618693554984, 35.009997028493402],
              [-81.015946053321187, 35.01003913094047],
              [-81.016050148240524, 35.009509525729726],
              [-81.016377506253008, 35.009551626106784],
              [-81.01648159665794, 35.009022020518408],
              [-81.016808952938703, 35.00906411972678],
              [-81.016860997148243, 35.008799316739953],
              [-81.017188352732077, 35.008841414919836],
              [-81.017396519844411, 35.007782201258443],
              [-81.017723871627737, 35.007824297989231],
              [-81.017827951079113, 35.007294690254504],
              [-81.018155300035488, 35.007336785834909],
              [-81.018482650424616, 35.007378880508774],
              [-81.018534688111956, 35.007114075857992],
              [-81.019516737127319, 35.00724035504863],
              [-81.019464703616649, 35.007505159200271],
              [-81.020119407454018, 35.007589341454278],
              [-81.020067375674927, 35.007854145857415],
              [-81.02072208290852, 35.007938323937104],
              [-81.020670052883332, 35.008203129493026],
              [-81.023288905734091, 35.008539805560112],
              [-81.023340927505416, 35.008274999784327],
              [-81.023668284326988, 35.008317080384955],
              [-81.023616264685657, 35.00858188628245],
              [-81.024598341350853, 35.008708122254781],
              [-81.024546324498274, 35.008972929445136],
              [-81.025855769627611, 35.009141231585254],
              [-81.025803756553572, 35.009406038405835],
              [-81.026458483210988, 35.009490184425907],
              [-81.026406471868839, 35.009754991497807],
              [-81.026733837296973, 35.009797063756871],
              [-81.026681826652151, 35.010061870940021],
              [-81.027663926923594, 35.010188081022939],
              [-81.027611920140899, 35.010452888579081],
              [-81.028594027646818, 35.010579091068237],
              [-81.028542022535177, 35.010843899034171],
              [-81.029196764612877, 35.010928029264306],
              [-81.029144762328883, 35.011192837463128],
              [-81.031763753500314, 35.011529323949588],
              [-81.031711759156309, 35.011794133239938],
              [-81.035640303650425, 35.012298755252999],
              [-81.035692284504947, 35.012033945202113],
              [-81.036347044559392, 35.012118035702265],
              [-81.036399022985037, 35.01185322444119],
              [-81.037053782318381, 35.011937311107609],
              [-81.037105758337447, 35.011672499537603],
              [-81.038087896224539, 35.011798622913915],
              [-81.038139868802503, 35.011533810895116],
              [-81.038794627367324, 35.011617888117243],
              [-81.038742656858801, 35.011882700416002],
              [-81.039070038217943, 35.011924737374514],
              [-81.039018068407131, 35.012189549784367],
              [-81.039345451138018, 35.012231585994357],
              [-81.039293482024959, 35.012496398515395],
              [-81.039620866127592, 35.012538433976914],
              [-81.039568898807772, 35.012803246590451],
              [-81.040223668997953, 35.012887315146649],
              [-81.040119737463897, 35.013416939945728],
              [-81.040447125681808, 35.013458973161761],
              [-81.04034319377385, 35.013988598143705],
              [-81.040670584398342, 35.014030630751293],
              [-81.040618619510155, 35.014295442878826],
              [-81.040946011506435, 35.014337474737893],
              [-81.040894047316073, 35.014602286976569],
              [-81.041221440684154, 35.014644318087093],
              [-81.04116947719163, 35.014909130436813],
              [-81.042151663540125, 35.015035219740057],
              [-81.042099702792584, 35.015300031579379],
              [-81.042754497796196, 35.015384086345236],
              [-81.042702538803923, 35.015648899336846],
              [-81.043357337225146, 35.015732950828578],
              [-81.043305379965645, 35.01599776407118],
              [-81.044614986063706, 35.016165856032515],
              [-81.04456303260676, 35.016430669805807],
              [-81.044890436265462, 35.016472690034213],
              [-81.045217840283541, 35.016514710275302],
              [-81.045269790575162, 35.016249896240758],
              [-81.046252000428211, 35.016375950330648],
              [-81.046303948396385, 35.016111136730061],
              [-81.046631351669788, 35.016153152235987],
              [-81.046943020581807, 35.014564263871044],
              [-81.047270416909868, 35.014606278568941],
              [-81.047426243143505, 35.013811832684588],
              [-81.04775363777668, 35.013853845154358],
              [-81.047805578153572, 35.01358902996234],
              [-81.048132971015932, 35.013631042323716],
              [-81.048184909998554, 35.013366226061535],
              [-81.04883969464133, 35.013450246937737],
              [-81.048891631240082, 35.013185431268226],
              [-81.049546415160393, 35.013269448310716],
              [-81.049598349330068, 35.013004631431244],
              [-81.049925740749302, 35.013046638029216],
              [-81.04997767356997, 35.01278182188242],
              [-81.050305064291038, 35.012823827451939],
              [-81.050253132527914, 35.013088644640028],
              [-81.050580525716114, 35.013130649442331],
              [-81.050632455348975, 35.01286583213308],
              [-81.051614631661337, 35.012991841728095],
              [-81.051666557875549, 35.012727024871673],
              [-81.052321340952346, 35.012811025957774],
              [-81.052373264737497, 35.012546207891461],
              [-81.052700655768817, 35.012588207863246],
              [-81.052752578182407, 35.0123233896282],
              [-81.053079969588367, 35.012365387651705],
              [-81.053131890630411, 35.012100569247956],
              [-81.053459280242805, 35.012142566261772],
              [-81.053563118151558, 35.011612929106853],
              [-81.053890506030982, 35.011654924952495],
              [-81.053942423993277, 35.011390106182645],
              [-81.054269811174549, 35.011432100999947],
              [-81.05437364292392, 35.01090246311275],
              [-81.054701028372207, 35.01094445676182],
              [-81.054752943254869, 35.010679637625913],
              [-81.055080328004905, 35.010721630246735],
              [-81.055132240397953, 35.010456810059551],
              [-81.055787008861174, 35.010540793257377],
              [-81.055838918847954, 35.01027597276164],
              [-81.057148454533589, 35.01044392615244],
              [-81.05709654868609, 35.010708747207381],
              [-81.0577513211433, 35.01079271794088],
              [-81.057699417028886, 35.011057539246664],
              [-81.058354192902044, 35.011141506705862],
              [-81.058302290520743, 35.01140632826246],
              [-81.059284459970769, 35.011532273657664],
              [-81.059336359270205, 35.011267452582963],
              [-81.061955485420668, 35.011603266438833],
              [-81.062007374986365, 35.011338443334481],
              [-81.062334766106147, 35.011380416268736],
              [-81.06238665432322, 35.011115593897131],
              [-81.062714044721929, 35.011157564901907],
              [-81.062765932663112, 35.010892742342939],
              [-81.063093322363358, 35.010934712319433],
              [-81.063145207837692, 35.010669889610746],
              [-81.063799986177898, 35.010753826618661],
              [-81.063851869246051, 35.010489003601698],
              [-81.064834036197936, 35.010614902931863],
              [-81.064885915802208, 35.010350078565509],
              [-81.065540693390105, 35.010434007030725],
              [-81.065592569492821, 35.010169182374945],
              [-81.065919957767861, 35.010211145585693],
              [-81.065971833594674, 35.009946320742657],
              [-81.066953997265344, 35.01007220192092],
              [-81.067005868578462, 35.009807377550018],
              [-81.068642809582627, 35.010017161045148],
              [-81.068590943466873, 35.010281987015965],
              [-81.068918333711594, 35.010323941189149],
              [-81.068866468294317, 35.01058876727101],
              [-81.070176037849151, 35.010756574717547],
              [-81.070227899149629, 35.010491748978247],
              [-81.07055529107609, 35.010533698708876],
              [-81.070607150982411, 35.010268871899868],
              [-81.070934542210253, 35.010310820602271],
              [-81.07098640074544, 35.010045993624821],
              [-81.071313791274576, 35.010087941299119],
              [-81.071365647343086, 35.009823114172022],
              [-81.071693037173702, 35.009865060818065],
              [-81.071796747327525, 35.00933540617946],
              [-81.072778912604548, 35.009461239047234],
              [-81.072727061348218, 35.009726066790577],
              [-81.074364016463875, 35.009935770845665],
              [-81.074312170045943, 35.010200599258539],
              [-81.076276534352317, 35.010452215085664],
              [-81.076224693807887, 35.010717044307725],
              [-81.076879487011638, 35.010800908513545],
              [-81.077534281583013, 35.010884770066504],
              [-81.077482445937861, 35.011149599799602],
              [-81.077809844203514, 35.011191528941659],
              [-81.07775800925711, 35.011456358785644],
              [-81.078740211367105, 35.011582141261741],
              [-81.078792043231445, 35.011317311900228],
              [-81.079446844979088, 35.011401162149731],
              [-81.079498674414353, 35.011136331578982],
              [-81.07982607366192, 35.011178255250194],
              [-81.079877901749029, 35.010913425412319],
              [-81.080205301393164, 35.010955348036489],
              [-81.080257128086217, 35.010690517128999],
              [-81.080584527031377, 35.010732438725015],
              [-81.08068817624374, 35.010202776563602],
              [-81.081015573455076, 35.010244696991883],
              [-81.081067395974415, 35.009979865737982],
              [-81.081394792486762, 35.010021785138029],
              [-81.081446613635052, 35.009756953715872],
              [-81.083738388100031, 35.010050363716864],
              [-81.083790201667355, 35.009785531288905],
              [-81.085099789992412, 35.009953173144389],
              [-81.08515159908363, 35.009688340129458],
              [-81.085478995404898, 35.009730247565912],
              [-81.085427188444058, 35.009995080701472],
              [-81.087064183620285, 35.010204607900249],
              [-81.087012380403038, 35.010469441723856],
              [-81.087339781928605, 35.010511344990448],
              [-81.087287979410533, 35.0107761789249],
              [-81.088597592966153, 35.010943781861002],
              [-81.088649390248563, 35.010678947387376],
              [-81.088976793430717, 35.010720845309756],
              [-81.089028589342149, 35.010456010667887],
              [-81.089355991825045, 35.010497907562176],
              [-81.089304196948675, 35.010762742343651],
              [-81.089959003920086, 35.01084653466588],
              [-81.089907210777938, 35.01111136969768],
              [-81.090234616338648, 35.011153264205284],
              [-81.090182823895702, 35.011418099347829],
              [-81.090510230827476, 35.011459993106435],
              [-81.090458439083733, 35.011724828359796],
              [-81.090785847386456, 35.011766721369256],
              [-81.09073405634193, 35.012031556733476],
              [-81.091061466015645, 35.012073448993853],
              [-81.091009676765722, 35.012338284449811],
              [-81.09133708781043, 35.012380175961177],
              [-81.091388877120622, 35.012115340346732],
              [-81.092043698147378, 35.012199120424583],
              [-81.092095485074495, 35.011934285403711],
              [-81.093405128113105, 35.012101834319225],
              [-81.093353346421864, 35.012366669879064],
              [-81.093680758782369, 35.012408555030724],
              [-81.093628976718051, 35.012673391621632],
              [-81.094938635826423, 35.012840922960606],
              [-81.094886858662477, 35.013105760061698],
              [-81.096196528379693, 35.013273277721694],
              [-81.09614475390191, 35.013538114469654],
              [-81.096799593381718, 35.013621867786618],
              [-81.096747821734112, 35.013886704765788],
              [-81.097730086589095, 35.014012328945441],
              [-81.097885390089587, 35.01321781578465],
              [-81.098212808186105, 35.013259688635053],
              [-81.098316340826685, 35.012730012458888],
              [-81.098643757165334, 35.012771883240497],
              [-81.098695521958447, 35.012507045420534],
              [-81.099022937597724, 35.01254891517415],
              [-81.099074701019958, 35.012284077185988],
              [-81.099402115959677, 35.012325945911563],
              [-81.099453877987969, 35.012061106854048],
              [-81.099781292251379, 35.012102975452784],
              [-81.099833052908721, 35.011838136227126],
              [-81.100160466449566, 35.011880002896582],
              [-81.100212224663778, 35.011615164423198],
              [-81.100539638600594, 35.011657030045491],
              [-81.10064315190526, 35.011127350931254],
              [-81.100970563035133, 35.011169216306428],
              [-81.101074073999243, 35.010639535878283],
              [-81.101401483394497, 35.010681400086106],
              [-81.101504989850298, 35.010151719283535],
              [-81.101832397510805, 35.010193582323872],
              [-81.101884149188535, 35.009928741289606],
              [-81.102538963423058, 35.010012463524355],
              [-81.102590712718197, 35.009747623083882],
              [-81.103572933446827, 35.009873198001294],
              [-81.103624678205449, 35.009608357133096],
              [-81.104279492727585, 35.009692069003826],
              [-81.104331235080366, 35.00942722782824],
              [-81.104658641261878, 35.009469082300882],
              [-81.1047621239628, 35.008939398664566],
              [-81.105089528409607, 35.00898125196985],
              [-81.105244745184137, 35.00818672546071],
              [-81.105572146861221, 35.008228577459256],
              [-81.105727357487439, 35.00743404937338],
              [-81.106054756394641, 35.007475900065188],
              [-81.10615822639771, 35.006946214252913],
              [-81.106485623570322, 35.006988063777378],
              [-81.106589089042572, 35.006458376690027],
              [-81.106916484480422, 35.006500225047084],
              [-81.107019947636061, 35.005970537547675],
              [-81.107347340243862, 35.006012384756552],
              [-81.107399070294974, 35.005747541275326],
              [-81.10772646220326, 35.005789387456353],
              [-81.1077781908606, 35.005524542905896],
              [-81.108105582069271, 35.005566388058938],
              [-81.108209035235276, 35.005036699514193],
              [-81.1085364247093, 35.005078543500005],
              [-81.108588150290174, 35.00481369858521],
              [-81.108915539064569, 35.004855541543122],
              [-81.108967262179604, 35.004590696479653],
              [-81.108639874440257, 35.004548853661113],
              [-81.108743322829511, 35.004019163708278],
              [-81.108415937495664, 35.003977320279915],
              [-81.108519386613267, 35.00344763049182],
              [-81.10884676987709, 35.003489473641423],
              [-81.10895021555973, 35.002959782559408],
              [-81.109277595993575, 35.003001624560895],
              [-81.10932931785608, 35.002736779278891],
              [-81.109656697590253, 35.002778620252563],
              [-81.109708416987019, 35.002513774821836],
              [-81.110035797117021, 35.002555614748474],
              [-81.110087515120128, 35.002290768248706],
              [-81.110414894550502, 35.002332607147473],
              [-81.110466611183227, 35.00206776047991],
              [-81.11112136788465, 35.002151435352232],
              [-81.111173082135338, 35.001886589278833],
              [-81.111500459383151, 35.001928424351142],
              [-81.111552172263501, 35.001663578109955],
              [-81.112206926813741, 35.00174724619248],
              [-81.112258637288761, 35.001482399644217],
              [-81.112586013495928, 35.001524232673631],
              [-81.112637722577333, 35.001259385056315],
              [-81.113619851177617, 35.00138487747482],
              [-81.113671556818744, 35.001120029411098],
              [-81.114653685331675, 35.001245513415],
              [-81.114705386437208, 35.00098066492415],
              [-81.11503276298177, 35.001022491296645],
              [-81.115187861181823, 35.000227945235714],
              [-81.115515234933241, 35.000269769400575],
              [-81.115670326987797, 34.999475221764826],
              [-81.11534295743661, 34.999433397998665],
              [-81.11549804955547, 34.998638849624292],
              [-81.115170683421027, 34.998597024486259],
              [-81.11522238117405, 34.998332175711568],
              [-81.115549746250309, 34.998373999809012],
              [-81.115601442633078, 34.998109150866611],
              [-81.117238266842591, 34.998318260052756],
              [-81.117289957692222, 34.998053409484285],
              [-81.117617321637908, 34.99809522889268],
              [-81.117669011117428, 34.997830378156607],
              [-81.119633201003225, 34.998081271411351],
              [-81.119684882865997, 34.997816420731709],
              [-81.12197644698324, 34.998109086903],
              [-81.12192477238861, 34.998373939458666],
              [-81.122906879440734, 34.99849935542295],
              [-81.12295855093042, 34.998234502449549],
              [-81.123285919575082, 34.998276305554327],
              [-81.12333758859954, 34.998011452432564],
              [-81.12366495654426, 34.998053254509593],
              [-81.123716625293881, 34.99778840120107],
              [-81.124043992538773, 34.997830202250341],
              [-81.124095658823094, 34.997565348793593],
              [-81.124750392247932, 34.997648947948036],
              [-81.124802056127265, 34.997384094184525],
              [-81.130039956206147, 34.998052753622041],
              [-81.130091602095135, 34.997787897622395],
              [-81.130418973671283, 34.997829680451524],
              [-81.130470618190444, 34.997564824284396],
              [-81.13145272858425, 34.997690168883601],
              [-81.131504369663546, 34.997425312270664],
              [-81.132159110594287, 34.997508869394878],
              [-81.132262386512735, 34.996979155546846],
              [-81.132589754862778, 34.997020932507496],
              [-81.132693028443441, 34.996491217347504],
              [-81.133020395081815, 34.996532994042568],
              [-81.133175298729739, 34.995738420241899],
              [-81.133502662574301, 34.995780194729704],
              [-81.133657560103785, 34.994985620257445],
              [-81.133984920083051, 34.995027393458749],
              [-81.134036551245529, 34.99476253479876],
              [-81.135018632301069, 34.994887849516687],
              [-81.134967004243549, 34.995152708593999],
              [-81.135621729405543, 34.995236246961291],
              [-81.13557010308331, 34.995501106288671],
              [-81.135897467201417, 34.995542874278641],
              [-81.135845842674584, 34.995807733697461],
              [-81.136500573985174, 34.995891267290133],
              [-81.136448951193614, 34.996156126959043],
              [-81.137431053775344, 34.996281421101386],
              [-81.137482673485295, 34.996016561916434],
              [-81.139774250622267, 34.996308881941587],
              [-81.139825861633568, 34.996044020872837],
              [-81.140480600002519, 34.996127532843097],
              [-81.14053220860896, 34.995862671467961],
              [-81.142169052427249, 34.996071433394839],
              [-81.142117448996089, 34.996336295465362],
              [-81.142772192026513, 34.99641979409472],
              [-81.142720590330896, 34.996684656415212],
              [-81.143047962847334, 34.996726404996465],
              [-81.142996361828537, 34.996991266526514],
              [-81.143651111006989, 34.997074760380826],
              [-81.143599511747411, 34.997339623061912],
              [-81.14392688840951, 34.997381368334963],
              [-81.143875289826781, 34.997646230225605],
              [-81.144202666786981, 34.997687975669784],
              [-81.144254264310945, 34.997423112738808],
              [-81.146545909441073, 34.997715301312205],
              [-81.146494320234595, 34.997980164295676],
              [-81.147149080890557, 34.998063638256326],
              [-81.147097493443525, 34.998328502390883],
              [-81.148734407756464, 34.998537172408142],
              [-81.148785990051394, 34.998272308479827],
              [-81.149113373101443, 34.998314039674618],
              [-81.149164954002998, 34.998049174677753],
              [-81.151784021043952, 34.998382990252829],
              [-81.151732449519244, 34.998647856342139],
              [-81.15205983555569, 34.998689578638306],
              [-81.152008263636347, 34.998954444857809],
              [-81.15397259425599, 34.999204763491896],
              [-81.154024158892526, 34.998939897359342],
              [-81.154351547293714, 34.998981614336515],
              [-81.154403110536848, 34.99871674713556],
              [-81.155057886271834, 34.998800178146041],
              [-81.155109447110164, 34.998535310638928],
              [-81.155436834444032, 34.998577024672379],
              [-81.155488393912776, 34.998312156998011],
              [-81.15614316748929, 34.998395581219881],
              [-81.156797942403273, 34.998479001887212],
              [-81.156746388146928, 34.998743869196375],
              [-81.157728557214355, 34.998868993479356],
              [-81.157677004634095, 34.999133861196668],
              [-81.160623540277356, 34.999509186389055],
              [-81.160520454939274, 35.00003892420024],
              [-81.160847852133699, 35.000080622622761],
              [-81.160744768624554, 35.000610360579032],
              [-81.161399567038146, 35.000693754432184],
              [-81.161348027400464, 35.000958623635931],
              [-81.161675427608273, 35.001000319828762],
              [-81.161623888671684, 35.001265189143147],
              [-81.162278694351201, 35.001348579101482],
              [-81.162227156031861, 35.001613447783633],
              [-81.162554560408282, 35.001655141568691],
              [-81.162503023909309, 35.001920011243094],
              [-81.162830429631441, 35.001961703377098],
              [-81.162778893833533, 35.002226573162062],
              [-81.16310630094911, 35.002268265447526],
              [-81.163003229278274, 35.002798003427401],
              [-81.163330638798911, 35.002839695101926],
              [-81.163227568957268, 35.003369433226688],
              [-81.163554980882822, 35.003411124290182],
              [-81.163451910679711, 35.003940862599109],
              [-81.163779326105654, 35.003982553031982],
              [-81.163727792085936, 35.004247422272954],
              [-81.164055207762289, 35.004289111074414],
              [-81.164003674443691, 35.004553980425918],
              [-81.164331092609018, 35.004595669359169],
              [-81.164279559991641, 35.004860538821184],
              [-81.164606979502636, 35.004902226103276],
              [-81.164555446491107, 35.005167095695462],
              [-81.164882867371873, 35.005208782227676],
              [-81.164831336156951, 35.005473651910691],
              [-81.165486181015808, 35.005557023487881],
              [-81.165434651513721, 35.005821892519037],
              [-81.166416925498368, 35.005946943165476],
              [-81.166365398792422, 35.006211813485983],
              [-81.167347677795775, 35.006336856570094],
              [-81.167296154933524, 35.006601726357687],
              [-81.16795101132837, 35.006685083646815],
              [-81.167899490227001, 35.006949954585046],
              [-81.168554351124811, 35.007033308577064],
              [-81.168502830641003, 35.007298178882991],
              [-81.169157694946435, 35.007381529597595],
              [-81.169106177294964, 35.007646400132977],
              [-81.169761045008016, 35.007729747570131],
              [-81.16970952909351, 35.007994618354836],
              [-81.170364400214154, 35.008077962514491],
              [-81.170312886036641, 35.008342833548305],
              [-81.170640323681482, 35.008384504423951],
              [-81.17058881020543, 35.008649375568169],
              [-81.170916249219857, 35.008691045693823],
              [-81.170864736445267, 35.008955916948416],
              [-81.171519617523728, 35.009039253909563],
              [-81.171365083385012, 35.009833867434331],
              [-81.171692527544224, 35.009875535448671],
              [-81.171486481450941, 35.010935018803245],
              [-81.171813930086785, 35.010976686483957],
              [-81.171710907118836, 35.011506428718697],
              [-81.172038359231479, 35.011548094867159],
              [-81.171986848270024, 35.01181296562995],
              [-81.172314300681052, 35.011854631949362],
              [-81.172211279827067, 35.012384373667082],
              [-81.172538735715037, 35.012426038454215],
              [-81.172435715596094, 35.012955780335517],
              [-81.172763173913452, 35.012997445412623],
              [-81.172711664364556, 35.01326231554809],
              [-81.173039124051812, 35.013303979875026],
              [-81.17298761629992, 35.013568850100953],
              [-81.173315077356989, 35.013610513677882],
              [-81.173263569211315, 35.01387538403381],
              [-81.173591031638225, 35.01391704686062],
              [-81.173539524194098, 35.014181917326788],
              [-81.173866989086321, 35.01422357938376],
              [-81.173815482343954, 35.014488449960169],
              [-81.174470412987063, 35.014571770823167],
              [-81.174418909077616, 35.014836641628882],
              [-81.174746375924101, 35.014878300415155],
              [-81.174694872740233, 35.01514317223225],
              [-81.175022340956559, 35.015184830268474],
              [-81.174970838450491, 35.015449701294507],
              [-81.175625779052325, 35.015533014958024],
              [-81.175574277188247, 35.015797886252763],
              [-81.175901749597855, 35.015839542781244],
              [-81.175850249530939, 35.016104414166392],
              [-81.176177723286401, 35.016146069043529],
              [-81.176126222825815, 35.0164109405587],
              [-81.176781173410419, 35.016494247923632],
              [-81.176729675782937, 35.01675911966786],
              [-81.177057152612079, 35.01680077215574],
              [-81.177005654590971, 35.017065644029891],
              [-81.177333132789983, 35.017107295767595],
              [-81.177281636542062, 35.017372166830839],
              [-81.177609116110958, 35.01741381781833],
              [-81.177403131970436, 35.018473304143001],
              [-81.177730616016902, 35.018514954796359],
              [-81.177627624014079, 35.019044698064576],
              [-81.177955110442312, 35.019086347205146],
              [-81.177903614987713, 35.019351219385626],
              [-81.178231103905475, 35.019392868657562],
              [-81.17817960912879, 35.019657740046867],
              [-81.178507098296805, 35.019699387687062],
              [-81.178455604222009, 35.019964259186459],
              [-81.178783095879666, 35.020005906957884],
              [-81.17868010879981, 35.020535650148183],
              [-81.17900760286328, 35.020577297308066],
              [-81.178904616495146, 35.021107039759968],
              [-81.179232112964613, 35.021148686308202],
              [-81.179129127332274, 35.02167842892279],
              [-81.179456626207624, 35.021720074859623],
              [-81.179302148349763, 35.022514688531956],
              [-81.179629651762667, 35.022556333975999],
              [-81.179578159503308, 35.022821204981014],
              [-81.179905663166579, 35.022862848793309],
              [-81.179699696052481, 35.023922333962688],
              [-81.180027204193848, 35.023963977440637],
              [-81.179975712336343, 35.024228848579504],
              [-81.180303222967552, 35.024270492188684],
              [-81.180251731788005, 35.024535362536255],
              [-81.180906756100427, 35.024618646463658],
              [-81.180855266682926, 35.024883517961122],
              [-81.18183780875296, 35.025008437149879],
              [-81.181786323205301, 35.025273309014828],
              [-81.182113838924693, 35.025314946804635],
              [-81.182062352983465, 35.025579818799308],
              [-81.182389870072953, 35.025621455838746],
              [-81.182235415496962, 35.026416070224215],
              [-81.181907894203533, 35.026374432788629],
              [-81.181753432417409, 35.02716904651907],
              [-81.181425909421321, 35.027127406857339],
              [-81.1813229315282, 35.027657149216921],
              [-81.180995405698113, 35.027615508408616],
              [-81.180840931565115, 35.028410119993744],
              [-81.181168460479455, 35.028451760316791],
              [-81.180859510434473, 35.030040980817617],
              [-81.180808017420532, 35.030305851254091],
              [-81.18113555392226, 35.030347491658738],
              [-81.180929583670036, 35.031406970045907],
              [-81.180602041927841, 35.031365329106372],
              [-81.180550547219397, 35.031630198497332],
              [-81.180223005870772, 35.031588556510243],
              [-81.180120013355165, 35.032118294026773],
              [-81.180068516608316, 35.032383163192392],
              [-81.180396061065736, 35.032424805595497],
              [-81.180344565021016, 35.032689674870838],
              [-81.180672111944432, 35.032731316503707],
              [-81.1806206166019, 35.032996185888784],
              [-81.180948164895739, 35.033037826771128],
              [-81.180896670231306, 35.033302695364782],
              [-81.181551768822047, 35.033385975660522],
              [-81.181500276991841, 35.033650844482707],
              [-81.184120697294247, 35.03398392752532],
              [-81.184172180809981, 35.033719056692618],
              [-81.185809950245044, 35.033927203082868],
              [-81.185861428269305, 35.0336623324295],
              [-81.18651653585583, 35.03374558395145],
              [-81.186568011473483, 35.033480712991945],
              [-81.18689556471935, 35.03352233682994],
              [-81.186947038966736, 35.03325746570291],
              [-81.187602144411741, 35.033340711335612],
              [-81.187653616228374, 35.033075839001135],
              [-81.188636273803596, 35.033200700816344],
              [-81.188687742177336, 35.032935828037274],
              [-81.189015294341928, 35.032977447025914],
              [-81.189066760249787, 35.032712574099214],
              [-81.190049415589755, 35.03283742351185],
              [-81.190100879174665, 35.032572551021843],
              [-81.192393746057945, 35.032863833927323],
              [-81.192342290823106, 35.033128707367432],
              [-81.192669845624067, 35.033170316436724],
              [-81.192618392187569, 35.033435189966532],
              [-81.192945948334412, 35.03347679738404],
              [-81.192894494528915, 35.033741671944767],
              [-81.193222052046053, 35.033783278611587],
              [-81.193119146577757, 35.034313027002234],
              [-81.193446706501391, 35.034354633057049],
              [-81.193395254862935, 35.034619507788214],
              [-81.194050378880377, 35.034702717487399],
              [-81.193998927860818, 35.034967591585406],
              [-81.194654056382106, 35.0350507979851],
              [-81.194705504232985, 35.034785923629961],
              [-81.196343323979434, 35.034993922514126],
              [-81.196394767410311, 35.034729047417493],
              [-81.197377460363555, 35.034853835297625],
              [-81.197428899255542, 35.034588959776499],
              [-81.199066721963618, 35.034796920806507],
              [-81.199118155339846, 35.034532044563946],
              [-81.201083548902432, 35.034781567056719],
              [-81.201032121744973, 35.03504644413028],
              [-81.202342395669575, 35.035212775215243],
              [-81.202290973420105, 35.035477652793823],
              [-81.203273685464154, 35.03560239173558],
              [-81.20322226599049, 35.035867269700567],
              [-81.204204985242285, 35.035992001034664],
              [-81.204256401582128, 35.035727121753055],
              [-81.206221841753759, 35.035976558700092],
              [-81.206170431608584, 35.03624143791118],
              [-81.208791048340544, 35.036573969660914],
              [-81.20884244909756, 35.036309089362618],
              [-81.209170026108566, 35.036350651814026],
              [-81.209118626387991, 35.036615532250742],
              [-81.209773784554855, 35.036698653841299],
              [-81.209722386574001, 35.03696353452591],
              [-81.210049967754372, 35.037005094566226],
              [-81.209947172865256, 35.037534856125596],
              [-81.210274756427765, 35.03757641465225],
              [-81.210120565774261, 35.038371056268076],
              [-81.210448152804545, 35.038412615221986],
              [-81.210345359798367, 35.038942376124204],
              [-81.210672949235388, 35.038983934465499],
              [-81.210621552708531, 35.039248814570989],
              [-81.210949143491263, 35.039290371260115],
              [-81.210897748787872, 35.039555252356209],
              [-81.211225340940629, 35.039596808294462],
              [-81.211173945844592, 35.039861689520052],
              [-81.211829134319757, 35.039944798985239],
              [-81.211777740963555, 35.040209680458673],
              [-81.212432932845914, 35.040292786643406],
              [-81.212381542301003, 35.040557667443203],
              [-81.212709139255495, 35.040599220251309],
              [-81.212760529859651, 35.040334339293032],
              [-81.214398516544563, 35.040542085656767],
              [-81.214347132219757, 35.040806967286713],
              [-81.21467473224628, 35.040848513837553],
              [-81.214726115534376, 35.040583632069172],
              [-81.215053713761861, 35.040625177612483],
              [-81.215105095679874, 35.040360295676869],
              [-81.216087891444204, 35.040484926515482],
              [-81.216139269918727, 35.040220044135893],
              [-81.216794466323137, 35.040303126645043],
              [-81.216845841295154, 35.04003824397995],
              [-81.217173439508571, 35.040079783752198],
              [-81.217224813085963, 35.03981490001847],
              [-81.217880007343709, 35.039897976638507],
              [-81.217828635839709, 35.040162860648799],
              [-81.218811433359832, 35.040287468412458],
              [-81.218760064608162, 35.040552351907522],
              [-81.219742868262799, 35.040676952983404],
              [-81.219691502288001, 35.040941836864363],
              [-81.221001916290319, 35.04110795792549],
              [-81.220950554153745, 35.041372843231933],
              [-81.222916194115584, 35.041621998922558],
              [-81.222864837841826, 35.041886884128452],
              [-81.223520055512168, 35.041969928292524],
              [-81.22384766596754, 35.042011449921581],
              [-81.223796312470938, 35.042276335513307],
              [-81.224123924271851, 35.042317855489998],
              [-81.224072571478843, 35.042582741190991],
              [-81.224727797057938, 35.0426657787729],
              [-81.224676447101189, 35.042930664701316],
              [-81.225986906429853, 35.04309673064688],
              [-81.225935560262684, 35.043361616197998],
              [-81.226263176410541, 35.043403130383929],
              [-81.226314521540985, 35.04313824469466],
              [-81.22762498750869, 35.043304291951372],
              [-81.227676328133896, 35.043039404778888],
              [-81.228003944969743, 35.043080914221271],
              [-81.228055283153779, 35.042816027802992],
              [-81.228382899285918, 35.042857536217774],
              [-81.228434236075287, 35.042592648731102],
              [-81.229417082264234, 35.042717168244586],
              [-81.229468415609929, 35.042452280314322],
              [-81.231106493679277, 35.042659793463457],
              [-81.231055165517773, 35.042924682084802],
              [-81.23138278249057, 35.042966181475819],
              [-81.231331455032958, 35.043231070206346],
              [-81.23198669429236, 35.043314068358505],
              [-81.231935368550737, 35.043578956435155],
              [-81.232262989716958, 35.043620454315345],
              [-81.232211664679269, 35.043885342501277],
              [-81.233194533262122, 35.044009830318593],
              [-81.233143211026729, 35.044274719791261],
              [-81.235108963356524, 35.044523670438181],
              [-81.235160279395018, 35.044258781037712],
              [-81.236470783452233, 35.044424729494132],
              [-81.236419472657374, 35.044689619426826],
              [-81.238057616494984, 35.044897035430175],
              [-81.238108922105056, 35.044632144806698],
              [-81.238436550610402, 35.044673625024757],
              [-81.238385247133152, 35.044938515765949],
              [-81.240023400934007, 35.045145904184764],
              [-81.239972102333482, 35.045410796488788],
              [-81.241282636804669, 35.045576690840541],
              [-81.241231341994535, 35.045841582766734],
              [-81.242541885939687, 35.046007463439452],
              [-81.243197161017562, 35.046090398869318],
              [-81.243145872096733, 35.046355291595034],
              [-81.243473511172056, 35.046396758113758],
              [-81.243422222955331, 35.046661650948607],
              [-81.243749863375768, 35.04670311581468],
              [-81.243801149459429, 35.046438222862193],
              [-81.24543935144267, 35.046645535864016],
              [-81.245490632008256, 35.046380642192183],
              [-81.246801195402028, 35.046546474768952],
              [-81.246852471486591, 35.046281580515839],
              [-81.247835394420832, 35.046405945419977],
              [-81.247784121447495, 35.046670840087152],
              [-81.248111764119926, 35.046712293113849],
              [-81.24806049185085, 35.046977187890057],
              [-81.248388135917921, 35.047018641066551],
              [-81.248336864353192, 35.047283535951777],
              [-81.248664509765277, 35.047324987475555],
              [-81.248561967687124, 35.047854776533811],
              [-81.248889616626897, 35.04789622832488],
              [-81.248838346113402, 35.048161122497916],
              [-81.249165995302249, 35.048202572656756],
              [-81.249114725517984, 35.048467467839956],
              [-81.249770028064063, 35.048550365744646],
              [-81.249718761117293, 35.048815261154466],
              [-81.250374067068407, 35.048898155777081],
              [-81.250322800742552, 35.049163050553062],
              [-81.250650455802244, 35.04920449665785],
              [-81.250599190205705, 35.049469392444038],
              [-81.251254503372763, 35.049552281359851],
              [-81.251203239517878, 35.049817177393003],
              [-81.251858556090028, 35.049900063026605],
              [-81.25190981679944, 35.049635167639238],
              [-81.252237474559735, 35.049676609434393],
              [-81.252288733899405, 35.04941171387987],
              [-81.252616390930299, 35.049453153746256],
              [-81.252667648900115, 35.049188258024756],
              [-81.252995305251346, 35.049229697764865],
              [-81.253046561826395, 35.048964800975021],
              [-81.253701873452499, 35.049047677510607],
              [-81.253753127620641, 35.048782780415827],
              [-81.254080781799473, 35.048824217231711],
              [-81.254132034597703, 35.048559319969904],
              [-81.254459689168016, 35.048600755737922],
              [-81.254510940596475, 35.048335858309109],
              [-81.25516624753962, 35.048418726019676],
              [-81.255217496561215, 35.04815382828582],
              [-81.256200456919188, 35.048278123206764],
              [-81.25614921100923, 35.048543021354426],
              [-81.257132177446962, 35.048667307782146],
              [-81.257183420270266, 35.048402410121788],
              [-81.259477013360581, 35.048692379379411],
              [-81.259425778918569, 35.048957278886022],
              [-81.261391736904784, 35.049205789420107],
              [-81.261442964027495, 35.048940889106468],
              [-81.261770623814172, 35.048982304127172],
              [-81.26182185066321, 35.048717403626071],
              [-81.262149509745015, 35.048758817619358],
              [-81.262251958204331, 35.048229017196597],
              [-81.262579615544112, 35.04827043002453],
              [-81.262630839321289, 35.048005529160598],
              [-81.262958494860428, 35.048046940981628],
              [-81.263009717267963, 35.047782039950725],
              [-81.263337372102228, 35.047823450744289],
              [-81.26338859314022, 35.047558549546473],
              [-81.263716247269755, 35.047599959312628],
              [-81.263767465842122, 35.047335057968589],
              [-81.26442277519071, 35.047417873614059],
              [-81.26437155871777, 35.047682776135133],
              [-81.265354527261678, 35.047806993816444],
              [-81.265405740647935, 35.047542091782972],
              [-81.265733396830441, 35.047583496053342],
              [-81.265682184481321, 35.047848398224708],
              [-81.266337499892757, 35.047931203471258],
              [-81.266286289285858, 35.048196105889339],
              [-81.266941608126189, 35.048278908754668],
              [-81.266890399261356, 35.048543811419513],
              [-81.267218060192306, 35.048585210754425],
              [-81.26716685312833, 35.048850113507548],
              [-81.267494515453905, 35.048891512991993],
              [-81.267443307999045, 35.04915641587457],
              [-81.268098636792431, 35.049239211528594],
              [-81.268047431054782, 35.049504113756626],
              [-81.268375097547747, 35.049545510827429],
              [-81.268272688271054, 35.05007531635291],
              [-81.268600356074998, 35.050116712830338],
              [-81.268549152510957, 35.050381615226485],
              [-81.268876822755502, 35.050423010030329],
              [-81.268825618825474, 35.050687913457146],
              [-81.269153290439689, 35.050729307509286],
              [-81.269102088310632, 35.050994211024246],
              [-81.269757433514556, 35.051076996755995],
              [-81.269706233127991, 35.051341900517585],
              [-81.270361582831669, 35.051424682945942],
              [-81.270310384162457, 35.051689586052802],
              [-81.270638059479182, 35.05173097699226],
              [-81.270586861515056, 35.051995880208047],
              [-81.270914539272425, 35.052037269473729],
              [-81.270863342013328, 35.052302172798299],
              [-81.271191021140353, 35.052343561312263],
              [-81.271139825682425, 35.052608464724891],
              [-81.271467506179022, 35.052649852487036],
              [-81.271365114149091, 35.053179659541961],
              [-81.271692797052779, 35.053221046690091],
              [-81.27164160157686, 35.053485950311824],
              [-81.271969286946302, 35.053527336687431],
              [-81.271918092150372, 35.053792239516625],
              [-81.272573464889931, 35.05387501079651],
              [-81.272522271836678, 35.05413991387217],
              [-81.275143784258603, 35.054470960001346],
              [-81.275092600293888, 35.054735865030899],
              [-81.275420292405073, 35.054777241132356],
              [-81.275215556227749, 35.055836859728522],
              [-81.275543252821109, 35.055878235491562],
              [-81.275492069245232, 35.056143139749786],
              [-81.275819767233443, 35.05618451566199],
              [-81.275768584362879, 35.056449420028905],
              [-81.276096283720818, 35.056490795189248],
              [-81.276045101555567, 35.05675569966472],
              [-81.276372802258109, 35.0567970731719],
              [-81.276321620798214, 35.057061977756],
              [-81.276649322895608, 35.057103351412479],
              [-81.27659814211593, 35.05736825520394],
              [-81.276925845583065, 35.057409628108481],
              [-81.276874665533882, 35.057674532909864],
              [-81.277202371441675, 35.05771590414043],
              [-81.277151192097875, 35.05798080905037],
              [-81.277478898279369, 35.058022179549802],
              [-81.277427720737023, 35.058287084547622],
              [-81.277755428288273, 35.058328454295022],
              [-81.277704250355256, 35.058593359422098],
              [-81.278031960372445, 35.058634728396818],
              [-81.277980783144756, 35.0588996336325],
              [-81.278308494531714, 35.058941001855239],
              [-81.278257317984298, 35.05920590629826],
              [-81.277929605559819, 35.059164537937697],
              [-81.277724893019339, 35.060224155747278],
              [-81.278052609594297, 35.060265524658739],
              [-81.277796715205909, 35.061590044949995],
              [-81.278452160850293, 35.061672782361676],
              [-81.278400983031261, 35.061937685886775],
              [-81.27905643207977, 35.062020420014768],
              [-81.279005256003842, 35.062285323786085],
              [-81.279332982624737, 35.062326690093393],
              [-81.279281807254335, 35.062591593973174],
              [-81.279609535219933, 35.062632958627169],
              [-81.279456011350234, 35.063427671384495],
              [-81.279783742761083, 35.063469035561965],
              [-81.279732568162657, 35.063733940501386],
              [-81.280060300943433, 35.063775303926775],
              [-81.279753254619436, 35.065364727423891],
              [-81.279425515612047, 35.065323363172155],
              [-81.27932316138201, 35.065853170808815],
              [-81.278995420631261, 35.06581180539181],
              [-81.278944241954974, 35.06607670812712],
              [-81.278616500498657, 35.066035341682635],
              [-81.278462959388193, 35.066830050200693],
              [-81.278135214054629, 35.066788682473785],
              [-81.278084031973762, 35.067053584816257],
              [-81.277756287030797, 35.0670122160411],
              [-81.277602734610468, 35.067806923401101],
              [-81.277930483763058, 35.067848292568783],
              [-81.277725748130322, 35.068907901292498],
              [-81.278053501766621, 35.068949270121287],
              [-81.277848763822632, 35.070008877143593],
              [-81.277521006034959, 35.069967507763899],
              [-81.27746981887087, 35.070232409774405],
              [-81.277797578792644, 35.070273779271162],
              [-81.277746393405238, 35.070538680467934],
              [-81.278074153600954, 35.070580049233243],
              [-81.27797178393017, 35.071109852715182],
              [-81.278299546533972, 35.071151220866064],
              [-81.278248362225554, 35.071416122250035],
              [-81.278576127295779, 35.071457489628067],
              [-81.278524943693, 35.07172239112024],
              [-81.278852709037182, 35.071763757766767],
              [-81.278801526140072, 35.072028659367199],
              [-81.279129293950703, 35.072070025240855],
              [-81.279026929210417, 35.072599827727608],
              [-81.278719825943853, 35.074189235401946],
              [-81.278392049829407, 35.074147868426593],
              [-81.278289677696648, 35.074677670165855],
              [-81.277961898742305, 35.074636302045754],
              [-81.277808333828091, 35.075431003590204],
              [-81.277480553188227, 35.075389634146298],
              [-81.277378172458981, 35.075919434158806],
              [-81.27705038897912, 35.075878063570187],
              [-81.2769480048444, 35.076407863189004],
              [-81.276620220717206, 35.076366491414305],
              [-81.276313052961925, 35.077955886952644],
              [-81.276640844413762, 35.077997259533085],
              [-81.27658964928051, 35.078262158229741],
              [-81.2769174421028, 35.078303530057887],
              [-81.276815054036774, 35.078833328518307],
              [-81.277142849242537, 35.078874698830759],
              [-81.277091656284611, 35.079139597693292],
              [-81.277419452885994, 35.079180968154709],
              [-81.27736826063385, 35.079445867125401],
              [-81.277696058605599, 35.079487236834552],
              [-81.277644867059294, 35.079752135913246],
              [-81.27797266640161, 35.079793504870153],
              [-81.277921475561016, 35.080058404056913],
              [-81.278249276248644, 35.080099771360253],
              [-81.278198086113846, 35.080364670655136],
              [-81.278525888197038, 35.080406038107434],
              [-81.278474698768136, 35.08067093751044],
              [-81.278802503292994, 35.080712303288443],
              [-81.278751314569917, 35.080977202799481],
              [-81.27907911936893, 35.081018567845938],
              [-81.278823174812402, 35.082343063862623],
              [-81.27915098623113, 35.082384428686879],
              [-81.278946228501979, 35.083444024259002],
              [-81.278618411834088, 35.083402658904667],
              [-81.278567220800539, 35.083667557355213],
              [-81.278895037410265, 35.083708922868006],
              [-81.278843847082513, 35.083973821426532],
              [-81.27949948449718, 35.084056550937937],
              [-81.279448295913639, 35.08432144974212],
              [-81.280103936734136, 35.084404175968672],
              [-81.280052749869597, 35.084669074117272],
              [-81.280380571281, 35.084710436494198],
              [-81.280227011858443, 35.085505132977161],
              [-81.280554837788358, 35.085546493955015],
              [-81.280401277368014, 35.086341189702836],
              [-81.280729106770266, 35.08638255110494],
              [-81.280677920694217, 35.086647449458326],
              [-81.281333583618448, 35.086730168044404],
              [-81.281282399286724, 35.086995066643361],
              [-81.282593732205285, 35.087160494606927],
              [-81.282542551694604, 35.087425393726853],
              [-81.282870387609393, 35.087466748389325],
              [-81.282819207804692, 35.087731647617055],
              [-81.283474881611525, 35.087814354567655],
              [-81.28342370355135, 35.088079254040949],
              [-81.283751542539775, 35.088120606308351],
              [-81.28364918859566, 35.088650405420118],
              [-81.283977028896786, 35.088691757093436],
              [-81.283925852987636, 35.0889566558306],
              [-81.284253695755908, 35.088998006730712],
              [-81.284202519481653, 35.089262906497702],
              [-81.284530363620632, 35.089304256645313],
              [-81.28422330841633, 35.090893651779524],
              [-81.284551159118124, 35.090935001862874],
              [-81.284499982569557, 35.091199900795083],
              [-81.284827835738596, 35.091241250105085],
              [-81.284725484818026, 35.091771048134312],
              [-81.285053339299807, 35.091812396850258],
              [-81.284950989126941, 35.092342195035272],
              [-81.285278847114512, 35.092383543115538],
              [-81.28517649766431, 35.092913340555008],
              [-81.285504358061189, 35.09295468802037],
              [-81.285453183315198, 35.093219586392621],
              [-81.28578104508297, 35.093260933105427],
              [-81.285729872164993, 35.093525832465943],
              [-81.286057734181796, 35.093567177545616],
              [-81.285955389400883, 35.094096975550848],
              [-81.286283254948771, 35.094138320896135],
              [-81.286180909819365, 35.094668119077653],
              [-81.286508777751393, 35.094709462906756],
              [-81.286457606262474, 35.094974361629255],
              [-81.28678547559052, 35.095015705606897],
              [-81.286734304807908, 35.095280604437093],
              [-81.287062175481637, 35.09532194676089],
              [-81.2870110054306, 35.095586846599986],
              [-81.287338878571745, 35.095628188150506],
              [-81.287287708105197, 35.095893087216702],
              [-81.287943457486804, 35.095975768823472],
              [-81.287892288765192, 35.096240668134939],
              [-81.288220164980373, 35.096282007289943],
              [-81.288168998061693, 35.096546906688346],
              [-81.288824753566132, 35.096629582602773],
              [-81.288773588392374, 35.096894482246405],
              [-81.289429346231799, 35.096977155797148],
              [-81.289378182777796, 35.097242054784644],
              [-81.289706063770197, 35.097283389901207],
              [-81.289654902144477, 35.097548289876777],
              [-81.289982784507785, 35.097589624240676],
              [-81.289726971055785, 35.098914122658186],
              [-81.290054860041238, 35.098955456798933],
              [-81.290003697377671, 35.099220355989196],
              [-81.291971041209706, 35.099468343025507],
              [-81.292022196519156, 35.099203442129294],
              [-81.292350087830144, 35.099244770038041],
              [-81.29229893243749, 35.099509670191388],
              [-81.292954717036935, 35.099592323633885],
              [-81.292903564486281, 35.099857224011494],
              [-81.293231458871276, 35.099898549524482],
              [-81.293180305930562, 35.100163450030358],
              [-81.293508201686507, 35.100204774790583],
              [-81.293457050548881, 35.100469675383223],
              [-81.293784947650508, 35.100510998489483],
              [-81.293733797219488, 35.10077589918955],
              [-81.294061695717303, 35.100817222444242],
              [-81.294112845109552, 35.100552321606635],
              [-81.294440741804138, 35.100593643854239],
              [-81.294389593450759, 35.100858544829471],
              [-81.294717492587552, 35.100899865402241],
              [-81.294768639902145, 35.10063496428954],
              [-81.295424437145414, 35.100717604291681],
              [-81.29537329188301, 35.100982504778194],
              [-81.295701192041591, 35.101023823581322],
              [-81.295598903745244, 35.101553626520989],
              [-81.295926806288179, 35.101594943807619],
              [-81.295824517618996, 35.10212474602168],
              [-81.296152422597075, 35.102166063594254],
              [-81.296101279886983, 35.102430964322267],
              [-81.296429186236111, 35.102472281141949],
              [-81.296378043136073, 35.102737181998279],
              [-81.296705951952802, 35.102778498044344],
              [-81.296654809559485, 35.103043399008037],
              [-81.296982718625173, 35.103084713420891],
              [-81.29672700908975, 35.104409219335089],
              [-81.297054924803973, 35.104450534425538],
              [-81.297003782495594, 35.104715435483712],
              [-81.297331699555457, 35.104756748920053],
              [-81.297280557953854, 35.105021650085526],
              [-81.297936395147644, 35.105104274562095],
              [-81.297885256388426, 35.105369175951587],
              [-81.299196938942345, 35.105534415671372],
              [-81.299145803981418, 35.10579931667948],
              [-81.29980164931608, 35.105881931021401],
              [-81.299750516126338, 35.106146833175643],
              [-81.300078440330736, 35.106188139148394],
              [-81.300027307847884, 35.106453041409992],
              [-81.300355234520012, 35.106494346609026],
              [-81.300252969513792, 35.107024150436224],
              [-81.300580898595953, 35.107065455019836],
              [-81.300171836813561, 35.109184667455004],
              [-81.300499774539873, 35.109225972247962],
              [-81.300448640398912, 35.109490873709532],
              [-81.30077657947092, 35.109532176848298],
              [-81.30072544713363, 35.109797078396241],
              [-81.301053388698961, 35.109838381662421],
              [-81.30100225597181, 35.110103283338475],
              [-81.301330198882937, 35.11014458495039],
              [-81.301279067959527, 35.110409486712875],
              [-81.30160701117039, 35.11045078849385],
              [-81.301555880928603, 35.110715689462317],
              [-81.302211771521499, 35.110798290607057],
              [-81.3021606430258, 35.111063191820129],
              [-81.302816536999799, 35.111145788776668],
              [-81.302765410275597, 35.111410691135696],
              [-81.303093359335392, 35.111451987954545],
              [-81.302991106943765, 35.111981791955451],
              [-81.303319058413848, 35.1120230881588],
              [-81.303267932771718, 35.112287990701951],
              [-81.303595885613078, 35.112329286152274],
              [-81.303340258586971, 35.113653794573779],
              [-81.303668216981521, 35.113695090722032],
              [-81.3036170924712, 35.11395999153487],
              [-81.303945052236983, 35.114001286930126],
              [-81.303893927337086, 35.114266187871046],
              [-81.304221889570911, 35.114307482492222],
              [-81.304119640878667, 35.114837285459295],
              [-81.304447605497501, 35.114878578563555],
              [-81.304396481705268, 35.115143480589651],
              [-81.304724447695364, 35.115184772940815],
              [-81.304673324610292, 35.115449675074061],
              [-81.305001291971649, 35.115490966672056],
              [-81.304950169568301, 35.115755868011142],
              [-81.30527813830092, 35.115797158856111],
              [-81.305227016630141, 35.116062061203536],
              [-81.305882957170084, 35.116144640496586],
              [-81.305831837220353, 35.116409542187263],
              [-81.306815753663599, 35.116533405761302],
              [-81.306764637596373, 35.116798307812907],
              [-81.30774856012016, 35.116922162883014],
              [-81.307697446838844, 35.117187065316365],
              [-81.30802542271671, 35.117228348696045],
              [-81.307974310142683, 35.117493251236496],
              [-81.308302287391825, 35.117534533862994],
              [-81.308251175525015, 35.117799436510403],
              [-81.308579154145548, 35.11784071838369],
              [-81.308476931469144, 35.118370522961015],
              [-81.308804913597072, 35.118411804197557],
              [-81.308753802813015, 35.118676707028619],
              [-81.308425819645677, 35.118635425654674],
              [-81.308272482157946, 35.119430132652198],
              [-81.308600468443217, 35.119471414438294],
              [-81.308498243836056, 35.120001218024356],
              [-81.308826232532127, 35.120042499194604],
              [-81.308366213622932, 35.122426615082823],
              [-81.30803821557231, 35.122385332676139],
              [-81.307987099782565, 35.122650234149518],
              [-81.30765910102464, 35.122608950714827],
              [-81.307607982766427, 35.12287385204128],
              [-81.307279983301129, 35.122832567578563],
              [-81.307228864742939, 35.123097467814887],
              [-81.306900863498839, 35.123056183246412],
              [-81.306849743569074, 35.123321083314877],
              [-81.306521741592107, 35.123279796817044],
              [-81.306419497586148, 35.123809597509904],
              [-81.306747501641993, 35.123850884282525],
              [-81.306543013777414, 35.124910484950448],
              [-81.306871022297713, 35.124951770480749],
              [-81.306717655463274, 35.125746470407876],
              [-81.307045667459619, 35.125787756360936],
              [-81.306943424784563, 35.126317556406413],
              [-81.307271439166499, 35.126358840842336],
              [-81.307118073562208, 35.127153540234218],
              [-81.307774110682075, 35.127236108138547],
              [-81.307722990968671, 35.1275010084359],
              [-81.308051010530491, 35.127542291650556],
              [-81.307999891499136, 35.127807191153479],
              [-81.308327912432603, 35.127848473614733],
              [-81.308276794108735, 35.128113373224451],
              [-81.308604817485303, 35.12815465401026],
              [-81.308553699894446, 35.128419554627953],
              [-81.308881723545682, 35.128460834681341],
              [-81.308830606636732, 35.12872573450457],
              [-81.309158632782115, 35.128767014684946],
              [-81.309107516580795, 35.129031914614934],
              [-81.309435544097838, 35.129073194041894],
              [-81.309384427507041, 35.129338094099602],
              [-81.310040485565466, 35.129420648753758],
              [-81.309989371818844, 35.129685549034519],
              [-81.310645433310228, 35.129768101301693],
              [-81.310594321310717, 35.130033001826554],
              [-81.311250387306629, 35.13011555078451],
              [-81.311199277028905, 35.130380450652297],
              [-81.311527311028783, 35.130421724393628],
              [-81.311373982470315, 35.131216426006475],
              [-81.311702019895634, 35.131257698367776],
              [-81.311548691442468, 35.13205239921551],
              [-81.3118767334159, 35.132093671077158],
              [-81.311621183803311, 35.133418171976317],
              [-81.311949231332903, 35.133459444535113],
              [-81.311847010768417, 35.13398924370123],
              [-81.312175060709535, 35.134030515643914],
              [-81.311970620877645, 35.13509011503713],
              [-81.312298675284367, 35.135131385737033],
              [-81.312196455991455, 35.13566118506283],
              [-81.312524512809688, 35.135702455146657],
              [-81.312422294242609, 35.136232253722781],
              [-81.312750353472481, 35.136273523190447],
              [-81.312699245304842, 35.136538423460379],
              [-81.313027305906573, 35.13657969217455],
              [-81.312873980259823, 35.137374390550164],
              [-81.313202045410179, 35.137415658764453],
              [-81.312997610359432, 35.138475256723815],
              [-81.312669541049672, 35.138433987960241],
              [-81.312618430142663, 35.138698887241048],
              [-81.312290360125104, 35.138657617449297],
              [-81.312188135209652, 35.13918741474258],
              [-81.311860063444428, 35.13914614378529],
              [-81.311808949461522, 35.13941104269896],
              [-81.311480876988412, 35.139369770713543],
              [-81.311378645921025, 35.13989956727255],
              [-81.3110505706032, 35.13985829414262],
              [-81.31099945351859, 35.140123191787822],
              [-81.311327529876294, 35.140164465055086],
              [-81.311174178678073, 35.14095915913996],
              [-81.311502258487607, 35.141000431928326],
              [-81.311451142154368, 35.14126532972486],
              [-81.311779223336046, 35.141306601759602],
              [-81.311728107710621, 35.141571499662554],
              [-81.312384272027245, 35.141654040453965],
              [-81.312333158149684, 35.141918938600718],
              [-81.312989326997311, 35.142001476983388],
              [-81.312938214867557, 35.142266375373843],
              [-81.313266300829483, 35.142307643366216],
              [-81.313215190479198, 35.142572540940854],
              [-81.313543277813167, 35.14261380817954],
              [-81.313492167099099, 35.142878706782817],
              [-81.313820255805112, 35.142919973267986],
              [-81.313769145773463, 35.143184871076457],
              [-81.31442532735889, 35.143267401627583],
              [-81.314374219075148, 35.143532299679727],
              [-81.314702311954562, 35.14357356374574],
              [-81.314651204378748, 35.143838461904217],
              [-81.314979298630249, 35.143879725216628],
              [-81.314928191762291, 35.14414462348153],
              [-81.315584382218788, 35.144227146827916],
              [-81.315533278221636, 35.144492046216627],
              [-81.315861374426547, 35.144533306250601],
              [-81.315810271111886, 35.144798204844484],
              [-81.316466467720716, 35.144880723394436],
              [-81.316415366179712, 35.145145623133097],
              [-81.317399668336407, 35.14526939420562],
              [-81.317348568460659, 35.145534293445046],
              [-81.317676670875215, 35.145575548524171],
              [-81.317625572804744, 35.145840447848862],
              [-81.317953676591316, 35.14588170217425],
              [-81.317902579228999, 35.146146601605246],
              [-81.31823068441328, 35.146187856078264],
              [-81.318179587759062, 35.146452755615471],
              [-81.31850769428975, 35.146494008433521],
              [-81.318405503137015, 35.147023806767415],
              [-81.318733612105746, 35.147065059870272],
              [-81.318682516510023, 35.147329958687841],
              [-81.319010626825147, 35.147371210135709],
              [-81.318908436769391, 35.14790100975496],
              [-81.319236550594056, 35.147942260565308],
              [-81.319083266089407, 35.148736958371003],
              [-81.319411383366543, 35.148778208702105],
              [-81.319360288924997, 35.149043108646744],
              [-81.319688407574304, 35.149084358224059],
              [-81.319586219724158, 35.149614156492241],
              [-81.319914340785871, 35.149655405453046],
              [-81.319863247403035, 35.149920304677792],
              [-81.320191369836934, 35.149961552884918],
              [-81.320089185252883, 35.15049135149475],
              [-81.320417310099202, 35.150532599085253],
              [-81.32036621780081, 35.150797498491414],
              [-81.32069434511665, 35.150838745306963],
              [-81.320643253526413, 35.151103644819294],
              [-81.32097138109151, 35.151144890000964],
              [-81.320920291306905, 35.151409789598333],
              [-81.321576549564895, 35.151492279365506],
              [-81.321525461503057, 35.15175717830509],
              [-81.322181724266855, 35.151839664761468],
              [-81.322130636881965, 35.152104564866633],
              [-81.322786904125806, 35.152187047110964],
              [-81.322735818463812, 35.15245194655833],
              [-81.323063953636762, 35.152493186931736],
              [-81.323012869806192, 35.15275808736537],
              [-81.323669142105516, 35.152840564833205],
              [-81.323618059997912, 35.15310546460892],
              [-81.323946198247299, 35.153146702583562],
              [-81.323895116847993, 35.153411602465376],
              [-81.324223256469651, 35.153452839686068],
              [-81.324172175778813, 35.153717739673972],
              [-81.324500316746935, 35.153758975239626],
              [-81.324449235667174, 35.154023875354724],
              [-81.32477737913068, 35.154065111046549],
              [-81.324726299856664, 35.154330011246707],
              [-81.325054443569414, 35.154371245304489],
              [-81.325003365003823, 35.154636145610638],
              [-81.325331510114566, 35.154677379815858],
              [-81.325229354068142, 35.155207180609047],
              [-81.325557502663273, 35.155248413275189],
              [-81.325506425182667, 35.15551331376227],
              [-81.325834574052649, 35.15555454569563],
              [-81.325732420176251, 35.156084346850704],
              [-81.326060572556401, 35.156125578146174],
              [-81.326009496160722, 35.156390478814195],
              [-81.3263376499131, 35.156431709355715],
              [-81.326235498181219, 35.156961509971332],
              [-81.326563654346444, 35.157002739896079],
              [-81.326461503367668, 35.157532540661329],
              [-81.326789661945597, 35.157573769969275],
              [-81.326534284951435, 35.158898270649573],
              [-81.326206121171197, 35.158857040655768],
              [-81.326001808514988, 35.159916638484134],
              [-81.326329977580301, 35.159957869906698],
              [-81.326278899607573, 35.160222768746998],
              [-81.326607070019719, 35.160263998514345],
              [-81.326555992781266, 35.160528898361747],
              [-81.3262278213287, 35.160487668457208],
              [-81.326176742717792, 35.16075256813626],
              [-81.326504915210975, 35.160793798177934],
              [-81.326453837282941, 35.161058697061641],
              [-81.326782011148509, 35.161099926349316],
              [-81.326730933954877, 35.161364826240174],
              [-81.32705910919293, 35.161406054773735],
              [-81.326956955839378, 35.161935852933503],
              [-81.327285133490477, 35.161977080850264],
              [-81.327234057382128, 35.162241980921557],
              [-81.32756223640574, 35.16228320808429],
              [-81.327511162077641, 35.162548107339163],
              [-81.327839342473766, 35.16258933374786],
              [-81.327635042712259, 35.163648931066277],
              [-81.32796322760268, 35.163690157132365],
              [-81.327350307661149, 35.166868941438345],
              [-81.327678506468786, 35.166910168237813],
              [-81.327372034726011, 35.168499556365433],
              [-81.327043828602427, 35.168458329665363],
              [-81.326992747571552, 35.168723226692087],
              [-81.327320954761703, 35.168764454430416],
              [-81.326503627623865, 35.17300280661879],
              [-81.326299281945239, 35.174062391163574],
              [-81.325971052841524, 35.17402116070344],
              [-81.325715607205652, 35.175345638652978],
              [-81.326043841513766, 35.175386869798778],
              [-81.325890572350872, 35.176181555176136],
              [-81.326218811211405, 35.176222785820976],
              [-81.326167721509918, 35.176487681328091],
              [-81.32649596171774, 35.176528910317487],
              [-81.326393784497967, 35.17705870149026],
              [-81.326722026021983, 35.177099929883667],
              [-81.326670938503796, 35.177364825549233],
              [-81.326999181400922, 35.177406053188413],
              [-81.326948094591529, 35.177670948959417],
              [-81.327276339959425, 35.177712175823224],
              [-81.327225253858927, 35.177977071699821],
              [-81.327553499502102, 35.178018297830498],
              [-81.326940448187287, 35.181197036796426],
              [-81.327268708875778, 35.181238264540177],
              [-81.327115441646839, 35.182032947177838],
              [-81.327443704692911, 35.182074174462699],
              [-81.327341526578849, 35.182603962409779],
              [-81.327669793136906, 35.182645189056295],
              [-81.327516527147253, 35.183439871139697],
              [-81.327844797160722, 35.183481097306121],
              [-81.327589352016375, 35.184805566601682],
              [-81.327261076823007, 35.184764340650879],
              [-81.327158894148951, 35.185294128237196],
              [-81.326830617179681, 35.18525290021946],
              [-81.326728429997132, 35.185782687425565],
              [-81.326400151277582, 35.185741458242092],
              [-81.326349056146924, 35.186006351660353],
              [-81.326020776718451, 35.185965121448262],
              [-81.325918583352518, 35.186494907013817],
              [-81.32559030110184, 35.186453676558308],
              [-81.325539203964397, 35.18671856868454],
              [-81.325210921004597, 35.1866773372005],
              [-81.325159821396028, 35.186942229178811],
              [-81.325057621207947, 35.187472013941267],
              [-81.325385907291221, 35.187513245836776],
              [-81.325334807727302, 35.187778137856796],
              [-81.325006520602827, 35.187736905824124],
              [-81.32490431839588, 35.188266689494398],
              [-81.325232607602786, 35.188307921801332],
              [-81.325079304898779, 35.189102596577911],
              [-81.324751012568186, 35.189061363859558],
              [-81.324699909969851, 35.189326255551336],
              [-81.324043323099332, 35.189243786285765],
              [-81.32399221808619, 35.189508677671299],
              [-81.32366392464418, 35.189467441102877],
              [-81.323612818257558, 35.18973233231943],
              [-81.322956229215492, 35.189649857156049],
              [-81.322905120414234, 35.189914748066435],
              [-81.322576824788584, 35.189873508570237],
              [-81.322423492118119, 35.19066817891715],
              [-81.322751789769711, 35.190709418845906],
              [-81.322547344956334, 35.191768978222647],
              [-81.322875648203137, 35.191810217787314],
              [-81.3228245366614, 35.192075107699374],
              [-81.322167929541024, 35.191992628284197],
              [-81.322116815584423, 35.192257517890013],
              [-81.321788510945282, 35.192216277169081],
              [-81.321737395589494, 35.192481165704592],
              [-81.321409091339206, 35.192439923933854],
              [-81.32135797463549, 35.192704813201452],
              [-81.321029668552697, 35.192663569521855],
              [-81.32097855047526, 35.192928458620322],
              [-81.320650243709153, 35.192887214813254],
              [-81.320599124232515, 35.193152102841353],
              [-81.320270816757429, 35.193110858005511],
              [-81.320219695907042, 35.193375745864515],
              [-81.31989138772299, 35.193334499999956],
              [-81.319840265499025, 35.193599387689687],
              [-81.319511956606021, 35.193558140796412],
              [-81.319460833008307, 35.193823028317141],
              [-81.319132522308564, 35.193781780416089],
              [-81.319081397337158, 35.19404666776758],
              [-81.318753087026238, 35.19400541881685],
              [-81.318701960681224, 35.19427030599909],
              [-81.318373648537843, 35.194229055139381],
              [-81.318322520844674, 35.194493943053708],
              [-81.31799420909023, 35.194452691144214],
              [-81.317943078899916, 35.194717578009183],
              [-81.317614766462185, 35.194676325972111],
              [-81.31751250409944, 35.195206099310568],
              [-81.317184188787948, 35.195164845227382],
              [-81.317133056641211, 35.195429732602001],
              [-81.316804740620796, 35.195388477490098],
              [-81.316753607074574, 35.195653363794257],
              [-81.316425290345251, 35.195612107653488],
              [-81.316374154327434, 35.195876993809627],
              [-81.316045837987119, 35.195835736619053],
              [-81.315994700595397, 35.196100622605805],
              [-81.315666382448285, 35.196059364407411],
              [-81.315615243682842, 35.196324250224954],
              [-81.31528692595036, 35.196282991878007],
              [-81.31518464531365, 35.196812762241251],
              [-81.314856324707421, 35.196771501848112],
              [-81.314805182861818, 35.197036387295036],
              [-81.314476861546694, 35.196995125873052],
              [-81.314425718327143, 35.19726001115064],
              [-81.314097396303154, 35.197218748699932],
              [-81.313995105660339, 35.197748518004317],
              [-81.313841667738444, 35.198543171259011],
              [-81.313513340840032, 35.198501907230508],
              [-81.313359896801174, 35.199296559784543],
              [-81.313031567111082, 35.199255294452733],
              [-81.312724661533224, 35.20084459520838],
              [-81.312396324829251, 35.200803328182594],
              [-81.312038245486477, 35.202657508328109],
              [-81.311577831955972, 35.205041447308673],
              [-81.311906185324091, 35.205082716530171],
              [-81.311803869360872, 35.205612479771361],
              [-81.312132226242767, 35.205653748354649],
              [-81.312081068790945, 35.205918629613372],
              [-81.313066143433446, 35.206042429544489],
              [-81.313014988799438, 35.206307312083901],
              [-81.313343349094964, 35.206348577374833],
              [-81.313241041959103, 35.206878340807741],
              [-81.312912679605958, 35.206837076143714],
              [-81.312861523948428, 35.207101957684976],
              [-81.312759211635623, 35.207631720670761],
              [-81.313744310216677, 35.207755515004401],
              [-81.31369315668627, 35.208020396860469],
              [-81.31500662990085, 35.20818544344278],
              [-81.314955480204446, 35.208450325815299],
              [-81.31561222143965, 35.208532844019928],
              [-81.315561074591983, 35.208797726613554],
              [-81.316217819240819, 35.20888024152557],
              [-81.316166674144043, 35.209145124361285],
              [-81.316495047447134, 35.209186380176746],
              [-81.316443903059493, 35.209451263117202],
              [-81.316772278834918, 35.209492518157155],
              [-81.316721134058241, 35.209757401223484],
              [-81.317377888715797, 35.209839909803513],
              [-81.317326746762433, 35.210104792189703],
              [-81.317655125618927, 35.210146044828633],
              [-81.317603984400492, 35.210410928220853],
              [-81.317932364631247, 35.210452180105207],
              [-81.317881224121919, 35.210717063602168],
              [-81.318209605726949, 35.210758314731954],
              [-81.318107325768622, 35.21128808100206],
              [-81.317778940981981, 35.211246829618979],
              [-81.317472086824111, 35.212836125026669],
              [-81.317143696093751, 35.212794871027626],
              [-81.316990261387033, 35.213589517431856],
              [-81.316661867889465, 35.213548263030702],
              [-81.316508425940313, 35.214342907851467],
              [-81.316836822563232, 35.214384162664125],
              [-81.316785675998105, 35.214649044636744],
              [-81.317114072897397, 35.214690298715816],
              [-81.317011780827343, 35.215220061936797],
              [-81.317340181241036, 35.215261315377248],
              [-81.317237888826156, 35.215791078763779],
              [-81.317566291656178, 35.215832331586796],
              [-81.317617437524262, 35.215567449311486],
              [-81.317945839645006, 35.215608701105509],
              [-81.318048126154764, 35.215078938027666],
              [-81.318376526524361, 35.215120188655604],
              [-81.318478809594495, 35.214590424272409],
              [-81.318807208212945, 35.214631673734047],
              [-81.318909487843527, 35.214101908045635],
              [-81.319237884710759, 35.214143156341201],
              [-81.319289022985444, 35.21387827331116],
              [-81.319617418045311, 35.213919520598822],
              [-81.319668554971216, 35.213654638300419],
              [-81.320325346175451, 35.21373712898265],
              [-81.320376479587139, 35.213472246398588],
              [-81.323003647224141, 35.213802174201966],
              [-81.32305477304098, 35.213537289566155],
              [-81.323711565629267, 35.213619761884424],
              [-81.323660442994282, 35.21388464677338],
              [-81.32431724009416, 35.21396711577745],
              [-81.324368360645423, 35.213702230614288],
              [-81.325353555675633, 35.213825927470921],
              [-81.325404671670739, 35.213561041885157],
              [-81.326718265513279, 35.213725957392413],
              [-81.326667153685676, 35.213990843526531],
              [-81.327652355670182, 35.214114520753178],
              [-81.32760124553775, 35.214379407287268],
              [-81.328914858068075, 35.21454429709587],
              [-81.328863752869097, 35.214809184124697],
              [-81.32919215759992, 35.214850404489553],
              [-81.329141053110448, 35.215115291623015],
              [-81.329797866751065, 35.215197729930104],
              [-81.329746764013223, 35.215462617305171],
              [-81.330731990641411, 35.215586268477423],
              [-81.330680890697039, 35.215851156231217],
              [-81.331337711802718, 35.215933585266534],
              [-81.33128661360999, 35.216198473262082],
              [-81.331615026265212, 35.216239687018991],
              [-81.331563928756452, 35.216504574217886],
              [-81.332220757147638, 35.21658699933009],
              [-81.33216966139058, 35.216851886770613],
              [-81.332498077126615, 35.216893098125816],
              [-81.332446983177462, 35.217157985649742],
              [-81.332775400287673, 35.217199196250228],
              [-81.33272430595008, 35.217464083899827],
              [-81.333052724434594, 35.217505293745369],
              [-81.333001631904935, 35.2177701814784],
              [-81.333330051763824, 35.217811390569054],
              [-81.333278958845739, 35.218076278427851],
              [-81.333607381177018, 35.218117486742408],
              [-81.333505197526264, 35.218647262615391],
              [-81.333833621149978, 35.218688469432053],
              [-81.333782530417409, 35.21895335744621],
              [-81.334110955441247, 35.218994564409108],
              [-81.333957684350253, 35.219789227766448],
              [-81.334286112832558, 35.219830434248514],
              [-81.333877381383942, 35.221949534139405],
              [-81.334205819607831, 35.221990739903148],
              [-81.334154726890361, 35.222255627058018],
              [-81.334103634938771, 35.222520514159129],
              [-81.33377519463059, 35.222479308121365],
              [-81.33362191253174, 35.223273967938191],
              [-81.333293468331121, 35.223232760618622],
              [-81.33324237296614, 35.223497647315398],
              [-81.332913928029811, 35.223456438065604],
              [-81.332760634592034, 35.22425109669048],
              [-81.333089082654823, 35.224292306351217],
              [-81.333037984836523, 35.224557192037572],
              [-81.333366434273913, 35.224598400943414],
              [-81.333264240848777, 35.225128173372283],
              [-81.332935789326953, 35.225086964192407],
              [-81.332884690511676, 35.225351849780857],
              [-81.333213143075625, 35.225393059097748],
              [-81.333110947630729, 35.22592283042961],
              [-81.333439402611646, 35.225964039128485],
              [-81.333235010804529, 35.227023581969533],
              [-81.333563471385006, 35.227064790303217],
              [-81.333410177415885, 35.227859446595552],
              [-81.333359078403944, 35.228124332268422],
              [-81.333030614752673, 35.228083123365529],
              [-81.332928413647352, 35.228612894339179],
              [-81.332599948243839, 35.228571684270221],
              [-81.332446640940248, 35.229366339173147],
              [-81.332118171643927, 35.229325127822278],
              [-81.331964858196201, 35.230119781118361],
              [-81.331636387229366, 35.230078569344442],
              [-81.33117641725589, 35.23246252266857],
              [-81.33150489980072, 35.232503735633273],
              [-81.331402682048989, 35.233033502262835],
              [-81.331731167036864, 35.233074715510796],
              [-81.331680059241023, 35.233339598452154],
              [-81.332008545603713, 35.233380810944986],
              [-81.331957438517875, 35.233645693990518],
              [-81.332285925131302, 35.23368690484822],
              [-81.332234818781288, 35.233951788899191],
              [-81.332563307867858, 35.233992998980618],
              [-81.332512202202153, 35.234257882234523],
              [-81.332461096229764, 35.234522766356918],
              [-81.332789587733629, 35.234563975820144],
              [-81.332738482445464, 35.234828859145487],
              [-81.333066975324215, 35.234870067853578],
              [-81.332913660592851, 35.235664718043495],
              [-81.333242156931419, 35.235705926270462],
              [-81.332935525821057, 35.237295225662514],
              [-81.332607022129025, 35.237254016634765],
              [-81.332555914533344, 35.237518898867144],
              [-81.332227410131296, 35.237477688810294],
              [-81.33217630118655, 35.237742571774049],
              [-81.332504806631135, 35.237783781967948],
              [-81.332351479779575, 35.238578429291444],
              [-81.332679988684419, 35.23861963900417],
              [-81.332628880469088, 35.23888452120903],
              [-81.333614412305621, 35.239008145405187],
              [-81.333563306911401, 35.239273028889322],
              [-81.334548844867498, 35.239396645466741],
              [-81.334497743341259, 35.239661528406472],
              [-81.335154772392571, 35.239743935271079],
              [-81.335103671520642, 35.240008818472958],
              [-81.336417739953518, 35.240173621100148],
              [-81.336468835556403, 35.239908737371472],
              [-81.336797351910164, 35.239949935896696],
              [-81.33674625734983, 35.240214819762315],
              [-81.337403294212308, 35.240297213487629],
              [-81.337352201404855, 35.240562097594044],
              [-81.338009241707894, 35.240644488925611],
              [-81.337958149529101, 35.240909372393084],
              [-81.338615194345309, 35.24099176040842],
              [-81.338564105017895, 35.241256644095579],
              [-81.339549676738301, 35.241380218957765],
              [-81.339498590232466, 35.241645103923972],
              [-81.340155642516621, 35.24172748266357],
              [-81.340104557738044, 35.241992366969427],
              [-81.34043308543427, 35.242033555588385],
              [-81.340330916991419, 35.242563325276251],
              [-81.340659447079361, 35.242604512375628],
              [-81.3406083634152, 35.242869397757588],
              [-81.340506196136573, 35.243399166598877],
              [-81.340834729710991, 35.243440354117972],
              [-81.340681478879674, 35.244235007553648],
              [-81.341010015914648, 35.244276194591208],
              [-81.340856765220934, 35.245070848140493],
              [-81.341185305690516, 35.24511203379528],
              [-81.341083139351625, 35.245641802929697],
              [-81.341411683337739, 35.245682987944747],
              [-81.34120734969288, 35.246742524583368],
              [-81.340878801535524, 35.246701339020674],
              [-81.340776630084221, 35.247231106878374],
              [-81.34044808017353, 35.247189920149573],
              [-81.339988282715439, 35.249573869484252],
              [-81.339659722652755, 35.249532680652123],
              [-81.339608631338677, 35.249797563508871],
              [-81.339937192444253, 35.249838752477906],
              [-81.339835009780316, 35.250368517485789],
              [-81.340163573329974, 35.250409706737507],
              [-81.340061392520994, 35.250939471865358],
              [-81.340389958462964, 35.250980659597339],
              [-81.340134505825617, 35.252305072070321],
              [-81.33980593357019, 35.252263883675042],
              [-81.339601560165391, 35.253323411087536],
              [-81.339272985169231, 35.253282221231004],
              [-81.339119698221708, 35.254076866036144],
              [-81.338791119330324, 35.254035674897736],
              [-81.338637826235953, 35.254830318091749],
              [-81.338309244548086, 35.254789125650163],
              [-81.338207045028781, 35.255318887046073],
              [-81.337878461587238, 35.255277693438252],
              [-81.337827359723633, 35.255542573509381],
              [-81.337498775571518, 35.255501378872232],
              [-81.337447672332331, 35.255766258773178],
              [-81.337119087469674, 35.255725063106695],
              [-81.337067983979651, 35.255989943717402],
              [-81.336739398380573, 35.255948746120374],
              [-81.336483865130617, 35.257273144491101],
              [-81.336812455944838, 35.257314342772787],
              [-81.336761349335262, 35.25757922230369],
              [-81.337089942597999, 35.257620418907457],
              [-81.337038836698994, 35.257885298541972],
              [-81.337367430238487, 35.25792649441145],
              [-81.337316325050068, 35.258191374149554],
              [-81.337644921063884, 35.25823256924231],
              [-81.33759381658605, 35.258497449083983],
              [-81.337922413975548, 35.258538643421232],
              [-81.337820206082668, 35.259068402377217],
              [-81.337491607705687, 35.259027207744921],
              [-81.337440502230365, 35.259292087486592],
              [-81.337111902044043, 35.259250891846165],
              [-81.336958580441717, 35.260045528657777],
              [-81.3366299774586, 35.260004331714086],
              [-81.33657886856686, 35.260269211048438],
              [-81.336907472593197, 35.260310408129037],
              [-81.336856364386307, 35.26057528666572],
              [-81.337184969788211, 35.260616482990777],
              [-81.337082754488904, 35.261146241138995],
              [-81.337411363408535, 35.26118743682423],
              [-81.337258041056387, 35.261982072855012],
              [-81.336929429007228, 35.261940876759034],
              [-81.336673879861067, 35.263265267889466],
              [-81.33700249712625, 35.263306464670109],
              [-81.336900276137797, 35.26383622154367],
              [-81.337228895822008, 35.263877417705594],
              [-81.337177786395813, 35.264142295317335],
              [-81.337506407455692, 35.264183490723667],
              [-81.337455298766073, 35.264448369340059],
              [-81.338112543970155, 35.264530757749],
              [-81.338061437008534, 35.264795635704537],
              [-81.338390062251463, 35.264836828685887],
              [-81.338338956000584, 35.265101706744865],
              [-81.338996207372929, 35.265184090346267],
              [-81.338945102876096, 35.265448968645636],
              [-81.33927373120315, 35.265490159223162],
              [-81.339222627417158, 35.265755037625865],
              [-81.339551256021124, 35.265796227469025],
              [-81.339500154044757, 35.266061105953895],
              [-81.342786474221725, 35.266472953854716],
              [-81.34273538234612, 35.266737833674853],
              [-81.343064017766949, 35.266779013051263],
              [-81.343012926602242, 35.267043892974769],
              [-81.345313390138955, 35.267332127300726],
              [-81.345364474000078, 35.267067246419266],
              [-81.346021750518929, 35.267149590899642],
              [-81.346072830862056, 35.266884709732309],
              [-81.347058744964059, 35.267008217996271],
              [-81.347007666652132, 35.2672730995954],
              [-81.347664946468711, 35.267355434248785],
              [-81.347613871010083, 35.267620316066726],
              [-81.347942512460293, 35.267661482191379],
              [-81.347891437712732, 35.267926364112633],
              [-81.348220080538695, 35.26796752948151],
              [-81.348117932133462, 35.26849729349707],
              [-81.34844657737861, 35.268538458246987],
              [-81.34839550372088, 35.2688033403413],
              [-81.348724151440678, 35.268844504314067],
              [-81.348622004090288, 35.269374267795826],
              [-81.348950654229327, 35.269415431149611],
              [-81.348899581635479, 35.269680312515703],
              [-81.349228233150342, 35.269721475113677],
              [-81.349126089078652, 35.270251238920046],
              [-81.349454741913917, 35.270292400920326],
              [-81.349403670409998, 35.270557282459372],
              [-81.34973232571997, 35.270598443682495],
              [-81.349681254927219, 35.270863325324747],
              [-81.350009911613029, 35.270904485792045],
              [-81.349907771117643, 35.271434249249424],
              [-81.350236430222793, 35.271475409097746],
              [-81.350185360520086, 35.271740290912767],
              [-81.350514022100015, 35.271781449983798],
              [-81.350360814128706, 35.272576095637987],
              [-81.350689478046647, 35.272617253346787],
              [-81.350536271313914, 35.273411899087506],
              [-81.350864938694755, 35.273453056314082],
              [-81.350762801638851, 35.273982819338279],
              [-81.351091471465224, 35.274023976846941],
              [-81.3509893351671, 35.274553740010155],
              [-81.351318008512038, 35.274594896878341],
              [-81.351266940345553, 35.274859778106325],
              [-81.351595615040395, 35.274900933317397],
              [-81.35154454761134, 35.275165815549705],
              [-81.351873223682048, 35.275206970004852],
              [-81.351822156964289, 35.275471852340274],
              [-81.35215083441102, 35.275513006039489],
              [-81.352099769477419, 35.275777887555435],
              [-81.352428448326165, 35.275819041399927],
              [-81.352377383004921, 35.276083923040346],
              [-81.352706064302552, 35.276125075206323],
              [-81.35265499969266, 35.276389956949807],
              [-81.352983681293338, 35.276431109282413],
              [-81.352932618493881, 35.276695991107609],
              [-81.353261301444391, 35.276737141783045],
              [-81.353210239356287, 35.277002023711312],
              [-81.353538923682791, 35.277043173630787],
              [-81.353487862306082, 35.277308055662083],
              [-81.353816548034644, 35.277349205726864],
              [-81.353765487343267, 35.277614086959936],
              [-81.354094174447894, 35.277655236268757],
              [-81.354043114467856, 35.277920117604872],
              [-81.354371804047474, 35.277961266136295],
              [-81.354320743679949, 35.278226147596826],
              [-81.354978125897361, 35.278308441353701],
              [-81.354927068384001, 35.278573323032695],
              [-81.355255761047857, 35.278614469159294],
              [-81.355204703146953, 35.278879350962654],
              [-81.355533397160741, 35.278920495432061],
              [-81.355482341096405, 35.279185378218237],
              [-81.355811036486301, 35.279226521931605],
              [-81.355759981107283, 35.279491403919593],
              [-81.356088677873217, 35.279532546876929],
              [-81.356037623231785, 35.279797429869092],
              [-81.356366322472766, 35.279838572048988],
              [-81.356315268516767, 35.280103454242933],
              [-81.356972667885032, 35.280185736240675],
              [-81.356768457114995, 35.281245266673814],
              [-81.357097162571776, 35.281286406858911],
              [-81.356995057241761, 35.281816170794471],
              [-81.357323765118451, 35.281857310360294],
              [-81.357221660573174, 35.282387075335258],
              [-81.357550370869816, 35.282428214281602],
              [-81.357448267057023, 35.28295797849367],
              [-81.357776979773647, 35.282999116820704],
              [-81.357725928425879, 35.283263999463273],
              [-81.357368560656369, 35.285118172486676],
              [-81.357039839588609, 35.285077033066003],
              [-81.356886673389965, 35.285871677867426],
              [-81.356557948423614, 35.285830537165182],
              [-81.356455833857808, 35.286360299322524],
              [-81.356127107135876, 35.286319157453995],
              [-81.356024989126752, 35.28684891830116],
              [-81.355696260675316, 35.286807776167556],
              [-81.355645200115319, 35.287072655952954],
              [-81.355973928537608, 35.287113799145871],
              [-81.355820746948496, 35.287908440532654],
              [-81.35614948103327, 35.287949583200039],
              [-81.355996299559038, 35.288744223790339],
              [-81.356325036009096, 35.288785365996411],
              [-81.356222915068727, 35.289315125899961],
              [-81.356551655038288, 35.289356267465159],
              [-81.356449534856708, 35.289886027506334],
              [-81.35677827724669, 35.289927168452067],
              [-81.356676157823841, 35.290456928630832],
              [-81.356522977845756, 35.291251568611941],
              [-81.356194230235573, 35.291210426982701],
              [-81.356092105401061, 35.291740186548125],
              [-81.355763356035098, 35.291699043752551],
              [-81.35571229259871, 35.291963922435649],
              [-81.355383542547131, 35.291922779511637],
              [-81.355281411516046, 35.292452537424943],
              [-81.354952658583301, 35.292411392454845],
              [-81.354799456948001, 35.293206028198156],
              [-81.354470701241496, 35.293164882826133],
              [-81.354368562683987, 35.293694638950974],
              [-81.354039805195583, 35.293653491511364],
              [-81.353937663246228, 35.294183248127773],
              [-81.353608904001902, 35.294142099521778],
              [-81.35350675750928, 35.294671854848652],
              [-81.353177997608356, 35.294630705054956],
              [-81.353126922819285, 35.294895582530536],
              [-81.353455684863491, 35.294936732439673],
              [-81.353404610786143, 35.295201610017912],
              [-81.353075847697681, 35.295160459972038],
              [-81.352922620312242, 35.295955091190798],
              [-81.353251385433865, 35.295996241668199],
              [-81.353149234260982, 35.296525995616527],
              [-81.353478002902648, 35.296567145453167],
              [-81.353426927848588, 35.296832022062283],
              [-81.353755697867001, 35.296873171142487],
              [-81.35370462355074, 35.297138048755492],
              [-81.354033394919739, 35.297179196178298],
              [-81.353931248451545, 35.297708950652591],
              [-81.354260022267511, 35.297750098357064],
              [-81.354208949016254, 35.298014975239823],
              [-81.354537724208882, 35.298056122188029],
              [-81.35448665166949, 35.298320999173328],
              [-81.35514420621368, 35.2984032897416],
              [-81.355093135456329, 35.298668167867383],
              [-81.357065814432062, 35.298915018938956],
              [-81.357014749581879, 35.299179896949639],
              [-81.35800109777621, 35.29930331036784],
              [-81.357950035726489, 35.299568188754314],
              [-81.358278820158247, 35.299609324610614],
              [-81.358227759919743, 35.299874203078211],
              [-81.358556545728192, 35.299915338178167],
              [-81.358505486201622, 35.300180216748245],
              [-81.358834273412967, 35.300221351993031],
              [-81.358783213472819, 35.300486229785811],
              [-81.359112003160178, 35.300527364252837],
              [-81.359060943931993, 35.300792242148084],
              [-81.359389734996043, 35.30083337585873],
              [-81.359338676479823, 35.301098253856523],
              [-81.359667468920591, 35.301139386810704],
              [-81.359616412215658, 35.30140426488957],
              [-81.359945204907689, 35.301445396207662],
              [-81.359894148940896, 35.301710275290091],
              [-81.36022294300966, 35.301751405851782],
              [-81.360171887728825, 35.302016284135441],
              [-81.36050068429968, 35.302057414820517],
              [-81.360449629730866, 35.302322293206601],
              [-81.360778427652406, 35.30236342223408],
              [-81.360727373795541, 35.302628300722603],
              [-81.361384971625014, 35.30271055639308],
              [-81.361333919550859, 35.302975436021818],
              [-81.361662720558115, 35.303016562643379],
              [-81.361611669169889, 35.303281441473452],
              [-81.361940471553893, 35.303322567338554],
              [-81.361889420877745, 35.303587446270939],
              [-81.362218224638511, 35.303628571379619],
              [-81.361911922067634, 35.305217845031365],
              [-81.362240732427537, 35.305258970066738],
              [-81.362087579762758, 35.306053605938665],
              [-81.362416394687813, 35.306094730469376],
              [-81.362365343795432, 35.306359608567689],
              [-81.362694160097334, 35.30640073234192],
              [-81.362643109943377, 35.306665611443769],
              [-81.362971927595865, 35.306706733560368],
              [-81.3629208781541, 35.306971612764535],
              [-81.363249697209611, 35.307012735025744],
              [-81.363198648453846, 35.307277613431019],
              [-81.363527468886232, 35.30731873493572],
              [-81.363476421942053, 35.307583613421812],
              [-81.364134064767384, 35.307665853145316],
              [-81.364083019606085, 35.307930732771517],
              [-81.365727140442999, 35.308136316628975],
              [-81.365676100172337, 35.308401196903858],
              [-81.366004926370636, 35.30844231077203],
              [-81.365953886786059, 35.308707190248015],
              [-81.366611542294947, 35.308789416479165],
              [-81.366560504467287, 35.30905429619402],
              [-81.367218164493863, 35.309136519104243],
              [-81.367167128423048, 35.309401399057819],
              [-81.367495959416573, 35.309442508869935],
              [-81.367444925157528, 35.309707388904229],
              [-81.367773757554161, 35.309748498860934],
              [-81.367722722881823, 35.310013378117745],
              [-81.368051557754768, 35.310054487296327],
              [-81.368000523794834, 35.310319366655399],
              [-81.368658195500984, 35.310401581726424],
              [-81.36860716442385, 35.310666462203969],
              [-81.368936001257069, 35.310707568096454],
              [-81.368629810707972, 35.3122968484194],
              [-81.368300965407713, 35.312255741750519],
              [-81.368147861950746, 35.313050380597943],
              [-81.367819014948168, 35.313009272604589],
              [-81.367767978377998, 35.313274151721764],
              [-81.368096826425372, 35.313315259851514],
              [-81.368045791667114, 35.313580139049357],
              [-81.367716941475621, 35.313539030804428],
              [-81.367512790543117, 35.314598546790549],
              [-81.367183937604381, 35.314557437084623],
              [-81.367132897969583, 35.314822314957283],
              [-81.367461751979221, 35.314863425700914],
              [-81.366951347932485, 35.317512208401482],
              [-81.36662248240161, 35.317471097214742],
              [-81.36646935043396, 35.318265729611703],
              [-81.366316215475081, 35.319060361697638],
              [-81.36598734290807, 35.319019248819707],
              [-81.365936296552476, 35.319284125067604],
              [-81.365607423272877, 35.319243011159735],
              [-81.365454278056518, 35.320037642011279],
              [-81.364796526199186, 35.31995540979495],
              [-81.364745475405769, 35.320220286395966],
              [-81.364416598917686, 35.320179168360667],
              [-81.364365545647416, 35.320444044812],
              [-81.36337891619506, 35.320320686717366],
              [-81.363327859457655, 35.320585562724311],
              [-81.362670105320106, 35.320503318968811],
              [-81.362619046134327, 35.320768193766661],
              [-81.361961291263114, 35.320685947065911],
              [-81.361910229655123, 35.320950821555932],
              [-81.361581351673351, 35.320909696728982],
              [-81.361530288688016, 35.32117457104777],
              [-81.361201409967677, 35.321133444289714],
              [-81.361099279836395, 35.321663193472872],
              [-81.360770399384947, 35.321622066449393],
              [-81.360668265807817, 35.322151814319525],
              [-81.360997149448636, 35.322192941594743],
              [-81.360843949174125, 35.322987563560602],
              [-81.361172836308342, 35.323028691253462],
              [-81.361121769563567, 35.323293565316526],
              [-81.36145065804908, 35.323334691351576],
              [-81.361399592016724, 35.323599565516524],
              [-81.361728481879766, 35.323640690794832],
              [-81.361677416559758, 35.323905565061708],
              [-81.362006307800144, 35.323946689583281],
              [-81.36195524429229, 35.324211563930717],
              [-81.362284135810611, 35.324252687716928],
              [-81.362182009887192, 35.324782436580833],
              [-81.362510904927561, 35.324823559725537],
              [-81.362357715531857, 35.325618181389878],
              [-81.362028817356517, 35.325577057835282],
              [-81.361926686982372, 35.326106805472257],
              [-81.361597787049533, 35.326065680751171],
              [-81.361546720855458, 35.326330553919583],
              [-81.361217820184081, 35.326289427267305],
              [-81.361166751538903, 35.326554301187109],
              [-81.360837850154937, 35.326513173504857],
              [-81.360786780106139, 35.326778046351919],
              [-81.360457878035959, 35.326736918540959],
              [-81.360355735950236, 35.327266663836284],
              [-81.360026832122486, 35.327225534858641],
              [-81.359924685516802, 35.327755279763167],
              [-81.359595779905504, 35.32771414871771],
              [-81.359493629879367, 35.328243893209823],
              [-81.359164721410892, 35.328202761019121],
              [-81.359113644853906, 35.328467633076379],
              [-81.358784736798796, 35.328426500735453],
              [-81.35873365883802, 35.328691371720083],
              [-81.358404748970798, 35.328650238370606],
              [-81.358353669658484, 35.328915110084928],
              [-81.358024760152375, 35.328873974782752],
              [-81.357973678362711, 35.329138846347192],
              [-81.357644768144169, 35.329097710014977],
              [-81.357593684976834, 35.329362581407885],
              [-81.357264774045859, 35.329321444045654],
              [-81.357162605696772, 35.329851185531076],
              [-81.356833691908548, 35.329810047023635],
              [-81.356782606752915, 35.330074918017274],
              [-81.356453692252273, 35.330033778479724],
              [-81.356300429402424, 35.330828389082363],
              [-81.355971512099316, 35.33078724824145],
              [-81.355920423496627, 35.331052117921338],
              [-81.355818243119984, 35.331581858120408],
              [-81.355489323014183, 35.331540715976146],
              [-81.355438232368414, 35.331805585414706],
              [-81.355109310450501, 35.331764442261708],
              [-81.355007124970825, 35.332294180782398],
              [-81.354349279078576, 35.332211892129244],
              [-81.354298183173526, 35.332476760173428],
              [-81.353640336521963, 35.332394467673296],
              [-81.353589238220039, 35.332659336310414],
              [-81.353260314885048, 35.332618188122254],
              [-81.35320921520514, 35.332883056587768],
              [-81.352551366343562, 35.332800757279202],
              [-81.35250026424076, 35.333065625436525],
              [-81.352171338714086, 35.333024474316026],
              [-81.352120235207309, 35.333289341400558],
              [-81.351791310094214, 35.333248190129815],
              [-81.351740204109689, 35.333513057064053],
              [-81.351411278258112, 35.333471903862012],
              [-81.351360170921808, 35.333736771525793],
              [-81.351031243258163, 35.333695617315009],
              [-81.350980135617618, 35.333960483884681],
              [-81.350651207241739, 35.333919328643681],
              [-81.350600098249203, 35.33418419594296],
              [-81.350271169160933, 35.334143039671801],
              [-81.350220057664714, 35.334407905919562],
              [-81.349891128963989, 35.334366748597006],
              [-81.349840016089715, 35.334631614673036],
              [-81.349511085576935, 35.334590456341687],
              [-81.349459971324663, 35.33485532224605],
              [-81.349131040099508, 35.334814162884541],
              [-81.349079924495356, 35.335079029518532],
              [-81.34875099253189, 35.335037868225591],
              [-81.348699875549713, 35.335302734687886],
              [-81.348370943973848, 35.335261572343533],
              [-81.348217586716473, 35.336056168428001],
              [-81.347888651264185, 35.336015005702556],
              [-81.347735287875309, 35.33680960106188],
              [-81.348064226463137, 35.336850764197429],
              [-81.34704174519257, 35.342148045996638],
              [-81.346712784599944, 35.342106880148023],
              [-81.346508266795212, 35.343166331958784],
              [-81.346179301254494, 35.343125164691145],
              [-81.345974773914492, 35.344184614491304],
              [-81.345645804525361, 35.344143445783224],
              [-81.345390131223255, 35.345467755458493],
              [-81.345719105839351, 35.345508924850279],
              [-81.345616836278097, 35.346038648747403],
              [-81.346274791790819, 35.346120985375919],
              [-81.346325924647175, 35.345856123171423],
              [-81.347641834387147, 35.346020785179775],
              [-81.347590705686528, 35.346285647029831],
              [-81.348248665195342, 35.346367972935262],
              [-81.348197538278512, 35.346632835924773],
              [-81.348526520127848, 35.346673997663125],
              [-81.348475393897459, 35.346938859852877],
              [-81.348804377125205, 35.346980020834323],
              [-81.348753251633326, 35.347244884026772],
              [-81.349082236213491, 35.347286043350174],
              [-81.349031111434087, 35.34755090664401],
              [-81.34936009741871, 35.347592066111659],
              [-81.349308974425711, 35.34785692858442],
              [-81.349637961788801, 35.347898087295157],
              [-81.349586838408371, 35.348162949890686],
              [-81.349915827149914, 35.348204107844474],
              [-81.34986470558195, 35.348468970520216],
              [-81.350193695701932, 35.348510127717056],
              [-81.349989209182766, 35.349569578635112],
              [-81.3496602138068, 35.349528421814064],
              [-81.349455717777957, 35.350587871620888],
              [-81.349126718526861, 35.350546712458261],
              [-81.348973339571202, 35.351341298136809],
              [-81.349302340859012, 35.351382457730807],
              [-81.349200088272667, 35.351912181320017],
              [-81.348871084919764, 35.351871022353755],
              [-81.348717699842865, 35.352665606622502],
              [-81.348388692560192, 35.352624445472728],
              [-81.34833756214033, 35.352889307296209],
              [-81.348995579103985, 35.352971628074251],
              [-81.348944451516203, 35.35323648921316],
              [-81.349273461556152, 35.353277648848938],
              [-81.349222333581039, 35.353542510110493],
              [-81.353170494599496, 35.354036351321561],
              [-81.353119381039193, 35.354301214144961],
              [-81.353448397473059, 35.354342361534911],
              [-81.353397283525467, 35.354607224480986],
              [-81.354055320583498, 35.354689516831655],
              [-81.354384340161857, 35.354730661655729],
              [-81.354333229018408, 35.354995524976388],
              [-81.354662248901448, 35.355036669965962],
              [-81.354611139570821, 35.355301533366415],
              [-81.354940161906427, 35.355342676676344],
              [-81.354991270191334, 35.355077813139175],
              [-81.355320291814223, 35.355118955418803],
              [-81.355269183474888, 35.355383819113896],
              [-81.355927229810675, 35.355466101265094],
              [-81.355876123203871, 35.355730964296846],
              [-81.356534172962654, 35.355813243146429],
              [-81.356483069240483, 35.356078107295744],
              [-81.356812094564944, 35.356119245537968],
              [-81.356760991555646, 35.356384109788557],
              [-81.357419047494673, 35.356466383822095],
              [-81.357367946217977, 35.356731247409371],
              [-81.357696975732495, 35.356772383222058],
              [-81.357645875194848, 35.357037247811682],
              [-81.357974906061884, 35.35707838196609],
              [-81.357923806237139, 35.357343246656853],
              [-81.358252838508946, 35.357384380955295],
              [-81.358048440012112, 35.358443838108023],
              [-81.35837747787393, 35.358484971136541],
              [-81.35812197635336, 35.359809291132308],
              [-81.358451019803425, 35.359850424851139],
              [-81.358297718678514, 35.360645016279129],
              [-81.358626765572893, 35.360686148612736],
              [-81.358524565250391, 35.361215876327108],
              [-81.358853614595461, 35.361257008941188],
              [-81.358751415007077, 35.361786735885488],
              [-81.359080466776817, 35.361827867879015],
              [-81.3590293675162, 35.362092730983917],
              [-81.359358421764867, 35.36213386219876],
              [-81.359307323217294, 35.362398725404645],
              [-81.359636377744607, 35.362439855883679],
              [-81.359585279910092, 35.362704719190674],
              [-81.359914336916347, 35.36274584889096],
              [-81.359863239794905, 35.363010712298966],
              [-81.360521355771638, 35.363092968411571],
              [-81.360470260409159, 35.363357832057105],
              [-81.360799320505947, 35.36339895934907],
              [-81.360748225856568, 35.363663823095642],
              [-81.361077287332193, 35.363704949630417],
              [-81.36097510022698, 35.364234677268549],
              [-81.361304164101227, 35.36427580228132],
              [-81.36125307054526, 35.364540666194394],
              [-81.361582135824506, 35.364581791350979],
              [-81.361531042981696, 35.364846655365007],
              [-81.361860109613616, 35.364887778863213],
              [-81.36180901858414, 35.365152642956694],
              [-81.362796223640899, 35.365276009398841],
              [-81.362745135416745, 35.365540873866472],
              [-81.363403276960142, 35.365623113342707],
              [-81.363352189394973, 35.365887978069217],
              [-81.36466848065956, 35.366052446841309],
              [-81.364617398045993, 35.366317312056914],
              [-81.365275547767126, 35.366399541352621],
              [-81.365604623676987, 35.36644065464899],
              [-81.365553543842609, 35.366705519337309],
              [-81.366211697652503, 35.366787743542723],
              [-81.36616061960386, 35.367052609369644],
              [-81.366818777910822, 35.367134829349901],
              [-81.366767701621612, 35.367399695414221],
              [-81.367425863378074, 35.367481912992929],
              [-81.367374788822019, 35.367746778393446],
              [-81.368032954001663, 35.367828992669502],
              [-81.367981881205125, 35.368093858307439],
              [-81.370614564622485, 35.368422678021645],
              [-81.370563500962703, 35.368687544694268],
              [-81.370892589879347, 35.36872864269025],
              [-81.370841526959254, 35.368993510364803],
              [-81.371828796669732, 35.369116800342702],
              [-81.371777736529324, 35.369381667489826],
              [-81.374410480212006, 35.36971040189264],
              [-81.374359429208837, 35.369975270074079],
              [-81.374688524155445, 35.370016357773544],
              [-81.374637473892236, 35.370281226957026],
              [-81.375624766066821, 35.370404485057726],
              [-81.375675814317972, 35.370139616344581],
              [-81.376334008304624, 35.370221783676229],
              [-81.376385053004071, 35.369956913775063],
              [-81.376714150000197, 35.369997995952716],
              [-81.376663106346996, 35.370262865990277],
              [-81.377321303402965, 35.370345027035391],
              [-81.377270261483289, 35.370609896408915],
              [-81.377599361569466, 35.370650976177501],
              [-81.377548320363516, 35.370915845651822],
              [-81.3778774218286, 35.370956924662792],
              [-81.37782638133649, 35.371221794237755],
              [-81.37848458735661, 35.371303949850557],
              [-81.378433549724789, 35.371568819641084],
              [-81.378762654279925, 35.371609896242866],
              [-81.37871161623525, 35.371874765254596],
              [-81.379040722169208, 35.371915841098776],
              [-81.378989685964868, 35.372180711090706],
              [-81.379647901996918, 35.372262859447027],
              [-81.379596866452545, 35.372527729697723],
              [-81.379925975476652, 35.372568803132637],
              [-81.379823907657112, 35.373098542855118],
              [-81.380153019106416, 35.373139615668798],
              [-81.380101985731088, 35.373404485162354],
              [-81.380431098559228, 35.373445557218361],
              [-81.380329032930106, 35.373975297272118],
              [-81.380658148183471, 35.374016368706819],
              [-81.38040298128368, 35.375340717589374],
              [-81.380732104302083, 35.375381788768628],
              [-81.380681070305599, 35.375646658407064],
              [-81.380351947341083, 35.375605587069792],
              [-81.380147806071037, 35.376665065578052],
              [-81.380476933194871, 35.376706136559598],
              [-81.380425898636119, 35.376971005997468],
              [-81.380096769339147, 35.376929933999975],
              [-81.379994696030764, 35.377459672517297],
              [-81.380323827420654, 35.377500744787504],
              [-81.380221754848677, 35.378030482533177],
              [-81.379892621365755, 35.377989409990214],
              [-81.379688468071762, 35.379048885407393],
              [-81.379359330735554, 35.37900781142487],
              [-81.379308289990547, 35.379272680289375],
              [-81.379637428346967, 35.379313753507006],
              [-81.379586388289525, 35.37957862157085],
              [-81.381232091445327, 35.379783976710435],
              [-81.381181056314318, 35.380048846321337],
              [-81.383155918197204, 35.380295242522465],
              [-81.383104890086997, 35.380560111992786],
              [-81.383763182492416, 35.380642236871971],
              [-81.383814208509591, 35.380377367128901],
              [-81.385459939558231, 35.380582663008759],
              [-81.385510960010023, 35.380317792548233],
              [-81.386498399268888, 35.380440958941932],
              [-81.386549415148153, 35.380176088058363],
              [-81.387207707896636, 35.380258193335855],
              [-81.387258722450724, 35.379993322122161],
              [-81.389233601320115, 35.380239616600541],
              [-81.389131586665684, 35.380769361485449],
              [-81.389460737142869, 35.380810407994204],
              [-81.389409730350479, 35.381075280068544],
              [-81.389738882206558, 35.381116325819498],
              [-81.389687876128519, 35.381381197994266],
              [-81.390017029337073, 35.381422242086103],
              [-81.389966023973315, 35.381687114361284],
              [-81.390295178587337, 35.381728158596495],
              [-81.390244173937774, 35.381993030972076],
              [-81.390573329904399, 35.382034073548127],
              [-81.390522325969144, 35.382298946024186],
              [-81.390851483341251, 35.382339988743446],
              [-81.390800481220708, 35.382604861298191],
              [-81.392117118165871, 35.382769021976486],
              [-81.392066119873277, 35.383033894139203],
              [-81.392724442433092, 35.383115969385798],
              [-81.392673445928082, 35.383380842686222],
              [-81.393660936487407, 35.383503948786128],
              [-81.393711929852387, 35.383239075076986],
              [-81.395028585121324, 35.383403203165038],
              [-81.395079573967195, 35.383138328875091],
              [-81.396396230338752, 35.383302441210006],
              [-81.39644721356504, 35.383037566360983],
              [-81.397434706742118, 35.383160640803673],
              [-81.397485686496111, 35.382895765510192],
              [-81.397814849772516, 35.382936788414355],
              [-81.397865829247991, 35.38267191292708],
              [-81.39918248258904, 35.382835995035009],
              [-81.399511647579999, 35.382877013310249],
              [-81.399460674438672, 35.383141889456709],
              [-81.399789839681517, 35.383182906094476],
              [-81.399738867254683, 35.38344778234125],
              [-81.400068035003528, 35.383488799100427],
              [-81.400017063291301, 35.383753675447537],
              [-81.40034623131865, 35.383794691470392],
              [-81.400295260294428, 35.3840595670166],
              [-81.400624430801074, 35.38410058225972],
              [-81.400573460518117, 35.384365458807402],
              [-81.400902632376983, 35.384406472391213],
              [-81.400851662808662, 35.384671349039237],
              [-81.401180836073152, 35.384712362766102],
              [-81.401129867192864, 35.38497723861321],
              [-81.401788216785121, 35.385059262755561],
              [-81.401839183571738, 35.384794386636202],
              [-81.402168357805976, 35.384835396779273],
              [-81.40211739209272, 35.385100273935983],
              [-81.403763274632908, 35.385305313699213],
              [-81.403712313821657, 35.385570191500776],
              [-81.404370671921612, 35.385652201585778],
              [-81.404319713945739, 35.385917078700764],
              [-81.405636437190694, 35.386081087804932],
              [-81.405585483096857, 35.386345966329642],
              [-81.406573032412695, 35.386468963495048],
              [-81.406522081100761, 35.386733841491093],
              [-81.407180452559217, 35.38681583627433],
              [-81.407129503009017, 35.387080714506688],
              [-81.407787876762185, 35.387162705105709],
              [-81.407736928973776, 35.387427583574244],
              [-81.408395307249236, 35.387509570846511],
              [-81.408344361222646, 35.387774449551422],
              [-81.409002742919924, 35.387856433518728],
              [-81.40895179975567, 35.388121312437974],
              [-81.409610184874751, 35.388203293100304],
              [-81.409559242372012, 35.388468172277591],
              [-81.409888436476422, 35.388509161403384],
              [-81.409837495789048, 35.388774040658951],
              [-81.410495887087777, 35.388856016499787],
              [-81.410444948135634, 35.38912089509045],
              [-81.410774145329952, 35.389161881805478],
              [-81.41072320711956, 35.389426761397324],
              [-81.411052405692729, 35.389467747354132],
              [-81.410950530343399, 35.389997505800956],
              [-81.410621329702977, 35.389956520473177],
              [-81.410570389369482, 35.39022139907788],
              [-81.410241187987211, 35.390180411818449],
              [-81.410037421426793, 35.391239927992579],
              [-81.410366628070889, 35.391280914873178],
              [-81.410315687204744, 35.391545794177148],
              [-81.410644895227762, 35.39158678029947],
              [-81.410593953976246, 35.391851659725305],
              [-81.410923163405002, 35.391892645990524],
              [-81.410872223942363, 35.392157524593429],
              [-81.411201434723409, 35.392198509199112],
              [-81.411048616410795, 35.392993146122791],
              [-81.411377830691748, 35.393034131143565],
              [-81.411072192405882, 35.39462340210833],
              [-81.41074297076851, 35.394582417194144],
              [-81.410488256516373, 35.395906806966316],
              [-81.410817483389408, 35.395947792560811],
              [-81.410715597233434, 35.396477548313129],
              [-81.411044826559532, 35.396518534186455],
              [-81.410891998513705, 35.397313167490701],
              [-81.411221231286618, 35.397354151976707],
              [-81.411068403367466, 35.39814878446267],
              [-81.411397639640839, 35.398189769363519],
              [-81.411295754428807, 35.398719524452787],
              [-81.411624994202413, 35.398760507808277],
              [-81.41157405159538, 35.399025385445611],
              [-81.411903292775065, 35.399066368943927],
              [-81.411801409733116, 35.399596123459141],
              [-81.412130653339219, 35.399637106335042],
              [-81.411672174278735, 35.402021000602566],
              [-81.411342921273828, 35.401980017403389],
              [-81.411241030900428, 35.402509770220711],
              [-81.411570286026816, 35.402550754593143],
              [-81.411519341939695, 35.402815631072563],
              [-81.411848598418615, 35.402856613785396],
              [-81.411797655046882, 35.403121490364533],
              [-81.41212691290518, 35.403162472318883],
              [-81.41207597024875, 35.403427348997866],
              [-81.412734490183595, 35.403509311374499],
              [-81.412683549289753, 35.403774188289205],
              [-81.413012810238968, 35.403815167832164],
              [-81.41306375118603, 35.403550290759519],
              [-81.41471005900388, 35.403755175212154],
              [-81.414659124394362, 35.404020052943018],
              [-81.414988388436214, 35.404061027097029],
              [-81.415039321998421, 35.403796149230175],
              [-81.415368585324856, 35.403837122353572],
              [-81.415419517507729, 35.403572244314418],
              [-81.416407305236007, 35.403695157931743],
              [-81.416356377296097, 35.403960036356985],
              [-81.416685640849707, 35.40400100592435],
              [-81.416736568843007, 35.403736127341176],
              [-81.418382890729092, 35.403940960091305],
              [-81.41833196907352, 35.404205839332498],
              [-81.418990502670439, 35.404287765893464],
              [-81.418939582777796, 35.404552645370337],
              [-81.419268851134902, 35.404593607895642],
              [-81.419217931957903, 35.404858487472211],
              [-81.419547201667498, 35.404899448337709],
              [-81.419496283206044, 35.405164328013996],
              [-81.419825554321804, 35.405205289022057],
              [-81.419774636575823, 35.40547016879809],
              [-81.420103910144817, 35.405511128124459],
              [-81.420052993114496, 35.405776008000167],
              [-81.420382266962136, 35.405816966589867],
              [-81.420280434000688, 35.406346726504246],
              [-81.420609711375874, 35.40638768444942],
              [-81.420558795444862, 35.406652564488063],
              [-81.421217352211997, 35.406734478889838],
              [-81.421166439117883, 35.40699935824091],
              [-81.421824999307589, 35.407081269336253],
              [-81.421875911407298, 35.406816389691308],
              [-81.422863751575107, 35.40693924875562],
              [-81.422965567366447, 35.406409487661833],
              [-81.423294846348938, 35.40645043831455],
              [-81.423345752135617, 35.406185557137704],
              [-81.423675030429294, 35.406226507661067],
              [-81.423725934836639, 35.405961626311914],
              [-81.425372323735672, 35.40616636127001],
              [-81.425423222600585, 35.405901480106202],
              [-81.427069614529415, 35.406106191119278],
              [-81.427120507797994, 35.405841308338459],
              [-81.428108344013836, 35.405964123799329],
              [-81.428159233808088, 35.405699240574528],
              [-81.428817790481901, 35.405781112811113],
              [-81.428868676748692, 35.405516229300304],
              [-81.429197955085996, 35.40555716392975],
              [-81.429147069866644, 35.405822047576535],
              [-81.43013490891029, 35.405944846527376],
              [-81.430084026475015, 35.406209729644338],
              [-81.430742589224067, 35.406291591417748],
              [-81.430691708579104, 35.406556475671351],
              [-81.43135027582359, 35.406638333214929],
              [-81.431299396942194, 35.406903217703899],
              [-81.43162868209599, 35.406944144819072],
              [-81.431577805031182, 35.407209029385591],
              [-81.431907091591171, 35.407249956643213],
              [-81.431856214114703, 35.407514840430139],
              [-81.432185502053912, 35.407555766929057],
              [-81.432134625293315, 35.407820650815538],
              [-81.432463915712518, 35.407861576533541],
              [-81.432413039667978, 35.408126460519561],
              [-81.432742330365699, 35.408167385500974],
              [-81.432284442697707, 35.410551341820138],
              [-81.43261374422967, 35.410592266206216],
              [-81.432257597451709, 35.412446450877901],
              [-81.432586906676235, 35.412487376221428],
              [-81.432434271109216, 35.413282025614023],
              [-81.432763583808637, 35.413322950470395],
              [-81.432509189050393, 35.41464736548911],
              [-81.432179870011325, 35.414606439975607],
              [-81.431925460594172, 35.415930852524568],
              [-81.432254784845284, 35.415971777816132],
              [-81.432203903014511, 35.416236660352311],
              [-81.432533228672142, 35.416277585786226],
              [-81.432431466084296, 35.41680735011964],
              [-81.432760794142297, 35.416848274029277],
              [-81.432709913412197, 35.417113156727645],
              [-81.433039243950603, 35.417154079856346],
              [-81.432886602886541, 35.417948727238702],
              [-81.43321593690024, 35.417989649880127],
              [-81.433114176619156, 35.418519414590364],
              [-81.43344351306034, 35.418560336608678],
              [-81.433341753521248, 35.419090100543251],
              [-81.433671092416915, 35.419131022839636],
              [-81.433416693003906, 35.420455432715357],
              [-81.433087348895512, 35.420414510641031],
              [-81.432832935922917, 35.421738918021909],
              [-81.43316228527101, 35.421779840775379],
              [-81.433111402734724, 35.422044722457848],
              [-81.433440754563449, 35.42208564443029],
              [-81.433389872716432, 35.42235052531079],
              [-81.433719225924875, 35.422391446524237],
              [-81.433668344821015, 35.422656328405047],
              [-81.433997698308247, 35.42269724888164],
              [-81.433895937174498, 35.423227011903947],
              [-81.434225294190398, 35.423267931735246],
              [-81.434174415261452, 35.423532812854496],
              [-81.434503773657099, 35.423573731926773],
              [-81.434045847919265, 35.425957662673106],
              [-81.433716480091093, 35.425916742378512],
              [-81.433665596743751, 35.426181623153134],
              [-81.433336227098266, 35.426140701849967],
              [-81.433285342371093, 35.426405582452155],
              [-81.432955972009253, 35.42636466011826],
              [-81.432905085875319, 35.426629539646733],
              [-81.432575714797082, 35.426588616282245],
              [-81.432524827310246, 35.426853496539294],
              [-81.432195455515696, 35.426812572144108],
              [-81.432093676322395, 35.427342331397362],
              [-81.432423050213075, 35.427383256064203],
              [-81.431965037362033, 35.429767170744356],
              [-81.431635654038075, 35.429726244855118],
              [-81.431533867517913, 35.430256002398444],
              [-81.431204481328379, 35.430215075364707],
              [-81.431051795364255, 35.431009711007277],
              [-81.430722407436065, 35.430968781747872],
              [-81.430671510035737, 35.431233660093305],
              [-81.430342120317135, 35.431192730726437],
              [-81.429884020339472, 35.433576629328108],
              [-81.429554620417605, 35.43353569786585],
              [-81.429503717575017, 35.433800574669874],
              [-81.428844918832652, 35.433718708744799],
              [-81.428794013561742, 35.433983585240249],
              [-81.428464612539003, 35.4339426508215],
              [-81.428413705887905, 35.434207527144309],
              [-81.427425503842045, 35.434084717165582],
              [-81.42737459371412, 35.434349593043855],
              [-81.42671579153739, 35.434267714685419],
              [-81.4266648789811, 35.43453259025506],
              [-81.426006076062635, 35.43445070894618],
              [-81.425955159976752, 35.434715584229281],
              [-81.425625758490952, 35.434674641184415],
              [-81.42552392322655, 35.43520439136806],
              [-81.425194520003032, 35.435163448057672],
              [-81.424583466711681, 35.438341939941843],
              [-81.424912883615917, 35.438382884860701],
              [-81.424658268513809, 35.439707253872399],
              [-81.424987692093083, 35.439748198553666],
              [-81.424682149463962, 35.44133743897406],
              [-81.425011579665735, 35.441378383575532],
              [-81.424909730810825, 35.441908129771555],
              [-81.425239163468433, 35.441949074650921],
              [-81.425086392479386, 35.442743693590927],
              [-81.425415828587532, 35.442784637081708],
              [-81.425364905326717, 35.443049510724215],
              [-81.42569434281539, 35.443090453455817],
              [-81.425643420244157, 35.443355326295908],
              [-81.425972859140302, 35.443396269169433],
              [-81.425921937285381, 35.443661142108304],
              [-81.42625137863638, 35.44370208329952],
              [-81.426200457497998, 35.443966956337121],
              [-81.426529899128326, 35.444007896791163],
              [-81.426478978706285, 35.44427276992753],
              [-81.42680842284534, 35.444313710501419],
              [-81.426757503139839, 35.444578583736515],
              [-81.427086948632564, 35.444619522650108],
              [-81.427036029643446, 35.444884395983934],
              [-81.427365476516741, 35.444925334138325],
              [-81.427263639639506, 35.445455080966141],
              [-81.427593088941947, 35.445496018497153],
              [-81.427542171053858, 35.445760891991235],
              [-81.427871621736784, 35.445801828763003],
              [-81.427820704538277, 35.446066701454541],
              [-81.428150156601916, 35.446107637467051],
              [-81.428099240146764, 35.446372511158515],
              [-81.428428694692371, 35.446413446389755],
              [-81.42837777895376, 35.446678320179863],
              [-81.428707233778638, 35.44671925467388],
              [-81.428656318729736, 35.446984127661537],
              [-81.428985776036399, 35.447025061374219],
              [-81.428934861731022, 35.447289935361596],
              [-81.429264319317156, 35.447330868337083],
              [-81.42921340680256, 35.447595741499953],
              [-81.429542865769349, 35.447636673716154],
              [-81.429441040767117, 35.448166421125151],
              [-81.42977050326445, 35.44820735269581],
              [-81.429617765852768, 35.449001973498298],
              [-81.429288301310692, 35.448961041498137],
              [-81.429237386751367, 35.44922591459563],
              [-81.429566853443291, 35.449266846709655],
              [-81.429414112020183, 35.450061466164293],
              [-81.429743582190156, 35.450102397790545],
              [-81.429590840924732, 35.450897017317601],
              [-81.42992031457284, 35.450937948456151],
              [-81.429818487866896, 35.451467694885501],
              [-81.430147965018833, 35.451508624477306],
              [-81.429995225038098, 35.452303243359168],
              [-81.430324705695256, 35.452344173364381],
              [-81.430273792394942, 35.452609046694015],
              [-81.430603274405939, 35.452649975038717],
              [-81.430399624227718, 35.453709466680934],
              [-81.430729110792825, 35.453750395574815],
              [-81.430525458368749, 35.454809886284131],
              [-81.43085495053549, 35.454850813902809],
              [-81.43070221113237, 35.455645431268913],
              [-81.431031705703276, 35.455686359323025],
              [-81.430980793687922, 35.455951231824677],
              [-81.431639786997863, 35.456033084595582],
              [-81.431588876748179, 35.456297957331529],
              [-81.432247873457129, 35.456379805891949],
              [-81.432196963871661, 35.456644678884238],
              [-81.432526464350829, 35.456685602847479],
              [-81.432475556556597, 35.456950475014843],
              [-81.433134559480223, 35.457032319648242],
              [-81.433083653478718, 35.457297192950918],
              [-81.433413157024759, 35.45733811359888],
              [-81.433362251740149, 35.457602987000044],
              [-81.433691756667017, 35.457643906888514],
              [-81.433640852099288, 35.457908780388031],
              [-81.43397035840708, 35.457949699517037],
              [-81.433868550346546, 35.458479445774223],
              [-81.434198059084181, 35.458520364279494],
              [-81.433994443174697, 35.459579856888034],
              [-81.434323956440153, 35.45962077504111],
              [-81.434222148564231, 35.460150520491162],
              [-81.434551665360985, 35.460191437998347],
              [-81.43444985828198, 35.460721184471311],
              [-81.434779376407462, 35.460762101376844],
              [-81.434677570071273, 35.461291847070406],
              [-81.435007091728139, 35.461332763330056],
              [-81.434905286161992, 35.461862509145192],
              [-81.435234810248915, 35.461903424781028],
              [-81.435183908016811, 35.46216829776818],
              [-81.435513433484786, 35.462209212644467],
              [-81.435360729018115, 35.463003830864864],
              [-81.436678847873168, 35.463167482122486],
              [-81.436627949558329, 35.463432355638375],
              [-81.436957481984024, 35.463473266558893],
              [-81.436906584386364, 35.463738140173007],
              [-81.437565651203286, 35.463819958720464],
              [-81.437514755371836, 35.464084832568439],
              [-81.438173825614967, 35.464166647806081],
              [-81.438122932651154, 35.464431521865855],
              [-81.438452469856998, 35.464472427815686],
              [-81.438401576508937, 35.464737301995854],
              [-81.438731115095877, 35.464778207186086],
              [-81.438680223566394, 35.465043081442289],
              [-81.439009763534429, 35.465083985872859],
              [-81.438958871620613, 35.465348860249414],
              [-81.439947496690991, 35.465471569477181],
              [-81.439896608694198, 35.46573644420117],
              [-81.440226152088329, 35.465777345321669],
              [-81.440175264781686, 35.466042219242588],
              [-81.440834354691134, 35.466124019970131],
              [-81.440783469178101, 35.466388895026],
              [-81.441113015666346, 35.466429793731855],
              [-81.441011245688628, 35.466959542200101],
              [-81.441340795735798, 35.46700044116087],
              [-81.441289911271127, 35.467265314573119],
              [-81.441619461597909, 35.46730621279643],
              [-81.441365039650293, 35.468630583577259],
              [-81.441035484104447, 35.468589685576688],
              [-81.440882823767012, 35.469384305925317],
              [-81.440553264303801, 35.469343406644491],
              [-81.440502375802424, 35.469608279582744],
              [-81.440172815621921, 35.46956737927075],
              [-81.440071034450369, 35.470097125686266],
              [-81.440400596729418, 35.470138026269588],
              [-81.440349707259259, 35.470402899995989],
              [-81.440679270919588, 35.470443799819648],
              [-81.440628382139735, 35.470708672742951],
              [-81.440957947208403, 35.470749572708009],
              [-81.440856170751346, 35.471279318713101],
              [-81.441185738223396, 35.471320217152851],
              [-81.441083962564221, 35.471849964179796],
              [-81.441413533568564, 35.471890861973321],
              [-81.441311757551532, 35.472420608241919],
              [-81.441641330986513, 35.472461505411417],
              [-81.441539556841818, 35.472991251778474],
              [-81.441869132707581, 35.473032148323767],
              [-81.441818246186656, 35.47329702158639],
              [-81.442147822332046, 35.473337917394133],
              [-81.442096936528543, 35.473602790754711],
              [-81.442756094117144, 35.473684579910646],
              [-81.442705210080561, 35.473949453504908],
              [-81.4430347915238, 35.473990346853128],
              [-81.442983908204582, 35.474255220545352],
              [-81.443643071982436, 35.474337004871096],
              [-81.443592190430195, 35.474601878796847],
              [-81.443921773866336, 35.474642769752109],
              [-81.443870894106098, 35.474907642852529],
              [-81.444530064073206, 35.474989422347875],
              [-81.444479186106932, 35.475254296582968],
              [-81.444808772637813, 35.475295185123038],
              [-81.444757895362031, 35.475560058554805],
              [-81.445087483274179, 35.475600946335106],
              [-81.445036606743088, 35.475865820765932],
              [-81.445366196009545, 35.475906706885127],
              [-81.445315320195931, 35.476171581413908],
              [-81.445644910870882, 35.476212467674372],
              [-81.445543159191047, 35.476742215109851],
              [-81.445872753398532, 35.476783100723857],
              [-81.445669250253857, 35.47784259568391],
              [-81.4453396518755, 35.47780171042853],
              [-81.445288774760854, 35.478066583927358],
              [-81.444959175637834, 35.478025696739579],
              [-81.444603013631991, 35.479879807531837],
              [-81.444932621203577, 35.479920695646804],
              [-81.444576450224886, 35.481774803731952],
              [-81.444906065475564, 35.481815691900877],
              [-81.444804300797585, 35.482345436238731],
              [-81.44513391847947, 35.482386323783231],
              [-81.445032155674625, 35.482916068218579],
              [-81.445361775787774, 35.482956955138839],
              [-81.445310894386125, 35.483221827446343],
              [-81.445640516955478, 35.483262712683263],
              [-81.445589636271635, 35.483527585088488],
              [-81.445919259147914, 35.483568470488713],
              [-81.445868380283528, 35.483833342969518],
              [-81.446198004514386, 35.483874226708586],
              [-81.446096246787235, 35.484403971849773],
              [-81.446425874578225, 35.484444855843485],
              [-81.446324117595509, 35.484974600202932],
              [-81.44665374671618, 35.485015483594445],
              [-81.446551990505057, 35.485545228073427],
              [-81.446881623158902, 35.485586110818318],
              [-81.446779867719329, 35.4861158554166],
              [-81.447109502777536, 35.486156736635941],
              [-81.446956870815058, 35.486951353193845],
              [-81.447286509381541, 35.486992234825401],
              [-81.447184754794378, 35.487521978685237],
              [-81.447514395792368, 35.487562859692353],
              [-81.447412641976911, 35.488092603671419],
              [-81.447742286481088, 35.48813348313076],
              [-81.4476914106761, 35.48839835518779],
              [-81.448021055487374, 35.488439234810372],
              [-81.447919303879416, 35.488968979103703],
              [-81.448248952196934, 35.489009857178431],
              [-81.448147201360825, 35.489539601590835],
              [-81.448476852109948, 35.489580479041045],
              [-81.44832422706655, 35.490375095770027],
              [-81.44865388129719, 35.490415972731377],
              [-81.448603007035942, 35.490680845026397],
              [-81.448932662648389, 35.490721721227544],
              [-81.448830915202606, 35.491251465073553],
              [-81.449160573246772, 35.491292340650148],
              [-81.449109700089451, 35.491557213102205],
              [-81.449769019273504, 35.491638961839392],
              [-81.449718147884028, 35.491903834524507],
              [-81.450047810125696, 35.491944707662853],
              [-81.44999693942701, 35.492209579544216],
              [-81.450326601948646, 35.49225045194472],
              [-81.450275731995248, 35.492515324824737],
              [-81.450605397000629, 35.492556196442735],
              [-81.450554527737935, 35.492821068519142],
              [-81.450884193023299, 35.492861939399297],
              [-81.450833324478637, 35.493126811573092],
              [-81.451162992247745, 35.493167681670741],
              [-81.451112124448315, 35.493432554843196],
              [-81.451441793599301, 35.493473424180642],
              [-81.451390926490788, 35.493738296549402],
              [-81.45172059702368, 35.493779165126661],
              [-81.451669730633128, 35.49404403759285],
              [-81.451999402547898, 35.494084905409906],
              [-81.451948536875435, 35.494349777973447],
              [-81.452278211273992, 35.494390645008089],
              [-81.452227346319617, 35.494655517669123],
              [-81.452557020998185, 35.494696383965739],
              [-81.452506156734771, 35.494961255823029],
              [-81.452835832795245, 35.495002121359406],
              [-81.452784970378971, 35.495266994192896],
              [-81.453114647821366, 35.495307858969078],
              [-81.453063786123238, 35.495572731899998],
              [-81.453393464947538, 35.495613595915913],
              [-81.45334260283839, 35.495878468065378],
              [-81.453672284146649, 35.495919331298751],
              [-81.453570562189171, 35.496449077534265],
              [-81.453900244800153, 35.496489939264023],
              [-81.453849384924609, 35.496754812448863],
              [-81.454179070046706, 35.496795674297225],
              [-81.454077350271035, 35.497325419944637],
              [-81.454407036723239, 35.497366281190544],
              [-81.454305318794894, 35.497896026032727],
              [-81.454635008781096, 35.497936886631685],
              [-81.454533290550643, 35.498466632515509],
              [-81.454862982941691, 35.498507491588448],
              [-81.454812123841421, 35.498772365070309],
              [-81.454761265484038, 35.499037237590471],
              [-81.455090960307132, 35.499078096038687],
              [-81.454989243622194, 35.499607842158838],
              [-81.455318941979627, 35.49964869995987],
              [-81.455217226040105, 35.500178445296932],
              [-81.455546926856897, 35.500219303374301],
              [-81.455445211690289, 35.500749048829448],
              [-81.455774914912055, 35.500789905380856],
              [-81.455622343374515, 35.501584523671873],
              [-81.45595205007875, 35.501625379733952],
              [-81.455901192551309, 35.501890252571449],
              [-81.456230900637649, 35.501931107873141],
              [-81.456129188864281, 35.502460852758588],
              [-81.456458899410222, 35.502501708336538],
              [-81.456357188409896, 35.503031453339709],
              [-81.456686901360925, 35.503072307391648],
              [-81.456585190031475, 35.50360205253493],
              [-81.4569149065169, 35.50364290593965],
              [-81.456660629276271, 35.504967268320485],
              [-81.45699035024299, 35.50500812152908],
              [-81.45683778369532, 35.505802737809077],
              [-81.457167509246915, 35.505843590505918],
              [-81.457065797554222, 35.506373334794027],
              [-81.457395525538359, 35.506414186865975],
              [-81.457192104548639, 35.507473674577717],
              [-81.457521837093381, 35.507514527196818],
              [-81.457470982046075, 35.507779398488367],
              [-81.457800715945694, 35.507820249445828],
              [-81.45774986164426, 35.50808512173559],
              [-81.458079596926254, 35.508125971932508],
              [-81.458130450204706, 35.507861100408441],
              [-81.458789920765568, 35.507942797821165],
              [-81.458840771584065, 35.507677925086519],
              [-81.460159712486771, 35.507841308618673],
              [-81.46010886586987, 35.508106181895158],
              [-81.460438603526626, 35.508147025662574],
              [-81.460387757628297, 35.508411899036119],
              [-81.460717496667286, 35.508452742043005],
              [-81.460666651460215, 35.508717614612472],
              [-81.461326132634554, 35.508799298209219],
              [-81.461275289196507, 35.509064171011161],
              [-81.462264516597969, 35.509186690092783],
              [-81.462213675979456, 35.509451563262594],
              [-81.462543420160671, 35.509492401299937],
              [-81.462492581362994, 35.509757274544441],
              [-81.462822326926485, 35.509798111821198],
              [-81.46277148884738, 35.510062985162683],
              [-81.463101235793133, 35.510103821678904],
              [-81.463050398432713, 35.510368695117286],
              [-81.463380146760755, 35.510409530873019],
              [-81.463329310119022, 35.510674404408363],
              [-81.463659059829354, 35.510715239403496],
              [-81.463201522346125, 35.513099099853299],
              [-81.463531282945155, 35.513139935148772],
              [-81.463480445086475, 35.513404808570222],
              [-81.46381020704051, 35.513445642203934],
              [-81.463759368798449, 35.513710515744656],
              [-81.464418895830434, 35.513792181496008],
              [-81.46436806045989, 35.514057055246568],
              [-81.464697825510484, 35.514097886462984],
              [-81.464646990858824, 35.514362760310519],
              [-81.464976757291822, 35.514403590766257],
              [-81.464925923358877, 35.514668464710653],
              [-81.465255692276429, 35.514709294383387],
              [-81.465204859062325, 35.514974168424651],
              [-81.465534628260102, 35.515014997359089],
              [-81.46548379573747, 35.515279870596075],
              [-81.465813566345034, 35.515320699670994],
              [-81.465711903535322, 35.515850447178835],
              [-81.466041676548471, 35.515891274727245],
              [-81.465940014485582, 35.516421021450498],
              [-81.466269789959284, 35.516461849274847],
              [-81.466218958929701, 35.516726722725174],
              [-81.466548736860659, 35.516767548865275],
              [-81.46649790655006, 35.517032422412427],
              [-81.466827685863478, 35.517073247791849],
              [-81.466776856271707, 35.517338121435813],
              [-81.467106636994984, 35.517378946955603],
              [-81.467004980019709, 35.517908694376061],
              [-81.46733476314877, 35.517949518369463],
              [-81.467233106947944, 35.518479265906237],
              [-81.467562892510202, 35.518520089274219],
              [-81.46746123705627, 35.519049836026234],
              [-81.467791025051781, 35.519090658768846],
              [-81.46774019734049, 35.519355532684045],
              [-81.467410408294256, 35.519314709806054],
              [-81.467308749747559, 35.519844456348608],
              [-81.466978958931577, 35.519803632303741],
              [-81.466928128123541, 35.520068505832263],
              [-81.466268544255797, 35.519986853904527],
              [-81.466217711014352, 35.520251727123657],
              [-81.465887919064855, 35.520210899218476],
              [-81.465786249457679, 35.520740644368736],
              [-81.46545645466361, 35.520699816220173],
              [-81.465100592059528, 35.522553919849621],
              [-81.465049752268527, 35.522818791532352],
              [-81.465379556598677, 35.522859621642795],
              [-81.465176201599121, 35.523919107559728],
              [-81.465506009334717, 35.523959936436832],
              [-81.465455171088507, 35.524224808174331],
              [-81.465784980206834, 35.524265636290593],
              [-81.465734142679636, 35.52453050812472],
              [-81.466393765115953, 35.524612161917133],
              [-81.46634292935866, 35.524877033983266],
              [-81.467002556326406, 35.524958684439241],
              [-81.466951722339076, 35.52522355673738],
              [-81.467281537371647, 35.525264380756504],
              [-81.467230704075888, 35.525529252250031],
              [-81.468549970625574, 35.525692539928137],
              [-81.468600799717677, 35.525427667893076],
              [-81.46893061640931, 35.525468487431091],
              [-81.468879788368127, 35.525733359601496],
              [-81.469539424848591, 35.525814996259683],
              [-81.469488598604883, 35.526079869563084],
              [-81.47014823958925, 35.526161501983452],
              [-81.47009741511566, 35.526426375518859],
              [-81.470427236591576, 35.526467190080695],
              [-81.470376413939519, 35.526732063690226],
              [-81.470706236825592, 35.526772878392386],
              [-81.470655414865277, 35.527037751197255],
              [-81.47098523913408, 35.527078565138439],
              [-81.470934417893005, 35.52734343804002],
              [-81.471594070602734, 35.527425062580754],
              [-81.471543250057067, 35.5276899366376],
              [-81.47220290619569, 35.527771557864085],
              [-81.472152088495079, 35.528036431229275],
              [-81.473471410059602, 35.528199663445982],
              [-81.473522223556174, 35.527934789539493],
              [-81.473852052912591, 35.527975595685128],
              [-81.473902864998948, 35.527710720703404],
              [-81.474562523679609, 35.527792329111726],
              [-81.474613333359713, 35.527527454721735],
              [-81.475272991265172, 35.527609059274361],
              [-81.475323797409246, 35.527344184597546],
              [-81.475983454511734, 35.527425784393429],
              [-81.475932650497299, 35.52769066024203],
              [-81.47659231102844, 35.527772256723459],
              [-81.476541508756924, 35.528037131902707],
              [-81.477201173846566, 35.528118725948289],
              [-81.477150372243145, 35.52838360138179],
              [-81.477480206322667, 35.528424396744889],
              [-81.477429406540935, 35.528689272252365],
              [-81.477759242030743, 35.528730067755603],
              [-81.477657643574616, 35.529259818924672],
              [-81.478317319698291, 35.529341405981079],
              [-81.478266522075018, 35.52960628177793],
              [-81.478926201627417, 35.529687865519712],
              [-81.47887540577463, 35.529952741548264],
              [-81.479535089857947, 35.530034321952947],
              [-81.479484295775862, 35.530299198213072],
              [-81.479814138814902, 35.530339987217516],
              [-81.479763346554634, 35.530604863551552],
              [-81.480093190976376, 35.530645651794849],
              [-81.479991606433416, 35.531175403738438],
              [-81.480321453289037, 35.531216191355924],
              [-81.480169076603786, 35.5320108198474],
              [-81.48049892804687, 35.532051606951782],
              [-81.480397344392642, 35.532581359047661],
              [-81.480194172002697, 35.533640862795146],
              [-81.480524030056984, 35.533681648913564],
              [-81.480371650698189, 35.53447627626521],
              [-81.480701512265313, 35.534517062794073],
              [-81.480599926819579, 35.535046813829474],
              [-81.480929790820809, 35.535087599732407],
              [-81.480726620155181, 35.53614710187675],
              [-81.481056488665502, 35.536187886523123],
              [-81.480243764003234, 35.540425883029009],
              [-81.479913877569174, 35.540385096241359],
              [-81.479761477329987, 35.541179717920627],
              [-81.480091368020851, 35.541220505091367],
              [-81.47973576575869, 35.543074620967971],
              [-81.48006566414125, 35.543115408188896],
              [-81.479760854413541, 35.544704647746308],
              [-81.480090760566682, 35.544745435760738],
              [-81.479938354889384, 35.545540054053674],
              [-81.480268264528775, 35.545580841577276],
              [-81.480014252587324, 35.546905205314076],
              [-81.479861841000741, 35.547699823094881],
              [-81.4801917593567, 35.547740609902654],
              [-81.480090152784385, 35.548270354247983],
              [-81.480420074705521, 35.548311141308332],
              [-81.480369270884481, 35.548576014030431],
              [-81.480699194161289, 35.548616799428345],
              [-81.480394376009016, 35.550206032932522],
              [-81.48072430592741, 35.550246818245164],
              [-81.480622699693654, 35.550776562695354],
              [-81.480952632046993, 35.550817347381759],
              [-81.48085102548697, 35.551347091967841],
              [-81.481180961378158, 35.55138787600562],
              [-81.481028552714974, 35.55218249163272],
              [-81.481358492120535, 35.552223276080618],
              [-81.481256887525007, 35.552753019890794],
              [-81.481586828235393, 35.552793802834003],
              [-81.481536026491781, 35.553058674815276],
              [-81.482195913244269, 35.553140239138287],
              [-81.482145113244812, 35.553405110449589],
              [-81.48247505708143, 35.553445891874439],
              [-81.482373458218319, 35.553975635550543],
              [-81.482043512305694, 35.55393485475642],
              [-81.481891107278798, 35.554729470042901],
              [-81.481561157412358, 35.554688687067973],
              [-81.481510354398196, 35.554953558909098],
              [-81.481840305288841, 35.554994341118096],
              [-81.481789502994815, 35.555259213055059],
              [-81.482119455268901, 35.555299994502604],
              [-81.481916246899402, 35.55635947969396],
              [-81.482246203740132, 35.556400261686839],
              [-81.482144599654774, 35.556930003865929],
              [-81.482474560005969, 35.556970784308838],
              [-81.482423758517527, 35.5572356554745],
              [-81.48506345950706, 35.557561868765802],
              [-81.485012667204415, 35.557826740950901],
              [-81.485342632644958, 35.557867513594189],
              [-81.48539342389563, 35.557602641273938],
              [-81.486383319051754, 35.557724953418408],
              [-81.486332530956631, 35.557989826144109],
              [-81.487322432225724, 35.55811212972278],
              [-81.487170074314804, 35.558906748902217],
              [-81.487500045232693, 35.558947515674227],
              [-81.48744926001865, 35.559212388782782],
              [-81.487779233422899, 35.559253154770644],
              [-81.487728447798702, 35.559518027096374],
              [-81.488388396603369, 35.55959955667484],
              [-81.488718372026952, 35.559640319206672],
              [-81.488769153392539, 35.559375446498144],
              [-81.48909912699304, 35.559416208020679],
              [-81.489149906947503, 35.559151334236475],
              [-81.489479879855338, 35.559192095628354],
              [-81.489530658426148, 35.558927221669585],
              [-81.489860631716397, 35.558967982007019],
              [-81.489911407801003, 35.558703107896271],
              [-81.490571352141927, 35.558784624732255],
              [-81.490622126893811, 35.558519750289335],
              [-81.490952097944557, 35.558560506787906],
              [-81.491002870210167, 35.558295632193008],
              [-81.491332841670896, 35.558336388538386],
              [-81.491383612553008, 35.558071513768944],
              [-81.491713582163001, 35.558112268203921],
              [-81.491764352764378, 35.557847393237367],
              [-81.492094321653909, 35.557888146640551],
              [-81.492145089769068, 35.557623271522097],
              [-81.492805026466698, 35.557704776269432],
              [-81.492855792146472, 35.557439900841402],
              [-81.493185761029579, 35.557480651261713],
              [-81.493236525325926, 35.557215775659266],
              [-81.493896459777261, 35.557297273584901],
              [-81.493947221638237, 35.557032397672828],
              [-81.497906845828169, 35.557521310040876],
              [-81.497957593603957, 35.557256431590169],
              [-81.498617533128268, 35.557337903868238],
              [-81.498668278496481, 35.557073026009206],
              [-81.50031812892189, 35.557276689413044],
              [-81.500368868698942, 35.557011810839469],
              [-81.500698838483302, 35.55705253997872],
              [-81.500749576877027, 35.556787661230771],
              [-81.501079545968665, 35.55682839023941],
              [-81.501130282951195, 35.556563510416026],
              [-81.5014602513222, 35.556604238392964],
              [-81.501510985818825, 35.556339358417866],
              [-81.502500890838718, 35.556461535639606],
              [-81.502551621875952, 35.556196656121251],
              [-81.502881589440392, 35.556237379340239],
              [-81.50354152669388, 35.556318823966592],
              [-81.503490797737612, 35.556583704813796],
              [-81.504150738420066, 35.55666514612323],
              [-81.504100012339322, 35.55693002717836],
              [-81.505419900756863, 35.55709289869835],
              [-81.505369179655474, 35.557357780231335],
              [-81.505699153357284, 35.557398495784909],
              [-81.505648431874022, 35.557663377436256],
              [-81.507298313948013, 35.557866943261061],
              [-81.507349030199023, 35.557602061835972],
              [-81.508008983011393, 35.557683481263027],
              [-81.508059695724484, 35.557418599551433],
              [-81.508389672126711, 35.557459307773449],
              [-81.508440383428706, 35.557194424986434],
              [-81.508770359110187, 35.557235132176871],
              [-81.508821069056864, 35.556970250116684],
              [-81.509151042914652, 35.557010956298143],
              [-81.509252459682614, 35.556481190888846],
              [-81.509582432870332, 35.55652189588092],
              [-81.509683846181701, 35.555992129143213],
              [-81.510013816493824, 35.556032832991448],
              [-81.510115226404608, 35.55550306672783],
              [-81.51044519494377, 35.555543769409461],
              [-81.510546600295498, 35.555014001840455],
              [-81.5108765670618, 35.555054703355502],
              [-81.51092726874036, 35.554789819365688],
              [-81.511257234785887, 35.554830519849119],
              [-81.511307935081362, 35.55456563568508],
              [-81.511637900405916, 35.554606335136882],
              [-81.511967866061624, 35.554647033692028],
              [-81.512018562819364, 35.554382149241661],
              [-81.512348527754185, 35.554422846765135],
              [-81.512297832048446, 35.55468773135042],
              [-81.512627798338386, 35.554728427210968],
              [-81.512577102278769, 35.554993312815824],
              [-81.512907071054386, 35.555034007891763],
              [-81.512856375687889, 35.555298892691162],
              [-81.513186345874487, 35.555339587906424],
              [-81.513135651228922, 35.55560447280142],
              [-81.513795593596726, 35.555685860834508],
              [-81.513744901826755, 35.555950745937146],
              [-81.51440484759496, 35.55603212975204],
              [-81.514354156495315, 35.556297015108072],
              [-81.51468413149324, 35.556337706244406],
              [-81.514633442189464, 35.556602590772123],
              [-81.51430346616732, 35.556561900401981],
              [-81.513999317191661, 35.558151209537414],
              [-81.514329298423476, 35.558191900739764],
              [-81.514278606830231, 35.558456785893277],
              [-81.514608590547951, 35.558497476310961],
              [-81.514557899675737, 35.558762361560007],
              [-81.514887884776613, 35.558803051215804],
              [-81.514837194625485, 35.559067936560389],
              [-81.515827156229278, 35.55919000233132],
              [-81.515776468875515, 35.559454887140028],
              [-81.516106457404788, 35.559495573478607],
              [-81.516055770800079, 35.559760459283936],
              [-81.518035718603969, 35.560004561025551],
              [-81.518086397820952, 35.55973967533496],
              [-81.518746380975273, 35.559821035438475],
              [-81.518797058831751, 35.559556148514751],
              [-81.519127049315728, 35.559596827548468],
              [-81.519177724686301, 35.559331940473463],
              [-81.519507715523943, 35.559372617551546],
              [-81.519558389539398, 35.559107731203362],
              [-81.520218367925608, 35.559189083346418],
              [-81.520167696014468, 35.55945396996426],
              [-81.520497687844937, 35.559494644351972],
              [-81.520396345162183, 35.560024418640346],
              [-81.522046317594473, 35.560227778521202],
              [-81.521995651003749, 35.560492665829983],
              [-81.522985641811161, 35.560614671396642],
              [-81.523315639776385, 35.560655338117627],
              [-81.523264977091543, 35.560920226827413],
              [-81.524584976441872, 35.561082884381392],
              [-81.52453431870984, 35.561347772667013],
              [-81.524864320433764, 35.561388435173491],
              [-81.524813662320341, 35.561653323577453],
              [-81.525803673708921, 35.56177530519625],
              [-81.525753019524217, 35.562040193942259],
              [-81.52641303015875, 35.562121511422973],
              [-81.526463682238898, 35.561856622407369],
              [-81.526793686972084, 35.561897278766558],
              [-81.526743035944207, 35.562162167916931],
              [-81.527073042088475, 35.562202824415145],
              [-81.527022392884973, 35.56246771363795],
              [-81.52669238458553, 35.562427057027904],
              [-81.526591080875704, 35.562956835131565],
              [-81.526921091279746, 35.562997492011249],
              [-81.52687043995283, 35.563262380237667],
              [-81.527200450637181, 35.563303036378137],
              [-81.527149800059846, 35.563567925601035],
              [-81.527479813202063, 35.563608580055359],
              [-81.527429163346241, 35.563873469373583],
              [-81.530069286717747, 35.564198677449582],
              [-81.530018646024629, 35.564463566882381],
              [-81.530678682831308, 35.564544860190587],
              [-81.53072932139159, 35.564279969587247],
              [-81.531059339224569, 35.564320614310752],
              [-81.531008701716758, 35.564585505048882],
              [-81.531338719830003, 35.564626149033202],
              [-81.531288083043734, 35.564891039866637],
              [-81.532608168556138, 35.565053608160227],
              [-81.532658800030021, 35.56478871681086],
              [-81.533648864734161, 35.564910632527926],
              [-81.53369949272016, 35.564645740734974],
              [-81.535019579044643, 35.564808281936699],
              [-81.535679623639354, 35.564889547167589],
              [-81.535730244980257, 35.56462465452698],
              [-81.536060267823942, 35.564665285639443],
              [-81.536110887809983, 35.56440039372584],
              [-81.537100951831079, 35.564522280445516],
              [-81.537151568301084, 35.564257387187304],
              [-81.537481589259684, 35.564298014465749],
              [-81.537430973842078, 35.564562907858623],
              [-81.538091018827572, 35.564644159093099],
              [-81.538141632140338, 35.564379265430873],
              [-81.539131700057624, 35.564501135579142],
              [-81.539182308807924, 35.564236242397726],
              [-81.539512331772158, 35.564276863235904],
              [-81.539562939111192, 35.564011968979223],
              [-81.539892960278934, 35.56405258971008],
              [-81.539943567337914, 35.563787695256281],
              [-81.54027358775555, 35.563828314054383],
              [-81.540324192356991, 35.563563420350697],
              [-81.540654212052658, 35.563604038117326],
              [-81.540704816345738, 35.563339143315432],
              [-81.541694874286918, 35.563460991730814],
              [-81.541745473989465, 35.563196096508733],
              [-81.542405512174028, 35.56327732433224],
              [-81.542456109441247, 35.563012428801422],
              [-81.543446166025078, 35.563134262955614],
              [-81.543496759804583, 35.562869366981523],
              [-81.544486815102474, 35.562991192682695],
              [-81.544436225583141, 35.563256089037552],
              [-81.544766246087178, 35.563296695637334],
              [-81.544715656186966, 35.563561592110553],
              [-81.545045678073961, 35.563602197948128],
              [-81.544995089998565, 35.563867094493389],
              [-81.545325113296713, 35.56390770046977],
              [-81.54517334801146, 35.564702389418329],
              [-81.545503375871988, 35.564742993977404],
              [-81.54540219985445, 35.565272787275013],
              [-81.546392289527674, 35.565394596424355],
              [-81.546442875123859, 35.56512970028102],
              [-81.547102934820629, 35.565210901618947],
              [-81.547153516850287, 35.564946004288934],
              [-81.547483546693215, 35.564986603466416],
              [-81.547534127340001, 35.564721705962455],
              [-81.547864155357885, 35.564762304131456],
              [-81.547914734621813, 35.564497406453505],
              [-81.548244763020477, 35.564538003567925],
              [-81.548295340901561, 35.564273105715984],
              [-81.548625367475182, 35.564313701821966],
              [-81.548675943973421, 35.564048803796055],
              [-81.549005970899486, 35.564089397946276],
              [-81.549056544912077, 35.563824499769552],
              [-81.550706676039482, 35.564027460067784],
              [-81.550656107289342, 35.564292358917335],
              [-81.551316164389405, 35.564373537382934],
              [-81.551366731034449, 35.564108638264365],
              [-81.552356816527137, 35.564230397919466],
              [-81.552407379656202, 35.563965497456699],
              [-81.552737407035963, 35.564006082395757],
              [-81.552787968810534, 35.563741182660181],
              [-81.553778051922848, 35.563862929843637],
              [-81.553879168166247, 35.563333129447251],
              [-81.55420919368116, 35.563373709493852],
              [-81.554310305395774, 35.562843908694198],
              [-81.554640330238712, 35.562884487551621],
              [-81.554690883996869, 35.562619586969802],
              [-81.555020908117413, 35.562660164795773],
              [-81.555071460492968, 35.562395264040127],
              [-81.556391552787204, 35.562557566807421],
              [-81.556442101725338, 35.562292665451174],
              [-81.557102147182832, 35.562373810745271],
              [-81.557051601480993, 35.562638713248411],
              [-81.55837170169886, 35.562800994489194],
              [-81.558422243190762, 35.562536091448109],
              [-81.559082293741113, 35.562617226856794],
              [-81.559031754325815, 35.56288212926583],
              [-81.560351863540802, 35.563044388055701],
              [-81.560402397643102, 35.562779485131983],
              [-81.560732425270928, 35.562820047441036],
              [-81.560681891118293, 35.563084950522494],
              [-81.561011920128649, 35.563125512068993],
              [-81.561062452125952, 35.562860608876321],
              [-81.561392480413915, 35.562901169391417],
              [-81.561796719228127, 35.560781935883256],
              [-81.562126738323116, 35.560822494448949],
              [-81.562278319144767, 35.560027781060114],
              [-81.562608335383956, 35.56006833742444],
              [-81.562658860234933, 35.559803432455887],
              [-81.562988874677259, 35.559843988713148],
              [-81.563039399248524, 35.559579083547611],
              [-81.563699428121154, 35.559660193079196],
              [-81.563749949154655, 35.559395287628874],
              [-81.565400023445306, 35.559598043252528],
              [-81.565349507702607, 35.559862950276241],
              [-81.566669578154773, 35.560025138789271],
              [-81.566770599844574, 35.559495325452744],
              [-81.566440583827358, 35.559454779488206],
              [-81.566642627429516, 35.558395151980982],
              [-81.566972638134004, 35.558435697431129],
              [-81.567023146584575, 35.558170789657702],
              [-81.56966324107627, 35.558495116174036],
              [-81.569713740805753, 35.558230208187283],
              [-81.570043753448317, 35.558270744371995],
              [-81.569993254771234, 35.558535652493212],
              [-81.571643327148024, 35.558738322460627],
              [-81.57169381943126, 35.558473412789716],
              [-81.572353849032311, 35.558554474400268],
              [-81.572404338880759, 35.5582895644215],
              [-81.573064367667399, 35.558370621274491],
              [-81.572912902316503, 35.559165351021953],
              [-81.57324292036192, 35.55920587850617],
              [-81.573192432653229, 35.559470787869436],
              [-81.57451251234275, 35.559632889374427],
              [-81.574462029617138, 35.559897799212543],
              [-81.575122073545515, 35.559978844852026],
              [-81.575172555268793, 35.559713934721913],
              [-81.576492643945883, 35.559876013775657],
              [-81.576822666670921, 35.559916531527811],
              [-81.576772190210491, 35.560181442329728],
              [-81.577102215420453, 35.560221959295944],
              [-81.577001264718533, 35.5607517810273],
              [-81.577331291260606, 35.560792297388531],
              [-81.577280817019059, 35.56105720831804],
              [-81.577610844943493, 35.561097723916681],
              [-81.577509897576547, 35.561627545926591],
              [-81.577839927907249, 35.56166805999581],
              [-81.577789454781893, 35.561932971076253],
              [-81.578119487626225, 35.561973485260452],
              [-81.578069014121041, 35.562238396459378],
              [-81.578399048319127, 35.562278908979806],
              [-81.578298104630591, 35.562808731481695],
              [-81.578197158491903, 35.563338552948757],
              [-81.577867120083283, 35.563298039891066],
              [-81.577362359052657, 35.565947142624729],
              [-81.577032309343821, 35.565906627350252],
              [-81.576981830914576, 35.56617153690091],
              [-81.577311880573305, 35.566212052333114],
              [-81.57721092375688, 35.566741872509503],
              [-81.578201084364963, 35.56686341506078],
              [-81.578861193095193, 35.566944439243407],
              [-81.578810718890367, 35.567209349505248],
              [-81.579140774817134, 35.567249860835787],
              [-81.579090302438331, 35.567514771169236],
              [-81.580410534670222, 35.56767680533148],
              [-81.580461002837865, 35.567411894460868],
              [-81.581121118289104, 35.56749290635323],
              [-81.58117158402149, 35.567227995174683],
              [-81.581501641174512, 35.567268499190511],
              [-81.581552105524466, 35.567003587838336],
              [-81.58188216195424, 35.567044090822755],
              [-81.581932624921762, 35.566779179296958],
              [-81.58226268062846, 35.566819681250102],
              [-81.582313142213579, 35.566554769550692],
              [-81.582973252510172, 35.566635770497207],
              [-81.583023710557242, 35.566370858513373],
              [-81.584013875344525, 35.566492353703474],
              [-81.584064329874948, 35.566227440376416],
              [-81.584724439256291, 35.566308431815315],
              [-81.584774892483111, 35.566043519058063],
              [-81.585434999974268, 35.56612450666367],
              [-81.585485450766072, 35.56585959359861],
              [-81.586805666565795, 35.566021556583614],
              [-81.586856111713971, 35.565756642965738],
              [-81.587516220051214, 35.565837619246224],
              [-81.587566662735441, 35.565572704419509],
              [-81.58822677028509, 35.565653676843205],
              [-81.58827720943151, 35.565388761732223],
              [-81.58893731619338, 35.565469730299348],
              [-81.588987752904728, 35.565204814880644],
              [-81.589647858878934, 35.565285779591093],
              [-81.589698293155152, 35.565020863864675],
              [-81.591348558534193, 35.565223257468951],
              [-81.59200866698589, 35.565304208451018],
              [-81.591958240079279, 35.565569125116809],
              [-81.592288295837676, 35.565609598945855],
              [-81.592187444215938, 35.566139431503231],
              [-81.592847560960976, 35.566220377907968],
              [-81.592797136195884, 35.566485293957356],
              [-81.594447441278149, 35.566687644443448],
              [-81.594397022550837, 35.566952561100742],
              [-81.594727085155512, 35.566993028289858],
              [-81.594676667180565, 35.567257945943176],
              [-81.595006731167558, 35.567298412369311],
              [-81.594956313887323, 35.567563329216362],
              [-81.595286379256564, 35.567603794879552],
              [-81.595235962728708, 35.567868712722529],
              [-81.595566030583043, 35.56790917759929],
              [-81.595364364171672, 35.568968845533618],
              [-81.595034292105638, 35.568928380120262],
              [-81.594832615081174, 35.569988046010927],
              [-81.595162691358894, 35.570028511960807],
              [-81.595061854093714, 35.5705583453652],
              [-81.59539193280682, 35.570598810686256],
              [-81.595341514733178, 35.570863727463532],
              [-81.59567159482863, 35.570904192021572],
              [-81.595621177449743, 35.57116910799251],
              [-81.595951258956319, 35.571209572688673],
              [-81.595900842301077, 35.571474488754333],
              [-81.596230926264155, 35.571514951762879],
              [-81.59633175711042, 35.570985118422762],
              [-81.596661838193938, 35.571025580289358],
              [-81.596712253158444, 35.570760662952033],
              [-81.597702496328864, 35.570882042743044],
              [-81.59775290670224, 35.570617124987415],
              [-81.598413068723957, 35.570698040086214],
              [-81.598463475559143, 35.57043312204658],
              [-81.598793556562356, 35.570473578104291],
              [-81.598843963118128, 35.570208659867554],
              [-81.599504121800805, 35.570289569070574],
              [-81.59955452592132, 35.570024650526229],
              [-81.599884604703576, 35.57006510364787],
              [-81.599935007441729, 35.569800184930124],
              [-81.600265085500254, 35.569840637020505],
              [-81.600315485753384, 35.569575718152905],
              [-81.600645564191026, 35.569616169188436],
              [-81.600695963061952, 35.569351250147307],
              [-81.601026039643955, 35.569391699274092],
              [-81.601076437132662, 35.569126780059584],
              [-81.601406514122786, 35.569167229032594],
              [-81.601456910229288, 35.568902309644578],
              [-81.601786985392678, 35.568942757609982],
              [-81.60183738011709, 35.568677838048536],
              [-81.602497529296727, 35.568758730118716],
              [-81.602547921585895, 35.56849381024977],
              [-81.602877995602114, 35.56853425435456],
              [-81.602928386509106, 35.568269334312198],
              [-81.603258459830371, 35.568309778286775],
              [-81.603308849326297, 35.568044857169994],
              [-81.603638921923832, 35.568085300113367],
              [-81.603689310066557, 35.567820379724225],
              [-81.60533967263963, 35.568022577610506],
              [-81.605390054056571, 35.56775765563426],
              [-81.605720126522996, 35.567798092926665],
              [-81.605770506557846, 35.567533170777146],
              [-81.606100578300314, 35.567573607038341],
              [-81.606150956953172, 35.567308684715414],
              [-81.606481027971768, 35.567349119945511],
              [-81.606531405242478, 35.567084197449205],
              [-81.606861475508325, 35.567124630746939],
              [-81.606911851425949, 35.566859708978434],
              [-81.607241920967908, 35.566900141245057],
              [-81.607292295503413, 35.566635219303208],
              [-81.608282502944689, 35.566756510318037],
              [-81.608332872889505, 35.566491587958474],
              [-81.609983219940929, 35.566693721037076],
              [-81.610033584291884, 35.566428797968108],
              [-81.610363653636156, 35.566469221758361],
              [-81.61031328923535, 35.566734144985041],
              [-81.61097343101703, 35.566814990142269],
              [-81.610923069525057, 35.567079914475116],
              [-81.61125314196228, 35.56712033584207],
              [-81.611202781165275, 35.5673852593685],
              [-81.611862929132769, 35.567466099678903],
              [-81.611913287823768, 35.567201175884399],
              [-81.612573434972177, 35.567282011437108],
              [-81.612623791228202, 35.567017087335323],
              [-81.613614011273356, 35.567138333984289],
              [-81.613664364012351, 35.566873408540168],
              [-81.613994436975219, 35.566913822461949],
              [-81.61409513938959, 35.566383972089092],
              [-81.614425210546301, 35.566424383944572],
              [-81.614475559118773, 35.566159459039639],
              [-81.615135700342137, 35.566240280692398],
              [-81.615186046450575, 35.565975354579116],
              [-81.615516116517114, 35.56601576437631],
              [-81.615566462346578, 35.565750838066066],
              [-81.616556670228491, 35.56587206079562],
              [-81.6166070125701, 35.565607134044313],
              [-81.616937080714223, 35.565647539107644],
              [-81.616987421673983, 35.565382612183086],
              [-81.618307696693236, 35.56554422466025],
              [-81.618257361048293, 35.565809152096868],
              [-81.618917503188342, 35.565889953208611],
              [-81.618867169320623, 35.56615488087391],
              [-81.61952731488185, 35.566235678664917],
              [-81.619476982762293, 35.566500605657815],
              [-81.619807057103628, 35.56654100379204],
              [-81.619857388141043, 35.566276075764137],
              [-81.620517535645973, 35.566356867271146],
              [-81.620567864248542, 35.566091938936161],
              [-81.622548308364188, 35.566334292022809],
              [-81.622598630319729, 35.566069362845084],
              [-81.624579081024677, 35.566311681905361],
              [-81.624629394127524, 35.56604675193266],
              [-81.625289545621186, 35.566127517491729],
              [-81.625239233521668, 35.566392447756051],
              [-81.625899388436039, 35.566473209994122],
              [-81.62594969732659, 35.56620827948597],
              [-81.626609851420227, 35.566289036966594],
              [-81.62666015787606, 35.566024106151403],
              [-81.627650387814342, 35.566145236605365],
              [-81.62770069078222, 35.565880305349381],
              [-81.628360842847002, 35.565961053346612],
              [-81.628411143380134, 35.565696121783695],
              [-81.629401371870586, 35.565817236174681],
              [-81.629451668915863, 35.565552304171014],
              [-81.629781744335219, 35.565592674315219],
              [-81.629731448343023, 35.565857606452717],
              [-81.630721680776347, 35.565978710077545],
              [-81.630771973609271, 35.565713777538527],
              [-81.63143212737576, 35.565794508851113],
              [-81.631482417773938, 35.565529576005233],
              [-81.632142570748329, 35.565610303461447],
              [-81.632192858711818, 35.565345370308741],
              [-81.634503399230596, 35.565627884906853],
              [-81.634553678391029, 35.565362950802168],
              [-81.63488375554941, 35.565403306185992],
              [-81.634934033328292, 35.565138371908425],
              [-81.635264109762105, 35.565178726261252],
              [-81.635314386159422, 35.564913791810746],
              [-81.635974537905994, 35.564994497557272],
              [-81.636024811839391, 35.564729561898858],
              [-81.63668496279351, 35.564810263789134],
              [-81.636735234321563, 35.564545328725067],
              [-81.637065309238025, 35.56458567819066],
              [-81.637115578281879, 35.564320742977628],
              [-81.637775728067311, 35.564401438024639],
              [-81.63782599467649, 35.564136502504901],
              [-81.638156068471957, 35.564176849011346],
              [-81.638206333670354, 35.563911912417716],
              [-81.638536406740982, 35.563952257893177],
              [-81.63858667058733, 35.563687322027661],
              [-81.638916742903902, 35.563727665571193],
              [-81.638967005368713, 35.56346272953278],
              [-81.639627148910051, 35.563543414561714],
              [-81.639677408940443, 35.563278478216752],
              [-81.640667623570195, 35.563399499080624],
              [-81.640717880083628, 35.563134561394115],
              [-81.641378022725007, 35.563215237817396],
              [-81.641428275701102, 35.562950299848303],
              [-81.642088417520043, 35.563030971514287],
              [-81.642138669164524, 35.562766033214622],
              [-81.642798809087552, 35.562846701048414],
              [-81.642849058326831, 35.562581763343267],
              [-81.643509198559428, 35.562662427296871],
              [-81.643559444232125, 35.562397488408124],
              [-81.644219582568752, 35.562478148529586],
              [-81.644269825806944, 35.562213209334303],
              [-81.64492996445297, 35.562293865575597],
              [-81.644980205256857, 35.562028926073886],
              [-81.646630551202733, 35.562230548980274],
              [-81.646680786412915, 35.561965608770983],
              [-81.647010855748078, 35.562005930341989],
              [-81.648001065751998, 35.56212689057304],
              [-81.648051295318822, 35.561861949813959],
              [-81.650031717837692, 35.562103844348918],
              [-81.649981494589667, 35.562368785910124],
              [-81.65031156720606, 35.562409098509242],
              [-81.650261344682988, 35.562674040165078],
              [-81.653892179948826, 35.563117419136425],
              [-81.653841969785432, 35.563382362199185],
              [-81.654832207820377, 35.563503264882335],
              [-81.654782000488595, 35.563768208307039],
              [-81.655112082258327, 35.563808506923756],
              [-81.655061875651668, 35.564073450442933],
              [-81.655722041208207, 35.564154046177165],
              [-81.655671836380037, 35.564418989924512],
              [-81.656001921269649, 35.564459287017947],
              [-81.655951717137313, 35.564724229958749],
              [-81.656281803408149, 35.564764526288478],
              [-81.656231600030509, 35.565029470224864],
              [-81.656561687653038, 35.565069764889863],
              [-81.656511485000792, 35.565334708920794],
              [-81.656841574004446, 35.565375002822137],
              [-81.657171663336158, 35.565415295826362],
              [-81.657831844115648, 35.565495880019832],
              [-81.657882041481557, 35.565230936379749],
              [-81.659532491101359, 35.56543237920225],
              [-81.659582682843421, 35.565167433954045],
              [-81.660242862897903, 35.565248005075752],
              [-81.660572954505, 35.565288288816056],
              [-81.660472576983111, 35.56581817917364],
              [-81.660802669921864, 35.565858462307901],
              [-81.660752481737234, 35.566123408012253],
              [-81.661082576057083, 35.566163690382822],
              [-81.660982200781262, 35.566693581040099],
              [-81.66131229863845, 35.566733862756372],
              [-81.661262111547288, 35.566998807709354],
              [-81.661592209682951, 35.567039088686016],
              [-81.661542024449659, 35.567304034610359],
              [-81.661872123966432, 35.567344314823323],
              [-81.661821938326227, 35.567609259965202],
              [-81.662482142655847, 35.567689817918072],
              [-81.662532325086602, 35.56742487253328],
              [-81.663522629747405, 35.567545701455302],
              [-81.663572809792967, 35.567280755606646],
              [-81.664233012084679, 35.567361304076577],
              [-81.664283189666079, 35.567096357020596],
              [-81.664943391162282, 35.567176901634276],
              [-81.664993565206331, 35.566911954296295],
              [-81.665323665944214, 35.566952225111521],
              [-81.66537383860711, 35.566687277600991],
              [-81.665703937516582, 35.566727547409606],
              [-81.665754108827954, 35.56646260062746],
              [-81.666414307699512, 35.566543136336371],
              [-81.66646447544386, 35.566278188371214],
              [-81.667454771961133, 35.566398985279157],
              [-81.667504937320572, 35.566134036850166],
              [-81.668495232524492, 35.56625482530611],
              [-81.668445071427968, 35.566519774111349],
              [-81.669765474520275, 35.566680811839852],
              [-81.66981563253583, 35.566415863377479],
              [-81.670805935694432, 35.56653663206486],
              [-81.670755781941864, 35.566801580903437],
              [-81.67141598743504, 35.566882089450544],
              [-81.671365835491386, 35.56714703941806],
              [-81.672026045475022, 35.567227543717458],
              [-81.671975895310581, 35.567492493912795],
              [-81.6729662159035, 35.567613244032557],
              [-81.672916068542023, 35.567878193688003],
              [-81.673576286045446, 35.567958690449174],
              [-81.673526141566001, 35.568223640308226],
              [-81.673856251847624, 35.568263887025672],
              [-81.673806107021093, 35.568528837904452],
              [-81.674136219786632, 35.568569083833751],
              [-81.674086075656149, 35.568834033905659],
              [-81.674416188700022, 35.56887427909539],
              [-81.674315902665938, 35.569404179364554],
              [-81.674646019247234, 35.569444423899448],
              [-81.674495590377973, 35.570239275323168],
              [-81.674825710447379, 35.570279519361009],
              [-81.67472542535134, 35.57080941977236],
              [-81.675055547855408, 35.570849663179793],
              [-81.675005406421008, 35.571114613448046],
              [-81.675335529203267, 35.571154856115712],
              [-81.675235247429015, 35.571684755900471],
              [-81.675895500396365, 35.571765239028949],
              [-81.675845360036959, 35.572030189604213],
              [-81.677496004529047, 35.57223138146275],
              [-81.677445870183021, 35.57249633174051],
              [-81.677776002006027, 35.572536567714181],
              [-81.677725868415891, 35.572801518987248],
              [-81.678056000487302, 35.572841753320127],
              [-81.677955734431606, 35.573371656015432],
              [-81.678285870040696, 35.573411889693418],
              [-81.678235737575164, 35.573676841115649],
              [-81.678565873462617, 35.573717074053988],
              [-81.67846561072929, 35.574246976122119],
              [-81.678795749081246, 35.57428720933089],
              [-81.678745617710575, 35.574552160001176],
              [-81.679075758546745, 35.574592392421515],
              [-81.679025627902092, 35.574857343185954],
              [-81.679355770119471, 35.574897574842346],
              [-81.679255511087788, 35.575427477396879],
              [-81.679585655710198, 35.575467707521511],
              [-81.679535526190364, 35.575732658434866],
              [-81.679865672223784, 35.575772888696548],
              [-81.679815544533056, 35.576037839679778],
              [-81.680145691918028, 35.576078068276217],
              [-81.680095563850458, 35.57634301937788],
              [-81.680755862843, 35.576423475021947],
              [-81.680705736555439, 35.576688426351012],
              [-81.682356494470596, 35.576889549984109],
              [-81.682306374227153, 35.577154501916382],
              [-81.682966681989896, 35.577234944628387],
              [-81.682916563526476, 35.577499896788147],
              [-81.683246719504254, 35.577540116919025],
              [-81.683196601767136, 35.577805069172825],
              [-81.683526758023277, 35.57784528856395],
              [-81.683476641012476, 35.578110240911812],
              [-81.683806799752745, 35.578150459514411],
              [-81.683756683468246, 35.5784154119563],
              [-81.684086843589867, 35.578455629794824],
              [-81.684036728031629, 35.578720582330746],
              [-81.684366889534516, 35.578760799405039],
              [-81.684316774702708, 35.579025752034987],
              [-81.684646937586763, 35.579065968345112],
              [-81.684596823451471, 35.579330920167997],
              [-81.685257152309688, 35.579411350362484],
              [-81.685307264307482, 35.579146397371879],
              [-81.686297756889132, 35.579267035532254],
              [-81.686247649155817, 35.579531988898431],
              [-81.686907981258472, 35.579612409216722],
              [-81.686857875305449, 35.579877362810223],
              [-81.687188042916617, 35.579917572206909],
              [-81.687137937660182, 35.58018252499339],
              [-81.687468106652616, 35.580222733625831],
              [-81.687418002152512, 35.580487687407278],
              [-81.687748172526327, 35.580527895275509],
              [-81.687698068722824, 35.580792848249843],
              [-81.688028240477806, 35.580833055353935],
              [-81.687978137400791, 35.581098008422103],
              [-81.688308311640156, 35.581138214737571],
              [-81.687857375449724, 35.583522793655249],
              [-81.688187559501827, 35.58356300027279],
              [-81.688087349584961, 35.584092906139809],
              [-81.688417536042536, 35.584133111225299],
              [-81.688317328027011, 35.584663017176901],
              [-81.689638087829053, 35.584823831362414],
              [-81.689587987546119, 35.585088784812214],
              [-81.690248371519473, 35.585169186786032],
              [-81.69057856343079, 35.585209385988257],
              [-81.690678757345026, 35.584679478249626],
              [-81.691008948608641, 35.584719677164358],
              [-81.69105904402069, 35.584454723102567],
              [-81.691389233424701, 35.584494920109805],
              [-81.691489421158778, 35.583965011601663],
              [-81.691819608812025, 35.584005208345772],
              [-81.691869701134067, 35.583740253899279],
              [-81.692199888030657, 35.583780448711487],
              [-81.692249978971475, 35.583515494092396],
              [-81.692580165141479, 35.58355568787379],
              [-81.692830607429556, 35.582230912668798],
              [-81.692500426529406, 35.582190719553715],
              [-81.692600603022939, 35.581660808926841],
              [-81.692270424557975, 35.58162061518069],
              [-81.692420690541823, 35.580825748882262],
              [-81.692090515565937, 35.580785554638233],
              [-81.692391043917183, 35.579195821803559],
              [-81.692721211466704, 35.579236015272443],
              [-81.692771297867267, 35.578971059216563],
              [-81.693431631870283, 35.579051444096237],
              [-81.693481714732926, 35.57878648775899],
              [-81.693811882259453, 35.578826678244361],
              [-81.693912044895271, 35.578296765185499],
              [-81.694242209538245, 35.578336954531295],
              [-81.694192129437923, 35.578601911213603],
              [-81.695512796630638, 35.578762661030886],
              [-81.695562872515566, 35.578497703815621],
              [-81.69622320650393, 35.578578072609865],
              [-81.697874045546172, 35.578778978925527],
              [-81.697823978141372, 35.579043937048795],
              [-81.698484318818089, 35.579124293544012],
              [-81.698434253193966, 35.579389251894497],
              [-81.698764424525478, 35.57942942894126],
              [-81.698714359628227, 35.579694387385643],
              [-81.699044532340707, 35.57973456366804],
              [-81.698994468170255, 35.579999522206336],
              [-81.69965481886058, 35.580079871394801],
              [-81.699604756470876, 35.580344830160193],
              [-81.699934932272754, 35.5803850040163],
              [-81.699884870609779, 35.580649962875576],
              [-81.700215048865715, 35.580690135041706],
              [-81.700164987929696, 35.580955093994881],
              [-81.700495167596642, 35.580995266297791],
              [-81.700445107357453, 35.581260224443682],
              [-81.701765834585458, 35.581420904286524],
              [-81.701715778265154, 35.581685863826962],
              [-81.702376145383212, 35.581766198190984],
              [-81.702326091916746, 35.58203115703283],
              [-81.702656277035473, 35.58207132345219],
              [-81.702706329417822, 35.581806363576128],
              [-81.703036514912625, 35.581846528940126],
              [-81.703086565913893, 35.581581568891643],
              [-81.703746934643519, 35.581661895784009],
              [-81.703796983209784, 35.5813969354299],
              [-81.7047875350585, 35.581517419099967],
              [-81.704837580135674, 35.581252458307013],
              [-81.705828131735501, 35.581372932598327],
              [-81.706158315878483, 35.581413088608052],
              [-81.706108275017414, 35.581678049933558],
              [-81.707098833673314, 35.581798512952098],
              [-81.706898680680851, 35.582858358532789],
              [-81.707228871401952, 35.582898511617714],
              [-81.707178834195162, 35.583163472353469],
              [-81.706848641316881, 35.583123319159988],
              [-81.706748561638236, 35.583653241197318],
              [-81.707078756624583, 35.583693394657061],
              [-81.70687859752951, 35.584753237890993],
              [-81.707208798162441, 35.584793390961011],
              [-81.707058678547412, 35.585588272696533],
              [-81.707388881566601, 35.585628425292938],
              [-81.707338842095083, 35.585893385925694],
              [-81.707669047598586, 35.585933537732956],
              [-81.707568969782784, 35.586463459146643],
              [-81.707899177721515, 35.586503610322417],
              [-81.70779910070614, 35.587033531844583],
              [-81.708129311080327, 35.587073682388962],
              [-81.708079273136335, 35.587338643224072],
              [-81.708409484891632, 35.587378793003829],
              [-81.708209335105238, 35.588438634655517],
              [-81.70886976803061, 35.588518932586886],
              [-81.708819731614184, 35.588783893624061],
              [-81.709149949591534, 35.588824040475188],
              [-81.709099915005552, 35.589089001581407],
              [-81.710420794491924, 35.589249583244708],
              [-81.710470825994136, 35.588984622482599],
              [-81.712121928517917, 35.589185327997519],
              [-81.71207190342021, 35.589450290301464],
              [-81.712021877965526, 35.589715251664892],
              [-81.712352101351115, 35.589755390706287],
              [-81.71195189330507, 35.591875082182199],
              [-81.712282126555152, 35.591915221365689],
              [-81.712232100266661, 35.592180182639751],
              [-81.713553040693668, 35.592340729126533],
              [-81.713503018295683, 35.592605690893308],
              [-81.714163492579473, 35.592685959016279],
              [-81.714113473066476, 35.592950920984883],
              [-81.714443711737985, 35.592991053382278],
              [-81.71439369184931, 35.59325601546913],
              [-81.714723931902114, 35.593296147101732],
              [-81.714673913844095, 35.593561109257422],
              [-81.715004155278194, 35.593601240125359],
              [-81.714954136844611, 35.593866202399177],
              [-81.715284380763194, 35.59390633247768],
              [-81.715234363057036, 35.594171294844976],
              [-81.715564608356942, 35.5942114241588],
              [-81.715514591378309, 35.594476386619476],
              [-81.715844838059539, 35.594516515168529],
              [-81.715744806333589, 35.595046440212691],
              [-81.716075055450673, 35.595086568130021],
              [-81.715774957530272, 35.596676339531072],
              [-81.716105213301105, 35.596716467348735],
              [-81.716005180532562, 35.597246391390428],
              [-81.71633543984241, 35.597286518551606],
              [-81.71628542345573, 35.597551480207855],
              [-81.716615683043798, 35.597591606628846],
              [-81.71656566848786, 35.597856568353812],
              [-81.717226191887235, 35.59793681964485],
              [-81.717176178010163, 35.598201781620851],
              [-81.717506441791272, 35.598241905589738],
              [-81.717406416268886, 35.598771829664159],
              [-81.717736682486006, 35.59881195300121],
              [-81.717686670840621, 35.599076915099602],
              [-81.717356403569042, 35.599036791629544],
              [-81.717256376055118, 35.599566714564958],
              [-81.716926107001186, 35.59952658993096],
              [-81.716876092266091, 35.599791551644337],
              [-81.716545822484576, 35.599751425979555],
              [-81.716495805264628, 35.600016387544883],
              [-81.716165534755447, 35.599976260849154],
              [-81.716065498298775, 35.600506182669015],
              [-81.71573522600734, 35.600466054809402],
              [-81.715485122201358, 35.601790857585563],
              [-81.715815400869204, 35.601830986085623],
              [-81.715665337158143, 35.60262586750833],
              [-81.715995619347069, 35.602665996410543],
              [-81.715945598500682, 35.602930956637948],
              [-81.716275882040918, 35.60297108387423],
              [-81.716175841476783, 35.603501004475845],
              [-81.716506127453314, 35.603541131080327],
              [-81.716456107735496, 35.60380609145448],
              [-81.716786395093763, 35.603846217294006],
              [-81.716736376103597, 35.604111177761432],
              [-81.717066664843401, 35.604151302836087],
              [-81.717016647684389, 35.604416263372002],
              [-81.717346937805914, 35.604456387681765],
              [-81.71729692027138, 35.604721348335609],
              [-81.717627211774456, 35.604761471880394],
              [-81.717577196071005, 35.605026432602799],
              [-81.71823778216752, 35.605106677264658],
              [-81.718187768246509, 35.605371638213242],
              [-81.718518062839934, 35.605411759330288],
              [-81.718468049616618, 35.605676719471013],
              [-81.718798345591608, 35.605716839823074],
              [-81.718748333126356, 35.605981800958027],
              [-81.719078630483011, 35.606021920545111],
              [-81.719028618715384, 35.606286880872197],
              [-81.719358917453704, 35.606326999694403],
              [-81.719308906444169, 35.60659196101561],
              [-81.719639207667512, 35.606632079048161],
              [-81.719589196252286, 35.606897039586244],
              [-81.719919498857323, 35.606937156853732],
              [-81.721901319085205, 35.607177839849051],
              [-81.721951321983369, 35.606912877503902],
              [-81.722281625821736, 35.606952988352724],
              [-81.722331627338235, 35.606688025834714],
              [-81.722992232752361, 35.60676824369628],
              [-81.723042231832324, 35.606503280872644],
              [-81.723702836444133, 35.606583494876389],
              [-81.723752831984328, 35.606318531771784],
              [-81.724083134292485, 35.606358637731944],
              [-81.724283110069436, 35.605298784358787],
              [-81.724613408485226, 35.605338888889307],
              [-81.724663400281884, 35.605073925319793],
              [-81.724993696836066, 35.605114027943117],
              [-81.725043688354489, 35.604849064176165],
              [-81.725373984180649, 35.604889165768547],
              [-81.72542397321422, 35.604624201853639],
              [-81.725754268312372, 35.604664302415209],
              [-81.725804255964434, 35.604399338327624],
              [-81.726134550364776, 35.6044394387593],
              [-81.726184536605103, 35.604174473598064],
              [-81.72651483138074, 35.604214572974129],
              [-81.726564815136157, 35.603949607664902],
              [-81.727225402424523, 35.604029802581252],
              [-81.727275384877345, 35.60376483784264],
              [-81.727935970229481, 35.603845028025127],
              [-81.727985949142777, 35.603580063005815],
              [-81.728646534795374, 35.603660249305896],
              [-81.728696511272446, 35.603395283981051],
              [-81.729026802982389, 35.603435375663629],
              [-81.729076779151001, 35.603170409240334],
              [-81.729407070132766, 35.603210499892164],
              [-81.729457043847077, 35.602945534222087],
              [-81.730117624681142, 35.603025712566058],
              [-81.730167595928876, 35.60276074568943],
              [-81.730828175959957, 35.602840920175908],
              [-81.730878144801906, 35.602575953894927],
              [-81.731208434252679, 35.602616039658336],
              [-81.731258401682908, 35.602351072303669],
              [-81.731588690405417, 35.602391157036266],
              [-81.731638656454379, 35.602126189509086],
              [-81.731968944448852, 35.602166273210877],
              [-81.732018908043472, 35.601901306436915],
              [-81.732349195279326, 35.601941388206939],
              [-81.732399158595982, 35.601676421235616],
              [-81.733059731968382, 35.601756582717137],
              [-81.733109691745611, 35.601491615465207],
              [-81.733439977851774, 35.601531694275707],
              [-81.733390018056284, 35.601796662586374],
              [-81.733720305574124, 35.601836741532807],
              [-81.733670347610115, 35.602101709911807],
              [-81.734661215256693, 35.602221940861156],
              [-81.734711170056329, 35.60195697208357],
              [-81.736362617380337, 35.60215733807572],
              [-81.736412566579688, 35.601892368594285],
              [-81.737403435703357, 35.602012576295003],
              [-81.73735348966845, 35.602277546174925],
              [-81.737683782207611, 35.602317614321322],
              [-81.73801407393961, 35.602357680693466],
              [-81.737964129687725, 35.602622650799368],
              [-81.738294422800976, 35.602662716406385],
              [-81.738244480380729, 35.602927686580536],
              [-81.738574774875261, 35.602967751422526],
              [-81.738524833152923, 35.603232720788675],
              [-81.740506615571391, 35.603473093532294],
              [-81.740456679851704, 35.603738063655626],
              [-81.741117279090176, 35.603818181277397],
              [-81.741067345153695, 35.604083151626604],
              [-81.741397646853855, 35.604123208760271],
              [-81.741347713676404, 35.604388180103619],
              [-81.741678015654429, 35.604428236497021],
              [-81.741628083174987, 35.604693207032454],
              [-81.743940221267465, 35.6049735773978],
              [-81.743990145228864, 35.604708605056906],
              [-81.744320450921251, 35.604748654241526],
              [-81.744370373531936, 35.604483682629223],
              [-81.744700678495647, 35.6045237307831],
              [-81.744750600798, 35.604258758072341],
              [-81.74541120849139, 35.60433885144549],
              [-81.745461128357533, 35.604073878429531],
              [-81.746121735246376, 35.604153967945194],
              [-81.746071818562825, 35.60441894030064],
              [-81.746402124103199, 35.604458983831726],
              [-81.746352208148238, 35.60472395628021],
              [-81.746682515069821, 35.604763999046121],
              [-81.746582684321339, 35.605293944990429],
              [-81.746912993648607, 35.605333986222718],
              [-81.746813163704857, 35.605863932273699],
              [-81.747143475498831, 35.605903973774424],
              [-81.747093561077165, 35.606168946422542],
              [-81.747423874221838, 35.606208986256995],
              [-81.747373960559386, 35.606473959899141],
              [-81.747704276188628, 35.606513998943292],
              [-81.747654363224328, 35.606778971777381],
              [-81.74798467916203, 35.606819010982271],
              [-81.747934766926434, 35.607083983909263],
              [-81.748265085318195, 35.607124021422841],
              [-81.748215173811303, 35.607388994442879],
              [-81.748545493614884, 35.607429032092107],
              [-81.748495582836824, 35.607694005205083],
              [-81.748825903991019, 35.607734041188031],
              [-81.748775993941706, 35.607999014393961],
              [-81.749106316507749, 35.608039050512531],
              [-81.749056407187183, 35.608304023811392],
              [-81.749386731103939, 35.608344058263569],
              [-81.749336822512149, 35.608609031655362],
              [-81.749667147810214, 35.608649065342284],
              [-81.749467516576729, 35.609708958091389],
              [-81.749797846421259, 35.60974899141101],
              [-81.749747938308289, 35.610013964869282],
              [-81.75007827063753, 35.610053997398559],
              [-81.750028363253378, 35.610318970949621],
              [-81.750358695860569, 35.610359002738498],
              [-81.750308790308551, 35.610623976357537],
              [-81.751299794356427, 35.610744066708612],
              [-81.751249891643582, 35.6110090406859],
              [-81.751910565117896, 35.611089096686534],
              [-81.751860664189067, 35.611354070889334],
              [-81.753182020376386, 35.61151417261916],
              [-81.75313212331119, 35.611779146411777],
              [-81.754123146766361, 35.611899213683273],
              [-81.754073252571061, 35.612164188735086],
              [-81.754403595798038, 35.612204209486947],
              [-81.754353702301188, 35.612469183730532],
              [-81.754684048013033, 35.612509203691928],
              [-81.7546341552451, 35.61277417802841],
              [-81.754964501234866, 35.612814197249349],
              [-81.754864716830937, 35.613344146067803],
              [-81.755195066360599, 35.613384164630894],
              [-81.755045390474066, 35.614179087957524],
              [-81.755375743495563, 35.614219106020506],
              [-81.755275960050867, 35.61474905497078],
              [-81.754945606022261, 35.614709036617512],
              [-81.754795925243045, 35.615503959345737],
              [-81.754465567271438, 35.615463939721401],
              [-81.754415671970762, 35.615728913751646],
              [-81.753424597951067, 35.615608849066767],
              [-81.753374699158414, 35.615873822659147],
              [-81.752713981930214, 35.615793774796423],
              [-81.752664080670101, 35.616058747182528],
              [-81.751673005212396, 35.615938668229212],
              [-81.751623099387515, 35.616203641103382],
              [-81.749310593620606, 35.615923423622085],
              [-81.749360506832161, 35.615658451676644],
              [-81.747708732685908, 35.615458269780042],
              [-81.747658814167721, 35.615723240160854],
              [-81.746998104916088, 35.615643161205199],
              [-81.746948182857565, 35.615908131305588],
              [-81.746617828217865, 35.615868090335326],
              [-81.746567904777848, 35.616133060263031],
              [-81.746237549409216, 35.616093018261893],
              [-81.746187624587606, 35.616357988016944],
              [-81.745526911585173, 35.616277900178751],
              [-81.745476984357268, 35.616542870529422],
              [-81.74514662782687, 35.616502824667414],
              [-81.745096699217328, 35.616767794845323],
              [-81.744766340854639, 35.616727747977357],
              [-81.744716410863475, 35.616992717982633],
              [-81.744386052875555, 35.616952670058787],
              [-81.744336121472131, 35.617217638990283],
              [-81.744005761682459, 35.61717759096161],
              [-81.743955828897356, 35.617442559720388],
              [-81.743625468348398, 35.617402509759657],
              [-81.74352559971895, 35.617932447792818],
              [-81.742864875379382, 35.617852344646018],
              [-81.742814938479782, 35.618117313787621],
              [-81.741493489528082, 35.617957097086112],
              [-81.741443546946954, 35.618222064780525],
              [-81.740782823407955, 35.618141950299808],
              [-81.740732878389849, 35.618406917688645],
              [-81.740402515503163, 35.618366858980821],
              [-81.740352569103294, 35.618631826196896],
              [-81.74002220548789, 35.61859176645801],
              [-81.739972258809743, 35.618856733476413],
              [-81.739311529344633, 35.618776611063602],
              [-81.739411428802129, 35.61824667746815],
              [-81.739081067221619, 35.618206615167665],
              [-81.739131017515746, 35.617941648442709],
              [-81.738800657317029, 35.617901585376771],
              [-81.738850608339803, 35.617636618744555],
              [-81.738520249522836, 35.617596554913355],
              [-81.738570201274257, 35.617331588373936],
              [-81.738239844942541, 35.617291523752499],
              [-81.738289797422581, 35.61702655730587],
              [-81.737959441369142, 35.616986491943891],
              [-81.738009394547333, 35.616721524689019],
              [-81.737679040979131, 35.616681458536895],
              [-81.737728994916367, 35.616416492275889],
              [-81.737068289766441, 35.616336357567583],
              [-81.737018333718879, 35.616601323563025],
              [-81.736357627765017, 35.61652118499677],
              [-81.736307668177048, 35.61678615071142],
              [-81.735646962492368, 35.61670600736101],
              [-81.735597000497791, 35.616970973671108],
              [-81.734605940166787, 35.616850751986576],
              [-81.73455597464978, 35.617115716957059],
              [-81.733895266907382, 35.617035564119021],
              [-81.733845298953312, 35.6173005287839],
              [-81.73351494508394, 35.617260451322991],
              [-81.733464975748134, 35.617525415815024],
              [-81.733134620016401, 35.617485336446862],
              [-81.733084649298661, 35.617750300766041],
              [-81.732754292868762, 35.617710221268027],
              [-81.732654348343019, 35.618240149520531],
              [-81.732323991202591, 35.61820006793284],
              [-81.732224042156176, 35.618729995784314],
              [-81.731893682158997, 35.618689913958647],
              [-81.731843706611883, 35.618954876778048],
              [-81.731513345886327, 35.61891479392132],
              [-81.73146336898759, 35.619179757468856],
              [-81.731133007503203, 35.619139672680177],
              [-81.731033049511694, 35.619669599414173],
              [-81.730983070547268, 35.61993456180759],
              [-81.730652706254304, 35.619894476623159],
              [-81.730552743058055, 35.620424401974709],
              [-81.730222376951161, 35.620384314725349],
              [-81.730122411440803, 35.620914239626025],
              [-81.729792043580531, 35.620874152113927],
              [-81.72959210328365, 35.621934000026265],
              [-81.729261730395308, 35.621893910208186],
              [-81.729161755638984, 35.622423834120795],
              [-81.728831382070609, 35.622383743114007],
              [-81.728781393131698, 35.622648704426723],
              [-81.728451017761657, 35.622608613314775],
              [-81.728301045818213, 35.623403496613335],
              [-81.728631424384517, 35.623443589025008],
              [-81.728531443534948, 35.623973510387884],
              [-81.728861824538669, 35.624013602167111],
              [-81.728211928706145, 35.627458090235606],
              [-81.728161934612359, 35.627723050917396],
              [-81.728492331791102, 35.627763142732753],
              [-81.728392344703437, 35.628293063340209],
              [-81.728722744350094, 35.628333155424102],
              [-81.728272792326322, 35.63071779337929],
              [-81.728603202872279, 35.630757884834871],
              [-81.728353222963449, 35.632082682059426],
              [-81.728683639113683, 35.632122773280962],
              [-81.728583647867993, 35.632652692494688],
              [-81.728253229576367, 35.63261260010632],
              [-81.72805323667157, 35.633672435765369],
              [-81.727722814484721, 35.633632341947298],
              [-81.727472810122549, 35.634957133608772],
              [-81.727803238691024, 35.634997228066439],
              [-81.727703236977263, 35.635527144348536],
              [-81.727372806297609, 35.635487049625119],
              [-81.727272801164901, 35.636016965479769],
              [-81.726942368731343, 35.635976870493423],
              [-81.726892364067055, 35.636241828239584],
              [-81.726561930874624, 35.636201731320874],
              [-81.726461919557551, 35.63673164640138],
              [-81.72613148458089, 35.636691548318645],
              [-81.725981460300019, 35.637486419373417],
              [-81.725651022513802, 35.637446320894682],
              [-81.72540097018674, 35.638771103310596],
              [-81.724740083904706, 35.638690901452961],
              [-81.724690070334802, 35.638955857188478],
              [-81.724029183249641, 35.638875651471558],
              [-81.7239291509362, 35.639405563190543],
              [-81.723598705205873, 35.63936545828053],
              [-81.723548687487778, 35.639630413495823],
              [-81.72321824102886, 35.639590307554514],
              [-81.723118201426473, 35.640120218523499],
              [-81.722787754287083, 35.640080111393395],
              [-81.722687710130842, 35.640610021058052],
              [-81.722357261207193, 35.640569912763702],
              [-81.722257213661379, 35.641099822901374],
              [-81.721926761849645, 35.641059713467513],
              [-81.721826711957462, 35.641589622251445],
              [-81.721496258361441, 35.641549511653366],
              [-81.721446231316975, 35.641814465863888],
              [-81.721115776992391, 35.641774354234485],
              [-81.721065748565138, 35.642039308271507],
              [-81.720735293511893, 35.641999195610872],
              [-81.720685263671513, 35.642264148573339],
              [-81.720354807889805, 35.642224034881295],
              [-81.720304776696779, 35.642488988571301],
              [-81.719974320186438, 35.642448873848018],
              [-81.719924286506682, 35.642713827389109],
              [-81.719593829267879, 35.642673711634494],
              [-81.719193545002597, 35.644793333710453],
              [-81.718863079644976, 35.644753215994008],
              [-81.718763002918948, 35.645283120846813],
              [-81.718432534673127, 35.645243001990728],
              [-81.718382494196376, 35.645507953785071],
              [-81.717721557678729, 35.645427713086974],
              [-81.717671514763239, 35.64569266457476],
              [-81.715027772172746, 35.645371663003878],
              [-81.714977720483631, 35.645636613387367],
              [-81.714316786258451, 35.645556353287617],
              [-81.714266731056853, 35.645821304290145],
              [-81.713605796029384, 35.645741040330869],
              [-81.713555739462606, 35.646005990101131],
              [-81.713225270817034, 35.645965856202565],
              [-81.713175211763257, 35.64623080582377],
              [-81.712844743523434, 35.646190671770285],
              [-81.7127946830864, 35.646455621217726],
              [-81.712464212984131, 35.646415485256405],
              [-81.712364090120431, 35.646945383738732],
              [-81.71203361826413, 35.646905247514042],
              [-81.711983554733379, 35.647170196573377],
              [-81.711653082118531, 35.647130058416188],
              [-81.711502886364428, 35.647924904050015],
              [-81.711172410939753, 35.647884765496386],
              [-81.711122343987824, 35.648149714126873],
              [-81.710791867804502, 35.648109573640838],
              [-81.710741799469332, 35.648374522097562],
              [-81.710411322557732, 35.648334380580003],
              [-81.71036125283922, 35.648599328862971],
              [-81.71003077409533, 35.64855918633863],
              [-81.709980702963406, 35.648824133546732],
              [-81.709650224625207, 35.648783990867337],
              [-81.709600152110099, 35.649048937901753],
              [-81.709269671939524, 35.649008794215497],
              [-81.708969220385185, 35.650598476643154],
              [-81.708638735280843, 35.650558330334746],
              [-81.708438422357233, 35.651618116995976],
              [-81.708107932253043, 35.65157796928154],
              [-81.708007772251349, 35.652107861638342],
              [-81.707677280392929, 35.652067713660465],
              [-81.707527033080027, 35.652862550626089],
              [-81.706866043980455, 35.652782250275592],
              [-81.706815958785882, 35.653047195883403],
              [-81.706485463656477, 35.653007043776881],
              [-81.706435377078535, 35.653271989210786],
              [-81.706104881220753, 35.653231836072663],
              [-81.706054793229214, 35.65349678043161],
              [-81.705724295539056, 35.653456626286598],
              [-81.705674206194104, 35.653721571372664],
              [-81.705343708879681, 35.653681416171445],
              [-81.705293618151117, 35.653946361083591],
              [-81.704632621289264, 35.653866047744216],
              [-81.704582528121236, 35.654130992349373],
              [-81.703591032639778, 35.654010514288956],
              [-81.70354093487235, 35.654275458478608],
              [-81.702879938167129, 35.654195135597355],
              [-81.702829837930139, 35.65446007857895],
              [-81.700846847104913, 35.654219086720566],
              [-81.700796740204765, 35.654484028862839],
              [-81.700466242197948, 35.654443860417288],
              [-81.700416132840317, 35.654708803311138],
              [-81.700085634075165, 35.654668632932861],
              [-81.700035524437908, 35.654933575628178],
              [-81.699044025897351, 35.65481306052984],
              [-81.698993911630595, 35.6550780019084],
              [-81.698663412094689, 35.65503782857099],
              [-81.698613296444165, 35.655302769775524],
              [-81.69828279615011, 35.655262594505501],
              [-81.698232679146003, 35.655527536437056],
              [-81.697902178123798, 35.655487360135361],
              [-81.697852059705994, 35.655752300991821],
              [-81.697521557985567, 35.655712124559628],
              [-81.697471438184053, 35.655977065242013],
              [-81.697140934631577, 35.655936887802618],
              [-81.697090813446238, 35.656201828310948],
              [-81.696429806286858, 35.656121469544907],
              [-81.69637968266187, 35.656386409746105],
              [-81.696049179054995, 35.656346228419388],
              [-81.695999052942312, 35.656611168470924],
              [-81.695668548637244, 35.656570987013616],
              [-81.69561842224482, 35.656835926866549],
              [-81.695287916077717, 35.656795743501121],
              [-81.695237788301526, 35.657060683179942],
              [-81.694907281406259, 35.657020498782792],
              [-81.694807021654384, 35.657550377775699],
              [-81.694476513005142, 35.657510193114852],
              [-81.694426382103416, 35.657775131503421],
              [-81.694376249799831, 35.658040070776487],
              [-81.694045739366572, 35.657999884950826],
              [-81.693945472737795, 35.658529762182141],
              [-81.693614960490535, 35.658489574290591],
              [-81.693514690468305, 35.659019451992549],
              [-81.69346455438334, 35.659284389893173],
              [-81.693134039325926, 35.65924420160475],
              [-81.693083901857051, 35.659509139331114],
              [-81.692753386041716, 35.659468949109979],
              [-81.692703247218802, 35.659733887563256],
              [-81.692372730675388, 35.659693696310427],
              [-81.692322590468649, 35.659958634589429],
              [-81.690670006286737, 35.65975766420555],
              [-81.690619859337986, 35.660022600901144],
              [-81.68995882629072, 35.65994220618164],
              [-81.689908676932035, 35.66020714347092],
              [-81.689247643084698, 35.660126744890867],
              [-81.689197491256124, 35.660391680971685],
              [-81.688866973768427, 35.660351480200688],
              [-81.688816820555857, 35.660616416107239],
              [-81.688486302340308, 35.660576214304449],
              [-81.688436147743715, 35.660841150036724],
              [-81.68777511018466, 35.660760743469197],
              [-81.687524321750857, 35.66208542108334],
              [-81.687854846750426, 35.662125625457946],
              [-81.687704372903596, 35.662920431391967],
              [-81.688034901399348, 35.662960635267559],
              [-81.68788442776804, 35.663755441230812],
              [-81.688214959760018, 35.663795644607383],
              [-81.688114645581763, 35.664325515285803],
              [-81.688445180013957, 35.664365718030119],
              [-81.688344865501449, 35.664895587933493],
              [-81.688675402373875, 35.66493579004559],
              [-81.688424616751249, 35.666260465175505],
              [-81.688094074598155, 35.66622026239758],
              [-81.687993755492641, 35.666750131724385],
              [-81.688324299757923, 35.666790334768621],
              [-81.688274140754231, 35.667055269052824],
              [-81.688604687507919, 35.66709547130727],
              [-81.68855452926222, 35.667360406584457],
              [-81.688885077400144, 35.667400608073372],
              [-81.688834919852596, 35.667665542541449],
              [-81.688504370658407, 35.667625340919393],
              [-81.688353893982907, 35.668420143652298],
              [-81.687692789137699, 35.668339736937511],
              [-81.687642627109128, 35.668604670841098],
              [-81.686981522568189, 35.668524260240964],
              [-81.68693135809913, 35.668789193836922],
              [-81.68627025165415, 35.668708779400234],
              [-81.686220084744662, 35.668973712688562],
              [-81.685228424891903, 35.668853082980817],
              [-81.685178254485692, 35.669118015828282],
              [-81.684847701179635, 35.66907780428739],
              [-81.68479752828479, 35.669342736984767],
              [-81.68380586603304, 35.669222095694032],
              [-81.683755689641515, 35.669487027950488],
              [-81.682764028249423, 35.669366377246789],
              [-81.682713848361146, 35.669631309062275],
              [-81.681722185680258, 35.669510650796575],
              [-81.681672002295187, 35.669775582171113],
              [-81.681010892962391, 35.669695137374291],
              [-81.680960707136691, 35.669960068441064],
              [-81.680630151384136, 35.669919845474674],
              [-81.680579964144172, 35.670184775465863],
              [-81.679918852586155, 35.670104325645234],
              [-81.679868662935348, 35.670369256229584],
              [-81.679207550548284, 35.670288801646898],
              [-81.679157357352565, 35.670553731947734],
              [-81.678826801147395, 35.670513503163022],
              [-81.67872641162883, 35.671043362473334],
              [-81.678395853669514, 35.671003133424477],
              [-81.67834565734664, 35.671268062433853],
              [-81.67768453916652, 35.671187600496857],
              [-81.677634340402918, 35.671452529198362],
              [-81.677303780182214, 35.671412296310301],
              [-81.677253580063834, 35.671677225738144],
              [-81.676923019115222, 35.671636991818133],
              [-81.676872817582492, 35.671901920170406],
              [-81.676542257010411, 35.671861685194138],
              [-81.676492053018521, 35.672126614297134],
              [-81.675830929642871, 35.672046141406319],
              [-81.675780723180495, 35.672311069300349],
              [-81.675450161481194, 35.672270831361487],
              [-81.675399953634098, 35.672535759080816],
              [-81.675069391207032, 35.672495520110083],
              [-81.675019182005158, 35.672760448555806],
              [-81.67336636506846, 35.672559238751596],
              [-81.673316150257008, 35.672824166489555],
              [-81.672985587658474, 35.672783920959127],
              [-81.672935371462444, 35.673048848522285],
              [-81.672274242959631, 35.672968355448504],
              [-81.672224024292873, 35.673233281802624],
              [-81.671562895021367, 35.6731527857686],
              [-81.671512673913796, 35.673417711814665],
              [-81.670520980376239, 35.673296959698455],
              [-81.670571204652418, 35.673032034052326],
              [-81.66991007974066, 35.672951528106395],
              [-81.669960306904912, 35.672686602661308],
              [-81.669629745460767, 35.67264634893629],
              [-81.669679973352657, 35.672381423583126],
              [-81.668357737235141, 35.672220398379501],
              [-81.668307505118619, 35.672485323199375],
              [-81.667976945287577, 35.67244506408008],
              [-81.668027178460264, 35.672180139393625],
              [-81.667696621147911, 35.672139880385835],
              [-81.667746855018478, 35.671874954890292],
              [-81.667416297986605, 35.67183469514152],
              [-81.667466532614398, 35.671569770638996],
              [-81.665813761962838, 35.671368455381213],
              [-81.665763522084063, 35.671633380117932],
              [-81.665432968098429, 35.671593114052094],
              [-81.665382725700908, 35.671858037737216],
              [-81.665052170987778, 35.671817770639322],
              [-81.665001928309863, 35.672082694125571],
              [-81.664671371794554, 35.672042426920918],
              [-81.664621127731849, 35.672307350232323],
              [-81.664290571563782, 35.67226708107048],
              [-81.664240325012031, 35.672532004231265],
              [-81.663909768116511, 35.672491734037415],
              [-81.663859520179855, 35.672756657023328],
              [-81.663198404158308, 35.672676113697122],
              [-81.663248654207123, 35.672411190977897],
              [-81.662918098297638, 35.672370918088063],
              [-81.662587541612581, 35.672330644323765],
              [-81.662637793444944, 35.672065721829917],
              [-81.66230723924896, 35.672025447276319],
              [-81.66235749180872, 35.671760524874465],
              [-81.661035282536247, 35.671599418207279],
              [-81.660985024647772, 35.671864340099653],
              [-81.660323919871772, 35.671783781107514],
              [-81.660273660646723, 35.67204870266751],
              [-81.658620898045513, 35.671847287917444],
              [-81.658570633211284, 35.672112208768873],
              [-81.657909527679891, 35.672031635785515],
              [-81.657859260404592, 35.672296556328675],
              [-81.657198154105842, 35.672215980385033],
              [-81.657147883285418, 35.672480900643983],
              [-81.656817330095748, 35.672440610277725],
              [-81.65676705789032, 35.672705530361796],
              [-81.656436504002968, 35.67266523986445],
              [-81.656335956464062, 35.673195078740392],
              [-81.656666512493132, 35.673235370405628],
              [-81.65651569190149, 35.674030129259435],
              [-81.656846251427751, 35.674070420426467],
              [-81.656795978275909, 35.674335339494235],
              [-81.657126540291429, 35.674375629871903],
              [-81.656975722060807, 35.675170388127782],
              [-81.656645156906436, 35.675130098250747],
              [-81.65659488243918, 35.675395017152589],
              [-81.656264316557426, 35.675354726243548],
              [-81.656214040705166, 35.675619644970354],
              [-81.6558834740962, 35.675579353029221],
              [-81.655732640322938, 35.67637410948565],
              [-81.655402070874274, 35.676333816245474],
              [-81.655301510880818, 35.676863652815946],
              [-81.654970939648791, 35.676823358410175],
              [-81.654820093470988, 35.677618112565959],
              [-81.654489519399306, 35.677577816861159],
              [-81.654439235257925, 35.677842734704591],
              [-81.654108660429429, 35.677802437066596],
              [-81.654058374902903, 35.678067354734992],
              [-81.653727799376682, 35.678027056965824],
              [-81.653677512464924, 35.678291974459214],
              [-81.653346936181904, 35.678251674756943],
              [-81.653296647885071, 35.678516592075255],
              [-81.653627225224284, 35.678556891911079],
              [-81.653576938759059, 35.678821809297155],
              [-81.654238097611724, 35.678902405614473],
              [-81.654187811825665, 35.679167323250113],
              [-81.655179556277702, 35.679288210923701],
              [-81.65512927333144, 35.679553128918073],
              [-81.656121025017015, 35.679674008879672],
              [-81.656070744910608, 35.679938927232811],
              [-81.657062502725168, 35.680059799506438],
              [-81.657012226533396, 35.680324717293132],
              [-81.658003990476843, 35.680445581878587],
              [-81.657953717154726, 35.680710500925052],
              [-81.658614896854218, 35.680791072487935],
              [-81.658564625315933, 35.681055991759642],
              [-81.658895216715308, 35.681096276326393],
              [-81.65884494587479, 35.681361194788934],
              [-81.659175539763723, 35.681401478566237],
              [-81.659024728497243, 35.682196235906304],
              [-81.658694132543587, 35.682155951704537],
              [-81.658643860387627, 35.682420870000499],
              [-81.65897445739752, 35.682461154335684],
              [-81.658873914241283, 35.682990991970513],
              [-81.658543315118663, 35.682950707368505],
              [-81.658442767429662, 35.683480544594033],
              [-81.658112166523182, 35.683440258826501],
              [-81.658061891666435, 35.683705176780748],
              [-81.657731288928048, 35.683664890005069],
              [-81.657681012686012, 35.683929807784232],
              [-81.657019806083099, 35.683849231269917],
              [-81.656969526294986, 35.684114148764671],
              [-81.656638923505426, 35.684073858100675],
              [-81.656588642361527, 35.684338776321347],
              [-81.655927433477089, 35.684258192979591],
              [-81.655877150966447, 35.684523109966527],
              [-81.65554654597598, 35.684482817264644],
              [-81.655496260975482, 35.684747734100533],
              [-81.65516565525769, 35.684707440366424],
              [-81.655115368871819, 35.684972357027199],
              [-81.654784762396957, 35.684932061359731],
              [-81.654734475730152, 35.685196977821214],
              [-81.654073261684331, 35.685116384424227],
              [-81.654022971471306, 35.685381300601179],
              [-81.653692363900262, 35.685341002871645],
              [-81.653642071197382, 35.685605918897537],
              [-81.65331146286934, 35.685565619234723],
              [-81.653261169885496, 35.685830535061314],
              [-81.652930560859673, 35.685790235267241],
              [-81.652880266460869, 35.686055150017644],
              [-81.652549656707663, 35.686014849191324],
              [-81.652449063735105, 35.686544679224987],
              [-81.652118452198124, 35.68650437723295],
              [-81.652068153624441, 35.686769292516303],
              [-81.651737541330476, 35.686728988590971],
              [-81.65158664264527, 35.687523732840738],
              [-81.651256026406614, 35.687483427640252],
              [-81.651205725508262, 35.687748342465447],
              [-81.650875108542351, 35.687708036232777],
              [-81.650824806228968, 35.68797294998167],
              [-81.650494188565204, 35.687932643617692],
              [-81.650443883761696, 35.688197557215375],
              [-81.649782648383578, 35.688116940599024],
              [-81.649732341138176, 35.688381853887925],
              [-81.648740486802993, 35.688260921383488],
              [-81.648690176059119, 35.688525834230134],
              [-81.648359557200749, 35.688485522072966],
              [-81.648309245071289, 35.688750434744279],
              [-81.647978626560629, 35.688710120629636],
              [-81.647877998126205, 35.689239946504927],
              [-81.647547376727331, 35.689199631248613],
              [-81.647497061497475, 35.68946454352745],
              [-81.647166439371304, 35.68942422723881],
              [-81.647116122755804, 35.689689139342335],
              [-81.646454877378119, 35.689608503801537],
              [-81.646404558349957, 35.689873416497221],
              [-81.645743312176066, 35.689792777094169],
              [-81.645692989571799, 35.690057688603964],
              [-81.645031742601873, 35.689977045338694],
              [-81.644981418659981, 35.690241956515564],
              [-81.64465079461246, 35.690201633401117],
              [-81.644600468180329, 35.690466544426592],
              [-81.644269843405581, 35.690426220279811],
              [-81.644169187411094, 35.690956041037154],
              [-81.643838560852686, 35.690915715724479],
              [-81.643788231319817, 35.691180626357308],
              [-81.642465723048531, 35.691019315608528],
              [-81.642415388930985, 35.691284224764232],
              [-81.641754135210448, 35.691203563717828],
              [-81.641703797575602, 35.691468473489586],
              [-81.641373170123359, 35.69142814058349],
              [-81.641423508844156, 35.69116323184646],
              [-81.641092882777713, 35.691122898175145],
              [-81.641143221121112, 35.690857989553677],
              [-81.640812596440469, 35.690817655117122],
              [-81.640862936586032, 35.690552745662316],
              [-81.640532313320662, 35.690512411361631],
              [-81.640481972118636, 35.690777320682884],
              [-81.640151348096765, 35.690736984448804],
              [-81.640101005508967, 35.691001893594589],
              [-81.639439757474221, 35.690921219039879],
              [-81.639389412444146, 35.69118612787662],
              [-81.63905878732686, 35.691145789580027],
              [-81.638958094136299, 35.691675605959531],
              [-81.638627467235466, 35.691635266496981],
              [-81.638577119074981, 35.691900174039809],
              [-81.638246490342453, 35.691859833568799],
              [-81.637944387106614, 35.693449279871118],
              [-81.637613753439894, 35.69340893677473],
              [-81.637563400889888, 35.693673844657958],
              [-81.637232765391545, 35.693633500553105],
              [-81.637031345373799, 35.694693128476857],
              [-81.637361985098295, 35.694733473116074],
              [-81.63726127521106, 35.695263286642827],
              [-81.636930633373566, 35.695222941736375],
              [-81.636880277431558, 35.695487848291215],
              [-81.63654963489634, 35.695447503253384],
              [-81.636499277568319, 35.695712409632613],
              [-81.636168634276672, 35.695672062661217],
              [-81.636118274457942, 35.695936968888851],
              [-81.63578763046857, 35.695896621786034],
              [-81.635636546941299, 35.696691338890936],
              [-81.633983314895985, 35.69648958521087],
              [-81.633932947419979, 35.696754490468699],
              [-81.632941008500566, 35.696633426001817],
              [-81.632890637525321, 35.696898330816758],
              [-81.632559991189609, 35.69685797405517],
              [-81.632509618828166, 35.697122878694458],
              [-81.631848323927755, 35.697042162231256],
              [-81.631898699506849, 35.69677725783535],
              [-81.628592260869539, 35.696373622805446],
              [-81.628541874725116, 35.696638525864742],
              [-81.627880588811053, 35.696557786904251],
              [-81.627830200223841, 35.696822689654169],
              [-81.627499556719414, 35.696782319142464],
              [-81.627449165612248, 35.697047220839558],
              [-81.627118521380993, 35.697006849295335],
              [-81.627068130021456, 35.697271751693819],
              [-81.626076196107007, 35.697150630366082],
              [-81.626126590636218, 35.69688572836867],
              [-81.625795947723688, 35.696845353229143],
              [-81.625846344084181, 35.696580451299447],
              [-81.62253994572356, 35.696176647252308],
              [-81.622489538768988, 35.69644154694376],
              [-81.621828261603966, 35.696360775066765],
              [-81.621878670642289, 35.696095874741644],
              [-81.621217396909316, 35.696015099536851],
              [-81.62116698462431, 35.696279998717088],
              [-81.620836347763017, 35.696239610071359],
              [-81.620785934091728, 35.696504509075901],
              [-81.62045529650382, 35.696464119397625],
              [-81.62040488144622, 35.696729018226343],
              [-81.619743603983977, 35.69664823412743],
              [-81.619693186512777, 35.696913133547653],
              [-81.618039991690694, 35.696711156938022],
              [-81.617989568607314, 35.69697605564739],
              [-81.617658929355201, 35.696935657500028],
              [-81.617608504856335, 35.697200555132468],
              [-81.617277865982274, 35.697160155928685],
              [-81.617227439021562, 35.69742505431018],
              [-81.616566159045675, 35.697344252962445],
              [-81.616162718822466, 35.699463430856476],
              [-81.615832070325268, 35.699423027775971],
              [-81.615731204842263, 35.699952821110216],
              [-81.615400553428231, 35.699912415986006],
              [-81.615299684512394, 35.700442208884034],
              [-81.614969032449082, 35.700401803470776],
              [-81.614868158995577, 35.70093159595632],
              [-81.614817721773804, 35.701196492135828],
              [-81.613495101903681, 35.701034859035154],
              [-81.613444660125751, 35.701299754637319],
              [-81.612452694959217, 35.701178519758898],
              [-81.612503139906835, 35.700913624558162],
              [-81.611511181985037, 35.700792381967851],
              [-81.611460733867943, 35.701057276767145],
              [-81.611130080075171, 35.701016860053997],
              [-81.61102917963305, 35.701546649282122],
              [-81.610698525162007, 35.701506231378758],
              [-81.610496714956753, 35.702565807915995],
              [-81.610166055484868, 35.702525388602155],
              [-81.610115601887514, 35.702790282704612],
              [-81.609784941689313, 35.70274986235809],
              [-81.609684029152774, 35.703279649314887],
              [-81.609353367171494, 35.703239227801831],
              [-81.609000155454211, 35.705093478531801],
              [-81.608669486436114, 35.705053056084061],
              [-81.608417176018136, 35.706377517690207],
              [-81.608086502018111, 35.706337092773339],
              [-81.60793510819326, 35.707131768837328],
              [-81.607604430248742, 35.707091342643693],
              [-81.607553964291654, 35.707356234438627],
              [-81.60656192929838, 35.707234950963631],
              [-81.606460988919906, 35.707764732746064],
              [-81.606791669043631, 35.707805162038099],
              [-81.606589788550281, 35.7088647256302],
              [-81.606259103124088, 35.708824296727471],
              [-81.606158158782534, 35.709354078001724],
              [-81.605827472648798, 35.709313647007718],
              [-81.605776998359133, 35.709578537008682],
              [-81.606107685549475, 35.709618968136553],
              [-81.605956265002476, 35.710413639164081],
              [-81.606286956798044, 35.710454069770982],
              [-81.60623648330062, 35.710718959870093],
              [-81.606567176483196, 35.710759389711818],
              [-81.606516703712245, 35.711024279902453],
              [-81.60684739825281, 35.71106470807802],
              [-81.606746453862627, 35.711594489500605],
              [-81.607077150847076, 35.711634917044968],
              [-81.606925736045412, 35.712429588788005],
              [-81.607256436530349, 35.712470015834917],
              [-81.607155494140429, 35.712999796443768],
              [-81.60748619709824, 35.713040223760437],
              [-81.60738525547184, 35.713570003566169],
              [-81.607715960873563, 35.713610430251521],
              [-81.607665491188698, 35.713875320663206],
              [-81.607996197948495, 35.713915745682264],
              [-81.608657613592783, 35.713996593900795],
              [-81.608607145643347, 35.714261484695172],
              [-81.608937855555581, 35.714301907127435],
              [-81.608887389437584, 35.714566797989498],
              [-81.609218099632059, 35.714607219680246],
              [-81.609167634240677, 35.714872110633564],
              [-81.609498346927111, 35.71491253153544],
              [-81.609447881157521, 35.715177422603837],
              [-81.609778595231148, 35.715217842740508],
              [-81.609728131292968, 35.715482733876463],
              [-81.610058846753631, 35.715523153247901],
              [-81.609907454994826, 35.716317825925152],
              [-81.610238173956404, 35.716358244798897],
              [-81.610036318231181, 35.717417807699903],
              [-81.610697766705357, 35.717498643797043],
              [-81.610647304324033, 35.71776353422716],
              [-81.611308756262204, 35.717844367896753],
              [-81.611258295664484, 35.71810925855192],
              [-81.61158902261883, 35.718149673733237],
              [-81.611538562748052, 35.718414564479573],
              [-81.611869292194484, 35.718454978871982],
              [-81.61181883307944, 35.718719870610599],
              [-81.61214956280817, 35.718760284261357],
              [-81.612099104390836, 35.719025175190154],
              [-81.611998187670437, 35.71955495689631],
              [-81.611667453665802, 35.719514542867877],
              [-81.61156653240559, 35.720044324159829],
              [-81.611235796617137, 35.720003908964735],
              [-81.611134871892887, 35.720533688917733],
              [-81.61080413432056, 35.720493272555871],
              [-81.610652740524884, 35.721287943139593],
              [-81.610322000082817, 35.72124752457627],
              [-81.610271533030058, 35.721512414567577],
              [-81.609940791890097, 35.721471995872413],
              [-81.609839855755553, 35.722001774534782],
              [-81.609509112831844, 35.721961354672899],
              [-81.609458642674227, 35.722226244268711],
              [-81.609127898994601, 35.722185822472767],
              [-81.609026956650055, 35.72271560034411],
              [-81.608696210110793, 35.72267517830619],
              [-81.608645737952983, 35.722940067482696],
              [-81.606992007078091, 35.722737941287903],
              [-81.607042485626195, 35.722473052756804],
              [-81.605719512636071, 35.722311335758675],
              [-81.605769995111018, 35.722046448621306],
              [-81.604777771717707, 35.721925151389065],
              [-81.604828258138298, 35.721660264586923],
              [-81.603836041994654, 35.721538959640654],
              [-81.603505302826306, 35.721498522583708],
              [-81.603555793115532, 35.721233635373302],
              [-81.603225056468546, 35.721193198428558],
              [-81.603275547484344, 35.720928311309386],
              [-81.602614077266651, 35.720847434089251],
              [-81.602664570065883, 35.720582547195072],
              [-81.601010906650671, 35.720380337705151],
              [-81.60106140440439, 35.720115451437898],
              [-81.600730673542813, 35.720075006801068],
              [-81.60078117202309, 35.719810120625006],
              [-81.600450443653855, 35.71976967519938],
              [-81.60050094286062, 35.719504789114644],
              [-81.600170214773854, 35.719464342947475],
              [-81.600220715840834, 35.719199457831401],
              [-81.599889989141573, 35.71915901089907],
              [-81.599940489801128, 35.718894124996829],
              [-81.599279041717693, 35.718813228655719],
              [-81.599329544160582, 35.718548342978679],
              [-81.598006655053339, 35.718386539166161],
              [-81.597956148382892, 35.718651424307545],
              [-81.597625426158942, 35.718610971192234],
              [-81.59752440750286, 35.719140741126623],
              [-81.597193683466756, 35.719100285943398],
              [-81.597143172586001, 35.719365170712777],
              [-81.596150998243218, 35.719243801192761],
              [-81.59620151229457, 35.718978916825229],
              [-81.595870789609592, 35.718938458946944],
              [-81.595820274501364, 35.719203343180546],
              [-81.59515882798037, 35.719122423557913],
              [-81.59510831042769, 35.719387307481028],
              [-81.594777585515757, 35.719346846661317],
              [-81.59472706654671, 35.719611729506795],
              [-81.593073446023567, 35.719409409356572],
              [-81.593123970248143, 35.719144526279798],
              [-81.592793248424812, 35.719104060042284],
              [-81.592843774480514, 35.718839177033296],
              [-81.592513052910903, 35.718798709153283],
              [-81.592614106172988, 35.71826894417643],
              [-81.592283388152637, 35.718228475641702],
              [-81.592333914225222, 35.71796359279633],
              [-81.59167248242386, 35.717882654175398],
              [-81.591723010279367, 35.717617771555368],
              [-81.591392295364614, 35.717577300591614],
              [-81.591442825051274, 35.717312418039356],
              [-81.590781399461108, 35.717231474560343],
              [-81.590450686595162, 35.71719100103352],
              [-81.590400153738003, 35.717455883183838],
              [-81.590069441250918, 35.717415408600488],
              [-81.590018907006353, 35.717680290574137],
              [-81.589688192688186, 35.717639814981403],
              [-81.589637657056116, 35.717904696778454],
              [-81.589306942011817, 35.717864220152642],
              [-81.589256404992256, 35.718129101773172],
              [-81.588925689221853, 35.718088624114365],
              [-81.588875150814744, 35.718353505558213],
              [-81.588213718152403, 35.718272547275724],
              [-81.588163177300942, 35.718537428408943],
              [-81.587501743847994, 35.71845646626236],
              [-81.587451200552167, 35.718721347084916],
              [-81.586459049682986, 35.718599896720484],
              [-81.586408501752231, 35.718864776220826],
              [-81.585747068484523, 35.718783804524634],
              [-81.58569651813815, 35.719048684615402],
              [-81.585365800391259, 35.719008197296453],
              [-81.585315248628504, 35.719273076309435],
              [-81.584984530155609, 35.719232587957528],
              [-81.584933977033984, 35.719497467694872],
              [-81.584603257806322, 35.719456977408889],
              [-81.5845021484282, 35.719986735586438],
              [-81.584171427446478, 35.719946245034414],
              [-81.584120870113793, 35.720211124399142],
              [-81.583790148377318, 35.720170631913028],
              [-81.58373959076188, 35.720435511077504],
              [-81.5824167018785, 35.720273534333224],
              [-81.582085980739535, 35.720233037217952],
              [-81.58213654477278, 35.719968159601279],
              [-81.581805825021533, 35.719927661721002],
              [-81.581856389780697, 35.719662784195677],
              [-81.581194953413217, 35.719581786907398],
              [-81.581245518850167, 35.719316909630976],
              [-81.579591940096762, 35.719114400874162],
              [-81.579642511562909, 35.71884952330096],
              [-81.578319660081377, 35.718687500982398],
              [-81.578370235443614, 35.718422623902818],
              [-81.577708813802118, 35.718341607617781],
              [-81.577759390946881, 35.718076730763883],
              [-81.576436556938589, 35.717914687018101],
              [-81.576487137979299, 35.717649810657882],
              [-81.575825725612674, 35.717568783197201],
              [-81.575775141353631, 35.717833659312575],
              [-81.573790903648003, 35.717590554597649],
              [-81.573841494247262, 35.717325679287043],
              [-81.572849384138436, 35.717204115172699],
              [-81.572899977576668, 35.716939240221791],
              [-81.572569275925886, 35.716898717186623],
              [-81.572619871194618, 35.716633842303935],
              [-81.572289170931569, 35.716593318503904],
              [-81.57239036040788, 35.716063569826701],
              [-81.571728966177858, 35.715982519142109],
              [-81.571779562518415, 35.715717644557344],
              [-81.571448866418535, 35.715677118463056],
              [-81.571499464589508, 35.715412243946496],
              [-81.57083807546762, 35.71533118842833],
              [-81.570888675420733, 35.715066314137481],
              [-81.569896598510056, 35.714944724948069],
              [-81.569947201301886, 35.714679851017145],
              [-81.56928582082233, 35.714598787023192],
              [-81.569235217022197, 35.714863660662431],
              [-81.568573835753867, 35.714782592804497],
              [-81.568523228404558, 35.715047466156179],
              [-81.568192536672441, 35.715006931206837],
              [-81.5681419279354, 35.715271804381651],
              [-81.567811236554121, 35.715231267474877],
              [-81.567760626429333, 35.715496140472872],
              [-81.567099241470814, 35.715415064618526],
              [-81.567048628901659, 35.715679937305524],
              [-81.565064475577927, 35.715436685561478],
              [-81.565013855233175, 35.715701557423962],
              [-81.564021780439717, 35.715579919452949],
              [-81.563971156594093, 35.715844790869987],
              [-81.562979080478854, 35.715723143528386],
              [-81.562928453132045, 35.715988014500162],
              [-81.561605686126626, 35.715825804907624],
              [-81.561656318804481, 35.715560934449563],
              [-81.56099493940259, 35.71547982452838],
              [-81.560002873308733, 35.715358151992248],
              [-81.560053510938005, 35.715093282162819],
              [-81.559392136962458, 35.715012163521266],
              [-81.559290858251316, 35.715541902577151],
              [-81.558960170171588, 35.71550134072686],
              [-81.558909528709904, 35.715766210068189],
              [-81.558578839933404, 35.715725648085801],
              [-81.558376266490285, 35.716785122685422],
              [-81.558045573818958, 35.716744559266971],
              [-81.557994928558884, 35.717009427225371],
              [-81.557664234057512, 35.716968862797039],
              [-81.557613587437942, 35.717233731479553],
              [-81.556290810673005, 35.717071462391409],
              [-81.556240158362414, 35.717336329616217],
              [-81.553594612289615, 35.7170117472184],
              [-81.553543951195152, 35.717276613326142],
              [-81.552882565878704, 35.717195458469014],
              [-81.552831902339648, 35.717460324265396],
              [-81.552501209688813, 35.717419745793116],
              [-81.552450544761712, 35.717684611412416],
              [-81.552119850252524, 35.717644031029096],
              [-81.552170516236188, 35.717379165544145],
              [-81.551839824219982, 35.717338584373081],
              [-81.551941157305649, 35.716808853543661],
              [-81.551610467734022, 35.716768271742389],
              [-81.551661134807716, 35.71650340549693],
              [-81.551330446624149, 35.716462822931064],
              [-81.551381114451118, 35.716197957678311],
              [-81.551050427655682, 35.716157374347972],
              [-81.551151764399705, 35.715627643082072],
              [-81.550821080048706, 35.715587059121468],
              [-81.550490396029247, 35.715546474262084],
              [-81.550541066031144, 35.715281609284347],
              [-81.549879702204606, 35.715200437114447],
              [-81.549930373959484, 35.714935571461702],
              [-81.549599693047625, 35.714894984174435],
              [-81.549650366660572, 35.71463011949119],
              [-81.549319687136816, 35.714589531439437],
              [-81.549269012467335, 35.714854395988226],
              [-81.548607653406236, 35.714773216896226],
              [-81.548556976320754, 35.715038082034759],
              [-81.548226295678646, 35.714997491017648],
              [-81.548175618281533, 35.715262355054875],
              [-81.547844936914686, 35.715221763004628],
              [-81.547794257052999, 35.715486627789033],
              [-81.547463574961299, 35.715446034705536],
              [-81.547412893683187, 35.715710898411821],
              [-81.547082210866648, 35.715670304295088],
              [-81.547031528200549, 35.715935167824306],
              [-81.544386070203899, 35.715610379631364],
              [-81.544335377677996, 35.715875242966931],
              [-81.543674014092261, 35.715794036216678],
              [-81.543623320198265, 35.716058898316582],
              [-81.54097787177777, 35.715734034263143],
              [-81.541028575228154, 35.715469173215531],
              [-81.540036541361729, 35.71534733340193],
              [-81.540087247649438, 35.715082472714919],
              [-81.539756572150523, 35.71504185776508],
              [-81.539807279162758, 35.714776997169835],
              [-81.539476605052016, 35.714736381455687],
              [-81.539527312788934, 35.714471520952216],
              [-81.539196640066336, 35.714430904473687],
              [-81.539247348499487, 35.714166043160844],
              [-81.538916677165062, 35.714125425917956],
              [-81.538967386351132, 35.713860565598011],
              [-81.538636716404852, 35.713819947590714],
              [-81.538586006190698, 35.71408480867732],
              [-81.538255334415012, 35.714044189659894],
              [-81.53820462278442, 35.714309049668231],
              [-81.537543279220657, 35.714227808644843],
              [-81.537391133846711, 35.715022389455235],
              [-81.53772180807934, 35.71506301083128],
              [-81.537671092960238, 35.715327870557459],
              [-81.537340417671231, 35.715287249046966],
              [-81.53708683297765, 35.716611548144037],
              [-81.536756151633639, 35.716570925085463],
              [-81.536705432410642, 35.716835784286424],
              [-81.536374750342105, 35.716795160194543],
              [-81.536273308787671, 35.71732487819925],
              [-81.535942624938158, 35.717284252939578],
              [-81.535841178838439, 35.717813970527381],
              [-81.535510493207966, 35.717773344099911],
              [-81.53545976915629, 35.718038202683694],
              [-81.535129082801276, 35.717997575222888],
              [-81.535078357361257, 35.718262433629413],
              [-81.534416982429207, 35.718181175765203],
              [-81.534366254544381, 35.718446033860005],
              [-81.532712819370175, 35.718242871861698],
              [-81.532763553641985, 35.717978014416403],
              [-81.530779450029726, 35.717734190415072],
              [-81.530728709419378, 35.71799904705329],
              [-81.530067342082461, 35.717917764626065],
              [-81.530016599027277, 35.71818262095244],
              [-81.529685914814536, 35.718141978706598],
              [-81.529635169237849, 35.718406833977582],
              [-81.529304484300738, 35.718366190698362],
              [-81.529253738468682, 35.718631046670119],
              [-81.528923052779064, 35.718590401456474],
              [-81.528872304453614, 35.718855257273852],
              [-81.52854161803964, 35.718814611026822],
              [-81.528490868325804, 35.719079466666834],
              [-81.528160181187403, 35.719038819386355],
              [-81.528109431189918, 35.719303674826151],
              [-81.527448054719912, 35.719222378223634],
              [-81.527397301144433, 35.719487232473334],
              [-81.527066612917693, 35.719446583128288],
              [-81.527117367521441, 35.719181728112069],
              [-81.526455994148961, 35.71910042609364],
              [-81.526506750533315, 35.718835571303735],
              [-81.525514696120581, 35.718713611487871],
              [-81.524191961544034, 35.718550985515982],
              [-81.524242724991055, 35.718286131625263],
              [-81.523912044331595, 35.718245472771656],
              [-81.523962808502716, 35.717980618972703],
              [-81.52098670039328, 35.717614650192132],
              [-81.521037474844206, 35.717349797538851],
              [-81.520706798514524, 35.717309129989147],
              [-81.520757573689636, 35.717044277427696],
              [-81.520426899853234, 35.717003609091002],
              [-81.520477675752431, 35.716738756621375],
              [-81.520147003304629, 35.716698087520413],
              [-81.520248556218817, 35.716168382722046],
              [-81.519256546073606, 35.716046369956182],
              [-81.519205766613737, 35.71631122197288],
              [-81.518213756289882, 35.716189200714055],
              [-81.518162973329026, 35.716454052284099],
              [-81.517170962826839, 35.716332022532406],
              [-81.517120176336761, 35.716596872754664],
              [-81.516789506155362, 35.716556194531314],
              [-81.516738717199829, 35.716821045500026],
              [-81.516077374588505, 35.716739685209376],
              [-81.516026583188108, 35.717004535865996],
              [-81.515365240870452, 35.716923170787048],
              [-81.515314447025176, 35.71718802113157],
              [-81.514322431795591, 35.717065967292051],
              [-81.513330419557221, 35.716943905363642],
              [-81.513381220844963, 35.716679055804335],
              [-81.510405213739716, 35.716312820849708],
              [-81.510456024229086, 35.716047973360865],
              [-81.508141379960946, 35.715763072921824],
              [-81.50809056210619, 35.716027920368639],
              [-81.506767911790249, 35.715865099410337],
              [-81.506717088245338, 35.716129945397185],
              [-81.505063778387125, 35.715926398512089],
              [-81.50501294922887, 35.716191243782283],
              [-81.504351624628327, 35.716109818846149],
              [-81.50430079302518, 35.716374663804089],
              [-81.502316822994985, 35.716130363868771],
              [-81.502367660907325, 35.715865518818447],
              [-81.501706342705532, 35.715784078578757],
              [-81.501757183502335, 35.715519233732493],
              [-81.501095868743363, 35.715437790167456],
              [-81.501197552458876, 35.714908100908367],
              [-81.50086689822902, 35.71486737758552],
              [-81.500968582754027, 35.714337689325937],
              [-81.50063793096902, 35.714296965373876],
              [-81.500739616247714, 35.713767276311664],
              [-81.500408966907628, 35.713726551730588],
              [-81.500459810656565, 35.713461707258688],
              [-81.500129162705207, 35.713420981913714],
              [-81.500180007177562, 35.713156137533922],
              [-81.499849360614832, 35.713115411425044],
              [-81.499798515114335, 35.713380256571114],
              [-81.499137220874815, 35.713298801387623],
              [-81.499086372901601, 35.713563645320221],
              [-81.498755725224854, 35.713522916245672],
              [-81.498704875862984, 35.71378776000067],
              [-81.498043579395315, 35.713706298885839],
              [-81.497992727588624, 35.713971142328433],
              [-81.497331430339742, 35.713889677349123],
              [-81.497280576088102, 35.714154520479269],
              [-81.496619278030082, 35.714073050734548],
              [-81.49667013442162, 35.713808208775127],
              [-81.495678193012225, 35.713685998272801],
              [-81.495627333480414, 35.713950840728771],
              [-81.493974099191576, 35.71374713851101],
              [-81.493923232941896, 35.714011980272659],
              [-81.493592587109148, 35.71397123625465],
              [-81.493541718365663, 35.714236077861266],
              [-81.493211071837436, 35.714195333710883],
              [-81.493160201705095, 35.714460175139735],
              [-81.492168258683861, 35.714337935134914],
              [-81.492219130907188, 35.714073095034351],
              [-81.491888484577643, 35.714032346410697],
              [-81.491939357496307, 35.713767505501153],
              [-81.491608713688336, 35.713726756992259],
              [-81.49165958733019, 35.713461916174772],
              [-81.491328943778356, 35.713421166023565],
              [-81.491379819275977, 35.713156326176644],
              [-81.49071853528288, 35.713074823448096],
              [-81.490667658805776, 35.713339663903703],
              [-81.49033701628025, 35.713298911957644],
              [-81.490235259082922, 35.713828591592225],
              [-81.48990461477824, 35.713787838477693],
              [-81.489853735190508, 35.714052678535126],
              [-81.489523090134995, 35.71401192348587],
              [-81.489573971883615, 35.713747083540731],
              [-81.489243328244626, 35.713706328628859],
              [-81.489294210716352, 35.713441488775814],
              [-81.488632927598601, 35.713359974701078],
              [-81.488683810744533, 35.71309513509761],
              [-81.48835317132118, 35.713054377736995],
              [-81.488302286014388, 35.713319217228111],
              [-81.482681440602292, 35.712626187149766],
              [-81.482630535906424, 35.712891024326716],
              [-81.481638629064832, 35.712768696630668],
              [-81.481587720868148, 35.713033533359919],
              [-81.480926449921213, 35.712951976483986],
              [-81.480875538174743, 35.713216812922994],
              [-81.480544902158783, 35.713176033452669],
              [-81.480493990100555, 35.713440868790286],
              [-81.480163352256881, 35.713400088308852],
              [-81.480112438837239, 35.713664924369745],
              [-81.479781800243131, 35.713624141953616],
              [-81.47957813677256, 35.714683484372785],
              [-81.479247494287677, 35.714642700518077],
              [-81.479145658427967, 35.715172370750139],
              [-81.478815014191994, 35.715131586627848],
              [-81.478764094140175, 35.715396421105197],
              [-81.478433449181367, 35.715355635949287],
              [-81.47833160707134, 35.715885304483074],
              [-81.478000960333645, 35.71584451815842],
              [-81.473371945136321, 35.715273406154544],
              [-81.473422883854468, 35.715008573900725],
              [-81.473092243143441, 35.714967773330258],
              [-81.473143182556726, 35.714702940267493],
              [-81.47281254433976, 35.714662138911052],
              [-81.472863484503321, 35.714397306841576],
              [-81.472532846570644, 35.714356504743932],
              [-81.472634727982111, 35.713826839845389],
              [-81.472304093599377, 35.713786037096845],
              [-81.472355034874809, 35.713521205168902],
              [-81.472024401881228, 35.713480401656717],
              [-81.472126285489153, 35.712950736140208],
              [-81.471795654940493, 35.712909931999548],
              [-81.471846597327925, 35.71264510021318],
              [-81.47151596816839, 35.712604295309049],
              [-81.471566911250861, 35.712339462713821],
              [-81.47123628348055, 35.712298657046169],
              [-81.471287228390437, 35.712033824520724],
              [-81.470956602009153, 35.711993018089451],
              [-81.471007546536725, 35.71172818567873],
              [-81.470676921544651, 35.711687378484015],
              [-81.470727867899498, 35.711422546143027],
              [-81.470397244296535, 35.711381738184791],
              [-81.470448190269153, 35.711116905958605],
              [-81.470117569160124, 35.711076097214431],
              [-81.47016851585515, 35.710811265080494],
              [-81.469507275643736, 35.710729645188934],
              [-81.469558225221874, 35.710464813259954],
              [-81.469227606092005, 35.710424001211578],
              [-81.469278556392695, 35.710159169374869],
              [-81.468947939784115, 35.710118357441615],
              [-81.468998889702434, 35.709853525719659],
              [-81.468668274483036, 35.709812713022984],
              [-81.468719226228615, 35.709547881370916],
              [-81.468388612370717, 35.709507067009611],
              [-81.468439563733881, 35.709242235472303],
              [-81.468108951292521, 35.709201421248721],
              [-81.468159904482917, 35.708936589781302],
              [-81.467829293403241, 35.708895773893197],
              [-81.467880247315932, 35.708630942518049],
              [-81.46754963765278, 35.708590126767596],
              [-81.467600591155659, 35.70832529460619],
              [-81.466939376018132, 35.708243659756356],
              [-81.466990332431266, 35.70797882870103],
              [-81.466659725879524, 35.707938010525169],
              [-81.466710682987639, 35.707673178661238],
              [-81.466049474072861, 35.707591538960671],
              [-81.466100432986423, 35.707326708225239],
              [-81.465769829518578, 35.707285886722929],
              [-81.465718869576861, 35.707550718224283],
              [-81.465388266491601, 35.70750989566595],
              [-81.465337305133332, 35.70777472608836],
              [-81.465006700248324, 35.707733903419857],
              [-81.464904774420347, 35.708263563866169],
              [-81.464574167757462, 35.708222740028887],
              [-81.46452320328774, 35.708487570052782],
              [-81.4635313831823, 35.708365090919749],
              [-81.463480414107238, 35.708629920517915],
              [-81.462819200609417, 35.708548262391453],
              [-81.462870171795842, 35.708283433063656],
              [-81.462539566050268, 35.708242602798911],
              [-81.46248859380826, 35.708507431991634],
              [-81.462157988472669, 35.708466601571956],
              [-81.462107014841536, 35.708731430586766],
              [-81.461115195376891, 35.708608930872749],
              [-81.46106421824517, 35.708873759439228],
              [-81.460733612104818, 35.708832924254125],
              [-81.460682633584028, 35.709097752642656],
              [-81.460352025616672, 35.709056916446229],
              [-81.460250065463057, 35.709586572824591],
              [-81.459258237227886, 35.70946405803307],
              [-81.459207253483854, 35.709728885752554],
              [-81.45887664368351, 35.709688045556405],
              [-81.458927629588061, 35.709423217949805],
              [-81.458266413099366, 35.709341535132445],
              [-81.458317400781795, 35.709076707753418],
              [-81.45798679409333, 35.709035865132073],
              [-81.45803778139306, 35.708771037867741],
              [-81.457376572205675, 35.708689349276632],
              [-81.457427562387949, 35.708424522217612],
              [-81.457096959916356, 35.708383677148809],
              [-81.45714794971579, 35.708118850204499],
              [-81.456817348606066, 35.708078003471357],
              [-81.456868340232447, 35.707813176597092],
              [-81.455545942328797, 35.707649783945996],
              [-81.455494946480044, 35.707914610279325],
              [-81.454172549664293, 35.707751201810325],
              [-81.454121548154703, 35.708016027582261],
              [-81.453460350189715, 35.707934317675175],
              [-81.453409347340028, 35.708199143111706],
              [-81.453078746711284, 35.708158287147945],
              [-81.453027742472329, 35.708423112406464],
              [-81.452697142199156, 35.708382254485635],
              [-81.452646135466196, 35.708647079588296],
              [-81.452315534498382, 35.708606221534893],
              [-81.452264526376069, 35.708871046459514],
              [-81.450611518945365, 35.708666738478932],
              [-81.450560505211328, 35.708931562684505],
              [-81.44923810171953, 35.70876809975298],
              [-81.449187083429464, 35.709032923374657],
              [-81.44488929540023, 35.708501563970742],
              [-81.444838261949087, 35.708766385813092],
              [-81.444507664408121, 35.708725505652332],
              [-81.444456629567483, 35.708990327316535],
              [-81.444126031277619, 35.708949445220824],
              [-81.444074995047657, 35.709214266706979],
              [-81.443744394958372, 35.709173384500808],
              [-81.443693357338972, 35.709438205808723],
              [-81.440717970790928, 35.709070220521852],
              [-81.440666922232737, 35.709335040591185],
              [-81.440336324449135, 35.709294148206261],
              [-81.440285274501448, 35.709558968097326],
              [-81.439954675996262, 35.709518074678648],
              [-81.439903624659081, 35.709782894391495],
              [-81.439573024327473, 35.709741999961146],
              [-81.439521971600868, 35.710006819495774],
              [-81.439191371652427, 35.70996592400946],
              [-81.439140317536285, 35.710230743365884],
              [-81.438809716866359, 35.710189846845715],
              [-81.438758661360595, 35.710454666023978],
              [-81.438097457806677, 35.710372870039549],
              [-81.437995340439102, 35.710902506846878],
              [-81.437664737604422, 35.710861607225198],
              [-81.437613676825194, 35.711126425890761],
              [-81.437283073268958, 35.711085525235205],
              [-81.437232011072993, 35.711350342821426],
              [-81.436901406822457, 35.711309442033112],
              [-81.436850343236799, 35.711574259441043],
              [-81.435858528136421, 35.711451548597879],
              [-81.43590959488877, 35.711186731595994],
              [-81.434917784883922, 35.711064013995873],
              [-81.434866714964983, 35.711328830591455],
              [-81.433874905877971, 35.711206103575542],
              [-81.433925978963643, 35.710941287386142],
              [-81.433264777423787, 35.710859465139279],
              [-81.433315852259227, 35.710594648276683],
              [-81.431993456306387, 35.710430993564934],
              [-81.432044536134626, 35.710166177179019],
              [-81.431713939313468, 35.710125261384874],
              [-81.431662858429718, 35.71039007763536],
              [-81.431332260860046, 35.710349159906237],
              [-81.431383341721627, 35.71008434471436],
              [-81.430391554238383, 35.709961588344626],
              [-81.430442639037025, 35.70969677349408],
              [-81.426806124448532, 35.709246597869161],
              [-81.426755028039693, 35.709511411229798],
              [-81.42411030768173, 35.709183940169787],
              [-81.424059201390392, 35.709448752425587],
              [-81.423398023092091, 35.709366875619637],
              [-81.423346914355577, 35.709631687561533],
              [-81.423016324086532, 35.709590747235886],
              [-81.422965213960268, 35.709855558999394],
              [-81.422634624075059, 35.709814617617724],
              [-81.422304033446238, 35.709773676260795],
              [-81.422252920874769, 35.71003848771047],
              [-81.421922329498003, 35.709997544418421],
              [-81.421820100136188, 35.710527166939876],
              [-81.421489506982823, 35.710486222478366],
              [-81.421438391297386, 35.710751033528254],
              [-81.421107797423048, 35.710710088032876],
              [-81.421005561860142, 35.711239710655711],
              [-81.420852205414931, 35.712034142927202],
              [-81.420521606597688, 35.711993195855861],
              [-81.420470485663174, 35.712258005497752],
              [-81.420139886124772, 35.712217057392436],
              [-81.420088764932174, 35.71248186773493],
              [-81.419096964052414, 35.712359017643443],
              [-81.419045838254078, 35.712623827558431],
              [-81.418384636860509, 35.712541922741423],
              [-81.418333508589839, 35.712806731441248],
              [-81.418002907891662, 35.712765777538557],
              [-81.417951778257986, 35.713030586960954],
              [-81.417621176838892, 35.712989632024268],
              [-81.417570044683359, 35.713254440388987],
              [-81.417239442543263, 35.713213484418262],
              [-81.417188310129646, 35.71347829348359],
              [-81.416857706136682, 35.71343733559975],
              [-81.416806572333073, 35.713702144486419],
              [-81.416475967646136, 35.7136611864697],
              [-81.416373695792501, 35.714190802963913],
              [-81.416043090433888, 35.714149843755635],
              [-81.415838537358596, 35.715209074806346],
              [-81.415507928112575, 35.715168114157414],
              [-81.415456787668859, 35.715432921457072],
              [-81.415126176596956, 35.715391959795966],
              [-81.415075034763163, 35.715656766917057],
              [-81.414744424075195, 35.715615804199992],
              [-81.41469328085131, 35.71588061114246],
              [-81.414362668337475, 35.71583964741324],
              [-81.414311523723569, 35.716104454177177],
              [-81.41398091048876, 35.716063489413834],
              [-81.413827471472729, 35.716857909040264],
              [-81.413496855405896, 35.716816942971775],
              [-81.413343409106943, 35.717611361825526],
              [-81.413012790181071, 35.71757039355073],
              [-81.412910488495953, 35.718100005640537],
              [-81.412579867793582, 35.718059036196038],
              [-81.412477562658665, 35.718588647842182],
              [-81.412146940206725, 35.718547678129212],
              [-81.412095785502331, 35.718812482861871],
              [-81.411765162329431, 35.718771512114614],
              [-81.411714006261747, 35.719036317569717],
              [-81.411383382340986, 35.718995344887368],
              [-81.411332224883012, 35.719260150163564],
              [-81.411001600241434, 35.719219176447091],
              [-81.410950441393197, 35.719483981544691],
              [-81.410619814925624, 35.719443006815936],
              [-81.410568655792076, 35.719707811712908],
              [-81.4102380286037, 35.719666835950107],
              [-81.410186866974769, 35.719931640690135],
              [-81.409856239065491, 35.719890663893118],
              [-81.40980507601941, 35.720155467553347],
              [-81.409474448521053, 35.720114490601489],
              [-81.409423284084738, 35.720379294082854],
              [-81.409092654733684, 35.720338315217575],
              [-81.409041488933752, 35.720603119421334],
              [-81.408710858861824, 35.720562139521988],
              [-81.40865969167163, 35.720826943546868],
              [-81.408329060878728, 35.720785962613313],
              [-81.408277892271343, 35.72105076555836],
              [-81.40794726075768, 35.721009783590731],
              [-81.407896090786636, 35.721274587257994],
              [-81.407565458552099, 35.721233604256113],
              [-81.407514286058856, 35.721498406865308],
              [-81.407183653103473, 35.72145742282936],
              [-81.407132480351706, 35.721722226139043],
              [-81.406801846675478, 35.721681241068922],
              [-81.406750671401383, 35.721946043320443],
              [-81.406420037004338, 35.72190505721607],
              [-81.406368860339811, 35.722169859288812],
              [-81.406038225221934, 35.722128872150357],
              [-81.405987048272053, 35.722393674022307],
              [-81.405656412433345, 35.722352685849692],
              [-81.405502873119389, 35.723147091765831],
              [-81.405172234448557, 35.723106102287744],
              [-81.404865140365686, 35.724694909435158],
              [-81.404534494590521, 35.724653918266377],
              [-81.404483310020169, 35.724918719320669],
              [-81.404152664629322, 35.724877727095937],
              [-81.404101477536472, 35.725142527092032],
              [-81.403109537995988, 35.725019544663077],
              [-81.403058348533065, 35.725284345088163],
              [-81.402397054305808, 35.725202351151495],
              [-81.402345862396473, 35.725467151261952],
              [-81.402015214742917, 35.725426153260635],
              [-81.401964021416291, 35.725690952291025],
              [-81.401633373041975, 35.725649953255491],
              [-81.401582177246297, 35.725914753029791],
              [-81.401251528124476, 35.725873752058902],
              [-81.401200332043317, 35.726138551632395],
              [-81.400539032719777, 35.726056547624587],
              [-81.400487833087169, 35.726321346905273],
              [-81.39949588346046, 35.726198334197754],
              [-81.399444680298942, 35.726463132126902],
              [-81.398783380102543, 35.726381118590666],
              [-81.398732174521413, 35.726645917106161],
              [-81.398070873553564, 35.726563899704274],
              [-81.398019665499319, 35.726828697003903],
              [-81.397358363760205, 35.726746675736301],
              [-81.397307153259504, 35.72701147272118],
              [-81.396976501850204, 35.726970461054364],
              [-81.39692528885368, 35.727235257881958],
              [-81.396263984902077, 35.727153230680045],
              [-81.396212769459069, 35.72741802719284],
              [-81.395551464736371, 35.72733599612522],
              [-81.395500247951958, 35.727600792301423],
              [-81.39516959391915, 35.727559774855152],
              [-81.395118375743849, 35.727824570852334],
              [-81.394457067703925, 35.727742533872011],
              [-81.394405845977005, 35.728007329576101],
              [-81.39407519194323, 35.727966309140974],
              [-81.39402396882555, 35.72823110466588],
              [-81.39369331409776, 35.728190084097498],
              [-81.393642089589235, 35.728454879443262],
              [-81.393311433009217, 35.728413856961119],
              [-81.393260207109833, 35.728678652127762],
              [-81.392598895053951, 35.728596604152955],
              [-81.392547665602848, 35.728861399026499],
              [-81.391225040185333, 35.728697291772541],
              [-81.391173807281305, 35.728962086037839],
              [-81.390181838294325, 35.728838995760498],
              [-81.390130600782868, 35.729103789596785],
              [-81.389138631645793, 35.728980690824841],
              [-81.389087390605368, 35.729245483309263],
              [-81.387764767033545, 35.729081338461327],
              [-81.387713520329868, 35.729346130380847],
              [-81.387052208444274, 35.729264052293857],
              [-81.387000959320588, 35.729528844799432],
              [-81.386008990813195, 35.72940571962107],
              [-81.385957738160556, 35.729670510774781],
              [-81.38529642427352, 35.729588422279306],
              [-81.385347679037622, 35.729323631397293],
              [-81.385017024192024, 35.729282585476177],
              [-81.384965768398899, 35.729547377123467],
              [-81.383973802734019, 35.729424234462343],
              [-81.383922543438388, 35.729689025658722],
              [-81.383261231464346, 35.729606926108758],
              [-81.383209969721975, 35.72987171699009],
              [-81.381556691995669, 35.729666450327038],
              [-81.381607960148017, 35.729401661004403],
              [-81.380946653948115, 35.729319547951881],
              [-81.380997922744712, 35.729054757978069],
              [-81.380005970259234, 35.728931582947197],
              [-81.380057242992322, 35.728666793315824],
              [-81.379395944369492, 35.728584671821231],
              [-81.379447218878369, 35.728319882418155],
              [-81.378785923735123, 35.728237758502246],
              [-81.37883720001976, 35.72797296932756],
              [-81.37718397653299, 35.727767641274141],
              [-81.377132693865278, 35.728032429790993],
              [-81.37680204956618, 35.727991361520417],
              [-81.376750765507367, 35.728256149857955],
              [-81.376420120488106, 35.72821508055307],
              [-81.376368835038178, 35.72847986871124],
              [-81.376038188167385, 35.728438797492316],
              [-81.375986902431336, 35.72870358544958],
              [-81.372349802020068, 35.728251748365878],
              [-81.372247204081873, 35.728781320301898],
              [-81.37191655807132, 35.728740237892865],
              [-81.371813956705893, 35.729269810284336],
              [-81.371483308919807, 35.729228726704825],
              [-81.371432005572984, 35.729493512720978],
              [-81.371101358172083, 35.729452428085537],
              [-81.371050053433933, 35.729717213922186],
              [-81.370719404207989, 35.729676128273809],
              [-81.370668099157385, 35.729940913008399],
              [-81.370337449237809, 35.729899827226646],
              [-81.370234834939708, 35.730429397216049],
              [-81.36990418321831, 35.730388309362745],
              [-81.369852873944282, 35.730653093716448],
              [-81.369522221529365, 35.73061200572976],
              [-81.369470910864123, 35.730876789904009],
              [-81.369140257702909, 35.730835699981704],
              [-81.369088945672658, 35.731100484877565],
              [-81.368096985062792, 35.730977210212664],
              [-81.368045669503672, 35.731241993756001],
              [-81.367384361980385, 35.731159805280697],
              [-81.367435679650697, 35.730895022009292],
              [-81.367105027448261, 35.730853926559803],
              [-81.367156345838424, 35.730589143380762],
              [-81.36682569502733, 35.730548047168739],
              [-81.366877015242451, 35.730283264060745],
              [-81.3665463658227, 35.730242167086139],
              [-81.366597685626232, 35.729977383191013],
              [-81.365936391036556, 35.729895187697757],
              [-81.365987713720585, 35.729630404009619],
              [-81.365657067419178, 35.729589304611409],
              [-81.365759712812135, 35.729059738299128],
              [-81.365429070062802, 35.729018638252924],
              [-81.365531716223657, 35.72848907203872],
              [-81.365201074816028, 35.728447971387489],
              [-81.365252399014125, 35.728183188790887],
              [-81.364921760102789, 35.728142087355685],
              [-81.364591120422233, 35.728100985043454],
              [-81.364488468599745, 35.728630550528607],
              [-81.364157828249063, 35.728589447024397],
              [-81.364106500212955, 35.728854229126],
              [-81.363775858037442, 35.728813124608664],
              [-81.363724529741333, 35.729077907410421],
              [-81.363393886819807, 35.729036800957537],
              [-81.363342556027234, 35.72930158360117],
              [-81.363011913517539, 35.729260476993417],
              [-81.362960581307306, 35.729525258556421],
              [-81.362629936972709, 35.729484150935605],
              [-81.362578603371119, 35.729748932319069],
              [-81.361917314703504, 35.729666714088353],
              [-81.361865978655089, 35.729931495156357],
              [-81.361535333216992, 35.729890384567966],
              [-81.361483995777093, 35.730155165456338],
              [-81.361153349619329, 35.730114053833411],
              [-81.361102010788102, 35.730378834542229],
              [-81.360771363910686, 35.730337721884752],
              [-81.360668682024297, 35.730867282921153],
              [-81.360338034476825, 35.730826169071648],
              [-81.360235348030599, 35.731355729683308],
              [-81.360565998794314, 35.731396843783443],
              [-81.360309284288547, 35.732720745187457],
              [-81.35799469897303, 35.732432920152554],
              [-81.358046050377212, 35.732168140902658],
              [-81.356723441802856, 35.732003649973215],
              [-81.35667208617653, 35.732268428678928],
              [-81.356341433804175, 35.732227303113575],
              [-81.356290075681116, 35.732492081660936],
              [-81.355959422589308, 35.732450955061083],
              [-81.355298117439887, 35.732368700066878],
              [-81.355349478729664, 35.732103921927738],
              [-81.355018827701485, 35.73206279276836],
              [-81.35496746535614, 35.732327570771425],
              [-81.353314211109421, 35.73212191169609],
              [-81.353262843150432, 35.732386688975076],
              [-81.349625702599042, 35.731934156443792],
              [-81.349574321561931, 35.732198931302129],
              [-81.347590437901559, 35.731952047826603],
              [-81.347539050221442, 35.73221682272559],
              [-81.345885818143728, 35.732011060048237],
              [-81.345834424823764, 35.732275833321715],
              [-81.344842486229751, 35.732152364709378],
              [-81.344791089433329, 35.732417138431828],
              [-81.344129795862088, 35.732334820972625],
              [-81.344078396592352, 35.732599593478],
              [-81.343086456683352, 35.732476109670031],
              [-81.343035052831837, 35.732740882645686],
              [-81.342043112783145, 35.732617390342817],
              [-81.342094519801151, 35.73235261777576],
              [-81.34011065730175, 35.732105608784487],
              [-81.340162070342132, 35.731840837892243],
              [-81.338839506642472, 35.731676147199003],
              [-81.338890923542024, 35.731411375906845],
              [-81.337899008294414, 35.731287848845803],
              [-81.337950429129052, 35.731023077897561],
              [-81.336958520075271, 35.730899543159076],
              [-81.337009943739645, 35.730634772575947],
              [-81.336348674829949, 35.730552411863925],
              [-81.336400100268534, 35.730287641509697],
              [-81.335738834789382, 35.730205276575298],
              [-81.335687407240059, 35.730470046657004],
              [-81.335026141021913, 35.73038767875736],
              [-81.335077571787068, 35.730122908927051],
              [-81.334085678123486, 35.729999349847027],
              [-81.334137111718221, 35.72973458038188],
              [-81.331161462764484, 35.729363853966504],
              [-81.331110019671812, 35.729628622205041],
              [-81.330118139201844, 35.72950502985946],
              [-81.330066692606792, 35.729769797645574],
              [-81.329736066129414, 35.729728597949432],
              [-81.329684617037273, 35.729993365576696],
              [-81.329353989841152, 35.729952164845827],
              [-81.329302539357215, 35.73021693229316],
              [-81.328971911442281, 35.730175730527577],
              [-81.328920459566433, 35.730440497794966],
              [-81.328589830932913, 35.730399294994633],
              [-81.328435471198915, 35.731193595203848],
              [-81.328126742590399, 35.732782192641466],
              [-81.328457381827135, 35.732823396647618],
              [-81.328251560971751, 35.733882460963358],
              [-81.327920916408289, 35.733841256433038],
              [-81.327818000757759, 35.734370788527777],
              [-81.327487355525264, 35.734329582805273],
              [-81.327332976319397, 35.735123878858772],
              [-81.327002328257421, 35.73508267182882],
              [-81.326847942855096, 35.735876967079818],
              [-81.32651729085832, 35.7358357587635],
              [-81.326362898128281, 35.736630052331762],
              [-81.326032243301867, 35.73658884270796],
              [-81.325980777706619, 35.736853607300148],
              [-81.325650122161463, 35.736812396641511],
              [-81.325495719058182, 35.737606688866379],
              [-81.325165060683247, 35.737565476900308],
              [-81.325062121109369, 35.738095004265254],
              [-81.324731460960322, 35.738053791127903],
              [-81.32467998907309, 35.738318555069434],
              [-81.32368800634886, 35.73819490897727],
              [-81.323739481428404, 35.737930146345917],
              [-81.32340882152117, 35.737888929635574],
              [-81.323460297293536, 35.737624166195644],
              [-81.323129639883817, 35.737582948702126],
              [-81.323078163055982, 35.737847712005689],
              [-81.322086189655209, 35.737724052823985],
              [-81.322034709324186, 35.737988815674647],
              [-81.321704050045483, 35.737947594335488],
              [-81.32165256829623, 35.738212356104853],
              [-81.321321909404148, 35.73817113370972],
              [-81.3212704262627, 35.738435895298899],
              [-81.320939766651975, 35.738394671868974],
              [-81.320888281012969, 35.73865943329902],
              [-81.32055762068363, 35.738618208834168],
              [-81.320506134757451, 35.738882970062889],
              [-81.320175472604234, 35.738841744584228],
              [-81.320123985285832, 35.739106505632741],
              [-81.319793323519264, 35.739065279098099],
              [-81.319741833703247, 35.739330039987458],
              [-81.319411171217993, 35.739288812417918],
              [-81.319359680009612, 35.73955357312704],
              [-81.318367690277825, 35.739429883738154],
              [-81.318316195566112, 35.739694643994227],
              [-81.317654868274886, 35.73961217934292],
              [-81.317603371115297, 35.739876939282311],
              [-81.315950054045871, 35.739670760787099],
              [-81.315898550166693, 35.739935520021554],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 52,
        ORIG_FID: 5,
        AAPC_Examp: 8,
        Area_mile2: 386,
        BASIN_NAME: "Lake James",
        Res_Basin: "James",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.843446316578252, 36.129313660469215],
              [-81.84349623908706, 36.129048753381831],
              [-81.840502440089367, 36.128690912330299],
              [-81.840552373006261, 36.128426006346515],
              [-81.839887090067776, 36.128346475720072],
              [-81.839937023716558, 36.128081570874429],
              [-81.839604384904206, 36.128041803858757],
              [-81.839654319294013, 36.127776899093348],
              [-81.838989043715145, 36.127697363536271],
              [-81.839038979883682, 36.127432458081479],
              [-81.83837370775791, 36.127352919167031],
              [-81.838423646847374, 36.127088013897946],
              [-81.838756281137208, 36.12712778413978],
              [-81.838806218828665, 36.126862878688684],
              [-81.83913885237753, 36.12690264789444],
              [-81.839238723525554, 36.126372836603096],
              [-81.839571355232096, 36.126412603740739],
              [-81.839671222958941, 36.125882792884653],
              [-81.840003852855119, 36.125922558854988],
              [-81.84030343945075, 36.124333122529883],
              [-81.840636063258714, 36.124372886808416],
              [-81.840685991948618, 36.124107980299428],
              [-81.840353369210064, 36.12406821615204],
              [-81.840403298640837, 36.123803309723407],
              [-81.84007067837878, 36.123763543875661],
              [-81.840120608550592, 36.123498637527511],
              [-81.839787988607796, 36.123458871832717],
              [-81.839837919520548, 36.123193965564866],
              [-81.839172683837774, 36.123114430796441],
              [-81.839222617671112, 36.12284952471439],
              [-81.838890000852615, 36.122809756567413],
              [-81.838939934284724, 36.122544849690634],
              [-81.838607319974358, 36.122505080744205],
              [-81.838657254179196, 36.122240174848756],
              [-81.837992028650987, 36.122160633602782],
              [-81.838091901462832, 36.121630822158671],
              [-81.837759291314327, 36.121591049990386],
              [-81.837859164950842, 36.121061238605698],
              [-81.837526557269399, 36.121021465794875],
              [-81.837876111516564, 36.119167123480686],
              [-81.838208711712909, 36.119206895373168],
              [-81.838308578294786, 36.118677082200819],
              [-81.838641176681065, 36.118716852926049],
              [-81.838741039810571, 36.118187039288557],
              [-81.839073635244617, 36.118226807971581],
              [-81.83917349603216, 36.11769699384341],
              [-81.839506089688001, 36.117736762260122],
              [-81.839705800824277, 36.11667713292281],
              [-81.83937321144542, 36.116637365030819],
              [-81.839423139493519, 36.116372458151517],
              [-81.838425376566775, 36.116253150341336],
              [-81.838475308572569, 36.115988242878352],
              [-81.838142722677617, 36.115948472530562],
              [-81.838192654313858, 36.115683565173903],
              [-81.837860069816443, 36.115643794052488],
              [-81.837910003303818, 36.115378886750676],
              [-81.836579673922188, 36.11521979191464],
              [-81.836629611357665, 36.114954885087151],
              [-81.835299292586726, 36.114795775373253],
              [-81.835349233970376, 36.114530869019944],
              [-81.835016656746902, 36.11449109012365],
              [-81.834966714262407, 36.114755995444803],
              [-81.833303825379886, 36.11455708318379],
              [-81.833353773178175, 36.114292177617955],
              [-81.831358325291987, 36.114053452718835],
              [-81.83130837107899, 36.114318357497169],
              [-81.830643221533819, 36.114238775047603],
              [-81.830593264822483, 36.114503678612053],
              [-81.829928115563689, 36.114424092255263],
              [-81.829878155306957, 36.114688996433117],
              [-81.829545580069734, 36.1146492008655],
              [-81.829495618415265, 36.114914104861512],
              [-81.828830465709942, 36.114834511676037],
              [-81.828780501588653, 36.115099415358877],
              [-81.828115349170076, 36.115019818266319],
              [-81.828065382581826, 36.115284721635895],
              [-81.827732804701938, 36.115244922077387],
              [-81.827682836684389, 36.115509824364175],
              [-81.827017681961365, 36.115430221318078],
              [-81.827067652117179, 36.115165319293794],
              [-81.826735076301986, 36.115125516094544],
              [-81.826785047198101, 36.114860614150942],
              [-81.826452472812377, 36.114820811079028],
              [-81.826502444417287, 36.114555908315296],
              [-81.826169871429244, 36.114516104469999],
              [-81.826219844884861, 36.114251201761242],
              [-81.825554702001369, 36.114171590718293],
              [-81.825604676156047, 36.113906688247177],
              [-81.823609262919575, 36.113667834167437],
              [-81.823659244270004, 36.113402932408029],
              [-81.822994112032674, 36.113323307389408],
              [-81.823044095192373, 36.113058405842054],
              [-81.820716149598468, 36.112779689359911],
              [-81.820766141022574, 36.112514788655751],
              [-81.819768457848426, 36.112395324793802],
              [-81.819818452182261, 36.112130425334087],
              [-81.819485893590951, 36.112090602351692],
              [-81.819535887523031, 36.111825702097441],
              [-81.819203330329586, 36.111785878341749],
              [-81.819253326143567, 36.111520979043547],
              [-81.818920770347788, 36.111481154514649],
              [-81.818970765760184, 36.111216254421997],
              [-81.818305657293024, 36.111136602912858],
              [-81.818355655624558, 36.110871703006794],
              [-81.81769055061072, 36.110792048141811],
              [-81.817740550751225, 36.110527148447986],
              [-81.815412703233235, 36.110248327891846],
              [-81.815362695578997, 36.110513225765047],
              [-81.814365047399875, 36.11039371678433],
              [-81.814315036241567, 36.110658615113621],
              [-81.813982486448012, 36.110618777146293],
              [-81.813932472750196, 36.110883674418176],
              [-81.813267373090895, 36.110803994580451],
              [-81.813217356957836, 36.111068892439874],
              [-81.812552255366484, 36.110989208746183],
              [-81.812502236766619, 36.111254106292186],
              [-81.811837135432185, 36.111174417790743],
              [-81.81188715620118, 36.110909521408473],
              [-81.811554606541165, 36.11086967594494],
              [-81.811604628050119, 36.110604779643637],
              [-81.811937176609717, 36.110644624074766],
              [-81.811987196720807, 36.110379727591351],
              [-81.812319744540545, 36.110419570986572],
              [-81.812369762143589, 36.110154674346674],
              [-81.81270230922344, 36.110194516705668],
              [-81.812752326507422, 36.109929618957487],
              [-81.813417418437609, 36.110009301625226],
              [-81.813467433286405, 36.109744404464422],
              [-81.813799978105209, 36.109784243872291],
              [-81.813849991556197, 36.109519346529517],
              [-81.814182535635084, 36.109559184901357],
              [-81.814232547656829, 36.10929428647573],
              [-81.814565091027376, 36.109334124712412],
              [-81.814615101651256, 36.10906922610485],
              [-81.814947644250395, 36.109109062404613],
              [-81.81504766129504, 36.108579265701536],
              [-81.815380202085407, 36.108619100833849],
              [-81.815430209584903, 36.108354201811899],
              [-81.81576274963534, 36.108394035908198],
              [-81.81586275932122, 36.107864238401987],
              [-81.816195298673094, 36.107904071305285],
              [-81.81634530748164, 36.107109372921812],
              [-81.816677842845777, 36.107149204551867],
              [-81.81682784548812, 36.1063545053198],
              [-81.817160378006236, 36.106394336551936],
              [-81.817260374875971, 36.105864536574437],
              [-81.817592905553866, 36.10590436573834],
              [-81.817692900080814, 36.105374565270594],
              [-81.818025427839842, 36.105414393292691],
              [-81.81807542354133, 36.105149492851758],
              [-81.818407951670551, 36.105189319812311],
              [-81.818507938808068, 36.104659517641132],
              [-81.818840464018436, 36.104699343459906],
              [-81.818940447734732, 36.104169541725234],
              [-81.819272972246537, 36.104209366350986],
              [-81.819372951400226, 36.103679564177433],
              [-81.81970547410333, 36.103719387636026],
              [-81.819755462086732, 36.103454485441411],
              [-81.820087982939739, 36.10349430788952],
              [-81.820137970667318, 36.103229406388401],
              [-81.820470490780366, 36.103269227800638],
              [-81.820520476000368, 36.103004326143385],
              [-81.820852995373428, 36.103044146519693],
              [-81.82090297916443, 36.102779243779892],
              [-81.821235497797502, 36.10281906312018],
              [-81.821335462287607, 36.102289258127534],
              [-81.821667979111965, 36.102329076300634],
              [-81.821767940149755, 36.101799270843742],
              [-81.822100455165426, 36.101839087849562],
              [-81.822150434138379, 36.101574185364683],
              [-81.822815461876715, 36.101653815524642],
              [-81.822865438383701, 36.101388912726762],
              [-81.823197952216347, 36.101428725855385],
              [-81.823247926215899, 36.10116382290137],
              [-81.823912952761447, 36.101243447083064],
              [-81.823962924294989, 36.10097854381609],
              [-81.824960463387882, 36.101097973360979],
              [-81.825010431355025, 36.100833068748763],
              [-81.825342943985106, 36.100872876956949],
              [-81.825392910555067, 36.100607972163075],
              [-81.825725422444961, 36.100647779335318],
              [-81.825825352461635, 36.100117969334043],
              [-81.82615786143279, 36.100157775364814],
              [-81.826307750818941, 36.099363060492479],
              [-81.82664025799113, 36.099402864298241],
              [-81.826690219415099, 36.09913795970953],
              [-81.827022724737134, 36.099177762505029],
              [-81.827122644398941, 36.098647951112376],
              [-81.827455147943766, 36.098687753641563],
              [-81.827505106213252, 36.098422847738554],
              [-81.827837609018076, 36.09846264923199],
              [-81.827887564780241, 36.098197743173017],
              [-81.828220067923368, 36.098237542703998],
              [-81.828270022288393, 36.097972636463538],
              [-81.828602523612986, 36.098012435885231],
              [-81.828652477690895, 36.097747529437584],
              [-81.828984978243724, 36.097787326922578],
              [-81.829034929814554, 36.097522420319024],
              [-81.829699930910053, 36.097602013188165],
              [-81.829749880015143, 36.097337106271716],
              [-81.830082379416098, 36.097376900780681],
              [-81.830132327124076, 36.097111993682681],
              [-81.830797324774224, 36.09719157972448],
              [-81.830847270016406, 36.0969266723137],
              [-81.831179768297346, 36.096966464747418],
              [-81.831229712142459, 36.096701557155129],
              [-81.8318947074888, 36.096781137244733],
              [-81.831994588809351, 36.096251321410151],
              [-81.832327084837971, 36.096291109835605],
              [-81.832377024491961, 36.096026201698891],
              [-81.832044529532055, 36.095986413404667],
              [-81.832094469894571, 36.095721504448171],
              [-81.831761976331592, 36.095681715380827],
              [-81.831861858272006, 36.095151899381641],
              [-81.832194348587819, 36.09519168821214],
              [-81.83229422707744, 36.094661871749686],
              [-81.832344164687939, 36.094396962568013],
              [-81.831679189686156, 36.094317384815845],
              [-81.831729130247126, 36.094052476721743],
              [-81.831064158664404, 36.093972894713374],
              [-81.831114101033961, 36.093707986831696],
              [-81.830781617374583, 36.093668195039633],
              [-81.830831560484341, 36.093403287239106],
              [-81.830499078190257, 36.093363493773083],
              [-81.830549022040117, 36.093098586053721],
              [-81.82988406060592, 36.093018997571583],
              [-81.829934006264281, 36.092754090064773],
              [-81.829601527124254, 36.092714295048758],
              [-81.829701419561005, 36.092184479246392],
              [-81.82936894288639, 36.092144683588543],
              [-81.829468836146447, 36.091614867848328],
              [-81.829136363015451, 36.091575070622106],
              [-81.829186310237048, 36.091310163258541],
              [-81.828853837424646, 36.091270366185817],
              [-81.828953732987884, 36.090740550670155],
              [-81.828621263719128, 36.090700752029015],
              [-81.828771107821751, 36.089906029224387],
              [-81.828438642086724, 36.089866230072822],
              [-81.828488590511427, 36.08960132190186],
              [-81.828156126204874, 36.089561522878022],
              [-81.828256025347244, 36.089031707523795],
              [-81.827923563474286, 36.088991906957318],
              [-81.827973513081986, 36.088726999799697],
              [-81.827641052605969, 36.088687198460214],
              [-81.827740954051066, 36.08815738333093],
              [-81.827408496040135, 36.088117581349664],
              [-81.827558348378929, 36.087322858236995],
              [-81.827225893901485, 36.087283055745395],
              [-81.827475646920888, 36.085958516271553],
              [-81.827808094946946, 36.085998318132241],
              [-81.827907990818559, 36.085468501390324],
              [-81.828240438114491, 36.085508301157368],
              [-81.828340330535866, 36.084978483952924],
              [-81.828672774945389, 36.085018283479528],
              [-81.828822607909643, 36.084223556904142],
              [-81.829155049411199, 36.084263354231624],
              [-81.829204990897665, 36.083998445167602],
              [-81.828872551574221, 36.083958647945856],
              [-81.829022379456234, 36.083163919895945],
              [-81.828689943665879, 36.083124122163831],
              [-81.82878983014453, 36.082594303159581],
              [-81.829122263830058, 36.082634101529933],
              [-81.829172205524443, 36.082369192272118],
              [-81.829504638438394, 36.082408988705964],
              [-81.829554578736222, 36.082144079266826],
              [-81.829887012020137, 36.082183874639398],
              [-81.829936949811582, 36.081918965044757],
              [-81.830601814150043, 36.081998553740895],
              [-81.830651750554793, 36.08173364290716],
              [-81.831316612960322, 36.081813227749052],
              [-81.831366546900412, 36.081548316602884],
              [-81.831698977543184, 36.081588107986448],
              [-81.831798841223787, 36.08105828620841],
              [-81.832131270026863, 36.081098075524281],
              [-81.832231129148013, 36.08056825330987],
              [-81.832563557284558, 36.080608042333907],
              [-81.83276326731486, 36.079548396781746],
              [-81.833095690365425, 36.079588183501166],
              [-81.833145615968306, 36.079323271406842],
              [-81.83347803827894, 36.07936305709066],
              [-81.833527962517195, 36.079098145716095],
              [-81.833860382978045, 36.079137930390061],
              [-81.833960228301763, 36.078608106327991],
              [-81.834625068155262, 36.07868767238655],
              [-81.834674987062812, 36.07842275959355],
              [-81.835007406413752, 36.078462541135174],
              [-81.835057323924929, 36.07819762816095],
              [-81.835389742535924, 36.078237408667015],
              [-81.835439659792343, 36.077972496387019],
              [-81.835107242249506, 36.077932716012235],
              [-81.835207076740801, 36.07740288978929],
              [-81.834874662772179, 36.077363108747129],
              [-81.834974498118626, 36.076833283488575],
              [-81.834642085472979, 36.076793500929504],
              [-81.834741922752599, 36.076263675708645],
              [-81.834409512603045, 36.076223893408731],
              [-81.834459431802756, 36.0759589804046],
              [-81.834127024159301, 36.075919197305979],
              [-81.834226862600559, 36.0753893714367],
              [-81.833894457421337, 36.075349587696422],
              [-81.834044216380889, 36.074554848900476],
              [-81.834376618355961, 36.074594632246907],
              [-81.834426536654675, 36.074329719075607],
              [-81.83475893678019, 36.074369501412207],
              [-81.834808853682603, 36.074104588059903],
              [-81.835141254177955, 36.074144369335301],
              [-81.835191168574468, 36.073879455827715],
              [-81.835523568329947, 36.073919236067589],
              [-81.835623394002539, 36.073389408640814],
              [-81.835955791950127, 36.07342918771392],
              [-81.836005703226149, 36.073164273794802],
              [-81.836338100433849, 36.073204051832327],
              [-81.836437919833855, 36.072674222681563],
              [-81.836770314123953, 36.072713999578077],
              [-81.836920037574885, 36.071919255536095],
              [-81.836587646520556, 36.071879479934211],
              [-81.836787277445723, 36.070819820440853],
              [-81.836454890959558, 36.070780043558941],
              [-81.836554706621186, 36.070250214677948],
              [-81.836222323708682, 36.070210437128679],
              [-81.836322140162039, 36.069680607410881],
              [-81.835989758603844, 36.069640829245706],
              [-81.836039667390409, 36.069375913993291],
              [-81.836372047880687, 36.069415692027086],
              [-81.836421955303024, 36.069150777494677],
              [-81.836754333943844, 36.069190554518777],
              [-81.836804239938374, 36.068925638904531],
              [-81.837136618949032, 36.068965414867542],
              [-81.837186523579291, 36.068700499973389],
              [-81.837518900708744, 36.068740274025743],
              [-81.837618706817651, 36.068210442925206],
              [-81.837951082171088, 36.068250216711839],
              [-81.838150685039352, 36.067190552515761],
              [-81.838483056449235, 36.067230324873172],
              [-81.838632750573424, 36.066435576069559],
              [-81.83896511910784, 36.06647534712905],
              [-81.839064911763515, 36.06594551347392],
              [-81.839397278490168, 36.06598528336675],
              [-81.839447172703387, 36.065720366346547],
              [-81.839779538690294, 36.065760135203988],
              [-81.839879325106523, 36.065230300726782],
              [-81.840211688176169, 36.065270068443368],
              [-81.840261580379348, 36.06500515098638],
              [-81.840593942677387, 36.065044916766702],
              [-81.840643832375108, 36.064779999154752],
              [-81.840976195074632, 36.064819764774818],
              [-81.841075971350662, 36.064289929140067],
              [-81.841408332242509, 36.064329693593443],
              [-81.841458217711164, 36.064064775596442],
              [-81.841790577831375, 36.064104538113575],
              [-81.841840463013966, 36.063839619910098],
              [-81.842172821316495, 36.063879382318639],
              [-81.842322470551807, 36.06308462614242],
              [-81.842654827088438, 36.06312438722734],
              [-81.842804470169227, 36.062329630212531],
              [-81.843136822720865, 36.062369390025474],
              [-81.843286458537804, 36.061574632197953],
              [-81.843618808214046, 36.061614390713132],
              [-81.843718562009755, 36.061084551343008],
              [-81.844050909846572, 36.061124307790756],
              [-81.844250408155006, 36.060064628859813],
              [-81.844582750939225, 36.060104383904367],
              [-81.844832110808596, 36.058779782804528],
              [-81.845164448582153, 36.058819536288958],
              [-81.84546366181911, 36.057230011143297],
              [-81.845795993514457, 36.0572697629364],
              [-81.845945592504876, 36.056474999304172],
              [-81.846277920215513, 36.056514749825524],
              [-81.846477375556674, 36.055455063493262],
              [-81.84680970043415, 36.055494812559921],
              [-81.846959283885155, 36.054700046256499],
              [-81.847291605919295, 36.054739794926419],
              [-81.847341464976779, 36.05447487260416],
              [-81.847673786239326, 36.054514619337979],
              [-81.847723643901418, 36.054249696835271],
              [-81.848055964424091, 36.054289442533879],
              [-81.848105820690975, 36.054024519850749],
              [-81.848438139364347, 36.054064264540024],
              [-81.848487994235853, 36.053799341676481],
              [-81.84882031327875, 36.053839085304659],
              [-81.848870166755006, 36.053574162260816],
              [-81.849534801469844, 36.053653646594462],
              [-81.849584652483358, 36.053388723238946],
              [-81.849916970374139, 36.053428463892729],
              [-81.849966818882905, 36.053163540382691],
              [-81.850631452402851, 36.05324301874181],
              [-81.850681299558246, 36.05297809489435],
              [-81.851013615187497, 36.053017832599643],
              [-81.851063459806397, 36.052752907696799],
              [-81.851728091021869, 36.052832380107219],
              [-81.851777933209888, 36.052567455793749],
              [-81.852110247686767, 36.052607190524739],
              [-81.85216008958902, 36.052342266005034],
              [-81.852824717358587, 36.052421731591799],
              [-81.852874555688786, 36.052156806786492],
              [-81.853206869013334, 36.052196538543178],
              [-81.853256707025821, 36.051931612630774],
              [-81.855250587414545, 36.052169982498917],
              [-81.855300417584758, 36.051905055776466],
              [-81.856629673446875, 36.052063950116988],
              [-81.856579847515164, 36.0523288764631],
              [-81.857244480639906, 36.052408317995265],
              [-81.857294303327023, 36.052143391412841],
              [-81.857958934485168, 36.052222828191773],
              [-81.858008755819043, 36.05195790127204],
              [-81.859670335280242, 36.052156478519301],
              [-81.859720149807387, 36.051891550020066],
              [-81.860052465411556, 36.051931263172136],
              [-81.860102278543678, 36.051666334492573],
              [-81.860434593375771, 36.05170604570867],
              [-81.860534216554456, 36.051176188840891],
              [-81.86086652961076, 36.051215899791629],
              [-81.861065766627661, 36.050156184067312],
              [-81.860733457873664, 36.050116474541717],
              [-81.860783267646283, 36.049851545387206],
              [-81.860450960287224, 36.049811835088711],
              [-81.860550581017478, 36.049281977795481],
              [-81.860882885132099, 36.049321687857756],
              [-81.860932693922564, 36.049056758555949],
              [-81.861264997328988, 36.049096468484052],
              [-81.861364611792965, 36.04856660947106],
              [-81.861696913359623, 36.048606317332101],
              [-81.861746719033022, 36.048341387620944],
              [-81.862079019891596, 36.048381095347793],
              [-81.862128824170142, 36.048116165456506],
              [-81.862461124256541, 36.048155871247417],
              [-81.862560728587042, 36.047626011081441],
              [-81.862893026865763, 36.047665715706323],
              [-81.86294282691793, 36.047400785431705],
              [-81.863275124456464, 36.047440489021483],
              [-81.863374722553118, 36.046910628036926],
              [-81.863707018283932, 36.046950330460611],
              [-81.863756815219105, 36.04668539977677],
              [-81.86405558995736, 36.045095814644071],
              [-81.86438787854317, 36.045135515246457],
              [-81.864537258259773, 36.044340720724634],
              [-81.864869542861143, 36.044380420055894],
              [-81.86501891535292, 36.04358562562696],
              [-81.865351198188648, 36.043625323635219],
              [-81.865400986525827, 36.043360391046399],
              [-81.865733268621341, 36.043400088019595],
              [-81.865832843320334, 36.042870223307872],
              [-81.866165122498998, 36.042909919141081],
              [-81.866214908861025, 36.042644987018228],
              [-81.86626469375453, 36.042380053971534],
              [-81.865932416742709, 36.042340359301399],
              [-81.866081774912558, 36.041545561109949],
              [-81.865749501398042, 36.041505865028093],
              [-81.865799287149542, 36.041240932948739],
              [-81.865467015029566, 36.041201236094103],
              [-81.865566589872031, 36.040671371097993],
              [-81.865234320213958, 36.040631673601489],
              [-81.865333894773016, 36.040101808697926],
              [-81.865001628686059, 36.040062110533654],
              [-81.865399922308796, 36.037942648211526],
              [-81.865732179857432, 36.037982345327229],
              [-81.865781964567191, 36.037717411947945],
              [-81.866114221375739, 36.037757108028778],
              [-81.866263568121951, 36.036962307256751],
              [-81.866595820946785, 36.037002002066664],
              [-81.866745161546902, 36.036207200462158],
              [-81.867077412606278, 36.036246893948999],
              [-81.867127190715124, 36.035981959860919],
              [-81.866794940722997, 36.035942266505067],
              [-81.86699405526501, 36.034882530162974],
              [-81.867326299847107, 36.034922222119782],
              [-81.867376076320269, 36.034657287787851],
              [-81.867708320194339, 36.034696979610615],
              [-81.867758095273302, 36.034432045098896],
              [-81.868090339516428, 36.034471735860762],
              [-81.868140112059891, 36.034206800294406],
              [-81.868472355562844, 36.034246490021509],
              [-81.868522126744097, 36.033981555176197],
              [-81.868854369507048, 36.034021243868438],
              [-81.868904139261886, 36.033756307942461],
              [-81.869236380175579, 36.033795995625908],
              [-81.869286149677478, 36.033531060395028],
              [-81.869618389818882, 36.033570746142757],
              [-81.869717924598049, 36.033040875298887],
              [-81.870050162932245, 36.033080559880666],
              [-81.870199459969001, 36.032285751942631],
              [-81.870531694351726, 36.032325436154473],
              [-81.870680984102705, 36.031530626510211],
              [-81.871013216720158, 36.031570309399264],
              [-81.871062977868945, 36.031305372331161],
              [-81.871395209746282, 36.031345054185373],
              [-81.871444969500871, 36.03108011693768],
              [-81.871777200638064, 36.031119797757029],
              [-81.871876717064211, 36.030589923754441],
              [-81.872208945252979, 36.030629602533267],
              [-81.872457725244828, 36.029304915569959],
              [-81.872125502359438, 36.029265236545285],
              [-81.872225013098955, 36.028735361887868],
              [-81.872557233818085, 36.02877503974964],
              [-81.872905506330611, 36.026920473683219],
              [-81.87323771990738, 36.026960149724182],
              [-81.873486469396184, 36.025635456954667],
              [-81.873154261122338, 36.025595780667871],
              [-81.873204011550015, 36.025330842088934],
              [-81.872871805811187, 36.025291165904214],
              [-81.872921555869823, 36.025026227433791],
              [-81.872589351492778, 36.024986549575551],
              [-81.872688853872347, 36.024456672725044],
              [-81.872356651956125, 36.024416994225071],
              [-81.872406404277044, 36.024152055845015],
              [-81.872738605094227, 36.024191733313174],
              [-81.872788356021204, 36.023926794753592],
              [-81.872456156270957, 36.023887117416528],
              [-81.872605409150353, 36.023092300965132],
              [-81.872937605699818, 36.023131977909159],
              [-81.87298735421021, 36.022867039181676],
              [-81.87331955001963, 36.022906715091025],
              [-81.873369297136335, 36.022641776184145],
              [-81.87370149220574, 36.022681451058766],
              [-81.873751237928545, 36.022416511972352],
              [-81.874083432258004, 36.022456185812231],
              [-81.874232665341566, 36.021661366942325],
              [-81.874564855688106, 36.021701039511612],
              [-81.874614599078797, 36.021436099943323],
              [-81.874946788685293, 36.021475771477959],
              [-81.875096010304347, 36.020680950365936],
              [-81.875428198145997, 36.020720620577812],
              [-81.875527675468277, 36.020190739632582],
              [-81.875859860394144, 36.020230408705011],
              [-81.875909597514237, 36.019965468479207],
              [-81.87624178169996, 36.020005136516964],
              [-81.876341251652931, 36.019475253882554],
              [-81.877005616769495, 36.019554587624022],
              [-81.877055349676624, 36.019289645959248],
              [-81.877719713934439, 36.019368974923353],
              [-81.877769443304246, 36.019104033875159],
              [-81.878433806735359, 36.01918335896265],
              [-81.87848353472161, 36.018918416677195],
              [-81.878815715860426, 36.018958077734474],
              [-81.878865441344075, 36.018693135295806],
              [-81.879197621742861, 36.018732795318471],
              [-81.879247345832951, 36.018467852700468],
              [-81.879579525491522, 36.018507511688497],
              [-81.879629248188081, 36.018242568891189],
              [-81.879961427106608, 36.018282226844732],
              [-81.880309469367702, 36.016427624992737],
              [-81.88040890707363, 36.015897738314294],
              [-81.880741075608611, 36.01593739421174],
              [-81.881039373312774, 36.014347730456123],
              [-81.88137153577388, 36.014387384664268],
              [-81.881470962845356, 36.013857495814761],
              [-81.881138803626598, 36.013817841842375],
              [-81.881287945554874, 36.013023009036367],
              [-81.880955788754008, 36.012983354579482],
              [-81.881005503523824, 36.012718410636829],
              [-81.879676886071181, 36.0125597842448],
              [-81.879627167034627, 36.012824727663606],
              [-81.878962858134742, 36.012745408784198],
              [-81.879112020600374, 36.011950577367188],
              [-81.878779870394894, 36.01191091695199],
              [-81.879028474423833, 36.010586197888394],
              [-81.878696329878224, 36.010546537224521],
              [-81.878795770899302, 36.010016649714267],
              [-81.878463628781404, 36.009976987507905],
              [-81.878612791046905, 36.009182155823623],
              [-81.878280653596306, 36.009142493981457],
              [-81.878330374754057, 36.008877549821115],
              [-81.878662511138003, 36.008917211532292],
              [-81.878712230902522, 36.00865226719273],
              [-81.878380095585143, 36.008612605612512],
              [-81.878429816089508, 36.008347661355828],
              [-81.878761950372578, 36.008387323705961],
              [-81.878861388268263, 36.007857434786075],
              [-81.879193521821492, 36.007897095043703],
              [-81.879243238104053, 36.0076321504067],
              [-81.879575370917266, 36.007671809629855],
              [-81.879625085806666, 36.007406864813852],
              [-81.879957217880033, 36.007446523002436],
              [-81.8800069313763, 36.007181578007327],
              [-81.880339062709723, 36.007221235161502],
              [-81.880388774812857, 36.006956289987251],
              [-81.880720904297476, 36.006995946133117],
              [-81.880820326499773, 36.006466055352206],
              [-81.881152454177908, 36.00650571033264],
              [-81.881202164277099, 36.006240764726051],
              [-81.881534291215289, 36.006280418671992],
              [-81.881583998780343, 36.006015472011619],
              [-81.881916124978588, 36.006055124923158],
              [-81.8821149489129, 36.004995339953432],
              [-81.88244707117164, 36.005034991437597],
              [-81.882496775003176, 36.004770045175768],
              [-81.882164654919663, 36.004730393796322],
              [-81.882214359491243, 36.00446544761747],
              [-81.881882239692004, 36.00442579549167],
              [-81.881931946112175, 36.004160849369569],
              [-81.881599827705926, 36.004121196471253],
              [-81.881649533725195, 36.003856249557238],
              [-81.880985302242721, 36.0037769412599],
              [-81.881035010100447, 36.003511995460677],
              [-81.880038667416471, 36.003393026657747],
              [-81.880187800781499, 36.002598188296624],
              [-81.880519911114092, 36.002637845083541],
              [-81.880619329232943, 36.00210795264978],
              [-81.880951437759251, 36.002147608271315],
              [-81.881100558750958, 36.001352769331262],
              [-81.880104244825802, 36.00123380000818],
              [-81.88015395401024, 36.000968854300751],
              [-81.879821851837804, 36.00092919646675],
              [-81.8798715617299, 36.000664249941437],
              [-81.878543158321634, 36.000505609246467],
              [-81.878592873293499, 36.000240664071868],
              [-81.878260773745751, 36.000201001107968],
              [-81.878360204810065, 35.999671109976049],
              [-81.878409919836372, 35.999406163887741],
              [-81.87807782384634, 35.999366501314313],
              [-81.878525251798735, 35.996981986494298],
              [-81.878857337083559, 35.997021647914877],
              [-81.879006470277361, 35.99622680809459],
              [-81.879338551581355, 35.996266468244869],
              [-81.879487678605528, 35.99547162669986],
              [-81.879155599391495, 35.995431966968603],
              [-81.879304726707787, 35.994637126286634],
              [-81.878972652095413, 35.994597465117998],
              [-81.87902236087497, 35.994332518276764],
              [-81.878690287687476, 35.99429285723668],
              [-81.878789707475718, 35.99376296274562],
              [-81.878457636747086, 35.993723301064229],
              [-81.878606767749361, 35.992928459799401],
              [-81.878274700513558, 35.992888796706787],
              [-81.87832441164106, 35.99262384994541],
              [-81.878656476670002, 35.99266351203228],
              [-81.878805603754188, 35.991868670193696],
              [-81.878473540782906, 35.991829007625171],
              [-81.878771791391586, 35.990239322569273],
              [-81.878439736251806, 35.990199659857289],
              [-81.878489444039602, 35.989934711874483],
              [-81.878539152641622, 35.989669764718649],
              [-81.878871205617031, 35.98970942626773],
              [-81.879169439381513, 35.988119737144565],
              [-81.87950148517865, 35.988159397030472],
              [-81.879650594439539, 35.987364550981368],
              [-81.879982637365089, 35.987404209570947],
              [-81.88008203903577, 35.986874311721984],
              [-81.88041408129645, 35.986913970021092],
              [-81.880463780544019, 35.986649019993195],
              [-81.880795820956635, 35.986688677284214],
              [-81.880845518843969, 35.986423727978568],
              [-81.881177558484836, 35.986463383334446],
              [-81.881227254979791, 35.986198433850134],
              [-81.881559293881068, 35.98623808817181],
              [-81.881658683727537, 35.985708187897366],
              [-81.881990720823268, 35.9857478410539],
              [-81.882090106155971, 35.985217941253829],
              [-81.882422141446142, 35.985257593245173],
              [-81.882571214321629, 35.984462741881018],
              [-81.882903245631852, 35.984502392602366],
              [-81.88295293525195, 35.984237442212986],
              [-81.883284966931029, 35.984277091873977],
              [-81.883334654018185, 35.984012140431275],
              [-81.883666684957575, 35.984051789058107],
              [-81.883766056053219, 35.983521886668946],
              [-81.884098085187162, 35.983561534130608],
              [-81.884346502978303, 35.982236776193623],
              [-81.884678526000556, 35.982276422123341],
              [-81.884777888264182, 35.981746518544334],
              [-81.88510990948096, 35.981786163309017],
              [-81.885159589041393, 35.981521210866717],
              [-81.885491609518553, 35.981560854597291],
              [-81.885541287686834, 35.981295901976566],
              [-81.885873307424376, 35.981335544672895],
              [-81.88592298420042, 35.981070591873618],
              [-81.886255003198343, 35.98111023353588],
              [-81.886404027295413, 35.980315375387221],
              [-81.886736043389831, 35.980355014852563],
              [-81.886835388666228, 35.979825109229694],
              [-81.887167402955072, 35.979864747529966],
              [-81.887316415763621, 35.979069887883036],
              [-81.887648426072758, 35.979109524913497],
              [-81.887698095649355, 35.978844571162966],
              [-81.888030105251119, 35.978884208060251],
              [-81.888129440185864, 35.978354300180904],
              [-81.888461447949922, 35.978393935012257],
              [-81.888511114416318, 35.978128980857441],
              [-81.888843121472974, 35.978168615555539],
              [-81.888942449079067, 35.977638706893991],
              [-81.88927445540638, 35.977678339500017],
              [-81.889324117654382, 35.977413384967114],
              [-81.88965612216596, 35.977453017466189],
              [-81.889755443519576, 35.976923107095402],
              [-81.890087446225692, 35.976962738429485],
              [-81.890137105331434, 35.976697782591536],
              [-81.890801109558183, 35.976777041387543],
              [-81.890850766238401, 35.976512086141241],
              [-81.891182767791392, 35.976551714504069],
              [-81.891232423047455, 35.976286758178603],
              [-81.891896424967669, 35.976366011032042],
              [-81.891946077798451, 35.97610105529818],
              [-81.892278078165745, 35.976140679788877],
              [-81.892327728496312, 35.975875723903016],
              [-81.892659728123917, 35.975915347359688],
              [-81.892709378170991, 35.975650391269198],
              [-81.89304137705885, 35.975690013691747],
              [-81.893091024573451, 35.975425056548403],
              [-81.893755021228074, 35.975504299323383],
              [-81.893804666317308, 35.975239342771687],
              [-81.894136664051828, 35.975278962223129],
              [-81.89418630771695, 35.975014004592261],
              [-81.894518304744039, 35.97505362391059],
              [-81.894567947017379, 35.974788666101553],
              [-81.894899943272293, 35.974828283484889],
              [-81.894949584186278, 35.974563326398503],
              [-81.895281578593114, 35.97460294277419],
              [-81.895331218082916, 35.974337984608582],
              [-81.895663212858395, 35.974377599923997],
              [-81.895712850988872, 35.974112642481046],
              [-81.896376838277746, 35.974191870167026],
              [-81.896426473918311, 35.973926911514091],
              [-81.897090460379587, 35.974006135325837],
              [-81.897140093595041, 35.973741177264714],
              [-81.897472086802964, 35.973780787671906],
              [-81.897521717486143, 35.973515828558071],
              [-81.898185701639946, 35.973595046427583],
              [-81.898136073055994, 35.973860004902235],
              [-81.898800061753292, 35.973939219394737],
              [-81.898849688205814, 35.973674260658385],
              [-81.899181680836861, 35.97371386554444],
              [-81.899231305897729, 35.973448906629919],
              [-81.900227285938627, 35.973567717199799],
              [-81.900475389130477, 35.972242916377276],
              [-81.900525007898096, 35.971977955911782],
              [-81.900856995513095, 35.972017557144078],
              [-81.901154698174622, 35.970427791593238],
              [-81.901204314704032, 35.970162830639595],
              [-81.900872335657155, 35.970123230296743],
              [-81.90097156876152, 35.969593308535799],
              [-81.900639592171927, 35.969553707551512],
              [-81.90073882610443, 35.969023785863307],
              [-81.900406851971979, 35.96898418423762],
              [-81.900555704145603, 35.968189300829629],
              [-81.900223734644044, 35.968149698667084],
              [-81.900273351721239, 35.967884737885363],
              [-81.899941383611207, 35.967845134950629],
              [-81.899991002503953, 35.967580173325267],
              [-81.899659034677143, 35.96754056964469],
              [-81.89970865434212, 35.967275609003885],
              [-81.899044724013635, 35.96719639914258],
              [-81.899143965487767, 35.966666477368307],
              [-81.898812002943373, 35.966626871344765],
              [-81.898861624827589, 35.966361910954959],
              [-81.89852966367468, 35.966322304159256],
              [-81.898628908564248, 35.965792382599041],
              [-81.89829694986831, 35.965752775162109],
              [-81.898445817939304, 35.964957892410204],
              [-81.89811386276574, 35.964918284462748],
              [-81.89856045783263, 35.962533633965371],
              [-81.898892402308491, 35.96257324076128],
              [-81.899140492279045, 35.961248430762737],
              [-81.898808553130323, 35.961208824621167],
              [-81.899155872654987, 35.95935408992856],
              [-81.899487804345753, 35.959393695154176],
              [-81.899587033283765, 35.958863770532489],
              [-81.899255104831852, 35.958824165542275],
              [-81.899403948372964, 35.958029276861197],
              [-81.89907202344277, 35.95798967136065],
              [-81.899220867280505, 35.957194783550101],
              [-81.898888946980279, 35.957155177512909],
              [-81.899186632187138, 35.955565399607323],
              [-81.899518547203641, 35.955605004832918],
              [-81.899865823953476, 35.953750260599968],
              [-81.899533917501714, 35.953710656264086],
              [-81.899633139085623, 35.953180728653884],
              [-81.899301235090135, 35.953141123676865],
              [-81.899350845904706, 35.952876159945518],
              [-81.899018943300064, 35.952836554196494],
              [-81.899068555962145, 35.952571590522737],
              [-81.898736654748518, 35.952531984001752],
              [-81.898835880117772, 35.952002056801803],
              [-81.89850398136015, 35.951962449639787],
              [-81.898652819580292, 35.951167557993287],
              [-81.898801654901277, 35.950372666826198],
              [-81.898469763936077, 35.950333058619329],
              [-81.898519375754148, 35.950068094942928],
              [-81.898187486212151, 35.950028486865087],
              [-81.898336325090838, 35.94923359499419],
              [-81.898004439069879, 35.949193986406137],
              [-81.898054051715306, 35.94892902280435],
              [-81.897722167052834, 35.948889412543465],
              [-81.897821394572233, 35.948359484533988],
              [-81.897157631657336, 35.948280263602975],
              [-81.897207246520836, 35.948015300253417],
              [-81.896875367138733, 35.94797568765059],
              [-81.896974599094179, 35.947445760145769],
              [-81.896642721092292, 35.947406147829085],
              [-81.896741954982843, 35.946876220372857],
              [-81.896410079436762, 35.946836607415207],
              [-81.896459696942244, 35.946571643297354],
              [-81.896127823894872, 35.946532029541522],
              [-81.896623987318264, 35.943882389009325],
              [-81.896955849715809, 35.943922001456073],
              [-81.897055076127558, 35.943392072172259],
              [-81.896723215859808, 35.943352459987338],
              [-81.896772830196014, 35.943087495393137],
              [-81.896440971318995, 35.943047882436417],
              [-81.896490586394421, 35.942782917926529],
              [-81.895826872822795, 35.94270368863949],
              [-81.895876489702346, 35.94243872434479],
              [-81.89554463389986, 35.942399108040796],
              [-81.895594251518602, 35.942134143830344],
              [-81.895262398247041, 35.94209452762918],
              [-81.895312016604848, 35.941829563502999],
              [-81.89498016469156, 35.94178994562909],
              [-81.895079401451994, 35.941260017524932],
              [-81.893088310795804, 35.941022295572765],
              [-81.893137935598247, 35.940757331322303],
              [-81.89280608919205, 35.940717708423271],
              [-81.892855714765759, 35.940452745157984],
              [-81.891860181739574, 35.940333869609617],
              [-81.891909811257548, 35.940068905763461],
              [-81.891246126712005, 35.939989651437926],
              [-81.891295756958343, 35.939724688734216],
              [-81.890963915668905, 35.939685059911085],
              [-81.891013547729813, 35.939420096364493],
              [-81.890681708971087, 35.939380467644412],
              [-81.890731340663137, 35.939115504208416],
              [-81.890399503262827, 35.939075873815703],
              [-81.890449135726129, 35.938810911364996],
              [-81.890780973169413, 35.938850541600601],
              [-81.890979496548795, 35.937790688980165],
              [-81.89131132895109, 35.937830317815376],
              [-81.891609098379931, 35.936240535787967],
              [-81.891940924719634, 35.936280162934843],
              [-81.892139425410434, 35.935220306529992],
              [-81.892471247817056, 35.935259932250396],
              [-81.892620116696648, 35.934465038389867],
              [-81.892951935127201, 35.934504662840958],
              [-81.893051177289053, 35.933974733106176],
              [-81.892719361020013, 35.933935109817916],
              [-81.892818602900576, 35.933405180185417],
              [-81.89248678905426, 35.933365555355465],
              [-81.892536411124482, 35.933100590587379],
              [-81.892204599776363, 35.933060964959552],
              [-81.892254222585422, 35.932796000275872],
              [-81.891922411519886, 35.932756373902784],
              [-81.891972035067838, 35.93249140930363],
              [-81.891640225392635, 35.932451782158815],
              [-81.891689849679565, 35.932186817644251],
              [-81.891026234575463, 35.932107561783518],
              [-81.891075860633606, 35.931842596583543],
              [-81.890744054096956, 35.931802967893859],
              [-81.890793682001672, 35.93153800275212],
              [-81.890461876823181, 35.931498372390017],
              [-81.890511504391256, 35.931233408260049],
              [-81.890179701710721, 35.931193777100056],
              [-81.890229330017618, 35.93092881305455],
              [-81.88989752872746, 35.930889181122964],
              [-81.8902449273575, 35.929034430567889],
              [-81.889913133844701, 35.928994798650706],
              [-81.889962762750187, 35.928729833568909],
              [-81.889630970627721, 35.928690200880155],
              [-81.889779856432284, 35.92789530760372],
              [-81.889448067828909, 35.92785567440545],
              [-81.889547325858501, 35.927325744374699],
              [-81.88921554081746, 35.927286110509684],
              [-81.889314798597596, 35.926756181482695],
              [-81.888983016011167, 35.926716546977232],
              [-81.889082275692587, 35.926186617099766],
              [-81.888750495560856, 35.926146981953757],
              [-81.888849754992748, 35.925617053080153],
              [-81.888517977315558, 35.925577417293653],
              [-81.888815757406405, 35.923987627566234],
              [-81.888483986441244, 35.92394799166339],
              [-81.888583245994411, 35.923418061646018],
              [-81.888251478591187, 35.923378425076422],
              [-81.888301108389769, 35.923113460142424],
              [-81.888632874728543, 35.923153096581011],
              [-81.888980270038743, 35.92129833885182],
              [-81.888648511117808, 35.921258702429427],
              [-81.888797391687049, 35.920463805478398],
              [-81.888465637424545, 35.920424169421295],
              [-81.888564891626672, 35.919894237851302],
              [-81.888233139818368, 35.919854601153887],
              [-81.8882827675112, 35.91958963588106],
              [-81.88795101595305, 35.919549997537494],
              [-81.888000645491985, 35.919285032323366],
              [-81.887668895355986, 35.919245394109318],
              [-81.887917040067208, 35.917920565377031],
              [-81.887585296653285, 35.917880925988584],
              [-81.887684554614211, 35.917350994609606],
              [-81.887352813686576, 35.917311355481743],
              [-81.887402442672752, 35.917046389416754],
              [-81.887070703134967, 35.917006749517576],
              [-81.887120333967246, 35.916741783511284],
              [-81.88678859578701, 35.916702141939822],
              [-81.886838226282421, 35.916437176945514],
              [-81.886506489492135, 35.916397534602808],
              [-81.886556121801206, 35.916132568766386],
              [-81.886224386432986, 35.916092926553191],
              [-81.886274019480453, 35.9158279608016],
              [-81.885942285502082, 35.915788317817125],
              [-81.886091184775282, 35.914993420705237],
              [-81.88575945542236, 35.914953777185353],
              [-81.88605725041954, 35.91336398162894],
              [-81.886156512823817, 35.912834049708721],
              [-81.885824792276964, 35.912794405433964],
              [-81.885874424608332, 35.912529439522665],
              [-81.885542705483488, 35.912489795377617],
              [-81.885592338520908, 35.912224828650437],
              [-81.885260620785814, 35.912185183734152],
              [-81.885310254561546, 35.911920217091989],
              [-81.884978538216146, 35.911880571404367],
              [-81.885028172762404, 35.911615605748267],
              [-81.884696457774524, 35.911575958388475],
              [-81.884746093059164, 35.911310992817405],
              [-81.884414379460893, 35.911271344686384],
              [-81.8844640154838, 35.911006379200373],
              [-81.884132304382803, 35.910966730271966],
              [-81.884181940036427, 35.910701764897091],
              [-81.883518520939418, 35.910622464595654],
              [-81.883568159502801, 35.9103574994108],
              [-81.883236450399352, 35.910317848063904],
              [-81.883286089700974, 35.910052882964095],
              [-81.882622676814179, 35.909973577773293],
              [-81.882672317917937, 35.909708612889659],
              [-81.882340613027139, 35.909668959071965],
              [-81.882390254869009, 35.909403994273454],
              [-81.881726848192415, 35.9093246841934],
              [-81.881776491804303, 35.909059718710154],
              [-81.881444791158088, 35.909020063322572],
              [-81.881494435508131, 35.908755097924548],
              [-81.881162735112099, 35.908715440891108],
              [-81.881212380232355, 35.908450476479047],
              [-81.880548983652773, 35.90837115994141],
              [-81.880598630542892, 35.908106194844684],
              [-81.880266934359355, 35.908066535340474],
              [-81.880316581987671, 35.907801570328957],
              [-81.879984887193686, 35.907761910053658],
              [-81.880034535592216, 35.907496946028218],
              [-81.879702842187896, 35.907457284981795],
              [-81.880000735282167, 35.905867496068133],
              [-81.879669048586706, 35.905827834906155],
              [-81.879718696800253, 35.905562869920608],
              [-81.879387011494373, 35.905523207987514],
              [-81.879436661553314, 35.905258243061041],
              [-81.879104977637013, 35.90521858035688],
              [-81.879154627326614, 35.904953615541771],
              [-81.878822944799865, 35.904913952066529],
              [-81.878922247544111, 35.904384022508552],
              [-81.87859056747071, 35.904344358393452],
              [-81.878938120873485, 35.902489602690224],
              [-81.87860644967941, 35.902449938564615],
              [-81.878755399754141, 35.901655042832907],
              [-81.87842373207684, 35.90161537819862],
              [-81.878473381754304, 35.901350412410977],
              [-81.878141715466455, 35.90131074700561],
              [-81.878191367021302, 35.901045782178137],
              [-81.878523032245468, 35.90108544745236],
              [-81.87867197948529, 35.90029055055215],
              [-81.879335304560925, 35.900369878508293],
              [-81.87938495048229, 35.900104912229473],
              [-81.879716612428936, 35.900144574272716],
              [-81.879766256993051, 35.89987960871791],
              [-81.880097918201784, 35.899919269728002],
              [-81.880346131796955, 35.898594436981924],
              [-81.880677786905977, 35.898634096460412],
              [-81.881025264358897, 35.896779327427268],
              [-81.880693616695069, 35.896739668866672],
              [-81.880792894837811, 35.896209734458033],
              [-81.88046124962689, 35.896170075257587],
              [-81.880510889273353, 35.895905108115841],
              [-81.880179245451671, 35.895865448144427],
              [-81.880228885803788, 35.895600480187191],
              [-81.879897243371232, 35.895560819444746],
              [-81.880046166867928, 35.894765918369181],
              [-81.879714527951734, 35.894726257118052],
              [-81.879764169128194, 35.894461289240475],
              [-81.87981381111841, 35.894196322192023],
              [-81.880145446800441, 35.894235983206976],
              [-81.880195086294165, 35.893971016007875],
              [-81.880526721238368, 35.894010675989655],
              [-81.880576359342939, 35.893745708613899],
              [-81.880907993549229, 35.893785367562494],
              [-81.880957630232686, 35.893520399108922],
              [-81.881289264808316, 35.893560056998197],
              [-81.881338899027384, 35.893295089295002],
              [-81.881670532865272, 35.893334746151147],
              [-81.881720165695228, 35.893069778271148],
              [-81.882051797655876, 35.893109433219365],
              [-81.882151062426473, 35.89257949700805],
              [-81.882482692585768, 35.892619150791994],
              [-81.882631581862583, 35.891824244891083],
              [-81.882963209189242, 35.891863898280526],
              [-81.883012837950275, 35.891598928806538],
              [-81.882681211687085, 35.891559275548133],
              [-81.882780469284185, 35.891029337652775],
              [-81.883112092313212, 35.891068990675045],
              [-81.883211346449045, 35.890539051434274],
              [-81.883542968783956, 35.890578703266172],
              [-81.883691842694816, 35.889783793709178],
              [-81.884023462165132, 35.889823444245813],
              [-81.884122707684412, 35.889293504051246],
              [-81.884454324246207, 35.88933315344979],
              [-81.884553567443703, 35.888803212784786],
              [-81.884885182204314, 35.888842861019121],
              [-81.885034039708287, 35.888047949326172],
              [-81.884702428137913, 35.888008301485122],
              [-81.884900903366827, 35.886948418240479],
              [-81.884569297514673, 35.886908770896447],
              [-81.884817389796325, 35.885583915790789],
              [-81.884916624866889, 35.885053973239685],
              [-81.885248223308011, 35.885093620566977],
              [-81.885397070266805, 35.884298705555835],
              [-81.885728665843644, 35.884338351587886],
              [-81.885827892299218, 35.883808408084349],
              [-81.885496298849006, 35.883768762314517],
              [-81.885545913221463, 35.88350379106312],
              [-81.885877505576303, 35.883543435800874],
              [-81.88597673007915, 35.883013492024951],
              [-81.885645139818706, 35.882973846648419],
              [-81.885843590246836, 35.881913958149191],
              [-81.886240473203728, 35.879794177224284],
              [-81.886572049598797, 35.879833821053971],
              [-81.886770477875316, 35.878773928104629],
              [-81.887102050343174, 35.8788135705081],
              [-81.887151655234717, 35.878548596357241],
              [-81.887483226964747, 35.878588237727747],
              [-81.887582433677835, 35.878058289929086],
              [-81.887914003607051, 35.878097930135468],
              [-81.887963605428425, 35.87783295648763],
              [-81.888295174587583, 35.877872594760184],
              [-81.888344775020585, 35.877607620935926],
              [-81.888676343474273, 35.877647259076475],
              [-81.888874736528734, 35.876587361027049],
              [-81.888543173434556, 35.876547723384661],
              [-81.888592772240727, 35.876282749334223],
              [-81.888261209427966, 35.876243110947115],
              [-81.888360408158348, 35.875713162071762],
              [-81.888028848904042, 35.875673523018818],
              [-81.888376040498571, 35.873818700123117],
              [-81.888044489010881, 35.873779061085983],
              [-81.888292479306983, 35.872454185583223],
              [-81.887960933459595, 35.872414546299616],
              [-81.888010531605786, 35.872149571194711],
              [-81.887678988253498, 35.872109931114167],
              [-81.887728587137431, 35.871844956095195],
              [-81.885739339159016, 35.871607098388075],
              [-81.885788944095296, 35.871342124110576],
              [-81.885457405146283, 35.871302477979697],
              [-81.885507010820149, 35.871037503788074],
              [-81.884512399114257, 35.870918560378193],
              [-81.884562008758294, 35.870653586508681],
              [-81.884230473236698, 35.87061393703295],
              [-81.884280082511367, 35.870348963275603],
              [-81.883285482194253, 35.870230009804928],
              [-81.883335095407034, 35.869965035468773],
              [-81.883003563312684, 35.869925382648212],
              [-81.883053177295082, 35.869660409298994],
              [-81.882721646589104, 35.8696207557078],
              [-81.882771261276716, 35.869355781543696],
              [-81.88243973195901, 35.869316127181889],
              [-81.882489347416211, 35.869051154004765],
              [-81.882157820593648, 35.869011498846191],
              [-81.882207435649264, 35.868746524880336],
              [-81.881875910214944, 35.868706868951172],
              [-81.881925527147089, 35.868441895946141],
              [-81.881594003068798, 35.868402238345439],
              [-81.881643620738345, 35.868137265426405],
              [-81.881312098080443, 35.868097607956152],
              [-81.881411333429782, 35.867567661370487],
              [-81.88107981319088, 35.86752800235984],
              [-81.881129432009658, 35.867263029567923],
              [-81.880797913158972, 35.867223369786657],
              [-81.880847532715208, 35.8669583970809],
              [-81.880516016391624, 35.86691873740385],
              [-81.880565636653074, 35.866653763883271],
              [-81.879571089470701, 35.866534779012973],
              [-81.87962071370174, 35.866269805814632],
              [-81.879289199698718, 35.866230142819312],
              [-81.879338823560317, 35.865965169733244],
              [-81.879007312020121, 35.865925505040245],
              [-81.87910656092447, 35.865395559896371],
              [-81.879438071446017, 35.865435224300874],
              [-81.879537316890989, 35.864905277813726],
              [-81.879868825580417, 35.86494494015318],
              [-81.880017688151085, 35.864150020592234],
              [-81.880349192870909, 35.864189681662737],
              [-81.880398812003563, 35.863924707954283],
              [-81.880067308346341, 35.863885047014975],
              [-81.880166547760709, 35.863355099725425],
              [-81.880498049292541, 35.8633947604024],
              [-81.880944600953583, 35.861009993292583],
              [-81.880613108953483, 35.860970332895249],
              [-81.880761956816599, 35.860175409509431],
              [-81.879436008206071, 35.860016762201035],
              [-81.87910452215975, 35.859977098563782],
              [-81.879154142754814, 35.859712124067322],
              [-81.878822658096468, 35.859672459659663],
              [-81.878872279428705, 35.859407485249385],
              [-81.878540796126231, 35.85936781917043],
              [-81.878590418227731, 35.859102845747458],
              [-81.87825893631323, 35.859063178898005],
              [-81.878358181633061, 35.858533231278827],
              [-81.87802670330781, 35.858493564664961],
              [-81.878076326526127, 35.858228590468819],
              [-81.877744848450021, 35.858188922209798],
              [-81.87779447354427, 35.857923948974758],
              [-81.875474145807232, 35.857646247683874],
              [-81.875523778013559, 35.857381275322439],
              [-81.875192305772785, 35.857341600087032],
              [-81.875241937577343, 35.857076626937165],
              [-81.874247524888716, 35.856957596241109],
              [-81.874297160693885, 35.85669262431491],
              [-81.873965691880088, 35.856652945735618],
              [-81.874015328390257, 35.856387972994895],
              [-81.87368386096432, 35.856348293645276],
              [-81.873783135132953, 35.855818348291692],
              [-81.874114600434012, 35.855858027378829],
              [-81.874164235967598, 35.855593054503601],
              [-81.87449570053171, 35.855632732557886],
              [-81.874545334677464, 35.855367759506606],
              [-81.87487679850453, 35.855407436528182],
              [-81.874926431262381, 35.855142463300766],
              [-81.875589357768135, 35.855221814376819],
              [-81.875638986968909, 35.854956840868248],
              [-81.875970449647568, 35.854996514922732],
              [-81.876020078567251, 35.854731541212132],
              [-81.87635154050885, 35.85477121423385],
              [-81.876450794140268, 35.854241266441818],
              [-81.876782253175875, 35.85428093832568],
              [-81.876831878962349, 35.854015963317316],
              [-81.877163337260868, 35.854055634168333],
              [-81.877262584690257, 35.853525685582866],
              [-81.877594042296039, 35.853565355243802],
              [-81.877693285160277, 35.853035405341963],
              [-81.878024740966666, 35.853075073839037],
              [-81.878421690941309, 35.850955272293326],
              [-81.879416031464018, 35.851074268403934],
              [-81.879465645539597, 35.850809292052816],
              [-81.881785778461023, 35.851086916546542],
              [-81.881934594245607, 35.850291985423397],
              [-81.882266039743413, 35.850331641554654],
              [-81.882414848306951, 35.849536709661947],
              [-81.882746290975135, 35.849576365399216],
              [-81.882845494018483, 35.849046409691582],
              [-81.88317693374853, 35.84908606339026],
              [-81.883276132259681, 35.848556107267861],
              [-81.88360757132908, 35.848595760677497],
              [-81.883657169034521, 35.848330782418195],
              [-81.883988606228073, 35.848370433920344],
              [-81.884038202545995, 35.848105455485275],
              [-81.884369640141301, 35.848145106829605],
              [-81.884419235071618, 35.847880128218662],
              [-81.884750670791064, 35.847919777655605],
              [-81.884849857551643, 35.847389820037655],
              [-81.88518129147171, 35.847429468310793],
              [-81.885280473700405, 35.84689951027827],
              [-81.885611905821065, 35.846939157387681],
              [-81.885711084592401, 35.846409198013575],
              [-81.886042516052427, 35.846448844833894],
              [-81.88614169029205, 35.845918885045386],
              [-81.886473118813839, 35.845958529827222],
              [-81.886572290767234, 35.845428570472855],
              [-81.886903717489758, 35.845468214090943],
              [-81.887002884879337, 35.844938253421326],
              [-81.887334309802398, 35.844977895875708],
              [-81.887383891931236, 35.844712914892447],
              [-81.887715316149354, 35.844752557215273],
              [-81.887814477339461, 35.844222595753763],
              [-81.888145899726027, 35.844262236011929],
              [-81.888294635870182, 35.843467292641847],
              [-81.88862605428875, 35.843506931631431],
              [-81.888675631280421, 35.8432419499876],
              [-81.889007050068273, 35.843281587918348],
              [-81.889056625672666, 35.843016606098885],
              [-81.889388042616844, 35.843056243023291],
              [-81.889487189651803, 35.842526279915816],
              [-81.889818605903145, 35.842565915650411],
              [-81.889868177301523, 35.842300933461416],
              [-81.89019959281579, 35.842340568163436],
              [-81.890249162826862, 35.842075585798916],
              [-81.89058057649747, 35.842115219494602],
              [-81.890630146227934, 35.841850236928231],
              [-81.890961559161383, 35.841889869591448],
              [-81.891060694416367, 35.841359904089266],
              [-81.891392105550366, 35.841399535588884],
              [-81.891491236274646, 35.840869569672876],
              [-81.891822645609295, 35.840909200008873],
              [-81.891921773983597, 35.840379232725674],
              [-81.89225318151891, 35.840418861898073],
              [-81.892352305394738, 35.839888895102241],
              [-81.8926837111308, 35.839928523110949],
              [-81.892782830443835, 35.839398555000514],
              [-81.893114234380576, 35.839438181845658],
              [-81.893163793056971, 35.839173198030082],
              [-81.89349519622418, 35.839212822941874],
              [-81.893594309371466, 35.838682854941503],
              [-81.893925710771612, 35.838722479590693],
              [-81.89397526631673, 35.838457494478796],
              [-81.894306665873302, 35.838497118121687],
              [-81.894405772790364, 35.837967148429762],
              [-81.894455326298683, 35.837702163053542],
              [-81.894123928854242, 35.837662540731102],
              [-81.894173483099678, 35.837397555441676],
              [-81.893842088148745, 35.837357932322547],
              [-81.893891642025011, 35.837092947146033],
              [-81.893560248428699, 35.837053322355693],
              [-81.893609804180855, 35.836788338140636],
              [-81.893278411971508, 35.836748712580118],
              [-81.893327968428537, 35.836483727550771],
              [-81.892996576531985, 35.836444102147155],
              [-81.893046133726173, 35.836179117204551],
              [-81.892714744290743, 35.836139490103392],
              [-81.892764302254321, 35.835874506148571],
              [-81.892432914205813, 35.835834878277154],
              [-81.892482472874264, 35.835569893508136],
              [-81.891819699876194, 35.835490635323453],
              [-81.891869260375898, 35.835225651673248],
              [-81.891537876532638, 35.835186021333833],
              [-81.891587437737272, 35.834921036869368],
              [-81.889599143448009, 35.834683236796138],
              [-81.889549575871513, 35.834948220473834],
              [-81.888886811953, 35.834868946301071],
              [-81.888985952135428, 35.834338979399639],
              [-81.888654572234344, 35.834299341236665],
              [-81.88870414345331, 35.834034357837496],
              [-81.888372764939263, 35.833994718904314],
              [-81.88842233689509, 35.833729735591966],
              [-81.88809096087428, 35.833690095862401],
              [-81.888140532460767, 35.8334251126631],
              [-81.887809157826993, 35.83338547216335],
              [-81.887858731256713, 35.833120489024687],
              [-81.887527358009763, 35.833080847754765],
              [-81.887576931070114, 35.832815864729191],
              [-81.887245559177998, 35.832776221788151],
              [-81.887295134081526, 35.832511238823137],
              [-81.886963763608435, 35.832471596012979],
              [-81.887013339248952, 35.832206613134929],
              [-81.886681970162769, 35.832166969554549],
              [-81.886731546540162, 35.831901986763285],
              [-81.886068812540898, 35.831822696233964],
              [-81.886118389610587, 35.831557713687012],
              [-81.885787024729666, 35.831518067638903],
              [-81.8858366036104, 35.831253084251706],
              [-81.885173875840934, 35.831173789740234],
              [-81.88522345652045, 35.830908806571109],
              [-81.884560732142688, 35.830829507815949],
              [-81.884610314652903, 35.830564525765872],
              [-81.884278954582641, 35.830524875604965],
              [-81.884328537797586, 35.830259892740841],
              [-81.88399717800786, 35.830220241836038],
              [-81.88404676309807, 35.829955259933612],
              [-81.883715405769422, 35.829915607331614],
              [-81.883814574853346, 35.829385642807907],
              [-81.883483220005616, 35.829345990467949],
              [-81.883532805105759, 35.829081007820392],
              [-81.88320145164478, 35.829041354710469],
              [-81.883251038587986, 35.828776372123691],
              [-81.882919686513844, 35.828736718243732],
              [-81.882969273087426, 35.828471735770187],
              [-81.882306573112089, 35.828392424641684],
              [-81.88235616148414, 35.828127442386339],
              [-81.88202481250886, 35.828087786064998],
              [-81.882074402723802, 35.827822803870482],
              [-81.881743055103115, 35.827783145878158],
              [-81.881792646086936, 35.827518164671545],
              [-81.880798609499209, 35.827399186555866],
              [-81.88084820331099, 35.827134204797723],
              [-81.880516860220482, 35.827094543436907],
              [-81.880467265347079, 35.827359525063756],
              [-81.879473232628484, 35.827240535232463],
              [-81.879522830719239, 35.82697555490023],
              [-81.878860147464337, 35.826896224067703],
              [-81.878959346853023, 35.826366261993641],
              [-81.878628007836667, 35.826326595487991],
              [-81.878677608137082, 35.82606161541694],
              [-81.878346270507549, 35.82602194814136],
              [-81.878395871512382, 35.82575696725641],
              [-81.877733199352221, 35.825677630264231],
              [-81.877782802155124, 35.825412649597546],
              [-81.877451467624496, 35.825372979880946],
              [-81.877501071163891, 35.825107999301359],
              [-81.877169739126245, 35.82506832878866],
              [-81.877318552084844, 35.824273387152871],
              [-81.877467362116988, 35.823478445119832],
              [-81.877136035667945, 35.823438774519587],
              [-81.877235243222287, 35.822908813197522],
              [-81.876903920327806, 35.822869141932529],
              [-81.877003127598442, 35.822339180722608],
              [-81.876671807120104, 35.822299507918025],
              [-81.876820619878202, 35.821504566140284],
              [-81.876157986298665, 35.821425219516243],
              [-81.876207592761645, 35.821160239080619],
              [-81.875876277537117, 35.821120564998665],
              [-81.875925883598185, 35.820855583775391],
              [-81.875263257353907, 35.820776233144628],
              [-81.87531286521282, 35.820511252139781],
              [-81.874650242392363, 35.820431898167115],
              [-81.87469985315532, 35.820166917354648],
              [-81.874368542741209, 35.820127239161039],
              [-81.874418154240388, 35.819862258435897],
              [-81.874086845212943, 35.819822579472373],
              [-81.8741364574485, 35.819557598834407],
              [-81.873473843598461, 35.819478238440652],
              [-81.873523457631734, 35.8192132580213],
              [-81.872860847173314, 35.819133893384823],
              [-81.87291046303632, 35.818868914084923],
              [-81.870922648015721, 35.818630798384149],
              [-81.87097226988999, 35.818365818927091],
              [-81.869978370507908, 35.818246749293166],
              [-81.870027996379434, 35.817981771061021],
              [-81.869034103077055, 35.817862692810557],
              [-81.86908373177539, 35.817597714027421],
              [-81.868089844616776, 35.817478628962334],
              [-81.868139476173965, 35.817213650529226],
              [-81.867808182155812, 35.817173953536518],
              [-81.867857814449138, 35.816908975190699],
              [-81.867526521849527, 35.816869278329158],
              [-81.86777468374305, 35.815544386597196],
              [-81.867443397849641, 35.81550468856441],
              [-81.867542662452507, 35.814974731826581],
              [-81.867211377900972, 35.814935033181492],
              [-81.867409907448234, 35.813875119703759],
              [-81.867078628573211, 35.813835420657057],
              [-81.86712826120808, 35.813570442309164],
              [-81.866796983719709, 35.813530742492802],
              [-81.866846617090445, 35.813265764232348],
              [-81.866515342094758, 35.813226063620334],
              [-81.866564976169499, 35.81296108454638],
              [-81.866233701454149, 35.812921383190748],
              [-81.86628333629686, 35.812656405105187],
              [-81.865952062968063, 35.812616702979966],
              [-81.866001698546683, 35.812351724981866],
              [-81.865339156061694, 35.812272317364233],
              [-81.865388794543534, 35.812007339558946],
              [-81.864726255514313, 35.811927929501209],
              [-81.864775894655097, 35.811662951039835],
              [-81.864113360155628, 35.811583537614915],
              [-81.864163001093559, 35.811318559372438],
              [-81.863831735393035, 35.811278851439901],
              [-81.863881377098721, 35.811013874185896],
              [-81.862887585069089, 35.810894743574906],
              [-81.862937230738908, 35.810629766645206],
              [-81.862605968462248, 35.810590055371655],
              [-81.862655613729856, 35.810325077654518],
              [-81.862705259810156, 35.810060100768389],
              [-81.862373999949185, 35.810020387955817],
              [-81.862622228467757, 35.808695500873817],
              [-81.86229097534391, 35.808655787791274],
              [-81.86258884559345, 35.807065921363403],
              [-81.862920092350905, 35.807105633657443],
              [-81.862969734449976, 35.806840655501915],
              [-81.863300981545862, 35.806880365836776],
              [-81.863400262646067, 35.80635040913149],
              [-81.863731506870963, 35.806390119229626],
              [-81.863880423972262, 35.805595182522396],
              [-81.863549184068333, 35.805555473693424],
              [-81.863648461883429, 35.805025515649533],
              [-81.862985986157653, 35.804946094939694],
              [-81.863035626715103, 35.804681116565391],
              [-81.862041918212398, 35.804561979601736],
              [-81.862091562701721, 35.804297000651026],
              [-81.861097861416013, 35.804177855947849],
              [-81.861147507656838, 35.803912877373641],
              [-81.860816275985954, 35.803873161093918],
              [-81.860865924068364, 35.803608182581456],
              [-81.860534693783819, 35.803568465532301],
              [-81.860584341495823, 35.803303487133491],
              [-81.860253112597491, 35.803263769314945],
              [-81.860352411411355, 35.802733813498065],
              [-81.860021184928371, 35.80269409414057],
              [-81.860120484562515, 35.802164138411669],
              [-81.859789260526739, 35.802124418416277],
              [-81.859838910932666, 35.801859441068174],
              [-81.858845243960303, 35.801740276071193],
              [-81.858894897223536, 35.801475299073786],
              [-81.858232457429978, 35.801395851475341],
              [-81.858282112457701, 35.801130873796119],
              [-81.857619676087594, 35.801051422857313],
              [-81.857718990548008, 35.800521468768792],
              [-81.857387774419763, 35.800481742224093],
              [-81.857437431653878, 35.800216764808312],
              [-81.857106218017833, 35.800177037468274],
              [-81.85730485018702, 35.799117128743525],
              [-81.856973640013749, 35.799077401054468],
              [-81.857023298848389, 35.798812423657459],
              [-81.856692091167261, 35.79877269517317],
              [-81.856791408875878, 35.798242740537056],
              [-81.856460203641845, 35.798203011414998],
              [-81.856509862515907, 35.797938034176006],
              [-81.856178658667872, 35.797898304284594],
              [-81.85622831938322, 35.797633327107505],
              [-81.855565914753299, 35.797553863984355],
              [-81.853578710949705, 35.797315454746915],
              [-81.853628379825608, 35.797050478578164],
              [-81.853297182134057, 35.797010740816965],
              [-81.853346851713326, 35.796745763835169],
              [-81.852684458322287, 35.796666285899555],
              [-81.85278380077915, 35.796136333232063],
              [-81.852452607246164, 35.796096593163256],
              [-81.852551950522511, 35.795566640584454],
              [-81.852220759436477, 35.795526899877878],
              [-81.85232010353225, 35.794996947387901],
              [-81.851988914892971, 35.794957206043627],
              [-81.852038588603236, 35.79469222938841],
              [-81.851707401350183, 35.794652487274959],
              [-81.851856420424284, 35.793857558421237],
              [-81.851525237784514, 35.79381781577581],
              [-81.851624584338381, 35.793287863552308],
              [-81.851293403007617, 35.793248119394086],
              [-81.851343077978711, 35.792983143773213],
              [-81.851011898034074, 35.792943398845814],
              [-81.851111247983297, 35.792413446861509],
              [-81.850448894450466, 35.792333955705857],
              [-81.850498571595082, 35.792068979448096],
              [-81.850167395808697, 35.792029232213224],
              [-81.850217073688412, 35.791764256043585],
              [-81.84988590042569, 35.79172450891469],
              [-81.84993557793463, 35.791459532858859],
              [-81.849273234475064, 35.791380035261206],
              [-81.849322914885335, 35.791115059399118],
              [-81.848660574817274, 35.791035557560939],
              [-81.848710255917382, 35.790770581944329],
              [-81.848379087463883, 35.790730830706764],
              [-81.848428770404709, 35.790465855152412],
              [-81.848097603305519, 35.790426102244872],
              [-81.848147286981416, 35.790161126778486],
              [-81.847816121268281, 35.790121373101854],
              [-81.847915489764404, 35.789591422301939],
              [-81.84824665335654, 35.789631175715513],
              [-81.848346017323735, 35.789101224504513],
              [-81.848677179088668, 35.789140975853385],
              [-81.848776539632837, 35.788611024205515],
              [-81.849107698528272, 35.788650775317457],
              [-81.849157377804545, 35.788385799283915],
              [-81.848826218863806, 35.788346048329409],
              [-81.848875898874965, 35.788081072383989],
              [-81.848544742426071, 35.788041320634512],
              [-81.848594422034665, 35.787776343902159],
              [-81.847932112265852, 35.787696838865564],
              [-81.847981794775691, 35.787431862327068],
              [-81.847650641455857, 35.78739210903985],
              [-81.847849371439423, 35.786332204805795],
              [-81.847518222655069, 35.786292450243295],
              [-81.847567905658082, 35.786027473750941],
              [-81.847236758291459, 35.785987719320381],
              [-81.847286442029358, 35.785722742916242],
              [-81.84695529601683, 35.785682986815765],
              [-81.847004980489672, 35.78541801049974],
              [-81.84634269266769, 35.785338495834303],
              [-81.846293006074163, 35.785603471887072],
              [-81.844637286361476, 35.785404668818259],
              [-81.844686978256917, 35.785139693423346],
              [-81.844355837014874, 35.785099930224],
              [-81.844405529645101, 35.784834954917329],
              [-81.844074389788972, 35.784795190949019],
              [-81.844223470044568, 35.784000266038994],
              [-81.843892333694996, 35.783960501564927],
              [-81.843942027143484, 35.783695526348076],
              [-81.84361089217991, 35.783655761105059],
              [-81.843660586363143, 35.783390785976486],
              [-81.843329452785355, 35.783351019964535],
              [-81.843379148809106, 35.783086044898504],
              [-81.843048016617203, 35.783046278117546],
              [-81.843147408702819, 35.782516328144453],
              [-81.842816278925284, 35.782476559825305],
              [-81.842865976092838, 35.782211584892472],
              [-81.842534847732921, 35.78217181670535],
              [-81.842634242105973, 35.781641866998896],
              [-81.842303116192255, 35.781602098174439],
              [-81.842452209011796, 35.780807172771169],
              [-81.842121087710055, 35.780767403415268],
              [-81.842220483068147, 35.780237453802528],
              [-81.841227126308482, 35.78011813934549],
              [-81.841276827232534, 35.779853164856156],
              [-81.840614593888915, 35.779773617928718],
              [-81.840664296607727, 35.779508643659483],
              [-81.839670952199114, 35.779389316459067],
              [-81.839720657772901, 35.779124342541493],
              [-81.83938954467088, 35.779084564838371],
              [-81.839439250979098, 35.778819591009231],
              [-81.838777028977944, 35.778740033139037],
              [-81.838826737080893, 35.778475059529974],
              [-81.838495627076099, 35.778435279388695],
              [-81.838545335913537, 35.778170305868059],
              [-81.837883120106824, 35.778090743121773],
              [-81.837932831844498, 35.777825769795463],
              [-81.837601724936931, 35.777785987216134],
              [-81.837651436303418, 35.777521014004044],
              [-81.836327019487229, 35.777361875131696],
              [-81.836277302806408, 35.777626848743871],
              [-81.835946198632797, 35.777587061636531],
              [-81.835896480534586, 35.777852034172774],
              [-81.834903165682505, 35.777732667078737],
              [-81.834952886960977, 35.777467694937521],
              [-81.834290680439764, 35.777388112644573],
              [-81.834340403481178, 35.777123139822621],
              [-81.833347099875468, 35.7770037600121],
              [-81.833446552478549, 35.776473815904041],
              [-81.833115454416884, 35.776434021087816],
              [-81.833165180721195, 35.77616904866305],
              [-81.832502987695037, 35.776089456592835],
              [-81.83235380030726, 35.776884372071912],
              [-81.832022701071168, 35.776844573389965],
              [-81.831873507571103, 35.777639488085015],
              [-81.831542404375085, 35.777599688133243],
              [-81.831492671875779, 35.777864659771801],
              [-81.831161569082582, 35.777824859663056],
              [-81.831111834060323, 35.778089830251361],
              [-81.83078073053278, 35.778050029110538],
              [-81.830730994124607, 35.778314999523865],
              [-81.830399888757341, 35.778275197376495],
              [-81.830350152068831, 35.778540167589128],
              [-81.830019045967248, 35.778500364409631],
              [-81.829969306787149, 35.778765334472986],
              [-81.829638201057065, 35.778725530235519],
              [-81.829588460491053, 35.778990500123847],
              [-81.827932929121971, 35.778791462119749],
              [-81.827982674988263, 35.778526492890165],
              [-81.826989363504012, 35.778407060393285],
              [-81.826939613351783, 35.778672029253244],
              [-81.826277405900214, 35.778592401604513],
              [-81.824290789219347, 35.778353498901133],
              [-81.824340547851918, 35.77808853109542],
              [-81.823016147817441, 35.777929243935183],
              [-81.823065911469669, 35.777664276587807],
              [-81.822403716081155, 35.777584627855916],
              [-81.822453480421828, 35.777319660754479],
              [-81.822122384276582, 35.777279835169779],
              [-81.822172149351147, 35.777014868156854],
              [-81.82150996015875, 35.776935214549816],
              [-81.821559728101548, 35.776670246830726],
              [-81.821228635054212, 35.776630418808402],
              [-81.821278402656773, 35.776365452104464],
              [-81.82094731210114, 35.776325623287953],
              [-81.820997080406201, 35.776060655771637],
              [-81.82033490128731, 35.775980995726762],
              [-81.82038467252319, 35.775716029306253],
              [-81.820053583928498, 35.775676197176836],
              [-81.820103355898368, 35.775411230844917],
              [-81.819772268721067, 35.77537139884793],
              [-81.819822041393394, 35.775106431703662],
              [-81.819490956707682, 35.775066598912545],
              [-81.819540729008182, 35.774801631882497],
              [-81.819209645708511, 35.774761798322807],
              [-81.819259419879899, 35.774496832256787],
              [-81.818597256346933, 35.774417161799164],
              [-81.818647031175118, 35.774152195078152],
              [-81.818315950973314, 35.774112359081172],
              [-81.818365727640824, 35.773847392423306],
              [-81.817703570335269, 35.773767717991909],
              [-81.817753348796458, 35.773502751554467],
              [-81.816760119537463, 35.773383232623694],
              [-81.816809900852121, 35.773118266538738],
              [-81.816147751015379, 35.773038582730344],
              [-81.816197535229293, 35.772773616840375],
              [-81.814211102084641, 35.772534543646159],
              [-81.814260892362711, 35.77226957940529],
              [-81.813598753057491, 35.772189880792475],
              [-81.813648545097664, 35.771924915871267],
              [-81.813317476472619, 35.77188506626004],
              [-81.813616226011632, 35.770295276668932],
              [-81.813285165145587, 35.77025542602226],
              [-81.813484329558122, 35.769195565050381],
              [-81.81381538618524, 35.769235415169376],
              [-81.81411411495651, 35.767645620313999],
              [-81.814445164446013, 35.767685468766935],
              [-81.814644306199199, 35.766625604140941],
              [-81.814975351744877, 35.766665450265009],
              [-81.81502513477119, 35.766400484332259],
              [-81.815356179583191, 35.766440329424256],
              [-81.815455743612532, 35.765910396239306],
              [-81.815124700888461, 35.765870550510186],
              [-81.815224264627304, 35.76534061744254],
              [-81.815555305200746, 35.765380462006895],
              [-81.81570464516895, 35.764585561236601],
              [-81.816035682889577, 35.764625404505111],
              [-81.816085461156888, 35.764360437063495],
              [-81.8164164981753, 35.764400280200796],
              [-81.816466275056868, 35.764135312584372],
              [-81.81679731020489, 35.764175153814151],
              [-81.816896860870827, 35.763645218188358],
              [-81.817227894257044, 35.763685059155243],
              [-81.817377214600668, 35.762890154097775],
              [-81.817708245134142, 35.762929993768715],
              [-81.817857558259703, 35.762135087954618],
              [-81.81818858590907, 35.762174925428759],
              [-81.818586729908844, 35.760055172480115],
              [-81.818917748333874, 35.760095008925781],
              [-81.819017278955258, 35.759565069995809],
              [-81.819348296661218, 35.759604904350944],
              [-81.819398059855061, 35.759339934252047],
              [-81.819729076858849, 35.759379768476151],
              [-81.81982860014989, 35.758849827886166],
              [-81.820159614223783, 35.758889660070899],
              [-81.820209374352416, 35.758624690480758],
              [-81.820540388798037, 35.758664521607948],
              [-81.820590147509861, 35.758399550942087],
              [-81.820921160116612, 35.758439381062871],
              [-81.820970917474497, 35.758174411123242],
              [-81.821301929347641, 35.758214240212013],
              [-81.821351685288661, 35.757949269196708],
              [-81.821682697533561, 35.757989097227835],
              [-81.821732450983873, 35.757724126063543],
              [-81.822063462495166, 35.757763953062671],
              [-81.822113214560233, 35.757498981723678],
              [-81.822775235367402, 35.757578633684325],
              [-81.82282498498769, 35.75731366203879],
              [-81.823155994252204, 35.757353486099454],
              [-81.823205743592567, 35.757088514253624],
              [-81.823536752123431, 35.757128337282261],
              [-81.823586498973057, 35.756863365287501],
              [-81.823917507875635, 35.756903187258409],
              [-81.823967253340101, 35.756638215089026],
              [-81.824298260403651, 35.756678036053678],
              [-81.824348004482772, 35.756413063709594],
              [-81.824679010812659, 35.756452883642268],
              [-81.82472875350652, 35.756187911123639],
              [-81.825059759102757, 35.756227730024357],
              [-81.825109500380023, 35.755962756430065],
              [-81.825440505242568, 35.756002574298797],
              [-81.825539984763793, 35.755472628520366],
              [-81.825870987833198, 35.755512445225349],
              [-81.825920726014218, 35.75524747123918],
              [-81.826251728349959, 35.755287286912115],
              [-81.826301465177366, 35.755022313652368],
              [-81.826632465674052, 35.755062128319025],
              [-81.826781669855151, 35.754267206111507],
              [-81.827112668604641, 35.754307019457052],
              [-81.827212133953381, 35.753777071150189],
              [-81.82754313090976, 35.753816883331986],
              [-81.827940969602011, 35.751697084664428],
              [-81.828271957303741, 35.751736894917329],
              [-81.828669765478722, 35.749617089786511],
              [-81.828338787356884, 35.749577280562214],
              [-81.828487962390454, 35.748782353123872],
              [-81.828156987772005, 35.748742543394954],
              [-81.828355888579196, 35.747682639448108],
              [-81.82868685896041, 35.747722448649917],
              [-81.828736582028341, 35.747457472881862],
              [-81.829067551676019, 35.747497281051814],
              [-81.829117273327583, 35.747232304208296],
              [-81.829779211481267, 35.747311917584511],
              [-81.829828930688649, 35.747046940434842],
              [-81.830159899194641, 35.747086745641113],
              [-81.830209617017204, 35.746821768317119],
              [-81.830540584789617, 35.746861572491554],
              [-81.830590300122125, 35.746596595018886],
              [-81.83092126716079, 35.746636398161478],
              [-81.83087155288743, 35.746901375765859],
              [-81.831202521279465, 35.746941177239201],
              [-81.831152806634563, 35.747206154958491],
              [-81.831814746576697, 35.747285757270525],
              [-81.831765034798451, 35.747550734284083],
              [-81.832426978161322, 35.747630333259366],
              [-81.83237726817606, 35.747895310493881],
              [-81.833039214928107, 35.747974905231473],
              [-81.833088923900036, 35.747709927707717],
              [-81.833419896689279, 35.747749723144196],
              [-81.833519309295923, 35.74721976685592],
              [-81.833850281397361, 35.74725956110305],
              [-81.833899986168873, 35.746994583213784],
              [-81.834230956431398, 35.747034376454884],
              [-81.834280659786586, 35.746769397490347],
              [-81.834942599200957, 35.746848981909814],
              [-81.83499230011212, 35.746584002639231],
              [-81.835323269264137, 35.746623793817662],
              [-81.835372968790566, 35.746358814372705],
              [-81.835703937177101, 35.746398603618374],
              [-81.835803332061545, 35.745868645264238],
              [-81.836134298655011, 35.745908433346322],
              [-81.836183995086571, 35.745643453510347],
              [-81.836514960946332, 35.745683240560723],
              [-81.836614349609548, 35.745153280523439],
              [-81.836945312570847, 35.745193066436059],
              [-81.836995005907724, 35.744928086209093],
              [-81.837325969272101, 35.744967871965194],
              [-81.837425350609081, 35.744437910270705],
              [-81.837756312180389, 35.74447769486332],
              [-81.837905379688735, 35.743682752032598],
              [-81.838236337270999, 35.743722534454704],
              [-81.838335712222403, 35.743192571782799],
              [-81.838666668043231, 35.743232353942474],
              [-81.838716353403242, 35.742967371973563],
              [-81.839047308458646, 35.743007152200533],
              [-81.839096992465798, 35.742742170958373],
              [-81.839427947892531, 35.742781950127871],
              [-81.839477630515148, 35.742516968711485],
              [-81.839808584102869, 35.742556746875053],
              [-81.839858265309246, 35.742291764383594],
              [-81.84018921816309, 35.742331541515448],
              [-81.840238898016693, 35.742066559750818],
              [-81.840569850136674, 35.742106335850899],
              [-81.840619527468888, 35.741841353036939],
              [-81.840950479960156, 35.741881128079527],
              [-81.841000155939611, 35.741616145992495],
              [-81.841331106591852, 35.741655920029167],
              [-81.841380782260273, 35.741390936841185],
              [-81.841711732178638, 35.74143070984622],
              [-81.841761405357516, 35.741165726509927],
              [-81.842423304051721, 35.741245269556543],
              [-81.842472975892065, 35.740980285888682],
              [-81.842803924667891, 35.741020055930328],
              [-81.842952931776281, 35.740225105255568],
              [-81.843283877699875, 35.740264874002207],
              [-81.843532211027266, 35.738939953203243],
              [-81.843863151948725, 35.738979719490551],
              [-81.843912816579362, 35.738714735071994],
              [-81.844243755693483, 35.738754501254355],
              [-81.84429341893987, 35.738489516661772],
              [-81.844624358425179, 35.738529281786747],
              [-81.844674019182065, 35.738264297046001],
              [-81.845004957901864, 35.738304060238256],
              [-81.845104277427083, 35.737774090340579],
              [-81.845435214385418, 35.737813853270595],
              [-81.845633845234047, 35.736753910638633],
              [-81.845964777176093, 35.736793672167899],
              [-81.846064088531321, 35.736263700334781],
              [-81.846395018680369, 35.736303460700718],
              [-81.846444672242868, 35.736038474151485],
              [-81.846775601657995, 35.736078233485799],
              [-81.846825254941365, 35.735813246736775],
              [-81.847156183622459, 35.735853005039573],
              [-81.847454084678873, 35.734263084799011],
              [-81.847785006194144, 35.734302840536863],
              [-81.847884302542994, 35.733772865904172],
              [-81.848215222297014, 35.733812621379727],
              [-81.848314514122464, 35.733282646340626],
              [-81.848645432083558, 35.733322400653066],
              [-81.848695076449729, 35.733057412938543],
              [-81.849025993645142, 35.733097165318341],
              [-81.849075636627248, 35.732832177429977],
              [-81.849406554225453, 35.732871929653435],
              [-81.849456194718428, 35.732606941617099],
              [-81.849787110445931, 35.732646691933816],
              [-81.849836750659975, 35.732381703697783],
              [-81.850167665653501, 35.732421452983026],
              [-81.850217304483536, 35.732156464573151],
              [-81.850879133359314, 35.732235961081578],
              [-81.850928769746346, 35.73197097236627],
              [-81.851259683596922, 35.732010718688535],
              [-81.85130931749498, 35.73174572982532],
              [-81.851640230611451, 35.731785475116077],
              [-81.85173949641964, 35.731255496973894],
              [-81.852070407743241, 35.731295241101527],
              [-81.852120038548307, 35.731030251848544],
              [-81.852781860052431, 35.731109737140777],
              [-81.852831488414694, 35.730844747582431],
              [-81.853162398595217, 35.730884488747122],
              [-81.853261651090122, 35.730354508365622],
              [-81.853592559509408, 35.730394249268201],
              [-81.85374143427957, 35.729599277495865],
              [-81.854072338710154, 35.729639016228695],
              [-81.854171584825025, 35.729109034873559],
              [-81.854502487462668, 35.729148772443303],
              [-81.85460173012828, 35.728618789755572],
              [-81.854932631004729, 35.728658527063288],
              [-81.855081488284824, 35.727863552347046],
              [-81.855412386277607, 35.727903287459114],
              [-81.85546200403067, 35.727638295662658],
              [-81.855792901289419, 35.727678029743196],
              [-81.855842516553764, 35.727413037798996],
              [-81.856173413078366, 35.727452770848188],
              [-81.856223028063908, 35.727187778704405],
              [-81.856553923886352, 35.727227511623099],
              [-81.856603536383219, 35.726962519331543],
              [-81.857265326821206, 35.727041980404259],
              [-81.857215716442141, 35.727306972958893],
              [-81.857877510297229, 35.727386430695169],
              [-81.857827901711161, 35.727651423470846],
              [-81.858158801289875, 35.727691151094838],
              [-81.85810919343794, 35.727956143960014],
              [-81.858440093263511, 35.727995869940628],
              [-81.858390486145694, 35.728260862895269],
              [-81.858721388491787, 35.728300588982535],
              [-81.858671782108118, 35.728565582026718],
              [-81.859333588787692, 35.728645031790684],
              [-81.859283984197134, 35.728910025055775],
              [-81.859614889635523, 35.728949748706533],
              [-81.859565285747294, 35.729214741160106],
              [-81.859896192569423, 35.729254464042498],
              [-81.859846589447343, 35.729519457486482],
              [-81.860177497653225, 35.729559179600429],
              [-81.860127895233404, 35.729824172232874],
              [-81.860458804823054, 35.729863893578532],
              [-81.860409204274276, 35.730128887175404],
              [-81.861401938136979, 35.730248045306375],
              [-81.861352339335582, 35.73051303928176],
              [-81.862014165641909, 35.730592473498852],
              [-81.861964569706828, 35.730857466768114],
              [-81.862295484422077, 35.730897183108816],
              [-81.862245889253259, 35.731162177368439],
              [-81.862576805320316, 35.731201892039756],
              [-81.862527210885816, 35.731466886388773],
              [-81.863189047249264, 35.731546314169499],
              [-81.863139454576171, 35.731811307838399],
              [-81.86380129325164, 35.731890732308436],
              [-81.863751702371971, 35.732155726198165],
              [-81.864082624376891, 35.732195437639191],
              [-81.863983443793472, 35.73272542645627],
              [-81.864314368209307, 35.73276513635934],
              [-81.864215188445328, 35.733295125270764],
              [-81.863884261879392, 35.73325541420364],
              [-81.863636301443307, 35.73458038373483],
              [-81.863967233304962, 35.73462009545937],
              [-81.863917641296311, 35.734885089190364],
              [-81.864248574509858, 35.734924799245412],
              [-81.864198983235639, 35.735189793065629],
              [-81.864529917833082, 35.735229502352247],
              [-81.864430736428844, 35.735759490128963],
              [-81.864761673501263, 35.735799199679576],
              [-81.864712084476352, 35.736064193610041],
              [-81.865043022900679, 35.736103901491092],
              [-81.864993433505177, 35.73636889553687],
              [-81.865324374418577, 35.736408602623477],
              [-81.865274785757464, 35.736673596758436],
              [-81.867260442233672, 35.736911822119296],
              [-81.867310023434413, 35.736646827221442],
              [-81.867640966620655, 35.736686528007915],
              [-81.86769054754248, 35.736421532910406],
              [-81.868021488889099, 35.736461232691433],
              [-81.868071068427142, 35.736196237420195],
              [-81.868732951080958, 35.736275633993209],
              [-81.868683374766434, 35.73654062950127],
              [-81.869014317086979, 35.736580326582256],
              [-81.868964741507043, 35.736845322179533],
              [-81.869295686316548, 35.736885018465898],
              [-81.869246111471313, 35.737150014152384],
              [-81.869577057664685, 35.737189709670162],
              [-81.869527483553981, 35.737454705445842],
              [-81.869858431099289, 35.737494399294036],
              [-81.869808857723228, 35.737759395158925],
              [-81.870139806684364, 35.737799089139422],
              [-81.870090234043047, 35.738064085093448],
              [-81.870421184388078, 35.73810377830543],
              [-81.870371612449375, 35.738368773447668],
              [-81.870702564178316, 35.738408465890998],
              [-81.870652993006331, 35.738673462023385],
              [-81.870983946119097, 35.7387131536981],
              [-81.870934375649739, 35.738978149018656],
              [-81.871265331251692, 35.739017839898686],
              [-81.87121576151705, 35.739282835308366],
              [-81.87154671739772, 35.739322525445758],
              [-81.871447580210429, 35.739852517276063],
              [-81.871778538534357, 35.73989220677624],
              [-81.871728971049677, 35.740157202295855],
              [-81.872059930757558, 35.740196891027381],
              [-81.872010364007622, 35.740461886636034],
              [-81.872341325099441, 35.740501574598959],
              [-81.872291759084263, 35.740766570296707],
              [-81.872622721560049, 35.740806257490981],
              [-81.872573156279756, 35.741071253277809],
              [-81.875220884958509, 35.741388715667185],
              [-81.875551852519862, 35.741428394865913],
              [-81.875601408233734, 35.741163396995482],
              [-81.875932375060216, 35.741203075162765],
              [-81.875981929390178, 35.740938077118692],
              [-81.876643861865745, 35.741017429589135],
              [-81.876594309622632, 35.741282426995035],
              [-81.877256245515099, 35.741361776127803],
              [-81.877305795639387, 35.741096778459216],
              [-81.878960635703834, 35.741295133531068],
              [-81.879010180174603, 35.741030134262417],
              [-81.879341148095037, 35.74106980226496],
              [-81.879390690108792, 35.740804803749761],
              [-81.880052624804108, 35.740884136791763],
              [-81.880003084909106, 35.741149135569628],
              [-81.881326963535585, 35.741307791351595],
              [-81.881277427586085, 35.741572791513462],
              [-81.881608398836875, 35.741612453346548],
              [-81.881558863590243, 35.741877452696471],
              [-81.881889836224886, 35.741917113760771],
              [-81.881790768014682, 35.742447113471115],
              [-81.882452719567979, 35.742526432497456],
              [-81.882502251179844, 35.742261432864311],
              [-81.883164201943885, 35.742340748928484],
              [-81.883213732185453, 35.7420757480633],
              [-81.883544707547131, 35.742115404600796],
              [-81.883594235331742, 35.741850404489064],
              [-81.883925209958292, 35.741890059995107],
              [-81.883974736327076, 35.741625058808872],
              [-81.884305710218484, 35.741664713283477],
              [-81.884553334174399, 35.740339707810783],
              [-81.884222366717594, 35.740300054867461],
              [-81.884271891140301, 35.740035053427484],
              [-81.884602857569888, 35.740074707140444],
              [-81.884701904428709, 35.739544703844885],
              [-81.885032869063835, 35.739584356395071],
              [-81.885131911401487, 35.739054352694041],
              [-81.885793837406808, 35.739133654568988],
              [-81.885892874209659, 35.738603650173815],
              [-81.886223835579912, 35.738643299498619],
              [-81.886372384068963, 35.737848292221415],
              [-81.886703342585292, 35.737887940252172],
              [-81.886802371875916, 35.737357934008571],
              [-81.887133327492663, 35.737397580902972],
              [-81.887232353399611, 35.736867575128976],
              [-81.887563307189708, 35.736907219959683],
              [-81.887662329681035, 35.736377213754338],
              [-81.887993281676628, 35.736416857422491],
              [-81.888092299647326, 35.735886850812072],
              [-81.888423249848501, 35.735926493317528],
              [-81.888472757840702, 35.735661489804677],
              [-81.889134657128906, 35.735740772753971],
              [-81.889184161573269, 35.73547576896258],
              [-81.889515110628224, 35.735515408505371],
              [-81.889614117498539, 35.734985399606536],
              [-81.889762624836408, 35.734190386405707],
              [-81.889100737611855, 35.734111106831818],
              [-81.889150241138267, 35.733846102017928],
              [-81.889199745445623, 35.733581097135705],
              [-81.888868804454219, 35.73354145671167],
              [-81.889264829119554, 35.73142141824647],
              [-81.888933898031397, 35.731381777946353],
              [-81.889032903619238, 35.730851767933444],
              [-81.889363832588927, 35.730891407971001],
              [-81.889413333284949, 35.730626402783301],
              [-81.889744261551641, 35.730666042690487],
              [-81.889843259820665, 35.730136031025445],
              [-81.890041252501433, 35.729076008091845],
              [-81.890372174737152, 35.72911564631152],
              [-81.89042166994102, 35.728850640593215],
              [-81.892076278346252, 35.729048813983951],
              [-81.89212576903509, 35.728783807541191],
              [-81.892456691064723, 35.728823439197441],
              [-81.892555668214726, 35.728293425048626],
              [-81.892886587344989, 35.728333055568662],
              [-81.892936074943336, 35.728068048737398],
              [-81.893266993338543, 35.728107678226216],
              [-81.893316478448739, 35.727842671247913],
              [-81.893978315197828, 35.727921927236892],
              [-81.894027797833473, 35.727656919053246],
              [-81.894689633792453, 35.727736172080711],
              [-81.894640153242932, 35.72800117962592],
              [-81.895301992583995, 35.728080428414962],
              [-81.895252513861081, 35.728345437081522],
              [-81.895583435629263, 35.728385060244115],
              [-81.895632913292971, 35.728120051446389],
              [-81.89629475457788, 35.728199294835491],
              [-81.896344229831385, 35.727934286634273],
              [-81.896675149348212, 35.727973906860797],
              [-81.896724623186429, 35.727708897585337],
              [-81.897717381607535, 35.727827752445137],
              [-81.897766851944226, 35.727562742734172],
              [-81.89842869076125, 35.727641974536198],
              [-81.898379223647893, 35.727906984483248],
              [-81.898710144016803, 35.727946598277676],
              [-81.898660677638645, 35.728211608313977],
              [-81.898991599422942, 35.728251222240566],
              [-81.898942133747923, 35.7285162314651],
              [-81.899273058020199, 35.728555844596499],
              [-81.899223593112779, 35.728820854811282],
              [-81.899885443610231, 35.728900077761843],
              [-81.8998359804974, 35.729165088196922],
              [-81.900497835513832, 35.729244307783588],
              [-81.900448374195719, 35.729509318439014],
              [-81.901110232626152, 35.729588534687878],
              [-81.901209150733862, 35.729058513711315],
              [-81.901540078300215, 35.729098119772935],
              [-81.901737905287575, 35.728038076817626],
              [-81.902068828940784, 35.728077681454579],
              [-81.902118283006189, 35.727812670949938],
              [-81.902449205924086, 35.727852274555659],
              [-81.902498659679154, 35.727587262950649],
              [-81.902829580788818, 35.727626866452553],
              [-81.902879033160858, 35.727361854674363],
              [-81.903540875305964, 35.727441057788646],
              [-81.903590325235768, 35.727176045706223],
              [-81.904583087135492, 35.72729484279683],
              [-81.904533641488314, 35.727559855246348],
              [-81.904864564586518, 35.727599453190756],
              [-81.904815119642464, 35.727864464828471],
              [-81.905476967791358, 35.727943657404914],
              [-81.905427524674408, 35.728208670163987],
              [-81.906089377309399, 35.728287858475284],
              [-81.906039935987224, 35.728552871454582],
              [-81.91034202458485, 35.729067510304802],
              [-81.910292597782345, 35.729332524019156],
              [-81.910954465470368, 35.729411685827536],
              [-81.9109050393904, 35.729676700689431],
              [-81.91322159439575, 35.729953737769605],
              [-81.913271010850465, 35.7296887220431],
              [-81.913601947997719, 35.729728295283664],
              [-81.913651363069491, 35.729463279384149],
              [-81.91464417213723, 35.729581992437737],
              [-81.914693583707361, 35.729316976103121],
              [-81.916017328665092, 35.729475246466606],
              [-81.916066735674164, 35.729210229565886],
              [-81.91639767112683, 35.729249795239326],
              [-81.916447076753116, 35.728984778165561],
              [-81.916778012542409, 35.729024341880454],
              [-81.916827416785836, 35.728759324633771],
              [-81.917158350766798, 35.728798888245066],
              [-81.917207753627267, 35.728533870825437],
              [-81.917538686840018, 35.728573432504568],
              [-81.917637489471886, 35.728043397277489],
              [-81.91796842092181, 35.728082958695609],
              [-81.918017820693223, 35.727817940888173],
              [-81.918348751374793, 35.727857500374242],
              [-81.918398148658483, 35.727592482420377],
              [-81.919060010010767, 35.727671599304756],
              [-81.919109404819821, 35.727406580145981],
              [-81.919440334385584, 35.727446137570887],
              [-81.91948972894923, 35.727181119113695],
              [-81.921806238427308, 35.727457988636218],
              [-81.921756852383893, 35.727723007983855],
              [-81.922087784504427, 35.727762557307386],
              [-81.922038399197007, 35.728027576744118],
              [-81.922700267631853, 35.728106672926437],
              [-81.922650884119889, 35.728371692583202],
              [-81.92298181932928, 35.728411239468436],
              [-81.922932436553225, 35.728676259214339],
              [-81.923594311165814, 35.728755350520068],
              [-81.923544931290138, 35.729020370459381],
              [-81.924868688600583, 35.729178541892921],
              [-81.924819312639201, 35.72944356231416],
              [-81.925481195369557, 35.729522643342861],
              [-81.925431821171131, 35.729787663083066],
              [-81.926093708418577, 35.729866740746751],
              [-81.926044336048307, 35.730131761607815],
              [-81.926375281183866, 35.730171298319497],
              [-81.926325908444781, 35.730436319296196],
              [-81.926656854995684, 35.730475856139677],
              [-81.92660748406513, 35.730740876277849],
              [-81.927269380255836, 35.730819947526371],
              [-81.927220011120951, 35.731084967884435],
              [-81.928543812659228, 35.73124309827525],
              [-81.928593177556195, 35.73097807739348],
              [-81.929916979996165, 35.731136191957802],
              [-81.929867620474781, 35.731401214237586],
              [-81.930198572668061, 35.731440740540691],
              [-81.930149212777991, 35.731705762936009],
              [-81.930480167458924, 35.731745288443307],
              [-81.930430808305147, 35.732010310927549],
              [-81.931092720755714, 35.732089359503455],
              [-81.931043363397777, 35.732354382207525],
              [-81.931374321167425, 35.732393905276204],
              [-81.931324964545823, 35.732658928069213],
              [-81.933310723203192, 35.732896048390032],
              [-81.93336007239499, 35.732631025739337],
              [-81.93468391335675, 35.732789087435584],
              [-81.934634568403141, 35.733054110609764],
              [-81.934965530787352, 35.733093623907578],
              [-81.935014874681286, 35.73282860060263],
              [-81.935676798267096, 35.732907623335201],
              [-81.935726138613816, 35.732642599753142],
              [-81.936388061405594, 35.732721619524348],
              [-81.936437399277352, 35.732456594737663],
              [-81.936768360098299, 35.732496103142218],
              [-81.936817696587298, 35.73223107818275],
              [-81.937148656671852, 35.7322705855563],
              [-81.937197991778177, 35.732005560424042],
              [-81.937592663241659, 35.729885358703697],
              [-81.937923613008238, 35.729924864026245],
              [-81.937972944147575, 35.729659838385807],
              [-81.938303893177675, 35.72969934267735],
              [-81.938451881446127, 35.728904264211174],
              [-81.938782827620727, 35.728943767210176],
              [-81.938881481616463, 35.728413714154605],
              [-81.93921242599508, 35.728453215991841],
              [-81.939261752017771, 35.728188189707623],
              [-81.939592695627155, 35.72822768961295],
              [-81.939691343479566, 35.727697636683935],
              [-81.940022285293068, 35.727737135427532],
              [-81.940071608227726, 35.727472108756018],
              [-81.940402548199728, 35.727511606495412],
              [-81.940451869752025, 35.727246579651265],
              [-81.940782810125157, 35.727286077233963],
              [-81.940832129157357, 35.727021049342945],
              [-81.941163068793983, 35.727060545894666],
              [-81.941261703770536, 35.726530489725533],
              [-81.941592641611166, 35.726569985115617],
              [-81.941641957555618, 35.72630495683751],
              [-81.941972894659713, 35.726344451196681],
              [-81.942022209221776, 35.72607942274594],
              [-81.942353144484457, 35.72611891610088],
              [-81.942402457664144, 35.725853887477541],
              [-81.942733393262415, 35.725893378873977],
              [-81.942832016566868, 35.725363322141277],
              [-81.943162949264249, 35.725402812402756],
              [-81.943212259389114, 35.725137784293409],
              [-81.943543191349946, 35.725177273523919],
              [-81.943592500059736, 35.724912244341041],
              [-81.944916228031659, 35.725070192613607],
              [-81.944965532180831, 35.72480516286582],
              [-81.94562739544898, 35.724884131803961],
              [-81.945676697156429, 35.724619101752829],
              [-81.946669491285121, 35.724737547566548],
              [-81.946620193828096, 35.725002577082314],
              [-81.946951128036147, 35.725042057924668],
              [-81.946901830210876, 35.725307087556111],
              [-81.947894636779765, 35.725425523299883],
              [-81.947943930288943, 35.725160492401969],
              [-81.948274864650472, 35.725199968769722],
              [-81.948373448581464, 35.724669906587081],
              [-81.948704381146925, 35.724709381793332],
              [-81.948753671568781, 35.724444350508627],
              [-81.949084603397452, 35.72448382468405],
              [-81.949035315139852, 35.724748856072729],
              [-81.950359050991906, 35.724906743368663],
              [-81.950309766649468, 35.725171775238529],
              [-81.950971639199707, 35.725250714184412],
              [-81.951020921423165, 35.724985682053124],
              [-81.95135185767532, 35.725025150031819],
              [-81.951401138516616, 35.724760117728032],
              [-81.95173207403198, 35.724799584675942],
              [-81.95183063259465, 35.724269518780723],
              [-81.952161565208868, 35.724308984593804],
              [-81.952654329237674, 35.721658653082279],
              [-81.952985251580174, 35.721698116688223],
              [-81.95308379816106, 35.721168049269032],
              [-81.953414717569672, 35.721207510839342],
              [-81.953463989333258, 35.720942477387048],
              [-81.953794909109916, 35.720981937899808],
              [-81.953844179491497, 35.72071690427515],
              [-81.954836935435083, 35.720835281876447],
              [-81.954935468808287, 35.72030521191602],
              [-81.955266385625251, 35.720344669364188],
              [-81.955315650768583, 35.720079634190888],
              [-81.954984733906088, 35.720040176900163],
              [-81.955033999819221, 35.719775142716813],
              [-81.954703084338774, 35.719735684656747],
              [-81.954801616145133, 35.719205614651429],
              [-81.954470704243718, 35.719166156826837],
              [-81.954618503264484, 35.718371051885384],
              [-81.954949411987869, 35.718410509317856],
              [-81.954998675887097, 35.718145474141345],
              [-81.955329583906618, 35.718184931443986],
              [-81.955428109723442, 35.71765486067796],
              [-81.95509720382266, 35.717615403636607],
              [-81.955195730467892, 35.71708533296561],
              [-81.954864827008208, 35.717045875285727],
              [-81.955012617149634, 35.716250768471888],
              [-81.954681718328033, 35.716211311158226],
              [-81.954878771181114, 35.715151168089129],
              [-81.95454787688621, 35.715111709497236],
              [-81.954597141197269, 35.7148466741872],
              [-81.953935354588879, 35.714767754591797],
              [-81.953984620695934, 35.714502719501638],
              [-81.953653729487272, 35.714463258471227],
              [-81.953702995193268, 35.714198222596117],
              [-81.953372105399183, 35.714158761697412],
              [-81.953421372946664, 35.713893725884695],
              [-81.953090484501473, 35.71385426331576],
              [-81.953336820519695, 35.712529085237762],
              [-81.953667703668941, 35.712568547153104],
              [-81.953716968176337, 35.712303511118222],
              [-81.954047850589063, 35.712342972002816],
              [-81.954146377622521, 35.711812899520353],
              [-81.954477257134656, 35.711852359270509],
              [-81.954723563193284, 35.710527175797793],
              [-81.95505443773223, 35.710566633994425],
              [-81.955103696481899, 35.710301597235784],
              [-81.95576544437624, 35.710380509766843],
              [-81.955814700685153, 35.710115472705382],
              [-81.956145574073432, 35.710154927940806],
              [-81.956194828967838, 35.709889889806256],
              [-81.956856575665157, 35.709968796388146],
              [-81.956905828151847, 35.709703758851745],
              [-81.957236700389203, 35.709743211126032],
              [-81.957285950356734, 35.709478172543427],
              [-81.957947693647597, 35.709557073229867],
              [-81.95799694231205, 35.70929203521866],
              [-81.958327813365543, 35.709331483630805],
              [-81.958377060648459, 35.709066445447441],
              [-81.960031415522366, 35.709263675158219],
              [-81.960080656049669, 35.708998635405962],
              [-81.960411526952271, 35.709038079058239],
              [-81.960362287451147, 35.709303118040125],
              [-81.961024032308785, 35.709382002005327],
              [-81.961073269658698, 35.709116961861092],
              [-81.961404141528405, 35.709156402813704],
              [-81.961453377496809, 35.708891362497411],
              [-81.961784248596786, 35.708930801518413],
              [-81.961882717481288, 35.708400721401532],
              [-81.962213586785325, 35.708440159261315],
              [-81.962312051157525, 35.707910078744568],
              [-81.962642918665765, 35.707949515443062],
              [-81.962692149845154, 35.707684474528094],
              [-81.963023015511851, 35.70772391022301],
              [-81.963072245342815, 35.707458870036987],
              [-81.963403111377261, 35.707498304674466],
              [-81.963452338689294, 35.707233263442426],
              [-81.964444934444586, 35.707351561590279],
              [-81.964494159361578, 35.707086519898169],
              [-81.96482502456702, 35.707125950674673],
              [-81.964874246998093, 35.706860908837442],
              [-81.965205110361978, 35.706900338610396],
              [-81.9653035532437, 35.706370254523812],
              [-81.965634414811646, 35.706409683135554],
              [-81.965732853181507, 35.70587959864941],
              [-81.966063714058237, 35.705919026073225],
              [-81.966112931700764, 35.705653983637511],
              [-81.966443790736008, 35.70569341005767],
              [-81.966640653814636, 35.704633238478792],
              [-81.966971508935842, 35.704672663476629],
              [-81.967020722849199, 35.704407620573349],
              [-81.967351577233543, 35.704447044540636],
              [-81.967400789732636, 35.704182000564515],
              [-81.967731643380105, 35.70422142350138],
              [-81.967780853426419, 35.703956380281312],
              [-81.968773413091654, 35.704074642399796],
              [-81.968871826935114, 35.703544554124413],
              [-81.969202678693989, 35.703583973069996],
              [-81.969251884609335, 35.70331892827587],
              [-81.968921033942337, 35.703279510361881],
              [-81.969019444715443, 35.702749420965034],
              [-81.968688597593214, 35.702710002385473],
              [-81.968787009195722, 35.702179913084912],
              [-81.970110394557736, 35.702337583581603],
              [-81.970208796432772, 35.701807493972467],
              [-81.970539641711881, 35.70184690840339],
              [-81.970638040180532, 35.701316818368227],
              [-81.970307196986354, 35.701277403297532],
              [-81.970405597356134, 35.700747312430991],
              [-81.970074756635071, 35.700707897622536],
              [-81.970173156729899, 35.70017780687936],
              [-81.969842319520325, 35.700138390504328],
              [-81.969891520143563, 35.699873345201553],
              [-81.969560684315041, 35.699833928057281],
              [-81.969659086713051, 35.699303837589476],
              [-81.969328253324591, 35.699264419806532],
              [-81.969475858787263, 35.698469284161028],
              [-81.969806688966003, 35.69850870065121],
              [-81.969855888715685, 35.69824365523219],
              [-81.970186717086122, 35.698283071619912],
              [-81.970285113468393, 35.697752979496244],
              [-81.970615941147628, 35.697792394696037],
              [-81.970665137780244, 35.697527347991489],
              [-81.970995964722619, 35.697566762160875],
              [-81.971045158902854, 35.697301716212593],
              [-81.971375985075341, 35.697341128450631],
              [-81.971425178979146, 35.697076082303703],
              [-81.971756003310261, 35.697115493538362],
              [-81.97180519583317, 35.696850447219724],
              [-81.97213601942741, 35.696889857423997],
              [-81.972185210569421, 35.696624810933713],
              [-81.9725160334268, 35.69666422010755],
              [-81.972565223187928, 35.696399173445634],
              [-81.972896045308431, 35.696438581589156],
              [-81.972994420609353, 35.69590848700701],
              [-81.97332524093413, 35.695947893989604],
              [-81.973472797725279, 35.695152750979716],
              [-81.973141980577068, 35.695113344388794],
              [-81.973240352625083, 35.694583248495093],
              [-81.972909537883453, 35.694543840364574],
              [-81.973007909656772, 35.694013744594862],
              [-81.973338722280758, 35.694053152464278],
              [-81.973486275454007, 35.693258008083362],
              [-81.973155466006332, 35.693218600605633],
              [-81.97320465154867, 35.692953552509231],
              [-81.97287384348185, 35.692914144262318],
              [-81.972923028656481, 35.692649096282452],
              [-81.972592223107824, 35.692609688140415],
              [-81.972641408986192, 35.692344639349173],
              [-81.972310604818361, 35.692305230437888],
              [-81.972359791433519, 35.692040181736225],
              [-81.972028988646429, 35.692000772055785],
              [-81.972127364165843, 35.691470675664647],
              [-81.972274924494442, 35.690675531234646],
              [-81.972936519836381, 35.690754349290977],
              [-81.972985703461049, 35.690489300212924],
              [-81.974308893806224, 35.690646925907622],
              [-81.974358072874054, 35.690381876266464],
              [-81.974688870214706, 35.69042128053546],
              [-81.974787225267193, 35.68989118086904],
              [-81.975118020812289, 35.689930583977272],
              [-81.975265546729361, 35.689135434706991],
              [-81.97559633938711, 35.689174835622879],
              [-81.975743859231187, 35.688379685592764],
              [-81.976074649034729, 35.688419085217312],
              [-81.976123819866373, 35.688154034712625],
              [-81.976454608933082, 35.688193433306907],
              [-81.976552948617851, 35.687663331886675],
              [-81.976883734817804, 35.687702730248184],
              [-81.976932903670942, 35.687437679332739],
              [-81.977263689100937, 35.687477075763027],
              [-81.977362022620028, 35.686946973575481],
              [-81.97769280625451, 35.68698636884502],
              [-81.977741970920505, 35.686721317572953],
              [-81.978072754922493, 35.686760711785212],
              [-81.978121918208018, 35.686495660341762],
              [-81.978783482884523, 35.686574446761128],
              [-81.978832643697814, 35.686309394114829],
              [-81.981478910018907, 35.686624499976816],
              [-81.981429757674974, 35.686889553667243],
              [-81.98176054415768, 35.686928937852258],
              [-81.981662241659976, 35.687459043542567],
              [-81.981993029477437, 35.687498427116019],
              [-81.981894727810257, 35.688028532903971],
              [-81.982225519171209, 35.688067915811573],
              [-81.982176369465947, 35.688332968761166],
              [-81.982507162207355, 35.688372350899606],
              [-81.982458012134714, 35.688637403965878],
              [-81.983450395465397, 35.688755545374256],
              [-81.983499541257544, 35.688490491943568],
              [-81.984491924274067, 35.688608623962082],
              [-81.984442781658259, 35.68887367778418],
              [-81.987419958710561, 35.689228026400961],
              [-81.987750757624198, 35.689267393373171],
              [-81.989404758219948, 35.689464217413693],
              [-81.989355632268598, 35.68972927312447],
              [-81.990017237498265, 35.689807995605861],
              [-81.989968113342982, 35.690073051536601],
              [-81.990629720905403, 35.690151771607823],
              [-81.990580599650627, 35.69041682773134],
              [-81.99091140495635, 35.690456186097272],
              [-81.990862283334465, 35.69072124233746],
              [-81.991193091124984, 35.690760599906881],
              [-81.991143970240401, 35.691025656236583],
              [-81.99147477941132, 35.691065013036614],
              [-81.991425659264038, 35.691330069455823],
              [-81.991756469815456, 35.691369425486414],
              [-81.991658230640752, 35.691899537561994],
              [-81.993643113440541, 35.692135657316015],
              [-81.993692226818666, 35.691870600065819],
              [-81.994023040679465, 35.691909949928409],
              [-81.99407215157278, 35.691644892534086],
              [-81.994402964696164, 35.69168424136641],
              [-81.994452074242432, 35.691419184701843],
              [-81.994782886595061, 35.691458531603054],
              [-81.994831994760972, 35.691193474767211],
              [-81.995162806409525, 35.691232821539195],
              [-81.995211913161754, 35.690967763631107],
              [-81.995542724072848, 35.691007109372968],
              [-81.995591829444749, 35.690742051293618],
              [-81.995922639618428, 35.690781396005299],
              [-81.995971743609999, 35.690516337754687],
              [-81.996302551908471, 35.690555680562397],
              [-81.99635165455318, 35.690290623041562],
              [-81.997013272238775, 35.690369306543531],
              [-81.997062371306384, 35.690104247847295],
              [-81.997393179589096, 35.690143588568752],
              [-81.99744227838103, 35.68987852967409],
              [-81.999757934533221, 35.690153885996629],
              [-81.999708844257967, 35.690418945776479],
              [-82.000039655111664, 35.690458279299563],
              [-82.000088744328011, 35.690193219389457],
              [-82.001081175573617, 35.690311212367163],
              [-82.001032089533979, 35.690576272668295],
              [-82.002355338846797, 35.690733585190586],
              [-82.002306257825907, 35.690998645944838],
              [-82.003298702172444, 35.691116620808629],
              [-82.003249622902686, 35.691381681940172],
              [-82.00391125727343, 35.69146032698292],
              [-82.003862179800322, 35.691725388334241],
              [-82.004523817607463, 35.691804030939252],
              [-82.004474741897596, 35.69206909160922],
              [-82.005136383107697, 35.692147730875568],
              [-82.005185455561787, 35.691882669071347],
              [-82.005847095905452, 35.691961303576761],
              [-82.005896165920333, 35.69169624147117],
              [-82.00655780543093, 35.691774872116753],
              [-82.006606873006646, 35.691509809709665],
              [-82.006937691632572, 35.691549123566134],
              [-82.006986757828102, 35.691284060987904],
              [-82.007648393925578, 35.69136268574082],
              [-82.007697457682085, 35.691097622861136],
              [-82.00802827515362, 35.691136933757655],
              [-82.008077337529883, 35.690871870706943],
              [-82.007746521117326, 35.690832559940723],
              [-82.007844645919874, 35.690302434004494],
              [-82.008175460214531, 35.690341744510114],
              [-82.008224521593831, 35.690076680435865],
              [-82.008555335150675, 35.690115989911483],
              [-82.008604394078972, 35.689850926594502],
              [-82.008935206898016, 35.689890235039996],
              [-82.009033322744202, 35.689360107094579],
              [-82.009364133766482, 35.689399414379849],
              [-82.00946224621029, 35.688869286010657],
              [-82.00979305543585, 35.688908592135618],
              [-82.009842109581697, 35.688643528223693],
              [-82.00951130141506, 35.688604222228967],
              [-82.00965846614011, 35.687809028809895],
              [-82.009327661471303, 35.687769722306108],
              [-82.009621988604891, 35.686179335148324],
              [-82.009952785816182, 35.686218640897977],
              [-82.010001837776485, 35.685953575807531],
              [-82.010663432148746, 35.686032184319693],
              [-82.010712481670254, 35.685767118928069],
              [-82.011374074071242, 35.685845722707072],
              [-82.011423121153982, 35.685580657014214],
              [-82.01175391679395, 35.685619957874238],
              [-82.011802961392476, 35.685354892037772],
              [-82.0124645514843, 35.685433489897271],
              [-82.012513594748427, 35.685168423732271],
              [-82.012844389233877, 35.685207721632636],
              [-82.01289343111803, 35.684942655296695],
              [-82.013555018900661, 35.685021247236655],
              [-82.013604057241892, 35.684756180626998],
              [-82.013934850572582, 35.684795475567554],
              [-82.013983887500444, 35.684530407886037],
              [-82.014314680093278, 35.68456970179664],
              [-82.014363715674847, 35.684304634845063],
              [-82.015025299672203, 35.684383218805777],
              [-82.015074332781595, 35.684118150652161],
              [-82.01540512421964, 35.684157441603148],
              [-82.015454155982795, 35.683892374179592],
              [-82.016115737636937, 35.683970951320006],
              [-82.01616476696158, 35.683705883595387],
              [-82.017157137602467, 35.683823743095537],
              [-82.017206163429577, 35.683558674939654],
              [-82.017536952478807, 35.683597959227981],
              [-82.017585976926185, 35.683332890901241],
              [-82.01791676634177, 35.683372174132245],
              [-82.017965788271553, 35.683107104761135],
              [-82.018296576982763, 35.683146387863161],
              [-82.018394618899919, 35.682616249612153],
              [-82.018725404676559, 35.682655530680506],
              [-82.018970497991376, 35.681330183254097],
              [-82.019301278794757, 35.681369462771805],
              [-82.019350296085506, 35.68110439265773],
              [-82.019681076150931, 35.68114367114562],
              [-82.019730090957793, 35.680878600888008],
              [-82.020722431040625, 35.680996431436405],
              [-82.020771442350309, 35.680731360747778],
              [-82.02110222158133, 35.68077063537644],
              [-82.021151231511425, 35.680505564517077],
              [-82.021482010004647, 35.680544838115878],
              [-82.021580025680663, 35.68001469604247],
              [-82.021910802377008, 35.680053968481175],
              [-82.022057821965305, 35.6792587546352],
              [-82.02238859470188, 35.679298025811214],
              [-82.022584610956244, 35.678237738721229],
              [-82.022915379778595, 35.678277008476933],
              [-82.022964382266039, 35.678011936691391],
              [-82.023295150350393, 35.678051205417333],
              [-82.023344151424737, 35.677786132559987],
              [-82.023674918771079, 35.677825400256125],
              [-82.023723918499627, 35.677560328129211],
              [-82.02405468510797, 35.677599594795453],
              [-82.024103682319307, 35.677334521624303],
              [-82.024434449293736, 35.677373787233321],
              [-82.024483445159319, 35.677108714792496],
              [-82.024152680347711, 35.677069449286122],
              [-82.024201676917571, 35.676804376033957],
              [-82.023540149269579, 35.676725842609969],
              [-82.023589148773993, 35.676460770450959],
              [-82.02325838593768, 35.676421502533323],
              [-82.023307386146428, 35.676156429563086],
              [-82.02297662468952, 35.67611716087584],
              [-82.023025625669888, 35.6758520888962],
              [-82.022364108043945, 35.675773549028051],
              [-82.0224131096831, 35.675508476394704],
              [-82.021420838825648, 35.675390660235507],
              [-82.021469844424203, 35.675125587924683],
              [-82.021139089171839, 35.675086314202424],
              [-82.021188095508293, 35.674821241981249],
              [-82.020857341635306, 35.674781967489508],
              [-82.020906348709644, 35.674516895358039],
              [-82.020575596216034, 35.674477620096795],
              [-82.020624604028256, 35.674212548055102],
              [-82.020293852913923, 35.674173272024341],
              [-82.020342861464073, 35.67390820007229],
              [-82.020012111729201, 35.673868923272067],
              [-82.020061121017122, 35.673603851409702],
              [-82.019730373765753, 35.673564573812605],
              [-82.019877402847072, 35.67276935747244],
              [-82.019546657988059, 35.672730079393638],
              [-82.01984071387524, 35.671139647303846],
              [-82.020171451278813, 35.671178924628947],
              [-82.020269464098419, 35.670648779442189],
              [-82.01993872881188, 35.670609502377452],
              [-82.020036743569506, 35.670079357262075],
              [-82.019706010720697, 35.670040079558014],
              [-82.019804026278962, 35.669509933640541],
              [-82.019473295867812, 35.669470655297246],
              [-82.019522304224253, 35.669205582408139],
              [-82.019191576296606, 35.669166303268028],
              [-82.01924058539079, 35.668901230468755],
              [-82.018909857738052, 35.668861950586667],
              [-82.019007877114717, 35.668331806028469],
              [-82.018677152970227, 35.668292524578739],
              [-82.018775173147219, 35.667762379218559],
              [-82.018444451473925, 35.667723098030756],
              [-82.01849346213973, 35.667458025420345],
              [-82.018162741845615, 35.667418743463102],
              [-82.018211753249176, 35.667153670942575],
              [-82.018309775093897, 35.666623525780615],
              [-82.018640492212782, 35.666662807347279],
              [-82.018738510657755, 35.666132661763726],
              [-82.019069224876532, 35.666171942197892],
              [-82.019167239921828, 35.665641796192773],
              [-82.019497952378146, 35.665681076367974],
              [-82.019644969332035, 35.664885855766727],
              [-82.019975678900451, 35.664925133750913],
              [-82.020073685367578, 35.664394985948817],
              [-82.020404394277548, 35.664434263646648],
              [-82.02050239734524, 35.663904115423215],
              [-82.020833103321536, 35.663943391087606],
              [-82.020882102780831, 35.663678317249541],
              [-82.021212808019541, 35.663717591884279],
              [-82.021261807170262, 35.66345251694743],
              [-82.021592511704824, 35.663491791453424],
              [-82.021641508372525, 35.663226716373622],
              [-82.021972212135765, 35.663265988948893],
              [-82.022021207458124, 35.663000914599735],
              [-82.022351911587705, 35.663040186117897],
              [-82.022400905497477, 35.662775110697389],
              [-82.022731607785332, 35.662814381213416],
              [-82.022829592560129, 35.662284230892283],
              [-82.023160293051944, 35.662323500248469],
              [-82.023258273289969, 35.661793348632742],
              [-82.023588972019397, 35.661832617730006],
              [-82.023637961151223, 35.661567541718064],
              [-82.0239686591093, 35.661606808884706],
              [-82.024066633190628, 35.661076656507262],
              [-82.024397329352681, 35.661115922514213],
              [-82.02444631540628, 35.660850846121441],
              [-82.02477700972662, 35.66089011112615],
              [-82.024825994401297, 35.66062503456299],
              [-82.025156689087879, 35.660664298510667],
              [-82.025254654255036, 35.660134145031087],
              [-82.025585346041709, 35.660173407846464],
              [-82.025634326534146, 35.659908330930058],
              [-82.025965018686961, 35.65994759268834],
              [-82.026013997767123, 35.659682514700698],
              [-82.026344689182196, 35.659721775429418],
              [-82.026393666917144, 35.659456698172463],
              [-82.02672435649049, 35.659495957899018],
              [-82.026773332846659, 35.659230880471824],
              [-82.027104022786304, 35.659270139141277],
              [-82.027152997729971, 35.659005060642876],
              [-82.027814374246162, 35.659083575078128],
              [-82.028145064088875, 35.659122830918975],
              [-82.028194035537382, 35.658857751990112],
              [-82.028855411859823, 35.658936260768265],
              [-82.028904380904947, 35.658671182440045],
              [-82.029565756358977, 35.65874968645916],
              [-82.029614722967239, 35.658484607830594],
              [-82.03060678649743, 35.658602357145881],
              [-82.030557823063859, 35.658867436164741],
              [-82.031880583903359, 35.659024423461418],
              [-82.031929541966036, 35.658759343048779],
              [-82.032260231375034, 35.658798587733187],
              [-82.032309188059074, 35.658533507150366],
              [-82.0326398778341, 35.65857275077768],
              [-82.032688833139488, 35.658307670024691],
              [-82.033019521072646, 35.658346912649975],
              [-82.033068474999482, 35.658081831726847],
              [-82.03339916216099, 35.658121072421636],
              [-82.033448114742882, 35.657855992229294],
              [-82.033778801166434, 35.65789523189455],
              [-82.033827752369731, 35.657630151532068],
              [-82.034158438055428, 35.657669390167783],
              [-82.034207387846294, 35.657404308734158],
              [-82.034538072827814, 35.657443547241286],
              [-82.034587021240199, 35.657178465637678],
              [-82.035579074863989, 35.65729617357011],
              [-82.03562801978137, 35.657031091536204],
              [-82.036289388360046, 35.657109559034062],
              [-82.036338329736608, 35.656844476727571],
              [-82.036669013431194, 35.656883708546786],
              [-82.036717953429246, 35.65661862607022],
              [-82.037379319662662, 35.656697086750142],
              [-82.037330381781132, 35.656962169486782],
              [-82.037991751445915, 35.65704062772987],
              [-82.037942815326915, 35.657305709785497],
              [-82.038273502818711, 35.657344938110761],
              [-82.038224567437766, 35.657610020256449],
              [-82.03888594322315, 35.657688472721809],
              [-82.038837009672378, 35.65795355598847],
              [-82.039167700207642, 35.657992780974347],
              [-82.039216631629984, 35.657727698506235],
              [-82.04087008047216, 35.657923808474969],
              [-82.040821154375209, 35.658188892494096],
              [-82.041151845727669, 35.658228112110621],
              [-82.041102920368871, 35.658493196219659],
              [-82.041433613066005, 35.658532414165741],
              [-82.04138468844539, 35.658797498364727],
              [-82.041715383658811, 35.658836716414633],
              [-82.041617534435886, 35.659366884079056],
              [-82.04194823208627, 35.659406101489367],
              [-82.041899307517923, 35.659671185855728],
              [-82.042230006512909, 35.659710401595639],
              [-82.04218108378673, 35.659975486024301],
              [-82.042511784194105, 35.660014701895612],
              [-82.042462862172329, 35.660279785513204],
              [-82.043124266064268, 35.66035821481718],
              [-82.043075344735158, 35.660623298682253],
              [-82.043406049323721, 35.660662512087306],
              [-82.043454968456601, 35.660397427218946],
              [-82.044447077622181, 35.660515060828935],
              [-82.04449599325973, 35.660249975530547],
              [-82.045157398876384, 35.660328393780247],
              [-82.045255224957614, 35.659798222543323],
              [-82.045585926129291, 35.659837430058943],
              [-82.045634836527356, 35.659572344278054],
              [-82.045965536960708, 35.659611550764211],
              [-82.046014447084275, 35.659346464785621],
              [-82.046345146779302, 35.659385670242308],
              [-82.046394054420446, 35.659120584121347],
              [-82.046724753343256, 35.659159787647589],
              [-82.046773660743895, 35.658894702229858],
              [-82.047104357824409, 35.658933904754335],
              [-82.047153263812646, 35.658668818265717],
              [-82.0474839601887, 35.658708020661642],
              [-82.047532864798512, 35.658442934002956],
              [-82.047863561506276, 35.658482134440874],
              [-82.047961365441282, 35.657951960798904],
              [-82.048292060386217, 35.657991160978376],
              [-82.048487660182587, 35.656930812694505],
              [-82.04881835007626, 35.656970010581396],
              [-82.048867248166999, 35.656704923280458],
              [-82.049197938426218, 35.656744120110197],
              [-82.049295730427161, 35.656213945156153],
              [-82.049626418889687, 35.656253140826557],
              [-82.04967531279992, 35.655988053173473],
              [-82.050006000524064, 35.656027247814365],
              [-82.050494917900366, 35.653376363115321],
              [-82.050825594257546, 35.653415555585205],
              [-82.050972259157078, 35.652620288578632],
              [-82.050641587078516, 35.65258109647074],
              [-82.050837139618963, 35.651520739230847],
              [-82.051167808568437, 35.651559930791358],
              [-82.051216694569604, 35.651294841258682],
              [-82.051547361676896, 35.651334031817527],
              [-82.051596247403808, 35.651068942087335],
              [-82.05258824750436, 35.651186508878524],
              [-82.052637128632909, 35.650921418746428],
              [-82.052967794903239, 35.650960605447658],
              [-82.053163311952304, 35.649900243100227],
              [-82.052832649914677, 35.649861056918539],
              [-82.052979287509288, 35.649065784904025],
              [-82.053309946372281, 35.649104970696051],
              [-82.053456577879885, 35.648309697032438],
              [-82.05180330484319, 35.648113760971796],
              [-82.051852186652141, 35.64784867002011],
              [-82.050860230691725, 35.647731097057587],
              [-82.050909116492832, 35.647466007329207],
              [-82.050578465445383, 35.647426814090643],
              [-82.050725121828734, 35.646631543310043],
              [-82.051055768564154, 35.646670735285554],
              [-82.051104653085915, 35.646405645398048],
              [-82.051435299083096, 35.646444836344266],
              [-82.051533062775562, 35.645914654443615],
              [-82.051863706976434, 35.64595384423054],
              [-82.052010346942012, 35.645158570691549],
              [-82.051679705915348, 35.645119381294379],
              [-82.051728586322369, 35.644854290165128],
              [-82.051397947777247, 35.644815099970756],
              [-82.051593470405038, 35.643754734675213],
              [-82.051262836411951, 35.643715544101276],
              [-82.051360597905926, 35.643185361024571],
              [-82.051691229816967, 35.643224552239722],
              [-82.051886744735256, 35.642164185090287],
              [-82.052217372700198, 35.64220337398541],
              [-82.052266249589252, 35.641938282419396],
              [-82.052596876816068, 35.641977470285319],
              [-82.052645752293415, 35.641712377648759],
              [-82.052976378781963, 35.641751564485432],
              [-82.053025252915489, 35.641486472580233],
              [-82.053355877562126, 35.641525658415382],
              [-82.053404750283974, 35.641260565439666],
              [-82.053074125591607, 35.641221379762165],
              [-82.053220743942816, 35.640426101915608],
              [-82.052890123847661, 35.640386915700809],
              [-82.052938996302757, 35.640121822854063],
              [-82.052608377551337, 35.640082634968913],
              [-82.052657251882152, 35.639817543085712],
              [-82.052326633404832, 35.639778354458898],
              [-82.052375508439852, 35.639513261765046],
              [-82.052044891374152, 35.639474073269902],
              [-82.052093767147312, 35.639208980666268],
              [-82.05176315256297, 35.639169791374059],
              [-82.051812029074355, 35.638904698860792],
              [-82.051481415833663, 35.638865507898188],
              [-82.051628045518925, 35.638070230538354],
              [-82.051297435805566, 35.638031039967238],
              [-82.051346313119652, 35.63776594665449],
              [-82.051015704784035, 35.637726755314127],
              [-82.05106458287004, 35.637461662992706],
              [-82.050733975878018, 35.637422469981992],
              [-82.050782854702192, 35.637157377750945],
              [-82.050452249121818, 35.637118184871831],
              [-82.050501129753656, 35.636853091802493],
              [-82.050170525550826, 35.63681389815418],
              [-82.050219405817046, 35.636548805202921],
              [-82.049888802992001, 35.636509610785218],
              [-82.049986565818273, 35.63597942589719],
              [-82.04965596539455, 35.635940229939195],
              [-82.049704846851014, 35.635675138030123],
              [-82.049043650216191, 35.635596744549574],
              [-82.048994766644242, 35.635861836198742],
              [-82.048002971452263, 35.635744238379587],
              [-82.047954083317293, 35.636009330527919],
              [-82.047292885615747, 35.635930926634245],
              [-82.047243996149106, 35.636196018455429],
              [-82.046252198270452, 35.636078405494565],
              [-82.046203304206941, 35.636343496913909],
              [-82.045872705231716, 35.636304290646756],
              [-82.045726017880725, 35.637099562475477],
              [-82.045395414948473, 35.637060354946776],
              [-82.045297619643463, 35.637590535107392],
              [-82.044636411644888, 35.637512117705583],
              [-82.044587511396898, 35.637777207466527],
              [-82.04425690625088, 35.637737996850106],
              [-82.044305807556768, 35.637472907219156],
              [-82.043975203788577, 35.637433695833479],
              [-82.044024106935893, 35.637168606265291],
              [-82.043032301827893, 35.637050967075282],
              [-82.042983395507221, 35.637316056253425],
              [-82.042322191953943, 35.637237625361301],
              [-82.042273284301473, 35.637502714212189],
              [-82.041612078775032, 35.637424278589727],
              [-82.04156316868692, 35.637689367140986],
              [-82.041232565362762, 35.637650148301375],
              [-82.041183652793194, 35.637915236710597],
              [-82.04085304873135, 35.637876016841709],
              [-82.040755220516601, 35.638406193281284],
              [-82.040424615762831, 35.638366972225498],
              [-82.040375700117551, 35.638632060255809],
              [-82.040045093522423, 35.638592838198328],
              [-82.039996176465493, 35.638857925158035],
              [-82.039665569132495, 35.638818702071241],
              [-82.039567732010681, 35.639348877413582],
              [-82.039237122882014, 35.639309653167501],
              [-82.039139281226838, 35.639839827217692],
              [-82.0388086703027, 35.63980060181234],
              [-82.038759748472131, 35.640065688183661],
              [-82.038429135706522, 35.640026461776515],
              [-82.038331289003196, 35.640556635041015],
              [-82.038000674441989, 35.640517407474441],
              [-82.037951749535509, 35.640782493466723],
              [-82.037621134236545, 35.640743264870871],
              [-82.037572207952124, 35.641008350693362],
              [-82.037241593019047, 35.640969121040634],
              [-82.037143735200516, 35.641499293262413],
              [-82.036813118438161, 35.641460061549353],
              [-82.036764188007737, 35.641725147921221],
              [-82.03643356940394, 35.641685915206367],
              [-82.036384638699232, 35.641951001380754],
              [-82.036054019357607, 35.64191176763655],
              [-82.036005086137408, 35.642176852767932],
              [-82.035674467195662, 35.642137618867778],
              [-82.035576597679039, 35.64266778875097],
              [-82.035245975837881, 35.642628553719057],
              [-82.035294911780497, 35.642363468026375],
              [-82.03496429131738, 35.642324232225171],
              [-82.035013229135075, 35.642059147496255],
              [-82.034682610016347, 35.642019910024878],
              [-82.034829422480826, 35.641224655144313],
              [-82.034498806855822, 35.641185417163896],
              [-82.034596682737273, 35.640655247896582],
              [-82.03426606954811, 35.640616009277061],
              [-82.034412883523075, 35.63982075368051],
              [-82.034082274964959, 35.639781515425504],
              [-82.034180150704714, 35.639251344507315],
              [-82.033849545686024, 35.639212105585607],
              [-82.033898484133417, 35.638947020197129],
              [-82.033567879355175, 35.638907779632873],
              [-82.033616818540253, 35.638642694334813],
              [-82.032625011541185, 35.638524968482912],
              [-82.032576068079237, 35.638790053418219],
              [-82.031914862508259, 35.638711564169093],
              [-82.031865916610585, 35.63897664880453],
              [-82.031204710205074, 35.638898155698286],
              [-82.031155761871659, 35.639163240033838],
              [-82.029172142890687, 35.638927738380609],
              [-82.029221097570584, 35.638662654825822],
              [-82.029270051930197, 35.638397571231373],
              [-82.02960065254544, 35.638436823485563],
              [-82.029649605527069, 35.638171739721322],
              [-82.029980204334876, 35.638210991874615],
              [-82.030029155938493, 35.637945907940605],
              [-82.030359755078919, 35.637985158136075],
              [-82.030457654106343, 35.637454989916371],
              [-82.03078825145144, 35.637494238952399],
              [-82.030886147082512, 35.636964070313915],
              [-82.03121674152888, 35.637003318218014],
              [-82.031265688358204, 35.636738233695453],
              [-82.031596282066999, 35.636777480570231],
              [-82.031694171545965, 35.636247311173577],
              [-82.032024763459589, 35.63628655688894],
              [-82.032171592621708, 35.63549130119187],
              [-82.032502181682474, 35.635530545617698],
              [-82.032551122313933, 35.635265460494814],
              [-82.032881710637284, 35.635304703891322],
              [-82.032979589894254, 35.634774532337872],
              [-82.033310175318746, 35.634813774602563],
              [-82.033359113944272, 35.634548688172146],
              [-82.033689698631264, 35.634587929407537],
              [-82.033787571736795, 35.63405775709645],
              [-82.034118154628615, 35.634096997172442],
              [-82.03416708910855, 35.633831911291608],
              [-82.034497672332748, 35.633871149409877],
              [-82.034546605434898, 35.633606063359544],
              [-82.034877186817965, 35.633645300476012],
              [-82.034926118542373, 35.633380214256057],
              [-82.035256700325377, 35.633419451216739],
              [-82.03530562953469, 35.633154363953686],
              [-82.035636210580051, 35.633193599885132],
              [-82.035685139515252, 35.632928512424904],
              [-82.036015718719568, 35.632967747354655],
              [-82.036113572410869, 35.63243757208302],
              [-82.036444150923529, 35.632476805825831],
              [-82.036590925335432, 35.631681542245708],
              [-82.036260351099102, 35.631642308865516],
              [-82.036358201589721, 35.631112133198918],
              [-82.036027629822499, 35.631072900080767],
              [-82.036223332263262, 35.630012547865675],
              [-82.036553898730432, 35.630051781392083],
              [-82.036602822733485, 35.629786692426968],
              [-82.036933388463154, 35.629825924924177],
              [-82.037031232324324, 35.629295747543942],
              [-82.037361797362493, 35.629334978854338],
              [-82.037410717220581, 35.629069890439254],
              [-82.037741281487428, 35.629109119819461],
              [-82.037790199968057, 35.628844031234884],
              [-82.037459636724861, 35.62880480108376],
              [-82.037508557046536, 35.628539712562251],
              [-82.037177995180883, 35.628500481642199],
              [-82.037226915136443, 35.628235393238917],
              [-82.036896355751935, 35.628196161522155],
              [-82.036994197887779, 35.627665983928992],
              [-82.036663640938301, 35.627626751573366],
              [-82.036712562048805, 35.62736166331203],
              [-82.036382006476842, 35.627322430187313],
              [-82.03643092829104, 35.627057341115709],
              [-82.036100374096591, 35.627018107221978],
              [-82.036149297785514, 35.626753019114446],
              [-82.035157640294628, 35.626635312429059],
              [-82.035304417787273, 35.625840047266777],
              [-82.034973869102771, 35.6258008102971],
              [-82.035022795707576, 35.625535721651481],
              [-82.034692248400546, 35.625496483912862],
              [-82.034741175742738, 35.625231395357993],
              [-82.034410629813181, 35.62519215685046],
              [-82.034508485652239, 35.62466197988288],
              [-82.034177942157541, 35.624622740736442],
              [-82.034275798831203, 35.624092563871699],
              [-82.033945258874809, 35.624053324058956],
              [-82.033994186685462, 35.623788235725229],
              [-82.032010959978848, 35.62355277784831],
              [-82.032059894917154, 35.623287690228452],
              [-82.031729359316003, 35.623248444382618],
              [-82.031827230313155, 35.622718268384254],
              [-82.031496697146693, 35.622679021899735],
              [-82.03159457008158, 35.622148845976859],
              [-82.031264039349935, 35.622109598853534],
              [-82.031312975859763, 35.621844511427547],
              [-82.030982447608878, 35.621805263507802],
              [-82.031031384822128, 35.621540175271683],
              [-82.030700856845286, 35.621500926610693],
              [-82.030749795899155, 35.621235838437912],
              [-82.030419269299699, 35.621196589007987],
              [-82.030468207987312, 35.620931500953581],
              [-82.030137683868688, 35.620892250727294],
              [-82.03018662329346, 35.620627162763768],
              [-82.030517147491963, 35.620666413733368],
              [-82.030566085539363, 35.620401325600426],
              [-82.030896607863554, 35.620440574667448],
              [-82.030994482020787, 35.619910398896963],
              [-82.031655523400417, 35.619988893813414],
              [-82.031704457315783, 35.61972380517237],
              [-82.033026540824039, 35.619880784570327],
              [-82.032977611137724, 35.620145873731978],
              [-82.033308134691381, 35.620185116218167],
              [-82.033357062217007, 35.619920026954013],
              [-82.034679153195725, 35.62007698744366],
              [-82.03472807620598, 35.619811898520695],
              [-82.035058598141688, 35.619851136279301],
              [-82.035107520844448, 35.619586046258448],
              [-82.035438042042855, 35.619625282987975],
              [-82.035486962264841, 35.619360192825347],
              [-82.035817483829405, 35.61939942849822],
              [-82.035866402674145, 35.619134338166269],
              [-82.036527442408143, 35.619212805708997],
              [-82.03657635881838, 35.618947715077631],
              [-82.038559480526317, 35.619183097123766],
              [-82.038510571563052, 35.619448188508045],
              [-82.038841093846528, 35.619487415987457],
              [-82.03879218451732, 35.619752507490198],
              [-82.039122709247522, 35.619791733272095],
              [-82.039073800655714, 35.620056824865699],
              [-82.039404325693496, 35.620096050807149],
              [-82.03935541894252, 35.620361142463977],
              [-82.041338585439988, 35.62059647722856],
              [-82.041387486950455, 35.620331384763844],
              [-82.042709600116709, 35.620488256106341],
              [-82.042758495974553, 35.620223163109785],
              [-82.043419552362053, 35.620301593126861],
              [-82.043468445785393, 35.620036499831016],
              [-82.044790559238223, 35.620193347628131],
              [-82.044741670044274, 35.620458441444093],
              [-82.045402731338868, 35.620536859744277],
              [-82.045353843939864, 35.620801953781047],
              [-82.046014908628365, 35.620880368745134],
              [-82.045966023024349, 35.621145463002627],
              [-82.046296556905574, 35.621184669266121],
              [-82.046247673142616, 35.62144976358681],
              [-82.047239279877019, 35.621567377373019],
              [-82.047190398966507, 35.621832472044389],
              [-82.047520936241625, 35.621871674971693],
              [-82.047569816094722, 35.621606580170258],
              [-82.050214118415141, 35.621920169254125],
              [-82.050262989489184, 35.621655073373674],
              [-82.0505935275268, 35.621694267949444],
              [-82.050544657510102, 35.621959363959945],
              [-82.050875197994259, 35.621998556838022],
              [-82.050826328715416, 35.622263652939225],
              [-82.051156869507238, 35.622302845976876],
              [-82.051108002035633, 35.622567941240135],
              [-82.051438544204572, 35.622607133508666],
              [-82.051389677504773, 35.622872229763502],
              [-82.051720221016808, 35.622911420362044],
              [-82.051671355054737, 35.623176516707574],
              [-82.052332446290151, 35.62325489634042],
              [-82.052283582123508, 35.623519992906658],
              [-82.052944676752361, 35.623598369202981],
              [-82.05289581434721, 35.623863465088881],
              [-82.053226363767195, 35.623902652455001],
              [-82.05327522508108, 35.623637555538281],
              [-82.053936321627774, 35.623715926440049],
              [-82.053887462428904, 35.6239810236167],
              [-82.054879114456696, 35.624098573123916],
              [-82.054830258110783, 35.624363670651164],
              [-82.055160810472159, 35.624402851851578],
              [-82.055111954830295, 35.624667948568415],
              [-82.055773062660677, 35.624746309432865],
              [-82.055724209917798, 35.625011406342537],
              [-82.056715877215908, 35.625128940835019],
              [-82.056667027326057, 35.625394038095116],
              [-82.057328142582406, 35.625472390488007],
              [-82.057279294488239, 35.625737487968614],
              [-82.060584904761299, 35.626129192668301],
              [-82.060536066923561, 35.626394291408324],
              [-82.060866631427899, 35.62643345704624],
              [-82.060768956943178, 35.626963655569234],
              [-82.061099523882277, 35.627002820567711],
              [-82.061001850200867, 35.627533018292098],
              [-82.06133241957464, 35.627572182651207],
              [-82.061283583312417, 35.62783728158422],
              [-82.062936440193539, 35.628033088739357],
              [-82.062887610003742, 35.628298188254568],
              [-82.063218183409617, 35.628337347478222],
              [-82.063169353958074, 35.628602447083921],
              [-82.063499928707003, 35.628641604637373],
              [-82.063402269857477, 35.629171804018213],
              [-82.0637328470753, 35.629210961833081],
              [-82.063684018780876, 35.629476061580412],
              [-82.064345177359868, 35.629554373843433],
              [-82.064394003538851, 35.629289273836434],
              [-82.065055161279972, 35.629367582243169],
              [-82.065103985024109, 35.629102481937174],
              [-82.065434562781405, 35.629141635126189],
              [-82.065483385148411, 35.628876534651113],
              [-82.065813963236749, 35.628915685882426],
              [-82.065862784226653, 35.628650585238077],
              [-82.066193360473207, 35.628689735468221],
              [-82.066242180120113, 35.628424635555682],
              [-82.066572755628229, 35.628463784756796],
              [-82.066621573863955, 35.628198683774144],
              [-82.066290999379447, 35.628159533801899],
              [-82.066339818353512, 35.627894432909713],
              [-82.066009244176641, 35.627855283096885],
              [-82.06610688328206, 35.627325081454224],
              [-82.065776312609358, 35.627285930073107],
              [-82.065873952552678, 35.6267557285328],
              [-82.065543384314694, 35.626716576512109],
              [-82.065641025095942, 35.626186375074241],
              [-82.065310459292647, 35.626147222414012],
              [-82.065359280261816, 35.625882121766047],
              [-82.06502871693894, 35.625842968308561],
              [-82.065126360000434, 35.625312766253394],
              [-82.06479579804278, 35.625273613085291],
              [-82.064893441942019, 35.62474341113262],
              [-82.064562883488463, 35.624704256396249],
              [-82.064660528225332, 35.624174054546181],
              [-82.064991084529609, 35.624213208122001],
              [-82.065137546049954, 35.623417904212552],
              [-82.064806992918406, 35.623378751026323],
              [-82.065002274659307, 35.622318344582645],
              [-82.064671726043173, 35.622279190115663],
              [-82.064867006904578, 35.621218783563961],
              [-82.064536462838007, 35.621179628717236],
              [-82.06468292330338, 35.620384323781536],
              [-82.064352383832073, 35.620345168397343],
              [-82.064401204405982, 35.620080066803652],
              [-82.064070666311508, 35.620040910650239],
              [-82.064119487623643, 35.619775809147228],
              [-82.063788949802486, 35.619736652252449],
              [-82.063886593549398, 35.619206448487631],
              [-82.063556059266006, 35.619167290925589],
              [-82.063653704953907, 35.618637087236088],
              [-82.063984236018996, 35.61867624456616],
              [-82.064033056757708, 35.618411142096249],
              [-82.064363588188115, 35.618450298369638],
              [-82.064461226626818, 35.617920093953693],
              [-82.064791756227606, 35.617959248167367],
              [-82.064938207198011, 35.617163940437955],
              [-82.065268733979948, 35.617203094264077],
              [-82.065854495128235, 35.614021852893494],
              [-82.066185008437643, 35.614061004290235],
              [-82.066331437524582, 35.613265692250053],
              [-82.066661947947168, 35.613304841457392],
              [-82.066808370988952, 35.612509528676554],
              [-82.067138877455548, 35.612548676623277],
              [-82.067187684153168, 35.612283571535983],
              [-82.067518189915887, 35.612322719354921],
              [-82.067615798066356, 35.61179250976037],
              [-82.067946303102815, 35.611831655491812],
              [-82.067995105624988, 35.61156655005577],
              [-82.068325608854181, 35.611605695687196],
              [-82.068472011333455, 35.610810378755879],
              [-82.068141511310188, 35.610771234414855],
              [-82.068239113481738, 35.610241023192273],
              [-82.067908615857959, 35.610201877310963],
              [-82.067957417522166, 35.609936771771039],
              [-82.067626921274908, 35.60989762512061],
              [-82.067675723677141, 35.609632519671649],
              [-82.067724525760283, 35.609367414183694],
              [-82.068055019893094, 35.609406560574492],
              [-82.068152619849826, 35.608876348349085],
              [-82.068483112187451, 35.608915493581264],
              [-82.068531911164428, 35.608650386815967],
              [-82.068862402763926, 35.608689531019415],
              [-82.068911200398645, 35.608424424986353],
              [-82.069241690122809, 35.608463567287913],
              [-82.069339282286293, 35.607933353944603],
              [-82.06966977024932, 35.607972495988498],
              [-82.069718564778086, 35.607707388678257],
              [-82.071371006197865, 35.607903082006295],
              [-82.071419795155677, 35.607637974909046],
              [-82.072080772070294, 35.607716245862754],
              [-82.072129557457316, 35.607451137593969],
              [-82.072790533533819, 35.607529404692372],
              [-82.072839316487276, 35.60726429612513],
              [-82.073169803378221, 35.607303427759589],
              [-82.073218584989675, 35.607038319924705],
              [-82.073549072245711, 35.607077450502537],
              [-82.073695410788346, 35.606282123700502],
              [-82.07435637884528, 35.606360382309539],
              [-82.074405154871656, 35.606095273056965],
              [-82.074735638855984, 35.606134400418924],
              [-82.07478441350618, 35.605869290997603],
              [-82.075445379214258, 35.605947542793189],
              [-82.075494151431002, 35.605682433073575],
              [-82.075824633188915, 35.605721558408106],
              [-82.075873405132825, 35.605456448492014],
              [-82.076534367422852, 35.605534694402785],
              [-82.076583135830219, 35.605269584216366],
              [-82.077905061078127, 35.605426063803009],
              [-82.077953824937737, 35.605160953058821],
              [-82.079275751093959, 35.605317418644638],
              [-82.079324509302808, 35.60505230737057],
              [-82.079654990271877, 35.605091420496038],
              [-82.079703748207777, 35.604826309025583],
              [-82.080364709056738, 35.60490453412212],
              [-82.080413464525137, 35.604639421452383],
              [-82.08074394437071, 35.604678532522605],
              [-82.080792697394202, 35.604413420613398],
              [-82.081453656996487, 35.604491638868659],
              [-82.081502407586768, 35.604226526661094],
              [-82.082163365246856, 35.604304741089223],
              [-82.082212113403955, 35.604039628583607],
              [-82.082542591637917, 35.60407873386913],
              [-82.082591338418936, 35.603813621194988],
              [-82.082921815948794, 35.603852726352748],
              [-82.082970561319655, 35.603587612609275],
              [-82.083301038111145, 35.603626716738511],
              [-82.083398525816293, 35.603096489776725],
              [-82.083729000777979, 35.603135591846694],
              [-82.084070188046496, 35.601279794057383],
              [-82.083739721587875, 35.60124069286725],
              [-82.084080900568324, 35.599384892287588],
              [-82.084411359627239, 35.599423992569861],
              [-82.084460096361639, 35.599158877936965],
              [-82.084790554716491, 35.599197978091652],
              [-82.084888026183748, 35.598667747521652],
              [-82.084557569977306, 35.598628648527267],
              [-82.08460630682427, 35.598363532849476],
              [-82.084275851993496, 35.59832443308597],
              [-82.084422062758719, 35.597529088039877],
              [-82.08475251331528, 35.597568187442334],
              [-82.084947451553745, 35.5965077257395],
              [-82.08527789930406, 35.596546823696372],
              [-82.085326631475525, 35.596281708508947],
              [-82.089622458798701, 35.59678990229591],
              [-82.089671175772921, 35.596524784511345],
              [-82.09000162587013, 35.596563869625086],
              [-82.09009905785193, 35.596033633652915],
              [-82.090429505084927, 35.596072718537535],
              [-82.090478220092535, 35.595807600349907],
              [-82.090808666552732, 35.595846683305091],
              [-82.090857379081569, 35.595581564977344],
              [-82.09184871830567, 35.595698808032921],
              [-82.09189742734489, 35.595433689277719],
              [-82.092227872965367, 35.595472768378279],
              [-82.092373995000926, 35.594677411492604],
              [-82.092704438871863, 35.594716489277388],
              [-82.092753143419912, 35.594451370136852],
              [-82.093083586552453, 35.59449044689319],
              [-82.093132289690629, 35.594225326683542],
              [-82.093793172591006, 35.594303477296677],
              [-82.093841874434133, 35.594038357662193],
              [-82.094172315305116, 35.594077431490881],
              [-82.094221015738384, 35.593812310787371],
              [-82.094881896321581, 35.593890455489024],
              [-82.094930593253821, 35.59362533541676],
              [-82.095261032966121, 35.593664406289783],
              [-82.095309728488431, 35.593399285148543],
              [-82.095640167462193, 35.593438354993097],
              [-82.095688862746627, 35.593173234556602],
              [-82.095358424829826, 35.593134164841601],
              [-82.095504509668231, 35.592338801944123],
              [-82.095174075275196, 35.592299732620141],
              [-82.0952227713654, 35.592034611387895],
              [-82.094892338313258, 35.591995540393668],
              [-82.094989730583507, 35.591465299002152],
              [-82.094659299963695, 35.591426227368252],
              [-82.094707997212325, 35.591161106279934],
              [-82.094377568002386, 35.591122034777875],
              [-82.094426265989597, 35.59085691378062],
              [-82.094095839223542, 35.59081784058035],
              [-82.094144536846116, 35.590552719702366],
              [-82.094474962555267, 35.59059179277304],
              [-82.094523659905505, 35.590326671699003],
              [-82.094854084876118, 35.590365743741273],
              [-82.094902779748111, 35.590100622527203],
              [-82.095563629633887, 35.590178763628103],
              [-82.095612322073563, 35.589913642116436],
              [-82.095942745885637, 35.589952711203146],
              [-82.095991436950101, 35.589687589523429],
              [-82.096652283381246, 35.589765723840422],
              [-82.096700972013437, 35.589500601863108],
              [-82.098353089498801, 35.589695921701356],
              [-82.09840177142523, 35.589430799065838],
              [-82.099393041911028, 35.589547979991764],
              [-82.099441721451299, 35.589282856901008],
              [-82.099111298683141, 35.589243797320385],
              [-82.099208657819631, 35.588713551311038],
              [-82.098878237483504, 35.588674491090785],
              [-82.09892691818257, 35.588409368144013],
              [-82.098596499221756, 35.588370307154563],
              [-82.09874254144232, 35.587574937599932],
              [-82.098412126004774, 35.587535877001422],
              [-82.098655528209932, 35.586210259765878],
              [-82.098985937294771, 35.586249320645599],
              [-82.0990346161514, 35.585984196582224],
              [-82.099365024463197, 35.586023255532702],
              [-82.099462378039519, 35.585493007961055],
              [-82.099131971806784, 35.585453948368794],
              [-82.0991806486401, 35.585188825119559],
              [-82.099511053781498, 35.585227883681313],
              [-82.099559729205183, 35.584962759363243],
              [-82.099890133642603, 35.585001817797661],
              [-82.100036155936664, 35.584206444196951],
              [-82.100366557521909, 35.584245501343915],
              [-82.100512573746812, 35.583450126109],
              [-82.100182175366328, 35.583411070251692],
              [-82.100425532810149, 35.58208544448933],
              [-82.100095141134503, 35.582046388353007],
              [-82.100241154924475, 35.581251012104687],
              [-82.10057154456733, 35.58129006872511],
              [-82.100620214125954, 35.581024942802649],
              [-82.100950601927551, 35.581063998422955],
              [-82.101047938011291, 35.580533747105221],
              [-82.10137832398317, 35.580572800666879],
              [-82.101426990508216, 35.580307675271627],
              [-82.101757375741713, 35.580346727804958],
              [-82.101708710307719, 35.580611854230646],
              [-82.102699871121828, 35.580729007728216],
              [-82.102748533385636, 35.58046388091379],
              [-82.103078919926261, 35.580502930753362],
              [-82.103127579712435, 35.580237803799434],
              [-82.103457965480047, 35.580276851709847],
              [-82.103506625028615, 35.580011725460906],
              [-82.103837010057788, 35.580050772343085],
              [-82.103885667094175, 35.579785645053619],
              [-82.104216051384796, 35.579824690907607],
              [-82.104313363527112, 35.579294436827958],
              [-82.104643746022433, 35.579333481524102],
              [-82.104692399991123, 35.579068353861054],
              [-82.105022781747891, 35.579107397529199],
              [-82.105071434341696, 35.57884226969847],
              [-82.105401815359997, 35.578881312338289],
              [-82.105353163822855, 35.579146440298473],
              [-82.106013927858598, 35.579224524070334],
              [-82.106062577282472, 35.578959395851129],
              [-82.10672334154566, 35.579037474840121],
              [-82.106771988538, 35.578772346323632],
              [-82.107102370089976, 35.578811384340625],
              [-82.107151014604725, 35.578546255684678],
              [-82.107481395418176, 35.578585292673488],
              [-82.107627326089826, 35.577789906031917],
              [-82.107957704051245, 35.577828941733607],
              [-82.108006345146009, 35.577563811765167],
              [-82.108336722368804, 35.577602846438616],
              [-82.108385362123329, 35.57733771720352],
              [-82.1087157386076, 35.577376750848821],
              [-82.108764376987438, 35.577111621446136],
              [-82.109094752733071, 35.577150654063253],
              [-82.109143389703718, 35.576885523592011],
              [-82.109473763607795, 35.576924555209295],
              [-82.109522400341149, 35.576659425443175],
              [-82.110513521859872, 35.576776513585195],
              [-82.110562154002494, 35.576511383420829],
              [-82.111222901514267, 35.576589437120269],
              [-82.111271531225654, 35.576324306658776],
              [-82.111601903867765, 35.576363332495646],
              [-82.111650532169961, 35.576098200965646],
              [-82.111980904073292, 35.576137225774396],
              [-82.112029531000999, 35.575872094076928],
              [-82.112359902165622, 35.575911117857494],
              [-82.112408527718657, 35.575645985992466],
              [-82.11273889814467, 35.575685008744905],
              [-82.11278752232316, 35.57541987671236],
              [-82.113448262015567, 35.575497919262553],
              [-82.113496883762863, 35.575232786932943],
              [-82.114157622579, 35.575310824728703],
              [-82.11420624079247, 35.575045692130416],
              [-82.11453660963798, 35.57508471000137],
              [-82.11458522757961, 35.574819577207286],
              [-82.114915595686469, 35.574858594050134],
              [-82.115012826297431, 35.574328328145619],
              [-82.115343192574187, 35.574367342929996],
              [-82.115391806346224, 35.574102209791199],
              [-82.115722171918947, 35.574141224448461],
              [-82.11577078431651, 35.573876091142218],
              [-82.116431514267489, 35.57395411660098],
              [-82.116480124234016, 35.573688982997844],
              [-82.117140853308413, 35.573767003702272],
              [-82.117189459741141, 35.573501869830558],
              [-82.118510917546345, 35.573657901740049],
              [-82.118559519400179, 35.573392766411793],
              [-82.119220248096198, 35.573470776715844],
              [-82.119268848656432, 35.573205641963391],
              [-82.120259939758967, 35.573322648968471],
              [-82.120308535728938, 35.573057513818],
              [-82.120638899376289, 35.573096514216992],
              [-82.12078468217517, 35.572301107248826],
              [-82.121115042970288, 35.572340106360862],
              [-82.121163635556087, 35.572074970799846],
              [-82.121493995612397, 35.572113968883919],
              [-82.121542586789204, 35.571848832254517],
              [-82.121872945003886, 35.571887829338927],
              [-82.121970124325941, 35.571357556608419],
              [-82.122300480745125, 35.571396552535468],
              [-82.122446243055904, 35.570601142345005],
              [-82.122115889806551, 35.570562146806054],
              [-82.12235882553, 35.569236462452523],
              [-82.12268917349671, 35.569275457344467],
              [-82.122834928149103, 35.568480045343122],
              [-82.123223590569381, 35.566358942369924],
              [-82.12355392612956, 35.566397934968435],
              [-82.123748246025087, 35.565337381591611],
              [-82.124078577677054, 35.565376372774054],
              [-82.12417573246897, 35.564846094727336],
              [-82.124506062325665, 35.564885084752504],
              [-82.124603213769134, 35.564354807196828],
              [-82.124933541795826, 35.564393795163738],
              [-82.124982116002172, 35.564128656650396],
              [-82.125312443324731, 35.564167644490375],
              [-82.125361016122369, 35.563902504908853],
              [-82.125691342671217, 35.56394149082],
              [-82.125739914095021, 35.563676351071337],
              [-82.126070239939722, 35.563715336855523],
              [-82.126118808886929, 35.563450196968191],
              [-82.126449133992779, 35.563489181724528],
              [-82.126497702668686, 35.563224041641611],
              [-82.126828027035629, 35.563263025370013],
              [-82.126925160219415, 35.562732744860647],
              [-82.127255481653705, 35.562771726559347],
              [-82.127401176430368, 35.561976305110043],
              [-82.127731495012981, 35.562015285522044],
              [-82.127780058614661, 35.561750144824721],
              [-82.128440695757021, 35.561828103567166],
              [-82.128489255825983, 35.561562962601755],
              [-82.129149892125312, 35.561640917491502],
              [-82.12919844976409, 35.561375776229688],
              [-82.129859085220517, 35.561453727266716],
              [-82.12990764042901, 35.56118858570855],
              [-82.130568273939801, 35.561266532921302],
              [-82.130616826683365, 35.56100139016592],
              [-82.131277460488405, 35.561079334398464],
              [-82.131326010801914, 35.560814191346722],
              [-82.131986642626387, 35.560892130854143],
              [-82.132035190509669, 35.560626987506033],
              [-82.132695822593462, 35.560704923132356],
              [-82.132744366944038, 35.560439779516379],
              [-82.133074681871761, 35.560478746317187],
              [-82.133123225951209, 35.560213602505769],
              [-82.133453540139911, 35.56025256827877],
              [-82.133599165100065, 35.559457136287641],
              [-82.133929476402102, 35.559496099873293],
              [-82.133978015997016, 35.559230955681166],
              [-82.134026555274531, 35.558965811451316],
              [-82.134356864816013, 35.559004774780853],
              [-82.134453940306528, 35.558474485949745],
              [-82.134784248017908, 35.558513447221223],
              [-82.134832784230952, 35.558248302619901],
              [-82.135163091203111, 35.55828726286363],
              [-82.135211626042505, 35.55802211809538],
              [-82.135541931173265, 35.558061077339808],
              [-82.135638997787424, 35.557530787431922],
              [-82.135969302225362, 35.557569745490852],
              [-82.13606636432057, 35.557039454301552],
              [-82.136396666997882, 35.557078412104389],
              [-82.136493725711475, 35.556548120506179],
              [-82.136824025456164, 35.55658707627952],
              [-82.136969608512231, 35.555791637747767],
              [-82.137299905440031, 35.555830593135809],
              [-82.137396956721375, 35.555300299743827],
              [-82.137727251819072, 35.555339253073811],
              [-82.138066912299109, 35.553483225058116],
              [-82.138397200320298, 35.55352217658487],
              [-82.138445720601055, 35.553257029325557],
              [-82.138776007883166, 35.553295979824611],
              [-82.138824526790629, 35.553030832398598],
              [-82.139485099091246, 35.553108730471351],
              [-82.139533615569107, 35.55284358274929],
              [-82.140194188128433, 35.552921476941407],
              [-82.140242702176636, 35.552656328923291],
              [-82.140903272789544, 35.5527342192917],
              [-82.140951784408202, 35.552469070977637],
              [-82.14128206858291, 35.552508014699193],
              [-82.141330578793443, 35.55224286531751],
              [-82.141660862228946, 35.552281808011429],
              [-82.141709371100973, 35.552016659363922],
              [-82.142039654899918, 35.552055601001655],
              [-82.142136667340566, 35.551525302491868],
              [-82.142466949344083, 35.551564242972717],
              [-82.142515454015182, 35.551299093081994],
              [-82.142845735279565, 35.551338032535206],
              [-82.142894238612328, 35.551072883378694],
              [-82.14322451800021, 35.55111182093183],
              [-82.14337002399499, 35.550316371920573],
              [-82.143700300531449, 35.55035530818764],
              [-82.143845799367043, 35.549559857579467],
              [-82.144176073086854, 35.549598793461435],
              [-82.144224570996926, 35.549333642754391],
              [-82.144554843977602, 35.549372577608722],
              [-82.144603340514507, 35.549107426735013],
              [-82.144933612721076, 35.549146359660803],
              [-82.145030602766482, 35.548616058443777],
              [-82.145360872075415, 35.548654990241232],
              [-82.145409365548986, 35.548389838996989],
              [-82.145739635256106, 35.548428770639262],
              [-82.145788127356553, 35.548163619228418],
              [-82.146118395187059, 35.548202548970778],
              [-82.146166885914511, 35.547937397393369],
              [-82.14649715414329, 35.547976326980546],
              [-82.146545642395154, 35.547711175265043],
              [-82.146875909849797, 35.547750102923686],
              [-82.147069856530877, 35.546689494241988],
              [-82.147400120077975, 35.546728420485451],
              [-82.147448604632956, 35.546463268324921],
              [-82.147778867475822, 35.546502194441793],
              [-82.147875833487845, 35.545971888849259],
              [-82.148206093398073, 35.546010812937013],
              [-82.148448498687245, 35.544685047177808],
              [-82.148778753633891, 35.544723969721296],
              [-82.148827232450486, 35.544458815973691],
              [-82.149157486692999, 35.544497738390611],
              [-82.149205963034319, 35.544232584505139],
              [-82.149866470322721, 35.544310425484682],
              [-82.149914945337542, 35.544045271274975],
              [-82.150575451781208, 35.544123108402736],
              [-82.150623924366954, 35.543857953897422],
              [-82.151284429930826, 35.54393578627252],
              [-82.151332898985302, 35.543670631500234],
              [-82.152323656574012, 35.543787373838782],
              [-82.152275190687718, 35.544052528998513],
              [-82.153596209453539, 35.544208173089295],
              [-82.153644671115543, 35.543943017412971],
              [-82.154305180269375, 35.54402083335949],
              [-82.154256720719459, 35.544285989294089],
              [-82.154917233252434, 35.54436380190544],
              [-82.154868776600424, 35.544628958032405],
              [-82.155529292512526, 35.544706767308476],
              [-82.155480836518478, 35.5449719227841],
              [-82.155811097108156, 35.54501082617508],
              [-82.155714186352327, 35.545541139074537],
              [-82.156044449370867, 35.545580041825396],
              [-82.155995994574056, 35.545845198348339],
              [-82.158638115196084, 35.546156387330257],
              [-82.158686561543675, 35.545891229774483],
              [-82.159347092460479, 35.545969017334585],
              [-82.159443977811506, 35.545438702553149],
              [-82.159774241063786, 35.54547759429132],
              [-82.159822682190679, 35.545212436265196],
              [-82.160152945840977, 35.545251327848142],
              [-82.160201385595002, 35.544986169655616],
              [-82.160531647403317, 35.545025060239794],
              [-82.160580085784673, 35.544759901880987],
              [-82.162231396394191, 35.544954337920501],
              [-82.162279829178189, 35.544689178879025],
              [-82.163600879559439, 35.544844710471523],
              [-82.163649306699767, 35.544579550905297],
              [-82.166621679611566, 35.544929443031407],
              [-82.166573263079087, 35.545194603730081],
              [-82.167233795848503, 35.545272347514334],
              [-82.167185381112063, 35.545537508433881],
              [-82.167845917259669, 35.54561524888252],
              [-82.167797504319196, 35.545880410022818],
              [-82.168458044947542, 35.545958147107051],
              [-82.168409632700573, 35.546223308496792],
              [-82.168739904545703, 35.546262175820289],
              [-82.168691494105701, 35.546527336372101],
              [-82.169021767323514, 35.546566202926108],
              [-82.16897335765853, 35.546831364470584],
              [-82.169964183481596, 35.546947959100407],
              [-82.171615565376186, 35.547142263732013],
              [-82.171567163844941, 35.547407426271093],
              [-82.172558001462448, 35.547523997894587],
              [-82.172509602783691, 35.547789160783203],
              [-82.172839882967182, 35.547828016943015],
              [-82.17279148609552, 35.548093178993533],
              [-82.173452050591209, 35.548170887945801],
              [-82.173403655551169, 35.548436051117854],
              [-82.175715650245834, 35.548708003657183],
              [-82.176045936807625, 35.548746851061431],
              [-82.176094323397066, 35.548481686857741],
              [-82.176424609183513, 35.548520532333583],
              [-82.176472993333192, 35.548255368893457],
              [-82.176803278379595, 35.54829421334189],
              [-82.176900045786851, 35.547763885133399],
              [-82.177890895383399, 35.547880412343211],
              [-82.177939274323521, 35.547615247374623],
              [-82.178269557501253, 35.5476540887434],
              [-82.17822117847976, 35.547919252968775],
              [-82.179212032973652, 35.548035769368106],
              [-82.179260407723717, 35.547770604784844],
              [-82.180251260792147, 35.547887112740092],
              [-82.180299632021629, 35.547621946831953],
              [-82.180629916321223, 35.547660780881557],
              [-82.180678286178249, 35.547395614807314],
              [-82.181008569737784, 35.547434447829581],
              [-82.181056938222184, 35.54716928158912],
              [-82.181387219939069, 35.547208113612903],
              [-82.181435587051041, 35.546942947206311],
              [-82.181765869130217, 35.546981778173866],
              [-82.181814234869606, 35.546716611601205],
              [-82.182144515106131, 35.546755441570305],
              [-82.18228960722324, 35.545959940341163],
              [-82.182619884607007, 35.545998769025182],
              [-82.182668246969158, 35.545733602045757],
              [-82.182998523612738, 35.545772429702438],
              [-82.183046883500083, 35.545507262585907],
              [-82.183377160470471, 35.545546088285413],
              [-82.183425518985402, 35.545280921002735],
              [-82.183755795250846, 35.545319746575863],
              [-82.183804152393463, 35.545054579127189],
              [-82.184134426781057, 35.545093402800966],
              [-82.184182782551161, 35.544828235186124],
              [-82.184513057336261, 35.544867058704646],
              [-82.18489988459406, 35.54274571088321],
              [-82.18523015014263, 35.542784532501344],
              [-82.185326851381859, 35.542254194248223],
              [-82.185987378064354, 35.542331833401818],
              [-82.185939029715215, 35.542597002804698],
              [-82.187920625889248, 35.542829899418457],
              [-82.187968966798408, 35.542564729271568],
              [-82.188959766551449, 35.542681165499218],
              [-82.189056442186597, 35.542150824292058],
              [-82.189386706164356, 35.542189633741771],
              [-82.189676718047252, 35.54059860848551],
              [-82.190006976003446, 35.540637416263905],
              [-82.190055308787862, 35.540372245138833],
              [-82.190385566003854, 35.540411051890104],
              [-82.190433897416028, 35.540145880599113],
              [-82.190764154994, 35.540184686294118],
              [-82.19081248503403, 35.539919514837287],
              [-82.191142740769394, 35.53995831953403],
              [-82.191191069437323, 35.539693147911315],
              [-82.191521324432358, 35.539731951580919],
              [-82.191569651692731, 35.539466778891381],
              [-82.191899905982893, 35.539505582434685],
              [-82.191948231871166, 35.539240409579278],
              [-82.192608739215643, 35.539318011911192],
              [-82.192560415439786, 35.539583185024199],
              [-82.193881439246113, 35.539738379393405],
              [-82.19392975773026, 35.539473206695213],
              [-82.194590269967108, 35.539550798217313],
              [-82.19463858598759, 35.539285624323647],
              [-82.194968840972322, 35.53932441862284],
              [-82.195017155656259, 35.539059245464223],
              [-82.195347411002842, 35.539098038707202],
              [-82.195395724279251, 35.538832864481833],
              [-82.196056231602881, 35.538910448073182],
              [-82.196007920403417, 35.539175621655062],
              [-82.19732894416714, 35.53933077854272],
              [-82.197280636876954, 35.53959595260266],
              [-82.198271411423917, 35.539712310313114],
              [-82.198223108124466, 35.539977485594143],
              [-82.198553367639008, 35.540016270139432],
              [-82.198505065044557, 35.540281444611189],
              [-82.199165588200046, 35.540359010334186],
              [-82.199117286335792, 35.540624185956318],
              [-82.199777813968922, 35.540701748313992],
              [-82.199729513866004, 35.54096692325551],
              [-82.200390044874652, 35.541044482276888],
              [-82.200438341762776, 35.540779307106952],
              [-82.200768606666529, 35.54081808469077],
              [-82.200720310870281, 35.541083260890439],
              [-82.201380842670801, 35.541160814550111],
              [-82.201332548636046, 35.541425990069172],
              [-82.201993084914207, 35.5415035403635],
              [-82.202041376836334, 35.541238364587024],
              [-82.202701912228932, 35.541315910129192],
              [-82.202750200620528, 35.541050734087392],
              [-82.205392346436653, 35.54136088109027],
              [-82.20534406539349, 35.541626058190623],
              [-82.208977062559924, 35.542052414408928],
              [-82.209025330880678, 35.541787235922378],
              [-82.21133725030225, 35.542058494790808],
              [-82.211385510912748, 35.541793315366874],
              [-82.212706610881497, 35.541948299368741],
              [-82.213036886404609, 35.541987043580754],
              [-82.213085140279603, 35.541721862604938],
              [-82.215397073541624, 35.541993042339875],
              [-82.21544531860394, 35.541727860455993],
              [-82.216105871852662, 35.541805332161211],
              [-82.216154114522382, 35.541540150884053],
              [-82.216484389994292, 35.541578884824489],
              [-82.216532632358891, 35.541313702451653],
              [-82.218184010304441, 35.541507358905299],
              [-82.218232245969261, 35.541242175881749],
              [-82.218892797924624, 35.541319632439667],
              [-82.218941031161251, 35.541054449122072],
              [-82.219271305986368, 35.541093175488896],
              [-82.219319537851277, 35.540827992005624],
              [-82.219649811935227, 35.540866717345402],
              [-82.219746271539861, 35.54033635094104],
              [-82.220035642680131, 35.538745248137189],
              [-82.220365908628082, 35.538783971550146],
              [-82.220414134440517, 35.538518787506128],
              [-82.220744399647415, 35.538557509892087],
              [-82.220792624088347, 35.538292325682626],
              [-82.22145315333492, 35.538369767502068],
              [-82.221501375312101, 35.538104582097596],
              [-82.222822432259818, 35.538259454469994],
              [-82.222870649732073, 35.537994269415513],
              [-82.223531177397348, 35.538071699067508],
              [-82.223579392441579, 35.537806513719026],
              [-82.223909656259664, 35.537845227504697],
              [-82.224239920357064, 35.537883939490953],
              [-82.22419170746133, 35.538149125997379],
              [-82.224521972930177, 35.538187837213741],
              [-82.224570183703094, 35.537922651508936],
              [-82.225230713509305, 35.538000071890067],
              [-82.225182503782762, 35.538265258782062],
              [-82.226503571402191, 35.538420089276592],
              [-82.226455366652601, 35.538685275715324],
              [-82.227115904465848, 35.53876268582875],
              [-82.227067701514017, 35.539027872487509],
              [-82.227728242700394, 35.539105279263865],
              [-82.22768004154635, 35.539370466142685],
              [-82.228010313686468, 35.539409168762077],
              [-82.227962113273904, 35.53967435573233],
              [-82.22862266164293, 35.539751756701435],
              [-82.228574463028124, 35.540016943891679],
              [-82.228904738226461, 35.540055644072488],
              [-82.228856540353064, 35.5403208313543],
              [-82.229517094838329, 35.540398227446126],
              [-82.22946889876286, 35.540663414947865],
              [-82.229799178085628, 35.540702111759735],
              [-82.229750982751696, 35.540967299352936],
              [-82.230081263481893, 35.541005996295745],
              [-82.230033068853459, 35.541271183079502],
              [-82.230363350955315, 35.541309879252225],
              [-82.230315157068347, 35.541575066127358],
              [-82.23064544054175, 35.541613761529987],
              [-82.230597248498569, 35.541878948467307],
              [-82.230927533307664, 35.541917642198925],
              [-82.23068657044989, 35.543243579763079],
              [-82.231016860856769, 35.543282273238361],
              [-82.230968669072411, 35.543547461111274],
              [-82.231298960850822, 35.543586153816499],
              [-82.231009806418086, 35.545177277531018],
              [-82.231340104851228, 35.545215970108309],
              [-82.231243719609324, 35.545746344039507],
              [-82.230913419062929, 35.545707651205362],
              [-82.230817029345133, 35.546238024760761],
              [-82.23114733310716, 35.546276717822344],
              [-82.231099139934685, 35.546541904643504],
              [-82.231429445068414, 35.546580596934874],
              [-82.231381251535183, 35.546845783876655],
              [-82.231711558040644, 35.546884475397917],
              [-82.231663366315502, 35.547149661500768],
              [-82.231993674192594, 35.547188352251851],
              [-82.23204186486106, 35.546923166020576],
              [-82.232372171960705, 35.546961854843723],
              [-82.232420362359747, 35.54669666841766],
              [-82.232750668717742, 35.54673535621378],
              [-82.232798856642702, 35.546470169651435],
              [-82.233459468226215, 35.546547543191949],
              [-82.233507653687013, 35.546282355434847],
              [-82.233837958912517, 35.546321041179276],
              [-82.233789774508466, 35.546586229064786],
              [-82.235111003853589, 35.546740962639831],
              [-82.235159182928641, 35.54647577427],
              [-82.236810721110814, 35.546669169709723],
              [-82.236762547319344, 35.546934358721458],
              [-82.239735345567922, 35.547282413788373],
              [-82.239687180971956, 35.547547603918318],
              [-82.240017495361016, 35.54758627191547],
              [-82.239969331506913, 35.547851462136578],
              [-82.24029964726769, 35.547890129363516],
              [-82.240251484155479, 35.548155319675885],
              [-82.24058180018551, 35.54819398616187],
              [-82.240485475130043, 35.548724366931985],
              [-82.240815794690832, 35.548763032746592],
              [-82.240767632747591, 35.549028223204346],
              [-82.241097953679954, 35.549066888248639],
              [-82.241049792478648, 35.549332078797597],
              [-82.240719471555892, 35.549293412694546],
              [-82.240430491287199, 35.550884551735194],
              [-82.240100163272018, 35.550845884893768],
              [-82.240051997716975, 35.551111074025016],
              [-82.240382326788975, 35.55114974099471],
              [-82.240334161975966, 35.551414930217099],
              [-82.240994824316729, 35.551492262588638],
              [-82.240946661302544, 35.551757452030536],
              [-82.241607326980613, 35.551834780162999],
              [-82.241559165765182, 35.552099969824361],
              [-82.241889500684579, 35.552138632656209],
              [-82.241841340211252, 35.552403822408699],
              [-82.24217167650238, 35.552442484470184],
              [-82.242123516806984, 35.552707675214613],
              [-82.242453854469957, 35.552746336505805],
              [-82.242405695444589, 35.553011525539539],
              [-82.242736034479321, 35.553050186060254],
              [-82.242687876232011, 35.553315376086054],
              [-82.243018216638404, 35.553354035836399],
              [-82.242970060235621, 35.553619225923867],
              [-82.243300402013872, 35.553657884903707],
              [-82.243252245250716, 35.553923075111712],
              [-82.243582588400741, 35.553961733321202],
              [-82.243341808382397, 35.555287682553917],
              [-82.243672157131954, 35.555326340506234],
              [-82.24362400169619, 35.55559153071782],
              [-82.24395435181755, 35.555630187899702],
              [-82.243809885617424, 35.556425756923552],
              [-82.244140240327098, 35.556464413562132],
              [-82.244092084607416, 35.556729603009821],
              [-82.244422440688965, 35.55676825887798],
              [-82.24432613152149, 35.557298637888543],
              [-82.244656490031915, 35.557337293114585],
              [-82.244560181755091, 35.557867673133458],
              [-82.244890542658482, 35.557906326816344],
              [-82.24484238963808, 35.558171516432864],
              [-82.245833477480318, 35.558287474275588],
              [-82.245785327352436, 35.558552665140539],
              [-82.246115691629171, 35.558591315484868],
              [-82.247437154161517, 35.558745909617123],
              [-82.247389008968071, 35.559011100185302],
              [-82.248710479662904, 35.559165680479431],
              [-82.248758620627882, 35.558900489398205],
              [-82.249749724040342, 35.559016414338743],
              [-82.249701587349136, 35.559281605775169],
              [-82.25069269676554, 35.55939752301127],
              [-82.250644562930972, 35.559662714795131],
              [-82.251305306609353, 35.559739989005308],
              [-82.25125717453821, 35.560005180107382],
              [-82.251587548475911, 35.560043816428212],
              [-82.251539417147299, 35.560309007621157],
              [-82.251869792456944, 35.56034764317139],
              [-82.251773531005881, 35.56087802660263],
              [-82.2521039076058, 35.560916660639073],
              [-82.252007647046469, 35.561447045078147],
              [-82.252338027178013, 35.561485678442693],
              [-82.25204924418172, 35.563076828929383],
              [-82.25304040659708, 35.563192725908266],
              [-82.252992278149819, 35.563457917459274],
              [-82.254313838509404, 35.563612434643993],
              [-82.25426571397648, 35.563877626670411],
              [-82.254926498710176, 35.563954880111098],
              [-82.254878375977114, 35.564220072356576],
              [-82.255869558573238, 35.564335945259714],
              [-82.255821438733548, 35.564601138753176],
              [-82.256812627333375, 35.564717003950847],
              [-82.256860742934748, 35.564451811003273],
              [-82.257191138682586, 35.564490430209233],
              [-82.257143024138514, 35.564755623284924],
              [-82.257803818764941, 35.564832861058406],
              [-82.257851931194296, 35.564567667726315],
              [-82.258843123351653, 35.564683515428804],
              [-82.259503918973181, 35.564760743055977],
              [-82.259455811830335, 35.56502593702885],
              [-82.259786211188427, 35.565064550072677],
              [-82.259738104788354, 35.565329744136278],
              [-82.260398907629224, 35.565406966853018],
              [-82.260350803029269, 35.56567216113546],
              [-82.260681206512118, 35.56571077080833],
              [-82.260633102654978, 35.565975965181408],
              [-82.260963506407037, 35.566014574113105],
              [-82.260819198325748, 35.566810157332917],
              [-82.261149605600707, 35.566848766650992],
              [-82.261101503666524, 35.567113960200039],
              [-82.263083964531873, 35.56734559427764],
              [-82.263035868627568, 35.5676107885579],
              [-82.263366281321709, 35.567649391810008],
              [-82.263318186160419, 35.567914586180954],
              [-82.263648600190152, 35.567953187761333],
              [-82.263600505808057, 35.568218383123835],
              [-82.263930921209905, 35.568256983933345],
              [-82.263882827570569, 35.568522179386363],
              [-82.264213244344376, 35.568560779425098],
              [-82.264117058201066, 35.56909116957403],
              [-82.263786638209794, 35.569052569308774],
              [-82.263305676872804, 35.571704514748717],
              [-82.262644816683775, 35.571627308959137],
              [-82.262500514963392, 35.572422889399185],
              [-82.262830948109496, 35.572461494028722],
              [-82.262782847205131, 35.572726686980829],
              [-82.263113281687197, 35.572765289938552],
              [-82.263065182664789, 35.573030483852484],
              [-82.263395618518871, 35.573069086039311],
              [-82.263347520239492, 35.573334280043738],
              [-82.263677957465774, 35.573372881459633],
              [-82.263629859929438, 35.573638075554491],
              [-82.263960298527763, 35.573676676199483],
              [-82.263864104590468, 35.574207063631803],
              [-82.264194545618508, 35.574245663633938],
              [-82.264146449253744, 35.57451085787222],
              [-82.264476891654041, 35.574549457103409],
              [-82.264428795996068, 35.574814650531174],
              [-82.264759240871157, 35.574853248961787],
              [-82.264711145992578, 35.575118443380994],
              [-82.26504159220363, 35.575157040139722],
              [-82.264993496965403, 35.575422234679024],
              [-82.265323944584821, 35.575460831567696],
              [-82.265227757413086, 35.575991218928344],
              [-82.265558207462334, 35.576029815174124],
              [-82.265462021184121, 35.576560203541227],
              [-82.265792473663211, 35.576598799144165],
              [-82.265744381091807, 35.576863992948809],
              [-82.266074834943112, 35.576902587780758],
              [-82.265978652074978, 35.577432975503598],
              [-82.266309108356182, 35.577471569692527],
              [-82.266261016956619, 35.577736763640345],
              [-82.266591474573843, 35.577775356157389],
              [-82.26654338391748, 35.578040550195503],
              [-82.267204303386194, 35.578117734560685],
              [-82.267156215633534, 35.578382928787548],
              [-82.267486676309645, 35.578421518863458],
              [-82.267438589300312, 35.578686713180772],
              [-82.267769051384974, 35.578725303386541],
              [-82.267720964016007, 35.57899049782386],
              [-82.268051428539479, 35.579029086327992],
              [-82.268003343016574, 35.579294280825948],
              [-82.268664274055823, 35.579371456323649],
              [-82.268616190334072, 35.579636651039934],
              [-82.26894665791643, 35.579675237102911],
              [-82.268898573835088, 35.579940431939207],
              [-82.269229042826126, 35.579979018131951],
              [-82.269180960554579, 35.580244212128044],
              [-82.269841901559104, 35.580321381171274],
              [-82.269793820022215, 35.580586576316335],
              [-82.270124293138721, 35.580625159137149],
              [-82.270076212345202, 35.580890354372485],
              [-82.270737160534281, 35.580967517602474],
              [-82.270689082644765, 35.581232713026438],
              [-82.271019557717352, 35.581271293435506],
              [-82.27097148057122, 35.581536488949716],
              [-82.271962911952158, 35.581652225136743],
              [-82.271722537263784, 35.582978201358699],
              [-82.271392053797797, 35.58293962290859],
              [-82.27115166592273, 35.584265596644769],
              [-82.270821177445328, 35.584227015754486],
              [-82.27062885612132, 35.585287792859667],
              [-82.270298363763132, 35.585249211459015],
              [-82.270202198510532, 35.585779598642482],
              [-82.269871703248072, 35.585741016116224],
              [-82.269631279892778, 35.587066983207706],
              [-82.269961780445058, 35.587105566374049],
              [-82.269913695241328, 35.587370760184939],
              [-82.270244197166107, 35.587409342580067],
              [-82.270196113808666, 35.587674536451395],
              [-82.270526616003139, 35.587713118105029],
              [-82.270478533352744, 35.587978311165507],
              [-82.270809038022634, 35.588016892018317],
              [-82.270857119614931, 35.587751698829756],
              [-82.27151812778196, 35.587828857581492],
              [-82.271566206943746, 35.587563664099036],
              [-82.27222721425278, 35.58764081899794],
              [-82.27217913724337, 35.587906013637259],
              [-82.272840147926217, 35.587983165195162],
              [-82.272792072682122, 35.588248359151756],
              [-82.273453086738812, 35.588325507368737],
              [-82.273405013296241, 35.588590701543424],
              [-82.274066030726885, 35.588667846419391],
              [-82.274114102053375, 35.588402651988744],
              [-82.274775118625527, 35.58847979301175],
              [-82.274727049415247, 35.588744987698398],
              [-82.275718580908674, 35.588860692842871],
              [-82.275670515661076, 35.589125887845711],
              [-82.276992565882153, 35.589280148402956],
              [-82.277040626861023, 35.589014951987281],
              [-82.277371138877598, 35.589053514983121],
              [-82.277419199623452, 35.588788319272808],
              [-82.27774971085978, 35.588826880340548],
              [-82.277797769130302, 35.58856168449406],
              [-82.27845879046626, 35.588638804576775],
              [-82.278410735451359, 35.588904001550283],
              [-82.278741247630705, 35.588942559920248],
              [-82.278693192256554, 35.589207757013568],
              [-82.279023705808314, 35.589246314612211],
              [-82.278975652244497, 35.589511510864831],
              [-82.279306167205149, 35.589550068593113],
              [-82.279258113282083, 35.589815264965523],
              [-82.279588630718081, 35.589853821892639],
              [-82.279540577538839, 35.590119018355139],
              [-82.279871096310913, 35.590157573609993],
              [-82.279823043875481, 35.590422770162505],
              [-82.280814604185309, 35.590538431845857],
              [-82.280766555749381, 35.590803629615316],
              [-82.282088645150665, 35.590957832804506],
              [-82.28204060059663, 35.5912230301468],
              [-82.282371125057409, 35.591261578591102],
              [-82.28232308124737, 35.591526776023358],
              [-82.282653607080576, 35.591565323696244],
              [-82.282557519531011, 35.592095718732459],
              [-82.282888048897846, 35.59213426573212],
              [-82.282791962207526, 35.592664660872316],
              [-82.283122494005028, 35.592703207228382],
              [-82.283026408174166, 35.593233602472466],
              [-82.283356942402392, 35.593272148185015],
              [-82.28330890058875, 35.593537344962201],
              [-82.283639435086499, 35.593575889933113],
              [-82.283591394053417, 35.593841087701122],
              [-82.28392193102664, 35.593879631870777],
              [-82.283873890737482, 35.594144829728691],
              [-82.284204427980228, 35.594183373156689],
              [-82.284156388398671, 35.594448570203582],
              [-82.284486928116905, 35.594487112830336],
              [-82.284438889315794, 35.594752310867996],
              [-82.284769430406655, 35.594790852723285],
              [-82.28472139231306, 35.595056049949839],
              [-82.285051934776348, 35.59509459103365],
              [-82.284955860902556, 35.595624986499701],
              [-82.285286405796782, 35.595663526939894],
              [-82.28523836887706, 35.595928724308202],
              [-82.285568915143756, 35.595967263976902],
              [-82.285520878968128, 35.596232461435065],
              [-82.286181975663837, 35.596309538300261],
              [-82.286133941290728, 35.596574735976112],
              [-82.286464490616751, 35.596613273202429],
              [-82.286416458090685, 35.596878470938265],
              [-82.287077560905175, 35.59695554291865],
              [-82.28702952907858, 35.597220740901982],
              [-82.287360082566977, 35.597259275656029],
              [-82.287312051484534, 35.597524473729095],
              [-82.287642606309049, 35.597563006810766],
              [-82.287594575970786, 35.597828204973602],
              [-82.287925132204307, 35.597866738184585],
              [-82.287877103713271, 35.598131936407434],
              [-82.28820766131939, 35.598170468846824],
              [-82.288159632469601, 35.598435667189278],
              [-82.288490191411768, 35.598474197956229],
              [-82.288442164409133, 35.598739396358603],
              [-82.288772724760534, 35.598777927254879],
              [-82.28872469739909, 35.599043125776852],
              [-82.289055259086439, 35.599081655000568],
              [-82.289007233572292, 35.599346853582432],
              [-82.288676670789954, 35.599308323329907],
              [-82.288532588084124, 35.600103918493133],
              [-82.288202022440572, 35.600065386957652],
              [-82.288105963211251, 35.600595782395985],
              [-82.28777539576501, 35.600557249705403],
              [-82.287679333161918, 35.601087644735429],
              [-82.287348762846449, 35.601049111820451],
              [-82.287300730548424, 35.601314308685083],
              [-82.286970159452153, 35.601275773841948],
              [-82.286922124714877, 35.601540971471323],
              [-82.286591553977573, 35.601502435570929],
              [-82.28654351783095, 35.601767632133409],
              [-82.28621294528287, 35.601729096136552],
              [-82.286164908866496, 35.601994292503164],
              [-82.285834335537672, 35.601955754578015],
              [-82.285786296645554, 35.602220950808444],
              [-82.285455722609029, 35.602182412756953],
              [-82.285407682344157, 35.602447608821365],
              [-82.285077108630006, 35.602409068811831],
              [-82.28502906699245, 35.602674264710174],
              [-82.284037341287487, 35.602558639891626],
              [-82.283989296160058, 35.60282383536817],
              [-82.282997571175088, 35.602708201140217],
              [-82.282949521454881, 35.602973396224805],
              [-82.282288371287308, 35.602896301976514],
              [-82.282240319135667, 35.603161496767058],
              [-82.281909744016531, 35.603122948150919],
              [-82.281813636653226, 35.603653337361592],
              [-82.282144214992542, 35.603691886203663],
              [-82.28185589384195, 35.605283051824308],
              [-82.282186478846668, 35.605321600534239],
              [-82.281994262337292, 35.606382377671537],
              [-82.28166367309835, 35.606343828450015],
              [-82.281615616823302, 35.606609021842488],
              [-82.281285025737034, 35.606570471623385],
              [-82.281140852989409, 35.607366052058794],
              [-82.280810259041729, 35.607327500556423],
              [-82.280714139427545, 35.607857886485355],
              [-82.280383543677203, 35.607819333827734],
              [-82.280287420724648, 35.608349720248469],
              [-82.280618018592207, 35.608388273161857],
              [-82.280521895359371, 35.608918658814048],
              [-82.27986069566775, 35.608841550675216],
              [-82.279812632014, 35.609106743173029],
              [-82.279151431499457, 35.609029632081231],
              [-82.279103365413988, 35.609294824285001],
              [-82.278772763466606, 35.609256267291649],
              [-82.278724696008041, 35.609521459329152],
              [-82.278394094419696, 35.609482901278611],
              [-82.278346024448354, 35.609748092278608],
              [-82.278015422115899, 35.609709533200657],
              [-82.27796735080787, 35.609974724935356],
              [-82.277636747731265, 35.609936164829946],
              [-82.277588675013646, 35.610201355497381],
              [-82.277258071192932, 35.610162794364513],
              [-82.277209997138584, 35.610427985766655],
              [-82.276879392573761, 35.610389423606321],
              [-82.27683131710981, 35.610654613941243],
              [-82.275839500986919, 35.610538920808139],
              [-82.275791422069005, 35.610804111621697],
              [-82.275130210396028, 35.61072697782236],
              [-82.275082129009746, 35.610992167440827],
              [-82.273759706307644, 35.610837888506211],
              [-82.273711619305402, 35.611103078505103],
              [-82.273381013615349, 35.611064505936817],
              [-82.273284837616558, 35.61159488463278],
              [-82.27262362187885, 35.611517737216388],
              [-82.272575530720516, 35.61178292581527],
              [-82.272244922834815, 35.611744351065376],
              [-82.272196830303074, 35.612009539497876],
              [-82.270874395356643, 35.611855228347743],
              [-82.270922492122949, 35.611590040427224],
              [-82.269930671814251, 35.611474297342653],
              [-82.269882571908411, 35.611739485780213],
              [-82.269551965445132, 35.61170090280519],
              [-82.269503864129589, 35.611966090175393],
              [-82.269173255819155, 35.61192750620247],
              [-82.26907704905986, 35.612457881502216],
              [-82.268746440050037, 35.61241929634415],
              [-82.268698334569905, 35.612684483372661],
              [-82.267706505149846, 35.61256872214696],
              [-82.267658396178945, 35.612833908753082],
              [-82.267327785207669, 35.612795319770555],
              [-82.267279675966691, 35.613060506180553],
              [-82.266618452851077, 35.612983325260863],
              [-82.266570341177939, 35.613248511376284],
              [-82.266239729033714, 35.6132099194391],
              [-82.266191614883894, 35.613475105417784],
              [-82.265530389422523, 35.613397918588738],
              [-82.2654822728406, 35.613663104272831],
              [-82.265151660626742, 35.613624509351389],
              [-82.265007304676601, 35.614420064917539],
              [-82.264814826048578, 35.615480805423921],
              [-82.265145447879689, 35.615519401182354],
              [-82.264952968412686, 35.616580140683666],
              [-82.264622342346698, 35.616541544413117],
              [-82.264574220928054, 35.616806729507132],
              [-82.264904848052751, 35.616845325905715],
              [-82.26471236114908, 35.617906063764892],
              [-82.265704257117406, 35.618021849099478],
              [-82.265656138359489, 35.618287034512825],
              [-82.266317404926895, 35.618364220523596],
              [-82.266269287935387, 35.618629405253493],
              [-82.266930558982921, 35.618706587892213],
              [-82.266882443794159, 35.618971772839551],
              [-82.267213080848975, 35.619010362937502],
              [-82.267164966404295, 35.619275547974254],
              [-82.267826244679327, 35.619352725697688],
              [-82.267778132037449, 35.619617910951852],
              [-82.268439412586034, 35.619695085362466],
              [-82.268391302850247, 35.619960270804341],
              [-82.26872194461923, 35.619998855887303],
              [-82.268673834524151, 35.620264041448273],
              [-82.269004478806565, 35.620302626630803],
              [-82.268956369455552, 35.620567812281131],
              [-82.269617659978891, 35.620644980232974],
              [-82.269569553497817, 35.620910165170088],
              [-82.269900199738956, 35.620948747939387],
              [-82.269852094038313, 35.621213933866649],
              [-82.270182742756361, 35.621252515834669],
              [-82.270134636660117, 35.621517700980093],
              [-82.271787889090305, 35.621710596192756],
              [-82.271739789113525, 35.621975782810686],
              [-82.272070441838011, 35.622014359636601],
              [-82.271974243021688, 35.622544732111471],
              [-82.272304898142366, 35.622583307392738],
              [-82.272208700184947, 35.623113679968974],
              [-82.271878042946469, 35.623075104431805],
              [-82.271829941315119, 35.623340290113227],
              [-82.272160600752315, 35.623378866649553],
              [-82.272064399803142, 35.623909239023519],
              [-82.271733739351319, 35.623870662201469],
              [-82.271685637915581, 35.624135848638041],
              [-82.27201629938989, 35.624174424687084],
              [-82.271920097655496, 35.624704796799215],
              [-82.272250762665919, 35.624743372174741],
              [-82.27215456065079, 35.625273743516814],
              [-82.27248522813035, 35.625312319149366],
              [-82.272389028077612, 35.62584269056277],
              [-82.272719697953562, 35.625881264650687],
              [-82.272623498759856, 35.626411636165109],
              [-82.272954171105013, 35.626450210510079],
              [-82.272906072095026, 35.626715396337147],
              [-82.273236745777581, 35.626753969009485],
              [-82.273188647511887, 35.627019154925726],
              [-82.273519322568248, 35.627057726826266],
              [-82.273423127210819, 35.627588098798327],
              [-82.27375380470005, 35.627626670055008],
              [-82.273705707608244, 35.627891856110878],
              [-82.274036387574753, 35.62793042656611],
              [-82.273940194565185, 35.628460798817351],
              [-82.274270875861163, 35.628499368658339],
              [-82.274078491332062, 35.629560111375781],
              [-82.273747804696171, 35.629521541052767],
              [-82.273699706423812, 35.629786726064218],
              [-82.273369019043542, 35.629748154713539],
              [-82.273320919433701, 35.630013340459136],
              [-82.272990231272658, 35.629974767179775],
              [-82.272942130288911, 35.630239952758693],
              [-82.271950065657009, 35.6301242280095],
              [-82.271901960077557, 35.630389413195402],
              [-82.271571272160926, 35.630350836031454],
              [-82.271523165207526, 35.6306160210505],
              [-82.271192475443087, 35.630577442888523],
              [-82.271000042342649, 35.63163817930279],
              [-82.270903823885291, 35.6321685468263],
              [-82.271234519968289, 35.632207124855121],
              [-82.271186411878247, 35.632472308671737],
              [-82.271517109371658, 35.632510886829593],
              [-82.271420893225539, 35.633041253730894],
              [-82.271751594255775, 35.633079831215149],
              [-82.27170348623612, 35.633345015200732],
              [-82.272034188603925, 35.63338359101224],
              [-82.271986081328635, 35.633648775086797],
              [-82.272316785070487, 35.633687350126507],
              [-82.272268679643219, 35.633952534260473],
              [-82.272599384759133, 35.633991108528228],
              [-82.272551278972657, 35.634256292780961],
              [-82.272881985462618, 35.634294866276839],
              [-82.272833881524193, 35.63456005058881],
              [-82.273164589388131, 35.634598623312812],
              [-82.273116485090611, 35.634863807743578],
              [-82.27344719432871, 35.634902379695752],
              [-82.273399091842691, 35.63516756328486],
              [-82.273729802491275, 35.635206135366026],
              [-82.273681700749719, 35.635471319044207],
              [-82.274012412736056, 35.635509889452472],
              [-82.273964311775401, 35.635775074120474],
              [-82.274295025135743, 35.635813643756833],
              [-82.274246924883158, 35.636078827612998],
              [-82.274577640721148, 35.636117396447702],
              [-82.274529541249606, 35.636382581293738],
              [-82.275190973781449, 35.636459716578869],
              [-82.275142877180784, 35.636724900711158],
              [-82.275473594978195, 35.636763467131857],
              [-82.275425498054958, 35.637028652283725],
              [-82.275756218330031, 35.637067217902739],
              [-82.275660025585111, 35.637597586543635],
              [-82.278305813823266, 35.637906080305285],
              [-82.278257726557101, 35.638171265560615],
              [-82.278919179443733, 35.638248380468369],
              [-82.278967264591046, 35.637983194957229],
              [-82.279628716581172, 35.638060305108709],
              [-82.279580633552655, 35.638325490875594],
              [-82.280903545583342, 35.638479701792249],
              [-82.280855466477576, 35.638744888031589],
              [-82.281186196304049, 35.638783437963198],
              [-82.281138117979609, 35.639048625192324],
              [-82.281799581835287, 35.639125723482643],
              [-82.281751505278649, 35.639390910027601],
              [-82.282082238167789, 35.639429457515256],
              [-82.281986087332911, 35.639959830713998],
              [-82.282316822655673, 35.639998377557347],
              [-82.282028367815869, 35.64158949611334],
              [-82.282359110950381, 35.641628043694929],
              [-82.282311034703525, 35.641893229371355],
              [-82.282641779212184, 35.641931776180826],
              [-82.28259370371029, 35.642196961946055],
              [-82.28292444959304, 35.642235507983429],
              [-82.282828300904441, 35.642765880523491],
              [-82.283159049184377, 35.642804425015662],
              [-82.283062901356345, 35.643334797655115],
              [-82.283393652070018, 35.643373341502993],
              [-82.283297505102382, 35.643903714241816],
              [-82.282966752233051, 35.643865169237387],
              [-82.28272637229928, 35.645191098454475],
              [-82.283057131570288, 35.64522964406823],
              [-82.282864826388945, 35.646290386522445],
              [-82.283195590176589, 35.646328930846643],
              [-82.283099438368936, 35.646859302079712],
              [-82.283430204590431, 35.646897845759575],
              [-82.28333405360695, 35.647428216190782],
              [-82.285318671400091, 35.647659461774445],
              [-82.285270602364534, 35.647924648133646],
              [-82.285601375071266, 35.647963185866949],
              [-82.285505237035267, 35.648493557852561],
              [-82.285836012212684, 35.648532095842313],
              [-82.285691806511551, 35.649327653444793],
              [-82.286022585146227, 35.649366190017105],
              [-82.285878379792479, 35.650161747650181],
              [-82.286209161920922, 35.650200283705821],
              [-82.286113025209019, 35.650730655830934],
              [-82.286443809771711, 35.650769191242006],
              [-82.286251537878329, 35.651829934602048],
              [-82.286582328062025, 35.651868468693507],
              [-82.286486191813367, 35.652398840858901],
              [-82.28681698443134, 35.652437374305897],
              [-82.286432437354122, 35.654558857854774],
              [-82.286101636220863, 35.654520322484693],
              [-82.285957422396535, 35.655315877228155],
              [-82.286288226709431, 35.655354412981609],
              [-82.286095940647456, 35.656415151118502],
              [-82.285765132094951, 35.656376614853805],
              [-82.28566898507421, 35.656906983881022],
              [-82.28599979685039, 35.65694552037138],
              [-82.285951723357798, 35.657210704518192],
              [-82.286282536508509, 35.657249240236183],
              [-82.286234463761147, 35.657514424471437],
              [-82.286565278286602, 35.657552959417046],
              [-82.286517207388243, 35.657818143710855],
              [-82.2868480221845, 35.657856677913919],
              [-82.286799952031416, 35.658121862296127],
              [-82.286469135071357, 35.658083327995122],
              [-82.286324918440258, 35.658878880551825],
              [-82.285994098651514, 35.658840345868185],
              [-82.285897950132721, 35.659370712895843],
              [-82.285567128502166, 35.659332176155594],
              [-82.285326745900448, 35.660658093762159],
              [-82.285657572831028, 35.660696631141256],
              [-82.285465264618281, 35.661757363964462],
              [-82.285134433484089, 35.661718826974969],
              [-82.285038274780675, 35.66224919200824],
              [-82.284707440700572, 35.662210652991952],
              [-82.284611279758423, 35.66274101848277],
              [-82.284280443873072, 35.662702478310699],
              [-82.28418427844764, 35.663232843417802],
              [-82.283853440756999, 35.663194302089828],
              [-82.283757271915704, 35.663724665882413],
              [-82.283426432419589, 35.66368612339874],
              [-82.283330259094967, 35.664216486807476],
              [-82.282999417793476, 35.66417794316795],
              [-82.282903242193385, 35.664708306133065],
              [-82.282572397982548, 35.664669761367513],
              [-82.282476219002817, 35.665200123918915],
              [-82.282145372986591, 35.665161577997537],
              [-82.282049190627333, 35.665691940135119],
              [-82.281718342805689, 35.665653393057838],
              [-82.281525967823754, 35.666714115505599],
              [-82.28119511607639, 35.666675567016817],
              [-82.281098924557554, 35.667205927297161],
              [-82.280768071004758, 35.667167377652497],
              [-82.280671876106027, 35.667697737518786],
              [-82.280341020711376, 35.667659185817335],
              [-82.280292921195723, 35.667924366028501],
              [-82.280623777614153, 35.667962916956881],
              [-82.280575678807296, 35.668228096355449],
              [-82.280906536637318, 35.668266647412267],
              [-82.280714143642172, 35.669327365992579],
              [-82.28104500599143, 35.669365915759492],
              [-82.280900711367735, 35.670161453978778],
              [-82.281231577212722, 35.670200003228828],
              [-82.281183480171265, 35.670465182663897],
              [-82.281514347391308, 35.670503731141459],
              [-82.281418153380173, 35.671034090177947],
              [-82.281749024139671, 35.671072637981048],
              [-82.281652829885502, 35.671602997144205],
              [-82.281983703080499, 35.671641544302581],
              [-82.281935607645337, 35.671906723922639],
              [-82.282266482215533, 35.671945270308484],
              [-82.282170290313104, 35.672475629744568],
              [-82.282501167318813, 35.672514175485709],
              [-82.282453073023049, 35.672779354341358],
              [-82.282783951403985, 35.672817899310019],
              [-82.282735857890188, 35.673083079154544],
              [-82.283066737646351, 35.673121623350646],
              [-82.283018643737392, 35.673386802412239],
              [-82.283349525972852, 35.673425345805917],
              [-82.283253340471646, 35.673955704936461],
              [-82.283584224038648, 35.673994247715243],
              [-82.283536132446329, 35.674259427784193],
              [-82.283867017352051, 35.674297968889512],
              [-82.283722742726297, 35.675093508370281],
              [-82.284053632268751, 35.675132049829578],
              [-82.283813173110175, 35.676457946715693],
              [-82.284144068232976, 35.676496487012628],
              [-82.284095976987871, 35.676761667107037],
              [-82.284426873485998, 35.676800206631349],
              [-82.284378782949915, 35.677065385912869],
              [-82.284709680859933, 35.67710392556544],
              [-82.284661591069394, 35.677369104934904],
              [-82.28532338991846, 35.67744618089359],
              [-82.285275301934064, 35.677711360478781],
              [-82.285606204013774, 35.67774989765585],
              [-82.285558115670767, 35.678015077358722],
              [-82.286219922859232, 35.67809214836636],
              [-82.286171836322339, 35.678357328285031],
              [-82.2871645515621, 35.678472928906743],
              [-82.287116470100216, 35.678738109109148],
              [-82.287778283538401, 35.678815170752429],
              [-82.287730202778576, 35.679080351200341],
              [-82.288061111619143, 35.679118881685028],
              [-82.28801303156834, 35.679384061319944],
              [-82.288674852278433, 35.679461118942704],
              [-82.28862677517462, 35.679726299664182],
              [-82.28895768704416, 35.679764826802142],
              [-82.288909609581921, 35.680030007641442],
              [-82.289571436423131, 35.680107060372464],
              [-82.289523361834782, 35.680372240496531],
              [-82.289854276806466, 35.680410766089565],
              [-82.289806202963888, 35.680675946301548],
              [-82.290137119274334, 35.680714470220877],
              [-82.290089045110093, 35.680979651451473],
              [-82.290419963900092, 35.681018174568194],
              [-82.290323817815761, 35.681548536234324],
              [-82.29065473904177, 35.6815870587061],
              [-82.290606666054245, 35.681852240072459],
              [-82.291268510467674, 35.681929282599683],
              [-82.291220440354266, 35.682194463250802],
              [-82.292544138341668, 35.682348538881428],
              [-82.292496072155942, 35.68261372000336],
              [-82.293157925693308, 35.682690752205694],
              [-82.29310986131415, 35.682955933543269],
              [-82.294102647162831, 35.683071475476119],
              [-82.294054585650954, 35.683336657156737],
              [-82.294385515957302, 35.683375169461023],
              [-82.294337455191382, 35.683640351229421],
              [-82.294668386873113, 35.683678862760871],
              [-82.294620327957375, 35.683944044587086],
              [-82.294951261014532, 35.683982555345708],
              [-82.294903201740567, 35.684247737289596],
              [-82.295234137277347, 35.684286247245453],
              [-82.295186078749381, 35.684551429277057],
              [-82.294855143292764, 35.684512920064471],
              [-82.294662902895126, 35.685573647250827],
              [-82.294331963473738, 35.685535135726177],
              [-82.294139713760643, 35.68659586086148],
              [-82.293808769306651, 35.686557347955514],
              [-82.293664574697388, 35.68735289080135],
              [-82.293333627375816, 35.687314376611823],
              [-82.293189427855438, 35.688109918684688],
              [-82.292858476562103, 35.688071403241381],
              [-82.292666200387998, 35.689132124373387],
              [-82.292335246270397, 35.689093607488971],
              [-82.292191031529242, 35.689889147113902],
              [-82.291860074543948, 35.689850628945862],
              [-82.291812000524757, 35.690115808332578],
              [-82.291481042793464, 35.690077289136347],
              [-82.291384892793005, 35.690607647504379],
              [-82.291053932150547, 35.690569127181988],
              [-82.291005856169349, 35.690834306163282],
              [-82.290674894780892, 35.690795784812614],
              [-82.290434501534975, 35.692121678568768],
              [-82.290103535157002, 35.692083155678944],
              [-82.289911209949537, 35.693143868568029],
              [-82.289580239642959, 35.693105344266847],
              [-82.28948407245754, 35.693635699779641],
              [-82.289153100343938, 35.69359717432247],
              [-82.289105015236231, 35.693862352341284],
              [-82.288912671583148, 35.694923062213483],
              [-82.289243650105817, 35.694961588279412],
              [-82.288955131470772, 35.696552650846435],
              [-82.288293162008685, 35.696475596281573],
              [-82.288245072339194, 35.696740772829806],
              [-82.287914087019601, 35.696702244068661],
              [-82.287865994869676, 35.696967420478799],
              [-82.287204023053391, 35.696890359999131],
              [-82.287155929570872, 35.697155536083699],
              [-82.286824943074208, 35.697117004365154],
              [-82.286776847111412, 35.697382180311635],
              [-82.286445859832156, 35.697343646663853],
              [-82.286397763597648, 35.697608822412541],
              [-82.286066775608987, 35.697570288637223],
              [-82.286018676894074, 35.697835464247781],
              [-82.285356699703286, 35.697758392838765],
              [-82.285308598551325, 35.69802356815358],
              [-82.284977609385706, 35.697985031420821],
              [-82.284929506857694, 35.698250206567657],
              [-82.28459851690954, 35.698211667905603],
              [-82.284550413005462, 35.698476842884432],
              [-82.284219421243606, 35.698438304124636],
              [-82.284171315926883, 35.698703478034574],
              [-82.283840324523396, 35.698664938216588],
              [-82.283792217867131, 35.698930112859344],
              [-82.283461225681137, 35.698891571112078],
              [-82.283413116544352, 35.699156745616541],
              [-82.283082123612431, 35.699118202840829],
              [-82.282985904480441, 35.699648551413908],
              [-82.282654908637127, 35.699610007511815],
              [-82.282510573251741, 35.700405528814805],
              [-82.282179574540393, 35.700366983628768],
              [-82.282083346872028, 35.700897330416609],
              [-82.281752347458138, 35.700858784044549],
              [-82.281511766860149, 35.70218464833296],
              [-82.281180761388072, 35.702146101352049],
              [-82.281132642812793, 35.702411273792649],
              [-82.280801636558252, 35.702372724882373],
              [-82.280753516606808, 35.702637897154844],
              [-82.280091502957589, 35.702560797277492],
              [-82.280043380568657, 35.702825969253972],
              [-82.27938136605799, 35.702748865518309],
              [-82.279333241231669, 35.703014037198898],
              [-82.278671224718749, 35.702936928733571],
              [-82.278623097454997, 35.703202100118204],
              [-82.278292089162463, 35.70316354439182],
              [-82.278243960522389, 35.703428715608304],
              [-82.277912950415981, 35.703390159784057],
              [-82.277864820363149, 35.703655329931436],
              [-82.277533810615282, 35.703616773048864],
              [-82.277485678081618, 35.703881943057873],
              [-82.277154667587979, 35.703843385146762],
              [-82.277106533677838, 35.704108554987492],
              [-82.276775522401877, 35.704069995147158],
              [-82.276727387151823, 35.704335165720465],
              [-82.276396375130091, 35.704296604851521],
              [-82.276348238503644, 35.704561775256657],
              [-82.275686212179167, 35.704484650590707],
              [-82.275638074219657, 35.704749820670088],
              [-82.275307059916997, 35.704711256873068],
              [-82.275355200042114, 35.704446086891799],
              [-82.273700141877654, 35.704253254945769],
              [-82.273651996410621, 35.704518423387064],
              [-82.271003911405217, 35.704209842605927],
              [-82.27095575717037, 35.704475010884892],
              [-82.270624746185234, 35.704436433449104],
              [-82.270672902622053, 35.704171266169183],
              [-82.270010885965576, 35.704094109692704],
              [-82.270059044172712, 35.703828941727203],
              [-82.269397029795599, 35.703751781933249],
              [-82.269445189845825, 35.703486615083989],
              [-82.26878317995704, 35.703409451913345],
              [-82.268831341777528, 35.703144284378453],
              [-82.268500338349071, 35.703105701119568],
              [-82.268548500951624, 35.702840534573042],
              [-82.268217500004212, 35.70280195051167],
              [-82.268313826311996, 35.702271615751364],
              [-82.26798282673397, 35.702233031975652],
              [-82.268030990475737, 35.701967864662777],
              [-82.267699993342276, 35.701929279183716],
              [-82.267748157829487, 35.701664111958358],
              [-82.267417160968108, 35.701625525736198],
              [-82.267465327305317, 35.701360358568657],
              [-82.267134331856667, 35.7013217724746],
              [-82.267230664602366, 35.700791438303838],
              [-82.26656867992709, 35.700714262093868],
              [-82.266616847396335, 35.700449095218545],
              [-82.266285857145178, 35.700410505875681],
              [-82.266334026464364, 35.700145339058281],
              [-82.265672047925847, 35.700068157956089],
              [-82.265623877625472, 35.700333325388939],
              [-82.264961898226943, 35.70025614042838],
              [-82.264913725452942, 35.700521306664157],
              [-82.262927790673118, 35.70028972934022],
              [-82.262975970917239, 35.700024563842348],
              [-82.261652025688292, 35.699870161439087],
              [-82.261700209860493, 35.699604996412646],
              [-82.260045291761216, 35.699411971952792],
              [-82.26009348202669, 35.699146807496163],
              [-82.259431518705213, 35.69906959239642],
              [-82.259479710740536, 35.698804427254458],
              [-82.258155794301132, 35.69864998579942],
              [-82.258107597986111, 35.698915149528645],
              [-82.257776618061357, 35.698876537025448],
              [-82.257728420369816, 35.699141700586246],
              [-82.257397440768116, 35.699103086123905],
              [-82.257301041248141, 35.699633413798963],
              [-82.256970059840313, 35.699594798180122],
              [-82.256825455508078, 35.700390287639777],
              [-82.256494470165123, 35.700351671666795],
              [-82.256205246575163, 35.701942647826598],
              [-82.255874255146267, 35.701904029284059],
              [-82.255777842279343, 35.702434354401547],
              [-82.255446849044304, 35.702395734702385],
              [-82.255350432792568, 35.702926059402074],
              [-82.255019436646933, 35.702887438575871],
              [-82.254923016974004, 35.703417761956871],
              [-82.254592020126722, 35.703379139944488],
              [-82.254495595964443, 35.703909462937155],
              [-82.254164596242731, 35.703870840698528],
              [-82.254068169763713, 35.704401162342847],
              [-82.253737168235844, 35.704362538947571],
              [-82.253640737267148, 35.704892860203493],
              [-82.253309733933193, 35.704854235651489],
              [-82.253261516914165, 35.705119396090602],
              [-82.252930512798756, 35.70508076960909],
              [-82.252737637356773, 35.706141411349641],
              [-82.252406629313285, 35.706102783455229],
              [-82.252310186452632, 35.706633103405423],
              [-82.251648167068936, 35.706555844402615],
              [-82.251599943595167, 35.706821004046084],
              [-82.250275903305692, 35.706666475650501],
              [-82.250227674167448, 35.706931634770861],
              [-82.24956565433007, 35.706854364447658],
              [-82.249517422753911, 35.707119523271288],
              [-82.248855400953673, 35.707042249118821],
              [-82.248807166903305, 35.707307406744938],
              [-82.248145145349298, 35.707230128704126],
              [-82.248096908897224, 35.707495286934439],
              [-82.247765896428575, 35.707456646464443],
              [-82.247717658599441, 35.707721804526052],
              [-82.247386646454117, 35.707683162097013],
              [-82.247338407248122, 35.707948319990052],
              [-82.247007393289337, 35.70790967746246],
              [-82.246959152706253, 35.708174835186938],
              [-82.24629712468294, 35.708097546243259],
              [-82.246248881661927, 35.70836270367095],
              [-82.245917866529027, 35.708324058185198],
              [-82.245869622094759, 35.708589214543323],
              [-82.245538606216826, 35.708550568028791],
              [-82.245490360441664, 35.708815725119138],
              [-82.245159343782674, 35.708777076674956],
              [-82.245111096630453, 35.709042233596591],
              [-82.244780079226359, 35.709003584123657],
              [-82.24473182959251, 35.709268740906055],
              [-82.244400812584189, 35.709230091275742],
              [-82.24435256153717, 35.709495246988553],
              [-82.244021542679178, 35.70945659635882],
              [-82.24392503751497, 35.709986907406467],
              [-82.243594017955502, 35.709948255590405],
              [-82.243111461491111, 35.712599808038632],
              [-82.242780430532292, 35.712561154070386],
              [-82.242732171088576, 35.7128263088998],
              [-82.242401139348658, 35.712787653001826],
              [-82.242352879632222, 35.713052807633005],
              [-82.241690813909955, 35.712975493808948],
              [-82.241642550650624, 35.713240648172636],
              [-82.241311517736818, 35.713201989316246],
              [-82.241263253100144, 35.71346714351111],
              [-82.240601185030272, 35.71338982377015],
              [-82.240552919059823, 35.713654977638633],
              [-82.240221883867591, 35.71361631585313],
              [-82.240270152003802, 35.713351162083342],
              [-82.238946020922512, 35.713196508189618],
              [-82.238897748505863, 35.713461660545995],
              [-82.237904650865858, 35.713345659818422],
              [-82.23785637498564, 35.713610812650543],
              [-82.237525341261943, 35.713572143531039],
              [-82.237477064004366, 35.7138372961942],
              [-82.236814996487908, 35.713759954967387],
              [-82.23676671679199, 35.71402510733359],
              [-82.236435681894676, 35.713986435255492],
              [-82.236387400785276, 35.714251586551995],
              [-82.235725330921852, 35.714174239407953],
              [-82.235677046305341, 35.714439391337585],
              [-82.235014975584875, 35.714362040334343],
              [-82.234966688493842, 35.714627191066],
              [-82.23463565309919, 35.714588514070421],
              [-82.234587364666751, 35.714853665534044],
              [-82.23392529159959, 35.714776308613644],
              [-82.233877000692601, 35.715041458879277],
              [-82.233545963019992, 35.71500277895445],
              [-82.233449378170874, 35.715533080007873],
              [-82.23311833869235, 35.715494398925969],
              [-82.233070044714211, 35.715759548812876],
              [-82.232739004490895, 35.71572086670195],
              [-82.232642413463111, 35.716251166097116],
              [-82.232311371433866, 35.716212482829135],
              [-82.232214775913789, 35.716742781833887],
              [-82.231883732078614, 35.716704097408872],
              [-82.231835433335235, 35.716969246707116],
              [-82.231504388755297, 35.716930561253129],
              [-82.231407787092309, 35.717460859500214],
              [-82.231076740706428, 35.71742217288898],
              [-82.231028437786762, 35.717687321837793],
              [-82.230366343805997, 35.717609944755743],
              [-82.230318038447592, 35.717875093407287],
              [-82.229655943610084, 35.717797712465767],
              [-82.229607635812911, 35.718062860820062],
              [-82.228945539014205, 35.717985476048341],
              [-82.228897228778237, 35.718250624105352],
              [-82.228566180362662, 35.718211930675892],
              [-82.22851786874908, 35.718477078563836],
              [-82.227855769604432, 35.718399687874602],
              [-82.227807455516171, 35.718664834564251],
              [-82.226152209562045, 35.718471341842999],
              [-82.226103888746749, 35.718736487880001],
              [-82.225110741318247, 35.718620380344305],
              [-82.225062418143551, 35.718885526827258],
              [-82.224731367824219, 35.718846822104801],
              [-82.22468304327181, 35.719111968418638],
              [-82.224351992207787, 35.719073262667145],
              [-82.224303665172926, 35.719338408841111],
              [-82.223972614469105, 35.719299702031329],
              [-82.223779298847276, 35.720360284003334],
              [-82.223448243110582, 35.720321575808988],
              [-82.223399912370454, 35.720586721521983],
              [-82.223068856993891, 35.720548012269241],
              [-82.223020524839995, 35.720813156912108],
              [-82.222358410704814, 35.720735735506381],
              [-82.222310076147892, 35.721000880752648],
              [-82.221979018494025, 35.720962168570345],
              [-82.221930682523293, 35.721227312746514],
              [-82.221268566043094, 35.721149885422953],
              [-82.221220227633395, 35.721415029301646],
              [-82.220889168807062, 35.721376314160374],
              [-82.220792487846325, 35.721906602468465],
              [-82.220461427214232, 35.721867886169854],
              [-82.220364742828522, 35.722398173156648],
              [-82.220033680390671, 35.722359455700655],
              [-82.219985336109147, 35.722624599018914],
              [-82.219654272926746, 35.722585880533828],
              [-82.219605927267395, 35.722851023682828],
              [-82.219274863340473, 35.722812304168627],
              [-82.219226516303081, 35.723077447148363],
              [-82.218564387241145, 35.723000004260058],
              [-82.218516037764587, 35.723265146942182],
              [-82.218184971560561, 35.723226424498208],
              [-82.218136620706119, 35.723491567011024],
              [-82.217474489335359, 35.723414119105541],
              [-82.217426136005784, 35.723679260419878],
              [-82.217095068629604, 35.723640535016813],
              [-82.217046713957842, 35.723905677062625],
              [-82.216715646941879, 35.723866950601227],
              [-82.216667289751612, 35.724132091606194],
              [-82.216336221991085, 35.724093364115582],
              [-82.21628786456337, 35.724358505822813],
              [-82.214301456004904, 35.724126119449295],
              [-82.214253090752266, 35.724391259473776],
              [-82.213590954598061, 35.724313789595563],
              [-82.213494218933107, 35.724844069908954],
              [-82.213163149779135, 35.724805333797782],
              [-82.213114780392246, 35.72507047331414],
              [-82.212783709388987, 35.725031736202965],
              [-82.212735338624071, 35.725296875549894],
              [-82.212404267981242, 35.725258137380386],
              [-82.212355894733307, 35.725523276587083],
              [-82.212024823346198, 35.72548453738839],
              [-82.211976448720122, 35.725749676425679],
              [-82.211645376552795, 35.725710935296945],
              [-82.211597001689228, 35.725976075036513],
              [-82.210934854008883, 35.725898590779003],
              [-82.210886476670041, 35.726163729319893],
              [-82.210555402226106, 35.726124985261009],
              [-82.21050702350918, 35.726390123632527],
              [-82.21017594835665, 35.726351379445418],
              [-82.210127567156604, 35.726616517676568],
              [-82.209796491224111, 35.726577771559271],
              [-82.209651343679127, 35.727373186492748],
              [-82.209320264879665, 35.727334439089631],
              [-82.209175110157616, 35.728129852396151],
              [-82.208844028491228, 35.728091103707058],
              [-82.208795641888685, 35.728356241265217],
              [-82.208464559513715, 35.728317492447694],
              [-82.208319394584322, 35.729112903589581],
              [-82.207988308201905, 35.729074152614345],
              [-82.207843137271212, 35.729869563901524],
              [-82.207512048021712, 35.729830811640255],
              [-82.207463656016174, 35.730095948525218],
              [-82.206801477450455, 35.730018441014046],
              [-82.206753082969385, 35.730283576700209],
              [-82.2064219925484, 35.730244821479552],
              [-82.20637359672466, 35.730509957896963],
              [-82.206042505559296, 35.730471201647013],
              [-82.205994108357217, 35.730736337894911],
              [-82.205663016447559, 35.730697580615548],
              [-82.205614616726422, 35.730962715822088],
              [-82.205283525177364, 35.730923957484414],
              [-82.205186722696894, 35.731454228417903],
              [-82.204855628237425, 35.731415468951681],
              [-82.204758823470641, 35.731945739434444],
              [-82.204427727205527, 35.73190697881035],
              [-82.204330917907001, 35.732437247999584],
              [-82.203999819836142, 35.732398486217946],
              [-82.203903007182234, 35.732928755886284],
              [-82.203571907305715, 35.73288999294676],
              [-82.203523499423738, 35.733155127140193],
              [-82.203192398802969, 35.73311636317149],
              [-82.203143988437631, 35.733381497224336],
              [-82.202150685258374, 35.733265198626377],
              [-82.202102272496603, 35.73353033222358],
              [-82.201108968987725, 35.733414025132369],
              [-82.201060551619648, 35.733679158332038],
              [-82.200729450034899, 35.733640387671699],
              [-82.200681031288127, 35.733905520701633],
              [-82.200349928923558, 35.733866748111048],
              [-82.200301508798262, 35.734131880971283],
              [-82.199970405725139, 35.734093108252239],
              [-82.199921984221234, 35.734358240942726],
              [-82.199590880403917, 35.734319467194339],
              [-82.199494034285863, 35.734849731293643],
              [-82.199162928662972, 35.734810956387435],
              [-82.199114504084505, 35.73507608869712],
              [-82.198783396576815, 35.735037311889677],
              [-82.198734970619668, 35.735302444029585],
              [-82.198403863472834, 35.735263666163753],
              [-82.198258579408474, 35.736059061979255],
              [-82.197927468289691, 35.736020282856117],
              [-82.197879038940485, 35.736285414573835],
              [-82.197216816672452, 35.736207854239694],
              [-82.197265248144532, 35.735942722778958],
              [-82.196603029268587, 35.735865159098132],
              [-82.196651463651179, 35.735600027824013],
              [-82.195658141436084, 35.735483675466504],
              [-82.195706577580651, 35.735218544565534],
              [-82.194051052922276, 35.735024605974303],
              [-82.194099495161325, 35.734759475645433],
              [-82.19376839245696, 35.734720685167055],
              [-82.193719949156531, 35.734985815367295],
              [-82.193332390115415, 35.737106852807926],
              [-82.19300127707082, 35.737068060300984],
              [-82.192855933287376, 35.737863447503173],
              [-82.192524817375855, 35.737824653709744],
              [-82.192427918523151, 35.73835491076067],
              [-82.192096800806155, 35.738316115809297],
              [-82.191999897491371, 35.738846373367487],
              [-82.191668777968857, 35.738807577258122],
              [-82.191620324203512, 35.739072705410642],
              [-82.191289203937089, 35.739033908271807],
              [-82.191192294436149, 35.739564164166573],
              [-82.190861171259115, 35.739525365898714],
              [-82.190812715523506, 35.739790493640946],
              [-82.190521968881882, 35.741381258468159],
              [-82.190853098384366, 35.741420057664705],
              [-82.190804641487901, 35.741685185245359],
              [-82.190473509818759, 35.741646385949167],
              [-82.190425050438137, 35.741911513388864],
              [-82.190093918025042, 35.741872713063231],
              [-82.190045457229843, 35.742137839431933],
              [-82.189714325177732, 35.742099038047847],
              [-82.189665861933975, 35.742364165176383],
              [-82.189334729137755, 35.742325362762784],
              [-82.189286265584514, 35.742590488791429],
              [-82.188955130939149, 35.742551685377272],
              [-82.188858199687417, 35.743081937982851],
              [-82.188527063236549, 35.74304313341063],
              [-82.188478596607482, 35.743308259057692],
              [-82.188809734119999, 35.74334706375847],
              [-82.188664334478204, 35.744142442666956],
              [-82.188995475492916, 35.744181246852406],
              [-82.188947009716799, 35.744446372590495],
              [-82.188615867640479, 35.744407568276522],
              [-82.188518931907453, 35.744937819399908],
              [-82.188187788025388, 35.744899013927828],
              [-82.188090848934351, 35.745429265528863],
              [-82.187759703211242, 35.745390457997793],
              [-82.187565815830823, 35.746450959315005],
              [-82.187234666178782, 35.746412150368698],
              [-82.187186192192925, 35.746677275247485],
              [-82.187517343976111, 35.746716083392513],
              [-82.187420398279983, 35.747246333253528],
              [-82.187089244373368, 35.747207524851383],
              [-82.186992294178239, 35.74773777431782],
              [-82.186661138430409, 35.747698963856635],
              [-82.186564185946523, 35.748229212870562],
              [-82.186233028428475, 35.748190402152112],
              [-82.186136071410033, 35.748720649870457],
              [-82.18580491208624, 35.748681837993843],
              [-82.185756432021151, 35.748946961211558],
              [-82.185425270848199, 35.748908148334237],
              [-82.185231343235088, 35.749968642076148],
              [-82.185562508654911, 35.750007455467795],
              [-82.185368579100682, 35.751067949086256],
              [-82.185037409398433, 35.751029134279491],
              [-82.184843471583818, 35.752089625786859],
              [-82.184512297987766, 35.752050810465612],
              [-82.184269861007337, 35.753376422200084],
              [-82.183607503009767, 35.753298786696689],
              [-82.183559012978918, 35.753563908649674],
              [-82.182234297654659, 35.753408626286252],
              [-82.18218580195375, 35.753673747712099],
              [-82.181854623113864, 35.753634924282196],
              [-82.181806126033266, 35.753900045537691],
              [-82.181474946449384, 35.753861221078182],
              [-82.181426447989267, 35.754126342163225],
              [-82.18109526655617, 35.754087516702889],
              [-82.181046766716449, 35.754352637617593],
              [-82.180715585679991, 35.754313811999552],
              [-82.180667084425124, 35.754578931843],
              [-82.180335902644757, 35.754540105195254],
              [-82.180287398905051, 35.754805224897197],
              [-82.179956216380603, 35.754766397219718],
              [-82.179907712366528, 35.755031516722404],
              [-82.179576527957479, 35.754992687143194],
              [-82.179528022599172, 35.755257807376339],
              [-82.179196837446128, 35.755218976767431],
              [-82.179148330708145, 35.755484096830159],
              [-82.178485960337241, 35.755406432622891],
              [-82.178437450052499, 35.755671552415428],
              [-82.17777507882947, 35.75559388434651],
              [-82.177726566103075, 35.755859003839987],
              [-82.177395380459288, 35.755820168310791],
              [-82.177346866317777, 35.756085286732933],
              [-82.17701567882483, 35.756046450202859],
              [-82.176627546929311, 35.75816739681791],
              [-82.176296351259325, 35.758128558357598],
              [-82.176247832132859, 35.758393676145872],
              [-82.175585438553085, 35.758315997194273],
              [-82.175536916984882, 35.758581114683373],
              [-82.174874523623089, 35.758503430940209],
              [-82.17482599850787, 35.758768548158969],
              [-82.173832407629192, 35.758652014498828],
              [-82.17378387901023, 35.758917131289735],
              [-82.17312148458096, 35.758839438020551],
              [-82.173072953520219, 35.759104554512227],
              [-82.172741755720679, 35.759065706397244],
              [-82.17269322328012, 35.75933082271834],
              [-82.172362024701414, 35.759291972672678],
              [-82.17231349088091, 35.759557088823193],
              [-82.171982291593693, 35.75951823864861],
              [-82.171933756393159, 35.759783354628567],
              [-82.171602555221583, 35.759744502552046],
              [-82.171554018641075, 35.760009618361387],
              [-82.171222817866294, 35.75997076612714],
              [-82.171174279905841, 35.760235881765979],
              [-82.170843078351794, 35.760197027600924],
              [-82.170794537906161, 35.760462143097854],
              [-82.170463335643632, 35.760423288803956],
              [-82.170414794887961, 35.760688403200561],
              [-82.170083590776343, 35.760649547905601],
              [-82.17003504867597, 35.760914663032544],
              [-82.169703843785229, 35.760875805806847],
              [-82.16965530030474, 35.761140920763012],
              [-82.169606756470941, 35.761406034776485],
              [-82.169937962380203, 35.761444892288353],
              [-82.169889419290101, 35.761710006388491],
              [-82.170220627684742, 35.761748863099122],
              [-82.170172084233144, 35.762013977314773],
              [-82.170503293972516, 35.762052832352033],
              [-82.170260581744728, 35.763378405130823],
              [-82.169929366695541, 35.763339549450123],
              [-82.16968663895868, 35.764665119692083],
              [-82.169024198054217, 35.764587403468056],
              [-82.168975650084491, 35.764852516935562],
              [-82.168644428477137, 35.764813656906966],
              [-82.168595879127324, 35.765078770203772],
              [-82.168264656811402, 35.765039910046156],
              [-82.168167553927873, 35.765570136284943],
              [-82.167836329770807, 35.765531274067932],
              [-82.167545003759614, 35.767121950188034],
              [-82.167213773583882, 35.767083087198351],
              [-82.167165217043433, 35.767348198858329],
              [-82.166833986123777, 35.767309334838714],
              [-82.166785428203127, 35.767574446327863],
              [-82.166454196539647, 35.767535581278317],
              [-82.166114273749912, 35.769391362353815],
              [-82.165783033864628, 35.769352495530725],
              [-82.165637344031794, 35.770147828117416],
              [-82.165306101278418, 35.77010896000693],
              [-82.165257536670623, 35.770374070646511],
              [-82.164595049959232, 35.770296330563411],
              [-82.164546482908875, 35.770561440903386],
              [-82.164215238950874, 35.770522568930687],
              [-82.164118100665533, 35.771052789255471],
              [-82.164449346747716, 35.771091661485542],
              [-82.164109357392988, 35.772947432265994],
              [-82.163446850676522, 35.772869685102272],
              [-82.163398277213105, 35.773134794791559],
              [-82.163067023270159, 35.773095919729023],
              [-82.163018448426186, 35.773361029247312],
              [-82.162687193739416, 35.77332215315478],
              [-82.162638616409311, 35.773587262530803],
              [-82.162307360978815, 35.773548385408212],
              [-82.162258783373602, 35.773813494584537],
              [-82.161596270237808, 35.773735737406696],
              [-82.161547690189792, 35.774000846283265],
              [-82.160885176203195, 35.773923085242714],
              [-82.160836593712361, 35.774188193819548],
              [-82.160505335581789, 35.774149311832922],
              [-82.160359581836417, 35.77494463515054],
              [-82.160028320837554, 35.774905751876318],
              [-82.159785384786332, 35.776231289588097],
              [-82.159454118794727, 35.776192404768722],
              [-82.159211168329946, 35.777517939906716],
              [-82.158879897345571, 35.777479053542173],
              [-82.158539744285278, 35.77933479883464],
              [-82.158208465077649, 35.779295910696021],
              [-82.158159869411818, 35.779561015967239],
              [-82.158491149716866, 35.779599905135548],
              [-82.158393959589489, 35.780130116709266],
              [-82.158725242302552, 35.780169004332905],
              [-82.158676647841034, 35.780434110635376],
              [-82.159007930829489, 35.78047299751502],
              [-82.158959337076524, 35.780738103002996],
              [-82.159621908344818, 35.78081587425833],
              [-82.159573316433296, 35.781080980862313],
              [-82.159904603607572, 35.781119865266682],
              [-82.159856013510392, 35.781384971027485],
              [-82.160518590939134, 35.781462737389489],
              [-82.160470001577863, 35.781727844294892],
              [-82.161132583475734, 35.781805606379336],
              [-82.161083995920805, 35.782070713499884],
              [-82.161415287874632, 35.782109593783531],
              [-82.161463874367257, 35.781844486534318],
              [-82.161795165577132, 35.781883365787827],
              [-82.161746581252629, 35.782148473137106],
              [-82.162409166717495, 35.782226228296537],
              [-82.162360584164162, 35.78249133495995],
              [-82.163023174168515, 35.782569087643381],
              [-82.162974593421609, 35.782834194521826],
              [-82.163637186824616, 35.782911943857066],
              [-82.163588607884179, 35.783177050950535],
              [-82.164582504081906, 35.783293667663727],
              [-82.164533928010442, 35.783558775100794],
              [-82.165196528812288, 35.78363651503588],
              [-82.165147954547379, 35.783901622687992],
              [-82.166141863001613, 35.784018226623218],
              [-82.166093291605804, 35.784283334618969],
              [-82.16675590094718, 35.784361066025696],
              [-82.166707332463631, 35.784626174207631],
              [-82.167038638656805, 35.784665038237065],
              [-82.166941501789239, 35.785195255660611],
              [-82.167272810425743, 35.785234119045967],
              [-82.167127105318627, 35.786029443911659],
              [-82.167458418602081, 35.786068307653743],
              [-82.167361281660092, 35.786598524294909],
              [-82.167692597351873, 35.786637386492004],
              [-82.167595462367203, 35.787167603191811],
              [-82.16792678050254, 35.787206464744777],
              [-82.167732510927863, 35.78826689724773],
              [-82.168063833667318, 35.788305759314873],
              [-82.167820994269363, 35.789631298968281],
              [-82.167489665146292, 35.789592437187473],
              [-82.167441095891078, 35.789857544304063],
              [-82.167109766023827, 35.789818681493053],
              [-82.167012623362524, 35.79034889627053],
              [-82.166681292793953, 35.790310032271883],
              [-82.166632719352577, 35.7905751390321],
              [-82.166301386934123, 35.790536274031979],
              [-82.166252812111551, 35.790801380620849],
              [-82.165921480054465, 35.790762514561798],
              [-82.165872903850868, 35.791027620979349],
              [-82.165541569943841, 35.790988753918825],
              [-82.165492992359106, 35.791253860165064],
              [-82.165824327364007, 35.791292728255179],
              [-82.165775750523522, 35.791557834587564],
              [-82.165444414491091, 35.791518967269667],
              [-82.165395836269454, 35.791784073430669],
              [-82.165064499492786, 35.791745205082542],
              [-82.165015919889967, 35.792010311072204],
              [-82.16468458233382, 35.791971440792949],
              [-82.164636001385077, 35.792236547512182],
              [-82.164304663084692, 35.792197676202683],
              [-82.164256080719511, 35.792462781849679],
              [-82.163924741710161, 35.792423910410825],
              [-82.16387615796377, 35.792689015886495],
              [-82.163544818174955, 35.792650142516493],
              [-82.163496231976865, 35.792915248750383],
              [-82.162833551264555, 35.792837499949258],
              [-82.16268778538273, 35.79363281489136],
              [-82.163019128750179, 35.793671689678412],
              [-82.162970541285688, 35.793936794683312],
              [-82.162639196820351, 35.793897918866676],
              [-82.162542016598863, 35.794428128548425],
              [-82.162210670361702, 35.794389252473678],
              [-82.162113486705366, 35.794919460826392],
              [-82.161782138661252, 35.794880583592651],
              [-82.161684951605068, 35.795410791517014],
              [-82.161353601718844, 35.795371912223381],
              [-82.161305006667675, 35.795637016443202],
              [-82.161636357616757, 35.795675895865564],
              [-82.161587763274554, 35.795940999270499],
              [-82.161919116710791, 35.795979877891369],
              [-82.161870523148053, 35.796244982283206],
              [-82.162201877930414, 35.796283859230257],
              [-82.16210469193966, 35.796814067242195],
              [-82.16243604920146, 35.796852944446002],
              [-82.162387456773473, 35.797118048066125],
              [-82.162056098484101, 35.7970791716345],
              [-82.161958910581902, 35.797609379389939],
              [-82.161627550450305, 35.797570500898416],
              [-82.161578954940836, 35.797835604132892],
              [-82.161247594100217, 35.797796725511908],
              [-82.161198997209311, 35.798061828574838],
              [-82.160867635589156, 35.798022948022577],
              [-82.160819037352127, 35.798288051814964],
              [-82.160487674987735, 35.798249170232452],
              [-82.160390474291063, 35.79877937655904],
              [-82.160059110119604, 35.798740493817462],
              [-82.159961905987402, 35.799270698814603],
              [-82.159630540044162, 35.799231815814835],
              [-82.159338913622747, 35.800822429005976],
              [-82.159007540479806, 35.800783543460035],
              [-82.158910326997557, 35.801313747256906],
              [-82.158247578521852, 35.801235972916366],
              [-82.158198968623893, 35.801501074507371],
              [-82.156210722291789, 35.801267730032208],
              [-82.156162105697675, 35.801532830807844],
              [-82.155499358069875, 35.801455041526111],
              [-82.155450737890206, 35.80172014112911],
              [-82.155119364044509, 35.801681244992714],
              [-82.155022121779339, 35.802211445585684],
              [-82.154690745020659, 35.802172548318666],
              [-82.154544876525975, 35.802967846684048],
              [-82.154213496897412, 35.802928948129122],
              [-82.15416487165146, 35.803194047989336],
              [-82.153833491278689, 35.80315514840408],
              [-82.153784864616028, 35.803420247191617],
              [-82.153453483499135, 35.803381346575918],
              [-82.153404855489683, 35.803646446092728],
              [-82.153073473593636, 35.803607543545638],
              [-82.15302484420242, 35.803872642890724],
              [-82.15269346159738, 35.803833740214067],
              [-82.152644830789257, 35.804098838486553],
              [-82.152313447440122, 35.80405993477946],
              [-82.152264815250192, 35.804325032880222],
              [-82.150939279649251, 35.804169407647812],
              [-82.150987916090841, 35.803904310062286],
              [-82.150656534300907, 35.803865400946023],
              [-82.148668249292356, 35.803631930946132],
              [-82.148619605410317, 35.803897027629965],
              [-82.147956844523208, 35.803819196516059],
              [-82.147859549373976, 35.804349390197615],
              [-82.147528167265477, 35.80431047258017],
              [-82.147479518131888, 35.804575568777175],
              [-82.147382218943065, 35.805105761943409],
              [-82.147713605311097, 35.805144679017992],
              [-82.14761630697231, 35.805674872270039],
              [-82.147947694679274, 35.805713788729136],
              [-82.147753099461596, 35.806774173402296],
              [-82.148084492880429, 35.806813090347426],
              [-82.147987194895748, 35.807343281797095],
              [-82.148318590759644, 35.80738219809821],
              [-82.148269942369296, 35.807647294337741],
              [-82.148601339580082, 35.807686208965109],
              [-82.148552691933773, 35.807951305290473],
              [-82.149215490579223, 35.808029132970063],
              [-82.149166844740094, 35.808294229510025],
              [-82.149498245033811, 35.80833314168995],
              [-82.149449599938677, 35.808598238315753],
              [-82.149781002720331, 35.808637149694185],
              [-82.149732358369391, 35.808902246405687],
              [-82.150063761426992, 35.808941157039825],
              [-82.149869187227665, 35.810001542111735],
              [-82.149537779917708, 35.809962630962268],
              [-82.149489133972011, 35.81022772745856],
              [-82.149157724776856, 35.810188814406359],
              [-82.149109078555071, 35.810453910702115],
              [-82.148777668615793, 35.810414996619336],
              [-82.148729019905957, 35.810680092771776],
              [-82.148397609222584, 35.810641177658468],
              [-82.14834896023666, 35.810906273610406],
              [-82.148017548809165, 35.810867357466556],
              [-82.147968897335161, 35.811132453275249],
              [-82.147637485163571, 35.811093536100763],
              [-82.147588832272362, 35.811358630836644],
              [-82.146926006795212, 35.811280794425819],
              [-82.146877351493856, 35.811545889761724],
              [-82.146545938153139, 35.81150696962446],
              [-82.146497281434563, 35.811772063887531],
              [-82.145834453619116, 35.811694221550987],
              [-82.145785794455293, 35.81195931551327],
              [-82.145122965789938, 35.81188146931207],
              [-82.145074304180838, 35.812146562973602],
              [-82.144411474665475, 35.812068712907774],
              [-82.144362809505068, 35.812333806296962],
              [-82.143699979104824, 35.812255951465588],
              [-82.143651311534143, 35.81252104545478],
              [-82.142657065137882, 35.812404256058066],
              [-82.142608394023725, 35.812669348716653],
              [-82.142276978133538, 35.8126304169854],
              [-82.14194556256237, 35.81259148435241],
              [-82.141750866409339, 35.81365185352427],
              [-82.141419445798846, 35.81361291950256],
              [-82.14132209368114, 35.814143103571766],
              [-82.140990672369611, 35.814104168361951],
              [-82.140941993662793, 35.814369260231373],
              [-82.140610571607311, 35.814330323991058],
              [-82.140561891518104, 35.814595415688416],
              [-82.140230468718755, 35.81455647841738],
              [-82.140181787212171, 35.814821569041747],
              [-82.139850363668913, 35.81478263074009],
              [-82.139801680815069, 35.815047722093247],
              [-82.139470256527758, 35.81500878276092],
              [-82.139372887701029, 35.815538964179026],
              [-82.139041461606737, 35.815500023687136],
              [-82.13899277566891, 35.815765114652869],
              [-82.138661348830595, 35.815726173130329],
              [-82.138563973835772, 35.816256353773412],
              [-82.138232544084403, 35.816217411119794],
              [-82.138183855027407, 35.816482500797122],
              [-82.137852424532127, 35.816443557112905],
              [-82.137755043369012, 35.816973736980927],
              [-82.137423611066637, 35.816934792137012],
              [-82.137374918925445, 35.817199881426795],
              [-82.137043485879175, 35.817160935552195],
              [-82.136800013996904, 35.818486381636923],
              [-82.136468575953813, 35.818447434215962],
              [-82.136371182161724, 35.81897761174578],
              [-82.136039742311496, 35.818938663165191],
              [-82.135942345115524, 35.819468840263895],
              [-82.135610902352141, 35.819429890552115],
              [-82.135513501752314, 35.819960067219718],
              [-82.135182058252951, 35.819921115418921],
              [-82.135133355857747, 35.820186203573108],
              [-82.134801910543217, 35.820147251670939],
              [-82.134753207871412, 35.820412339624333],
              [-82.134421761778171, 35.820373385790532],
              [-82.13437305661769, 35.820638473600106],
              [-82.134041610921528, 35.820599519607967],
              [-82.133992904378417, 35.820864607245255],
              [-82.133661456797313, 35.820825651349942],
              [-82.133515332026704, 35.821620912714074],
              [-82.133846782797619, 35.821659868996214],
              [-82.133651949528314, 35.822720216789698],
              [-82.13332049453912, 35.822681260892686],
              [-82.133271784377428, 35.822946348054074],
              [-82.133603240429849, 35.822985304080014],
              [-82.13335968893557, 35.82431073720651],
              [-82.133028227566371, 35.824271780535867],
              [-82.132979515453144, 35.82453686653588],
              [-82.13198512782499, 35.824419989881925],
              [-82.131936412202236, 35.824685075451669],
              [-82.131604948843076, 35.824646114043226],
              [-82.131556231872409, 35.824911200341482],
              [-82.130893305092073, 35.824833274532665],
              [-82.130844584568919, 35.825098360557988],
              [-82.130181656939868, 35.825020430883782],
              [-82.130132933970586, 35.825285516607714],
              [-82.128807078328194, 35.825129646822049],
              [-82.12885580555087, 35.824864561613936],
              [-82.127198499048703, 35.82466970449552],
              [-82.127247231303471, 35.824404620789771],
              [-82.126252855733455, 35.824287695508851],
              [-82.126301590858489, 35.824022612146699],
              [-82.124644308844211, 35.823827719315112],
              [-82.12459556840264, 35.82409280203229],
              [-82.123269744035241, 35.823936870122708],
              [-82.123220997949502, 35.824201953209524],
              [-82.122558086092866, 35.824123981120991],
              [-82.122509337526182, 35.824389063005462],
              [-82.121514967860264, 35.824272098200687],
              [-82.12146621578394, 35.824537179654513],
              [-82.121134759185011, 35.824498189744403],
              [-82.121086004619627, 35.824763271054046],
              [-82.12075454727723, 35.824724280113124],
              [-82.120657036166818, 35.825254442315554],
              [-82.120325576982779, 35.825215449313724],
              [-82.120276819331536, 35.825480530234842],
              [-82.119935508998097, 35.827336094507167],
              [-82.119604040555814, 35.827297100500267],
              [-82.119408994618539, 35.828357421085755],
              [-82.119077522207732, 35.828318424759857],
              [-82.118882465759441, 35.829378743259703],
              [-82.118550989449687, 35.829339746416565],
              [-82.118258387661669, 35.830930221211858],
              [-82.117926905256525, 35.83089122179161],
              [-82.117780596301472, 35.83168645822748],
              [-82.117634284432981, 35.832481693369864],
              [-82.11730279486072, 35.832442692301527],
              [-82.117156476923114, 35.833237926663848],
              [-82.116824983408804, 35.83319892523555],
              [-82.116776209393777, 35.833464002229576],
              [-82.115450238636967, 35.833307985065808],
              [-82.11540145897655, 35.833573062429096],
              [-82.114738473355942, 35.833495047276486],
              [-82.114689691248628, 35.833760124337836],
              [-82.114358197854685, 35.833721115279594],
              [-82.114309414363973, 35.833986192168247],
              [-82.113977919120259, 35.833947182107224],
              [-82.113929134246021, 35.834212258823001],
              [-82.113597639365096, 35.834173247702672],
              [-82.113548853107503, 35.834438324245653],
              [-82.113217357482952, 35.834399312094376],
              [-82.113168568735588, 35.834664388492811],
              [-82.112837072367512, 35.834625375310424],
              [-82.112788283308305, 35.834890450606885],
              [-82.111130798772535, 35.834695371477672],
              [-82.111082004075882, 35.83496044608485],
              [-82.110750506394496, 35.834921427260831],
              [-82.110701710349005, 35.835186502596123],
              [-82.109707218247422, 35.835069440268612],
              [-82.109658417550321, 35.835334514300044],
              [-82.109326919022209, 35.835295491610026],
              [-82.109278116941553, 35.835560565468583],
              [-82.108946618776372, 35.835521541719238],
              [-82.10884901156264, 35.836051689947844],
              [-82.108517510484177, 35.83601266506647],
              [-82.108468705316398, 35.836277738535529],
              [-82.108137203494422, 35.836238712623107],
              [-82.10808839694306, 35.836503785919241],
              [-82.10775689548403, 35.836464758947557],
              [-82.107708086477345, 35.836729832999872],
              [-82.107376584240356, 35.83669080409615],
              [-82.107278964211233, 35.837220950882092],
              [-82.106947459060962, 35.837181920846291],
              [-82.106801023961651, 35.837977139844881],
              [-82.106469515975476, 35.837938109420541],
              [-82.106323073663333, 35.838733326764782],
              [-82.105991562806651, 35.838694295050963],
              [-82.105942746629935, 35.838959367291643],
              [-82.105611234995237, 35.838920333645795],
              [-82.105562417434797, 35.839185405713543],
              [-82.105230905091432, 35.839146371937382],
              [-82.105182086147138, 35.839411443832148],
              [-82.104850573025786, 35.839372408124028],
              [-82.104801752697824, 35.839637479845663],
              [-82.103475698122821, 35.839481329306686],
              [-82.103426873220286, 35.839746400467853],
              [-82.102763845173783, 35.839668319542191],
              [-82.102666190119365, 35.840198460315221],
              [-82.102334673913376, 35.840159418705561],
              [-82.102285844824934, 35.840424488446651],
              [-82.101954327875731, 35.840385445805801],
              [-82.101807834460715, 35.841180656208273],
              [-82.101476314606359, 35.841141611376962],
              [-82.101378648220816, 35.841671750876586],
              [-82.101047126594125, 35.841632705785763],
              [-82.100802948030037, 35.842958052712234],
              [-82.100168044404583, 35.846403946742491],
              [-82.100499586318264, 35.846442995031765],
              [-82.100401905978899, 35.846973131900697],
              [-82.100070361937767, 35.846934083353084],
              [-82.099484236767353, 35.850114895798676],
              [-82.099815794680367, 35.850153945868712],
              [-82.099571566651264, 35.851479281079889],
              [-82.099240004560187, 35.851440231236474],
              [-82.099191156258556, 35.851705297853933],
              [-82.098859592282921, 35.851666246106475],
              [-82.098810743703481, 35.851931312522453],
              [-82.098147614620459, 35.851853206963767],
              [-82.098098762486217, 35.852118273105347],
              [-82.097435632522718, 35.852040162778756],
              [-82.097386779081489, 35.85230522949044],
              [-82.095065826857336, 35.852031814026489],
              [-82.095114688886582, 35.851766749092249],
              [-82.094783126594407, 35.851727685360125],
              [-82.094831990473637, 35.851462620482828],
              [-82.094500429600316, 35.851423556878729],
              [-82.094549293116188, 35.851158492114706],
              [-82.094217734733846, 35.851119427709691],
              [-82.094266598993002, 35.850854363030805],
              [-82.09393504085385, 35.850815296980159],
              [-82.093983906962904, 35.850550232358252],
              [-82.093652350242593, 35.850511166435716],
              [-82.093701217094917, 35.850246101898968],
              [-82.09336966172441, 35.850207034302649],
              [-82.093418529319877, 35.849941969851145],
              [-82.09308697536828, 35.84990290238288],
              [-82.093135843672499, 35.849637837115658],
              [-82.092472739964919, 35.849559699703384],
              [-82.09252161011058, 35.849294635551487],
              [-82.091858510884748, 35.849216493860538],
              [-82.091809638611608, 35.84948155775389],
              [-82.091146538575046, 35.849403413096979],
              [-82.090999913484012, 35.850198604637704],
              [-82.090668360720926, 35.85015952966743],
              [-82.09061948397418, 35.850424592996518],
              [-82.090287929395757, 35.850385517923797],
              [-82.090239051264646, 35.850650581079563],
              [-82.089907497049623, 35.850611504947423],
              [-82.089858617534134, 35.850876567929696],
              [-82.089527061469497, 35.850837490794213],
              [-82.0894781805696, 35.851102553603155],
              [-82.088483513252228, 35.850985314538406],
              [-82.088532397343513, 35.850720252117441],
              [-82.086211525963279, 35.85044666332282],
              [-82.086260418286571, 35.85018160173486],
              [-82.085928867885016, 35.850142513876996],
              [-82.085977760951423, 35.849877452374308],
              [-82.08531466323798, 35.849799274211023],
              [-82.085363557004484, 35.849534212950942],
              [-82.084700463807835, 35.849456031410099],
              [-82.084749360487564, 35.849190970336558],
              [-82.084417814880709, 35.849151878356409],
              [-82.084368917171687, 35.849416940201522],
              [-82.083374279084666, 35.849299658460758],
              [-82.083325377829439, 35.849564718972871],
              [-82.081336103899474, 35.849330129459062],
              [-82.08138501264331, 35.8490650696952],
              [-82.080721927144978, 35.848986866505385],
              [-82.080770836588798, 35.848721806984173],
              [-82.080107754466681, 35.848643599544083],
              [-82.080058841789025, 35.848908658834489],
              [-82.079395758857245, 35.848830448428394],
              [-82.079346843731443, 35.849095507416003],
              [-82.078683758814861, 35.849017292270076],
              [-82.078634841275147, 35.849282351855599],
              [-82.077971756621253, 35.849204132814876],
              [-82.078020676322581, 35.848939074388966],
              [-82.076694515119783, 35.848782626000691],
              [-82.076743438754775, 35.848517568048393],
              [-82.074754214031827, 35.84828286825325],
              [-82.074422676952096, 35.848243748181986],
              [-82.074324812292033, 35.848773863187212],
              [-82.073661737054181, 35.848695619765103],
              [-82.073612801026087, 35.848960677421076],
              [-82.073281262824949, 35.848921554227488],
              [-82.073232326484558, 35.849186610781096],
              [-82.072900786434346, 35.849147486583995],
              [-82.072851848709249, 35.849412542963968],
              [-82.072520307950683, 35.849373418636304],
              [-82.072471368840965, 35.849638474842735],
              [-82.07213982730552, 35.849599348582721],
              [-82.072041944889477, 35.85012946063199],
              [-82.071710402654261, 35.850090333183147],
              [-82.071661459347808, 35.850355389026028],
              [-82.070998372606709, 35.850277131191291],
              [-82.070949426851953, 35.85054218673109],
              [-82.067633999526592, 35.850150841296013],
              [-82.067585042814571, 35.850415895496816],
              [-82.066921958418348, 35.850337615523465],
              [-82.066872999257981, 35.850602669421193],
              [-82.066541456443716, 35.85056352705103],
              [-82.066492495898601, 35.850828580775051],
              [-82.066160952376165, 35.850789438274326],
              [-82.066111989339731, 35.851054491852395],
              [-82.065780446147102, 35.851015347391439],
              [-82.065731481725891, 35.851280400795822],
              [-82.065068391998082, 35.851202109865405],
              [-82.06501942623504, 35.851467162938746],
              [-82.064687880806318, 35.851428016441396],
              [-82.064638912517722, 35.851693068467924],
              [-82.063975820496722, 35.851614772508128],
              [-82.063926849759682, 35.851879824231482],
              [-82.062932210912194, 35.85176237223714],
              [-82.062883236663183, 35.852027423527566],
              [-82.062551689970888, 35.851988271229587],
              [-82.062600666356033, 35.851723219139885],
              [-82.062269121048587, 35.85168406606936],
              [-82.061937576028342, 35.851644911195883],
              [-82.061986553112874, 35.851379859348931],
              [-82.061655010618196, 35.851340704576117],
              [-82.061606031329234, 35.851605755420408],
              [-82.060279856920715, 35.851449125072435],
              [-82.060328840429705, 35.851184073845396],
              [-82.059665757799422, 35.851105752603715],
              [-82.059334216447098, 35.851066591544566],
              [-82.059285229713154, 35.851331641481998],
              [-82.058290606538108, 35.851214150646662],
              [-82.058241616292122, 35.851479200151083],
              [-82.057910074063841, 35.851440034323943],
              [-82.057861082467056, 35.851705084555526],
              [-82.057529539496443, 35.851665917696806],
              [-82.057480546514725, 35.851930967754484],
              [-82.05681745941051, 35.851852631071836],
              [-82.056768463946185, 35.852117679925222],
              [-82.056436919812512, 35.852078510101357],
              [-82.056387922963225, 35.852343558780923],
              [-82.055724833532949, 35.852265216167815],
              [-82.055675834269209, 35.85253026544482],
              [-82.054349656083716, 35.852373567947566],
              [-82.054398659635936, 35.852108520089878],
              [-82.053404033937184, 35.851990987624795],
              [-82.053453041465119, 35.851725940083881],
              [-82.053121500511523, 35.851686760339021],
              [-82.053072491954069, 35.851951808651201],
              [-82.052409410024865, 35.851873447069437],
              [-82.052360399018852, 35.852138495078016],
              [-82.052028856902638, 35.852099312367926],
              [-82.051979844511592, 35.852364360202699],
              [-82.051316759150453, 35.852285992718137],
              [-82.051267744276785, 35.852551039348405],
              [-82.05093620103186, 35.85251185457394],
              [-82.050887184773146, 35.852776901030367],
              [-82.049892554849492, 35.852659339977464],
              [-82.049843533971995, 35.85292438602827],
              [-82.04884890371757, 35.852806815567519],
              [-82.048799879327873, 35.853071861185093],
              [-82.048468335143014, 35.853032669707758],
              [-82.048419309368199, 35.853297715151392],
              [-82.047756220908809, 35.853219328302629],
              [-82.047707192685309, 35.85348437344264],
              [-82.047375647304193, 35.853445178099051],
              [-82.047326617695546, 35.853710223065079],
              [-82.046995071606347, 35.85367102759065],
              [-82.046946039505926, 35.853936072410363],
              [-82.046614493747413, 35.853896874975689],
              [-82.046565460261817, 35.854161919621475],
              [-82.04651642756123, 35.854426964195241],
              [-82.046184878890671, 35.854387765626839],
              [-82.046135843698266, 35.854652810054318],
              [-82.045804294285674, 35.854613610454173],
              [-82.04575525881468, 35.854878654679965],
              [-82.045423708660252, 35.854839454048197],
              [-82.045325632412784, 35.855369542162663],
              [-82.04466252881447, 35.855291137674207],
              [-82.04461348808114, 35.855556181405568],
              [-82.044281935700937, 35.855516977678661],
              [-82.044232893582318, 35.855782021235896],
              [-82.04356978765972, 35.855703610816704],
              [-82.043520743092131, 35.855968654070189],
              [-82.043189189549835, 35.855929447377825],
              [-82.043091096215889, 35.856459533519946],
              [-82.042759540868119, 35.85642032566615],
              [-82.04271049317434, 35.856685367626191],
              [-82.042378935977951, 35.856646158768271],
              [-82.042329886932777, 35.856911201455112],
              [-82.041666772484689, 35.856832780746217],
              [-82.041617720956651, 35.857097822228333],
              [-82.04128616259834, 35.857058610404877],
              [-82.04123710971875, 35.857323652613793],
              [-82.040905550584682, 35.857284438857697],
              [-82.04080744062648, 35.857814522910537],
              [-82.040475880793622, 35.857775307965333],
              [-82.040426823714796, 35.858040349809251],
              [-82.040095262067325, 35.858001134760777],
              [-82.04004620467596, 35.858266175501988],
              [-82.039714642286555, 35.858226959421749],
              [-82.039665582403074, 35.858492000016369],
              [-82.039334019271948, 35.858452782904344],
              [-82.039284958003009, 35.858717823324888],
              [-82.037295581738135, 35.858482499074455],
              [-82.037246513765595, 35.858747538672056],
              [-82.036914950759979, 35.858708314986174],
              [-82.036816810649228, 35.859238394717025],
              [-82.036485246911141, 35.859199168941167],
              [-82.036436174738938, 35.859464208173421],
              [-82.036104609186282, 35.859424982294215],
              [-82.03590831523772, 35.860485138204375],
              [-82.035576745752252, 35.860445910904076],
              [-82.035527669843319, 35.860710949654482],
              [-82.035196099582365, 35.860671720421436],
              [-82.035147022287845, 35.860936758997525],
              [-82.034815451318892, 35.860897529633547],
              [-82.034668215419444, 35.86169264467798],
              [-82.034336640440927, 35.861653413149241],
              [-82.034287559731297, 35.861918451287742],
              [-82.033955985117672, 35.861879218699642],
              [-82.033514233494628, 35.864264557000119],
              [-82.033182648522782, 35.864225322369386],
              [-82.033035388211886, 35.865020432761895],
              [-82.032703800404605, 35.864981197740612],
              [-82.032605623841661, 35.865511270234094],
              [-82.032274033122093, 35.865472034078721],
              [-82.032224943293812, 35.86573707012878],
              [-82.031893352905541, 35.86569783201297],
              [-82.031844260618485, 35.86596286881705],
              [-82.031512669488393, 35.865923629669354],
              [-82.031414482859716, 35.866453701054802],
              [-82.031082888851216, 35.866414461673777],
              [-82.031033794543404, 35.866679497155985],
              [-82.030702199759276, 35.866640255842235],
              [-82.030653102958894, 35.866905291177488],
              [-82.030321508539856, 35.866866048804205],
              [-82.030223311878842, 35.867396119982196],
              [-82.029891715654372, 35.867356876447168],
              [-82.029842615759932, 35.867621911388916],
              [-82.029511017686943, 35.86758266684943],
              [-82.0293637128798, 35.868377771017272],
              [-82.029695314144263, 35.868417015946171],
              [-82.029548009629593, 35.869212120101402],
              [-82.029879613300963, 35.869251364545129],
              [-82.029585001889942, 35.870841570625849],
              [-82.029339482403984, 35.872166740857097],
              [-82.029007865922978, 35.872127495012954],
              [-82.028860546737818, 35.872922596416409],
              [-82.028528926280373, 35.872883349308111],
              [-82.02838160097005, 35.873678449018257],
              [-82.028713224619366, 35.873717696515975],
              [-82.02861500787138, 35.874247762433697],
              [-82.028946633970918, 35.874287009288786],
              [-82.028701092369815, 35.875612174835062],
              [-82.028369459843447, 35.875572927358327],
              [-82.02822212802559, 35.876368025212663],
              [-82.027890492629354, 35.876328776444304],
              [-82.027792267309351, 35.876858841205824],
              [-82.027460630107143, 35.876819591275535],
              [-82.02726417023726, 35.877879719768309],
              [-82.027595811695619, 35.877918970217891],
              [-82.027497582475092, 35.878449033539944],
              [-82.027829226383915, 35.878488283346918],
              [-82.027780111817805, 35.878753315534695],
              [-82.028443402730275, 35.878831812701463],
              [-82.028394291043313, 35.879096844175507],
              [-82.028725938046946, 35.87913609153528],
              [-82.028676827135655, 35.879401123995116],
              [-82.028345177960915, 35.879361876533018],
              [-82.028296065629519, 35.879626907917299],
              [-82.027632767259192, 35.879548410900028],
              [-82.027583651370321, 35.879813442007169],
              [-82.027252002141054, 35.879774191551114],
              [-82.027202884899481, 35.880039223384422],
              [-82.026871233821367, 35.879999971923738],
              [-82.026822115159746, 35.880265002681455],
              [-82.026490464446752, 35.880225750161202],
              [-82.026441344432385, 35.880490781645051],
              [-82.026109691870573, 35.88045152812014],
              [-82.026060570436115, 35.880716558528256],
              [-82.025728918239437, 35.880677303943777],
              [-82.025434174333014, 35.882267486526104],
              [-82.025102516073659, 35.882228230260324],
              [-82.024955135435874, 35.883023319681314],
              [-82.024623473199355, 35.882984062151181],
              [-82.024574345349251, 35.883249091720074],
              [-82.024242682370797, 35.883209833157792],
              [-82.024193552027128, 35.883474862579234],
              [-82.023861888306925, 35.883435602984775],
              [-82.023763625631119, 35.883965661405504],
              [-82.023431960104887, 35.883926400649059],
              [-82.023333692904117, 35.884456458657425],
              [-82.023002025571742, 35.884417196738937],
              [-82.02290375491927, 35.884947253406544],
              [-82.022572085780951, 35.884907990326028],
              [-82.022522948923751, 35.885173018912894],
              [-82.021196269089671, 35.885015956202075],
              [-82.021147127619429, 35.885280983323462],
              [-82.019820448687568, 35.885123905655945],
              [-82.019771301530852, 35.885388932240176],
              [-82.018776293070374, 35.885271113886446],
              [-82.018727142432212, 35.885536140936928],
              [-82.017732133647769, 35.885418313171698],
              [-82.017682979460901, 35.88568333888643],
              [-82.015029628022276, 35.885369091080484],
              [-82.014980463892201, 35.885634115738313],
              [-82.014317127934845, 35.885555543807541],
              [-82.014366293086226, 35.885290519437014],
              [-82.013371293655197, 35.885172655190651],
              [-82.013420462749949, 35.88490763023659],
              [-82.013088798351276, 35.884868340470653],
              [-82.013137967114119, 35.884603316529684],
              [-82.012474641413121, 35.884524734550816],
              [-82.012523813054997, 35.884259709896362],
              [-82.010865509735538, 35.884063238907913],
              [-82.010914686375514, 35.883798214858167],
              [-82.010251370555096, 35.883719620739761],
              [-82.010300549000561, 35.88345459690494],
              [-82.008973924347501, 35.883297397486906],
              [-82.009023106726772, 35.883032374126948],
              [-82.008028146192785, 35.882914465220111],
              [-82.008077331441683, 35.882649442205043],
              [-82.007414027780484, 35.8825708317246],
              [-82.007463215975264, 35.882305809797998],
              [-82.006799915733239, 35.882227195968611],
              [-82.006849105699871, 35.881962173356051],
              [-82.006517457663648, 35.881922864753811],
              [-82.00656664837156, 35.881657842226318],
              [-82.006235000648644, 35.881618533780056],
              [-82.006284192097851, 35.881353511337515],
              [-82.00595254683526, 35.881314201190811],
              [-82.006001739025734, 35.881049178833216],
              [-82.005670095183447, 35.881009868815241],
              [-82.005719288081664, 35.880744845641821],
              [-82.005056003459714, 35.880666222258185],
              [-82.005105198196688, 35.880401200200602],
              [-82.00444191699377, 35.880322573468199],
              [-82.004491114642946, 35.880057551598355],
              [-82.004159475572891, 35.880018236558357],
              [-82.004257869816783, 35.879488192998394],
              [-82.00392623430443, 35.879448877289036],
              [-82.003975432006229, 35.879183855571632],
              [-82.003643796773517, 35.879144539117284],
              [-82.003692996323437, 35.878879517457605],
              [-82.003361362477563, 35.87884020023106],
              [-82.003459761596375, 35.87831015616338],
              [-82.003128130201134, 35.878270838294668],
              [-82.003226530190247, 35.877740795208126],
              [-82.002894902352566, 35.877701476670097],
              [-82.003140901375588, 35.876376367029124],
              [-82.003472522753484, 35.876415684043309],
              [-82.003570918190277, 35.875885639426713],
              [-82.003239297799652, 35.87584632179896],
              [-82.003288495561051, 35.875581300017451],
              [-82.002625258982832, 35.875502662288312],
              [-82.002674458515642, 35.875237639821087],
              [-82.001347993521364, 35.875080354958108],
              [-82.001397198093628, 35.874815332938887],
              [-82.000402356457116, 35.874697359519935],
              [-82.000451562791071, 35.874432337873337],
              [-81.99846189569044, 35.874196366530029],
              [-81.998412681866824, 35.874461387423409],
              [-81.997417848913528, 35.874343388745906],
              [-81.997467065928475, 35.874078368242834],
              [-81.996803848461752, 35.873999698789795],
              [-81.996754629285846, 35.874264718131762],
              [-81.996423019973093, 35.874225381921974],
              [-81.996373798337046, 35.874490402017095],
              [-81.99604218824993, 35.874451063874218],
              [-81.995992965227188, 35.874716083794311],
              [-81.995661354399303, 35.87467674461913],
              [-81.995612129989865, 35.874941764364287],
              [-81.995280518454464, 35.874902425057769],
              [-81.995231292624993, 35.875167443726916],
              [-81.994899680348766, 35.875128103388171],
              [-81.994850453132486, 35.875393121882354],
              [-81.994518840115404, 35.875353780511354],
              [-81.994469611512486, 35.875618798830438],
              [-81.993806383179418, 35.875540112248132],
              [-81.993757152159262, 35.875805131162927],
              [-81.992762309427334, 35.875687093668944],
              [-81.992713074859566, 35.875952111247528],
              [-81.992049845501214, 35.875873415123273],
              [-81.991951370067753, 35.876403450553106],
              [-81.991619754282382, 35.876364100413497],
              [-81.991570514447176, 35.876629117493991],
              [-81.991238897920923, 35.876589766322034],
              [-81.990992690860949, 35.877914852146866],
              [-81.990661068231844, 35.877875499449175],
              [-81.990267110190899, 35.879995630537742],
              [-81.989935479374097, 35.879956275896653],
              [-81.989836983951875, 35.88048630797185],
              [-81.989173717924984, 35.880407595489636],
              [-81.9891244676014, 35.880672611199323],
              [-81.988792833455193, 35.880633253488419],
              [-81.9887435817446, 35.880898269022879],
              [-81.988080313400914, 35.880819550606972],
              [-81.988031059239404, 35.881084565836048],
              [-81.987699425041939, 35.881045205131038],
              [-81.987650168353056, 35.881310219311089],
              [-81.986655263400849, 35.881192131419617],
              [-81.986704522174364, 35.880927117657151],
              [-81.986372889236151, 35.880887752442575],
              [-81.986274368197897, 35.881417781490825],
              [-81.985942732381417, 35.881378416041727],
              [-81.985844207889215, 35.881908443748472],
              [-81.985512570268241, 35.881869077136713],
              [-81.985463305903266, 35.882134090355308],
              [-81.985131668648634, 35.882094722684052],
              [-81.985082402929862, 35.882359736628295],
              [-81.984750763827421, 35.88232036795182],
              [-81.984701496688217, 35.882585380819854],
              [-81.984369856845191, 35.882546011110918],
              [-81.984271319502568, 35.883076037352211],
              [-81.983939677821681, 35.883036665579759],
              [-81.983890407618205, 35.883301678952954],
              [-81.983558765229859, 35.883262307048994],
              [-81.983509493605922, 35.883527319346015],
              [-81.983177850477006, 35.88348794640963],
              [-81.983128577465891, 35.883752958531232],
              [-81.982796933596319, 35.883713584562443],
              [-81.982747659197997, 35.88397859650879],
              [-81.982416014587699, 35.883939221507553],
              [-81.982366737695187, 35.884204233305532],
              [-81.982035092311008, 35.884164856371065],
              [-81.981985815171484, 35.884429868867493],
              [-81.981654167939595, 35.884390490927572],
              [-81.981604889412921, 35.884655503248645],
              [-81.980941596038733, 35.88457674524853],
              [-81.98089231392045, 35.884841756390138],
              [-81.98022901860466, 35.884762994547764],
              [-81.980081165033212, 35.885558026893221],
              [-81.980412815184309, 35.885597409120166],
              [-81.980215676415568, 35.886657451243948],
              [-81.980547331145928, 35.886696833089708],
              [-81.980448761412376, 35.887226854604741],
              [-81.980780419667909, 35.88726623488072],
              [-81.980632565834483, 35.88806126720489],
              [-81.98096422760544, 35.888100646969349],
              [-81.980767089963649, 35.889160689027911],
              [-81.98109875631414, 35.889200068410979],
              [-81.980803045931125, 35.890790129584367],
              [-81.980471372088942, 35.890750749447022],
              [-81.979929191223917, 35.8936658537542],
              [-81.98026087677151, 35.893705235324049],
              [-81.97957078348287, 35.897415356794724],
              [-81.979902485360299, 35.897454739258336],
              [-81.979853191941984, 35.897719747845343],
              [-81.980184895207074, 35.897759129536794],
              [-81.979889131976009, 35.899349178237088],
              [-81.97955742232503, 35.899309795764395],
              [-81.979113743766675, 35.901694862374072],
              [-81.979445464137228, 35.901734246892673],
              [-81.97934686758046, 35.90226426105113],
              [-81.979678590370057, 35.902303644026802],
              [-81.979579994648134, 35.902833658263546],
              [-81.979911719923308, 35.902873041498111],
              [-81.979813125036173, 35.903403055812994],
              [-81.980144853837771, 35.903442437477644],
              [-81.980046259785368, 35.903972451870644],
              [-81.980377991039461, 35.904011832893296],
              [-81.980279397821917, 35.9045418473643],
              [-81.980611130420996, 35.904581227771949],
              [-81.980463241882291, 35.90537624861274],
              [-81.980794979138906, 35.905415629382453],
              [-81.980647090881988, 35.906210650203327],
              [-81.980978831622352, 35.906250029560191],
              [-81.980880239582419, 35.906780043181691],
              [-81.981211983882702, 35.906819421869386],
              [-81.981113392677784, 35.907349435568804],
              [-81.981445138356548, 35.907388814542372],
              [-81.981395843870374, 35.907653820989758],
              [-81.981727592011396, 35.907693198263061],
              [-81.981530412941609, 35.908753225972816],
              [-81.981862165664353, 35.908792602864366],
              [-81.981714281696028, 35.909587623093557],
              [-81.982046037936087, 35.909626999473147],
              [-81.981947448744123, 35.910157012399488],
              [-81.982279208577722, 35.910196389010828],
              [-81.982180620220944, 35.910726402014703],
              [-81.982512381366547, 35.910765777109944],
              [-81.982463088321239, 35.911030783660024],
              [-81.982131326110988, 35.910991408434612],
              [-81.982082030570098, 35.911256414835748],
              [-81.982413794952421, 35.911295790164289],
              [-81.982364500152741, 35.911560796649965],
              [-81.98170097068963, 35.911482044802902],
              [-81.981651674544437, 35.911747050955306],
              [-81.981319908679836, 35.911707673561359],
              [-81.981270611146442, 35.911972679537811],
              [-81.980607079332657, 35.911893920853736],
              [-81.980557778238932, 35.912158926551129],
              [-81.979894245623441, 35.912080164897354],
              [-81.979844942076795, 35.912345170288596],
              [-81.979513174602786, 35.912305787090325],
              [-81.979463869667882, 35.912570792305594],
              [-81.979132102593383, 35.91253140894834],
              [-81.979033489623447, 35.913061418981165],
              [-81.978701719602597, 35.913022033587048],
              [-81.978455175050414, 35.914347056342855],
              [-81.978123400062856, 35.914307670296012],
              [-81.978074089311704, 35.914572673853357],
              [-81.977742313583164, 35.914533286773789],
              [-81.977693001443654, 35.914798290155126],
              [-81.977361224974047, 35.914758902042678],
              [-81.977311910338685, 35.915023905275049],
              [-81.976648356240915, 35.914945126081932],
              [-81.976599039152433, 35.915210129007953],
              [-81.976267261524399, 35.915170737927362],
              [-81.976217943047615, 35.915435740677303],
              [-81.974890830339405, 35.915278165033065],
              [-81.974841508387414, 35.91554316718922],
              [-81.973846173767726, 35.915424975144418],
              [-81.973895500021328, 35.915159973351969],
              [-81.972568396344471, 35.915002371534406],
              [-81.97251906583206, 35.915267372805786],
              [-81.971855514377125, 35.915188566657989],
              [-81.971806180304057, 35.915453567649948],
              [-81.971474403981034, 35.915414162641412],
              [-81.97137573387478, 35.915944165101195],
              [-81.971043955746154, 35.915904758929535],
              [-81.970994618572291, 35.916169759523413],
              [-81.970331061157069, 35.916090944211781],
              [-81.970281721563111, 35.916355945400056],
              [-81.969949942276443, 35.916316536260162],
              [-81.969752576303989, 35.91737653733059],
              [-81.969420793082378, 35.917337126766931],
              [-81.969371449706586, 35.917602126564269],
              [-81.969039664636568, 35.917562714994617],
              [-81.968990319872191, 35.917827714615797],
              [-81.968658535168728, 35.917788301986228],
              [-81.96851049463065, 35.918583300210265],
              [-81.968178705949398, 35.918543886314204],
              [-81.968080009246648, 35.91907388462198],
              [-81.967748218726641, 35.919034468661813],
              [-81.967698868824655, 35.919299467616533],
              [-81.966039915891926, 35.919102377228526],
              [-81.965990560342178, 35.91936737548572],
              [-81.964995189262382, 35.919249109670233],
              [-81.96469902442395, 35.920839095135747],
              [-81.964035431428158, 35.920760244908351],
              [-81.963986067179107, 35.921025242218988],
              [-81.963322474457243, 35.920946388093498],
              [-81.963273106647023, 35.921211385124352],
              [-81.96194592079955, 35.9210536646195],
              [-81.961896548406216, 35.921318661082985],
              [-81.960569362409927, 35.921160926542456],
              [-81.960519985400254, 35.921425921537583],
              [-81.960188188940066, 35.921386485959268],
              [-81.960089432926438, 35.921916475523759],
              [-81.95975763355321, 35.921877038808937],
              [-81.959658873039004, 35.922407028856334],
              [-81.95932707293484, 35.922367590050378],
              [-81.959228308994653, 35.922897579652854],
              [-81.958896505977322, 35.92285813971035],
              [-81.958797738577942, 35.923388127966973],
              [-81.958465934895742, 35.923348687735121],
              [-81.958367162962503, 35.923878675573569],
              [-81.958035356334207, 35.92383923330425],
              [-81.957936582115281, 35.924369221571659],
              [-81.957604773681467, 35.924329778138919],
              [-81.957555383896732, 35.924594771649538],
              [-81.957223575829957, 35.924555327156916],
              [-81.957124794265951, 35.925085313752149],
              [-81.956792983285965, 35.92504586812295],
              [-81.956694197187801, 35.925575854299794],
              [-81.956362384402311, 35.925536407507145],
              [-81.956312990909723, 35.925801400369203],
              [-81.955981177383578, 35.925761952543454],
              [-81.955882385080628, 35.926291937921967],
              [-81.955550569749064, 35.926252488932782],
              [-81.955501172046297, 35.926517481422387],
              [-81.955169355974007, 35.926478031400173],
              [-81.9551199568822, 35.926743023713264],
              [-81.954788138961632, 35.926703572684708],
              [-81.954738738480586, 35.926968564821287],
              [-81.954406920927141, 35.92692911273285],
              [-81.954357519056941, 35.927194104692781],
              [-81.954689337675376, 35.927233556911631],
              [-81.954442328718855, 35.92855851486916],
              [-81.954774154093698, 35.928597966810557],
              [-81.954724752407657, 35.928862958754252],
              [-81.955056578064173, 35.92890240995024],
              [-81.954957775816155, 35.929432393058917],
              [-81.954625946922164, 35.929392941629011],
              [-81.954477737906643, 35.930187915103936],
              [-81.954809569995632, 35.930227366925045],
              [-81.954760167428816, 35.930492358721864],
              [-81.954513148490804, 35.931817313435729],
              [-81.954844988401703, 35.931856765109629],
              [-81.954746180041525, 35.932386747488799],
              [-81.95507802240688, 35.932426198520872],
              [-81.955028619342741, 35.932691189307526],
              [-81.955692306068528, 35.93277008895096],
              [-81.955642904810276, 35.933035079952219],
              [-81.956306596071101, 35.933113976218507],
              [-81.956257196618679, 35.933378967434258],
              [-81.95692089130678, 35.933457860350231],
              [-81.956871493660145, 35.933722851780459],
              [-81.957535191742593, 35.93380174044529],
              [-81.95748579700961, 35.934066732063059],
              [-81.957817647618882, 35.934106175622134],
              [-81.957718857094861, 35.934636159033225],
              [-81.958050711266267, 35.934675601923345],
              [-81.95800131658298, 35.934940593689824],
              [-81.95833317100309, 35.934980034933552],
              [-81.958283778168436, 35.935245026757144],
              [-81.959279347743262, 35.935363347237541],
              [-81.959229957779797, 35.935628339405895],
              [-81.959893674972392, 35.9357072160645],
              [-81.959844286782058, 35.935972207546321],
              [-81.960176146375986, 35.936011644665193],
              [-81.960126758959561, 35.936276637132003],
              [-81.960458621017594, 35.93631607255066],
              [-81.960409233234444, 35.936581065128266],
              [-81.960741096714941, 35.936620500675325],
              [-81.960642324488703, 35.937150484997616],
              [-81.960974190423798, 35.937189919902451],
              [-81.960924804906099, 35.937454912574871],
              [-81.961920407786266, 35.937573210461778],
              [-81.961871025140113, 35.937838203478819],
              [-81.962202894469414, 35.937877634132171],
              [-81.9621535125642, 35.938142627233113],
              [-81.962817255433635, 35.938221486065039],
              [-81.962767875334833, 35.938486479380309],
              [-81.963431620556747, 35.938565335789214],
              [-81.96328348574832, 35.939360314409811],
              [-81.963615363165701, 35.939399742074436],
              [-81.963516606408973, 35.93992972816833],
              [-81.963184726860916, 35.939890300243029],
              [-81.962493379347961, 35.943600193145038],
              [-81.962161484134512, 35.943560763420201],
              [-81.962062712708743, 35.944090746605276],
              [-81.962394610085965, 35.944130177491644],
              [-81.962345224952088, 35.944395169144698],
              [-81.962677122578086, 35.944434598384476],
              [-81.962627738185375, 35.944699590121395],
              [-81.962959638308831, 35.944739018561648],
              [-81.962910254657302, 35.945004010382299],
              [-81.963242156170409, 35.945043438049929],
              [-81.963192773260189, 35.945308429954338],
              [-81.963524676162962, 35.945347856849352],
              [-81.963376528683099, 35.946142832674461],
              [-81.963708435106454, 35.946182259057416],
              [-81.963412137752201, 35.947772209331355],
              [-81.963080224902853, 35.947732781265742],
              [-81.962981453296223, 35.948262764384353],
              [-81.962649538640107, 35.948223335155106],
              [-81.962600151838856, 35.948488326500708],
              [-81.962268235366636, 35.948448897166053],
              [-81.962120068646684, 35.949243868757499],
              [-81.961788149302038, 35.949204438128824],
              [-81.961639976466913, 35.949999408909804],
              [-81.961308054250026, 35.949959976987181],
              [-81.961209268215882, 35.950489956422274],
              [-81.960877343084221, 35.950450523362989],
              [-81.960778553654734, 35.950980503251792],
              [-81.961110480917554, 35.951019936571718],
              [-81.960715316436207, 35.953139852653543],
              [-81.960383380648523, 35.953100418291001],
              [-81.960086986659206, 35.954690351045421],
              [-81.960418929981884, 35.954729787063975],
              [-81.959924922782761, 35.957379670493602],
              [-81.959592969944097, 35.957340234045901],
              [-81.959444757127002, 35.958135197077446],
              [-81.959776714304027, 35.958174634790126],
              [-81.959480287413186, 35.959764560343928],
              [-81.959148323874501, 35.959725122750207],
              [-81.958950694815115, 35.960785071369649],
              [-81.958618726196477, 35.960745631477486],
              [-81.958569317323438, 35.961010618152926],
              [-81.957241444112412, 35.96085285077978],
              [-81.957192029543492, 35.96111783691385],
              [-81.955864157261004, 35.960960054569455],
              [-81.955814738104394, 35.961225040135183],
              [-81.955482770316721, 35.961185591697038],
              [-81.955433349769763, 35.961450577085586],
              [-81.9551013801658, 35.961411128541691],
              [-81.955002535966443, 35.961941098917222],
              [-81.955334507702091, 35.961980547721822],
              [-81.955285086181433, 35.962245532969526],
              [-81.954953113379958, 35.962206084034648],
              [-81.954854267200318, 35.962736053227765],
              [-81.95452229259179, 35.962696603128876],
              [-81.954472867965777, 35.962961587975144],
              [-81.954140892582785, 35.962922135941952],
              [-81.954091466566453, 35.963187120610925],
              [-81.95399261241873, 35.963717088933841],
              [-81.954324590999221, 35.963756541358123],
              [-81.954275165075018, 35.964021526016509],
              [-81.954607145012972, 35.964060976767108],
              [-81.954557719830007, 35.964325961508905],
              [-81.954889701191419, 35.964365412387579],
              [-81.954840276749849, 35.964630397212815],
              [-81.955172259468739, 35.964669846417813],
              [-81.955073411743541, 35.965199816187983],
              [-81.95540539691882, 35.96523926475043],
              [-81.955355973635378, 35.9655042496954],
              [-81.955687961309323, 35.965543697458315],
              [-81.955589114792261, 35.966073667494712],
              [-81.955257126061625, 35.966034218543399],
              [-81.955158276081448, 35.966564187230183],
              [-81.95482628554339, 35.966524737114995],
              [-81.95477685846285, 35.966789721721398],
              [-81.95444486607532, 35.966750270599498],
              [-81.954346010991983, 35.967280238455885],
              [-81.954014016797146, 35.96724078617008],
              [-81.953964586610837, 35.96750577037465],
              [-81.953632591674634, 35.967466317055312],
              [-81.953385432628423, 35.968791233063207],
              [-81.953717434002783, 35.968830687007575],
              [-81.953668001835737, 35.969095670028914],
              [-81.954000004600772, 35.969135123200424],
              [-81.953950573175206, 35.969400106304974],
              [-81.954282576222653, 35.969439558730414],
              [-81.954233146646629, 35.969704541891495],
              [-81.954565151084736, 35.969743993544064],
              [-81.954515722250108, 35.970008976788314],
              [-81.954847729154224, 35.970048426740249],
              [-81.954748871567986, 35.970578394276025],
              [-81.955080880928733, 35.970617843585401],
              [-81.955031452144112, 35.970882826975952],
              [-81.955363462928545, 35.970922276413383],
              [-81.955314035993737, 35.971187259860251],
              [-81.955646048135861, 35.97122670762392],
              [-81.955547194316139, 35.971756674663858],
              [-81.955879208948161, 35.971796122685831],
              [-81.955780357070481, 35.972326089771087],
              [-81.956776409244526, 35.97244442739769],
              [-81.956825832147331, 35.97217944348759],
              [-81.957157849143755, 35.972218888026866],
              [-81.957207270655857, 35.971953903939315],
              [-81.957539287985995, 35.97199334651728],
              [-81.957588706999118, 35.971728362279194],
              [-81.957920723620745, 35.971767804724436],
              [-81.957970141243194, 35.971502820308828],
              [-81.958634172186606, 35.971581701354893],
              [-81.95858475558812, 35.971846686057901],
              [-81.958916772042201, 35.971886124919308],
              [-81.958867356185294, 35.972151109705578],
              [-81.959199375138212, 35.972190547767113],
              [-81.959149960022913, 35.972455532636552],
              [-81.959481979291283, 35.972494970852949],
              [-81.959432565992813, 35.972759954877795],
              [-81.960096607602523, 35.972838827960388],
              [-81.96004719614487, 35.973103813099506],
              [-81.960379218486096, 35.973143247965773],
              [-81.960329807770108, 35.973408233188096],
              [-81.960661832610398, 35.973447667254504],
              [-81.960414776135721, 35.974772592489792],
              [-81.960082745964442, 35.974733157771865],
              [-81.960033333268314, 35.974998141810282],
              [-81.958705211531054, 35.974840392483777],
              [-81.958655794278485, 35.975105376854629],
              [-81.95799173320836, 35.975026496060451],
              [-81.9579423123905, 35.97529148015041],
              [-81.957610281292617, 35.975252038718487],
              [-81.957560859050929, 35.975517021730049],
              [-81.957228827211438, 35.975477579264521],
              [-81.957031129691529, 35.976537512145782],
              [-81.956699093878726, 35.976498067354775],
              [-81.956649667914533, 35.976763050770451],
              [-81.956317631360122, 35.976723604945796],
              [-81.956268202896723, 35.976988588210773],
              [-81.955936165600704, 35.976949141352392],
              [-81.955886736854765, 35.977214124412953],
              [-81.95555469770882, 35.97717467654784],
              [-81.955505267571951, 35.977439659430807],
              [-81.95517322768444, 35.977400210532011],
              [-81.955074363162936, 35.977930175021385],
              [-81.955406405149972, 35.977969623279982],
              [-81.955208675334532, 35.979029551338733],
              [-81.955540721944374, 35.979069000116176],
              [-81.955392424624819, 35.979863945372351],
              [-81.955724474758341, 35.979903393637336],
              [-81.955625610757323, 35.980433357156599],
              [-81.955957663315104, 35.980472803878065],
              [-81.955858800148121, 35.981002767468681],
              [-81.956190856304673, 35.981042214421485],
              [-81.956141424746264, 35.981307196289578],
              [-81.955809368631762, 35.981267749179601],
              [-81.955759935682323, 35.981532730869951],
              [-81.956091993971583, 35.981572178083397],
              [-81.95584483186984, 35.98289708552209],
              [-81.955512768248568, 35.98285763765702],
              [-81.955413899237072, 35.983387600395211],
              [-81.956078029928349, 35.983466494869205],
              [-81.956028597622549, 35.98373147641442],
              [-81.956360665059364, 35.983770921962744],
              [-81.956162933496657, 35.984830847370638],
              [-81.955830861761072, 35.984791400400212],
              [-81.955633120704135, 35.985851323627266],
              [-81.955301045060409, 35.985811876132857],
              [-81.955202169896339, 35.986341837214617],
              [-81.954870092411483, 35.986302387655357],
              [-81.954820653292344, 35.986567368445165],
              [-81.953824418511104, 35.986449014883426],
              [-81.953774974726187, 35.986713994360713],
              [-81.952778739668489, 35.98659563227703],
              [-81.952729293500866, 35.986860612189922],
              [-81.952397213981428, 35.986821155942373],
              [-81.952347766389622, 35.987086134776469],
              [-81.952015686161488, 35.98704667839602],
              [-81.951966237178297, 35.987311657052366],
              [-81.951634156175416, 35.987272198737287],
              [-81.951535254026155, 35.987802156574404],
              [-81.951203171215198, 35.987762697095206],
              [-81.951153719124534, 35.988027675348327],
              [-81.950821635571913, 35.987988214835241],
              [-81.950673271967332, 35.988783149873079],
              [-81.951337444655024, 35.98886206990386],
              [-81.951287992331572, 35.989127048097281],
              [-81.951620080799884, 35.98916650732486],
              [-81.951224452398264, 35.991286332364695],
              [-81.951556550799623, 35.99132579080397],
              [-81.951061996105096, 35.99397556733711],
              [-81.950729887004442, 35.993936106693475],
              [-81.95068042843333, 35.994201083239176],
              [-81.951012538600651, 35.994240544013159],
              [-81.950963080804357, 35.994505521542521],
              [-81.951627305322646, 35.994584439712895],
              [-81.951577849334726, 35.994849417455221],
              [-81.952242077285973, 35.994928332272067],
              [-81.9521926230736, 35.995193309326645],
              [-81.952856854490747, 35.995272221690946],
              [-81.952807403195592, 35.995537198931643],
              [-81.9531395199039, 35.995576653902212],
              [-81.953040617396624, 35.99610660942912],
              [-81.95270849855487, 35.996067154197824],
              [-81.952560138586506, 35.9968620863025],
              [-81.952228015760809, 35.996822629803553],
              [-81.952129104629122, 35.997352584359049],
              [-81.951796979994796, 35.997313126695914],
              [-81.951698066538682, 35.997843080773094],
              [-81.952030193273572, 35.997882537796109],
              [-81.951931279542663, 35.998412491969916],
              [-81.952927669272071, 35.99853086017432],
              [-81.952878215103254, 35.998795837130245],
              [-81.953210347446756, 35.998835290903088],
              [-81.95316089401976, 35.999100267941529],
              [-81.953825160739427, 35.999179173964777],
              [-81.95377570912126, 35.999444151216089],
              [-81.954107844605986, 35.999483603439693],
              [-81.954058393729795, 35.999748580773677],
              [-81.954390530573306, 35.999788031323064],
              [-81.954341081547639, 36.000053008712705],
              [-81.954673218707242, 36.000092459416521],
              [-81.954623770423552, 36.000357436888741],
              [-81.954955910050529, 36.000396885891512],
              [-81.95490646140027, 36.000661863473155],
              [-81.9552386024519, 36.000701312603525],
              [-81.955139708527781, 36.001231267830391],
              [-81.954807565342307, 36.001191818439437],
              [-81.95475811571734, 36.001456795877765],
              [-81.954425971822857, 36.001417346353747],
              [-81.954376521914881, 36.001682323587097],
              [-81.953712232933768, 36.001603420666655],
              [-81.953662779425258, 36.001868396717619],
              [-81.953330634371753, 36.001828944222133],
              [-81.953182269871022, 36.002623873471997],
              [-81.953514418158349, 36.00266332725937],
              [-81.953366053934204, 36.003458256469912],
              [-81.953698205714218, 36.003497708843689],
              [-81.953648752140026, 36.003762685564219],
              [-81.953980905344906, 36.003802138065552],
              [-81.95393145247985, 36.004067113967757],
              [-81.954263607076598, 36.004106565695658],
              [-81.954214154953505, 36.004371541680321],
              [-81.954546310942121, 36.004410992634924],
              [-81.95449685956109, 36.004675968701974],
              [-81.954829018050305, 36.004715418856399],
              [-81.954779566302733, 36.004980395032788],
              [-81.955111726150719, 36.005019843512883],
              [-81.955012824956469, 36.005549796856648],
              [-81.955344987296485, 36.005589245594678],
              [-81.955246086936654, 36.006119199007536],
              [-81.958235584917816, 36.006474192941397],
              [-81.958186144391732, 36.00673917028471],
              [-81.95885048333885, 36.006818047736004],
              [-81.958801044655104, 36.007083026192817],
              [-81.959465387035678, 36.007161900289844],
              [-81.959415950128289, 36.007426878058361],
              [-81.960412469890912, 36.007545182803327],
              [-81.96046190359705, 36.007280204643997],
              [-81.961126249192716, 36.00735906880297],
              [-81.961175679331134, 36.007094090362123],
              [-81.961840025233471, 36.007172951519713],
              [-81.96188945287993, 36.006907971869666],
              [-81.962221624142416, 36.006947400989525],
              [-81.962271051538863, 36.006682422035396],
              [-81.963267565149152, 36.006800702654125],
              [-81.963218142062729, 36.007065681972122],
              [-81.963550315732917, 36.007105107450172],
              [-81.963500893422079, 36.007370087751305],
              [-81.963833068450995, 36.007409511555004],
              [-81.963734224956056, 36.007939471373206],
              [-81.964066402477059, 36.007978895434455],
              [-81.963918137984123, 36.008773834742712],
              [-81.964250318998168, 36.008813257390003],
              [-81.964102055892937, 36.009608196630623],
              [-81.964434240466318, 36.009647619665657],
              [-81.964285976531286, 36.010442558892457],
              [-81.964618165706554, 36.010481980486553],
              [-81.96437105796744, 36.011806878289114],
              [-81.96403886453156, 36.011767455116136],
              [-81.963841167090507, 36.012827371279919],
              [-81.963508968601673, 36.012787946709302],
              [-81.963459542639427, 36.013052925493326],
              [-81.96379174219561, 36.013092350194157],
              [-81.963742316975839, 36.013357329060305],
              [-81.962745716113233, 36.013239052756695],
              [-81.96269628733377, 36.013504030283329],
              [-81.961699685083673, 36.01338574548172],
              [-81.961650252810699, 36.013650723470526],
              [-81.960985850408505, 36.013571861565062],
              [-81.960886979510178, 36.014101816904592],
              [-81.960554777216316, 36.014062384322187],
              [-81.960406464643142, 36.014857315693064],
              [-81.960738670138966, 36.014896748666061],
              [-81.960689232673559, 36.01516172613308],
              [-81.961021440670393, 36.015201158305707],
              [-81.96097200391435, 36.015466134953932],
              [-81.961304213303436, 36.015505566352942],
              [-81.961254777322964, 36.015770543984274],
              [-81.961586988071019, 36.015809973708841],
              [-81.961438682458379, 36.016604905777065],
              [-81.961106468475123, 36.016565474761066],
              [-81.960958156736737, 36.017360406005743],
              [-81.96062593987638, 36.017320973695185],
              [-81.960576500521526, 36.017585949958644],
              [-81.96024428291868, 36.017546516613933],
              [-81.960194842204572, 36.0178114936],
              [-81.960527060874796, 36.017850927074853],
              [-81.960477620870094, 36.018115903242169],
              [-81.960145401132507, 36.018076469636966],
              [-81.960046518046852, 36.018606422467471],
              [-81.959714296499385, 36.018566987698037],
              [-81.959615409946167, 36.019096939174617],
              [-81.959947633628431, 36.019136374204578],
              [-81.959898190931867, 36.019401350000841],
              [-81.95956596621528, 36.019361915741577],
              [-81.959417631865037, 36.020156842477562],
              [-81.959085404238024, 36.020117406022791],
              [-81.959035958106952, 36.020382381365941],
              [-81.959368186801385, 36.020421817950975],
              [-81.959170402188647, 36.021481719389364],
              [-81.959502636586734, 36.021521155564585],
              [-81.959304852153821, 36.022581056725755],
              [-81.95897261237684, 36.022541620056366],
              [-81.958873716075672, 36.02307157008714],
              [-81.958541474488726, 36.023032132253427],
              [-81.958343672574728, 36.024092030340853],
              [-81.958011427042621, 36.024052591082238],
              [-81.957961974969763, 36.024317565571735],
              [-81.95762972869511, 36.024278125278947],
              [-81.957580274087576, 36.02454309871591],
              [-81.957248028179379, 36.024503657362118],
              [-81.95714911592033, 36.025033605632302],
              [-81.956816868202083, 36.024994163114073],
              [-81.956322277673578, 36.027643895732858],
              [-81.956654537176618, 36.027683339526789],
              [-81.95650615653328, 36.028478258318188],
              [-81.956173893794258, 36.028438813232654],
              [-81.955926580893589, 36.029763676566482],
              [-81.95559431203246, 36.029724229952492],
              [-81.955445916072406, 36.030519146827928],
              [-81.955778188103523, 36.030558592931818],
              [-81.955580326868443, 36.031618480732071],
              [-81.955248050566524, 36.031579034107132],
              [-81.955149115308416, 36.032108977469598],
              [-81.954816837196105, 36.032069529680136],
              [-81.954618958474384, 36.033129414401493],
              [-81.954286676416359, 36.033089965186974],
              [-81.954187732952718, 36.033619906545916],
              [-81.953855447975229, 36.033580456193626],
              [-81.95380597414983, 36.033845427133883],
              [-81.953473689538853, 36.033805975720547],
              [-81.953374738744103, 36.034335916294197],
              [-81.953042451213662, 36.034296463743019],
              [-81.952992975353325, 36.034561433349587],
              [-81.952660687080296, 36.034521979764094],
              [-81.952462771745488, 36.035581859092943],
              [-81.952130479526517, 36.035542404082356],
              [-81.952031517772312, 36.036072343194938],
              [-81.951699223742963, 36.036032887019722],
              [-81.951600257443005, 36.036562825704401],
              [-81.951267961570267, 36.036523367463616],
              [-81.951168992942968, 36.037053305666682],
              [-81.95083669418365, 36.037013847188895],
              [-81.950787208297768, 36.03727881563691],
              [-81.950454908762907, 36.037239355223875],
              [-81.950405421517004, 36.037504324393872],
              [-81.950073121239583, 36.037464862946464],
              [-81.949974143603086, 36.037994799979167],
              [-81.949641841515273, 36.037955337367151],
              [-81.949592350048661, 36.038220306157484],
              [-81.94926004832746, 36.038180842484245],
              [-81.949161062249487, 36.038710778757746],
              [-81.94882875871788, 36.038671313919828],
              [-81.94877926410669, 36.038936281402847],
              [-81.948446958756321, 36.038896816458141],
              [-81.948397462751842, 36.03916178376231],
              [-81.948065156625987, 36.039122316882349],
              [-81.948015660337504, 36.039387283980808],
              [-81.946354129315083, 36.039189937169787],
              [-81.946403633128213, 36.038924969768551],
              [-81.945739025139062, 36.038846025156303],
              [-81.945788529686354, 36.038581058894778],
              [-81.945456228340404, 36.038541584885685],
              [-81.945406721616195, 36.038806551043685],
              [-81.945074419527813, 36.038767076000191],
              [-81.945024911410286, 36.039032041979141],
              [-81.944692608579459, 36.038992565901225],
              [-81.944643099068699, 36.03925753170126],
              [-81.944310794419167, 36.0392180555165],
              [-81.944211773394969, 36.039747986683246],
              [-81.943879466902246, 36.039708508432852],
              [-81.943780442440513, 36.040238439144211],
              [-81.943448134137626, 36.040198959729025],
              [-81.943349105129172, 36.040728890011749],
              [-81.943016794983009, 36.040689408530973],
              [-81.942967278922737, 36.040954373468928],
              [-81.942634968067139, 36.040914891854548],
              [-81.942585450613521, 36.041179856613482],
              [-81.942253139015435, 36.041140373964545],
              [-81.942203620168442, 36.041405338544521],
              [-81.941871307827967, 36.04136585486112],
              [-81.9418217875876, 36.041630819261933],
              [-81.941489473395592, 36.041591334570839],
              [-81.941390429769712, 36.042121262064875],
              [-81.941058114876554, 36.042081776182151],
              [-81.940959066703698, 36.042611703247275],
              [-81.94062674889102, 36.042572216226482],
              [-81.940527698422343, 36.043102143710129],
              [-81.940195378766504, 36.043062654623526],
              [-81.940145851975885, 36.043327618161925],
              [-81.93981353161054, 36.043288128941661],
              [-81.939664943797467, 36.044083017124727],
              [-81.939332620553884, 36.044043526609279],
              [-81.939233558628857, 36.044573451851498],
              [-81.938901233574882, 36.04453396017108],
              [-81.938851700485031, 36.044798922151593],
              [-81.938519374688781, 36.044759429436638],
              [-81.938469840205286, 36.045024391238002],
              [-81.93813751477596, 36.044984897461838],
              [-81.938087978899006, 36.045249859083931],
              [-81.937755651618048, 36.04521036429999],
              [-81.937706114347435, 36.045475325742913],
              [-81.93737378632413, 36.04543582992433],
              [-81.937076547301686, 36.047025598629936],
              [-81.936744213163294, 36.046986100223933],
              [-81.936694671510097, 36.047251061965305],
              [-81.936362335520158, 36.047211562551389],
              [-81.936164160170861, 36.048271405830839],
              [-81.936496499290925, 36.048310904892155],
              [-81.936397412336561, 36.048840826035736],
              [-81.936729753951099, 36.048880325354567],
              [-81.936482033987602, 36.05020512806356],
              [-81.936814382344664, 36.050244626202648],
              [-81.936566659546585, 36.051569427440256],
              [-81.936234305849027, 36.051529928649153],
              [-81.936184758585114, 36.051794888815685],
              [-81.935852404112325, 36.051755388089084],
              [-81.935703758238745, 36.052550266976276],
              [-81.935371399810691, 36.052510765881749],
              [-81.935074091717425, 36.054100520677501],
              [-81.93540645658733, 36.054140023455346],
              [-81.935307354159548, 36.054669941571774],
              [-81.935639721491484, 36.054709443706159],
              [-81.935590170286062, 36.054974402384126],
              [-81.935922538979142, 36.055013902843804],
              [-81.935872989658407, 36.055278862477451],
              [-81.936537730191034, 36.055357861845593],
              [-81.936488182648191, 36.055622820790191],
              [-81.938150047223445, 36.055820303534126],
              [-81.938100503586654, 36.05608526310828],
              [-81.938432879232437, 36.056124757599129],
              [-81.938383337447718, 36.056389717227887],
              [-81.939048090710514, 36.056468702883322],
              [-81.938998550736642, 36.056733662723786],
              [-81.939330929463082, 36.056773153861677],
              [-81.939281389155241, 36.057038114711084],
              [-81.940278530493671, 36.057156583090048],
              [-81.940228994141606, 36.05742154335389],
              [-81.9405613763074, 36.057461031135276],
              [-81.940610911591165, 36.057196070741028],
              [-81.940943293014314, 36.057235557487779],
              [-81.940893758831649, 36.057500518913272],
              [-81.941558524791503, 36.05757948995447],
              [-81.941508992387028, 36.057844450690737],
              [-81.941841376923634, 36.057883934985249],
              [-81.941791846371302, 36.058148895776114],
              [-81.942124232301964, 36.058188379296581],
              [-81.942074701415692, 36.058453341096303],
              [-81.942407088740325, 36.058492823842855],
              [-81.942357559673326, 36.058757784796185],
              [-81.942689948391944, 36.058797266768771],
              [-81.942590890308338, 36.05932718881575],
              [-81.942923281489385, 36.05936667014457],
              [-81.942774695714576, 36.060161554125422],
              [-81.943107090393411, 36.06020103404007],
              [-81.942958504893269, 36.060995917969869],
              [-81.942626105866836, 36.060956436790036],
              [-81.942576574896535, 36.06122139758898],
              [-81.941911775683735, 36.061142432255537],
              [-81.941862243360362, 36.061407392717967],
              [-81.940532644569103, 36.061249450677515],
              [-81.940483106536902, 36.06151441059616],
              [-81.940150706584859, 36.061474922694721],
              [-81.940101167158531, 36.061739882433834],
              [-81.939768766463843, 36.061700393497759],
              [-81.939719225610531, 36.061965352156513],
              [-81.939386824173056, 36.061925862185731],
              [-81.939337280848974, 36.062190821592537],
              [-81.939004878668783, 36.062151330587014],
              [-81.938955335060143, 36.062416289787542],
              [-81.938622932137278, 36.062376797747383],
              [-81.938573386024842, 36.062641756795117],
              [-81.937908578986708, 36.06256276883996],
              [-81.937958127235888, 36.062297810052925],
              [-81.936960922343502, 36.062179322178977],
              [-81.937010473472171, 36.061914363734026],
              [-81.936013274716984, 36.0617958681121],
              [-81.936062828725056, 36.061530910009054],
              [-81.935398033342466, 36.061451909267312],
              [-81.935447589161598, 36.061186951375902],
              [-81.934782798295672, 36.06110794635017],
              [-81.934832355925735, 36.060842988670416],
              [-81.934499962588276, 36.060803484467648],
              [-81.934549520960815, 36.060538526869237],
              [-81.933884736323193, 36.060459516939048],
              [-81.933934296506692, 36.060194559552315],
              [-81.932604736524297, 36.060036529335058],
              [-81.932555170958096, 36.060301486226834],
              [-81.932222780969752, 36.060261975824211],
              [-81.932173214009268, 36.060526932536412],
              [-81.930843651964793, 36.060368882288486],
              [-81.930893222089011, 36.060103926124718],
              [-81.930228445697168, 36.060024895371996],
              [-81.930178872326763, 36.06028985130159],
              [-81.9291817058688, 36.060171298025431],
              [-81.929132128967638, 36.060436253514553],
              [-81.928799740101695, 36.060396733807913],
              [-81.928601422712291, 36.061456553903191],
              [-81.928269029899027, 36.061417032770379],
              [-81.928219448121965, 36.061681987779366],
              [-81.927887054566327, 36.061642465611776],
              [-81.927837471394852, 36.061907420441123],
              [-81.927505077096868, 36.061867897238749],
              [-81.927455492531166, 36.062132851888457],
              [-81.926125912074056, 36.061974748666088],
              [-81.926076322908898, 36.062239702744712],
              [-81.925079137221402, 36.062121115229147],
              [-81.925029544525216, 36.062386068867113],
              [-81.924697148488121, 36.062346537164402],
              [-81.924647554397637, 36.062611490622665],
              [-81.924315158760479, 36.062571958759456],
              [-81.9242655621333, 36.062836911163693],
              [-81.92393316575388, 36.06279737826565],
              [-81.92388356776506, 36.063062331390988],
              [-81.923551170610679, 36.063022796557348],
              [-81.923451972594862, 36.063552701472027],
              [-81.923784372995371, 36.063592236540217],
              [-81.923685175811897, 36.064122141519],
              [-81.923352773274573, 36.064082606189878],
              [-81.923303173696496, 36.064347558912033],
              [-81.922970770416882, 36.064308022548019],
              [-81.922921168334696, 36.064572975117009],
              [-81.92258876431282, 36.064533437718126],
              [-81.922539161945679, 36.064798390080846],
              [-81.922206757181641, 36.064758851647092],
              [-81.922157152277762, 36.065023802955722],
              [-81.921824746804063, 36.064984264387981],
              [-81.921775140505758, 36.065249215516836],
              [-81.921442734289826, 36.065209675914211],
              [-81.921393126596897, 36.065474626863278],
              [-81.920728311864522, 36.065395543809615],
              [-81.920678701708667, 36.065660494448359],
              [-81.920346294334408, 36.065620951871651],
              [-81.920296682783984, 36.0658859023305],
              [-81.919964274634864, 36.065846357818046],
              [-81.919765819820924, 36.066906159566003],
              [-81.920098233353258, 36.066945704574067],
              [-81.920048620171897, 36.067210654786408],
              [-81.919051378490465, 36.067092017531969],
              [-81.919001761777722, 36.067356967303496],
              [-81.91833693275548, 36.067277871335513],
              [-81.918287313579569, 36.067542820796596],
              [-81.917954898473155, 36.0675032708751],
              [-81.917905277902562, 36.067768220156324],
              [-81.916908032477608, 36.067649565448221],
              [-81.916858407265877, 36.067914514314978],
              [-81.915861161539397, 36.067795850174996],
              [-81.915910789956385, 36.067530901699996],
              [-81.915578376065355, 36.067491345317315],
              [-81.915528746580051, 36.067756293661837],
              [-81.915196331946916, 36.067716736244193],
              [-81.915146701066803, 36.067981684408785],
              [-81.91481428680116, 36.067942125929754],
              [-81.914764654526309, 36.068207073914458],
              [-81.914432238409063, 36.068167514426925],
              [-81.914481871752244, 36.067902566572833],
              [-81.912487391608167, 36.0676651904595],
              [-81.912537031035498, 36.067400243339549],
              [-81.911872209079291, 36.067321111294973],
              [-81.91217005604814, 36.065731429000842],
              [-81.911837652524525, 36.06569186239242],
              [-81.911887293981849, 36.065426915292612],
              [-81.911554891820472, 36.065387347009612],
              [-81.911654174783166, 36.064857452949674],
              [-81.910989375777703, 36.064778315994786],
              [-81.911039019428387, 36.064513368238018],
              [-81.910706621483527, 36.064473798534699],
              [-81.910656976732, 36.064738745260037],
              [-81.908330194900714, 36.064461728346529],
              [-81.90837984823979, 36.064196782509178],
              [-81.908047452906189, 36.064157205571391],
              [-81.908146759599362, 36.063627313135754],
              [-81.907814366728715, 36.06358773555494],
              [-81.907481975261646, 36.063548156142559],
              [-81.907531629716516, 36.063283210575705],
              [-81.905869681574302, 36.06308530243107],
              [-81.905919342120981, 36.062820356540925],
              [-81.905586953672028, 36.062780772891394],
              [-81.905537293134159, 36.063045717723611],
              [-81.904872516123845, 36.062966545622352],
              [-81.904922179907658, 36.062701601025097],
              [-81.904589793515228, 36.062662013735505],
              [-81.904540128695686, 36.062926959102882],
              [-81.903875354754035, 36.062847781549557],
              [-81.903825687439081, 36.063112725705473],
              [-81.903160911557507, 36.063033544300048],
              [-81.903111241812169, 36.063298489046268],
              [-81.902778853847749, 36.06325889684323],
              [-81.902729182675102, 36.063523840508623],
              [-81.902064404480086, 36.063444653154704],
              [-81.902014730844471, 36.063709596509426],
              [-81.901017563351246, 36.063590808291231],
              [-81.901067241301021, 36.06332586530236],
              [-81.900402467481243, 36.063246668879351],
              [-81.900452146098843, 36.06298172522807],
              [-81.899787375721871, 36.062902525449424],
              [-81.89983705729108, 36.062637582884719],
              [-81.899504673660772, 36.062597981769777],
              [-81.899454991023291, 36.062862924203685],
              [-81.898790222606038, 36.062783718999526],
              [-81.898740537505603, 36.063048661122707],
              [-81.898075768226249, 36.062969451139175],
              [-81.898125455462917, 36.062704509277388],
              [-81.897793073507174, 36.062664903484048],
              [-81.897743384092706, 36.062929845241428],
              [-81.897078616773214, 36.062850629832354],
              [-81.897028926005405, 36.063115571252574],
              [-81.896696541783712, 36.063075962511284],
              [-81.896448075484074, 36.064400668272057],
              [-81.89678046504666, 36.064440277667025],
              [-81.896631384772959, 36.065235100918656],
              [-81.896963777866858, 36.065274709801564],
              [-81.896715309181204, 36.066599414896473],
              [-81.897047709052572, 36.066639023502461],
              [-81.896998014737775, 36.066903964519909],
              [-81.89733041600411, 36.066943572352351],
              [-81.896982558504291, 36.068798157099579],
              [-81.896650148617923, 36.068758547477451],
              [-81.89650105903894, 36.069553368242445],
              [-81.896168646274248, 36.069513757323854],
              [-81.896019549439885, 36.070308577278013],
              [-81.895687133797026, 36.070268965062795],
              [-81.895538030816653, 36.071063784179685],
              [-81.895205612295584, 36.071024170667862],
              [-81.8951062057874, 36.071554049287585],
              [-81.894773785456394, 36.071514434609924],
              [-81.894724080659927, 36.071779374165253],
              [-81.894391659587257, 36.071739758452487],
              [-81.894143124121797, 36.073064451255576],
              [-81.894475550536455, 36.073104067622111],
              [-81.894127595035599, 36.074958635110093],
              [-81.89379516006457, 36.074919018755438],
              [-81.893695738303705, 36.075448893591002],
              [-81.893363302632409, 36.075409276044212],
              [-81.893214163827878, 36.076204088897946],
              [-81.892881724135847, 36.076164469179865],
              [-81.89278229486473, 36.076694343897543],
              [-81.892449853362592, 36.07665472301354],
              [-81.892350420646906, 36.077184597271213],
              [-81.892017976224949, 36.077144975247585],
              [-81.891918540032279, 36.077674848144412],
              [-81.891586094942269, 36.077635225829354],
              [-81.891486654195162, 36.078165098292487],
              [-81.891154207262687, 36.07812547391061],
              [-81.891104485346418, 36.078390410387385],
              [-81.890772036562623, 36.078350784996537],
              [-81.890722313250777, 36.078615721292806],
              [-81.890389864835228, 36.078576094840585],
              [-81.89034014012789, 36.078841030956454],
              [-81.890007689860923, 36.078801403495149],
              [-81.889957963725763, 36.079066338529806],
              [-81.889625512749632, 36.07902671093423],
              [-81.889575785218966, 36.079291645788352],
              [-81.888578432059376, 36.079172754453651],
              [-81.888528701028477, 36.079437689766607],
              [-81.887863798789937, 36.079358424084312],
              [-81.887565388378334, 36.080948030751756],
              [-81.887897846300348, 36.080987664816831],
              [-81.887748639869116, 36.081782467421533],
              [-81.888081101323948, 36.081822100974556],
              [-81.887882158730847, 36.082881837316172],
              [-81.888214624754895, 36.082921469587035],
              [-81.888065417867466, 36.083716271931983],
              [-81.887732948605205, 36.083676638367976],
              [-81.887434518413457, 36.085266239153661],
              [-81.887102041956368, 36.085226603926593],
              [-81.887002560101834, 36.08575647020394],
              [-81.886670081834438, 36.085716833810665],
              [-81.886520852283468, 36.086511632486889],
              [-81.886188371137038, 36.086471994796788],
              [-81.885989389885509, 36.087531724234594],
              [-81.88565690479129, 36.087492085116679],
              [-81.885457914030624, 36.088551813233479],
              [-81.885125423878634, 36.08851217271404],
              [-81.885025923826774, 36.08904203577422],
              [-81.884693431864392, 36.089002394088595],
              [-81.8845939283663, 36.089532256687612],
              [-81.884261434593526, 36.089492613835716],
              [-81.8842116818238, 36.08975754446606],
              [-81.883879187309418, 36.089717900578798],
              [-81.883829432065951, 36.089982831955467],
              [-81.883496936810076, 36.089943187032794],
              [-81.88334766695553, 36.090737978642814],
              [-81.884345163356514, 36.090856912775216],
              [-81.884295408909708, 36.091121843312933],
              [-81.884960411372404, 36.09120112784656],
              [-81.884860906914668, 36.091730990170049],
              [-81.885193410219571, 36.091770631354706],
              [-81.885093906590797, 36.09230049373997],
              [-81.88542641347054, 36.092340134255451],
              [-81.885376661663102, 36.092605065066572],
              [-81.885709169971179, 36.09264470570912],
              [-81.885659418905519, 36.092909636601135],
              [-81.885991928577397, 36.092949275569019],
              [-81.885942179363298, 36.093214206515619],
              [-81.886274690431264, 36.09325384470965],
              [-81.886224940881235, 36.093518776664204],
              [-81.886557453345262, 36.093558414084427],
              [-81.886507705614648, 36.093823345192632],
              [-81.886840219474763, 36.093862981839038],
              [-81.886790471375932, 36.094127913054308],
              [-81.887122986632164, 36.094167548926876],
              [-81.887073240385135, 36.09443248019668],
              [-81.887405757037456, 36.094472115295368],
              [-81.887306265699749, 36.095001977946602],
              [-81.887638784817042, 36.095041612402248],
              [-81.887589039758751, 36.095306544684497],
              [-81.88792156023986, 36.095346177465331],
              [-81.88787181592339, 36.095611109828418],
              [-81.888204338910711, 36.095650741809166],
              [-81.888154595335976, 36.09591567425295],
              [-81.888819643352335, 36.095994936689195],
              [-81.888769901556131, 36.096259868443603],
              [-81.889434953051364, 36.096339128423637],
              [-81.889385213066035, 36.096604060389524],
              [-81.890050267975397, 36.096683316111637],
              [-81.88980157494241, 36.098007976471578],
              [-81.890134108804162, 36.098047604066622],
              [-81.890034632105198, 36.098577468105908],
              [-81.890367168432221, 36.098617095057847],
              [-81.890118474608002, 36.099941754071516],
              [-81.890451016607528, 36.099981380772519],
              [-81.890301800025867, 36.100776175600807],
              [-81.889969253708813, 36.100736548533789],
              [-81.889820029865263, 36.101531342544824],
              [-81.890152579389735, 36.101570970004076],
              [-81.890003355809327, 36.102365763955987],
              [-81.890335909978461, 36.102405390876527],
              [-81.89013694487943, 36.103465115365758],
              [-81.890469502510157, 36.103504741029838],
              [-81.890370020136061, 36.104034603234837],
              [-81.890702581374839, 36.104074229130227],
              [-81.890553358551401, 36.104869022002973],
              [-81.89022079413725, 36.104829396616111],
              [-81.890171051461806, 36.10509432704216],
              [-81.890503616977441, 36.105133953460602],
              [-81.890354390227941, 36.105928745730623],
              [-81.890686959246295, 36.105968370735582],
              [-81.89053773276008, 36.106763162945505],
              [-81.890870305345828, 36.106802788338783],
              [-81.89077082150267, 36.107332649221711],
              [-81.890438246810731, 36.107293024467786],
              [-81.890189525020375, 36.108617675167551],
              [-81.889856945308907, 36.108578048855215],
              [-81.889409214482768, 36.110962414898971],
              [-81.889076624364066, 36.110922786531304],
              [-81.888678609083001, 36.113042217578396],
              [-81.888346009593604, 36.113002586385285],
              [-81.888296255704432, 36.113267515120768],
              [-81.888628856263381, 36.113307146444633],
              [-81.888579101973946, 36.113572074385836],
              [-81.888246500377846, 36.11353244383205],
              [-81.888196744691541, 36.113797371592014],
              [-81.887864143463503, 36.113757739976471],
              [-81.887764629002461, 36.114287595984514],
              [-81.887432024820313, 36.114247962327902],
              [-81.887382266045435, 36.114512890575959],
              [-81.887049662231291, 36.114473255857497],
              [-81.886999902027412, 36.114738183023512],
              [-81.886667296360869, 36.114698547295625],
              [-81.88661753479235, 36.114963475181284],
              [-81.886284928383759, 36.114923838417717],
              [-81.886235164308133, 36.115188766148371],
              [-81.885902557157394, 36.115149128349167],
              [-81.88585279276289, 36.115414054971517],
              [-81.885520184869918, 36.115374416136632],
              [-81.885470419110931, 36.115639343478634],
              [-81.88513781047584, 36.11559970360809],
              [-81.88498850685113, 36.11639448316329],
              [-81.884655894224622, 36.116354842021707],
              [-81.884556354307165, 36.116884693952734],
              [-81.88422374097928, 36.116845051618505],
              [-81.884173970047371, 36.117109978265248],
              [-81.883841354867087, 36.117070334921515],
              [-81.883741808707015, 36.117600186026301],
              [-81.883409191715074, 36.117560541516163],
              [-81.883359416519241, 36.117825466875104],
              [-81.883026799895504, 36.117785821303023],
              [-81.882977023302587, 36.118050746480591],
              [-81.882644404826536, 36.118011099899029],
              [-81.882544849661883, 36.118540950715726],
              [-81.882212229341832, 36.11850130206674],
              [-81.882112669617868, 36.119031152445913],
              [-81.881780047518504, 36.118991503531277],
              [-81.881730266079558, 36.119256428063792],
              [-81.881397643205787, 36.119216777212557],
              [-81.881347860369871, 36.119481701563728],
              [-81.88101523678624, 36.119442050577597],
              [-81.880915667992625, 36.119971898866552],
              [-81.880583041487043, 36.119932246740099],
              [-81.880483469244169, 36.12046209456512],
              [-81.880150842037324, 36.120422441245879],
              [-81.880051265234826, 36.120952288633141],
              [-81.879718635073644, 36.120912633272624],
              [-81.879668845666586, 36.12117755674646],
              [-81.879336215906008, 36.121137901224905],
              [-81.879286423991431, 36.121402824543459],
              [-81.878621162171072, 36.12132350964913],
              [-81.878571368900054, 36.121588432629288],
              [-81.878238736840316, 36.121548773256521],
              [-81.878139146066118, 36.122078618829427],
              [-81.877806512194709, 36.122038958290048],
              [-81.877756714691316, 36.122303880882718],
              [-81.877424081220525, 36.122264220182331],
              [-81.877324483059553, 36.122794064053323],
              [-81.876991846634468, 36.122754401311582],
              [-81.876942046009049, 36.123019323490638],
              [-81.876609408874131, 36.122979660613879],
              [-81.876509804468824, 36.123509503657644],
              [-81.876177165490063, 36.123469838713383],
              [-81.875977947390837, 36.124529524596355],
              [-81.875645304461074, 36.124489858223825],
              [-81.875595497222719, 36.124754779681744],
              [-81.874597568333101, 36.124635775616277],
              [-81.874647377670087, 36.124370854576931],
              [-81.8736494549388, 36.1242518427592],
              [-81.873699267157065, 36.123986922061754],
              [-81.873366628701191, 36.123947249459057],
              [-81.873416441693649, 36.123682329742707],
              [-81.872751166793947, 36.123602982110235],
              [-81.872800982676182, 36.123338061677977],
              [-81.872135711258878, 36.12325871158825],
              [-81.872185528952542, 36.122993791367023],
              [-81.871520260953176, 36.122914437018466],
              [-81.871570080458412, 36.122649517008334],
              [-81.869241661938048, 36.122371749159512],
              [-81.869341316081787, 36.121841911694553],
              [-81.869008688599976, 36.121802226307032],
              [-81.869108343571668, 36.121272388901353],
              [-81.868775718588935, 36.121232703771561],
              [-81.868825547191719, 36.120967784660294],
              [-81.868492923606325, 36.12092809875648],
              [-81.868592580857197, 36.120398260669695],
              [-81.868259959738651, 36.120358574122804],
              [-81.868309789497076, 36.120093655121444],
              [-81.867977169775841, 36.12005396780058],
              [-81.868027000275973, 36.11978904887944],
              [-81.867694383062386, 36.11974936075864],
              [-81.867744213193873, 36.119484441943847],
              [-81.867411597377469, 36.119444753048995],
              [-81.867461429361043, 36.119179834288488],
              [-81.867128813831613, 36.119140144645684],
              [-81.867228478954203, 36.118610307234803],
              [-81.86689586700183, 36.118570616923037],
              [-81.866945700140803, 36.1183056982726],
              [-81.86661308844343, 36.118266006311949],
              [-81.866712755908978, 36.117736170022098],
              [-81.866380147788519, 36.117696477392492],
              [-81.866479816049576, 36.117166640261367],
              [-81.86614721042784, 36.117126947889552],
              [-81.866246879516453, 36.116597110818134],
              [-81.865914276329377, 36.116557416902594],
              [-81.866113614850363, 36.115497742676901],
              [-81.865781017410868, 36.115458049255039],
              [-81.865880686811124, 36.114928211200017],
              [-81.865548090727913, 36.114888517161226],
              [-81.865597926021749, 36.114623598639405],
              [-81.865265332413884, 36.114583902899881],
              [-81.865215496050695, 36.114848821290813],
              [-81.86421771293999, 36.114729730078089],
              [-81.864167873041055, 36.114994648025629],
              [-81.863502683420307, 36.114915248806184],
              [-81.863452841054837, 36.115180166441228],
              [-81.862787650606862, 36.115100763340543],
              [-81.86273780466459, 36.115365680689166],
              [-81.861074830553633, 36.115167155057648],
              [-81.859411864608603, 36.114968605904693],
              [-81.859362007645089, 36.115233521892897],
              [-81.858364228393512, 36.115114382061535],
              [-81.858314366783901, 36.115379297632053],
              [-81.857981773614114, 36.11533958120453],
              [-81.857931911717401, 36.115604496567627],
              [-81.857266723176963, 36.115525062563599],
              [-81.857216858813715, 36.115789977614099],
              [-81.856884263411004, 36.115750259136803],
              [-81.856834397650431, 36.116015174005724],
              [-81.85650180150644, 36.115975454492578],
              [-81.856451934348442, 36.11624036917992],
              [-81.856119337463241, 36.116200648630915],
              [-81.856019597772047, 36.116730476742823],
              [-81.855687000186435, 36.116690755000924],
              [-81.855587257076337, 36.117220583549312],
              [-81.855254656569826, 36.117180860666579],
              [-81.855204783421428, 36.117445773832905],
              [-81.854872183283959, 36.117406049888309],
              [-81.854822308770082, 36.117670963773961],
              [-81.854489706781038, 36.117631238819392],
              [-81.853941050252004, 36.120545283252845],
              [-81.854273664004296, 36.120585009648963],
              [-81.854173905631669, 36.121114835279293],
              [-81.854506522961287, 36.121154561006662],
              [-81.854157366346456, 36.123008949570085],
              [-81.853824741530588, 36.12296922292542],
              [-81.853774860094404, 36.123234134846307],
              [-81.853442234537198, 36.123194407165713],
              [-81.85339235059287, 36.123459318930664],
              [-81.852727098292164, 36.123379859691866],
              [-81.852677211880689, 36.123644771144022],
              [-81.852344585169106, 36.123605040486623],
              [-81.852294697359952, 36.123869951756994],
              [-81.851296815953788, 36.123750753061316],
              [-81.851246924608049, 36.124015663887768],
              [-81.850914297038386, 36.123975928447727],
              [-81.850814511255052, 36.124505750587019],
              [-81.850481881874529, 36.124466013979941],
              [-81.850431986294609, 36.124730924417854],
              [-81.849766726411261, 36.124651449127725],
              [-81.849716829474502, 36.124916359226773],
              [-81.849384197861269, 36.124876620569609],
              [-81.849334299526731, 36.125141530486864],
              [-81.848669036192632, 36.125062048367852],
              [-81.848619135390805, 36.125326957972192],
              [-81.848286503733789, 36.125287216312145],
              [-81.848236600423832, 36.125552125760436],
              [-81.847903967993645, 36.125512382163514],
              [-81.84785406328578, 36.125777291429941],
              [-81.847521429004061, 36.125737546822876],
              [-81.847421617574881, 36.126267364915265],
              [-81.847088981482329, 36.126227619141062],
              [-81.846789530676972, 36.127817071463582],
              [-81.846456888495467, 36.127777323997861],
              [-81.846406977624085, 36.128042232413499],
              [-81.84607433470137, 36.1280024839118],
              [-81.84587468251145, 36.129062115666542],
              [-81.845542035638857, 36.129022365735374],
              [-81.845442204863289, 36.129552180607739],
              [-81.843446316578252, 36.129313660469215],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 53,
        ORIG_FID: 6,
        AAPC_Examp: 5,
        Area_mile2: 139,
        BASIN_NAME: "Lookout Shoals",
        Res_Basin: "LookoutShoals",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.201819839888444, 36.036721295919193],
              [-81.201871943168612, 36.036456646164531],
              [-81.201540048255779, 36.036415099811627],
              [-81.201592152256453, 36.036150450143225],
              [-81.200928365557317, 36.036067354104588],
              [-81.200980471340927, 36.035802704659574],
              [-81.200648579554709, 36.035761154973819],
              [-81.200700686058866, 36.035496505615178],
              [-81.196054257466997, 36.034914719832898],
              [-81.196002134978613, 36.035179367292784],
              [-81.195338362011682, 36.035096239961149],
              [-81.19528623705618, 36.035360887096211],
              [-81.194954351132807, 36.035319321920539],
              [-81.194850096960863, 36.035848615784474],
              [-81.194518209254724, 36.035807049432684],
              [-81.194466081147723, 36.036071696141526],
              [-81.194134191587395, 36.036030127869616],
              [-81.194082060966338, 36.036294774410699],
              [-81.193750171819644, 36.036253205980884],
              [-81.193698039793844, 36.03651785233415],
              [-81.193034259292887, 36.03643471251447],
              [-81.192982124775284, 36.036699357641851],
              [-81.192318342409024, 36.03661621396013],
              [-81.19226620655806, 36.036880859643709],
              [-81.190938641658121, 36.036714560007525],
              [-81.191042923736376, 36.036185269646779],
              [-81.190711035498111, 36.036143692982414],
              [-81.190763177086282, 36.035879047863077],
              [-81.190099404846464, 36.03579589118187],
              [-81.190151548217244, 36.035531246286112],
              [-81.189819664240204, 36.035489667170339],
              [-81.18987180833102, 36.035225022361004],
              [-81.188876160507178, 36.03510027913245],
              [-81.188928307442993, 36.034835634683837],
              [-81.186937028472897, 36.034586122876263],
              [-81.186884874053206, 36.034850766521863],
              [-81.18655299453745, 36.03480917762316],
              [-81.186500838712973, 36.035073821080914],
              [-81.185173318739103, 36.034907456839377],
              [-81.185225478813152, 36.034642813930276],
              [-81.184229846453178, 36.034518031901158],
              [-81.184282009371856, 36.034253389352948],
              [-81.183950133657618, 36.034211793376315],
              [-81.184002297296331, 36.03394715091455],
              [-81.183670422986893, 36.033905554173217],
              [-81.183722588454771, 36.033640911778136],
              [-81.183058843012248, 36.033557716765252],
              [-81.183111009153052, 36.033293074613709],
              [-81.182447268289877, 36.03320987534687],
              [-81.182499436212979, 36.032945233419092],
              [-81.182167567911947, 36.032903632560156],
              [-81.182219736554941, 36.032638990718915],
              [-81.181887868549424, 36.032597389115132],
              [-81.181835698844125, 36.032862030819167],
              [-81.181503830118771, 36.032820428176343],
              [-81.181451658984315, 36.033085068791522],
              [-81.18111978953911, 36.033043465109614],
              [-81.181015443057603, 36.033572747735363],
              [-81.18068357183023, 36.033531142877187],
              [-81.180735746859213, 36.033266501716909],
              [-81.180072008665832, 36.033183289549683],
              [-81.180124184367614, 36.032918648632993],
              [-81.179792317401677, 36.032877041323871],
              [-81.179844494932496, 36.032612400473965],
              [-81.179512628237674, 36.032570791518964],
              [-81.179564806488358, 36.032306150755609],
              [-81.178569213937422, 36.032181321554773],
              [-81.178621395032422, 36.031916681152559],
              [-81.177957671217442, 36.031833456536418],
              [-81.178009854094427, 36.031568816357932],
              [-81.17601869613479, 36.031319121708734],
              [-81.176070886151862, 36.03105448228343],
              [-81.17573902895019, 36.031012863936681],
              [-81.175791219687, 36.030748224597943],
              [-81.175459363865812, 36.030706604585589],
              [-81.175511554212861, 36.030441965353234],
              [-81.174847846856238, 36.030358723778953],
              [-81.174900038985186, 36.030094084770496],
              [-81.174568187425351, 36.030052462307609],
              [-81.174620381383193, 36.029787823366085],
              [-81.173956679221462, 36.029704576949811],
              [-81.174008874936746, 36.029439937331205],
              [-81.173677026553449, 36.02939831333844],
              [-81.174042393414624, 36.02754584007787],
              [-81.173710552784556, 36.027504215243162],
              [-81.173762747907617, 36.027239576395566],
              [-81.173430908682249, 36.027197950796449],
              [-81.173483104524806, 36.026933312035659],
              [-81.173151266704025, 36.026891685672098],
              [-81.173203463266091, 36.026627046997973],
              [-81.172871626850096, 36.026585419869924],
              [-81.17292382413153, 36.026320781282578],
              [-81.171264653610166, 36.026112632782933],
              [-81.17131685696863, 36.02584799481189],
              [-81.167666726738844, 36.025389989314064],
              [-81.167614511698147, 36.025654625774614],
              [-81.166950854092548, 36.025571340212757],
              [-81.167055289164395, 36.02504206686978],
              [-81.166723462445006, 36.025000423020764],
              [-81.166827897160744, 36.024471149770122],
              [-81.166496073993144, 36.024429504373373],
              [-81.166548291910914, 36.024164868260158],
              [-81.166216470148029, 36.024123222099114],
              [-81.166268689894324, 36.02385858605308],
              [-81.165936869560241, 36.023816940028631],
              [-81.165989088892346, 36.02355230318814],
              [-81.165657269963049, 36.023510656399409],
              [-81.165709490014265, 36.023246019645711],
              [-81.166231673968312, 36.020599651997195],
              [-81.165899868147577, 36.020558004897026],
              [-81.166004302515645, 36.02002873140512],
              [-81.165672499161346, 36.01998708367806],
              [-81.165933584332876, 36.018663897500161],
              [-81.166265382377944, 36.01870554454041],
              [-81.166317597322362, 36.018440907016277],
              [-81.165985800339129, 36.018399260113391],
              [-81.16619466093708, 36.017340710062705],
              [-81.166526453648913, 36.017382355515075],
              [-81.166787515244209, 36.016059165250553],
              [-81.166455728950211, 36.016017520465354],
              [-81.166560152633309, 36.015488243753168],
              [-81.166228368781248, 36.015446597439961],
              [-81.166280581737198, 36.015181959586499],
              [-81.165948799313824, 36.015140313410186],
              [-81.16620986418954, 36.013817121371808],
              [-81.16654164021962, 36.013858767782004],
              [-81.166698270734386, 36.013064851382993],
              [-81.167030045031012, 36.01310649645982],
              [-81.167343289330802, 36.011518661500382],
              [-81.167011522513121, 36.011477017228046],
              [-81.16667975606218, 36.011435372955248],
              [-81.166731964916096, 36.011170733584109],
              [-81.165073143036409, 36.01096249668074],
              [-81.165125356879955, 36.010697858847287],
              [-81.164130072152687, 36.010572906099199],
              [-81.16418228881416, 36.010308267726636],
              [-81.163850528607625, 36.010266614851155],
              [-81.163902745988025, 36.010001976565796],
              [-81.163570988318824, 36.009960323807611],
              [-81.163623206418123, 36.009695685609422],
              [-81.163291449020065, 36.009654031205784],
              [-81.163343668947149, 36.009389393075189],
              [-81.163011912977481, 36.00934773880843],
              [-81.163116352817838, 36.00881846269111],
              [-81.162784599289751, 36.008776806896599],
              [-81.162836820311909, 36.008512168890356],
              [-81.162505069297012, 36.008470512312051],
              [-81.16287060763436, 36.006618045943064],
              [-81.162538865501475, 36.006576389405623],
              [-81.162591084796361, 36.006311751292621],
              [-81.162922826976668, 36.006353407672954],
              [-81.162975044843293, 36.006088768471265],
              [-81.1633067851958, 36.006130423832516],
              [-81.163359001682494, 36.005865785344383],
              [-81.16369074242526, 36.005907439647046],
              [-81.163742957483592, 36.005642800070312],
              [-81.164074697507431, 36.005684453334133],
              [-81.164126911185946, 36.005419814471047],
              [-81.164458649357826, 36.005461465814491],
              [-81.16451086163228, 36.005196826763822],
              [-81.164842599109519, 36.005238477969634],
              [-81.16510364887823, 36.003915279495665],
              [-81.164771916708148, 36.003873628976912],
              [-81.164980754370632, 36.002815069489664],
              [-81.164649026788723, 36.002773418619071],
              [-81.164753445132803, 36.002244138859851],
              [-81.16442172004038, 36.002202488263613],
              [-81.164473930302236, 36.001937847986028],
              [-81.164142206589588, 36.001896195724804],
              [-81.1641944175703, 36.001631555534537],
              [-81.163530974428454, 36.001548249464108],
              [-81.163478761325123, 36.001812889379529],
              [-81.162483595407068, 36.00168792175932],
              [-81.162535811718655, 36.001423283157195],
              [-81.161872371978689, 36.001339967171674],
              [-81.161924591155042, 36.001075327873671],
              [-81.161592872305917, 36.001033668676079],
              [-81.161697310668416, 36.000504391125702],
              [-81.161365594284547, 36.000462731301546],
              [-81.161417814555591, 36.000198092128421],
              [-81.161086099575655, 36.000156431540326],
              [-81.161138320565271, 35.999891792454626],
              [-81.160474894864649, 35.999808468829549],
              [-81.160527117634246, 35.999543829968758],
              [-81.160195406913573, 35.999502166931876],
              [-81.160247629317055, 35.999237529079203],
              [-81.159915919976243, 35.99919586437727],
              [-81.160281477816724, 35.997343393506739],
              [-81.159949777380106, 35.997301729747036],
              [-81.160367545149683, 35.995184615460872],
              [-81.160035853545082, 35.995142951899425],
              [-81.160192513844237, 35.994349033408298],
              [-81.159860825765563, 35.994307369358012],
              [-81.160121923970379, 35.992984171275587],
              [-81.158795199630319, 35.992817508751514],
              [-81.158847422251569, 35.992552868998601],
              [-81.157852385476005, 35.992427862158436],
              [-81.157800159671567, 35.992692501498851],
              [-81.157136801034625, 35.992609158530236],
              [-81.157189030094059, 35.992344520346244],
              [-81.156857352892928, 35.992302847187105],
              [-81.156961810972916, 35.991773570063039],
              [-81.156630136260702, 35.991731897178745],
              [-81.156682366390115, 35.991467258219096],
              [-81.156019020091549, 35.991383909120671],
              [-81.156071252024418, 35.991119271287317],
              [-81.155076239426336, 35.990994240822609],
              [-81.155128474199515, 35.990729603351966],
              [-81.154796804702215, 35.990687924571596],
              [-81.154849040169609, 35.990423286287502],
              [-81.154185705457991, 35.990339927179562],
              [-81.154237942728699, 35.990075290021757],
              [-81.153906276381463, 35.990033608812695],
              [-81.153958515455116, 35.989768970822062],
              [-81.153626850535701, 35.98972728975037],
              [-81.1536790903275, 35.989462651847411],
              [-81.15334742678769, 35.989420969110924],
              [-81.153295187043597, 35.989685606856824],
              [-81.152631859978911, 35.989602238385629],
              [-81.152684102977659, 35.989337601796286],
              [-81.152352440466032, 35.989295916356156],
              [-81.152613653309643, 35.987972729785504],
              [-81.152281997553956, 35.987931044112223],
              [-81.152386482182692, 35.987401769054216],
              [-81.152718135816599, 35.987443454452396],
              [-81.152770376001712, 35.98717881672097],
              [-81.15310202891736, 35.987220501080415],
              [-81.153049789793243, 35.98748513894936],
              [-81.153713098750373, 35.987568504338647],
              [-81.153765335752823, 35.987303866194686],
              [-81.15442864398311, 35.987387228605215],
              [-81.154480878521099, 35.987122590136472],
              [-81.15481253206525, 35.987164268951375],
              [-81.155125924623022, 35.985576436289449],
              [-81.155457572168686, 35.985618114279141],
              [-81.155614260060872, 35.984824195973445],
              [-81.15594590476671, 35.984865872649479],
              [-81.155998132671456, 35.984601232370096],
              [-81.156329775550432, 35.98464290802702],
              [-81.156382002075802, 35.984378268461562],
              [-81.156713644212459, 35.984419942178754],
              [-81.156765868225833, 35.984155302445707],
              [-81.156434227125914, 35.984113627964966],
              [-81.156643127298508, 35.983055068126632],
              [-81.155648218808196, 35.982930041828581],
              [-81.155700445351769, 35.98266540239733],
              [-81.155037177952138, 35.982582046680989],
              [-81.155089407383272, 35.982317407455575],
              [-81.154426142391074, 35.982234049330437],
              [-81.154478373577049, 35.981969409429368],
              [-81.154146742643576, 35.98192772870204],
              [-81.154512358849018, 35.980075251671686],
              [-81.15418073679183, 35.980033570986365],
              [-81.15423296627371, 35.979768931884237],
              [-81.153901345619843, 35.979727250435353],
              [-81.153953576904343, 35.979462610500548],
              [-81.153621957677927, 35.979420929189175],
              [-81.153674189680459, 35.979156289342313],
              [-81.153342571833193, 35.979114606366288],
              [-81.153394804553685, 35.978849966607513],
              [-81.153063188109797, 35.978808282867938],
              [-81.15311542157238, 35.97854364409816],
              [-81.152783807640347, 35.97850195957551],
              [-81.152836041796974, 35.978237319992623],
              [-81.152504428159659, 35.978195634726056],
              [-81.15255666303419, 35.977930995231205],
              [-81.152225051908616, 35.977889309181485],
              [-81.152277287501221, 35.977624669774634],
              [-81.151945676670451, 35.977582982980948],
              [-81.151997912980946, 35.977318343662212],
              [-81.151666304686003, 35.977276656986454],
              [-81.151718541714558, 35.977012017755655],
              [-81.149397298259075, 35.976720179589968],
              [-81.149449543477587, 35.976455541253095],
              [-81.148786335798818, 35.976372151351569],
              [-81.148838582795761, 35.9761075132404],
              [-81.148175379717372, 35.976024119990278],
              [-81.148436619034271, 35.974700929186099],
              [-81.148768215099366, 35.974742625573711],
              [-81.149133927646645, 35.972890154878002],
              [-81.149465515546467, 35.972931850322112],
              [-81.149726722540876, 35.971608653392266],
              [-81.149395139967325, 35.971566959537135],
              [-81.149604102094671, 35.970508402042029],
              [-81.149272525213831, 35.970466707816691],
              [-81.149377006896643, 35.969937429038296],
              [-81.149045431346863, 35.96989573330567],
              [-81.149254394818357, 35.968837174805685],
              [-81.148922823876703, 35.968795479623552],
              [-81.149027305689359, 35.968266200803228],
              [-81.148695738295501, 35.968224504074747],
              [-81.148747980290381, 35.967959864267854],
              [-81.148416414323506, 35.967918167677098],
              [-81.148468655927729, 35.967653527978058],
              [-81.148137091363708, 35.967611830623959],
              [-81.148189334793969, 35.967347190993635],
              [-81.147857771633014, 35.967305492876221],
              [-81.147910014672576, 35.967040853353666],
              [-81.147578452890514, 35.96699915357194],
              [-81.147735185350996, 35.966205235082271],
              [-81.148066743952214, 35.966246934451348],
              [-81.148432432849546, 35.964394454399411],
              [-81.148763983239164, 35.964436151022625],
              [-81.148816221449607, 35.964171510820485],
              [-81.149147772229895, 35.964213206385722],
              [-81.149200009037415, 35.963948565996731],
              [-81.149531557991764, 35.963990260543078],
              [-81.149583793396545, 35.963725619967214],
              [-81.149915342741551, 35.963767313455556],
              [-81.15001981037895, 35.963238030979923],
              [-81.150351356837675, 35.963279723311736],
              [-81.150455821008308, 35.962750441265136],
              [-81.151118910712682, 35.962833822675691],
              [-81.151171140163797, 35.962569181303365],
              [-81.151834229117895, 35.96265255883538],
              [-81.151886454973678, 35.962387916257249],
              [-81.152217998904248, 35.962429603534353],
              [-81.152270224489698, 35.962164961650899],
              [-81.151938681619342, 35.962123274511363],
              [-81.152043133858982, 35.96159398997105],
              [-81.151711593427478, 35.961552301304742],
              [-81.151972721104855, 35.960229089369783],
              [-81.151641187449059, 35.960187401371989],
              [-81.151902311840701, 35.958864187995239],
              [-81.151570783828049, 35.958822499784546],
              [-81.151623008069251, 35.958557856930874],
              [-81.148970805838943, 35.958224318094828],
              [-81.149023039326664, 35.957959676273333],
              [-81.148359995133802, 35.957876282366257],
              [-81.148516699901833, 35.957082357432697],
              [-81.14884821918902, 35.957124053963518],
              [-81.149057149844126, 35.956065486147743],
              [-81.149388664125354, 35.956107181246828],
              [-81.149754272140655, 35.954254683816131],
              [-81.149422765255323, 35.954212988779211],
              [-81.149631679707539, 35.953154417923997],
              [-81.149300178512846, 35.953112722517211],
              [-81.149456863652503, 35.952318794053632],
              [-81.149125365980083, 35.952277098159044],
              [-81.149177595112278, 35.952012455671316],
              [-81.149509091700878, 35.952054150527303],
              [-81.149613546794185, 35.951524864228659],
              [-81.149945041605363, 35.951566557908706],
              [-81.149997267590322, 35.951301914998922],
              [-81.149665772706825, 35.951260220574945],
              [-81.149717999409219, 35.950995577753758],
              [-81.149386507036297, 35.950953882547147],
              [-81.149438734456169, 35.950689239814629],
              [-81.149107243485531, 35.950647543844909],
              [-81.149159470490602, 35.950382900319539],
              [-81.148827980946294, 35.950341204487827],
              [-81.149036894238009, 35.949282631270364],
              [-81.149368379542921, 35.949324326551618],
              [-81.149472830894055, 35.948795038943118],
              [-81.14980431444566, 35.948836733949534],
              [-81.150013208799351, 35.947778157595202],
              [-81.150344687322047, 35.947819850268907],
              [-81.15039690954859, 35.94755520591081],
              [-81.151391345123855, 35.947680278996536],
              [-81.151443562720502, 35.947415634196282],
              [-81.152106519740443, 35.947499011768734],
              [-81.152158734875002, 35.947234366644317],
              [-81.152490213380844, 35.947276053481623],
              [-81.152542427113303, 35.947011408170738],
              [-81.152873903793477, 35.947053093989332],
              [-81.15292611612378, 35.946788448492029],
              [-81.153257592086504, 35.94683013327235],
              [-81.15330980301465, 35.946565487588664],
              [-81.153641278260039, 35.946607171330712],
              [-81.153745696945549, 35.946077878640438],
              [-81.154077170413686, 35.946119561206665],
              [-81.154129378195137, 35.945854915101314],
              [-81.154460850945824, 35.945896596629296],
              [-81.154513057325246, 35.94563195033745],
              [-81.154844529358371, 35.945673630827194],
              [-81.154948937838469, 35.945144336940466],
              [-81.155280408094441, 35.945186016254389],
              [-81.155489216708119, 35.944127427344903],
              [-81.155820681959227, 35.944169105227495],
              [-81.155872882763219, 35.943904458181315],
              [-81.156204347296864, 35.943946135025676],
              [-81.156256545566663, 35.943681486911721],
              [-81.156588010490935, 35.943723162698298],
              [-81.156640207358819, 35.943458514397939],
              [-81.157303136114507, 35.943541862994032],
              [-81.157355330544775, 35.943277215270882],
              [-81.157686793268084, 35.943318888100002],
              [-81.157947754890955, 35.941995644460938],
              [-81.158279212657888, 35.942037315701661],
              [-81.158540159294944, 35.940714069274982],
              [-81.158208706826372, 35.940672398721986],
              [-81.158417461993722, 35.939613801258155],
              [-81.158086014105976, 35.939572130354748],
              [-81.158138203379039, 35.939307480999695],
              [-81.157806756893109, 35.939265809333243],
              [-81.158015513693954, 35.938207211996236],
              [-81.157684072896714, 35.938165539959819],
              [-81.157788450785219, 35.937636240383263],
              [-81.158119889463165, 35.937677912144636],
              [-81.158172077400636, 35.93741326258656],
              [-81.158503515361161, 35.937454933309738],
              [-81.158660072809667, 35.936660983049642],
              [-81.158991507933706, 35.936702652459594],
              [-81.159095875358858, 35.936173351474139],
              [-81.158764442353956, 35.93613168233928],
              [-81.159286268415315, 35.933485174991155],
              [-81.158954847455803, 35.933443506311669],
              [-81.159059210883953, 35.932914203809666],
              [-81.159390629724598, 35.932955872214052],
              [-81.159547167460673, 35.932161917713756],
              [-81.159703703226128, 35.931367962757285],
              [-81.160035116052029, 35.931409629435862],
              [-81.160139468661029, 35.930880325627086],
              [-81.160470879710232, 35.93092199113007],
              [-81.160575228832229, 35.930392686852258],
              [-81.160906636996629, 35.930434351199303],
              [-81.160958809961016, 35.930169697949104],
              [-81.161290218516058, 35.93021136123842],
              [-81.16134239010303, 35.929946708703213],
              [-81.161673796832758, 35.929988370974286],
              [-81.161725967018398, 35.929723718253079],
              [-81.162057373006519, 35.929765378584975],
              [-81.162161710232851, 35.929236072722247],
              [-81.16249311444426, 35.929277731878607],
              [-81.162545281485066, 35.929013078737],
              [-81.163208088839127, 35.929096394974316],
              [-81.163886191590194, 35.925655890922997],
              [-81.164217581996525, 35.92569754545265],
              [-81.164374054824648, 35.924903580869142],
              [-81.16404266868004, 35.924861925831387],
              [-81.164303455168906, 35.923538650645803],
              [-81.163972074662482, 35.923496995395155],
              [-81.164232857875845, 35.922173718788386],
              [-81.163901483007521, 35.922132063324952],
              [-81.16395363968374, 35.921867407996295],
              [-81.163622267324428, 35.921825751750347],
              [-81.163674424717854, 35.921561096510878],
              [-81.163343052675998, 35.921519440422799],
              [-81.163395210762559, 35.921254784371591],
              [-81.162732470906548, 35.921171468848648],
              [-81.162784630793709, 35.920906813925242],
              [-81.162453262979625, 35.920865154490365],
              [-81.162121895507369, 35.920823494155144],
              [-81.162174057171072, 35.920558839458565],
              [-81.158529045872967, 35.920100517919202],
              [-81.158581219952964, 35.919835864667895],
              [-81.157587137227992, 35.919710849373701],
              [-81.157639314119336, 35.919446195585877],
              [-81.156645238757633, 35.919321172581796],
              [-81.156697418484143, 35.919056519158403],
              [-81.156366061404199, 35.919014843167176],
              [-81.156418242979598, 35.918750190714661],
              [-81.156086887276714, 35.91870851305962],
              [-81.156139068461457, 35.918443860716138],
              [-81.155807715291417, 35.918402183179857],
              [-81.155145007738014, 35.918318824544421],
              [-81.155197192865643, 35.918054172545894],
              [-81.154203139481538, 35.9179291277636],
              [-81.154255326336454, 35.917664476149248],
              [-81.15359262754771, 35.917581109041606],
              [-81.153644817262204, 35.917316456733637],
              [-81.153313470005926, 35.917274772407723],
              [-81.153365660437359, 35.917010120189261],
              [-81.15270296793723, 35.91692674823031],
              [-81.152755160168596, 35.916662097139898],
              [-81.151429785701097, 35.916495342028853],
              [-81.151481981826265, 35.916230691440916],
              [-81.149825277836442, 35.916022227739234],
              [-81.149773075308616, 35.916286877658514],
              [-81.149110393561202, 35.916203486149065],
              [-81.149058189681114, 35.916468135725246],
              [-81.14839550716043, 35.916384739438271],
              [-81.148343299736496, 35.916649389611571],
              [-81.147680616466701, 35.916565989448166],
              [-81.147628406582541, 35.916830639297906],
              [-81.146965722563792, 35.916747235258036],
              [-81.146913510195517, 35.917011883883056],
              [-81.145256800843114, 35.916803357340235],
              [-81.145204582837835, 35.917068005228529],
              [-81.143879217257009, 35.916901166337475],
              [-81.143931440605769, 35.916636518980475],
              [-81.142606085821313, 35.916469665315454],
              [-81.142658311955699, 35.916205018480589],
              [-81.141664302927438, 35.916079869637976],
              [-81.141716532980027, 35.915815222247495],
              [-81.141385199127242, 35.915773504591229],
              [-81.141332967991886, 35.916038150942853],
              [-81.141001632314939, 35.915996432268066],
              [-81.141053864485215, 35.915731785153113],
              [-81.138734536080136, 35.915439726505035],
              [-81.137409213869987, 35.915272815386345],
              [-81.137356968944331, 35.915537461005478],
              [-81.134043676212201, 35.915120117473329],
              [-81.133991420331881, 35.915384760765214],
              [-81.132997435896471, 35.91525953946946],
              [-81.132945176497302, 35.915524182299777],
              [-81.131619864846201, 35.915357207411411],
              [-81.131567599761397, 35.915621849661576],
              [-81.129910962864457, 35.915413109201268],
              [-81.129963233219385, 35.915148466739232],
              [-81.129300583405211, 35.915064963980953],
              [-81.129352856666571, 35.914800322628075],
              [-81.128690210340395, 35.914716816544676],
              [-81.128027565384713, 35.914633306860587],
              [-81.128079842538526, 35.914368666010887],
              [-81.127417201070898, 35.914285153001664],
              [-81.127469479975815, 35.914020511478405],
              [-81.126806841996256, 35.913936995144176],
              [-81.126859122699841, 35.913672354749572],
              [-81.126196489292241, 35.913588834169992],
              [-81.126248771770591, 35.913324194002868],
              [-81.125254826875349, 35.913198907706573],
              [-81.125307112163341, 35.912934267003877],
              [-81.124644487548849, 35.91285073885593],
              [-81.124696774611621, 35.912586098380757],
              [-81.123702842316504, 35.912460799390082],
              [-81.123755132212594, 35.912196159280469],
              [-81.122761207261973, 35.912070851681783],
              [-81.122813500015027, 35.911806212838705],
              [-81.122150887212157, 35.911722670214772],
              [-81.122203182823739, 35.911458030678979],
              [-81.121871877995062, 35.911416258154674],
              [-81.121924174346219, 35.911151619609747],
              [-81.121592870918903, 35.911109846323228],
              [-81.121697463555094, 35.910580567581853],
              [-81.121366162587847, 35.910538793671066],
              [-81.12147075707783, 35.910009514993654],
              [-81.121139458570525, 35.90996774045859],
              [-81.121296349826864, 35.90917382341555],
              [-81.120965055945575, 35.909132048374886],
              [-81.121121947269231, 35.908338130411451],
              [-81.120790655798729, 35.908296354903811],
              [-81.120842953648918, 35.908031715617923],
              [-81.120511664663638, 35.907989938427711],
              [-81.12061626034513, 35.907460660006848],
              [-81.120284974951034, 35.907418883074278],
              [-81.120337272782308, 35.90715424393931],
              [-81.120005987681751, 35.907112466263783],
              [-81.120058287336505, 35.906847827199435],
              [-81.119727003613534, 35.906806047860726],
              [-81.119779303984032, 35.90654140888617],
              [-81.119448022793577, 35.906499629667216],
              [-81.119500323879777, 35.906234990782607],
              [-81.119709523665662, 35.9051764338815],
              [-81.119378246979124, 35.90513465357045],
              [-81.119430547409948, 35.904870014583445],
              [-81.119761823014471, 35.90491179385554],
              [-81.120232498526136, 35.902530036023819],
              [-81.120895028389228, 35.902613589442652],
              [-81.120947322193942, 35.902348948660659],
              [-81.121278587158585, 35.902390724773568],
              [-81.121330878454771, 35.902126083824989],
              [-81.121662142679952, 35.902167857998784],
              [-81.122132741638836, 35.8997860869245],
              [-81.122463995574066, 35.899827858976238],
              [-81.122725420912246, 35.898504649977689],
              [-81.122394172268557, 35.898462878615611],
              [-81.122498742098344, 35.897933594055708],
              [-81.122167495913843, 35.897891822069553],
              [-81.122219782492451, 35.897627180286968],
              [-81.121888537708841, 35.897585407538749],
              [-81.121993109715973, 35.897056123243381],
              [-81.121661868499075, 35.897014349851915],
              [-81.12187101263487, 35.895955781238634],
              [-81.121539775993412, 35.895914007498895],
              [-81.121748918880286, 35.894855438672153],
              [-81.121417686814254, 35.894813664584277],
              [-81.121469972725819, 35.894549021945423],
              [-81.121138742060566, 35.894507247095568],
              [-81.121347887644419, 35.893448677495911],
              [-81.12101666155435, 35.89340690229794],
              [-81.121121233841407, 35.892877617046416],
              [-81.120790011317723, 35.892835841205169],
              [-81.120894584350523, 35.892306556038491],
              [-81.120563364285687, 35.89226477957331],
              [-81.120615651346341, 35.892000137056222],
              [-81.120284432682212, 35.891958359829118],
              [-81.120336720458354, 35.891693717402312],
              [-81.120005503171285, 35.891651938512197],
              [-81.120057791662902, 35.891387296175537],
              [-81.119726575800186, 35.891345517424611],
              [-81.119988019516683, 35.890022305696156],
              [-81.119656809263233, 35.889980525834069],
              [-81.120179681510479, 35.887334097476192],
              [-81.119848483310832, 35.887292318975824],
              [-81.120109910734456, 35.885969102367142],
              [-81.119778719274507, 35.885927323637652],
              [-81.119883289942933, 35.885398036215847],
              [-81.119552099833982, 35.885356256881671],
              [-81.119656671247967, 35.884826969545415],
              [-81.119325484704831, 35.884785189568085],
              [-81.119377770955822, 35.884520545966552],
              [-81.119046584705757, 35.884478765246769],
              [-81.11909887277946, 35.884214121716305],
              [-81.118767687906214, 35.88417233933361],
              [-81.118872264034366, 35.883643052425256],
              [-81.118541081643102, 35.883601270319929],
              [-81.118593370804732, 35.883336626922741],
              [-81.118262190897539, 35.883294843135204],
              [-81.118314479667262, 35.883030199847724],
              [-81.11798330118414, 35.882988416199467],
              [-81.118192458175713, 35.881929843106633],
              [-81.117530110652638, 35.881846272412744],
              [-81.117582401990191, 35.881581629282294],
              [-81.117251229800345, 35.881539842723733],
              [-81.117303521853003, 35.881275199683671],
              [-81.116972351087256, 35.881233413264503],
              [-81.117024642724246, 35.880968769433117],
              [-81.116693474466047, 35.880926982232978],
              [-81.116745766818312, 35.880662338492009],
              [-81.116414599960478, 35.880620550530175],
              [-81.116466893027919, 35.880355906879757],
              [-81.11580456243199, 35.880272327631815],
              [-81.115856857272362, 35.880007684209929],
              [-81.115525693534266, 35.879965892923849],
              [-81.115577990220515, 35.879701250474298],
              [-81.115246827882871, 35.879659458426531],
              [-81.115299124177014, 35.879394816086808],
              [-81.114967963239721, 35.879353023277339],
              [-81.115020261356193, 35.87908838100887],
              [-81.114357942624736, 35.879004792966931],
              [-81.114410242490521, 35.878740150025912],
              [-81.113747927245015, 35.878656558661305],
              [-81.113416770136325, 35.878614761629436],
              [-81.113364467073836, 35.878879403255148],
              [-81.113033309250014, 35.87883760518563],
              [-81.112981004810734, 35.879102247526916],
              [-81.112649846248388, 35.879060447518462],
              [-81.112597540408743, 35.879325089674289],
              [-81.112266381154825, 35.879283289529219],
              [-81.112214072784084, 35.879547930617562],
              [-81.111882912815162, 35.879506129434724],
              [-81.111830604151166, 35.879770770318281],
              [-81.111499443467309, 35.879728968097709],
              [-81.111447132295652, 35.879993608814935],
              [-81.11078480981692, 35.879910000497446],
              [-81.11073249729445, 35.880174640871878],
              [-81.110401334415968, 35.880132835695242],
              [-81.110349020492976, 35.880397475884031],
              [-81.109355532931886, 35.880272053602255],
              [-81.109250895496842, 35.880801333047266],
              [-81.108919731923535, 35.880759523838421],
              [-81.108815091002057, 35.881288802817039],
              [-81.108483925655989, 35.881246992432345],
              [-81.108379281247935, 35.881776270944613],
              [-81.108048112998375, 35.881734458502088],
              [-81.107995789222372, 35.881999097548842],
              [-81.107333452766994, 35.881915470570434],
              [-81.107281126532769, 35.882180109293344],
              [-81.106618789333169, 35.882096478440005],
              [-81.106566459533369, 35.882361116858277],
              [-81.106235290931963, 35.882319299483775],
              [-81.106182959731498, 35.882583937716461],
              [-81.105189450620188, 35.882458480719656],
              [-81.105241784993794, 35.88219384290138],
              [-81.104248283248779, 35.882068378202433],
              [-81.104195945701974, 35.882333015606363],
              [-81.103202443846214, 35.882207541494552],
              [-81.103097760270174, 35.882736815350192],
              [-81.102766591924379, 35.882694988564211],
              [-81.102714249117852, 35.882959625272946],
              [-81.102383078950069, 35.88291779746816],
              [-81.102330734719416, 35.883182433090035],
              [-81.101668394427747, 35.883098775387218],
              [-81.101616046631477, 35.883363410704312],
              [-81.100622535004149, 35.883237915645729],
              [-81.100570184822459, 35.883502551382726],
              [-81.100239013539138, 35.883460717765303],
              [-81.100186660825997, 35.883725352434553],
              [-81.098530806480284, 35.883516170122775],
              [-81.098478448135523, 35.883780804053607],
              [-81.097816106904375, 35.883697124550771],
              [-81.097763746101052, 35.883961758157533],
              [-81.096107893161161, 35.883752542073175],
              [-81.096055525618894, 35.884017174960512],
              [-81.093737339799972, 35.883724232222733],
              [-81.093406171147009, 35.883682380058438],
              [-81.093458547127497, 35.883417747375695],
              [-81.093127380959302, 35.883375893529795],
              [-81.093232134029535, 35.882846629198824],
              [-81.092900970343067, 35.882804775630795],
              [-81.092953347409477, 35.882540143081499],
              [-81.092622184016619, 35.882498288770996],
              [-81.092674561797523, 35.882233656312366],
              [-81.092343400889405, 35.882191800320307],
              [-81.092395780515403, 35.881927168834331],
              [-81.092064621008078, 35.881885312080811],
              [-81.092117000217868, 35.881620679803426],
              [-81.091785842134641, 35.881578823189606],
              [-81.091838222058968, 35.881314191002723],
              [-81.091507065353198, 35.881272332726354],
              [-81.091559446015324, 35.881007701531317],
              [-81.091228291817572, 35.880965842474531],
              [-81.09133305418078, 35.880436578415832],
              [-81.091001902441377, 35.880394718735865],
              [-81.091106665570436, 35.879865455664429],
              [-81.090775516289327, 35.879823595361316],
              [-81.090932662654211, 35.879029699441425],
              [-81.090601516888924, 35.878987838653401],
              [-81.090653899012622, 35.878723206729617],
              [-81.08999161065006, 35.878639483632305],
              [-81.090043994545653, 35.878374851937387],
              [-81.088057143969266, 35.878123659180872],
              [-81.088109534974052, 35.877859028249013],
              [-81.087447256285884, 35.877775289508754],
              [-81.087499649062437, 35.87751065880591],
              [-81.085512828734252, 35.877259423607605],
              [-81.085565228619501, 35.87699479366799],
              [-81.084902959653093, 35.876911041087261],
              [-81.084955361310051, 35.876646411376818],
              [-81.084624228410831, 35.876604534325935],
              [-81.084676630781956, 35.876339904706185],
              [-81.084345499260181, 35.876298025993009],
              [-81.084397902345444, 35.876033396464031],
              [-81.084066772247624, 35.875991517890625],
              [-81.08411917604694, 35.875726888452547],
              [-81.083788048433746, 35.87568500819782],
              [-81.083840451840089, 35.875420378869421],
              [-81.083509325627588, 35.875378497853433],
              [-81.083561729748041, 35.875113868615891],
              [-81.083230604959454, 35.875071987739716],
              [-81.08328301087785, 35.874807357672992],
              [-81.08295188749004, 35.874765476035648],
              [-81.083004294122517, 35.87450084605976],
              [-81.081679808187033, 35.874333309265992],
              [-81.08173221872886, 35.874068680697029],
              [-81.081069979935663, 35.873984907179562],
              [-81.081122392225723, 35.873720277938638],
              [-81.080460158027705, 35.873636501080881],
              [-81.080512572089006, 35.873371872069129],
              [-81.080181456020327, 35.873329982889295],
              [-81.080233870795709, 35.873065353968535],
              [-81.079902757211656, 35.873023463107494],
              [-81.079955172724183, 35.872758835178658],
              [-81.08006000269593, 35.872229578277633],
              [-81.079728892627102, 35.872187686932136],
              [-81.079886138169854, 35.871393801189285],
              [-81.079555031616181, 35.871351909359426],
              [-81.07960744826714, 35.871087280502699],
              [-81.078945237220395, 35.871003495341014],
              [-81.07899765564251, 35.87073886671368],
              [-81.078666552233372, 35.870696972461971],
              [-81.07871897026213, 35.870432343944408],
              [-81.078387868253699, 35.870390448931573],
              [-81.078440288103451, 35.870125820486166],
              [-81.078109187518677, 35.870083925613358],
              [-81.0782140271955, 35.869554668875892],
              [-81.077882929045316, 35.869512772479219],
              [-81.077935348872387, 35.869248144187168],
              [-81.077604253229879, 35.869206247010524],
              [-81.077656673770676, 35.868941618809565],
              [-81.077325579528789, 35.868899720871845],
              [-81.077378000783412, 35.868635092761799],
              [-81.077046907942091, 35.868593194063081],
              [-81.077099331017592, 35.868328566025198],
              [-81.076768238469612, 35.868286666584197],
              [-81.076820662258896, 35.868022038637349],
              [-81.076489572218534, 35.867980138416463],
              [-81.076541995614605, 35.867715510579515],
              [-81.075548730702181, 35.867589804034196],
              [-81.075601158033251, 35.86732517654621],
              [-81.074938985271586, 35.867241368562944],
              [-81.074991414350436, 35.866976740403381],
              [-81.074660329541828, 35.866934835201015],
              [-81.074765188829687, 35.866405580818764],
              [-81.074434106478691, 35.86636367499387],
              [-81.074643825115601, 35.865305164413058],
              [-81.074312747336322, 35.865263258242308],
              [-81.074365177205607, 35.864998631067756],
              [-81.074696252820885, 35.865040537118951],
              [-81.074801109392126, 35.864511281450127],
              [-81.074470035891039, 35.864469375675782],
              [-81.074522464706988, 35.864204747458352],
              [-81.074191391499298, 35.864162840941816],
              [-81.074348679081211, 35.863368957322066],
              [-81.074017610494991, 35.863327050302559],
              [-81.074174898157267, 35.862533166672719],
              [-81.073843833085448, 35.862491259169104],
              [-81.073896262668754, 35.862226631036094],
              [-81.07356519897418, 35.862184721870427],
              [-81.073617629294191, 35.861920094729719],
              [-81.07328656810698, 35.861878184784274],
              [-81.07333899911734, 35.861613556833568],
              [-81.073007938246604, 35.86157164704715],
              [-81.073060369970619, 35.861307019187706],
              [-81.072729310500236, 35.86126510864036],
              [-81.072834176138812, 35.86073585303776],
              [-81.072503119102606, 35.860693940966868],
              [-81.072555551933377, 35.860429314143701],
              [-81.071562389400029, 35.860303573794873],
              [-81.071614825034928, 35.860038946438721],
              [-81.071283772593276, 35.859997031028477],
              [-81.071231334794405, 35.860261658265003],
              [-81.07090028166229, 35.860219742718122],
              [-81.070847843570064, 35.860484369750189],
              [-81.070516789701486, 35.860442452264401],
              [-81.070464349101925, 35.860707079129519],
              [-81.070133294519806, 35.86066516060604],
              [-81.070080852519879, 35.860929787285507],
              [-81.068094523707913, 35.860678257366068],
              [-81.068146972072313, 35.860413632418449],
              [-81.067815919894571, 35.860371707599562],
              [-81.067868368949192, 35.860107081842159],
              [-81.067537318171929, 35.860065156262408],
              [-81.067642217364394, 35.859535904882861],
              [-81.067311169044373, 35.859493978680781],
              [-81.067468518427404, 35.858700101672909],
              [-81.067137474728298, 35.858658174968298],
              [-81.067189924892432, 35.858393549343091],
              [-81.066858881486795, 35.85835162189634],
              [-81.066911332364242, 35.858086996362452],
              [-81.066580291465797, 35.858045068136221],
              [-81.066685194303858, 35.857515817204018],
              [-81.066354155862541, 35.857473888355401],
              [-81.06651151069066, 35.856680011117774],
              [-81.066180475763218, 35.85663808178537],
              [-81.066390281603162, 35.855579578095281],
              [-81.066059251246244, 35.855537648417574],
              [-81.066216606308188, 35.854743771002632],
              [-81.065885579464975, 35.854701840841201],
              [-81.066095385224671, 35.853643336020255],
              [-81.065764362951768, 35.853601405513643],
              [-81.065816814307212, 35.853336779108602],
              [-81.065485794541374, 35.853294847822461],
              [-81.065538246609918, 35.853030221508931],
              [-81.064876210222096, 35.852946356515993],
              [-81.06492866406056, 35.852681730432337],
              [-81.064597647438561, 35.852639796725626],
              [-81.06475501119256, 35.85184591949043],
              [-81.064423998060988, 35.851803984398849],
              [-81.064528906895916, 35.851274733263459],
              [-81.064197896221103, 35.85123279754972],
              [-81.064250352275181, 35.850968171580739],
              [-81.0639193430235, 35.850926236007453],
              [-81.064024255107569, 35.850396984224155],
              [-81.063693248312603, 35.850355048028739],
              [-81.063798161112786, 35.84982579543334],
              [-81.063467156774479, 35.849783858615808],
              [-81.063676982502002, 35.848725354316372],
              [-81.063345983840279, 35.848683417135035],
              [-81.063555807191065, 35.84762491265694],
              [-81.063224813075934, 35.847582974229468],
              [-81.063487090631085, 35.84625984257832],
              [-81.063156103248716, 35.846217903925719],
              [-81.063208558352287, 35.845953277961158],
              [-81.062877571263073, 35.845911338566651],
              [-81.062930028186315, 35.845646712674991],
              [-81.062599042497169, 35.845604772519991],
              [-81.062651500133356, 35.845340146719963],
              [-81.062320515844064, 35.845298205804305],
              [-81.062372974170287, 35.845033579194869],
              [-81.062041992410613, 35.844991638401076],
              [-81.062094450342997, 35.844727011901945],
              [-81.061763469983347, 35.844685070347637],
              [-81.061868388037141, 35.844155817467197],
              [-81.061537410133809, 35.84411387529083],
              [-81.06158987025519, 35.843849248909443],
              [-81.061258893728791, 35.843807305071401],
              [-81.061416274094483, 35.843013426080589],
              [-81.061085301103731, 35.842971482660296],
              [-81.061190221373806, 35.84244223004881],
              [-81.060859250816293, 35.84240028510542],
              [-81.061226469972794, 35.84054789953084],
              [-81.061557432029218, 35.840589843522913],
              [-81.061662346093456, 35.840060589167521],
              [-81.062239347240748, 35.837149687347519],
              [-81.061908398915875, 35.837107745156672],
              [-81.062013306162783, 35.83657848986276],
              [-81.061682361400358, 35.836536547031344],
              [-81.06173481499998, 35.836271919011658],
              [-81.061403871637197, 35.836229975419855],
              [-81.061456325972571, 35.835965348393145],
              [-81.061125384009515, 35.835923404040884],
              [-81.061177839034642, 35.835658776204987],
              [-81.060846898471141, 35.835616831092274],
              [-81.060899355315726, 35.835352203329478],
              [-81.060568416174803, 35.835310258357573],
              [-81.060620872625378, 35.83504563070538],
              [-81.060289934861103, 35.83500368407185],
              [-81.060342393131123, 35.834739056492857],
              [-81.060011456766361, 35.834697109099025],
              [-81.060116373281659, 35.834167854096933],
              [-81.059785439372632, 35.834125906081226],
              [-81.059890357732911, 35.833596651150948],
              [-81.059559426279762, 35.833554702513467],
              [-81.059611886000766, 35.833290075116977],
              [-81.059280955947102, 35.833248125719123],
              [-81.059438335088274, 35.83245424278298],
              [-81.059107409652867, 35.832412292883262],
              [-81.059212329121578, 35.831883038243561],
              [-81.058881406141865, 35.831841087722168],
              [-81.058933866947484, 35.831576459560445],
              [-81.059264788871133, 35.831618409943324],
              [-81.059317247193505, 35.831353782516238],
              [-81.058986326325993, 35.831311832272036],
              [-81.0593010797418, 35.829724064784678],
              [-81.058970165553816, 35.829682114473073],
              [-81.059284912918969, 35.828094345239649],
              [-81.059615819664486, 35.828136294738279],
              [-81.059668274555335, 35.827871665943306],
              [-81.059999180588292, 35.827913614404537],
              [-81.05989427257515, 35.82844287222516],
              [-81.061217909672365, 35.828610657269898],
              [-81.061322810367216, 35.828081399222988],
              [-81.061653718094135, 35.828123342288613],
              [-81.061706166328833, 35.827858713516733],
              [-81.062037073343149, 35.827900655544816],
              [-81.062141966647417, 35.827371396683226],
              [-81.062472871893149, 35.827413337535276],
              [-81.062525316962848, 35.827148707445552],
              [-81.062856221518942, 35.827190648161348],
              [-81.063013550393421, 35.82639675721606],
              [-81.06334445212498, 35.826438696617274],
              [-81.063501774718816, 35.825644803936861],
              [-81.063832673625868, 35.825686742023407],
              [-81.063989989962096, 35.82489284850908],
              [-81.064320884938169, 35.824934785299888],
              [-81.064478195017159, 35.824140890951739],
              [-81.064809087168797, 35.824182826427993],
              [-81.064966389861056, 35.823388930363954],
              [-81.065297279188286, 35.823430864525704],
              [-81.065402144198302, 35.822901600267933],
              [-81.065733031733984, 35.822943532352596],
              [-81.065785462126584, 35.822678900475445],
              [-81.066116348949635, 35.822720831522716],
              [-81.066168777943147, 35.822456199460611],
              [-81.066499664053467, 35.822498129470524],
              [-81.066552091624843, 35.822233496322426],
              [-81.066882977045637, 35.822275426196079],
              [-81.066987829070015, 35.82174616038472],
              [-81.067318712699247, 35.821788088181307],
              [-81.067685674312713, 35.819935653195976],
              [-81.068016549787941, 35.819977579142751],
              [-81.068068970759896, 35.819712945115448],
              [-81.068399845545443, 35.819754870925962],
              [-81.068504683241912, 35.819225602474106],
              [-81.068835557342354, 35.819267526188817],
              [-81.06894039155469, 35.818738257274553],
              [-81.069271262780262, 35.818780179832274],
              [-81.069323677762711, 35.818515545176524],
              [-81.069654549382108, 35.818557466678229],
              [-81.069759376206179, 35.818028196950785],
              [-81.070090244950634, 35.81807011729542],
              [-81.07019506826768, 35.817540846204672],
              [-81.070525935266772, 35.817582766274633],
              [-81.070630755099955, 35.817053494721755],
              [-81.070683164490347, 35.816788858425419],
              [-81.071014028665232, 35.816830777180982],
              [-81.071066436656906, 35.816566140699813],
              [-81.07172816390127, 35.816649974336435],
              [-81.071780569438374, 35.816385337532061],
              [-81.07211143253086, 35.816427253314195],
              [-81.07216383556279, 35.816162616343888],
              [-81.072825560619322, 35.816246443132719],
              [-81.07287796121976, 35.815981806740254],
              [-81.073539685536957, 35.816065629656983],
              [-81.073592084766162, 35.815800992021401],
              [-81.07458466956389, 35.815926720160107],
              [-81.074637064176486, 35.81566208208168],
              [-81.074967925766998, 35.815703989218584],
              [-81.075020318980975, 35.81543935095538],
              [-81.075682041079375, 35.815523162255907],
              [-81.075734431838796, 35.815258523669542],
              [-81.076396152091135, 35.815342331116895],
              [-81.076500928381435, 35.814813054152864],
              [-81.076831786357431, 35.814854956260881],
              [-81.077041329447439, 35.81379639944128],
              [-81.07737218464969, 35.813838300077819],
              [-81.077476951472661, 35.81330902112348],
              [-81.077146098381817, 35.813267120763854],
              [-81.077408015247116, 35.811943922352093],
              [-81.077738861953193, 35.811985822038189],
              [-81.077791243689148, 35.811721182251674],
              [-81.078122089682424, 35.811763080900661],
              [-81.078174468890268, 35.811498440047281],
              [-81.078505314170741, 35.811540337659075],
              [-81.078557692003329, 35.811275697522191],
              [-81.078888536570986, 35.81131759409682],
              [-81.078940912981906, 35.811052952874206],
              [-81.079271756836746, 35.811094848411656],
              [-81.079376506541848, 35.810565566452503],
              [-81.079707348628219, 35.81060746081431],
              [-81.079759721922528, 35.810342820077764],
              [-81.07986446745943, 35.809813537565191],
              [-81.080195306698499, 35.809855429711803],
              [-81.080404788329901, 35.8087968635989],
              [-81.080073954396397, 35.808754971086032],
              [-81.080388173400252, 35.807167119554158],
              [-81.0800573461662, 35.807125227874479],
              [-81.080162085564069, 35.806595943632466],
              [-81.079169612182383, 35.806470261328442],
              [-81.079221984533817, 35.806205619553779],
              [-81.078229518533163, 35.806079930459362],
              [-81.078281894814509, 35.805815289035216],
              [-81.07762025388628, 35.805731491501014],
              [-81.077882137364256, 35.80440828423081],
              [-81.078212951273287, 35.80445018277377],
              [-81.078317699278159, 35.803920899642506],
              [-81.078648511419146, 35.803962797009888],
              [-81.079015111180865, 35.802110300930664],
              [-81.079345915170251, 35.802152196449065],
              [-81.079450652737123, 35.801622910134896],
              [-81.079781456064609, 35.801664804458902],
              [-81.079833822195567, 35.801400162014559],
              [-81.080164624787201, 35.801442054400368],
              [-81.080216990626099, 35.801177411752732],
              [-81.080547791422163, 35.801219304021487],
              [-81.080600155839747, 35.800954660288369],
              [-81.080930955923023, 35.80099655152015],
              [-81.080983318942586, 35.800731907602696],
              [-81.081644919109422, 35.800815686173301],
              [-81.081697278592529, 35.800551042853215],
              [-81.082028077569902, 35.800592930211103],
              [-81.082080436737783, 35.800328285786563],
              [-81.082411235025745, 35.800370173008616],
              [-81.08246359168939, 35.800105528418705],
              [-81.08279439037068, 35.800147414584742],
              [-81.082846745636374, 35.799882769810608],
              [-81.083177542498774, 35.799924654958453],
              [-81.083282249891354, 35.799395364995725],
              [-81.083613044962419, 35.799437248067065],
              [-81.08366539708932, 35.799172602878521],
              [-81.083996191470945, 35.799214485813984],
              [-81.084100892586221, 35.798685195022522],
              [-81.084431685176412, 35.798727075881416],
              [-81.084588730007425, 35.797933138954228],
              [-81.084919519774246, 35.797975018499258],
              [-81.085076558355951, 35.797181080744664],
              [-81.084745771731932, 35.79713920071373],
              [-81.084955156278156, 35.796080616046112],
              [-81.08462437421808, 35.796038735670379],
              [-81.084781411914108, 35.795244796883821],
              [-81.085112189678981, 35.795286675962174],
              [-81.085216877276608, 35.794757382638217],
              [-81.085547653273395, 35.794799260541176],
              [-81.08570467947618, 35.794005319336968],
              [-81.085373906645088, 35.793963441849257],
              [-81.08542624833774, 35.793698794540347],
              [-81.085095476881193, 35.793656915391452],
              [-81.085147819309682, 35.793392269076413],
              [-81.084155511290376, 35.793266627535829],
              [-81.084207856518773, 35.79300198068929],
              [-81.083546322038913, 35.792918215740585],
              [-81.083598670141214, 35.792653569106314],
              [-81.083267903905721, 35.79261168498153],
              [-81.083320252720739, 35.792347038439978],
              [-81.082658724517174, 35.792263268654004],
              [-81.082711075099866, 35.791998622343641],
              [-81.08204955035383, 35.791914848339452],
              [-81.082258956594217, 35.790856263767644],
              [-81.082589715128307, 35.790898150655757],
              [-81.082642063947318, 35.790633504274332],
              [-81.082972821745628, 35.790675389224369],
              [-81.083025169166916, 35.790410742658949],
              [-81.082694413506459, 35.790368856927302],
              [-81.08279911164361, 35.789839563898589],
              [-81.082468358435975, 35.789797677545401],
              [-81.08252070804582, 35.789533031100973],
              [-81.082189956235965, 35.789491143987725],
              [-81.082242306558328, 35.789226497636207],
              [-81.081911556146068, 35.789184609762962],
              [-81.082016257850057, 35.788655316298737],
              [-81.081685509890576, 35.788613427803973],
              [-81.081790212334397, 35.7880841344342],
              [-81.081459466827638, 35.788042245317897],
              [-81.081564170011191, 35.787512952042768],
              [-81.081233428063214, 35.787471062286059],
              [-81.081285780207793, 35.78720641616907],
              [-81.080624298343707, 35.787122634256228],
              [-81.079301339809433, 35.786955058729333],
              [-81.079353699047573, 35.786690413378722],
              [-81.079022960501803, 35.786648517628848],
              [-81.079075320429155, 35.786383871470093],
              [-81.077752378293411, 35.786216279063773],
              [-81.07780474099215, 35.78595163343229],
              [-81.077143275207703, 35.785867832547382],
              [-81.077195639673661, 35.785603187147338],
              [-81.076864908903389, 35.785561285486374],
              [-81.076917274081651, 35.785296640179162],
              [-81.076255814549896, 35.785212833556869],
              [-81.076308182601451, 35.784948188462359],
              [-81.074985271892928, 35.784780565892156],
              [-81.074932898492605, 35.785045209550283],
              [-81.073940716441925, 35.784919482539408],
              [-81.073993091878037, 35.784654838414497],
              [-81.073662366621349, 35.784612927145666],
              [-81.073714742792774, 35.784348284014875],
              [-81.073384018933808, 35.784306371986141],
              [-81.07348877231199, 35.78377708406282],
              [-81.073158050928654, 35.783735172313996],
              [-81.073262806151646, 35.783205884466831],
              [-81.072932086091782, 35.78316397121435],
              [-81.072984464797059, 35.782899327351579],
              [-81.072653747240651, 35.782857413320535],
              [-81.072758504626734, 35.782328125754482],
              [-81.072427789522848, 35.782286211102118],
              [-81.07248016820364, 35.782021567398843],
              [-81.072149454497335, 35.781979651986681],
              [-81.072201834996235, 35.781715008357565],
              [-81.071871122687512, 35.781673092185713],
              [-81.07192350389839, 35.781408448649664],
              [-81.071592792987232, 35.781366531717957],
              [-81.071645174910287, 35.781101888275067],
              [-81.071314465396682, 35.781059970583591],
              [-81.071366848031701, 35.780795327233712],
              [-81.071036139915662, 35.780753408782552],
              [-81.071140905138165, 35.780224121341256],
              [-81.070810200603418, 35.780182203151192],
              [-81.070862584296691, 35.779917559040989],
              [-81.070531881136404, 35.779875639190024],
              [-81.070584264458958, 35.779610996092813],
              [-81.070253562696109, 35.7795690754822],
              [-81.070358332611193, 35.779039788489925],
              [-81.070027633300583, 35.778997867258099],
              [-81.070080017681335, 35.778733223400565],
              [-81.06974931976832, 35.778691301409083],
              [-81.069801705989889, 35.778426658527067],
              [-81.069471009474356, 35.778384735775802],
              [-81.069523396384909, 35.778120092085857],
              [-81.069192701266886, 35.778078168574893],
              [-81.069297476192077, 35.777548882237106],
              [-81.068966784632082, 35.777506958086263],
              [-81.069019172623655, 35.777242314537183],
              [-81.068688481355252, 35.777200389645436],
              [-81.068793258419547, 35.77667110268839],
              [-81.068462569603298, 35.77662917717565],
              [-81.06856734740586, 35.776099890314235],
              [-81.068236662147541, 35.776057964161552],
              [-81.068341440688215, 35.775528677395833],
              [-81.068498605928681, 35.774734746907065],
              [-81.068829285913807, 35.774776672366748],
              [-81.068881672587196, 35.774512028641134],
              [-81.069212351860443, 35.774553953063993],
              [-81.069264736030661, 35.774289309173327],
              [-81.069595414592044, 35.774331232559362],
              [-81.069647798470612, 35.774066588465999],
              [-81.069978476320117, 35.774108510815132],
              [-81.070030857695656, 35.773843866556618],
              [-81.070692212313574, 35.773927708283203],
              [-81.070744592342976, 35.773663063683372],
              [-81.071075268005586, 35.77370498307954],
              [-81.071127646614599, 35.773440337394632],
              [-81.071458321565302, 35.773482255754089],
              [-81.071510698800012, 35.773217610786439],
              [-81.071841373038779, 35.77325952810903],
              [-81.071946123242299, 35.772730236878466],
              [-81.072276795714544, 35.772772153025677],
              [-81.072381542438137, 35.772242861336792],
              [-81.072712213143859, 35.77228477630868],
              [-81.072764584372621, 35.772020129816617],
              [-81.073095255472268, 35.772062043732838],
              [-81.073199993710119, 35.771532751255769],
              [-81.073530663043172, 35.771574663996716],
              [-81.073740132271851, 35.77051607702515],
              [-81.073409468262795, 35.770474164819603],
              [-81.073828398688363, 35.768356988912842],
              [-81.074159054261358, 35.768398900009934],
              [-81.074368505893716, 35.767340309084716],
              [-81.074699157591255, 35.767382218729338],
              [-81.074803879231553, 35.76685292226805],
              [-81.075134528057021, 35.766894830756272],
              [-81.075239246218288, 35.766365533837202],
              [-81.07556989327739, 35.766407441150314],
              [-81.075674606853838, 35.765878143792406],
              [-81.076005252146587, 35.76592004993023],
              [-81.076109963349793, 35.765390752095875],
              [-81.076440606876261, 35.765432657058362],
              [-81.076650018720315, 35.764374059430757],
              [-81.076319379434821, 35.764332155923583],
              [-81.0763717325882, 35.764067506317119],
              [-81.076702370842511, 35.764109410586983],
              [-81.076807074013118, 35.763580110961819],
              [-81.077137710501233, 35.763622014056487],
              [-81.077190059412729, 35.763357364056638],
              [-81.077520695188824, 35.763399266114583],
              [-81.077625392087057, 35.762869965664876],
              [-81.077956024991295, 35.762911866566583],
              [-81.078008371872329, 35.762647216135541],
              [-81.07833900404141, 35.762689115099462],
              [-81.078391349525702, 35.762424464484923],
              [-81.078721981005998, 35.76246636331328],
              [-81.078774325093491, 35.762201712515143],
              [-81.079104955861851, 35.762243610306946],
              [-81.079157298552573, 35.761978959325255],
              [-81.07948792858582, 35.762020855179301],
              [-81.07959260972558, 35.761491552822569],
              [-81.079923237992517, 35.761533447501499],
              [-81.079975576441683, 35.761268796126487],
              [-81.08030620401982, 35.76131069066993],
              [-81.080410878864669, 35.760781386587887],
              [-81.08074150467651, 35.760823279956192],
              [-81.080793839989866, 35.760558628169235],
              [-81.081124465066551, 35.760600519599812],
              [-81.081176798983208, 35.76033586762923],
              [-81.081507423347915, 35.760377758023232],
              [-81.081559755867886, 35.760113105869237],
              [-81.081890379520601, 35.760154995226571],
              [-81.08194271064383, 35.759890342889079],
              [-81.082273333584638, 35.759932231209902],
              [-81.082325662205591, 35.759667578707855],
              [-81.082656284434407, 35.759709465992103],
              [-81.082708612764335, 35.759444813287601],
              [-81.083039234281031, 35.759486699535273],
              [-81.083091560108826, 35.759222046666288],
              [-81.083752802060502, 35.759305816190405],
              [-81.083805125437365, 35.759041162999438],
              [-81.084135745872317, 35.759083046275883],
              [-81.084240390572873, 35.758553738562107],
              [-81.084571009241458, 35.758595620663513],
              [-81.084623329459674, 35.758330966159654],
              [-81.085615183273802, 35.758456607580072],
              [-81.085667499986727, 35.758191952615839],
              [-81.085998116810131, 35.758233830866963],
              [-81.086050433232188, 35.757969175700396],
              [-81.08737290196521, 35.758136680033502],
              [-81.087425212722238, 35.757872024287131],
              [-81.088086447021652, 35.757955770337702],
              [-81.0881387542223, 35.757691114288477],
              [-81.088469371383738, 35.75773298581877],
              [-81.088521678293475, 35.75746832956721],
              [-81.090505378781657, 35.757719538214438],
              [-81.090609976711619, 35.757190223953245],
              [-81.090940593105131, 35.757232088781713],
              [-81.090992890502676, 35.756967431445304],
              [-81.091323505078407, 35.75700929525626],
              [-81.091375801079536, 35.756744637736617],
              [-81.091706416048623, 35.756786500492105],
              [-81.091758710653252, 35.756521842789176],
              [-81.092089323781337, 35.756563703625986],
              [-81.092141616989679, 35.756299045739674],
              [-81.092802843292148, 35.756382765324474],
              [-81.092855132944138, 35.756118107135471],
              [-81.099798071153018, 35.756996939801198],
              [-81.099745803642222, 35.757261600896008],
              [-81.100407046758335, 35.757345278248685],
              [-81.100354782119965, 35.757609939556211],
              [-81.101016027606917, 35.757693613612183],
              [-81.100963764735368, 35.757958275151509],
              [-81.101294389598948, 35.758000110961135],
              [-81.101189864939101, 35.75852943418181],
              [-81.101851119909455, 35.758613103659954],
              [-81.101798859175602, 35.758877765479632],
              [-81.102129488228044, 35.758919599009864],
              [-81.102077228206667, 35.759184260922908],
              [-81.102738490528338, 35.759267924645386],
              [-81.103399754240812, 35.759351585676399],
              [-81.103452010044279, 35.759086923210113],
              [-81.103782640806116, 35.759128752249708],
              [-81.103834895213282, 35.758864089600216],
              [-81.105818682776487, 35.759115041405288],
              [-81.105870929432911, 35.758850378801412],
              [-81.106532192948023, 35.758934021935083],
              [-81.106636682076385, 35.758404694218015],
              [-81.106967311673202, 35.758446513720067],
              [-81.107019554117414, 35.758181849665455],
              [-81.107350183024906, 35.75822366903224],
              [-81.107402424073001, 35.757959004794586],
              [-81.107733052244313, 35.758000822223806],
              [-81.107785291896107, 35.757736157802938],
              [-81.108115920460349, 35.757777974176634],
              [-81.108168157610393, 35.757513309591836],
              [-81.108498785461919, 35.757555124929141],
              [-81.108551021215774, 35.757290460161151],
              [-81.109212274752906, 35.75737408878512],
              [-81.109264508056157, 35.757109423695852],
              [-81.109925759722145, 35.75719304756872],
              [-81.109977991680353, 35.756928382138945],
              [-81.11229237702176, 35.757221037317407],
              [-81.11234460015163, 35.756956370894187],
              [-81.113667109819602, 35.757123581589703],
              [-81.113719327284315, 35.756858914587987],
              [-81.114380582611844, 35.756942514260864],
              [-81.114432797602447, 35.756677846036823],
              [-81.114763425276934, 35.756719644378244],
              [-81.114815638895152, 35.75645497687222],
              [-81.11514626475109, 35.756496774196513],
              [-81.115198478055163, 35.75623210558723],
              [-81.115529103198284, 35.756273901875154],
              [-81.115581314024439, 35.756009234003116],
              [-81.115911938454559, 35.756051029254749],
              [-81.115964148966583, 35.755786360279423],
              [-81.116294772683787, 35.755828154494722],
              [-81.116346980694345, 35.755563485355609],
              [-81.117008227044508, 35.755647070815314],
              [-81.117060432604532, 35.75538240135505],
              [-81.118052301504122, 35.755507771493029],
              [-81.118000099107405, 35.755772441367867],
              [-81.118661348849074, 35.755856016945089],
              [-81.118713549160333, 35.755591347695059],
              [-81.11904417347705, 35.755633133547761],
              [-81.119096371286915, 35.75536846413403],
              [-81.119426994890645, 35.755410248950419],
              [-81.119374798158944, 35.755674919403454],
              [-81.120697300253639, 35.755842052042617],
              [-81.12064510739836, 35.756106723003541],
              [-81.121636990706222, 35.756232063016284],
              [-81.121584800649416, 35.756496733445736],
              [-81.122246061117607, 35.756580289520876],
              [-81.122193871746518, 35.756844961102239],
              [-81.12285513566566, 35.756928512959874],
              [-81.122802949167905, 35.757193184753433],
              [-81.123133583246911, 35.75723495946351],
              [-81.123029211312542, 35.757764302291399],
              [-81.123359846736491, 35.757806076398843],
              [-81.123307661299904, 35.758070747433095],
              [-81.123638299225391, 35.758112520761223],
              [-81.123533929460933, 35.758641863872697],
              [-81.123864569836982, 35.758683636578894],
              [-81.123708015781716, 35.759477650873542],
              [-81.124038659662986, 35.759519423095988],
              [-81.123882105675321, 35.760313436500297],
              [-81.124212753061954, 35.760355208238813],
              [-81.124056200271212, 35.761149221634362],
              [-81.12438685116301, 35.761190992888949],
              [-81.124282482301112, 35.761720335217341],
              [-81.12461313674936, 35.761762105830719],
              [-81.124560952861032, 35.762026777065643],
              [-81.126875548729416, 35.762319147214036],
              [-81.126823372964452, 35.762583818450324],
              [-81.127154032253245, 35.762625582153966],
              [-81.127101856119694, 35.762890254403914],
              [-81.127432516804475, 35.762932017347488],
              [-81.127380342466353, 35.76319668877013],
              [-81.127711004547209, 35.763238450953466],
              [-81.127658829840442, 35.763503123389704],
              [-81.127989494423062, 35.763544884793525],
              [-81.127937320406062, 35.763809556421812],
              [-81.128267985279138, 35.763851317084786],
              [-81.128215813104831, 35.764115989688044],
              [-81.130199818003689, 35.764366533789634],
              [-81.13025198384932, 35.764101860357904],
              [-81.132897335831117, 35.764435866524629],
              [-81.132845179505878, 35.764700540140154],
              [-81.135159888306177, 35.764992747980571],
              [-81.135107739047299, 35.765257423418511],
              [-81.137091796494275, 35.765507851500765],
              [-81.138745188003526, 35.765716516849437],
              [-81.138797325682688, 35.765451840794739],
              [-81.139458682540607, 35.765535299468802],
              [-81.139510816663332, 35.765270623112642],
              [-81.139841494560983, 35.765312351414842],
              [-81.139893627263874, 35.765047673974614],
              [-81.142208374665927, 35.765339744168834],
              [-81.142156249347281, 35.765604422574775],
              [-81.142817610916879, 35.765687863006491],
              [-81.142765488472804, 35.765952541623989],
              [-81.143096170812129, 35.765994260179745],
              [-81.143044047976161, 35.766258938909644],
              [-81.143374732817136, 35.766300656685516],
              [-81.143322610695165, 35.766565335508425],
              [-81.143653295826425, 35.76660705254335],
              [-81.143601175523983, 35.76687173143975],
              [-81.14393186205119, 35.766913447714337],
              [-81.143879742462673, 35.767178126703755],
              [-81.144210430385868, 35.76721984221782],
              [-81.144158311487516, 35.767484520399073],
              [-81.144819691596453, 35.767567949889688],
              [-81.144767574490928, 35.767832629202935],
              [-81.145098266652695, 35.767874342278326],
              [-81.145046149131559, 35.768139020802863],
              [-81.145707536612164, 35.768222445435477],
              [-81.145655421965742, 35.768487124171479],
              [-81.145986117260591, 35.768528834827571],
              [-81.145934003352011, 35.768793514557558],
              [-81.146264700042849, 35.768835224453177],
              [-81.146212585718672, 35.769099903394419],
              [-81.146873983362994, 35.769183321647986],
              [-81.14682187191373, 35.769448000800644],
              [-81.147152571737564, 35.769489708276843],
              [-81.147100461002367, 35.769754387522369],
              [-81.147431162222134, 35.769796094238153],
              [-81.147379052201018, 35.77006077357651],
              [-81.147709754816901, 35.770102479531793],
              [-81.147657646615514, 35.770367158943607],
              [-81.147988350651374, 35.770408865039435],
              [-81.147936242058464, 35.770673544563607],
              [-81.148266948572157, 35.770715248978355],
              [-81.148214840693299, 35.770979928595352],
              [-81.148545547497292, 35.771021632269054],
              [-81.148493441438319, 35.771286311959386],
              [-81.148824149638457, 35.771328014872573],
              [-81.148772044269705, 35.77159269375467],
              [-81.14910275386579, 35.771634395907235],
              [-81.148998543157816, 35.772163755633784],
              [-81.149329256310949, 35.772205457144246],
              [-81.149225046349457, 35.772734816966334],
              [-81.149555761929875, 35.772776516952973],
              [-81.149503656939984, 35.773041196944007],
              [-81.149834373940337, 35.773082897071205],
              [-81.149730166129871, 35.773612256273246],
              [-81.15006088558151, 35.773653955777718],
              [-81.15000878278498, 35.773918635889736],
              [-81.15033950363275, 35.773960334633614],
              [-81.150287400420638, 35.77422501395683],
              [-81.150618123770329, 35.774266711920589],
              [-81.15067022482144, 35.774002032479046],
              [-81.15166239151489, 35.774127119703898],
              [-81.15171449014133, 35.773862438882929],
              [-81.152375933476648, 35.773945826211921],
              [-81.152428029651688, 35.773681145070292],
              [-81.153420195127481, 35.77380621799103],
              [-81.153472285608473, 35.77354153733102],
              [-81.154133729213541, 35.773624914203225],
              [-81.154185818325047, 35.773360232301791],
              [-81.155177982582444, 35.77348529091848],
              [-81.155230068187606, 35.773220608558489],
              [-81.156222232322648, 35.77334565777447],
              [-81.156274313339622, 35.773080975876681],
              [-81.157266477376297, 35.773206016592994],
              [-81.157214399549005, 35.773470699805351],
              [-81.157545122254717, 35.773512378091127],
              [-81.157388889869821, 35.774306426970078],
              [-81.15771961608209, 35.774348104770795],
              [-81.157615461557441, 35.774877470439989],
              [-81.15794619135076, 35.774919148499343],
              [-81.15789411462049, 35.775183830953488],
              [-81.158224845785895, 35.775225507350996],
              [-81.158172769794206, 35.775490190798976],
              [-81.158834234151897, 35.775573541193687],
              [-81.158782159930013, 35.77583822487221],
              [-81.15911289533409, 35.775879898830105],
              [-81.159060821802697, 35.776144581700187],
              [-81.159391557496946, 35.776186254917043],
              [-81.159339484704148, 35.77645093878084],
              [-81.160662436215787, 35.776617623194532],
              [-81.160714503705449, 35.776352939700296],
              [-81.161045242209298, 35.776394608405013],
              [-81.161097308278841, 35.776129923826772],
              [-81.16142804496225, 35.776171591514746],
              [-81.161480110765652, 35.775906907635161],
              [-81.161810846734369, 35.775948574286907],
              [-81.162141583043876, 35.775990240040024],
              [-81.162193646371833, 35.775725554938752],
              [-81.162855117902168, 35.775808883474021],
              [-81.162803057766467, 35.776073567930091],
              [-81.163133795097934, 35.776115230987635],
              [-81.163081735700999, 35.776379916437506],
              [-81.163743213472586, 35.776463239231461],
              [-81.163691155845697, 35.77672792491164],
              [-81.164352638196348, 35.776811244367316],
              [-81.164300582339607, 35.777075930277846],
              [-81.166285045029085, 35.777325868787777],
              [-81.166337093447822, 35.77706118207054],
              [-81.167660071517446, 35.777227788614624],
              [-81.167608026214467, 35.777492475902413],
              [-81.167938772903113, 35.777534124974267],
              [-81.167990816068993, 35.777269438469666],
              [-81.168652308381311, 35.777352734543392],
              [-81.168600266220409, 35.777617421343152],
              [-81.169592510397194, 35.777742358677081],
              [-81.169540472167924, 35.778007045825142],
              [-81.169871221979491, 35.778048690544452],
              [-81.169819184465027, 35.778313377784968],
              [-81.170149935648396, 35.778355020842255],
              [-81.170097898848823, 35.778619708175398],
              [-81.170428651452454, 35.778661351372932],
              [-81.17032457894264, 35.779190726179024],
              [-81.170655333973443, 35.779232367852188],
              [-81.170447190219448, 35.780291118354839],
              [-81.170777949812731, 35.780332759680107],
              [-81.170725913795934, 35.780597447110132],
              [-81.171056675891037, 35.78063908765477],
              [-81.171004640589132, 35.780903775177364],
              [-81.171335402974606, 35.780945414980707],
              [-81.171283369493565, 35.781210102576068],
              [-81.171944898502801, 35.781293379742635],
              [-81.171996930978636, 35.781028691852235],
              [-81.172989224432769, 35.781153600439644],
              [-81.172937195123524, 35.781418288742941],
              [-81.173267961713364, 35.781459923272593],
              [-81.173215934225084, 35.781724611648713],
              [-81.173877470537349, 35.781807878287403],
              [-81.173825443713795, 35.782072566913264],
              [-81.17415621343622, 35.782114199022317],
              [-81.174104188433745, 35.782378887720959],
              [-81.174434959528, 35.782420518167868],
              [-81.174382935240544, 35.782685206958888],
              [-81.174713707755075, 35.782726837545923],
              [-81.174661684182752, 35.78299152642937],
              [-81.174992458093357, 35.783033156255371],
              [-81.174940435211937, 35.783297844330178],
              [-81.17560198616583, 35.783381101561545],
              [-81.175549965055168, 35.783645789866277],
              [-81.175880743204289, 35.783687417251855],
              [-81.175828722833145, 35.783952106550103],
              [-81.176159501272338, 35.783993733194393],
              [-81.176107481592126, 35.784258421683987],
              [-81.176438262533424, 35.784300047547461],
              [-81.176386243568331, 35.784564736129376],
              [-81.1767170247998, 35.784606361251548],
              [-81.176665006549896, 35.784871049925812],
              [-81.176995790283399, 35.784912674267176],
              [-81.176943772772987, 35.785177363935006],
              [-81.177274557878434, 35.785218986614098],
              [-81.177170523923394, 35.785748365187985],
              [-81.177501311504813, 35.785789988144856],
              [-81.177293244814024, 35.786848744378815],
              [-81.177624036934461, 35.786890366086219],
              [-81.177572021289762, 35.787155054928974],
              [-81.177902814830631, 35.787196676776375],
              [-81.177798782419572, 35.787726054640792],
              [-81.178129579518313, 35.787767675844918],
              [-81.177869498917431, 35.789091120381073],
              [-81.178200301611298, 35.789132740473313],
              [-81.178148285304374, 35.789397429386078],
              [-81.179802308101443, 35.789605518876151],
              [-81.179854319129277, 35.789340829275481],
              [-81.180846733277875, 35.789465671599586],
              [-81.180742719428508, 35.789995051541439],
              [-81.18107352729767, 35.790036663820189],
              [-81.181021520918236, 35.790301353860677],
              [-81.181352330183628, 35.790342965378194],
              [-81.181300324519725, 35.790607655510854],
              [-81.183616008535211, 35.790898912792386],
              [-81.183564009921909, 35.791163603842563],
              [-81.183894824021934, 35.791205208445092],
              [-81.183842826099919, 35.791469898686294],
              [-81.184173642702092, 35.791511502507618],
              [-81.184121645519966, 35.791776193742209],
              [-81.184452463518483, 35.791817796802349],
              [-81.18440046705183, 35.79208248812899],
              [-81.18473128642222, 35.792124089526844],
              [-81.184627294579869, 35.792653472319031],
              [-81.184958116426785, 35.792695073994196],
              [-81.184906122132602, 35.792959764538743],
              [-81.185236945375863, 35.793001365452646],
              [-81.185184950691351, 35.793266056109225],
              [-81.185515775330785, 35.793307656261817],
              [-81.185307799674135, 35.794366420746528],
              [-81.185638628877768, 35.794408020550463],
              [-81.185586634603538, 35.794672711254918],
              [-81.185917466309533, 35.794714310277612],
              [-81.185657497350007, 35.796037764667417],
              [-81.185988333570307, 35.796079363498698],
              [-81.185936340472182, 35.796344054185631],
              [-81.186267179170571, 35.796385651334624],
              [-81.186215185682158, 35.796650342133503],
              [-81.186876866260704, 35.79673353581213],
              [-81.186824874543987, 35.796998226840465],
              [-81.187155716399801, 35.79703982246901],
              [-81.187103726480544, 35.797304512668475],
              [-81.18743456973273, 35.797346107535567],
              [-81.187382580553603, 35.797610798728144],
              [-81.187713425202176, 35.797652392833953],
              [-81.187609446804657, 35.798181774476205],
              [-81.18794029390574, 35.798223367958187],
              [-81.187836317389753, 35.798752750574643],
              [-81.188167166918959, 35.798794342531508],
              [-81.187959212897155, 35.799853106886999],
              [-81.188290068121603, 35.799894699376203],
              [-81.188082111748784, 35.800953462671643],
              [-81.187751253405764, 35.800911869612627],
              [-81.187595278843332, 35.801705941653751],
              [-81.187926141436662, 35.801747534204239],
              [-81.187822160272404, 35.802276915285233],
              [-81.187491294436143, 35.802235321578507],
              [-81.187179333070532, 35.803823461159297],
              [-81.186848462367834, 35.803781866609889],
              [-81.186640475969313, 35.804840624286989],
              [-81.186971352003212, 35.804882219366434],
              [-81.18686735832101, 35.805411598215954],
              [-81.187198236832216, 35.805453193572539],
              [-81.187094244982873, 35.80598257159339],
              [-81.187425125947144, 35.806024166325898],
              [-81.187269138248183, 35.806818233887562],
              [-81.1871651451319, 35.807347612627588],
              [-81.187496031693783, 35.807389206247471],
              [-81.187392039304214, 35.807918584178537],
              [-81.187722928343462, 35.807960178075518],
              [-81.187670933235367, 35.808224866649411],
              [-81.188001823671357, 35.808266459784868],
              [-81.187897833464945, 35.808795837991475],
              [-81.188228727435856, 35.808837429581871],
              [-81.18812473798063, 35.809366807880487],
              [-81.188455633298545, 35.809408398866672],
              [-81.188299650272825, 35.810202465923318],
              [-81.188630550206625, 35.810244056403022],
              [-81.188578555518973, 35.810508746081801],
              [-81.188909456849672, 35.810550335799938],
              [-81.18880546962373, 35.811079713473084],
              [-81.189136374538279, 35.81112130344836],
              [-81.189084380918032, 35.811385992363675],
              [-81.189415287205037, 35.811427580676167],
              [-81.189311302162636, 35.8119569586244],
              [-81.189642209796673, 35.811998546332788],
              [-81.189590218374377, 35.812263235365684],
              [-81.189921127429685, 35.812304823213488],
              [-81.189869136723672, 35.812569512338094],
              [-81.190200048257736, 35.812611098503233],
              [-81.190148057161437, 35.81287578773938],
              [-81.190478970092386, 35.812917373142945],
              [-81.19037499120428, 35.813446751712689],
              [-81.190705905482332, 35.813488336512066],
              [-81.190653916584324, 35.813753025865637],
              [-81.190984833365576, 35.813794609883402],
              [-81.190932845183639, 35.814059299328576],
              [-81.191263763361803, 35.814100882584761],
              [-81.191159788065491, 35.814630260711333],
              [-81.191490708697131, 35.81467184334322],
              [-81.19143872160717, 35.814936532925699],
              [-81.191769643635752, 35.814978114796006],
              [-81.191301751708295, 35.817360316646386],
              [-81.191632684692991, 35.817401898834547],
              [-81.191476717374968, 35.818195965109894],
              [-81.191807653869986, 35.818237546811133],
              [-81.191703676790723, 35.818766923759263],
              [-81.192034615739374, 35.818808504836213],
              [-81.191878650737479, 35.819602570326005],
              [-81.192209593196651, 35.819644150916055],
              [-81.192105616940069, 35.82017352795431],
              [-81.191897659235778, 35.821232281498972],
              [-81.192228608375643, 35.821273862014394],
              [-81.192176619431862, 35.821538550417991],
              [-81.192507571075311, 35.821580130151709],
              [-81.19245558284787, 35.821844818646795],
              [-81.19278653586403, 35.821886396717652],
              [-81.192734548352959, 35.822151085304043],
              [-81.193396457568909, 35.822234240825175],
              [-81.193344471831054, 35.822498929640467],
              [-81.193675427981944, 35.822540505288536],
              [-81.193623442960401, 35.822805194195112],
              [-81.193954400532817, 35.822846769982498],
              [-81.193902416203329, 35.823111458079424],
              [-81.194233375173027, 35.823153033104902],
              [-81.194181391584308, 35.823417722194314],
              [-81.194512353033176, 35.823459295537035],
              [-81.194460370160911, 35.823723984717773],
              [-81.194129407630754, 35.823682410336488],
              [-81.194025438726953, 35.824211787383852],
              [-81.195018332548585, 35.824336507773097],
              [-81.194966350768837, 35.824601197090409],
              [-81.195297317490642, 35.824642769186106],
              [-81.195245336402991, 35.824907457693641],
              [-81.195576304521936, 35.824949029027501],
              [-81.195472344545649, 35.825478406159206],
              [-81.195803314012295, 35.825519976888529],
              [-81.195595393120698, 35.826578732069528],
              [-81.195926369368152, 35.826620302409239],
              [-81.195822408428498, 35.827149679557294],
              [-81.196153386023781, 35.827191249292412],
              [-81.196049426942864, 35.827720626510832],
              [-81.196711388464266, 35.827803762911685],
              [-81.19665940997389, 35.828068451736392],
              [-81.197321376077156, 35.828151584794917],
              [-81.197269399360337, 35.828416273848276],
              [-81.197931367807939, 35.828499402703137],
              [-81.197879393971306, 35.828764091965127],
              [-81.198210380340285, 35.82880565607212],
              [-81.198158406113762, 35.829070345445295],
              [-81.198820381937864, 35.829153469433891],
              [-81.198768410591427, 35.829418159015567],
              [-81.199099400070878, 35.829459719798244],
              [-81.19904742833468, 35.829724409491085],
              [-81.199378419235998, 35.829765970412986],
              [-81.199326449298511, 35.83003065927592],
              [-81.199657441597253, 35.830072219435777],
              [-81.199605472376362, 35.830336908389889],
              [-81.199936466072458, 35.830378467787739],
              [-81.199832528748331, 35.830907846733275],
              [-81.200163525980855, 35.830949404585517],
              [-81.200059588327548, 35.831478784541964],
              [-81.200390588014514, 35.831520341769441],
              [-81.200338620287724, 35.831785031805786],
              [-81.200669620265614, 35.831826588291214],
              [-81.200565685880733, 35.832355967598765],
              [-81.200896689419579, 35.832397523439539],
              [-81.200584884439934, 35.833985661076689],
              [-81.200915894661804, 35.834027216842038],
              [-81.200708020966388, 35.835085974010035],
              [-81.201039036863804, 35.835127529405312],
              [-81.200831161927184, 35.836186285481084],
              [-81.201162182393688, 35.836227840526256],
              [-81.201058245575538, 35.836757219452103],
              [-81.201389268496797, 35.836798773872481],
              [-81.201337300597629, 35.83706346205166],
              [-81.201668323809812, 35.837105015729939],
              [-81.201512421315556, 35.837899083104581],
              [-81.201843449146423, 35.837940636275377],
              [-81.201687546760965, 35.838734703645024],
              [-81.202018579186074, 35.838776255407225],
              [-81.201914644675199, 35.839305633696476],
              [-81.202245678473091, 35.839347185754868],
              [-81.202089777301552, 35.840141252351856],
              [-81.202420815718355, 35.840182803902785],
              [-81.202264914631087, 35.840976869593057],
              [-81.202595955453546, 35.841018420676484],
              [-81.202543988792911, 35.841283108984037],
              [-81.203537119794419, 35.841407757189138],
              [-81.203485155965581, 35.841672445862237],
              [-81.204478293177473, 35.84179708548276],
              [-81.204426333311588, 35.84206177540235],
              [-81.206081574058487, 35.842269489598557],
              [-81.206743673850866, 35.842352569140772],
              [-81.206691721046454, 35.842617259974851],
              [-81.207022771944793, 35.842658798093439],
              [-81.206970819857531, 35.842923489018247],
              [-81.207632924789834, 35.843006562831441],
              [-81.207580974477168, 35.843271253984405],
              [-81.208243083992031, 35.843354324454005],
              [-81.208191135454001, 35.843619015834953],
              [-81.209184306003792, 35.843743615194676],
              [-81.209132360297886, 35.844008306940907],
              [-81.210125538188692, 35.844132898596555],
              [-81.210073595314995, 35.844397590708098],
              [-81.210404656349866, 35.844439119598157],
              [-81.210352714168806, 35.844703810899375],
              [-81.212008029733482, 35.844911442542653],
              [-81.212059965520339, 35.844646750575443],
              [-81.21338421986502, 35.844812839149469],
              [-81.213436151081694, 35.844548146587101],
              [-81.213767214460745, 35.844589666344547],
              [-81.213715284301671, 35.844854359044113],
              [-81.214046349078274, 35.844895878039225],
              [-81.213994419636506, 35.84516057082962],
              [-81.214325486917588, 35.845202089042239],
              [-81.214273558193341, 35.845466781923285],
              [-81.214604625765247, 35.84550829939365],
              [-81.214552697758307, 35.845772992365482],
              [-81.214883766727979, 35.845814509073385],
              [-81.214831839438489, 35.84607920213594],
              [-81.215162910887869, 35.846120717160325],
              [-81.215110984315828, 35.846385410313573],
              [-81.215773129292714, 35.846468439760294],
              [-81.215721204495708, 35.846733133141335],
              [-81.21605227910527, 35.846774646642444],
              [-81.216000355001043, 35.847039339213161],
              [-81.216993582911584, 35.84716387384951],
              [-81.217045503818312, 35.846899179966208],
              [-81.218038731616645, 35.847023706094809],
              [-81.217986813882817, 35.847288400389559],
              [-81.220304366151751, 35.847578929301889],
              [-81.220252456587971, 35.847843624489919],
              [-81.221576784495952, 35.848009621113434],
              [-81.221524879929547, 35.848274316783282],
              [-81.22251813260948, 35.848398805441541],
              [-81.222466230851339, 35.848663500575128],
              [-81.222797316832171, 35.848704995733165],
              [-81.222745414684951, 35.848969690977604],
              [-81.223076503170219, 35.8490111853529],
              [-81.223024601740732, 35.849275880687941],
              [-81.223355691598883, 35.849317373399629],
              [-81.223251890991833, 35.849846765986243],
              [-81.223582983305363, 35.84988825807249],
              [-81.223531082996217, 35.850152954443146],
              [-81.223862176707442, 35.850194445766846],
              [-81.223758378266865, 35.850723837721517],
              [-81.224089474433427, 35.850765328419783],
              [-81.224037576326722, 35.85103002490488],
              [-81.224368673891078, 35.851071514840534],
              [-81.224316775370554, 35.851336210535223],
              [-81.224647874332632, 35.851377699708358],
              [-81.224595977661551, 35.85164239637443],
              [-81.224927078021352, 35.851683884784968],
              [-81.224875182043291, 35.851948580640446],
              [-81.2261995922198, 35.852114525813974],
              [-81.226147700132998, 35.852379222171187],
              [-81.226478804308016, 35.852420706357485],
              [-81.226375021255109, 35.852950100107499],
              [-81.226706128967621, 35.852991582746924],
              [-81.226654238001629, 35.853256280139604],
              [-81.226323129206378, 35.853214796462098],
              [-81.22606366388861, 35.854538278436912],
              [-81.226394777973113, 35.854579762799837],
              [-81.226342884942667, 35.854844459011829],
              [-81.226674000424978, 35.854885942612015],
              [-81.22662210811248, 35.855150638914445],
              [-81.226953224992613, 35.855192121752054],
              [-81.226901333398061, 35.855456818144901],
              [-81.227232451676016, 35.855498300219836],
              [-81.227180560799439, 35.855762996703042],
              [-81.227511680450377, 35.855804477114191],
              [-81.227459791398672, 35.85606917366762],
              [-81.227790912472287, 35.856110654217247],
              [-81.227739023031702, 35.856375350881201],
              [-81.228070145503139, 35.85641683066811],
              [-81.227966368824966, 35.856946224110054],
              [-81.228297493727354, 35.856987702370269],
              [-81.228193717805425, 35.85751709589956],
              [-81.228524845163619, 35.857558573534014],
              [-81.228472958304209, 35.857823270355588],
              [-81.228804087085123, 35.857864748128478],
              [-81.228752200943774, 35.858129445040404],
              [-81.229083331097726, 35.858170921149473],
              [-81.229031445674451, 35.858435618151653],
              [-81.229362578333223, 35.858477093477788],
              [-81.229310692521111, 35.858741790590535],
              [-81.229972960999092, 35.858824739718564],
              [-81.229921076963194, 35.859089437058749],
              [-81.230583348916753, 35.859172382861757],
              [-81.231245622205037, 35.859255324164529],
              [-81.231297500926914, 35.85899062719767],
              [-81.232290910234212, 35.859115031992431],
              [-81.232342785442071, 35.858850334567798],
              [-81.233336194633793, 35.858974730853511],
              [-81.233998469152183, 35.859057658112832],
              [-81.233894730108744, 35.859587055166195],
              [-81.234225869981245, 35.859628517269527],
              [-81.234174000466822, 35.859893216323819],
              [-81.234505141737273, 35.859934677664391],
              [-81.23440140488718, 35.860464074985344],
              [-81.234732549720619, 35.860505535679785],
              [-81.234680681302848, 35.860770234867879],
              [-81.23501182753408, 35.860811694799494],
              [-81.234700618355092, 35.862399886122986],
              [-81.23436946577516, 35.862358425369479],
              [-81.234265724924668, 35.862887822122815],
              [-81.233934570568294, 35.862846360195505],
              [-81.233882698009211, 35.863111057924655],
              [-81.234213853423753, 35.863152519988859],
              [-81.234058238013858, 35.863946614187199],
              [-81.234389396942717, 35.863988075762585],
              [-81.234285652998679, 35.864517471347504],
              [-81.234616815490725, 35.864558932276701],
              [-81.234564944067159, 35.864823630135874],
              [-81.234233780516902, 35.864782169069777],
              [-81.234130035640902, 35.865311564353405],
              [-81.234461200200556, 35.865353025713794],
              [-81.234201835693511, 35.866676513818618],
              [-81.234533006991185, 35.866717974943725],
              [-81.234429260458128, 35.867247369238584],
              [-81.234760434212092, 35.867288829737745],
              [-81.234708562613548, 35.867553527382114],
              [-81.238682689007177, 35.868050982079446],
              [-81.238630829768468, 35.86831568132034],
              [-81.238962010056596, 35.8683571303946],
              [-81.238910151511377, 35.868621828824445],
              [-81.24056606661172, 35.86882905951844],
              [-81.240514213043426, 35.869093759487022],
              [-81.240845397461882, 35.869135202534494],
              [-81.240793545719256, 35.869399902572816],
              [-81.241124731535791, 35.869441344857258],
              [-81.241072880511823, 35.869706044985513],
              [-81.241404068833532, 35.86974748648656],
              [-81.241352217421124, 35.870012186725226],
              [-81.242014597200424, 35.870095067301357],
              [-81.241962747565054, 35.870359767766793],
              [-81.24229393902273, 35.870401206841841],
              [-81.242242091213143, 35.870665907377003],
              [-81.242573284068925, 35.87070734568897],
              [-81.242521435845944, 35.870972045433348],
              [-81.242852630124872, 35.871013483883331],
              [-81.242800783727688, 35.871278183697235],
              [-81.243463175396499, 35.871361057270086],
              [-81.243411330776482, 35.871625757310824],
              [-81.244073727002927, 35.871708626635979],
              [-81.244021883078034, 35.871973327825096],
              [-81.246009086260543, 35.872221915022308],
              [-81.245957249453909, 35.87248661696529],
              [-81.246950859359544, 35.872610898814436],
              [-81.246899026470771, 35.872875600199464],
              [-81.247561436520556, 35.872958450546037],
              [-81.247509604302081, 35.873223152178227],
              [-81.248172018934568, 35.873305999178157],
              [-81.248120189600613, 35.873570701016625],
              [-81.248451398484804, 35.873612123303374],
              [-81.248399568762579, 35.873876825252196],
              [-81.248730779044905, 35.873918246775766],
              [-81.248627121806024, 35.874447650785633],
              [-81.248958334545051, 35.874489071682852],
              [-81.248854679172013, 35.87501847575799],
              [-81.250510759603685, 35.875225566289252],
              [-81.250458936700596, 35.875490268940482],
              [-81.251452593765308, 35.8756145130208],
              [-81.251400773698563, 35.875879216035422],
              [-81.253388102215041, 35.876127679834816],
              [-81.253336289267637, 35.87639238360277],
              [-81.253998736940005, 35.876475197942327],
              [-81.253895113128976, 35.877004605003606],
              [-81.254226340157885, 35.877046011537182],
              [-81.254174529367916, 35.877310715574311],
              [-81.254505757769778, 35.877352120443554],
              [-81.254453946591909, 35.877616824590909],
              [-81.254785176391934, 35.877658228696767],
              [-81.25473336704033, 35.877922932913435],
              [-81.255395829776418, 35.878005738698462],
              [-81.255344022202721, 35.878270443141545],
              [-81.256006489521255, 35.878353245579333],
              [-81.255954682618409, 35.878617950269351],
              [-81.256617153412265, 35.878700749380208],
              [-81.256668957115764, 35.878436045338219],
              [-81.257331427150106, 35.878518840575119],
              [-81.257383228371523, 35.878254135311394],
              [-81.257714462285477, 35.878295531453205],
              [-81.257766262108817, 35.878030826005755],
              [-81.258097495303474, 35.878072221110706],
              [-81.258149293728565, 35.877807515479383],
              [-81.259805460607041, 35.878014476798477],
              [-81.259857253399119, 35.877749770436438],
              [-81.26018848651276, 35.877791159867321],
              [-81.26151342344339, 35.877956707668261],
              [-81.261461637052165, 35.878221414693321],
              [-81.261792874028643, 35.878262799739858],
              [-81.261689302345673, 35.878792213922353],
              [-81.262020540646503, 35.87883359746165],
              [-81.261916969723671, 35.879363011729339],
              [-81.262248211588684, 35.879404394621382],
              [-81.262144641426019, 35.879933808974229],
              [-81.262475884666017, 35.879975192161261],
              [-81.262424100109214, 35.880239898502666],
              [-81.262755345854472, 35.880281280905614],
              [-81.262703562017222, 35.880545987336724],
              [-81.265022300178885, 35.880835637186458],
              [-81.264970523439302, 35.881100345428379],
              [-81.265301775098834, 35.881141719981862],
              [-81.265249999078719, 35.881406428313284],
              [-81.265581251029175, 35.881447802123866],
              [-81.265529476835951, 35.881712510524366],
              [-81.265860730184556, 35.881753883571463],
              [-81.265808956685632, 35.882018591160353],
              [-81.266140211457682, 35.882059964345096],
              [-81.266088438678352, 35.882324672023564],
              [-81.266750952439935, 35.882407415044042],
              [-81.266699181439122, 35.882672122948797],
              [-81.267361698675685, 35.882754862641853],
              [-81.267309929453418, 35.883019570772788],
              [-81.268303711570326, 35.883143673510119],
              [-81.268251945185568, 35.883408382003815],
              [-81.268914471132291, 35.883491112319881],
              [-81.26813796536257, 35.887461739988204],
              [-81.268469245836982, 35.887503105824983],
              [-81.268003313608602, 35.889885474801133],
              [-81.267672023602557, 35.889844107734653],
              [-81.267620249746628, 35.89010881534206],
              [-81.267951541919047, 35.890150182524579],
              [-81.267899768757516, 35.890414889320347],
              [-81.268231062328454, 35.890456255739238],
              [-81.268179291019393, 35.890720963504727],
              [-81.268841880189996, 35.890803693935567],
              [-81.268790110634569, 35.891068401025976],
              [-81.269121406234333, 35.891109765037847],
              [-81.269017868249236, 35.891639180251154],
              [-81.269349167413935, 35.891680543615443],
              [-81.269142090476151, 35.892739373140287],
              [-81.268810787074742, 35.892698009229562],
              [-81.26860370047126, 35.893756837450532],
              [-81.268935008109466, 35.893798201907735],
              [-81.268831465431276, 35.894327615546501],
              [-81.26916277552715, 35.894368979376644],
              [-81.269111004738306, 35.894633686261621],
              [-81.269442317340236, 35.894675049307459],
              [-81.269338775755671, 35.895204463229028],
              [-81.269670090815424, 35.895245825647741],
              [-81.269618321127126, 35.89551053266382],
              [-81.269949636478032, 35.895551894339327],
              [-81.269897867509698, 35.895816601444608],
              [-81.270229185366532, 35.895857962335896],
              [-81.269970339598743, 35.897181496953571],
              [-81.269639016445439, 35.897140135379288],
              [-81.269483701281828, 35.897934254818807],
              [-81.269152375289806, 35.897892891934298],
              [-81.268997053894097, 35.898687010536861],
              [-81.268665723955976, 35.898645646362716],
              [-81.268458619018034, 35.899704469368238],
              [-81.268096171718696, 35.901557406457798],
              [-81.267764830466859, 35.901516039880526],
              [-81.267661267958445, 35.902045449279356],
              [-81.267329924927083, 35.90200408152824],
              [-81.267226358942139, 35.902533490463647],
              [-81.266895014131364, 35.902492121538984],
              [-81.266739659455155, 35.903286235076571],
              [-81.26707100855181, 35.903327604390604],
              [-81.266967439147194, 35.90385701194937],
              [-81.267298789594676, 35.903898380656727],
              [-81.267247005455232, 35.904163084951918],
              [-81.267578358409168, 35.9042044528749],
              [-81.267526574989873, 35.904469157259129],
              [-81.267857929342725, 35.904510524418264],
              [-81.26780614664348, 35.904775228891502],
              [-81.268137502395291, 35.904816595286825],
              [-81.268085720416096, 35.90508129984898],
              [-81.268417077541429, 35.905122664579359],
              [-81.268365297389877, 35.905387369210025],
              [-81.268696655939564, 35.90542873407761],
              [-81.268644875400554, 35.905693438817785],
              [-81.2689762353492, 35.9057348029216],
              [-81.268924455504973, 35.905999506849682],
              [-81.269255817960129, 35.90604087016893],
              [-81.269152259397742, 35.906570279056574],
              [-81.269483624311434, 35.906611641748547],
              [-81.269380067618144, 35.907141050698293],
              [-81.269711433882819, 35.907182412783563],
              [-81.269659655545496, 35.907447117784336],
              [-81.269991024291315, 35.907488478184028],
              [-81.269939247781764, 35.907753183253227],
              [-81.270601987328774, 35.907835902545884],
              [-81.270550211491397, 35.908100607861208],
              [-81.270218841029717, 35.908059248067694],
              [-81.270063509359261, 35.908853361494941],
              [-81.269732134975982, 35.908812001312803],
              [-81.269680355636368, 35.909076705263175],
              [-81.269348981615082, 35.90903534312222],
              [-81.269297200901747, 35.909300047789301],
              [-81.268965825052661, 35.909258684631851],
              [-81.268758694490288, 35.910317499573651],
              [-81.269090075685568, 35.910358863256917],
              [-81.26903829324965, 35.910623566784444],
              [-81.269369674711001, 35.910664928823273],
              [-81.269317893020627, 35.910929633340665],
              [-81.269649276988829, 35.910970994594948],
              [-81.269597495993352, 35.91123569830021],
              [-81.270260266010411, 35.911318420203045],
              [-81.270156708347585, 35.911847827995999],
              [-81.270488096538458, 35.911889187859437],
              [-81.270436317703712, 35.912153891831231],
              [-81.27076770726849, 35.912195250029612],
              [-81.270612373014458, 35.912989362047419],
              [-81.27094376612321, 35.913030720656089],
              [-81.270736653984372, 35.91408953564251],
              [-81.271068051671492, 35.914130893896861],
              [-81.270757378518027, 35.91571911533206],
              [-81.270425974472076, 35.915677756258248],
              [-81.270374193004457, 35.915942459833978],
              [-81.270705598084888, 35.915983818143303],
              [-81.270550255906997, 35.916777928071696],
              [-81.270881665614084, 35.916819285869515],
              [-81.270778104945265, 35.917348692215143],
              [-81.271109517136779, 35.91739005028645],
              [-81.271057736786489, 35.917654753083823],
              [-81.271389150377445, 35.917696110391134],
              [-81.271337370747545, 35.917960813277197],
              [-81.271668786845467, 35.918002169799792],
              [-81.271617007935973, 35.918266872774545],
              [-81.271948424300277, 35.918308227652652],
              [-81.27189664724429, 35.918572931596422],
              [-81.272228065007937, 35.918614285710412],
              [-81.272176288647032, 35.91887898884189],
              [-81.272839127337775, 35.918961695542066],
              [-81.272787352757234, 35.919226398898751],
              [-81.27311877368443, 35.919267751484945],
              [-81.27317054828741, 35.919003047070014],
              [-81.273501968494017, 35.919044398618979],
              [-81.273553741697654, 35.91877969401969],
              [-81.273885161183884, 35.91882104453147],
              [-81.273936931880414, 35.91855633976828],
              [-81.27493119025273, 35.91868038366718],
              [-81.274982957455492, 35.918415679347653],
              [-81.27597721567723, 35.918539713829873],
              [-81.276028979360845, 35.918275009052877],
              [-81.277023237457158, 35.918399035019561],
              [-81.276971478061057, 35.918663740185515],
              [-81.277302898795824, 35.918705080516474],
              [-81.27719938002312, 35.919234491899459],
              [-81.277530804324968, 35.919275831582105],
              [-81.27747904492297, 35.91954053689804],
              [-81.277810470624104, 35.91958187581649],
              [-81.277758713075897, 35.919846582101513],
              [-81.278090139068524, 35.919887920276452],
              [-81.278038382215527, 35.920152625749019],
              [-81.278369810715304, 35.920193963139091],
              [-81.278318053475218, 35.920458668720833],
              [-81.278649483374224, 35.920500005346824],
              [-81.278597727962534, 35.92076471099648],
              [-81.278929159260983, 35.920806046858232],
              [-81.278877404569968, 35.921070752596457],
              [-81.27920883726776, 35.921112087693928],
              [-81.279157083297449, 35.921376793520807],
              [-81.27981995183093, 35.921459461286602],
              [-81.279768199641353, 35.921724167338503],
              [-81.280099635477058, 35.921765500006813],
              [-81.280047884008198, 35.922030206147205],
              [-81.280379321217893, 35.922071537150231],
              [-81.28032757046978, 35.92233624337922],
              [-81.281321889581221, 35.922460233154027],
              [-81.281373637149159, 35.922195526515679],
              [-81.281705075741485, 35.922236854816845],
              [-81.28175682190998, 35.921972147994012],
              [-81.282088259781517, 35.92201347525797],
              [-81.282140004550769, 35.921748768250794],
              [-81.28247144278383, 35.921790093555799],
              [-81.282523185045989, 35.921525386384872],
              [-81.282854622583628, 35.921566711553737],
              [-81.282906363421105, 35.921302003297448],
              [-81.283237800237927, 35.921343327429135],
              [-81.283289539701499, 35.921078619889435],
              [-81.28362097466426, 35.921119942103594],
              [-81.283672713836324, 35.920855234358896],
              [-81.284335582684989, 35.920937876713225],
              [-81.284387318290086, 35.920673168668387],
              [-81.284718753258133, 35.920714487886755],
              [-81.284770487463888, 35.920449779657609],
              [-81.285101920603438, 35.920491097859532],
              [-81.285153653409964, 35.920226389446015],
              [-81.285816518611924, 35.920309023776014],
              [-81.285764787925473, 35.920573732462344],
              [-81.286096223190313, 35.920615047941361],
              [-81.286044493250287, 35.920879757617378],
              [-81.28670736470194, 35.920962386187632],
              [-81.286655636542861, 35.921227096088508],
              [-81.286987074945216, 35.921268409138214],
              [-81.286728435368673, 35.922591954981357],
              [-81.286396991640231, 35.922550640348469],
              [-81.286345261420635, 35.922815349060755],
              [-81.286013816996785, 35.92277403429177],
              [-81.285910353419823, 35.923303451299745],
              [-81.285578907215012, 35.923262135357142],
              [-81.28542370612513, 35.924056260100144],
              [-81.285755155510429, 35.924097576452056],
              [-81.285651687433685, 35.924626993001134],
              [-81.285983140386307, 35.924668308704376],
              [-81.285931406345384, 35.924933017053782],
              [-81.286262860697292, 35.924974331992665],
              [-81.286211128485149, 35.925239040409757],
              [-81.286542583128693, 35.925280354605043],
              [-81.286387387612095, 35.926074479076448],
              [-81.286718845775326, 35.926115792780202],
              [-81.286408451944837, 35.92770404124488],
              [-81.286739917916449, 35.927745354845683],
              [-81.286688185492892, 35.928010063191707],
              [-81.287019652864075, 35.928051376028009],
              [-81.286967921136025, 35.928316083561405],
              [-81.287630859041968, 35.928398707705426],
              [-81.287579129095249, 35.928663415463589],
              [-81.288242070453009, 35.928746035376037],
              [-81.288190343421164, 35.929010744239172],
              [-81.288521815669071, 35.929052052980609],
              [-81.288470088224841, 35.929316761051766],
              [-81.288801562980183, 35.929358069007939],
              [-81.288749836257068, 35.929622777167488],
              [-81.289081311303946, 35.929664084379965],
              [-81.288977860094235, 35.930193501707805],
              [-81.289309337600926, 35.930234808292234],
              [-81.289257613088893, 35.930499516559507],
              [-81.289589091995111, 35.930540822379456],
              [-81.289537368204151, 35.930805530735043],
              [-81.290200330262749, 35.930888139924328],
              [-81.290148608253347, 35.93115284850446],
              [-81.291143057620047, 35.93127675593071],
              [-81.291091338452574, 35.93154146487187],
              [-81.291422823682723, 35.93158276567496],
              [-81.291215946755059, 35.9326416015248],
              [-81.290884456175419, 35.932600300197038],
              [-81.290677568471594, 35.933659134751842],
              [-81.291009063293046, 35.933700436625095],
              [-81.290853896555731, 35.934494561554025],
              [-81.290522399686552, 35.934453260151855],
              [-81.290470675315518, 35.934717967635002],
              [-81.290139176617117, 35.934676665216401],
              [-81.290087451954477, 35.934941372494094],
              [-81.28942445342868, 35.934858763780397],
              [-81.289372725197978, 35.935123470758015],
              [-81.288378227867142, 35.934999550050016],
              [-81.288326496115829, 35.935264256570278],
              [-81.287331998631231, 35.935140326443914],
              [-81.287280263384858, 35.935405033407825],
              [-81.286617263898577, 35.935322408559962],
              [-81.286513787012666, 35.935851820917186],
              [-81.286182286765452, 35.935810506848341],
              [-81.285871840493911, 35.937398741964714],
              [-81.285540333115236, 35.937357426197252],
              [-81.285385102204486, 35.938151542244213],
              [-81.285053591983527, 35.938110225166497],
              [-81.285001846229605, 35.938374929989109],
              [-81.284898354837239, 35.938904340369739],
              [-81.284566840640636, 35.938863021101618],
              [-81.284411596150562, 35.939657135481589],
              [-81.284743113503296, 35.939698454257936],
              [-81.28453612141503, 35.940757272078251],
              [-81.28486764226696, 35.940798591421071],
              [-81.284815895288077, 35.941063295876205],
              [-81.284484372267542, 35.941021976417709],
              [-81.284277372283768, 35.942080793291908],
              [-81.284608899521231, 35.942122112395005],
              [-81.284505399614176, 35.94265152081482],
              [-81.284836929312249, 35.942692839289748],
              [-81.284733430169013, 35.943222247788789],
              [-81.28506496343573, 35.943263565614792],
              [-81.285013214415685, 35.943528269928109],
              [-81.28534474908254, 35.943569586989582],
              [-81.285293000758259, 35.943834290489825],
              [-81.2856245357425, 35.943875607708435],
              [-81.285572789247581, 35.944140311275994],
              [-81.28723047886767, 35.944346879947368],
              [-81.287178736254276, 35.944611585070319],
              [-81.287510276478713, 35.944652896233798],
              [-81.287458535669145, 35.944917600522828],
              [-81.287790077293607, 35.944958910921585],
              [-81.287738336122956, 35.94522361622046],
              [-81.288401423619362, 35.945306234566871],
              [-81.288349684205286, 35.945570939189018],
              [-81.289012775180453, 35.945653554203979],
              [-81.288961037548518, 35.945918259050451],
              [-81.289292585159927, 35.945959565332338],
              [-81.289240849383106, 35.946224271146924],
              [-81.289572397286378, 35.946265576684887],
              [-81.289520662205433, 35.946530281686414],
              [-81.289852211508887, 35.94657158645969],
              [-81.289800477149413, 35.946836291549133],
              [-81.290132028960954, 35.946877595536911],
              [-81.290080295322966, 35.947142300714255],
              [-81.292401172662423, 35.947431402592571],
              [-81.292297719219846, 35.947960814710648],
              [-81.292629276928054, 35.948002111898816],
              [-81.292370644930571, 35.949325642886706],
              [-81.292702208282378, 35.949366939855437],
              [-81.29265048147758, 35.949631645872003],
              [-81.292982046203846, 35.949672941174896],
              [-81.292826868070222, 35.950467060222678],
              [-81.292495300135613, 35.950425763609729],
              [-81.292443571973706, 35.950690469432161],
              [-81.292112003343163, 35.950649172682809],
              [-81.29206027378109, 35.950913878320421],
              [-81.29106556447482, 35.95078998049609],
              [-81.291013831390728, 35.951054685676155],
              [-81.290350691805912, 35.950972082641755],
              [-81.290247219267997, 35.951501492331573],
              [-81.289915648404133, 35.951460188729065],
              [-81.289863910011178, 35.951724893375207],
              [-81.29019548304413, 35.951766197093285],
              [-81.290040271228293, 35.952560311107746],
              [-81.290371847757896, 35.952601613432677],
              [-81.290164898506816, 35.953660431075797],
              [-81.289833317733411, 35.95361912820556],
              [-81.28978157766096, 35.953883831698249],
              [-81.289118414984856, 35.953801222080379],
              [-81.28906667358487, 35.954065926132031],
              [-81.288403509010593, 35.953983311756559],
              [-81.288300019814827, 35.954512718288441],
              [-81.287968435386333, 35.954471410387676],
              [-81.287864942710883, 35.955000816452241],
              [-81.287533356474285, 35.954959506476435],
              [-81.287481609133394, 35.955224209739647],
              [-81.287150022175155, 35.955182898726328],
              [-81.287046522111069, 35.955712303974316],
              [-81.28699477155709, 35.955977006074832],
              [-81.287326361698319, 35.95601831749719],
              [-81.287274611865911, 35.956283019685429],
              [-81.287606203407506, 35.956324330342966],
              [-81.287554454296668, 35.956589032618908],
              [-81.287886046130566, 35.956630342532399],
              [-81.287834298875111, 35.956895045776186],
              [-81.289492272822784, 35.95710158062252],
              [-81.289440530507591, 35.957366283598446],
              [-81.290766920921229, 35.957531496323256],
              [-81.290715182510908, 35.957796199795844],
              [-81.291046782004713, 35.957837500184574],
              [-81.29099504434167, 35.958102204645769],
              [-81.291326645235856, 35.958143504269707],
              [-81.29127490826896, 35.958408207917572],
              [-81.291606510589276, 35.958449507677507],
              [-81.291554774344107, 35.958714211413003],
              [-81.292217983207649, 35.958796807580136],
              [-81.29216624874536, 35.959061511539559],
              [-81.293161067202192, 35.959185398999892],
              [-81.293109335583949, 35.959450103319547],
              [-81.294104161386542, 35.959573983056949],
              [-81.29405243372068, 35.959838687715902],
              [-81.294384043339619, 35.959879979910077],
              [-81.294280588003886, 35.960409389375378],
              [-81.294612201167169, 35.960450680019008],
              [-81.29393971715767, 35.963891836852177],
              [-81.293608089090185, 35.963850544457472],
              [-81.293349419932937, 35.965174062345795],
              [-81.293681053306742, 35.965215355422096],
              [-81.293629319528705, 35.9654800593499],
              [-81.293577585385677, 35.965744762327901],
              [-81.293909221221554, 35.965786054775386],
              [-81.293857487826074, 35.966050758741879],
              [-81.294520762638527, 35.966133341205463],
              [-81.294469031026324, 35.966398045395678],
              [-81.294800671111261, 35.966439335390923],
              [-81.294748940195433, 35.966704038767489],
              [-81.295080580572787, 35.966745328018462],
              [-81.295028851512853, 35.967010032362644],
              [-81.295360493290914, 35.967051320848661],
              [-81.295308763819051, 35.967316024400041],
              [-81.295640408106237, 35.967357312100113],
              [-81.295588679382021, 35.96762201663995],
              [-81.296583616355221, 35.967745874761782],
              [-81.296531891558573, 35.968010578739616],
              [-81.297526834769968, 35.968134429158056],
              [-81.297578557465187, 35.967869723849567],
              [-81.298241852143789, 35.967952286022843],
              [-81.298293571294138, 35.967687581314799],
              [-81.298956866289871, 35.967770138688188],
              [-81.299008582978132, 35.967505433658843],
              [-81.300998468656601, 35.967753084250766],
              [-81.3009467583625, 35.968017790998658],
              [-81.301610058786366, 35.968100333950169],
              [-81.301558351358608, 35.968365039999711],
              [-81.302553307735707, 35.968488848525858],
              [-81.302501602044643, 35.968753554956045],
              [-81.30283325591877, 35.968794823098555],
              [-81.302781552058377, 35.969059529595164],
              [-81.303113207307533, 35.969100796071451],
              [-81.303061504195057, 35.969365503556418],
              [-81.303724817859631, 35.969448034978271],
              [-81.30367311650518, 35.969712741785685],
              [-81.304004776003325, 35.969754005809293],
              [-81.303953074262836, 35.970018712724894],
              [-81.304284735187295, 35.970059976884329],
              [-81.304233035277562, 35.97032468386621],
              [-81.304564696468645, 35.970365946380397],
              [-81.304512997281151, 35.970630653449561],
              [-81.304844661007095, 35.970671916078558],
              [-81.304741262629364, 35.971201330363428],
              [-81.305072928791731, 35.971242591462435],
              [-81.305021230709869, 35.971507298657151],
              [-81.305352898298793, 35.971548559891971],
              [-81.305301200939297, 35.9718132671739],
              [-81.305632869903107, 35.971854526742348],
              [-81.305581173266006, 35.972119234111588],
              [-81.306244514334338, 35.972201750816524],
              [-81.30619281948124, 35.972466458409158],
              [-81.30685616405512, 35.972548972681729],
              [-81.306804472094498, 35.972813680476797],
              [-81.307136145938827, 35.972854935946557],
              [-81.307084453592182, 35.973119643849763],
              [-81.307416128837261, 35.973160898554241],
              [-81.307312746358249, 35.973690314465017],
              [-81.307644424065785, 35.973731568540366],
              [-81.307437658150207, 35.974790399438561],
              [-81.307769341551946, 35.974831653136143],
              [-81.307717650016187, 35.975096361104818],
              [-81.307385966661286, 35.975055107250157],
              [-81.307127498535223, 35.976378643875123],
              [-81.307459188306936, 35.976419898389658],
              [-81.307304107660059, 35.977214020343808],
              [-81.307635800956149, 35.977255274365334],
              [-81.307532413538112, 35.977784688742055],
              [-81.307864110405362, 35.977825942113412],
              [-81.307812417236491, 35.978090648913813],
              [-81.308144115504561, 35.978131901519809],
              [-81.308092423058255, 35.978396608407174],
              [-81.308424121618799, 35.978437860268741],
              [-81.308320738966543, 35.978967275048753],
              [-81.308652441098232, 35.979008526260131],
              [-81.308600749757957, 35.979273233272572],
              [-81.309264157162474, 35.979355733263034],
              [-81.309212467606613, 35.97962044049865],
              [-81.311202704304478, 35.979867918748809],
              [-81.311151021914696, 35.980132627632074],
              [-81.312477858483774, 35.980297594423277],
              [-81.312809568209488, 35.980338834322907],
              [-81.312757890763962, 35.98060354293667],
              [-81.313421314439168, 35.980686019381437],
              [-81.313369638804005, 35.980950729119215],
              [-81.313701351644923, 35.980991965685426],
              [-81.313649676706817, 35.981256674609128],
              [-81.313981392082994, 35.981297911289857],
              [-81.31392971786768, 35.981562620300529],
              [-81.314261433510467, 35.981603855335756],
              [-81.314209761152412, 35.981868565313391],
              [-81.314541478196048, 35.98190979958305],
              [-81.314489806534993, 35.982174508746652],
              [-81.314821525005527, 35.982215743151848],
              [-81.314666508956478, 35.983009870797908],
              [-81.314998232034455, 35.983051103787709],
              [-81.314946561141028, 35.98331581302682],
              [-81.31461483700113, 35.983274579900915],
              [-81.314511489855988, 35.983803998001349],
              [-81.314179763905315, 35.983762762800652],
              [-81.313973062406959, 35.984821597845865],
              [-81.31364133143903, 35.984780361220196],
              [-81.313537975977042, 35.985309778185822],
              [-81.313206244332804, 35.98526854036534],
              [-81.313154565031184, 35.985533248638184],
              [-81.313486297737313, 35.985574486594793],
              [-81.313331260957867, 35.986368610625256],
              [-81.313662998271994, 35.986409847166435],
              [-81.313611319737902, 35.986674555514455],
              [-81.313943058453077, 35.986715791290159],
              [-81.313891380641891, 35.986980499725021],
              [-81.314223119649526, 35.987021734756084],
              [-81.314274796424527, 35.986757027086149],
              [-81.314606535817987, 35.986798261058489],
              [-81.314658211191869, 35.986533553203223],
              [-81.31664864721796, 35.986780938208042],
              [-81.31670031477195, 35.986516229508148],
              [-81.318359016402255, 35.986722358335761],
              [-81.318410678281097, 35.986457648005157],
              [-81.31907415917189, 35.986540092592129],
              [-81.319125818587651, 35.986275381940274],
              [-81.319789297601645, 35.986357822669589],
              [-81.319840954580243, 35.986093112597473],
              [-81.320504433934673, 35.986175549426953],
              [-81.320556088424297, 35.985910838132568],
              [-81.321551305677332, 35.986034485326329],
              [-81.321602956667775, 35.985769774475614],
              [-81.322266434028862, 35.985852200864535],
              [-81.32221478629711, 35.986116912867168],
              [-81.322546527115335, 35.98615812528508],
              [-81.322598173785025, 35.985893413146449],
              [-81.323593395061167, 35.98601704368118],
              [-81.323128589240184, 35.988399453069086],
              [-81.323460339910852, 35.988440662968408],
              [-81.32340869441343, 35.988705374306818],
              [-81.323740447593821, 35.988746583419349],
              [-81.323688801736907, 35.989011295766581],
              [-81.32402055629241, 35.989052503212314],
              [-81.323968912267418, 35.989317215625242],
              [-81.324300668224069, 35.989358422305237],
              [-81.32424902381365, 35.989623134825976],
              [-81.324580781197241, 35.989664341641301],
              [-81.324529138592837, 35.989929053326591],
              [-81.325192656475096, 35.990011463622977],
              [-81.324986091217937, 35.991070312761018],
              [-81.325317854903332, 35.991111516650022],
              [-81.325059646004732, 35.992435077192056],
              [-81.325391416448923, 35.99247628083819],
              [-81.325339774730267, 35.992740993475124],
              [-81.325671545466889, 35.992782196376581],
              [-81.325619904445702, 35.993046908199062],
              [-81.325951677718123, 35.993088111214647],
              [-81.325900037420226, 35.993352823123764],
              [-81.326231812067931, 35.993394024472444],
              [-81.326180172519514, 35.993658737369259],
              [-81.326511948568395, 35.993699937952144],
              [-81.326460309717291, 35.993964650034521],
              [-81.326792087193269, 35.994005850752629],
              [-81.32663717179382, 35.994799987111243],
              [-81.325973610832776, 35.994717584858449],
              [-81.325921969563865, 35.994982296607276],
              [-81.325258407833914, 35.994899890475509],
              [-81.325206764101424, 35.995164601902999],
              [-81.324874981560654, 35.995123396919041],
              [-81.324823336426903, 35.995388108161102],
              [-81.32449155429758, 35.995346903019282],
              [-81.324439907762567, 35.995611614076005],
              [-81.324108124883907, 35.995570406995412],
              [-81.324056475838745, 35.995835117887765],
              [-81.323724692236695, 35.995793909769418],
              [-81.323673042899088, 35.996058620455322],
              [-81.323341257490853, 35.996017412221299],
              [-81.323289606725908, 35.996282121820677],
              [-81.322957820594311, 35.996240912548942],
              [-81.322854514840103, 35.996770332249604],
              [-81.321527368479295, 35.99660548319109],
              [-81.321579025207896, 35.996340773469434],
              [-81.32124724079172, 35.996299558630575],
              [-81.321195581891999, 35.996564268237258],
              [-81.320863796752619, 35.996523052360594],
              [-81.320812136451522, 35.996787761781817],
              [-81.320148563956337, 35.996705327072064],
              [-81.320096901191505, 35.996970036171831],
              [-81.319765114944602, 35.996928817317723],
              [-81.319816778771752, 35.996664108353997],
              [-81.319484992817351, 35.996622888755198],
              [-81.319433327927868, 35.996887597582919],
              [-81.317774402366027, 35.996681485297664],
              [-81.317671058702942, 35.997210900585415],
              [-81.317339272704999, 35.997169675146722],
              [-81.317184252601876, 35.997963797728623],
              [-81.317516041786817, 35.998005023575423],
              [-81.31741269584144, 35.998534438347299],
              [-81.317080903422976, 35.998493212249414],
              [-81.31692587820686, 35.999287333187809],
              [-81.316594082940426, 35.999246105779974],
              [-81.316542405775394, 35.999510813132055],
              [-81.31621060975985, 35.999469583785405],
              [-81.31600389340673, 36.000528410352047],
              [-81.315672093506592, 36.000487180460382],
              [-81.315620412495022, 36.000751886391633],
              [-81.315288611871665, 36.000710655462022],
              [-81.315236928349734, 36.000975361228683],
              [-81.314905127003172, 36.000934129261275],
              [-81.314853443188397, 36.001198834821288],
              [-81.314189839362314, 36.001116367007754],
              [-81.313983090019789, 36.002175188606486],
              [-81.314314896000013, 36.002216423057874],
              [-81.314056457523236, 36.003539948483862],
              [-81.314388270263876, 36.003581182692663],
              [-81.314233205407149, 36.004375297777763],
              [-81.313901389479142, 36.004334063160769],
              [-81.313849699082965, 36.004598767960807],
              [-81.313186066093849, 36.004516294848052],
              [-81.313134374342297, 36.004780999305254],
              [-81.312802557307108, 36.004739761710532],
              [-81.312750863019048, 36.005004465102026],
              [-81.312087227841474, 36.004921986934789],
              [-81.312035531089123, 36.005186690004422],
              [-81.311040077654297, 36.005062964680214],
              [-81.310988376292045, 36.005327668213951],
              [-81.309992922697901, 36.005203933463612],
              [-81.309941218917743, 36.005468636518479],
              [-81.30795031340844, 36.005221141871566],
              [-81.30789860291398, 36.005485844060054],
              [-81.305907704331418, 36.005238315207293],
              [-81.305959422309598, 36.004973613814492],
              [-81.303968543430713, 36.004726053290568],
              [-81.303916819052105, 36.004990752965512],
              [-81.302589569358574, 36.004825693426938],
              [-81.302641297961159, 36.00456099339565],
              [-81.301977677830706, 36.004478458477116],
              [-81.302029409327517, 36.00421375864758],
              [-81.301365792679988, 36.004131220394022],
              [-81.301314059058456, 36.004395919951307],
              [-81.300982249601475, 36.004354648445052],
              [-81.300620097228588, 36.006207540298092],
              [-81.299956464510814, 36.006124994475648],
              [-81.299904723850773, 36.006389692346609],
              [-81.299241090340658, 36.006307141743257],
              [-81.299189348350765, 36.006571840172271],
              [-81.29819389743254, 36.006448006644248],
              [-81.298142150806754, 36.006712704635838],
              [-81.2978103334422, 36.006671424552948],
              [-81.297758585414428, 36.006936122358553],
              [-81.297094949605153, 36.006853560115822],
              [-81.297043199087071, 36.007118256698192],
              [-81.29571592729468, 36.00695312084558],
              [-81.295664172187287, 36.007217816833418],
              [-81.295332354013084, 36.007176530028893],
              [-81.2952805975291, 36.007441226731707],
              [-81.294948778632261, 36.007399938889101],
              [-81.294845261360052, 36.007929330091812],
              [-81.294513440703682, 36.007888041975939],
              [-81.294461681050009, 36.008152737356014],
              [-81.294129858536223, 36.008111447321838],
              [-81.29402633497584, 36.00864083768095],
              [-81.293694511811552, 36.008599547352645],
              [-81.293590984767278, 36.00912893724017],
              [-81.293259158683199, 36.009087644857317],
              [-81.293207394143337, 36.009352339579657],
              [-81.292875567336552, 36.009311046158672],
              [-81.292823801394547, 36.009575740695048],
              [-81.291164669388465, 36.009369260240128],
              [-81.291112896685306, 36.009633954066253],
              [-81.290117419243728, 36.009510054004821],
              [-81.290065641904377, 36.009774747393031],
              [-81.28973381607733, 36.009733445425717],
              [-81.289682037310072, 36.009998137726797],
              [-81.289350210760489, 36.009956834721258],
              [-81.289194871526902, 36.010750913578292],
              [-81.288863041020761, 36.01070960928282],
              [-81.288811258794681, 36.010974302013089],
              [-81.289143090363325, 36.011015606444808],
              [-81.289039528099948, 36.011544991106398],
              [-81.288707694406312, 36.011503686402122],
              [-81.288655912269604, 36.011768378961975],
              [-81.287660410014851, 36.011644458127904],
              [-81.287608623241695, 36.011909150249764],
              [-81.286944954663412, 36.011826532179008],
              [-81.286893165399775, 36.012091223077455],
              [-81.286229496056279, 36.012008601126567],
              [-81.286177704327599, 36.012273291702442],
              [-81.285845869103269, 36.012231979237846],
              [-81.285794075997785, 36.012496670528662],
              [-81.285462240025353, 36.012455356124846],
              [-81.2854104455174, 36.012720047229436],
              [-81.285078608822417, 36.012678731787339],
              [-81.28502681180295, 36.012943422726465],
              [-81.284694974410982, 36.012902107147269],
              [-81.28464317707251, 36.013166796978389],
              [-81.28431133895792, 36.013125480361005],
              [-81.284104139786976, 36.014184240484354],
              [-81.284435982151692, 36.014225557647038],
              [-81.284332382651272, 36.014754937681694],
              [-81.284664228590074, 36.014796254194309],
              [-81.284612428825113, 36.015060943832886],
              [-81.284944276192007, 36.015102260480887],
              [-81.284840678876307, 36.015631639860061],
              [-81.284508829409774, 36.01559032384047],
              [-81.2844570286248, 36.015855013329059],
              [-81.28412517954466, 36.015813696250476],
              [-81.284073377357075, 36.016078385552873],
              [-81.28307782672573, 36.015954427636999],
              [-81.283026021010329, 36.01621911648045],
              [-81.282694170030581, 36.016177794641202],
              [-81.282538747681855, 36.016971861363572],
              [-81.282870601823802, 36.017013182710841],
              [-81.282766987357974, 36.017542560848675],
              [-81.282435131065313, 36.017501238327824],
              [-81.282331513088337, 36.018030615091902],
              [-81.281999655010509, 36.017989291396461],
              [-81.281947844449405, 36.018253979567149],
              [-81.281615985674421, 36.018212655734452],
              [-81.281564173710493, 36.018477343718708],
              [-81.280900453921163, 36.018394692194455],
              [-81.280848639466271, 36.018659378955064],
              [-81.279853059687085, 36.018535394034039],
              [-81.279801240620642, 36.018800081257282],
              [-81.278805660714752, 36.018676087809666],
              [-81.278753839229353, 36.018940774553137],
              [-81.278421978130467, 36.018899441177112],
              [-81.278370155242257, 36.019164127734065],
              [-81.278038293421019, 36.019122793319724],
              [-81.277986468020885, 36.019387479711014],
              [-81.277654606586296, 36.019346144237694],
              [-81.277602779783322, 36.019610830442524],
              [-81.277270916517182, 36.019569493951529],
              [-81.277219088311369, 36.019834179969919],
              [-81.276887225431821, 36.019792842419868],
              [-81.276835395797789, 36.020057527350787],
              [-81.276503531086718, 36.020016188783117],
              [-81.276451700075157, 36.020280874428522],
              [-81.276119834641733, 36.020239534822586],
              [-81.276068002227291, 36.020504220281602],
              [-81.275736137180601, 36.020462879616517],
              [-81.275684302228683, 36.020727564008688],
              [-81.275352436459499, 36.020686222305315],
              [-81.27530060013018, 36.02095090741193],
              [-81.274636866378273, 36.020868221047188],
              [-81.274585027557848, 36.021132904929978],
              [-81.273921293041738, 36.021050214684578],
              [-81.27386945178111, 36.021314899145459],
              [-81.273205716475559, 36.021232204118355],
              [-81.273153872749333, 36.021496888256245],
              [-81.272490135570578, 36.021414189369075],
              [-81.272438289378684, 36.021678873184079],
              [-81.272106420249756, 36.021637522701653],
              [-81.271774552545011, 36.021596170395597],
              [-81.271619004416777, 36.022390219840808],
              [-81.271287132755248, 36.022348866244151],
              [-81.271183430906902, 36.022878231703231],
              [-81.27085155748577, 36.022836877832809],
              [-81.270747851015997, 36.023366241938021],
              [-81.270415975809797, 36.023324886892667],
              [-81.270312265853065, 36.023854250524302],
              [-81.269980388862052, 36.023812894304193],
              [-81.269928531755795, 36.024077575918596],
              [-81.269596654042289, 36.024036218660001],
              [-81.269492936683093, 36.024565581465346],
              [-81.269161057184689, 36.024524223031953],
              [-81.269109196931737, 36.02478890422281],
              [-81.268777316685686, 36.024747543849955],
              [-81.268725455029568, 36.025012224854223],
              [-81.266734172184101, 36.02476404560074],
              [-81.266682302702051, 36.025028725756691],
              [-81.266350422713856, 36.024987358796949],
              [-81.266298552937798, 36.025252038745563],
              [-81.265966671143531, 36.025210671668951],
              [-81.26591479996425, 36.02547535143092],
              [-81.265251036330298, 36.025392612476189],
              [-81.26519916157595, 36.025657291935488],
              [-81.264535397178719, 36.025574549099758],
              [-81.264483521067532, 36.025839228215425],
              [-81.261164710022967, 36.025425459432547],
              [-81.261112820725785, 36.025690137174152],
              [-81.260780940873119, 36.025648755099994],
              [-81.260729051256561, 36.02591343173335],
              [-81.260397169572641, 36.025872048641226],
              [-81.260345278578015, 36.026136725988742],
              [-81.259017753191117, 36.025971183112432],
              [-81.259069649570932, 36.025706507191572],
              [-81.258405891034172, 36.025623730164014],
              [-81.258457789173406, 36.02535905356482],
              [-81.258125912045983, 36.025317664274873],
              [-81.258074012844091, 36.025582340737472],
              [-81.257410256351463, 36.025499558298016],
              [-81.257306452674925, 36.026028910526392],
              [-81.256974573356388, 36.02598751721986],
              [-81.256870766191639, 36.026516868973985],
              [-81.255211361915144, 36.026309889389125],
              [-81.255263271006413, 36.026045215120938],
              [-81.25426763593434, 36.025921016771584],
              [-81.254319548956801, 36.025656341941257],
              [-81.252660170046752, 36.02544932762239],
              [-81.252608252820337, 36.025714001749357],
              [-81.251944500830973, 36.025631188726251],
              [-81.251892580029249, 36.025895862550207],
              [-81.251560704037843, 36.025854454538937],
              [-81.251508781832669, 36.026119128176035],
              [-81.251176905119479, 36.026077719126135],
              [-81.251124981510827, 36.026342392576346],
              [-81.250793102966639, 36.026300982508445],
              [-81.250741177929356, 36.026565654870666],
              [-81.250409299772613, 36.026524243743694],
              [-81.250357373356962, 36.026788916820088],
              [-81.250025493369151, 36.026747504675001],
              [-81.249973565549979, 36.027012177564416],
              [-81.248646048084993, 36.026846519356496],
              [-81.248697980154688, 36.026581847013411],
              [-81.248034226147539, 36.026499012760638],
              [-81.248086161110777, 36.026234340620157],
              [-81.247422409483235, 36.026151503053086],
              [-81.247474346255885, 36.02588683203647],
              [-81.246810599201368, 36.025803990213475],
              [-81.246862537758261, 36.025539319419863],
              [-81.245535053125337, 36.025373626377544],
              [-81.24558699556647, 36.025108955179093],
              [-81.244923257962938, 36.02502610305887],
              [-81.244871312287572, 36.025290774004397],
              [-81.243875704709865, 36.025166489110298],
              [-81.243823756615043, 36.025431159575199],
              [-81.24316001706201, 36.025348297909339],
              [-81.243108065366698, 36.025612967169877],
              [-81.242776196160946, 36.02557153527755],
              [-81.242724243062042, 36.025836204351165],
              [-81.24172863095059, 36.025711902012461],
              [-81.241676674323074, 36.025976570625815],
              [-81.241012933596451, 36.025893697296326],
              [-81.240960974527709, 36.026158366486897],
              [-81.240753133682659, 36.02721704096431],
              [-81.241085009730412, 36.027258478302741],
              [-81.240929129547482, 36.028052484477399],
              [-81.240597249177654, 36.028011045848217],
              [-81.240493325216605, 36.028540382472464],
              [-81.240161443062746, 36.028498942667959],
              [-81.24010947949543, 36.02876361031732],
              [-81.239777596645112, 36.028722170375119],
              [-81.239673666361853, 36.02925150524927],
              [-81.239341781702393, 36.029210063230749],
              [-81.239289813902204, 36.029474731376887],
              [-81.238957929630502, 36.02943328829938],
              [-81.23890596040151, 36.029697955357399],
              [-81.238574075408337, 36.029656511241186],
              [-81.238522104775555, 36.029921178112041],
              [-81.238190217976666, 36.029879733878502],
              [-81.23813824594005, 36.030144400562293],
              [-81.237474472299681, 36.030061507293517],
              [-81.237422497796629, 36.030326173653435],
              [-81.23709060987099, 36.03028472553946],
              [-81.237142585436686, 36.030020059316293],
              [-81.23681070002408, 36.029978610416791],
              [-81.236758723395724, 36.030243276503242],
              [-81.235763063823967, 36.030118924924395],
              [-81.235711082557259, 36.030383590570629],
              [-81.234051651752267, 36.030176316806489],
              [-81.233999665940459, 36.03044098169827],
              [-81.232008353421961, 36.030192222784052],
              [-81.231956359783922, 36.030456886825256],
              [-81.231624474130541, 36.030415423869407],
              [-81.231572479088584, 36.030680087723461],
              [-81.231240593823216, 36.030638623708647],
              [-81.231136599455951, 36.031167950110913],
              [-81.230804712406581, 36.031126484920669],
              [-81.230752713106085, 36.031391148369991],
              [-81.230420825335571, 36.031349682140927],
              [-81.230368824631086, 36.031614345403078],
              [-81.229705046854662, 36.031531409084771],
              [-81.229757048574982, 36.031266746116465],
              [-81.228761387091424, 36.031142334844233],
              [-81.228813392792489, 36.03087767311667],
              [-81.226822085418547, 36.030628827039727],
              [-81.226770073366851, 36.030893488847603],
              [-81.225774422210705, 36.030769053202086],
              [-81.225722406629842, 36.03103371454899],
              [-81.225058638388248, 36.030950752074602],
              [-81.225006619231479, 36.031215413117685],
              [-81.22434285023057, 36.031132446761809],
              [-81.224394871512487, 36.030867785992385],
              [-81.222735460878894, 36.030660355893595],
              [-81.222787486022796, 36.03039569577804],
              [-81.222455606421846, 36.03035420700099],
              [-81.222507633396035, 36.030089546951679],
              [-81.221843877343858, 36.030006566965497],
              [-81.22189590499238, 36.029741907159412],
              [-81.221232152431384, 36.029658923839158],
              [-81.221180121548642, 36.029923583391607],
              [-81.219520740786209, 36.029716107721931],
              [-81.21946870314008, 36.029980766559667],
              [-81.218804951086241, 36.029897769164016],
              [-81.218752912082877, 36.030162427657395],
              [-81.218089159295289, 36.030079427281258],
              [-81.218037116691136, 36.030344084569585],
              [-81.217705239747303, 36.030302582891736],
              [-81.217653195763816, 36.03056724089371],
              [-81.217321318074355, 36.030525737275951],
              [-81.217269272686721, 36.030790395090641],
              [-81.216937394301155, 36.030748891335165],
              [-81.216885347484578, 36.031013548061246],
              [-81.216221589613326, 36.030930537570711],
              [-81.216169540330171, 36.031195193972593],
              [-81.2158376608445, 36.031153687237513],
              [-81.215733558043482, 36.031683000537036],
              [-81.215401676774462, 36.031641492626235],
              [-81.215349624341215, 36.031906148602765],
              [-81.21501774124215, 36.031864639673238],
              [-81.214965687404586, 36.032129295462326],
              [-81.214633804693918, 36.032087785473841],
              [-81.214529692784112, 36.032617097547664],
              [-81.214197808265453, 36.032575585482384],
              [-81.214145750193083, 36.032840241767168],
              [-81.213813863844209, 36.032798728683105],
              [-81.213761805452222, 36.033063383859307],
              [-81.2134299184073, 36.033021870637434],
              [-81.21337785861104, 36.033286525626131],
              [-81.212714083396691, 36.03320349530162],
              [-81.212662021133482, 36.033468149965856],
              [-81.212330132964112, 36.03342663286324],
              [-81.212278068211972, 36.03369128826116],
              [-81.210286742241308, 36.033442166743747],
              [-81.210338813393491, 36.033177513068473],
              [-81.21000692872596, 36.033135989632143],
              [-81.210059000574034, 36.032871335142218],
              [-81.209395233280276, 36.032788285857706],
              [-81.209447306936241, 36.032523632492172],
              [-81.20712414125336, 36.032232931613152],
              [-81.207072059075415, 36.032497584941048],
              [-81.206408299044568, 36.032414519401733],
              [-81.206356214375006, 36.032679171503965],
              [-81.206024333809992, 36.032637637244548],
              [-81.205972247736042, 36.032902289159196],
              [-81.205640365341154, 36.032860753880804],
              [-81.205588277862802, 36.033125405607983],
              [-81.205256395856665, 36.033083869270662],
              [-81.205152216639846, 36.033613172319207],
              [-81.204820332850687, 36.033571634806052],
              [-81.204716151275136, 36.03410093825925],
              [-81.204384264568844, 36.034059398689045],
              [-81.204227985331599, 36.034853352647815],
              [-81.203896095779839, 36.034811811764676],
              [-81.203844001315815, 36.035076462539031],
              [-81.20417589082075, 36.035118003579186],
              [-81.204071702991939, 36.035647305249988],
              [-81.203967512685736, 36.036176606738152],
              [-81.20363561784589, 36.036135066071047],
              [-81.203531424046574, 36.036664367082665],
              [-81.202867630008654, 36.036581281613493],
              [-81.20281553102555, 36.036845931759323],
              [-81.201819839888444, 36.036721295919193],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 54,
        ORIG_FID: 7,
        AAPC_Examp: 3,
        Area_mile2: 791,
        BASIN_NAME: "Fishing Creek Reservoir",
        Res_Basin: "FishingCreek",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.832861082651263, 35.314366248729513],
              [-80.832913970605517, 35.314101605369665],
              [-80.830942620877607, 35.313845996438054],
              [-80.830995514733331, 35.313581353890527],
              [-80.830666958497005, 35.31353874897458],
              [-80.830719853049672, 35.313274106532617],
              [-80.830391298198322, 35.313231500866422],
              [-80.830444193447718, 35.312966858529705],
              [-80.830115641080937, 35.312924252096735],
              [-80.830168537027149, 35.312659609865484],
              [-80.829839984945707, 35.312617002698865],
              [-80.829892881588805, 35.312352360573037],
              [-80.829564331991918, 35.312309752639649],
              [-80.829617229311438, 35.312045109717886],
              [-80.828960132152318, 35.311959892376876],
              [-80.829013031209556, 35.311695249702062],
              [-80.828355938587819, 35.311610028159464],
              [-80.828408839402798, 35.311345386632858],
              [-80.827751750239116, 35.311260161806644],
              [-80.82780465387097, 35.31099551960903],
              [-80.826161942188079, 35.310782442647543],
              [-80.826109034431511, 35.311047083220004],
              [-80.825780492086523, 35.311004465011465],
              [-80.825727581865664, 35.311269106324353],
              [-80.825070496126628, 35.311183866949385],
              [-80.825123408429093, 35.310919225919434],
              [-80.824466326148254, 35.310833983260977],
              [-80.824519240188138, 35.310569342477962],
              [-80.82419070060476, 35.31052671995279],
              [-80.82429652973363, 35.309997438561794],
              [-80.823967993675538, 35.309954815411409],
              [-80.82402090876441, 35.309690174803436],
              [-80.823692372971507, 35.309647550018276],
              [-80.823745288756953, 35.309382909515783],
              [-80.823416755468756, 35.309340284865236],
              [-80.823469671950832, 35.30907564446828],
              [-80.822812607368732, 35.308990391890703],
              [-80.822865525588, 35.308725751740852],
              [-80.822536995413842, 35.308683124698518],
              [-80.822695751108483, 35.307889203556421],
              [-80.822367224380457, 35.307846575145781],
              [-80.822420143304328, 35.307581935135197],
              [-80.821106044037563, 35.307411414945449],
              [-80.821158967859304, 35.307146774547107],
              [-80.820830444953884, 35.307104142862926],
              [-80.820777521170825, 35.307368782201898],
              [-80.820120474291201, 35.307283514974628],
              [-80.820173400135431, 35.307018875017391],
              [-80.81984487936225, 35.306976240642101],
              [-80.819897805902983, 35.306711600790472],
              [-80.819569285394934, 35.306668964780329],
              [-80.819622213751742, 35.30640432591909],
              [-80.819293694628684, 35.306361689158926],
              [-80.819346623681767, 35.306097050403338],
              [-80.819675141744042, 35.306139686120588],
              [-80.819780995616568, 35.305610407333873],
              [-80.819452479615393, 35.305567770998273],
              [-80.81950540761558, 35.305303131233721],
              [-80.819176894118911, 35.305260495033018],
              [-80.819123965057969, 35.305525133754749],
              [-80.818795450844789, 35.305482495619685],
              [-80.818848379846941, 35.305217857055922],
              [-80.817862841295323, 35.305089937742686],
              [-80.817915774154272, 35.304825298649959],
              [-80.816273223822719, 35.304612082644596],
              [-80.81632616154026, 35.30434744422363],
              [-80.815997653547385, 35.304304798489639],
              [-80.815944714789282, 35.30456943676905],
              [-80.815287696635792, 35.30448414145858],
              [-80.815340638574497, 35.304219503445829],
              [-80.815012131614679, 35.304176855037348],
              [-80.815065073150123, 35.30391221714688],
              [-80.814736567575096, 35.303869567988393],
              [-80.814789510906223, 35.303604930187156],
              [-80.814461006716058, 35.303562280278769],
              [-80.81451394964391, 35.303297642599738],
              [-80.814185447917993, 35.303254991023728],
              [-80.814238391542119, 35.302990353450355],
              [-80.813909891221144, 35.302947702025783],
              [-80.813962835541346, 35.302683064558209],
              [-80.813305836893903, 35.302597758432896],
              [-80.813358784030115, 35.302333120294868],
              [-80.812701788861162, 35.302247811788213],
              [-80.812754736634645, 35.301983173914017],
              [-80.812097746003488, 35.301897861206946],
              [-80.812150696632969, 35.30163322446495],
              [-80.811493708360885, 35.301547908491727],
              [-80.811440755650381, 35.301812544950494],
              [-80.809469795035611, 35.301556573852352],
              [-80.809416834658677, 35.301821210343377],
              [-80.808431356409443, 35.301693211425409],
              [-80.808378392566723, 35.301957847455689],
              [-80.80739291319675, 35.301829840106294],
              [-80.807445880180808, 35.301565205402213],
              [-80.807117389153362, 35.301522534638991],
              [-80.80717035681333, 35.301257899139436],
              [-80.806841867170775, 35.301215227626493],
              [-80.8068948355467, 35.300950593133976],
              [-80.806237859375955, 35.300865247717056],
              [-80.806184888939143, 35.301129882827574],
              [-80.804542449319001, 35.300916502075815],
              [-80.804595424977805, 35.300651868574988],
              [-80.804266940211164, 35.300609189154571],
              [-80.804213962432613, 35.300873823431409],
              [-80.803885475890695, 35.300831143895678],
              [-80.803938454709609, 35.300566509760493],
              [-80.802953000272268, 35.300438466229743],
              [-80.803005981867756, 35.30017383248375],
              [-80.802349017125934, 35.300088465038634],
              [-80.802401999378105, 35.299823832457825],
              [-80.802073519104084, 35.299781147081035],
              [-80.802126502032067, 35.29951651370483],
              [-80.801798023162917, 35.29947382847962],
              [-80.801851007886071, 35.299209195193029],
              [-80.800865575347771, 35.299081133709166],
              [-80.800918561747977, 35.298816500828167],
              [-80.800261611252338, 35.298731122020797],
              [-80.80031460048788, 35.298466489371037],
              [-80.799657653451277, 35.29838110728155],
              [-80.799710644422774, 35.298116474879599],
              [-80.799053700825382, 35.298031088606734],
              [-80.799106693532821, 35.297766456452315],
              [-80.798778223311118, 35.297723763021899],
              [-80.79883121671422, 35.297459130973415],
              [-80.798502747857668, 35.29741643589206],
              [-80.798555741956378, 35.297151803949603],
              [-80.798227274484645, 35.297109108118633],
              [-80.798280270378342, 35.29684447626574],
              [-80.797951804291486, 35.296801779685168],
              [-80.798004799761486, 35.296537147053343],
              [-80.797676336178782, 35.296494450608144],
              [-80.797729332344375, 35.296229818082239],
              [-80.797400870126651, 35.296187119986087],
              [-80.797453867007718, 35.295922488467575],
              [-80.797125405075576, 35.295879789638121],
              [-80.797178403731678, 35.295615157307971],
              [-80.79684994320418, 35.295572458630211],
              [-80.796902942555832, 35.295307826406102],
              [-80.796574484492652, 35.29526512606121],
              [-80.796680483162874, 35.294735862706844],
              [-80.796352026425367, 35.294693161770439],
              [-80.793724393708757, 35.294351521160046],
              [-80.793777402428006, 35.294086890246383],
              [-80.793448950932202, 35.294044181413597],
              [-80.793607978142191, 35.293250288883762],
              [-80.793936426517632, 35.293292997291104],
              [-80.794042440853701, 35.292763735142458],
              [-80.794370886394276, 35.292806442391374],
              [-80.794423892005213, 35.292541811121765],
              [-80.794752337949703, 35.292584517321259],
              [-80.794911348475026, 35.291790621988085],
              [-80.795239791663676, 35.291833327772288],
              [-80.795292792696529, 35.291568695191486],
              [-80.795621235169961, 35.291611399041422],
              [-80.795992228826861, 35.289758971640609],
              [-80.795663794733258, 35.289716268766796],
              [-80.795769792039309, 35.289187002983226],
              [-80.795441360370319, 35.289144299501707],
              [-80.795547359477169, 35.288615033842959],
              [-80.795218930212954, 35.288572328852439],
              [-80.795324928922014, 35.288043063351211],
              [-80.794996502102336, 35.288000358654287],
              [-80.795155501230923, 35.287206459651642],
              [-80.794827078975061, 35.287163754472473],
              [-80.794986077002804, 35.286369854690143],
              [-80.79465765821152, 35.286327149045078],
              [-80.794710658631004, 35.286062515543705],
              [-80.794382241224199, 35.286019809149217],
              [-80.794541241356086, 35.285225909791173],
              [-80.794212828493031, 35.285183202013243],
              [-80.79431882929083, 35.284653935274235],
              [-80.793990417772875, 35.28461122780628],
              [-80.794096419271966, 35.284081961208848],
              [-80.793768011257839, 35.284039252215791],
              [-80.79398001428001, 35.282980719162133],
              [-80.793651610769956, 35.282938009845104],
              [-80.793757612273225, 35.282408742487682],
              [-80.793429211207254, 35.282366033464385],
              [-80.793482213021463, 35.282101399415509],
              [-80.791840216901406, 35.28188783895294],
              [-80.79189322357017, 35.28162320557761],
              [-80.791564826651552, 35.281580491491042],
              [-80.791617834015469, 35.281315858222129],
              [-80.791289438461547, 35.281273142484856],
              [-80.791342446540355, 35.281008510223671],
              [-80.790685658545968, 35.280923076359464],
              [-80.790738669439094, 35.280658443428898],
              [-80.790410276998728, 35.280615725301971],
              [-80.790463287487697, 35.280351092493973],
              [-80.788492948520698, 35.28009476583739],
              [-80.788545966022454, 35.279830134730126],
              [-80.788217578054599, 35.279787409747797],
              [-80.788270596251394, 35.279522778746973],
              [-80.787942210767127, 35.279480052999084],
              [-80.78799522853997, 35.279215421219597],
              [-80.788048247067337, 35.27895078938851],
              [-80.787719864027025, 35.278908063934608],
              [-80.787825901027688, 35.278378800466108],
              [-80.787497520411492, 35.278336074404805],
              [-80.787550538865105, 35.278071441866139],
              [-80.787222159633288, 35.278028715055591],
              [-80.787275179900789, 35.277764083508487],
              [-80.7866184245309, 35.277678626596611],
              [-80.786671446533006, 35.27741399529787],
              [-80.786343070414702, 35.277371266097859],
              [-80.786396093091952, 35.277106634004326],
              [-80.786067718358026, 35.277063904055076],
              [-80.786014694621358, 35.277328535105433],
              [-80.784701196053902, 35.277157604912993],
              [-80.784648167813913, 35.277422235360469],
              [-80.78431979272456, 35.277379500672225],
              [-80.78426676310005, 35.27764413094247],
              [-80.783938387295976, 35.277601394319959],
              [-80.783832323854213, 35.278130655388019],
              [-80.782518816907384, 35.277959700638597],
              [-80.782571852399883, 35.277695070247205],
              [-80.782243477299843, 35.277652329928841],
              [-80.782296514606102, 35.277387700529168],
              [-80.780654651596308, 35.277173982643248],
              [-80.780707693755701, 35.27690935391778],
              [-80.780050952157765, 35.276823860462315],
              [-80.780103996031798, 35.276559231083993],
              [-80.77944726011043, 35.276473735217238],
              [-80.779394213038415, 35.276738363426553],
              [-80.778737475298584, 35.276652862826637],
              [-80.778684426921316, 35.276917491601949],
              [-80.778356058078799, 35.276874739815412],
              [-80.778303007198232, 35.277139367528129],
              [-80.777974637660947, 35.27709661470869],
              [-80.777921585415569, 35.277361243145407],
              [-80.776608104339374, 35.277190221520357],
              [-80.776555048689815, 35.277454849337772],
              [-80.775569937831378, 35.277326573119957],
              [-80.77551687759933, 35.277591199590894],
              [-80.775188507702822, 35.27754843921732],
              [-80.775241568974465, 35.277283812888349],
              [-80.774256462255693, 35.277155525978174],
              [-80.774203397865364, 35.277420151881202],
              [-80.773546660078168, 35.277334623130834],
              [-80.77359972654763, 35.277069997511724],
              [-80.772286260331484, 35.276898928067943],
              [-80.772233189703812, 35.277163553119074],
              [-80.771904822158291, 35.277120783851345],
              [-80.771851750146041, 35.277385408725088],
              [-80.771523381905993, 35.277342638424244],
              [-80.771470308509151, 35.277607263120657],
              [-80.771141939555008, 35.277564490885467],
              [-80.771088864773617, 35.277829115404401],
              [-80.770760495144614, 35.277786343037576],
              [-80.770707417879393, 35.278050967395139],
              [-80.770379048635419, 35.278008193077902],
              [-80.770272890990583, 35.278537441402804],
              [-80.769616148300159, 35.278451890444423],
              [-80.769563066880963, 35.278716514269739],
              [-80.769234694454468, 35.27867373686945],
              [-80.769181611650595, 35.278938360517223],
              [-80.767868123455642, 35.278767242306678],
              [-80.767815035049125, 35.279031865367017],
              [-80.767486663368331, 35.2789890832114],
              [-80.767539751734745, 35.278724461210537],
              [-80.766883010388625, 35.278638894526409],
              [-80.766936100469493, 35.278374271872856],
              [-80.766607731903207, 35.278331487328302],
              [-80.766660822678446, 35.278066864781273],
              [-80.766332455496851, 35.278024079487743],
              [-80.766385548065585, 35.277759457031358],
              [-80.766057181189225, 35.277716671906234],
              [-80.766375733352021, 35.276128935505518],
              [-80.766704092911908, 35.276171720695793],
              [-80.768137381085012, 35.269026866783626],
              [-80.767809051786983, 35.268984085396532],
              [-80.768074451995389, 35.267660957630817],
              [-80.767746128238869, 35.267618176063053],
              [-80.767852287973625, 35.267088924453546],
              [-80.768180609671177, 35.26713170663843],
              [-80.76839291972459, 35.266073201543641],
              [-80.768064602203594, 35.266030420828173],
              [-80.768117680163442, 35.265765794379213],
              [-80.76778936512568, 35.265723012898867],
              [-80.767948598973277, 35.264929134684529],
              [-80.767620287398103, 35.264886352739587],
              [-80.767673366056314, 35.264621726434015],
              [-80.767016744901142, 35.264536159270399],
              [-80.767069825292609, 35.264271533213801],
              [-80.766741516831033, 35.264228748880456],
              [-80.766794597916544, 35.263964122930865],
              [-80.765809677700148, 35.263835764110524],
              [-80.765862761558196, 35.263571138551995],
              [-80.765534456535519, 35.263528350038115],
              [-80.765587541087754, 35.263263724586501],
              [-80.765259237468939, 35.263220936225125],
              [-80.765206151877663, 35.263485561534644],
              [-80.764549543577346, 35.263399980954063],
              [-80.764602631247001, 35.263135355928704],
              [-80.763946027484806, 35.263049771151607],
              [-80.763999116887447, 35.262785146375315],
              [-80.76334251660343, 35.262699559220465],
              [-80.763395607739383, 35.262434934693232],
              [-80.762082415449669, 35.262263749361011],
              [-80.762029319058485, 35.262528373335861],
              [-80.761372722915979, 35.262442775491351],
              [-80.761319624101461, 35.262707399146819],
              [-80.76066302614295, 35.262621796569562],
              [-80.760609924924566, 35.262886420807007],
              [-80.759953327367484, 35.262800814366365],
              [-80.759900223706254, 35.263065437383119],
              [-80.758915326312305, 35.262937021524046],
              [-80.758862218089646, 35.263201644095233],
              [-80.757549021848746, 35.263030408713597],
              [-80.757602134208284, 35.262765785809663],
              [-80.757273837874422, 35.262722974867401],
              [-80.757220724475815, 35.262987597629255],
              [-80.755907536219311, 35.262816343514466],
              [-80.755854418299705, 35.263080964771063],
              [-80.755197823075733, 35.262995332551249],
              [-80.755144702732721, 35.263259953488465],
              [-80.754488107891305, 35.2631743165038],
              [-80.754434985124846, 35.263438937121514],
              [-80.754106686643283, 35.263396117609489],
              [-80.754053562492444, 35.263660738049865],
              [-80.753725263317122, 35.263617917504874],
              [-80.753672137781905, 35.263882537767913],
              [-80.753343837893425, 35.263839715288597],
              [-80.753290709874847, 35.264104335390215],
              [-80.752634110174156, 35.264018688360174],
              [-80.752580979732073, 35.264283308142311],
              [-80.75126777921983, 35.264112002839859],
              [-80.751214644276089, 35.264376622018005],
              [-80.750229744666328, 35.264248132806557],
              [-80.750176606260084, 35.264512751522915],
              [-80.749848305669047, 35.2644699195668],
              [-80.74974202574198, 35.264999156609349],
              [-80.749413723418428, 35.264956323478103],
              [-80.749360581897676, 35.265220941804252],
              [-80.749032278880392, 35.265178107640054],
              [-80.748979135975119, 35.265442725788752],
              [-80.748650832264218, 35.265399890591446],
              [-80.748597686856073, 35.265664507677364],
              [-80.747612775797222, 35.265535997199436],
              [-80.747559626945744, 35.265800614724682],
              [-80.747231322538553, 35.265757775679724],
              [-80.747178172283171, 35.266022392126203],
              [-80.746849867182391, 35.265979552048215],
              [-80.746796715561771, 35.266244169218581],
              [-80.746468409767431, 35.266201328107542],
              [-80.746415256742878, 35.266465944199069],
              [-80.746086950254949, 35.266423102054993],
              [-80.74603379584579, 35.266687717969049],
              [-80.74570548868364, 35.266644875693153],
              [-80.745652332889861, 35.266909491429779],
              [-80.744667410301389, 35.266780956126702],
              [-80.744614251044794, 35.267045571401226],
              [-80.743629327350092, 35.266917027669955],
              [-80.743682490822906, 35.266652412806366],
              [-80.74302587956511, 35.266566712805549],
              [-80.743079043671486, 35.266302098207014],
              [-80.742422435854877, 35.266216394026138],
              [-80.742475602792766, 35.265951779661222],
              [-80.742147300451535, 35.265908926827507],
              [-80.742200468082856, 35.26564431256952],
              [-80.74187216710699, 35.265601458086074],
              [-80.74181899845594, 35.265866073103084],
              [-80.741490697885624, 35.265823217570713],
              [-80.741162396581231, 35.265780362064653],
              [-80.741215567329647, 35.265515748233554],
              [-80.739245775155155, 35.265258595510602],
              [-80.739192598172636, 35.265523208487899],
              [-80.737551109345162, 35.265308888137355],
              [-80.737604291522672, 35.265044275871659],
              [-80.737275996484911, 35.26500140890154],
              [-80.737382362960517, 35.264472183631931],
              [-80.737054070365645, 35.26442931695702],
              [-80.737107253564773, 35.264164703968937],
              [-80.736778962354563, 35.264121836545677],
              [-80.736832147345822, 35.26385722364892],
              [-80.73650385640218, 35.26381435459168],
              [-80.736557042086773, 35.263549741802045],
              [-80.734587316550247, 35.263292511315136],
              [-80.734693699349307, 35.262763287338359],
              [-80.734037131079205, 35.262677536685395],
              [-80.734090324037965, 35.262412924928832],
              [-80.732448916805808, 35.262198533389252],
              [-80.732395718652683, 35.262463144433923],
              [-80.732067436765035, 35.262420263317779],
              [-80.732120635957145, 35.262155652415544],
              [-80.731792356553157, 35.262112770535268],
              [-80.73184555643833, 35.261848159740182],
              [-80.73151727841902, 35.261805277111677],
              [-80.73157047899727, 35.261540666423777],
              [-80.731242202362623, 35.26149778304697],
              [-80.731295403614737, 35.261233171564875],
              [-80.730967128364725, 35.261190287439732],
              [-80.731020330329073, 35.260925676966238],
              [-80.731073533046498, 35.260661066441848],
              [-80.731401806218827, 35.260703950282192],
              [-80.731455007551631, 35.260439339580223],
              [-80.732111552856026, 35.260525104304172],
              [-80.732164750666499, 35.260260493297913],
              [-80.732821296375135, 35.260346254158613],
              [-80.732874491762274, 35.260081642832517],
              [-80.733202763546984, 35.260124521792299],
              [-80.73325595753019, 35.25985990938738],
              [-80.733584229720734, 35.259902787298358],
              [-80.733637422338631, 35.259638175617248],
              [-80.734293963481562, 35.259723928513949],
              [-80.734347153656813, 35.259459315611636],
              [-80.734675423707998, 35.259502190581571],
              [-80.734728612517998, 35.259237578403088],
              [-80.735056881876062, 35.259280452340001],
              [-80.735110068183445, 35.259015839098446],
              [-80.735438337947301, 35.259058711986555],
              [-80.735544708546229, 35.25852948509764],
              [-80.735872975479253, 35.258572356826136],
              [-80.735926158671944, 35.258307743194528],
              [-80.736254424911834, 35.258350613889981],
              [-80.736467152268418, 35.257292158411722],
              [-80.736573513872813, 35.256762930462003],
              [-80.736245253865647, 35.256720060620637],
              [-80.736457977043258, 35.255661603067296],
              [-80.736129722635582, 35.255618732888841],
              [-80.736395623465185, 35.25429566042758],
              [-80.736067374596843, 35.254252790070346],
              [-80.736492805979793, 35.252135870569596],
              [-80.736164565766302, 35.252093000460604],
              [-80.736536806588646, 35.250240691462487],
              [-80.736208575089989, 35.250197821443614],
              [-80.736368104934954, 35.249403973472035],
              [-80.736039876897763, 35.249361102989688],
              [-80.736305757521336, 35.248038023061305],
              [-80.735977536101942, 35.247995151483131],
              [-80.736190237636336, 35.246936686593251],
              [-80.735862020735752, 35.246893815595335],
              [-80.736021547448345, 35.246099965846646],
              [-80.735693333989715, 35.246057093483969],
              [-80.735959209379502, 35.244734009994161],
              [-80.735631001478041, 35.244691138354156],
              [-80.735737350844701, 35.244161904644997],
              [-80.736065556669274, 35.244204776000331],
              [-80.736171902576913, 35.243675541867248],
              [-80.736500105571494, 35.243718412063096],
              [-80.736659618706909, 35.242924559274179],
              [-80.736987820049634, 35.242967429038011],
              [-80.737147325842187, 35.242173574623529],
              [-80.737475523316533, 35.242216443085596],
              [-80.737794519323444, 35.240628731563035],
              [-80.738122710893734, 35.240671597379077],
              [-80.738229037682785, 35.240142359347232],
              [-80.73790084928784, 35.240099493800152],
              [-80.738060339426795, 35.239305636025755],
              [-80.737732154492349, 35.239262770015237],
              [-80.737838481960821, 35.238733531304533],
              [-80.737510299448459, 35.23869066468837],
              [-80.737563463163127, 35.238426045881944],
              [-80.737235282015334, 35.238383177616313],
              [-80.737341611564233, 35.237853939267147],
              [-80.737013432857722, 35.237811071297166],
              [-80.737066598691925, 35.237546451754383],
              [-80.736738421349884, 35.237503582135126],
              [-80.736791586797523, 35.237238963617131],
              [-80.736463410839306, 35.237196093249743],
              [-80.73651657805911, 35.236931473922418],
              [-80.73618840350386, 35.236888603708415],
              [-80.736241571416443, 35.236623984488809],
              [-80.735257053998552, 35.236495367113058],
              [-80.735310224680376, 35.236230748285976],
              [-80.734325713486641, 35.236102123323931],
              [-80.734272538591625, 35.236366741739573],
              [-80.733944368572438, 35.236323864518127],
              [-80.733891192293584, 35.236588482756758],
              [-80.733563021581787, 35.236545604502517],
              [-80.733616197800302, 35.236280986422045],
              [-80.732959858391226, 35.236195227542922],
              [-80.733013036340637, 35.235930609712625],
              [-80.732684867643357, 35.235887729087729],
              [-80.73273804738399, 35.235623111349554],
              [-80.732409880070477, 35.235580229976719],
              [-80.732463060503662, 35.235315612346263],
              [-80.732134894573989, 35.23527273022551],
              [-80.732188075699796, 35.235008112702872],
              [-80.731859912252332, 35.234965229818464],
              [-80.731913092972306, 35.234700612419353],
              [-80.731584930908568, 35.234657728786921],
              [-80.73163811232115, 35.234393111495621],
              [-80.731309951641151, 35.234350227115328],
              [-80.731363134825713, 35.234085609014819],
              [-80.73103497552944, 35.234042723886624],
              [-80.731194525063884, 35.233248871623204],
              [-80.730866369227513, 35.233205986031855],
              [-80.731079100490405, 35.232147514941254],
              [-80.730750950250652, 35.232104629013591],
              [-80.730804132686529, 35.231840011079498],
              [-80.730475983830431, 35.231797124404068],
              [-80.730529168057245, 35.231532506562147],
              [-80.730201019486287, 35.231489619154537],
              [-80.730254204405696, 35.231225001420519],
              [-80.729926057199251, 35.23118211236369],
              [-80.729979242811169, 35.230917494737518],
              [-80.729322953746404, 35.230831716009099],
              [-80.72937613999035, 35.230567098649068],
              [-80.728719854364797, 35.230481315742949],
              [-80.72877304343784, 35.230216698617603],
              [-80.728116761270741, 35.230130912435285],
              [-80.728169952055012, 35.229866294658926],
              [-80.727841812537491, 35.22982340082546],
              [-80.727895004033314, 35.229558784058462],
              [-80.727566865880291, 35.229515888575882],
              [-80.727620058068524, 35.229251271916844],
              [-80.726963784894465, 35.229165479466964],
              [-80.727016979892326, 35.228900862141373],
              [-80.726688844871276, 35.228857965174214],
              [-80.726795234807838, 35.228328730720172],
              [-80.72646710220836, 35.228285833147609],
              [-80.726626687104712, 35.227491982544549],
              [-80.72629855794564, 35.227449083607937],
              [-80.726458142826246, 35.226655232220793],
              [-80.726130018244149, 35.226612333706953],
              [-80.726289603108896, 35.225818481535903],
              [-80.725961481966962, 35.225775581658041],
              [-80.726067872603394, 35.225246347585824],
              [-80.725739753882962, 35.225203447102722],
              [-80.726005727929476, 35.223880360253261],
              [-80.7256776158424, 35.223837459576629],
              [-80.725784004674196, 35.22330822470709],
              [-80.725455895008409, 35.223265323425323],
              [-80.725509089934249, 35.223000705630824],
              [-80.725180981651903, 35.222957803601382],
              [-80.72512778568823, 35.223222421253411],
              [-80.724799675615259, 35.223179518206855],
              [-80.724746478249074, 35.223444134780685],
              [-80.724418367483892, 35.223401230701505],
              [-80.724365168753337, 35.223665847999747],
              [-80.723708947282617, 35.223580036870153],
              [-80.723655745032346, 35.22384465386466],
              [-80.722999522868548, 35.223758838889204],
              [-80.722946318177804, 35.224023454662948],
              [-80.722618207125777, 35.223980545689621],
              [-80.722565001051706, 35.22424516128644],
              [-80.721580664659299, 35.22411642862896],
              [-80.721527455125951, 35.224381043763792],
              [-80.721199343381016, 35.224338130944631],
              [-80.72103970851397, 35.225131976632035],
              [-80.720711592902703, 35.225089062510705],
              [-80.720605166573236, 35.225618291900453],
              [-80.72027704923218, 35.225575376603878],
              [-80.720223833412575, 35.22583999112009],
              [-80.717270786798764, 35.225453709357502],
              [-80.71721756129287, 35.225718322556432],
              [-80.715905100591627, 35.225546617461823],
              [-80.715958330248924, 35.225282004833268],
              [-80.71431776813813, 35.22506735278499],
              [-80.714211297001128, 35.225596576597397],
              [-80.713883184215277, 35.225553643943726],
              [-80.713829946540855, 35.22581825566342],
              [-80.714158061463138, 35.225861188444114],
              [-80.714051588250911, 35.22639041204971],
              [-80.713723471234005, 35.226347478082509],
              [-80.71361699456267, 35.22687670126502],
              [-80.71328887581609, 35.226833766122404],
              [-80.713235635374474, 35.227098377527099],
              [-80.712579396843353, 35.227012504285995],
              [-80.712526153980136, 35.227277115371052],
              [-80.712198034195609, 35.227234177272543],
              [-80.712144791047081, 35.227498788164866],
              [-80.711816670570656, 35.227455849033632],
              [-80.711763424920946, 35.227720458863068],
              [-80.710779062472739, 35.227591636601467],
              [-80.710725812265096, 35.227856245984078],
              [-80.709741449798031, 35.227727414381512],
              [-80.709794703119186, 35.227462805426711],
              [-80.708810346860659, 35.227333965338843],
              [-80.708863602949251, 35.227069356777584],
              [-80.70787925291809, 35.22694050910588],
              [-80.707932511774089, 35.226675900937991],
              [-80.70760439791276, 35.226632949460004],
              [-80.707657656362059, 35.226368341415743],
              [-80.707329543884541, 35.226325389190158],
              [-80.707382804123952, 35.226060781238552],
              [-80.706726581202972, 35.225974875319125],
              [-80.7067798431531, 35.22571026671703],
              [-80.70612362369171, 35.225624357522179],
              [-80.706176887390285, 35.225359750072244],
              [-80.705848778657696, 35.22531679383934],
              [-80.705902044127427, 35.225052185580786],
              [-80.705245830893404, 35.2249662716156],
              [-80.705299096994111, 35.224701663623421],
              [-80.704642888318205, 35.224615746367583],
              [-80.704696156148202, 35.224351138626197],
              [-80.703711848833578, 35.224222256043703],
              [-80.703765120529113, 35.223957648680539],
              [-80.702124621534168, 35.223742826670666],
              [-80.702177896973609, 35.223478220001901],
              [-80.701521702544127, 35.223392285784023],
              [-80.701574980792259, 35.223127678449522],
              [-80.701246884575752, 35.223084709705176],
              [-80.701353442127953, 35.222555496119554],
              [-80.701025348351692, 35.222512527671817],
              [-80.701078627636832, 35.222247920519536],
              [-80.700750536323909, 35.222204950407566],
              [-80.700803815202036, 35.221940343378918],
              [-80.700475725272881, 35.221897372519656],
              [-80.700529005959794, 35.221632766485193],
              [-80.699544740263619, 35.221503849010354],
              [-80.6995980236983, 35.221239242468315],
              [-80.699269937228934, 35.221196268334126],
              [-80.699376506233577, 35.220667055416889],
              [-80.699048422185541, 35.220624080678078],
              [-80.699101706657004, 35.220359474318258],
              [-80.698773623992608, 35.220316498832112],
              [-80.698880193972201, 35.219787286294874],
              [-80.698552113729164, 35.219744310204156],
              [-80.698605400316751, 35.219479703109933],
              [-80.698277321457411, 35.219436726271809],
              [-80.69838389351024, 35.218907514099278],
              [-80.698055818151687, 35.218864535739904],
              [-80.698109104696371, 35.218599929744855],
              [-80.697781030740302, 35.218556951539504],
              [-80.697834317957543, 35.218292344751504],
              [-80.697506245385057, 35.218249365798854],
              [-80.697559533293642, 35.217984759119247],
              [-80.697231462104895, 35.217941779419228],
              [-80.697284750723583, 35.217677173749372],
              [-80.696300541210945, 35.217548227950374],
              [-80.695644404005364, 35.217462259924289],
              [-80.695697698563578, 35.217197654918508],
              [-80.695369631508171, 35.217154669262577],
              [-80.695422925659201, 35.216890064380657],
              [-80.695094859987464, 35.216847077977469],
              [-80.695148155909393, 35.216582472287236],
              [-80.694492027698217, 35.216496497997639],
              [-80.694545325348813, 35.216231892558675],
              [-80.694217262790517, 35.216188903771005],
              [-80.694270561151058, 35.215924299341843],
              [-80.693614440246492, 35.215838319366604],
              [-80.693667740316883, 35.215573714287423],
              [-80.692683564138562, 35.21544473898701],
              [-80.692630260955951, 35.215709343637741],
              [-80.692302201512646, 35.215666349652899],
              [-80.692248896946367, 35.21593095412662],
              [-80.691920836811917, 35.215887959108848],
              [-80.69186753086197, 35.216152563405508],
              [-80.691211410655413, 35.216066570399008],
              [-80.691158101186062, 35.216331174391001],
              [-80.690501980289767, 35.216245177538951],
              [-80.690555290735702, 35.215980573847965],
              [-80.688586942582916, 35.215722562788706],
              [-80.688640260005045, 35.215457959905024],
              [-80.687656093167263, 35.215328942796752],
              [-80.687709414453323, 35.21506434029196],
              [-80.687381360204711, 35.215021332957221],
              [-80.687488001597345, 35.214492127259696],
              [-80.686831900485146, 35.214406110461098],
              [-80.686778577878016, 35.214670712590625],
              [-80.686450526804038, 35.214627702713429],
              [-80.686503850429943, 35.214363099825405],
              [-80.686175800739704, 35.214320089201216],
              [-80.686122474959703, 35.214584691060324],
              [-80.685466374525163, 35.214498665954928],
              [-80.685519702379878, 35.214234064381536],
              [-80.684535556349132, 35.214105020943258],
              [-80.684482226480768, 35.214369622072716],
              [-80.684154177831715, 35.21432660596588],
              [-80.684207508718757, 35.214062004077952],
              [-80.683551415614204, 35.21397596856346],
              [-80.68322336904167, 35.213932949929642],
              [-80.683276702694243, 35.213668348436052],
              [-80.682620613742841, 35.213582307867746],
              [-80.682567276898894, 35.213846908189481],
              [-80.680599009921295, 35.213588763397624],
              [-80.680652352988844, 35.213324163933329],
              [-80.678028026501906, 35.212979919755604],
              [-80.67797467403814, 35.213244518091749],
              [-80.677646634435916, 35.213201483412426],
              [-80.677593280607013, 35.213466082472657],
              [-80.677265239215757, 35.213423046775709],
              [-80.677211883984398, 35.21368764475735],
              [-80.674915599947767, 35.213386367111731],
              [-80.674968962439678, 35.213121770130861],
              [-80.674312886647812, 35.213035683593048],
              [-80.674366251947148, 35.212771085947189],
              [-80.674038215059213, 35.212728041494024],
              [-80.674144945612838, 35.212198847301885],
              [-80.673816912244078, 35.212155802229567],
              [-80.673977009126119, 35.211362010654838],
              [-80.673648979197083, 35.211318964220155],
              [-80.673702344448401, 35.211054367714105],
              [-80.673374315903132, 35.211011320532513],
              [-80.673427682924796, 35.210746723218577],
              [-80.673099655781812, 35.210703676191507],
              [-80.673153023493867, 35.210439078986298],
              [-80.672824997716148, 35.210396030310996],
              [-80.67287836611878, 35.210131433214492],
              [-80.672550341743332, 35.210088384693663],
              [-80.672603709738198, 35.209823787721092],
              [-80.672275687826158, 35.209780737536782],
              [-80.672329057609701, 35.209516140657811],
              [-80.672001035983257, 35.209473089741884],
              [-80.672054406457164, 35.209208492971634],
              [-80.671726386232976, 35.2091654422102],
              [-80.671779757378886, 35.20890084464741],
              [-80.671123721124374, 35.208814739824376],
              [-80.671177094016272, 35.208550143414762],
              [-80.670849077985849, 35.208507089353212],
              [-80.670902451568139, 35.208242493052381],
              [-80.670574436921541, 35.20819943824398],
              [-80.670627811175635, 35.207934841150546],
              [-80.670299797931321, 35.207891786496738],
              [-80.670353172875778, 35.207627189512117],
              [-80.670459921743344, 35.207097996341574],
              [-80.670131911938213, 35.207054940325648],
              [-80.67023866149303, 35.206525747304404],
              [-80.669910654108605, 35.206482690684609],
              [-80.670017404332256, 35.205953496911313],
              [-80.670345409642778, 35.205996553245051],
              [-80.670398783197555, 35.205731956164151],
              [-80.670452156405787, 35.205467359049074],
              [-80.670124153169326, 35.20542430300145],
              [-80.67033764666499, 35.204365913870383],
              [-80.670009649039685, 35.204322858391222],
              [-80.670489997291114, 35.201941478772355],
              [-80.670162010424278, 35.20189842277415],
              [-80.670322122628846, 35.201104628492111],
              [-80.669994139219369, 35.201061572033197],
              [-80.670047510663778, 35.200796974009052],
              [-80.669719527539669, 35.200753916818599],
              [-80.669772899674399, 35.200489318903401],
              [-80.66944491903179, 35.200446260951097],
              [-80.669391545860293, 35.200710858723255],
              [-80.669063563429347, 35.200667799753262],
              [-80.668735582443261, 35.200624739878428],
              [-80.668788957670074, 35.200360141490997],
              [-80.668132997713428, 35.200274019373424],
              [-80.668186374685618, 35.20000942213936],
              [-80.667858396794699, 35.199966358980113],
              [-80.667911774457153, 35.199701761855046],
              [-80.667583797968177, 35.199658698850463],
              [-80.667637177400323, 35.199394100917935],
              [-80.66730920119673, 35.199351037181827],
              [-80.667362581337542, 35.199086440259585],
              [-80.667034607596975, 35.199043374860331],
              [-80.667087987329822, 35.198778778062334],
              [-80.666760014972795, 35.198735711916441],
              [-80.666813396493836, 35.19847111521235],
              [-80.666485425520179, 35.198428048319819],
              [-80.666592188479243, 35.197898854209512],
              [-80.666264219925893, 35.197855786713404],
              [-80.666317602480319, 35.197591190193414],
              [-80.66598963531041, 35.197548121950682],
              [-80.666096400317969, 35.197018927307312],
              [-80.665768435586656, 35.196975859362375],
              [-80.665821819156079, 35.19671126212522],
              [-80.66450996998536, 35.196538979286615],
              [-80.66456335735505, 35.196274382587809],
              [-80.66390743803062, 35.196188236552182],
              [-80.6639608271269, 35.195923640105555],
              [-80.663632867914203, 35.195880565460826],
              [-80.663686257700533, 35.195615969123338],
              [-80.663358300969207, 35.195572893716921],
              [-80.6634116914271, 35.195308296587214],
              [-80.662755781096536, 35.195222144292977],
              [-80.662809173281033, 35.194957547415513],
              [-80.65952965490375, 35.194526729459746],
              [-80.658217857464166, 35.194354376463323],
              [-80.658324672032848, 35.193825187485224],
              [-80.657996726441326, 35.19378209751239],
              [-80.658156947695815, 35.192988313784554],
              [-80.657829005560842, 35.192945223351586],
              [-80.657882413348517, 35.192680628545446],
              [-80.657554472578525, 35.192637536464673],
              [-80.657714695890533, 35.19184375318865],
              [-80.657386758577104, 35.19180066064775],
              [-80.657493575177241, 35.191271471302954],
              [-80.657165640283694, 35.191228378158783],
              [-80.65721904909995, 35.190963783578731],
              [-80.656891115608076, 35.190920690589387],
              [-80.65694452509581, 35.190656095217314],
              [-80.656616594085236, 35.190613001466517],
              [-80.656670004280869, 35.190348407105013],
              [-80.656342073537317, 35.190305311721595],
              [-80.656395484422703, 35.190040717469387],
              [-80.656067555062407, 35.189997621339572],
              [-80.656120967735404, 35.189733027181646],
              [-80.655793039758507, 35.189689930305434],
              [-80.655846452004752, 35.189425335370466],
              [-80.655518526527445, 35.189382238634202],
              [-80.655625352052539, 35.188853048949127],
              [-80.655297428994899, 35.188809951609656],
              [-80.655350842273634, 35.188545356859535],
              [-80.655022920581047, 35.188502257872344],
              [-80.655183162561684, 35.187708474734855],
              [-80.654855244325205, 35.187665375287679],
              [-80.655015485177287, 35.186871591389071],
              [-80.65468757039686, 35.186828491481926],
              [-80.654740985454893, 35.186563896909597],
              [-80.654413072057608, 35.186520796256147],
              [-80.654466486707264, 35.186256201808241],
              [-80.654138575791109, 35.186213100393353],
              [-80.654191991112, 35.185948505153505],
              [-80.653864080481256, 35.185905403007403],
              [-80.65391749760785, 35.185640808763395],
              [-80.652933772003635, 35.185511497402587],
              [-80.652987191892336, 35.185246903554315],
              [-80.651347662783422, 35.185031366624514],
              [-80.651294238829138, 35.185295960643046],
              [-80.650966332356646, 35.185252850634676],
              [-80.65101975844513, 35.184988256744369],
              [-80.650691854453669, 35.184945145974744],
              [-80.650745281231565, 35.184680552193875],
              [-80.651012408837829, 35.183357583699539],
              [-80.650684511411129, 35.183314472899802],
              [-80.650791362529674, 35.182785285185808],
              [-80.649807679628083, 35.182655947392938],
              [-80.64986110777582, 35.182391353915044],
              [-80.649205323341633, 35.18230512425351],
              [-80.649258753214923, 35.182040531028385],
              [-80.64893086311254, 35.181997415449906],
              [-80.648984293656824, 35.181732821432973],
              [-80.648656403839723, 35.181689705123297],
              [-80.64870983507339, 35.181425111215901],
              [-80.648381947737292, 35.18138199414507],
              [-80.648435379678375, 35.181117401248578],
              [-80.647451722823718, 35.180988043326593],
              [-80.647505157508306, 35.180723449924798],
              [-80.647177273632352, 35.180680329582792],
              [-80.647230709006095, 35.180415736290598],
              [-80.647558591864197, 35.18045885739069],
              [-80.647718893911403, 35.179665077768625],
              [-80.648046773989719, 35.179708196648257],
              [-80.648100207019525, 35.179443603821227],
              [-80.648428085310684, 35.179486721683062],
              [-80.64853494712294, 35.178957533853776],
              [-80.648862824804766, 35.179000651426129],
              [-80.648916253605464, 35.178736057325217],
              [-80.649244129500218, 35.178779173879867],
              [-80.649297556917816, 35.178514579602101],
              [-80.649625433202971, 35.178557694207761],
              [-80.649678859237625, 35.178293099753112],
              [-80.65000673483344, 35.178336213326197],
              [-80.650060159485065, 35.178071618694659],
              [-80.650388033312055, 35.178114732151393],
              [-80.65044145658068, 35.177850137342993],
              [-80.65076932970014, 35.177893248865736],
              [-80.650822751585778, 35.177628653880554],
              [-80.651150625113743, 35.177671764355594],
              [-80.651204044518636, 35.177407169208493],
              [-80.651531917357303, 35.177450278651065],
              [-80.65158533537921, 35.177185683327103],
              [-80.651913207528594, 35.177228791736994],
              [-80.652020041539132, 35.176699599785479],
              [-80.652347910883648, 35.176742707935894],
              [-80.65240132577452, 35.176478111323334],
              [-80.652729195509238, 35.176521217524865],
              [-80.652836022196709, 35.175992024813951],
              [-80.653163889108214, 35.17603512985459],
              [-80.653324124518917, 35.175241339641104],
              [-80.653651988668841, 35.17528444336277],
              [-80.654079256556727, 35.173167663573658],
              [-80.653751400677052, 35.173124560096433],
              [-80.654018436974454, 35.171801570214555],
              [-80.654071843186472, 35.171536971777186],
              [-80.654399691752303, 35.171580074410123],
              [-80.654453096581591, 35.171315475796064],
              [-80.654780944439864, 35.17135857649513],
              [-80.654834347886421, 35.171093977704373],
              [-80.655162195073714, 35.171137078272253],
              [-80.655215597137584, 35.17087247930484],
              [-80.655871290443713, 35.170958675683629],
              [-80.655817890451942, 35.171223274937439],
              [-80.65647358833273, 35.171309468931561],
              [-80.656420190066186, 35.171574068438353],
              [-80.657731594095395, 35.171746444524793],
              [-80.657784987138385, 35.17148184536169],
              [-80.658440689160841, 35.17156802778252],
              [-80.658494079766896, 35.171303427398222],
              [-80.658821929713127, 35.171346517139071],
              [-80.658875318936552, 35.171081916578132],
              [-80.659531018894612, 35.171168093090266],
              [-80.659584405699263, 35.17090349220954],
              [-80.660240103874003, 35.17098966489317],
              [-80.66034687120181, 35.170460462473834],
              [-80.660674719816399, 35.170503546278873],
              [-80.660728101375625, 35.170238944883437],
              [-80.661055948221318, 35.170282028572402],
              [-80.661109329495659, 35.170017426985318],
              [-80.661437175651912, 35.1700605096418],
              [-80.661490554427616, 35.169795906991951],
              [-80.66214624680552, 35.169882069335578],
              [-80.662199623180939, 35.169617467267287],
              [-80.662855313756879, 35.169703624881052],
              [-80.662908687713767, 35.169439022493158],
              [-80.663236532494736, 35.169482100273576],
              [-80.663343277260253, 35.168952893308493],
              [-80.663671120315726, 35.168995969913333],
              [-80.663724491161076, 35.168731367138903],
              [-80.664052333508636, 35.168774441809937],
              [-80.664105702971597, 35.168509838859016],
              [-80.664433544629546, 35.168552912497681],
              [-80.664807112708928, 35.166700684512165],
              [-80.665134946363935, 35.16674375627467],
              [-80.66524167412166, 35.166214547445719],
              [-80.665569507167177, 35.166257618918912],
              [-80.665622868923194, 35.165993013417655],
              [-80.665950701279186, 35.166036083858451],
              [-80.666004061671273, 35.16577147908189],
              [-80.666331892221677, 35.16581454760415],
              [-80.666385252329036, 35.165549942636147],
              [-80.666713082189915, 35.165593010125917],
              [-80.666873155112029, 35.164799193721727],
              [-80.667200982211583, 35.164842259892893],
              [-80.667414403384768, 35.163783835484949],
              [-80.667742225607782, 35.163826901110887],
              [-80.667848932583993, 35.163297687962952],
              [-80.66817675306315, 35.163340751512088],
              [-80.668230104447034, 35.163076144752644],
              [-80.668557925352673, 35.163119208155628],
              [-80.668611275354337, 35.162854601219848],
              [-80.668939095551977, 35.16289766268919],
              [-80.668992443073819, 35.162633055592245],
              [-80.669320262600365, 35.162676116930513],
              [-80.669373609819132, 35.162411508740732],
              [-80.669701427558621, 35.162454569061886],
              [-80.669754773395098, 35.162189960695699],
              [-80.670082591542595, 35.162233019969364],
              [-80.670936051417883, 35.157999281247633],
              [-80.670608250959759, 35.157956225149661],
              [-80.67066158979361, 35.157691615333434],
              [-80.670989389234435, 35.157734672189669],
              [-80.671202738080254, 35.156676232907508],
              [-80.671530532626861, 35.156719288317404],
              [-80.671583867658015, 35.156454678049464],
              [-80.671256074165655, 35.156411623684001],
              [-80.671362745260822, 35.155882403335006],
              [-80.671690537797616, 35.155925458300473],
              [-80.671743871790127, 35.155660847933355],
              [-80.672071662521418, 35.155703900980427],
              [-80.672178327396324, 35.155174679860629],
              [-80.672506116420934, 35.15521773263378],
              [-80.672559447303442, 35.154953121881228],
              [-80.672887235619939, 35.154996172720708],
              [-80.672940565120541, 35.154731561792005],
              [-80.673268351650009, 35.154774611614464],
              [-80.67337500754131, 35.154245389371809],
              [-80.673702793442999, 35.15428843800381],
              [-80.673756118736236, 35.154023826705043],
              [-80.674083903948386, 35.154066874304775],
              [-80.67413722895725, 35.153802262814736],
              [-80.674465013479903, 35.15384530938222],
              [-80.674571659271791, 35.153316085130854],
              [-80.674899440990245, 35.153359131439622],
              [-80.674952762870831, 35.153094518663039],
              [-80.675280543899632, 35.153137563939595],
              [-80.675387183453623, 35.152608338016591],
              [-80.676042743485752, 35.152694424413525],
              [-80.676096060123527, 35.152429811123987],
              [-80.677079400199844, 35.152558933163434],
              [-80.67702608666913, 35.152823546882011],
              [-80.678337216099649, 35.152995696518488],
              [-80.678390524408684, 35.152731083144282],
              [-80.679046089677527, 35.152817152333839],
              [-80.67909939555048, 35.152552537739261],
              [-80.679427177668273, 35.152595570857258],
              [-80.679533787402292, 35.15206634126794],
              [-80.679861567794703, 35.152109373210791],
              [-80.680287983471075, 35.149992450603328],
              [-80.679960211364119, 35.149949419804635],
              [-80.68006681415973, 35.149420188655014],
              [-80.680394583098092, 35.149463219182934],
              [-80.680874266424453, 35.147081672977215],
              [-80.680546506806593, 35.147038643736316],
              [-80.680653100856645, 35.146509410075694],
              [-80.680325344753228, 35.146466380216623],
              [-80.680485237447144, 35.145672530343774],
              [-80.680157483698935, 35.145629500039895],
              [-80.680210782326469, 35.145364882881879],
              [-80.680538533941771, 35.145407913058001],
              [-80.680591830109051, 35.145143296640768],
              [-80.680919581034857, 35.145186325784877],
              [-80.680972875802027, 35.144921708290468],
              [-80.681300626038109, 35.144964736402436],
              [-80.681407211385107, 35.144435501945743],
              [-80.681734959896161, 35.144478528882708],
              [-80.682161281266104, 35.142361584063096],
              [-80.682489021839032, 35.142404608966942],
              [-80.682595596089314, 35.141875371479991],
              [-80.682923333839952, 35.14191839522406],
              [-80.68334960868647, 35.139801441020381],
              [-80.683021879219368, 35.139758418420399],
              [-80.683128446534212, 35.139229179377779],
              [-80.682800719483737, 35.139186156174716],
              [-80.682907287467557, 35.138656916385976],
              [-80.682579563930929, 35.138613892564791],
              [-80.682632847881464, 35.138349272321399],
              [-80.682305125726032, 35.138306247754166],
              [-80.682411695776281, 35.137777008341445],
              [-80.682083974940127, 35.137733983186536],
              [-80.682137261021253, 35.137469363115898],
              [-80.68180954266343, 35.137426337199763],
              [-80.681916114780194, 35.136897098163196],
              [-80.681588398820367, 35.136854070742785],
              [-80.681694971624367, 35.136324831861792],
              [-80.681367258080982, 35.136281803838386],
              [-80.681527118319977, 35.135487944341577],
              [-80.68185482875748, 35.13553097193585],
              [-80.682014681662523, 35.134737110830429],
              [-80.682342389340093, 35.134780137106702],
              [-80.682502236027034, 35.13398627527895],
              [-80.682829939828693, 35.134029299351148],
              [-80.682989780279314, 35.133235435899827],
              [-80.6833174813396, 35.133278459555427],
              [-80.683370760488287, 35.133013837886196],
              [-80.683698460859205, 35.133056860509939],
              [-80.683751737529604, 35.132792238680452],
              [-80.684079437192423, 35.132835259370843],
              [-80.684132712481841, 35.132570637365802],
              [-80.684460411473793, 35.132613657925681],
              [-80.684620232162246, 35.131819791286347],
              [-80.684947928375522, 35.131862809626888],
              [-80.685267553090938, 35.130275073726494],
              [-80.685595243438726, 35.130318090320173],
              [-80.68586158138406, 35.128994974427762],
              [-80.686189265804543, 35.129037989432945],
              [-80.686242530651981, 35.128773365482445],
              [-80.686570215498833, 35.128816380341725],
              [-80.686623478965359, 35.12855175621582],
              [-80.687278846491225, 35.128637781194399],
              [-80.687438629703465, 35.12784390774884],
              [-80.687766310349474, 35.127886919384956],
              [-80.688405396268379, 35.124711415608203],
              [-80.688733063741694, 35.124754424655009],
              [-80.688786317519444, 35.124489798537532],
              [-80.689113985381695, 35.124532805635759],
              [-80.689167237778804, 35.124268179342955],
              [-80.689822570294453, 35.124354191519195],
              [-80.689929069911784, 35.123824938264633],
              [-80.69025673460844, 35.123867942282892],
              [-80.690309982863752, 35.123603315464109],
              [-80.690637646870968, 35.123646318450668],
              [-80.690744139159079, 35.123117063544228],
              [-80.69107180146041, 35.123160066257341],
              [-80.691125046590287, 35.122895438154401],
              [-80.691452708202092, 35.122938439835735],
              [-80.691505950854477, 35.122673811572817],
              [-80.691833611758042, 35.122716811321034],
              [-80.691993335671484, 35.121922927697106],
              [-80.692320993815457, 35.121965926127658],
              [-80.69242747188936, 35.121436669640886],
              [-80.692755128308775, 35.121479666896718],
              [-80.692861602912117, 35.120950409093638],
              [-80.693189257606974, 35.120993405174808],
              [-80.693242493355513, 35.120728776081897],
              [-80.693570147360703, 35.120771771131345],
              [-80.693623381728798, 35.1205071418633],
              [-80.694278687627303, 35.120593129926661],
              [-80.69433191957998, 35.120328500340435],
              [-80.694659572551444, 35.120371492437648],
              [-80.694712802007984, 35.120106861790354],
              [-80.695040454289966, 35.120149852855825],
              [-80.695093682384723, 35.119885222934656],
              [-80.694766032234853, 35.119842231996721],
              [-80.694978947475505, 35.11878371162949],
              [-80.694651301810893, 35.118740720374461],
              [-80.694757759419389, 35.118211459832828],
              [-80.694864215646419, 35.117682199162395],
              [-80.694536574466966, 35.117639207590265],
              [-80.694589802539483, 35.117374576906705],
              [-80.694262162740358, 35.117331584588804],
              [-80.694368622112933, 35.116802323380298],
              [-80.694040984729, 35.116759330459537],
              [-80.694147443692884, 35.11623006942358],
              [-80.693492174100683, 35.116144081487583],
              [-80.693545405664111, 35.115879450298209],
              [-80.693217773518001, 35.115836455124693],
              [-80.693271004692662, 35.115571824962835],
              [-80.692943373926724, 35.115528829043534],
              [-80.693049838387836, 35.114999567992662],
              [-80.69272221003699, 35.114956571470458],
              [-80.692881907781839, 35.11416267962376],
              [-80.692554282880906, 35.114119682641672],
              [-80.692660749124329, 35.113590421826068],
              [-80.692333126619729, 35.113547423339938],
              [-80.69238635917057, 35.113282793492935],
              [-80.692058738046271, 35.113239794261062],
              [-80.692111972364742, 35.112975163608212],
              [-80.691784352639246, 35.112932164531969],
              [-80.691837587647115, 35.112667533989992],
              [-80.691509969283189, 35.112624533266654],
              [-80.691563204980426, 35.112359902835649],
              [-80.69123558911221, 35.112316902252537],
              [-80.691288825498816, 35.112052271932399],
              [-80.690961209895178, 35.112009269717674],
              [-80.691014446971124, 35.11174463950838],
              [-80.690359219985297, 35.111658633584717],
              [-80.690412458785318, 35.111394003629378],
              [-80.690084846287448, 35.111350999034585],
              [-80.69013808577678, 35.11108636919009],
              [-80.689810475756062, 35.111043363834249],
              [-80.689863715934706, 35.110778734100684],
              [-80.689536107294046, 35.110735727999185],
              [-80.689589348161959, 35.110471098376578],
              [-80.689261740901543, 35.1104280915294],
              [-80.689314982439981, 35.110163461116436],
              [-80.688987376578382, 35.110120454424894],
              [-80.68904061880616, 35.10985582412291],
              [-80.688713014305932, 35.109812815784416],
              [-80.688925986113816, 35.108754295698652],
              [-80.689253586475246, 35.10879730346516],
              [-80.689306826975866, 35.108532673002998],
              [-80.689634426648055, 35.108575679737882],
              [-80.689687665768687, 35.108311049100813],
              [-80.690015264751622, 35.108354054804074],
              [-80.690121739887061, 35.10782479314792],
              [-80.690449337146191, 35.10786779767659],
              [-80.690502573161197, 35.107603166657498],
              [-80.690830168615463, 35.107646169268584],
              [-80.690936637540048, 35.107116906848596],
              [-80.691264232386004, 35.10715990917106],
              [-80.69142392896309, 35.106366013986417],
              [-80.691751519934996, 35.10640901410536],
              [-80.692070897519642, 35.104821220213182],
              [-80.692398483744782, 35.104864219471594],
              [-80.69250493772968, 35.104334953703876],
              [-80.69283252113415, 35.104377951803194],
              [-80.69293897166871, 35.103848685621784],
              [-80.692611390333369, 35.103805687808411],
              [-80.692717841537132, 35.1032764208841],
              [-80.693045419706465, 35.103319418426921],
              [-80.693205090279648, 35.102525517830848],
              [-80.693532666787434, 35.102568514040819],
              [-80.693958430720485, 35.100451440245301],
              [-80.693630862488732, 35.100408445179056],
              [-80.693950176530578, 35.098820636206938],
              [-80.693622615966348, 35.098777641995945],
              [-80.693729054012167, 35.098248372059544],
              [-80.694056611410772, 35.098291366000012],
              [-80.694109828342377, 35.09802673129952],
              [-80.694437386167152, 35.098069725094462],
              [-80.694597031731178, 35.097275817669711],
              [-80.694924585701173, 35.097318810162662],
              [-80.694977799164334, 35.0970541741479],
              [-80.694650246247477, 35.097011182699262],
              [-80.694703459303057, 35.096746546811126],
              [-80.6950310122823, 35.09678953810149],
              [-80.695084223958361, 35.096524902038659],
              [-80.694756673110206, 35.096481910875958],
              [-80.694809886572159, 35.096217274908973],
              [-80.694154787942892, 35.096131288401317],
              [-80.694208003128395, 35.095866652688549],
              [-80.693552907948728, 35.095780662912901],
              [-80.69360612483905, 35.095516026553028],
              [-80.693278578801369, 35.095473030475453],
              [-80.693385013652502, 35.094943759749306],
              [-80.693057470028734, 35.094900763069219],
              [-80.693110687952114, 35.094636126900191],
              [-80.692783145708006, 35.094593129474639],
              [-80.693102453338511, 35.09300531444061],
              [-80.693429988280059, 35.093048311023729],
              [-80.693483203786926, 35.092783674633033],
              [-80.693589633765072, 35.092254401756648],
              [-80.693917165949046, 35.092297397022392],
              [-80.693970379386045, 35.092032760393785],
              [-80.69429791088082, 35.092075754628048],
              [-80.694563966601507, 35.090752569409965],
              [-80.694891493270134, 35.09079556204096],
              [-80.695051118993007, 35.090001648846247],
              [-80.695378641807437, 35.090044640175314],
              [-80.69559146639844, 35.088986087944789],
              [-80.695918986517796, 35.08902907779823],
              [-80.696025394664673, 35.088499800757027],
              [-80.696352911964127, 35.088542789451502],
              [-80.69677852022501, 35.086425676208286],
              [-80.697106029596114, 35.086468662870708],
              [-80.697159227755762, 35.086204022871094],
              [-80.697486736437781, 35.086247008502177],
              [-80.697539933218209, 35.08598236832804],
              [-80.697212425570427, 35.085939382839904],
              [-80.697265623058783, 35.085674743678602],
              [-80.696938116771463, 35.085631756543677],
              [-80.697044512762446, 35.085102477511285],
              [-80.696717010003738, 35.085059490659859],
              [-80.696770208506422, 35.084794850788697],
              [-80.696442706011524, 35.084751862305986],
              [-80.69649590520325, 35.084487222546365],
              [-80.695513405140687, 35.084358254870217],
              [-80.695566607070916, 35.084093614606651],
              [-80.695239109139791, 35.084050623742449],
              [-80.6951859050602, 35.084315262977107],
              [-80.694858406440176, 35.084272271081623],
              [-80.694911610457311, 35.084007632005317],
              [-80.694584113216365, 35.083964639364481],
              [-80.694637317903755, 35.083699999498371],
              [-80.694309822041973, 35.083657006112162],
              [-80.694363027437063, 35.08339236725903],
              [-80.69370803879832, 35.08330637720622],
              [-80.693761246994242, 35.083041737690856],
              [-80.693433754235897, 35.082998741925692],
              [-80.69348696202421, 35.082734102537216],
              [-80.692831979592341, 35.082648107726541],
              [-80.692885190218831, 35.082383469478621],
              [-80.69223021123554, 35.082297471400729],
              [-80.692176998541015, 35.082562109362577],
              [-80.691194529543196, 35.082433104703782],
              [-80.691247745339766, 35.082168467170874],
              [-80.690265283646937, 35.082039454931071],
              [-80.690318502181668, 35.081774816894459],
              [-80.689663531866643, 35.081688803973172],
              [-80.689769972055174, 35.08115952927924],
              [-80.689115007256902, 35.081073513376857],
              [-80.689168229988496, 35.080808875802866],
              [-80.68884074859281, 35.080765866669985],
              [-80.688893972013148, 35.080501229207584],
              [-80.688239013421878, 35.080415208547784],
              [-80.688292237468431, 35.080150571355375],
              [-80.687637283422347, 35.080064547413293],
              [-80.687690509191455, 35.079799910475664],
              [-80.687363033720217, 35.079756897315214],
              [-80.687788841125169, 35.077639798147004],
              [-80.687461374270185, 35.077596785242783],
              [-80.687514600078117, 35.077332147392291],
              [-80.687187134602198, 35.077289133742923],
              [-80.6872935861537, 35.076759858249439],
              [-80.686966124187137, 35.076716843982581],
              [-80.687179027283079, 35.07565829319207],
              [-80.686851569778383, 35.075615277707975],
              [-80.686958021322795, 35.075086002411069],
              [-80.687285476741252, 35.075129016707692],
              [-80.687391924818584, 35.074599740098151],
              [-80.687064471467806, 35.074556726087664],
              [-80.687117696561415, 35.074292087420666],
              [-80.6864627929628, 35.07420605702125],
              [-80.685480441213201, 35.074077002942751],
              [-80.685533671148988, 35.07381236586123],
              [-80.685206221918321, 35.073769345906413],
              [-80.685152990967296, 35.07403398374624],
              [-80.684825541048014, 35.073990962760114],
              [-80.684878773051409, 35.07372632596482],
              [-80.684551324511105, 35.073683304233668],
              [-80.684604557184414, 35.073418666648791],
              [-80.684277111138172, 35.073375645058611],
              [-80.684490042513886, 35.072317094979077],
              [-80.684162599851405, 35.072274073088401],
              [-80.684428761434845, 35.070950883699034],
              [-80.684101325382301, 35.070907861620427],
              [-80.684154557685858, 35.070643223251174],
              [-80.683827123012207, 35.070600200427499],
              [-80.683880356004209, 35.07033556217025],
              [-80.683552922690836, 35.07029253770019],
              [-80.683606156389871, 35.070027900456132],
              [-80.683278725551816, 35.069984875225785],
              [-80.683331959920579, 35.069720237192257],
              [-80.682677099173233, 35.069634185285345],
              [-80.682730335264125, 35.069369547506945],
              [-80.681420624202374, 35.069197431774931],
              [-80.681473864082491, 35.068932794537758],
              [-80.680819012690293, 35.068846731629414],
              [-80.680872255407223, 35.068582095533472],
              [-80.680544831792218, 35.068539061981227],
              [-80.680598074082582, 35.068274425111177],
              [-80.680270651864987, 35.068231391715393],
              [-80.680377138573377, 35.067702118152766],
              [-80.680049718768203, 35.067659084155181],
              [-80.680102962089876, 35.067394447477874],
              [-80.679448125582596, 35.067308377104744],
              [-80.679394880193797, 35.067573013495775],
              [-80.677757788667122, 35.067357819518328],
              [-80.677811040319938, 35.067093183827865],
              [-80.677483624310725, 35.067050142868865],
              [-80.677430371624396, 35.06731477841624],
              [-80.676775538556484, 35.067228692646253],
              [-80.676828793309866, 35.066964057385292],
              [-80.674536897868833, 35.066662729737743],
              [-80.674590159511411, 35.066398095447845],
              [-80.673935337890455, 35.066311994250412],
              [-80.673988602332315, 35.066047359299276],
              [-80.673661192534482, 35.066004307969905],
              [-80.673714457664488, 35.065739673130814],
              [-80.673059642249854, 35.065653567177975],
              [-80.673112909101391, 35.065388932594068],
              [-80.672458097116845, 35.065302822474166],
              [-80.672511365708246, 35.06503818904693],
              [-80.671529153970567, 35.064909017628914],
              [-80.671582425298439, 35.064644383698948],
              [-80.670927621998246, 35.064558265256409],
              [-80.670874347507237, 35.064822898915196],
              [-80.669892142047573, 35.06469371416167],
              [-80.669945418542554, 35.064429080947825],
              [-80.669290618696991, 35.0643429536252],
              [-80.669343898009487, 35.064078320651554],
              [-80.667379512946482, 35.063819917329937],
              [-80.66743279811385, 35.063555285184705],
              [-80.666450613337702, 35.063426071515039],
              [-80.666503901259617, 35.063161439768486],
              [-80.665849116293401, 35.063075292853135],
              [-80.665902405936365, 35.062810661361944],
              [-80.665247624419038, 35.06272451118123],
              [-80.665194332709291, 35.062989142385774],
              [-80.664539550507527, 35.062902988366631],
              [-80.664592844283931, 35.062638357448648],
              [-80.664265454177368, 35.06259527880718],
              [-80.664318749737745, 35.0623306479863],
              [-80.663336586802515, 35.062201408035371],
              [-80.663389884020972, 35.061936777628326],
              [-80.662735112147658, 35.061850612911961],
              [-80.662788412183346, 35.061585982745321],
              [-80.662461028346499, 35.061542899190982],
              [-80.662514329051504, 35.06127826823532],
              [-80.662186945515671, 35.061235184852784],
              [-80.662240246908311, 35.060970554009295],
              [-80.66158548509587, 35.0608843830341],
              [-80.661638788227776, 35.060619753347552],
              [-80.660656651201009, 35.060490490655198],
              [-80.660329272883331, 35.060447401315209],
              [-80.660275965636799, 35.060712031329771],
              [-80.659621207972009, 35.06062584969937],
              [-80.659567898294995, 35.060890478494919],
              [-80.659240518947811, 35.060847386204415],
              [-80.659187207910236, 35.061112015726955],
              [-80.658859827875588, 35.061068922405255],
              [-80.658806515440745, 35.061333550851955],
              [-80.658151754341944, 35.061247361258083],
              [-80.658098438398696, 35.061511989402149],
              [-80.657771057334543, 35.061468893129913],
              [-80.657717741126902, 35.061733521985872],
              [-80.65739035826067, 35.061690423796087],
              [-80.657337040674136, 35.061955052477529],
              [-80.657009658234941, 35.061911954142793],
              [-80.657062976854817, 35.061647325604696],
              [-80.656735595794601, 35.061604226525375],
              [-80.656788916216527, 35.061339598985768],
              [-80.656461536516971, 35.061296498260496],
              [-80.656514857626391, 35.061031870833276],
              [-80.65422321781277, 35.060730145488485],
              [-80.654169889489239, 35.060994772813473],
              [-80.65253300941302, 35.060779226578845],
              [-80.652586344017095, 35.060514600857069],
              [-80.652258969845903, 35.060471488732276],
              [-80.652205634208642, 35.060736114310643],
              [-80.65187826046467, 35.060693002040878],
              [-80.65177158720077, 35.061222253703889],
              [-80.65144421062169, 35.061179139372975],
              [-80.651390871880565, 35.061443764571315],
              [-80.650081366004727, 35.061271298681639],
              [-80.650134708878511, 35.061006674056969],
              [-80.64980733402885, 35.060963555286584],
              [-80.649860677589928, 35.060698930774258],
              [-80.649533305215527, 35.06065581124443],
              [-80.649586649445752, 35.060391185943125],
              [-80.649259277372309, 35.060348066585171],
              [-80.649365966861339, 35.059818816176147],
              [-80.649038598278153, 35.059775695300736],
              [-80.649251977256483, 35.058717195585054],
              [-80.64930532113182, 35.058452570353111],
              [-80.64897795806003, 35.058409449307035],
              [-80.649084646839228, 35.057880199037029],
              [-80.64941200784483, 35.057923319796238],
              [-80.649572035302185, 35.057129442826813],
              [-80.649899392458195, 35.057172562282929],
              [-80.650006073628347, 35.056643310227656],
              [-80.650333430160259, 35.056686428493997],
              [-80.65038676918428, 35.056421801825735],
              [-80.650714123932786, 35.056464919075808],
              [-80.650820797799412, 35.05593566627595],
              [-80.651148151923792, 35.05597878233629],
              [-80.651254822322898, 35.055449528224258],
              [-80.651582174726983, 35.055492643110028],
              [-80.651742174084859, 35.054698761286986],
              [-80.652069522639465, 35.05474187486972],
              [-80.652176185430179, 35.054212618973025],
              [-80.65184883895995, 35.05416950657856],
              [-80.65195550133744, 35.053640250860006],
              [-80.651628157260674, 35.053597136963198],
              [-80.651734821417293, 35.053067881392778],
              [-80.651407479752294, 35.053024766894957],
              [-80.651620808048165, 35.051966255956906],
              [-80.651293470860736, 35.051923141144961],
              [-80.65134680364217, 35.051658513018516],
              [-80.651019467833549, 35.051615397462193],
              [-80.651072800206038, 35.051350769463184],
              [-80.650745466872323, 35.051307653147504],
              [-80.650852133741878, 35.050778397328799],
              [-80.650524802801485, 35.050735279510839],
              [-80.650684803063342, 35.049941395989073],
              [-80.650357475585679, 35.049898278615011],
              [-80.650464143110995, 35.049369022160072],
              [-80.650791468522769, 35.049412139247288],
              [-80.650898132599224, 35.048882882382138],
              [-80.651225456291101, 35.048925998294727],
              [-80.651278786238819, 35.048661370130702],
              [-80.651606109243559, 35.048704485012053],
              [-80.651872749655212, 35.047381339392047],
              [-80.652200066745337, 35.0474244526837],
              [-80.652253392534703, 35.047159823146558],
              [-80.652306719074502, 35.046895193563635],
              [-80.651979402935879, 35.046852079672632],
              [-80.652086054851821, 35.04632282073139],
              [-80.651758742238741, 35.046279707125727],
              [-80.651812068692777, 35.046015076851042],
              [-80.649193586268495, 35.045670135423052],
              [-80.649140251533836, 35.045934763648951],
              [-80.648485631762654, 35.045848518684693],
              [-80.648432294616654, 35.04611314659293],
              [-80.647777674163052, 35.046026897791002],
              [-80.647724333527847, 35.046291526297807],
              [-80.64608778489827, 35.046075886666294],
              [-80.646034438753347, 35.046340514424976],
              [-80.645052511292064, 35.04621111991478],
              [-80.644725202824048, 35.046167986335526],
              [-80.644778553099954, 35.045903359150707],
              [-80.643142021129719, 35.045687679557808],
              [-80.643195377337747, 35.045423053946116],
              [-80.641558860256353, 35.045207351962283],
              [-80.64161222126387, 35.044942726136],
              [-80.641284919698933, 35.044899583222772],
              [-80.64133828141145, 35.044634958410604],
              [-80.641010981206833, 35.044591813851802],
              [-80.641064343606217, 35.044327189152469],
              [-80.640409747414495, 35.044240898545269],
              [-80.640463111533208, 35.043976274102242],
              [-80.638826632828597, 35.043760533211298],
              [-80.638773263564644, 35.044025157838],
              [-80.638445967178583, 35.043982007222709],
              [-80.638499338571208, 35.04371738272468],
              [-80.637844749980346, 35.043631078202125],
              [-80.637791376522429, 35.043895702413124],
              [-80.637464082252251, 35.043852548218716],
              [-80.637410707415668, 35.044117172255341],
              [-80.636101528604769, 35.043944547844532],
              [-80.63604814929154, 35.044209171276123],
              [-80.6347389718687, 35.044036531187352],
              [-80.63468558698284, 35.044301154028723],
              [-80.633049118097034, 35.044085332998826],
              [-80.633102507050054, 35.043820710890166],
              [-80.632447924414819, 35.043734376185462],
              [-80.632501316200972, 35.043469755219874],
              [-80.632174025339125, 35.043426586694466],
              [-80.632227417793757, 35.043161964940268],
              [-80.631900129406631, 35.043118795656063],
              [-80.631953522547747, 35.042854174014771],
              [-80.631626235539315, 35.042811003986564],
              [-80.631679629384891, 35.042546383359415],
              [-80.632006914264764, 35.042589553258843],
              [-80.632060306713541, 35.04232493155606],
              [-80.631733021770359, 35.04228176181504],
              [-80.631839807676783, 35.041752517703053],
              [-80.632167089458946, 35.041795687171671],
              [-80.632273871933847, 35.041266443550903],
              [-80.631946591120908, 35.041223274384329],
              [-80.632106764221206, 35.040429408267151],
              [-80.63243404084065, 35.040472577017709],
              [-80.632487430502607, 35.040207954167734],
              [-80.632160153819825, 35.040164785575612],
              [-80.632213544186229, 35.039900163739986],
              [-80.631886269977997, 35.039856994389076],
              [-80.631939659916853, 35.039592371779769],
              [-80.631285114603585, 35.039506030702412],
              [-80.631338507357398, 35.039241408334902],
              [-80.631011235156322, 35.039198236623307],
              [-80.631064628596519, 35.038933614368673],
              [-80.630410089471809, 35.038847267639028],
              [-80.630463484648743, 35.038582646542338],
              [-80.629481681663947, 35.038453120672713],
              [-80.629535079574254, 35.038188499074991],
              [-80.62855328389783, 35.03805896563351],
              [-80.628606684541339, 35.0377943435346],
              [-80.628279420681281, 35.03775116439472],
              [-80.628332823125078, 35.037486543295444],
              [-80.627678298490295, 35.037400181738967],
              [-80.627731702652682, 35.037135560896289],
              [-80.627077182545335, 35.037049195160847],
              [-80.627130587330441, 35.036784574589703],
              [-80.626803329394335, 35.036741391428315],
              [-80.626856734847721, 35.036476770068781],
              [-80.626529478272218, 35.036433585262159],
              [-80.626582884429766, 35.036168964917074],
              [-80.62625562923283, 35.036125779366614],
              [-80.626309037172476, 35.03586115911974],
              [-80.625327275677961, 35.035731598490457],
              [-80.625380686350539, 35.035466977742679],
              [-80.624726183201872, 35.03538059922888],
              [-80.624779595610903, 35.035115979639166],
              [-80.62445234558821, 35.035072789194693],
              [-80.624505757569537, 35.034808168831518],
              [-80.624178510021281, 35.034764977628484],
              [-80.624231922688722, 35.034500357378334],
              [-80.623904676519004, 35.034457165431505],
              [-80.623958089872545, 35.034192545294516],
              [-80.623630845081365, 35.034149352603919],
              [-80.623737675006055, 35.033620112495996],
              [-80.623410431529905, 35.033576919220053],
              [-80.623463847005169, 35.033312299263883],
              [-80.623136604907543, 35.033269105244223],
              [-80.62319002106895, 35.033004485401186],
              [-80.622862781427585, 35.03296128972157],
              [-80.622916198275121, 35.032696669991672],
              [-80.622588960030171, 35.03265347446979],
              [-80.622695794732991, 35.032124234304348],
              [-80.62302303091333, 35.032167429539001],
              [-80.623129862166664, 35.031638188964067],
              [-80.622802628050792, 35.031594994016892],
              [-80.622909459965896, 35.031065752705842],
              [-80.622582228260711, 35.03102255715865],
              [-80.622635644731162, 35.030757936601098],
              [-80.622308414404415, 35.030714740310174],
              [-80.622415248370984, 35.030185499390846],
              [-80.622088020454953, 35.030142302499911],
              [-80.622141437951058, 35.029877682138171],
              [-80.621814212509179, 35.029834484488809],
              [-80.621867630673421, 35.029569863338978],
              [-80.621540405514125, 35.029526664960571],
              [-80.621593825478044, 35.029262044810615],
              [-80.621266601697144, 35.029218845688568],
              [-80.621320021233302, 35.028954224765222],
              [-80.621647243999931, 35.028997424644913],
              [-80.621700662157664, 35.028732803547264],
              [-80.621373440441005, 35.028689604712653],
              [-80.621533697047099, 35.027895742554961],
              [-80.621206479868974, 35.027852543249345],
              [-80.621259898706029, 35.027587922317338],
              [-80.621587114869925, 35.027631122380534],
              [-80.621747366207714, 35.026837258970609],
              [-80.621420153158084, 35.026794060239972],
              [-80.621633820871935, 35.025735574940839],
              [-80.621306613392733, 35.02569237588299],
              [-80.621413447227638, 35.025163132887712],
              [-80.621086241062926, 35.025119933244788],
              [-80.621193075577267, 35.024590690415344],
              [-80.620865872900708, 35.024547489256285],
              [-80.620972708094342, 35.024018246592831],
              [-80.620645507845879, 35.023975045735327],
              [-80.620698925946556, 35.023710424051018],
              [-80.620371727058512, 35.02366722154855],
              [-80.620425145862725, 35.023402600879059],
              [-80.620097948352893, 35.02335939763298],
              [-80.620258206826676, 35.022565533154648],
              [-80.620585401240504, 35.022608735969477],
              [-80.620638818642036, 35.022344114277104],
              [-80.620966012352184, 35.022387315159527],
              [-80.621072844052804, 35.021858071396416],
              [-80.6211262593839, 35.021593449469364],
              [-80.621453449248563, 35.021636649047871],
              [-80.62193416117816, 35.019255044554598],
              [-80.622261342101353, 35.019298241952285],
              [-80.622368160235126, 35.018768995099038],
              [-80.622695340554273, 35.0188121922086],
              [-80.622748747522138, 35.018547568600134],
              [-80.62307592605984, 35.018590764693499],
              [-80.623182737971362, 35.018061516182335],
              [-80.623509914791256, 35.018104711100904],
              [-80.623563319752748, 35.017840087099508],
              [-80.62389049586902, 35.017883280085783],
              [-80.623943898356885, 35.017618655925176],
              [-80.624271073787313, 35.017661847880348],
              [-80.624377875643034, 35.01713259827973],
              [-80.624705049373688, 35.017175789961669],
              [-80.624758448741645, 35.016911164521623],
              [-80.625085621786383, 35.01695435517248],
              [-80.625245814736246, 35.016160479141412],
              [-80.625572985013548, 35.01620366757254],
              [-80.625733171753396, 35.015409790838603],
              [-80.626060338185525, 35.015452977966078],
              [-80.626167125539624, 35.014923725770892],
              [-80.626494290254058, 35.014966911723825],
              [-80.626601074160462, 35.014437659120546],
              [-80.626928237157216, 35.014480843898774],
              [-80.626981627559644, 35.014216217408155],
              [-80.627308789888517, 35.014259402056865],
              [-80.627362178895154, 35.013994774491053],
              [-80.627689340538097, 35.014037958108759],
              [-80.627742727071322, 35.013773330383991],
              [-80.628069888028477, 35.013816512970735],
              [-80.628230044666083, 35.013022630056255],
              [-80.628557202855774, 35.013065810423385],
              [-80.628770734236838, 35.012007298598569],
              [-80.628443580156372, 35.011964117904832],
              [-80.62881725420209, 35.010111719576372],
              [-80.628490107689899, 35.010068539001153],
              [-80.628703632050517, 35.00901002429859],
              [-80.628376491106764, 35.008966843396166],
              [-80.628803530293141, 35.006849809207353],
              [-80.628476399062151, 35.006806629453934],
              [-80.62858315717574, 35.006277370221802],
              [-80.628910286343441, 35.006320549687842],
              [-80.629070418929359, 35.005526660619743],
              [-80.628743291743191, 35.00548348069821],
              [-80.628796669234504, 35.005218850496668],
              [-80.629123795389148, 35.005262030274508],
              [-80.629230546175648, 35.004732769508927],
              [-80.629557670612812, 35.004775948112247],
              [-80.629664417952696, 35.004246686939609],
              [-80.629337296674123, 35.004203508608875],
              [-80.629604165754699, 35.002880354948481],
              [-80.629931281857395, 35.002923531659413],
              [-80.630198137098361, 35.001600374730579],
              [-80.629871026135532, 35.001557197836718],
              [-80.630031137783206, 35.000763303577671],
              [-80.629704031356113, 35.000720126212983],
              [-80.629810772411474, 35.000190862915758],
              [-80.629483668393092, 35.000147684951472],
              [-80.62964378100682, 34.999353789304159],
              [-80.629316680428786, 34.999310610883867],
              [-80.629476794102018, 34.998516714483195],
              [-80.629149696982168, 34.99847353650825],
              [-80.629256438657549, 34.997944271793386],
              [-80.628929345023792, 34.997901092302641],
              [-80.629089458585298, 34.997107195430665],
              [-80.628762368391619, 34.997064015484014],
              [-80.628815739188383, 34.996799383287396],
              [-80.628488650371906, 34.996756202597425],
              [-80.628595395181492, 34.996226938372644],
              [-80.628268308773613, 34.996183757083081],
              [-80.62837505314954, 34.995654492140233],
              [-80.628047969150217, 34.995611310251029],
              [-80.628154715300937, 34.995082045461785],
              [-80.62782763371024, 34.995038862973047],
              [-80.627987752332601, 34.994244965561478],
              [-80.627660675276999, 34.994201782602111],
              [-80.627714048850009, 34.993937149910508],
              [-80.628041124874301, 34.99398033272616],
              [-80.628094497070194, 34.99371569986117],
              [-80.627767420981911, 34.993672517204004],
              [-80.627820793863322, 34.993407884453006],
              [-80.627493720247372, 34.99336470103772],
              [-80.627653838815419, 34.99257080305248],
              [-80.627326768621373, 34.992527618280114],
              [-80.627380142182133, 34.992262985697828],
              [-80.627707211327021, 34.99230616942517],
              [-80.62776058351082, 34.992041536669419],
              [-80.6274335153972, 34.991998353085854],
              [-80.627486888248526, 34.991733719542864],
              [-80.627159821511967, 34.991690535216065],
              [-80.627266567162437, 34.991161269244635],
              [-80.627593631836376, 34.991204453283963],
              [-80.627753745163659, 34.990410553215341],
              [-80.627426684678653, 34.99036736959232],
              [-80.627693538069821, 34.989044201440024],
              [-80.628020594511852, 34.989087385230832],
              [-80.628127331148633, 34.988558116576797],
              [-80.628454385856145, 34.988601298291947],
              [-80.628561119065452, 34.988072030133416],
              [-80.628614484585938, 34.987807395115823],
              [-80.628941535468343, 34.987850576429068],
              [-80.629101629070362, 34.987056671639564],
              [-80.628447532761044, 34.986970309904699],
              [-80.628500899025056, 34.986705674897735],
              [-80.628173852976744, 34.986662493286964],
              [-80.628227218830943, 34.986397858409042],
              [-80.627573130911017, 34.986311491898121],
              [-80.627626498481774, 34.986046857278112],
              [-80.627299456610174, 34.986003672377976],
              [-80.627352824866321, 34.985739037872094],
              [-80.627025783294314, 34.98569585314506],
              [-80.627079153313119, 34.985431217837238],
              [-80.626752114213176, 34.985388032352205],
              [-80.626805483839973, 34.985123398074791],
              [-80.626478446098929, 34.98508021094527],
              [-80.626745297452402, 34.983757037987239],
              [-80.627072331133249, 34.983800224383288],
              [-80.627232434908066, 34.98300631909926],
              [-80.627559464728307, 34.98304950329063],
              [-80.627612830194565, 34.982784867702669],
              [-80.627285801405364, 34.982741683655028],
              [-80.62739253445821, 34.982212412663365],
              [-80.627719561203293, 34.982255597324979],
              [-80.627826290793223, 34.981726325026635],
              [-80.627499266128154, 34.981683141553972],
              [-80.627605996397335, 34.981153869425427],
              [-80.62727897521728, 34.981110684437162],
              [-80.625316851033645, 34.980851556845408],
              [-80.625370223423232, 34.980586922186568],
              [-80.625043204598924, 34.980543731149204],
              [-80.62509657765581, 34.980279095703317],
              [-80.624769561303282, 34.980235903908159],
              [-80.624822935063293, 34.9799712694779],
              [-80.624495918992437, 34.979928076954536],
              [-80.624549293419733, 34.97966344173728],
              [-80.6242222798207, 34.979620248456065],
              [-80.6242756549332, 34.979355613353249],
              [-80.623621630834265, 34.979269224418012],
              [-80.623728384145636, 34.978739954699265],
              [-80.623401374676646, 34.978696759189013],
              [-80.623508128666884, 34.9781674896402],
              [-80.623181122700601, 34.978124293516018],
              [-80.623341254167471, 34.977330388502558],
              [-80.623014250544486, 34.977287191937691],
              [-80.623067629140593, 34.977022557303904],
              [-80.622740626876322, 34.976979359094713],
              [-80.622794005062417, 34.976714724590167],
              [-80.622467005287675, 34.976671526524633],
              [-80.622733897209443, 34.975348350660497],
              [-80.622894027563646, 34.974554445159569],
              [-80.623221019091545, 34.974597642074777],
              [-80.623274394148027, 34.974333006404947],
              [-80.623601384990934, 34.974376202289626],
              [-80.623761504993652, 34.973582294672937],
              [-80.624088493089801, 34.973625489239552],
              [-80.62456882285521, 34.971243758136467],
              [-80.624895800943165, 34.971286951438444],
              [-80.625002534406804, 34.970757676658032],
              [-80.625329511856052, 34.970800867869642],
              [-80.625382876491059, 34.97053623029926],
              [-80.625709852160199, 34.970579420495156],
              [-80.62576321651396, 34.970314782737013],
              [-80.626417166845656, 34.970401161082613],
              [-80.626470527679359, 34.970136522121123],
              [-80.626797502341915, 34.970179710270841],
              [-80.626850862894287, 34.969915071121619],
              [-80.627177836871724, 34.969958258240879],
              [-80.627231194952756, 34.969693618933483],
              [-80.627885141864979, 34.969779989323108],
              [-80.627938497538977, 34.969515349699002],
              [-80.628265470473821, 34.96955853296928],
              [-80.628372178741586, 34.969029254247431],
              [-80.628699149960582, 34.969072436343602],
              [-80.628752502554292, 34.968807797245866],
              [-80.629079473088154, 34.9688509783116],
              [-80.629186174046737, 34.968321697953215],
              [-80.629513142882715, 34.968364878746179],
              [-80.629726537625459, 34.967306317089815],
              [-80.62939957291222, 34.967263136871821],
              [-80.62945292203851, 34.966998496524965],
              [-80.629779884608126, 34.96704167571265],
              [-80.629833232358123, 34.966777035192933],
              [-80.629506269724189, 34.966733856163799],
              [-80.629559618159504, 34.96646921575865],
              [-80.629232657978648, 34.966426035070448],
              [-80.629286006004037, 34.96616139479486],
              [-80.628959047217421, 34.966118214265073],
              [-80.6290123970049, 34.965853573187907],
              [-80.628685438499488, 34.965810391930084],
              [-80.628738788972072, 34.965545750967586],
              [-80.628411832937786, 34.965502568952111],
              [-80.628465184095333, 34.965237928104258],
              [-80.628138229437212, 34.965194745345926],
              [-80.628191581279921, 34.964930104612691],
              [-80.627864627997951, 34.96488692111155],
              [-80.627917980525709, 34.964622280493089],
              [-80.627591028619875, 34.96457909624894],
              [-80.627697733604919, 34.964049815227078],
              [-80.628024683449581, 34.964092999183606],
              [-80.628131386086324, 34.963563717743185],
              [-80.627804438302803, 34.963520534074178],
              [-80.628124542954623, 34.961932687229165],
              [-80.627797602794914, 34.961889503521377],
              [-80.628064351017798, 34.9605662964033],
              [-80.62839128602505, 34.960609479392303],
              [-80.629031433819634, 34.957433771863009],
              [-80.6293583557121, 34.95747695225505],
              [-80.629518380939288, 34.9566830224989],
              [-80.629845300103838, 34.956726202474506],
              [-80.630165333918271, 34.955138339531615],
              [-80.630325345596873, 34.954344406776507],
              [-80.630432019422983, 34.953815118414639],
              [-80.630758926486138, 34.953858295035872],
              [-80.630918929874682, 34.953064360515548],
              [-80.631245834210119, 34.953107536720424],
              [-80.631405831381258, 34.952313600608257],
              [-80.631732732971116, 34.952356775495389],
              [-80.631786063646487, 34.952092130223804],
              [-80.63145916308693, 34.952048955480379],
              [-80.631779143806696, 34.950461080532897],
              [-80.631452249792304, 34.950417906666907],
              [-80.631558910047758, 34.949888614464385],
              [-80.631232018421144, 34.949845439098084],
              [-80.631445337796251, 34.948786854036619],
              [-80.631772224207211, 34.948830028842742],
              [-80.631985533918069, 34.94777144184534],
              [-80.632312416553589, 34.947814615190033],
              [-80.632419066729312, 34.947285321239093],
              [-80.632745947649653, 34.947328493409884],
              [-80.632799270633043, 34.947063845804365],
              [-80.633126150868492, 34.947107016945019],
              [-80.633179473571133, 34.946842369152222],
              [-80.633506353103641, 34.94688553836135],
              [-80.633559673354029, 34.946620891312371],
              [-80.634540310968788, 34.946750394992833],
              [-80.634486993808864, 34.947015042472998],
              [-80.635140756017336, 34.947101374716325],
              [-80.635247385835058, 34.946572077349664],
              [-80.635574265378722, 34.946615240952895],
              [-80.635787515655849, 34.945556645315676],
              [-80.636114391423988, 34.94559980745764],
              [-80.636221012978211, 34.945070509172304],
              [-80.636547885936622, 34.945113670155294],
              [-80.63660119516571, 34.944849020825963],
              [-80.636928068533834, 34.944892180763986],
              [-80.637087988852301, 34.944098231301524],
              [-80.636761119651368, 34.944055070878498],
              [-80.636814426386991, 34.943790420548225],
              [-80.636867733890227, 34.943525771075805],
              [-80.637194601012865, 34.943568930310128],
              [-80.637247907122756, 34.943304279764121],
              [-80.636594174170227, 34.943217959810035],
              [-80.63664748090055, 34.942953309537707],
              [-80.63632061651046, 34.942910147916706],
              [-80.636373925020365, 34.942645497744692],
              [-80.636047062023607, 34.942602336282427],
              [-80.636100370123685, 34.9423376862404],
              [-80.635773508484178, 34.942294523134116],
              [-80.635933435975019, 34.941500572336778],
              [-80.635606577771043, 34.941457408775342],
              [-80.635766505245456, 34.940663458152251],
              [-80.63543965157136, 34.940620294120791],
              [-80.635546269851019, 34.940090993554684],
              [-80.635219418582068, 34.940047828924364],
              [-80.635326037523058, 34.939518527630213],
              [-80.634999187564816, 34.939475362415884],
              [-80.635105808279633, 34.938946061280376],
              [-80.634778961821198, 34.938902895452365],
              [-80.634938892282079, 34.938108943080131],
              [-80.634612049258791, 34.938065776797011],
              [-80.634771979702947, 34.937271824599719],
              [-80.634445140114735, 34.937228657861539],
              [-80.634551761468657, 34.936699356231486],
              [-80.634224924285519, 34.93665618889451],
              [-80.634278235465558, 34.936391537729882],
              [-80.633951399657533, 34.936348369650389],
              [-80.634004711522408, 34.936083718601061],
              [-80.634217954435584, 34.935025114134227],
              [-80.634544783999488, 34.935068281509835],
              [-80.634758017252508, 34.934009675111987],
              [-80.634431191808147, 34.933966508311414],
              [-80.63453780784927, 34.933437203885006],
              [-80.634210984809812, 34.933394036485666],
              [-80.634317601548005, 34.932864733134736],
              [-80.633990780913365, 34.93282156513667],
              [-80.634044090332893, 34.932556913104179],
              [-80.633717271055232, 34.932513743462309],
              [-80.633823890918492, 34.931984439599866],
              [-80.633497074063555, 34.93194127026068],
              [-80.633550384507274, 34.931676618430657],
              [-80.633223569027393, 34.931633448348961],
              [-80.633276880155776, 34.931368796634416],
              [-80.633603694605853, 34.931411966572362],
              [-80.633657003264787, 34.931147314700624],
              [-80.633983817030199, 34.931190483608539],
              [-80.634037124314204, 34.9309258315647],
              [-80.634363937394937, 34.930968999442705],
              [-80.634417243304028, 34.93070434722668],
              [-80.634744055700011, 34.930747514074788],
              [-80.635010577054686, 34.929424249117659],
              [-80.634683770901887, 34.929381082973599],
              [-80.634790379292042, 34.92885177635609],
              [-80.634463575543947, 34.928808609613277],
              [-80.634516879703881, 34.928543956413307],
              [-80.634190078406988, 34.928500788011874],
              [-80.634243383251643, 34.928236134927396],
              [-80.633916582253136, 34.92819296669974],
              [-80.634023194042854, 34.927663659817291],
              [-80.633696396543428, 34.927620490976189],
              [-80.633803009012041, 34.92709118426832],
              [-80.6334762128227, 34.927048014843344],
              [-80.633582827064799, 34.926518708295127],
              [-80.633689438813946, 34.925989400747447],
              [-80.634016229808154, 34.926032570513513],
              [-80.634069533588345, 34.925767916561],
              [-80.634396324992295, 34.925811085282305],
              [-80.634449627397643, 34.925546431157827],
              [-80.634776417004659, 34.925589597962677],
              [-80.634829719147703, 34.925324944552763],
              [-80.635156508070139, 34.925368110327724],
              [-80.635209807726014, 34.925103455859407],
              [-80.635536595963842, 34.925146620604401],
              [-80.635589894245001, 34.924881965964055],
              [-80.635916681816255, 34.924925130580611],
              [-80.636076571482519, 34.924131165157824],
              [-80.63640335630987, 34.924174328457092],
              [-80.636509945630181, 34.923645017446333],
              [-80.636183162880158, 34.923601855335988],
              [-80.636289752879733, 34.923072544500229],
              [-80.635962973610319, 34.923029380874965],
              [-80.636069564288945, 34.922500070214028],
              [-80.635742786329374, 34.922456906005188],
              [-80.63595596764695, 34.921398284019375],
              [-80.63628274147014, 34.921441446751913],
              [-80.636336033808476, 34.921176790830913],
              [-80.636662806965219, 34.921219953434935],
              [-80.636716099005312, 34.920955296425937],
              [-80.637042870383027, 34.920998458015021],
              [-80.637096159972344, 34.920733801750323],
              [-80.637202740286682, 34.92020448820557],
              [-80.637529508920736, 34.920247648477357],
              [-80.637636084701739, 34.919718334547667],
              [-80.637962851603589, 34.919761492744492],
              [-80.638069423945609, 34.919232178415044],
              [-80.638396189133417, 34.919275335438343],
              [-80.63871589057355, 34.917687389126421],
              [-80.638389132639077, 34.917644232049284],
              [-80.638708827784782, 34.916056282886849],
              [-80.638382076372153, 34.91601312578603],
              [-80.638595203294003, 34.914954491528867],
              [-80.638268456362113, 34.914911335018452],
              [-80.638428301262238, 34.914117357881949],
              [-80.638101557763505, 34.914074200916751],
              [-80.638154840453979, 34.913809541348265],
              [-80.637828098329535, 34.913766383640791],
              [-80.637934663658285, 34.91323706562374],
              [-80.638588143076447, 34.913323381395315],
              [-80.6386414226086, 34.913058721614149],
              [-80.638968162352711, 34.913101878019773],
              [-80.639021440510589, 34.912837218066961],
              [-80.639348178475956, 34.912880373457718],
              [-80.639401455259488, 34.912615713333224],
              [-80.639728193634667, 34.91265886767939],
              [-80.639781469044067, 34.912394207383279],
              [-80.640108205640388, 34.912437360714591],
              [-80.640161479675555, 34.912172700246821],
              [-80.640488215587354, 34.912215852548456],
              [-80.640541488248218, 34.911951191909111],
              [-80.640868223475579, 34.911994343180943],
              [-80.641187844929561, 34.910406377911606],
              [-80.640861115878309, 34.910363227502067],
              [-80.640967654915613, 34.909833904918649],
              [-80.640640928286004, 34.909790754811972],
              [-80.640694198854817, 34.909526093164089],
              [-80.640367473581236, 34.909482941413792],
              [-80.640474015761029, 34.90895361922334],
              [-80.640147292890873, 34.908910466874588],
              [-80.640307105559259, 34.908116483375608],
              [-80.639980387215843, 34.908073330557208],
              [-80.640140199850705, 34.90727934633783],
              [-80.63981348493995, 34.907236193064641],
              [-80.63992002776034, 34.906706870390309],
              [-80.639593315252853, 34.906663716518771],
              [-80.639699857640338, 34.906134393134238],
              [-80.63937314753602, 34.906091238664274],
              [-80.639532963269147, 34.905297254238775],
              [-80.638879550270218, 34.905210943503633],
              [-80.638826276639577, 34.905475604418591],
              [-80.638499570722431, 34.905432447563541],
              [-80.638446295735847, 34.905697109208361],
              [-80.637466174777131, 34.905567632926363],
              [-80.637519452851279, 34.905302971712807],
              [-80.635885930309669, 34.90508716000415],
              [-80.635832645995492, 34.905351820513687],
              [-80.635179237542658, 34.905265488797696],
              [-80.635232523915064, 34.905000828575716],
              [-80.634252416371908, 34.904871325247115],
              [-80.634199126911938, 34.905135985037695],
              [-80.633872424793651, 34.905092815636515],
              [-80.633925715282757, 34.904828155989712],
              [-80.633272313026978, 34.904741813930769],
              [-80.633325605229444, 34.904477154543805],
              [-80.632998905110057, 34.904433982787438],
              [-80.633052197996719, 34.90416932351652],
              [-80.632398803021161, 34.904082976702789],
              [-80.632452097621126, 34.903818317691716],
              [-80.632125401670891, 34.903775142649195],
              [-80.632178696955023, 34.903510483754204],
              [-80.631198614279242, 34.903380954644348],
              [-80.631145314813708, 34.90364561312272],
              [-80.630165232155434, 34.903516075608749],
              [-80.630111930351774, 34.903780733613054],
              [-80.629458541567701, 34.903694369952611],
              [-80.629405236266763, 34.903959027656285],
              [-80.627445072038242, 34.903699915453039],
              [-80.62739176019987, 34.903964571364504],
              [-80.62673837281983, 34.903878192955162],
              [-80.626685058596209, 34.904142849452512],
              [-80.626358364394747, 34.904099658775237],
              [-80.626411679647433, 34.903835002421751],
              [-80.626084986820103, 34.903791811002499],
              [-80.626138302756686, 34.903527154765143],
              [-80.62581161130359, 34.903483962603921],
              [-80.625864927924155, 34.903219306482661],
              [-80.6255382389392, 34.903176113564726],
              [-80.625644873203242, 34.902646801526792],
              [-80.625318186603522, 34.902603607109413],
              [-80.625371504246999, 34.902338951192661],
              [-80.625044817945181, 34.9022957569494],
              [-80.624718133082553, 34.902252561805653],
              [-80.624771452421058, 34.901987905247545],
              [-80.624118084694913, 34.901901512605221],
              [-80.624171406840432, 34.901636856292434],
              [-80.623191361455639, 34.90150726020822],
              [-80.62324468524892, 34.901242604314142],
              [-80.622591326166088, 34.901156202473189],
              [-80.622264646057118, 34.901113000689463],
              [-80.622317973686279, 34.900848345184627],
              [-80.621991296045593, 34.900805142644266],
              [-80.622204606132527, 34.899746520938358],
              [-80.622531279675329, 34.899789723804375],
              [-80.622584605578851, 34.899525068161132],
              [-80.622911278419949, 34.899568269096157],
              [-80.62307124987889, 34.898774301583153],
              [-80.622744580106669, 34.898731100178452],
              [-80.622957874998519, 34.897672475208552],
              [-80.622631209704778, 34.897629274395037],
              [-80.622737856572968, 34.897099961138089],
              [-80.62241119477612, 34.897056759711994],
              [-80.622464519268462, 34.896792103178569],
              [-80.62213785882777, 34.89674890010928],
              [-80.62219118290983, 34.896484243706894],
              [-80.621864523843101, 34.89644103989577],
              [-80.621971175217567, 34.895911727266522],
              [-80.621644518571486, 34.895868523758899],
              [-80.621697843657955, 34.895603866660139],
              [-80.621371188385822, 34.895560662410716],
              [-80.621424515250112, 34.895296005413606],
              [-80.621097861334007, 34.895252799521025],
              [-80.621204516120557, 34.89472348753452],
              [-80.620877864607181, 34.894680281044074],
              [-80.621091174118504, 34.893621655513115],
              [-80.621417820404957, 34.893664860541193],
              [-80.62152447103243, 34.89313554732248],
              [-80.621851115624239, 34.893178752078391],
              [-80.622064406399659, 34.892120122961543],
              [-80.621411125790999, 34.892033713715449],
              [-80.621464450208776, 34.891769055746515],
              [-80.620811173037879, 34.891682643245595],
              [-80.620864498091905, 34.891417986453177],
              [-80.61988458910389, 34.891288361476391],
              [-80.619937917975037, 34.891023704172113],
              [-80.619611283, 34.890980494224053],
              [-80.619664612572592, 34.89071583793767],
              [-80.619337978953382, 34.890672626346557],
              [-80.619444639102753, 34.890143313077665],
              [-80.61911800790395, 34.890100101790154],
              [-80.619224668712306, 34.889570787797936],
              [-80.618898041009871, 34.889527575897993],
              [-80.618951371925078, 34.889262919004558],
              [-80.618624745596406, 34.889219706362908],
              [-80.618731408430833, 34.888690391880267],
              [-80.618404783410611, 34.888647178655674],
              [-80.618511448033601, 34.88811786523771],
              [-80.618184826491827, 34.888074650499341],
              [-80.618291490697771, 34.887545337274325],
              [-80.617964871558215, 34.887502121938198],
              [-80.618018204172103, 34.887237465428541],
              [-80.617364969003546, 34.887151033288916],
              [-80.617418303311666, 34.886886376138321],
              [-80.617091688358556, 34.886843158418714],
              [-80.617145023367939, 34.886578502286028],
              [-80.616165181495276, 34.886448845176382],
              [-80.616218519227388, 34.886184188546828],
              [-80.615565295465629, 34.886097746629424],
              [-80.614585461838089, 34.885968075766549],
              [-80.614638805461638, 34.885703419814973],
              [-80.614312196718402, 34.885660195458499],
              [-80.614365539931413, 34.885395539638097],
              [-80.614038932544091, 34.88535231363867],
              [-80.614092277534212, 34.885087657920259],
              [-80.613439064758154, 34.885001203567391],
              [-80.6134924114603, 34.884736548109551],
              [-80.612839203215856, 34.88465009048786],
              [-80.61294589968044, 34.884120779164789],
              [-80.612619298133012, 34.884077549313659],
              [-80.612725995273749, 34.88354823816907],
              [-80.612072797328381, 34.88346177638622],
              [-80.612179497202192, 34.882932465708336],
              [-80.611852901897961, 34.882889233762064],
              [-80.61190625233661, 34.882624578075365],
              [-80.611579657312262, 34.882581345402222],
              [-80.61163300843404, 34.882316689832216],
              [-80.611306415877181, 34.882273456402956],
              [-80.611359767682245, 34.882008800949656],
              [-80.611033175387405, 34.881965565892131],
              [-80.611086528969409, 34.881700910540928],
              [-80.610433348601546, 34.881614439845649],
              [-80.610486702801381, 34.881349784769796],
              [-80.609180351380218, 34.881176831511901],
              [-80.609233709348246, 34.880912176984914],
              [-80.607927368658395, 34.880739210120716],
              [-80.607874005482998, 34.881003864086068],
              [-80.607547420136541, 34.880960619561527],
              [-80.607440690710902, 34.881489928023463],
              [-80.607114103653075, 34.881446682325304],
              [-80.607060737385098, 34.881711335920414],
              [-80.605427799981001, 34.881495095258835],
              [-80.605534543531348, 34.880965788565945],
              [-80.605207959591283, 34.880922538600323],
              [-80.605314702723163, 34.880393232100921],
              [-80.604988121167494, 34.880349980636545],
              [-80.605148237462856, 34.879556021550947],
              [-80.60482165933746, 34.87951276963345],
              [-80.604875032508588, 34.879248116985131],
              [-80.604548455756827, 34.879204864326269],
              [-80.604601828499426, 34.878940210907942],
              [-80.603948679182281, 34.878853703207575],
              [-80.604002054730003, 34.878589050035607],
              [-80.603675481070724, 34.878545795008584],
              [-80.603728857301363, 34.87828114195343],
              [-80.60340228501579, 34.87823788618509],
              [-80.603455661929331, 34.877973233246756],
              [-80.602802521544476, 34.877886719327513],
              [-80.602855900169132, 34.877622066650112],
              [-80.602529332069849, 34.877578808499166],
              [-80.602689467844939, 34.876784849849017],
              [-80.60236290317566, 34.876741591244986],
              [-80.602576416079245, 34.875682979315371],
              [-80.602249855868223, 34.875639720402411],
              [-80.602303234796992, 34.875375067038142],
              [-80.602356613397959, 34.875110414547954],
              [-80.60300972895898, 34.875196930926499],
              [-80.603063105140691, 34.874932277219507],
              [-80.603389661881764, 34.874975534845198],
              [-80.603443036689981, 34.874710880966859],
              [-80.603769592730544, 34.8747541366617],
              [-80.603822966165254, 34.874489482612006],
              [-80.60414952152297, 34.874532737277349],
              [-80.604202893584201, 34.874268083056336],
              [-80.604529448259044, 34.874311336692145],
              [-80.604582817853085, 34.87404668231445],
              [-80.604909372938792, 34.874089934906173],
              [-80.604962741159383, 34.873825280357245],
              [-80.605289294468591, 34.873868531934072],
              [-80.60539602891123, 34.873339222451719],
              [-80.605722580509294, 34.873382472854964],
              [-80.605775945620067, 34.873117817034831],
              [-80.606102496552936, 34.873161067309958],
              [-80.606155860290286, 34.872896411318592],
              [-80.606482410522673, 34.872939659662876],
              [-80.606535772904238, 34.872675004401614],
              [-80.606209223700091, 34.872631756201514],
              [-80.606315949448472, 34.872102444083019],
              [-80.605989402645818, 34.872059195285665],
              [-80.606096127975874, 34.871529883361404],
              [-80.605769583574613, 34.871486633966875],
              [-80.605876310673608, 34.870957322207801],
              [-80.606202853036265, 34.8710005722155],
              [-80.606309576680317, 34.870471259158322],
              [-80.606636116220727, 34.870514507105675],
              [-80.606742836445434, 34.869985194553337],
              [-80.6070693742749, 34.870028441327179],
              [-80.607122732841319, 34.869763784866237],
              [-80.607449271081592, 34.869807030596043],
              [-80.607662695560478, 34.868748402131629],
              [-80.607989228939928, 34.868791646414252],
              [-80.608042582612981, 34.868526988643097],
              [-80.608695649444925, 34.868613474249671],
              [-80.608749000716713, 34.86834881616322],
              [-80.609075534169307, 34.868392057487135],
              [-80.609128884067772, 34.868127399229543],
              [-80.60945541574381, 34.868170639538668],
              [-80.609508764269094, 34.867905981109935],
              [-80.610488359377285, 34.868035696278504],
              [-80.610755081406666, 34.86671240156852],
              [-80.611081608277104, 34.866755636570623],
              [-80.611241633158144, 34.865961658101519],
              [-80.611894681801658, 34.866048126388058],
              [-80.611948019941579, 34.86578346596508],
              [-80.612601067909424, 34.865869930422264],
              [-80.61265440364825, 34.865605269684288],
              [-80.613307450940283, 34.865691730312065],
              [-80.613360784277944, 34.865427069258942],
              [-80.613687307413457, 34.865470298100938],
              [-80.613740639378165, 34.865205636876794],
              [-80.61406716183069, 34.865248864689278],
              [-80.614120492422387, 34.864984203294227],
              [-80.61444701419201, 34.865027430077433],
              [-80.614500343410583, 34.864762768511341],
              [-80.614607000812953, 34.864233445298289],
              [-80.614280483220924, 34.864190218932571],
              [-80.614333812432591, 34.863925557429617],
              [-80.613680779246096, 34.86383910234494],
              [-80.613734110168721, 34.863574441103154],
              [-80.613407595667667, 34.863531212369587],
              [-80.613460927273223, 34.863266551244976],
              [-80.613567589431483, 34.862737228013479],
              [-80.613894101924473, 34.862780455398898],
              [-80.613947431467082, 34.862515794049429],
              [-80.614273942201237, 34.862559021321538],
              [-80.61432727035303, 34.862294358899732],
              [-80.614653780404325, 34.862337585142619],
              [-80.614707107183307, 34.862072922549878],
              [-80.615033616551528, 34.862116147763381],
              [-80.615140267018688, 34.861586822209162],
              [-80.615466774676023, 34.861630046249338],
              [-80.615893352300347, 34.859512740032109],
              [-80.615566852866266, 34.859469517144163],
              [-80.615673496416576, 34.858940190151451],
              [-80.615346999383164, 34.85889696666635],
              [-80.615400321659934, 34.858632302823047],
              [-80.615073827092758, 34.858589078582092],
              [-80.61518047157368, 34.858059751117864],
              [-80.614853979407215, 34.858016526279727],
              [-80.615013947171789, 34.857222535307123],
              [-80.615340436254854, 34.857265759713052],
              [-80.615393757489826, 34.85700109585305],
              [-80.615067269434661, 34.856957871591078],
              [-80.615280553907738, 34.855899214671119],
              [-80.615933521047154, 34.855985661172241],
              [-80.615986839511962, 34.855720996132888],
              [-80.617619258803771, 34.855937096167246],
              [-80.617672570709146, 34.85567243129708],
              [-80.617999054993945, 34.855715647597059],
              [-80.61805236552658, 34.855450982556221],
              [-80.619031817402046, 34.855580627515664],
              [-80.619085123395237, 34.855315961129264],
              [-80.61941160702159, 34.855359174501899],
              [-80.619464912735538, 34.855094507930083],
              [-80.619791395678902, 34.855137720273447],
              [-80.619844698926755, 34.854873053545575],
              [-80.619518218104758, 34.854829841331529],
              [-80.619571523129153, 34.854565174706252],
              [-80.619898002923378, 34.854608386776327],
              [-80.620111214350501, 34.853549718545537],
              [-80.620437690378495, 34.853592929154345],
              [-80.620490991623385, 34.853328262107617],
              [-80.620544292505556, 34.853063594132749],
              [-80.620870766822833, 34.853106803568458],
              [-80.620924065239123, 34.852842135437704],
              [-80.621250539966738, 34.852885343829449],
              [-80.621303837010515, 34.852620675528009],
              [-80.621630309961702, 34.852663882905404],
              [-80.621736902070808, 34.85213454682107],
              [-80.622063373293386, 34.852177752123936],
              [-80.622169961950192, 34.851648414743693],
              [-80.622496431479888, 34.851691619774805],
              [-80.622603015608917, 34.851162282014741],
              [-80.622929483427853, 34.851205485872661],
              [-80.622982773947584, 34.85094081680873],
              [-80.623309241065627, 34.850984018736142],
              [-80.623362530212944, 34.85071934950161],
              [-80.623036064104639, 34.850676146816738],
              [-80.623142643420579, 34.850146808555664],
              [-80.623469107455591, 34.850190010051143],
              [-80.623575682226004, 34.849660670509046],
              [-80.623628969649886, 34.849396001141386],
              [-80.623955430946381, 34.849439201319953],
              [-80.624062002686856, 34.848909861315533],
              [-80.624388461179251, 34.848953060335703],
              [-80.624601595302281, 34.847894378545462],
              [-80.624275140902654, 34.847851179199772],
              [-80.624328423774969, 34.847586508615542],
              [-80.624001970747628, 34.847543308528635],
              [-80.624055255396229, 34.847278638047101],
              [-80.623728803741187, 34.847235437219148],
              [-80.623782089072847, 34.846970766854916],
              [-80.62345563879002, 34.846927565285895],
              [-80.623508924786762, 34.846662894137751],
              [-80.623182475894168, 34.846619692728829],
              [-80.623235762573771, 34.846355021698209],
              [-80.622582867842041, 34.846268614710411],
              [-80.622636156250081, 34.846003944842622],
              [-80.621983266045575, 34.84591753458762],
              [-80.622036556146341, 34.845652864079895],
              [-80.621710112041924, 34.845609657776166],
              [-80.621763403918933, 34.845344987371085],
              [-80.621436961186873, 34.845301780326288],
              [-80.621490252653331, 34.845037110053518],
              [-80.621163812386854, 34.844993902252824],
              [-80.621270396341018, 34.844464561915757],
              [-80.620943958474243, 34.844421353518008],
              [-80.621103835978431, 34.843627342329491],
              [-80.620777401538945, 34.843584133478764],
              [-80.620937277930125, 34.842790122498656],
              [-80.620610846917955, 34.842746913194993],
              [-80.620717432579895, 34.842217571480681],
              [-80.620391003967384, 34.842174361579929],
              [-80.620444296762088, 34.841909690834569],
              [-80.619465017150503, 34.841780055337956],
              [-80.619518313776311, 34.841515384983417],
              [-80.619191888597513, 34.841472171830553],
              [-80.619138592037544, 34.841736842026378],
              [-80.618812166175886, 34.841693627844457],
              [-80.618865464856611, 34.841428957777978],
              [-80.617233347989881, 34.841212871579664],
              [-80.617286651463104, 34.840948202207002],
              [-80.617613072470675, 34.84099142144202],
              [-80.617826277695315, 34.839932742223994],
              [-80.618152694920198, 34.839975959096478],
              [-80.618365890501877, 34.83891727797841],
              [-80.6186923039796, 34.838960494291129],
              [-80.618852192571083, 34.838166481409722],
              [-80.619178603311354, 34.838209696405357],
              [-80.619338485718586, 34.837415682854669],
              [-80.619664892610345, 34.837458895646485],
              [-80.619718185758231, 34.837194224256393],
              [-80.620044591985135, 34.837237436920681],
              [-80.620097882667764, 34.836972765374867],
              [-80.620424289287357, 34.837015976094037],
              [-80.620477578597985, 34.836751304377962],
              [-80.620803984534959, 34.836794514068067],
              [-80.621017134186005, 34.835735824561468],
              [-80.620690732358383, 34.83569261544735],
              [-80.621063738108745, 34.833839905651459],
              [-80.62139013166977, 34.833883114673547],
              [-80.621443415781982, 34.833618440695027],
              [-80.621769807567219, 34.833661648702886],
              [-80.621823090307473, 34.833396974554084],
              [-80.622149482503204, 34.83344018151827],
              [-80.622202763889547, 34.833175508100652],
              [-80.622529154291442, 34.833218713149265],
              [-80.622635712864849, 34.832689365060475],
              [-80.622962102667799, 34.832732569822795],
              [-80.623068657790583, 34.832203220439759],
              [-80.623395045883569, 34.83224642402913],
              [-80.623448321354303, 34.831981749161621],
              [-80.623774708746794, 34.832024950820696],
              [-80.623881256617949, 34.831495601620446],
              [-80.624207642300448, 34.831538802106614],
              [-80.62426091468302, 34.831274126872451],
              [-80.624913683951689, 34.831360525818191],
              [-80.624966953935356, 34.831095850269932],
              [-80.62529333750372, 34.831139047828088],
              [-80.62534660611567, 34.830874372109619],
              [-80.625672989001174, 34.830917568638924],
              [-80.625726256241393, 34.830652892750237],
              [-80.626052639537292, 34.830696088235904],
              [-80.626105904312666, 34.830431412191885],
              [-80.626432286943782, 34.830474607550016],
              [-80.626538814481819, 34.829945254179236],
              [-80.626865194291881, 34.829988447477909],
              [-80.626971719490825, 34.829459093699811],
              [-80.627024980471589, 34.829194416335568],
              [-80.627351357544342, 34.829237608317506],
              [-80.627404618264521, 34.82897293166976],
              [-80.62773099465457, 34.829016122622761],
              [-80.627837511878198, 34.82848676717299],
              [-80.628163886576189, 34.828529957854578],
              [-80.628217143097686, 34.828265279953868],
              [-80.628376911670856, 34.827471245178977],
              [-80.628050542192639, 34.827428055959857],
              [-80.628103798363711, 34.827163378098746],
              [-80.627777429146136, 34.827120187252113],
              [-80.627830687093081, 34.826855509494031],
              [-80.627504319246967, 34.826812317906509],
              [-80.627557577876672, 34.826547640266277],
              [-80.627231211420124, 34.826504448839245],
              [-80.627284470714642, 34.82623977041559],
              [-80.626958106722654, 34.826196578232903],
              [-80.62706462523964, 34.82566722161004],
              [-80.626738263646303, 34.825624028830482],
              [-80.626791522868771, 34.825359350631231],
              [-80.626465162629131, 34.825316156209411],
              [-80.626571683188061, 34.824786800006052],
              [-80.626245325364934, 34.824743605888735],
              [-80.626298586701367, 34.824478927884613],
              [-80.625972230231838, 34.824435732125082],
              [-80.626025492251031, 34.824171054238818],
              [-80.625699137152921, 34.824127857738439],
              [-80.625858923114251, 34.823333823468943],
              [-80.625532571459743, 34.823290627417094],
              [-80.625639096488626, 34.822761271084886],
              [-80.625312747214736, 34.822718073534844],
              [-80.625366010230692, 34.822453395023103],
              [-80.626018705975895, 34.822539788965138],
              [-80.626071965500259, 34.822275110154074],
              [-80.626724660551076, 34.822361499367531],
              [-80.626777918770102, 34.822096820227749],
              [-80.627430612069261, 34.822183206530177],
              [-80.62753712227294, 34.821653847611522],
              [-80.627590376311773, 34.821389168120618],
              [-80.627803390098663, 34.82033044898099],
              [-80.628129730075642, 34.820373639797168],
              [-80.62818298134782, 34.820108959131097],
              [-80.628509320642223, 34.82015214891851],
              [-80.628828816011264, 34.818564065301253],
              [-80.629155148377109, 34.818607252453475],
              [-80.629314887768132, 34.817813208969149],
              [-80.629641217415454, 34.817856395706059],
              [-80.629747706549495, 34.817327032064348],
              [-80.630074034487222, 34.817370217628529],
              [-80.630127277519918, 34.817105536075601],
              [-80.630453604756937, 34.81714871970977],
              [-80.630666568208525, 34.816089990875419],
              [-80.63099289170006, 34.816133173950369],
              [-80.631152606200331, 34.81533912528765],
              [-80.631478926955381, 34.815382307046029],
              [-80.631532163042479, 34.815117623967716],
              [-80.631858483096991, 34.815160803796019],
              [-80.632071420988524, 34.814102070634128],
              [-80.632397737279703, 34.814145249001925],
              [-80.632663896456236, 34.812821829450158],
              [-80.632990206864292, 34.812865006228328],
              [-80.633149894489506, 34.812070953043843],
              [-80.633476203254119, 34.81211412849072],
              [-80.633529429648476, 34.811849443614769],
              [-80.633855737748391, 34.81189261893433],
              [-80.633908962771798, 34.81162793388868],
              [-80.634235270171118, 34.811671107278237],
              [-80.634288493823561, 34.811406422062994],
              [-80.634941106525957, 34.811492766816372],
              [-80.634887883834267, 34.811757452334319],
              [-80.635866808011741, 34.811886963719083],
              [-80.635973245779084, 34.811357590907313],
              [-80.636026463609184, 34.811092904920969],
              [-80.636352769992683, 34.811136072570498],
              [-80.636405986451777, 34.810871386414533],
              [-80.636079682188083, 34.810828218893974],
              [-80.636132899312003, 34.810563531954806],
              [-80.636186117202612, 34.81029884587636],
              [-80.635533513796702, 34.81021250785551],
              [-80.635586732285972, 34.809947821152761],
              [-80.634934132309937, 34.809861479881043],
              [-80.634987353601645, 34.809596793425506],
              [-80.634334757055569, 34.809510448902977],
              [-80.634387980074735, 34.809245763611067],
              [-80.634061683335588, 34.809202589716058],
              [-80.634114907019395, 34.808937903640931],
              [-80.633788611651212, 34.808894729005196],
              [-80.633895060039919, 34.808365357065853],
              [-80.63356876708734, 34.808322182734742],
              [-80.63367521613516, 34.807792810079285],
              [-80.634001505959418, 34.807835985038722],
              [-80.634054728949422, 34.807571298979155],
              [-80.634381018073071, 34.807614472008659],
              [-80.634647120506472, 34.806291038833436],
              [-80.63432083649829, 34.806247865622282],
              [-80.634374056312922, 34.805983178522375],
              [-80.63404777478641, 34.805940005457074],
              [-80.634100995283646, 34.805675318475423],
              [-80.633774714017008, 34.805632143782901],
              [-80.633827936289634, 34.805367456904825],
              [-80.632849098727206, 34.805237927936609],
              [-80.632795873374576, 34.80550261438291],
              [-80.63246959348443, 34.805459436151949],
              [-80.632522819863723, 34.805194749849612],
              [-80.631870264261835, 34.805108391007018],
              [-80.631923491257396, 34.804843704981813],
              [-80.632083173458057, 34.804049646708172],
              [-80.632409447526854, 34.804092825988555],
              [-80.632782017822706, 34.80224001696984],
              [-80.633108283956588, 34.802283194372734],
              [-80.633267948528015, 34.801489131778936],
              [-80.633594211926152, 34.801532307865415],
              [-80.633647432099508, 34.801267620134212],
              [-80.633973693722382, 34.80131079520698],
              [-80.63408012987307, 34.800781418493536],
              [-80.634406390879704, 34.800824592379001],
              [-80.634512823601028, 34.800295215275618],
              [-80.63483908180585, 34.800338388003404],
              [-80.634892297179334, 34.800073699712797],
              [-80.634566038908304, 34.800030527143747],
              [-80.634619253853572, 34.799765838085001],
              [-80.634945511098053, 34.799809010510138],
              [-80.635264788496769, 34.798220875645711],
              [-80.635591038833326, 34.798264046337515],
              [-80.635644249299489, 34.797999355911202],
              [-80.635970500046341, 34.798042525559644],
              [-80.636289748422058, 34.796454384323233],
              [-80.636768597123179, 34.794072165803392],
              [-80.636442363958508, 34.794028998284304],
              [-80.636548773522065, 34.79349961569212],
              [-80.636222541661468, 34.793456447591382],
              [-80.636328951902101, 34.792927065185694],
              [-80.636002723512831, 34.792883895572054],
              [-80.636055929152604, 34.792619204925792],
              [-80.635729702133759, 34.79257603457166],
              [-80.636048933689224, 34.790987884731578],
              [-80.63572271319029, 34.790944715258064],
              [-80.635935531065911, 34.78988594757088],
              [-80.635609316090736, 34.789842776872518],
              [-80.635715724471325, 34.789313393172613],
              [-80.63538951189274, 34.789270221877743],
              [-80.635495922024731, 34.788740837448486],
              [-80.635169711860513, 34.788697666458496],
              [-80.635222917436934, 34.788432974349973],
              [-80.634896708624993, 34.788389801718161],
              [-80.635003119705928, 34.787860417728112],
              [-80.634676914382965, 34.787817244484998],
              [-80.634730119887564, 34.787552552603557],
              [-80.634403915934683, 34.787509378620001],
              [-80.634457123214133, 34.787244686842463],
              [-80.634130919538919, 34.787201512133279],
              [-80.634237335100408, 34.786672127889062],
              [-80.633911133821456, 34.786628952583527],
              [-80.633964342103582, 34.786364260116805],
              [-80.63363814330539, 34.7863210849574],
              [-80.633691352269778, 34.786056392609545],
              [-80.633365154823636, 34.786013215808396],
              [-80.633471573772965, 34.785483831324974],
              [-80.633145377630569, 34.785440653942231],
              [-80.633198588708041, 34.785175961791943],
              [-80.632872393953832, 34.785132784570209],
              [-80.632925604602903, 34.784868091652086],
              [-80.63227322034561, 34.78478173391261],
              [-80.632326433795683, 34.784517041242481],
              [-80.631674052948497, 34.784430679352219],
              [-80.631833695242577, 34.783636603887274],
              [-80.631507508413335, 34.783593422047623],
              [-80.631560722473452, 34.783328729723756],
              [-80.631234537014166, 34.783285547143741],
              [-80.631287752866911, 34.783020855825207],
              [-80.629656838027785, 34.782804929449526],
              [-80.629710057556508, 34.782540237939386],
              [-80.629383877305756, 34.782497050326725],
              [-80.629437098609074, 34.782232358920652],
              [-80.629110918635874, 34.782189170582555],
              [-80.629164140621342, 34.781924479295355],
              [-80.628837963092764, 34.781881289300756],
              [-80.628891184667694, 34.781616598147316],
              [-80.62856500852709, 34.781573408313839],
              [-80.628671454864104, 34.781044025288672],
              [-80.628345281119564, 34.781000834858936],
              [-80.628398504807052, 34.780736143903376],
              [-80.628072332414703, 34.78069295183208],
              [-80.628125555691625, 34.780428261010265],
              [-80.627799385761776, 34.780385068183953],
              [-80.62790583441037, 34.779855685836942],
              [-80.627579666894334, 34.779812493315809],
              [-80.627686116200891, 34.779283110255228],
              [-80.62801228277533, 34.779326303374937],
              [-80.628278396530703, 34.778002844542605],
              [-80.627952236178615, 34.77795965212831],
              [-80.628111902926932, 34.77716557606508],
              [-80.62843806018337, 34.777208767145872],
              [-80.628970250323476, 34.774561838756455],
              [-80.628644103326081, 34.774518649116054],
              [-80.628697321782269, 34.774253955287847],
              [-80.629023466679314, 34.774297145700373],
              [-80.629129899433678, 34.773767758597096],
              [-80.628803756588241, 34.773724568472666],
              [-80.628963405662702, 34.772930487619533],
              [-80.629016622073195, 34.772665794542988],
              [-80.628690482582655, 34.77262260412536],
              [-80.628743699657122, 34.772357910266528],
              [-80.627439153625104, 34.772185141172351],
              [-80.627492373348488, 34.771920446978299],
              [-80.627166238739505, 34.771877253314408],
              [-80.627219460237285, 34.771612559224728],
              [-80.626893326980152, 34.771569363919355],
              [-80.62705299143019, 34.770775283750524],
              [-80.626726862686766, 34.770732087978402],
              [-80.626780084878078, 34.770467394978638],
              [-80.626453956411922, 34.770424198481223],
              [-80.626560401796283, 34.769894811793705],
              [-80.626234275725565, 34.769851614700265],
              [-80.6262874989186, 34.769586921012646],
              [-80.625961375309885, 34.769543723164368],
              [-80.626014598092354, 34.769279029610615],
              [-80.626340721767676, 34.769322227300066],
              [-80.626393944272806, 34.769057533562716],
              [-80.627046188419101, 34.769143926030601],
              [-80.627099408528849, 34.76887923198025],
              [-80.627425530092125, 34.768922426743877],
              [-80.627638401896661, 34.767863649732604],
              [-80.627312284418281, 34.767820454643775],
              [-80.627525153694819, 34.766761676023599],
              [-80.627199040680964, 34.766718481528279],
              [-80.627305475307153, 34.766189092357486],
              [-80.626979365762821, 34.766145896350054],
              [-80.627032583048518, 34.765881202329659],
              [-80.626706474873728, 34.765838005582197],
              [-80.626759692823228, 34.765573310779693],
              [-80.626433586035887, 34.765530114193609],
              [-80.626486805759399, 34.765265419495634],
              [-80.626160699249311, 34.765222222184235],
              [-80.626213919636641, 34.764957526704201],
              [-80.625887814496082, 34.764914328652871],
              [-80.626047476707143, 34.764120244299185],
              [-80.626699679011907, 34.764206639584152],
              [-80.626752896997886, 34.763941943861006],
              [-80.627078997649477, 34.763985140484067],
              [-80.627132213173823, 34.763720444606889],
              [-80.62778441453176, 34.763806833996419],
              [-80.627837627661023, 34.76354213780639],
              [-80.628163727275179, 34.763585330587709],
              [-80.628216940127132, 34.763320634214125],
              [-80.628543039077471, 34.763363826868719],
              [-80.628809088682999, 34.762040343968685],
              [-80.629135182831405, 34.762083534117572],
              [-80.629560834297394, 34.75996595545967],
              [-80.629234748353284, 34.759922766463177],
              [-80.629341159814786, 34.759393370938888],
              [-80.6296672437079, 34.759436559647227],
              [-80.629773650651003, 34.758907163751033],
              [-80.629826854152881, 34.758642465758641],
              [-80.630152934221101, 34.7586856531656],
              [-80.630365739579148, 34.757626859487054],
              [-80.630691815889165, 34.75767004543377],
              [-80.630745015603367, 34.757405346128891],
              [-80.630418940318719, 34.757362160326174],
              [-80.63047214071463, 34.757097461140745],
              [-80.630146066799199, 34.757054274598083],
              [-80.630199267876876, 34.756789575532054],
              [-80.629873195330646, 34.756746388249532],
              [-80.629979598505301, 34.756216990331929],
              [-80.629653528335837, 34.756173801552102],
              [-80.629706730432986, 34.755909102700564],
              [-80.629380662742719, 34.7558659140674],
              [-80.629487066864272, 34.755336516593481],
              [-80.629161001550742, 34.75529332646316],
              [-80.629214204667363, 34.755028627826078],
              [-80.628888140722907, 34.754985436955785],
              [-80.628941344521152, 34.754720738438174],
              [-80.628615281945926, 34.754677546827992],
              [-80.628562077140131, 34.75494224610285],
              [-80.627257825101225, 34.754769471162618],
              [-80.627364242589621, 34.75424007464234],
              [-80.627038182758739, 34.754196878756503],
              [-80.627144602014738, 34.753667482411444],
              [-80.626818544578342, 34.753624285929845],
              [-80.626924963399773, 34.753094888888278],
              [-80.626598909467845, 34.753051692697518],
              [-80.626652119388211, 34.752786994284108],
              [-80.626326065715318, 34.752743796466937],
              [-80.626379277409313, 34.752479098158318],
              [-80.626053225105522, 34.752435899601316],
              [-80.626106437480985, 34.752171201412324],
              [-80.625780386546325, 34.752128002115505],
              [-80.625886811206399, 34.751598605065581],
              [-80.62556076266614, 34.751555405173036],
              [-80.625667188001501, 34.751026008313467],
              [-80.625341142947661, 34.750982807810487],
              [-80.625447568958279, 34.750453411141272],
              [-80.62512152629877, 34.750410210042624],
              [-80.625281165238945, 34.749616113943006],
              [-80.624955125998937, 34.749572912392757],
              [-80.62516797791676, 34.748514116916439],
              [-80.62484194312151, 34.748470915058782],
              [-80.625480476955445, 34.745294521722336],
              [-80.625806498374729, 34.74533772276682],
              [-80.626178943112464, 34.743484818510439],
              [-80.626504957699694, 34.743528017662257],
              [-80.626558161685637, 34.743263316555307],
              [-80.626232148123492, 34.743220117547537],
              [-80.626338557114238, 34.74269071554901],
              [-80.626012547055723, 34.742647516832278],
              [-80.62622536499498, 34.741588712218885],
              [-80.626551370953294, 34.741631910359203],
              [-80.626604573540348, 34.741367208325073],
              [-80.626278568607063, 34.741324010328867],
              [-80.626331771875414, 34.741059308414499],
              [-80.626005768310861, 34.741016109678647],
              [-80.626112175866467, 34.740486706065589],
              [-80.625786174695662, 34.740443506734167],
              [-80.625892582926383, 34.739914103312408],
              [-80.625566585223339, 34.739870902469313],
              [-80.625619789848116, 34.739606200866227],
              [-80.62529379243972, 34.739563000199659],
              [-80.625346997745751, 34.739298298716456],
              [-80.624695007066364, 34.739211893202807],
              [-80.624748214078494, 34.738947191983655],
              [-80.62442222082538, 34.738903988037968],
              [-80.624475428518735, 34.738639286938657],
              [-80.624149436634298, 34.738596082253366],
              [-80.62420264500895, 34.738331381274129],
              [-80.623876654493117, 34.738288175849164],
              [-80.623929863548952, 34.738023474989795],
              [-80.62360387440178, 34.737980268825339],
              [-80.62365708412095, 34.737715567184551],
              [-80.623331096342454, 34.737672360280477],
              [-80.623384306760641, 34.737407659661002],
              [-80.623058320350808, 34.737364452017388],
              [-80.623111532524192, 34.737099750601793],
              [-80.622459562785707, 34.737013332951683],
              [-80.622512775573142, 34.736748631814926],
              [-80.622186793336368, 34.736705421793722],
              [-80.622293221021792, 34.736176019721235],
              [-80.621967240086548, 34.736132809119439],
              [-80.622020454984337, 34.73586810818373],
              [-80.621694475417812, 34.735824896842416],
              [-80.621747690996699, 34.735560196026725],
              [-80.621095734926783, 34.735473770079871],
              [-80.621148952211655, 34.735209069528345],
              [-80.620822976817948, 34.735165855809583],
              [-80.620876193674178, 34.73490115449151],
              [-80.621195493087157, 34.733312947805544],
              [-80.620869525210921, 34.733269734212193],
              [-80.620975956890419, 34.732740330586637],
              [-80.620649991407532, 34.732697116398008],
              [-80.620703208311241, 34.732432415136564],
              [-80.620377244179139, 34.732389199307093],
              [-80.620430461763746, 34.732124498165767],
              [-80.620104499017913, 34.732081282498193],
              [-80.620157717265869, 34.731816580575597],
              [-80.619831755888555, 34.731773364168582],
              [-80.619884974817296, 34.731508662366039],
              [-80.619559014808615, 34.731465445219612],
              [-80.619612234418398, 34.731200743537286],
              [-80.619286276869957, 34.731157525636632],
              [-80.619339497160681, 34.73089282407436],
              [-80.619013539888911, 34.730849605449052],
              [-80.619066760860591, 34.730584904007003],
              [-80.61874080603134, 34.730541683726123],
              [-80.618794027683919, 34.730276982404213],
              [-80.61846807314916, 34.730233762299989],
              [-80.618521295482637, 34.729969061098181],
              [-80.617869391659795, 34.729882617596637],
              [-80.617922615698774, 34.729617916659166],
              [-80.617596665318871, 34.729574693276533],
              [-80.61764989003872, 34.729309992459257],
              [-80.617323941027308, 34.729266768337226],
              [-80.617377166428014, 34.729002067640224],
              [-80.617051219876856, 34.728958842764051],
              [-80.617104445958404, 34.728694142187237],
              [-80.616126608166269, 34.728564462733921],
              [-80.616179838069797, 34.728299762551067],
              [-80.615853893851352, 34.728256534443688],
              [-80.615907123326124, 34.727991833494357],
              [-80.615581181567904, 34.727948604632935],
              [-80.61563441174124, 34.727683904705216],
              [-80.61498253021378, 34.727597444634839],
              [-80.615088994683589, 34.727068043466893],
              [-80.614763057030714, 34.727024812387484],
              [-80.614869522174317, 34.726495411412039],
              [-80.614543586914465, 34.726452179737542],
              [-80.614703284561131, 34.725658077633412],
              [-80.614377352718805, 34.725614845508069],
              [-80.614483819596217, 34.725085443954057],
              [-80.614157890146842, 34.725042211233678],
              [-80.614211123011458, 34.724777511030339],
              [-80.613885194912697, 34.724734276669317],
              [-80.613991663825175, 34.724204875548551],
              [-80.614044896665035, 34.723940174516329],
              [-80.614370821672381, 34.723983407543223],
              [-80.614424054253391, 34.723718707229551],
              [-80.615075902159006, 34.723805170359022],
              [-80.6151291323294, 34.723540468831551],
              [-80.615455055227471, 34.723583698934107],
              [-80.615508284029545, 34.723318997238515],
              [-80.615834206247015, 34.723362226313235],
              [-80.615887433680768, 34.723097524449507],
              [-80.61621335521744, 34.723140752496555],
              [-80.616266580191294, 34.722876050479378],
              [-80.616592502138943, 34.722919277484031],
              [-80.616752171906299, 34.722125169955063],
              [-80.616426254123738, 34.722081943368494],
              [-80.616479477722379, 34.721817241255742],
              [-80.61680539448048, 34.72186046769815],
              [-80.616858616693023, 34.72159576451601],
              [-80.616532700959411, 34.721552538217836],
              [-80.616798813097972, 34.720229023557124],
              [-80.61712472370931, 34.720272249134176],
              [-80.617231162574157, 34.71974284247468],
              [-80.617882980730215, 34.719829290401471],
              [-80.617936198144008, 34.719564586740169],
              [-80.618262106713232, 34.719607809234141],
              [-80.618315322740969, 34.719343104503388],
              [-80.618641229555607, 34.71938632688574],
              [-80.61874765854914, 34.718856917965923],
              [-80.61842175270948, 34.718813696788096],
              [-80.618581395576911, 34.718019582325255],
              [-80.618255494245815, 34.717976360681874],
              [-80.618361922790513, 34.717446951214377],
              [-80.618036023851886, 34.717403728976102],
              [-80.618248880914393, 34.71634491033295],
              [-80.617922986399108, 34.716301686886709],
              [-80.617976201375697, 34.716036982303386],
              [-80.617650308246354, 34.715993759019327],
              [-80.617703523885694, 34.715729053655132],
              [-80.617377632124288, 34.715685829631802],
              [-80.61743084735275, 34.71542112440283],
              [-80.617104956959452, 34.71537789964033],
              [-80.617211390617058, 34.714848489370368],
              [-80.616885502616, 34.714805264012966],
              [-80.616991936947514, 34.714275853936556],
              [-80.616340167147499, 34.714189400232357],
              [-80.616393385846379, 34.713924695447055],
              [-80.615415736242355, 34.713795008254586],
              [-80.615468957670245, 34.713530303878393],
              [-80.615794839873388, 34.713573533977289],
              [-80.615848059933199, 34.713308829433089],
              [-80.616173940346428, 34.713352057617662],
              [-80.61622715903836, 34.713087352905589],
              [-80.616553038788823, 34.713130580963906],
              [-80.616606256095025, 34.712865875182551],
              [-80.616932135164816, 34.712909102213239],
              [-80.616985351103068, 34.712644396263983],
              [-80.617311230583965, 34.712687622252275],
              [-80.617417658307119, 34.712158210910395],
              [-80.61774353497384, 34.712201434840225],
              [-80.617903171445164, 34.711407316759285],
              [-80.618229045382833, 34.711450539373047],
              [-80.618335465572471, 34.710921127217752],
              [-80.618661337805321, 34.710964348659772],
              [-80.618714545805688, 34.710699641959152],
              [-80.619040417357951, 34.710742862373422],
              [-80.619253242900413, 34.709684033841931],
              [-80.619579110717339, 34.709727253697409],
              [-80.619738722352778, 34.708933130502636],
              [-80.620064587440652, 34.708976349042111],
              [-80.620170991419471, 34.708446932571746],
              [-80.62049685369314, 34.7084901490528],
              [-80.62055005416083, 34.708225441539454],
              [-80.620875916845506, 34.708268656978184],
              [-80.620929114836116, 34.708003948410401],
              [-80.621254976840177, 34.708047162821615],
              [-80.621361370833952, 34.707517745312217],
              [-80.621687231150958, 34.707560959453055],
              [-80.621740426044767, 34.707296249624854],
              [-80.622392144551938, 34.707382674081487],
              [-80.622445337071753, 34.707117964842752],
              [-80.622771196353284, 34.707161175143817],
              [-80.622824387505347, 34.706896465737358],
              [-80.623150245014813, 34.706939675025559],
              [-80.623416192631552, 34.705616124186001],
              [-80.623090340242939, 34.705572915618681],
              [-80.623196719137454, 34.70504349468272],
              [-80.623522569477899, 34.705086702961644],
              [-80.6236821316256, 34.704292570956419],
              [-80.624007978145755, 34.704335777934219],
              [-80.624114349509185, 34.703806356186725],
              [-80.624440194324606, 34.703849561992946],
              [-80.624546561156649, 34.703320138976657],
              [-80.624599745157923, 34.703055427869323],
              [-80.624925587244562, 34.703098632359627],
              [-80.625031949950042, 34.702569208013067],
              [-80.625357790349739, 34.702612412233115],
              [-80.625410970163287, 34.702347699880512],
              [-80.625736809864563, 34.702390902171722],
              [-80.625789988310629, 34.702126189651544],
              [-80.626115827349125, 34.702169391816582],
              [-80.626275356445348, 34.70137525279651],
              [-80.626601192755103, 34.701418453645864],
              [-80.626867062224647, 34.700094887128259],
              [-80.627192893739746, 34.70013808547214],
              [-80.627352407341505, 34.699343944139329],
              [-80.627678236127934, 34.69938714116747],
              [-80.627731405248085, 34.699122426903592],
              [-80.62778457511628, 34.698857712601423],
              [-80.628110401106653, 34.698900908472773],
              [-80.628216736673707, 34.6983714795251],
              [-80.628542560959332, 34.698414674224892],
              [-80.628808387076148, 34.697091098946352],
              [-80.62913420658532, 34.697134292042165],
              [-80.629240532140884, 34.696604861305005],
              [-80.629566349945364, 34.696648053229204],
              [-80.629725832623336, 34.695853906065778],
              [-80.630051647699347, 34.6958970966744],
              [-80.630104806875551, 34.695632380462094],
              [-80.630157964617069, 34.695367664241374],
              [-80.630483777988587, 34.69541085367851],
              [-80.630590092558862, 34.694881419948025],
              [-80.630915903152285, 34.69492460912987],
              [-80.631022213210542, 34.694395175032959],
              [-80.631348023172762, 34.694438362127137],
              [-80.631401177199848, 34.694173644441598],
              [-80.631726985408008, 34.694216831424633],
              [-80.631886440175492, 34.693422677825929],
              [-80.632212246746505, 34.6934658634786],
              [-80.632318546524886, 34.692936427336946],
              [-80.631992742001643, 34.692893241972584],
              [-80.632205341535482, 34.691834369987617],
              [-80.632531140854113, 34.691877553888915],
              [-80.632584288292108, 34.691612834796729],
              [-80.632910086947902, 34.691656018572033],
              [-80.633016378764822, 34.691126580931133],
              [-80.633342175698147, 34.691169762633727],
              [-80.633395320059051, 34.690905043183754],
              [-80.63372111631169, 34.690948223859046],
              [-80.633774259323488, 34.690683505143205],
              [-80.634100054895512, 34.690726684791173],
              [-80.634365758507613, 34.689403085652373],
              [-80.634691549303568, 34.689446263696667],
              [-80.634797825644256, 34.688916823600017],
              [-80.635123614735733, 34.688960000472939],
              [-80.6352830241118, 34.688165837912173],
              [-80.635389294955914, 34.687636396395064],
              [-80.635063512074609, 34.687593220227896],
              [-80.635222919357744, 34.686799056852067],
              [-80.634897139890811, 34.68675588023406],
              [-80.634950276335189, 34.686491158300463],
              [-80.635003411363272, 34.686226437260054],
              [-80.635329188800696, 34.686269614491238],
              [-80.635701121518153, 34.684416561889961],
              [-80.636026891023477, 34.68445973634276],
              [-80.63613315220806, 34.683930291476656],
              [-80.635807384750123, 34.683887117312082],
              [-80.635966776479663, 34.683092950280326],
              [-80.635641012417778, 34.683049774763624],
              [-80.635694142968148, 34.682785052522846],
              [-80.635368380272965, 34.682741876267222],
              [-80.635421511503964, 34.682477154147556],
              [-80.635095750175452, 34.682433977153067],
              [-80.635148883178132, 34.68216925513974],
              [-80.634497363615552, 34.682082899691054],
              [-80.634550498304492, 34.681818177041642],
              [-80.63422474005246, 34.681774997686155],
              [-80.63427787434884, 34.681510276074157],
              [-80.633952118554546, 34.681467095964912],
              [-80.634005253513436, 34.681202373572717],
              [-80.633679499103849, 34.681159193625966],
              [-80.633732634743367, 34.680894471354989],
              [-80.63340688168239, 34.680851289767936],
              [-80.63351315507056, 34.680321845430676],
              [-80.632861652980992, 34.680235480198924],
              [-80.632914791753109, 34.679970758276752],
              [-80.631937544808153, 34.679841204231558],
              [-80.631990686307944, 34.679576482718893],
              [-80.631664939399698, 34.679533296410341],
              [-80.631718081579933, 34.679268575018945],
              [-80.631392336038317, 34.679225387971528],
              [-80.631445478881062, 34.67896066580002],
              [-80.631119734706076, 34.678917478013751],
              [-80.631172878247085, 34.678652756864878],
              [-80.630847135438813, 34.67860956833978],
              [-80.630953423502902, 34.678080124459044],
              [-80.630627683084541, 34.678036935339271],
              [-80.630946546274643, 34.676448603998224],
              [-80.631272280552011, 34.676491792252989],
              [-80.631378563108797, 34.675962347105106],
              [-80.631704294591216, 34.676005534203433],
              [-80.632355759676258, 34.676091905736236],
              [-80.632302621648861, 34.676356629204768],
              [-80.632954090153021, 34.6764429974941],
              [-80.632900954902567, 34.676707720311882],
              [-80.633878162756744, 34.676837266563346],
              [-80.633931296027754, 34.676572543298384],
              [-80.634257032369078, 34.676615723462227],
              [-80.634310163164628, 34.676350999143686],
              [-80.634635898825465, 34.676394178280432],
              [-80.63468902827249, 34.676129454696216],
              [-80.635014763252855, 34.676172632805873],
              [-80.635067891315416, 34.675907908153398],
              [-80.635393624524198, 34.675951085250809],
              [-80.635446752311381, 34.675686360416542],
              [-80.635772484839677, 34.675729536486848],
              [-80.635878735174003, 34.67520008739276],
              [-80.636204467071323, 34.675243261375591],
              [-80.636310713986845, 34.674713811902087],
              [-80.637287903449817, 34.674843329520584],
              [-80.63734102331378, 34.6745786038666],
              [-80.638969673426473, 34.674794447893689],
              [-80.639182126801785, 34.673735542197846],
              [-80.639507853530162, 34.673778707240075],
              [-80.639560965538848, 34.673513980607751],
              [-80.639886691586639, 34.673557144622926],
              [-80.639939801137956, 34.673292417838546],
              [-80.640265526505175, 34.673335580826759],
              [-80.640318635781227, 34.673070853860672],
              [-80.640644360467832, 34.673114015821838],
              [-80.640697467286444, 34.672849288703844],
              [-80.641348915659336, 34.672935610590542],
              [-80.641402020070288, 34.672670882260263],
              [-80.642053467767582, 34.672757200327005],
              [-80.642106569788822, 34.672492471685857],
              [-80.642758016810461, 34.672578785932643],
              [-80.642864214639687, 34.67204932802084],
              [-80.643189936626854, 34.672092483982297],
              [-80.643349228275014, 34.671298296504752],
              [-80.643674947516587, 34.671341450249756],
              [-80.643940421224457, 34.670017801481926],
              [-80.644266134600812, 34.670060953638668],
              [-80.644425410755062, 34.669266762964774],
              [-80.644478502842333, 34.669002032384334],
              [-80.644804212467776, 34.669045183081998],
              [-80.644910391385423, 34.668515721595121],
              [-80.645236100415914, 34.668558872008155],
              [-80.645289188337287, 34.668294141086662],
              [-80.645940603161506, 34.668380437203012],
              [-80.645993688693451, 34.668115705970884],
              [-80.64697081044784, 34.668245143992856],
              [-80.647023892566992, 34.667980412306008],
              [-80.647675306724523, 34.668066699017054],
              [-80.64772838645429, 34.667801967019599],
              [-80.64805409302366, 34.667845108906782],
              [-80.648160249390386, 34.667315643654504],
              [-80.648266403277461, 34.666786177425479],
              [-80.648592106114151, 34.666829318755028],
              [-80.649122843631858, 34.6641819830283],
              [-80.649448535468963, 34.66422512114837],
              [-80.649501606953109, 34.663960386577642],
              [-80.649827298127548, 34.664003524572209],
              [-80.649880367154992, 34.663738789849937],
              [-80.650206057630584, 34.663781925916254],
              [-80.650259126383091, 34.663517191012467],
              [-80.650910506333517, 34.663603461109858],
              [-80.650963571605999, 34.663338725910556],
              [-80.651289261062672, 34.663381859040278],
              [-80.651342324969306, 34.663117123674468],
              [-80.651668013745223, 34.663160255777328],
              [-80.651721076286051, 34.662895520245023],
              [-80.652046764381225, 34.662938651321035],
              [-80.652099825556263, 34.662673915622257],
              [-80.652425512970666, 34.662717045671421],
              [-80.652584690261733, 34.661922837121921],
              [-80.652910374948931, 34.661965965856226],
              [-80.653069546087849, 34.661171756672609],
              [-80.653395226956775, 34.661214884107146],
              [-80.65350133690265, 34.660685409721054],
              [-80.653827017158363, 34.660728535969824],
              [-80.653880070048928, 34.660463798606678],
              [-80.654205749642131, 34.660506924729859],
              [-80.654258801166989, 34.660242187200382],
              [-80.654584480061231, 34.660285311395434],
              [-80.654743630584179, 34.659491097323944],
              [-80.654796679716085, 34.659226359560748],
              [-80.653819658521073, 34.659096986024821],
              [-80.653872710361952, 34.658832247769745],
              [-80.652895696409402, 34.658702866705418],
              [-80.652948750977401, 34.658438128859977],
              [-80.652297412101021, 34.658351870691007],
              [-80.65235046837293, 34.658087133111252],
              [-80.652024800471594, 34.658044002846559],
              [-80.652077858515028, 34.657779265373357],
              [-80.651426525767974, 34.657693001582231],
              [-80.651479585515233, 34.657428264374644],
              [-80.651153920687662, 34.657385131749606],
              [-80.651366159253541, 34.656326183286822],
              [-80.651691819988599, 34.65636931533583],
              [-80.651744876932653, 34.65610457803151],
              [-80.652070536987068, 34.656147709053755],
              [-80.652123592565516, 34.655882971583061],
              [-80.652449251939288, 34.655926101578537],
              [-80.652502306152201, 34.655661363941526],
              [-80.652827964845287, 34.655704492910218],
              [-80.653093227784865, 34.654380800936359],
              [-80.652767575298043, 34.654337672672661],
              [-80.653032833647032, 34.653013978172339],
              [-80.653358481018557, 34.653057105716037],
              [-80.653464580576795, 34.652527627823531],
              [-80.65379022515387, 34.65257075421151],
              [-80.653896321278353, 34.652041275040631],
              [-80.654221964151745, 34.652084400257905],
              [-80.654328056860649, 34.651554920710041],
              [-80.654653698030373, 34.651598044756611],
              [-80.654706742293754, 34.651333304362261],
              [-80.655032383891779, 34.651376428268463],
              [-80.655244552320795, 34.650317465006239],
              [-80.655570189078389, 34.650360587468818],
              [-80.655676269729113, 34.64983110495838],
              [-80.656327540161129, 34.64991734575807],
              [-80.656380577936133, 34.649652604632251],
              [-80.657031848763978, 34.649738840696827],
              [-80.657084883059952, 34.649474099275949],
              [-80.657410517418967, 34.649517215847716],
              [-80.657463551440401, 34.649252474245635],
              [-80.657789185118716, 34.649295589790761],
              [-80.657842216665941, 34.649030847136196],
              [-80.658167849663542, 34.649073961654636],
              [-80.658273910794136, 34.648544476877547],
              [-80.658599542088055, 34.648587590225361],
              [-80.658652570562509, 34.648322847216434],
              [-80.658978201175714, 34.648365959537628],
              [-80.659031228284945, 34.648101216362576],
              [-80.659356858235654, 34.64814432855848],
              [-80.659409883979677, 34.647879585217346],
              [-80.659735513231425, 34.647922695485228],
              [-80.659788537610254, 34.647657951978026],
              [-80.660114166181273, 34.647701061219259],
              [-80.660167188104211, 34.647436317561045],
              [-80.660492815994459, 34.647479425775678],
              [-80.660651879876767, 34.646685192388517],
              [-80.660977503967928, 34.646728300205069],
              [-80.661083542787082, 34.646198810280943],
              [-80.661409165174561, 34.646241916926975],
              [-80.66151519948842, 34.645712426641758],
              [-80.661840821244638, 34.645755531200777],
              [-80.661893836320033, 34.645490785888626],
              [-80.66221945739548, 34.645533889421039],
              [-80.662431511274562, 34.644474907361008],
              [-80.662757127509835, 34.644518009450046],
              [-80.662863150877769, 34.643988517090939],
              [-80.662537536688305, 34.643945415289672],
              [-80.662696570597788, 34.643151176589413],
              [-80.663022181718617, 34.643194277958919],
              [-80.663128201665302, 34.642664785379424],
              [-80.663453811082505, 34.642707885578432],
              [-80.663506818965374, 34.642443138668611],
              [-80.66383242770182, 34.642486237841077],
              [-80.663885434219623, 34.642221490765309],
              [-80.66355982650613, 34.642178391736792],
              [-80.66361283370469, 34.641913644783017],
              [-80.663938440395526, 34.641956743667642],
              [-80.66399144513862, 34.641691996562983],
              [-80.66366584056135, 34.641648897807208],
              [-80.663718847057552, 34.641384149908014],
              [-80.663393242754609, 34.641341050428593],
              [-80.663552263295699, 34.640546808833768],
              [-80.663764285845915, 34.639487817329481],
              [-80.664089881916837, 34.639530916717902],
              [-80.664142885676412, 34.63926616864304],
              [-80.664468481048345, 34.639309266103567],
              [-80.664521483443067, 34.63904451786285],
              [-80.665172673167476, 34.639130709848295],
              [-80.665225673174589, 34.638865961297796],
              [-80.665551267527064, 34.638909055822715],
              [-80.665604266169353, 34.638644307106397],
              [-80.665929859841086, 34.638687400604809],
              [-80.665982856027966, 34.638422651737798],
              [-80.666634042351902, 34.63850883579903],
              [-80.666687036151231, 34.638244086622358],
              [-80.667012628803406, 34.638287177185134],
              [-80.667118614402781, 34.63775767756173],
              [-80.667444205351416, 34.6378007669542],
              [-80.667603176472284, 34.637006516495127],
              [-80.667921110516559, 34.635418012267408],
              [-80.667595529863192, 34.635374924154839],
              [-80.668284354372261, 34.631933154452824],
              [-80.668935489449581, 34.632019326955501],
              [-80.668988470486667, 34.631754574112321],
              [-80.671593014054139, 34.632099224997539],
              [-80.671540040107885, 34.632363979006961],
              [-80.672842325249718, 34.632536282477218],
              [-80.673493469880768, 34.632622429367707],
              [-80.673546435528323, 34.632357675426995],
              [-80.67387200733387, 34.632400747404645],
              [-80.673977937702446, 34.631871237336973],
              [-80.674629078248188, 34.631957378069281],
              [-80.674682039784031, 34.631692622730348],
              [-80.675007609546796, 34.631735691628869],
              [-80.675060570808682, 34.631470936109167],
              [-80.67538613880005, 34.631514003996543],
              [-80.675439098697495, 34.631249248311256],
              [-80.676090234750717, 34.631335381135521],
              [-80.676143192261065, 34.63107062514085],
              [-80.676468759214004, 34.631113689191622],
              [-80.676521715359868, 34.630848933031466],
              [-80.676847281650268, 34.630891996957253],
              [-80.676900236431734, 34.630627240631512],
              [-80.677225802041207, 34.630670303530934],
              [-80.677278754349373, 34.63040554615354],
              [-80.67760432036836, 34.630448608011406],
              [-80.677657271330574, 34.630183851369836],
              [-80.677982836650173, 34.630226911300021],
              [-80.678035786248032, 34.629962154492922],
              [-80.678361349814622, 34.630005214313371],
              [-80.678467245921567, 34.629475699445251],
              [-80.678792807766072, 34.62951875719412],
              [-80.679004591770919, 34.628459725721122],
              [-80.679330149884976, 34.628502782913777],
              [-80.679488980129193, 34.627708507989354],
              [-80.679814535517011, 34.627751563868067],
              [-80.679867477570866, 34.627486804466457],
              [-80.6801930322777, 34.627529859318791],
              [-80.680510668075186, 34.625941304341893],
              [-80.680836215897642, 34.625984357464326],
              [-80.681153832116365, 34.624395798158325],
              [-80.681259701801736, 34.623866277310562],
              [-80.680934162159687, 34.623823225338526],
              [-80.681092965548601, 34.623028943908949],
              [-80.68141850212308, 34.623071995449614],
              [-80.681471435964625, 34.622807234470535],
              [-80.681796971858162, 34.622850284985063],
              [-80.681902834290838, 34.622320762705172],
              [-80.682228368480892, 34.622363812049691],
              [-80.682334227502423, 34.62183428939624],
              [-80.682659758880192, 34.621877336684648],
              [-80.682712686856178, 34.621612575181871],
              [-80.683363750790463, 34.62169866769468],
              [-80.683416676379949, 34.621433905882839],
              [-80.684393270694144, 34.621563038068459],
              [-80.684446191765829, 34.621298274917741],
              [-80.684771723233951, 34.621341317366372],
              [-80.685195075733063, 34.619223211979303],
              [-80.68486955355354, 34.619180171566832],
              [-80.685134142275331, 34.617856352799912],
              [-80.684808625549294, 34.617813312223959],
              [-80.684914459798364, 34.617283783785453],
              [-80.684588946548857, 34.617240742599179],
              [-80.684906449416403, 34.615652157602277],
              [-80.685231956550084, 34.615695198827169],
              [-80.685390700270503, 34.614900903806173],
              [-80.685065196222396, 34.614857863913947],
              [-80.685171024986303, 34.614328334391352],
              [-80.684845523305754, 34.614285293002951],
              [-80.684898438188668, 34.614020527902724],
              [-80.685223938846903, 34.61406356914744],
              [-80.685647239603568, 34.611945445496517],
              [-80.685972731316085, 34.611988483822692],
              [-80.686078550487863, 34.611458951741085],
              [-80.686404041605954, 34.611501989783498],
              [-80.686456949648274, 34.611237223116419],
              [-80.686782438995237, 34.611280260147993],
              [-80.686729531975175, 34.6115450269589],
              [-80.687055023775955, 34.611588063236702],
              [-80.686949210737808, 34.612117596180781],
              [-80.687274704924505, 34.612160631863759],
              [-80.687221798906435, 34.612425397997008],
              [-80.687547294456778, 34.612468432941313],
              [-80.687494389119635, 34.612733199197038],
              [-80.688145383270381, 34.612819265824967],
              [-80.688092479655083, 34.613084033248235],
              [-80.689394477232099, 34.613256156475614],
              [-80.689447375686413, 34.612991389394125],
              [-80.690098373914395, 34.613077444532649],
              [-80.690151269982763, 34.612812677142401],
              [-80.691127766836019, 34.612941753702842],
              [-80.691074873853111, 34.613206522425635],
              [-80.691400374502962, 34.613249546024335],
              [-80.691294589520979, 34.613779081890627],
              [-80.691620092556704, 34.613822104894403],
              [-80.691567200576216, 34.614086872939282],
              [-80.691892706084474, 34.61412989609051],
              [-80.691839814766439, 34.614394663356379],
              [-80.692165320548071, 34.614437685784239],
              [-80.692490826652289, 34.61448070642836],
              [-80.692437938146583, 34.614745474846352],
              [-80.693739972246064, 34.614917549143165],
              [-80.69368708639854, 34.615182318130074],
              [-80.694012597343999, 34.615225334634268],
              [-80.693959712159071, 34.615490102842152],
              [-80.694285224468175, 34.615533118607708],
              [-80.694232339964429, 34.615797886937948],
              [-80.694557853637178, 34.615840901964731],
              [-80.694504969833233, 34.616105671318678],
              [-80.694830484869641, 34.616148685606824],
              [-80.694777602818604, 34.616413454166342],
              [-80.695103119218658, 34.6164564677157],
              [-80.695050236758576, 34.61672123641295],
              [-80.695375754522303, 34.616764249223642],
              [-80.69532287383376, 34.617029018027836],
              [-80.695973912411119, 34.617115040387901],
              [-80.695921032336003, 34.617379809473434],
              [-80.696572075413414, 34.617465828575845],
              [-80.696519198132364, 34.617730597911944],
              [-80.697170243547816, 34.617816614688714],
              [-80.697117367951947, 34.618081383389388],
              [-80.697768417848778, 34.618167396007038],
              [-80.697715543975391, 34.618432165874935],
              [-80.698041069903582, 34.618475170560764],
              [-80.697988196711563, 34.618739940550988],
              [-80.698313725093755, 34.618782944482653],
              [-80.69826085258309, 34.619047714595112],
              [-80.69858638123867, 34.619090717803388],
              [-80.698533510499715, 34.619355488022599],
              [-80.698859040518983, 34.619398490492053],
              [-80.698806169352423, 34.619663259947643],
              [-80.699131701825735, 34.619706261662863],
              [-80.699078831359245, 34.619971032141947],
              [-80.699404365196258, 34.620014033118359],
              [-80.69929862524765, 34.620543572496921],
              [-80.699624162561292, 34.620586572862678],
              [-80.699571293097861, 34.620851342663492],
              [-80.699896830684892, 34.62089434230586],
              [-80.6998439619029, 34.621159112228867],
              [-80.700169500853676, 34.621202111132277],
              [-80.700116632753122, 34.621466881177454],
              [-80.700442174157999, 34.62150987932668],
              [-80.700389306720254, 34.621774648592613],
              [-80.700714848398519, 34.621817646018407],
              [-80.700661982751299, 34.6220824162925],
              [-80.700987525793323, 34.622125412979408],
              [-80.700934660808926, 34.622390182474291],
              [-80.701260205214709, 34.622433178422256],
              [-80.701207339840124, 34.622697948956123],
              [-80.701532886699965, 34.622740944149818],
              [-80.701427158025808, 34.623270483622058],
              [-80.701752707272178, 34.623313478220481],
              [-80.701699842900851, 34.623578248075766],
              [-80.702025393510922, 34.623621241935275],
              [-80.701972530892817, 34.623886010995854],
              [-80.702298081776391, 34.623929004131902],
              [-80.702245219858639, 34.624193774215883],
              [-80.702570773196413, 34.624236766597555],
              [-80.702517911941371, 34.624501535902354],
              [-80.702843466642932, 34.624544527545083],
              [-80.702790604997887, 34.624809297888731],
              [-80.703116161063363, 34.624852288792532],
              [-80.703063301171511, 34.625117058341502],
              [-80.703388858600732, 34.625160048506359],
              [-80.703335999390575, 34.625424818177336],
              [-80.703661558183626, 34.625467807603243],
              [-80.703608699655092, 34.625732577396342],
              [-80.703555840785469, 34.625997347167939],
              [-80.703881401965106, 34.626040335998461],
              [-80.703828543777121, 34.626305105892101],
              [-80.704154106320587, 34.626348093983673],
              [-80.704101248814268, 34.626612863999256],
              [-80.704752378041718, 34.626698836905035],
              [-80.704699522221105, 34.626963606284896],
              [-80.705025088923804, 34.627006592000434],
              [-80.70497223380363, 34.627271362403746],
              [-80.705623369168691, 34.627357330588424],
              [-80.70557051573428, 34.627622100355872],
              [-80.705896085505799, 34.627665083710838],
              [-80.705843232753111, 34.627929853600371],
              [-80.706168803888502, 34.627972836216344],
              [-80.706115951817551, 34.628237606227856],
              [-80.7064415242981, 34.62828058720342],
              [-80.706388672927645, 34.628545358238298],
              [-80.707039822047989, 34.628631317813195],
              [-80.706986972382154, 34.628896089113589],
              [-80.707638123821937, 34.628982045460802],
              [-80.70758527693242, 34.629246816109763],
              [-80.707910854186736, 34.629289793103005],
              [-80.707858006888586, 34.629554563889499],
              [-80.708509165575265, 34.629640516401118],
              [-80.708456319981806, 34.62990528745312],
              [-80.708781901395312, 34.629948262070009],
              [-80.708729056465032, 34.630213032342553],
              [-80.709054639242538, 34.630256006220456],
              [-80.709001796103166, 34.630520777500784],
              [-80.709327379154217, 34.630563750655114],
              [-80.709274536696725, 34.630828522057257],
              [-80.709600121111691, 34.63087149447248],
              [-80.709547279317306, 34.63113626509525],
              [-80.709872866186601, 34.631179236755848],
              [-80.709767182549086, 34.631708779140368],
              [-80.710092771805236, 34.631751750205382],
              [-80.709987089921242, 34.632281291873568],
              [-80.71031268156429, 34.632324262342969],
              [-80.71020699927162, 34.632853804227061],
              [-80.710532593301494, 34.632896774100836],
              [-80.710479753211956, 34.63316154514618],
              [-80.710805348605888, 34.633204514280784],
              [-80.710752509179329, 34.633469284546557],
              [-80.711078107027845, 34.633512252926479],
              [-80.711025267211554, 34.63377702423103],
              [-80.711350866405184, 34.633819990970437],
              [-80.711298028361554, 34.634084762381214],
              [-80.711623627847388, 34.634127729298434],
              [-80.711570790466865, 34.63439249992966],
              [-80.711896392407297, 34.634435466092079],
              [-80.711843555708796, 34.634700236845177],
              [-80.71216915792273, 34.63474320228395],
              [-80.712116321906237, 34.635007973158878],
              [-80.712441926555897, 34.635050936941468],
              [-80.712389091240269, 34.635315708839542],
              [-80.712063485548768, 34.635272744012042],
              [-80.711957811810819, 34.635802285653348],
              [-80.712283419548399, 34.635845250767794],
              [-80.712124909434195, 34.636639562595988],
              [-80.712450520581953, 34.636682527258181],
              [-80.712397684205769, 34.63694729736671],
              [-80.712723297808168, 34.636990261274271],
              [-80.712670462114062, 34.637255031504473],
              [-80.714298535937672, 34.637469835876594],
              [-80.716577854231659, 34.637770523291394],
              [-80.716630677629766, 34.637505750438159],
              [-80.717607530519359, 34.637634602413215],
              [-80.717660349435505, 34.63736983002488],
              [-80.7183115846981, 34.637455725734469],
              [-80.718153133103826, 34.638250045497571],
              [-80.71847875376119, 34.638292992015664],
              [-80.718373120791156, 34.63882253838652],
              [-80.718698743854603, 34.638865485210118],
              [-80.718645927326691, 34.639130258063432],
              [-80.718971552825892, 34.639173203230712],
              [-80.718918736980186, 34.639437976205635],
              [-80.719244363862416, 34.63948092153494],
              [-80.719191548699101, 34.639745694631578],
              [-80.719842805513522, 34.639831582027369],
              [-80.719789993146307, 34.640096355373373],
              [-80.721743777909467, 34.640353997234605],
              [-80.721796585246892, 34.640089223913904],
              [-80.722122216200916, 34.640132160540198],
              [-80.722175021064871, 34.639867386168596],
              [-80.722500651355503, 34.639910322670126],
              [-80.722606259104936, 34.639380773559104],
              [-80.722931887671024, 34.639423707989756],
              [-80.722984689466031, 34.639158933265996],
              [-80.723310317368629, 34.639201867571835],
              [-80.72336311779965, 34.638937092682966],
              [-80.72596814498894, 34.639280528794714],
              [-80.72602093580295, 34.639015752752748],
              [-80.72732345346337, 34.639187448147126],
              [-80.727376240934333, 34.638922671494328],
              [-80.728027499762334, 34.639008513608125],
              [-80.72808028375546, 34.638743736662562],
              [-80.72873154189972, 34.63882957495855],
              [-80.728784323486721, 34.638564796803323],
              [-80.729109952046613, 34.638607714483705],
              [-80.729057171482864, 34.638872492782362],
              [-80.729708431652114, 34.638958324880235],
              [-80.729655652794207, 34.639223103443733],
              [-80.731609449817029, 34.639480580280242],
              [-80.731556676739004, 34.639745358780615],
              [-80.732207947561093, 34.639831178212667],
              [-80.732155176189025, 34.640095956977916],
              [-80.734760281191882, 34.64043919603305],
              [-80.73470751875638, 34.640703975907137],
              [-80.735684442364629, 34.64083267629551],
              [-80.735631683749205, 34.641097456561916],
              [-80.735957325922968, 34.641140354779104],
              [-80.736282969527835, 34.64118325209764],
              [-80.736230211527968, 34.641448032644519],
              [-80.736881501805925, 34.641533824919605],
              [-80.736775989949621, 34.642063385604125],
              [-80.737101637646148, 34.642106280703828],
              [-80.737048881694847, 34.642371061615243],
              [-80.737374530755275, 34.642413955975442],
              [-80.737269020930327, 34.642943516200944],
              [-80.737594673468692, 34.642986409948982],
              [-80.737489163237768, 34.643515970389508],
              [-80.737814818163486, 34.643558863541202],
              [-80.737709309688967, 34.644088423263938],
              [-80.738034965911424, 34.644131315835018],
              [-80.737823948969861, 34.645190435591473],
              [-80.738149610717272, 34.645233327836927],
              [-80.738044102250342, 34.645762887870646],
              [-80.73836976636612, 34.645805778618254],
              [-80.738317012109576, 34.646070559204183],
              [-80.738642677589354, 34.646113449212102],
              [-80.738968343428738, 34.646156339238523],
              [-80.738915591950146, 34.646421119171841],
              [-80.740543931333903, 34.646635554973543],
              [-80.740491184632447, 34.646900335601202],
              [-80.741142525903228, 34.646986103831793],
              [-80.741089780908354, 34.647250884723995],
              [-80.741415452542213, 34.647293768116704],
              [-80.741309963558891, 34.647823329220465],
              [-80.741961313013746, 34.64790909301275],
              [-80.741908570048977, 34.64817387336759],
              [-80.742234246865578, 34.648216754525265],
              [-80.742181504583911, 34.648481535001409],
              [-80.742507181654688, 34.648524414533817],
              [-80.742454441166004, 34.648789196016786],
              [-80.742780119600809, 34.648832074809391],
              [-80.742727379776099, 34.649096855512269],
              [-80.743378740824014, 34.649182611620645],
              [-80.743326001615344, 34.649447392603847],
              [-80.743651684209368, 34.64949026901818],
              [-80.743598946774597, 34.649755050106826],
              [-80.743924630732678, 34.649797925781293],
              [-80.743871892890368, 34.650062707007024],
              [-80.744197578212507, 34.650105581941872],
              [-80.744250315031195, 34.649840800572953],
              [-80.745227369969257, 34.649969419649864],
              [-80.745280102285932, 34.649704637845367],
              [-80.745605786922468, 34.649747508961894],
              [-80.74565851896584, 34.649482726976444],
              [-80.74630988612212, 34.64956846629007],
              [-80.746362615777841, 34.649303683996401],
              [-80.747013983339087, 34.649389419475966],
              [-80.746961256821322, 34.649654202040104],
              [-80.747612627791284, 34.649739934274088],
              [-80.747559902980441, 34.650004717102625],
              [-80.747885589999612, 34.650047582038233],
              [-80.747832865871985, 34.650312364987997],
              [-80.75011269384413, 34.65061239398149],
              [-80.750165410805977, 34.650347610029748],
              [-80.750491100528507, 34.650390467000157],
              [-80.750543815054982, 34.650125683800653],
              [-80.751195194583502, 34.65021139569172],
              [-80.751247906703028, 34.649946611282729],
              [-80.751899285545278, 34.650032319355525],
              [-80.751951995277167, 34.649767534638386],
              [-80.752277683630027, 34.649810386764457],
              [-80.752330391997987, 34.649545601882309],
              [-80.752981768787052, 34.649631304084693],
              [-80.75292906246645, 34.649896089252969],
              [-80.753254751840032, 34.649938938730052],
              [-80.753202046202873, 34.65020372401959],
              [-80.75352773805038, 34.650246573642285],
              [-80.753475033096606, 34.650511359052949],
              [-80.753800725198033, 34.65055420705022],
              [-80.753748020927816, 34.650818992582145],
              [-80.754399409289505, 34.650904686197919],
              [-80.754346707817248, 34.651169471978157],
              [-80.75467240298677, 34.651212317612583],
              [-80.755323796547188, 34.651298007101815],
              [-80.755376494948251, 34.651033220892309],
              [-80.756353583649471, 34.65116174673814],
              [-80.756406278639133, 34.650896960077539],
              [-80.75673197491237, 34.650939801013109],
              [-80.757100825311738, 34.649086291841016],
              [-80.758077892434869, 34.649214803673168],
              [-80.758130580608594, 34.648950016265218],
              [-80.759107646630895, 34.649078519737017],
              [-80.759160331374062, 34.648813730976755],
              [-80.760137398476914, 34.648942226056377],
              [-80.760084716804869, 34.649207015245906],
              [-80.76041040825082, 34.649249844404061],
              [-80.760357727262516, 34.649514633714716],
              [-80.761334805659956, 34.64964311813911],
              [-80.761282127383438, 34.649907906955612],
              [-80.762259211912493, 34.650036383861888],
              [-80.762206537457914, 34.650301173069572],
              [-80.764486420777331, 34.650600922006291],
              [-80.764539088065519, 34.650336131797559],
              [-80.765516182775727, 34.650464581311475],
              [-80.765410855092711, 34.650994162548891],
              [-80.765736556414183, 34.651036977267609],
              [-80.765631228328161, 34.651566558719558],
              [-80.765956932037128, 34.651609372841207],
              [-80.765904269598664, 34.651874163661283],
              [-80.766229974671532, 34.651916977042823],
              [-80.766282636086089, 34.651652186079758],
              [-80.76660834158514, 34.65169499932059],
              [-80.766555680084267, 34.651959789541309],
              [-80.767532799472306, 34.652088222623625],
              [-80.767427482684482, 34.652617803841139],
              [-80.76775319152415, 34.652660613994627],
              [-80.767647876514928, 34.653190195394338],
              [-80.76797358772285, 34.653233004049348],
              [-80.767920930732245, 34.653497794859192],
              [-80.768898071648763, 34.653626215922479],
              [-80.768845416299115, 34.653891007155316],
              [-80.769171132005297, 34.65393381254782],
              [-80.7691184784303, 34.654198603885703],
              [-80.769444195500313, 34.654241408538027],
              [-80.769391542609412, 34.654506199996909],
              [-80.769065824515494, 34.654463395201638],
              [-80.769013170260735, 34.654728186495653],
              [-80.769338889359048, 34.654770990532498],
              [-80.769286234697503, 34.655035781963498],
              [-80.769611955179201, 34.655078586161537],
              [-80.769559302273009, 34.655343376796161],
              [-80.769885024118551, 34.655386180254027],
              [-80.769779719353636, 34.655915762644618],
              [-80.770105443587013, 34.655958565505237],
              [-80.770052791708949, 34.656223356359824],
              [-80.770378518396996, 34.656266158464192],
              [-80.770273215669064, 34.656795740393292],
              [-80.770598943654164, 34.65683854191645],
              [-80.770493641594967, 34.657368123142007],
              [-80.771145103771687, 34.657453724094545],
              [-80.771092454270544, 34.65771851450954],
              [-80.77174392092617, 34.657804111297878],
              [-80.771691273152584, 34.658068902878064],
              [-80.772342742125119, 34.658154496435714],
              [-80.772290097150517, 34.658419288263524],
              [-80.77261583370624, 34.658462083401851],
              [-80.773918782256871, 34.658633256040673],
              [-80.77386614206263, 34.658898048561014],
              [-80.774191881341835, 34.658940839426378],
              [-80.774139240721453, 34.65920563118231],
              [-80.775116464780709, 34.659333999793077],
              [-80.775063826892605, 34.659598791955759],
              [-80.775389569579332, 34.659641579574121],
              [-80.775336933466406, 34.659906371841487],
              [-80.776965659299478, 34.660120297367541],
              [-80.77691302796724, 34.660385090327267],
              [-80.777238774741079, 34.660427872932182],
              [-80.777080882868646, 34.661222252091378],
              [-80.777406633035127, 34.661265033340335],
              [-80.777301372499792, 34.661794619616543],
              [-80.777627125054366, 34.66183740026802],
              [-80.777574495846494, 34.662102193507764],
              [-80.777900249784565, 34.662144974320249],
              [-80.777847621241605, 34.662409766779419],
              [-80.778499133276284, 34.662495326024214],
              [-80.778446506441867, 34.662760118746945],
              [-80.77877226451902, 34.662802896277967],
              [-80.778719637297669, 34.663067690038936],
              [-80.779696916659844, 34.663196018662276],
              [-80.779749540828249, 34.662931225374123],
              [-80.780075300284054, 34.662974000273401],
              [-80.780127921997519, 34.662709206836489],
              [-80.780779439860439, 34.662794752798348],
              [-80.780832060257069, 34.662529958136155],
              [-80.781157818683724, 34.662572730100095],
              [-80.781210436625628, 34.6623079352891],
              [-80.781536194367732, 34.662350706226981],
              [-80.781588810945721, 34.662085911251062],
              [-80.782891841175712, 34.662256983796198],
              [-80.782944453317, 34.661992188226947],
              [-80.783270210709361, 34.662034954462996],
              [-80.783322821486948, 34.661770158728913],
              [-80.783974334131813, 34.661855687380516],
              [-80.784026942521237, 34.661590891338712],
              [-80.786958761858088, 34.661975722037418],
              [-80.786906163777459, 34.662240519348082],
              [-80.787883446069031, 34.66236878047701],
              [-80.787830850701781, 34.662633577292581],
              [-80.788482377066813, 34.662719080564834],
              [-80.788534970365802, 34.662454282562386],
              [-80.78886073248799, 34.662497032738976],
              [-80.788913324442831, 34.662232235473084],
              [-80.78956484763782, 34.662317731989589],
              [-80.789617437204598, 34.662052934416103],
              [-80.789943198277285, 34.662095680756103],
              [-80.790048373233205, 34.661566084372431],
              [-80.79037413261662, 34.661608830444898],
              [-80.790321546868256, 34.661873628331769],
              [-80.790973068702129, 34.66195911811247],
              [-80.790920484682346, 34.662223917164063],
              [-80.792223537572823, 34.662394884878445],
              [-80.792276117495447, 34.662130085255953],
              [-80.793253407937726, 34.662258301785748],
              [-80.793200831068134, 34.662523100935076],
              [-80.793526595895059, 34.662565838460388],
              [-80.793474020801241, 34.662830637714016],
              [-80.79379978699194, 34.662873374498758],
              [-80.793747211492089, 34.663138173889415],
              [-80.794072980137415, 34.66318090991728],
              [-80.794020405322314, 34.663445709428586],
              [-80.794346174220806, 34.663488443830822],
              [-80.794293601201261, 34.663753244347824],
              [-80.79461937146354, 34.663795978009475],
              [-80.794566799109035, 34.664060777745753],
              [-80.795218343791504, 34.664146242688247],
              [-80.795165773146323, 34.664411042687838],
              [-80.796143096354029, 34.664539234798049],
              [-80.796090528442377, 34.664804035203652],
              [-80.796416304905208, 34.664846763977209],
              [-80.796363736587679, 34.665111564519634],
              [-80.797992630205798, 34.665325195834818],
              [-80.797940067761658, 34.665589997052223],
              [-80.798917410404229, 34.665718164777871],
              [-80.798969970866082, 34.665453363116079],
              [-80.799022529917437, 34.66518856234989],
              [-80.799348309434833, 34.665231283173526],
              [-80.799453424431192, 34.66470167948691],
              [-80.80010498036674, 34.664787117012182],
              [-80.800157535307065, 34.66452231485048],
              [-80.800483312769359, 34.664565032596201],
              [-80.800535865255071, 34.664300230286052],
              [-80.801187419148874, 34.664385662842207],
              [-80.801239970337406, 34.664120860208357],
              [-80.801565746749105, 34.664163574117453],
              [-80.80161829657402, 34.663898771318898],
              [-80.80226984838626, 34.663984197103183],
              [-80.802322394732244, 34.663719394013697],
              [-80.802973945852941, 34.663804815979482],
              [-80.80292140157556, 34.664069620255574],
              [-80.803572956102144, 34.664155038973178],
              [-80.803520413514477, 34.664419842611203],
              [-80.804171971446905, 34.664505258080702],
              [-80.804119430568875, 34.664770061981834],
              [-80.804445211058479, 34.664812768083543],
              [-80.804497750912091, 34.664547964039883],
              [-80.804823530736357, 34.664590670017013],
              [-80.804770991907162, 34.6648554742033],
              [-80.805096774166159, 34.664898178522002],
              [-80.805044236022056, 34.665162982828818],
              [-80.806021585745768, 34.665291091846697],
              [-80.806347369295196, 34.665333792790854],
              [-80.806399903341458, 34.665068987913642],
              [-80.806725686225505, 34.665111688733298],
              [-80.806778218908121, 34.664846883691339],
              [-80.807104001087069, 34.664889582583697],
              [-80.80715653240614, 34.664624777377121],
              [-80.807482313899854, 34.664667475243533],
              [-80.807534843855194, 34.664402669872281],
              [-80.80818640581127, 34.664488062669939],
              [-80.808238933378576, 34.664223256991555],
              [-80.808564713840923, 34.664265951922708],
              [-80.808617238953758, 34.66400114609614],
              [-80.80894301873083, 34.664043840001433],
              [-80.808995542460181, 34.663779033108845],
              [-80.809321322662825, 34.663821726873159],
              [-80.809373845028617, 34.663556919815967],
              [-80.809699623435108, 34.663599611669426],
              [-80.809752144457235, 34.663334805349088],
              [-80.810403700238723, 34.663420186120881],
              [-80.810456218852977, 34.663155378591959],
              [-80.810781996227959, 34.663198067510265],
              [-80.810834513478781, 34.662933259816789],
              [-80.811811844564929, 34.663061320844072],
              [-80.811759330387488, 34.66332612896521],
              [-80.81241088928013, 34.663411498835323],
              [-80.812358376792488, 34.663676306318195],
              [-80.813009939090279, 34.663761672939962],
              [-80.813335720182408, 34.663804354032742],
              [-80.81338822850573, 34.663539546138701],
              [-80.813714010023247, 34.663582227090444],
              [-80.813766516963241, 34.663317418130511],
              [-80.814092296704459, 34.663360098072857],
              [-80.814144802280865, 34.66309528894849],
              [-80.814470582427546, 34.663137967848378],
              [-80.814523086640477, 34.662873158559492],
              [-80.815174643700303, 34.662958512555953],
              [-80.815227145545265, 34.662693703861301],
              [-80.815552923549262, 34.662736378941318],
              [-80.81560542401094, 34.66247156918083],
              [-80.815931201349329, 34.662514244136311],
              [-80.815983700447475, 34.662249434211397],
              [-80.816961031383542, 34.662377451547627],
              [-80.817013527069477, 34.662112641173117],
              [-80.818316635645118, 34.662283318618755],
              [-80.818264145147864, 34.662548129546714],
              [-80.818589924173565, 34.662590797292907],
              [-80.818537434361858, 34.662855608341381],
              [-80.818863214730968, 34.662898274445361],
              [-80.818810724514009, 34.663163085630757],
              [-80.819136507337404, 34.663205750977411],
              [-80.819462289408847, 34.663248415457161],
              [-80.819514776505955, 34.662983604904568],
              [-80.819840558982676, 34.663026268341923],
              [-80.819893044696485, 34.662761456723537],
              [-80.820218825396665, 34.662804119151595],
              [-80.820271309747099, 34.662539307368753],
              [-80.82059708976179, 34.662581968771036],
              [-80.820649572748763, 34.662317156823775],
              [-80.820975353188729, 34.662359818084902],
              [-80.821132795932201, 34.661565380799232],
              [-80.821458572526751, 34.661608039864859],
              [-80.821563531333013, 34.661078414301642],
              [-80.8218893062175, 34.661121072199002],
              [-80.821994260528712, 34.660591446279597],
              [-80.822645807236412, 34.660676759755823],
              [-80.822698282370339, 34.660411946019387],
              [-80.825956024801386, 34.660838454485017],
              [-80.825903560982965, 34.661103268727913],
              [-80.826229338222902, 34.661145914856789],
              [-80.826176875110193, 34.661410730121567],
              [-80.82715421300675, 34.661538662717362],
              [-80.827206673045978, 34.661273847025349],
              [-80.827858230574407, 34.661359131332851],
              [-80.827910688225899, 34.661094315334111],
              [-80.828236466473726, 34.6611369560204],
              [-80.828288921671074, 34.660872139873952],
              [-80.828614699233114, 34.660914779534536],
              [-80.828719607653639, 34.660385146874546],
              [-80.829696936204869, 34.660513058800205],
              [-80.82974938737901, 34.660248242001927],
              [-80.830400939384148, 34.660333511617033],
              [-80.830453387079828, 34.660068694528647],
              [-80.830779162575936, 34.660111328319438],
              [-80.830831608908454, 34.659846511066768],
              [-80.831483158847519, 34.659931774812165],
              [-80.831535602792442, 34.659666957252888],
              [-80.832187152016886, 34.659752216278761],
              [-80.83208226788642, 34.660281851944916],
              [-80.832408045075695, 34.660324481318895],
              [-80.832355603526963, 34.660589299261495],
              [-80.832681383150074, 34.660631926976492],
              [-80.832576501065759, 34.661161562179466],
              [-80.832902282005819, 34.661204190213766],
              [-80.832797401706998, 34.661733825597132],
              [-80.8331231850146, 34.661776452131441],
              [-80.833018305410434, 34.66230608771194],
              [-80.833344092196626, 34.662348713630927],
              [-80.833396530608042, 34.662083896168284],
              [-80.833722316708275, 34.662126521061488],
              [-80.833774753736478, 34.661861702533209],
              [-80.834100539170905, 34.661904327302111],
              [-80.83415297483586, 34.661639508609582],
              [-80.834804543540159, 34.661724753426306],
              [-80.834856977928439, 34.661459935311882],
              [-80.835834329905012, 34.661587795492807],
              [-80.835886759770048, 34.661322976044801],
              [-80.836212544142015, 34.661365594184865],
              [-80.836264972644031, 34.661100774572702],
              [-80.836916539263484, 34.66118600793471],
              [-80.836968965398043, 34.660921188917278],
              [-80.837620531302349, 34.661006417559719],
              [-80.837672953958347, 34.660741598252464],
              [-80.837998736929023, 34.660784210647321],
              [-80.838208423261804, 34.659724928974839],
              [-80.838522942000623, 34.658136003115054],
              [-80.838197170345879, 34.658093391225364],
              [-80.838249589238202, 34.65782856987515],
              [-80.838575359868457, 34.657871181622546],
              [-80.838837447403407, 34.656547073782576],
              [-80.839163212158908, 34.656589683951779],
              [-80.839215628062377, 34.656324862169399],
              [-80.838889863240553, 34.656282252159244],
              [-80.83904710977194, 34.655487785339133],
              [-80.838721349452655, 34.655445174855906],
              [-80.838773766051389, 34.655180353271163],
              [-80.83844800707466, 34.655137741145545],
              [-80.838500423268613, 34.654872919697993],
              [-80.838174665675041, 34.654830307732723],
              [-80.838227083625441, 34.654565485487701],
              [-80.837901327394732, 34.654522872781449],
              [-80.837953744940421, 34.65425805067369],
              [-80.837627990052368, 34.654215436325117],
              [-80.837732827267885, 34.653685792312459],
              [-80.837407075877991, 34.653643178249787],
              [-80.83751191376831, 34.653113533533535],
              [-80.837186163674872, 34.653070918889007],
              [-80.837343421580343, 34.652276451619677],
              [-80.83766916862092, 34.652319066738571],
              [-80.837721585487699, 34.652054243840418],
              [-80.83804733182204, 34.65209685703244],
              [-80.838152163602572, 34.651567211771081],
              [-80.838477907135925, 34.651609823811775],
              [-80.838530320938773, 34.651345000563929],
              [-80.839181808062847, 34.65143022169417],
              [-80.839286630556018, 34.650900574564275],
              [-80.83933904238566, 34.650635750950087],
              [-80.839013302272718, 34.65059314124521],
              [-80.83906571369765, 34.650328317768377],
              [-80.838739974927122, 34.650285706421222],
              [-80.838792387037984, 34.650020883065132],
              [-80.838466649650442, 34.649978271878396],
              [-80.838519062426911, 34.649713447741526],
              [-80.83819332749276, 34.649670835797124],
              [-80.83824574095523, 34.649406011781089],
              [-80.837920006293047, 34.64936339911246],
              [-80.837972421532015, 34.649098575200362],
              [-80.83764668823251, 34.64905596179085],
              [-80.83769910306674, 34.648791138016122],
              [-80.837751518653249, 34.648526314203096],
              [-80.838077248833727, 34.648568928245993],
              [-80.838129663057487, 34.648304104269123],
              [-80.838455392531912, 34.648346716385113],
              [-80.838507805393036, 34.648081892244228],
              [-80.838833534181575, 34.648124503334671],
              [-80.838885944589478, 34.647859679046526],
              [-80.839211673802936, 34.647902289996146],
              [-80.83957852995367, 34.6460485156747],
              [-80.839904252315634, 34.646091123843426],
              [-80.839956657948065, 34.645826298196788],
              [-80.840009062151424, 34.645561472545253],
              [-80.83935762513444, 34.645476255843803],
              [-80.839410032138602, 34.64521143043865],
              [-80.839084314596263, 34.645168820012444],
              [-80.83913672228627, 34.644903994728104],
              [-80.838485290285206, 34.644818772412108],
              [-80.838537699685119, 34.644553947390868],
              [-80.838642518540453, 34.64402429636587],
              [-80.838968230218811, 34.644066908005833],
              [-80.839020637559344, 34.643802081876373],
              [-80.839346349642511, 34.643844692474147],
              [-80.839503566582096, 34.643050214431199],
              [-80.839829274840426, 34.643092823735486],
              [-80.83993408238014, 34.642563170713792],
              [-80.840585496885765, 34.642648385185588],
              [-80.840637897009358, 34.642383558374675],
              [-80.840963604300882, 34.642426164585785],
              [-80.841016003061981, 34.642161337611036],
              [-80.841341709647423, 34.64220394189531],
              [-80.841394107046213, 34.641939114756873],
              [-80.841719812966033, 34.641981718916959],
              [-80.841824605770839, 34.641452063372888],
              [-80.842150308890012, 34.641494666382016],
              [-80.842202703205558, 34.641229837993144],
              [-80.842528405638944, 34.641272439976802],
              [-80.84263319231782, 34.640742783734879],
              [-80.842958893020992, 34.640785383649423],
              [-80.843011284293766, 34.640520555813012],
              [-80.843336984311065, 34.640563154702122],
              [-80.843598931491101, 34.639239010865367],
              [-80.843924626726135, 34.639281608159862],
              [-80.84397701390138, 34.639016779009062],
              [-80.844302707380152, 34.639059376196201],
              [-80.844459863784195, 34.63826488728737],
              [-80.844134173377498, 34.638222290527082],
              [-80.84423894357343, 34.637692631148553],
              [-80.843913255573241, 34.637650034691063],
              [-80.843965641723173, 34.637385204653],
              [-80.843639955085195, 34.637342607454677],
              [-80.843692341920871, 34.637077777537534],
              [-80.843366656624838, 34.637035178697069],
              [-80.843419044166708, 34.636770349802219],
              [-80.843093359142259, 34.636727750237682],
              [-80.843198136309368, 34.636198090849199],
              [-80.842872454761803, 34.636155490669459],
              [-80.843082006932534, 34.635096171337871],
              [-80.843186780978627, 34.634566511093581],
              [-80.843512456382442, 34.634609110419497],
              [-80.843564841884501, 34.634344280573757],
              [-80.843890515491779, 34.634386877989705],
              [-80.843942899611378, 34.634122047079074],
              [-80.844268573643603, 34.634164644354207],
              [-80.844320956401205, 34.63389981328001],
              [-80.844646628657131, 34.633942409546577],
              [-80.84480377073865, 34.633147915786061],
              [-80.845129441351375, 34.633190510725953],
              [-80.845286576207286, 34.632396015462639],
              [-80.84561224408634, 34.632438609092603],
              [-80.845664620294571, 34.632173776558062],
              [-80.845990286397353, 34.632216369179396],
              [-80.845937911192749, 34.632481200954899],
              [-80.846589246440104, 34.632566382931657],
              [-80.846536872965558, 34.632831215871796],
              [-80.847513882524396, 34.632958982173406],
              [-80.847461511763299, 34.633223814617772],
              [-80.847787182946036, 34.633266401466244],
              [-80.847734812891233, 34.633531234932875],
              [-80.848060486526464, 34.633573821023766],
              [-80.848008117137184, 34.633838653710157],
              [-80.848333791043942, 34.633881239076985],
              [-80.848386159409259, 34.633616406248336],
              [-80.848711833740893, 34.633658991474483],
              [-80.848816567389079, 34.633129324567584],
              [-80.849142238920265, 34.633171908642858],
              [-80.849246969168405, 34.632642241366504],
              [-80.849572640059947, 34.632684823356072],
              [-80.849677365837962, 34.632155156628414],
              [-80.850003033929156, 34.632197737467223],
              [-80.850055395812163, 34.631932903019539],
              [-80.850381063237748, 34.631975483734365],
              [-80.850538142696365, 34.631180979854172],
              [-80.850863807388137, 34.631223559259197],
              [-80.85091616587134, 34.630958724442195],
              [-80.851241829856832, 34.63100130192057],
              [-80.85129418588761, 34.630736466957018],
              [-80.851619849187145, 34.630779043410136],
              [-80.851724559277514, 34.630249373118097],
              [-80.85205022086717, 34.630291948403681],
              [-80.852102573836348, 34.630027113092069],
              [-80.852428234740046, 34.630069687352368],
              [-80.852532938707029, 34.629540016364388],
              [-80.852858596810435, 34.629582589474033],
              [-80.853015646569077, 34.628788080612388],
              [-80.852689990446933, 34.628745507946249],
              [-80.852742340344207, 34.628480671464132],
              [-80.85241668667426, 34.62843809804037],
              [-80.852469037257592, 34.628173261679329],
              [-80.852143383859016, 34.628130687531595],
              [-80.852248087149945, 34.627601015013845],
              [-80.851922436137002, 34.627558440267556],
              [-80.852027140103601, 34.627028767048394],
              [-80.852352787998825, 34.627071342428366],
              [-80.852614534146582, 34.625747157891581],
              [-80.852288891390785, 34.625704584124108],
              [-80.852341241712736, 34.625439746918346],
              [-80.852015600318623, 34.625397172410132],
              [-80.852120299815567, 34.624867498253586],
              [-80.851794660806945, 34.624824923146875],
              [-80.851847011072294, 34.624560086179187],
              [-80.851521374495363, 34.624517509413565],
              [-80.85157372544667, 34.624252672567167],
              [-80.851678426315146, 34.623722997909766],
              [-80.85200406091117, 34.623765574231911],
              [-80.852161106618567, 34.622971062112065],
              [-80.852486737370569, 34.623013636239932],
              [-80.852539084226706, 34.622748798081432],
              [-80.852864714313185, 34.622791372085473],
              [-80.852917059807623, 34.622526533763747],
              [-80.853893950072646, 34.622654248231932],
              [-80.854155654961289, 34.621330055110043],
              [-80.853830030003465, 34.621287484608153],
              [-80.853934712505719, 34.620757807122992],
              [-80.85426033539558, 34.620800376438979],
              [-80.854417351343145, 34.620005858759669],
              [-80.854091732594156, 34.619963288952107],
              [-80.854196410607443, 34.619433610372866],
              [-80.853870793153305, 34.619391039983739],
              [-80.853923133211893, 34.619126200345818],
              [-80.853597517139505, 34.619083630117359],
              [-80.853649857883937, 34.618818790600805],
              [-80.853324244243012, 34.61877621871345],
              [-80.85337658458289, 34.618511379335004],
              [-80.853050972303507, 34.618468806707007],
              [-80.853103314419471, 34.618203967433018],
              [-80.852777703521809, 34.618161394965703],
              [-80.852882387676956, 34.617631715755032],
              [-80.853207996547752, 34.617674288839218],
              [-80.853260337629891, 34.61740944860118],
              [-80.853585945814885, 34.617452020660309],
              [-80.853638285535695, 34.617187180259123],
              [-80.853963893034901, 34.617229751293024],
              [-80.854016230303969, 34.616964910745658],
              [-80.854341837117445, 34.617007480754367],
              [-80.854394174115384, 34.616742640027027],
              [-80.854719780242974, 34.616785209010715],
              [-80.854772114789355, 34.616520368137046],
              [-80.855097720231029, 34.616562936095598],
              [-80.855150053416054, 34.616298095058916],
              [-80.855475658171997, 34.616340661992382],
              [-80.855527989995679, 34.616075820792531],
              [-80.855202386263329, 34.616033254001273],
              [-80.855254719862998, 34.615768412905929],
              [-80.854603515479127, 34.615683277860597],
              [-80.854655850788262, 34.615418437028936],
              [-80.854330250116362, 34.61537586787351],
              [-80.854382585020957, 34.615111027179999],
              [-80.854056986800614, 34.615068457267121],
              [-80.854109322391011, 34.614803616694985],
              [-80.854434920678131, 34.614846186448887],
              [-80.854487254886834, 34.61458134481235],
              [-80.855138449336835, 34.614666481403788],
              [-80.855190781160687, 34.614401639461988],
              [-80.855516378413981, 34.614444206282833],
              [-80.855568708876547, 34.614179364177964],
              [-80.856219901238461, 34.614264494002015],
              [-80.856272229336597, 34.613999652493249],
              [-80.856923421002293, 34.614084778501528],
              [-80.856975746695326, 34.613819935786182],
              [-80.857301341476287, 34.613862497782875],
              [-80.857353665808063, 34.613597654904488],
              [-80.857679259903179, 34.613640215876167],
              [-80.857731582873654, 34.613375372834859],
              [-80.858382771099656, 34.613460490926975],
              [-80.858435090595236, 34.613195647597351],
              [-80.859086278124835, 34.613280761873668],
              [-80.859190913099013, 34.612751074566255],
              [-80.859516504767896, 34.612793629653531],
              [-80.85962113634443, 34.612263941978703],
              [-80.859946726303903, 34.61230649589875],
              [-80.859999040017058, 34.612041651896483],
              [-80.859673451060686, 34.611999097217293],
              [-80.859882707680754, 34.610939719749759],
              [-80.859935021799075, 34.610674874867627],
              [-80.860911773217865, 34.610802533239493],
              [-80.860964082837938, 34.610537687927099],
              [-80.861615250811425, 34.610622788498127],
              [-80.861667558047003, 34.610357942880569],
              [-80.861993140416232, 34.610400491265906],
              [-80.86204544629075, 34.610135645485514],
              [-80.862371029064221, 34.610178192828975],
              [-80.86242333357761, 34.609913346885612],
              [-80.862748914574965, 34.609955893220985],
              [-80.862801217727423, 34.609691047114858],
              [-80.864103544289264, 34.609861223910073],
              [-80.864051245230883, 34.610126070584919],
              [-80.86502799758938, 34.610253694550764],
              [-80.865080292487193, 34.609988847466404],
              [-80.865731459262918, 34.610073924827894],
              [-80.865783752866292, 34.609809077421616],
              [-80.866109335191894, 34.609851614644427],
              [-80.866161626323631, 34.609586766191015],
              [-80.866487209053247, 34.60962930237195],
              [-80.866539498844588, 34.609364454657033],
              [-80.866865080888189, 34.609406989813003],
              [-80.867074232543871, 34.608347596375253],
              [-80.866748654594247, 34.608305061787767],
              [-80.866800942677727, 34.6080402130682],
              [-80.867126518513828, 34.608082747530467],
              [-80.867283377669338, 34.607288200821294],
              [-80.869562398656598, 34.607585914320182],
              [-80.869614676112604, 34.607321064397979],
              [-80.869940251623575, 34.607363590311017],
              [-80.870097079986991, 34.60656903997743],
              [-80.870422651674716, 34.606611564598431],
              [-80.870631746937917, 34.605552161499737],
              [-80.871282882089076, 34.605637208776002],
              [-80.871335152731803, 34.605372357219295],
              [-80.871660720334944, 34.605414879382657],
              [-80.871712989617009, 34.605150027663214],
              [-80.872038556534022, 34.605192548801675],
              [-80.872143092039238, 34.604662845017032],
              [-80.872468657246714, 34.60470536498854],
              [-80.872729983858818, 34.603381102670859],
              [-80.874357786079088, 34.603593684884466],
              [-80.874410045478257, 34.603328831104697],
              [-80.876363413768772, 34.60358389904583],
              [-80.87641566560022, 34.603319044410284],
              [-80.877066790097729, 34.603404059393462],
              [-80.877119039545036, 34.60313920445325],
              [-80.877444600196384, 34.603181710946338],
              [-80.877496848283187, 34.602916855843638],
              [-80.877822409338364, 34.602959361294843],
              [-80.877874654974406, 34.602694506046554],
              [-80.878200215322792, 34.602737009571698],
              [-80.87825246068833, 34.602472154143832],
              [-80.878578019280909, 34.602514657562487],
              [-80.878630263285885, 34.602249801972071],
              [-80.878955821171672, 34.602292303464672],
              [-80.879060305033406, 34.601762591955449],
              [-80.8793858612094, 34.601805092281062],
              [-80.879490341675847, 34.60127538040588],
              [-80.879815897232206, 34.601317879547771],
              [-80.879920373192704, 34.600788166422333],
              [-80.880245925970115, 34.600830665315733],
              [-80.880350399604836, 34.600300950906096],
              [-80.880675950672455, 34.600343448632735],
              [-80.880780419822045, 34.599813733874349],
              [-80.882733720922488, 34.60006869548554],
              [-80.882681493746603, 34.600333553709852],
              [-80.883007046129521, 34.600376044213917],
              [-80.883059272302788, 34.60011118674889],
              [-80.883710377123165, 34.600196164807478],
              [-80.883762600891941, 34.59993130613676],
              [-80.884413703923542, 34.600016280397469],
              [-80.884465926398477, 34.599751421405252],
              [-80.885117028731372, 34.599836391850957],
              [-80.885169247732492, 34.599571532571403],
              [-80.885494798380975, 34.599614016328204],
              [-80.885547017111833, 34.599349156869131],
              [-80.886198117372942, 34.599434121450386],
              [-80.886250332630027, 34.599169261704098],
              [-80.887226982457932, 34.599296700628116],
              [-80.887279194307794, 34.599031840435558],
              [-80.887930293824127, 34.599116794720032],
              [-80.887982503290274, 34.598851934223241],
              [-80.888308052540737, 34.598894410350063],
              [-80.888360259556663, 34.598629549708001],
              [-80.888685808099893, 34.59867202390884],
              [-80.888738014845458, 34.598407163087309],
              [-80.889063562722939, 34.598449637164705],
              [-80.889115766997563, 34.598184775296666],
              [-80.889441314188531, 34.598227248349481],
              [-80.889545720791034, 34.597697525152647],
              [-80.890196812069306, 34.597782467140725],
              [-80.890144610427924, 34.598047328591051],
              [-80.890470157606018, 34.598089798853586],
              [-80.890365757560247, 34.598619522844118],
              [-80.891016857000039, 34.598704460387339],
              [-80.890964657418195, 34.598969322202336],
              [-80.891941313274884, 34.59909672275564],
              [-80.891889117516584, 34.599361584958864],
              [-80.89319133264685, 34.599531439092644],
              [-80.893139141756208, 34.599796302727313],
              [-80.893464697399068, 34.599838763970389],
              [-80.893412506084346, 34.600103626842362],
              [-80.893738064177484, 34.600146087327417],
              [-80.893685873549359, 34.600410950320871],
              [-80.89401143191283, 34.600453410082181],
              [-80.893907052783106, 34.600983136274607],
              [-80.894232613530249, 34.601025595436823],
              [-80.89412823507962, 34.601555320930068],
              [-80.894453799300607, 34.601597779476101],
              [-80.894297231613706, 34.602392367546166],
              [-80.894622798151985, 34.602434825652274],
              [-80.89446623048363, 34.603229413062692],
              [-80.894791802609461, 34.603271870677226],
              [-80.894687423800278, 34.603801595810431],
              [-80.895012997219965, 34.603844052843066],
              [-80.894804241792713, 34.604903501594137],
              [-80.8951298196435, 34.604945958311468],
              [-80.895077631502858, 34.605210820130623],
              [-80.894752051538376, 34.605168363288641],
              [-80.894491096325439, 34.606492672337438],
              [-80.894165511579715, 34.606450213903152],
              [-80.894008930651765, 34.607244797228553],
              [-80.894660106621771, 34.607329714065571],
              [-80.894607915428992, 34.607594574798568],
              [-80.894933504942955, 34.607637032034575],
              [-80.894881313346801, 34.607901892906114],
              [-80.895532495432562, 34.607986805013311],
              [-80.895480306637126, 34.608251666130876],
              [-80.895154713312024, 34.608209210394016],
              [-80.895050331593794, 34.608738933202261],
              [-80.894724737648403, 34.608696476281558],
              [-80.894568157807313, 34.609491058587636],
              [-80.894242560037455, 34.609448600375686],
              [-80.894138169744963, 34.609978321526306],
              [-80.894463769541431, 34.610020779120674],
              [-80.893994005618211, 34.612404518245178],
              [-80.894319614986031, 34.612446977134766],
              [-80.894267418329349, 34.612711836875846],
              [-80.894593029037026, 34.612754294123086],
              [-80.894540833067154, 34.613019153985377],
              [-80.894866446225706, 34.613061610474354],
              [-80.894762055302124, 34.61359132951921],
              [-80.895087669775776, 34.613633786327512],
              [-80.89493108392476, 34.614428364714591],
              [-80.895256702876139, 34.614470820146941],
              [-80.895100117043825, 34.615265397871944],
              [-80.895425739424411, 34.615307853748249],
              [-80.895321349212011, 34.615837571510333],
              [-80.895646973977207, 34.615880026787345],
              [-80.895490390283285, 34.616674603232724],
              [-80.895816018457012, 34.616717058052295],
              [-80.895711628950366, 34.61724677527306],
              [-80.89603725948794, 34.617289228592028],
              [-80.895985065273962, 34.617554088214483],
              [-80.896310698262681, 34.617596540775054],
              [-80.896258504714893, 34.617861399617247],
              [-80.896584139064331, 34.617903851436651],
              [-80.896531946203581, 34.618168710399843],
              [-80.896857580823564, 34.618211161495367],
              [-80.896805389740138, 34.618476020562404],
              [-80.897131025721009, 34.618518470916662],
              [-80.897078835324649, 34.61878333010484],
              [-80.897404472666253, 34.618825779717874],
              [-80.89735228293614, 34.619090638125677],
              [-80.897677921638575, 34.61913308699755],
              [-80.897625732616461, 34.619397946427782],
              [-80.898602657087693, 34.619525288118872],
              [-80.898654843037903, 34.619260428263232],
              [-80.89898048417804, 34.619302873585674],
              [-80.899032668767518, 34.619038013567312],
              [-80.899683950010385, 34.619122901279518],
              [-80.899579583479465, 34.619652620978492],
              [-80.899905226654113, 34.619695063793593],
              [-80.899800861914045, 34.62022478367583],
              [-80.90012650745274, 34.620267224990087],
              [-80.900022143413381, 34.620796945072684],
              [-80.900347791336813, 34.620839385787569],
              [-80.900295609825577, 34.621104245488766],
              [-80.900621259130645, 34.621146686363701],
              [-80.900569078306617, 34.621411546185811],
              [-80.900894728972645, 34.621453986319473],
              [-80.900842548835726, 34.621718846262624],
              [-80.901168201932052, 34.621761284736401],
              [-80.901116022482455, 34.622026144800508],
              [-80.901441675849327, 34.62206858255027],
              [-80.90133731798764, 34.622598302899412],
              [-80.901662974829762, 34.622640740032402],
              [-80.901558617648178, 34.623170459680352],
              [-80.901884276875293, 34.623212896213929],
              [-80.90183209881387, 34.623477756598987],
              [-80.902157759401874, 34.623520192391148],
              [-80.90210558200674, 34.623785051995881],
              [-80.902431243976565, 34.623827487948041],
              [-80.90237906726874, 34.624092347673688],
              [-80.903030394225226, 34.624177215409539],
              [-80.902978219228928, 34.624442075397845],
              [-80.903303885347654, 34.624484508966916],
              [-80.903251711038678, 34.624749369076035],
              [-80.903199536371929, 34.625014228262984],
              [-80.903525203785463, 34.625056661249751],
              [-80.903420856601215, 34.625586380728507],
              [-80.903746526378953, 34.625628812214373],
              [-80.90317260122994, 34.628542265396767],
              [-80.903498283701111, 34.62858469754164],
              [-80.903446107391602, 34.628849556860622],
              [-80.903771791223733, 34.628891988264066],
              [-80.903406555760981, 34.630745998475817],
              [-80.903732248190124, 34.630788429971133],
              [-80.903575716229241, 34.631583004605908],
              [-80.903901412068222, 34.631625435643365],
              [-80.903797057785113, 34.632155151944353],
              [-80.904122756009571, 34.632197582382034],
              [-80.904018402406848, 34.632727297981049],
              [-80.904344104107253, 34.632769727801637],
              [-80.904291927824943, 34.633034585711293],
              [-80.904617629796078, 34.633077014807775],
              [-80.904565454201261, 34.633341872838102],
              [-80.904891157533612, 34.633384301193061],
              [-80.904838983716871, 34.633649159326758],
              [-80.905490393419839, 34.633734012769125],
              [-80.905438221314952, 34.63399887116524],
              [-80.905763927685555, 34.634041296252619],
              [-80.905398716266546, 34.635895302791418],
              [-80.905724430144829, 34.635937727987709],
              [-80.905672257394158, 34.636202586498264],
              [-80.905346541380013, 34.636160160276198],
              [-80.905242190596496, 34.636689875182498],
              [-80.905567908659521, 34.636732301688028],
              [-80.905359208217703, 34.637791730894925],
              [-80.905684931784904, 34.637834156165461],
              [-80.905528406236485, 34.638628727142482],
              [-80.905854132144498, 34.638671152873563],
              [-80.905749782493189, 34.639200866429029],
              [-80.906075510766129, 34.639243290658982],
              [-80.906023335914483, 34.639508147555368],
              [-80.906349066660269, 34.639550571927586],
              [-80.90629689358677, 34.63981542892715],
              [-80.90662262458244, 34.639857851673831],
              [-80.906570452196618, 34.640122708793911],
              [-80.907221918338109, 34.640207551903401],
              [-80.90716974766454, 34.640472409285664],
              [-80.907495482810376, 34.640514829648481],
              [-80.907443311733957, 34.640779687168546],
              [-80.907769048241278, 34.640822106789706],
              [-80.907716878922201, 34.641086963511626],
              [-80.90735167993715, 34.642940961841575],
              [-80.907677426068517, 34.64298338169575],
              [-80.907625253988755, 34.643248238406478],
              [-80.907299507923355, 34.643205818393291],
              [-80.907195161773743, 34.643735530549002],
              [-80.906869412905365, 34.643693109386341],
              [-80.90681723776575, 34.643957965749763],
              [-80.906491489300208, 34.643915543544701],
              [-80.90638713594025, 34.644445255023037],
              [-80.906061384671688, 34.644402831668422],
              [-80.905643950565519, 34.646521671972032],
              [-80.905318191436635, 34.646479246600208],
              [-80.905109460520933, 34.647538663895823],
              [-80.904783697630108, 34.647496237073739],
              [-80.904418398057246, 34.649350213276222],
              [-80.904744169211867, 34.649392641073071],
              [-80.904431048976775, 34.650981759156792],
              [-80.904756826617003, 34.651024186920552],
              [-80.904600264458352, 34.651818745205226],
              [-80.904926045509967, 34.651861172510586],
              [-80.904717295689323, 34.652920582915733],
              [-80.905043082267866, 34.652963009887358],
              [-80.904573383763562, 34.655346679047348],
              [-80.904247586870326, 34.655304250817565],
              [-80.904195395796691, 34.655569102490972],
              [-80.904521192603056, 34.655611529978444],
              [-80.904155848378139, 34.657465488543863],
              [-80.903830044376292, 34.657423059163108],
              [-80.903777849494688, 34.657687909779789],
              [-80.903452043735072, 34.65764548029231],
              [-80.903347652001486, 34.658175181160097],
              [-80.903021844508018, 34.65813274960437],
              [-80.902917449376261, 34.658662450102128],
              [-80.902591640170115, 34.658620017379391],
              [-80.90243504021231, 34.659414566663258],
              [-80.902760852472483, 34.659456998909846],
              [-80.902656452858309, 34.659986698307115],
              [-80.90298226750555, 34.660029129953585],
              [-80.902930068228287, 34.660293980212167],
              [-80.903255885328548, 34.660336411099514],
              [-80.903099288486345, 34.661130959466334],
              [-80.903047088864739, 34.661395808878581],
              [-80.902721268734297, 34.661353376505701],
              [-80.90261686642981, 34.661883074981588],
              [-80.902291043495552, 34.661840641458959],
              [-80.901560172780734, 34.665548513788224],
              [-80.901886010066136, 34.665590949295122],
              [-80.9016771812949, 34.666650338475804],
              [-80.901025497790044, 34.666565466363316],
              [-80.900973286880557, 34.666830313108136],
              [-80.900647445164608, 34.66678787602671],
              [-80.900543021353883, 34.667317568248791],
              [-80.900217176833877, 34.66727513001748],
              [-80.90016496286249, 34.667539976413373],
              [-80.899839117633732, 34.667497536255247],
              [-80.89978690339089, 34.667762382470301],
              [-80.899630256436495, 34.668556920117567],
              [-80.899304407444163, 34.668514478508889],
              [-80.898991096034223, 34.670103549693003],
              [-80.898665241248764, 34.670061107251421],
              [-80.898456356073936, 34.671120484632517],
              [-80.898130497524818, 34.671078040740305],
              [-80.898078273261248, 34.67134288445564],
              [-80.897752414024268, 34.671300439537973],
              [-80.897647963546675, 34.671830127503341],
              [-80.897322102575444, 34.671787680517134],
              [-80.897269875270425, 34.672052524784483],
              [-80.896944013611432, 34.672010076772814],
              [-80.896891784922914, 34.672274919975038],
              [-80.896565922596722, 34.67223247183923],
              [-80.896513692545611, 34.672497314877816],
              [-80.896187828440517, 34.672454865733755],
              [-80.896135598117894, 34.672719708591416],
              [-80.89580973330402, 34.672677257520512],
              [-80.895757500527793, 34.672942100231651],
              [-80.895431635046833, 34.672899649036616],
              [-80.895327167501861, 34.673429333190796],
              [-80.895653035054124, 34.67347178557079],
              [-80.895548568210302, 34.674001469920626],
              [-80.895874438129738, 34.674043920799001],
              [-80.895665504495014, 34.675103289801896],
              [-80.895339629362482, 34.67506083747238],
              [-80.894660539311445, 34.678503773067945],
              [-80.89498642884395, 34.678546226322041],
              [-80.894829710186968, 34.679340747952644],
              [-80.895155602042038, 34.67938320076545],
              [-80.89484216279908, 34.680972241559033],
              [-80.895168061144219, 34.681014694338621],
              [-80.894750129923068, 34.683133411708262],
              [-80.895076038991817, 34.683175864703728],
              [-80.894658093141302, 34.685294575495277],
              [-80.894984010751855, 34.685337028740996],
              [-80.89493176716131, 34.68560186751688],
              [-80.895257685043731, 34.685644320037717],
              [-80.894944222504833, 34.687233350372374],
              [-80.895270147969413, 34.687275802842628],
              [-80.895061169163711, 34.688335154735192],
              [-80.894735239617262, 34.688292702599234],
              [-80.894682993298488, 34.688557539469173],
              [-80.894357061972769, 34.688515086324742],
              [-80.894304814290834, 34.688779923030673],
              [-80.893978883347373, 34.688737467942076],
              [-80.893874383870042, 34.68926714282334],
              [-80.893548450121571, 34.689224686584467],
              [-80.893443947221996, 34.689754360191607],
              [-80.893118012850948, 34.689711902768067],
              [-80.892961252337116, 34.690506412143847],
              [-80.892635314135347, 34.690463953428193],
              [-80.892530803736847, 34.690993625343445],
              [-80.892856743989995, 34.691036084342585],
              [-80.89280448876444, 34.691300920417063],
              [-80.892699978391988, 34.691830592481672],
              [-80.893025922038902, 34.691873050120826],
              [-80.892921412367642, 34.692402722379455],
              [-80.893247358424475, 34.692445180319538],
              [-80.893195103541743, 34.692710015664296],
              [-80.893521050961866, 34.692752472862175],
              [-80.893468797858688, 34.69301730830918],
              [-80.893794746642101, 34.693059764764861],
              [-80.893742494227027, 34.693324600331131],
              [-80.89439439594922, 34.693409510856938],
              [-80.894342144156923, 34.693674346701435],
              [-80.895645956804572, 34.693844155892585],
              [-80.895593709869047, 34.694108992264461],
              [-80.896245619723985, 34.694193891848229],
              [-80.896193374502545, 34.69445872848123],
              [-80.8968452888504, 34.694543624795216],
              [-80.896793044251794, 34.694808461706479],
              [-80.897444962001273, 34.69489335476792],
              [-80.897392720187227, 34.695158191021648],
              [-80.89771868060447, 34.695200636818832],
              [-80.897666440570049, 34.695465473174536],
              [-80.897992402350624, 34.695507918229453],
              [-80.897940161913368, 34.695772754721631],
              [-80.898266125036301, 34.695815198132848],
              [-80.89821388530828, 34.696080035645714],
              [-80.898539849794645, 34.696122478314685],
              [-80.898121930064121, 34.698241172398646],
              [-80.897795956279353, 34.698198728613256],
              [-80.897743713492929, 34.698463565022472],
              [-80.897417738998982, 34.69842111930992],
              [-80.897156514833, 34.699745298506969],
              [-80.896830535567716, 34.699702852103179],
              [-80.896778288683535, 34.699967687192462],
              [-80.897104268974658, 34.700010133737962],
              [-80.896999775154114, 34.70053980414955],
              [-80.897325758926129, 34.700582250077218],
              [-80.897012277681128, 34.70217126068497],
              [-80.896686287753184, 34.702128813907002],
              [-80.896425037630124, 34.703452985249498],
              [-80.896099044001105, 34.703410536861533],
              [-80.895942285912597, 34.704205038443483],
              [-80.895890033268898, 34.70446987224728],
              [-80.895837779175253, 34.704734705144396],
              [-80.896163779025898, 34.704777154223748],
              [-80.896111526733065, 34.705041988124059],
              [-80.896763529499154, 34.705126883913678],
              [-80.896711278900028, 34.705391717173406],
              [-80.897363286160044, 34.705476609692823],
              [-80.897311036183964, 34.705741443230529],
              [-80.897963046846556, 34.705826332496727],
              [-80.897910799676538, 34.706091166277957],
              [-80.898236807085993, 34.706133609717916],
              [-80.898184559513084, 34.706398443635429],
              [-80.898510568286227, 34.706440886333027],
              [-80.898458322493383, 34.706705720352311],
              [-80.898784332630129, 34.70674816230737],
              [-80.898732087525843, 34.707012996445606],
              [-80.899058097913951, 34.707055436774169],
              [-80.898901363654119, 34.707849939477967],
              [-80.899227378549639, 34.70789237933014],
              [-80.899175134147441, 34.708157213661018],
              [-80.899501150427582, 34.70819965367204],
              [-80.899292173485037, 34.709258988631085],
              [-80.899618195299013, 34.70930142830742],
              [-80.899461462110594, 34.710095929037003],
              [-80.899787486249281, 34.710138368271508],
              [-80.899735242204201, 34.710403201944217],
              [-80.90006126877725, 34.710445639517566],
              [-80.899956781726971, 34.710975307078058],
              [-80.900282809598451, 34.711017744067775],
              [-80.900126080640675, 34.711812244744124],
              [-80.900452113020094, 34.711854681257464],
              [-80.900347626678197, 34.712384348255483],
              [-80.900673661447712, 34.712426784167867],
              [-80.900621418786343, 34.712691617323692],
              [-80.900947454919688, 34.712734052493516],
              [-80.900738486094994, 34.713793385438024],
              [-80.901064526692025, 34.713835821191616],
              [-80.900907798768458, 34.714630320196854],
              [-80.901233843852694, 34.714672754572653],
              [-80.901077115949107, 34.715467252895841],
              [-80.901403164449945, 34.715509686812354],
              [-80.901298679552667, 34.716039352830634],
              [-80.901624730443814, 34.716081786146127],
              [-80.901572488504826, 34.716346618811912],
              [-80.901898540759831, 34.716389051384844],
              [-80.901846300601235, 34.716653884152159],
              [-80.902172354220198, 34.716696315982283],
              [-80.902120113658853, 34.716961148885616],
              [-80.902446168620955, 34.717003579071914],
              [-80.902393928748353, 34.717268412094008],
              [-80.902719986186838, 34.717310842421632],
              [-80.90261550746105, 34.717840507779066],
              [-80.902289449082161, 34.717798078052219],
              [-80.902132726463392, 34.718592575023614],
              [-80.902458787942535, 34.718635006076752],
              [-80.90240654705002, 34.718899838201736],
              [-80.90224982343878, 34.719694334421519],
              [-80.902575890452937, 34.719736765139594],
              [-80.90241916688295, 34.720531261577733],
              [-80.902745236201511, 34.720573690952357],
              [-80.902536270436471, 34.72163301888591],
              [-80.90286234638198, 34.721675447908197],
              [-80.902810104855561, 34.721940280205374],
              [-80.903462257649977, 34.722025136816029],
              [-80.903410018909824, 34.722289968454852],
              [-80.903736096850366, 34.722332396025955],
              [-80.903683857707449, 34.722597227800755],
              [-80.904336017725242, 34.72268207965444],
              [-80.904283781389623, 34.722946911672118],
              [-80.90460986237504, 34.722989335972002],
              [-80.904557626728419, 34.723254168108333],
              [-80.904883710190575, 34.723296592549403],
              [-80.904779238825114, 34.723826256152165],
              [-80.905105323565479, 34.723868679108108],
              [-80.904948617621599, 34.724663175206203],
              [-80.905274706891959, 34.724705598586617],
              [-80.905170236236231, 34.725235261623951],
              [-80.90549632789741, 34.725277684403281],
              [-80.905182915080289, 34.726866673793822],
              [-80.90550901321798, 34.726909095636969],
              [-80.90535230531124, 34.727703589543992],
              [-80.905300067925438, 34.727968420210729],
              [-80.905626171599152, 34.72801084171855],
              [-80.905364988035714, 34.72933499626275],
              [-80.905038879228414, 34.729292574046909],
              [-80.904620957717128, 34.731411215766698],
              [-80.904947074738459, 34.731453639115358],
              [-80.904790351497681, 34.73224812777665],
              [-80.904464231396091, 34.732205704003206],
              [-80.904255256785191, 34.733265021349723],
              [-80.90392913291403, 34.733222596125323],
              [-80.903667901877228, 34.734546739774849],
              [-80.903994031974364, 34.734589165689982],
              [-80.903889539224622, 34.735118822188511],
              [-80.90421567171316, 34.73516124750234],
              [-80.904111180758207, 34.735690904173651],
              [-80.904437315638148, 34.735733328886212],
              [-80.904385070135177, 34.735998157337185],
              [-80.904711207471507, 34.736040581289416],
              [-80.904606717485407, 34.736570237503379],
              [-80.904932856121377, 34.736612660871607],
              [-80.904880612751029, 34.736877489518548],
              [-80.905532894160743, 34.736962332965845],
              [-80.905480651414649, 34.737227161889933],
              [-80.905806794188408, 34.737269581969095],
              [-80.905754552131597, 34.737534411011474],
              [-80.906080696269939, 34.737576830347571],
              [-80.90602845599426, 34.737841659490883],
              [-80.906354601497185, 34.737884078084001],
              [-80.90630236081897, 34.738148907362877],
              [-80.906628507686492, 34.738191325213009],
              [-80.906524028442007, 34.738720983065306],
              [-80.906850177701102, 34.738763400313985],
              [-80.906745699138924, 34.739293057454717],
              [-80.907071850789634, 34.739335474101956],
              [-80.907019612575013, 34.739600302770128],
              [-80.907345765611339, 34.739642719575713],
              [-80.907293528086072, 34.739907548362098],
              [-80.907619682466049, 34.73994996352328],
              [-80.90756744563015, 34.740214792427956],
              [-80.907893601374781, 34.740257206846124],
              [-80.908219758548825, 34.740299620362329],
              [-80.908167523429441, 34.740564449526637],
              [-80.908819839752653, 34.740649274205794],
              [-80.908872072839074, 34.740384445659643],
              [-80.909198231025982, 34.740426856522049],
              [-80.90925046274775, 34.74016202781101],
              [-80.909576619153427, 34.74020443766458],
              [-80.909628849489479, 34.739939607887287],
              [-80.910933477676465, 34.74010923873869],
              [-80.91088125142744, 34.740374068180785],
              [-80.911533569064957, 34.740458879041256],
              [-80.911481345624438, 34.740723708725703],
              [-80.911807505420072, 34.740766112528476],
              [-80.911755282690095, 34.741030943232417],
              [-80.91208144385034, 34.741073346292119],
              [-80.912029221788771, 34.741338176212928],
              [-80.912355385405633, 34.741380578512157],
              [-80.912303162941768, 34.741645408568374],
              [-80.912629327923156, 34.74168781012456],
              [-80.912524885128803, 34.742217470432465],
              [-80.912851052501921, 34.742259871386985],
              [-80.912798832171532, 34.742524701638665],
              [-80.913125000909304, 34.742567101850028],
              [-80.913072780176577, 34.742831932237173],
              [-80.913398951371022, 34.742874331688014],
              [-80.913346731327778, 34.743139162193337],
              [-80.913672903865844, 34.743181559999691],
              [-80.913620684512196, 34.743446390623021],
              [-80.914273031605248, 34.743531185684823],
              [-80.914220815039343, 34.743796015649075],
              [-80.914546989573282, 34.743838412003164],
              [-80.914494773697001, 34.74410324208538],
              [-80.91482095068757, 34.744145637678926],
              [-80.914768735522003, 34.7444104687806],
              [-80.915094912764246, 34.744452862746996],
              [-80.915042698267257, 34.744717693065333],
              [-80.915368877987191, 34.744760087172487],
              [-80.915316664179898, 34.745024917608816],
              [-80.915642845264529, 34.74506731097285],
              [-80.915590631054968, 34.745332141544623],
              [-80.915916813483236, 34.745374533264034],
              [-80.915864601076294, 34.745639364837757],
              [-80.916190784869357, 34.74568175581404],
              [-80.916138573131065, 34.745946586604433],
              [-80.916464758288839, 34.745988976837403],
              [-80.916412547240355, 34.746253807745788],
              [-80.916738734875793, 34.746296198119452],
              [-80.916686524516976, 34.746561029145838],
              [-80.917012712404187, 34.746603417892423],
              [-80.916960502735108, 34.746868249036773],
              [-80.917286691987044, 34.746910637040024],
              [-80.917234482986643, 34.747175467401028],
              [-80.917560674716384, 34.747217855544982],
              [-80.917508466405764, 34.747482686023936],
              [-80.917834659500258, 34.747525073424654],
              [-80.917782451879503, 34.747789904021566],
              [-80.918108645225573, 34.747832289795063],
              [-80.918056439407621, 34.748097121393833],
              [-80.918382634118544, 34.748139506424103],
              [-80.918330428969398, 34.748404337239499],
              [-80.918656625044974, 34.74844672152642],
              [-80.918604420585595, 34.748711552459717],
              [-80.918930618026025, 34.748753936003432],
              [-80.918878414256554, 34.74901876705465],
              [-80.919204614153671, 34.749061149837559],
              [-80.919152409981976, 34.7493259810241],
              [-80.919804812843395, 34.749410744218629],
              [-80.919752611480902, 34.749675575647096],
              [-80.92105742536225, 34.749845091984703],
              [-80.920953028730665, 34.750374755018576],
              [-80.921279236191268, 34.750417132156436],
              [-80.9212270378613, 34.750681964238858],
              [-80.921879454736469, 34.750766715259317],
              [-80.921827259215775, 34.751031547583551],
              [-80.922153468630299, 34.751073921466123],
              [-80.922101273799584, 34.751338753908207],
              [-80.922427485692111, 34.751381127931189],
              [-80.922375291530216, 34.751645959589794],
              [-80.922701503695507, 34.751688332886872],
              [-80.922649310223647, 34.751953164663369],
              [-80.922975524824622, 34.751995536298281],
              [-80.922871138944743, 34.752525200964484],
              [-80.923197355937901, 34.752567571997481],
              [-80.92314516350865, 34.752832403985842],
              [-80.923471381887893, 34.752874775176778],
              [-80.923314805637006, 34.753669270523368],
              [-80.92364102741486, 34.753711640352279],
              [-80.923588835690907, 34.753976472529089],
              [-80.923915058854774, 34.75401884251589],
              [-80.923862867799741, 34.754283673909086],
              [-80.923536643629561, 34.754241304682218],
              [-80.923432258452408, 34.754770967115029],
              [-80.923758485790799, 34.754813337508544],
              [-80.923497522080652, 34.756137493883429],
              [-80.92317128962587, 34.756095123684233],
              [-80.923119094844125, 34.756359954746785],
              [-80.923445328326338, 34.7564023250874],
              [-80.923340939805499, 34.756931987424224],
              [-80.923667175680208, 34.756974357162825],
              [-80.923510593429768, 34.757768850013292],
              [-80.923836832724575, 34.757811219291135],
              [-80.923784638979711, 34.758076049734349],
              [-80.924110879639514, 34.758118418268708],
              [-80.924058686605889, 34.758383249730919],
              [-80.924384928630786, 34.75842561752188],
              [-80.924332736266237, 34.758690448200419],
              [-80.924658979656058, 34.758732815247782],
              [-80.924606787981588, 34.75899764604398],
              [-80.924933033828637, 34.759040012330445],
              [-80.924828651522745, 34.759569674134383],
              [-80.925154898670215, 34.759612039836206],
              [-80.925102709130968, 34.759876870826432],
              [-80.925428957643462, 34.759919235784629],
              [-80.925376767702275, 34.760184066909986],
              [-80.925703018671996, 34.760226431107242],
              [-80.925598640903516, 34.760756092650595],
              [-80.925924893173772, 34.760798456263082],
              [-80.925872705367794, 34.761063287582395],
              [-80.926198958981928, 34.761105649550025],
              [-80.926042394395751, 34.761900142021751],
              [-80.926368652543673, 34.761942504412438],
              [-80.926264276871123, 34.762472165617254],
              [-80.925938016667715, 34.762429802943899],
              [-80.925885826751568, 34.762694633377976],
              [-80.925559565879084, 34.762652270579636],
              [-80.925455182957819, 34.76318193019226],
              [-80.925128920346253, 34.76313956532492],
              [-80.92507672736231, 34.763404395404869],
              [-80.924750464060395, 34.763362029511121],
              [-80.924646075003622, 34.763891688415377],
              [-80.924972340360966, 34.763934054591786],
              [-80.92492014636467, 34.764198884600155],
              [-80.925246414179412, 34.764241250015509],
              [-80.925194220852205, 34.764506079240142],
              [-80.925846758457226, 34.764590807716182],
              [-80.925794566848069, 34.764855637199616],
              [-80.926447108929366, 34.76494036149974],
              [-80.926394919038287, 34.765205191242039],
              [-80.9267211916235, 34.765247552656682],
              [-80.926616812884689, 34.76577721235239],
              [-80.926290538265192, 34.765734851556445],
              [-80.926133965613815, 34.766529339577978],
              [-80.92580768822711, 34.766486976571613],
              [-80.925651108363027, 34.767281463971607],
              [-80.925977389925379, 34.76732382738458],
              [-80.925925196976081, 34.767588656010624],
              [-80.925598914407189, 34.76754629335754],
              [-80.925494525440541, 34.76807595025506],
              [-80.925820810086392, 34.768118314092106],
              [-80.925716421825612, 34.768647971176605],
              [-80.925390135145278, 34.768605607958101],
              [-80.925337939480784, 34.768870436323034],
              [-80.925011653181244, 34.768828071159398],
              [-80.924750663543804, 34.770152211034258],
              [-80.924098079515133, 34.770067477574457],
              [-80.924045878720946, 34.770332304470195],
              [-80.923393293986535, 34.770247567187567],
              [-80.92334109079907, 34.770512393776585],
              [-80.922688505359147, 34.770427652671074],
              [-80.92263629977829, 34.770692478953301],
              [-80.922310006526175, 34.77065010648095],
              [-80.922257798508838, 34.770914933516607],
              [-80.921931504566487, 34.770872560017814],
              [-80.921879295162455, 34.771137385986741],
              [-80.921553000529713, 34.771095011461526],
              [-80.921396368322078, 34.771889489684035],
              [-80.921070070943244, 34.771847113849603],
              [-80.920965644985387, 34.77237676500571],
              [-80.920639345888361, 34.772334388003443],
              [-80.920169401916453, 34.774717811253865],
              [-80.920064965340302, 34.775247459812562],
              [-80.920391276836909, 34.775289838352798],
              [-80.920339059616552, 34.775554662728766],
              [-80.920012747091874, 34.775512284047124],
              [-80.919856090299646, 34.776306755704539],
              [-80.920182405908278, 34.776349134810445],
              [-80.919764644481504, 34.778467723014202],
              [-80.920090969744422, 34.778510102348733],
              [-80.920038749478906, 34.778774925675002],
              [-80.920365074994024, 34.778817303381857],
              [-80.920312855418914, 34.779082126825386],
              [-80.9206391834133, 34.779124504672396],
              [-80.920534744192636, 34.779654150885669],
              [-80.92086107458087, 34.779696528130394],
              [-80.920808856027747, 34.779961350882743],
              [-80.921135187781658, 34.780003727383622],
              [-80.921082969919055, 34.780268550253155],
              [-80.921409303017668, 34.780310925108957],
              [-80.921304868356657, 34.780840571959558],
              [-80.921631203870305, 34.780882947114257],
              [-80.921474551306559, 34.781677416298109],
              [-80.921800891313495, 34.781719790073083],
              [-80.921696457367261, 34.782249436345595],
              [-80.921370115304029, 34.782207062287853],
              [-80.92105679803602, 34.783795996988395],
              [-80.921383146268411, 34.783838371894291],
              [-80.921330925670162, 34.78410319486607],
              [-80.921657275268345, 34.784145569028176],
              [-80.921605056432028, 34.784410391198172],
              [-80.921931407396116, 34.784452764616503],
              [-80.921826970766716, 34.784982409166417],
              [-80.92215332414618, 34.785024782883546],
              [-80.921996669732934, 34.785819249048835],
              [-80.92167031219752, 34.785776875826322],
              [-80.921409208513879, 34.787100984625404],
              [-80.921735570098207, 34.787143358572152],
              [-80.921474463049691, 34.788467464765247],
              [-80.921148095231388, 34.788425090129103],
              [-80.921095871998773, 34.788689911149703],
              [-80.920443135318834, 34.788605158938871],
              [-80.920338682613334, 34.789134799457507],
              [-80.920012312723316, 34.789092421741472],
              [-80.919960084299959, 34.789357242282257],
              [-80.919633713719406, 34.789314863539559],
              [-80.919581483908814, 34.789579683013166],
              [-80.919163635490662, 34.79169823969066],
              [-80.91949001532636, 34.791740619705813],
              [-80.919385552436253, 34.792270258454124],
              [-80.919711935759096, 34.792312637849321],
              [-80.919607472460868, 34.792842275898579],
              [-80.919933858178311, 34.792884654691214],
              [-80.919881627040425, 34.793149473369816],
              [-80.920208014123986, 34.793191851418584],
              [-80.920155783697766, 34.793456671115386],
              [-80.920482172147643, 34.793499048420244],
              [-80.920325482956756, 34.794293505066364],
              [-80.920221022171305, 34.794823142702114],
              [-80.91989462750783, 34.794780765609254],
              [-80.919737930143441, 34.79557522056222],
              [-80.920064326820821, 34.795617598998035],
              [-80.920012094361638, 34.795882417714225],
              [-80.920338493476905, 34.79592479448722],
              [-80.920286261708398, 34.796189613320223],
              [-80.920939063051563, 34.796274366295648],
              [-80.920886832981083, 34.796539184485574],
              [-80.922518846258015, 34.796751050351766],
              [-80.922466621013584, 34.797015870125172],
              [-80.922793025916732, 34.797058240239359],
              [-80.922740802455593, 34.797323060112177],
              [-80.923067208724959, 34.797365429482326],
              [-80.92301498484089, 34.797630248588042],
              [-80.923341393590377, 34.797672618098012],
              [-80.92318472518231, 34.798467075656575],
              [-80.92351113624143, 34.798509443821352],
              [-80.923406690640149, 34.799039082359279],
              [-80.923733105186969, 34.799081449903838],
              [-80.923680882361879, 34.799346269286005],
              [-80.924007298296246, 34.799388636987814],
              [-80.923902854761536, 34.79991827504211],
              [-80.924229273069628, 34.799960641239863],
              [-80.924177052359937, 34.800225459912042],
              [-80.924503470963003, 34.800267826284482],
              [-80.924451250944173, 34.800532645073297],
              [-80.924777671985026, 34.800575009782833],
              [-80.924725452657171, 34.800839828688403],
              [-80.9250518750856, 34.800882193555196],
              [-80.924999656448634, 34.801147012577424],
              [-80.925652504333769, 34.801231738134675],
              [-80.925600287416273, 34.801496557414914],
              [-80.927232418204014, 34.801708357420907],
              [-80.927180206092132, 34.801973177382912],
              [-80.927506634287354, 34.802015534688884],
              [-80.927454423937945, 34.802280353848722],
              [-80.927780853499556, 34.802322710410458],
              [-80.927728642769651, 34.802587530605813],
              [-80.928055074790279, 34.802629886405867],
              [-80.928002864751477, 34.80289470671778],
              [-80.928329297045821, 34.802937061791262],
              [-80.92827708876942, 34.803201881300936],
              [-80.928929957521461, 34.803286589056398],
              [-80.9288777498722, 34.80355140884155],
              [-80.929530623125117, 34.803636113320252],
              [-80.929478417195639, 34.803900933363209],
              [-80.930131293856761, 34.803985634582546],
              [-80.930079089647123, 34.804250454883352],
              [-80.931711291217852, 34.804462191356791],
              [-80.931659093999798, 34.804727012304227],
              [-80.931985535490014, 34.804769357458227],
              [-80.931881141005832, 34.805298999578902],
              [-80.932207585962772, 34.805341343211055],
              [-80.932050993151606, 34.806135806656684],
              [-80.932377441532623, 34.80617814982714],
              [-80.932220848747875, 34.806972612572586],
              [-80.932547301645741, 34.80701495526371],
              [-80.932495104411544, 34.807279776571001],
              [-80.932821558675911, 34.807322118517781],
              [-80.932769362111557, 34.80758693904032],
              [-80.933095816649626, 34.807629280260336],
              [-80.932991425679901, 34.808158922397297],
              [-80.933317883706252, 34.808201262996683],
              [-80.933265688186751, 34.808466083727325],
              [-80.933592147579603, 34.808508423582488],
              [-80.933487757585468, 34.809038065251961],
              [-80.933814219373772, 34.809080404503916],
              [-80.933709831179712, 34.809610046339102],
              [-80.934036295363484, 34.809652384988013],
              [-80.933931906762638, 34.810182026122661],
              [-80.933879713059326, 34.810446847085821],
              [-80.93420618066753, 34.810489185272814],
              [-80.934049598390203, 34.811283646669644],
              [-80.934376069422825, 34.811325984394728],
              [-80.934323876405486, 34.811590804639749],
              [-80.934650348804823, 34.811633141620476],
              [-80.934545962722481, 34.812162782335911],
              [-80.934872438610157, 34.812205118695879],
              [-80.934768052142417, 34.812734759611928],
              [-80.935094530404413, 34.8127770944674],
              [-80.935042338785905, 34.81304191501178],
              [-80.935695297330724, 34.813126584168621],
              [-80.935643107411309, 34.813391404069137],
              [-80.936296070457544, 34.813476069948358],
              [-80.936243882279896, 34.813740891007726],
              [-80.936896848713346, 34.813825552725518],
              [-80.936844662234961, 34.814090373141063],
              [-80.936635910780666, 34.815149655490941],
              [-80.936309422260535, 34.815107324819579],
              [-80.936152851453812, 34.815901784328268],
              [-80.936479344175424, 34.815944116307001],
              [-80.936374964386189, 34.816473756732165],
              [-80.936701459482336, 34.81651608720631],
              [-80.936649270656787, 34.816780907513873],
              [-80.936975766026862, 34.816823237261062],
              [-80.936923577892827, 34.817088057684963],
              [-80.937250075722545, 34.81713038667008],
              [-80.937197888280025, 34.817395207210154],
              [-80.937850887031857, 34.817479863707113],
              [-80.937798700195756, 34.817744683620859],
              [-80.93812520111797, 34.817787011132687],
              [-80.938073016066326, 34.818051831144992],
              [-80.938399518333867, 34.818094157011018],
              [-80.938347333973866, 34.818358977139539],
              [-80.938673837629537, 34.818401303162375],
              [-80.938569469954984, 34.818930943626796],
              [-80.938895977078218, 34.818973268127365],
              [-80.938843793763681, 34.819238088463244],
              [-80.939170301160843, 34.819280412236886],
              [-80.93911811853792, 34.819545232688967],
              [-80.939771137517667, 34.819629878745083],
              [-80.93971895659422, 34.819894698553149],
              [-80.940045468176891, 34.819937020835603],
              [-80.939888926490127, 34.820731481434649],
              [-80.940215441476639, 34.820773802353528],
              [-80.940163261282663, 34.821038623245251],
              [-80.940489777636103, 34.821080943419567],
              [-80.940437598112425, 34.821345763525969],
              [-80.940764115832636, 34.821388082955792],
              [-80.940711937000728, 34.821652903178318],
              [-80.942018016467429, 34.821822172587012],
              [-80.941913666046446, 34.822351814987449],
              [-80.9415871438881, 34.822309498677164],
              [-80.941534967131204, 34.822574319248041],
              [-80.940881921768565, 34.822489683688055],
              [-80.940829742615605, 34.822754503951487],
              [-80.940503219411312, 34.822712184701764],
              [-80.940451038869995, 34.822977003897776],
              [-80.939797990322816, 34.822892362476288],
              [-80.939745807406652, 34.823157182266222],
              [-80.939419283156681, 34.823114860076863],
              [-80.939367098873603, 34.823379679700601],
              [-80.939040572838948, 34.823337356501931],
              [-80.93883182707755, 34.824396632396152],
              [-80.938505298334846, 34.824354306828162],
              [-80.938244352537481, 34.825678399086286],
              [-80.938570887498571, 34.825720724441155],
              [-80.938466508647466, 34.826250361093699],
              [-80.938793046004946, 34.826292685845111],
              [-80.938740857648781, 34.826557504266013],
              [-80.93906739637319, 34.826599828272698],
              [-80.939015208687508, 34.826864645908366],
              [-80.939341748778972, 34.826906969170402],
              [-80.939237373381516, 34.82743660556747],
              [-80.938910831231254, 34.827394282023036],
              [-80.938858642553924, 34.827659100484532],
              [-80.938205556114454, 34.827574450473634],
              [-80.93815336501919, 34.827839267726254],
              [-80.937500277871777, 34.82775461388988],
              [-80.937448083286952, 34.828019430852535],
              [-80.937121539736907, 34.827977102455712],
              [-80.937069343785069, 34.828241919252108],
              [-80.936416254546188, 34.828157259536148],
              [-80.93636405729076, 34.828422076007413],
              [-80.936037511602024, 34.828379744688313],
              [-80.935985311886412, 34.828644561010783],
              [-80.935658766599047, 34.828602228647092],
              [-80.935502162337556, 34.829396677039632],
              [-80.935175613206638, 34.82935434338426],
              [-80.934810183056911, 34.8312080508408],
              [-80.935136739394068, 34.831250385484545],
              [-80.934927919440398, 34.832309645342107],
              [-80.935254480233169, 34.832351979664658],
              [-80.935202275429859, 34.832616794707057],
              [-80.935528837589928, 34.832659128284945],
              [-80.935476633478316, 34.832923943443134],
              [-80.935803197005697, 34.832966276276416],
              [-80.935750993585728, 34.833231091550481],
              [-80.935424429028842, 34.833188758576121],
              [-80.935267812517949, 34.83398320203802],
              [-80.934941245210268, 34.833940867754258],
              [-80.93473241470555, 34.835000124477816],
              [-80.934680205409705, 34.835264938383126],
              [-80.934353632198949, 34.835222602525157],
              [-80.934144789704973, 34.836281857293166],
              [-80.933818212713746, 34.836239519984552],
              [-80.933766000762617, 34.836504333463118],
              [-80.933439423079633, 34.836461995127422],
              [-80.933334993897077, 34.836991620859884],
              [-80.933008414492775, 34.836949281355871],
              [-80.932956198354404, 34.837214093592188],
              [-80.932629618258375, 34.837171753061028],
              [-80.932577400752521, 34.837436565130766],
              [-80.932250819964779, 34.837394223572574],
              [-80.932198601112702, 34.83765903637692],
              [-80.932094162352385, 34.838188660106816],
              [-80.932420747322155, 34.838231002071133],
              [-80.932264089736236, 34.839025437898812],
              [-80.931937501677552, 34.83898309551099],
              [-80.931780837940224, 34.839777529784747],
              [-80.931454247130517, 34.839735186087395],
              [-80.931402024186283, 34.839999997620346],
              [-80.93107543268485, 34.839957652895819],
              [-80.930918758693096, 34.84075208511549],
              [-80.931245353283543, 34.840794430263614],
              [-80.931193128966385, 34.841059240793342],
              [-80.931519724924456, 34.841101585196739],
              [-80.931206377009232, 34.842690448704225],
              [-80.931532980576762, 34.84273279305139],
              [-80.931324078919346, 34.843792033647198],
              [-80.931650686943911, 34.843834377673097],
              [-80.931546235600351, 34.844363998108896],
              [-80.932199456761083, 34.844448683166242],
              [-80.932147233199146, 34.844713494070128],
              [-80.932473845305537, 34.844755834960274],
              [-80.932800457771165, 34.844798175865783],
              [-80.932539345083882, 34.846122226906594],
              [-80.932865964108501, 34.846164566713121],
              [-80.932813741587069, 34.846429376985725],
              [-80.933140360907473, 34.846471716966292],
              [-80.933088139056522, 34.846736526453142],
              [-80.933414760838104, 34.846778865671261],
              [-80.933153651706036, 34.848102915229745],
              [-80.933480278953596, 34.848145253366397],
              [-80.93337583499914, 34.848674873473037],
              [-80.933049205670599, 34.848632534152635],
              [-80.932996982145838, 34.848897343575644],
              [-80.932670352125399, 34.848855003228081],
              [-80.932618127232757, 34.849119812484183],
              [-80.932291495448339, 34.849077472028277],
              [-80.932187042568131, 34.849607089279885],
              [-80.932082588357218, 34.85013670733013],
              [-80.932409223167838, 34.850179048368403],
              [-80.932304768550225, 34.850708665714471],
              [-80.93263140687344, 34.850751007032734],
              [-80.93257918062352, 34.851015815349015],
              [-80.932526952942325, 34.851280623657132],
              [-80.932200313673775, 34.851238282940237],
              [-80.932095856330747, 34.851767899179457],
              [-80.931769215340381, 34.851725557294081],
              [-80.931716984585151, 34.85199036524272],
              [-80.931063700443943, 34.851905677647672],
              [-80.931011467290759, 34.852170485288205],
              [-80.9306848252333, 34.852128139560953],
              [-80.930580355852939, 34.852657754482316],
              [-80.930907001042428, 34.852700099573084],
              [-80.930854767968569, 34.852964907118846],
              [-80.931181414547368, 34.853007252366034],
              [-80.931129181050693, 34.853272059143443],
              [-80.931455828997528, 34.853314403645712],
              [-80.931403596192879, 34.853579210538484],
              [-80.931730246601148, 34.853621554278369],
              [-80.931678014488384, 34.853886361286619],
              [-80.932004665171405, 34.853928704299015],
              [-80.931900203085164, 34.854458318502999],
              [-80.931573550363481, 34.854415976109586],
              [-80.931521318330056, 34.854680783022978],
              [-80.931194663822893, 34.85463843961972],
              [-80.931142430421403, 34.854903246366156],
              [-80.930815775222257, 34.854860901935567],
              [-80.930763540452674, 34.85512570851504],
              [-80.930110230080885, 34.855041015794384],
              [-80.930057991819638, 34.855305822083153],
              [-80.929731336646924, 34.855263473793016],
              [-80.929679097017555, 34.855528279914829],
              [-80.930005753220414, 34.855570628346172],
              [-80.929953515376397, 34.85583543456579],
              [-80.93060683190717, 34.855920127232324],
              [-80.93055459469187, 34.856184933726055],
              [-80.93316788371429, 34.85652367186087],
              [-80.93306342693748, 34.857053286994628],
              [-80.933390092051383, 34.857095625324263],
              [-80.93333786307133, 34.857360432109715],
              [-80.93301119692731, 34.857318093639002],
              [-80.932958967693764, 34.857582901141356],
              [-80.933285634867886, 34.85762523975324],
              [-80.933128947072092, 34.858419659842127],
              [-80.932972256233953, 34.859214079698269],
              [-80.933298931041705, 34.859256419154697],
              [-80.933246700366027, 34.859521224973221],
              [-80.934226728750772, 34.859648235762471],
              [-80.934174500848854, 34.859913042879917],
              [-80.934501179047814, 34.859955378172529],
              [-80.934292271418514, 34.861014605092514],
              [-80.933965590192258, 34.860972269217662],
              [-80.933913360600116, 34.861237076205612],
              [-80.933586677588224, 34.861194739321029],
              [-80.933482217473795, 34.861724351098978],
              [-80.933155532739534, 34.861682013045865],
              [-80.933051068118885, 34.862211624455334],
              [-80.932724381683471, 34.862169286135043],
              [-80.932672147289097, 34.862434091668447],
              [-80.932345460140326, 34.86239175141943],
              [-80.932240989348855, 34.862921361207263],
              [-80.931914300477729, 34.862879019789681],
              [-80.931705349644474, 34.86393823940805],
              [-80.932032042636592, 34.863980581390301],
              [-80.931979805917393, 34.864245385681976],
              [-80.932306500277988, 34.864287726919123],
              [-80.932202026813627, 34.864817336625705],
              [-80.932528723572815, 34.864859677258856],
              [-80.93247648789982, 34.865124481754869],
              [-80.932803186027513, 34.865166821643058],
              [-80.932698714634256, 34.865696430856815],
              [-80.93302541625421, 34.865738770123428],
              [-80.932920945569123, 34.866268379515525],
              [-80.933247649566553, 34.866310717276846],
              [-80.933195414200384, 34.86657552208375],
              [-80.933522119587465, 34.866617860001249],
              [-80.933469885985886, 34.866882664004386],
              [-80.93379659274143, 34.866925001176753],
              [-80.933848825291349, 34.8666601961312],
              [-80.934828945567844, 34.86678720098822],
              [-80.934881174709957, 34.866522396394544],
              [-80.936841417093774, 34.86677638044825],
              [-80.936789194133823, 34.867041185888716],
              [-80.937442613755891, 34.867125840420123],
              [-80.93739039254001, 34.867390647018119],
              [-80.937717103331011, 34.867432972653596],
              [-80.937612663018101, 34.86796258513467],
              [-80.937560441797089, 34.868227390894319],
              [-80.937887156038656, 34.868269716968094],
              [-80.937834936603622, 34.868534522825193],
              [-80.938161652192377, 34.868576847252427],
              [-80.938109433449739, 34.868841653224564],
              [-80.938436150428203, 34.868883977807918],
              [-80.938383932378073, 34.869148783895035],
              [-80.938710650703641, 34.869191106831835],
              [-80.938606214556501, 34.869720719227708],
              [-80.938279493076351, 34.869678396026259],
              [-80.938227272918539, 34.869943202052745],
              [-80.937900550746036, 34.869900877823937],
              [-80.937796108427563, 34.870430488597442],
              [-80.937142660974786, 34.870345836916371],
              [-80.937090436690099, 34.870610641539862],
              [-80.936763712440296, 34.870568314228713],
              [-80.936659259723442, 34.871097924034189],
              [-80.936985987127827, 34.87114025160998],
              [-80.936933761850213, 34.871405057056442],
              [-80.937260489529422, 34.871447383904659],
              [-80.937208264922887, 34.871712188564786],
              [-80.937534993992088, 34.871754515569037],
              [-80.937482770077978, 34.872019320344123],
              [-80.938789695958917, 34.872188616421326],
              [-80.938737476922526, 34.872453421717054],
              [-80.93939094395229, 34.872538064719613],
              [-80.939338725545284, 34.872802870289028],
              [-80.939665461144571, 34.872845190592976],
              [-80.939613243429989, 34.87310999627735],
              [-80.939939980397938, 34.873152315835988],
              [-80.939887763375864, 34.873417121635271],
              [-80.941194718749799, 34.873586391570299],
              [-80.941142505490575, 34.873851197006402],
              [-80.941795987266659, 34.873935826937718],
              [-80.941691566065359, 34.874465439161597],
              [-80.942018309521572, 34.874507753079733],
              [-80.941966099433799, 34.874772558842828],
              [-80.942292844258688, 34.874814872015619],
              [-80.942188425151869, 34.875344484646703],
              [-80.94251517235449, 34.875386796313826],
              [-80.942462963335387, 34.875651603181794],
              [-80.942789711906613, 34.875693914103572],
              [-80.942737503580247, 34.875958721086377],
              [-80.943064254613773, 34.876001031245103],
              [-80.943012046958586, 34.876265837441423],
              [-80.943338798288451, 34.876308147773763],
              [-80.944319056450524, 34.876435071614239],
              [-80.944371259983015, 34.876170264853833],
              [-80.945351518094668, 34.876297180293264],
              [-80.945247117969345, 34.87682679461642],
              [-80.945573872674132, 34.876869097983445],
              [-80.945521673682066, 34.877133905237834],
              [-80.945848430870612, 34.877176208743087],
              [-80.945639632321971, 34.878235436312941],
              [-80.945966393971275, 34.878277739495807],
              [-80.945914195329635, 34.878542545999778],
              [-80.946894485354406, 34.878669448849685],
              [-80.946842289466929, 34.87893425575043],
              [-80.948149352752296, 34.879103446484315],
              [-80.948044968231173, 34.87963306223628],
              [-80.948371736685345, 34.879675357989917],
              [-80.948319544937931, 34.87994016551685],
              [-80.948646315854504, 34.879982460507385],
              [-80.948594123706329, 34.880247268166642],
              [-80.948920895991577, 34.880289562411562],
              [-80.948868705629963, 34.880554370167914],
              [-80.949522252160563, 34.880638955396655],
              [-80.949470063522682, 34.880903763408611],
              [-80.949796838883174, 34.880946055276034],
              [-80.949744650938229, 34.881210863402742],
              [-80.95072498211799, 34.881337733207396],
              [-80.950672796927662, 34.881602541730707],
              [-80.950999575679774, 34.881644829432588],
              [-80.950947392276291, 34.881909638052768],
              [-80.951600952877229, 34.881994211980306],
              [-80.951548770103756, 34.882259020873896],
              [-80.951875551931096, 34.882301306198123],
              [-80.951823370944496, 34.882566115188638],
              [-80.952803722661415, 34.882692967148579],
              [-80.952751543335751, 34.882957776553333],
              [-80.953078329670035, 34.883000058595535],
              [-80.953026151015919, 34.883264867213597],
              [-80.953679725687365, 34.883349429839384],
              [-80.953627549851007, 34.883614238695259],
              [-80.954281127935403, 34.883698798056614],
              [-80.954228953823076, 34.883963607168006],
              [-80.954555743845233, 34.884005885217974],
              [-80.954451396669114, 34.884535503643548],
              [-80.955104984059673, 34.884620058513406],
              [-80.955052812005306, 34.8848848670671],
              [-80.955000639634619, 34.885149676495821],
              [-80.95532743588771, 34.885191952432024],
              [-80.955223091101161, 34.885721571509968],
              [-80.955549889753968, 34.88576384684152],
              [-80.955341202374271, 34.886823082504755],
              [-80.955668005488818, 34.886865357513351],
              [-80.955459316806781, 34.887924592416503],
              [-80.955132508474321, 34.887882316861962],
              [-80.955028160044463, 34.888411933873172],
              [-80.954701349987772, 34.888369657150264],
              [-80.95464917423503, 34.888634465475214],
              [-80.954322363485105, 34.888592187724754],
              [-80.954165829969895, 34.889386610333339],
              [-80.954492643834513, 34.889428889407974],
              [-80.954388288151662, 34.88995850403488],
              [-80.954715104394708, 34.89000078160332],
              [-80.954662927646041, 34.89026558991047],
              [-80.954989745258004, 34.890307866733245],
              [-80.954937569181126, 34.890572674253434],
              [-80.955591207480722, 34.890657225520798],
              [-80.955539033128119, 34.890922033296391],
              [-80.956846318978037, 34.891091124835768],
              [-80.956741977508358, 34.891620741434622],
              [-80.95706880270339, 34.891663012596631],
              [-80.95701663247138, 34.89192782009583],
              [-80.957343459035357, 34.891970090512082],
              [-80.957291289518253, 34.892234899026988],
              [-80.957618116357366, 34.89227716871514],
              [-80.957670284843232, 34.892012360059333],
              [-80.957997112082808, 34.892054628702141],
              [-80.957944944606425, 34.892319436597568],
              [-80.959252259951739, 34.892488501982101],
              [-80.959200096262308, 34.892753310414463],
              [-80.959526927253094, 34.892795574905684],
              [-80.95985375856003, 34.892837837608795],
              [-80.959801597710623, 34.893102647179965],
              [-80.961108929368265, 34.89327169060811],
              [-80.960848139520607, 34.89459573819699],
              [-80.960521300653824, 34.894553477294195],
              [-80.960312657224122, 34.895612712903045],
              [-80.960639500194262, 34.895654973467948],
              [-80.960587340070646, 34.895919782435691],
              [-80.961241030202288, 34.896004302070601],
              [-80.961188871781928, 34.89626911039214],
              [-80.961842565327132, 34.896353626761694],
              [-80.961790408631487, 34.896618435338354],
              [-80.962117257478155, 34.896660691874345],
              [-80.9620651014977, 34.896925501466484],
              [-80.962391951713386, 34.896967757256597],
              [-80.962339795310996, 34.897232566079516],
              [-80.962666646895741, 34.897274821123638],
              [-80.962614492302436, 34.897539630944287],
              [-80.962941345256226, 34.897581885242474],
              [-80.962889191334881, 34.897846694276026],
              [-80.963216045657816, 34.897888947828285],
              [-80.963163892430089, 34.898153756976058],
              [-80.963817605245552, 34.898238261684149],
              [-80.963765453742809, 34.898503071086928],
              [-80.964092311141471, 34.898545322260517],
              [-80.964040160332459, 34.898810131777509],
              [-80.964367020194175, 34.898852382187286],
              [-80.96431487005708, 34.899117190917124],
              [-80.964641730193875, 34.899159440598858],
              [-80.964589581866193, 34.89942425032627],
              [-80.964916443372076, 34.899466499261941],
              [-80.964864294643974, 34.899731309121428],
              [-80.965191158591281, 34.899773556392034],
              [-80.965139010556996, 34.900038366365621],
              [-80.965465875895035, 34.900080613791559],
              [-80.965413728532695, 34.90034542297802],
              [-80.965740595239851, 34.900387669657874],
              [-80.965636302680721, 34.900917289115888],
              [-80.965963171766731, 34.900959534289214],
              [-80.965858878825671, 34.901489153939934],
              [-80.966185750333821, 34.901531399409386],
              [-80.965925019341071, 34.902855445582524],
              [-80.965598142697118, 34.902813200310412],
              [-80.965441696727098, 34.903607626914265],
              [-80.965114816210786, 34.903565379449411],
              [-80.965010515503351, 34.904094996463698],
              [-80.964683633283414, 34.904052748731623],
              [-80.964631480833717, 34.9043175566161],
              [-80.96430459792002, 34.904275307856423],
              [-80.964252444101149, 34.904540115573326],
              [-80.963925561566015, 34.904497864866762],
              [-80.963873406377886, 34.904762672416098],
              [-80.963546522076697, 34.904720421601091],
              [-80.96333789272613, 34.90577965098506],
              [-80.963664781175069, 34.905821903264652],
              [-80.963612624298023, 34.906086710680029],
              [-80.963939514094633, 34.906128961312248],
              [-80.96383520139041, 34.906658576344277],
              [-80.964162093587888, 34.906700826371214],
              [-80.964109937760725, 34.906965633987895],
              [-80.964436831327546, 34.907007883268747],
              [-80.964384676194072, 34.907272690999271],
              [-80.964711571130238, 34.907314939534118],
              [-80.964607261891615, 34.907844554295131],
              [-80.965261058019323, 34.907929050150706],
              [-80.965313210755127, 34.907664242952748],
              [-80.965640107736121, 34.907706488967648],
              [-80.965587956031939, 34.907971296306464],
              [-80.966895554785452, 34.90814027380943],
              [-80.966843406869955, 34.908405081684492],
              [-80.967497209767586, 34.908489564503668],
              [-80.967445064693337, 34.908754373516999],
              [-80.967771967133288, 34.908796613745885],
              [-80.967719822731269, 34.909061421971813],
              [-80.9680467276347, 34.909103661436738],
              [-80.967994582832588, 34.909368469794451],
              [-80.968648395715888, 34.909452946344956],
              [-80.968596253733423, 34.909717754939457],
              [-80.968923161713292, 34.909759992025094],
              [-80.968662449684103, 34.911084034432761],
              [-80.968989363138377, 34.911126270433989],
              [-80.968937221193116, 34.911391079148757],
              [-80.969264136038504, 34.911433315305032],
              [-80.969159853176677, 34.911962932034072],
              [-80.969813689079913, 34.91204740132963],
              [-80.969761548122563, 34.912312210403826],
              [-80.971723072337127, 34.912565598713108],
              [-80.971775204937387, 34.912300789731574],
              [-80.972755969054873, 34.912427471480861],
              [-80.972808098222202, 34.912162662050228],
              [-80.973788861167151, 34.912289334511193],
              [-80.973840987995658, 34.912024524613521],
              [-80.974821750905591, 34.912151189570793],
              [-80.97487387318526, 34.911886378340768],
              [-80.975527715471742, 34.911970816616865],
              [-80.975579835372159, 34.911706005979902],
              [-80.976560597133229, 34.911832655886826],
              [-80.976612713600773, 34.911567844800864],
              [-80.976939634188568, 34.911610059215477],
              [-80.976991749265025, 34.911345247060616],
              [-80.977318668086212, 34.911387461366765],
              [-80.977370781793269, 34.911122649044366],
              [-80.977697700992593, 34.911164861403641],
              [-80.977749812258097, 34.910900049832954],
              [-80.979384405991794, 34.911111098558251],
              [-80.979332299885215, 34.911375910832191],
              [-80.979986141576987, 34.911460324037911],
              [-80.981293831198641, 34.911629139769211],
              [-80.981241729872977, 34.911893953779611],
              [-80.982222503727726, 34.912020556686549],
              [-80.982170406232385, 34.912285370172718],
              [-80.982497332557315, 34.912327569508285],
              [-80.982549429020821, 34.912062755881585],
              [-80.983857133545456, 34.912231542890055],
              [-80.983805042303445, 34.91249635706135],
              [-80.98445889864287, 34.912580745074131],
              [-80.98440680805497, 34.912845560419015],
              [-80.984733737751668, 34.912887752784897],
              [-80.984681648930447, 34.913152567324282],
              [-80.985335512537631, 34.913236951460668],
              [-80.985283424348708, 34.913501766272397],
              [-80.985610357121161, 34.91354395625833],
              [-80.985558270720929, 34.913808771165741],
              [-80.985885205978803, 34.91385096128851],
              [-80.985833119178835, 34.914115776327648],
              [-80.986813928934282, 34.9142423400111],
              [-80.986761845986862, 34.91450715542701],
              [-80.98708878354195, 34.914549341399599],
              [-80.987036701289114, 34.914814156929275],
              [-80.987690581685243, 34.91489852735954],
              [-80.988017522378343, 34.914940710793431],
              [-80.987965442883976, 34.915205526717955],
              [-80.989273212085024, 34.915374253064712],
              [-80.989169061412341, 34.915903885038183],
              [-80.989496006887862, 34.915946064458439],
              [-80.989391855813707, 34.916475695722042],
              [-80.989718803712393, 34.916517875437677],
              [-80.989666729795672, 34.916782691151603],
              [-80.990320628625057, 34.916867046400185],
              [-80.990268556434955, 34.917131862368244],
              [-80.990922458677503, 34.91721621434958],
              [-80.991026599686663, 34.916686581806246],
              [-80.991353550344073, 34.916728756167053],
              [-80.991457686823892, 34.916199122351671],
              [-80.991784635754556, 34.91624129554431],
              [-80.991836702445568, 34.915976478004964],
              [-80.992490597066293, 34.916060821484109],
              [-80.992542661378053, 34.915796004538066],
              [-80.995812147610621, 34.91621766454486],
              [-80.995760093618856, 34.916482482896093],
              [-80.997394855075441, 34.916693279876199],
              [-80.997342807000948, 34.916958098884855],
              [-80.998650626791644, 34.917126720695109],
              [-80.9987026707159, 34.916861900223118],
              [-80.999683536538456, 34.916988356384877],
              [-80.999735577051453, 34.916723536365829],
              [-81.000062532325146, 34.916765686198616],
              [-81.000010492844211, 34.917030506358103],
              [-81.003280071179191, 34.917451958253892],
              [-81.003332099245014, 34.917187136708023],
              [-81.004966897498321, 34.917397827306942],
              [-81.00491487459314, 34.917662649555012],
              [-81.005568798326436, 34.91774692022598],
              [-81.005516777148443, 34.918011742727927],
              [-81.00649766834988, 34.918138141600679],
              [-81.006445649931365, 34.918402964496963],
              [-81.008407448718756, 34.91865573911344],
              [-81.008355437250643, 34.91892056280701],
              [-81.009009375639351, 34.919004812982003],
              [-81.00895736589861, 34.919269636929322],
              [-81.009284336105566, 34.91931176173695],
              [-81.009232327038049, 34.91957658489639],
              [-81.009559298614093, 34.919618708957145],
              [-81.0095072902418, 34.919883532230024],
              [-81.009834264281224, 34.919925655525716],
              [-81.009782256604268, 34.920190478912019],
              [-81.010109230918516, 34.920232601479235],
              [-81.010057223936883, 34.920497424978905],
              [-81.010384200692471, 34.920539545879684],
              [-81.010332194428344, 34.920804370394087],
              [-81.010659172552948, 34.92084649054808],
              [-81.010607166984187, 34.921111315175828],
              [-81.011261126308526, 34.921195553102834],
              [-81.011209122445251, 34.921460377083086],
              [-81.011536103644985, 34.921502494856199],
              [-81.011484100477077, 34.921767318949684],
              [-81.012138065973858, 34.921851553016246],
              [-81.012086064533662, 34.922116377363551],
              [-81.012413049902918, 34.922158492737445],
              [-81.012361049158159, 34.922423317198017],
              [-81.013342008194982, 34.922549658453832],
              [-81.013290010210284, 34.9228144833085],
              [-81.013943986992871, 34.922898705749574],
              [-81.013891991830278, 34.923163530839638],
              [-81.014872962091232, 34.9232898587276],
              [-81.014924954179051, 34.923025034117877],
              [-81.015578934329739, 34.923109247686483],
              [-81.015630923993882, 34.922844421867801],
              [-81.016938884229504, 34.923012838698128],
              [-81.016886899789029, 34.923277665059757],
              [-81.017213891158363, 34.923319766522667],
              [-81.017161906319174, 34.923584593015917],
              [-81.017815894343684, 34.923668794425346],
              [-81.017763911232478, 34.923933621172182],
              [-81.018417901574367, 34.924017819331382],
              [-81.018365921263225, 34.924282645412148],
              [-81.018692918529965, 34.924324743742716],
              [-81.018640937820166, 34.924589569955081],
              [-81.019621934693376, 34.92471585824125],
              [-81.019569957860739, 34.924980685730404],
              [-81.020223959509664, 34.92506487316453],
              [-81.020171983310817, 34.925329700925523],
              [-81.020825989465834, 34.925413885072693],
              [-81.020774014972773, 34.9256787121858],
              [-81.021101020157218, 34.9257208039609],
              [-81.021049046359892, 34.925985631187324],
              [-81.021703058664812, 34.926069810571782],
              [-81.02165108769006, 34.926334638033303],
              [-81.021978095927167, 34.926376726525618],
              [-81.021926124553843, 34.92664155411871],
              [-81.02225313416011, 34.926683641864081],
              [-81.022201164577012, 34.926948469551867],
              [-81.022528175552424, 34.926990556550173],
              [-81.022476206665061, 34.927255384351241],
              [-81.022803217915268, 34.927297470620772],
              [-81.022751249723726, 34.927562298534987],
              [-81.023078263437469, 34.927604384039164],
              [-81.02302629591945, 34.927869211165245],
              [-81.023353311002353, 34.92791129592225],
              [-81.023301344202494, 34.928176124062787],
              [-81.02362836065457, 34.928218208072778],
              [-81.023576394528263, 34.928483035425081],
              [-81.023903412349526, 34.928525118687965],
              [-81.023799482268771, 34.929054774474714],
              [-81.024126502469457, 34.929096856229414],
              [-81.02407453742056, 34.929361684682235],
              [-81.024728580919287, 34.929445846711317],
              [-81.024676618670924, 34.929710674497791],
              [-81.025003641968979, 34.92975275477216],
              [-81.024951679322157, 34.930017582690077],
              [-81.025605730065379, 34.930101739937584],
              [-81.025553770241487, 34.930366568090413],
              [-81.025880796614572, 34.93040864598315],
              [-81.025828837486756, 34.930673474249076],
              [-81.026155865206732, 34.930715550493417],
              [-81.026103906774793, 34.930980378872405],
              [-81.02643093698066, 34.931022455252446],
              [-81.026327020055476, 34.931552111326454],
              [-81.026654052663289, 34.931594187099584],
              [-81.026602094169959, 34.931859014794554],
              [-81.026929128147032, 34.931901089820485],
              [-81.026877171444141, 34.932165917610156],
              [-81.027204206790472, 34.932207991888816],
              [-81.027152249689181, 34.932472819809938],
              [-81.02747928640477, 34.932514893341491],
              [-81.027375375446624, 34.933044549345617],
              [-81.02770241456416, 34.933086622270196],
              [-81.027650459612801, 34.933351450371696],
              [-81.027977500077242, 34.933393521647666],
              [-81.027873591230076, 34.933923178049525],
              [-81.028200634118875, 34.933965249619703],
              [-81.027992815399315, 34.935024561774668],
              [-81.028319863827647, 34.935066632098533],
              [-81.028163999072461, 34.935861116128585],
              [-81.028491050935628, 34.935903185985552],
              [-81.028387142203542, 34.936432841836961],
              [-81.028714196468727, 34.936474911086954],
              [-81.028610287338864, 34.937004566226797],
              [-81.028937345100786, 34.937046634851207],
              [-81.02888539053879, 34.937311463430895],
              [-81.029212449670112, 34.937353531307991],
              [-81.02869290432929, 34.940001805140994],
              [-81.028365834869746, 34.93995973586204],
              [-81.028002125104763, 34.941813520532072],
              [-81.028329201794421, 34.941855590792265],
              [-81.02827724325553, 34.94212041719905],
              [-81.0295855585845, 34.942288689005508],
              [-81.029533603840605, 34.94255351594559],
              [-81.030514847238905, 34.942679710671875],
              [-81.030462895257145, 34.942944538005129],
              [-81.031771229347441, 34.9431127851621],
              [-81.031719281160875, 34.943377613028545],
              [-81.032700538897856, 34.943503789037528],
              [-81.032648594568158, 34.943768617278543],
              [-81.033629858433216, 34.943894785720019],
              [-81.033577916843569, 34.944159613452612],
              [-81.033905005789975, 34.944201668577989],
              [-81.033853064896661, 34.94446649642336],
              [-81.034834340093141, 34.944592655036253],
              [-81.034782401962289, 34.944857483274561],
              [-81.035436589160412, 34.944941584258004],
              [-81.035384652759063, 34.94520641274908],
              [-81.036038843391822, 34.94529051136373],
              [-81.03598690872002, 34.945555340107603],
              [-81.036641103837113, 34.945639434532289],
              [-81.036589170894885, 34.945904263528945],
              [-81.037243369424147, 34.945988354683443],
              [-81.037191438211522, 34.946253183932811],
              [-81.037845640152952, 34.946337271817107],
              [-81.037793710647506, 34.946602100417905],
              [-81.038120812608724, 34.946644143177934],
              [-81.038068884916967, 34.94690897277416],
              [-81.038395988247771, 34.946951014786734],
              [-81.038344061230134, 34.947215843594279],
              [-81.038998272062017, 34.947299925218182],
              [-81.038946346796592, 34.94756475517967],
              [-81.03927345318057, 34.947606793908186],
              [-81.03922152861179, 34.947871623982294],
              [-81.039548637459973, 34.947913661944789],
              [-81.039496712493232, 34.948178492150014],
              [-81.039823822711014, 34.948220529364875],
              [-81.039771899535566, 34.948485359664147],
              [-81.040099011122962, 34.948527396131503],
              [-81.040047088621705, 34.948792225642059],
              [-81.04037420160121, 34.94883426226307],
              [-81.040322278702007, 34.949099091904699],
              [-81.040649394145774, 34.949141127759674],
              [-81.040597471943272, 34.949405957513825],
              [-81.040924588734185, 34.949447991719843],
              [-81.040872667228385, 34.949712821586566],
              [-81.041199785411436, 34.949754855946345],
              [-81.04114786457987, 34.950019685024309],
              [-81.041802102927022, 34.950103751379345],
              [-81.041750184920048, 34.950368580691425],
              [-81.04207730617884, 34.950410612668193],
              [-81.041973471244503, 34.950940272390987],
              [-81.042300593788966, 34.950982302877506],
              [-81.042248676861632, 34.951247133288291],
              [-81.042575801870427, 34.951289163008553],
              [-81.042523885617456, 34.951553992630522],
              [-81.04285101201836, 34.951596022504503],
              [-81.042695264340409, 34.952390511625168],
              [-81.043022394177598, 34.952432541031477],
              [-81.042918562776578, 34.952962200587493],
              [-81.043245694994212, 34.953004228484936],
              [-81.043089948469387, 34.953798718032161],
              [-81.043417084123234, 34.953840745461903],
              [-81.043365168952818, 34.95410557449452],
              [-81.04369230709375, 34.954147602059294],
              [-81.043640392620219, 34.95441243120441],
              [-81.043967532108354, 34.954454457120185],
              [-81.043863704241275, 34.954984116508761],
              [-81.044190846155047, 34.955026142718218],
              [-81.044138932738875, 34.955290972060439],
              [-81.044466074905145, 34.955332996639356],
              [-81.044414163280635, 34.955597826075419],
              [-81.04474130681659, 34.955639849906674],
              [-81.044689395889094, 34.955904679455017],
              [-81.045016541912219, 34.95594670342129],
              [-81.044964630586747, 34.956211533100642],
              [-81.045618925663973, 34.956295576847275],
              [-81.045567016068844, 34.956560406778991],
              [-81.045894165145413, 34.956602427460638],
              [-81.045842257319634, 34.956867256584815],
              [-81.046169407766001, 34.956909276518786],
              [-81.046117499564843, 34.957174106675183],
              [-81.046771804628335, 34.957258144141122],
              [-81.046667992422954, 34.957787804011026],
              [-81.04699514697873, 34.957829821701644],
              [-81.046943241963518, 34.958094652176442],
              [-81.047270398983798, 34.958136669100604],
              [-81.047166588893816, 34.958666329364384],
              [-81.047493747200022, 34.958708344798055],
              [-81.047389938926173, 34.95923800521247],
              [-81.047717099658357, 34.959280020939566],
              [-81.047665196038977, 34.959544850794529],
              [-81.04799235923582, 34.959586865755298],
              [-81.047940456313555, 34.959851695722534],
              [-81.048267620857658, 34.959893709034056],
              [-81.048163814976149, 34.96042336918395],
              [-81.04849098194623, 34.960465382789003],
              [-81.048439080081792, 34.960730212953017],
              [-81.048766248399161, 34.960772224908837],
              [-81.048714347231879, 34.961037055185081],
              [-81.049041516941742, 34.96107906729442],
              [-81.048937715642509, 34.961608727142334],
              [-81.049264887755797, 34.961650738643783],
              [-81.049212987623847, 34.961915568215417],
              [-81.049540161107075, 34.961957578968899],
              [-81.049436361923767, 34.962487239210127],
              [-81.049763538882686, 34.962529248435857],
              [-81.049711639808748, 34.962794078204119],
              [-81.050038817065399, 34.962836087601801],
              [-81.049986918688745, 34.96310091748223],
              [-81.05031409838746, 34.963142925212168],
              [-81.050210302714774, 34.963672586070928],
              [-81.050537484817042, 34.963714593192819],
              [-81.0504855874984, 34.963979423269883],
              [-81.050812770970722, 34.96402142964385],
              [-81.050708977391594, 34.964551089994309],
              [-81.051036163267497, 34.964593095760378],
              [-81.05093237041028, 34.965122756279413],
              [-81.051259559784583, 34.965164761418876],
              [-81.051103871054309, 34.965959251507378],
              [-81.051431063866033, 34.966001256178728],
              [-81.051379167968449, 34.966266085691622],
              [-81.051706361055324, 34.966308089633564],
              [-81.051654465877661, 34.96657292015994],
              [-81.051981661429465, 34.966614923335271],
              [-81.051929766926506, 34.966879753072355],
              [-81.052256963848251, 34.966921755499769],
              [-81.052205070042717, 34.967186585348863],
              [-81.052532268334616, 34.967228587028309],
              [-81.052376589095658, 34.968023076809693],
              [-81.052703790825049, 34.968065078021006],
              [-81.052444323115893, 34.969389226909755],
              [-81.052771530350242, 34.969431227932695],
              [-81.052667743623005, 34.969960887020768],
              [-81.052994953261347, 34.970002887435513],
              [-81.052943060963074, 34.97026771661762],
              [-81.053270271971542, 34.970309716284426],
              [-81.053218379275762, 34.970574545597174],
              [-81.053545592749231, 34.970616544497169],
              [-81.053493700750963, 34.970881373921898],
              [-81.054148130752012, 34.970965368436651],
              [-81.05409624050759, 34.97123019901462],
              [-81.054423457035156, 34.971272194629265],
              [-81.054371567465637, 34.971537024417785],
              [-81.054698785385924, 34.97157902018575],
              [-81.054595008400298, 34.972108679940092],
              [-81.054922228702026, 34.972150674198559],
              [-81.054870341286033, 34.97241550416431],
              [-81.055197562980609, 34.97245749857597],
              [-81.055145675144502, 34.972722327771073],
              [-81.055472899304235, 34.972764321415895],
              [-81.055421012188333, 34.973029151624218],
              [-81.055748236600621, 34.973071143638371],
              [-81.055644465594852, 34.973600803312216],
              [-81.0559716924339, 34.973642795619426],
              [-81.055919807449072, 34.973907625103607],
              [-81.056247035635664, 34.973949615761406],
              [-81.056195151371099, 34.974214446258763],
              [-81.056849611916022, 34.974298425171327],
              [-81.05679772938295, 34.974563255920444],
              [-81.057124961741195, 34.97460524417518],
              [-81.05707307878815, 34.974870074153543],
              [-81.057727546604298, 34.97495404918007],
              [-81.057675666477721, 34.975218879391456],
              [-81.058330136589134, 34.975302850263027],
              [-81.05827825819415, 34.975567680726179],
              [-81.058605495335783, 34.975609664960395],
              [-81.058553617638481, 34.975874495535301],
              [-81.058880856150353, 34.9759164790214],
              [-81.058828980245735, 34.976181309689451],
              [-81.059483460346271, 34.976265274277118],
              [-81.059431585078329, 34.976530105215524],
              [-81.059758826666979, 34.976572086317177],
              [-81.059706952096775, 34.976836917367372],
              [-81.06036143944587, 34.976920877167338],
              [-81.060309566584564, 34.977185707567941],
              [-81.060964057346709, 34.977269664095417],
              [-81.060912186240017, 34.977534495648868],
              [-81.061566680415183, 34.977618448903719],
              [-81.061618549453812, 34.977353617070612],
              [-81.06260028956585, 34.977479538980909],
              [-81.062548424724326, 34.977744371214762],
              [-81.062875672777409, 34.977786343855236],
              [-81.062823808610972, 34.978051175299562],
              [-81.063151058034293, 34.978093147191764],
              [-81.063099194565694, 34.978357978747738],
              [-81.063426446454329, 34.978399949872966],
              [-81.063374583683583, 34.978664781540751],
              [-81.063701836942485, 34.978706751917692],
              [-81.063649974869605, 34.978971583697223],
              [-81.063977229475924, 34.979013552424483],
              [-81.063925368101039, 34.979278384315791],
              [-81.064579879318217, 34.979362320305697],
              [-81.064528019675222, 34.979627152448515],
              [-81.06583705356546, 34.97979501427411],
              [-81.066818831680052, 34.979920900881602],
              [-81.066870682989119, 34.979656067779047],
              [-81.067197942014047, 34.979698028666419],
              [-81.067249793048006, 34.979433195377318],
              [-81.067577051352131, 34.979475154335439],
              [-81.06762889992082, 34.979210320897266],
              [-81.06795615752695, 34.97925227882758],
              [-81.068008004725399, 34.978987445221556],
              [-81.068335261633422, 34.979029402123935],
              [-81.068387107461675, 34.978764568350073],
              [-81.068714363671774, 34.978806524224673],
              [-81.068766208129816, 34.978541690282917],
              [-81.069093463641892, 34.978583645129625],
              [-81.069145306729752, 34.978318811020145],
              [-81.069472561543705, 34.978360764838989],
              [-81.069524402166365, 34.978095930580437],
              [-81.070178910734441, 34.97817983527424],
              [-81.07023075004787, 34.977915000689407],
              [-81.070885256796828, 34.977998901570139],
              [-81.07093709368317, 34.977734065776481],
              [-81.071264347075143, 34.977776014735561],
              [-81.072246108172067, 34.977901856303006],
              [-81.072297939513817, 34.977637020842408],
              [-81.073279701604591, 34.977762853077571],
              [-81.07333152950801, 34.977498017169879],
              [-81.074313290425309, 34.97762384101167],
              [-81.074365114890412, 34.977359004656819],
              [-81.075019622502452, 34.977442882185187],
              [-81.075071444540498, 34.977178044621517],
              [-81.075725950356016, 34.97726191923811],
              [-81.075777769989898, 34.976997081367095],
              [-81.077086782587457, 34.977164818464068],
              [-81.077138596653938, 34.976899980025209],
              [-81.078447610466327, 34.977067701452313],
              [-81.078395800536384, 34.977332540450014],
              [-81.078723056125597, 34.977374468270789],
              [-81.078671246893947, 34.977639307380066],
              [-81.080307535094335, 34.97784893566326],
              [-81.08025573069763, 34.978113775442864],
              [-81.080910250378551, 34.978197620097482],
              [-81.080858447714377, 34.97846246012837],
              [-81.081512971902072, 34.978546301490901],
              [-81.081461170970471, 34.978811141772972],
              [-81.082115698569694, 34.97889497986224],
              [-81.08206389934783, 34.979159819494356],
              [-81.082718430381632, 34.979243655211491],
              [-81.082666632892312, 34.979508495094841],
              [-81.083321167314736, 34.979592326637345],
              [-81.083269372653135, 34.979857166753021],
              [-81.08359664194991, 34.979899081322195],
              [-81.083544846891641, 34.980163921568348],
              [-81.083872117558357, 34.980205835389015],
              [-81.083820324293697, 34.98047067572773],
              [-81.084474867607881, 34.980554501002693],
              [-81.084423076075979, 34.980819341592678],
              [-81.08475034981852, 34.98086125302796],
              [-81.084698558985153, 34.981126093729571],
              [-81.085025834097621, 34.981168004416283],
              [-81.084974042867799, 34.981432845248278],
              [-81.085628597240756, 34.981516663316221],
              [-81.085576808838752, 34.981781504380422],
              [-81.086885923583665, 34.981949131355563],
              [-81.086834138960157, 34.982213972048704],
              [-81.090106963987907, 34.982632976378902],
              [-81.090055190467169, 34.982897818420803],
              [-81.090382475424022, 34.982939714566456],
              [-81.090434247887316, 34.982674871483709],
              [-81.090761533240467, 34.982716766582541],
              [-81.09070976178856, 34.98298160890355],
              [-81.091037047393542, 34.983023502371474],
              [-81.090985276663346, 34.98328834570524],
              [-81.091639852044267, 34.98337213023644],
              [-81.091588083047199, 34.983636973821206],
              [-81.091915372822953, 34.983678864884546],
              [-81.091863604501668, 34.983943707679465],
              [-81.092190895647448, 34.983985597994007],
              [-81.092139128024868, 34.984250440900439],
              [-81.092466420540745, 34.984292330466275],
              [-81.092414653616956, 34.984557173484056],
              [-81.09274194752598, 34.984599063202566],
              [-81.092690181300995, 34.984863906331746],
              [-81.093017476556966, 34.984905794400241],
              [-81.092965712125931, 34.985170637621842],
              [-81.093293008751857, 34.98521252494151],
              [-81.093241243901431, 34.985477367392285],
              [-81.093568541897511, 34.985519253963247],
              [-81.093516777769025, 34.985784097426624],
              [-81.093844078230291, 34.985825983229894],
              [-81.093792314800666, 34.986090826804656],
              [-81.094119615536869, 34.986132711878113],
              [-81.094067853878173, 34.986397554644007],
              [-81.09439515598433, 34.986439438968723],
              [-81.094343395047716, 34.986704282747205],
              [-81.09467069852397, 34.986746166323186],
              [-81.094618938263054, 34.987011009311757],
              [-81.09494624310949, 34.987052892138969],
              [-81.094894483547463, 34.987317735238804],
              [-81.095549097411251, 34.987401498488374],
              [-81.095497339582678, 34.987666341839123],
              [-81.095824648599944, 34.987708222261368],
              [-81.095772890375272, 34.98797306574243],
              [-81.096427511462508, 34.9880568232999],
              [-81.096375756066408, 34.988321667012777],
              [-81.097030380588123, 34.988405422197197],
              [-81.096978626925633, 34.98867026616086],
              [-81.097960569282264, 34.988795890891033],
              [-81.098012319841146, 34.988531046508953],
              [-81.099648891980848, 34.988740402895871],
              [-81.099597146594917, 34.989005247975328],
              [-81.100251780968293, 34.989088984752023],
              [-81.100200037315958, 34.989353830082202],
              [-81.100527356576507, 34.989395696817247],
              [-81.100475613623288, 34.989660542258783],
              [-81.100802934253934, 34.989702408244945],
              [-81.100751191999748, 34.989967253797658],
              [-81.101078514000477, 34.990009119035022],
              [-81.101026772445536, 34.990273964698936],
              [-81.101354095816376, 34.990315829187388],
              [-81.101302354937388, 34.990580674061263],
              [-81.101629679678339, 34.990622537800817],
              [-81.101526200101603, 34.991152228624905],
              [-81.101853527270407, 34.991194092656279],
              [-81.101801788549594, 34.991458937705275],
              [-81.102129115970143, 34.991500800105527],
              [-81.102077377948419, 34.99176564526573],
              [-81.102404707834395, 34.991807506897857],
              [-81.102301232877124, 34.992337198313734],
              [-81.102628565167933, 34.992379059336372],
              [-81.102576827113964, 34.992643904709844],
              [-81.102904161870057, 34.992685764964449],
              [-81.102800687920379, 34.993215455886286],
              [-81.103128023962995, 34.993257314649156],
              [-81.102972815269524, 34.994051851209441],
              [-81.10264547500438, 34.994009991146022],
              [-81.102490260164259, 34.994804526132057],
              [-81.102162917130272, 34.994762664762007],
              [-81.101852468827985, 34.99635173048636],
              [-81.102179818070596, 34.996393592693032],
              [-81.102024593030549, 34.997188125130783],
              [-81.102351945689861, 34.997229985966051],
              [-81.102300204738739, 34.997494830188209],
              [-81.102627558791468, 34.997536691175753],
              [-81.102575818539748, 34.997801535509033],
              [-81.103230529698266, 34.997885254196333],
              [-81.103178791180639, 34.998150098780116],
              [-81.103506149381701, 34.998191956460659],
              [-81.103454410468018, 34.998456801174562],
              [-81.104436489139829, 34.998582371125558],
              [-81.104384754067411, 34.998847215308906],
              [-81.106676289184449, 34.999140180251231],
              [-81.106728017012742, 34.99887533509213],
              [-81.108364833947505, 34.999084568263441],
              [-81.108416555147372, 34.998819721496879],
              [-81.11005337526916, 34.999028930858074],
              [-81.110001659220558, 34.999293777420149],
              [-81.110983759089137, 34.999419293142111],
              [-81.110932045809932, 34.999684140093834],
              [-81.111586782786659, 34.99976781247716],
              [-81.111638492900681, 34.999502965265911],
              [-81.112293229125513, 34.999586632915282],
              [-81.112344937929663, 34.999321785377823],
              [-81.112672305521798, 34.999363618181107],
              [-81.112724012955709, 34.999098770475889],
              [-81.113051379824896, 34.99914060135],
              [-81.113103084793281, 34.998875753496293],
              [-81.113430450986044, 34.99891758424387],
              [-81.113533858919183, 34.998387887252235],
              [-81.113861222258762, 34.99842971595065],
              [-81.113912925246751, 34.998164867713939],
              [-81.114567651980551, 34.998248523048737],
              [-81.114619351468278, 34.99798367452432],
              [-81.11494671483905, 34.998025500259374],
              [-81.114895016409676, 34.998290349824458],
              [-81.11522238117405, 34.998332175711568],
              [-81.115170683421027, 34.998597024486259],
              [-81.11549804955547, 34.998638849624292],
              [-81.11534295743661, 34.999433397998665],
              [-81.115670326987797, 34.999475221764826],
              [-81.115515234933241, 35.000269769400575],
              [-81.115187861181823, 35.000227945235714],
              [-81.11503276298177, 35.001022491296645],
              [-81.114705386437208, 35.00098066492415],
              [-81.114653685331675, 35.001245513415],
              [-81.113671556818744, 35.001120029411098],
              [-81.113619851177617, 35.00138487747482],
              [-81.112637722577333, 35.001259385056315],
              [-81.112586013495928, 35.001524232673631],
              [-81.112258637288761, 35.001482399644217],
              [-81.112206926813741, 35.00174724619248],
              [-81.111552172263501, 35.001663578109955],
              [-81.111500459383151, 35.001928424351142],
              [-81.111173082135338, 35.001886589278833],
              [-81.11112136788465, 35.002151435352232],
              [-81.110466611183227, 35.00206776047991],
              [-81.110414894550502, 35.002332607147473],
              [-81.110087515120128, 35.002290768248706],
              [-81.110035797117021, 35.002555614748474],
              [-81.109708416987019, 35.002513774821836],
              [-81.109656697590253, 35.002778620252563],
              [-81.10932931785608, 35.002736779278891],
              [-81.109277595993575, 35.003001624560895],
              [-81.10895021555973, 35.002959782559408],
              [-81.10884676987709, 35.003489473641423],
              [-81.108519386613267, 35.00344763049182],
              [-81.108415937495664, 35.003977320279915],
              [-81.108743322829511, 35.004019163708278],
              [-81.108639874440257, 35.004548853661113],
              [-81.108967262179604, 35.004590696479653],
              [-81.108915539064569, 35.004855541543122],
              [-81.108588150290174, 35.00481369858521],
              [-81.1085364247093, 35.005078543500005],
              [-81.108209035235276, 35.005036699514193],
              [-81.108105582069271, 35.005566388058938],
              [-81.1077781908606, 35.005524542905896],
              [-81.10772646220326, 35.005789387456353],
              [-81.107399070294974, 35.005747541275326],
              [-81.107347340243862, 35.006012384756552],
              [-81.107019947636061, 35.005970537547675],
              [-81.106916484480422, 35.006500225047084],
              [-81.106589089042572, 35.006458376690027],
              [-81.106485623570322, 35.006988063777378],
              [-81.10615822639771, 35.006946214252913],
              [-81.106054756394641, 35.007475900065188],
              [-81.105727357487439, 35.00743404937338],
              [-81.105572146861221, 35.008228577459256],
              [-81.105244745184137, 35.00818672546071],
              [-81.105089528409607, 35.00898125196985],
              [-81.1047621239628, 35.008939398664566],
              [-81.104658641261878, 35.009469082300882],
              [-81.104331235080366, 35.00942722782824],
              [-81.104279492727585, 35.009692069003826],
              [-81.103624678205449, 35.009608357133096],
              [-81.103572933446827, 35.009873198001294],
              [-81.102590712718197, 35.009747623083882],
              [-81.102538963423058, 35.010012463524355],
              [-81.101884149188535, 35.009928741289606],
              [-81.101832397510805, 35.010193582323872],
              [-81.101504989850298, 35.010151719283535],
              [-81.101401483394497, 35.010681400086106],
              [-81.101074073999243, 35.010639535878283],
              [-81.100970563035133, 35.011169216306428],
              [-81.10064315190526, 35.011127350931254],
              [-81.100539638600594, 35.011657030045491],
              [-81.100212224663778, 35.011615164423198],
              [-81.100160466449566, 35.011880002896582],
              [-81.099833052908721, 35.011838136227126],
              [-81.099781292251379, 35.012102975452784],
              [-81.099453877987969, 35.012061106854048],
              [-81.099402115959677, 35.012325945911563],
              [-81.099074701019958, 35.012284077185988],
              [-81.099022937597724, 35.01254891517415],
              [-81.098695521958447, 35.012507045420534],
              [-81.098643757165334, 35.012771883240497],
              [-81.098316340826685, 35.012730012458888],
              [-81.098212808186105, 35.013259688635053],
              [-81.097885390089587, 35.01321781578465],
              [-81.097730086589095, 35.014012328945441],
              [-81.096747821734112, 35.013886704765788],
              [-81.096799593381718, 35.013621867786618],
              [-81.09614475390191, 35.013538114469654],
              [-81.096196528379693, 35.013273277721694],
              [-81.094886858662477, 35.013105760061698],
              [-81.094938635826423, 35.012840922960606],
              [-81.093628976718051, 35.012673391621632],
              [-81.093680758782369, 35.012408555030724],
              [-81.093353346421864, 35.012366669879064],
              [-81.093405128113105, 35.012101834319225],
              [-81.092095485074495, 35.011934285403711],
              [-81.092043698147378, 35.012199120424583],
              [-81.091388877120622, 35.012115340346732],
              [-81.09133708781043, 35.012380175961177],
              [-81.091009676765722, 35.012338284449811],
              [-81.091061466015645, 35.012073448993853],
              [-81.09073405634193, 35.012031556733476],
              [-81.090785847386456, 35.011766721369256],
              [-81.090458439083733, 35.011724828359796],
              [-81.090510230827476, 35.011459993106435],
              [-81.090182823895702, 35.011418099347829],
              [-81.090234616338648, 35.011153264205284],
              [-81.089907210777938, 35.01111136969768],
              [-81.089959003920086, 35.01084653466588],
              [-81.089304196948675, 35.010762742343651],
              [-81.089355991825045, 35.010497907562176],
              [-81.089028589342149, 35.010456010667887],
              [-81.088976793430717, 35.010720845309756],
              [-81.088649390248563, 35.010678947387376],
              [-81.088597592966153, 35.010943781861002],
              [-81.087287979410533, 35.0107761789249],
              [-81.087339781928605, 35.010511344990448],
              [-81.087012380403038, 35.010469441723856],
              [-81.087064183620285, 35.010204607900249],
              [-81.085427188444058, 35.009995080701472],
              [-81.085478995404898, 35.009730247565912],
              [-81.08515159908363, 35.009688340129458],
              [-81.085099789992412, 35.009953173144389],
              [-81.083790201667355, 35.009785531288905],
              [-81.083738388100031, 35.010050363716864],
              [-81.081446613635052, 35.009756953715872],
              [-81.081394792486762, 35.010021785138029],
              [-81.081067395974415, 35.009979865737982],
              [-81.081015573455076, 35.010244696991883],
              [-81.08068817624374, 35.010202776563602],
              [-81.080584527031377, 35.010732438725015],
              [-81.080257128086217, 35.010690517128999],
              [-81.080205301393164, 35.010955348036489],
              [-81.079877901749029, 35.010913425412319],
              [-81.07982607366192, 35.011178255250194],
              [-81.079498674414353, 35.011136331578982],
              [-81.079446844979088, 35.011401162149731],
              [-81.078792043231445, 35.011317311900228],
              [-81.078740211367105, 35.011582141261741],
              [-81.07775800925711, 35.011456358785644],
              [-81.077809844203514, 35.011191528941659],
              [-81.077482445937861, 35.011149599799602],
              [-81.077534281583013, 35.010884770066504],
              [-81.076879487011638, 35.010800908513545],
              [-81.076224693807887, 35.010717044307725],
              [-81.076276534352317, 35.010452215085664],
              [-81.074312170045943, 35.010200599258539],
              [-81.074364016463875, 35.009935770845665],
              [-81.072727061348218, 35.009726066790577],
              [-81.072778912604548, 35.009461239047234],
              [-81.071796747327525, 35.00933540617946],
              [-81.071693037173702, 35.009865060818065],
              [-81.071365647343086, 35.009823114172022],
              [-81.071313791274576, 35.010087941299119],
              [-81.07098640074544, 35.010045993624821],
              [-81.070934542210253, 35.010310820602271],
              [-81.070607150982411, 35.010268871899868],
              [-81.07055529107609, 35.010533698708876],
              [-81.070227899149629, 35.010491748978247],
              [-81.070176037849151, 35.010756574717547],
              [-81.068866468294317, 35.01058876727101],
              [-81.068918333711594, 35.010323941189149],
              [-81.068590943466873, 35.010281987015965],
              [-81.068642809582627, 35.010017161045148],
              [-81.067005868578462, 35.009807377550018],
              [-81.066953997265344, 35.01007220192092],
              [-81.065971833594674, 35.009946320742657],
              [-81.065919957767861, 35.010211145585693],
              [-81.065592569492821, 35.010169182374945],
              [-81.065540693390105, 35.010434007030725],
              [-81.064885915802208, 35.010350078565509],
              [-81.064834036197936, 35.010614902931863],
              [-81.063851869246051, 35.010489003601698],
              [-81.063799986177898, 35.010753826618661],
              [-81.063145207837692, 35.010669889610746],
              [-81.063093322363358, 35.010934712319433],
              [-81.062765932663112, 35.010892742342939],
              [-81.062714044721929, 35.011157564901907],
              [-81.06238665432322, 35.011115593897131],
              [-81.062334766106147, 35.011380416268736],
              [-81.062007374986365, 35.011338443334481],
              [-81.061955485420668, 35.011603266438833],
              [-81.059336359270205, 35.011267452582963],
              [-81.059284459970769, 35.011532273657664],
              [-81.058302290520743, 35.01140632826246],
              [-81.058354192902044, 35.011141506705862],
              [-81.057699417028886, 35.011057539246664],
              [-81.0577513211433, 35.01079271794088],
              [-81.05709654868609, 35.010708747207381],
              [-81.057148454533589, 35.01044392615244],
              [-81.055838918847954, 35.01027597276164],
              [-81.055787008861174, 35.010540793257377],
              [-81.055132240397953, 35.010456810059551],
              [-81.055080328004905, 35.010721630246735],
              [-81.054752943254869, 35.010679637625913],
              [-81.054701028372207, 35.01094445676182],
              [-81.05437364292392, 35.01090246311275],
              [-81.054269811174549, 35.011432100999947],
              [-81.053942423993277, 35.011390106182645],
              [-81.053890506030982, 35.011654924952495],
              [-81.053563118151558, 35.011612929106853],
              [-81.053459280242805, 35.012142566261772],
              [-81.053131890630411, 35.012100569247956],
              [-81.053079969588367, 35.012365387651705],
              [-81.052752578182407, 35.0123233896282],
              [-81.052700655768817, 35.012588207863246],
              [-81.052373264737497, 35.012546207891461],
              [-81.052321340952346, 35.012811025957774],
              [-81.051666557875549, 35.012727024871673],
              [-81.051614631661337, 35.012991841728095],
              [-81.050632455348975, 35.01286583213308],
              [-81.050580525716114, 35.013130649442331],
              [-81.050253132527914, 35.013088644640028],
              [-81.050305064291038, 35.012823827451939],
              [-81.04997767356997, 35.01278182188242],
              [-81.049925740749302, 35.013046638029216],
              [-81.049598349330068, 35.013004631431244],
              [-81.049546415160393, 35.013269448310716],
              [-81.048891631240082, 35.013185431268226],
              [-81.04883969464133, 35.013450246937737],
              [-81.048184909998554, 35.013366226061535],
              [-81.048132971015932, 35.013631042323716],
              [-81.047805578153572, 35.01358902996234],
              [-81.04775363777668, 35.013853845154358],
              [-81.047426243143505, 35.013811832684588],
              [-81.047270416909868, 35.014606278568941],
              [-81.046943020581807, 35.014564263871044],
              [-81.046631351669788, 35.016153152235987],
              [-81.046303948396385, 35.016111136730061],
              [-81.046252000428211, 35.016375950330648],
              [-81.045269790575162, 35.016249896240758],
              [-81.045217840283541, 35.016514710275302],
              [-81.044890436265462, 35.016472690034213],
              [-81.04456303260676, 35.016430669805807],
              [-81.044614986063706, 35.016165856032515],
              [-81.043305379965645, 35.01599776407118],
              [-81.043357337225146, 35.015732950828578],
              [-81.042702538803923, 35.015648899336846],
              [-81.042754497796196, 35.015384086345236],
              [-81.042099702792584, 35.015300031579379],
              [-81.042151663540125, 35.015035219740057],
              [-81.04116947719163, 35.014909130436813],
              [-81.041221440684154, 35.014644318087093],
              [-81.040894047316073, 35.014602286976569],
              [-81.040946011506435, 35.014337474737893],
              [-81.040618619510155, 35.014295442878826],
              [-81.040670584398342, 35.014030630751293],
              [-81.04034319377385, 35.013988598143705],
              [-81.040447125681808, 35.013458973161761],
              [-81.040119737463897, 35.013416939945728],
              [-81.040223668997953, 35.012887315146649],
              [-81.039568898807772, 35.012803246590451],
              [-81.039620866127592, 35.012538433976914],
              [-81.039293482024959, 35.012496398515395],
              [-81.039345451138018, 35.012231585994357],
              [-81.039018068407131, 35.012189549784367],
              [-81.039070038217943, 35.011924737374514],
              [-81.038742656858801, 35.011882700416002],
              [-81.038794627367324, 35.011617888117243],
              [-81.038139868802503, 35.011533810895116],
              [-81.038087896224539, 35.011798622913915],
              [-81.037105758337447, 35.011672499537603],
              [-81.037053782318381, 35.011937311107609],
              [-81.036399022985037, 35.01185322444119],
              [-81.036347044559392, 35.012118035702265],
              [-81.035692284504947, 35.012033945202113],
              [-81.035640303650425, 35.012298755252999],
              [-81.031711759156309, 35.011794133239938],
              [-81.031763753500314, 35.011529323949588],
              [-81.029144762328883, 35.011192837463128],
              [-81.029196764612877, 35.010928029264306],
              [-81.028542022535177, 35.010843899034171],
              [-81.028594027646818, 35.010579091068237],
              [-81.027611920140899, 35.010452888579081],
              [-81.027663926923594, 35.010188081022939],
              [-81.026681826652151, 35.010061870940021],
              [-81.026733837296973, 35.009797063756871],
              [-81.026406471868839, 35.009754991497807],
              [-81.026458483210988, 35.009490184425907],
              [-81.025803756553572, 35.009406038405835],
              [-81.025855769627611, 35.009141231585254],
              [-81.024546324498274, 35.008972929445136],
              [-81.024598341350853, 35.008708122254781],
              [-81.023616264685657, 35.00858188628245],
              [-81.023668284326988, 35.008317080384955],
              [-81.023340927505416, 35.008274999784327],
              [-81.023288905734091, 35.008539805560112],
              [-81.020670052883332, 35.008203129493026],
              [-81.02072208290852, 35.007938323937104],
              [-81.020067375674927, 35.007854145857415],
              [-81.020119407454018, 35.007589341454278],
              [-81.019464703616649, 35.007505159200271],
              [-81.019516737127319, 35.00724035504863],
              [-81.018534688111956, 35.007114075857992],
              [-81.018482650424616, 35.007378880508774],
              [-81.018155300035488, 35.007336785834909],
              [-81.017827951079113, 35.007294690254504],
              [-81.017723871627737, 35.007824297989231],
              [-81.017396519844411, 35.007782201258443],
              [-81.017188352732077, 35.008841414919836],
              [-81.016860997148243, 35.008799316739953],
              [-81.016808952938703, 35.00906411972678],
              [-81.01648159665794, 35.009022020518408],
              [-81.016377506253008, 35.009551626106784],
              [-81.016050148240524, 35.009509525729726],
              [-81.015946053321187, 35.01003913094047],
              [-81.015618693554984, 35.009997028493402],
              [-81.015514595216615, 35.010526633308181],
              [-81.015187233741145, 35.010484530593672],
              [-81.015135183008866, 35.010749331916223],
              [-81.01480781974098, 35.010707228191372],
              [-81.014755767658897, 35.010972030246194],
              [-81.014428404767159, 35.010929924573176],
              [-81.014376351313118, 35.011194726458868],
              [-81.013721622304402, 35.011110513105287],
              [-81.013669566443653, 35.011375314681658],
              [-81.013342201400064, 35.011333206081467],
              [-81.013290144167215, 35.011598007488672],
              [-81.012962778426655, 35.011555897859907],
              [-81.01291071982169, 35.01182069909791],
              [-81.012583353384073, 35.011778588440649],
              [-81.012427171300232, 35.012572989775983],
              [-81.012099802118755, 35.012530878711011],
              [-81.012047740072234, 35.012795678651464],
              [-81.011393000630747, 35.012711452674559],
              [-81.011340935081904, 35.012976252323881],
              [-81.010686194921817, 35.01289202251305],
              [-81.010634126966238, 35.013156821852995],
              [-81.009979387160897, 35.013072587288583],
              [-81.009927316798553, 35.013337386319087],
              [-81.009599945842254, 35.013295268474522],
              [-81.009443728507904, 35.014089664088175],
              [-81.009116354785476, 35.014047544934478],
              [-81.008960132381787, 35.014841938946908],
              [-81.008305378624854, 35.014757696249873],
              [-81.008201224536364, 35.015287291852481],
              [-81.007873845536366, 35.015245168449418],
              [-81.007821766939827, 35.015509965616239],
              [-81.007494387265098, 35.015467842085769],
              [-81.007442307296259, 35.015732639083289],
              [-81.007114927998117, 35.015690513604689],
              [-81.007062845561407, 35.01595531045114],
              [-81.006735464492991, 35.0159131848634],
              [-81.006683381779482, 35.016177981522333],
              [-81.006355999991968, 35.01613585400456],
              [-81.006095572898602, 35.017459836216709],
              [-81.006422958741425, 35.017501963552654],
              [-81.006162527237308, 35.018825943954553],
              [-81.006489920804611, 35.018868071968356],
              [-81.006437834292711, 35.019132867564529],
              [-81.006765228136871, 35.019174994848349],
              [-81.006713142321871, 35.019439790555673],
              [-81.007040538634001, 35.019481917073051],
              [-81.006988453515945, 35.01974671289156],
              [-81.00764324810531, 35.019830962658446],
              [-81.007539082090332, 35.020360554750809],
              [-81.007866481424358, 35.020402679041815],
              [-81.007814399492759, 35.020667475175379],
              [-81.008141800177114, 35.020709597816939],
              [-81.007829304394264, 35.022298374000947],
              [-81.008156712720435, 35.0223404965772],
              [-81.00784420982454, 35.02392926986645],
              [-81.008171624697326, 35.02397139239573],
              [-81.008015372867348, 35.024765778147156],
              [-81.008342791182315, 35.0248079002086],
              [-81.008238623311229, 35.025337490849935],
              [-81.008566045129342, 35.025379612285157],
              [-81.008513961174188, 35.025644407711226],
              [-81.008461876859002, 35.025909202206869],
              [-81.008789301084704, 35.025951323034022],
              [-81.008685134628664, 35.026480913100897],
              [-81.009012561283981, 35.026523034221228],
              [-81.008595889042851, 35.028641390852513],
              [-81.00892332538892, 35.028683511286552],
              [-81.00887124046271, 35.02894830588064],
              [-81.009198678181519, 35.028990425566406],
              [-81.009146593952465, 35.02925522027148],
              [-81.008819155198694, 35.02921310044546],
              [-81.008714984730915, 35.029742688567431],
              [-81.008387544245053, 35.029700567572441],
              [-81.0083354580063, 35.029965360989422],
              [-81.008008016823268, 35.02992323896563],
              [-81.007955928138074, 35.030188033132575],
              [-81.007628486258, 35.030145910079987],
              [-81.007576396177825, 35.03041070317601],
              [-81.006921512434744, 35.030326453205014],
              [-81.00686941996895, 35.030591246892534],
              [-81.00654197703237, 35.030549120893724],
              [-81.006489883171668, 35.030813913510308],
              [-81.005834996220031, 35.030729657665091],
              [-81.005730802249246, 35.031259242267438],
              [-81.005403357221454, 35.031217113182031],
              [-81.005351259242076, 35.031481905289816],
              [-81.005023813517113, 35.031439775175592],
              [-81.004971714164853, 35.031704567113657],
              [-81.004644267720749, 35.031662435069371],
              [-81.004383757406387, 35.032986392770816],
              [-81.004056305051563, 35.032944260056041],
              [-81.003743674812, 35.034533004968679],
              [-81.004071133399279, 35.034575139426281],
              [-81.003810603313767, 35.03589909192133],
              [-81.00348313955125, 35.035856956762458],
              [-81.003431031265976, 35.036121747036354],
              [-81.003103566828372, 35.036079611749848],
              [-81.002999348247513, 35.036609191009163],
              [-81.002671880981964, 35.036567054571663],
              [-81.002567657883006, 35.037096633450567],
              [-81.002240189958968, 35.037054494924426],
              [-81.002188076878426, 35.03731928462998],
              [-81.001860608279316, 35.03727714597612],
              [-81.001808493803537, 35.037541934610651],
              [-81.001481023389502, 35.037499794044791],
              [-81.001428907562669, 35.037764583410677],
              [-81.001101437547305, 35.037722441797712],
              [-81.001049319251578, 35.037987231012075],
              [-81.000721848539129, 35.037945088370115],
              [-81.000669729943894, 35.038209876495102],
              [-81.00034225746073, 35.038167733743727],
              [-81.00023801608998, 35.03869730964275],
              [-80.999910542970468, 35.038655165703886],
              [-80.999858420194926, 35.038919953477823],
              [-80.999530945260332, 35.038877807626925],
              [-80.999478822207521, 35.03914259521283],
              [-80.998496397444924, 35.039016152790325],
              [-80.998548524699061, 35.038751365607169],
              [-80.997893578990784, 35.038667066790424],
              [-80.997945707976967, 35.038402279858332],
              [-80.997618237202062, 35.038360128797549],
              [-80.997566107180674, 35.038624915589409],
              [-80.996911163501139, 35.038540611440794],
              [-80.996859031071409, 35.038805397922374],
              [-80.996531558144738, 35.038763243933204],
              [-80.996479424341842, 35.039028030244907],
              [-80.99615195181407, 35.038985875208631],
              [-80.995839132352472, 35.04057458986739],
              [-80.995511653974233, 35.040532434001733],
              [-80.995459514615874, 35.040797218942402],
              [-80.995132034422667, 35.04075506116461],
              [-80.995079894808896, 35.041019846818592],
              [-80.994752413918818, 35.040977688011729],
              [-80.994700271813755, 35.041242472612481],
              [-80.993717829163117, 35.04111599042082],
              [-80.993665683636479, 35.041380775472227],
              [-80.993338202028909, 35.041338612830017],
              [-80.993286055106864, 35.041603396810139],
              [-80.99295857280238, 35.041561233138921],
              [-80.992697827942322, 35.042885151893053],
              [-80.992370340800264, 35.042842986631378],
              [-80.992318189778217, 35.043107770152837],
              [-80.991990700843417, 35.043065603880194],
              [-80.991782088091867, 35.044124735323301],
              [-80.99145459645041, 35.044082567582429],
              [-80.991350285426506, 35.044612132854148],
              [-80.99167778023434, 35.044654300857644],
              [-80.99162562578708, 35.044919083129557],
              [-80.991953120872395, 35.044961250402864],
              [-80.991640191104011, 35.046549944288458],
              [-80.991967693835178, 35.04659211149707],
              [-80.991863383985091, 35.047121675254907],
              [-80.991535879205344, 35.047079508666975],
              [-80.991483721630615, 35.047344289928276],
              [-80.991811227467551, 35.047386457557991],
              [-80.991759071707733, 35.047651239813135],
              [-80.992086578896291, 35.047693405793034],
              [-80.991982268410226, 35.048222969593454],
              [-80.992309778029806, 35.0482651358667],
              [-80.992257623303558, 35.048529917411877],
              [-80.992585134296775, 35.048572082936673],
              [-80.992480825921973, 35.049101647119642],
              [-80.992808340398085, 35.04914381111697],
              [-80.992756185631649, 35.049408592871536],
              [-80.993083701503423, 35.049450757021738],
              [-80.993031548530013, 35.049715538868696],
              [-80.993359065775479, 35.049757702270441],
              [-80.993202606813227, 35.050552047170335],
              [-80.992875086483664, 35.050509884248953],
              [-80.992770777469744, 35.051039446773125],
              [-80.992443254289711, 35.050997281799063],
              [-80.99213031072351, 35.052585967849311],
              [-80.992457839020048, 35.052628133683513],
              [-80.992405681964541, 35.052892914271041],
              [-80.992078152632558, 35.052850748296407],
              [-80.991921674159499, 35.053645090395889],
              [-80.991594140963613, 35.053602923129581],
              [-80.99148981856942, 35.05413248349214],
              [-80.991817353814312, 35.054174650137846],
              [-80.991765192597072, 35.054439430423663],
              [-80.992092729237811, 35.054481597222164],
              [-80.992040569791726, 35.054746376699015],
              [-80.992368107806328, 35.054788542749023],
              [-80.992315947961259, 35.05505332235429],
              [-80.992971027054466, 35.0551376511672],
              [-80.992814553938302, 35.055931992411935],
              [-80.993142097087997, 35.055974155455409],
              [-80.992985624011041, 35.056768495950152],
              [-80.992658077754854, 35.056726332485674],
              [-80.992553758227174, 35.05725589179017],
              [-80.992226209120076, 35.057213726272884],
              [-80.992174047265138, 35.057478505748904],
              [-80.991846498579022, 35.057436340085616],
              [-80.991794335350122, 35.057701119391318],
              [-80.991466785944866, 35.057658951797492],
              [-80.991362456400637, 35.058188510038107],
              [-80.991034904188567, 35.058146342194121],
              [-80.990982737851212, 35.058411120227795],
              [-80.990655186038225, 35.058368951336512],
              [-80.99018566451808, 35.060751953728726],
              [-80.990513226769494, 35.060794124766311],
              [-80.990461057047753, 35.061058902499575],
              [-80.990788620651372, 35.061101071887279],
              [-80.990632112561954, 35.061895405327576],
              [-80.990959680707121, 35.061937574229304],
              [-80.990855342359922, 35.062467129986366],
              [-80.99118291291478, 35.062509298279892],
              [-80.99107857526306, 35.063038853295922],
              [-80.991406148227739, 35.063081020981123],
              [-80.991353979940897, 35.06334579903492],
              [-80.992009127860925, 35.063430132037276],
              [-80.991956962403137, 35.063694910323598],
              [-80.992284538454669, 35.063737075622576],
              [-80.992075872931068, 35.064796186396151],
              [-80.991748292736744, 35.064754020535865],
              [-80.991591786894404, 35.065548351301182],
              [-80.991264203931323, 35.065506184130854],
              [-80.990794657686109, 35.067889172650951],
              [-80.993743010303831, 35.068268656383168],
              [-80.993690846260236, 35.068533434395789],
              [-80.994673640740359, 35.068659912472377],
              [-80.994621479443836, 35.068924689974338],
              [-80.996587084875131, 35.069177623848468],
              [-80.996534929433409, 35.069442401260751],
              [-80.997517740362269, 35.06956885615682],
              [-80.997465588808154, 35.069833634842766],
              [-80.998120799484951, 35.06991793364773],
              [-80.998016499505056, 35.070447491490036],
              [-80.998671716775405, 35.070531787281659],
              [-80.998723864862725, 35.070267008095158],
              [-80.99905147186216, 35.070309154084669],
              [-80.999155764971988, 35.069779596241609],
              [-80.999810976938605, 35.06986388497409],
              [-80.999863120925113, 35.069599106177407],
              [-81.000190725831928, 35.069641249078984],
              [-81.0001385839996, 35.069906028899076],
              [-81.000466190280633, 35.069948171051827],
              [-81.000414049145903, 35.070212950981926],
              [-81.000741657897379, 35.070255092367688],
              [-81.000689516364062, 35.070519872425955],
              [-81.001344736953939, 35.070604152810681],
              [-81.001292598250387, 35.070868933100982],
              [-81.001620210088788, 35.070911072100017],
              [-81.00167234777841, 35.0706462925707],
              [-81.001999958919157, 35.070688430540422],
              [-81.001947822265407, 35.070953210209957],
              [-81.002603047655725, 35.071037484663762],
              [-81.002550911639403, 35.071302264601684],
              [-81.003533756433001, 35.071428668664836],
              [-81.003481624304669, 35.07169344987625],
              [-81.003809240583024, 35.07173558293205],
              [-81.00354857995876, 35.073059485630765],
              [-81.003876202851757, 35.073101618480301],
              [-81.003719805512716, 35.073895959615832],
              [-81.003392179511721, 35.073853826345655],
              [-81.003287910855448, 35.074383386670739],
              [-81.003615538928642, 35.074425520221389],
              [-81.003563405129142, 35.074690300478622],
              [-81.003891034554471, 35.074732432379037],
              [-81.003838901452824, 35.074997212746155],
              [-81.004166532274652, 35.075039344798952],
              [-81.00411439984866, 35.075304124374568],
              [-81.004442032044849, 35.075346255678461],
              [-81.004389900338793, 35.075611036265251],
              [-81.004717533909357, 35.075653166820096],
              [-81.004665402878899, 35.075917946615554],
              [-81.005320674181363, 35.076002204418721],
              [-81.005268544906983, 35.076266985365493],
              [-81.005596181542529, 35.076309112631947],
              [-81.005544052965988, 35.076573893688547],
              [-81.00587169207229, 35.076616020187814],
              [-81.005819564193629, 35.076880801354179],
              [-81.005663179558056, 35.077675141948987],
              [-81.005990823147187, 35.077717268119919],
              [-81.005834438575576, 35.078511608861724],
              [-81.006162084515097, 35.078553734582201],
              [-81.006057828880586, 35.079083295199013],
              [-81.006385478327061, 35.079125420292392],
              [-81.006333350490337, 35.079390200704502],
              [-81.006661001311372, 35.079432325048849],
              [-81.006556747769878, 35.079961885142517],
              [-81.005901442299162, 35.079877634102587],
              [-81.005849312411399, 35.080142414283159],
              [-81.005521659136136, 35.080100286838565],
              [-81.005469527851801, 35.080365065947284],
              [-81.00481421916956, 35.080280809029496],
              [-81.004762085496523, 35.080545588728718],
              [-81.004434431163759, 35.080503458336082],
              [-81.004382296094136, 35.080768236963323],
              [-81.004054641085673, 35.080726106442597],
              [-81.003950366762382, 35.081255663343455],
              [-81.0036227099976, 35.081213530751896],
              [-81.003570571840513, 35.081478309007281],
              [-81.003242913281412, 35.081436175404484],
              [-81.002982211120582, 35.082760064640254],
              [-81.003309873764493, 35.082802198962227],
              [-81.003257733577186, 35.08306697703474],
              [-81.003205593029364, 35.083331754175383],
              [-81.004188591379631, 35.083458151709536],
              [-81.0041364536023, 35.083722929240302],
              [-81.004464121804887, 35.083765060407686],
              [-81.004411984725593, 35.084029838048096],
              [-81.004739654280684, 35.084071967565123],
              [-81.004687517921511, 35.084336746216529],
              [-81.00501518885126, 35.08437887498448],
              [-81.004963053167813, 35.0846436528442],
              [-81.005290725494461, 35.084685781764378],
              [-81.005238590509009, 35.084950559733741],
              [-81.005893939302069, 35.085034813365908],
              [-81.005789672483758, 35.085564370674888],
              [-81.006117348912213, 35.085606496446481],
              [-81.006013082791128, 35.086136053012282],
              [-81.006340762727135, 35.086178178156771],
              [-81.006288630195513, 35.086442956534015],
              [-81.006616311506221, 35.086485080929329],
              [-81.006512047478409, 35.087014636971048],
              [-81.00683973120033, 35.087056760757449],
              [-81.006735467892014, 35.087586316957044],
              [-81.007063154025062, 35.087628440134473],
              [-81.006802496101187, 35.088952330330024],
              [-81.007130187754655, 35.08899445331884],
              [-81.006921659655063, 35.090053564038833],
              [-81.006593962759226, 35.090011440507624],
              [-81.006229016317505, 35.091864879434191],
              [-81.005901312504534, 35.091822754032464],
              [-81.005849174895872, 35.092087530244072],
              [-81.006176879745368, 35.092129655785889],
              [-81.006020467998781, 35.092923984656942],
              [-81.006348176296356, 35.092966109729872],
              [-81.006296039407218, 35.093230886098901],
              [-81.006623749079736, 35.093273010422685],
              [-81.006571612888777, 35.093537786901209],
              [-81.006899323936139, 35.093579910475704],
              [-81.006847188443459, 35.093844687063658],
              [-81.006519476359443, 35.093802563348937],
              [-81.006467339491735, 35.094067339766013],
              [-81.006139625613017, 35.094025215039977],
              [-81.006035348789325, 35.094554767501485],
              [-81.005707633175859, 35.09451264160576],
              [-81.005655493197608, 35.094777416748954],
              [-81.005327777982487, 35.094735289805556],
              [-81.005275635554781, 35.09500006569727],
              [-81.00494791964158, 35.094957937724274],
              [-81.004895775816721, 35.095222712543794],
              [-81.005223493863213, 35.095264840638805],
              [-81.005171350758658, 35.095529616469044],
              [-81.005826789940031, 35.095613870271052],
              [-81.005722507914484, 35.096143420579004],
              [-81.006050230086004, 35.096185546426256],
              [-81.005998090150484, 35.096450321665131],
              [-81.006325813697146, 35.096492446763094],
              [-81.006221534884219, 35.097021997428669],
              [-81.00654926084249, 35.097064121917576],
              [-81.00644498165255, 35.09759367275845],
              [-81.006772711119211, 35.097635796619905],
              [-81.006720571504914, 35.097900572143409],
              [-81.007048302346647, 35.09794269525559],
              [-81.006996164505182, 35.098207469968905],
              [-81.007323895625362, 35.098249592349987],
              [-81.007271758504359, 35.098514368073907],
              [-81.007599490999638, 35.098556489705771],
              [-81.007547354554745, 35.098821264637621],
              [-81.007875089521789, 35.098863385501957],
              [-81.007822953775104, 35.099128160543152],
              [-81.008478425701256, 35.099212399901788],
              [-81.008426291689688, 35.099477175192405],
              [-81.008754029745361, 35.099519293668536],
              [-81.008649762780337, 35.10004884443746],
              [-81.008977503247976, 35.100090962304378],
              [-81.008821104491716, 35.100885289529643],
              [-81.008493360913803, 35.10084317124231],
              [-81.008389090564904, 35.101372721702532],
              [-81.008061344177364, 35.101330603164982],
              [-81.008009207458556, 35.101595378208522],
              [-81.007681461447191, 35.10155325772191],
              [-81.007629323353186, 35.101818032594444],
              [-81.007301576643471, 35.101775911078214],
              [-81.007197296269808, 35.102305460468607],
              [-81.006869547825048, 35.102263337782617],
              [-81.006817406642085, 35.102528112282144],
              [-81.006489656402266, 35.102485988584654],
              [-81.006437513844048, 35.10275076291321],
              [-81.00610976400263, 35.102708638167869],
              [-81.006005474678304, 35.10323818556882],
              [-81.005677722027201, 35.103196060573218],
              [-81.005573429253175, 35.103725606668718],
              [-81.005245674866956, 35.103683480503385],
              [-81.005141378665442, 35.104213026194799],
              [-81.004813622521809, 35.104170897958269],
              [-81.004709322914792, 35.104700444146808],
              [-81.004381565036113, 35.104658314740554],
              [-81.004277260882361, 35.105187859641767],
              [-81.003949501268536, 35.105145729065612],
              [-81.003897347647026, 35.105410501329629],
              [-81.004225108297774, 35.105452632045953],
              [-81.003964341348905, 35.106776491780799],
              [-81.004292107522517, 35.106818622308296],
              [-81.004187800003734, 35.107348166092429],
              [-81.004515569686461, 35.107390295992559],
              [-81.004463416445091, 35.107655067527595],
              [-81.004791186406379, 35.107697196696463],
              [-81.004739033863459, 35.107961968340554],
              [-81.005066806274826, 35.108004095840592],
              [-81.005014654452438, 35.108268868495124],
              [-81.005342428239274, 35.108310995245688],
              [-81.00529027709301, 35.108575767108015],
              [-81.005618052277498, 35.108617894010429],
              [-81.005513751043352, 35.109147437922218],
              [-81.005841528618078, 35.109189563314033],
              [-81.005789379627004, 35.109454335345248],
              [-81.006117157502587, 35.109496460907138],
              [-81.006065009209848, 35.109761233047408],
              [-81.006392789535653, 35.109803356940326],
              [-81.00628849288961, 35.110332900524739],
              [-81.00661627565006, 35.11037502470969],
              [-81.006459830693601, 35.111169339831974],
              [-81.00678761690358, 35.111211463547626],
              [-81.006683321020262, 35.111741007381596],
              [-81.007011110717386, 35.111783129568373],
              [-81.006906814435069, 35.112312672674946],
              [-81.007234606566968, 35.11235479515372],
              [-81.007078163812452, 35.11314910998221],
              [-81.007405959371965, 35.113191231090354],
              [-81.007249517778732, 35.113985546040581],
              [-81.007577316787902, 35.114027666679519],
              [-81.007368725533368, 35.115086751631601],
              [-81.007696530126267, 35.115128871923019],
              [-81.007592233993549, 35.115658414501333],
              [-81.007920040999423, 35.11570053418334],
              [-81.007867893473431, 35.115965306016435],
              [-81.008195701854916, 35.116007424948926],
              [-81.008143555005418, 35.116272195989573],
              [-81.008799174858368, 35.116356431465682],
              [-81.008747029744555, 35.11662120275539],
              [-81.009074841215934, 35.116663319299001],
              [-81.009022697897677, 35.116928090679316],
              [-81.00935050964793, 35.11697020649158],
              [-81.009298367028322, 35.117234977980779],
              [-81.009626181251093, 35.117277093025272],
              [-81.009574039330246, 35.117541864623242],
              [-81.009901854928728, 35.117583978918127],
              [-81.009849712609636, 35.117848750643184],
              [-81.010177529583828, 35.11789086418846],
              [-81.009968965414174, 35.11894994946114],
              [-81.010296786875841, 35.118992062677059],
              [-81.010192504834336, 35.119521605405943],
              [-81.010520328709006, 35.119563718012309],
              [-81.01046818820663, 35.119828489019319],
              [-81.010796013456968, 35.119870600875956],
              [-81.010639593052829, 35.120664915030645],
              [-81.010311763593734, 35.120622802771962],
              [-81.010259621737262, 35.120887573653782],
              [-81.010207479542373, 35.121152344504289],
              [-81.009223986994613, 35.121026002450705],
              [-81.009171841349328, 35.121290772849633],
              [-81.008516179254258, 35.121206539484191],
              [-81.008464031195743, 35.121471309571547],
              [-81.008136199641015, 35.121429192315382],
              [-81.008084049087486, 35.121693961348335],
              [-81.007756217931032, 35.12165184304417],
              [-81.007704066001622, 35.121916611905611],
              [-81.007376233027315, 35.121874491688544],
              [-81.007324079744308, 35.12213926127982],
              [-81.006996246071211, 35.122097140032899],
              [-81.006891936414675, 35.122626678841321],
              [-81.006564101005594, 35.122584556424364],
              [-81.006511944609983, 35.122849324740145],
              [-81.006184108502225, 35.122807201293391],
              [-81.006131950752845, 35.123071970338955],
              [-81.005804113946439, 35.12302984586232],
              [-81.005751954798924, 35.1232946138351],
              [-81.005096280127518, 35.12321036193228],
              [-81.005148441349718, 35.122945594239773],
              [-81.003837100220053, 35.122777080317576],
              [-81.003889265230598, 35.122512312252901],
              [-81.002905767126251, 35.12238591696989],
              [-81.002853597906892, 35.122650684632426],
              [-81.000558772135577, 35.122355730508332],
              [-81.000506595294524, 35.122620496257127],
              [-80.999850932536759, 35.122536214963077],
              [-80.999903111452213, 35.122271449494619],
              [-80.99957528105918, 35.122229307211668],
              [-80.999523101128474, 35.12249407344116],
              [-80.998211779936312, 35.122325496635582],
              [-80.998263964015862, 35.122060730966801],
              [-80.997936136413756, 35.122018584217017],
              [-80.99798832121391, 35.121753819558265],
              [-80.996021366767692, 35.121500922154418],
              [-80.995969174669369, 35.121765686891429],
              [-80.995313523450562, 35.121681380064274],
              [-80.99526132893898, 35.121946144489556],
              [-80.994933502252238, 35.121903989610274],
              [-80.994776913550709, 35.122698281375584],
              [-80.994449084091201, 35.122656125185934],
              [-80.994344687825304, 35.123185651390358],
              [-80.994016856630154, 35.123143494030579],
              [-80.99380805371068, 35.124202546439008],
              [-80.993480218705599, 35.124160387628663],
              [-80.993428016634141, 35.124425150277638],
              [-80.9937558526765, 35.12446730922823],
              [-80.99349484020577, 35.125791121837324],
              [-80.993822681773395, 35.125833280599366],
              [-80.993666073524295, 35.126627566942517],
              [-80.993338227747628, 35.126585407777924],
              [-80.993233818120544, 35.127114931875333],
              [-80.992905970608305, 35.127072771540554],
              [-80.992801557528637, 35.127602294330238],
              [-80.992145859393773, 35.127517971331692],
              [-80.992198068704155, 35.127253209322802],
              [-80.991870222290558, 35.127211047061671],
              [-80.991818011920756, 35.127475808029118],
              [-80.991490163689775, 35.127433643854793],
              [-80.991542375096884, 35.12716888302765],
              [-80.990886682823486, 35.127084552272152],
              [-80.990938895944126, 35.126819790792631],
              [-80.989955363736996, 35.126693288397909],
              [-80.989903146385444, 35.126958048573364],
              [-80.989575302322777, 35.126915879182711],
              [-80.989523084691953, 35.127180639168344],
              [-80.988867394412182, 35.127096297455232],
              [-80.988919614117663, 35.126831537750114],
              [-80.987608241753861, 35.126662843306526],
              [-80.987556017899365, 35.12692760245065],
              [-80.986244646811755, 35.126758893210074],
              [-80.986192417371953, 35.127023651779623],
              [-80.985864574942511, 35.126981471419811],
              [-80.985812344148385, 35.127246230718846],
              [-80.985484501020807, 35.127204049328967],
              [-80.985432268828333, 35.127468807554898],
              [-80.985104423927595, 35.127426626054422],
              [-80.98505219035863, 35.127691384108431],
              [-80.984724344737813, 35.127649200676586],
              [-80.984672109792442, 35.127913958558779],
              [-80.98434426459248, 35.127871774980179],
              [-80.984292027173609, 35.128136532708631],
              [-80.983964181253597, 35.128094347198555],
              [-80.98391194355537, 35.12835910473715],
              [-80.983584095840186, 35.128316918215063],
              [-80.983531855668417, 35.128581675599804],
              [-80.983204008352203, 35.12853948802961],
              [-80.983151766803971, 35.128804245242492],
              [-80.982823918811562, 35.128762057543547],
              [-80.982771675864939, 35.129026813683232],
              [-80.982443826077358, 35.128984624972318],
              [-80.982391582851335, 35.129249380922033],
              [-80.98206373236566, 35.129207191181024],
              [-80.982011486666039, 35.129471946976857],
              [-80.981355785712921, 35.129387563625528],
              [-80.981303537599501, 35.129652319109191],
              [-80.980319983945549, 35.129525736564275],
              [-80.980267732381094, 35.129790491595379],
              [-80.979612029574071, 35.129706098803076],
              [-80.979507521278109, 35.130235608209126],
              [-80.979179668298016, 35.130193409746951],
              [-80.979127412625658, 35.130458165163517],
              [-80.978799558947458, 35.13041596567124],
              [-80.978747300779631, 35.130680720032515],
              [-80.978419446403521, 35.1306385195101],
              [-80.978314929169429, 35.131168027821111],
              [-80.977987071960825, 35.131125826146267],
              [-80.977934811797539, 35.131390580114001],
              [-80.977606954988048, 35.131348377390985],
              [-80.977554692351021, 35.131613131204702],
              [-80.977226834843535, 35.131570927451641],
              [-80.977174570829831, 35.131835681093264],
              [-80.976846712624365, 35.13179347630998],
              [-80.976689916510423, 35.132587735704874],
              [-80.976362054435185, 35.132545529628672],
              [-80.976309786989432, 35.132810282863012],
              [-80.976637650101907, 35.132852489079546],
              [-80.976585383354106, 35.133117242422585],
              [-80.976913246724308, 35.133159447006406],
              [-80.976599646344368, 35.134747963618182],
              [-80.976927517397286, 35.134790169037707],
              [-80.976822982916872, 35.135319674007242],
              [-80.977150856383872, 35.135361878817612],
              [-80.977046323717929, 35.135891383923024],
              [-80.97737419959914, 35.135933588124246],
              [-80.977321933224047, 35.13619833987741],
              [-80.977649810482163, 35.136240543329187],
              [-80.977597544826992, 35.136505296092203],
              [-80.977925424559004, 35.136547498776523],
              [-80.977873159580142, 35.136812250746893],
              [-80.979184683835499, 35.136981052281818],
              [-80.979132422667121, 35.137245804781799],
              [-80.97946030617085, 35.137288003297535],
              [-80.97940804572238, 35.137552756807402],
              [-80.979735930581057, 35.13759495367232],
              [-80.979683670830838, 35.137859707290758],
              [-80.980011557066334, 35.13790190340621],
              [-80.979854781042604, 35.138696162653211],
              [-80.979526891672705, 35.138653965215475],
              [-80.979474630543223, 35.138918717805495],
              [-80.979802520950642, 35.138960915383571],
              [-80.979593479383453, 35.140019925950924],
              [-80.979265584825896, 35.139977727811555],
              [-80.978899741162678, 35.141830992303362],
              [-80.978571838584301, 35.141788792309733],
              [-80.978415039900852, 35.142583046596897],
              [-80.978087134570941, 35.14254084619364],
              [-80.97798259759702, 35.143070348022761],
              [-80.97765469050934, 35.143028145547667],
              [-80.977550150102502, 35.143557646968539],
              [-80.977222241300964, 35.143515444224157],
              [-80.977169969550744, 35.143780194746363],
              [-80.976842060029156, 35.143737990070399],
              [-80.976737513435182, 35.144267490738514],
              [-80.97640960217781, 35.144225284891888],
              [-80.975886833943065, 35.146872779577066],
              [-80.975558912670394, 35.146830572338239],
              [-80.975402071562655, 35.147624818632323],
              [-80.975074146397304, 35.147582609199091],
              [-80.974917300218223, 35.148376854766177],
              [-80.974589372279056, 35.148334644021979],
              [-80.974537087771068, 35.148599392351855],
              [-80.974209159133707, 35.148557180577292],
              [-80.974104587001762, 35.149086675959154],
              [-80.973776656650401, 35.149044463915104],
              [-80.97356750413897, 35.150103452831566],
              [-80.973239569976187, 35.150061239336026],
              [-80.973187279956136, 35.150325986119363],
              [-80.97285934507326, 35.150283770692241],
              [-80.972807053675965, 35.150548517303164],
              [-80.972479117019446, 35.150506301764793],
              [-80.972426824244693, 35.150771048203246],
              [-80.972098887965572, 35.150728830715295],
              [-80.971994298224416, 35.151258323233137],
              [-80.971666360209284, 35.151216104574445],
              [-80.971614063242853, 35.151480850653826],
              [-80.971942003393067, 35.151523069434916],
              [-80.971628223937316, 35.15311154422298],
              [-80.971956170653996, 35.153153762956435],
              [-80.971903874461262, 35.153418508172535],
              [-80.9722318225555, 35.153460726156339],
              [-80.972127230151301, 35.15399021769219],
              [-80.972455181736379, 35.154032434147467],
              [-80.972402885503016, 35.154297179565845],
              [-80.972074933977495, 35.154254962952344],
              [-80.972022637464079, 35.154519708180366],
              [-80.971694684121118, 35.154477489652962],
              [-80.971642386252057, 35.154742235609739],
              [-80.970986479606708, 35.154657795586225],
              [-80.970934179300485, 35.154922540328783],
              [-80.970606224922477, 35.154880319751776],
              [-80.970553923238697, 35.155145064321779],
              [-80.970225968140809, 35.155102841813097],
              [-80.969650605818217, 35.158015024970034],
              [-80.969978572333048, 35.158057249023081],
              [-80.96982165067557, 35.158851478826776],
              [-80.969493681046899, 35.158809254352576],
              [-80.969127511334605, 35.160662454214744],
              [-80.968799533704129, 35.160620228786918],
              [-80.968747221329537, 35.160884970582352],
              [-80.969075200019773, 35.160927197051919],
              [-80.969022887245998, 35.161191938973225],
              [-80.968694908636934, 35.161149713246743],
              [-80.968642594485374, 35.161414454995345],
              [-80.968314615178173, 35.161372228238349],
              [-80.968209984877106, 35.161901711340022],
              [-80.967882002736033, 35.161859483429943],
              [-80.967829686586612, 35.162124224783],
              [-80.967501703725659, 35.16208199494104],
              [-80.967135469508307, 35.163935182595409],
              [-80.966807479742599, 35.16389295178174],
              [-80.966650513673926, 35.164687173259288],
              [-80.966322520014543, 35.164644940250881],
              [-80.966270195915243, 35.164909680844609],
              [-80.966598190590986, 35.164951913092153],
              [-80.966545867190007, 35.165216653793891],
              [-80.966217872552008, 35.1651744204868],
              [-80.966165547772988, 35.165439161015755],
              [-80.965837551339192, 35.165396926695855],
              [-80.965628245704579, 35.166455885204016],
              [-80.965300245458124, 35.166413649432378],
              [-80.965247916881864, 35.166678388617115],
              [-80.964919915959015, 35.166636152716066],
              [-80.964867586004658, 35.166900891727934],
              [-80.96453958436183, 35.166858653894977],
              [-80.964382588247403, 35.167652870332461],
              [-80.964054583830119, 35.167610631188055],
              [-80.964002250439918, 35.167875369789243],
              [-80.963674245324327, 35.167833129614216],
              [-80.963517238880286, 35.168627343918295],
              [-80.963845247110243, 35.168669584514554],
              [-80.963740576151011, 35.169199060541217],
              [-80.96341256584472, 35.169156819664032],
              [-80.963255553182009, 35.169951032597282],
              [-80.96292754012282, 35.169908791309879],
              [-80.962822860491954, 35.170438265566304],
              [-80.962494845674641, 35.170396022206511],
              [-80.96233782166145, 35.171190232908067],
              [-80.962285479277824, 35.171454969749469],
              [-80.961957459550035, 35.171412724955509],
              [-80.961800428342286, 35.172206934845121],
              [-80.961472405839814, 35.172164688739684],
              [-80.961420060019691, 35.172429425169959],
              [-80.961092036819011, 35.172387178033723],
              [-80.96103968959892, 35.172651913389679],
              [-80.960055617846294, 35.172525167137429],
              [-80.960003267171544, 35.172789902039369],
              [-80.959675243254566, 35.172747651061336],
              [-80.959622891223191, 35.173012386691589],
              [-80.959294866607934, 35.172970134682764],
              [-80.959242512078887, 35.173234869256476],
              [-80.95891448676548, 35.173192616217015],
              [-80.958390917897034, 35.175839959567966],
              [-80.958718954690923, 35.175882213994306],
              [-80.958404802120242, 35.177470616955098],
              [-80.958076759096471, 35.177428361685891],
              [-80.95797203601451, 35.177957827607152],
              [-80.957643991254201, 35.177915571166793],
              [-80.957382173524167, 35.179239235578784],
              [-80.957054122814398, 35.179196977563542],
              [-80.956949390816703, 35.179726442464869],
              [-80.9566213394681, 35.179684183260548],
              [-80.956568971920532, 35.179948915521656],
              [-80.956897024307622, 35.179991174866458],
              [-80.956739922695803, 35.180785370072094],
              [-80.956411867215209, 35.180743111207114],
              [-80.956150018108843, 35.182066768843541],
              [-80.955821956656891, 35.182024507502362],
              [-80.955560092711721, 35.183348161831695],
              [-80.955888159355993, 35.183390423875274],
              [-80.955835787023801, 35.183655154776069],
              [-80.956163856144713, 35.183697416051999],
              [-80.95611148341294, 35.183962147078191],
              [-80.95643955389086, 35.184004406702968],
              [-80.956387182955197, 35.184269137819058],
              [-80.957043325954231, 35.184353656498722],
              [-80.957095694812807, 35.184088925101726],
              [-80.957751837095714, 35.184173439938903],
              [-80.95769947029244, 35.184438170715552],
              [-80.958027543531585, 35.184480426930094],
              [-80.957975177448375, 35.18474515871565],
              [-80.958303252044587, 35.184787413279032],
              [-80.958250886659727, 35.185052145172101],
              [-80.958578962656333, 35.185094399886857],
              [-80.958526597948065, 35.185359130986321],
              [-80.958854675323408, 35.185401384951035],
              [-80.958802311313576, 35.18566611615806],
              [-80.959130390067671, 35.185708369372826],
              [-80.959078027853977, 35.185973100669663],
              [-80.959406107986837, 35.186015353134522],
              [-80.959301382420421, 35.18654481594605],
              [-80.959629466068463, 35.186587067783492],
              [-80.959577103813615, 35.186851799280376],
              [-80.959905187720921, 35.186894049484359],
              [-80.95985282618615, 35.187158781990149],
              [-80.959524741218488, 35.187116530744369],
              [-80.9592105595801, 35.188704917918109],
              [-80.958882467644784, 35.188662665858416],
              [-80.958620635221564, 35.18998631917426],
              [-80.958948732350166, 35.190028571936161],
              [-80.958320308405618, 35.193205331787389],
              [-80.958648419458555, 35.193247586227656],
              [-80.958596049693512, 35.193512315238387],
              [-80.958924161005768, 35.193554568045023],
              [-80.958871791960874, 35.193819298064426],
              [-80.959199905750197, 35.193861550103236],
              [-80.959147537403823, 35.194126280230016],
              [-80.959475652572181, 35.194168531518798],
              [-80.95942328490257, 35.19443326085166],
              [-80.959751401471621, 35.194475512291596],
              [-80.959699034500659, 35.19474024173185],
              [-80.960027153525033, 35.194782491502657],
              [-80.959974787252619, 35.195047221050245],
              [-80.960631026225471, 35.195131719137919],
              [-80.960526297913049, 35.195661178677746],
              [-80.960854419976613, 35.195703426215999],
              [-80.960749692380929, 35.196232885904429],
              [-80.960421567141708, 35.196190638103147],
              [-80.960369201245612, 35.196455367766212],
              [-80.960041075307785, 35.196413118934053],
              [-80.959936341493574, 35.196942576960943],
              [-80.960264468432783, 35.196984826993052],
              [-80.960212101494363, 35.197249555655524],
              [-80.960540230889009, 35.197291804018377],
              [-80.960487864670782, 35.197556533689443],
              [-80.960815995444648, 35.197598781302261],
              [-80.96076362992504, 35.197863511080627],
              [-80.961091762077956, 35.197905757943182],
              [-80.961039397235297, 35.198170486927559],
              [-80.961367530767333, 35.19821273304003],
              [-80.96126280325899, 35.198742192073567],
              [-80.961590939208989, 35.198784437576279],
              [-80.961538575423504, 35.19904916674205],
              [-80.962194851520351, 35.199133655338613],
              [-80.962142489472384, 35.199398384752044],
              [-80.962798769007932, 35.199482870067051],
              [-80.962746408697711, 35.199747599728113],
              [-80.96340269167203, 35.199832081761507],
              [-80.963297975284661, 35.200361541527862],
              [-80.963626119909094, 35.200403781480453],
              [-80.963521404238804, 35.200933241394907],
              [-80.963849551259671, 35.200975479836423],
              [-80.963692477770536, 35.201769669889259],
              [-80.963364327610961, 35.201727430125274],
              [-80.963207249019234, 35.202521618538931],
              [-80.961566487761473, 35.202310405155266],
              [-80.961514121608857, 35.202575133269136],
              [-80.961185969356364, 35.202532888501089],
              [-80.961133601824486, 35.20279761644133],
              [-80.961461755137591, 35.202839862251082],
              [-80.961304653638805, 35.203634047194903],
              [-80.961632810409355, 35.203676292535107],
              [-80.961475710041512, 35.204470476681806],
              [-80.961803870269407, 35.204512721552568],
              [-80.961594401018743, 35.205571632966404],
              [-80.961922566841324, 35.205613877490336],
              [-80.961713096280619, 35.20667278803176],
              [-80.962041266599911, 35.206715032226562],
              [-80.961936532454132, 35.207244487560423],
              [-80.961608358958458, 35.20720224310265],
              [-80.961555989797802, 35.20746697103872],
              [-80.961884164310931, 35.207509214735644],
              [-80.961831795827308, 35.207773941877505],
              [-80.961503620275053, 35.20773169804022],
              [-80.961451250412068, 35.207996425008375],
              [-80.960794900424816, 35.207911933444095],
              [-80.960742528143143, 35.208176660098097],
              [-80.960414351511858, 35.208134412406629],
              [-80.960361977850695, 35.208399138886975],
              [-80.960033801618778, 35.208356890146604],
              [-80.959929050099035, 35.208886342744059],
              [-80.959600872150858, 35.208844093733461],
              [-80.959548494292235, 35.209108819850634],
              [-80.959220315623497, 35.209066568907652],
              [-80.959115557904994, 35.209596020743298],
              [-80.958787376400124, 35.209553768646543],
              [-80.958263554665109, 35.212201019044151],
              [-80.957935363130417, 35.212158765553909],
              [-80.957621045463299, 35.213747110312717],
              [-80.957292848033561, 35.213704855089127],
              [-80.957240459506878, 35.21396957820695],
              [-80.956912260258449, 35.213927321068731],
              [-80.956755089560914, 35.214721491602603],
              [-80.957083291927177, 35.214763749162053],
              [-80.957030900962053, 35.215028473064976],
              [-80.957359104686432, 35.215070728972826],
              [-80.957254325988075, 35.215600176022207],
              [-80.957582532153197, 35.215642432221379],
              [-80.957530143321847, 35.21590715538553],
              [-80.957858350866744, 35.215949410834341],
              [-80.957805962734156, 35.216214134105364],
              [-80.95813417165887, 35.216256388803878],
              [-80.958081784225143, 35.216521112181802],
              [-80.958409994529646, 35.216563366129812],
              [-80.958357607794895, 35.216828089614602],
              [-80.9586858205558, 35.216870341893262],
              [-80.958633434519939, 35.217135065484904],
              [-80.958961647584076, 35.217177317932133],
              [-80.958856876569527, 35.217706765295588],
              [-80.958528661426669, 35.217664512567538],
              [-80.95842388697163, 35.218193959515958],
              [-80.958752105269951, 35.218236211605713],
              [-80.958699718571083, 35.218500935169949],
              [-80.95935615719111, 35.218585437876968],
              [-80.959303772230655, 35.218850161688437],
              [-80.95963199362906, 35.218892411386598],
              [-80.959527225842137, 35.219421858270458],
              [-80.959855448561541, 35.219464107376268],
              [-80.959698296907703, 35.220258278330796],
              [-80.960026524184258, 35.220300526949231],
              [-80.959869372562949, 35.221094697121096],
              [-80.960197603298269, 35.22113694526977],
              [-80.96009283627906, 35.221666391556852],
              [-80.960421069433792, 35.221708639095382],
              [-80.960263918920873, 35.222502808703808],
              [-80.960592156632799, 35.222545055754821],
              [-80.960539773120999, 35.222809779036588],
              [-80.960868012212998, 35.222852025337112],
              [-80.96076324622527, 35.223381471179181],
              [-80.961091487736795, 35.223423716869554],
              [-80.960934340429588, 35.22421788623393],
              [-80.96126258540022, 35.224260131454514],
              [-80.961210204040825, 35.224524854036304],
              [-80.96088195803064, 35.22448260867526],
              [-80.960829574214571, 35.224747332002011],
              [-80.960501327483669, 35.224705084708496],
              [-80.960448942287528, 35.224969807861143],
              [-80.959792447789582, 35.224885311221726],
              [-80.959740060152129, 35.225150033158684],
              [-80.959411811286799, 35.22510778383068],
              [-80.959359422269372, 35.225372505593484],
              [-80.958702925677841, 35.225288003948421],
              [-80.958755316774415, 35.225023282466417],
              [-80.956785841344953, 35.224769754287905],
              [-80.956733442912679, 35.225034474945289],
              [-80.956405197170895, 35.224992217440914],
              [-80.956247997810252, 35.225786378771652],
              [-80.955919749290459, 35.225744119955202],
              [-80.955867347416756, 35.226008840196734],
              [-80.954882600738983, 35.22588205617798],
              [-80.954830195405904, 35.22614677596458],
              [-80.953188951466373, 35.225935451064423],
              [-80.953241361997129, 35.225670731980024],
              [-80.952256622374478, 35.225543925695959],
              [-80.952151794734974, 35.226073362988416],
              [-80.951823546064105, 35.226031092176115],
              [-80.951771130693842, 35.226295810631342],
              [-80.952099379305892, 35.226338081601725],
              [-80.951942133149586, 35.227132236302268],
              [-80.951613881418794, 35.227089964910462],
              [-80.951561463587382, 35.227354683248215],
              [-80.951233211157813, 35.227312410825093],
              [-80.951180791924443, 35.22757712808734],
              [-80.950852538795999, 35.227534854632758],
              [-80.950800118204043, 35.227799572622089],
              [-80.950471863278338, 35.227757298153854],
              [-80.950419442382795, 35.228022015049838],
              [-80.950091186758257, 35.227979739550243],
              [-80.950038763405672, 35.228244457190947],
              [-80.947740979592979, 35.227948502711733],
              [-80.947793411321058, 35.227683786036735],
              [-80.947465158422588, 35.227641503409806],
              [-80.947412726753456, 35.227906219926659],
              [-80.946427969159359, 35.227779365342307],
              [-80.946323097441393, 35.228308796548106],
              [-80.945994842787485, 35.22826650993602],
              [-80.945942404817657, 35.228531224905844],
              [-80.945614150563429, 35.228488937244663],
              [-80.945561711213273, 35.228753652040126],
              [-80.945889967605495, 35.22879593982416],
              [-80.945785091060088, 35.229325369576955],
              [-80.945456832588718, 35.229283081511916],
              [-80.945089743877219, 35.231136081838052],
              [-80.944984859095769, 35.231665509798859],
              [-80.944656590510291, 35.231623219596074],
              [-80.944551701166148, 35.232152646256587],
              [-80.944223431940841, 35.232110354864162],
              [-80.944170985717705, 35.232375068003037],
              [-80.944499256004192, 35.232417360437282],
              [-80.944446810479889, 35.232682073682732],
              [-80.944118539153763, 35.232639781108013],
              [-80.943908749510257, 35.233698634089755],
              [-80.943580473267886, 35.233656340079598],
              [-80.943318222197405, 35.234979902830005],
              [-80.942989941119137, 35.234937608127652],
              [-80.942885035552962, 35.235467032178768],
              [-80.942556752736124, 35.235424736304289],
              [-80.942451843726687, 35.235954159938473],
              [-80.942123559149778, 35.235911861990672],
              [-80.941756357917811, 35.237764841329373],
              [-80.941428066403887, 35.237722541506862],
              [-80.941323145977663, 35.23825196308281],
              [-80.940994852746527, 35.238209662989547],
              [-80.940889928855285, 35.238739083246877],
              [-80.940561632787151, 35.238696781999131],
              [-80.940509169300839, 35.238961492386785],
              [-80.940180872512485, 35.238919189206172],
              [-80.940128407645446, 35.239183899419203],
              [-80.939800111278387, 35.239141596090782],
              [-80.939747643910465, 35.239406305245495],
              [-80.939419346844574, 35.239364000885438],
              [-80.939366878095811, 35.239628709865535],
              [-80.939038580331285, 35.23958640447394],
              [-80.938986110201569, 35.239851113279407],
              [-80.938657810639839, 35.239808806873782],
              [-80.938605340227809, 35.240073515487047],
              [-80.938277039967275, 35.240031208049828],
              [-80.938119622488301, 35.240825334182013],
              [-80.937791319427987, 35.240783024530771],
              [-80.937738844473358, 35.241047732744086],
              [-80.938067148573495, 35.241090042535795],
              [-80.938014675416269, 35.241354750837843],
              [-80.937029759964062, 35.241227820188001],
              [-80.936977282247639, 35.241492528051843],
              [-80.935992367823317, 35.241365588041866],
              [-80.935939886646196, 35.241630295449859],
              [-80.935283275564657, 35.241545664358156],
              [-80.935230791966816, 35.24181037145086],
              [-80.934245875322475, 35.241683417249284],
              [-80.934193388263907, 35.241948123886083],
              [-80.93222355902607, 35.241694189662098],
              [-80.932171064288923, 35.241958895438707],
              [-80.931186151695897, 35.24183191541632],
              [-80.93113365349808, 35.242096620736923],
              [-80.930805348943053, 35.242054292140153],
              [-80.930752849342809, 35.242318996384682],
              [-80.930096238078534, 35.242234336254192],
              [-80.930043736078773, 35.242499041084585],
              [-80.929715430468079, 35.24245670953335],
              [-80.929505413755237, 35.243515526165531],
              [-80.929833724623805, 35.243557858261681],
              [-80.929781220917533, 35.243822562921075],
              [-80.929452909009115, 35.243780230684315],
              [-80.929400403900345, 35.244044934267585],
              [-80.928743780126368, 35.243960266821922],
              [-80.928691272596609, 35.244224970089796],
              [-80.928362959632523, 35.2441826348985],
              [-80.928310450721554, 35.244447337991446],
              [-80.927653823737771, 35.244362664654439],
              [-80.927601312405699, 35.244627367431875],
              [-80.927272998386002, 35.244585029286178],
              [-80.927220484574178, 35.244849731906214],
              [-80.926892170954673, 35.244807392711209],
              [-80.926839655761512, 35.24507209515636],
              [-80.926511340344902, 35.245029754947232],
              [-80.926458823770602, 35.245294457217483],
              [-80.926130508754198, 35.245252115959126],
              [-80.926183025269665, 35.244987413847028],
              [-80.925526397220864, 35.244902728054448],
              [-80.925578916573258, 35.244638026171906],
              [-80.924593979641315, 35.244510991681636],
              [-80.924541457169383, 35.244775693142167],
              [-80.924213144142641, 35.244733346695476],
              [-80.924160620289527, 35.244998047981177],
              [-80.923832306543119, 35.244955699601455],
              [-80.923779781308738, 35.245220400712142],
              [-80.923451466885268, 35.245178052202064],
              [-80.923398940269493, 35.245442753137816],
              [-80.921757370208624, 35.24523099377901],
              [-80.921809902023398, 35.244966293546696],
              [-80.92148159072616, 35.244923938771535],
              [-80.92153412323924, 35.244659238645639],
              [-80.920877502671601, 35.24457452762212],
              [-80.920930038021396, 35.244309827725928],
              [-80.920601729828292, 35.244267470559016],
              [-80.92065426477771, 35.244002770786729],
              [-80.920325957965872, 35.24396041286942],
              [-80.920378494712196, 35.243695713186149],
              [-80.920050189281753, 35.243653354518578],
              [-80.920102726726313, 35.243388654941711],
              [-80.91977442157841, 35.243346295541237],
              [-80.919826959721206, 35.243081596070937],
              [-80.919498657053296, 35.243039235902657],
              [-80.919551195894314, 35.242774536538946],
              [-80.919222894607586, 35.242732175620333],
              [-80.919275434146797, 35.242467476363025],
              [-80.918947134241321, 35.242425114694214],
              [-80.918894593662401, 35.242689813810806],
              [-80.918237992796634, 35.242605087518626],
              [-80.918185449817855, 35.242869787220812],
              [-80.917528848238959, 35.242785057083132],
              [-80.917476302817946, 35.243049755568357],
              [-80.917804604476373, 35.243092121223398],
              [-80.917699513611751, 35.243621519291331],
              [-80.91802781769124, 35.243663884336918],
              [-80.917817637078372, 35.244722678804685],
              [-80.917489328839793, 35.244680313196177],
              [-80.917384233855046, 35.245209709951773],
              [-80.917055922779937, 35.24516734318815],
              [-80.916950824327941, 35.245696738623792],
              [-80.916622512613529, 35.245654370670337],
              [-80.916569961296645, 35.245919068655333],
              [-80.91591333571445, 35.245834329811103],
              [-80.915860781976264, 35.246099027480319],
              [-80.915532468108481, 35.246056656589531],
              [-80.915479912967754, 35.246321353182466],
              [-80.914166658951331, 35.246151860111205],
              [-80.914114099309799, 35.246416556106659],
              [-80.913785785070729, 35.246374180479201],
              [-80.913628099899114, 35.247168268756241],
              [-80.913299783981046, 35.247125891798056],
              [-80.913194656385997, 35.247655283835769],
              [-80.912866337610154, 35.247612904821047],
              [-80.912761206568646, 35.248142296439852],
              [-80.912432886054887, 35.24809991625245],
              [-80.91232775156692, 35.248629307452276],
              [-80.911999429315259, 35.248586926092337],
              [-80.911841721319476, 35.249381010868674],
              [-80.911513396311221, 35.249338629096613],
              [-80.911460824868598, 35.249603322917004],
              [-80.91113249916225, 35.249560940113007],
              [-80.910974780805205, 35.250355022730702],
              [-80.910646451222334, 35.250312638630703],
              [-80.910488725549783, 35.251106719632482],
              [-80.9101603942878, 35.251064334201551],
              [-80.91000266241943, 35.251858414471016],
              [-80.908689324972556, 35.251688860396513],
              [-80.908741906770473, 35.251424167871413],
              [-80.908413574039002, 35.251381777720844],
              [-80.908466156513839, 35.251117084400754],
              [-80.906496176776514, 35.250862722873173],
              [-80.906443588041498, 35.251127414447097],
              [-80.906115257703149, 35.251085018058824],
              [-80.906062666487557, 35.251349709474709],
              [-80.905734336528894, 35.251307311135953],
              [-80.905681743952726, 35.251572003277865],
              [-80.905353412197371, 35.25152960392446],
              [-80.905300818218265, 35.251794294989786],
              [-80.90431582300404, 35.251667092044592],
              [-80.90426322556344, 35.251931782652946],
              [-80.901636579815502, 35.251592532939213],
              [-80.901583972615526, 35.251857222403515],
              [-80.900927312513289, 35.251772400557194],
              [-80.900874702891642, 35.252037089705375],
              [-80.900218042077896, 35.251952264012928],
              [-80.900165430034548, 35.25221695284479],
              [-80.899180437689253, 35.252089706307821],
              [-80.899233052852068, 35.251825017898597],
              [-80.898248067824468, 35.251697764648654],
              [-80.897563998971975, 35.255138701344883],
              [-80.897892341223027, 35.255181121806096],
              [-80.89726085150221, 35.258357360054923],
              [-80.89758920767342, 35.258399781298507],
              [-80.897536581777473, 35.258664466917651],
              [-80.897864939351805, 35.258706888312346],
              [-80.897601813257268, 35.260030317442457],
              [-80.897273449405091, 35.259987896261883],
              [-80.897220822555113, 35.260252581655905],
              [-80.89689245800507, 35.260210159443211],
              [-80.896787200099595, 35.260739529862768],
              [-80.896458833811678, 35.260697106477082],
              [-80.896353572457457, 35.26122647647616],
              [-80.896025204431737, 35.261184051917475],
              [-80.895919938508783, 35.261713420611798],
              [-80.895591568745104, 35.261670994880227],
              [-80.895538934240804, 35.261935679485077],
              [-80.896195676190487, 35.262020530338951],
              [-80.896143043424004, 35.262285215190929],
              [-80.896799788822619, 35.262370062761789],
              [-80.896747157773177, 35.262634746959542],
              [-80.897403906620596, 35.262719591247375],
              [-80.897351277330131, 35.262984276593492],
              [-80.89767965330725, 35.263026697541534],
              [-80.897627024693691, 35.263291382092511],
              [-80.897955402053086, 35.263333802290163],
              [-80.897902775257378, 35.263598487831338],
              [-80.898231153978031, 35.263640906377411],
              [-80.898073272418245, 35.264434961447094],
              [-80.898401654622461, 35.264477380425873],
              [-80.898191145950676, 35.265536119382482],
              [-80.898519532657659, 35.265578538033544],
              [-80.898466906223945, 35.265843222817999],
              [-80.898795294313274, 35.2658856407187],
              [-80.898742668556437, 35.266150324707979],
              [-80.899071059127067, 35.266192741840946],
              [-80.898965807610523, 35.266722111816712],
              [-80.899294199483748, 35.266764527456203],
              [-80.89918894865778, 35.267293896673173],
              [-80.899517344073558, 35.267336312587169],
              [-80.89935946892794, 35.268130366114853],
              [-80.899687867806591, 35.26817278156016],
              [-80.899582617719773, 35.268702150992382],
              [-80.899254216760639, 35.268659735265331],
              [-80.899201589603734, 35.268924419346369],
              [-80.899529992702099, 35.268966835196956],
              [-80.899477366243175, 35.269231519383915],
              [-80.899805770723987, 35.269273934484133],
              [-80.899332129812876, 35.271656090011255],
              [-80.899003715969485, 35.271613673643309],
              [-80.8988984569434, 35.272143040366238],
              [-80.898570041382499, 35.27210062372653],
              [-80.898517409755797, 35.272365306452691],
              [-80.898188993496802, 35.272322888780856],
              [-80.898136360487442, 35.272587571331229],
              [-80.897807943509434, 35.272545151725922],
              [-80.897755309138503, 35.272809835001794],
              [-80.897426890363263, 35.272767414381399],
              [-80.897374254588797, 35.273032096580181],
              [-80.897045836235549, 35.272989675811736],
              [-80.896993199078466, 35.273254357834702],
              [-80.896664778907081, 35.273211935149938],
              [-80.896559501485299, 35.273741298809249],
              [-80.89623107959649, 35.273698875852624],
              [-80.896073156655618, 35.274492919772712],
              [-80.895744733086957, 35.274450495484878],
              [-80.895639446960743, 35.274979857346956],
              [-80.8959678737301, 35.275022282800549],
              [-80.89591523065485, 35.27528696427931],
              [-80.896243658785934, 35.275329388081147],
              [-80.896191017507746, 35.27559406964847],
              [-80.895862588315282, 35.275551644804466],
              [-80.89580994455541, 35.275816326213125],
              [-80.896138374788265, 35.275858751198022],
              [-80.895980446731983, 35.276652793799641],
              [-80.895927803721207, 35.276917474290606],
              [-80.896256238458122, 35.276959898947759],
              [-80.89615095241254, 35.277489261024876],
              [-80.896479389551672, 35.277531684170974],
              [-80.896426747594788, 35.277796364838679],
              [-80.896098308316212, 35.277753941568818],
              [-80.895993020195561, 35.278283302534433],
              [-80.896321461576164, 35.278325726987248],
              [-80.896058241014558, 35.279649128581994],
              [-80.896386686819852, 35.279691551963737],
              [-80.896334043164899, 35.279956232491656],
              [-80.896662491473194, 35.279998656006995],
              [-80.896609847396164, 35.280263335756629],
              [-80.896281398068368, 35.280220913001784],
              [-80.896228753707717, 35.280485592558236],
              [-80.89655720409705, 35.280528016355234],
              [-80.896346625215671, 35.281586735729178],
              [-80.896018170685167, 35.281544312269951],
              [-80.895702284739031, 35.283132388998105],
              [-80.895373823187285, 35.283089962918048],
              [-80.895215872459715, 35.283883999934218],
              [-80.894887408128568, 35.283841572540076],
              [-80.894834755794264, 35.284106251339928],
              [-80.894506290764937, 35.284063822913495],
              [-80.894400982987733, 35.284593180125874],
              [-80.89407251621968, 35.284550750526201],
              [-80.89401986077678, 35.284815428938714],
              [-80.893691393310547, 35.284772998306678],
              [-80.893586079295261, 35.285302353843008],
              [-80.893257610090146, 35.285259922037703],
              [-80.893152291524558, 35.28578927716854],
              [-80.892823820601677, 35.285746845091268],
              [-80.89277116030361, 35.286011522003633],
              [-80.892114217382954, 35.285926653991538],
              [-80.892061553561916, 35.286191330604126],
              [-80.891733081564155, 35.286148894669303],
              [-80.891680416360074, 35.286413571105733],
              [-80.891023471331721, 35.286328697180934],
              [-80.890970803703823, 35.286593373300363],
              [-80.890313857943326, 35.286508494626574],
              [-80.890261187912557, 35.286773171330132],
              [-80.889932713406225, 35.286730730532227],
              [-80.889827371293023, 35.287260081732057],
              [-80.889498895048035, 35.287217639760861],
              [-80.889340874517373, 35.288011664997626],
              [-80.889012396613026, 35.28796922259621],
              [-80.888907044692232, 35.288498572012543],
              [-80.889235525798, 35.288541015579881],
              [-80.88902482310732, 35.289599714535299],
              [-80.889353308718569, 35.289642157774907],
              [-80.889195282018591, 35.290436180678483],
              [-80.888866793306093, 35.290393737917498],
              [-80.888814116013563, 35.29065841200709],
              [-80.889142605787711, 35.29070085581035],
              [-80.888984573877337, 35.291494878306935],
              [-80.889313068215614, 35.291537321624283],
              [-80.889207713589826, 35.29206666916479],
              [-80.889536210352261, 35.292109111872399],
              [-80.889378179526531, 35.292903133782666],
              [-80.889049679662648, 35.292860691553614],
              [-80.888997001347249, 35.293125364705347],
              [-80.888668500785201, 35.293082921443855],
              [-80.888563141065745, 35.293612268260652],
              [-80.888234638743867, 35.293569822924468],
              [-80.887971224936607, 35.294893188242838],
              [-80.887642717753181, 35.294850741310306],
              [-80.887590033574313, 35.295115413756029],
              [-80.887261525692722, 35.295072965791007],
              [-80.887208839051695, 35.29533763897868],
              [-80.886880330471939, 35.295295189981211],
              [-80.886827642426596, 35.295559862091217],
              [-80.886499133148675, 35.295517412061209],
              [-80.886446443719791, 35.295782083994794],
              [-80.886117933743733, 35.295739632932275],
              [-80.886065242931295, 35.296004304689426],
              [-80.886012551776147, 35.296268976411142],
              [-80.885684038961728, 35.296226524192178],
              [-80.885578653541472, 35.296755867247306],
              [-80.885250140087408, 35.296713413837736],
              [-80.885144751193721, 35.297242755567737],
              [-80.884816234922155, 35.297200301903068],
              [-80.884763538909894, 35.297464972123187],
              [-80.88443502191933, 35.297422516524719],
              [-80.884487720071832, 35.297157846428455],
              [-80.881859609190656, 35.296818169700693],
              [-80.881806902732094, 35.297082839570486],
              [-80.881478389925945, 35.2970403759305],
              [-80.881425682062911, 35.297305044722521],
              [-80.881097168558725, 35.297262580049875],
              [-80.881044459332827, 35.297527249566791],
              [-80.880715944031252, 35.297484783878623],
              [-80.880663233400838, 35.297749452317632],
              [-80.88033471850062, 35.2977069855799],
              [-80.880229294150283, 35.298236322970723],
              [-80.879900776391068, 35.298193854175096],
              [-80.879531770827896, 35.30004653071704],
              [-80.879203246146062, 35.30000406094414],
              [-80.879097811439664, 35.300533396229611],
              [-80.878769285018976, 35.300490925283043],
              [-80.878663845738913, 35.301020259259921],
              [-80.878335316479792, 35.300977787156796],
              [-80.878282595844624, 35.301242454392913],
              [-80.877954066966097, 35.301199980338872],
              [-80.877848620386274, 35.301729314456416],
              [-80.877520089768822, 35.301686839228609],
              [-80.877467364923547, 35.301951506092912],
              [-80.877138833608115, 35.301909029832558],
              [-80.877033380785932, 35.302438362270934],
              [-80.87670484775245, 35.302395885738186],
              [-80.876652119775528, 35.302660551312265],
              [-80.876323584944629, 35.302618073763867],
              [-80.87621812588003, 35.303147404523017],
              [-80.875889590409528, 35.303104925783934],
              [-80.875678662901265, 35.304163586327299],
              [-80.875350123609863, 35.304121106132364],
              [-80.875297389009717, 35.304385771050804],
              [-80.874311767830321, 35.304258322924461],
              [-80.87425902976419, 35.304522987384125],
              [-80.873930488663376, 35.304480503357468],
              [-80.873877750312786, 35.304745167623309],
              [-80.873549208493344, 35.304702681662661],
              [-80.873443727581275, 35.305232009822248],
              [-80.873115184022922, 35.305189522687897],
              [-80.873062441461599, 35.305454186581557],
              [-80.872733897205322, 35.305411698414403],
              [-80.872681153259904, 35.305676362131365],
              [-80.872352608305704, 35.305633872931502],
              [-80.8722998640757, 35.305898536454684],
              [-80.871642772015093, 35.305813555114881],
              [-80.871590024260499, 35.306078218337177],
              [-80.871261478253587, 35.306035726180212],
              [-80.871208729114855, 35.306300389225697],
              [-80.870880182410062, 35.306257896035945],
              [-80.870827431866473, 35.306522558003323],
              [-80.870498884484533, 35.306480064682184],
              [-80.8704461314573, 35.306744726489811],
              [-80.870117583356759, 35.306702231234539],
              [-80.870064830065587, 35.306966893749582],
              [-80.869407731712428, 35.306881900298926],
              [-80.869354975975355, 35.307146561594834],
              [-80.868697876933609, 35.307061565196541],
              [-80.868750635831503, 35.306796903264512],
              [-80.868422087306286, 35.306754403426794],
              [-80.868369328466869, 35.307019065200699],
              [-80.868040779243813, 35.306976564330206],
              [-80.867988018999512, 35.307241225025962],
              [-80.867659469078646, 35.30719872312261],
              [-80.867606707450065, 35.307463383641583],
              [-80.867278156831475, 35.307420880705479],
              [-80.867225393818757, 35.307685541047576],
              [-80.866896842502285, 35.307643037078662],
              [-80.866949607655528, 35.307378376860783],
              [-80.866621058822886, 35.30733587212432],
              [-80.866779354246788, 35.306541891696277],
              [-80.866450808880273, 35.306499386491446],
              [-80.866503574721932, 35.306234725511409],
              [-80.866175030739697, 35.306192219555996],
              [-80.866227797299786, 35.305927559582599],
              [-80.86589925470166, 35.305885052876711],
              [-80.865952021938796, 35.305620392107294],
              [-80.865294939854266, 35.305535376302679],
              [-80.865347708830072, 35.30527071577982],
              [-80.864033554022356, 35.305100673116989],
              [-80.864086327938878, 35.304836014007243],
              [-80.863757790845668, 35.304793500581738],
              [-80.863810564360278, 35.30452884159434],
              [-80.863153494405708, 35.304443813235153],
              [-80.863100718829841, 35.304708472841661],
              [-80.86244364816659, 35.304623440633677],
              [-80.862390869045498, 35.30488809903774],
              [-80.861405261963199, 35.304760544074149],
              [-80.861352479396785, 35.305025202920348],
              [-80.860366873360462, 35.304897638590269],
              [-80.860314087307415, 35.305162296076041],
              [-80.85998555088851, 35.305119773320357],
              [-80.859932763451198, 35.305384430629118],
              [-80.859604226334739, 35.305341906840688],
              [-80.859498648348236, 35.305871221068607],
              [-80.85917010949332, 35.305828696106239],
              [-80.859117318944087, 35.306093353025375],
              [-80.858788779371054, 35.306050826128882],
              [-80.858735987458132, 35.306315483772394],
              [-80.85807890726042, 35.30623042702203],
              [-80.858131702375346, 35.305965770545299],
              [-80.857803163832415, 35.30592324097389],
              [-80.857855958545372, 35.305658584619415],
              [-80.855884744064682, 35.305403390163988],
              [-80.855831942007001, 35.305668045688222],
              [-80.855174871172323, 35.305582973153896],
              [-80.855122066709981, 35.305847629261166],
              [-80.854793530217208, 35.30580509152383],
              [-80.85446499516739, 35.305762552877916],
              [-80.854306572313064, 35.306556517450836],
              [-80.853978033384607, 35.306513977506555],
              [-80.853925224405018, 35.306778632145765],
              [-80.852611069062348, 35.306608462870507],
              [-80.852558255575701, 35.306873116909024],
              [-80.851901177313479, 35.306788025746229],
              [-80.851848361401593, 35.307052679466544],
              [-80.851519821755133, 35.307010132857073],
              [-80.851467003359303, 35.307274786417175],
              [-80.851138463015417, 35.307232238774823],
              [-80.850980003722853, 35.308026197898137],
              [-80.849994375364432, 35.307898547448936],
              [-80.849941551430689, 35.308163200300655],
              [-80.847641756653985, 35.307865316455349],
              [-80.847694588973468, 35.307600664575695],
              [-80.846051895014384, 35.307387863568401],
              [-80.845999057490545, 35.30765251474174],
              [-80.8450134425523, 35.307524822827034],
              [-80.844960601562207, 35.307789473540673],
              [-80.844303524926389, 35.307704340250304],
              [-80.844250681510857, 35.307968990645492],
              [-80.84326506548328, 35.307841284508633],
              [-80.843212217481522, 35.308105933559474],
              [-80.842226601404604, 35.307978218072869],
              [-80.842173751056478, 35.308242867548387],
              [-80.841188133851531, 35.308115143629927],
              [-80.84113528003698, 35.308379792645624],
              [-80.840806740615449, 35.308337216081526],
              [-80.840753884296433, 35.308601864035481],
              [-80.840425344177788, 35.308559286438445],
              [-80.840372487573632, 35.308823934198479],
              [-80.840043946757959, 35.308781355568485],
              [-80.839991087689896, 35.309046004069245],
              [-80.839662546177266, 35.309003424406342],
              [-80.839556824887268, 35.309532719214907],
              [-80.839228281636679, 35.309490138377683],
              [-80.839175419435193, 35.309754785586776],
              [-80.83884687548759, 35.309712203716565],
              [-80.838741147971049, 35.310241497744315],
              [-80.838412601186107, 35.31019891471648],
              [-80.83830687021235, 35.310728208317904],
              [-80.83797832278897, 35.310685624098973],
              [-80.83792545464587, 35.310950270721143],
              [-80.83759690652559, 35.310907685469211],
              [-80.837544038076913, 35.311172330996044],
              [-80.837215488159998, 35.311129744727872],
              [-80.837162618346795, 35.311394390978691],
              [-80.83683406773298, 35.311351803677503],
              [-80.836728323873203, 35.31188109490396],
              [-80.836399772621093, 35.311838506411689],
              [-80.83629402420425, 35.312367797228312],
              [-80.835965471214237, 35.312325207561607],
              [-80.835859719339808, 35.312854497951498],
              [-80.835531164611822, 35.312811907110451],
              [-80.835478287107648, 35.313076551659478],
              [-80.835149730583112, 35.313033959802013],
              [-80.835043972460994, 35.313563248509226],
              [-80.834715414198655, 35.313520655477333],
              [-80.834662533580612, 35.31378529963559],
              [-80.834333974621316, 35.313742705570647],
              [-80.83422821027149, 35.314271993496298],
              [-80.833899649574306, 35.314229398256934],
              [-80.833846765842409, 35.314494042024386],
              [-80.833518204427961, 35.314451444850633],
              [-80.832861082651263, 35.314366248729513],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 55,
        ORIG_FID: 8,
        AAPC_Examp: 9,
        Area_mile2: 291,
        BASIN_NAME: "Great Falls Reservoir",
        Res_Basin: "GreatFalls",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.251203239517878, 35.049817177393003],
              [-81.251254503372763, 35.049552281359851],
              [-81.250599190205705, 35.049469392444038],
              [-81.250650455802244, 35.04920449665785],
              [-81.250322800742552, 35.049163050553062],
              [-81.250374067068407, 35.048898155777081],
              [-81.249718761117293, 35.048815261154466],
              [-81.249770028064063, 35.048550365744646],
              [-81.249114725517984, 35.048467467839956],
              [-81.249165995302249, 35.048202572656756],
              [-81.248838346113402, 35.048161122497916],
              [-81.248889616626897, 35.04789622832488],
              [-81.248561967687124, 35.047854776533811],
              [-81.248664509765277, 35.047324987475555],
              [-81.248336864353192, 35.047283535951777],
              [-81.248388135917921, 35.047018641066551],
              [-81.24806049185085, 35.046977187890057],
              [-81.248111764119926, 35.046712293113849],
              [-81.247784121447495, 35.046670840087152],
              [-81.247835394420832, 35.046405945419977],
              [-81.246852471486591, 35.046281580515839],
              [-81.246801195402028, 35.046546474768952],
              [-81.245490632008256, 35.046380642192183],
              [-81.24543935144267, 35.046645535864016],
              [-81.243801149459429, 35.046438222862193],
              [-81.243749863375768, 35.04670311581468],
              [-81.243422222955331, 35.046661650948607],
              [-81.243473511172056, 35.046396758113758],
              [-81.243145872096733, 35.046355291595034],
              [-81.243197161017562, 35.046090398869318],
              [-81.242541885939687, 35.046007463439452],
              [-81.241231341994535, 35.045841582766734],
              [-81.241282636804669, 35.045576690840541],
              [-81.239972102333482, 35.045410796488788],
              [-81.240023400934007, 35.045145904184764],
              [-81.238385247133152, 35.044938515765949],
              [-81.238436550610402, 35.044673625024757],
              [-81.238108922105056, 35.044632144806698],
              [-81.238057616494984, 35.044897035430175],
              [-81.236419472657374, 35.044689619426826],
              [-81.236470783452233, 35.044424729494132],
              [-81.235160279395018, 35.044258781037712],
              [-81.235108963356524, 35.044523670438181],
              [-81.233143211026729, 35.044274719791261],
              [-81.233194533262122, 35.044009830318593],
              [-81.232211664679269, 35.043885342501277],
              [-81.232262989716958, 35.043620454315345],
              [-81.231935368550737, 35.043578956435155],
              [-81.23198669429236, 35.043314068358505],
              [-81.231331455032958, 35.043231070206346],
              [-81.23138278249057, 35.042966181475819],
              [-81.231055165517773, 35.042924682084802],
              [-81.231106493679277, 35.042659793463457],
              [-81.229468415609929, 35.042452280314322],
              [-81.229417082264234, 35.042717168244586],
              [-81.228434236075287, 35.042592648731102],
              [-81.228382899285918, 35.042857536217774],
              [-81.228055283153779, 35.042816027802992],
              [-81.228003944969743, 35.043080914221271],
              [-81.227676328133896, 35.043039404778888],
              [-81.22762498750869, 35.043304291951372],
              [-81.226314521540985, 35.04313824469466],
              [-81.226263176410541, 35.043403130383929],
              [-81.225935560262684, 35.043361616197998],
              [-81.225986906429853, 35.04309673064688],
              [-81.224676447101189, 35.042930664701316],
              [-81.224727797057938, 35.0426657787729],
              [-81.224072571478843, 35.042582741190991],
              [-81.224123924271851, 35.042317855489998],
              [-81.223796312470938, 35.042276335513307],
              [-81.22384766596754, 35.042011449921581],
              [-81.223520055512168, 35.041969928292524],
              [-81.222864837841826, 35.041886884128452],
              [-81.222916194115584, 35.041621998922558],
              [-81.220950554153745, 35.041372843231933],
              [-81.221001916290319, 35.04110795792549],
              [-81.219691502288001, 35.040941836864363],
              [-81.219742868262799, 35.040676952983404],
              [-81.218760064608162, 35.040552351907522],
              [-81.218811433359832, 35.040287468412458],
              [-81.217828635839709, 35.040162860648799],
              [-81.217880007343709, 35.039897976638507],
              [-81.217224813085963, 35.03981490001847],
              [-81.217173439508571, 35.040079783752198],
              [-81.216845841295154, 35.04003824397995],
              [-81.216794466323137, 35.040303126645043],
              [-81.216139269918727, 35.040220044135893],
              [-81.216087891444204, 35.040484926515482],
              [-81.215105095679874, 35.040360295676869],
              [-81.215053713761861, 35.040625177612483],
              [-81.214726115534376, 35.040583632069172],
              [-81.21467473224628, 35.040848513837553],
              [-81.214347132219757, 35.040806967286713],
              [-81.214398516544563, 35.040542085656767],
              [-81.212760529859651, 35.040334339293032],
              [-81.212709139255495, 35.040599220251309],
              [-81.212381542301003, 35.040557667443203],
              [-81.212432932845914, 35.040292786643406],
              [-81.211777740963555, 35.040209680458673],
              [-81.211829134319757, 35.039944798985239],
              [-81.211173945844592, 35.039861689520052],
              [-81.211225340940629, 35.039596808294462],
              [-81.210897748787872, 35.039555252356209],
              [-81.210949143491263, 35.039290371260115],
              [-81.210621552708531, 35.039248814570989],
              [-81.210672949235388, 35.038983934465499],
              [-81.210345359798367, 35.038942376124204],
              [-81.210448152804545, 35.038412615221986],
              [-81.210120565774261, 35.038371056268076],
              [-81.210274756427765, 35.03757641465225],
              [-81.209947172865256, 35.037534856125596],
              [-81.210049967754372, 35.037005094566226],
              [-81.209722386574001, 35.03696353452591],
              [-81.209773784554855, 35.036698653841299],
              [-81.209118626387991, 35.036615532250742],
              [-81.209170026108566, 35.036350651814026],
              [-81.20884244909756, 35.036309089362618],
              [-81.208791048340544, 35.036573969660914],
              [-81.206170431608584, 35.03624143791118],
              [-81.206221841753759, 35.035976558700092],
              [-81.204256401582128, 35.035727121753055],
              [-81.204204985242285, 35.035992001034664],
              [-81.20322226599049, 35.035867269700567],
              [-81.203273685464154, 35.03560239173558],
              [-81.202290973420105, 35.035477652793823],
              [-81.202342395669575, 35.035212775215243],
              [-81.201032121744973, 35.03504644413028],
              [-81.201083548902432, 35.034781567056719],
              [-81.199118155339846, 35.034532044563946],
              [-81.199066721963618, 35.034796920806507],
              [-81.197428899255542, 35.034588959776499],
              [-81.197377460363555, 35.034853835297625],
              [-81.196394767410311, 35.034729047417493],
              [-81.196343323979434, 35.034993922514126],
              [-81.194705504232985, 35.034785923629961],
              [-81.194654056382106, 35.0350507979851],
              [-81.193998927860818, 35.034967591585406],
              [-81.194050378880377, 35.034702717487399],
              [-81.193395254862935, 35.034619507788214],
              [-81.193446706501391, 35.034354633057049],
              [-81.193119146577757, 35.034313027002234],
              [-81.193222052046053, 35.033783278611587],
              [-81.192894494528915, 35.033741671944767],
              [-81.192945948334412, 35.03347679738404],
              [-81.192618392187569, 35.033435189966532],
              [-81.192669845624067, 35.033170316436724],
              [-81.192342290823106, 35.033128707367432],
              [-81.192393746057945, 35.032863833927323],
              [-81.190100879174665, 35.032572551021843],
              [-81.190049415589755, 35.03283742351185],
              [-81.189066760249787, 35.032712574099214],
              [-81.189015294341928, 35.032977447025914],
              [-81.188687742177336, 35.032935828037274],
              [-81.188636273803596, 35.033200700816344],
              [-81.187653616228374, 35.033075839001135],
              [-81.187602144411741, 35.033340711335612],
              [-81.186947038966736, 35.03325746570291],
              [-81.18689556471935, 35.03352233682994],
              [-81.186568011473483, 35.033480712991945],
              [-81.18651653585583, 35.03374558395145],
              [-81.185861428269305, 35.0336623324295],
              [-81.185809950245044, 35.033927203082868],
              [-81.184172180809981, 35.033719056692618],
              [-81.184120697294247, 35.03398392752532],
              [-81.181500276991841, 35.033650844482707],
              [-81.181551768822047, 35.033385975660522],
              [-81.180896670231306, 35.033302695364782],
              [-81.180948164895739, 35.033037826771128],
              [-81.1806206166019, 35.032996185888784],
              [-81.180672111944432, 35.032731316503707],
              [-81.180344565021016, 35.032689674870838],
              [-81.180396061065736, 35.032424805595497],
              [-81.180068516608316, 35.032383163192392],
              [-81.180120013355165, 35.032118294026773],
              [-81.180223005870772, 35.031588556510243],
              [-81.180550547219397, 35.031630198497332],
              [-81.180602041927841, 35.031365329106372],
              [-81.180929583670036, 35.031406970045907],
              [-81.18113555392226, 35.030347491658738],
              [-81.180808017420532, 35.030305851254091],
              [-81.180859510434473, 35.030040980817617],
              [-81.181168460479455, 35.028451760316791],
              [-81.180840931565115, 35.028410119993744],
              [-81.180995405698113, 35.027615508408616],
              [-81.1813229315282, 35.027657149216921],
              [-81.181425909421321, 35.027127406857339],
              [-81.181753432417409, 35.02716904651907],
              [-81.181907894203533, 35.026374432788629],
              [-81.182235415496962, 35.026416070224215],
              [-81.182389870072953, 35.025621455838746],
              [-81.182062352983465, 35.025579818799308],
              [-81.182113838924693, 35.025314946804635],
              [-81.181786323205301, 35.025273309014828],
              [-81.18183780875296, 35.025008437149879],
              [-81.180855266682926, 35.024883517961122],
              [-81.180906756100427, 35.024618646463658],
              [-81.180251731788005, 35.024535362536255],
              [-81.180303222967552, 35.024270492188684],
              [-81.179975712336343, 35.024228848579504],
              [-81.180027204193848, 35.023963977440637],
              [-81.179699696052481, 35.023922333962688],
              [-81.179905663166579, 35.022862848793309],
              [-81.179578159503308, 35.022821204981014],
              [-81.179629651762667, 35.022556333975999],
              [-81.179302148349763, 35.022514688531956],
              [-81.179456626207624, 35.021720074859623],
              [-81.179129127332274, 35.02167842892279],
              [-81.179232112964613, 35.021148686308202],
              [-81.178904616495146, 35.021107039759968],
              [-81.17900760286328, 35.020577297308066],
              [-81.17868010879981, 35.020535650148183],
              [-81.178783095879666, 35.020005906957884],
              [-81.178455604222009, 35.019964259186459],
              [-81.178507098296805, 35.019699387687062],
              [-81.17817960912879, 35.019657740046867],
              [-81.178231103905475, 35.019392868657562],
              [-81.177903614987713, 35.019351219385626],
              [-81.177955110442312, 35.019086347205146],
              [-81.177627624014079, 35.019044698064576],
              [-81.177730616016902, 35.018514954796359],
              [-81.177403131970436, 35.018473304143001],
              [-81.177609116110958, 35.01741381781833],
              [-81.177281636542062, 35.017372166830839],
              [-81.177333132789983, 35.017107295767595],
              [-81.177005654590971, 35.017065644029891],
              [-81.177057152612079, 35.01680077215574],
              [-81.176729675782937, 35.01675911966786],
              [-81.176781173410419, 35.016494247923632],
              [-81.176126222825815, 35.0164109405587],
              [-81.176177723286401, 35.016146069043529],
              [-81.175850249530939, 35.016104414166392],
              [-81.175901749597855, 35.015839542781244],
              [-81.175574277188247, 35.015797886252763],
              [-81.175625779052325, 35.015533014958024],
              [-81.174970838450491, 35.015449701294507],
              [-81.175022340956559, 35.015184830268474],
              [-81.174694872740233, 35.01514317223225],
              [-81.174746375924101, 35.014878300415155],
              [-81.174418909077616, 35.014836641628882],
              [-81.174470412987063, 35.014571770823167],
              [-81.173815482343954, 35.014488449960169],
              [-81.173866989086321, 35.01422357938376],
              [-81.173539524194098, 35.014181917326788],
              [-81.173591031638225, 35.01391704686062],
              [-81.173263569211315, 35.01387538403381],
              [-81.173315077356989, 35.013610513677882],
              [-81.17298761629992, 35.013568850100953],
              [-81.173039124051812, 35.013303979875026],
              [-81.172711664364556, 35.01326231554809],
              [-81.172763173913452, 35.012997445412623],
              [-81.172435715596094, 35.012955780335517],
              [-81.172538735715037, 35.012426038454215],
              [-81.172211279827067, 35.012384373667082],
              [-81.172314300681052, 35.011854631949362],
              [-81.171986848270024, 35.01181296562995],
              [-81.172038359231479, 35.011548094867159],
              [-81.171710907118836, 35.011506428718697],
              [-81.171813930086785, 35.010976686483957],
              [-81.171486481450941, 35.010935018803245],
              [-81.171692527544224, 35.009875535448671],
              [-81.171365083385012, 35.009833867434331],
              [-81.171519617523728, 35.009039253909563],
              [-81.170864736445267, 35.008955916948416],
              [-81.170916249219857, 35.008691045693823],
              [-81.17058881020543, 35.008649375568169],
              [-81.170640323681482, 35.008384504423951],
              [-81.170312886036641, 35.008342833548305],
              [-81.170364400214154, 35.008077962514491],
              [-81.16970952909351, 35.007994618354836],
              [-81.169761045008016, 35.007729747570131],
              [-81.169106177294964, 35.007646400132977],
              [-81.169157694946435, 35.007381529597595],
              [-81.168502830641003, 35.007298178882991],
              [-81.168554351124811, 35.007033308577064],
              [-81.167899490227001, 35.006949954585046],
              [-81.16795101132837, 35.006685083646815],
              [-81.167296154933524, 35.006601726357687],
              [-81.167347677795775, 35.006336856570094],
              [-81.166365398792422, 35.006211813485983],
              [-81.166416925498368, 35.005946943165476],
              [-81.165434651513721, 35.005821892519037],
              [-81.165486181015808, 35.005557023487881],
              [-81.164831336156951, 35.005473651910691],
              [-81.164882867371873, 35.005208782227676],
              [-81.164555446491107, 35.005167095695462],
              [-81.164606979502636, 35.004902226103276],
              [-81.164279559991641, 35.004860538821184],
              [-81.164331092609018, 35.004595669359169],
              [-81.164003674443691, 35.004553980425918],
              [-81.164055207762289, 35.004289111074414],
              [-81.163727792085936, 35.004247422272954],
              [-81.163779326105654, 35.003982553031982],
              [-81.163451910679711, 35.003940862599109],
              [-81.163554980882822, 35.003411124290182],
              [-81.163227568957268, 35.003369433226688],
              [-81.163330638798911, 35.002839695101926],
              [-81.163003229278274, 35.002798003427401],
              [-81.16310630094911, 35.002268265447526],
              [-81.162778893833533, 35.002226573162062],
              [-81.162830429631441, 35.001961703377098],
              [-81.162503023909309, 35.001920011243094],
              [-81.162554560408282, 35.001655141568691],
              [-81.162227156031861, 35.001613447783633],
              [-81.162278694351201, 35.001348579101482],
              [-81.161623888671684, 35.001265189143147],
              [-81.161675427608273, 35.001000319828762],
              [-81.161348027400464, 35.000958623635931],
              [-81.161399567038146, 35.000693754432184],
              [-81.160744768624554, 35.000610360579032],
              [-81.160847852133699, 35.000080622622761],
              [-81.160520454939274, 35.00003892420024],
              [-81.160623540277356, 34.999509186389055],
              [-81.157677004634095, 34.999133861196668],
              [-81.157728557214355, 34.998868993479356],
              [-81.156746388146928, 34.998743869196375],
              [-81.156797942403273, 34.998479001887212],
              [-81.15614316748929, 34.998395581219881],
              [-81.155488393912776, 34.998312156998011],
              [-81.155436834444032, 34.998577024672379],
              [-81.155109447110164, 34.998535310638928],
              [-81.155057886271834, 34.998800178146041],
              [-81.154403110536848, 34.99871674713556],
              [-81.154351547293714, 34.998981614336515],
              [-81.154024158892526, 34.998939897359342],
              [-81.15397259425599, 34.999204763491896],
              [-81.152008263636347, 34.998954444857809],
              [-81.15205983555569, 34.998689578638306],
              [-81.151732449519244, 34.998647856342139],
              [-81.151784021043952, 34.998382990252829],
              [-81.149164954002998, 34.998049174677753],
              [-81.149113373101443, 34.998314039674618],
              [-81.148785990051394, 34.998272308479827],
              [-81.148734407756464, 34.998537172408142],
              [-81.147097493443525, 34.998328502390883],
              [-81.147149080890557, 34.998063638256326],
              [-81.146494320234595, 34.997980164295676],
              [-81.146545909441073, 34.997715301312205],
              [-81.144254264310945, 34.997423112738808],
              [-81.144202666786981, 34.997687975669784],
              [-81.143875289826781, 34.997646230225605],
              [-81.14392688840951, 34.997381368334963],
              [-81.143599511747411, 34.997339623061912],
              [-81.143651111006989, 34.997074760380826],
              [-81.142996361828537, 34.996991266526514],
              [-81.143047962847334, 34.996726404996465],
              [-81.142720590330896, 34.996684656415212],
              [-81.142772192026513, 34.99641979409472],
              [-81.142117448996089, 34.996336295465362],
              [-81.142169052427249, 34.996071433394839],
              [-81.14053220860896, 34.995862671467961],
              [-81.140480600002519, 34.996127532843097],
              [-81.139825861633568, 34.996044020872837],
              [-81.139774250622267, 34.996308881941587],
              [-81.137482673485295, 34.996016561916434],
              [-81.137431053775344, 34.996281421101386],
              [-81.136448951193614, 34.996156126959043],
              [-81.136500573985174, 34.995891267290133],
              [-81.135845842674584, 34.995807733697461],
              [-81.135897467201417, 34.995542874278641],
              [-81.13557010308331, 34.995501106288671],
              [-81.135621729405543, 34.995236246961291],
              [-81.134967004243549, 34.995152708593999],
              [-81.135018632301069, 34.994887849516687],
              [-81.134036551245529, 34.99476253479876],
              [-81.133984920083051, 34.995027393458749],
              [-81.133657560103785, 34.994985620257445],
              [-81.133502662574301, 34.995780194729704],
              [-81.133175298729739, 34.995738420241899],
              [-81.133020395081815, 34.996532994042568],
              [-81.132693028443441, 34.996491217347504],
              [-81.132589754862778, 34.997020932507496],
              [-81.132262386512735, 34.996979155546846],
              [-81.132159110594287, 34.997508869394878],
              [-81.131504369663546, 34.997425312270664],
              [-81.13145272858425, 34.997690168883601],
              [-81.130470618190444, 34.997564824284396],
              [-81.130418973671283, 34.997829680451524],
              [-81.130091602095135, 34.997787897622395],
              [-81.130039956206147, 34.998052753622041],
              [-81.124802056127265, 34.997384094184525],
              [-81.124750392247932, 34.997648947948036],
              [-81.124095658823094, 34.997565348793593],
              [-81.124043992538773, 34.997830202250341],
              [-81.123716625293881, 34.99778840120107],
              [-81.12366495654426, 34.998053254509593],
              [-81.12333758859954, 34.998011452432564],
              [-81.123285919575082, 34.998276305554327],
              [-81.12295855093042, 34.998234502449549],
              [-81.122906879440734, 34.99849935542295],
              [-81.12192477238861, 34.998373939458666],
              [-81.12197644698324, 34.998109086903],
              [-81.119684882865997, 34.997816420731709],
              [-81.119633201003225, 34.998081271411351],
              [-81.117669011117428, 34.997830378156607],
              [-81.117617321637908, 34.99809522889268],
              [-81.117289957692222, 34.998053409484285],
              [-81.117238266842591, 34.998318260052756],
              [-81.115601442633078, 34.998109150866611],
              [-81.115549746250309, 34.998373999809012],
              [-81.11522238117405, 34.998332175711568],
              [-81.114895016409676, 34.998290349824458],
              [-81.11494671483905, 34.998025500259374],
              [-81.114619351468278, 34.99798367452432],
              [-81.114567651980551, 34.998248523048737],
              [-81.113912925246751, 34.998164867713939],
              [-81.113861222258762, 34.99842971595065],
              [-81.113533858919183, 34.998387887252235],
              [-81.113430450986044, 34.99891758424387],
              [-81.113103084793281, 34.998875753496293],
              [-81.113051379824896, 34.99914060135],
              [-81.112724012955709, 34.999098770475889],
              [-81.112672305521798, 34.999363618181107],
              [-81.112344937929663, 34.999321785377823],
              [-81.112293229125513, 34.999586632915282],
              [-81.111638492900681, 34.999502965265911],
              [-81.111586782786659, 34.99976781247716],
              [-81.110932045809932, 34.999684140093834],
              [-81.110983759089137, 34.999419293142111],
              [-81.110001659220558, 34.999293777420149],
              [-81.11005337526916, 34.999028930858074],
              [-81.108416555147372, 34.998819721496879],
              [-81.108364833947505, 34.999084568263441],
              [-81.106728017012742, 34.99887533509213],
              [-81.106676289184449, 34.999140180251231],
              [-81.104384754067411, 34.998847215308906],
              [-81.104436489139829, 34.998582371125558],
              [-81.103454410468018, 34.998456801174562],
              [-81.103506149381701, 34.998191956460659],
              [-81.103178791180639, 34.998150098780116],
              [-81.103230529698266, 34.997885254196333],
              [-81.102575818539748, 34.997801535509033],
              [-81.102627558791468, 34.997536691175753],
              [-81.102300204738739, 34.997494830188209],
              [-81.102351945689861, 34.997229985966051],
              [-81.102024593030549, 34.997188125130783],
              [-81.102179818070596, 34.996393592693032],
              [-81.101852468827985, 34.99635173048636],
              [-81.102162917130272, 34.994762664762007],
              [-81.102490260164259, 34.994804526132057],
              [-81.10264547500438, 34.994009991146022],
              [-81.102972815269524, 34.994051851209441],
              [-81.103128023962995, 34.993257314649156],
              [-81.102800687920379, 34.993215455886286],
              [-81.102904161870057, 34.992685764964449],
              [-81.102576827113964, 34.992643904709844],
              [-81.102628565167933, 34.992379059336372],
              [-81.102301232877124, 34.992337198313734],
              [-81.102404707834395, 34.991807506897857],
              [-81.102077377948419, 34.99176564526573],
              [-81.102129115970143, 34.991500800105527],
              [-81.101801788549594, 34.991458937705275],
              [-81.101853527270407, 34.991194092656279],
              [-81.101526200101603, 34.991152228624905],
              [-81.101629679678339, 34.990622537800817],
              [-81.101302354937388, 34.990580674061263],
              [-81.101354095816376, 34.990315829187388],
              [-81.101026772445536, 34.990273964698936],
              [-81.101078514000477, 34.990009119035022],
              [-81.100751191999748, 34.989967253797658],
              [-81.100802934253934, 34.989702408244945],
              [-81.100475613623288, 34.989660542258783],
              [-81.100527356576507, 34.989395696817247],
              [-81.100200037315958, 34.989353830082202],
              [-81.100251780968293, 34.989088984752023],
              [-81.099597146594917, 34.989005247975328],
              [-81.099648891980848, 34.988740402895871],
              [-81.098012319841146, 34.988531046508953],
              [-81.097960569282264, 34.988795890891033],
              [-81.096978626925633, 34.98867026616086],
              [-81.097030380588123, 34.988405422197197],
              [-81.096375756066408, 34.988321667012777],
              [-81.096427511462508, 34.9880568232999],
              [-81.095772890375272, 34.98797306574243],
              [-81.095824648599944, 34.987708222261368],
              [-81.095497339582678, 34.987666341839123],
              [-81.095549097411251, 34.987401498488374],
              [-81.094894483547463, 34.987317735238804],
              [-81.09494624310949, 34.987052892138969],
              [-81.094618938263054, 34.987011009311757],
              [-81.09467069852397, 34.986746166323186],
              [-81.094343395047716, 34.986704282747205],
              [-81.09439515598433, 34.986439438968723],
              [-81.094067853878173, 34.986397554644007],
              [-81.094119615536869, 34.986132711878113],
              [-81.093792314800666, 34.986090826804656],
              [-81.093844078230291, 34.985825983229894],
              [-81.093516777769025, 34.985784097426624],
              [-81.093568541897511, 34.985519253963247],
              [-81.093241243901431, 34.985477367392285],
              [-81.093293008751857, 34.98521252494151],
              [-81.092965712125931, 34.985170637621842],
              [-81.093017476556966, 34.984905794400241],
              [-81.092690181300995, 34.984863906331746],
              [-81.09274194752598, 34.984599063202566],
              [-81.092414653616956, 34.984557173484056],
              [-81.092466420540745, 34.984292330466275],
              [-81.092139128024868, 34.984250440900439],
              [-81.092190895647448, 34.983985597994007],
              [-81.091863604501668, 34.983943707679465],
              [-81.091915372822953, 34.983678864884546],
              [-81.091588083047199, 34.983636973821206],
              [-81.091639852044267, 34.98337213023644],
              [-81.090985276663346, 34.98328834570524],
              [-81.091037047393542, 34.983023502371474],
              [-81.09070976178856, 34.98298160890355],
              [-81.090761533240467, 34.982716766582541],
              [-81.090434247887316, 34.982674871483709],
              [-81.090382475424022, 34.982939714566456],
              [-81.090055190467169, 34.982897818420803],
              [-81.090106963987907, 34.982632976378902],
              [-81.086834138960157, 34.982213972048704],
              [-81.086885923583665, 34.981949131355563],
              [-81.085576808838752, 34.981781504380422],
              [-81.085628597240756, 34.981516663316221],
              [-81.084974042867799, 34.981432845248278],
              [-81.085025834097621, 34.981168004416283],
              [-81.084698558985153, 34.981126093729571],
              [-81.08475034981852, 34.98086125302796],
              [-81.084423076075979, 34.980819341592678],
              [-81.084474867607881, 34.980554501002693],
              [-81.083820324293697, 34.98047067572773],
              [-81.083872117558357, 34.980205835389015],
              [-81.083544846891641, 34.980163921568348],
              [-81.08359664194991, 34.979899081322195],
              [-81.083269372653135, 34.979857166753021],
              [-81.083321167314736, 34.979592326637345],
              [-81.082666632892312, 34.979508495094841],
              [-81.082718430381632, 34.979243655211491],
              [-81.08206389934783, 34.979159819494356],
              [-81.082115698569694, 34.97889497986224],
              [-81.081461170970471, 34.978811141772972],
              [-81.081512971902072, 34.978546301490901],
              [-81.080858447714377, 34.97846246012837],
              [-81.080910250378551, 34.978197620097482],
              [-81.08025573069763, 34.978113775442864],
              [-81.080307535094335, 34.97784893566326],
              [-81.078671246893947, 34.977639307380066],
              [-81.078723056125597, 34.977374468270789],
              [-81.078395800536384, 34.977332540450014],
              [-81.078447610466327, 34.977067701452313],
              [-81.077138596653938, 34.976899980025209],
              [-81.077086782587457, 34.977164818464068],
              [-81.075777769989898, 34.976997081367095],
              [-81.075725950356016, 34.97726191923811],
              [-81.075071444540498, 34.977178044621517],
              [-81.075019622502452, 34.977442882185187],
              [-81.074365114890412, 34.977359004656819],
              [-81.074313290425309, 34.97762384101167],
              [-81.07333152950801, 34.977498017169879],
              [-81.073279701604591, 34.977762853077571],
              [-81.072297939513817, 34.977637020842408],
              [-81.072246108172067, 34.977901856303006],
              [-81.071264347075143, 34.977776014735561],
              [-81.07093709368317, 34.977734065776481],
              [-81.070885256796828, 34.977998901570139],
              [-81.07023075004787, 34.977915000689407],
              [-81.070178910734441, 34.97817983527424],
              [-81.069524402166365, 34.978095930580437],
              [-81.069472561543705, 34.978360764838989],
              [-81.069145306729752, 34.978318811020145],
              [-81.069093463641892, 34.978583645129625],
              [-81.068766208129816, 34.978541690282917],
              [-81.068714363671774, 34.978806524224673],
              [-81.068387107461675, 34.978764568350073],
              [-81.068335261633422, 34.979029402123935],
              [-81.068008004725399, 34.978987445221556],
              [-81.06795615752695, 34.97925227882758],
              [-81.06762889992082, 34.979210320897266],
              [-81.067577051352131, 34.979475154335439],
              [-81.067249793048006, 34.979433195377318],
              [-81.067197942014047, 34.979698028666419],
              [-81.066870682989119, 34.979656067779047],
              [-81.066818831680052, 34.979920900881602],
              [-81.06583705356546, 34.97979501427411],
              [-81.064528019675222, 34.979627152448515],
              [-81.064579879318217, 34.979362320305697],
              [-81.063925368101039, 34.979278384315791],
              [-81.063977229475924, 34.979013552424483],
              [-81.063649974869605, 34.978971583697223],
              [-81.063701836942485, 34.978706751917692],
              [-81.063374583683583, 34.978664781540751],
              [-81.063426446454329, 34.978399949872966],
              [-81.063099194565694, 34.978357978747738],
              [-81.063151058034293, 34.978093147191764],
              [-81.062823808610972, 34.978051175299562],
              [-81.062875672777409, 34.977786343855236],
              [-81.062548424724326, 34.977744371214762],
              [-81.06260028956585, 34.977479538980909],
              [-81.061618549453812, 34.977353617070612],
              [-81.061566680415183, 34.977618448903719],
              [-81.060912186240017, 34.977534495648868],
              [-81.060964057346709, 34.977269664095417],
              [-81.060309566584564, 34.977185707567941],
              [-81.06036143944587, 34.976920877167338],
              [-81.059706952096775, 34.976836917367372],
              [-81.059758826666979, 34.976572086317177],
              [-81.059431585078329, 34.976530105215524],
              [-81.059483460346271, 34.976265274277118],
              [-81.058828980245735, 34.976181309689451],
              [-81.058880856150353, 34.9759164790214],
              [-81.058553617638481, 34.975874495535301],
              [-81.058605495335783, 34.975609664960395],
              [-81.05827825819415, 34.975567680726179],
              [-81.058330136589134, 34.975302850263027],
              [-81.057675666477721, 34.975218879391456],
              [-81.057727546604298, 34.97495404918007],
              [-81.05707307878815, 34.974870074153543],
              [-81.057124961741195, 34.97460524417518],
              [-81.05679772938295, 34.974563255920444],
              [-81.056849611916022, 34.974298425171327],
              [-81.056195151371099, 34.974214446258763],
              [-81.056247035635664, 34.973949615761406],
              [-81.055919807449072, 34.973907625103607],
              [-81.0559716924339, 34.973642795619426],
              [-81.055644465594852, 34.973600803312216],
              [-81.055748236600621, 34.973071143638371],
              [-81.055421012188333, 34.973029151624218],
              [-81.055472899304235, 34.972764321415895],
              [-81.055145675144502, 34.972722327771073],
              [-81.055197562980609, 34.97245749857597],
              [-81.054870341286033, 34.97241550416431],
              [-81.054922228702026, 34.972150674198559],
              [-81.054595008400298, 34.972108679940092],
              [-81.054698785385924, 34.97157902018575],
              [-81.054371567465637, 34.971537024417785],
              [-81.054423457035156, 34.971272194629265],
              [-81.05409624050759, 34.97123019901462],
              [-81.054148130752012, 34.970965368436651],
              [-81.053493700750963, 34.970881373921898],
              [-81.053545592749231, 34.970616544497169],
              [-81.053218379275762, 34.970574545597174],
              [-81.053270271971542, 34.970309716284426],
              [-81.052943060963074, 34.97026771661762],
              [-81.052994953261347, 34.970002887435513],
              [-81.052667743623005, 34.969960887020768],
              [-81.052771530350242, 34.969431227932695],
              [-81.052444323115893, 34.969389226909755],
              [-81.052703790825049, 34.968065078021006],
              [-81.052376589095658, 34.968023076809693],
              [-81.052532268334616, 34.967228587028309],
              [-81.052205070042717, 34.967186585348863],
              [-81.052256963848251, 34.966921755499769],
              [-81.051929766926506, 34.966879753072355],
              [-81.051981661429465, 34.966614923335271],
              [-81.051654465877661, 34.96657292015994],
              [-81.051706361055324, 34.966308089633564],
              [-81.051379167968449, 34.966266085691622],
              [-81.051431063866033, 34.966001256178728],
              [-81.051103871054309, 34.965959251507378],
              [-81.051259559784583, 34.965164761418876],
              [-81.05093237041028, 34.965122756279413],
              [-81.051036163267497, 34.964593095760378],
              [-81.050708977391594, 34.964551089994309],
              [-81.050812770970722, 34.96402142964385],
              [-81.0504855874984, 34.963979423269883],
              [-81.050537484817042, 34.963714593192819],
              [-81.050210302714774, 34.963672586070928],
              [-81.05031409838746, 34.963142925212168],
              [-81.049986918688745, 34.96310091748223],
              [-81.050038817065399, 34.962836087601801],
              [-81.049711639808748, 34.962794078204119],
              [-81.049763538882686, 34.962529248435857],
              [-81.049436361923767, 34.962487239210127],
              [-81.049540161107075, 34.961957578968899],
              [-81.049212987623847, 34.961915568215417],
              [-81.049264887755797, 34.961650738643783],
              [-81.048937715642509, 34.961608727142334],
              [-81.049041516941742, 34.96107906729442],
              [-81.048714347231879, 34.961037055185081],
              [-81.048766248399161, 34.960772224908837],
              [-81.048439080081792, 34.960730212953017],
              [-81.04849098194623, 34.960465382789003],
              [-81.048163814976149, 34.96042336918395],
              [-81.048267620857658, 34.959893709034056],
              [-81.047940456313555, 34.959851695722534],
              [-81.04799235923582, 34.959586865755298],
              [-81.047665196038977, 34.959544850794529],
              [-81.047717099658357, 34.959280020939566],
              [-81.047389938926173, 34.95923800521247],
              [-81.047493747200022, 34.958708344798055],
              [-81.047166588893816, 34.958666329364384],
              [-81.047270398983798, 34.958136669100604],
              [-81.046943241963518, 34.958094652176442],
              [-81.04699514697873, 34.957829821701644],
              [-81.046667992422954, 34.957787804011026],
              [-81.046771804628335, 34.957258144141122],
              [-81.046117499564843, 34.957174106675183],
              [-81.046169407766001, 34.956909276518786],
              [-81.045842257319634, 34.956867256584815],
              [-81.045894165145413, 34.956602427460638],
              [-81.045567016068844, 34.956560406778991],
              [-81.045618925663973, 34.956295576847275],
              [-81.044964630586747, 34.956211533100642],
              [-81.045016541912219, 34.95594670342129],
              [-81.044689395889094, 34.955904679455017],
              [-81.04474130681659, 34.955639849906674],
              [-81.044414163280635, 34.955597826075419],
              [-81.044466074905145, 34.955332996639356],
              [-81.044138932738875, 34.955290972060439],
              [-81.044190846155047, 34.955026142718218],
              [-81.043863704241275, 34.954984116508761],
              [-81.043967532108354, 34.954454457120185],
              [-81.043640392620219, 34.95441243120441],
              [-81.04369230709375, 34.954147602059294],
              [-81.043365168952818, 34.95410557449452],
              [-81.043417084123234, 34.953840745461903],
              [-81.043089948469387, 34.953798718032161],
              [-81.043245694994212, 34.953004228484936],
              [-81.042918562776578, 34.952962200587493],
              [-81.043022394177598, 34.952432541031477],
              [-81.042695264340409, 34.952390511625168],
              [-81.04285101201836, 34.951596022504503],
              [-81.042523885617456, 34.951553992630522],
              [-81.042575801870427, 34.951289163008553],
              [-81.042248676861632, 34.951247133288291],
              [-81.042300593788966, 34.950982302877506],
              [-81.041973471244503, 34.950940272390987],
              [-81.04207730617884, 34.950410612668193],
              [-81.041750184920048, 34.950368580691425],
              [-81.041802102927022, 34.950103751379345],
              [-81.04114786457987, 34.950019685024309],
              [-81.041199785411436, 34.949754855946345],
              [-81.040872667228385, 34.949712821586566],
              [-81.040924588734185, 34.949447991719843],
              [-81.040597471943272, 34.949405957513825],
              [-81.040649394145774, 34.949141127759674],
              [-81.040322278702007, 34.949099091904699],
              [-81.04037420160121, 34.94883426226307],
              [-81.040047088621705, 34.948792225642059],
              [-81.040099011122962, 34.948527396131503],
              [-81.039771899535566, 34.948485359664147],
              [-81.039823822711014, 34.948220529364875],
              [-81.039496712493232, 34.948178492150014],
              [-81.039548637459973, 34.947913661944789],
              [-81.03922152861179, 34.947871623982294],
              [-81.03927345318057, 34.947606793908186],
              [-81.038946346796592, 34.94756475517967],
              [-81.038998272062017, 34.947299925218182],
              [-81.038344061230134, 34.947215843594279],
              [-81.038395988247771, 34.946951014786734],
              [-81.038068884916967, 34.94690897277416],
              [-81.038120812608724, 34.946644143177934],
              [-81.037793710647506, 34.946602100417905],
              [-81.037845640152952, 34.946337271817107],
              [-81.037191438211522, 34.946253183932811],
              [-81.037243369424147, 34.945988354683443],
              [-81.036589170894885, 34.945904263528945],
              [-81.036641103837113, 34.945639434532289],
              [-81.03598690872002, 34.945555340107603],
              [-81.036038843391822, 34.94529051136373],
              [-81.035384652759063, 34.94520641274908],
              [-81.035436589160412, 34.944941584258004],
              [-81.034782401962289, 34.944857483274561],
              [-81.034834340093141, 34.944592655036253],
              [-81.033853064896661, 34.94446649642336],
              [-81.033905005789975, 34.944201668577989],
              [-81.033577916843569, 34.944159613452612],
              [-81.033629858433216, 34.943894785720019],
              [-81.032648594568158, 34.943768617278543],
              [-81.032700538897856, 34.943503789037528],
              [-81.031719281160875, 34.943377613028545],
              [-81.031771229347441, 34.9431127851621],
              [-81.030462895257145, 34.942944538005129],
              [-81.030514847238905, 34.942679710671875],
              [-81.029533603840605, 34.94255351594559],
              [-81.0295855585845, 34.942288689005508],
              [-81.02827724325553, 34.94212041719905],
              [-81.028329201794421, 34.941855590792265],
              [-81.028002125104763, 34.941813520532072],
              [-81.028365834869746, 34.93995973586204],
              [-81.02869290432929, 34.940001805140994],
              [-81.029212449670112, 34.937353531307991],
              [-81.02888539053879, 34.937311463430895],
              [-81.028937345100786, 34.937046634851207],
              [-81.028610287338864, 34.937004566226797],
              [-81.028714196468727, 34.936474911086954],
              [-81.028387142203542, 34.936432841836961],
              [-81.028491050935628, 34.935903185985552],
              [-81.028163999072461, 34.935861116128585],
              [-81.028319863827647, 34.935066632098533],
              [-81.027992815399315, 34.935024561774668],
              [-81.028200634118875, 34.933965249619703],
              [-81.027873591230076, 34.933923178049525],
              [-81.027977500077242, 34.933393521647666],
              [-81.027650459612801, 34.933351450371696],
              [-81.02770241456416, 34.933086622270196],
              [-81.027375375446624, 34.933044549345617],
              [-81.02747928640477, 34.932514893341491],
              [-81.027152249689181, 34.932472819809938],
              [-81.027204206790472, 34.932207991888816],
              [-81.026877171444141, 34.932165917610156],
              [-81.026929128147032, 34.931901089820485],
              [-81.026602094169959, 34.931859014794554],
              [-81.026654052663289, 34.931594187099584],
              [-81.026327020055476, 34.931552111326454],
              [-81.02643093698066, 34.931022455252446],
              [-81.026103906774793, 34.930980378872405],
              [-81.026155865206732, 34.930715550493417],
              [-81.025828837486756, 34.930673474249076],
              [-81.025880796614572, 34.93040864598315],
              [-81.025553770241487, 34.930366568090413],
              [-81.025605730065379, 34.930101739937584],
              [-81.024951679322157, 34.930017582690077],
              [-81.025003641968979, 34.92975275477216],
              [-81.024676618670924, 34.929710674497791],
              [-81.024728580919287, 34.929445846711317],
              [-81.02407453742056, 34.929361684682235],
              [-81.024126502469457, 34.929096856229414],
              [-81.023799482268771, 34.929054774474714],
              [-81.023903412349526, 34.928525118687965],
              [-81.023576394528263, 34.928483035425081],
              [-81.02362836065457, 34.928218208072778],
              [-81.023301344202494, 34.928176124062787],
              [-81.023353311002353, 34.92791129592225],
              [-81.02302629591945, 34.927869211165245],
              [-81.023078263437469, 34.927604384039164],
              [-81.022751249723726, 34.927562298534987],
              [-81.022803217915268, 34.927297470620772],
              [-81.022476206665061, 34.927255384351241],
              [-81.022528175552424, 34.926990556550173],
              [-81.022201164577012, 34.926948469551867],
              [-81.02225313416011, 34.926683641864081],
              [-81.021926124553843, 34.92664155411871],
              [-81.021978095927167, 34.926376726525618],
              [-81.02165108769006, 34.926334638033303],
              [-81.021703058664812, 34.926069810571782],
              [-81.021049046359892, 34.925985631187324],
              [-81.021101020157218, 34.9257208039609],
              [-81.020774014972773, 34.9256787121858],
              [-81.020825989465834, 34.925413885072693],
              [-81.020171983310817, 34.925329700925523],
              [-81.020223959509664, 34.92506487316453],
              [-81.019569957860739, 34.924980685730404],
              [-81.019621934693376, 34.92471585824125],
              [-81.018640937820166, 34.924589569955081],
              [-81.018692918529965, 34.924324743742716],
              [-81.018365921263225, 34.924282645412148],
              [-81.018417901574367, 34.924017819331382],
              [-81.017763911232478, 34.923933621172182],
              [-81.017815894343684, 34.923668794425346],
              [-81.017161906319174, 34.923584593015917],
              [-81.017213891158363, 34.923319766522667],
              [-81.016886899789029, 34.923277665059757],
              [-81.016938884229504, 34.923012838698128],
              [-81.015630923993882, 34.922844421867801],
              [-81.015578934329739, 34.923109247686483],
              [-81.014924954179051, 34.923025034117877],
              [-81.014872962091232, 34.9232898587276],
              [-81.013891991830278, 34.923163530839638],
              [-81.013943986992871, 34.922898705749574],
              [-81.013290010210284, 34.9228144833085],
              [-81.013342008194982, 34.922549658453832],
              [-81.012361049158159, 34.922423317198017],
              [-81.012413049902918, 34.922158492737445],
              [-81.012086064533662, 34.922116377363551],
              [-81.012138065973858, 34.921851553016246],
              [-81.011484100477077, 34.921767318949684],
              [-81.011536103644985, 34.921502494856199],
              [-81.011209122445251, 34.921460377083086],
              [-81.011261126308526, 34.921195553102834],
              [-81.010607166984187, 34.921111315175828],
              [-81.010659172552948, 34.92084649054808],
              [-81.010332194428344, 34.920804370394087],
              [-81.010384200692471, 34.920539545879684],
              [-81.010057223936883, 34.920497424978905],
              [-81.010109230918516, 34.920232601479235],
              [-81.009782256604268, 34.920190478912019],
              [-81.009834264281224, 34.919925655525716],
              [-81.0095072902418, 34.919883532230024],
              [-81.009559298614093, 34.919618708957145],
              [-81.009232327038049, 34.91957658489639],
              [-81.009284336105566, 34.91931176173695],
              [-81.00895736589861, 34.919269636929322],
              [-81.009009375639351, 34.919004812982003],
              [-81.008355437250643, 34.91892056280701],
              [-81.008407448718756, 34.91865573911344],
              [-81.006445649931365, 34.918402964496963],
              [-81.00649766834988, 34.918138141600679],
              [-81.005516777148443, 34.918011742727927],
              [-81.005568798326436, 34.91774692022598],
              [-81.00491487459314, 34.917662649555012],
              [-81.004966897498321, 34.917397827306942],
              [-81.003332099245014, 34.917187136708023],
              [-81.003280071179191, 34.917451958253892],
              [-81.000010492844211, 34.917030506358103],
              [-81.000062532325146, 34.916765686198616],
              [-80.999735577051453, 34.916723536365829],
              [-80.999683536538456, 34.916988356384877],
              [-80.9987026707159, 34.916861900223118],
              [-80.998650626791644, 34.917126720695109],
              [-80.997342807000948, 34.916958098884855],
              [-80.997394855075441, 34.916693279876199],
              [-80.995760093618856, 34.916482482896093],
              [-80.995812147610621, 34.91621766454486],
              [-80.992542661378053, 34.915796004538066],
              [-80.992490597066293, 34.916060821484109],
              [-80.991836702445568, 34.915976478004964],
              [-80.991784635754556, 34.91624129554431],
              [-80.991457686823892, 34.916199122351671],
              [-80.991353550344073, 34.916728756167053],
              [-80.991026599686663, 34.916686581806246],
              [-80.990922458677503, 34.91721621434958],
              [-80.990268556434955, 34.917131862368244],
              [-80.990320628625057, 34.916867046400185],
              [-80.989666729795672, 34.916782691151603],
              [-80.989718803712393, 34.916517875437677],
              [-80.989391855813707, 34.916475695722042],
              [-80.989496006887862, 34.915946064458439],
              [-80.989169061412341, 34.915903885038183],
              [-80.989273212085024, 34.915374253064712],
              [-80.987965442883976, 34.915205526717955],
              [-80.988017522378343, 34.914940710793431],
              [-80.987690581685243, 34.91489852735954],
              [-80.987036701289114, 34.914814156929275],
              [-80.98708878354195, 34.914549341399599],
              [-80.986761845986862, 34.91450715542701],
              [-80.986813928934282, 34.9142423400111],
              [-80.985833119178835, 34.914115776327648],
              [-80.985885205978803, 34.91385096128851],
              [-80.985558270720929, 34.913808771165741],
              [-80.985610357121161, 34.91354395625833],
              [-80.985283424348708, 34.913501766272397],
              [-80.985335512537631, 34.913236951460668],
              [-80.984681648930447, 34.913152567324282],
              [-80.984733737751668, 34.912887752784897],
              [-80.98440680805497, 34.912845560419015],
              [-80.98445889864287, 34.912580745074131],
              [-80.983805042303445, 34.91249635706135],
              [-80.983857133545456, 34.912231542890055],
              [-80.982549429020821, 34.912062755881585],
              [-80.982497332557315, 34.912327569508285],
              [-80.982170406232385, 34.912285370172718],
              [-80.982222503727726, 34.912020556686549],
              [-80.981241729872977, 34.911893953779611],
              [-80.981293831198641, 34.911629139769211],
              [-80.979986141576987, 34.911460324037911],
              [-80.979332299885215, 34.911375910832191],
              [-80.979384405991794, 34.911111098558251],
              [-80.977749812258097, 34.910900049832954],
              [-80.977697700992593, 34.911164861403641],
              [-80.977370781793269, 34.911122649044366],
              [-80.977318668086212, 34.911387461366765],
              [-80.976991749265025, 34.911345247060616],
              [-80.976939634188568, 34.911610059215477],
              [-80.976612713600773, 34.911567844800864],
              [-80.976560597133229, 34.911832655886826],
              [-80.975579835372159, 34.911706005979902],
              [-80.975527715471742, 34.911970816616865],
              [-80.97487387318526, 34.911886378340768],
              [-80.974821750905591, 34.912151189570793],
              [-80.973840987995658, 34.912024524613521],
              [-80.973788861167151, 34.912289334511193],
              [-80.972808098222202, 34.912162662050228],
              [-80.972755969054873, 34.912427471480861],
              [-80.971775204937387, 34.912300789731574],
              [-80.971723072337127, 34.912565598713108],
              [-80.969761548122563, 34.912312210403826],
              [-80.969813689079913, 34.91204740132963],
              [-80.969159853176677, 34.911962932034072],
              [-80.969264136038504, 34.911433315305032],
              [-80.968937221193116, 34.911391079148757],
              [-80.968989363138377, 34.911126270433989],
              [-80.968662449684103, 34.911084034432761],
              [-80.968923161713292, 34.909759992025094],
              [-80.968596253733423, 34.909717754939457],
              [-80.968648395715888, 34.909452946344956],
              [-80.967994582832588, 34.909368469794451],
              [-80.9680467276347, 34.909103661436738],
              [-80.967719822731269, 34.909061421971813],
              [-80.967771967133288, 34.908796613745885],
              [-80.967445064693337, 34.908754373516999],
              [-80.967497209767586, 34.908489564503668],
              [-80.966843406869955, 34.908405081684492],
              [-80.966895554785452, 34.90814027380943],
              [-80.965587956031939, 34.907971296306464],
              [-80.965640107736121, 34.907706488967648],
              [-80.965313210755127, 34.907664242952748],
              [-80.965261058019323, 34.907929050150706],
              [-80.964607261891615, 34.907844554295131],
              [-80.964711571130238, 34.907314939534118],
              [-80.964384676194072, 34.907272690999271],
              [-80.964436831327546, 34.907007883268747],
              [-80.964109937760725, 34.906965633987895],
              [-80.964162093587888, 34.906700826371214],
              [-80.96383520139041, 34.906658576344277],
              [-80.963939514094633, 34.906128961312248],
              [-80.963612624298023, 34.906086710680029],
              [-80.963664781175069, 34.905821903264652],
              [-80.96333789272613, 34.90577965098506],
              [-80.963546522076697, 34.904720421601091],
              [-80.963873406377886, 34.904762672416098],
              [-80.963925561566015, 34.904497864866762],
              [-80.964252444101149, 34.904540115573326],
              [-80.96430459792002, 34.904275307856423],
              [-80.964631480833717, 34.9043175566161],
              [-80.964683633283414, 34.904052748731623],
              [-80.965010515503351, 34.904094996463698],
              [-80.965114816210786, 34.903565379449411],
              [-80.965441696727098, 34.903607626914265],
              [-80.965598142697118, 34.902813200310412],
              [-80.965925019341071, 34.902855445582524],
              [-80.966185750333821, 34.901531399409386],
              [-80.965858878825671, 34.901489153939934],
              [-80.965963171766731, 34.900959534289214],
              [-80.965636302680721, 34.900917289115888],
              [-80.965740595239851, 34.900387669657874],
              [-80.965413728532695, 34.90034542297802],
              [-80.965465875895035, 34.900080613791559],
              [-80.965139010556996, 34.900038366365621],
              [-80.965191158591281, 34.899773556392034],
              [-80.964864294643974, 34.899731309121428],
              [-80.964916443372076, 34.899466499261941],
              [-80.964589581866193, 34.89942425032627],
              [-80.964641730193875, 34.899159440598858],
              [-80.96431487005708, 34.899117190917124],
              [-80.964367020194175, 34.898852382187286],
              [-80.964040160332459, 34.898810131777509],
              [-80.964092311141471, 34.898545322260517],
              [-80.963765453742809, 34.898503071086928],
              [-80.963817605245552, 34.898238261684149],
              [-80.963163892430089, 34.898153756976058],
              [-80.963216045657816, 34.897888947828285],
              [-80.962889191334881, 34.897846694276026],
              [-80.962941345256226, 34.897581885242474],
              [-80.962614492302436, 34.897539630944287],
              [-80.962666646895741, 34.897274821123638],
              [-80.962339795310996, 34.897232566079516],
              [-80.962391951713386, 34.896967757256597],
              [-80.9620651014977, 34.896925501466484],
              [-80.962117257478155, 34.896660691874345],
              [-80.961790408631487, 34.896618435338354],
              [-80.961842565327132, 34.896353626761694],
              [-80.961188871781928, 34.89626911039214],
              [-80.961241030202288, 34.896004302070601],
              [-80.960587340070646, 34.895919782435691],
              [-80.960639500194262, 34.895654973467948],
              [-80.960312657224122, 34.895612712903045],
              [-80.960521300653824, 34.894553477294195],
              [-80.960848139520607, 34.89459573819699],
              [-80.961108929368265, 34.89327169060811],
              [-80.959801597710623, 34.893102647179965],
              [-80.95985375856003, 34.892837837608795],
              [-80.959526927253094, 34.892795574905684],
              [-80.959200096262308, 34.892753310414463],
              [-80.959252259951739, 34.892488501982101],
              [-80.957944944606425, 34.892319436597568],
              [-80.957997112082808, 34.892054628702141],
              [-80.957670284843232, 34.892012360059333],
              [-80.957618116357366, 34.89227716871514],
              [-80.957291289518253, 34.892234899026988],
              [-80.957343459035357, 34.891970090512082],
              [-80.95701663247138, 34.89192782009583],
              [-80.95706880270339, 34.891663012596631],
              [-80.956741977508358, 34.891620741434622],
              [-80.956846318978037, 34.891091124835768],
              [-80.955539033128119, 34.890922033296391],
              [-80.955591207480722, 34.890657225520798],
              [-80.954937569181126, 34.890572674253434],
              [-80.954989745258004, 34.890307866733245],
              [-80.954662927646041, 34.89026558991047],
              [-80.954715104394708, 34.89000078160332],
              [-80.954388288151662, 34.88995850403488],
              [-80.954492643834513, 34.889428889407974],
              [-80.954165829969895, 34.889386610333339],
              [-80.954322363485105, 34.888592187724754],
              [-80.95464917423503, 34.888634465475214],
              [-80.954701349987772, 34.888369657150264],
              [-80.955028160044463, 34.888411933873172],
              [-80.955132508474321, 34.887882316861962],
              [-80.955459316806781, 34.887924592416503],
              [-80.955668005488818, 34.886865357513351],
              [-80.955341202374271, 34.886823082504755],
              [-80.955549889753968, 34.88576384684152],
              [-80.955223091101161, 34.885721571509968],
              [-80.95532743588771, 34.885191952432024],
              [-80.955000639634619, 34.885149676495821],
              [-80.955052812005306, 34.8848848670671],
              [-80.955104984059673, 34.884620058513406],
              [-80.954451396669114, 34.884535503643548],
              [-80.954555743845233, 34.884005885217974],
              [-80.954228953823076, 34.883963607168006],
              [-80.954281127935403, 34.883698798056614],
              [-80.953627549851007, 34.883614238695259],
              [-80.953679725687365, 34.883349429839384],
              [-80.953026151015919, 34.883264867213597],
              [-80.953078329670035, 34.883000058595535],
              [-80.952751543335751, 34.882957776553333],
              [-80.952803722661415, 34.882692967148579],
              [-80.951823370944496, 34.882566115188638],
              [-80.951875551931096, 34.882301306198123],
              [-80.951548770103756, 34.882259020873896],
              [-80.951600952877229, 34.881994211980306],
              [-80.950947392276291, 34.881909638052768],
              [-80.950999575679774, 34.881644829432588],
              [-80.950672796927662, 34.881602541730707],
              [-80.95072498211799, 34.881337733207396],
              [-80.949744650938229, 34.881210863402742],
              [-80.949796838883174, 34.880946055276034],
              [-80.949470063522682, 34.880903763408611],
              [-80.949522252160563, 34.880638955396655],
              [-80.948868705629963, 34.880554370167914],
              [-80.948920895991577, 34.880289562411562],
              [-80.948594123706329, 34.880247268166642],
              [-80.948646315854504, 34.879982460507385],
              [-80.948319544937931, 34.87994016551685],
              [-80.948371736685345, 34.879675357989917],
              [-80.948044968231173, 34.87963306223628],
              [-80.948149352752296, 34.879103446484315],
              [-80.946842289466929, 34.87893425575043],
              [-80.946894485354406, 34.878669448849685],
              [-80.945914195329635, 34.878542545999778],
              [-80.945966393971275, 34.878277739495807],
              [-80.945639632321971, 34.878235436312941],
              [-80.945848430870612, 34.877176208743087],
              [-80.945521673682066, 34.877133905237834],
              [-80.945573872674132, 34.876869097983445],
              [-80.945247117969345, 34.87682679461642],
              [-80.945351518094668, 34.876297180293264],
              [-80.944371259983015, 34.876170264853833],
              [-80.944319056450524, 34.876435071614239],
              [-80.943338798288451, 34.876308147773763],
              [-80.943012046958586, 34.876265837441423],
              [-80.943064254613773, 34.876001031245103],
              [-80.942737503580247, 34.875958721086377],
              [-80.942789711906613, 34.875693914103572],
              [-80.942462963335387, 34.875651603181794],
              [-80.94251517235449, 34.875386796313826],
              [-80.942188425151869, 34.875344484646703],
              [-80.942292844258688, 34.874814872015619],
              [-80.941966099433799, 34.874772558842828],
              [-80.942018309521572, 34.874507753079733],
              [-80.941691566065359, 34.874465439161597],
              [-80.941795987266659, 34.873935826937718],
              [-80.941142505490575, 34.873851197006402],
              [-80.941194718749799, 34.873586391570299],
              [-80.939887763375864, 34.873417121635271],
              [-80.939939980397938, 34.873152315835988],
              [-80.939613243429989, 34.87310999627735],
              [-80.939665461144571, 34.872845190592976],
              [-80.939338725545284, 34.872802870289028],
              [-80.93939094395229, 34.872538064719613],
              [-80.938737476922526, 34.872453421717054],
              [-80.938789695958917, 34.872188616421326],
              [-80.937482770077978, 34.872019320344123],
              [-80.937534993992088, 34.871754515569037],
              [-80.937208264922887, 34.871712188564786],
              [-80.937260489529422, 34.871447383904659],
              [-80.936933761850213, 34.871405057056442],
              [-80.936985987127827, 34.87114025160998],
              [-80.936659259723442, 34.871097924034189],
              [-80.936763712440296, 34.870568314228713],
              [-80.937090436690099, 34.870610641539862],
              [-80.937142660974786, 34.870345836916371],
              [-80.937796108427563, 34.870430488597442],
              [-80.937900550746036, 34.869900877823937],
              [-80.938227272918539, 34.869943202052745],
              [-80.938279493076351, 34.869678396026259],
              [-80.938606214556501, 34.869720719227708],
              [-80.938710650703641, 34.869191106831835],
              [-80.938383932378073, 34.869148783895035],
              [-80.938436150428203, 34.868883977807918],
              [-80.938109433449739, 34.868841653224564],
              [-80.938161652192377, 34.868576847252427],
              [-80.937834936603622, 34.868534522825193],
              [-80.937887156038656, 34.868269716968094],
              [-80.937560441797089, 34.868227390894319],
              [-80.937612663018101, 34.86796258513467],
              [-80.937717103331011, 34.867432972653596],
              [-80.93739039254001, 34.867390647018119],
              [-80.937442613755891, 34.867125840420123],
              [-80.936789194133823, 34.867041185888716],
              [-80.936841417093774, 34.86677638044825],
              [-80.934881174709957, 34.866522396394544],
              [-80.934828945567844, 34.86678720098822],
              [-80.933848825291349, 34.8666601961312],
              [-80.93379659274143, 34.866925001176753],
              [-80.933469885985886, 34.866882664004386],
              [-80.933522119587465, 34.866617860001249],
              [-80.933195414200384, 34.86657552208375],
              [-80.933247649566553, 34.866310717276846],
              [-80.932920945569123, 34.866268379515525],
              [-80.93302541625421, 34.865738770123428],
              [-80.932698714634256, 34.865696430856815],
              [-80.932803186027513, 34.865166821643058],
              [-80.93247648789982, 34.865124481754869],
              [-80.932528723572815, 34.864859677258856],
              [-80.932202026813627, 34.864817336625705],
              [-80.932306500277988, 34.864287726919123],
              [-80.931979805917393, 34.864245385681976],
              [-80.932032042636592, 34.863980581390301],
              [-80.931705349644474, 34.86393823940805],
              [-80.931914300477729, 34.862879019789681],
              [-80.932240989348855, 34.862921361207263],
              [-80.932345460140326, 34.86239175141943],
              [-80.932672147289097, 34.862434091668447],
              [-80.932724381683471, 34.862169286135043],
              [-80.933051068118885, 34.862211624455334],
              [-80.933155532739534, 34.861682013045865],
              [-80.933482217473795, 34.861724351098978],
              [-80.933586677588224, 34.861194739321029],
              [-80.933913360600116, 34.861237076205612],
              [-80.933965590192258, 34.860972269217662],
              [-80.934292271418514, 34.861014605092514],
              [-80.934501179047814, 34.859955378172529],
              [-80.934174500848854, 34.859913042879917],
              [-80.934226728750772, 34.859648235762471],
              [-80.933246700366027, 34.859521224973221],
              [-80.933298931041705, 34.859256419154697],
              [-80.932972256233953, 34.859214079698269],
              [-80.933128947072092, 34.858419659842127],
              [-80.933285634867886, 34.85762523975324],
              [-80.932958967693764, 34.857582901141356],
              [-80.93301119692731, 34.857318093639002],
              [-80.93333786307133, 34.857360432109715],
              [-80.933390092051383, 34.857095625324263],
              [-80.93306342693748, 34.857053286994628],
              [-80.93316788371429, 34.85652367186087],
              [-80.93055459469187, 34.856184933726055],
              [-80.93060683190717, 34.855920127232324],
              [-80.929953515376397, 34.85583543456579],
              [-80.930005753220414, 34.855570628346172],
              [-80.929679097017555, 34.855528279914829],
              [-80.929731336646924, 34.855263473793016],
              [-80.930057991819638, 34.855305822083153],
              [-80.930110230080885, 34.855041015794384],
              [-80.930763540452674, 34.85512570851504],
              [-80.930815775222257, 34.854860901935567],
              [-80.931142430421403, 34.854903246366156],
              [-80.931194663822893, 34.85463843961972],
              [-80.931521318330056, 34.854680783022978],
              [-80.931573550363481, 34.854415976109586],
              [-80.931900203085164, 34.854458318502999],
              [-80.932004665171405, 34.853928704299015],
              [-80.931678014488384, 34.853886361286619],
              [-80.931730246601148, 34.853621554278369],
              [-80.931403596192879, 34.853579210538484],
              [-80.931455828997528, 34.853314403645712],
              [-80.931129181050693, 34.853272059143443],
              [-80.931181414547368, 34.853007252366034],
              [-80.930854767968569, 34.852964907118846],
              [-80.930907001042428, 34.852700099573084],
              [-80.930580355852939, 34.852657754482316],
              [-80.9306848252333, 34.852128139560953],
              [-80.931011467290759, 34.852170485288205],
              [-80.931063700443943, 34.851905677647672],
              [-80.931716984585151, 34.85199036524272],
              [-80.931769215340381, 34.851725557294081],
              [-80.932095856330747, 34.851767899179457],
              [-80.932200313673775, 34.851238282940237],
              [-80.932526952942325, 34.851280623657132],
              [-80.93257918062352, 34.851015815349015],
              [-80.93263140687344, 34.850751007032734],
              [-80.932304768550225, 34.850708665714471],
              [-80.932409223167838, 34.850179048368403],
              [-80.932082588357218, 34.85013670733013],
              [-80.932187042568131, 34.849607089279885],
              [-80.932291495448339, 34.849077472028277],
              [-80.932618127232757, 34.849119812484183],
              [-80.932670352125399, 34.848855003228081],
              [-80.932996982145838, 34.848897343575644],
              [-80.933049205670599, 34.848632534152635],
              [-80.93337583499914, 34.848674873473037],
              [-80.933480278953596, 34.848145253366397],
              [-80.933153651706036, 34.848102915229745],
              [-80.933414760838104, 34.846778865671261],
              [-80.933088139056522, 34.846736526453142],
              [-80.933140360907473, 34.846471716966292],
              [-80.932813741587069, 34.846429376985725],
              [-80.932865964108501, 34.846164566713121],
              [-80.932539345083882, 34.846122226906594],
              [-80.932800457771165, 34.844798175865783],
              [-80.932473845305537, 34.844755834960274],
              [-80.932147233199146, 34.844713494070128],
              [-80.932199456761083, 34.844448683166242],
              [-80.931546235600351, 34.844363998108896],
              [-80.931650686943911, 34.843834377673097],
              [-80.931324078919346, 34.843792033647198],
              [-80.931532980576762, 34.84273279305139],
              [-80.931206377009232, 34.842690448704225],
              [-80.931519724924456, 34.841101585196739],
              [-80.931193128966385, 34.841059240793342],
              [-80.931245353283543, 34.840794430263614],
              [-80.930918758693096, 34.84075208511549],
              [-80.93107543268485, 34.839957652895819],
              [-80.931402024186283, 34.839999997620346],
              [-80.931454247130517, 34.839735186087395],
              [-80.931780837940224, 34.839777529784747],
              [-80.931937501677552, 34.83898309551099],
              [-80.932264089736236, 34.839025437898812],
              [-80.932420747322155, 34.838231002071133],
              [-80.932094162352385, 34.838188660106816],
              [-80.932198601112702, 34.83765903637692],
              [-80.932250819964779, 34.837394223572574],
              [-80.932577400752521, 34.837436565130766],
              [-80.932629618258375, 34.837171753061028],
              [-80.932956198354404, 34.837214093592188],
              [-80.933008414492775, 34.836949281355871],
              [-80.933334993897077, 34.836991620859884],
              [-80.933439423079633, 34.836461995127422],
              [-80.933766000762617, 34.836504333463118],
              [-80.933818212713746, 34.836239519984552],
              [-80.934144789704973, 34.836281857293166],
              [-80.934353632198949, 34.835222602525157],
              [-80.934680205409705, 34.835264938383126],
              [-80.93473241470555, 34.835000124477816],
              [-80.934941245210268, 34.833940867754258],
              [-80.935267812517949, 34.83398320203802],
              [-80.935424429028842, 34.833188758576121],
              [-80.935750993585728, 34.833231091550481],
              [-80.935803197005697, 34.832966276276416],
              [-80.935476633478316, 34.832923943443134],
              [-80.935528837589928, 34.832659128284945],
              [-80.935202275429859, 34.832616794707057],
              [-80.935254480233169, 34.832351979664658],
              [-80.934927919440398, 34.832309645342107],
              [-80.935136739394068, 34.831250385484545],
              [-80.934810183056911, 34.8312080508408],
              [-80.935175613206638, 34.82935434338426],
              [-80.935502162337556, 34.829396677039632],
              [-80.935658766599047, 34.828602228647092],
              [-80.935985311886412, 34.828644561010783],
              [-80.936037511602024, 34.828379744688313],
              [-80.93636405729076, 34.828422076007413],
              [-80.936416254546188, 34.828157259536148],
              [-80.937069343785069, 34.828241919252108],
              [-80.937121539736907, 34.827977102455712],
              [-80.937448083286952, 34.828019430852535],
              [-80.937500277871777, 34.82775461388988],
              [-80.93815336501919, 34.827839267726254],
              [-80.938205556114454, 34.827574450473634],
              [-80.938858642553924, 34.827659100484532],
              [-80.938910831231254, 34.827394282023036],
              [-80.939237373381516, 34.82743660556747],
              [-80.939341748778972, 34.826906969170402],
              [-80.939015208687508, 34.826864645908366],
              [-80.93906739637319, 34.826599828272698],
              [-80.938740857648781, 34.826557504266013],
              [-80.938793046004946, 34.826292685845111],
              [-80.938466508647466, 34.826250361093699],
              [-80.938570887498571, 34.825720724441155],
              [-80.938244352537481, 34.825678399086286],
              [-80.938505298334846, 34.824354306828162],
              [-80.93883182707755, 34.824396632396152],
              [-80.939040572838948, 34.823337356501931],
              [-80.939367098873603, 34.823379679700601],
              [-80.939419283156681, 34.823114860076863],
              [-80.939745807406652, 34.823157182266222],
              [-80.939797990322816, 34.822892362476288],
              [-80.940451038869995, 34.822977003897776],
              [-80.940503219411312, 34.822712184701764],
              [-80.940829742615605, 34.822754503951487],
              [-80.940881921768565, 34.822489683688055],
              [-80.941534967131204, 34.822574319248041],
              [-80.9415871438881, 34.822309498677164],
              [-80.941913666046446, 34.822351814987449],
              [-80.942018016467429, 34.821822172587012],
              [-80.940711937000728, 34.821652903178318],
              [-80.940764115832636, 34.821388082955792],
              [-80.940437598112425, 34.821345763525969],
              [-80.940489777636103, 34.821080943419567],
              [-80.940163261282663, 34.821038623245251],
              [-80.940215441476639, 34.820773802353528],
              [-80.939888926490127, 34.820731481434649],
              [-80.940045468176891, 34.819937020835603],
              [-80.93971895659422, 34.819894698553149],
              [-80.939771137517667, 34.819629878745083],
              [-80.93911811853792, 34.819545232688967],
              [-80.939170301160843, 34.819280412236886],
              [-80.938843793763681, 34.819238088463244],
              [-80.938895977078218, 34.818973268127365],
              [-80.938569469954984, 34.818930943626796],
              [-80.938673837629537, 34.818401303162375],
              [-80.938347333973866, 34.818358977139539],
              [-80.938399518333867, 34.818094157011018],
              [-80.938073016066326, 34.818051831144992],
              [-80.93812520111797, 34.817787011132687],
              [-80.937798700195756, 34.817744683620859],
              [-80.937850887031857, 34.817479863707113],
              [-80.937197888280025, 34.817395207210154],
              [-80.937250075722545, 34.81713038667008],
              [-80.936923577892827, 34.817088057684963],
              [-80.936975766026862, 34.816823237261062],
              [-80.936649270656787, 34.816780907513873],
              [-80.936701459482336, 34.81651608720631],
              [-80.936374964386189, 34.816473756732165],
              [-80.936479344175424, 34.815944116307001],
              [-80.936152851453812, 34.815901784328268],
              [-80.936309422260535, 34.815107324819579],
              [-80.936635910780666, 34.815149655490941],
              [-80.936844662234961, 34.814090373141063],
              [-80.936896848713346, 34.813825552725518],
              [-80.936243882279896, 34.813740891007726],
              [-80.936296070457544, 34.813476069948358],
              [-80.935643107411309, 34.813391404069137],
              [-80.935695297330724, 34.813126584168621],
              [-80.935042338785905, 34.81304191501178],
              [-80.935094530404413, 34.8127770944674],
              [-80.934768052142417, 34.812734759611928],
              [-80.934872438610157, 34.812205118695879],
              [-80.934545962722481, 34.812162782335911],
              [-80.934650348804823, 34.811633141620476],
              [-80.934323876405486, 34.811590804639749],
              [-80.934376069422825, 34.811325984394728],
              [-80.934049598390203, 34.811283646669644],
              [-80.93420618066753, 34.810489185272814],
              [-80.933879713059326, 34.810446847085821],
              [-80.933931906762638, 34.810182026122661],
              [-80.934036295363484, 34.809652384988013],
              [-80.933709831179712, 34.809610046339102],
              [-80.933814219373772, 34.809080404503916],
              [-80.933487757585468, 34.809038065251961],
              [-80.933592147579603, 34.808508423582488],
              [-80.933265688186751, 34.808466083727325],
              [-80.933317883706252, 34.808201262996683],
              [-80.932991425679901, 34.808158922397297],
              [-80.933095816649626, 34.807629280260336],
              [-80.932769362111557, 34.80758693904032],
              [-80.932821558675911, 34.807322118517781],
              [-80.932495104411544, 34.807279776571001],
              [-80.932547301645741, 34.80701495526371],
              [-80.932220848747875, 34.806972612572586],
              [-80.932377441532623, 34.80617814982714],
              [-80.932050993151606, 34.806135806656684],
              [-80.932207585962772, 34.805341343211055],
              [-80.931881141005832, 34.805298999578902],
              [-80.931985535490014, 34.804769357458227],
              [-80.931659093999798, 34.804727012304227],
              [-80.931711291217852, 34.804462191356791],
              [-80.930079089647123, 34.804250454883352],
              [-80.930131293856761, 34.803985634582546],
              [-80.929478417195639, 34.803900933363209],
              [-80.929530623125117, 34.803636113320252],
              [-80.9288777498722, 34.80355140884155],
              [-80.928929957521461, 34.803286589056398],
              [-80.92827708876942, 34.803201881300936],
              [-80.928329297045821, 34.802937061791262],
              [-80.928002864751477, 34.80289470671778],
              [-80.928055074790279, 34.802629886405867],
              [-80.927728642769651, 34.802587530605813],
              [-80.927780853499556, 34.802322710410458],
              [-80.927454423937945, 34.802280353848722],
              [-80.927506634287354, 34.802015534688884],
              [-80.927180206092132, 34.801973177382912],
              [-80.927232418204014, 34.801708357420907],
              [-80.925600287416273, 34.801496557414914],
              [-80.925652504333769, 34.801231738134675],
              [-80.924999656448634, 34.801147012577424],
              [-80.9250518750856, 34.800882193555196],
              [-80.924725452657171, 34.800839828688403],
              [-80.924777671985026, 34.800575009782833],
              [-80.924451250944173, 34.800532645073297],
              [-80.924503470963003, 34.800267826284482],
              [-80.924177052359937, 34.800225459912042],
              [-80.924229273069628, 34.799960641239863],
              [-80.923902854761536, 34.79991827504211],
              [-80.924007298296246, 34.799388636987814],
              [-80.923680882361879, 34.799346269286005],
              [-80.923733105186969, 34.799081449903838],
              [-80.923406690640149, 34.799039082359279],
              [-80.92351113624143, 34.798509443821352],
              [-80.92318472518231, 34.798467075656575],
              [-80.923341393590377, 34.797672618098012],
              [-80.92301498484089, 34.797630248588042],
              [-80.923067208724959, 34.797365429482326],
              [-80.922740802455593, 34.797323060112177],
              [-80.922793025916732, 34.797058240239359],
              [-80.922466621013584, 34.797015870125172],
              [-80.922518846258015, 34.796751050351766],
              [-80.920886832981083, 34.796539184485574],
              [-80.920939063051563, 34.796274366295648],
              [-80.920286261708398, 34.796189613320223],
              [-80.920338493476905, 34.79592479448722],
              [-80.920012094361638, 34.795882417714225],
              [-80.920064326820821, 34.795617598998035],
              [-80.919737930143441, 34.79557522056222],
              [-80.91989462750783, 34.794780765609254],
              [-80.920221022171305, 34.794823142702114],
              [-80.920325482956756, 34.794293505066364],
              [-80.920482172147643, 34.793499048420244],
              [-80.920155783697766, 34.793456671115386],
              [-80.920208014123986, 34.793191851418584],
              [-80.919881627040425, 34.793149473369816],
              [-80.919933858178311, 34.792884654691214],
              [-80.919607472460868, 34.792842275898579],
              [-80.919711935759096, 34.792312637849321],
              [-80.919385552436253, 34.792270258454124],
              [-80.91949001532636, 34.791740619705813],
              [-80.919163635490662, 34.79169823969066],
              [-80.919581483908814, 34.789579683013166],
              [-80.919633713719406, 34.789314863539559],
              [-80.919960084299959, 34.789357242282257],
              [-80.920012312723316, 34.789092421741472],
              [-80.920338682613334, 34.789134799457507],
              [-80.920443135318834, 34.788605158938871],
              [-80.921095871998773, 34.788689911149703],
              [-80.921148095231388, 34.788425090129103],
              [-80.921474463049691, 34.788467464765247],
              [-80.921735570098207, 34.787143358572152],
              [-80.921409208513879, 34.787100984625404],
              [-80.92167031219752, 34.785776875826322],
              [-80.921996669732934, 34.785819249048835],
              [-80.92215332414618, 34.785024782883546],
              [-80.921826970766716, 34.784982409166417],
              [-80.921931407396116, 34.784452764616503],
              [-80.921605056432028, 34.784410391198172],
              [-80.921657275268345, 34.784145569028176],
              [-80.921330925670162, 34.78410319486607],
              [-80.921383146268411, 34.783838371894291],
              [-80.92105679803602, 34.783795996988395],
              [-80.921370115304029, 34.782207062287853],
              [-80.921696457367261, 34.782249436345595],
              [-80.921800891313495, 34.781719790073083],
              [-80.921474551306559, 34.781677416298109],
              [-80.921631203870305, 34.780882947114257],
              [-80.921304868356657, 34.780840571959558],
              [-80.921409303017668, 34.780310925108957],
              [-80.921082969919055, 34.780268550253155],
              [-80.921135187781658, 34.780003727383622],
              [-80.920808856027747, 34.779961350882743],
              [-80.92086107458087, 34.779696528130394],
              [-80.920534744192636, 34.779654150885669],
              [-80.9206391834133, 34.779124504672396],
              [-80.920312855418914, 34.779082126825386],
              [-80.920365074994024, 34.778817303381857],
              [-80.920038749478906, 34.778774925675002],
              [-80.920090969744422, 34.778510102348733],
              [-80.919764644481504, 34.778467723014202],
              [-80.920182405908278, 34.776349134810445],
              [-80.919856090299646, 34.776306755704539],
              [-80.920012747091874, 34.775512284047124],
              [-80.920339059616552, 34.775554662728766],
              [-80.920391276836909, 34.775289838352798],
              [-80.920064965340302, 34.775247459812562],
              [-80.920169401916453, 34.774717811253865],
              [-80.920639345888361, 34.772334388003443],
              [-80.920965644985387, 34.77237676500571],
              [-80.921070070943244, 34.771847113849603],
              [-80.921396368322078, 34.771889489684035],
              [-80.921553000529713, 34.771095011461526],
              [-80.921879295162455, 34.771137385986741],
              [-80.921931504566487, 34.770872560017814],
              [-80.922257798508838, 34.770914933516607],
              [-80.922310006526175, 34.77065010648095],
              [-80.92263629977829, 34.770692478953301],
              [-80.922688505359147, 34.770427652671074],
              [-80.92334109079907, 34.770512393776585],
              [-80.923393293986535, 34.770247567187567],
              [-80.924045878720946, 34.770332304470195],
              [-80.924098079515133, 34.770067477574457],
              [-80.924750663543804, 34.770152211034258],
              [-80.925011653181244, 34.768828071159398],
              [-80.925337939480784, 34.768870436323034],
              [-80.925390135145278, 34.768605607958101],
              [-80.925716421825612, 34.768647971176605],
              [-80.925820810086392, 34.768118314092106],
              [-80.925494525440541, 34.76807595025506],
              [-80.925598914407189, 34.76754629335754],
              [-80.925925196976081, 34.767588656010624],
              [-80.925977389925379, 34.76732382738458],
              [-80.925651108363027, 34.767281463971607],
              [-80.92580768822711, 34.766486976571613],
              [-80.926133965613815, 34.766529339577978],
              [-80.926290538265192, 34.765734851556445],
              [-80.926616812884689, 34.76577721235239],
              [-80.9267211916235, 34.765247552656682],
              [-80.926394919038287, 34.765205191242039],
              [-80.926447108929366, 34.76494036149974],
              [-80.925794566848069, 34.764855637199616],
              [-80.925846758457226, 34.764590807716182],
              [-80.925194220852205, 34.764506079240142],
              [-80.925246414179412, 34.764241250015509],
              [-80.92492014636467, 34.764198884600155],
              [-80.924972340360966, 34.763934054591786],
              [-80.924646075003622, 34.763891688415377],
              [-80.924750464060395, 34.763362029511121],
              [-80.92507672736231, 34.763404395404869],
              [-80.925128920346253, 34.76313956532492],
              [-80.925455182957819, 34.76318193019226],
              [-80.925559565879084, 34.762652270579636],
              [-80.925885826751568, 34.762694633377976],
              [-80.925938016667715, 34.762429802943899],
              [-80.926264276871123, 34.762472165617254],
              [-80.926368652543673, 34.761942504412438],
              [-80.926042394395751, 34.761900142021751],
              [-80.926198958981928, 34.761105649550025],
              [-80.925872705367794, 34.761063287582395],
              [-80.925924893173772, 34.760798456263082],
              [-80.925598640903516, 34.760756092650595],
              [-80.925703018671996, 34.760226431107242],
              [-80.925376767702275, 34.760184066909986],
              [-80.925428957643462, 34.759919235784629],
              [-80.925102709130968, 34.759876870826432],
              [-80.925154898670215, 34.759612039836206],
              [-80.924828651522745, 34.759569674134383],
              [-80.924933033828637, 34.759040012330445],
              [-80.924606787981588, 34.75899764604398],
              [-80.924658979656058, 34.758732815247782],
              [-80.924332736266237, 34.758690448200419],
              [-80.924384928630786, 34.75842561752188],
              [-80.924058686605889, 34.758383249730919],
              [-80.924110879639514, 34.758118418268708],
              [-80.923784638979711, 34.758076049734349],
              [-80.923836832724575, 34.757811219291135],
              [-80.923510593429768, 34.757768850013292],
              [-80.923667175680208, 34.756974357162825],
              [-80.923340939805499, 34.756931987424224],
              [-80.923445328326338, 34.7564023250874],
              [-80.923119094844125, 34.756359954746785],
              [-80.92317128962587, 34.756095123684233],
              [-80.923497522080652, 34.756137493883429],
              [-80.923758485790799, 34.754813337508544],
              [-80.923432258452408, 34.754770967115029],
              [-80.923536643629561, 34.754241304682218],
              [-80.923862867799741, 34.754283673909086],
              [-80.923915058854774, 34.75401884251589],
              [-80.923588835690907, 34.753976472529089],
              [-80.92364102741486, 34.753711640352279],
              [-80.923314805637006, 34.753669270523368],
              [-80.923471381887893, 34.752874775176778],
              [-80.92314516350865, 34.752832403985842],
              [-80.923197355937901, 34.752567571997481],
              [-80.922871138944743, 34.752525200964484],
              [-80.922975524824622, 34.751995536298281],
              [-80.922649310223647, 34.751953164663369],
              [-80.922701503695507, 34.751688332886872],
              [-80.922375291530216, 34.751645959589794],
              [-80.922427485692111, 34.751381127931189],
              [-80.922101273799584, 34.751338753908207],
              [-80.922153468630299, 34.751073921466123],
              [-80.921827259215775, 34.751031547583551],
              [-80.921879454736469, 34.750766715259317],
              [-80.9212270378613, 34.750681964238858],
              [-80.921279236191268, 34.750417132156436],
              [-80.920953028730665, 34.750374755018576],
              [-80.92105742536225, 34.749845091984703],
              [-80.919752611480902, 34.749675575647096],
              [-80.919804812843395, 34.749410744218629],
              [-80.919152409981976, 34.7493259810241],
              [-80.919204614153671, 34.749061149837559],
              [-80.918878414256554, 34.74901876705465],
              [-80.918930618026025, 34.748753936003432],
              [-80.918604420585595, 34.748711552459717],
              [-80.918656625044974, 34.74844672152642],
              [-80.918330428969398, 34.748404337239499],
              [-80.918382634118544, 34.748139506424103],
              [-80.918056439407621, 34.748097121393833],
              [-80.918108645225573, 34.747832289795063],
              [-80.917782451879503, 34.747789904021566],
              [-80.917834659500258, 34.747525073424654],
              [-80.917508466405764, 34.747482686023936],
              [-80.917560674716384, 34.747217855544982],
              [-80.917234482986643, 34.747175467401028],
              [-80.917286691987044, 34.746910637040024],
              [-80.916960502735108, 34.746868249036773],
              [-80.917012712404187, 34.746603417892423],
              [-80.916686524516976, 34.746561029145838],
              [-80.916738734875793, 34.746296198119452],
              [-80.916412547240355, 34.746253807745788],
              [-80.916464758288839, 34.745988976837403],
              [-80.916138573131065, 34.745946586604433],
              [-80.916190784869357, 34.74568175581404],
              [-80.915864601076294, 34.745639364837757],
              [-80.915916813483236, 34.745374533264034],
              [-80.915590631054968, 34.745332141544623],
              [-80.915642845264529, 34.74506731097285],
              [-80.915316664179898, 34.745024917608816],
              [-80.915368877987191, 34.744760087172487],
              [-80.915042698267257, 34.744717693065333],
              [-80.915094912764246, 34.744452862746996],
              [-80.914768735522003, 34.7444104687806],
              [-80.91482095068757, 34.744145637678926],
              [-80.914494773697001, 34.74410324208538],
              [-80.914546989573282, 34.743838412003164],
              [-80.914220815039343, 34.743796015649075],
              [-80.914273031605248, 34.743531185684823],
              [-80.913620684512196, 34.743446390623021],
              [-80.913672903865844, 34.743181559999691],
              [-80.913346731327778, 34.743139162193337],
              [-80.913398951371022, 34.742874331688014],
              [-80.913072780176577, 34.742831932237173],
              [-80.913125000909304, 34.742567101850028],
              [-80.912798832171532, 34.742524701638665],
              [-80.912851052501921, 34.742259871386985],
              [-80.912524885128803, 34.742217470432465],
              [-80.912629327923156, 34.74168781012456],
              [-80.912303162941768, 34.741645408568374],
              [-80.912355385405633, 34.741380578512157],
              [-80.912029221788771, 34.741338176212928],
              [-80.91208144385034, 34.741073346292119],
              [-80.911755282690095, 34.741030943232417],
              [-80.911807505420072, 34.740766112528476],
              [-80.911481345624438, 34.740723708725703],
              [-80.911533569064957, 34.740458879041256],
              [-80.91088125142744, 34.740374068180785],
              [-80.910933477676465, 34.74010923873869],
              [-80.909628849489479, 34.739939607887287],
              [-80.909576619153427, 34.74020443766458],
              [-80.90925046274775, 34.74016202781101],
              [-80.909198231025982, 34.740426856522049],
              [-80.908872072839074, 34.740384445659643],
              [-80.908819839752653, 34.740649274205794],
              [-80.908167523429441, 34.740564449526637],
              [-80.908219758548825, 34.740299620362329],
              [-80.907893601374781, 34.740257206846124],
              [-80.90756744563015, 34.740214792427956],
              [-80.907619682466049, 34.73994996352328],
              [-80.907293528086072, 34.739907548362098],
              [-80.907345765611339, 34.739642719575713],
              [-80.907019612575013, 34.739600302770128],
              [-80.907071850789634, 34.739335474101956],
              [-80.906745699138924, 34.739293057454717],
              [-80.906850177701102, 34.738763400313985],
              [-80.906524028442007, 34.738720983065306],
              [-80.906628507686492, 34.738191325213009],
              [-80.90630236081897, 34.738148907362877],
              [-80.906354601497185, 34.737884078084001],
              [-80.90602845599426, 34.737841659490883],
              [-80.906080696269939, 34.737576830347571],
              [-80.905754552131597, 34.737534411011474],
              [-80.905806794188408, 34.737269581969095],
              [-80.905480651414649, 34.737227161889933],
              [-80.905532894160743, 34.736962332965845],
              [-80.904880612751029, 34.736877489518548],
              [-80.904932856121377, 34.736612660871607],
              [-80.904606717485407, 34.736570237503379],
              [-80.904711207471507, 34.736040581289416],
              [-80.904385070135177, 34.735998157337185],
              [-80.904437315638148, 34.735733328886212],
              [-80.904111180758207, 34.735690904173651],
              [-80.90421567171316, 34.73516124750234],
              [-80.903889539224622, 34.735118822188511],
              [-80.903994031974364, 34.734589165689982],
              [-80.903667901877228, 34.734546739774849],
              [-80.90392913291403, 34.733222596125323],
              [-80.904255256785191, 34.733265021349723],
              [-80.904464231396091, 34.732205704003206],
              [-80.904790351497681, 34.73224812777665],
              [-80.904947074738459, 34.731453639115358],
              [-80.904620957717128, 34.731411215766698],
              [-80.905038879228414, 34.729292574046909],
              [-80.905364988035714, 34.72933499626275],
              [-80.905626171599152, 34.72801084171855],
              [-80.905300067925438, 34.727968420210729],
              [-80.90535230531124, 34.727703589543992],
              [-80.90550901321798, 34.726909095636969],
              [-80.905182915080289, 34.726866673793822],
              [-80.90549632789741, 34.725277684403281],
              [-80.905170236236231, 34.725235261623951],
              [-80.905274706891959, 34.724705598586617],
              [-80.904948617621599, 34.724663175206203],
              [-80.905105323565479, 34.723868679108108],
              [-80.904779238825114, 34.723826256152165],
              [-80.904883710190575, 34.723296592549403],
              [-80.904557626728419, 34.723254168108333],
              [-80.90460986237504, 34.722989335972002],
              [-80.904283781389623, 34.722946911672118],
              [-80.904336017725242, 34.72268207965444],
              [-80.903683857707449, 34.722597227800755],
              [-80.903736096850366, 34.722332396025955],
              [-80.903410018909824, 34.722289968454852],
              [-80.903462257649977, 34.722025136816029],
              [-80.902810104855561, 34.721940280205374],
              [-80.90286234638198, 34.721675447908197],
              [-80.902536270436471, 34.72163301888591],
              [-80.902745236201511, 34.720573690952357],
              [-80.90241916688295, 34.720531261577733],
              [-80.902575890452937, 34.719736765139594],
              [-80.90224982343878, 34.719694334421519],
              [-80.90240654705002, 34.718899838201736],
              [-80.902458787942535, 34.718635006076752],
              [-80.902132726463392, 34.718592575023614],
              [-80.902289449082161, 34.717798078052219],
              [-80.90261550746105, 34.717840507779066],
              [-80.902719986186838, 34.717310842421632],
              [-80.902393928748353, 34.717268412094008],
              [-80.902446168620955, 34.717003579071914],
              [-80.902120113658853, 34.716961148885616],
              [-80.902172354220198, 34.716696315982283],
              [-80.901846300601235, 34.716653884152159],
              [-80.901898540759831, 34.716389051384844],
              [-80.901572488504826, 34.716346618811912],
              [-80.901624730443814, 34.716081786146127],
              [-80.901298679552667, 34.716039352830634],
              [-80.901403164449945, 34.715509686812354],
              [-80.901077115949107, 34.715467252895841],
              [-80.901233843852694, 34.714672754572653],
              [-80.900907798768458, 34.714630320196854],
              [-80.901064526692025, 34.713835821191616],
              [-80.900738486094994, 34.713793385438024],
              [-80.900947454919688, 34.712734052493516],
              [-80.900621418786343, 34.712691617323692],
              [-80.900673661447712, 34.712426784167867],
              [-80.900347626678197, 34.712384348255483],
              [-80.900452113020094, 34.711854681257464],
              [-80.900126080640675, 34.711812244744124],
              [-80.900282809598451, 34.711017744067775],
              [-80.899956781726971, 34.710975307078058],
              [-80.90006126877725, 34.710445639517566],
              [-80.899735242204201, 34.710403201944217],
              [-80.899787486249281, 34.710138368271508],
              [-80.899461462110594, 34.710095929037003],
              [-80.899618195299013, 34.70930142830742],
              [-80.899292173485037, 34.709258988631085],
              [-80.899501150427582, 34.70819965367204],
              [-80.899175134147441, 34.708157213661018],
              [-80.899227378549639, 34.70789237933014],
              [-80.898901363654119, 34.707849939477967],
              [-80.899058097913951, 34.707055436774169],
              [-80.898732087525843, 34.707012996445606],
              [-80.898784332630129, 34.70674816230737],
              [-80.898458322493383, 34.706705720352311],
              [-80.898510568286227, 34.706440886333027],
              [-80.898184559513084, 34.706398443635429],
              [-80.898236807085993, 34.706133609717916],
              [-80.897910799676538, 34.706091166277957],
              [-80.897963046846556, 34.705826332496727],
              [-80.897311036183964, 34.705741443230529],
              [-80.897363286160044, 34.705476609692823],
              [-80.896711278900028, 34.705391717173406],
              [-80.896763529499154, 34.705126883913678],
              [-80.896111526733065, 34.705041988124059],
              [-80.896163779025898, 34.704777154223748],
              [-80.895837779175253, 34.704734705144396],
              [-80.895890033268898, 34.70446987224728],
              [-80.895942285912597, 34.704205038443483],
              [-80.896099044001105, 34.703410536861533],
              [-80.896425037630124, 34.703452985249498],
              [-80.896686287753184, 34.702128813907002],
              [-80.897012277681128, 34.70217126068497],
              [-80.897325758926129, 34.700582250077218],
              [-80.896999775154114, 34.70053980414955],
              [-80.897104268974658, 34.700010133737962],
              [-80.896778288683535, 34.699967687192462],
              [-80.896830535567716, 34.699702852103179],
              [-80.897156514833, 34.699745298506969],
              [-80.897417738998982, 34.69842111930992],
              [-80.897743713492929, 34.698463565022472],
              [-80.897795956279353, 34.698198728613256],
              [-80.898121930064121, 34.698241172398646],
              [-80.898539849794645, 34.696122478314685],
              [-80.89821388530828, 34.696080035645714],
              [-80.898266125036301, 34.695815198132848],
              [-80.897940161913368, 34.695772754721631],
              [-80.897992402350624, 34.695507918229453],
              [-80.897666440570049, 34.695465473174536],
              [-80.89771868060447, 34.695200636818832],
              [-80.897392720187227, 34.695158191021648],
              [-80.897444962001273, 34.69489335476792],
              [-80.896793044251794, 34.694808461706479],
              [-80.8968452888504, 34.694543624795216],
              [-80.896193374502545, 34.69445872848123],
              [-80.896245619723985, 34.694193891848229],
              [-80.895593709869047, 34.694108992264461],
              [-80.895645956804572, 34.693844155892585],
              [-80.894342144156923, 34.693674346701435],
              [-80.89439439594922, 34.693409510856938],
              [-80.893742494227027, 34.693324600331131],
              [-80.893794746642101, 34.693059764764861],
              [-80.893468797858688, 34.69301730830918],
              [-80.893521050961866, 34.692752472862175],
              [-80.893195103541743, 34.692710015664296],
              [-80.893247358424475, 34.692445180319538],
              [-80.892921412367642, 34.692402722379455],
              [-80.893025922038902, 34.691873050120826],
              [-80.892699978391988, 34.691830592481672],
              [-80.89280448876444, 34.691300920417063],
              [-80.892856743989995, 34.691036084342585],
              [-80.892530803736847, 34.690993625343445],
              [-80.892635314135347, 34.690463953428193],
              [-80.892961252337116, 34.690506412143847],
              [-80.893118012850948, 34.689711902768067],
              [-80.893443947221996, 34.689754360191607],
              [-80.893548450121571, 34.689224686584467],
              [-80.893874383870042, 34.68926714282334],
              [-80.893978883347373, 34.688737467942076],
              [-80.894304814290834, 34.688779923030673],
              [-80.894357061972769, 34.688515086324742],
              [-80.894682993298488, 34.688557539469173],
              [-80.894735239617262, 34.688292702599234],
              [-80.895061169163711, 34.688335154735192],
              [-80.895270147969413, 34.687275802842628],
              [-80.894944222504833, 34.687233350372374],
              [-80.895257685043731, 34.685644320037717],
              [-80.89493176716131, 34.68560186751688],
              [-80.894984010751855, 34.685337028740996],
              [-80.894658093141302, 34.685294575495277],
              [-80.895076038991817, 34.683175864703728],
              [-80.894750129923068, 34.683133411708262],
              [-80.895168061144219, 34.681014694338621],
              [-80.89484216279908, 34.680972241559033],
              [-80.895155602042038, 34.67938320076545],
              [-80.894829710186968, 34.679340747952644],
              [-80.89498642884395, 34.678546226322041],
              [-80.894660539311445, 34.678503773067945],
              [-80.895339629362482, 34.67506083747238],
              [-80.895665504495014, 34.675103289801896],
              [-80.895874438129738, 34.674043920799001],
              [-80.895548568210302, 34.674001469920626],
              [-80.895653035054124, 34.67347178557079],
              [-80.895327167501861, 34.673429333190796],
              [-80.895431635046833, 34.672899649036616],
              [-80.895757500527793, 34.672942100231651],
              [-80.89580973330402, 34.672677257520512],
              [-80.896135598117894, 34.672719708591416],
              [-80.896187828440517, 34.672454865733755],
              [-80.896513692545611, 34.672497314877816],
              [-80.896565922596722, 34.67223247183923],
              [-80.896891784922914, 34.672274919975038],
              [-80.896944013611432, 34.672010076772814],
              [-80.897269875270425, 34.672052524784483],
              [-80.897322102575444, 34.671787680517134],
              [-80.897647963546675, 34.671830127503341],
              [-80.897752414024268, 34.671300439537973],
              [-80.898078273261248, 34.67134288445564],
              [-80.898130497524818, 34.671078040740305],
              [-80.898456356073936, 34.671120484632517],
              [-80.898665241248764, 34.670061107251421],
              [-80.898991096034223, 34.670103549693003],
              [-80.899304407444163, 34.668514478508889],
              [-80.899630256436495, 34.668556920117567],
              [-80.89978690339089, 34.667762382470301],
              [-80.899839117633732, 34.667497536255247],
              [-80.90016496286249, 34.667539976413373],
              [-80.900217176833877, 34.66727513001748],
              [-80.900543021353883, 34.667317568248791],
              [-80.900647445164608, 34.66678787602671],
              [-80.900973286880557, 34.666830313108136],
              [-80.901025497790044, 34.666565466363316],
              [-80.9016771812949, 34.666650338475804],
              [-80.901886010066136, 34.665590949295122],
              [-80.901560172780734, 34.665548513788224],
              [-80.902291043495552, 34.661840641458959],
              [-80.90261686642981, 34.661883074981588],
              [-80.902721268734297, 34.661353376505701],
              [-80.903047088864739, 34.661395808878581],
              [-80.903099288486345, 34.661130959466334],
              [-80.903255885328548, 34.660336411099514],
              [-80.902930068228287, 34.660293980212167],
              [-80.90298226750555, 34.660029129953585],
              [-80.902656452858309, 34.659986698307115],
              [-80.902760852472483, 34.659456998909846],
              [-80.90243504021231, 34.659414566663258],
              [-80.902591640170115, 34.658620017379391],
              [-80.902917449376261, 34.658662450102128],
              [-80.903021844508018, 34.65813274960437],
              [-80.903347652001486, 34.658175181160097],
              [-80.903452043735072, 34.65764548029231],
              [-80.903777849494688, 34.657687909779789],
              [-80.903830044376292, 34.657423059163108],
              [-80.904155848378139, 34.657465488543863],
              [-80.904521192603056, 34.655611529978444],
              [-80.904195395796691, 34.655569102490972],
              [-80.904247586870326, 34.655304250817565],
              [-80.904573383763562, 34.655346679047348],
              [-80.905043082267866, 34.652963009887358],
              [-80.904717295689323, 34.652920582915733],
              [-80.904926045509967, 34.651861172510586],
              [-80.904600264458352, 34.651818745205226],
              [-80.904756826617003, 34.651024186920552],
              [-80.904431048976775, 34.650981759156792],
              [-80.904744169211867, 34.649392641073071],
              [-80.904418398057246, 34.649350213276222],
              [-80.904783697630108, 34.647496237073739],
              [-80.905109460520933, 34.647538663895823],
              [-80.905318191436635, 34.646479246600208],
              [-80.905643950565519, 34.646521671972032],
              [-80.906061384671688, 34.644402831668422],
              [-80.90638713594025, 34.644445255023037],
              [-80.906491489300208, 34.643915543544701],
              [-80.90681723776575, 34.643957965749763],
              [-80.906869412905365, 34.643693109386341],
              [-80.907195161773743, 34.643735530549002],
              [-80.907299507923355, 34.643205818393291],
              [-80.907625253988755, 34.643248238406478],
              [-80.907677426068517, 34.64298338169575],
              [-80.90735167993715, 34.642940961841575],
              [-80.907716878922201, 34.641086963511626],
              [-80.907769048241278, 34.640822106789706],
              [-80.907443311733957, 34.640779687168546],
              [-80.907495482810376, 34.640514829648481],
              [-80.90716974766454, 34.640472409285664],
              [-80.907221918338109, 34.640207551903401],
              [-80.906570452196618, 34.640122708793911],
              [-80.90662262458244, 34.639857851673831],
              [-80.90629689358677, 34.63981542892715],
              [-80.906349066660269, 34.639550571927586],
              [-80.906023335914483, 34.639508147555368],
              [-80.906075510766129, 34.639243290658982],
              [-80.905749782493189, 34.639200866429029],
              [-80.905854132144498, 34.638671152873563],
              [-80.905528406236485, 34.638628727142482],
              [-80.905684931784904, 34.637834156165461],
              [-80.905359208217703, 34.637791730894925],
              [-80.905567908659521, 34.636732301688028],
              [-80.905242190596496, 34.636689875182498],
              [-80.905346541380013, 34.636160160276198],
              [-80.905672257394158, 34.636202586498264],
              [-80.905724430144829, 34.635937727987709],
              [-80.905398716266546, 34.635895302791418],
              [-80.905763927685555, 34.634041296252619],
              [-80.905438221314952, 34.63399887116524],
              [-80.905490393419839, 34.633734012769125],
              [-80.904838983716871, 34.633649159326758],
              [-80.904891157533612, 34.633384301193061],
              [-80.904565454201261, 34.633341872838102],
              [-80.904617629796078, 34.633077014807775],
              [-80.904291927824943, 34.633034585711293],
              [-80.904344104107253, 34.632769727801637],
              [-80.904018402406848, 34.632727297981049],
              [-80.904122756009571, 34.632197582382034],
              [-80.903797057785113, 34.632155151944353],
              [-80.903901412068222, 34.631625435643365],
              [-80.903575716229241, 34.631583004605908],
              [-80.903732248190124, 34.630788429971133],
              [-80.903406555760981, 34.630745998475817],
              [-80.903771791223733, 34.628891988264066],
              [-80.903446107391602, 34.628849556860622],
              [-80.903498283701111, 34.62858469754164],
              [-80.90317260122994, 34.628542265396767],
              [-80.903746526378953, 34.625628812214373],
              [-80.903420856601215, 34.625586380728507],
              [-80.903525203785463, 34.625056661249751],
              [-80.903199536371929, 34.625014228262984],
              [-80.903251711038678, 34.624749369076035],
              [-80.903303885347654, 34.624484508966916],
              [-80.902978219228928, 34.624442075397845],
              [-80.903030394225226, 34.624177215409539],
              [-80.90237906726874, 34.624092347673688],
              [-80.902431243976565, 34.623827487948041],
              [-80.90210558200674, 34.623785051995881],
              [-80.902157759401874, 34.623520192391148],
              [-80.90183209881387, 34.623477756598987],
              [-80.901884276875293, 34.623212896213929],
              [-80.901558617648178, 34.623170459680352],
              [-80.901662974829762, 34.622640740032402],
              [-80.90133731798764, 34.622598302899412],
              [-80.901441675849327, 34.62206858255027],
              [-80.901116022482455, 34.622026144800508],
              [-80.901168201932052, 34.621761284736401],
              [-80.900842548835726, 34.621718846262624],
              [-80.900894728972645, 34.621453986319473],
              [-80.900569078306617, 34.621411546185811],
              [-80.900621259130645, 34.621146686363701],
              [-80.900295609825577, 34.621104245488766],
              [-80.900347791336813, 34.620839385787569],
              [-80.900022143413381, 34.620796945072684],
              [-80.90012650745274, 34.620267224990087],
              [-80.899800861914045, 34.62022478367583],
              [-80.899905226654113, 34.619695063793593],
              [-80.899579583479465, 34.619652620978492],
              [-80.899683950010385, 34.619122901279518],
              [-80.899032668767518, 34.619038013567312],
              [-80.89898048417804, 34.619302873585674],
              [-80.898654843037903, 34.619260428263232],
              [-80.898602657087693, 34.619525288118872],
              [-80.897625732616461, 34.619397946427782],
              [-80.897677921638575, 34.61913308699755],
              [-80.89735228293614, 34.619090638125677],
              [-80.897404472666253, 34.618825779717874],
              [-80.897078835324649, 34.61878333010484],
              [-80.897131025721009, 34.618518470916662],
              [-80.896805389740138, 34.618476020562404],
              [-80.896857580823564, 34.618211161495367],
              [-80.896531946203581, 34.618168710399843],
              [-80.896584139064331, 34.617903851436651],
              [-80.896258504714893, 34.617861399617247],
              [-80.896310698262681, 34.617596540775054],
              [-80.895985065273962, 34.617554088214483],
              [-80.89603725948794, 34.617289228592028],
              [-80.895711628950366, 34.61724677527306],
              [-80.895816018457012, 34.616717058052295],
              [-80.895490390283285, 34.616674603232724],
              [-80.895646973977207, 34.615880026787345],
              [-80.895321349212011, 34.615837571510333],
              [-80.895425739424411, 34.615307853748249],
              [-80.895100117043825, 34.615265397871944],
              [-80.895256702876139, 34.614470820146941],
              [-80.89493108392476, 34.614428364714591],
              [-80.895087669775776, 34.613633786327512],
              [-80.894762055302124, 34.61359132951921],
              [-80.894866446225706, 34.613061610474354],
              [-80.894540833067154, 34.613019153985377],
              [-80.894593029037026, 34.612754294123086],
              [-80.894267418329349, 34.612711836875846],
              [-80.894319614986031, 34.612446977134766],
              [-80.893994005618211, 34.612404518245178],
              [-80.894463769541431, 34.610020779120674],
              [-80.894138169744963, 34.609978321526306],
              [-80.894242560037455, 34.609448600375686],
              [-80.894568157807313, 34.609491058587636],
              [-80.894724737648403, 34.608696476281558],
              [-80.895050331593794, 34.608738933202261],
              [-80.895154713312024, 34.608209210394016],
              [-80.895480306637126, 34.608251666130876],
              [-80.895532495432562, 34.607986805013311],
              [-80.894881313346801, 34.607901892906114],
              [-80.894933504942955, 34.607637032034575],
              [-80.894607915428992, 34.607594574798568],
              [-80.894660106621771, 34.607329714065571],
              [-80.894008930651765, 34.607244797228553],
              [-80.894165511579715, 34.606450213903152],
              [-80.894491096325439, 34.606492672337438],
              [-80.894752051538376, 34.605168363288641],
              [-80.895077631502858, 34.605210820130623],
              [-80.8951298196435, 34.604945958311468],
              [-80.894804241792713, 34.604903501594137],
              [-80.895012997219965, 34.603844052843066],
              [-80.894687423800278, 34.603801595810431],
              [-80.894791802609461, 34.603271870677226],
              [-80.89446623048363, 34.603229413062692],
              [-80.894622798151985, 34.602434825652274],
              [-80.894297231613706, 34.602392367546166],
              [-80.894453799300607, 34.601597779476101],
              [-80.89412823507962, 34.601555320930068],
              [-80.894232613530249, 34.601025595436823],
              [-80.893907052783106, 34.600983136274607],
              [-80.89401143191283, 34.600453410082181],
              [-80.893685873549359, 34.600410950320871],
              [-80.893738064177484, 34.600146087327417],
              [-80.893412506084346, 34.600103626842362],
              [-80.893464697399068, 34.599838763970389],
              [-80.893139141756208, 34.599796302727313],
              [-80.89319133264685, 34.599531439092644],
              [-80.891889117516584, 34.599361584958864],
              [-80.891941313274884, 34.59909672275564],
              [-80.890964657418195, 34.598969322202336],
              [-80.891016857000039, 34.598704460387339],
              [-80.890365757560247, 34.598619522844118],
              [-80.890470157606018, 34.598089798853586],
              [-80.890144610427924, 34.598047328591051],
              [-80.890196812069306, 34.597782467140725],
              [-80.890249012263155, 34.597517604785764],
              [-80.890574557394572, 34.597560074764516],
              [-80.890626756228201, 34.597295212247282],
              [-80.89095230065233, 34.597337680299923],
              [-80.891004498146401, 34.597072818521823],
              [-80.891330041884004, 34.597115285549712],
              [-80.891434433773483, 34.596585559845963],
              [-80.891759974732039, 34.596628026625879],
              [-80.891916556949241, 34.595833436590574],
              [-80.892242096264411, 34.595875902044774],
              [-80.89245086262099, 34.594816446327357],
              [-80.892555244333892, 34.594286718788943],
              [-80.892229712248678, 34.594244254169048],
              [-80.892386285330957, 34.593449661783147],
              [-80.892438474594826, 34.593184797631139],
              [-80.892112948029521, 34.593142332679022],
              [-80.892165137980015, 34.59287746864868],
              [-80.891839612774746, 34.592835002955866],
              [-80.891891804501569, 34.592570139029995],
              [-80.891566279566433, 34.592527672613528],
              [-80.891618471959049, 34.592262807908092],
              [-80.890967426256381, 34.592177873595027],
              [-80.89101962035862, 34.591913009153217],
              [-80.890694098480367, 34.591870540372561],
              [-80.890746293269174, 34.591605676052389],
              [-80.890420773840873, 34.591563206513797],
              [-80.890525164475207, 34.591033478998057],
              [-80.890199647430165, 34.590991008860691],
              [-80.890251843255015, 34.590726144763799],
              [-80.889926327569881, 34.59068367388565],
              [-80.889978524081144, 34.590418809910481],
              [-80.889653009756131, 34.590376338291691],
              [-80.88970520693303, 34.590111473536872],
              [-80.889379693967911, 34.59006900117744],
              [-80.889431891851956, 34.589804137445711],
              [-80.888129848521928, 34.589634238830492],
              [-80.88818205016176, 34.589369375646484],
              [-80.887856540927189, 34.589326899157676],
              [-80.887908744322601, 34.589062035176944],
              [-80.887583236447938, 34.589019557947402],
              [-80.887635440529635, 34.588754694088458],
              [-80.887309934015079, 34.588712216118338],
              [-80.887362138783061, 34.588447352381095],
              [-80.887687643205368, 34.588489831127845],
              [-80.887739846613385, 34.58822496722847],
              [-80.88806535032856, 34.588267444049173],
              [-80.888326353910159, 34.586943122690592],
              [-80.888000855310565, 34.586900646579728],
              [-80.888053056697544, 34.586635782559483],
              [-80.887727558347208, 34.586593304823765],
              [-80.887779760420457, 34.586328440925385],
              [-80.887454264540594, 34.586285963333317],
              [-80.887506466189635, 34.586021098672603],
              [-80.887831961046402, 34.586063576122768],
              [-80.887936362398349, 34.585533847341296],
              [-80.887610869587718, 34.585491370175077],
              [-80.887715271617822, 34.584961640695788],
              [-80.887389781169389, 34.584919162029593],
              [-80.887441983247243, 34.58465429739325],
              [-80.887116494179381, 34.584611818887801],
              [-80.887168696943363, 34.584346954373267],
              [-80.886843209214646, 34.584304474225867],
              [-80.886947614667847, 34.583774744536463],
              [-80.886622130432443, 34.583732264674637],
              [-80.886778738599816, 34.582937669973923],
              [-80.886453257770327, 34.582895189655524],
              [-80.886505460472023, 34.582630324562352],
              [-80.886179980981609, 34.582587842602067],
              [-80.886232185459164, 34.582322977613806],
              [-80.883628368797275, 34.581983093115696],
              [-80.883680581121794, 34.581718229243407],
              [-80.883355106708905, 34.581675739624217],
              [-80.88340731971941, 34.581410875873935],
              [-80.883081846666343, 34.581368385514295],
              [-80.883186274791768, 34.580838657319177],
              [-80.883511744709295, 34.580881147411851],
              [-80.883668380767574, 34.580086555002268],
              [-80.88399384795315, 34.580129043786386],
              [-80.884644784424594, 34.580214018690008],
              [-80.884696992324606, 34.579949153492258],
              [-80.885022460587535, 34.579991639469739],
              [-80.885074667148416, 34.579726775011274],
              [-80.885400133614908, 34.57976925908001],
              [-80.885452338816151, 34.579504394459605],
              [-80.885608951246994, 34.578709797791618],
              [-80.885934413979314, 34.57875228131121],
              [-80.886038818173645, 34.578222549821049],
              [-80.886091020289697, 34.577957683136191],
              [-80.886143220999926, 34.577692817349778],
              [-80.88624762247926, 34.577163084798741],
              [-80.885922165884139, 34.577120602131174],
              [-80.885974367675885, 34.576855735508559],
              [-80.885648912440388, 34.576813252100528],
              [-80.885701114918291, 34.576548385599999],
              [-80.885375661042403, 34.576505901451483],
              [-80.885050207503198, 34.576463416420424],
              [-80.885102410600268, 34.576198550201127],
              [-80.885154614450258, 34.575933683944797],
              [-80.884829163293489, 34.575891198315304],
              [-80.884881367829493, 34.575626332181088],
              [-80.8849335720287, 34.575361466026891],
              [-80.885037978306258, 34.574831732774754],
              [-80.884712532667265, 34.574789246813829],
              [-80.884764735789105, 34.57452438075908],
              [-80.884439291488974, 34.574481893156396],
              [-80.884543699836925, 34.573952160352995],
              [-80.884218257939807, 34.573909673053244],
              [-80.884270463165905, 34.573644806304564],
              [-80.883945022628325, 34.573602318264406],
              [-80.883997227450607, 34.573337451655014],
              [-80.883671788272565, 34.573294962874435],
              [-80.883723994870479, 34.573030096370054],
              [-80.883398557031384, 34.572987605947759],
              [-80.883555177872637, 34.572193006741713],
              [-80.883229743438321, 34.572150515863108],
              [-80.883281950734556, 34.571885649563434],
              [-80.882956517680398, 34.571843158845788],
              [-80.883008725641858, 34.571578291767054],
              [-80.883165346436357, 34.570783691330341],
              [-80.882839918899492, 34.570741200281333],
              [-80.882944333836477, 34.570211466229608],
              [-80.883100952626449, 34.569416865020806],
              [-80.883426375050036, 34.569459355359612],
              [-80.883791800248943, 34.567605281476695],
              [-80.883466384963, 34.567562791230792],
              [-80.883622993587778, 34.566768186725497],
              [-80.882972171271945, 34.56668320532313],
              [-80.883076580134059, 34.566153468543121],
              [-80.882751170971289, 34.566110976360392],
              [-80.882803375930152, 34.565846108533584],
              [-80.882477969216254, 34.565803615593495],
              [-80.882530174860804, 34.565538747888965],
              [-80.88285558055216, 34.565581240687003],
              [-80.882907784816823, 34.565316371919465],
              [-80.883558595164786, 34.565401354584495],
              [-80.883610797047538, 34.5651364855132],
              [-80.883936201704032, 34.565178975380192],
              [-80.884197197685793, 34.563854629069667],
              [-80.884522597566402, 34.563897117344155],
              [-80.884574795383855, 34.563632247870814],
              [-80.884900194578663, 34.563674735120983],
              [-80.885161169082579, 34.562350384113934],
              [-80.884835775020946, 34.562307898475254],
              [-80.884887970797536, 34.562043027982831],
              [-80.884562578074267, 34.56200054070257],
              [-80.884666969572805, 34.561470799959999],
              [-80.884341579231148, 34.561428312081524],
              [-80.884445971427837, 34.560898571544655],
              [-80.883795196934003, 34.560813593691968],
              [-80.884056181171715, 34.559489241633564],
              [-80.884381562593489, 34.559531730299398],
              [-80.884485952100647, 34.559001988871429],
              [-80.884811331814277, 34.559044476370914],
              [-80.884863524485382, 34.558779605043462],
              [-80.885188903513281, 34.558822091518586],
              [-80.885293285800742, 34.558292348521015],
              [-80.885618663120525, 34.558334833829917],
              [-80.885670852736908, 34.558069962159749],
              [-80.886646982559228, 34.558197412384132],
              [-80.886751354627208, 34.557667667231797],
              [-80.88707672913678, 34.557710148602816],
              [-80.887128913653882, 34.557445276306069],
              [-80.887454288567156, 34.557487756635702],
              [-80.887506470635813, 34.557222884194573],
              [-80.888157218317161, 34.557307841038913],
              [-80.888209399093981, 34.557042968277123],
              [-80.889185520628232, 34.557170396942865],
              [-80.889133342939388, 34.557435271032091],
              [-80.889784095075825, 34.557520219036938],
              [-80.889731920163939, 34.557785092472066],
              [-80.890057296680283, 34.557827565761045],
              [-80.89000512245417, 34.558092439318699],
              [-80.890330501419015, 34.558134911850154],
              [-80.890278327878761, 34.558399785530284],
              [-80.891254469838742, 34.558527197355872],
              [-80.891202299029231, 34.558792071442468],
              [-80.891527681364295, 34.558834539827529],
              [-80.891475511240714, 34.559099414036581],
              [-80.891800894955495, 34.559141882582708],
              [-80.891696555722902, 34.559671630324914],
              [-80.89202194181901, 34.559714098272721],
              [-80.891969772710112, 34.559978971805791],
              [-80.892295161254708, 34.560021438996124],
              [-80.892242992831683, 34.560286312651698],
              [-80.894195333949142, 34.560541094563689],
              [-80.894143171324558, 34.560805969051486],
              [-80.894468564248783, 34.560848429448377],
              [-80.894416402310227, 34.561113304058502],
              [-80.894741796593436, 34.561155763715043],
              [-80.894689635361814, 34.561420639348896],
              [-80.895015031004007, 34.561463098265158],
              [-80.894910710624259, 34.561992847938171],
              [-80.895236108647993, 34.562035306255929],
              [-80.895079629146394, 34.562829930134598],
              [-80.896706639650333, 34.563042210617631],
              [-80.896758795427274, 34.562777334989441],
              [-80.897084197514801, 34.562819788297148],
              [-80.897409599938754, 34.562862240722509],
              [-80.89735744727578, 34.563127115716085],
              [-80.897682851058732, 34.563169567401047],
              [-80.897630699102663, 34.563434443418181],
              [-80.897956104223809, 34.563476893461392],
              [-80.897851801326851, 34.564006644819457],
              [-80.898177209940002, 34.564049095148242],
              [-80.898125058999042, 34.564313970489074],
              [-80.898450467881759, 34.564356420094654],
              [-80.898398318716573, 34.564621295540611],
              [-80.899049140605015, 34.564706191469952],
              [-80.898944843176949, 34.565235942905019],
              [-80.898892693958246, 34.56550081859308],
              [-80.899218109009453, 34.56554326520061],
              [-80.899113812697735, 34.566073016784429],
              [-80.899439230130582, 34.566115462793398],
              [-80.899334933408255, 34.566645213698244],
              [-80.899660353243718, 34.566687660009954],
              [-80.899503909784329, 34.567482286177665],
              [-80.899829333003453, 34.567524731131321],
              [-80.899568591507816, 34.56884910600116],
              [-80.899894021287537, 34.568891551665956],
              [-80.899737575828013, 34.569686175508281],
              [-80.900063007922697, 34.569728620733578],
              [-80.899802264203899, 34.57105299261103],
              [-80.900127702838645, 34.571095437646036],
              [-80.90002340414911, 34.571625185655023],
              [-80.900348845145032, 34.571667629190031],
              [-80.900296697418511, 34.571932503740136],
              [-80.899971254310216, 34.571890060080491],
              [-80.899919104113934, 34.572154933584891],
              [-80.900244548265903, 34.572197378287726],
              [-80.900192398756104, 34.572462251914267],
              [-80.900517843156834, 34.57250469499246],
              [-80.900465695443856, 34.572769569625166],
              [-80.900791141203854, 34.572812011962704],
              [-80.900686845683012, 34.573341759667102],
              [-80.901012293825133, 34.573384201405901],
              [-80.900960147127819, 34.573649075361651],
              [-80.901285596629236, 34.573691516359915],
              [-80.901233450618264, 34.57395639043763],
              [-80.901558901479078, 34.573998830695295],
              [-80.901454609403942, 34.574528579092366],
              [-80.901780063715663, 34.574571017832746],
              [-80.901623625582843, 34.575365639812503],
              [-80.901949084410376, 34.575408078979983],
              [-80.90184479195203, 34.575937826862102],
              [-80.902170253161756, 34.57598026543068],
              [-80.902065962472491, 34.576510012598519],
              [-80.902391424974823, 34.576552450585716],
              [-80.902234988941643, 34.577347071622583],
              [-80.902560455918206, 34.577389508234155],
              [-80.902456165956394, 34.577919255770567],
              [-80.90278163531525, 34.577961691783372],
              [-80.902677346032903, 34.578491438622564],
              [-80.903002816705325, 34.578533874955127],
              [-80.902950673116891, 34.578798748027424],
              [-80.903276146238468, 34.578841183601945],
              [-80.90317185900868, 34.579370929987796],
              [-80.90349733451275, 34.579413364963429],
              [-80.90344519196114, 34.579678238259618],
              [-80.903770668803773, 34.579720671593293],
              [-80.903666383647547, 34.580250418426871],
              [-80.903991862872658, 34.580292851161587],
              [-80.903939721357816, 34.580557724681718],
              [-80.904265201963312, 34.580600157577003],
              [-80.904213061114191, 34.580865030317696],
              [-80.904538543079269, 34.580907462472247],
              [-80.904486402916802, 34.581172335334728],
              [-80.905137370971005, 34.581257196361122],
              [-80.905085232518388, 34.581522069487356],
              [-80.905410718607527, 34.581564498359164],
              [-80.905358579772738, 34.581829372525867],
              [-80.905684067221472, 34.581871800656884],
              [-80.905631930142235, 34.582136674026835],
              [-80.906282908094994, 34.582221527924752],
              [-80.906230772746525, 34.582486402459615],
              [-80.906556262160876, 34.582528828243774],
              [-80.906504128567917, 34.582793701981885],
              [-80.907155111541883, 34.582878551168719],
              [-80.907102979658831, 34.583143425170455],
              [-80.90742847321836, 34.583185848573059],
              [-80.907376340911341, 34.583450721812525],
              [-80.90770183583048, 34.583493144474332],
              [-80.90764970423119, 34.58375801873698],
              [-80.907975200509782, 34.583800440657953],
              [-80.908027331085904, 34.583535566253502],
              [-80.908352826677685, 34.583577987150079],
              [-80.908404955894298, 34.583313112583909],
              [-80.90970693751126, 34.583482785875475],
              [-80.910032433761572, 34.583525202217189],
              [-80.909980310750598, 34.583790077474895],
              [-80.910631307375425, 34.583874906875216],
              [-80.910579184984712, 34.584139782413892],
              [-80.911230185001074, 34.584224608567155],
              [-80.911178065389265, 34.584489483450625],
              [-80.911503566390778, 34.584531895804488],
              [-80.911451447465893, 34.584796770809682],
              [-80.911776950916618, 34.584839182405211],
              [-80.911724832678672, 34.585104057532149],
              [-80.91205033639919, 34.585146468404155],
              [-80.911946100960861, 34.585676218881545],
              [-80.912271608153873, 34.585718629137112],
              [-80.912219490953532, 34.585983504487487],
              [-80.912544999485164, 34.586025913100698],
              [-80.912492882971776, 34.586290788572867],
              [-80.913143903111234, 34.586375604336112],
              [-80.913091788308094, 34.586640480071637],
              [-80.913417299895102, 34.586682886320325],
              [-80.913365185778957, 34.58694776217763],
              [-80.913690698725432, 34.586990167685364],
              [-80.913638586386242, 34.58725504364687],
              [-80.91591719467938, 34.587551858460671],
              [-80.91596929987648, 34.587286982408422],
              [-80.9162948166028, 34.587329380837282],
              [-80.916346920419329, 34.587064503721869],
              [-80.916672435368696, 34.587106901143777],
              [-80.916776639946647, 34.586577146569461],
              [-80.917102154275355, 34.586619542807782],
              [-80.917050052087902, 34.586884420264042],
              [-80.917375567776105, 34.58692681576138],
              [-80.91732346627586, 34.587191693339378],
              [-80.917648983323573, 34.587234088095713],
              [-80.917596883579151, 34.58749896487663],
              [-80.917922400896515, 34.587541358909355],
              [-80.917870301860304, 34.587806236713163],
              [-80.918521340619122, 34.587891021495174],
              [-80.918469243293401, 34.588155899562288],
              [-80.918794763665971, 34.588198291230405],
              [-80.918742668096243, 34.588463168500382],
              [-80.919068189828323, 34.588505559427361],
              [-80.91901609385603, 34.588770436836462],
              [-80.919667142534053, 34.588855215389465],
              [-80.919615048272235, 34.589120093061752],
              [-80.919940574149408, 34.589162481606564],
              [-80.919836386664173, 34.589692237174354],
              [-80.919510858740239, 34.589649848346241],
              [-80.919406668953471, 34.590179603532782],
              [-80.919081139339966, 34.590137214439906],
              [-80.919029042352861, 34.590402090968794],
              [-80.918703512052133, 34.590359700851501],
              [-80.918547216024677, 34.591154330793572],
              [-80.918872749416721, 34.591196722237235],
              [-80.9188206510849, 34.59146159868564],
              [-80.919146186926469, 34.591503989370786],
              [-80.919094089281884, 34.591768865940644],
              [-80.919745161819634, 34.591853644079578],
              [-80.919693066975668, 34.592118520895291],
              [-80.920018604782769, 34.59216090923298],
              [-80.919966509536167, 34.592425786187633],
              [-80.920292049771732, 34.592468172865431],
              [-80.920239955212523, 34.592733049941522],
              [-80.920891037670231, 34.5928178218509],
              [-80.92083894591164, 34.593082699172797],
              [-80.921164488112723, 34.593125083503026],
              [-80.921060304523124, 34.593654837485687],
              [-80.921385850197268, 34.593697221199001],
              [-80.921333758387362, 34.593962098761139],
              [-80.921008211689625, 34.593919714906157],
              [-80.920956119588894, 34.594184591387609],
              [-80.920630572203905, 34.594142206508167],
              [-80.920526382787941, 34.594671960063465],
              [-80.920200833671004, 34.594629573116578],
              [-80.920096641953307, 34.595159326290322],
              [-80.919771091125511, 34.595116938177291],
              [-80.919718993183295, 34.595381814150166],
              [-80.919393441689252, 34.595339425914013],
              [-80.919341342387369, 34.595604301725011],
              [-80.919015790205918, 34.595561912464376],
              [-80.918807384430835, 34.596621414055605],
              [-80.919132941796278, 34.596663803865503],
              [-80.919028739472964, 34.597193554363692],
              [-80.919354298131864, 34.597235943591464],
              [-80.919302197500031, 34.597500819402512],
              [-80.919627758608726, 34.597543207871759],
              [-80.91936725541828, 34.59886758282434],
              [-80.919263050677429, 34.599397331779457],
              [-80.919588619308712, 34.599439721258847],
              [-80.919484416360149, 34.599969470398598],
              [-80.919809987353858, 34.600011858377137],
              [-80.919757886388297, 34.600276732607561],
              [-80.920083459831915, 34.600319119827468],
              [-80.919979258960538, 34.600848869411927],
              [-80.920304833697713, 34.600891256049685],
              [-80.920200633507648, 34.601421004934792],
              [-80.920526211718354, 34.60146339095553],
              [-80.920369911961501, 34.602258013654136],
              [-80.920695493579629, 34.602300399217022],
              [-80.920591294100873, 34.602830147563822],
              [-80.920916878102602, 34.60287253252703],
              [-80.920812679326332, 34.60340228107561],
              [-80.921138265711861, 34.603444665439213],
              [-80.921086166832509, 34.603709539373938],
              [-80.921411754578102, 34.603751922996253],
              [-80.921359656386272, 34.604016797052125],
              [-80.921685245491744, 34.604059179933188],
              [-80.921581051237084, 34.604588928249669],
              [-80.921906642705224, 34.604631309629795],
              [-80.921854545552222, 34.604896183907748],
              [-80.922180139491573, 34.60493856543038],
              [-80.922128043005017, 34.605203438928285],
              [-80.922453637214289, 34.605245819727067],
              [-80.922297349941047, 34.606040442308164],
              [-80.921971751570268, 34.605998061102021],
              [-80.921867556682358, 34.606527808797722],
              [-80.922193157100764, 34.606570190287123],
              [-80.922088961804221, 34.607099937300461],
              [-80.922414564585523, 34.607142317288933],
              [-80.922310369991678, 34.607672064503575],
              [-80.92263597517794, 34.607714444793622],
              [-80.922531782355946, 34.608244191290915],
              [-80.922857389926179, 34.608286570981178],
              [-80.922701100708437, 34.609081191457605],
              [-80.923026711665429, 34.609123569788487],
              [-80.922870422472059, 34.609918189603896],
              [-80.923196036836998, 34.60996056747662],
              [-80.923039747668014, 34.610755186630826],
              [-80.923365366531144, 34.610797564027934],
              [-80.923209077407705, 34.611592183422019],
              [-80.923534699678939, 34.61163456036104],
              [-80.92343050654874, 34.612164305914519],
              [-80.923756131204058, 34.612206682253642],
              [-80.923651939866986, 34.612736427990605],
              [-80.923977566906629, 34.612778803729974],
              [-80.923925472292169, 34.613043676249767],
              [-80.924251100692146, 34.613086051247599],
              [-80.924146911412322, 34.613615796526133],
              [-80.924472542196455, 34.613658170924161],
              [-80.924316258260717, 34.614452788219673],
              [-80.924641893543395, 34.614495162141935],
              [-80.924485609653502, 34.615289779676679],
              [-80.924811248344568, 34.615332153140692],
              [-80.924759153688697, 34.615597025157555],
              [-80.925410434127627, 34.615681769719487],
              [-80.925358342273881, 34.615946641981424],
              [-80.925683984021845, 34.615989013079357],
              [-80.925631891765605, 34.616253885479722],
              [-80.926283178297268, 34.616338624408058],
              [-80.92623108884321, 34.61660349705344],
              [-80.926556733648155, 34.616645865785344],
              [-80.926504644881973, 34.616910738551567],
              [-80.926830291047168, 34.616953106541857],
              [-80.926726114533196, 34.617482851394179],
              [-80.927051763082844, 34.617525218784529],
              [-80.926999675335068, 34.617790090870599],
              [-80.927325325245164, 34.617832457519398],
              [-80.927273238206467, 34.618097330627691],
              [-80.927598890545937, 34.618139695616094],
              [-80.927546803104917, 34.6184045688628],
              [-80.928523764143932, 34.618531659872801],
              [-80.928471680508252, 34.618796532604641],
              [-80.928797336262051, 34.618838895244984],
              [-80.928745253314446, 34.619103768097602],
              [-80.929396567857793, 34.619188490110737],
              [-80.92934448662227, 34.619453363225745],
              [-80.929995804537555, 34.619538081088088],
              [-80.929943725014226, 34.619802954465477],
              [-80.930595046343825, 34.619887669979555],
              [-80.930542968532833, 34.620152543619383],
              [-80.932171283897986, 34.620364315386269],
              [-80.932119210871903, 34.620629189713],
              [-80.933096207633511, 34.620756242592691],
              [-80.932939999006365, 34.621550865768086],
              [-80.933265667953918, 34.62159321449068],
              [-80.933109458263132, 34.622387837020092],
              [-80.933435131731201, 34.622430186167819],
              [-80.933383062542617, 34.622695060138007],
              [-80.93370873737112, 34.622737408544026],
              [-80.93360459894383, 34.623267156722591],
              [-80.933278922066705, 34.623224808033548],
              [-80.933226851869819, 34.623489681941415],
              [-80.933552529771163, 34.623532030771933],
              [-80.933188033885301, 34.625386144964757],
              [-80.932862348813458, 34.625343795143706],
              [-80.932810274836939, 34.625608668902679],
              [-80.932484589076893, 34.625566318056826],
              [-80.932432514808966, 34.625831190734552],
              [-80.93178114115841, 34.625746486127852],
              [-80.931676986426154, 34.626276230872222],
              [-80.932002674586954, 34.626318583909011],
              [-80.931950597195737, 34.626583456400255],
              [-80.933579052483367, 34.626795207170289],
              [-80.933526979878053, 34.627060080348137],
              [-80.93450406079495, 34.627187119904114],
              [-80.934451990926718, 34.627451993485565],
              [-80.935103381941346, 34.627536682690064],
              [-80.935051314854874, 34.62780155561471],
              [-80.935377011901451, 34.627843899484255],
              [-80.935272878768998, 34.628373645553978],
              [-80.935598578200697, 34.628415988823122],
              [-80.935338244609639, 34.629740354339283],
              [-80.935663949478482, 34.629782696531194],
              [-80.935611882776342, 34.630047569713199],
              [-80.935937590096231, 34.630089911145731],
              [-80.935885524061177, 34.630354783546963],
              [-80.936862650041974, 34.630481803888017],
              [-80.936810586744429, 34.630746676692723],
              [-80.937136296388587, 34.630789015793866],
              [-80.937084234869886, 34.631053888701508],
              [-80.937409945853503, 34.631096226159471],
              [-80.937305822787465, 34.631625973113806],
              [-80.93763153724673, 34.631668309953781],
              [-80.937527413773722, 34.632198056223359],
              [-80.937853130618208, 34.632240392462926],
              [-80.937749008919027, 34.632770138012646],
              [-80.938074728148777, 34.632812473651853],
              [-80.938022667284969, 34.633077346996124],
              [-80.938348388965863, 34.633119681875748],
              [-80.938296328790543, 34.633384555340591],
              [-80.938622050741685, 34.633426889495915],
              [-80.938465873411346, 34.634221508350841],
              [-80.938791598772411, 34.634263842047147],
              [-80.938687480543152, 34.634793587533345],
              [-80.93901320938005, 34.634835920611572],
              [-80.938961150240758, 34.635100793473356],
              [-80.939286880438331, 34.635143125809648],
              [-80.939234821987569, 34.635407998791884],
              [-80.939560553524672, 34.635450329484833],
              [-80.939508496852866, 34.635715202569877],
              [-80.939834228681548, 34.635757533439872],
              [-80.939782172698315, 34.636022406645296],
              [-80.940433641572994, 34.636107065081539],
              [-80.940381587303023, 34.636371938548692],
              [-80.940707323258508, 34.636414266132526],
              [-80.940655268586639, 34.636679139737687],
              [-80.941632482655493, 34.636806118496651],
              [-80.941580431812156, 34.637070992487367],
              [-80.942557650889199, 34.637197963737592],
              [-80.942505602762637, 34.637462837230139],
              [-80.94283134525655, 34.637505159938101],
              [-80.94277929781876, 34.637770033550964],
              [-80.943430784730992, 34.637854674814371],
              [-80.943378739027906, 34.638119549590293],
              [-80.944030229333777, 34.638204187602788],
              [-80.943978185322806, 34.638469061739102],
              [-80.944629680112826, 34.638553696483022],
              [-80.944577637815371, 34.638818570881007],
              [-80.945229134908459, 34.638903202391617],
              [-80.94517709541509, 34.639168077033617],
              [-80.945828596992314, 34.63925270527556],
              [-80.945776558121992, 34.63951758019688],
              [-80.946102310439386, 34.639559893134098],
              [-80.946050273348504, 34.639824768158086],
              [-80.946701781019428, 34.639909390763414],
              [-80.94664974455165, 34.640174266066701],
              [-80.946975501017107, 34.640216576618528],
              [-80.946923465216784, 34.640481451140751],
              [-80.947249221952575, 34.640523760968073],
              [-80.947197187931579, 34.640788635592855],
              [-80.947522947118756, 34.640830944660543],
              [-80.947470912696062, 34.641095819423157],
              [-80.947796673244056, 34.641138127748711],
              [-80.947692606531135, 34.641667877475683],
              [-80.948018369464734, 34.641710185200381],
              [-80.94796633717452, 34.64197506016469],
              [-80.948292100378396, 34.642017367164939],
              [-80.948240068755695, 34.642282241348113],
              [-80.948565834411085, 34.642324547588544],
              [-80.948513803498614, 34.642589422793264],
              [-80.948839570493462, 34.642631727390203],
              [-80.948683478793697, 34.643426352399977],
              [-80.949009248130054, 34.643468657456445],
              [-80.948957217902162, 34.643733531957849],
              [-80.949282989689976, 34.643775836254449],
              [-80.949230960151056, 34.644040710875998],
              [-80.949556734368954, 34.644083013511384],
              [-80.949504705540434, 34.644347889154339],
              [-80.949830480028638, 34.644390191065312],
              [-80.94967439453805, 34.645184815587157],
              [-80.950000172437072, 34.645227117038502],
              [-80.949948145383857, 34.645491991960348],
              [-80.95027392466524, 34.645534293570826],
              [-80.950169869398351, 34.646064042767584],
              [-80.950495652134848, 34.646106342858133],
              [-80.950443625033145, 34.646371218016597],
              [-80.950769409130586, 34.646413517364977],
              [-80.95071738269651, 34.646678391742263],
              [-80.951368952880074, 34.646762988989884],
              [-80.951316929250723, 34.647027863610781],
              [-80.951642716406027, 34.647070160591106],
              [-80.951590692396564, 34.647335036251107],
              [-80.951916480912857, 34.647377332489185],
              [-80.951864457571048, 34.647642207367902],
              [-80.9521902474483, 34.647684502863697],
              [-80.951982157003485, 34.648744002695942],
              [-80.951656363025904, 34.648701706635016],
              [-80.951604337983071, 34.648966580506155],
              [-80.951278543316448, 34.648924283420357],
              [-80.950914352588711, 34.650778401619228],
              [-80.951240155521575, 34.65082069967643],
              [-80.951084072412627, 34.651615320696862],
              [-80.951409878735518, 34.651657617393106],
              [-80.951305822972841, 34.652187364927762],
              [-80.951631631703449, 34.652229661924309],
              [-80.951527576625651, 34.652759408754825],
              [-80.95185338774256, 34.652801705150253],
              [-80.951801361804854, 34.65306657820642],
              [-80.952127174282893, 34.653108873859509],
              [-80.952075147943788, 34.653373747053315],
              [-80.952400961782899, 34.653416041964093],
              [-80.952348937223718, 34.653680915260054],
              [-80.952674752423988, 34.653723209428449],
              [-80.952622727463307, 34.653988082862085],
              [-80.952948545115575, 34.654030376270377],
              [-80.952896520844064, 34.654295249823896],
              [-80.953548158094605, 34.654379833388518],
              [-80.953496136628402, 34.654644707185462],
              [-80.953821956226776, 34.654686997341763],
              [-80.95376993544977, 34.654951871258582],
              [-80.954095757521529, 34.65499416155609],
              [-80.954043736321594, 34.655259034709196],
              [-80.954369559754554, 34.655301324264286],
              [-80.95426551950959, 34.655831071672296],
              [-80.95459134530752, 34.655873359724986],
              [-80.954539326262591, 34.656138233979966],
              [-80.954213499417918, 34.656095944884669],
              [-80.954109457157273, 34.656625692164191],
              [-80.954435286031028, 34.656667980640634],
              [-80.954331244477146, 34.65719772811692],
              [-80.954657076828212, 34.657240015974438],
              [-80.954501014476136, 34.658034636108596],
              [-80.954826850239101, 34.658076923506179],
              [-80.954774829444858, 34.65834179698853],
              [-80.955100666569095, 34.658384083643668],
              [-80.954996627092825, 34.658913829907306],
              [-80.95532246660369, 34.658956115961246],
              [-80.955270447943079, 34.659220989643899],
              [-80.955596287724447, 34.659263274973107],
              [-80.955492251403101, 34.659793020753753],
              [-80.955818094683437, 34.659835306365316],
              [-80.955714057978341, 34.660365052360127],
              [-80.956039903623804, 34.660407336469135],
              [-80.955987886338178, 34.660672209566677],
              [-80.95631373334507, 34.660714492933245],
              [-80.956261716748799, 34.660979366150514],
              [-80.957239262861208, 34.661106211371234],
              [-80.957187249005329, 34.66137108499067],
              [-80.958490653695804, 34.661540199224966],
              [-80.958438643605646, 34.661805073387697],
              [-80.959090350573405, 34.66188962592657],
              [-80.959038342176711, 34.662154499448917],
              [-80.959690052538932, 34.662239048735039],
              [-80.959430017974995, 34.663563418301422],
              [-80.959104156953146, 34.663521143403102],
              [-80.958948128876528, 34.664315763923284],
              [-80.959273992974872, 34.664358039245229],
              [-80.959169974970621, 34.664887785525821],
              [-80.959495841455919, 34.664930060246391],
              [-80.959443832419254, 34.665194933053755],
              [-80.960095568427079, 34.665279479224608],
              [-80.96004356221799, 34.665544353176323],
              [-80.960369431740517, 34.665586624626521],
              [-80.960317425130199, 34.665851498715597],
              [-80.960969168324908, 34.665936039229145],
              [-80.960917164520723, 34.666200913561191],
              [-80.961243037102022, 34.666243182642326],
              [-80.96119103396596, 34.666508056192598],
              [-80.961842783299772, 34.666592592869257],
              [-80.961790780788107, 34.666857466698083],
              [-80.962442534607476, 34.666942000104022],
              [-80.962390533811117, 34.667206874193525],
              [-80.962716412239416, 34.667249139261251],
              [-80.962664413223706, 34.66751401345244],
              [-80.962990293013476, 34.667556277777521],
              [-80.962938293596622, 34.667821152106129],
              [-80.963264174747849, 34.667863415688458],
              [-80.963212177090099, 34.66812828921735],
              [-80.963538059624383, 34.668170552958379],
              [-80.963486062656415, 34.66843542660677],
              [-80.963811946530598, 34.668477688703788],
              [-80.96375995027411, 34.668742563373094],
              [-80.964085835509692, 34.668784824727325],
              [-80.964033839921328, 34.669049698614778],
              [-80.964359726518495, 34.669091959226307],
              [-80.964307731620053, 34.669356833233216],
              [-80.964633620669559, 34.669399093084188],
              [-80.964529631938007, 34.669928842216635],
              [-80.964855522283855, 34.669971101483846],
              [-80.964803528429186, 34.670235975708039],
              [-80.965129421227502, 34.670278234214663],
              [-80.965025434540436, 34.670807981978889],
              [-80.965351329725991, 34.670850239883919],
              [-80.965299336915223, 34.671115114325261],
              [-80.965625233462333, 34.671157371487539],
              [-80.965573241319859, 34.671422245146971],
              [-80.965899139228512, 34.671464501566476],
              [-80.965847147797547, 34.67172937624666],
              [-80.966173047046155, 34.671771631022082],
              [-80.966121056305099, 34.672036505821666],
              [-80.966446956915263, 34.672078759854301],
              [-80.966394966864243, 34.672343634773291],
              [-80.966720868835878, 34.67238588806314],
              [-80.966668880565692, 34.672650763083652],
              [-80.966994783898997, 34.672693015630692],
              [-80.966942795206165, 34.672957889887137],
              [-80.96726869992267, 34.67300014259277],
              [-80.967060748078453, 34.674059639929624],
              [-80.967386658303298, 34.67410189139656],
              [-80.967178704055058, 34.675161387164742],
              [-80.96750461874062, 34.675203639213528],
              [-80.967452630081254, 34.675468512796002],
              [-80.968104462489791, 34.675553013622597],
              [-80.968052475546401, 34.675817887465499],
              [-80.968704311349882, 34.675902385038434],
              [-80.968652327213334, 34.676167259123879],
              [-80.968978246633853, 34.676209506274837],
              [-80.968874278314161, 34.676739254680342],
              [-80.969200200143874, 34.676781502130837],
              [-80.969096233602073, 34.677311249812028],
              [-80.969422157797723, 34.677353495759398],
              [-80.969318190895137, 34.677883244554629],
              [-80.969644118569448, 34.677925489882305],
              [-80.969488169277184, 34.678720111522942],
              [-80.969814099274004, 34.678762356407795],
              [-80.969762116887367, 34.679027230078958],
              [-80.970088049336994, 34.679069474203011],
              [-80.970036067662164, 34.679334348894713],
              [-80.969710134186585, 34.679292104629582],
              [-80.969554182891841, 34.680086724562599],
              [-80.969880119445307, 34.680128969251079],
              [-80.969828136405624, 34.680393842953841],
              [-80.970154074320874, 34.680436086899377],
              [-80.96999812515223, 34.681230707416098],
              [-80.970324066481268, 34.681272950900855],
              [-80.970220101462061, 34.681802697772184],
              [-80.970546045178835, 34.681844940655068],
              [-80.970338114080334, 34.682904432914093],
              [-80.970234145970679, 34.683434178920869],
              [-80.970182161935981, 34.683699050981048],
              [-80.969856211036856, 34.683656807110651],
              [-80.969804224570808, 34.683921679926911],
              [-80.970130177587109, 34.683963923920537],
              [-80.969974221391169, 34.684758540822337],
              [-80.970300177821557, 34.684800784355041],
              [-80.970248192042661, 34.685065656464296],
              [-80.970574149834974, 34.685107899254007],
              [-80.97047018150414, 34.685637643652754],
              [-80.970796141684502, 34.685679885840521],
              [-80.970380260911227, 34.687798861915979],
              [-80.970054291430586, 34.687756618617527],
              [-80.969690368369413, 34.689610715846079],
              [-80.970016345033457, 34.689652960132136],
              [-80.969964356309276, 34.689917830305944],
              [-80.972246210570759, 34.69021351381717],
              [-80.972298192089696, 34.689948641754633],
              [-80.972624171440401, 34.689990878967436],
              [-80.972572190926115, 34.690255750269721],
              [-80.972898171638889, 34.690297986739353],
              [-80.973224153757101, 34.69034022140557],
              [-80.973172174959473, 34.69060509296785],
              [-80.973498157370145, 34.690647327810176],
              [-80.973446179262865, 34.690912199491407],
              [-80.974098148192169, 34.690996664985022],
              [-80.973942218034907, 34.691791281643468],
              [-80.973616230869141, 34.691749048906651],
              [-80.973408315157258, 34.692808535708657],
              [-80.973082323130882, 34.692766301541425],
              [-80.973030342315326, 34.693031172144096],
              [-80.972704349598473, 34.692988936951636],
              [-80.972652367442535, 34.693253808292482],
              [-80.972326374035219, 34.69321157207478],
              [-80.972274390495329, 34.69347644235107],
              [-80.971948396397565, 34.693434205108026],
              [-80.971896411495507, 34.693699075221204],
              [-80.971570416707365, 34.693656836952961],
              [-80.971518430464798, 34.693921707804066],
              [-80.971192434964536, 34.69387946760925],
              [-80.970984480282524, 34.694938947559557],
              [-80.970932490221216, 34.695203817275228],
              [-80.97191049417296, 34.695330535502279],
              [-80.971858507946109, 34.695595405601047],
              [-80.973814532124209, 34.695848817206674],
              [-80.973762551719872, 34.696113688129373],
              [-80.974414564518838, 34.6961981518727],
              [-80.974362585831358, 34.696463023055223],
              [-80.975340611151779, 34.696589711551198],
              [-80.975288635207718, 34.696854583134552],
              [-80.975614645716945, 34.696896811300142],
              [-80.975458719997093, 34.697691424519164],
              [-80.975784733899729, 34.697733651322217],
              [-80.975732758665274, 34.697998523098569],
              [-80.9760587750215, 34.698040749140489],
              [-80.976006800455963, 34.698305620134263],
              [-80.976332817104861, 34.698347846352107],
              [-80.976228870110575, 34.698877588536909],
              [-80.976554889148261, 34.698919814152461],
              [-80.976502915606474, 34.699184684460093],
              [-80.977154957833591, 34.699269133302487],
              [-80.97710298710021, 34.699534003851788],
              [-80.977755032701793, 34.699618448537663],
              [-80.977703062616229, 34.699883320266011],
              [-80.978029087481929, 34.69992554096379],
              [-80.977977119178334, 34.700190412792836],
              [-80.978955197800317, 34.700317070922694],
              [-80.978903231149189, 34.70058194317037],
              [-80.980207345141991, 34.700750808553877],
              [-80.980155384422233, 34.701015680405845],
              [-80.98113347625457, 34.701142320810817],
              [-80.981081517187434, 34.70140719308133],
              [-80.981407549852776, 34.701449404615467],
              [-80.981355592567823, 34.701714276986621],
              [-80.981681626595403, 34.701756487777402],
              [-80.981629668910045, 34.702021360285158],
              [-80.98195570430002, 34.702063570332541],
              [-80.981903748396832, 34.702328442940917],
              [-80.98222978514903, 34.702370652245001],
              [-80.982177828845352, 34.702635524989958],
              [-80.982503866959988, 34.702677733550551],
              [-80.982451912416792, 34.702942605494805],
              [-80.982777951893752, 34.702984813311957],
              [-80.982725998063259, 34.703249686276102],
              [-80.984682251386801, 34.703502914497967],
              [-80.984734199057328, 34.703238040688269],
              [-80.985386284137959, 34.703322443039156],
              [-80.985438229419529, 34.703057568925253],
              [-80.986416357001673, 34.703184164485833],
              [-80.98646829886772, 34.702919289926918],
              [-80.989402689384335, 34.703299026701082],
              [-80.98935075782785, 34.703563901608703],
              [-80.99065494506678, 34.703732650863621],
              [-80.990603017281515, 34.703997526312385],
              [-80.991255115513297, 34.704081895906249],
              [-80.991203189445912, 34.704346771614318],
              [-80.991855291051465, 34.70443113705096],
              [-80.9918033667239, 34.704696013919779],
              [-80.992129419056624, 34.704738195452386],
              [-80.992077495398362, 34.705003071538286],
              [-80.992729603124033, 34.705087432231892],
              [-80.992677681183736, 34.705352308577218],
              [-80.993003737668005, 34.705394487720113],
              [-80.992951816418952, 34.705659364183902],
              [-80.99327787317408, 34.705701542601311],
              [-80.993225952616299, 34.705966419183554],
              [-80.99355201182513, 34.706008596839268],
              [-80.993448172826092, 34.706538349298889],
              [-80.993774233332573, 34.706580526369976],
              [-80.993722313821849, 34.706845403166689],
              [-80.994048376782047, 34.706887579476053],
              [-80.993996457940696, 34.707152455489897],
              [-80.994322522263118, 34.707194631055636],
              [-80.994270605226518, 34.707459508071118],
              [-80.9945966697979, 34.707501682010104],
              [-80.994544753452544, 34.70776655914397],
              [-80.994870820477757, 34.707808732321148],
              [-80.994818903732323, 34.708073609591544],
              [-80.99514497211986, 34.708115782025111],
              [-80.995093057157206, 34.708380659395743],
              [-80.99541912690708, 34.708422831085727],
              [-80.995315296893722, 34.708952584256835],
              [-80.995641369054724, 34.708994756245247],
              [-80.995589455117411, 34.709259632928884],
              [-80.995915528618823, 34.709301803272282],
              [-80.995863615372912, 34.709566680074296],
              [-80.996189690258532, 34.709608850575442],
              [-80.996137777704135, 34.709873727495697],
              [-80.996463853930265, 34.709915896351838],
              [-80.996411942067098, 34.710180773390476],
              [-80.996738019655595, 34.710222941502927],
              [-80.996686108483942, 34.710487818659949],
              [-80.997664348547772, 34.710614318977697],
              [-80.997716256638682, 34.710349441398265],
              [-80.998368415948121, 34.710433770545791],
              [-80.999020576577394, 34.710518095254081],
              [-80.998968672594202, 34.71078297339664],
              [-80.999946919680198, 34.710909454238532],
              [-80.999895019533909, 34.711174332762774],
              [-81.00022110286605, 34.71121649142723],
              [-81.000169203389319, 34.711481369168453],
              [-81.000821375296582, 34.711565684089145],
              [-81.000769476468989, 34.711830563008853],
              [-81.001095563952646, 34.711872719283143],
              [-81.000991768392538, 34.712402475515844],
              [-81.001317858265637, 34.712444631187196],
              [-81.001214063395949, 34.712974386710115],
              [-81.00154015674994, 34.713016541760169],
              [-81.001488259304267, 34.71328142008835],
              [-81.001814352929159, 34.713323574412811],
              [-81.001710561246696, 34.71385333034543],
              [-81.00203665726103, 34.71389548406691],
              [-81.001984761954773, 34.714160362590832],
              [-81.002310859309475, 34.714202514667129],
              [-81.002258964694889, 34.714467393309235],
              [-81.003237264079402, 34.714593846419326],
              [-81.003185372188582, 34.714858724559797],
              [-81.003511472960909, 34.714900874280637],
              [-81.003459581761803, 34.715165752539292],
              [-81.003785684988088, 34.715207901498204],
              [-81.003733794480695, 34.715472779874958],
              [-81.004059899047292, 34.715514927188686],
              [-81.004008009231725, 34.715779805683688],
              [-81.004334115182814, 34.715821953154794],
              [-81.004282226058891, 34.716086831767946],
              [-81.004608333350461, 34.716128977593947],
              [-81.004556444918322, 34.716393856325197],
              [-81.004882553594399, 34.716436002308626],
              [-81.00483066583196, 34.716700880256766],
              [-81.005156775870418, 34.716743025496271],
              [-81.005053002507481, 34.717272782489317],
              [-81.00537911491358, 34.717314926224311],
              [-81.005327228221347, 34.717579805287507],
              [-81.005653343081491, 34.717621948260422],
              [-81.005601457081269, 34.717886827441625],
              [-81.005927572212244, 34.717928969688835],
              [-81.006579804571842, 34.718013251511273],
              [-81.006631687512737, 34.717748372809282],
              [-81.007936152169137, 34.717916926177175],
              [-81.00798803066607, 34.717652046889768],
              [-81.009292496556171, 34.717820584639725],
              [-81.009344369517564, 34.717555704785198],
              [-81.009670485255342, 34.71759783687957],
              [-81.009722356832214, 34.717332955960558],
              [-81.010374588350501, 34.717417217195816],
              [-81.010426457559873, 34.717152336874136],
              [-81.011078688364904, 34.717236594289638],
              [-81.011130555162609, 34.716971712762728],
              [-81.011782785254042, 34.717055966358373],
              [-81.011834649662177, 34.716791084527557],
              [-81.012486879040011, 34.716875334303495],
              [-81.012538739967056, 34.716610452187105],
              [-81.013190968631264, 34.716694698143158],
              [-81.013242828260331, 34.716429815704693],
              [-81.014221170120919, 34.716556176690034],
              [-81.014273025241749, 34.716291293825329],
              [-81.015903596932574, 34.716501876765584],
              [-81.015955446582325, 34.716236993175187],
              [-81.016607675763893, 34.716321219693711],
              [-81.016555828168535, 34.716586103565369],
              [-81.016881944846048, 34.716628216079805],
              [-81.016778250682307, 34.717157983135301],
              [-81.017104369749333, 34.717200095046287],
              [-81.017052523203148, 34.717464979131471],
              [-81.017704764352473, 34.717549198778201],
              [-81.017652919525659, 34.717814083122022],
              [-81.018631286866636, 34.717940407280352],
              [-81.018579444764299, 34.718205291122068],
              [-81.0189055689235, 34.718247398147049],
              [-81.018853728604938, 34.718512282088376],
              [-81.019179854104266, 34.718554387467989],
              [-81.019076173447147, 34.719084156483831],
              [-81.019402302427565, 34.719126261241527],
              [-81.019194941157522, 34.7201857977618],
              [-81.018868809159414, 34.72014369242342],
              [-81.018765124437238, 34.720673459365379],
              [-81.01909125848988, 34.7207155649849],
              [-81.018832046491852, 34.722039982186836],
              [-81.019158187107962, 34.722082087606253],
              [-81.018898970733559, 34.723406503160213],
              [-81.019225116821616, 34.723448608397881],
              [-81.019121430199348, 34.723978374738188],
              [-81.019447579768936, 34.724020479353875],
              [-81.019395736414538, 34.72428536173841],
              [-81.019721886254899, 34.724327465628356],
              [-81.019670044706771, 34.72459234901374],
              [-81.019996195887416, 34.72463445125814],
              [-81.019944355031555, 34.724899334761361],
              [-81.020596661566898, 34.72498353776011],
              [-81.020544822430921, 34.725248421521755],
              [-81.020870976693885, 34.725290522294365],
              [-81.020819138228063, 34.72555540527253],
              [-81.021145294922988, 34.725597504381263],
              [-81.021093457171688, 34.72586238837858],
              [-81.021419615229235, 34.72590448674319],
              [-81.021315939640274, 34.726434253166438],
              [-81.020989779505655, 34.726392153619464],
              [-81.020937940726839, 34.726657036647246],
              [-81.020611779922035, 34.726614936976191],
              [-81.020559939758542, 34.726879818939331],
              [-81.020233778261257, 34.726837718243004],
              [-81.020130093804624, 34.727367482739531],
              [-81.019803930565232, 34.727325379976037],
              [-81.019700242713128, 34.72785514410036],
              [-81.019374077753938, 34.727813040170901],
              [-81.019270385414671, 34.728342803941302],
              [-81.019596553520572, 34.728384908133556],
              [-81.019492861873701, 34.728914671192591],
              [-81.019819032369782, 34.728956774781174],
              [-81.019767187071238, 34.729221656417131],
              [-81.019441016639249, 34.729179552669571],
              [-81.019389169977956, 34.729444434142067],
              [-81.01971534252921, 34.729486538011855],
              [-81.019559804686665, 34.730281181794496],
              [-81.019885980633489, 34.730323284299772],
              [-81.019834134686789, 34.730588165962097],
              [-81.020160312018533, 34.73063026862453],
              [-81.020108466741959, 34.73089514950324],
              [-81.02043464543641, 34.730937251421338],
              [-81.020330957025052, 34.731467013373098],
              [-81.020657138109726, 34.731509114687483],
              [-81.020553450412976, 34.732038876828824],
              [-81.020879633888072, 34.732080977539468],
              [-81.020775947997819, 34.732610739852099],
              [-81.021102133841069, 34.732652839057771],
              [-81.020998447573817, 34.733182601578335],
              [-81.021324635807375, 34.733224700180152],
              [-81.021169107499745, 34.734019343300503],
              [-81.020842916160902, 34.733977243375627],
              [-81.020635534447038, 34.735036766082779],
              [-81.020309339332655, 34.734994664710847],
              [-81.020205643841265, 34.735524424753542],
              [-81.020531842102827, 34.735566526388226],
              [-81.020479995973901, 34.73583140649739],
              [-81.021132395513376, 34.735915605590428],
              [-81.021080550012996, 34.736180485976064],
              [-81.021406751313123, 34.736222584335778],
              [-81.021199372232132, 34.73728210527279],
              [-81.021525579070172, 34.737324203291493],
              [-81.021473733927181, 34.73758908294203],
              [-81.021799942128226, 34.73763118021634],
              [-81.021748097700083, 34.737896060885745],
              [-81.022074307264035, 34.73793815741562],
              [-81.022022463506175, 34.738203037301233],
              [-81.022674885695068, 34.738287227987342],
              [-81.022623043657717, 34.738552108131032],
              [-81.02327546924262, 34.7386362955584],
              [-81.02322362892572, 34.738901175960223],
              [-81.023549843248617, 34.738943268487034],
              [-81.023498004716345, 34.739208148987885],
              [-81.023824220402176, 34.739250240770289],
              [-81.023772382562498, 34.739515121388656],
              [-81.024098599611349, 34.739557212426696],
              [-81.02404676244214, 34.739822092261214],
              [-81.024372980853926, 34.739864182554776],
              [-81.024269307588085, 34.740393943337068],
              [-81.024595528390577, 34.740436033026754],
              [-81.02454369229406, 34.740700913974514],
              [-81.024869914437247, 34.740743002018327],
              [-81.024818079033395, 34.741007883083647],
              [-81.02514430253963, 34.741049970382996],
              [-81.02509246892042, 34.741314851547287],
              [-81.025418693789533, 34.741356938102165],
              [-81.02536685977131, 34.741621819402376],
              [-81.025693087095291, 34.741663905194379],
              [-81.025641253747494, 34.74192878571062],
              [-81.025967481364944, 34.741970871677871],
              [-81.02591564870994, 34.742235752311615],
              [-81.026568109167343, 34.742319920934037],
              [-81.026516278233061, 34.742584801825572],
              [-81.029126140574633, 34.742921441156824],
              [-81.029074317528739, 34.743186323149061],
              [-81.029400552985166, 34.74322839888314],
              [-81.029348731724085, 34.743493280974405],
              [-81.029674968543574, 34.743535355963921],
              [-81.029623147975443, 34.743800238172454],
              [-81.029949386157924, 34.743842312417485],
              [-81.029897566260402, 34.74410719384209],
              [-81.030223805805775, 34.744149267342486],
              [-81.030171986623557, 34.744414149785754],
              [-81.030498227532036, 34.744456222541544],
              [-81.030446409020456, 34.744721104200913],
              [-81.030772652383774, 34.744763176193693],
              [-81.030720834587513, 34.745028058871625],
              [-81.031373322191513, 34.745112200519948],
              [-81.031321507185865, 34.745377082535875],
              [-81.033278987458914, 34.745629486125772],
              [-81.033330797388345, 34.745364603248817],
              [-81.034309538428104, 34.745490793134309],
              [-81.034361344916078, 34.745225908911664],
              [-81.034687591207145, 34.745267970302464],
              [-81.03463578681675, 34.7455328537458],
              [-81.035288283551765, 34.745616974134578],
              [-81.035236480882574, 34.745881857835485],
              [-81.036541483530982, 34.746050086733014],
              [-81.036489684639136, 34.746314970972385],
              [-81.037468444033891, 34.746441133308963],
              [-81.037520239841513, 34.746176248648453],
              [-81.038825252958631, 34.746344452745539],
              [-81.038773461263389, 34.746609337967648],
              [-81.039425971864858, 34.746693434085174],
              [-81.039374182982939, 34.74695831954628],
              [-81.039700439814084, 34.747000366417851],
              [-81.039648650533493, 34.747265252014685],
              [-81.040301168370846, 34.747349344265992],
              [-81.040249381881196, 34.747614229200579],
              [-81.040575641773088, 34.74765627369753],
              [-81.040627427234512, 34.747391388622596],
              [-81.041932467129413, 34.747559557179585],
              [-81.041984248121196, 34.747294670618821],
              [-81.042963028746186, 34.747420787317445],
              [-81.043014806341105, 34.747155901213873],
              [-81.043993586872162, 34.747282008624389],
              [-81.043941812384077, 34.747546896050245],
              [-81.045573126575661, 34.747757058643323],
              [-81.045521356893801, 34.748021946747599],
              [-81.046173886737932, 34.748106005332545],
              [-81.046225654363425, 34.747841116947662],
              [-81.047204448020608, 34.747967198225815],
              [-81.047256212249351, 34.747702310298216],
              [-81.048235005811463, 34.74782838228824],
              [-81.048286765528999, 34.747563493935182],
              [-81.048939294558821, 34.747647537516592],
              [-81.04888753689778, 34.747912426150123],
              [-81.049540070414523, 34.747996466452996],
              [-81.049384802707053, 34.74879113393915],
              [-81.049711073063293, 34.748833153634386],
              [-81.049504048487307, 34.749892709324037],
              [-81.04983032329126, 34.749934728695187],
              [-81.049778567043376, 34.750199616803279],
              [-81.05043112078107, 34.750283652250651],
              [-81.050379366277639, 34.750548541517404],
              [-81.051358201898964, 34.750674587127818],
              [-81.051306451237693, 34.750939476773546],
              [-81.053916702874062, 34.75127556056966],
              [-81.053864960082194, 34.751540450412769],
              [-81.054843814006702, 34.751666467635289],
              [-81.054792075057307, 34.751931357857096],
              [-81.055118360668303, 34.751973361980241],
              [-81.055066622412696, 34.752238252319138],
              [-81.055392909386597, 34.752280255697222],
              [-81.055341171824736, 34.752545146153203],
              [-81.055667461253549, 34.752587148767645],
              [-81.055563984999083, 34.753116929927614],
              [-81.055890276819099, 34.753158931937264],
              [-81.055786802375366, 34.753688713266015],
              [-81.056113096586699, 34.753730714670915],
              [-81.056009622838914, 34.754260495285841],
              [-81.056335919441693, 34.754302496085863],
              [-81.056284182537084, 34.754567386057317],
              [-81.056610481594774, 34.754609386093698],
              [-81.056558745406662, 34.754874277083424],
              [-81.05688504580479, 34.754916275473384],
              [-81.056833310310594, 34.755181166580115],
              [-81.059770034993932, 34.755559116275741],
              [-81.059821760139968, 34.755294223926192],
              [-81.06080067130307, 34.755420190803115],
              [-81.060852393029208, 34.755155298009804],
              [-81.06150499970731, 34.755239270626582],
              [-81.061556719064853, 34.754974378431044],
              [-81.062209325023801, 34.755058347226509],
              [-81.062261040875143, 34.754793453844776],
              [-81.062913647206841, 34.754877418800241],
              [-81.062965360689432, 34.754612526016388],
              [-81.063291663328457, 34.754654507026146],
              [-81.063343375425575, 34.754389613177516],
              [-81.063669677370399, 34.754431593161826],
              [-81.063721388104781, 34.75416669914992],
              [-81.064373989848576, 34.754250656201322],
              [-81.064425698191641, 34.753985761885964],
              [-81.064751999082617, 34.754027738934255],
              [-81.064803706062776, 34.753762844455537],
              [-81.065130005167703, 34.753804820497301],
              [-81.065181710784969, 34.753539925855179],
              [-81.065834309010199, 34.753623874082614],
              [-81.065937715127959, 34.753094084168488],
              [-81.066264012150484, 34.7531360571341],
              [-81.066315713656763, 34.752871161100821],
              [-81.066642009985046, 34.752913133041098],
              [-81.066693710151299, 34.752648237745838],
              [-81.067020005785523, 34.752690208660852],
              [-81.067071704566274, 34.752425312300907],
              [-81.067397999506355, 34.752467282190587],
              [-81.067449695832266, 34.752202385686239],
              [-81.067775990078161, 34.752244354550506],
              [-81.067827686133469, 34.751979457864117],
              [-81.068153979685107, 34.752021425703035],
              [-81.068205673285703, 34.751756528872242],
              [-81.068531967235259, 34.751798495667103],
              [-81.068583659473049, 34.751533598672971],
              [-81.068909951636414, 34.751575564461277],
              [-81.068961642511624, 34.751310667303855],
              [-81.069287933980789, 34.751352632066919],
              [-81.069339623493335, 34.751087734746335],
              [-81.069665914268199, 34.751129698484007],
              [-81.069717602418095, 34.750864801000212],
              [-81.070043892476079, 34.750906762811361],
              [-81.070095579263338, 34.750641865164283],
              [-81.070748158347612, 34.750725786752],
              [-81.070799842743824, 34.75046088880169],
              [-81.072757581846844, 34.750712631478983],
              [-81.072705903621269, 34.750977530269729],
              [-81.076621432348119, 34.751480918056856],
              [-81.076569768314073, 34.751745818467171],
              [-81.078201259190209, 34.751955524781209],
              [-81.078149598872628, 34.752220425887259],
              [-81.07880219984601, 34.752304302852814],
              [-81.078750542343442, 34.752569204196845],
              [-81.079076843790887, 34.752611140599818],
              [-81.079128501379728, 34.752346239998225],
              [-81.080107406870269, 34.752472045240459],
              [-81.080159059924682, 34.752207143313328],
              [-81.080485360673904, 34.752249076796659],
              [-81.08053701236588, 34.751984174706344],
              [-81.081189614970441, 34.752068037798097],
              [-81.08124126427127, 34.751803135404693],
              [-81.081567563965379, 34.751845065952239],
              [-81.081619211903714, 34.751580163395758],
              [-81.081945510903353, 34.75162209291809],
              [-81.081997157479194, 34.75135719019859],
              [-81.08232345685326, 34.751399117775378],
              [-81.08237510097463, 34.751134214911744],
              [-81.08270139967712, 34.751176142364599],
              [-81.082753043505008, 34.750911238417672],
              [-81.083731936323503, 34.751037014181399],
              [-81.083680295558182, 34.751301917646984],
              [-81.085638097428003, 34.751553444290394],
              [-81.085689732022061, 34.751288539985218],
              [-81.086016031920053, 34.75133045756305],
              [-81.086342333244005, 34.751372374236773],
              [-81.086393964355096, 34.751107469647579],
              [-81.08672026389236, 34.751149385314953],
              [-81.086668633832772, 34.75141429094537],
              [-81.087321238149755, 34.751498119866312],
              [-81.087269609790496, 34.75176302485221],
              [-81.087595912386405, 34.751804938143657],
              [-81.087544285813877, 34.752069843227389],
              [-81.087870589772123, 34.752111755773399],
              [-81.087767336590716, 34.752641566170325],
              [-81.088093644032085, 34.752683478091996],
              [-81.087990390457549, 34.753213287793933],
              [-81.088316700289951, 34.753255199110107],
              [-81.088213448529245, 34.75378500898038],
              [-81.088539760752738, 34.753826919691086],
              [-81.088436509714015, 34.754356729748608],
              [-81.088762824328526, 34.75439863985374],
              [-81.088659572896759, 34.754928449216173],
              [-81.088985890994451, 34.754970358696816],
              [-81.088934265806472, 34.755235263483172],
              [-81.089260585266757, 34.755277172218427],
              [-81.089208960750639, 34.755542076220195],
              [-81.089535281573333, 34.755583984209956],
              [-81.089483657775048, 34.75584888922976],
              [-81.090136302479394, 34.755932702833086],
              [-81.090084680381679, 34.756197607208257],
              [-81.09269527961979, 34.756532824641631],
              [-81.092746892372745, 34.756267918265216],
              [-81.093399543729859, 34.756351713260187],
              [-81.093451154091596, 34.756086806580875],
              [-81.093777479253617, 34.75612870306108],
              [-81.093829088252903, 34.755863796218712],
              [-81.094155411604746, 34.755905690791415],
              [-81.09420701924158, 34.755640783786063],
              [-81.094533343013623, 34.755682678215869],
              [-81.094584948195958, 34.755417771066476],
              [-81.09491127124987, 34.755459663569837],
              [-81.094859667096244, 34.755724570859051],
              [-81.095185991512651, 34.755766462616918],
              [-81.095134386961902, 34.756031370041825],
              [-81.095460712763824, 34.756073261955379],
              [-81.095305901264823, 34.756867983590283],
              [-81.095632230463536, 34.756909874136774],
              [-81.095580627704564, 34.75717478082835],
              [-81.095906958288893, 34.757216671530578],
              [-81.095803752735279, 34.757746485142818],
              [-81.09613008677978, 34.757788374318942],
              [-81.095975278509954, 34.758583095893627],
              [-81.096301615974554, 34.758624984603856],
              [-81.096250013207694, 34.758889891594762],
              [-81.096576352034816, 34.758931779559411],
              [-81.096524751055071, 34.759196686647826],
              [-81.096851090152597, 34.759238573886009],
              [-81.09679948986799, 34.759503481091002],
              [-81.097125831420243, 34.759545367564471],
              [-81.097074230738684, 34.759810274905178],
              [-81.097400573653374, 34.759852160633052],
              [-81.097348974735965, 34.760117067169872],
              [-81.098328008568885, 34.760242719460543],
              [-81.098379604376547, 34.759977811602958],
              [-81.098705948626048, 34.760019693789239],
              [-81.098757543071272, 34.759754785768607],
              [-81.099410229421338, 34.759838547224192],
              [-81.099461821475202, 34.759573638900726],
              [-81.099788164667856, 34.759615518151143],
              [-81.099839755359326, 34.759350609664558],
              [-81.100166097856757, 34.759392487889748],
              [-81.100217686093558, 34.759127579259307],
              [-81.100544027895765, 34.759169456459219],
              [-81.100595615862289, 34.758904547646672],
              [-81.100921956946152, 34.75894642292009],
              [-81.100973542458021, 34.758681513963644],
              [-81.101299882869853, 34.758723389113136],
              [-81.101351467019299, 34.758458479993678],
              [-81.101677806712686, 34.75850035321676],
              [-81.101729389499695, 34.758235443934339],
              [-81.102055728520938, 34.758277317033468],
              [-81.102158892105621, 34.757747497199091],
              [-81.102485228310684, 34.757789369152334],
              [-81.102536809108273, 34.757524458600386],
              [-81.102863144618169, 34.757566329528458],
              [-81.102914722961302, 34.757301418832746],
              [-81.103241058867923, 34.757343288716534],
              [-81.103292635848675, 34.757078377857788],
              [-81.103618969968025, 34.757120246735532],
              [-81.103670545586439, 34.75685533571388],
              [-81.10399687901041, 34.756897203566524],
              [-81.104048453266472, 34.756632292381852],
              [-81.104701120126379, 34.756716024230975],
              [-81.104752691991308, 34.756451112743711],
              [-81.10507902435829, 34.756492977660571],
              [-81.105027453522183, 34.756757889287677],
              [-81.105680122359928, 34.756841614923033],
              [-81.105576984894626, 34.75737143864756],
              [-81.105903321325314, 34.757413300426165],
              [-81.105851753667309, 34.75767821238383],
              [-81.106178091460336, 34.757720073416841],
              [-81.106023388433627, 34.758514807786575],
              [-81.106349729646709, 34.758556668353329],
              [-81.106298162689313, 34.758821579596422],
              [-81.10662450638003, 34.758863440299756],
              [-81.106572939025952, 34.75912835167852],
              [-81.106899284055942, 34.759170210734737],
              [-81.106847718489263, 34.75943512221091],
              [-81.107174064881647, 34.759476980521541],
              [-81.107122498918386, 34.759741892133356],
              [-81.107448846673051, 34.759783749698173],
              [-81.107345717988153, 34.760313573093448],
              [-81.107672068134207, 34.760355430052243],
              [-81.10756893905787, 34.760885252751905],
              [-81.107895291595284, 34.760927109104713],
              [-81.107740599101135, 34.76172184293879],
              [-81.108066956150921, 34.761763698806149],
              [-81.107809133423103, 34.763088254835182],
              [-81.108135495951274, 34.763130110515718],
              [-81.108083931843296, 34.763395020702198],
              [-81.108410294641899, 34.763436875656005],
              [-81.108358731252537, 34.763701786860203],
              [-81.108685096505781, 34.76374364104916],
              [-81.108633533788776, 34.76400855146845],
              [-81.108959900404514, 34.764050404911544],
              [-81.108908338406351, 34.764315316348643],
              [-81.109234706384598, 34.764357169045937],
              [-81.109183145058694, 34.764622079698107],
              [-81.109509514399463, 34.764663931649601],
              [-81.109457953769223, 34.76492884241808],
              [-81.110110696578133, 34.765012543023516],
              [-81.110059137695757, 34.765277454949377],
              [-81.110711883896528, 34.765361152292101],
              [-81.110660326715646, 34.765626063572739],
              [-81.111965827234968, 34.765793448190649],
              [-81.11201738029952, 34.765528536351304],
              [-81.11234375469941, 34.765570380612552],
              [-81.112395306378218, 34.765305467708828],
              [-81.113048055188898, 34.765389152374837],
              [-81.113099604499368, 34.765124240069838],
              [-81.113425977817968, 34.765166080493849],
              [-81.113477525765973, 34.764901168025808],
              [-81.114130272460613, 34.764984846819971],
              [-81.114233361032575, 34.764455020392568],
              [-81.114559733356288, 34.764496857721731],
              [-81.114611276124776, 34.764231944784086],
              [-81.114937646660508, 34.764273781107292],
              [-81.114989188043396, 34.764008867105382],
              [-81.115315558975553, 34.76405070238431],
              [-81.115418638706288, 34.763520874932603],
              [-81.115745006821584, 34.763562709065909],
              [-81.115848083137607, 34.763032880340525],
              [-81.116174449551281, 34.763074714210333],
              [-81.116225986180268, 34.762809799673157],
              [-81.116552351875001, 34.762851631616407],
              [-81.116603887141636, 34.762586716916353],
              [-81.118888450248448, 34.762879515629812],
              [-81.119214817580314, 34.762921340349408],
              [-81.119266343189068, 34.762656424528238],
              [-81.119592710917146, 34.762698248203478],
              [-81.119747282633483, 34.76190349928055],
              [-81.120073646515479, 34.76194532167068],
              [-81.120125168709976, 34.76168040457614],
              [-81.120451531896109, 34.761722225941085],
              [-81.120503052751587, 34.761457309585019],
              [-81.120829415241971, 34.761499129924857],
              [-81.120932453848567, 34.760969295061322],
              [-81.121258814614066, 34.761011114236446],
              [-81.121310332388546, 34.760746196630265],
              [-81.121636692458083, 34.760788014780296],
              [-81.121688208893687, 34.760523097912696],
              [-81.122014567152007, 34.760564914155587],
              [-81.12206608222543, 34.760299997125145],
              [-81.122392440879892, 34.760341812323702],
              [-81.122443954567757, 34.760076894229179],
              [-81.122770311457586, 34.760118709323208],
              [-81.12287333468386, 34.759588872804741],
              [-81.123199690940879, 34.759630686714857],
              [-81.123302710753222, 34.759100848923239],
              [-81.123629064193253, 34.75914266168806],
              [-81.123732080615, 34.758612823524622],
              [-81.12405843342232, 34.758654635105508],
              [-81.124264457037143, 34.757594957060761],
              [-81.124590804969515, 34.757636767217072],
              [-81.124796819115659, 34.756577087342947],
              [-81.124470475299248, 34.756535277744845],
              [-81.124521978759034, 34.756270357862967],
              [-81.124848321546509, 34.756312167321411],
              [-81.124899823644284, 34.756047247276875],
              [-81.125226165735967, 34.756089055710369],
              [-81.125174664667142, 34.756353975894463],
              [-81.125501008120835, 34.756395783582107],
              [-81.125552508137318, 34.75613086235704],
              [-81.126531536317586, 34.756256279707721],
              [-81.126480039364566, 34.756521200450081],
              [-81.126806384150342, 34.756563004595783],
              [-81.126754888985317, 34.75682792543531],
              [-81.127081235109785, 34.756869727933882],
              [-81.127029739548661, 34.757134648909194],
              [-81.127682434901232, 34.757218253331558],
              [-81.12763094215714, 34.757483174543545],
              [-81.127957291338618, 34.757524974664804],
              [-81.127905798198427, 34.757789896012547],
              [-81.12823214983392, 34.75783169536848],
              [-81.12818065738972, 34.758096616832702],
              [-81.128833362649175, 34.758180214087893],
              [-81.128781871930016, 34.75844513580801],
              [-81.129108226622535, 34.758486932786553],
              [-81.129056737691499, 34.758751854603837],
              [-81.129383092653967, 34.758793650855743],
              [-81.129280114735508, 34.759323493817853],
              [-81.129606473181141, 34.759365289444055],
              [-81.129554985309341, 34.759630211470913],
              [-81.130860425469422, 34.75979738659921],
              [-81.130808941357387, 34.760062308259691],
              [-81.13178802886236, 34.760187680036083],
              [-81.131736547528078, 34.760452602993261],
              [-81.132062911724802, 34.760494391953799],
              [-81.132114392053438, 34.760229469758436],
              [-81.133419848052981, 34.760396616187492],
              [-81.133368371840874, 34.760661538940816],
              [-81.13532657307087, 34.760912231934725],
              [-81.135275103792651, 34.761177155482393],
              [-81.13560147268042, 34.761218934841544],
              [-81.135652942021721, 34.76095401113502],
              [-81.136958417830115, 34.761121117336423],
              [-81.136906953697618, 34.761386041581503],
              [-81.137559696203311, 34.761469589638054],
              [-81.137611158301013, 34.761204666015523],
              [-81.137937528466082, 34.761246438135068],
              [-81.137988989178353, 34.760981513448584],
              [-81.14027358579547, 34.761273895159675],
              [-81.140325037878569, 34.761008969493396],
              [-81.141304153391957, 34.76113426196806],
              [-81.141355602054858, 34.760869335860278],
              [-81.142008344979601, 34.760952859776971],
              [-81.142059791251398, 34.760687933367343],
              [-81.143365275837709, 34.760854968233218],
              [-81.14341671765996, 34.76059004124285],
              [-81.14374308942827, 34.760631797591337],
              [-81.143691648635198, 34.760896724721121],
              [-81.146629014414245, 34.761272493340016],
              [-81.146577583643506, 34.761537421681602],
              [-81.146903960796806, 34.761579169453221],
              [-81.146852530722668, 34.761844097911045],
              [-81.146526152540048, 34.761802350000039],
              [-81.146423288243255, 34.762332206587082],
              [-81.146749668460643, 34.762373953875432],
              [-81.146595373130836, 34.763168738089334],
              [-81.146921756792281, 34.76321048581147],
              [-81.146870325727946, 34.763475413368482],
              [-81.147196710751402, 34.763517160344385],
              [-81.14709384970692, 34.76404701656876],
              [-81.14742023709789, 34.764088762036543],
              [-81.147368807117928, 34.764353690703921],
              [-81.147695195870838, 34.764395435425442],
              [-81.147643766563831, 34.764660363307712],
              [-81.147970157794475, 34.764702108164705],
              [-81.14776444024146, 34.765761819137388],
              [-81.148090835922162, 34.765803563666132],
              [-81.147987976650526, 34.766333418850998],
              [-81.148314374698955, 34.76637516187148],
              [-81.14826294615149, 34.766640090009339],
              [-81.148589345561817, 34.766681832283517],
              [-81.148435060964445, 34.767476615172924],
              [-81.148761463819213, 34.767518357880839],
              [-81.148710035976308, 34.767783285303018],
              [-81.148383632092077, 34.767741542455767],
              [-81.148229342387097, 34.768536324184232],
              [-81.147902935771015, 34.76849458093465],
              [-81.147697207729792, 34.769554288114669],
              [-81.148023618463569, 34.769596031921722],
              [-81.147972186930005, 34.76986095901826],
              [-81.14829860011811, 34.769902702059369],
              [-81.148247169257701, 34.770167628370736],
              [-81.148573582739445, 34.770209371586276],
              [-81.148522152575723, 34.770474298013596],
              [-81.148848568488191, 34.770516039562033],
              [-81.148797139021369, 34.770780966105377],
              [-81.149123556319523, 34.770822707808769],
              [-81.149072127549502, 34.77108763446806],
              [-81.149398546186148, 34.771129374523703],
              [-81.149347118112971, 34.771394301299011],
              [-81.149673538111841, 34.771436040608343],
              [-81.14957068302644, 34.77196589436759],
              [-81.149897105416983, 34.772007633069848],
              [-81.149794251036326, 34.772537486113102],
              [-81.150120675842217, 34.77257922510951],
              [-81.150017822189994, 34.773109078337932],
              [-81.150344250456129, 34.773150815806432],
              [-81.150241397508822, 34.773680668318718],
              [-81.150567827074511, 34.773722405199614],
              [-81.150516402247391, 34.773987332441706],
              [-81.150842833175219, 34.774029068576219],
              [-81.15073998235026, 34.774558921505346],
              [-81.151066416762163, 34.774600657013217],
              [-81.151014991880288, 34.774865583581978],
              [-81.151341427654444, 34.774907318343388],
              [-81.15129000453814, 34.775172244107225],
              [-81.151942879143562, 34.775255711251283],
              [-81.151891456685306, 34.775520638191118],
              [-81.152217895516586, 34.775562370573816],
              [-81.15216647373154, 34.775827296728245],
              [-81.153145796378695, 34.775952488058586],
              [-81.153094377373506, 34.776217415508818],
              [-81.154400150390629, 34.776384324824889],
              [-81.154348735171524, 34.776649252808745],
              [-81.155001625734869, 34.776732702430586],
              [-81.15495021331111, 34.776997629748706],
              [-81.156256002547536, 34.777164518919776],
              [-81.156204593909948, 34.777429446771571],
              [-81.156857492582887, 34.777512886320778],
              [-81.156754679521043, 34.778042742491259],
              [-81.157081131950022, 34.778084460755231],
              [-81.157029725403859, 34.778349388954339],
              [-81.157682632250356, 34.778432824024108],
              [-81.157631228523456, 34.778697752458655],
              [-81.157957684009489, 34.778739468343637],
              [-81.157906279887442, 34.779004396913535],
              [-81.158559193916659, 34.779087826304391],
              [-81.158507792613946, 34.779352755109727],
              [-81.159487169240862, 34.779477892969894],
              [-81.159435770671152, 34.779742821268066],
              [-81.160088692144441, 34.779826242357402],
              [-81.160037295325552, 34.780091171811897],
              [-81.161996076064867, 34.780341413714083],
              [-81.162047466705076, 34.780076483424345],
              [-81.16270039459026, 34.780159890320327],
              [-81.162751782838328, 34.779894959728836],
              [-81.163404709993742, 34.7799783628029],
              [-81.163301938352987, 34.780508223598439],
              [-81.163628403918935, 34.780549923193576],
              [-81.163577018095452, 34.780814854155793],
              [-81.163903486116013, 34.780856552984602],
              [-81.163852100966125, 34.781121483161137],
              [-81.164178570372741, 34.781163182144596],
              [-81.164075802227785, 34.781693042685845],
              [-81.164402274026529, 34.781734741061783],
              [-81.164350889939101, 34.78199967144613],
              [-81.165003836569809, 34.782083064917323],
              [-81.164952454209683, 34.782347995556577],
              [-81.165931880818832, 34.782473079066257],
              [-81.165880501215952, 34.782738010099408],
              [-81.167186411762316, 34.782904775972561],
              [-81.167237786153208, 34.782639844402389],
              [-81.167564263311732, 34.782681533296362],
              [-81.167512889950743, 34.782946465005608],
              [-81.167839368471618, 34.782988153152807],
              [-81.167787995808098, 34.783253084977652],
              [-81.168114476783643, 34.783294772358396],
              [-81.168063104793916, 34.783559703397572],
              [-81.16838958603924, 34.783601390051217],
              [-81.168286843146404, 34.784131253238456],
              [-81.168613327876443, 34.784172939264664],
              [-81.168561956973335, 34.784437871412806],
              [-81.168235471213436, 34.784396185247374],
              [-81.168081353348725, 34.785190978428922],
              [-81.168407842198604, 34.785232665011705],
              [-81.168356469942324, 34.785497596164234],
              [-81.169009450698894, 34.785580966950285],
              [-81.168958080170242, 34.785845898357422],
              [-81.169611064316172, 34.785929265877996],
              [-81.169559695515247, 34.786194197539778],
              [-81.169886189116724, 34.786235880110176],
              [-81.169629346321202, 34.7875605369385],
              [-81.169302847569526, 34.787518853672424],
              [-81.169251476750546, 34.787783784291314],
              [-81.169577977624613, 34.787825467676733],
              [-81.169526607503329, 34.788090398411022],
              [-81.169853108647445, 34.788132081069364],
              [-81.169750370560308, 34.788661942725632],
              [-81.170076874096793, 34.788703624776133],
              [-81.170025506131054, 34.788968555698098],
              [-81.170352011029948, 34.789010237001698],
              [-81.170300643738059, 34.78927516713776],
              [-81.17062714999939, 34.789316847694558],
              [-81.170575783429129, 34.789581778847335],
              [-81.170902291052982, 34.789623458657239],
              [-81.170850925156486, 34.789888389024163],
              [-81.172156967417592, 34.790055098078525],
              [-81.172105604240727, 34.790320029899235],
              [-81.172758629425431, 34.790403379388522],
              [-81.172707269044992, 34.790668310542664],
              [-81.173360297619169, 34.790751656765977],
              [-81.173308938990658, 34.791016589075873],
              [-81.173635454794422, 34.791058260546968],
              [-81.173584096839932, 34.791323192070962],
              [-81.174563650451645, 34.79144820246664],
              [-81.174615005315658, 34.791183270525423],
              [-81.174941523540937, 34.791224938432642],
              [-81.174890169707098, 34.791489870512926],
              [-81.176849288963723, 34.791739861143832],
              [-81.176900635523978, 34.791474928248959],
              [-81.178533239416069, 34.791683227952134],
              [-81.178481898006694, 34.791948161542201],
              [-81.178808420156457, 34.791989818975608],
              [-81.178757079445205, 34.792254752681004],
              [-81.179083604049794, 34.792296409347507],
              [-81.17913494373083, 34.792031475503073],
              [-81.179787990759664, 34.792114785018434],
              [-81.179736653139074, 34.792379719140961],
              [-81.182348866107688, 34.792712924614762],
              [-81.182400194393836, 34.792447989400038],
              [-81.183379777026033, 34.792572925281426],
              [-81.183431102981856, 34.792307989606094],
              [-81.184084156585428, 34.792391275805535],
              [-81.184135480148754, 34.792126339828641],
              [-81.184788532995967, 34.792209621304487],
              [-81.184839854166626, 34.791944685025925],
              [-81.18581943340746, 34.792069601068597],
              [-81.185870750062861, 34.791804664369209],
              [-81.186850330293623, 34.791929572000384],
              [-81.186901642409637, 34.79166463397906],
              [-81.187881222537769, 34.791789533218513],
              [-81.187932531230899, 34.791524594756709],
              [-81.191197809519565, 34.791940863565628],
              [-81.191249107601934, 34.791675924592028],
              [-81.19190216518227, 34.791759167442898],
              [-81.191850869160405, 34.792024106694278],
              [-81.192503930128865, 34.792107346278506],
              [-81.192452635835707, 34.792372285784019],
              [-81.193758766945081, 34.792538753953629],
              [-81.193707476441247, 34.792803693991175],
              [-81.197952455499461, 34.793344615095897],
              [-81.198003731516167, 34.793079673272992],
              [-81.198656808223305, 34.793162878541288],
              [-81.198605534267458, 34.793427820641966],
              [-81.198932074683228, 34.793469421625083],
              [-81.198983346516243, 34.793204479405524],
              [-81.19930988623338, 34.793246079363733],
              [-81.199258614338206, 34.793511021742113],
              [-81.201217863605024, 34.793760603755167],
              [-81.201269128225263, 34.793495660563828],
              [-81.201922212635424, 34.79357884718803],
              [-81.201870950076113, 34.793843790657],
              [-81.202197493809166, 34.793885382778718],
              [-81.202248755338147, 34.79362043917093],
              [-81.202575297279935, 34.793662030287607],
              [-81.20262655744672, 34.793397086517309],
              [-81.20360618433179, 34.793521853192892],
              [-81.203657441075762, 34.793256908982464],
              [-81.204310526007717, 34.793340082645337],
              [-81.204361779242404, 34.793075137252394],
              [-81.204688321175411, 34.793116722615736],
              [-81.205014863440084, 34.793158307093016],
              [-81.204963611149537, 34.793423251882281],
              [-81.205943241907818, 34.793547999532102],
              [-81.205891993469422, 34.793812944694075],
              [-81.208830916153488, 34.794187139177062],
              [-81.208779676657741, 34.794452085564188],
              [-81.209432776274056, 34.79453522962217],
              [-81.209381539600301, 34.794800176243179],
              [-81.211014300712421, 34.795008022454766],
              [-81.21096306885957, 34.795272969745781],
              [-81.211289622883754, 34.795314536292075],
              [-81.211238391729907, 34.79557948369807],
              [-81.21189150392631, 34.795662614389343],
              [-81.211840273385207, 34.795927561168],
              [-81.212493388968568, 34.796010688591899],
              [-81.212442161273984, 34.796275636505406],
              [-81.212768721139724, 34.796317199016748],
              [-81.212717494119858, 34.796582146144011],
              [-81.213370615814341, 34.796665268805484],
              [-81.213319390524148, 34.796930216186496],
              [-81.213645953445464, 34.796971776316589],
              [-81.21359472885436, 34.797236723812567],
              [-81.214247857752639, 34.79731984169149],
              [-81.214196634891167, 34.797584789441188],
              [-81.214849767152117, 34.797667903151215],
              [-81.214798546020475, 34.797932851154592],
              [-81.217084527692904, 34.798223722624464],
              [-81.217033314536792, 34.798488671554786],
              [-81.217359885533412, 34.798530220703469],
              [-81.217308673076545, 34.798795169748743],
              [-81.217635245459704, 34.798836719051025],
              [-81.217584033677582, 34.799101667310026],
              [-81.217910607422695, 34.799143215864724],
              [-81.217859396339946, 34.799408164238649],
              [-81.218185972539729, 34.799449712025606],
              [-81.218134761063638, 34.799714660534597],
              [-81.218461338601088, 34.799756206672654],
              [-81.218410128941315, 34.800021156177628],
              [-81.218736707840819, 34.800062701568159],
              [-81.218685497787746, 34.800327651208235],
              [-81.219012078049332, 34.800369195851033],
              [-81.218960869763762, 34.800634144684494],
              [-81.219940615653442, 34.800758774612611],
              [-81.219889410128559, 34.801023723838334],
              [-81.221195746710805, 34.801189884020488],
              [-81.221246947020163, 34.800924934260429],
              [-81.222226699843617, 34.801049544675038],
              [-81.222277897822067, 34.800784594455088],
              [-81.22293106594168, 34.800867662454827],
              [-81.222982260434719, 34.800602711954085],
              [-81.223635427842495, 34.800685777032598],
              [-81.223686621010927, 34.800420825309445],
              [-81.224666371514971, 34.800545415864129],
              [-81.224717560167278, 34.800280463721535],
              [-81.225697310560491, 34.800405045883657],
              [-81.225646124976009, 34.800669997540766],
              [-81.229238581989136, 34.801126727533564],
              [-81.22928975511806, 34.800861773471063],
              [-81.229616342888065, 34.800903289336333],
              [-81.229565170765483, 34.801168242636109],
              [-81.230544940198286, 34.801292783506931],
              [-81.230493770836958, 34.801557737198536],
              [-81.230820361968583, 34.801599248894384],
              [-81.230769193306926, 34.801864202700784],
              [-81.231095786893135, 34.801905713628599],
              [-81.231044618931165, 34.802170667549788],
              [-81.231697808090658, 34.802253687944862],
              [-81.231646641859129, 34.802518642119445],
              [-81.231973238500402, 34.802560150665194],
              [-81.23192207294413, 34.802825104053241],
              [-81.232575268213722, 34.802908119684417],
              [-81.232524105480593, 34.803173073305402],
              [-81.236116719232527, 34.803629591475961],
              [-81.236167870601264, 34.803364635430107],
              [-81.237474281766964, 34.803530613410196],
              [-81.237525428705709, 34.803265657687867],
              [-81.238505237450426, 34.803390130999915],
              [-81.238454094721632, 34.803655088018623],
              [-81.238780699321268, 34.803696577187964],
              [-81.238729556199743, 34.803961534341674],
              [-81.239382769570923, 34.804044511179043],
              [-81.239331628155725, 34.804309467684639],
              [-81.239984844912755, 34.804392441253292],
              [-81.239933705253051, 34.804657398913299],
              [-81.240586925371147, 34.804740368311982],
              [-81.240535788510414, 34.805005325303561],
              [-81.240862399575235, 34.80504680972323],
              [-81.240811263414514, 34.805311766829384],
              [-81.241137876909164, 34.805353249579497],
              [-81.241086741448584, 34.805618206800297],
              [-81.241739970424746, 34.805701170839633],
              [-81.241688836695118, 34.805966128313592],
              [-81.242342069032304, 34.806049088182881],
              [-81.242290937033758, 34.806314045909801],
              [-81.242944173849338, 34.806397002489888],
              [-81.242893043581901, 34.806661960469924],
              [-81.244526146542015, 34.806869336198417],
              [-81.244475022191565, 34.807134294826284],
              [-81.245454891021453, 34.807258709680866],
              [-81.24540376834068, 34.807523668720492],
              [-81.245730394077682, 34.80756513898416],
              [-81.245679272072294, 34.807830097237137],
              [-81.246005899171323, 34.807871566752738],
              [-81.245954777890887, 34.808136526021556],
              [-81.246281406327029, 34.808177993887909],
              [-81.245923559540387, 34.810032703580632],
              [-81.245596923861768, 34.809991233844237],
              [-81.245545799909735, 34.810256192020773],
              [-81.245872436619493, 34.810297661895511],
              [-81.245821314460457, 34.810562620166174],
              [-81.246147952507513, 34.8106040883915],
              [-81.245994584928184, 34.811398962614582],
              [-81.24632122749243, 34.811440430348291],
              [-81.246116737566396, 34.812500261348653],
              [-81.246443383518212, 34.812541729671082],
              [-81.246341138619812, 34.813071644853686],
              [-81.246667788032951, 34.813113111644697],
              [-81.246361051534578, 34.814702855645201],
              [-81.246034395934132, 34.814661388023872],
              [-81.245625385955691, 34.816781040355238],
              [-81.245952049806306, 34.816822509083671],
              [-81.245849796307326, 34.817352421071227],
              [-81.246176462576216, 34.817393890091097],
              [-81.24612533689573, 34.817658845726363],
              [-81.246452004526873, 34.817700313998039],
              [-81.246400879546798, 34.817965269747617],
              [-81.246727548515437, 34.818006736369831],
              [-81.246625298556992, 34.818536648995199],
              [-81.246951971012081, 34.81857811498719],
              [-81.246900847101941, 34.818843070941369],
              [-81.247227519851194, 34.818884537106719],
              [-81.247176396641493, 34.819149493175189],
              [-81.247503071821129, 34.819190957670621],
              [-81.247451948218924, 34.81945591387381],
              [-81.247778624760954, 34.819497377620998],
              [-81.247676380812379, 34.820027290190893],
              [-81.248003059747987, 34.820068753328258],
              [-81.247900815420948, 34.820598665197622],
              [-81.24822749675026, 34.820640127725092],
              [-81.248125254280112, 34.821170040655474],
              [-81.248451938003186, 34.82121150257305],
              [-81.248349695129747, 34.821741413901563],
              [-81.248676382339482, 34.821782875188717],
              [-81.248369653083699, 34.823372610305761],
              [-81.248042959685108, 34.823331148188487],
              [-81.247991835220063, 34.823596103681737],
              [-81.247665142213947, 34.823554640518928],
              [-81.247614016386478, 34.823819595849727],
              [-81.247287321586811, 34.823778131682339],
              [-81.247236194372164, 34.824043085949221],
              [-81.246909498896798, 34.824001621658141],
              [-81.246756113290189, 34.824796484779476],
              [-81.246429415026483, 34.824755018285273],
              [-81.246173759826604, 34.826079788390906],
              [-81.246500463247813, 34.82612125557695],
              [-81.246398201559856, 34.826651163353489],
              [-81.246724907375238, 34.826692629929596],
              [-81.246673777612841, 34.826957583909618],
              [-81.24700048476592, 34.826999048836122],
              [-81.24689822521826, 34.827528957020625],
              [-81.247224935883182, 34.827570422217988],
              [-81.247122675956987, 34.828100329701279],
              [-81.247449388991313, 34.82814179338731],
              [-81.24739825957576, 34.828406747681569],
              [-81.247724973972637, 34.828448210619221],
              [-81.247673846350594, 34.828713165007159],
              [-81.248000562110164, 34.828754627196432],
              [-81.247898306818698, 34.829284535295145],
              [-81.248225024972399, 34.829325996874466],
              [-81.248173897302991, 34.829590950585256],
              [-81.248500617937054, 34.829632412297045],
              [-81.248398363668471, 34.830162319922444],
              [-81.248725086672039, 34.830203780122893],
              [-81.248622834235888, 34.830733687907284],
              [-81.248949558540716, 34.830775147518004],
              [-81.248847306819314, 34.831305054580461],
              [-81.249174034636269, 34.831346514462041],
              [-81.248969531323525, 34.832406327946842],
              [-81.249296263598325, 34.832447787494971],
              [-81.249245138514681, 34.832712740708025],
              [-81.248918405208258, 34.832671281021554],
              [-81.248662766387739, 34.833996046090853],
              [-81.248336027160519, 34.833954584846396],
              [-81.248029242950665, 34.835544298598528],
              [-81.247702497888724, 34.835502836538424],
              [-81.247497962827396, 34.836562642889085],
              [-81.247171215037682, 34.836521178467116],
              [-81.246966669434443, 34.837580982992137],
              [-81.246639916780197, 34.837539518051607],
              [-81.246588778799406, 34.837804469203576],
              [-81.245935272395741, 34.837721535484128],
              [-81.245986412440189, 34.837456584608866],
              [-81.245659660779182, 34.837415117007914],
              [-81.245710801524353, 34.837150166246523],
              [-81.244730552666994, 34.837025756714702],
              [-81.244679407733315, 34.83729070708138],
              [-81.244352657396632, 34.837249235933022],
              [-81.244301512168434, 34.837514185215198],
              [-81.243974761106458, 34.837472712140389],
              [-81.243821318202663, 34.838267561267948],
              [-81.244148072335449, 34.838309033856611],
              [-81.244096924714214, 34.838573983962355],
              [-81.24442368130309, 34.838615455782175],
              [-81.244372534357836, 34.838880405100511],
              [-81.244699291241176, 34.838921877093497],
              [-81.244596999514187, 34.839451775913012],
              [-81.244923759885495, 34.839493247275485],
              [-81.244872614010617, 34.839758196796964],
              [-81.245199375720205, 34.839799666509691],
              [-81.245097086133853, 34.840329565735424],
              [-81.245423850238311, 34.840371034837972],
              [-81.245168125041175, 34.841695781372032],
              [-81.245494894636252, 34.841737250279422],
              [-81.245392604516908, 34.842267148813391],
              [-81.245065832833248, 34.842225678727928],
              [-81.244503200297984, 34.845140109752982],
              [-81.244829984426502, 34.845181581339951],
              [-81.244165014621984, 34.848625895676776],
              [-81.243838216008513, 34.848584423212785],
              [-81.243787060863298, 34.848849369469526],
              [-81.243460262642245, 34.848807895959908],
              [-81.243306792122766, 34.849602734166623],
              [-81.242979990043509, 34.849561259375314],
              [-81.242928831509062, 34.849826205256392],
              [-81.242602028704169, 34.849784728538388],
              [-81.242550868806447, 34.850049674256276],
              [-81.242224065325331, 34.850008197414262],
              [-81.241763598645804, 34.852392702069629],
              [-81.241436785113592, 34.852351223115555],
              [-81.241232119740815, 34.853411000175058],
              [-81.240905303504562, 34.853369519760172],
              [-81.240802965647873, 34.853899406983182],
              [-81.240476147678578, 34.85385792540449],
              [-81.240424977208761, 34.854122868389723],
              [-81.240098157445303, 34.854081385805948],
              [-81.239995814565134, 34.854611272305903],
              [-81.239668993068634, 34.854569788558322],
              [-81.239566645705295, 34.855099674702394],
              [-81.239239822451083, 34.855058188889778],
              [-81.239137471697831, 34.85558807465749],
              [-81.238810646710562, 34.855546587680983],
              [-81.238708292567324, 34.856076473072328],
              [-81.238381465847027, 34.856034984932073],
              [-81.238125568348664, 34.857359694148222],
              [-81.237798735729754, 34.857318205350531],
              [-81.237542824735229, 34.858642911448698],
              [-81.237215987286419, 34.858601421071825],
              [-81.237164803269735, 34.858866361713631],
              [-81.236837965095532, 34.858824869410007],
              [-81.236684405773033, 34.859619690810902],
              [-81.237011247044123, 34.859661183529688],
              [-81.236908874909517, 34.860191063707454],
              [-81.237235718576727, 34.860232555815955],
              [-81.237133347180986, 34.860762436170511],
              [-81.237460193219661, 34.860803926767439],
              [-81.23740900861587, 34.861068867486118],
              [-81.238062703752263, 34.861151846295492],
              [-81.238011520857171, 34.861416786364657],
              [-81.239645770778353, 34.861624219436592],
              [-81.239543414317382, 34.862154100883544],
              [-81.23987026823059, 34.86219558491684],
              [-81.239767911415257, 34.86272546656074],
              [-81.240094767724585, 34.862766949983637],
              [-81.239941234753331, 34.863561772646271],
              [-81.240268094491341, 34.863603255597049],
              [-81.240165738466715, 34.864133136642671],
              [-81.240492600601002, 34.864174618983071],
              [-81.240441423683123, 34.864439560046996],
              [-81.240768287181282, 34.864481041638683],
              [-81.240665934366675, 34.865010922165638],
              [-81.240992801354665, 34.865052403126448],
              [-81.240941625507432, 34.865317344391912],
              [-81.241268492741142, 34.865358823723071],
              [-81.24121731759486, 34.865623765101724],
              [-81.241544186192556, 34.865665243684042],
              [-81.241871056239759, 34.865706722260136],
              [-81.241819882802361, 34.865971662989111],
              [-81.242146753120068, 34.866013140836728],
              [-81.242095581477244, 34.866278081658585],
              [-81.242422453134068, 34.866319557856094],
              [-81.24237128109894, 34.866584498811513],
              [-81.24269815523779, 34.866625975141098],
              [-81.242544641310303, 34.867420797350185],
              [-81.242217764098484, 34.867379321506938],
              [-81.242115418114395, 34.867909202269942],
              [-81.241788539168908, 34.867867725262798],
              [-81.241737364087996, 34.868132665027829],
              [-81.241410484441388, 34.868091186995038],
              [-81.241256954137242, 34.868886006625303],
              [-81.240603189593628, 34.868803045265516],
              [-81.240552010114072, 34.869067985416002],
              [-81.240225127306786, 34.869026503266873],
              [-81.240173945344964, 34.869291442372969],
              [-81.239847061861369, 34.86924996009953],
              [-81.239795879629042, 34.869514899021716],
              [-81.239468995419671, 34.869473414821414],
              [-81.239417810729648, 34.869738353600376],
              [-81.238110273101583, 34.869572408274855],
              [-81.238059083949864, 34.869837346475101],
              [-81.237405315047383, 34.869754367761644],
              [-81.237354123499017, 34.870019305659838],
              [-81.236046584473556, 34.869853337954289],
              [-81.235995388463408, 34.870118275273747],
              [-81.235014735379735, 34.869993789072424],
              [-81.234963534822086, 34.870258725070514],
              [-81.232021583066555, 34.869885216430283],
              [-81.232072792892453, 34.869620280776751],
              [-81.231745912179008, 34.869578775616979],
              [-81.231797122705686, 34.869313840076749],
              [-81.231143364314207, 34.869230826471231],
              [-81.231194576574239, 34.868965891182654],
              [-81.230867698920775, 34.868924383638173],
              [-81.230918911881716, 34.868659448462836],
              [-81.230592035567852, 34.868617939268347],
              [-81.230643249229516, 34.868353004206256],
              [-81.229008880058586, 34.868145448281766],
              [-81.228957661234546, 34.86841038265166],
              [-81.227977040376558, 34.868285837318496],
              [-81.227925818123424, 34.868550771247769],
              [-81.227598943762416, 34.868509253930192],
              [-81.227547720144983, 34.86877418769572],
              [-81.227220845107894, 34.868732670253735],
              [-81.227169620126261, 34.868997603855625],
              [-81.226842744363822, 34.868956084486811],
              [-81.226791516924507, 34.869221017945137],
              [-81.226464641554898, 34.869179497530425],
              [-81.226362183615947, 34.869709364094497],
              [-81.226035305419614, 34.869667842535776],
              [-81.225932845182129, 34.870197708701745],
              [-81.225605965252612, 34.87015618597885],
              [-81.225503500504914, 34.870686050885702],
              [-81.225176618842198, 34.870644526998717],
              [-81.225022916122384, 34.871439323303314],
              [-81.224696031693966, 34.871397798113627],
              [-81.224593558404621, 34.871927662233944],
              [-81.224266672242905, 34.87188613587999],
              [-81.224112957232563, 34.872680930093253],
              [-81.223786068305174, 34.872639402436633],
              [-81.22352986440778, 34.873964056464068],
              [-81.223202969580399, 34.873922528149087],
              [-81.223151727195528, 34.874187458191265],
              [-81.222824831642953, 34.874145927949264],
              [-81.222671097236415, 34.874940718449679],
              [-81.222344198917995, 34.87489918690499],
              [-81.221985466036443, 34.87675369411869],
              [-81.221658560821709, 34.876712160717055],
              [-81.22155606001067, 34.877242018525678],
              [-81.221229153062609, 34.877200483959733],
              [-81.221126648858629, 34.877730341389629],
              [-81.220799740177242, 34.877688805659382],
              [-81.220645977177639, 34.878483590767381],
              [-81.220319065730266, 34.878442053734268],
              [-81.220216552957808, 34.878971909474572],
              [-81.21988963977708, 34.878930371277107],
              [-81.219838381313394, 34.879195298980541],
              [-81.219184552788917, 34.879112219666965],
              [-81.219082031828449, 34.879642074423195],
              [-81.219408948536881, 34.879683614790672],
              [-81.2193064282893, 34.880213468820862],
              [-81.218979509515492, 34.880171928176338],
              [-81.218825724114993, 34.880966709055322],
              [-81.218498802575184, 34.880925167107847],
              [-81.218396274852097, 34.881455019427634],
              [-81.218069350485123, 34.881413476335929],
              [-81.218018085639685, 34.881678402308836],
              [-81.217691160571945, 34.881636858191357],
              [-81.217588626750199, 34.882166710705029],
              [-81.217261701042858, 34.882125165402897],
              [-81.21721043204208, 34.882390091042353],
              [-81.216883504540277, 34.882348544734583],
              [-81.21683223415026, 34.882613469308644],
              [-81.216505307041629, 34.882571921954799],
              [-81.216454035311202, 34.882836847265949],
              [-81.216127107501904, 34.882795298886279],
              [-81.216075834406567, 34.883060224033343],
              [-81.215748904802922, 34.883018674647907],
              [-81.215697630342802, 34.883283599630829],
              [-81.215043771135683, 34.883200496999414],
              [-81.214992493184226, 34.883465421699825],
              [-81.214665563605195, 34.883423869355106],
              [-81.214614285358167, 34.88368879296997],
              [-81.214287353984787, 34.883647239619464],
              [-81.214236073303454, 34.883912163991582],
              [-81.213909142323217, 34.883870609595114],
              [-81.213857860252659, 34.88413553290173],
              [-81.213530928571814, 34.884093977479282],
              [-81.21347964513626, 34.884358900621791],
              [-81.213152711661095, 34.884317344193605],
              [-81.213101427954442, 34.884582267151764],
              [-81.212120626399411, 34.884457591225996],
              [-81.212171914298082, 34.884192668663459],
              [-81.211191119924862, 34.884067985147496],
              [-81.211242410589634, 34.88380306297514],
              [-81.210261623373952, 34.883678370967687],
              [-81.210312916804568, 34.883413449185582],
              [-81.208024431445949, 34.883122471696787],
              [-81.207973129692689, 34.88338739252859],
              [-81.207319278599087, 34.883304247951706],
              [-81.207267974448044, 34.883569168480648],
              [-81.20694104837915, 34.883527595173248],
              [-81.206838437014866, 34.884057435877061],
              [-81.206511509188672, 34.88401586050378],
              [-81.206357586576075, 34.884810621852651],
              [-81.206030654890142, 34.884769045196151],
              [-81.205979345647151, 34.885033965155472],
              [-81.205652414354688, 34.884992387453082],
              [-81.205549792806224, 34.885522227017454],
              [-81.205222858686909, 34.885480648170358],
              [-81.205120233743457, 34.88601048735493],
              [-81.20479329789103, 34.885968907343198],
              [-81.204690668434637, 34.886498745266572],
              [-81.204363730848996, 34.886457164090189],
              [-81.204312415148522, 34.886722083315277],
              [-81.203985476862599, 34.886680501112984],
              [-81.203882841280873, 34.887210338327591],
              [-81.203555901261851, 34.887168754960541],
              [-81.203453262260595, 34.887698590893869],
              [-81.20312632050846, 34.887657006362119],
              [-81.202921033292057, 34.888716677385624],
              [-81.203247980269126, 34.888758262452072],
              [-81.20319665839331, 34.889023180287332],
              [-81.20581225110935, 34.889355828239999],
              [-81.205760938257484, 34.8896207471388],
              [-81.206087890267554, 34.889662323594855],
              [-81.20603657811607, 34.889927242606547],
              [-81.206363530397525, 34.889968818333898],
              [-81.206312218946593, 34.890233737458487],
              [-81.206639173687122, 34.890275312417117],
              [-81.206587862936672, 34.890540231654633],
              [-81.206914819042424, 34.890581805864436],
              [-81.206812198585752, 34.891111643638176],
              [-81.207139157089614, 34.891153217237857],
              [-81.207036537369333, 34.891683055185844],
              [-81.207363499365186, 34.891724628155309],
              [-81.206953015306112, 34.893843974617042],
              [-81.206626045046846, 34.893802400538441],
              [-81.206472104886743, 34.89459715259202],
              [-81.206799078269299, 34.894638727987697],
              [-81.206337246923539, 34.897022983138854],
              [-81.206664229911084, 34.897064557993566],
              [-81.206561599599794, 34.897594391754268],
              [-81.206888586079756, 34.897635965978694],
              [-81.20683727035248, 34.897900882528184],
              [-81.207164258197906, 34.897942456003769],
              [-81.20706163002427, 34.898472290163625],
              [-81.207388620268247, 34.898513863028839],
              [-81.207337305609826, 34.898778779777913],
              [-81.207664297219296, 34.898820351894358],
              [-81.207612984355464, 34.899085268736073],
              [-81.207939977330426, 34.899126840103555],
              [-81.207888665167175, 34.899391757057963],
              [-81.208215659507658, 34.89943332767654],
              [-81.208164348045173, 34.899698244743604],
              [-81.208491343751163, 34.899739814613362],
              [-81.208388721870875, 34.90026964804553],
              [-81.208715721069467, 34.900311217284937],
              [-81.208664410675908, 34.900576134551379],
              [-81.209318409924293, 34.900659269783553],
              [-81.209267102358652, 34.90092418728117],
              [-81.211229116516577, 34.90117357427102],
              [-81.210972602411999, 34.902498161962086],
              [-81.210645593322155, 34.902456598852844],
              [-81.210542983058858, 34.902986433113711],
              [-81.210215972234991, 34.902944868839768],
              [-81.21016466502455, 34.903209785802652],
              [-81.209837653499889, 34.903168220502558],
              [-81.209735037109311, 34.903698054053017],
              [-81.20940802275662, 34.903656487608224],
              [-81.209254092006688, 34.904451236431633],
              [-81.208927075980753, 34.904409668663426],
              [-81.208619195950718, 34.905999162131792],
              [-81.208946219270274, 34.906040730711496],
              [-81.208843592357098, 34.906570561629081],
              [-81.209170616957181, 34.906612128717164],
              [-81.209119304035241, 34.906877044275433],
              [-81.209446331119366, 34.906918611495712],
              [-81.20939501887392, 34.907183526265143],
              [-81.210049076106273, 34.907266658319941],
              [-81.209997765594991, 34.907531573340542],
              [-81.210324795743148, 34.907573138175103],
              [-81.210119555625326, 34.908632799431736],
              [-81.209792520274164, 34.908591234964234],
              [-81.209741208100681, 34.908856149854131],
              [-81.208760104175369, 34.90873144884722],
              [-81.208708788569311, 34.908996363295302],
              [-81.208381752481301, 34.908954795000085],
              [-81.208330435509524, 34.909219709283363],
              [-81.207676364428053, 34.909136568811398],
              [-81.20762504396302, 34.909401482811475],
              [-81.206970972120544, 34.909318337610515],
              [-81.206919649256307, 34.909583251307126],
              [-81.20659261280052, 34.909541677236625],
              [-81.206541288570321, 34.909806590768483],
              [-81.206214251438112, 34.909765016573012],
              [-81.206111600983306, 34.910294842359725],
              [-81.205784560998438, 34.910253266118161],
              [-81.205579250895127, 34.911312917744347],
              [-81.205906294989163, 34.911354493639195],
              [-81.205752311168965, 34.912149231387993],
              [-81.206079358720061, 34.912190807712278],
              [-81.205976704321898, 34.912720632403378],
              [-81.206303754272284, 34.912762208117194],
              [-81.206252427060718, 34.913027120572096],
              [-81.20657947835285, 34.913068694635491],
              [-81.206476826093038, 34.913598519723685],
              [-81.206803879808831, 34.913640094077991],
              [-81.206752553642133, 34.913905005829996],
              [-81.207079609818166, 34.913946579415139],
              [-81.206976958578252, 34.914476404018941],
              [-81.207304017153774, 34.914517976993601],
              [-81.207252692056329, 34.914782888944096],
              [-81.207579751997869, 34.914824461169651],
              [-81.20752842760136, 34.91508937323249],
              [-81.208182549430575, 34.915172514416362],
              [-81.208131227862552, 34.915437426710028],
              [-81.208458290844376, 34.915478995648307],
              [-81.20840696890761, 34.9157439089756],
              [-81.210696425280034, 34.916034869480399],
              [-81.210747738888003, 34.915769955203139],
              [-81.212383070446421, 34.915977756409923],
              [-81.212331761981773, 34.916242670478645],
              [-81.212985898989729, 34.916325784663833],
              [-81.212934592259657, 34.916590698983384],
              [-81.213261663390128, 34.916632254862826],
              [-81.213210357361177, 34.916897169294636],
              [-81.213537428763374, 34.916938724444911],
              [-81.213486124529837, 34.917203638968857],
              [-81.213813197298066, 34.917245193369979],
              [-81.213761892647085, 34.917510107124976],
              [-81.214088967875412, 34.917551660756786],
              [-81.214037663950009, 34.917816575525265],
              [-81.214364740544468, 34.917858128407772],
              [-81.214313437320214, 34.918123043288496],
              [-81.215294671080272, 34.918247696143986],
              [-81.215243371694228, 34.918512610492549],
              [-81.2155704505914, 34.918554160121133],
              [-81.215519151906506, 34.918819074581883],
              [-81.216500395857366, 34.918943717615193],
              [-81.216449099965416, 34.919208633366409],
              [-81.217757435463781, 34.91937481157489],
              [-81.217706143349744, 34.919639726952411],
              [-81.218360315160396, 34.91972281100692],
              [-81.218309024781348, 34.919987726635163],
              [-81.218963199988849, 34.920070807415371],
              [-81.218911912438969, 34.920335723274093],
              [-81.219239001562698, 34.920377262020367],
              [-81.219187713620045, 34.920642178011285],
              [-81.21984189605061, 34.920725253998505],
              [-81.21979060984296, 34.920990170240088],
              [-81.220444795645818, 34.921073242051634],
              [-81.220393512267606, 34.921338158523547],
              [-81.220720606154146, 34.921379693246259],
              [-81.220669323477338, 34.921644609830246],
              [-81.220996418729825, 34.921686143803527],
              [-81.220945136754466, 34.921951060499687],
              [-81.221272234467349, 34.921992593703344],
              [-81.221220952074702, 34.922257509630462],
              [-81.221548051153661, 34.92229904208483],
              [-81.221496770581126, 34.922563959005132],
              [-81.222150970709208, 34.922647021547313],
              [-81.222099691847532, 34.922911937816856],
              [-81.22242679399092, 34.922953467884582],
              [-81.222375515855134, 34.923218385167331],
              [-81.223029723181881, 34.923301442014868],
              [-81.222978446781497, 34.923566359548133],
              [-81.223305551989355, 34.923607887229252],
              [-81.223254276266005, 34.923872803973296],
              [-81.223581382839868, 34.923914330904871],
              [-81.22353010781805, 34.924179247760939],
              [-81.223857215733574, 34.924220773041803],
              [-81.223805942507596, 34.924485689989687],
              [-81.224133051813652, 34.924527215422458],
              [-81.224081778194972, 34.924792132502539],
              [-81.224736000966018, 34.924875180960989],
              [-81.224684729058239, 34.925140097390219],
              [-81.225011841428881, 34.925181620436113],
              [-81.224960571341427, 34.925446537858399],
              [-81.225614800216917, 34.925529580642049],
              [-81.225563530770785, 34.925794498334916],
              [-81.226217764137203, 34.925877537823531],
              [-81.226166496402044, 34.926142454865499],
              [-81.226820733165283, 34.926225491079322],
              [-81.226769467190238, 34.926490409272901],
              [-81.227423707350084, 34.92657344221201],
              [-81.227372444180389, 34.926838359734333],
              [-81.227699565780313, 34.926879874559773],
              [-81.2276483022425, 34.927144793115616],
              [-81.227975425208555, 34.927186307191519],
              [-81.22792416344231, 34.927451224937769],
              [-81.228251287799068, 34.927492739165338],
              [-81.228148765337764, 34.928022574855142],
              [-81.228475892070051, 34.928064087570398],
              [-81.228270847296926, 34.929123759185408],
              [-81.228597978497319, 34.929165271566255],
              [-81.228495456160871, 34.929695106590124],
              [-81.228168321798165, 34.92965359395285],
              [-81.228117059109948, 34.929918511737277],
              [-81.227789924020897, 34.929876997172286],
              [-81.227687395555137, 34.930406831483488],
              [-81.227033122286912, 34.930323800938716],
              [-81.226981855475344, 34.930588717327026],
              [-81.226654718305539, 34.930547200584357],
              [-81.226603450127797, 34.930812116807729],
              [-81.226276312256331, 34.930770599038631],
              [-81.226225041617852, 34.931035515117308],
              [-81.225897904138918, 34.930993996301609],
              [-81.22584663213425, 34.93125891221532],
              [-81.225519492834664, 34.931217391492211],
              [-81.225468219463679, 34.93148230724092],
              [-81.225141079486917, 34.931440786392706],
              [-81.225089805843893, 34.93170570195629],
              [-81.224762665165485, 34.931664180081711],
              [-81.224660112601285, 34.932194009991221],
              [-81.224332970187035, 34.932152486951836],
              [-81.22428169238475, 34.932417402179048],
              [-81.223954549244255, 34.932375877212003],
              [-81.223800710716645, 34.933170621417133],
              [-81.224127856959271, 34.933212146799399],
              [-81.224025298339086, 34.93374197611287],
              [-81.224352446957639, 34.933783499982731],
              [-81.224301168170896, 34.934048414287304],
              [-81.2246283192748, 34.934089938288572],
              [-81.224577041189718, 34.934354852704899],
              [-81.22523134531923, 34.934437896538093],
              [-81.225180068994618, 34.93470281210579],
              [-81.225507222044655, 34.934744332838889],
              [-81.225455947491724, 34.93500924759693],
              [-81.225783101908263, 34.935050767580442],
              [-81.225731828057135, 34.935315682450224],
              [-81.226058983840119, 34.935357201684035],
              [-81.226007710690695, 34.935622116665542],
              [-81.226334867840123, 34.935663635149815],
              [-81.226181047311727, 34.936458380389915],
              [-81.226508208990325, 34.936499898381037],
              [-81.226456936555493, 34.936764812631374],
              [-81.226784099600565, 34.936806329872923],
              [-81.22663028126486, 34.93760107472179],
              [-81.226957447744667, 34.937642591490444],
              [-81.226854902926505, 34.938172420324541],
              [-81.227182071807107, 34.938213936481922],
              [-81.227079527727994, 34.938743765485881],
              [-81.227406700103785, 34.938785281011704],
              [-81.227355428052704, 34.939050195622066],
              [-81.227682601795053, 34.939091710398237],
              [-81.227631330445959, 34.939356625120254],
              [-81.227958505554838, 34.939398139146746],
              [-81.227907234907619, 34.939663053980304],
              [-81.228234411358471, 34.93970456635585],
              [-81.22818314250776, 34.939969481280883],
              [-81.228510320349741, 34.940010993807945],
              [-81.228459051106398, 34.940275908864756],
              [-81.229113409831186, 34.940358930630119],
              [-81.229062143418702, 34.940623845916718],
              [-81.229389324301508, 34.940665355154991],
              [-81.229338058591011, 34.940930270553196],
              [-81.229992423446518, 34.941013287543335],
              [-81.229941159447733, 34.941278202290214],
              [-81.230268343420548, 34.941319710042094],
              [-81.230165816519275, 34.941849540633449],
              [-81.230493002868386, 34.941891046872712],
              [-81.23023668381613, 34.943215621340926],
              [-81.230563876788295, 34.943257128264818],
              [-81.230307554551629, 34.944581700951794],
              [-81.230634753003343, 34.944623206778012],
              [-81.230480959414123, 34.945417949749817],
              [-81.230808161325925, 34.945459456004158],
              [-81.23075689715786, 34.945724370112316],
              [-81.231411303997845, 34.945807378430601],
              [-81.231360041566333, 34.946072292788607],
              [-81.232341659635452, 34.946196799880454],
              [-81.232290399974971, 34.946461714626594],
              [-81.232944815394589, 34.946544715497481],
              [-81.232893557470859, 34.946809630493291],
              [-81.233547976263765, 34.94689262718682],
              [-81.233496721171193, 34.947157542412057],
              [-81.234478355503128, 34.947282031195797],
              [-81.23442710206254, 34.94754694592811],
              [-81.235735958086948, 34.947712919064507],
              [-81.235787206294987, 34.947448003799309],
              [-81.237750494065793, 34.947696935126828],
              [-81.2376992520648, 34.94796185122172],
              [-81.238026468894404, 34.948003336956063],
              [-81.237975227620453, 34.948268254063599],
              [-81.238302445792158, 34.948309738146676],
              [-81.238148724126162, 34.949104487899703],
              [-81.238475945758324, 34.949145972410719],
              [-81.238373464334458, 34.949675806035287],
              [-81.238700689437849, 34.949717289013002],
              [-81.238598208729812, 34.950247121905065],
              [-81.238925435164575, 34.950288605192597],
              [-81.238771715785134, 34.951083353821787],
              [-81.239098946750474, 34.951124836615563],
              [-81.238996466834593, 34.951654668889837],
              [-81.239323700201339, 34.951696151071793],
              [-81.239272460767495, 34.951961066856157],
              [-81.239599695501013, 34.952002548288107],
              [-81.239548456769569, 34.95226746418367],
              [-81.239875693964521, 34.952308944845356],
              [-81.239824455935661, 34.952573860852162],
              [-81.240151693377982, 34.952615339882783],
              [-81.240100456076306, 34.952880256902148],
              [-81.240427695980188, 34.952921735162441],
              [-81.240171510633971, 34.95424631601859],
              [-81.240498756068121, 34.954287794983017],
              [-81.240345044391873, 34.955082542677758],
              [-81.240672293237509, 34.955124020267327],
              [-81.240621056025873, 34.955388936556901],
              [-81.240948306238494, 34.955430413396471],
              [-81.240794596784752, 34.956225161595945],
              [-81.241121850433743, 34.956266637961825],
              [-81.241070613938192, 34.956531553518438],
              [-81.240743359254424, 34.956490077014259],
              [-81.240692122486735, 34.956754992385122],
              [-81.240364866005677, 34.956713514874743],
              [-81.240262388309546, 34.95724334527911],
              [-81.239935131185845, 34.957201866583574],
              [-81.239832648996639, 34.957731696623426],
              [-81.239505390135562, 34.957690216763083],
              [-81.239197929337266, 34.95927970359994],
              [-81.238870663504869, 34.95923822204221],
              [-81.238768171836469, 34.95976805019378],
              [-81.239095439738506, 34.959809532028125],
              [-81.238685466860844, 34.961928841069238],
              [-81.239012743373962, 34.961970323121108],
              [-81.2388077525287, 34.963029976650915],
              [-81.239135034608452, 34.963071458347024],
              [-81.239032538702475, 34.963601284784602],
              [-81.23935982318423, 34.963642765868691],
              [-81.239257329089114, 34.964172591552291],
              [-81.239584615973172, 34.96421407202444],
              [-81.239482121524134, 34.964743897895765],
              [-81.239809410810153, 34.964785377756023],
              [-81.239758164669666, 34.965050290778855],
              [-81.240085455323012, 34.965091769888843],
              [-81.240034209885238, 34.965356683022712],
              [-81.240361501905696, 34.965398161382481],
              [-81.240259012078681, 34.965927986943484],
              [-81.24058630650137, 34.965969464691291],
              [-81.240535062136829, 34.966234378019713],
              [-81.240862358996964, 34.96627585409572],
              [-81.240811115335106, 34.966540767535079],
              [-81.241138412492376, 34.966582243782447],
              [-81.241035926217435, 34.967112069954418],
              [-81.241363226847014, 34.967153544668086],
              [-81.241311984258587, 34.967418458301964],
              [-81.24163928625552, 34.967459932265449],
              [-81.241536802127214, 34.967989758826398],
              [-81.241864106526478, 34.968031232177822],
              [-81.241812864986727, 34.968296145104894],
              [-81.242140170777915, 34.9683376186073],
              [-81.242037688771831, 34.968867444655871],
              [-81.242364996940665, 34.968908916644935],
              [-81.242313757569235, 34.969173829745976],
              [-81.242641067130194, 34.969215301886003],
              [-81.242538587246258, 34.969745127422073],
              [-81.242865899209647, 34.969786598949987],
              [-81.242814660886921, 34.970051511344188],
              [-81.24314197421765, 34.970092982121713],
              [-81.243090735502918, 34.970357894647101],
              [-81.24341805129589, 34.970399364654014],
              [-81.243366813284155, 34.970664277290219],
              [-81.243694130444453, 34.970705746546827],
              [-81.243642893135643, 34.970970659293826],
              [-81.243970211638469, 34.971012126898835],
              [-81.243918975057341, 34.971277040657917],
              [-81.244573614035104, 34.97135997349902],
              [-81.244522380286995, 34.971624887486705],
              [-81.244849700761932, 34.971666352722849],
              [-81.244798467691922, 34.971931265920048],
              [-81.245125790629132, 34.971972730385453],
              [-81.245074558262218, 34.972237643693418],
              [-81.245401881471793, 34.972279107428854],
              [-81.245299417811665, 34.972808934238806],
              [-81.24562674451883, 34.972850397341645],
              [-81.245575513225717, 34.973115310843724],
              [-81.245902841300193, 34.973156773196159],
              [-81.245800378692934, 34.973686600414723],
              [-81.246127709170125, 34.97372806215494],
              [-81.24602524837465, 34.974257888618425],
              [-81.246352581254484, 34.974299349746403],
              [-81.246250121200745, 34.974829176376403],
              [-81.24657745648328, 34.974870636892128],
              [-81.246474997146549, 34.97540046278727],
              [-81.246423766979945, 34.975665375693772],
              [-81.24675110679533, 34.975706835714874],
              [-81.246546185853106, 34.976766487638798],
              [-81.246218841896436, 34.976725027064994],
              [-81.246013911472971, 34.977784677094988],
              [-81.245686563707253, 34.977743215079983],
              [-81.245532858990614, 34.978537950777643],
              [-81.245205507356204, 34.97849648747993],
              [-81.24500055820296, 34.979556133851666],
              [-81.245327913978912, 34.979597597701982],
              [-81.245174201140671, 34.980392331038864],
              [-81.245501560354924, 34.980433794415028],
              [-81.245399085920525, 34.980963617372062],
              [-81.245726447513164, 34.981005079234642],
              [-81.245675210285384, 34.981269990820124],
              [-81.246002573270445, 34.981311452833388],
              [-81.245746390535913, 34.982636008272443],
              [-81.24607375900564, 34.982677469186633],
              [-81.245817571997279, 34.984002022843654],
              [-81.245490198350211, 34.983960561238746],
              [-81.245387719344208, 34.984490381326339],
              [-81.245715095087036, 34.984531844108794],
              [-81.245561377727611, 34.985326573986249],
              [-81.245888756909224, 34.985368036294446],
              [-81.245376351489327, 34.988017133418403],
              [-81.245703742429086, 34.988058595297659],
              [-81.24534504511945, 34.98991295838124],
              [-81.245017645835304, 34.989871495555356],
              [-81.244710170977754, 34.99146094541161],
              [-81.244382764692219, 34.991419479987293],
              [-81.24428026795303, 34.991949295212656],
              [-81.243952861048371, 34.991907829504136],
              [-81.243901611156971, 34.992172737387598],
              [-81.243246795128755, 34.992089801245655],
              [-81.243195542833604, 34.992354708824884],
              [-81.24286813483009, 34.992313239272448],
              [-81.242816880071587, 34.992578146706116],
              [-81.242489471364721, 34.992536676126619],
              [-81.242335704128763, 34.993331396002752],
              [-81.242663115942705, 34.993372866996715],
              [-81.242458091687226, 34.99443249247264],
              [-81.242130675730436, 34.99439102092601],
              [-81.242079417252199, 34.994655927097256],
              [-81.241751999496941, 34.994614454544021],
              [-81.241700739650526, 34.994879360549298],
              [-81.241373322311915, 34.994837887849982],
              [-81.241270799525523, 34.99536769859904],
              [-81.240943379327973, 34.995326223853624],
              [-81.240892116412709, 34.995591129498742],
              [-81.240564695511878, 34.995549653726435],
              [-81.240513431228294, 34.995814559205435],
              [-81.240186009624296, 34.995773082406053],
              [-81.240032211622093, 34.996567796458429],
              [-81.239704787267982, 34.996526319257008],
              [-81.239602251516047, 34.997056127608346],
              [-81.23992967796633, 34.99709760598747],
              [-81.239775874951846, 34.997892318719295],
              [-81.240103305937353, 34.997933796603768],
              [-81.239949503012923, 34.998728508597097],
              [-81.240276937438253, 34.998769986007261],
              [-81.240123133508746, 34.999564697282253],
              [-81.240450571374083, 34.999606174218052],
              [-81.240348036568506, 35.000135982157893],
              [-81.240675477908709, 35.000177457559566],
              [-81.240624211055405, 35.000442362076249],
              [-81.240951652668713, 35.000483836747549],
              [-81.240695318469932, 35.001808355095044],
              [-81.241022765619803, 35.001849830469475],
              [-81.240971498818425, 35.002114734192965],
              [-81.240644050632653, 35.002073258680355],
              [-81.240541513960366, 35.002603065766827],
              [-81.240214062939913, 35.002561589109312],
              [-81.240111522841005, 35.003091394905866],
              [-81.239784070105955, 35.003049917984292],
              [-81.239527707751421, 35.00437443086296],
              [-81.23920025014462, 35.004332951460341],
              [-81.238943873151115, 35.005657461163018],
              [-81.238616409626331, 35.005615981102181],
              [-81.238513854185072, 35.006145783612666],
              [-81.238841319806454, 35.00618726485115],
              [-81.238738765106774, 35.006717067525379],
              [-81.239066232012789, 35.006758547270344],
              [-81.238963678054603, 35.007288350108318],
              [-81.239291148485265, 35.00732983012152],
              [-81.239239872578679, 35.007594731175558],
              [-81.240222287829738, 35.007719164513873],
              [-81.24017101469866, 35.007984065954247],
              [-81.240825961577499, 35.008067017651676],
              [-81.240774690185958, 35.008331919340257],
              [-81.24110216569558, 35.008373393532828],
              [-81.241050895007533, 35.008638295331266],
              [-81.241378371885801, 35.008679768773085],
              [-81.241327101901248, 35.008944670681494],
              [-81.241982058752484, 35.009027616075585],
              [-81.241930790482797, 35.009292517330884],
              [-81.242258271551194, 35.009333989262913],
              [-81.242207003984959, 35.00959889062807],
              [-81.242534485301846, 35.009640360928309],
              [-81.242483218464002, 35.009905263304631],
              [-81.242810701149466, 35.009946732853955],
              [-81.242759436110603, 35.010211635319827],
              [-81.243086920164728, 35.010253104118327],
              [-81.243035654709288, 35.010518005813303],
              [-81.243363141227604, 35.010559473840473],
              [-81.243311876500499, 35.010824376546509],
              [-81.24363936438742, 35.010865843822842],
              [-81.243588100339153, 35.01113074573761],
              [-81.243915589594778, 35.011172212262984],
              [-81.243864326274903, 35.011437115188784],
              [-81.244191816874519, 35.011478580062068],
              [-81.24414055425818, 35.011743483097732],
              [-81.24446804622653, 35.0117849472201],
              [-81.244416784313827, 35.012049850365642],
              [-81.244744277650881, 35.012091313737095],
              [-81.244693017537386, 35.012356216972066],
              [-81.245020512243045, 35.012397679592617],
              [-81.245348007306106, 35.012439142225332],
              [-81.245296747812162, 35.012704044827409],
              [-81.246279239175578, 35.012828426883864],
              [-81.246227982457853, 35.013093329871971],
              [-81.249175483778529, 35.013466427472423],
              [-81.249226730074781, 35.013201523261934],
              [-81.250209233382108, 35.013325872410789],
              [-81.250157991290095, 35.013590777015061],
              [-81.250485493720149, 35.013632224797696],
              [-81.25043425233207, 35.013897129511726],
              [-81.252071777961234, 35.014104356618716],
              [-81.252020540326711, 35.01436926201518],
              [-81.254313099358455, 35.014659342290777],
              [-81.254159409459788, 35.015454059345281],
              [-81.254486922487743, 35.015495496239396],
              [-81.254282003202661, 35.016555118813557],
              [-81.253954484958598, 35.01651368228908],
              [-81.253595854173781, 35.018368017293781],
              [-81.253268328982514, 35.018326578012797],
              [-81.253217093650932, 35.018591482890699],
              [-81.252889567755716, 35.018550042582454],
              [-81.252787093997597, 35.019079851075887],
              [-81.252459566361864, 35.019038409602409],
              [-81.252408327960254, 35.019303314119071],
              [-81.252080799620529, 35.019261871618433],
              [-81.252029558729674, 35.019526775087925],
              [-81.251702029685944, 35.01948533155997],
              [-81.251599545954491, 35.020015139018412],
              [-81.251272015170358, 35.019973694325294],
              [-81.251067039522596, 35.0210333065463],
              [-81.251394574452434, 35.021074751791595],
              [-81.251292086681858, 35.021604557106251],
              [-81.251619624017181, 35.021646001738461],
              [-81.251568380134373, 35.021910904951987],
              [-81.252223458970789, 35.021993791804455],
              [-81.252172217899144, 35.022258694343904],
              [-81.252499758304765, 35.022300136584612],
              [-81.252448517962122, 35.022565040134872],
              [-81.252776060807307, 35.022606480702699],
              [-81.252673580077285, 35.023136287113076],
              [-81.253001125352796, 35.023177727968999],
              [-81.252949884965432, 35.023442630829081],
              [-81.252898645341219, 35.023707533640227],
              [-81.253226193022229, 35.023748973883002],
              [-81.253072473073289, 35.024543682600452],
              [-81.253400025292109, 35.024585122347617],
              [-81.253348785315538, 35.024850025341244],
              [-81.253676337807747, 35.024891464357644],
              [-81.253317661323294, 35.026745782674205],
              [-81.252990100504263, 35.026704343613382],
              [-81.252528908993753, 35.029088460180361],
              [-81.252856479142579, 35.029129900483341],
              [-81.252651500847634, 35.030189506348449],
              [-81.252979075475764, 35.030230946314212],
              [-81.252774095957903, 35.031290550470658],
              [-81.253101675090264, 35.031331991000457],
              [-81.252896694374456, 35.032391594349129],
              [-81.253224279057136, 35.032433033619924],
              [-81.253070542591345, 35.03322773625446],
              [-81.253398130716832, 35.033269175049981],
              [-81.253346885234805, 35.033534076015577],
              [-81.253674474729507, 35.033575514059748],
              [-81.253571985912387, 35.034105315259204],
              [-81.253899577813186, 35.03414675269002],
              [-81.253797089739649, 35.034676554050534],
              [-81.254124685142315, 35.034717990847554],
              [-81.254022197812333, 35.035247792369155],
              [-81.254349794525496, 35.035289228573269],
              [-81.254298551933971, 35.035554128968066],
              [-81.254626150016492, 35.035595564420817],
              [-81.25452366590973, 35.036125365400245],
              [-81.254851266398447, 35.036166800239521],
              [-81.254748781939639, 35.036696601400386],
              [-81.25507638593028, 35.036738035605843],
              [-81.255025144786941, 35.037002936270824],
              [-81.255352750147054, 35.037044369724917],
              [-81.255301509708019, 35.037309270499094],
              [-81.255956723499438, 35.037392135015025],
              [-81.255905484776903, 35.037657035135126],
              [-81.256233093208195, 35.037698466196979],
              [-81.25618185521499, 35.037963367327528],
              [-81.256837075124054, 35.038046226157689],
              [-81.256785838872219, 35.038311127535408],
              [-81.257113450374788, 35.038352556204977],
              [-81.257062214802517, 35.038617456790568],
              [-81.257717441974819, 35.038700311716831],
              [-81.257666208143974, 35.038965212549627],
              [-81.258321438695091, 35.039048063292931],
              [-81.258167742550242, 35.039842767326697],
              [-81.258495360874974, 35.03988419133772],
              [-81.258444129921045, 35.040149092448488],
              [-81.258771749640132, 35.040190516609265],
              [-81.258720519390749, 35.040455417829179],
              [-81.259375762971231, 35.040538262836513],
              [-81.25922207423379, 35.041332966290604],
              [-81.259549700194171, 35.041374388314296],
              [-81.259447241804665, 35.041904190441414],
              [-81.259774870146529, 35.041945610950293],
              [-81.259723640954746, 35.042210512569341],
              [-81.260051271761938, 35.042251932306144],
              [-81.260000043274786, 35.042516834034238],
              [-81.260327675451563, 35.042558253019507],
              [-81.260276447668957, 35.042823154856663],
              [-81.260604080119379, 35.042864573110904],
              [-81.260552854112305, 35.043129474135405],
              [-81.260880487957181, 35.043170892539266],
              [-81.260829262654767, 35.043435793672714],
              [-81.261812171436915, 35.043560042117804],
              [-81.261863393602994, 35.043295139669361],
              [-81.263501577082835, 35.043502201317132],
              [-81.263450360077329, 35.043767103553883],
              [-81.263777999021769, 35.043808513168507],
              [-81.26372678274592, 35.044073416415515],
              [-81.264054423059861, 35.044114825278463],
              [-81.264003207463759, 35.044379727733258],
              [-81.264986133511059, 35.044503949398852],
              [-81.26493492066912, 35.044768851337167],
              [-81.265262564386688, 35.044810256917941],
              [-81.265211352274477, 35.045075159866371],
              [-81.265538997361602, 35.045116564695491],
              [-81.265180512647859, 35.046970882744858],
              [-81.265508166423473, 35.047012287629045],
              [-81.265405740647935, 35.047542091782972],
              [-81.265354527261678, 35.047806993816444],
              [-81.26437155871777, 35.047682776135133],
              [-81.26442277519071, 35.047417873614059],
              [-81.263767465842122, 35.047335057968589],
              [-81.263716247269755, 35.047599959312628],
              [-81.26338859314022, 35.047558549546473],
              [-81.263337372102228, 35.047823450744289],
              [-81.263009717267963, 35.047782039950725],
              [-81.262958494860428, 35.048046940981628],
              [-81.262630839321289, 35.048005529160598],
              [-81.262579615544112, 35.04827043002453],
              [-81.262251958204331, 35.048229017196597],
              [-81.262149509745015, 35.048758817619358],
              [-81.26182185066321, 35.048717403626071],
              [-81.261770623814172, 35.048982304127172],
              [-81.261442964027495, 35.048940889106468],
              [-81.261391736904784, 35.049205789420107],
              [-81.259425778918569, 35.048957278886022],
              [-81.259477013360581, 35.048692379379411],
              [-81.257183420270266, 35.048402410121788],
              [-81.257132177446962, 35.048667307782146],
              [-81.25614921100923, 35.048543021354426],
              [-81.256200456919188, 35.048278123206764],
              [-81.255217496561215, 35.04815382828582],
              [-81.25516624753962, 35.048418726019676],
              [-81.254510940596475, 35.048335858309109],
              [-81.254459689168016, 35.048600755737922],
              [-81.254132034597703, 35.048559319969904],
              [-81.254080781799473, 35.048824217231711],
              [-81.253753127620641, 35.048782780415827],
              [-81.253701873452499, 35.049047677510607],
              [-81.253046561826395, 35.048964800975021],
              [-81.252995305251346, 35.049229697764865],
              [-81.252667648900115, 35.049188258024756],
              [-81.252616390930299, 35.049453153746256],
              [-81.252288733899405, 35.04941171387987],
              [-81.252237474559735, 35.049676609434393],
              [-81.25190981679944, 35.049635167639238],
              [-81.251858556090028, 35.049900063026605],
              [-81.251203239517878, 35.049817177393003],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 56,
        ORIG_FID: 9,
        AAPC_Examp: 6,
        Area_mile2: 252,
        BASIN_NAME: "Cedar Creek Reservoir",
        Res_Basin: "CedarCreek",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.201870950076113, 34.793843790657],
              [-81.201922212635424, 34.79357884718803],
              [-81.201269128225263, 34.793495660563828],
              [-81.201217863605024, 34.793760603755167],
              [-81.199258614338206, 34.793511021742113],
              [-81.19930988623338, 34.793246079363733],
              [-81.198983346516243, 34.793204479405524],
              [-81.198932074683228, 34.793469421625083],
              [-81.198605534267458, 34.793427820641966],
              [-81.198656808223305, 34.793162878541288],
              [-81.198003731516167, 34.793079673272992],
              [-81.197952455499461, 34.793344615095897],
              [-81.193707476441247, 34.792803693991175],
              [-81.193758766945081, 34.792538753953629],
              [-81.192452635835707, 34.792372285784019],
              [-81.192503930128865, 34.792107346278506],
              [-81.191850869160405, 34.792024106694278],
              [-81.19190216518227, 34.791759167442898],
              [-81.191249107601934, 34.791675924592028],
              [-81.191197809519565, 34.791940863565628],
              [-81.187932531230899, 34.791524594756709],
              [-81.187881222537769, 34.791789533218513],
              [-81.186901642409637, 34.79166463397906],
              [-81.186850330293623, 34.791929572000384],
              [-81.185870750062861, 34.791804664369209],
              [-81.18581943340746, 34.792069601068597],
              [-81.184839854166626, 34.791944685025925],
              [-81.184788532995967, 34.792209621304487],
              [-81.184135480148754, 34.792126339828641],
              [-81.184084156585428, 34.792391275805535],
              [-81.183431102981856, 34.792307989606094],
              [-81.183379777026033, 34.792572925281426],
              [-81.182400194393836, 34.792447989400038],
              [-81.182348866107688, 34.792712924614762],
              [-81.179736653139074, 34.792379719140961],
              [-81.179787990759664, 34.792114785018434],
              [-81.17913494373083, 34.792031475503073],
              [-81.179083604049794, 34.792296409347507],
              [-81.178757079445205, 34.792254752681004],
              [-81.178808420156457, 34.791989818975608],
              [-81.178481898006694, 34.791948161542201],
              [-81.178533239416069, 34.791683227952134],
              [-81.176900635523978, 34.791474928248959],
              [-81.176849288963723, 34.791739861143832],
              [-81.174890169707098, 34.791489870512926],
              [-81.174941523540937, 34.791224938432642],
              [-81.174615005315658, 34.791183270525423],
              [-81.174563650451645, 34.79144820246664],
              [-81.173584096839932, 34.791323192070962],
              [-81.173635454794422, 34.791058260546968],
              [-81.173308938990658, 34.791016589075873],
              [-81.173360297619169, 34.790751656765977],
              [-81.172707269044992, 34.790668310542664],
              [-81.172758629425431, 34.790403379388522],
              [-81.172105604240727, 34.790320029899235],
              [-81.172156967417592, 34.790055098078525],
              [-81.170850925156486, 34.789888389024163],
              [-81.170902291052982, 34.789623458657239],
              [-81.170575783429129, 34.789581778847335],
              [-81.17062714999939, 34.789316847694558],
              [-81.170300643738059, 34.78927516713776],
              [-81.170352011029948, 34.789010237001698],
              [-81.170025506131054, 34.788968555698098],
              [-81.170076874096793, 34.788703624776133],
              [-81.169750370560308, 34.788661942725632],
              [-81.169853108647445, 34.788132081069364],
              [-81.169526607503329, 34.788090398411022],
              [-81.169577977624613, 34.787825467676733],
              [-81.169251476750546, 34.787783784291314],
              [-81.169302847569526, 34.787518853672424],
              [-81.169629346321202, 34.7875605369385],
              [-81.169886189116724, 34.786235880110176],
              [-81.169559695515247, 34.786194197539778],
              [-81.169611064316172, 34.785929265877996],
              [-81.168958080170242, 34.785845898357422],
              [-81.169009450698894, 34.785580966950285],
              [-81.168356469942324, 34.785497596164234],
              [-81.168407842198604, 34.785232665011705],
              [-81.168081353348725, 34.785190978428922],
              [-81.168235471213436, 34.784396185247374],
              [-81.168561956973335, 34.784437871412806],
              [-81.168613327876443, 34.784172939264664],
              [-81.168286843146404, 34.784131253238456],
              [-81.16838958603924, 34.783601390051217],
              [-81.168063104793916, 34.783559703397572],
              [-81.168114476783643, 34.783294772358396],
              [-81.167787995808098, 34.783253084977652],
              [-81.167839368471618, 34.782988153152807],
              [-81.167512889950743, 34.782946465005608],
              [-81.167564263311732, 34.782681533296362],
              [-81.167237786153208, 34.782639844402389],
              [-81.167186411762316, 34.782904775972561],
              [-81.165880501215952, 34.782738010099408],
              [-81.165931880818832, 34.782473079066257],
              [-81.164952454209683, 34.782347995556577],
              [-81.165003836569809, 34.782083064917323],
              [-81.164350889939101, 34.78199967144613],
              [-81.164402274026529, 34.781734741061783],
              [-81.164075802227785, 34.781693042685845],
              [-81.164178570372741, 34.781163182144596],
              [-81.163852100966125, 34.781121483161137],
              [-81.163903486116013, 34.780856552984602],
              [-81.163577018095452, 34.780814854155793],
              [-81.163628403918935, 34.780549923193576],
              [-81.163301938352987, 34.780508223598439],
              [-81.163404709993742, 34.7799783628029],
              [-81.162751782838328, 34.779894959728836],
              [-81.16270039459026, 34.780159890320327],
              [-81.162047466705076, 34.780076483424345],
              [-81.161996076064867, 34.780341413714083],
              [-81.160037295325552, 34.780091171811897],
              [-81.160088692144441, 34.779826242357402],
              [-81.159435770671152, 34.779742821268066],
              [-81.159487169240862, 34.779477892969894],
              [-81.158507792613946, 34.779352755109727],
              [-81.158559193916659, 34.779087826304391],
              [-81.157906279887442, 34.779004396913535],
              [-81.157957684009489, 34.778739468343637],
              [-81.157631228523456, 34.778697752458655],
              [-81.157682632250356, 34.778432824024108],
              [-81.157029725403859, 34.778349388954339],
              [-81.157081131950022, 34.778084460755231],
              [-81.156754679521043, 34.778042742491259],
              [-81.156857492582887, 34.777512886320778],
              [-81.156204593909948, 34.777429446771571],
              [-81.156256002547536, 34.777164518919776],
              [-81.15495021331111, 34.776997629748706],
              [-81.155001625734869, 34.776732702430586],
              [-81.154348735171524, 34.776649252808745],
              [-81.154400150390629, 34.776384324824889],
              [-81.153094377373506, 34.776217415508818],
              [-81.153145796378695, 34.775952488058586],
              [-81.15216647373154, 34.775827296728245],
              [-81.152217895516586, 34.775562370573816],
              [-81.151891456685306, 34.775520638191118],
              [-81.151942879143562, 34.775255711251283],
              [-81.15129000453814, 34.775172244107225],
              [-81.151341427654444, 34.774907318343388],
              [-81.151014991880288, 34.774865583581978],
              [-81.151066416762163, 34.774600657013217],
              [-81.15073998235026, 34.774558921505346],
              [-81.150842833175219, 34.774029068576219],
              [-81.150516402247391, 34.773987332441706],
              [-81.150567827074511, 34.773722405199614],
              [-81.150241397508822, 34.773680668318718],
              [-81.150344250456129, 34.773150815806432],
              [-81.150017822189994, 34.773109078337932],
              [-81.150120675842217, 34.77257922510951],
              [-81.149794251036326, 34.772537486113102],
              [-81.149897105416983, 34.772007633069848],
              [-81.14957068302644, 34.77196589436759],
              [-81.149673538111841, 34.771436040608343],
              [-81.149347118112971, 34.771394301299011],
              [-81.149398546186148, 34.771129374523703],
              [-81.149072127549502, 34.77108763446806],
              [-81.149123556319523, 34.770822707808769],
              [-81.148797139021369, 34.770780966105377],
              [-81.148848568488191, 34.770516039562033],
              [-81.148522152575723, 34.770474298013596],
              [-81.148573582739445, 34.770209371586276],
              [-81.148247169257701, 34.770167628370736],
              [-81.14829860011811, 34.769902702059369],
              [-81.147972186930005, 34.76986095901826],
              [-81.148023618463569, 34.769596031921722],
              [-81.147697207729792, 34.769554288114669],
              [-81.147902935771015, 34.76849458093465],
              [-81.148229342387097, 34.768536324184232],
              [-81.148383632092077, 34.767741542455767],
              [-81.148710035976308, 34.767783285303018],
              [-81.148761463819213, 34.767518357880839],
              [-81.148435060964445, 34.767476615172924],
              [-81.148589345561817, 34.766681832283517],
              [-81.14826294615149, 34.766640090009339],
              [-81.148314374698955, 34.76637516187148],
              [-81.147987976650526, 34.766333418850998],
              [-81.148090835922162, 34.765803563666132],
              [-81.14776444024146, 34.765761819137388],
              [-81.147970157794475, 34.764702108164705],
              [-81.147643766563831, 34.764660363307712],
              [-81.147695195870838, 34.764395435425442],
              [-81.147368807117928, 34.764353690703921],
              [-81.14742023709789, 34.764088762036543],
              [-81.14709384970692, 34.76404701656876],
              [-81.147196710751402, 34.763517160344385],
              [-81.146870325727946, 34.763475413368482],
              [-81.146921756792281, 34.76321048581147],
              [-81.146595373130836, 34.763168738089334],
              [-81.146749668460643, 34.762373953875432],
              [-81.146423288243255, 34.762332206587082],
              [-81.146526152540048, 34.761802350000039],
              [-81.146852530722668, 34.761844097911045],
              [-81.146903960796806, 34.761579169453221],
              [-81.146577583643506, 34.761537421681602],
              [-81.146629014414245, 34.761272493340016],
              [-81.143691648635198, 34.760896724721121],
              [-81.14374308942827, 34.760631797591337],
              [-81.14341671765996, 34.76059004124285],
              [-81.143365275837709, 34.760854968233218],
              [-81.142059791251398, 34.760687933367343],
              [-81.142008344979601, 34.760952859776971],
              [-81.141355602054858, 34.760869335860278],
              [-81.141304153391957, 34.76113426196806],
              [-81.140325037878569, 34.761008969493396],
              [-81.14027358579547, 34.761273895159675],
              [-81.137988989178353, 34.760981513448584],
              [-81.137937528466082, 34.761246438135068],
              [-81.137611158301013, 34.761204666015523],
              [-81.137559696203311, 34.761469589638054],
              [-81.136906953697618, 34.761386041581503],
              [-81.136958417830115, 34.761121117336423],
              [-81.135652942021721, 34.76095401113502],
              [-81.13560147268042, 34.761218934841544],
              [-81.135275103792651, 34.761177155482393],
              [-81.13532657307087, 34.760912231934725],
              [-81.133368371840874, 34.760661538940816],
              [-81.133419848052981, 34.760396616187492],
              [-81.132114392053438, 34.760229469758436],
              [-81.132062911724802, 34.760494391953799],
              [-81.131736547528078, 34.760452602993261],
              [-81.13178802886236, 34.760187680036083],
              [-81.130808941357387, 34.760062308259691],
              [-81.130860425469422, 34.75979738659921],
              [-81.129554985309341, 34.759630211470913],
              [-81.129606473181141, 34.759365289444055],
              [-81.129280114735508, 34.759323493817853],
              [-81.129383092653967, 34.758793650855743],
              [-81.129056737691499, 34.758751854603837],
              [-81.129108226622535, 34.758486932786553],
              [-81.128781871930016, 34.75844513580801],
              [-81.128833362649175, 34.758180214087893],
              [-81.12818065738972, 34.758096616832702],
              [-81.12823214983392, 34.75783169536848],
              [-81.127905798198427, 34.757789896012547],
              [-81.127957291338618, 34.757524974664804],
              [-81.12763094215714, 34.757483174543545],
              [-81.127682434901232, 34.757218253331558],
              [-81.127029739548661, 34.757134648909194],
              [-81.127081235109785, 34.756869727933882],
              [-81.126754888985317, 34.75682792543531],
              [-81.126806384150342, 34.756563004595783],
              [-81.126480039364566, 34.756521200450081],
              [-81.126531536317586, 34.756256279707721],
              [-81.125552508137318, 34.75613086235704],
              [-81.125501008120835, 34.756395783582107],
              [-81.125174664667142, 34.756353975894463],
              [-81.125226165735967, 34.756089055710369],
              [-81.124899823644284, 34.756047247276875],
              [-81.124848321546509, 34.756312167321411],
              [-81.124521978759034, 34.756270357862967],
              [-81.124470475299248, 34.756535277744845],
              [-81.124796819115659, 34.756577087342947],
              [-81.124590804969515, 34.757636767217072],
              [-81.124264457037143, 34.757594957060761],
              [-81.12405843342232, 34.758654635105508],
              [-81.123732080615, 34.758612823524622],
              [-81.123629064193253, 34.75914266168806],
              [-81.123302710753222, 34.759100848923239],
              [-81.123199690940879, 34.759630686714857],
              [-81.12287333468386, 34.759588872804741],
              [-81.122770311457586, 34.760118709323208],
              [-81.122443954567757, 34.760076894229179],
              [-81.122392440879892, 34.760341812323702],
              [-81.12206608222543, 34.760299997125145],
              [-81.122014567152007, 34.760564914155587],
              [-81.121688208893687, 34.760523097912696],
              [-81.121636692458083, 34.760788014780296],
              [-81.121310332388546, 34.760746196630265],
              [-81.121258814614066, 34.761011114236446],
              [-81.120932453848567, 34.760969295061322],
              [-81.120829415241971, 34.761499129924857],
              [-81.120503052751587, 34.761457309585019],
              [-81.120451531896109, 34.761722225941085],
              [-81.120125168709976, 34.76168040457614],
              [-81.120073646515479, 34.76194532167068],
              [-81.119747282633483, 34.76190349928055],
              [-81.119592710917146, 34.762698248203478],
              [-81.119266343189068, 34.762656424528238],
              [-81.119214817580314, 34.762921340349408],
              [-81.118888450248448, 34.762879515629812],
              [-81.116603887141636, 34.762586716916353],
              [-81.116552351875001, 34.762851631616407],
              [-81.116225986180268, 34.762809799673157],
              [-81.116174449551281, 34.763074714210333],
              [-81.115848083137607, 34.763032880340525],
              [-81.115745006821584, 34.763562709065909],
              [-81.115418638706288, 34.763520874932603],
              [-81.115315558975553, 34.76405070238431],
              [-81.114989188043396, 34.764008867105382],
              [-81.114937646660508, 34.764273781107292],
              [-81.114611276124776, 34.764231944784086],
              [-81.114559733356288, 34.764496857721731],
              [-81.114233361032575, 34.764455020392568],
              [-81.114130272460613, 34.764984846819971],
              [-81.113477525765973, 34.764901168025808],
              [-81.113425977817968, 34.765166080493849],
              [-81.113099604499368, 34.765124240069838],
              [-81.113048055188898, 34.765389152374837],
              [-81.112395306378218, 34.765305467708828],
              [-81.11234375469941, 34.765570380612552],
              [-81.11201738029952, 34.765528536351304],
              [-81.111965827234968, 34.765793448190649],
              [-81.110660326715646, 34.765626063572739],
              [-81.110711883896528, 34.765361152292101],
              [-81.110059137695757, 34.765277454949377],
              [-81.110110696578133, 34.765012543023516],
              [-81.109457953769223, 34.76492884241808],
              [-81.109509514399463, 34.764663931649601],
              [-81.109183145058694, 34.764622079698107],
              [-81.109234706384598, 34.764357169045937],
              [-81.108908338406351, 34.764315316348643],
              [-81.108959900404514, 34.764050404911544],
              [-81.108633533788776, 34.76400855146845],
              [-81.108685096505781, 34.76374364104916],
              [-81.108358731252537, 34.763701786860203],
              [-81.108410294641899, 34.763436875656005],
              [-81.108083931843296, 34.763395020702198],
              [-81.108135495951274, 34.763130110515718],
              [-81.107809133423103, 34.763088254835182],
              [-81.108066956150921, 34.761763698806149],
              [-81.107740599101135, 34.76172184293879],
              [-81.107895291595284, 34.760927109104713],
              [-81.10756893905787, 34.760885252751905],
              [-81.107672068134207, 34.760355430052243],
              [-81.107345717988153, 34.760313573093448],
              [-81.107448846673051, 34.759783749698173],
              [-81.107122498918386, 34.759741892133356],
              [-81.107174064881647, 34.759476980521541],
              [-81.106847718489263, 34.75943512221091],
              [-81.106899284055942, 34.759170210734737],
              [-81.106572939025952, 34.75912835167852],
              [-81.10662450638003, 34.758863440299756],
              [-81.106298162689313, 34.758821579596422],
              [-81.106349729646709, 34.758556668353329],
              [-81.106023388433627, 34.758514807786575],
              [-81.106178091460336, 34.757720073416841],
              [-81.105851753667309, 34.75767821238383],
              [-81.105903321325314, 34.757413300426165],
              [-81.105576984894626, 34.75737143864756],
              [-81.105680122359928, 34.756841614923033],
              [-81.105027453522183, 34.756757889287677],
              [-81.10507902435829, 34.756492977660571],
              [-81.104752691991308, 34.756451112743711],
              [-81.104701120126379, 34.756716024230975],
              [-81.104048453266472, 34.756632292381852],
              [-81.10399687901041, 34.756897203566524],
              [-81.103670545586439, 34.75685533571388],
              [-81.103618969968025, 34.757120246735532],
              [-81.103292635848675, 34.757078377857788],
              [-81.103241058867923, 34.757343288716534],
              [-81.102914722961302, 34.757301418832746],
              [-81.102863144618169, 34.757566329528458],
              [-81.102536809108273, 34.757524458600386],
              [-81.102485228310684, 34.757789369152334],
              [-81.102158892105621, 34.757747497199091],
              [-81.102055728520938, 34.758277317033468],
              [-81.101729389499695, 34.758235443934339],
              [-81.101677806712686, 34.75850035321676],
              [-81.101351467019299, 34.758458479993678],
              [-81.101299882869853, 34.758723389113136],
              [-81.100973542458021, 34.758681513963644],
              [-81.100921956946152, 34.75894642292009],
              [-81.100595615862289, 34.758904547646672],
              [-81.100544027895765, 34.759169456459219],
              [-81.100217686093558, 34.759127579259307],
              [-81.100166097856757, 34.759392487889748],
              [-81.099839755359326, 34.759350609664558],
              [-81.099788164667856, 34.759615518151143],
              [-81.099461821475202, 34.759573638900726],
              [-81.099410229421338, 34.759838547224192],
              [-81.098757543071272, 34.759754785768607],
              [-81.098705948626048, 34.760019693789239],
              [-81.098379604376547, 34.759977811602958],
              [-81.098328008568885, 34.760242719460543],
              [-81.097348974735965, 34.760117067169872],
              [-81.097400573653374, 34.759852160633052],
              [-81.097074230738684, 34.759810274905178],
              [-81.097125831420243, 34.759545367564471],
              [-81.09679948986799, 34.759503481091002],
              [-81.096851090152597, 34.759238573886009],
              [-81.096524751055071, 34.759196686647826],
              [-81.096576352034816, 34.758931779559411],
              [-81.096250013207694, 34.758889891594762],
              [-81.096301615974554, 34.758624984603856],
              [-81.095975278509954, 34.758583095893627],
              [-81.09613008677978, 34.757788374318942],
              [-81.095803752735279, 34.757746485142818],
              [-81.095906958288893, 34.757216671530578],
              [-81.095580627704564, 34.75717478082835],
              [-81.095632230463536, 34.756909874136774],
              [-81.095305901264823, 34.756867983590283],
              [-81.095460712763824, 34.756073261955379],
              [-81.095134386961902, 34.756031370041825],
              [-81.095185991512651, 34.755766462616918],
              [-81.094859667096244, 34.755724570859051],
              [-81.09491127124987, 34.755459663569837],
              [-81.094584948195958, 34.755417771066476],
              [-81.094533343013623, 34.755682678215869],
              [-81.09420701924158, 34.755640783786063],
              [-81.094155411604746, 34.755905690791415],
              [-81.093829088252903, 34.755863796218712],
              [-81.093777479253617, 34.75612870306108],
              [-81.093451154091596, 34.756086806580875],
              [-81.093399543729859, 34.756351713260187],
              [-81.092746892372745, 34.756267918265216],
              [-81.09269527961979, 34.756532824641631],
              [-81.090084680381679, 34.756197607208257],
              [-81.090136302479394, 34.755932702833086],
              [-81.089483657775048, 34.75584888922976],
              [-81.089535281573333, 34.755583984209956],
              [-81.089208960750639, 34.755542076220195],
              [-81.089260585266757, 34.755277172218427],
              [-81.088934265806472, 34.755235263483172],
              [-81.088985890994451, 34.754970358696816],
              [-81.088659572896759, 34.754928449216173],
              [-81.088762824328526, 34.75439863985374],
              [-81.088436509714015, 34.754356729748608],
              [-81.088539760752738, 34.753826919691086],
              [-81.088213448529245, 34.75378500898038],
              [-81.088316700289951, 34.753255199110107],
              [-81.087990390457549, 34.753213287793933],
              [-81.088093644032085, 34.752683478091996],
              [-81.087767336590716, 34.752641566170325],
              [-81.087870589772123, 34.752111755773399],
              [-81.087544285813877, 34.752069843227389],
              [-81.087595912386405, 34.751804938143657],
              [-81.087269609790496, 34.75176302485221],
              [-81.087321238149755, 34.751498119866312],
              [-81.086668633832772, 34.75141429094537],
              [-81.08672026389236, 34.751149385314953],
              [-81.086393964355096, 34.751107469647579],
              [-81.086342333244005, 34.751372374236773],
              [-81.086016031920053, 34.75133045756305],
              [-81.085689732022061, 34.751288539985218],
              [-81.085638097428003, 34.751553444290394],
              [-81.083680295558182, 34.751301917646984],
              [-81.083731936323503, 34.751037014181399],
              [-81.082753043505008, 34.750911238417672],
              [-81.08270139967712, 34.751176142364599],
              [-81.08237510097463, 34.751134214911744],
              [-81.08232345685326, 34.751399117775378],
              [-81.081997157479194, 34.75135719019859],
              [-81.081945510903353, 34.75162209291809],
              [-81.081619211903714, 34.751580163395758],
              [-81.081567563965379, 34.751845065952239],
              [-81.08124126427127, 34.751803135404693],
              [-81.081189614970441, 34.752068037798097],
              [-81.08053701236588, 34.751984174706344],
              [-81.080485360673904, 34.752249076796659],
              [-81.080159059924682, 34.752207143313328],
              [-81.080107406870269, 34.752472045240459],
              [-81.079128501379728, 34.752346239998225],
              [-81.079076843790887, 34.752611140599818],
              [-81.078750542343442, 34.752569204196845],
              [-81.07880219984601, 34.752304302852814],
              [-81.078149598872628, 34.752220425887259],
              [-81.078201259190209, 34.751955524781209],
              [-81.076569768314073, 34.751745818467171],
              [-81.076621432348119, 34.751480918056856],
              [-81.072705903621269, 34.750977530269729],
              [-81.072757581846844, 34.750712631478983],
              [-81.070799842743824, 34.75046088880169],
              [-81.070748158347612, 34.750725786752],
              [-81.070095579263338, 34.750641865164283],
              [-81.070043892476079, 34.750906762811361],
              [-81.069717602418095, 34.750864801000212],
              [-81.069665914268199, 34.751129698484007],
              [-81.069339623493335, 34.751087734746335],
              [-81.069287933980789, 34.751352632066919],
              [-81.068961642511624, 34.751310667303855],
              [-81.068909951636414, 34.751575564461277],
              [-81.068583659473049, 34.751533598672971],
              [-81.068531967235259, 34.751798495667103],
              [-81.068205673285703, 34.751756528872242],
              [-81.068153979685107, 34.752021425703035],
              [-81.067827686133469, 34.751979457864117],
              [-81.067775990078161, 34.752244354550506],
              [-81.067449695832266, 34.752202385686239],
              [-81.067397999506355, 34.752467282190587],
              [-81.067071704566274, 34.752425312300907],
              [-81.067020005785523, 34.752690208660852],
              [-81.066693710151299, 34.752648237745838],
              [-81.066642009985046, 34.752913133041098],
              [-81.066315713656763, 34.752871161100821],
              [-81.066264012150484, 34.7531360571341],
              [-81.065937715127959, 34.753094084168488],
              [-81.065834309010199, 34.753623874082614],
              [-81.065181710784969, 34.753539925855179],
              [-81.065130005167703, 34.753804820497301],
              [-81.064803706062776, 34.753762844455537],
              [-81.064751999082617, 34.754027738934255],
              [-81.064425698191641, 34.753985761885964],
              [-81.064373989848576, 34.754250656201322],
              [-81.063721388104781, 34.75416669914992],
              [-81.063669677370399, 34.754431593161826],
              [-81.063343375425575, 34.754389613177516],
              [-81.063291663328457, 34.754654507026146],
              [-81.062965360689432, 34.754612526016388],
              [-81.062913647206841, 34.754877418800241],
              [-81.062261040875143, 34.754793453844776],
              [-81.062209325023801, 34.755058347226509],
              [-81.061556719064853, 34.754974378431044],
              [-81.06150499970731, 34.755239270626582],
              [-81.060852393029208, 34.755155298009804],
              [-81.06080067130307, 34.755420190803115],
              [-81.059821760139968, 34.755294223926192],
              [-81.059770034993932, 34.755559116275741],
              [-81.056833310310594, 34.755181166580115],
              [-81.05688504580479, 34.754916275473384],
              [-81.056558745406662, 34.754874277083424],
              [-81.056610481594774, 34.754609386093698],
              [-81.056284182537084, 34.754567386057317],
              [-81.056335919441693, 34.754302496085863],
              [-81.056009622838914, 34.754260495285841],
              [-81.056113096586699, 34.753730714670915],
              [-81.055786802375366, 34.753688713266015],
              [-81.055890276819099, 34.753158931937264],
              [-81.055563984999083, 34.753116929927614],
              [-81.055667461253549, 34.752587148767645],
              [-81.055341171824736, 34.752545146153203],
              [-81.055392909386597, 34.752280255697222],
              [-81.055066622412696, 34.752238252319138],
              [-81.055118360668303, 34.751973361980241],
              [-81.054792075057307, 34.751931357857096],
              [-81.054843814006702, 34.751666467635289],
              [-81.053864960082194, 34.751540450412769],
              [-81.053916702874062, 34.75127556056966],
              [-81.051306451237693, 34.750939476773546],
              [-81.051358201898964, 34.750674587127818],
              [-81.050379366277639, 34.750548541517404],
              [-81.05043112078107, 34.750283652250651],
              [-81.049778567043376, 34.750199616803279],
              [-81.04983032329126, 34.749934728695187],
              [-81.049504048487307, 34.749892709324037],
              [-81.049711073063293, 34.748833153634386],
              [-81.049384802707053, 34.74879113393915],
              [-81.049540070414523, 34.747996466452996],
              [-81.04888753689778, 34.747912426150123],
              [-81.048939294558821, 34.747647537516592],
              [-81.048286765528999, 34.747563493935182],
              [-81.048235005811463, 34.74782838228824],
              [-81.047256212249351, 34.747702310298216],
              [-81.047204448020608, 34.747967198225815],
              [-81.046225654363425, 34.747841116947662],
              [-81.046173886737932, 34.748106005332545],
              [-81.045521356893801, 34.748021946747599],
              [-81.045573126575661, 34.747757058643323],
              [-81.043941812384077, 34.747546896050245],
              [-81.043993586872162, 34.747282008624389],
              [-81.043014806341105, 34.747155901213873],
              [-81.042963028746186, 34.747420787317445],
              [-81.041984248121196, 34.747294670618821],
              [-81.041932467129413, 34.747559557179585],
              [-81.040627427234512, 34.747391388622596],
              [-81.040575641773088, 34.74765627369753],
              [-81.040249381881196, 34.747614229200579],
              [-81.040301168370846, 34.747349344265992],
              [-81.039648650533493, 34.747265252014685],
              [-81.039700439814084, 34.747000366417851],
              [-81.039374182982939, 34.74695831954628],
              [-81.039425971864858, 34.746693434085174],
              [-81.038773461263389, 34.746609337967648],
              [-81.038825252958631, 34.746344452745539],
              [-81.037520239841513, 34.746176248648453],
              [-81.037468444033891, 34.746441133308963],
              [-81.036489684639136, 34.746314970972385],
              [-81.036541483530982, 34.746050086733014],
              [-81.035236480882574, 34.745881857835485],
              [-81.035288283551765, 34.745616974134578],
              [-81.03463578681675, 34.7455328537458],
              [-81.034687591207145, 34.745267970302464],
              [-81.034361344916078, 34.745225908911664],
              [-81.034309538428104, 34.745490793134309],
              [-81.033330797388345, 34.745364603248817],
              [-81.033278987458914, 34.745629486125772],
              [-81.031321507185865, 34.745377082535875],
              [-81.031373322191513, 34.745112200519948],
              [-81.030720834587513, 34.745028058871625],
              [-81.030772652383774, 34.744763176193693],
              [-81.030446409020456, 34.744721104200913],
              [-81.030498227532036, 34.744456222541544],
              [-81.030171986623557, 34.744414149785754],
              [-81.030223805805775, 34.744149267342486],
              [-81.029897566260402, 34.74410719384209],
              [-81.029949386157924, 34.743842312417485],
              [-81.029623147975443, 34.743800238172454],
              [-81.029674968543574, 34.743535355963921],
              [-81.029348731724085, 34.743493280974405],
              [-81.029400552985166, 34.74322839888314],
              [-81.029074317528739, 34.743186323149061],
              [-81.029126140574633, 34.742921441156824],
              [-81.026516278233061, 34.742584801825572],
              [-81.026568109167343, 34.742319920934037],
              [-81.02591564870994, 34.742235752311615],
              [-81.025967481364944, 34.741970871677871],
              [-81.025641253747494, 34.74192878571062],
              [-81.025693087095291, 34.741663905194379],
              [-81.02536685977131, 34.741621819402376],
              [-81.025418693789533, 34.741356938102165],
              [-81.02509246892042, 34.741314851547287],
              [-81.02514430253963, 34.741049970382996],
              [-81.024818079033395, 34.741007883083647],
              [-81.024869914437247, 34.740743002018327],
              [-81.02454369229406, 34.740700913974514],
              [-81.024595528390577, 34.740436033026754],
              [-81.024269307588085, 34.740393943337068],
              [-81.024372980853926, 34.739864182554776],
              [-81.02404676244214, 34.739822092261214],
              [-81.024098599611349, 34.739557212426696],
              [-81.023772382562498, 34.739515121388656],
              [-81.023824220402176, 34.739250240770289],
              [-81.023498004716345, 34.739208148987885],
              [-81.023549843248617, 34.738943268487034],
              [-81.02322362892572, 34.738901175960223],
              [-81.02327546924262, 34.7386362955584],
              [-81.022623043657717, 34.738552108131032],
              [-81.022674885695068, 34.738287227987342],
              [-81.022022463506175, 34.738203037301233],
              [-81.022074307264035, 34.73793815741562],
              [-81.021748097700083, 34.737896060885745],
              [-81.021799942128226, 34.73763118021634],
              [-81.021473733927181, 34.73758908294203],
              [-81.021525579070172, 34.737324203291493],
              [-81.021199372232132, 34.73728210527279],
              [-81.021406751313123, 34.736222584335778],
              [-81.021080550012996, 34.736180485976064],
              [-81.021132395513376, 34.735915605590428],
              [-81.020479995973901, 34.73583140649739],
              [-81.020531842102827, 34.735566526388226],
              [-81.020205643841265, 34.735524424753542],
              [-81.020309339332655, 34.734994664710847],
              [-81.020635534447038, 34.735036766082779],
              [-81.020842916160902, 34.733977243375627],
              [-81.021169107499745, 34.734019343300503],
              [-81.021324635807375, 34.733224700180152],
              [-81.020998447573817, 34.733182601578335],
              [-81.021102133841069, 34.732652839057771],
              [-81.020775947997819, 34.732610739852099],
              [-81.020879633888072, 34.732080977539468],
              [-81.020553450412976, 34.732038876828824],
              [-81.020657138109726, 34.731509114687483],
              [-81.020330957025052, 34.731467013373098],
              [-81.02043464543641, 34.730937251421338],
              [-81.020108466741959, 34.73089514950324],
              [-81.020160312018533, 34.73063026862453],
              [-81.019834134686789, 34.730588165962097],
              [-81.019885980633489, 34.730323284299772],
              [-81.019559804686665, 34.730281181794496],
              [-81.01971534252921, 34.729486538011855],
              [-81.019389169977956, 34.729444434142067],
              [-81.019441016639249, 34.729179552669571],
              [-81.019767187071238, 34.729221656417131],
              [-81.019819032369782, 34.728956774781174],
              [-81.019492861873701, 34.728914671192591],
              [-81.019596553520572, 34.728384908133556],
              [-81.019270385414671, 34.728342803941302],
              [-81.019374077753938, 34.727813040170901],
              [-81.019700242713128, 34.72785514410036],
              [-81.019803930565232, 34.727325379976037],
              [-81.020130093804624, 34.727367482739531],
              [-81.020233778261257, 34.726837718243004],
              [-81.020559939758542, 34.726879818939331],
              [-81.020611779922035, 34.726614936976191],
              [-81.020937940726839, 34.726657036647246],
              [-81.020989779505655, 34.726392153619464],
              [-81.021315939640274, 34.726434253166438],
              [-81.021419615229235, 34.72590448674319],
              [-81.021093457171688, 34.72586238837858],
              [-81.021145294922988, 34.725597504381263],
              [-81.020819138228063, 34.72555540527253],
              [-81.020870976693885, 34.725290522294365],
              [-81.020544822430921, 34.725248421521755],
              [-81.020596661566898, 34.72498353776011],
              [-81.019944355031555, 34.724899334761361],
              [-81.019996195887416, 34.72463445125814],
              [-81.019670044706771, 34.72459234901374],
              [-81.019721886254899, 34.724327465628356],
              [-81.019395736414538, 34.72428536173841],
              [-81.019447579768936, 34.724020479353875],
              [-81.019121430199348, 34.723978374738188],
              [-81.019225116821616, 34.723448608397881],
              [-81.018898970733559, 34.723406503160213],
              [-81.019158187107962, 34.722082087606253],
              [-81.018832046491852, 34.722039982186836],
              [-81.01909125848988, 34.7207155649849],
              [-81.018765124437238, 34.720673459365379],
              [-81.018868809159414, 34.72014369242342],
              [-81.019194941157522, 34.7201857977618],
              [-81.019402302427565, 34.719126261241527],
              [-81.019076173447147, 34.719084156483831],
              [-81.019179854104266, 34.718554387467989],
              [-81.018853728604938, 34.718512282088376],
              [-81.0189055689235, 34.718247398147049],
              [-81.018579444764299, 34.718205291122068],
              [-81.018631286866636, 34.717940407280352],
              [-81.017652919525659, 34.717814083122022],
              [-81.017704764352473, 34.717549198778201],
              [-81.017052523203148, 34.717464979131471],
              [-81.017104369749333, 34.717200095046287],
              [-81.016778250682307, 34.717157983135301],
              [-81.016881944846048, 34.716628216079805],
              [-81.016555828168535, 34.716586103565369],
              [-81.016607675763893, 34.716321219693711],
              [-81.015955446582325, 34.716236993175187],
              [-81.015903596932574, 34.716501876765584],
              [-81.014273025241749, 34.716291293825329],
              [-81.014221170120919, 34.716556176690034],
              [-81.013242828260331, 34.716429815704693],
              [-81.013190968631264, 34.716694698143158],
              [-81.012538739967056, 34.716610452187105],
              [-81.012486879040011, 34.716875334303495],
              [-81.011834649662177, 34.716791084527557],
              [-81.011782785254042, 34.717055966358373],
              [-81.011130555162609, 34.716971712762728],
              [-81.011078688364904, 34.717236594289638],
              [-81.010426457559873, 34.717152336874136],
              [-81.010374588350501, 34.717417217195816],
              [-81.009722356832214, 34.717332955960558],
              [-81.009670485255342, 34.71759783687957],
              [-81.009344369517564, 34.717555704785198],
              [-81.009292496556171, 34.717820584639725],
              [-81.00798803066607, 34.717652046889768],
              [-81.007936152169137, 34.717916926177175],
              [-81.006631687512737, 34.717748372809282],
              [-81.006579804571842, 34.718013251511273],
              [-81.005927572212244, 34.717928969688835],
              [-81.005601457081269, 34.717886827441625],
              [-81.005653343081491, 34.717621948260422],
              [-81.005327228221347, 34.717579805287507],
              [-81.00537911491358, 34.717314926224311],
              [-81.005053002507481, 34.717272782489317],
              [-81.005156775870418, 34.716743025496271],
              [-81.00483066583196, 34.716700880256766],
              [-81.004882553594399, 34.716436002308626],
              [-81.004556444918322, 34.716393856325197],
              [-81.004608333350461, 34.716128977593947],
              [-81.004282226058891, 34.716086831767946],
              [-81.004334115182814, 34.715821953154794],
              [-81.004008009231725, 34.715779805683688],
              [-81.004059899047292, 34.715514927188686],
              [-81.003733794480695, 34.715472779874958],
              [-81.003785684988088, 34.715207901498204],
              [-81.003459581761803, 34.715165752539292],
              [-81.003511472960909, 34.714900874280637],
              [-81.003185372188582, 34.714858724559797],
              [-81.003237264079402, 34.714593846419326],
              [-81.002258964694889, 34.714467393309235],
              [-81.002310859309475, 34.714202514667129],
              [-81.001984761954773, 34.714160362590832],
              [-81.00203665726103, 34.71389548406691],
              [-81.001710561246696, 34.71385333034543],
              [-81.001814352929159, 34.713323574412811],
              [-81.001488259304267, 34.71328142008835],
              [-81.00154015674994, 34.713016541760169],
              [-81.001214063395949, 34.712974386710115],
              [-81.001317858265637, 34.712444631187196],
              [-81.000991768392538, 34.712402475515844],
              [-81.001095563952646, 34.711872719283143],
              [-81.000769476468989, 34.711830563008853],
              [-81.000821375296582, 34.711565684089145],
              [-81.000169203389319, 34.711481369168453],
              [-81.00022110286605, 34.71121649142723],
              [-80.999895019533909, 34.711174332762774],
              [-80.999946919680198, 34.710909454238532],
              [-80.998968672594202, 34.71078297339664],
              [-80.999020576577394, 34.710518095254081],
              [-80.998368415948121, 34.710433770545791],
              [-80.997716256638682, 34.710349441398265],
              [-80.997664348547772, 34.710614318977697],
              [-80.996686108483942, 34.710487818659949],
              [-80.996738019655595, 34.710222941502927],
              [-80.996411942067098, 34.710180773390476],
              [-80.996463853930265, 34.709915896351838],
              [-80.996137777704135, 34.709873727495697],
              [-80.996189690258532, 34.709608850575442],
              [-80.995863615372912, 34.709566680074296],
              [-80.995915528618823, 34.709301803272282],
              [-80.995589455117411, 34.709259632928884],
              [-80.995641369054724, 34.708994756245247],
              [-80.995315296893722, 34.708952584256835],
              [-80.99541912690708, 34.708422831085727],
              [-80.995093057157206, 34.708380659395743],
              [-80.99514497211986, 34.708115782025111],
              [-80.994818903732323, 34.708073609591544],
              [-80.994870820477757, 34.707808732321148],
              [-80.994544753452544, 34.70776655914397],
              [-80.9945966697979, 34.707501682010104],
              [-80.994270605226518, 34.707459508071118],
              [-80.994322522263118, 34.707194631055636],
              [-80.993996457940696, 34.707152455489897],
              [-80.994048376782047, 34.706887579476053],
              [-80.993722313821849, 34.706845403166689],
              [-80.993774233332573, 34.706580526369976],
              [-80.993448172826092, 34.706538349298889],
              [-80.99355201182513, 34.706008596839268],
              [-80.993225952616299, 34.705966419183554],
              [-80.99327787317408, 34.705701542601311],
              [-80.992951816418952, 34.705659364183902],
              [-80.993003737668005, 34.705394487720113],
              [-80.992677681183736, 34.705352308577218],
              [-80.992729603124033, 34.705087432231892],
              [-80.992077495398362, 34.705003071538286],
              [-80.992129419056624, 34.704738195452386],
              [-80.9918033667239, 34.704696013919779],
              [-80.991855291051465, 34.70443113705096],
              [-80.991203189445912, 34.704346771614318],
              [-80.991255115513297, 34.704081895906249],
              [-80.990603017281515, 34.703997526312385],
              [-80.99065494506678, 34.703732650863621],
              [-80.98935075782785, 34.703563901608703],
              [-80.989402689384335, 34.703299026701082],
              [-80.98646829886772, 34.702919289926918],
              [-80.986416357001673, 34.703184164485833],
              [-80.985438229419529, 34.703057568925253],
              [-80.985386284137959, 34.703322443039156],
              [-80.984734199057328, 34.703238040688269],
              [-80.984682251386801, 34.703502914497967],
              [-80.982725998063259, 34.703249686276102],
              [-80.982777951893752, 34.702984813311957],
              [-80.982451912416792, 34.702942605494805],
              [-80.982503866959988, 34.702677733550551],
              [-80.982177828845352, 34.702635524989958],
              [-80.98222978514903, 34.702370652245001],
              [-80.981903748396832, 34.702328442940917],
              [-80.98195570430002, 34.702063570332541],
              [-80.981629668910045, 34.702021360285158],
              [-80.981681626595403, 34.701756487777402],
              [-80.981355592567823, 34.701714276986621],
              [-80.981407549852776, 34.701449404615467],
              [-80.981081517187434, 34.70140719308133],
              [-80.98113347625457, 34.701142320810817],
              [-80.980155384422233, 34.701015680405845],
              [-80.980207345141991, 34.700750808553877],
              [-80.978903231149189, 34.70058194317037],
              [-80.978955197800317, 34.700317070922694],
              [-80.977977119178334, 34.700190412792836],
              [-80.978029087481929, 34.69992554096379],
              [-80.977703062616229, 34.699883320266011],
              [-80.977755032701793, 34.699618448537663],
              [-80.97710298710021, 34.699534003851788],
              [-80.977154957833591, 34.699269133302487],
              [-80.976502915606474, 34.699184684460093],
              [-80.976554889148261, 34.698919814152461],
              [-80.976228870110575, 34.698877588536909],
              [-80.976332817104861, 34.698347846352107],
              [-80.976006800455963, 34.698305620134263],
              [-80.9760587750215, 34.698040749140489],
              [-80.975732758665274, 34.697998523098569],
              [-80.975784733899729, 34.697733651322217],
              [-80.975458719997093, 34.697691424519164],
              [-80.975614645716945, 34.696896811300142],
              [-80.975288635207718, 34.696854583134552],
              [-80.975340611151779, 34.696589711551198],
              [-80.974362585831358, 34.696463023055223],
              [-80.974414564518838, 34.6961981518727],
              [-80.973762551719872, 34.696113688129373],
              [-80.973814532124209, 34.695848817206674],
              [-80.971858507946109, 34.695595405601047],
              [-80.97191049417296, 34.695330535502279],
              [-80.970932490221216, 34.695203817275228],
              [-80.970984480282524, 34.694938947559557],
              [-80.971192434964536, 34.69387946760925],
              [-80.971518430464798, 34.693921707804066],
              [-80.971570416707365, 34.693656836952961],
              [-80.971896411495507, 34.693699075221204],
              [-80.971948396397565, 34.693434205108026],
              [-80.972274390495329, 34.69347644235107],
              [-80.972326374035219, 34.69321157207478],
              [-80.972652367442535, 34.693253808292482],
              [-80.972704349598473, 34.692988936951636],
              [-80.973030342315326, 34.693031172144096],
              [-80.973082323130882, 34.692766301541425],
              [-80.973408315157258, 34.692808535708657],
              [-80.973616230869141, 34.691749048906651],
              [-80.973942218034907, 34.691791281643468],
              [-80.974098148192169, 34.690996664985022],
              [-80.973446179262865, 34.690912199491407],
              [-80.973498157370145, 34.690647327810176],
              [-80.973172174959473, 34.69060509296785],
              [-80.973224153757101, 34.69034022140557],
              [-80.972898171638889, 34.690297986739353],
              [-80.972572190926115, 34.690255750269721],
              [-80.972624171440401, 34.689990878967436],
              [-80.972298192089696, 34.689948641754633],
              [-80.972246210570759, 34.69021351381717],
              [-80.969964356309276, 34.689917830305944],
              [-80.970016345033457, 34.689652960132136],
              [-80.969690368369413, 34.689610715846079],
              [-80.970054291430586, 34.687756618617527],
              [-80.970380260911227, 34.687798861915979],
              [-80.970796141684502, 34.685679885840521],
              [-80.97047018150414, 34.685637643652754],
              [-80.970574149834974, 34.685107899254007],
              [-80.970248192042661, 34.685065656464296],
              [-80.970300177821557, 34.684800784355041],
              [-80.969974221391169, 34.684758540822337],
              [-80.970130177587109, 34.683963923920537],
              [-80.969804224570808, 34.683921679926911],
              [-80.969856211036856, 34.683656807110651],
              [-80.970182161935981, 34.683699050981048],
              [-80.970234145970679, 34.683434178920869],
              [-80.970338114080334, 34.682904432914093],
              [-80.970546045178835, 34.681844940655068],
              [-80.970220101462061, 34.681802697772184],
              [-80.970324066481268, 34.681272950900855],
              [-80.96999812515223, 34.681230707416098],
              [-80.970154074320874, 34.680436086899377],
              [-80.969828136405624, 34.680393842953841],
              [-80.969880119445307, 34.680128969251079],
              [-80.969554182891841, 34.680086724562599],
              [-80.969710134186585, 34.679292104629582],
              [-80.970036067662164, 34.679334348894713],
              [-80.970088049336994, 34.679069474203011],
              [-80.969762116887367, 34.679027230078958],
              [-80.969814099274004, 34.678762356407795],
              [-80.969488169277184, 34.678720111522942],
              [-80.969644118569448, 34.677925489882305],
              [-80.969318190895137, 34.677883244554629],
              [-80.969422157797723, 34.677353495759398],
              [-80.969096233602073, 34.677311249812028],
              [-80.969200200143874, 34.676781502130837],
              [-80.968874278314161, 34.676739254680342],
              [-80.968978246633853, 34.676209506274837],
              [-80.968652327213334, 34.676167259123879],
              [-80.968704311349882, 34.675902385038434],
              [-80.968052475546401, 34.675817887465499],
              [-80.968104462489791, 34.675553013622597],
              [-80.967452630081254, 34.675468512796002],
              [-80.96750461874062, 34.675203639213528],
              [-80.967178704055058, 34.675161387164742],
              [-80.967386658303298, 34.67410189139656],
              [-80.967060748078453, 34.674059639929624],
              [-80.96726869992267, 34.67300014259277],
              [-80.966942795206165, 34.672957889887137],
              [-80.966994783898997, 34.672693015630692],
              [-80.966668880565692, 34.672650763083652],
              [-80.966720868835878, 34.67238588806314],
              [-80.966394966864243, 34.672343634773291],
              [-80.966446956915263, 34.672078759854301],
              [-80.966121056305099, 34.672036505821666],
              [-80.966173047046155, 34.671771631022082],
              [-80.965847147797547, 34.67172937624666],
              [-80.965899139228512, 34.671464501566476],
              [-80.965573241319859, 34.671422245146971],
              [-80.965625233462333, 34.671157371487539],
              [-80.965299336915223, 34.671115114325261],
              [-80.965351329725991, 34.670850239883919],
              [-80.965025434540436, 34.670807981978889],
              [-80.965129421227502, 34.670278234214663],
              [-80.964803528429186, 34.670235975708039],
              [-80.964855522283855, 34.669971101483846],
              [-80.964529631938007, 34.669928842216635],
              [-80.964633620669559, 34.669399093084188],
              [-80.964307731620053, 34.669356833233216],
              [-80.964359726518495, 34.669091959226307],
              [-80.964033839921328, 34.669049698614778],
              [-80.964085835509692, 34.668784824727325],
              [-80.96375995027411, 34.668742563373094],
              [-80.963811946530598, 34.668477688703788],
              [-80.963486062656415, 34.66843542660677],
              [-80.963538059624383, 34.668170552958379],
              [-80.963212177090099, 34.66812828921735],
              [-80.963264174747849, 34.667863415688458],
              [-80.962938293596622, 34.667821152106129],
              [-80.962990293013476, 34.667556277777521],
              [-80.962664413223706, 34.66751401345244],
              [-80.962716412239416, 34.667249139261251],
              [-80.962390533811117, 34.667206874193525],
              [-80.962442534607476, 34.666942000104022],
              [-80.961790780788107, 34.666857466698083],
              [-80.961842783299772, 34.666592592869257],
              [-80.96119103396596, 34.666508056192598],
              [-80.961243037102022, 34.666243182642326],
              [-80.960917164520723, 34.666200913561191],
              [-80.960969168324908, 34.665936039229145],
              [-80.960317425130199, 34.665851498715597],
              [-80.960369431740517, 34.665586624626521],
              [-80.96004356221799, 34.665544353176323],
              [-80.960095568427079, 34.665279479224608],
              [-80.959443832419254, 34.665194933053755],
              [-80.959495841455919, 34.664930060246391],
              [-80.959169974970621, 34.664887785525821],
              [-80.959273992974872, 34.664358039245229],
              [-80.958948128876528, 34.664315763923284],
              [-80.959104156953146, 34.663521143403102],
              [-80.959430017974995, 34.663563418301422],
              [-80.959690052538932, 34.662239048735039],
              [-80.959038342176711, 34.662154499448917],
              [-80.959090350573405, 34.66188962592657],
              [-80.958438643605646, 34.661805073387697],
              [-80.958490653695804, 34.661540199224966],
              [-80.957187249005329, 34.66137108499067],
              [-80.957239262861208, 34.661106211371234],
              [-80.956261716748799, 34.660979366150514],
              [-80.95631373334507, 34.660714492933245],
              [-80.955987886338178, 34.660672209566677],
              [-80.956039903623804, 34.660407336469135],
              [-80.955714057978341, 34.660365052360127],
              [-80.955818094683437, 34.659835306365316],
              [-80.955492251403101, 34.659793020753753],
              [-80.955596287724447, 34.659263274973107],
              [-80.955270447943079, 34.659220989643899],
              [-80.95532246660369, 34.658956115961246],
              [-80.954996627092825, 34.658913829907306],
              [-80.955100666569095, 34.658384083643668],
              [-80.954774829444858, 34.65834179698853],
              [-80.954826850239101, 34.658076923506179],
              [-80.954501014476136, 34.658034636108596],
              [-80.954657076828212, 34.657240015974438],
              [-80.954331244477146, 34.65719772811692],
              [-80.954435286031028, 34.656667980640634],
              [-80.954109457157273, 34.656625692164191],
              [-80.954213499417918, 34.656095944884669],
              [-80.954539326262591, 34.656138233979966],
              [-80.95459134530752, 34.655873359724986],
              [-80.95426551950959, 34.655831071672296],
              [-80.954369559754554, 34.655301324264286],
              [-80.954043736321594, 34.655259034709196],
              [-80.954095757521529, 34.65499416155609],
              [-80.95376993544977, 34.654951871258582],
              [-80.953821956226776, 34.654686997341763],
              [-80.953496136628402, 34.654644707185462],
              [-80.953548158094605, 34.654379833388518],
              [-80.952896520844064, 34.654295249823896],
              [-80.952948545115575, 34.654030376270377],
              [-80.952622727463307, 34.653988082862085],
              [-80.952674752423988, 34.653723209428449],
              [-80.952348937223718, 34.653680915260054],
              [-80.952400961782899, 34.653416041964093],
              [-80.952075147943788, 34.653373747053315],
              [-80.952127174282893, 34.653108873859509],
              [-80.951801361804854, 34.65306657820642],
              [-80.95185338774256, 34.652801705150253],
              [-80.951527576625651, 34.652759408754825],
              [-80.951631631703449, 34.652229661924309],
              [-80.951305822972841, 34.652187364927762],
              [-80.951409878735518, 34.651657617393106],
              [-80.951084072412627, 34.651615320696862],
              [-80.951240155521575, 34.65082069967643],
              [-80.950914352588711, 34.650778401619228],
              [-80.951278543316448, 34.648924283420357],
              [-80.951604337983071, 34.648966580506155],
              [-80.951656363025904, 34.648701706635016],
              [-80.951982157003485, 34.648744002695942],
              [-80.9521902474483, 34.647684502863697],
              [-80.951864457571048, 34.647642207367902],
              [-80.951916480912857, 34.647377332489185],
              [-80.951590692396564, 34.647335036251107],
              [-80.951642716406027, 34.647070160591106],
              [-80.951316929250723, 34.647027863610781],
              [-80.951368952880074, 34.646762988989884],
              [-80.95071738269651, 34.646678391742263],
              [-80.950769409130586, 34.646413517364977],
              [-80.950443625033145, 34.646371218016597],
              [-80.950495652134848, 34.646106342858133],
              [-80.950169869398351, 34.646064042767584],
              [-80.95027392466524, 34.645534293570826],
              [-80.949948145383857, 34.645491991960348],
              [-80.950000172437072, 34.645227117038502],
              [-80.94967439453805, 34.645184815587157],
              [-80.949830480028638, 34.644390191065312],
              [-80.949504705540434, 34.644347889154339],
              [-80.949556734368954, 34.644083013511384],
              [-80.949230960151056, 34.644040710875998],
              [-80.949282989689976, 34.643775836254449],
              [-80.948957217902162, 34.643733531957849],
              [-80.949009248130054, 34.643468657456445],
              [-80.948683478793697, 34.643426352399977],
              [-80.948839570493462, 34.642631727390203],
              [-80.948513803498614, 34.642589422793264],
              [-80.948565834411085, 34.642324547588544],
              [-80.948240068755695, 34.642282241348113],
              [-80.948292100378396, 34.642017367164939],
              [-80.94796633717452, 34.64197506016469],
              [-80.948018369464734, 34.641710185200381],
              [-80.947692606531135, 34.641667877475683],
              [-80.947796673244056, 34.641138127748711],
              [-80.947470912696062, 34.641095819423157],
              [-80.947522947118756, 34.640830944660543],
              [-80.947197187931579, 34.640788635592855],
              [-80.947249221952575, 34.640523760968073],
              [-80.946923465216784, 34.640481451140751],
              [-80.946975501017107, 34.640216576618528],
              [-80.94664974455165, 34.640174266066701],
              [-80.946701781019428, 34.639909390763414],
              [-80.946050273348504, 34.639824768158086],
              [-80.946102310439386, 34.639559893134098],
              [-80.945776558121992, 34.63951758019688],
              [-80.945828596992314, 34.63925270527556],
              [-80.94517709541509, 34.639168077033617],
              [-80.945229134908459, 34.638903202391617],
              [-80.944577637815371, 34.638818570881007],
              [-80.944629680112826, 34.638553696483022],
              [-80.943978185322806, 34.638469061739102],
              [-80.944030229333777, 34.638204187602788],
              [-80.943378739027906, 34.638119549590293],
              [-80.943430784730992, 34.637854674814371],
              [-80.94277929781876, 34.637770033550964],
              [-80.94283134525655, 34.637505159938101],
              [-80.942505602762637, 34.637462837230139],
              [-80.942557650889199, 34.637197963737592],
              [-80.941580431812156, 34.637070992487367],
              [-80.941632482655493, 34.636806118496651],
              [-80.940655268586639, 34.636679139737687],
              [-80.940707323258508, 34.636414266132526],
              [-80.940381587303023, 34.636371938548692],
              [-80.940433641572994, 34.636107065081539],
              [-80.939782172698315, 34.636022406645296],
              [-80.939834228681548, 34.635757533439872],
              [-80.939508496852866, 34.635715202569877],
              [-80.939560553524672, 34.635450329484833],
              [-80.939234821987569, 34.635407998791884],
              [-80.939286880438331, 34.635143125809648],
              [-80.938961150240758, 34.635100793473356],
              [-80.93901320938005, 34.634835920611572],
              [-80.938687480543152, 34.634793587533345],
              [-80.938791598772411, 34.634263842047147],
              [-80.938465873411346, 34.634221508350841],
              [-80.938622050741685, 34.633426889495915],
              [-80.938296328790543, 34.633384555340591],
              [-80.938348388965863, 34.633119681875748],
              [-80.938022667284969, 34.633077346996124],
              [-80.938074728148777, 34.632812473651853],
              [-80.937749008919027, 34.632770138012646],
              [-80.937853130618208, 34.632240392462926],
              [-80.937527413773722, 34.632198056223359],
              [-80.93763153724673, 34.631668309953781],
              [-80.937305822787465, 34.631625973113806],
              [-80.937409945853503, 34.631096226159471],
              [-80.937084234869886, 34.631053888701508],
              [-80.937136296388587, 34.630789015793866],
              [-80.936810586744429, 34.630746676692723],
              [-80.936862650041974, 34.630481803888017],
              [-80.935885524061177, 34.630354783546963],
              [-80.935937590096231, 34.630089911145731],
              [-80.935611882776342, 34.630047569713199],
              [-80.935663949478482, 34.629782696531194],
              [-80.935338244609639, 34.629740354339283],
              [-80.935598578200697, 34.628415988823122],
              [-80.935272878768998, 34.628373645553978],
              [-80.935377011901451, 34.627843899484255],
              [-80.935051314854874, 34.62780155561471],
              [-80.935103381941346, 34.627536682690064],
              [-80.934451990926718, 34.627451993485565],
              [-80.93450406079495, 34.627187119904114],
              [-80.933526979878053, 34.627060080348137],
              [-80.933579052483367, 34.626795207170289],
              [-80.931950597195737, 34.626583456400255],
              [-80.932002674586954, 34.626318583909011],
              [-80.931676986426154, 34.626276230872222],
              [-80.93178114115841, 34.625746486127852],
              [-80.932432514808966, 34.625831190734552],
              [-80.932484589076893, 34.625566318056826],
              [-80.932810274836939, 34.625608668902679],
              [-80.932862348813458, 34.625343795143706],
              [-80.933188033885301, 34.625386144964757],
              [-80.933552529771163, 34.623532030771933],
              [-80.933226851869819, 34.623489681941415],
              [-80.933278922066705, 34.623224808033548],
              [-80.93360459894383, 34.623267156722591],
              [-80.93370873737112, 34.622737408544026],
              [-80.933383062542617, 34.622695060138007],
              [-80.933435131731201, 34.622430186167819],
              [-80.933109458263132, 34.622387837020092],
              [-80.933265667953918, 34.62159321449068],
              [-80.932939999006365, 34.621550865768086],
              [-80.933096207633511, 34.620756242592691],
              [-80.932119210871903, 34.620629189713],
              [-80.932171283897986, 34.620364315386269],
              [-80.930542968532833, 34.620152543619383],
              [-80.930595046343825, 34.619887669979555],
              [-80.929943725014226, 34.619802954465477],
              [-80.929995804537555, 34.619538081088088],
              [-80.92934448662227, 34.619453363225745],
              [-80.929396567857793, 34.619188490110737],
              [-80.928745253314446, 34.619103768097602],
              [-80.928797336262051, 34.618838895244984],
              [-80.928471680508252, 34.618796532604641],
              [-80.928523764143932, 34.618531659872801],
              [-80.927546803104917, 34.6184045688628],
              [-80.927598890545937, 34.618139695616094],
              [-80.927273238206467, 34.618097330627691],
              [-80.927325325245164, 34.617832457519398],
              [-80.926999675335068, 34.617790090870599],
              [-80.927051763082844, 34.617525218784529],
              [-80.926726114533196, 34.617482851394179],
              [-80.926830291047168, 34.616953106541857],
              [-80.926504644881973, 34.616910738551567],
              [-80.926556733648155, 34.616645865785344],
              [-80.92623108884321, 34.61660349705344],
              [-80.926283178297268, 34.616338624408058],
              [-80.925631891765605, 34.616253885479722],
              [-80.925683984021845, 34.615989013079357],
              [-80.925358342273881, 34.615946641981424],
              [-80.925410434127627, 34.615681769719487],
              [-80.924759153688697, 34.615597025157555],
              [-80.924811248344568, 34.615332153140692],
              [-80.924485609653502, 34.615289779676679],
              [-80.924641893543395, 34.614495162141935],
              [-80.924316258260717, 34.614452788219673],
              [-80.924472542196455, 34.613658170924161],
              [-80.924146911412322, 34.613615796526133],
              [-80.924251100692146, 34.613086051247599],
              [-80.923925472292169, 34.613043676249767],
              [-80.923977566906629, 34.612778803729974],
              [-80.923651939866986, 34.612736427990605],
              [-80.923756131204058, 34.612206682253642],
              [-80.92343050654874, 34.612164305914519],
              [-80.923534699678939, 34.61163456036104],
              [-80.923209077407705, 34.611592183422019],
              [-80.923365366531144, 34.610797564027934],
              [-80.923039747668014, 34.610755186630826],
              [-80.923196036836998, 34.60996056747662],
              [-80.922870422472059, 34.609918189603896],
              [-80.923026711665429, 34.609123569788487],
              [-80.922701100708437, 34.609081191457605],
              [-80.922857389926179, 34.608286570981178],
              [-80.922531782355946, 34.608244191290915],
              [-80.92263597517794, 34.607714444793622],
              [-80.922310369991678, 34.607672064503575],
              [-80.922414564585523, 34.607142317288933],
              [-80.922088961804221, 34.607099937300461],
              [-80.922193157100764, 34.606570190287123],
              [-80.921867556682358, 34.606527808797722],
              [-80.921971751570268, 34.605998061102021],
              [-80.922297349941047, 34.606040442308164],
              [-80.922453637214289, 34.605245819727067],
              [-80.922128043005017, 34.605203438928285],
              [-80.922180139491573, 34.60493856543038],
              [-80.921854545552222, 34.604896183907748],
              [-80.921906642705224, 34.604631309629795],
              [-80.921581051237084, 34.604588928249669],
              [-80.921685245491744, 34.604059179933188],
              [-80.921359656386272, 34.604016797052125],
              [-80.921411754578102, 34.603751922996253],
              [-80.921086166832509, 34.603709539373938],
              [-80.921138265711861, 34.603444665439213],
              [-80.920812679326332, 34.60340228107561],
              [-80.920916878102602, 34.60287253252703],
              [-80.920591294100873, 34.602830147563822],
              [-80.920695493579629, 34.602300399217022],
              [-80.920369911961501, 34.602258013654136],
              [-80.920526211718354, 34.60146339095553],
              [-80.920200633507648, 34.601421004934792],
              [-80.920304833697713, 34.600891256049685],
              [-80.919979258960538, 34.600848869411927],
              [-80.920083459831915, 34.600319119827468],
              [-80.919757886388297, 34.600276732607561],
              [-80.919809987353858, 34.600011858377137],
              [-80.919484416360149, 34.599969470398598],
              [-80.919588619308712, 34.599439721258847],
              [-80.919263050677429, 34.599397331779457],
              [-80.91936725541828, 34.59886758282434],
              [-80.919627758608726, 34.597543207871759],
              [-80.919302197500031, 34.597500819402512],
              [-80.919354298131864, 34.597235943591464],
              [-80.919028739472964, 34.597193554363692],
              [-80.919132941796278, 34.596663803865503],
              [-80.918807384430835, 34.596621414055605],
              [-80.919015790205918, 34.595561912464376],
              [-80.919341342387369, 34.595604301725011],
              [-80.919393441689252, 34.595339425914013],
              [-80.919718993183295, 34.595381814150166],
              [-80.919771091125511, 34.595116938177291],
              [-80.920096641953307, 34.595159326290322],
              [-80.920200833671004, 34.594629573116578],
              [-80.920526382787941, 34.594671960063465],
              [-80.920630572203905, 34.594142206508167],
              [-80.920956119588894, 34.594184591387609],
              [-80.921008211689625, 34.593919714906157],
              [-80.921333758387362, 34.593962098761139],
              [-80.921385850197268, 34.593697221199001],
              [-80.921060304523124, 34.593654837485687],
              [-80.921164488112723, 34.593125083503026],
              [-80.92083894591164, 34.593082699172797],
              [-80.920891037670231, 34.5928178218509],
              [-80.920239955212523, 34.592733049941522],
              [-80.920292049771732, 34.592468172865431],
              [-80.919966509536167, 34.592425786187633],
              [-80.920018604782769, 34.59216090923298],
              [-80.919693066975668, 34.592118520895291],
              [-80.919745161819634, 34.591853644079578],
              [-80.919094089281884, 34.591768865940644],
              [-80.919146186926469, 34.591503989370786],
              [-80.9188206510849, 34.59146159868564],
              [-80.918872749416721, 34.591196722237235],
              [-80.918547216024677, 34.591154330793572],
              [-80.918703512052133, 34.590359700851501],
              [-80.919029042352861, 34.590402090968794],
              [-80.919081139339966, 34.590137214439906],
              [-80.919406668953471, 34.590179603532782],
              [-80.919510858740239, 34.589649848346241],
              [-80.919836386664173, 34.589692237174354],
              [-80.919940574149408, 34.589162481606564],
              [-80.919615048272235, 34.589120093061752],
              [-80.919667142534053, 34.588855215389465],
              [-80.91901609385603, 34.588770436836462],
              [-80.919068189828323, 34.588505559427361],
              [-80.918742668096243, 34.588463168500382],
              [-80.918794763665971, 34.588198291230405],
              [-80.918469243293401, 34.588155899562288],
              [-80.918521340619122, 34.587891021495174],
              [-80.917870301860304, 34.587806236713163],
              [-80.917922400896515, 34.587541358909355],
              [-80.917596883579151, 34.58749896487663],
              [-80.917648983323573, 34.587234088095713],
              [-80.91732346627586, 34.587191693339378],
              [-80.917375567776105, 34.58692681576138],
              [-80.917050052087902, 34.586884420264042],
              [-80.917102154275355, 34.586619542807782],
              [-80.916776639946647, 34.586577146569461],
              [-80.916672435368696, 34.587106901143777],
              [-80.916346920419329, 34.587064503721869],
              [-80.9162948166028, 34.587329380837282],
              [-80.91596929987648, 34.587286982408422],
              [-80.91591719467938, 34.587551858460671],
              [-80.913638586386242, 34.58725504364687],
              [-80.913690698725432, 34.586990167685364],
              [-80.913365185778957, 34.58694776217763],
              [-80.913417299895102, 34.586682886320325],
              [-80.913091788308094, 34.586640480071637],
              [-80.913143903111234, 34.586375604336112],
              [-80.912492882971776, 34.586290788572867],
              [-80.912544999485164, 34.586025913100698],
              [-80.912219490953532, 34.585983504487487],
              [-80.912271608153873, 34.585718629137112],
              [-80.911946100960861, 34.585676218881545],
              [-80.91205033639919, 34.585146468404155],
              [-80.911724832678672, 34.585104057532149],
              [-80.911776950916618, 34.584839182405211],
              [-80.911451447465893, 34.584796770809682],
              [-80.911503566390778, 34.584531895804488],
              [-80.911178065389265, 34.584489483450625],
              [-80.911230185001074, 34.584224608567155],
              [-80.910579184984712, 34.584139782413892],
              [-80.910631307375425, 34.583874906875216],
              [-80.909980310750598, 34.583790077474895],
              [-80.910032433761572, 34.583525202217189],
              [-80.90970693751126, 34.583482785875475],
              [-80.908404955894298, 34.583313112583909],
              [-80.908352826677685, 34.583577987150079],
              [-80.908027331085904, 34.583535566253502],
              [-80.907975200509782, 34.583800440657953],
              [-80.90764970423119, 34.58375801873698],
              [-80.90770183583048, 34.583493144474332],
              [-80.907376340911341, 34.583450721812525],
              [-80.90742847321836, 34.583185848573059],
              [-80.907102979658831, 34.583143425170455],
              [-80.907155111541883, 34.582878551168719],
              [-80.906504128567917, 34.582793701981885],
              [-80.906556262160876, 34.582528828243774],
              [-80.906230772746525, 34.582486402459615],
              [-80.906282908094994, 34.582221527924752],
              [-80.905631930142235, 34.582136674026835],
              [-80.905684067221472, 34.581871800656884],
              [-80.905358579772738, 34.581829372525867],
              [-80.905410718607527, 34.581564498359164],
              [-80.905085232518388, 34.581522069487356],
              [-80.905137370971005, 34.581257196361122],
              [-80.904486402916802, 34.581172335334728],
              [-80.904538543079269, 34.580907462472247],
              [-80.904213061114191, 34.580865030317696],
              [-80.904265201963312, 34.580600157577003],
              [-80.903939721357816, 34.580557724681718],
              [-80.903991862872658, 34.580292851161587],
              [-80.903666383647547, 34.580250418426871],
              [-80.903770668803773, 34.579720671593293],
              [-80.90344519196114, 34.579678238259618],
              [-80.90349733451275, 34.579413364963429],
              [-80.90317185900868, 34.579370929987796],
              [-80.903276146238468, 34.578841183601945],
              [-80.902950673116891, 34.578798748027424],
              [-80.903002816705325, 34.578533874955127],
              [-80.902677346032903, 34.578491438622564],
              [-80.90278163531525, 34.577961691783372],
              [-80.902456165956394, 34.577919255770567],
              [-80.902560455918206, 34.577389508234155],
              [-80.902234988941643, 34.577347071622583],
              [-80.902391424974823, 34.576552450585716],
              [-80.902065962472491, 34.576510012598519],
              [-80.902170253161756, 34.57598026543068],
              [-80.90184479195203, 34.575937826862102],
              [-80.901949084410376, 34.575408078979983],
              [-80.901623625582843, 34.575365639812503],
              [-80.901780063715663, 34.574571017832746],
              [-80.901454609403942, 34.574528579092366],
              [-80.901558901479078, 34.573998830695295],
              [-80.901233450618264, 34.57395639043763],
              [-80.901285596629236, 34.573691516359915],
              [-80.900960147127819, 34.573649075361651],
              [-80.901012293825133, 34.573384201405901],
              [-80.900686845683012, 34.573341759667102],
              [-80.900791141203854, 34.572812011962704],
              [-80.900465695443856, 34.572769569625166],
              [-80.900517843156834, 34.57250469499246],
              [-80.900192398756104, 34.572462251914267],
              [-80.900244548265903, 34.572197378287726],
              [-80.899919104113934, 34.572154933584891],
              [-80.899971254310216, 34.571890060080491],
              [-80.900296697418511, 34.571932503740136],
              [-80.900348845145032, 34.571667629190031],
              [-80.90002340414911, 34.571625185655023],
              [-80.900127702838645, 34.571095437646036],
              [-80.899802264203899, 34.57105299261103],
              [-80.900063007922697, 34.569728620733578],
              [-80.899737575828013, 34.569686175508281],
              [-80.899894021287537, 34.568891551665956],
              [-80.899568591507816, 34.56884910600116],
              [-80.899829333003453, 34.567524731131321],
              [-80.899503909784329, 34.567482286177665],
              [-80.899660353243718, 34.566687660009954],
              [-80.899334933408255, 34.566645213698244],
              [-80.899439230130582, 34.566115462793398],
              [-80.899113812697735, 34.566073016784429],
              [-80.899218109009453, 34.56554326520061],
              [-80.898892693958246, 34.56550081859308],
              [-80.898944843176949, 34.565235942905019],
              [-80.899049140605015, 34.564706191469952],
              [-80.898398318716573, 34.564621295540611],
              [-80.898450467881759, 34.564356420094654],
              [-80.898125058999042, 34.564313970489074],
              [-80.898177209940002, 34.564049095148242],
              [-80.897851801326851, 34.564006644819457],
              [-80.897956104223809, 34.563476893461392],
              [-80.897630699102663, 34.563434443418181],
              [-80.897682851058732, 34.563169567401047],
              [-80.89735744727578, 34.563127115716085],
              [-80.897409599938754, 34.562862240722509],
              [-80.897084197514801, 34.562819788297148],
              [-80.896758795427274, 34.562777334989441],
              [-80.896706639650333, 34.563042210617631],
              [-80.895079629146394, 34.562829930134598],
              [-80.895236108647993, 34.562035306255929],
              [-80.894910710624259, 34.561992847938171],
              [-80.895015031004007, 34.561463098265158],
              [-80.894689635361814, 34.561420639348896],
              [-80.894741796593436, 34.561155763715043],
              [-80.894416402310227, 34.561113304058502],
              [-80.894468564248783, 34.560848429448377],
              [-80.894143171324558, 34.560805969051486],
              [-80.894195333949142, 34.560541094563689],
              [-80.892242992831683, 34.560286312651698],
              [-80.892295161254708, 34.560021438996124],
              [-80.891969772710112, 34.559978971805791],
              [-80.89202194181901, 34.559714098272721],
              [-80.891696555722902, 34.559671630324914],
              [-80.891800894955495, 34.559141882582708],
              [-80.891475511240714, 34.559099414036581],
              [-80.891527681364295, 34.558834539827529],
              [-80.891202299029231, 34.558792071442468],
              [-80.891254469838742, 34.558527197355872],
              [-80.890278327878761, 34.558399785530284],
              [-80.890330501419015, 34.558134911850154],
              [-80.89000512245417, 34.558092439318699],
              [-80.890057296680283, 34.557827565761045],
              [-80.889731920163939, 34.557785092472066],
              [-80.889784095075825, 34.557520219036938],
              [-80.889133342939388, 34.557435271032091],
              [-80.889185520628232, 34.557170396942865],
              [-80.888209399093981, 34.557042968277123],
              [-80.888157218317161, 34.557307841038913],
              [-80.887506470635813, 34.557222884194573],
              [-80.887454288567156, 34.557487756635702],
              [-80.887128913653882, 34.557445276306069],
              [-80.88707672913678, 34.557710148602816],
              [-80.886751354627208, 34.557667667231797],
              [-80.886646982559228, 34.558197412384132],
              [-80.885670852736908, 34.558069962159749],
              [-80.885618663120525, 34.558334833829917],
              [-80.885293285800742, 34.558292348521015],
              [-80.885188903513281, 34.558822091518586],
              [-80.884863524485382, 34.558779605043462],
              [-80.884811331814277, 34.559044476370914],
              [-80.884485952100647, 34.559001988871429],
              [-80.884381562593489, 34.559531730299398],
              [-80.884056181171715, 34.559489241633564],
              [-80.883795196934003, 34.560813593691968],
              [-80.884445971427837, 34.560898571544655],
              [-80.884341579231148, 34.561428312081524],
              [-80.884666969572805, 34.561470799959999],
              [-80.884562578074267, 34.56200054070257],
              [-80.884887970797536, 34.562043027982831],
              [-80.884835775020946, 34.562307898475254],
              [-80.885161169082579, 34.562350384113934],
              [-80.884900194578663, 34.563674735120983],
              [-80.884574795383855, 34.563632247870814],
              [-80.884522597566402, 34.563897117344155],
              [-80.884197197685793, 34.563854629069667],
              [-80.883936201704032, 34.565178975380192],
              [-80.883610797047538, 34.5651364855132],
              [-80.883558595164786, 34.565401354584495],
              [-80.882907784816823, 34.565316371919465],
              [-80.88285558055216, 34.565581240687003],
              [-80.882530174860804, 34.565538747888965],
              [-80.882477969216254, 34.565803615593495],
              [-80.882803375930152, 34.565846108533584],
              [-80.882751170971289, 34.566110976360392],
              [-80.883076580134059, 34.566153468543121],
              [-80.882972171271945, 34.56668320532313],
              [-80.883622993587778, 34.566768186725497],
              [-80.883466384963, 34.567562791230792],
              [-80.883791800248943, 34.567605281476695],
              [-80.883426375050036, 34.569459355359612],
              [-80.883100952626449, 34.569416865020806],
              [-80.882944333836477, 34.570211466229608],
              [-80.882293482753468, 34.570126481168394],
              [-80.882241273408013, 34.57039134792003],
              [-80.881915847883548, 34.570348853464587],
              [-80.881811426136977, 34.570878586624467],
              [-80.881759213648124, 34.571143452290407],
              [-80.882084643330231, 34.571185947154881],
              [-80.881980221587426, 34.571715679578659],
              [-80.882631084942588, 34.571800666344387],
              [-80.882683293947309, 34.571535800309029],
              [-80.883008725641858, 34.571578291767054],
              [-80.882956517680398, 34.571843158845788],
              [-80.883281950734556, 34.571885649563434],
              [-80.883229743438321, 34.572150515863108],
              [-80.883555177872637, 34.572193006741713],
              [-80.883398557031384, 34.572987605947759],
              [-80.883723994870479, 34.573030096370054],
              [-80.883671788272565, 34.573294962874435],
              [-80.883997227450607, 34.573337451655014],
              [-80.883945022628325, 34.573602318264406],
              [-80.884270463165905, 34.573644806304564],
              [-80.884218257939807, 34.573909673053244],
              [-80.884543699836925, 34.573952160352995],
              [-80.884439291488974, 34.574481893156396],
              [-80.884764735789105, 34.57452438075908],
              [-80.884712532667265, 34.574789246813829],
              [-80.885037978306258, 34.574831732774754],
              [-80.8849335720287, 34.575361466026891],
              [-80.884881367829493, 34.575626332181088],
              [-80.884829163293489, 34.575891198315304],
              [-80.885154614450258, 34.575933683944797],
              [-80.885102410600268, 34.576198550201127],
              [-80.885050207503198, 34.576463416420424],
              [-80.885375661042403, 34.576505901451483],
              [-80.885701114918291, 34.576548385599999],
              [-80.885648912440388, 34.576813252100528],
              [-80.885974367675885, 34.576855735508559],
              [-80.885922165884139, 34.577120602131174],
              [-80.88624762247926, 34.577163084798741],
              [-80.886143220999926, 34.577692817349778],
              [-80.886091020289697, 34.577957683136191],
              [-80.886038818173645, 34.578222549821049],
              [-80.885934413979314, 34.57875228131121],
              [-80.885608951246994, 34.578709797791618],
              [-80.885452338816151, 34.579504394459605],
              [-80.885400133614908, 34.57976925908001],
              [-80.885074667148416, 34.579726775011274],
              [-80.885022460587535, 34.579991639469739],
              [-80.884696992324606, 34.579949153492258],
              [-80.884644784424594, 34.580214018690008],
              [-80.88399384795315, 34.580129043786386],
              [-80.883668380767574, 34.580086555002268],
              [-80.883511744709295, 34.580881147411851],
              [-80.883186274791768, 34.580838657319177],
              [-80.883081846666343, 34.581368385514295],
              [-80.88340731971941, 34.581410875873935],
              [-80.883355106708905, 34.581675739624217],
              [-80.883680581121794, 34.581718229243407],
              [-80.883628368797275, 34.581983093115696],
              [-80.886232185459164, 34.582322977613806],
              [-80.886179980981609, 34.582587842602067],
              [-80.886505460472023, 34.582630324562352],
              [-80.886453257770327, 34.582895189655524],
              [-80.886778738599816, 34.582937669973923],
              [-80.886622130432443, 34.583732264674637],
              [-80.886947614667847, 34.583774744536463],
              [-80.886843209214646, 34.584304474225867],
              [-80.887168696943363, 34.584346954373267],
              [-80.887116494179381, 34.584611818887801],
              [-80.887441983247243, 34.58465429739325],
              [-80.887389781169389, 34.584919162029593],
              [-80.887715271617822, 34.584961640695788],
              [-80.887610869587718, 34.585491370175077],
              [-80.887936362398349, 34.585533847341296],
              [-80.887831961046402, 34.586063576122768],
              [-80.887506466189635, 34.586021098672603],
              [-80.887454264540594, 34.586285963333317],
              [-80.887779760420457, 34.586328440925385],
              [-80.887727558347208, 34.586593304823765],
              [-80.888053056697544, 34.586635782559483],
              [-80.888000855310565, 34.586900646579728],
              [-80.888326353910159, 34.586943122690592],
              [-80.88806535032856, 34.588267444049173],
              [-80.887739846613385, 34.58822496722847],
              [-80.887687643205368, 34.588489831127845],
              [-80.887362138783061, 34.588447352381095],
              [-80.887309934015079, 34.588712216118338],
              [-80.887635440529635, 34.588754694088458],
              [-80.887583236447938, 34.589019557947402],
              [-80.887908744322601, 34.589062035176944],
              [-80.887856540927189, 34.589326899157676],
              [-80.88818205016176, 34.589369375646484],
              [-80.888129848521928, 34.589634238830492],
              [-80.889431891851956, 34.589804137445711],
              [-80.889379693967911, 34.59006900117744],
              [-80.88970520693303, 34.590111473536872],
              [-80.889653009756131, 34.590376338291691],
              [-80.889978524081144, 34.590418809910481],
              [-80.889926327569881, 34.59068367388565],
              [-80.890251843255015, 34.590726144763799],
              [-80.890199647430165, 34.590991008860691],
              [-80.890525164475207, 34.591033478998057],
              [-80.890420773840873, 34.591563206513797],
              [-80.890746293269174, 34.591605676052389],
              [-80.890694098480367, 34.591870540372561],
              [-80.89101962035862, 34.591913009153217],
              [-80.890967426256381, 34.592177873595027],
              [-80.891618471959049, 34.592262807908092],
              [-80.891566279566433, 34.592527672613528],
              [-80.891891804501569, 34.592570139029995],
              [-80.891839612774746, 34.592835002955866],
              [-80.892165137980015, 34.59287746864868],
              [-80.892112948029521, 34.593142332679022],
              [-80.892438474594826, 34.593184797631139],
              [-80.892386285330957, 34.593449661783147],
              [-80.892229712248678, 34.594244254169048],
              [-80.892555244333892, 34.594286718788943],
              [-80.89245086262099, 34.594816446327357],
              [-80.89212532850992, 34.594773982324952],
              [-80.892073136114547, 34.595038845471173],
              [-80.891747601316936, 34.59499638044413],
              [-80.891695407561414, 34.595261243428055],
              [-80.891044336909189, 34.595176309540655],
              [-80.890992139701098, 34.595441173138937],
              [-80.891317676961719, 34.595483640198161],
              [-80.891161088524797, 34.596278229460005],
              [-80.890835549284105, 34.5962357619579],
              [-80.890783351797836, 34.596500624556477],
              [-80.890457810759884, 34.59645815514547],
              [-80.889806730805248, 34.59637321455957],
              [-80.889754529912182, 34.596638076712047],
              [-80.889650126025273, 34.597167800973033],
              [-80.889324582927813, 34.597125328487998],
              [-80.888999041257208, 34.597082855103231],
              [-80.888946836216746, 34.597347716927871],
              [-80.888621293880391, 34.597305243419669],
              [-80.888516880721426, 34.597834965822692],
              [-80.887865791834486, 34.597750013804777],
              [-80.887813582713221, 34.598014875142489],
              [-80.887488037206836, 34.59797239767601],
              [-80.887435826725294, 34.598237258851469],
              [-80.88711028162237, 34.598194780343164],
              [-80.887058069759803, 34.598459640454855],
              [-80.886732522901141, 34.598417161840317],
              [-80.886784735766355, 34.598152300969168],
              [-80.886133645065144, 34.598067339573099],
              [-80.886081430153197, 34.598332200160328],
              [-80.88575588537465, 34.598289717979071],
              [-80.885808101309962, 34.598024857533836],
              [-80.885157012630373, 34.597939890841296],
              [-80.885209231386142, 34.597675031543822],
              [-80.884883688575187, 34.597632547015536],
              [-80.884988125991015, 34.597102826857885],
              [-80.885313665665308, 34.597145311119512],
              [-80.885365882299496, 34.596880450876725],
              [-80.885691422377391, 34.596922934096462],
              [-80.885639206766541, 34.597187794481215],
              [-80.885964747114727, 34.597230276977299],
              [-80.88591253219036, 34.597495137483591],
              [-80.886889160506612, 34.597622580067139],
              [-80.886941372361036, 34.597357719134934],
              [-80.887266914056966, 34.597400198100125],
              [-80.887319124551013, 34.597135337005533],
              [-80.887644666650502, 34.597177814928941],
              [-80.887696875784385, 34.59691295367201],
              [-80.888022416107432, 34.596955430587826],
              [-80.888074623881081, 34.596690569168679],
              [-80.888179037327589, 34.596160846286452],
              [-80.888231244090463, 34.595895984806248],
              [-80.887905707860739, 34.595853508458269],
              [-80.887957914199433, 34.595588646215489],
              [-80.887632379329915, 34.595546169126827],
              [-80.887580170877939, 34.595811031244722],
              [-80.886929100102961, 34.595726074135207],
              [-80.886876889246722, 34.59599093504751],
              [-80.886551353341375, 34.595948455026708],
              [-80.886225816682796, 34.595905974140329],
              [-80.886173603443098, 34.596170834748364],
              [-80.885196995689242, 34.596043386315607],
              [-80.885249211998854, 34.595778526133486],
              [-80.883947076026857, 34.595608582215476],
              [-80.883790412738094, 34.596403161915575],
              [-80.884115949017868, 34.596445649424034],
              [-80.884011506809983, 34.596975368811137],
              [-80.884337046563346, 34.59701785570374],
              [-80.884232605033475, 34.597547574392024],
              [-80.884023717929935, 34.598607011524074],
              [-80.88434926418104, 34.598649499287205],
              [-80.884297042030624, 34.598914358219183],
              [-80.883971494776844, 34.598871871215373],
              [-80.883762600891941, 34.59993130613676],
              [-80.883710377123165, 34.600196164807478],
              [-80.883059272302788, 34.60011118674889],
              [-80.883007046129521, 34.600376044213917],
              [-80.882681493746603, 34.600333553709852],
              [-80.882733720922488, 34.60006869548554],
              [-80.880780419822045, 34.599813733874349],
              [-80.880675950672455, 34.600343448632735],
              [-80.880350399604836, 34.600300950906096],
              [-80.880245925970115, 34.600830665315733],
              [-80.879920373192704, 34.600788166422333],
              [-80.879815897232206, 34.601317879547771],
              [-80.879490341675847, 34.60127538040588],
              [-80.8793858612094, 34.601805092281062],
              [-80.879060305033406, 34.601762591955449],
              [-80.878955821171672, 34.602292303464672],
              [-80.878630263285885, 34.602249801972071],
              [-80.878578019280909, 34.602514657562487],
              [-80.87825246068833, 34.602472154143832],
              [-80.878200215322792, 34.602737009571698],
              [-80.877874654974406, 34.602694506046554],
              [-80.877822409338364, 34.602959361294843],
              [-80.877496848283187, 34.602916855843638],
              [-80.877444600196384, 34.603181710946338],
              [-80.877119039545036, 34.60313920445325],
              [-80.877066790097729, 34.603404059393462],
              [-80.87641566560022, 34.603319044410284],
              [-80.876363413768772, 34.60358389904583],
              [-80.874410045478257, 34.603328831104697],
              [-80.874357786079088, 34.603593684884466],
              [-80.872729983858818, 34.603381102670859],
              [-80.872468657246714, 34.60470536498854],
              [-80.872143092039238, 34.604662845017032],
              [-80.872038556534022, 34.605192548801675],
              [-80.871712989617009, 34.605150027663214],
              [-80.871660720334944, 34.605414879382657],
              [-80.871335152731803, 34.605372357219295],
              [-80.871282882089076, 34.605637208776002],
              [-80.870631746937917, 34.605552161499737],
              [-80.870422651674716, 34.606611564598431],
              [-80.870097079986991, 34.60656903997743],
              [-80.869940251623575, 34.607363590311017],
              [-80.869614676112604, 34.607321064397979],
              [-80.869562398656598, 34.607585914320182],
              [-80.867283377669338, 34.607288200821294],
              [-80.867126518513828, 34.608082747530467],
              [-80.866800942677727, 34.6080402130682],
              [-80.866748654594247, 34.608305061787767],
              [-80.867074232543871, 34.608347596375253],
              [-80.866865080888189, 34.609406989813003],
              [-80.866539498844588, 34.609364454657033],
              [-80.866487209053247, 34.60962930237195],
              [-80.866161626323631, 34.609586766191015],
              [-80.866109335191894, 34.609851614644427],
              [-80.865783752866292, 34.609809077421616],
              [-80.865731459262918, 34.610073924827894],
              [-80.865080292487193, 34.609988847466404],
              [-80.86502799758938, 34.610253694550764],
              [-80.864051245230883, 34.610126070584919],
              [-80.864103544289264, 34.609861223910073],
              [-80.862801217727423, 34.609691047114858],
              [-80.862748914574965, 34.609955893220985],
              [-80.86242333357761, 34.609913346885612],
              [-80.862371029064221, 34.610178192828975],
              [-80.86204544629075, 34.610135645485514],
              [-80.861993140416232, 34.610400491265906],
              [-80.861667558047003, 34.610357942880569],
              [-80.861615250811425, 34.610622788498127],
              [-80.860964082837938, 34.610537687927099],
              [-80.860911773217865, 34.610802533239493],
              [-80.859935021799075, 34.610674874867627],
              [-80.859882707680754, 34.610939719749759],
              [-80.859673451060686, 34.611999097217293],
              [-80.859999040017058, 34.612041651896483],
              [-80.859946726303903, 34.61230649589875],
              [-80.85962113634443, 34.612263941978703],
              [-80.859516504767896, 34.612793629653531],
              [-80.859190913099013, 34.612751074566255],
              [-80.859086278124835, 34.613280761873668],
              [-80.858435090595236, 34.613195647597351],
              [-80.858382771099656, 34.613460490926975],
              [-80.857731582873654, 34.613375372834859],
              [-80.857679259903179, 34.613640215876167],
              [-80.857353665808063, 34.613597654904488],
              [-80.857301341476287, 34.613862497782875],
              [-80.856975746695326, 34.613819935786182],
              [-80.856923421002293, 34.614084778501528],
              [-80.856272229336597, 34.613999652493249],
              [-80.856219901238461, 34.614264494002015],
              [-80.855568708876547, 34.614179364177964],
              [-80.855516378413981, 34.614444206282833],
              [-80.855190781160687, 34.614401639461988],
              [-80.855138449336835, 34.614666481403788],
              [-80.854487254886834, 34.61458134481235],
              [-80.854434920678131, 34.614846186448887],
              [-80.854109322391011, 34.614803616694985],
              [-80.854056986800614, 34.615068457267121],
              [-80.854382585020957, 34.615111027179999],
              [-80.854330250116362, 34.61537586787351],
              [-80.854655850788262, 34.615418437028936],
              [-80.854603515479127, 34.615683277860597],
              [-80.855254719862998, 34.615768412905929],
              [-80.855202386263329, 34.616033254001273],
              [-80.855527989995679, 34.616075820792531],
              [-80.855475658171997, 34.616340661992382],
              [-80.855150053416054, 34.616298095058916],
              [-80.855097720231029, 34.616562936095598],
              [-80.854772114789355, 34.616520368137046],
              [-80.854719780242974, 34.616785209010715],
              [-80.854394174115384, 34.616742640027027],
              [-80.854341837117445, 34.617007480754367],
              [-80.854016230303969, 34.616964910745658],
              [-80.853963893034901, 34.617229751293024],
              [-80.853638285535695, 34.617187180259123],
              [-80.853585945814885, 34.617452020660309],
              [-80.853260337629891, 34.61740944860118],
              [-80.853207996547752, 34.617674288839218],
              [-80.852882387676956, 34.617631715755032],
              [-80.852777703521809, 34.618161394965703],
              [-80.853103314419471, 34.618203967433018],
              [-80.853050972303507, 34.618468806707007],
              [-80.85337658458289, 34.618511379335004],
              [-80.853324244243012, 34.61877621871345],
              [-80.853649857883937, 34.618818790600805],
              [-80.853597517139505, 34.619083630117359],
              [-80.853923133211893, 34.619126200345818],
              [-80.853870793153305, 34.619391039983739],
              [-80.854196410607443, 34.619433610372866],
              [-80.854091732594156, 34.619963288952107],
              [-80.854417351343145, 34.620005858759669],
              [-80.85426033539558, 34.620800376438979],
              [-80.853934712505719, 34.620757807122992],
              [-80.853830030003465, 34.621287484608153],
              [-80.854155654961289, 34.621330055110043],
              [-80.853893950072646, 34.622654248231932],
              [-80.852917059807623, 34.622526533763747],
              [-80.852864714313185, 34.622791372085473],
              [-80.852539084226706, 34.622748798081432],
              [-80.852486737370569, 34.623013636239932],
              [-80.852161106618567, 34.622971062112065],
              [-80.85200406091117, 34.623765574231911],
              [-80.851678426315146, 34.623722997909766],
              [-80.85157372544667, 34.624252672567167],
              [-80.851521374495363, 34.624517509413565],
              [-80.851847011072294, 34.624560086179187],
              [-80.851794660806945, 34.624824923146875],
              [-80.852120299815567, 34.624867498253586],
              [-80.852015600318623, 34.625397172410132],
              [-80.852341241712736, 34.625439746918346],
              [-80.852288891390785, 34.625704584124108],
              [-80.852614534146582, 34.625747157891581],
              [-80.852352787998825, 34.627071342428366],
              [-80.852027140103601, 34.627028767048394],
              [-80.851922436137002, 34.627558440267556],
              [-80.852248087149945, 34.627601015013845],
              [-80.852143383859016, 34.628130687531595],
              [-80.852469037257592, 34.628173261679329],
              [-80.85241668667426, 34.62843809804037],
              [-80.852742340344207, 34.628480671464132],
              [-80.852689990446933, 34.628745507946249],
              [-80.853015646569077, 34.628788080612388],
              [-80.852858596810435, 34.629582589474033],
              [-80.852532938707029, 34.629540016364388],
              [-80.852428234740046, 34.630069687352368],
              [-80.852102573836348, 34.630027113092069],
              [-80.85205022086717, 34.630291948403681],
              [-80.851724559277514, 34.630249373118097],
              [-80.851619849187145, 34.630779043410136],
              [-80.85129418588761, 34.630736466957018],
              [-80.851241829856832, 34.63100130192057],
              [-80.85091616587134, 34.630958724442195],
              [-80.850863807388137, 34.631223559259197],
              [-80.850538142696365, 34.631180979854172],
              [-80.850381063237748, 34.631975483734365],
              [-80.850055395812163, 34.631932903019539],
              [-80.850003033929156, 34.632197737467223],
              [-80.849677365837962, 34.632155156628414],
              [-80.849572640059947, 34.632684823356072],
              [-80.849246969168405, 34.632642241366504],
              [-80.849142238920265, 34.633171908642858],
              [-80.848816567389079, 34.633129324567584],
              [-80.848711833740893, 34.633658991474483],
              [-80.848386159409259, 34.633616406248336],
              [-80.848333791043942, 34.633881239076985],
              [-80.848008117137184, 34.633838653710157],
              [-80.848060486526464, 34.633573821023766],
              [-80.847734812891233, 34.633531234932875],
              [-80.847787182946036, 34.633266401466244],
              [-80.847461511763299, 34.633223814617772],
              [-80.847513882524396, 34.632958982173406],
              [-80.846536872965558, 34.632831215871796],
              [-80.846589246440104, 34.632566382931657],
              [-80.845937911192749, 34.632481200954899],
              [-80.845990286397353, 34.632216369179396],
              [-80.845664620294571, 34.632173776558062],
              [-80.84561224408634, 34.632438609092603],
              [-80.845286576207286, 34.632396015462639],
              [-80.845129441351375, 34.633190510725953],
              [-80.84480377073865, 34.633147915786061],
              [-80.844646628657131, 34.633942409546577],
              [-80.844320956401205, 34.63389981328001],
              [-80.844268573643603, 34.634164644354207],
              [-80.843942899611378, 34.634122047079074],
              [-80.843890515491779, 34.634386877989705],
              [-80.843564841884501, 34.634344280573757],
              [-80.843512456382442, 34.634609110419497],
              [-80.843186780978627, 34.634566511093581],
              [-80.843082006932534, 34.635096171337871],
              [-80.842872454761803, 34.636155490669459],
              [-80.843198136309368, 34.636198090849199],
              [-80.843093359142259, 34.636727750237682],
              [-80.843419044166708, 34.636770349802219],
              [-80.843366656624838, 34.637035178697069],
              [-80.843692341920871, 34.637077777537534],
              [-80.843639955085195, 34.637342607454677],
              [-80.843965641723173, 34.637385204653],
              [-80.843913255573241, 34.637650034691063],
              [-80.84423894357343, 34.637692631148553],
              [-80.844134173377498, 34.638222290527082],
              [-80.844459863784195, 34.63826488728737],
              [-80.844302707380152, 34.639059376196201],
              [-80.84397701390138, 34.639016779009062],
              [-80.843924626726135, 34.639281608159862],
              [-80.843598931491101, 34.639239010865367],
              [-80.843336984311065, 34.640563154702122],
              [-80.843011284293766, 34.640520555813012],
              [-80.842958893020992, 34.640785383649423],
              [-80.84263319231782, 34.640742783734879],
              [-80.842528405638944, 34.641272439976802],
              [-80.842202703205558, 34.641229837993144],
              [-80.842150308890012, 34.641494666382016],
              [-80.841824605770839, 34.641452063372888],
              [-80.841719812966033, 34.641981718916959],
              [-80.841394107046213, 34.641939114756873],
              [-80.841341709647423, 34.64220394189531],
              [-80.841016003061981, 34.642161337611036],
              [-80.840963604300882, 34.642426164585785],
              [-80.840637897009358, 34.642383558374675],
              [-80.840585496885765, 34.642648385185588],
              [-80.83993408238014, 34.642563170713792],
              [-80.839829274840426, 34.643092823735486],
              [-80.839503566582096, 34.643050214431199],
              [-80.839346349642511, 34.643844692474147],
              [-80.839020637559344, 34.643802081876373],
              [-80.838968230218811, 34.644066908005833],
              [-80.838642518540453, 34.64402429636587],
              [-80.838537699685119, 34.644553947390868],
              [-80.838485290285206, 34.644818772412108],
              [-80.83913672228627, 34.644903994728104],
              [-80.839084314596263, 34.645168820012444],
              [-80.839410032138602, 34.64521143043865],
              [-80.83935762513444, 34.645476255843803],
              [-80.840009062151424, 34.645561472545253],
              [-80.839956657948065, 34.645826298196788],
              [-80.839904252315634, 34.646091123843426],
              [-80.83957852995367, 34.6460485156747],
              [-80.839211673802936, 34.647902289996146],
              [-80.838885944589478, 34.647859679046526],
              [-80.838833534181575, 34.648124503334671],
              [-80.838507805393036, 34.648081892244228],
              [-80.838455392531912, 34.648346716385113],
              [-80.838129663057487, 34.648304104269123],
              [-80.838077248833727, 34.648568928245993],
              [-80.837751518653249, 34.648526314203096],
              [-80.83769910306674, 34.648791138016122],
              [-80.83764668823251, 34.64905596179085],
              [-80.837972421532015, 34.649098575200362],
              [-80.837920006293047, 34.64936339911246],
              [-80.83824574095523, 34.649406011781089],
              [-80.83819332749276, 34.649670835797124],
              [-80.838519062426911, 34.649713447741526],
              [-80.838466649650442, 34.649978271878396],
              [-80.838792387037984, 34.650020883065132],
              [-80.838739974927122, 34.650285706421222],
              [-80.83906571369765, 34.650328317768377],
              [-80.839013302272718, 34.65059314124521],
              [-80.83933904238566, 34.650635750950087],
              [-80.839286630556018, 34.650900574564275],
              [-80.839181808062847, 34.65143022169417],
              [-80.838530320938773, 34.651345000563929],
              [-80.838477907135925, 34.651609823811775],
              [-80.838152163602572, 34.651567211771081],
              [-80.83804733182204, 34.65209685703244],
              [-80.837721585487699, 34.652054243840418],
              [-80.83766916862092, 34.652319066738571],
              [-80.837343421580343, 34.652276451619677],
              [-80.837186163674872, 34.653070918889007],
              [-80.83751191376831, 34.653113533533535],
              [-80.837407075877991, 34.653643178249787],
              [-80.837732827267885, 34.653685792312459],
              [-80.837627990052368, 34.654215436325117],
              [-80.837953744940421, 34.65425805067369],
              [-80.837901327394732, 34.654522872781449],
              [-80.838227083625441, 34.654565485487701],
              [-80.838174665675041, 34.654830307732723],
              [-80.838500423268613, 34.654872919697993],
              [-80.83844800707466, 34.655137741145545],
              [-80.838773766051389, 34.655180353271163],
              [-80.838721349452655, 34.655445174855906],
              [-80.83904710977194, 34.655487785339133],
              [-80.838889863240553, 34.656282252159244],
              [-80.839215628062377, 34.656324862169399],
              [-80.839163212158908, 34.656589683951779],
              [-80.838837447403407, 34.656547073782576],
              [-80.838575359868457, 34.657871181622546],
              [-80.838249589238202, 34.65782856987515],
              [-80.838197170345879, 34.658093391225364],
              [-80.838522942000623, 34.658136003115054],
              [-80.838208423261804, 34.659724928974839],
              [-80.837998736929023, 34.660784210647321],
              [-80.837672953958347, 34.660741598252464],
              [-80.837620531302349, 34.661006417559719],
              [-80.836968965398043, 34.660921188917278],
              [-80.836916539263484, 34.66118600793471],
              [-80.836264972644031, 34.661100774572702],
              [-80.836212544142015, 34.661365594184865],
              [-80.835886759770048, 34.661322976044801],
              [-80.835834329905012, 34.661587795492807],
              [-80.834856977928439, 34.661459935311882],
              [-80.834804543540159, 34.661724753426306],
              [-80.83415297483586, 34.661639508609582],
              [-80.834100539170905, 34.661904327302111],
              [-80.833774753736478, 34.661861702533209],
              [-80.833722316708275, 34.662126521061488],
              [-80.833396530608042, 34.662083896168284],
              [-80.833344092196626, 34.662348713630927],
              [-80.833018305410434, 34.66230608771194],
              [-80.8331231850146, 34.661776452131441],
              [-80.832797401706998, 34.661733825597132],
              [-80.832902282005819, 34.661204190213766],
              [-80.832576501065759, 34.661161562179466],
              [-80.832681383150074, 34.660631926976492],
              [-80.832355603526963, 34.660589299261495],
              [-80.832408045075695, 34.660324481318895],
              [-80.83208226788642, 34.660281851944916],
              [-80.832187152016886, 34.659752216278761],
              [-80.831535602792442, 34.659666957252888],
              [-80.831483158847519, 34.659931774812165],
              [-80.830831608908454, 34.659846511066768],
              [-80.830779162575936, 34.660111328319438],
              [-80.830453387079828, 34.660068694528647],
              [-80.830400939384148, 34.660333511617033],
              [-80.82974938737901, 34.660248242001927],
              [-80.829696936204869, 34.660513058800205],
              [-80.828719607653639, 34.660385146874546],
              [-80.828614699233114, 34.660914779534536],
              [-80.828288921671074, 34.660872139873952],
              [-80.828236466473726, 34.6611369560204],
              [-80.827910688225899, 34.661094315334111],
              [-80.827858230574407, 34.661359131332851],
              [-80.827206673045978, 34.661273847025349],
              [-80.82715421300675, 34.661538662717362],
              [-80.826176875110193, 34.661410730121567],
              [-80.826229338222902, 34.661145914856789],
              [-80.825903560982965, 34.661103268727913],
              [-80.825956024801386, 34.660838454485017],
              [-80.822698282370339, 34.660411946019387],
              [-80.822645807236412, 34.660676759755823],
              [-80.821994260528712, 34.660591446279597],
              [-80.8218893062175, 34.661121072199002],
              [-80.821563531333013, 34.661078414301642],
              [-80.821458572526751, 34.661608039864859],
              [-80.821132795932201, 34.661565380799232],
              [-80.820975353188729, 34.662359818084902],
              [-80.820649572748763, 34.662317156823775],
              [-80.82059708976179, 34.662581968771036],
              [-80.820271309747099, 34.662539307368753],
              [-80.820218825396665, 34.662804119151595],
              [-80.819893044696485, 34.662761456723537],
              [-80.819840558982676, 34.663026268341923],
              [-80.819514776505955, 34.662983604904568],
              [-80.819462289408847, 34.663248415457161],
              [-80.819136507337404, 34.663205750977411],
              [-80.818810724514009, 34.663163085630757],
              [-80.818863214730968, 34.662898274445361],
              [-80.818537434361858, 34.662855608341381],
              [-80.818589924173565, 34.662590797292907],
              [-80.818264145147864, 34.662548129546714],
              [-80.818316635645118, 34.662283318618755],
              [-80.817013527069477, 34.662112641173117],
              [-80.816961031383542, 34.662377451547627],
              [-80.815983700447475, 34.662249434211397],
              [-80.815931201349329, 34.662514244136311],
              [-80.81560542401094, 34.66247156918083],
              [-80.815552923549262, 34.662736378941318],
              [-80.815227145545265, 34.662693703861301],
              [-80.815174643700303, 34.662958512555953],
              [-80.814523086640477, 34.662873158559492],
              [-80.814470582427546, 34.663137967848378],
              [-80.814144802280865, 34.66309528894849],
              [-80.814092296704459, 34.663360098072857],
              [-80.813766516963241, 34.663317418130511],
              [-80.813714010023247, 34.663582227090444],
              [-80.81338822850573, 34.663539546138701],
              [-80.813335720182408, 34.663804354032742],
              [-80.813009939090279, 34.663761672939962],
              [-80.812358376792488, 34.663676306318195],
              [-80.81241088928013, 34.663411498835323],
              [-80.811759330387488, 34.66332612896521],
              [-80.811811844564929, 34.663061320844072],
              [-80.810834513478781, 34.662933259816789],
              [-80.810781996227959, 34.663198067510265],
              [-80.810456218852977, 34.663155378591959],
              [-80.810403700238723, 34.663420186120881],
              [-80.809752144457235, 34.663334805349088],
              [-80.809699623435108, 34.663599611669426],
              [-80.809373845028617, 34.663556919815967],
              [-80.809321322662825, 34.663821726873159],
              [-80.808995542460181, 34.663779033108845],
              [-80.80894301873083, 34.664043840001433],
              [-80.808617238953758, 34.66400114609614],
              [-80.808564713840923, 34.664265951922708],
              [-80.808238933378576, 34.664223256991555],
              [-80.80818640581127, 34.664488062669939],
              [-80.807534843855194, 34.664402669872281],
              [-80.807482313899854, 34.664667475243533],
              [-80.80715653240614, 34.664624777377121],
              [-80.807104001087069, 34.664889582583697],
              [-80.806778218908121, 34.664846883691339],
              [-80.806725686225505, 34.665111688733298],
              [-80.806399903341458, 34.665068987913642],
              [-80.806347369295196, 34.665333792790854],
              [-80.806021585745768, 34.665291091846697],
              [-80.805044236022056, 34.665162982828818],
              [-80.805096774166159, 34.664898178522002],
              [-80.804770991907162, 34.6648554742033],
              [-80.804823530736357, 34.664590670017013],
              [-80.804497750912091, 34.664547964039883],
              [-80.804445211058479, 34.664812768083543],
              [-80.804119430568875, 34.664770061981834],
              [-80.804171971446905, 34.664505258080702],
              [-80.803520413514477, 34.664419842611203],
              [-80.803572956102144, 34.664155038973178],
              [-80.80292140157556, 34.664069620255574],
              [-80.802973945852941, 34.663804815979482],
              [-80.802322394732244, 34.663719394013697],
              [-80.80226984838626, 34.663984197103183],
              [-80.80161829657402, 34.663898771318898],
              [-80.801565746749105, 34.664163574117453],
              [-80.801239970337406, 34.664120860208357],
              [-80.801187419148874, 34.664385662842207],
              [-80.800535865255071, 34.664300230286052],
              [-80.800483312769359, 34.664565032596201],
              [-80.800157535307065, 34.66452231485048],
              [-80.80010498036674, 34.664787117012182],
              [-80.799453424431192, 34.66470167948691],
              [-80.799348309434833, 34.665231283173526],
              [-80.799022529917437, 34.66518856234989],
              [-80.798969970866082, 34.665453363116079],
              [-80.798917410404229, 34.665718164777871],
              [-80.797940067761658, 34.665589997052223],
              [-80.797992630205798, 34.665325195834818],
              [-80.796363736587679, 34.665111564519634],
              [-80.796416304905208, 34.664846763977209],
              [-80.796090528442377, 34.664804035203652],
              [-80.796143096354029, 34.664539234798049],
              [-80.795165773146323, 34.664411042687838],
              [-80.795218343791504, 34.664146242688247],
              [-80.794566799109035, 34.664060777745753],
              [-80.79461937146354, 34.663795978009475],
              [-80.794293601201261, 34.663753244347824],
              [-80.794346174220806, 34.663488443830822],
              [-80.794020405322314, 34.663445709428586],
              [-80.794072980137415, 34.66318090991728],
              [-80.793747211492089, 34.663138173889415],
              [-80.79379978699194, 34.662873374498758],
              [-80.793474020801241, 34.662830637714016],
              [-80.793526595895059, 34.662565838460388],
              [-80.793200831068134, 34.662523100935076],
              [-80.793253407937726, 34.662258301785748],
              [-80.792276117495447, 34.662130085255953],
              [-80.792223537572823, 34.662394884878445],
              [-80.790920484682346, 34.662223917164063],
              [-80.790973068702129, 34.66195911811247],
              [-80.790321546868256, 34.661873628331769],
              [-80.79037413261662, 34.661608830444898],
              [-80.790048373233205, 34.661566084372431],
              [-80.789943198277285, 34.662095680756103],
              [-80.789617437204598, 34.662052934416103],
              [-80.78956484763782, 34.662317731989589],
              [-80.788913324442831, 34.662232235473084],
              [-80.78886073248799, 34.662497032738976],
              [-80.788534970365802, 34.662454282562386],
              [-80.788482377066813, 34.662719080564834],
              [-80.787830850701781, 34.662633577292581],
              [-80.787883446069031, 34.66236878047701],
              [-80.786906163777459, 34.662240519348082],
              [-80.786958761858088, 34.661975722037418],
              [-80.784026942521237, 34.661590891338712],
              [-80.783974334131813, 34.661855687380516],
              [-80.783322821486948, 34.661770158728913],
              [-80.783270210709361, 34.662034954462996],
              [-80.782944453317, 34.661992188226947],
              [-80.782891841175712, 34.662256983796198],
              [-80.781588810945721, 34.662085911251062],
              [-80.781536194367732, 34.662350706226981],
              [-80.781210436625628, 34.6623079352891],
              [-80.781157818683724, 34.662572730100095],
              [-80.780832060257069, 34.662529958136155],
              [-80.780779439860439, 34.662794752798348],
              [-80.780127921997519, 34.662709206836489],
              [-80.780075300284054, 34.662974000273401],
              [-80.779749540828249, 34.662931225374123],
              [-80.779696916659844, 34.663196018662276],
              [-80.778719637297669, 34.663067690038936],
              [-80.77877226451902, 34.662802896277967],
              [-80.778446506441867, 34.662760118746945],
              [-80.778499133276284, 34.662495326024214],
              [-80.777847621241605, 34.662409766779419],
              [-80.777900249784565, 34.662144974320249],
              [-80.777574495846494, 34.662102193507764],
              [-80.777627125054366, 34.66183740026802],
              [-80.777301372499792, 34.661794619616543],
              [-80.777406633035127, 34.661265033340335],
              [-80.777080882868646, 34.661222252091378],
              [-80.777238774741079, 34.660427872932182],
              [-80.77691302796724, 34.660385090327267],
              [-80.776965659299478, 34.660120297367541],
              [-80.775336933466406, 34.659906371841487],
              [-80.775389569579332, 34.659641579574121],
              [-80.775063826892605, 34.659598791955759],
              [-80.775116464780709, 34.659333999793077],
              [-80.774139240721453, 34.65920563118231],
              [-80.774191881341835, 34.658940839426378],
              [-80.77386614206263, 34.658898048561014],
              [-80.773918782256871, 34.658633256040673],
              [-80.77261583370624, 34.658462083401851],
              [-80.772290097150517, 34.658419288263524],
              [-80.772342742125119, 34.658154496435714],
              [-80.771691273152584, 34.658068902878064],
              [-80.77174392092617, 34.657804111297878],
              [-80.771092454270544, 34.65771851450954],
              [-80.771145103771687, 34.657453724094545],
              [-80.770493641594967, 34.657368123142007],
              [-80.770598943654164, 34.65683854191645],
              [-80.770273215669064, 34.656795740393292],
              [-80.770378518396996, 34.656266158464192],
              [-80.770052791708949, 34.656223356359824],
              [-80.770105443587013, 34.655958565505237],
              [-80.769779719353636, 34.655915762644618],
              [-80.769885024118551, 34.655386180254027],
              [-80.769559302273009, 34.655343376796161],
              [-80.769611955179201, 34.655078586161537],
              [-80.769286234697503, 34.655035781963498],
              [-80.769338889359048, 34.654770990532498],
              [-80.769013170260735, 34.654728186495653],
              [-80.769065824515494, 34.654463395201638],
              [-80.769391542609412, 34.654506199996909],
              [-80.769444195500313, 34.654241408538027],
              [-80.7691184784303, 34.654198603885703],
              [-80.769171132005297, 34.65393381254782],
              [-80.768845416299115, 34.653891007155316],
              [-80.768898071648763, 34.653626215922479],
              [-80.767920930732245, 34.653497794859192],
              [-80.76797358772285, 34.653233004049348],
              [-80.767647876514928, 34.653190195394338],
              [-80.76775319152415, 34.652660613994627],
              [-80.767427482684482, 34.652617803841139],
              [-80.767532799472306, 34.652088222623625],
              [-80.766555680084267, 34.651959789541309],
              [-80.76660834158514, 34.65169499932059],
              [-80.766282636086089, 34.651652186079758],
              [-80.766229974671532, 34.651916977042823],
              [-80.765904269598664, 34.651874163661283],
              [-80.765956932037128, 34.651609372841207],
              [-80.765631228328161, 34.651566558719558],
              [-80.765736556414183, 34.651036977267609],
              [-80.765410855092711, 34.650994162548891],
              [-80.765516182775727, 34.650464581311475],
              [-80.764539088065519, 34.650336131797559],
              [-80.764486420777331, 34.650600922006291],
              [-80.762206537457914, 34.650301173069572],
              [-80.762259211912493, 34.650036383861888],
              [-80.761282127383438, 34.649907906955612],
              [-80.761334805659956, 34.64964311813911],
              [-80.760357727262516, 34.649514633714716],
              [-80.76041040825082, 34.649249844404061],
              [-80.760084716804869, 34.649207015245906],
              [-80.760137398476914, 34.648942226056377],
              [-80.759160331374062, 34.648813730976755],
              [-80.759107646630895, 34.649078519737017],
              [-80.758130580608594, 34.648950016265218],
              [-80.758077892434869, 34.649214803673168],
              [-80.757100825311738, 34.649086291841016],
              [-80.75673197491237, 34.650939801013109],
              [-80.756406278639133, 34.650896960077539],
              [-80.756353583649471, 34.65116174673814],
              [-80.755376494948251, 34.651033220892309],
              [-80.755323796547188, 34.651298007101815],
              [-80.75467240298677, 34.651212317612583],
              [-80.754346707817248, 34.651169471978157],
              [-80.754399409289505, 34.650904686197919],
              [-80.753748020927816, 34.650818992582145],
              [-80.753800725198033, 34.65055420705022],
              [-80.753475033096606, 34.650511359052949],
              [-80.75352773805038, 34.650246573642285],
              [-80.753202046202873, 34.65020372401959],
              [-80.753254751840032, 34.649938938730052],
              [-80.75292906246645, 34.649896089252969],
              [-80.752981768787052, 34.649631304084693],
              [-80.752330391997987, 34.649545601882309],
              [-80.752277683630027, 34.649810386764457],
              [-80.751951995277167, 34.649767534638386],
              [-80.751899285545278, 34.650032319355525],
              [-80.751247906703028, 34.649946611282729],
              [-80.751195194583502, 34.65021139569172],
              [-80.750543815054982, 34.650125683800653],
              [-80.750491100528507, 34.650390467000157],
              [-80.750165410805977, 34.650347610029748],
              [-80.75011269384413, 34.65061239398149],
              [-80.747832865871985, 34.650312364987997],
              [-80.747885589999612, 34.650047582038233],
              [-80.747559902980441, 34.650004717102625],
              [-80.747612627791284, 34.649739934274088],
              [-80.746961256821322, 34.649654202040104],
              [-80.747013983339087, 34.649389419475966],
              [-80.746362615777841, 34.649303683996401],
              [-80.74630988612212, 34.64956846629007],
              [-80.74565851896584, 34.649482726976444],
              [-80.745605786922468, 34.649747508961894],
              [-80.745280102285932, 34.649704637845367],
              [-80.745227369969257, 34.649969419649864],
              [-80.744250315031195, 34.649840800572953],
              [-80.744197578212507, 34.650105581941872],
              [-80.743871892890368, 34.650062707007024],
              [-80.743924630732678, 34.649797925781293],
              [-80.743598946774597, 34.649755050106826],
              [-80.743651684209368, 34.64949026901818],
              [-80.743326001615344, 34.649447392603847],
              [-80.743378740824014, 34.649182611620645],
              [-80.742727379776099, 34.649096855512269],
              [-80.742780119600809, 34.648832074809391],
              [-80.742454441166004, 34.648789196016786],
              [-80.742507181654688, 34.648524414533817],
              [-80.742181504583911, 34.648481535001409],
              [-80.742234246865578, 34.648216754525265],
              [-80.741908570048977, 34.64817387336759],
              [-80.741961313013746, 34.64790909301275],
              [-80.741309963558891, 34.647823329220465],
              [-80.741415452542213, 34.647293768116704],
              [-80.741089780908354, 34.647250884723995],
              [-80.741142525903228, 34.646986103831793],
              [-80.740491184632447, 34.646900335601202],
              [-80.740543931333903, 34.646635554973543],
              [-80.738915591950146, 34.646421119171841],
              [-80.738968343428738, 34.646156339238523],
              [-80.738642677589354, 34.646113449212102],
              [-80.738317012109576, 34.646070559204183],
              [-80.73836976636612, 34.645805778618254],
              [-80.738044102250342, 34.645762887870646],
              [-80.738149610717272, 34.645233327836927],
              [-80.737823948969861, 34.645190435591473],
              [-80.738034965911424, 34.644131315835018],
              [-80.737709309688967, 34.644088423263938],
              [-80.737814818163486, 34.643558863541202],
              [-80.737489163237768, 34.643515970389508],
              [-80.737594673468692, 34.642986409948982],
              [-80.737269020930327, 34.642943516200944],
              [-80.737374530755275, 34.642413955975442],
              [-80.737048881694847, 34.642371061615243],
              [-80.737101637646148, 34.642106280703828],
              [-80.736775989949621, 34.642063385604125],
              [-80.736881501805925, 34.641533824919605],
              [-80.736230211527968, 34.641448032644519],
              [-80.736282969527835, 34.64118325209764],
              [-80.735957325922968, 34.641140354779104],
              [-80.735631683749205, 34.641097456561916],
              [-80.735684442364629, 34.64083267629551],
              [-80.73470751875638, 34.640703975907137],
              [-80.734760281191882, 34.64043919603305],
              [-80.732155176189025, 34.640095956977916],
              [-80.732207947561093, 34.639831178212667],
              [-80.731556676739004, 34.639745358780615],
              [-80.731609449817029, 34.639480580280242],
              [-80.729655652794207, 34.639223103443733],
              [-80.729708431652114, 34.638958324880235],
              [-80.729057171482864, 34.638872492782362],
              [-80.729109952046613, 34.638607714483705],
              [-80.728784323486721, 34.638564796803323],
              [-80.72873154189972, 34.63882957495855],
              [-80.72808028375546, 34.638743736662562],
              [-80.728027499762334, 34.639008513608125],
              [-80.727376240934333, 34.638922671494328],
              [-80.72732345346337, 34.639187448147126],
              [-80.72602093580295, 34.639015752752748],
              [-80.72596814498894, 34.639280528794714],
              [-80.72336311779965, 34.638937092682966],
              [-80.723310317368629, 34.639201867571835],
              [-80.722984689466031, 34.639158933265996],
              [-80.722931887671024, 34.639423707989756],
              [-80.722606259104936, 34.639380773559104],
              [-80.722500651355503, 34.639910322670126],
              [-80.722175021064871, 34.639867386168596],
              [-80.722122216200916, 34.640132160540198],
              [-80.721796585246892, 34.640089223913904],
              [-80.721743777909467, 34.640353997234605],
              [-80.719789993146307, 34.640096355373373],
              [-80.719842805513522, 34.639831582027369],
              [-80.719191548699101, 34.639745694631578],
              [-80.719244363862416, 34.63948092153494],
              [-80.718918736980186, 34.639437976205635],
              [-80.718971552825892, 34.639173203230712],
              [-80.718645927326691, 34.639130258063432],
              [-80.718698743854603, 34.638865485210118],
              [-80.718373120791156, 34.63882253838652],
              [-80.71847875376119, 34.638292992015664],
              [-80.718153133103826, 34.638250045497571],
              [-80.7183115846981, 34.637455725734469],
              [-80.717660349435505, 34.63736983002488],
              [-80.717607530519359, 34.637634602413215],
              [-80.716630677629766, 34.637505750438159],
              [-80.716577854231659, 34.637770523291394],
              [-80.714298535937672, 34.637469835876594],
              [-80.712670462114062, 34.637255031504473],
              [-80.712723297808168, 34.636990261274271],
              [-80.712397684205769, 34.63694729736671],
              [-80.712450520581953, 34.636682527258181],
              [-80.712124909434195, 34.636639562595988],
              [-80.712283419548399, 34.635845250767794],
              [-80.711957811810819, 34.635802285653348],
              [-80.712063485548768, 34.635272744012042],
              [-80.712389091240269, 34.635315708839542],
              [-80.712441926555897, 34.635050936941468],
              [-80.712116321906237, 34.635007973158878],
              [-80.71216915792273, 34.63474320228395],
              [-80.711843555708796, 34.634700236845177],
              [-80.711896392407297, 34.634435466092079],
              [-80.711570790466865, 34.63439249992966],
              [-80.711623627847388, 34.634127729298434],
              [-80.711298028361554, 34.634084762381214],
              [-80.711350866405184, 34.633819990970437],
              [-80.711025267211554, 34.63377702423103],
              [-80.711078107027845, 34.633512252926479],
              [-80.710752509179329, 34.633469284546557],
              [-80.710805348605888, 34.633204514280784],
              [-80.710479753211956, 34.63316154514618],
              [-80.710532593301494, 34.632896774100836],
              [-80.71020699927162, 34.632853804227061],
              [-80.71031268156429, 34.632324262342969],
              [-80.709987089921242, 34.632281291873568],
              [-80.710092771805236, 34.631751750205382],
              [-80.709767182549086, 34.631708779140368],
              [-80.709872866186601, 34.631179236755848],
              [-80.709547279317306, 34.63113626509525],
              [-80.709600121111691, 34.63087149447248],
              [-80.709274536696725, 34.630828522057257],
              [-80.709327379154217, 34.630563750655114],
              [-80.709001796103166, 34.630520777500784],
              [-80.709054639242538, 34.630256006220456],
              [-80.708729056465032, 34.630213032342553],
              [-80.708781901395312, 34.629948262070009],
              [-80.708456319981806, 34.62990528745312],
              [-80.708509165575265, 34.629640516401118],
              [-80.707858006888586, 34.629554563889499],
              [-80.707910854186736, 34.629289793103005],
              [-80.70758527693242, 34.629246816109763],
              [-80.707638123821937, 34.628982045460802],
              [-80.706986972382154, 34.628896089113589],
              [-80.707039822047989, 34.628631317813195],
              [-80.706388672927645, 34.628545358238298],
              [-80.7064415242981, 34.62828058720342],
              [-80.706115951817551, 34.628237606227856],
              [-80.706168803888502, 34.627972836216344],
              [-80.705843232753111, 34.627929853600371],
              [-80.705896085505799, 34.627665083710838],
              [-80.70557051573428, 34.627622100355872],
              [-80.705623369168691, 34.627357330588424],
              [-80.70497223380363, 34.627271362403746],
              [-80.705025088923804, 34.627006592000434],
              [-80.704699522221105, 34.626963606284896],
              [-80.704752378041718, 34.626698836905035],
              [-80.704101248814268, 34.626612863999256],
              [-80.704154106320587, 34.626348093983673],
              [-80.703828543777121, 34.626305105892101],
              [-80.703881401965106, 34.626040335998461],
              [-80.703555840785469, 34.625997347167939],
              [-80.703608699655092, 34.625732577396342],
              [-80.703661558183626, 34.625467807603243],
              [-80.703335999390575, 34.625424818177336],
              [-80.703388858600732, 34.625160048506359],
              [-80.703063301171511, 34.625117058341502],
              [-80.703116161063363, 34.624852288792532],
              [-80.702790604997887, 34.624809297888731],
              [-80.702843466642932, 34.624544527545083],
              [-80.702517911941371, 34.624501535902354],
              [-80.702570773196413, 34.624236766597555],
              [-80.702245219858639, 34.624193774215883],
              [-80.702298081776391, 34.623929004131902],
              [-80.701972530892817, 34.623886010995854],
              [-80.702025393510922, 34.623621241935275],
              [-80.701699842900851, 34.623578248075766],
              [-80.701752707272178, 34.623313478220481],
              [-80.701427158025808, 34.623270483622058],
              [-80.701532886699965, 34.622740944149818],
              [-80.701207339840124, 34.622697948956123],
              [-80.701260205214709, 34.622433178422256],
              [-80.700934660808926, 34.622390182474291],
              [-80.700987525793323, 34.622125412979408],
              [-80.700661982751299, 34.6220824162925],
              [-80.700714848398519, 34.621817646018407],
              [-80.700389306720254, 34.621774648592613],
              [-80.700442174157999, 34.62150987932668],
              [-80.700116632753122, 34.621466881177454],
              [-80.700169500853676, 34.621202111132277],
              [-80.6998439619029, 34.621159112228867],
              [-80.699896830684892, 34.62089434230586],
              [-80.699571293097861, 34.620851342663492],
              [-80.699624162561292, 34.620586572862678],
              [-80.69929862524765, 34.620543572496921],
              [-80.699404365196258, 34.620014033118359],
              [-80.699078831359245, 34.619971032141947],
              [-80.699131701825735, 34.619706261662863],
              [-80.698806169352423, 34.619663259947643],
              [-80.698859040518983, 34.619398490492053],
              [-80.698533510499715, 34.619355488022599],
              [-80.69858638123867, 34.619090717803388],
              [-80.69826085258309, 34.619047714595112],
              [-80.698313725093755, 34.618782944482653],
              [-80.697988196711563, 34.618739940550988],
              [-80.698041069903582, 34.618475170560764],
              [-80.697715543975391, 34.618432165874935],
              [-80.697768417848778, 34.618167396007038],
              [-80.697117367951947, 34.618081383389388],
              [-80.697170243547816, 34.617816614688714],
              [-80.696519198132364, 34.617730597911944],
              [-80.696572075413414, 34.617465828575845],
              [-80.695921032336003, 34.617379809473434],
              [-80.695973912411119, 34.617115040387901],
              [-80.69532287383376, 34.617029018027836],
              [-80.695375754522303, 34.616764249223642],
              [-80.695050236758576, 34.61672123641295],
              [-80.695103119218658, 34.6164564677157],
              [-80.694777602818604, 34.616413454166342],
              [-80.694830484869641, 34.616148685606824],
              [-80.694504969833233, 34.616105671318678],
              [-80.694557853637178, 34.615840901964731],
              [-80.694232339964429, 34.615797886937948],
              [-80.694285224468175, 34.615533118607708],
              [-80.693959712159071, 34.615490102842152],
              [-80.694012597343999, 34.615225334634268],
              [-80.69368708639854, 34.615182318130074],
              [-80.693739972246064, 34.614917549143165],
              [-80.692437938146583, 34.614745474846352],
              [-80.692490826652289, 34.61448070642836],
              [-80.692165320548071, 34.614437685784239],
              [-80.691839814766439, 34.614394663356379],
              [-80.691892706084474, 34.61412989609051],
              [-80.691567200576216, 34.614086872939282],
              [-80.691620092556704, 34.613822104894403],
              [-80.691294589520979, 34.613779081890627],
              [-80.691400374502962, 34.613249546024335],
              [-80.691074873853111, 34.613206522425635],
              [-80.691127766836019, 34.612941753702842],
              [-80.690151269982763, 34.612812677142401],
              [-80.690098373914395, 34.613077444532649],
              [-80.689447375686413, 34.612991389394125],
              [-80.689394477232099, 34.613256156475614],
              [-80.688092479655083, 34.613084033248235],
              [-80.688145383270381, 34.612819265824967],
              [-80.687494389119635, 34.612733199197038],
              [-80.687547294456778, 34.612468432941313],
              [-80.687221798906435, 34.612425397997008],
              [-80.687274704924505, 34.612160631863759],
              [-80.686949210737808, 34.612117596180781],
              [-80.687055023775955, 34.611588063236702],
              [-80.686729531975175, 34.6115450269589],
              [-80.686782438995237, 34.611280260147993],
              [-80.686456949648274, 34.611237223116419],
              [-80.686880199518171, 34.609119089045024],
              [-80.687205680668711, 34.609162124025168],
              [-80.687311488204998, 34.608632589098519],
              [-80.687636967670969, 34.608675623810335],
              [-80.68774277070743, 34.608146088526667],
              [-80.688068248470302, 34.608189122068708],
              [-80.688279846321947, 34.607130048869308],
              [-80.68795437264771, 34.607087015902387],
              [-80.688060171551584, 34.606557478561747],
              [-80.688385643181476, 34.606600511241268],
              [-80.688491437585782, 34.606070973543908],
              [-80.688165969090434, 34.606027941136574],
              [-80.688324662284685, 34.605233634397152],
              [-80.688377560072695, 34.604968866032436],
              [-80.688052094916983, 34.604925833333262],
              [-80.688104993367318, 34.604661064189791],
              [-80.687779530665125, 34.604618030736823],
              [-80.687832428706145, 34.604353261731312],
              [-80.687506967367341, 34.604310227539806],
              [-80.687612765541374, 34.603780688836366],
              [-80.687287306588019, 34.603737654050157],
              [-80.687340206739535, 34.603472885253531],
              [-80.687014749149526, 34.603429849728848],
              [-80.687067649963325, 34.603165080153616],
              [-80.687173449495504, 34.602635541856436],
              [-80.687498902929221, 34.602678576965225],
              [-80.687710493791741, 34.601619498641618],
              [-80.687385044446273, 34.601576464107985],
              [-80.687702425479827, 34.599987843714416],
              [-80.688027868693297, 34.600030877385471],
              [-80.68808076295872, 34.599766107408037],
              [-80.687755320748707, 34.599723072979366],
              [-80.687808216784958, 34.599458303109465],
              [-80.688133656864395, 34.59950133650824],
              [-80.688239444742209, 34.598971794630387],
              [-80.688564884227404, 34.599014827745606],
              [-80.688617776087455, 34.598750056639119],
              [-80.688943214873532, 34.59879308782704],
              [-80.689048995526079, 34.598263545263535],
              [-80.689374431519269, 34.598306575297052],
              [-80.689427320311808, 34.598041803840111],
              [-80.689752756714284, 34.598084832832477],
              [-80.689805644143661, 34.597820061210754],
              [-80.690456514838232, 34.597906116276846],
              [-80.690509399882544, 34.597641344346648],
              [-80.690834834174538, 34.597684370420417],
              [-80.691485705962037, 34.597770419890793],
              [-80.691538586509296, 34.597505647523775],
              [-80.692514892593181, 34.597634714697357],
              [-80.692462015130317, 34.59789948839682],
              [-80.694414642621183, 34.598157598886665],
              [-80.69446751397065, 34.597892825226168],
              [-80.695443830335307, 34.598021867664691],
              [-80.695496697187707, 34.59775709356753],
              [-80.695822135642246, 34.597800106106142],
              [-80.695875002203024, 34.597535330927656],
              [-80.696525876982648, 34.597621352184909],
              [-80.696578741177021, 34.597356577599491],
              [-80.696904178610666, 34.597399587204201],
              [-80.696957040333359, 34.597134811568118],
              [-80.69728247708602, 34.597177820146975],
              [-80.697335338535751, 34.596913044330847],
              [-80.697660773517399, 34.596956051899298],
              [-80.697713633604238, 34.596691275918666],
              [-80.698364503617924, 34.596777287204901],
              [-80.698417360229669, 34.596512510931333],
              [-80.699068229563281, 34.596598518401592],
              [-80.699121083790089, 34.596333741819812],
              [-80.699446518500679, 34.596376744531],
              [-80.699499371364652, 34.59611196778463],
              [-80.699824805375556, 34.596154968568619],
              [-80.700036208225015, 34.595095859913762],
              [-80.700687068022546, 34.595181858602828],
              [-80.700739916115836, 34.594917081321043],
              [-80.70106534494964, 34.594960078755754],
              [-80.701171038050873, 34.594430522940989],
              [-80.701496465200336, 34.594473520107599],
              [-80.701602153785515, 34.593943963036189],
              [-80.701927579231963, 34.593986959033394],
              [-80.702033264409621, 34.593457401591223],
              [-80.702358688134382, 34.593500395517616],
              [-80.70241152973496, 34.593235616613995],
              [-80.702736952797338, 34.593278610415993],
              [-80.7028426307334, 34.592749051388473],
              [-80.703168052092764, 34.592792044021067],
              [-80.70322088953705, 34.592527264783087],
              [-80.703546310196629, 34.592570255488546],
              [-80.703599147368038, 34.592305476070727],
              [-80.703924566256561, 34.592348465765838],
              [-80.703977400975248, 34.592083686199054],
              [-80.70430282027263, 34.592126674853013],
              [-80.704355653609966, 34.591861894220358],
              [-80.704681072244952, 34.591904882749937],
              [-80.704733904219495, 34.591640101952891],
              [-80.705059322154639, 34.591683088555428],
              [-80.705112152785176, 34.591418308495342],
              [-80.705437568949264, 34.59146129408753],
              [-80.705490398198378, 34.591196512961687],
              [-80.706141230613852, 34.5912824820984],
              [-80.706352534937452, 34.590223356254029],
              [-80.706027122092635, 34.590180372258224],
              [-80.706079948616008, 34.589915590884935],
              [-80.705754538242488, 34.589872607036597],
              [-80.705913017725024, 34.589078262337367],
              [-80.705587610739144, 34.589035277136361],
              [-80.705640437943217, 34.58877049596736],
              [-80.706291250174672, 34.588856463397398],
              [-80.706344073904376, 34.588591681935974],
              [-80.706994885454989, 34.588677645550391],
              [-80.707047707890183, 34.588412863765512],
              [-80.70834933101149, 34.588584780736227],
              [-80.708402147909879, 34.588319997470443],
              [-80.709378365748563, 34.588448924829812],
              [-80.709325551915654, 34.588713708526384],
              [-80.709976368645158, 34.588799655960493],
              [-80.70992355651542, 34.589064439923582],
              [-80.714153910062549, 34.589623011425793],
              [-80.714206707836411, 34.589358226513355],
              [-80.714532120576351, 34.589401186546297],
              [-80.714857534765613, 34.589444146582935],
              [-80.71491033013632, 34.589179360461408],
              [-80.715561156402742, 34.589265277616811],
              [-80.715613949388938, 34.589000491187441],
              [-80.715939361447255, 34.589043447404876],
              [-80.716044944373039, 34.58851387509845],
              [-80.716370354728127, 34.588556830146707],
              [-80.716475934229138, 34.588027256569134],
              [-80.716801342899672, 34.588070211349617],
              [-80.716854130027599, 34.587805424401843],
              [-80.717179539087795, 34.587848377239744],
              [-80.717232324853398, 34.587583590127871],
              [-80.71788313979053, 34.587669493802807],
              [-80.717935924261624, 34.58740470636765],
              [-80.718912146145854, 34.587533554383107],
              [-80.718964927210791, 34.58726876649677],
              [-80.719941149074529, 34.587397605241492],
              [-80.71988837218403, 34.587662394444052],
              [-80.720539190505932, 34.587748282816968],
              [-80.72048641638996, 34.588013071368962],
              [-80.720811827102239, 34.588056015277026],
              [-80.720495174830589, 34.589644746207952],
              [-80.721471429528748, 34.589773572486926],
              [-80.721418656008822, 34.59003836058227],
              [-80.722069496946915, 34.590124240634083],
              [-80.721963952992681, 34.590653818238003],
              [-80.722289376016377, 34.590696757227626],
              [-80.722236605631124, 34.59096154567569],
              [-80.722562030017258, 34.591004483926611],
              [-80.722456488089676, 34.59153406107874],
              [-80.722781914860263, 34.591576998734361],
              [-80.722729145497837, 34.591841787407198],
              [-80.72305457363089, 34.591884724324153],
              [-80.723001803860143, 34.592149513135283],
              [-80.723327234426691, 34.592192448396602],
              [-80.723274465337497, 34.592457237330443],
              [-80.723599897285524, 34.592500172754342],
              [-80.72354712887801, 34.592764961810964],
              [-80.723872561079617, 34.592807895610314],
              [-80.723819794443742, 34.593072684773993],
              [-80.724145229097772, 34.593115617819016],
              [-80.724039695740203, 34.593645195485216],
              [-80.724365131707685, 34.593688128851959],
              [-80.724312366075978, 34.59395291733879],
              [-80.724637804495941, 34.593995849951114],
              [-80.72458503845597, 34.594260638576351],
              [-80.724910478219499, 34.594303569548558],
              [-80.724804948252569, 34.594833147007783],
              [-80.725130390419693, 34.594876078286028],
              [-80.725077626492791, 34.595140867120044],
              [-80.725403070003495, 34.595183796758107],
              [-80.725350306758344, 34.595448585714806],
              [-80.726977535637289, 34.595663222274545],
              [-80.727030293790605, 34.595398433502091],
              [-80.730610216879299, 34.595870553625197],
              [-80.730557471078981, 34.596135344860699],
              [-80.730882921378495, 34.59617825874281],
              [-80.730830176260099, 34.596443050100916],
              [-80.731155627941092, 34.596485964145451],
              [-80.731050138708923, 34.597015546184487],
              [-80.731701048251622, 34.597101371283337],
              [-80.731648305160093, 34.597366162107747],
              [-80.732624675666358, 34.597494894913304],
              [-80.732677416762243, 34.597230102741698],
              [-80.733002872529227, 34.597273011779471],
              [-80.733055611191531, 34.597008220360777],
              [-80.733381067366523, 34.597051128357201],
              [-80.733433804647319, 34.596786335873063],
              [-80.734084714883593, 34.59687214894808],
              [-80.734031980718186, 34.597136940801839],
              [-80.734357436804885, 34.597179845265799],
              [-80.734304703340555, 34.597444638143436],
              [-80.734630161879664, 34.597487541852807],
              [-80.734577427988441, 34.59775233396735],
              [-80.734902887909044, 34.597795237839065],
              [-80.734850154699899, 34.598060030076205],
              [-80.735175615964039, 34.59810293230754],
              [-80.735122884526902, 34.598367724651432],
              [-80.735448347172493, 34.598410627045254],
              [-80.735395615327519, 34.598675419527382],
              [-80.736372009424272, 34.598804120026522],
              [-80.736424737112387, 34.598539327130233],
              [-80.73805206264953, 34.598753808993358],
              [-80.737999338982974, 34.599018602622074],
              [-80.739301210045255, 34.599190172076945],
              [-80.739353928551523, 34.598925378792138],
              [-80.741632208284159, 34.599225589472397],
              [-80.741579498043606, 34.599490384645783],
              [-80.74255591150839, 34.599619033951747],
              [-80.742397786819296, 34.6004134188493],
              [-80.742723262448166, 34.600456300605792],
              [-80.742617845957241, 34.600985890124079],
              [-80.742943323970977, 34.601028771284859],
              [-80.742890616782873, 34.60129356614808],
              [-80.742837909254717, 34.601558360990445],
              [-80.743163389634219, 34.60160124065402],
              [-80.743110682788341, 34.601866035618663],
              [-80.743436164549465, 34.601908915444518],
              [-80.743383457295991, 34.602173710547454],
              [-80.744685393972716, 34.602345219765809],
              [-80.744896200227075, 34.601286037000179],
              [-80.745221680621071, 34.601328911984467],
              [-80.745327078554013, 34.600799319304436],
              [-80.745001600224043, 34.600756445507969],
              [-80.745054300257564, 34.60049164972272],
              [-80.745379777565105, 34.600534523375842],
              [-80.74559056908123, 34.599475337666277],
              [-80.74591604265828, 34.599518210765517],
              [-80.745968738927857, 34.599253413688125],
              [-80.746294210713387, 34.599296284876303],
              [-80.746346905639754, 34.599031488536255],
              [-80.746672377832809, 34.599074358683012],
              [-80.746830455267514, 34.598279966435825],
              [-80.747155924752533, 34.598322836171896],
              [-80.747050541817501, 34.598852431315883],
              [-80.747376012578002, 34.598895299570572],
              [-80.747323322167986, 34.599160097246681],
              [-80.747648795380883, 34.599202964746333],
              [-80.747596104563186, 34.599467762560742],
              [-80.747921579138421, 34.599510629321358],
              [-80.747868890093343, 34.599775427242271],
              [-80.748845317855043, 34.599904023576784],
              [-80.748950690560903, 34.599374426839063],
              [-80.749601639654983, 34.599460153049947],
              [-80.749654322908128, 34.59919535437141],
              [-80.749979797478105, 34.599238215551509],
              [-80.750085160919909, 34.598708617845439],
              [-80.750410633804066, 34.598751478758139],
              [-80.750463312902795, 34.598486679746678],
              [-80.750788785085192, 34.5985295387325],
              [-80.750946819435541, 34.597735140210439],
              [-80.751272288890675, 34.597777997884329],
              [-80.75137764101045, 34.597248398184234],
              [-80.751703108760566, 34.597291254689381],
              [-80.751861130769754, 34.596496854111194],
              [-80.755766724749961, 34.597011058324156],
              [-80.755819385434975, 34.596746256076791],
              [-80.75647032084035, 34.596831944134692],
              [-80.756522979140811, 34.596567141580209],
              [-80.75684844578565, 34.59660998415049],
              [-80.756953758232683, 34.596080378709019],
              [-80.757279224262447, 34.596123220094704],
              [-80.757331878408962, 34.595858417207779],
              [-80.758633738526825, 34.596029771599831],
              [-80.758686388244072, 34.595764968119646],
              [-80.759011853927461, 34.595807804807436],
              [-80.759064502282655, 34.595543001163314],
              [-80.759389966174083, 34.595585835940227],
              [-80.759442613167209, 34.595321032132318],
              [-80.759768077485134, 34.595363866769169],
              [-80.75982072309688, 34.59509906189615],
              [-80.760471648507561, 34.595184727367247],
              [-80.760524291754322, 34.594919923088561],
              [-80.761500679340656, 34.59504841379777],
              [-80.761553319180535, 34.594783609069154],
              [-80.761878781359684, 34.594826437097282],
              [-80.761931419837637, 34.594561632204879],
              [-80.763233267879883, 34.594732935823309],
              [-80.763285901928597, 34.594468130337781],
              [-80.76361136376083, 34.594510953668134],
              [-80.76366399533832, 34.594246147133511],
              [-80.763989456487735, 34.594288969438544],
              [-80.764042087812527, 34.594024163625512],
              [-80.764367548279068, 34.594066984905055],
              [-80.764472805646676, 34.593537371160231],
              [-80.764798264407972, 34.593580191271307],
              [-80.76495614484574, 34.592785770069995],
              [-80.765281600879405, 34.592828588869516],
              [-80.765386850742928, 34.592298973444734],
              [-80.765712304000715, 34.592341791993121],
              [-80.76587017317307, 34.59154736782142],
              [-80.766195624773829, 34.591590184140976],
              [-80.766511345599739, 34.590001332612445],
              [-80.766185901222798, 34.589958517135265],
              [-80.766291140444267, 34.589428899184647],
              [-80.765965698451481, 34.589386083111329],
              [-80.766018319110572, 34.589121273789516],
              [-80.766343760100554, 34.589164090621132],
              [-80.766396378308002, 34.588899281151804],
              [-80.766721818615153, 34.588942096958107],
              [-80.766827054147015, 34.588412477639494],
              [-80.767152491639763, 34.588455291392151],
              [-80.767257722698758, 34.58792567262271],
              [-80.767310338254461, 34.587660862748585],
              [-80.767635774109692, 34.587703675173671],
              [-80.768056671560501, 34.585585191690271],
              [-80.768382098487351, 34.585628002104684],
              [-80.768487317932284, 34.585098379608247],
              [-80.768812743173513, 34.585141189755696],
              [-80.768865350819866, 34.584876378341733],
              [-80.769190775358823, 34.584919186562601],
              [-80.76924338164352, 34.584654374985156],
              [-80.769568805499617, 34.584697182180683],
              [-80.769621410422857, 34.584432370439806],
              [-80.770272257128141, 34.584517982799476],
              [-80.770324859667539, 34.584253170752007],
              [-80.770975705666288, 34.584338778395363],
              [-80.771028305822057, 34.584073966041394],
              [-80.771353728308199, 34.584116768396711],
              [-80.771616716648168, 34.582792702933844],
              [-80.771942134362661, 34.582835503691776],
              [-80.772152513301492, 34.581776249863978],
              [-80.772257701250908, 34.581246621467741],
              [-80.772583112081904, 34.581289420501435],
              [-80.772688296649548, 34.580759792639086],
              [-80.773013705775554, 34.580802590504526],
              [-80.773066295964043, 34.580537775506464],
              [-80.773391705496934, 34.580580572330689],
              [-80.773444294343463, 34.580315758070626],
              [-80.77376970210365, 34.580358553885681],
              [-80.773927463502645, 34.57956410875191],
              [-80.774252868535712, 34.579606903255659],
              [-80.774358039048479, 34.579077272178012],
              [-80.774683442376528, 34.579120065513649],
              [-80.774788609488212, 34.578590434068701],
              [-80.775114011111341, 34.578633226236171],
              [-80.775219173712856, 34.578103593538792],
              [-80.775544573630981, 34.57814638453808],
              [-80.775597153955516, 34.577881568458551],
              [-80.775922552100866, 34.577924358448811],
              [-80.775975131044774, 34.577659541304705],
              [-80.776300529616364, 34.577702331155052],
              [-80.776195374504027, 34.578231964791541],
              [-80.776520775439536, 34.578274753144328],
              [-80.776468197842107, 34.578539569632504],
              [-80.776793600138859, 34.578582357246148],
              [-80.776741024314063, 34.578847173840877],
              [-80.777066427972159, 34.578889960715337],
              [-80.777013851740662, 34.57915477744897],
              [-80.77733925675993, 34.579197563584252],
              [-80.777286682301082, 34.579462380424381],
              [-80.777612088681622, 34.579505165820436],
              [-80.777559514905874, 34.579769982783326],
              [-80.777884922647686, 34.579812767440181],
              [-80.777832349554942, 34.580077584525668],
              [-80.778483168080712, 34.580163150577427],
              [-80.778430596693156, 34.580427967928536],
              [-80.779081419729039, 34.580513531622699],
              [-80.779028850027174, 34.580778348338121],
              [-80.77935426362086, 34.580821128996803],
              [-80.779301693512281, 34.581085945850951],
              [-80.779627108467253, 34.581128725770377],
              [-80.77957454015106, 34.58139354363243],
              [-80.779899956447792, 34.581436321911191],
              [-80.779847388795091, 34.581701138994504],
              [-80.780498223399633, 34.58178669501028],
              [-80.780445657452319, 34.582051512359101],
              [-80.780771076810765, 34.582094288277226],
              [-80.780718511546482, 34.582359105748651],
              [-80.781043932285868, 34.582401881828794],
              [-80.78099136879456, 34.582666699406708],
              [-80.781967636074626, 34.582795020979802],
              [-80.782020196499118, 34.582530202973089],
              [-80.78234561857515, 34.582572974623034],
              [-80.782293059172886, 34.582837792772629],
              [-80.7826184826298, 34.582880564584521],
              [-80.782671041009792, 34.582615746292021],
              [-80.783321887947011, 34.582701285164305],
              [-80.783374443943458, 34.582436466565603],
              [-80.783699866372984, 34.582479235444929],
              [-80.784350712209971, 34.582564768754239],
              [-80.784403264800744, 34.582299949706403],
              [-80.785379533032085, 34.5824282426248],
              [-80.785432082217, 34.582163423127831],
              [-80.786408351522169, 34.582291707661113],
              [-80.786460896211508, 34.582026887731402],
              [-80.786786318605635, 34.582069647340447],
              [-80.786838863023661, 34.581804827231281],
              [-80.787164284734487, 34.581847585815282],
              [-80.787216827771587, 34.581582764641453],
              [-80.787542248799198, 34.581625522200333],
              [-80.78759478940492, 34.581360701780973],
              [-80.787920209749117, 34.581403458314696],
              [-80.788025288949939, 34.580873815310241],
              [-80.788350707588535, 34.580916570675996],
              [-80.788403245133011, 34.580651749909968],
              [-80.788728663088307, 34.580694504250488],
              [-80.788781200341887, 34.580429682403668],
              [-80.789106616524023, 34.580472435735452],
              [-80.789159152416374, 34.580207613725463],
              [-80.789484567915281, 34.580250366032054],
              [-80.789537102446502, 34.579985543858903],
              [-80.790187933486422, 34.580071044622223],
              [-80.790240464544524, 34.579806222159299],
              [-80.791216711133956, 34.57993446670153],
              [-80.791269238786498, 34.579669643789714],
              [-80.791594653251764, 34.579712390374432],
              [-80.791699705495944, 34.579182744204324],
              [-80.792025119325771, 34.579225488703507],
              [-80.792182690974599, 34.578431017982844],
              [-80.792508101006391, 34.578473762088898],
              [-80.792560623539728, 34.578208937725627],
              [-80.79288603288812, 34.578251680806602],
              [-80.792938552970597, 34.577986856296555],
              [-80.793589371709501, 34.578072338608635],
              [-80.793641889408761, 34.577807513792671],
              [-80.793967298818842, 34.577850253925078],
              [-80.794292707478021, 34.577892993191533],
              [-80.794345222794107, 34.577628168069772],
              [-80.794670631839807, 34.577670905393475],
              [-80.794723145795004, 34.577406080108666],
              [-80.795373960718123, 34.577491553659229],
              [-80.795426472290089, 34.577226728068553],
              [-80.795751880307577, 34.577269462460073],
              [-80.795804390518697, 34.577004636706299],
              [-80.796129796762955, 34.577047370089069],
              [-80.796182305613044, 34.57678254417236],
              [-80.797158523331262, 34.576910739500363],
              [-80.797211028776047, 34.576645913134989],
              [-80.79753643435042, 34.576688643604662],
              [-80.797483929927836, 34.576953470112791],
              [-80.797809337933117, 34.576996198925414],
              [-80.797704330116261, 34.57752585216685],
              [-80.798029740524512, 34.577568581284119],
              [-80.797872230395768, 34.578363060505453],
              [-80.798197644209424, 34.578405789168947],
              [-80.798092638164718, 34.578935441861688],
              [-80.798418053251993, 34.578978169043545],
              [-80.798313047897082, 34.579507821941093],
              [-80.798638466457362, 34.579550548509985],
              [-80.798533461772635, 34.580080200710697],
              [-80.798858882735914, 34.580122927584171],
              [-80.798806380362933, 34.580387753805169],
              [-80.800433496362061, 34.580601371129887],
              [-80.800380999851782, 34.580866198028112],
              [-80.801031850211004, 34.580951638172877],
              [-80.801084344676624, 34.580686810989071],
              [-80.801409769351835, 34.580729530046042],
              [-80.801462262456482, 34.580464702699182],
              [-80.801787687537697, 34.580507420714667],
              [-80.801892670686556, 34.579977765674791],
              [-80.802218092971955, 34.580020482538885],
              [-80.802270581906626, 34.579755653960852],
              [-80.803246848817224, 34.579883798814926],
              [-80.803299334346235, 34.579618969788328],
              [-80.80427560012761, 34.579747105388812],
              [-80.804223117665501, 34.580011934843625],
              [-80.804548541278862, 34.580054644487753],
              [-80.804496059500622, 34.580319474064986],
              [-80.80514690980759, 34.580404891893245],
              [-80.805094429715595, 34.580669720834422],
              [-80.805745283421473, 34.580755135419054],
              [-80.805797762558456, 34.580490306176017],
              [-80.806123188886872, 34.580533011551516],
              [-80.806175665573249, 34.580268182162023],
              [-80.806826517220784, 34.580353590882083],
              [-80.806878992613619, 34.580088761170437],
              [-80.807204417912871, 34.580131463613654],
              [-80.807256890855157, 34.579866633755472],
              [-80.80758231547054, 34.579909335173774],
              [-80.808233165736638, 34.579994736264794],
              [-80.808180696951311, 34.5802595665346],
              [-80.808506123604516, 34.580302265448708],
              [-80.808453654433066, 34.58056709675877],
              [-80.809104511889529, 34.580652492209182],
              [-80.808999577670932, 34.581182153520324],
              [-80.809325008406702, 34.581224850215605],
              [-80.80927254235705, 34.581489680975317],
              [-80.809597974453695, 34.581532376931037],
              [-80.809493042293923, 34.582062038691461],
              [-80.809818477863672, 34.582104734033834],
              [-80.809713546375121, 34.582634395097145],
              [-80.810038984328116, 34.582677089842633],
              [-80.809986518563605, 34.582941920945458],
              [-80.810962837521046, 34.583070000315807],
              [-80.810910375555309, 34.583334830908626],
              [-80.811235816907114, 34.583377522410153],
              [-80.811288277830513, 34.58311269077327],
              [-80.812264600849147, 34.583240759555686],
              [-80.812317058386597, 34.582975928371944],
              [-80.81329338245348, 34.583103987867595],
              [-80.813240927983472, 34.583368819479283],
              [-80.813891813718485, 34.583454188961902],
              [-80.813839360955129, 34.583719020838693],
              [-80.814490251158503, 34.583804386159343],
              [-80.814437800101771, 34.584069218301124],
              [-80.81704138262991, 34.584410645430921],
              [-80.816988939414912, 34.58467547869347],
              [-80.817314390310926, 34.58471815279772],
              [-80.817261948870083, 34.584982986166061],
              [-80.817587400037127, 34.585025659547114],
              [-80.817534959280422, 34.585290493037689],
              [-80.817860412898284, 34.585333165662497],
              [-80.817807972825818, 34.585597999275464],
              [-80.818133426714624, 34.585640671176968],
              [-80.81808098732651, 34.585905504912184],
              [-80.818731900343906, 34.585990846320257],
              [-80.818679462662658, 34.586255680320392],
              [-80.819004920701403, 34.586298349843453],
              [-80.818952483684384, 34.586563183064442],
              [-80.819277943084117, 34.586605851847686],
              [-80.819225506751422, 34.586870685191016],
              [-80.820527352913231, 34.587041352053888],
              [-80.820579785155388, 34.586776518140191],
              [-80.820905246246426, 34.586819182511483],
              [-80.821010106560948, 34.586289513453224],
              [-80.820684647535131, 34.586246850268566],
              [-80.820737078742596, 34.58598201539256],
              [-80.820411621057559, 34.585939350566655],
              [-80.820621344435324, 34.584880012364479],
              [-80.820946798029908, 34.58492267662006],
              [-80.82099922754611, 34.584657841642638],
              [-80.821650132615034, 34.584743167238074],
              [-80.821702559747749, 34.58447833195514],
              [-80.822678917365096, 34.584606313294422],
              [-80.822731340002051, 34.584341477580118],
              [-80.82370769759541, 34.58446945055033],
              [-80.823655278026195, 34.584734286692317],
              [-80.824306187531079, 34.584819596966589],
              [-80.824253769668886, 34.58508443337346],
              [-80.824904682571642, 34.585169740403209],
              [-80.824852266416499, 34.585434577074899],
              [-80.825503182737179, 34.585519881761542],
              [-80.825450768268979, 34.585784717796606],
              [-80.826427148334048, 34.585912667734917],
              [-80.826479559714215, 34.58564783037081],
              [-80.828106861445235, 34.585861060705312],
              [-80.828159267394128, 34.585596223509683],
              [-80.829135649418035, 34.585724150513606],
              [-80.829083247626841, 34.5859889881201],
              [-80.831036029118096, 34.586244819124431],
              [-80.83098363312493, 34.586509657565678],
              [-80.83130909963252, 34.586552292808854],
              [-80.831256704344042, 34.586817132273566],
              [-80.831582171122591, 34.586859766793545],
              [-80.831634565388285, 34.586594927186262],
              [-80.833261902506962, 34.586808085786771],
              [-80.833314290251479, 34.586543246364876],
              [-80.833639756992469, 34.586585874403831],
              [-80.833587370290871, 34.586850714869499],
              [-80.834238307962352, 34.586935970371066],
              [-80.834290693708368, 34.586671129603822],
              [-80.835267099117232, 34.586799004917452],
              [-80.835319480387412, 34.58653416462051],
              [-80.835970417327459, 34.586619409826454],
              [-80.83602279621438, 34.586354569224412],
              [-80.836348264703432, 34.58639718990225],
              [-80.836295886859418, 34.58666203154813],
              [-80.836946825827155, 34.586747271459402],
              [-80.836894450780548, 34.587012113353232],
              [-80.83721992123904, 34.587054731685292],
              [-80.837167546877268, 34.587319573701258],
              [-80.83749301869635, 34.587362191293209],
              [-80.837440644999347, 34.587627032529952],
              [-80.838742543055645, 34.587797496395808],
              [-80.83879491375157, 34.587532654572776],
              [-80.839120388349968, 34.587575267735794],
              [-80.839172756595289, 34.58731042576666],
              [-80.839823704780372, 34.587395650061843],
              [-80.839876070622168, 34.58713080688635],
              [-80.841828916039049, 34.587386456853629],
              [-80.841776557403648, 34.587651299965422],
              [-80.84242751097392, 34.587736509239697],
              [-80.842479867563824, 34.587471665843125],
              [-80.843130820439711, 34.587556871302738],
              [-80.843183175736087, 34.587292027584404],
              [-80.843508651657928, 34.587334628848097],
              [-80.843561005573392, 34.587069784065719],
              [-80.843886479720354, 34.587112384321316],
              [-80.843938832295592, 34.586847540277695],
              [-80.844589781736929, 34.586932737823183],
              [-80.844642130818642, 34.586667892589958],
              [-80.844967605023143, 34.586710489896674],
              [-80.845019952744408, 34.586445644500849],
              [-80.845345426263862, 34.5864882407827],
              [-80.845397772624608, 34.586223395224209],
              [-80.846048718631138, 34.586308584855821],
              [-80.846101062608483, 34.586043738992444],
              [-80.846426535095631, 34.586086332342191],
              [-80.846478877712485, 34.58582148631622],
              [-80.846804349514471, 34.585864078641151],
              [-80.84685668968109, 34.585599232469292],
              [-80.847507632232265, 34.585684413285726],
              [-80.847559971105397, 34.585419566792261],
              [-80.847885440785021, 34.585462156201892],
              [-80.847937778297791, 34.585197309545791],
              [-80.847612308551106, 34.585154720295307],
              [-80.847716984609079, 34.584625027207245],
              [-80.847391517245597, 34.584582437358939],
              [-80.847443855770507, 34.584317590025648],
              [-80.84711839085729, 34.584274999420444],
              [-80.847170728997725, 34.584010153127338],
              [-80.846845265445012, 34.583967561782039],
              [-80.847002284137517, 34.583173021355748],
              [-80.846676822900747, 34.583130429571824],
              [-80.846729162805502, 34.582865582564551],
              [-80.847054621929743, 34.582908174222879],
              [-80.847368647001105, 34.581319089116221],
              [-80.847694099236961, 34.581361679054844],
              [-80.84779876987065, 34.580831983267394],
              [-80.848124220398986, 34.58087457203893],
              [-80.848281219629058, 34.580080026444648],
              [-80.848606667426949, 34.580122613906774],
              [-80.848763659460857, 34.579328067721264],
              [-80.848438214730905, 34.57928548068616],
              [-80.848542876787022, 34.57875578250647],
              [-80.848595206773908, 34.578490933845629],
              [-80.848269765449416, 34.578448346355152],
              [-80.848374427504879, 34.577918647438388],
              [-80.848048988563207, 34.577876059350203],
              [-80.848153651333732, 34.577346361539242],
              [-80.848479088230221, 34.577388949342712],
              [-80.848793061133264, 34.575799850986279],
              [-80.849118491142207, 34.575842437070214],
              [-80.849641739391942, 34.573193930215339],
              [-80.849967159513199, 34.573236513993507],
              [-80.850071802825383, 34.572706811020012],
              [-80.850397221239163, 34.572749393631199],
              [-80.85044954137669, 34.572484542422835],
              [-80.851425794485948, 34.57261228455242],
              [-80.851478111198205, 34.572347431995794],
              [-80.851803528937339, 34.572390011694424],
              [-80.851855844289531, 34.572125158975638],
              [-80.852181260233749, 34.572167736765081],
              [-80.852285886791179, 34.57163803099985],
              [-80.851960472892003, 34.571595453495028],
              [-80.852117412719693, 34.570800895133267],
              [-80.851792002225366, 34.570758317173087],
              [-80.852001255380145, 34.569698904500946],
              [-80.85232666178473, 34.56974148189186],
              [-80.852378973762299, 34.569476628974108],
              [-80.853029785539036, 34.569561780824451],
              [-80.853082094024359, 34.569296926717712],
              [-80.853407500486028, 34.569339501160322],
              [-80.853564420870725, 34.568544939195284],
              [-80.85388982351293, 34.568587512345523],
              [-80.853942128603848, 34.568322657874695],
              [-80.854267530561046, 34.568365230000374],
              [-80.8543721376858, 34.567835520714681],
              [-80.855673740709321, 34.568005799256341],
              [-80.855726039675972, 34.567740944014545],
              [-80.857353045913428, 34.567953770727755],
              [-80.857300753148465, 34.568218626664041],
              [-80.857951560057714, 34.568303751818441],
              [-80.857899269000285, 34.568568608019454],
              [-80.858224673983329, 34.56861116941549],
              [-80.858172382521303, 34.568876025755884],
              [-80.858497789953731, 34.568918586395043],
              [-80.858445499156517, 34.569183441956454],
              [-80.85877090794871, 34.569226001855561],
              [-80.858927772931054, 34.568431432031645],
              [-80.859253178993171, 34.568473990621726],
              [-80.859410037852257, 34.567679419291274],
              [-80.859084635947042, 34.567636861111119],
              [-80.859136922588021, 34.567372004404014],
              [-80.858811522042615, 34.567329445483836],
              [-80.859020670351782, 34.566270016305886],
              [-80.859996859641726, 34.566397688729417],
              [-80.860049142531224, 34.566132830737544],
              [-80.860374538956066, 34.566175385398388],
              [-80.860426819396238, 34.565910527261387],
              [-80.860752215156367, 34.565953081798973],
              [-80.860804495326576, 34.56568822348315],
              [-80.861129890401529, 34.56573077699624],
              [-80.861286724711576, 34.564936200618995],
              [-80.861612115967034, 34.564978752839991],
              [-80.861716668072887, 34.564449034577137],
              [-80.862042058689937, 34.564491584713181],
              [-80.862094332680172, 34.564226725869084],
              [-80.861768944154534, 34.564184174957049],
              [-80.862082590315438, 34.562595017774399],
              [-80.861757208261096, 34.562552466833573],
              [-80.86186175630128, 34.562022746697828],
              [-80.861536376628678, 34.561980195159236],
              [-80.861588650630807, 34.561715335663251],
              [-80.861263273407289, 34.561672783367811],
              [-80.861315548094439, 34.561407923994331],
              [-80.86099017114087, 34.561365370975821],
              [-80.861042447582292, 34.561100510806753],
              [-80.86071707198812, 34.56105795704832],
              [-80.860926177401737, 34.55999851766267],
              [-80.860600806234004, 34.559955963591122],
              [-80.86065308303391, 34.55969110360828],
              [-80.860327714314991, 34.559648548779819],
              [-80.860379990690049, 34.55938368803524],
              [-80.860054623330555, 34.559341132466869],
              [-80.860106901500643, 34.5590762727293],
              [-80.859781535500574, 34.55903371642102],
              [-80.859990647767347, 34.557974276058125],
              [-80.860966733423112, 34.55810194064621],
              [-80.861019007842444, 34.557837079624811],
              [-80.861669731980697, 34.557922184643623],
              [-80.86177427354005, 34.557392462006845],
              [-80.861448914436224, 34.557349910206106],
              [-80.861710268614644, 34.556025603064825],
              [-80.86138491495872, 34.555983051093207],
              [-80.861437185591384, 34.555718189932151],
              [-80.860786480225443, 34.555633082742588],
              [-80.860838753654704, 34.555368221829688],
              [-80.860188051681746, 34.55528311139615],
              [-80.860240326818015, 34.555018250748219],
              [-80.86089102563686, 34.55510336001273],
              [-80.860943298371396, 34.554838498159391],
              [-80.861268647284831, 34.554881052227572],
              [-80.861320917570666, 34.554616190229353],
              [-80.861646266868107, 34.554658742354924],
              [-80.861698535794602, 34.554393880194958],
              [-80.862023883317704, 34.554436431313043],
              [-80.862076150885073, 34.554171568991237],
              [-80.86240149772307, 34.554214119084975],
              [-80.862506030891552, 34.553684394081586],
              [-80.862831376022427, 34.553726943008634],
              [-80.862883640534164, 34.553462080343976],
              [-80.863208984980034, 34.553504628246664],
              [-80.863261247022805, 34.553239764535796],
              [-80.86358659078374, 34.553282311414208],
              [-80.863638852577097, 34.553017448426054],
              [-80.863964195652912, 34.553059994280112],
              [-80.86401645606675, 34.55279513022888],
              [-80.864341798457517, 34.55283767505864],
              [-80.864655345571336, 34.551248490424314],
              [-80.864330009313065, 34.551205946447979],
              [-80.864486781355524, 34.550411352943094],
              [-80.864161449589716, 34.55036880849449],
              [-80.864318221644538, 34.54957421434024],
              [-80.864968879401275, 34.549659302403334],
              [-80.865021134391128, 34.549394437055923],
              [-80.865346462742849, 34.54943697917146],
              [-80.865294209864388, 34.54970184464419],
              [-80.86561953957505, 34.549744386019903],
              [-80.865671791431382, 34.549479520404965],
              [-80.866322450909081, 34.549564600208797],
              [-80.866374700404847, 34.54929973519129],
              [-80.867025358097052, 34.549384811199438],
              [-80.867077605191398, 34.549119944976781],
              [-80.867402933520864, 34.549162481515488],
              [-80.867455180345488, 34.54889761511437],
              [-80.867780507989877, 34.548940150628802],
              [-80.867884996405692, 34.5484104175179],
              [-80.867559670805235, 34.548367882287863],
              [-80.867716403947355, 34.547573282001522],
              [-80.868041725392359, 34.547615816821931],
              [-80.868093968063562, 34.547350949278027],
              [-80.868419289912836, 34.547393483057185],
              [-80.868471531225097, 34.54712861535166],
              [-80.869772816813978, 34.54729874109595],
              [-80.869825052632564, 34.547033873720714],
              [-80.870150374101826, 34.547076401903901],
              [-80.870202609650846, 34.546811534350169],
              [-80.870527929345684, 34.546854061526105],
              [-80.870684629775084, 34.546059456536653],
              [-80.871009946761347, 34.546101983305313],
              [-80.871062178898413, 34.545837114488627],
              [-80.870736861844719, 34.545794587879136],
              [-80.87099801927792, 34.544470242465472],
              [-80.871323330132512, 34.544512768381011],
              [-80.871532245245845, 34.543453289882741],
              [-80.871857552349439, 34.54349581434758],
              [-80.872066456855634, 34.542436333187048],
              [-80.872391760208473, 34.542478856201171],
              [-80.872339535603857, 34.542743726437038],
              [-80.872664839226033, 34.542786248728333],
              [-80.872717062808761, 34.542521378350351],
              [-80.87304236683508, 34.542563899600474],
              [-80.873094589038558, 34.542299028159789],
              [-80.873745196057712, 34.542384067729593],
              [-80.873797414811889, 34.542119196903705],
              [-80.874122717784161, 34.542161714322162],
              [-80.874174936268943, 34.541896843317872],
              [-80.874500237487354, 34.541939360630508],
              [-80.874552454592902, 34.541674488563537],
              [-80.874877756215426, 34.541717004835],
              [-80.874929970872998, 34.541452132623689],
              [-80.877857689190662, 34.541834735427756],
              [-80.877909894314385, 34.541569861917928],
              [-80.878235196150257, 34.541612368200923],
              [-80.878287399936028, 34.541347495431218],
              [-80.878612702175886, 34.54139000067287],
              [-80.878664904582365, 34.541125126840498],
              [-80.878339603364438, 34.541082621740891],
              [-80.878391806456278, 34.540817748031479],
              [-80.877741208142723, 34.540732734552137],
              [-80.877897821508995, 34.539938113260249],
              [-80.878223116897502, 34.539980620473763],
              [-80.878275319664539, 34.539715746829835],
              [-80.878600614347192, 34.539758252117892],
              [-80.878652815755714, 34.539493378312706],
              [-80.879303405196936, 34.539578386842884],
              [-80.879355603115229, 34.539313511850054],
              [-80.879680896784549, 34.53935601510927],
              [-80.879733094433661, 34.539091139938037],
              [-80.880058387417719, 34.539133642173155],
              [-80.880110583708358, 34.538868766840764],
              [-80.881086461573233, 34.538996266025251],
              [-80.881138654461637, 34.538731390247442],
              [-80.882114532290174, 34.538858881067796],
              [-80.88216672179702, 34.538594005745907],
              [-80.882492014050868, 34.538636500479569],
              [-80.882648574156818, 34.537841871320211],
              [-80.88297386368157, 34.537884364745537],
              [-80.8830782333591, 34.537354611593024],
              [-80.883728809330748, 34.537439595229564],
              [-80.883780990531875, 34.537174718357633],
              [-80.884106278000615, 34.537217208710885],
              [-80.884158458932575, 34.536952331660686],
              [-80.884483745715954, 34.536994820989896],
              [-80.884796814759952, 34.53540555388193],
              [-80.88512209574877, 34.535448041476819],
              [-80.885278622097672, 34.534653406346841],
              [-80.88495334526344, 34.534610919161118],
              [-80.885214221192371, 34.533286524898372],
              [-80.88488894980361, 34.533244037541081],
              [-80.884941124557841, 34.532979158419948],
              [-80.884615854527198, 34.532936670322762],
              [-80.884824556445139, 34.531877154182602],
              [-80.885149821299777, 34.531919641728464],
              [-80.885201995460932, 34.531654762495386],
              [-80.8855272607193, 34.531697249000295],
              [-80.885579432433261, 34.531432369623246],
              [-80.88590469700631, 34.531474855104115],
              [-80.88595686736214, 34.531209975566121],
              [-80.886282130160765, 34.531252460040172],
              [-80.886334299158463, 34.530987580341197],
              [-80.886659562360876, 34.531030063774104],
              [-80.886711729979751, 34.53076518301279],
              [-80.887036992517537, 34.53080766632312],
              [-80.88708915877821, 34.530542785400812],
              [-80.887739681689069, 34.530627747306113],
              [-80.887791845590726, 34.530362866982109],
              [-80.888117105984392, 34.530405346478453],
              [-80.888169268507255, 34.530140465092153],
              [-80.888819789349313, 34.530225421137921],
              [-80.88947031155756, 34.530310374557438],
              [-80.889522469657109, 34.53004549258398],
              [-80.890823513981019, 34.530215386469493],
              [-80.890875666568334, 34.529950503926315],
              [-80.892501974975744, 34.530162850721844],
              [-80.892554122118099, 34.529897967449536],
              [-80.892879384222269, 34.529940434014122],
              [-80.892931528917529, 34.529675550598107],
              [-80.893256790315448, 34.5297180152374],
              [-80.893361078831958, 34.529188248030472],
              [-80.89433685677605, 34.529315637641965],
              [-80.894388997464759, 34.529050753584201],
              [-80.894714256074067, 34.529093215188418],
              [-80.894662117496182, 34.529358099370988],
              [-80.894987377463522, 34.529400560235331],
              [-80.895039516087877, 34.529135674992226],
              [-80.895690036076687, 34.529220593774014],
              [-80.895794306072574, 34.528690823599035],
              [-80.896119565072269, 34.528733281374485],
              [-80.896171698533863, 34.528468395666081],
              [-80.896822214388507, 34.528553307403271],
              [-80.896874345491184, 34.5282884222934],
              [-80.898175376058191, 34.528458234634392],
              [-80.898227502717049, 34.52819334803646],
              [-80.899203276368297, 34.528320697151429],
              [-80.899151153863755, 34.528585584158009],
              [-80.900126933605208, 34.528712925761752],
              [-80.900179054133545, 34.528448038312128],
              [-80.901154833834397, 34.528575371552876],
              [-80.901206950982257, 34.528310484559917],
              [-80.902507991899483, 34.528480248086595],
              [-80.90256010351429, 34.528215359622997],
              [-80.903210623966416, 34.52830023671239],
              [-80.903262733201728, 34.528035347946158],
              [-80.903587992355057, 34.528077784584063],
              [-80.903640100232423, 34.527812895657071],
              [-80.903965359789154, 34.527855331253839],
              [-80.904017465219667, 34.527590442183445],
              [-80.904342724090554, 34.527632876756435],
              [-80.904394829252468, 34.527367987508008],
              [-80.904720086348505, 34.527410421074471],
              [-80.90477219015267, 34.527145531665354],
              [-80.905097446563019, 34.527187964207982],
              [-80.905149549009366, 34.526923074638106],
              [-80.905474804733828, 34.526965506156955],
              [-80.905526904712602, 34.526700615542424],
              [-80.905852160840311, 34.526743046020179],
              [-80.90590425948227, 34.526478156146389],
              [-80.906554769613081, 34.526563014189627],
              [-80.906606865854599, 34.52629812311185],
              [-80.907582628871381, 34.526425403162989],
              [-80.90763472280085, 34.526160511623509],
              [-80.907959976789812, 34.526202936401425],
              [-80.908064159419339, 34.52567315301706],
              [-80.908389411700696, 34.525715576629402],
              [-80.908441501489918, 34.525450684767236],
              [-80.908766753085516, 34.525493107355828],
              [-80.908818841517018, 34.52522821533303],
              [-80.909144092426658, 34.525270636897851],
              [-80.909248265149742, 34.524740852529561],
              [-80.909573514351777, 34.524783272928865],
              [-80.909625599732308, 34.524518380566043],
              [-80.90995084822751, 34.524560799040174],
              [-80.910055014848481, 34.524031013992158],
              [-80.910380261636064, 34.524073431300693],
              [-80.910640666595668, 34.522748964948683],
              [-80.910965908631482, 34.522791381567416],
              [-80.911122144269498, 34.521996700028247],
              [-80.910796905319614, 34.521954284736402],
              [-80.911109373596119, 34.520364919301528],
              [-80.910784142180077, 34.520322503060299],
              [-80.910836220808932, 34.520057609336902],
              [-80.913112840579245, 34.520354501711317],
              [-80.913438073303098, 34.520396910736487],
              [-80.913386003957243, 34.52066180647865],
              [-80.913711238059392, 34.520704215665162],
              [-80.913763306383572, 34.520439319781161],
              [-80.914413773530811, 34.520524134323658],
              [-80.914465839475966, 34.520259238137363],
              [-80.914791072541945, 34.520301644394685],
              [-80.914843137129623, 34.520036748047971],
              [-80.915168369509757, 34.520079153281614],
              [-80.915220431651107, 34.519814256791797],
              [-80.915545663324266, 34.519856660100459],
              [-80.915597725197259, 34.519591763432985],
              [-80.917223883531577, 34.519803767842269],
              [-80.91727593887164, 34.519538870464459],
              [-80.91792640251586, 34.519623665951954],
              [-80.917978455477055, 34.51935876827195],
              [-80.918303686770372, 34.519401164100493],
              [-80.918355738374288, 34.519136266260105],
              [-80.919006199924453, 34.51922105498803],
              [-80.91905824914943, 34.518956156845505],
              [-80.922635800452625, 34.519422431139013],
              [-80.922583763555068, 34.519687330823629],
              [-80.923234234918965, 34.519772096606005],
              [-80.923182198640433, 34.520036996572969],
              [-80.923507436370755, 34.520079377373023],
              [-80.923455401867287, 34.52034427744568],
              [-80.923780639887084, 34.520386658424421],
              [-80.923728606069815, 34.520651558620301],
              [-80.924379086227177, 34.520736317296773],
              [-80.924327054117882, 34.521001217757529],
              [-80.927904732942338, 34.521467329389964],
              [-80.927956752723262, 34.52120242738787],
              [-80.92828199752914, 34.521244795243092],
              [-80.928334015974031, 34.520979893982044],
              [-80.928984503459489, 34.521064626781076],
              [-80.929036519525411, 34.520799725218133],
              [-80.930012250748348, 34.52092681736864],
              [-80.930064263392367, 34.520661914460632],
              [-80.930389507166481, 34.520704277500805],
              [-80.93044151845335, 34.520439374432478],
              [-80.931417246481146, 34.520566455167753],
              [-80.931365239348494, 34.520831358643505],
              [-80.931690484442967, 34.520873717254766],
              [-80.931586469037541, 34.521403524469029],
              [-80.931911716510868, 34.521445882481629],
              [-80.931807702875361, 34.521975688986039],
              [-80.932132952727756, 34.522018046400142],
              [-80.932080946962699, 34.522282949306621],
              [-80.932406198172188, 34.522325305980459],
              [-80.932458202915413, 34.522060402932325],
              [-80.932783453438404, 34.522102758582605],
              [-80.932887460942979, 34.521572951228272],
              [-80.933212709757655, 34.521615305713375],
              [-80.93336871485775, 34.52082059277442],
              [-80.933693960942378, 34.520862945952508],
              [-80.933953955812498, 34.519538423337224],
              [-80.934279197123345, 34.519580774925259],
              [-80.934331194048553, 34.519315869303583],
              [-80.934656434672902, 34.519358219868067],
              [-80.934708429173313, 34.519093315005087],
              [-80.935358908274182, 34.519178012321362],
              [-80.935410901484659, 34.518913107138992],
              [-80.935736141071331, 34.518955454774542],
              [-80.935788131814689, 34.518690548548285],
              [-80.936438608861081, 34.518775240907978],
              [-80.936490598314606, 34.518510334362368],
              [-80.936815835774397, 34.518552679086582],
              [-80.936867823870912, 34.518287772380823],
              [-80.937518298820393, 34.518372457981378],
              [-80.937570283449119, 34.518107550991473],
              [-80.938220757717104, 34.518192233682555],
              [-80.938272739967104, 34.517927326390918],
              [-80.938597976037684, 34.517969666280848],
              [-80.938649956930746, 34.517704758829097],
              [-80.938975193382376, 34.517747096776574],
              [-80.9390271729186, 34.517482189164731],
              [-80.939352407615885, 34.51752452700768],
              [-80.939300429101422, 34.517789434761127],
              [-80.939625665134358, 34.517831770962445],
              [-80.939677642627046, 34.517566863067401],
              [-80.941954300440997, 34.51786319434521],
              [-80.942006269335849, 34.517598284556854],
              [-80.942656744121962, 34.51768294228475],
              [-80.942604776160465, 34.517947851472577],
              [-80.94293001563301, 34.517990179597099],
              [-80.942878048358224, 34.518255088908006],
              [-80.943203289166462, 34.518297415390876],
              [-80.943151323667337, 34.51856232480722],
              [-80.943801807243631, 34.518646975428098],
              [-80.943749842364156, 34.518911885126734],
              [-80.944075086221147, 34.518954209247021],
              [-80.943971159657281, 34.51948402793527],
              [-80.944296405893041, 34.519526351456712],
              [-80.944192478943577, 34.520056170371731],
              [-80.945168225792457, 34.520183136493991],
              [-80.945116265435885, 34.520448046790186],
              [-80.945441516056206, 34.520490366307278],
              [-80.945337596360332, 34.521020186225833],
              [-80.945662849380739, 34.521062506045403],
              [-80.945558930345868, 34.52159232437026],
              [-80.945884185745129, 34.521634643591007],
              [-80.945832226757659, 34.52189955331783],
              [-80.946157484602864, 34.521941871780442],
              [-80.94605356764535, 34.522471690560117],
              [-80.946378826780489, 34.522514008441519],
              [-80.94627491161566, 34.523043827412387],
              [-80.946600173108379, 34.523086143793556],
              [-80.946496257558181, 34.523615962990768],
              [-80.94682152254029, 34.523658279656651],
              [-80.946769565273911, 34.523923188918239],
              [-80.947094831591599, 34.523965503942414],
              [-80.947146787836004, 34.52370059453925],
              [-80.948122585776119, 34.523827535697819],
              [-80.948174537530562, 34.523562625869239],
              [-80.948499803144372, 34.523604937082389],
              [-80.948551754630813, 34.523340027075939],
              [-80.949202284840894, 34.523424647474613],
              [-80.9491503353984, 34.523689557764008],
              [-80.950126136302643, 34.523816482622181],
              [-80.950178081590181, 34.523551571926014],
              [-80.951479150028405, 34.52372079065416],
              [-80.951427208828875, 34.523985701916388],
              [-80.952077747091039, 34.524070305820807],
              [-80.952025807600592, 34.524335217347343],
              [-80.953326893342464, 34.524504416021607],
              [-80.953274957605316, 34.524769328095509],
              [-80.95392550506358, 34.524853921963974],
              [-80.953873571035516, 34.525118834302113],
              [-80.954198846833563, 34.525161130045916],
              [-80.95409498088452, 34.525690954030267],
              [-80.954420257972572, 34.525733249192783],
              [-80.954368326051622, 34.525998160838945],
              [-80.954693604496555, 34.526040455260805],
              [-80.954589740626446, 34.526570279699278],
              [-80.954915022539566, 34.526612573504231],
              [-80.95481115935263, 34.527142397249754],
              [-80.955136443644861, 34.527184690455584],
              [-80.955084512026602, 34.527449602450787],
              [-80.956710943578926, 34.527661054153704],
              [-80.956762870086479, 34.527396141451291],
              [-80.957088156387982, 34.527438429364821],
              [-80.957140080449577, 34.527173516520179],
              [-80.957465366063801, 34.527215803410201],
              [-80.957569211116748, 34.526685976480856],
              [-80.957894495021534, 34.526728262205964],
              [-80.957998335601872, 34.526198434937058],
              [-80.958323617797276, 34.52624071949726],
              [-80.958427456083129, 34.525710891853272],
              [-80.958752736547638, 34.525753174347294],
              [-80.958804653087981, 34.525488260824439],
              [-80.959129932865181, 34.525530542294909],
              [-80.95918184913765, 34.525265628594234],
              [-80.959507127138522, 34.525307909059208],
              [-80.959559042032609, 34.525042994297131],
              [-80.95988431936766, 34.525085274639977],
              [-80.95993623290488, 34.524820359717999],
              [-80.960912065987486, 34.524947193191586],
              [-80.960860155516556, 34.525212108537787],
              [-80.961185435279688, 34.525254385334605],
              [-80.961133526563643, 34.525519299884543],
              [-80.961081616445412, 34.525784215334873],
              [-80.961406898566011, 34.52582649063114],
              [-80.961354990224095, 34.526091406186481],
              [-80.96168027370166, 34.52613368074212],
              [-80.961576456947313, 34.52666351119634],
              [-80.962227029016589, 34.526748059128145],
              [-80.962175122715351, 34.527012974150566],
              [-80.962500410263573, 34.527055246484146],
              [-80.962448504649771, 34.527320161629319],
              [-80.962773793554803, 34.52736243322218],
              [-80.963749663388938, 34.527489243591759],
              [-80.963697761529119, 34.527754159283788],
              [-80.964348344396015, 34.527838694813468],
              [-80.964296444245946, 34.528103610769612],
              [-80.96494703156354, 34.528188142134525],
              [-80.96489513421227, 34.528453058336886],
              [-80.966196316995749, 34.528622112849668],
              [-80.96614442228794, 34.52888702871531],
              [-80.968096213703987, 34.529140583950202],
              [-80.968044325883952, 34.529405500627377],
              [-80.968694928455918, 34.529490011651902],
              [-80.969345531277227, 34.529574519165948],
              [-80.969397415008103, 34.529309601923501],
              [-80.971999834744494, 34.529647594608946],
              [-80.972051709940942, 34.529382675316057],
              [-80.972377013019241, 34.529424919950678],
              [-80.972325138845164, 34.529689839384808],
              [-80.973301053154145, 34.529816568419584],
              [-80.973249182801425, 34.530081488241002],
              [-80.973899796058078, 34.530165970136103],
              [-80.973847927393834, 34.530430889320094],
              [-80.974173235546203, 34.530473129085671],
              [-80.974121367591536, 34.530738049293589],
              [-80.97444667818975, 34.530780288300356],
              [-80.974394810901245, 34.531045207729534],
              [-80.974720121767305, 34.531087446013409],
              [-80.974668255166549, 34.531352365565198],
              [-80.974993567367875, 34.531394602206767],
              [-80.974941701454881, 34.531659521881153],
              [-80.975267016123851, 34.531701758665228],
              [-80.975163285339278, 34.532231598240493],
              [-80.975488602365814, 34.532273833523611],
              [-80.975436737494064, 34.532538753424433],
              [-80.976087373549547, 34.532623223447153],
              [-80.976035510366515, 34.532888142710455],
              [-80.976686150872155, 34.532972608567796],
              [-80.976634289421128, 34.533237528996189],
              [-80.977610255766109, 34.533364222040063],
              [-80.977662114149979, 34.53309930118786],
              [-80.979288726294612, 34.533310437610446],
              [-80.979236873001369, 34.533575358267385],
              [-80.979887523329879, 34.533659806745192],
              [-80.979939373489202, 34.533394885823789],
              [-80.980590022021445, 34.533479330508328],
              [-80.98064186977966, 34.533214408384488],
              [-80.982268491838781, 34.533425503500652],
              [-80.982216650260114, 34.533690425411145],
              [-80.983192630571025, 34.533817072137651],
              [-80.983140791725432, 34.534081994453018],
              [-80.983466120179699, 34.534124208104785],
              [-80.983414280933104, 34.534389130560641],
              [-80.983739611833258, 34.53443134345337],
              [-80.983687773274809, 34.534696266031752],
              [-80.984338437033969, 34.534780689470871],
              [-80.984390273547419, 34.534515766610212],
              [-80.985040936598949, 34.53460018623808],
              [-80.984989102130513, 34.534865109381052],
              [-80.986615771244857, 34.535076142807121],
              [-80.986667599511563, 34.534811218976394],
              [-80.98796893737908, 34.53498002908119],
              [-80.987917113202499, 34.535244953476507],
              [-80.988242449783471, 34.535287153257705],
              [-80.987931504863823, 34.536876697361166],
              [-80.988256847936228, 34.536918898008217],
              [-80.988153199705053, 34.537448745261614],
              [-80.988478545157008, 34.53749094530874],
              [-80.988374897612175, 34.538020791868],
              [-80.988700245443496, 34.538062991315023],
              [-80.988648421658624, 34.538327915167386],
              [-80.989624472360831, 34.538454506799305],
              [-80.989572651287759, 34.538719430154948],
              [-80.990548708066029, 34.538846014269566],
              [-80.9906005249819, 34.538581090508735],
              [-80.990925877242987, 34.538623283001435],
              [-80.990977692780248, 34.53835835817943],
              [-80.991303044352804, 34.538400549648699],
              [-80.991354858554971, 34.538135625568138],
              [-80.992330910031612, 34.538262194295065],
              [-80.9923827208099, 34.537997268871102],
              [-80.992708071673917, 34.538039456529006],
              [-80.99265626191837, 34.538304382094026],
              [-80.994933731214076, 34.538599672036796],
              [-80.994881929372113, 34.5388645985525],
              [-80.99520728495466, 34.538906779434058],
              [-80.995259085773952, 34.538641852777282],
              [-80.995909795896139, 34.53872621161134],
              [-80.995961594335967, 34.538461284653678],
              [-80.996286948875493, 34.53850346260618],
              [-80.996338745958525, 34.538238535488702],
              [-80.997640162255735, 34.538407237028103],
              [-80.997588369263539, 34.538672164709887],
              [-80.998564437762553, 34.538798680901103],
              [-80.998616227708695, 34.538533753697635],
              [-80.999917653337661, 34.538702429630803],
              [-81.000021222913162, 34.538172572254801],
              [-81.000346578393305, 34.538214739196398],
              [-81.000398362201921, 34.537949810330112],
              [-81.000723715882245, 34.537991975365173],
              [-81.000775498334164, 34.537727046339207],
              [-81.001100852414964, 34.537769210332733],
              [-81.001152632421025, 34.537504281165205],
              [-81.001477985835066, 34.537546445036789],
              [-81.002454046948031, 34.537672929572985],
              [-81.002402271032778, 34.537937859304542],
              [-81.002727625694263, 34.537980018904634],
              [-81.002675850467753, 34.538244948758475],
              [-81.003001207575167, 34.538287107599118],
              [-81.002897658165651, 34.538816967532547],
              [-81.003223017652402, 34.538859125772781],
              [-81.003171243469581, 34.539124055852419],
              [-81.003496604313014, 34.539166213351308],
              [-81.003444830818907, 34.539431143553131],
              [-81.003770193019037, 34.539473300310654],
              [-81.003718420213744, 34.539738230634683],
              [-81.004369147661464, 34.53982254178473],
              [-81.004420918421232, 34.539557611178665],
              [-81.005071646248226, 34.539641918499115],
              [-81.005019877534011, 34.539906849387066],
              [-81.005345242426557, 34.539949001873794],
              [-81.005293475468406, 34.540213931964431],
              [-81.005618841717748, 34.540256083709863],
              [-81.005515307756056, 34.540785944134932],
              [-81.005840676384807, 34.540828095279863],
              [-81.00578890938128, 34.541093025614373],
              [-81.006114280455975, 34.541135175999578],
              [-81.006062514141348, 34.541400106456251],
              [-81.006387886572867, 34.541442256100147],
              [-81.006439651864568, 34.541177325502566],
              [-81.006765022517939, 34.5412194741413],
              [-81.00681678645293, 34.540954543383975],
              [-81.007142156417387, 34.540996690999386],
              [-81.007193918973741, 34.540731759181014],
              [-81.007519288249171, 34.540773905773086],
              [-81.007571049470855, 34.540508974696287],
              [-81.009523269221262, 34.540761833015893],
              [-81.009471515225712, 34.541026764920105],
              [-81.010773006901914, 34.541195319430145],
              [-81.010721256663899, 34.541460251879265],
              [-81.013324265288546, 34.541797318752018],
              [-81.013272523988931, 34.542062252291394],
              [-81.013597902719823, 34.542104381480257],
              [-81.013546162087252, 34.542369314240482],
              [-81.013871542196796, 34.542411443589053],
              [-81.013819802253352, 34.542676376471363],
              [-81.014145183697451, 34.542718504177188],
              [-81.014093444465274, 34.542983438082814],
              [-81.014418827266041, 34.543025565047174],
              [-81.01436708872302, 34.543290499074885],
              [-81.01469247288054, 34.543332625297616],
              [-81.014640735004619, 34.543597558546075],
              [-81.014966120518721, 34.543639684027305],
              [-81.014862645811519, 34.544169550749579],
              [-81.015188034794477, 34.544211675611798],
              [-81.015136297963238, 34.544476609085535],
              [-81.01546168721373, 34.544518733224471],
              [-81.015409951071703, 34.544783666820315],
              [-81.015735342767968, 34.544825790199397],
              [-81.015683607315282, 34.545090723917177],
              [-81.016009000368356, 34.545132846554679],
              [-81.015957265604811, 34.545397780394538],
              [-81.016608054735997, 34.545482022402531],
              [-81.016556321684718, 34.545746956505219],
              [-81.017532510964486, 34.54587331377202],
              [-81.017584240946761, 34.545608379246744],
              [-81.017909637025099, 34.545650496730289],
              [-81.017857908043709, 34.545915430495114],
              [-81.019484898511493, 34.546126003577399],
              [-81.019433175422961, 34.54639093891052],
              [-81.019758575527234, 34.546433050479585],
              [-81.019706853105916, 34.546697985033468],
              [-81.021984672584466, 34.546992744960178],
              [-81.022036387822212, 34.546727808519329],
              [-81.023338003117857, 34.546896222125518],
              [-81.023663407225555, 34.546938323104655],
              [-81.02376682540249, 34.546408449678033],
              [-81.024092228886886, 34.546450549474685],
              [-81.024143935918403, 34.546185613052167],
              [-81.024469337624012, 34.546227711843919],
              [-81.024779569826975, 34.544638087506499],
              [-81.02510496572782, 34.544680184571028],
              [-81.025156668356232, 34.544415246833879],
              [-81.025807459112542, 34.544499438033931],
              [-81.025859159339134, 34.544234499095033],
              [-81.026184554194387, 34.544276593230563],
              [-81.026236253086779, 34.544011655033373],
              [-81.026561647252578, 34.544053748145672],
              [-81.026613343699125, 34.543788809807324],
              [-81.026938737175385, 34.543830901896364],
              [-81.026990433332429, 34.543565962478738],
              [-81.02731582611915, 34.543608053544531],
              [-81.027367520941922, 34.543343114868605],
              [-81.028343697122409, 34.543469381466046],
              [-81.028292006480186, 34.543734321447083],
              [-81.028617399511504, 34.543776409001467],
              [-81.028565709536636, 34.54404134820318],
              [-81.02889110501367, 34.54408343499734],
              [-81.028942793943258, 34.543818494753573],
              [-81.029593583851565, 34.543902665412965],
              [-81.029645271490864, 34.543637724850505],
              [-81.029970664832859, 34.543679808734176],
              [-81.030022351137987, 34.54341486891348],
              [-81.030673137843209, 34.543499032832194],
              [-81.030724821746546, 34.543234091809879],
              [-81.031050214042693, 34.543276172764706],
              [-81.031101896589618, 34.543011231582959],
              [-81.031427288196099, 34.543053311514491],
              [-81.03147896829735, 34.542788370191687],
              [-81.032129752620563, 34.542872526187708],
              [-81.032233107730846, 34.542342642922868],
              [-81.03255849726844, 34.542384719784813],
              [-81.032661848999155, 34.541854836163651],
              [-81.032987237890964, 34.541896910941901],
              [-81.033038911144118, 34.541631968980973],
              [-81.033364299346147, 34.541674042736084],
              [-81.033467643895676, 34.541144158458145],
              [-81.033142258806492, 34.541102084064718],
              [-81.033193931037445, 34.540837141146305],
              [-81.033519316192766, 34.540879215380563],
              [-81.033674328928029, 34.54008438697376],
              [-81.033348947930804, 34.540042313143211],
              [-81.033452290481932, 34.539512427119661],
              [-81.033777670522397, 34.539554500650333],
              [-81.033881009694369, 34.539024614270609],
              [-81.034206386910611, 34.539066685754655],
              [-81.034258054429102, 34.538801742405269],
              [-81.035559562579706, 34.538970020756622],
              [-81.035611225673094, 34.538705076825849],
              [-81.036261979701976, 34.538789210425463],
              [-81.036210318654511, 34.53905415463764],
              [-81.036861076062294, 34.539138284988965],
              [-81.036912735041454, 34.538873339594105],
              [-81.037238113233485, 34.538915403756036],
              [-81.037289770856447, 34.538650458201843],
              [-81.037615148358626, 34.53869252134065],
              [-81.037666804625388, 34.538427575627132],
              [-81.037992181437801, 34.538469637742836],
              [-81.038147144103178, 34.537674800988142],
              [-81.038472518157548, 34.537716860898129],
              [-81.038575822511305, 34.537186968759208],
              [-81.03890119378616, 34.53722902842518],
              [-81.038952844984905, 34.536964082177789],
              [-81.039928959850869, 34.53709025271823],
              [-81.03987731281012, 34.537355199369024],
              [-81.040528059425, 34.537439309839343],
              [-81.040476414097043, 34.537704256752825],
              [-81.041127165157192, 34.537788363054858],
              [-81.04107552154214, 34.538053310230971],
              [-81.041400898594816, 34.53809536219903],
              [-81.041349255647205, 34.538360308595927],
              [-81.042000012798056, 34.538444410165894],
              [-81.041948371585661, 34.538709357726681],
              [-81.042599132115072, 34.538793456048126],
              [-81.042547492593073, 34.539058402970319],
              [-81.042872875469683, 34.539100450929475],
              [-81.042924512879381, 34.538835503885196],
              [-81.043575276474058, 34.53891959599197],
              [-81.043626912593808, 34.53865464862924],
              [-81.044277674385569, 34.538738736943486],
              [-81.044329308126223, 34.538473789280879],
              [-81.044654688509993, 34.538515832424359],
              [-81.044706319782961, 34.538250883719783],
              [-81.045031699476681, 34.538292925840182],
              [-81.04508333048274, 34.538027976957792],
              [-81.045408709486495, 34.538070018055144],
              [-81.045511967364334, 34.537540119971922],
              [-81.045837344655084, 34.537582159905554],
              [-81.045888971526878, 34.537317210704721],
              [-81.046214348105295, 34.537359248713997],
              [-81.046265973620962, 34.537094299353981],
              [-81.046591349531852, 34.537136337241513],
              [-81.046694597518382, 34.536606438184513],
              [-81.047019971693729, 34.536648474007087],
              [-81.047174838084658, 34.535853623959191],
              [-81.047500209546499, 34.535895659378845],
              [-81.047655068737654, 34.535100806958035],
              [-81.047980437463551, 34.535142841073551],
              [-81.047928818726064, 34.535407792045802],
              [-81.048254188807988, 34.535449825419413],
              [-81.048150952357503, 34.535979726688147],
              [-81.048476324795871, 34.53602175855918],
              [-81.048373089059609, 34.536551660034945],
              [-81.048698463899314, 34.536593692206033],
              [-81.048646846543505, 34.536858642605942],
              [-81.048972222739152, 34.53690067403523],
              [-81.048920607162572, 34.537165624538538],
              [-81.049245984691709, 34.537207654324583],
              [-81.049142752407391, 34.537737555593836],
              [-81.049793513668277, 34.537821613963601],
              [-81.049741899072757, 34.53808656485149],
              [-81.051043428570381, 34.538254670680878],
              [-81.051095039073687, 34.537989719230715],
              [-81.051420421001993, 34.538031744021936],
              [-81.051472029060179, 34.537766792431256],
              [-81.051797410275739, 34.537808815298142],
              [-81.051849018067045, 34.537543863529692],
              [-81.052174398592541, 34.537585885373566],
              [-81.052226003938827, 34.537320933464613],
              [-81.052551383774073, 34.537362954285477],
              [-81.052602987764445, 34.537098002217405],
              [-81.05325374638754, 34.53718204093061],
              [-81.053305347998958, 34.53691708856288],
              [-81.053956106996466, 34.537001123446572],
              [-81.054007705139782, 34.536736170797866],
              [-81.05465846342257, 34.536820201870555],
              [-81.054710059164421, 34.536555248020903],
              [-81.05536081564334, 34.536639275301425],
              [-81.055412410117981, 34.53637432203476],
              [-81.055737787833579, 34.536416334210735],
              [-81.055789379840661, 34.536151379902449],
              [-81.056114756866009, 34.536193391055548],
              [-81.056217938947441, 34.535663482984887],
              [-81.056543314259486, 34.535705492974493],
              [-81.056594903222233, 34.535440538329503],
              [-81.057245652798613, 34.535524555380135],
              [-81.057571028085945, 34.53556656258182],
              [-81.057519442169834, 34.535831516746946],
              [-81.057844818812953, 34.535873523206611],
              [-81.057793233609715, 34.536138478395088],
              [-81.058118611608649, 34.536180484112805],
              [-81.058067027073136, 34.536445438521859],
              [-81.058717786115466, 34.536529447590929],
              [-81.058666203293242, 34.536794402262366],
              [-81.058991584336638, 34.536836405613684],
              [-81.058940003293984, 34.537101360388469],
              [-81.059265385693124, 34.537143362997746],
              [-81.059213804251669, 34.537408317913147],
              [-81.059539188006624, 34.53745031978049],
              [-81.05948760834471, 34.537715274799119],
              [-81.059812993432871, 34.537757275023068],
              [-81.059761413372186, 34.538022230182335],
              [-81.060086800927962, 34.538064230546937],
              [-81.060035221557655, 34.538329185828104],
              [-81.061662168285963, 34.53853917243049],
              [-81.061610593698603, 34.538804128395412],
              [-81.061935985274289, 34.538846122844561],
              [-81.061884412466569, 34.539111078912747],
              [-81.062209805398084, 34.539153072619804],
              [-81.062003512709367, 34.540212895503053],
              [-81.062328910066242, 34.540254888889422],
              [-81.062277337641433, 34.540519845145447],
              [-81.062602736354293, 34.540561837789816],
              [-81.062551164597267, 34.540826793266376],
              [-81.063201965067336, 34.540910776188241],
              [-81.063150395024138, 34.54117573192714],
              [-81.063475796781361, 34.541217722204713],
              [-81.063424227428683, 34.541482678065364],
              [-81.063749631631012, 34.541524667582053],
              [-81.063698062968911, 34.54178962356459],
              [-81.064348873328811, 34.54187360024995],
              [-81.064297306380524, 34.542138556494656],
              [-81.064622712538096, 34.542180543663285],
              [-81.064571146257734, 34.542445499128476],
              [-81.064896554860454, 34.542487485536299],
              [-81.064793423370929, 34.543017397592891],
              [-81.06511883435293, 34.543059383398912],
              [-81.065015703579249, 34.543589295661775],
              [-81.065341116917693, 34.543631279964771],
              [-81.065289552066631, 34.543896236659243],
              [-81.065614966761032, 34.54393822022007],
              [-81.065563402577865, 34.544203176135035],
              [-81.065888818650876, 34.544245159855016],
              [-81.065734128264552, 34.545040027890153],
              [-81.0660595477176, 34.545082010247455],
              [-81.066007984250462, 34.545346966368427],
              [-81.066333406171339, 34.545388948866069],
              [-81.066230280263042, 34.54591886043147],
              [-81.066555704540676, 34.545960841426009],
              [-81.066607266649086, 34.545695885962971],
              [-81.068559809959723, 34.545947755313072],
              [-81.068508253991482, 34.546212711618615],
              [-81.069809961344021, 34.546380606507689],
              [-81.069758410248696, 34.546645564238489],
              [-81.070083839208323, 34.546687535664496],
              [-81.070032288781121, 34.546952492615695],
              [-81.07035771909672, 34.546994463299463],
              [-81.070306168271046, 34.547259420391022],
              [-81.071607896975593, 34.547427295762432],
              [-81.071659442618312, 34.54716233812821],
              [-81.072310306907625, 34.547246269786697],
              [-81.072361850170935, 34.546981311852953],
              [-81.072687281790976, 34.547023276217899],
              [-81.072738823698344, 34.54675831812505],
              [-81.073064253515525, 34.546800280584527],
              [-81.073115794066979, 34.546535322332652],
              [-81.0740920857791, 34.546661206641431],
              [-81.073885936037996, 34.547721041182854],
              [-81.074211371367454, 34.547763001415142],
              [-81.074108296548829, 34.54829291885418],
              [-81.074433735347, 34.548334878465596],
              [-81.074485271354433, 34.548069919624588],
              [-81.07513614678922, 34.548153835036324],
              [-81.075187680440123, 34.547888876797202],
              [-81.076489430116155, 34.548056696486256],
              [-81.076437900582036, 34.548321656188023],
              [-81.077414219683945, 34.548447512553551],
              [-81.07736269288786, 34.548712472657485],
              [-81.078013576353783, 34.548796372461652],
              [-81.078065101102979, 34.54853141207716],
              [-81.078390542844318, 34.548573360054831],
              [-81.078442065171004, 34.548308400431473],
              [-81.079418388252449, 34.548434239567712],
              [-81.079366867929792, 34.548699200532106],
              [-81.080017753314834, 34.548783088562175],
              [-81.079966235796036, 34.549048049769503],
              [-81.080291680555248, 34.549089992591291],
              [-81.080240162638376, 34.549354953939044],
              [-81.080565608753503, 34.549396896018415],
              [-81.08051409259437, 34.549661856567575],
              [-81.081164986802335, 34.549745739278933],
              [-81.081113472357742, 34.550010700089871],
              [-81.081438921517147, 34.55005263980167],
              [-81.081387407763856, 34.550317600734019],
              [-81.081712858279005, 34.550359539703372],
              [-81.081661345217015, 34.550624500757316],
              [-81.081986797088177, 34.550666438984194],
              [-81.081832258978466, 34.551461322453925],
              [-81.082157714252745, 34.551503260218915],
              [-81.08210620190853, 34.551768221478191],
              [-81.082431658538667, 34.551810158500693],
              [-81.08238014686286, 34.552075118980106],
              [-81.082705604849053, 34.552117055260055],
              [-81.082654094976604, 34.552382016743323],
              [-81.083305015082644, 34.55246588691665],
              [-81.083201996210761, 34.552995808622946],
              [-81.083527458264982, 34.553037742675457],
              [-81.083475950443571, 34.553302703621704],
              [-81.08445234384962, 34.553428500865429],
              [-81.084503848577171, 34.553163538597204],
              [-81.084829313049937, 34.553205469099666],
              [-81.084880817533758, 34.55294050755468],
              [-81.085206280202939, 34.552982436151865],
              [-81.085257783330889, 34.552717474447832],
              [-81.085583246420967, 34.552759402904421],
              [-81.085634747080633, 34.552494440158959],
              [-81.085960209479339, 34.552536367592559],
              [-81.08601170878309, 34.552271404688156],
              [-81.086988093789614, 34.552397180389214],
              [-81.087039589713129, 34.552132217946699],
              [-81.087365051370682, 34.552174140667432],
              [-81.087416545938211, 34.551909178065898],
              [-81.087742005815002, 34.551951099782634],
              [-81.087793499003723, 34.551686136120743],
              [-81.08844441881898, 34.551769977507988],
              [-81.088495909628165, 34.551505013546972],
              [-81.088821369566602, 34.551546933217381],
              [-81.08897583584077, 34.550752040820058],
              [-81.089301291928237, 34.550793958304865],
              [-81.089404266462239, 34.550264029653142],
              [-81.08972972083464, 34.55030594597477],
              [-81.089832690903464, 34.549776016986712],
              [-81.090158143560799, 34.549817932145224],
              [-81.090261110230685, 34.549288001900585],
              [-81.09253927493684, 34.549581383114649],
              [-81.092590750075075, 34.549316417443187],
              [-81.092916202318662, 34.549358325119236],
              [-81.092967676078203, 34.549093358387587],
              [-81.093293127630247, 34.549135265040768],
              [-81.093447543868479, 34.548340365214337],
              [-81.093772992681878, 34.548382270564375],
              [-81.093824463065843, 34.54811730347754],
              [-81.09447535855216, 34.548201111268071],
              [-81.094526826556873, 34.547936143882318],
              [-81.094852274319152, 34.547978046303797],
              [-81.094903740968292, 34.547713078759202],
              [-81.095229186949751, 34.54775498017684],
              [-81.095332117181528, 34.547225043850069],
              [-81.095657561447837, 34.547266944104685],
              [-81.09570902505358, 34.547001976223811],
              [-81.096034468628318, 34.547043875455614],
              [-81.096085930855551, 34.546778906514639],
              [-81.096411373738718, 34.546820804723552],
              [-81.096462834633272, 34.546555836525108],
              [-81.096788276824867, 34.546597733711181],
              [-81.096839736340826, 34.546332764452643],
              [-81.097165177840836, 34.546374660615868],
              [-81.097216634912044, 34.546109691217609],
              [-81.097867517950135, 34.546193480596713],
              [-81.097970427002139, 34.545663541183757],
              [-81.098295867516612, 34.545705434259524],
              [-81.0983473205211, 34.545440464385038],
              [-81.098672759254796, 34.545482356456922],
              [-81.098775662197738, 34.544952415470526],
              [-81.099101099216185, 34.544994306379564],
              [-81.099152549177703, 34.54472933616902],
              [-81.099477985504507, 34.544771226055175],
              [-81.099580882361721, 34.544241284396726],
              [-81.099906316973346, 34.544283173120043],
              [-81.100214990123874, 34.542693345112895],
              [-81.100540418926215, 34.542735232112904],
              [-81.10079763169712, 34.541410371685558],
              [-81.101123055713842, 34.541452257102307],
              [-81.101174495799484, 34.541187284288014],
              [-81.101499919124592, 34.541229168682001],
              [-81.101551357877881, 34.54096419661041],
              [-81.102527624572346, 34.541089843212994],
              [-81.102579059900378, 34.540824869801412],
              [-81.102904482505863, 34.540866750384545],
              [-81.102853048201439, 34.541131723936317],
              [-81.104154743834073, 34.541299237139562],
              [-81.104103314381035, 34.541564211214094],
              [-81.104428739580285, 34.541606086781854],
              [-81.10437730972977, 34.541871060996961],
              [-81.106004451312728, 34.542080428003125],
              [-81.105953026248073, 34.542345402900096],
              [-81.106929318245022, 34.542471012751399],
              [-81.106877896985353, 34.542735987129582],
              [-81.107203329353467, 34.542777856055523],
              [-81.10715190878561, 34.543042830555144],
              [-81.108453645600378, 34.543210295288375],
              [-81.108505062074045, 34.542945320228604],
              [-81.109481366169291, 34.543070908394789],
              [-81.109532779240425, 34.542805932896464],
              [-81.109858213215588, 34.54284779371946],
              [-81.109909623842313, 34.542582818081719],
              [-81.110235058214812, 34.542624677862854],
              [-81.110286467486191, 34.542359702066591],
              [-81.110937333000578, 34.542443418739104],
              [-81.111091554815488, 34.541648488558209],
              [-81.111416984999465, 34.541690345150428],
              [-81.111468389873536, 34.541425368860111],
              [-81.111793819365573, 34.541467224429574],
              [-81.111691011356129, 34.541997178172977],
              [-81.112341875429422, 34.542080887223868],
              [-81.112290471873592, 34.542345863916346],
              [-81.112941340409776, 34.542429569697212],
              [-81.112889938592701, 34.542694547552479],
              [-81.113215374903234, 34.542736398347898],
              [-81.113163974867362, 34.54300137630554],
              [-81.1134894114671, 34.543043227278631],
              [-81.113438012100019, 34.543308204456444],
              [-81.113763450031783, 34.543350053785581],
              [-81.113712051379935, 34.543615031986079],
              [-81.114037490666917, 34.543656880572563],
              [-81.113934694369163, 34.544186835395294],
              [-81.114260137147539, 34.544228684261022],
              [-81.114208739524855, 34.544493661784628],
              [-81.114534183635314, 34.544535509006295],
              [-81.114328593877019, 34.545595417672345],
              [-81.114654042436868, 34.545637265472436],
              [-81.114602645180369, 34.545902242281841],
              [-81.114928095095522, 34.545944089339223],
              [-81.114825301635108, 34.546474043182236],
              [-81.115150753929257, 34.546515889636737],
              [-81.115099357725342, 34.546780866670382],
              [-81.116401175332371, 34.546948241497397],
              [-81.116349782891774, 34.547213219072241],
              [-81.116675239957374, 34.547255060472253],
              [-81.116623848208917, 34.547520038168457],
              [-81.117274764316278, 34.547603719520595],
              [-81.117326152927959, 34.547338741563742],
              [-81.118627985003229, 34.547506092213496],
              [-81.118576600486577, 34.547771070730086],
              [-81.118902059807425, 34.547812906109748],
              [-81.118953444389689, 34.547547927433968],
              [-81.119604363067936, 34.547631595245811],
              [-81.119552981622576, 34.54789657418219],
              [-81.120529364652967, 34.548022069708388],
              [-81.120580743027091, 34.54775709035227],
              [-81.120906204402615, 34.547798920276044],
              [-81.120957581421379, 34.547533940761404],
              [-81.121283041015346, 34.547575769681657],
              [-81.121334416702112, 34.547310790909812],
              [-81.121659876669767, 34.547352617886801],
              [-81.121762623888898, 34.546822659125446],
              [-81.122088082140479, 34.546864484939917],
              [-81.122139453672773, 34.546599504950436],
              [-81.122464911255335, 34.546641330643482],
              [-81.122516281432397, 34.546376350495606],
              [-81.123167194455135, 34.546459998972679],
              [-81.123115826325488, 34.546724979400317],
              [-81.124092200848622, 34.546850445023118],
              [-81.123989468648546, 34.54738040668132],
              [-81.124314930267246, 34.547422227357281],
              [-81.124212198789195, 34.547952189220936],
              [-81.125514056721997, 34.54811946241319],
              [-81.125565417986849, 34.547854480940828],
              [-81.126867276037217, 34.54802173946775],
              [-81.126918632875402, 34.547756757417353],
              [-81.127244096387386, 34.547798570167288],
              [-81.127295451870395, 34.547533587958448],
              [-81.127620915755742, 34.547575398765119],
              [-81.12777497605606, 34.546780451626951],
              [-81.128100436135384, 34.54682226205189],
              [-81.128151788245418, 34.546557279489079],
              [-81.128477248698175, 34.546599087970705],
              [-81.128528598363829, 34.546334105268855],
              [-81.129504977572651, 34.54645952591811],
              [-81.12945362988917, 34.546724509058819],
              [-81.130430015151177, 34.546849922182261],
              [-81.130378671297123, 34.547114905704561],
              [-81.131029599219687, 34.547198509705339],
              [-81.130978257082035, 34.547463493488713],
              [-81.13162918837736, 34.547547094237842],
              [-81.131680527378037, 34.547282110194175],
              [-81.132656923733848, 34.547407504277146],
              [-81.132708260421126, 34.547142519776145],
              [-81.133359189844185, 34.547226111157009],
              [-81.133307856293982, 34.547491095918311],
              [-81.133633323070825, 34.547532890414601],
              [-81.133530655935132, 34.54806286018038],
              [-81.133856125090944, 34.54810465407347],
              [-81.133804791505554, 34.548369639077983],
              [-81.134130262016456, 34.548411432227994],
              [-81.134078930189617, 34.548676416433139],
              [-81.134404402055608, 34.548718208840079],
              [-81.13430173839015, 34.549248178394684],
              [-81.134627212635252, 34.549289970198416],
              [-81.13437055330462, 34.550614893096927],
              [-81.134696034066366, 34.550656683795957],
              [-81.134644701572199, 34.550921668290975],
              [-81.134970183712625, 34.550963459148257],
              [-81.135021514093438, 34.550698474532759],
              [-81.135672477296396, 34.550782052417766],
              [-81.135723805298085, 34.550517067504181],
              [-81.136049286384207, 34.550558855433188],
              [-81.136100613030678, 34.550293870361244],
              [-81.136426093400502, 34.550335656366329],
              [-81.136477418692024, 34.550070671135984],
              [-81.136802897303212, 34.550112457039127],
              [-81.136854221239631, 34.549847471650438],
              [-81.137179700223783, 34.549889255610218],
              [-81.137231022805025, 34.549624270063276],
              [-81.137556501096569, 34.549666053000415],
              [-81.137607821233402, 34.549401067314449],
              [-81.137933298832053, 34.549442849228953],
              [-81.137881978653326, 34.549707835975383],
              [-81.138532935802615, 34.549791397454783],
              [-81.138481617340659, 34.550056384462138],
              [-81.140109022785666, 34.550265274281053],
              [-81.140160335062063, 34.550000287495756],
              [-81.141136779893301, 34.550125610722105],
              [-81.141188088743107, 34.549860622597635],
              [-81.14183905048985, 34.549944167041225],
              [-81.141890358050034, 34.549679178599391],
              [-81.142215837839615, 34.549720948916161],
              [-81.142267142978895, 34.549455961236795],
              [-81.142918103681822, 34.549539498903336],
              [-81.142969406418601, 34.549274510024688],
              [-81.143294885153438, 34.549316277413453],
              [-81.143243583440537, 34.549581266431844],
              [-81.143894546129687, 34.549664798801125],
              [-81.143945844705229, 34.549399809522733],
              [-81.144271325522297, 34.549441574243403],
              [-81.144220026881442, 34.549706563680857],
              [-81.14487099155653, 34.549790090752779],
              [-81.144922288149402, 34.549525101035861],
              [-81.145898733483122, 34.549650384622396],
              [-81.145847439938706, 34.54991537385709],
              [-81.147149377208791, 34.55008240590994],
              [-81.14720066556788, 34.549817416136115],
              [-81.147526149671037, 34.549859171126485],
              [-81.147577436675178, 34.549594181194372],
              [-81.148228403849913, 34.549677689148261],
              [-81.148279688451453, 34.549412698017044],
              [-81.149907105981413, 34.549621452222709],
              [-81.149958385132123, 34.549356460374703],
              [-81.150283868833938, 34.549398208784112],
              [-81.150335145540438, 34.549133216797351],
              [-81.150986111865208, 34.549216709786869],
              [-81.150934836117543, 34.549481702072441],
              [-81.152562262804068, 34.549690418441365],
              [-81.152510992934978, 34.549955411386989],
              [-81.15283648028408, 34.549997151971354],
              [-81.152785211084691, 34.550262144136674],
              [-81.153436188823463, 34.550345622935964],
              [-81.153384921365031, 34.550610616263249],
              [-81.153710412844021, 34.550652354458506],
              [-81.153761679302008, 34.550387361892902],
              [-81.155389132181185, 34.550596039005548],
              [-81.155337870843596, 34.550861032269147],
              [-81.155988857012545, 34.550944497201094],
              [-81.155937597392324, 34.551209490725313],
              [-81.15626309254165, 34.551251221996694],
              [-81.156211833614847, 34.551516215641925],
              [-81.156537330119065, 34.551557946169886],
              [-81.156486071885638, 34.551822939936116],
              [-81.156811569721057, 34.551864668819476],
              [-81.15655528086819, 34.553189637129364],
              [-81.155578774010323, 34.553064443013426],
              [-81.155527511744012, 34.553329436388921],
              [-81.155202008732374, 34.553287703117967],
              [-81.155150745087354, 34.553552695433972],
              [-81.154825241382454, 34.553510961140368],
              [-81.154773976406133, 34.55377595419953],
              [-81.154122967940239, 34.553692482684284],
              [-81.154071700561232, 34.553957474544269],
              [-81.153746195800352, 34.553915737322612],
              [-81.153489848821863, 34.555240695645004],
              [-81.153284764147301, 34.556300660184029],
              [-81.152959249410202, 34.556258920842531],
              [-81.152907976650766, 34.556523911335425],
              [-81.152582462309695, 34.556482170951753],
              [-81.152531187129313, 34.556747162207209],
              [-81.152205672094908, 34.55670542080086],
              [-81.152154395535902, 34.556970410996705],
              [-81.151828879808164, 34.55692866856787],
              [-81.15177760189404, 34.557193658605406],
              [-81.151452084383564, 34.557151915173343],
              [-81.151349526603482, 34.557681894893555],
              [-81.151024007375455, 34.557640150299328],
              [-81.150921446199632, 34.558170128764054],
              [-81.150595925254052, 34.558128383007578],
              [-81.150544642612658, 34.55839337253262],
              [-81.150870164582486, 34.558435118428747],
              [-81.150818883700211, 34.558700107153932],
              [-81.151469930681145, 34.55878359657634],
              [-81.151418650427047, 34.559048585581472],
              [-81.152395226453123, 34.559173813511244],
              [-81.152343950030371, 34.559438802896999],
              [-81.152669477058481, 34.559480543913757],
              [-81.152618200239587, 34.55974553343993],
              [-81.15294372971232, 34.559787273693694],
              [-81.152892453587015, 34.56005226334063],
              [-81.153217984414852, 34.560094002851024],
              [-81.153115434285795, 34.56062398144693],
              [-81.153440967493253, 34.560665720353377],
              [-81.153338418089177, 34.561195699153444],
              [-81.15366395258674, 34.561237437475583],
              [-81.153612678969296, 34.561502427427925],
              [-81.154263753161018, 34.561585900761763],
              [-81.154212480148288, 34.561850890092771],
              [-81.154538018776648, 34.561892626025291],
              [-81.154486747546869, 34.562157615457465],
              [-81.155463368442994, 34.562282816572825],
              [-81.15551463659969, 34.562017826721956],
              [-81.156165716786873, 34.562101288836772],
              [-81.156114450702603, 34.562366279868101],
              [-81.157742162105876, 34.56257492130019],
              [-81.157690900788978, 34.562839912109204],
              [-81.158341989705306, 34.562923362418978],
              [-81.158290731195962, 34.563188353468774],
              [-81.158616277174758, 34.563230077438504],
              [-81.158565019358946, 34.563495068608916],
              [-81.158890566693046, 34.563536791835126],
              [-81.158736793244856, 34.564331765671824],
              [-81.159062343982981, 34.564373488433482],
              [-81.15895982835886, 34.564903470281315],
              [-81.158634275571828, 34.564861747240556],
              [-81.158480496024666, 34.565656719011479],
              [-81.158806052974711, 34.565698442451264],
              [-81.158754793117367, 34.56596343284945],
              [-81.159080351422631, 34.566005155545696],
              [-81.159029093348579, 34.56627014604495],
              [-81.160005773298977, 34.566395308351986],
              [-81.160057028299619, 34.566130317434144],
              [-81.161033708153511, 34.566255471374234],
              [-81.161084959749843, 34.565990480018961],
              [-81.162061639507201, 34.566115625592133],
              [-81.162010390984506, 34.566380617365951],
              [-81.162335952597559, 34.566422331230697],
              [-81.162284704768538, 34.566687323125137],
              [-81.162610267736866, 34.566729036246137],
              [-81.162559021667519, 34.566994027340364],
              [-81.162884585991137, 34.567035739717724],
              [-81.162782092754, 34.567565723068974],
              [-81.163107660546984, 34.567607434822513],
              [-81.162953921272461, 34.568402409675457],
              [-81.163279492469897, 34.568444120964365],
              [-81.16322824640028, 34.568709112383239],
              [-81.163553818929202, 34.568750822027084],
              [-81.16350257355343, 34.569015813566608],
              [-81.163828147461516, 34.569057523368016],
              [-81.163725657766861, 34.569587506669095],
              [-81.164051234031177, 34.569629214965076],
              [-81.163999989712394, 34.569894206726694],
              [-81.164325567332043, 34.569935914278879],
              [-81.164274324796736, 34.570200906141459],
              [-81.164925483077482, 34.570284318875274],
              [-81.164874241171148, 34.57054931101743],
              [-81.165850985236119, 34.57067442389274],
              [-81.165902223002703, 34.570409432253122],
              [-81.166227803631259, 34.570451134645502],
              [-81.166279040018722, 34.570186141946181],
              [-81.166930200218673, 34.570269543802446],
              [-81.166981435315734, 34.570004550785598],
              [-81.167307014887015, 34.570046250249405],
              [-81.167358247562973, 34.569781257995103],
              [-81.16800940562446, 34.569864653092871],
              [-81.168060635920583, 34.569599660540732],
              [-81.169362951936421, 34.569766440481196],
              [-81.169311725762725, 34.570031434492478],
              [-81.169962887815601, 34.570114819892936],
              [-81.169860439640956, 34.570644807495277],
              [-81.170186023212665, 34.570686499149559],
              [-81.170032350988393, 34.571481480388513],
              [-81.169706764366438, 34.57143978921723],
              [-81.169655538234636, 34.571704782492318],
              [-81.169981126994713, 34.57174647468463],
              [-81.169724998678902, 34.573071441433299],
              [-81.1693994047952, 34.573029748543846],
              [-81.169245719456356, 34.573824727970461],
              [-81.168920122829078, 34.573783033779499],
              [-81.168766432482059, 34.57457801079439],
              [-81.169092032183642, 34.57461970540362],
              [-81.169040802060309, 34.574884697549393],
              [-81.169366403117337, 34.574926391414863],
              [-81.16931517368802, 34.57519138368103],
              [-81.169640777166393, 34.575233075881648],
              [-81.169692004481192, 34.574968083495676],
              [-81.170994414380345, 34.575134844769075],
              [-81.171045638354968, 34.574869851786751],
              [-81.172348049444921, 34.575036598369714],
              [-81.172296830659135, 34.575301591890018],
              [-81.172948040758286, 34.575384959249327],
              [-81.172896822601928, 34.575649953049144],
              [-81.173222429740875, 34.575691636434634],
              [-81.173171213368292, 34.575956630335],
              [-81.173496820749179, 34.575998312095095],
              [-81.173445605070953, 34.576263306115791],
              [-81.1727943874766, 34.576179940551981],
              [-81.172589512145407, 34.577239914447524],
              [-81.172915125409588, 34.577281597768135],
              [-81.172863907029694, 34.577546590652801],
              [-81.172538292740555, 34.577504907192804],
              [-81.172487071939372, 34.577769900840075],
              [-81.172812687253298, 34.577811584439459],
              [-81.172710248840403, 34.578341570113416],
              [-81.173035866534804, 34.578383253108257],
              [-81.17288220842093, 34.579178231913765],
              [-81.173207829520479, 34.579219914443499],
              [-81.173105391901188, 34.57974990048379],
              [-81.173431015381269, 34.579791582408959],
              [-81.1731749195106, 34.581116545187761],
              [-81.173500549511786, 34.5811582260054],
              [-81.173346890632274, 34.581953202787268],
              [-81.173672524062965, 34.581994884040945],
              [-81.17341642405583, 34.583319844762606],
              [-81.173742064008039, 34.583361524908838],
              [-81.173588403295739, 34.584156500263717],
              [-81.173262760244484, 34.584114818798092],
              [-81.173211538676156, 34.584379810098284],
              [-81.172885894954462, 34.584338128511092],
              [-81.172783448775476, 34.584868110774927],
              [-81.173109093457498, 34.584909792660611],
              [-81.173057870897026, 34.585174783902993],
              [-81.173383516958637, 34.585216465945827],
              [-81.173332295092663, 34.585481457308425],
              [-81.17365794357589, 34.585523137686195],
              [-81.17360672131457, 34.585788129188565],
              [-81.173932371177386, 34.585829809723627],
              [-81.173881150676308, 34.586094800425101],
              [-81.174532453420184, 34.586178158222289],
              [-81.174430014460768, 34.58670814104601],
              [-81.174755668390816, 34.586749819348746],
              [-81.174448351835579, 34.588339765449845],
              [-81.174774012223168, 34.588381442804028],
              [-81.174722792846836, 34.588646433889416],
              [-81.175048455679942, 34.588688110479751],
              [-81.174894797528879, 34.589483083156352],
              [-81.175220463768142, 34.589524759281346],
              [-81.175066805692879, 34.590319731300681],
              [-81.174741136353973, 34.590278053856423],
              [-81.174638694644059, 34.590808034213126],
              [-81.174313023609386, 34.590766356507906],
              [-81.174159354375064, 34.591561325598114],
              [-81.173833679505549, 34.591519646611061],
              [-81.173782455088244, 34.591784635522217],
              [-81.17345678061406, 34.591742955492528],
              [-81.173354327670808, 34.59227293389894],
              [-81.17302865036315, 34.59223125182546],
              [-81.172670046863047, 34.594086172417704],
              [-81.172995730233779, 34.594127854585295],
              [-81.172944501484011, 34.594392843162893],
              [-81.173270186210758, 34.594434524586255],
              [-81.17316772865604, 34.594964501080369],
              [-81.173493416877761, 34.59500618278053],
              [-81.173390958936963, 34.595536158593909],
              [-81.173716649540154, 34.595577839689227],
              [-81.173511735970763, 34.596637791600408],
              [-81.173186041290137, 34.596596110848935],
              [-81.172827419836096, 34.598451022312538],
              [-81.172501718308681, 34.598409339701988],
              [-81.172348014854123, 34.599204299510163],
              [-81.172022309491354, 34.599162615617658],
              [-81.171919836816883, 34.599692588446608],
              [-81.171594129710257, 34.599650902490396],
              [-81.171440415141333, 34.600445861168993],
              [-81.171389176266246, 34.600710846454696],
              [-81.171714887450705, 34.60075253206702],
              [-81.171561170850651, 34.601547489145837],
              [-81.171886885442248, 34.60158917429267],
              [-81.171784408840281, 34.602119146146599],
              [-81.172110126903618, 34.602160830668801],
              [-81.172058889119995, 34.602425816254971],
              [-81.172384607449629, 34.602467500052704],
              [-81.17233337033673, 34.602732484857349],
              [-81.172659091112749, 34.602774167890999],
              [-81.172607854718379, 34.603039153716715],
              [-81.172933575760609, 34.603080836025804],
              [-81.172882340061008, 34.603345821971232],
              [-81.173208063549552, 34.603387503516295],
              [-81.173105593185042, 34.603917474725598],
              [-81.17343131905541, 34.603959155665763],
              [-81.173380084390587, 34.604224140929624],
              [-81.173705811617296, 34.604265821125459],
              [-81.173654577647198, 34.604530806508997],
              [-81.173980306230149, 34.604572485960531],
              [-81.173929072954806, 34.604837471463739],
              [-81.175557727001845, 34.605045854340382],
              [-81.175506499637322, 34.605310841402229],
              [-81.176157965181886, 34.605394188473298],
              [-81.176106739513955, 34.605659174892835],
              [-81.176432473796012, 34.605700846791535],
              [-81.176381248822835, 34.605965833330693],
              [-81.177032721544293, 34.60604917563721],
              [-81.176981497201623, 34.606314162455],
              [-81.177307235617135, 34.606355831961459],
              [-81.177256011969334, 34.606620818898953],
              [-81.178558971974923, 34.606787489566102],
              [-81.178507753188953, 34.607052477021256],
              [-81.18078795391007, 34.60734411694974],
              [-81.180736741949005, 34.607609104459016],
              [-81.181062487409861, 34.607650763778686],
              [-81.181011276167652, 34.607915752308813],
              [-81.181337022984863, 34.607957410884026],
              [-81.181285813503862, 34.608222398612625],
              [-81.181611561677414, 34.608264056443382],
              [-81.181560351801281, 34.608529044311318],
              [-81.181886101331273, 34.608570701397596],
              [-81.181783683728597, 34.60910067733294],
              [-81.182109436706668, 34.609142332892908],
              [-81.182007019832795, 34.609672309027907],
              [-81.181681264779314, 34.609630652288054],
              [-81.181630055365744, 34.609895640176703],
              [-81.181304299617196, 34.609853982413902],
              [-81.181253088823283, 34.610118969242286],
              [-81.180927332379696, 34.610077310456404],
              [-81.180824906682105, 34.610607284696357],
              [-81.18049914851774, 34.610565624748183],
              [-81.180345503856927, 34.61136058510121],
              [-81.180997025580737, 34.611443904969242],
              [-81.180945812802719, 34.611708891678987],
              [-81.181271575707441, 34.611750549515385],
              [-81.181220362534376, 34.612015536364375],
              [-81.181546126819626, 34.612057194357533],
              [-81.181494914341627, 34.612322181326],
              [-81.18182067998336, 34.61236383857463],
              [-81.181769469266541, 34.612628824741371],
              [-81.182095236264757, 34.61267048124553],
              [-81.182044025176964, 34.612935468452818],
              [-81.182369793507704, 34.612977123311175],
              [-81.182318584205092, 34.613242110618152],
              [-81.182644353916288, 34.613283765633135],
              [-81.18259314528494, 34.613548752158259],
              [-81.182918916352619, 34.613590406428713],
              [-81.182765289371616, 34.614385366342248],
              [-81.183091064937642, 34.614427020126868],
              [-81.182988647711966, 34.614956993575248],
              [-81.183314425636411, 34.614998645853184],
              [-81.183263217008289, 34.615263632696767],
              [-81.183588996313347, 34.615305285131477],
              [-81.183486581183104, 34.615835258116263],
              [-81.183812362846524, 34.61587690904431],
              [-81.18376115636832, 34.616141896086937],
              [-81.184086939388308, 34.6161835462703],
              [-81.1839845263776, 34.616713519692823],
              [-81.184310311780166, 34.616755169270803],
              [-81.184105486960547, 34.617815115512812],
              [-81.182802329805909, 34.617648508788356],
              [-81.182751117822903, 34.61791349363034],
              [-81.18242532861359, 34.617871839820694],
              [-81.182374115297989, 34.618136825404768],
              [-81.182048325369493, 34.618095169670738],
              [-81.181997110697239, 34.61836015509563],
              [-81.181671320097507, 34.618318499239727],
              [-81.181568887684861, 34.618848468849976],
              [-81.181243095364067, 34.618806811831639],
              [-81.181038222442069, 34.619866749354401],
              [-81.180712426348123, 34.619825090895155],
              [-81.180661206258819, 34.620090074861373],
              [-81.180987003378732, 34.620131733459807],
              [-81.180935783984623, 34.620396717545304],
              [-81.181913183725428, 34.620521690198288],
              [-81.181861967078433, 34.620786674681533],
              [-81.182839372850111, 34.620911638895855],
              [-81.182788158926485, 34.621176622875581],
              [-81.183113962553847, 34.621218276585942],
              [-81.183062750415672, 34.621483260665073],
              [-81.18273694578636, 34.621441607716697],
              [-81.182685731225149, 34.621706592557693],
              [-81.183011536880585, 34.621748245645229],
              [-81.182704250808925, 34.623338151461354],
              [-81.182378437906962, 34.623296497558108],
              [-81.182019913724488, 34.625151382812128],
              [-81.181694095037017, 34.625109726129224],
              [-81.181591654198428, 34.625639692247347],
              [-81.181917476004443, 34.62568134828765],
              [-81.181763815332019, 34.626476297281222],
              [-81.181437989357391, 34.626434640842923],
              [-81.181335545186826, 34.626964605858774],
              [-81.180683892281948, 34.626881288832422],
              [-81.180427763600207, 34.628206198765376],
              [-81.180753596096281, 34.628247857946299],
              [-81.180497465292916, 34.629572765347895],
              [-81.180171627665814, 34.629531105470718],
              [-81.180017941660481, 34.630326047817547],
              [-81.179692101285596, 34.630284386638593],
              [-81.179640870210307, 34.630549367554799],
              [-81.178989188400749, 34.630466042266313],
              [-81.178937954918183, 34.630731021982513],
              [-81.178612113483524, 34.630689357872996],
              [-81.178560879758152, 34.630954338311213],
              [-81.178235036513868, 34.630912672296887],
              [-81.177773901033873, 34.633297487005336],
              [-81.178099752400129, 34.633339153391617],
              [-81.177997276837843, 34.633869111158802],
              [-81.178323130587586, 34.63391077693958],
              [-81.178220655754174, 34.634440734904139],
              [-81.177894798861502, 34.634399068864631],
              [-81.177843559388791, 34.634664048137765],
              [-81.177517701800753, 34.63462238107499],
              [-81.177415219762082, 34.635152337479155],
              [-81.177089360452285, 34.635110669253692],
              [-81.176935631833118, 34.635905603289821],
              [-81.177261494221895, 34.635947271933112],
              [-81.177056521063122, 34.637007182470761],
              [-81.177382388978643, 34.637048850767187],
              [-81.177279903011367, 34.637578806182646],
              [-81.177605772220247, 34.637620473893271],
              [-81.177349556581817, 34.638945360518285],
              [-81.177675431253661, 34.638987028041193],
              [-81.177572944710263, 34.639516981925681],
              [-81.177898822856406, 34.639558648823218],
              [-81.177796337042025, 34.640088602904576],
              [-81.178122217572025, 34.640130269196433],
              [-81.178070975182735, 34.640395245895128],
              [-81.178722740379129, 34.640478576085215],
              [-81.178671499711783, 34.640743553041972],
              [-81.178997383287665, 34.640785216959927],
              [-81.178946143315883, 34.641050194035444],
              [-81.179272029315783, 34.641091856287474],
              [-81.179220790039423, 34.641356833481886],
              [-81.17954667633019, 34.641398495910011],
              [-81.179495437749438, 34.641663473223183],
              [-81.180147215533964, 34.641746794764622],
              [-81.180095978675155, 34.642011772335849],
              [-81.180421869078387, 34.642053431468867],
              [-81.180370632915228, 34.642318409158882],
              [-81.180696524699883, 34.642360068448255],
              [-81.180645289208229, 34.642625045355651],
              [-81.181297075799478, 34.642708360659185],
              [-81.181245842053997, 34.642973338725881],
              [-81.181897631998012, 34.643056649869756],
              [-81.181846401065215, 34.643321628174697],
              [-81.182498194386056, 34.643404936060271],
              [-81.182446964084917, 34.643669914642935],
              [-81.182772863358863, 34.643711567378787],
              [-81.182721633729315, 34.643976545178909],
              [-81.183047533270198, 34.644018197189595],
              [-81.182996305450914, 34.644283175989884],
              [-81.183322206349203, 34.644324827255595],
              [-81.183270979201623, 34.644589805273299],
              [-81.18392278513484, 34.64467310541071],
              [-81.183871559709445, 34.644938083686355],
              [-81.184197463653732, 34.644979732577767],
              [-81.184095013863669, 34.645509689345822],
              [-81.184420921282552, 34.645551337611664],
              [-81.18436969689381, 34.645816315202758],
              [-81.185347423125378, 34.645941255132321],
              [-81.185398644410228, 34.645676276222282],
              [-81.186050461135451, 34.645759564815734],
              [-81.186101680036231, 34.645494585606819],
              [-81.186427587323251, 34.645536228447988],
              [-81.186478804866468, 34.645271249079414],
              [-81.187130619470352, 34.645354531810753],
              [-81.187181834629442, 34.645089552143332],
              [-81.187507741946192, 34.645131192033595],
              [-81.187456527813737, 34.645396171840211],
              [-81.189411982568373, 34.645645990783521],
              [-81.189463190564979, 34.645381011043327],
              [-81.18978909908283, 34.645422643862481],
              [-81.189737893227502, 34.645687624623292],
              [-81.190063804193258, 34.64572925667747],
              [-81.190012599033821, 34.645994237556906],
              [-81.190338511381171, 34.646035869767339],
              [-81.19028730689358, 34.646300849864055],
              [-81.191916878652833, 34.646508995644012],
              [-81.191865678992016, 34.646773977317196],
              [-81.192517512045157, 34.646857228996623],
              [-81.192415116904584, 34.6473871919167],
              [-81.192741035980688, 34.647428816708185],
              [-81.192689839474241, 34.647693797815961],
              [-81.19301575993191, 34.647735422763581],
              [-81.192964564121553, 34.64800040398989],
              [-81.193290485912584, 34.648042027291055],
              [-81.192983306969552, 34.649631914210246],
              [-81.193309236342515, 34.649673537441927],
              [-81.193104446726181, 34.650733460116506],
              [-81.193430380537094, 34.6507750830202],
              [-81.19337918414486, 34.651040063536207],
              [-81.193705119313336, 34.651081685694692],
              [-81.193653922526636, 34.65134666634912],
              [-81.194305797000098, 34.651429908271453],
              [-81.194957671705737, 34.651513146676443],
              [-81.194906479786312, 34.65177812784659],
              [-81.195232418662016, 34.651819745861701],
              [-81.195181227414594, 34.652084726249107],
              [-81.195507168738615, 34.652126343498971],
              [-81.195404786262898, 34.652656306312473],
              [-81.195730729947229, 34.652697922054934],
              [-81.195474774668554, 34.654022826689179],
              [-81.195800722751542, 34.65406444316379],
              [-81.195698340795829, 34.65459440442195],
              [-81.196024292354068, 34.654636020270488],
              [-81.195563564147221, 34.657020842594186],
              [-81.195237602255688, 34.656979225513972],
              [-81.195084016748709, 34.657774164002817],
              [-81.194758052131064, 34.657732546522261],
              [-81.194655657697652, 34.65826250423823],
              [-81.194329691356671, 34.658220885595092],
              [-81.19422729354612, 34.658750842949843],
              [-81.193901325481988, 34.658709223144044],
              [-81.193850124496109, 34.658974201210526],
              [-81.19352415682647, 34.658932580361387],
              [-81.193472954482772, 34.659197558267934],
              [-81.193146985002087, 34.659155935513922],
              [-81.193095781324644, 34.659420914161942],
              [-81.192769811147741, 34.659379290384322],
              [-81.192616195001122, 34.660174223983688],
              [-81.19229022207395, 34.660132598904404],
              [-81.192239014995025, 34.660397576289682],
              [-81.19191304139575, 34.660355951088178],
              [-81.191605784672959, 34.661945812345373],
              [-81.191279804151478, 34.661904185444719],
              [-81.191074955206801, 34.662964090480635],
              [-81.19140093983674, 34.663005717937708],
              [-81.191349728091268, 34.663270694954619],
              [-81.191675712988314, 34.663312321686298],
              [-81.191573290510703, 34.663842274133032],
              [-81.191247302444552, 34.663800646241874],
              [-81.191196089682336, 34.664065622294736],
              [-81.19087010094394, 34.664023994281251],
              [-81.19081888573281, 34.664288970194093],
              [-81.190492896274193, 34.664247340255677],
              [-81.190083158698386, 34.66636714110637],
              [-81.190409156350242, 34.666408771256393],
              [-81.190050625130979, 34.668263592716052],
              [-81.189724619197932, 34.668221961612261],
              [-81.189468510187496, 34.669546830443487],
              [-81.189366063384171, 34.670076777484219],
              [-81.189040050567328, 34.670035143620723],
              [-81.188732695977919, 34.671624980699441],
              [-81.190036777110876, 34.67179151232223],
              [-81.189985554284604, 34.672056485200798],
              [-81.190311576683357, 34.672098115578493],
              [-81.19020913209259, 34.672628061550547],
              [-81.190535156901049, 34.672669692223245],
              [-81.190483935136584, 34.672934665316667],
              [-81.190157909300794, 34.672893034504952],
              [-81.190004240030291, 34.673687953221574],
              [-81.18967821144345, 34.67364632110791],
              [-81.189370855547125, 34.675236154472408],
              [-81.189696891365102, 34.675277786499528],
              [-81.189645665239212, 34.675542758341358],
              [-81.189971701324552, 34.67558438964285],
              [-81.189664344849021, 34.677174220163231],
              [-81.189338301508172, 34.677132588047115],
              [-81.189287073040916, 34.677397558839473],
              [-81.188961029003664, 34.677355925699615],
              [-81.188909799177949, 34.677620896331753],
              [-81.188583754444309, 34.677579262168081],
              [-81.188532523284138, 34.677844233541194],
              [-81.188206477830036, 34.67780259745247],
              [-81.188104011346653, 34.678332538975454],
              [-81.187777964192776, 34.678290902625214],
              [-81.187675494306106, 34.678820842883759],
              [-81.187349445404251, 34.678779204469365],
              [-81.187246972138283, 34.679309144364751],
              [-81.186920921536711, 34.679267505688749],
              [-81.186818443776318, 34.679797444339499],
              [-81.186492391450813, 34.679755804500608],
              [-81.186338670347538, 34.680550712330785],
              [-81.186664726846459, 34.680592352567267],
              [-81.186613486464267, 34.680857321346373],
              [-81.186939544321916, 34.680898960837197],
              [-81.186888304636, 34.681163929734147],
              [-81.187214363852306, 34.681205568479498],
              [-81.187163124886922, 34.681470538395487],
              [-81.187489185461772, 34.681512176395266],
              [-81.187386709635703, 34.682042114619321],
              [-81.18771277259674, 34.682083752012559],
              [-81.187661535736069, 34.682348720320526],
              [-81.188313664706683, 34.68243199273121],
              [-81.188262428503009, 34.682696962217236],
              [-81.190870968007843, 34.683030016643315],
              [-81.19081974076073, 34.683294986299586],
              [-81.191471881554335, 34.683378241552496],
              [-81.191420656031383, 34.683643211465515],
              [-81.191746727406866, 34.683684837913937],
              [-81.191644278514048, 34.68421477793445],
              [-81.191970352275817, 34.684256403776203],
              [-81.191663001636044, 34.685846224199643],
              [-81.191989082961683, 34.685887849069921],
              [-81.191886632858726, 34.686417788403702],
              [-81.192212716594881, 34.686459413568663],
              [-81.192110266131891, 34.68698935311501],
              [-81.192436352230473, 34.687030976771943],
              [-81.192333903589798, 34.687560916490945],
              [-81.192659992074837, 34.687602539541246],
              [-81.192608768819213, 34.687867509047095],
              [-81.192934858687138, 34.68790913225299],
              [-81.192883635036964, 34.688174101896401],
              [-81.193209727330697, 34.68821572343532],
              [-81.193158504377308, 34.688480693196347],
              [-81.193484598029741, 34.688522313989623],
              [-81.193433375773054, 34.688787283868201],
              [-81.194085566126617, 34.688870523078279],
              [-81.194034345594432, 34.689135493213641],
              [-81.194360442295576, 34.689177111630556],
              [-81.194309222460006, 34.689442081883485],
              [-81.194961418910836, 34.689525316340891],
              [-81.194910201891062, 34.689790286830473],
              [-81.195236301640833, 34.689831902871035],
              [-81.195185084202564, 34.690096872596932],
              [-81.195511185311048, 34.690138487891687],
              [-81.195459969684848, 34.690403458616402],
              [-81.195786072128115, 34.69044507226409],
              [-81.195683641914087, 34.690975013026012],
              [-81.196009746768041, 34.691016626968207],
              [-81.195958532180327, 34.691281597005393],
              [-81.196284638393209, 34.691323210201681],
              [-81.196079779728137, 34.692383089810349],
              [-81.19575366940397, 34.692341476057983],
              [-81.195446363635696, 34.693931292669625],
              [-81.195772481218441, 34.693972907236244],
              [-81.19572126399315, 34.694237876274613],
              [-81.196047382934836, 34.694279490095383],
              [-81.195996166406431, 34.694544459251162],
              [-81.196648407314541, 34.694627683614748],
              [-81.196494760819562, 34.695422591807024],
              [-81.196820884866028, 34.69546420352917],
              [-81.196667239518661, 34.696259110121865],
              [-81.19699336698001, 34.696300721376126],
              [-81.196942152222107, 34.69656569003574],
              [-81.197594411260823, 34.696648909246356],
              [-81.197543198251921, 34.696913879063658],
              [-81.197869329829373, 34.696955487019984],
              [-81.19781811749327, 34.697220456053479],
              [-81.198144249362656, 34.697262064185111],
              [-81.198041825753506, 34.697792002465221],
              [-81.198367961076997, 34.697833609068695],
              [-81.198316749803965, 34.698098578315353],
              [-81.198969023524043, 34.698181790046675],
              [-81.198917813976067, 34.698446759549711],
              [-81.199243952348496, 34.698488363776271],
              [-81.19903911602573, 34.699548241206784],
              [-81.199365258841368, 34.699589845104391],
              [-81.199262840192418, 34.700119783527057],
              [-81.19958898648656, 34.700161386797603],
              [-81.199537777681485, 34.700426355664824],
              [-81.199863925334725, 34.700467958189485],
              [-81.199556672813358, 34.702057771751477],
              [-81.19923051899147, 34.702016168393072],
              [-81.199179307845213, 34.702281136207269],
              [-81.198853153326226, 34.702239531824816],
              [-81.198801940844987, 34.70250450037959],
              [-81.198475785604728, 34.702462894071964],
              [-81.198322143066108, 34.703257798287758],
              [-81.197995983981258, 34.703216190698171],
              [-81.197842335354522, 34.704011093400275],
              [-81.1975161746075, 34.70396948448878],
              [-81.197464955608936, 34.70423445159313],
              [-81.197791118475365, 34.704276060623727],
              [-81.197637464736133, 34.705070961282274],
              [-81.197963629902745, 34.705112568963429],
              [-81.197758758075722, 34.706172436151796],
              [-81.198084928777575, 34.706214043483889],
              [-81.197982492364247, 34.706743976334039],
              [-81.198308665453567, 34.706785583059137],
              [-81.198257448348357, 34.707050550481775],
              [-81.197931274230726, 34.707008943617708],
              [-81.197880055742019, 34.707273909978277],
              [-81.197553880903058, 34.707232301188981],
              [-81.197502659963646, 34.707497267408698],
              [-81.197176484451717, 34.707455658496585],
              [-81.197125263244331, 34.707720624535561],
              [-81.1964729111574, 34.70763740377825],
              [-81.196370461429254, 34.7081673343734],
              [-81.196044283825927, 34.708125722389234],
              [-81.195788149648763, 34.709450547329162],
              [-81.196114333508888, 34.709492160889724],
              [-81.195704510142633, 34.711611876073597],
              [-81.196030702536277, 34.711653488959733],
              [-81.19597947388678, 34.711918453628464],
              [-81.196958056139934, 34.712043287393094],
              [-81.196906830220087, 34.712308251555577],
              [-81.19788541963014, 34.712433077751321],
              [-81.197834196464342, 34.712698042308844],
              [-81.198812791915472, 34.712822860054118],
              [-81.198761571503681, 34.713087825006589],
              [-81.19908777241379, 34.71312942957757],
              [-81.199036553790648, 34.713394394627116],
              [-81.199362754968803, 34.713435998471901],
              [-81.199311537043059, 34.713700963638395],
              [-81.199963943516721, 34.713784168029164],
              [-81.199912727316601, 34.714049133451752],
              [-81.200565137171523, 34.714132334579823],
              [-81.200616351314792, 34.713867368879406],
              [-81.201268760437188, 34.713950566189169],
              [-81.201217548350883, 34.714215532167465],
              [-81.201543754970729, 34.714257129172886],
              [-81.201492543581793, 34.714522095268144],
              [-81.201818751561248, 34.71456369152741],
              [-81.201665118469037, 34.71535858919691],
              [-81.201991329864853, 34.715400184987821],
              [-81.201940119208459, 34.7156651512731],
              [-81.202266331963912, 34.715706746317721],
              [-81.202215123072307, 34.71597171179873],
              [-81.2038461969819, 34.716179675340278],
              [-81.20379499290172, 34.716444641493794],
              [-81.204447426854401, 34.716527821532814],
              [-81.20439622450013, 34.716792787942168],
              [-81.205048662901234, 34.716875963796959],
              [-81.204997461181591, 34.717140930482167],
              [-81.205649902963813, 34.717224103073981],
              [-81.205598704037499, 34.717489069093659],
              [-81.205924926999387, 34.717530654190718],
              [-81.20587372879487, 34.717795621228532],
              [-81.206199952024789, 34.717837205599224],
              [-81.206148754517841, 34.71810217275393],
              [-81.206801205095161, 34.718185338196307],
              [-81.206750009314362, 34.718450305606687],
              [-81.20707623668595, 34.71849188757956],
              [-81.20687145429433, 34.719551754852716],
              [-81.206545222808273, 34.719510172324384],
              [-81.206340431006225, 34.72057003868909],
              [-81.206014194645149, 34.720528454740055],
              [-81.205553382108789, 34.722913145024584],
              [-81.205879627727796, 34.722954730223691],
              [-81.205521204938734, 34.724809484489477],
              [-81.205847458089778, 34.724851069775411],
              [-81.205642641542028, 34.725910927755685],
              [-81.205968900230758, 34.72595251269189],
              [-81.205917696834703, 34.72621747703505],
              [-81.20624395579155, 34.726259061244797],
              [-81.206192753093205, 34.726524025704606],
              [-81.206519013385616, 34.726565608266718],
              [-81.206467811384996, 34.726830572843227],
              [-81.206794074128894, 34.726872154638819],
              [-81.206691670100398, 34.727402084022891],
              [-81.20701793523294, 34.72744366521092],
              [-81.206966734296742, 34.727708629998496],
              [-81.20729300078925, 34.727750210440021],
              [-81.207241800550719, 34.728015175344289],
              [-81.207894336586435, 34.728098333849125],
              [-81.20784313698293, 34.728363299028885],
              [-81.208169407617817, 34.728404877072037],
              [-81.208118208712108, 34.728669842368404],
              [-81.208770751941103, 34.728752996096546],
              [-81.208719555829433, 34.729017960727127],
              [-81.209045828423442, 34.729059536412016],
              [-81.20899463303391, 34.729324502060379],
              [-81.209647182364492, 34.729407651031792],
              [-81.209595988677279, 34.729672616034321],
              [-81.20992226541388, 34.729714189320831],
              [-81.209973456980421, 34.729449224199534],
              [-81.210626008297083, 34.729532367858752],
              [-81.210574818788302, 34.729797333257721],
              [-81.211553650786612, 34.729922042532905],
              [-81.211502464033416, 34.730187008326013],
              [-81.212155022751219, 34.730270142785649],
              [-81.212103836633275, 34.730535108854298],
              [-81.212430118075517, 34.730576675335357],
              [-81.212327747971941, 34.731106606762083],
              [-81.212654031802984, 34.731148172635486],
              [-81.212602847841723, 34.731413138894808],
              [-81.21292913300833, 34.731454703120363],
              [-81.212877949745035, 34.731719669496144],
              [-81.213204236295923, 34.731761233876369],
              [-81.213101869693972, 34.73229116505631],
              [-81.213754448996255, 34.732374291695606],
              [-81.213703266710837, 34.732639257539788],
              [-81.214029558408953, 34.732680818758823],
              [-81.213927196019398, 34.733210751538984],
              [-81.214253490106344, 34.733252312150121],
              [-81.214202309977878, 34.733517278184827],
              [-81.214099947636271, 34.734047210207386],
              [-81.213773651529664, 34.734005648258396],
              [-81.213722469379221, 34.734270614087258],
              [-81.213396171482813, 34.734229051134236],
              [-81.213293804106726, 34.734758981546072],
              [-81.212967504507603, 34.734717418331201],
              [-81.212916319281845, 34.734982382914133],
              [-81.21259001898467, 34.734940818675113],
              [-81.212538831307128, 34.735205783116967],
              [-81.211886230715038, 34.735122650783524],
              [-81.211835040648509, 34.735387614925358],
              [-81.211508739286273, 34.735346047752309],
              [-81.211457548951373, 34.735611011712898],
              [-81.211131246866785, 34.73556944261437],
              [-81.211182439322471, 34.735304478772449],
              [-81.210856138597876, 34.735262908927311],
              [-81.210804945137468, 34.735527873531602],
              [-81.209826042954745, 34.735403158246875],
              [-81.209774846076201, 34.735668122412292],
              [-81.209448544617814, 34.735626548747746],
              [-81.209346147761039, 34.736156474931221],
              [-81.209019844575565, 34.736114900103487],
              [-81.208968644621521, 34.736379863022847],
              [-81.208642340738095, 34.73633828717081],
              [-81.208591139423845, 34.736603249928862],
              [-81.206959619852654, 34.736395357594297],
              [-81.206908413037894, 34.736660318734494],
              [-81.206255805459492, 34.736577154964294],
              [-81.206204595188055, 34.736842116725668],
              [-81.20587829086648, 34.736800533373533],
              [-81.205827080326628, 34.737065494953484],
              [-81.205500775307172, 34.737023910577051],
              [-81.205398348968075, 34.737553832530736],
              [-81.205724656045504, 34.737595417184885],
              [-81.2055710182198, 34.738390299893069],
              [-81.205897328715579, 34.738431884078317],
              [-81.205743690972369, 34.739226766099506],
              [-81.205417376297461, 34.739185181517719],
              [-81.205314947785098, 34.739715102468416],
              [-81.204988631383273, 34.739673516723336],
              [-81.204886198371867, 34.740203436425148],
              [-81.204559880243039, 34.740161849516845],
              [-81.20445744387284, 34.740691769752083],
              [-81.204131124016911, 34.74065018168055],
              [-81.20407990428167, 34.740915140724248],
              [-81.203977463841738, 34.741445059645436],
              [-81.20365114122967, 34.741403470271777],
              [-81.203446251117995, 34.742463305505552],
              [-81.203119924720696, 34.742421714690877],
              [-81.202966250274002, 34.743216589566394],
              [-81.202639920028616, 34.743174997469659],
              [-81.202537466310801, 34.743704913368944],
              [-81.202211135430204, 34.743663320088835],
              [-81.202159906511326, 34.743928278326429],
              [-81.201833574932863, 34.743886684021923],
              [-81.201782344629365, 34.744151641196666],
              [-81.201456012352921, 34.744110045867842],
              [-81.201404780688947, 34.744375002881085],
              [-81.201078446622759, 34.744333406547739],
              [-81.200924747671337, 34.745128277916457],
              [-81.200598410848983, 34.745086680280963],
              [-81.200290996636141, 34.746676417988979],
              [-81.200617339633908, 34.746718016457848],
              [-81.200566103627324, 34.746982973105908],
              [-81.200239759600393, 34.746941374498036],
              [-81.199983568262283, 34.748266153118607],
              [-81.199657219420359, 34.748224552930758],
              [-81.199554737842178, 34.748754463593784],
              [-81.199228387273067, 34.748712862242471],
              [-81.199177145503583, 34.748977817391129],
              [-81.198850793144743, 34.748936215035293],
              [-81.198799549990284, 34.749201169120973],
              [-81.198473196933563, 34.749159565740662],
              [-81.198319461350493, 34.749954429267255],
              [-81.197993105537208, 34.749912824584598],
              [-81.197941859022777, 34.750177779202822],
              [-81.197615502487523, 34.75013617259448],
              [-81.197564253520298, 34.750401127070994],
              [-81.197237897403312, 34.750359520319378],
              [-81.197032895098189, 34.751419333817246],
              [-81.196706534103342, 34.751377725644318],
              [-81.196450268320106, 34.752702490413355],
              [-81.196347759235067, 34.753232395628594],
              [-81.196674127435372, 34.753274004774021],
              [-81.196264087090086, 34.755393623109775],
              [-81.195937709562571, 34.755352012872891],
              [-81.195886451858726, 34.755616964813015],
              [-81.196212830415575, 34.755658575188747],
              [-81.196110316048021, 34.756188478376934],
              [-81.196436696995249, 34.756230088144882],
              [-81.196334183336361, 34.756759990618093],
              [-81.196660566674026, 34.756801599778221],
              [-81.196609310400916, 34.757066552020682],
              [-81.196935695099654, 34.757108160434136],
              [-81.196833183569424, 34.757638063325615],
              [-81.197159571750603, 34.757679671111262],
              [-81.197108316517742, 34.75794462266159],
              [-81.197434704967861, 34.757986229720345],
              [-81.197383450408807, 34.758251180485381],
              [-81.19770984131209, 34.758292786777481],
              [-81.197658587475132, 34.75855773855978],
              [-81.197984979739431, 34.758599344105093],
              [-81.197933726600482, 34.758864296003388],
              [-81.198586513066275, 34.758947503833326],
              [-81.198535262747043, 34.759212455966519],
              [-81.198861656948537, 34.75925405825123],
              [-81.198810407327358, 34.759519010500412],
              [-81.199136803981943, 34.759560612018333],
              [-81.199085553966754, 34.759825564403407],
              [-81.199411951982384, 34.759867165174441],
              [-81.199360703757336, 34.760132117655544],
              [-81.199687103134096, 34.760173717679749],
              [-81.19963585451498, 34.760438670296729],
              [-81.200288656346174, 34.760521868867094],
              [-81.200083669971306, 34.761581676551437],
              [-81.199757263667365, 34.761540076722973],
              [-81.199654765885839, 34.762069980159445],
              [-81.199328358970476, 34.762028380048683],
              [-81.199225856687434, 34.76255828223421],
              [-81.198899448020143, 34.76251668005866],
              [-81.198848195363823, 34.762781631428624],
              [-81.198521784906305, 34.76274002824848],
              [-81.198368022927312, 34.763534880882368],
              [-81.198041609712405, 34.763493276399778],
              [-81.197631551877635, 34.765612875643903],
              [-81.197305130781245, 34.765571270065557],
              [-81.197253871033254, 34.765836219841056],
              [-81.196927449214584, 34.765794612336755],
              [-81.196773663751458, 34.766589459325417],
              [-81.196447239199358, 34.766547851419922],
              [-81.196037121350244, 34.768667437963664],
              [-81.196363555256525, 34.768709047861748],
              [-81.196312289359142, 34.768973995438365],
              [-81.196638724626709, 34.769015604589484],
              [-81.196382399707105, 34.770340343641266],
              [-81.196331132873908, 34.770605292119043],
              [-81.196657574627736, 34.770646900316478],
              [-81.196503777339615, 34.771441743283759],
              [-81.196830223606653, 34.771483350992057],
              [-81.196778957482394, 34.771748298753664],
              [-81.197105405110904, 34.771789905715011],
              [-81.197002874995206, 34.772319800525061],
              [-81.197329325015048, 34.772361406878346],
              [-81.197226795632545, 34.772891301873308],
              [-81.197553248043718, 34.772932907618497],
              [-81.197450720486614, 34.77346280277834],
              [-81.197777175289261, 34.773504407915418],
              [-81.197674647348975, 34.774034302378752],
              [-81.198327563160902, 34.774117510530907],
              [-81.198276300206928, 34.774382458015396],
              [-81.198602760186418, 34.774424060891555],
              [-81.198551499023111, 34.77468900847169],
              [-81.199204419852023, 34.774772211883963],
              [-81.199153160416941, 34.77503715971865],
              [-81.199806085700303, 34.775120358943759],
              [-81.199754827993431, 34.775385307032835],
              [-81.200081292720739, 34.775426905896012],
              [-81.200030035712217, 34.775691854100664],
              [-81.200356500684549, 34.775733451335554],
              [-81.200305245466694, 34.775998399635874],
              [-81.200958179582486, 34.776081592606765],
              [-81.20090692500078, 34.776346541181489],
              [-81.20123339412001, 34.776388136016287],
              [-81.20118214023671, 34.77665308470656],
              [-81.202814496029035, 34.776861048989602],
              [-81.202865743694389, 34.776596100526476],
              [-81.203192214802939, 34.776637690045717],
              [-81.203140968167673, 34.776902638647691],
              [-81.203793913463713, 34.776985816207201],
              [-81.203742667488797, 34.777250765984803],
              [-81.204069141652042, 34.777292353123883],
              [-81.2039154080326, 34.778087199969541],
              [-81.203588930755004, 34.778045611512724],
              [-81.203537683429801, 34.778310560295793],
              [-81.203211205478055, 34.778268971715541],
              [-81.203057460583352, 34.779063816566932],
              [-81.202730978756321, 34.779022225802976],
              [-81.202474725087285, 34.780346964865778],
              [-81.20280121094801, 34.780388555442684],
              [-81.202698709005972, 34.780918451366162],
              [-81.203025198350815, 34.780960041314685],
              [-81.202973948446527, 34.781224988919504],
              [-81.203300439153139, 34.781266578120913],
              [-81.203249188855111, 34.781531525861134],
              [-81.203575680923322, 34.781573114315407],
              [-81.203524432416231, 34.781838062151039],
              [-81.204177419631861, 34.781921237580377],
              [-81.20412617282922, 34.782186184768975],
              [-81.205105659217693, 34.782310941233717],
              [-81.205054415173962, 34.78257588881533],
              [-81.2053809123219, 34.782617472369964],
              [-81.205175937000703, 34.783677264819993],
              [-81.20484943463957, 34.783635680730164],
              [-81.204746942905103, 34.784165575635683],
              [-81.205073447326541, 34.78420716000312],
              [-81.204919709212007, 34.7850020017205],
              [-81.20459320169995, 34.784960416936741],
              [-81.204541954340584, 34.785225364283896],
              [-81.204215446129879, 34.785183778475265],
              [-81.204061696762764, 34.785978619098131],
              [-81.20438820915669, 34.786020205303238],
              [-81.20433695935435, 34.786285151675166],
              [-81.204010447022696, 34.786243565311239],
              [-81.203600432794644, 34.788363134249799],
              [-81.203273911459974, 34.788321545909298],
              [-81.203222656612738, 34.788586491791968],
              [-81.202896135671764, 34.788544902406564],
              [-81.202639852329128, 34.789869628048216],
              [-81.202966379514038, 34.789911218107612],
              [-81.202863865459776, 34.790441107763833],
              [-81.203190395037012, 34.790482697214749],
              [-81.203036625651691, 34.79127753191851],
              [-81.203363158651641, 34.791319120899814],
              [-81.203311903285297, 34.791584065879299],
              [-81.203638437647257, 34.79162565411329],
              [-81.203587181862886, 34.791890598326688],
              [-81.203913718679388, 34.791932185793343],
              [-81.203862463618023, 34.792197131023173],
              [-81.204189000679776, 34.792238716861227],
              [-81.204137747409675, 34.792503662186249],
              [-81.204464285857725, 34.792545248178172],
              [-81.204413033262128, 34.79281019271712],
              [-81.204739573072231, 34.792851777961687],
              [-81.204688321175411, 34.793116722615736],
              [-81.204361779242404, 34.793075137252394],
              [-81.204310526007717, 34.793340082645337],
              [-81.203657441075762, 34.793256908982464],
              [-81.20360618433179, 34.793521853192892],
              [-81.20262655744672, 34.793397086517309],
              [-81.202575297279935, 34.793662030287607],
              [-81.202248755338147, 34.79362043917093],
              [-81.202197493809166, 34.793885382778718],
              [-81.201870950076113, 34.793843790657],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        reservoir_: 57,
        ORIG_FID: 10,
        AAPC_Examp: 10,
        Area_mile2: 385,
        BASIN_NAME: "Lake Wateree",
        Res_Basin: "Wateree",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.742837909254717, 34.601558360990445],
              [-80.742890616782873, 34.60129356614808],
              [-80.742943323970977, 34.601028771284859],
              [-80.742617845957241, 34.600985890124079],
              [-80.742723262448166, 34.600456300605792],
              [-80.742397786819296, 34.6004134188493],
              [-80.74255591150839, 34.599619033951747],
              [-80.741579498043606, 34.599490384645783],
              [-80.741632208284159, 34.599225589472397],
              [-80.739353928551523, 34.598925378792138],
              [-80.739301210045255, 34.599190172076945],
              [-80.737999338982974, 34.599018602622074],
              [-80.73805206264953, 34.598753808993358],
              [-80.736424737112387, 34.598539327130233],
              [-80.736372009424272, 34.598804120026522],
              [-80.735395615327519, 34.598675419527382],
              [-80.735448347172493, 34.598410627045254],
              [-80.735122884526902, 34.598367724651432],
              [-80.735175615964039, 34.59810293230754],
              [-80.734850154699899, 34.598060030076205],
              [-80.734902887909044, 34.597795237839065],
              [-80.734577427988441, 34.59775233396735],
              [-80.734630161879664, 34.597487541852807],
              [-80.734304703340555, 34.597444638143436],
              [-80.734357436804885, 34.597179845265799],
              [-80.734031980718186, 34.597136940801839],
              [-80.734084714883593, 34.59687214894808],
              [-80.733433804647319, 34.596786335873063],
              [-80.733381067366523, 34.597051128357201],
              [-80.733055611191531, 34.597008220360777],
              [-80.733002872529227, 34.597273011779471],
              [-80.732677416762243, 34.597230102741698],
              [-80.732624675666358, 34.597494894913304],
              [-80.731648305160093, 34.597366162107747],
              [-80.731701048251622, 34.597101371283337],
              [-80.731050138708923, 34.597015546184487],
              [-80.731155627941092, 34.596485964145451],
              [-80.730830176260099, 34.596443050100916],
              [-80.730882921378495, 34.59617825874281],
              [-80.730557471078981, 34.596135344860699],
              [-80.730610216879299, 34.595870553625197],
              [-80.727030293790605, 34.595398433502091],
              [-80.726977535637289, 34.595663222274545],
              [-80.725350306758344, 34.595448585714806],
              [-80.725403070003495, 34.595183796758107],
              [-80.725077626492791, 34.595140867120044],
              [-80.725130390419693, 34.594876078286028],
              [-80.724804948252569, 34.594833147007783],
              [-80.724910478219499, 34.594303569548558],
              [-80.72458503845597, 34.594260638576351],
              [-80.724637804495941, 34.593995849951114],
              [-80.724312366075978, 34.59395291733879],
              [-80.724365131707685, 34.593688128851959],
              [-80.724039695740203, 34.593645195485216],
              [-80.724145229097772, 34.593115617819016],
              [-80.723819794443742, 34.593072684773993],
              [-80.723872561079617, 34.592807895610314],
              [-80.72354712887801, 34.592764961810964],
              [-80.723599897285524, 34.592500172754342],
              [-80.723274465337497, 34.592457237330443],
              [-80.723327234426691, 34.592192448396602],
              [-80.723001803860143, 34.592149513135283],
              [-80.72305457363089, 34.591884724324153],
              [-80.722729145497837, 34.591841787407198],
              [-80.722781914860263, 34.591576998734361],
              [-80.722456488089676, 34.59153406107874],
              [-80.722562030017258, 34.591004483926611],
              [-80.722236605631124, 34.59096154567569],
              [-80.722289376016377, 34.590696757227626],
              [-80.721963952992681, 34.590653818238003],
              [-80.722069496946915, 34.590124240634083],
              [-80.721418656008822, 34.59003836058227],
              [-80.721471429528748, 34.589773572486926],
              [-80.720495174830589, 34.589644746207952],
              [-80.720811827102239, 34.588056015277026],
              [-80.72048641638996, 34.588013071368962],
              [-80.720539190505932, 34.587748282816968],
              [-80.71988837218403, 34.587662394444052],
              [-80.719941149074529, 34.587397605241492],
              [-80.718964927210791, 34.58726876649677],
              [-80.718912146145854, 34.587533554383107],
              [-80.717935924261624, 34.58740470636765],
              [-80.71788313979053, 34.587669493802807],
              [-80.717232324853398, 34.587583590127871],
              [-80.717179539087795, 34.587848377239744],
              [-80.716854130027599, 34.587805424401843],
              [-80.716801342899672, 34.588070211349617],
              [-80.716475934229138, 34.588027256569134],
              [-80.716370354728127, 34.588556830146707],
              [-80.716044944373039, 34.58851387509845],
              [-80.715939361447255, 34.589043447404876],
              [-80.715613949388938, 34.589000491187441],
              [-80.715561156402742, 34.589265277616811],
              [-80.71491033013632, 34.589179360461408],
              [-80.714857534765613, 34.589444146582935],
              [-80.714532120576351, 34.589401186546297],
              [-80.714206707836411, 34.589358226513355],
              [-80.714153910062549, 34.589623011425793],
              [-80.70992355651542, 34.589064439923582],
              [-80.709976368645158, 34.588799655960493],
              [-80.709325551915654, 34.588713708526384],
              [-80.709378365748563, 34.588448924829812],
              [-80.708402147909879, 34.588319997470443],
              [-80.70834933101149, 34.588584780736227],
              [-80.707047707890183, 34.588412863765512],
              [-80.706994885454989, 34.588677645550391],
              [-80.706344073904376, 34.588591681935974],
              [-80.706291250174672, 34.588856463397398],
              [-80.705640437943217, 34.58877049596736],
              [-80.705746090201799, 34.588240931780135],
              [-80.705420686640934, 34.588197947029272],
              [-80.705473513825837, 34.587933165040383],
              [-80.705148111627452, 34.587890179551131],
              [-80.705200939493523, 34.587625397685208],
              [-80.704875538638987, 34.587582410556053],
              [-80.704928367186014, 34.587317628813111],
              [-80.704602967694157, 34.58727464094553],
              [-80.703626772390507, 34.587145673837732],
              [-80.703573939756268, 34.587410455006129],
              [-80.703248540605728, 34.587367463466776],
              [-80.703301375351643, 34.587102682426448],
              [-80.702325184118664, 34.586973703832719],
              [-80.702378020499239, 34.586708923218119],
              [-80.70172723044243, 34.586622932759902],
              [-80.701780069615609, 34.586358152396542],
              [-80.70112928296551, 34.58627215869749],
              [-80.70118212275159, 34.586007378616138],
              [-80.70085673150443, 34.585964380579576],
              [-80.700909573042622, 34.585699599704419],
              [-80.700584182068155, 34.585656600944844],
              [-80.700637024305934, 34.585391821094113],
              [-80.700311635783905, 34.585348821580773],
              [-80.700364477594022, 34.585084040967068],
              [-80.700039090434558, 34.585041040715332],
              [-80.700091934015319, 34.584776260209289],
              [-80.69976654821852, 34.584733259219242],
              [-80.699819392480151, 34.584468478836214],
              [-80.699494008045875, 34.584425477107843],
              [-80.69959969650013, 34.58389591658257],
              [-80.69927431443142, 34.583852913358186],
              [-80.699538536308168, 34.582529010576607],
              [-80.699213159707227, 34.582486008090029],
              [-80.699635903544305, 34.58036775941288],
              [-80.699310536528429, 34.580324756277008],
              [-80.699363379404701, 34.580059975237653],
              [-80.699038013769808, 34.580016972264985],
              [-80.699090857308093, 34.579752190447401],
              [-80.698114765494765, 34.579623175767082],
              [-80.698167611756929, 34.579358394360142],
              [-80.697842250618308, 34.579315388131555],
              [-80.697789403316023, 34.579580168493429],
              [-80.697138677820064, 34.579494152232598],
              [-80.697191527165572, 34.579229372158117],
              [-80.696866167030961, 34.579186362382359],
              [-80.696919017038496, 34.578921581529613],
              [-80.696593657195152, 34.5788785719324],
              [-80.696752208237328, 34.578084229682339],
              [-80.697077565016087, 34.578127238848374],
              [-80.697130413660204, 34.57786245791354],
              [-80.697455768687149, 34.577905466970755],
              [-80.697720000560508, 34.576581559483365],
              [-80.697394650641087, 34.576538551144694],
              [-80.697606033846839, 34.575479424102092],
              [-80.69728068835407, 34.575436415456458],
              [-80.697492071244952, 34.574377286843152],
              [-80.697166730178893, 34.574334277890522],
              [-80.697378110594002, 34.57327514863924],
              [-80.697052775044142, 34.573232139364102],
              [-80.697211309540819, 34.572437791294298],
              [-80.696885977395965, 34.572394781568484],
              [-80.696991668125591, 34.57186521636379],
              [-80.696666338364295, 34.571822206043564],
              [-80.696772028665123, 34.571292640158838],
              [-80.696446702377045, 34.571249629228824],
              [-80.696499547502071, 34.570984846858046],
              [-80.696174222576147, 34.570941835189934],
              [-80.69622706945276, 34.570677052025751],
              [-80.695576420486589, 34.570591026362315],
              [-80.695417872668969, 34.571385373128692],
              [-80.695092545633145, 34.571342358543028],
              [-80.695039694669987, 34.571607141214969],
              [-80.69471436693506, 34.571564124702356],
              [-80.694608661924818, 34.572093688796151],
              [-80.694283332506615, 34.572050672015607],
              [-80.694124768253275, 34.572845016235377],
              [-80.693799436111846, 34.572801998141742],
              [-80.693746580002255, 34.573066778457324],
              [-80.693095913609525, 34.572980739352388],
              [-80.693043055134879, 34.573245520261416],
              [-80.692067056654437, 34.573116453634483],
              [-80.692119918193171, 34.572851673156777],
              [-80.691794587075776, 34.57280864962835],
              [-80.691847450384671, 34.57254386925856],
              [-80.691522120629543, 34.572500844992042],
              [-80.691574984618867, 34.572236064745702],
              [-80.691249656207432, 34.57219303883975],
              [-80.691302520877159, 34.571928258716781],
              [-80.69097719384655, 34.57188523297414],
              [-80.691030059178118, 34.571620452073113],
              [-80.690704733509776, 34.571577425592501],
              [-80.690757599540305, 34.571312645716233],
              [-80.690432275215557, 34.571269617596172],
              [-80.690485141926459, 34.57100483784334],
              [-80.690159820053736, 34.570961808969884],
              [-80.690212687444983, 34.570697029340465],
              [-80.689887365844825, 34.570653999744323],
              [-80.689940233897858, 34.570389219336967],
              [-80.689289594871198, 34.570303158672878],
              [-80.689342465715512, 34.57003837851736],
              [-80.688691830095209, 34.569952314613701],
              [-80.688638958297915, 34.570217094466273],
              [-80.688313639423839, 34.57017406060492],
              [-80.688260766264392, 34.57043884029337],
              [-80.687610128586314, 34.57035276962236],
              [-80.687557253043323, 34.570617549002762],
              [-80.686255977693989, 34.570445396503985],
              [-80.686203097724942, 34.570710175288752],
              [-80.684576506649833, 34.570494964098252],
              [-80.684523620125262, 34.570759741257405],
              [-80.683222349973093, 34.570587555848249],
              [-80.683169459022466, 34.570852332411633],
              [-80.681868190224293, 34.570680131417078],
              [-80.681921085259859, 34.570415355429105],
              [-80.681270455485489, 34.570329249470511],
              [-80.681217557317879, 34.570594025186118],
              [-80.680892241930337, 34.570550971191011],
              [-80.680839342400276, 34.570815746742319],
              [-80.680514026332744, 34.570772691721544],
              [-80.680188710606359, 34.570729635818978],
              [-80.679538080158508, 34.570643520467158],
              [-80.679590982665417, 34.570378744605222],
              [-80.676663171720477, 34.569991182719036],
              [-80.676610258914778, 34.570255956399713],
              [-80.675634325005262, 34.570126752500478],
              [-80.675687241945781, 34.569861978334963],
              [-80.675361932340167, 34.569818908448454],
              [-80.675414849978964, 34.569554135307776],
              [-80.675089541735773, 34.56951106468351],
              [-80.675142458946425, 34.569246290780193],
              [-80.674817153155303, 34.569203219402844],
              [-80.674870071045859, 34.568938445623097],
              [-80.674544766617132, 34.568895373507949],
              [-80.674597686295613, 34.568630600737926],
              [-80.67427238213952, 34.568587527900171],
              [-80.674325302461114, 34.568322753450893],
              [-80.673999999667515, 34.56827967987541],
              [-80.67405292068733, 34.568014906451062],
              [-80.673727620345758, 34.567971832122517],
              [-80.673780542045421, 34.567707058821817],
              [-80.673455241976583, 34.5676639837707],
              [-80.673508164356065, 34.567399210593543],
              [-80.673182866720879, 34.567356133888083],
              [-80.673235789780179, 34.567091360834482],
              [-80.67291049352562, 34.567048284292696],
              [-80.673016340644281, 34.566518737511004],
              [-80.672691046754778, 34.566475659473973],
              [-80.672743970832386, 34.566210886647205],
              [-80.672418678305334, 34.566167807872503],
              [-80.672471603044357, 34.565903034268061],
              [-80.672146311898004, 34.565859955656848],
              [-80.672252162394287, 34.56533040867491],
              [-80.671926874720896, 34.56528732945479],
              [-80.672032725875752, 34.56475778177807],
              [-80.671707439496089, 34.564714701979405],
              [-80.671866217830583, 34.563920381179472],
              [-80.671540934836884, 34.563877300029624],
              [-80.671646787752508, 34.563347752710619],
              [-80.671321507141982, 34.563304670967121],
              [-80.671427360734441, 34.562775123854962],
              [-80.671102083596807, 34.562732041502493],
              [-80.671207936758137, 34.562202493710991],
              [-80.67088266200372, 34.562159410764835],
              [-80.670935589093403, 34.561894636982721],
              [-80.670610315719529, 34.561851554200267],
              [-80.670663244560103, 34.561586779625351],
              [-80.670337972548452, 34.561543696105247],
              [-80.670443829086807, 34.561014148114275],
              [-80.670118560529517, 34.560971063083947],
              [-80.670171489298497, 34.560706288751447],
              [-80.669846221032088, 34.560663203900035],
              [-80.669899151570235, 34.56039842967612],
              [-80.669573884647789, 34.560355343185712],
              [-80.669626815865541, 34.560090569085581],
              [-80.669301550305264, 34.560047481857531],
              [-80.66940741268742, 34.559517934801079],
              [-80.669082150599664, 34.55947484696425],
              [-80.669135081745722, 34.559210073106669],
              [-80.668809821020204, 34.559166984532212],
              [-80.668862753935372, 34.558902210783266],
              [-80.668537493482503, 34.558859121486392],
              [-80.668590427058888, 34.558594346959879],
              [-80.668265169057676, 34.55855125691027],
              [-80.668371036156998, 34.55802170900116],
              [-80.668045780538776, 34.557978618357971],
              [-80.668098715151217, 34.557713844960297],
              [-80.66777346089512, 34.557670753579522],
              [-80.667879330011544, 34.557141205224085],
              [-80.667554078138295, 34.557098113249758],
              [-80.667659949020575, 34.55656856508655],
              [-80.667009450381371, 34.556482379069351],
              [-80.667062387352118, 34.556217605245614],
              [-80.666737140636371, 34.556174510142164],
              [-80.666790077197149, 34.555909736457359],
              [-80.666464831861802, 34.555866641517802],
              [-80.666517770191419, 34.555601867941768],
              [-80.666192526199936, 34.555558771363259],
              [-80.666245464119498, 34.555293997926327],
              [-80.665920221490111, 34.555250900610297],
              [-80.666026100507395, 34.55472135303242],
              [-80.665700860278989, 34.554678256024388],
              [-80.665806739972481, 34.554148708654147],
              [-80.665481502108577, 34.554105610151197],
              [-80.665905014214005, 34.551987416527609],
              [-80.666230242804588, 34.552030512992054],
              [-80.666494921204276, 34.550706637569057],
              [-80.666169697716924, 34.55066354182474],
              [-80.66664610510766, 34.548280560889012],
              [-80.666320891128734, 34.548237464658271],
              [-80.666373824950441, 34.547972688537087],
              [-80.665072977637365, 34.547800296261698],
              [-80.665125915200036, 34.547535520696748],
              [-80.664800706057932, 34.547492420331658],
              [-80.664747767474708, 34.547757195752631],
              [-80.664422556564062, 34.547714094377191],
              [-80.664369616618998, 34.547978869634093],
              [-80.663719194869842, 34.547892663935698],
              [-80.663772136856096, 34.547627888966844],
              [-80.663446928058676, 34.547584784931956],
              [-80.663499870724053, 34.54732001008729],
              [-80.663174662199225, 34.547276905330158],
              [-80.66322760554381, 34.547012130609588],
              [-80.66225198832862, 34.546882810535209],
              [-80.662304934393404, 34.546618036226839],
              [-80.661979730364308, 34.546574928217112],
              [-80.662032677108257, 34.546310154032867],
              [-80.661707474422684, 34.546267044384365],
              [-80.66181336999864, 34.545737495327927],
              [-80.662138570643236, 34.545780604688261],
              [-80.662720956731022, 34.54286807516177],
              [-80.662395768419003, 34.542824968272569],
              [-80.662501653976378, 34.542295416009871],
              [-80.662176468046397, 34.54225230852748],
              [-80.662282354279426, 34.541722756473497],
              [-80.661957170713293, 34.541679647496494],
              [-80.662116001653374, 34.54088531968403],
              [-80.661790821489745, 34.540842210257985],
              [-80.661843765111101, 34.540577434429956],
              [-80.661518586309143, 34.540534324266638],
              [-80.66162447563967, 34.540004771922803],
              [-80.661299299237939, 34.539961662067718],
              [-80.66177579069047, 34.537578671918162],
              [-80.661828732238718, 34.537313895239485],
              [-80.661503567452272, 34.537270785027616],
              [-80.66160945154725, 34.536741230997947],
              [-80.661284289142714, 34.536698120192924],
              [-80.661337232778635, 34.536433342826861],
              [-80.661012071735641, 34.536390231284635],
              [-80.661065014961309, 34.53612545405803],
              [-80.660739855297976, 34.536082342680011],
              [-80.660792799184463, 34.535817564676414],
              [-80.660142483993212, 34.535731338648326],
              [-80.660195429596996, 34.535466561814552],
              [-80.658244498263144, 34.535207859839048],
              [-80.658297450736057, 34.534943083835195],
              [-80.656021387399704, 34.534641225918143],
              [-80.656233222388209, 34.533582123037739],
              [-80.656558368737521, 34.533625248443549],
              [-80.656717238320496, 34.532830919968035],
              [-80.657042381932484, 34.532874043158891],
              [-80.657095336757308, 34.532609266816884],
              [-80.656770194165446, 34.532566143770246],
              [-80.656823149669052, 34.532301367552797],
              [-80.6564980084204, 34.532258242867826],
              [-80.656550964602857, 34.5319934667748],
              [-80.656225824733824, 34.531950342254035],
              [-80.656331738096554, 34.531420789396137],
              [-80.655681464534041, 34.531334537371251],
              [-80.655734422734554, 34.531069760750384],
              [-80.655409286949876, 34.531026634018467],
              [-80.655462245829014, 34.530761857522222],
              [-80.655137112476851, 34.530718729136858],
              [-80.655190072052861, 34.530453953666502],
              [-80.654539807323502, 34.530367694555565],
              [-80.65459276966925, 34.530102918437557],
              [-80.654267638310316, 34.530059788613407],
              [-80.654320601334717, 34.529795012619886],
              [-80.653995472408099, 34.529751881142367],
              [-80.65404843502202, 34.529487105288425],
              [-80.6537233074569, 34.529443973073889],
              [-80.653776270749461, 34.529179197344554],
              [-80.653126018683352, 34.529092930560232],
              [-80.653178983674565, 34.528828155099738],
              [-80.652203612285078, 34.528698748731962],
              [-80.652256579994884, 34.528433973684443],
              [-80.651606336511932, 34.528347698650201],
              [-80.651659305902172, 34.5280829229701],
              [-80.651334186237406, 34.528039784267939],
              [-80.6513871563062, 34.527775008712474],
              [-80.651062038002834, 34.527731869273367],
              [-80.651115009857392, 34.527467094728898],
              [-80.650789891808088, 34.527423953666499],
              [-80.650895836514721, 34.526894403905949],
              [-80.650570720846844, 34.526851262250794],
              [-80.650729638378706, 34.526056936543888],
              [-80.650404526130075, 34.526013795341626],
              [-80.650775327714442, 34.52416036452621],
              [-80.650450224035581, 34.524117223437358],
              [-80.650662106718016, 34.523058118337048],
              [-80.650337008549172, 34.523014976929041],
              [-80.650442949328067, 34.522485424107259],
              [-80.650495919740891, 34.522220647209039],
              [-80.650821014868342, 34.522263789085827],
              [-80.650873983919936, 34.521999012023947],
              [-80.651199078369061, 34.522042152875365],
              [-80.651252046059355, 34.521777375649805],
              [-80.65157713981192, 34.521820514574664],
              [-80.651630106141099, 34.521555737185466],
              [-80.651955199233313, 34.521598875986328],
              [-80.6520081631122, 34.52133409844847],
              [-80.652333256596876, 34.521377235307781],
              [-80.652386219114675, 34.521112457606307],
              [-80.652061127738833, 34.521069320876187],
              [-80.652114090935186, 34.520804543299491],
              [-80.652439181291129, 34.520847679885414],
              [-80.652545105709024, 34.520318124370256],
              [-80.652870194366798, 34.520361259786696],
              [-80.65302907479348, 34.51956692414074],
              [-80.653354159662243, 34.519610059159831],
              [-80.653460076551454, 34.519080501071265],
              [-80.653785159721991, 34.519123634920994],
              [-80.653891072117418, 34.51859407648147],
              [-80.654216154660674, 34.518637208245408],
              [-80.654322063651406, 34.518107649440118],
              [-80.654647143425592, 34.518150780950954],
              [-80.654700096371045, 34.517886000473794],
              [-80.655025176555725, 34.517929130944388],
              [-80.655131078312678, 34.51739957055991],
              [-80.655456156780872, 34.517442698959712],
              [-80.655773847725129, 34.515854015575954],
              [-80.655448776464254, 34.515810888026216],
              [-80.655554673832029, 34.515281326416179],
              [-80.655229604933623, 34.51523819737244],
              [-80.655441397457054, 34.514179073626501],
              [-80.655766462258597, 34.514222201192155],
              [-80.655872354424545, 34.51369263846459],
              [-80.656197416457303, 34.513735765777376],
              [-80.656250361544167, 34.513470983782312],
              [-80.656575422880223, 34.51351410916859],
              [-80.656628366606242, 34.51324932701003],
              [-80.656303305200936, 34.513206201783056],
              [-80.656462135212934, 34.512411855654726],
              [-80.656137078278405, 34.51236872906285],
              [-80.656295908260475, 34.511574382292764],
              [-80.65597085474414, 34.511531256153837],
              [-80.656076742088956, 34.511001691527291],
              [-80.656182626961979, 34.510472125937675],
              [-80.656288511559183, 34.509942560256199],
              [-80.656613558976161, 34.509985686431406],
              [-80.656666499198991, 34.509720903425276],
              [-80.656991545919311, 34.509764027674002],
              [-80.657044484781409, 34.509499244504553],
              [-80.656719439080661, 34.509456120400003],
              [-80.656931196248294, 34.508396986289497],
              [-80.657256236800166, 34.508440110733723],
              [-80.65736211073542, 34.507910542381467],
              [-80.657687150678314, 34.507953665641402],
              [-80.657740086114714, 34.507688881292417],
              [-80.65806512429036, 34.507732003542216],
              [-80.658170992100665, 34.507202434498545],
              [-80.658223925494198, 34.50693764994805],
              [-80.658548960934198, 34.506980769983166],
              [-80.659078262538728, 34.504332916623795],
              [-80.659131191142635, 34.504068130818005],
              [-80.659456214638851, 34.504111249302817],
              [-80.659562067677555, 34.503581676459447],
              [-80.659887090564737, 34.50362479375994],
              [-80.659940015017796, 34.503360007623762],
              [-80.659614994238822, 34.503316890452325],
              [-80.659667919370264, 34.503052104441316],
              [-80.659992940200283, 34.503095220552169],
              [-80.660098787383077, 34.502565647283426],
              [-80.660423806533501, 34.502608763126482],
              [-80.660476728584783, 34.502343975868776],
              [-80.660801745968044, 34.502387090701873],
              [-80.660854666658892, 34.502122303280935],
              [-80.661179684434387, 34.502165416172595],
              [-80.661232603764816, 34.501900628588601],
              [-80.661557619791353, 34.501943741371704],
              [-80.661610537761376, 34.501678953624577],
              [-80.661935553109416, 34.501722065382694],
              [-80.661988469719034, 34.501457277472348],
              [-80.662638499381103, 34.501543497156383],
              [-80.662691413610958, 34.501278708938884],
              [-80.663016427924958, 34.501321816864817],
              [-80.663069340794436, 34.501057028484112],
              [-80.663394354429897, 34.501100135385059],
              [-80.663447265939126, 34.50083534684137],
              [-80.664097292212332, 34.500921558613818],
              [-80.664203109023163, 34.500391980006775],
              [-80.664528120641592, 34.500435084734093],
              [-80.664739746076435, 34.499375925814938],
              [-80.664845556739266, 34.498846345791151],
              [-80.664520551237189, 34.498803241928506],
              [-80.664626362575149, 34.498273662117356],
              [-80.664951364931525, 34.498316764805502],
              [-80.665057172849032, 34.497787183729045],
              [-80.665382173525884, 34.497830286149522],
              [-80.665487978041099, 34.49730070470941],
              [-80.665812977001991, 34.497343805059465],
              [-80.665865877193653, 34.497079014625236],
              [-80.66619087547609, 34.497122113950361],
              [-80.666243775378092, 34.496857322436654],
              [-80.666568773000336, 34.496900421638152],
              [-80.666674567565735, 34.496370838296201],
              [-80.666999563471805, 34.496413935427398],
              [-80.667052459223939, 34.496149143584041],
              [-80.667377454469815, 34.496192240591647],
              [-80.667483242894775, 34.495662655658833],
              [-80.667808235354187, 34.495705751512659],
              [-80.667861128045246, 34.495440959324576],
              [-80.66818612089665, 34.495484053237],
              [-80.668239012227602, 34.49521926088601],
              [-80.668564004400494, 34.495262353773448],
              [-80.668616893264371, 34.494997560373335],
              [-80.66894188477697, 34.495040653137366],
              [-80.668994773369462, 34.494775859559184],
              [-80.669319763114956, 34.49481895131342],
              [-80.669372650347384, 34.494554157572409],
              [-80.669697640484642, 34.494597247385315],
              [-80.669750525286631, 34.494332454397892],
              [-80.670075514745463, 34.494375543185875],
              [-80.670128398169098, 34.49411074913418],
              [-80.670453386949291, 34.494153836897368],
              [-80.670559150735798, 34.4936242484495],
              [-80.67088413783658, 34.493667335945112],
              [-80.670937018199467, 34.493402541548882],
              [-80.671262003514627, 34.493445627133461],
              [-80.671314883606172, 34.493180832559254],
              [-80.671639868242863, 34.493223917118989],
              [-80.671692745885778, 34.492959122397139],
              [-80.672017730932566, 34.493002205916795],
              [-80.672070606126852, 34.492737411047287],
              [-80.672395590494972, 34.492780493542185],
              [-80.672448465399626, 34.492515697593305],
              [-80.672773448018958, 34.492558779979937],
              [-80.672826321563676, 34.492293983868272],
              [-80.673476286837683, 34.492380143893143],
              [-80.673529156932915, 34.492115348391103],
              [-80.674179121531054, 34.492201504604843],
              [-80.674231989228758, 34.491936707894681],
              [-80.674556971563888, 34.491979784528766],
              [-80.674881953150944, 34.492022860297205],
              [-80.674934818487856, 34.49175806418166],
              [-80.675584781734017, 34.491844212773053],
              [-80.675637644673429, 34.491579415449259],
              [-80.675962626332435, 34.49162248827222],
              [-80.676015487911954, 34.491357690785684],
              [-80.676340467803783, 34.491400762599035],
              [-80.676393328023465, 34.491135964949692],
              [-80.676718307236598, 34.49117903573827],
              [-80.676929740614099, 34.490119843476769],
              [-80.677579691908051, 34.490205981259386],
              [-80.677632547348736, 34.489941182183209],
              [-80.677957522487361, 34.489984249609421],
              [-80.678010376586656, 34.489719451271917],
              [-80.678985300969558, 34.489848646932558],
              [-80.679038150581917, 34.489583848159668],
              [-80.679363125025773, 34.489626910873412],
              [-80.679468822260873, 34.48909731206691],
              [-80.679793795025191, 34.489140374513347],
              [-80.679846641559237, 34.488875574495026],
              [-80.680171613644745, 34.488918635916761],
              [-80.680224458819168, 34.488653835735796],
              [-80.680549430225938, 34.488696896132687],
              [-80.680602274040552, 34.488432095789108],
              [-80.680927244750151, 34.488475154259959],
              [-80.681032929319642, 34.487945553228933],
              [-80.681357898349702, 34.487988611432428],
              [-80.681463579500416, 34.487459009137588],
              [-80.681788546832607, 34.487502066172382],
              [-80.681841385868864, 34.487237264402367],
              [-80.682166351433708, 34.487280320427637],
              [-80.682219189110327, 34.487015518495035],
              [-80.682544155084898, 34.487058573480411],
              [-80.682649826308349, 34.48652897018809],
              [-80.682324863460721, 34.486485915475427],
              [-80.682483371316906, 34.485691509469824],
              [-80.682158411885581, 34.485648455209834],
              [-80.682264084833278, 34.485118850489599],
              [-80.683238958070248, 34.48524801156649],
              [-80.683291790985294, 34.484983209197402],
              [-80.683616748398705, 34.48502626128478],
              [-80.683669578847102, 34.484761457866931],
              [-80.684319492656869, 34.484847559111678],
              [-80.684425149562955, 34.484317952530702],
              [-80.684750104921662, 34.484361000642735],
              [-80.684855758428029, 34.483831393699674],
              [-80.685180712107268, 34.483874441544444],
              [-80.685339185096325, 34.483080029229932],
              [-80.685014235563003, 34.483036981801845],
              [-80.685067060231134, 34.482772177803959],
              [-80.684742110968827, 34.482729129654373],
              [-80.685006235351707, 34.481405108289508],
              [-80.685331179518514, 34.481448155719278],
              [-80.685436824412093, 34.480918546581933],
              [-80.685761765774188, 34.480961591957062],
              [-80.685814586700687, 34.480696787667341],
              [-80.685489646339178, 34.480653741534816],
              [-80.685595289172497, 34.48012413138548],
              [-80.68527035118943, 34.480081084660206],
              [-80.685428817014227, 34.47928666881301],
              [-80.685753750851632, 34.479329715121672],
              [-80.685806570736915, 34.479064909964045],
              [-80.685481637918528, 34.479021863799339],
              [-80.685587278687777, 34.47849225281535],
              [-80.685262347159266, 34.478449206073243],
              [-80.685420808790283, 34.477654788539112],
              [-80.685095881747429, 34.477611741333064],
              [-80.68525434337289, 34.476817324065301],
              [-80.684929419727254, 34.47677427641058],
              [-80.68498224060744, 34.476509470761904],
              [-80.684657318321129, 34.476466422370457],
              [-80.684710139861522, 34.476201615946046],
              [-80.684385218934381, 34.476158566817986],
              [-80.684438041171887, 34.475893761420558],
              [-80.684113121585597, 34.475850710654584],
              [-80.684218767059136, 34.475321099191191],
              [-80.683893849869477, 34.475278048733976],
              [-80.68394667310551, 34.475013242668105],
              [-80.683296842873233, 34.474927139384469],
              [-80.68334966780678, 34.474662333588313],
              [-80.683024753657307, 34.474619279875924],
              [-80.683077580357846, 34.474354474190072],
              [-80.682752667586129, 34.474311420642465],
              [-80.682805493876856, 34.474046615097564],
              [-80.6824805835342, 34.474003559896829],
              [-80.682639064491156, 34.473209142667535],
              [-80.682963972865508, 34.473252197421083],
              [-80.6831224465963, 34.472457778709114],
              [-80.683447352235902, 34.472500831248738],
              [-80.683500175107227, 34.47223602453041],
              [-80.684149983211469, 34.47232212761201],
              [-80.684202803704721, 34.472057320587368],
              [-80.68452770832755, 34.472100370197396],
              [-80.684580527461776, 34.471835563010551],
              [-80.685230333532132, 34.471921660232972],
              [-80.685283150288512, 34.471656852739898],
              [-80.686582762418212, 34.471829035140928],
              [-80.686635574740379, 34.471564226152346],
              [-80.687610284358001, 34.471693353500939],
              [-80.687663093283376, 34.471428544062213],
              [-80.687987996163059, 34.47147158520761],
              [-80.688146415683434, 34.470677157282132],
              [-80.68782151587871, 34.470634117469984],
              [-80.687874322373517, 34.470369308875],
              [-80.687549425016158, 34.47032626831092],
              [-80.68765503898598, 34.469796649551519],
              [-80.687330144006424, 34.469753608394832],
              [-80.68743575865318, 34.469223989850498],
              [-80.687110866051398, 34.469180948101219],
              [-80.687269289596316, 34.468386519663497],
              [-80.68694440039117, 34.468343477465645],
              [-80.687050016781058, 34.467813858398564],
              [-80.687374903948765, 34.467856900308526],
              [-80.687480516940454, 34.467327280880838],
              [-80.687805402410774, 34.467370321622347],
              [-80.687911010916054, 34.466840701849456],
              [-80.688235894688944, 34.466883741422585],
              [-80.688288698491647, 34.466618930898939],
              [-80.688499905948106, 34.465559688684351],
              [-80.688175027250267, 34.465516648929679],
              [-80.688280632044993, 34.46498702708498],
              [-80.687955755743218, 34.464943987639096],
              [-80.68800855809566, 34.464679176390511],
              [-80.687683683152812, 34.464636136208142],
              [-80.687736487272076, 34.464371325070083],
              [-80.687411613687942, 34.464328284151243],
              [-80.687675629904319, 34.463004228069501],
              [-80.687350762841902, 34.462961186974717],
              [-80.687614775611493, 34.461637129358529],
              [-80.687939637599229, 34.461680170634793],
              [-80.688045237838921, 34.46115054645913],
              [-80.688370098129454, 34.461193586566971],
              [-80.688422896720638, 34.460928774308222],
              [-80.688747756314129, 34.460971812490392],
              [-80.688800552458304, 34.460707000084902],
              [-80.689125411391885, 34.460750038143999],
              [-80.689178207265599, 34.460485225561278],
              [-80.690152781925519, 34.460614332237],
              [-80.690205574403379, 34.460349519204314],
              [-80.690855290149756, 34.46043558593707],
              [-80.690960868904199, 34.459905958355947],
              [-80.690636013451609, 34.45986292616071],
              [-80.690688802784024, 34.459598112044098],
              [-80.690363948689964, 34.459555079112356],
              [-80.690416738700932, 34.459290265121702],
              [-80.68976703246345, 34.459204196018959],
              [-80.689819825259832, 34.4589393822829],
              [-80.689494974223024, 34.458896346997861],
              [-80.68954776660965, 34.458631533403143],
              [-80.688898067574868, 34.458545459578467],
              [-80.688950862746751, 34.458280646238435],
              [-80.688626013660482, 34.458237607714146],
              [-80.688678809510932, 34.457972794500037],
              [-80.68835396287146, 34.457929755224015],
              [-80.688406759400308, 34.457664942135999],
              [-80.688081914119422, 34.457621902123577],
              [-80.688134711326711, 34.457357089161519],
              [-80.687809867404511, 34.45731404841267],
              [-80.687862665290254, 34.457049235576655],
              [-80.687537822726625, 34.45700619409152],
              [-80.687590621272307, 34.456741380480182],
              [-80.686940939220591, 34.45665529605801],
              [-80.68699373946319, 34.456390482716692],
              [-80.686668901044939, 34.456347438863055],
              [-80.686721701965936, 34.456082625647902],
              [-80.686396863818004, 34.456039581073227],
              [-80.686449665435973, 34.455774768885412],
              [-80.685799993267082, 34.455688676466409],
              [-80.685852796581798, 34.4554238645486],
              [-80.685527962560741, 34.455380816704306],
              [-80.685686374552333, 34.454586378556307],
              [-80.685361542857123, 34.454543331180346],
              [-80.68551995482467, 34.453748892402004],
              [-80.685195127594383, 34.45370584366097],
              [-80.685247932653454, 34.453441031133366],
              [-80.684923106781667, 34.453397981656131],
              [-80.684975911430826, 34.453133169269968],
              [-80.684326262762909, 34.453047068863796],
              [-80.684379070178394, 34.452782255831146],
              [-80.683404602791683, 34.452653099502449],
              [-80.683351792321062, 34.452917912103054],
              [-80.683026970596487, 34.452874857472651],
              [-80.683079781015891, 34.452610045932744],
              [-80.681455679359686, 34.45239476214531],
              [-80.681508495600312, 34.452129950391061],
              [-80.681183676865231, 34.452086890782304],
              [-80.681130859606256, 34.452351702392534],
              [-80.680481222226561, 34.452265581132245],
              [-80.680692497349725, 34.451206334835319],
              [-80.680367683778229, 34.451163273002635],
              [-80.680473321326133, 34.450633649584667],
              [-80.680148510131431, 34.450590587159823],
              [-80.68030696694774, 34.449796151880626],
              [-80.679982159148054, 34.449753089007679],
              [-80.68003497841859, 34.449488277056027],
              [-80.680359785199983, 34.449531339784841],
              [-80.68051823789736, 34.448736902489408],
              [-80.680843041964124, 34.448779963905878],
              [-80.681159932047009, 34.447191087069463],
              [-80.68148472925634, 34.447234146756578],
              [-80.681537542052325, 34.446969333293708],
              [-80.681862339671596, 34.447012391941314],
              [-80.682020771857466, 34.44621795012791],
              [-80.68169597839929, 34.446174892798467],
              [-80.682012839660516, 34.444586007754985],
              [-80.682337627028119, 34.444629065121426],
              [-80.682390434298355, 34.444364250451244],
              [-80.682715220987888, 34.444407306793551],
              [-80.682873639879645, 34.443612862214366],
              [-80.683198422766779, 34.443655917259534],
              [-80.683251227728434, 34.443391102215394],
              [-80.682926444771439, 34.443348047329458],
              [-80.683084858476121, 34.442553601652214],
              [-80.683409638378606, 34.442596656106026],
              [-80.683462440873626, 34.442331840105162],
              [-80.683787220098125, 34.442374893534762],
              [-80.683840021234971, 34.44211007737222],
              [-80.684164798693402, 34.442153129792956],
              [-80.684217599560085, 34.441888313453454],
              [-80.684542376340431, 34.441931364849971],
              [-80.68459517477946, 34.441666549265499],
              [-80.684919950863247, 34.441709598736459],
              [-80.685078341030604, 34.440915148741276],
              [-80.685403115506503, 34.440958197786003],
              [-80.685455910530862, 34.440693380941774],
              [-80.68578068324058, 34.440736428977623],
              [-80.685886269126755, 34.440206794062128],
              [-80.686535812581923, 34.440292886902206],
              [-80.686588602425658, 34.440028068687113],
              [-80.687238145204461, 34.440114157718511],
              [-80.687290932671971, 34.439849339197728],
              [-80.687615703009243, 34.439892382256915],
              [-80.687826847602508, 34.438833108293068],
              [-80.687502081337556, 34.438790065809911],
              [-80.687660438709599, 34.437995609916427],
              [-80.687335676926736, 34.437952566969912],
              [-80.687441249188737, 34.437422929001514],
              [-80.687766008935611, 34.437465971660089],
              [-80.687871577783397, 34.436936332432218],
              [-80.688196335834149, 34.436979373922661],
              [-80.688249119292621, 34.436714555032175],
              [-80.68857387666516, 34.436757595498513],
              [-80.688626657659398, 34.436492775560595],
              [-80.688951415441693, 34.436535814987408],
              [-80.689004195078041, 34.436270994887892],
              [-80.689328951094296, 34.436314033306047],
              [-80.689381729372684, 34.436049213045067],
              [-80.68970648469238, 34.436092249537751],
              [-80.689759261612878, 34.435827429115292],
              [-80.690084017360817, 34.43587046546989],
              [-80.690189567058397, 34.435340824300042],
              [-80.690514321091641, 34.435383858585141],
              [-80.690567093868722, 34.43511903783768],
              [-80.690672640560663, 34.434589395358408],
              [-80.690023141080744, 34.434503324938468],
              [-80.690075915962183, 34.434238504411368],
              [-80.689426419877563, 34.434152430759092],
              [-80.689479196436579, 34.433887609601101],
              [-80.688829704835115, 34.43380153270126],
              [-80.688882483090183, 34.433536711813815],
              [-80.688232993796078, 34.433450631697056],
              [-80.688285774835038, 34.433185811064888],
              [-80.687636288936361, 34.433099727715813],
              [-80.687689071652869, 34.432834906452953],
              [-80.687364330231233, 34.43279186360234],
              [-80.687469895629434, 34.432262223130401],
              [-80.687145156564995, 34.432219178786404],
              [-80.687197940316111, 34.431954358660647],
              [-80.686873202628021, 34.431911314481994],
              [-80.686925987038663, 34.431646493581596],
              [-80.686601251796091, 34.431603448651614],
              [-80.686548466349095, 34.431868268506697],
              [-80.685249523600007, 34.43169607852429],
              [-80.685302313100124, 34.431431258343956],
              [-80.684977579557057, 34.431388209013811],
              [-80.685030369735045, 34.431123388960138],
              [-80.684705637531266, 34.431080337992654],
              [-80.685022375392492, 34.429491418200712],
              [-80.684697649635666, 34.429448367217525],
              [-80.684750439469923, 34.429183547330361],
              [-80.684425715070901, 34.429140495611144],
              [-80.684478505583058, 34.42887567585079],
              [-80.684153782541671, 34.428832623395628],
              [-80.684628888655368, 34.426449237085642],
              [-80.684304176220053, 34.426406185933132],
              [-80.684356964244031, 34.426141364500225],
              [-80.684032254235746, 34.426098311695057],
              [-80.684507345755108, 34.423714919242457],
              [-80.684832047691899, 34.423757970735736],
              [-80.685201536084179, 34.421904213682595],
              [-80.684876842358705, 34.421861163182292],
              [-80.684929625604184, 34.421596340699161],
              [-80.684604933236187, 34.42155328946302],
              [-80.684657718228678, 34.421288466190106],
              [-80.684008336547564, 34.421202361366312],
              [-80.684061123253898, 34.420937539265701],
              [-80.682762369109525, 34.420765318719006],
              [-80.682815159528033, 34.420500496276162],
              [-80.682165787083292, 34.420414381454613],
              [-80.682218579196984, 34.420149559282855],
              [-80.681569210128615, 34.42006344032859],
              [-80.681622003937548, 34.419798618427834],
              [-80.681297320396112, 34.419755558233284],
              [-80.68135011488269, 34.419490736459402],
              [-80.681025433786473, 34.419447675513901],
              [-80.68107822895071, 34.419182853866928],
              [-80.680753549193426, 34.419139791284223],
              [-80.680911935680882, 34.418345325771568],
              [-80.680964730503135, 34.418080504200184],
              [-80.6812894061718, 34.418123565305137],
              [-80.681342199636546, 34.417858743572552],
              [-80.68166687462768, 34.417901803653592],
              [-80.681719666735034, 34.417636981759806],
              [-80.682044341048538, 34.417680040816883],
              [-80.6820971317985, 34.41741521876191],
              [-80.682421805434373, 34.41745827679506],
              [-80.682474593720883, 34.417193453692818],
              [-80.682799266697529, 34.417236511603392],
              [-80.682852054714331, 34.416971688324686],
              [-80.683176726994802, 34.417014744310009],
              [-80.683229512566712, 34.416749920885508],
              [-80.683554184187955, 34.416792976748198],
              [-80.683606968402444, 34.416528153162517],
              [-80.683931639327668, 34.416571207100013],
              [-80.684037205789821, 34.416041559573856],
              [-80.68436187501986, 34.416084612343361],
              [-80.684414656179911, 34.41581978841829],
              [-80.684739324732291, 34.415862840163861],
              [-80.684897663102831, 34.415068366952809],
              [-80.685222328942473, 34.415111417386328],
              [-80.685275106689943, 34.414846592203617],
              [-80.685327884116177, 34.414581767905176],
              [-80.685003221380512, 34.414538716843154],
              [-80.685214332770997, 34.413479416434761],
              [-80.68488967335729, 34.413436365084401],
              [-80.684942452188594, 34.413171540072454],
              [-80.68461779523814, 34.413128488872431],
              [-80.684881684960928, 34.411804360733605],
              [-80.684557033437287, 34.411761309374135],
              [-80.684662588540334, 34.411231657933534],
              [-80.684337940460424, 34.411188605065774],
              [-80.684443496239169, 34.410658953845413],
              [-80.68411885053365, 34.410615900386134],
              [-80.68422440696969, 34.410086248484788],
              [-80.683899763657067, 34.41004319533527],
              [-80.684216431965268, 34.408454238271595],
              [-80.683891795077983, 34.408411184205448],
              [-80.683997350128706, 34.407881531708412],
              [-80.6836727167031, 34.407838477035426],
              [-80.683936604502691, 34.406514343498529],
              [-80.684261232841848, 34.406557397451181],
              [-80.68431400814039, 34.406292570191617],
              [-80.684638636889531, 34.406335623105164],
              [-80.684691410831078, 34.406070795684677],
              [-80.685016037815203, 34.406113847589737],
              [-80.685121582644641, 34.405584192410132],
              [-80.685446207933978, 34.405627243147343],
              [-80.685770834650413, 34.405670292989548],
              [-80.68582360450273, 34.405405464184945],
              [-80.687122107434149, 34.405577654226235],
              [-80.687174871790205, 34.405312824843868],
              [-80.690096515947957, 34.405700196946469],
              [-80.69014927082749, 34.405435367152229],
              [-80.690473899228792, 34.405478403344873],
              [-80.690526652751444, 34.405213573389879],
              [-80.690851279387587, 34.405256608574192],
              [-80.690904031553387, 34.404991778458282],
              [-80.691228657511829, 34.405034812618851],
              [-80.691386908901478, 34.404240320836962],
              [-80.691062284888815, 34.40419728622242],
              [-80.691431531087559, 34.402343467408613],
              [-80.6911069167286, 34.402300433793222],
              [-80.6912124141231, 34.401770769873664],
              [-80.691537027553736, 34.401813804086991],
              [-80.69158977418077, 34.401548971965745],
              [-80.691914386933746, 34.401592005155372],
              [-80.691967133291485, 34.401327172857989],
              [-80.692291744279274, 34.401370205039306],
              [-80.692397232854489, 34.400840540121777],
              [-80.692721843216404, 34.400883570218653],
              [-80.692774585433796, 34.400618737598457],
              [-80.692932811117927, 34.39982423872101],
              [-80.693257416681718, 34.399867268278889],
              [-80.693415636240402, 34.399072767917971],
              [-80.693740239092321, 34.399115796164082],
              [-80.693792976880431, 34.39885096223778],
              [-80.693468375064199, 34.398807935036956],
              [-80.693521113529982, 34.398543101237919],
              [-80.693845715416472, 34.398586128279369],
              [-80.693898452525659, 34.398321294319658],
              [-80.694547653126492, 34.398407346407758],
              [-80.694600387861954, 34.39814251214338],
              [-80.695249587786222, 34.398228560424904],
              [-80.695355051083027, 34.39769889128565],
              [-80.695030452994672, 34.397655868323476],
              [-80.695135918036726, 34.397126198488998],
              [-80.694811322303536, 34.397083174033732],
              [-80.695022252382941, 34.396023834741428],
              [-80.695346842941916, 34.396066857734638],
              [-80.69539957358684, 34.395802022275397],
              [-80.695724164574074, 34.395845045131061],
              [-80.695776892775157, 34.395580209526535],
              [-80.696426072627702, 34.395666251424963],
              [-80.696478799542618, 34.395401415500579],
              [-80.696803388407929, 34.395444434543457],
              [-80.696856113966362, 34.395179598458419],
              [-80.69718070217256, 34.395222617379041],
              [-80.697286149131259, 34.394692943985405],
              [-80.69761073564267, 34.394735961738377],
              [-80.69766345704295, 34.394471124429813],
              [-80.697988043964003, 34.39451414114388],
              [-80.698409795919602, 34.392395441813427],
              [-80.698085218222189, 34.392352426235838],
              [-80.698137936568529, 34.392087588778409],
              [-80.697813361314758, 34.392044572449706],
              [-80.698024235337456, 34.390985221228291],
              [-80.698348807591955, 34.39102823606428],
              [-80.698401524241632, 34.390763398524363],
              [-80.698076954072803, 34.390720382915589],
              [-80.698129671381551, 34.390455544601913],
              [-80.697805101481705, 34.390412528272968],
              [-80.697910538202578, 34.38988285186867],
              [-80.697963255510373, 34.389618013649589],
              [-80.697638690087999, 34.389574996857661],
              [-80.69769140807341, 34.389310158766023],
              [-80.698015972460254, 34.389353174512621],
              [-80.698068689089524, 34.389088336260627],
              [-80.697744125700936, 34.389045319756669],
              [-80.697954992836699, 34.387985964456142],
              [-80.698007708767733, 34.387721125364557],
              [-80.697683151909047, 34.387678109587014],
              [-80.697735868517682, 34.387413270623028],
              [-80.6970867556401, 34.38732723494546],
              [-80.697139475030468, 34.387062396237653],
              [-80.696490365543923, 34.386976357330049],
              [-80.696543086610149, 34.386711517992474],
              [-80.696218533392681, 34.386668497363381],
              [-80.696271255155125, 34.386403659054849],
              [-80.695946703293728, 34.386360637690345],
              [-80.695999425715129, 34.386095798608004],
              [-80.695674875210017, 34.38605277650796],
              [-80.695727598308835, 34.38578793755331],
              [-80.695403049159879, 34.385744914717769],
              [-80.695508495304665, 34.38521523796409],
              [-80.694859404247921, 34.385129189298482],
              [-80.694912129379247, 34.384864350726801],
              [-80.69393849839895, 34.38473527201517],
              [-80.693991225154122, 34.384470433874561],
              [-80.693342142645236, 34.384384376975007],
              [-80.693394871094625, 34.384119539106074],
              [-80.692745791976819, 34.384033478976825],
              [-80.692798523207614, 34.383768641364213],
              [-80.692473984621614, 34.383725609681484],
              [-80.692526716529898, 34.383460772196592],
              [-80.691877643515284, 34.383374707366841],
              [-80.691930377099055, 34.383109869252337],
              [-80.69160584158324, 34.383066836120669],
              [-80.691658575844386, 34.382801998133957],
              [-80.691009510020322, 34.382715928588418],
              [-80.691062245975544, 34.382451090873431],
              [-80.690737713492837, 34.38240805449027],
              [-80.690790451212564, 34.38214321688767],
              [-80.691114981591369, 34.38218625314218],
              [-80.69116771686781, 34.381921415394537],
              [-80.691492246569283, 34.381964450625588],
              [-80.691544980471093, 34.381699611816224],
              [-80.691869509495248, 34.381742646023774],
              [-80.691922242059505, 34.381477807955513],
              [-80.692246771493501, 34.381520841124235],
              [-80.692352232464899, 34.380991163764577],
              [-80.692676759117575, 34.381034195781162],
              [-80.693151310194011, 34.378650642368214],
              [-80.692826792691292, 34.37860761164805],
              [-80.693143150094528, 34.377018571977018],
              [-80.692818640136537, 34.37697554212783],
              [-80.693240438671779, 34.37485681770962],
              [-80.693564940515657, 34.374899847307859],
              [-80.693617662098148, 34.374635005985056],
              [-80.693942163264751, 34.374678034559892],
              [-80.693994883491428, 34.374413193076968],
              [-80.693670384428444, 34.374370164630776],
              [-80.693723105332381, 34.374105323275685],
              [-80.693398606538153, 34.374062294109649],
              [-80.693451329206525, 34.373797452867102],
              [-80.693126831768097, 34.373754422965739],
              [-80.69317955511363, 34.373489581851182],
              [-80.692530564374749, 34.373403519683308],
              [-80.69263601300851, 34.372873837096009],
              [-80.692688737369224, 34.372608996221246],
              [-80.693013228975872, 34.372652027320321],
              [-80.693065951962367, 34.372387185384007],
              [-80.692741460285063, 34.372344154444292],
              [-80.692846906185963, 34.371814470826827],
              [-80.69252241796795, 34.371771439280543],
              [-80.692627865631849, 34.371241755871509],
              [-80.69230337978604, 34.371198723734132],
              [-80.692830602367465, 34.368550301835015],
              [-80.69250612702524, 34.368507270258895],
              [-80.69266429073619, 34.367712741818181],
              [-80.69233981986936, 34.367669709779676],
              [-80.692392540707189, 34.367404866487547],
              [-80.692068071195962, 34.367361833713822],
              [-80.692120792710924, 34.367096990549697],
              [-80.691796325642301, 34.36705395702554],
              [-80.691849047834367, 34.366789113989569],
              [-80.691524581015827, 34.366746078844187],
              [-80.691682750798108, 34.365951550943166],
              [-80.692007214549079, 34.365994584754944],
              [-80.692059935384151, 34.365729741655109],
              [-80.691735472630995, 34.365686707086027],
              [-80.692051799839447, 34.364097647167554],
              [-80.6917273446103, 34.364054612568438],
              [-80.691832786307089, 34.363524924887955],
              [-80.691508333468221, 34.363481890599012],
              [-80.69161377582212, 34.362952202241715],
              [-80.691938226646954, 34.362995237143721],
              [-80.691990945764601, 34.362730393255475],
              [-80.692315395912402, 34.362773427134307],
              [-80.69242083216605, 34.362243738105228],
              [-80.692745280620571, 34.362286770816645],
              [-80.692956143886875, 34.361227391105857],
              [-80.692631699497312, 34.361184358970661],
              [-80.692737130593684, 34.360654669766667],
              [-80.692412689662632, 34.36061163702508],
              [-80.692570836765583, 34.359817101279056],
              [-80.692895275734841, 34.359860133573001],
              [-80.693053416718101, 34.359065596350867],
              [-80.693377851891199, 34.359108627348832],
              [-80.693430563870109, 34.358843782033382],
              [-80.693754999434532, 34.358886811091409],
              [-80.693807708971264, 34.358621965631386],
              [-80.694456578016826, 34.358708021738536],
              [-80.694509286250394, 34.358443175057957],
              [-80.695158153551816, 34.358529228277263],
              [-80.695316269034052, 34.357734687291661],
              [-80.69564070014485, 34.35777771215038],
              [-80.695746106357689, 34.357248016896776],
              [-80.696070535775362, 34.357291040588301],
              [-80.696175938599467, 34.356761344983546],
              [-80.696500366305358, 34.356804366606511],
              [-80.696605764654038, 34.356274670666167],
              [-80.696930190685293, 34.356317692023218],
              [-80.696982888325707, 34.356052843434696],
              [-80.697307313679801, 34.356095863768672],
              [-80.697360009965053, 34.355831015020293],
              [-80.697684434642056, 34.355874034331045],
              [-80.697737129572118, 34.355609185422999],
              [-80.698061553553543, 34.355652202809296],
              [-80.69816694036416, 34.355122504657984],
              [-80.698491361584132, 34.355165521793822],
              [-80.698649436172019, 34.354370972214184],
              [-80.698325018018949, 34.354327956411701],
              [-80.698377709880091, 34.354063106364066],
              [-80.69805329197672, 34.354020088940622],
              [-80.698105985620344, 34.35375523990723],
              [-80.697781569072163, 34.353712221748737],
              [-80.697834262287358, 34.35344737195777],
              [-80.69750984818107, 34.353404353048823],
              [-80.697562542073413, 34.353139503386231],
              [-80.697238129340718, 34.353096484643586],
              [-80.69729082497831, 34.352831634192619],
              [-80.696966413600677, 34.352788614714925],
              [-80.697071803735952, 34.35225891498623],
              [-80.696747394710869, 34.35221589401629],
              [-80.696800090295241, 34.351951044723236],
              [-80.696475683711995, 34.3519080230028],
              [-80.696528379954799, 34.351643172936726],
              [-80.696203973639825, 34.351600150496793],
              [-80.69625667166504, 34.351335301445133],
              [-80.695932266705114, 34.351292278270137],
              [-80.696090359565076, 34.350497728780262],
              [-80.695765959078926, 34.350454705143001],
              [-80.695924051935648, 34.349660155944768],
              [-80.695599653749696, 34.349617131876073],
              [-80.695652352412438, 34.349352281344608],
              [-80.695327956668223, 34.34930925652553],
              [-80.695433352853541, 34.348779556636096],
              [-80.695108959480351, 34.348736531226152],
              [-80.695214357409597, 34.348206830645822],
              [-80.696187532469466, 34.348335904275508],
              [-80.696240227334386, 34.348071053537396],
              [-80.696889010376992, 34.348157098241174],
              [-80.696941702871058, 34.347892247199482],
              [-80.697266093875626, 34.34793526763805],
              [-80.697529545076378, 34.346611010590024],
              [-80.698178317961819, 34.346697048291055],
              [-80.698231004269005, 34.346432195837963],
              [-80.69855539019494, 34.346475212775282],
              [-80.698608076252569, 34.346210361048634],
              [-80.698932460414852, 34.346253376978339],
              [-80.69898514509913, 34.34598852419078],
              [-80.699309528584337, 34.346031539097389],
              [-80.699362211932396, 34.345766687051615],
              [-80.699686594740641, 34.345809700935206],
              [-80.699739276715277, 34.345544847828549],
              [-80.700063658846616, 34.3455878606892],
              [-80.700169018660475, 34.345058154156675],
              [-80.70049339909896, 34.345101165850288],
              [-80.700651432668522, 34.344306604609713],
              [-80.700327055277668, 34.344263593348195],
              [-80.700432411683991, 34.343733885759633],
              [-80.70010803666375, 34.343690873907221],
              [-80.700318748367991, 34.342631458234834],
              [-80.699994377750002, 34.342588446079574],
              [-80.700152411256539, 34.341793883014084],
              [-80.699828045111531, 34.341750870396645],
              [-80.699986078596908, 34.340956306723186],
              [-80.699661714751585, 34.340913293674369],
              [-80.699714393636313, 34.340648438918983],
              [-80.699390031145555, 34.340605425135266],
              [-80.699495388843289, 34.340075715881966],
              [-80.699171029809548, 34.340032701491879],
              [-80.699223708622554, 34.339767846993972],
              [-80.698899350924876, 34.33972483096769],
              [-80.698952031501449, 34.339459976583001],
              [-80.698627675158392, 34.339416959821868],
              [-80.698680355325109, 34.339152105581306],
              [-80.699004710652432, 34.33919512219839],
              [-80.69916274712169, 34.338400557134186],
              [-80.699487099740708, 34.338443572440156],
              [-80.699539776501908, 34.338178716948775],
              [-80.699864128444077, 34.338221731231819],
              [-80.699916803850698, 34.337956875581],
              [-80.700241155115989, 34.337999888841068],
              [-80.700293829168061, 34.337735033030881],
              [-80.700618179737873, 34.337778044366765],
              [-80.700776196814061, 34.336983476412065],
              [-80.701100544694199, 34.337026487338292],
              [-80.701153215359568, 34.33676163117876],
              [-80.701477561457608, 34.336804640196092],
              [-80.701635568355286, 34.33601007029224],
              [-80.701959911744964, 34.336053077998614],
              [-80.702012579023872, 34.33578822148985],
              [-80.702336921736759, 34.335831228173369],
              [-80.702442253228043, 34.33530151392052],
              [-80.702766594248359, 34.335344519437164],
              [-80.702819258460863, 34.335079661693158],
              [-80.703143598822919, 34.33512266708815],
              [-80.703196260594538, 34.334857809200351],
              [-80.703844941371486, 34.334943816149007],
              [-80.703897600772933, 34.334678957957948],
              [-80.704221940101263, 34.334721959527023],
              [-80.704274598148302, 34.334457101176589],
              [-80.704598936799698, 34.334500101722838],
              [-80.704704250932863, 34.333970384672938],
              [-80.705028587891661, 34.334013384052291],
              [-80.705081242891353, 34.333748525368151],
              [-80.705405579173188, 34.33379152372467],
              [-80.705563539055547, 34.332996945346309],
              [-80.705887872629148, 34.333039942391984],
              [-80.705993175499017, 34.332510223409543],
              [-80.706317507380035, 34.332553219288293],
              [-80.706422806845197, 34.332023499055744],
              [-80.70707146647419, 34.332109487616314],
              [-80.707124113667547, 34.33184462718927],
              [-80.707448442449916, 34.331887620916454],
              [-80.707501088270334, 34.331622759428825],
              [-80.707825416375627, 34.331665752133198],
              [-80.707878060841793, 34.331400890486464],
              [-80.708526717104846, 34.331486872053809],
              [-80.70949970455419, 34.331615836453821],
              [-80.709394426507203, 34.332145560255029],
              [-80.709718758727632, 34.33218854691345],
              [-80.709666119659303, 34.332453408492306],
              [-80.709990453233956, 34.33249639441577],
              [-80.709937815929223, 34.332761256107958],
              [-80.710586486125607, 34.332847225606322],
              [-80.710639121399012, 34.332582363626301],
              [-80.711612125080634, 34.332711309964786],
              [-80.711559492854121, 34.332976172376654],
              [-80.713505518730543, 34.333234043042658],
              [-80.71355814377641, 34.332969179782729],
              [-80.719071932714328, 34.3336996326071],
              [-80.719019326003533, 34.333964497396401],
              [-80.71966801622321, 34.334050416760604],
              [-80.719615410118891, 34.334315281838137],
              [-80.719939757285943, 34.334358239887237],
              [-80.719887151877899, 34.334623105994723],
              [-80.720211500399131, 34.334666063308859],
              [-80.720106291648236, 34.335195793947712],
              [-80.720430641452865, 34.335238750686194],
              [-80.720378038128942, 34.3355036161188],
              [-80.720702390374313, 34.335546572106701],
              [-80.720597183656281, 34.336076303229632],
              [-80.720272829379383, 34.336033346954096],
              [-80.720220223953746, 34.336298212356674],
              [-80.720544579246436, 34.336341168776137],
              [-80.720439370479312, 34.336870898906341],
              [-80.721088087229973, 34.336956809668258],
              [-80.721035483817687, 34.33722167455533],
              [-80.721359844259823, 34.337264628753921],
              [-80.721307241525011, 34.337529493769608],
              [-80.721955964369371, 34.337615399833176],
              [-80.721903364414558, 34.337880265105731],
              [-80.722876453704345, 34.338009117146946],
              [-80.722823856458689, 34.33827398283568],
              [-80.723796951841337, 34.338402827397722],
              [-80.723744357305094, 34.338667693502678],
              [-80.724717459848208, 34.338796529668549],
              [-80.724664868021264, 34.339061396189713],
              [-80.726286718150916, 34.339276105660808],
              [-80.726339304917161, 34.339011239321955],
              [-80.726663674068931, 34.33905417826773],
              [-80.726716259462066, 34.338789310868435],
              [-80.727040629022767, 34.338832248775674],
              [-80.727145794589077, 34.338302513675082],
              [-80.728118898137851, 34.338431322177286],
              [-80.728171477346891, 34.338166453271405],
              [-80.729468949772865, 34.338338184754377],
              [-80.72894318283403, 34.340986866258696],
              [-80.730889466333508, 34.341244443431258],
              [-80.730942038464804, 34.340979575027845],
              [-80.733537094272663, 34.341322959374942],
              [-80.733431968101556, 34.341852698434984],
              [-80.733756353212044, 34.34189561725271],
              [-80.733651227701316, 34.342425355637602],
              [-80.73332683947217, 34.342382436548085],
              [-80.73306401251439, 34.343706781104061],
              [-80.733388405824002, 34.343749700912227],
              [-80.733283275578927, 34.344279439082349],
              [-80.733607671258895, 34.344322358298854],
              [-80.733555105549073, 34.344587227069603],
              [-80.733879503670011, 34.344630145535078],
              [-80.733826938638146, 34.344895014434286],
              [-80.734151338113278, 34.344937932164328],
              [-80.734098773759357, 34.345202801191803],
              [-80.73474757466991, 34.345288634317974],
              [-80.734695013096754, 34.345553503601764],
              [-80.735343817392234, 34.345639333498994],
              [-80.73529125749414, 34.345904202153456],
              [-80.735940065174518, 34.345990028821639],
              [-80.735992623040318, 34.345725159879748],
              [-80.736641430040862, 34.345810982744204],
              [-80.736693985536192, 34.345546113499452],
              [-80.737018389051869, 34.345589022560098],
              [-80.737070942106158, 34.345324153172115],
              [-80.737719747070855, 34.345409970187113],
              [-80.737772298822378, 34.345145099579248],
              [-80.73809670025237, 34.345188006632242],
              [-80.738044150603699, 34.345452877368103],
              [-80.73869295757784, 34.345538688207156],
              [-80.7386404096234, 34.345803559214886],
              [-80.738964814099987, 34.345846463918051],
              [-80.738912266804533, 34.34611133415283],
              [-80.739236673722075, 34.346154238104688],
              [-80.739184126018131, 34.346419108483559],
              [-80.739508534289925, 34.346462011700041],
              [-80.739455988350798, 34.346726882191305],
              [-80.740104806835262, 34.34681268538862],
              [-80.74005226259041, 34.347077556151895],
              [-80.740376673908827, 34.347120457018264],
              [-80.740324130342216, 34.347385327909706],
              [-80.740648542995871, 34.347428227139382],
              [-80.740596000107431, 34.347693098159063],
              [-80.740920414134322, 34.347735997554587],
              [-80.741244828480305, 34.347778895169597],
              [-80.74119228728631, 34.348043766461181],
              [-80.741841120130658, 34.348129560226631],
              [-80.741788580611967, 34.348394430888767],
              [-80.742112998023629, 34.348437327055002],
              [-80.742060459183264, 34.348702197845348],
              [-80.742384879035953, 34.348745093260256],
              [-80.74233234087383, 34.349009964178805],
              [-80.742656760975024, 34.349052857972666],
              [-80.742604223491057, 34.349317729019418],
              [-80.742928646052377, 34.349360622963275],
              [-80.742876109246779, 34.349625494138223],
              [-80.743524956291154, 34.349711277888822],
              [-80.743472422266791, 34.349976149319758],
              [-80.743796846768916, 34.350019040027874],
              [-80.743534173379516, 34.351343396814855],
              [-80.743209742709084, 34.351300505404417],
              [-80.743104668054514, 34.351830246294135],
              [-80.743429100757751, 34.351873137991824],
              [-80.743376565045679, 34.352138009442513],
              [-80.743700999103311, 34.352180900404605],
              [-80.74364846296362, 34.352445771097948],
              [-80.744946207707457, 34.352617325811501],
              [-80.744998739781536, 34.352352454543684],
              [-80.745972049125299, 34.352481111588311],
              [-80.746024576725461, 34.352216239890026],
              [-80.746673449621554, 34.352302005663212],
              [-80.746725974851074, 34.352037133662229],
              [-80.747050410255753, 34.352080015545042],
              [-80.74699788604272, 34.352344887689611],
              [-80.747322323888625, 34.35238776882094],
              [-80.747269800353962, 34.352652641093592],
              [-80.747594238467443, 34.352695521505161],
              [-80.747541715611305, 34.352960393905903],
              [-80.747866156146785, 34.353003272664623],
              [-80.747813633969045, 34.353268145193461],
              [-80.748138074791328, 34.353311024133724],
              [-80.748085553272915, 34.353575895889286],
              [-80.748409996536282, 34.353618774078058],
              [-80.748357475696366, 34.353883645961652],
              [-80.748681920314183, 34.353926523414756],
              [-80.74862940015278, 34.354191395426412],
              [-80.748953846124905, 34.354234272143842],
              [-80.748848806840527, 34.354764017309115],
              [-80.749173255164266, 34.354806892533112],
              [-80.749068216541858, 34.355336637021921],
              [-80.74939266725579, 34.355379512555146],
              [-80.749340148454138, 34.355644384919785],
              [-80.749664601590069, 34.355687258800081],
              [-80.749612083467056, 34.355952131292739],
              [-80.749936536870507, 34.35599500445322],
              [-80.749884019426162, 34.356259877073924],
              [-80.750532930366759, 34.356345621028204],
              [-80.75048041570426, 34.356610493904583],
              [-80.75307608111504, 34.356953435699076],
              [-80.753128587664435, 34.35668856257567],
              [-80.755075344794349, 34.356945730228801],
              [-80.755022844344253, 34.3572106042133],
              [-80.755996231072643, 34.35733917704259],
              [-80.756048728453791, 34.357074301726279],
              [-80.756373190717909, 34.357117157428647],
              [-80.75642568565786, 34.356852281969203],
              [-80.756750147243181, 34.356895136648689],
              [-80.756802641915598, 34.356630261014217],
              [-80.757127101735307, 34.356673114686913],
              [-80.757179595053472, 34.35640823889333],
              [-80.75723208696617, 34.356143364001575],
              [-80.757556546158114, 34.356186215590682],
              [-80.757609036697261, 34.355921339638506],
              [-80.757933494142819, 34.355964191122062],
              [-80.75798598441456, 34.355699314994922],
              [-80.757661526898744, 34.355656463670719],
              [-80.757714017849196, 34.355391587671512],
              [-80.758038473261649, 34.355434438868151],
              [-80.758143449923452, 34.354904685666611],
              [-80.758467904727368, 34.354947535681035],
              [-80.758520391544721, 34.354682659364251],
              [-80.758844844563683, 34.354725507470512],
              [-80.758897330026855, 34.354460630994708],
              [-80.75954623615111, 34.354546325167746],
              [-80.759598718137354, 34.354281447504114],
              [-80.760247623579346, 34.354367137873069],
              [-80.760300104300896, 34.354102260792345],
              [-80.760949007973764, 34.354187947373205],
              [-80.761001486305389, 34.353923069088722],
              [-80.761650389295781, 34.354008751865635],
              [-80.761702865256566, 34.353743873278631],
              [-80.762676219215422, 34.353872389968366],
              [-80.762728690702247, 34.353607510951534],
              [-80.763377594089803, 34.353693184345829],
              [-80.763430063205831, 34.353428305026682],
              [-80.765052320840866, 34.353642471079631],
              [-80.765104784536661, 34.353377591027829],
              [-80.765753687478167, 34.353463250653085],
              [-80.765806148803307, 34.35319837029899],
              [-80.766455051061953, 34.353284026120171],
              [-80.76650751001641, 34.353019145463762],
              [-80.766831960635557, 34.353061971911991],
              [-80.766884417149072, 34.352797091112755],
              [-80.767533317366826, 34.352882741084613],
              [-80.767585772596505, 34.352617859967047],
              [-80.768234672111888, 34.352703505233691],
              [-80.768287123884164, 34.352438623829919],
              [-80.768611573141257, 34.352481445451524],
              [-80.768664024626986, 34.35221656297152],
              [-80.768988473204914, 34.352259383570534],
              [-80.769040922269198, 34.35199450184902],
              [-80.76936537016816, 34.352037321425335],
              [-80.769417817858979, 34.351772438643756],
              [-80.770715608743913, 34.351943706681226],
              [-80.770768052050457, 34.351678824212044],
              [-80.771092498497467, 34.351721638219843],
              [-80.77119738204587, 34.351191872047117],
              [-80.771521827883973, 34.351234684872871],
              [-80.771574267039, 34.350969801185386],
              [-80.771898712217393, 34.351012613889843],
              [-80.771951151105299, 34.350747730027571],
              [-80.772275595604469, 34.350790541709408],
              [-80.772328032032277, 34.350525656803029],
              [-80.772976920009924, 34.350611277242123],
              [-80.773029354067177, 34.350346392033657],
              [-80.773353797545539, 34.350389200790936],
              [-80.773668389830902, 34.348799890144171],
              [-80.77334395355777, 34.348757083132199],
              [-80.773448817698551, 34.348227312468929],
              [-80.773773251938707, 34.34827011919409],
              [-80.773878111610401, 34.347740348198812],
              [-80.774202544174258, 34.347783154659368],
              [-80.774254972468242, 34.34751826809407],
              [-80.774579405439766, 34.347561073515863],
              [-80.774631832399265, 34.347296187693239],
              [-80.775280695128501, 34.34738179474342],
              [-80.775333119698402, 34.347116907717378],
              [-80.775981981763266, 34.347202511865262],
              [-80.77603440396291, 34.346937624537325],
              [-80.776358834484853, 34.346980425149084],
              [-80.776411254243968, 34.34671553767857],
              [-80.776735684067276, 34.346758336366399],
              [-80.776892940464492, 34.34596367430234],
              [-80.776568513671094, 34.345920875143165],
              [-80.776830605589296, 34.344596436382943],
              [-80.77715502728104, 34.344639233924113],
              [-80.777207442988427, 34.344374346172877],
              [-80.777531862914344, 34.344417142707634],
              [-80.77768910709807, 34.343622477097789],
              [-80.778013524331428, 34.343665273224623],
              [-80.778065936637518, 34.343400384224566],
              [-80.778118348587029, 34.343135494307916],
              [-80.778442765211366, 34.343178289252847],
              [-80.778495175826734, 34.34291340007897],
              [-80.778819590665847, 34.342956193116194],
              [-80.779144005861554, 34.342998986175552],
              [-80.779196414106934, 34.3427340966999],
              [-80.779520829690696, 34.342776887819191],
              [-80.779573236582408, 34.342511998185017],
              [-80.779897650419812, 34.342554789199369],
              [-80.780002461139517, 34.342025008697433],
              [-80.781300111671158, 34.342196160112486],
              [-80.781352512469439, 34.34193126971617],
              [-80.782001337735068, 34.342016840312645],
              [-80.782053735057303, 34.341751948729417],
              [-80.782702560725298, 34.341837515506363],
              [-80.783121713059373, 34.339718379069105],
              [-80.783278889862615, 34.338923701186772],
              [-80.783603290382587, 34.338966481253962],
              [-80.783708071549299, 34.338436695030751],
              [-80.784032470393214, 34.338479474833498],
              [-80.784189634916928, 34.337684794078633],
              [-80.784514031048971, 34.337727572572383],
              [-80.784566417520097, 34.337462678213129],
              [-80.784890812972861, 34.33750545568455],
              [-80.784943198090744, 34.337240561166929],
              [-80.78526759286423, 34.337283337615936],
              [-80.785477124833122, 34.336223759739333],
              [-80.785801515858921, 34.336266533834745],
              [-80.786063417316882, 34.3349420593199],
              [-80.78638780359833, 34.334984831819924],
              [-80.786492559553295, 34.334455040724293],
              [-80.786816944139204, 34.334497812058594],
              [-80.786869320061555, 34.334232916803806],
              [-80.787193703968086, 34.334275687115813],
              [-80.787246078537251, 34.334010791702788],
              [-80.78757046176456, 34.334053560992416],
              [-80.787622834960899, 34.333788664519744],
              [-80.788271599326919, 34.333874201092776],
              [-80.788219228163086, 34.334139097851875],
              [-80.788867995889731, 34.334224630293527],
              [-80.788815626421382, 34.334489527324216],
              [-80.789140011263072, 34.334532292377695],
              [-80.789087642454589, 34.334797188635292],
              [-80.789412029755582, 34.334839953837978],
              [-80.789359661626364, 34.335104850223821],
              [-80.789684050260945, 34.335147613789367],
              [-80.789631682811191, 34.335412510303357],
              [-80.789956072818555, 34.335455274034352],
              [-80.789903706048094, 34.335720170676552],
              [-80.790552489066954, 34.335805693984923],
              [-80.790500123992288, 34.336070590898565],
              [-80.790824517023367, 34.336113351377207],
              [-80.790772153714585, 34.336378248402873],
              [-80.791420942820025, 34.336463767009178],
              [-80.791368580100837, 34.336728663421106],
              [-80.791692977261775, 34.336771421532525],
              [-80.791640615241747, 34.337036318973979],
              [-80.791965012669323, 34.337079076365711],
              [-80.792017373673005, 34.336814178781061],
              [-80.792341770421118, 34.336856935150436],
              [-80.792289411520358, 34.337121832862032],
              [-80.793262607901838, 34.337250096207121],
              [-80.793314963753545, 34.336985198065939],
              [-80.793963761348365, 34.337070701974639],
              [-80.794016114850166, 34.336805804433297],
              [-80.794340513669923, 34.336848554466904],
              [-80.794392865818651, 34.336583656767402],
              [-80.795041660282394, 34.3366691548446],
              [-80.795094010041879, 34.336404255942398],
              [-80.796391601118614, 34.336575240941684],
              [-80.796339255424499, 34.336840140416591],
              [-80.796663654498886, 34.336882884169157],
              [-80.796611310571024, 34.337147783755867],
              [-80.796935710998468, 34.337190526772382],
              [-80.797584513970051, 34.337276011052992],
              [-80.797532172754586, 34.337540911054184],
              [-80.797856575208783, 34.337583651576381],
              [-80.79780423465327, 34.33784855080436],
              [-80.798128638480222, 34.337891291491871],
              [-80.798076298604386, 34.338156190847954],
              [-80.79840070485146, 34.338198929881763],
              [-80.798348364588307, 34.338463830283722],
              [-80.798672772188553, 34.338506568581387],
              [-80.79862043367207, 34.338771468193791],
              [-80.798944842625474, 34.338814205755462],
              [-80.798997180125539, 34.338549305999962],
              [-80.799321588399252, 34.338592042539311],
              [-80.799373924546146, 34.338327142625666],
              [-80.800347148338304, 34.338455346539043],
              [-80.800399481099291, 34.338190446180938],
              [-80.800723888686989, 34.338233178917257],
              [-80.800776220075022, 34.337968277499598],
              [-80.801100626982986, 34.33801100921356],
              [-80.801152957037701, 34.337746108539157],
              [-80.801477362179355, 34.337788839247168],
              [-80.801529690861173, 34.337523937513311],
              [-80.801854096409613, 34.337566667182529],
              [-80.801958750730506, 34.33703686338351],
              [-80.802607557685107, 34.337122319528419],
              [-80.802555232725808, 34.337387221721727],
              [-80.802879638267783, 34.33742994861035],
              [-80.802774990439019, 34.337959752320337],
              [-80.803099398350525, 34.338002478615991],
              [-80.803047074394513, 34.338267380149077],
              [-80.803695893260354, 34.338352830388963],
              [-80.803643571000521, 34.338617732193235],
              [-80.804292394332393, 34.338703179186012],
              [-80.804240073768867, 34.338968081261505],
              [-80.804564486412914, 34.33901080359032],
              [-80.804512167615925, 34.339275705777503],
              [-80.804836581593236, 34.339318426468935],
              [-80.804784263476122, 34.339583328784066],
              [-80.805108678826386, 34.339626049640664],
              [-80.805056361389163, 34.339890952083856],
              [-80.805380778072788, 34.339933671302916],
              [-80.805328461315355, 34.340198573874197],
              [-80.80662613769465, 34.340369444300471],
              [-80.806678450385903, 34.340104541156954],
              [-80.80765170868834, 34.340232683856527],
              [-80.807704016906825, 34.339967780285185],
              [-80.808352855069259, 34.340053204375195],
              [-80.808405162004746, 34.339788300486198],
              [-80.808729581107741, 34.339831010610375],
              [-80.808781885603551, 34.339566106579781],
              [-80.809106304026471, 34.3396088156817],
              [-80.809210911062152, 34.339079007272773],
              [-80.809535326701948, 34.339121715225779],
              [-80.809483023845445, 34.339386620490309],
              [-80.809807441924775, 34.339429327690688],
              [-80.809755139728466, 34.339694232181891],
              [-80.81007955916084, 34.339736938646084],
              [-80.810027258731168, 34.34000184324892],
              [-80.811000519923979, 34.340129957827855],
              [-80.810948222207358, 34.340394862844683],
              [-80.811272645018917, 34.340437566077902],
              [-80.811597067080356, 34.340480268448317],
              [-80.811649363850449, 34.340215363128898],
              [-80.8119737852318, 34.340258064477041],
              [-80.812078373556176, 34.339728253540081],
              [-80.814024901037513, 34.339984440481047],
              [-80.813972613142909, 34.340249346815128],
              [-80.814297036949498, 34.340292041848862],
              [-80.814349323827443, 34.340027135371777],
              [-80.814998169310087, 34.340112521630815],
              [-80.815155019652892, 34.339317801284295],
              [-80.815479440402328, 34.339360493108344],
              [-80.815584003924812, 34.338830678276189],
              [-80.81590842189091, 34.338873368951425],
              [-80.815960702684293, 34.33860846181225],
              [-80.816285119950351, 34.338651150564004],
              [-80.816337398304157, 34.338386243283338],
              [-80.816661815976587, 34.338428930996336],
              [-80.816714092977463, 34.338164023557646],
              [-80.817362925145019, 34.338249396994257],
              [-80.817415199756667, 34.337984488353378],
              [-80.817739616424916, 34.338027174043994],
              [-80.817791889683591, 34.337762265245075],
              [-80.818440719802922, 34.337847632834333],
              [-80.818492990692278, 34.337582723734563],
              [-80.818817405249817, 34.337625406518036],
              [-80.818869674786228, 34.337360497260271],
              [-80.820491749648838, 34.337573894537009],
              [-80.820816165187608, 34.337616571000609],
              [-80.820763901750539, 34.337881481116121],
              [-80.821088319748611, 34.337924157728217],
              [-80.821036058058397, 34.338189067053762],
              [-80.821360476322752, 34.338231742946142],
              [-80.821308215312953, 34.338496652399719],
              [-80.82163263493014, 34.338539327555793],
              [-80.821580374600586, 34.338804237137253],
              [-80.821904796637256, 34.338846910639042],
              [-80.821852537008084, 34.33911182124988],
              [-80.822176959310923, 34.339154494031931],
              [-80.822124700342172, 34.339419403869286],
              [-80.822449125084546, 34.33946207589841],
              [-80.822396866816035, 34.339726986765093],
              [-80.822721292911282, 34.339769658057897],
              [-80.822669035303292, 34.340034568151125],
              [-80.822993462751299, 34.340077238707586],
              [-80.82294120473712, 34.340342148945282],
              [-80.823265633538099, 34.340384818765372],
              [-80.823213377290841, 34.340649729114396],
              [-80.823537808531299, 34.340692398181552],
              [-80.823433297041547, 34.341222218219059],
              [-80.823757729564946, 34.341264886709318],
              [-80.823705474342333, 34.341529797299195],
              [-80.824029908198767, 34.341572464151618],
              [-80.823925398758249, 34.342102284670673],
              [-80.824249836090772, 34.342144951814468],
              [-80.82419758187298, 34.34240986174364],
              [-80.824522020558348, 34.342452528151021],
              [-80.824469767021043, 34.34271743820802],
              [-80.825118646347391, 34.342802768687164],
              [-80.825066394507203, 34.343067679014929],
              [-80.825390836214751, 34.3431103421687],
              [-80.825338585055164, 34.343375252624391],
              [-80.825663028135551, 34.343417915943121],
              [-80.825610777656507, 34.343682826526525],
              [-80.82593522206993, 34.343725488207504],
              [-80.825882973358091, 34.34399039890215],
              [-80.827180757393947, 34.344161039223238],
              [-80.827233003125428, 34.343896127940468],
              [-80.827557449219768, 34.34393878522468],
              [-80.827505204505059, 34.344203696650347],
              [-80.828478548961911, 34.344331664540228],
              [-80.828426306961376, 34.344596576379409],
              [-80.83134636650783, 34.344980432062982],
              [-80.831294135475559, 34.345245344238187],
              [-80.83161858898994, 34.345287990519203],
              [-80.831566358638511, 34.345552902822156],
              [-80.831890813505709, 34.34559554836666],
              [-80.831838582748347, 34.345860460813967],
              [-80.832487496611535, 34.345945749533804],
              [-80.832435268638363, 34.346210662235045],
              [-80.833408645547607, 34.346338589139435],
              [-80.833356420268856, 34.346603501352774],
              [-80.833680880203147, 34.346646142049295],
              [-80.833628655625375, 34.346911055291656],
              [-80.833953117999286, 34.346953695235015],
              [-80.833900894082177, 34.347218607703653],
              [-80.834225356722328, 34.347261246927083],
              [-80.834173133486047, 34.347526159523404],
              [-80.834497598565832, 34.347568797993567],
              [-80.834445376010507, 34.347833710717552],
              [-80.834769842443151, 34.347876348451116],
              [-80.834717620568682, 34.348141261302857],
              [-80.835042088354371, 34.348183898299695],
              [-80.834989867160687, 34.348448811278999],
              [-80.835638805753945, 34.348534082018816],
              [-80.835963276631261, 34.348576715602256],
              [-80.836015494774074, 34.348311802194459],
              [-80.836339963903882, 34.348354435673741],
              [-80.836392180673727, 34.348089521206695],
              [-80.836716649122621, 34.348132153663691],
              [-80.836768864539451, 34.347867239038763],
              [-80.837093332307418, 34.347909870473529],
              [-80.837145545304836, 34.347644956608718],
              [-80.837470013458358, 34.347687586103262],
              [-80.837522225082751, 34.347422671179146],
              [-80.837846691488849, 34.34746530056951],
              [-80.837898902846945, 34.347200385470877],
              [-80.838547834613095, 34.347285640426321],
              [-80.83849562526872, 34.34755055490924],
              [-80.838820093769414, 34.34759318164452],
              [-80.838767885105923, 34.347858096255081],
              [-80.839092353852678, 34.34790072136915],
              [-80.839040146976998, 34.348165636991922],
              [-80.839689088598959, 34.34825088485055],
              [-80.83963688231448, 34.348515799859058],
              [-80.840610299774369, 34.348643665487735],
              [-80.840558097291549, 34.348908580892733],
              [-80.840882572191433, 34.348951201141602],
              [-80.840830370389668, 34.349216116674157],
              [-80.84115484555582, 34.349258736203097],
              [-80.84110264443504, 34.349523651863308],
              [-80.841427122040812, 34.34956627063881],
              [-80.841374921601115, 34.349831186426535],
              [-80.841699400559818, 34.349873804465325],
              [-80.841647200781139, 34.35013871947929],
              [-80.841971680006012, 34.350181336798066],
              [-80.841919482015257, 34.350446252824206],
              [-80.842892926909585, 34.350574098997107],
              [-80.842840730527186, 34.350839014551667],
              [-80.843165214216441, 34.350881628621238],
              [-80.843113019601844, 34.351146544286614],
              [-80.843761990022116, 34.351231770072751],
              [-80.843709796018985, 34.351496686025108],
              [-80.844034282729837, 34.351539296840343],
              [-80.843982090494634, 34.351804212903481],
              [-80.844955556820665, 34.351932042286165],
              [-80.844903366214069, 34.352196958779018],
              [-80.846525822228585, 34.3524099896661],
              [-80.846473637438166, 34.352674905939203],
              [-80.846798130243116, 34.352717510168283],
              [-80.84674594613405, 34.352982426568801],
              [-80.847070441378619, 34.353025030044286],
              [-80.847018257950864, 34.353289946572232],
              [-80.847342754528228, 34.35333254840954],
              [-80.847238387632885, 34.353862382623348],
              [-80.847562886580249, 34.353904983866514],
              [-80.847510704179314, 34.354169900634155],
              [-80.847835204479722, 34.354212501140424],
              [-80.847730840724765, 34.354742335816702],
              [-80.84805534339533, 34.354784935728773],
              [-80.848003162001035, 34.355049851834792],
              [-80.848327666024488, 34.355092451009924],
              [-80.848275485331783, 34.355357368144702],
              [-80.848924497507454, 34.355442564124893],
              [-80.848872318513244, 34.355707481529727],
              [-80.849196825578588, 34.355750078351576],
              [-80.849144647245524, 34.356014994982409],
              [-80.849469156750672, 34.356057591050558],
              [-80.849416979099104, 34.356322507808798],
              [-80.849741488870364, 34.356365103156811],
              [-80.849324066967029, 34.358484437763195],
              [-80.849648585212023, 34.358527033372852],
              [-80.849596407639311, 34.358791949360636],
              [-80.851219014381712, 34.359004913041431],
              [-80.85127118686809, 34.35873999634051],
              [-80.85159570819448, 34.358782585754405],
              [-80.851647880414561, 34.358517668878655],
              [-80.851972401059442, 34.358560257270312],
              [-80.852024570839632, 34.358295340253477],
              [-80.852673611122356, 34.358380515014055],
              [-80.852725778512024, 34.358115596795223],
              [-80.854023859037042, 34.358285935190537],
              [-80.853971695696245, 34.35855085307842],
              [-80.854296217694269, 34.358593436071502],
              [-80.854244055035153, 34.358858354086749],
              [-80.854568578365814, 34.358900935441383],
              [-80.854516416408586, 34.359165854485255],
              [-80.854840941092363, 34.359208435102914],
              [-80.854788779796365, 34.359473353372721],
              [-80.855113306940325, 34.359515934137917],
              [-80.855061146325951, 34.359780852534925],
              [-80.855710202528172, 34.35986600992576],
              [-80.855658043612792, 34.360130928592632],
              [-80.856307103192478, 34.360216082750128],
              [-80.856254945976218, 34.360481001686829],
              [-80.856579477830266, 34.360523577580267],
              [-80.856631632942396, 34.360258658517743],
              [-80.856956164114862, 34.360301233389002],
              [-80.857164779310793, 34.359241556381633],
              [-80.857489305662639, 34.359284129819706],
              [-80.857541457041435, 34.359019209494136],
              [-80.857865983798334, 34.359061781893118],
              [-80.857970283534669, 34.358531941812139],
              [-80.858294807505786, 34.358574513063203],
              [-80.858346955842904, 34.358309592406556],
              [-80.858671480219144, 34.358352162618516],
              [-80.858723627223767, 34.358087242705302],
              [-80.859048149831452, 34.358129811912015],
              [-80.859100295462696, 34.357864890939645],
              [-80.859424817388671, 34.357907459124057],
              [-80.859476961666985, 34.357642537993833],
              [-80.859801482911223, 34.35768510515615],
              [-80.859853625836735, 34.357420183868101],
              [-80.860178146378871, 34.357462749106908],
              [-80.860230286864578, 34.357197827677915],
              [-80.860879326961637, 34.357282957034123],
              [-80.860931466163962, 34.357018035287865],
              [-80.861255985698506, 34.35706059850402],
              [-80.861308123527678, 34.356795675698557],
              [-80.861957160481978, 34.356880799223816],
              [-80.862061429997922, 34.356350953915268],
              [-80.862385947487056, 34.356393514064955],
              [-80.86243808017089, 34.356128590803088],
              [-80.862762596978328, 34.35617114993061],
              [-80.862814729396121, 34.355906226494],
              [-80.863139244434876, 34.355948784616359],
              [-80.863243505142592, 34.355418937429363],
              [-80.863568018482241, 34.355461494386986],
              [-80.863620146772163, 34.355196570636572],
              [-80.863944660516893, 34.355239126555141],
              [-80.864101040301449, 34.35444435388392],
              [-80.864425551329788, 34.354486908495311],
              [-80.864529800800767, 34.353957059386282],
              [-80.864854309043253, 34.353999612849954],
              [-80.864906432248048, 34.353734687679534],
              [-80.865230939808797, 34.353777240121012],
              [-80.86528306166089, 34.35351231479288],
              [-80.86593207575396, 34.353597416752237],
              [-80.865984195236109, 34.353332491123901],
              [-80.866633209702655, 34.353417588361502],
              [-80.866685325728184, 34.353152662449943],
              [-80.867009831914046, 34.353195210066033],
              [-80.867061947673662, 34.352930283979823],
              [-80.867386453177758, 34.35297283057384],
              [-80.867438566497981, 34.35270790434695],
              [-80.86776307238641, 34.352750449000595],
              [-80.867867295986187, 34.352220596216377],
              [-80.868191799088819, 34.352263139722417],
              [-80.868243909368317, 34.351998213165146],
              [-80.86856841180952, 34.352040756550423],
              [-80.868672630394357, 34.351510902187179],
              [-80.868997131115961, 34.351553443506546],
              [-80.869049238355032, 34.351288516618958],
              [-80.869698237703489, 34.351373597252298],
              [-80.869750342552194, 34.351108669163303],
              [-80.87072384265791, 34.351236282613044],
              [-80.871192740462448, 34.348851924435664],
              [-80.871517230940256, 34.34889445982877],
              [-80.871673521087814, 34.348099671568882],
              [-80.871998008828839, 34.3481422047537],
              [-80.872102198174105, 34.347612344958414],
              [-80.872426684236444, 34.34765487788011],
              [-80.872530870206319, 34.34712501773982],
              [-80.872855354549245, 34.347167548595728],
              [-80.872907445470787, 34.346902618369171],
              [-80.873231929131691, 34.346945148203105],
              [-80.87333610791444, 34.346415286518614],
              [-80.873985072193733, 34.34650034387942],
              [-80.874037159037712, 34.346235412279164],
              [-80.874361640673357, 34.346277939948486],
              [-80.8759840537965, 34.346490561496871],
              [-80.876088213207012, 34.345960697460484],
              [-80.876412694798702, 34.346003218666475],
              [-80.876464773517199, 34.345738286024158],
              [-80.876789254426868, 34.345780806208197],
              [-80.876841330706327, 34.345515873425555],
              [-80.877814772415121, 34.345643429174601],
              [-80.877866845308006, 34.345378495949689],
              [-80.878191325523815, 34.345421012330803],
              [-80.878243397064537, 34.345156078948591],
              [-80.878892356467091, 34.345241108787434],
              [-80.878944425638338, 34.344976175105394],
              [-80.879593383260229, 34.345061201158373],
              [-80.879541316123209, 34.345326135125219],
              [-80.879865796997223, 34.345368646966293],
              [-80.879813730542395, 34.345633581060504],
              [-80.880462695309291, 34.34571860148754],
              [-80.880410630553669, 34.345983535851587],
              [-80.880735114467214, 34.34602604533886],
              [-80.880683051480489, 34.346290979813269],
              [-80.881007536746267, 34.346333488563388],
              [-80.880903409607981, 34.346863356884953],
              [-80.881227898309291, 34.346905864121808],
              [-80.881175835265097, 34.347170798853213],
              [-80.881824814641178, 34.347255811891515],
              [-80.881772753275797, 34.347520745991353],
              [-80.882421736047064, 34.347605756697568],
              [-80.882369676381131, 34.347870691067108],
              [-80.882694169809369, 34.347913194333373],
              [-80.882642110825643, 34.348178128830291],
              [-80.882966605626578, 34.34822063226067],
              [-80.882914548411904, 34.348485566867836],
              [-80.883239043457891, 34.348528068676778],
              [-80.883186986925367, 34.34879300341133],
              [-80.883511484430954, 34.348835505367276],
              [-80.8834073712882, 34.349365374191876],
              [-80.883731871142658, 34.349407874651646],
              [-80.883679814553062, 34.349672809642925],
              [-80.88400431575981, 34.349715309365457],
              [-80.884056371352827, 34.349450375133046],
              [-80.884380871877383, 34.349492873833633],
              [-80.88432881730175, 34.349757808208388],
              [-80.885302323957788, 34.349885300360825],
              [-80.885250273185704, 34.350150235130393],
              [-80.886548291899288, 34.350320212604444],
              [-80.886600338601994, 34.350055277265525],
              [-80.887249347380177, 34.350140259998149],
              [-80.887301391692688, 34.349875323458249],
              [-80.887625895566757, 34.34991781336273],
              [-80.887677938547682, 34.349652877566797],
              [-80.888651449127721, 34.349780341575936],
              [-80.888703488701054, 34.349515404436701],
              [-80.890975019053542, 34.349812787756953],
              [-80.890922987667722, 34.350077724973815],
              [-80.891572001083759, 34.350162684267239],
              [-80.891624030414235, 34.34989774586451],
              [-80.892597551063531, 34.35002517686214],
              [-80.892649577027811, 34.349760238918797],
              [-80.89329858970207, 34.349845187945569],
              [-80.893246566859247, 34.350110126156281],
              [-80.894869111653719, 34.350322483555942],
              [-80.895193621194721, 34.35036495294468],
              [-80.895245637912524, 34.350100012979212],
              [-80.895894657030041, 34.35018494791472],
              [-80.895946671378198, 34.349920007649651],
              [-80.896271180975418, 34.349962474097772],
              [-80.896323193971526, 34.34969753367556],
              [-80.896647701778633, 34.349739998217693],
              [-80.896699713422535, 34.349475057638152],
              [-80.897024221654448, 34.349517522042539],
              [-80.897076231946329, 34.349252581305855],
              [-80.8974007383881, 34.349295043804247],
              [-80.897556763116484, 34.348500221094824],
              [-80.897881266840855, 34.348542682286912],
              [-80.897933273759136, 34.348277741205877],
              [-80.897608771052134, 34.348235280156025],
              [-80.897712785898463, 34.347705397332135],
              [-80.897388285560879, 34.347662935687183],
              [-80.897440293509774, 34.347397994845693],
              [-80.897764791743342, 34.347440456365703],
              [-80.897816798319354, 34.347175514465789],
              [-80.898141296977727, 34.347217975848046],
              [-80.89819330220179, 34.346953033790953],
              [-80.898517799070049, 34.346995493267301],
              [-80.898569802942106, 34.346730551052978],
              [-80.898894300235057, 34.346773010391615],
              [-80.898998303853844, 34.346243125651071],
              [-80.899322798339455, 34.34628558294164],
              [-80.899374799172875, 34.34602064039813],
              [-80.89947879874903, 34.345490755283791],
              [-80.899154306207933, 34.345448297535853],
              [-80.899362305372307, 34.344388525894679],
              [-80.899037817255831, 34.34434606873738],
              [-80.899193816679386, 34.343551238916376],
              [-80.899297813173078, 34.343021352317805],
              [-80.899622295116714, 34.343063808781231],
              [-80.899726289324065, 34.342533921821541],
              [-80.900050769567713, 34.342576377120942],
              [-80.900102764608974, 34.342311433485314],
              [-80.90042724416989, 34.342353887762989],
              [-80.900479237838525, 34.342088943068966],
              [-80.900803716737698, 34.34213139722619],
              [-80.901115665619713, 34.340541727897758],
              [-80.90144013766357, 34.340584180339427],
              [-80.90149212693494, 34.340319234356087],
              [-80.901816598296122, 34.340361685776024],
              [-80.901868586215741, 34.340096739635698],
              [-80.903815418481329, 34.340351425174831],
              [-80.903763436644127, 34.340616371267089],
              [-80.904087911412773, 34.340658815611633],
              [-80.904139891146173, 34.340393869394568],
              [-80.904464365232002, 34.340436312717408],
              [-80.904620300439234, 34.339641472632429],
              [-80.904944770721414, 34.339683914666445],
              [-80.904996747061688, 34.339418967204658],
              [-80.904672277796706, 34.339376525312744],
              [-80.904776232594514, 34.338846630611968],
              [-80.905100699845974, 34.33888907312086],
              [-80.905308599165295, 34.337829282107037],
              [-80.905633062661664, 34.337871722266392],
              [-80.905737008290814, 34.337341826837537],
              [-80.90606147008728, 34.337384265832952],
              [-80.90611344136218, 34.337119317053002],
              [-80.906165412302556, 34.336854368258322],
              [-80.905840952540402, 34.336811929547181],
              [-80.906048837047351, 34.335752135691664],
              [-80.905724382753888, 34.335709695751078],
              [-80.905828325024572, 34.33517979856903],
              [-80.905503872033989, 34.335137358951933],
              [-80.905555843664004, 34.334872409579795],
              [-80.905880294571816, 34.33491484997333],
              [-80.905932264850293, 34.334649900444354],
              [-80.906256716140987, 34.334692338897625],
              [-80.906360652594898, 34.334162440429978],
              [-80.906685102185904, 34.334204877719394],
              [-80.906737068361366, 34.333939928780829],
              [-80.907061516183077, 34.333982365065971],
              [-80.907113482072702, 34.333717415051865],
              [-80.90743792921181, 34.33375985031536],
              [-80.907593820736693, 34.332964999776493],
              [-80.90791826515887, 34.333007433734032],
              [-80.907970226590379, 34.33274248339459],
              [-80.907645784271665, 34.332700049561872],
              [-80.90774970923124, 34.332170148204902],
              [-80.907425268194487, 34.332127713794321],
              [-80.907737041109385, 34.330538008423943],
              [-80.907944882976722, 34.32947820214909],
              [-80.908269312777747, 34.329520636056557],
              [-80.90832127083813, 34.329255683867444],
              [-80.908645699935619, 34.329298115852083],
              [-80.908697657751929, 34.329033164390147],
              [-80.909670944020192, 34.329160455542414],
              [-80.909722897344025, 34.328895502755586],
              [-80.910047325744742, 34.328937930938281],
              [-80.911669473869622, 34.329150059544517],
              [-80.911721420778193, 34.328885106791567],
              [-80.912045850167488, 34.328927529555664],
              [-80.912097795703787, 34.328662575744843],
              [-80.912422224410406, 34.328704997487392],
              [-80.91247416750906, 34.328440043537334],
              [-80.912798596619268, 34.32848246424097],
              [-80.912850538366797, 34.328217510134337],
              [-80.913174965707782, 34.328259929833798],
              [-80.913486605203701, 34.326670202199686],
              [-80.913811026776983, 34.326712620167143],
              [-80.914070711287948, 34.325387843038065],
              [-80.914395128110769, 34.325430259415477],
              [-80.914447062776262, 34.325165303807758],
              [-80.915095894304159, 34.325250133657569],
              [-80.915147826601554, 34.324985177751252],
              [-80.915472241306603, 34.325027591223588],
              [-80.915524172232082, 34.324762634259429],
              [-80.915848586254313, 34.324805046710388],
              [-80.915900515849714, 34.324540090491048],
              [-80.916224930275533, 34.324582501903144],
              [-80.91627685741247, 34.324317544643399],
              [-80.916601271155486, 34.324359955034005],
              [-80.916653196962443, 34.324094998519087],
              [-80.916977610022641, 34.324137407888173],
              [-80.917029534457626, 34.323872450315534],
              [-80.91735394574863, 34.323914858680567],
              [-80.91800277041925, 34.323999672755249],
              [-80.918054691469322, 34.323734714741931],
              [-80.919027927365832, 34.323861927939816],
              [-80.919079846117526, 34.323596969468376],
              [-80.919404257379085, 34.323639372273185],
              [-80.920053080904225, 34.323724175244465],
              [-80.920001166289751, 34.323989134124659],
              [-80.920325580635691, 34.324031533514578],
              [-80.920273666704034, 34.3242964925224],
              [-80.920598081335584, 34.324338892093671],
              [-80.92054616806584, 34.324603850327861],
              [-80.920870585134722, 34.324646249144529],
              [-80.920818672568927, 34.324911208407691],
              [-80.922440765772464, 34.32512318734085],
              [-80.922492673274348, 34.324858228268752],
              [-80.923141510646687, 34.324943013585958],
              [-80.923193414673321, 34.32467805333156],
              [-80.923517833376124, 34.324720444069648],
              [-80.923569736072892, 34.324455484560154],
              [-80.923894154092579, 34.324497874276737],
              [-80.92394605541736, 34.324232913709643],
              [-80.924270472775177, 34.324275303306088],
              [-80.924322372749046, 34.324010342582525],
              [-80.924646789402772, 34.324052730256334],
              [-80.924698688025757, 34.323787769376366],
              [-80.926320768001219, 34.323999695699541],
              [-80.926372661205434, 34.32373473409519],
              [-80.926697077828663, 34.323777116208589],
              [-80.926748969682023, 34.323512154447812],
              [-80.927722216331205, 34.323639295120714],
              [-80.927774104799781, 34.323374332919656],
              [-80.928098519638155, 34.323416711249024],
              [-80.928150406755904, 34.32315174889164],
              [-80.929123651309567, 34.323278878159897],
              [-80.929175535042546, 34.323013915362218],
              [-80.93014877952848, 34.323141035388645],
              [-80.930200658790355, 34.322876072168199],
              [-80.931498319441502, 34.323045552946091],
              [-80.931446444268801, 34.323310517635754],
              [-80.932095278620508, 34.323395252581484],
              [-80.932043405147951, 34.323660217540642],
              [-80.932367824374055, 34.323702583376971],
              [-80.932315951563595, 34.323967547562432],
              [-80.933938056762656, 34.324179366984048],
              [-80.934262479029329, 34.32422172858714],
              [-80.93431434465127, 34.323956763567566],
              [-80.934963188130723, 34.324041482950285],
              [-80.93501505138488, 34.323776517632467],
              [-80.935339472597533, 34.323818875412194],
              [-80.935391334500864, 34.323553909938205],
              [-80.936040175914371, 34.323638623476604],
              [-80.936092035450045, 34.323373657704451],
              [-80.936416455629697, 34.323416012562006],
              [-80.936468313793455, 34.323151045732324],
              [-80.936792732224575, 34.32319340048744],
              [-80.936844589037634, 34.322928433501531],
              [-80.937169007871759, 34.322970787217749],
              [-80.937272718462779, 34.322440852919293],
              [-80.936948301683898, 34.322398500388239],
              [-80.937103868119252, 34.321603599259042],
              [-80.937428280781972, 34.321645952283284],
              [-80.937480134576049, 34.321380985069801],
              [-80.937804547620615, 34.321423336153835],
              [-80.937856398977729, 34.321158368801797],
              [-80.938180811338839, 34.321200718864503],
              [-80.938505222968445, 34.321243068966716],
              [-80.938453372558897, 34.321508036620187],
              [-80.939102199896823, 34.321592732650046],
              [-80.939050352274009, 34.321857700555405],
              [-80.939374766938457, 34.321900048303242],
              [-80.93942661460936, 34.321635079337042],
              [-80.939751028590379, 34.321677426063587],
              [-80.939906564420227, 34.320882520491573],
              [-80.940230975662587, 34.320924865011825],
              [-80.940282819986365, 34.320659896606074],
              [-80.940607230545297, 34.320702240104964],
              [-80.940659072410952, 34.320437270659475],
              [-80.940983482307686, 34.320479614038405],
              [-80.941035323909119, 34.320214644419181],
              [-80.941359733101066, 34.320256985875524],
              [-80.94141157226565, 34.319992016117837],
              [-80.941735980795485, 34.320034357454205],
              [-80.941787818609541, 34.319769387540468],
              [-80.942112226434674, 34.319811726954264],
              [-80.942164062898229, 34.319546756884414],
              [-80.942812877514982, 34.319631432790047],
              [-80.942864711611122, 34.319366462422266],
              [-80.943513525528019, 34.319451134526709],
              [-80.943565357256517, 34.319186163860998],
              [-80.943889763698195, 34.319228498452254],
              [-80.943941592990029, 34.318963527648165],
              [-80.944265998748207, 34.319005861218201],
              [-80.94431782777589, 34.318740890240406],
              [-80.944642232850555, 34.318783222789214],
              [-80.944694059420215, 34.318518250771724],
              [-80.945018463811365, 34.318560582299305],
              [-80.945173939597495, 34.317765667521272],
              [-80.945498341271175, 34.317807997743856],
              [-80.94560198842791, 34.317278052996393],
              [-80.945926387314728, 34.31732038207366],
              [-80.945978208832884, 34.317055409545688],
              [-80.946302608122537, 34.317097737584092],
              [-80.94635442829032, 34.316832764900163],
              [-80.946678826896431, 34.316875091917346],
              [-80.946730645713856, 34.316610119077431],
              [-80.947055042550076, 34.316652445091115],
              [-80.947106860017158, 34.316387472095244],
              [-80.947431257234868, 34.316429796168762],
              [-80.947483073351634, 34.316164823016862],
              [-80.947158677129551, 34.316122498183923],
              [-80.947262310375507, 34.31559255122027],
              [-80.947417757733433, 34.314797630218308],
              [-80.947742147784552, 34.314839954359826],
              [-80.947793961879782, 34.314574980222076],
              [-80.948118351247459, 34.314617303342466],
              [-80.948170162906194, 34.314352329066516],
              [-80.948818941694014, 34.314436972367908],
              [-80.948870750985606, 34.314171997794226],
              [-80.949843916965918, 34.314298954851061],
              [-80.949895723959713, 34.314033979820202],
              [-80.95054450092681, 34.314118613778554],
              [-80.950596304445909, 34.313853637566496],
              [-80.950920692412538, 34.313895953084817],
              [-80.950972494602794, 34.313630977618189],
              [-80.951296881864451, 34.313673291214087],
              [-80.951348683790755, 34.313408315573824],
              [-80.951673070390143, 34.31345062904996],
              [-80.951724869858694, 34.313185652370279],
              [-80.952049254688234, 34.313227964843016],
              [-80.95237364091588, 34.313270275517382],
              [-80.952425438038645, 34.313005299441386],
              [-80.95274982358265, 34.313047609094625],
              [-80.95280161935537, 34.312782632862799],
              [-80.95312600421569, 34.312824941494938],
              [-80.953177798638279, 34.312559965107326],
              [-80.953502182814958, 34.312602272718337],
              [-80.953553974779837, 34.312337295291357],
              [-80.954527126301201, 34.312464214225614],
              [-80.95457891488212, 34.312199236359319],
              [-80.955227682188095, 34.312283844001669],
              [-80.955279468402125, 34.312018865837807],
              [-80.955603851527286, 34.312061167747459],
              [-80.955655636391256, 34.311796189427838],
              [-80.95598001883269, 34.311838490316482],
              [-80.956031802346601, 34.311573511841118],
              [-80.95635618412571, 34.311615812609986],
              [-80.956407966268074, 34.311350833077562],
              [-80.956732347363456, 34.311393132825351],
              [-80.956835908636577, 34.310863174336383],
              [-80.957160286923738, 34.310905472037803],
              [-80.957263844830578, 34.310375513209429],
              [-80.95758822250329, 34.310417809730275],
              [-80.957639999929455, 34.310152829702737],
              [-80.957964375853663, 34.31019512612162],
              [-80.958016151929755, 34.309930145938324],
              [-80.958340527148863, 34.309972440434912],
              [-80.95839230187498, 34.309707460095993],
              [-80.958716676431663, 34.309749754472676],
              [-80.958768449786518, 34.309484773076868],
              [-80.95941719893014, 34.309569357989844],
              [-80.959468968853457, 34.30930437721571],
              [-80.959793343440396, 34.309346667751917],
              [-80.959845112013767, 34.309081686822061],
              [-80.960169484830743, 34.30912397635511],
              [-80.960221253140389, 34.308858995251811],
              [-80.96054562527344, 34.308901283763838],
              [-80.96064915666615, 34.308371320366334],
              [-80.96097352818478, 34.308413607697801],
              [-80.961077056211678, 34.307883643961233],
              [-80.960752687812857, 34.307841356895388],
              [-80.960856216519858, 34.307311392485325],
              [-80.961180582885021, 34.30735367926777],
              [-80.961232346808316, 34.307088696882396],
              [-80.961556711403546, 34.307130982661604],
              [-80.961504949583201, 34.307395965170898],
              [-80.962153684000072, 34.307480535240607],
              [-80.96210192385891, 34.307745517118107],
              [-80.963075031021418, 34.307872365602407],
              [-80.963023274683863, 34.308137347873263],
              [-80.963672016476494, 34.308221908815476],
              [-80.963620260775187, 34.308486892274956],
              [-80.963944633187822, 34.308529171568715],
              [-80.96389287923499, 34.308794154236935],
              [-80.964217252997358, 34.308836432793129],
              [-80.964165498642373, 34.309101415606861],
              [-80.96481425028604, 34.309185970346817],
              [-80.964762498718031, 34.309450953412181],
              [-80.966384388173751, 34.309662324689803],
              [-80.966332641357326, 34.309927308449481],
              [-80.966657021920128, 34.309969580378294],
              [-80.966605274701436, 34.310234564283668],
              [-80.966929656592654, 34.310276834573415],
              [-80.966877911144152, 34.310541818588625],
              [-80.967526677980786, 34.310626357714689],
              [-80.967474933147088, 34.31089134201715],
              [-80.967799318070817, 34.310933609952151],
              [-80.967747575007422, 34.311198594364335],
              [-80.968071961281026, 34.311240861561657],
              [-80.9680202189017, 34.311505846101703],
              [-80.96834460652525, 34.311548112561269],
              [-80.968292864808362, 34.311813096327647],
              [-80.969266033834913, 34.311939891738433],
              [-80.969214294857736, 34.312204876817034],
              [-80.9695386858467, 34.312247140042494],
              [-80.969590423828521, 34.311982155723548],
              [-80.970887988131594, 34.31215119924677],
              [-80.970939720626376, 34.311886214365281],
              [-80.971264112331909, 34.311928472892639],
              [-80.971419305811295, 34.311133515919089],
              [-80.971743693712398, 34.31117577316013],
              [-80.971847152703148, 34.310645801785959],
              [-80.972171538903197, 34.310688057864382],
              [-80.972223266328498, 34.310423071573105],
              [-80.97254765184438, 34.310465326630592],
              [-80.972599377919906, 34.310200340183791],
              [-80.973248147916081, 34.310284847377417],
              [-80.973299871624874, 34.310019860633503],
              [-80.973948640918209, 34.310104364026529],
              [-80.974273026063941, 34.310146614403976],
              [-80.974324746389144, 34.309881627221365],
              [-80.974649129764472, 34.309923876595796],
              [-80.974700848739857, 34.30965888925769],
              [-80.975674001048532, 34.309785630724981],
              [-80.975725715554091, 34.309520642966191],
              [-80.97637448338233, 34.309605132297328],
              [-80.976426196607378, 34.30934014422354],
              [-80.977074962667857, 34.30942463067332],
              [-80.977126673504713, 34.309159641401202],
              [-80.977775438840439, 34.309244123149035],
              [-80.977827146245943, 34.308979134499189],
              [-80.978151528949837, 34.309021374354202],
              [-80.978203234984051, 34.308756384647687],
              [-80.978527617003536, 34.308798623481799],
              [-80.97857932168823, 34.30853363361971],
              [-80.978903703001734, 34.308575870531627],
              [-80.97900710936085, 34.308045891384253],
              [-80.97933148897306, 34.308088127133665],
              [-80.97943489085992, 34.307558146764471],
              [-80.980732402370293, 34.307727081674912],
              [-80.980680706225669, 34.307992073324925],
              [-80.981653846933924, 34.308118765239506],
              [-80.981602152399944, 34.308383756416909],
              [-80.982250917300334, 34.308468213867982],
              [-80.982199225532199, 34.308733204395296],
              [-80.982523608422952, 34.308775432411927],
              [-80.982471917339254, 34.309040423066904],
              [-80.982796302665747, 34.309082650327703],
              [-80.982744612266501, 34.309347641110321],
              [-80.983068998942571, 34.309389867633257],
              [-80.983120688303103, 34.309124875807832],
              [-80.983445074273007, 34.309167100408551],
              [-80.983496762305677, 34.308902109329026],
              [-80.983821147591129, 34.308944332908887],
              [-80.983872834252523, 34.308679340772734],
              [-80.984521603786987, 34.308763785011287],
              [-80.984573288081734, 34.308498792578263],
              [-80.98489767235256, 34.308541014138285],
              [-80.984949355297843, 34.308276021549915],
              [-80.985598122759285, 34.308360459946243],
              [-80.985649802251984, 34.308095467079085],
              [-80.987271721639232, 34.308306546973405],
              [-80.98722004614558, 34.308571540565993],
              [-80.987868818240131, 34.308655965749686],
              [-80.987817145534223, 34.30892095959333],
              [-80.988141533108106, 34.308963171458245],
              [-80.988089861086792, 34.309228165429481],
              [-80.988738639244232, 34.309312585902703],
              [-80.988686968924625, 34.309577580143035],
              [-80.989011359529954, 34.309619789652693],
              [-80.988959689873198, 34.30988478311933],
              [-80.989284081828032, 34.30992699189099],
              [-80.989180743573144, 34.310456979966887],
              [-80.989505137872811, 34.310499187240744],
              [-80.989453469274693, 34.310764181850011],
              [-80.989777866010073, 34.310806388367844],
              [-80.989726198074848, 34.31107138220338],
              [-80.990374993512731, 34.311155793803053],
              [-80.990323327279228, 34.311420787907586],
              [-80.990972126081004, 34.311505196272421],
              [-80.990920461549507, 34.311770190645952],
              [-80.991244862998371, 34.311812393190969],
              [-80.991193199151425, 34.312077387692085],
              [-80.992166408565609, 34.312203991376045],
              [-80.99211474743781, 34.312468986287541],
              [-80.99308796287319, 34.312595581579458],
              [-80.99313962097132, 34.312330587145077],
              [-80.994112836346517, 34.31245717409783],
              [-80.99416448997458, 34.312192179243354],
              [-80.994813299852026, 34.31227656586519],
              [-80.994864952177863, 34.312011569794549],
              [-80.995513761350296, 34.312095952615707],
              [-80.995565410223179, 34.311830956266597],
              [-80.996214218690625, 34.311915335287139],
              [-80.996265865218675, 34.311650339542702],
              [-80.996914671872929, 34.311734713879488],
              [-80.99696631603436, 34.311469717838406],
              [-80.99793952709652, 34.311596272747053],
              [-80.9978878860084, 34.311861270113639],
              [-80.99853669817972, 34.311945636520932],
              [-80.998485058771919, 34.312210633254935],
              [-80.999133873220359, 34.312294996445239],
              [-81.00010709844905, 34.312421533715337],
              [-81.000158731662736, 34.312156535391239],
              [-81.000483139364803, 34.312198712887422],
              [-81.000534772315262, 34.311933714390051],
              [-81.000859179332338, 34.311975890865419],
              [-81.000910809847198, 34.311710892230984],
              [-81.00188402979613, 34.311837414153743],
              [-81.001935656927174, 34.3115724150814],
              [-81.003557692826178, 34.311783266938896],
              [-81.003609313452785, 34.311518267164274],
              [-81.004258128759119, 34.311602601637745],
              [-81.00430974701915, 34.311337601566557],
              [-81.004634153599227, 34.311379766901275],
              [-81.004685771596101, 34.311114766656722],
              [-81.005010177491073, 34.311156930970654],
              [-81.005061793052448, 34.31089193058915],
              [-81.0053861982623, 34.31093409388231],
              [-81.005437813560434, 34.310669093327476],
              [-81.005762218085181, 34.310711255599777],
              [-81.005865443456756, 34.310181253301316],
              [-81.006189846279156, 34.310223414411546],
              [-81.006396288946476, 34.309163408182336],
              [-81.006071890192857, 34.309121247637286],
              [-81.006226722805948, 34.308326241648942],
              [-81.006551117421921, 34.308368401788336],
              [-81.006654334737945, 34.307838397156324],
              [-81.007303122005396, 34.307922715114685],
              [-81.007354728120063, 34.307657712044808],
              [-81.007679120702662, 34.307699870023278],
              [-81.007730725468136, 34.307434866798367],
              [-81.008379510637255, 34.307519178013628],
              [-81.008431111972257, 34.307254175411934],
              [-81.008755504580733, 34.30729632955002],
              [-81.008807104544616, 34.307031325891991],
              [-81.009131496467944, 34.307073479009354],
              [-81.00918309508269, 34.306808475196306],
              [-81.009507485234721, 34.306850627311228],
              [-81.009559083586467, 34.306585623324949],
              [-81.009883473053321, 34.306627774419155],
              [-81.009935068969838, 34.306362770296118],
              [-81.010259458837638, 34.306404920351383],
              [-81.010311053405061, 34.306139916073334],
              [-81.010635441501549, 34.30618206512618],
              [-81.010687034719879, 34.305917060693169],
              [-81.011011422131176, 34.305959208725334],
              [-81.011063014000456, 34.305694204137325],
              [-81.011387400726562, 34.305736351148809],
              [-81.011335809874339, 34.30600135587806],
              [-81.013606535520935, 34.306296360385474],
              [-81.013658118166916, 34.306031354685764],
              [-81.013982508634356, 34.306073494643577],
              [-81.014034089909273, 34.305808487887646],
              [-81.014682868719362, 34.305892764900776],
              [-81.014734447650142, 34.305627758749999],
              [-81.015058835970734, 34.305669894904007],
              [-81.015110414638599, 34.305404888580036],
              [-81.016083578583917, 34.305531293144526],
              [-81.016032004053628, 34.305796299873869],
              [-81.017005175123074, 34.305922696928775],
              [-81.016953603334542, 34.306187704969446],
              [-81.018251173075669, 34.306356221132511],
              [-81.018199605024108, 34.306621229724279],
              [-81.018848394451823, 34.306705482351688],
              [-81.018796829189014, 34.306970491193795],
              [-81.019121224874695, 34.307012616338632],
              [-81.019172790228495, 34.306747608238446],
              [-81.019821581646752, 34.306831855589195],
              [-81.01987314463446, 34.306566847192848],
              [-81.021819520770038, 34.306819568192324],
              [-81.021871076236465, 34.30655455895365],
              [-81.02349306199622, 34.306765134116908],
              [-81.023441511615971, 34.307030144061379],
              [-81.024739110045772, 34.307198588929779],
              [-81.024687563402793, 34.307463599425105],
              [-81.025011965128186, 34.307505708580067],
              [-81.02496041917091, 34.307770719202793],
              [-81.025284822223085, 34.307812826718177],
              [-81.025233278037561, 34.308077837449879],
              [-81.025557682460814, 34.308119945128119],
              [-81.025454594021127, 34.308649965949954],
              [-81.025779000810616, 34.308692073030954],
              [-81.025675913078956, 34.309222094080077],
              [-81.026000322212553, 34.309264199662415],
              [-81.025948778334566, 34.309529210767337],
              [-81.026273189903208, 34.309571315593075],
              [-81.026170104228797, 34.310101336222935],
              [-81.02779217511447, 34.310311851310239],
              [-81.027740636323486, 34.310576862319294],
              [-81.028065052933997, 34.31061896229113],
              [-81.030011558518737, 34.310871545472978],
              [-81.030063088015766, 34.310606533513138],
              [-81.030387505930008, 34.310648627187163],
              [-81.03043903516425, 34.310383615054036],
              [-81.031087868866464, 34.310467799499747],
              [-81.031139395734414, 34.31020278707075],
              [-81.031463812608521, 34.310244877824005],
              [-81.031515337041384, 34.309979865258711],
              [-81.031839753229647, 34.310021954991292],
              [-81.031891276313615, 34.3097569422712],
              [-81.03221569181602, 34.309799030983179],
              [-81.032267213551094, 34.309534018108288],
              [-81.032591628367655, 34.309576105799678],
              [-81.032643148731594, 34.309311091868643],
              [-81.032967562862282, 34.309353178539517],
              [-81.033070600583201, 34.308823151255574],
              [-81.033395013010633, 34.308865236764717],
              [-81.033446530367627, 34.308600223411808],
              [-81.033770941023036, 34.308642307918859],
              [-81.03392548798638, 34.307847264650768],
              [-81.034249897007342, 34.307889347836692],
              [-81.034301410981527, 34.307624333245649],
              [-81.034625818230452, 34.307666415429445],
              [-81.034677330855843, 34.307401400683609],
              [-81.035001737418895, 34.307443481847038],
              [-81.035053248695519, 34.307178466946368],
              [-81.035377654550373, 34.307220546187878],
              [-81.035429164478231, 34.306955531132729],
              [-81.035753569669467, 34.306997610254946],
              [-81.035805077162451, 34.306732595063409],
              [-81.037751510229029, 34.306985049561391],
              [-81.037700008840176, 34.307250065599],
              [-81.038348824171166, 34.307334209749527],
              [-81.038297324507852, 34.30759922695681],
              [-81.038946144285234, 34.307683367852775],
              [-81.038894645216871, 34.307948384445616],
              [-81.03986788095213, 34.308074589156277],
              [-81.039816385690713, 34.308339606139832],
              [-81.041438456608347, 34.308549930745002],
              [-81.041489945718979, 34.308284913976316],
              [-81.042138775221474, 34.308369036643747],
              [-81.042190261943659, 34.308104018678108],
              [-81.04251467617442, 34.308146078551509],
              [-81.04256616154791, 34.307881060431214],
              [-81.04289057511491, 34.307923120185428],
              [-81.042942059117351, 34.307658101009217],
              [-81.043266471998223, 34.307700159742936],
              [-81.043317954651997, 34.307435140412096],
              [-81.043966778264206, 34.30751925407624],
              [-81.044018258551944, 34.307254234449822],
              [-81.044667081454321, 34.307338344313884],
              [-81.044615603179025, 34.307603363320915],
              [-81.044940015590228, 34.307645416633221],
              [-81.04483706008071, 34.3081754548882],
              [-81.045161475966466, 34.308217508485598],
              [-81.04500704324802, 34.309012565287183],
              [-81.045331462494957, 34.309054617526634],
              [-81.045228507742991, 34.309584656208671],
              [-81.045552929333695, 34.309626706949089],
              [-81.045501452478248, 34.309891726410548],
              [-81.045825875440016, 34.309933777313624],
              [-81.045722922725815, 34.310463814674847],
              [-81.046047348053776, 34.310505864980222],
              [-81.045995872217318, 34.310770883781196],
              [-81.046320298893988, 34.310812933347897],
              [-81.046268823743802, 34.311077952276101],
              [-81.046917681211994, 34.311162049034024],
              [-81.046866207765518, 34.311427068230358],
              [-81.047190638534644, 34.311469114520278],
              [-81.047139165774581, 34.311734133843814],
              [-81.047463596828649, 34.311776180314943],
              [-81.047412125841078, 34.312041199747085],
              [-81.047736558221487, 34.312083244578247],
              [-81.047685087920257, 34.312348264137555],
              [-81.048009521649405, 34.312390308230036],
              [-81.047855109593883, 34.313185365482894],
              [-81.048179547815607, 34.313227410001168],
              [-81.048025135809567, 34.314022466651778],
              [-81.048349577392713, 34.314064509811864],
              [-81.04829810780339, 34.314329528796264],
              [-81.048622550757671, 34.314371572119022],
              [-81.048571080746214, 34.314636590347924],
              [-81.049219970747444, 34.314720673716231],
              [-81.049168502462337, 34.314985693114444],
              [-81.0494929495093, 34.315027733160093],
              [-81.049441481888209, 34.315292751784121],
              [-81.049765930306421, 34.315334791992299],
              [-81.049714463371771, 34.315599810743457],
              [-81.050038913116367, 34.315641849311568],
              [-81.049935981397368, 34.316171887937003],
              [-81.050260433508441, 34.316213925907235],
              [-81.050157502477688, 34.316743963857718],
              [-81.050481956955267, 34.316786001230071],
              [-81.050430491417671, 34.317051020334887],
              [-81.050754947244116, 34.317093056968453],
              [-81.050703483456729, 34.317358075280346],
              [-81.051027940654492, 34.317400112076363],
              [-81.050822085200508, 34.318460186668965],
              [-81.051146546777801, 34.318502222247531],
              [-81.051095083370043, 34.318767240772033],
              [-81.051419546296245, 34.318809275611756],
              [-81.051316620522641, 34.319339312900723],
              [-81.050992154474599, 34.319297277797865],
              [-81.050889225340796, 34.31982731474956],
              [-81.050564757588631, 34.319785278485163],
              [-81.050358890144381, 34.320845349874467],
              [-81.050683360881365, 34.320887386720919],
              [-81.050426023341913, 34.322212474109513],
              [-81.050750500585366, 34.322254510762114],
              [-81.050699033536176, 34.322519528331355],
              [-81.051023512106212, 34.322561563343768],
              [-81.05097204574362, 34.322826581040019],
              [-81.051296525685032, 34.322868616214762],
              [-81.051245058900378, 34.323133633155287],
              [-81.051569541277175, 34.323175667572542],
              [-81.051518075179047, 34.323440684639955],
              [-81.052167041853295, 34.323524750234192],
              [-81.052115578568447, 34.323789768452045],
              [-81.052764547516844, 34.323873830827573],
              [-81.052713085914064, 34.324138848411962],
              [-81.053362059309165, 34.324222907531464],
              [-81.053310599410949, 34.32448792538348],
              [-81.054284065573029, 34.324614007426995],
              [-81.054232608397285, 34.324879025687544],
              [-81.054557099152845, 34.324921051110017],
              [-81.054505642663841, 34.325186069497427],
              [-81.054830134768466, 34.325228094181014],
              [-81.055154627204217, 34.325270117984616],
              [-81.055103172419891, 34.325535136639722],
              [-81.055427666204707, 34.32557715970453],
              [-81.055376212107149, 34.325842178486447],
              [-81.055700707240945, 34.325884200812197],
              [-81.055649253807672, 34.326149218819666],
              [-81.055973750290619, 34.326191240406438],
              [-81.055922298653016, 34.32645625942331],
              [-81.056246796485055, 34.326498280271103],
              [-81.056195344425404, 34.326763298532242],
              [-81.056519843606409, 34.326805318640965],
              [-81.056468393319946, 34.32707033701022],
              [-81.057117394711469, 34.327154374869963],
              [-81.057065946129825, 34.327419393506737],
              [-81.057390448340229, 34.327461411257687],
              [-81.057339000445438, 34.327726430021237],
              [-81.05766350400495, 34.327768447033144],
              [-81.057612056774417, 34.328033465022187],
              [-81.057936562769427, 34.328075481276365],
              [-81.057885115161994, 34.328340500312137],
              [-81.058209622506112, 34.328382515827386],
              [-81.058106729397835, 34.328912553218359],
              [-81.058431239086474, 34.328954567233964],
              [-81.058379793608154, 34.329219586490467],
              [-81.059028814951063, 34.329303613083688],
              [-81.058977371155194, 34.329568631706415],
              [-81.059301883895799, 34.32961064426533],
              [-81.059250440786784, 34.329875663014832],
              [-81.059899469274811, 34.329959684873337],
              [-81.059848027870913, 34.330224703890266],
              [-81.060497059719395, 34.33030872251085],
              [-81.060445620020545, 34.330573741795227],
              [-81.060770137448188, 34.330615749475847],
              [-81.060718699522681, 34.330880768868255],
              [-81.061692256868781, 34.331006787954408],
              [-81.061640821666586, 34.331271807754945],
              [-81.061965342454641, 34.331313812197529],
              [-81.062016777747644, 34.331048793138805],
              [-81.062990339043694, 34.331174800765005],
              [-81.062938907913917, 34.331439821128562],
              [-81.063587953555739, 34.331523822075987],
              [-81.063536523044675, 34.331788842725743],
              [-81.064510097587558, 34.33191483796049],
              [-81.064458670863814, 34.33217985809825],
              [-81.064783196667193, 34.332221854903267],
              [-81.064731770630686, 34.332486875167653],
              [-81.065380827439725, 34.332570866381921],
              [-81.065329403108436, 34.332835886913657],
              [-81.065978462191268, 34.332919874908384],
              [-81.065927039565437, 34.333184895707411],
              [-81.066576103094945, 34.333268880445132],
              [-81.066524682174432, 34.333533901511558],
              [-81.066849215431247, 34.333575891799939],
              [-81.066797795197942, 34.333840912992862],
              [-81.067122329826546, 34.333882903443296],
              [-81.067070911344246, 34.334147923842743],
              [-81.067395447321999, 34.334189913553942],
              [-81.067344028440573, 34.334454934098702],
              [-81.067668565767491, 34.334496923070674],
              [-81.067617148659807, 34.334761943723215],
              [-81.067941687335818, 34.33480393195601],
              [-81.067838853077575, 34.335333973518757],
              [-81.068163394121086, 34.33537596115292],
              [-81.068111976970556, 34.335640982063119],
              [-81.068436520449751, 34.335682968939246],
              [-81.068385103963877, 34.335947989074661],
              [-81.070656922448393, 34.336241869985628],
              [-81.070605512782151, 34.336506891992961],
              [-81.071579158218782, 34.336632827903529],
              [-81.071527752340359, 34.336897849398589],
              [-81.072176851910257, 34.336981802872884],
              [-81.072125448823982, 34.337246824616237],
              [-81.072774552817833, 34.337330773931846],
              [-81.072723150373591, 34.337595796862345],
              [-81.073372257727556, 34.337679742939244],
              [-81.073320856966347, 34.337944765235626],
              [-81.07364541215793, 34.337986737094667],
              [-81.073594013170833, 34.33825175949864],
              [-81.074243126583269, 34.338335700857961],
              [-81.074191728215425, 34.338600723547849],
              [-81.074516287522783, 34.338642693029371],
              [-81.074413492895317, 34.339172737727587],
              [-81.074738053483742, 34.339214706629114],
              [-81.074635259569689, 34.339744751551628],
              [-81.074959823612247, 34.339786719835551],
              [-81.074908426622656, 34.340051741974925],
              [-81.074857030411565, 34.340316764982433],
              [-81.075181595712522, 34.340358731785109],
              [-81.075130200189022, 34.34062375491898],
              [-81.075454767925692, 34.340665720963386],
              [-81.075403371980499, 34.340930743341183],
              [-81.076052510528797, 34.34101467487374],
              [-81.076103903350571, 34.340749652233505],
              [-81.076753041162547, 34.340833579063343],
              [-81.076804431616651, 34.340568556127671],
              [-81.077778137748837, 34.340694438899412],
              [-81.077726750350052, 34.34095946225689],
              [-81.078375891469875, 34.341043380286123],
              [-81.07842727683186, 34.340778356647462],
              [-81.07875184685814, 34.340820313750712],
              [-81.078803230870818, 34.340555289957109],
              [-81.07945236881487, 34.340639202162386],
              [-81.079400987925652, 34.340904226218285],
              [-81.080374701996163, 34.341030087868553],
              [-81.080323322745031, 34.341295112350757],
              [-81.080972469134167, 34.341379015397614],
              [-81.080921092675823, 34.341644040127797],
              [-81.081570242424704, 34.341727939935424],
              [-81.081518867672642, 34.34199296493248],
              [-81.081843444061676, 34.34203491365674],
              [-81.081894818881821, 34.341769888500217],
              [-81.082219394583063, 34.34181183620381],
              [-81.082168021867901, 34.34207686148207],
              [-81.08314175509787, 34.342202699715124],
              [-81.083090385107639, 34.342467725400709],
              [-81.083414964168639, 34.342509669865045],
              [-81.083363594843362, 34.342774694775613],
              [-81.084661920618458, 34.342942463455181],
              [-81.084610556146146, 34.343207489795951],
              [-81.084935138897549, 34.343249430140879],
              [-81.084883774003728, 34.34351445572554],
              [-81.085208359168092, 34.343556394410669],
              [-81.085156994984914, 34.343821421022923],
              [-81.085481581498499, 34.343863358968498],
              [-81.08537885521848, 34.344393410609463],
              [-81.085703444122586, 34.344435348857317],
              [-81.085652080962006, 34.344700374806337],
              [-81.085976671192498, 34.344742311413206],
              [-81.085925309806385, 34.34500733746949],
              [-81.086574493319418, 34.345091209225153],
              [-81.086523132553367, 34.345356235567081],
              [-81.086847725812973, 34.345398169814644],
              [-81.086796366821417, 34.3456631962638],
              [-81.087120961430358, 34.345705129771673],
              [-81.08706960310397, 34.34597015544572],
              [-81.087394199084926, 34.346012089115213],
              [-81.087342841446542, 34.346277114915424],
              [-81.087667438753982, 34.346319046944075],
              [-81.087616081826511, 34.346584073771723],
              [-81.087940680483115, 34.346626005060635],
              [-81.08768389366935, 34.347951134316183],
              [-81.087359289919306, 34.347909202324757],
              [-81.087256570611046, 34.348439253108296],
              [-81.086931964067531, 34.348397319974637],
              [-81.086777879014477, 34.349192395179315],
              [-81.086453269722753, 34.349150459842683],
              [-81.086145084507919, 34.350740606315682],
              [-81.085820468370684, 34.350698670176001],
              [-81.085769101610879, 34.350963693652332],
              [-81.085444485872273, 34.350921756472971],
              [-81.085341749345901, 34.351451804002622],
              [-81.085017131900514, 34.351409865661928],
              [-81.084914391990978, 34.351939911951384],
              [-81.084589771752107, 34.351897972468471],
              [-81.084538400282497, 34.352162995450911],
              [-81.084213779332757, 34.352121054045938],
              [-81.08416240651367, 34.352386076873387],
              [-81.083837785985409, 34.352344135330036],
              [-81.083632285130221, 34.353404225069312],
              [-81.083307660857699, 34.353362282083594],
              [-81.082999393459588, 34.354952412259152],
              [-81.08294801491887, 34.355217432843219],
              [-81.082623381672036, 34.355175488031399],
              [-81.082572001804593, 34.355440509361635],
              [-81.082247368956359, 34.355398563510121],
              [-81.082144603995062, 34.355928604982466],
              [-81.081819969440062, 34.355886657969542],
              [-81.081768585433025, 34.356151678092672],
              [-81.081443950189879, 34.356109730059011],
              [-81.08139256483318, 34.356374750026973],
              [-81.081067928902044, 34.356332800972496],
              [-81.080965155158452, 34.356862840583567],
              [-81.080640516433689, 34.356820890386601],
              [-81.080589128046782, 34.357085910029774],
              [-81.080264489720776, 34.357043958793099],
              [-81.080161709916609, 34.357573997754358],
              [-81.079837068774282, 34.357532044473899],
              [-81.079631500010592, 34.358592120776407],
              [-81.079306855123548, 34.35855016605344],
              [-81.079152672806572, 34.359345221564176],
              [-81.078828025216509, 34.359303266440428],
              [-81.078622437881705, 34.360363338523364],
              [-81.078297786524161, 34.360321381055783],
              [-81.078143590012203, 34.361116433631388],
              [-81.077818934864837, 34.361074475782011],
              [-81.077613330044258, 34.362134543625189],
              [-81.077288671151948, 34.362092584333176],
              [-81.076928841125834, 34.363947698156096],
              [-81.077253507150999, 34.363989658432111],
              [-81.07720210222989, 34.364254674135751],
              [-81.077526769627696, 34.364296634573257],
              [-81.07747536648148, 34.364561650383784],
              [-81.078124704262692, 34.364645567096346],
              [-81.078073302823469, 34.36491058317322],
              [-81.078397973229542, 34.364952540349471],
              [-81.07834657139162, 34.365217556571018],
              [-81.078671244234556, 34.36525951298858],
              [-81.07861984308461, 34.365524529335893],
              [-81.078944517277563, 34.365566485013744],
              [-81.078893116815806, 34.365831501486809],
              [-81.079217792358747, 34.365873456424772],
              [-81.079166392562328, 34.366138472122358],
              [-81.079491069455202, 34.366180426320405],
              [-81.079439671456569, 34.366445443026159],
              [-81.079764348612656, 34.366487396503324],
              [-81.079712951279291, 34.366752412433549],
              [-81.080037630872297, 34.366794365151918],
              [-81.079986233140275, 34.367059381226731],
              [-81.080635595357279, 34.367143284303502],
              [-81.080532805150611, 34.367673316933008],
              [-81.080857488793654, 34.367715267431855],
              [-81.080806093126114, 34.367980283883739],
              [-81.081130778096409, 34.368022232741446],
              [-81.081079384203989, 34.368287249300202],
              [-81.081404071634125, 34.368329198300401],
              [-81.081352677342991, 34.368594215003668],
              [-81.081677366100408, 34.368636162362712],
              [-81.081625973584423, 34.368901179172809],
              [-81.081950662627676, 34.368943126712146],
              [-81.081899270777143, 34.369208142746558],
              [-81.082223961170556, 34.369250089545972],
              [-81.082172570008254, 34.369515105706135],
              [-81.082497262815778, 34.369557050845437],
              [-81.082445872364559, 34.369822068032477],
              [-81.082770565435311, 34.369864012450741],
              [-81.082719175649558, 34.370129028862202],
              [-81.083043871180067, 34.370170973422965],
              [-81.082992482082588, 34.370435989959965],
              [-81.083317178940476, 34.370477932879467],
              [-81.0832657905313, 34.370742949542105],
              [-81.083590488762098, 34.37078489262305],
              [-81.083539101018332, 34.37104990851001],
              [-81.083863800599246, 34.371091850850895],
              [-81.083761026180355, 34.371621883762543],
              [-81.084085728107794, 34.371663824602678],
              [-81.084034342519658, 34.37192884160838],
              [-81.084359045797214, 34.371970781708562],
              [-81.084307659810392, 34.372235798858711],
              [-81.084632364438079, 34.372277738218926],
              [-81.084580980203867, 34.372542754574482],
              [-81.084905686181685, 34.372584693194689],
              [-81.084854301548773, 34.372849709694691],
              [-81.08517900996371, 34.372891647555981],
              [-81.085076241743991, 34.373421680792241],
              [-81.085400952528275, 34.373463618054018],
              [-81.085298186110592, 34.373993651492469],
              [-81.084973473287718, 34.373951713949708],
              [-81.084716545767122, 34.375276794788626],
              [-81.085041263686747, 34.375318733033779],
              [-81.084989877760705, 34.375583748763979],
              [-81.085314597030475, 34.375625686269125],
              [-81.085263211792963, 34.375890702124721],
              [-81.085587932412878, 34.375932638889836],
              [-81.085536548950827, 34.376197654852071],
              [-81.085861270898221, 34.376239589975839],
              [-81.085809887037627, 34.37650460608247],
              [-81.086134611445146, 34.376546541348532],
              [-81.08608322827294, 34.376811557580588],
              [-81.086407952943688, 34.376853492125512],
              [-81.086356571524234, 34.377118507562784],
              [-81.086681297545155, 34.377160441367728],
              [-81.086578534688215, 34.377690473398367],
              [-81.086903264165912, 34.377732406584677],
              [-81.08680050200131, 34.378262437934893],
              [-81.086475771571841, 34.378220504448706],
              [-81.086116085870628, 34.380075611825248],
              [-81.086440824523166, 34.38011754627577],
              [-81.08638944006195, 34.380382560930997],
              [-81.086064700412805, 34.380340627241296],
              [-81.086013315688277, 34.380605641722099],
              [-81.086338056379745, 34.380647576453576],
              [-81.086235286889732, 34.381177605669627],
              [-81.086560029928194, 34.381219538900147],
              [-81.086457261153598, 34.381749568336765],
              [-81.086782006584727, 34.381791501868946],
              [-81.086730623264074, 34.382056516695556],
              [-81.087055370022853, 34.38209844858622],
              [-81.086952603317826, 34.382628477592711],
              [-81.087277353556502, 34.382670409766021],
              [-81.087174587544126, 34.383200438091571],
              [-81.087499339065559, 34.383242369684119],
              [-81.087447957126088, 34.383507383955205],
              [-81.087772711062314, 34.383549313887272],
              [-81.087721328724371, 34.383814328302584],
              [-81.088046084033834, 34.383856258395845],
              [-81.087994703471665, 34.38412127291749],
              [-81.088319460108806, 34.384163201369269],
              [-81.088268080235309, 34.38442821601614],
              [-81.088592838222837, 34.384470143727697],
              [-81.088541457950882, 34.384735158518865],
              [-81.088866217288867, 34.384777085490235],
              [-81.088814838792842, 34.385042100387693],
              [-81.0891395994813, 34.385084026618784],
              [-81.089036843512687, 34.385614055747645],
              [-81.08936160657133, 34.385655981379102],
              [-81.089207473184047, 34.386451024929038],
              [-81.089532239632348, 34.386492950101051],
              [-81.089378106303144, 34.387287993034086],
              [-81.089702876141345, 34.387329917746705],
              [-81.089651498699837, 34.387594931266449],
              [-81.08997627097574, 34.387636855219831],
              [-81.089873517185325, 34.388166884296986],
              [-81.090198291808477, 34.388208806749219],
              [-81.090044160567246, 34.389003848888187],
              [-81.090368939690421, 34.389045771763293],
              [-81.090266185536876, 34.389575799465973],
              [-81.090590967030465, 34.389617721741295],
              [-81.090539591020558, 34.389882735700589],
              [-81.090214808507341, 34.389840813284948],
              [-81.090112053455499, 34.390370840876862],
              [-81.089462486452874, 34.390286993724047],
              [-81.089411106368289, 34.390552006314948],
              [-81.089086322875758, 34.390510080816895],
              [-81.089034940376195, 34.390775094172419],
              [-81.088710156194864, 34.390733167653188],
              [-81.088607389227789, 34.391263193117119],
              [-81.08828260333803, 34.391221265436265],
              [-81.087717348335715, 34.39413639611535],
              [-81.087392550495068, 34.394094466929296],
              [-81.087238379874918, 34.394889499614493],
              [-81.086913579282992, 34.394847568225096],
              [-81.086862187032068, 34.395112578955299],
              [-81.086537385751242, 34.395070646544681],
              [-81.086485992149463, 34.39533565711897],
              [-81.086810794450031, 34.395377589670019],
              [-81.086708008270222, 34.395907610152001],
              [-81.087032814028845, 34.395949542084217],
              [-81.086981421473681, 34.396214552893191],
              [-81.087306228560209, 34.396256483183755],
              [-81.087254836693887, 34.396521494117771],
              [-81.08790445283536, 34.396605353257627],
              [-81.087955842661955, 34.396340342042777],
              [-81.088605458087798, 34.396424197378693],
              [-81.088554071388131, 34.396689208855356],
              [-81.089203690177669, 34.396773060948959],
              [-81.089255074837482, 34.396508049191375],
              [-81.089579884263927, 34.396549974217677],
              [-81.089631268660199, 34.396284962285364],
              [-81.091255313413924, 34.396494570837],
              [-81.091203935181355, 34.396759582551091],
              [-81.091853558313687, 34.396843420252026],
              [-81.091802181790072, 34.397108432231462],
              [-81.092126994860962, 34.39715034945052],
              [-81.092075619026332, 34.397415361554842],
              [-81.093050063307359, 34.397541109249474],
              [-81.092998690201583, 34.397806121759587],
              [-81.095272413255458, 34.398099500177238],
              [-81.095169681417687, 34.398629527097398],
              [-81.095494503295498, 34.398671435169717],
              [-81.095443137356682, 34.398936448756515],
              [-81.095767960562299, 34.398978355187083],
              [-81.095613865972297, 34.399773395336318],
              [-81.095938691481393, 34.399815301326115],
              [-81.095887327346446, 34.400080315112639],
              [-81.096212155293813, 34.400122220342894],
              [-81.096160791824971, 34.400387233353065],
              [-81.096485620058843, 34.400429138763151],
              [-81.096434257279242, 34.400694151898065],
              [-81.096759087951227, 34.400736056548674],
              [-81.096656362329242, 34.40126608217053],
              [-81.096981196459581, 34.401307986201829],
              [-81.096878471554263, 34.401838012042369],
              [-81.097203306968126, 34.401879915492472],
              [-81.09710058386689, 34.402409941532554],
              [-81.097425421628728, 34.402451843481174],
              [-81.09737406059044, 34.402716856167586],
              [-81.097698899726282, 34.402758758276981],
              [-81.097544817686455, 34.403553796664013],
              [-81.097869661277798, 34.403595697393101],
              [-81.097715578233306, 34.404390736080224],
              [-81.098040425215842, 34.40443263634937],
              [-81.097937704274088, 34.404962661429565],
              [-81.098262553627748, 34.405004561098444],
              [-81.098211193692279, 34.405269574206187],
              [-81.097886343295485, 34.405227673495681],
              [-81.097834982009005, 34.405492686447424],
              [-81.098159833402974, 34.405534586397003],
              [-81.097851663608537, 34.407124658331945],
              [-81.097526805006225, 34.407082757559458],
              [-81.097372713113913, 34.407877792434853],
              [-81.097047851781795, 34.407835890360495],
              [-81.096945119040726, 34.408365912069165],
              [-81.097269982413167, 34.408407814424194],
              [-81.09701315041454, 34.409732869113789],
              [-81.096688281940857, 34.409690966057141],
              [-81.096585544543984, 34.410220986645065],
              [-81.09691041505819, 34.410262889982455],
              [-81.096859047415933, 34.41052789993298],
              [-81.096534175881473, 34.41048599645535],
              [-81.096482805800548, 34.410751006268981],
              [-81.096157933576862, 34.410709101769939],
              [-81.096106563255177, 34.410974112309759],
              [-81.095781689254579, 34.410932206808496],
              [-81.095730317558733, 34.411197216290915],
              [-81.095405443956295, 34.41115530974929],
              [-81.095302695356224, 34.411685328424383],
              [-81.094977820021242, 34.411643419819811],
              [-81.094926444204475, 34.411908428993371],
              [-81.094601568203231, 34.41186652026871],
              [-81.094447435683165, 34.41266154637276],
              [-81.094122555864431, 34.412619636365008],
              [-81.094019797147922, 34.41314965310783],
              [-81.093694916707079, 34.413107741919411],
              [-81.093643535820647, 34.413372749676107],
              [-81.093318653602992, 34.413330837485312],
              [-81.093267271365136, 34.413595845085965],
              [-81.092942388458184, 34.413553931873743],
              [-81.092891004869003, 34.413818939318226],
              [-81.092241239072251, 34.413735109049831],
              [-81.092189852046701, 34.414000117118036],
              [-81.091540085533495, 34.41391628304482],
              [-81.091488696113444, 34.414181289915192],
              [-81.091163812888396, 34.414139371857836],
              [-81.091061030991426, 34.414669384369191],
              [-81.090736146056813, 34.414627465150076],
              [-81.090684753602787, 34.414892471692312],
              [-81.091009640645353, 34.41493439103283],
              [-81.090906856762004, 34.415464403449299],
              [-81.091231746176248, 34.415506322189529],
              [-81.091180355857276, 34.415771328946065],
              [-81.091505246623043, 34.415813246945618],
              [-81.091248291786513, 34.417138276742882],
              [-81.091573187985219, 34.417180194563251],
              [-81.091521797697496, 34.417445200604028],
              [-81.092821392059676, 34.417612863598258],
              [-81.092770004434939, 34.417877870203682],
              [-81.093094905417402, 34.417919783880393],
              [-81.09304351954674, 34.418184789690009],
              [-81.093368420792999, 34.418226702644979],
              [-81.093162877016766, 34.419286727206099],
              [-81.093487783763521, 34.419328639822389],
              [-81.093436397190857, 34.419593645852217],
              [-81.093761305289036, 34.419635557727737],
              [-81.093658535366629, 34.420165569982245],
              [-81.093983445836898, 34.420207481257364],
              [-81.093932061376563, 34.420472486599806],
              [-81.09425697319837, 34.420514397134156],
              [-81.094205589450496, 34.420779403502323],
              [-81.094530502600833, 34.42082131239458],
              [-81.094479119542399, 34.421086318887113],
              [-81.095453865174775, 34.421212042481805],
              [-81.095402484823978, 34.421477048478025],
              [-81.096377237584846, 34.421602764544396],
              [-81.096428613763607, 34.421337757244963],
              [-81.097728285010788, 34.421505365162538],
              [-81.097676911803589, 34.421770372141054],
              [-81.098326750910417, 34.421854171103085],
              [-81.098275380500951, 34.422119178327101],
              [-81.099575067833158, 34.422286765318383],
              [-81.099523700087346, 34.422551773106754],
              [-81.099848624034578, 34.422593667561863],
              [-81.099797258065919, 34.422858675455515],
              [-81.100447108994302, 34.422942462002823],
              [-81.100395744735991, 34.423207470161039],
              [-81.10104559902932, 34.423291253464249],
              [-81.10099423648127, 34.423556261887057],
              [-81.10261888419646, 34.423765705406858],
              [-81.102670240553877, 34.423500696301829],
              [-81.103320100567657, 34.423584467249505],
              [-81.103371454552828, 34.423319457848088],
              [-81.104996103619996, 34.423528868222846],
              [-81.105047453258805, 34.423263858085143],
              [-81.106347173487421, 34.423431368896409],
              [-81.106398518735929, 34.423166359083119],
              [-81.1067234485879, 34.423208233992113],
              [-81.106774791374335, 34.422943223140493],
              [-81.107424650052607, 34.423026970935766],
              [-81.107373309307562, 34.423291982067809],
              [-81.107698241262668, 34.42333385521539],
              [-81.107749579899433, 34.423068843962291],
              [-81.108724372466227, 34.423194455933029],
              [-81.108827043711145, 34.422664432518438],
              [-81.109476903363799, 34.422748169159604],
              [-81.109425569404195, 34.423013181164841],
              [-81.110725295650241, 34.423180643551362],
              [-81.110673966530186, 34.423445656082251],
              [-81.111973703215, 34.423613104009945],
              [-81.112025028252333, 34.423348090918338],
              [-81.112674896537555, 34.423431809314657],
              [-81.112726219226076, 34.42316679682817],
              [-81.113376085704942, 34.423250511438454],
              [-81.113427405998252, 34.422985497754347],
              [-81.116026881278728, 34.423320317946775],
              [-81.115975569151104, 34.423585332752076],
              [-81.116625444418105, 34.423669029699724],
              [-81.116676753416669, 34.423404014633377],
              [-81.117326626853782, 34.423487706893916],
              [-81.117275319896677, 34.423752722240415],
              [-81.118250136149271, 34.423878254883377],
              [-81.118198831923806, 34.424143270634403],
              [-81.118848713837252, 34.424226954623883],
              [-81.118797411299497, 34.424491969737808],
              [-81.119447296576681, 34.424575650482581],
              [-81.119395995773104, 34.424840666762066],
              [-81.120370829218629, 34.424966181239512],
              [-81.12042212587221, 34.424701164558925],
              [-81.121072014454853, 34.424784836491177],
              [-81.121020719819484, 34.425049852550671],
              [-81.121670611765666, 34.42513352123818],
              [-81.121619318841454, 34.425398537561804],
              [-81.122269214127982, 34.425482202103161],
              [-81.122217922914885, 34.425747218690979],
              [-81.12254287209791, 34.425789050681182],
              [-81.122491581575133, 34.426054067393082],
              [-81.122816532086205, 34.426095897740737],
              [-81.122765242253777, 34.426360914576833],
              [-81.122713952090848, 34.426625931396948],
              [-81.123038904974067, 34.426667761143449],
              [-81.122936325675155, 34.427197794114534],
              [-81.123261282018248, 34.427239623240588],
              [-81.123056122450222, 34.428299689570444],
              [-81.123381083207363, 34.428341518375483],
              [-81.123227212816943, 34.429136567027797],
              [-81.122902248997235, 34.429094737802586],
              [-81.122594493144049, 34.430684832932542],
              [-81.122919464153824, 34.430726662077561],
              [-81.12286817174224, 34.430991678160233],
              [-81.123518114642934, 34.431075335026243],
              [-81.123466823919358, 34.431340350471679],
              [-81.124116771295022, 34.431424004974438],
              [-81.124065482282958, 34.431689020683883],
              [-81.124390458008051, 34.431730845842381],
              [-81.124339169686465, 34.431995861675773],
              [-81.124664146786401, 34.432037686994271],
              [-81.12456157119361, 34.432567718892827],
              [-81.124886549554887, 34.432609542728038],
              [-81.124681398440941, 34.43366960508957],
              [-81.12435641490778, 34.433627780713465],
              [-81.124202545003925, 34.434422825543265],
              [-81.123877558738286, 34.434380999865674],
              [-81.123774974019895, 34.434911029410678],
              [-81.124099963415489, 34.434952855349053],
              [-81.123997379417062, 34.435482885109529],
              [-81.124322371185301, 34.435524710446629],
              [-81.124219787883618, 34.436054739521303],
              [-81.124544782047721, 34.436096565158373],
              [-81.124493491454459, 34.436361579351178],
              [-81.124818485882471, 34.436403404266166],
              [-81.124767195979672, 34.43666841858284],
              [-81.125092192823814, 34.436710241835883],
              [-81.124989614069037, 34.437240270700656],
              [-81.124664615182695, 34.43719844716756],
              [-81.124459448511118, 34.438258503260592],
              [-81.124784452593062, 34.438300328235812],
              [-81.124733160286553, 34.4385653416971],
              [-81.124408156294663, 34.438523517463764],
              [-81.124254276527537, 34.439318558192788],
              [-81.123929269802773, 34.439276732657895],
              [-81.123775383997113, 34.440071772820488],
              [-81.123450373428227, 34.440029945102118],
              [-81.123399076832243, 34.440294958010185],
              [-81.123074065596114, 34.440253130171591],
              [-81.123022767648379, 34.44051814292343],
              [-81.122697755721575, 34.440476314063332],
              [-81.122646456398812, 34.440741325757564],
              [-81.12199643258262, 34.44065766509361],
              [-81.12194512979903, 34.440922676510716],
              [-81.121620116821703, 34.440880844726188],
              [-81.121568812709569, 34.441145856888134],
              [-81.121243800106384, 34.441104023161543],
              [-81.121192494642344, 34.441369035167241],
              [-81.121089882699266, 34.441899058228351],
              [-81.121414899430633, 34.441940891454571],
              [-81.121363593972703, 34.442205902650109],
              [-81.121688612055991, 34.44224773513492],
              [-81.121637307311801, 34.442512747355494],
              [-81.121962326746953, 34.442554579098903],
              [-81.121911022670062, 34.442819590541902],
              [-81.122236043457065, 34.442861421543896],
              [-81.122184741158677, 34.443126433091344],
              [-81.122509763297543, 34.443168263351872],
              [-81.122458460601948, 34.443433275042331],
              [-81.122783484092608, 34.443475104561429],
              [-81.122680880816674, 34.444005127252723],
              [-81.123005906680447, 34.444046956170439],
              [-81.12269809393382, 34.445637023810058],
              [-81.123023127343913, 34.445678852667207],
              [-81.122766611925186, 34.447003905991259],
              [-81.122441573408508, 34.446962076433891],
              [-81.122287657542074, 34.44775710708246],
              [-81.121962616292052, 34.447715276223363],
              [-81.121757384940324, 34.448775315121907],
              [-81.121432338847683, 34.448733482840382],
              [-81.121124475701308, 34.450323537150048],
              [-81.12079942381105, 34.450281703146644],
              [-81.120748111119426, 34.450546712451185],
              [-81.120423058538407, 34.450504877426241],
              [-81.120371744471399, 34.450769885672877],
              [-81.11972163825881, 34.450686212698145],
              [-81.119670321818276, 34.450951220648122],
              [-81.119345268186734, 34.450909382698356],
              [-81.119293950394052, 34.45117439049185],
              [-81.119619003958988, 34.45121622860102],
              [-81.119516368312929, 34.451746243536455],
              [-81.119191311617058, 34.451704405166481],
              [-81.118986032269959, 34.45276443427877],
              [-81.119311093051493, 34.452806273208985],
              [-81.119208453436258, 34.453336287945952],
              [-81.119533517656365, 34.453378125354213],
              [-81.119379558238151, 34.454173146413282],
              [-81.120679828577309, 34.454340491653127],
              [-81.120628512921044, 34.454605499187046],
              [-81.12127865212986, 34.454689167247942],
              [-81.121227338162527, 34.454954174144071],
              [-81.121552409828638, 34.454996006982498],
              [-81.121449781880159, 34.455526021925621],
              [-81.121774855896717, 34.455567853261208],
              [-81.121620916178372, 34.456362875491806],
              [-81.121945993590415, 34.456404706365923],
              [-81.121432846477958, 34.459054774235327],
              [-81.121107758826938, 34.459012941059669],
              [-81.121005122928821, 34.459542953079612],
              [-81.121330213711289, 34.459584786516146],
              [-81.121227578510045, 34.460114797848178],
              [-81.12090248570766, 34.460072965032865],
              [-81.120851167144934, 34.460337970974784],
              [-81.120526073628469, 34.460296136236551],
              [-81.120423431267753, 34.460826146927438],
              [-81.120098336061929, 34.460784311928606],
              [-81.120047014440274, 34.461049316639034],
              [-81.119721918543718, 34.461007480618555],
              [-81.119773241163784, 34.460742475146873],
              [-81.118797958500693, 34.46061696131396],
              [-81.118746632792451, 34.460881965464203],
              [-81.118096443953533, 34.460798284586588],
              [-81.118147772793179, 34.460533280697213],
              [-81.117822679880078, 34.460491438625311],
              [-81.117668688238652, 34.461286450743486],
              [-81.117993785304805, 34.461328293216404],
              [-81.117942455472559, 34.461593297055558],
              [-81.118267552779969, 34.461635137904729],
              [-81.117856909249198, 34.463755167351621],
              [-81.118182016149007, 34.463797008420379],
              [-81.117925358913411, 34.465122024488821],
              [-81.118250470164554, 34.465163865395269],
              [-81.118096474880616, 34.465958873812575],
              [-81.118746706705679, 34.466042554683419],
              [-81.118695376310626, 34.466307557134783],
              [-81.119020494817846, 34.466349395917675],
              [-81.118917835101868, 34.466879401951246],
              [-81.119242954895341, 34.466921240151692],
              [-81.119140295876164, 34.467451245496626],
              [-81.119465419132325, 34.467493083076079],
              [-81.119362760833226, 34.468023088633615],
              [-81.119687885375683, 34.468064925630742],
              [-81.119533899575828, 34.4688599333399],
              [-81.119208770879538, 34.468818095941991],
              [-81.119157440240954, 34.469083098644766],
              [-81.118832310853819, 34.46904126022492],
              [-81.118729646493691, 34.469571263497059],
              [-81.118404515393777, 34.469529423915411],
              [-81.117993834341846, 34.471649434343604],
              [-81.117668695397853, 34.471607592759774],
              [-81.117617356918643, 34.47187259307276],
              [-81.117292217283719, 34.471830750467092],
              [-81.117189538400268, 34.472360751644139],
              [-81.116864395964214, 34.472318907895634],
              [-81.116710372600238, 34.473113907752165],
              [-81.116385227429305, 34.473072062701675],
              [-81.116282541125159, 34.47360206224733],
              [-81.115957394241363, 34.473560216034947],
              [-81.115906049003499, 34.473825214750526],
              [-81.115580901428885, 34.473783367516219],
              [-81.115529554861268, 34.474048366975914],
              [-81.115204407683947, 34.474006518700477],
              [-81.115153059740166, 34.474271517101883],
              [-81.115478209027657, 34.474313365498183],
              [-81.115426861797999, 34.4745783649239],
              [-81.116402314712559, 34.474703905241903],
              [-81.11635097019456, 34.474968904169494],
              [-81.117651583890819, 34.475136279454972],
              [-81.117600243129729, 34.475401278925752],
              [-81.117925399219487, 34.475443120668039],
              [-81.117874059149401, 34.475708120261793],
              [-81.118199215503893, 34.47574996128148],
              [-81.118147877213161, 34.476014960979001],
              [-81.118473034920541, 34.476056801256838],
              [-81.118421697320841, 34.476321801077319],
              [-81.119397177648381, 34.4764473160999],
              [-81.119345841695349, 34.476712316342663],
              [-81.119671003835833, 34.476754152452465],
              [-81.119722337678397, 34.476489152088924],
              [-81.121022984442703, 34.476656489872269],
              [-81.121074312777935, 34.476391488950853],
              [-81.121724636634056, 34.476475151810739],
              [-81.121775962594285, 34.476210150592259],
              [-81.12210112400831, 34.47625198101008],
              [-81.122152449703862, 34.475986979615286],
              [-81.122802770368978, 34.476070636643009],
              [-81.122854092601216, 34.475805634970456],
              [-81.123829573546828, 34.47593111301763],
              [-81.123778253292357, 34.476196115129433],
              [-81.124428578331205, 34.476279762417576],
              [-81.124377259789924, 34.476544764792415],
              [-81.124702423851019, 34.476586588154866],
              [-81.124753740281818, 34.476321585659427],
              [-81.125078903628321, 34.476363407098745],
              [-81.125130218706218, 34.476098404446276],
              [-81.125455381361562, 34.476140224863691],
              [-81.125506695086585, 34.475875222054285],
              [-81.128107996819679, 34.476209753484348],
              [-81.128056692359465, 34.476474757394172],
              [-81.128381856903644, 34.476516569893676],
              [-81.128330553134845, 34.476781573926381],
              [-81.128655720120122, 34.476823385664545],
              [-81.128604415931008, 34.47708838893822],
              [-81.12892958426913, 34.477130199934422],
              [-81.128878281883047, 34.477395204213032],
              [-81.129203451574043, 34.477437014467228],
              [-81.129100846742134, 34.477967022370912],
              [-81.129426018784798, 34.478008831121834],
              [-81.129374717450688, 34.478273835629039],
              [-81.129699890869603, 34.478315644539151],
              [-81.129751191181569, 34.478050639892025],
              [-81.130076363909168, 34.478092447780263],
              [-81.1301276639566, 34.477827442956865],
              [-81.130452835992713, 34.477869249823236],
              [-81.130555430897857, 34.477339240785234],
              [-81.13088060122044, 34.477381046489811],
              [-81.13098319382344, 34.476851036183213],
              [-81.131308362432478, 34.476892840726002],
              [-81.131359656671577, 34.476627835416885],
              [-81.132009992814233, 34.476711440675537],
              [-81.132061283590076, 34.476446435088974],
              [-81.132386451147056, 34.476488236706146],
              [-81.132335161393257, 34.476753242432686],
              [-81.132985499520004, 34.476836842399791],
              [-81.132934210391539, 34.477101848408402],
              [-81.133584551885235, 34.477185445127553],
              [-81.133533264470287, 34.47745045139893],
              [-81.134183610395866, 34.477534043949433],
              [-81.134234894701478, 34.477269038318951],
              [-81.134560067682202, 34.477310833121628],
              [-81.1345087833338, 34.477575839812694],
              [-81.135484305173051, 34.47770122028885],
              [-81.13543302462547, 34.477966226461895],
              [-81.135758199908096, 34.478008018937288],
              [-81.135706920052058, 34.478273025233101],
              [-81.136357274718577, 34.478356606896959],
              [-81.136305995487845, 34.478621613474928],
              [-81.136631174871738, 34.478663402663393],
              [-81.136579897421058, 34.478928409344775],
              [-81.136905078157639, 34.478970197791135],
              [-81.136853800310121, 34.479235204614703],
              [-81.137178982423052, 34.47927699322031],
              [-81.137025153205386, 34.480072013048634],
              [-81.136699968049285, 34.480030224924811],
              [-81.136648689967785, 34.480295231660676],
              [-81.136597411555584, 34.480560238379439],
              [-81.136922598756172, 34.480602026782989],
              [-81.136871321012265, 34.480867032723225],
              [-81.137196509565712, 34.480908820384627],
              [-81.137145232536881, 34.481173827348897],
              [-81.1374704224433, 34.481215614268152],
              [-81.137367869391397, 34.481745626622541],
              [-81.137693061672962, 34.481787412939475],
              [-81.137641785673324, 34.482052419230833],
              [-81.137966979307691, 34.48209420480562],
              [-81.137864428361397, 34.48262421761661],
              [-81.138189625459376, 34.482666002569658],
              [-81.138138350512634, 34.482931009089285],
              [-81.138463547875105, 34.482972793319639],
              [-81.138412274685052, 34.483237799041298],
              [-81.138737473400454, 34.483279582529384],
              [-81.138686199837153, 34.483544589294389],
              [-81.139011400970446, 34.483586371119564],
              [-81.138960128098887, 34.483851378007294],
              [-81.139285330585111, 34.483893159090265],
              [-81.139234058405322, 34.484158166100634],
              [-81.139559262244475, 34.484199946441464],
              [-81.13920035692189, 34.486054990565187],
              [-81.138875144814193, 34.486013208363737],
              [-81.138772594763907, 34.486543219388373],
              [-81.13844738205384, 34.486501436906956],
              [-81.138396105498671, 34.486766441802935],
              [-81.138721320342981, 34.486808225305985],
              [-81.138618768285625, 34.487338235325964],
              [-81.138943984416926, 34.487380018246078],
              [-81.138892708914739, 34.487645023369971],
              [-81.139217927487564, 34.487686805528391],
              [-81.13916665267719, 34.487951810774966],
              [-81.139491872579626, 34.487993591289751],
              [-81.139440598461078, 34.488258596658895],
              [-81.139765819716587, 34.488300376431397],
              [-81.139714546289881, 34.488565381923102],
              [-81.140364991861205, 34.488648940002847],
              [-81.140313720148725, 34.488913945756906],
              [-81.140964169063935, 34.488997499686533],
              [-81.140912899065839, 34.489262505702904],
              [-81.141238125041852, 34.489304281484394],
              [-81.141186856824248, 34.489569287603828],
              [-81.141512084153263, 34.489611062642993],
              [-81.141460815515572, 34.489876068003205],
              [-81.141786045286196, 34.489917842280548],
              [-81.141734777363908, 34.490182848664546],
              [-81.14206000739911, 34.490224622218996],
              [-81.141957474737922, 34.490754633373278],
              [-81.142282707148738, 34.490796406325167],
              [-81.142180174121677, 34.491326417709274],
              [-81.142505409996701, 34.491368190039246],
              [-81.142402877692518, 34.491898201633596],
              [-81.142077639772396, 34.491856429024175],
              [-81.141975104077147, 34.492386439368346],
              [-81.141649864442471, 34.492344665597095],
              [-81.141547325356214, 34.49287467469108],
              [-81.141222084006984, 34.492832899757971],
              [-81.141170813513185, 34.493097905030723],
              [-81.1408455714719, 34.493056129075718],
              [-81.140794298512844, 34.493321133309436],
              [-81.141119541576714, 34.493362909404254],
              [-81.141016996711812, 34.49389291809922],
              [-81.141342243239961, 34.493934693572008],
              [-81.141085881446486, 34.495259713882248],
              [-81.140760630893709, 34.495217937691002],
              [-81.140658081376529, 34.49574794524122],
              [-81.140332828020618, 34.495706167907592],
              [-81.140230276224003, 34.496236175089116],
              [-81.139905021153453, 34.496194396593594],
              [-81.139802464876809, 34.496724402543911],
              [-81.139477208091634, 34.496682622886652],
              [-81.139374648423484, 34.497212627586308],
              [-81.139049389923741, 34.497170846767183],
              [-81.138998108582641, 34.497435849401597],
              [-81.138672849390829, 34.497394067560435],
              [-81.138570283647823, 34.497924071596103],
              [-81.138245022741359, 34.497882288593104],
              [-81.138142452541487, 34.498412292298582],
              [-81.137817189920383, 34.498370508133775],
              [-81.137714616328779, 34.498900510588385],
              [-81.138039880995379, 34.498942295032982],
              [-81.137937308126226, 34.499472297697359],
              [-81.138262576257517, 34.499514081519877],
              [-81.138160004110972, 34.500044084393878],
              [-81.138810544368141, 34.500127649970871],
              [-81.13875925927637, 34.500392650769719],
              [-81.139409802901909, 34.50047621309735],
              [-81.139358520613655, 34.500741214138827],
              [-81.140984891099478, 34.500950104306504],
              [-81.140933613594342, 34.501215106029441],
              [-81.141584167005874, 34.501298655643843],
              [-81.141532891239109, 34.501563658530124],
              [-81.141858169451936, 34.501605431703126],
              [-81.14180689435382, 34.50187043381036],
              [-81.142132173943608, 34.501912207142077],
              [-81.142029625889606, 34.502442211564059],
              [-81.142354907832214, 34.502483983391762],
              [-81.142303633787591, 34.502748985725908],
              [-81.142628917107274, 34.502790757712475],
              [-81.142475097123295, 34.503585764108138],
              [-81.142800383818567, 34.503627534730349],
              [-81.142390190000242, 34.505747548237125],
              [-81.142715486297902, 34.505789319075767],
              [-81.142612937120745, 34.506319322320792],
              [-81.142938234706293, 34.506361092576107],
              [-81.142886961165502, 34.506626093400989],
              [-81.143212261216803, 34.506667863795549],
              [-81.143160987279515, 34.50693286476195],
              [-81.144136892415872, 34.507058168367017],
              [-81.144085622328902, 34.507323170616857],
              [-81.144410925725268, 34.507364936860263],
              [-81.144257115390133, 34.508159941238723],
              [-81.143931807836125, 34.508118174595545],
              [-81.143777990343722, 34.508913177513875],
              [-81.143452681140218, 34.508871409549592],
              [-81.143196306166956, 34.510196412172029],
              [-81.143145029976552, 34.510461413188025],
              [-81.143795663167893, 34.510544949872461],
              [-81.143744388669333, 34.510809950248991],
              [-81.144069706760561, 34.510851716506046],
              [-81.144018434043119, 34.511116716985114],
              [-81.144994393440768, 34.511242013585019],
              [-81.144943123461701, 34.511507014465579],
              [-81.145593766736056, 34.511590540498275],
              [-81.145645034669016, 34.511325539338252],
              [-81.147271644650345, 34.5115343396116],
              [-81.147322907137195, 34.511269337735179],
              [-81.149274844715819, 34.511519866467069],
              [-81.149223588343816, 34.5117848682805],
              [-81.149874239257286, 34.511868371071323],
              [-81.149822985713357, 34.512133374028174],
              [-81.150148312132742, 34.512175123798713],
              [-81.150097058192642, 34.512440126897026],
              [-81.151073044681482, 34.512565371294194],
              [-81.151021794568905, 34.51283037477431],
              [-81.152973782610403, 34.513080840580372],
              [-81.152922538306157, 34.513345844880803],
              [-81.153573206403081, 34.513429326689504],
              [-81.153521963791036, 34.513694330350226],
              [-81.153847299358432, 34.513736070070593],
              [-81.153796057439052, 34.514001073853187],
              [-81.154446731611841, 34.514084550925652],
              [-81.154395491408238, 34.514349554969812],
              [-81.154720830013517, 34.514391292322074],
              [-81.15466959047906, 34.514656295586725],
              [-81.15499493043805, 34.514698032196144],
              [-81.154943692708784, 34.514963036464465],
              [-81.155594375641016, 34.51504650641381],
              [-81.155543139627696, 34.515311510943597],
              [-81.156519170674983, 34.515436710098861],
              [-81.15646793631204, 34.515701715049367],
              [-81.157118627730881, 34.515785176712718],
              [-81.157067396149074, 34.516050181003727],
              [-81.157392742844792, 34.516091911111751],
              [-81.157290280736518, 34.516621919919757],
              [-81.157615629809214, 34.516663649424508],
              [-81.157564399282677, 34.516928653941413],
              [-81.157889750774302, 34.516970381782315],
              [-81.157787289687477, 34.517500391061482],
              [-81.158112643579884, 34.517542119200371],
              [-81.158010183194179, 34.518072127786127],
              [-81.158335540528768, 34.51811385440093],
              [-81.158284310331013, 34.518378859267088],
              [-81.158609667930477, 34.518420585158481],
              [-81.158558439514465, 34.5186855901268],
              [-81.159209158840284, 34.518769039521622],
              [-81.159157932140488, 34.519034044751301],
              [-81.159483292777949, 34.519075768274277],
              [-81.159432066747399, 34.519340772724419],
              [-81.160082792172872, 34.519424218283611],
              [-81.160031567858624, 34.519689222995069],
              [-81.160356931534054, 34.519730944149636],
              [-81.160305707912741, 34.51999594898281],
              [-81.160956439390503, 34.520079388903717],
              [-81.160905218574442, 34.520344393978554],
              [-81.161230585287868, 34.520386112764498],
              [-81.161179364075878, 34.520651117980691],
              [-81.161504733232007, 34.520692836004031],
              [-81.161453512712924, 34.520957841341882],
              [-81.161778883199233, 34.520999557721034],
              [-81.161727663373156, 34.521264563180566],
              [-81.162378407431135, 34.521347995372714],
              [-81.162327190386705, 34.521613000172565],
              [-81.163303312021782, 34.521738142260013],
              [-81.163354525972096, 34.521473136140173],
              [-81.164656021993139, 34.521639978440902],
              [-81.164604812112842, 34.521904984217656],
              [-81.164930187973326, 34.521946692951275],
              [-81.164981396806425, 34.521681686133597],
              [-81.166933653783119, 34.521931916439534],
              [-81.167259030932428, 34.521973618855284],
              [-81.167207830328664, 34.522238625728654],
              [-81.169810870724348, 34.522572208930036],
              [-81.169759676889313, 34.522837216921289],
              [-81.170410442621915, 34.522920604620126],
              [-81.1703592515927, 34.523185612852771],
              [-81.171010020669343, 34.52326899639877],
              [-81.170958831356955, 34.523534004892412],
              [-81.171284217958529, 34.523575695471131],
              [-81.171233029339461, 34.523840704086403],
              [-81.171558417294875, 34.523882393921951],
              [-81.171456041089385, 34.524412410476366],
              [-81.171781430333155, 34.524454099727883],
              [-81.171627866630843, 34.525249125293982],
              [-81.171953261453496, 34.525290814041931],
              [-81.171902073899943, 34.525555822066664],
              [-81.172227468987487, 34.525597510091046],
              [-81.17217628212741, 34.525862518237304],
              [-81.171850886016273, 34.525820830073435],
              [-81.171799697802371, 34.526085838062258],
              [-81.17212509493703, 34.526127526365585],
              [-81.172022719565817, 34.526657542568302],
              [-81.17234811907791, 34.526699230267887],
              [-81.172245744432985, 34.527229246677493],
              [-81.171920342873634, 34.527187558698941],
              [-81.17181796483672, 34.527717573856329],
              [-81.171492560471322, 34.527675884735849],
              [-81.171441369945839, 34.527940892598686],
              [-81.171115964887036, 34.527899202456041],
              [-81.170962388210398, 34.528694223715377],
              [-81.171287795251061, 34.528735914296192],
              [-81.171236603380748, 34.529000921185606],
              [-81.171562012864442, 34.529042611003547],
              [-81.171510821687562, 34.529307618014414],
              [-81.171836232501349, 34.529349306187726],
              [-81.171785042017916, 34.529614313320025],
              [-81.172110454209459, 34.529656001651396],
              [-81.171803309378916, 34.531246043119921],
              [-81.1714778910451, 34.53120435395163],
              [-81.171426698226057, 34.531469360056128],
              [-81.171101278133463, 34.531427670786705],
              [-81.170845301787125, 34.532752700379767],
              [-81.171170726998369, 34.532794390346567],
              [-81.171119532197636, 34.533059396342381],
              [-81.171444958762933, 34.533101085565875],
              [-81.171291374337386, 34.533896102981402],
              [-81.170965944700654, 34.533854413339498],
              [-81.170863550989864, 34.534384423385106],
              [-81.170538119635935, 34.534342732581393],
              [-81.170486920716627, 34.534607737447303],
              [-81.170161488645405, 34.534566044720108],
              [-81.170110289461178, 34.53483104940868],
              [-81.170007887923447, 34.535361058770661],
              [-81.169682453134939, 34.535319365643545],
              [-81.169631250846805, 34.535584370157792],
              [-81.169305815341005, 34.535542675107116],
              [-81.169254611698705, 34.53580767946368],
              [-81.168929175499343, 34.53576598339064],
              [-81.168877970502862, 34.536030987589605],
              [-81.168227095982388, 34.535947593437264],
              [-81.168175888584187, 34.53621259643765],
              [-81.167525014426658, 34.536129198455441],
              [-81.167473803585011, 34.536394202079677],
              [-81.16487030866594, 34.53606057197976],
              [-81.164819090368624, 34.536325573548638],
              [-81.16449365328026, 34.536283865460994],
              [-81.164442433652539, 34.536548867773483],
              [-81.163466123476937, 34.536423737756024],
              [-81.163414899334455, 34.536688738750051],
              [-81.163740336389907, 34.536730449784692],
              [-81.163637890250257, 34.537260451977303],
              [-81.163963329683725, 34.537302162408317],
              [-81.163912106597522, 34.537567163626619],
              [-81.164237548474432, 34.53760887329463],
              [-81.164186326081492, 34.537873874634215],
              [-81.164511769312796, 34.537915583559034],
              [-81.164460547613231, 34.538180585019909],
              [-81.164785992198858, 34.538222293201358],
              [-81.164581107232806, 34.53928229759812],
              [-81.16425565855171, 34.539240588858469],
              [-81.164204436288912, 34.539505590208314],
              [-81.163878985825178, 34.539463880466045],
              [-81.163827762184255, 34.539728880756861],
              [-81.163502312116407, 34.539687169972524],
              [-81.163399859640592, 34.540217171160648],
              [-81.163074406766299, 34.540175459234149],
              [-81.163023178996909, 34.540440459210551],
              [-81.162372274370469, 34.540357032390567],
              [-81.162321044222679, 34.540622032069685],
              [-81.161670137781428, 34.540538601458941],
              [-81.161618905255409, 34.540803600840569],
              [-81.160967999177672, 34.540720166399772],
              [-81.160916763184133, 34.540985165503578],
              [-81.160591309617274, 34.540943446819441],
              [-81.16038636037257, 34.542003440651271],
              [-81.160060901951496, 34.541961720545665],
              [-81.160009663346315, 34.542226719259034],
              [-81.159684204231937, 34.542184998130914],
              [-81.15963296424863, 34.542449995785134],
              [-81.159307504440932, 34.542408273634628],
              [-81.159051290088613, 34.543733261010374],
              [-81.158725825491786, 34.543691537279173],
              [-81.158520843043931, 34.544751524503475],
              [-81.158195374682009, 34.544709799331201],
              [-81.158144127231324, 34.544974796402279],
              [-81.157818658176012, 34.544933070207556],
              [-81.157716160187306, 34.54546306221274],
              [-81.157390689414825, 34.545421334855966],
              [-81.157339438900394, 34.545686330691318],
              [-81.157013966345431, 34.545644602331677],
              [-81.15696271447635, 34.54590959800899],
              [-81.156637242317245, 34.545867868607331],
              [-81.156585988004309, 34.546132864146188],
              [-81.156260515151956, 34.54609113372203],
              [-81.156209260573576, 34.546356129083293],
              [-81.155558312700009, 34.546272664425338],
              [-81.155507054653668, 34.546537659508509],
              [-81.154856107167973, 34.546454191921519],
              [-81.154804846742906, 34.546719186707129],
              [-81.154479372448577, 34.54667745054838],
              [-81.154376848558229, 34.547207439784749],
              [-81.15405137254659, 34.547165702463978],
              [-81.153846316653272, 34.54822567835393],
              [-81.153520835787191, 34.548183939611377],
              [-81.153418303784207, 34.548713926264789],
              [-81.153092822313809, 34.54867218724182],
              [-81.152939017666881, 34.549467166683819],
              [-81.152613532342258, 34.549425425477281],
              [-81.152562262804068, 34.549690418441365],
              [-81.150934836117543, 34.549481702072441],
              [-81.150986111865208, 34.549216709786869],
              [-81.150335145540438, 34.549133216797351],
              [-81.150283868833938, 34.549398208784112],
              [-81.149958385132123, 34.549356460374703],
              [-81.149907105981413, 34.549621452222709],
              [-81.148279688451453, 34.549412698017044],
              [-81.148228403849913, 34.549677689148261],
              [-81.147577436675178, 34.549594181194372],
              [-81.147526149671037, 34.549859171126485],
              [-81.14720066556788, 34.549817416136115],
              [-81.147149377208791, 34.55008240590994],
              [-81.145847439938706, 34.54991537385709],
              [-81.145898733483122, 34.549650384622396],
              [-81.144922288149402, 34.549525101035861],
              [-81.14487099155653, 34.549790090752779],
              [-81.144220026881442, 34.549706563680857],
              [-81.144271325522297, 34.549441574243403],
              [-81.143945844705229, 34.549399809522733],
              [-81.143894546129687, 34.549664798801125],
              [-81.143243583440537, 34.549581266431844],
              [-81.143294885153438, 34.549316277413453],
              [-81.142969406418601, 34.549274510024688],
              [-81.142918103681822, 34.549539498903336],
              [-81.142267142978895, 34.549455961236795],
              [-81.142215837839615, 34.549720948916161],
              [-81.141890358050034, 34.549679178599391],
              [-81.14183905048985, 34.549944167041225],
              [-81.141188088743107, 34.549860622597635],
              [-81.141136779893301, 34.550125610722105],
              [-81.140160335062063, 34.550000287495756],
              [-81.140109022785666, 34.550265274281053],
              [-81.138481617340659, 34.550056384462138],
              [-81.138532935802615, 34.549791397454783],
              [-81.137881978653326, 34.549707835975383],
              [-81.137933298832053, 34.549442849228953],
              [-81.137607821233402, 34.549401067314449],
              [-81.137556501096569, 34.549666053000415],
              [-81.137231022805025, 34.549624270063276],
              [-81.137179700223783, 34.549889255610218],
              [-81.136854221239631, 34.549847471650438],
              [-81.136802897303212, 34.550112457039127],
              [-81.136477418692024, 34.550070671135984],
              [-81.136426093400502, 34.550335656366329],
              [-81.136100613030678, 34.550293870361244],
              [-81.136049286384207, 34.550558855433188],
              [-81.135723805298085, 34.550517067504181],
              [-81.135672477296396, 34.550782052417766],
              [-81.135021514093438, 34.550698474532759],
              [-81.134970183712625, 34.550963459148257],
              [-81.134644701572199, 34.550921668290975],
              [-81.134696034066366, 34.550656683795957],
              [-81.13437055330462, 34.550614893096927],
              [-81.134627212635252, 34.549289970198416],
              [-81.13430173839015, 34.549248178394684],
              [-81.134404402055608, 34.548718208840079],
              [-81.134078930189617, 34.548676416433139],
              [-81.134130262016456, 34.548411432227994],
              [-81.133804791505554, 34.548369639077983],
              [-81.133856125090944, 34.54810465407347],
              [-81.133530655935132, 34.54806286018038],
              [-81.133633323070825, 34.547532890414601],
              [-81.133307856293982, 34.547491095918311],
              [-81.133359189844185, 34.547226111157009],
              [-81.132708260421126, 34.547142519776145],
              [-81.132656923733848, 34.547407504277146],
              [-81.131680527378037, 34.547282110194175],
              [-81.13162918837736, 34.547547094237842],
              [-81.130978257082035, 34.547463493488713],
              [-81.131029599219687, 34.547198509705339],
              [-81.130378671297123, 34.547114905704561],
              [-81.130430015151177, 34.546849922182261],
              [-81.12945362988917, 34.546724509058819],
              [-81.129504977572651, 34.54645952591811],
              [-81.128528598363829, 34.546334105268855],
              [-81.128477248698175, 34.546599087970705],
              [-81.128151788245418, 34.546557279489079],
              [-81.128100436135384, 34.54682226205189],
              [-81.12777497605606, 34.546780451626951],
              [-81.127620915755742, 34.547575398765119],
              [-81.127295451870395, 34.547533587958448],
              [-81.127244096387386, 34.547798570167288],
              [-81.126918632875402, 34.547756757417353],
              [-81.126867276037217, 34.54802173946775],
              [-81.125565417986849, 34.547854480940828],
              [-81.125514056721997, 34.54811946241319],
              [-81.124212198789195, 34.547952189220936],
              [-81.124314930267246, 34.547422227357281],
              [-81.123989468648546, 34.54738040668132],
              [-81.124092200848622, 34.546850445023118],
              [-81.123115826325488, 34.546724979400317],
              [-81.123167194455135, 34.546459998972679],
              [-81.122516281432397, 34.546376350495606],
              [-81.122464911255335, 34.546641330643482],
              [-81.122139453672773, 34.546599504950436],
              [-81.122088082140479, 34.546864484939917],
              [-81.121762623888898, 34.546822659125446],
              [-81.121659876669767, 34.547352617886801],
              [-81.121334416702112, 34.547310790909812],
              [-81.121283041015346, 34.547575769681657],
              [-81.120957581421379, 34.547533940761404],
              [-81.120906204402615, 34.547798920276044],
              [-81.120580743027091, 34.54775709035227],
              [-81.120529364652967, 34.548022069708388],
              [-81.119552981622576, 34.54789657418219],
              [-81.119604363067936, 34.547631595245811],
              [-81.118953444389689, 34.547547927433968],
              [-81.118902059807425, 34.547812906109748],
              [-81.118576600486577, 34.547771070730086],
              [-81.118627985003229, 34.547506092213496],
              [-81.117326152927959, 34.547338741563742],
              [-81.117274764316278, 34.547603719520595],
              [-81.116623848208917, 34.547520038168457],
              [-81.116675239957374, 34.547255060472253],
              [-81.116349782891774, 34.547213219072241],
              [-81.116401175332371, 34.546948241497397],
              [-81.115099357725342, 34.546780866670382],
              [-81.115150753929257, 34.546515889636737],
              [-81.114825301635108, 34.546474043182236],
              [-81.114928095095522, 34.545944089339223],
              [-81.114602645180369, 34.545902242281841],
              [-81.114654042436868, 34.545637265472436],
              [-81.114328593877019, 34.545595417672345],
              [-81.114534183635314, 34.544535509006295],
              [-81.114208739524855, 34.544493661784628],
              [-81.114260137147539, 34.544228684261022],
              [-81.113934694369163, 34.544186835395294],
              [-81.114037490666917, 34.543656880572563],
              [-81.113712051379935, 34.543615031986079],
              [-81.113763450031783, 34.543350053785581],
              [-81.113438012100019, 34.543308204456444],
              [-81.1134894114671, 34.543043227278631],
              [-81.113163974867362, 34.54300137630554],
              [-81.113215374903234, 34.542736398347898],
              [-81.112889938592701, 34.542694547552479],
              [-81.112941340409776, 34.542429569697212],
              [-81.112290471873592, 34.542345863916346],
              [-81.112341875429422, 34.542080887223868],
              [-81.111691011356129, 34.541997178172977],
              [-81.111793819365573, 34.541467224429574],
              [-81.111468389873536, 34.541425368860111],
              [-81.111416984999465, 34.541690345150428],
              [-81.111091554815488, 34.541648488558209],
              [-81.110937333000578, 34.542443418739104],
              [-81.110286467486191, 34.542359702066591],
              [-81.110235058214812, 34.542624677862854],
              [-81.109909623842313, 34.542582818081719],
              [-81.109858213215588, 34.54284779371946],
              [-81.109532779240425, 34.542805932896464],
              [-81.109481366169291, 34.543070908394789],
              [-81.108505062074045, 34.542945320228604],
              [-81.108453645600378, 34.543210295288375],
              [-81.10715190878561, 34.543042830555144],
              [-81.107203329353467, 34.542777856055523],
              [-81.106877896985353, 34.542735987129582],
              [-81.106929318245022, 34.542471012751399],
              [-81.105953026248073, 34.542345402900096],
              [-81.106004451312728, 34.542080428003125],
              [-81.10437730972977, 34.541871060996961],
              [-81.104428739580285, 34.541606086781854],
              [-81.104103314381035, 34.541564211214094],
              [-81.104154743834073, 34.541299237139562],
              [-81.102853048201439, 34.541131723936317],
              [-81.102904482505863, 34.540866750384545],
              [-81.102579059900378, 34.540824869801412],
              [-81.102527624572346, 34.541089843212994],
              [-81.101551357877881, 34.54096419661041],
              [-81.101499919124592, 34.541229168682001],
              [-81.101174495799484, 34.541187284288014],
              [-81.101123055713842, 34.541452257102307],
              [-81.10079763169712, 34.541410371685558],
              [-81.100540418926215, 34.542735232112904],
              [-81.100214990123874, 34.542693345112895],
              [-81.099906316973346, 34.544283173120043],
              [-81.099580882361721, 34.544241284396726],
              [-81.099477985504507, 34.544771226055175],
              [-81.099152549177703, 34.54472933616902],
              [-81.099101099216185, 34.544994306379564],
              [-81.098775662197738, 34.544952415470526],
              [-81.098672759254796, 34.545482356456922],
              [-81.0983473205211, 34.545440464385038],
              [-81.098295867516612, 34.545705434259524],
              [-81.097970427002139, 34.545663541183757],
              [-81.097867517950135, 34.546193480596713],
              [-81.097216634912044, 34.546109691217609],
              [-81.097165177840836, 34.546374660615868],
              [-81.096839736340826, 34.546332764452643],
              [-81.096788276824867, 34.546597733711181],
              [-81.096462834633272, 34.546555836525108],
              [-81.096411373738718, 34.546820804723552],
              [-81.096085930855551, 34.546778906514639],
              [-81.096034468628318, 34.547043875455614],
              [-81.09570902505358, 34.547001976223811],
              [-81.095657561447837, 34.547266944104685],
              [-81.095332117181528, 34.547225043850069],
              [-81.095229186949751, 34.54775498017684],
              [-81.094903740968292, 34.547713078759202],
              [-81.094852274319152, 34.547978046303797],
              [-81.094526826556873, 34.547936143882318],
              [-81.09447535855216, 34.548201111268071],
              [-81.093824463065843, 34.54811730347754],
              [-81.093772992681878, 34.548382270564375],
              [-81.093447543868479, 34.548340365214337],
              [-81.093293127630247, 34.549135265040768],
              [-81.092967676078203, 34.549093358387587],
              [-81.092916202318662, 34.549358325119236],
              [-81.092590750075075, 34.549316417443187],
              [-81.09253927493684, 34.549581383114649],
              [-81.090261110230685, 34.549288001900585],
              [-81.090158143560799, 34.549817932145224],
              [-81.089832690903464, 34.549776016986712],
              [-81.08972972083464, 34.55030594597477],
              [-81.089404266462239, 34.550264029653142],
              [-81.089301291928237, 34.550793958304865],
              [-81.08897583584077, 34.550752040820058],
              [-81.088821369566602, 34.551546933217381],
              [-81.088495909628165, 34.551505013546972],
              [-81.08844441881898, 34.551769977507988],
              [-81.087793499003723, 34.551686136120743],
              [-81.087742005815002, 34.551951099782634],
              [-81.087416545938211, 34.551909178065898],
              [-81.087365051370682, 34.552174140667432],
              [-81.087039589713129, 34.552132217946699],
              [-81.086988093789614, 34.552397180389214],
              [-81.08601170878309, 34.552271404688156],
              [-81.085960209479339, 34.552536367592559],
              [-81.085634747080633, 34.552494440158959],
              [-81.085583246420967, 34.552759402904421],
              [-81.085257783330889, 34.552717474447832],
              [-81.085206280202939, 34.552982436151865],
              [-81.084880817533758, 34.55294050755468],
              [-81.084829313049937, 34.553205469099666],
              [-81.084503848577171, 34.553163538597204],
              [-81.08445234384962, 34.553428500865429],
              [-81.083475950443571, 34.553302703621704],
              [-81.083527458264982, 34.553037742675457],
              [-81.083201996210761, 34.552995808622946],
              [-81.083305015082644, 34.55246588691665],
              [-81.082654094976604, 34.552382016743323],
              [-81.082705604849053, 34.552117055260055],
              [-81.08238014686286, 34.552075118980106],
              [-81.082431658538667, 34.551810158500693],
              [-81.08210620190853, 34.551768221478191],
              [-81.082157714252745, 34.551503260218915],
              [-81.081832258978466, 34.551461322453925],
              [-81.081986797088177, 34.550666438984194],
              [-81.081661345217015, 34.550624500757316],
              [-81.081712858279005, 34.550359539703372],
              [-81.081387407763856, 34.550317600734019],
              [-81.081438921517147, 34.55005263980167],
              [-81.081113472357742, 34.550010700089871],
              [-81.081164986802335, 34.549745739278933],
              [-81.08051409259437, 34.549661856567575],
              [-81.080565608753503, 34.549396896018415],
              [-81.080240162638376, 34.549354953939044],
              [-81.080291680555248, 34.549089992591291],
              [-81.079966235796036, 34.549048049769503],
              [-81.080017753314834, 34.548783088562175],
              [-81.079366867929792, 34.548699200532106],
              [-81.079418388252449, 34.548434239567712],
              [-81.078442065171004, 34.548308400431473],
              [-81.078390542844318, 34.548573360054831],
              [-81.078065101102979, 34.54853141207716],
              [-81.078013576353783, 34.548796372461652],
              [-81.07736269288786, 34.548712472657485],
              [-81.077414219683945, 34.548447512553551],
              [-81.076437900582036, 34.548321656188023],
              [-81.076489430116155, 34.548056696486256],
              [-81.075187680440123, 34.547888876797202],
              [-81.07513614678922, 34.548153835036324],
              [-81.074485271354433, 34.548069919624588],
              [-81.074433735347, 34.548334878465596],
              [-81.074108296548829, 34.54829291885418],
              [-81.074211371367454, 34.547763001415142],
              [-81.073885936037996, 34.547721041182854],
              [-81.0740920857791, 34.546661206641431],
              [-81.073115794066979, 34.546535322332652],
              [-81.073064253515525, 34.546800280584527],
              [-81.072738823698344, 34.54675831812505],
              [-81.072687281790976, 34.547023276217899],
              [-81.072361850170935, 34.546981311852953],
              [-81.072310306907625, 34.547246269786697],
              [-81.071659442618312, 34.54716233812821],
              [-81.071607896975593, 34.547427295762432],
              [-81.070306168271046, 34.547259420391022],
              [-81.07035771909672, 34.546994463299463],
              [-81.070032288781121, 34.546952492615695],
              [-81.070083839208323, 34.546687535664496],
              [-81.069758410248696, 34.546645564238489],
              [-81.069809961344021, 34.546380606507689],
              [-81.068508253991482, 34.546212711618615],
              [-81.068559809959723, 34.545947755313072],
              [-81.066607266649086, 34.545695885962971],
              [-81.066555704540676, 34.545960841426009],
              [-81.066230280263042, 34.54591886043147],
              [-81.066333406171339, 34.545388948866069],
              [-81.066007984250462, 34.545346966368427],
              [-81.0660595477176, 34.545082010247455],
              [-81.065734128264552, 34.545040027890153],
              [-81.065888818650876, 34.544245159855016],
              [-81.065563402577865, 34.544203176135035],
              [-81.065614966761032, 34.54393822022007],
              [-81.065289552066631, 34.543896236659243],
              [-81.065341116917693, 34.543631279964771],
              [-81.065015703579249, 34.543589295661775],
              [-81.06511883435293, 34.543059383398912],
              [-81.064793423370929, 34.543017397592891],
              [-81.064896554860454, 34.542487485536299],
              [-81.064571146257734, 34.542445499128476],
              [-81.064622712538096, 34.542180543663285],
              [-81.064297306380524, 34.542138556494656],
              [-81.064348873328811, 34.54187360024995],
              [-81.063698062968911, 34.54178962356459],
              [-81.063749631631012, 34.541524667582053],
              [-81.063424227428683, 34.541482678065364],
              [-81.063475796781361, 34.541217722204713],
              [-81.063150395024138, 34.54117573192714],
              [-81.063201965067336, 34.540910776188241],
              [-81.062551164597267, 34.540826793266376],
              [-81.062602736354293, 34.540561837789816],
              [-81.062277337641433, 34.540519845145447],
              [-81.062328910066242, 34.540254888889422],
              [-81.062003512709367, 34.540212895503053],
              [-81.062209805398084, 34.539153072619804],
              [-81.061884412466569, 34.539111078912747],
              [-81.061935985274289, 34.538846122844561],
              [-81.061610593698603, 34.538804128395412],
              [-81.061662168285963, 34.53853917243049],
              [-81.060035221557655, 34.538329185828104],
              [-81.060086800927962, 34.538064230546937],
              [-81.059761413372186, 34.538022230182335],
              [-81.059812993432871, 34.537757275023068],
              [-81.05948760834471, 34.537715274799119],
              [-81.059539188006624, 34.53745031978049],
              [-81.059213804251669, 34.537408317913147],
              [-81.059265385693124, 34.537143362997746],
              [-81.058940003293984, 34.537101360388469],
              [-81.058991584336638, 34.536836405613684],
              [-81.058666203293242, 34.536794402262366],
              [-81.058717786115466, 34.536529447590929],
              [-81.058067027073136, 34.536445438521859],
              [-81.058118611608649, 34.536180484112805],
              [-81.057793233609715, 34.536138478395088],
              [-81.057844818812953, 34.535873523206611],
              [-81.057519442169834, 34.535831516746946],
              [-81.057571028085945, 34.53556656258182],
              [-81.057245652798613, 34.535524555380135],
              [-81.056594903222233, 34.535440538329503],
              [-81.056543314259486, 34.535705492974493],
              [-81.056217938947441, 34.535663482984887],
              [-81.056114756866009, 34.536193391055548],
              [-81.055789379840661, 34.536151379902449],
              [-81.055737787833579, 34.536416334210735],
              [-81.055412410117981, 34.53637432203476],
              [-81.05536081564334, 34.536639275301425],
              [-81.054710059164421, 34.536555248020903],
              [-81.05465846342257, 34.536820201870555],
              [-81.054007705139782, 34.536736170797866],
              [-81.053956106996466, 34.537001123446572],
              [-81.053305347998958, 34.53691708856288],
              [-81.05325374638754, 34.53718204093061],
              [-81.052602987764445, 34.537098002217405],
              [-81.052551383774073, 34.537362954285477],
              [-81.052226003938827, 34.537320933464613],
              [-81.052174398592541, 34.537585885373566],
              [-81.051849018067045, 34.537543863529692],
              [-81.051797410275739, 34.537808815298142],
              [-81.051472029060179, 34.537766792431256],
              [-81.051420421001993, 34.538031744021936],
              [-81.051095039073687, 34.537989719230715],
              [-81.051043428570381, 34.538254670680878],
              [-81.049741899072757, 34.53808656485149],
              [-81.049793513668277, 34.537821613963601],
              [-81.049142752407391, 34.537737555593836],
              [-81.049245984691709, 34.537207654324583],
              [-81.048920607162572, 34.537165624538538],
              [-81.048972222739152, 34.53690067403523],
              [-81.048646846543505, 34.536858642605942],
              [-81.048698463899314, 34.536593692206033],
              [-81.048373089059609, 34.536551660034945],
              [-81.048476324795871, 34.53602175855918],
              [-81.048150952357503, 34.535979726688147],
              [-81.048254188807988, 34.535449825419413],
              [-81.047928818726064, 34.535407792045802],
              [-81.047980437463551, 34.535142841073551],
              [-81.047655068737654, 34.535100806958035],
              [-81.047500209546499, 34.535895659378845],
              [-81.047174838084658, 34.535853623959191],
              [-81.047019971693729, 34.536648474007087],
              [-81.046694597518382, 34.536606438184513],
              [-81.046591349531852, 34.537136337241513],
              [-81.046265973620962, 34.537094299353981],
              [-81.046214348105295, 34.537359248713997],
              [-81.045888971526878, 34.537317210704721],
              [-81.045837344655084, 34.537582159905554],
              [-81.045511967364334, 34.537540119971922],
              [-81.045408709486495, 34.538070018055144],
              [-81.04508333048274, 34.538027976957792],
              [-81.045031699476681, 34.538292925840182],
              [-81.044706319782961, 34.538250883719783],
              [-81.044654688509993, 34.538515832424359],
              [-81.044329308126223, 34.538473789280879],
              [-81.044277674385569, 34.538738736943486],
              [-81.043626912593808, 34.53865464862924],
              [-81.043575276474058, 34.53891959599197],
              [-81.042924512879381, 34.538835503885196],
              [-81.042872875469683, 34.539100450929475],
              [-81.042547492593073, 34.539058402970319],
              [-81.042599132115072, 34.538793456048126],
              [-81.041948371585661, 34.538709357726681],
              [-81.042000012798056, 34.538444410165894],
              [-81.041349255647205, 34.538360308595927],
              [-81.041400898594816, 34.53809536219903],
              [-81.04107552154214, 34.538053310230971],
              [-81.041127165157192, 34.537788363054858],
              [-81.040476414097043, 34.537704256752825],
              [-81.040528059425, 34.537439309839343],
              [-81.03987731281012, 34.537355199369024],
              [-81.039928959850869, 34.53709025271823],
              [-81.038952844984905, 34.536964082177789],
              [-81.03890119378616, 34.53722902842518],
              [-81.038575822511305, 34.537186968759208],
              [-81.038472518157548, 34.537716860898129],
              [-81.038147144103178, 34.537674800988142],
              [-81.037992181437801, 34.538469637742836],
              [-81.037666804625388, 34.538427575627132],
              [-81.037615148358626, 34.53869252134065],
              [-81.037289770856447, 34.538650458201843],
              [-81.037238113233485, 34.538915403756036],
              [-81.036912735041454, 34.538873339594105],
              [-81.036861076062294, 34.539138284988965],
              [-81.036210318654511, 34.53905415463764],
              [-81.036261979701976, 34.538789210425463],
              [-81.035611225673094, 34.538705076825849],
              [-81.035559562579706, 34.538970020756622],
              [-81.034258054429102, 34.538801742405269],
              [-81.034206386910611, 34.539066685754655],
              [-81.033881009694369, 34.539024614270609],
              [-81.033777670522397, 34.539554500650333],
              [-81.033452290481932, 34.539512427119661],
              [-81.033348947930804, 34.540042313143211],
              [-81.033674328928029, 34.54008438697376],
              [-81.033519316192766, 34.540879215380563],
              [-81.033193931037445, 34.540837141146305],
              [-81.033142258806492, 34.541102084064718],
              [-81.033467643895676, 34.541144158458145],
              [-81.033364299346147, 34.541674042736084],
              [-81.033038911144118, 34.541631968980973],
              [-81.032987237890964, 34.541896910941901],
              [-81.032661848999155, 34.541854836163651],
              [-81.03255849726844, 34.542384719784813],
              [-81.032233107730846, 34.542342642922868],
              [-81.032129752620563, 34.542872526187708],
              [-81.03147896829735, 34.542788370191687],
              [-81.031427288196099, 34.543053311514491],
              [-81.031101896589618, 34.543011231582959],
              [-81.031050214042693, 34.543276172764706],
              [-81.030724821746546, 34.543234091809879],
              [-81.030673137843209, 34.543499032832194],
              [-81.030022351137987, 34.54341486891348],
              [-81.029970664832859, 34.543679808734176],
              [-81.029645271490864, 34.543637724850505],
              [-81.029593583851565, 34.543902665412965],
              [-81.028942793943258, 34.543818494753573],
              [-81.02889110501367, 34.54408343499734],
              [-81.028565709536636, 34.54404134820318],
              [-81.028617399511504, 34.543776409001467],
              [-81.028292006480186, 34.543734321447083],
              [-81.028343697122409, 34.543469381466046],
              [-81.027367520941922, 34.543343114868605],
              [-81.02731582611915, 34.543608053544531],
              [-81.026990433332429, 34.543565962478738],
              [-81.026938737175385, 34.543830901896364],
              [-81.026613343699125, 34.543788809807324],
              [-81.026561647252578, 34.544053748145672],
              [-81.026236253086779, 34.544011655033373],
              [-81.026184554194387, 34.544276593230563],
              [-81.025859159339134, 34.544234499095033],
              [-81.025807459112542, 34.544499438033931],
              [-81.025156668356232, 34.544415246833879],
              [-81.02510496572782, 34.544680184571028],
              [-81.024779569826975, 34.544638087506499],
              [-81.024469337624012, 34.546227711843919],
              [-81.024143935918403, 34.546185613052167],
              [-81.024092228886886, 34.546450549474685],
              [-81.02376682540249, 34.546408449678033],
              [-81.023663407225555, 34.546938323104655],
              [-81.023338003117857, 34.546896222125518],
              [-81.022036387822212, 34.546727808519329],
              [-81.021984672584466, 34.546992744960178],
              [-81.019706853105916, 34.546697985033468],
              [-81.019758575527234, 34.546433050479585],
              [-81.019433175422961, 34.54639093891052],
              [-81.019484898511493, 34.546126003577399],
              [-81.017857908043709, 34.545915430495114],
              [-81.017909637025099, 34.545650496730289],
              [-81.017584240946761, 34.545608379246744],
              [-81.017532510964486, 34.54587331377202],
              [-81.016556321684718, 34.545746956505219],
              [-81.016608054735997, 34.545482022402531],
              [-81.015957265604811, 34.545397780394538],
              [-81.016009000368356, 34.545132846554679],
              [-81.015683607315282, 34.545090723917177],
              [-81.015735342767968, 34.544825790199397],
              [-81.015409951071703, 34.544783666820315],
              [-81.01546168721373, 34.544518733224471],
              [-81.015136297963238, 34.544476609085535],
              [-81.015188034794477, 34.544211675611798],
              [-81.014862645811519, 34.544169550749579],
              [-81.014966120518721, 34.543639684027305],
              [-81.014640735004619, 34.543597558546075],
              [-81.01469247288054, 34.543332625297616],
              [-81.01436708872302, 34.543290499074885],
              [-81.014418827266041, 34.543025565047174],
              [-81.014093444465274, 34.542983438082814],
              [-81.014145183697451, 34.542718504177188],
              [-81.013819802253352, 34.542676376471363],
              [-81.013871542196796, 34.542411443589053],
              [-81.013546162087252, 34.542369314240482],
              [-81.013597902719823, 34.542104381480257],
              [-81.013272523988931, 34.542062252291394],
              [-81.013324265288546, 34.541797318752018],
              [-81.010721256663899, 34.541460251879265],
              [-81.010773006901914, 34.541195319430145],
              [-81.009471515225712, 34.541026764920105],
              [-81.009523269221262, 34.540761833015893],
              [-81.007571049470855, 34.540508974696287],
              [-81.007519288249171, 34.540773905773086],
              [-81.007193918973741, 34.540731759181014],
              [-81.007142156417387, 34.540996690999386],
              [-81.00681678645293, 34.540954543383975],
              [-81.006765022517939, 34.5412194741413],
              [-81.006439651864568, 34.541177325502566],
              [-81.006387886572867, 34.541442256100147],
              [-81.006062514141348, 34.541400106456251],
              [-81.006114280455975, 34.541135175999578],
              [-81.00578890938128, 34.541093025614373],
              [-81.005840676384807, 34.540828095279863],
              [-81.005515307756056, 34.540785944134932],
              [-81.005618841717748, 34.540256083709863],
              [-81.005293475468406, 34.540213931964431],
              [-81.005345242426557, 34.539949001873794],
              [-81.005019877534011, 34.539906849387066],
              [-81.005071646248226, 34.539641918499115],
              [-81.004420918421232, 34.539557611178665],
              [-81.004369147661464, 34.53982254178473],
              [-81.003718420213744, 34.539738230634683],
              [-81.003770193019037, 34.539473300310654],
              [-81.003444830818907, 34.539431143553131],
              [-81.003496604313014, 34.539166213351308],
              [-81.003171243469581, 34.539124055852419],
              [-81.003223017652402, 34.538859125772781],
              [-81.002897658165651, 34.538816967532547],
              [-81.003001207575167, 34.538287107599118],
              [-81.002675850467753, 34.538244948758475],
              [-81.002727625694263, 34.537980018904634],
              [-81.002402271032778, 34.537937859304542],
              [-81.002454046948031, 34.537672929572985],
              [-81.001477985835066, 34.537546445036789],
              [-81.001152632421025, 34.537504281165205],
              [-81.001100852414964, 34.537769210332733],
              [-81.000775498334164, 34.537727046339207],
              [-81.000723715882245, 34.537991975365173],
              [-81.000398362201921, 34.537949810330112],
              [-81.000346578393305, 34.538214739196398],
              [-81.000021222913162, 34.538172572254801],
              [-80.999917653337661, 34.538702429630803],
              [-80.998616227708695, 34.538533753697635],
              [-80.998564437762553, 34.538798680901103],
              [-80.997588369263539, 34.538672164709887],
              [-80.997640162255735, 34.538407237028103],
              [-80.996338745958525, 34.538238535488702],
              [-80.996286948875493, 34.53850346260618],
              [-80.995961594335967, 34.538461284653678],
              [-80.995909795896139, 34.53872621161134],
              [-80.995259085773952, 34.538641852777282],
              [-80.99520728495466, 34.538906779434058],
              [-80.994881929372113, 34.5388645985525],
              [-80.994933731214076, 34.538599672036796],
              [-80.99265626191837, 34.538304382094026],
              [-80.992708071673917, 34.538039456529006],
              [-80.9923827208099, 34.537997268871102],
              [-80.992330910031612, 34.538262194295065],
              [-80.991354858554971, 34.538135625568138],
              [-80.991303044352804, 34.538400549648699],
              [-80.990977692780248, 34.53835835817943],
              [-80.990925877242987, 34.538623283001435],
              [-80.9906005249819, 34.538581090508735],
              [-80.990548708066029, 34.538846014269566],
              [-80.989572651287759, 34.538719430154948],
              [-80.989624472360831, 34.538454506799305],
              [-80.988648421658624, 34.538327915167386],
              [-80.988700245443496, 34.538062991315023],
              [-80.988374897612175, 34.538020791868],
              [-80.988478545157008, 34.53749094530874],
              [-80.988153199705053, 34.537448745261614],
              [-80.988256847936228, 34.536918898008217],
              [-80.987931504863823, 34.536876697361166],
              [-80.988242449783471, 34.535287153257705],
              [-80.987917113202499, 34.535244953476507],
              [-80.98796893737908, 34.53498002908119],
              [-80.986667599511563, 34.534811218976394],
              [-80.986615771244857, 34.535076142807121],
              [-80.984989102130513, 34.534865109381052],
              [-80.985040936598949, 34.53460018623808],
              [-80.984390273547419, 34.534515766610212],
              [-80.984338437033969, 34.534780689470871],
              [-80.983687773274809, 34.534696266031752],
              [-80.983739611833258, 34.53443134345337],
              [-80.983414280933104, 34.534389130560641],
              [-80.983466120179699, 34.534124208104785],
              [-80.983140791725432, 34.534081994453018],
              [-80.983192630571025, 34.533817072137651],
              [-80.982216650260114, 34.533690425411145],
              [-80.982268491838781, 34.533425503500652],
              [-80.98064186977966, 34.533214408384488],
              [-80.980590022021445, 34.533479330508328],
              [-80.979939373489202, 34.533394885823789],
              [-80.979887523329879, 34.533659806745192],
              [-80.979236873001369, 34.533575358267385],
              [-80.979288726294612, 34.533310437610446],
              [-80.977662114149979, 34.53309930118786],
              [-80.977610255766109, 34.533364222040063],
              [-80.976634289421128, 34.533237528996189],
              [-80.976686150872155, 34.532972608567796],
              [-80.976035510366515, 34.532888142710455],
              [-80.976087373549547, 34.532623223447153],
              [-80.975436737494064, 34.532538753424433],
              [-80.975488602365814, 34.532273833523611],
              [-80.975163285339278, 34.532231598240493],
              [-80.975267016123851, 34.531701758665228],
              [-80.974941701454881, 34.531659521881153],
              [-80.974993567367875, 34.531394602206767],
              [-80.974668255166549, 34.531352365565198],
              [-80.974720121767305, 34.531087446013409],
              [-80.974394810901245, 34.531045207729534],
              [-80.97444667818975, 34.530780288300356],
              [-80.974121367591536, 34.530738049293589],
              [-80.974173235546203, 34.530473129085671],
              [-80.973847927393834, 34.530430889320094],
              [-80.973899796058078, 34.530165970136103],
              [-80.973249182801425, 34.530081488241002],
              [-80.973301053154145, 34.529816568419584],
              [-80.972325138845164, 34.529689839384808],
              [-80.972377013019241, 34.529424919950678],
              [-80.972051709940942, 34.529382675316057],
              [-80.971999834744494, 34.529647594608946],
              [-80.969397415008103, 34.529309601923501],
              [-80.969345531277227, 34.529574519165948],
              [-80.968694928455918, 34.529490011651902],
              [-80.968044325883952, 34.529405500627377],
              [-80.968096213703987, 34.529140583950202],
              [-80.96614442228794, 34.52888702871531],
              [-80.966196316995749, 34.528622112849668],
              [-80.96489513421227, 34.528453058336886],
              [-80.96494703156354, 34.528188142134525],
              [-80.964296444245946, 34.528103610769612],
              [-80.964348344396015, 34.527838694813468],
              [-80.963697761529119, 34.527754159283788],
              [-80.963749663388938, 34.527489243591759],
              [-80.962773793554803, 34.52736243322218],
              [-80.962448504649771, 34.527320161629319],
              [-80.962500410263573, 34.527055246484146],
              [-80.962175122715351, 34.527012974150566],
              [-80.962227029016589, 34.526748059128145],
              [-80.961576456947313, 34.52666351119634],
              [-80.96168027370166, 34.52613368074212],
              [-80.961354990224095, 34.526091406186481],
              [-80.961406898566011, 34.52582649063114],
              [-80.961081616445412, 34.525784215334873],
              [-80.961133526563643, 34.525519299884543],
              [-80.961185435279688, 34.525254385334605],
              [-80.960860155516556, 34.525212108537787],
              [-80.960912065987486, 34.524947193191586],
              [-80.95993623290488, 34.524820359717999],
              [-80.95988431936766, 34.525085274639977],
              [-80.959559042032609, 34.525042994297131],
              [-80.959507127138522, 34.525307909059208],
              [-80.95918184913765, 34.525265628594234],
              [-80.959129932865181, 34.525530542294909],
              [-80.958804653087981, 34.525488260824439],
              [-80.958752736547638, 34.525753174347294],
              [-80.958427456083129, 34.525710891853272],
              [-80.958323617797276, 34.52624071949726],
              [-80.957998335601872, 34.526198434937058],
              [-80.957894495021534, 34.526728262205964],
              [-80.957569211116748, 34.526685976480856],
              [-80.957465366063801, 34.527215803410201],
              [-80.957140080449577, 34.527173516520179],
              [-80.957088156387982, 34.527438429364821],
              [-80.956762870086479, 34.527396141451291],
              [-80.956710943578926, 34.527661054153704],
              [-80.955084512026602, 34.527449602450787],
              [-80.955136443644861, 34.527184690455584],
              [-80.95481115935263, 34.527142397249754],
              [-80.954915022539566, 34.526612573504231],
              [-80.954589740626446, 34.526570279699278],
              [-80.954693604496555, 34.526040455260805],
              [-80.954368326051622, 34.525998160838945],
              [-80.954420257972572, 34.525733249192783],
              [-80.95409498088452, 34.525690954030267],
              [-80.954198846833563, 34.525161130045916],
              [-80.953873571035516, 34.525118834302113],
              [-80.95392550506358, 34.524853921963974],
              [-80.953274957605316, 34.524769328095509],
              [-80.953326893342464, 34.524504416021607],
              [-80.952025807600592, 34.524335217347343],
              [-80.952077747091039, 34.524070305820807],
              [-80.951427208828875, 34.523985701916388],
              [-80.951479150028405, 34.52372079065416],
              [-80.950178081590181, 34.523551571926014],
              [-80.950126136302643, 34.523816482622181],
              [-80.9491503353984, 34.523689557764008],
              [-80.949202284840894, 34.523424647474613],
              [-80.948551754630813, 34.523340027075939],
              [-80.948499803144372, 34.523604937082389],
              [-80.948174537530562, 34.523562625869239],
              [-80.948122585776119, 34.523827535697819],
              [-80.947146787836004, 34.52370059453925],
              [-80.947094831591599, 34.523965503942414],
              [-80.946769565273911, 34.523923188918239],
              [-80.94682152254029, 34.523658279656651],
              [-80.946496257558181, 34.523615962990768],
              [-80.946600173108379, 34.523086143793556],
              [-80.94627491161566, 34.523043827412387],
              [-80.946378826780489, 34.522514008441519],
              [-80.94605356764535, 34.522471690560117],
              [-80.946157484602864, 34.521941871780442],
              [-80.945832226757659, 34.52189955331783],
              [-80.945884185745129, 34.521634643591007],
              [-80.945558930345868, 34.52159232437026],
              [-80.945662849380739, 34.521062506045403],
              [-80.945337596360332, 34.521020186225833],
              [-80.945441516056206, 34.520490366307278],
              [-80.945116265435885, 34.520448046790186],
              [-80.945168225792457, 34.520183136493991],
              [-80.944192478943577, 34.520056170371731],
              [-80.944296405893041, 34.519526351456712],
              [-80.943971159657281, 34.51948402793527],
              [-80.944075086221147, 34.518954209247021],
              [-80.943749842364156, 34.518911885126734],
              [-80.943801807243631, 34.518646975428098],
              [-80.943151323667337, 34.51856232480722],
              [-80.943203289166462, 34.518297415390876],
              [-80.942878048358224, 34.518255088908006],
              [-80.94293001563301, 34.517990179597099],
              [-80.942604776160465, 34.517947851472577],
              [-80.942656744121962, 34.51768294228475],
              [-80.942006269335849, 34.517598284556854],
              [-80.941954300440997, 34.51786319434521],
              [-80.939677642627046, 34.517566863067401],
              [-80.939625665134358, 34.517831770962445],
              [-80.939300429101422, 34.517789434761127],
              [-80.939352407615885, 34.51752452700768],
              [-80.9390271729186, 34.517482189164731],
              [-80.938975193382376, 34.517747096776574],
              [-80.938649956930746, 34.517704758829097],
              [-80.938597976037684, 34.517969666280848],
              [-80.938272739967104, 34.517927326390918],
              [-80.938220757717104, 34.518192233682555],
              [-80.937570283449119, 34.518107550991473],
              [-80.937518298820393, 34.518372457981378],
              [-80.936867823870912, 34.518287772380823],
              [-80.936815835774397, 34.518552679086582],
              [-80.936490598314606, 34.518510334362368],
              [-80.936438608861081, 34.518775240907978],
              [-80.935788131814689, 34.518690548548285],
              [-80.935736141071331, 34.518955454774542],
              [-80.935410901484659, 34.518913107138992],
              [-80.935358908274182, 34.519178012321362],
              [-80.934708429173313, 34.519093315005087],
              [-80.934656434672902, 34.519358219868067],
              [-80.934331194048553, 34.519315869303583],
              [-80.934279197123345, 34.519580774925259],
              [-80.933953955812498, 34.519538423337224],
              [-80.933693960942378, 34.520862945952508],
              [-80.93336871485775, 34.52082059277442],
              [-80.933212709757655, 34.521615305713375],
              [-80.932887460942979, 34.521572951228272],
              [-80.932783453438404, 34.522102758582605],
              [-80.932458202915413, 34.522060402932325],
              [-80.932406198172188, 34.522325305980459],
              [-80.932080946962699, 34.522282949306621],
              [-80.932132952727756, 34.522018046400142],
              [-80.931807702875361, 34.521975688986039],
              [-80.931911716510868, 34.521445882481629],
              [-80.931586469037541, 34.521403524469029],
              [-80.931690484442967, 34.520873717254766],
              [-80.931365239348494, 34.520831358643505],
              [-80.931417246481146, 34.520566455167753],
              [-80.93044151845335, 34.520439374432478],
              [-80.930389507166481, 34.520704277500805],
              [-80.930064263392367, 34.520661914460632],
              [-80.930012250748348, 34.52092681736864],
              [-80.929036519525411, 34.520799725218133],
              [-80.928984503459489, 34.521064626781076],
              [-80.928334015974031, 34.520979893982044],
              [-80.92828199752914, 34.521244795243092],
              [-80.927956752723262, 34.52120242738787],
              [-80.927904732942338, 34.521467329389964],
              [-80.924327054117882, 34.521001217757529],
              [-80.924379086227177, 34.520736317296773],
              [-80.923728606069815, 34.520651558620301],
              [-80.923780639887084, 34.520386658424421],
              [-80.923455401867287, 34.52034427744568],
              [-80.923507436370755, 34.520079377373023],
              [-80.923182198640433, 34.520036996572969],
              [-80.923234234918965, 34.519772096606005],
              [-80.922583763555068, 34.519687330823629],
              [-80.922635800452625, 34.519422431139013],
              [-80.91905824914943, 34.518956156845505],
              [-80.919006199924453, 34.51922105498803],
              [-80.918355738374288, 34.519136266260105],
              [-80.918303686770372, 34.519401164100493],
              [-80.917978455477055, 34.51935876827195],
              [-80.91792640251586, 34.519623665951954],
              [-80.91727593887164, 34.519538870464459],
              [-80.917223883531577, 34.519803767842269],
              [-80.915597725197259, 34.519591763432985],
              [-80.915545663324266, 34.519856660100459],
              [-80.915220431651107, 34.519814256791797],
              [-80.915168369509757, 34.520079153281614],
              [-80.914843137129623, 34.520036748047971],
              [-80.914791072541945, 34.520301644394685],
              [-80.914465839475966, 34.520259238137363],
              [-80.914413773530811, 34.520524134323658],
              [-80.913763306383572, 34.520439319781161],
              [-80.913711238059392, 34.520704215665162],
              [-80.913386003957243, 34.52066180647865],
              [-80.913438073303098, 34.520396910736487],
              [-80.913112840579245, 34.520354501711317],
              [-80.910836220808932, 34.520057609336902],
              [-80.910784142180077, 34.520322503060299],
              [-80.911109373596119, 34.520364919301528],
              [-80.910796905319614, 34.521954284736402],
              [-80.911122144269498, 34.521996700028247],
              [-80.910965908631482, 34.522791381567416],
              [-80.910640666595668, 34.522748964948683],
              [-80.910380261636064, 34.524073431300693],
              [-80.910055014848481, 34.524031013992158],
              [-80.90995084822751, 34.524560799040174],
              [-80.909625599732308, 34.524518380566043],
              [-80.909573514351777, 34.524783272928865],
              [-80.909248265149742, 34.524740852529561],
              [-80.909144092426658, 34.525270636897851],
              [-80.908818841517018, 34.52522821533303],
              [-80.908766753085516, 34.525493107355828],
              [-80.908441501489918, 34.525450684767236],
              [-80.908389411700696, 34.525715576629402],
              [-80.908064159419339, 34.52567315301706],
              [-80.907959976789812, 34.526202936401425],
              [-80.90763472280085, 34.526160511623509],
              [-80.907582628871381, 34.526425403162989],
              [-80.906606865854599, 34.52629812311185],
              [-80.906554769613081, 34.526563014189627],
              [-80.90590425948227, 34.526478156146389],
              [-80.905852160840311, 34.526743046020179],
              [-80.905526904712602, 34.526700615542424],
              [-80.905474804733828, 34.526965506156955],
              [-80.905149549009366, 34.526923074638106],
              [-80.905097446563019, 34.527187964207982],
              [-80.90477219015267, 34.527145531665354],
              [-80.904720086348505, 34.527410421074471],
              [-80.904394829252468, 34.527367987508008],
              [-80.904342724090554, 34.527632876756435],
              [-80.904017465219667, 34.527590442183445],
              [-80.903965359789154, 34.527855331253839],
              [-80.903640100232423, 34.527812895657071],
              [-80.903587992355057, 34.528077784584063],
              [-80.903262733201728, 34.528035347946158],
              [-80.903210623966416, 34.52830023671239],
              [-80.90256010351429, 34.528215359622997],
              [-80.902507991899483, 34.528480248086595],
              [-80.901206950982257, 34.528310484559917],
              [-80.901154833834397, 34.528575371552876],
              [-80.900179054133545, 34.528448038312128],
              [-80.900126933605208, 34.528712925761752],
              [-80.899151153863755, 34.528585584158009],
              [-80.899203276368297, 34.528320697151429],
              [-80.898227502717049, 34.52819334803646],
              [-80.898175376058191, 34.528458234634392],
              [-80.896874345491184, 34.5282884222934],
              [-80.896822214388507, 34.528553307403271],
              [-80.896171698533863, 34.528468395666081],
              [-80.896119565072269, 34.528733281374485],
              [-80.895794306072574, 34.528690823599035],
              [-80.895690036076687, 34.529220593774014],
              [-80.895039516087877, 34.529135674992226],
              [-80.894987377463522, 34.529400560235331],
              [-80.894662117496182, 34.529358099370988],
              [-80.894714256074067, 34.529093215188418],
              [-80.894388997464759, 34.529050753584201],
              [-80.89433685677605, 34.529315637641965],
              [-80.893361078831958, 34.529188248030472],
              [-80.893256790315448, 34.5297180152374],
              [-80.892931528917529, 34.529675550598107],
              [-80.892879384222269, 34.529940434014122],
              [-80.892554122118099, 34.529897967449536],
              [-80.892501974975744, 34.530162850721844],
              [-80.890875666568334, 34.529950503926315],
              [-80.890823513981019, 34.530215386469493],
              [-80.889522469657109, 34.53004549258398],
              [-80.88947031155756, 34.530310374557438],
              [-80.888819789349313, 34.530225421137921],
              [-80.888169268507255, 34.530140465092153],
              [-80.888117105984392, 34.530405346478453],
              [-80.887791845590726, 34.530362866982109],
              [-80.887739681689069, 34.530627747306113],
              [-80.88708915877821, 34.530542785400812],
              [-80.887036992517537, 34.53080766632312],
              [-80.886711729979751, 34.53076518301279],
              [-80.886659562360876, 34.531030063774104],
              [-80.886334299158463, 34.530987580341197],
              [-80.886282130160765, 34.531252460040172],
              [-80.88595686736214, 34.531209975566121],
              [-80.88590469700631, 34.531474855104115],
              [-80.885579432433261, 34.531432369623246],
              [-80.8855272607193, 34.531697249000295],
              [-80.885201995460932, 34.531654762495386],
              [-80.885149821299777, 34.531919641728464],
              [-80.884824556445139, 34.531877154182602],
              [-80.884615854527198, 34.532936670322762],
              [-80.884941124557841, 34.532979158419948],
              [-80.88488894980361, 34.533244037541081],
              [-80.885214221192371, 34.533286524898372],
              [-80.88495334526344, 34.534610919161118],
              [-80.885278622097672, 34.534653406346841],
              [-80.88512209574877, 34.535448041476819],
              [-80.884796814759952, 34.53540555388193],
              [-80.884483745715954, 34.536994820989896],
              [-80.884158458932575, 34.536952331660686],
              [-80.884106278000615, 34.537217208710885],
              [-80.883780990531875, 34.537174718357633],
              [-80.883728809330748, 34.537439595229564],
              [-80.8830782333591, 34.537354611593024],
              [-80.88297386368157, 34.537884364745537],
              [-80.882648574156818, 34.537841871320211],
              [-80.882492014050868, 34.538636500479569],
              [-80.88216672179702, 34.538594005745907],
              [-80.882114532290174, 34.538858881067796],
              [-80.881138654461637, 34.538731390247442],
              [-80.881086461573233, 34.538996266025251],
              [-80.880110583708358, 34.538868766840764],
              [-80.880058387417719, 34.539133642173155],
              [-80.879733094433661, 34.539091139938037],
              [-80.879680896784549, 34.53935601510927],
              [-80.879355603115229, 34.539313511850054],
              [-80.879303405196936, 34.539578386842884],
              [-80.878652815755714, 34.539493378312706],
              [-80.878600614347192, 34.539758252117892],
              [-80.878275319664539, 34.539715746829835],
              [-80.878223116897502, 34.539980620473763],
              [-80.877897821508995, 34.539938113260249],
              [-80.877741208142723, 34.540732734552137],
              [-80.878391806456278, 34.540817748031479],
              [-80.878339603364438, 34.541082621740891],
              [-80.878664904582365, 34.541125126840498],
              [-80.878612702175886, 34.54139000067287],
              [-80.878287399936028, 34.541347495431218],
              [-80.878235196150257, 34.541612368200923],
              [-80.877909894314385, 34.541569861917928],
              [-80.877857689190662, 34.541834735427756],
              [-80.874929970872998, 34.541452132623689],
              [-80.874877756215426, 34.541717004835],
              [-80.874552454592902, 34.541674488563537],
              [-80.874500237487354, 34.541939360630508],
              [-80.874174936268943, 34.541896843317872],
              [-80.874122717784161, 34.542161714322162],
              [-80.873797414811889, 34.542119196903705],
              [-80.873745196057712, 34.542384067729593],
              [-80.873094589038558, 34.542299028159789],
              [-80.87304236683508, 34.542563899600474],
              [-80.872717062808761, 34.542521378350351],
              [-80.872664839226033, 34.542786248728333],
              [-80.872339535603857, 34.542743726437038],
              [-80.872391760208473, 34.542478856201171],
              [-80.872066456855634, 34.542436333187048],
              [-80.871857552349439, 34.54349581434758],
              [-80.871532245245845, 34.543453289882741],
              [-80.871323330132512, 34.544512768381011],
              [-80.87099801927792, 34.544470242465472],
              [-80.870736861844719, 34.545794587879136],
              [-80.871062178898413, 34.545837114488627],
              [-80.871009946761347, 34.546101983305313],
              [-80.870684629775084, 34.546059456536653],
              [-80.870527929345684, 34.546854061526105],
              [-80.870202609650846, 34.546811534350169],
              [-80.870150374101826, 34.547076401903901],
              [-80.869825052632564, 34.547033873720714],
              [-80.869772816813978, 34.54729874109595],
              [-80.868471531225097, 34.54712861535166],
              [-80.868419289912836, 34.547393483057185],
              [-80.868093968063562, 34.547350949278027],
              [-80.868041725392359, 34.547615816821931],
              [-80.867716403947355, 34.547573282001522],
              [-80.867559670805235, 34.548367882287863],
              [-80.867884996405692, 34.5484104175179],
              [-80.867780507989877, 34.548940150628802],
              [-80.867455180345488, 34.54889761511437],
              [-80.867402933520864, 34.549162481515488],
              [-80.867077605191398, 34.549119944976781],
              [-80.867025358097052, 34.549384811199438],
              [-80.866374700404847, 34.54929973519129],
              [-80.866322450909081, 34.549564600208797],
              [-80.865671791431382, 34.549479520404965],
              [-80.86561953957505, 34.549744386019903],
              [-80.865294209864388, 34.54970184464419],
              [-80.865346462742849, 34.54943697917146],
              [-80.865021134391128, 34.549394437055923],
              [-80.864968879401275, 34.549659302403334],
              [-80.864318221644538, 34.54957421434024],
              [-80.864161449589716, 34.55036880849449],
              [-80.864486781355524, 34.550411352943094],
              [-80.864330009313065, 34.551205946447979],
              [-80.864655345571336, 34.551248490424314],
              [-80.864341798457517, 34.55283767505864],
              [-80.86401645606675, 34.55279513022888],
              [-80.863964195652912, 34.553059994280112],
              [-80.863638852577097, 34.553017448426054],
              [-80.86358659078374, 34.553282311414208],
              [-80.863261247022805, 34.553239764535796],
              [-80.863208984980034, 34.553504628246664],
              [-80.862883640534164, 34.553462080343976],
              [-80.862831376022427, 34.553726943008634],
              [-80.862506030891552, 34.553684394081586],
              [-80.86240149772307, 34.554214119084975],
              [-80.862076150885073, 34.554171568991237],
              [-80.862023883317704, 34.554436431313043],
              [-80.861698535794602, 34.554393880194958],
              [-80.861646266868107, 34.554658742354924],
              [-80.861320917570666, 34.554616190229353],
              [-80.861268647284831, 34.554881052227572],
              [-80.860943298371396, 34.554838498159391],
              [-80.86089102563686, 34.55510336001273],
              [-80.860240326818015, 34.555018250748219],
              [-80.860188051681746, 34.55528311139615],
              [-80.860838753654704, 34.555368221829688],
              [-80.860786480225443, 34.555633082742588],
              [-80.861437185591384, 34.555718189932151],
              [-80.86138491495872, 34.555983051093207],
              [-80.861710268614644, 34.556025603064825],
              [-80.861448914436224, 34.557349910206106],
              [-80.86177427354005, 34.557392462006845],
              [-80.861669731980697, 34.557922184643623],
              [-80.861019007842444, 34.557837079624811],
              [-80.860966733423112, 34.55810194064621],
              [-80.859990647767347, 34.557974276058125],
              [-80.859781535500574, 34.55903371642102],
              [-80.860106901500643, 34.5590762727293],
              [-80.860054623330555, 34.559341132466869],
              [-80.860379990690049, 34.55938368803524],
              [-80.860327714314991, 34.559648548779819],
              [-80.86065308303391, 34.55969110360828],
              [-80.860600806234004, 34.559955963591122],
              [-80.860926177401737, 34.55999851766267],
              [-80.86071707198812, 34.56105795704832],
              [-80.861042447582292, 34.561100510806753],
              [-80.86099017114087, 34.561365370975821],
              [-80.861315548094439, 34.561407923994331],
              [-80.861263273407289, 34.561672783367811],
              [-80.861588650630807, 34.561715335663251],
              [-80.861536376628678, 34.561980195159236],
              [-80.86186175630128, 34.562022746697828],
              [-80.861757208261096, 34.562552466833573],
              [-80.862082590315438, 34.562595017774399],
              [-80.861768944154534, 34.564184174957049],
              [-80.862094332680172, 34.564226725869084],
              [-80.862042058689937, 34.564491584713181],
              [-80.861716668072887, 34.564449034577137],
              [-80.861612115967034, 34.564978752839991],
              [-80.861286724711576, 34.564936200618995],
              [-80.861129890401529, 34.56573077699624],
              [-80.860804495326576, 34.56568822348315],
              [-80.860752215156367, 34.565953081798973],
              [-80.860426819396238, 34.565910527261387],
              [-80.860374538956066, 34.566175385398388],
              [-80.860049142531224, 34.566132830737544],
              [-80.859996859641726, 34.566397688729417],
              [-80.859020670351782, 34.566270016305886],
              [-80.858811522042615, 34.567329445483836],
              [-80.859136922588021, 34.567372004404014],
              [-80.859084635947042, 34.567636861111119],
              [-80.859410037852257, 34.567679419291274],
              [-80.859253178993171, 34.568473990621726],
              [-80.858927772931054, 34.568431432031645],
              [-80.85877090794871, 34.569226001855561],
              [-80.858445499156517, 34.569183441956454],
              [-80.858497789953731, 34.568918586395043],
              [-80.858172382521303, 34.568876025755884],
              [-80.858224673983329, 34.56861116941549],
              [-80.857899269000285, 34.568568608019454],
              [-80.857951560057714, 34.568303751818441],
              [-80.857300753148465, 34.568218626664041],
              [-80.857353045913428, 34.567953770727755],
              [-80.855726039675972, 34.567740944014545],
              [-80.855673740709321, 34.568005799256341],
              [-80.8543721376858, 34.567835520714681],
              [-80.854267530561046, 34.568365230000374],
              [-80.853942128603848, 34.568322657874695],
              [-80.85388982351293, 34.568587512345523],
              [-80.853564420870725, 34.568544939195284],
              [-80.853407500486028, 34.569339501160322],
              [-80.853082094024359, 34.569296926717712],
              [-80.853029785539036, 34.569561780824451],
              [-80.852378973762299, 34.569476628974108],
              [-80.85232666178473, 34.56974148189186],
              [-80.852001255380145, 34.569698904500946],
              [-80.851792002225366, 34.570758317173087],
              [-80.852117412719693, 34.570800895133267],
              [-80.851960472892003, 34.571595453495028],
              [-80.852285886791179, 34.57163803099985],
              [-80.852181260233749, 34.572167736765081],
              [-80.851855844289531, 34.572125158975638],
              [-80.851803528937339, 34.572390011694424],
              [-80.851478111198205, 34.572347431995794],
              [-80.851425794485948, 34.57261228455242],
              [-80.85044954137669, 34.572484542422835],
              [-80.850397221239163, 34.572749393631199],
              [-80.850071802825383, 34.572706811020012],
              [-80.849967159513199, 34.573236513993507],
              [-80.849641739391942, 34.573193930215339],
              [-80.849118491142207, 34.575842437070214],
              [-80.848793061133264, 34.575799850986279],
              [-80.848479088230221, 34.577388949342712],
              [-80.848153651333732, 34.577346361539242],
              [-80.848048988563207, 34.577876059350203],
              [-80.848374427504879, 34.577918647438388],
              [-80.848269765449416, 34.578448346355152],
              [-80.848595206773908, 34.578490933845629],
              [-80.848542876787022, 34.57875578250647],
              [-80.848438214730905, 34.57928548068616],
              [-80.848763659460857, 34.579328067721264],
              [-80.848606667426949, 34.580122613906774],
              [-80.848281219629058, 34.580080026444648],
              [-80.848124220398986, 34.58087457203893],
              [-80.84779876987065, 34.580831983267394],
              [-80.847694099236961, 34.581361679054844],
              [-80.847368647001105, 34.581319089116221],
              [-80.847054621929743, 34.582908174222879],
              [-80.846729162805502, 34.582865582564551],
              [-80.846676822900747, 34.583130429571824],
              [-80.847002284137517, 34.583173021355748],
              [-80.846845265445012, 34.583967561782039],
              [-80.847170728997725, 34.584010153127338],
              [-80.84711839085729, 34.584274999420444],
              [-80.847443855770507, 34.584317590025648],
              [-80.847391517245597, 34.584582437358939],
              [-80.847716984609079, 34.584625027207245],
              [-80.847612308551106, 34.585154720295307],
              [-80.847937778297791, 34.585197309545791],
              [-80.847885440785021, 34.585462156201892],
              [-80.847559971105397, 34.585419566792261],
              [-80.847507632232265, 34.585684413285726],
              [-80.84685668968109, 34.585599232469292],
              [-80.846804349514471, 34.585864078641151],
              [-80.846478877712485, 34.58582148631622],
              [-80.846426535095631, 34.586086332342191],
              [-80.846101062608483, 34.586043738992444],
              [-80.846048718631138, 34.586308584855821],
              [-80.845397772624608, 34.586223395224209],
              [-80.845345426263862, 34.5864882407827],
              [-80.845019952744408, 34.586445644500849],
              [-80.844967605023143, 34.586710489896674],
              [-80.844642130818642, 34.586667892589958],
              [-80.844589781736929, 34.586932737823183],
              [-80.843938832295592, 34.586847540277695],
              [-80.843886479720354, 34.587112384321316],
              [-80.843561005573392, 34.587069784065719],
              [-80.843508651657928, 34.587334628848097],
              [-80.843183175736087, 34.587292027584404],
              [-80.843130820439711, 34.587556871302738],
              [-80.842479867563824, 34.587471665843125],
              [-80.84242751097392, 34.587736509239697],
              [-80.841776557403648, 34.587651299965422],
              [-80.841828916039049, 34.587386456853629],
              [-80.839876070622168, 34.58713080688635],
              [-80.839823704780372, 34.587395650061843],
              [-80.839172756595289, 34.58731042576666],
              [-80.839120388349968, 34.587575267735794],
              [-80.83879491375157, 34.587532654572776],
              [-80.838742543055645, 34.587797496395808],
              [-80.837440644999347, 34.587627032529952],
              [-80.83749301869635, 34.587362191293209],
              [-80.837167546877268, 34.587319573701258],
              [-80.83721992123904, 34.587054731685292],
              [-80.836894450780548, 34.587012113353232],
              [-80.836946825827155, 34.586747271459402],
              [-80.836295886859418, 34.58666203154813],
              [-80.836348264703432, 34.58639718990225],
              [-80.83602279621438, 34.586354569224412],
              [-80.835970417327459, 34.586619409826454],
              [-80.835319480387412, 34.58653416462051],
              [-80.835267099117232, 34.586799004917452],
              [-80.834290693708368, 34.586671129603822],
              [-80.834238307962352, 34.586935970371066],
              [-80.833587370290871, 34.586850714869499],
              [-80.833639756992469, 34.586585874403831],
              [-80.833314290251479, 34.586543246364876],
              [-80.833261902506962, 34.586808085786771],
              [-80.831634565388285, 34.586594927186262],
              [-80.831582171122591, 34.586859766793545],
              [-80.831256704344042, 34.586817132273566],
              [-80.83130909963252, 34.586552292808854],
              [-80.83098363312493, 34.586509657565678],
              [-80.831036029118096, 34.586244819124431],
              [-80.829083247626841, 34.5859889881201],
              [-80.829135649418035, 34.585724150513606],
              [-80.828159267394128, 34.585596223509683],
              [-80.828106861445235, 34.585861060705312],
              [-80.826479559714215, 34.58564783037081],
              [-80.826427148334048, 34.585912667734917],
              [-80.825450768268979, 34.585784717796606],
              [-80.825503182737179, 34.585519881761542],
              [-80.824852266416499, 34.585434577074899],
              [-80.824904682571642, 34.585169740403209],
              [-80.824253769668886, 34.58508443337346],
              [-80.824306187531079, 34.584819596966589],
              [-80.823655278026195, 34.584734286692317],
              [-80.82370769759541, 34.58446945055033],
              [-80.822731340002051, 34.584341477580118],
              [-80.822678917365096, 34.584606313294422],
              [-80.821702559747749, 34.58447833195514],
              [-80.821650132615034, 34.584743167238074],
              [-80.82099922754611, 34.584657841642638],
              [-80.820946798029908, 34.58492267662006],
              [-80.820621344435324, 34.584880012364479],
              [-80.820411621057559, 34.585939350566655],
              [-80.820737078742596, 34.58598201539256],
              [-80.820684647535131, 34.586246850268566],
              [-80.821010106560948, 34.586289513453224],
              [-80.820905246246426, 34.586819182511483],
              [-80.820579785155388, 34.586776518140191],
              [-80.820527352913231, 34.587041352053888],
              [-80.819225506751422, 34.586870685191016],
              [-80.819277943084117, 34.586605851847686],
              [-80.818952483684384, 34.586563183064442],
              [-80.819004920701403, 34.586298349843453],
              [-80.818679462662658, 34.586255680320392],
              [-80.818731900343906, 34.585990846320257],
              [-80.81808098732651, 34.585905504912184],
              [-80.818133426714624, 34.585640671176968],
              [-80.817807972825818, 34.585597999275464],
              [-80.817860412898284, 34.585333165662497],
              [-80.817534959280422, 34.585290493037689],
              [-80.817587400037127, 34.585025659547114],
              [-80.817261948870083, 34.584982986166061],
              [-80.817314390310926, 34.58471815279772],
              [-80.816988939414912, 34.58467547869347],
              [-80.81704138262991, 34.584410645430921],
              [-80.814437800101771, 34.584069218301124],
              [-80.814490251158503, 34.583804386159343],
              [-80.813839360955129, 34.583719020838693],
              [-80.813891813718485, 34.583454188961902],
              [-80.813240927983472, 34.583368819479283],
              [-80.81329338245348, 34.583103987867595],
              [-80.812317058386597, 34.582975928371944],
              [-80.812264600849147, 34.583240759555686],
              [-80.811288277830513, 34.58311269077327],
              [-80.811235816907114, 34.583377522410153],
              [-80.810910375555309, 34.583334830908626],
              [-80.810962837521046, 34.583070000315807],
              [-80.809986518563605, 34.582941920945458],
              [-80.810038984328116, 34.582677089842633],
              [-80.809713546375121, 34.582634395097145],
              [-80.809818477863672, 34.582104734033834],
              [-80.809493042293923, 34.582062038691461],
              [-80.809597974453695, 34.581532376931037],
              [-80.80927254235705, 34.581489680975317],
              [-80.809325008406702, 34.581224850215605],
              [-80.808999577670932, 34.581182153520324],
              [-80.809104511889529, 34.580652492209182],
              [-80.808453654433066, 34.58056709675877],
              [-80.808506123604516, 34.580302265448708],
              [-80.808180696951311, 34.5802595665346],
              [-80.808233165736638, 34.579994736264794],
              [-80.80758231547054, 34.579909335173774],
              [-80.807256890855157, 34.579866633755472],
              [-80.807204417912871, 34.580131463613654],
              [-80.806878992613619, 34.580088761170437],
              [-80.806826517220784, 34.580353590882083],
              [-80.806175665573249, 34.580268182162023],
              [-80.806123188886872, 34.580533011551516],
              [-80.805797762558456, 34.580490306176017],
              [-80.805745283421473, 34.580755135419054],
              [-80.805094429715595, 34.580669720834422],
              [-80.80514690980759, 34.580404891893245],
              [-80.804496059500622, 34.580319474064986],
              [-80.804548541278862, 34.580054644487753],
              [-80.804223117665501, 34.580011934843625],
              [-80.80427560012761, 34.579747105388812],
              [-80.803299334346235, 34.579618969788328],
              [-80.803246848817224, 34.579883798814926],
              [-80.802270581906626, 34.579755653960852],
              [-80.802218092971955, 34.580020482538885],
              [-80.801892670686556, 34.579977765674791],
              [-80.801787687537697, 34.580507420714667],
              [-80.801462262456482, 34.580464702699182],
              [-80.801409769351835, 34.580729530046042],
              [-80.801084344676624, 34.580686810989071],
              [-80.801031850211004, 34.580951638172877],
              [-80.800380999851782, 34.580866198028112],
              [-80.800433496362061, 34.580601371129887],
              [-80.798806380362933, 34.580387753805169],
              [-80.798858882735914, 34.580122927584171],
              [-80.798533461772635, 34.580080200710697],
              [-80.798638466457362, 34.579550548509985],
              [-80.798313047897082, 34.579507821941093],
              [-80.798418053251993, 34.578978169043545],
              [-80.798092638164718, 34.578935441861688],
              [-80.798197644209424, 34.578405789168947],
              [-80.797872230395768, 34.578363060505453],
              [-80.798029740524512, 34.577568581284119],
              [-80.797704330116261, 34.57752585216685],
              [-80.797809337933117, 34.576996198925414],
              [-80.797483929927836, 34.576953470112791],
              [-80.79753643435042, 34.576688643604662],
              [-80.797211028776047, 34.576645913134989],
              [-80.797158523331262, 34.576910739500363],
              [-80.796182305613044, 34.57678254417236],
              [-80.796129796762955, 34.577047370089069],
              [-80.795804390518697, 34.577004636706299],
              [-80.795751880307577, 34.577269462460073],
              [-80.795426472290089, 34.577226728068553],
              [-80.795373960718123, 34.577491553659229],
              [-80.794723145795004, 34.577406080108666],
              [-80.794670631839807, 34.577670905393475],
              [-80.794345222794107, 34.577628168069772],
              [-80.794292707478021, 34.577892993191533],
              [-80.793967298818842, 34.577850253925078],
              [-80.793641889408761, 34.577807513792671],
              [-80.793589371709501, 34.578072338608635],
              [-80.792938552970597, 34.577986856296555],
              [-80.79288603288812, 34.578251680806602],
              [-80.792560623539728, 34.578208937725627],
              [-80.792508101006391, 34.578473762088898],
              [-80.792182690974599, 34.578431017982844],
              [-80.792025119325771, 34.579225488703507],
              [-80.791699705495944, 34.579182744204324],
              [-80.791594653251764, 34.579712390374432],
              [-80.791269238786498, 34.579669643789714],
              [-80.791216711133956, 34.57993446670153],
              [-80.790240464544524, 34.579806222159299],
              [-80.790187933486422, 34.580071044622223],
              [-80.789537102446502, 34.579985543858903],
              [-80.789484567915281, 34.580250366032054],
              [-80.789159152416374, 34.580207613725463],
              [-80.789106616524023, 34.580472435735452],
              [-80.788781200341887, 34.580429682403668],
              [-80.788728663088307, 34.580694504250488],
              [-80.788403245133011, 34.580651749909968],
              [-80.788350707588535, 34.580916570675996],
              [-80.788025288949939, 34.580873815310241],
              [-80.787920209749117, 34.581403458314696],
              [-80.78759478940492, 34.581360701780973],
              [-80.787542248799198, 34.581625522200333],
              [-80.787216827771587, 34.581582764641453],
              [-80.787164284734487, 34.581847585815282],
              [-80.786838863023661, 34.581804827231281],
              [-80.786786318605635, 34.582069647340447],
              [-80.786460896211508, 34.582026887731402],
              [-80.786408351522169, 34.582291707661113],
              [-80.785432082217, 34.582163423127831],
              [-80.785379533032085, 34.5824282426248],
              [-80.784403264800744, 34.582299949706403],
              [-80.784350712209971, 34.582564768754239],
              [-80.783699866372984, 34.582479235444929],
              [-80.783374443943458, 34.582436466565603],
              [-80.783321887947011, 34.582701285164305],
              [-80.782671041009792, 34.582615746292021],
              [-80.7826184826298, 34.582880564584521],
              [-80.782293059172886, 34.582837792772629],
              [-80.78234561857515, 34.582572974623034],
              [-80.782020196499118, 34.582530202973089],
              [-80.781967636074626, 34.582795020979802],
              [-80.78099136879456, 34.582666699406708],
              [-80.781043932285868, 34.582401881828794],
              [-80.780718511546482, 34.582359105748651],
              [-80.780771076810765, 34.582094288277226],
              [-80.780445657452319, 34.582051512359101],
              [-80.780498223399633, 34.58178669501028],
              [-80.779847388795091, 34.581701138994504],
              [-80.779899956447792, 34.581436321911191],
              [-80.77957454015106, 34.58139354363243],
              [-80.779627108467253, 34.581128725770377],
              [-80.779301693512281, 34.581085945850951],
              [-80.77935426362086, 34.580821128996803],
              [-80.779028850027174, 34.580778348338121],
              [-80.779081419729039, 34.580513531622699],
              [-80.778430596693156, 34.580427967928536],
              [-80.778483168080712, 34.580163150577427],
              [-80.777832349554942, 34.580077584525668],
              [-80.777884922647686, 34.579812767440181],
              [-80.777559514905874, 34.579769982783326],
              [-80.777612088681622, 34.579505165820436],
              [-80.777286682301082, 34.579462380424381],
              [-80.77733925675993, 34.579197563584252],
              [-80.777013851740662, 34.57915477744897],
              [-80.777066427972159, 34.578889960715337],
              [-80.776741024314063, 34.578847173840877],
              [-80.776793600138859, 34.578582357246148],
              [-80.776468197842107, 34.578539569632504],
              [-80.776520775439536, 34.578274753144328],
              [-80.776195374504027, 34.578231964791541],
              [-80.776300529616364, 34.577702331155052],
              [-80.775975131044774, 34.577659541304705],
              [-80.775922552100866, 34.577924358448811],
              [-80.775597153955516, 34.577881568458551],
              [-80.775544573630981, 34.57814638453808],
              [-80.775219173712856, 34.578103593538792],
              [-80.775114011111341, 34.578633226236171],
              [-80.774788609488212, 34.578590434068701],
              [-80.774683442376528, 34.579120065513649],
              [-80.774358039048479, 34.579077272178012],
              [-80.774252868535712, 34.579606903255659],
              [-80.773927463502645, 34.57956410875191],
              [-80.77376970210365, 34.580358553885681],
              [-80.773444294343463, 34.580315758070626],
              [-80.773391705496934, 34.580580572330689],
              [-80.773066295964043, 34.580537775506464],
              [-80.773013705775554, 34.580802590504526],
              [-80.772688296649548, 34.580759792639086],
              [-80.772583112081904, 34.581289420501435],
              [-80.772257701250908, 34.581246621467741],
              [-80.772152513301492, 34.581776249863978],
              [-80.771942134362661, 34.582835503691776],
              [-80.771616716648168, 34.582792702933844],
              [-80.771353728308199, 34.584116768396711],
              [-80.771028305822057, 34.584073966041394],
              [-80.770975705666288, 34.584338778395363],
              [-80.770324859667539, 34.584253170752007],
              [-80.770272257128141, 34.584517982799476],
              [-80.769621410422857, 34.584432370439806],
              [-80.769568805499617, 34.584697182180683],
              [-80.76924338164352, 34.584654374985156],
              [-80.769190775358823, 34.584919186562601],
              [-80.768865350819866, 34.584876378341733],
              [-80.768812743173513, 34.585141189755696],
              [-80.768487317932284, 34.585098379608247],
              [-80.768382098487351, 34.585628002104684],
              [-80.768056671560501, 34.585585191690271],
              [-80.767635774109692, 34.587703675173671],
              [-80.767310338254461, 34.587660862748585],
              [-80.767257722698758, 34.58792567262271],
              [-80.767152491639763, 34.588455291392151],
              [-80.766827054147015, 34.588412477639494],
              [-80.766721818615153, 34.588942096958107],
              [-80.766396378308002, 34.588899281151804],
              [-80.766343760100554, 34.589164090621132],
              [-80.766018319110572, 34.589121273789516],
              [-80.765965698451481, 34.589386083111329],
              [-80.766291140444267, 34.589428899184647],
              [-80.766185901222798, 34.589958517135265],
              [-80.766511345599739, 34.590001332612445],
              [-80.766195624773829, 34.591590184140976],
              [-80.76587017317307, 34.59154736782142],
              [-80.765712304000715, 34.592341791993121],
              [-80.765386850742928, 34.592298973444734],
              [-80.765281600879405, 34.592828588869516],
              [-80.76495614484574, 34.592785770069995],
              [-80.764798264407972, 34.593580191271307],
              [-80.764472805646676, 34.593537371160231],
              [-80.764367548279068, 34.594066984905055],
              [-80.764042087812527, 34.594024163625512],
              [-80.763989456487735, 34.594288969438544],
              [-80.76366399533832, 34.594246147133511],
              [-80.76361136376083, 34.594510953668134],
              [-80.763285901928597, 34.594468130337781],
              [-80.763233267879883, 34.594732935823309],
              [-80.761931419837637, 34.594561632204879],
              [-80.761878781359684, 34.594826437097282],
              [-80.761553319180535, 34.594783609069154],
              [-80.761500679340656, 34.59504841379777],
              [-80.760524291754322, 34.594919923088561],
              [-80.760471648507561, 34.595184727367247],
              [-80.75982072309688, 34.59509906189615],
              [-80.759768077485134, 34.595363866769169],
              [-80.759442613167209, 34.595321032132318],
              [-80.759389966174083, 34.595585835940227],
              [-80.759064502282655, 34.595543001163314],
              [-80.759011853927461, 34.595807804807436],
              [-80.758686388244072, 34.595764968119646],
              [-80.758633738526825, 34.596029771599831],
              [-80.757331878408962, 34.595858417207779],
              [-80.757279224262447, 34.596123220094704],
              [-80.756953758232683, 34.596080378709019],
              [-80.75684844578565, 34.59660998415049],
              [-80.756522979140811, 34.596567141580209],
              [-80.75647032084035, 34.596831944134692],
              [-80.755819385434975, 34.596746256076791],
              [-80.755766724749961, 34.597011058324156],
              [-80.751861130769754, 34.596496854111194],
              [-80.751703108760566, 34.597291254689381],
              [-80.75137764101045, 34.597248398184234],
              [-80.751272288890675, 34.597777997884329],
              [-80.750946819435541, 34.597735140210439],
              [-80.750788785085192, 34.5985295387325],
              [-80.750463312902795, 34.598486679746678],
              [-80.750410633804066, 34.598751478758139],
              [-80.750085160919909, 34.598708617845439],
              [-80.749979797478105, 34.599238215551509],
              [-80.749654322908128, 34.59919535437141],
              [-80.749601639654983, 34.599460153049947],
              [-80.748950690560903, 34.599374426839063],
              [-80.748845317855043, 34.599904023576784],
              [-80.747868890093343, 34.599775427242271],
              [-80.747921579138421, 34.599510629321358],
              [-80.747596104563186, 34.599467762560742],
              [-80.747648795380883, 34.599202964746333],
              [-80.747323322167986, 34.599160097246681],
              [-80.747376012578002, 34.598895299570572],
              [-80.747050541817501, 34.598852431315883],
              [-80.747155924752533, 34.598322836171896],
              [-80.746830455267514, 34.598279966435825],
              [-80.746672377832809, 34.599074358683012],
              [-80.746346905639754, 34.599031488536255],
              [-80.746294210713387, 34.599296284876303],
              [-80.745968738927857, 34.599253413688125],
              [-80.74591604265828, 34.599518210765517],
              [-80.74559056908123, 34.599475337666277],
              [-80.745379777565105, 34.600534523375842],
              [-80.745054300257564, 34.60049164972272],
              [-80.745001600224043, 34.600756445507969],
              [-80.745327078554013, 34.600799319304436],
              [-80.745221680621071, 34.601328911984467],
              [-80.744896200227075, 34.601286037000179],
              [-80.744685393972716, 34.602345219765809],
              [-80.743383457295991, 34.602173710547454],
              [-80.743436164549465, 34.601908915444518],
              [-80.743110682788341, 34.601866035618663],
              [-80.743163389634219, 34.60160124065402],
              [-80.742837909254717, 34.601558360990445],
            ],
          ],
        ],
      },
    },
  ],
};
