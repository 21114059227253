import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, 
  TileLayer, 
  GeoJSON, 
  LayerGroup, 
  Marker,
  Popup,
  LayersControl } from "react-leaflet";
import "./Map.css";
import { basinsYadkin } from "../../GIS/Yadkin_UI_Basins_Simple";
import { streams4Yadkin } from "../../GIS/Yadkin_UI_StreamsSO4+_Simple";
import { reservoirsYadkin } from "../../GIS/Yadkin_UI_Reservoirs_Simple";
import { basinsCatawba } from "../../GIS/Catawba_Basins";
import { riversCatawba } from "../../GIS/Catawba_Rivers_Simplified";
import { reservoirsCatawba } from "../../GIS/Catawba_Reservoirs";

import { basinsCatawbaCustom } from "../../GIS/Custom_catawba_Basins";
import { basinsYadkinCustom } from "../../GIS/Custom_yadkin_Basins";
import { basinsNantCustom } from "../../GIS/Custom_nant_Basins";
import { basinsTuckCustom } from "../../GIS/Custom_tuck_Basins";
import { riversNant } from "../../GIS/Nant_Waterbodies";
import { riversTuck } from "../../GIS/Tuck_Waterbodies";

import { starsCatawba } from "../../GIS/Catawba_stars";
import { starsYadkin } from "../../GIS/Yadkin_stars";
import { starsNant } from "../../GIS/Nant_stars";
import { starsTuck } from "../../GIS/Tuck_stars";

import { arrowsCatawba } from "../../GIS/Catawba_arrow";
import { arrowsYadkin } from "../../GIS/Yadkin_arrow";
import { arrowsNant } from "../../GIS/Nant_arrow";
import { arrowsTuck } from "../../GIS/Tuck_arrow";

import { CircularProgress } from "@mui/material";
import L from 'leaflet';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import star from "./star_icon.svg";
import starAlert from "./star_alert_icon.svg";
import SE from "./SE.svg";
import N from "./N.svg";
import NW from "./NW.svg";

let StarIcon = L.icon({
    iconUrl: star,
    shadowUrl: iconShadow,
    iconSize: [24, 24], // size of the icon
    shadowSize: [8, 12], // size of the shadow
    iconAnchor: [12, 14], // point of the icon which will correspond to marker's location
    shadowAnchor: [2, 2],  // the same for the shadow
    popupAnchor:  [0, -6] // point from which the popup should open relative to the iconAnchor
  });

let StarAlertIcon = L.icon({
  iconUrl: starAlert,
  shadowUrl: iconShadow,
  iconSize: [24, 24], // size of the icon
  shadowSize: [8, 12], // size of the shadow
  iconAnchor: [12, 14], // point of the icon which will correspond to marker's location
  shadowAnchor: [2, 2],  // the same for the shadow
  popupAnchor:  [0, -6] // point from which the popup should open relative to the iconAnchor
});

let arrowSE = L.icon({
  iconUrl: SE,
  iconSize: [37, 37], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});
let arrowN = L.icon({
  iconUrl: N,
  iconSize: [24, 24], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});
let arrowNW = L.icon({
  iconUrl: NW,
  iconSize: [30, 30], // size of the icon
  iconAnchor: [0, 0], // point of the icon which will correspond to marker's location
});

export const CustomMap = (props) => {
  const { basinClick, watershed, thresholds } = props;
  let basins, streams, reservoirs, lat, lng, default_zoom, points, bigbasins, basinColoring, arrows, arrowIcon;

  const fillCatSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "GREA_CAT");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "PLEA_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "JAME_CAT").threshold >= 1) {
      // console.log("This subbasin is:",feature.properties.FEWS_ID,"Looking at threshold:", currentThreshold);
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "LINV_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "JAME_CAT").threshold >= 1) {
      return alertStyle;    
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "MORG_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "RHOD_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "WARR_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "RHOD_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "JOHN_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "RHOD_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "LOWL_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "LOOK_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ROCK_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "CEDA_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "SFOR_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "WYLI_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "SUGA_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "FISH_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "FCRK_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "GREA_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "MCDO_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "MOUN_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "MIDL_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HICK_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "UPPL_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HICK_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "DUTC_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "WYLI_CAT").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "BELW_CAT").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "FISH_CAT").threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };
  
  
  const fillYadSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "PATT_YAD");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "PATT_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "KERR_YAD").threshold >= 1) {
      // console.log("This subbasin is:",feature.properties.FEWS_ID,"Looking at threshold:", currentThreshold);
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "UPPR_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "KERR_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ELKC_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "KERR_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "REDD_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "WILK_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "MITC_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "FISH_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;    
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ARAR_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "LYAD_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ELKN_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ENON_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ABBO_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "IRSH_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "BLEW_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "MOCK_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "HUNT_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "LITT_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "BLEW_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "SECO_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ROAR_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "UWHA_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "TILL_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "NORW_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "BLEW_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "YADC_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "SYAD_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "ROCK_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "BLEW_YAD").threshold >= 1) {
      return alertStyle;
    } else if (thresholds.find(thresholdItem => thresholdItem.name === "CONF_YAD").name===feature.properties.FEWS_ID && thresholds.find(thresholdItem => thresholdItem.name === "HIGH_YAD").threshold >= 1) {
      return alertStyle;

    } else {
      return defaultStyle;
    }
  };

  
  const fillNantSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "RAIN_NAN");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };
  

  const fillTuckSubbasin = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
    const otherThreshold = thresholds.find(thresholdItem => thresholdItem.name === "GLEN_TUC");

    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold || !otherThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };
  
  
  

  if (watershed === "Custom_catawba") {
    basins = basinsCatawbaCustom;
    streams = riversCatawba;
    reservoirs = reservoirsCatawba;
    points = starsCatawba;
    lat = 35.2;
    lng = -81.2;
    default_zoom = 8;
    bigbasins = basinsCatawba;
    basinColoring = fillCatSubbasin;
    arrows = arrowsCatawba;
    arrowIcon = arrowSE;
  }
  else if (watershed === "Custom_yadkin") {
    basins = basinsYadkinCustom;
    streams = streams4Yadkin;
    reservoirs = reservoirsYadkin;
    points = starsYadkin;
    lat = 35.8;
    lng = -80.7;
    default_zoom = 8;
    bigbasins = basinsYadkin;
    basinColoring = fillYadSubbasin;
    arrows = arrowsYadkin;
    arrowIcon = arrowSE;
  }
  else if (watershed === "Custom_nant") {
    basins = basinsNantCustom;
    streams = riversNant;
    reservoirs = riversNant;
    points = starsNant;
    lat = 35.17;
    lng = -83.62;
    default_zoom = 10;
    bigbasins = basinsNantCustom;
    basinColoring = fillNantSubbasin;
    arrows = arrowsNant;
    arrowIcon = arrowN;
  }
  else if (watershed === "Custom_tuck") {
    basins = basinsTuckCustom;
    streams = riversTuck;
    reservoirs = riversTuck;
    points = starsTuck;
    lat = 35.25;
    lng = -83.1;
    default_zoom = 10;
    bigbasins = basinsTuckCustom;
    basinColoring = fillTuckSubbasin;
    arrows = arrowsTuck;
    arrowIcon = arrowNW;
  }

  const zoom = default_zoom;
  const position = [lat, lng];
  const watershedRef = useRef();
  const streamsRef = useRef();
  const resRef = useRef();
  const pointsRef = useRef();


  const chooseBasinStyle = (feature) => {
    const defaultStyle = {
      fillColor: "green",
      fillOpacity: 0.5,
      color: "black",
      weight: 1,
      opacity: 1,
    };
    const alertStyle = {
      fillColor: "red",
      fillOpacity: 0.4,
      color: "red",
      weight: 1,
      opacity: 1,
    };
  
    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.FEWS_ID;
    });
    if (!currentThreshold) {
      return defaultStyle;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return alertStyle;
    } else {
      return defaultStyle;
    }
  };

  const chooseStarStyle = (feature) => {
    const currentThreshold = thresholds.find((thresholdItem) => {
      return thresholdItem.name === feature.properties.SUBBASIN_ID;
    });
    if (!currentThreshold) {
      return StarIcon;
    } else if (currentThreshold && currentThreshold.threshold >= 1) {
      return StarAlertIcon;
    } else {
      return StarIcon;
    }
  };

  const streamStyle = {
    fillColor: "blue",
    fillOpacity: 0.7,
    color: "blue",
    weight: 0.5,
    opacity: 1,
  };
  const bigBasinStyle = {
    fillOpacity: 0,
    color: "black",
    weight: 3,
    opacity: 1,
  };

  
  return (
    <>
      {!thresholds || basins.features.length < 1 ? (
        <CircularProgress />
      ) : (
        <MapContainer center={position} zoom={zoom} scrollWheelZoom={true}>
          <LayersControl position="topright">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
           
            <LayersControl.Overlay checked name="Basins">
              <GeoJSON
                ref={watershedRef}
                key={Math.random()}
                data={bigbasins.features}
                style={bigBasinStyle}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Subbasins">
              <GeoJSON
                ref={watershedRef}
                // key={basins.features.properties}
                key={Math.random()}
                data={basins.features}
                // style={chooseBasinStyle}
                style={basinColoring}
                // onEachFeature={onEachBasin}
              />
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Streams">
              <LayerGroup>
                <GeoJSON
                  ref={streamsRef}
                  key={streams.features}
                  data={streams.features}
                  style={streamStyle}
                />
                <GeoJSON
                  ref={resRef}
                  // key={reservoirs.features}
                  key={Math.random()}
                  data={reservoirs.features}
                  style={streamStyle}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Points">
              <LayerGroup>
                {/* <GeoJSON
                  ref={pointsRef}
                  key={points.features}
                  style={starsStyle}
                  data={points.features}
                  type='MultiPoint'
                  onEachFeature={onEachPoint}
                /> */}
                  {points.features.map((elem, i) => (
                        <Marker
                          ref={pointsRef}
                          key={elem.properties.SUBBASIN_ID}
                          // key={points.features}
                          // key={park.properties.PARK_ID}
                          // style={chooseStarStyle}
                          // data={points.features}
                          position={[
                            elem.properties.LAT, 
                            elem.properties.LON
                          ]}
                          // icon={StarIcon}
                          icon={chooseStarStyle(elem)}
                          eventHandlers={
                            // {onEachPoint}
                            {
                            mouseover: (event) => event.target.openPopup(),
                            click: (event) => basinClick(elem.properties.SUBBASIN_ID),
                            mouseout: (event) => event.target.closePopup()
                          }
                        }
                        >
                          <Popup>{elem.properties.DisplayName}</Popup>
                      </Marker>
                      ))}
             </LayerGroup>
             </LayersControl.Overlay>
             <LayersControl.Overlay checked name="General Flow Direction">
                <LayerGroup>
                {arrows.features.map((elem) => (
                  <Marker
                    ref={pointsRef}
                    key={Math.random()}
                    position={[
                      elem.properties.LAT, 
                      elem.properties.LON
                    ]}
                    icon={arrowIcon}
                  >
                </Marker>
                ))}
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      )}
    </>
  );
};
