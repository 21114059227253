import { AppBar, Toolbar, Typography } from "@mui/material";
import { useAuth } from "../../Hooks/useAuth";
import { Logout } from "../Auth/Logout";
import { About } from "./About";
import { Disclaimer } from "./Disclaimer";
import RTILogo from "../../Images/RTI_logo_w_1in.png";
// import { MoreInfo } from /"./MoreInfo";

export const Header = () => {
  const { user, token } = useAuth();
  return (
    <AppBar
      position="static"
      sx={{ background: "#496D99", marginBottom: "0px" }}
    >
      <Toolbar sx={{ background: "#496D99" }}>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          Water Forecast Portal
        </Typography>
        {user && token ? (
          <>
            <About />
            <Disclaimer />
            <Logout />
            <img src={RTILogo} alt="RTI Logo" height={100} />
          </>
        ) : (
          <img src={RTILogo} alt="RTI Logo" height={100} />
        )}
      </Toolbar>
    </AppBar>
  );
};
