export const basinsYadkin = {
  type: "FeatureCollection",
  name: "Yadkin_UI_Basins_Simple",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4269" } },
  features: [
    {
      type: "Feature",
      properties: {
        Res_Basin: "BlewettFalls",
        BASIN_ID: "Blewett_Basin",
        BASIN_NAME: "Blewett",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.522062083204574, 34.874228197891796],
              [-80.525877602391674, 34.876910345128778],
              [-80.52675926938484, 34.880832344360279],
              [-80.530292830699779, 34.879943900917702],
              [-80.535575965494516, 34.882005691179103],
              [-80.538724388602603, 34.879706997569784],
              [-80.547268158290649, 34.880571407478634],
              [-80.550469732264119, 34.878007767859629],
              [-80.557161228116598, 34.878081764594057],
              [-80.564441351463131, 34.875244390552815],
              [-80.575715680487804, 34.879186700473106],
              [-80.581694871427473, 34.877806094243539],
              [-80.589164384633719, 34.882329124315973],
              [-80.596318440473055, 34.875125550342574],
              [-80.599470996941051, 34.874456391259692],
              [-80.602576416286638, 34.875682979597215],
              [-80.605427800268217, 34.88149509480737],
              [-80.610486702839324, 34.881349784564875],
              [-80.612839202995787, 34.884650090190121],
              [-80.618291490532869, 34.887545336850472],
              [-80.619337978894251, 34.890672626612108],
              [-80.622064406807354, 34.892120122614749],
              [-80.62087786487804, 34.894680280906343],
              [-80.62295787460215, 34.897672475530094],
              [-80.622264646504206, 34.901113000475334],
              [-80.626358364094258, 34.904099658901465],
              [-80.631198613954055, 34.903380954618896],
              [-80.633872424608754, 34.905092815267118],
              [-80.639532963048168, 34.905297253877642],
              [-80.640868223786825, 34.911994342963411],
              [-80.63793466354916, 34.913237065722697],
              [-80.638396189262167, 34.919275335716328],
              [-80.633476213222977, 34.927048014577501],
              [-80.635010577133244, 34.92942424914181],
              [-80.63322356946766, 34.931633448127378],
              [-80.634758017003378, 34.934009674831302],
              [-80.633951399662067, 34.936348369564065],
              [-80.636928068066823, 34.944892181143878],
              [-80.631445337790183, 34.948786854410514],
              [-80.631732733019149, 34.952356775454682],
              [-80.627797602294947, 34.96188950338923],
              [-80.629513142882303, 34.968364878349313],
              [-80.624568822605141, 34.971243758438668],
              [-80.62246700490067, 34.976671526220116],
              [-80.623621630790581, 34.979269224368807],
              [-80.627826290851786, 34.981726325379569],
              [-80.626478445820567, 34.985080210908407],
              [-80.629101628543438, 34.98705667143863],
              [-80.627159820990315, 34.991690535305182],
              [-80.630198136686317, 35.00160037486387],
              [-80.628557202500033, 35.013065810606875],
              [-80.620258206360333, 35.022565533220217],
              [-80.621266601729175, 35.029218845274791],
              [-80.624726182958085, 35.035380599483176],
              [-80.632487430141552, 35.040207954402646],
              [-80.631626235389817, 35.042811004376368],
              [-80.63304911789777, 35.044085332818661],
              [-80.651812068696202, 35.046015077284792],
              [-80.650357475386016, 35.049898278249053],
              [-80.652069522889263, 35.054741874666618],
              [-80.648977957976626, 35.058409449247279],
              [-80.650081365987717, 35.061271299123341],
              [-80.652258969841142, 35.060471488627918],
              [-80.657337040529015, 35.061955052257076],
              [-80.661638788018564, 35.06061975299297],
              [-80.674536897531624, 35.066662730154],
              [-80.680102962505032, 35.067394447330152],
              [-80.684428761631125, 35.070950884071245],
              [-80.684825541286031, 35.073990963075218],
              [-80.687391925312269, 35.074599740156799],
              [-80.687637283289476, 35.080064547437772],
              [-80.697539933050621, 35.085982368718788],
              [-80.693102453103833, 35.09300531441788],
              [-80.693278578391428, 35.095473030868547],
              [-80.695084224494707, 35.096524902476041],
              [-80.693532666750343, 35.102568513811995],
              [-80.688713014566545, 35.109812815988924],
              [-80.692660748712981, 35.113590421604044],
              [-80.695040454137029, 35.120149853261942],
              [-80.688405396042413, 35.12471141588896],
              [-80.687766310426611, 35.12788691914961],
              [-80.681527117994662, 35.135487944553581],
              [-80.683349608754611, 35.139801441278266],
              [-80.680210781861049, 35.145364883013286],
              [-80.679861567396841, 35.152109373468875],
              [-80.675387183385752, 35.152608338106475],
              [-80.671362745705238, 35.155882403363215],
              [-80.6700825912326, 35.162233019928642],
              [-80.66443354472645, 35.168552912792187],
              [-80.658440688900839, 35.171568027556283],
              [-80.654071843038381, 35.171536972221837],
              [-80.653163889631571, 35.176035130138871],
              [-80.6471772735418, 35.180680329408005],
              [-80.650791362676529, 35.182785285231816],
              [-80.650966332678522, 35.185252850259253],
              [-80.655015485442277, 35.186871591097393],
              [-80.658217857967429, 35.194354376662176],
              [-80.662809173540012, 35.194957547750668],
              [-80.670322122866011, 35.201104628926643],
              [-80.670299797596925, 35.207891786109826],
              [-80.674915599669646, 35.213386367507091],
              [-80.683276703170762, 35.213668348820391],
              [-80.691158101300914, 35.216331174566442],
              [-80.693667740217037, 35.215573714443266],
              [-80.697284750794637, 35.21767717336833],
              [-80.701521702292084, 35.223392285387227],
              [-80.709741450170355, 35.227727414451707],
              [-80.713235635462055, 35.227098377393922],
              [-80.714317768590419, 35.225067352847333],
              [-80.720223833947898, 35.22583999134087],
              [-80.725509090304243, 35.223000705473986],
              [-80.726688844833689, 35.228857964732399],
              [-80.731079100977823, 35.232147515049874],
              [-80.732959858022795, 35.236195227794887],
              [-80.736241571576031, 35.236623984423744],
              [-80.73822903812723, 35.240142359657028],
              [-80.735631001287175, 35.244691138311644],
              [-80.736254425149497, 35.258350614245778],
              [-80.73107353297361, 35.26066106618584],
              [-80.732067436566723, 35.26242026333491],
              [-80.744614250698689, 35.26704557142719],
              [-80.755907535737791, 35.262816343480822],
              [-80.765587541244258, 35.263263724537765],
              [-80.768392919726423, 35.266073201825719],
              [-80.766883010298301, 35.278638894516654],
              [-80.772286260307737, 35.276898928060078],
              [-80.779447259576585, 35.276473734933553],
              [-80.783832324182654, 35.278130655366638],
              [-80.786396092911446, 35.277106634100896],
              [-80.788492948939776, 35.280094766249761],
              [-80.793757612491007, 35.2824087427921],
              [-80.795992229220062, 35.289758971599881],
              [-80.793724393355348, 35.294351521051667],
              [-80.796680482794585, 35.294735862366686],
              [-80.802953000805473, 35.300438465772643],
              [-80.812150696168331, 35.301633224246402],
              [-80.815287697061464, 35.304484141856108],
              [-80.819505407708675, 35.305303131541386],
              [-80.824466326428819, 35.310833982817066],
              [-80.827804653440566, 35.310995519677881],
              [-80.833518204552192, 35.314451445150269],
              [-80.831739152513862, 35.318293288445261],
              [-80.827425898433603, 35.319634479285121],
              [-80.826877194525636, 35.327436841851153],
              [-80.82231181309794, 35.340148227434604],
              [-80.824676477523198, 35.341812382931082],
              [-80.825907496680898, 35.345772956026529],
              [-80.822748537591366, 35.348078301156612],
              [-80.822474933451176, 35.356187694213773],
              [-80.819262475809992, 35.358757552192834],
              [-80.820376582675891, 35.361616983984582],
              [-80.815753682862052, 35.366175834157687],
              [-80.817960081167669, 35.368633952746919],
              [-80.82666746258208, 35.368948357316675],
              [-80.833148764493785, 35.371960089993408],
              [-80.834010225013685, 35.377772868471197],
              [-80.832516876580243, 35.383552079650364],
              [-80.834289581811973, 35.386496508340933],
              [-80.836591688683356, 35.386794631022646],
              [-80.834228817264673, 35.393547147036742],
              [-80.837593472035223, 35.396969072802001],
              [-80.838690011676718, 35.404983842352323],
              [-80.842418698662783, 35.413339121553591],
              [-80.839333157194432, 35.418640759569342],
              [-80.843149924997448, 35.431622161958622],
              [-80.842673513007881, 35.434003737705218],
              [-80.839182324246323, 35.436266772182115],
              [-80.845099096721768, 35.443818711137986],
              [-80.84144148704388, 35.453661293376449],
              [-80.842304499838491, 35.459473587157575],
              [-80.847606297609985, 35.465045263336791],
              [-80.849128393410936, 35.470942550975984],
              [-80.85143310335144, 35.471240362016538],
              [-80.85880037515328, 35.481692741438053],
              [-80.856530616872121, 35.486285703375607],
              [-80.857883257684151, 35.49134640651512],
              [-80.856024864411779, 35.492192229410804],
              [-80.85397810284951, 35.497356848401395],
              [-80.848602477790038, 35.497205414559936],
              [-80.846479001442262, 35.4993741165476],
              [-80.846684135876345, 35.505101036324163],
              [-80.840119267137084, 35.507510147246855],
              [-80.837200146715347, 35.513647660006491],
              [-80.8388284256623, 35.519015636326614],
              [-80.842869220557873, 35.524152334985388],
              [-80.845509678000951, 35.532908141204096],
              [-80.849604642749341, 35.537779951935221],
              [-80.848033163558526, 35.540562887303686],
              [-80.846714995391835, 35.540392687450279],
              [-80.84870385705149, 35.542278109961309],
              [-80.845966209588454, 35.550882102967655],
              [-80.841810582918612, 35.553059875864101],
              [-80.838719348215463, 35.558360850537554],
              [-80.836305967008855, 35.558591989465356],
              [-80.832001714047223, 35.563193264812696],
              [-80.830300491934537, 35.563244877514251],
              [-80.826741422079778, 35.572556821515768],
              [-80.819521755136364, 35.576509518624825],
              [-80.816587408944045, 35.581016084601501],
              [-80.808111472672138, 35.586163012220595],
              [-80.801251270501751, 35.586632504757539],
              [-80.791061405893672, 35.590199293211995],
              [-80.780241573465375, 35.598569902031215],
              [-80.769816047529261, 35.599932934133776],
              [-80.759850774376758, 35.595654035185198],
              [-80.757969400658723, 35.593238117201174],
              [-80.750109593877625, 35.591946184402623],
              [-80.749314964114475, 35.587499417517073],
              [-80.739967692815057, 35.585199062375146],
              [-80.737151623252117, 35.582389707525543],
              [-80.72337749073526, 35.583584608403662],
              [-80.710103330998379, 35.575612302930836],
              [-80.702209594299049, 35.577841882994825],
              [-80.69194619118629, 35.578404516778541],
              [-80.682791925669932, 35.57015126236238],
              [-80.680937700276189, 35.572624375400821],
              [-80.669425703488884, 35.576008683105833],
              [-80.656464394563244, 35.574858272740308],
              [-80.652755609729326, 35.568128427528116],
              [-80.643306191130762, 35.566349412803149],
              [-80.6405481062192, 35.563273217784371],
              [-80.63697709972071, 35.562534291811382],
              [-80.63217466957623, 35.556203317602524],
              [-80.62723237502874, 35.55555598762399],
              [-80.623762614388099, 35.552657541077515],
              [-80.620360364345345, 35.552754758364806],
              [-80.618262602263371, 35.549764369839828],
              [-80.618027380317088, 35.545931924675095],
              [-80.614343192659121, 35.544091147850217],
              [-80.607713824428501, 35.546751764676209],
              [-80.605097570917025, 35.551296409674386],
              [-80.595574604022033, 35.558193175991143],
              [-80.587619388989992, 35.55904950574223],
              [-80.585924094303422, 35.560727728990912],
              [-80.586319496750278, 35.563766769194473],
              [-80.583413916330898, 35.564742894272499],
              [-80.584408375853457, 35.566502855712308],
              [-80.583102222654816, 35.569589946343918],
              [-80.56917170620514, 35.573190439129178],
              [-80.554715520074822, 35.584324120707031],
              [-80.551468662524925, 35.581995900023088],
              [-80.550669992580254, 35.572657464854366],
              [-80.546769910593937, 35.571872449202999],
              [-80.539117252850033, 35.564618336568088],
              [-80.534401375409914, 35.566169563308655],
              [-80.528087268065647, 35.565608860794484],
              [-80.524512561489459, 35.563236425801421],
              [-80.512155927436496, 35.560791425894671],
              [-80.51225984683002, 35.558632419214256],
              [-80.50934479862201, 35.556346474631944],
              [-80.493695762141698, 35.553464584197684],
              [-80.490610837905464, 35.54871119137276],
              [-80.491370111856767, 35.545009118775582],
              [-80.483721606733184, 35.536121101986353],
              [-80.484209854197289, 35.533741202762513],
              [-80.478046853921569, 35.525863837149672],
              [-80.465921834777532, 35.525616553335276],
              [-80.462401897144915, 35.521347554727285],
              [-80.458176110388976, 35.520515964034523],
              [-80.457287334726132, 35.514965649417768],
              [-80.452732751528757, 35.514090222574644],
              [-80.449664293378788, 35.515856375878506],
              [-80.446308805796292, 35.510793593648302],
              [-80.441262277134527, 35.510667318451816],
              [-80.434558833621495, 35.50380153198941],
              [-80.429349536610076, 35.504468000624996],
              [-80.426877027866908, 35.501694802087464],
              [-80.423095746724186, 35.503637253922228],
              [-80.421007065724481, 35.50064324930122],
              [-80.417441123308606, 35.499897663468211],
              [-80.415835819789265, 35.489633181496245],
              [-80.411775696649457, 35.488006672854738],
              [-80.405525583988975, 35.488805214730512],
              [-80.405255268424909, 35.491757477807163],
              [-80.402079666538924, 35.495681463738535],
              [-80.395281609694194, 35.49749328393024],
              [-80.394958246232221, 35.502340111853592],
              [-80.391119741120107, 35.502915732400602],
              [-80.389477727791956, 35.505957027930954],
              [-80.386735536773443, 35.506135183620025],
              [-80.386355294274878, 35.509616147628165],
              [-80.377967568604518, 35.514203765467343],
              [-80.374900345897828, 35.519228355028446],
              [-80.370566370083822, 35.518922407880524],
              [-80.367442499578871, 35.522580987268313],
              [-80.362943154152774, 35.521437727449737],
              [-80.363384132827676, 35.52421322635692],
              [-80.360152693539433, 35.531660696374622],
              [-80.353789068526112, 35.532985227601735],
              [-80.346379299967666, 35.527921279691732],
              [-80.339134022651081, 35.520433554516607],
              [-80.338639206770765, 35.517922326505968],
              [-80.333208475899127, 35.518011846091845],
              [-80.325581964443288, 35.512373810559929],
              [-80.322070133374766, 35.506470390154519],
              [-80.316091402663858, 35.504312160871912],
              [-80.315213355604243, 35.500390921096248],
              [-80.307151265578568, 35.496866525922229],
              [-80.297061783479464, 35.485190302144808],
              [-80.288453609875845, 35.482677937557582],
              [-80.285985683022233, 35.484792459705616],
              [-80.284011790880015, 35.484527756868012],
              [-80.276557040467452, 35.478093335353094],
              [-80.274253743074269, 35.479414570455617],
              [-80.27233709026747, 35.473994581871587],
              [-80.26532145536062, 35.470335358292409],
              [-80.264339987652406, 35.460421261026553],
              [-80.260557578882981, 35.460728316547346],
              [-80.258147594161144, 35.457687141531011],
              [-80.247074440702221, 35.458915918034513],
              [-80.243239660857213, 35.456226214752768],
              [-80.237868252689225, 35.456047022662531],
              [-80.237817533309169, 35.451420523868237],
              [-80.233983455589225, 35.448730502543896],
              [-80.233986502953186, 35.445469925496688],
              [-80.22888958402207, 35.445598922365591],
              [-80.230755338892592, 35.443132644058352],
              [-80.227854786967328, 35.439209249059211],
              [-80.225827199362584, 35.439207901500225],
              [-80.221658272596116, 35.442994363443319],
              [-80.219685683585126, 35.442728577266422],
              [-80.21875634459407, 35.440701043463662],
              [-80.218695694601578, 35.445856256714592],
              [-80.209157227500555, 35.447831661146495],
              [-80.207400954108962, 35.449768938410543],
              [-80.205814301061992, 35.447652644834726],
              [-80.203181917650468, 35.448928191425829],
              [-80.200498752428558, 35.447207428630954],
              [-80.19984796579854, 35.442227845086691],
              [-80.19541339501761, 35.439183514111086],
              [-80.196845633874162, 35.433941423910326],
              [-80.191754270377643, 35.430808202538401],
              [-80.190773073887627, 35.427414434787138],
              [-80.183660396996373, 35.421018216488285],
              [-80.181917262828733, 35.414803518538619],
              [-80.182908354438126, 35.411676060961561],
              [-80.177219815625222, 35.406558672919857],
              [-80.177222549040508, 35.404928325391943],
              [-80.174649176279516, 35.404308615374667],
              [-80.171918677252322, 35.399590748094731],
              [-80.171874833125187, 35.393333625142205],
              [-80.168868720154407, 35.389937253824264],
              [-80.170354204689446, 35.386061308996297],
              [-80.168496650998478, 35.38363563068377],
              [-80.169979145106524, 35.381390052774208],
              [-80.16866817845299, 35.379581896427148],
              [-80.170316255711825, 35.37654333363907],
              [-80.165613470368029, 35.373188938964844],
              [-80.16469240849213, 35.367900053216552],
              [-80.166724015930711, 35.364641639159736],
              [-80.166073161145178, 35.361291915175784],
              [-80.162577537859519, 35.357013395706289],
              [-80.164338829042379, 35.351815775095282],
              [-80.168284791153255, 35.349088348645886],
              [-80.166875800271725, 35.341287031175881],
              [-80.168412722658545, 35.338777054156083],
              [-80.167547081681946, 35.333223690536023],
              [-80.171988614901466, 35.328117077519728],
              [-80.168058495899714, 35.322692359784966],
              [-80.171787813060988, 35.317761142799718],
              [-80.181586729213365, 35.314466798194388],
              [-80.180334439317804, 35.310763828171957],
              [-80.18520964996884, 35.306802828585006],
              [-80.182699865693721, 35.302657893246526],
              [-80.185437791243686, 35.300853933717953],
              [-80.183744593295273, 35.299265760337832],
              [-80.183694657045606, 35.296269094634702],
              [-80.180090763765563, 35.292519541718498],
              [-80.184046028665293, 35.281638809063544],
              [-80.189467568037813, 35.276664509643524],
              [-80.188047759637442, 35.275385111808269],
              [-80.184875139523015, 35.276042943379259],
              [-80.178430303853787, 35.271364883145296],
              [-80.176024032115379, 35.271582612371688],
              [-80.170674336880154, 35.266508682053271],
              [-80.170578403258219, 35.2588845832327],
              [-80.168886441045956, 35.25729613456609],
              [-80.170587011414, 35.253992871961692],
              [-80.164638253620325, 35.248565336761963],
              [-80.166289593066665, 35.242265284428257],
              [-80.164766508031605, 35.238253085889745],
              [-80.166791725699213, 35.236624882010105],
              [-80.167508422626028, 35.233188227326686],
              [-80.165541422875549, 35.23292148637158],
              [-80.164180967526477, 35.229746780620239],
              [-80.159208589879185, 35.229344081318146],
              [-80.156089616822243, 35.231367420956822],
              [-80.150734177060784, 35.231184231978311],
              [-80.150020052731165, 35.232990186528205],
              [-80.148329088408602, 35.231401404201783],
              [-80.140841145825561, 35.231743751611766],
              [-80.127147573267777, 35.221455332452877],
              [-80.122768397358399, 35.224665808315173],
              [-80.11724762044517, 35.225274115603717],
              [-80.115237833722617, 35.220379008834676],
              [-80.112126288617716, 35.219139954745827],
              [-80.110772430689451, 35.214333959437283],
              [-80.107333489618696, 35.213050158478829],
              [-80.106741838403295, 35.209435300277889],
              [-80.103141490680045, 35.20731373708167],
              [-80.100520011857071, 35.206956634095285],
              [-80.098328124102366, 35.209376741387366],
              [-80.09488513609837, 35.209723212330957],
              [-80.093616223043426, 35.214172156977561],
              [-80.0896191659306, 35.217161754536029],
              [-80.086674348909995, 35.215129044862081],
              [-80.082903340409572, 35.215430483627202],
              [-80.079806915652213, 35.209298661442126],
              [-80.076530122083255, 35.208851625382742],
              [-80.071624948461007, 35.204919612312388],
              [-80.062329401648554, 35.207456961283839],
              [-80.062807996789189, 35.211600678916511],
              [-80.061330839445617, 35.212214629317195],
              [-80.061103723223908, 35.214902516565743],
              [-80.059354281013157, 35.215207375983454],
              [-80.058889387711758, 35.223844358541484],
              [-80.055445571824436, 35.224189672144696],
              [-80.052152120311987, 35.228633802857352],
              [-80.049146872929796, 35.22849509392934],
              [-80.049358106484974, 35.230699092425908],
              [-80.044169626763548, 35.229718148840114],
              [-80.041375358288221, 35.231783247028282],
              [-80.042897438522388, 35.23416646248409],
              [-80.040808458075787, 35.237687433990281],
              [-80.044575177092, 35.239017942674955],
              [-80.044069593600511, 35.243027221858107],
              [-80.042536113588028, 35.243905216430818],
              [-80.042580494435128, 35.246902084912122],
              [-80.038641000926063, 35.24799498710118],
              [-80.040246261656847, 35.25800245999045],
              [-80.034494241871826, 35.261294600228901],
              [-80.033048323452832, 35.268166088318765],
              [-80.039361478050807, 35.276641755108031],
              [-80.040211536373093, 35.283826869161025],
              [-80.03714853035072, 35.283952147794423],
              [-80.033690343101853, 35.287557847667429],
              [-80.029420639777271, 35.288605575850802],
              [-80.030782400142357, 35.290151146456246],
              [-80.02890215983291, 35.295875619777412],
              [-80.030536421474181, 35.297730297039934],
              [-80.029924277028186, 35.300637353589771],
              [-80.032047832615078, 35.303374498840569],
              [-80.031875066730066, 35.305797823237768],
              [-80.026880655092839, 35.310281032196222],
              [-80.028503379745487, 35.315396669438208],
              [-80.020839609258573, 35.316524184606614],
              [-80.022523780828337, 35.319745138924283],
              [-80.01911899287056, 35.323086023937059],
              [-80.019918040990831, 35.328904786892799],
              [-80.016463166529405, 35.330879381074823],
              [-80.014698508864967, 35.334444442917743],
              [-80.015553069506694, 35.339998914273231],
              [-80.013571420264867, 35.342990536720023],
              [-80.016079526749451, 35.345508481113697],
              [-80.015472629032061, 35.346784907936168],
              [-80.012295569954702, 35.347438052355294],
              [-80.011156160858036, 35.344835319381403],
              [-80.005421931613213, 35.341604053452635],
              [-80.002871422846582, 35.336089246584017],
              [-79.997180853479506, 35.335854190361822],
              [-79.993725078648438, 35.337828106265306],
              [-79.991624320251091, 35.342978300949532],
              [-79.994738947521299, 35.344220393095405],
              [-79.996458681050328, 35.352068657103025],
              [-79.994853422439817, 35.356471022195457],
              [-79.99056592183382, 35.360778095240548],
              [-79.993891260941041, 35.364224020292674],
              [-79.989015981194029, 35.364916102903976],
              [-79.987137561523781, 35.369009125037628],
              [-79.984511084805774, 35.368649494914571],
              [-79.982583346058888, 35.37137628021469],
              [-79.978203473970325, 35.371320160029882],
              [-79.97605187842106, 35.375103772136029],
              [-79.972111936889448, 35.374563938091846],
              [-79.970232375595913, 35.378656655482729],
              [-79.966990465868918, 35.38120316776164],
              [-79.970315630727484, 35.384649693014097],
              [-79.969812346571345, 35.387027749708096],
              [-79.972879590765146, 35.386904231549735],
              [-79.972704761197392, 35.389327284206459],
              [-79.967540824519276, 35.392969966071675],
              [-79.970866525920599, 35.39641643929972],
              [-79.967952312731938, 35.399007930939682],
              [-79.967336978030914, 35.401914393852145],
              [-79.962403095469824, 35.402869577305658],
              [-79.960641607798991, 35.404803259256624],
              [-79.960298439831945, 35.408018911114162],
              [-79.955364075351028, 35.40897379535928],
              [-79.957760465562927, 35.412021259045289],
              [-79.955998649558879, 35.413954855924082],
              [-79.954983299015467, 35.420341032806945],
              [-79.94879004000083, 35.420851262647574],
              [-79.949737644056938, 35.429137969721481],
              [-79.947799510231349, 35.433494337405904],
              [-79.948489828551558, 35.438211123876712],
              [-79.944172007265237, 35.447407037505201],
              [-79.941030672560117, 35.451054550948371],
              [-79.934738468018537, 35.448831884518889],
              [-79.928646945688527, 35.450442924929931],
              [-79.926162026588401, 35.454180250196458],
              [-79.918354009976383, 35.459089872853596],
              [-79.917848617954149, 35.461467550017176],
              [-79.914224949285469, 35.46260121940233],
              [-79.912283941174067, 35.46695687544868],
              [-79.907280474965845, 35.469804126894289],
              [-79.905555186211714, 35.474733168430369],
              [-79.900421213379843, 35.481368918198442],
              [-79.895753858126625, 35.482630609941047],
              [-79.889572906823531, 35.479877259164184],
              [-79.886155167660974, 35.483213825122206],
              [-79.888503749013168, 35.484896439901405],
              [-79.888870990197248, 35.48793792659982],
              [-79.886448136130042, 35.489779917495348],
              [-79.887576536134731, 35.494013722452799],
              [-79.886515993074354, 35.497402623184584],
              [-79.891092745913625, 35.502926270695518],
              [-79.885356697851392, 35.518723523823688],
              [-79.886832259284589, 35.519742121522334],
              [-79.889152350502286, 35.516798316470982],
              [-79.896379265042427, 35.519423032164141],
              [-79.898006390439292, 35.522909339328571],
              [-79.897227286449663, 35.5249774420843],
              [-79.899135921438258, 35.527142912261397],
              [-79.90494439354157, 35.528484485032131],
              [-79.905650000859652, 35.529940820757972],
              [-79.90807366640253, 35.528098500740889],
              [-79.910921761922282, 35.52903327020595],
              [-79.911827179675214, 35.534323363492177],
              [-79.913792688372595, 35.536224413924664],
              [-79.919602109335401, 35.537565256761177],
              [-79.927024835465829, 35.534505103803227],
              [-79.929199343493906, 35.538609501561737],
              [-79.927297284698057, 35.545961219740768],
              [-79.92344494337479, 35.548151770957837],
              [-79.926446355844789, 35.551553904585028],
              [-79.924608050715307, 35.557011211180551],
              [-79.920313544343145, 35.559684855994384],
              [-79.921524482437263, 35.56991017955778],
              [-79.917670765709573, 35.572100498077518],
              [-79.916835449539931, 35.574432802784287],
              [-79.910975436537058, 35.571725925941443],
              [-79.909160945487884, 35.572292615224931],
              [-79.908035505514533, 35.577575727770622],
              [-79.903916718139129, 35.577826179186658],
              [-79.902968327921457, 35.580686678997452],
              [-79.900269995234936, 35.582219478749195],
              [-79.898030782707067, 35.58000893984979],
              [-79.894128344520738, 35.580832538259948],
              [-79.89556142324723, 35.590001362027166],
              [-79.894396016921974, 35.59228827665904],
              [-79.896137267678611, 35.595246103908792],
              [-79.894568654132925, 35.601012078856471],
              [-79.90027003147118, 35.602882082204189],
              [-79.902310572602488, 35.601258957483317],
              [-79.903281162462577, 35.604654593612423],
              [-79.897037660594378, 35.611682220437103],
              [-79.890979828631359, 35.614656992331042],
              [-79.887538235870963, 35.621253275768467],
              [-79.88187407952519, 35.622378613689669],
              [-79.881206328028384, 35.623918172150773],
              [-79.884217846718172, 35.625691136798778],
              [-79.880991732747432, 35.632860657340956],
              [-79.875599573615716, 35.634295048258068],
              [-79.869849871326764, 35.640573940575635],
              [-79.867007488776721, 35.638008267701331],
              [-79.860795954067129, 35.638513790842396],
              [-79.857736595059734, 35.644890249634258],
              [-79.858027736278629, 35.651455508047114],
              [-79.852520780917501, 35.653417055171921],
              [-79.848951257323179, 35.66217019634631],
              [-79.853535185741805, 35.667694525203054],
              [-79.853806722885778, 35.677519574922393],
              [-79.851057145935926, 35.677685287135269],
              [-79.849918307109689, 35.675081747076113],
              [-79.847281927218333, 35.674719160821553],
              [-79.844785699821173, 35.678454060135522],
              [-79.845924413606028, 35.681057642272968],
              [-79.842655574570045, 35.68523001717611],
              [-79.840188649424164, 35.684074975869898],
              [-79.831439642895006, 35.685317885560131],
              [-79.823045611166179, 35.691231250219239],
              [-79.811839338321803, 35.689687334366639],
              [-79.81525488535317, 35.680097830184771],
              [-79.817675076289561, 35.679887539854953],
              [-79.818241705338181, 35.677246644138918],
              [-79.819889408025375, 35.677473670472928],
              [-79.819431498437368, 35.671700735669489],
              [-79.825064170053253, 35.667582605565251],
              [-79.826593039523857, 35.660452000226528],
              [-79.824343373055356, 35.659870186243154],
              [-79.824955965130769, 35.658595091798844],
              [-79.82032839923842, 35.651703732070452],
              [-79.82122414612968, 35.649108163060788],
              [-79.82564039206747, 35.645910114426691],
              [-79.823844104516837, 35.643215477326734],
              [-79.824966073452757, 35.639563458418195],
              [-79.826895996763298, 35.638469886485375],
              [-79.827415624092794, 35.634462954783899],
              [-79.82231575768833, 35.632944394154471],
              [-79.824549147969961, 35.627270317707087],
              [-79.823458264550808, 35.62603232864307],
              [-79.825989907641372, 35.625293651537163],
              [-79.823967691743505, 35.623655391965606],
              [-79.82477034951593, 35.618327894522174],
              [-79.822758665840851, 35.615059573655358],
              [-79.817762993282457, 35.614642613777612],
              [-79.813904035841901, 35.616829471310183],
              [-79.810066042894803, 35.615756143648532],
              [-79.809202142018492, 35.613461611394747],
              [-79.805251069381498, 35.612916324351396],
              [-79.807825012993405, 35.605657871614525],
              [-79.804486849077605, 35.603837488206501],
              [-79.804451616718666, 35.600841492373966],
              [-79.800126172328788, 35.598884628057718],
              [-79.802791942461781, 35.59435811975144],
              [-79.795901199527265, 35.590143268214234],
              [-79.79007893713208, 35.590426478983005],
              [-79.784278912103005, 35.587449275024994],
              [-79.784675885367719, 35.585600618262482],
              [-79.781883945285685, 35.58439876355407],
              [-79.780941439946645, 35.577741922838982],
              [-79.776708767982541, 35.578516137497637],
              [-79.774087431608095, 35.57652181489108],
              [-79.777457526961314, 35.573452851515505],
              [-79.774609398586847, 35.564628541287071],
              [-79.778103643444297, 35.55940123557501],
              [-79.777127914146675, 35.557634554696719],
              [-79.781789118032847, 35.542234756231935],
              [-79.773962864738465, 35.539247925657108],
              [-79.778011664043376, 35.53300964084027],
              [-79.776548596385553, 35.530359554376176],
              [-79.769982503741957, 35.527818643288036],
              [-79.778056354296268, 35.518602379409948],
              [-79.776253442316374, 35.517536894977653],
              [-79.77632123973865, 35.507755874083536],
              [-79.779076096463044, 35.505961692592656],
              [-79.776423103342282, 35.500971038034955],
              [-79.777375243944959, 35.498111358288902],
              [-79.772149579540425, 35.492492208263428],
              [-79.777374945568283, 35.490224487485555],
              [-79.781466326815533, 35.485351915670392],
              [-79.778950131844638, 35.484459520555973],
              [-79.777487961784018, 35.481809334003216],
              [-79.779357425246744, 35.473093514674609],
              [-79.775561068561927, 35.46712824161024],
              [-79.778393614608163, 35.461809414779069],
              [-79.783299258971695, 35.457865596813001],
              [-79.788510895199394, 35.457227566089941],
              [-79.791670724241015, 35.45195387326212],
              [-79.787920009335465, 35.447355022463412],
              [-79.789675842247291, 35.447054258524126],
              [-79.789256158343392, 35.444276484791885],
              [-79.784452765129274, 35.441435400028546],
              [-79.785120871180865, 35.439896145657734],
              [-79.782673890007914, 35.437109388285457],
              [-79.783081264242966, 35.43363021327076],
              [-79.78129146794204, 35.430934439655658],
              [-79.787689536224889, 35.424749719989308],
              [-79.786646402603864, 35.416989960915316],
              [-79.784404206755426, 35.416407267118679],
              [-79.785965079268252, 35.404384677928441],
              [-79.783473757102783, 35.400231654397473],
              [-79.785114289126213, 35.392571665546072],
              [-79.788045479102706, 35.388354325325928],
              [-79.779815728915636, 35.382589544161206],
              [-79.775967245023224, 35.376887943235161],
              [-79.777313901238529, 35.372178974720605],
              [-79.77989415311383, 35.371176979188263],
              [-79.782145352647632, 35.362241638461569],
              [-79.779316256804478, 35.359673241603431],
              [-79.782494978003783, 35.351138529022698],
              [-79.778783422623576, 35.341647726311223],
              [-79.76999364663132, 35.338523505554818],
              [-79.769575272528627, 35.335745390702961],
              [-79.767505335583962, 35.334369897485324],
              [-79.768885477728361, 35.332657563876573],
              [-79.767426556809028, 35.330006892613241],
              [-79.764146437548817, 35.329551221796947],
              [-79.761795087354358, 35.321608279945238],
              [-79.763231713344098, 35.319631847233964],
              [-79.761220285281198, 35.3101039329676],
              [-79.757556536818015, 35.309866636714915],
              [-79.754458900019785, 35.306987783688747],
              [-79.752335681445643, 35.297987900832197],
              [-79.747203097555641, 35.296729803726791],
              [-79.74488726909081, 35.291783124277728],
              [-79.739370748663617, 35.29074319584138],
              [-79.744720447727715, 35.284687226271814],
              [-79.743084656186326, 35.27657068855482],
              [-79.738689952516992, 35.271878276525584],
              [-79.739462321780096, 35.263552787810774],
              [-79.733960071733364, 35.2608820437964],
              [-79.731601746889922, 35.254568541609025],
              [-79.725129803273603, 35.250129812090606],
              [-79.721587837814084, 35.241474449106995],
              [-79.719792232949402, 35.240407837589792],
              [-79.721681774123468, 35.236318598878725],
              [-79.724472446444196, 35.235891675584341],
              [-79.72625329440568, 35.224441870422154],
              [-79.731170860083822, 35.217238162045241],
              [-79.730237275299928, 35.210578723069929],
              [-79.727245633862992, 35.20880118915084],
              [-79.726538995683782, 35.201085036247079],
              [-79.72789788199951, 35.194745227792083],
              [-79.725586379363861, 35.189797695106549],
              [-79.728084778954681, 35.184433035190921],
              [-79.727717898229457, 35.175131721604529],
              [-79.72419864367599, 35.171103686773357],
              [-79.719301520097659, 35.168787566780651],
              [-79.716330266980222, 35.157489727442616],
              [-79.717179641900046, 35.153527261620837],
              [-79.714675080240028, 35.152633226355135],
              [-79.715952411891891, 35.149818843796609],
              [-79.721111911279735, 35.146186350409842],
              [-79.720595579361358, 35.142305181356214],
              [-79.726780150405872, 35.140176063880553],
              [-79.720576264359323, 35.137677185463758],
              [-79.717782484646989, 35.131845308097354],
              [-79.711648804973436, 35.127451027005911],
              [-79.712397456182742, 35.122386072894308],
              [-79.714542404892782, 35.120237136349175],
              [-79.714353648940133, 35.116401560457867],
              [-79.717322277533498, 35.113551474893562],
              [-79.717416343820076, 35.108394995744113],
              [-79.721503581714614, 35.10189199653027],
              [-79.726421101840529, 35.10094661113915],
              [-79.732400403988251, 35.096612268287053],
              [-79.743893203686824, 35.091686975287509],
              [-79.74501091600014, 35.08803388807015],
              [-79.752905166961142, 35.082605627121445],
              [-79.753469616257817, 35.079963617210112],
              [-79.758239298795075, 35.076547838432973],
              [-79.758533026487427, 35.073595983608939],
              [-79.761284276588029, 35.070170657962002],
              [-79.77277109097372, 35.073132402390371],
              [-79.785385398084387, 35.070808651993204],
              [-79.790469065479286, 35.06906790173295],
              [-79.796611120666967, 35.063937489820447],
              [-79.803915418168813, 35.064409654465329],
              [-79.807869949864568, 35.060062697370547],
              [-79.813325052084338, 35.059733028538204],
              [-79.817222604924311, 35.055649973259662],
              [-79.821865303154496, 35.054390880147139],
              [-79.824342363071992, 35.050654349247964],
              [-79.829189066257896, 35.051599738187591],
              [-79.834597326948, 35.049902449580749],
              [-79.834535018063491, 35.042276532449407],
              [-79.837710890281031, 35.039997093220826],
              [-79.83811366604256, 35.036516427513142],
              [-79.840962399314151, 35.034191508031881],
              [-79.840487007973294, 35.031677093101784],
              [-79.845081553710969, 35.029050399207826],
              [-79.844589083627724, 35.020276564780175],
              [-79.841994861538012, 35.016651785847827],
              [-79.850145570300839, 35.011526245748307],
              [-79.850920736355945, 35.009457461863725],
              [-79.85452397346134, 35.008325249995089],
              [-79.855849821838461, 35.005244682886492],
              [-79.8683573955893, 35.009699942437287],
              [-79.871204220285279, 35.007374227648278],
              [-79.871175871745521, 35.002745646324804],
              [-79.87643918029741, 34.996946298300827],
              [-79.875860051216137, 34.993329526931163],
              [-79.879312402503999, 34.989727433761011],
              [-79.880534072653774, 34.985544118483276],
              [-79.878089031476705, 34.984389126146539],
              [-79.876259514245092, 34.980326936432299],
              [-79.877219653735011, 34.974203049815891],
              [-79.882668367077144, 34.973870134757647],
              [-79.885160144348916, 34.976391754402862],
              [-79.889461551688782, 34.976715642506804],
              [-79.894604845048178, 34.982596627967247],
              [-79.911351555859895, 34.97811359263337],
              [-79.915326568219882, 34.978391354546794],
              [-79.920424317343191, 34.982904511729075],
              [-79.92626390348056, 34.980719426294691],
              [-79.930478055316001, 34.976677431634428],
              [-79.935544366813488, 34.976561251959893],
              [-79.939694323792835, 34.974414235604392],
              [-79.940092140446495, 34.970932993108796],
              [-79.936674757122262, 34.968012887471964],
              [-79.938928342791058, 34.963699873339259],
              [-79.94546710466571, 34.962970699213976],
              [-79.946710022693324, 34.965046376273072],
              [-79.952205971774902, 34.966076931208875],
              [-79.956221364340792, 34.969351026187333],
              [-79.961732323350958, 34.967119093267314],
              [-79.963275715313458, 34.962979860988952],
              [-79.967305755158947, 34.962991535192373],
              [-79.968278307762333, 34.964757626954103],
              [-79.972204292526754, 34.963666774953026],
              [-79.976282983504419, 34.96504483165107],
              [-79.977359993806957, 34.967913191824685],
              [-79.98591388026766, 34.967187380935144],
              [-79.99183489589565, 34.970994403950392],
              [-79.992099324024622, 34.972934716917976],
              [-79.997005251916917, 34.97197790093395],
              [-80.000529669945664, 34.975998555160501],
              [-80.005706829059747, 34.975350654933607],
              [-80.010760441407328, 34.978493246467181],
              [-80.013268957136475, 34.977750124986798],
              [-80.018976381162531, 34.980982141787656],
              [-80.022175668661731, 34.98495723108919],
              [-80.025880174523635, 34.984922024860893],
              [-80.026539476929059, 34.983380743600762],
              [-80.029424255775353, 34.98404877891668],
              [-80.035182501712811, 34.988646621589247],
              [-80.035663992861842, 34.991160352128951],
              [-80.040016618905028, 34.992845345969158],
              [-80.049181668139738, 34.989206968412766],
              [-80.050776147255291, 34.984802313030791],
              [-80.053123804150687, 34.98322045040193],
              [-80.069690540140073, 34.981138960245644],
              [-80.074339290241099, 34.974976774715508],
              [-80.078586157529003, 34.975558116596211],
              [-80.078158609989558, 34.972780138616194],
              [-80.07974151927769, 34.971637062956347],
              [-80.077795993678109, 34.966475714378433],
              [-80.087715186463114, 34.964025922396061],
              [-80.089803728747896, 34.957241092410605],
              [-80.093672420118452, 34.956410565135357],
              [-80.095429194904256, 34.951211966263017],
              [-80.100226485952049, 34.949148556358047],
              [-80.101159749364015, 34.946284793425484],
              [-80.106287367805351, 34.942634708334573],
              [-80.113043169041703, 34.940838563428358],
              [-80.112130897928338, 34.935547063134216],
              [-80.114747224395302, 34.934272921042904],
              [-80.115845035130974, 34.930615760192097],
              [-80.122064438608845, 34.924938829635742],
              [-80.127130822348093, 34.923183276677619],
              [-80.132677553778706, 34.925572105487824],
              [-80.135626896623705, 34.921079841647952],
              [-80.142218608512309, 34.918444144249477],
              [-80.144077778428283, 34.914346874680504],
              [-80.142775376324849, 34.912537614708839],
              [-80.144630967671418, 34.910071438003392],
              [-80.141380438065582, 34.903101547905372],
              [-80.141930233217394, 34.9004572170684],
              [-80.145685579985695, 34.900153794779122],
              [-80.150413545175425, 34.903246084292995],
              [-80.154059119040909, 34.903471268355673],
              [-80.157006367591407, 34.898978365019268],
              [-80.16272607018675, 34.895679074183164],
              [-80.162787014948876, 34.892152323236111],
              [-80.16463849243523, 34.891316936901426],
              [-80.171697809516559, 34.899348710227819],
              [-80.174202189397406, 34.898602091585083],
              [-80.176157938601918, 34.900499919656816],
              [-80.178283732717844, 34.898342074847903],
              [-80.182853574704211, 34.898964128980886],
              [-80.188285688942443, 34.90514156755421],
              [-80.186812902530107, 34.907388409612956],
              [-80.192903671499195, 34.910392161598615],
              [-80.195510820386659, 34.914009558237652],
              [-80.199978856167647, 34.910266478460898],
              [-80.203843995367606, 34.909432280970982],
              [-80.209838233229874, 34.903177285842226],
              [-80.21495590859962, 34.90115343710152],
              [-80.220614990759785, 34.901378088221648],
              [-80.227747274918443, 34.89772396918768],
              [-80.226660253887175, 34.896488860873241],
              [-80.229109332516401, 34.896005542292691],
              [-80.230091826801072, 34.892876124142767],
              [-80.235045822344077, 34.890013649739302],
              [-80.234069609625351, 34.886618474229458],
              [-80.235159585625027, 34.884591191890792],
              [-80.246750983585827, 34.88045349623787],
              [-80.24582771861418, 34.878425077976146],
              [-80.24930980250457, 34.877809591066452],
              [-80.248658267856641, 34.876089921834875],
              [-80.25154322763845, 34.873490199594606],
              [-80.250348162375687, 34.871153072994893],
              [-80.254537132407677, 34.870361410717507],
              [-80.255137374188593, 34.867451966205039],
              [-80.258564334442582, 34.867100693078591],
              [-80.257912683767131, 34.865381056116284],
              [-80.263243830176492, 34.863928235775887],
              [-80.262701173251543, 34.861679618122245],
              [-80.268415924788428, 34.853481390525495],
              [-80.274453085244446, 34.851851914564747],
              [-80.276520914660892, 34.848325460065112],
              [-80.27918491514167, 34.850045491310539],
              [-80.283589798618848, 34.849825956965248],
              [-80.284241890831126, 34.851545479071895],
              [-80.287015455884855, 34.851105074526203],
              [-80.289679454059893, 34.854456085288973],
              [-80.297673983417596, 34.853090184059496],
              [-80.301970127212726, 34.855030220190905],
              [-80.305668235082251, 34.853354960626994],
              [-80.306755827233104, 34.84969571482894],
              [-80.31018176180531, 34.849342926527413],
              [-80.310725040163604, 34.841803943672815],
              [-80.314150420276661, 34.839819791194223],
              [-80.312029345471146, 34.833823977939666],
              [-80.31757459247045, 34.831310511954982],
              [-80.320673772653123, 34.832544615993527],
              [-80.327523489490716, 34.830206851253095],
              [-80.328664001304219, 34.826282772957612],
              [-80.333882578034462, 34.825355764030029],
              [-80.334207553262772, 34.82213722447738],
              [-80.33768501988051, 34.818256515657808],
              [-80.337085594524652, 34.814641394944829],
              [-80.345835885913331, 34.812831053772229],
              [-80.346378021919023, 34.81018552143972],
              [-80.348932932594494, 34.81080184736566],
              [-80.350941095229302, 34.805907181138629],
              [-80.35344069894775, 34.805156663725498],
              [-80.350557911685584, 34.801233849226044],
              [-80.352785450191178, 34.800174819488277],
              [-80.352619902546451, 34.79607454505792],
              [-80.356042172611197, 34.79408907267068],
              [-80.356961871398767, 34.78796015795708],
              [-80.359404398611261, 34.784211385206575],
              [-80.365869772788784, 34.783723231015088],
              [-80.366193237545815, 34.780504461072049],
              [-80.372925898160545, 34.775430348238338],
              [-80.382109897254622, 34.778026042947104],
              [-80.386942770054901, 34.775685970033038],
              [-80.391235029867204, 34.775991537382531],
              [-80.391348551165379, 34.780356427329288],
              [-80.39471699227478, 34.780265725376069],
              [-80.396621142643042, 34.782424691588901],
              [-80.397933171760954, 34.789125385464587],
              [-80.400433784426866, 34.790005185132173],
              [-80.401634176058337, 34.793972304491895],
              [-80.404733199640887, 34.795204233621774],
              [-80.409197358942635, 34.801240652380649],
              [-80.402959223717261, 34.810460688022104],
              [-80.402965418073819, 34.815354564034038],
              [-80.409379128920762, 34.815128602652159],
              [-80.412697351554627, 34.816933254677814],
              [-80.420955599827195, 34.814235977313423],
              [-80.424055746792803, 34.81546736879217],
              [-80.426942806023689, 34.819388314394246],
              [-80.433405282812885, 34.815633882252527],
              [-80.438190677338014, 34.816818955947546],
              [-80.4412825757192, 34.813156028341609],
              [-80.441549130236012, 34.810201749253032],
              [-80.44617270250167, 34.812180295177448],
              [-80.450727277506218, 34.806266778356949],
              [-80.462254765309012, 34.808897268525428],
              [-80.466327162774462, 34.806995905512927],
              [-80.466387773879788, 34.80999389226254],
              [-80.470363030590505, 34.81351549132382],
              [-80.468960942392357, 34.81876403759707],
              [-80.470979210447368, 34.822023767633929],
              [-80.476522038932615, 34.821134030347146],
              [-80.478268184996352, 34.824085404541428],
              [-80.485123772035109, 34.826632222602861],
              [-80.490521258535708, 34.833060710592321],
              [-80.494482796741252, 34.830056386843054],
              [-80.496989893914034, 34.832565343844912],
              [-80.502049948481513, 34.834055958803809],
              [-80.505907975282696, 34.833211746860911],
              [-80.508200850700405, 34.836778935141098],
              [-80.514946812914133, 34.838221970131656],
              [-80.518822979274745, 34.843902301910454],
              [-80.517584593026299, 34.848357394520079],
              [-80.521237755134337, 34.851833628090183],
              [-80.519731136947158, 34.857611812078829],
              [-80.521095100182919, 34.859152355573272],
              [-80.518819038120924, 34.862110343442502],
              [-80.520889639089773, 34.86347324666994],
              [-80.52209737802761, 34.867438821848857],
              [-80.525094003278767, 34.869196707185765],
              [-80.522062083204574, 34.874228197891796],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "LakeTillery",
        BASIN_ID: "LakeTillery_Basin",
        BASIN_NAME: "Lake Tillery",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.06068700906269, 35.448102226437491],
              [-80.061060927647361, 35.451143271827668],
              [-80.057824253848253, 35.452061836224956],
              [-80.060827872785026, 35.455460843241468],
              [-80.058243195689897, 35.458099166133252],
              [-80.059552895314894, 35.459908420354168],
              [-80.058940555459401, 35.462815201687619],
              [-80.062172480688858, 35.463526876615489],
              [-80.062986625123202, 35.466084105158977],
              [-80.06022418973086, 35.472775703234021],
              [-80.058028677823202, 35.473564228798786],
              [-80.057912033768616, 35.47572297102915],
              [-80.054891707084295, 35.477214681292871],
              [-80.054061620711721, 35.4795481426615],
              [-80.047691561634593, 35.482486500652413],
              [-80.047295983802684, 35.48596642446995],
              [-80.043951772354191, 35.485782837035579],
              [-80.041438140236508, 35.48326580045989],
              [-80.039242132803736, 35.48405396996052],
              [-80.048779486756473, 35.501390730531078],
              [-80.045797080580428, 35.507508537333194],
              [-80.050274486500143, 35.513554439147192],
              [-80.049165154178141, 35.517208971399548],
              [-80.04685674707639, 35.51852572891741],
              [-80.047670965027535, 35.521082940208657],
              [-80.046399656679995, 35.523899948330573],
              [-80.050180597035649, 35.52522993449864],
              [-80.05054903129394, 35.529900974643077],
              [-80.055423611862722, 35.532466780822496],
              [-80.052395586196326, 35.53558851244626],
              [-80.043443974787124, 35.537904143238876],
              [-80.040247869768706, 35.541818230336993],
              [-80.051638340471214, 35.548804402142906],
              [-80.054037595767994, 35.553479672111955],
              [-80.058098832332803, 35.553488225603857],
              [-80.058366963314157, 35.555427332945897],
              [-80.063076264371816, 35.558785458214381],
              [-80.067299827969592, 35.559631124041559],
              [-80.065739766617597, 35.567029645735637],
              [-80.071326380732643, 35.571050038975912],
              [-80.068550750504073, 35.581001419025277],
              [-80.077385530538351, 35.582472634437615],
              [-80.083521149050569, 35.587110245109855],
              [-80.083623016711357, 35.589841944770413],
              [-80.087625874958803, 35.591743801804576],
              [-80.092074133459136, 35.59153161436182],
              [-80.093971884076296, 35.60021410979855],
              [-80.09105406763419, 35.602808176233587],
              [-80.094946495661404, 35.605238235288382],
              [-80.098462978846911, 35.604627646327224],
              [-80.099720517051637, 35.606700465406682],
              [-80.099711721132067, 35.609960582326821],
              [-80.097290089028945, 35.611806694846777],
              [-80.097717475577312, 35.616213008006703],
              [-80.101503889632994, 35.617541249883054],
              [-80.098581243319018, 35.621765505238081],
              [-80.103069547705488, 35.627808820270921],
              [-80.107408624727213, 35.628124534779133],
              [-80.108825241231756, 35.632664559847939],
              [-80.101776730989059, 35.638776280553415],
              [-80.096276984553015, 35.64074916545394],
              [-80.093785030390464, 35.647749412045172],
              [-80.085424945421494, 35.650421508807199],
              [-80.085582682046777, 35.652888840096331],
              [-80.078966692598087, 35.660145313140482],
              [-80.077776497903059, 35.67221375942848],
              [-80.072324504933945, 35.675551232259807],
              [-80.073247842297008, 35.679209470750742],
              [-80.063336326946413, 35.68438793086613],
              [-80.066400047254405, 35.689151869388269],
              [-80.06573544216397, 35.690692379715216],
              [-80.069525001735286, 35.69202156155584],
              [-80.0686879549894, 35.695984641232108],
              [-80.064334850911095, 35.698927400547376],
              [-80.064288396151568, 35.713596725871113],
              [-80.060415779964018, 35.720681153220269],
              [-80.063531444302058, 35.726810729429175],
              [-80.068368318574798, 35.728009881012078],
              [-80.069901166322623, 35.730391713002284],
              [-80.072763797567546, 35.729692535757387],
              [-80.076138435236743, 35.723355676294659],
              [-80.083832853657597, 35.725484771211541],
              [-80.085091990319526, 35.727557539003129],
              [-80.097508686304778, 35.73304231593152],
              [-80.101240815774148, 35.73626453660205],
              [-80.099530870346669, 35.737935527531704],
              [-80.09929911957083, 35.742252129986852],
              [-80.104111669055712, 35.753229036094126],
              [-80.107742108158035, 35.753719692647429],
              [-80.110429806873469, 35.757071976384118],
              [-80.111622032973571, 35.764298182300699],
              [-80.110126171119958, 35.768172143505929],
              [-80.113419024817546, 35.771877713053371],
              [-80.113792536907425, 35.77654760069499],
              [-80.125288307547578, 35.780001469041771],
              [-80.128912596824065, 35.783751123864697],
              [-80.132437061160829, 35.783139615753342],
              [-80.133038089942275, 35.785122716529635],
              [-80.135790924731367, 35.784950481827529],
              [-80.136937580114221, 35.789180854577502],
              [-80.140236190178001, 35.791255802993362],
              [-80.145352993574491, 35.792760480696543],
              [-80.148328477729848, 35.791531075079746],
              [-80.150636779665774, 35.79347226914733],
              [-80.148095962128778, 35.797477397975399],
              [-80.145617343289913, 35.797958628936989],
              [-80.145610493035662, 35.801218241348003],
              [-80.137513585414055, 35.802572589332691],
              [-80.130674322118068, 35.807628334782322],
              [-80.125549097463804, 35.809382632607971],
              [-80.123496041953203, 35.815898686107744],
              [-80.11979885678619, 35.818932304349815],
              [-80.11346275808917, 35.819979397324552],
              [-80.108735051277833, 35.816623993923486],
              [-80.102840862804072, 35.817186720918905],
              [-80.103111065096329, 35.819125308035176],
              [-80.097044778729867, 35.8221101073377],
              [-80.096649692629839, 35.82558921753278],
              [-80.089582311181815, 35.831699263055938],
              [-80.080932145599192, 35.832432053853609],
              [-80.081090114144999, 35.834899030373784],
              [-80.078274600718871, 35.836963927532601],
              [-80.07860012311788, 35.838638361266405],
              [-80.07429512427305, 35.841316973231777],
              [-80.073405758431363, 35.843914044856497],
              [-80.069115481945133, 35.841703217537493],
              [-80.06580634766577, 35.842885877027051],
              [-80.061521580973348, 35.839045021837407],
              [-80.059097003825102, 35.839260260500012],
              [-80.059395732326209, 35.849083469806025],
              [-80.056736320375236, 35.853614803248469],
              [-80.058220680211591, 35.854630983368935],
              [-80.057549144808476, 35.857800975229104],
              [-80.052917260804833, 35.858804310090832],
              [-80.052463978301134, 35.862547336341741],
              [-80.048319190980152, 35.866062192162133],
              [-80.049467690094332, 35.868663426023019],
              [-80.047961079459682, 35.874165996141421],
              [-80.045641803567989, 35.875482346533971],
              [-80.042862950721371, 35.882171287116925],
              [-80.039770121192348, 35.883926267283044],
              [-80.041411635263501, 35.887409564747422],
              [-80.039579167115704, 35.891237484201163],
              [-80.041114406818792, 35.893619382907424],
              [-80.040497864091648, 35.896525033756042],
              [-80.042369519917031, 35.897322010278025],
              [-80.0419211429532, 35.89943521062861],
              [-80.037605654866908, 35.903742040580909],
              [-80.03214700216212, 35.903817718573407],
              [-80.031249577427218, 35.908044016261243],
              [-80.032403987303667, 35.909015668611111],
              [-80.030346147931496, 35.913899952561039],
              [-80.032324449717024, 35.915798450629232],
              [-80.026909079998788, 35.918869053452177],
              [-80.020701211397991, 35.927266935271668],
              [-80.014648015971005, 35.923728714625881],
              [-80.005936878963311, 35.923090482657898],
              [-80.000377480233965, 35.92043366447075],
              [-79.997778096540898, 35.908666903810264],
              [-79.994577375132252, 35.909319265562495],
              [-79.990616339326451, 35.907150625391822],
              [-79.987822956597611, 35.902694602098691],
              [-79.983693703185139, 35.901318092104525],
              [-79.981730889728212, 35.896159414487812],
              [-79.970723516670304, 35.891944590226601],
              [-79.966061229139427, 35.886689894885976],
              [-79.954984720531385, 35.885997082752425],
              [-79.952798873638443, 35.881894316652875],
              [-79.94154622466111, 35.883622275918469],
              [-79.930696081055999, 35.881870776986297],
              [-79.926834923691317, 35.882431152088785],
              [-79.925665971720576, 35.884717836066045],
              [-79.921216572777183, 35.881664346019008],
              [-79.919120727567176, 35.881921835604054],
              [-79.919298399807531, 35.879499856822818],
              [-79.914366457069661, 35.873933868930195],
              [-79.910570541857822, 35.872599923721566],
              [-79.90764453963483, 35.873559200684127],
              [-79.90550952899379, 35.8708211394963],
              [-79.904213630542472, 35.865751378848913],
              [-79.906478563877272, 35.864701871193617],
              [-79.908260612412278, 35.861140022605646],
              [-79.9052649521105, 35.854478840501727],
              [-79.898401450739399, 35.850006745935211],
              [-79.897662735542795, 35.84392563814454],
              [-79.895641945638204, 35.840659150488527],
              [-79.885129276955496, 35.838948622192547],
              [-79.882826385025794, 35.83700234519543],
              [-79.881052811711172, 35.838934137836311],
              [-79.874721012882034, 35.838338738831624],
              [-79.872144736494889, 35.836082925422744],
              [-79.86724748220422, 35.835139961799221],
              [-79.865332021145477, 35.83297452473596],
              [-79.865256550501059, 35.826983676132286],
              [-79.8583508430157, 35.82114349305786],
              [-79.847454875008594, 35.819648393824941],
              [-79.840334429037782, 35.813233658541876],
              [-79.834825386960532, 35.813564358190668],
              [-79.834402030602533, 35.810787594676015],
              [-79.831838037231691, 35.806901081096257],
              [-79.829470560836384, 35.806847571127086],
              [-79.827953944092542, 35.802833003716167],
              [-79.821398596447693, 35.803291018811791],
              [-79.816127731217591, 35.800934810074665],
              [-79.812518068131268, 35.797175681212508],
              [-79.811822344795573, 35.794089319648052],
              [-79.816690939189684, 35.790409274773609],
              [-79.81535608475491, 35.783972490102812],
              [-79.817300271604594, 35.781249393263806],
              [-79.820080141653804, 35.768309992771258],
              [-79.81871410554362, 35.766762631321534],
              [-79.818585650206856, 35.761035513387348],
              [-79.81494204239084, 35.754280724924143],
              [-79.817318329085964, 35.752704680050002],
              [-79.817303434797907, 35.746449346992513],
              [-79.821874872898533, 35.745719184225486],
              [-79.822215061733957, 35.744134720451875],
              [-79.820349485597987, 35.743334186128372],
              [-79.822096732988541, 35.736777634671071],
              [-79.827826958151689, 35.735391177559215],
              [-79.83043340664257, 35.723243313664227],
              [-79.82946455454092, 35.719847414163468],
              [-79.827599497453193, 35.71904697811204],
              [-79.826955100749544, 35.707811001274216],
              [-79.818564098404721, 35.704208591459555],
              [-79.815802999022054, 35.706003392973145],
              [-79.814051817321939, 35.704674569230676],
              [-79.814763651259042, 35.696615824028335],
              [-79.811669259346942, 35.690479586709607],
              [-79.813271074916727, 35.689340856750107],
              [-79.823045611166179, 35.691231250219239],
              [-79.831439642895006, 35.685317885560131],
              [-79.840188649424164, 35.684074975869898],
              [-79.842655574570045, 35.68523001717611],
              [-79.845924413606028, 35.681057642272968],
              [-79.844785699821173, 35.678454060135522],
              [-79.847281927218333, 35.674719160821553],
              [-79.849918307109689, 35.675081747076113],
              [-79.851057145935926, 35.677685287135269],
              [-79.854249357621825, 35.677036668897465],
              [-79.853535185741805, 35.667694525203054],
              [-79.848951257323179, 35.66217019634631],
              [-79.852520780917501, 35.653417055171921],
              [-79.858027736278629, 35.651455508047114],
              [-79.857736595059734, 35.644890249634258],
              [-79.860795954067129, 35.638513790842396],
              [-79.867007488776721, 35.638008267701331],
              [-79.869849871326764, 35.640573940575635],
              [-79.875599573615716, 35.634295048258068],
              [-79.880991732747432, 35.632860657340956],
              [-79.884217846718172, 35.625691136798778],
              [-79.881206328028384, 35.623918172150773],
              [-79.88187407952519, 35.622378613689669],
              [-79.887538235870963, 35.621253275768467],
              [-79.890979828631359, 35.614656992331042],
              [-79.897037660594378, 35.611682220437103],
              [-79.903281162462577, 35.604654593612423],
              [-79.902310572602488, 35.601258957483317],
              [-79.90027003147118, 35.602882082204189],
              [-79.894568654132925, 35.601012078856471],
              [-79.896137267678611, 35.595246103908792],
              [-79.894396016921974, 35.59228827665904],
              [-79.89556142324723, 35.590001362027166],
              [-79.894128344520738, 35.580832538259948],
              [-79.898030782707067, 35.58000893984979],
              [-79.900269995234936, 35.582219478749195],
              [-79.902968327921457, 35.580686678997452],
              [-79.903916718139129, 35.577826179186658],
              [-79.908035505514533, 35.577575727770622],
              [-79.909160945487884, 35.572292615224931],
              [-79.910975436537058, 35.571725925941443],
              [-79.916835449539931, 35.574432802784287],
              [-79.917670765709573, 35.572100498077518],
              [-79.921524482437263, 35.56991017955778],
              [-79.920313544343131, 35.559684855994384],
              [-79.924166557986567, 35.557494428295101],
              [-79.925996799760668, 35.553667256790249],
              [-79.92622968258074, 35.55098045390929],
              [-79.923388742339853, 35.548415939630701],
              [-79.927297284698057, 35.545961219740768],
              [-79.928766032355782, 35.537462596873468],
              [-79.92669584803491, 35.534459992698771],
              [-79.919602109335415, 35.537565256761184],
              [-79.913792688372553, 35.536224413924742],
              [-79.911827179675242, 35.534323363492149],
              [-79.910921761922225, 35.529033270206071],
              [-79.908073666402515, 35.52809850074101],
              [-79.905650000859623, 35.5299408207581],
              [-79.904944393541541, 35.528484485032095],
              [-79.899135921438244, 35.527142912261354],
              [-79.897227286449663, 35.524977442084321],
              [-79.898006390439264, 35.522909339328635],
              [-79.896379265042356, 35.519423032164241],
              [-79.889152350502286, 35.516798316470897],
              [-79.886832259284574, 35.51974212152237],
              [-79.885356697851364, 35.518723523823724],
              [-79.891092745913568, 35.502926270695582],
              [-79.886515993074283, 35.497402623184598],
              [-79.887576536134702, 35.494013722452848],
              [-79.88644813613007, 35.489779917495355],
              [-79.888870990197233, 35.487937926599898],
              [-79.888503749013182, 35.48489643990132],
              [-79.886155167660945, 35.483213825122277],
              [-79.88957290682356, 35.479877259164198],
              [-79.895753858126568, 35.482630609941054],
              [-79.900421213379786, 35.481368918198484],
              [-79.905555186211643, 35.474733168430362],
              [-79.90728047496583, 35.46980412689436],
              [-79.912283941173996, 35.466956875448716],
              [-79.914224949285483, 35.462601219402416],
              [-79.917848617954178, 35.461467550017176],
              [-79.918354009976383, 35.459089872853554],
              [-79.926162026588372, 35.454180250196544],
              [-79.928646945688399, 35.45044292493003],
              [-79.934738468018509, 35.448831884518881],
              [-79.941030672560089, 35.45105455094847],
              [-79.944172007265237, 35.447407037505172],
              [-79.948489828551558, 35.438211123876712],
              [-79.947799510231349, 35.433494337405904],
              [-79.949737644056938, 35.429137969721481],
              [-79.94879004000083, 35.420851262647574],
              [-79.954983299015467, 35.420341032806945],
              [-79.955998649558879, 35.413954855924082],
              [-79.957760465562927, 35.412021259045289],
              [-79.955364075351028, 35.40897379535928],
              [-79.960298439831945, 35.408018911114162],
              [-79.960641607798991, 35.404803259256624],
              [-79.962403095469824, 35.402869577305658],
              [-79.967336978030914, 35.401914393852145],
              [-79.967952312731938, 35.399007930939682],
              [-79.970866525920599, 35.39641643929972],
              [-79.967540824519276, 35.392969966071675],
              [-79.972704761197392, 35.389327284206459],
              [-79.972879590765146, 35.386904231549735],
              [-79.969812346571345, 35.387027749708096],
              [-79.970315630727484, 35.384649693014097],
              [-79.966990465868918, 35.38120316776164],
              [-79.970232375595913, 35.378656655482729],
              [-79.972111936889448, 35.374563938091846],
              [-79.97605187842106, 35.375103772136029],
              [-79.978203473970325, 35.371320160029882],
              [-79.982583346058888, 35.37137628021469],
              [-79.984511084805774, 35.368649494914571],
              [-79.987137561523781, 35.369009125037628],
              [-79.989015981194029, 35.364916102903976],
              [-79.993891260941041, 35.364224020292674],
              [-79.99056592183382, 35.360778095240548],
              [-79.994853422439817, 35.356471022195457],
              [-79.996458681050328, 35.352068657103025],
              [-79.994738947521299, 35.344220393095405],
              [-79.991624320251091, 35.342978300949532],
              [-79.993725078648438, 35.337828106265306],
              [-79.997180853479506, 35.335854190361822],
              [-80.002871422846582, 35.336089246584017],
              [-80.005149494459801, 35.34129489685801],
              [-80.011156160858036, 35.344835319381403],
              [-80.012295569954702, 35.347438052355294],
              [-80.015968061854537, 35.346037000819905],
              [-80.013571420264867, 35.342990536720023],
              [-80.015553069506694, 35.339998914273231],
              [-80.014698508864967, 35.334444442917743],
              [-80.016463166529405, 35.330879381074823],
              [-80.019918040990831, 35.328904786892799],
              [-80.01911899287056, 35.323086023937059],
              [-80.022523780828337, 35.319745138924283],
              [-80.020839609258573, 35.316524184606614],
              [-80.028503379745487, 35.315396669438208],
              [-80.026880655092839, 35.310281032196222],
              [-80.031875066730066, 35.305797823237768],
              [-80.032047832615078, 35.303374498840569],
              [-80.029924277028186, 35.300637353589771],
              [-80.030536421474181, 35.297730297039934],
              [-80.02890215983291, 35.295875619777412],
              [-80.030782400142357, 35.290151146456246],
              [-80.029420639777271, 35.288605575850802],
              [-80.033690343101824, 35.287557847667443],
              [-80.037148530350734, 35.283952147794437],
              [-80.040211536373079, 35.283826869161025],
              [-80.039361478050793, 35.276641755108031],
              [-80.033048323452846, 35.268166088318772],
              [-80.034494241871826, 35.261294600228901],
              [-80.040246261656847, 35.25800245999045],
              [-80.038641000926063, 35.24799498710118],
              [-80.042580494435128, 35.246902084912122],
              [-80.042536113588028, 35.243905216430818],
              [-80.044069593600511, 35.243027221858107],
              [-80.044575177092, 35.239017942674955],
              [-80.040808458075787, 35.237687433990281],
              [-80.042897438522388, 35.23416646248409],
              [-80.041375358288221, 35.231783247028282],
              [-80.044169626763548, 35.229718148840114],
              [-80.049358106484974, 35.230699092425908],
              [-80.049146872929796, 35.22849509392934],
              [-80.052152120311987, 35.228633802857352],
              [-80.055445571824436, 35.224189672144696],
              [-80.058889387711758, 35.223844358541484],
              [-80.059354281013157, 35.215207375983454],
              [-80.061103723223908, 35.214902516565743],
              [-80.061330839445617, 35.212214629317195],
              [-80.062807996789189, 35.211600678916511],
              [-80.062712535985312, 35.207237392643691],
              [-80.072935582633107, 35.205098492936429],
              [-80.075330271933396, 35.208144142232761],
              [-80.079806915652213, 35.209298661442126],
              [-80.082903340409572, 35.215430483627202],
              [-80.086674348909995, 35.215129044862095],
              [-80.089619165930586, 35.217161754536029],
              [-80.093616223043412, 35.214172156977568],
              [-80.09488513609837, 35.209723212330935],
              [-80.098328124102366, 35.209376741387366],
              [-80.100520011857071, 35.206956634095285],
              [-80.103141490680045, 35.20731373708167],
              [-80.106741838403295, 35.209435300277867],
              [-80.107333489618682, 35.213050158478829],
              [-80.110772430689437, 35.214333959437283],
              [-80.112126288617702, 35.219139954745806],
              [-80.115237833722631, 35.220379008834676],
              [-80.117247620445156, 35.225274115603725],
              [-80.122768397358399, 35.224665808315173],
              [-80.12649205241307, 35.221366196929601],
              [-80.140841145825561, 35.231743751611766],
              [-80.148329088408602, 35.231401404201783],
              [-80.150020052731165, 35.232990186528205],
              [-80.151444992165693, 35.231008880181989],
              [-80.164180967526477, 35.229746780620239],
              [-80.165541422875549, 35.23292148637158],
              [-80.167508422626028, 35.233188227326686],
              [-80.166791725699213, 35.236624882010105],
              [-80.164766508031605, 35.238253085889745],
              [-80.166289593066665, 35.242265284428257],
              [-80.164638253620325, 35.248565336761963],
              [-80.170587011414, 35.253992871961692],
              [-80.168886441045956, 35.25729613456609],
              [-80.170578403258219, 35.2588845832327],
              [-80.170674336880154, 35.266508682053271],
              [-80.176024032115379, 35.271582612371688],
              [-80.178430303853787, 35.271364883145296],
              [-80.184875139523015, 35.276042943379259],
              [-80.188047759637442, 35.275385111808269],
              [-80.189467568037813, 35.276664509643524],
              [-80.184046028665293, 35.281638809063544],
              [-80.180090763765563, 35.292519541718498],
              [-80.183694657045606, 35.296269094634702],
              [-80.183744593295273, 35.299265760337832],
              [-80.185437791243686, 35.300853933717953],
              [-80.182699865693721, 35.302657893246526],
              [-80.18520964996884, 35.306802828585006],
              [-80.180334439317804, 35.310763828171957],
              [-80.181586729213365, 35.314466798194388],
              [-80.171787813060988, 35.317761142799718],
              [-80.168058495899714, 35.322692359784966],
              [-80.171988614901466, 35.328117077519728],
              [-80.167547081681946, 35.333223690536023],
              [-80.168412722658545, 35.338777054156083],
              [-80.166875800271725, 35.341287031175881],
              [-80.168284791153255, 35.349088348645886],
              [-80.164338829042379, 35.351815775095282],
              [-80.162577537859519, 35.357013395706289],
              [-80.166073161145178, 35.361291915175784],
              [-80.166724015930711, 35.364641639159736],
              [-80.16469240849213, 35.367900053216552],
              [-80.165613470368029, 35.373188938964844],
              [-80.170316255711825, 35.37654333363907],
              [-80.16866817845299, 35.379581896427148],
              [-80.169979145106524, 35.381390052774208],
              [-80.168496650998478, 35.38363563068377],
              [-80.170354204689446, 35.386061308996297],
              [-80.168868720154407, 35.389937253824264],
              [-80.171874833125187, 35.393333625142205],
              [-80.171918677252322, 35.399590748094731],
              [-80.174649176279516, 35.404308615374667],
              [-80.177222549040508, 35.404928325391943],
              [-80.177219815625222, 35.406558672919857],
              [-80.182908354438126, 35.411676060961561],
              [-80.181917262828733, 35.414803518538619],
              [-80.183660396996373, 35.421018216488285],
              [-80.190773073887627, 35.427414434787138],
              [-80.191754270377643, 35.430808202538401],
              [-80.196845633874162, 35.433941423910326],
              [-80.19541339501761, 35.439183514111086],
              [-80.19984796579854, 35.442227845086691],
              [-80.200498752428558, 35.447207428630954],
              [-80.203181917650468, 35.448928191425829],
              [-80.205814301061992, 35.447652644834726],
              [-80.206524893732464, 35.449107281980027],
              [-80.204766377017023, 35.452674799158956],
              [-80.198293998989584, 35.456149979564842],
              [-80.191775523558206, 35.453368013915181],
              [-80.185031488997538, 35.454903496729905],
              [-80.1866830734117, 35.450234628029804],
              [-80.183076991483858, 35.443225107146503],
              [-80.167193031026699, 35.438360765291485],
              [-80.164394459958089, 35.440428383840896],
              [-80.163291648824369, 35.444084217628159],
              [-80.157752192104667, 35.446324589805286],
              [-80.155825487769633, 35.450684292146811],
              [-80.150565363601274, 35.449972530936222],
              [-80.14513583378924, 35.451683704302866],
              [-80.148111460637864, 35.443932806924295],
              [-80.144772275664295, 35.442121770960398],
              [-80.146640659910986, 35.43965681377184],
              [-80.142917637832525, 35.438065513483402],
              [-80.145114672420718, 35.435645085331743],
              [-80.144848185468419, 35.432075665634784],
              [-80.14074505442359, 35.429073765205516],
              [-80.138112225536347, 35.430347883440106],
              [-80.135265036844572, 35.429418504159244],
              [-80.124985253943009, 35.42041444447846],
              [-80.122898857955434, 35.422305947534305],
              [-80.119394926811509, 35.421287036462289],
              [-80.121881065529521, 35.412654550935656],
              [-80.125063742649601, 35.410368157917809],
              [-80.122059039913637, 35.406970647575143],
              [-80.110453172320064, 35.404308251843425],
              [-80.108247532200281, 35.409988786486686],
              [-80.103323513783181, 35.407689167046932],
              [-80.107231753348344, 35.40033715747704],
              [-80.100281895366834, 35.39803397438628],
              [-80.100350491441489, 35.392878605933021],
              [-80.097292090719421, 35.389744701490528],
              [-80.098077664137293, 35.382783913658237],
              [-80.101818762478757, 35.376224792143837],
              [-80.093229286797978, 35.373697977548105],
              [-80.088534681435846, 35.368710107433444],
              [-80.086342448790859, 35.369499231860402],
              [-80.085560630134026, 35.374829636271386],
              [-80.087581863108383, 35.376463779229589],
              [-80.087299671172488, 35.379415593638583],
              [-80.084505807290725, 35.379851047312172],
              [-80.084662956098654, 35.382318957779447],
              [-80.081702373833394, 35.383547193565299],
              [-80.079828458445618, 35.387641619376971],
              [-80.076432915297318, 35.387723214287867],
              [-80.074831513117374, 35.392126523227709],
              [-80.07143576547962, 35.392207974145627],
              [-80.066377337229142, 35.398587131371194],
              [-80.06105559731013, 35.401396330417988],
              [-80.065260201259392, 35.405502878110674],
              [-80.072765498014178, 35.405165386484349],
              [-80.073523796407841, 35.407986946701904],
              [-80.069015449240197, 35.413353750648739],
              [-80.072619588867667, 35.417106282191028],
              [-80.072604693191536, 35.421997254170897],
              [-80.069652437422263, 35.419964502156333],
              [-80.061306013793484, 35.425896054475835],
              [-80.063267284844272, 35.429425110717453],
              [-80.062518153954642, 35.441012003716601],
              [-80.065698673810516, 35.440357562658896],
              [-80.067883017578993, 35.442829456418714],
              [-80.067438014154007, 35.444943543517113],
              [-80.06068700906269, 35.448102226437491],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "HighRock",
        BASIN_ID: "HighRock_Basin",
        BASIN_NAME: "High Rock",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.071776264680551, 36.123010581458317],
              [-80.065878569894551, 36.117053687177176],
              [-80.0631343603049, 36.110706570490244],
              [-80.063813104823765, 36.105907707209674],
              [-80.060885293821784, 36.105241157552356],
              [-80.059406573728509, 36.100966328587646],
              [-80.060957211555589, 36.100088733339447],
              [-80.06246737289581, 36.094586913747165],
              [-80.061600859848539, 36.089036180209661],
              [-80.064436568976802, 36.083713154293534],
              [-80.063003746714941, 36.082433100015137],
              [-80.065165920834758, 36.080279528216401],
              [-80.0619742404173, 36.076045231941457],
              [-80.058828425114768, 36.074805650965338],
              [-80.0588443382523, 36.069917241645044],
              [-80.067052948369636, 36.060156999085123],
              [-80.064749015931426, 36.054955594419518],
              [-80.066686053047746, 36.053858474476918],
              [-80.067246799299937, 36.051217157981498],
              [-80.061069345607095, 36.048209886982661],
              [-80.06091158413075, 36.045743277757474],
              [-80.059092163682763, 36.044682551067602],
              [-80.054673326115818, 36.044629297229548],
              [-80.048828169786773, 36.041666114970845],
              [-80.049394953360235, 36.037395350161255],
              [-80.044757589095468, 36.036768722943897],
              [-80.042225658001755, 36.034252810359234],
              [-80.041849618469172, 36.031213130185222],
              [-80.039093353009292, 36.029753637785952],
              [-80.041479283587165, 36.026543920522826],
              [-80.039660596055711, 36.025482882535123],
              [-80.039571157134233, 36.019493022390137],
              [-80.042444985272496, 36.018794731476781],
              [-80.044392643211253, 36.014438875140037],
              [-80.040486521938533, 36.010378395360704],
              [-80.035335793570141, 35.99935628282239],
              [-80.029595354943027, 35.999122918287732],
              [-80.026459255955444, 35.996252857552172],
              [-80.026140209927377, 35.992948919776907],
              [-80.023548122883483, 35.9923262178893],
              [-80.025602515704321, 35.989071904448487],
              [-80.024172137485934, 35.987791296233482],
              [-80.025951549566045, 35.984228016566817],
              [-80.024589495658361, 35.979424131073301],
              [-80.017101945944475, 35.974605540085577],
              [-80.014966381236931, 35.970240066802212],
              [-80.01083928665264, 35.96723497778634],
              [-80.006625754028391, 35.958239585160811],
              [-80.014922639083622, 35.95284272761063],
              [-80.018037369327701, 35.94619964063336],
              [-80.02490264552516, 35.939521294085424],
              [-80.022486905869371, 35.93647651358846],
              [-80.023228859337536, 35.929783503506087],
              [-80.02075735429041, 35.927002801068966],
              [-80.026909079998788, 35.918869053452177],
              [-80.032324449717024, 35.915798450629232],
              [-80.030346147931496, 35.913899952561039],
              [-80.032403987303667, 35.909015668611111],
              [-80.031249577427218, 35.908044016261243],
              [-80.03214700216212, 35.903817718573407],
              [-80.037605654866908, 35.903742040580909],
              [-80.0419211429532, 35.89943521062861],
              [-80.042369519917031, 35.897322010278025],
              [-80.040497864091648, 35.896525033756042],
              [-80.041114406818792, 35.893619382907424],
              [-80.039579167115704, 35.891237484201163],
              [-80.041411635263501, 35.887409564747422],
              [-80.039770121192348, 35.883926267283044],
              [-80.044742669220142, 35.880523684604569],
              [-80.045087189309115, 35.876494201624105],
              [-80.047961079459682, 35.874165996141421],
              [-80.049019679805198, 35.870776699404388],
              [-80.048319190980152, 35.866062192162133],
              [-80.052463978301134, 35.862547336341741],
              [-80.052917260804833, 35.858804310090832],
              [-80.057549144808476, 35.857800975229104],
              [-80.058220680211591, 35.854630983368935],
              [-80.056736320375236, 35.853614803248469],
              [-80.059395732326209, 35.849083469806025],
              [-80.059097003825102, 35.839260260500012],
              [-80.061521580973348, 35.839045021837407],
              [-80.06580634766577, 35.842885877027051],
              [-80.069115481945133, 35.841703217537493],
              [-80.073405758431363, 35.843914044856497],
              [-80.07429512427305, 35.841316973231777],
              [-80.07860012311788, 35.838638361266405],
              [-80.078274600718871, 35.836963927532601],
              [-80.081090114144999, 35.834899030373784],
              [-80.080932145599192, 35.832432053853609],
              [-80.089582311181815, 35.831699263055938],
              [-80.096649692629839, 35.82558921753278],
              [-80.097044778729867, 35.8221101073377],
              [-80.103111065096329, 35.819125308035176],
              [-80.102840862804072, 35.817186720918905],
              [-80.108735051277833, 35.816623993923486],
              [-80.11346275808917, 35.819979397324552],
              [-80.11979885678619, 35.818932304349815],
              [-80.123496041953203, 35.815898686107744],
              [-80.125549097463804, 35.809382632607971],
              [-80.130674322118068, 35.807628334782322],
              [-80.137513585414055, 35.802572589332691],
              [-80.145610493035662, 35.801218241348003],
              [-80.145617343289913, 35.797958628936989],
              [-80.148095962128778, 35.797477397975399],
              [-80.150581186654449, 35.793736491571238],
              [-80.148328477729862, 35.791531075079746],
              [-80.145352993574491, 35.792760480696572],
              [-80.140236190177987, 35.791255802993355],
              [-80.136937580114221, 35.789180854577502],
              [-80.135790924731367, 35.784950481827529],
              [-80.133038089942261, 35.785122716529642],
              [-80.132437061160829, 35.783139615753342],
              [-80.12891259682408, 35.783751123864697],
              [-80.125288307547564, 35.780001469041792],
              [-80.113792536907425, 35.77654760069499],
              [-80.113419024817546, 35.771877713053371],
              [-80.110674957788731, 35.768789745530832],
              [-80.111622032973585, 35.764298182300706],
              [-80.110429806873469, 35.757071976384118],
              [-80.107742108158035, 35.753719692647444],
              [-80.104111669055712, 35.753229036094126],
              [-80.099461907723608, 35.743089381087991],
              [-80.099530870346655, 35.737935527531725],
              [-80.101240815774148, 35.736264536602071],
              [-80.093056719469971, 35.731624790852528],
              [-80.092127226670513, 35.729596759205847],
              [-80.094608471181559, 35.727486721155451],
              [-80.099338843603491, 35.727583132212381],
              [-80.101442928218518, 35.722432729685607],
              [-80.107766839365809, 35.723016102347067],
              [-80.10735947472034, 35.710460634361837],
              [-80.109843860146526, 35.706720320016018],
              [-80.117275416368955, 35.703648676360437],
              [-80.120510192554946, 35.707618466635644],
              [-80.123927725343478, 35.704275771476766],
              [-80.12670061181457, 35.694324116298894],
              [-80.123299240477493, 35.69114714631543],
              [-80.127149242959035, 35.690580308150707],
              [-80.129465242347592, 35.68763222430433],
              [-80.130854847097339, 35.681026322501793],
              [-80.138283048355945, 35.67795329828278],
              [-80.138953198898108, 35.673152426327427],
              [-80.137745456479408, 35.672445869855181],
              [-80.140386659339455, 35.67117202377711],
              [-80.140286180530055, 35.666810579478252],
              [-80.14557828153869, 35.659372740125818],
              [-80.150696261729536, 35.655987372053076],
              [-80.150823781394166, 35.647308860374146],
              [-80.156538075981729, 35.647536445764096],
              [-80.159289011866207, 35.645733638821611],
              [-80.162582019781112, 35.647808207547882],
              [-80.167535140389802, 35.643584848277591],
              [-80.166666101157034, 35.638032956242562],
              [-80.168649410619594, 35.635039538541328],
              [-80.171177990086704, 35.634293497271877],
              [-80.173811682568868, 35.636278915966663],
              [-80.176229474943739, 35.636061301837834],
              [-80.197901351741237, 35.618328746237161],
              [-80.209389383040516, 35.611994618191375],
              [-80.212690226062918, 35.607547620726102],
              [-80.216095410232739, 35.607462124761511],
              [-80.217740900727037, 35.609313695291519],
              [-80.220709866354127, 35.606452229537183],
              [-80.227849584862724, 35.606324950497587],
              [-80.231759737987943, 35.601467364531061],
              [-80.239090734851573, 35.599964052033386],
              [-80.240434738495907, 35.59633180603457],
              [-80.246423584694043, 35.59197336796823],
              [-80.249552520380846, 35.593208461637268],
              [-80.258229990684583, 35.590568853492996],
              [-80.258011725682394, 35.588365944216442],
              [-80.262462058888104, 35.583257077838191],
              [-80.268007583118802, 35.582642164666282],
              [-80.269327478395411, 35.577928498129189],
              [-80.272182551631701, 35.577224423077205],
              [-80.273611427071799, 35.573612144058409],
              [-80.27822307267914, 35.572599979816253],
              [-80.282341301750492, 35.569076282310945],
              [-80.285087299659992, 35.564010196018423],
              [-80.291400066404705, 35.561323606467504],
              [-80.290028520222393, 35.55815129272915],
              [-80.295243128468229, 35.554230720709533],
              [-80.296560660732311, 35.551146779192131],
              [-80.30051199286855, 35.551675702425264],
              [-80.300841661987334, 35.545199192994552],
              [-80.304024470520176, 35.544538400906404],
              [-80.30676817504893, 35.54110186209418],
              [-80.311542099496663, 35.540925477311021],
              [-80.311322057363725, 35.533832086961034],
              [-80.31911295983403, 35.532157214853754],
              [-80.322349148930215, 35.527971221491967],
              [-80.325640638269405, 35.526781117151941],
              [-80.333543040701514, 35.53272742376226],
              [-80.336394505161508, 35.528761432760348],
              [-80.34034420737629, 35.527658863491489],
              [-80.34341485573097, 35.524265386688889],
              [-80.348629946422761, 35.530123414594819],
              [-80.353789068526112, 35.532985227601735],
              [-80.360152693539433, 35.531660696374622],
              [-80.363384132827676, 35.52421322635692],
              [-80.362943154152774, 35.521437727449737],
              [-80.367442499578871, 35.522580987268313],
              [-80.370566370083822, 35.518922407880524],
              [-80.374900345897828, 35.519228355028446],
              [-80.377967568604518, 35.514203765467343],
              [-80.386355294274878, 35.509616147628165],
              [-80.386735536773443, 35.506135183620025],
              [-80.389477727791956, 35.505957027930954],
              [-80.391119741120107, 35.502915732400602],
              [-80.394958246232221, 35.502340111853592],
              [-80.395281609694194, 35.49749328393024],
              [-80.402079666538924, 35.495681463738535],
              [-80.405255268424909, 35.491757477807163],
              [-80.405525583988975, 35.488805214730512],
              [-80.411775696649457, 35.488006672854738],
              [-80.415835819789265, 35.489633181496245],
              [-80.417441123308606, 35.499897663468211],
              [-80.421007065724481, 35.50064324930122],
              [-80.423095746724186, 35.503637253922228],
              [-80.426877027866908, 35.501694802087464],
              [-80.429349536610076, 35.504468000624996],
              [-80.434558833621495, 35.50380153198941],
              [-80.441262277134527, 35.510667318451816],
              [-80.446308805796292, 35.510793593648302],
              [-80.449664293378788, 35.515856375878506],
              [-80.452732751528757, 35.514090222574644],
              [-80.457287334726132, 35.514965649417768],
              [-80.458176110388976, 35.520515964034523],
              [-80.462401897144915, 35.521347554727285],
              [-80.465921834777532, 35.525616553335276],
              [-80.478046853921569, 35.525863837149672],
              [-80.484209854197289, 35.533741202762513],
              [-80.48372160673317, 35.536121101986353],
              [-80.491370111856767, 35.545009118775582],
              [-80.490610837905464, 35.54871119137276],
              [-80.493695762141698, 35.553464584197684],
              [-80.50934479862201, 35.556346474631944],
              [-80.51225984683002, 35.558632419214256],
              [-80.512155927436496, 35.560791425894671],
              [-80.524512561489459, 35.563236425801421],
              [-80.528087268065647, 35.565608860794484],
              [-80.534401375409914, 35.566169563308655],
              [-80.539117252850033, 35.564618336568088],
              [-80.546769910593937, 35.571872449202999],
              [-80.550669992580254, 35.572657464854366],
              [-80.551468662524925, 35.581995900023088],
              [-80.554715520074822, 35.584324120707031],
              [-80.56917170620514, 35.573190439129178],
              [-80.583102222654816, 35.569589946343918],
              [-80.584408375853457, 35.566502855712308],
              [-80.583413916330898, 35.564742894272499],
              [-80.586319496750278, 35.563766769194473],
              [-80.585924094303422, 35.560727728990912],
              [-80.587619388989992, 35.55904950574223],
              [-80.595574604022033, 35.558193175991143],
              [-80.605097570917025, 35.551296409674386],
              [-80.607713824428501, 35.546751764676209],
              [-80.614343192659121, 35.544091147850217],
              [-80.618027380317088, 35.545931924675095],
              [-80.618262602263371, 35.549764369839828],
              [-80.620360364345345, 35.552754758364806],
              [-80.623762614388099, 35.552657541077515],
              [-80.62723237502874, 35.55555598762399],
              [-80.63217466957623, 35.556203317602524],
              [-80.63697709972071, 35.562534291811382],
              [-80.6405481062192, 35.563273217784371],
              [-80.643306191130762, 35.566349412803149],
              [-80.652755609729326, 35.568128427528116],
              [-80.655360798545715, 35.573627934937626],
              [-80.659652908972362, 35.575818036653899],
              [-80.669425703488884, 35.576008683105833],
              [-80.680937700276189, 35.572624375400821],
              [-80.682791925669932, 35.57015126236238],
              [-80.69194619118629, 35.578404516778541],
              [-80.702209594299049, 35.577841882994825],
              [-80.710103330998379, 35.575612302930836],
              [-80.72337749073526, 35.583584608403662],
              [-80.737151623252132, 35.582389707525515],
              [-80.739967692815057, 35.585199062375146],
              [-80.749314964114475, 35.587499417517073],
              [-80.750109593877625, 35.591946184402623],
              [-80.757969400658723, 35.593238117201174],
              [-80.759850774376744, 35.595654035185198],
              [-80.769816047529261, 35.599932934133776],
              [-80.780241573465375, 35.598569902031215],
              [-80.791061405893672, 35.590199293211995],
              [-80.803229571492238, 35.586888548385375],
              [-80.802857307374126, 35.588740510925696],
              [-80.805995069336788, 35.589960887583061],
              [-80.805686726967991, 35.59317834924223],
              [-80.807974027688374, 35.598631710952645],
              [-80.814367813368875, 35.602173000368026],
              [-80.814506771241582, 35.606533998403805],
              [-80.822083545252099, 35.614299162030271],
              [-80.822041460388803, 35.616193759252475],
              [-80.825574769336171, 35.618821662979613],
              [-80.830682159631451, 35.618666962430389],
              [-80.823693031706995, 35.624821510785274],
              [-80.822938039833986, 35.626895430605565],
              [-80.824939682974772, 35.630411123310246],
              [-80.823748463193766, 35.632971560559014],
              [-80.829933378201247, 35.637570165568306],
              [-80.837916822854709, 35.639957605396688],
              [-80.838705760911722, 35.642773591781335],
              [-80.841015627189549, 35.643071562163477],
              [-80.843860806498654, 35.649138268274491],
              [-80.843223800089248, 35.652313111868075],
              [-80.846994990128735, 35.657141964218603],
              [-80.85261754226309, 35.65949509425797],
              [-80.855735463337467, 35.657454148342239],
              [-80.862095290921189, 35.66288722316591],
              [-80.861842053966654, 35.665840059246001],
              [-80.863333443412998, 35.666846300971564],
              [-80.856525285493163, 35.675468706902848],
              [-80.860050662987391, 35.676465511759723],
              [-80.858712593165947, 35.68144985349916],
              [-80.863913012808695, 35.685918957215556],
              [-80.864874473003013, 35.6895708741691],
              [-80.872970485266464, 35.691426708693818],
              [-80.874935321254995, 35.69683585776464],
              [-80.879028704088441, 35.700076073227052],
              [-80.887721271945637, 35.700650463724173],
              [-80.895037527625874, 35.708103346541208],
              [-80.891943703725943, 35.713405024986798],
              [-80.902420924478108, 35.716921035448721],
              [-80.910732351606654, 35.717715923017501],
              [-80.914246623137998, 35.723865299301004],
              [-80.919334303637726, 35.727231119958773],
              [-80.924052276453921, 35.725664783499781],
              [-80.928638231337402, 35.728151771440132],
              [-80.932708713500389, 35.728130323384953],
              [-80.936806238575528, 35.731368462310776],
              [-80.938829455524484, 35.736511783185009],
              [-80.948609764884722, 35.73504951188059],
              [-80.952986032183702, 35.738593933088588],
              [-80.955407701019027, 35.745144432670493],
              [-80.966528843996969, 35.752263440314181],
              [-80.973905902061375, 35.752662641073371],
              [-80.977599374870834, 35.75964597197342],
              [-80.984541297885258, 35.760531541980846],
              [-80.987478411192939, 35.76280537335402],
              [-80.989596544802041, 35.765788670750837],
              [-80.989017305107907, 35.768699383012006],
              [-81.003354035308234, 35.771611446888095],
              [-81.00212892412074, 35.776067788822765],
              [-81.004180411680551, 35.777685616025387],
              [-81.002992921235162, 35.780247543377151],
              [-81.004834082925271, 35.782923806087062],
              [-81.002879905093053, 35.785930739667428],
              [-81.012846323967707, 35.792082905361731],
              [-81.013365173513478, 35.794590681919267],
              [-81.017191544082223, 35.797519179096099],
              [-81.024189943810697, 35.798137788205807],
              [-81.026934962238911, 35.803098808198989],
              [-81.035829297357765, 35.806127951191037],
              [-81.041862669664269, 35.815032814639608],
              [-81.047172066696206, 35.817334416102661],
              [-81.048023085788586, 35.819883974836259],
              [-81.052219408126845, 35.820958911285715],
              [-81.055681338749736, 35.825738489476549],
              [-81.059668274854488, 35.827871665934438],
              [-81.058881406108469, 35.831841087575093],
              [-81.062239346979553, 35.837149687093962],
              [-81.060859251165226, 35.842400285258677],
              [-81.063487090996674, 35.846259842452739],
              [-81.066580291192281, 35.858045068119004],
              [-81.068094524253667, 35.860678257755836],
              [-81.072834175767483, 35.860735853037717],
              [-81.074660329223988, 35.866934835422001],
              [-81.079886138660228, 35.871393801033811],
              [-81.080460157817825, 35.873636500871179],
              [-81.090653898978545, 35.878723207008015],
              [-81.093406171243529, 35.883682379654765],
              [-81.106182959865805, 35.88258393750921],
              [-81.113416770183136, 35.878614761639149],
              [-81.115020261287242, 35.879088380653833],
              [-81.120109911227317, 35.88596910255621],
              [-81.119726575657353, 35.891345517392587],
              [-81.122725420938281, 35.898504649925016],
              [-81.119448022396455, 35.906499629717459],
              [-81.122150887529358, 35.91172267009712],
              [-81.129910962967003, 35.915413108778473],
              [-81.145204582744185, 35.917068005153929],
              [-81.151481982132736, 35.916230691055738],
              [-81.164232858008475, 35.922173718715008],
              [-81.163208089329189, 35.929096395338533],
              [-81.159059210416785, 35.932914203890689],
              [-81.157686793400984, 35.943318887678387],
              [-81.149036893983492, 35.949282631004692],
              [-81.149997267062318, 35.951301914820917],
              [-81.148359994723435, 35.957876282368275],
              [-81.151902311770243, 35.958864187902009],
              [-81.152270224520848, 35.962164961482614],
              [-81.148432432539281, 35.964394454137157],
              [-81.147578453421445, 35.96699915388394],
              [-81.149726722042345, 35.971608653830188],
              [-81.148175379721692, 35.976024119622075],
              [-81.151718541989055, 35.977012017352457],
              [-81.154512358543556, 35.980075251865685],
              [-81.154146743047235, 35.981927729114176],
              [-81.156643126768046, 35.983055067890078],
              [-81.154812531606694, 35.987164269338315],
              [-81.152386482006165, 35.9874017694979],
              [-81.152352440654539, 35.989295916382389],
              [-81.160121924262029, 35.992984171520632],
              [-81.159915920426457, 35.999195864761347],
              [-81.164980754050362, 36.002815069574908],
              [-81.162505068750036, 36.008470512570838],
              [-81.167343289657012, 36.011518661049138],
              [-81.165672498830673, 36.019987083814087],
              [-81.166950854505032, 36.025571339911458],
              [-81.172923823940337, 36.026320780878507],
              [-81.176018696503391, 36.031319121885943],
              [-81.19226620616314, 36.036880859437154],
              [-81.196054256989697, 36.034914719901991],
              [-81.203531423557067, 36.036664366701032],
              [-81.206408298755235, 36.032414518973098],
              [-81.212278068431345, 36.033691288110681],
              [-81.219520740530413, 36.029716107846582],
              [-81.230368825000156, 36.031614344969881],
              [-81.23200835335642, 36.030192223000014],
              [-81.237422497905882, 36.030326173839406],
              [-81.240929129059708, 36.028052484099099],
              [-81.241012933347662, 36.02589369749456],
              [-81.243875704882299, 36.025166488999027],
              [-81.250357372825547, 36.026788916919628],
              [-81.250833758268968, 36.02955987538396],
              [-81.24888298667355, 36.032570284188957],
              [-81.251597140551127, 36.046466381679522],
              [-81.242642686396863, 36.050500978270072],
              [-81.242299036846489, 36.053983048805037],
              [-81.244363170538918, 36.055596382957489],
              [-81.242931219038681, 36.055959993729687],
              [-81.242067774412874, 36.062088679088745],
              [-81.23878765311764, 36.064933110577556],
              [-81.239595907985191, 36.067745480152723],
              [-81.237623651129496, 36.069126222790629],
              [-81.241980732060924, 36.072923735031715],
              [-81.241408843029163, 36.075834994111815],
              [-81.242933505242604, 36.078465528096849],
              [-81.240576925948886, 36.080069537127201],
              [-81.239640908024015, 36.084833375876336],
              [-81.23620365566066, 36.088471659546045],
              [-81.22855217031163, 36.091041491714819],
              [-81.22644275879226, 36.094845352047109],
              [-81.227166598328864, 36.099816411875928],
              [-81.224424759284574, 36.101643251386086],
              [-81.227702144738657, 36.107475401186591],
              [-81.234802938742462, 36.109446170333655],
              [-81.227665063023295, 36.118045453413167],
              [-81.223261212293608, 36.119664870875432],
              [-81.22398508023393, 36.124635863500906],
              [-81.222695403259209, 36.127728602747283],
              [-81.226214382489971, 36.130608151483138],
              [-81.226814541490526, 36.134479045783792],
              [-81.225316449974642, 36.138630358351577],
              [-81.232960738254462, 36.139584108969679],
              [-81.234310201470151, 36.141379247995545],
              [-81.243928966614291, 36.140951677453792],
              [-81.246264371312094, 36.146394228142583],
              [-81.252320056382388, 36.148504286578579],
              [-81.262332968578875, 36.161680338115048],
              [-81.267652658516738, 36.162342208790058],
              [-81.27096715921023, 36.166278985917373],
              [-81.269366851648613, 36.179635748903927],
              [-81.264440469925589, 36.183903136555031],
              [-81.263410618731768, 36.194348535295575],
              [-81.266622325910021, 36.198814640322276],
              [-81.265281132760492, 36.202172329427917],
              [-81.267443257411998, 36.206778935532483],
              [-81.26692333096139, 36.209425400717215],
              [-81.271425092210563, 36.210798296684445],
              [-81.27401384603121, 36.209764462289066],
              [-81.277205888267787, 36.212601004669082],
              [-81.275968855211289, 36.215429483463438],
              [-81.276479128340227, 36.224981512028648],
              [-81.285610029131092, 36.228826295688457],
              [-81.297495876461994, 36.227319003878662],
              [-81.307263475327417, 36.233138536589443],
              [-81.313573697555299, 36.228769788512473],
              [-81.322581381725968, 36.231511857776923],
              [-81.325923779503427, 36.237076122652006],
              [-81.330406158913917, 36.236817574624489],
              [-81.330398566752621, 36.24034063134507],
              [-81.335598624879722, 36.239899583843226],
              [-81.338549844101337, 36.237011674287736],
              [-81.344392928488375, 36.235023365879542],
              [-81.346515919577769, 36.236370053739599],
              [-81.343565029865005, 36.239258161228683],
              [-81.343942677759983, 36.242557684733114],
              [-81.347649305490407, 36.246268589998472],
              [-81.34628842544457, 36.247997900987372],
              [-81.348996671683139, 36.25158533286556],
              [-81.348249842672018, 36.253661593391733],
              [-81.353880585020335, 36.256254559231117],
              [-81.356922449143298, 36.259882908156406],
              [-81.357937725323481, 36.265158393942606],
              [-81.361468258336899, 36.268033684228023],
              [-81.361720637217005, 36.270233287949722],
              [-81.379290540359449, 36.274568508770081],
              [-81.379514984438316, 36.278661891138796],
              [-81.370379062392914, 36.287022433846481],
              [-81.372986495373794, 36.291138583755334],
              [-81.368902983069589, 36.296327176871429],
              [-81.368024084126247, 36.300826645463815],
              [-81.364738338831685, 36.303674076984151],
              [-81.359252636717414, 36.303810603810497],
              [-81.359964464610258, 36.307151037064507],
              [-81.358625498830591, 36.310509532300244],
              [-81.369069211237331, 36.319928772711719],
              [-81.368057828094578, 36.326851278052374],
              [-81.374332641439736, 36.331419064656956],
              [-81.374763798259821, 36.334453621530791],
              [-81.372683624899821, 36.33636568968754],
              [-81.375569018384425, 36.344310204576082],
              [-81.372092157592874, 36.353368305520426],
              [-81.372931522746555, 36.357808284108337],
              [-81.371465747845988, 36.360067089266529],
              [-81.366901514958698, 36.358962760525593],
              [-81.361997890221502, 36.361339948411185],
              [-81.363429000731429, 36.364497758182743],
              [-81.358651216786868, 36.367974531125455],
              [-81.354390985048823, 36.368804542339646],
              [-81.350648771043879, 36.366987851244168],
              [-81.348130012341528, 36.369387639648394],
              [-81.338379005873463, 36.370352839725975],
              [-81.33537069572138, 36.373505143891499],
              [-81.327849365438055, 36.375286914204814],
              [-81.320964614641071, 36.379044312023289],
              [-81.319453060120338, 36.378044354809504],
              [-81.319083939640407, 36.371222292318386],
              [-81.305599792681207, 36.366844271556687],
              [-81.298359839441105, 36.368930080332653],
              [-81.291899759174001, 36.367046007876901],
              [-81.287764124657116, 36.368973453614679],
              [-81.280314071039612, 36.363442093191843],
              [-81.272667391434197, 36.364120588544424],
              [-81.267823091355083, 36.362706523198476],
              [-81.262478494797321, 36.365567513051403],
              [-81.258071924876319, 36.36366518685233],
              [-81.249413544458235, 36.367740811326307],
              [-81.243288156669209, 36.365895519118645],
              [-81.231531633442543, 36.375278038658237],
              [-81.221235901366953, 36.377250647050197],
              [-81.218105002976017, 36.384451902926315],
              [-81.200582473041635, 36.390132272706168],
              [-81.201483859422623, 36.395937810881335],
              [-81.197801830211432, 36.399003528746142],
              [-81.19880784506816, 36.404279889710374],
              [-81.196021113745573, 36.407999332603715],
              [-81.196379276960755, 36.414821612867186],
              [-81.194273154488585, 36.415101448216909],
              [-81.191733722665191, 36.421020509183805],
              [-81.184342561961387, 36.420370452124303],
              [-81.181325817418241, 36.423518784345482],
              [-81.181349105872542, 36.430299451335422],
              [-81.178417886436435, 36.431289530910938],
              [-81.177612116345202, 36.433629054527287],
              [-81.170028575910479, 36.435665412475252],
              [-81.161159613893247, 36.432118519763598],
              [-81.160245336987728, 36.429835422326661],
              [-81.155855975363707, 36.429558436349204],
              [-81.148304503280414, 36.42454819720983],
              [-81.146723580841467, 36.422181614608171],
              [-81.148073967804677, 36.418825735174444],
              [-81.143685292074494, 36.418548301518399],
              [-81.138736186035075, 36.422809841342037],
              [-81.133698100641766, 36.424077651930553],
              [-81.130029764877705, 36.428770262731128],
              [-81.124745129682097, 36.427837828832772],
              [-81.114298867846728, 36.432223845445179],
              [-81.11001493419991, 36.431416097421454],
              [-81.105660263025911, 36.434395330674732],
              [-81.098374018207494, 36.433210900976135],
              [-81.098917279557313, 36.437346204805671],
              [-81.095404403688462, 36.441244117378567],
              [-81.088309851173392, 36.442523530004721],
              [-81.080689482098677, 36.441296179211783],
              [-81.07844045497437, 36.443996571838795],
              [-81.070202478542114, 36.447571859338332],
              [-81.066304635369704, 36.446539937521528],
              [-81.067556751302291, 36.438562454932864],
              [-81.063431558142355, 36.436959484249556],
              [-81.062520183735415, 36.434675649164255],
              [-81.058553951220944, 36.432278950574421],
              [-81.058240725675347, 36.428714421931808],
              [-81.055539965576529, 36.425120824719151],
              [-81.04995980238877, 36.422249584478159],
              [-81.046550933704268, 36.425617071968908],
              [-81.040796375502111, 36.421909897713007],
              [-81.037090731828002, 36.423341575121022],
              [-81.034724662726575, 36.419789490980023],
              [-81.025683143291531, 36.420548682778026],
              [-81.021491349783076, 36.417579700870967],
              [-81.024255971217087, 36.410606224002493],
              [-81.018170231363086, 36.406855898000202],
              [-81.012532641446327, 36.40939909152862],
              [-81.00553065336203, 36.408515562512903],
              [-81.001242151297845, 36.400923130991139],
              [-80.993786659847117, 36.398896732510032],
              [-80.991491310914583, 36.396708328372796],
              [-80.986384677214275, 36.396605376596895],
              [-80.982499849698002, 36.390418950352135],
              [-80.98046132945953, 36.392059627846912],
              [-80.978360071769401, 36.399116491981211],
              [-80.975040885853289, 36.400323941251237],
              [-80.971146789810305, 36.399288895463755],
              [-80.968121993688271, 36.402431892826193],
              [-80.969445376748638, 36.407752339668455],
              [-80.965061840698738, 36.414249119943825],
              [-80.970357911709002, 36.416817666549683],
              [-80.973397628177921, 36.415303705772253],
              [-80.977065448546298, 36.415767539583662],
              [-80.979650693541373, 36.413111159104076],
              [-80.985584670721991, 36.41250521201119],
              [-80.987493771141331, 36.414916064416715],
              [-80.989706868910957, 36.414110885767279],
              [-80.991737181249107, 36.417621750624058],
              [-80.995496494423293, 36.415926979216138],
              [-80.998724868885091, 36.416877022016969],
              [-80.999982574959404, 36.42083252119631],
              [-81.00326428242542, 36.421517945968134],
              [-81.003279366089757, 36.423146999593975],
              [-80.999534871204858, 36.426471058465481],
              [-80.999739141691791, 36.43056472747881],
              [-80.99357655267633, 36.430600276826738],
              [-80.986496000756148, 36.433502714818417],
              [-80.989072734507033, 36.435997778877123],
              [-80.987047860422393, 36.439267561824295],
              [-80.976944994055387, 36.452093337236342],
              [-80.971143021356454, 36.455427944352813],
              [-80.971718253386541, 36.457670269606851],
              [-80.968517149656549, 36.459977567195125],
              [-80.971814687021777, 36.462292856146775],
              [-80.968709641774012, 36.469222719661289],
              [-80.97077928621772, 36.470840342139205],
              [-80.970353554413109, 36.472956116101344],
              [-80.957786494641098, 36.48438468532548],
              [-80.947162750023679, 36.489549367203615],
              [-80.946963381684057, 36.492236193493333],
              [-80.943706723255872, 36.494807238551665],
              [-80.944000984937844, 36.496742935359926],
              [-80.934615536427742, 36.497452968289714],
              [-80.934802851678953, 36.49991758738431],
              [-80.938168144174455, 36.503598278376742],
              [-80.93712703607433, 36.505364904286196],
              [-80.938530795431873, 36.513678858822928],
              [-80.941335847574237, 36.516745996423225],
              [-80.94029463197154, 36.518512636172211],
              [-80.941430491061354, 36.521368444997108],
              [-80.946988294828955, 36.527766938463067],
              [-80.942167173555262, 36.529597761389176],
              [-80.935702265330022, 36.527694668878333],
              [-80.933431863398297, 36.528763241466358],
              [-80.933753194969412, 36.533956834698145],
              [-80.931215579455312, 36.536347588352534],
              [-80.933126079578244, 36.538759128171385],
              [-80.930801902979724, 36.540092088621883],
              [-80.923895573101476, 36.538674971014672],
              [-80.918966298638864, 36.541033730675437],
              [-80.916882096061784, 36.544566560985011],
              [-80.918899247797526, 36.546449443250474],
              [-80.918137633790494, 36.548522616839321],
              [-80.91255267339686, 36.552425391984862],
              [-80.909146034749696, 36.550637460949396],
              [-80.90706110501344, 36.554170101117016],
              [-80.908583763292725, 36.556804083566263],
              [-80.902049091084365, 36.560314871616065],
              [-80.902235495924629, 36.562779451208321],
              [-80.899963559863707, 36.563847368860749],
              [-80.90059102824064, 36.565825466079318],
              [-80.897757924954249, 36.566279745646085],
              [-80.89516698732757, 36.562153981241053],
              [-80.889207838843831, 36.5611264639316],
              [-80.886160537337602, 36.56263815709746],
              [-80.886024949754514, 36.566689275872527],
              [-80.88343112183145, 36.569343392824159],
              [-80.879529538578993, 36.568305473664047],
              [-80.877108652371916, 36.57179512807884],
              [-80.872670723451648, 36.573401132820038],
              [-80.863358202626912, 36.570318885382385],
              [-80.856414571610188, 36.56401111853549],
              [-80.843453088391314, 36.563717694968936],
              [-80.840584952156206, 36.559283815601894],
              [-80.835187254582891, 36.558867490274807],
              [-80.833106679734541, 36.55561866993623],
              [-80.82345509781203, 36.559270907451115],
              [-80.819406408570103, 36.569062217588026],
              [-80.821390314460004, 36.574468957417764],
              [-80.82569054515119, 36.57691587539599],
              [-80.826435051836043, 36.579994485651802],
              [-80.825336457552893, 36.582024452570977],
              [-80.822222014551471, 36.582169975480852],
              [-80.821102964444663, 36.589351035644192],
              [-80.827151129181175, 36.595004256330355],
              [-80.829147623557446, 36.60203969028624],
              [-80.824103574127278, 36.604923244255531],
              [-80.824471506597021, 36.609852515482714],
              [-80.822488631407268, 36.612854796695984],
              [-80.82727821384664, 36.621330970559804],
              [-80.825176211591767, 36.623233164952929],
              [-80.820916024421351, 36.618893024471511],
              [-80.819664428291901, 36.614936111773702],
              [-80.814413420894667, 36.612096825681569],
              [-80.814218747887693, 36.608003283560031],
              [-80.799966992682613, 36.60727034516102],
              [-80.796226369160465, 36.605436616435441],
              [-80.795234445662757, 36.606937532311399],
              [-80.788712664104665, 36.605290980611343],
              [-80.783268336056821, 36.606765603031612],
              [-80.780163073756867, 36.6085389329428],
              [-80.779535799733054, 36.614969107738595],
              [-80.776386314703842, 36.618635587093991],
              [-80.768138825955688, 36.617038704848419],
              [-80.766443690825753, 36.621976033499564],
              [-80.76257071629044, 36.625821006433227],
              [-80.755876010971818, 36.623336869258551],
              [-80.746266099402291, 36.626718408537471],
              [-80.743837146605429, 36.630205257901551],
              [-80.733178114932471, 36.635350542210496],
              [-80.727414535628157, 36.640037525604448],
              [-80.727279395917606, 36.645717154184268],
              [-80.724062786660411, 36.648017667805121],
              [-80.720231532601176, 36.649968020529315],
              [-80.711484718352182, 36.649117404605576],
              [-80.709939153954537, 36.651631997748602],
              [-80.702132356303707, 36.649544856123157],
              [-80.702701044553862, 36.651788212583412],
              [-80.698271705791527, 36.656645424452797],
              [-80.696679715124688, 36.66105301740162],
              [-80.694401890510377, 36.662116984142934],
              [-80.694084372919548, 36.66533175459567],
              [-80.687404979371024, 36.66610156871581],
              [-80.685586465241698, 36.669937400652792],
              [-80.678944757744944, 36.667184723711841],
              [-80.675562565103277, 36.670276845694943],
              [-80.672498583013166, 36.670154168855099],
              [-80.665911763247365, 36.667136463613957],
              [-80.656401128778825, 36.669982122963681],
              [-80.653547530291732, 36.667172903496642],
              [-80.650149340693616, 36.667006600987406],
              [-80.641088815347842, 36.670994370475817],
              [-80.632340452261275, 36.670138005459201],
              [-80.628095752572847, 36.66742000691081],
              [-80.624547731645336, 36.671303556962265],
              [-80.616816126291269, 36.673833173045153],
              [-80.616133887090768, 36.670489148459296],
              [-80.611998988353562, 36.667242015959978],
              [-80.608935234577501, 36.66711771912324],
              [-80.606077000478308, 36.662678506997679],
              [-80.593093280838957, 36.660729210015809],
              [-80.585726413752056, 36.656520326890302],
              [-80.582620449770232, 36.659917365136515],
              [-80.582796621677517, 36.662382289517282],
              [-80.577338230546687, 36.662218665697097],
              [-80.573703990914865, 36.658220668203342],
              [-80.566738818922644, 36.657047369034025],
              [-80.563324504126868, 36.651991995202884],
              [-80.552858578236226, 36.652806428160638],
              [-80.552124562227249, 36.649726259679419],
              [-80.549165987462246, 36.647443047073821],
              [-80.551105873307506, 36.644709603706687],
              [-80.550097928990269, 36.642950694493166],
              [-80.538844878918681, 36.640947967177148],
              [-80.53034022549889, 36.645542695668546],
              [-80.526105391645018, 36.64444994470287],
              [-80.518208444066104, 36.647765990506024],
              [-80.517100537562158, 36.649793050249158],
              [-80.513868286899921, 36.648830172481773],
              [-80.510924276843397, 36.651432655325316],
              [-80.505581138284896, 36.652366174508018],
              [-80.504697083653852, 36.654965050890084],
              [-80.501528269775946, 36.656995357518824],
              [-80.498905174946998, 36.654754395195773],
              [-80.490769720932775, 36.652610048332662],
              [-80.489149709609904, 36.650499389081503],
              [-80.487930893981883, 36.653054631369145],
              [-80.48966499786907, 36.656265724023775],
              [-80.489287689094638, 36.661373052710736],
              [-80.486507503533659, 36.663182242112569],
              [-80.486680529214411, 36.665647301700162],
              [-80.480663737177068, 36.664863799853521],
              [-80.478720768113334, 36.667596075452032],
              [-80.472597315041483, 36.668969475033336],
              [-80.470412002731763, 36.662985294587664],
              [-80.473409324985582, 36.658490730214929],
              [-80.472792335890574, 36.656510523013985],
              [-80.470008363946278, 36.656690462599158],
              [-80.468109751878444, 36.654271739806539],
              [-80.470497165856585, 36.651054743591118],
              [-80.467530475453614, 36.643882874852373],
              [-80.467743865091421, 36.639568224133306],
              [-80.469693631181499, 36.640093864314807],
              [-80.471915265072184, 36.637669476661124],
              [-80.473754847500587, 36.638723494412879],
              [-80.475756016416199, 36.637355923965956],
              [-80.477309118471979, 36.634844329856698],
              [-80.476075355542321, 36.630883898715815],
              [-80.47751446004834, 36.627271843256111],
              [-80.480242273466331, 36.627355953883246],
              [-80.48668450270192, 36.621138945449808],
              [-80.476482516850623, 36.614109953080373],
              [-80.47753594326862, 36.61234745579781],
              [-80.474962830181497, 36.606583852943224],
              [-80.475006859454979, 36.601432861385014],
              [-80.472386493661304, 36.599191251419199],
              [-80.473883869034196, 36.596943875951887],
              [-80.47304353011846, 36.594391587765074],
              [-80.477597533731185, 36.590642992183142],
              [-80.475366426129611, 36.588180748098807],
              [-80.475248987667229, 36.585451335458387],
              [-80.485246881166006, 36.57861281165335],
              [-80.489084560989653, 36.578298822567177],
              [-80.489245529486951, 36.575877161645245],
              [-80.49124474456795, 36.574509285112491],
              [-80.488679813912711, 36.57200375627901],
              [-80.491955329766412, 36.569445210390818],
              [-80.494347215200776, 36.569485495887363],
              [-80.498552268034558, 36.560805676969274],
              [-80.501941064869442, 36.559347313406271],
              [-80.501767783620863, 36.556882129301052],
              [-80.50699437832678, 36.556477229370813],
              [-80.516761495751609, 36.549064000876847],
              [-80.518142224006212, 36.54571554779772],
              [-80.513629803351918, 36.542685627787691],
              [-80.512555773559214, 36.536303616055314],
              [-80.515051802633707, 36.534185994881724],
              [-80.508474415172756, 36.527901458396727],
              [-80.514010056595467, 36.519394731971005],
              [-80.512387781144923, 36.515655224970189],
              [-80.507210189924805, 36.512538105074462],
              [-80.506872228499276, 36.510865631898866],
              [-80.501758581155784, 36.510742028709778],
              [-80.494795882845139, 36.504677429364811],
              [-80.483902380883833, 36.504341914076598],
              [-80.479786088009135, 36.502718948194165],
              [-80.476663161226213, 36.497968459611776],
              [-80.471220511689481, 36.499429021902202],
              [-80.464495120985049, 36.498821743428167],
              [-80.464825187946388, 36.497236307299318],
              [-80.45742646137839, 36.493283412882292],
              [-80.454522269645864, 36.485846338606947],
              [-80.451518047614485, 36.483824746293934],
              [-80.45201345403855, 36.481446623615092],
              [-80.446559306319571, 36.476389924574399],
              [-80.44666636763985, 36.474232411787845],
              [-80.448608608729856, 36.473129399124971],
              [-80.44175200949428, 36.460016891542011],
              [-80.439638984046383, 36.45865438609502],
              [-80.441630096825179, 36.454029116049675],
              [-80.443516838433752, 36.453190408339701],
              [-80.43978966184639, 36.449716359836827],
              [-80.439780944771186, 36.444829146347381],
              [-80.437445258863775, 36.442894460785524],
              [-80.434060452009817, 36.444351103613087],
              [-80.424115447874982, 36.441147239109895],
              [-80.425377729749499, 36.431811766627085],
              [-80.421487111673159, 36.429129800787528],
              [-80.420369876707866, 36.424639861510187],
              [-80.417591920135081, 36.423189516373121],
              [-80.416254513428072, 36.419756443322171],
              [-80.41907760414, 36.414426187725589],
              [-80.410577576116353, 36.409238414810062],
              [-80.400258163876259, 36.412769430235144],
              [-80.396367456415035, 36.408457507754022],
              [-80.397806133724529, 36.404845937633972],
              [-80.396749794787567, 36.403349721831262],
              [-80.40001701246274, 36.397535188689041],
              [-80.396684751128134, 36.395468346668281],
              [-80.394127137600876, 36.39133140466933],
              [-80.394678923100102, 36.388689160881967],
              [-80.392123523166774, 36.386181292710326],
              [-80.382191291100511, 36.386232104417253],
              [-80.377635527489787, 36.380202727629033],
              [-80.378463935736889, 36.376239445872919],
              [-80.371916021033869, 36.37483427211982],
              [-80.350616162375658, 36.381801088136946],
              [-80.347451957130318, 36.37894023851522],
              [-80.347783959764257, 36.377355010031458],
              [-80.345731159068507, 36.377355719144525],
              [-80.33058659747833, 36.382687592142084],
              [-80.321099923362468, 36.38876544332323],
              [-80.314052493951621, 36.386476568624559],
              [-80.308781254336665, 36.388766425387509],
              [-80.30234455056268, 36.386829087999743],
              [-80.311388532668076, 36.379607937164742],
              [-80.314216196004281, 36.362875959751037],
              [-80.324199988673953, 36.359308269073907],
              [-80.328358507672633, 36.354155862469653],
              [-80.330964974394462, 36.35314260843527],
              [-80.331352581145879, 36.351293198299757],
              [-80.329799404733507, 36.350544987447023],
              [-80.331018134540358, 36.346361721897246],
              [-80.32946506295697, 36.345613504366696],
              [-80.331017581247451, 36.344732546365314],
              [-80.331459945178068, 36.340989743633777],
              [-80.329351728616047, 36.337996012066796],
              [-80.331846335108679, 36.335881949927753],
              [-80.330902990009037, 36.333856679348891],
              [-80.333675380139852, 36.333679927841793],
              [-80.33661310198228, 36.331081301002364],
              [-80.336832413942417, 36.325136887889293],
              [-80.332727292728904, 36.318621095012894],
              [-80.33483192478802, 36.313468780921092],
              [-80.332336398026484, 36.310695307798916],
              [-80.332168071372422, 36.304971076728862],
              [-80.327898901667638, 36.300876889282641],
              [-80.329450518308676, 36.299995926333295],
              [-80.326012903619784, 36.29519695734114],
              [-80.320193415827362, 36.293612623144092],
              [-80.31936164978444, 36.291058793053431],
              [-80.321798564692756, 36.28432137168479],
              [-80.321077220345856, 36.279609875884447],
              [-80.313873477822085, 36.274854962927797],
              [-80.312820620234675, 36.273357883720877],
              [-80.31403923146695, 36.270803837942843],
              [-80.3094406615472, 36.268294147886927],
              [-80.309108012993676, 36.263362346164797],
              [-80.311212841952624, 36.259839529198125],
              [-80.30927382956699, 36.256052660748139],
              [-80.310658510322, 36.254335268167978],
              [-80.310159835111634, 36.251825327705369],
              [-80.294929102117635, 36.243018049713456],
              [-80.288838516957853, 36.236236001584125],
              [-80.290998650137169, 36.234078569490073],
              [-80.288175491972098, 36.229630657933079],
              [-80.288342523906124, 36.225579447496976],
              [-80.283747559058938, 36.223068694071465],
              [-80.28640635492809, 36.218533504353694],
              [-80.282808858312492, 36.214525649541557],
              [-80.281871383578633, 36.202723896332706],
              [-80.27921569254984, 36.199112396263274],
              [-80.276115393731757, 36.200873115602775],
              [-80.269196234588506, 36.201311578798048],
              [-80.258850118147933, 36.193689758683639],
              [-80.254697874299339, 36.195581622466271],
              [-80.250768759689919, 36.194787240024496],
              [-80.24424157417981, 36.190116238760368],
              [-80.237874649876403, 36.1927990197131],
              [-80.227251422941549, 36.189754041017615],
              [-80.220388639703557, 36.18992549898428],
              [-80.216235640641159, 36.191816030371299],
              [-80.214136147005803, 36.188819977570525],
              [-80.209708783552998, 36.188772476321226],
              [-80.208496322557593, 36.184808223746451],
              [-80.201687413654454, 36.186343780284687],
              [-80.198100562991073, 36.1790745865096],
              [-80.187919224089171, 36.178800785085947],
              [-80.185818637560899, 36.177433563698102],
              [-80.179065709262815, 36.178703674140493],
              [-80.178287536377013, 36.180772569414913],
              [-80.176128496874213, 36.181298690869944],
              [-80.170109345188479, 36.174246154813744],
              [-80.169947765283808, 36.171779909456184],
              [-80.160480895345046, 36.174587152469527],
              [-80.153455022442643, 36.173917867400142],
              [-80.150809806969008, 36.168674082358201],
              [-80.140797748829584, 36.167603812467675],
              [-80.137262683711825, 36.164956663786626],
              [-80.134436419858687, 36.16702239235223],
              [-80.132672701732787, 36.164069369760639],
              [-80.133071610672758, 36.158961631064457],
              [-80.130308932526049, 36.157504380450469],
              [-80.127445978105044, 36.151687208720901],
              [-80.12762910066597, 36.144377237842548],
              [-80.118472092756292, 36.134454725121074],
              [-80.108788252003336, 36.136685103440286],
              [-80.104079636092123, 36.139583757646342],
              [-80.098505345923385, 36.135302542774831],
              [-80.094684009689487, 36.137233529291436],
              [-80.085116007176083, 36.137304329351501],
              [-80.07760408089284, 36.134119489641463],
              [-80.074807130581675, 36.126407431989207],
              [-80.071776264680551, 36.123010581458317],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "Tuckertown",
        BASIN_ID: "Tuckertown_Basin",
        BASIN_NAME: "Tuckertown",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.0757527420607, 35.723575188925267],
              [-80.072763797567546, 35.729692535757387],
              [-80.069901166322623, 35.730391713002284],
              [-80.068368318574798, 35.728009881012078],
              [-80.063531444302058, 35.726810729429175],
              [-80.060415779964018, 35.720681153220269],
              [-80.064288396151568, 35.713596725871113],
              [-80.064334850911095, 35.698927400547376],
              [-80.0686879549894, 35.695984641232108],
              [-80.069525001735286, 35.69202156155584],
              [-80.06573544216397, 35.690692379715216],
              [-80.066400047254405, 35.689151869388269],
              [-80.063336326946413, 35.68438793086613],
              [-80.073247842297008, 35.679209470750742],
              [-80.072324504933945, 35.675551232259807],
              [-80.077776497903059, 35.67221375942848],
              [-80.078966692598087, 35.660145313140482],
              [-80.085582682046777, 35.652888840096331],
              [-80.085424945421494, 35.650421508807199],
              [-80.093785030390464, 35.647749412045172],
              [-80.096276984553015, 35.64074916545394],
              [-80.101776730989059, 35.638776280553415],
              [-80.108825241231756, 35.632664559847939],
              [-80.107408624727213, 35.628124534779133],
              [-80.103069547705488, 35.627808820270921],
              [-80.098581243319018, 35.621765505238081],
              [-80.101503889632994, 35.617541249883054],
              [-80.097717475577312, 35.616213008006703],
              [-80.097290089028945, 35.611806694846777],
              [-80.099711721132067, 35.609960582326821],
              [-80.099720517051637, 35.606700465406682],
              [-80.098462978846911, 35.604627646327224],
              [-80.094946495661404, 35.605238235288382],
              [-80.09105406763419, 35.602808176233587],
              [-80.093971884076296, 35.60021410979855],
              [-80.092074133459136, 35.59153161436182],
              [-80.087625874958803, 35.591743801804576],
              [-80.083623016711357, 35.589841944770413],
              [-80.083521149050569, 35.587110245109855],
              [-80.077385530538351, 35.582472634437615],
              [-80.068003656531445, 35.580383526982175],
              [-80.071326380732643, 35.571050038975912],
              [-80.065739766617597, 35.567029645735637],
              [-80.067076980589661, 35.560688059829417],
              [-80.072784534269189, 35.560919699381209],
              [-80.080208189909015, 35.556219876179661],
              [-80.084705330083736, 35.557373847222294],
              [-80.086397514237902, 35.560593201372278],
              [-80.091066476840012, 35.559324073715501],
              [-80.092925846008839, 35.561750594890654],
              [-80.095230274584594, 35.562063110965575],
              [-80.101335903523946, 35.557183391775631],
              [-80.114178612271914, 35.557292946948373],
              [-80.112604668100431, 35.550285193705548],
              [-80.118589214885446, 35.549193338661006],
              [-80.124458715151235, 35.550259847902034],
              [-80.126227063003199, 35.545063683652025],
              [-80.125631942941396, 35.541450004759042],
              [-80.127447889699084, 35.539249830833029],
              [-80.132277575559314, 35.53881640947246],
              [-80.133712353180542, 35.535205703961907],
              [-80.132566001334297, 35.532604578137168],
              [-80.141729018167325, 35.532485428697548],
              [-80.140915040645424, 35.528298721276101],
              [-80.148220516479768, 35.524123056081713],
              [-80.151892453571534, 35.525978350934139],
              [-80.160670193826519, 35.526077529316964],
              [-80.161771247627158, 35.524052160793183],
              [-80.159692266738091, 35.521053606580438],
              [-80.165400124754683, 35.519650606354148],
              [-80.168152104166793, 35.514587021045806],
              [-80.172097803682405, 35.516750435961249],
              [-80.176654806475668, 35.514376281677691],
              [-80.175012359412136, 35.512523990609289],
              [-80.172103497062238, 35.51349005532844],
              [-80.17046982367161, 35.506747103698025],
              [-80.16169122469276, 35.508278851534421],
              [-80.15922832539917, 35.505500079025488],
              [-80.159732710661501, 35.499861050774818],
              [-80.160833411254089, 35.497835644945887],
              [-80.163960122785198, 35.497442885115802],
              [-80.164624068691708, 35.494271355189774],
              [-80.171430152642785, 35.490842567717607],
              [-80.177749040440588, 35.483271174525157],
              [-80.189763291275369, 35.479185897982966],
              [-80.19124945130217, 35.475309997835495],
              [-80.185387341356375, 35.47261648180536],
              [-80.186273658680022, 35.466757262091761],
              [-80.183208776302024, 35.463625789592626],
              [-80.182508982924745, 35.455649948484954],
              [-80.191775523558206, 35.453368013915181],
              [-80.199390785274574, 35.455754414162037],
              [-80.204766377017023, 35.452674799158956],
              [-80.206140960258239, 35.449327269269276],
              [-80.218695694601578, 35.445856256714592],
              [-80.21875634459407, 35.440701043463662],
              [-80.219685683585126, 35.442728577266422],
              [-80.221658272596116, 35.442994363443319],
              [-80.225827199362584, 35.439207901500225],
              [-80.227854786967328, 35.439209249059211],
              [-80.230755338892592, 35.443132644058352],
              [-80.22888958402207, 35.445598922365591],
              [-80.233986502953186, 35.445469925496688],
              [-80.233983455589225, 35.448730502543896],
              [-80.237817533309169, 35.451420523868237],
              [-80.237868252689225, 35.456047022662531],
              [-80.243239660857213, 35.456226214752768],
              [-80.247074440702221, 35.458915918034513],
              [-80.258147594161144, 35.457687141531011],
              [-80.260557578882981, 35.460728316547346],
              [-80.264339987652406, 35.460421261026553],
              [-80.26532145536062, 35.470335358292409],
              [-80.27233709026747, 35.473994581871587],
              [-80.274253743074269, 35.479414570455617],
              [-80.276557040467452, 35.478093335353094],
              [-80.284011790880015, 35.484527756868012],
              [-80.285985683022233, 35.484792459705616],
              [-80.288453609875845, 35.482677937557582],
              [-80.297061783479464, 35.485190302144808],
              [-80.307151265578568, 35.496866525922229],
              [-80.312251619267229, 35.498364377107983],
              [-80.315761875352365, 35.501007711386144],
              [-80.316091402663858, 35.504312160871912],
              [-80.322070133374766, 35.506470390154519],
              [-80.327886328318087, 35.514312004541303],
              [-80.333208475899127, 35.518011846091845],
              [-80.338639206770765, 35.517922326505968],
              [-80.339134022651081, 35.520433554516607],
              [-80.343524313076998, 35.523736644592837],
              [-80.34034420737629, 35.527658863491489],
              [-80.336394505161508, 35.528761432760348],
              [-80.333543040701514, 35.53272742376226],
              [-80.325640638269405, 35.526781117151941],
              [-80.322349148930215, 35.527971221491967],
              [-80.31911295983403, 35.532157214853754],
              [-80.311322057363725, 35.533832086961034],
              [-80.311542099496663, 35.540925477311021],
              [-80.30676817504893, 35.54110186209418],
              [-80.304024470520147, 35.544538400906433],
              [-80.300841661987334, 35.545199192994566],
              [-80.30051199286855, 35.551675702425264],
              [-80.296560660732311, 35.551146779192131],
              [-80.295243128468229, 35.554230720709533],
              [-80.290028520222393, 35.55815129272915],
              [-80.291400066404705, 35.561323606467504],
              [-80.285087299659992, 35.564010196018423],
              [-80.282341301750492, 35.569076282310945],
              [-80.27822307267914, 35.572599979816253],
              [-80.273611427071799, 35.573612144058409],
              [-80.272182551631701, 35.577224423077205],
              [-80.269327478395411, 35.577928498129189],
              [-80.268007583118802, 35.582642164666282],
              [-80.262462058888104, 35.583257077838191],
              [-80.258011725682394, 35.588365944216442],
              [-80.258229990684583, 35.590568853492996],
              [-80.249552520380846, 35.593208461637268],
              [-80.246423584694043, 35.59197336796823],
              [-80.240434738495907, 35.59633180603457],
              [-80.239090734851573, 35.599964052033386],
              [-80.231759737987943, 35.601467364531061],
              [-80.227849584862724, 35.606324950497587],
              [-80.220709866354127, 35.606452229537183],
              [-80.217740900727037, 35.609313695291519],
              [-80.216095410232739, 35.607462124761511],
              [-80.212690226062918, 35.607547620726102],
              [-80.209389383040516, 35.611994618191375],
              [-80.197901351741237, 35.618328746237161],
              [-80.176229474943739, 35.636061301837834],
              [-80.173811682568868, 35.636278915966663],
              [-80.171177990086704, 35.634293497271877],
              [-80.168649410619594, 35.635039538541328],
              [-80.166666101157034, 35.638032956242562],
              [-80.167535140389802, 35.643584848277591],
              [-80.162582019781112, 35.647808207547882],
              [-80.159289011866207, 35.645733638821611],
              [-80.156538075981729, 35.647536445764096],
              [-80.150823781394166, 35.647308860374146],
              [-80.150696261729536, 35.655987372053076],
              [-80.14557828153869, 35.659372740125818],
              [-80.140286180530055, 35.666810579478252],
              [-80.140386659339455, 35.67117202377711],
              [-80.137745456479408, 35.672445869855181],
              [-80.138953198898108, 35.673152426327427],
              [-80.138283048355959, 35.677953298282766],
              [-80.130854847097311, 35.681026322501815],
              [-80.129465242347592, 35.68763222430433],
              [-80.127149242959035, 35.690580308150707],
              [-80.123299240477493, 35.69114714631543],
              [-80.12670061181457, 35.694324116298894],
              [-80.123927725343478, 35.704275771476766],
              [-80.120510192554946, 35.707618466635644],
              [-80.117275416368955, 35.703648676360437],
              [-80.109843860146526, 35.706720320016018],
              [-80.107582201273871, 35.70940375471919],
              [-80.107766839365809, 35.723016102347067],
              [-80.101442928218532, 35.722432729685593],
              [-80.099338843603491, 35.727583132212381],
              [-80.094608471181559, 35.727486721155444],
              [-80.092457155138518, 35.729641403109504],
              [-80.0757527420607, 35.723575188925267],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "FallsDam",
        BASIN_ID: "FallsDam_Basin",
        BASIN_NAME: "Falls Dam",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.101818762478757, 35.376224792143837],
              [-80.098077664137293, 35.382783913658237],
              [-80.097292090719421, 35.389744701490528],
              [-80.100350491441489, 35.392878605933021],
              [-80.100281895366834, 35.39803397438628],
              [-80.107231753348344, 35.40033715747704],
              [-80.103101607514432, 35.408746311019314],
              [-80.101019347348952, 35.409007108047369],
              [-80.099965068370011, 35.414028502816812],
              [-80.096077753808203, 35.413228540898615],
              [-80.096503960018637, 35.417635616335843],
              [-80.089645503231822, 35.421324589167327],
              [-80.08832186207286, 35.424406569905159],
              [-80.081967249131651, 35.424086285576465],
              [-80.079768547415441, 35.426505564171677],
              [-80.074126601553459, 35.42600996636218],
              [-80.072432944996848, 35.424420376926314],
              [-80.072619588867667, 35.417106282191028],
              [-80.069015449240197, 35.413353750648739],
              [-80.073357057569723, 35.408779762583499],
              [-80.072765498014178, 35.405165386484349],
              [-80.065260201259392, 35.405502878110674],
              [-80.06105559731013, 35.401396330417988],
              [-80.066377337229142, 35.398587131371194],
              [-80.07143576547962, 35.392207974145627],
              [-80.074831513117374, 35.392126523227738],
              [-80.076432915297303, 35.387723214287846],
              [-80.079828458445618, 35.387641619376971],
              [-80.081702373833394, 35.383547193565299],
              [-80.084662956098654, 35.382318957779447],
              [-80.084505807290725, 35.379851047312172],
              [-80.087299671172488, 35.379415593638583],
              [-80.087581863108383, 35.376463779229589],
              [-80.085560630134026, 35.374829636271386],
              [-80.086342448790859, 35.369499231860402],
              [-80.08886305087951, 35.368754775133517],
              [-80.089949964518169, 35.37162098907347],
              [-80.093229286797978, 35.373697977548105],
              [-80.101818762478757, 35.376224792143837],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "KerrScott",
        BASIN_ID: "KerrScott_Basin",
        BASIN_NAME: "W Kerr Scott",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.63149603022319, 36.14713993042318],
              [-81.631907884005756, 36.148544692452106],
              [-81.628610201128936, 36.149770424101654],
              [-81.620909247016044, 36.14910683827739],
              [-81.619787550769516, 36.151409363091467],
              [-81.616489546696883, 36.152634757465485],
              [-81.617363503159211, 36.155179564986838],
              [-81.611460407535375, 36.159340150249328],
              [-81.600071627027944, 36.156601522475185],
              [-81.597235148553935, 36.158966441743964],
              [-81.5979561211809, 36.162305770758081],
              [-81.593608473783803, 36.163673997950937],
              [-81.589191118200716, 36.170724096404605],
              [-81.585891776434948, 36.171948629142875],
              [-81.585997800862643, 36.174942264082233],
              [-81.591067041373151, 36.176913661780304],
              [-81.590258114585765, 36.184673509492264],
              [-81.595965848050469, 36.185096407201961],
              [-81.597227929833608, 36.190940134239646],
              [-81.600070585010997, 36.192098440621727],
              [-81.60245604942105, 36.195639818361308],
              [-81.602895587338821, 36.198673815426254],
              [-81.59992807629952, 36.199939136872864],
              [-81.599780573138645, 36.204256654924521],
              [-81.597353833325798, 36.208026314673887],
              [-81.60352584512232, 36.209588876939051],
              [-81.602966848898973, 36.212501573509719],
              [-81.596031965290351, 36.214910795754214],
              [-81.592069620343452, 36.219577682835165],
              [-81.58500010212299, 36.217363557319061],
              [-81.583288924935701, 36.220949062689925],
              [-81.57742581111529, 36.221319902948864],
              [-81.576916641275801, 36.223967677300919],
              [-81.571440765983695, 36.227637032159194],
              [-81.567061681682063, 36.227375076646226],
              [-81.558948556784827, 36.232348785690348],
              [-81.547268774834279, 36.229299942131945],
              [-81.543815436952315, 36.234840627518601],
              [-81.536566165071861, 36.231788786166717],
              [-81.531802948334203, 36.231749688675841],
              [-81.526270136148696, 36.228635556618642],
              [-81.525167697057896, 36.225520060636221],
              [-81.507370953507206, 36.224159054287576],
              [-81.501558790818009, 36.227784514961861],
              [-81.495951026338588, 36.233873796287419],
              [-81.487909759905008, 36.233160947187834],
              [-81.484785225850985, 36.235217589327668],
              [-81.483837274853855, 36.238353658235972],
              [-81.477177889133685, 36.241061427579041],
              [-81.477073820909439, 36.24863698341035],
              [-81.474306543160282, 36.252363254680866],
              [-81.467211146600533, 36.252036090550462],
              [-81.458298479359243, 36.248775519365999],
              [-81.452786533130109, 36.2578562686369],
              [-81.446200320942907, 36.261926705169166],
              [-81.435644088751459, 36.263612313581589],
              [-81.4299254225201, 36.270227767990079],
              [-81.431048866211569, 36.274973185180734],
              [-81.429868045036429, 36.277538431426599],
              [-81.417849921392857, 36.277959101892868],
              [-81.413952408867431, 36.280461350696115],
              [-81.41594009627083, 36.291275879041294],
              [-81.415040116309228, 36.294146590025548],
              [-81.41150250929077, 36.294795865587275],
              [-81.402896648800066, 36.289943088084037],
              [-81.400076958804732, 36.29040929307795],
              [-81.392701497144955, 36.286249091891243],
              [-81.389267306190987, 36.286368338790872],
              [-81.387325303959628, 36.282334578746237],
              [-81.382380828416075, 36.281454447217655],
              [-81.378952170907766, 36.278050434533313],
              [-81.379290540359449, 36.274568508770081],
              [-81.362283252994345, 36.270844828776312],
              [-81.361468258336899, 36.268033684228023],
              [-81.357937725323481, 36.265158393942606],
              [-81.356922449143298, 36.259882908156406],
              [-81.353880585020335, 36.256254559231117],
              [-81.348249842672018, 36.253661593391733],
              [-81.348996671683139, 36.25158533286556],
              [-81.34628842544457, 36.247997900987372],
              [-81.347649305490407, 36.246268589998472],
              [-81.343942677759983, 36.242557684733114],
              [-81.343565029865005, 36.239258161228683],
              [-81.346515919577769, 36.236370053739599],
              [-81.344392928488375, 36.235023365879542],
              [-81.338549844101337, 36.237011674287736],
              [-81.335598624879722, 36.239899583843226],
              [-81.330398566752621, 36.24034063134507],
              [-81.330406158913917, 36.236817574624489],
              [-81.325923779503427, 36.237076122652006],
              [-81.322581381725968, 36.231511857776923],
              [-81.313573697555299, 36.228769788512473],
              [-81.307263475327417, 36.233138536589443],
              [-81.297495876461994, 36.227319003878662],
              [-81.285610029131092, 36.228826295688457],
              [-81.276479128340227, 36.224981512028648],
              [-81.275968855211289, 36.215429483463438],
              [-81.277205888267787, 36.212601004669082],
              [-81.27401384603121, 36.209764462289066],
              [-81.271425092210563, 36.210798296684445],
              [-81.26692333096139, 36.209425400717215],
              [-81.267443257411998, 36.206778935532483],
              [-81.265281132760492, 36.202172329427917],
              [-81.266622325910021, 36.198814640322276],
              [-81.263410618731768, 36.194348535295575],
              [-81.264440469925589, 36.183903136555031],
              [-81.269366851648613, 36.179635748903927],
              [-81.27096715921023, 36.166278985917373],
              [-81.267652658516738, 36.162342208790058],
              [-81.262332968578875, 36.161680338115048],
              [-81.252320056382388, 36.148504286578579],
              [-81.246264371312094, 36.146394228142583],
              [-81.243928966614291, 36.140951677453792],
              [-81.234310201470151, 36.141379247995545],
              [-81.232960738254462, 36.139584108969679],
              [-81.225544625196278, 36.139201117763712],
              [-81.226814541490526, 36.134479045783792],
              [-81.226214382489971, 36.130608151483116],
              [-81.222695403259223, 36.127728602747297],
              [-81.223985080233916, 36.124635863500885],
              [-81.223261212293608, 36.11966487087544],
              [-81.227665063023295, 36.118045453413167],
              [-81.234802938742462, 36.109446170333655],
              [-81.227702144738657, 36.107475401186591],
              [-81.224372690099543, 36.101907898629321],
              [-81.227166598328864, 36.099816411875928],
              [-81.22644275879226, 36.094845352047109],
              [-81.22855217031163, 36.091041491714819],
              [-81.23620365566066, 36.088471659546045],
              [-81.239640908024015, 36.084833375876336],
              [-81.240576925948886, 36.080069537127201],
              [-81.242933505242604, 36.078465528096849],
              [-81.241408843029163, 36.075834994111815],
              [-81.241980732060924, 36.072923735031715],
              [-81.237623651129496, 36.069126222790629],
              [-81.239595907985191, 36.067745480152723],
              [-81.23878765311764, 36.064933110577556],
              [-81.242067774412874, 36.062088679088745],
              [-81.242931219038681, 36.055959993729687],
              [-81.244363170538918, 36.055596382957489],
              [-81.242299036846489, 36.053983048805037],
              [-81.242642686396863, 36.050500978270072],
              [-81.251597140551127, 36.046466381679522],
              [-81.24888298667355, 36.032570284188957],
              [-81.250833758268968, 36.02955987538396],
              [-81.250409299657619, 36.026524244150629],
              [-81.268725455036375, 36.025012225218497],
              [-81.271774552175373, 36.021596170610046],
              [-81.282331513482262, 36.018030615120971],
              [-81.282694170149256, 36.01617779499388],
              [-81.284840678617485, 36.015631640292838],
              [-81.284311338699737, 36.0131254801098],
              [-81.295715927593392, 36.006953120527051],
              [-81.300620096701749, 36.006207540401078],
              [-81.301365792385965, 36.004131220267226],
              [-81.307898602591507, 36.005485843793487],
              [-81.313849699560137, 36.004598767713105],
              [-81.314189838859932, 36.0011163673665],
              [-81.31600389350362, 36.000528410165515],
              [-81.317774401907698, 35.996681485269555],
              [-81.322854515020211, 35.996770332608527],
              [-81.3259736107441, 35.994717584742901],
              [-81.330640603127065, 35.996923791936581],
              [-81.333427709433209, 35.994829659674785],
              [-81.343388378209482, 35.992541020261633],
              [-81.347479669421588, 35.988981684370025],
              [-81.352868561219267, 35.987480917579376],
              [-81.35493966274332, 35.985568735884513],
              [-81.35468842805453, 35.983368681803519],
              [-81.358778694046322, 35.979808933770798],
              [-81.366311559486221, 35.977760008157674],
              [-81.366752139165982, 35.973747922957699],
              [-81.369789023168195, 35.97385295190022],
              [-81.371453249607754, 35.970534625195846],
              [-81.377218106327931, 35.972332797646388],
              [-81.377503747814131, 35.969114873605605],
              [-81.381666722916677, 35.966919059540885],
              [-81.384320244835735, 35.967247411387504],
              [-81.391907271023044, 35.961408362459046],
              [-81.393103168369876, 35.963996171923981],
              [-81.396648946756358, 35.964976787439383],
              [-81.407851539561975, 35.965005717709502],
              [-81.410860554257624, 35.967003944069916],
              [-81.418489863045238, 35.967945925360482],
              [-81.418154661586399, 35.971428706416553],
              [-81.421519921691242, 35.975097078657768],
              [-81.420292147420042, 35.977927522464121],
              [-81.422918941995363, 35.980149240093965],
              [-81.421485939532616, 35.984038708926001],
              [-81.424342193441532, 35.986830830400656],
              [-81.434684832939126, 35.98078702169709],
              [-81.437546626511562, 35.976531433209651],
              [-81.446965168479494, 35.975252264749479],
              [-81.458963128695288, 35.96941061288711],
              [-81.460110282382644, 35.972262506893578],
              [-81.468174753681836, 35.972712581139675],
              [-81.469475685003758, 35.974770046986777],
              [-81.472666096861843, 35.974078119015509],
              [-81.471286882999607, 35.97770342559955],
              [-81.464854525275769, 35.979351892833726],
              [-81.461586348292286, 35.982202617110005],
              [-81.459082297548832, 35.98812905279577],
              [-81.461403590257959, 35.991938523871589],
              [-81.472481886438061, 35.994385132269315],
              [-81.477510176958106, 35.998255816705239],
              [-81.489815668235025, 35.994346156718287],
              [-81.492572766511259, 35.990618855529718],
              [-81.50165988562209, 35.985770954856811],
              [-81.505666580887876, 35.980841508904646],
              [-81.51687045296373, 35.977336478287661],
              [-81.527127247790915, 35.969919936982251],
              [-81.533176629692377, 35.972016002041343],
              [-81.535041123033196, 35.974683611406419],
              [-81.539432019299653, 35.97657623939287],
              [-81.552983159680679, 35.974981486621431],
              [-81.556962019857792, 35.971944545085705],
              [-81.560764316026365, 35.971596187961595],
              [-81.562088342925392, 35.968234648825799],
              [-81.566784242875585, 35.968537367044235],
              [-81.568592053573354, 35.964421815413033],
              [-81.572596956704885, 35.963013789514072],
              [-81.572671249535603, 35.960854679822766],
              [-81.574814094678501, 35.96030328112905],
              [-81.580965484576637, 35.961867282667434],
              [-81.582550347435713, 35.964228847747464],
              [-81.58760541528008, 35.96620068794914],
              [-81.586512936347646, 35.970132650047262],
              [-81.583120822655729, 35.971886937450556],
              [-81.584378366199786, 35.977731683741283],
              [-81.581368453426961, 35.979261567441696],
              [-81.583082615716222, 35.982722881793322],
              [-81.580583986855075, 35.985128183577373],
              [-81.58802314183815, 35.994165922458066],
              [-81.597091297288642, 35.998252326039889],
              [-81.594597311511578, 36.004181535045156],
              [-81.597303753242031, 36.004240305286061],
              [-81.597741975132138, 36.007274729840198],
              [-81.596059108319167, 36.008966721838178],
              [-81.596598722944336, 36.011471491051424],
              [-81.594302442942279, 36.012817732061791],
              [-81.598983255638643, 36.018536991533615],
              [-81.601971553430587, 36.018900919841428],
              [-81.602359352822432, 36.022200120047685],
              [-81.611459715921811, 36.027914722841267],
              [-81.609269945872654, 36.032255148792011],
              [-81.613362047120006, 36.035733924451115],
              [-81.619570999303406, 36.037031018083482],
              [-81.621221178713313, 36.046174182729715],
              [-81.617826464477417, 36.047929361400307],
              [-81.61750007276261, 36.051412492828987],
              [-81.620270685815996, 36.058252925916619],
              [-81.619589738263983, 36.063589812374559],
              [-81.624500462685106, 36.066354416094768],
              [-81.623199794635582, 36.067822260895774],
              [-81.62496852059364, 36.071017925164263],
              [-81.622417650868471, 36.073688775619516],
              [-81.622676960803688, 36.07588811243555],
              [-81.629582463554627, 36.078894703224336],
              [-81.627572647515365, 36.084070135514516],
              [-81.633611875989203, 36.088055048436587],
              [-81.634875245735941, 36.093898770050416],
              [-81.640149614065663, 36.098332294409857],
              [-81.639361668327794, 36.10067540768307],
              [-81.64105248406733, 36.10250630537621],
              [-81.649189730613884, 36.106202633121946],
              [-81.6563227933175, 36.106254194695381],
              [-81.662314761096567, 36.110502423225981],
              [-81.659590807396611, 36.115862587644337],
              [-81.66128245164856, 36.117693179061419],
              [-81.651965778284747, 36.125506179975261],
              [-81.653122567536428, 36.128355889153241],
              [-81.651619012956388, 36.130883285191274],
              [-81.651604774423106, 36.136300660396174],
              [-81.641273209929807, 36.136945266643131],
              [-81.632024878062282, 36.142597735416004],
              [-81.63149603022319, 36.14713993042318],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        Res_Basin: "Narrows",
        BASIN_ID: "Narrows_Basin",
        BASIN_NAME: "Yadkin Narrows",
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.048746605882485, 35.511171921372032],
              [-80.045797080580428, 35.507508537333194],
              [-80.048779486756473, 35.501390730531078],
              [-80.039242132803736, 35.48405396996052],
              [-80.041438140236508, 35.48326580045989],
              [-80.043951772354191, 35.485782837035579],
              [-80.047295983802684, 35.48596642446995],
              [-80.047691561634593, 35.482486500652413],
              [-80.054061620711721, 35.4795481426615],
              [-80.054891707084309, 35.477214681292885],
              [-80.057912033768616, 35.475722971029164],
              [-80.058028677823202, 35.473564228798786],
              [-80.060224189730846, 35.472775703234021],
              [-80.062986625123202, 35.466084105158977],
              [-80.062172480688858, 35.463526876615489],
              [-80.058940555459401, 35.462815201687619],
              [-80.059552895314894, 35.459908420354168],
              [-80.058243195689897, 35.458099166133252],
              [-80.060827872785026, 35.455460843241468],
              [-80.057935583884856, 35.451533327356671],
              [-80.061060927647361, 35.451143271827668],
              [-80.06068700906269, 35.448102226437491],
              [-80.067438014154007, 35.444943543517113],
              [-80.067883017578993, 35.442829456418714],
              [-80.065698673810516, 35.440357562658896],
              [-80.062518153954642, 35.441012003716601],
              [-80.063267284844272, 35.429425110717453],
              [-80.061194744411367, 35.426424578534643],
              [-80.069652437422263, 35.419964502156333],
              [-80.074126601553459, 35.42600996636218],
              [-80.079768547415441, 35.426505564171677],
              [-80.081967249131651, 35.424086285576465],
              [-80.086785251505361, 35.42528499422049],
              [-80.091677170642839, 35.419697949628279],
              [-80.096503960018637, 35.417635616335843],
              [-80.096077753808203, 35.413228540898615],
              [-80.099965068370011, 35.414028502816812],
              [-80.101019347348952, 35.409007108047369],
              [-80.103101607514432, 35.408746311019314],
              [-80.103272348379335, 35.406323109886564],
              [-80.10529052505008, 35.409587244317549],
              [-80.107534953971637, 35.410163848908518],
              [-80.109685200609107, 35.404747618356915],
              [-80.113410002391177, 35.404709666696021],
              [-80.122059039913637, 35.406970647575143],
              [-80.125063742649601, 35.410368157917809],
              [-80.121881065529521, 35.412654550935656],
              [-80.119394926811509, 35.421287036462289],
              [-80.122898857955434, 35.422305947534305],
              [-80.124985253942995, 35.420414444478432],
              [-80.135265036844558, 35.429418504159216],
              [-80.138112225536361, 35.430347883440092],
              [-80.140745054423604, 35.429073765205516],
              [-80.144848185468391, 35.43207566563477],
              [-80.145114672420704, 35.435645085331743],
              [-80.142917637832525, 35.438065513483416],
              [-80.146640659910972, 35.439656813771855],
              [-80.144772275664295, 35.442121770960377],
              [-80.14811146063785, 35.443932806924316],
              [-80.145135833789226, 35.451683704302866],
              [-80.150565363601274, 35.449972530936229],
              [-80.155825487769647, 35.450684292146832],
              [-80.157752192104653, 35.446324589805286],
              [-80.163291648824341, 35.444084217628159],
              [-80.164394459958089, 35.440428383840917],
              [-80.167193031026699, 35.438360765291485],
              [-80.179353036970639, 35.441634959734202],
              [-80.185538750619742, 35.446003534791991],
              [-80.184986539277801, 35.448646687040274],
              [-80.186627854897637, 35.45049894319444],
              [-80.185305058659466, 35.455212206071927],
              [-80.182508982924745, 35.455649948484954],
              [-80.182558976847531, 35.458646183133993],
              [-80.183427130342949, 35.46419881125567],
              [-80.186273658680022, 35.466757262091761],
              [-80.185387341356375, 35.47261648180536],
              [-80.19124945130217, 35.475309997835495],
              [-80.189763291275369, 35.479185897982966],
              [-80.177749040440588, 35.483271174525157],
              [-80.17143015264277, 35.490842567717607],
              [-80.164624068691708, 35.494271355189753],
              [-80.164126114432989, 35.496650003805222],
              [-80.159732710661501, 35.499861050774804],
              [-80.15922832539917, 35.505500079025488],
              [-80.16169122469276, 35.508278851534421],
              [-80.17046982367161, 35.506747103698025],
              [-80.172103497062238, 35.51349005532844],
              [-80.174354283530477, 35.512435144814596],
              [-80.177094449839473, 35.513892106723176],
              [-80.172097803682405, 35.516750435961249],
              [-80.168152104166793, 35.514587021045806],
              [-80.165400124754683, 35.519650606354148],
              [-80.159692266738091, 35.521053606580438],
              [-80.161771247627158, 35.524052160793183],
              [-80.160670193826519, 35.526077529316964],
              [-80.151892453571534, 35.525978350934139],
              [-80.148220516479768, 35.524123056081713],
              [-80.140915040645424, 35.528298721276101],
              [-80.141729018167325, 35.532485428697548],
              [-80.132566001334297, 35.532604578137168],
              [-80.133712353180542, 35.535205703961907],
              [-80.132277575559314, 35.53881640947246],
              [-80.127447889699084, 35.539249830833029],
              [-80.125631942941396, 35.541450004759042],
              [-80.126227063003199, 35.545063683652025],
              [-80.124458715151235, 35.550259847902034],
              [-80.118589214885446, 35.549193338661006],
              [-80.112604668100431, 35.550285193705548],
              [-80.114178612271914, 35.557292946948373],
              [-80.101335903523946, 35.557183391775631],
              [-80.095230274584594, 35.562063110965575],
              [-80.092925846008839, 35.561750594890654],
              [-80.091066476840012, 35.559324073715501],
              [-80.086397514237902, 35.560593201372278],
              [-80.084705330083736, 35.557373847222294],
              [-80.080208189909015, 35.556219876179661],
              [-80.072784534269189, 35.560919699381209],
              [-80.070039643278932, 35.561090505180793],
              [-80.063076264371816, 35.558785458214381],
              [-80.058366963314157, 35.555427332945897],
              [-80.058098832332803, 35.553488225603857],
              [-80.054037595767994, 35.553479672111955],
              [-80.051638340471214, 35.548804402142906],
              [-80.040247869768706, 35.541818230336993],
              [-80.043443974787124, 35.537904143238876],
              [-80.050527254666932, 35.536421617290564],
              [-80.055256413033959, 35.533259484482841],
              [-80.05054903129394, 35.529900974643077],
              [-80.05018059703562, 35.525229934498654],
              [-80.046399656680009, 35.523899948330588],
              [-80.047670965027535, 35.521082940208665],
              [-80.046856747076362, 35.51852572891741],
              [-80.049165154178155, 35.517208971399548],
              [-80.050274486500143, 35.513554439147192],
              [-80.048746605882485, 35.511171921372032],
            ],
          ],
        ],
      },
    },
  ],
};
