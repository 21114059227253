export const starsYadkin = {
"type": "FeatureCollection",
"name": "yadkin_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "BLEW_YAD", "DisplayName": "Blewett (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.126917066009092, "LON": -79.987388285455864 }, "geometry": { "type": "Point", "coordinates": [ -79.893740168626138, 35.020458032951701 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "FALL_YAD", "DisplayName": "Falls (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.391726631888694 , "LON": -80.079985094439763 }, "geometry": { "type": "Point", "coordinates": [ -80.075003241027147, 35.395108396503666 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "HIGH_YAD", "DisplayName": "High Rock (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.691311877933401, "LON": -80.330029319084986 }, "geometry": { "type": "Point", "coordinates": [ -80.307924288366252, 35.663441765934373 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "TILL_YAD", "DisplayName": "Tillery (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.27972581190545 , "LON": -80.089928186124439 }, "geometry": { "type": "Point", "coordinates": [ -80.0981713025989, 35.254739553681908 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "NARR_YAD", "DisplayName": "Narrows (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.49257361157521, "LON": -80.108947186094895 }, "geometry": { "type": "Point", "coordinates": [ -80.112216189665745, 35.457890242110125 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "TUCK_YAD", "DisplayName": "Tuckertown (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.566968256367886, "LON": -80.179072333960249 }, "geometry": { "type": "Point", "coordinates": [ -80.190203326113775, 35.525793869894777 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Yadkin", "SUBBASIN_ID": "KERR_YAD", "DisplayName": "Kerr Scott", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 36.110320610616213, "LON": -81.316000984221262 }, "geometry": { "type": "Point", "coordinates": [ -81.244930329517217, 36.127723317605195 ] } }
]
}
