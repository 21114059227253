export const starsCatawba = {
"type": "FeatureCollection",
"name": "catawba_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "CEDA_CAT", "DisplayName": "Cedar Creek (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.540802623469119, "LON": -80.878059353651395}, "geometry": { "type": "Point", "coordinates": [ -80.878059353651395, 34.540802623469119 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "FISH_CAT", "DisplayName": "Fishing Creek (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.748741313429349, "LON": -80.868788752280778 }, "geometry": { "type": "Point", "coordinates": [ -80.868788752280778, 34.748741313429349 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "GREA_CAT", "DisplayName": "Great Falls (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.640567471158221, "LON": -80.917466981302596 }, "geometry": { "type": "Point", "coordinates": [ -80.917466981302596, 34.640567471158221 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "HICK_CAT", "DisplayName": "Hickory (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.794309877773401, "LON": -81.340326117516895 }, "geometry": { "type": "Point", "coordinates": [ -81.340326117516895, 35.794309877773401 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "JAME_CAT", "DisplayName": "James", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.781152464064668, "LON": -81.994662102638316, }, "geometry": { "type": "Point", "coordinates": [ -81.994662102638316, 35.781152464064668 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "NORM_CAT", "DisplayName": "Norman (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.630797211360516, "LON": -80.971340584756319 },  "geometry": { "type": "Point", "coordinates": [ -80.971340584756319, 35.630797211360516 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "RHOD_CAT", "DisplayName": "Rhodiss (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.779758277795963 , "LON": -81.552718117187226 }, "geometry": { "type": "Point", "coordinates": [ -81.552718117187226, 35.779758277795963 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WATR_CAT", "DisplayName": "Wateree (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 34.413586865717811, "LON": -80.790812314356259 }, "geometry": { "type": "Point", "coordinates": [ -80.790812314356259, 34.413586865717811 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WYLI_CAT", "DisplayName": "Wylie (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.14984901210704, "LON": -81.054755384626844 }, "geometry": { "type": "Point", "coordinates": [ -81.054755384626844, 35.14984901210704 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LOOK_CAT", "DisplayName": "Lookout Shoals (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.864229974928868 , "LON": -81.160425431043649 },  "geometry": { "type": "Point", "coordinates": [ -81.160425431043649, 35.864229974928868 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MOUN_CAT", "DisplayName": "Mountain Is. (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.377883078419757, "LON": -80.958183984776724 },"geometry": { "type": "Point", "coordinates": [ -80.958183984776724, 35.377883078419757 ] } }
]
}
