import { Header } from "./Header";
// import { Footer } from "./Footer";
import "./ProjectLayout.css";

export const ProjectLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      {/* <Footer /> */}
    </>
  );
};
