import { useState } from "react";
import { Typography, Box, Button, Modal, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
};
export const About = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const aboutText = `For more information on using the portal, please see the User Guide. Please view the Disclaimer before using this data.`;

  return (
    <>
      <Button size="small" sx={{ m: 2, color: "white" }} onClick={handleOpen}>
        <InfoOutlinedIcon />
        About
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="About-info-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar sx={{ background: "#496D99" }}>
            <Toolbar
              sx={{ background: "#496D99", justifyContent: "space-between" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                About
              </Typography>
              <Button
                size="large"
                sx={{ m: 1, color: "white" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>
          <Typography
            id="modal-modal-text"
            variant="p"
            sx={{ m: 3, marginTop: "15%" }}
          >
            {aboutText}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
