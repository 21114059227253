export const starsNant = {
"type": "FeatureCollection",
"name": "nant_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "LAKE_NAN", "DisplayName": "Nantahala Lake", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.162298078754418, "LON": -83.655325580590201}, "geometry": { "type": "Point", "coordinates": [ -83.668488632233348, 35.180363673808635 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "DICK_NAN", "DisplayName": "Dicks Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.215835964355925, "LON": -83.692126035556285 }, "geometry": { "type": "Point", "coordinates": [ -83.668290873118778, 35.218535039880365 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "WHIT_NAN", "DisplayName": "Whiteoak Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.216917702778638 , "LON": -83.595634968250465 }, "geometry": { "type": "Point", "coordinates": [ -83.631558091559171, 35.228953665151153 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "QUEE_NAN", "DisplayName": "Queens Creek", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.283552789617623 , "LON": -83.637822766736207}, "geometry": { "type": "Point", "coordinates": [ -83.655294041036086, 35.277417444693896 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "HYDR_NAN", "DisplayName": "Nantahala Station Bypass", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.244610206400026 , "LON": -83.655130581499577 }, "geometry": { "type": "Point", "coordinates": [ -83.676685043959282, 35.271988639625434 ] } }

]
}