export const squaresCatawba = {
    "type": "FeatureCollection",
    "name": "catawba_squares",
    "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    "features": [
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "PLEA_CAT", "DisplayName": "Pleasant Gardens", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.638795687635884 , "LON": -82.181586502082681 }, "geometry": { "type": "Point", "coordinates": [ -82.181586502082681, 35.638795687635884 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "JOHN_CAT", "DisplayName": "Johns River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.989278936594218, "LON": -81.722064020115042}, "geometry": { "type": "Point", "coordinates": [ -81.722064020115042, 35.989278936594218 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LOWL_CAT", "DisplayName": "Lower Little R", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.956523895263672 , "LON": -81.233909606933594 }, "geometry": { "type": "Point", "coordinates": [ -81.233909606933594, 35.956523895263672 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "ROCK_CAT", "DisplayName": "Rocky Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 34.621961570287596 , "LON": -81.038405336961688}, "geometry": { "type": "Point", "coordinates": [ -81.038405336961688, 34.621961570287596 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "SFOR_CAT", "DisplayName": "South Fork", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.463121367787608 , "LON": -81.311868810223018 }, "geometry": { "type": "Point", "coordinates": [ -81.311868810223018, 35.463121367787608 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "SUGA_CAT", "DisplayName": "Sugar Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.134272887283487 , "LON": -80.849750156040926 }, "geometry": { "type": "Point", "coordinates": [ -80.849750156040926, 35.134272887283487 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "FCRK_CAT", "DisplayName": "Fishing Creek", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 34.815809095637398 , "LON": -81.022828303674643 }, "geometry": { "type": "Point", "coordinates": [ -81.022828303674643, 34.815809095637398 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "LINV_CAT", "DisplayName": "Linville River", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.953592742109549 , "LON": -81.899772548940462 }, "geometry": { "type": "Point", "coordinates": [ -81.899772548940462, 35.953592742109549 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MCDO_CAT", "DisplayName": "McDowell Cr.", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.414659486450155, "LON": -80.875711878185967 }, "geometry": { "type": "Point", "coordinates": [ -80.875711878185967, 35.414659486450155 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MIDL_CAT", "DisplayName": "Middle Little R", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.918316896064368, "LON": -81.320522717604703 }, "geometry": { "type": "Point", "coordinates": [ -81.320522717604703, 35.918316896064368 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "UPPL_CAT", "DisplayName": "Upper Little R", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.892355173919306 , "LON": -81.401869446992563 }, "geometry": { "type": "Point", "coordinates": [ -81.401869446992563, 35.892355173919306 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "DUTC_CAT", "DisplayName": "Dutchmans Cr.", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.428505738260853, "LON": -81.069559403535763 }, "geometry": { "type": "Point", "coordinates": [ -81.069559403535763, 35.428505738260853 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "WARR_CAT", "DisplayName": "Warrior Fork", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage",  "LAT": 35.860335716607075 , "LON": -81.822449345742669 }, "geometry": { "type": "Point", "coordinates": [ -81.822449345742669, 35.860335716607075 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "MORG_CAT", "DisplayName": "Abv Rhodiss (Local)", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.678603661591637 , "LON": -81.799949186550236 }, "geometry": { "type": "Point", "coordinates": [ -81.799949186550236, 35.678603661591637 ] } },
    { "type": "Feature", "properties": { "BASIN_NAME": "Catawba", "SUBBASIN_ID": "BELW_CAT", "DisplayName": "Bowater  (Local)", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 34.928309891599334 , "LON": -80.901673600331023 }, "geometry": { "type": "Point", "coordinates": [ -80.901673600331023, 34.928309891599334 ] } }
    ]
}