import { useState } from "react";
import { Typography, Box, Button, Modal, AppBar, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  display: "flex",
  flexDirection: "column",
};
export const MoreInfo = ({name}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const MoreInfoText = (name) => {
    let moreText;
    if (name.includes("JAME_CAT")) {
      moreText = "Here's some additional help text regarding Lake James. (Work in progress)";
    } 
    if (name.includes("RHOD_CAT")) {
      moreText = "Here's some additional help text regarding Rhodiss Local. (Work in progress)";
    } 
    if (name.includes("HICK_CAT")) {
      moreText = "Here's some additional help text regarding Hickory Local. (Work in progress)";
    } 
    if (name.includes("LOOK_CAT")) {
      moreText = "Here's some additional help text regarding Lookout Shoals Local. (Work in progress)";
    } 
    if (name.includes("NORM_CAT")) {
      moreText = "Here's some additional help text regarding Norman. (Work in progress)";
    } 
    if (name.includes("MOUN_CAT")) {
      moreText = "Here's some additional help text regarding Mountain Isl. (Work in progress)";
    } 
    if (name.includes("WYLI_CAT")) {
      moreText = "Here's some additional help text regarding Wylie. (Work in progress)";
    } 
    if (name.includes("FISH_CAT")) {
      moreText = "Here's some additional help text regarding Fishing Creek Local. (Work in progress)";
    } 
    if (name.includes("GREA_CAT")) {
      moreText = "Here's some additional help text regarding Great Falls Local. (Work in progress)";
    } 
    if (name.includes("CEDA_CAT")) {
      moreText = "Here's some additional help text regarding Cedar Creek Local. (Work in progress)";
    } 
    if (name.includes("WATR_CAT")) {
      moreText = "Here's some additional help text regarding Watere Local. (Work in progress)";
    } 
    if (name.includes("PLEA_CAT")) {
      moreText = "Here's some additional help text regarding Pleasant Gardens. (Work in progress)";
    } 
    if (name.includes("LINV_CAT")) {
      moreText = "Here's some additional help text regarding Linville River. (Work in progress)";
    } 
    if (name.includes("MORG_CAT")) {
      moreText = "Here's some additional help text regarding Above Rhodiss Local. (Work in progress)";
    } 
    if (name.includes("WARR_CAT")) {
      moreText = "Here's some additional help text regarding Warrior Fork. (Work in progress)";
    } 
    if (name.includes("JOHN_CAT")) {
      moreText = "Here's some additional help text regarding Johns River. (Work in progress)";
    } 
    if (name.includes("LOWL_CAT")) {
      moreText = "Here's some additional help text regarding Lower Little R. (Work in progress)";
    } 
    if (name.includes("ROCK_CAT")) {
      moreText = "Here's some additional help text regarding Rocky Creek. (Work in progress)";
    } 
    if (name.includes("SFOR_CAT")) {
      moreText = "Here's some additional help text regarding South Fork. (Work in progress)";
    } 
    if (name.includes("SUGA_CAT")) {
      moreText = "Here's some additional help text regarding Sugar Creek. (Work in progress)";
    } 
    if (name.includes("FCRK_CAT")) {
      moreText = "Here's some additional help text regarding Fishing Creek. (Work in progress)";
    } 
    if (name.includes("MCDO_CAT")) {
      moreText = "Here's some additional help text regarding McDowell Creek. (Work in progress)";
    } 
    if (name.includes("MIDL_CAT")) {
      moreText = "Here's some additional help text regarding Middle Little R. (Work in progress)";
    } 
    if (name.includes("UPPL_CAT")) {
      moreText = "Here's some additional help text regarding Upper Little R. (Work in progress)";
    } 
    if (name.includes("DUTC_CAT")) {
      moreText = "Here's some additional help text regarding Dutchmans Cr. (Work in progress)";
    } 
    if (name.includes("BELW_CAT")) {
      moreText = "Here's some additional help text regarding Bowater. (Work in progress)";
    } 
    if (name.includes("KERR_YAD")) {
      moreText = "Here's some additional help text regarding Kerr Scott. No exceedance threshold specified. (Work in progress)";
    } 
    if (name.includes("HIGH_YAD")) {
      moreText = "Here's some additional help text regarding High Rock. (Work in progress)";
    } 
    if (name.includes("TUCK_YAD")) {
      moreText = "Here's some additional help text regarding Tuckertown. (Work in progress)";
    } 
    if (name.includes("NARR_YAD")) {
      moreText = "Here's some additional help text regarding Narrows. (Work in progress)";
    } 
    if (name.includes("FALL_YAD")) {
      moreText = "Here's some additional help text regarding Falls. (Work in progress)";
    } 
    if (name.includes("TILL_YAD")) {
      moreText = "Here's some additional help text regarding Tillery. (Work in progress)";
    } 
    if (name.includes("BLEW_YAD")) {
      moreText = "Here's some additional help text regarding Blewett. (Work in progress)";
    } 
    if (name.includes("PATT_YAD")) {
      moreText = "Here's some additional help text regarding Patterson. (Work in progress)";
    } 
    if (name.includes("UPPR_YAD")) {
      moreText = "Here's some additional help text regarding Upper Yadkin. (Work in progress)";
    } 
    if (name.includes("ELKC_YAD")) {
      moreText = "Here's some additional help text regarding Elk Creek. (Work in progress)";
    } 
    if (name.includes("REDD_YAD")) {
      moreText = "Here's some additional help text regarding Reddies River. (Work in progress)";
    } 
    if (name.includes("WILK_YAD")) {
      moreText = "Here's some additional help text regarding Wilkesboro. (Work in progress) ";
    } 
    if (name.includes("MITC_YAD")) {
      moreText = "Here's some additional help text regarding Mitchell River. (Work in progress)";
    } 
    if (name.includes("FISH_YAD")) {
      moreText = "Here's some additional help text regarding Fisher River. (Work in progress)";
    } 
    if (name.includes("ARAR_YAD")) {
      moreText = "Here's some additional help text regarding Arat River. (Work in progress)";
    } 
    if (name.includes("LYAD_YAD")) {
      moreText = "Here's some additional help text regarding Little Yadkin. (Work in progress)";
    } 
    if (name.includes("ELKN_YAD")) {
      moreText = "Here's some additional help text regarding Elkin. (Work in progress)";
    } 
    if (name.includes("ENON_YAD")) {
      moreText = "Here's some additional help text regarding Enon. (Work in progress)";
    } 
    if (name.includes("ABBO_YAD")) {
      moreText = "Here's some additional help text regarding Abbott's Creek. (Work in progress)";
    } 
    if (name.includes("IRSH_YAD")) {
      moreText = "Here's some additional help text regarding IRSH_YAD. (Work in progress)";
    } 
    if (name.includes("MOCK_YAD")) {
      moreText = "Here's some additional help text regarding MOCK_YAD. (Work in progress)";
    } 
    if (name.includes("HUNT_YAD")) {
      moreText = "Here's some additional help text regarding HUNT_YAD. (Work in progress)";
    } 
    if (name.includes("LITT_YAD")) {
      moreText = "Here's some additional help text regarding LITT_YAD. (Work in progress)";
    } 
    if (name.includes("SECO_YAD")) {
      moreText = "Here's some additional help text regarding SECO_YAD. (Work in progress)";
    } 
    if (name.includes("ROAR_YAD")) {
      moreText = "Here's some additional help text regarding ROAR_YAD. (Work in progress)";
    } 
    if (name.includes("UWHA_YAD")) {
      moreText = "Here's some additional help text regarding UWHA_YAD. (Work in progress)";
    } 
    if (name.includes("NORW_YAD")) {
      moreText = "Here's some additional help text regarding NORW_YAD. (Work in progress)";
    } 
    if (name.includes("YADC_YAD")) {
      moreText = "Here's some additional help text regarding YADC_YAD. (Work in progress)";
    } 
    if (name.includes("SYAD_YAD")) {
      moreText = "Here's some additional help text regarding SYAD_YAD. (Work in progress)";
    } 
    if (name.includes("ROCK_YAD")) {
      moreText = "Here's some additional help text regarding ROCK_YAD. (Work in progress)";
    } 
    if (name.includes("CONF_YAD")) {
      moreText = "Here's some additional help text regarding CONF_YAD. (Work in progress)";
    } 
    if (name.includes("GLEN_TUC")) {
      moreText = "Here's some additional help text regarding Glenville. (Work in progress)";
    } 
    if (name.includes("THOR_TUC")) {
      moreText = "Here's some additional help text regarding Thorpe. (Work in progress)";
    } 
    if (name.includes("TANA_TUC")) {
      moreText = "Here's some additional help text regarding Tanasee. (Work in progress)";
    } 
    if (name.includes("WOLF_TUC")) {
      moreText = "Here's some additional help text regarding Wolf Creek. (Work in progress)";
    } 
    if (name.includes("BEAR_TUC")) {
      moreText = "Here's some additional help text regarding Bear Creek. (Work in progress)";
    } 
    if (name.includes("CEDA_TUC")) {
      moreText = "Here's some additional help text regarding Cedar Cliff. (Work in progress)";
    } 
    if (name.includes("GAGE_TUC")) {
      moreText = "Here's some additional help text regarding Below Lakes. (Work in progress)";
    } 
    if (name.includes("CANE_TUC")) {
      moreText = "Here's some additional help text regarding Caney Fork. (Work in progress)";
    } 
    if (name.includes("SAVA_TUC")) {
      moreText = "Here's some additional help text regarding Savannah Creek. (Work in progress)";
    } 
    if (name.includes("CULL_TUC")) {
      moreText = "Here's some additional help text regarding Cullowhee Creek. (Work in progress)";
    } 
    if (name.includes("BELW_TUC")) {
      moreText = "Here's some additional help text regarding Below Cullowhee. (Work in progress)";
    } 
    if (name.includes("DILL_TUC")) {
      moreText = "Here's some additional help text regarding Dillsboro. (Work in progress)";
    } 
    if (name.includes("LAKE_NAN")) {
      moreText = "Here's some additional help text regarding Nantahala Lake. (Work in progress)";
    } 
    if (name.includes("DICK_NAN")) {
      moreText = "Here's some additional help text regarding Dick's Creek. (Work in progress)";
    } 
    if (name.includes("WHIT_NAN")) {
      moreText = "Here's some additional help text regarding Whiteoak Creek. (Work in progress)";
    } 
    if (name.includes("HYDR_NAN")) {
      moreText = "Here's some additional help text regarding Nantahala Station Bypass. (Work in progress)";
    } 
    if (name.includes("QUEE_NAN")) {
      moreText = "Here's some additional help text regarding Queen's Creek. (Work in progress)";
    } 
    if (name.includes("OUTD_NAN")) {
      moreText = "Here's some additional help text regarding Outdoor Center. (Work in progress)";
    } 
    if (name.includes("RAIN_NAN")) {
      moreText = "Here's some additional help text regarding Rainbow Springs. (Work in progress)";
    } 
    if (name.includes("SILV_NAN")) {
      moreText = "Here's some additional help text regarding Silvermine Creek. (Work in progress)";
    } 
    if (name.includes("HEWI_NAN")) {
      moreText = "Here's some additional help text regarding Hewitt. (Work in progress)";
    } 

    return moreText;
  }
  
  return (
    <>
      <Button size="small" sx={{ m: 0.6, color: "black" }} onClick={handleOpen}>
        <InfoOutlinedIcon fontSize="small"/>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="MoreInfo-info-modal"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar sx={{ background: "#496D99" }}>
            <Toolbar
              sx={{ background: "#496D99", justifyContent: "space-between" }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                More Information
              </Typography>
              <Button
                size="large"
                sx={{ m: 1, color: "white" }}
                onClick={handleClose}
              >
                <CloseIcon />
              </Button>
            </Toolbar>
          </AppBar>
          <Typography
            id="modal-modal-text"
            variant="p"
            sx={{ m: 3, marginTop: "25%" }}
          >
            {MoreInfoText(name)}
        
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
