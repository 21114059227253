export const extractThresholds = (array) => {
  const thresholdsArray = array.map((basinItem) => {
    return {
      name: basinItem.header.stationName.split("_")[0],
      threshold: basinItem.events
        ? basinItem.events
            .map((timestep) => parseInt(timestep.value))
            .reduce((prev, next) => prev + next)
        : 0,
    };
  });

  return thresholdsArray;
};

export const processTSForThumbnails = (fullArray) => {
  // each basin object has its name, and an array of timeseries
  // {name: James,  ts: [{ts1}, {ts2}, {ts3}]}

  // create set of basin names
  const setOfBasins = new Set();
  fullArray.forEach((ts) => {
    setOfBasins.add(ts.header.locationId);
  });
  // create array of objects, each obj has a basin
  const processedTS4Thumbnails = [];
  setOfBasins.forEach((basin) => {
    processedTS4Thumbnails.push({ name: basin, ts: [] });
  });

  // then loop through fullArray and add each ts to the correct basin object
  fullArray.forEach((ts) => {
    processedTS4Thumbnails.forEach((basinObj) => {
      if (basinObj.name === ts.header.locationId) {
        basinObj.ts.push(ts);
      }
    });
  });

  return processedTS4Thumbnails;
};

export const extractShortFlowForecastDateTime = (array) => {
  const shortFlowForecastDateTime = array.find((ts) => {
    return ts.header.qualifierId && ts.header.qualifierId[0] === "ShortRange";
  }).header.forecastDate;
  return shortFlowForecastDateTime;
};

export const extractMedFlowForecastDateTime = (array) => {
  const medFlowForecastDateTime = array.find((ts) => {
    return ts.header.qualifierId && ts.header.qualifierId[0] === "LastMedFlow";
  }).header.forecastDate;
  return medFlowForecastDateTime;
};

export const addCorrectPrecipTs = (shortPpt, medPpt, allTs) => {
  // take in 2 new corrected precip ts lists and list of all ts
  // remove out all the old precip ts
  const filteredAllTs = allTs.filter((ts) => {
    return (
      (ts.header.parameterId !== "FMAP" &&
        ts.header.moduleInstanceId !== "PreprocessGFS") ||
      (ts.header.parameterId !== "FMAP" &&
        ts.header.moduleInstanceId !== "PreprocessHRRR")
    );
  });

  // add in new precip ts
  const correctTs = filteredAllTs.concat(shortPpt, medPpt);

  //then parse out into separate basins and proceed as before with thumbnails
  return processTSForThumbnails(correctTs);
};

export const grabSelectedBasinTs = (allTs, selectedBasin) => {
  // find the object which matches selectedBasin
  // return that object's ts only!

  const basinOfInterest = allTs.find((obj) => {
    return obj.name.match(selectedBasin);
  });

  return basinOfInterest.ts;
};
