export const getT0 = (array) => {
  let t0 = array;
  // // const tsMrms = array.find((ts) => ts.name.startsWith("Preprocess_MRMS_grids"));
  // console.log('obj',obj);
  // return 5;
  // let t0_array = tsMrms['x'];
  // let t0 = t0_array.slice(-1);
  // t0 = t0[0];
  // console.log('t0',t0);
  return t0;
  // const tsMrms = array.find((ts) => ts.name.startsWith("Preprocess_MRMS_grids_to_MAPmerged"));
  // // console.log('tsMrms',tsMrms);
  // if (tsMrms) {
  //   let t0_array = tsMrms['x'];
  //   let t0 = t0_array.slice(-1);
  //   t0 = t0[0];
  //   console.log('0000t0',t0);
  //   return t0;
  // } else {
  //   return 0;
  // }
};


export const extractThresholds = (array) => {
  const thresholdsArray = array.map((basinItem) => {
    return {
      // name: basinItem.header.stationName.split("_")[0],
      name: basinItem.header.stationName,
      threshold: basinItem.events
        ? basinItem.events
            .map((timestep) => parseInt(timestep.value))
            .reduce((prev, next) => prev + next)
        : 0,
    };
  });

  return thresholdsArray;
};

export const processTSForThumbnails = (fullArray) => {
  // each basin object has its name, and an array of timeseries
  // {name: James,  ts: [{ts1}, {ts2}, {ts3}]}

  // create set of basin names
  const setOfBasins = new Set();
  fullArray.forEach((ts) => {
    setOfBasins.add(ts.header.locationId);
  });
  // create array of objects, each obj has a basin
  const processedTS4Thumbnails = [];
  setOfBasins.forEach((basin) => {
    processedTS4Thumbnails.push({ name: basin, ts: [] });
  });

  // then loop through fullArray and add each ts to the correct basin object
  fullArray.forEach((ts) => {
    processedTS4Thumbnails.forEach((basinObj) => {
      if (basinObj.name === ts.header.locationId) {
        basinObj.ts.push(ts);
      }
    });
  });

  return processedTS4Thumbnails;
};


// export const extractShortFlowForecastDateTime = (array) => {
//   const shortFlowForecastDateTime = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "ShortRange";
//   }).header.forecastDate;
//   return shortFlowForecastDateTime;
// };

// export const extractMedFlowForecastDateTime = (array) => {
//   const medFlowForecastDateTime = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "LastMedFlow";
//   }).header.forecastDate;
//   return medFlowForecastDateTime;
// };

// export const extractStarTimeseries = (array) => {
//   const starTimeseries = array.find((ts) => {
//     return ts.header.qualifierId && ts.header.qualifierId[0] === "LastMedFlow";
//   });
//   return starTimeseries;
// };
export const extractStarTimeseries = (allTs) => {
  // REORDER THUMBNAILS
  var sortOrder = ['KERR_YAD','HIGH_YAD','TUCK_YAD','NARR_YAD','FALL_YAD','TILL_YAD','BLEW_YAD',
      'JAME_CAT','RHOD_CAT','HICK_CAT','LOOK_CAT','NORM_CAT','MOUN_CAT','WYLI_CAT','FISH_CAT','GREA_CAT','CEDA_CAT','WATR_CAT',
      'LAKE_NAN','DICK_NAN','WHIT_NAN','HYDR_NAN','QUEE_NAN','OUTD_NAN',
      'GLEN_TUC','TANA_TUC','WOLF_TUC','BEAR_TUC','CEDA_TUC','THOR_TUC','DILL_TUC'
  ]; 
  allTs.sort((a, b) => {
    let fa = a.header.locationId;
    let fb = b.header.locationId;
    return sortOrder.indexOf(fa) - sortOrder.indexOf(fb);
  });
  // allTs.forEach((ts) => {
  //   console.log(`${ts.header.locationId}`);
  // });

  const starTimeseries = allTs.filter((ts) => {
    return (
      (ts.header.locationId === "BLEW_YAD") ||
      (ts.header.locationId === "FALL_YAD") ||
      (ts.header.locationId === "HIGH_YAD") ||
      (ts.header.locationId === "TILL_YAD") ||
      (ts.header.locationId === "NARR_YAD") ||
      (ts.header.locationId === "TUCK_YAD") ||
      (ts.header.locationId === "KERR_YAD") ||

      (ts.header.locationId === "CEDA_CAT") ||
      (ts.header.locationId === "FISH_CAT") ||
      (ts.header.locationId === "GREA_CAT") ||
      (ts.header.locationId === "HICK_CAT") ||
      (ts.header.locationId === "JAME_CAT") ||
      (ts.header.locationId === "NORM_CAT") ||
      (ts.header.locationId === "RHOD_CAT") ||
      (ts.header.locationId === "WATR_CAT") ||
      (ts.header.locationId === "WYLI_CAT") ||
      (ts.header.locationId === "LOOK_CAT") ||
      (ts.header.locationId === "MOUN_CAT") ||

      (ts.header.locationId === "WOLF_TUC") ||
      (ts.header.locationId === "TANA_TUC") ||
      (ts.header.locationId === "BEAR_TUC") ||
      (ts.header.locationId === "CEDA_TUC") ||
      (ts.header.locationId === "GLEN_TUC") ||
      (ts.header.locationId === "THOR_TUC") ||

      // (ts.header.locationId === "RAIN_NAN") ||
      (ts.header.locationId === "LAKE_NAN") ||
      (ts.header.locationId === "DICK_NAN") ||
      (ts.header.locationId === "WHIT_NAN") ||
      (ts.header.locationId === "QUEE_NAN") ||
      (ts.header.locationId === "HYDR_NAN")
    );
  });
  // return processTSForThumbnails(allTs);
  return starTimeseries;
};

export const grabSelectedBasinTs = (allTs, selectedBasin) => {
  // find the object which matches selectedBasin
  // return that object's ts only!

  const basinOfInterest = allTs.find((obj) => {
    return obj.name.match(selectedBasin);
  });

  return basinOfInterest.ts;
};
