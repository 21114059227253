export const riversCatawba = {
  type: "FeatureCollection",
  name: "Catawba_Rivers_Simplified",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID: 288735.0,
        COMID: 166740260,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.178,
        REACHCODE: "03050103017696",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0017266007849839999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094162.0,
        ToNode: 250060055.0,
        Hydroseq: 250005029.0,
        LevelPathI: 250003258.0,
        Pathlength: 375.668,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.577,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005051.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004983.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0261,
        TotDASqKM: 9867.1509,
        DivDASqKM: 9867.1509,
        Tidal: 0,
        TOTMA: 0.04402822398,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10797.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.178000003099442,
        QA_MA: 4886.685,
        VA_MA: -9998.0,
        QC_MA: 4886.685,
        VC_MA: -9998.0,
        QE_MA: 5588.741,
        VE_MA: -9998.0,
        QA_01: 8991.009,
        VA_01: -9998.0,
        QC_01: 8991.009,
        VC_01: -9998.0,
        QE_01: 7554.658,
        VE_01: -9998.0,
        QA_02: 9499.987,
        VA_02: -9998.0,
        QC_02: 9499.987,
        VC_02: -9998.0,
        QE_02: 7687.834,
        VE_02: -9998.0,
        QA_03: 10424.565,
        VA_03: -9998.0,
        QC_03: 10424.565,
        VC_03: -9998.0,
        QE_03: 8187.363,
        VE_03: -9998.0,
        QA_04: 7156.105,
        VA_04: -9998.0,
        QC_04: 7156.105,
        VC_04: -9998.0,
        QE_04: 6934.512,
        VE_04: -9998.0,
        QA_05: 4875.503,
        VA_05: -9998.0,
        QC_05: 4875.503,
        VC_05: -9998.0,
        QE_05: 5861.117,
        VE_05: -9998.0,
        QA_06: 2881.029,
        VA_06: -9998.0,
        QC_06: 2881.029,
        VC_06: -9998.0,
        QE_06: 5120.92,
        VE_06: -9998.0,
        QA_07: 1797.768,
        VA_07: -9998.0,
        QC_07: 1797.768,
        VC_07: -9998.0,
        QE_07: 3890.222,
        VE_07: -9998.0,
        QA_08: 1280.606,
        VA_08: -9998.0,
        QC_08: 1280.606,
        VC_08: -9998.0,
        QE_08: 3619.605,
        VE_08: -9998.0,
        QA_09: 1163.368,
        VA_09: -9998.0,
        QC_09: 1163.368,
        VC_09: -9998.0,
        QE_09: 3308.703,
        VE_09: -9998.0,
        QA_10: 1249.92,
        VA_10: -9998.0,
        QC_10: 1249.92,
        VC_10: -9998.0,
        QE_10: 4494.787,
        VE_10: -9998.0,
        QA_11: 3179.201,
        VA_11: -9998.0,
        QC_11: 3179.201,
        VC_11: -9998.0,
        QE_11: 4556.512,
        VE_11: -9998.0,
        QA_12: 6174.552,
        VA_12: -9998.0,
        QC_12: 6174.552,
        VC_12: -9998.0,
        QE_12: 5882.796,
        VE_12: -9998.0,
        LakeFract: 0.00998935596,
        SurfArea: 122976.39367400001,
        RAreaHLoad: 0.00899390609,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.891603118213368, 34.597256946298216, 0.0],
          [-80.892741918211584, 34.595959146300231, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288736.0,
        COMID: 166740261,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.172,
        REACHCODE: "03050103017697",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0016518250789079999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094165.0,
        ToNode: 250094166.0,
        Hydroseq: 250005378.0,
        LevelPathI: 250005331.0,
        Pathlength: 393.882,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.669,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005393.0,
        DnLevelPat: 250005331.0,
        DnMinorHyd: 250005367.0,
        DnDrainCou: 2,
        DnHydroseq: 250005368.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0684,
        TotDASqKM: 9368.2818,
        DivDASqKM: 0.0684,
        Tidal: 0,
        TOTMA: 0.0120517069669,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12646.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12646.0,
        SLOPE: 0.0390909,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.021999999880790998,
        QA_MA: 0.026,
        VA_MA: 0.54194,
        QC_MA: 0.026,
        VC_MA: 0.54194,
        QE_MA: 0.026,
        VE_MA: 0.54194,
        QA_01: 0.057,
        VA_01: 0.66271,
        QC_01: 0.057,
        VC_01: 0.66271,
        QE_01: 0.057,
        VE_01: 0.66271,
        QA_02: 0.061,
        VA_02: 0.6757,
        QC_02: 0.061,
        VC_02: 0.6757,
        QE_02: 0.061,
        VE_02: 0.6757,
        QA_03: 0.066,
        VA_03: 0.69139,
        QC_03: 0.066,
        VC_03: 0.69139,
        QE_03: 0.066,
        VE_03: 0.69139,
        QA_04: 0.037,
        VA_04: 0.59006,
        QC_04: 0.037,
        VC_04: 0.59006,
        QE_04: 0.037,
        VE_04: 0.59006,
        QA_05: 0.021,
        VA_05: 0.5169,
        QC_05: 0.021,
        VC_05: 0.5169,
        QE_05: 0.021,
        VE_05: 0.5169,
        QA_06: 0.013,
        VA_06: 0.47002,
        QC_06: 0.013,
        VC_06: 0.47002,
        QE_06: 0.013,
        VE_06: 0.47002,
        QA_07: 0.009,
        VA_07: 0.44135,
        QC_07: 0.009,
        VC_07: 0.44135,
        QE_07: 0.009,
        VE_07: 0.44135,
        QA_08: 0.007,
        VA_08: 0.42474,
        QC_08: 0.007,
        VC_08: 0.42474,
        QE_08: 0.007,
        VE_08: 0.42474,
        QA_09: 0.005,
        VA_09: 0.4057,
        QC_09: 0.005,
        VC_09: 0.4057,
        QE_09: 0.005,
        VE_09: 0.4057,
        QA_10: 0.004,
        VA_10: 0.39483,
        QC_10: 0.004,
        VC_10: 0.39483,
        QE_10: 0.004,
        VE_10: 0.39483,
        QA_11: 0.004,
        VA_11: 0.39483,
        QC_11: 0.004,
        VC_11: 0.39483,
        QE_11: 0.004,
        VE_11: 0.39483,
        QA_12: 0.029,
        VA_12: 0.55588,
        QC_12: 0.029,
        VC_12: 0.55588,
        QE_12: 0.029,
        VE_12: 0.55588,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875650184904771, 34.739102679411417, 0.0],
          [-80.876161784903957, 34.737887546079889, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288737.0,
        COMID: 166740262,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.053,
        REACHCODE: "03050103017698",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00055676992964,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094166.0,
        ToNode: 250059474.0,
        Hydroseq: 250005368.0,
        LevelPathI: 250005331.0,
        Pathlength: 393.829,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.722,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005331.0,
        UpHydroseq: 250005378.0,
        DnLevelPat: 250005331.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005360.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 9368.289,
        DivDASqKM: 0.0756,
        Tidal: 0,
        TOTMA: 0.0054015198772100004,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12653.0,
        MAXELEVSMO: 12739.0,
        MINELEVSMO: 12739.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052999999374151001,
        QA_MA: 0.029,
        VA_MA: 0.37259,
        QC_MA: 0.029,
        VC_MA: 0.37259,
        QE_MA: 0.029,
        VE_MA: 0.37259,
        QA_01: 0.064,
        VA_01: 0.40613,
        QC_01: 0.064,
        VC_01: 0.40613,
        QE_01: 0.064,
        VE_01: 0.40613,
        QA_02: 0.068,
        VA_02: 0.40933,
        QC_02: 0.068,
        VC_02: 0.40933,
        QE_02: 0.068,
        VE_02: 0.40933,
        QA_03: 0.073,
        VA_03: 0.41321,
        QC_03: 0.073,
        VC_03: 0.41321,
        QE_03: 0.073,
        VE_03: 0.41321,
        QA_04: 0.041,
        VA_04: 0.38555,
        QC_04: 0.041,
        VC_04: 0.38555,
        QE_04: 0.041,
        VE_04: 0.38555,
        QA_05: 0.023,
        VA_05: 0.36516,
        QC_05: 0.023,
        VC_05: 0.36516,
        QE_05: 0.023,
        VE_05: 0.36516,
        QA_06: 0.015,
        VA_06: 0.35363,
        QC_06: 0.015,
        VC_06: 0.35363,
        QE_06: 0.015,
        VE_06: 0.35363,
        QA_07: 0.01,
        VA_07: 0.34487,
        QC_07: 0.01,
        VC_07: 0.34487,
        QE_07: 0.01,
        VE_07: 0.34487,
        QA_08: 0.008,
        VA_08: 0.34079,
        QC_08: 0.008,
        VC_08: 0.34079,
        QE_08: 0.008,
        VE_08: 0.34079,
        QA_09: 0.006,
        VA_09: 0.3362,
        QC_09: 0.006,
        VC_09: 0.3362,
        QE_09: 0.006,
        VE_09: 0.3362,
        QA_10: 0.005,
        VA_10: 0.33363,
        QC_10: 0.005,
        VC_10: 0.33363,
        QE_10: 0.005,
        VE_10: 0.33363,
        QA_11: 0.005,
        VA_11: 0.33363,
        QC_11: 0.005,
        VC_11: 0.33363,
        QE_11: 0.005,
        VE_11: 0.33363,
        QA_12: 0.032,
        VA_12: 0.37604,
        QC_12: 0.032,
        VC_12: 0.37604,
        QE_12: 0.032,
        VE_12: 0.37604,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876161784903957, 34.737887546079889, 0.0],
          [-80.875655318238117, 34.737656279413613, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286647.0,
        COMID: 9745256,
        FDATE: "2001/04/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.251,
        REACHCODE: "03050102000429",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024124623256750002,
        StreamLeve: 6,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060201.0,
        ToNode: 250060207.0,
        Hydroseq: 250018597.0,
        LevelPathI: 250018597.0,
        Pathlength: 554.2,
        TerminalPa: 250002604.0,
        ArbolateSu: 232.911,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250018884.0,
        DnLevelPat: 250018327.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018327.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 231.4962,
        DivDASqKM: 0.0207,
        Tidal: 0,
        TOTMA: 0.021475245578399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25059.0,
        MAXELEVSMO: 25127.0,
        MINELEVSMO: 25059.0,
        SLOPE: 0.00666666,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.10199999809265101,
        QA_MA: 0.009,
        VA_MA: 0.44382,
        QC_MA: 0.009,
        VC_MA: 0.44382,
        QE_MA: 0.009,
        VE_MA: 0.44382,
        QA_01: 0.018,
        VA_01: 0.50407,
        QC_01: 0.018,
        VC_01: 0.50407,
        QE_01: 0.018,
        VE_01: 0.50407,
        QA_02: 0.019,
        VA_02: 0.50977,
        QC_02: 0.019,
        VC_02: 0.50977,
        QE_02: 0.019,
        VE_02: 0.50977,
        QA_03: 0.021,
        VA_03: 0.52076,
        QC_03: 0.021,
        VC_03: 0.52076,
        QE_03: 0.021,
        VE_03: 0.52076,
        QA_04: 0.014,
        VA_04: 0.47962,
        QC_04: 0.014,
        VC_04: 0.47962,
        QE_04: 0.014,
        VE_04: 0.47962,
        QA_05: 0.01,
        VA_05: 0.45161,
        QC_05: 0.01,
        VC_05: 0.45161,
        QE_05: 0.01,
        VE_05: 0.45161,
        QA_06: 0.005,
        VA_06: 0.40751,
        QC_06: 0.005,
        VC_06: 0.40751,
        QE_06: 0.005,
        VE_06: 0.40751,
        QA_07: 0.003,
        VA_07: 0.38396,
        QC_07: 0.003,
        VC_07: 0.38396,
        QE_07: 0.003,
        VE_07: 0.38396,
        QA_08: 0.002,
        VA_08: 0.36932,
        QC_08: 0.002,
        VC_08: 0.36932,
        QE_08: 0.002,
        VE_08: 0.36932,
        QA_09: 0.001,
        VA_09: 0.35056,
        QC_09: 0.001,
        VC_09: 0.35056,
        QE_09: 0.001,
        VE_09: 0.35056,
        QA_10: 0.001,
        VA_10: 0.35056,
        QC_10: 0.001,
        VC_10: 0.35056,
        QE_10: 0.001,
        VE_10: 0.35056,
        QA_11: 0.005,
        VA_11: 0.40751,
        QC_11: 0.005,
        VC_11: 0.40751,
        QE_11: 0.005,
        VE_11: 0.40751,
        QA_12: 0.012,
        VA_12: 0.46617,
        QC_12: 0.012,
        VC_12: 0.46617,
        QE_12: 0.012,
        VE_12: 0.46617,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.331570517530452, 35.64997141133091, 0.0],
          [-81.330476984198754, 35.648138811333638, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288738.0,
        COMID: 166740263,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.209,
        REACHCODE: "03050103017699",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00187525766709,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094166.0,
        ToNode: 250094168.0,
        Hydroseq: 250005367.0,
        LevelPathI: 250005357.0,
        Pathlength: 393.679,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.878,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250005331.0,
        UpHydroseq: 250005378.0,
        DnLevelPat: 250005357.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 3,
        DnHydroseq: 250005357.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 9368.3169,
        DivDASqKM: 0.0351,
        Tidal: 0,
        TOTMA: 0.021919823906600001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12665.0,
        MAXELEVSMO: 12646.0,
        MINELEVSMO: 12665.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.059000000357628,
        QA_MA: 0.013,
        VA_MA: 0.36206,
        QC_MA: 0.013,
        VC_MA: 0.36206,
        QE_MA: 0.013,
        VE_MA: 0.36206,
        QA_01: 0.029,
        VA_01: 0.39057,
        QC_01: 0.029,
        VC_01: 0.39057,
        QE_01: 0.029,
        VE_01: 0.39057,
        QA_02: 0.031,
        VA_02: 0.39353,
        QC_02: 0.031,
        VC_02: 0.39353,
        QE_02: 0.031,
        VE_02: 0.39353,
        QA_03: 0.033,
        VA_03: 0.39641,
        QC_03: 0.033,
        VC_03: 0.39641,
        QE_03: 0.033,
        VE_03: 0.39641,
        QA_04: 0.019,
        VA_04: 0.37404,
        QC_04: 0.019,
        VC_04: 0.37404,
        QE_04: 0.019,
        VE_04: 0.37404,
        QA_05: 0.01,
        VA_05: 0.35508,
        QC_05: 0.01,
        VC_05: 0.35508,
        QE_05: 0.01,
        VE_05: 0.35508,
        QA_06: 0.007,
        VA_06: 0.34703,
        QC_06: 0.007,
        VC_06: 0.34703,
        QE_06: 0.007,
        VE_06: 0.34703,
        QA_07: 0.004,
        VA_07: 0.33709,
        QC_07: 0.004,
        VC_07: 0.33709,
        QE_07: 0.004,
        VE_07: 0.33709,
        QA_08: 0.003,
        VA_08: 0.33303,
        QC_08: 0.003,
        VC_08: 0.33303,
        QE_08: 0.003,
        VE_08: 0.33303,
        QA_09: 0.002,
        VA_09: 0.32826,
        QC_09: 0.002,
        VC_09: 0.32826,
        QE_09: 0.002,
        VE_09: 0.32826,
        QA_10: 0.002,
        VA_10: 0.32826,
        QC_10: 0.002,
        VC_10: 0.32826,
        QE_10: 0.002,
        VE_10: 0.32826,
        QA_11: 0.002,
        VA_11: 0.32826,
        QC_11: 0.002,
        VC_11: 0.32826,
        QE_11: 0.002,
        VE_11: 0.32826,
        QA_12: 0.015,
        VA_12: 0.3663,
        QC_12: 0.015,
        VC_12: 0.3663,
        QE_12: 0.015,
        VE_12: 0.3663,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876161784903957, 34.737887546079889, 0.0],
          [-80.875884651571141, 34.736032879416086, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286648.0,
        COMID: 9745264,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.187,
        REACHCODE: "03050102000430",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0017732843911109999,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060207.0,
        ToNode: 250060227.0,
        Hydroseq: 250018327.0,
        LevelPathI: 250018327.0,
        Pathlength: 554.013,
        TerminalPa: 250002604.0,
        ArbolateSu: 233.481,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018327.0,
        UpHydroseq: 250018883.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018071.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0234,
        TotDASqKM: 231.6483,
        DivDASqKM: 0.1728,
        Tidal: 0,
        TOTMA: 0.013988322940100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24969.0,
        MAXELEVSMO: 25059.0,
        MINELEVSMO: 24996.0,
        SLOPE: 0.00336898,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.187000006437302,
        QA_MA: 0.082,
        VA_MA: 0.50763,
        QC_MA: 0.082,
        VC_MA: 0.50763,
        QE_MA: 0.082,
        VE_MA: 0.50763,
        QA_01: 0.155,
        VA_01: 0.58778,
        QC_01: 0.155,
        VC_01: 0.58778,
        QE_01: 0.155,
        VE_01: 0.58778,
        QA_02: 0.164,
        VA_02: 0.59628,
        QC_02: 0.164,
        VC_02: 0.59628,
        QE_02: 0.164,
        VE_02: 0.59628,
        QA_03: 0.178,
        VA_03: 0.60908,
        QC_03: 0.178,
        VC_03: 0.60908,
        QE_03: 0.178,
        VE_03: 0.60908,
        QA_04: 0.123,
        VA_04: 0.5555,
        QC_04: 0.123,
        VC_04: 0.5555,
        QE_04: 0.123,
        VE_04: 0.5555,
        QA_05: 0.083,
        VA_05: 0.50892,
        QC_05: 0.083,
        VC_05: 0.50892,
        QE_05: 0.083,
        VE_05: 0.50892,
        QA_06: 0.048,
        VA_06: 0.45832,
        QC_06: 0.048,
        VC_06: 0.45832,
        QE_06: 0.048,
        VE_06: 0.45832,
        QA_07: 0.03,
        VA_07: 0.4252,
        QC_07: 0.03,
        VC_07: 0.4252,
        QE_07: 0.03,
        VE_07: 0.4252,
        QA_08: 0.02,
        VA_08: 0.40258,
        QC_08: 0.02,
        VC_08: 0.40258,
        QE_08: 0.02,
        VE_08: 0.40258,
        QA_09: 0.016,
        VA_09: 0.39205,
        QC_09: 0.016,
        VC_09: 0.39205,
        QE_09: 0.016,
        VE_09: 0.39205,
        QA_10: 0.013,
        VA_10: 0.38332,
        QC_10: 0.013,
        VC_10: 0.38332,
        QE_10: 0.013,
        VE_10: 0.38332,
        QA_11: 0.046,
        VA_11: 0.45497,
        QC_11: 0.046,
        VC_11: 0.45497,
        QE_11: 0.046,
        VE_11: 0.45497,
        QA_12: 0.106,
        VA_12: 0.53673,
        QC_12: 0.106,
        VC_12: 0.53673,
        QE_12: 0.106,
        VE_12: 0.53673,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.330476984198754, 35.648138811333638, 0.0],
          [-81.329664117533412, 35.646787411335708, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288715.0,
        COMID: 166740240,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.114,
        REACHCODE: "03050103017676",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001035039529554,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060139.0,
        ToNode: 250059387.0,
        Hydroseq: 250005499.0,
        LevelPathI: 250005476.0,
        Pathlength: 398.338,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.236,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005512.0,
        DnLevelPat: 250005476.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005490.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9333.7668,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.065979059798400005,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13130.0,
        MINELEVSMO: 13130.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.11400000005960501,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877079718235791, 34.776786812686282, 0.0],
          [-80.876766784903111, 34.775800212687784, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288716.0,
        COMID: 166740241,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.34,
        REACHCODE: "03050103017677",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0032608048368319999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094145.0,
        ToNode: 250059981.0,
        Hydroseq: 250005476.0,
        LevelPathI: 250005476.0,
        Pathlength: 397.974,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.6,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005476.0,
        UpHydroseq: 250005490.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005466.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0477,
        TotDASqKM: 9333.8145,
        DivDASqKM: 0.0477,
        Tidal: 0,
        TOTMA: 0.027653764642800002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12933.0,
        MAXELEVSMO: 13130.0,
        MINELEVSMO: 12934.0,
        SLOPE: 0.0057647,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.34000000357627902,
        QA_MA: 0.018,
        VA_MA: 0.46687,
        QC_MA: 0.018,
        VC_MA: 0.46687,
        QE_MA: 0.018,
        VE_MA: 0.46687,
        QA_01: 0.041,
        VA_01: 0.55376,
        QC_01: 0.041,
        VC_01: 0.55376,
        QE_01: 0.041,
        VE_01: 0.55376,
        QA_02: 0.042,
        VA_02: 0.55691,
        QC_02: 0.042,
        VC_02: 0.55691,
        QE_02: 0.042,
        VE_02: 0.55691,
        QA_03: 0.046,
        VA_03: 0.56921,
        QC_03: 0.046,
        VC_03: 0.56921,
        QE_03: 0.046,
        VE_03: 0.56921,
        QA_04: 0.026,
        VA_04: 0.50104,
        QC_04: 0.026,
        VC_04: 0.50104,
        QE_04: 0.026,
        VE_04: 0.50104,
        QA_05: 0.014,
        VA_05: 0.44707,
        QC_05: 0.014,
        VC_05: 0.44707,
        QE_05: 0.014,
        VE_05: 0.44707,
        QA_06: 0.009,
        VA_06: 0.41807,
        QC_06: 0.009,
        VC_06: 0.41807,
        QE_06: 0.009,
        VE_06: 0.41807,
        QA_07: 0.007,
        VA_07: 0.40437,
        QC_07: 0.007,
        VC_07: 0.40437,
        QE_07: 0.007,
        VE_07: 0.40437,
        QA_08: 0.005,
        VA_08: 0.38866,
        QC_08: 0.005,
        VC_08: 0.38866,
        QE_08: 0.005,
        VE_08: 0.38866,
        QA_09: 0.003,
        VA_09: 0.36959,
        QC_09: 0.003,
        VC_09: 0.36959,
        QE_09: 0.003,
        VE_09: 0.36959,
        QA_10: 0.003,
        VA_10: 0.36959,
        QC_10: 0.003,
        VC_10: 0.36959,
        QE_10: 0.003,
        VE_10: 0.36959,
        QA_11: 0.003,
        VA_11: 0.36959,
        QC_11: 0.003,
        VC_11: 0.36959,
        QE_11: 0.003,
        VE_11: 0.36959,
        QA_12: 0.021,
        VA_12: 0.48039,
        QC_12: 0.021,
        VC_12: 0.48039,
        QE_12: 0.021,
        VE_12: 0.48039,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876683518236405, 34.775594012687975, 0.0],
          [-80.874673984906337, 34.773026012692014, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288711.0,
        COMID: 166740236,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.097,
        REACHCODE: "03050103017672",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00090294633281700004,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094140.0,
        ToNode: 250059382.0,
        Hydroseq: 250005560.0,
        LevelPathI: 250005531.0,
        Pathlength: 398.509,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.409,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005531.0,
        UpHydroseq: 250005574.0,
        DnLevelPat: 250005531.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005549.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0315,
        TotDASqKM: 9333.0099,
        DivDASqKM: 0.1989,
        Tidal: 0,
        TOTMA: 0.0094758317810299999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13115.0,
        MAXELEVSMO: 13115.0,
        MINELEVSMO: 13115.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.097000002861023005,
        QA_MA: 0.078,
        VA_MA: 0.38871,
        QC_MA: 0.078,
        VC_MA: 0.38871,
        QE_MA: 0.078,
        VE_MA: 0.38871,
        QA_01: 0.173,
        VA_01: 0.431,
        QC_01: 0.173,
        VC_01: 0.431,
        QE_01: 0.173,
        VE_01: 0.431,
        QA_02: 0.179,
        VA_02: 0.43324,
        QC_02: 0.179,
        VC_02: 0.43324,
        QE_02: 0.179,
        VE_02: 0.43324,
        QA_03: 0.192,
        VA_03: 0.43798,
        QC_03: 0.192,
        VC_03: 0.43798,
        QE_03: 0.192,
        VE_03: 0.43798,
        QA_04: 0.112,
        VA_04: 0.40572,
        QC_04: 0.112,
        VC_04: 0.40572,
        QE_04: 0.112,
        VE_04: 0.40572,
        QA_05: 0.061,
        VA_05: 0.37888,
        QC_05: 0.061,
        VC_05: 0.37888,
        QE_05: 0.061,
        VE_05: 0.37888,
        QA_06: 0.04,
        VA_06: 0.36473,
        QC_06: 0.04,
        VC_06: 0.36473,
        QE_06: 0.04,
        VE_06: 0.36473,
        QA_07: 0.029,
        VA_07: 0.35589,
        QC_07: 0.029,
        VC_07: 0.35589,
        QE_07: 0.029,
        VE_07: 0.35589,
        QA_08: 0.021,
        VA_08: 0.34841,
        QC_08: 0.021,
        VC_08: 0.34841,
        QE_08: 0.021,
        VE_08: 0.34841,
        QA_09: 0.016,
        VA_09: 0.34303,
        QC_09: 0.016,
        VC_09: 0.34303,
        QE_09: 0.016,
        VE_09: 0.34303,
        QA_10: 0.013,
        VA_10: 0.33941,
        QC_10: 0.013,
        VC_10: 0.33941,
        QE_10: 0.013,
        VE_10: 0.33941,
        QA_11: 0.013,
        VA_11: 0.33941,
        QC_11: 0.013,
        VC_11: 0.33941,
        QE_11: 0.013,
        VE_11: 0.33941,
        QA_12: 0.09,
        VA_12: 0.39505,
        QC_12: 0.09,
        VC_12: 0.39505,
        QE_12: 0.09,
        VE_12: 0.39505,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.873055984908831, 34.778029746017637, 0.0],
          [-80.872713784909365, 34.777243279352149, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288712.0,
        COMID: 166740237,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.111,
        REACHCODE: "03050103017673",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001093466375545,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094140.0,
        ToNode: 250059383.0,
        Hydroseq: 250005559.0,
        LevelPathI: 250005530.0,
        Pathlength: 398.544,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.423,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005531.0,
        UpHydroseq: 250005574.0,
        DnLevelPat: 250005530.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005548.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9332.9784,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.064242769545399994,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13108.0,
        MINELEVSMO: 13108.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.111000001430511,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.873055984908831, 34.778029746017637, 0.0],
          [-80.872297318243341, 34.777289212685389, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288713.0,
        COMID: 166740238,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.24,
        REACHCODE: "03050103017674",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0023666864149790002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094142.0,
        ToNode: 250059979.0,
        Hydroseq: 250005531.0,
        LevelPathI: 250005531.0,
        Pathlength: 398.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.708,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005531.0,
        UpHydroseq: 250005549.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005523.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0225,
        TotDASqKM: 9333.0342,
        DivDASqKM: 0.2232,
        Tidal: 0,
        TOTMA: 0.017683646850200001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13019.0,
        MAXELEVSMO: 13097.0,
        MINELEVSMO: 13019.0,
        SLOPE: 0.00325,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.239999994635582,
        QA_MA: 0.088,
        VA_MA: 0.51536,
        QC_MA: 0.088,
        VC_MA: 0.51536,
        QE_MA: 0.088,
        VE_MA: 0.51536,
        QA_01: 0.195,
        VA_01: 0.62418,
        QC_01: 0.195,
        VC_01: 0.62418,
        QE_01: 0.195,
        VE_01: 0.62418,
        QA_02: 0.201,
        VA_02: 0.62931,
        QC_02: 0.201,
        VC_02: 0.62931,
        QE_02: 0.201,
        VE_02: 0.62931,
        QA_03: 0.216,
        VA_03: 0.64181,
        QC_03: 0.216,
        VC_03: 0.64181,
        QE_03: 0.216,
        VE_03: 0.64181,
        QA_04: 0.126,
        VA_04: 0.55882,
        QC_04: 0.126,
        VC_04: 0.55882,
        QE_04: 0.126,
        VE_04: 0.55882,
        QA_05: 0.069,
        VA_05: 0.49029,
        QC_05: 0.069,
        VC_05: 0.49029,
        QE_05: 0.069,
        VE_05: 0.49029,
        QA_06: 0.045,
        VA_06: 0.45335,
        QC_06: 0.045,
        VC_06: 0.45335,
        QE_06: 0.045,
        VE_06: 0.45335,
        QA_07: 0.033,
        VA_07: 0.43134,
        QC_07: 0.033,
        VC_07: 0.43134,
        QE_07: 0.033,
        VE_07: 0.43134,
        QA_08: 0.024,
        VA_08: 0.41221,
        QC_08: 0.024,
        VC_08: 0.41221,
        QE_08: 0.024,
        VE_08: 0.41221,
        QA_09: 0.018,
        VA_09: 0.3975,
        QC_09: 0.018,
        VC_09: 0.3975,
        QE_09: 0.018,
        VE_09: 0.3975,
        QA_10: 0.015,
        VA_10: 0.38928,
        QC_10: 0.015,
        VC_10: 0.38928,
        QE_10: 0.015,
        VE_10: 0.38928,
        QA_11: 0.015,
        VA_11: 0.38928,
        QC_11: 0.015,
        VC_11: 0.38928,
        QE_11: 0.015,
        VE_11: 0.38928,
        QA_12: 0.102,
        VA_12: 0.53224,
        QC_12: 0.102,
        VC_12: 0.53224,
        QE_12: 0.102,
        VE_12: 0.53224,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872769184909316, 34.776716612686357, 0.0],
          [-80.87443845157334, 34.775093612688806, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288714.0,
        COMID: 166740239,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.265,
        REACHCODE: "03050103017675",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0026800259206180002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094143.0,
        ToNode: 250059979.0,
        Hydroseq: 250005530.0,
        LevelPathI: 250005530.0,
        Pathlength: 398.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.757,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005530.0,
        UpHydroseq: 250005548.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005523.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2493,
        TotDASqKM: 9333.6813,
        DivDASqKM: 0.7029,
        Tidal: 0,
        TOTMA: 0.017498323736,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12974.0,
        MAXELEVSMO: 13100.0,
        MINELEVSMO: 13019.0,
        SLOPE: 0.0030566,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.26499998569488498,
        QA_MA: 0.276,
        VA_MA: 0.57507,
        QC_MA: 0.276,
        VC_MA: 0.57507,
        QE_MA: 0.276,
        VE_MA: 0.57507,
        QA_01: 0.61,
        VA_01: 0.71472,
        QC_01: 0.61,
        VC_01: 0.71472,
        QE_01: 0.61,
        VE_01: 0.71472,
        QA_02: 0.633,
        VA_02: 0.72279,
        QC_02: 0.633,
        VC_02: 0.72279,
        QE_02: 0.633,
        VE_02: 0.72279,
        QA_03: 0.68,
        VA_03: 0.73885,
        QC_03: 0.68,
        VC_03: 0.73885,
        QE_03: 0.68,
        VE_03: 0.73885,
        QA_04: 0.392,
        VA_04: 0.62969,
        QC_04: 0.392,
        VC_04: 0.62969,
        QE_04: 0.392,
        VE_04: 0.62969,
        QA_05: 0.217,
        VA_05: 0.5431,
        QC_05: 0.217,
        VC_05: 0.5431,
        QE_05: 0.217,
        VE_05: 0.5431,
        QA_06: 0.141,
        VA_06: 0.49507,
        QC_06: 0.141,
        VC_06: 0.49507,
        QE_06: 0.141,
        VE_06: 0.49507,
        QA_07: 0.103,
        VA_07: 0.4664,
        QC_07: 0.103,
        VC_07: 0.4664,
        QE_07: 0.103,
        VE_07: 0.4664,
        QA_08: 0.075,
        VA_08: 0.44191,
        QC_08: 0.075,
        VC_08: 0.44191,
        QE_08: 0.075,
        VE_08: 0.44191,
        QA_09: 0.056,
        VA_09: 0.42272,
        QC_09: 0.056,
        VC_09: 0.42272,
        QE_09: 0.056,
        VE_09: 0.42272,
        QA_10: 0.047,
        VA_10: 0.41256,
        QC_10: 0.047,
        VC_10: 0.41256,
        QE_10: 0.047,
        VE_10: 0.41256,
        QA_11: 0.047,
        VA_11: 0.41256,
        QC_11: 0.047,
        VC_11: 0.41256,
        QE_11: 0.047,
        VE_11: 0.41256,
        QA_12: 0.317,
        VA_12: 0.59543,
        QC_12: 0.317,
        VC_12: 0.59543,
        QE_12: 0.317,
        VE_12: 0.59543,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872297384909984, 34.776670679353117, 0.0],
          [-80.87443845157334, 34.775093612688806, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287683.0,
        COMID: 9731432,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.254,
        REACHCODE: "03050103000796",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0026319402813400001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250058896.0,
        ToNode: 250058908.0,
        Hydroseq: 250027962.0,
        LevelPathI: 250027962.0,
        Pathlength: 432.154,
        TerminalPa: 250002604.0,
        ArbolateSu: 325.83,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250028925.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026291.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0261,
        TotDASqKM: 665.0667,
        DivDASqKM: 0.0261,
        Tidal: 0,
        TOTMA: 0.0218441417422,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14934.0,
        MAXELEVSMO: 15052.0,
        MINELEVSMO: 15004.0,
        SLOPE: 0.00457142,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.104999996721745,
        QA_MA: 0.01,
        VA_MA: 0.44154,
        QC_MA: 0.01,
        VC_MA: 0.44154,
        QE_MA: 0.01,
        VE_MA: 0.44154,
        QA_01: 0.023,
        VA_01: 0.5156,
        QC_01: 0.023,
        VC_01: 0.5156,
        QE_01: 0.023,
        VE_01: 0.5156,
        QA_02: 0.024,
        VA_02: 0.52033,
        QC_02: 0.024,
        VC_02: 0.52033,
        QE_02: 0.024,
        VE_02: 0.52033,
        QA_03: 0.026,
        VA_03: 0.52954,
        QC_03: 0.026,
        VC_03: 0.52954,
        QE_03: 0.026,
        VE_03: 0.52954,
        QA_04: 0.015,
        VA_04: 0.47352,
        QC_04: 0.015,
        VC_04: 0.47352,
        QE_04: 0.015,
        VE_04: 0.47352,
        QA_05: 0.008,
        VA_05: 0.42666,
        QC_05: 0.008,
        VC_05: 0.42666,
        QE_05: 0.008,
        VE_05: 0.42666,
        QA_06: 0.005,
        VA_06: 0.40054,
        QC_06: 0.005,
        VC_06: 0.40054,
        QE_06: 0.005,
        VE_06: 0.40054,
        QA_07: 0.003,
        VA_07: 0.37865,
        QC_07: 0.003,
        VC_07: 0.37865,
        QE_07: 0.003,
        VE_07: 0.37865,
        QA_08: 0.002,
        VA_08: 0.36504,
        QC_08: 0.002,
        VC_08: 0.36504,
        QE_08: 0.002,
        VE_08: 0.36504,
        QA_09: 0.002,
        VA_09: 0.36504,
        QC_09: 0.002,
        VC_09: 0.36504,
        QE_09: 0.002,
        VE_09: 0.36504,
        QA_10: 0.001,
        VA_10: 0.34759,
        QC_10: 0.001,
        VC_10: 0.34759,
        QE_10: 0.001,
        VE_10: 0.34759,
        QA_11: 0.003,
        VA_11: 0.37865,
        QC_11: 0.003,
        VC_11: 0.37865,
        QE_11: 0.003,
        VE_11: 0.37865,
        QA_12: 0.013,
        VA_12: 0.46144,
        QC_12: 0.013,
        VC_12: 0.46144,
        QE_12: 0.013,
        VE_12: 0.46144,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.901577918197859, 35.012885678986436, 0.0],
          [-80.899350384867887, 35.011741012321522, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288725.0,
        COMID: 166740250,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.253,
        REACHCODE: "03050103017686",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0023807079928559998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094154.0,
        ToNode: 250060091.0,
        Hydroseq: 250004668.0,
        LevelPathI: 250004668.0,
        Pathlength: 371.455,
        TerminalPa: 250002604.0,
        ArbolateSu: 7925.239,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004668.0,
        UpHydroseq: 250004685.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0117,
        TotDASqKM: 10619.9712,
        DivDASqKM: 0.0189,
        Tidal: 0,
        TOTMA: 0.06256643045,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8604.0,
        MAXELEVSMO: 8656.0,
        MINELEVSMO: 8626.0,
        SLOPE: 0.00118577,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25299999117851302,
        QA_MA: 0.006,
        VA_MA: -9998.0,
        QC_MA: 0.006,
        VC_MA: -9998.0,
        QE_MA: 0.006,
        VE_MA: -9998.0,
        QA_01: 0.013,
        VA_01: -9998.0,
        QC_01: 0.013,
        VC_01: -9998.0,
        QE_01: 0.013,
        VE_01: -9998.0,
        QA_02: 0.014,
        VA_02: -9998.0,
        QC_02: 0.014,
        VC_02: -9998.0,
        QE_02: 0.014,
        VE_02: -9998.0,
        QA_03: 0.016,
        VA_03: -9998.0,
        QC_03: 0.016,
        VC_03: -9998.0,
        QE_03: 0.016,
        VE_03: -9998.0,
        QA_04: 0.008,
        VA_04: -9998.0,
        QC_04: 0.008,
        VC_04: -9998.0,
        QE_04: 0.008,
        VE_04: -9998.0,
        QA_05: 0.004,
        VA_05: -9998.0,
        QC_05: 0.004,
        VC_05: -9998.0,
        QE_05: 0.004,
        VE_05: -9998.0,
        QA_06: 0.003,
        VA_06: -9998.0,
        QC_06: 0.003,
        VC_06: -9998.0,
        QE_06: 0.003,
        VE_06: -9998.0,
        QA_07: 0.002,
        VA_07: -9998.0,
        QC_07: 0.002,
        VC_07: -9998.0,
        QE_07: 0.002,
        VE_07: -9998.0,
        QA_08: 0.002,
        VA_08: -9998.0,
        QC_08: 0.002,
        VC_08: -9998.0,
        QE_08: 0.002,
        VE_08: -9998.0,
        QA_09: 0.001,
        VA_09: -9998.0,
        QC_09: 0.001,
        VC_09: -9998.0,
        QE_09: 0.001,
        VE_09: -9998.0,
        QA_10: 0.001,
        VA_10: -9998.0,
        QC_10: 0.001,
        VC_10: -9998.0,
        QE_10: 0.001,
        VE_10: -9998.0,
        QA_11: 0.001,
        VA_11: -9998.0,
        QC_11: 0.001,
        VC_11: -9998.0,
        QE_11: 0.001,
        VE_11: -9998.0,
        QA_12: 0.004,
        VA_12: -9998.0,
        QC_12: 0.004,
        VC_12: -9998.0,
        QE_12: 0.004,
        VE_12: -9998.0,
        LakeFract: 1.524e-8,
        SurfArea: 0.18761572289,
        RAreaHLoad: 0.01278081532,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880782184896759, 34.570211813006722, 0.0],
          [-80.879978184898107, 34.568257679676549, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287684.0,
        COMID: 9733160,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.439,
        REACHCODE: "03050103000797",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0041668419512599997,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250058949.0,
        ToNode: 250058972.0,
        Hydroseq: 250024872.0,
        LevelPathI: 250024872.0,
        Pathlength: 427.834,
        TerminalPa: 250002604.0,
        ArbolateSu: 336.646,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250025549.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023121.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1431,
        TotDASqKM: 686.214,
        DivDASqKM: 0.1431,
        Tidal: 0,
        TOTMA: 0.034577190256700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14777.0,
        MAXELEVSMO: 14969.0,
        MINELEVSMO: 14911.0,
        SLOPE: 0.00200692,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.289000004529953,
        QA_MA: 0.059,
        VA_MA: 0.48211,
        QC_MA: 0.059,
        VC_MA: 0.48211,
        QE_MA: 0.059,
        VE_MA: 0.48211,
        QA_01: 0.126,
        VA_01: 0.5683,
        QC_01: 0.126,
        VC_01: 0.5683,
        QE_01: 0.126,
        VE_01: 0.5683,
        QA_02: 0.132,
        VA_02: 0.5748,
        QC_02: 0.132,
        VC_02: 0.5748,
        QE_02: 0.132,
        VE_02: 0.5748,
        QA_03: 0.142,
        VA_03: 0.58533,
        QC_03: 0.142,
        VC_03: 0.58533,
        QE_03: 0.142,
        VE_03: 0.58533,
        QA_04: 0.084,
        VA_04: 0.51795,
        QC_04: 0.084,
        VC_04: 0.51795,
        QE_04: 0.084,
        VE_04: 0.51795,
        QA_05: 0.048,
        VA_05: 0.46408,
        QC_05: 0.048,
        VC_05: 0.46408,
        QE_05: 0.048,
        VE_05: 0.46408,
        QA_06: 0.028,
        VA_06: 0.42533,
        QC_06: 0.028,
        VC_06: 0.42533,
        QE_06: 0.028,
        VE_06: 0.42533,
        QA_07: 0.021,
        VA_07: 0.40876,
        QC_07: 0.021,
        VC_07: 0.40876,
        QE_07: 0.021,
        VE_07: 0.40876,
        QA_08: 0.015,
        VA_08: 0.39234,
        QC_08: 0.015,
        VC_08: 0.39234,
        QE_08: 0.015,
        VE_08: 0.39234,
        QA_09: 0.013,
        VA_09: 0.3862,
        QC_09: 0.013,
        VC_09: 0.3862,
        QE_09: 0.013,
        VE_09: 0.3862,
        QA_10: 0.009,
        VA_10: 0.3724,
        QC_10: 0.009,
        VC_10: 0.3724,
        QE_10: 0.009,
        VE_10: 0.3724,
        QA_11: 0.015,
        VA_11: 0.39234,
        QC_11: 0.015,
        VC_11: 0.39234,
        QE_11: 0.015,
        VE_11: 0.39234,
        QA_12: 0.071,
        VA_12: 0.50005,
        QC_12: 0.071,
        VC_12: 0.50005,
        QE_12: 0.071,
        VE_12: 0.50005,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890910118214379, 34.99097521235376, 0.0],
          [-80.888655118217969, 34.98758541235896, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288726.0,
        COMID: 166740251,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.303,
        REACHCODE: "03050103017687",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0028574646414809999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094155.0,
        ToNode: 250060091.0,
        Hydroseq: 250004736.0,
        LevelPathI: 250004736.0,
        Pathlength: 371.455,
        TerminalPa: 250002604.0,
        ArbolateSu: 7925.326,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004760.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 10619.9676,
        DivDASqKM: 0.0684,
        Tidal: 0,
        TOTMA: 0.07494983634000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8603.0,
        MAXELEVSMO: 8634.0,
        MINELEVSMO: 8626.0,
        SLOPE: 0.00026402,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30300000309944197,
        QA_MA: 0.022,
        VA_MA: -9998.0,
        QC_MA: 0.022,
        VC_MA: -9998.0,
        QE_MA: 0.022,
        VE_MA: -9998.0,
        QA_01: 0.048,
        VA_01: -9998.0,
        QC_01: 0.048,
        VC_01: -9998.0,
        QE_01: 0.048,
        VE_01: -9998.0,
        QA_02: 0.054,
        VA_02: -9998.0,
        QC_02: 0.054,
        VC_02: -9998.0,
        QE_02: 0.054,
        VE_02: -9998.0,
        QA_03: 0.059,
        VA_03: -9998.0,
        QC_03: 0.059,
        VC_03: -9998.0,
        QE_03: 0.059,
        VE_03: -9998.0,
        QA_04: 0.031,
        VA_04: -9998.0,
        QC_04: 0.031,
        VC_04: -9998.0,
        QE_04: 0.031,
        VE_04: -9998.0,
        QA_05: 0.017,
        VA_05: -9998.0,
        QC_05: 0.017,
        VC_05: -9998.0,
        QE_05: 0.017,
        VE_05: -9998.0,
        QA_06: 0.011,
        VA_06: -9998.0,
        QC_06: 0.011,
        VC_06: -9998.0,
        QE_06: 0.011,
        VE_06: -9998.0,
        QA_07: 0.009,
        VA_07: -9998.0,
        QC_07: 0.009,
        VC_07: -9998.0,
        QE_07: 0.009,
        VE_07: -9998.0,
        QA_08: 0.007,
        VA_08: -9998.0,
        QC_08: 0.007,
        VC_08: -9998.0,
        QE_08: 0.007,
        VE_08: -9998.0,
        QA_09: 0.005,
        VA_09: -9998.0,
        QC_09: 0.005,
        VC_09: -9998.0,
        QE_09: 0.005,
        VE_09: -9998.0,
        QA_10: 0.004,
        VA_10: -9998.0,
        QC_10: 0.004,
        VC_10: -9998.0,
        QE_10: 0.004,
        VE_10: -9998.0,
        QA_11: 0.003,
        VA_11: -9998.0,
        QC_11: 0.003,
        VC_11: -9998.0,
        QE_11: 0.003,
        VE_11: -9998.0,
        QA_12: 0.016,
        VA_12: -9998.0,
        QC_12: 0.016,
        VC_12: -9998.0,
        QE_12: 0.016,
        VE_12: -9998.0,
        LakeFract: 6.694e-8,
        SurfArea: 0.82408113450999998,
        RAreaHLoad: 0.01531044699,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881335984895998, 34.570624013006238, 0.0],
          [-80.879978184898107, 34.568257679676549, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288719.0,
        COMID: 166740244,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.055,
        REACHCODE: "03050103017680",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00050809192892799996,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094148.0,
        ToNode: 250060059.0,
        Hydroseq: 250004832.0,
        LevelPathI: 250004832.0,
        Pathlength: 372.994,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.311,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250004832.0,
        UpHydroseq: 250004857.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004822.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 10619.6823,
        DivDASqKM: 0.0063,
        Tidal: 0,
        TOTMA: 0.01354784911,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10989.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054999999701976998,
        QA_MA: 0.002,
        VA_MA: -9998.0,
        QC_MA: 0.002,
        VC_MA: -9998.0,
        QE_MA: 0.002,
        VE_MA: -9998.0,
        QA_01: 0.004,
        VA_01: -9998.0,
        QC_01: 0.004,
        VC_01: -9998.0,
        QE_01: 0.004,
        VE_01: -9998.0,
        QA_02: 0.004,
        VA_02: -9998.0,
        QC_02: 0.004,
        VC_02: -9998.0,
        QE_02: 0.004,
        VE_02: -9998.0,
        QA_03: 0.005,
        VA_03: -9998.0,
        QC_03: 0.005,
        VC_03: -9998.0,
        QE_03: 0.005,
        VE_03: -9998.0,
        QA_04: 0.002,
        VA_04: -9998.0,
        QC_04: 0.002,
        VC_04: -9998.0,
        QE_04: 0.002,
        VE_04: -9998.0,
        QA_05: 0.001,
        VA_05: -9998.0,
        QC_05: 0.001,
        VC_05: -9998.0,
        QE_05: 0.001,
        VE_05: -9998.0,
        QA_06: 0.001,
        VA_06: -9998.0,
        QC_06: 0.001,
        VC_06: -9998.0,
        QE_06: 0.001,
        VE_06: -9998.0,
        QA_07: 0.0,
        VA_07: -9998.0,
        QC_07: 0.0,
        VC_07: -9998.0,
        QE_07: 0.0,
        VE_07: -9998.0,
        QA_08: 0.0,
        VA_08: -9998.0,
        QC_08: 0.0,
        VC_08: -9998.0,
        QE_08: 0.0,
        VE_08: -9998.0,
        QA_09: 0.0,
        VA_09: -9998.0,
        QC_09: 0.0,
        VC_09: -9998.0,
        QE_09: 0.0,
        VE_09: -9998.0,
        QA_10: 0.0,
        VA_10: -9998.0,
        QC_10: 0.0,
        VC_10: -9998.0,
        QE_10: 0.0,
        VE_10: -9998.0,
        QA_11: 0.0,
        VA_11: -9998.0,
        QC_11: 0.0,
        VC_11: -9998.0,
        QE_11: 0.0,
        VE_11: -9998.0,
        QA_12: 0.001,
        VA_12: -9998.0,
        QC_12: 0.001,
        VC_12: -9998.0,
        QE_12: 0.001,
        VE_12: -9998.0,
        LakeFract: 1.1e-9,
        SurfArea: 0.01354181727,
        RAreaHLoad: 0.00276749938,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88543491822287, 34.575892212998042, 0.0],
          [-80.885625651555983, 34.575421279665306, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288720.0,
        COMID: 166740245,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.037,
        REACHCODE: "03050103017681",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00033900235937399999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060089.0,
        ToNode: 250094146.0,
        Hydroseq: 250004945.0,
        LevelPathI: 250003258.0,
        Pathlength: 373.315,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.513,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004969.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250004929.0,
        DnDrainCou: 2,
        DnHydroseq: 250004928.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.018,
        TotDASqKM: 10619.6319,
        DivDASqKM: 10619.6319,
        Tidal: 0,
        TOTMA: 0.00915193422,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10810.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.037000000476836999,
        QA_MA: 5195.0,
        VA_MA: -9998.0,
        QC_MA: 5195.0,
        VC_MA: -9998.0,
        QE_MA: 5897.389,
        VE_MA: -9998.0,
        QA_01: 9655.742,
        VA_01: -9998.0,
        QC_01: 9655.742,
        VC_01: -9998.0,
        QE_01: 8217.969,
        VE_01: -9998.0,
        QA_02: 10198.173,
        VA_02: -9998.0,
        QC_02: 10198.173,
        VC_02: -9998.0,
        QE_02: 8384.549,
        VE_02: -9998.0,
        QA_03: 11175.795,
        VA_03: -9998.0,
        QC_03: 11175.795,
        VC_03: -9998.0,
        QE_03: 8936.359,
        VE_03: -9998.0,
        QA_04: 7598.372,
        VA_04: -9998.0,
        QC_04: 7598.372,
        VC_04: -9998.0,
        QE_04: 7376.234,
        VE_04: -9998.0,
        QA_05: 5120.95,
        VA_05: -9998.0,
        QC_05: 5120.95,
        VC_05: -9998.0,
        QE_05: 6106.597,
        VE_05: -9998.0,
        QA_06: 3037.128,
        VA_06: -9998.0,
        QC_06: 3037.128,
        VC_06: -9998.0,
        QE_06: 5278.415,
        VE_06: -9998.0,
        QA_07: 1906.153,
        VA_07: -9998.0,
        QC_07: 1906.153,
        VC_07: -9998.0,
        QE_07: 3999.871,
        VE_07: -9998.0,
        QA_08: 1361.544,
        VA_08: -9998.0,
        QC_08: 1361.544,
        VC_08: -9998.0,
        QE_08: 3702.291,
        VE_08: -9998.0,
        QA_09: 1231.385,
        VA_09: -9998.0,
        QC_09: 1231.385,
        VC_09: -9998.0,
        QE_09: 3378.134,
        VE_09: -9998.0,
        QA_10: 1300.58,
        VA_10: -9998.0,
        QC_10: 1300.58,
        VC_10: -9998.0,
        QE_10: 4547.77,
        VE_10: -9998.0,
        QA_11: 3253.169,
        VA_11: -9998.0,
        QC_11: 3253.169,
        VC_11: -9998.0,
        QE_11: 4631.684,
        VE_11: -9998.0,
        QA_12: 6536.751,
        VA_12: -9998.0,
        QC_12: 6536.751,
        VC_12: -9998.0,
        QE_12: 6244.338,
        VE_12: -9998.0,
        LakeFract: 0.00219111415,
        SurfArea: 26974.243121899999,
        RAreaHLoad: 0.00186951981,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886826584887444, 34.578460079660772, 0.0],
          [-80.88667218488763, 34.578158279661238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288721.0,
        COMID: 166740246,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.062,
        REACHCODE: "03050103017682",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00055660017078600001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094146.0,
        ToNode: 250094147.0,
        Hydroseq: 250004928.0,
        LevelPathI: 250003258.0,
        Pathlength: 373.253,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.575,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004945.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250004911.0,
        DnDrainCou: 2,
        DnHydroseq: 250004910.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 10619.6337,
        DivDASqKM: 10619.6337,
        Tidal: 0,
        TOTMA: 0.01533567352,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10822.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.061999998986720997,
        QA_MA: 5195.001,
        VA_MA: -9998.0,
        QC_MA: 5195.001,
        VC_MA: -9998.0,
        QE_MA: 5897.39,
        VE_MA: -9998.0,
        QA_01: 9655.744,
        VA_01: -9998.0,
        QC_01: 9655.744,
        VC_01: -9998.0,
        QE_01: 8217.971,
        VE_01: -9998.0,
        QA_02: 10198.174,
        VA_02: -9998.0,
        QC_02: 10198.174,
        VC_02: -9998.0,
        QE_02: 8384.55,
        VE_02: -9998.0,
        QA_03: 11175.796,
        VA_03: -9998.0,
        QC_03: 11175.796,
        VC_03: -9998.0,
        QE_03: 8936.36,
        VE_03: -9998.0,
        QA_04: 7598.373,
        VA_04: -9998.0,
        QC_04: 7598.373,
        VC_04: -9998.0,
        QE_04: 7376.235,
        VE_04: -9998.0,
        QA_05: 5120.95,
        VA_05: -9998.0,
        QC_05: 5120.95,
        VC_05: -9998.0,
        QE_05: 6106.598,
        VE_05: -9998.0,
        QA_06: 3037.128,
        VA_06: -9998.0,
        QC_06: 3037.128,
        VC_06: -9998.0,
        QE_06: 5278.416,
        VE_06: -9998.0,
        QA_07: 1906.154,
        VA_07: -9998.0,
        QC_07: 1906.154,
        VC_07: -9998.0,
        QE_07: 3999.872,
        VE_07: -9998.0,
        QA_08: 1361.544,
        VA_08: -9998.0,
        QC_08: 1361.544,
        VC_08: -9998.0,
        QE_08: 3702.291,
        VE_08: -9998.0,
        QA_09: 1231.385,
        VA_09: -9998.0,
        QC_09: 1231.385,
        VC_09: -9998.0,
        QE_09: 3378.134,
        VE_09: -9998.0,
        QA_10: 1300.58,
        VA_10: -9998.0,
        QC_10: 1300.58,
        VC_10: -9998.0,
        QE_10: 4547.77,
        VE_10: -9998.0,
        QA_11: 3253.169,
        VA_11: -9998.0,
        QC_11: 3253.169,
        VC_11: -9998.0,
        QE_11: 4631.684,
        VE_11: -9998.0,
        QA_12: 6536.752,
        VA_12: -9998.0,
        QC_12: 6536.752,
        VC_12: -9998.0,
        QE_12: 6244.338,
        VE_12: -9998.0,
        LakeFract: 0.0036715973,
        SurfArea: 45200.090655200001,
        RAreaHLoad: 0.00313270886,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88667218488763, 34.578158279661238, 0.0],
          [-80.886551384887866, 34.577614946328765, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288722.0,
        COMID: 166740247,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.259,
        REACHCODE: "03050103017683",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0023809988727689999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094147.0,
        ToNode: 250060059.0,
        Hydroseq: 250004910.0,
        LevelPathI: 250003258.0,
        Pathlength: 372.994,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.834,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004928.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004822.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.072,
        TotDASqKM: 10619.7057,
        DivDASqKM: 10619.7057,
        Tidal: 0,
        TOTMA: 0.06406353939,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10439.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25900000333786,
        QA_MA: 5195.025,
        VA_MA: -9998.0,
        QC_MA: 5195.025,
        VC_MA: -9998.0,
        QE_MA: 5897.413,
        VE_MA: -9998.0,
        QA_01: 9655.795,
        VA_01: -9998.0,
        QC_01: 9655.795,
        VC_01: -9998.0,
        QE_01: 8218.022,
        VE_01: -9998.0,
        QA_02: 10198.232,
        VA_02: -9998.0,
        QC_02: 10198.232,
        VC_02: -9998.0,
        QE_02: 8384.607,
        VE_02: -9998.0,
        QA_03: 11175.859,
        VA_03: -9998.0,
        QC_03: 11175.859,
        VC_03: -9998.0,
        QE_03: 8936.423,
        VE_03: -9998.0,
        QA_04: 7598.406,
        VA_04: -9998.0,
        QC_04: 7598.406,
        VC_04: -9998.0,
        QE_04: 7376.268,
        VE_04: -9998.0,
        QA_05: 5120.968,
        VA_05: -9998.0,
        QC_05: 5120.968,
        VC_05: -9998.0,
        QE_05: 6106.616,
        VE_05: -9998.0,
        QA_06: 3037.141,
        VA_06: -9998.0,
        QC_06: 3037.141,
        VC_06: -9998.0,
        QE_06: 5278.428,
        VE_06: -9998.0,
        QA_07: 1906.163,
        VA_07: -9998.0,
        QC_07: 1906.163,
        VC_07: -9998.0,
        QE_07: 3999.881,
        VE_07: -9998.0,
        QA_08: 1361.552,
        VA_08: -9998.0,
        QC_08: 1361.552,
        VC_08: -9998.0,
        QE_08: 3702.299,
        VE_08: -9998.0,
        QA_09: 1231.39,
        VA_09: -9998.0,
        QC_09: 1231.39,
        VC_09: -9998.0,
        QE_09: 3378.14,
        VE_09: -9998.0,
        QA_10: 1300.585,
        VA_10: -9998.0,
        QC_10: 1300.585,
        VC_10: -9998.0,
        QE_10: 4547.775,
        VE_10: -9998.0,
        QA_11: 3253.173,
        VA_11: -9998.0,
        QC_11: 3253.173,
        VC_11: -9998.0,
        QE_11: 4631.688,
        VE_11: -9998.0,
        QA_12: 6536.769,
        VA_12: -9998.0,
        QC_12: 6536.769,
        VC_12: -9998.0,
        QE_12: 6244.356,
        VE_12: -9998.0,
        LakeFract: 0.01533786144,
        SurfArea: 188820.46992,
        RAreaHLoad: 0.01308663863,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886551384887866, 34.577614946328765, 0.0],
          [-80.885625651555983, 34.575421279665306, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288644.0,
        COMID: 9757270,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.129,
        REACHCODE: "03050103017605",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0012294822067000001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059736.0,
        ToNode: 250062142.0,
        Hydroseq: 250004493.0,
        LevelPathI: 250004493.0,
        Pathlength: 372.709,
        TerminalPa: 250002604.0,
        ArbolateSu: 7670.729,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004505.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004482.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9975.6135,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.015884030386399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9254.0,
        MINELEVSMO: 9109.0,
        SLOPE: 0.01124031,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.12899999320507,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878677584900117, 34.579397479659292, 0.0],
          [-80.878068518234386, 34.578458412993996, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288619.0,
        COMID: 9757220,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.19,
        REACHCODE: "03050103017580",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00170727702084,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062114.0,
        ToNode: 250062115.0,
        Hydroseq: 250004625.0,
        LevelPathI: 250004607.0,
        Pathlength: 373.763,
        TerminalPa: 250002604.0,
        ArbolateSu: 7558.576,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004636.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004615.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 9868.0158,
        DivDASqKM: 0.6741,
        Tidal: 0,
        TOTMA: 0.0138586440543,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9619.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9732.0,
        SLOPE: 0.0008421,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18999999761581399,
        QA_MA: 0.223,
        VA_MA: 0.5206,
        QC_MA: 0.223,
        VC_MA: 0.5206,
        QE_MA: 0.223,
        VE_MA: 0.5206,
        QA_01: 0.48,
        VA_01: 0.62722,
        QC_01: 0.48,
        VC_01: 0.62722,
        QE_01: 0.48,
        VE_01: 0.62722,
        QA_02: 0.534,
        VA_02: 0.64579,
        QC_02: 0.534,
        VC_02: 0.64579,
        QE_02: 0.534,
        VE_02: 0.64579,
        QA_03: 0.598,
        VA_03: 0.66669,
        QC_03: 0.598,
        VC_03: 0.66669,
        QE_03: 0.598,
        VE_03: 0.66669,
        QA_04: 0.308,
        VA_04: 0.5603,
        QC_04: 0.308,
        VC_04: 0.5603,
        QE_04: 0.308,
        VE_04: 0.5603,
        QA_05: 0.172,
        VA_05: 0.49327,
        QC_05: 0.172,
        VC_05: 0.49327,
        QE_05: 0.172,
        VE_05: 0.49327,
        QA_06: 0.117,
        VA_06: 0.45906,
        QC_06: 0.117,
        VC_06: 0.45906,
        QE_06: 0.117,
        VE_06: 0.45906,
        QA_07: 0.09,
        VA_07: 0.43947,
        QC_07: 0.09,
        VC_07: 0.43947,
        QE_07: 0.09,
        VE_07: 0.43947,
        QA_08: 0.072,
        VA_08: 0.42482,
        QC_08: 0.072,
        VC_08: 0.42482,
        QE_08: 0.072,
        VE_08: 0.42482,
        QA_09: 0.054,
        VA_09: 0.40833,
        QC_09: 0.054,
        VC_09: 0.40833,
        QE_09: 0.054,
        VE_09: 0.40833,
        QA_10: 0.045,
        VA_10: 0.3991,
        QC_10: 0.045,
        VC_10: 0.3991,
        QE_10: 0.045,
        VE_10: 0.3991,
        QA_11: 0.036,
        VA_11: 0.38897,
        QC_11: 0.036,
        VC_11: 0.38897,
        QE_11: 0.036,
        VE_11: 0.38897,
        QA_12: 0.172,
        VA_12: 0.49327,
        QC_12: 0.172,
        VC_12: 0.49327,
        QE_12: 0.172,
        VE_12: 0.49327,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882666184893935, 34.586796012981097, 0.0],
          [-80.882333784894399, 34.585123812983625, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288620.0,
        COMID: 9757222,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.239,
        REACHCODE: "03050103017581",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0023787681571860002,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062115.0,
        ToNode: 250059724.0,
        Hydroseq: 250004615.0,
        LevelPathI: 250004607.0,
        Pathlength: 373.524,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.478,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004625.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004606.0,
        DnDrainCou: 2,
        DnHydroseq: 250004607.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0234,
        TotDASqKM: 9868.1256,
        DivDASqKM: 0.7263,
        Tidal: 0,
        TOTMA: 0.016440178031300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9599.0,
        MAXELEVSMO: 9732.0,
        MINELEVSMO: 9689.0,
        SLOPE: 0.00179916,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.23899999260902399,
        QA_MA: 0.241,
        VA_MA: 0.55203,
        QC_MA: 0.241,
        VC_MA: 0.55203,
        QE_MA: 0.241,
        VE_MA: 0.55203,
        QA_01: 0.517,
        VA_01: 0.67378,
        QC_01: 0.517,
        VC_01: 0.67378,
        QE_01: 0.517,
        VE_01: 0.67378,
        QA_02: 0.576,
        VA_02: 0.69536,
        QC_02: 0.576,
        VC_02: 0.69536,
        QE_02: 0.576,
        VE_02: 0.69536,
        QA_03: 0.644,
        VA_03: 0.71898,
        QC_03: 0.644,
        VC_03: 0.71898,
        QE_03: 0.644,
        VE_03: 0.71898,
        QA_04: 0.332,
        VA_04: 0.5972,
        QC_04: 0.332,
        VC_04: 0.5972,
        QE_04: 0.332,
        VE_04: 0.5972,
        QA_05: 0.185,
        VA_05: 0.52011,
        QC_05: 0.185,
        VC_05: 0.52011,
        QE_05: 0.185,
        VE_05: 0.52011,
        QA_06: 0.126,
        VA_06: 0.48105,
        QC_06: 0.126,
        VC_06: 0.48105,
        QE_06: 0.126,
        VE_06: 0.48105,
        QA_07: 0.097,
        VA_07: 0.45866,
        QC_07: 0.097,
        VC_07: 0.45866,
        QE_07: 0.097,
        VE_07: 0.45866,
        QA_08: 0.078,
        VA_08: 0.44223,
        QC_08: 0.078,
        VC_08: 0.44223,
        QE_08: 0.078,
        VE_08: 0.44223,
        QA_09: 0.058,
        VA_09: 0.42275,
        QC_09: 0.058,
        VC_09: 0.42275,
        QE_09: 0.058,
        VE_09: 0.42275,
        QA_10: 0.048,
        VA_10: 0.41182,
        QC_10: 0.048,
        VC_10: 0.41182,
        QE_10: 0.048,
        VE_10: 0.41182,
        QA_11: 0.039,
        VA_11: 0.40102,
        QC_11: 0.039,
        VC_11: 0.40102,
        QE_11: 0.039,
        VE_11: 0.40102,
        QA_12: 0.185,
        VA_12: 0.52011,
        QC_12: 0.185,
        VC_12: 0.52011,
        QE_12: 0.185,
        VE_12: 0.52011,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882333784894399, 34.585123812983625, 0.0],
          [-80.880644184896994, 34.583703679652501, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288621.0,
        COMID: 9757224,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.393,
        REACHCODE: "03050103017582",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0036285957774049998,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062116.0,
        ToNode: 250059730.0,
        Hydroseq: 250004624.0,
        LevelPathI: 250004624.0,
        Pathlength: 373.454,
        TerminalPa: 250002604.0,
        ArbolateSu: 7558.825,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004624.0,
        UpHydroseq: 250004728.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004616.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0387,
        TotDASqKM: 9868.0599,
        DivDASqKM: 0.0441,
        Tidal: 0,
        TOTMA: 0.040969843599899997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9603.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.39300000667571999,
        QA_MA: 0.014,
        VA_MA: 0.36425,
        QC_MA: 0.014,
        VC_MA: 0.36425,
        QE_MA: 0.014,
        VE_MA: 0.36425,
        QA_01: 0.031,
        VA_01: 0.39359,
        QC_01: 0.031,
        VC_01: 0.39359,
        QE_01: 0.031,
        VE_01: 0.39359,
        QA_02: 0.034,
        VA_02: 0.39787,
        QC_02: 0.034,
        VC_02: 0.39787,
        QE_02: 0.034,
        VE_02: 0.39787,
        QA_03: 0.038,
        VA_03: 0.40331,
        QC_03: 0.038,
        VC_03: 0.40331,
        QE_03: 0.038,
        VE_03: 0.40331,
        QA_04: 0.02,
        VA_04: 0.3759,
        QC_04: 0.02,
        VC_04: 0.3759,
        QE_04: 0.02,
        VE_04: 0.3759,
        QA_05: 0.011,
        VA_05: 0.35754,
        QC_05: 0.011,
        VC_05: 0.35754,
        QE_05: 0.011,
        VE_05: 0.35754,
        QA_06: 0.007,
        VA_06: 0.34705,
        QC_06: 0.007,
        VC_06: 0.34705,
        QE_06: 0.007,
        VE_06: 0.34705,
        QA_07: 0.005,
        VA_07: 0.34072,
        QC_07: 0.005,
        VC_07: 0.34072,
        QE_07: 0.005,
        VE_07: 0.34072,
        QA_08: 0.004,
        VA_08: 0.33711,
        QC_08: 0.004,
        VC_08: 0.33711,
        QE_08: 0.004,
        VE_08: 0.33711,
        QA_09: 0.003,
        VA_09: 0.33304,
        QC_09: 0.003,
        VC_09: 0.33304,
        QE_09: 0.003,
        VE_09: 0.33304,
        QA_10: 0.002,
        VA_10: 0.32827,
        QC_10: 0.002,
        VC_10: 0.32827,
        QE_10: 0.002,
        VE_10: 0.32827,
        QA_11: 0.002,
        VA_11: 0.32827,
        QC_11: 0.002,
        VC_11: 0.32827,
        QE_11: 0.002,
        VE_11: 0.32827,
        QA_12: 0.011,
        VA_12: 0.35754,
        QC_12: 0.011,
        VC_12: 0.35754,
        QE_12: 0.011,
        VE_12: 0.35754,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88330338489294, 34.586704212981317, 0.0],
          [-80.882859784893654, 34.583474479652921, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288615.0,
        COMID: 9757212,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.033,
        REACHCODE: "03050103017576",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00035178959561699998,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062109.0,
        ToNode: 250062116.0,
        Hydroseq: 250004635.0,
        LevelPathI: 250004635.0,
        Pathlength: 373.847,
        TerminalPa: 250002604.0,
        ArbolateSu: 7558.088,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004646.0,
        DnLevelPat: 250004624.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004624.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.9951,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0040633568024900004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9703.0,
        SLOPE: 0.01363636,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.032999999821186003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882970784893473, 34.586818812981051, 0.0],
          [-80.88330338489294, 34.586704212981317, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288616.0,
        COMID: 9757214,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.199,
        REACHCODE: "03050103017577",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0019492567660220001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059725.0,
        ToNode: 250062116.0,
        Hydroseq: 250004728.0,
        LevelPathI: 250004624.0,
        Pathlength: 373.847,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.514,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004746.0,
        DnLevelPat: 250004624.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004624.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 9867.5343,
        DivDASqKM: 0.0054,
        Tidal: 0,
        TOTMA: 0.017311716811100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9703.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9703.0,
        SLOPE: 0.05061224,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.048999998718499999,
        QA_MA: 0.001,
        VA_MA: 0.4365,
        QC_MA: 0.001,
        VC_MA: 0.4365,
        QE_MA: 0.001,
        VE_MA: 0.4365,
        QA_01: 0.003,
        VA_01: 0.53797,
        QC_01: 0.003,
        VC_01: 0.53797,
        QE_01: 0.003,
        VE_01: 0.53797,
        QA_02: 0.004,
        VA_02: 0.57586,
        QC_02: 0.004,
        VC_02: 0.57586,
        QE_02: 0.004,
        VE_02: 0.57586,
        QA_03: 0.004,
        VA_03: 0.57586,
        QC_03: 0.004,
        VC_03: 0.57586,
        QE_03: 0.004,
        VE_03: 0.57586,
        QA_04: 0.002,
        VA_04: 0.4935,
        QC_04: 0.002,
        VC_04: 0.4935,
        QE_04: 0.002,
        VE_04: 0.4935,
        QA_05: 0.001,
        VA_05: 0.4365,
        QC_05: 0.001,
        VC_05: 0.4365,
        QE_05: 0.001,
        VE_05: 0.4365,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.001,
        VA_12: 0.4365,
        QC_12: 0.001,
        VC_12: 0.4365,
        QE_12: 0.001,
        VE_12: 0.4365,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884604984890927, 34.587918212979275, 0.0],
          [-80.88330338489294, 34.586704212981317, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288617.0,
        COMID: 9757216,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.309,
        REACHCODE: "03050103017578",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0028594071159310001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062112.0,
        ToNode: 250059722.0,
        Hydroseq: 250004659.0,
        LevelPathI: 250004659.0,
        Pathlength: 373.707,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.674,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004659.0,
        UpHydroseq: 250004674.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004647.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0531,
        TotDASqKM: 9868.0311,
        DivDASqKM: 0.1107,
        Tidal: 0,
        TOTMA: 0.0310789828097,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9810.0,
        MAXELEVSMO: 9810.0,
        MINELEVSMO: 9810.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30899998545646701,
        QA_MA: 0.036,
        VA_MA: 0.37754,
        QC_MA: 0.036,
        VC_MA: 0.37754,
        QE_MA: 0.036,
        VE_MA: 0.37754,
        QA_01: 0.078,
        VA_01: 0.41264,
        QC_01: 0.078,
        VC_01: 0.41264,
        QE_01: 0.078,
        VE_01: 0.41264,
        QA_02: 0.087,
        VA_02: 0.41887,
        QC_02: 0.087,
        VC_02: 0.41887,
        QE_02: 0.087,
        VE_02: 0.41887,
        QA_03: 0.098,
        VA_03: 0.42608,
        QC_03: 0.098,
        VC_03: 0.42608,
        QE_03: 0.098,
        VE_03: 0.42608,
        QA_04: 0.05,
        VA_04: 0.39072,
        QC_04: 0.05,
        VC_04: 0.39072,
        QE_04: 0.05,
        VE_04: 0.39072,
        QA_05: 0.028,
        VA_05: 0.3689,
        QC_05: 0.028,
        VC_05: 0.3689,
        QE_05: 0.028,
        VE_05: 0.3689,
        QA_06: 0.019,
        VA_06: 0.35764,
        QC_06: 0.019,
        VC_06: 0.35764,
        QE_06: 0.019,
        VE_06: 0.35764,
        QA_07: 0.014,
        VA_07: 0.35027,
        QC_07: 0.014,
        VC_07: 0.35027,
        QE_07: 0.014,
        VE_07: 0.35027,
        QA_08: 0.011,
        VA_08: 0.34523,
        QC_08: 0.011,
        VC_08: 0.34523,
        QE_08: 0.011,
        VE_08: 0.34523,
        QA_09: 0.008,
        VA_09: 0.3395,
        QC_09: 0.008,
        VC_09: 0.3395,
        QE_09: 0.008,
        VE_09: 0.3395,
        QA_10: 0.007,
        VA_10: 0.33737,
        QC_10: 0.007,
        VC_10: 0.33737,
        QE_10: 0.007,
        VE_10: 0.33737,
        QA_11: 0.005,
        VA_11: 0.33263,
        QC_11: 0.005,
        VC_11: 0.33263,
        QE_11: 0.005,
        VE_11: 0.33263,
        QA_12: 0.028,
        VA_12: 0.3689,
        QC_12: 0.028,
        VC_12: 0.3689,
        QE_12: 0.028,
        VE_12: 0.3689,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880976784896518, 34.588422412978559, 0.0],
          [-80.880284184897505, 34.585925679649108, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288618.0,
        COMID: 9757218,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.422,
        REACHCODE: "03050103017579",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0039438765663210003,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062112.0,
        ToNode: 250062115.0,
        Hydroseq: 250004658.0,
        LevelPathI: 250004658.0,
        Pathlength: 373.763,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.787,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004659.0,
        UpHydroseq: 250004674.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004615.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0288,
        TotDASqKM: 9868.0068,
        DivDASqKM: 0.0288,
        Tidal: 0,
        TOTMA: 0.040401565197100003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9732.0,
        MAXELEVSMO: 9741.0,
        MINELEVSMO: 9732.0,
        SLOPE: 0.00033088,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.27200001478195202,
        QA_MA: 0.009,
        VA_MA: 0.39663,
        QC_MA: 0.009,
        VC_MA: 0.39663,
        QE_MA: 0.009,
        VE_MA: 0.39663,
        QA_01: 0.02,
        VA_01: 0.44323,
        QC_01: 0.02,
        VC_01: 0.44323,
        QE_01: 0.02,
        VE_01: 0.44323,
        QA_02: 0.022,
        VA_02: 0.45023,
        QC_02: 0.022,
        VC_02: 0.45023,
        QE_02: 0.022,
        VE_02: 0.45023,
        QA_03: 0.025,
        VA_03: 0.46019,
        QC_03: 0.025,
        VC_03: 0.46019,
        QE_03: 0.025,
        VE_03: 0.46019,
        QA_04: 0.013,
        VA_04: 0.41566,
        QC_04: 0.013,
        VC_04: 0.41566,
        QE_04: 0.013,
        VE_04: 0.41566,
        QA_05: 0.007,
        VA_05: 0.38561,
        QC_05: 0.007,
        VC_05: 0.38561,
        QE_05: 0.007,
        VE_05: 0.38561,
        QA_06: 0.005,
        VA_06: 0.37297,
        QC_06: 0.005,
        VC_06: 0.37297,
        QE_06: 0.005,
        VE_06: 0.37297,
        QA_07: 0.003,
        VA_07: 0.35763,
        QC_07: 0.003,
        VC_07: 0.35763,
        QE_07: 0.003,
        VE_07: 0.35763,
        QA_08: 0.003,
        VA_08: 0.35763,
        QC_08: 0.003,
        VC_08: 0.35763,
        QE_08: 0.003,
        VE_08: 0.35763,
        QA_09: 0.002,
        VA_09: 0.34809,
        QC_09: 0.002,
        VC_09: 0.34809,
        QE_09: 0.002,
        VE_09: 0.34809,
        QA_10: 0.001,
        VA_10: 0.33587,
        QC_10: 0.001,
        VC_10: 0.33587,
        QE_10: 0.001,
        VE_10: 0.33587,
        QA_11: 0.001,
        VA_11: 0.33587,
        QC_11: 0.001,
        VC_11: 0.33587,
        QE_11: 0.001,
        VE_11: 0.33587,
        QA_12: 0.007,
        VA_12: 0.38561,
        QC_12: 0.007,
        VC_12: 0.38561,
        QE_12: 0.007,
        VE_12: 0.38561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880976784896518, 34.588422412978559, 0.0],
          [-80.882333784894399, 34.585123812983625, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288626.0,
        COMID: 9757234,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.158,
        REACHCODE: "03050103017587",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015151988092690001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062121.0,
        ToNode: 250059732.0,
        Hydroseq: 250004593.0,
        LevelPathI: 250004457.0,
        Pathlength: 373.079,
        TerminalPa: 250002604.0,
        ArbolateSu: 7561.312,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004605.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004582.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0126,
        TotDASqKM: 9868.4748,
        DivDASqKM: 0.6156,
        Tidal: 0,
        TOTMA: 0.0145952867769,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9504.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158000007271767,
        QA_MA: 0.203,
        VA_MA: 0.41107,
        QC_MA: 0.203,
        VC_MA: 0.41107,
        QE_MA: 0.203,
        VE_MA: 0.41107,
        QA_01: 0.438,
        VA_01: 0.46285,
        QC_01: 0.438,
        VC_01: 0.46285,
        QE_01: 0.438,
        VE_01: 0.46285,
        QA_02: 0.488,
        VA_02: 0.47197,
        QC_02: 0.488,
        VC_02: 0.47197,
        QE_02: 0.488,
        VE_02: 0.47197,
        QA_03: 0.543,
        VA_03: 0.48152,
        QC_03: 0.543,
        VC_03: 0.48152,
        QE_03: 0.543,
        VE_03: 0.48152,
        QA_04: 0.281,
        VA_04: 0.43042,
        QC_04: 0.281,
        VC_04: 0.43042,
        QE_04: 0.281,
        VE_04: 0.43042,
        QA_05: 0.157,
        VA_05: 0.39797,
        QC_05: 0.157,
        VC_05: 0.39797,
        QE_05: 0.157,
        VE_05: 0.39797,
        QA_06: 0.107,
        VA_06: 0.38147,
        QC_06: 0.107,
        VC_06: 0.38147,
        QE_06: 0.107,
        VE_06: 0.38147,
        QA_07: 0.082,
        VA_07: 0.37184,
        QC_07: 0.082,
        VC_07: 0.37184,
        QE_07: 0.082,
        VE_07: 0.37184,
        QA_08: 0.066,
        VA_08: 0.36493,
        QC_08: 0.066,
        VC_08: 0.36493,
        QE_08: 0.066,
        VE_08: 0.36493,
        QA_09: 0.049,
        VA_09: 0.35666,
        QC_09: 0.049,
        VC_09: 0.35666,
        QE_09: 0.049,
        VE_09: 0.35666,
        QA_10: 0.041,
        VA_10: 0.3523,
        QC_10: 0.041,
        VC_10: 0.3523,
        QE_10: 0.041,
        VE_10: 0.3523,
        QA_11: 0.033,
        VA_11: 0.34752,
        QC_11: 0.033,
        VC_11: 0.34752,
        QE_11: 0.033,
        VE_11: 0.34752,
        QA_12: 0.157,
        VA_12: 0.39797,
        QC_12: 0.157,
        VC_12: 0.39797,
        QE_12: 0.157,
        VE_12: 0.39797,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880782718230137, 34.583314279653173, 0.0],
          [-80.880117918231178, 34.582169012988345, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287691.0,
        COMID: 9736270,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.111,
        REACHCODE: "03050103000801",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0011236279814309999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059896.0,
        ToNode: 250059900.0,
        Hydroseq: 250006272.0,
        LevelPathI: 250006272.0,
        Pathlength: 420.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 6390.578,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250006272.0,
        UpHydroseq: 250006290.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006254.0,
        FromMeas: 0.0,
        ToMeas: 16.27777,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4311,
        TotDASqKM: 8695.2357,
        DivDASqKM: 0.6228,
        Tidal: 0,
        TOTMA: 0.0102035103969,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14367.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.111000001430511,
        QA_MA: 0.253,
        VA_MA: 0.41309,
        QC_MA: 0.253,
        VC_MA: 0.41309,
        QE_MA: 0.253,
        VE_MA: 0.41309,
        QA_01: 0.552,
        VA_01: 0.46684,
        QC_01: 0.552,
        VC_01: 0.46684,
        QE_01: 0.552,
        VE_01: 0.46684,
        QA_02: 0.571,
        VA_02: 0.46971,
        QC_02: 0.571,
        VC_02: 0.46971,
        QE_02: 0.571,
        VE_02: 0.46971,
        QA_03: 0.611,
        VA_03: 0.47561,
        QC_03: 0.611,
        VC_03: 0.47561,
        QE_03: 0.611,
        VE_03: 0.47561,
        QA_04: 0.362,
        VA_04: 0.43503,
        QC_04: 0.362,
        VC_04: 0.43503,
        QE_04: 0.362,
        VE_04: 0.43503,
        QA_05: 0.2,
        VA_05: 0.40081,
        QC_05: 0.2,
        VC_05: 0.40081,
        QE_05: 0.2,
        VE_05: 0.40081,
        QA_06: 0.125,
        VA_06: 0.3804,
        QC_06: 0.125,
        VC_06: 0.3804,
        QE_06: 0.125,
        VE_06: 0.3804,
        QA_07: 0.092,
        VA_07: 0.36958,
        QC_07: 0.092,
        VC_07: 0.36958,
        QE_07: 0.092,
        VE_07: 0.36958,
        QA_08: 0.066,
        VA_08: 0.35969,
        QC_08: 0.066,
        VC_08: 0.35969,
        QE_08: 0.066,
        VE_08: 0.35969,
        QA_09: 0.058,
        VA_09: 0.35629,
        QC_09: 0.058,
        VC_09: 0.35629,
        QE_09: 0.058,
        VE_09: 0.35629,
        QA_10: 0.041,
        VA_10: 0.34823,
        QC_10: 0.041,
        VC_10: 0.34823,
        QE_10: 0.041,
        VE_10: 0.34823,
        QA_11: 0.058,
        VA_11: 0.35629,
        QC_11: 0.058,
        VC_11: 0.35629,
        QE_11: 0.058,
        VE_11: 0.35629,
        QA_12: 0.303,
        VA_12: 0.42362,
        QC_12: 0.303,
        VC_12: 0.42362,
        QE_12: 0.303,
        VE_12: 0.42362,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.867090584917946, 34.941088212431112, 0.0],
          [-80.867990518250053, 34.940415412432287, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287692.0,
        COMID: 9736252,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.225,
        REACHCODE: "03050103000801",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0021698615097340002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059888.0,
        ToNode: 250059050.0,
        Hydroseq: 250006312.0,
        LevelPathI: 250006272.0,
        Pathlength: 420.67,
        TerminalPa: 250002604.0,
        ArbolateSu: 6390.118,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006334.0,
        DnLevelPat: 250006272.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006290.0,
        FromMeas: 67.09173,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 8694.6264,
        DivDASqKM: 0.0135,
        Tidal: 0,
        TOTMA: 0.0196677192982,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14354.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14354.0,
        SLOPE: 0.00853333,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.075000002980232003,
        QA_MA: 0.005,
        VA_MA: 0.43441,
        QC_MA: 0.005,
        VC_MA: 0.43441,
        QE_MA: 0.005,
        VE_MA: 0.43441,
        QA_01: 0.011,
        VA_01: 0.49994,
        QC_01: 0.011,
        VC_01: 0.49994,
        QE_01: 0.011,
        VE_01: 0.49994,
        QA_02: 0.012,
        VA_02: 0.509,
        QC_02: 0.012,
        VC_02: 0.509,
        QE_02: 0.012,
        VE_02: 0.509,
        QA_03: 0.013,
        VA_03: 0.51771,
        QC_03: 0.013,
        VC_03: 0.51771,
        QE_03: 0.013,
        VE_03: 0.51771,
        QA_04: 0.007,
        VA_04: 0.45907,
        QC_04: 0.007,
        VC_04: 0.45907,
        QE_04: 0.007,
        VE_04: 0.45907,
        QA_05: 0.004,
        VA_05: 0.42033,
        QC_05: 0.004,
        VC_05: 0.42033,
        QE_05: 0.004,
        VE_05: 0.42033,
        QA_06: 0.002,
        VA_06: 0.38586,
        QC_06: 0.002,
        VC_06: 0.38586,
        QE_06: 0.002,
        VE_06: 0.38586,
        QA_07: 0.001,
        VA_07: 0.36201,
        QC_07: 0.001,
        VC_07: 0.36201,
        QE_07: 0.001,
        VE_07: 0.36201,
        QA_08: 0.001,
        VA_08: 0.36201,
        QC_08: 0.001,
        VC_08: 0.36201,
        QE_08: 0.001,
        VE_08: 0.36201,
        QA_09: 0.001,
        VA_09: 0.36201,
        QC_09: 0.001,
        VC_09: 0.36201,
        QE_09: 0.001,
        VE_09: 0.36201,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.001,
        VA_11: 0.36201,
        QC_11: 0.001,
        VC_11: 0.36201,
        QE_11: 0.001,
        VE_11: 0.36201,
        QA_12: 0.006,
        VA_12: 0.44723,
        QC_12: 0.006,
        VC_12: 0.44723,
        QE_12: 0.006,
        VE_12: 0.44723,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.869395184914538, 34.942634879095522, 0.0],
          [-80.868091718249843, 34.943997212426666, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288663.0,
        COMID: 9757308,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.044,
        REACHCODE: "03050103017624",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00046373280076600002,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062158.0,
        ToNode: 250059746.0,
        Hydroseq: 250004842.0,
        LevelPathI: 250004842.0,
        Pathlength: 372.388,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.531,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004842.0,
        UpHydroseq: 250004856.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004823.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0009,
        TotDASqKM: 10619.7147,
        DivDASqKM: 0.0198,
        Tidal: 0,
        TOTMA: 0.0036333546571600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9163.0,
        MAXELEVSMO: 9239.0,
        MINELEVSMO: 9163.0,
        SLOPE: 0.01727272,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.043999999761580998,
        QA_MA: 0.006,
        VA_MA: 0.45985,
        QC_MA: 0.006,
        VC_MA: 0.45985,
        QE_MA: 0.006,
        VE_MA: 0.45985,
        QA_01: 0.014,
        VA_01: 0.54591,
        QC_01: 0.014,
        VC_01: 0.54591,
        QE_01: 0.014,
        VE_01: 0.54591,
        QA_02: 0.015,
        VA_02: 0.55477,
        QC_02: 0.015,
        VC_02: 0.55477,
        QE_02: 0.015,
        VE_02: 0.55477,
        QA_03: 0.017,
        VA_03: 0.5717,
        QC_03: 0.017,
        VC_03: 0.5717,
        QE_03: 0.017,
        VE_03: 0.5717,
        QA_04: 0.009,
        VA_04: 0.49624,
        QC_04: 0.009,
        VC_04: 0.49624,
        QE_04: 0.009,
        VE_04: 0.49624,
        QA_05: 0.005,
        VA_05: 0.44588,
        QC_05: 0.005,
        VC_05: 0.44588,
        QE_05: 0.005,
        VE_05: 0.44588,
        QA_06: 0.003,
        VA_06: 0.41321,
        QC_06: 0.003,
        VC_06: 0.41321,
        QE_06: 0.003,
        VE_06: 0.41321,
        QA_07: 0.002,
        VA_07: 0.39291,
        QC_07: 0.002,
        VC_07: 0.39291,
        QE_07: 0.002,
        VE_07: 0.39291,
        QA_08: 0.002,
        VA_08: 0.39291,
        QC_08: 0.002,
        VC_08: 0.39291,
        QE_08: 0.002,
        VE_08: 0.39291,
        QA_09: 0.001,
        VA_09: 0.36688,
        QC_09: 0.001,
        VC_09: 0.36688,
        QE_09: 0.001,
        VE_09: 0.36688,
        QA_10: 0.001,
        VA_10: 0.36688,
        QC_10: 0.001,
        VC_10: 0.36688,
        QE_10: 0.001,
        VE_10: 0.36688,
        QA_11: 0.001,
        VA_11: 0.36688,
        QC_11: 0.001,
        VC_11: 0.36688,
        QE_11: 0.001,
        VE_11: 0.36688,
        QA_12: 0.004,
        VA_12: 0.43051,
        QC_12: 0.004,
        VC_12: 0.43051,
        QE_12: 0.004,
        VE_12: 0.43051,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884188584891547, 34.575571612998488, 0.0],
          [-80.883773184892107, 34.575365479665493, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288665.0,
        COMID: 9757312,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.039,
        REACHCODE: "03050103017626",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00037110935736,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062158.0,
        ToNode: 250062170.0,
        Hydroseq: 250004841.0,
        LevelPathI: 250004841.0,
        Pathlength: 372.375,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.526,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004842.0,
        UpHydroseq: 250004856.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004819.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.7138,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0048021490734999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.039000000804663003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884188584891547, 34.575571612998488, 0.0],
          [-80.88441011822448, 34.575273879665588, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287689.0,
        COMID: 9733284,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.163,
        REACHCODE: "03050103000800",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0017953058814690001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250058997.0,
        ToNode: 250059007.0,
        Hydroseq: 250021714.0,
        LevelPathI: 250021714.0,
        Pathlength: 424.319,
        TerminalPa: 250002604.0,
        ArbolateSu: 353.209,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250022153.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021312.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 702.2484,
        DivDASqKM: 0.0081,
        Tidal: 0,
        TOTMA: 0.014140750223300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14581.0,
        MAXELEVSMO: 14629.0,
        MINELEVSMO: 14587.0,
        SLOPE: 0.021,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.019999999552965001,
        QA_MA: 0.003,
        VA_MA: 0.43771,
        QC_MA: 0.003,
        VC_MA: 0.43771,
        QE_MA: 0.003,
        VE_MA: 0.43771,
        QA_01: 0.007,
        VA_01: 0.51119,
        QC_01: 0.007,
        VC_01: 0.51119,
        QE_01: 0.007,
        VE_01: 0.51119,
        QA_02: 0.007,
        VA_02: 0.51119,
        QC_02: 0.007,
        VC_02: 0.51119,
        QE_02: 0.007,
        VE_02: 0.51119,
        QA_03: 0.008,
        VA_03: 0.52609,
        QC_03: 0.008,
        VC_03: 0.52609,
        QE_03: 0.008,
        VE_03: 0.52609,
        QA_04: 0.004,
        VA_04: 0.45906,
        QC_04: 0.004,
        VC_04: 0.45906,
        QE_04: 0.004,
        VE_04: 0.45906,
        QA_05: 0.002,
        VA_05: 0.41266,
        QC_05: 0.002,
        VC_05: 0.41266,
        QE_05: 0.002,
        VE_05: 0.41266,
        QA_06: 0.001,
        VA_06: 0.38055,
        QC_06: 0.001,
        VC_06: 0.38055,
        QE_06: 0.001,
        VE_06: 0.38055,
        QA_07: 0.001,
        VA_07: 0.38055,
        QC_07: 0.001,
        VC_07: 0.38055,
        QE_07: 0.001,
        VE_07: 0.38055,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.004,
        VA_12: 0.45906,
        QC_12: 0.004,
        VC_12: 0.45906,
        QE_12: 0.004,
        VE_12: 0.45906,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882530384894153, 34.965184479060383, 0.0],
          [-80.880749718230163, 34.964955679060836, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288675.0,
        COMID: 9757332,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.236,
        REACHCODE: "03050103017636",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0022224405478699998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062170.0,
        ToNode: 250062169.0,
        Hydroseq: 250004819.0,
        LevelPathI: 250004736.0,
        Pathlength: 372.139,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.991,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004855.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 250004801.0,
        DnDrainCou: 2,
        DnHydroseq: 250004803.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0279,
        TotDASqKM: 10619.7417,
        DivDASqKM: 0.0279,
        Tidal: 0,
        TOTMA: 0.024980637113300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9082.0,
        MAXELEVSMO: 9082.0,
        MINELEVSMO: 9082.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.236000001430511,
        QA_MA: 0.009,
        VA_MA: 0.35874,
        QC_MA: 0.009,
        VC_MA: 0.35874,
        QE_MA: 0.009,
        VE_MA: 0.35874,
        QA_01: 0.019,
        VA_01: 0.38326,
        QC_01: 0.019,
        VC_01: 0.38326,
        QE_01: 0.019,
        VE_01: 0.38326,
        QA_02: 0.022,
        VA_02: 0.38932,
        QC_02: 0.022,
        VC_02: 0.38932,
        QE_02: 0.022,
        VE_02: 0.38932,
        QA_03: 0.024,
        VA_03: 0.39315,
        QC_03: 0.024,
        VC_03: 0.39315,
        QE_03: 0.024,
        VE_03: 0.39315,
        QA_04: 0.012,
        VA_04: 0.36705,
        QC_04: 0.012,
        VC_04: 0.36705,
        QE_04: 0.012,
        VE_04: 0.36705,
        QA_05: 0.007,
        VA_05: 0.35245,
        QC_05: 0.007,
        VC_05: 0.35245,
        QE_05: 0.007,
        VE_05: 0.35245,
        QA_06: 0.004,
        VA_06: 0.34112,
        QC_06: 0.004,
        VC_06: 0.34112,
        QE_06: 0.004,
        VE_06: 0.34112,
        QA_07: 0.003,
        VA_07: 0.33649,
        QC_07: 0.003,
        VC_07: 0.33649,
        QE_07: 0.003,
        VE_07: 0.33649,
        QA_08: 0.003,
        VA_08: 0.33649,
        QC_08: 0.003,
        VC_08: 0.33649,
        QE_08: 0.003,
        VE_08: 0.33649,
        QA_09: 0.002,
        VA_09: 0.33104,
        QC_09: 0.002,
        VC_09: 0.33104,
        QE_09: 0.002,
        VE_09: 0.33104,
        QA_10: 0.001,
        VA_10: 0.32407,
        QC_10: 0.001,
        VC_10: 0.32407,
        QE_10: 0.001,
        VE_10: 0.32407,
        QA_11: 0.001,
        VA_11: 0.32407,
        QC_11: 0.001,
        VC_11: 0.32407,
        QE_11: 0.001,
        VE_11: 0.32407,
        QA_12: 0.006,
        VA_12: 0.34899,
        QC_12: 0.006,
        VC_12: 0.34899,
        QE_12: 0.006,
        VE_12: 0.34899,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88441011822448, 34.575273879665588, 0.0],
          [-80.883246918226291, 34.573395679668636, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287690.0,
        COMID: 9733416,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.349,
        REACHCODE: "03050103000801",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0032064129308710001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059050.0,
        ToNode: 250059896.0,
        Hydroseq: 250006290.0,
        LevelPathI: 250006272.0,
        Pathlength: 420.321,
        TerminalPa: 250002604.0,
        ArbolateSu: 6390.467,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250006272.0,
        UpHydroseq: 250006312.0,
        DnLevelPat: 250006272.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006272.0,
        FromMeas: 16.27777,
        ToMeas: 67.09173,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1782,
        TotDASqKM: 8694.8046,
        DivDASqKM: 0.1917,
        Tidal: 0,
        TOTMA: 0.034132092080699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14369.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.34900000691413902,
        QA_MA: 0.078,
        VA_MA: 0.38827,
        QC_MA: 0.078,
        VC_MA: 0.38827,
        QE_MA: 0.078,
        VE_MA: 0.38827,
        QA_01: 0.17,
        VA_01: 0.4292,
        QC_01: 0.17,
        VC_01: 0.4292,
        QE_01: 0.17,
        VE_01: 0.4292,
        QA_02: 0.177,
        VA_02: 0.43182,
        QC_02: 0.177,
        VC_02: 0.43182,
        QE_02: 0.177,
        VE_02: 0.43182,
        QA_03: 0.188,
        VA_03: 0.43584,
        QC_03: 0.188,
        VC_03: 0.43584,
        QE_03: 0.188,
        VE_03: 0.43584,
        QA_04: 0.112,
        VA_04: 0.40519,
        QC_04: 0.112,
        VC_04: 0.40519,
        QE_04: 0.112,
        VE_04: 0.40519,
        QA_05: 0.061,
        VA_05: 0.3785,
        QC_05: 0.061,
        VC_05: 0.3785,
        QE_05: 0.061,
        VE_05: 0.3785,
        QA_06: 0.038,
        VA_06: 0.36292,
        QC_06: 0.038,
        VC_06: 0.36292,
        QE_06: 0.038,
        VE_06: 0.36292,
        QA_07: 0.028,
        VA_07: 0.35476,
        QC_07: 0.028,
        VC_07: 0.35476,
        QE_07: 0.028,
        VE_07: 0.35476,
        QA_08: 0.02,
        VA_08: 0.34717,
        QC_08: 0.02,
        VC_08: 0.34717,
        QE_08: 0.02,
        VE_08: 0.34717,
        QA_09: 0.018,
        VA_09: 0.34506,
        QC_09: 0.018,
        VC_09: 0.34506,
        QE_09: 0.018,
        VE_09: 0.34506,
        QA_10: 0.012,
        VA_10: 0.33796,
        QC_10: 0.012,
        VC_10: 0.33796,
        QE_10: 0.012,
        VE_10: 0.33796,
        QA_11: 0.018,
        VA_11: 0.34506,
        QC_11: 0.018,
        VC_11: 0.34506,
        QE_11: 0.018,
        VE_11: 0.34506,
        QA_12: 0.094,
        VA_12: 0.39659,
        QC_12: 0.094,
        VC_12: 0.39659,
        QE_12: 0.094,
        VE_12: 0.39659,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.868091718249843, 34.943997212426666, 0.0],
          [-80.867090584917946, 34.941088212431112, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288673.0,
        COMID: 9757328,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.059,
        REACHCODE: "03050103017634",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00054202501302000002,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059747.0,
        ToNode: 250062186.0,
        Hydroseq: 250004804.0,
        LevelPathI: 250004804.0,
        Pathlength: 372.108,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.947,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004823.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004782.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.8992,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0072647895181599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9081.0,
        MINELEVSMO: 8951.0,
        SLOPE: 0.02203389,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.059000000357628,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882720718227176, 34.573922479667772, 0.0],
          [-80.8829147182268, 34.573441479668475, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288674.0,
        COMID: 9757330,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.031,
        REACHCODE: "03050103017635",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00033534233202400001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062169.0,
        ToNode: 250062186.0,
        Hydroseq: 250004803.0,
        LevelPathI: 250004736.0,
        Pathlength: 372.108,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.022,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004819.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004782.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.7417,
        DivDASqKM: 0.0279,
        Tidal: 0,
        TOTMA: 0.0023624332132600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9082.0,
        MINELEVSMO: 8951.0,
        SLOPE: 0.04225806,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.030999999493361002,
        QA_MA: 0.009,
        VA_MA: 0.49828,
        QC_MA: 0.009,
        VC_MA: 0.49828,
        QE_MA: 0.009,
        VE_MA: 0.49828,
        QA_01: 0.019,
        VA_01: 0.59076,
        QC_01: 0.019,
        VC_01: 0.59076,
        QE_01: 0.019,
        VE_01: 0.59076,
        QA_02: 0.022,
        VA_02: 0.61362,
        QC_02: 0.022,
        VC_02: 0.61362,
        QE_02: 0.022,
        VE_02: 0.61362,
        QA_03: 0.024,
        VA_03: 0.62805,
        QC_03: 0.024,
        VC_03: 0.62805,
        QE_03: 0.024,
        VE_03: 0.62805,
        QA_04: 0.012,
        VA_04: 0.52962,
        QC_04: 0.012,
        VC_04: 0.52962,
        QE_04: 0.012,
        VE_04: 0.52962,
        QA_05: 0.007,
        VA_05: 0.47456,
        QC_05: 0.007,
        VC_05: 0.47456,
        QE_05: 0.007,
        VE_05: 0.47456,
        QA_06: 0.004,
        VA_06: 0.43184,
        QC_06: 0.004,
        VC_06: 0.43184,
        QE_06: 0.004,
        VE_06: 0.43184,
        QA_07: 0.003,
        VA_07: 0.41435,
        QC_07: 0.003,
        VC_07: 0.41435,
        QE_07: 0.003,
        VE_07: 0.41435,
        QA_08: 0.003,
        VA_08: 0.41435,
        QC_08: 0.003,
        VC_08: 0.41435,
        QE_08: 0.003,
        VE_08: 0.41435,
        QA_09: 0.002,
        VA_09: 0.39383,
        QC_09: 0.002,
        VC_09: 0.39383,
        QE_09: 0.002,
        VE_09: 0.39383,
        QA_10: 0.001,
        VA_10: 0.36752,
        QC_10: 0.001,
        VC_10: 0.36752,
        QE_10: 0.001,
        VE_10: 0.36752,
        QA_11: 0.001,
        VA_11: 0.36752,
        QC_11: 0.001,
        VC_11: 0.36752,
        QE_11: 0.001,
        VE_11: 0.36752,
        QA_12: 0.006,
        VA_12: 0.4615,
        QC_12: 0.006,
        VC_12: 0.4615,
        QE_12: 0.006,
        VE_12: 0.4615,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883246918226291, 34.573395679668636, 0.0],
          [-80.8829147182268, 34.573441479668475, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288651.0,
        COMID: 9757284,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.077,
        REACHCODE: "03050103017612",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000805913776477,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062146.0,
        ToNode: 250059743.0,
        Hydroseq: 250004916.0,
        LevelPathI: 250004916.0,
        Pathlength: 372.68,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.662,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004916.0,
        UpHydroseq: 250004929.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 250004896.0,
        DnDrainCou: 2,
        DnHydroseq: 250004902.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 10619.6346,
        DivDASqKM: 0.0027,
        Tidal: 0,
        TOTMA: 0.0094811658724900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9723.0,
        MAXELEVSMO: 10810.0,
        MINELEVSMO: 9839.0,
        SLOPE: 0.12610389,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.076999999582767001,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.001,
        VA_01: 0.30839,
        QC_01: 0.001,
        VC_01: 0.30839,
        QE_01: 0.001,
        VE_01: 0.30839,
        QA_02: 0.002,
        VA_02: 0.30839,
        QC_02: 0.002,
        VC_02: 0.30839,
        QE_02: 0.002,
        VE_02: 0.30839,
        QA_03: 0.002,
        VA_03: 0.30839,
        QC_03: 0.002,
        VC_03: 0.30839,
        QE_03: 0.002,
        VE_03: 0.30839,
        QA_04: 0.001,
        VA_04: 0.30839,
        QC_04: 0.001,
        VC_04: 0.30839,
        QE_04: 0.001,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885933518222089, 34.577930812994907, 0.0],
          [-80.885213518223281, 34.57761021299541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288652.0,
        COMID: 9757286,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.063,
        REACHCODE: "03050103017613",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00056696830315300004,
        StreamLeve: 7,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059743.0,
        ToNode: 250062151.0,
        Hydroseq: 250004896.0,
        LevelPathI: 250004896.0,
        Pathlength: 372.63,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.917,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004938.0,
        DnLevelPat: 250004842.0,
        DnMinorHyd: 250004877.0,
        DnDrainCou: 2,
        DnHydroseq: 250004878.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.6706,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0077573176988299996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9839.0,
        MINELEVSMO: 9756.0,
        SLOPE: 0.0131746,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.063000001013279003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885213518223281, 34.57761021299541, 0.0],
          [-80.88535198488978, 34.577060412996275, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288653.0,
        COMID: 9757288,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.074,
        REACHCODE: "03050103017614",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00076489462021899998,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062148.0,
        ToNode: 250062151.0,
        Hydroseq: 250004895.0,
        LevelPathI: 250004842.0,
        Pathlength: 372.63,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.704,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004842.0,
        UpHydroseq: 250004911.0,
        DnLevelPat: 250004842.0,
        DnMinorHyd: 250004877.0,
        DnDrainCou: 2,
        DnHydroseq: 250004878.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 10619.6373,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0062330392172400004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9756.0,
        MAXELEVSMO: 10822.0,
        MINELEVSMO: 9756.0,
        SLOPE: 0.14405405,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 0.001,
        VA_MA: 0.45082,
        QC_MA: 0.001,
        VC_MA: 0.45082,
        QE_MA: 0.001,
        VE_MA: 0.45082,
        QA_01: 0.002,
        VA_01: 0.5142,
        QC_01: 0.002,
        VC_01: 0.5142,
        QE_01: 0.002,
        VE_01: 0.5142,
        QA_02: 0.002,
        VA_02: 0.5142,
        QC_02: 0.002,
        VC_02: 0.5142,
        QE_02: 0.002,
        VE_02: 0.5142,
        QA_03: 0.003,
        VA_03: 0.56364,
        QC_03: 0.003,
        VC_03: 0.56364,
        QE_03: 0.003,
        VE_03: 0.56364,
        QA_04: 0.001,
        VA_04: 0.45082,
        QC_04: 0.001,
        VC_04: 0.45082,
        QE_04: 0.001,
        VE_04: 0.45082,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886016584888637, 34.577381012995772, 0.0],
          [-80.88535198488978, 34.577060412996275, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288649.0,
        COMID: 9757280,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.118,
        REACHCODE: "03050103017610",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0011910543387120001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059748.0,
        ToNode: 250062145.0,
        Hydroseq: 250004481.0,
        LevelPathI: 250004481.0,
        Pathlength: 372.626,
        TerminalPa: 250002604.0,
        ArbolateSu: 7670.844,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004494.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004456.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9975.6495,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0145295790363,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9190.0,
        MINELEVSMO: 9073.0,
        SLOPE: 0.00991525,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.118000000715256,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878705384900002, 34.578275012994254, 0.0],
          [-80.877763784901504, 34.577771079661716, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288662.0,
        COMID: 9757306,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.106,
        REACHCODE: "03050103017623",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0009976104078580001,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062157.0,
        ToNode: 250062158.0,
        Hydroseq: 250004856.0,
        LevelPathI: 250004842.0,
        Pathlength: 372.432,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.487,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004842.0,
        UpHydroseq: 250004878.0,
        DnLevelPat: 250004842.0,
        DnMinorHyd: 250004841.0,
        DnDrainCou: 2,
        DnHydroseq: 250004842.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 10619.7138,
        DivDASqKM: 0.0189,
        Tidal: 0,
        TOTMA: 0.0090911453011799995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9239.0,
        MAXELEVSMO: 9329.0,
        MINELEVSMO: 9239.0,
        SLOPE: 0.00849056,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.105999998748302,
        QA_MA: 0.006,
        VA_MA: 0.44275,
        QC_MA: 0.006,
        VC_MA: 0.44275,
        QE_MA: 0.006,
        VE_MA: 0.44275,
        QA_01: 0.013,
        VA_01: 0.51096,
        QC_01: 0.013,
        VC_01: 0.51096,
        QE_01: 0.013,
        VE_01: 0.51096,
        QA_02: 0.014,
        VA_02: 0.51909,
        QC_02: 0.014,
        VC_02: 0.51909,
        QE_02: 0.014,
        VE_02: 0.51909,
        QA_03: 0.016,
        VA_03: 0.53457,
        QC_03: 0.016,
        VC_03: 0.53457,
        QE_03: 0.016,
        VE_03: 0.53457,
        QA_04: 0.008,
        VA_04: 0.46492,
        QC_04: 0.008,
        VC_04: 0.46492,
        QE_04: 0.008,
        VE_04: 0.46492,
        QA_05: 0.004,
        VA_05: 0.41672,
        QC_05: 0.004,
        VC_05: 0.41672,
        QE_05: 0.004,
        VE_05: 0.41672,
        QA_06: 0.003,
        VA_06: 0.40137,
        QC_06: 0.003,
        VC_06: 0.40137,
        QE_06: 0.003,
        VE_06: 0.40137,
        QA_07: 0.002,
        VA_07: 0.38336,
        QC_07: 0.002,
        VC_07: 0.38336,
        QE_07: 0.002,
        VE_07: 0.38336,
        QA_08: 0.002,
        VA_08: 0.38336,
        QC_08: 0.002,
        VC_08: 0.38336,
        QE_08: 0.002,
        VE_08: 0.38336,
        QA_09: 0.001,
        VA_09: 0.36028,
        QC_09: 0.001,
        VC_09: 0.36028,
        QE_09: 0.001,
        VE_09: 0.36028,
        QA_10: 0.001,
        VA_10: 0.36028,
        QC_10: 0.001,
        VC_10: 0.36028,
        QE_10: 0.001,
        VE_10: 0.36028,
        QA_11: 0.001,
        VA_11: 0.36028,
        QC_11: 0.001,
        VC_11: 0.36028,
        QE_11: 0.001,
        VE_11: 0.36028,
        QA_12: 0.004,
        VA_12: 0.41672,
        QC_12: 0.004,
        VC_12: 0.41672,
        QE_12: 0.004,
        VE_12: 0.41672,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884714918224063, 34.576419079663879, 0.0],
          [-80.884188584891547, 34.575571612998488, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288655.0,
        COMID: 9757292,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.056,
        REACHCODE: "03050103017616",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000553983155766,
        StreamLeve: 7,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059744.0,
        ToNode: 250062157.0,
        Hydroseq: 250004879.0,
        LevelPathI: 250004879.0,
        Pathlength: 372.538,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.035,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004902.0,
        DnLevelPat: 250004842.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004856.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.6931,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0068953936120099996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9527.0,
        MINELEVSMO: 9329.0,
        SLOPE: 0.03535714,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.056000001728534997,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884299584891323, 34.576785679663317, 0.0],
          [-80.884714918224063, 34.576419079663879, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288656.0,
        COMID: 9757294,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.092,
        REACHCODE: "03050103017617",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00090397034232900003,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062151.0,
        ToNode: 250062157.0,
        Hydroseq: 250004878.0,
        LevelPathI: 250004842.0,
        Pathlength: 372.538,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.2,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004842.0,
        UpHydroseq: 250004895.0,
        DnLevelPat: 250004842.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004856.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0108,
        TotDASqKM: 10619.6868,
        DivDASqKM: 0.0144,
        Tidal: 0,
        TOTMA: 0.0074019260627299997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9329.0,
        MAXELEVSMO: 9756.0,
        MINELEVSMO: 9329.0,
        SLOPE: 0.04641304,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.092000000178814004,
        QA_MA: 0.004,
        VA_MA: 0.47197,
        QC_MA: 0.004,
        VC_MA: 0.47197,
        QE_MA: 0.004,
        VE_MA: 0.47197,
        QA_01: 0.01,
        VA_01: 0.57448,
        QC_01: 0.01,
        VC_01: 0.57448,
        QE_01: 0.01,
        VE_01: 0.57448,
        QA_02: 0.011,
        VA_02: 0.58829,
        QC_02: 0.011,
        VC_02: 0.58829,
        QE_02: 0.011,
        VE_02: 0.58829,
        QA_03: 0.012,
        VA_03: 0.60153,
        QC_03: 0.012,
        VC_03: 0.60153,
        QE_03: 0.012,
        VE_03: 0.60153,
        QA_04: 0.006,
        VA_04: 0.51126,
        QC_04: 0.006,
        VC_04: 0.51126,
        QE_04: 0.006,
        VE_04: 0.51126,
        QA_05: 0.003,
        VA_05: 0.44879,
        QC_05: 0.003,
        VC_05: 0.44879,
        QE_05: 0.003,
        VE_05: 0.44879,
        QA_06: 0.002,
        VA_06: 0.4216,
        QC_06: 0.002,
        VC_06: 0.4216,
        QE_06: 0.002,
        VE_06: 0.4216,
        QA_07: 0.001,
        VA_07: 0.38674,
        QC_07: 0.001,
        VC_07: 0.38674,
        QE_07: 0.001,
        VE_07: 0.38674,
        QA_08: 0.001,
        VA_08: 0.38674,
        QC_08: 0.001,
        VC_08: 0.38674,
        QE_08: 0.001,
        VE_08: 0.38674,
        QA_09: 0.001,
        VA_09: 0.38674,
        QC_09: 0.001,
        VC_09: 0.38674,
        QE_09: 0.001,
        VE_09: 0.38674,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.003,
        VA_12: 0.44879,
        QC_12: 0.003,
        VC_12: 0.44879,
        QE_12: 0.003,
        VE_12: 0.44879,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88535198488978, 34.577060412996275, 0.0],
          [-80.884714918224063, 34.576419079663879, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288658.0,
        COMID: 9757298,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.034,
        REACHCODE: "03050103017619",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00035559678733200001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062153.0,
        ToNode: 250094148.0,
        Hydroseq: 250004857.0,
        LevelPathI: 250004832.0,
        Pathlength: 373.049,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.256,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004832.0,
        UpHydroseq: 250004877.0,
        DnLevelPat: 250004832.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004832.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 10619.6787,
        DivDASqKM: 0.0027,
        Tidal: 0,
        TOTMA: 0.0041864890770099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9860.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.034000001847744002,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.001,
        VA_01: 0.30839,
        QC_01: 0.001,
        VC_01: 0.30839,
        QE_01: 0.001,
        VE_01: 0.30839,
        QA_02: 0.002,
        VA_02: 0.30839,
        QC_02: 0.002,
        VC_02: 0.30839,
        QE_02: 0.002,
        VE_02: 0.30839,
        QA_03: 0.002,
        VA_03: 0.30839,
        QC_03: 0.002,
        VC_03: 0.30839,
        QE_03: 0.002,
        VE_03: 0.30839,
        QA_04: 0.001,
        VA_04: 0.30839,
        QC_04: 0.001,
        VC_04: 0.30839,
        QE_04: 0.001,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885130184889988, 34.576075479664382, 0.0],
          [-80.88543491822287, 34.575892212998042, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286582.0,
        COMID: 9745234,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.757,
        REACHCODE: "03050102000367",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0075351747690059999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060195.0,
        ToNode: 250060202.0,
        Hydroseq: 250017822.0,
        LevelPathI: 250017822.0,
        Pathlength: 552.273,
        TerminalPa: 250002604.0,
        ArbolateSu: 263.455,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250018072.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017591.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5598,
        TotDASqKM: 286.6689,
        DivDASqKM: 0.5598,
        Tidal: 0,
        TOTMA: 0.069804088853099994,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24654.0,
        MAXELEVSMO: 24724.0,
        MINELEVSMO: 24724.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.60699999332428001,
        QA_MA: 0.264,
        VA_MA: 0.4118,
        QC_MA: 0.264,
        VC_MA: 0.4118,
        QE_MA: 0.264,
        VE_MA: 0.4118,
        QA_01: 0.496,
        VA_01: 0.45294,
        QC_01: 0.496,
        VC_01: 0.45294,
        QE_01: 0.496,
        VE_01: 0.45294,
        QA_02: 0.533,
        VA_02: 0.45856,
        QC_02: 0.533,
        VC_02: 0.45856,
        QE_02: 0.533,
        VE_02: 0.45856,
        QA_03: 0.572,
        VA_03: 0.4643,
        QC_03: 0.572,
        VC_03: 0.4643,
        QE_03: 0.572,
        VE_03: 0.4643,
        QA_04: 0.398,
        VA_04: 0.43699,
        QC_04: 0.398,
        VC_04: 0.43699,
        QE_04: 0.398,
        VE_04: 0.43699,
        QA_05: 0.27,
        VA_05: 0.41304,
        QC_05: 0.27,
        VC_05: 0.41304,
        QE_05: 0.27,
        VE_05: 0.41304,
        QA_06: 0.158,
        VA_06: 0.38713,
        QC_06: 0.158,
        VC_06: 0.38713,
        QE_06: 0.158,
        VE_06: 0.38713,
        QA_07: 0.097,
        VA_07: 0.36916,
        QC_07: 0.097,
        VC_07: 0.36916,
        QE_07: 0.097,
        VE_07: 0.36916,
        QA_08: 0.067,
        VA_08: 0.35832,
        QC_08: 0.067,
        VC_08: 0.35832,
        QE_08: 0.067,
        VE_08: 0.35832,
        QA_09: 0.052,
        VA_09: 0.35203,
        QC_09: 0.052,
        VC_09: 0.35203,
        QE_09: 0.052,
        VE_09: 0.35203,
        QA_10: 0.045,
        VA_10: 0.34881,
        QC_10: 0.045,
        VC_10: 0.34881,
        QE_10: 0.045,
        VE_10: 0.34881,
        QA_11: 0.149,
        VA_11: 0.38471,
        QC_11: 0.149,
        VC_11: 0.38471,
        QE_11: 0.149,
        VE_11: 0.38471,
        QA_12: 0.338,
        VA_12: 0.4263,
        QC_12: 0.338,
        VC_12: 0.4263,
        QE_12: 0.338,
        VE_12: 0.4263,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.311308317561853, 35.654680611323563, 0.0],
          [-81.313750384224761, 35.653513811325411, 0.0],
          [-81.311450784228214, 35.651840811327816, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287813.0,
        COMID: 9733308,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.121,
        REACHCODE: "03050103000871",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001287035129394,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059010.0,
        ToNode: 250059011.0,
        Hydroseq: 250035577.0,
        LevelPathI: 250035577.0,
        Pathlength: 439.776,
        TerminalPa: 250002604.0,
        ArbolateSu: 80.504,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250037492.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033911.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 107.4618,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.014898974942500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 15699.0,
        MINELEVSMO: 15685.0,
        SLOPE: 0.00115702,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.120999999344349,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.705136785169486, 34.961380679066338, 0.0],
          [-80.706305118501007, 34.961059279066887, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 290885.0,
        COMID: 9713495,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.058,
        REACHCODE: "03050104001869",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0096614112788619994,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250057092.0,
        ToNode: 250058150.0,
        Hydroseq: 250004209.0,
        LevelPathI: 250003258.0,
        Pathlength: 362.558,
        TerminalPa: 250002604.0,
        ArbolateSu: 8446.481,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004219.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004201.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6362,
        TotDASqKM: 11372.9004,
        DivDASqKM: 11372.9004,
        Tidal: 0,
        TOTMA: 0.030208431820399999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6790.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.057999968528748,
        QA_MA: 5476.533,
        VA_MA: 1.32005,
        QC_MA: 5476.533,
        VC_MA: 1.32005,
        QE_MA: 6174.21,
        VE_MA: 1.32993,
        QA_01: 10271.957,
        VA_01: 1.72118,
        QC_01: 10271.957,
        VC_01: 1.72118,
        QE_01: 8879.439,
        VE_01: 1.54732,
        QA_02: 10858.629,
        VA_02: 1.76346,
        QC_02: 10858.629,
        VC_02: 1.76346,
        QE_02: 9018.702,
        VE_02: 1.5576,
        QA_03: 11888.869,
        VA_03: 1.83521,
        QC_03: 11888.869,
        VC_03: 1.83521,
        QE_03: 9639.682,
        VE_03: 1.60256,
        QA_04: 7999.81,
        VA_04: 1.54555,
        QC_04: 7999.81,
        VC_04: 1.54555,
        QE_04: 7748.65,
        VE_04: 1.46087,
        QA_05: 5344.089,
        VA_05: 1.30699,
        QC_05: 5344.089,
        VC_05: 1.30699,
        QE_05: 6299.421,
        VE_05: 1.34088,
        QA_06: 3182.006,
        VA_06: 1.06666,
        QC_06: 3182.006,
        VC_06: 1.06666,
        QE_06: 5405.78,
        VE_06: 1.26032,
        QA_07: 2008.066,
        VA_07: 0.90222,
        QC_07: 2008.066,
        VC_07: 0.90222,
        QE_07: 4083.914,
        VE_07: 1.12862,
        QA_08: 1442.566,
        VA_08: 0.80657,
        QC_08: 1442.566,
        VC_08: 0.80657,
        QE_08: 3737.635,
        VE_08: 1.09093,
        QA_09: 1293.275,
        VA_09: 0.7785,
        QC_09: 1293.275,
        VC_09: 0.7785,
        QE_09: 3430.083,
        VE_09: 1.05605,
        QA_10: 1351.219,
        VA_10: 0.78956,
        QC_10: 1351.219,
        VC_10: 0.78956,
        QE_10: 4670.749,
        VE_10: 1.18924,
        QA_11: 3297.984,
        VA_11: 1.08121,
        QC_11: 3297.984,
        VC_11: 1.08121,
        QE_11: 4744.044,
        VE_11: 1.19655,
        QA_12: 6817.806,
        VA_12: 1.44485,
        QC_12: 6817.806,
        VC_12: 1.44485,
        QE_12: 6526.485,
        VE_12: 1.36047,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880275184897641, 34.509113213101614, 0.0],
          [-80.882133384894701, 34.500149013115561, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287843.0,
        COMID: 9733576,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1027460",
        GNIS_NAME: "Sixmile Creek",
        LENGTHKM: 0.891,
        REACHCODE: "03050103000892",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0088716624218679995,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059109.0,
        ToNode: 250059110.0,
        Hydroseq: 250031137.0,
        LevelPathI: 250031137.0,
        Pathlength: 422.229,
        TerminalPa: 250002604.0,
        ArbolateSu: 68.582,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250031137.0,
        UpHydroseq: 250032436.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022622.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2664,
        TotDASqKM: 103.248,
        DivDASqKM: 103.248,
        Tidal: 0,
        TOTMA: 0.031095114715,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14427.0,
        MAXELEVSMO: 14617.0,
        MINELEVSMO: 14454.0,
        SLOPE: 0.0018294,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.89099997282028198,
        QA_MA: 42.776,
        VA_MA: 1.08518,
        QC_MA: 44.778,
        VC_MA: 1.08807,
        QE_MA: 44.778,
        VE_MA: 1.08807,
        QA_01: 91.697,
        VA_01: 1.47291,
        QC_01: 73.87,
        VC_01: 1.32547,
        QE_01: 73.87,
        VE_01: 1.32547,
        QA_02: 95.687,
        VA_02: 1.49955,
        QC_02: 82.326,
        VC_02: 1.38572,
        QE_02: 82.326,
        VE_02: 1.38572,
        QA_03: 102.484,
        VA_03: 1.54376,
        QC_03: 89.565,
        VC_03: 1.43503,
        QE_03: 89.565,
        VE_03: 1.43503,
        QA_04: 60.688,
        VA_04: 1.24372,
        QC_04: 57.851,
        VC_04: 1.20167,
        QE_04: 57.851,
        VE_04: 1.20167,
        QA_05: 33.556,
        VA_05: 0.99123,
        QC_05: 33.414,
        VC_05: 0.97582,
        QE_05: 33.414,
        VE_05: 0.97582,
        QA_06: 20.823,
        VA_06: 0.8384,
        QC_06: 26.652,
        VC_06: 0.9003,
        QE_06: 26.652,
        VE_06: 0.9003,
        QA_07: 15.27,
        VA_07: 0.75792,
        QC_07: 23.864,
        VC_07: 0.86658,
        QE_07: 23.864,
        VE_07: 0.86658,
        QA_08: 11.106,
        VA_08: 0.68799,
        QC_08: 24.175,
        VC_08: 0.87043,
        QE_08: 24.175,
        VE_08: 0.87043,
        QA_09: 9.717,
        VA_09: 0.66199,
        QC_09: 29.125,
        VC_09: 0.92886,
        QE_09: 29.125,
        VE_09: 0.92886,
        QA_10: 6.941,
        VA_10: 0.60414,
        QC_10: 25.854,
        VC_10: 0.89083,
        QE_10: 25.854,
        VE_10: 0.89083,
        QA_11: 13.254,
        VA_11: 0.72536,
        QC_11: 27.439,
        VC_11: 0.90952,
        QE_11: 27.439,
        VE_11: 0.90952,
        QA_12: 52.387,
        VA_12: 1.17345,
        QC_12: 48.463,
        VC_12: 1.12151,
        QE_12: 48.463,
        VE_12: 1.12151,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.815172918331996, 34.921948279127605, 0.0],
          [-80.816508718329885, 34.92041427913, 0.0],
          [-80.811867785003813, 34.916357679136297, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287844.0,
        COMID: 9733540,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1027460",
        GNIS_NAME: "Sixmile Creek",
        LENGTHKM: 2.522,
        REACHCODE: "03050103000893",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024984123725849999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059097.0,
        ToNode: 250059109.0,
        Hydroseq: 250032436.0,
        LevelPathI: 250031137.0,
        Pathlength: 423.12,
        TerminalPa: 250002604.0,
        ArbolateSu: 66.337,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250031137.0,
        UpHydroseq: 250033910.0,
        DnLevelPat: 250031137.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031137.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.3562,
        TotDASqKM: 101.79,
        DivDASqKM: 101.79,
        Tidal: 0,
        TOTMA: 0.088204464109,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14493.0,
        MAXELEVSMO: 15079.0,
        MINELEVSMO: 14617.0,
        SLOPE: 0.00183187,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5220000743865971,
        QA_MA: 42.182,
        VA_MA: 1.08283,
        QC_MA: 44.181,
        VC_MA: 1.08574,
        QE_MA: 44.181,
        VE_MA: 1.08574,
        QA_01: 90.403,
        VA_01: 1.46924,
        QC_01: 72.855,
        VC_01: 1.32221,
        QE_01: 72.855,
        VE_01: 1.32221,
        QA_02: 94.354,
        VA_02: 1.49591,
        QC_02: 81.21,
        VC_02: 1.38238,
        QE_02: 81.21,
        VE_02: 1.38238,
        QA_03: 101.053,
        VA_03: 1.53996,
        QC_03: 88.356,
        VC_03: 1.43157,
        QE_03: 88.356,
        VE_03: 1.43157,
        QA_04: 59.845,
        VA_04: 1.24089,
        QC_04: 57.066,
        VC_04: 1.19889,
        QE_04: 57.066,
        VE_04: 1.19889,
        QA_05: 33.085,
        VA_05: 0.98911,
        QC_05: 32.963,
        VC_05: 0.97376,
        QE_05: 32.963,
        VE_05: 0.97376,
        QA_06: 20.529,
        VA_06: 0.83673,
        QC_06: 26.281,
        VC_06: 0.89835,
        QE_06: 26.281,
        VE_06: 0.89835,
        QA_07: 15.054,
        VA_07: 0.75649,
        QC_07: 23.535,
        VC_07: 0.86477,
        QE_07: 23.535,
        VE_07: 0.86477,
        QA_08: 10.949,
        VA_08: 0.68679,
        QC_08: 23.827,
        VC_08: 0.86843,
        QE_08: 23.827,
        VE_08: 0.86843,
        QA_09: 9.58,
        VA_09: 0.66088,
        QC_09: 28.762,
        VC_09: 0.9273,
        QE_09: 28.762,
        VE_09: 0.9273,
        QA_10: 6.843,
        VA_10: 0.60321,
        QC_10: 25.562,
        VC_10: 0.88973,
        QE_10: 25.562,
        VE_10: 0.88973,
        QA_11: 13.115,
        VA_11: 0.72485,
        QC_11: 27.219,
        VC_11: 0.90944,
        QE_11: 27.219,
        VE_11: 0.90944,
        QA_12: 51.68,
        VA_12: 1.17101,
        QC_12: 47.867,
        VC_12: 1.11953,
        QE_12: 47.867,
        VE_12: 1.11953,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.818194784993977, 34.935578412439725, 0.0],
          [-80.816917118329343, 34.93280621244395, 0.0],
          [-80.818447718326922, 34.931593012445887, 0.0],
          [-80.813997718333781, 34.931659612445685, 0.0],
          [-80.816474318329995, 34.92975961244872, 0.0],
          [-80.815753984997798, 34.925979812454671, 0.0],
          [-80.813002118335362, 34.924214879124008, 0.0],
          [-80.815172918331996, 34.921948279127605, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287816.0,
        COMID: 9733290,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.122,
        REACHCODE: "03050103000873",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0011918486964679999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059001.0,
        ToNode: 250059003.0,
        Hydroseq: 250032438.0,
        LevelPathI: 250032438.0,
        Pathlength: 438.894,
        TerminalPa: 250002604.0,
        ArbolateSu: 85.179,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250033911.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031138.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 110.8386,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.015022107217,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 15660.0,
        MINELEVSMO: 15552.0,
        SLOPE: 0.00885245,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.122000001370907,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.712595585157885, 34.963826479062618, 0.0],
          [-80.713319785156784, 34.96458181239467, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286789.0,
        COMID: 9734766,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.538,
        REACHCODE: "03050103000073",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014827082361712001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059508.0,
        ToNode: 250059520.0,
        Hydroseq: 250018602.0,
        LevelPathI: 250016109.0,
        Pathlength: 400.834,
        TerminalPa: 250002604.0,
        ArbolateSu: 222.573,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250018887.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018331.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7649,
        TotDASqKM: 205.0164,
        DivDASqKM: 205.0164,
        Tidal: 0,
        TOTMA: 0.055452472340299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12838.0,
        MAXELEVSMO: 12950.0,
        MINELEVSMO: 12869.0,
        SLOPE: 0.00052665,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5379999876022341,
        QA_MA: 81.499,
        VA_MA: 1.05194,
        QC_MA: 83.206,
        VC_MA: 1.05319,
        QE_MA: 83.206,
        VE_MA: 1.05319,
        QA_01: 179.57,
        VA_01: 1.43945,
        QC_01: 142.134,
        VC_01: 1.29813,
        QE_01: 142.134,
        VE_01: 1.29813,
        QA_02: 186.27,
        VA_02: 1.46167,
        QC_02: 157.378,
        VC_02: 1.35314,
        QE_02: 157.378,
        VE_02: 1.35314,
        QA_03: 199.006,
        VA_03: 1.50289,
        QC_03: 170.152,
        VC_03: 1.39735,
        QE_03: 170.152,
        VE_03: 1.39735,
        QA_04: 113.701,
        VA_04: 1.19575,
        QC_04: 106.85,
        VC_04: 1.15897,
        QE_04: 106.85,
        VE_04: 1.15897,
        QA_05: 63.399,
        VA_05: 0.95911,
        QC_05: 61.646,
        VC_05: 0.94354,
        QE_05: 61.646,
        VE_05: 0.94354,
        QA_06: 41.241,
        VA_06: 0.82627,
        QC_06: 52.294,
        VC_06: 0.8904,
        QE_06: 52.294,
        VE_06: 0.8904,
        QA_07: 30.288,
        VA_07: 0.74797,
        QC_07: 46.565,
        VC_07: 0.85563,
        QE_07: 46.565,
        VE_07: 0.85563,
        QA_08: 22.052,
        VA_08: 0.6798,
        QC_08: 48.667,
        VC_08: 0.86861,
        QE_08: 48.667,
        VE_08: 0.86861,
        QA_09: 16.644,
        VA_09: 0.62826,
        QC_09: 46.84,
        VC_09: 0.85734,
        QE_09: 46.84,
        VE_09: 0.85734,
        QA_10: 13.782,
        VA_10: 0.59776,
        QC_10: 44.72,
        VC_10: 0.844,
        QE_10: 44.72,
        VE_10: 0.844,
        QA_11: 16.633,
        VA_11: 0.62815,
        QC_11: 32.627,
        VC_11: 0.76144,
        QE_11: 32.627,
        VE_11: 0.76144,
        QA_12: 96.058,
        VA_12: 1.11975,
        QC_12: 84.134,
        VC_12: 1.05759,
        QE_12: 84.134,
        VE_12: 1.05759,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.811672918337365, 34.732670879421391, 0.0],
          [-80.81056631833917, 34.728845012760587, 0.0],
          [-80.811927785003718, 34.726280279431251, 0.0],
          [-80.807548185010546, 34.721628079438517, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283739.0,
        COMID: 9751976,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.062,
        REACHCODE: "03050101000157",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011051907834934,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060926.0,
        ToNode: 250060934.0,
        Hydroseq: 250031112.0,
        LevelPathI: 250020920.0,
        Pathlength: 643.349,
        TerminalPa: 250002604.0,
        ArbolateSu: 101.937,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250032412.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029949.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1205,
        TotDASqKM: 106.2306,
        DivDASqKM: 106.2306,
        Tidal: 0,
        TOTMA: 0.0427029872536,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32003.0,
        MAXELEVSMO: 32198.0,
        MINELEVSMO: 32153.0,
        SLOPE: 0.00042372,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.062000036239624,
        QA_MA: 58.672,
        VA_MA: 0.94263,
        QC_MA: 60.67,
        VC_MA: 0.94436,
        QE_MA: 60.67,
        VE_MA: 0.94436,
        QA_01: 99.28,
        VA_01: 1.14699,
        QC_01: 79.813,
        VC_01: 1.04405,
        QE_01: 79.813,
        VE_01: 1.04405,
        QA_02: 103.394,
        VA_02: 1.16527,
        QC_02: 88.769,
        VC_02: 1.08679,
        QE_02: 88.769,
        VE_02: 1.08679,
        QA_03: 113.986,
        VA_03: 1.21081,
        QC_03: 99.268,
        VC_03: 1.1344,
        QE_03: 99.268,
        VE_03: 1.1344,
        QA_04: 89.95,
        VA_04: 1.10418,
        QC_04: 84.981,
        VC_04: 1.06898,
        QE_04: 84.981,
        VE_04: 1.06898,
        QA_05: 65.802,
        VA_05: 0.98246,
        QC_05: 63.893,
        VC_05: 0.96208,
        QE_05: 63.893,
        VE_05: 0.96208,
        QA_06: 36.813,
        VA_06: 0.80357,
        QC_06: 46.751,
        VC_06: 0.86217,
        QE_06: 46.751,
        VE_06: 0.86217,
        QA_07: 22.356,
        VA_07: 0.68836,
        QC_07: 34.62,
        VC_07: 0.78052,
        QE_07: 34.62,
        VE_07: 0.78052,
        QA_08: 14.929,
        VA_08: 0.61503,
        QC_08: 32.691,
        VC_08: 0.76636,
        QE_08: 32.691,
        VE_08: 0.76636,
        QA_09: 11.601,
        VA_09: 0.5766,
        QC_09: 34.057,
        VC_09: 0.77642,
        QE_09: 34.057,
        VE_09: 0.77642,
        QA_10: 15.412,
        VA_10: 0.62026,
        QC_10: 48.895,
        VC_10: 0.87551,
        QE_10: 48.895,
        VE_10: 0.87551,
        QA_11: 53.146,
        VA_11: 0.91018,
        QC_11: 79.127,
        VC_11: 1.04069,
        QE_11: 79.127,
        VE_11: 1.04069,
        QA_12: 77.564,
        VA_12: 1.04397,
        QC_12: 69.259,
        VC_12: 0.99068,
        QE_12: 69.259,
        VE_12: 0.99068,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.559947983842676, 35.895218477616879, 0.0],
          [-81.569351983827914, 35.889589410958934, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286790.0,
        COMID: 9734712,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.514,
        REACHCODE: "03050103000074",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015302301431642001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059488.0,
        ToNode: 250059508.0,
        Hydroseq: 250023116.0,
        LevelPathI: 250023116.0,
        Pathlength: 402.372,
        TerminalPa: 250002604.0,
        ArbolateSu: 82.096,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250023659.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018602.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0592,
        TotDASqKM: 73.7073,
        DivDASqKM: 73.7073,
        Tidal: 0,
        TOTMA: 0.056083512256900003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12948.0,
        MAXELEVSMO: 13220.0,
        MINELEVSMO: 12950.0,
        SLOPE: 0.00178335,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5140000581741331,
        QA_MA: 29.085,
        VA_MA: 1.02158,
        QC_MA: 30.905,
        VC_MA: 1.02509,
        QE_MA: 30.905,
        VE_MA: 1.02509,
        QA_01: 64.311,
        VA_01: 1.3953,
        QC_01: 52.292,
        VC_01: 1.25598,
        QE_01: 52.292,
        VE_01: 1.25598,
        QA_02: 66.631,
        VA_02: 1.41595,
        QC_02: 57.896,
        VC_02: 1.30862,
        QE_02: 57.896,
        VE_02: 1.30862,
        QA_03: 71.242,
        VA_03: 1.45601,
        QC_03: 63.014,
        VC_03: 1.35463,
        QE_03: 63.014,
        VE_03: 1.35463,
        QA_04: 40.568,
        VA_04: 1.15941,
        QC_04: 39.027,
        VC_04: 1.11963,
        QE_04: 39.027,
        VE_04: 1.11963,
        QA_05: 22.793,
        VA_05: 0.93498,
        QC_05: 23.028,
        VC_05: 0.92143,
        QE_05: 23.028,
        VE_05: 0.92143,
        QA_06: 14.78,
        VA_06: 0.80623,
        QC_06: 19.007,
        VC_06: 0.86204,
        QE_06: 19.007,
        VE_06: 0.86204,
        QA_07: 10.901,
        VA_07: 0.73192,
        QC_07: 17.174,
        VC_07: 0.83301,
        QE_07: 17.174,
        VE_07: 0.83301,
        QA_08: 7.928,
        VA_08: 0.66603,
        QC_08: 17.142,
        VC_08: 0.83249,
        QE_08: 17.142,
        VE_08: 0.83249,
        QA_09: 5.946,
        VA_09: 0.61537,
        QC_09: 18.877,
        VC_09: 0.86002,
        QE_09: 18.877,
        VE_09: 0.86002,
        QA_10: 4.955,
        VA_10: 0.58704,
        QC_10: 19.752,
        VC_10: 0.87346,
        QE_10: 19.752,
        VE_10: 0.87346,
        QA_11: 5.273,
        VA_11: 0.5964,
        QC_11: 13.586,
        VC_11: 0.77163,
        QE_11: 13.586,
        VE_11: 0.77163,
        QA_12: 33.945,
        VA_12: 1.08256,
        QC_12: 32.655,
        VC_12: 1.04636,
        QE_12: 32.655,
        VE_12: 1.04636,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.802093585018952, 34.740247479409504, 0.0],
          [-80.810005785006695, 34.735693612749913, 0.0],
          [-80.809313118341095, 34.734456279418509, 0.0],
          [-80.811672918337365, 34.732670879421391, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283735.0,
        COMID: 9752186,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.322,
        REACHCODE: "03050101000153",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012348307830616001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060982.0,
        ToNode: 250060991.0,
        Hydroseq: 250027071.0,
        LevelPathI: 250020920.0,
        Pathlength: 639.284,
        TerminalPa: 250002604.0,
        ArbolateSu: 138.541,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250027943.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026273.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.603,
        TotDASqKM: 148.3065,
        DivDASqKM: 148.3065,
        Tidal: 0,
        TOTMA: 0.045135262970799998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31299.0,
        MAXELEVSMO: 31508.0,
        MINELEVSMO: 31355.0,
        SLOPE: 0.00115733,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3220000267028811,
        QA_MA: 80.707,
        VA_MA: 1.11084,
        QC_MA: 82.429,
        VC_MA: 1.11221,
        QE_MA: 82.429,
        VE_MA: 1.11221,
        QA_01: 137.461,
        VA_01: 1.37307,
        QC_01: 109.569,
        VC_01: 1.24335,
        QE_01: 109.569,
        VE_01: 1.24335,
        QA_02: 143.529,
        VA_02: 1.39777,
        QC_02: 122.13,
        VC_02: 1.29882,
        QE_02: 122.13,
        VE_02: 1.29882,
        QA_03: 158.259,
        VA_03: 1.45578,
        QC_03: 136.34,
        VC_03: 1.35843,
        QE_03: 136.34,
        VE_03: 1.35843,
        QA_04: 124.239,
        VA_04: 1.3174,
        QC_04: 116.518,
        VC_04: 1.27438,
        QE_04: 116.518,
        VE_04: 1.27438,
        QA_05: 90.285,
        VA_05: 1.16007,
        QC_05: 86.635,
        VC_05: 1.13373,
        QE_05: 86.635,
        VE_05: 1.13373,
        QA_06: 50.585,
        VA_06: 0.93454,
        QC_06: 63.963,
        VC_06: 1.01093,
        QE_06: 63.963,
        VE_06: 1.01093,
        QA_07: 30.74,
        VA_07: 0.78902,
        QC_07: 47.242,
        VC_07: 0.90651,
        QE_07: 47.242,
        VE_07: 0.90651,
        QA_08: 20.576,
        VA_08: 0.69675,
        QC_08: 45.347,
        VC_08: 0.89364,
        QE_08: 45.347,
        VE_08: 0.89364,
        QA_09: 16.127,
        VA_09: 0.64963,
        QC_09: 45.551,
        VC_09: 0.89504,
        QE_09: 45.551,
        VE_09: 0.89504,
        QA_10: 19.042,
        VA_10: 0.6811,
        QC_10: 57.895,
        VC_10: 0.97471,
        QE_10: 57.895,
        VE_10: 0.97471,
        QA_11: 71.43,
        VA_11: 1.06046,
        QC_11: 99.141,
        VC_11: 1.195,
        QE_11: 99.141,
        VE_11: 1.195,
        QA_12: 106.476,
        VA_12: 1.23803,
        QC_12: 92.397,
        VC_12: 1.16244,
        QE_12: 92.397,
        VE_12: 1.16244,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.589227317130451, 35.872787210984995, 0.0],
          [-81.594801783788512, 35.86204821100165, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283736.0,
        COMID: 9752124,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.402,
        REACHCODE: "03050101000154",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0037448565065810002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060967.0,
        ToNode: 250060982.0,
        Hydroseq: 250027943.0,
        LevelPathI: 250020920.0,
        Pathlength: 640.606,
        TerminalPa: 250002604.0,
        ArbolateSu: 134.235,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250028906.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027071.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2934,
        TotDASqKM: 144.7461,
        DivDASqKM: 144.7461,
        Tidal: 0,
        TOTMA: 0.0142329860092,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31508.0,
        MAXELEVSMO: 31543.0,
        MINELEVSMO: 31508.0,
        SLOPE: 0.00087064,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40200001001357999,
        QA_MA: 78.882,
        VA_MA: 1.07115,
        QC_MA: 80.636,
        VC_MA: 1.07251,
        QE_MA: 80.636,
        VE_MA: 1.07251,
        QA_01: 134.246,
        VA_01: 1.31998,
        QC_01: 107.073,
        VC_01: 1.19668,
        QE_01: 107.073,
        VE_01: 1.19668,
        QA_02: 140.112,
        VA_02: 1.34322,
        QC_02: 119.301,
        VC_02: 1.24918,
        QE_02: 119.301,
        VE_02: 1.24918,
        QA_03: 154.517,
        VA_03: 1.39842,
        QC_03: 133.222,
        VC_03: 1.30596,
        QE_03: 133.222,
        VE_03: 1.30596,
        QA_04: 121.392,
        VA_04: 1.26734,
        QC_04: 113.908,
        VC_04: 1.22635,
        QE_04: 113.908,
        VE_04: 1.22635,
        QA_05: 88.292,
        VA_05: 1.11819,
        QC_05: 84.793,
        VC_05: 1.09318,
        QE_05: 84.793,
        VE_05: 1.09318,
        QA_06: 49.457,
        VA_06: 0.90367,
        QC_06: 62.555,
        VC_06: 0.97613,
        QE_06: 62.555,
        VE_06: 0.97613,
        QA_07: 30.067,
        VA_07: 0.76543,
        QC_07: 46.232,
        VC_07: 0.87708,
        QE_07: 46.232,
        VE_07: 0.87708,
        QA_08: 20.105,
        VA_08: 0.67749,
        QC_08: 44.29,
        VC_08: 0.86427,
        QE_08: 44.29,
        VE_08: 0.86427,
        QA_09: 15.744,
        VA_09: 0.63255,
        QC_09: 44.595,
        VC_09: 0.8663,
        QE_09: 44.595,
        VE_09: 0.8663,
        QA_10: 18.754,
        VA_10: 0.6641,
        QC_10: 57.196,
        VC_10: 0.94511,
        QE_10: 57.196,
        VE_10: 0.94511,
        QA_11: 70.047,
        VA_11: 1.02452,
        QC_11: 97.673,
        VC_11: 1.15438,
        QE_11: 97.673,
        VE_11: 1.15438,
        QA_12: 104.1,
        VA_12: 1.1922,
        QC_12: 90.519,
        VC_12: 1.12089,
        QE_12: 90.519,
        VE_12: 1.12089,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.58762211713298, 35.876061610979889, 0.0],
          [-81.589227317130451, 35.872787210984995, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286763.0,
        COMID: 9734886,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.006,
        REACHCODE: "03050103000048",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0098455253473699999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059543.0,
        ToNode: 250059539.0,
        Hydroseq: 250017593.0,
        LevelPathI: 250016109.0,
        Pathlength: 396.075,
        TerminalPa: 250002604.0,
        ArbolateSu: 416.325,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250017825.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017364.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1637,
        TotDASqKM: 386.2152,
        DivDASqKM: 386.2152,
        Tidal: 0,
        TOTMA: 0.031895699892000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12694.0,
        MAXELEVSMO: 12767.0,
        MINELEVSMO: 12701.0,
        SLOPE: 0.00065606,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0060000419616699,
        QA_MA: 151.291,
        VA_MA: 1.19791,
        QC_MA: 150.796,
        VC_MA: 1.19767,
        QE_MA: 150.796,
        VE_MA: 1.19767,
        QA_01: 334.816,
        VA_01: 1.66466,
        QC_01: 260.724,
        VC_01: 1.49773,
        QE_01: 260.724,
        VE_01: 1.49773,
        QA_02: 347.596,
        VA_02: 1.69191,
        QC_02: 288.728,
        VC_02: 1.56394,
        QE_02: 288.728,
        VE_02: 1.56394,
        QA_03: 371.352,
        VA_03: 1.74134,
        QC_03: 311.038,
        VC_03: 1.61456,
        QE_03: 311.038,
        VE_03: 1.61456,
        QA_04: 211.373,
        VA_04: 1.37076,
        QC_04: 195.856,
        VC_04: 1.33011,
        QE_04: 195.856,
        VE_04: 1.33011,
        QA_05: 118.441,
        VA_05: 1.08949,
        QC_05: 112.504,
        VC_05: 1.06957,
        QE_05: 112.504,
        VE_05: 1.06957,
        QA_06: 75.534,
        VA_06: 0.92352,
        QC_06: 94.985,
        VC_06: 1.00414,
        QE_06: 94.985,
        VE_06: 1.00414,
        QA_07: 57.003,
        VA_07: 0.83812,
        QC_07: 86.32,
        VC_07: 0.96967,
        QE_07: 86.32,
        VE_07: 0.96967,
        QA_08: 41.542,
        VA_08: 0.7562,
        QC_08: 92.851,
        VC_08: 0.99579,
        QE_08: 92.851,
        VE_08: 0.99579,
        QA_09: 31.262,
        VA_09: 0.69345,
        QC_09: 81.716,
        VC_09: 0.95071,
        QE_09: 81.716,
        VE_09: 0.95071,
        QA_10: 25.964,
        VA_10: 0.65729,
        QC_10: 74.167,
        VC_10: 0.91848,
        QE_10: 74.167,
        VE_10: 0.91848,
        QA_11: 27.357,
        VA_11: 0.66711,
        QC_11: 47.685,
        VC_11: 0.79093,
        QE_11: 47.685,
        VE_11: 0.79093,
        QA_12: 174.4,
        VA_12: 1.26765,
        QC_12: 144.754,
        VC_12: 1.17857,
        QE_12: 144.754,
        VE_12: 1.17857,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.822902784986695, 34.70189041280247, 0.0],
          [-80.825483584982692, 34.699875479472269, 0.0],
          [-80.824927184983551, 34.702486679468223, 0.0],
          [-80.826867918313852, 34.703678479466312, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283737.0,
        COMID: 9752090,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.596,
        REACHCODE: "03050101000155",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016322575682314999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060957.0,
        ToNode: 250060967.0,
        Hydroseq: 250028906.0,
        LevelPathI: 250020920.0,
        Pathlength: 641.008,
        TerminalPa: 250002604.0,
        ArbolateSu: 120.669,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250029949.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027943.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8873,
        TotDASqKM: 127.0863,
        DivDASqKM: 127.0863,
        Tidal: 0,
        TOTMA: 0.048827660462699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31543.0,
        MAXELEVSMO: 32128.0,
        MINELEVSMO: 31543.0,
        SLOPE: 0.00366541,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.595999956130981,
        QA_MA: 69.615,
        VA_MA: 1.23917,
        QC_MA: 71.509,
        VC_MA: 1.24119,
        QE_MA: 71.509,
        VE_MA: 1.24119,
        QA_01: 118.214,
        VA_01: 1.54137,
        QC_01: 94.601,
        VC_01: 1.39063,
        QE_01: 94.601,
        VE_01: 1.39063,
        QA_02: 123.285,
        VA_02: 1.56918,
        QC_02: 105.34,
        VC_02: 1.45422,
        QE_02: 105.34,
        VE_02: 1.45422,
        QA_03: 135.925,
        VA_03: 1.63625,
        QC_03: 117.689,
        VC_03: 1.52369,
        QE_03: 117.689,
        VE_03: 1.52369,
        QA_04: 106.966,
        VA_04: 1.47762,
        QC_04: 100.661,
        VC_04: 1.42691,
        QE_04: 100.661,
        VE_04: 1.42691,
        QA_05: 77.929,
        VA_05: 1.29663,
        QC_05: 75.191,
        VC_05: 1.2664,
        QE_05: 75.191,
        VE_05: 1.2664,
        QA_06: 43.64,
        VA_06: 1.03475,
        QC_06: 55.292,
        VC_06: 1.12212,
        QE_06: 55.292,
        VE_06: 1.12212,
        QA_07: 26.521,
        VA_07: 0.86595,
        QC_07: 40.903,
        VC_07: 1.00176,
        QE_07: 40.903,
        VE_07: 1.00176,
        QA_08: 17.727,
        VA_08: 0.75858,
        QC_08: 38.952,
        VC_08: 0.984,
        QE_08: 38.952,
        VE_08: 0.984,
        QA_09: 13.844,
        VA_09: 0.70319,
        QC_09: 39.809,
        VC_09: 0.99185,
        QE_09: 39.809,
        VE_09: 0.99185,
        QA_10: 17.247,
        VA_10: 0.75206,
        QC_10: 53.493,
        VC_10: 1.10795,
        QE_10: 53.493,
        VE_10: 1.10795,
        QA_11: 62.27,
        VA_11: 1.18566,
        QC_11: 89.29,
        VC_11: 1.35793,
        QE_11: 89.29,
        VE_11: 1.35793,
        QA_12: 91.947,
        VA_12: 1.38736,
        QC_12: 80.852,
        VC_12: 1.30404,
        QE_12: 80.852,
        VE_12: 1.30404,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.574673783819776, 35.884530410966647, 0.0],
          [-81.580445583810729, 35.878715877642492, 0.0],
          [-81.58762211713298, 35.876061610979889, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286764.0,
        COMID: 9734876,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 0.972,
        REACHCODE: "03050103000049",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010389441590308,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059541.0,
        ToNode: 250059543.0,
        Hydroseq: 250017825.0,
        LevelPathI: 250016109.0,
        Pathlength: 397.081,
        TerminalPa: 250002604.0,
        ArbolateSu: 413.442,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250018074.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017593.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6102,
        TotDASqKM: 383.4711,
        DivDASqKM: 383.4711,
        Tidal: 0,
        TOTMA: 0.048253954907399997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12729.0,
        MAXELEVSMO: 12767.0,
        MINELEVSMO: 12767.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97200000286102295,
        QA_MA: 150.256,
        VA_MA: 0.76501,
        QC_MA: 149.805,
        VC_MA: 0.7649,
        QE_MA: 149.805,
        VE_MA: 0.7649,
        QA_01: 332.522,
        VA_01: 1.00461,
        QC_01: 258.984,
        VC_01: 0.9189,
        QE_01: 258.984,
        VE_01: 0.9189,
        QA_02: 345.189,
        VA_02: 1.01857,
        QC_02: 286.783,
        VC_02: 0.95287,
        QE_02: 286.783,
        VE_02: 0.95287,
        QA_03: 368.761,
        VA_03: 1.04393,
        QC_03: 308.94,
        VC_03: 0.97885,
        QE_03: 308.94,
        VE_03: 0.97885,
        QA_04: 209.898,
        VA_04: 0.8537,
        QC_04: 194.52,
        VC_04: 0.83282,
        QE_04: 194.52,
        VE_04: 0.83282,
        QA_05: 117.629,
        VA_05: 0.70935,
        QC_05: 111.762,
        VC_05: 0.69913,
        QE_05: 111.762,
        VE_05: 0.69913,
        QA_06: 75.018,
        VA_06: 0.62416,
        QC_06: 94.345,
        VC_06: 0.66552,
        QE_06: 94.345,
        VE_06: 0.66552,
        QA_07: 56.627,
        VA_07: 0.58036,
        QC_07: 85.764,
        VC_07: 0.64788,
        QE_07: 85.764,
        VE_07: 0.64788,
        QA_08: 41.247,
        VA_08: 0.53823,
        QC_08: 92.178,
        VC_08: 0.66114,
        QE_08: 92.178,
        VE_08: 0.66114,
        QA_09: 31.04,
        VA_09: 0.50603,
        QC_09: 81.205,
        VC_09: 0.63818,
        QE_09: 81.205,
        VE_09: 0.63818,
        QA_10: 25.779,
        VA_10: 0.48747,
        QC_10: 73.746,
        VC_10: 0.62173,
        QE_10: 73.746,
        VE_10: 0.62173,
        QA_11: 27.209,
        VA_11: 0.49268,
        QC_11: 47.488,
        VC_11: 0.55643,
        QE_11: 47.488,
        VE_11: 0.55643,
        QA_12: 173.286,
        VA_12: 0.80093,
        QC_12: 143.913,
        VC_12: 0.75528,
        QE_12: 143.913,
        VE_12: 0.75528,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.813332385001559, 34.704062212799045, 0.0],
          [-80.822902784986695, 34.70189041280247, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283738.0,
        COMID: 9752020,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.745,
        REACHCODE: "03050101000156",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0073950124337060002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060934.0,
        ToNode: 250060957.0,
        Hydroseq: 250029949.0,
        LevelPathI: 250020920.0,
        Pathlength: 642.604,
        TerminalPa: 250002604.0,
        ArbolateSu: 115.482,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250031112.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028906.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4959,
        TotDASqKM: 119.8737,
        DivDASqKM: 119.8737,
        Tidal: 0,
        TOTMA: 0.030161150001599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31724.0,
        MAXELEVSMO: 32153.0,
        MINELEVSMO: 32128.0,
        SLOPE: 0.00033557,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.74500000476837203,
        QA_MA: 65.846,
        VA_MA: 0.93647,
        QC_MA: 67.784,
        VC_MA: 0.93795,
        QE_MA: 67.784,
        VE_MA: 0.93795,
        QA_01: 111.623,
        VA_01: 1.13965,
        QC_01: 89.46,
        VC_01: 1.03789,
        QE_01: 89.46,
        VE_01: 1.03789,
        QA_02: 116.327,
        VA_02: 1.15807,
        QC_02: 99.553,
        VC_02: 1.08049,
        QE_02: 99.553,
        VE_02: 1.08049,
        QA_03: 128.316,
        VA_03: 1.2035,
        QC_03: 111.312,
        VC_03: 1.12765,
        QE_03: 111.312,
        VE_03: 1.12765,
        QA_04: 101.11,
        VA_04: 1.09711,
        QC_04: 95.271,
        VC_04: 1.06268,
        QE_04: 95.271,
        VE_04: 1.06268,
        QA_05: 73.792,
        VA_05: 0.97564,
        QC_05: 71.345,
        VC_05: 0.9553,
        QE_05: 71.345,
        VE_05: 0.9553,
        QA_06: 41.305,
        VA_06: 0.7987,
        QC_06: 52.374,
        VC_06: 0.85737,
        QE_06: 52.374,
        VE_06: 0.85737,
        QA_07: 25.103,
        VA_07: 0.68477,
        QC_07: 38.766,
        VC_07: 0.77631,
        QE_07: 38.766,
        VE_07: 0.77631,
        QA_08: 16.76,
        VA_08: 0.6121,
        QC_08: 36.785,
        VC_08: 0.76346,
        QE_08: 36.785,
        VE_08: 0.76346,
        QA_09: 13.068,
        VA_09: 0.57451,
        QC_09: 37.833,
        VC_09: 0.7703,
        QE_09: 37.833,
        VE_09: 0.7703,
        QA_10: 16.665,
        VA_10: 0.61119,
        QC_10: 52.047,
        VC_10: 0.85555,
        QE_10: 52.047,
        VE_10: 0.85555,
        QA_11: 59.212,
        VA_11: 0.90203,
        QC_11: 85.926,
        VC_11: 1.02244,
        QE_11: 85.926,
        VE_11: 1.02244,
        QA_12: 86.977,
        VA_12: 1.03651,
        QC_12: 76.865,
        VC_12: 0.98141,
        QE_12: 76.865,
        VE_12: 0.98141,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.569351983827914, 35.889589410958934, 0.0],
          [-81.574673783819776, 35.884530410966647, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286765.0,
        COMID: 9734860,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.75,
        REACHCODE: "03050103000050",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017070702013177001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059537.0,
        ToNode: 250059541.0,
        Hydroseq: 250018074.0,
        LevelPathI: 250016109.0,
        Pathlength: 398.053,
        TerminalPa: 250002604.0,
        ArbolateSu: 410.131,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250018331.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017825.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6038,
        TotDASqKM: 381.3228,
        DivDASqKM: 381.3228,
        Tidal: 0,
        TOTMA: 0.056381838838999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12746.0,
        MAXELEVSMO: 12869.0,
        MINELEVSMO: 12767.0,
        SLOPE: 0.00058285,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.75,
        QA_MA: 149.442,
        VA_MA: 1.17881,
        QC_MA: 149.024,
        VC_MA: 1.17861,
        QE_MA: 149.024,
        VE_MA: 1.17861,
        QA_01: 330.72,
        VA_01: 1.63553,
        QC_01: 257.617,
        VC_01: 1.47214,
        QE_01: 257.617,
        VE_01: 1.47214,
        QA_02: 343.285,
        VA_02: 1.66207,
        QC_02: 285.244,
        VC_02: 1.53682,
        QE_02: 285.244,
        VE_02: 1.53682,
        QA_03: 366.713,
        VA_03: 1.71037,
        QC_03: 307.28,
        VC_03: 1.58633,
        QE_03: 307.28,
        VE_03: 1.58633,
        QA_04: 208.742,
        VA_04: 1.34782,
        QC_04: 193.474,
        VC_04: 1.30799,
        QE_04: 193.474,
        VE_04: 1.30799,
        QA_05: 116.989,
        VA_05: 1.0727,
        QC_05: 111.177,
        VC_05: 1.05324,
        QE_05: 111.177,
        VE_05: 1.05324,
        QA_06: 74.613,
        VA_06: 0.91032,
        QC_06: 93.843,
        VC_06: 0.98912,
        QE_06: 93.843,
        VE_06: 0.98912,
        QA_07: 56.327,
        VA_07: 0.82685,
        QC_07: 85.321,
        VC_07: 0.95556,
        QE_07: 85.321,
        VE_07: 0.95556,
        QA_08: 41.016,
        VA_08: 0.74648,
        QC_08: 91.651,
        VC_08: 0.98063,
        QE_08: 91.651,
        VE_08: 0.98063,
        QA_09: 30.867,
        VA_09: 0.6851,
        QC_09: 80.805,
        VC_09: 0.93714,
        QE_09: 80.805,
        VE_09: 0.93714,
        QA_10: 25.635,
        VA_10: 0.64972,
        QC_10: 73.416,
        VC_10: 0.90593,
        QE_10: 73.416,
        VE_10: 0.90593,
        QA_11: 27.094,
        VA_11: 0.6599,
        QC_11: 47.334,
        VC_11: 0.7817,
        QE_11: 47.334,
        VE_11: 0.7817,
        QA_12: 172.407,
        VA_12: 1.24745,
        QC_12: 143.248,
        VC_12: 1.16054,
        QE_12: 143.248,
        VE_12: 1.16054,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.808246718342744, 34.71574161278096, 0.0],
          [-80.809081785008118, 34.712352012786141, 0.0],
          [-80.81438271833315, 34.708827079458331, 0.0],
          [-80.815606318331334, 34.704750412798091, 0.0],
          [-80.813332385001559, 34.704062212799045, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286766.0,
        COMID: 9734796,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.031,
        REACHCODE: "03050103000051",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010019676873712999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059520.0,
        ToNode: 250059537.0,
        Hydroseq: 250018331.0,
        LevelPathI: 250016109.0,
        Pathlength: 399.803,
        TerminalPa: 250002604.0,
        ArbolateSu: 406.76,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250018602.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018074.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4401,
        TotDASqKM: 378.9657,
        DivDASqKM: 378.9657,
        Tidal: 0,
        TOTMA: 0.051264045769899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12784.0,
        MAXELEVSMO: 12869.0,
        MINELEVSMO: 12869.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.031000018119812,
        QA_MA: 148.538,
        VA_MA: 0.76379,
        QC_MA: 148.158,
        VC_MA: 0.76369,
        QE_MA: 148.158,
        VE_MA: 0.76369,
        QA_01: 328.724,
        VA_01: 1.00275,
        QC_01: 256.104,
        VC_01: 0.91724,
        QE_01: 256.104,
        VE_01: 0.91724,
        QA_02: 341.194,
        VA_02: 1.01661,
        QC_02: 283.554,
        VC_02: 0.95107,
        QE_02: 283.554,
        VE_02: 0.95107,
        QA_03: 364.462,
        VA_03: 1.04186,
        QC_03: 305.457,
        VC_03: 0.97697,
        QE_03: 305.457,
        VE_03: 0.97697,
        QA_04: 207.466,
        VA_04: 0.85219,
        QC_04: 192.318,
        VC_04: 0.83134,
        QE_04: 192.318,
        VE_04: 0.83134,
        QA_05: 116.263,
        VA_05: 0.70824,
        QC_05: 110.513,
        VC_05: 0.69806,
        QE_05: 110.513,
        VE_05: 0.69806,
        QA_06: 74.164,
        VA_06: 0.62332,
        QC_06: 93.286,
        VC_06: 0.66453,
        QE_06: 93.286,
        VE_06: 0.66453,
        QA_07: 55.979,
        VA_07: 0.57962,
        QC_07: 84.805,
        VC_07: 0.64695,
        QE_07: 84.805,
        VE_07: 0.64695,
        QA_08: 40.762,
        VA_08: 0.53758,
        QC_08: 91.074,
        VC_08: 0.66002,
        QE_08: 91.074,
        VE_08: 0.66002,
        QA_09: 30.677,
        VA_09: 0.50547,
        QC_09: 80.365,
        VC_09: 0.63742,
        QE_09: 80.365,
        VE_09: 0.63742,
        QA_10: 25.476,
        VA_10: 0.48696,
        QC_10: 73.053,
        VC_10: 0.62117,
        QE_10: 73.053,
        VE_10: 0.62117,
        QA_11: 26.967,
        VA_11: 0.49243,
        QC_11: 47.165,
        VC_11: 0.55632,
        QE_11: 47.165,
        VE_11: 0.55632,
        QA_12: 171.405,
        VA_12: 0.79976,
        QC_12: 142.49,
        VC_12: 0.75436,
        QE_12: 142.49,
        VE_12: 0.75436,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.807548185010546, 34.721628079438517, 0.0],
          [-80.80843751834243, 34.719841879441226, 0.0],
          [-80.809935318340138, 34.720117479440887, 0.0],
          [-80.808246718342744, 34.71574161278096, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286759.0,
        COMID: 9734956,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.46,
        REACHCODE: "03050103000045",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014931303127416,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059569.0,
        ToNode: 250060017.0,
        Hydroseq: 250016929.0,
        LevelPathI: 250016109.0,
        Pathlength: 391.065,
        TerminalPa: 250002604.0,
        ArbolateSu: 450.318,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250017142.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016715.0,
        FromMeas: 62.53987,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1637,
        TotDASqKM: 421.4169,
        DivDASqKM: 421.4169,
        Tidal: 0,
        TOTMA: 0.071570733803600003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12637.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4600000381469731,
        QA_MA: 164.449,
        VA_MA: 0.77486,
        QC_MA: 163.381,
        VC_MA: 0.77462,
        QE_MA: 163.381,
        VE_MA: 0.77462,
        QA_01: 363.843,
        VA_01: 1.01954,
        QC_01: 282.711,
        VC_01: 0.9322,
        QE_01: 282.711,
        VE_01: 0.9322,
        QA_02: 378.345,
        VA_02: 1.03446,
        QC_02: 313.544,
        VC_02: 0.96745,
        QE_02: 313.544,
        VE_02: 0.96745,
        QA_03: 404.51,
        VA_03: 1.0607,
        QC_03: 337.856,
        VC_03: 0.99411,
        QE_03: 337.856,
        VE_03: 0.99411,
        QA_04: 229.894,
        VA_04: 0.86569,
        QC_04: 212.612,
        VC_04: 0.8446,
        QE_04: 212.612,
        VE_04: 0.8446,
        QA_05: 128.84,
        VA_05: 0.71817,
        QC_05: 121.998,
        VC_05: 0.70764,
        QE_05: 121.998,
        VE_05: 0.70764,
        QA_06: 82.16,
        VA_06: 0.63109,
        QC_06: 103.198,
        VC_06: 0.67369,
        QE_06: 103.198,
        VE_06: 0.67369,
        QA_07: 61.813,
        VA_07: 0.58584,
        QC_07: 93.422,
        VC_07: 0.65489,
        QE_07: 93.422,
        VE_07: 0.65489,
        QA_08: 45.328,
        VA_08: 0.5437,
        QC_08: 101.491,
        VC_08: 0.67047,
        QE_08: 101.491,
        VE_08: 0.67047,
        QA_09: 34.102,
        VA_09: 0.5107,
        QC_09: 88.236,
        VC_09: 0.64454,
        QE_09: 88.236,
        VE_09: 0.64454,
        QA_10: 28.33,
        VA_10: 0.49173,
        QC_10: 79.519,
        VC_10: 0.62647,
        QE_10: 79.519,
        VE_10: 0.62647,
        QA_11: 29.25,
        VA_11: 0.49487,
        QC_11: 50.181,
        VC_11: 0.55749,
        QE_11: 50.181,
        VE_11: 0.55749,
        QA_12: 188.195,
        VA_12: 0.8095,
        QC_12: 155.136,
        VC_12: 0.76197,
        QE_12: 155.136,
        VE_12: 0.76197,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.841711718290753, 34.692527879483578, 0.0],
          [-80.846843518282753, 34.689413679488439, 0.0],
          [-80.849755184945025, 34.689437279488459, 0.0],
          [-80.851612184942155, 34.692598679483581, 0.0],
          [-80.849670718278446, 34.693308479482425, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286760.0,
        COMID: 9736530,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 0.805,
        REACHCODE: "03050103000045",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0075782542805980001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060017.0,
        ToNode: 250060013.0,
        Hydroseq: 250016715.0,
        LevelPathI: 250016109.0,
        Pathlength: 390.26,
        TerminalPa: 250002604.0,
        ArbolateSu: 451.123,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250016929.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016508.0,
        FromMeas: 42.02107,
        ToMeas: 62.53987,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4896,
        TotDASqKM: 421.9065,
        DivDASqKM: 421.9065,
        Tidal: 0,
        TOTMA: 0.0394558327254,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12636.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.80500000715255704,
        QA_MA: 164.634,
        VA_MA: 0.77499,
        QC_MA: 163.557,
        VC_MA: 0.77474,
        QE_MA: 163.557,
        VE_MA: 0.77474,
        QA_01: 364.248,
        VA_01: 1.01973,
        QC_01: 283.018,
        VC_01: 0.93236,
        QE_01: 283.018,
        VE_01: 0.93236,
        QA_02: 378.773,
        VA_02: 1.03465,
        QC_02: 313.889,
        VC_02: 0.96763,
        QE_02: 313.889,
        VE_02: 0.96763,
        QA_03: 404.974,
        VA_03: 1.06091,
        QC_03: 338.231,
        VC_03: 0.9943,
        QE_03: 338.231,
        VE_03: 0.9943,
        QA_04: 230.155,
        VA_04: 0.86584,
        QC_04: 212.847,
        VC_04: 0.84475,
        QE_04: 212.847,
        VE_04: 0.84475,
        QA_05: 128.985,
        VA_05: 0.71828,
        QC_05: 122.13,
        VC_05: 0.70774,
        QE_05: 122.13,
        VE_05: 0.70774,
        QA_06: 82.252,
        VA_06: 0.63118,
        QC_06: 103.313,
        VC_06: 0.67379,
        QE_06: 103.313,
        VE_06: 0.67379,
        QA_07: 61.879,
        VA_07: 0.5859,
        QC_07: 93.52,
        VC_07: 0.65497,
        QE_07: 93.52,
        VE_07: 0.65497,
        QA_08: 45.381,
        VA_08: 0.54377,
        QC_08: 101.611,
        VC_08: 0.67058,
        QE_08: 101.611,
        VE_08: 0.67058,
        QA_09: 34.141,
        VA_09: 0.51076,
        QC_09: 88.326,
        VC_09: 0.64461,
        QE_09: 88.326,
        VE_09: 0.64461,
        QA_10: 28.363,
        VA_10: 0.49178,
        QC_10: 79.593,
        VC_10: 0.62653,
        QE_10: 79.593,
        VE_10: 0.62653,
        QA_11: 29.276,
        VA_11: 0.49489,
        QC_11: 50.216,
        VC_11: 0.55751,
        QE_11: 50.216,
        VE_11: 0.55751,
        QA_12: 188.39,
        VA_12: 0.80961,
        QC_12: 155.282,
        VC_12: 0.76206,
        QE_12: 155.282,
        VE_12: 0.76206,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.849670718278446, 34.693308479482425, 0.0],
          [-80.847320318282144, 34.695705679478806, 0.0],
          [-80.848053318280961, 34.6996046794726, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288763.0,
        COMID: 9736794,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.921,
        REACHCODE: "03050103017724",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0083452735811199996,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060082.0,
        ToNode: 250059995.0,
        Hydroseq: 250005404.0,
        LevelPathI: 250003258.0,
        Pathlength: 394.312,
        TerminalPa: 250002604.0,
        ArbolateSu: 7027.087,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005413.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005393.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6273,
        TotDASqKM: 9366.057,
        DivDASqKM: 9366.057,
        Tidal: 0,
        TOTMA: 0.027109470257500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12660.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.92100000381469704,
        QA_MA: 4692.942,
        VA_MA: 1.27904,
        QC_MA: 4692.942,
        VC_MA: 1.27904,
        QE_MA: 5394.777,
        VE_MA: 1.29006,
        QA_01: 8563.056,
        VA_01: 1.64422,
        QC_01: 8563.056,
        VC_01: 1.64422,
        QE_01: 7127.652,
        VE_01: 1.44654,
        QA_02: 9052.978,
        VA_02: 1.68427,
        QC_02: 9052.978,
        VC_02: 1.68427,
        QE_02: 7241.805,
        VE_02: 1.45619,
        QA_03: 9945.638,
        VA_03: 1.75472,
        QC_03: 9945.638,
        VC_03: 1.75472,
        QE_03: 7709.924,
        VE_03: 1.49501,
        QA_04: 6884.844,
        VA_04: 1.49811,
        QC_04: 6884.844,
        VC_04: 1.49811,
        QE_04: 6663.614,
        VE_04: 1.40658,
        QA_05: 4723.593,
        VA_05: 1.2824,
        QC_05: 4723.593,
        VC_05: 1.2824,
        QE_05: 5709.185,
        VE_05: 1.32003,
        QA_06: 2783.89,
        VA_06: 1.04398,
        QC_06: 2783.89,
        VC_06: 1.04398,
        QE_06: 5022.851,
        VE_06: 1.25352,
        QA_07: 1725.148,
        VA_07: 0.87892,
        QC_07: 1725.148,
        VC_07: 0.87892,
        QE_07: 3816.76,
        VE_07: 1.12529,
        QA_08: 1226.707,
        VA_08: 0.78443,
        QC_08: 1226.707,
        VC_08: 0.78443,
        QE_08: 3564.543,
        VE_08: 1.09616,
        QA_09: 1122.839,
        VA_09: 0.76258,
        QC_09: 1122.839,
        VC_09: 0.76258,
        QE_09: 3267.232,
        VE_09: 1.06056,
        QA_10: 1216.233,
        VA_10: 0.78227,
        QC_10: 1216.233,
        VC_10: 0.78227,
        QE_10: 4459.553,
        VE_10: 1.19567,
        QA_11: 3145.59,
        VA_11: 1.09327,
        QC_11: 3145.59,
        VC_11: 1.09327,
        QE_11: 4522.099,
        VE_11: 1.20226,
        QA_12: 5956.776,
        VA_12: 1.41007,
        QC_12: 5956.776,
        VC_12: 1.41007,
        QE_12: 5665.457,
        VE_12: 1.31591,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877735118234853, 34.749928812727831, 0.0],
          [-80.877034318235928, 34.741924612740377, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286761.0,
        COMID: 9734934,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 2.315,
        REACHCODE: "03050103000046",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.022462169591343999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059559.0,
        ToNode: 250059569.0,
        Hydroseq: 250017142.0,
        LevelPathI: 250016109.0,
        Pathlength: 392.525,
        TerminalPa: 250002604.0,
        ArbolateSu: 424.387,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250017364.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016929.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2078,
        TotDASqKM: 391.8231,
        DivDASqKM: 391.8231,
        Tidal: 0,
        TOTMA: 0.082871496263500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12649.0,
        MAXELEVSMO: 12701.0,
        MINELEVSMO: 12649.0,
        SLOPE: 0.00022462,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.315000057220459,
        QA_MA: 153.427,
        VA_MA: 1.061,
        QC_MA: 152.841,
        VC_MA: 1.06076,
        QE_MA: 152.841,
        VE_MA: 1.06076,
        QA_01: 339.536,
        VA_01: 1.45589,
        QC_01: 264.302,
        VC_01: 1.31471,
        QE_01: 264.302,
        VE_01: 1.31471,
        QA_02: 352.569,
        VA_02: 1.47907,
        QC_02: 292.745,
        VC_02: 1.37083,
        QE_02: 292.745,
        VE_02: 1.37083,
        QA_03: 376.703,
        VA_03: 1.52096,
        QC_03: 315.372,
        VC_03: 1.41368,
        QE_03: 315.372,
        VE_03: 1.41368,
        QA_04: 214.389,
        VA_04: 1.20731,
        QC_04: 198.587,
        VC_04: 1.17299,
        QE_04: 198.587,
        VE_04: 1.17299,
        QA_05: 120.117,
        VA_05: 0.96927,
        QC_05: 114.037,
        VC_05: 0.9524,
        QE_05: 114.037,
        VE_05: 0.9524,
        QA_06: 76.59,
        VA_06: 0.8288,
        QC_06: 96.294,
        VC_06: 0.89708,
        QE_06: 96.294,
        VE_06: 0.89708,
        QA_07: 57.781,
        VA_07: 0.75647,
        QC_07: 87.469,
        VC_07: 0.86779,
        QE_07: 87.469,
        VE_07: 0.86779,
        QA_08: 42.145,
        VA_08: 0.68735,
        QC_08: 94.226,
        VC_08: 0.89034,
        QE_08: 94.226,
        VE_08: 0.89034,
        QA_09: 31.714,
        VA_09: 0.63424,
        QC_09: 82.759,
        VC_09: 0.85159,
        QE_09: 82.759,
        VE_09: 0.85159,
        QA_10: 26.341,
        VA_10: 0.60365,
        QC_10: 75.026,
        VC_10: 0.82402,
        QE_10: 75.026,
        VE_10: 0.82402,
        QA_11: 27.658,
        VA_11: 0.6114,
        QC_11: 48.085,
        VC_11: 0.71553,
        QE_11: 48.085,
        VE_11: 0.71553,
        QA_12: 176.751,
        VA_12: 1.11973,
        QC_12: 146.529,
        VC_12: 1.0441,
        QE_12: 146.529,
        VE_12: 1.0441,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.834661984968434, 34.703497812799981, 0.0],
          [-80.838075584963121, 34.699215479473253, 0.0],
          [-80.836884718298279, 34.695641679478854, 0.0],
          [-80.842402984956379, 34.697200879476384, 0.0],
          [-80.840712118292345, 34.695322079479183, 0.0],
          [-80.841711718290753, 34.692527879483578, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286762.0,
        COMID: 9734868,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 1.235,
        REACHCODE: "03050103000047",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012435492338695,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059539.0,
        ToNode: 250059559.0,
        Hydroseq: 250017364.0,
        LevelPathI: 250016109.0,
        Pathlength: 394.84,
        TerminalPa: 250002604.0,
        ArbolateSu: 420.643,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250017593.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017142.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6444,
        TotDASqKM: 389.4507,
        DivDASqKM: 389.4507,
        Tidal: 0,
        TOTMA: 0.061183142306999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12701.0,
        MAXELEVSMO: 12701.0,
        MINELEVSMO: 12701.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.235000014305115,
        QA_MA: 152.526,
        VA_MA: 0.76662,
        QC_MA: 151.979,
        VC_MA: 0.76649,
        QE_MA: 151.979,
        VE_MA: 0.76649,
        QA_01: 337.552,
        VA_01: 1.00707,
        QC_01: 262.798,
        VC_01: 0.92109,
        QE_01: 262.798,
        VE_01: 0.92109,
        QA_02: 350.467,
        VA_02: 1.02114,
        QC_02: 291.047,
        VC_02: 0.95523,
        QE_02: 291.047,
        VE_02: 0.95523,
        QA_03: 374.44,
        VA_03: 1.04663,
        QC_03: 313.54,
        VC_03: 0.98131,
        QE_03: 313.54,
        VE_03: 0.98131,
        QA_04: 213.113,
        VA_04: 0.85568,
        QC_04: 197.432,
        VC_04: 0.83477,
        QE_04: 197.432,
        VE_04: 0.83477,
        QA_05: 119.416,
        VA_05: 0.71078,
        QC_05: 113.396,
        VC_05: 0.70051,
        QE_05: 113.396,
        VE_05: 0.70051,
        QA_06: 76.143,
        VA_06: 0.62526,
        QC_06: 95.74,
        VC_06: 0.66681,
        QE_06: 95.74,
        VE_06: 0.66681,
        QA_07: 57.462,
        VA_07: 0.58126,
        QC_07: 86.998,
        VC_07: 0.64904,
        QE_07: 86.998,
        VE_07: 0.64904,
        QA_08: 41.89,
        VA_08: 0.5391,
        QC_08: 93.645,
        VC_08: 0.66262,
        QE_08: 93.645,
        VE_08: 0.66262,
        QA_09: 31.523,
        VA_09: 0.50677,
        QC_09: 82.318,
        VC_09: 0.63918,
        QE_09: 82.318,
        VE_09: 0.63918,
        QA_10: 26.181,
        VA_10: 0.48814,
        QC_10: 74.663,
        VC_10: 0.62248,
        QE_10: 74.663,
        VE_10: 0.62248,
        QA_11: 27.531,
        VA_11: 0.49301,
        QC_11: 47.916,
        VC_11: 0.55657,
        QE_11: 47.916,
        VE_11: 0.55657,
        QA_12: 175.766,
        VA_12: 0.80246,
        QC_12: 145.785,
        VC_12: 0.75648,
        QE_12: 145.785,
        VE_12: 0.75648,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.826867918313852, 34.703678479466312, 0.0],
          [-80.827171784980067, 34.705854679463016, 0.0],
          [-80.834661984968434, 34.703497812799981, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286771.0,
        COMID: 9734898,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220405",
        GNIS_NAME: "Bear Creek",
        LENGTHKM: 1.354,
        REACHCODE: "03050103000056",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013077684623414999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059546.0,
        ToNode: 250059547.0,
        Hydroseq: 250026285.0,
        LevelPathI: 250023658.0,
        Pathlength: 407.214,
        TerminalPa: 250002604.0,
        ArbolateSu: 91.01,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023658.0,
        UpHydroseq: 250027083.0,
        DnLevelPat: 250023658.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025544.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5823,
        TotDASqKM: 83.4966,
        DivDASqKM: 83.4966,
        Tidal: 0,
        TOTMA: 0.057164633531099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13525.0,
        MAXELEVSMO: 13904.0,
        MINELEVSMO: 13843.0,
        SLOPE: 0.00045051,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.353999972343445,
        QA_MA: 31.996,
        VA_MA: 0.8967,
        QC_MA: 33.874,
        VC_MA: 0.89942,
        QE_MA: 33.874,
        VE_MA: 0.89942,
        QA_01: 71.256,
        VA_01: 1.2084,
        QC_01: 57.784,
        VC_01: 1.09322,
        QE_01: 57.784,
        VE_01: 1.09322,
        QA_02: 74.013,
        VA_02: 1.22673,
        QC_02: 64.126,
        VC_02: 1.13784,
        QE_02: 64.126,
        VE_02: 1.13784,
        QA_03: 79.151,
        VA_03: 1.26005,
        QC_03: 69.766,
        VC_03: 1.17581,
        QE_03: 69.766,
        VE_03: 1.17581,
        QA_04: 44.868,
        VA_04: 1.0124,
        QC_04: 43.065,
        VC_04: 0.97977,
        QE_04: 43.065,
        VE_04: 0.97977,
        QA_05: 25.143,
        VA_05: 0.82602,
        QC_05: 25.308,
        VC_05: 0.81466,
        QE_05: 25.308,
        VE_05: 0.81466,
        QA_06: 15.717,
        VA_06: 0.71173,
        QC_06: 20.195,
        VC_06: 0.75748,
        QE_06: 20.195,
        VE_06: 0.75748,
        QA_07: 12.349,
        VA_07: 0.66325,
        QC_07: 19.397,
        VC_07: 0.74797,
        QE_07: 19.397,
        VE_07: 0.74797,
        QA_08: 8.981,
        VA_08: 0.60804,
        QC_08: 19.467,
        VC_08: 0.74881,
        QE_08: 19.467,
        VE_08: 0.74881,
        QA_09: 6.736,
        VA_09: 0.5656,
        QC_09: 21.074,
        VC_09: 0.76776,
        QE_09: 21.074,
        VE_09: 0.76776,
        QA_10: 5.613,
        VA_10: 0.54185,
        QC_10: 21.821,
        VC_10: 0.77633,
        QE_10: 21.821,
        VE_10: 0.77633,
        QA_11: 4.849,
        VA_11: 0.5244,
        QC_11: 12.745,
        VC_11: 0.6601,
        QE_11: 12.745,
        VE_11: 0.6601,
        QA_12: 35.483,
        VA_12: 0.92992,
        QC_12: 33.999,
        VC_12: 0.90058,
        QE_12: 33.999,
        VE_12: 0.90058,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.757339585088474, 34.699257812806536, 0.0],
          [-80.759607718418238, 34.703589212799898, 0.0],
          [-80.762465318413717, 34.702927479467576, 0.0],
          [-80.764773318410164, 34.698646079474088, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288759.0,
        COMID: 9736478,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.346,
        REACHCODE: "03050103017720",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0032238373038050001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059995.0,
        ToNode: 250094165.0,
        Hydroseq: 250005393.0,
        LevelPathI: 250003258.0,
        Pathlength: 393.966,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.497,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005404.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005378.0,
        DnDrainCou: 2,
        DnHydroseq: 250005377.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9297,
        TotDASqKM: 9368.2134,
        DivDASqKM: 9368.2134,
        Tidal: 0,
        TOTMA: 0.010184131881199999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12658.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.345999985933304,
        QA_MA: 4693.782,
        VA_MA: 1.27909,
        QC_MA: 4693.782,
        VC_MA: 1.27909,
        QE_MA: 5395.618,
        VE_MA: 1.2901,
        QA_01: 8564.898,
        VA_01: 1.64431,
        QC_01: 8564.898,
        VC_01: 1.64431,
        QE_01: 7129.49,
        VE_01: 1.44666,
        QA_02: 9054.917,
        VA_02: 1.68436,
        QC_02: 9054.917,
        VC_02: 1.68436,
        QE_02: 7243.74,
        VE_02: 1.45631,
        QA_03: 9947.725,
        VA_03: 1.75481,
        QC_03: 9947.725,
        VC_03: 1.75481,
        QE_03: 7712.005,
        VE_03: 1.49513,
        QA_04: 6886.033,
        VA_04: 1.49817,
        QC_04: 6886.033,
        VC_04: 1.49817,
        QE_04: 6664.801,
        VE_04: 1.40664,
        QA_05: 4724.259,
        VA_05: 1.28243,
        QC_05: 4724.259,
        VC_05: 1.28243,
        QE_05: 5709.851,
        VE_05: 1.32006,
        QA_06: 2784.325,
        VA_06: 1.04401,
        QC_06: 2784.325,
        VC_06: 1.04401,
        QE_06: 5023.29,
        VE_06: 1.25353,
        QA_07: 1725.453,
        VA_07: 0.87895,
        QC_07: 1725.453,
        VC_07: 0.87895,
        QE_07: 3817.069,
        VE_07: 1.12529,
        QA_08: 1226.939,
        VA_08: 0.78446,
        QC_08: 1226.939,
        VC_08: 0.78446,
        QE_08: 3564.779,
        VE_08: 1.09616,
        QA_09: 1123.013,
        VA_09: 0.7626,
        QC_09: 1123.013,
        VC_09: 0.7626,
        QE_09: 3267.41,
        VE_09: 1.06056,
        QA_10: 1216.378,
        VA_10: 0.78228,
        QC_10: 1216.378,
        VC_10: 0.78228,
        QE_10: 4459.705,
        VE_10: 1.19565,
        QA_11: 3145.735,
        VA_11: 1.09326,
        QC_11: 3145.735,
        VC_11: 1.09326,
        QE_11: 4522.247,
        VE_11: 1.20224,
        QA_12: 5957.72,
        VA_12: 1.41011,
        QC_12: 5957.72,
        VC_12: 1.41011,
        QE_12: 5666.399,
        VE_12: 1.31597,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877034318235928, 34.741924612740377, 0.0],
          [-80.875650184904771, 34.739102679411417, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288760.0,
        COMID: 9736492,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.869,
        REACHCODE: "03050103017721",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0081963245916880003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060001.0,
        ToNode: 250060004.0,
        Hydroseq: 250005272.0,
        LevelPathI: 250003258.0,
        Pathlength: 391.808,
        TerminalPa: 250002604.0,
        ArbolateSu: 7037.013,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005281.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005258.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4428,
        TotDASqKM: 9375.111,
        DivDASqKM: 9375.111,
        Tidal: 0,
        TOTMA: 0.025575093273300001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12659.0,
        MAXELEVSMO: 12676.0,
        MINELEVSMO: 12676.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.86900001764297496,
        QA_MA: 4696.43,
        VA_MA: 1.27924,
        QC_MA: 4696.43,
        VC_MA: 1.27924,
        QE_MA: 5398.269,
        VE_MA: 1.29025,
        QA_01: 8570.727,
        VA_01: 1.6446,
        QC_01: 8570.727,
        VC_01: 1.6446,
        QE_01: 7135.306,
        VE_01: 1.44703,
        QA_02: 9061.06,
        VA_02: 1.68466,
        QC_02: 9061.06,
        VC_02: 1.68466,
        QE_02: 7249.87,
        VE_02: 1.4567,
        QA_03: 9954.337,
        VA_03: 1.75511,
        QC_03: 9954.337,
        VC_03: 1.75511,
        QE_03: 7718.596,
        VE_03: 1.49554,
        QA_04: 6889.809,
        VA_04: 1.49834,
        QC_04: 6889.809,
        VC_04: 1.49834,
        QE_04: 6668.571,
        VE_04: 1.40685,
        QA_05: 4726.372,
        VA_05: 1.28252,
        QC_05: 4726.372,
        VC_05: 1.28252,
        QE_05: 5711.965,
        VE_05: 1.32015,
        QA_06: 2785.69,
        VA_06: 1.04409,
        QC_06: 2785.69,
        VC_06: 1.04409,
        QE_06: 5024.667,
        VE_06: 1.25356,
        QA_07: 1726.395,
        VA_07: 0.87903,
        QC_07: 1726.395,
        VC_07: 0.87903,
        QE_07: 3818.022,
        VE_07: 1.12531,
        QA_08: 1227.681,
        VA_08: 0.78454,
        QC_08: 1227.681,
        VC_08: 0.78454,
        QE_08: 3565.537,
        VE_08: 1.09616,
        QA_09: 1123.569,
        VA_09: 0.76265,
        QC_09: 1123.569,
        VC_09: 0.76265,
        QE_09: 3267.979,
        VE_09: 1.06054,
        QA_10: 1216.842,
        VA_10: 0.7823,
        QC_10: 1216.842,
        VC_10: 0.7823,
        QE_10: 4460.19,
        VE_10: 1.19561,
        QA_11: 3146.132,
        VA_11: 1.0932,
        QC_11: 3146.132,
        VC_11: 1.0932,
        QE_11: 4522.656,
        VE_11: 1.20218,
        QA_12: 5960.65,
        VA_12: 1.41024,
        QC_12: 5960.65,
        VC_12: 1.41024,
        QE_12: 5669.323,
        VE_12: 1.31613,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872118784910299, 34.72841661276118, 0.0],
          [-80.869081718248253, 34.725168279433035, 0.0],
          [-80.868363518249396, 34.721611212771904, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288761.0,
        COMID: 9736500,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.642,
        REACHCODE: "03050103017722",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0057988152264010001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060004.0,
        ToNode: 250060007.0,
        Hydroseq: 250005258.0,
        LevelPathI: 250003258.0,
        Pathlength: 391.166,
        TerminalPa: 250002604.0,
        ArbolateSu: 7042.576,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005272.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005246.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1953,
        TotDASqKM: 9379.8756,
        DivDASqKM: 9379.8756,
        Tidal: 0,
        TOTMA: 0.018892908614899999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12658.0,
        MAXELEVSMO: 12676.0,
        MINELEVSMO: 12676.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.64200001955032304,
        QA_MA: 4698.276,
        VA_MA: 1.27934,
        QC_MA: 4698.276,
        VC_MA: 1.27934,
        QE_MA: 5400.117,
        VE_MA: 1.29035,
        QA_01: 8574.771,
        VA_01: 1.6448,
        QC_01: 8574.771,
        VC_01: 1.6448,
        QE_01: 7139.341,
        VE_01: 1.44728,
        QA_02: 9065.323,
        VA_02: 1.68486,
        QC_02: 9065.323,
        VC_02: 1.68486,
        QE_02: 7254.123,
        VE_02: 1.45696,
        QA_03: 9958.925,
        VA_03: 1.75532,
        QC_03: 9958.925,
        VC_03: 1.75532,
        QE_03: 7723.17,
        VE_03: 1.49582,
        QA_04: 6892.433,
        VA_04: 1.49846,
        QC_04: 6892.433,
        VC_04: 1.49846,
        QE_04: 6671.193,
        VE_04: 1.40699,
        QA_05: 4727.844,
        VA_05: 1.28258,
        QC_05: 4727.844,
        VC_05: 1.28258,
        QE_05: 5713.437,
        VE_05: 1.32021,
        QA_06: 2786.649,
        VA_06: 1.04415,
        QC_06: 2786.649,
        VC_06: 1.04415,
        QE_06: 5025.635,
        VE_06: 1.25358,
        QA_07: 1727.065,
        VA_07: 0.87909,
        QC_07: 1727.065,
        VC_07: 0.87909,
        QE_07: 3818.7,
        VE_07: 1.12532,
        QA_08: 1228.193,
        VA_08: 0.7846,
        QC_08: 1228.193,
        VC_08: 0.7846,
        QE_08: 3566.061,
        VE_08: 1.09616,
        QA_09: 1123.954,
        VA_09: 0.76269,
        QC_09: 1123.954,
        VC_09: 0.76269,
        QE_09: 3268.373,
        VE_09: 1.06053,
        QA_10: 1217.162,
        VA_10: 0.78232,
        QC_10: 1217.162,
        VC_10: 0.78232,
        QE_10: 4460.525,
        VE_10: 1.19557,
        QA_11: 3146.406,
        VA_11: 1.09315,
        QC_11: 3146.406,
        VC_11: 1.09315,
        QE_11: 4522.937,
        VE_11: 1.20214,
        QA_12: 5962.708,
        VA_12: 1.41033,
        QC_12: 5962.708,
        VC_12: 1.41033,
        QE_12: 5671.377,
        VE_12: 1.31624,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.868363518249396, 34.721611212771904, 0.0],
          [-80.868390318249396, 34.715978012780624, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283724.0,
        COMID: 9754104,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.234,
        REACHCODE: "03050101000129",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19488978,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013250407811222,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061526.0,
        ToNode: 250061537.0,
        Hydroseq: 250010788.0,
        LevelPathI: 250003258.0,
        Pathlength: 602.893,
        TerminalPa: 250002604.0,
        ArbolateSu: 2610.887,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010859.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010722.0,
        FromMeas: 30.84939,
        ToMeas: 62.40065,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5102,
        TotDASqKM: 2831.1552,
        DivDASqKM: 2831.1552,
        Tidal: 0,
        TOTMA: 0.0343654281807,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28408.0,
        MAXELEVSMO: 28423.0,
        MINELEVSMO: 28413.0,
        SLOPE: 8.103e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.233999967575073,
        QA_MA: 1752.689,
        VA_MA: 1.35456,
        QC_MA: 1752.689,
        VC_MA: 1.35456,
        QE_MA: 1947.733,
        VE_MA: 1.36353,
        QA_01: 2787.866,
        VA_01: 1.64693,
        QC_01: 2053.639,
        VC_01: 1.44639,
        QE_01: 2258.913,
        VE_01: 1.44993,
        QA_02: 2953.546,
        VA_02: 1.6886,
        QC_02: 2953.546,
        VC_02: 1.6886,
        QE_02: 3025.369,
        VE_02: 1.6415,
        QA_03: 3360.758,
        VA_03: 1.78658,
        QC_03: 3360.758,
        VC_03: 1.78658,
        QE_03: 3624.878,
        VE_03: 1.77582,
        QA_04: 2579.447,
        VA_04: 1.59283,
        QC_04: 2579.447,
        VC_04: 1.59283,
        QE_04: 2771.573,
        VE_04: 1.58089,
        QA_05: 1989.611,
        VA_05: 1.42741,
        QC_05: 1989.611,
        VC_05: 1.42741,
        QE_05: 2211.733,
        VE_05: 1.43721,
        QA_06: 1160.782,
        VA_06: 1.14896,
        QC_06: 1160.782,
        VC_06: 1.14896,
        QE_06: 1420.395,
        VE_06: 1.20067,
        QA_07: 687.04,
        VA_07: 0.94464,
        QC_07: 687.04,
        VC_07: 0.94464,
        QE_07: 833.934,
        VE_07: 0.98089,
        QA_08: 489.169,
        VA_08: 0.83963,
        QC_08: 489.169,
        VC_08: 0.83963,
        QE_08: 788.786,
        VE_08: 0.9613,
        QA_09: 493.912,
        VA_09: 0.84236,
        QC_09: 934.486,
        VC_09: 1.05754,
        QE_09: 970.993,
        VE_09: 1.03749,
        QA_10: 649.787,
        VA_10: 0.92608,
        QC_10: 971.161,
        VC_10: 1.07301,
        QE_10: 931.658,
        VE_10: 1.02165,
        QA_11: 1674.528,
        VA_11: 1.32952,
        QC_11: 1674.528,
        VC_11: 1.32952,
        QE_11: 1651.728,
        VE_11: 1.2751,
        QA_12: 2217.352,
        VA_12: 1.4937,
        QC_12: 2217.352,
        VC_12: 1.4937,
        QE_12: 2241.064,
        VE_12: 1.44513,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.422265717389621, 35.770510011143756, 0.0],
          [-81.410021317408678, 35.765943877817506, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286767.0,
        COMID: 9734780,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220405",
        GNIS_NAME: "Bear Creek",
        LENGTHKM: 1.639,
        REACHCODE: "03050103000052",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016640707588579998,
        StreamLeve: 4,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059513.0,
        ToNode: 250059520.0,
        Hydroseq: 250023658.0,
        LevelPathI: 250023658.0,
        Pathlength: 400.834,
        TerminalPa: 250002604.0,
        ArbolateSu: 183.156,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250023658.0,
        UpHydroseq: 250024243.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018331.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2546,
        TotDASqKM: 173.5092,
        DivDASqKM: 173.5092,
        Tidal: 0,
        TOTMA: 0.059164242473500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12840.0,
        MAXELEVSMO: 12979.0,
        MINELEVSMO: 12869.0,
        SLOPE: 0.00067114,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6390000581741331,
        QA_MA: 66.87,
        VA_MA: 1.05023,
        QC_MA: 68.797,
        VC_MA: 1.05194,
        QE_MA: 68.797,
        VE_MA: 1.05194,
        QA_01: 148.782,
        VA_01: 1.44272,
        QC_01: 118.347,
        VC_01: 1.30015,
        QE_01: 118.347,
        VE_01: 1.30015,
        QA_02: 154.533,
        VA_02: 1.46579,
        QC_02: 131.229,
        VC_02: 1.35609,
        QE_02: 131.229,
        VE_02: 1.35609,
        QA_03: 165.037,
        VA_03: 1.50692,
        QC_03: 141.983,
        VC_03: 1.40083,
        QE_03: 141.983,
        VE_03: 1.40083,
        QA_04: 93.522,
        VA_04: 1.19487,
        QC_04: 88.278,
        VC_04: 1.1572,
        QE_04: 88.278,
        VE_04: 1.1572,
        QA_05: 52.728,
        VA_05: 0.9623,
        QC_05: 51.625,
        VC_05: 0.94678,
        QE_05: 51.625,
        VE_05: 0.94678,
        QA_06: 32.839,
        VA_06: 0.81692,
        QC_06: 41.771,
        VC_06: 0.87888,
        QE_06: 41.771,
        VE_06: 0.87888,
        QA_07: 25.625,
        VA_07: 0.75416,
        QC_07: 39.553,
        VC_07: 0.86258,
        QE_07: 39.553,
        VE_07: 0.86258,
        QA_08: 18.663,
        VA_08: 0.6851,
        QC_08: 41.051,
        VC_08: 0.87363,
        QE_08: 41.051,
        VE_08: 0.87363,
        QA_09: 13.997,
        VA_09: 0.63173,
        QC_09: 40.198,
        VC_09: 0.86737,
        QE_09: 40.198,
        VE_09: 0.86737,
        QA_10: 11.664,
        VA_10: 0.60189,
        QC_10: 39.139,
        VC_10: 0.8595,
        QE_10: 39.139,
        VE_10: 0.8595,
        QA_11: 10.31,
        VA_11: 0.58328,
        QC_11: 22.656,
        VC_11: 0.72064,
        QE_11: 22.656,
        VE_11: 0.72064,
        QA_12: 75.158,
        VA_12: 1.09772,
        QC_12: 67.301,
        VC_12: 1.04331,
        QE_12: 67.301,
        VE_12: 1.04331,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.795399718362717, 34.719009812775823, 0.0],
          [-80.799229185023364, 34.717798012777735, 0.0],
          [-80.799643385022819, 34.719768279441325, 0.0],
          [-80.805272318347363, 34.722749212770111, 0.0],
          [-80.80724198501099, 34.722910612769795, 0.0],
          [-80.807548185010546, 34.721628079438517, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288762.0,
        COMID: 9736508,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.798,
        REACHCODE: "03050103017723",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0072124891944669997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060007.0,
        ToNode: 250060010.0,
        Hydroseq: 250005246.0,
        LevelPathI: 250003258.0,
        Pathlength: 390.368,
        TerminalPa: 250002604.0,
        ArbolateSu: 7045.142,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005258.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005235.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.692,
        TotDASqKM: 9382.3839,
        DivDASqKM: 9382.3839,
        Tidal: 0,
        TOTMA: 0.0228478593525,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12641.0,
        MAXELEVSMO: 12676.0,
        MINELEVSMO: 12675.0,
        SLOPE: 1.253e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.79799997806549094,
        QA_MA: 4699.236,
        VA_MA: 1.31485,
        QC_MA: 4699.236,
        VC_MA: 1.31485,
        QE_MA: 5401.078,
        VE_MA: 1.32626,
        QA_01: 8576.874,
        VA_01: 1.6937,
        QC_01: 8576.874,
        VC_01: 1.6937,
        QE_01: 7141.44,
        VE_01: 1.489,
        QA_02: 9067.55,
        VA_02: 1.73523,
        QC_02: 9067.55,
        VC_02: 1.73523,
        QE_02: 7256.345,
        VE_02: 1.49905,
        QA_03: 9961.326,
        VA_03: 1.80827,
        QC_03: 9961.326,
        VC_03: 1.80827,
        QE_03: 7725.563,
        VE_03: 1.53933,
        QA_04: 6893.795,
        VA_04: 1.54198,
        QC_04: 6893.795,
        VC_04: 1.54198,
        QE_04: 6672.552,
        VE_04: 1.44718,
        QA_05: 4728.599,
        VA_05: 1.31818,
        QC_05: 4728.599,
        VC_05: 1.31818,
        QE_05: 5714.191,
        VE_05: 1.35718,
        QA_06: 2787.133,
        VA_06: 1.07105,
        QC_06: 2787.133,
        VC_06: 1.07105,
        QE_06: 5026.124,
        VE_06: 1.28811,
        QA_07: 1727.413,
        VA_07: 0.89996,
        QC_07: 1727.413,
        VC_07: 0.89996,
        QE_07: 3819.053,
        VE_07: 1.15516,
        QA_08: 1228.463,
        VA_08: 0.80202,
        QC_08: 1228.463,
        VC_08: 0.80202,
        QE_08: 3566.337,
        VE_08: 1.12493,
        QA_09: 1124.156,
        VA_09: 0.7793,
        QC_09: 1124.156,
        VC_09: 0.7793,
        QE_09: 3268.58,
        VE_09: 1.08799,
        QA_10: 1217.331,
        VA_10: 0.79964,
        QC_10: 1217.331,
        VC_10: 0.79964,
        QE_10: 4460.701,
        VE_10: 1.22795,
        QA_11: 3146.541,
        VA_11: 1.12178,
        QC_11: 3146.541,
        VC_11: 1.12178,
        QE_11: 4523.076,
        VE_11: 1.23475,
        QA_12: 5963.766,
        VA_12: 1.45061,
        QC_12: 5963.766,
        VC_12: 1.45061,
        QE_12: 5672.433,
        VE_12: 1.3531,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.868390318249396, 34.715978012780624, 0.0],
          [-80.866927984918334, 34.708996079458075, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283725.0,
        COMID: 9752730,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 4.217,
        REACHCODE: "03050101000143",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.041081645795452003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061126.0,
        ToNode: 250061545.0,
        Hydroseq: 250021302.0,
        LevelPathI: 250020920.0,
        Pathlength: 625.618,
        TerminalPa: 250002604.0,
        ArbolateSu: 231.653,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250021703.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020920.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.194,
        TotDASqKM: 254.4255,
        DivDASqKM: 254.4255,
        Tidal: 0,
        TOTMA: 0.14044837802,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30210.0,
        MAXELEVSMO: 30493.0,
        MINELEVSMO: 30210.0,
        SLOPE: 0.00067109,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.2170000076293954,
        QA_MA: 135.808,
        VA_MA: 1.14007,
        QC_MA: 135.932,
        VC_MA: 1.14014,
        QE_MA: 135.932,
        VE_MA: 1.14014,
        QA_01: 235.605,
        VA_01: 1.4227,
        QC_01: 185.165,
        VC_01: 1.28849,
        QE_01: 185.165,
        VE_01: 1.28849,
        QA_02: 247.738,
        VA_02: 1.45281,
        QC_02: 207.69,
        VC_02: 1.35009,
        QE_02: 207.69,
        VE_02: 1.35009,
        QA_03: 271.63,
        VA_03: 1.51015,
        QC_03: 229.873,
        VC_03: 1.40777,
        QE_03: 229.873,
        VE_03: 1.40777,
        QA_04: 208.91,
        VA_04: 1.35377,
        QC_04: 193.626,
        VC_04: 1.31202,
        QE_04: 193.626,
        VE_04: 1.31202,
        QA_05: 149.894,
        VA_05: 1.18482,
        QC_05: 141.132,
        VC_05: 1.15688,
        QE_05: 141.132,
        VE_05: 1.15688,
        QA_06: 84.318,
        VA_06: 0.9541,
        QC_06: 105.871,
        VC_06: 1.03676,
        QE_06: 105.871,
        VE_06: 1.03676,
        QA_07: 51.067,
        VA_07: 0.80315,
        QC_07: 77.534,
        VC_07: 0.92572,
        QE_07: 77.534,
        VE_07: 0.92572,
        QA_08: 34.318,
        VA_08: 0.70901,
        QC_08: 76.411,
        VC_08: 0.92095,
        QE_08: 76.411,
        VE_08: 0.92095,
        QA_09: 27.202,
        VA_09: 0.66251,
        QC_09: 72.271,
        VC_09: 0.9031,
        QE_09: 72.271,
        VE_09: 0.9031,
        QA_10: 27.653,
        VA_10: 0.66561,
        QC_10: 77.997,
        VC_10: 0.92767,
        QE_10: 77.997,
        VE_10: 0.92767,
        QA_11: 113.694,
        VA_11: 1.06517,
        QC_11: 141.317,
        VC_11: 1.15747,
        QE_11: 141.317,
        VE_11: 1.15747,
        QA_12: 178.398,
        VA_12: 1.2697,
        QC_12: 147.77,
        VC_12: 1.17785,
        QE_12: 147.77,
        VE_12: 1.17785,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.651832983700046, 35.805574211089265, 0.0],
          [-81.650904317034758, 35.80289501109354, 0.0],
          [-81.652224583699365, 35.800192411097669, 0.0],
          [-81.650226717035821, 35.794307277773555, 0.0],
          [-81.648708917038235, 35.794605411106318, 0.0],
          [-81.649213317037322, 35.790070811113367, 0.0],
          [-81.645980583709104, 35.788674477782138, 0.0],
          [-81.646599983708029, 35.791972077777132, 0.0],
          [-81.644042583712064, 35.793690211107673, 0.0],
          [-81.641259383716431, 35.791858611110683, 0.0],
          [-81.641736783715714, 35.789568411114097, 0.0],
          [-81.637183117056054, 35.787782677783639, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286768.0,
        COMID: 9734814,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220405",
        GNIS_NAME: "Bear Creek",
        LENGTHKM: 1.347,
        REACHCODE: "03050103000053",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013370632493719999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059527.0,
        ToNode: 250059513.0,
        Hydroseq: 250024243.0,
        LevelPathI: 250023658.0,
        Pathlength: 402.473,
        TerminalPa: 250002604.0,
        ArbolateSu: 101.932,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023658.0,
        UpHydroseq: 250024866.0,
        DnLevelPat: 250023658.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023658.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0269,
        TotDASqKM: 94.851,
        DivDASqKM: 94.851,
        Tidal: 0,
        TOTMA: 0.047790937850300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12979.0,
        MAXELEVSMO: 13229.0,
        MINELEVSMO: 12979.0,
        SLOPE: 0.00185597,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3470000028610229,
        QA_MA: 36.305,
        VA_MA: 1.06706,
        QC_MA: 38.247,
        VC_MA: 1.07027,
        QE_MA: 38.247,
        VE_MA: 1.07027,
        QA_01: 80.783,
        VA_01: 1.46849,
        QC_01: 65.295,
        VC_01: 1.3205,
        QE_01: 65.295,
        VE_01: 1.3205,
        QA_02: 84.024,
        VA_02: 1.49298,
        QC_02: 72.548,
        VC_02: 1.37872,
        QE_02: 72.548,
        VE_02: 1.37872,
        QA_03: 89.925,
        VA_03: 1.53645,
        QC_03: 78.93,
        VC_03: 1.42773,
        QE_03: 78.93,
        VE_03: 1.42773,
        QA_04: 50.942,
        VA_04: 1.21656,
        QC_04: 48.754,
        VC_04: 1.17507,
        QE_04: 48.754,
        VE_04: 1.17507,
        QA_05: 28.501,
        VA_05: 0.97556,
        QC_05: 28.554,
        VC_05: 0.96074,
        QE_05: 28.554,
        VE_05: 0.96074,
        QA_06: 17.854,
        VA_06: 0.82884,
        QC_06: 22.901,
        VC_06: 0.88863,
        QE_06: 22.901,
        VE_06: 0.88863,
        QA_07: 13.992,
        VA_07: 0.76566,
        QC_07: 21.912,
        VC_07: 0.87518,
        QE_07: 21.912,
        VE_07: 0.87518,
        QA_08: 10.202,
        VA_08: 0.69504,
        QC_08: 22.171,
        VC_08: 0.87873,
        QE_08: 22.171,
        VE_08: 0.87873,
        QA_09: 7.652,
        VA_09: 0.64028,
        QC_09: 23.585,
        VC_09: 0.89777,
        QE_09: 23.585,
        VE_09: 0.89777,
        QA_10: 6.376,
        VA_10: 0.60964,
        QC_10: 24.16,
        VC_10: 0.90535,
        QE_10: 24.16,
        VE_10: 0.90535,
        QA_11: 5.46,
        VA_11: 0.58582,
        QC_11: 13.951,
        VC_11: 0.75436,
        QE_11: 13.951,
        VE_11: 0.75436,
        QA_12: 40.135,
        VA_12: 1.10856,
        QC_12: 38.031,
        VC_12: 1.06798,
        QE_12: 38.031,
        VE_12: 1.06798,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.788112585040608, 34.710896212788441, 0.0],
          [-80.787361585041822, 34.712728279452335, 0.0],
          [-80.79318391836614, 34.71559541278112, 0.0],
          [-80.795399718362717, 34.719009812775823, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283726.0,
        COMID: 9752626,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 2.113,
        REACHCODE: "03050101000144",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020752776619332999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061098.0,
        ToNode: 250061126.0,
        Hydroseq: 250021703.0,
        LevelPathI: 250020920.0,
        Pathlength: 629.835,
        TerminalPa: 250002604.0,
        ArbolateSu: 202.425,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250022142.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021302.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9233,
        TotDASqKM: 220.2345,
        DivDASqKM: 220.2345,
        Tidal: 0,
        TOTMA: 0.068284452830600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30492.0,
        MAXELEVSMO: 30718.0,
        MINELEVSMO: 30493.0,
        SLOPE: 0.00106483,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1129999160766602,
        QA_MA: 118.157,
        VA_MA: 1.17459,
        QC_MA: 118.906,
        VC_MA: 1.17503,
        QE_MA: 118.906,
        VE_MA: 1.17503,
        QA_01: 203.775,
        VA_01: 1.4653,
        QC_01: 160.76,
        VC_01: 1.32554,
        QE_01: 160.76,
        VE_01: 1.32554,
        QA_02: 213.792,
        VA_02: 1.49516,
        QC_02: 179.953,
        VC_02: 1.38832,
        QE_02: 179.953,
        VE_02: 1.38832,
        QA_03: 234.889,
        VA_03: 1.55597,
        QC_03: 199.737,
        VC_03: 1.44982,
        QE_03: 199.737,
        VE_03: 1.44982,
        QA_04: 181.906,
        VA_04: 1.39762,
        QC_04: 169.129,
        VC_04: 1.35333,
        QE_04: 169.129,
        VE_04: 1.35333,
        QA_05: 131.033,
        VA_05: 1.22349,
        QC_05: 123.996,
        VC_05: 1.19454,
        QE_05: 123.996,
        VE_05: 1.19454,
        QA_06: 73.595,
        VA_06: 0.98204,
        QC_06: 92.579,
        VC_06: 1.06719,
        QE_06: 92.579,
        VE_06: 1.06719,
        QA_07: 44.652,
        VA_07: 0.82505,
        QC_07: 68.013,
        VC_07: 0.95257,
        QE_07: 68.013,
        VE_07: 0.95257,
        QA_08: 29.929,
        VA_08: 0.72617,
        QC_08: 66.457,
        VC_08: 0.94471,
        QE_08: 66.457,
        VE_08: 0.94471,
        QA_09: 23.711,
        VA_09: 0.67757,
        QC_09: 64.018,
        VC_09: 0.9322,
        QE_09: 64.018,
        VE_09: 0.9322,
        QA_10: 24.895,
        VA_10: 0.68725,
        QC_10: 71.718,
        VC_10: 0.97098,
        QE_10: 71.718,
        VE_10: 0.97098,
        QA_11: 100.725,
        VA_11: 1.1042,
        QC_11: 128.849,
        VC_11: 1.21279,
        QE_11: 128.849,
        VE_11: 1.21279,
        QA_12: 155.445,
        VA_12: 1.31039,
        QC_12: 130.367,
        VC_12: 1.21843,
        QE_12: 130.367,
        VE_12: 1.21843,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.641349983716282, 35.817232877737922, 0.0],
          [-81.641883583715412, 35.815011411074636, 0.0],
          [-81.644554183711307, 35.813522277743687, 0.0],
          [-81.643990517045452, 35.808598811084607, 0.0],
          [-81.648824917038041, 35.80539167775629, 0.0],
          [-81.651832983700046, 35.805574211089265, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286769.0,
        COMID: 9734854,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220405",
        GNIS_NAME: "Bear Creek",
        LENGTHKM: 1.788,
        REACHCODE: "03050103000054",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017813473561914001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059535.0,
        ToNode: 250059527.0,
        Hydroseq: 250024866.0,
        LevelPathI: 250023658.0,
        Pathlength: 403.82,
        TerminalPa: 250002604.0,
        ArbolateSu: 98.584,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023658.0,
        UpHydroseq: 250025544.0,
        DnLevelPat: 250023658.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024243.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.3895,
        TotDASqKM: 91.8846,
        DivDASqKM: 91.8846,
        Tidal: 0,
        TOTMA: 0.069188994843899995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13140.0,
        MAXELEVSMO: 13388.0,
        MINELEVSMO: 13229.0,
        SLOPE: 0.00088926,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7879999876022341,
        QA_MA: 35.18,
        VA_MA: 0.9784,
        QC_MA: 37.107,
        VC_MA: 0.9813,
        QE_MA: 37.107,
        VE_MA: 0.9813,
        QA_01: 78.3,
        VA_01: 1.33307,
        QC_01: 63.339,
        VC_01: 1.20224,
        QE_01: 63.339,
        VE_01: 1.20224,
        QA_02: 81.401,
        VA_02: 1.35442,
        QC_02: 70.344,
        VC_02: 1.25344,
        QE_02: 70.344,
        VE_02: 1.25344,
        QA_03: 87.103,
        VA_03: 1.39271,
        QC_03: 76.533,
        VC_03: 1.29672,
        QE_03: 76.533,
        VE_03: 1.29672,
        QA_04: 49.349,
        VA_04: 1.11031,
        QC_04: 47.264,
        VC_04: 1.07356,
        QE_04: 47.264,
        VE_04: 1.07356,
        QA_05: 27.624,
        VA_05: 0.89767,
        QC_05: 27.708,
        VC_05: 0.88463,
        QE_05: 27.708,
        VE_05: 0.88463,
        QA_06: 17.296,
        VA_06: 0.76796,
        QC_06: 22.194,
        VC_06: 0.82058,
        QE_06: 22.194,
        VE_06: 0.82058,
        QA_07: 13.568,
        VA_07: 0.71237,
        QC_07: 21.264,
        VC_07: 0.80907,
        QE_07: 21.264,
        VE_07: 0.80907,
        QA_08: 9.883,
        VA_08: 0.64981,
        QC_08: 21.464,
        VC_08: 0.81156,
        QE_08: 21.464,
        VE_08: 0.81156,
        QA_09: 7.412,
        VA_09: 0.60144,
        QC_09: 22.933,
        VC_09: 0.82956,
        QE_09: 22.933,
        VE_09: 0.82956,
        QA_10: 6.177,
        VA_10: 0.5744,
        QC_10: 23.555,
        VC_10: 0.83702,
        QE_10: 23.555,
        VE_10: 0.83702,
        QA_11: 5.3,
        VA_11: 0.55363,
        QC_11: 13.639,
        VC_11: 0.70389,
        QE_11: 13.639,
        VE_11: 0.70389,
        QA_12: 38.928,
        VA_12: 1.01541,
        QC_12: 36.989,
        VC_12: 0.98016,
        QE_12: 36.989,
        VE_12: 0.98016,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.776467718392041, 34.706512679461866, 0.0],
          [-80.779853385053514, 34.70500347946421, 0.0],
          [-80.781787318383863, 34.711624679454076, 0.0],
          [-80.788112585040608, 34.710896212788441, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288772.0,
        COMID: 933050104,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.433,
        REACHCODE: "03050103017745",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0047288764243939999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059965.0,
        ToNode: 250095015.0,
        Hydroseq: 250005876.0,
        LevelPathI: 250005876.0,
        Pathlength: 401.422,
        TerminalPa: 250002604.0,
        ArbolateSu: 6816.114,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005902.0,
        DnLevelPat: 250005853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005853.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0999,
        TotDASqKM: 9187.1487,
        DivDASqKM: 0.0999,
        Tidal: 0,
        TOTMA: 0.037092069621500003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13596.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13629.0,
        SLOPE: 0.00070671,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.28299999237060502,
        QA_MA: 0.039,
        VA_MA: 0.44328,
        QC_MA: 0.039,
        VC_MA: 0.44328,
        QE_MA: 0.039,
        VE_MA: 0.44328,
        QA_01: 0.087,
        VA_01: 0.51494,
        QC_01: 0.087,
        VC_01: 0.51494,
        QE_01: 0.087,
        VE_01: 0.51494,
        QA_02: 0.09,
        VA_02: 0.51869,
        QC_02: 0.09,
        VC_02: 0.51869,
        QE_02: 0.09,
        VE_02: 0.51869,
        QA_03: 0.096,
        VA_03: 0.52602,
        QC_03: 0.096,
        VC_03: 0.52602,
        QE_03: 0.096,
        VE_03: 0.52602,
        QA_04: 0.056,
        VA_04: 0.47186,
        QC_04: 0.056,
        VC_04: 0.47186,
        QE_04: 0.056,
        VE_04: 0.47186,
        QA_05: 0.03,
        VA_05: 0.42574,
        QC_05: 0.03,
        VC_05: 0.42574,
        QE_05: 0.03,
        VE_05: 0.42574,
        QA_06: 0.02,
        VA_06: 0.40301,
        QC_06: 0.02,
        VC_06: 0.40301,
        QE_06: 0.02,
        VE_06: 0.40301,
        QA_07: 0.014,
        VA_07: 0.38669,
        QC_07: 0.014,
        VC_07: 0.38669,
        QE_07: 0.014,
        VE_07: 0.38669,
        QA_08: 0.01,
        VA_08: 0.37387,
        QC_08: 0.01,
        VC_08: 0.37387,
        QE_08: 0.01,
        VE_08: 0.37387,
        QA_09: 0.008,
        VA_09: 0.36656,
        QC_09: 0.008,
        VC_09: 0.36656,
        QE_09: 0.008,
        VE_09: 0.36656,
        QA_10: 0.006,
        VA_10: 0.35832,
        QC_10: 0.006,
        VC_10: 0.35832,
        QE_10: 0.006,
        VE_10: 0.35832,
        QA_11: 0.006,
        VA_11: 0.35832,
        QC_11: 0.006,
        VC_11: 0.35832,
        QE_11: 0.006,
        VE_11: 0.35832,
        QA_12: 0.045,
        VA_12: 0.45393,
        QC_12: 0.045,
        VC_12: 0.45393,
        QE_12: 0.045,
        VE_12: 0.45393,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884698584890771, 34.798505612652434, 0.0],
          [-80.880050718231303, 34.79830801265274, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286770.0,
        COMID: 9734900,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220405",
        GNIS_NAME: "Bear Creek",
        LENGTHKM: 1.606,
        REACHCODE: "03050103000055",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016074086424164,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059547.0,
        ToNode: 250059535.0,
        Hydroseq: 250025544.0,
        LevelPathI: 250023658.0,
        Pathlength: 405.608,
        TerminalPa: 250002604.0,
        ArbolateSu: 94.291,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023658.0,
        UpHydroseq: 250026285.0,
        DnLevelPat: 250023658.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024866.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.3454,
        TotDASqKM: 87.1056,
        DivDASqKM: 87.1056,
        Tidal: 0,
        TOTMA: 0.055052747377,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13385.0,
        MAXELEVSMO: 13843.0,
        MINELEVSMO: 13388.0,
        SLOPE: 0.00283312,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6059999465942381,
        QA_MA: 33.368,
        VA_MA: 1.10416,
        QC_MA: 35.268,
        VC_MA: 1.10774,
        QE_MA: 35.268,
        VE_MA: 1.10774,
        QA_01: 74.292,
        VA_01: 1.52561,
        QC_01: 60.18,
        VC_01: 1.37,
        QE_01: 60.18,
        VE_01: 1.37,
        QA_02: 77.194,
        VA_02: 1.55063,
        QC_02: 66.805,
        VC_02: 1.43054,
        QE_02: 66.805,
        VE_02: 1.43054,
        QA_03: 82.575,
        VA_03: 1.59588,
        QC_03: 72.683,
        VC_03: 1.48193,
        QE_03: 72.683,
        VE_03: 1.48193,
        QA_04: 46.797,
        VA_04: 1.26072,
        QC_04: 44.874,
        VC_04: 1.21681,
        QE_04: 44.874,
        VE_04: 1.21681,
        QA_05: 26.21,
        VA_05: 1.0084,
        QC_05: 26.342,
        VC_05: 0.99299,
        QE_05: 26.342,
        VE_05: 0.99299,
        QA_06: 16.396,
        VA_06: 0.85405,
        QC_06: 21.055,
        VC_06: 0.91621,
        QE_06: 21.055,
        VE_06: 0.91621,
        QA_07: 12.881,
        VA_07: 0.78843,
        QC_07: 20.212,
        VC_07: 0.90317,
        QE_07: 20.212,
        VE_07: 0.90317,
        QA_08: 9.369,
        VA_08: 0.71377,
        QC_08: 20.326,
        VC_08: 0.90494,
        QE_08: 20.326,
        VE_08: 0.90494,
        QA_09: 7.027,
        VA_09: 0.65635,
        QC_09: 21.876,
        VC_09: 0.92868,
        QE_09: 21.876,
        VE_09: 0.92868,
        QA_10: 5.856,
        VA_10: 0.62425,
        QC_10: 22.571,
        VC_10: 0.93907,
        QE_10: 22.571,
        VE_10: 0.93907,
        QA_11: 5.043,
        VA_11: 0.60015,
        QC_11: 13.132,
        VC_11: 0.78144,
        QE_11: 13.132,
        VE_11: 0.78144,
        QA_12: 36.976,
        VA_12: 1.14875,
        QC_12: 35.298,
        VC_12: 1.1081,
        QE_12: 35.298,
        VE_12: 1.1081,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.764773318410164, 34.698646079474088, 0.0],
          [-80.773776918396266, 34.706739612794991, 0.0],
          [-80.776467718392041, 34.706512679461866, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283721.0,
        COMID: 9754134,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.663,
        REACHCODE: "03050101000128",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19488978,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0071740180121669999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061540.0,
        ToNode: 250061532.0,
        Hydroseq: 250010659.0,
        LevelPathI: 250003258.0,
        Pathlength: 601.018,
        TerminalPa: 250002604.0,
        ArbolateSu: 2644.58,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010722.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010597.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5506,
        TotDASqKM: 2882.3121,
        DivDASqKM: 2882.3121,
        Tidal: 0,
        TOTMA: 0.016484135068200002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28381.0,
        MAXELEVSMO: 28394.0,
        MINELEVSMO: 28381.0,
        SLOPE: 0.00019607,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.662999987602234,
        QA_MA: 1779.309,
        VA_MA: 1.51705,
        QC_MA: 1779.309,
        VC_MA: 1.51705,
        QE_MA: 1974.353,
        VE_MA: 1.52728,
        QA_01: 2835.757,
        VA_01: 1.85645,
        QC_01: 2835.757,
        VC_01: 1.85645,
        QE_01: 3041.031,
        VE_01: 1.84152,
        QA_02: 3004.832,
        VA_02: 1.9048,
        QC_02: 3004.832,
        VC_02: 1.9048,
        QE_02: 3076.654,
        VE_02: 1.85103,
        QA_03: 3416.131,
        VA_03: 2.01734,
        QC_03: 3416.131,
        VC_03: 2.01734,
        QE_03: 3680.251,
        VE_03: 2.00497,
        QA_04: 2619.337,
        VA_04: 1.79255,
        QC_04: 2619.337,
        VC_04: 1.79255,
        QE_04: 2811.462,
        VE_04: 1.77893,
        QA_05: 2017.584,
        VA_05: 1.60047,
        QC_05: 2017.584,
        VC_05: 1.60047,
        QE_05: 2239.706,
        VE_05: 1.61169,
        QA_06: 1176.793,
        VA_06: 1.27882,
        QC_06: 1176.793,
        VC_06: 1.27882,
        QE_06: 1436.407,
        VE_06: 1.33785,
        QA_07: 696.703,
        VA_07: 1.04304,
        QC_07: 696.703,
        VC_07: 1.04304,
        QE_07: 843.597,
        VE_07: 1.08441,
        QA_08: 495.58,
        VA_08: 0.92148,
        QC_08: 495.58,
        VC_08: 0.92148,
        QE_08: 795.196,
        VE_08: 1.06044,
        QA_09: 499.065,
        VA_09: 0.92377,
        QC_09: 943.09,
        VC_09: 1.17119,
        QE_09: 979.596,
        VE_09: 1.14851,
        QA_10: 654.024,
        VA_10: 1.01879,
        QC_10: 976.216,
        VC_10: 1.18715,
        QE_10: 936.714,
        VE_10: 1.12878,
        QA_11: 1695.241,
        VA_11: 1.48639,
        QC_11: 1695.241,
        VC_11: 1.48639,
        QE_11: 1672.442,
        VE_11: 1.42447,
        QA_12: 2252.435,
        VA_12: 1.67826,
        QC_12: 2252.435,
        VC_12: 1.67826,
        QE_12: 2276.147,
        VE_12: 1.62291,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.400422184090246, 35.75865401116215, 0.0],
          [-81.393689917433903, 35.758985077828299, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288767.0,
        COMID: 9735568,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.064,
        REACHCODE: "03050103017728",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00069830057723699996,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059724.0,
        ToNode: 250062117.0,
        Hydroseq: 250004607.0,
        LevelPathI: 250004607.0,
        Pathlength: 373.46,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.542,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004615.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004594.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 9868.131,
        DivDASqKM: 0.7317,
        Tidal: 0,
        TOTMA: 0.0058525032324700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9689.0,
        MAXELEVSMO: 9689.0,
        MINELEVSMO: 9689.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064000003039836995,
        QA_MA: 0.242,
        VA_MA: 0.41525,
        QC_MA: 0.242,
        VC_MA: 0.41525,
        QE_MA: 0.242,
        VE_MA: 0.41525,
        QA_01: 0.521,
        VA_01: 0.46895,
        QC_01: 0.521,
        VC_01: 0.46895,
        QE_01: 0.521,
        VE_01: 0.46895,
        QA_02: 0.58,
        VA_02: 0.47837,
        QC_02: 0.58,
        VC_02: 0.47837,
        QE_02: 0.58,
        VE_02: 0.47837,
        QA_03: 0.648,
        VA_03: 0.48867,
        QC_03: 0.648,
        VC_03: 0.48867,
        QE_03: 0.648,
        VE_03: 0.48867,
        QA_04: 0.334,
        VA_04: 0.43519,
        QC_04: 0.334,
        VC_04: 0.43519,
        QE_04: 0.334,
        VE_04: 0.43519,
        QA_05: 0.186,
        VA_05: 0.40131,
        QC_05: 0.186,
        VC_05: 0.40131,
        QE_05: 0.186,
        VE_05: 0.40131,
        QA_06: 0.127,
        VA_06: 0.38427,
        QC_06: 0.127,
        VC_06: 0.38427,
        QE_06: 0.127,
        VE_06: 0.38427,
        QA_07: 0.098,
        VA_07: 0.37451,
        QC_07: 0.098,
        VC_07: 0.37451,
        QE_07: 0.098,
        VE_07: 0.37451,
        QA_08: 0.078,
        VA_08: 0.36696,
        QC_08: 0.078,
        VC_08: 0.36696,
        QE_08: 0.078,
        VE_08: 0.36696,
        QA_09: 0.059,
        VA_09: 0.35889,
        QC_09: 0.059,
        VC_09: 0.35889,
        QE_09: 0.059,
        VE_09: 0.35889,
        QA_10: 0.049,
        VA_10: 0.35415,
        QC_10: 0.049,
        VC_10: 0.35415,
        QE_10: 0.049,
        VE_10: 0.35415,
        QA_11: 0.039,
        VA_11: 0.34893,
        QC_11: 0.039,
        VC_11: 0.34893,
        QE_11: 0.039,
        VE_11: 0.34893,
        QA_12: 0.186,
        VA_12: 0.40131,
        QC_12: 0.186,
        VC_12: 0.40131,
        QE_12: 0.186,
        VE_12: 0.40131,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880644184896994, 34.583703679652501, 0.0],
          [-80.879951918231484, 34.583795279652463, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283722.0,
        COMID: 9754128,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.212,
        REACHCODE: "03050101000129",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19488978,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012294645376447,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061537.0,
        ToNode: 250061540.0,
        Hydroseq: 250010722.0,
        LevelPathI: 250003258.0,
        Pathlength: 601.681,
        TerminalPa: 250002604.0,
        ArbolateSu: 2613.836,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010788.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010659.0,
        FromMeas: 0.0,
        ToMeas: 30.84939,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4985,
        TotDASqKM: 2834.2737,
        DivDASqKM: 2834.2737,
        Tidal: 0,
        TOTMA: 0.0310854186779,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28394.0,
        MAXELEVSMO: 28413.0,
        MINELEVSMO: 28394.0,
        SLOPE: 0.00015676,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2120000123977659,
        QA_MA: 1754.256,
        VA_MA: 1.47056,
        QC_MA: 1754.256,
        VC_MA: 1.47056,
        QE_MA: 1949.3,
        VE_MA: 1.48053,
        QA_01: 2790.717,
        VA_01: 1.79546,
        QC_01: 2055.684,
        VC_01: 1.57265,
        QE_01: 2260.959,
        VE_01: 1.57658,
        QA_02: 2956.633,
        VA_02: 1.84177,
        QC_02: 2956.633,
        VC_02: 1.84177,
        QE_02: 3028.456,
        VE_02: 1.78948,
        QA_03: 3364.073,
        VA_03: 1.95057,
        QC_03: 3364.073,
        VC_03: 1.95057,
        QE_03: 3628.193,
        VE_03: 1.93862,
        QA_04: 2581.795,
        VA_04: 1.73527,
        QC_04: 2581.795,
        VC_04: 1.73527,
        QE_04: 2773.921,
        VE_04: 1.72202,
        QA_05: 1991.236,
        VA_05: 1.55145,
        QC_05: 1991.236,
        VC_05: 1.55145,
        QE_05: 2213.358,
        VE_05: 1.56233,
        QA_06: 1161.704,
        VA_06: 1.24212,
        QC_06: 1161.704,
        VC_06: 1.24212,
        QE_06: 1421.317,
        VE_06: 1.29952,
        QA_07: 687.606,
        VA_07: 1.01517,
        QC_07: 687.606,
        VC_07: 1.01517,
        QE_07: 834.5,
        VE_07: 1.05541,
        QA_08: 489.547,
        VA_08: 0.8985,
        QC_08: 489.547,
        VC_08: 0.8985,
        QE_08: 789.163,
        VE_08: 1.03358,
        QA_09: 494.205,
        VA_09: 0.90148,
        QC_09: 934.977,
        VC_09: 1.14045,
        QE_09: 971.483,
        VE_09: 1.1182,
        QA_10: 650.039,
        VA_10: 0.99439,
        QC_10: 971.461,
        VC_10: 1.15753,
        QE_10: 931.959,
        VE_10: 1.10053,
        QA_11: 1675.644,
        VA_11: 1.44261,
        QC_11: 1675.644,
        VC_11: 1.44261,
        QE_11: 1652.845,
        VE_11: 1.38222,
        QA_12: 2219.406,
        VA_12: 1.62516,
        QC_12: 2219.406,
        VC_12: 1.62516,
        QE_12: 2243.119,
        VE_12: 1.57125,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.410021317408678, 35.765943877817506, 0.0],
          [-81.400422184090246, 35.75865401116215, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288768.0,
        COMID: 9735576,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.046,
        REACHCODE: "03050103017729",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00041883759539499999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059724.0,
        ToNode: 250062121.0,
        Hydroseq: 250004606.0,
        LevelPathI: 250004606.0,
        Pathlength: 373.237,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.524,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004615.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004593.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9868.1256,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0056640731603299996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9689.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.046000000089407002,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880644184896994, 34.583703679652501, 0.0],
          [-80.880782718230137, 34.583314279653173, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283731.0,
        COMID: 9752390,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.305,
        REACHCODE: "03050101000149",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01393981366314,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061038.0,
        ToNode: 250061040.0,
        Hydroseq: 250024231.0,
        LevelPathI: 250020920.0,
        Pathlength: 635.704,
        TerminalPa: 250002604.0,
        ArbolateSu: 152.119,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250024854.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023650.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8793,
        TotDASqKM: 164.2032,
        DivDASqKM: 164.2032,
        Tidal: 0,
        TOTMA: 0.0436960259728,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30893.0,
        MAXELEVSMO: 31172.0,
        MINELEVSMO: 31017.0,
        SLOPE: 0.00118773,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.304999947547913,
        QA_MA: 88.944,
        VA_MA: 1.13291,
        QC_MA: 90.499,
        VC_MA: 1.13407,
        QE_MA: 90.499,
        VE_MA: 1.13407,
        QA_01: 152.1,
        VA_01: 1.4047,
        QC_01: 120.916,
        VC_01: 1.27141,
        QE_01: 120.916,
        VE_01: 1.27141,
        QA_02: 159.043,
        VA_02: 1.43099,
        QC_02: 134.954,
        VC_02: 1.32924,
        QE_02: 134.954,
        VE_02: 1.32924,
        QA_03: 175.143,
        VA_03: 1.48997,
        QC_03: 150.382,
        VC_03: 1.38964,
        QE_03: 150.382,
        VE_03: 1.38964,
        QA_04: 136.95,
        VA_04: 1.34529,
        QC_04: 128.155,
        VC_04: 1.3016,
        QE_04: 128.155,
        VE_04: 1.3016,
        QA_05: 99.202,
        VA_05: 1.18211,
        QC_05: 94.857,
        VC_05: 1.15495,
        QE_05: 94.857,
        VE_05: 1.15495,
        QA_06: 55.628,
        VA_06: 0.95103,
        QC_06: 70.247,
        VC_06: 1.03015,
        QE_06: 70.247,
        VE_06: 1.03015,
        QA_07: 33.783,
        VA_07: 0.80152,
        QC_07: 51.803,
        VC_07: 0.92237,
        QE_07: 51.803,
        VE_07: 0.92237,
        QA_08: 22.622,
        VA_08: 0.70693,
        QC_08: 49.95,
        VC_08: 0.91061,
        QE_08: 49.95,
        VE_08: 0.91061,
        QA_09: 17.836,
        VA_09: 0.65967,
        QC_09: 49.79,
        VC_09: 0.90958,
        QE_09: 49.79,
        VE_09: 0.90958,
        QA_10: 20.324,
        VA_10: 0.6849,
        QC_10: 60.989,
        VC_10: 0.97797,
        QE_10: 60.989,
        VE_10: 0.97797,
        QA_11: 77.802,
        VA_11: 1.07635,
        QC_11: 105.816,
        VC_11: 1.20555,
        QE_11: 105.816,
        VE_11: 1.20555,
        QA_12: 117.266,
        VA_12: 1.26328,
        QC_12: 100.879,
        VC_12: 1.18308,
        QE_12: 100.879,
        VE_12: 1.18308,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.603555117108272, 35.843958211029701, 0.0],
          [-81.61258418376093, 35.839722611036336, 0.0],
          [-81.616268317088497, 35.840226611035575, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288769.0,
        COMID: 9735588,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.079,
        REACHCODE: "03050103017730",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00081861368318800003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059729.0,
        ToNode: 250062121.0,
        Hydroseq: 250004605.0,
        LevelPathI: 250004457.0,
        Pathlength: 373.237,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.685,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004616.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004593.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0162,
        TotDASqKM: 9868.3317,
        DivDASqKM: 0.603,
        Tidal: 0,
        TOTMA: 0.0073061746942099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9589.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.079000003635882998,
        QA_MA: 0.199,
        VA_MA: 0.41059,
        QC_MA: 0.199,
        VC_MA: 0.41059,
        QE_MA: 0.199,
        VE_MA: 0.41059,
        QA_01: 0.429,
        VA_01: 0.46206,
        QC_01: 0.429,
        VC_01: 0.46206,
        QE_01: 0.429,
        VE_01: 0.46206,
        QA_02: 0.478,
        VA_02: 0.47114,
        QC_02: 0.478,
        VC_02: 0.47114,
        QE_02: 0.478,
        VE_02: 0.47114,
        QA_03: 0.532,
        VA_03: 0.48066,
        QC_03: 0.532,
        VC_03: 0.48066,
        QE_03: 0.532,
        VE_03: 0.48066,
        QA_04: 0.275,
        VA_04: 0.42974,
        QC_04: 0.275,
        VC_04: 0.42974,
        QE_04: 0.275,
        VE_04: 0.42974,
        QA_05: 0.154,
        VA_05: 0.39758,
        QC_05: 0.154,
        VC_05: 0.39758,
        QE_05: 0.154,
        VE_05: 0.39758,
        QA_06: 0.105,
        VA_06: 0.38117,
        QC_06: 0.105,
        VC_06: 0.38117,
        QE_06: 0.105,
        VE_06: 0.38117,
        QA_07: 0.081,
        VA_07: 0.3718,
        QC_07: 0.081,
        VC_07: 0.3718,
        QE_07: 0.081,
        VE_07: 0.3718,
        QA_08: 0.064,
        VA_08: 0.36434,
        QC_08: 0.064,
        VC_08: 0.36434,
        QE_08: 0.064,
        VE_08: 0.36434,
        QA_09: 0.048,
        VA_09: 0.35642,
        QC_09: 0.048,
        VC_09: 0.35642,
        QE_09: 0.048,
        VE_09: 0.35642,
        QA_10: 0.04,
        VA_10: 0.35199,
        QC_10: 0.04,
        VC_10: 0.35199,
        QE_10: 0.04,
        VE_10: 0.35199,
        QA_11: 0.032,
        VA_11: 0.34711,
        QC_11: 0.032,
        VC_11: 0.34711,
        QE_11: 0.032,
        VE_11: 0.34711,
        QA_12: 0.154,
        VA_12: 0.39758,
        QC_12: 0.154,
        VC_12: 0.39758,
        QE_12: 0.154,
        VE_12: 0.39758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881502784895645, 34.582924879653781, 0.0],
          [-80.880782718230137, 34.583314279653173, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283732.0,
        COMID: 9752336,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.473,
        REACHCODE: "03050101000150",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0045252800346179999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061024.0,
        ToNode: 250061038.0,
        Hydroseq: 250024854.0,
        LevelPathI: 250020920.0,
        Pathlength: 637.009,
        TerminalPa: 250002604.0,
        ArbolateSu: 148.985,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250025532.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024231.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3069,
        TotDASqKM: 161.3574,
        DivDASqKM: 161.3574,
        Tidal: 0,
        TOTMA: 0.0155343098306,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30952.0,
        MAXELEVSMO: 31240.0,
        MINELEVSMO: 31172.0,
        SLOPE: 0.00143763,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.47299998998642001,
        QA_MA: 87.479,
        VA_MA: 1.15498,
        QC_MA: 89.066,
        VC_MA: 1.15622,
        QE_MA: 89.066,
        VE_MA: 1.15622,
        QA_01: 149.471,
        VA_01: 1.43355,
        QC_01: 118.881,
        VC_01: 1.2967,
        QE_01: 118.881,
        VE_01: 1.2967,
        QA_02: 156.254,
        VA_02: 1.46038,
        QC_02: 132.651,
        VC_02: 1.35593,
        QE_02: 132.651,
        VE_02: 1.35593,
        QA_03: 172.114,
        VA_03: 1.52106,
        QC_03: 147.866,
        VC_03: 1.4181,
        QE_03: 147.866,
        VE_03: 1.4181,
        QA_04: 134.703,
        VA_04: 1.37308,
        QC_04: 126.1,
        VC_04: 1.32813,
        QE_04: 126.1,
        VE_04: 1.32813,
        QA_05: 97.642,
        VA_05: 1.20586,
        QC_05: 93.421,
        VC_05: 1.17798,
        QE_05: 93.421,
        VE_05: 1.17798,
        QA_06: 54.741,
        VA_06: 0.96842,
        QC_06: 69.143,
        VC_06: 1.04956,
        QE_06: 69.143,
        VE_06: 1.04956,
        QA_07: 33.248,
        VA_07: 0.81489,
        QC_07: 51.001,
        VC_07: 0.93896,
        QE_07: 51.001,
        VE_07: 0.93896,
        QA_08: 22.27,
        VA_08: 0.7178,
        QC_08: 49.159,
        VC_08: 0.92676,
        QE_08: 49.159,
        VE_08: 0.92676,
        QA_09: 17.53,
        VA_09: 0.66894,
        QC_09: 49.035,
        VC_09: 0.92593,
        QE_09: 49.035,
        VE_09: 0.92593,
        QA_10: 20.095,
        VA_10: 0.69606,
        QC_10: 60.438,
        VC_10: 0.99844,
        QE_10: 60.438,
        VE_10: 0.99844,
        QA_11: 76.71,
        VA_11: 1.09794,
        QC_11: 104.682,
        VC_11: 1.23216,
        QE_11: 104.682,
        VE_11: 1.23216,
        QA_12: 115.342,
        VA_12: 1.28887,
        QC_12: 99.372,
        VC_12: 1.20697,
        QE_12: 99.372,
        VE_12: 1.20697,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.600938517112297, 35.847553077690804, 0.0],
          [-81.603555117108272, 35.843958211029701, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283733.0,
        COMID: 9752310,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.58,
        REACHCODE: "03050101000151",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014744246384229999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061016.0,
        ToNode: 250061024.0,
        Hydroseq: 250025532.0,
        LevelPathI: 250020920.0,
        Pathlength: 637.482,
        TerminalPa: 250002604.0,
        ArbolateSu: 145.566,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250026273.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024854.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7081,
        TotDASqKM: 157.5477,
        DivDASqKM: 157.5477,
        Tidal: 0,
        TOTMA: 0.056324487628799998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31018.0,
        MAXELEVSMO: 31355.0,
        MINELEVSMO: 31240.0,
        SLOPE: 0.00072784,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.580000042915344,
        QA_MA: 85.498,
        VA_MA: 1.06405,
        QC_MA: 87.127,
        VC_MA: 1.0652,
        QE_MA: 87.127,
        VE_MA: 1.0652,
        QA_01: 145.933,
        VA_01: 1.31213,
        QC_01: 116.139,
        VC_01: 1.18999,
        QE_01: 116.139,
        VE_01: 1.18999,
        QA_02: 152.505,
        VA_02: 1.33588,
        QC_02: 129.554,
        VC_02: 1.24267,
        QE_02: 129.554,
        VE_02: 1.24267,
        QA_03: 168.05,
        VA_03: 1.39023,
        QC_03: 144.489,
        VC_03: 1.2984,
        QE_03: 144.489,
        VE_03: 1.2984,
        QA_04: 131.667,
        VA_04: 1.25877,
        QC_04: 123.321,
        VC_04: 1.21853,
        QE_04: 123.321,
        VE_04: 1.21853,
        QA_05: 95.506,
        VA_05: 1.10979,
        QC_05: 91.453,
        VC_05: 1.08493,
        QE_05: 91.453,
        VE_05: 1.08493,
        QA_06: 53.525,
        VA_06: 0.89766,
        QC_06: 67.627,
        VC_06: 0.96993,
        QE_06: 67.627,
        VE_06: 0.96993,
        QA_07: 32.529,
        VA_07: 0.76073,
        QC_07: 49.924,
        VC_07: 0.87146,
        QE_07: 49.924,
        VE_07: 0.87146,
        QA_08: 21.771,
        VA_08: 0.67387,
        QC_08: 48.036,
        VC_08: 0.86006,
        QE_08: 48.036,
        VE_08: 0.86006,
        QA_09: 17.121,
        VA_09: 0.63009,
        QC_09: 48.021,
        VC_09: 0.85996,
        QE_09: 48.021,
        VE_09: 0.85996,
        QA_10: 19.787,
        VA_10: 0.65579,
        QC_10: 59.699,
        VC_10: 0.92755,
        QE_10: 59.699,
        VE_10: 0.92755,
        QA_11: 75.177,
        VA_11: 1.01415,
        QC_11: 103.083,
        VC_11: 1.13589,
        QE_11: 103.083,
        VE_11: 1.13589,
        QA_12: 112.731,
        VA_12: 1.18355,
        QC_12: 97.323,
        VC_12: 1.11101,
        QE_12: 97.323,
        VE_12: 1.11101,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.595590117120651, 35.860193277671158, 0.0],
          [-81.598123717116721, 35.854216677680434, 0.0],
          [-81.600881117112351, 35.851904211017313, 0.0],
          [-81.600938517112297, 35.847553077690804, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288747.0,
        COMID: 166740272,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.137,
        REACHCODE: "03050103017708",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001247572920019,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094176.0,
        ToNode: 250094178.0,
        Hydroseq: 250005293.0,
        LevelPathI: 250003258.0,
        Pathlength: 393.268,
        TerminalPa: 250002604.0,
        ArbolateSu: 7031.426,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005317.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005281.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0252,
        TotDASqKM: 9368.7678,
        DivDASqKM: 9368.7678,
        Tidal: 0,
        TOTMA: 0.00403238277226,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12720.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13699999451637301,
        QA_MA: 4693.998,
        VA_MA: 1.2791,
        QC_MA: 4693.998,
        VC_MA: 1.2791,
        QE_MA: 5395.834,
        VE_MA: 1.29012,
        QA_01: 8565.372,
        VA_01: 1.64433,
        QC_01: 8565.372,
        VC_01: 1.64433,
        QE_01: 7129.963,
        VE_01: 1.44669,
        QA_02: 9055.417,
        VA_02: 1.68439,
        QC_02: 9055.417,
        VC_02: 1.68439,
        QE_02: 7244.238,
        VE_02: 1.45634,
        QA_03: 9948.262,
        VA_03: 1.75484,
        QC_03: 9948.262,
        VC_03: 1.75484,
        QE_03: 7712.54,
        VE_03: 1.49517,
        QA_04: 6886.339,
        VA_04: 1.49818,
        QC_04: 6886.339,
        VC_04: 1.49818,
        QE_04: 6665.106,
        VE_04: 1.40666,
        QA_05: 4724.431,
        VA_05: 1.28244,
        QC_05: 4724.431,
        VC_05: 1.28244,
        QE_05: 5710.023,
        VE_05: 1.32007,
        QA_06: 2784.437,
        VA_06: 1.04401,
        QC_06: 2784.437,
        VC_06: 1.04401,
        QE_06: 5023.403,
        VE_06: 1.25353,
        QA_07: 1725.533,
        VA_07: 0.87895,
        QC_07: 1725.533,
        VC_07: 0.87895,
        QE_07: 3817.149,
        VE_07: 1.12529,
        QA_08: 1226.999,
        VA_08: 0.78446,
        QC_08: 1226.999,
        VC_08: 0.78446,
        QE_08: 3564.84,
        VE_08: 1.09616,
        QA_09: 1123.058,
        VA_09: 0.7626,
        QC_09: 1123.058,
        VC_09: 0.7626,
        QE_09: 3267.456,
        VE_09: 1.06056,
        QA_10: 1216.416,
        VA_10: 0.78228,
        QC_10: 1216.416,
        VC_10: 0.78228,
        QE_10: 4459.744,
        VE_10: 1.19565,
        QA_11: 3145.772,
        VA_11: 1.09325,
        QC_11: 3145.772,
        VC_11: 1.09325,
        QE_11: 4522.286,
        VE_11: 1.20224,
        QA_12: 5957.963,
        VA_12: 1.41012,
        QC_12: 5957.963,
        VC_12: 1.41012,
        QE_12: 5666.641,
        VE_12: 1.31598,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874562984906504, 34.734327412752179, 0.0],
          [-80.874989318239045, 34.733154946087268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283734.0,
        COMID: 9752214,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.222,
        REACHCODE: "03050101000152",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0020467791713700001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060991.0,
        ToNode: 250061016.0,
        Hydroseq: 250026273.0,
        LevelPathI: 250020920.0,
        Pathlength: 639.062,
        TerminalPa: 250002604.0,
        ArbolateSu: 139.342,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250027071.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025532.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.045,
        TotDASqKM: 148.5594,
        DivDASqKM: 148.5594,
        Tidal: 0,
        TOTMA: 0.012285671300799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31153.0,
        MAXELEVSMO: 31355.0,
        MINELEVSMO: 31355.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.222000002861023,
        QA_MA: 80.836,
        VA_MA: 0.68552,
        QC_MA: 82.555,
        VC_MA: 0.68616,
        QE_MA: 82.555,
        VE_MA: 0.68616,
        QA_01: 137.689,
        VA_01: 0.80878,
        QC_01: 109.746,
        VC_01: 0.74782,
        QE_01: 109.746,
        VE_01: 0.74782,
        QA_02: 143.772,
        VA_02: 0.8204,
        QC_02: 122.332,
        VC_02: 0.77389,
        QE_02: 122.332,
        VE_02: 0.77389,
        QA_03: 158.524,
        VA_03: 0.84766,
        QC_03: 136.561,
        VC_03: 0.8019,
        QE_03: 136.561,
        VE_03: 0.8019,
        QA_04: 124.44,
        VA_04: 0.78261,
        QC_04: 116.702,
        VC_04: 0.76239,
        QE_04: 116.702,
        VE_04: 0.76239,
        QA_05: 90.424,
        VA_05: 0.70865,
        QC_05: 86.764,
        VC_05: 0.69627,
        QE_05: 86.764,
        VE_05: 0.69627,
        QA_06: 50.664,
        VA_06: 0.60266,
        QC_06: 64.06,
        VC_06: 0.63856,
        QE_06: 64.06,
        VE_06: 0.63856,
        QA_07: 30.787,
        VA_07: 0.53427,
        QC_07: 47.313,
        VC_07: 0.58949,
        QE_07: 47.313,
        VE_07: 0.58949,
        QA_08: 20.608,
        VA_08: 0.49091,
        QC_08: 45.419,
        VC_08: 0.58345,
        QE_08: 45.419,
        VE_08: 0.58345,
        QA_09: 16.154,
        VA_09: 0.46877,
        QC_09: 45.619,
        VC_09: 0.5841,
        QE_09: 45.619,
        VE_09: 0.5841,
        QA_10: 19.062,
        VA_10: 0.4835,
        QC_10: 57.945,
        VC_10: 0.62143,
        QE_10: 57.945,
        VE_10: 0.62143,
        QA_11: 71.525,
        VA_11: 0.66179,
        QC_11: 99.242,
        VC_11: 0.72496,
        QE_11: 99.242,
        VE_11: 0.72496,
        QA_12: 106.642,
        VA_12: 0.74529,
        QC_12: 92.529,
        VC_12: 0.70975,
        QE_12: 92.529,
        VE_12: 0.70975,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.594801783788512, 35.86204821100165, 0.0],
          [-81.595590117120651, 35.860193277671158, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288748.0,
        COMID: 166740273,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.049,
        REACHCODE: "03050103017709",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00050530227631400001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094177.0,
        ToNode: 250094178.0,
        Hydroseq: 250005292.0,
        LevelPathI: 250005292.0,
        Pathlength: 393.268,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.543,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005292.0,
        UpHydroseq: 250005307.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005281.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0126,
        TotDASqKM: 9368.3853,
        DivDASqKM: 0.027,
        Tidal: 0,
        TOTMA: 0.0039986708617999996,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12732.0,
        MAXELEVSMO: 12793.0,
        MINELEVSMO: 12732.0,
        SLOPE: 0.01244897,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.048999998718499999,
        QA_MA: 0.01,
        VA_MA: 0.46532,
        QC_MA: 0.01,
        VC_MA: 0.46532,
        QE_MA: 0.01,
        VE_MA: 0.46532,
        QA_01: 0.022,
        VA_01: 0.54691,
        QC_01: 0.022,
        VC_01: 0.54691,
        QE_01: 0.022,
        VE_01: 0.54691,
        QA_02: 0.024,
        VA_02: 0.55819,
        QC_02: 0.024,
        VC_02: 0.55819,
        QE_02: 0.024,
        VE_02: 0.55819,
        QA_03: 0.026,
        VA_03: 0.56903,
        QC_03: 0.026,
        VC_03: 0.56903,
        QE_03: 0.026,
        VE_03: 0.56903,
        QA_04: 0.014,
        VA_04: 0.49601,
        QC_04: 0.014,
        VC_04: 0.49601,
        QE_04: 0.014,
        VE_04: 0.49601,
        QA_05: 0.008,
        VA_05: 0.44778,
        QC_05: 0.008,
        VC_05: 0.44778,
        QE_05: 0.008,
        VE_05: 0.44778,
        QA_06: 0.005,
        VA_06: 0.41699,
        QC_06: 0.005,
        VC_06: 0.41699,
        QE_06: 0.005,
        VE_06: 0.41699,
        QA_07: 0.003,
        VA_07: 0.39119,
        QC_07: 0.003,
        VC_07: 0.39119,
        QE_07: 0.003,
        VE_07: 0.39119,
        QA_08: 0.002,
        VA_08: 0.37515,
        QC_08: 0.002,
        VC_08: 0.37515,
        QE_08: 0.002,
        VE_08: 0.37515,
        QA_09: 0.002,
        VA_09: 0.37515,
        QC_09: 0.002,
        VC_09: 0.37515,
        QE_09: 0.002,
        VE_09: 0.37515,
        QA_10: 0.001,
        VA_10: 0.3546,
        QC_10: 0.001,
        VC_10: 0.3546,
        QE_10: 0.001,
        VE_10: 0.3546,
        QA_11: 0.001,
        VA_11: 0.3546,
        QC_11: 0.001,
        VC_11: 0.3546,
        QE_11: 0.001,
        VE_11: 0.3546,
        QA_12: 0.011,
        VA_12: 0.47346,
        QC_12: 0.011,
        VC_12: 0.47346,
        QE_12: 0.011,
        VE_12: 0.47346,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875433518238481, 34.733395812753599, 0.0],
          [-80.874989318239045, 34.733154946087268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283727.0,
        COMID: 9752544,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.08,
        REACHCODE: "03050101000145",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010277078415193,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061080.0,
        ToNode: 250061098.0,
        Hydroseq: 250022142.0,
        LevelPathI: 250020920.0,
        Pathlength: 631.948,
        TerminalPa: 250002604.0,
        ArbolateSu: 198.474,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250022609.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021703.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7496,
        TotDASqKM: 216.9675,
        DivDASqKM: 216.9675,
        Tidal: 0,
        TOTMA: 0.036999730809799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30611.0,
        MAXELEVSMO: 30789.0,
        MINELEVSMO: 30718.0,
        SLOPE: 0.0006574,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.080000042915344,
        QA_MA: 116.51,
        VA_MA: 1.10796,
        QC_MA: 117.311,
        VC_MA: 1.1084,
        QE_MA: 117.311,
        VE_MA: 1.1084,
        QA_01: 200.724,
        VA_01: 1.37571,
        QC_01: 158.416,
        VC_01: 1.24675,
        QE_01: 158.416,
        VE_01: 1.24675,
        QA_02: 210.51,
        VA_02: 1.40304,
        QC_02: 177.266,
        VC_02: 1.30448,
        QE_02: 177.266,
        VE_02: 1.30448,
        QA_03: 231.383,
        VA_03: 1.45939,
        QC_03: 196.853,
        VC_03: 1.36148,
        QE_03: 196.853,
        VE_03: 1.36148,
        QA_04: 179.383,
        VA_04: 1.31387,
        QC_04: 166.836,
        VC_04: 1.27291,
        QE_04: 166.836,
        VE_04: 1.27291,
        QA_05: 129.341,
        VA_05: 1.15357,
        QC_05: 122.454,
        VC_05: 1.12684,
        QE_05: 122.454,
        VE_05: 1.12684,
        QA_06: 72.626,
        VA_06: 0.93048,
        QC_06: 91.377,
        VC_06: 1.00901,
        QE_06: 91.377,
        VE_06: 1.00901,
        QA_07: 44.079,
        VA_07: 0.78559,
        QC_07: 67.161,
        VC_07: 0.90333,
        QE_07: 67.161,
        VE_07: 0.90333,
        QA_08: 29.534,
        VA_08: 0.69418,
        QC_08: 65.562,
        VC_08: 0.89577,
        QE_08: 65.562,
        VE_08: 0.89577,
        QA_09: 23.403,
        VA_09: 0.64935,
        QC_09: 63.285,
        VC_09: 0.88485,
        QE_09: 63.285,
        VE_09: 0.88485,
        QA_10: 24.631,
        VA_10: 0.65873,
        QC_10: 71.111,
        VC_10: 0.92166,
        QE_10: 71.111,
        VE_10: 0.92166,
        QA_11: 99.616,
        VA_11: 1.04414,
        QC_11: 127.766,
        VC_11: 1.1455,
        QE_11: 127.766,
        VE_11: 1.1455,
        QA_12: 153.314,
        VA_12: 1.23343,
        QC_12: 128.74,
        VC_12: 1.14889,
        QE_12: 128.74,
        VE_12: 1.14889,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.635756117058236, 35.825569477724969, 0.0],
          [-81.641349983716282, 35.817232877737922, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288749.0,
        COMID: 166740274,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.591,
        REACHCODE: "03050103017710",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0055400148285509997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094178.0,
        ToNode: 250060001.0,
        Hydroseq: 250005281.0,
        LevelPathI: 250003258.0,
        Pathlength: 392.677,
        TerminalPa: 250002604.0,
        ArbolateSu: 7032.329,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005293.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005272.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2025,
        TotDASqKM: 9368.9973,
        DivDASqKM: 9368.9973,
        Tidal: 0,
        TOTMA: 0.0096206740443500003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12665.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12676.0,
        SLOPE: 0.00094754,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59100002050399802,
        QA_MA: 4694.087,
        VA_MA: 2.30995,
        QC_MA: 4694.087,
        VC_MA: 2.30995,
        QE_MA: 5395.923,
        VE_MA: 2.33267,
        QA_01: 8565.568,
        VA_01: 3.06305,
        QC_01: 8565.568,
        VC_01: 3.06305,
        QE_01: 7130.159,
        VE_01: 2.65553,
        QA_02: 9055.623,
        VA_02: 3.14565,
        QC_02: 9055.623,
        VC_02: 3.14565,
        QE_02: 7244.445,
        VE_02: 2.67543,
        QA_03: 9948.484,
        VA_03: 3.29091,
        QC_03: 9948.484,
        VC_03: 3.29091,
        QE_03: 7712.761,
        VE_03: 2.75549,
        QA_04: 6886.465,
        VA_04: 2.76169,
        QC_04: 6886.465,
        VC_04: 2.76169,
        QE_04: 6665.232,
        VE_04: 2.57298,
        QA_05: 4724.502,
        VA_05: 2.31683,
        QC_05: 4724.502,
        VC_05: 2.31683,
        QE_05: 5710.094,
        VE_05: 2.39442,
        QA_06: 2784.483,
        VA_06: 1.82521,
        QC_06: 2784.483,
        VC_06: 1.82521,
        QE_06: 5023.449,
        VE_06: 2.25723,
        QA_07: 1725.566,
        VA_07: 1.48488,
        QC_07: 1725.566,
        VC_07: 1.48488,
        QE_07: 3817.183,
        VE_07: 1.99281,
        QA_08: 1227.023,
        VA_08: 1.29004,
        QC_08: 1227.023,
        VC_08: 1.29004,
        QE_08: 3564.866,
        VE_08: 1.93274,
        QA_09: 1123.076,
        VA_09: 1.24497,
        QC_09: 1123.076,
        VC_09: 1.24497,
        QE_09: 3267.475,
        VE_09: 1.85931,
        QA_10: 1216.431,
        VA_10: 1.28553,
        QC_10: 1216.431,
        VC_10: 1.28553,
        QE_10: 4459.76,
        VE_10: 2.13787,
        QA_11: 3145.787,
        VA_11: 1.92673,
        QC_11: 3145.787,
        VC_11: 1.92673,
        QE_11: 4522.3,
        VE_11: 2.15145,
        QA_12: 5958.063,
        VA_12: 2.58012,
        QC_12: 5958.063,
        VC_12: 2.58012,
        QE_12: 5666.741,
        VE_12: 2.386,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874989318239045, 34.733154946087268, 0.0],
          [-80.872118784910299, 34.72841661276118, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283728.0,
        COMID: 9753812,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 1.771,
        REACHCODE: "03050101000146",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017529994799804001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061410.0,
        ToNode: 250061080.0,
        Hydroseq: 250022609.0,
        LevelPathI: 250020920.0,
        Pathlength: 633.028,
        TerminalPa: 250002604.0,
        ArbolateSu: 194.696,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250023110.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022142.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8611,
        TotDASqKM: 213.0039,
        DivDASqKM: 213.0039,
        Tidal: 0,
        TOTMA: 0.068575884111900007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30682.0,
        MAXELEVSMO: 30829.0,
        MINELEVSMO: 30789.0,
        SLOPE: 0.00022586,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.771000027656555,
        QA_MA: 114.501,
        VA_MA: 0.98024,
        QC_MA: 115.367,
        VC_MA: 0.98066,
        QE_MA: 115.367,
        VE_MA: 0.98066,
        QA_01: 197.056,
        VA_01: 1.20474,
        QC_01: 155.596,
        VC_01: 1.0964,
        QE_01: 155.596,
        VE_01: 1.0964,
        QA_02: 206.588,
        VA_02: 1.22751,
        QC_02: 174.052,
        VC_02: 1.14472,
        QE_02: 174.052,
        VE_02: 1.14472,
        QA_03: 227.168,
        VA_03: 1.27504,
        QC_03: 193.384,
        VC_03: 1.19283,
        QE_03: 193.384,
        VE_03: 1.19283,
        QA_04: 176.302,
        VA_04: 1.1533,
        QC_04: 164.035,
        VC_04: 1.11881,
        QE_04: 164.035,
        VE_04: 1.11881,
        QA_05: 127.228,
        VA_05: 1.01892,
        QC_05: 120.528,
        VC_05: 0.99646,
        QE_05: 120.528,
        VE_05: 0.99646,
        QA_06: 71.422,
        VA_06: 0.83131,
        QC_06: 89.883,
        VC_06: 0.8972,
        QE_06: 89.883,
        VE_06: 0.8972,
        QA_07: 43.354,
        VA_07: 0.70954,
        QC_07: 66.083,
        VC_07: 0.80847,
        QE_07: 66.083,
        VE_07: 0.80847,
        QA_08: 29.048,
        VA_08: 0.6327,
        QC_08: 64.462,
        VC_08: 0.80192,
        QE_08: 64.462,
        VE_08: 0.80192,
        QA_09: 23.024,
        VA_09: 0.59505,
        QC_09: 62.377,
        VC_09: 0.79338,
        QE_09: 62.377,
        VE_09: 0.79338,
        QA_10: 24.312,
        VA_10: 0.60346,
        QC_10: 70.372,
        VC_10: 0.82545,
        QE_10: 70.372,
        VE_10: 0.82545,
        QA_11: 98.231,
        VA_11: 0.92773,
        QC_11: 126.408,
        VC_11: 1.01409,
        QE_11: 126.408,
        VE_11: 1.01409,
        QA_12: 150.712,
        VA_12: 1.08579,
        QC_12: 126.751,
        VC_12: 1.01511,
        QE_12: 126.751,
        VE_12: 1.01511,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.624565117075633, 35.836608411041198, 0.0],
          [-81.62675851707229, 35.832119811048187, 0.0],
          [-81.632944783729272, 35.829783477718365, 0.0],
          [-81.635756117058236, 35.825569477724969, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288750.0,
        COMID: 166740275,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.068,
        REACHCODE: "03050103017711",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00070416195621799999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094179.0,
        ToNode: 250059323.0,
        Hydroseq: 250005855.0,
        LevelPathI: 250005769.0,
        Pathlength: 401.091,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.811,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005881.0,
        DnLevelPat: 250005769.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005836.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9187.0551,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.039355932524300002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13639.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.068000003695488004,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88479298489051, 34.798023745986541, 0.0],
          [-80.884181318224933, 34.797674879320311, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283729.0,
        COMID: 9752424,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.586,
        REACHCODE: "03050101000147",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0060958978439419999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061050.0,
        ToNode: 250061410.0,
        Hydroseq: 250023110.0,
        LevelPathI: 250020920.0,
        Pathlength: 634.799,
        TerminalPa: 250002604.0,
        ArbolateSu: 156.439,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250023650.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022609.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1782,
        TotDASqKM: 167.1966,
        DivDASqKM: 167.1966,
        Tidal: 0,
        TOTMA: 0.017394018931,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30829.0,
        MAXELEVSMO: 31017.0,
        MINELEVSMO: 30829.0,
        SLOPE: 0.00320819,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.58600002527236905,
        QA_MA: 90.48,
        VA_MA: 1.27798,
        QC_MA: 92.001,
        VC_MA: 1.27929,
        QE_MA: 92.001,
        VE_MA: 1.27929,
        QA_01: 154.879,
        VA_01: 1.59825,
        QC_01: 123.067,
        VC_01: 1.44148,
        QE_01: 123.067,
        VE_01: 1.44148,
        QA_02: 162.003,
        VA_02: 1.62942,
        QC_02: 137.396,
        VC_02: 1.50972,
        QE_02: 137.396,
        VE_02: 1.50972,
        QA_03: 178.336,
        VA_03: 1.69855,
        QC_03: 153.033,
        VC_03: 1.58049,
        QE_03: 153.033,
        VE_03: 1.58049,
        QA_04: 139.298,
        VA_04: 1.52764,
        QC_04: 130.301,
        VC_04: 1.47637,
        QE_04: 130.301,
        VE_04: 1.47637,
        QA_05: 100.827,
        VA_05: 1.33536,
        QC_05: 96.352,
        VC_05: 1.30341,
        QE_05: 96.352,
        VE_05: 1.30341,
        QA_06: 56.556,
        VA_06: 1.06387,
        QC_06: 71.403,
        VC_06: 1.15703,
        QE_06: 71.403,
        VE_06: 1.15703,
        QA_07: 34.347,
        VA_07: 0.8881,
        QC_07: 52.646,
        VC_07: 1.03024,
        QE_07: 52.646,
        VE_07: 1.03024,
        QA_08: 22.986,
        VA_08: 0.77676,
        QC_08: 50.772,
        VC_08: 1.01648,
        QE_08: 50.772,
        VE_08: 1.01648,
        QA_09: 18.148,
        VA_09: 0.72153,
        QC_09: 50.556,
        VC_09: 1.01488,
        QE_09: 50.556,
        VE_09: 1.01488,
        QA_10: 20.566,
        VA_10: 0.7499,
        QC_10: 61.567,
        VC_10: 1.0928,
        QE_10: 61.567,
        VE_10: 1.0928,
        QA_11: 78.917,
        VA_11: 1.21008,
        QC_11: 106.971,
        VC_11: 1.36021,
        QE_11: 106.971,
        VE_11: 1.36021,
        QA_12: 119.275,
        VA_12: 1.4312,
        QC_12: 102.45,
        VC_12: 1.33636,
        QE_12: 102.45,
        VE_12: 1.33636,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.619502783750207, 35.839150477703811, 0.0],
          [-81.624565117075633, 35.836608411041198, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288743.0,
        COMID: 166740268,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.416,
        REACHCODE: "03050103017704",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0038299235862589999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094165.0,
        ToNode: 250094174.0,
        Hydroseq: 250005377.0,
        LevelPathI: 250003258.0,
        Pathlength: 393.55,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.913,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005393.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005317.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.162,
        TotDASqKM: 9368.3754,
        DivDASqKM: 9368.3754,
        Tidal: 0,
        TOTMA: 0.012244411223000001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12664.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.41600000858306901,
        QA_MA: 4693.845,
        VA_MA: 1.27909,
        QC_MA: 4693.845,
        VC_MA: 1.27909,
        QE_MA: 5395.681,
        VE_MA: 1.29011,
        QA_01: 8565.037,
        VA_01: 1.64431,
        QC_01: 8565.037,
        VC_01: 1.64431,
        QE_01: 7129.629,
        VE_01: 1.44667,
        QA_02: 9055.063,
        VA_02: 1.68437,
        QC_02: 9055.063,
        VC_02: 1.68437,
        QE_02: 7243.886,
        VE_02: 1.45632,
        QA_03: 9947.882,
        VA_03: 1.75482,
        QC_03: 9947.882,
        VC_03: 1.75482,
        QE_03: 7712.161,
        VE_03: 1.49514,
        QA_04: 6886.122,
        VA_04: 1.49817,
        QC_04: 6886.122,
        VC_04: 1.49817,
        QE_04: 6664.89,
        VE_04: 1.40665,
        QA_05: 4724.309,
        VA_05: 1.28243,
        QC_05: 4724.309,
        VC_05: 1.28243,
        QE_05: 5709.902,
        VE_05: 1.32006,
        QA_06: 2784.358,
        VA_06: 1.04401,
        QC_06: 2784.358,
        VC_06: 1.04401,
        QE_06: 5023.323,
        VE_06: 1.25353,
        QA_07: 1725.477,
        VA_07: 0.87895,
        QC_07: 1725.477,
        VC_07: 0.87895,
        QE_07: 3817.093,
        VE_07: 1.12529,
        QA_08: 1226.956,
        VA_08: 0.78446,
        QC_08: 1226.956,
        VC_08: 0.78446,
        QE_08: 3564.797,
        VE_08: 1.09616,
        QA_09: 1123.026,
        VA_09: 0.7626,
        QC_09: 1123.026,
        VC_09: 0.7626,
        QE_09: 3267.423,
        VE_09: 1.06056,
        QA_10: 1216.389,
        VA_10: 0.78228,
        QC_10: 1216.389,
        VC_10: 0.78228,
        QE_10: 4459.716,
        VE_10: 1.19565,
        QA_11: 3145.746,
        VA_11: 1.09326,
        QC_11: 3145.746,
        VC_11: 1.09326,
        QE_11: 4522.259,
        VE_11: 1.20224,
        QA_12: 5957.791,
        VA_12: 1.41011,
        QC_12: 5957.791,
        VC_12: 1.41011,
        QE_12: 5666.47,
        VE_12: 1.31597,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875650184904771, 34.739102679411417, 0.0],
          [-80.87458425157314, 34.73562781275001, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283730.0,
        COMID: 9752396,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013239",
        GNIS_NAME: "Lower Creek",
        LENGTHKM: 0.319,
        REACHCODE: "03050101000148",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003452301161283,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061040.0,
        ToNode: 250061050.0,
        Hydroseq: 250023650.0,
        LevelPathI: 250020920.0,
        Pathlength: 635.385,
        TerminalPa: 250002604.0,
        ArbolateSu: 154.199,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250024231.0,
        DnLevelPat: 250020920.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023110.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1584,
        TotDASqKM: 165.7791,
        DivDASqKM: 165.7791,
        Tidal: 0,
        TOTMA: 0.0174161588328,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30863.0,
        MAXELEVSMO: 31017.0,
        MINELEVSMO: 31017.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.31900000572204601,
        QA_MA: 89.754,
        VA_MA: 0.69499,
        QC_MA: 91.291,
        VC_MA: 0.69552,
        QE_MA: 91.291,
        VE_MA: 0.69552,
        QA_01: 153.564,
        VA_01: 0.82256,
        QC_01: 122.05,
        VC_01: 0.76006,
        QE_01: 122.05,
        VE_01: 0.76006,
        QA_02: 160.597,
        VA_02: 0.83493,
        QC_02: 136.236,
        VC_02: 0.78721,
        QE_02: 136.236,
        VE_02: 0.78721,
        QA_03: 176.825,
        VA_03: 0.86254,
        QC_03: 151.778,
        VC_03: 0.81548,
        QE_03: 151.778,
        VE_03: 0.81548,
        QA_04: 138.188,
        VA_04: 0.79454,
        QC_04: 129.286,
        VC_04: 0.77408,
        QE_04: 129.286,
        VE_04: 0.77408,
        QA_05: 100.06,
        VA_05: 0.71796,
        QC_05: 95.646,
        VC_05: 0.70522,
        QE_05: 95.646,
        VE_05: 0.70522,
        QA_06: 56.118,
        VA_06: 0.60966,
        QC_06: 70.858,
        VC_06: 0.64678,
        QE_06: 70.858,
        VE_06: 0.64678,
        QA_07: 34.08,
        VA_07: 0.53957,
        QC_07: 52.246,
        VC_07: 0.59623,
        QE_07: 52.246,
        VE_07: 0.59623,
        QA_08: 22.815,
        VA_08: 0.4952,
        QC_08: 50.385,
        VC_08: 0.59074,
        QE_08: 50.385,
        VE_08: 0.59074,
        QA_09: 18.006,
        VA_09: 0.47313,
        QC_09: 50.207,
        VC_09: 0.59021,
        QE_09: 50.207,
        VE_09: 0.59021,
        QA_10: 20.451,
        VA_10: 0.48466,
        QC_10: 61.294,
        VC_10: 0.62171,
        QE_10: 61.294,
        VE_10: 0.62171,
        QA_11: 78.395,
        VA_11: 0.66818,
        QC_11: 106.431,
        VC_11: 0.72838,
        QE_11: 106.431,
        VE_11: 0.72838,
        QA_12: 118.33,
        VA_12: 0.7561,
        QC_12: 101.711,
        VC_12: 0.71839,
        QE_12: 101.711,
        VE_12: 0.71839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.616268317088497, 35.840226611035575, 0.0],
          [-81.619502783750207, 35.839150477703811, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288744.0,
        COMID: 166740269,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.074,
        REACHCODE: "03050103017705",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00078187665293000003,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094173.0,
        ToNode: 250094174.0,
        Hydroseq: 250005331.0,
        LevelPathI: 250005331.0,
        Pathlength: 393.55,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.265,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005331.0,
        UpHydroseq: 250005343.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005317.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9368.3466,
        DivDASqKM: 0.1332,
        Tidal: 0,
        TOTMA: 0.0061206245696300001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12728.0,
        MAXELEVSMO: 12739.0,
        MINELEVSMO: 12732.0,
        SLOPE: 0.00094594,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 0.051,
        VA_MA: 0.4591,
        QC_MA: 0.051,
        VC_MA: 0.4591,
        QE_MA: 0.051,
        VE_MA: 0.4591,
        QA_01: 0.112,
        VA_01: 0.53725,
        QC_01: 0.112,
        VC_01: 0.53725,
        QE_01: 0.112,
        VE_01: 0.53725,
        QA_02: 0.119,
        VA_02: 0.54473,
        QC_02: 0.119,
        VC_02: 0.54473,
        QE_02: 0.119,
        VE_02: 0.54473,
        QA_03: 0.128,
        VA_03: 0.55406,
        QC_03: 0.128,
        VC_03: 0.55406,
        QE_03: 0.128,
        VE_03: 0.55406,
        QA_04: 0.073,
        VA_04: 0.49072,
        QC_04: 0.073,
        VC_04: 0.49072,
        QE_04: 0.073,
        VE_04: 0.49072,
        QA_05: 0.041,
        VA_05: 0.44261,
        QC_05: 0.041,
        VC_05: 0.44261,
        QE_05: 0.041,
        VE_05: 0.44261,
        QA_06: 0.026,
        VA_06: 0.41378,
        QC_06: 0.026,
        VC_06: 0.41378,
        QE_06: 0.026,
        VE_06: 0.41378,
        QA_07: 0.017,
        VA_07: 0.39249,
        QC_07: 0.017,
        VC_07: 0.39249,
        QE_07: 0.017,
        VE_07: 0.39249,
        QA_08: 0.014,
        VA_08: 0.38425,
        QC_08: 0.014,
        VC_08: 0.38425,
        QE_08: 0.014,
        VE_08: 0.38425,
        QA_09: 0.01,
        VA_09: 0.37184,
        QC_09: 0.01,
        VC_09: 0.37184,
        QE_09: 0.01,
        VE_09: 0.37184,
        QA_10: 0.008,
        VA_10: 0.36475,
        QC_10: 0.008,
        VC_10: 0.36475,
        QE_10: 0.008,
        VE_10: 0.36475,
        QA_11: 0.008,
        VA_11: 0.36475,
        QC_11: 0.008,
        VC_11: 0.36475,
        QE_11: 0.008,
        VE_11: 0.36475,
        QA_12: 0.057,
        VA_12: 0.46827,
        QC_12: 0.057,
        VC_12: 0.46827,
        QE_12: 0.057,
        VE_12: 0.46827,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875287784905368, 34.735968946082949, 0.0],
          [-80.87458425157314, 34.73562781275001, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288745.0,
        COMID: 166740270,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.145,
        REACHCODE: "03050103017706",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001300573883126,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094174.0,
        ToNode: 250094176.0,
        Hydroseq: 250005317.0,
        LevelPathI: 250003258.0,
        Pathlength: 393.405,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.826,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005377.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005293.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1863,
        TotDASqKM: 9368.6949,
        DivDASqKM: 9368.6949,
        Tidal: 0,
        TOTMA: 0.0042678504274199997,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12730.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.144999995827675,
        QA_MA: 4693.97,
        VA_MA: 1.2791,
        QC_MA: 4693.97,
        VC_MA: 1.2791,
        QE_MA: 5395.806,
        VE_MA: 1.29012,
        QA_01: 8565.31,
        VA_01: 1.64433,
        QC_01: 8565.31,
        VC_01: 1.64433,
        QE_01: 7129.902,
        VE_01: 1.44669,
        QA_02: 9055.351,
        VA_02: 1.68438,
        QC_02: 9055.351,
        VC_02: 1.68438,
        QE_02: 7244.173,
        VE_02: 1.45634,
        QA_03: 9948.192,
        VA_03: 1.75483,
        QC_03: 9948.192,
        VC_03: 1.75483,
        QE_03: 7712.47,
        VE_03: 1.49516,
        QA_04: 6886.298,
        VA_04: 1.49818,
        QC_04: 6886.298,
        VC_04: 1.49818,
        QE_04: 6665.066,
        VE_04: 1.40666,
        QA_05: 4724.408,
        VA_05: 1.28243,
        QC_05: 4724.408,
        VC_05: 1.28243,
        QE_05: 5710.0,
        VE_05: 1.32007,
        QA_06: 2784.422,
        VA_06: 1.04401,
        QC_06: 2784.422,
        VC_06: 1.04401,
        QE_06: 5023.388,
        VE_06: 1.25353,
        QA_07: 1725.522,
        VA_07: 0.87895,
        QC_07: 1725.522,
        VC_07: 0.87895,
        QE_07: 3817.139,
        VE_07: 1.12529,
        QA_08: 1226.991,
        VA_08: 0.78446,
        QC_08: 1226.991,
        VC_08: 0.78446,
        QE_08: 3564.832,
        VE_08: 1.09616,
        QA_09: 1123.052,
        VA_09: 0.7626,
        QC_09: 1123.052,
        VC_09: 0.7626,
        QE_09: 3267.45,
        VE_09: 1.06056,
        QA_10: 1216.411,
        VA_10: 0.78228,
        QC_10: 1216.411,
        VC_10: 0.78228,
        QE_10: 4459.739,
        VE_10: 1.19565,
        QA_11: 3145.767,
        VA_11: 1.09325,
        QC_11: 3145.767,
        VC_11: 1.09325,
        QE_11: 4522.28,
        VE_11: 1.20224,
        QA_12: 5957.931,
        VA_12: 1.41012,
        QC_12: 5957.931,
        VC_12: 1.41012,
        QE_12: 5666.61,
        VE_12: 1.31598,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87458425157314, 34.73562781275001, 0.0],
          [-80.874562984906504, 34.734327412752179, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288746.0,
        COMID: 166740271,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.067,
        REACHCODE: "03050103017707",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00070207076769600004,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094175.0,
        ToNode: 250094176.0,
        Hydroseq: 250005304.0,
        LevelPathI: 250005304.0,
        Pathlength: 393.405,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.341,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005322.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005293.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9368.3646,
        DivDASqKM: 0.0477,
        Tidal: 0,
        TOTMA: 0.0069499548468400004,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12718.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.067000001668929998,
        QA_MA: 0.018,
        VA_MA: 0.36607,
        QC_MA: 0.018,
        VC_MA: 0.36607,
        QE_MA: 0.018,
        VE_MA: 0.36607,
        QA_01: 0.04,
        VA_01: 0.39653,
        QC_01: 0.04,
        VC_01: 0.39653,
        QE_01: 0.04,
        VE_01: 0.39653,
        QA_02: 0.042,
        VA_02: 0.39884,
        QC_02: 0.042,
        VC_02: 0.39884,
        QE_02: 0.042,
        VE_02: 0.39884,
        QA_03: 0.046,
        VA_03: 0.40332,
        QC_03: 0.046,
        VC_03: 0.40332,
        QE_03: 0.046,
        VE_03: 0.40332,
        QA_04: 0.026,
        VA_04: 0.37851,
        QC_04: 0.026,
        VC_04: 0.37851,
        QE_04: 0.026,
        VE_04: 0.37851,
        QA_05: 0.014,
        VA_05: 0.35886,
        QC_05: 0.014,
        VC_05: 0.35886,
        QE_05: 0.014,
        VE_05: 0.35886,
        QA_06: 0.009,
        VA_06: 0.34831,
        QC_06: 0.009,
        VC_06: 0.34831,
        QE_06: 0.009,
        VE_06: 0.34831,
        QA_07: 0.006,
        VA_07: 0.34058,
        QC_07: 0.006,
        VC_07: 0.34058,
        QE_07: 0.006,
        VE_07: 0.34058,
        QA_08: 0.005,
        VA_08: 0.33761,
        QC_08: 0.005,
        VC_08: 0.33761,
        QE_08: 0.005,
        VE_08: 0.33761,
        QA_09: 0.003,
        VA_09: 0.33066,
        QC_09: 0.003,
        VC_09: 0.33066,
        QE_09: 0.003,
        VE_09: 0.33066,
        QA_10: 0.003,
        VA_10: 0.33066,
        QC_10: 0.003,
        VC_10: 0.33066,
        QE_10: 0.003,
        VE_10: 0.33066,
        QA_11: 0.003,
        VA_11: 0.33066,
        QC_11: 0.003,
        VC_11: 0.33066,
        QE_11: 0.003,
        VE_11: 0.33066,
        QA_12: 0.02,
        VA_12: 0.36939,
        QC_12: 0.02,
        VC_12: 0.36939,
        QE_12: 0.02,
        VE_12: 0.36939,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875183584905528, 34.734655679418267, 0.0],
          [-80.874562984906504, 34.734327412752179, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288755.0,
        COMID: 166740280,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.42,
        REACHCODE: "03050103017716",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.004183203075038,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094184.0,
        ToNode: 250094185.0,
        Hydroseq: 250005753.0,
        LevelPathI: 250003258.0,
        Pathlength: 400.327,
        TerminalPa: 250002604.0,
        ArbolateSu: 6821.081,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005829.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005739.0,
        DnDrainCou: 2,
        DnHydroseq: 250005738.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2628,
        TotDASqKM: 9190.1835,
        DivDASqKM: 9190.1835,
        Tidal: 0,
        TOTMA: 0.0066660511096500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13600.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13600.0,
        SLOPE: 0.00116666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.41999998688697798,
        QA_MA: 4622.443,
        VA_MA: 2.36877,
        QC_MA: 4622.443,
        VC_MA: 2.36877,
        QE_MA: 5324.2,
        VE_MA: 2.3925,
        QA_01: 8408.715,
        VA_01: 3.13934,
        QC_01: 8408.715,
        VC_01: 3.13934,
        QE_01: 6973.644,
        VE_01: 2.71362,
        QA_02: 8892.403,
        VA_02: 3.22468,
        QC_02: 8892.403,
        VC_02: 3.22468,
        QE_02: 7081.574,
        VE_02: 2.73331,
        QA_03: 9773.935,
        VA_03: 3.37478,
        QC_03: 9773.935,
        VC_03: 3.37478,
        QE_03: 7538.743,
        VE_03: 2.81522,
        QA_04: 6785.783,
        VA_04: 2.83466,
        QC_04: 6785.783,
        VC_04: 2.83466,
        QE_04: 6564.68,
        VE_04: 2.63766,
        QA_05: 4668.466,
        VA_05: 2.37964,
        QC_05: 4668.466,
        VC_05: 2.37964,
        QE_05: 5654.05,
        VE_05: 2.46009,
        QA_06: 2748.421,
        VA_06: 1.87173,
        QC_06: 2748.421,
        VC_06: 1.87173,
        QE_06: 4987.055,
        VE_06: 2.32135,
        QA_07: 1699.331,
        VA_07: 1.51949,
        QC_07: 1699.331,
        VC_07: 1.51949,
        QE_07: 3790.648,
        VE_07: 2.0485,
        QA_08: 1207.79,
        VA_08: 1.31867,
        QC_08: 1207.79,
        VC_08: 1.31867,
        QE_08: 3545.217,
        VE_08: 1.98773,
        QA_09: 1107.768,
        VA_09: 1.27334,
        QC_09: 1107.768,
        VC_09: 1.27334,
        QE_09: 3251.831,
        VE_09: 1.91244,
        QA_10: 1204.41,
        VA_10: 1.31716,
        QC_10: 1204.41,
        VC_10: 1.31716,
        QE_10: 4447.187,
        VE_10: 2.20253,
        QA_11: 3130.324,
        VA_11: 1.98356,
        QC_11: 3130.324,
        VC_11: 1.98356,
        QE_11: 4506.551,
        VE_11: 2.21592,
        QA_12: 5873.468,
        VA_12: 2.64822,
        QC_12: 5873.468,
        VC_12: 2.64822,
        QE_12: 5582.303,
        VE_12: 2.44555,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883171384893103, 34.794897745991364, 0.0],
          [-80.8800031182314, 34.79216621266238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288756.0,
        COMID: 166740281,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.115,
        REACHCODE: "03050103017717",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001255749980249,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094185.0,
        ToNode: 250059342.0,
        Hydroseq: 250005739.0,
        LevelPathI: 250005706.0,
        Pathlength: 400.264,
        TerminalPa: 250002604.0,
        ArbolateSu: 6821.196,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005753.0,
        DnLevelPat: 250005706.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005729.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9190.1835,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.066557824653500006,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13600.0,
        MINELEVSMO: 13600.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.115000002086163,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8800031182314, 34.79216621266238, 0.0],
          [-80.878766318233332, 34.791948879329368, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288757.0,
        COMID: 166740282,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.302,
        REACHCODE: "03050103017718",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0029072577948879999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094185.0,
        ToNode: 250059967.0,
        Hydroseq: 250005738.0,
        LevelPathI: 250003258.0,
        Pathlength: 400.025,
        TerminalPa: 250002604.0,
        ArbolateSu: 6821.383,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005753.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005697.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0783,
        TotDASqKM: 9190.2618,
        DivDASqKM: 9190.2618,
        Tidal: 0,
        TOTMA: 0.0054949545754699996,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13587.0,
        MAXELEVSMO: 13600.0,
        MINELEVSMO: 13587.0,
        SLOPE: 0.00043046,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30199998617172202,
        QA_MA: 4622.474,
        VA_MA: 2.06672,
        QC_MA: 4622.474,
        VC_MA: 2.06672,
        QE_MA: 5324.231,
        VE_MA: 2.08696,
        QA_01: 8408.784,
        VA_01: 2.72432,
        QC_01: 8408.784,
        VC_01: 2.72432,
        QE_01: 6973.712,
        VE_01: 2.36101,
        QA_02: 8892.473,
        VA_02: 2.79715,
        QC_02: 8892.473,
        VC_02: 2.79715,
        QE_02: 7081.644,
        VE_02: 2.37782,
        QA_03: 9774.011,
        VA_03: 2.92525,
        QC_03: 9774.011,
        VC_03: 2.92525,
        QE_03: 7538.818,
        VE_03: 2.44772,
        QA_04: 6785.827,
        VA_04: 2.46431,
        QC_04: 6785.827,
        VC_04: 2.46431,
        QE_04: 6564.724,
        VE_04: 2.29618,
        QA_05: 4668.49,
        VA_05: 2.07599,
        QC_05: 4668.49,
        VC_05: 2.07599,
        QE_05: 5654.074,
        VE_05: 2.14465,
        QA_06: 2748.436,
        VA_06: 1.64255,
        QC_06: 2748.436,
        VC_06: 1.64255,
        QE_06: 4987.071,
        VE_06: 2.02624,
        QA_07: 1699.343,
        VA_07: 1.34194,
        QC_07: 1699.343,
        VC_07: 1.34194,
        QE_07: 3790.66,
        VE_07: 1.79339,
        QA_08: 1207.798,
        VA_08: 1.17056,
        QC_08: 1207.798,
        VC_08: 1.17056,
        QE_08: 3545.225,
        VE_08: 1.74154,
        QA_09: 1107.775,
        VA_09: 1.13188,
        QC_09: 1107.775,
        VC_09: 1.13188,
        QE_09: 3251.837,
        VE_09: 1.67729,
        QA_10: 1204.415,
        VA_10: 1.16928,
        QC_10: 1204.415,
        VC_10: 1.16928,
        QE_10: 4447.193,
        VE_10: 1.92484,
        QA_11: 3130.329,
        VA_11: 1.73798,
        QC_11: 3130.329,
        VC_11: 1.73798,
        QE_11: 4506.557,
        VE_11: 1.93627,
        QA_12: 5873.504,
        VA_12: 2.3052,
        QC_12: 5873.504,
        VC_12: 2.3052,
        QE_12: 5582.338,
        VE_12: 2.13224,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8800031182314, 34.79216621266238, 0.0],
          [-80.87813991823424, 34.789934479332317, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286795.0,
        COMID: 9734544,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.695,
        REACHCODE: "03050103000079",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017969973498159,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059427.0,
        ToNode: 250059434.0,
        Hydroseq: 250026286.0,
        LevelPathI: 250023116.0,
        Pathlength: 408.271,
        TerminalPa: 250002604.0,
        ArbolateSu: 68.061,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250027085.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025545.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2806,
        TotDASqKM: 60.4404,
        DivDASqKM: 60.4404,
        Tidal: 0,
        TOTMA: 0.064804373117800004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13673.0,
        MAXELEVSMO: 14076.0,
        MINELEVSMO: 13775.0,
        SLOPE: 0.00177581,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.695000052452087,
        QA_MA: 23.891,
        VA_MA: 0.98942,
        QC_MA: 25.581,
        VC_MA: 0.9932,
        QE_MA: 25.581,
        VE_MA: 0.9932,
        QA_01: 52.894,
        VA_01: 1.34701,
        QC_01: 43.229,
        VC_01: 1.21322,
        QE_01: 43.229,
        VE_01: 1.21322,
        QA_02: 54.69,
        VA_02: 1.36559,
        QC_02: 47.777,
        VC_02: 1.26258,
        QE_02: 47.777,
        VE_02: 1.26258,
        QA_03: 58.422,
        VA_03: 1.4033,
        QC_03: 52.014,
        VC_03: 1.30662,
        QE_03: 52.014,
        VE_03: 1.30662,
        QA_04: 33.255,
        VA_04: 1.12016,
        QC_04: 32.136,
        VC_04: 1.0814,
        QE_04: 32.136,
        VE_04: 1.0814,
        QA_05: 18.691,
        VA_05: 0.9062,
        QC_05: 19.023,
        VC_05: 0.89354,
        QE_05: 19.023,
        VE_05: 0.89354,
        QA_06: 12.115,
        VA_06: 0.78325,
        QC_06: 15.622,
        VC_06: 0.83543,
        QE_06: 15.622,
        VE_06: 0.83543,
        QA_07: 8.939,
        VA_07: 0.71246,
        QC_07: 14.15,
        VC_07: 0.80845,
        QE_07: 14.15,
        VE_07: 0.80845,
        QA_08: 6.501,
        VA_08: 0.6496,
        QC_08: 14.001,
        VC_08: 0.80564,
        QE_08: 14.001,
        VE_08: 0.80564,
        QA_09: 4.876,
        VA_09: 0.60127,
        QC_09: 15.843,
        VC_09: 0.83937,
        QE_09: 15.843,
        VE_09: 0.83937,
        QA_10: 4.063,
        VA_10: 0.57423,
        QC_10: 16.856,
        VC_10: 0.85714,
        QE_10: 16.856,
        VE_10: 0.85714,
        QA_11: 4.43,
        VA_11: 0.58672,
        QC_11: 11.896,
        VC_11: 0.76443,
        QE_11: 11.896,
        VE_11: 0.76443,
        QA_12: 28.022,
        VA_12: 1.04961,
        QC_12: 27.427,
        VC_12: 1.01902,
        QE_12: 27.427,
        VE_12: 1.01902,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.760939185082819, 34.763495412706902, 0.0],
          [-80.77728731839079, 34.763829879372963, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288758.0,
        COMID: 166740283,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.141,
        REACHCODE: "03050103017719",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001260538225309,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094187.0,
        ToNode: 250059967.0,
        Hydroseq: 250005706.0,
        LevelPathI: 250005706.0,
        Pathlength: 400.025,
        TerminalPa: 250002604.0,
        ArbolateSu: 6821.435,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005706.0,
        UpHydroseq: 250005729.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005697.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0387,
        TotDASqKM: 9190.2762,
        DivDASqKM: 0.0927,
        Tidal: 0,
        TOTMA: 0.014249610663500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13549.0,
        MAXELEVSMO: 13587.0,
        MINELEVSMO: 13587.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14100000262260401,
        QA_MA: 0.036,
        VA_MA: 0.37574,
        QC_MA: 0.036,
        VC_MA: 0.37574,
        QE_MA: 0.036,
        VE_MA: 0.37574,
        QA_01: 0.081,
        VA_01: 0.41198,
        QC_01: 0.081,
        VC_01: 0.41198,
        QE_01: 0.081,
        VE_01: 0.41198,
        QA_02: 0.083,
        VA_02: 0.41333,
        QC_02: 0.083,
        VC_02: 0.41333,
        QE_02: 0.083,
        VE_02: 0.41333,
        QA_03: 0.089,
        VA_03: 0.41729,
        QC_03: 0.089,
        VC_03: 0.41729,
        QE_03: 0.089,
        VE_03: 0.41729,
        QA_04: 0.052,
        VA_04: 0.39026,
        QC_04: 0.052,
        VC_04: 0.39026,
        QE_04: 0.052,
        VE_04: 0.39026,
        QA_05: 0.028,
        VA_05: 0.36732,
        QC_05: 0.028,
        VC_05: 0.36732,
        QE_05: 0.028,
        VE_05: 0.36732,
        QA_06: 0.018,
        VA_06: 0.355,
        QC_06: 0.018,
        VC_06: 0.355,
        QE_06: 0.018,
        VE_06: 0.355,
        QA_07: 0.013,
        VA_07: 0.3476,
        QC_07: 0.013,
        VC_07: 0.3476,
        QE_07: 0.013,
        VE_07: 0.3476,
        QA_08: 0.009,
        VA_08: 0.34065,
        QC_08: 0.009,
        VC_08: 0.34065,
        QE_08: 0.009,
        VE_08: 0.34065,
        QA_09: 0.007,
        VA_09: 0.33662,
        QC_09: 0.007,
        VC_09: 0.33662,
        QE_09: 0.007,
        VE_09: 0.33662,
        QA_10: 0.006,
        VA_10: 0.3344,
        QC_10: 0.006,
        VC_10: 0.3344,
        QE_10: 0.006,
        VE_10: 0.3344,
        QA_11: 0.006,
        VA_11: 0.3344,
        QC_11: 0.006,
        VC_11: 0.3344,
        QE_11: 0.006,
        VE_11: 0.3344,
        QA_12: 0.042,
        VA_12: 0.38148,
        QC_12: 0.042,
        VC_12: 0.38148,
        QE_12: 0.042,
        VE_12: 0.38148,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878210984900775, 34.791193012663769, 0.0],
          [-80.87813991823424, 34.789934479332317, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286796.0,
        COMID: 9734542,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.51,
        REACHCODE: "03050103000080",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0054124128845260003,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059426.0,
        ToNode: 250059427.0,
        Hydroseq: 250027085.0,
        LevelPathI: 250023116.0,
        Pathlength: 409.966,
        TerminalPa: 250002604.0,
        ArbolateSu: 64.542,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250027959.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026286.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7461,
        TotDASqKM: 57.0168,
        DivDASqKM: 57.0168,
        Tidal: 0,
        TOTMA: 0.020608339805799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14009.0,
        MAXELEVSMO: 14135.0,
        MINELEVSMO: 14076.0,
        SLOPE: 0.00115686,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50999999046325695,
        QA_MA: 22.546,
        VA_MA: 0.93612,
        QC_MA: 24.196,
        VC_MA: 0.93972,
        QE_MA: 24.196,
        VE_MA: 0.93972,
        QA_01: 49.929,
        VA_01: 1.26584,
        QC_01: 40.868,
        VC_01: 1.14233,
        QE_01: 40.868,
        VE_01: 1.14233,
        QA_02: 51.606,
        VA_02: 1.28279,
        QC_02: 45.154,
        VC_02: 1.18768,
        QE_02: 45.154,
        VE_02: 1.18768,
        QA_03: 55.115,
        VA_03: 1.31742,
        QC_03: 49.164,
        VC_03: 1.22832,
        QE_03: 49.164,
        VE_03: 1.22832,
        QA_04: 31.367,
        VA_04: 1.05642,
        QC_04: 30.353,
        VC_04: 1.02049,
        QE_04: 30.353,
        VE_04: 1.02049,
        QA_05: 17.632,
        VA_05: 0.85929,
        QC_05: 17.985,
        VC_05: 0.84771,
        QE_05: 17.985,
        VE_05: 0.84771,
        QA_06: 11.431,
        VA_06: 0.74605,
        QC_06: 14.752,
        VC_06: 0.79384,
        QE_06: 14.752,
        VE_06: 0.79384,
        QA_07: 8.433,
        VA_07: 0.68077,
        QC_07: 13.367,
        VC_07: 0.76908,
        QE_07: 13.367,
        VE_07: 0.76908,
        QA_08: 6.133,
        VA_08: 0.62283,
        QC_08: 13.192,
        VC_08: 0.76587,
        QE_08: 13.192,
        VE_08: 0.76587,
        QA_09: 4.6,
        VA_09: 0.5783,
        QC_09: 15.048,
        VC_09: 0.79899,
        QE_09: 15.048,
        VE_09: 0.79899,
        QA_10: 3.833,
        VA_10: 0.55338,
        QC_10: 16.089,
        VC_10: 0.81673,
        QE_10: 16.089,
        VE_10: 0.81673,
        QA_11: 4.2,
        VA_11: 0.56557,
        QC_11: 11.422,
        VC_11: 0.73218,
        QE_11: 11.422,
        VE_11: 0.73218,
        QA_12: 26.474,
        VA_12: 0.992,
        QC_12: 26.046,
        VC_12: 0.96491,
        QE_12: 26.046,
        VE_12: 0.96491,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.756053718423743, 34.763788679372965, 0.0],
          [-80.760939185082819, 34.763495412706902, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288751.0,
        COMID: 166740276,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.067,
        REACHCODE: "03050103017712",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00070148092436700003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094180.0,
        ToNode: 250059324.0,
        Hydroseq: 250005830.0,
        LevelPathI: 250005809.0,
        Pathlength: 400.996,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.924,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005854.0,
        DnLevelPat: 250005809.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005809.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9187.0965,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.038777167669299999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13629.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.067000001668929998,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884734384890635, 34.797007812654783, 0.0],
          [-80.884097918225052, 34.796712879321888, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286797.0,
        COMID: 9734534,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.256,
        REACHCODE: "03050103000081",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0026307793960360002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059425.0,
        ToNode: 250059426.0,
        Hydroseq: 250027959.0,
        LevelPathI: 250023116.0,
        Pathlength: 410.476,
        TerminalPa: 250002604.0,
        ArbolateSu: 62.305,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250028916.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027085.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1287,
        TotDASqKM: 55.6632,
        DivDASqKM: 55.6632,
        Tidal: 0,
        TOTMA: 0.0112280346845,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14041.0,
        MAXELEVSMO: 14149.0,
        MINELEVSMO: 14135.0,
        SLOPE: 0.00054687,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25600001215934798,
        QA_MA: 22.015,
        VA_MA: 0.86256,
        QC_MA: 23.648,
        VC_MA: 0.86578,
        QE_MA: 23.648,
        VE_MA: 0.86578,
        QA_01: 48.756,
        VA_01: 1.15367,
        QC_01: 39.933,
        VC_01: 1.04457,
        QE_01: 39.933,
        VE_01: 1.04457,
        QA_02: 50.387,
        VA_02: 1.16857,
        QC_02: 44.116,
        VC_02: 1.08456,
        QE_02: 44.116,
        VE_02: 1.08456,
        QA_03: 53.808,
        VA_03: 1.19911,
        QC_03: 48.036,
        VC_03: 1.12045,
        QE_03: 48.036,
        VE_03: 1.12045,
        QA_04: 30.621,
        VA_04: 0.96868,
        QC_04: 29.647,
        VC_04: 0.93688,
        QE_04: 29.647,
        VE_04: 0.93688,
        QA_05: 17.213,
        VA_05: 0.79468,
        QC_05: 17.574,
        VC_05: 0.78449,
        QE_05: 17.574,
        VE_05: 0.78449,
        QA_06: 11.161,
        VA_06: 0.69475,
        QC_06: 14.408,
        VC_06: 0.73683,
        QE_06: 14.408,
        VE_06: 0.73683,
        QA_07: 8.232,
        VA_07: 0.63708,
        QC_07: 13.057,
        VC_07: 0.71501,
        QE_07: 13.057,
        VE_07: 0.71501,
        QA_08: 5.987,
        VA_08: 0.58595,
        QC_08: 12.873,
        VC_08: 0.71195,
        QE_08: 12.873,
        VE_08: 0.71195,
        QA_09: 4.49,
        VA_09: 0.54662,
        QC_09: 14.732,
        VC_09: 0.74192,
        QE_09: 14.732,
        VE_09: 0.74192,
        QA_10: 3.742,
        VA_10: 0.52465,
        QC_10: 15.783,
        VC_10: 0.75808,
        QE_10: 15.783,
        VE_10: 0.75808,
        QA_11: 4.109,
        VA_11: 0.53566,
        QC_11: 11.233,
        VC_11: 0.68378,
        QE_11: 11.233,
        VE_11: 0.68378,
        QA_12: 25.862,
        VA_12: 0.91203,
        QC_12: 25.497,
        VC_12: 0.88851,
        QE_12: 25.497,
        VE_12: 0.88851,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.753887318427132, 34.764840212704826, 0.0],
          [-80.756053718423743, 34.763788679372965, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288752.0,
        COMID: 166740277,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.073,
        REACHCODE: "03050103017713",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00065119391103200005,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094181.0,
        ToNode: 250094184.0,
        Hydroseq: 250005769.0,
        LevelPathI: 250005769.0,
        Pathlength: 400.747,
        TerminalPa: 250002604.0,
        ArbolateSu: 6816.377,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005769.0,
        UpHydroseq: 250005790.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005753.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 9187.1595,
        DivDASqKM: 0.063,
        Tidal: 0,
        TOTMA: 0.0074935282703799998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13641.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.072999998927116005,
        QA_MA: 0.024,
        VA_MA: 0.36992,
        QC_MA: 0.024,
        VC_MA: 0.36992,
        QE_MA: 0.024,
        VE_MA: 0.36992,
        QA_01: 0.055,
        VA_01: 0.40396,
        QC_01: 0.055,
        VC_01: 0.40396,
        QE_01: 0.055,
        VE_01: 0.40396,
        QA_02: 0.056,
        VA_02: 0.40488,
        QC_02: 0.056,
        VC_02: 0.40488,
        QE_02: 0.056,
        VE_02: 0.40488,
        QA_03: 0.06,
        VA_03: 0.40848,
        QC_03: 0.06,
        VC_03: 0.40848,
        QE_03: 0.06,
        VE_03: 0.40848,
        QA_04: 0.035,
        VA_04: 0.38357,
        QC_04: 0.035,
        VC_04: 0.38357,
        QE_04: 0.035,
        VE_04: 0.38357,
        QA_05: 0.019,
        VA_05: 0.36274,
        QC_05: 0.019,
        VC_05: 0.36274,
        QE_05: 0.019,
        VE_05: 0.36274,
        QA_06: 0.012,
        VA_06: 0.35098,
        QC_06: 0.012,
        VC_06: 0.35098,
        QE_06: 0.012,
        VE_06: 0.35098,
        QA_07: 0.009,
        VA_07: 0.34494,
        QC_07: 0.009,
        VC_07: 0.34494,
        QE_07: 0.009,
        VE_07: 0.34494,
        QA_08: 0.006,
        VA_08: 0.33786,
        QC_08: 0.006,
        VC_08: 0.33786,
        QE_08: 0.006,
        VE_08: 0.33786,
        QA_09: 0.005,
        VA_09: 0.33514,
        QC_09: 0.005,
        VC_09: 0.33514,
        QE_09: 0.005,
        VE_09: 0.33514,
        QA_10: 0.004,
        VA_10: 0.33215,
        QC_10: 0.004,
        VC_10: 0.33215,
        QE_10: 0.004,
        VE_10: 0.33215,
        QA_11: 0.004,
        VA_11: 0.33215,
        QC_11: 0.004,
        VC_11: 0.33215,
        QE_11: 0.004,
        VE_11: 0.33215,
        QA_12: 0.028,
        VA_12: 0.37517,
        QC_12: 0.028,
        VC_12: 0.37517,
        QE_12: 0.028,
        VE_12: 0.37517,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8830981848932, 34.795544812656999, 0.0],
          [-80.883171384893103, 34.794897745991364, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286798.0,
        COMID: 9736058,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 3.721,
        REACHCODE: "03050103000082",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.037887625060537999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059823.0,
        ToNode: 250059425.0,
        Hydroseq: 250028916.0,
        LevelPathI: 250023116.0,
        Pathlength: 410.732,
        TerminalPa: 250002604.0,
        ArbolateSu: 55.371,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250029970.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027959.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.1642,
        TotDASqKM: 49.9941,
        DivDASqKM: 49.9941,
        Tidal: 0,
        TOTMA: 0.14897136461300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14123.0,
        MAXELEVSMO: 14715.0,
        MINELEVSMO: 14149.0,
        SLOPE: 0.00152109,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.720999956130981,
        QA_MA: 19.781,
        VA_MA: 0.94457,
        QC_MA: 21.336,
        VC_MA: 0.94848,
        QE_MA: 21.336,
        VE_MA: 0.94848,
        QA_01: 43.804,
        VA_01: 1.27871,
        QC_01: 35.978,
        VC_01: 1.15316,
        QE_01: 35.978,
        VE_01: 1.15316,
        QA_02: 45.28,
        VA_02: 1.29594,
        QC_02: 39.76,
        VC_02: 1.19921,
        QE_02: 39.76,
        VE_02: 1.19921,
        QA_03: 48.32,
        VA_03: 1.33061,
        QC_03: 43.29,
        VC_03: 1.24036,
        QE_03: 43.29,
        VE_03: 1.24036,
        QA_04: 27.496,
        VA_04: 1.06614,
        QC_04: 26.687,
        VC_04: 1.02925,
        QE_04: 26.687,
        VE_04: 1.02925,
        QA_05: 15.46,
        VA_05: 0.86652,
        QC_05: 15.848,
        VC_05: 0.85499,
        QE_05: 15.848,
        VE_05: 0.85499,
        QA_06: 10.018,
        VA_06: 0.75168,
        QC_06: 12.952,
        VC_06: 0.79945,
        QE_06: 12.952,
        VE_06: 0.79945,
        QA_07: 7.394,
        VA_07: 0.68565,
        QC_07: 11.757,
        VC_07: 0.77484,
        QE_07: 11.757,
        VE_07: 0.77484,
        QA_08: 5.377,
        VA_08: 0.62695,
        QC_08: 11.537,
        VC_08: 0.77019,
        QE_08: 11.537,
        VE_08: 0.77019,
        QA_09: 4.033,
        VA_09: 0.58183,
        QC_09: 13.399,
        VC_09: 0.80837,
        QE_09: 13.399,
        VE_09: 0.80837,
        QA_10: 3.361,
        VA_10: 0.5566,
        QC_10: 14.485,
        VC_10: 0.8295,
        QE_10: 14.485,
        VE_10: 0.8295,
        QA_11: 3.728,
        VA_11: 0.57064,
        QC_11: 10.429,
        VC_11: 0.74608,
        QE_11: 10.429,
        VE_11: 0.74608,
        QA_12: 23.265,
        VA_12: 1.0018,
        QC_12: 23.156,
        VC_12: 0.97691,
        QE_12: 23.156,
        VE_12: 0.97691,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.726958518468962, 34.76708147936796, 0.0],
          [-80.728565185133107, 34.764698012704969, 0.0],
          [-80.727647985134467, 34.763713812706499, 0.0],
          [-80.737243518452885, 34.758047279381969, 0.0],
          [-80.739330985116396, 34.762420279375192, 0.0],
          [-80.749798985100142, 34.765479212703731, 0.0],
          [-80.753887318427132, 34.764840212704826, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288753.0,
        COMID: 166740278,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.114,
        REACHCODE: "03050103017714",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001017621980188,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094179.0,
        ToNode: 250094180.0,
        Hydroseq: 250005854.0,
        LevelPathI: 250003258.0,
        Pathlength: 401.038,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.857,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005881.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005830.0,
        DnDrainCou: 2,
        DnHydroseq: 250005829.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0414,
        TotDASqKM: 9187.0965,
        DivDASqKM: 9187.0965,
        Tidal: 0,
        TOTMA: 0.0033656922929699998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13629.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.11400000005960501,
        QA_MA: 4621.224,
        VA_MA: 1.27505,
        QC_MA: 4621.224,
        VC_MA: 1.27505,
        QE_MA: 5322.98,
        VE_MA: 1.28618,
        QA_01: 8406.028,
        VA_01: 1.63653,
        QC_01: 8406.028,
        VC_01: 1.63653,
        QE_01: 6970.962,
        VE_01: 1.43675,
        QA_02: 8889.622,
        VA_02: 1.67657,
        QC_02: 8889.622,
        VC_02: 1.67657,
        QE_02: 7078.799,
        VE_02: 1.44598,
        QA_03: 9770.946,
        VA_03: 1.747,
        QC_03: 9770.946,
        VC_03: 1.747,
        QE_03: 7535.764,
        VE_03: 1.48441,
        QA_04: 6784.04,
        VA_04: 1.49363,
        QC_04: 6784.04,
        VC_04: 1.49363,
        QE_04: 6562.939,
        VE_04: 1.40118,
        QA_05: 4667.511,
        VA_05: 1.28018,
        QC_05: 4667.511,
        VC_05: 1.28018,
        QE_05: 5653.095,
        VE_05: 1.31793,
        QA_06: 2747.798,
        VA_06: 1.04187,
        QC_06: 2747.798,
        VC_06: 1.04187,
        QE_06: 4986.426,
        VE_06: 1.25285,
        QA_07: 1698.875,
        VA_07: 0.87659,
        QC_07: 1698.875,
        VC_07: 0.87659,
        QE_07: 3790.186,
        VE_07: 1.12484,
        QA_08: 1207.458,
        VA_08: 0.78237,
        QC_08: 1207.458,
        VC_08: 0.78237,
        QE_08: 3544.878,
        VE_08: 1.09634,
        QA_09: 1107.519,
        VA_09: 0.76112,
        QC_09: 1107.519,
        VC_09: 0.76112,
        QE_09: 3251.576,
        VE_09: 1.06102,
        QA_10: 1204.203,
        VA_10: 0.78169,
        QC_10: 1204.203,
        VC_10: 0.78169,
        QE_10: 4446.97,
        VE_10: 1.19714,
        QA_11: 3130.116,
        VA_11: 1.09441,
        QC_11: 3130.116,
        VC_11: 1.09441,
        QE_11: 4506.339,
        VE_11: 1.20342,
        QA_12: 5872.057,
        VA_12: 1.40617,
        QC_12: 5872.057,
        VC_12: 1.40617,
        QE_12: 5580.894,
        VE_12: 1.31106,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88479298489051, 34.798023745986541, 0.0],
          [-80.884734384890635, 34.797007812654783, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286791.0,
        COMID: 9734648,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.448,
        REACHCODE: "03050103000075",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014350858443461,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059460.0,
        ToNode: 250059488.0,
        Hydroseq: 250023659.0,
        LevelPathI: 250023116.0,
        Pathlength: 403.886,
        TerminalPa: 250002604.0,
        ArbolateSu: 78.919,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250024240.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023116.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8243,
        TotDASqKM: 70.7796,
        DivDASqKM: 70.7796,
        Tidal: 0,
        TOTMA: 0.054810150132499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13188.0,
        MAXELEVSMO: 13445.0,
        MINELEVSMO: 13220.0,
        SLOPE: 0.00155386,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.447999954223633,
        QA_MA: 27.942,
        VA_MA: 0.99968,
        QC_MA: 29.737,
        VC_MA: 1.00318,
        QE_MA: 29.737,
        VE_MA: 1.00318,
        QA_01: 61.795,
        VA_01: 1.36204,
        QC_01: 50.299,
        VC_01: 1.22685,
        QE_01: 50.299,
        VE_01: 1.22685,
        QA_02: 63.997,
        VA_02: 1.38181,
        QC_02: 55.668,
        VC_02: 1.27767,
        QE_02: 55.668,
        VE_02: 1.27767,
        QA_03: 68.411,
        VA_03: 1.42051,
        QC_03: 60.591,
        VC_03: 1.32228,
        QE_03: 60.591,
        VE_03: 1.32228,
        QA_04: 38.954,
        VA_04: 1.13306,
        QC_04: 37.509,
        VC_04: 1.09434,
        QE_04: 37.509,
        VE_04: 1.09434,
        QA_05: 21.888,
        VA_05: 0.91561,
        QC_05: 22.146,
        VC_05: 0.90252,
        QE_05: 22.146,
        VE_05: 0.90252,
        QA_06: 14.189,
        VA_06: 0.79076,
        QC_06: 18.258,
        VC_06: 0.84463,
        QE_06: 18.258,
        VE_06: 0.84463,
        QA_07: 10.468,
        VA_07: 0.71882,
        QC_07: 16.508,
        VC_07: 0.81669,
        QE_07: 16.508,
        VE_07: 0.81669,
        QA_08: 7.613,
        VA_08: 0.65497,
        QC_08: 16.448,
        VC_08: 0.81571,
        QE_08: 16.448,
        VE_08: 0.81571,
        QA_09: 5.71,
        VA_09: 0.60588,
        QC_09: 18.213,
        VC_09: 0.84393,
        QE_09: 18.213,
        VE_09: 0.84393,
        QA_10: 4.758,
        VA_10: 0.57842,
        QC_10: 19.122,
        VC_10: 0.85796,
        QE_10: 19.122,
        VE_10: 0.85796,
        QA_11: 5.101,
        VA_11: 0.58859,
        QC_11: 13.246,
        VC_11: 0.76062,
        QE_11: 13.246,
        VE_11: 0.76062,
        QA_12: 32.648,
        VA_12: 1.05925,
        QC_12: 31.517,
        VC_12: 1.02496,
        QE_12: 31.517,
        VE_12: 1.02496,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.792622318367023, 34.748991812729344, 0.0],
          [-80.795899318361933, 34.746680412732985, 0.0],
          [-80.796873585027072, 34.743497079404449, 0.0],
          [-80.802093585018952, 34.740247479409504, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288754.0,
        COMID: 166740279,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.291,
        REACHCODE: "03050103017715",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0028038828924280002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250094180.0,
        ToNode: 250094184.0,
        Hydroseq: 250005829.0,
        LevelPathI: 250003258.0,
        Pathlength: 400.747,
        TerminalPa: 250002604.0,
        ArbolateSu: 6816.148,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005854.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005753.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0495,
        TotDASqKM: 9187.146,
        DivDASqKM: 9187.146,
        Tidal: 0,
        TOTMA: 0.0085913058839000005,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13631.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.29100000858306901,
        QA_MA: 4621.243,
        VA_MA: 1.27505,
        QC_MA: 4621.243,
        VC_MA: 1.27505,
        QE_MA: 5322.999,
        VE_MA: 1.28619,
        QA_01: 8406.071,
        VA_01: 1.63654,
        QC_01: 8406.071,
        VC_01: 1.63654,
        QE_01: 6971.006,
        VE_01: 1.43675,
        QA_02: 8889.666,
        VA_02: 1.67658,
        QC_02: 8889.666,
        VC_02: 1.67658,
        QE_02: 7078.843,
        VE_02: 1.44599,
        QA_03: 9770.994,
        VA_03: 1.747,
        QC_03: 9770.994,
        VC_03: 1.747,
        QE_03: 7535.811,
        VE_03: 1.48441,
        QA_04: 6784.068,
        VA_04: 1.49364,
        QC_04: 6784.068,
        VC_04: 1.49364,
        QE_04: 6562.966,
        VE_04: 1.40119,
        QA_05: 4667.526,
        VA_05: 1.28018,
        QC_05: 4667.526,
        VC_05: 1.28018,
        QE_05: 5653.11,
        VE_05: 1.31793,
        QA_06: 2747.808,
        VA_06: 1.04187,
        QC_06: 2747.808,
        VC_06: 1.04187,
        QE_06: 4986.437,
        VE_06: 1.25285,
        QA_07: 1698.882,
        VA_07: 0.87659,
        QC_07: 1698.882,
        VC_07: 0.87659,
        QE_07: 3790.194,
        VE_07: 1.12484,
        QA_08: 1207.463,
        VA_08: 0.78237,
        QC_08: 1207.463,
        VC_08: 0.78237,
        QE_08: 3544.883,
        VE_08: 1.09634,
        QA_09: 1107.523,
        VA_09: 0.76112,
        QC_09: 1107.523,
        VC_09: 0.76112,
        QE_09: 3251.58,
        VE_09: 1.06102,
        QA_10: 1204.206,
        VA_10: 0.78169,
        QC_10: 1204.206,
        VC_10: 0.78169,
        QE_10: 4446.973,
        VE_10: 1.19714,
        QA_11: 3130.12,
        VA_11: 1.09441,
        QC_11: 3130.12,
        VC_11: 1.09441,
        QE_11: 4506.342,
        VE_11: 1.20342,
        QA_12: 5872.08,
        VA_12: 1.40617,
        QC_12: 5872.08,
        VC_12: 1.40617,
        QE_12: 5580.917,
        VE_12: 1.31106,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884734384890635, 34.797007812654783, 0.0],
          [-80.883171384893103, 34.794897745991364, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286792.0,
        COMID: 9736080,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.711,
        REACHCODE: "03050103000076",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0070329517298680002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059825.0,
        ToNode: 250059460.0,
        Hydroseq: 250024240.0,
        LevelPathI: 250023116.0,
        Pathlength: 405.334,
        TerminalPa: 250002604.0,
        ArbolateSu: 75.609,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250024867.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023659.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0674,
        TotDASqKM: 67.7223,
        DivDASqKM: 67.7223,
        Tidal: 0,
        TOTMA: 0.043774146158699997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13389.0,
        MAXELEVSMO: 13445.0,
        MINELEVSMO: 13445.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.71100002527236905,
        QA_MA: 26.745,
        VA_MA: 0.61517,
        QC_MA: 28.512,
        VC_MA: 0.61677,
        QE_MA: 28.512,
        VE_MA: 0.61677,
        QA_01: 59.164,
        VA_01: 0.77605,
        QC_01: 48.213,
        VC_01: 0.71598,
        QE_01: 48.213,
        VE_01: 0.71598,
        QA_02: 61.243,
        VA_02: 0.7847,
        QC_02: 53.336,
        VC_02: 0.73843,
        QE_02: 53.336,
        VE_02: 0.73843,
        QA_03: 65.461,
        VA_03: 0.80185,
        QC_03: 58.063,
        VC_03: 0.75827,
        QE_03: 58.063,
        VE_03: 0.75827,
        QA_04: 37.269,
        VA_04: 0.67428,
        QC_04: 35.922,
        VC_04: 0.65702,
        QE_04: 35.922,
        VE_04: 0.65702,
        QA_05: 20.942,
        VA_05: 0.57781,
        QC_05: 21.225,
        VC_05: 0.57203,
        QE_05: 21.225,
        VE_05: 0.57203,
        QA_06: 13.573,
        VA_06: 0.52239,
        QC_06: 17.475,
        VC_06: 0.54618,
        QE_06: 17.475,
        VE_06: 0.54618,
        QA_07: 10.016,
        VA_07: 0.4905,
        QC_07: 15.811,
        VC_07: 0.53387,
        QE_07: 15.811,
        VE_07: 0.53387,
        QA_08: 7.284,
        VA_08: 0.46216,
        QC_08: 15.724,
        VC_08: 0.53321,
        QE_08: 15.724,
        VE_08: 0.53321,
        QA_09: 5.463,
        VA_09: 0.44038,
        QC_09: 17.517,
        VC_09: 0.54648,
        QE_09: 17.517,
        VE_09: 0.54648,
        QA_10: 4.553,
        VA_10: 0.42821,
        QC_10: 18.46,
        VC_10: 0.5532,
        QE_10: 18.46,
        VE_10: 0.5532,
        QA_11: 4.912,
        VA_11: 0.43314,
        QC_11: 12.872,
        VC_11: 0.51055,
        QE_11: 12.872,
        VE_11: 0.51055,
        QA_12: 31.292,
        VA_12: 0.64185,
        QC_12: 30.324,
        VC_12: 0.62702,
        QE_12: 30.324,
        VE_12: 0.62702,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.787624185041409, 34.752842612723448, 0.0],
          [-80.792622318367023, 34.748991812729344, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286793.0,
        COMID: 9734616,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.161,
        REACHCODE: "03050103000077",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011195014936272001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059451.0,
        ToNode: 250059825.0,
        Hydroseq: 250024867.0,
        LevelPathI: 250023116.0,
        Pathlength: 406.045,
        TerminalPa: 250002604.0,
        ArbolateSu: 72.86,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250025545.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024240.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9837,
        TotDASqKM: 65.3598,
        DivDASqKM: 65.3598,
        Tidal: 0,
        TOTMA: 0.044616781539500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13443.0,
        MAXELEVSMO: 13621.0,
        MINELEVSMO: 13445.0,
        SLOPE: 0.00151593,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.16100001335144,
        QA_MA: 25.824,
        VA_MA: 0.98452,
        QC_MA: 27.567,
        VC_MA: 0.98811,
        QE_MA: 27.567,
        VE_MA: 0.98811,
        QA_01: 57.139,
        VA_01: 1.33921,
        QC_01: 46.605,
        VC_01: 1.2067,
        QE_01: 46.605,
        VE_01: 1.2067,
        QA_02: 59.122,
        VA_02: 1.35805,
        QC_02: 51.539,
        VC_02: 1.256,
        QE_02: 51.539,
        VE_02: 1.256,
        QA_03: 63.185,
        VA_03: 1.39576,
        QC_03: 56.109,
        VC_03: 1.29973,
        QE_03: 56.109,
        VE_03: 1.29973,
        QA_04: 35.966,
        VA_04: 1.11457,
        QC_04: 34.695,
        VC_04: 1.0764,
        QE_04: 34.695,
        VE_04: 1.0764,
        QA_05: 20.212,
        VA_05: 0.90204,
        QC_05: 20.512,
        VC_05: 0.88937,
        QE_05: 20.512,
        VE_05: 0.88937,
        QA_06: 13.107,
        VA_06: 0.78007,
        QC_06: 16.883,
        VC_06: 0.8323,
        QE_06: 16.883,
        VE_06: 0.8323,
        QA_07: 9.667,
        VA_07: 0.70966,
        QC_07: 15.273,
        VC_07: 0.80515,
        QE_07: 15.273,
        VE_07: 0.80515,
        QA_08: 7.03,
        VA_08: 0.64722,
        QC_08: 15.164,
        VC_08: 0.80327,
        QE_08: 15.164,
        VE_08: 0.80327,
        QA_09: 5.273,
        VA_09: 0.59923,
        QC_09: 16.976,
        VC_09: 0.83383,
        QE_09: 16.976,
        VE_09: 0.83383,
        QA_10: 4.394,
        VA_10: 0.57239,
        QC_10: 17.943,
        VC_10: 0.84952,
        QE_10: 17.943,
        VE_10: 0.84952,
        QA_11: 4.761,
        VA_11: 0.58388,
        QC_11: 12.568,
        VC_11: 0.7563,
        QE_11: 12.568,
        VE_11: 0.7563,
        QA_12: 30.25,
        VA_12: 1.04378,
        QC_12: 29.405,
        VC_12: 1.01182,
        QE_12: 29.405,
        VE_12: 1.01182,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.786311918376668, 34.760217279378537, 0.0],
          [-80.785426585044775, 34.757628412715974, 0.0],
          [-80.788982585039321, 34.754217812721215, 0.0],
          [-80.787624185041409, 34.752842612723448, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283647.0,
        COMID: 9753936,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.522,
        REACHCODE: "03050101000041",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19489058,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.016547443648464,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061443.0,
        ToNode: 250061459.0,
        Hydroseq: 250009873.0,
        LevelPathI: 250003258.0,
        Pathlength: 572.205,
        TerminalPa: 250002604.0,
        ArbolateSu: 3282.19,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009920.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009822.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7667,
        TotDASqKM: 3659.4792,
        DivDASqKM: 3659.4792,
        Tidal: 0,
        TOTMA: 0.030980828173700001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25436.0,
        MAXELEVSMO: 25537.0,
        MINELEVSMO: 25436.0,
        SLOPE: 0.0006636,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5219999551773069,
        QA_MA: 2199.211,
        VA_MA: 1.85496,
        QC_MA: 2199.211,
        VC_MA: 1.85496,
        QE_MA: 2391.267,
        VE_MA: 1.86549,
        QA_01: 3554.403,
        VA_01: 2.30404,
        QC_01: 3554.403,
        VC_01: 2.30404,
        QE_01: 3752.543,
        VE_01: 2.28641,
        QA_02: 3756.215,
        VA_02: 2.36342,
        QC_02: 3756.215,
        VC_02: 2.36342,
        QE_02: 3820.17,
        VE_02: 2.30526,
        QA_03: 4248.954,
        VA_03: 2.50243,
        QC_03: 4248.954,
        VC_03: 2.50243,
        QE_03: 4507.491,
        VE_03: 2.48862,
        QA_04: 3271.715,
        VA_04: 2.21812,
        QC_04: 3271.715,
        VC_04: 2.21812,
        QE_04: 3460.827,
        VE_04: 2.20321,
        QA_05: 2490.957,
        VA_05: 1.96072,
        QC_05: 2490.957,
        VC_05: 1.96072,
        QE_05: 2712.165,
        VE_05: 1.97316,
        QA_06: 1452.279,
        VA_06: 1.54912,
        QC_06: 1452.279,
        VC_06: 1.54912,
        QE_06: 1714.063,
        VE_06: 1.61316,
        QA_07: 857.933,
        VA_07: 1.24658,
        QC_07: 857.933,
        VC_07: 1.24658,
        QE_07: 1008.275,
        VE_07: 1.29278,
        QA_08: 602.45,
        VA_08: 1.08601,
        QC_08: 602.45,
        VC_08: 1.08601,
        QE_08: 903.905,
        VE_08: 1.23728,
        QA_09: 584.491,
        VA_09: 1.07361,
        QC_09: 1084.27,
        VC_09: 1.37078,
        QE_09: 1120.975,
        VE_09: 1.34975,
        QA_10: 748.76,
        VA_10: 1.18117,
        QC_10: 748.76,
        VC_10: 1.18117,
        QE_10: 689.19,
        VE_10: 1.1127,
        QA_11: 2033.781,
        VA_11: 1.79206,
        QC_11: 2033.781,
        VC_11: 1.79206,
        QE_11: 1978.0,
        VE_11: 1.71625,
        QA_12: 2802.873,
        VA_12: 2.06754,
        QC_12: 2802.873,
        VC_12: 2.06754,
        QE_12: 2812.24,
        VE_12: 2.0055,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.156899584468192, 35.827211211055726, 0.0],
          [-81.147860717815547, 35.829191811052624, 0.0],
          [-81.141258717825849, 35.827410411055325, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286794.0,
        COMID: 9734564,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.065,
        REACHCODE: "03050103000078",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010982607747356999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059434.0,
        ToNode: 250059451.0,
        Hydroseq: 250025545.0,
        LevelPathI: 250023116.0,
        Pathlength: 407.206,
        TerminalPa: 250002604.0,
        ArbolateSu: 70.239,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250026286.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024867.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4013,
        TotDASqKM: 63.0972,
        DivDASqKM: 63.0972,
        Tidal: 0,
        TOTMA: 0.041364159945,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13562.0,
        MAXELEVSMO: 13775.0,
        MINELEVSMO: 13621.0,
        SLOPE: 0.001446,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.065000057220459,
        QA_MA: 24.936,
        VA_MA: 0.97407,
        QC_MA: 26.656,
        VC_MA: 0.97768,
        QE_MA: 26.656,
        VE_MA: 0.97768,
        QA_01: 55.192,
        VA_01: 1.32344,
        QC_01: 45.058,
        VC_01: 1.19283,
        QE_01: 45.058,
        VE_01: 1.19283,
        QA_02: 57.084,
        VA_02: 1.34177,
        QC_02: 49.81,
        VC_02: 1.2412,
        QE_02: 49.81,
        VE_02: 1.2412,
        QA_03: 60.994,
        VA_03: 1.37877,
        QC_03: 54.227,
        VC_03: 1.28424,
        QE_03: 54.227,
        VE_03: 1.28424,
        QA_04: 34.719,
        VA_04: 1.10198,
        QC_04: 33.519,
        VC_04: 1.06426,
        QE_04: 33.519,
        VE_04: 1.06426,
        QA_05: 19.512,
        VA_05: 0.89278,
        QC_05: 19.828,
        VC_05: 0.88036,
        QE_05: 19.828,
        VE_05: 0.88036,
        QA_06: 12.65,
        VA_06: 0.77265,
        QC_06: 16.303,
        VC_06: 0.82389,
        QE_06: 16.303,
        VE_06: 0.82389,
        QA_07: 9.332,
        VA_07: 0.7034,
        QC_07: 14.757,
        VC_07: 0.79733,
        QE_07: 14.757,
        VE_07: 0.79733,
        QA_08: 6.787,
        VA_08: 0.64195,
        QC_08: 14.629,
        VC_08: 0.79507,
        QE_08: 14.629,
        VE_08: 0.79507,
        QA_09: 5.09,
        VA_09: 0.59469,
        QC_09: 16.456,
        VC_09: 0.82645,
        QE_09: 16.456,
        VE_09: 0.82645,
        QA_10: 4.242,
        VA_10: 0.56828,
        QC_10: 17.445,
        VC_10: 0.84276,
        QE_10: 17.445,
        VE_10: 0.84276,
        QA_11: 4.609,
        VA_11: 0.57999,
        QC_11: 12.26,
        VC_11: 0.75149,
        QE_11: 12.26,
        VE_11: 0.75149,
        QA_12: 29.236,
        VA_12: 1.03275,
        QC_12: 28.507,
        VC_12: 1.00197,
        QE_12: 28.507,
        VE_12: 1.00197,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.77728731839079, 34.763829879372963, 0.0],
          [-80.778896518388308, 34.764426612705449, 0.0],
          [-80.786311918376668, 34.760217279378537, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283648.0,
        COMID: 9752466,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989098",
        GNIS_NAME: "Lower Little River",
        LENGTHKM: 1.176,
        REACHCODE: "03050101000042",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011312726157033,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061062.0,
        ToNode: 250061444.0,
        Hydroseq: 250023109.0,
        LevelPathI: 250022608.0,
        Pathlength: 574.455,
        TerminalPa: 250002604.0,
        ArbolateSu: 209.084,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022608.0,
        UpHydroseq: 250023649.0,
        DnLevelPat: 250022608.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022608.0,
        FromMeas: 38.33011,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0665,
        TotDASqKM: 250.8048,
        DivDASqKM: 250.8048,
        Tidal: 0,
        TOTMA: 0.0336555584592,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25649.0,
        MAXELEVSMO: 25935.0,
        MINELEVSMO: 25649.0,
        SLOPE: 0.00243197,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1759999990463259,
        QA_MA: 138.79,
        VA_MA: 1.32864,
        QC_MA: 138.8,
        VC_MA: 1.32864,
        QE_MA: 135.811,
        VE_MA: 1.32685,
        QA_01: 226.168,
        VA_01: 1.63065,
        QC_01: 177.939,
        VC_01: 1.4725,
        QE_01: 170.805,
        VE_01: 1.45869,
        QA_02: 232.559,
        VA_02: 1.65036,
        QC_02: 195.301,
        VC_02: 1.53149,
        QE_02: 187.433,
        VE_02: 1.51686,
        QA_03: 266.609,
        VA_03: 1.75134,
        QC_03: 225.764,
        VC_03: 1.62935,
        QE_03: 220.181,
        VE_03: 1.62473,
        QA_04: 222.537,
        VA_04: 1.61933,
        QC_04: 205.96,
        VC_04: 1.5665,
        QE_04: 202.946,
        VE_04: 1.56898,
        QA_05: 164.508,
        VA_05: 1.42502,
        QC_05: 154.354,
        VC_05: 1.38784,
        QE_05: 153.439,
        VE_05: 1.39503,
        QA_06: 99.338,
        VA_06: 1.16263,
        QC_06: 124.45,
        VC_06: 1.2712,
        QE_06: 126.621,
        VE_06: 1.28965,
        QA_07: 56.283,
        VA_07: 0.94016,
        QC_07: 85.255,
        VC_07: 1.096,
        QE_07: 88.703,
        VE_07: 1.12068,
        QA_08: 36.992,
        VA_08: 0.81395,
        QC_08: 82.489,
        VC_08: 1.08233,
        QE_08: 84.328,
        VE_08: 1.09915,
        QA_09: 29.397,
        VA_09: 0.75588,
        QC_09: 77.396,
        VC_09: 1.05658,
        QE_09: 77.594,
        VE_09: 1.06497,
        QA_10: 35.643,
        VA_10: 0.80408,
        QC_10: 95.529,
        VC_10: 1.14505,
        QE_10: 75.461,
        VE_10: 1.05385,
        QA_11: 115.36,
        VA_11: 1.23323,
        QC_11: 142.894,
        VC_11: 1.34451,
        QE_11: 109.913,
        VE_11: 1.21862,
        QA_12: 180.995,
        VA_12: 1.48311,
        QC_12: 149.726,
        VC_12: 1.37053,
        QE_12: 135.38,
        VE_12: 1.32513,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.158553384465677, 35.839719211036197, 0.0],
          [-81.158775584465332, 35.833306611046339, 0.0],
          [-81.162486917792933, 35.831381811049255, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283649.0,
        COMID: 9753938,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989098",
        GNIS_NAME: "Lower Little River",
        LENGTHKM: 0.728,
        REACHCODE: "03050101000042",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19489058,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0074345285965560001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061444.0,
        ToNode: 250061443.0,
        Hydroseq: 250022608.0,
        LevelPathI: 250022608.0,
        Pathlength: 573.727,
        TerminalPa: 250002604.0,
        ArbolateSu: 209.812,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250022608.0,
        UpHydroseq: 250023109.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009873.0,
        FromMeas: 0.0,
        ToMeas: 38.33011,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2763,
        TotDASqKM: 251.0811,
        DivDASqKM: 251.0811,
        Tidal: 0,
        TOTMA: 0.022017533553800001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25521.0,
        MAXELEVSMO: 25649.0,
        MINELEVSMO: 25537.0,
        SLOPE: 0.00153846,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.72799998521804798,
        QA_MA: 138.913,
        VA_MA: 1.25722,
        QC_MA: 138.918,
        VC_MA: 1.25722,
        QE_MA: 135.93,
        VE_MA: 1.25555,
        QA_01: 226.402,
        VA_01: 1.53818,
        QC_01: 178.119,
        VC_01: 1.3911,
        QE_01: 170.984,
        VE_01: 1.37826,
        QA_02: 232.811,
        VA_02: 1.55655,
        QC_02: 195.507,
        VC_02: 1.44599,
        QE_02: 187.639,
        VE_02: 1.43239,
        QA_03: 266.884,
        VA_03: 1.65044,
        QC_03: 225.989,
        VC_03: 1.53697,
        QE_03: 220.406,
        VE_03: 1.53268,
        QA_04: 222.734,
        VA_04: 1.52756,
        QC_04: 206.138,
        VC_04: 1.47843,
        QE_04: 203.124,
        VE_04: 1.48073,
        QA_05: 164.63,
        VA_05: 1.34677,
        QC_05: 154.464,
        VC_05: 1.3122,
        QE_05: 153.55,
        VE_05: 1.31888,
        QA_06: 99.409,
        VA_06: 1.10276,
        QC_06: 124.538,
        VC_06: 1.20373,
        QE_06: 126.709,
        VE_06: 1.22087,
        QA_07: 56.327,
        VA_07: 0.89591,
        QC_07: 85.321,
        VC_07: 1.04084,
        QE_07: 88.769,
        VE_07: 1.06376,
        QA_08: 37.021,
        VA_08: 0.77854,
        QC_08: 82.557,
        VC_08: 1.02814,
        QE_08: 84.395,
        VE_08: 1.04376,
        QA_09: 29.423,
        VA_09: 0.72455,
        QC_09: 77.457,
        VC_09: 1.00418,
        QE_09: 77.655,
        VE_09: 1.01197,
        QA_10: 35.661,
        VA_10: 0.76929,
        QC_10: 95.569,
        VC_10: 1.08631,
        QE_10: 75.501,
        VE_10: 1.00154,
        QA_11: 115.416,
        VA_11: 1.1683,
        QC_11: 142.947,
        VC_11: 1.27173,
        QE_11: 109.966,
        VE_11: 1.15472,
        QA_12: 181.151,
        VA_12: 1.40086,
        QC_12: 149.844,
        VC_12: 1.29614,
        QE_12: 135.498,
        VE_12: 1.25395,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.162486917792933, 35.831381811049255, 0.0],
          [-81.156899584468192, 35.827211211055726, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283650.0,
        COMID: 9752412,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989098",
        GNIS_NAME: "Lower Little River",
        LENGTHKM: 3.012,
        REACHCODE: "03050101000043",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.030531624687457001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061048.0,
        ToNode: 250061062.0,
        Hydroseq: 250023649.0,
        LevelPathI: 250022608.0,
        Pathlength: 575.631,
        TerminalPa: 250002604.0,
        ArbolateSu: 202.128,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022608.0,
        UpHydroseq: 250024232.0,
        DnLevelPat: 250022608.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023109.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8223,
        TotDASqKM: 244.1529,
        DivDASqKM: 244.1529,
        Tidal: 0,
        TOTMA: 0.081267705695399997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25836.0,
        MAXELEVSMO: 27161.0,
        MINELEVSMO: 25935.0,
        SLOPE: 0.00407038,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0120000839233398,
        QA_MA: 135.69,
        VA_MA: 1.40927,
        QC_MA: 135.819,
        VC_MA: 1.40935,
        QE_MA: 132.83,
        VE_MA: 1.40737,
        QA_01: 220.53,
        VA_01: 1.73313,
        QC_01: 173.618,
        VC_01: 1.56267,
        QE_01: 166.484,
        VE_01: 1.54738,
        QA_02: 226.511,
        VA_02: 1.75352,
        QC_02: 190.359,
        VC_02: 1.6255,
        QE_02: 182.491,
        VE_02: 1.60927,
        QA_03: 259.911,
        VA_03: 1.86302,
        QC_03: 220.277,
        VC_03: 1.73166,
        QE_03: 214.694,
        VE_03: 1.72652,
        QA_04: 217.502,
        VA_04: 1.72271,
        QC_04: 201.405,
        VC_04: 1.66555,
        QE_04: 198.391,
        VE_04: 1.66828,
        QA_05: 161.275,
        VA_05: 1.51502,
        QC_05: 151.433,
        VC_05: 1.47484,
        QE_05: 150.519,
        VE_05: 1.4828,
        QA_06: 97.433,
        VA_06: 1.23172,
        QC_06: 122.096,
        VC_06: 1.34881,
        QE_06: 124.267,
        VE_06: 1.36916,
        QA_07: 55.132,
        VA_07: 0.99079,
        QC_07: 83.552,
        VC_07: 1.159,
        QE_07: 87.001,
        VE_07: 1.18621,
        QA_08: 36.238,
        VA_08: 0.85449,
        QC_08: 80.775,
        VC_08: 1.14387,
        QE_08: 82.613,
        VE_08: 1.16242,
        QA_09: 28.747,
        VA_09: 0.7913,
        QC_09: 75.883,
        VC_09: 1.11661,
        QE_09: 76.081,
        VE_09: 1.12587,
        QA_10: 35.196,
        VA_10: 0.84609,
        QC_10: 94.57,
        VC_10: 1.21683,
        QE_10: 74.503,
        VE_10: 1.11682,
        QA_11: 113.657,
        VA_11: 1.31041,
        QC_11: 141.282,
        VC_11: 1.43265,
        QE_11: 108.301,
        VE_11: 1.29446,
        QA_12: 177.015,
        VA_12: 1.57618,
        QC_12: 146.728,
        VC_12: 1.45546,
        QE_12: 132.382,
        VE_12: 1.4054,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.180498984431495, 35.850566211019384, 0.0],
          [-81.177433517769714, 35.851369077684808, 0.0],
          [-81.171241984445999, 35.846264677692886, 0.0],
          [-81.163479117791397, 35.847206477691429, 0.0],
          [-81.161506917794441, 35.840359611035183, 0.0],
          [-81.158553384465677, 35.839719211036197, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286799.0,
        COMID: 9734500,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.435,
        REACHCODE: "03050103000083",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0046233083547810002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059412.0,
        ToNode: 250059823.0,
        Hydroseq: 250029970.0,
        LevelPathI: 250023116.0,
        Pathlength: 414.453,
        TerminalPa: 250002604.0,
        ArbolateSu: 49.881,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250031134.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028916.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2169,
        TotDASqKM: 43.5087,
        DivDASqKM: 43.5087,
        Tidal: 0,
        TOTMA: 0.0176535975399,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14696.0,
        MAXELEVSMO: 14786.0,
        MINELEVSMO: 14715.0,
        SLOPE: 0.00163218,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.43500000238418601,
        QA_MA: 17.235,
        VA_MA: 0.93158,
        QC_MA: 18.689,
        VC_MA: 0.93568,
        QE_MA: 18.689,
        VE_MA: 0.93568,
        QA_01: 38.197,
        VA_01: 1.25932,
        QC_01: 31.485,
        VC_01: 1.13586,
        QE_01: 31.485,
        VE_01: 1.13586,
        QA_02: 39.438,
        VA_02: 1.2756,
        QC_02: 34.761,
        VC_02: 1.18052,
        QE_02: 34.761,
        VE_02: 1.18052,
        QA_03: 42.067,
        VA_03: 1.30932,
        QC_03: 37.861,
        VC_03: 1.22099,
        QE_03: 37.861,
        VE_03: 1.22099,
        QA_04: 23.921,
        VA_04: 1.05008,
        QC_04: 23.29,
        VC_04: 1.01345,
        QE_04: 23.29,
        VE_04: 1.01345,
        QA_05: 13.455,
        VA_05: 0.85481,
        QC_05: 13.864,
        VC_05: 0.84369,
        QE_05: 13.864,
        VE_05: 0.84369,
        QA_06: 8.711,
        VA_06: 0.74216,
        QC_06: 11.285,
        VC_06: 0.78827,
        QE_06: 11.285,
        VE_06: 0.78827,
        QA_07: 6.435,
        VA_07: 0.67773,
        QC_07: 10.266,
        VC_07: 0.76476,
        QE_07: 10.266,
        VE_07: 0.76476,
        QA_08: 4.68,
        VA_08: 0.62027,
        QC_08: 10.013,
        VC_08: 0.75875,
        QE_08: 10.013,
        VE_08: 0.75875,
        QA_09: 3.51,
        VA_09: 0.57609,
        QC_09: 11.852,
        VC_09: 0.80093,
        QE_09: 11.852,
        VE_09: 0.80093,
        QA_10: 2.925,
        VA_10: 0.55139,
        QC_10: 12.964,
        VC_10: 0.82495,
        QE_10: 12.964,
        VE_10: 0.82495,
        QA_11: 3.292,
        VA_11: 0.56713,
        QC_11: 9.485,
        VC_11: 0.74598,
        QE_11: 9.485,
        VE_11: 0.74598,
        QA_12: 20.329,
        VA_12: 0.98868,
        QC_12: 20.482,
        VC_12: 0.96695,
        QE_12: 20.482,
        VE_12: 0.96695,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.722740518475462, 34.767909679366653, 0.0],
          [-80.726958518468962, 34.76708147936796, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286800.0,
        COMID: 9734490,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.507,
        REACHCODE: "03050103000084",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0054660809697580004,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059409.0,
        ToNode: 250059412.0,
        Hydroseq: 250031134.0,
        LevelPathI: 250023116.0,
        Pathlength: 414.888,
        TerminalPa: 250002604.0,
        ArbolateSu: 44.809,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250032433.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029970.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7083,
        TotDASqKM: 40.4973,
        DivDASqKM: 40.4973,
        Tidal: 0,
        TOTMA: 0.019439549753,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14685.0,
        MAXELEVSMO: 14941.0,
        MINELEVSMO: 14786.0,
        SLOPE: 0.00305719,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50700002908706698,
        QA_MA: 16.051,
        VA_MA: 0.98575,
        QC_MA: 17.453,
        VC_MA: 0.99036,
        QE_MA: 17.453,
        VE_MA: 0.99036,
        QA_01: 35.575,
        VA_01: 1.34201,
        QC_01: 29.379,
        VC_01: 1.2076,
        QE_01: 29.379,
        VE_01: 1.2076,
        QA_02: 36.725,
        VA_02: 1.35961,
        QC_02: 32.433,
        VC_02: 1.25609,
        QE_02: 32.433,
        VE_02: 1.25609,
        QA_03: 39.162,
        VA_03: 1.3961,
        QC_03: 35.33,
        VC_03: 1.30013,
        QE_03: 35.33,
        VE_03: 1.30013,
        QA_04: 22.261,
        VA_04: 1.11423,
        QC_04: 21.71,
        VC_04: 1.07417,
        QE_04: 21.71,
        VE_04: 1.07417,
        QA_05: 12.523,
        VA_05: 0.90211,
        QC_05: 12.939,
        VC_05: 0.89016,
        QE_05: 12.939,
        VE_05: 0.89016,
        QA_06: 8.104,
        VA_06: 0.7796,
        QC_06: 10.508,
        VC_06: 0.8293,
        QE_06: 10.508,
        VE_06: 0.8293,
        QA_07: 5.989,
        VA_07: 0.70969,
        QC_07: 9.572,
        VC_07: 0.80412,
        QE_07: 9.572,
        VE_07: 0.80412,
        QA_08: 4.356,
        VA_08: 0.64728,
        QC_08: 9.306,
        VC_08: 0.79676,
        QE_08: 9.306,
        VE_08: 0.79676,
        QA_09: 3.267,
        VA_09: 0.59927,
        QC_09: 11.125,
        VC_09: 0.84532,
        QE_09: 11.125,
        VE_09: 0.84532,
        QA_10: 2.722,
        VA_10: 0.5724,
        QC_10: 12.242,
        VC_10: 0.8733,
        QE_10: 12.242,
        VE_10: 0.8733,
        QA_11: 3.089,
        VA_11: 0.59074,
        QC_11: 9.037,
        VC_11: 0.78921,
        QE_11: 9.037,
        VE_11: 0.78921,
        QA_12: 18.962,
        VA_12: 1.04844,
        QC_12: 19.225,
        VC_12: 1.0263,
        QE_12: 19.225,
        VE_12: 1.0263,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.71757831848339, 34.768349079365862, 0.0],
          [-80.722740518475462, 34.767909679366653, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286801.0,
        COMID: 9734486,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 1.524,
        REACHCODE: "03050103000085",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015486812060068999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059407.0,
        ToNode: 250059409.0,
        Hydroseq: 250032433.0,
        LevelPathI: 250023116.0,
        Pathlength: 415.395,
        TerminalPa: 250002604.0,
        ArbolateSu: 41.795,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250033906.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031134.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2132,
        TotDASqKM: 38.2608,
        DivDASqKM: 38.2608,
        Tidal: 0,
        TOTMA: 0.064064799536299996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14867.0,
        MAXELEVSMO: 15155.0,
        MINELEVSMO: 14941.0,
        SLOPE: 0.00140419,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5240000486373899,
        QA_MA: 15.169,
        VA_MA: 0.89918,
        QC_MA: 16.531,
        VC_MA: 0.90331,
        QE_MA: 16.531,
        VE_MA: 0.90331,
        QA_01: 33.62,
        VA_01: 1.20991,
        QC_01: 27.806,
        VC_01: 1.09251,
        QE_01: 27.806,
        VE_01: 1.09251,
        QA_02: 34.711,
        VA_02: 1.22533,
        QC_02: 30.701,
        VC_02: 1.13486,
        QE_02: 30.701,
        VE_02: 1.13486,
        QA_03: 36.997,
        VA_03: 1.25691,
        QC_03: 33.439,
        VC_03: 1.17321,
        QE_03: 33.439,
        VE_03: 1.17321,
        QA_04: 21.028,
        VA_04: 1.01107,
        QC_04: 20.534,
        VC_04: 0.97592,
        QE_04: 20.534,
        VE_04: 0.97592,
        QA_05: 11.832,
        VA_05: 0.82617,
        QC_05: 12.25,
        VC_05: 0.81578,
        QE_05: 12.25,
        VE_05: 0.81578,
        QA_06: 7.653,
        VA_06: 0.71922,
        QC_06: 9.931,
        VC_06: 0.76228,
        QE_06: 9.931,
        VE_06: 0.76228,
        QA_07: 5.659,
        VA_07: 0.65838,
        QC_07: 9.056,
        VC_07: 0.74058,
        QE_07: 9.056,
        VE_07: 0.74058,
        QA_08: 4.115,
        VA_08: 0.6039,
        QC_08: 8.782,
        VC_08: 0.73359,
        QE_08: 8.782,
        VE_08: 0.73359,
        QA_09: 3.087,
        VA_09: 0.56207,
        QC_09: 10.581,
        VC_09: 0.77782,
        QE_09: 10.581,
        VE_09: 0.77782,
        QA_10: 2.572,
        VA_10: 0.53864,
        QC_10: 11.699,
        VC_10: 0.80353,
        QE_10: 11.699,
        VE_10: 0.80353,
        QA_11: 2.939,
        VA_11: 0.55554,
        QC_11: 8.7,
        VC_11: 0.73148,
        QE_11: 8.7,
        VE_11: 0.73148,
        QA_12: 17.936,
        VA_12: 0.95416,
        QC_12: 18.276,
        VC_12: 0.93588,
        QE_12: 18.276,
        VE_12: 0.93588,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.70703518516649, 34.772525612692732, 0.0],
          [-80.710168518495038, 34.769385412697545, 0.0],
          [-80.71155811849286, 34.771033479361847, 0.0],
          [-80.71757831848339, 34.768349079365862, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286802.0,
        COMID: 9734456,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1247122",
        GNIS_NAME: "Camp Creek",
        LENGTHKM: 0.875,
        REACHCODE: "03050103000086",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.009223704679924,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059395.0,
        ToNode: 250059407.0,
        Hydroseq: 250033906.0,
        LevelPathI: 250023116.0,
        Pathlength: 416.919,
        TerminalPa: 250002604.0,
        ArbolateSu: 38.227,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250023116.0,
        UpHydroseq: 250035570.0,
        DnLevelPat: 250023116.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032433.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1628,
        TotDASqKM: 35.8128,
        DivDASqKM: 35.8128,
        Tidal: 0,
        TOTMA: 0.040947081160699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15123.0,
        MAXELEVSMO: 15202.0,
        MINELEVSMO: 15155.0,
        SLOPE: 0.00053714,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.875,
        QA_MA: 14.203,
        VA_MA: 0.80784,
        QC_MA: 15.518,
        VC_MA: 0.81144,
        QE_MA: 15.518,
        VE_MA: 0.81144,
        QA_01: 31.481,
        VA_01: 1.07055,
        QC_01: 26.081,
        VC_01: 0.97113,
        QE_01: 26.081,
        VE_01: 0.97113,
        QA_02: 32.505,
        VA_02: 1.08362,
        QC_02: 28.802,
        VC_02: 1.00699,
        QE_02: 28.802,
        VE_02: 1.00699,
        QA_03: 34.627,
        VA_03: 1.11009,
        QC_03: 31.366,
        VC_03: 1.03936,
        QE_03: 31.366,
        VE_03: 1.03936,
        QA_04: 19.679,
        VA_04: 0.90227,
        QC_04: 19.245,
        VC_04: 0.87235,
        QE_04: 19.245,
        VE_04: 0.87235,
        QA_05: 11.075,
        VA_05: 0.74604,
        QC_05: 11.495,
        VC_05: 0.73734,
        QE_05: 11.495,
        VE_05: 0.73734,
        QA_06: 7.159,
        VA_06: 0.65553,
        QC_06: 9.299,
        VC_06: 0.69167,
        QE_06: 9.299,
        VE_06: 0.69167,
        QA_07: 5.297,
        VA_07: 0.60422,
        QC_07: 8.49,
        VC_07: 0.67359,
        QE_07: 8.49,
        VE_07: 0.67359,
        QA_08: 3.852,
        VA_08: 0.55818,
        QC_08: 8.209,
        VC_08: 0.66712,
        QE_08: 8.209,
        VE_08: 0.66712,
        QA_09: 2.889,
        VA_09: 0.5228,
        QC_09: 9.981,
        VC_09: 0.70635,
        QE_09: 9.981,
        VE_09: 0.70635,
        QA_10: 2.408,
        VA_10: 0.50303,
        QC_10: 11.097,
        VC_10: 0.72939,
        QE_10: 11.097,
        VE_10: 0.72939,
        QA_11: 2.775,
        VA_11: 0.51826,
        QC_11: 8.326,
        VC_11: 0.66982,
        QE_11: 8.326,
        VE_11: 0.66982,
        QA_12: 16.807,
        VA_12: 0.85454,
        QC_12: 17.227,
        VC_12: 0.84013,
        QE_12: 17.227,
        VE_12: 0.84013,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.69871111851279, 34.776012812687441, 0.0],
          [-80.70703518516649, 34.772525612692732, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283635.0,
        COMID: 9755508,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.723,
        REACHCODE: "03050101000014",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755298,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.028569527876181,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061857.0,
        ToNode: 250061858.0,
        Hydroseq: 250008399.0,
        LevelPathI: 250003258.0,
        Pathlength: 502.549,
        TerminalPa: 250002604.0,
        ArbolateSu: 3949.459,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008433.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008365.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.3371,
        TotDASqKM: 4674.8916,
        DivDASqKM: 4674.8916,
        Tidal: 0,
        TOTMA: 0.090019173668300007,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19584.0,
        MAXELEVSMO: 19593.0,
        MINELEVSMO: 19593.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.723000049591064,
        QA_MA: 2639.029,
        VA_MA: 1.14384,
        QC_MA: 2639.029,
        VC_MA: 1.14384,
        QE_MA: 2832.352,
        VE_MA: 1.14864,
        QA_01: 4419.008,
        VA_01: 1.40689,
        QC_01: 4419.008,
        VC_01: 1.40689,
        QE_01: 4598.619,
        VE_01: 1.39525,
        QA_02: 4671.826,
        VA_02: 1.43983,
        QC_02: 4671.826,
        VC_02: 1.43983,
        QE_02: 4715.152,
        VE_02: 1.40979,
        QA_03: 5236.475,
        VA_03: 1.5105,
        QC_03: 5236.475,
        VC_03: 1.5105,
        QE_03: 5465.289,
        VE_03: 1.49961,
        QA_04: 3936.874,
        VA_04: 1.34153,
        QC_04: 3936.874,
        VC_04: 1.34153,
        QE_04: 4117.012,
        VE_04: 1.33324,
        QA_05: 2902.678,
        VA_05: 1.18717,
        QC_05: 2902.678,
        VC_05: 1.18717,
        QE_05: 3124.988,
        VE_05: 1.19367,
        QA_06: 1696.815,
        VA_06: 0.96919,
        QC_06: 1696.815,
        VC_06: 0.96919,
        QE_06: 1976.849,
        VE_06: 1.00258,
        QA_07: 1012.769,
        VA_07: 0.8108,
        QC_07: 1012.769,
        VC_07: 0.8108,
        QE_07: 1181.305,
        VE_07: 0.83652,
        QA_08: 709.333,
        VA_08: 0.72424,
        QC_08: 709.333,
        VC_08: 0.72424,
        QE_08: 1033.304,
        VE_08: 0.80028,
        QA_09: 677.801,
        VA_09: 0.71432,
        QC_09: 1235.746,
        VC_09: 0.86679,
        QE_09: 1287.087,
        VE_09: 0.86113,
        QA_10: 817.863,
        VA_10: 0.75689,
        QC_10: 817.863,
        VC_10: 0.75689,
        QE_10: 784.623,
        VE_10: 0.73338,
        QA_11: 2235.213,
        VA_11: 1.07332,
        QC_11: 2235.213,
        VC_11: 1.07332,
        QE_11: 2182.113,
        VE_11: 1.03997,
        QA_12: 3368.897,
        VA_12: 1.2595,
        QC_12: 3368.897,
        VC_12: 1.2595,
        QE_12: 3365.066,
        VE_12: 1.22916,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.954607518115608, 35.39435341172765, 0.0],
          [-80.957974518110348, 35.390985878399533, 0.0],
          [-80.979552518076787, 35.381677078413929, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283636.0,
        COMID: 9755496,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.818,
        REACHCODE: "03050101000015",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755298,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0074023755551609997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061852.0,
        ToNode: 250061857.0,
        Hydroseq: 250008433.0,
        LevelPathI: 250003258.0,
        Pathlength: 505.272,
        TerminalPa: 250002604.0,
        ArbolateSu: 3945.912,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008464.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008399.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4824,
        TotDASqKM: 4669.4295,
        DivDASqKM: 4669.4295,
        Tidal: 0,
        TOTMA: 0.019680950659399999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19587.0,
        MAXELEVSMO: 19604.0,
        MINELEVSMO: 19593.0,
        SLOPE: 0.00013447,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.81800001859664895,
        QA_MA: 2637.036,
        VA_MA: 1.571,
        QC_MA: 2637.036,
        VC_MA: 1.571,
        QE_MA: 2830.356,
        VE_MA: 1.57826,
        QA_01: 4414.959,
        VA_01: 1.96841,
        QC_01: 4414.959,
        VC_01: 1.96841,
        QE_01: 4594.589,
        VE_01: 1.95081,
        QA_02: 4667.171,
        VA_02: 2.01811,
        QC_02: 4667.171,
        VC_02: 2.01811,
        QE_02: 4710.518,
        VE_02: 1.97269,
        QA_03: 5231.43,
        VA_03: 2.12493,
        QC_03: 5231.43,
        VC_03: 2.12493,
        QE_03: 5460.273,
        VE_03: 2.10847,
        QA_04: 3933.908,
        VA_04: 1.86977,
        QC_04: 3933.908,
        VC_04: 1.86977,
        QE_04: 4114.055,
        VE_04: 1.85724,
        QA_05: 2900.989,
        VA_05: 1.63661,
        QC_05: 2900.989,
        VC_05: 1.63661,
        QE_05: 3123.297,
        VE_05: 1.64644,
        QA_06: 1695.787,
        VA_06: 1.30713,
        QC_06: 1695.787,
        VC_06: 1.30713,
        QE_06: 1975.8,
        VE_06: 1.35762,
        QA_07: 1012.056,
        VA_07: 1.06771,
        QC_07: 1012.056,
        VC_07: 1.06771,
        QE_07: 1180.572,
        VE_07: 1.10659,
        QA_08: 708.819,
        VA_08: 0.93687,
        QC_08: 708.819,
        VC_08: 0.93687,
        QE_08: 1032.764,
        VE_08: 1.05187,
        QA_09: 677.36,
        VA_09: 0.9219,
        QC_09: 1235.036,
        VC_09: 1.15239,
        QE_09: 1286.358,
        VE_09: 1.14381,
        QA_10: 817.496,
        VA_10: 0.98632,
        QC_10: 817.496,
        VC_10: 0.98632,
        QE_10: 784.227,
        VE_10: 0.95075,
        QA_11: 2234.846,
        VA_11: 1.46479,
        QC_11: 2234.846,
        VC_11: 1.46479,
        QE_11: 2181.738,
        VE_11: 1.41434,
        QA_12: 3366.836,
        VA_12: 1.7459,
        QC_12: 3366.836,
        VC_12: 1.7459,
        QE_12: 3363.017,
        VE_12: 1.70002,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.955640318113922, 35.401475211716502, 0.0],
          [-80.954607518115608, 35.39435341172765, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283638.0,
        COMID: 9755492,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.777,
        REACHCODE: "03050101000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755298,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.025365633526572,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061850.0,
        ToNode: 250061852.0,
        Hydroseq: 250008464.0,
        LevelPathI: 250003258.0,
        Pathlength: 506.09,
        TerminalPa: 250002604.0,
        ArbolateSu: 3940.071,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008498.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008433.0,
        FromMeas: 0.0,
        ToMeas: 68.49055,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.3234,
        TotDASqKM: 4662.6876,
        DivDASqKM: 4662.6876,
        Tidal: 0,
        TOTMA: 0.071070500812900006,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19586.0,
        MAXELEVSMO: 19627.0,
        MINELEVSMO: 19604.0,
        SLOPE: 8.282e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.776999950408936,
        QA_MA: 2634.516,
        VA_MA: 1.47702,
        QC_MA: 2634.516,
        VC_MA: 1.47702,
        QE_MA: 2827.832,
        VE_MA: 1.48374,
        QA_01: 4409.78,
        VA_01: 1.84466,
        QC_01: 4409.78,
        VC_01: 1.84466,
        QE_01: 4589.431,
        VE_01: 1.82837,
        QA_02: 4661.344,
        VA_02: 1.89059,
        QC_02: 4661.344,
        VC_02: 1.89059,
        QE_02: 4704.717,
        VE_02: 1.84853,
        QA_03: 5225.147,
        VA_03: 1.98949,
        QC_03: 5225.147,
        VC_03: 1.98949,
        QE_03: 5454.028,
        VE_03: 1.97426,
        QA_04: 3930.148,
        VA_04: 1.75354,
        QC_04: 3930.148,
        VC_04: 1.75354,
        QE_04: 4110.306,
        VE_04: 1.74194,
        QA_05: 2898.832,
        VA_05: 1.53788,
        QC_05: 2898.832,
        VC_05: 1.53788,
        QE_05: 3121.136,
        VE_05: 1.54697,
        QA_06: 1694.5,
        VA_06: 1.23289,
        QC_06: 1694.5,
        VC_06: 1.23289,
        QE_06: 1974.487,
        VE_06: 1.27964,
        QA_07: 1011.15,
        VA_07: 1.01121,
        QC_07: 1011.15,
        VC_07: 1.01121,
        QE_07: 1179.64,
        VE_07: 1.04722,
        QA_08: 708.185,
        VA_08: 0.8901,
        QC_08: 708.185,
        VC_08: 0.8901,
        QE_08: 1032.098,
        VE_08: 0.99661,
        QA_09: 676.816,
        VA_09: 0.87628,
        QC_09: 1234.161,
        VC_09: 1.08966,
        QE_09: 1285.458,
        VE_09: 1.0817,
        QA_10: 817.043,
        VA_10: 0.93599,
        QC_10: 817.043,
        VC_10: 0.93599,
        QE_10: 783.739,
        VE_10: 0.90302,
        QA_11: 2234.393,
        VA_11: 1.37914,
        QC_11: 2234.393,
        VC_11: 1.37914,
        QE_11: 2181.275,
        VE_11: 1.33239,
        QA_12: 3364.065,
        VA_12: 1.63899,
        QC_12: 3364.065,
        VC_12: 1.63899,
        QE_12: 3360.258,
        VE_12: 1.59649,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.955910518113569, 35.425191278346347, 0.0],
          [-80.95566418478063, 35.418781411689679, 0.0],
          [-80.958839784775648, 35.412132211699941, 0.0],
          [-80.955640318113922, 35.401475211716502, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283632.0,
        COMID: 9756868,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.406,
        REACHCODE: "03050101000008",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022012995634153001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061984.0,
        ToNode: 250061986.0,
        Hydroseq: 250008111.0,
        LevelPathI: 250003258.0,
        Pathlength: 478.832,
        TerminalPa: 250002604.0,
        ArbolateSu: 4280.182,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008139.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008080.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5569,
        TotDASqKM: 5207.7924,
        DivDASqKM: 5207.7924,
        Tidal: 0,
        TOTMA: 0.078268705049199999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17245.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.405999898910522,
        QA_MA: 2852.396,
        VA_MA: 1.16273,
        QC_MA: 2852.396,
        VC_MA: 1.16273,
        QE_MA: 3045.981,
        VE_MA: 1.16729,
        QA_01: 4852.706,
        VA_01: 1.44124,
        QC_01: 4852.706,
        VC_01: 1.44124,
        QE_01: 5030.561,
        VE_01: 1.42948,
        QA_02: 5145.723,
        VA_02: 1.47706,
        QC_02: 5145.723,
        VC_02: 1.47706,
        QE_02: 5186.986,
        VE_02: 1.44785,
        QA_03: 5746.985,
        VA_03: 1.54769,
        QC_03: 5746.985,
        VC_03: 1.54769,
        QE_03: 5972.865,
        VE_03: 1.53649,
        QA_04: 4251.18,
        VA_04: 1.36436,
        QC_04: 4251.18,
        VC_04: 1.36436,
        QE_04: 4430.457,
        VE_04: 1.35635,
        QA_05: 3087.887,
        VA_05: 1.19949,
        QC_05: 3087.887,
        VC_05: 1.19949,
        QE_05: 3310.442,
        VE_05: 1.20611,
        QA_06: 1807.589,
        VA_06: 0.97894,
        QC_06: 1807.589,
        VC_06: 0.97894,
        QE_06: 2089.678,
        VE_06: 1.01153,
        QA_07: 1085.711,
        VA_07: 0.81993,
        QC_07: 1085.711,
        VC_07: 0.81993,
        QE_07: 1256.242,
        VE_07: 0.84503,
        QA_08: 761.627,
        VA_08: 0.73215,
        QC_08: 761.627,
        VC_08: 0.73215,
        QE_08: 1088.065,
        VE_08: 0.8056,
        QA_09: 722.797,
        VA_09: 0.72053,
        QC_09: 1307.9,
        VC_09: 0.87308,
        QE_09: 1361.174,
        VE_09: 0.86839,
        QA_10: 855.718,
        VA_10: 0.75919,
        QC_10: 855.718,
        VC_10: 0.75919,
        QE_10: 825.267,
        VE_10: 0.73772,
        QA_11: 2302.647,
        VA_11: 1.07092,
        QC_11: 2302.647,
        VC_11: 1.07092,
        QE_11: 2250.29,
        VE_11: 1.03973,
        QA_12: 3627.522,
        VA_12: 1.27905,
        QC_12: 3627.522,
        VC_12: 1.27905,
        QE_12: 3622.634,
        VE_12: 1.25011,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.002749318040856, 35.299764611874366, 0.0],
          [-81.009904784696346, 35.279306878572811, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283633.0,
        COMID: 9755522,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.788,
        REACHCODE: "03050101000012",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755298,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.037669365452212,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061864.0,
        ToNode: 250061863.0,
        Hydroseq: 250008337.0,
        LevelPathI: 250003258.0,
        Pathlength: 498.521,
        TerminalPa: 250002604.0,
        ArbolateSu: 3964.652,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008365.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008310.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 8.2665,
        TotDASqKM: 4702.6602,
        DivDASqKM: 4702.6602,
        Tidal: 0,
        TOTMA: 0.112934798563,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19581.0,
        MAXELEVSMO: 19593.0,
        MINELEVSMO: 19584.0,
        SLOPE: 2.375e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.788000106811523,
        QA_MA: 2649.362,
        VA_MA: 1.26817,
        QC_MA: 2649.362,
        VC_MA: 1.26817,
        QE_MA: 2842.699,
        VE_MA: 1.27366,
        QA_01: 4440.144,
        VA_01: 1.57095,
        QC_01: 4440.144,
        VC_01: 1.57095,
        QE_01: 4619.664,
        VE_01: 1.55757,
        QA_02: 4695.863,
        VA_02: 1.60905,
        QC_02: 4695.863,
        VC_02: 1.60905,
        QE_02: 4739.082,
        VE_02: 1.57462,
        QA_03: 5262.367,
        VA_03: 1.69014,
        QC_03: 5262.367,
        VC_03: 1.69014,
        QE_03: 5491.027,
        VE_03: 1.67761,
        QA_04: 3952.281,
        VA_04: 1.49528,
        QC_04: 3952.281,
        VC_04: 1.49528,
        QE_04: 4132.375,
        VE_04: 1.48578,
        QA_05: 2911.471,
        VA_05: 1.31747,
        QC_05: 2911.471,
        VC_05: 1.31747,
        QE_05: 3133.793,
        VE_05: 1.32495,
        QA_06: 1702.131,
        VA_06: 1.06722,
        QC_06: 1702.131,
        VC_06: 1.06722,
        QE_06: 1982.272,
        VE_06: 1.10549,
        QA_07: 1016.417,
        VA_07: 0.88547,
        QC_07: 1016.417,
        VC_07: 0.88547,
        QE_07: 1185.057,
        VE_07: 0.91495,
        QA_08: 711.947,
        VA_08: 0.78606,
        QC_08: 711.947,
        VC_08: 0.78606,
        QE_08: 1036.046,
        VE_08: 0.87317,
        QA_09: 680.041,
        VA_09: 0.77457,
        QC_09: 1239.351,
        VC_09: 0.94955,
        QE_09: 1290.793,
        VE_09: 0.94311,
        QA_10: 819.73,
        VA_10: 0.82319,
        QC_10: 819.73,
        VC_10: 0.82319,
        QE_10: 786.635,
        VE_10: 0.79634,
        QA_11: 2237.092,
        VA_11: 1.18573,
        QC_11: 2237.092,
        VC_11: 1.18573,
        QE_11: 2184.03,
        VE_11: 1.14759,
        QA_12: 3380.266,
        VA_12: 1.40073,
        QC_12: 3380.266,
        VC_12: 1.40073,
        QE_12: 3376.38,
        VE_12: 1.366,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.982157851406157, 35.382146878413209, 0.0],
          [-80.985925984733626, 35.381001611748218, 0.0],
          [-80.987048184731918, 35.377888478419891, 0.0],
          [-80.985595984734118, 35.371595478429583, 0.0],
          [-80.981744318073481, 35.366351678437695, 0.0],
          [-80.975100318083776, 35.364142211774549, 0.0],
          [-80.966797518096655, 35.369453678432883, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283634.0,
        COMID: 9755510,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.24,
        REACHCODE: "03050101000013",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755298,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0026473522237230001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061858.0,
        ToNode: 250061864.0,
        Hydroseq: 250008365.0,
        LevelPathI: 250003258.0,
        Pathlength: 502.309,
        TerminalPa: 250002604.0,
        ArbolateSu: 3950.344,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008399.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008337.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0504,
        TotDASqKM: 4676.1741,
        DivDASqKM: 4676.1741,
        Tidal: 0,
        TOTMA: 0.0079337717232200004,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19582.0,
        MAXELEVSMO: 19593.0,
        MINELEVSMO: 19593.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.239999994635582,
        QA_MA: 2639.492,
        VA_MA: 1.14389,
        QC_MA: 2639.492,
        VC_MA: 1.14389,
        QE_MA: 2832.816,
        VE_MA: 1.14869,
        QA_01: 4419.947,
        VA_01: 1.40698,
        QC_01: 4419.947,
        VC_01: 1.40698,
        QE_01: 4599.554,
        VE_01: 1.39533,
        QA_02: 4672.912,
        VA_02: 1.43993,
        QC_02: 4672.912,
        VC_02: 1.43993,
        QE_02: 4716.233,
        VE_02: 1.40989,
        QA_03: 5237.65,
        VA_03: 1.5106,
        QC_03: 5237.65,
        VC_03: 1.5106,
        QE_03: 5466.457,
        VE_03: 1.4997,
        QA_04: 3937.564,
        VA_04: 1.34159,
        QC_04: 3937.564,
        VC_04: 1.34159,
        QE_04: 4117.7,
        VE_04: 1.3333,
        QA_05: 2903.075,
        VA_05: 1.1872,
        QC_05: 2903.075,
        VC_05: 1.1872,
        QE_05: 3125.386,
        VE_05: 1.19371,
        QA_06: 1697.056,
        VA_06: 0.96921,
        QC_06: 1697.056,
        VC_06: 0.96921,
        QE_06: 1977.096,
        VE_06: 1.00261,
        QA_07: 1012.926,
        VA_07: 0.81082,
        QC_07: 1012.926,
        VC_07: 0.81082,
        QE_07: 1181.467,
        VE_07: 0.83654,
        QA_08: 709.454,
        VA_08: 0.72426,
        QC_08: 709.454,
        VC_08: 0.72426,
        QE_08: 1033.43,
        VE_08: 0.8003,
        QA_09: 677.904,
        VA_09: 0.71433,
        QC_09: 1235.912,
        VC_09: 0.86681,
        QE_09: 1287.258,
        VE_09: 0.86115,
        QA_10: 817.95,
        VA_10: 0.7569,
        QC_10: 817.95,
        VC_10: 0.7569,
        QE_10: 784.716,
        VE_10: 0.7334,
        QA_11: 2235.299,
        VA_11: 1.07331,
        QC_11: 2235.299,
        VC_11: 1.07331,
        QE_11: 2182.201,
        VE_11: 1.03996,
        QA_12: 3369.362,
        VA_12: 1.25953,
        QC_12: 3369.362,
        VC_12: 1.25953,
        QE_12: 3365.529,
        VE_12: 1.2292,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.979552518076787, 35.381677078413929, 0.0],
          [-80.982157851406157, 35.382146878413209, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288777.0,
        COMID: 25280147,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.376,
        REACHCODE: "03050103017747",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0037020783323640002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250093468.0,
        ToNode: 250093469.0,
        Hydroseq: 250007163.0,
        LevelPathI: 250003258.0,
        Pathlength: 437.627,
        TerminalPa: 250002604.0,
        ArbolateSu: 5905.945,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007193.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007134.0,
        FromMeas: 0.0,
        ToMeas: 28.47412,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1476,
        TotDASqKM: 7820.1189,
        DivDASqKM: 7820.1189,
        Tidal: 0,
        TOTMA: 0.011450029785,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15129.0,
        MAXELEVSMO: 15204.0,
        MINELEVSMO: 15204.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37599998712539701,
        QA_MA: 4066.372,
        VA_MA: 1.24243,
        QC_MA: 4066.372,
        VC_MA: 1.24243,
        QE_MA: 4316.387,
        VE_MA: 1.24696,
        QA_01: 7221.155,
        VA_01: 1.57545,
        QC_01: 7221.155,
        VC_01: 1.57545,
        QE_01: 7380.863,
        VE_01: 1.5563,
        QA_02: 7642.629,
        VA_02: 1.6142,
        QC_02: 7642.629,
        VC_02: 1.6142,
        QE_02: 7655.479,
        VE_02: 1.58074,
        QA_03: 8430.883,
        VA_03: 1.68407,
        QC_03: 8430.883,
        VC_03: 1.68407,
        QE_03: 8650.915,
        VE_03: 1.66607,
        QA_04: 5991.12,
        VA_04: 1.45584,
        QC_04: 5991.12,
        VC_04: 1.45584,
        QE_04: 6156.682,
        VE_04: 1.44173,
        QA_05: 4223.975,
        VA_05: 1.26149,
        QC_05: 4223.975,
        VC_05: 1.26149,
        QE_05: 4543.339,
        VE_05: 1.27285,
        QA_06: 2473.037,
        VA_06: 1.02566,
        QC_06: 2473.037,
        VC_06: 1.02566,
        QE_06: 2821.908,
        VE_06: 1.05735,
        QA_07: 1503.988,
        VA_07: 0.85919,
        QC_07: 1503.988,
        VC_07: 0.85919,
        QE_07: 1760.356,
        VE_07: 0.89134,
        QA_08: 1064.467,
        VA_08: 0.76683,
        QC_08: 1064.467,
        VC_08: 0.76683,
        QE_08: 1475.392,
        VE_08: 0.83916,
        QA_09: 984.429,
        VA_09: 0.74819,
        QC_09: 984.429,
        VC_09: 0.74819,
        QE_09: 1105.403,
        VE_09: 0.76372,
        QA_10: 1111.86,
        VA_10: 0.77756,
        QC_10: 1111.86,
        VC_10: 0.77756,
        QE_10: 1387.584,
        VE_10: 0.82214,
        QA_11: 2974.752,
        VA_11: 1.09958,
        QC_11: 2974.752,
        VC_11: 1.09958,
        QE_11: 3137.26,
        VE_11: 1.10068,
        QA_12: 5201.903,
        VA_12: 1.37293,
        QC_12: 5201.903,
        VC_12: 1.37293,
        QE_12: 5202.648,
        VE_12: 1.3448,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.999799584712093, 35.015603278982155, 0.0],
          [-80.99717551804946, 35.013001612319613, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288778.0,
        COMID: 25280149,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.076,
        REACHCODE: "03050103017748",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019092184602339999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250093469.0,
        ToNode: 250093422.0,
        Hydroseq: 250007134.0,
        LevelPathI: 250003258.0,
        Pathlength: 435.551,
        TerminalPa: 250002604.0,
        ArbolateSu: 5912.01,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007163.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007106.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.2542,
        TotDASqKM: 7831.1934,
        DivDASqKM: 7831.1934,
        Tidal: 0,
        TOTMA: 0.042148784694999997,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14941.0,
        MAXELEVSMO: 15204.0,
        MINELEVSMO: 15153.0,
        SLOPE: 0.00024566,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.0759999752044682,
        QA_MA: 4071.134,
        VA_MA: 1.86279,
        QC_MA: 4071.134,
        VC_MA: 1.86279,
        QE_MA: 4321.154,
        VE_MA: 1.87031,
        QA_01: 7231.131,
        VA_01: 2.41722,
        QC_01: 7231.131,
        VC_01: 2.41722,
        QE_01: 7390.802,
        VE_01: 2.38536,
        QA_02: 7653.122,
        VA_02: 2.4817,
        QC_02: 7653.122,
        VC_02: 2.4817,
        QE_02: 7665.929,
        VE_02: 2.42606,
        QA_03: 8442.244,
        VA_03: 2.59795,
        QC_03: 8442.244,
        VC_03: 2.59795,
        QE_03: 8662.215,
        VE_03: 2.56801,
        QA_04: 5997.969,
        VA_04: 2.21791,
        QC_04: 5997.969,
        VC_04: 2.21791,
        QE_04: 6163.514,
        VE_04: 2.19444,
        QA_05: 4227.751,
        VA_05: 1.89426,
        QC_05: 4227.751,
        VC_05: 1.89426,
        QE_05: 4547.121,
        VE_05: 1.91316,
        QA_06: 2475.419,
        VA_06: 1.50191,
        QC_06: 2475.419,
        VC_06: 1.50191,
        QE_06: 2824.333,
        VE_06: 1.5546,
        QA_07: 1505.626,
        VA_07: 1.22497,
        QC_07: 1505.626,
        VC_07: 1.22497,
        QE_07: 1762.035,
        VE_07: 1.27843,
        QA_08: 1065.659,
        VA_08: 1.07129,
        QC_08: 1065.659,
        VC_08: 1.07129,
        QE_08: 1476.635,
        VE_08: 1.19155,
        QA_09: 985.471,
        VA_09: 1.04025,
        QC_09: 985.471,
        VC_09: 1.04025,
        QE_09: 1106.485,
        VE_09: 1.06608,
        QA_10: 1112.605,
        VA_10: 1.08896,
        QC_10: 1112.605,
        VC_10: 1.08896,
        QE_10: 1388.386,
        VE_10: 1.16312,
        QA_11: 2976.51,
        VA_11: 1.62465,
        QC_11: 2976.51,
        VC_11: 1.62465,
        QE_11: 3139.033,
        VE_11: 1.62651,
        QA_12: 5207.819,
        VA_12: 2.07992,
        QC_12: 5207.819,
        VC_12: 2.07992,
        QE_12: 5208.542,
        VE_12: 2.03316,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.99717551804946, 35.013001612319613, 0.0],
          [-80.996519984717224, 35.010266078990469, 0.0],
          [-80.999132184713176, 35.006356078996475, 0.0],
          [-80.996298118050845, 34.995715812346305, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283677.0,
        COMID: 9753948,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.001,
        REACHCODE: "03050101000068",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19489058,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022030866246586001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061448.0,
        ToNode: 250061445.0,
        Hydroseq: 250009967.0,
        LevelPathI: 250003258.0,
        Pathlength: 574.987,
        TerminalPa: 250002604.0,
        ArbolateSu: 3068.052,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010020.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009920.0,
        FromMeas: 36.78478,
        ToMeas: 95.26572,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.6907,
        TotDASqKM: 3403.1484,
        DivDASqKM: 3403.1484,
        Tidal: 0,
        TOTMA: 0.042303920263700001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25575.0,
        MAXELEVSMO: 25686.0,
        MINELEVSMO: 25576.0,
        SLOPE: 0.00054972,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.000999927520752,
        QA_MA: 2057.931,
        VA_MA: 1.78526,
        QC_MA: 2057.931,
        VC_MA: 1.78526,
        QE_MA: 2252.975,
        VE_MA: 1.79613,
        QA_01: 3323.554,
        VA_01: 2.21333,
        QC_01: 3323.554,
        VC_01: 2.21333,
        QE_01: 3528.828,
        VE_01: 2.19641,
        QA_02: 3518.605,
        VA_02: 2.2719,
        QC_02: 3518.605,
        VC_02: 2.2719,
        QE_02: 3590.427,
        VE_02: 2.21384,
        QA_03: 3976.827,
        VA_03: 2.40378,
        QC_03: 3976.827,
        VC_03: 2.40378,
        QE_03: 4240.947,
        VE_03: 2.38999,
        QA_04: 3045.189,
        VA_04: 2.12688,
        QC_04: 3045.189,
        VC_04: 2.12688,
        QE_04: 3237.314,
        VE_04: 2.11192,
        QA_05: 2323.972,
        VA_05: 1.88375,
        QC_05: 2323.972,
        VC_05: 1.88375,
        QE_05: 2546.094,
        VE_05: 1.89596,
        QA_06: 1351.491,
        VA_06: 1.48973,
        QC_06: 1351.491,
        VC_06: 1.48973,
        QE_06: 1611.104,
        VE_06: 1.55347,
        QA_07: 800.753,
        VA_07: 1.20307,
        QC_07: 800.753,
        VC_07: 1.20307,
        QE_07: 947.647,
        VE_07: 1.24771,
        QA_08: 564.864,
        VA_08: 1.05174,
        QC_08: 564.864,
        VC_08: 1.05174,
        QE_08: 864.48,
        VE_08: 1.203,
        QA_09: 554.574,
        VA_09: 1.04452,
        QC_09: 1035.122,
        VC_09: 1.33374,
        QE_09: 1071.629,
        VE_09: 1.31109,
        QA_10: 712.746,
        VA_10: 1.14944,
        QC_10: 1045.623,
        VC_10: 1.33925,
        QE_10: 1006.12,
        VE_10: 1.27806,
        QA_11: 1917.243,
        VA_11: 1.73076,
        QC_11: 1917.243,
        VC_11: 1.73076,
        QE_11: 1894.443,
        VE_11: 1.66532,
        QA_12: 2618.713,
        VA_12: 1.98687,
        QC_12: 2618.713,
        VC_12: 1.98687,
        QE_12: 2642.426,
        VE_12: 1.92758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.191077117748534, 35.822424277729738, 0.0],
          [-81.176238584438238, 35.822864277729195, 0.0],
          [-81.169630717781843, 35.825423411058466, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283678.0,
        COMID: 9753940,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.26,
        REACHCODE: "03050101000068",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19489058,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0136584110932,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061445.0,
        ToNode: 250061443.0,
        Hydroseq: 250009920.0,
        LevelPathI: 250003258.0,
        Pathlength: 573.727,
        TerminalPa: 250002604.0,
        ArbolateSu: 3070.856,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009967.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009873.0,
        FromMeas: 0.0,
        ToMeas: 36.78478,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.1735,
        TotDASqKM: 3406.6314,
        DivDASqKM: 3406.6314,
        Tidal: 0,
        TOTMA: 0.028709014905599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25537.0,
        MAXELEVSMO: 25576.0,
        MINELEVSMO: 25537.0,
        SLOPE: 0.00030952,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2599999904632571,
        QA_MA: 2059.505,
        VA_MA: 1.65665,
        QC_MA: 2059.505,
        VC_MA: 1.65665,
        QE_MA: 2254.549,
        VE_MA: 1.66657,
        QA_01: 3326.504,
        VA_01: 2.04755,
        QC_01: 3326.504,
        VC_01: 2.04755,
        QE_01: 3531.779,
        VE_01: 2.03211,
        QA_02: 3521.789,
        VA_02: 2.10104,
        QC_02: 3521.789,
        VC_02: 2.10104,
        QE_02: 3593.611,
        VE_02: 2.04806,
        QA_03: 3980.306,
        VA_03: 2.22141,
        QC_03: 3980.306,
        VC_03: 2.22141,
        QE_03: 4244.425,
        VE_03: 2.20882,
        QA_04: 3047.715,
        VA_04: 1.96857,
        QC_04: 3047.715,
        VC_04: 1.96857,
        QE_04: 3239.841,
        VE_04: 1.95492,
        QA_05: 2325.536,
        VA_05: 1.74649,
        QC_05: 2325.536,
        VC_05: 1.74649,
        QE_05: 2547.658,
        VE_05: 1.75764,
        QA_06: 1352.412,
        VA_06: 1.3868,
        QC_06: 1352.412,
        VC_06: 1.3868,
        QE_06: 1612.025,
        VE_06: 1.44496,
        QA_07: 801.321,
        VA_07: 1.12514,
        QC_07: 801.321,
        VC_07: 1.12514,
        QE_07: 948.215,
        VE_07: 1.16586,
        QA_08: 565.239,
        VA_08: 0.98697,
        QC_08: 565.239,
        VC_08: 0.98697,
        QE_08: 864.855,
        VE_08: 1.12497,
        QA_09: 554.902,
        VA_09: 0.98035,
        QC_09: 1035.663,
        VC_09: 1.24433,
        QE_09: 1072.169,
        VE_09: 1.22367,
        QA_10: 712.98,
        VA_10: 1.07602,
        QC_10: 1045.897,
        VC_10: 1.24923,
        QE_10: 1006.394,
        VE_10: 1.19341,
        QA_11: 1917.995,
        VA_11: 1.60664,
        QC_11: 1917.995,
        VC_11: 1.60664,
        QE_11: 1895.195,
        VE_11: 1.54695,
        QA_12: 2620.718,
        VA_12: 1.8407,
        QC_12: 2620.718,
        VC_12: 1.8407,
        QE_12: 2644.431,
        VE_12: 1.78661,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.169630717781843, 35.825423411058466, 0.0],
          [-81.160399317796134, 35.824939611059278, 0.0],
          [-81.156899584468192, 35.827211211055726, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286756.0,
        COMID: 9736522,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 0.752,
        REACHCODE: "03050103000045",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0071405413307050001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060013.0,
        ToNode: 250060014.0,
        Hydroseq: 250016508.0,
        LevelPathI: 250016109.0,
        Pathlength: 389.508,
        TerminalPa: 250002604.0,
        ArbolateSu: 453.259,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250016715.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016309.0,
        FromMeas: 22.84642,
        ToMeas: 42.02107,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4158,
        TotDASqKM: 423.0603,
        DivDASqKM: 423.0603,
        Tidal: 0,
        TOTMA: 0.036844326389299999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12635.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.75199997425079301,
        QA_MA: 165.072,
        VA_MA: 0.77528,
        QC_MA: 163.976,
        VC_MA: 0.77503,
        QE_MA: 163.976,
        VE_MA: 0.77503,
        QA_01: 365.219,
        VA_01: 1.02017,
        QC_01: 283.752,
        VC_01: 0.93276,
        QE_01: 283.752,
        VE_01: 0.93276,
        QA_02: 379.792,
        VA_02: 1.03511,
        QC_02: 314.711,
        VC_02: 0.96805,
        QE_02: 314.711,
        VE_02: 0.96805,
        QA_03: 406.076,
        VA_03: 1.0614,
        QC_03: 339.121,
        VC_03: 0.99474,
        QE_03: 339.121,
        VE_03: 0.99474,
        QA_04: 230.775,
        VA_04: 0.86619,
        QC_04: 213.408,
        VC_04: 0.8451,
        QE_04: 213.408,
        VE_04: 0.8451,
        QA_05: 129.326,
        VA_05: 0.71853,
        QC_05: 122.441,
        VC_05: 0.70798,
        QE_05: 122.441,
        VE_05: 0.70798,
        QA_06: 82.47,
        VA_06: 0.63137,
        QC_06: 103.582,
        VC_06: 0.67402,
        QE_06: 103.582,
        VE_06: 0.67402,
        QA_07: 62.034,
        VA_07: 0.58605,
        QC_07: 93.748,
        VC_07: 0.65516,
        QE_07: 93.748,
        VE_07: 0.65516,
        QA_08: 45.505,
        VA_08: 0.54393,
        QC_08: 101.895,
        VC_08: 0.67085,
        QE_08: 101.895,
        VE_08: 0.67085,
        QA_09: 34.234,
        VA_09: 0.51089,
        QC_09: 88.539,
        VC_09: 0.64479,
        QE_09: 88.539,
        VE_09: 0.64479,
        QA_10: 28.441,
        VA_10: 0.49191,
        QC_10: 79.767,
        VC_10: 0.62666,
        QE_10: 79.767,
        VE_10: 0.62666,
        QA_11: 29.338,
        VA_11: 0.49496,
        QC_11: 50.297,
        VC_11: 0.55753,
        QE_11: 50.297,
        VE_11: 0.55753,
        QA_12: 188.867,
        VA_12: 0.80989,
        QC_12: 155.639,
        VC_12: 0.76227,
        QE_12: 155.639,
        VE_12: 0.76227,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.848053318280961, 34.6996046794726, 0.0],
          [-80.849837118278131, 34.702418479468292, 0.0],
          [-80.851610384942092, 34.699630812805935, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288935.0,
        COMID: 9719035,
        FDATE: "2001/06/20 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 0.074,
        REACHCODE: "03050104000150",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "Connector",
        FCODE: 33400,
        Shape_Leng: 0.00071120241114300004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058480.0,
        ToNode: 250058168.0,
        Hydroseq: 250015146.0,
        LevelPathI: 250014857.0,
        Pathlength: 360.742,
        TerminalPa: 250002604.0,
        ArbolateSu: 228.737,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015299.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015006.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 360.441,
        DivDASqKM: 360.441,
        Tidal: 0,
        TOTMA: 0.0037167556048299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6778.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 131.439,
        VA_MA: 0.75595,
        QC_MA: 131.726,
        VC_MA: 0.75603,
        QE_MA: 131.726,
        VE_MA: 0.75603,
        QA_01: 288.501,
        VA_01: 0.98782,
        QC_01: 225.537,
        VC_01: 0.90397,
        QE_01: 225.537,
        VE_01: 0.90397,
        QA_02: 316.752,
        VA_02: 1.02238,
        QC_02: 263.775,
        VC_02: 0.95562,
        QE_02: 263.775,
        VE_02: 0.95562,
        QA_03: 342.762,
        VA_03: 1.05294,
        QC_03: 287.852,
        VC_03: 0.98634,
        QE_03: 287.852,
        VE_03: 0.98634,
        QA_04: 187.397,
        VA_04: 0.8487,
        QC_04: 174.116,
        VC_04: 0.82751,
        QE_04: 174.116,
        VE_04: 0.82751,
        QA_05: 105.298,
        VA_05: 0.70623,
        QC_05: 100.461,
        VC_05: 0.69604,
        QE_05: 100.461,
        VE_05: 0.69604,
        QA_06: 67.851,
        VA_06: 0.62343,
        QC_06: 85.449,
        VC_06: 0.66412,
        QE_06: 85.449,
        VE_06: 0.66412,
        QA_07: 48.462,
        VA_07: 0.57187,
        QC_07: 73.672,
        VC_07: 0.63718,
        QE_07: 73.672,
        VE_07: 0.63718,
        QA_08: 38.77,
        VA_08: 0.54243,
        QC_08: 86.535,
        VC_08: 0.66651,
        QE_08: 86.535,
        VE_08: 0.66651,
        QA_09: 29.077,
        VA_09: 0.50928,
        QC_09: 76.653,
        VC_09: 0.64418,
        QE_09: 76.653,
        VE_09: 0.64418,
        QA_10: 24.231,
        VA_10: 0.49074,
        QC_10: 70.186,
        VC_10: 0.62882,
        QE_10: 70.186,
        VE_10: 0.62882,
        QA_11: 19.385,
        VA_11: 0.47037,
        QC_11: 36.669,
        VC_11: 0.53539,
        QE_11: 36.669,
        VE_11: 0.53539,
        QA_12: 109.572,
        VA_12: 0.71473,
        QC_12: 94.838,
        VC_12: 0.68436,
        QE_12: 94.838,
        VE_12: 0.68436,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.917538918173136, 34.469423613163265, 0.0],
          [-80.91710358484039, 34.469986013162384, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286757.0,
        COMID: 9736524,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 0.034,
        REACHCODE: "03050103000045",
        FLOWDIR: "With Digitized",
        WBAREACOMI: -9998,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000342967215319,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060014.0,
        ToNode: 250060015.0,
        Hydroseq: 250016309.0,
        LevelPathI: 250016109.0,
        Pathlength: 389.474,
        TerminalPa: 250002604.0,
        ArbolateSu: 453.293,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250016508.0,
        DnLevelPat: 250016109.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016109.0,
        FromMeas: 21.96922,
        ToMeas: 22.84642,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0153,
        TotDASqKM: 423.0756,
        DivDASqKM: 423.0756,
        Tidal: 0,
        TOTMA: 0.0016658340534700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12649.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.034000001847744002,
        QA_MA: 165.078,
        VA_MA: 0.77528,
        QC_MA: 163.981,
        VC_MA: 0.77503,
        QE_MA: 163.981,
        VE_MA: 0.77503,
        QA_01: 365.231,
        VA_01: 1.02018,
        QC_01: 283.762,
        VC_01: 0.93276,
        QE_01: 283.762,
        VE_01: 0.93276,
        QA_02: 379.806,
        VA_02: 1.03512,
        QC_02: 314.722,
        VC_02: 0.96806,
        QE_02: 314.722,
        VE_02: 0.96806,
        QA_03: 406.09,
        VA_03: 1.06141,
        QC_03: 339.132,
        VC_03: 0.99475,
        QE_03: 339.132,
        VE_03: 0.99475,
        QA_04: 230.784,
        VA_04: 0.8662,
        QC_04: 213.416,
        VC_04: 0.84511,
        QE_04: 213.416,
        VE_04: 0.84511,
        QA_05: 129.331,
        VA_05: 0.71854,
        QC_05: 122.445,
        VC_05: 0.70799,
        QE_05: 122.445,
        VE_05: 0.70799,
        QA_06: 82.473,
        VA_06: 0.63138,
        QC_06: 103.585,
        VC_06: 0.67403,
        QE_06: 103.585,
        VE_06: 0.67403,
        QA_07: 62.036,
        VA_07: 0.58605,
        QC_07: 93.751,
        VC_07: 0.65516,
        QE_07: 93.751,
        VE_07: 0.65516,
        QA_08: 45.507,
        VA_08: 0.54393,
        QC_08: 101.899,
        VC_08: 0.67085,
        QE_08: 101.899,
        VE_08: 0.67085,
        QA_09: 34.235,
        VA_09: 0.51089,
        QC_09: 88.542,
        VC_09: 0.6448,
        QE_09: 88.542,
        VE_09: 0.6448,
        QA_10: 28.442,
        VA_10: 0.49191,
        QC_10: 79.769,
        VC_10: 0.62666,
        QE_10: 79.769,
        VE_10: 0.62666,
        QA_11: 29.339,
        VA_11: 0.49496,
        QC_11: 50.298,
        VC_11: 0.55753,
        QE_11: 50.298,
        VE_11: 0.55753,
        QA_12: 188.873,
        VA_12: 0.80989,
        QC_12: 155.644,
        VC_12: 0.76228,
        QE_12: 155.644,
        VE_12: 0.76228,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.851610384942092, 34.699630812805935, 0.0],
          [-80.851875318275063, 34.699413012806247, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286758.0,
        COMID: 9736526,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "982589",
        GNIS_NAME: "Cane Creek",
        LENGTHKM: 0.854,
        REACHCODE: "03050103000045",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0089727612571650003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060015.0,
        ToNode: 250060018.0,
        Hydroseq: 250016109.0,
        LevelPathI: 250016109.0,
        Pathlength: 388.62,
        TerminalPa: 250002604.0,
        ArbolateSu: 454.147,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250016109.0,
        UpHydroseq: 250016309.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005217.0,
        FromMeas: 0.0,
        ToMeas: 21.96922,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7155,
        TotDASqKM: 423.7911,
        DivDASqKM: 423.7911,
        Tidal: 0,
        TOTMA: 0.041832112715200001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12634.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.85399997234344505,
        QA_MA: 165.348,
        VA_MA: 0.77546,
        QC_MA: 164.24,
        VC_MA: 0.77521,
        QE_MA: 164.24,
        VE_MA: 0.77521,
        QA_01: 365.828,
        VA_01: 1.02045,
        QC_01: 284.213,
        VC_01: 0.933,
        QE_01: 284.213,
        VE_01: 0.933,
        QA_02: 380.437,
        VA_02: 1.03541,
        QC_02: 315.231,
        VC_02: 0.96832,
        QE_02: 315.231,
        VE_02: 0.96832,
        QA_03: 406.771,
        VA_03: 1.06171,
        QC_03: 339.682,
        VC_03: 0.99503,
        QE_03: 339.682,
        VE_03: 0.99503,
        QA_04: 231.168,
        VA_04: 0.86642,
        QC_04: 213.763,
        VC_04: 0.84532,
        QE_04: 213.763,
        VE_04: 0.84532,
        QA_05: 129.542,
        VA_05: 0.71869,
        QC_05: 122.638,
        VC_05: 0.70814,
        QE_05: 122.638,
        VE_05: 0.70814,
        QA_06: 82.607,
        VA_06: 0.6315,
        QC_06: 103.752,
        VC_06: 0.67417,
        QE_06: 103.752,
        VE_06: 0.67417,
        QA_07: 62.132,
        VA_07: 0.58615,
        QC_07: 93.893,
        VC_07: 0.65528,
        QE_07: 93.893,
        VE_07: 0.65528,
        QA_08: 45.584,
        VA_08: 0.54403,
        QC_08: 102.074,
        VC_08: 0.67102,
        QE_08: 102.074,
        VE_08: 0.67102,
        QA_09: 34.293,
        VA_09: 0.51098,
        QC_09: 88.673,
        VC_09: 0.64491,
        QE_09: 88.673,
        VE_09: 0.64491,
        QA_10: 28.49,
        VA_10: 0.49198,
        QC_10: 79.877,
        VC_10: 0.62675,
        QE_10: 79.877,
        VE_10: 0.62675,
        QA_11: 29.378,
        VA_11: 0.495,
        QC_11: 50.348,
        VC_11: 0.55755,
        QE_11: 50.348,
        VE_11: 0.55755,
        QA_12: 189.167,
        VA_12: 0.81006,
        QC_12: 155.865,
        VC_12: 0.76241,
        QE_12: 155.865,
        VE_12: 0.76241,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.851875318275063, 34.699413012806247, 0.0],
          [-80.859925784929089, 34.695789879478639, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283831.0,
        COMID: 9750542,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 0.164,
        REACHCODE: "03050101000249",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001510589449385,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060567.0,
        ToNode: 250060767.0,
        Hydroseq: 250037464.0,
        LevelPathI: 250024233.0,
        Pathlength: 669.401,
        TerminalPa: 250002604.0,
        ArbolateSu: 98.128,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250039713.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250035546.0,
        FromMeas: 94.93742,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1035,
        TotDASqKM: 90.0441,
        DivDASqKM: 90.0441,
        Tidal: 0,
        TOTMA: 0.0039247759796199998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 46812.0,
        MAXELEVSMO: 47421.0,
        MINELEVSMO: 46812.0,
        SLOPE: 0.03713414,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.164000004529953,
        QA_MA: 68.02,
        VA_MA: 1.58385,
        QC_MA: 69.934,
        VC_MA: 1.58672,
        QE_MA: 69.934,
        VE_MA: 1.58672,
        QA_01: 86.035,
        VA_01: 1.75333,
        QC_01: 69.425,
        VC_01: 1.58178,
        QE_01: 69.425,
        VE_01: 1.58178,
        QA_02: 92.651,
        VA_02: 1.81131,
        QC_02: 79.784,
        VC_02: 1.67937,
        QE_02: 79.784,
        VE_02: 1.67937,
        QA_03: 116.739,
        VA_03: 2.00753,
        QC_03: 101.586,
        VC_03: 1.86702,
        QE_03: 101.586,
        VE_03: 1.86702,
        QA_04: 98.579,
        VA_04: 1.86163,
        QC_04: 92.94,
        VC_04: 1.79511,
        QE_04: 92.94,
        VE_04: 1.79511,
        QA_05: 77.856,
        VA_05: 1.67869,
        QC_05: 75.123,
        VC_05: 1.63624,
        QE_05: 75.123,
        VE_05: 1.63624,
        QA_06: 54.147,
        VA_06: 1.43836,
        QC_06: 68.403,
        VC_06: 1.57179,
        QE_06: 68.403,
        VE_06: 1.57179,
        QA_07: 32.971,
        VA_07: 1.17668,
        QC_07: 50.586,
        VC_07: 1.38474,
        QE_07: 50.586,
        VE_07: 1.38474,
        QA_08: 26.201,
        VA_08: 1.07693,
        QC_08: 58.024,
        VC_08: 1.46608,
        QE_08: 58.024,
        VE_08: 1.46608,
        QA_09: 33.654,
        VA_09: 1.18619,
        QC_09: 87.213,
        VC_09: 1.74574,
        QE_09: 87.213,
        VE_09: 1.74574,
        QA_10: 46.091,
        VA_10: 1.34572,
        QC_10: 117.305,
        VC_10: 1.99076,
        QE_10: 117.305,
        VE_10: 1.99076,
        QA_11: 74.327,
        VA_11: 1.64535,
        QC_11: 102.193,
        VC_11: 1.87196,
        QE_11: 102.193,
        VE_11: 1.87196,
        QA_12: 77.462,
        VA_12: 1.675,
        QC_12: 69.176,
        VC_12: 1.57935,
        QE_12: 69.176,
        VE_12: 1.57935,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.774251583510022, 36.001521277451729, 0.0],
          [-81.773750316844087, 36.000139677453944, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286731.0,
        COMID: 9747444,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.251,
        REACHCODE: "03050102004438",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022527603098503001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060481.0,
        ToNode: 250060483.0,
        Hydroseq: 250011633.0,
        LevelPathI: 250011553.0,
        Pathlength: 472.285,
        TerminalPa: 250002604.0,
        ArbolateSu: 1167.1,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250011712.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011553.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.2522,
        TotDASqKM: 1652.6961,
        DivDASqKM: 1652.6961,
        Tidal: 0,
        TOTMA: 0.7729941161,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17287.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.250999927520752,
        QA_MA: 825.052,
        VA_MA: -9998.0,
        QC_MA: 769.964,
        VC_MA: -9998.0,
        QE_MA: 822.896,
        VE_MA: -9998.0,
        QA_01: 1550.211,
        VA_01: -9998.0,
        QC_01: 1159.639,
        VC_01: -9998.0,
        QE_01: 1144.617,
        VE_01: -9998.0,
        QA_02: 1621.718,
        VA_02: -9998.0,
        QC_02: 1291.685,
        VC_02: -9998.0,
        QE_02: 1270.71,
        VE_02: -9998.0,
        QA_03: 1745.983,
        VA_03: -9998.0,
        QC_03: 1389.56,
        VC_03: -9998.0,
        QE_03: 1397.797,
        VE_03: -9998.0,
        QA_04: 1186.444,
        VA_04: -9998.0,
        QC_04: 1057.076,
        VC_04: -9998.0,
        QE_04: 1049.737,
        VE_04: -9998.0,
        QA_05: 822.437,
        VA_05: -9998.0,
        QC_05: 726.586,
        VC_05: -9998.0,
        QE_05: 818.119,
        VE_05: -9998.0,
        QA_06: 474.287,
        VA_06: -9998.0,
        QC_06: 581.563,
        VC_06: -9998.0,
        QE_06: 636.311,
        VE_06: -9998.0,
        QA_07: 290.132,
        VA_07: -9998.0,
        QC_07: 422.574,
        VC_07: -9998.0,
        QE_07: 499.038,
        VE_07: -9998.0,
        QA_08: 204.839,
        VA_08: -9998.0,
        QC_08: 472.703,
        VC_08: -9998.0,
        QE_08: 544.632,
        VE_08: -9998.0,
        QA_09: 175.433,
        VA_09: -9998.0,
        QC_09: 374.696,
        VC_09: -9998.0,
        QE_09: 435.097,
        VE_09: -9998.0,
        QA_10: 189.372,
        VA_10: -9998.0,
        QC_10: 362.707,
        VC_10: -9998.0,
        QE_10: 651.135,
        VE_10: -9998.0,
        QA_11: 553.093,
        VA_11: -9998.0,
        QC_11: 472.245,
        VC_11: -9998.0,
        QE_11: 675.986,
        VE_11: -9998.0,
        QA_12: 1092.44,
        VA_12: -9998.0,
        QC_12: 768.48,
        VC_12: -9998.0,
        QE_12: 778.12,
        VE_12: -9998.0,
        LakeFract: 0.01237928998,
        SurfArea: 560594.27260499995,
        RAreaHLoad: 0.27827970686999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.073082117931563, 35.242810011962831, 0.0],
          [-81.064406584611746, 35.232999811978061, 0.0],
          [-81.06550551794345, 35.230833211981405, 0.0],
          [-81.071321184600947, 35.230354811982131, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286733.0,
        COMID: 9747448,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 11.441,
        REACHCODE: "03050102004440",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.110686938391093,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060483.0,
        ToNode: 250062011.0,
        Hydroseq: 250011553.0,
        LevelPathI: 250011553.0,
        Pathlength: 460.844,
        TerminalPa: 250002604.0,
        ArbolateSu: 1191.047,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250011633.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007553.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 32.0652,
        TotDASqKM: 1711.4103,
        DivDASqKM: 1711.4103,
        Tidal: 0,
        TOTMA: 3.9288430409799999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17186.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 11.440999984741211,
        QA_MA: 847.535,
        VA_MA: -9998.0,
        QC_MA: 790.122,
        VC_MA: -9998.0,
        QE_MA: 843.053,
        VE_MA: -9998.0,
        QA_01: 1598.501,
        VA_01: -9998.0,
        QC_01: 1194.801,
        VC_01: -9998.0,
        QE_01: 1179.779,
        VE_01: -9998.0,
        QA_02: 1673.753,
        VA_02: -9998.0,
        QC_02: 1331.983,
        VC_02: -9998.0,
        QE_02: 1311.008,
        VE_02: -9998.0,
        QA_03: 1801.129,
        VA_03: -9998.0,
        QC_03: 1431.978,
        VC_03: -9998.0,
        QE_03: 1440.215,
        VE_03: -9998.0,
        QA_04: 1218.047,
        VA_04: -9998.0,
        QC_04: 1084.585,
        VC_04: -9998.0,
        QE_04: 1077.246,
        VE_04: -9998.0,
        QA_05: 840.582,
        VA_05: -9998.0,
        QC_05: 742.011,
        VC_05: -9998.0,
        QE_05: 833.544,
        VE_05: -9998.0,
        QA_06: 485.339,
        VA_06: -9998.0,
        QC_06: 594.927,
        VC_06: -9998.0,
        QE_06: 649.674,
        VE_06: -9998.0,
        QA_07: 297.409,
        VA_07: -9998.0,
        QC_07: 432.916,
        VC_07: -9998.0,
        QE_07: 509.38,
        VE_07: -9998.0,
        QA_08: 210.365,
        VA_08: -9998.0,
        QC_08: 485.714,
        VC_08: -9998.0,
        QE_08: 557.643,
        VE_08: -9998.0,
        QA_09: 180.327,
        VA_09: -9998.0,
        QC_09: 383.909,
        VC_09: -9998.0,
        QE_09: 444.311,
        VE_09: -9998.0,
        QA_10: 193.319,
        VA_10: -9998.0,
        QC_10: 368.733,
        VC_10: -9998.0,
        QE_10: 657.162,
        VE_10: -9998.0,
        QA_11: 557.382,
        VA_11: -9998.0,
        QC_11: 475.036,
        VC_11: -9998.0,
        QE_11: 678.776,
        VE_11: -9998.0,
        QA_12: 1120.229,
        VA_12: -9998.0,
        QC_12: 786.245,
        VC_12: -9998.0,
        QE_12: 795.885,
        VE_12: -9998.0,
        LakeFract: 0.06463738012,
        SurfArea: 2927093.9730799999,
        RAreaHLoad: 1.4143927709099999,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.071321184600947, 35.230354811982131, 0.0],
          [-81.072860384598698, 35.228515811985062, 0.0],
          [-81.072499384599212, 35.22521267865676, 0.0],
          [-81.070340984602524, 35.222494278660974, 0.0],
          [-81.061608984616157, 35.218121412001096, 0.0],
          [-81.062191317948489, 35.213445878675088, 0.0],
          [-81.058698517953985, 35.20553521202072, 0.0],
          [-81.0571795179563, 35.20384807868993, 0.0],
          [-81.052446517963745, 35.203106878691131, 0.0],
          [-81.047262384638387, 35.195250612036659, 0.0],
          [-81.044449384642746, 35.192447612041008, 0.0],
          [-81.040097517982758, 35.191483078709211, 0.0],
          [-81.033990784659011, 35.184050612054079, 0.0],
          [-81.040834317981705, 35.171491012073602, 0.0],
          [-81.039180984650955, 35.16707181208028, 0.0],
          [-81.03421891799195, 35.164943012083775, 0.0],
          [-81.033480584659799, 35.156245678763923, 0.0],
          [-81.031403717996341, 35.153129212102101, 0.0],
          [-81.032856384660818, 35.150872412105628, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283843.0,
        COMID: 9752782,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996813",
        GNIS_NAME: "Warrior Fork",
        LENGTHKM: 2.939,
        REACHCODE: "03050101000261",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.029168358261405,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061138.0,
        ToNode: 250061139.0,
        Hydroseq: 250025530.0,
        LevelPathI: 250024853.0,
        Pathlength: 632.809,
        TerminalPa: 250002604.0,
        ArbolateSu: 200.219,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024853.0,
        UpHydroseq: 250026271.0,
        DnLevelPat: 250024853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024853.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.087,
        TotDASqKM: 216.6111,
        DivDASqKM: 216.6111,
        Tidal: 0,
        TOTMA: 0.095194880053699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30302.0,
        MAXELEVSMO: 30727.0,
        MINELEVSMO: 30430.0,
        SLOPE: 0.00101054,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9389998912811279,
        QA_MA: 130.418,
        VA_MA: 1.17217,
        QC_MA: 130.742,
        VC_MA: 1.17235,
        QE_MA: 130.742,
        VE_MA: 1.17235,
        QA_01: 207.852,
        VA_01: 1.41473,
        QC_01: 163.891,
        VC_01: 1.28249,
        QE_01: 163.891,
        VE_01: 1.28249,
        QA_02: 218.153,
        VA_02: 1.44351,
        QC_02: 183.522,
        VC_02: 1.3428,
        QE_02: 183.522,
        VE_02: 1.3428,
        QA_03: 254.698,
        VA_03: 1.54081,
        QC_03: 216.003,
        VC_03: 1.4363,
        QE_03: 216.003,
        VE_03: 1.4363,
        QA_04: 195.205,
        VA_04: 1.37846,
        QC_04: 181.202,
        VC_04: 1.33584,
        QE_04: 181.202,
        VE_04: 1.33584,
        QA_05: 153.81,
        VA_05: 1.25126,
        QC_05: 144.679,
        VC_05: 1.22009,
        QE_05: 144.679,
        VE_05: 1.22009,
        QA_06: 86.838,
        VA_06: 1.0044,
        QC_06: 108.991,
        VC_06: 1.09278,
        QE_06: 108.991,
        VE_06: 1.09278,
        QA_07: 50.893,
        VA_07: 0.83247,
        QC_07: 77.277,
        VC_07: 0.96186,
        QE_07: 77.277,
        VE_07: 0.96186,
        QA_08: 35.386,
        VA_08: 0.74049,
        QC_08: 78.837,
        VC_08: 0.96884,
        QE_08: 78.837,
        VE_08: 0.96884,
        QA_09: 35.896,
        VA_09: 0.74379,
        QC_09: 92.322,
        VC_09: 1.0266,
        QE_09: 92.322,
        VE_09: 1.0266,
        QA_10: 43.859,
        VA_10: 0.79266,
        QC_10: 112.744,
        VC_10: 1.10701,
        QE_10: 112.744,
        VE_10: 1.10701,
        QA_11: 120.885,
        VA_11: 1.13805,
        QC_11: 148.084,
        VC_11: 1.23142,
        QE_11: 148.084,
        VE_11: 1.23142,
        QA_12: 162.757,
        VA_12: 1.27999,
        QC_12: 135.934,
        VC_12: 1.1904,
        QE_12: 135.934,
        VE_12: 1.1904,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.716504516932844, 35.793106011108712, 0.0],
          [-81.712171783606323, 35.789445211114355, 0.0],
          [-81.702108316955218, 35.788124011116452, 0.0],
          [-81.70043878362452, 35.776605811134175, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286734.0,
        COMID: 9756914,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.06,
        REACHCODE: "03050102009473",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00066137312956000003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062007.0,
        ToNode: 250062011.0,
        Hydroseq: 250007582.0,
        LevelPathI: 250003258.0,
        Pathlength: 460.844,
        TerminalPa: 250002604.0,
        ArbolateSu: 4383.742,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007610.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007553.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 5434.6194,
        DivDASqKM: 5434.6194,
        Tidal: 0,
        TOTMA: 0.02060401909,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17187.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.059999998658895,
        QA_MA: 2935.647,
        VA_MA: -9998.0,
        QC_MA: 2935.647,
        VC_MA: -9998.0,
        QE_MA: 3131.556,
        VE_MA: -9998.0,
        QA_01: 5026.48,
        VA_01: -9998.0,
        QC_01: 5026.48,
        VC_01: -9998.0,
        QE_01: 5209.073,
        VE_01: -9998.0,
        QA_02: 5339.18,
        VA_02: -9998.0,
        QC_02: 5339.18,
        VC_02: -9998.0,
        QE_02: 5382.239,
        VE_02: -9998.0,
        QA_03: 5956.324,
        VA_03: -9998.0,
        QC_03: 5956.324,
        VC_03: -9998.0,
        QE_03: 6181.254,
        VE_03: -9998.0,
        QA_04: 4373.069,
        VA_04: -9998.0,
        QC_04: 4373.069,
        VC_04: -9998.0,
        QE_04: 4549.825,
        VE_04: -9998.0,
        QA_05: 3157.461,
        VA_05: -9998.0,
        QC_05: 3157.461,
        VC_05: -9998.0,
        QE_05: 3384.198,
        VE_05: -9998.0,
        QA_06: 1849.964,
        VA_06: -9998.0,
        QC_06: 1849.964,
        VC_06: -9998.0,
        QE_06: 2134.893,
        VE_06: -9998.0,
        QA_07: 1114.334,
        VA_07: -9998.0,
        QC_07: 1114.334,
        VC_07: -9998.0,
        QE_07: 1285.31,
        VE_07: -9998.0,
        QA_08: 783.002,
        VA_08: -9998.0,
        QC_08: 783.002,
        VC_08: -9998.0,
        QE_08: 1110.952,
        VE_08: -9998.0,
        QA_09: 741.16,
        VA_09: -9998.0,
        QC_09: 1337.193,
        VC_09: -9998.0,
        QE_09: 1389.112,
        VE_09: -9998.0,
        QA_10: 870.967,
        VA_10: -9998.0,
        QC_10: 870.967,
        VC_10: -9998.0,
        QE_10: 845.777,
        VE_10: -9998.0,
        QA_11: 2318.372,
        VA_11: -9998.0,
        QC_11: 2318.372,
        VC_11: -9998.0,
        QE_11: 2273.814,
        VE_11: -9998.0,
        QA_12: 3717.377,
        VA_12: -9998.0,
        QC_12: 3717.377,
        VC_12: -9998.0,
        QE_12: 3713.215,
        VE_12: -9998.0,
        LakeFract: 0.00125676029,
        SurfArea: 56912.199467799997,
        RAreaHLoad: 0.00741749552,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.032200384661806, 35.150788278772268, 0.0],
          [-81.032856384660818, 35.150872412105628, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283844.0,
        COMID: 9752698,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996813",
        GNIS_NAME: "Warrior Fork",
        LENGTHKM: 1.853,
        REACHCODE: "03050101000262",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018277103373223001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061116.0,
        ToNode: 250061138.0,
        Hydroseq: 250026271.0,
        LevelPathI: 250024853.0,
        Pathlength: 635.748,
        TerminalPa: 250002604.0,
        ArbolateSu: 193.312,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024853.0,
        UpHydroseq: 250027067.0,
        DnLevelPat: 250024853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025530.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7018,
        TotDASqKM: 207.9477,
        DivDASqKM: 207.9477,
        Tidal: 0,
        TOTMA: 0.0604190207285,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30533.0,
        MAXELEVSMO: 30914.0,
        MINELEVSMO: 30727.0,
        SLOPE: 0.00100917,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8530000448226931,
        QA_MA: 125.926,
        VA_MA: 1.16433,
        QC_MA: 126.411,
        VC_MA: 1.16459,
        QE_MA: 126.411,
        VE_MA: 1.16459,
        QA_01: 199.677,
        VA_01: 1.40173,
        QC_01: 157.611,
        VC_01: 1.27099,
        QE_01: 157.611,
        VE_01: 1.27099,
        QA_02: 209.378,
        VA_02: 1.42962,
        QC_02: 176.338,
        VC_02: 1.33013,
        QE_02: 176.338,
        VE_02: 1.33013,
        QA_03: 245.291,
        VA_03: 1.52794,
        QC_03: 208.284,
        VC_03: 1.42457,
        QE_03: 208.284,
        VE_03: 1.42457,
        QA_04: 188.384,
        VA_04: 1.36844,
        QC_04: 175.012,
        VC_04: 1.32604,
        QE_04: 175.012,
        VE_04: 1.32604,
        QA_05: 149.083,
        VA_05: 1.24459,
        QC_05: 140.398,
        VC_05: 1.21366,
        QE_05: 140.398,
        VE_05: 1.21366,
        QA_06: 84.152,
        VA_06: 0.9994,
        QC_06: 105.666,
        VC_06: 1.08685,
        QE_06: 105.666,
        VE_06: 1.08685,
        QA_07: 49.297,
        VA_07: 0.82858,
        QC_07: 74.911,
        VC_07: 0.95689,
        QE_07: 74.911,
        VE_07: 0.95689,
        QA_08: 34.268,
        VA_08: 0.73724,
        QC_08: 76.299,
        VC_08: 0.96324,
        QE_08: 76.299,
        VE_08: 0.96324,
        QA_09: 35.011,
        VA_09: 0.74215,
        QC_09: 90.311,
        VC_09: 1.02458,
        QE_09: 90.311,
        VE_09: 1.02458,
        QA_10: 43.16,
        VA_10: 0.79313,
        QC_10: 111.307,
        VC_10: 1.10865,
        QE_10: 111.307,
        VE_10: 1.10865,
        QA_11: 117.661,
        VA_11: 1.13402,
        QC_11: 145.063,
        VC_11: 1.22951,
        QE_11: 145.063,
        VE_11: 1.22951,
        QA_12: 156.921,
        VA_12: 1.27042,
        QC_12: 131.493,
        VC_12: 1.1827,
        QE_12: 131.493,
        VE_12: 1.1827,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.725230783586028, 35.803014877760006, 0.0],
          [-81.724661983586998, 35.797839811101255, 0.0],
          [-81.716480183599629, 35.796266411103772, 0.0],
          [-81.716504516932844, 35.793106011108712, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286727.0,
        COMID: 9745258,
        FDATE: "2001/04/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.383,
        REACHCODE: "03050102000488",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0039900505939440001,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060203.0,
        ToNode: 250060207.0,
        Hydroseq: 250018883.0,
        LevelPathI: 250018327.0,
        Pathlength: 554.2,
        TerminalPa: 250002604.0,
        ArbolateSu: 232.865,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250019185.0,
        DnLevelPat: 250018327.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018327.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1287,
        TotDASqKM: 231.5592,
        DivDASqKM: 0.1287,
        Tidal: 0,
        TOTMA: 0.029650434067,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24833.0,
        MAXELEVSMO: 25127.0,
        MINELEVSMO: 25059.0,
        SLOPE: 0.00293103,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.23199999332428001,
        QA_MA: 0.061,
        VA_MA: 0.4905,
        QC_MA: 0.061,
        VC_MA: 0.4905,
        QE_MA: 0.061,
        VE_MA: 0.4905,
        QA_01: 0.115,
        VA_01: 0.56339,
        QC_01: 0.115,
        VC_01: 0.56339,
        QE_01: 0.115,
        VE_01: 0.56339,
        QA_02: 0.122,
        VA_02: 0.57152,
        QC_02: 0.122,
        VC_02: 0.57152,
        QE_02: 0.122,
        VE_02: 0.57152,
        QA_03: 0.133,
        VA_03: 0.58386,
        QC_03: 0.133,
        VC_03: 0.58386,
        QE_03: 0.133,
        VE_03: 0.58386,
        QA_04: 0.091,
        VA_04: 0.53359,
        QC_04: 0.091,
        VC_04: 0.53359,
        QE_04: 0.091,
        VE_04: 0.53359,
        QA_05: 0.062,
        VA_05: 0.49207,
        QC_05: 0.062,
        VC_05: 0.49207,
        QE_05: 0.062,
        VE_05: 0.49207,
        QA_06: 0.036,
        VA_06: 0.44602,
        QC_06: 0.036,
        VC_06: 0.44602,
        QE_06: 0.036,
        VE_06: 0.44602,
        QA_07: 0.022,
        VA_07: 0.41435,
        QC_07: 0.022,
        VC_07: 0.41435,
        QE_07: 0.022,
        VE_07: 0.41435,
        QA_08: 0.015,
        VA_08: 0.39485,
        QC_08: 0.015,
        VC_08: 0.39485,
        QE_08: 0.015,
        VE_08: 0.39485,
        QA_09: 0.012,
        VA_09: 0.38519,
        QC_09: 0.012,
        VC_09: 0.38519,
        QE_09: 0.012,
        VE_09: 0.38519,
        QA_10: 0.01,
        VA_10: 0.3781,
        QC_10: 0.01,
        VC_10: 0.3781,
        QE_10: 0.01,
        VE_10: 0.3781,
        QA_11: 0.034,
        VA_11: 0.4419,
        QC_11: 0.034,
        VC_11: 0.4419,
        QE_11: 0.034,
        VE_11: 0.4419,
        QA_12: 0.079,
        VA_12: 0.5173,
        QC_12: 0.079,
        VC_12: 0.5173,
        QE_12: 0.079,
        VE_12: 0.5173,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.333338317527705, 35.649559811331471, 0.0],
          [-81.330476984198754, 35.648138811333638, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283845.0,
        COMID: 9752632,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996813",
        GNIS_NAME: "Warrior Fork",
        LENGTHKM: 2.497,
        REACHCODE: "03050101000263",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024435513596702998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061099.0,
        ToNode: 250061116.0,
        Hydroseq: 250027067.0,
        LevelPathI: 250024853.0,
        Pathlength: 637.601,
        TerminalPa: 250002604.0,
        ArbolateSu: 187.973,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024853.0,
        UpHydroseq: 250027939.0,
        DnLevelPat: 250024853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026271.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6901,
        TotDASqKM: 202.7313,
        DivDASqKM: 202.7313,
        Tidal: 0,
        TOTMA: 0.087058288827499997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30693.0,
        MAXELEVSMO: 31060.0,
        MINELEVSMO: 30914.0,
        SLOPE: 0.0005847,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.496999979019165,
        QA_MA: 123.191,
        VA_MA: 1.08883,
        QC_MA: 123.77,
        VC_MA: 1.08913,
        QE_MA: 123.77,
        VE_MA: 1.08913,
        QA_01: 194.741,
        VA_01: 1.30367,
        QC_01: 153.816,
        VC_01: 1.18463,
        QE_01: 153.816,
        VE_01: 1.18463,
        QA_02: 204.108,
        VA_02: 1.32881,
        QC_02: 172.019,
        VC_02: 1.23825,
        QE_02: 172.019,
        VE_02: 1.23825,
        QA_03: 239.616,
        VA_03: 1.41952,
        QC_03: 203.622,
        VC_03: 1.32537,
        QE_03: 203.622,
        VE_03: 1.32537,
        QA_04: 184.224,
        VA_04: 1.27476,
        QC_04: 171.235,
        VC_04: 1.236,
        QE_04: 171.235,
        VE_04: 1.236,
        QA_05: 146.151,
        VA_05: 1.16297,
        QC_05: 137.738,
        VC_05: 1.13474,
        QE_05: 137.738,
        VE_05: 1.13474,
        QA_06: 82.493,
        VA_06: 0.93914,
        QC_06: 103.61,
        VC_06: 1.01879,
        QE_06: 103.61,
        VE_06: 1.01879,
        QA_07: 48.306,
        VA_07: 0.78312,
        QC_07: 73.44,
        VC_07: 0.90013,
        QE_07: 73.44,
        VE_07: 0.90013,
        QA_08: 33.594,
        VA_08: 0.69985,
        QC_08: 74.768,
        VC_08: 0.90579,
        QE_08: 74.768,
        VE_08: 0.90579,
        QA_09: 34.481,
        VA_09: 0.7053,
        QC_09: 89.102,
        VC_09: 0.9641,
        QE_09: 89.102,
        VE_09: 0.9641,
        QA_10: 42.739,
        VA_10: 0.75323,
        QC_10: 110.439,
        VC_10: 1.04328,
        QE_10: 110.439,
        VE_10: 1.04328,
        QA_11: 115.619,
        VA_11: 1.06298,
        QC_11: 143.138,
        VC_11: 1.15179,
        QE_11: 143.138,
        VE_11: 1.15179,
        QA_12: 153.361,
        VA_12: 1.1851,
        QC_12: 128.776,
        VC_12: 1.10574,
        QE_12: 128.776,
        VE_12: 1.10574,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.736579183568494, 35.816584877738826, 0.0],
          [-81.735645516903276, 35.812509411078508, 0.0],
          [-81.731705583576058, 35.809742077749434, 0.0],
          [-81.732069316908735, 35.808436477751513, 0.0],
          [-81.725181583586107, 35.808465411084796, 0.0],
          [-81.725230783586028, 35.803014877760006, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286729.0,
        COMID: 9745286,
        FDATE: "2001/04/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.766,
        REACHCODE: "03050102000491",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018194279984237002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060212.0,
        ToNode: 250060203.0,
        Hydroseq: 250019185.0,
        LevelPathI: 250018071.0,
        Pathlength: 555.398,
        TerminalPa: 250002604.0,
        ArbolateSu: 232.482,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250019509.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 250018883.0,
        DnDrainCou: 2,
        DnHydroseq: 250018884.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9989,
        TotDASqKM: 231.4305,
        DivDASqKM: 231.4305,
        Tidal: 0,
        TOTMA: 0.054714524993899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24908.0,
        MAXELEVSMO: 25366.0,
        MINELEVSMO: 25127.0,
        SLOPE: 0.00135334,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.766000032424927,
        QA_MA: 135.936,
        VA_MA: 1.22526,
        QC_MA: 136.055,
        VC_MA: 1.22532,
        QE_MA: 136.617,
        VE_MA: 1.22563,
        QA_01: 235.102,
        VA_01: 1.53482,
        QC_01: 184.78,
        VC_01: 1.38716,
        QE_01: 185.878,
        VE_01: 1.38855,
        QA_02: 243.952,
        VA_02: 1.55912,
        QC_02: 204.602,
        VC_02: 1.44714,
        QE_02: 205.596,
        VE_02: 1.44796,
        QA_03: 264.05,
        VA_03: 1.61282,
        QC_03: 223.668,
        VC_03: 1.50231,
        QE_03: 229.276,
        VE_03: 1.51587,
        QA_04: 195.978,
        VA_04: 1.42183,
        QC_04: 181.904,
        VC_04: 1.37821,
        QE_04: 190.432,
        VE_04: 1.40253,
        QA_05: 149.209,
        VA_05: 1.27176,
        QC_05: 140.512,
        VC_05: 1.24115,
        QE_05: 143.979,
        VE_05: 1.25155,
        QA_06: 84.302,
        VA_06: 1.01981,
        QC_06: 105.852,
        VC_06: 1.1109,
        QE_06: 111.231,
        VE_06: 1.13078,
        QA_07: 50.088,
        VA_07: 0.84798,
        QC_07: 76.083,
        VC_07: 0.98183,
        QE_07: 82.113,
        VE_07: 1.00837,
        QA_08: 34.413,
        VA_08: 0.75047,
        QC_08: 76.628,
        VC_08: 0.98438,
        QE_08: 80.769,
        VE_08: 1.00226,
        QA_09: 30.716,
        VA_09: 0.72458,
        QC_09: 80.456,
        VC_09: 1.00212,
        QE_09: 69.651,
        VE_09: 0.94978,
        QA_10: 45.86,
        VA_10: 0.82329,
        QC_10: 116.837,
        VC_10: 1.1541,
        QE_10: 71.427,
        VE_10: 0.95841,
        QA_11: 120.089,
        VA_11: 1.16686,
        QC_11: 147.339,
        VC_11: 1.26495,
        QE_11: 115.084,
        VE_11: 1.14578,
        QA_12: 178.324,
        VA_12: 1.3674,
        QC_12: 147.715,
        VC_12: 1.26625,
        QE_12: 138.606,
        VE_12: 1.2327,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.347342184172646, 35.641250078011012, 0.0],
          [-81.34467671751014, 35.641913611343284, 0.0],
          [-81.34262678417997, 35.646539411336107, 0.0],
          [-81.336930917522068, 35.647339411334997, 0.0],
          [-81.333338317527705, 35.649559811331471, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286730.0,
        COMID: 9745250,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.178,
        REACHCODE: "03050102000492",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0019130759881870001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060203.0,
        ToNode: 250060201.0,
        Hydroseq: 250018884.0,
        LevelPathI: 250018071.0,
        Pathlength: 555.22,
        TerminalPa: 250002604.0,
        ArbolateSu: 232.66,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250019185.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 250018597.0,
        DnDrainCou: 2,
        DnHydroseq: 250018598.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.045,
        TotDASqKM: 231.4755,
        DivDASqKM: 231.4755,
        Tidal: 0,
        TOTMA: 0.0092752296195499993,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25024.0,
        MAXELEVSMO: 25127.0,
        MINELEVSMO: 25127.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.178000003099442,
        QA_MA: 135.957,
        VA_MA: 0.72856,
        QC_MA: 136.076,
        VC_MA: 0.72859,
        QE_MA: 136.637,
        VE_MA: 0.72873,
        QA_01: 235.143,
        VA_01: 0.87043,
        QC_01: 184.811,
        VC_01: 0.80276,
        QE_01: 185.909,
        VE_01: 0.8034,
        QA_02: 243.994,
        VA_02: 0.88157,
        QC_02: 204.637,
        VC_02: 0.83025,
        QE_02: 205.631,
        VE_02: 0.83063,
        QA_03: 264.097,
        VA_03: 0.90618,
        QC_03: 223.706,
        VC_03: 0.85553,
        QE_03: 229.314,
        VE_03: 0.86175,
        QA_04: 196.01,
        VA_04: 0.81865,
        QC_04: 181.933,
        VC_04: 0.79866,
        QE_04: 190.461,
        VE_04: 0.8098,
        QA_05: 149.231,
        VA_05: 0.74987,
        QC_05: 140.532,
        VC_05: 0.73585,
        QE_05: 143.999,
        VE_05: 0.74061,
        QA_06: 84.315,
        VA_06: 0.63441,
        QC_06: 105.867,
        VC_06: 0.67615,
        QE_06: 111.247,
        VE_06: 0.68527,
        QA_07: 50.096,
        VA_07: 0.55567,
        QC_07: 76.095,
        VC_07: 0.61701,
        QE_07: 82.124,
        VE_07: 0.62917,
        QA_08: 34.419,
        VA_08: 0.51099,
        QC_08: 76.64,
        VC_08: 0.61818,
        QE_08: 80.782,
        VE_08: 0.62637,
        QA_09: 30.721,
        VA_09: 0.49912,
        QC_09: 80.466,
        VC_09: 0.6263,
        QE_09: 69.661,
        VE_09: 0.60232,
        QA_10: 45.864,
        VA_10: 0.54435,
        QC_10: 116.844,
        VC_10: 0.69593,
        QE_10: 71.434,
        VE_10: 0.60627,
        QA_11: 120.101,
        VA_11: 0.70179,
        QC_11: 147.351,
        VC_11: 0.74674,
        QE_11: 115.095,
        VE_11: 0.69213,
        QA_12: 178.352,
        VA_12: 0.7937,
        QC_12: 147.736,
        VC_12: 0.74734,
        QE_12: 138.627,
        VE_12: 0.73197,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.333338317527705, 35.649559811331471, 0.0],
          [-81.331570517530452, 35.64997141133091, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283840.0,
        COMID: 9752776,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.266,
        REACHCODE: "03050101000258",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013971603669291999,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061137.0,
        ToNode: 250061136.0,
        Hydroseq: 250012336.0,
        LevelPathI: 250003258.0,
        Pathlength: 627.42,
        TerminalPa: 250002604.0,
        ArbolateSu: 1637.699,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012429.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012245.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5985,
        TotDASqKM: 1831.2831,
        DivDASqKM: 1831.2831,
        Tidal: 0,
        TOTMA: 0.035596493676999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30197.0,
        MAXELEVSMO: 30228.0,
        MINELEVSMO: 30210.0,
        SLOPE: 0.00014218,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.266000032424927,
        QA_MA: 1171.484,
        VA_MA: 1.34618,
        QC_MA: 1078.493,
        VC_MA: 1.33925,
        QE_MA: 1233.221,
        VE_MA: 1.35051,
        QA_01: 1852.646,
        VA_01: 1.63215,
        QC_01: 1379.418,
        VC_01: 1.48316,
        QE_01: 1493.479,
        VE_01: 1.46204,
        QA_02: 1964.103,
        VA_02: 1.67385,
        QC_02: 1556.245,
        VC_02: 1.56086,
        QE_02: 1614.864,
        VE_02: 1.51091,
        QA_03: 2237.095,
        VA_03: 1.77155,
        QC_03: 1765.909,
        VC_03: 1.6478,
        QE_03: 1964.892,
        VE_03: 1.64294,
        QA_04: 1696.464,
        VA_04: 1.57167,
        QC_04: 1696.464,
        VC_04: 1.61957,
        QE_04: 1863.516,
        VE_04: 1.60593,
        QA_05: 1335.633,
        VA_05: 1.42102,
        QC_05: 1335.633,
        VC_05: 1.46321,
        QE_05: 1546.346,
        VE_05: 1.48355,
        QA_06: 774.236,
        VA_06: 1.14131,
        QC_06: 774.236,
        VC_06: 1.17289,
        QE_06: 990.955,
        VE_06: 1.23624,
        QA_07: 454.724,
        VA_07: 0.93626,
        QC_07: 454.724,
        VC_07: 0.96007,
        QE_07: 566.566,
        VE_07: 0.99791,
        QA_08: 327.474,
        VA_08: 0.83582,
        QC_08: 762.836,
        VC_08: 1.16611,
        QE_08: 987.354,
        VE_08: 1.23445,
        QA_09: 337.236,
        VA_09: 0.84411,
        QC_09: 667.212,
        VC_09: 1.10723,
        QE_09: 717.35,
        VE_09: 1.08996,
        QA_10: 454.025,
        VA_10: 0.93575,
        QC_10: 729.325,
        VC_10: 1.14589,
        QE_10: 721.066,
        VE_10: 1.09211,
        QA_11: 1141.476,
        VA_11: 1.33198,
        QC_11: 820.622,
        VC_11: 1.20002,
        QE_11: 792.502,
        VE_11: 1.13242,
        QA_12: 1490.801,
        VA_12: 1.48789,
        QC_12: 1019.717,
        VC_12: 1.30903,
        QE_12: 1038.295,
        VE_12: 1.25952,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.666520317010566, 35.77797401113213, 0.0],
          [-81.652890783698354, 35.77832167779826, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283841.0,
        COMID: 9752812,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.772,
        REACHCODE: "03050101000259",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.028977186486901001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061142.0,
        ToNode: 250061137.0,
        Hydroseq: 250012429.0,
        LevelPathI: 250003258.0,
        Pathlength: 628.686,
        TerminalPa: 250002604.0,
        ArbolateSu: 1571.918,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012521.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012336.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.9465,
        TotDASqKM: 1764.2538,
        DivDASqKM: 1764.2538,
        Tidal: 0,
        TOTMA: 0.096209827669199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30209.0,
        MAXELEVSMO: 30235.0,
        MINELEVSMO: 30228.0,
        SLOPE: 2.525e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.7720000743865971,
        QA_MA: 1135.579,
        VA_MA: 1.09049,
        QC_MA: 1046.702,
        VC_MA: 1.08535,
        QE_MA: 1201.429,
        VE_MA: 1.09407,
        QA_01: 1786.278,
        VA_01: 1.30317,
        QC_01: 1331.275,
        VC_01: 1.19118,
        QE_01: 1445.336,
        VE_01: 1.17509,
        QA_02: 1893.298,
        VA_02: 1.33439,
        QC_02: 1501.645,
        VC_02: 1.24947,
        QE_02: 1560.264,
        VE_02: 1.21103,
        QA_03: 2162.754,
        VA_03: 1.4095,
        QC_03: 1709.132,
        VC_03: 1.31642,
        QE_03: 1908.115,
        VE_03: 1.31284,
        QA_04: 1642.957,
        VA_04: 1.25996,
        QC_04: 1642.957,
        VC_04: 1.29551,
        QE_04: 1810.008,
        VE_04: 1.28508,
        QA_05: 1299.057,
        VA_05: 1.14839,
        QC_05: 1299.057,
        VC_05: 1.17977,
        QE_05: 1509.77,
        VE_05: 1.1954,
        QA_06: 753.342,
        VA_06: 0.93735,
        QC_06: 753.342,
        VC_06: 0.96085,
        QE_06: 970.061,
        VE_06: 1.00971,
        QA_07: 442.379,
        VA_07: 0.78248,
        QC_07: 637.85,
        VC_07: 0.90567,
        QE_07: 749.691,
        VE_07: 0.92002,
        QA_08: 319.058,
        VA_08: 0.70695,
        QC_08: 742.844,
        VC_08: 0.956,
        QE_08: 967.362,
        VE_08: 1.00868,
        QA_09: 330.496,
        VA_09: 0.71448,
        QC_09: 655.425,
        VC_09: 0.91435,
        QE_09: 705.563,
        VE_09: 0.90063,
        QA_10: 447.831,
        VA_10: 0.78557,
        QC_10: 721.365,
        VC_10: 0.94599,
        QE_10: 713.106,
        VE_10: 0.90398,
        QA_11: 1114.12,
        VA_11: 1.08261,
        QC_11: 805.58,
        VC_11: 0.98449,
        QE_11: 777.46,
        VE_11: 0.93195,
        QA_12: 1443.252,
        VA_12: 1.19668,
        QC_12: 990.083,
        VC_12: 1.06274,
        QE_12: 1008.66,
        VE_12: 1.0244,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.691329983638695, 35.772237277807676, 0.0],
          [-81.684948316981945, 35.76873687781324, 0.0],
          [-81.679330983657337, 35.77228941114106, 0.0],
          [-81.674273316998438, 35.772749611140227, 0.0],
          [-81.666520317010566, 35.77797401113213, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283842.0,
        COMID: 9752798,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996813",
        GNIS_NAME: "Warrior Fork",
        LENGTHKM: 1.351,
        REACHCODE: "03050101000260",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013874187573418,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061139.0,
        ToNode: 250061142.0,
        Hydroseq: 250024853.0,
        LevelPathI: 250024853.0,
        Pathlength: 631.458,
        TerminalPa: 250002604.0,
        ArbolateSu: 207.254,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250024853.0,
        UpHydroseq: 250025530.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012429.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6542,
        TotDASqKM: 226.2519,
        DivDASqKM: 226.2519,
        Tidal: 0,
        TOTMA: 0.041662819236200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30234.0,
        MAXELEVSMO: 30430.0,
        MINELEVSMO: 30235.0,
        SLOPE: 0.00144337,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3509999513626101,
        QA_MA: 135.346,
        VA_MA: 1.23126,
        QC_MA: 135.488,
        VC_MA: 1.23134,
        QE_MA: 135.488,
        VE_MA: 1.23134,
        QA_01: 216.943,
        VA_01: 1.494,
        QC_01: 170.868,
        VC_01: 1.35234,
        QE_01: 170.868,
        VE_01: 1.35234,
        QA_02: 227.913,
        VA_02: 1.52546,
        QC_02: 191.505,
        VC_02: 1.4175,
        QE_02: 191.505,
        VE_02: 1.4175,
        QA_03: 265.102,
        VA_03: 1.62717,
        QC_03: 224.529,
        VC_03: 1.51527,
        QE_03: 224.529,
        VE_03: 1.51527,
        QA_04: 202.689,
        VA_04: 1.45198,
        QC_04: 187.989,
        VC_04: 1.40664,
        QE_04: 187.989,
        VE_04: 1.40664,
        QA_05: 158.896,
        VA_05: 1.31331,
        QC_05: 149.282,
        VC_05: 1.2801,
        QE_05: 149.282,
        VE_05: 1.2801,
        QA_06: 89.75,
        VA_06: 1.05039,
        QC_06: 112.595,
        VC_06: 1.14494,
        QE_06: 112.595,
        VE_06: 1.14494,
        QA_07: 52.624,
        VA_07: 0.86723,
        QC_07: 79.84,
        VC_07: 1.00537,
        QE_07: 79.84,
        VE_07: 1.00537,
        QA_08: 36.588,
        VA_08: 0.76915,
        QC_08: 81.57,
        VC_08: 1.01334,
        QE_08: 81.57,
        VE_08: 1.01334,
        QA_09: 36.838,
        VA_09: 0.77082,
        QC_09: 94.459,
        VC_09: 1.07046,
        QE_09: 94.459,
        VE_09: 1.07046,
        QA_10: 44.636,
        VA_10: 0.82045,
        QC_10: 114.338,
        VC_10: 1.1518,
        QE_10: 114.338,
        VE_10: 1.1518,
        QA_11: 124.281,
        VA_11: 1.1904,
        QC_11: 151.246,
        VC_11: 1.28686,
        QE_11: 151.246,
        VE_11: 1.28686,
        QA_12: 169.169,
        VA_12: 1.34731,
        QC_12: 140.799,
        VC_12: 1.25037,
        QE_12: 140.799,
        VE_12: 1.25037,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.70043878362452, 35.776605811134175, 0.0],
          [-81.69363818363513, 35.77665587780092, 0.0],
          [-81.693523316968651, 35.773976477804979, 0.0],
          [-81.691329983638695, 35.772237277807676, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285884.0,
        COMID: 9754044,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.265,
        REACHCODE: "03050101002559",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023305481722716001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061496.0,
        ToNode: 250061484.0,
        Hydroseq: 250010461.0,
        LevelPathI: 250003258.0,
        Pathlength: 592.085,
        TerminalPa: 250002604.0,
        ArbolateSu: 2765.122,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010527.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010408.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.9264,
        TotDASqKM: 3035.5389,
        DivDASqKM: 3035.5389,
        Tidal: 0,
        TOTMA: 0.96590417237000004,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28342.0,
        MAXELEVSMO: 28350.0,
        MINELEVSMO: 28344.0,
        SLOPE: 2.647e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2660000324249272,
        QA_MA: 1859.899,
        VA_MA: -9998.0,
        QC_MA: 1859.899,
        VC_MA: -9998.0,
        QE_MA: 2054.942,
        VE_MA: -9998.0,
        QA_01: 2979.463,
        VA_01: -9998.0,
        QC_01: 2979.463,
        VC_01: -9998.0,
        QE_01: 3184.737,
        VE_01: -9998.0,
        QA_02: 3157.389,
        VA_02: -9998.0,
        QC_02: 3157.389,
        VC_02: -9998.0,
        QE_02: 3229.212,
        VE_02: -9998.0,
        QA_03: 3580.739,
        VA_03: -9998.0,
        QC_03: 3580.739,
        VC_03: -9998.0,
        QE_03: 3844.859,
        VE_03: -9998.0,
        QA_04: 2741.339,
        VA_04: -9998.0,
        QC_04: 2741.339,
        VC_04: -9998.0,
        QE_04: 2933.465,
        VE_04: -9998.0,
        QA_05: 2104.888,
        VA_05: -9998.0,
        QC_05: 2104.888,
        VC_05: -9998.0,
        QE_05: 2327.01,
        VE_05: -9998.0,
        QA_06: 1226.409,
        VA_06: -9998.0,
        QC_06: 1226.409,
        VC_06: -9998.0,
        QE_06: 1486.022,
        VE_06: -9998.0,
        QA_07: 726.525,
        VA_07: -9998.0,
        QC_07: 726.525,
        VC_07: -9998.0,
        QE_07: 873.419,
        VE_07: -9998.0,
        QA_08: 515.558,
        VA_08: -9998.0,
        QC_08: 515.558,
        VC_08: -9998.0,
        QE_08: 815.174,
        VE_08: -9998.0,
        QA_09: 514.945,
        VA_09: -9998.0,
        QC_09: 969.535,
        VC_09: -9998.0,
        QE_09: 1006.041,
        VE_09: -9998.0,
        QA_10: 666.742,
        VA_10: -9998.0,
        QC_10: 991.351,
        VC_10: -9998.0,
        QE_10: 951.848,
        VE_10: -9998.0,
        QA_11: 1758.486,
        VA_11: -9998.0,
        QC_11: 1758.486,
        VC_11: -9998.0,
        QE_11: 1735.687,
        VE_11: -9998.0,
        QA_12: 2358.628,
        VA_12: -9998.0,
        QC_12: 2358.628,
        VC_12: -9998.0,
        QE_12: 2382.34,
        VE_12: -9998.0,
        LakeFract: 0.09031790671000001,
        SurfArea: 1408055.2466,
        RAreaHLoad: 0.27940879321000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.350336917501352, 35.786329811119181, 0.0],
          [-81.346746184173526, 35.793475677774722, 0.0],
          [-81.343687384178281, 35.795264611105267, 0.0],
          [-81.332252384196067, 35.796553411103332, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283820.0,
        COMID: 9752028,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 1.219,
        REACHCODE: "03050101000239",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011732797171232,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060938.0,
        ToNode: 250060970.0,
        Hydroseq: 250024233.0,
        LevelPathI: 250024233.0,
        Pathlength: 648.503,
        TerminalPa: 250002604.0,
        ArbolateSu: 182.682,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250024857.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017821.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8082,
        TotDASqKM: 178.4601,
        DivDASqKM: 178.4601,
        Tidal: 0,
        TOTMA: 0.035150815236799998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31537.0,
        MAXELEVSMO: 31941.0,
        MINELEVSMO: 31544.0,
        SLOPE: 0.00325676,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.218999981880188,
        QA_MA: 126.157,
        VA_MA: 1.31655,
        QC_MA: 126.634,
        VC_MA: 1.31686,
        QE_MA: 126.634,
        VE_MA: 1.31686,
        QA_01: 172.322,
        VA_01: 1.4981,
        QC_01: 136.545,
        VC_01: 1.35803,
        QE_01: 136.545,
        VE_01: 1.35803,
        QA_02: 182.251,
        VA_02: 1.53402,
        QC_02: 154.074,
        VC_02: 1.42755,
        QE_02: 154.074,
        VE_02: 1.42755,
        QA_03: 223.505,
        VA_03: 1.67428,
        QC_03: 190.368,
        VC_03: 1.56058,
        QE_03: 190.368,
        VE_03: 1.56058,
        QA_04: 184.921,
        VA_04: 1.54353,
        QC_04: 171.868,
        VC_04: 1.49442,
        QE_04: 171.868,
        VE_04: 1.49442,
        QA_05: 146.228,
        VA_05: 1.39877,
        QC_05: 137.808,
        VC_05: 1.36317,
        QE_05: 137.808,
        VE_05: 1.36317,
        QA_06: 94.33,
        VA_06: 1.17233,
        QC_06: 118.26,
        VC_06: 1.28088,
        QE_06: 118.26,
        VE_06: 1.28088,
        QA_07: 56.592,
        VA_07: 0.96704,
        QC_07: 85.713,
        VC_07: 1.12809,
        QE_07: 85.713,
        VE_07: 1.12809,
        QA_08: 42.775,
        VA_08: 0.87607,
        QC_08: 95.662,
        VC_08: 1.17731,
        QE_08: 95.662,
        VE_08: 1.17731,
        QA_09: 52.726,
        VA_09: 0.94275,
        QC_09: 129.639,
        VC_09: 1.3295,
        QE_09: 129.639,
        VE_09: 1.3295,
        QA_10: 74.802,
        VA_10: 1.07222,
        QC_10: 172.706,
        VC_10: 1.49749,
        QE_10: 172.706,
        VE_10: 1.49749,
        QA_11: 135.982,
        VA_11: 1.35751,
        QC_11: 161.989,
        VC_11: 1.45772,
        QE_11: 161.989,
        VE_11: 1.45772,
        QA_12: 148.212,
        VA_12: 1.4066,
        QC_12: 124.836,
        VC_12: 1.30923,
        QE_12: 124.836,
        VE_12: 1.30923,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.714845916935474, 35.888488610960565, 0.0],
          [-81.712031916939907, 35.888559410960568, 0.0],
          [-81.711153583607882, 35.883361610968564, 0.0],
          [-81.709012983611217, 35.881622810971237, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283821.0,
        COMID: 9751996,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 0.494,
        REACHCODE: "03050101000240",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0052733630183910002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060930.0,
        ToNode: 250060938.0,
        Hydroseq: 250024857.0,
        LevelPathI: 250024233.0,
        Pathlength: 649.722,
        TerminalPa: 250002604.0,
        ArbolateSu: 180.792,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250025534.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024233.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1116,
        TotDASqKM: 177.372,
        DivDASqKM: 177.372,
        Tidal: 0,
        TOTMA: 0.015780158840199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31855.0,
        MAXELEVSMO: 32010.0,
        MINELEVSMO: 31941.0,
        SLOPE: 0.00139676,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.49399998784065202,
        QA_MA: 125.586,
        VA_MA: 1.18846,
        QC_MA: 126.083,
        VC_MA: 1.18874,
        QE_MA: 126.083,
        VE_MA: 1.18874,
        QA_01: 171.327,
        VA_01: 1.34625,
        QC_01: 135.777,
        VC_01: 1.22406,
        QE_01: 135.777,
        VE_01: 1.22406,
        QA_02: 181.198,
        VA_02: 1.37759,
        QC_02: 153.207,
        VC_02: 1.2847,
        QE_02: 153.207,
        VE_02: 1.2847,
        QA_03: 222.335,
        VA_03: 1.50029,
        QC_03: 189.404,
        VC_03: 1.40109,
        QE_03: 189.404,
        VE_03: 1.40109,
        QA_04: 184.031,
        VA_04: 1.38643,
        QC_04: 171.06,
        VC_04: 1.34355,
        QE_04: 171.06,
        VE_04: 1.34355,
        QA_05: 145.584,
        VA_05: 1.26029,
        QC_05: 137.224,
        VC_05: 1.22923,
        QE_05: 137.224,
        VE_05: 1.22923,
        QA_06: 93.964,
        VA_06: 1.06282,
        QC_06: 117.807,
        VC_06: 1.15757,
        QE_06: 117.807,
        VE_06: 1.15757,
        QA_07: 56.373,
        VA_07: 0.88356,
        QC_07: 85.388,
        VC_07: 1.02417,
        QE_07: 85.388,
        VE_07: 1.02417,
        QA_08: 42.628,
        VA_08: 0.80423,
        QC_08: 95.329,
        VC_08: 1.06727,
        QE_08: 95.329,
        VE_08: 1.06727,
        QA_09: 52.609,
        VA_09: 0.86284,
        QC_09: 129.385,
        VC_09: 1.20091,
        QE_09: 129.385,
        VE_09: 1.20091,
        QA_10: 74.714,
        VA_10: 0.97635,
        QC_10: 172.544,
        VC_10: 1.34831,
        QE_10: 172.544,
        VE_10: 1.34831,
        QA_11: 135.54,
        VA_11: 1.22484,
        QC_11: 161.587,
        VC_11: 1.31271,
        QE_11: 161.587,
        VE_11: 1.31271,
        QA_12: 147.481,
        VA_12: 1.26686,
        QC_12: 124.276,
        VC_12: 1.18202,
        QE_12: 124.276,
        VE_12: 1.18202,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.719602383594861, 35.88887407762661, 0.0],
          [-81.714845916935474, 35.888488610960565, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285879.0,
        COMID: 9754020,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.437,
        REACHCODE: "03050101002554",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.036537204363151998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061484.0,
        ToNode: 250061480.0,
        Hydroseq: 250010408.0,
        LevelPathI: 250003258.0,
        Pathlength: 588.648,
        TerminalPa: 250002604.0,
        ArbolateSu: 2876.695,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010461.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010352.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.9759,
        TotDASqKM: 3164.6592,
        DivDASqKM: 3164.6592,
        Tidal: 0,
        TOTMA: 1.46570094499,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28337.0,
        MAXELEVSMO: 28344.0,
        MINELEVSMO: 28341.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.437000036239624,
        QA_MA: 1930.214,
        VA_MA: -9998.0,
        QC_MA: 1930.214,
        VC_MA: -9998.0,
        QE_MA: 2125.257,
        VE_MA: -9998.0,
        QA_01: 3101.848,
        VA_01: -9998.0,
        QC_01: 3101.848,
        VC_01: -9998.0,
        QE_01: 3307.123,
        VE_01: -9998.0,
        QA_02: 3285.822,
        VA_02: -9998.0,
        QC_02: 3285.822,
        VC_02: -9998.0,
        QE_02: 3357.645,
        VE_02: -9998.0,
        QA_03: 3720.793,
        VA_03: -9998.0,
        QC_03: 3720.793,
        VC_03: -9998.0,
        QE_03: 3984.913,
        VE_03: -9998.0,
        QA_04: 2848.382,
        VA_04: -9998.0,
        QC_04: 2848.382,
        VC_04: -9998.0,
        QE_04: 3040.508,
        VE_04: -9998.0,
        QA_05: 2182.658,
        VA_05: -9998.0,
        QC_05: 2182.658,
        VC_05: -9998.0,
        QE_05: 2404.78,
        VE_05: -9998.0,
        QA_06: 1270.296,
        VA_06: -9998.0,
        QC_06: 1270.296,
        VC_06: -9998.0,
        QE_06: 1529.909,
        VE_06: -9998.0,
        QA_07: 752.725,
        VA_07: -9998.0,
        QC_07: 752.725,
        VC_07: -9998.0,
        QE_07: 899.619,
        VE_07: -9998.0,
        QA_08: 533.013,
        VA_08: -9998.0,
        QC_08: 533.013,
        VC_08: -9998.0,
        QE_08: 832.629,
        VE_08: -9998.0,
        QA_09: 528.701,
        VA_09: -9998.0,
        QC_09: 992.366,
        VC_09: -9998.0,
        QE_09: 1028.872,
        VE_09: -9998.0,
        QA_10: 681.963,
        VA_10: -9998.0,
        QC_10: 1009.388,
        VC_10: -9998.0,
        QE_10: 969.885,
        VE_10: -9998.0,
        QA_11: 1817.446,
        VA_11: -9998.0,
        QC_11: 1817.446,
        VC_11: -9998.0,
        QE_11: 1794.646,
        VE_11: -9998.0,
        QA_12: 2451.663,
        VA_12: -9998.0,
        QC_12: 2451.663,
        VC_12: -9998.0,
        QE_12: 2475.376,
        VE_12: -9998.0,
        LakeFract: 0.14173050877000001,
        SurfArea: 2209577.1895699999,
        RAreaHLoad: 0.42398588179000002,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.332252384196067, 35.796553411103332, 0.0],
          [-81.316287317554156, 35.796734411103046, 0.0],
          [-81.307423717567872, 35.803086811093237, 0.0],
          [-81.302474784242179, 35.801520611095668, 0.0],
          [-81.299751584246451, 35.798536877766992, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283822.0,
        COMID: 9751974,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 1.191,
        REACHCODE: "03050101000241",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011744058008470001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060925.0,
        ToNode: 250060930.0,
        Hydroseq: 250025534.0,
        LevelPathI: 250024233.0,
        Pathlength: 650.216,
        TerminalPa: 250002604.0,
        ArbolateSu: 180.298,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250026276.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 250024856.0,
        DnDrainCou: 2,
        DnHydroseq: 250024857.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7884,
        TotDASqKM: 177.2604,
        DivDASqKM: 177.2604,
        Tidal: 0,
        TOTMA: 0.035299578888500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32010.0,
        MAXELEVSMO: 32322.0,
        MINELEVSMO: 32010.0,
        SLOPE: 0.00261964,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1909999847412109,
        QA_MA: 125.527,
        VA_MA: 1.28088,
        QC_MA: 126.026,
        VC_MA: 1.28119,
        QE_MA: 126.026,
        VE_MA: 1.28119,
        QA_01: 171.225,
        VA_01: 1.45517,
        QC_01: 135.698,
        VC_01: 1.32015,
        QE_01: 135.698,
        VE_01: 1.32015,
        QA_02: 181.09,
        VA_02: 1.48979,
        QC_02: 153.119,
        VC_02: 1.38716,
        QE_02: 153.119,
        VE_02: 1.38716,
        QA_03: 222.215,
        VA_03: 1.62541,
        QC_03: 189.305,
        VC_03: 1.5158,
        QE_03: 189.305,
        VE_03: 1.5158,
        QA_04: 183.94,
        VA_04: 1.49963,
        QC_04: 170.976,
        VC_04: 1.45224,
        QE_04: 170.976,
        VE_04: 1.45224,
        QA_05: 145.518,
        VA_05: 1.36027,
        QC_05: 137.164,
        VC_05: 1.32594,
        QE_05: 137.164,
        VE_05: 1.32594,
        QA_06: 93.927,
        VA_06: 1.14209,
        QC_06: 117.761,
        VC_06: 1.24678,
        QE_06: 117.761,
        VE_06: 1.24678,
        QA_07: 56.35,
        VA_07: 0.94398,
        QC_07: 85.355,
        VC_07: 1.09937,
        QE_07: 85.355,
        VE_07: 1.09937,
        QA_08: 42.613,
        VA_08: 0.85634,
        QC_08: 95.294,
        VC_08: 1.14701,
        QE_08: 95.294,
        VE_08: 1.14701,
        QA_09: 52.597,
        VA_09: 0.92114,
        QC_09: 129.359,
        VC_09: 1.29477,
        QE_09: 129.359,
        VE_09: 1.29477,
        QA_10: 74.705,
        VA_10: 1.04664,
        QC_10: 172.527,
        VC_10: 1.45774,
        QE_10: 172.527,
        VE_10: 1.45774,
        QA_11: 135.493,
        VA_11: 1.32114,
        QC_11: 161.545,
        VC_11: 1.41829,
        QE_11: 161.545,
        VE_11: 1.41829,
        QA_12: 147.406,
        VA_12: 1.36749,
        QC_12: 124.218,
        VC_12: 1.27376,
        QE_12: 124.218,
        VE_12: 1.27376,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.715672116934229, 35.89682367761435, 0.0],
          [-81.71411978360328, 35.893160810953304, 0.0],
          [-81.719602383594861, 35.88887407762661, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286907.0,
        COMID: 9733376,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.389,
        REACHCODE: "03050103000194",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014765036375430999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059034.0,
        ToNode: 250059035.0,
        Hydroseq: 250027088.0,
        LevelPathI: 250018600.0,
        Pathlength: 431.266,
        TerminalPa: 250002604.0,
        ArbolateSu: 154.084,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250027955.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026290.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5579,
        TotDASqKM: 198.9756,
        DivDASqKM: 198.9756,
        Tidal: 0,
        TOTMA: 0.052020970442100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14940.0,
        MAXELEVSMO: 15055.0,
        MINELEVSMO: 15000.0,
        SLOPE: 0.00039596,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3890000581741331,
        QA_MA: 83.033,
        VA_MA: 1.01689,
        QC_MA: 84.71,
        VC_MA: 1.01804,
        QE_MA: 78.807,
        VE_MA: 1.0139,
        QA_01: 179.214,
        VA_01: 1.37439,
        QC_01: 141.86,
        VC_01: 1.24153,
        QE_01: 151.476,
        VE_01: 1.30652,
        QA_02: 184.933,
        VA_02: 1.39232,
        QC_02: 156.278,
        VC_02: 1.29074,
        QE_02: 163.113,
        VE_02: 1.34653,
        QA_03: 197.603,
        VA_03: 1.43114,
        QC_03: 168.992,
        VC_03: 1.3324,
        QE_03: 166.731,
        VE_03: 1.3587,
        QA_04: 114.615,
        VA_04: 1.14915,
        QC_04: 107.689,
        VC_04: 1.1145,
        QE_04: 94.035,
        VE_04: 1.08328,
        QA_05: 64.185,
        VA_05: 0.92635,
        QC_05: 62.382,
        VC_05: 0.91162,
        QE_05: 49.631,
        VE_05: 0.8603,
        QA_06: 40.129,
        VA_06: 0.78995,
        QC_06: 50.903,
        VC_06: 0.84988,
        QE_06: 34.312,
        VE_06: 0.76206,
        QA_07: 29.428,
        VA_07: 0.71683,
        QC_07: 45.273,
        VC_07: 0.8172,
        QE_07: 40.45,
        VE_07: 0.80349,
        QA_08: 21.402,
        VA_08: 0.65328,
        QC_08: 47.205,
        VC_08: 0.82862,
        QE_08: 45.786,
        VE_08: 0.83717,
        QA_09: 18.727,
        VA_09: 0.62968,
        QC_09: 51.978,
        VC_09: 0.85592,
        QE_09: 33.479,
        VE_09: 0.75618,
        QA_10: 13.822,
        VA_10: 0.58183,
        QC_10: 44.823,
        VC_10: 0.81451,
        QE_10: 55.621,
        VE_10: 0.89472,
        QA_11: 29.729,
        VA_11: 0.71904,
        QC_11: 50.807,
        VC_11: 0.84933,
        QE_11: 43.035,
        VE_11: 0.82005,
        QA_12: 103.205,
        VA_12: 1.10362,
        QC_12: 89.811,
        VC_12: 1.04042,
        QE_12: 72.015,
        VE_12: 0.98092,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.753558718427655, 34.952618879079978, 0.0],
          [-80.767084718406636, 34.948119212420238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288998.0,
        COMID: 9715133,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.41,
        REACHCODE: "03050104000207",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0043077008096219999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057334.0,
        ToNode: 250058233.0,
        Hydroseq: 250019868.0,
        LevelPathI: 250018611.0,
        Pathlength: 357.25,
        TerminalPa: 250002604.0,
        ArbolateSu: 118.762,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250020214.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019523.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1989,
        TotDASqKM: 97.2162,
        DivDASqKM: 97.2162,
        Tidal: 0,
        TOTMA: 0.024288679683200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6790.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40999999642372098,
        QA_MA: 35.399,
        VA_MA: 0.63956,
        QC_MA: 37.329,
        VC_MA: 0.64099,
        QE_MA: 37.329,
        VE_MA: 0.64099,
        QA_01: 78.751,
        VA_01: 0.81473,
        QC_01: 63.695,
        VC_01: 0.7501,
        QE_01: 63.695,
        VE_01: 0.7501,
        QA_02: 84.815,
        VA_02: 0.83508,
        QC_02: 73.213,
        VC_02: 0.784,
        QE_02: 73.213,
        VE_02: 0.784,
        QA_03: 91.76,
        VA_03: 0.85756,
        QC_03: 80.487,
        VC_03: 0.80854,
        QE_03: 80.487,
        VE_03: 0.80854,
        QA_04: 49.951,
        VA_04: 0.706,
        QC_04: 47.827,
        VC_04: 0.68776,
        QE_04: 47.827,
        VE_04: 0.68776,
        QA_05: 28.036,
        VA_05: 0.60099,
        QC_05: 28.105,
        VC_05: 0.59445,
        QE_05: 28.105,
        VE_05: 0.59445,
        QA_06: 18.299,
        VA_06: 0.54167,
        QC_06: 23.464,
        VC_06: 0.56831,
        QE_06: 23.464,
        VE_06: 0.56831,
        QA_07: 13.071,
        VA_07: 0.50351,
        QC_07: 20.503,
        VC_07: 0.55034,
        QE_07: 20.503,
        VE_07: 0.55034,
        QA_08: 10.457,
        VA_08: 0.48171,
        QC_08: 22.735,
        VC_08: 0.56399,
        QE_08: 22.735,
        VE_08: 0.56399,
        QA_09: 7.843,
        VA_09: 0.45716,
        QC_09: 24.103,
        VC_09: 0.57205,
        QE_09: 24.103,
        VE_09: 0.57205,
        QA_10: 6.535,
        VA_10: 0.44342,
        QC_10: 24.64,
        VC_10: 0.57515,
        QE_10: 24.64,
        VE_10: 0.57515,
        QA_11: 5.228,
        VA_11: 0.42833,
        QC_11: 13.498,
        VC_11: 0.50218,
        QE_11: 13.498,
        VE_11: 0.50218,
        QA_12: 30.336,
        VA_12: 0.6135,
        QC_12: 29.48,
        VC_12: 0.6018,
        QE_12: 29.48,
        VE_12: 0.6018,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.894813584875067, 34.405401279929265, 0.0],
          [-80.890944584881083, 34.406638879927414, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286908.0,
        COMID: 9736030,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 0.643,
        REACHCODE: "03050103000195",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0062619613157259999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059815.0,
        ToNode: 250059034.0,
        Hydroseq: 250027955.0,
        LevelPathI: 250018600.0,
        Pathlength: 432.655,
        TerminalPa: 250002604.0,
        ArbolateSu: 113.448,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250028924.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027088.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1683,
        TotDASqKM: 139.0446,
        DivDASqKM: 139.0446,
        Tidal: 0,
        TOTMA: 0.021268117407699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15025.0,
        MAXELEVSMO: 15178.0,
        MINELEVSMO: 15055.0,
        SLOPE: 0.0019129,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.64300000667571999,
        QA_MA: 58.084,
        VA_MA: 1.14665,
        QC_MA: 60.085,
        VC_MA: 1.14895,
        QE_MA: 59.275,
        VE_MA: 1.14803,
        QA_01: 125.575,
        VA_01: 1.57074,
        QC_01: 100.332,
        VC_01: 1.41198,
        QE_01: 101.651,
        VE_01: 1.42647,
        QA_02: 129.379,
        VA_02: 1.59091,
        QC_02: 110.402,
        VC_02: 1.46948,
        QE_02: 111.339,
        VE_02: 1.48184,
        QA_03: 138.086,
        VA_03: 1.63604,
        QC_03: 119.498,
        VC_03: 1.51933,
        QE_03: 119.188,
        VE_03: 1.52507,
        QA_04: 79.714,
        VA_04: 1.30009,
        QC_04: 75.518,
        VC_04: 1.25744,
        QE_04: 73.645,
        VE_04: 1.25061,
        QA_05: 44.783,
        VA_05: 1.03853,
        QC_05: 44.115,
        VC_05: 1.02177,
        QE_05: 42.366,
        VE_05: 1.01089,
        QA_06: 28.042,
        VA_06: 0.87783,
        QC_06: 35.747,
        VC_06: 0.94638,
        QE_06: 33.471,
        VE_06: 0.92825,
        QA_07: 20.564,
        VA_07: 0.79136,
        QC_07: 31.909,
        VC_07: 0.90904,
        QE_07: 31.248,
        VE_07: 0.90604,
        QA_08: 14.956,
        VA_08: 0.71623,
        QC_08: 32.751,
        VC_08: 0.9174,
        QE_08: 32.557,
        VE_08: 0.9192,
        QA_09: 13.086,
        VA_09: 0.6883,
        QC_09: 37.879,
        VC_09: 0.96631,
        QE_09: 35.342,
        VE_09: 0.94641,
        QA_10: 9.793,
        VA_10: 0.63411,
        QC_10: 34.037,
        VC_10: 0.92999,
        QE_10: 35.518,
        VE_10: 0.9481,
        QA_11: 21.12,
        VA_11: 0.79825,
        QC_11: 39.146,
        VC_11: 0.9779,
        QE_11: 38.08,
        VE_11: 0.9722,
        QA_12: 72.307,
        VA_12: 1.25004,
        QC_12: 64.975,
        VC_12: 1.18461,
        QE_12: 62.533,
        VE_12: 1.17223,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.74957578510049, 34.948219079086812, 0.0],
          [-80.753558718427655, 34.952618879079978, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286909.0,
        COMID: 9733372,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 2.426,
        REACHCODE: "03050103000196",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.02449791520008,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059032.0,
        ToNode: 250059815.0,
        Hydroseq: 250028924.0,
        LevelPathI: 250018600.0,
        Pathlength: 433.298,
        TerminalPa: 250002604.0,
        ArbolateSu: 97.627,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250029972.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027955.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8828,
        TotDASqKM: 121.3587,
        DivDASqKM: 121.3587,
        Tidal: 0,
        TOTMA: 0.088034305925600007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15137.0,
        MAXELEVSMO: 15428.0,
        MINELEVSMO: 15178.0,
        SLOPE: 0.0010305,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.4260001182556148,
        QA_MA: 50.829,
        VA_MA: 1.04491,
        QC_MA: 52.853,
        VC_MA: 1.04724,
        QE_MA: 52.146,
        VE_MA: 1.04643,
        QA_01: 109.846,
        VA_01: 1.41729,
        QC_01: 88.073,
        VC_01: 1.27737,
        QE_01: 89.225,
        VE_01: 1.29001,
        QA_02: 112.979,
        VA_02: 1.43397,
        QC_02: 96.764,
        VC_02: 1.32703,
        QE_02: 97.583,
        VE_02: 1.33781,
        QA_03: 120.726,
        VA_03: 1.47432,
        QC_03: 104.939,
        VC_03: 1.37185,
        QE_03: 104.669,
        VE_03: 1.37685,
        QA_04: 69.65,
        VA_04: 1.17901,
        QC_04: 66.186,
        VC_04: 1.14098,
        QE_04: 64.551,
        VE_04: 1.13499,
        QA_05: 39.1,
        VA_05: 0.94913,
        QC_05: 38.712,
        VC_05: 0.93464,
        QE_05: 37.186,
        VE_05: 0.92514,
        QA_06: 24.475,
        VA_06: 0.80802,
        QC_06: 31.258,
        VC_06: 0.86741,
        QE_06: 29.272,
        VE_06: 0.85154,
        QA_07: 17.949,
        VA_07: 0.73216,
        QC_07: 27.941,
        VC_07: 0.83508,
        QE_07: 27.364,
        VE_07: 0.83245,
        QA_08: 13.054,
        VA_08: 0.66623,
        QC_08: 28.508,
        VC_08: 0.84073,
        QE_08: 28.338,
        VE_08: 0.84227,
        QA_09: 11.422,
        VA_09: 0.64174,
        QC_09: 33.592,
        VC_09: 0.8892,
        QE_09: 31.377,
        VE_09: 0.87194,
        QA_10: 8.604,
        VA_10: 0.59518,
        QC_10: 30.694,
        VC_10: 0.86203,
        QE_10: 31.987,
        VE_10: 0.87774,
        QA_11: 18.99,
        VA_11: 0.74504,
        QC_11: 36.098,
        VC_11: 0.91182,
        QE_11: 35.167,
        VE_11: 0.90712,
        QA_12: 63.48,
        VA_12: 1.13716,
        QC_12: 57.716,
        VC_12: 1.08259,
        QE_12: 55.585,
        VE_12: 1.07189,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.734653585123681, 34.9599836124018, 0.0],
          [-80.737655518452371, 34.957896679071673, 0.0],
          [-80.74082778511405, 34.958466279070819, 0.0],
          [-80.745411185106832, 34.953674679078347, 0.0],
          [-80.743712318442931, 34.952302012413725, 0.0],
          [-80.744767585107923, 34.951041212415646, 0.0],
          [-80.74957578510049, 34.948219079086812, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283827.0,
        COMID: 9751612,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 3.629,
        REACHCODE: "03050101000246",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.035723324549633999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060821.0,
        ToNode: 250061398.0,
        Hydroseq: 250029951.0,
        LevelPathI: 250024233.0,
        Pathlength: 659.098,
        TerminalPa: 250002604.0,
        ArbolateSu: 155.473,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250031115.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028896.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5666,
        TotDASqKM: 148.9869,
        DivDASqKM: 148.9869,
        Tidal: 0,
        TOTMA: 0.10575896406099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 42250.0,
        MAXELEVSMO: 43987.0,
        MINELEVSMO: 42607.0,
        SLOPE: 0.0038027,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.6289999485015869,
        QA_MA: 108.645,
        VA_MA: 1.30222,
        QC_MA: 109.69,
        VC_MA: 1.30299,
        QE_MA: 109.69,
        VE_MA: 1.30299,
        QA_01: 143.937,
        VA_01: 1.46232,
        QC_01: 114.593,
        VC_01: 1.32636,
        QE_01: 114.593,
        VE_01: 1.32636,
        QA_02: 153.342,
        VA_02: 1.50177,
        QC_02: 130.246,
        VC_02: 1.39797,
        QE_02: 130.246,
        VE_02: 1.39797,
        QA_03: 190.133,
        VA_03: 1.64613,
        QC_03: 162.811,
        VC_03: 1.53505,
        QE_03: 162.811,
        VE_03: 1.53505,
        QA_04: 158.483,
        VA_04: 1.52285,
        QC_04: 147.814,
        VC_04: 1.4737,
        QE_04: 147.814,
        VE_04: 1.4737,
        QA_05: 125.475,
        VA_05: 1.38121,
        QC_05: 118.929,
        VC_05: 1.34663,
        QE_05: 118.929,
        VE_05: 1.34663,
        QA_06: 82.986,
        VA_06: 1.16975,
        QC_06: 104.221,
        VC_06: 1.27635,
        QE_06: 104.221,
        VE_06: 1.27635,
        QA_07: 49.858,
        VA_07: 0.96557,
        QC_07: 75.742,
        VC_07: 1.12544,
        QE_07: 75.742,
        VE_07: 1.12544,
        QA_08: 38.439,
        VA_08: 0.8808,
        QC_08: 85.783,
        VC_08: 1.18128,
        QE_08: 85.783,
        VE_08: 1.18128,
        QA_09: 48.963,
        VA_09: 0.95928,
        QC_09: 121.434,
        VC_09: 1.35819,
        QE_09: 121.434,
        VE_09: 1.35819,
        QA_10: 68.614,
        VA_10: 1.08701,
        QC_10: 161.196,
        VC_10: 1.52858,
        QE_10: 161.196,
        VE_10: 1.52858,
        QA_11: 118.618,
        VA_11: 1.34967,
        QC_11: 145.961,
        VC_11: 1.46592,
        QE_11: 145.961,
        VE_11: 1.46592,
        QA_12: 125.678,
        VA_12: 1.38213,
        QC_12: 107.442,
        VC_12: 1.29212,
        QE_12: 107.442,
        VE_12: 1.29212,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.765590983523452, 35.971053877499116, 0.0],
          [-81.763311916860232, 35.96899101083568, 0.0],
          [-81.757818983535515, 35.968780010835985, 0.0],
          [-81.760418383531487, 35.963080077511563, 0.0],
          [-81.759973583532144, 35.958705877518241, 0.0],
          [-81.76290558352764, 35.956670477521413, 0.0],
          [-81.756070783538235, 35.950023210865027, 0.0],
          [-81.756805783536947, 35.948100210868063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286910.0,
        COMID: 9733326,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 1.909,
        REACHCODE: "03050103000197",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.019136112183269001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059018.0,
        ToNode: 250059032.0,
        Hydroseq: 250029972.0,
        LevelPathI: 250018600.0,
        Pathlength: 435.724,
        TerminalPa: 250002604.0,
        ArbolateSu: 91.558,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250031138.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028924.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9431,
        TotDASqKM: 116.1909,
        DivDASqKM: 116.1909,
        Tidal: 0,
        TOTMA: 0.077177626539300007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15267.0,
        MAXELEVSMO: 15506.0,
        MINELEVSMO: 15428.0,
        SLOPE: 0.00040859,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.909000039100647,
        QA_MA: 48.7,
        VA_MA: 0.93787,
        QC_MA: 50.723,
        VC_MA: 0.93995,
        QE_MA: 50.047,
        VE_MA: 0.93926,
        QA_01: 105.245,
        VA_01: 1.25615,
        QC_01: 84.479,
        VC_01: 1.13644,
        QE_01: 85.581,
        VE_01: 1.14721,
        QA_02: 108.185,
        VA_02: 1.27011,
        QC_02: 92.768,
        VC_02: 1.17864,
        QE_02: 93.551,
        VE_02: 1.18782,
        QA_03: 115.638,
        VA_03: 1.30474,
        QC_03: 100.66,
        VC_03: 1.21719,
        QE_03: 100.401,
        VE_03: 1.22145,
        QA_04: 66.663,
        VA_04: 1.05208,
        QC_04: 63.411,
        VC_04: 1.01944,
        QE_04: 61.846,
        VE_04: 1.01432,
        QA_05: 37.432,
        VA_05: 0.85578,
        QC_05: 37.122,
        VC_05: 0.84348,
        QE_05: 35.66,
        VE_05: 0.83535,
        QA_06: 23.433,
        VA_06: 0.73525,
        QC_06: 29.945,
        VC_06: 0.78579,
        QE_06: 28.043,
        VE_06: 0.77223,
        QA_07: 17.184,
        VA_07: 0.67043,
        QC_07: 26.78,
        VC_07: 0.75829,
        QE_07: 26.227,
        VE_07: 0.75603,
        QA_08: 12.498,
        VA_08: 0.61411,
        QC_08: 27.27,
        VC_08: 0.76264,
        QE_08: 27.107,
        VE_08: 0.76394,
        QA_09: 10.935,
        VA_09: 0.59318,
        QC_09: 32.326,
        VC_09: 0.80558,
        QE_09: 30.206,
        VE_09: 0.7909,
        QA_10: 8.257,
        VA_10: 0.55371,
        QC_10: 29.7,
        VC_10: 0.78371,
        QE_10: 30.937,
        VE_10: 0.79706,
        QA_11: 18.343,
        VA_11: 0.6832,
        QC_11: 35.155,
        VC_11: 0.82823,
        QE_11: 34.264,
        VE_11: 0.8243,
        QA_12: 60.881,
        VA_12: 1.0171,
        QC_12: 55.562,
        VC_12: 0.97126,
        QE_12: 53.522,
        VE_12: 0.96216,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.721667585143791, 34.965468812393283, 0.0],
          [-80.72372411847391, 34.963543212396303, 0.0],
          [-80.727565918468031, 34.965372479060136, 0.0],
          [-80.727535785134705, 34.963494279063013, 0.0],
          [-80.730874385129539, 34.963491412396479, 0.0],
          [-80.734653585123681, 34.9599836124018, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284844.0,
        COMID: 9751716,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.195,
        REACHCODE: "03050101001171",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001910416400472,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060849.0,
        ToNode: 250060858.0,
        Hydroseq: 250019507.0,
        LevelPathI: 250019507.0,
        Pathlength: 660.097,
        TerminalPa: 250002604.0,
        ArbolateSu: 176.071,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250019850.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019184.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0306,
        TotDASqKM: 165.3219,
        DivDASqKM: 0.0306,
        Tidal: 0,
        TOTMA: 0.0167940698544,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33442.0,
        MAXELEVSMO: 33456.0,
        MINELEVSMO: 33442.0,
        SLOPE: 0.00311111,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.045000001788139003,
        QA_MA: 0.015,
        VA_MA: 0.44091,
        QC_MA: 0.015,
        VC_MA: 0.44091,
        QE_MA: 0.015,
        VE_MA: 0.44091,
        QA_01: 0.026,
        VA_01: 0.48587,
        QC_01: 0.026,
        VC_01: 0.48587,
        QE_01: 0.026,
        VE_01: 0.48587,
        QA_02: 0.028,
        VA_02: 0.49299,
        QC_02: 0.028,
        VC_02: 0.49299,
        QE_02: 0.028,
        VE_02: 0.49299,
        QA_03: 0.032,
        VA_03: 0.50655,
        QC_03: 0.032,
        VC_03: 0.50655,
        QE_03: 0.032,
        VE_03: 0.50655,
        QA_04: 0.025,
        VA_04: 0.48221,
        QC_04: 0.025,
        VC_04: 0.48221,
        QE_04: 0.025,
        VE_04: 0.48221,
        QA_05: 0.018,
        VA_05: 0.45438,
        QC_05: 0.018,
        VC_05: 0.45438,
        QE_05: 0.018,
        VE_05: 0.45438,
        QA_06: 0.01,
        VA_06: 0.41524,
        QC_06: 0.01,
        VC_06: 0.41524,
        QE_06: 0.01,
        VE_06: 0.41524,
        QA_07: 0.006,
        VA_07: 0.38986,
        QC_07: 0.006,
        VC_07: 0.38986,
        QE_07: 0.006,
        VE_07: 0.38986,
        QA_08: 0.004,
        VA_08: 0.37408,
        QC_08: 0.004,
        VC_08: 0.37408,
        QE_08: 0.004,
        VE_08: 0.37408,
        QA_09: 0.003,
        VA_09: 0.36477,
        QC_09: 0.003,
        VC_09: 0.36477,
        QE_09: 0.003,
        VE_09: 0.36477,
        QA_10: 0.002,
        VA_10: 0.35385,
        QC_10: 0.002,
        VC_10: 0.35385,
        QE_10: 0.002,
        VE_10: 0.35385,
        QA_11: 0.013,
        VA_11: 0.43122,
        QC_11: 0.013,
        VC_11: 0.43122,
        QE_11: 0.013,
        VE_11: 0.43122,
        QA_12: 0.02,
        VA_12: 0.46279,
        QC_12: 0.02,
        VC_12: 0.46279,
        QE_12: 0.02,
        VE_12: 0.46279,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.690797516972793, 35.934374410889347, 0.0],
          [-81.690345783640225, 35.933046410891393, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283828.0,
        COMID: 9751478,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 0.163,
        REACHCODE: "03050101000247",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001498465621485,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060792.0,
        ToNode: 250060821.0,
        Hydroseq: 250031115.0,
        LevelPathI: 250024233.0,
        Pathlength: 662.727,
        TerminalPa: 250002604.0,
        ArbolateSu: 148.728,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250033884.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029951.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.036,
        TotDASqKM: 141.7869,
        DivDASqKM: 141.7869,
        Tidal: 0,
        TOTMA: 0.0046637188756900004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 43754.0,
        MAXELEVSMO: 44064.0,
        MINELEVSMO: 43987.0,
        SLOPE: 0.00472392,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.163000002503395,
        QA_MA: 104.394,
        VA_MA: 1.32625,
        QC_MA: 105.562,
        VC_MA: 1.32717,
        QE_MA: 105.562,
        VE_MA: 1.32717,
        QA_01: 137.092,
        VA_01: 1.48471,
        QC_01: 109.283,
        VC_01: 1.34608,
        QE_01: 109.283,
        VE_01: 1.34608,
        QA_02: 146.468,
        VA_02: 1.52676,
        QC_02: 124.563,
        VC_02: 1.42076,
        QE_02: 124.563,
        VE_02: 1.42076,
        QA_03: 181.914,
        VA_03: 1.67536,
        QC_03: 156.0,
        VC_03: 1.56195,
        QE_03: 156.0,
        VE_03: 1.56195,
        QA_04: 151.888,
        VA_04: 1.5505,
        QC_04: 141.799,
        VC_04: 1.5,
        QE_04: 141.799,
        VE_04: 1.5,
        QA_05: 120.262,
        VA_05: 1.40568,
        QC_05: 114.169,
        VC_05: 1.37047,
        QE_05: 114.169,
        VE_05: 1.37047,
        QA_06: 80.166,
        VA_06: 1.19308,
        QC_06: 100.728,
        VC_06: 1.30212,
        QE_06: 100.728,
        VE_06: 1.30212,
        QA_07: 48.189,
        VA_07: 0.98356,
        QC_07: 73.266,
        VC_07: 1.14758,
        QE_07: 73.266,
        VE_07: 1.14758,
        QA_08: 37.369,
        VA_08: 0.89828,
        QC_08: 83.347,
        VC_08: 1.20704,
        QE_08: 83.347,
        VE_08: 1.20704,
        QA_09: 48.076,
        VA_09: 0.98272,
        QC_09: 119.489,
        VC_09: 1.39646,
        QE_09: 119.489,
        VE_09: 1.39646,
        QA_10: 67.323,
        VA_10: 1.11475,
        QC_10: 158.768,
        VC_10: 1.57372,
        QE_10: 158.768,
        VE_10: 1.57372,
        QA_11: 114.344,
        VA_11: 1.37667,
        QC_11: 141.933,
        VC_11: 1.5006,
        QE_11: 141.933,
        VE_11: 1.5006,
        QA_12: 120.413,
        VA_12: 1.40641,
        QC_12: 103.339,
        VC_12: 1.31572,
        QE_12: 103.339,
        VE_12: 1.31572,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.765138516857462, 35.972427610830323, 0.0],
          [-81.765590983523452, 35.971053877499116, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286903.0,
        COMID: 9733476,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 2.108,
        REACHCODE: "03050103000190",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020972090935485001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059073.0,
        ToNode: 250059074.0,
        Hydroseq: 250024247.0,
        LevelPathI: 250018600.0,
        Pathlength: 426.702,
        TerminalPa: 250002604.0,
        ArbolateSu: 172.183,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250024871.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023662.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3113,
        TotDASqKM: 216.8379,
        DivDASqKM: 216.8379,
        Tidal: 0,
        TOTMA: 0.0782322167841,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14524.0,
        MAXELEVSMO: 14864.0,
        MINELEVSMO: 14784.0,
        SLOPE: 0.0003795,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1080000400543208,
        QA_MA: 90.347,
        VA_MA: 1.02607,
        QC_MA: 91.872,
        VC_MA: 1.02704,
        QE_MA: 85.968,
        VE_MA: 1.02319,
        QA_01: 195.065,
        VA_01: 1.38839,
        QC_01: 154.064,
        VC_01: 1.25406,
        QE_01: 163.68,
        VE_01: 1.31458,
        QA_02: 201.383,
        VA_02: 1.40683,
        QC_02: 169.784,
        VC_02: 1.30412,
        QE_02: 176.62,
        VE_02: 1.35607,
        QA_03: 215.139,
        VA_03: 1.44605,
        QC_03: 183.473,
        VC_03: 1.34598,
        QE_03: 181.213,
        VE_03: 1.37045,
        QA_04: 124.942,
        VA_04: 1.16089,
        QC_04: 117.162,
        VC_04: 1.12609,
        QE_04: 103.508,
        VE_04: 1.09725,
        QA_05: 69.949,
        VA_05: 0.93488,
        QC_05: 67.765,
        VC_05: 0.9198,
        QE_05: 55.015,
        VE_05: 0.87234,
        QA_06: 43.731,
        VA_06: 0.79659,
        QC_06: 55.407,
        VC_06: 0.8578,
        QE_06: 38.816,
        VE_06: 0.777,
        QA_07: 32.07,
        VA_07: 0.72246,
        QC_07: 49.236,
        VC_07: 0.82441,
        QE_07: 44.413,
        VE_07: 0.81175,
        QA_08: 23.323,
        VA_08: 0.65804,
        QC_08: 51.532,
        VC_08: 0.83705,
        QE_08: 50.112,
        VE_08: 0.84507,
        QA_09: 20.408,
        VA_09: 0.63411,
        QC_09: 56.077,
        VC_09: 0.86132,
        QE_09: 37.577,
        VE_09: 0.769,
        QA_10: 15.023,
        VA_10: 0.58521,
        QC_10: 47.907,
        VC_10: 0.81697,
        QE_10: 58.705,
        VE_10: 0.89212,
        QA_11: 31.687,
        VA_11: 0.71983,
        QC_11: 53.34,
        VC_11: 0.84682,
        QE_11: 45.568,
        VE_11: 0.81865,
        QA_12: 112.091,
        VA_12: 1.11314,
        QC_12: 96.82,
        VC_12: 1.04734,
        QE_12: 79.024,
        VE_12: 0.99192,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.782836585048869, 34.941833079096682, 0.0],
          [-80.781341118384489, 34.936036879105593, 0.0],
          [-80.790798785036486, 34.934967212440654, 0.0],
          [-80.789745718371478, 34.931347479113015, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283829.0,
        COMID: 9751468,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 3.447,
        REACHCODE: "03050101000248",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.034596816944389999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060789.0,
        ToNode: 250060792.0,
        Hydroseq: 250033884.0,
        LevelPathI: 250024233.0,
        Pathlength: 662.89,
        TerminalPa: 250002604.0,
        ArbolateSu: 113.757,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250035546.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031115.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.3543,
        TotDASqKM: 104.3838,
        DivDASqKM: 104.3838,
        Tidal: 0,
        TOTMA: 0.10693603605099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 43761.0,
        MAXELEVSMO: 45357.0,
        MINELEVSMO: 44064.0,
        SLOPE: 0.00375108,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.4470000267028809,
        QA_MA: 76.182,
        VA_MA: 1.22229,
        QC_MA: 77.982,
        VC_MA: 1.22402,
        QE_MA: 77.982,
        VE_MA: 1.22402,
        QA_01: 99.253,
        VA_01: 1.36012,
        QC_01: 79.792,
        VC_01: 1.23524,
        QE_01: 79.792,
        VE_01: 1.23524,
        QA_02: 106.156,
        VA_02: 1.39835,
        QC_02: 91.075,
        VC_02: 1.30268,
        QE_02: 91.075,
        VE_02: 1.30268,
        QA_03: 132.822,
        VA_03: 1.53609,
        QC_03: 115.091,
        VC_03: 1.43425,
        QE_03: 115.091,
        VE_03: 1.43425,
        QA_04: 111.597,
        VA_04: 1.42767,
        QC_04: 104.917,
        VC_04: 1.38025,
        QE_04: 104.917,
        VE_04: 1.38025,
        QA_05: 87.571,
        VA_05: 1.29247,
        QC_05: 84.127,
        VC_05: 1.26165,
        QE_05: 84.127,
        VE_05: 1.26165,
        QA_06: 59.451,
        VA_06: 1.10954,
        QC_06: 75.006,
        VC_06: 1.2053,
        QE_06: 75.006,
        VE_06: 1.2053,
        QA_07: 36.161,
        VA_07: 0.92365,
        QC_07: 55.359,
        VC_07: 1.07171,
        QE_07: 55.359,
        VE_07: 1.07171,
        QA_08: 28.218,
        VA_08: 0.84773,
        QC_08: 62.585,
        VC_08: 1.12309,
        QE_08: 62.585,
        VE_08: 1.12309,
        QA_09: 35.199,
        VA_09: 0.9149,
        QC_09: 90.738,
        VC_09: 1.30072,
        QE_09: 90.738,
        VE_09: 1.30072,
        QA_10: 48.28,
        VA_10: 1.02572,
        QC_10: 121.734,
        VC_10: 1.4683,
        QE_10: 121.734,
        VE_10: 1.4683,
        QA_11: 82.611,
        VA_11: 1.26246,
        QC_11: 110.77,
        VC_11: 1.4116,
        QE_11: 110.77,
        VE_11: 1.4116,
        QA_12: 87.376,
        VA_12: 1.2913,
        QC_12: 77.187,
        VC_12: 1.21905,
        QE_12: 77.187,
        VE_12: 1.21905,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.761257583530039, 35.988477077472055, 0.0],
          [-81.762333116861839, 35.984928610810925, 0.0],
          [-81.755405316872611, 35.982426077481541, 0.0],
          [-81.758001916868523, 35.979130877486568, 0.0],
          [-81.762225316862001, 35.981172810816702, 0.0],
          [-81.764452116858479, 35.980396277484658, 0.0],
          [-81.766175516855867, 35.976642010823753, 0.0],
          [-81.76310878352723, 35.973433477495462, 0.0],
          [-81.765138516857462, 35.972427610830323, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286904.0,
        COMID: 9733436,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.819,
        REACHCODE: "03050103000191",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0085467258646050003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059059.0,
        ToNode: 250059073.0,
        Hydroseq: 250024871.0,
        LevelPathI: 250018600.0,
        Pathlength: 428.81,
        TerminalPa: 250002604.0,
        ArbolateSu: 167.308,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250025548.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024247.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.414,
        TotDASqKM: 211.8546,
        DivDASqKM: 211.8546,
        Tidal: 0,
        TOTMA: 0.040493254581800003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14795.0,
        MAXELEVSMO: 14866.0,
        MINELEVSMO: 14864.0,
        SLOPE: 2.442e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.81900000572204601,
        QA_MA: 88.307,
        VA_MA: 0.7699,
        QC_MA: 89.877,
        VC_MA: 0.77056,
        QE_MA: 83.974,
        VE_MA: 0.76802,
        QA_01: 190.642,
        VA_01: 1.00286,
        QC_01: 150.662,
        VC_01: 0.91643,
        QE_01: 160.278,
        VE_01: 0.95624,
        QA_02: 196.795,
        VA_02: 1.01468,
        QC_02: 166.021,
        VC_02: 0.94859,
        QE_02: 172.856,
        VE_02: 0.98276,
        QA_03: 210.248,
        VA_03: 1.03992,
        QC_03: 179.438,
        VC_03: 0.97557,
        QE_03: 177.178,
        VE_03: 0.99166,
        QA_04: 122.061,
        VA_04: 0.85645,
        QC_04: 114.521,
        VC_04: 0.83402,
        QE_04: 100.867,
        VE_04: 0.81501,
        QA_05: 68.341,
        VA_05: 0.71117,
        QC_05: 66.265,
        VC_05: 0.7015,
        QE_05: 53.515,
        VE_05: 0.67022,
        QA_06: 42.726,
        VA_06: 0.62226,
        QC_06: 54.151,
        VC_06: 0.66154,
        QE_06: 37.56,
        VE_06: 0.60821,
        QA_07: 31.333,
        VA_07: 0.57461,
        QC_07: 48.131,
        VC_07: 0.64012,
        QE_07: 43.308,
        VE_07: 0.63176,
        QA_08: 22.787,
        VA_08: 0.53319,
        QC_08: 50.324,
        VC_08: 0.64806,
        QE_08: 48.905,
        VE_08: 0.65332,
        QA_09: 19.939,
        VA_09: 0.5178,
        QC_09: 54.937,
        VC_09: 0.66425,
        QE_09: 36.438,
        VE_09: 0.60343,
        QA_10: 14.688,
        VA_10: 0.48643,
        QC_10: 47.052,
        VC_10: 0.63615,
        QE_10: 57.85,
        VE_10: 0.6855,
        QA_11: 31.144,
        VA_11: 0.57375,
        QC_11: 52.641,
        VC_11: 0.65627,
        QE_11: 44.869,
        VE_11: 0.6379,
        QA_12: 109.612,
        VA_12: 0.82602,
        QC_12: 94.87,
        VC_12: 0.78402,
        QE_12: 77.074,
        VE_12: 0.74756,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.775524785060213, 34.938781012434731, 0.0],
          [-80.782836585048869, 34.941833079096682, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285887.0,
        COMID: 9754076,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.605,
        REACHCODE: "03050101002562",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.024985368152751,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061512.0,
        ToNode: 250061496.0,
        Hydroseq: 250010527.0,
        LevelPathI: 250003258.0,
        Pathlength: 594.35,
        TerminalPa: 250002604.0,
        ArbolateSu: 2660.019,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010597.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010461.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.2083,
        TotDASqKM: 2910.9051,
        DivDASqKM: 2910.9051,
        Tidal: 0,
        TOTMA: 1.1108964101300001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28343.0,
        MAXELEVSMO: 28361.0,
        MINELEVSMO: 28350.0,
        SLOPE: 4.222e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.6050000190734859,
        QA_MA: 1794.011,
        VA_MA: -9998.0,
        QC_MA: 1794.011,
        VC_MA: -9998.0,
        QE_MA: 1989.055,
        VE_MA: -9998.0,
        QA_01: 2862.496,
        VA_01: -9998.0,
        QC_01: 2862.496,
        VC_01: -9998.0,
        QE_01: 3067.771,
        VE_01: -9998.0,
        QA_02: 3033.342,
        VA_02: -9998.0,
        QC_02: 3033.342,
        VC_02: -9998.0,
        QE_02: 3105.164,
        VE_02: -9998.0,
        QA_03: 3446.792,
        VA_03: -9998.0,
        QC_03: 3446.792,
        VC_03: -9998.0,
        QE_03: 3710.912,
        VE_03: -9998.0,
        QA_04: 2641.204,
        VA_04: -9998.0,
        QC_04: 2641.204,
        VC_04: -9998.0,
        QE_04: 2833.33,
        VE_04: -9998.0,
        QA_05: 2033.61,
        VA_05: -9998.0,
        QC_05: 2033.61,
        VC_05: -9998.0,
        QE_05: 2255.732,
        VE_05: -9998.0,
        QA_06: 1185.943,
        VA_06: -9998.0,
        QC_06: 1185.943,
        VC_06: -9998.0,
        QE_06: 1445.556,
        VE_06: -9998.0,
        QA_07: 702.122,
        VA_07: -9998.0,
        QC_07: 702.122,
        VC_07: -9998.0,
        QE_07: 849.016,
        VE_07: -9998.0,
        QA_08: 499.269,
        VA_08: -9998.0,
        QC_08: 499.269,
        VC_08: -9998.0,
        QE_08: 798.885,
        VE_08: -9998.0,
        QA_09: 501.93,
        VA_09: -9998.0,
        QC_09: 947.869,
        VC_09: -9998.0,
        QE_09: 984.375,
        VE_09: -9998.0,
        QA_10: 656.331,
        VA_10: -9998.0,
        QC_10: 978.966,
        VC_10: -9998.0,
        QE_10: 939.463,
        VE_10: -9998.0,
        QA_11: 1705.415,
        VA_11: -9998.0,
        QC_11: 1705.415,
        VC_11: -9998.0,
        QE_11: 1682.615,
        VE_11: -9998.0,
        QA_12: 2271.509,
        VA_12: -9998.0,
        QC_12: 2271.509,
        VC_12: -9998.0,
        QE_12: 2295.222,
        VE_12: -9998.0,
        LakeFract: 0.10055279751,
        SurfArea: 1567617.0900300001,
        RAreaHLoad: 0.321350952,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.357239117490622, 35.766918877815954, 0.0],
          [-81.35261978416446, 35.770208611144206, 0.0],
          [-81.352396517498107, 35.773977011138356, 0.0],
          [-81.355492917493279, 35.778971677797301, 0.0],
          [-81.350336917501352, 35.786329811119181, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283830.0,
        COMID: 9751400,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 3.064,
        REACHCODE: "03050101000249",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.029972169367237001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060767.0,
        ToNode: 250060789.0,
        Hydroseq: 250035546.0,
        LevelPathI: 250024233.0,
        Pathlength: 666.337,
        TerminalPa: 250002604.0,
        ArbolateSu: 101.192,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250037464.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033884.0,
        FromMeas: 0.0,
        ToMeas: 94.93742,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6793,
        TotDASqKM: 92.7234,
        DivDASqKM: 92.7234,
        Tidal: 0,
        TOTMA: 0.094149689490899996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 45357.0,
        MAXELEVSMO: 46812.0,
        MINELEVSMO: 45357.0,
        SLOPE: 0.00474869,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0639998912811279,
        QA_MA: 69.532,
        VA_MA: 1.23377,
        QC_MA: 71.427,
        VC_MA: 1.23578,
        QE_MA: 71.427,
        VE_MA: 1.23578,
        QA_01: 88.462,
        VA_01: 1.35998,
        QC_01: 71.332,
        VC_01: 1.23513,
        QE_01: 71.332,
        VE_01: 1.23513,
        QA_02: 95.097,
        VA_02: 1.40115,
        QC_02: 81.832,
        VC_02: 1.30523,
        QE_02: 81.832,
        VE_02: 1.30523,
        QA_03: 119.72,
        VA_03: 1.54327,
        QC_03: 104.094,
        VC_03: 1.44109,
        QE_03: 104.094,
        VE_03: 1.44109,
        QA_04: 101.017,
        VA_04: 1.43676,
        QC_04: 95.186,
        VC_04: 1.38854,
        QE_04: 95.186,
        VE_04: 1.38854,
        QA_05: 79.624,
        VA_05: 1.30281,
        QC_05: 76.766,
        VC_05: 1.27197,
        QE_05: 76.766,
        VE_05: 1.27197,
        QA_06: 55.128,
        VA_06: 1.12645,
        QC_06: 69.624,
        VC_06: 1.22328,
        QE_06: 69.624,
        VE_06: 1.22328,
        QA_07: 33.56,
        VA_07: 0.93692,
        QC_07: 51.469,
        VC_07: 1.08767,
        QE_07: 51.469,
        VE_07: 1.08767,
        QA_08: 26.58,
        VA_08: 0.86372,
        QC_08: 58.88,
        VC_08: 1.14537,
        QE_08: 58.88,
        VE_08: 1.14537,
        QA_09: 33.937,
        VA_09: 0.94066,
        QC_09: 87.859,
        VC_09: 1.34356,
        QE_09: 87.859,
        VE_09: 1.34356,
        QA_10: 46.527,
        VA_10: 1.05599,
        QC_10: 118.19,
        VC_10: 1.52011,
        QE_10: 118.19,
        VE_10: 1.52011,
        QA_11: 75.935,
        VA_11: 1.27808,
        QC_11: 103.875,
        VC_11: 1.43983,
        QE_11: 103.875,
        VE_11: 1.43983,
        QA_12: 79.303,
        VA_12: 1.30068,
        QC_12: 70.67,
        VC_12: 1.23055,
        QE_12: 70.67,
        VE_12: 1.23055,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.773750316844087, 36.000139677453944, 0.0],
          [-81.775276983508377, 35.996457877459648, 0.0],
          [-81.770940316848453, 35.994668277462381, 0.0],
          [-81.769299983517612, 35.998560010789674, 0.0],
          [-81.765809916856426, 35.996061010793539, 0.0],
          [-81.764806783524648, 35.989098477471032, 0.0],
          [-81.761257583530039, 35.988477077472055, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286905.0,
        COMID: 9733438,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.508,
        REACHCODE: "03050103000192",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014417155846549,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059060.0,
        ToNode: 250059059.0,
        Hydroseq: 250025548.0,
        LevelPathI: 250018600.0,
        Pathlength: 429.629,
        TerminalPa: 250002604.0,
        ArbolateSu: 164.204,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250026290.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024871.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4832,
        TotDASqKM: 209.2941,
        DivDASqKM: 209.2941,
        Tidal: 0,
        TOTMA: 0.053369007115700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14819.0,
        MAXELEVSMO: 14958.0,
        MINELEVSMO: 14866.0,
        SLOPE: 0.00061007,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5080000162124629,
        QA_MA: 87.261,
        VA_MA: 1.07611,
        QC_MA: 88.853,
        VC_MA: 1.07723,
        QE_MA: 82.95,
        VE_MA: 1.07296,
        QA_01: 188.37,
        VA_01: 1.46359,
        QC_01: 148.913,
        VC_01: 1.31978,
        QE_01: 158.529,
        VE_01: 1.3868,
        QA_02: 194.453,
        VA_02: 1.48325,
        QC_02: 164.099,
        VC_02: 1.3733,
        QE_02: 170.934,
        VE_02: 1.43082,
        QA_03: 207.735,
        VA_03: 1.5252,
        QC_03: 177.364,
        VC_03: 1.41818,
        QE_03: 175.103,
        VE_03: 1.44527,
        QA_04: 120.581,
        VA_04: 1.21995,
        QC_04: 113.164,
        VC_04: 1.18259,
        QE_04: 99.51,
        VE_04: 1.15055,
        QA_05: 67.515,
        VA_05: 0.97833,
        QC_05: 65.494,
        VC_05: 0.96226,
        QE_05: 52.743,
        VE_05: 0.90956,
        QA_06: 42.21,
        VA_06: 0.83045,
        QC_06: 53.505,
        VC_06: 0.8957,
        QE_06: 36.914,
        VE_06: 0.80579,
        QA_07: 30.954,
        VA_07: 0.75118,
        QC_07: 47.563,
        VC_07: 0.86011,
        QE_07: 42.74,
        VE_07: 0.84604,
        QA_08: 22.512,
        VA_08: 0.68229,
        QC_08: 49.703,
        VC_08: 0.87316,
        QE_08: 48.284,
        VE_08: 0.88201,
        QA_09: 19.698,
        VA_09: 0.6567,
        QC_09: 54.351,
        VC_09: 0.90061,
        QE_09: 35.851,
        VE_09: 0.79813,
        QA_10: 14.516,
        VA_10: 0.60458,
        QC_10: 46.611,
        VC_10: 0.85422,
        QE_10: 57.409,
        VE_10: 0.93723,
        QA_11: 30.869,
        VA_11: 0.75053,
        QC_11: 52.285,
        VC_11: 0.88855,
        QE_11: 44.513,
        VE_11: 0.85777,
        QA_12: 108.338,
        VA_12: 1.16957,
        QC_12: 93.867,
        VC_12: 1.09997,
        QE_12: 76.07,
        VE_12: 1.03861,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.768308318404763, 34.948418079086423, 0.0],
          [-80.772430185064991, 34.944527679092459, 0.0],
          [-80.773828718396203, 34.938252812435564, 0.0],
          [-80.775524785060213, 34.938781012434731, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283823.0,
        COMID: 9751932,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 0.22,
        REACHCODE: "03050101000242",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0020601544386990001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060914.0,
        ToNode: 250060925.0,
        Hydroseq: 250026276.0,
        LevelPathI: 250024233.0,
        Pathlength: 651.407,
        TerminalPa: 250002604.0,
        ArbolateSu: 178.874,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250027073.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025534.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8568,
        TotDASqKM: 175.6755,
        DivDASqKM: 175.6755,
        Tidal: 0,
        TOTMA: 0.0062465814961300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32273.0,
        MAXELEVSMO: 32405.0,
        MINELEVSMO: 32322.0,
        SLOPE: 0.00377272,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.21999999880790699,
        QA_MA: 124.691,
        VA_MA: 1.33702,
        QC_MA: 125.219,
        VC_MA: 1.33737,
        QE_MA: 125.219,
        VE_MA: 1.33737,
        QA_01: 169.78,
        VA_01: 1.52021,
        QC_01: 134.583,
        VC_01: 1.37754,
        QE_01: 134.583,
        VE_01: 1.37754,
        QA_02: 179.571,
        VA_02: 1.55683,
        QC_02: 151.869,
        VC_02: 1.44839,
        QE_02: 151.869,
        VE_02: 1.44839,
        QA_03: 220.511,
        VA_03: 1.70068,
        QC_03: 187.902,
        VC_03: 1.58483,
        QE_03: 187.902,
        VE_03: 1.58483,
        QA_04: 182.636,
        VA_04: 1.5681,
        QC_04: 169.792,
        VC_04: 1.51795,
        QE_04: 169.792,
        VE_04: 1.51795,
        QA_05: 144.563,
        VA_05: 1.42104,
        QC_05: 136.297,
        VC_05: 1.38474,
        QE_05: 136.297,
        VE_05: 1.38474,
        QA_06: 93.394,
        VA_06: 1.19067,
        QC_06: 117.102,
        VC_06: 1.30139,
        QE_06: 117.102,
        VE_06: 1.30139,
        QA_07: 56.031,
        VA_07: 0.98103,
        QC_07: 84.882,
        VC_07: 1.14543,
        QE_07: 84.882,
        VE_07: 1.14543,
        QA_08: 42.4,
        VA_08: 0.88848,
        QC_08: 94.808,
        VC_08: 1.19605,
        QE_08: 94.808,
        VE_08: 1.19605,
        QA_09: 52.427,
        VA_09: 0.9577,
        QC_09: 128.989,
        VC_09: 1.3537,
        QE_09: 128.989,
        VE_09: 1.3537,
        QA_10: 74.577,
        VA_10: 1.09132,
        QC_10: 172.291,
        VC_10: 1.52737,
        QE_10: 172.291,
        VE_10: 1.52737,
        QA_11: 134.825,
        VA_11: 1.38059,
        QC_11: 160.937,
        VC_11: 1.48404,
        QE_11: 160.937,
        VE_11: 1.48404,
        QA_12: 146.326,
        VA_12: 1.42823,
        QC_12: 123.39,
        VC_12: 1.32936,
        QE_12: 123.39,
        VE_12: 1.32936,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.715758583600746, 35.898564077611582, 0.0],
          [-81.715672116934229, 35.89682367761435, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286906.0,
        COMID: 9733378,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.129,
        REACHCODE: "03050103000193",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001369054821412,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059035.0,
        ToNode: 250059060.0,
        Hydroseq: 250026290.0,
        LevelPathI: 250018600.0,
        Pathlength: 431.137,
        TerminalPa: 250002604.0,
        ArbolateSu: 157.747,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250027088.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025548.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 202.3515,
        DivDASqKM: 202.3515,
        Tidal: 0,
        TOTMA: 0.00377246944956,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14948.0,
        MAXELEVSMO: 15000.0,
        MINELEVSMO: 14958.0,
        SLOPE: 0.00325581,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.12899999320507,
        QA_MA: 84.416,
        VA_MA: 1.30263,
        QC_MA: 86.066,
        VC_MA: 1.30419,
        QE_MA: 80.163,
        VE_MA: 1.29848,
        QA_01: 182.21,
        VA_01: 1.80436,
        QC_01: 144.169,
        VC_01: 1.61799,
        QE_01: 153.784,
        VE_01: 1.7077,
        QA_02: 188.045,
        VA_02: 1.82961,
        QC_02: 158.836,
        VC_02: 1.68713,
        QE_02: 165.671,
        VE_02: 1.76413,
        QA_03: 200.916,
        VA_03: 1.88404,
        QC_03: 171.732,
        VC_03: 1.74548,
        QE_03: 169.471,
        VE_03: 1.78176,
        QA_04: 116.567,
        VA_04: 1.48847,
        QC_04: 109.481,
        VC_04: 1.43992,
        QE_04: 95.827,
        VE_04: 1.3969,
        QA_05: 65.274,
        VA_05: 1.17573,
        QC_05: 63.4,
        VC_05: 1.15501,
        QE_05: 50.65,
        VE_05: 1.08427,
        QA_06: 40.81,
        VA_06: 0.98428,
        QC_06: 51.755,
        VC_06: 1.06852,
        QE_06: 35.163,
        VE_06: 0.94758,
        QA_07: 29.927,
        VA_07: 0.88165,
        QC_07: 46.023,
        VC_07: 1.02259,
        QE_07: 41.2,
        VE_07: 1.00369,
        QA_08: 21.765,
        VA_08: 0.79246,
        QC_08: 48.022,
        VC_08: 1.0389,
        QE_08: 46.603,
        VE_08: 1.05071,
        QA_09: 19.045,
        VA_09: 0.75933,
        QC_09: 52.756,
        VC_09: 1.07629,
        QE_09: 34.256,
        VE_09: 0.93878,
        QA_10: 14.049,
        VA_10: 0.69206,
        QC_10: 45.41,
        VC_10: 1.01752,
        QE_10: 56.208,
        VE_10: 1.12838,
        QA_11: 30.107,
        VA_11: 0.88347,
        QC_11: 51.299,
        VC_11: 1.06496,
        QE_11: 43.527,
        VE_11: 1.02428,
        QA_12: 104.885,
        VA_12: 1.42412,
        QC_12: 91.14,
        VC_12: 1.33495,
        QE_12: 73.344,
        VE_12: 1.25283,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.767084718406636, 34.948119212420238, 0.0],
          [-80.768308318404763, 34.948418079086423, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285889.0,
        COMID: 9754118,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 4.063,
        REACHCODE: "03050101002564",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.042831152799567,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061532.0,
        ToNode: 250061512.0,
        Hydroseq: 250010597.0,
        LevelPathI: 250003258.0,
        Pathlength: 596.955,
        TerminalPa: 250002604.0,
        ArbolateSu: 2648.643,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010659.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010527.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 8.2152,
        TotDASqKM: 2890.5273,
        DivDASqKM: 2890.5273,
        Tidal: 0,
        TOTMA: 1.7326572416299999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28360.0,
        MAXELEVSMO: 28381.0,
        MINELEVSMO: 28361.0,
        SLOPE: 4.922e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.0630002021789551,
        QA_MA: 1783.505,
        VA_MA: -9998.0,
        QC_MA: 1783.505,
        VC_MA: -9998.0,
        QE_MA: 1978.549,
        VE_MA: -9998.0,
        QA_01: 2843.38,
        VA_01: -9998.0,
        QC_01: 2843.38,
        VC_01: -9998.0,
        QE_01: 3048.655,
        VE_01: -9998.0,
        QA_02: 3013.002,
        VA_02: -9998.0,
        QC_02: 3013.002,
        VC_02: -9998.0,
        QE_02: 3084.824,
        VE_02: -9998.0,
        QA_03: 3424.917,
        VA_03: -9998.0,
        QC_03: 3424.917,
        VC_03: -9998.0,
        QE_03: 3689.037,
        VE_03: -9998.0,
        QA_04: 2625.584,
        VA_04: -9998.0,
        QC_04: 2625.584,
        VC_04: -9998.0,
        QE_04: 2817.71,
        VE_04: -9998.0,
        QA_05: 2022.077,
        VA_05: -9998.0,
        QC_05: 2022.077,
        VC_05: -9998.0,
        QE_05: 2244.199,
        VE_05: -9998.0,
        QA_06: 1179.365,
        VA_06: -9998.0,
        QC_06: 1179.365,
        VC_06: -9998.0,
        QE_06: 1438.978,
        VE_06: -9998.0,
        QA_07: 698.249,
        VA_07: -9998.0,
        QC_07: 698.249,
        VC_07: -9998.0,
        QE_07: 845.143,
        VE_07: -9998.0,
        QA_08: 496.597,
        VA_08: -9998.0,
        QC_08: 496.597,
        VC_08: -9998.0,
        QE_08: 796.213,
        VE_08: -9998.0,
        QA_09: 499.849,
        VA_09: -9998.0,
        QC_09: 944.398,
        VC_09: -9998.0,
        QE_09: 980.904,
        VE_09: -9998.0,
        QA_10: 654.687,
        VA_10: -9998.0,
        QC_10: 977.007,
        VC_10: -9998.0,
        QE_10: 937.504,
        VE_10: -9998.0,
        QA_11: 1698.182,
        VA_11: -9998.0,
        QC_11: 1698.182,
        VC_11: -9998.0,
        QE_11: 1675.382,
        VE_11: -9998.0,
        QA_12: 2257.899,
        VA_12: -9998.0,
        QC_12: 2257.899,
        VC_12: -9998.0,
        QE_12: 2281.612,
        VE_12: -9998.0,
        LakeFract: 0.15600512345000001,
        SurfArea: 2432118.2871900001,
        RAreaHLoad: 0.5012087977,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.393689917433903, 35.758985077828299, 0.0],
          [-81.3782201841247, 35.756984411164694, 0.0],
          [-81.369996717470826, 35.767080477815625, 0.0],
          [-81.366736984142506, 35.768166877814053, 0.0],
          [-81.357239117490622, 35.766918877815954, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283824.0,
        COMID: 9751926,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 0.604,
        REACHCODE: "03050101000243",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0060083815831060003,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060911.0,
        ToNode: 250060914.0,
        Hydroseq: 250027073.0,
        LevelPathI: 250024233.0,
        Pathlength: 651.627,
        TerminalPa: 250002604.0,
        ArbolateSu: 178.654,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250027945.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 250026275.0,
        DnDrainCou: 2,
        DnHydroseq: 250026276.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5886,
        TotDASqKM: 174.8187,
        DivDASqKM: 174.8187,
        Tidal: 0,
        TOTMA: 0.0157896010531,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32405.0,
        MAXELEVSMO: 32852.0,
        MINELEVSMO: 32405.0,
        SLOPE: 0.00740066,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60399997234344505,
        QA_MA: 124.242,
        VA_MA: 1.45216,
        QC_MA: 124.786,
        VC_MA: 1.45257,
        QE_MA: 124.786,
        VE_MA: 1.45257,
        QA_01: 169.008,
        VA_01: 1.65519,
        QC_01: 133.987,
        VC_01: 1.49662,
        QE_01: 133.987,
        VE_01: 1.49662,
        QA_02: 178.754,
        VA_02: 1.69588,
        QC_02: 151.197,
        VC_02: 1.57536,
        QE_02: 151.197,
        VE_02: 1.57536,
        QA_03: 219.598,
        VA_03: 1.85609,
        QC_03: 187.149,
        VC_03: 1.72732,
        QE_03: 187.149,
        VE_03: 1.72732,
        QA_04: 181.932,
        VA_04: 1.70893,
        QC_04: 169.153,
        VC_04: 1.65315,
        QE_04: 169.153,
        VE_04: 1.65315,
        QA_05: 144.046,
        VA_05: 1.54561,
        QC_05: 135.828,
        VC_05: 1.50526,
        QE_05: 135.828,
        VE_05: 1.50526,
        QA_06: 93.106,
        VA_06: 1.28971,
        QC_06: 116.746,
        VC_06: 1.41281,
        QE_06: 116.746,
        VE_06: 1.41281,
        QA_07: 55.858,
        VA_07: 1.05654,
        QC_07: 84.627,
        VC_07: 1.23936,
        QE_07: 84.627,
        VE_07: 1.23936,
        QA_08: 42.285,
        VA_08: 0.95373,
        QC_08: 94.546,
        VC_08: 1.2958,
        QE_08: 94.546,
        VE_08: 1.2958,
        QA_09: 52.335,
        VA_09: 1.0311,
        QC_09: 128.789,
        VC_09: 1.47191,
        QE_09: 128.789,
        VE_09: 1.47191,
        QA_10: 74.508,
        VA_10: 1.1802,
        QC_10: 172.164,
        VC_10: 1.66581,
        QE_10: 172.164,
        VE_10: 1.66581,
        QA_11: 134.466,
        VA_11: 1.50122,
        QC_11: 160.61,
        VC_11: 1.61665,
        QE_11: 160.61,
        VE_11: 1.61665,
        QA_12: 145.749,
        VA_12: 1.55336,
        QC_12: 122.947,
        VC_12: 1.44358,
        QE_12: 122.947,
        VE_12: 1.44358,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.720126116927304, 35.902614010938635, 0.0],
          [-81.715758583600746, 35.898564077611582, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286915.0,
        COMID: 9733306,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 0.516,
        REACHCODE: "03050103000202",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0050030067952890004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059009.0,
        ToNode: 250059010.0,
        Hydroseq: 250037492.0,
        LevelPathI: 250018600.0,
        Pathlength: 439.917,
        TerminalPa: 250002604.0,
        ArbolateSu: 66.736,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250039744.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 250035577.0,
        DnDrainCou: 2,
        DnHydroseq: 250035576.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3339,
        TotDASqKM: 92.8629,
        DivDASqKM: 92.8629,
        Tidal: 0,
        TOTMA: 0.020695746042800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15640.0,
        MAXELEVSMO: 15730.0,
        MINELEVSMO: 15699.0,
        SLOPE: 0.00060077,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.51599997282028198,
        QA_MA: 39.034,
        VA_MA: 0.94422,
        QC_MA: 41.006,
        VC_MA: 0.94676,
        QE_MA: 41.006,
        VE_MA: 0.94676,
        QA_01: 84.306,
        VA_01: 1.2654,
        QC_01: 68.066,
        VC_01: 1.14388,
        QE_01: 68.066,
        VE_01: 1.14388,
        QA_02: 86.543,
        VA_02: 1.27881,
        QC_02: 74.663,
        VC_02: 1.18595,
        QE_02: 74.663,
        VE_02: 1.18595,
        QA_03: 92.499,
        VA_03: 1.31372,
        QC_03: 81.113,
        VC_03: 1.22542,
        QE_03: 81.113,
        VE_03: 1.22542,
        QA_04: 53.352,
        VA_04: 1.05898,
        QC_04: 51.007,
        VC_04: 1.02521,
        QE_04: 51.007,
        VE_04: 1.02521,
        QA_05: 29.908,
        VA_05: 0.86038,
        QC_05: 29.91,
        VC_05: 0.84829,
        QE_05: 29.91,
        VE_05: 0.84829,
        QA_06: 18.728,
        VA_06: 0.7389,
        QC_06: 24.007,
        VC_06: 0.7888,
        QE_06: 24.007,
        VE_06: 0.7888,
        QA_07: 13.734,
        VA_07: 0.67353,
        QC_07: 21.518,
        VC_07: 0.76168,
        QE_07: 21.518,
        VE_07: 0.76168,
        QA_08: 9.988,
        VA_08: 0.61672,
        QC_08: 21.697,
        VC_08: 0.76367,
        QE_08: 21.697,
        VE_08: 0.76367,
        QA_09: 8.74,
        VA_09: 0.59562,
        QC_09: 26.523,
        VC_09: 0.81491,
        QE_09: 26.523,
        VE_09: 0.81491,
        QA_10: 6.689,
        VA_10: 0.55759,
        QC_10: 25.1,
        VC_10: 0.80029,
        QE_10: 25.1,
        VE_10: 0.80029,
        QA_11: 15.174,
        VA_11: 0.69338,
        QC_11: 30.421,
        VC_11: 0.85317,
        QE_11: 30.421,
        VE_11: 0.85317,
        QA_12: 48.972,
        VA_12: 1.02561,
        QC_12: 45.58,
        VC_12: 0.98364,
        QE_12: 45.58,
        VE_12: 0.98364,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.702107718507534, 34.965001612394076, 0.0],
          [-80.705136785169486, 34.961380679066338, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283825.0,
        COMID: 9751904,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 5.726,
        REACHCODE: "03050101000244",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.055631687866482003,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060905.0,
        ToNode: 250060911.0,
        Hydroseq: 250027945.0,
        LevelPathI: 250024233.0,
        Pathlength: 652.231,
        TerminalPa: 250002604.0,
        ArbolateSu: 174.984,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250028896.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027073.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.8189,
        TotDASqKM: 171.4896,
        DivDASqKM: 171.4896,
        Tidal: 0,
        TOTMA: 0.13682171021,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32547.0,
        MAXELEVSMO: 41684.0,
        MINELEVSMO: 32852.0,
        SLOPE: 0.01542438,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.7259998321533203,
        QA_MA: 122.448,
        VA_MA: 1.58865,
        QC_MA: 123.054,
        VC_MA: 1.58916,
        QE_MA: 123.054,
        VE_MA: 1.58916,
        QA_01: 165.964,
        VA_01: 1.81299,
        QC_01: 131.636,
        VC_01: 1.63584,
        QE_01: 131.636,
        VE_01: 1.63584,
        QA_02: 175.586,
        VA_02: 1.8587,
        QC_02: 148.589,
        VC_02: 1.72404,
        QE_02: 148.589,
        VE_02: 1.72404,
        QA_03: 216.017,
        VA_03: 2.03903,
        QC_03: 184.198,
        VC_03: 1.8951,
        QE_03: 184.198,
        VE_03: 1.8951,
        QA_04: 179.144,
        VA_04: 1.8753,
        QC_04: 166.619,
        VC_04: 1.8128,
        QE_04: 166.619,
        VE_04: 1.8128,
        QA_05: 141.966,
        VA_05: 1.69325,
        QC_05: 133.939,
        VC_05: 1.64812,
        QE_05: 133.939,
        VE_05: 1.64812,
        QA_06: 91.952,
        VA_06: 1.40802,
        QC_06: 115.319,
        VC_06: 1.54576,
        QE_06: 115.319,
        VE_06: 1.54576,
        QA_07: 55.175,
        VA_07: 1.14681,
        QC_07: 83.617,
        VC_07: 1.35159,
        QE_07: 83.617,
        VE_07: 1.35159,
        QA_08: 41.827,
        VA_08: 1.03214,
        QC_08: 93.5,
        VC_08: 1.41535,
        QE_08: 93.5,
        VE_08: 1.41535,
        QA_09: 51.977,
        VA_09: 1.12064,
        QC_09: 128.01,
        VC_09: 1.6163,
        QE_09: 128.01,
        VE_09: 1.6163,
        QA_10: 74.167,
        VA_10: 1.28941,
        QC_10: 171.535,
        VC_10: 1.83621,
        QE_10: 171.535,
        VE_10: 1.83621,
        QA_11: 132.939,
        VA_11: 1.64577,
        QC_11: 159.217,
        VC_11: 1.77693,
        QE_11: 159.217,
        VE_11: 1.77693,
        QA_12: 143.424,
        VA_12: 1.70078,
        QC_12: 121.162,
        VC_12: 1.57867,
        QE_12: 121.162,
        VE_12: 1.57867,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.747350983551769, 35.942091477544011, 0.0],
          [-81.745827183554127, 35.940078010880598, 0.0],
          [-81.747312916885164, 35.935908610886941, 0.0],
          [-81.742686183558931, 35.930279877562384, 0.0],
          [-81.734742783571278, 35.928043277565962, 0.0],
          [-81.734986583570958, 35.921424877576101, 0.0],
          [-81.728895516913667, 35.914171077587355, 0.0],
          [-81.727337516916123, 35.906936010931872, 0.0],
          [-81.720126116927304, 35.902614010938635, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283826.0,
        COMID: 9753762,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1016727",
        GNIS_NAME: "Wilson Creek",
        LENGTHKM: 1.141,
        REACHCODE: "03050101000245",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011780753392028999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061398.0,
        ToNode: 250060905.0,
        Hydroseq: 250028896.0,
        LevelPathI: 250024233.0,
        Pathlength: 657.957,
        TerminalPa: 250002604.0,
        ArbolateSu: 167.156,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250029951.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027945.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2537,
        TotDASqKM: 158.6394,
        DivDASqKM: 158.6394,
        Tidal: 0,
        TOTMA: 0.029981479065799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41381.0,
        MAXELEVSMO: 42607.0,
        MINELEVSMO: 41684.0,
        SLOPE: 0.00808939,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.141000032424927,
        QA_MA: 114.686,
        VA_MA: 1.44444,
        QC_MA: 115.546,
        VC_MA: 1.44512,
        QE_MA: 115.546,
        VE_MA: 1.44512,
        QA_01: 153.432,
        VA_01: 1.63431,
        QC_01: 121.947,
        VC_01: 1.47814,
        QE_01: 121.947,
        VE_01: 1.47814,
        QA_02: 162.823,
        VA_02: 1.6768,
        QC_02: 138.073,
        VC_02: 1.55788,
        QE_02: 138.073,
        VE_02: 1.55788,
        QA_03: 201.42,
        VA_03: 1.84044,
        QC_03: 172.148,
        VC_03: 1.71314,
        QE_03: 172.148,
        VE_03: 1.71314,
        QA_04: 167.562,
        VA_04: 1.69781,
        QC_04: 156.083,
        VC_04: 1.64193,
        QE_04: 156.083,
        VE_04: 1.64193,
        QA_05: 132.866,
        VA_05: 1.53676,
        QC_05: 125.665,
        VC_05: 1.49694,
        QE_05: 125.665,
        VE_05: 1.49694,
        QA_06: 86.977,
        VA_06: 1.28928,
        QC_06: 109.163,
        VC_06: 1.41134,
        QE_06: 109.163,
        VE_06: 1.41134,
        QA_07: 52.222,
        VA_07: 1.05652,
        QC_07: 79.246,
        VC_07: 1.23884,
        QE_07: 79.246,
        VE_07: 1.23884,
        QA_08: 39.912,
        VA_08: 0.95699,
        QC_08: 89.135,
        VC_08: 1.29879,
        QE_08: 89.135,
        VE_08: 1.29879,
        QA_09: 50.231,
        VA_09: 1.04124,
        QC_09: 124.206,
        VC_09: 1.4896,
        QE_09: 124.206,
        VE_09: 1.4896,
        QA_10: 71.152,
        VA_10: 1.19006,
        QC_10: 165.941,
        VC_10: 1.68601,
        QE_10: 165.941,
        VE_10: 1.68601,
        QA_11: 125.088,
        VA_11: 1.49803,
        QC_11: 151.995,
        VC_11: 1.62327,
        QE_11: 151.995,
        VE_11: 1.62327,
        QA_12: 133.346,
        VA_12: 1.53911,
        QC_12: 113.391,
        VC_12: 1.43382,
        QE_12: 113.391,
        VE_12: 1.43382,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.756805783536947, 35.948100210868063, 0.0],
          [-81.747350983551769, 35.942091477544011, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285868.0,
        COMID: 9753992,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.18,
        REACHCODE: "03050101002543",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022531290284404999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061470.0,
        ToNode: 250061463.0,
        Hydroseq: 250010300.0,
        LevelPathI: 250003258.0,
        Pathlength: 583.269,
        TerminalPa: 250002604.0,
        ArbolateSu: 2898.3,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010352.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010242.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.0581,
        TotDASqKM: 3206.7612,
        DivDASqKM: 3206.7612,
        Tidal: 0,
        TOTMA: 0.92965611295999995,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28333.0,
        MAXELEVSMO: 28337.0,
        MINELEVSMO: 28333.0,
        SLOPE: 1.834e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1800000667572021,
        QA_MA: 1951.442,
        VA_MA: -9998.0,
        QC_MA: 1951.442,
        VC_MA: -9998.0,
        QE_MA: 2146.486,
        VE_MA: -9998.0,
        QA_01: 3140.506,
        VA_01: -9998.0,
        QC_01: 3140.506,
        VC_01: -9998.0,
        QE_01: 3345.781,
        VE_01: -9998.0,
        QA_02: 3326.996,
        VA_02: -9998.0,
        QC_02: 3326.996,
        VC_02: -9998.0,
        QE_02: 3398.818,
        VE_02: -9998.0,
        QA_03: 3765.163,
        VA_03: -9998.0,
        QC_03: 3765.163,
        VC_03: -9998.0,
        QE_03: 4029.283,
        VE_03: -9998.0,
        QA_04: 2880.652,
        VA_04: -9998.0,
        QC_04: 2880.652,
        VC_04: -9998.0,
        QE_04: 3072.778,
        VE_04: -9998.0,
        QA_05: 2205.83,
        VA_05: -9998.0,
        QC_05: 2205.83,
        VC_05: -9998.0,
        QE_05: 2427.952,
        VE_05: -9998.0,
        QA_06: 1283.602,
        VA_06: -9998.0,
        QC_06: 1283.602,
        VC_06: -9998.0,
        QE_06: 1543.215,
        VE_06: -9998.0,
        QA_07: 760.677,
        VA_07: -9998.0,
        QC_07: 760.677,
        VC_07: -9998.0,
        QE_07: 907.571,
        VE_07: -9998.0,
        QA_08: 538.366,
        VA_08: -9998.0,
        QC_08: 538.366,
        VC_08: -9998.0,
        QE_08: 837.982,
        VE_08: -9998.0,
        QA_09: 532.914,
        VA_09: -9998.0,
        QC_09: 999.345,
        VC_09: -9998.0,
        QE_09: 1035.852,
        VE_09: -9998.0,
        QA_10: 685.165,
        VA_10: -9998.0,
        QC_10: 1013.172,
        VC_10: -9998.0,
        QE_10: 973.67,
        VE_10: -9998.0,
        QA_11: 1831.141,
        VA_11: -9998.0,
        QC_11: 1831.141,
        VC_11: -9998.0,
        QE_11: 1808.341,
        VE_11: -9998.0,
        QA_12: 2479.118,
        VA_12: -9998.0,
        QC_12: 2479.118,
        VC_12: -9998.0,
        QE_12: 2502.831,
        VE_12: -9998.0,
        LakeFract: 0.09079186997,
        SurfArea: 1415444.329,
        RAreaHLoad: 0.26892325352000002,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.268274984295317, 35.804368677757793, 0.0],
          [-81.253840184317767, 35.80927527775026, 0.0],
          [-81.250663784322683, 35.815115277741199, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286911.0,
        COMID: 9733294,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 1.261,
        REACHCODE: "03050103000198",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01243992406806,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059003.0,
        ToNode: 250059018.0,
        Hydroseq: 250031138.0,
        LevelPathI: 250018600.0,
        Pathlength: 437.633,
        TerminalPa: 250002604.0,
        ArbolateSu: 86.565,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250032437.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029972.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.053,
        TotDASqKM: 111.9051,
        DivDASqKM: 111.9051,
        Tidal: 0,
        TOTMA: 0.051891670023500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15417.0,
        MAXELEVSMO: 15552.0,
        MINELEVSMO: 15506.0,
        SLOPE: 0.00036478,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2610000371932979,
        QA_MA: 46.927,
        VA_MA: 0.92133,
        QC_MA: 48.948,
        VC_MA: 0.92343,
        QE_MA: 48.296,
        VE_MA: 0.92276,
        QA_01: 101.434,
        VA_01: 1.23134,
        QC_01: 81.499,
        VC_01: 1.11465,
        QE_01: 82.56,
        VE_01: 1.12512,
        QA_02: 104.209,
        VA_02: 1.24466,
        QC_02: 89.45,
        VC_02: 1.15551,
        QE_02: 90.204,
        VE_02: 1.16444,
        QA_03: 111.39,
        VA_03: 1.27839,
        QC_03: 97.081,
        VC_03: 1.19315,
        QE_03: 96.832,
        VE_03: 1.19729,
        QA_04: 64.185,
        VA_04: 1.03223,
        QC_04: 61.106,
        VC_04: 1.00033,
        QE_04: 59.599,
        VE_04: 0.99534,
        QA_05: 36.049,
        VA_05: 0.84124,
        QC_05: 35.801,
        VC_05: 0.82931,
        QE_05: 34.393,
        VE_05: 0.82142,
        QA_06: 22.569,
        VA_06: 0.72393,
        QC_06: 28.855,
        VC_06: 0.77294,
        QE_06: 27.024,
        VE_06: 0.75976,
        QA_07: 16.55,
        VA_07: 0.66082,
        QC_07: 25.815,
        VC_07: 0.74627,
        QE_07: 25.282,
        VE_07: 0.74407,
        QA_08: 12.037,
        VA_08: 0.606,
        QC_08: 26.244,
        VC_08: 0.75012,
        QE_08: 26.088,
        VE_08: 0.75139,
        QA_09: 10.532,
        VA_09: 0.58562,
        QC_09: 31.271,
        VC_09: 0.7932,
        QE_09: 29.229,
        VE_09: 0.77896,
        QA_10: 7.969,
        VA_10: 0.54747,
        QC_10: 28.869,
        VC_10: 0.77306,
        QE_10: 30.061,
        VE_10: 0.78602,
        QA_11: 17.793,
        VA_11: 0.67464,
        QC_11: 34.348,
        VC_11: 0.81797,
        QE_11: 33.49,
        VE_11: 0.81422,
        QA_12: 58.702,
        VA_12: 0.99871,
        QC_12: 53.75,
        VC_12: 0.95476,
        QE_12: 51.785,
        VE_12: 0.94594,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.713319785156784, 34.96458181239467, 0.0],
          [-80.715238585153713, 34.963733012396062, 0.0],
          [-80.718413985148857, 34.966983079057684, 0.0],
          [-80.720553518478823, 34.964439012394962, 0.0],
          [-80.721667585143791, 34.965468812393283, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286912.0,
        COMID: 9733292,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 0.125,
        REACHCODE: "03050103000199",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001227612242805,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059001.0,
        ToNode: 250059003.0,
        Hydroseq: 250032437.0,
        LevelPathI: 250018600.0,
        Pathlength: 438.894,
        TerminalPa: 250002604.0,
        ArbolateSu: 85.182,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250033911.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031138.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 110.8521,
        DivDASqKM: 110.8521,
        Tidal: 0,
        TOTMA: 0.0035893178023199998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15552.0,
        MAXELEVSMO: 15660.0,
        MINELEVSMO: 15552.0,
        SLOPE: 0.00864,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.125,
        QA_MA: 46.491,
        VA_MA: 1.32003,
        QC_MA: 48.51,
        VC_MA: 1.32352,
        QE_MA: 47.865,
        VE_MA: 1.32242,
        QA_01: 100.495,
        VA_01: 1.8317,
        QC_01: 80.764,
        VC_01: 1.63908,
        QE_01: 81.816,
        VE_01: 1.65635,
        QA_02: 103.232,
        VA_02: 1.85359,
        QC_02: 88.634,
        VC_02: 1.70643,
        QE_02: 89.381,
        VE_02: 1.72115,
        QA_03: 110.343,
        VA_03: 1.90923,
        QC_03: 96.199,
        VC_03: 1.76857,
        QE_03: 95.952,
        VE_03: 1.77539,
        QA_04: 63.576,
        VA_04: 1.50293,
        QC_04: 60.54,
        VC_04: 1.45024,
        QE_04: 59.047,
        VE_04: 1.442,
        QA_05: 35.71,
        VA_05: 1.18778,
        QC_05: 35.476,
        VC_05: 1.16811,
        QE_05: 34.082,
        VE_05: 1.15509,
        QA_06: 22.356,
        VA_06: 0.99416,
        QC_06: 28.587,
        VC_06: 1.07499,
        QE_06: 26.773,
        VE_06: 1.05324,
        QA_07: 16.395,
        VA_07: 0.89004,
        QC_07: 25.578,
        VC_07: 1.03103,
        QE_07: 25.05,
        VE_07: 1.02739,
        QA_08: 11.923,
        VA_08: 0.79954,
        QC_08: 25.992,
        VC_08: 1.03722,
        QE_08: 25.837,
        VE_08: 1.03929,
        QA_09: 10.433,
        VA_09: 0.76593,
        QC_09: 31.011,
        VC_09: 1.10885,
        QE_09: 28.988,
        VE_09: 1.08535,
        QA_10: 7.898,
        VA_10: 0.70306,
        QC_10: 28.664,
        VC_10: 1.07609,
        QE_10: 29.845,
        VE_10: 1.09746,
        QA_11: 17.652,
        VA_11: 0.91331,
        QC_11: 34.141,
        VC_11: 1.15078,
        QE_11: 33.291,
        VE_11: 1.1446,
        QA_12: 58.164,
        VA_12: 1.44781,
        QC_12: 53.302,
        VC_12: 1.37559,
        QE_12: 51.356,
        VE_12: 1.36104,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.712595585157885, 34.963826479062618, 0.0],
          [-80.713319785156784, 34.96458181239467, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285863.0,
        COMID: 9753978,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.204,
        REACHCODE: "03050101002538",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023783923135629999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061463.0,
        ToNode: 250061464.0,
        Hydroseq: 250010242.0,
        LevelPathI: 250003258.0,
        Pathlength: 581.065,
        TerminalPa: 250002604.0,
        ArbolateSu: 3044.313,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010300.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010186.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.2102,
        TotDASqKM: 3367.2717,
        DivDASqKM: 3367.2717,
        Tidal: 0,
        TOTMA: 0.93989085908000003,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28328.0,
        MAXELEVSMO: 28333.0,
        MINELEVSMO: 28329.0,
        SLOPE: 1.814e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2039999961853032,
        QA_MA: 2041.192,
        VA_MA: -9998.0,
        QC_MA: 2041.192,
        VC_MA: -9998.0,
        QE_MA: 2236.236,
        VE_MA: -9998.0,
        QA_01: 3292.546,
        VA_01: -9998.0,
        QC_01: 3292.546,
        VC_01: -9998.0,
        QE_01: 3497.82,
        VE_01: -9998.0,
        QA_02: 3485.181,
        VA_02: -9998.0,
        QC_02: 3485.181,
        VC_02: -9998.0,
        QE_02: 3557.004,
        VE_02: -9998.0,
        QA_03: 3940.203,
        VA_03: -9998.0,
        QC_03: 3940.203,
        VC_03: -9998.0,
        QE_03: 4204.322,
        VE_03: -9998.0,
        QA_04: 3018.609,
        VA_04: -9998.0,
        QC_04: 3018.609,
        VC_04: -9998.0,
        QE_04: 3210.735,
        VE_04: -9998.0,
        QA_05: 2307.032,
        VA_05: -9998.0,
        QC_05: 2307.032,
        VC_05: -9998.0,
        QE_05: 2529.154,
        VE_05: -9998.0,
        QA_06: 1341.552,
        VA_06: -9998.0,
        QC_06: 1341.552,
        VC_06: -9998.0,
        QE_06: 1601.166,
        VE_06: -9998.0,
        QA_07: 794.649,
        VA_07: -9998.0,
        QC_07: 794.649,
        VC_07: -9998.0,
        QE_07: 941.543,
        VE_07: -9998.0,
        QA_08: 560.846,
        VA_08: -9998.0,
        QC_08: 560.846,
        VC_08: -9998.0,
        QE_08: 860.462,
        VE_08: -9998.0,
        QA_09: 551.197,
        VA_09: -9998.0,
        QC_09: 1029.556,
        VC_09: -9998.0,
        QE_09: 1066.062,
        VE_09: -9998.0,
        QA_10: 710.334,
        VA_10: -9998.0,
        QC_10: 1042.795,
        VC_10: -9998.0,
        QE_10: 1003.292,
        VE_10: -9998.0,
        QA_11: 1908.097,
        VA_11: -9998.0,
        QC_11: 1908.097,
        VC_11: -9998.0,
        QE_11: 1885.298,
        VE_11: -9998.0,
        QA_12: 2597.202,
        VA_12: -9998.0,
        QC_12: 2597.202,
        VC_12: -9998.0,
        QE_12: 2620.915,
        VE_12: -9998.0,
        LakeFract: 0.09562082025,
        SurfArea: 1490727.61473,
        RAreaHLoad: 0.27188387647000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.250663784322683, 35.815115277741199, 0.0],
          [-81.231024384353191, 35.812384677745342, 0.0],
          [-81.228574517690276, 35.810179211082186, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286913.0,
        COMID: 9733310,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 0.757,
        REACHCODE: "03050103000200",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0077925938166570003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059011.0,
        ToNode: 250059001.0,
        Hydroseq: 250033911.0,
        LevelPathI: 250018600.0,
        Pathlength: 439.019,
        TerminalPa: 250002604.0,
        ArbolateSu: 81.402,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250035576.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 250032438.0,
        DnDrainCou: 2,
        DnHydroseq: 250032437.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4284,
        TotDASqKM: 107.9073,
        DivDASqKM: 107.9073,
        Tidal: 0,
        TOTMA: 0.031651571042899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15578.0,
        MAXELEVSMO: 15685.0,
        MINELEVSMO: 15660.0,
        SLOPE: 0.00033025,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.75700002908706698,
        QA_MA: 45.268,
        VA_MA: 0.90671,
        QC_MA: 47.283,
        VC_MA: 0.90883,
        QE_MA: 46.654,
        VE_MA: 0.90818,
        QA_01: 97.857,
        VA_01: 1.20937,
        QC_01: 78.698,
        VC_01: 1.09536,
        QE_01: 79.722,
        VE_01: 1.10557,
        QA_02: 100.5,
        VA_02: 1.22221,
        QC_02: 86.351,
        VC_02: 1.13511,
        QE_02: 87.079,
        VE_02: 1.14383,
        QA_03: 107.413,
        VA_03: 1.25507,
        QC_03: 93.728,
        VC_03: 1.17189,
        QE_03: 93.487,
        VE_03: 1.17593,
        QA_04: 61.874,
        VA_04: 1.01471,
        QC_04: 58.955,
        VC_04: 0.98345,
        QE_04: 57.502,
        VE_04: 0.9786,
        QA_05: 34.759,
        VA_05: 0.82841,
        QC_05: 34.567,
        VC_05: 0.81682,
        QE_05: 33.209,
        VE_05: 0.80912,
        QA_06: 21.763,
        VA_06: 0.71393,
        QC_06: 27.838,
        VC_06: 0.7616,
        QE_06: 26.072,
        VE_06: 0.74875,
        QA_07: 15.959,
        VA_07: 0.65235,
        QC_07: 24.914,
        VC_07: 0.73567,
        QE_07: 24.401,
        VE_07: 0.73353,
        QA_08: 11.607,
        VA_08: 0.59884,
        QC_08: 25.288,
        VC_08: 0.73906,
        QE_08: 25.137,
        VE_08: 0.74029,
        QA_09: 10.156,
        VA_09: 0.57896,
        QC_09: 30.283,
        VC_09: 0.78232,
        QE_09: 28.313,
        VE_09: 0.76846,
        QA_10: 7.7,
        VA_10: 0.54197,
        QC_10: 28.089,
        VC_10: 0.76377,
        QE_10: 29.238,
        VE_10: 0.77638,
        QA_11: 17.243,
        VA_11: 0.66678,
        QC_11: 33.536,
        VC_11: 0.80871,
        QE_11: 32.709,
        VE_11: 0.8051,
        QA_12: 56.659,
        VA_12: 0.98245,
        QC_12: 52.045,
        VC_12: 0.94021,
        QE_12: 50.151,
        VE_12: 0.93164,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.706305118501007, 34.961059279066887, 0.0],
          [-80.710063718495178, 34.963828212395924, 0.0],
          [-80.712595585157885, 34.963826479062618, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285864.0,
        COMID: 9753980,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.779,
        REACHCODE: "03050101002539",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.028906042096538999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061464.0,
        ToNode: 250061454.0,
        Hydroseq: 250010186.0,
        LevelPathI: 250003258.0,
        Pathlength: 578.286,
        TerminalPa: 250002604.0,
        ArbolateSu: 3054.813,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010242.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010127.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.6123,
        TotDASqKM: 3384.8991,
        DivDASqKM: 3384.8991,
        Tidal: 0,
        TOTMA: 1.18509832003,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28322.0,
        MAXELEVSMO: 28329.0,
        MINELEVSMO: 28329.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.779000043869019,
        QA_MA: 2049.524,
        VA_MA: -9998.0,
        QC_MA: 2049.524,
        VC_MA: -9998.0,
        QE_MA: 2244.567,
        VE_MA: -9998.0,
        QA_01: 3307.96,
        VA_01: -9998.0,
        QC_01: 3307.96,
        VC_01: -9998.0,
        QE_01: 3513.235,
        VE_01: -9998.0,
        QA_02: 3501.74,
        VA_02: -9998.0,
        QC_02: 3501.74,
        VC_02: -9998.0,
        QE_02: 3573.562,
        VE_02: -9998.0,
        QA_03: 3958.341,
        VA_03: -9998.0,
        QC_03: 3958.341,
        VC_03: -9998.0,
        QE_03: 4222.461,
        VE_03: -9998.0,
        QA_04: 3031.732,
        VA_04: -9998.0,
        QC_04: 3031.732,
        VC_04: -9998.0,
        QE_04: 3223.858,
        VE_04: -9998.0,
        QA_05: 2315.52,
        VA_05: -9998.0,
        QC_05: 2315.52,
        VC_05: -9998.0,
        QE_05: 2537.642,
        VE_05: -9998.0,
        QA_06: 1346.496,
        VA_06: -9998.0,
        QC_06: 1346.496,
        VC_06: -9998.0,
        QE_06: 1606.109,
        VE_06: -9998.0,
        QA_07: 797.706,
        VA_07: -9998.0,
        QC_07: 797.706,
        VC_07: -9998.0,
        QE_07: 944.6,
        VE_07: -9998.0,
        QA_08: 562.866,
        VA_08: -9998.0,
        QC_08: 562.866,
        VC_08: -9998.0,
        QE_08: 862.482,
        VE_08: -9998.0,
        QA_09: 552.856,
        VA_09: -9998.0,
        QC_09: 1032.291,
        VC_09: -9998.0,
        QE_09: 1068.797,
        VE_09: -9998.0,
        QA_10: 711.519,
        VA_10: -9998.0,
        QC_10: 1044.185,
        VC_10: -9998.0,
        QE_10: 1004.682,
        VE_10: -9998.0,
        QA_11: 1912.87,
        VA_11: -9998.0,
        QC_11: 1912.87,
        VC_11: -9998.0,
        QE_11: 1890.07,
        VE_11: -9998.0,
        QA_12: 2607.958,
        VA_12: -9998.0,
        QC_12: 2607.958,
        VC_12: -9998.0,
        QE_12: 2631.671,
        VE_12: -9998.0,
        LakeFract: 0.12101552142,
        SurfArea: 1886630.7475699999,
        RAreaHLoad: 0.34281546856,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.228574517690276, 35.810179211082186, 0.0],
          [-81.224934384362655, 35.809722811082793, 0.0],
          [-81.216950717708357, 35.81672227773862, 0.0],
          [-81.204279984394702, 35.823404277728237, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286914.0,
        COMID: 9733312,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984508",
        GNIS_NAME: "East Fork Twelvemile Creek",
        LENGTHKM: 0.141,
        REACHCODE: "03050103000201",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0014468418339339999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059010.0,
        ToNode: 250059011.0,
        Hydroseq: 250035576.0,
        LevelPathI: 250018600.0,
        Pathlength: 439.776,
        TerminalPa: 250002604.0,
        ArbolateSu: 80.524,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250037492.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033911.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0171,
        TotDASqKM: 107.4789,
        DivDASqKM: 107.4789,
        Tidal: 0,
        TOTMA: 0.0052375606114899997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15685.0,
        MAXELEVSMO: 15699.0,
        MINELEVSMO: 15685.0,
        SLOPE: 0.0009929,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14100000262260401,
        QA_MA: 45.09,
        VA_MA: 1.02051,
        QC_MA: 47.104,
        VC_MA: 1.02304,
        QE_MA: 46.478,
        VE_MA: 1.02226,
        QA_01: 97.472,
        VA_01: 1.38073,
        QC_01: 78.397,
        VC_01: 1.24503,
        QE_01: 79.417,
        VE_01: 1.25717,
        QA_02: 100.102,
        VA_02: 1.396,
        QC_02: 86.019,
        VC_02: 1.29233,
        QE_02: 86.744,
        VE_02: 1.30269,
        QA_03: 106.987,
        VA_03: 1.4351,
        QC_03: 93.368,
        VC_03: 1.3361,
        QE_03: 93.128,
        VE_03: 1.3409,
        QA_04: 61.627,
        VA_04: 1.14902,
        QC_04: 58.725,
        VC_04: 1.11181,
        QE_04: 57.277,
        VE_04: 1.10602,
        QA_05: 34.621,
        VA_05: 0.9273,
        QC_05: 34.434,
        VC_05: 0.9135,
        QE_05: 33.083,
        VE_05: 0.90435,
        QA_06: 21.676,
        VA_06: 0.79105,
        QC_06: 27.729,
        VC_06: 0.84777,
        QE_06: 25.97,
        VE_06: 0.83247,
        QA_07: 15.896,
        VA_07: 0.71776,
        QC_07: 24.818,
        VC_07: 0.81692,
        QE_07: 24.306,
        VE_07: 0.81436,
        QA_08: 11.561,
        VA_08: 0.65408,
        QC_08: 25.186,
        VC_08: 0.82091,
        QE_08: 25.035,
        VE_08: 0.82236,
        QA_09: 10.116,
        VA_09: 0.63042,
        QC_09: 30.176,
        VC_09: 0.87254,
        QE_09: 28.215,
        VE_09: 0.85605,
        QA_10: 7.671,
        VA_10: 0.58642,
        QC_10: 28.005,
        VC_10: 0.85061,
        QE_10: 29.149,
        VE_10: 0.86561,
        QA_11: 17.186,
        VA_11: 0.73508,
        QC_11: 33.451,
        VC_11: 0.90427,
        QE_11: 32.627,
        VE_11: 0.89998,
        QA_12: 56.44,
        VA_12: 1.11068,
        QC_12: 51.862,
        VC_12: 1.0605,
        QE_12: 49.975,
        VE_12: 1.0503,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.705136785169486, 34.961380679066338, 0.0],
          [-80.706305118501007, 34.961059279066887, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286891.0,
        COMID: 9733916,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 3.843,
        REACHCODE: "03050103000178",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.038081865376111003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059210.0,
        ToNode: 250059209.0,
        Hydroseq: 250019191.0,
        LevelPathI: 250018600.0,
        Pathlength: 409.548,
        TerminalPa: 250002604.0,
        ArbolateSu: 304.595,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250019515.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018889.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.735,
        TotDASqKM: 383.922,
        DivDASqKM: 383.922,
        Tidal: 0,
        TOTMA: 0.12478005515600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13703.0,
        MAXELEVSMO: 14048.0,
        MINELEVSMO: 13842.0,
        SLOPE: 0.00053603,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.842999935150146,
        QA_MA: 159.033,
        VA_MA: 1.17251,
        QC_MA: 158.206,
        VC_MA: 1.17215,
        QE_MA: 152.303,
        VE_MA: 1.16949,
        QA_01: 343.184,
        VA_01: 1.6084,
        QC_01: 267.067,
        VC_01: 1.449,
        QE_01: 276.683,
        VE_01: 1.49069,
        QA_02: 355.497,
        VA_02: 1.63297,
        QC_02: 295.11,
        VC_02: 1.51111,
        QE_02: 301.945,
        VE_02: 1.54683,
        QA_03: 380.233,
        VA_03: 1.68113,
        QC_03: 318.229,
        VC_03: 1.56025,
        QE_03: 315.968,
        VE_03: 1.57705,
        QA_04: 222.365,
        VA_04: 1.34086,
        QC_04: 205.804,
        VC_04: 1.30161,
        QE_04: 192.15,
        VE_04: 1.28259,
        QA_05: 124.095,
        VA_05: 1.06586,
        QC_05: 117.67,
        VC_05: 1.04651,
        QE_05: 104.92,
        VE_05: 1.01489,
        QA_06: 77.429,
        VA_06: 0.89803,
        QC_06: 97.334,
        VC_06: 0.97576,
        QE_06: 80.743,
        VE_06: 0.92315,
        QA_07: 56.781,
        VA_07: 0.8085,
        QC_07: 85.992,
        VC_07: 0.93327,
        QE_07: 81.168,
        VE_07: 0.92487,
        QA_08: 41.295,
        VA_08: 0.73069,
        QC_08: 92.289,
        VC_08: 0.95717,
        QE_08: 90.87,
        VE_08: 0.96296,
        QA_09: 36.014,
        VA_09: 0.7011,
        QC_09: 92.591,
        VC_09: 0.95829,
        QE_09: 74.092,
        VE_09: 0.89572,
        QA_10: 26.255,
        VA_10: 0.64042,
        QC_10: 74.832,
        VC_10: 0.88881,
        QE_10: 85.63,
        VE_10: 0.94263,
        QA_11: 50.964,
        VA_11: 0.7806,
        QC_11: 76.637,
        VC_11: 0.8962,
        QE_11: 68.865,
        VE_11: 0.87334,
        QA_12: 195.398,
        VA_12: 1.27236,
        QC_12: 160.529,
        VC_12: 1.17886,
        QE_12: 142.732,
        VE_12: 1.14032,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.847588984948288, 34.8657034125481, 0.0],
          [-80.85350851827252, 34.864879879216119, 0.0],
          [-80.850813918276685, 34.8618330125542, 0.0],
          [-80.852287318274421, 34.859451079224527, 0.0],
          [-80.854593584937504, 34.859772212557402, 0.0],
          [-80.854066984938243, 34.854778612565212, 0.0],
          [-80.858290784931739, 34.853633812566954, 0.0],
          [-80.86140211826023, 34.858398479226253, 0.0],
          [-80.864959184921361, 34.855054612564629, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286892.0,
        COMID: 9733844,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.24,
        REACHCODE: "03050103000179",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011819740847868,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059192.0,
        ToNode: 250059210.0,
        Hydroseq: 250019515.0,
        LevelPathI: 250018600.0,
        Pathlength: 413.391,
        TerminalPa: 250002604.0,
        ArbolateSu: 298.584,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250019858.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019191.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9828,
        TotDASqKM: 378.2655,
        DivDASqKM: 378.2655,
        Tidal: 0,
        TOTMA: 0.061626220443099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13946.0,
        MAXELEVSMO: 14048.0,
        MINELEVSMO: 14048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2400000095367429,
        QA_MA: 156.768,
        VA_MA: 0.76565,
        QC_MA: 156.04,
        VC_MA: 0.76548,
        QE_MA: 150.136,
        VE_MA: 0.76406,
        QA_01: 338.24,
        VA_01: 0.99626,
        QC_01: 263.32,
        VC_01: 0.91188,
        QE_01: 272.936,
        VE_01: 0.93426,
        QA_02: 350.325,
        VA_02: 1.0092,
        QC_02: 290.933,
        VC_02: 0.9447,
        QE_02: 297.768,
        VE_02: 0.96387,
        QA_03: 374.709,
        VA_03: 1.0347,
        QC_03: 313.757,
        VC_03: 0.97074,
        QE_03: 311.496,
        VE_03: 0.97975,
        QA_04: 219.138,
        VA_04: 0.85466,
        QC_04: 202.885,
        VC_04: 0.83386,
        QE_04: 189.231,
        VE_04: 0.82364,
        QA_05: 122.27,
        VA_05: 0.70912,
        QC_05: 116.004,
        VC_05: 0.6989,
        QE_05: 103.253,
        VE_05: 0.68191,
        QA_06: 76.288,
        VA_06: 0.62033,
        QC_06: 95.92,
        VC_06: 0.6614,
        QE_06: 79.329,
        VE_06: 0.63313,
        QA_07: 55.944,
        VA_07: 0.57296,
        QC_07: 84.755,
        VC_07: 0.63895,
        QE_07: 79.932,
        VE_07: 0.63444,
        QA_08: 40.687,
        VA_08: 0.5318,
        QC_08: 90.902,
        VC_08: 0.65147,
        QE_08: 89.483,
        VE_08: 0.65457,
        QA_09: 35.552,
        VA_09: 0.51636,
        QC_09: 91.541,
        VC_09: 0.65275,
        QE_09: 73.041,
        VE_09: 0.61919,
        QA_10: 25.875,
        VA_10: 0.48407,
        QC_10: 73.965,
        VC_10: 0.61589,
        QE_10: 84.763,
        VE_10: 0.64475,
        QA_11: 50.551,
        VA_11: 0.5591,
        QC_11: 76.163,
        VC_11: 0.62071,
        QE_11: 68.391,
        VE_11: 0.60853,
        QA_12: 192.736,
        VA_12: 0.81866,
        QC_12: 158.538,
        VC_12: 0.76935,
        QE_12: 140.741,
        VE_12: 0.74869,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.848947784946233, 34.874843079200616, 0.0],
          [-80.847588984948288, 34.8657034125481, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286893.0,
        COMID: 9733786,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.69,
        REACHCODE: "03050103000180",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.007139234262035,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059173.0,
        ToNode: 250059192.0,
        Hydroseq: 250019858.0,
        LevelPathI: 250018600.0,
        Pathlength: 414.631,
        TerminalPa: 250002604.0,
        ArbolateSu: 295.605,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250020203.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019515.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5409,
        TotDASqKM: 375.4566,
        DivDASqKM: 375.4566,
        Tidal: 0,
        TOTMA: 0.034326602847200002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13961.0,
        MAXELEVSMO: 14048.0,
        MINELEVSMO: 14048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68999999761581399,
        QA_MA: 155.64,
        VA_MA: 0.76488,
        QC_MA: 154.96,
        VC_MA: 0.76472,
        QE_MA: 149.057,
        VE_MA: 0.76329,
        QA_01: 335.785,
        VA_01: 0.99507,
        QC_01: 261.458,
        VC_01: 0.91083,
        QE_01: 271.074,
        VE_01: 0.93332,
        QA_02: 347.757,
        VA_02: 1.00797,
        QC_02: 288.858,
        VC_02: 0.94357,
        QE_02: 295.694,
        VE_02: 0.96284,
        QA_03: 371.969,
        VA_03: 1.03342,
        QC_03: 311.538,
        VC_03: 0.96958,
        QE_03: 309.277,
        VE_03: 0.97864,
        QA_04: 217.514,
        VA_04: 0.85367,
        QC_04: 201.415,
        VC_04: 0.83289,
        QE_04: 187.761,
        VE_04: 0.82261,
        QA_05: 121.364,
        VA_05: 0.7084,
        QC_05: 115.176,
        VC_05: 0.6982,
        QE_05: 102.426,
        VE_05: 0.68112,
        QA_06: 75.721,
        VA_06: 0.61976,
        QC_06: 95.217,
        VC_06: 0.66074,
        QE_06: 78.626,
        VE_06: 0.63229,
        QA_07: 55.529,
        VA_07: 0.57248,
        QC_07: 84.14,
        VC_07: 0.63834,
        QE_07: 79.317,
        VE_07: 0.6338,
        QA_08: 40.385,
        VA_08: 0.5314,
        QC_08: 90.213,
        VC_08: 0.65078,
        QE_08: 88.794,
        VE_08: 0.65389,
        QA_09: 35.313,
        VA_09: 0.51606,
        QC_09: 90.997,
        VC_09: 0.65236,
        QE_09: 72.497,
        VE_09: 0.61863,
        QA_10: 25.686,
        VA_10: 0.48376,
        QC_10: 73.533,
        VC_10: 0.61556,
        QE_10: 84.331,
        VE_10: 0.64456,
        QA_11: 50.341,
        VA_11: 0.55908,
        QC_11: 75.922,
        VC_11: 0.62082,
        QE_11: 68.15,
        VE_11: 0.6086,
        QA_12: 191.408,
        VA_12: 0.81788,
        QC_12: 157.544,
        VC_12: 0.76874,
        QE_12: 139.747,
        VE_12: 0.74797,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.843304718288266, 34.87761341252974, 0.0],
          [-80.848947784946233, 34.874843079200616, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285876.0,
        COMID: 9754012,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.199,
        REACHCODE: "03050101002551",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.034386431648380997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061480.0,
        ToNode: 250061470.0,
        Hydroseq: 250010352.0,
        LevelPathI: 250003258.0,
        Pathlength: 585.449,
        TerminalPa: 250002604.0,
        ArbolateSu: 2886.644,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010408.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010300.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.8887,
        TotDASqKM: 3185.8272,
        DivDASqKM: 3185.8272,
        Tidal: 0,
        TOTMA: 1.36420637854,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28320.0,
        MAXELEVSMO: 28341.0,
        MINELEVSMO: 28337.0,
        SLOPE: 1.25e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.1989998817443852,
        QA_MA: 1941.018,
        VA_MA: -9998.0,
        QC_MA: 1941.018,
        VC_MA: -9998.0,
        QE_MA: 2136.061,
        VE_MA: -9998.0,
        QA_01: 3121.461,
        VA_01: -9998.0,
        QC_01: 3121.461,
        VC_01: -9998.0,
        QE_01: 3326.735,
        VE_01: -9998.0,
        QA_02: 3306.694,
        VA_02: -9998.0,
        QC_02: 3306.694,
        VC_02: -9998.0,
        QE_02: 3378.516,
        VE_02: -9998.0,
        QA_03: 3743.204,
        VA_03: -9998.0,
        QC_03: 3743.204,
        VC_03: -9998.0,
        QE_03: 4007.324,
        VE_03: -9998.0,
        QA_04: 2864.703,
        VA_04: -9998.0,
        QC_04: 2864.703,
        VC_04: -9998.0,
        QE_04: 3056.829,
        VE_04: -9998.0,
        QA_05: 2194.602,
        VA_05: -9998.0,
        QC_05: 2194.602,
        VC_05: -9998.0,
        QE_05: 2416.724,
        VE_05: -9998.0,
        QA_06: 1277.146,
        VA_06: -9998.0,
        QC_06: 1277.146,
        VC_06: -9998.0,
        QE_06: 1536.759,
        VE_06: -9998.0,
        QA_07: 756.801,
        VA_07: -9998.0,
        QC_07: 756.801,
        VC_07: -9998.0,
        QE_07: 903.695,
        VE_07: -9998.0,
        QA_08: 535.757,
        VA_08: -9998.0,
        QC_08: 535.757,
        VC_08: -9998.0,
        QE_08: 835.374,
        VE_08: -9998.0,
        QA_09: 530.869,
        VA_09: -9998.0,
        QC_09: 995.958,
        VC_09: -9998.0,
        QE_09: 1032.465,
        VE_09: -9998.0,
        QA_10: 683.624,
        VA_10: -9998.0,
        QC_10: 1011.352,
        VC_10: -9998.0,
        QE_10: 971.849,
        VE_10: -9998.0,
        QA_11: 1824.516,
        VA_11: -9998.0,
        QC_11: 1824.516,
        VC_11: -9998.0,
        QE_11: 1801.716,
        VE_11: -9998.0,
        QA_12: 2465.613,
        VA_12: -9998.0,
        QC_12: 2465.613,
        VC_12: -9998.0,
        QE_12: 2489.325,
        VE_12: -9998.0,
        LakeFract: 0.13258526577,
        SurfArea: 2067002.94426,
        RAreaHLoad: 0.39462637063,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.299751584246451, 35.798536877766992, 0.0],
          [-81.291352584259585, 35.79945487776547, 0.0],
          [-81.280371917609841, 35.806219277754963, 0.0],
          [-81.268274984295317, 35.804368677757793, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286894.0,
        COMID: 9733774,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.628,
        REACHCODE: "03050103000181",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0059855752550020003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059169.0,
        ToNode: 250059173.0,
        Hydroseq: 250020203.0,
        LevelPathI: 250018600.0,
        Pathlength: 415.321,
        TerminalPa: 250002604.0,
        ArbolateSu: 286.044,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250020561.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019858.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3096,
        TotDASqKM: 365.6817,
        DivDASqKM: 365.6817,
        Tidal: 0,
        TOTMA: 0.0187541560245,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13983.0,
        MAXELEVSMO: 14121.0,
        MINELEVSMO: 14048.0,
        SLOPE: 0.00116242,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.62800002098083496,
        QA_MA: 151.685,
        VA_MA: 1.27492,
        QC_MA: 151.174,
        VC_MA: 1.27466,
        QE_MA: 145.271,
        VE_MA: 1.27155,
        QA_01: 327.181,
        VA_01: 1.76213,
        QC_01: 254.932,
        VC_01: 1.58367,
        QE_01: 264.548,
        VE_01: 1.63252,
        QA_02: 338.82,
        VA_02: 1.78937,
        QC_02: 281.635,
        VC_02: 1.65295,
        QE_02: 288.47,
        VE_02: 1.6948,
        QA_03: 362.375,
        VA_03: 1.84318,
        QC_03: 303.765,
        VC_03: 1.70805,
        QE_03: 301.504,
        VE_03: 1.72772,
        QA_04: 211.914,
        VA_04: 1.46271,
        QC_04: 196.347,
        VC_04: 1.41856,
        QE_04: 182.693,
        VE_04: 1.3962,
        QA_05: 118.209,
        VA_05: 1.15506,
        QC_05: 112.293,
        VC_05: 1.13354,
        QE_05: 99.543,
        VE_05: 1.09639,
        QA_06: 73.75,
        VA_06: 0.96744,
        QC_06: 92.772,
        VC_06: 1.05397,
        QE_06: 76.181,
        VE_06: 0.99205,
        QA_07: 54.083,
        VA_07: 0.86736,
        QC_07: 82.002,
        VC_07: 1.00668,
        QE_07: 77.178,
        VE_07: 0.99678,
        QA_08: 39.333,
        VA_08: 0.7804,
        QC_08: 87.818,
        VC_08: 1.03256,
        QE_08: 86.399,
        VE_08: 1.0393,
        QA_09: 34.417,
        VA_09: 0.74809,
        QC_09: 88.955,
        VC_09: 1.03752,
        QE_09: 70.456,
        VE_09: 0.96427,
        QA_10: 25.029,
        VA_10: 0.67968,
        QC_10: 72.027,
        VC_10: 0.9602,
        QE_10: 82.824,
        VE_10: 1.02309,
        QA_11: 49.491,
        VA_11: 0.84164,
        QC_11: 74.942,
        VC_11: 0.97408,
        QE_11: 67.17,
        VE_11: 0.94784,
        QA_12: 186.721,
        VA_12: 1.38768,
        QC_12: 154.03,
        VC_12: 1.28431,
        QE_12: 136.233,
        VE_12: 1.23925,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.84327531828842, 34.88210287918929, 0.0],
          [-80.841775184957385, 34.879880612526165, 0.0],
          [-80.843304718288266, 34.87761341252974, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286887.0,
        COMID: 9736382,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.989,
        REACHCODE: "03050103000175",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.030858117915387999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059951.0,
        ToNode: 250059953.0,
        Hydroseq: 250005960.0,
        LevelPathI: 250003258.0,
        Pathlength: 404.979,
        TerminalPa: 250002604.0,
        ArbolateSu: 6784.251,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005979.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005942.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.7916,
        TotDASqKM: 9160.6635,
        DivDASqKM: 9160.6635,
        Tidal: 0,
        TOTMA: 0.088285218015500003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13083.0,
        MAXELEVSMO: 13651.0,
        MINELEVSMO: 13651.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9890000820159912,
        QA_MA: 4610.734,
        VA_MA: 1.27446,
        QC_MA: 4610.734,
        VC_MA: 1.27446,
        QE_MA: 5312.478,
        VE_MA: 1.28561,
        QA_01: 8382.931,
        VA_01: 1.63538,
        QC_01: 8382.931,
        VC_01: 1.63538,
        QE_01: 6947.916,
        VE_01: 1.43528,
        QA_02: 8865.734,
        VA_02: 1.67543,
        QC_02: 8865.734,
        VC_02: 1.67543,
        QE_02: 7054.962,
        VE_02: 1.44447,
        QA_03: 9745.347,
        VA_03: 1.74585,
        QC_03: 9745.347,
        VC_03: 1.74585,
        QE_03: 7510.243,
        VE_03: 1.48283,
        QA_04: 6769.066,
        VA_04: 1.49295,
        QC_04: 6769.066,
        VC_04: 1.49295,
        QE_04: 6547.984,
        VE_04: 1.40036,
        QA_05: 4659.095,
        VA_05: 1.27983,
        QC_05: 4659.095,
        VC_05: 1.27983,
        QE_05: 5644.678,
        VE_05: 1.31759,
        QA_06: 2742.467,
        VA_06: 1.04155,
        QC_06: 2742.467,
        VC_06: 1.04155,
        QE_06: 4981.046,
        VE_06: 1.25275,
        QA_07: 1694.983,
        VA_07: 0.87624,
        QC_07: 1694.983,
        VC_07: 0.87624,
        QE_07: 3786.251,
        VE_07: 1.12476,
        QA_08: 1204.615,
        VA_08: 0.78206,
        QC_08: 1204.615,
        VC_08: 0.78206,
        QE_08: 3541.973,
        VE_08: 1.09636,
        QA_09: 1105.387,
        VA_09: 0.76092,
        QC_09: 1105.387,
        VC_09: 0.76092,
        QE_09: 3249.394,
        VE_09: 1.0611,
        QA_10: 1202.426,
        VA_10: 0.7816,
        QC_10: 1202.426,
        VC_10: 0.7816,
        QE_10: 4445.111,
        VE_10: 1.19736,
        QA_11: 3128.324,
        VA_11: 1.09463,
        QC_11: 3128.324,
        VC_11: 1.09463,
        QE_11: 4504.504,
        VE_11: 1.20364,
        QA_12: 5859.862,
        VA_12: 1.40561,
        QC_12: 5859.862,
        VC_12: 1.40561,
        QE_12: 5568.722,
        VE_12: 1.31036,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.871168518245042, 34.843190212583067, 0.0],
          [-80.875855584904457, 34.837281612592278, 0.0],
          [-80.884492518224363, 34.832565879266269, 0.0],
          [-80.89685731820515, 34.828470012606033, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286888.0,
        COMID: 9736378,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.313,
        REACHCODE: "03050103000176",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012331625951334999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059949.0,
        ToNode: 250059951.0,
        Hydroseq: 250005979.0,
        LevelPathI: 250003258.0,
        Pathlength: 407.968,
        TerminalPa: 250002604.0,
        ArbolateSu: 6771.443,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005996.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005960.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9116,
        TotDASqKM: 9148.1211,
        DivDASqKM: 9148.1211,
        Tidal: 0,
        TOTMA: 0.021916048753399999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13517.0,
        MAXELEVSMO: 13758.0,
        MINELEVSMO: 13651.0,
        SLOPE: 0.00081492,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.312999963760376,
        QA_MA: 4605.723,
        VA_MA: 2.25262,
        QC_MA: 4605.723,
        VC_MA: 2.25262,
        QE_MA: 5303.421,
        VE_MA: 2.27496,
        QA_01: 8371.959,
        VA_01: 2.97867,
        QC_01: 8371.959,
        VC_01: 2.97867,
        QE_01: 6954.26,
        VE_01: 2.57933,
        QA_02: 8854.344,
        VA_02: 3.0593,
        QC_02: 8854.344,
        VC_02: 3.0593,
        QE_02: 7061.487,
        VE_02: 2.59785,
        QA_03: 9733.16,
        VA_03: 3.20106,
        QC_03: 9733.16,
        VC_03: 3.20106,
        QE_03: 7525.254,
        VE_03: 2.6765,
        QA_04: 6761.838,
        VA_04: 2.69236,
        QC_04: 6761.838,
        VC_04: 2.69236,
        QE_04: 6547.39,
        VE_04: 2.50778,
        QA_05: 4655.056,
        VA_05: 2.26365,
        QC_05: 4655.056,
        VC_05: 2.26365,
        QE_05: 5640.231,
        VE_05: 2.34032,
        QA_06: 2739.938,
        VA_06: 1.78402,
        QC_06: 2739.938,
        VC_06: 1.78402,
        QE_06: 4961.515,
        VE_06: 2.20659,
        QA_07: 1693.129,
        VA_07: 1.45119,
        QC_07: 1693.129,
        VC_07: 1.45119,
        QE_07: 3769.007,
        VE_07: 1.94878,
        QA_08: 1203.266,
        VA_08: 1.26165,
        QC_08: 1203.266,
        VC_08: 1.26165,
        QE_08: 3519.343,
        VE_08: 1.89015,
        QA_09: 1104.37,
        VA_09: 1.21921,
        QC_09: 1104.37,
        VC_09: 1.21921,
        QE_09: 3231.155,
        VE_09: 1.82,
        QA_10: 1201.582,
        VA_10: 1.26094,
        QC_10: 1201.582,
        VC_10: 1.26094,
        QE_10: 4415.984,
        VE_10: 2.09274,
        QA_11: 3127.445,
        VA_11: 1.89139,
        QC_11: 3127.445,
        VC_11: 1.89139,
        QE_11: 4488.964,
        VE_11: 2.10834,
        QA_12: 5853.969,
        VA_12: 2.51666,
        QC_12: 5853.969,
        VC_12: 2.51666,
        QE_12: 5570.824,
        VE_12: 2.327,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.867111318251375, 34.85352627923379, 0.0],
          [-80.867011318251571, 34.847266279243399, 0.0],
          [-80.871168518245042, 34.843190212583067, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286889.0,
        COMID: 9733912,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.148,
        REACHCODE: "03050103000177",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0014556467715619999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059209.0,
        ToNode: 250059947.0,
        Hydroseq: 250018889.0,
        LevelPathI: 250018600.0,
        Pathlength: 409.4,
        TerminalPa: 250002604.0,
        ArbolateSu: 306.204,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250019191.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018600.0,
        FromMeas: 44.74787,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0612,
        TotDASqKM: 384.9066,
        DivDASqKM: 384.9066,
        Tidal: 0,
        TOTMA: 0.0035973253362699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13703.0,
        MAXELEVSMO: 13842.0,
        MINELEVSMO: 13758.0,
        SLOPE: 0.00567567,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 159.428,
        VA_MA: 1.56666,
        QC_MA: 158.584,
        VC_MA: 1.56612,
        QE_MA: 152.681,
        VE_MA: 1.56226,
        QA_01: 344.044,
        VA_01: 2.2014,
        QC_01: 267.719,
        VC_01: 1.9693,
        QE_01: 277.335,
        VE_01: 2.02985,
        QA_02: 356.397,
        VA_02: 2.23719,
        QC_02: 295.837,
        VC_02: 2.05976,
        QE_02: 302.672,
        VE_02: 2.11165,
        QA_03: 381.199,
        VA_03: 2.30734,
        QC_03: 319.011,
        VC_03: 2.13132,
        QE_03: 316.75,
        VE_03: 2.15571,
        QA_04: 222.934,
        VA_04: 1.81185,
        QC_04: 206.319,
        VC_04: 1.75473,
        QE_04: 192.665,
        VE_04: 1.7271,
        QA_05: 124.413,
        VA_05: 1.41142,
        QC_05: 117.96,
        VC_05: 1.38322,
        QE_05: 105.21,
        VE_05: 1.3373,
        QA_06: 77.627,
        VA_06: 1.16703,
        QC_06: 97.581,
        VC_06: 1.28025,
        QE_06: 80.989,
        VE_06: 1.20383,
        QA_07: 56.927,
        VA_07: 1.03665,
        QC_07: 86.207,
        VC_07: 1.21835,
        QE_07: 81.384,
        VE_07: 1.20615,
        QA_08: 41.401,
        VA_08: 0.92334,
        QC_08: 92.53,
        VC_08: 1.2532,
        QE_08: 91.111,
        VE_08: 1.26161,
        QA_09: 36.094,
        VA_09: 0.88014,
        QC_09: 92.772,
        VC_09: 1.25451,
        QE_09: 74.272,
        VE_09: 1.1636,
        QA_10: 26.322,
        VA_10: 0.79189,
        QC_10: 74.983,
        VC_10: 1.15339,
        QE_10: 85.78,
        VE_10: 1.23158,
        QA_11: 51.039,
        VA_11: 0.99563,
        QC_11: 76.723,
        VC_11: 1.16374,
        QE_11: 68.951,
        VE_11: 1.1305,
        QA_12: 195.861,
        VA_12: 1.71197,
        QC_12: 160.875,
        VC_12: 1.57573,
        QE_12: 143.078,
        VE_12: 1.51975,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.864959184921361, 34.855054612564629, 0.0],
          [-80.865987518253007, 34.854069679232907, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286890.0,
        COMID: 9736374,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.119,
        REACHCODE: "03050103000177",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0012517491541489999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059947.0,
        ToNode: 250059949.0,
        Hydroseq: 250018600.0,
        LevelPathI: 250018600.0,
        Pathlength: 409.281,
        TerminalPa: 250002604.0,
        ArbolateSu: 306.323,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250018889.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005979.0,
        FromMeas: 0.0,
        ToMeas: 44.74787,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 384.9363,
        DivDASqKM: 384.9363,
        Tidal: 0,
        TOTMA: 0.0059001522988900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13758.0,
        MAXELEVSMO: 13758.0,
        MINELEVSMO: 13758.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.11900000274181401,
        QA_MA: 159.44,
        VA_MA: 0.76747,
        QC_MA: 158.595,
        VC_MA: 0.76728,
        QE_MA: 152.692,
        VE_MA: 0.76587,
        QA_01: 344.07,
        VA_01: 0.99906,
        QC_01: 267.739,
        VC_01: 0.91438,
        QE_01: 277.355,
        VE_01: 0.93647,
        QA_02: 356.424,
        VA_02: 1.01213,
        QC_02: 295.859,
        VC_02: 0.94738,
        QE_02: 302.694,
        VE_02: 0.96631,
        QA_03: 381.229,
        VA_03: 1.03772,
        QC_03: 319.035,
        VC_03: 0.97349,
        QE_03: 316.774,
        VE_03: 0.98239,
        QA_04: 222.951,
        VA_04: 0.85693,
        QC_04: 206.334,
        VC_04: 0.83609,
        QE_04: 192.68,
        VE_04: 0.82601,
        QA_05: 124.423,
        VA_05: 0.71083,
        QC_05: 117.969,
        VC_05: 0.70055,
        QE_05: 105.219,
        VE_05: 0.68379,
        QA_06: 77.633,
        VA_06: 0.62167,
        QC_06: 97.588,
        VC_06: 0.66297,
        QE_06: 80.997,
        VE_06: 0.6351,
        QA_07: 56.931,
        VA_07: 0.5741,
        QC_07: 86.213,
        VC_07: 0.64039,
        QE_07: 81.39,
        VE_07: 0.63594,
        QA_08: 41.404,
        VA_08: 0.53276,
        QC_08: 92.537,
        VC_08: 0.65311,
        QE_08: 91.118,
        VE_08: 0.65618,
        QA_09: 36.096,
        VA_09: 0.51699,
        QC_09: 92.777,
        VC_09: 0.65358,
        QE_09: 74.278,
        VE_09: 0.62042,
        QA_10: 26.324,
        VA_10: 0.4848,
        QC_10: 74.987,
        VC_10: 0.61669,
        QE_10: 85.785,
        VE_10: 0.64522,
        QA_11: 51.041,
        VA_11: 0.55913,
        QC_11: 76.725,
        VC_11: 0.62046,
        QE_11: 68.953,
        VE_11: 0.60833,
        QA_12: 195.875,
        VA_12: 0.82049,
        QC_12: 160.885,
        VC_12: 0.77078,
        QE_12: 143.089,
        VE_12: 0.75036,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865987518253007, 34.854069679232907, 0.0],
          [-80.867111318251375, 34.85352627923379, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286899.0,
        COMID: 9733624,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.955,
        REACHCODE: "03050103000186",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018925994492926999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059122.0,
        ToNode: 250059127.0,
        Hydroseq: 250022152.0,
        LevelPathI: 250018600.0,
        Pathlength: 420.208,
        TerminalPa: 250002604.0,
        ArbolateSu: 269.18,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250022622.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021713.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9737,
        TotDASqKM: 350.0532,
        DivDASqKM: 350.0532,
        Tidal: 0,
        TOTMA: 0.064035159850799994,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14277.0,
        MAXELEVSMO: 14454.0,
        MINELEVSMO: 14343.0,
        SLOPE: 0.00056777,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.955000042915344,
        QA_MA: 145.334,
        VA_MA: 1.1623,
        QC_MA: 145.084,
        VC_MA: 1.16218,
        QE_MA: 139.181,
        VE_MA: 1.15931,
        QA_01: 313.313,
        VA_01: 1.59237,
        QC_01: 244.405,
        VC_01: 1.43459,
        QE_01: 254.02,
        VE_01: 1.47959,
        QA_02: 324.532,
        VA_02: 1.61658,
        QC_02: 270.075,
        VC_02: 1.49593,
        QE_02: 276.91,
        VE_02: 1.5345,
        QA_03: 347.035,
        VA_03: 1.66399,
        QC_03: 291.322,
        VC_03: 1.54466,
        QE_03: 289.061,
        VE_03: 1.56278,
        QA_04: 202.879,
        VA_04: 1.32777,
        QC_04: 188.161,
        VC_04: 1.28857,
        QE_04: 174.507,
        VE_04: 1.2679,
        QA_05: 113.166,
        VA_05: 1.05607,
        QC_05: 107.678,
        VC_05: 1.03716,
        QE_05: 94.928,
        VE_05: 1.00285,
        QA_06: 70.598,
        VA_06: 0.89036,
        QC_06: 88.86,
        VC_06: 0.96649,
        QE_06: 72.269,
        VE_06: 0.90922,
        QA_07: 51.772,
        VA_07: 0.80199,
        QC_07: 78.579,
        VC_07: 0.92489,
        QE_07: 73.756,
        VE_07: 0.91575,
        QA_08: 37.652,
        VA_08: 0.7252,
        QC_08: 83.992,
        VC_08: 0.94709,
        QE_08: 82.573,
        VE_08: 0.95328,
        QA_09: 32.946,
        VA_09: 0.69667,
        QC_09: 85.59,
        VC_09: 0.95352,
        QE_09: 67.091,
        VE_09: 0.88596,
        QA_10: 23.978,
        VA_10: 0.63639,
        QC_10: 69.601,
        VC_10: 0.88643,
        QE_10: 80.399,
        VE_10: 0.94421,
        QA_11: 48.037,
        VA_11: 0.78275,
        QC_11: 73.257,
        VC_11: 0.90236,
        QE_11: 65.485,
        VE_11: 0.87858,
        QA_12: 179.156,
        VA_12: 1.26264,
        QC_12: 148.341,
        VC_12: 1.1723,
        QE_12: 130.545,
        VE_12: 1.13086,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.812146318336659, 34.91580807913715, 0.0],
          [-80.81709631832905, 34.915260812471217, 0.0],
          [-80.81679591832949, 34.907587279149766, 0.0],
          [-80.819301118325598, 34.903282279156485, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286900.0,
        COMID: 9733580,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.066,
        REACHCODE: "03050103000187",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000616150125025,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059110.0,
        ToNode: 250059122.0,
        Hydroseq: 250022622.0,
        LevelPathI: 250018600.0,
        Pathlength: 422.163,
        TerminalPa: 250002604.0,
        ArbolateSu: 248.602,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250023120.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022152.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 326.0925,
        DivDASqKM: 326.0925,
        Tidal: 0,
        TOTMA: 0.0033465938583500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14454.0,
        MAXELEVSMO: 14454.0,
        MINELEVSMO: 14454.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.065999999642372006,
        QA_MA: 135.573,
        VA_MA: 0.75044,
        QC_MA: 135.705,
        VC_MA: 0.75047,
        QE_MA: 129.802,
        VE_MA: 0.74888,
        QA_01: 292.091,
        VA_01: 0.97286,
        QC_01: 228.269,
        VC_01: 0.89107,
        QE_01: 237.885,
        VE_01: 0.91602,
        QA_02: 302.561,
        VA_02: 0.9854,
        QC_02: 252.273,
        VC_02: 0.92284,
        QE_02: 259.108,
        VE_02: 0.94423,
        QA_03: 323.518,
        VA_03: 1.00992,
        QC_03: 272.21,
        VC_03: 0.94817,
        QE_03: 269.949,
        VE_03: 0.95822,
        QA_04: 189.103,
        VA_04: 0.83588,
        QC_04: 175.665,
        VC_04: 0.81541,
        QE_04: 162.011,
        VE_04: 0.8039,
        QA_05: 105.443,
        VA_05: 0.69521,
        QC_05: 100.595,
        VC_05: 0.6855,
        QE_05: 87.844,
        VE_05: 0.6664,
        QA_06: 65.766,
        VA_06: 0.60944,
        QC_06: 82.859,
        VC_06: 0.64859,
        QE_06: 66.267,
        VE_06: 0.61663,
        QA_07: 48.228,
        VA_07: 0.56373,
        QC_07: 73.325,
        VC_07: 0.62721,
        QE_07: 68.501,
        VE_07: 0.62211,
        QA_08: 35.075,
        VA_08: 0.52401,
        QC_08: 78.132,
        VC_08: 0.63814,
        QE_08: 76.712,
        VE_08: 0.64155,
        QA_09: 30.691,
        VA_09: 0.50925,
        QC_09: 80.396,
        VC_09: 0.64318,
        QE_09: 61.897,
        VE_09: 0.60567,
        QA_10: 22.368,
        VA_10: 0.47819,
        QC_10: 65.84,
        VC_10: 0.60949,
        QE_10: 76.638,
        VE_10: 0.64138,
        QA_11: 45.578,
        VA_11: 0.55618,
        QC_11: 70.38,
        VC_11: 0.62034,
        QE_11: 62.608,
        VE_11: 0.60748,
        QA_12: 167.419,
        VA_12: 0.80284,
        QC_12: 139.473,
        VC_12: 0.75695,
        QE_12: 121.677,
        VE_12: 0.73402,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.811867785003813, 34.916357679136297, 0.0],
          [-80.812146318336659, 34.91580807913715, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286901.0,
        COMID: 9733574,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 3.925,
        REACHCODE: "03050103000188",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.037847659933025003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059108.0,
        ToNode: 250059110.0,
        Hydroseq: 250023120.0,
        LevelPathI: 250018600.0,
        Pathlength: 422.229,
        TerminalPa: 250002604.0,
        ArbolateSu: 179.954,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250023662.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022622.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8539,
        TotDASqKM: 222.8391,
        DivDASqKM: 222.8391,
        Tidal: 0,
        TOTMA: 0.147613885133,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14390.0,
        MAXELEVSMO: 14581.0,
        MINELEVSMO: 14454.0,
        SLOPE: 0.00032356,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.9249999523162842,
        QA_MA: 92.795,
        VA_MA: 1.01247,
        QC_MA: 94.262,
        VC_MA: 1.01337,
        QE_MA: 88.359,
        VE_MA: 1.00968,
        QA_01: 200.39,
        VA_01: 1.36804,
        QC_01: 158.159,
        VC_01: 1.23633,
        QE_01: 167.775,
        VE_01: 1.29415,
        QA_02: 206.87,
        VA_02: 1.3861,
        QC_02: 174.283,
        VC_02: 1.28542,
        QE_02: 181.118,
        VE_02: 1.33503,
        QA_03: 221.029,
        VA_03: 1.42466,
        QC_03: 188.329,
        VC_03: 1.32647,
        QE_03: 186.068,
        VE_03: 1.34983,
        QA_04: 128.412,
        VA_04: 1.14503,
        QC_04: 120.341,
        VC_04: 1.11099,
        QE_04: 106.687,
        VE_04: 1.08351,
        QA_05: 71.886,
        VA_05: 0.9232,
        QC_05: 69.57,
        VC_05: 0.90835,
        QE_05: 56.82,
        VE_05: 0.86311,
        QA_06: 44.942,
        VA_06: 0.78749,
        QC_06: 56.919,
        VC_06: 0.8477,
        QE_06: 40.328,
        VE_06: 0.77079,
        QA_07: 32.957,
        VA_07: 0.71473,
        QC_07: 50.566,
        VC_07: 0.81485,
        QE_07: 45.742,
        VE_07: 0.80277,
        QA_08: 23.969,
        VA_08: 0.65152,
        QC_08: 52.987,
        VC_08: 0.82759,
        QE_08: 51.567,
        VE_08: 0.83526,
        QA_09: 20.973,
        VA_09: 0.62803,
        QC_09: 57.445,
        VC_09: 0.85034,
        QE_09: 38.945,
        VE_09: 0.7623,
        QA_10: 15.426,
        VA_10: 0.57993,
        QC_10: 48.932,
        VC_10: 0.8061,
        QE_10: 59.73,
        VE_10: 0.87802,
        QA_11: 32.323,
        VA_11: 0.71057,
        QC_11: 54.154,
        VC_11: 0.83363,
        QE_11: 46.382,
        VE_11: 0.80644,
        QA_12: 115.029,
        VA_12: 1.09753,
        QC_12: 99.127,
        VC_12: 1.03246,
        QE_12: 81.33,
        VE_12: 0.97948,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.795195918362992, 34.932404679111301, 0.0],
          [-80.797448585026132, 34.93254361244442, 0.0],
          [-80.799484518356337, 34.926223012454159, 0.0],
          [-80.802902718351049, 34.9292256124495, 0.0],
          [-80.803545718350051, 34.92540081245545, 0.0],
          [-80.807105118344566, 34.92570067912169, 0.0],
          [-80.806277785012469, 34.917248079134822, 0.0],
          [-80.808724185008657, 34.918119812466898, 0.0],
          [-80.811867785003813, 34.916357679136297, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285916.0,
        COMID: 9754126,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.778,
        REACHCODE: "03050101002591",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019698386435651999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061536.0,
        ToNode: 250061531.0,
        Hydroseq: 250011883.0,
        LevelPathI: 250003258.0,
        Pathlength: 619.969,
        TerminalPa: 250002604.0,
        ArbolateSu: 2457.787,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011974.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011794.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6767,
        TotDASqKM: 2668.9077,
        DivDASqKM: 2668.9077,
        Tidal: 0,
        TOTMA: 0.6547773744,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30196.0,
        MAXELEVSMO: 30205.0,
        MINELEVSMO: 30201.0,
        SLOPE: 2.249e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7779999971389771,
        QA_MA: 1666.884,
        VA_MA: -9998.0,
        QC_MA: 1666.884,
        VC_MA: -9998.0,
        QE_MA: 1861.927,
        VE_MA: -9998.0,
        QA_01: 2632.956,
        VA_01: -9998.0,
        QC_01: 1942.435,
        VC_01: -9998.0,
        QE_01: 2147.71,
        VE_01: -9998.0,
        QA_02: 2788.511,
        VA_02: -9998.0,
        QC_02: 2788.511,
        VC_02: -9998.0,
        QE_02: 2860.333,
        VE_02: -9998.0,
        QA_03: 3184.377,
        VA_03: -9998.0,
        QC_03: 3184.377,
        VC_03: -9998.0,
        QE_03: 3448.496,
        VE_03: -9998.0,
        QA_04: 2450.353,
        VA_04: -9998.0,
        QC_04: 2450.353,
        VC_04: -9998.0,
        QE_04: 2642.479,
        VE_04: -9998.0,
        QA_05: 1899.518,
        VA_05: -9998.0,
        QC_05: 1899.518,
        VC_05: -9998.0,
        QE_05: 2121.64,
        VE_05: -9998.0,
        QA_06: 1109.476,
        VA_06: -9998.0,
        QC_06: 1109.476,
        VC_06: -9998.0,
        QE_06: 1369.089,
        VE_06: -9998.0,
        QA_07: 656.281,
        VA_07: -9998.0,
        QC_07: 656.281,
        VC_07: -9998.0,
        QE_07: 803.175,
        VE_07: -9998.0,
        QA_08: 468.489,
        VA_08: -9998.0,
        QC_08: 468.489,
        VC_08: -9998.0,
        QE_08: 768.105,
        VE_08: -9998.0,
        QA_09: 477.094,
        VA_09: -9998.0,
        QC_09: 906.338,
        VC_09: -9998.0,
        QE_09: 942.844,
        VE_09: -9998.0,
        QA_10: 635.474,
        VA_10: -9998.0,
        QC_10: 954.034,
        VC_10: -9998.0,
        QE_10: 914.532,
        VE_10: -9998.0,
        QA_11: 1607.024,
        VA_11: -9998.0,
        QC_11: 1607.024,
        VC_11: -9998.0,
        QE_11: 1584.224,
        VE_11: -9998.0,
        QA_12: 2103.957,
        VA_12: -9998.0,
        QC_12: 2103.957,
        VC_12: -9998.0,
        QE_12: 2127.67,
        VE_12: -9998.0,
        LakeFract: 0.08221320439,
        SurfArea: 722871.03612499998,
        RAreaHLoad: 0.15827504691,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.599241183781658, 35.783394277790364, 0.0],
          [-81.579815517145107, 35.78223867779213, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284899.0,
        COMID: 9752006,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.671,
        REACHCODE: "03050101001216",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0068304381374220003,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060930.0,
        ToNode: 250060938.0,
        Hydroseq: 250024856.0,
        LevelPathI: 250024856.0,
        Pathlength: 649.722,
        TerminalPa: 250002604.0,
        ArbolateSu: 180.969,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250025534.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024233.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2799,
        TotDASqKM: 177.5403,
        DivDASqKM: 0.2799,
        Tidal: 0,
        TOTMA: 0.050765419904199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31941.0,
        MAXELEVSMO: 32010.0,
        MINELEVSMO: 31941.0,
        SLOPE: 0.00132692,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.519999980926514,
        QA_MA: 0.147,
        VA_MA: 0.50191,
        QC_MA: 0.147,
        VC_MA: 0.50191,
        QE_MA: 0.147,
        VE_MA: 0.50191,
        QA_01: 0.255,
        VA_01: 0.56766,
        QC_01: 0.255,
        VC_01: 0.56766,
        QE_01: 0.255,
        VE_01: 0.56766,
        QA_02: 0.27,
        VA_02: 0.57565,
        QC_02: 0.27,
        VC_02: 0.57565,
        QE_02: 0.27,
        VE_02: 0.57565,
        QA_03: 0.301,
        VA_03: 0.59153,
        QC_03: 0.301,
        VC_03: 0.59153,
        QE_03: 0.301,
        VE_03: 0.59153,
        QA_04: 0.229,
        VA_04: 0.55327,
        QC_04: 0.229,
        VC_04: 0.55327,
        QE_04: 0.229,
        VE_04: 0.55327,
        QA_05: 0.165,
        VA_05: 0.51415,
        QC_05: 0.165,
        VC_05: 0.51415,
        QE_05: 0.165,
        VE_05: 0.51415,
        QA_06: 0.094,
        VA_06: 0.46101,
        QC_06: 0.094,
        VC_06: 0.46101,
        QE_06: 0.094,
        VE_06: 0.46101,
        QA_07: 0.056,
        VA_07: 0.42431,
        QC_07: 0.056,
        VC_07: 0.42431,
        QE_07: 0.056,
        VE_07: 0.42431,
        QA_08: 0.037,
        VA_08: 0.40142,
        QC_08: 0.037,
        VC_08: 0.40142,
        QE_08: 0.037,
        VE_08: 0.40142,
        QA_09: 0.03,
        VA_09: 0.39161,
        QC_09: 0.03,
        VC_09: 0.39161,
        QE_09: 0.03,
        VE_09: 0.39161,
        QA_10: 0.022,
        VA_10: 0.37898,
        QC_10: 0.022,
        VC_10: 0.37898,
        QE_10: 0.022,
        VE_10: 0.37898,
        QA_11: 0.113,
        VA_11: 0.47668,
        QC_11: 0.113,
        VC_11: 0.47668,
        QE_11: 0.113,
        VE_11: 0.47668,
        QA_12: 0.188,
        VA_12: 0.52892,
        QC_12: 0.188,
        VC_12: 0.52892,
        QE_12: 0.188,
        VE_12: 0.52892,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.719602383594861, 35.88887407762661, 0.0],
          [-81.716645116932753, 35.886861210963218, 0.0],
          [-81.714845916935474, 35.888488610960565, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286902.0,
        COMID: 9733478,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.548,
        REACHCODE: "03050103000189",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0058630346462410004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059074.0,
        ToNode: 250059108.0,
        Hydroseq: 250023662.0,
        LevelPathI: 250018600.0,
        Pathlength: 426.154,
        TerminalPa: 250002604.0,
        ArbolateSu: 174.071,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250024247.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023120.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2268,
        TotDASqKM: 218.2734,
        DivDASqKM: 218.2734,
        Tidal: 0,
        TOTMA: 0.015566067719299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14562.0,
        MAXELEVSMO: 14784.0,
        MINELEVSMO: 14581.0,
        SLOPE: 0.00370437,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.54799997806549094,
        QA_MA: 90.933,
        VA_MA: 1.34095,
        QC_MA: 92.444,
        VC_MA: 1.34233,
        QE_MA: 86.541,
        VE_MA: 1.33682,
        QA_01: 196.338,
        VA_01: 1.86227,
        QC_01: 155.044,
        VC_01: 1.66903,
        QE_01: 164.66,
        VE_01: 1.75555,
        QA_02: 202.695,
        VA_02: 1.88879,
        QC_02: 170.861,
        VC_02: 1.74106,
        QE_02: 177.696,
        VE_02: 1.8153,
        QA_03: 216.548,
        VA_03: 1.94525,
        QC_03: 184.635,
        VC_03: 1.80127,
        QE_03: 182.374,
        VE_03: 1.83623,
        QA_04: 125.772,
        VA_04: 1.53502,
        QC_04: 117.923,
        VC_04: 1.485,
        QE_04: 104.269,
        VE_04: 1.44379,
        QA_05: 70.412,
        VA_05: 1.20982,
        QC_05: 68.197,
        VC_05: 1.18811,
        QE_05: 55.447,
        VE_05: 1.1203,
        QA_06: 44.021,
        VA_06: 1.01084,
        QC_06: 55.769,
        VC_06: 1.09897,
        QE_06: 39.178,
        VE_06: 0.98356,
        QA_07: 32.282,
        VA_07: 0.90417,
        QC_07: 49.554,
        VC_07: 1.0509,
        QE_07: 44.731,
        VE_07: 1.0328,
        QA_08: 23.478,
        VA_08: 0.81149,
        QC_08: 51.88,
        VC_08: 1.0692,
        QE_08: 50.46,
        VE_08: 1.08067,
        QA_09: 20.543,
        VA_09: 0.77705,
        QC_09: 56.404,
        VC_09: 1.10374,
        QE_09: 37.905,
        VE_09: 0.97182,
        QA_10: 15.119,
        VA_10: 0.70664,
        QC_10: 48.153,
        VC_10: 1.03967,
        QE_10: 58.951,
        VE_10: 1.14715,
        QA_11: 31.842,
        VA_11: 0.89985,
        QC_11: 53.538,
        VC_11: 1.08202,
        QE_11: 45.766,
        VE_11: 1.04165,
        QA_12: 112.796,
        VA_12: 1.4661,
        QC_12: 97.374,
        VC_12: 1.37125,
        QE_12: 79.578,
        VE_12: 1.29201,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.789745718371478, 34.931347479113015, 0.0],
          [-80.795195918362992, 34.932404679111301, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285917.0,
        COMID: 9754130,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.065,
        REACHCODE: "03050101002592",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00069320614424700001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061538.0,
        ToNode: 250061534.0,
        Hydroseq: 250011632.0,
        LevelPathI: 250003258.0,
        Pathlength: 616.827,
        TerminalPa: 250002604.0,
        ArbolateSu: 2504.205,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011711.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011552.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0243,
        TotDASqKM: 2713.7241,
        DivDASqKM: 2713.7241,
        Tidal: 0,
        TOTMA: 0.02393730557,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30179.0,
        MAXELEVSMO: 30185.0,
        MINELEVSMO: 30179.0,
        SLOPE: 0.00092307,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064999997615814001,
        QA_MA: 1691.111,
        VA_MA: -9998.0,
        QC_MA: 1691.111,
        VC_MA: -9998.0,
        QE_MA: 1886.155,
        VE_MA: -9998.0,
        QA_01: 2677.037,
        VA_01: -9998.0,
        QC_01: 1974.096,
        VC_01: -9998.0,
        QE_01: 2179.371,
        VE_01: -9998.0,
        QA_02: 2835.35,
        VA_02: -9998.0,
        QC_02: 2835.35,
        VC_02: -9998.0,
        QE_02: 2907.173,
        VE_02: -9998.0,
        QA_03: 3233.916,
        VA_03: -9998.0,
        QC_03: 3233.916,
        VC_03: -9998.0,
        QE_03: 3498.036,
        VE_03: -9998.0,
        QA_04: 2486.645,
        VA_04: -9998.0,
        QC_04: 2486.645,
        VC_04: -9998.0,
        QE_04: 2678.771,
        VE_04: -9998.0,
        QA_05: 1924.719,
        VA_05: -9998.0,
        QC_05: 1924.719,
        VC_05: -9998.0,
        QE_05: 2146.841,
        VE_05: -9998.0,
        QA_06: 1123.79,
        VA_06: -9998.0,
        QC_06: 1123.79,
        VC_06: -9998.0,
        QE_06: 1383.403,
        VE_06: -9998.0,
        QA_07: 664.806,
        VA_07: -9998.0,
        QC_07: 664.806,
        VC_07: -9998.0,
        QE_07: 811.7,
        VE_07: -9998.0,
        QA_08: 474.187,
        VA_08: -9998.0,
        QC_08: 474.187,
        VC_08: -9998.0,
        QE_08: 773.803,
        VE_08: -9998.0,
        QA_09: 481.714,
        VA_09: -9998.0,
        QC_09: 914.082,
        VC_09: -9998.0,
        QE_09: 950.588,
        VE_09: -9998.0,
        QA_10: 639.798,
        VA_10: -9998.0,
        QC_10: 959.216,
        VC_10: -9998.0,
        QE_10: 919.713,
        VE_10: -9998.0,
        QA_11: 1626.31,
        VA_11: -9998.0,
        QC_11: 1626.31,
        VC_11: -9998.0,
        QE_11: 1603.51,
        VE_11: -9998.0,
        QA_12: 2136.144,
        VA_12: -9998.0,
        QC_12: 2136.144,
        VC_12: -9998.0,
        QE_12: 2159.857,
        VE_12: -9998.0,
        LakeFract: 0.00304455081,
        SurfArea: 26769.636518700001,
        RAreaHLoad: 0.00578620812,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.547754783861535, 35.776752411134062, 0.0],
          [-81.547112183862566, 35.777012411133576, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286895.0,
        COMID: 9733742,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.003,
        REACHCODE: "03050103000182",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0098689408096909997,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059154.0,
        ToNode: 250059169.0,
        Hydroseq: 250020561.0,
        LevelPathI: 250018600.0,
        Pathlength: 415.949,
        TerminalPa: 250002604.0,
        ArbolateSu: 283.58,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250020928.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020203.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4194,
        TotDASqKM: 364.0239,
        DivDASqKM: 364.0239,
        Tidal: 0,
        TOTMA: 0.0332948143554,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13973.0,
        MAXELEVSMO: 14169.0,
        MINELEVSMO: 14121.0,
        SLOPE: 0.00047856,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.003000020980835,
        QA_MA: 151.013,
        VA_MA: 1.14685,
        QC_MA: 150.529,
        VC_MA: 1.14664,
        QE_MA: 144.626,
        VE_MA: 1.14392,
        QA_01: 325.711,
        VA_01: 1.56947,
        QC_01: 253.817,
        VC_01: 1.41464,
        QE_01: 263.433,
        VE_01: 1.4572,
        QA_02: 337.305,
        VA_02: 1.59311,
        QC_02: 280.409,
        VC_02: 1.47475,
        QE_02: 287.244,
        VE_02: 1.51122,
        QA_03: 360.748,
        VA_03: 1.63977,
        QC_03: 302.446,
        VC_03: 1.52256,
        QE_03: 300.185,
        VE_03: 1.5397,
        QA_04: 210.956,
        VA_04: 1.30971,
        QC_04: 195.479,
        VC_04: 1.2714,
        QE_04: 181.825,
        VE_04: 1.25191,
        QA_05: 117.675,
        VA_05: 1.04284,
        QC_05: 111.804,
        VC_05: 1.02418,
        QE_05: 99.054,
        VE_05: 0.9918,
        QA_06: 73.416,
        VA_06: 0.88008,
        QC_06: 92.357,
        VC_06: 0.95511,
        QE_06: 75.766,
        VE_06: 0.90114,
        QA_07: 53.838,
        VA_07: 0.79327,
        QC_07: 81.639,
        VC_07: 0.91411,
        QE_07: 76.815,
        VE_07: 0.90548,
        QA_08: 39.155,
        VA_08: 0.71783,
        QC_08: 87.412,
        VC_08: 0.93649,
        QE_08: 85.993,
        VE_08: 0.94236,
        QA_09: 34.261,
        VA_09: 0.68981,
        QC_09: 88.599,
        VC_09: 0.941,
        QE_09: 70.1,
        VE_09: 0.87717,
        QA_10: 24.918,
        VA_10: 0.63048,
        QC_10: 71.77,
        VC_10: 0.87405,
        QE_10: 82.568,
        VE_10: 0.92883,
        QA_11: 49.349,
        VA_11: 0.77136,
        QC_11: 74.777,
        VC_11: 0.88652,
        QE_11: 67.005,
        VE_11: 0.8637,
        QA_12: 185.918,
        VA_12: 1.24474,
        QC_12: 153.427,
        VC_12: 1.15517,
        QE_12: 135.631,
        VE_12: 1.11592,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.842772784955798, 34.887623012514155, 0.0],
          [-80.843607984954474, 34.884324879185954, 0.0],
          [-80.844775384952754, 34.8844624791858, 0.0],
          [-80.84327531828842, 34.88210287918929, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286896.0,
        COMID: 9733716,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.47,
        REACHCODE: "03050103000183",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014782841305978,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059147.0,
        ToNode: 250059154.0,
        Hydroseq: 250020928.0,
        LevelPathI: 250018600.0,
        Pathlength: 416.952,
        TerminalPa: 250002604.0,
        ArbolateSu: 280.862,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250021311.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020561.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2176,
        TotDASqKM: 362.3643,
        DivDASqKM: 362.3643,
        Tidal: 0,
        TOTMA: 0.047327817936299997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14090.0,
        MAXELEVSMO: 14261.0,
        MINELEVSMO: 14169.0,
        SLOPE: 0.00062585,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.470000028610229,
        QA_MA: 150.339,
        VA_MA: 1.18249,
        QC_MA: 149.883,
        VC_MA: 1.18227,
        QE_MA: 143.98,
        VE_MA: 1.17943,
        QA_01: 324.238,
        VA_01: 1.62302,
        QC_01: 252.699,
        VC_01: 1.46161,
        QE_01: 262.315,
        VE_01: 1.50617,
        QA_02: 335.787,
        VA_02: 1.64768,
        QC_02: 279.182,
        VC_02: 1.52428,
        QE_02: 286.017,
        VE_02: 1.56247,
        QA_03: 359.119,
        VA_03: 1.69632,
        QC_03: 301.125,
        VC_03: 1.57413,
        QE_03: 298.864,
        VE_03: 1.59207,
        QA_04: 209.997,
        VA_04: 1.35222,
        QC_04: 194.61,
        VC_04: 1.31226,
        QE_04: 180.956,
        VE_04: 1.29184,
        QA_05: 117.139,
        VA_05: 1.07402,
        QC_05: 111.314,
        VC_05: 1.05458,
        QE_05: 98.564,
        VE_05: 1.02066,
        QA_06: 73.081,
        VA_06: 0.90434,
        QC_06: 91.942,
        VC_06: 0.98253,
        QE_06: 75.351,
        VE_06: 0.926,
        QA_07: 53.593,
        VA_07: 0.81385,
        QC_07: 81.275,
        VC_07: 0.9398,
        QE_07: 76.452,
        VE_07: 0.93077,
        QA_08: 38.976,
        VA_08: 0.73521,
        QC_08: 87.006,
        VC_08: 0.96307,
        QE_08: 85.587,
        VE_08: 0.96922,
        QA_09: 34.104,
        VA_09: 0.706,
        QC_09: 88.242,
        VC_09: 0.96799,
        QE_09: 69.743,
        VE_09: 0.90115,
        QA_10: 24.806,
        VA_10: 0.64416,
        QC_10: 71.514,
        VC_10: 0.89833,
        QE_10: 82.311,
        VE_10: 0.95566,
        QA_11: 49.196,
        VA_11: 0.79139,
        QC_11: 74.601,
        VC_11: 0.91172,
        QE_11: 66.829,
        VE_11: 0.88787,
        QA_12: 185.115,
        VA_12: 1.28461,
        QC_12: 152.824,
        VC_12: 1.19134,
        QE_12: 135.027,
        VE_12: 1.15024,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.834569518301919, 34.893576079171567, 0.0],
          [-80.83551551830044, 34.891973079174136, 0.0],
          [-80.837377984964178, 34.892362879173504, 0.0],
          [-80.836989584964726, 34.890301412509984, 0.0],
          [-80.838935518295102, 34.890508079176357, 0.0],
          [-80.840159718293194, 34.88814921251327, 0.0],
          [-80.842772784955798, 34.887623012514155, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286897.0,
        COMID: 9733676,
        FDATE: "2005/08/26 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 0.349,
        REACHCODE: "03050103000184",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0035543783414909999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059134.0,
        ToNode: 250059147.0,
        Hydroseq: 250021311.0,
        LevelPathI: 250018600.0,
        Pathlength: 418.422,
        TerminalPa: 250002604.0,
        ArbolateSu: 277.277,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250021713.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020928.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0864,
        TotDASqKM: 358.7868,
        DivDASqKM: 358.7868,
        Tidal: 0,
        TOTMA: 0.0174696380071,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14213.0,
        MAXELEVSMO: 14261.0,
        MINELEVSMO: 14261.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.34900000691413902,
        QA_MA: 148.884,
        VA_MA: 0.76018,
        QC_MA: 148.49,
        VC_MA: 0.76008,
        QE_MA: 142.586,
        VE_MA: 0.7586,
        QA_01: 321.063,
        VA_01: 0.98783,
        QC_01: 250.29,
        VC_01: 0.90439,
        QE_01: 259.906,
        VE_01: 0.92764,
        QA_02: 332.517,
        VA_02: 1.0006,
        QC_02: 276.536,
        VC_02: 0.9368,
        QE_02: 283.372,
        VE_02: 0.95673,
        QA_03: 355.607,
        VA_03: 1.02573,
        QC_03: 298.278,
        VC_03: 0.96257,
        QE_03: 296.017,
        VE_03: 0.97193,
        QA_04: 207.928,
        VA_04: 0.84786,
        QC_04: 192.736,
        VC_04: 0.82717,
        QE_04: 179.083,
        VE_04: 0.81652,
        QA_05: 115.985,
        VA_05: 0.70408,
        QC_05: 110.258,
        VC_05: 0.69404,
        QE_05: 97.508,
        VE_05: 0.67633,
        QA_06: 72.359,
        VA_06: 0.61639,
        QC_06: 91.047,
        VC_06: 0.65676,
        QE_06: 74.455,
        VE_06: 0.62723,
        QA_07: 53.064,
        VA_07: 0.56962,
        QC_07: 80.492,
        VC_07: 0.6347,
        QE_07: 75.669,
        VE_07: 0.62998,
        QA_08: 38.592,
        VA_08: 0.52898,
        QC_08: 86.13,
        VC_08: 0.64664,
        QE_08: 84.711,
        VE_08: 0.64985,
        QA_09: 33.768,
        VA_09: 0.51388,
        QC_09: 87.473,
        VC_09: 0.64943,
        QE_09: 68.973,
        VE_09: 0.61454,
        QA_10: 24.566,
        VA_10: 0.48194,
        QC_10: 70.959,
        VC_10: 0.61357,
        QE_10: 81.757,
        VE_10: 0.64347,
        QA_11: 48.859,
        VA_11: 0.55842,
        QC_11: 74.211,
        VC_11: 0.62092,
        QE_11: 66.439,
        VE_11: 0.60852,
        QA_12: 183.383,
        VA_12: 0.81305,
        QC_12: 151.523,
        VC_12: 0.76496,
        QE_12: 133.726,
        VE_12: 0.74352,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.831705184972918, 34.895590879168424, 0.0],
          [-80.834569518301919, 34.893576079171567, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285912.0,
        COMID: 9754116,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.353,
        REACHCODE: "03050101002587",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0039140696088930003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061531.0,
        ToNode: 250061543.0,
        Hydroseq: 250011794.0,
        LevelPathI: 250003258.0,
        Pathlength: 619.616,
        TerminalPa: 250002604.0,
        ArbolateSu: 2460.461,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011883.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011711.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.126,
        TotDASqKM: 2671.7427,
        DivDASqKM: 2671.7427,
        Tidal: 0,
        TOTMA: 0.12999798269000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30198.0,
        MAXELEVSMO: 30201.0,
        MINELEVSMO: 30198.0,
        SLOPE: 8.498e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.35299998521804798,
        QA_MA: 1668.344,
        VA_MA: -9998.0,
        QC_MA: 1668.344,
        VC_MA: -9998.0,
        QE_MA: 1863.388,
        VE_MA: -9998.0,
        QA_01: 2635.625,
        VA_01: -9998.0,
        QC_01: 1944.352,
        VC_01: -9998.0,
        QE_01: 2149.627,
        VE_01: -9998.0,
        QA_02: 2791.369,
        VA_02: -9998.0,
        QC_02: 2791.369,
        VC_02: -9998.0,
        QE_02: 2863.192,
        VE_02: -9998.0,
        QA_03: 3187.426,
        VA_03: -9998.0,
        QC_03: 3187.426,
        VC_03: -9998.0,
        QE_03: 3451.546,
        VE_03: -9998.0,
        QA_04: 2452.572,
        VA_04: -9998.0,
        QC_04: 2452.572,
        VC_04: -9998.0,
        QE_04: 2644.698,
        VE_04: -9998.0,
        QA_05: 1901.029,
        VA_05: -9998.0,
        QC_05: 1901.029,
        VC_05: -9998.0,
        QE_05: 2123.152,
        VE_05: -9998.0,
        QA_06: 1110.343,
        VA_06: -9998.0,
        QC_06: 1110.343,
        VC_06: -9998.0,
        QE_06: 1369.956,
        VE_06: -9998.0,
        QA_07: 656.805,
        VA_07: -9998.0,
        QC_07: 656.805,
        VC_07: -9998.0,
        QE_07: 803.699,
        VE_07: -9998.0,
        QA_08: 468.832,
        VA_08: -9998.0,
        QC_08: 468.832,
        VC_08: -9998.0,
        QE_08: 768.449,
        VE_08: -9998.0,
        QA_09: 477.375,
        VA_09: -9998.0,
        QC_09: 906.808,
        VC_09: -9998.0,
        QE_09: 943.314,
        VE_09: -9998.0,
        QA_10: 635.703,
        VA_10: -9998.0,
        QC_10: 954.309,
        VC_10: -9998.0,
        QE_10: 914.806,
        VE_10: -9998.0,
        QA_11: 1608.085,
        VA_11: -9998.0,
        QC_11: 1608.085,
        VC_11: -9998.0,
        QE_11: 1585.286,
        VE_11: -9998.0,
        QA_12: 2105.887,
        VA_12: -9998.0,
        QC_12: 2105.887,
        VC_12: -9998.0,
        QE_12: 2129.6,
        VE_12: -9998.0,
        LakeFract: 0.01633518491,
        SurfArea: 143629.38567799999,
        RAreaHLoad: 0.03142356106,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.579815517145107, 35.78223867779213, 0.0],
          [-81.575939183817809, 35.781696477793105, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286898.0,
        COMID: 9733654,
        FDATE: "2005/08/25 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996381",
        GNIS_NAME: "Twelvemile Creek",
        LENGTHKM: 1.437,
        REACHCODE: "03050103000185",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0147906506755,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250059127.0,
        ToNode: 250059134.0,
        Hydroseq: 250021713.0,
        LevelPathI: 250018600.0,
        Pathlength: 418.771,
        TerminalPa: 250002604.0,
        ArbolateSu: 273.066,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018600.0,
        UpHydroseq: 250022152.0,
        DnLevelPat: 250018600.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021311.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6082,
        TotDASqKM: 355.1733,
        DivDASqKM: 355.1733,
        Tidal: 0,
        TOTMA: 0.046926222448200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14191.0,
        MAXELEVSMO: 14343.0,
        MINELEVSMO: 14261.0,
        SLOPE: 0.00057063,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.437000036239624,
        QA_MA: 147.415,
        VA_MA: 1.16582,
        QC_MA: 147.081,
        VC_MA: 1.16566,
        QE_MA: 141.178,
        VE_MA: 1.16282,
        QA_01: 317.857,
        VA_01: 1.59778,
        QC_01: 247.855,
        VC_01: 1.43939,
        QE_01: 257.471,
        VE_01: 1.48395,
        QA_02: 329.213,
        VA_02: 1.62204,
        QC_02: 273.863,
        VC_02: 1.50093,
        QE_02: 280.699,
        VE_02: 1.53913,
        QA_03: 352.061,
        VA_03: 1.66969,
        QC_03: 295.4,
        VC_03: 1.54985,
        QE_03: 293.14,
        VE_03: 1.5678,
        QA_04: 205.839,
        VA_04: 1.33212,
        QC_04: 190.844,
        VC_04: 1.29282,
        QE_04: 177.19,
        VE_04: 1.27238,
        QA_05: 114.819,
        VA_05: 1.05927,
        QC_05: 109.191,
        VC_05: 1.04024,
        QE_05: 96.44,
        VE_05: 1.00629,
        QA_06: 71.631,
        VA_06: 0.89285,
        QC_06: 90.142,
        VC_06: 0.9694,
        QE_06: 73.551,
        VE_06: 0.91276,
        QA_07: 52.529,
        VA_07: 0.8041,
        QC_07: 79.701,
        VC_07: 0.92757,
        QE_07: 74.877,
        VE_07: 0.91853,
        QA_08: 38.203,
        VA_08: 0.72698,
        QC_08: 85.245,
        VC_08: 0.95008,
        QE_08: 83.826,
        VE_08: 0.95622,
        QA_09: 33.428,
        VA_09: 0.69833,
        QC_09: 86.695,
        VC_09: 0.95586,
        QE_09: 68.195,
        VE_09: 0.88898,
        QA_10: 24.323,
        VA_10: 0.63775,
        QC_10: 70.398,
        VC_10: 0.88808,
        QE_10: 81.196,
        VE_10: 0.94535,
        QA_11: 48.519,
        VA_11: 0.78364,
        QC_11: 73.817,
        VC_11: 0.90286,
        QE_11: 66.045,
        VE_11: 0.87919,
        QA_12: 181.634,
        VA_12: 1.26632,
        QC_12: 150.207,
        VC_12: 1.17529,
        QE_12: 132.411,
        VE_12: 1.13422,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.819301118325598, 34.903282279156485, 0.0],
          [-80.831705184972918, 34.895590879168424, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286939.0,
        COMID: 9733282,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.46,
        REACHCODE: "03050103000220",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0043592866529680004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058997.0,
        ToNode: 250059007.0,
        Hydroseq: 250021715.0,
        LevelPathI: 250019853.0,
        Pathlength: 424.319,
        TerminalPa: 250002604.0,
        ArbolateSu: 353.506,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250022153.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021312.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1854,
        TotDASqKM: 702.4257,
        DivDASqKM: 702.4257,
        Tidal: 0,
        TOTMA: 0.012392295005500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14587.0,
        MAXELEVSMO: 14629.0,
        MINELEVSMO: 14587.0,
        SLOPE: 0.00091304,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.46000000834464999,
        QA_MA: 279.349,
        VA_MA: 1.38533,
        QC_MA: 271.887,
        VC_MA: 1.38298,
        QE_MA: 367.56,
        VE_MA: 1.40954,
        QA_01: 591.803,
        VA_01: 1.9128,
        QC_01: 454.014,
        VC_01: 1.71925,
        QE_01: 588.406,
        VE_01: 1.72209,
        QA_02: 630.836,
        VA_02: 1.96815,
        QC_02: 515.555,
        VC_02: 1.81777,
        QE_02: 602.421,
        VE_02: 1.73987,
        QA_03: 679.442,
        VA_03: 2.03487,
        QC_03: 557.853,
        VC_03: 1.88231,
        QE_03: 648.774,
        VE_03: 1.79734,
        QA_04: 403.595,
        VA_04: 1.61771,
        QC_04: 368.51,
        VC_04: 1.57128,
        QE_04: 401.491,
        VE_04: 1.4624,
        QA_05: 226.775,
        VA_05: 1.27246,
        QC_05: 210.237,
        VC_05: 1.24582,
        QE_05: 287.647,
        VE_05: 1.27514,
        QA_06: 139.228,
        VA_06: 1.05245,
        QC_06: 173.616,
        VC_06: 1.15523,
        QE_06: 255.258,
        VE_06: 1.21572,
        QA_07: 96.619,
        VA_07: 0.92124,
        QC_07: 144.476,
        VC_07: 1.07652,
        QE_07: 257.88,
        VE_07: 1.22065,
        QA_08: 71.51,
        VA_08: 0.83073,
        QC_08: 161.581,
        VC_08: 1.12354,
        QE_08: 287.272,
        VE_08: 1.27447,
        QA_09: 61.404,
        VA_09: 0.79014,
        QC_09: 148.304,
        VC_09: 1.08726,
        QE_09: 233.674,
        VE_09: 1.17414,
        QA_10: 47.221,
        VA_10: 0.72743,
        QC_10: 119.598,
        VC_10: 1.00318,
        QE_10: 240.468,
        VE_10: 1.18741,
        QA_11: 71.518,
        VA_11: 0.83076,
        QC_11: 99.234,
        VC_11: 0.93761,
        QE_11: 237.135,
        VE_11: 1.18092,
        QA_12: 334.001,
        VA_12: 1.49252,
        QC_12: 261.449,
        VC_12: 1.36087,
        QE_12: 318.809,
        VE_12: 1.32941,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882530384894153, 34.965184479060383, 0.0],
          [-80.881807118228608, 34.966810879057846, 0.0],
          [-80.880749718230163, 34.964955679060836, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285914.0,
        COMID: 9754122,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.247,
        REACHCODE: "03050101002589",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013509344983949,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061534.0,
        ToNode: 250061527.0,
        Hydroseq: 250011552.0,
        LevelPathI: 250003258.0,
        Pathlength: 615.58,
        TerminalPa: 250002604.0,
        ArbolateSu: 2523.491,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011632.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011471.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1646,
        TotDASqKM: 2731.9167,
        DivDASqKM: 2731.9167,
        Tidal: 0,
        TOTMA: 0.45922800105,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30175.0,
        MAXELEVSMO: 30179.0,
        MINELEVSMO: 30179.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.246999979019165,
        QA_MA: 1700.617,
        VA_MA: -9998.0,
        QC_MA: 1700.617,
        VC_MA: -9998.0,
        QE_MA: 1895.66,
        VE_MA: -9998.0,
        QA_01: 2694.168,
        VA_01: -9998.0,
        QC_01: 1986.397,
        VC_01: -9998.0,
        QE_01: 2191.672,
        VE_01: -9998.0,
        QA_02: 2853.611,
        VA_02: -9998.0,
        QC_02: 2853.611,
        VC_02: -9998.0,
        QE_02: 2925.434,
        VE_02: -9998.0,
        QA_03: 3253.484,
        VA_03: -9998.0,
        QC_03: 3253.484,
        VC_03: -9998.0,
        QE_03: 3517.604,
        VE_03: -9998.0,
        QA_04: 2501.079,
        VA_04: -9998.0,
        QC_04: 2501.079,
        VC_04: -9998.0,
        QE_04: 2693.205,
        VE_04: -9998.0,
        QA_05: 1934.754,
        VA_05: -9998.0,
        QC_05: 1934.754,
        VC_05: -9998.0,
        QE_05: 2156.876,
        VE_05: -9998.0,
        QA_06: 1129.502,
        VA_06: -9998.0,
        QC_06: 1129.502,
        VC_06: -9998.0,
        QE_06: 1389.115,
        VE_06: -9998.0,
        QA_07: 668.253,
        VA_07: -9998.0,
        QC_07: 668.253,
        VC_07: -9998.0,
        QE_07: 815.147,
        VE_07: -9998.0,
        QA_08: 476.48,
        VA_08: -9998.0,
        QC_08: 476.48,
        VC_08: -9998.0,
        QE_08: 776.096,
        VE_08: -9998.0,
        QA_09: 483.646,
        VA_09: -9998.0,
        QC_09: 917.318,
        VC_09: -9998.0,
        QE_09: 953.824,
        VE_09: -9998.0,
        QA_10: 641.266,
        VA_10: -9998.0,
        QC_10: 960.974,
        VC_10: -9998.0,
        QE_10: 921.471,
        VE_10: -9998.0,
        QA_11: 1633.603,
        VA_11: -9998.0,
        QC_11: 1633.603,
        VC_11: -9998.0,
        QE_11: 1610.803,
        VE_11: -9998.0,
        QA_12: 2148.664,
        VA_12: -9998.0,
        QC_12: 2148.664,
        VC_12: -9998.0,
        QE_12: 2172.377,
        VE_12: -9998.0,
        LakeFract: 0.05870215601,
        SurfArea: 516146.87266599998,
        RAreaHLoad: 0.11100617744000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.547112183862566, 35.777012411133576, 0.0],
          [-81.541780583870832, 35.779204611130183, 0.0],
          [-81.534385983882316, 35.779788011129426, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286940.0,
        COMID: 9733280,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.239,
        REACHCODE: "03050103000221",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024896422887429998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058996.0,
        ToNode: 250058997.0,
        Hydroseq: 250022153.0,
        LevelPathI: 250019853.0,
        Pathlength: 424.779,
        TerminalPa: 250002604.0,
        ArbolateSu: 353.046,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250022623.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 250021714.0,
        DnDrainCou: 2,
        DnHydroseq: 250021715.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1107,
        TotDASqKM: 702.2403,
        DivDASqKM: 702.2403,
        Tidal: 0,
        TOTMA: 0.010733216815800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14629.0,
        MAXELEVSMO: 14629.0,
        MINELEVSMO: 14629.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.23899999260902399,
        QA_MA: 279.273,
        VA_MA: 0.83374,
        QC_MA: 271.816,
        VC_MA: 0.83259,
        QE_MA: 367.489,
        VE_MA: 0.84555,
        QA_01: 591.639,
        VA_01: 1.09105,
        QC_01: 453.891,
        VC_01: 0.99662,
        QE_01: 588.283,
        VE_01: 0.99801,
        QA_02: 630.664,
        VA_02: 1.11805,
        QC_02: 515.418,
        VC_02: 1.04469,
        QE_02: 602.284,
        VE_02: 1.00668,
        QA_03: 679.257,
        VA_03: 1.1506,
        QC_03: 557.707,
        VC_03: 1.07617,
        QE_03: 648.628,
        VE_03: 1.03472,
        QA_04: 403.485,
        VA_04: 0.94709,
        QC_04: 368.412,
        VC_04: 0.92444,
        QE_04: 401.393,
        VE_04: 0.87132,
        QA_05: 226.715,
        VA_05: 0.77868,
        QC_05: 210.184,
        VC_05: 0.76568,
        QE_05: 287.594,
        VE_05: 0.77999,
        QA_06: 139.19,
        VA_06: 0.67135,
        QC_06: 173.57,
        VC_06: 0.72149,
        QE_06: 255.212,
        VE_06: 0.751,
        QA_07: 96.592,
        VA_07: 0.60735,
        QC_07: 144.436,
        VC_07: 0.68309,
        QE_07: 257.84,
        VE_07: 0.75342,
        QA_08: 71.49,
        VA_08: 0.5632,
        QC_08: 161.535,
        VC_08: 0.70602,
        QE_08: 287.226,
        VE_08: 0.77967,
        QA_09: 61.386,
        VA_09: 0.54339,
        QC_09: 148.267,
        VC_09: 0.68833,
        QE_09: 233.637,
        VE_09: 0.73072,
        QA_10: 47.209,
        VA_10: 0.51281,
        QC_10: 119.573,
        VC_10: 0.64733,
        QE_10: 240.443,
        VE_10: 0.73721,
        QA_11: 71.498,
        VA_11: 0.56321,
        QC_11: 99.213,
        VC_11: 0.61534,
        QE_11: 237.114,
        VE_11: 0.73405,
        QA_12: 333.909,
        VA_12: 0.88602,
        QC_12: 261.384,
        VC_12: 0.82181,
        QE_12: 318.743,
        VE_12: 0.80645,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884700784890697, 34.966146479058807, 0.0],
          [-80.882530384894153, 34.965184479060383, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284898.0,
        COMID: 9751934,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.233,
        REACHCODE: "03050101001215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0022412001169919999,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060914.0,
        ToNode: 250060925.0,
        Hydroseq: 250026275.0,
        LevelPathI: 250026275.0,
        Pathlength: 651.407,
        TerminalPa: 250002604.0,
        ArbolateSu: 178.887,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024233.0,
        UpHydroseq: 250027073.0,
        DnLevelPat: 250024233.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025534.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7965,
        TotDASqKM: 175.6152,
        DivDASqKM: 0.7965,
        Tidal: 0,
        TOTMA: 0.013657130574799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32322.0,
        MAXELEVSMO: 32405.0,
        MINELEVSMO: 32322.0,
        SLOPE: 0.01,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.082999996840953993,
        QA_MA: 0.421,
        VA_MA: 0.64784,
        QC_MA: 0.421,
        VC_MA: 0.64784,
        QE_MA: 0.421,
        VE_MA: 0.64784,
        QA_01: 0.726,
        VA_01: 0.76174,
        QC_01: 0.726,
        VC_01: 0.76174,
        QE_01: 0.726,
        VE_01: 0.76174,
        QA_02: 0.76,
        VA_02: 0.77289,
        QC_02: 0.76,
        VC_02: 0.77289,
        QE_02: 0.76,
        VE_02: 0.77289,
        QA_03: 0.856,
        VA_03: 0.80318,
        QC_03: 0.856,
        VC_03: 0.80318,
        QE_03: 0.856,
        VE_03: 0.80318,
        QA_04: 0.657,
        VA_04: 0.73833,
        QC_04: 0.657,
        VC_04: 0.73833,
        QE_04: 0.657,
        VE_04: 0.73833,
        QA_05: 0.485,
        VA_05: 0.67433,
        QC_05: 0.485,
        VC_05: 0.67433,
        QE_05: 0.485,
        VE_05: 0.67433,
        QA_06: 0.267,
        VA_06: 0.57493,
        QC_06: 0.267,
        VC_06: 0.57493,
        QE_06: 0.267,
        VE_06: 0.57493,
        QA_07: 0.16,
        VA_07: 0.51147,
        QC_07: 0.16,
        VC_07: 0.51147,
        QE_07: 0.16,
        VE_07: 0.51147,
        QA_08: 0.107,
        VA_08: 0.4724,
        QC_08: 0.107,
        VC_08: 0.4724,
        QE_08: 0.107,
        VE_08: 0.4724,
        QA_09: 0.085,
        VA_09: 0.45353,
        QC_09: 0.085,
        VC_09: 0.45353,
        QE_09: 0.085,
        VE_09: 0.45353,
        QA_10: 0.064,
        VA_10: 0.43323,
        QC_10: 0.064,
        VC_10: 0.43323,
        QE_10: 0.064,
        VE_10: 0.43323,
        QA_11: 0.339,
        VA_11: 0.61095,
        QC_11: 0.339,
        VC_11: 0.61095,
        QE_11: 0.339,
        VE_11: 0.61095,
        QA_12: 0.548,
        VA_12: 0.69884,
        QC_12: 0.548,
        VC_12: 0.69884,
        QE_12: 0.548,
        VE_12: 0.69884,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.715758583600746, 35.898564077611582, 0.0],
          [-81.715672116934229, 35.89682367761435, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286941.0,
        COMID: 9733270,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.545,
        REACHCODE: "03050103000222",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014611678625274,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058991.0,
        ToNode: 250058996.0,
        Hydroseq: 250022623.0,
        LevelPathI: 250019853.0,
        Pathlength: 425.018,
        TerminalPa: 250002604.0,
        ArbolateSu: 348.892,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250023121.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022153.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8693,
        TotDASqKM: 696.9627,
        DivDASqKM: 696.9627,
        Tidal: 0,
        TOTMA: 0.045573946432099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14515.0,
        MAXELEVSMO: 14697.0,
        MINELEVSMO: 14629.0,
        SLOPE: 0.00044012,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.544999957084656,
        QA_MA: 277.082,
        VA_MA: 1.26561,
        QC_MA: 269.766,
        VC_MA: 1.26354,
        QE_MA: 365.439,
        VE_MA: 1.28731,
        QA_01: 586.948,
        VA_01: 1.73437,
        QC_01: 450.386,
        VC_01: 1.56231,
        QE_01: 584.778,
        VE_01: 1.5649,
        QA_02: 625.748,
        VA_02: 1.78367,
        QC_02: 511.51,
        VC_02: 1.64997,
        QE_02: 598.376,
        VE_02: 1.58033,
        QA_03: 673.999,
        VA_03: 1.84302,
        QC_03: 553.531,
        VC_03: 1.70741,
        QE_03: 644.452,
        VE_03: 1.63143,
        QA_04: 400.345,
        VA_04: 1.47219,
        QC_04: 365.609,
        VC_04: 1.43087,
        QE_04: 398.591,
        VE_04: 1.33351,
        QA_05: 224.959,
        VA_05: 1.16534,
        QC_05: 208.617,
        VC_05: 1.14167,
        QE_05: 286.027,
        VE_05: 1.16789,
        QA_06: 138.126,
        VA_06: 0.9698,
        QC_06: 172.261,
        VC_06: 1.06111,
        QE_06: 253.903,
        VE_06: 1.1152,
        QA_07: 95.811,
        VA_07: 0.85304,
        QC_07: 143.296,
        VC_07: 0.991,
        QE_07: 256.701,
        VE_07: 1.11991,
        QA_08: 70.923,
        VA_08: 0.77264,
        QC_08: 160.227,
        VC_08: 1.03271,
        QE_08: 285.918,
        VE_08: 1.16772,
        QA_09: 60.89,
        VA_09: 0.73652,
        QC_09: 147.207,
        VC_09: 1.00084,
        QE_09: 232.577,
        VE_09: 1.07848,
        QA_10: 46.854,
        VA_10: 0.68091,
        QC_10: 118.854,
        VC_10: 0.92647,
        QE_10: 239.725,
        VE_10: 1.09096,
        QA_11: 70.859,
        VA_11: 0.77242,
        QC_11: 98.536,
        VC_11: 0.86791,
        QE_11: 236.437,
        VE_11: 1.08524,
        QA_12: 331.241,
        VA_12: 1.3608,
        QC_12: 259.483,
        VC_12: 1.24403,
        QE_12: 316.842,
        VE_12: 1.21588,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886121184888566, 34.977988412373804, 0.0],
          [-80.882447384894249, 34.970911012384931, 0.0],
          [-80.884700784890697, 34.966146479058807, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286942.0,
        COMID: 9733214,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.271,
        REACHCODE: "03050103000223",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011934457961725001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058972.0,
        ToNode: 250058991.0,
        Hydroseq: 250023121.0,
        LevelPathI: 250019853.0,
        Pathlength: 426.563,
        TerminalPa: 250002604.0,
        ArbolateSu: 343.005,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250023663.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022623.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8739,
        TotDASqKM: 691.4574,
        DivDASqKM: 691.4574,
        Tidal: 0,
        TOTMA: 0.031792109769899997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14673.0,
        MAXELEVSMO: 14911.0,
        MINELEVSMO: 14697.0,
        SLOPE: 0.00168371,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.271000027656555,
        QA_MA: 274.792,
        VA_MA: 1.49104,
        QC_MA: 267.622,
        VC_MA: 1.48851,
        QE_MA: 363.296,
        VE_MA: 1.51809,
        QA_01: 582.041,
        VA_01: 2.0701,
        QC_01: 446.72,
        VC_01: 1.85749,
        QE_01: 581.112,
        VE_01: 1.86078,
        QA_02: 620.61,
        VA_02: 2.13116,
        QC_02: 507.424,
        VC_02: 1.96593,
        QE_02: 594.29,
        VE_02: 1.87938,
        QA_03: 668.5,
        VA_03: 2.20454,
        QC_03: 549.164,
        VC_03: 2.03699,
        QE_03: 640.085,
        VE_03: 1.94254,
        QA_04: 397.067,
        VA_04: 1.74633,
        QC_04: 362.684,
        VC_04: 1.69522,
        QE_04: 395.665,
        VE_04: 1.57418,
        QA_05: 223.14,
        VA_05: 1.36725,
        QC_05: 206.993,
        VC_05: 1.33803,
        QE_05: 284.402,
        VE_05: 1.37062,
        QA_06: 137.012,
        VA_06: 1.12566,
        QC_06: 170.891,
        VC_06: 1.2384,
        QE_06: 252.532,
        VE_06: 1.30565,
        QA_07: 94.997,
        VA_07: 0.98122,
        QC_07: 142.108,
        VC_07: 1.15163,
        QE_07: 255.512,
        VE_07: 1.31189,
        QA_08: 70.33,
        VA_08: 0.88195,
        QC_08: 158.863,
        VC_08: 1.20305,
        QE_08: 284.553,
        VE_08: 1.37092,
        QA_09: 60.371,
        VA_09: 0.83728,
        QC_09: 146.101,
        VC_09: 1.16413,
        QE_09: 231.47,
        VE_09: 1.26059,
        QA_10: 46.484,
        VA_10: 0.76873,
        QC_10: 118.104,
        VC_10: 1.07273,
        QE_10: 238.974,
        VE_10: 1.27686,
        QA_11: 70.201,
        VA_11: 0.88139,
        QC_11: 97.838,
        VC_11: 1.00002,
        QE_11: 235.739,
        VE_11: 1.26987,
        QA_12: 328.453,
        VA_12: 1.60854,
        QC_12: 257.495,
        VC_12: 1.46458,
        QE_12: 314.854,
        VE_12: 1.42957,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888655118217969, 34.98758541235896, 0.0],
          [-80.88923918488365, 34.985203279029406, 0.0],
          [-80.887597118219617, 34.984768212363349, 0.0],
          [-80.886121184888566, 34.977988412373804, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286935.0,
        COMID: 9736238,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.202,
        REACHCODE: "03050103000216",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0019785511213639998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059882.0,
        ToNode: 250059889.0,
        Hydroseq: 250019853.0,
        LevelPathI: 250019853.0,
        Pathlength: 420.637,
        TerminalPa: 250002604.0,
        ArbolateSu: 366.411,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250020204.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006334.0,
        FromMeas: 0.0,
        ToMeas: 71.35484,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0216,
        TotDASqKM: 713.4606,
        DivDASqKM: 713.4606,
        Tidal: 0,
        TOTMA: 0.0062236163463000004,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14249.0,
        MAXELEVSMO: 14424.0,
        MINELEVSMO: 14418.0,
        SLOPE: 0.00029702,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.202000007033348,
        QA_MA: 283.889,
        VA_MA: 1.2125,
        QC_MA: 276.133,
        VC_MA: 1.21048,
        QE_MA: 371.806,
        VE_MA: 1.23248,
        QA_01: 601.595,
        VA_01: 1.65552,
        QC_01: 461.328,
        VC_01: 1.49308,
        QE_01: 595.72,
        VE_01: 1.49531,
        QA_02: 641.07,
        VA_02: 1.70176,
        QC_02: 523.689,
        VC_02: 1.57558,
        QE_02: 610.556,
        VE_02: 1.51092,
        QA_03: 690.39,
        VA_03: 1.75769,
        QC_03: 566.543,
        VC_03: 1.62963,
        QE_03: 657.464,
        VE_03: 1.55912,
        QA_04: 410.1,
        VA_04: 1.40751,
        QC_04: 374.314,
        VC_04: 1.36863,
        QE_04: 407.295,
        VE_04: 1.27831,
        QA_05: 230.349,
        VA_05: 1.11754,
        QC_05: 213.427,
        VC_05: 1.09516,
        QE_05: 290.836,
        VE_05: 1.11949,
        QA_06: 141.453,
        VA_06: 0.93295,
        QC_06: 176.353,
        VC_06: 1.01935,
        QE_06: 257.994,
        VE_06: 1.06949,
        QA_07: 98.251,
        VA_07: 0.82306,
        QC_07: 146.857,
        VC_07: 0.9535,
        QE_07: 260.262,
        VE_07: 1.07303,
        QA_08: 72.697,
        VA_08: 0.74699,
        QC_08: 164.317,
        VC_08: 0.99315,
        QE_08: 290.008,
        VE_08: 1.11826,
        QA_09: 62.442,
        VA_09: 0.71296,
        QC_09: 150.517,
        VC_09: 0.96199,
        QE_09: 235.886,
        VE_09: 1.03413,
        QA_10: 47.963,
        VA_10: 0.66008,
        QC_10: 121.096,
        VC_10: 0.8907,
        QE_10: 241.967,
        VE_10: 1.04401,
        QA_11: 72.703,
        VA_11: 0.747,
        QC_11: 100.486,
        VC_11: 0.83578,
        QE_11: 238.387,
        VE_11: 1.03821,
        QA_12: 339.492,
        VA_12: 1.30258,
        QC_12: 265.357,
        VC_12: 1.19161,
        QE_12: 322.716,
        VE_12: 1.16555,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.869176518248196, 34.945073812424937, 0.0],
          [-80.870355918246275, 34.94371027909375, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285926.0,
        COMID: 9754168,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.598,
        REACHCODE: "03050101002601",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.016354256044108999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061556.0,
        ToNode: 250061544.0,
        Hydroseq: 250012151.0,
        LevelPathI: 250003258.0,
        Pathlength: 625.326,
        TerminalPa: 250002604.0,
        ArbolateSu: 2200.56,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012245.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012062.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.636,
        TotDASqKM: 2378.1483,
        DivDASqKM: 2378.1483,
        Tidal: 0,
        TOTMA: 0.58848945122999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30206.0,
        MAXELEVSMO: 30210.0,
        MINELEVSMO: 30210.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.598000049591064,
        QA_MA: 1512.207,
        VA_MA: -9998.0,
        QC_MA: 1512.207,
        VC_MA: -9998.0,
        QE_MA: 1707.251,
        VE_MA: -9998.0,
        QA_01: 2363.067,
        VA_01: -9998.0,
        QC_01: 1748.275,
        VC_01: -9998.0,
        QE_01: 1953.55,
        VE_01: -9998.0,
        QA_02: 2504.18,
        VA_02: -9998.0,
        QC_02: 1971.076,
        VC_02: -9998.0,
        QE_02: 2042.899,
        VE_02: -9998.0,
        QA_03: 2873.624,
        VA_03: -9998.0,
        QC_03: 2873.624,
        VC_03: -9998.0,
        QE_03: 3137.744,
        VE_03: -9998.0,
        QA_04: 2212.734,
        VA_04: -9998.0,
        QC_04: 2212.734,
        VC_04: -9998.0,
        QE_04: 2404.86,
        VE_04: -9998.0,
        QA_05: 1729.871,
        VA_05: -9998.0,
        QC_05: 1729.871,
        VC_05: -9998.0,
        QE_05: 1951.993,
        VE_05: -9998.0,
        QA_06: 1013.902,
        VA_06: -9998.0,
        QC_06: 1013.902,
        VC_06: -9998.0,
        QE_06: 1273.515,
        VE_06: -9998.0,
        QA_07: 598.492,
        VA_07: -9998.0,
        QC_07: 598.492,
        VC_07: -9998.0,
        QE_07: 745.386,
        VE_07: -9998.0,
        QA_08: 429.604,
        VA_08: -9998.0,
        QC_08: 1006.198,
        VC_08: -9998.0,
        QE_08: 1305.814,
        VE_08: -9998.0,
        QA_09: 446.226,
        VA_09: -9998.0,
        QC_09: 854.363,
        VC_09: -9998.0,
        QE_09: 890.869,
        VE_09: -9998.0,
        QA_10: 604.89,
        VA_10: -9998.0,
        QC_10: 917.175,
        VC_10: -9998.0,
        QE_10: 877.672,
        VE_10: -9998.0,
        QA_11: 1479.2,
        VA_11: -9998.0,
        QC_11: 1479.2,
        VC_11: -9998.0,
        QE_11: 1456.401,
        VE_11: -9998.0,
        QA_12: 1900.741,
        VA_12: -9998.0,
        QC_12: 1900.741,
        VC_12: -9998.0,
        QE_12: 1924.454,
        VE_12: -9998.0,
        LakeFract: 0.06776773411000001,
        SurfArea: 595857.22920599999,
        RAreaHLoad: 0.14225170133000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.647382517040114, 35.777979477798681, 0.0],
          [-81.641314783716325, 35.779448211129932, 0.0],
          [-81.634581717060101, 35.786352077785693, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286936.0,
        COMID: 9736032,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.878,
        REACHCODE: "03050103000217",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01773581623401,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059816.0,
        ToNode: 250059041.0,
        Hydroseq: 250020556.0,
        LevelPathI: 250019853.0,
        Pathlength: 420.92,
        TerminalPa: 250002604.0,
        ArbolateSu: 364.363,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250020929.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020204.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0241,
        TotDASqKM: 712.1709,
        DivDASqKM: 712.1709,
        Tidal: 0,
        TOTMA: 0.084182528537300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14273.0,
        MAXELEVSMO: 14541.0,
        MINELEVSMO: 14541.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.878000020980835,
        QA_MA: 283.362,
        VA_MA: 0.83546,
        QC_MA: 275.64,
        VC_MA: 0.83428,
        QE_MA: 371.313,
        VE_MA: 0.84712,
        QA_01: 600.451,
        VA_01: 1.09371,
        QC_01: 460.473,
        VC_01: 0.999,
        QE_01: 594.865,
        VE_01: 1.00032,
        QA_02: 639.876,
        VA_02: 1.12068,
        QC_02: 522.741,
        VC_02: 1.04712,
        QE_02: 609.607,
        VE_02: 1.00937,
        QA_03: 689.114,
        VA_03: 1.15329,
        QC_03: 565.531,
        VC_03: 1.07864,
        QE_03: 656.452,
        VE_03: 1.03748,
        QA_04: 409.341,
        VA_04: 0.94914,
        QC_04: 373.636,
        VC_04: 0.92647,
        QE_04: 406.617,
        VE_04: 0.87374,
        QA_05: 229.933,
        VA_05: 0.78011,
        QC_05: 213.055,
        VC_05: 0.76706,
        QE_05: 290.465,
        VE_05: 0.78126,
        QA_06: 141.193,
        VA_06: 0.67249,
        QC_06: 176.033,
        VC_06: 0.72285,
        QE_06: 257.675,
        VE_06: 0.75212,
        QA_07: 98.061,
        VA_07: 0.60841,
        QC_07: 146.579,
        VC_07: 0.68445,
        QE_07: 259.983,
        VE_07: 0.75423,
        QA_08: 72.558,
        VA_08: 0.56407,
        QC_08: 163.997,
        VC_08: 0.70755,
        QE_08: 289.688,
        VE_08: 0.78059,
        QA_09: 62.321,
        VA_09: 0.54423,
        QC_09: 150.258,
        VC_09: 0.68943,
        QE_09: 235.628,
        VE_09: 0.73154,
        QA_10: 47.876,
        VA_10: 0.51342,
        QC_10: 120.921,
        VC_10: 0.64792,
        QE_10: 241.792,
        VE_10: 0.73738,
        QA_11: 72.572,
        VA_11: 0.5641,
        QC_11: 100.347,
        VC_11: 0.61591,
        QE_11: 238.248,
        VE_11: 0.73403,
        QA_12: 338.854,
        VA_12: 0.88796,
        QC_12: 264.903,
        VC_12: 0.8233,
        QE_12: 322.262,
        VE_12: 0.80808,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872625384909497, 34.960374812401312, 0.0],
          [-80.873821784907591, 34.95680147907342, 0.0],
          [-80.86864751824902, 34.951304079082036, 0.0],
          [-80.8693713182479, 34.945784012423871, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285919.0,
        COMID: 9754142,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.724,
        REACHCODE: "03050101002594",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.029661334354473001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061543.0,
        ToNode: 250061538.0,
        Hydroseq: 250011711.0,
        LevelPathI: 250003258.0,
        Pathlength: 616.892,
        TerminalPa: 250002604.0,
        ArbolateSu: 2476.981,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011794.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011632.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8421,
        TotDASqKM: 2690.226,
        DivDASqKM: 2690.226,
        Tidal: 0,
        TOTMA: 1.00315723732,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30150.0,
        MAXELEVSMO: 30198.0,
        MINELEVSMO: 30185.0,
        SLOPE: 4.772e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.723999977111816,
        QA_MA: 1678.055,
        VA_MA: -9998.0,
        QC_MA: 1678.055,
        VC_MA: -9998.0,
        QE_MA: 1873.099,
        VE_MA: -9998.0,
        QA_01: 2653.511,
        VA_01: -9998.0,
        QC_01: 1957.2,
        VC_01: -9998.0,
        QE_01: 2162.475,
        VE_01: -9998.0,
        QA_02: 2810.477,
        VA_02: -9998.0,
        QC_02: 2810.477,
        VC_02: -9998.0,
        QE_02: 2882.299,
        VE_02: -9998.0,
        QA_03: 3207.657,
        VA_03: -9998.0,
        QC_03: 3207.657,
        VC_03: -9998.0,
        QE_03: 3471.776,
        VE_03: -9998.0,
        QA_04: 2467.161,
        VA_04: -9998.0,
        QC_04: 2467.161,
        VC_04: -9998.0,
        QE_04: 2659.287,
        VE_04: -9998.0,
        QA_05: 1911.009,
        VA_05: -9998.0,
        QC_05: 1911.009,
        VC_05: -9998.0,
        QE_05: 2133.131,
        VE_05: -9998.0,
        QA_06: 1116.068,
        VA_06: -9998.0,
        QC_06: 1116.068,
        VC_06: -9998.0,
        QE_06: 1375.682,
        VE_06: -9998.0,
        QA_07: 660.224,
        VA_07: -9998.0,
        QC_07: 660.224,
        VC_07: -9998.0,
        QE_07: 807.118,
        VE_07: -9998.0,
        QA_08: 471.091,
        VA_08: -9998.0,
        QC_08: 471.091,
        VC_08: -9998.0,
        QE_08: 770.707,
        VE_08: -9998.0,
        QA_09: 479.199,
        VA_09: -9998.0,
        QC_09: 909.866,
        VC_09: -9998.0,
        QE_09: 946.372,
        VE_09: -9998.0,
        QA_10: 637.194,
        VA_10: -9998.0,
        QC_10: 956.096,
        VC_10: -9998.0,
        QE_10: 916.594,
        VE_10: -9998.0,
        QA_11: 1615.326,
        VA_11: -9998.0,
        QC_11: 1615.326,
        VC_11: -9998.0,
        QE_11: 1592.526,
        VE_11: -9998.0,
        QA_12: 2118.717,
        VA_12: -9998.0,
        QC_12: 2118.717,
        VC_12: -9998.0,
        QE_12: 2142.429,
        VE_12: -9998.0,
        LakeFract: 0.12670917533000001,
        SurfArea: 1114108.0503700001,
        RAreaHLoad: 0.24248662981,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.575939183817809, 35.781696477793105, 0.0],
          [-81.564603983835354, 35.78118947779376, 0.0],
          [-81.554455783851097, 35.776693077800815, 0.0],
          [-81.547754783861535, 35.776752411134062, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286937.0,
        COMID: 9733322,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.595,
        REACHCODE: "03050103000218",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0054315710586539997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059016.0,
        ToNode: 250059816.0,
        Hydroseq: 250020929.0,
        LevelPathI: 250019853.0,
        Pathlength: 422.798,
        TerminalPa: 250002604.0,
        ArbolateSu: 359.499,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250021312.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020556.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2205,
        TotDASqKM: 707.3262,
        DivDASqKM: 707.3262,
        Tidal: 0,
        TOTMA: 0.0266951991724,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14444.0,
        MAXELEVSMO: 14541.0,
        MINELEVSMO: 14541.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59500002861022905,
        QA_MA: 281.372,
        VA_MA: 0.83462,
        QC_MA: 273.78,
        VC_MA: 0.83346,
        QE_MA: 369.453,
        VE_MA: 0.84636,
        QA_01: 596.152,
        VA_01: 1.09241,
        QC_01: 457.262,
        VC_01: 0.99784,
        QE_01: 591.654,
        VE_01: 0.99919,
        QA_02: 635.382,
        VA_02: 1.11939,
        QC_02: 519.169,
        VC_02: 1.04593,
        QE_02: 606.035,
        VE_02: 1.00806,
        QA_03: 684.318,
        VA_03: 1.15198,
        QC_03: 561.724,
        VC_03: 1.07744,
        QE_03: 652.645,
        VE_03: 1.03614,
        QA_04: 406.494,
        VA_04: 0.94815,
        QC_04: 371.097,
        VC_04: 0.92548,
        QE_04: 404.078,
        VE_04: 0.87257,
        QA_05: 228.37,
        VA_05: 0.77942,
        QC_05: 211.661,
        VC_05: 0.76639,
        QE_05: 289.07,
        VE_05: 0.78064,
        QA_06: 140.216,
        VA_06: 0.67193,
        QC_06: 174.832,
        VC_06: 0.72219,
        QE_06: 256.473,
        VE_06: 0.75157,
        QA_07: 97.344,
        VA_07: 0.60789,
        QC_07: 145.533,
        VC_07: 0.68378,
        QE_07: 258.938,
        VE_07: 0.75383,
        QA_08: 72.037,
        VA_08: 0.56364,
        QC_08: 162.796,
        VC_08: 0.70681,
        QE_08: 288.487,
        VE_08: 0.78014,
        QA_09: 61.865,
        VA_09: 0.54382,
        QC_09: 149.287,
        VC_09: 0.68889,
        QE_09: 234.657,
        VE_09: 0.73114,
        QA_10: 47.551,
        VA_10: 0.51312,
        QC_10: 120.264,
        VC_10: 0.64763,
        QE_10: 241.134,
        VE_10: 0.7373,
        QA_11: 72.062,
        VA_11: 0.56369,
        QC_11: 99.809,
        VC_11: 0.61566,
        QE_11: 237.71,
        VE_11: 0.73405,
        QA_12: 336.454,
        VA_12: 0.88703,
        QC_12: 263.196,
        VC_12: 0.82258,
        QE_12: 320.555,
        VE_12: 0.80729,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874044184907291, 34.965459812393419, 0.0],
          [-80.872625384909497, 34.960374812401312, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285920.0,
        COMID: 9754144,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.23,
        REACHCODE: "03050101002595",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013355294529048999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061544.0,
        ToNode: 250061546.0,
        Hydroseq: 250012062.0,
        LevelPathI: 250003258.0,
        Pathlength: 624.096,
        TerminalPa: 250002604.0,
        ArbolateSu: 2433.735,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012151.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011974.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0061,
        TotDASqKM: 2634.8067,
        DivDASqKM: 2634.8067,
        Tidal: 0,
        TOTMA: 0.45296747498000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30204.0,
        MAXELEVSMO: 30210.0,
        MINELEVSMO: 30207.0,
        SLOPE: 2.439e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2300000190734861,
        QA_MA: 1649.144,
        VA_MA: -9998.0,
        QC_MA: 1649.144,
        VC_MA: -9998.0,
        QE_MA: 1844.187,
        VE_MA: -9998.0,
        QA_01: 2600.787,
        VA_01: -9998.0,
        QC_01: 1919.321,
        VC_01: -9998.0,
        QE_01: 2124.596,
        VE_01: -9998.0,
        QA_02: 2754.19,
        VA_02: -9998.0,
        QC_02: 2754.19,
        VC_02: -9998.0,
        QE_02: 2826.013,
        VE_02: -9998.0,
        QA_03: 3147.659,
        VA_03: -9998.0,
        QC_03: 3147.659,
        VC_03: -9998.0,
        QE_03: 3411.778,
        VE_03: -9998.0,
        QA_04: 2423.359,
        VA_04: -9998.0,
        QC_04: 2423.359,
        VC_04: -9998.0,
        QE_04: 2615.484,
        VE_04: -9998.0,
        QA_05: 1880.897,
        VA_05: -9998.0,
        QC_05: 1880.897,
        VC_05: -9998.0,
        QE_05: 2103.019,
        VE_05: -9998.0,
        QA_06: 1098.88,
        VA_06: -9998.0,
        QC_06: 1098.88,
        VC_06: -9998.0,
        QE_06: 1358.493,
        VE_06: -9998.0,
        QA_07: 649.949,
        VA_07: -9998.0,
        QC_07: 649.949,
        VC_07: -9998.0,
        QE_07: 796.843,
        VE_07: -9998.0,
        QA_08: 464.192,
        VA_08: -9998.0,
        QC_08: 464.192,
        VC_08: -9998.0,
        QE_08: 763.808,
        VE_08: -9998.0,
        QA_09: 473.638,
        VA_09: -9998.0,
        QC_09: 900.537,
        VC_09: -9998.0,
        QE_09: 937.044,
        VE_09: -9998.0,
        QA_10: 632.723,
        VA_10: -9998.0,
        QC_10: 950.734,
        VC_10: -9998.0,
        QE_10: 911.231,
        VE_10: -9998.0,
        QA_11: 1593.635,
        VA_11: -9998.0,
        QC_11: 1593.635,
        VC_11: -9998.0,
        QE_11: 1570.835,
        VE_11: -9998.0,
        QA_12: 2080.601,
        VA_12: -9998.0,
        QC_12: 2080.601,
        VC_12: -9998.0,
        QE_12: 2104.314,
        VE_12: -9998.0,
        LakeFract: 0.05633367156,
        SurfArea: 495321.64366399997,
        RAreaHLoad: 0.10949286147,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.634581717060101, 35.786352077785693, 0.0],
          [-81.626600583739219, 35.786463077785641, 0.0],
          [-81.621932783746445, 35.788739677782189, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286938.0,
        COMID: 9733302,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.926,
        REACHCODE: "03050103000219",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0092876664824899993,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059007.0,
        ToNode: 250059016.0,
        Hydroseq: 250021312.0,
        LevelPathI: 250019853.0,
        Pathlength: 423.393,
        TerminalPa: 250002604.0,
        ArbolateSu: 354.595,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250021715.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020929.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5535,
        TotDASqKM: 702.9873,
        DivDASqKM: 702.9873,
        Tidal: 0,
        TOTMA: 0.02688013152,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14442.0,
        MAXELEVSMO: 14587.0,
        MINELEVSMO: 14541.0,
        SLOPE: 0.00049676,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.92599999904632602,
        QA_MA: 279.58,
        VA_MA: 1.28618,
        QC_MA: 272.103,
        VC_MA: 1.28403,
        QE_MA: 367.776,
        VE_MA: 1.30813,
        QA_01: 592.301,
        VA_01: 1.76508,
        QC_01: 454.386,
        VC_01: 1.58936,
        QE_01: 588.778,
        VE_01: 1.59193,
        QA_02: 631.357,
        VA_02: 1.81532,
        QC_02: 515.969,
        VC_02: 1.6788,
        QE_02: 602.835,
        VE_02: 1.60811,
        QA_03: 680.001,
        VA_03: 1.8759,
        QC_03: 558.297,
        VC_03: 1.73739,
        QE_03: 649.218,
        VE_03: 1.66029,
        QA_04: 403.927,
        VA_04: 1.49715,
        QC_04: 368.806,
        VC_04: 1.455,
        QE_04: 401.788,
        VE_04: 1.35621,
        QA_05: 226.956,
        VA_05: 1.18368,
        QC_05: 210.399,
        VC_05: 1.1595,
        QE_05: 287.809,
        VE_05: 1.1861,
        QA_06: 139.341,
        VA_06: 0.98393,
        QC_06: 173.756,
        VC_06: 1.07726,
        QE_06: 255.397,
        VE_06: 1.13214,
        QA_07: 96.702,
        VA_07: 0.86482,
        QC_07: 144.597,
        VC_07: 1.00581,
        QE_07: 258.001,
        VE_07: 1.13659,
        QA_08: 71.571,
        VA_08: 0.78265,
        QC_08: 161.72,
        VC_08: 1.04851,
        QE_08: 287.411,
        VE_08: 1.18545,
        QA_09: 61.457,
        VA_09: 0.74579,
        QC_09: 148.417,
        VC_09: 1.01553,
        QE_09: 233.786,
        VE_09: 1.09436,
        QA_10: 47.259,
        VA_10: 0.68884,
        QC_10: 119.674,
        VC_10: 0.93915,
        QE_10: 240.545,
        VE_10: 1.10635,
        QA_11: 71.573,
        VA_11: 0.78265,
        QC_11: 99.292,
        VC_11: 0.87961,
        QE_11: 237.193,
        VE_11: 1.10042,
        QA_12: 334.28,
        VA_12: 1.3835,
        QC_12: 261.648,
        VC_12: 1.26394,
        QE_12: 319.008,
        VE_12: 1.2354,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880749718230163, 34.964955679060836, 0.0],
          [-80.879302718232395, 34.962527812397923, 0.0],
          [-80.874044184907291, 34.965459812393419, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285921.0,
        COMID: 9754146,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.292,
        REACHCODE: "03050101002596",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0030526035375879998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061545.0,
        ToNode: 250061544.0,
        Hydroseq: 250020920.0,
        LevelPathI: 250020920.0,
        Pathlength: 625.326,
        TerminalPa: 250002604.0,
        ArbolateSu: 231.945,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250020920.0,
        UpHydroseq: 250021302.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012062.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2268,
        TotDASqKM: 254.6523,
        DivDASqKM: 254.6523,
        Tidal: 0,
        TOTMA: 0.10753374251,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30210.0,
        MAXELEVSMO: 30210.0,
        MINELEVSMO: 30210.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.29199999570846602,
        QA_MA: 135.922,
        VA_MA: -9998.0,
        QC_MA: 136.041,
        VC_MA: -9998.0,
        QE_MA: 136.041,
        VE_MA: -9998.0,
        QA_01: 235.818,
        VA_01: -9998.0,
        QC_01: 185.328,
        VC_01: -9998.0,
        QE_01: 185.328,
        VE_01: -9998.0,
        QA_02: 247.967,
        VA_02: -9998.0,
        QC_02: 207.877,
        VC_02: -9998.0,
        QE_02: 207.877,
        VE_02: -9998.0,
        QA_03: 271.874,
        VA_03: -9998.0,
        QC_03: 230.073,
        VC_03: -9998.0,
        QE_03: 230.073,
        VE_03: -9998.0,
        QA_04: 209.084,
        VA_04: -9998.0,
        QC_04: 193.783,
        VC_04: -9998.0,
        QE_04: 193.783,
        VE_04: -9998.0,
        QA_05: 150.007,
        VA_05: -9998.0,
        QC_05: 141.235,
        VC_05: -9998.0,
        QE_05: 141.235,
        VE_05: -9998.0,
        QA_06: 84.385,
        VA_06: -9998.0,
        QC_06: 105.954,
        VC_06: -9998.0,
        QE_06: 105.954,
        VE_06: -9998.0,
        QA_07: 51.106,
        VA_07: -9998.0,
        QC_07: 77.593,
        VC_07: -9998.0,
        QE_07: 77.593,
        VE_07: -9998.0,
        QA_08: 34.345,
        VA_08: -9998.0,
        QC_08: 76.473,
        VC_08: -9998.0,
        QE_08: 76.473,
        VE_08: -9998.0,
        QA_09: 27.223,
        VA_09: -9998.0,
        QC_09: 72.321,
        VC_09: -9998.0,
        QE_09: 72.321,
        VE_09: -9998.0,
        QA_10: 27.671,
        VA_10: -9998.0,
        QC_10: 78.039,
        VC_10: -9998.0,
        QE_10: 78.039,
        VE_10: -9998.0,
        QA_11: 113.767,
        VA_11: -9998.0,
        QC_11: 141.387,
        VC_11: -9998.0,
        QE_11: 141.387,
        VE_11: -9998.0,
        QA_12: 178.544,
        VA_12: -9998.0,
        QC_12: 147.881,
        VC_12: -9998.0,
        QE_12: 147.881,
        VE_12: -9998.0,
        LakeFract: 0.00098075427,
        SurfArea: 8623.4183498799994,
        RAreaHLoad: 0.02599342739,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.637183117056054, 35.787782677783639, 0.0],
          [-81.634581717060101, 35.786352077785693, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286947.0,
        COMID: 9733144,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.317,
        REACHCODE: "03050103000227",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012537194982037999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058950.0,
        ToNode: 250058949.0,
        Hydroseq: 250025549.0,
        LevelPathI: 250019853.0,
        Pathlength: 429.001,
        TerminalPa: 250002604.0,
        ArbolateSu: 336.207,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250026291.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 250024872.0,
        DnDrainCou: 2,
        DnHydroseq: 250024873.0,
        FromMeas: 0.0,
        ToMeas: 41.7011,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5867,
        TotDASqKM: 686.0709,
        DivDASqKM: 686.0709,
        Tidal: 0,
        TOTMA: 0.059327394962900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14699.0,
        MAXELEVSMO: 14969.0,
        MINELEVSMO: 14969.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.317000031471252,
        QA_MA: 272.567,
        VA_MA: 0.83089,
        QC_MA: 265.539,
        VC_MA: 0.82979,
        QE_MA: 361.212,
        VE_MA: 0.84295,
        QA_01: 577.261,
        VA_01: 1.08668,
        QC_01: 443.147,
        VC_01: 0.99273,
        QE_01: 577.539,
        VE_01: 0.99423,
        QA_02: 615.613,
        VA_02: 1.11372,
        QC_02: 503.449,
        VC_02: 1.0407,
        QE_02: 590.315,
        VE_02: 1.00225,
        QA_03: 663.141,
        VA_03: 1.14616,
        QC_03: 544.906,
        VC_03: 1.07213,
        QE_03: 635.827,
        VE_03: 1.03016,
        QA_04: 393.88,
        VA_04: 0.9437,
        QC_04: 359.839,
        VC_04: 0.92109,
        QE_04: 392.82,
        VE_04: 0.86729,
        QA_05: 221.375,
        VA_05: 0.77625,
        QC_05: 205.416,
        VC_05: 0.76334,
        QE_05: 282.826,
        VE_05: 0.77783,
        QA_06: 135.925,
        VA_06: 0.66949,
        QC_06: 169.554,
        VC_06: 0.71927,
        QE_06: 251.196,
        VE_06: 0.74918,
        QA_07: 94.201,
        VA_07: 0.60561,
        QC_07: 140.944,
        VC_07: 0.68087,
        QE_07: 254.349,
        VE_07: 0.75211,
        QA_08: 69.751,
        VA_08: 0.56177,
        QC_08: 157.528,
        VC_08: 0.70353,
        QE_08: 283.218,
        VE_08: 0.77817,
        QA_09: 59.864,
        VA_09: 0.54202,
        QC_09: 145.017,
        VC_09: 0.68654,
        QE_09: 230.387,
        VE_09: 0.72939,
        QA_10: 46.122,
        VA_10: 0.51181,
        QC_10: 117.368,
        VC_10: 0.64637,
        QE_10: 238.239,
        VE_10: 0.73695,
        QA_11: 69.61,
        VA_11: 0.5615,
        QC_11: 97.209,
        VC_11: 0.61418,
        QE_11: 235.11,
        VE_11: 0.73395,
        QA_12: 325.767,
        VA_12: 0.88278,
        QC_12: 255.578,
        VC_12: 0.81931,
        QE_12: 312.938,
        VE_12: 0.80374,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.89494858487484, 35.00015687900617, 0.0],
          [-80.893250118210801, 34.997938012342956, 0.0],
          [-80.894055918209574, 34.993402612349996, 0.0],
          [-80.892524384878527, 34.990883279020466, 0.0],
          [-80.890910118214379, 34.99097521235376, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285922.0,
        COMID: 9754148,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.349,
        REACHCODE: "03050101002597",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.025089272637278,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061546.0,
        ToNode: 250061536.0,
        Hydroseq: 250011974.0,
        LevelPathI: 250003258.0,
        Pathlength: 621.747,
        TerminalPa: 250002604.0,
        ArbolateSu: 2440.324,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012062.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011883.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.3866,
        TotDASqKM: 2644.4313,
        DivDASqKM: 2644.4313,
        Tidal: 0,
        TOTMA: 0.86505739740999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30198.0,
        MAXELEVSMO: 30207.0,
        MINELEVSMO: 30205.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.348999977111816,
        QA_MA: 1654.068,
        VA_MA: -9998.0,
        QC_MA: 1654.068,
        VC_MA: -9998.0,
        QE_MA: 1849.112,
        VE_MA: -9998.0,
        QA_01: 2609.886,
        VA_01: -9998.0,
        QC_01: 1925.86,
        VC_01: -9998.0,
        QE_01: 2131.134,
        VE_01: -9998.0,
        QA_02: 2763.954,
        VA_02: -9998.0,
        QC_02: 2763.954,
        VC_02: -9998.0,
        QE_02: 2835.776,
        VE_02: -9998.0,
        QA_03: 3158.041,
        VA_03: -9998.0,
        QC_03: 3158.041,
        VC_03: -9998.0,
        QE_03: 3422.161,
        VE_03: -9998.0,
        QA_04: 2430.83,
        VA_04: -9998.0,
        QC_04: 2430.83,
        VC_04: -9998.0,
        QE_04: 2622.956,
        VE_04: -9998.0,
        QA_05: 1885.941,
        VA_05: -9998.0,
        QC_05: 1885.941,
        VC_05: -9998.0,
        QE_05: 2108.063,
        VE_05: -9998.0,
        QA_06: 1101.764,
        VA_06: -9998.0,
        QC_06: 1101.764,
        VC_06: -9998.0,
        QE_06: 1361.377,
        VE_06: -9998.0,
        QA_07: 651.66,
        VA_07: -9998.0,
        QC_07: 651.66,
        VC_07: -9998.0,
        QE_07: 798.554,
        VE_07: -9998.0,
        QA_08: 465.357,
        VA_08: -9998.0,
        QC_08: 465.357,
        VC_08: -9998.0,
        QE_08: 764.973,
        VE_08: -9998.0,
        QA_09: 474.555,
        VA_09: -9998.0,
        QC_09: 902.077,
        VC_09: -9998.0,
        QE_09: 938.583,
        VE_09: -9998.0,
        QA_10: 633.5,
        VA_10: -9998.0,
        QC_10: 951.666,
        VC_10: -9998.0,
        QE_10: 912.163,
        VE_10: -9998.0,
        QA_11: 1597.063,
        VA_11: -9998.0,
        QC_11: 1597.063,
        VC_11: -9998.0,
        QE_11: 1574.263,
        VE_11: -9998.0,
        QA_12: 2087.045,
        VA_12: -9998.0,
        QC_12: 2087.045,
        VC_12: -9998.0,
        QE_12: 2110.758,
        VE_12: -9998.0,
        LakeFract: 0.10787007099,
        SurfArea: 948462.60478499997,
        RAreaHLoad: 0.20910465985000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.621932783746445, 35.788739677782189, 0.0],
          [-81.618392317085181, 35.787765677783682, 0.0],
          [-81.614694917091015, 35.784061877789327, 0.0],
          [-81.599241183781658, 35.783394277790364, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286948.0,
        COMID: 9731430,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.303,
        REACHCODE: "03050103000228",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0030659146066690001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058898.0,
        ToNode: 250058908.0,
        Hydroseq: 250027089.0,
        LevelPathI: 250019853.0,
        Pathlength: 432.154,
        TerminalPa: 250002604.0,
        ArbolateSu: 332.8,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250027963.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026291.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0603,
        TotDASqKM: 681.0696,
        DivDASqKM: 681.0696,
        Tidal: 0,
        TOTMA: 0.0076569551983499996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15004.0,
        MAXELEVSMO: 15052.0,
        MINELEVSMO: 15004.0,
        SLOPE: 0.00158415,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30300000309944197,
        QA_MA: 270.472,
        VA_MA: 1.4755,
        QC_MA: 263.578,
        VC_MA: 1.47306,
        QE_MA: 359.251,
        VE_MA: 1.50265,
        QA_01: 572.804,
        VA_01: 2.04682,
        QC_01: 439.814,
        VC_01: 1.83693,
        QE_01: 574.206,
        VE_01: 1.84034,
        QA_02: 610.942,
        VA_02: 2.10735,
        QC_02: 499.733,
        VC_02: 1.94419,
        QE_02: 586.599,
        VE_02: 1.85781,
        QA_03: 658.134,
        VA_03: 2.17985,
        QC_03: 540.927,
        VC_03: 2.01446,
        QE_03: 631.848,
        VE_03: 1.92017,
        QA_04: 390.89,
        VA_04: 1.72757,
        QC_04: 357.169,
        VC_04: 1.67699,
        QE_04: 390.151,
        VE_04: 1.55614,
        QA_05: 219.694,
        VA_05: 1.3535,
        QC_05: 203.915,
        VC_05: 1.32468,
        QE_05: 281.324,
        VE_05: 1.35723,
        QA_06: 134.91,
        VA_06: 1.11509,
        QC_06: 168.305,
        VC_06: 1.22621,
        QE_06: 249.946,
        VE_06: 1.29339,
        QA_07: 93.461,
        VA_07: 0.97222,
        QC_07: 139.864,
        VC_07: 1.14029,
        QE_07: 253.268,
        VE_07: 1.30032,
        QA_08: 69.213,
        VA_08: 0.87436,
        QC_08: 156.289,
        VC_08: 1.19082,
        QE_08: 281.979,
        VE_08: 1.35853,
        QA_09: 59.394,
        VA_09: 0.8302,
        QC_09: 144.01,
        VC_09: 1.1533,
        QE_09: 229.379,
        VE_09: 1.24949,
        QA_10: 45.786,
        VA_10: 0.76286,
        QC_10: 116.684,
        VC_10: 1.06398,
        QE_10: 237.555,
        VE_10: 1.26716,
        QA_11: 68.956,
        VA_11: 0.87324,
        QC_11: 96.511,
        VC_11: 0.99153,
        QE_11: 234.412,
        VE_11: 1.2604,
        QA_12: 323.203,
        VA_12: 1.59127,
        QC_12: 253.747,
        VC_12: 1.44979,
        QE_12: 311.107,
        VE_12: 1.4148,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.896901118205164, 35.01355087898537, 0.0],
          [-80.899350384867887, 35.011741012321522, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285899.0,
        COMID: 9754072,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.715,
        REACHCODE: "03050101002574",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0079454269892289993,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061510.0,
        ToNode: 250061508.0,
        Hydroseq: 250011164.0,
        LevelPathI: 250003258.0,
        Pathlength: 608.171,
        TerminalPa: 250002604.0,
        ArbolateSu: 2589.549,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011236.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011090.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5859,
        TotDASqKM: 2806.0686,
        DivDASqKM: 2806.0686,
        Tidal: 0,
        TOTMA: 0.26331036144999997,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30170.0,
        MAXELEVSMO: 30173.0,
        MINELEVSMO: 30173.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.71499997377395597,
        QA_MA: 1739.925,
        VA_MA: -9998.0,
        QC_MA: 1739.925,
        VC_MA: -9998.0,
        QE_MA: 1934.968,
        VE_MA: -9998.0,
        QA_01: 2764.716,
        VA_01: -9998.0,
        QC_01: 2037.031,
        VC_01: -9998.0,
        QE_01: 2242.306,
        VE_01: -9998.0,
        QA_02: 2928.574,
        VA_02: -9998.0,
        QC_02: 2928.574,
        VC_02: -9998.0,
        QE_02: 3000.396,
        VE_02: -9998.0,
        QA_03: 3334.012,
        VA_03: -9998.0,
        QC_03: 3334.012,
        VC_03: -9998.0,
        QE_03: 3598.132,
        VE_03: -9998.0,
        QA_04: 2560.281,
        VA_04: -9998.0,
        QC_04: 2560.281,
        VC_04: -9998.0,
        QE_04: 2752.406,
        VE_04: -9998.0,
        QA_05: 1976.412,
        VA_05: -9998.0,
        QC_05: 1976.412,
        VC_05: -9998.0,
        QE_05: 2198.534,
        VE_05: -9998.0,
        QA_06: 1153.22,
        VA_06: -9998.0,
        QC_06: 1153.22,
        VC_06: -9998.0,
        QE_06: 1412.833,
        VE_06: -9998.0,
        QA_07: 682.463,
        VA_07: -9998.0,
        QC_07: 682.463,
        VC_07: -9998.0,
        QE_07: 829.357,
        VE_07: -9998.0,
        QA_08: 486.098,
        VA_08: -9998.0,
        QC_08: 486.098,
        VC_08: -9998.0,
        QE_08: 785.714,
        VE_08: -9998.0,
        QA_09: 491.483,
        VA_09: -9998.0,
        QC_09: 930.428,
        VC_09: -9998.0,
        QE_09: 966.934,
        VE_09: -9998.0,
        QA_10: 647.764,
        VA_10: -9998.0,
        QC_10: 968.744,
        VC_10: -9998.0,
        QE_10: 929.241,
        VE_10: -9998.0,
        QA_11: 1664.96,
        VA_11: -9998.0,
        QC_11: 1664.96,
        VC_11: -9998.0,
        QE_11: 1642.16,
        VE_11: -9998.0,
        QA_12: 2200.556,
        VA_12: -9998.0,
        QC_12: 2200.556,
        VC_12: -9998.0,
        QE_12: 2224.269,
        VE_12: -9998.0,
        LakeFract: 0.03435457149,
        SurfArea: 302067.348826,
        RAreaHLoad: 0.06364828939,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.462228117327641, 35.784191277789205, 0.0],
          [-81.454335917339847, 35.784708411121585, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286949.0,
        COMID: 9731424,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.541,
        REACHCODE: "03050103000229",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0056016082099029996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058896.0,
        ToNode: 250058898.0,
        Hydroseq: 250027963.0,
        LevelPathI: 250019853.0,
        Pathlength: 432.457,
        TerminalPa: 250002604.0,
        ArbolateSu: 326.117,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250028925.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027089.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5472,
        TotDASqKM: 665.5878,
        DivDASqKM: 665.5878,
        Tidal: 0,
        TOTMA: 0.024468452096400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14996.0,
        MAXELEVSMO: 15052.0,
        MINELEVSMO: 15052.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.54100000858306896,
        QA_MA: 264.03,
        VA_MA: 0.8272,
        QC_MA: 257.54,
        VC_MA: 0.82616,
        QE_MA: 353.214,
        VE_MA: 0.83958,
        QA_01: 559.066,
        VA_01: 1.0811,
        QC_01: 429.539,
        VC_01: 0.98775,
        QE_01: 563.931,
        VE_01: 0.98938,
        QA_02: 596.579,
        VA_02: 1.10821,
        QC_02: 488.301,
        VC_02: 1.03562,
        QE_02: 575.167,
        VE_02: 0.99655,
        QA_03: 642.73,
        VA_03: 1.14049,
        QC_03: 528.68,
        VC_03: 1.06696,
        QE_03: 619.601,
        VE_03: 1.02429,
        QA_04: 381.732,
        VA_04: 0.93938,
        QC_04: 348.989,
        VC_04: 0.91682,
        QE_04: 381.97,
        VE_04: 0.86212,
        QA_05: 214.49,
        VA_05: 0.773,
        QC_05: 199.263,
        VC_05: 0.76022,
        QE_05: 276.673,
        VE_05: 0.77497,
        QA_06: 131.787,
        VA_06: 0.66712,
        QC_06: 164.463,
        VC_06: 0.71643,
        QE_06: 246.104,
        VE_06: 0.74684,
        QA_07: 91.171,
        VA_07: 0.60337,
        QC_07: 136.518,
        VC_07: 0.67802,
        QE_07: 249.923,
        VE_07: 0.75044,
        QA_08: 67.548,
        VA_08: 0.55995,
        QC_08: 152.454,
        VC_08: 0.70033,
        QE_08: 278.144,
        VE_08: 0.77628,
        QA_09: 57.937,
        VA_09: 0.54026,
        QC_09: 140.886,
        VC_09: 0.68425,
        QE_09: 226.256,
        VE_09: 0.7277,
        QA_10: 44.745,
        VA_10: 0.51053,
        QC_10: 114.56,
        VC_10: 0.64515,
        QE_10: 235.431,
        VE_10: 0.73664,
        QA_11: 66.956,
        VA_11: 0.55877,
        QC_11: 94.369,
        VC_11: 0.61221,
        QE_11: 232.27,
        VE_11: 0.73358,
        QA_12: 315.295,
        VA_12: 0.87846,
        QC_12: 248.092,
        VC_12: 0.81598,
        QE_12: 305.452,
        VE_12: 0.80014,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.901577918197859, 35.012885678986436, 0.0],
          [-80.899184318201605, 35.014489612317107, 0.0],
          [-80.896901118205164, 35.01355087898537, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285900.0,
        COMID: 9754074,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.56,
        REACHCODE: "03050101002575",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.017176975543955,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061511.0,
        ToNode: 250061510.0,
        Hydroseq: 250011236.0,
        LevelPathI: 250003258.0,
        Pathlength: 608.886,
        TerminalPa: 250002604.0,
        ArbolateSu: 2586.334,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011312.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011164.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7639,
        TotDASqKM: 2803.5513,
        DivDASqKM: 2803.5513,
        Tidal: 0,
        TOTMA: 0.57449533414999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30173.0,
        MAXELEVSMO: 30176.0,
        MINELEVSMO: 30173.0,
        SLOPE: 1.923e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.559999942779541,
        QA_MA: 1738.64,
        VA_MA: -9998.0,
        QC_MA: 1738.64,
        VC_MA: -9998.0,
        QE_MA: 1933.683,
        VE_MA: -9998.0,
        QA_01: 2762.386,
        VA_01: -9998.0,
        QC_01: 2035.359,
        VC_01: -9998.0,
        QE_01: 2240.634,
        VE_01: -9998.0,
        QA_02: 2926.063,
        VA_02: -9998.0,
        QC_02: 2926.063,
        VC_02: -9998.0,
        QE_02: 2997.886,
        VE_02: -9998.0,
        QA_03: 3331.323,
        VA_03: -9998.0,
        QC_03: 3331.323,
        VC_03: -9998.0,
        QE_03: 3595.442,
        VE_03: -9998.0,
        QA_04: 2558.355,
        VA_04: -9998.0,
        QC_04: 2558.355,
        VC_04: -9998.0,
        QE_04: 2750.481,
        VE_04: -9998.0,
        QA_05: 1975.087,
        VA_05: -9998.0,
        QC_05: 1975.087,
        VC_05: -9998.0,
        QE_05: 2197.209,
        VE_05: -9998.0,
        QA_06: 1152.458,
        VA_06: -9998.0,
        QC_06: 1152.458,
        VC_06: -9998.0,
        QE_06: 1412.072,
        VE_06: -9998.0,
        QA_07: 681.997,
        VA_07: -9998.0,
        QC_07: 681.997,
        VC_07: -9998.0,
        QE_07: 828.891,
        VE_07: -9998.0,
        QA_08: 485.793,
        VA_08: -9998.0,
        QC_08: 485.793,
        VC_08: -9998.0,
        QE_08: 785.409,
        VE_08: -9998.0,
        QA_09: 491.246,
        VA_09: -9998.0,
        QC_09: 930.032,
        VC_09: -9998.0,
        QE_09: 966.538,
        VE_09: -9998.0,
        QA_10: 647.56,
        VA_10: -9998.0,
        QC_10: 968.502,
        VC_10: -9998.0,
        QE_10: 928.999,
        VE_10: -9998.0,
        QA_11: 1663.99,
        VA_11: -9998.0,
        QC_11: 1663.99,
        VC_11: -9998.0,
        QE_11: 1641.19,
        VE_11: -9998.0,
        QA_12: 2198.868,
        VA_12: -9998.0,
        QC_12: 2198.868,
        VC_12: -9998.0,
        QE_12: 2222.581,
        VE_12: -9998.0,
        LakeFract: 0.07490577536,
        SurfArea: 658619.44985600002,
        RAreaHLoad: 0.13886899505,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.478966917301705, 35.782595277791586, 0.0],
          [-81.462228117327641, 35.784191277789205, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286950.0,
        COMID: 9731434,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.81,
        REACHCODE: "03050103000230",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017442518171676,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058900.0,
        ToNode: 250058896.0,
        Hydroseq: 250028925.0,
        LevelPathI: 250019853.0,
        Pathlength: 432.998,
        TerminalPa: 250002604.0,
        ArbolateSu: 325.576,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250029973.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 250027962.0,
        DnDrainCou: 2,
        DnHydroseq: 250027963.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9574,
        TotDASqKM: 665.0406,
        DivDASqKM: 665.0406,
        Tidal: 0,
        TOTMA: 0.059926200617599998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14954.0,
        MAXELEVSMO: 15084.0,
        MINELEVSMO: 15052.0,
        SLOPE: 0.00017679,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.809999942779541,
        QA_MA: 263.803,
        VA_MA: 1.12737,
        QC_MA: 257.327,
        VC_MA: 1.12573,
        QE_MA: 353.0,
        VE_MA: 1.14692,
        QA_01: 558.58,
        VA_01: 1.52816,
        QC_01: 429.176,
        VC_01: 1.3808,
        QE_01: 563.567,
        VE_01: 1.38338,
        QA_02: 596.07,
        VA_02: 1.57096,
        QC_02: 487.896,
        VC_02: 1.45637,
        QE_02: 574.762,
        VE_02: 1.39466,
        QA_03: 642.185,
        VA_03: 1.62192,
        QC_03: 528.246,
        VC_03: 1.50584,
        QE_03: 619.167,
        VE_03: 1.43845,
        QA_04: 381.407,
        VA_04: 1.30446,
        QC_04: 348.698,
        VC_04: 1.26884,
        QE_04: 381.68,
        VE_04: 1.18244,
        QA_05: 214.306,
        VA_05: 1.04181,
        QC_05: 199.099,
        VC_05: 1.02163,
        QE_05: 276.508,
        VE_05: 1.04494,
        QA_06: 131.677,
        VA_06: 0.87467,
        QC_06: 164.327,
        VC_06: 0.95252,
        QE_06: 245.968,
        VE_06: 1.00055,
        QA_07: 91.09,
        VA_07: 0.77403,
        QC_07: 136.4,
        VC_07: 0.89186,
        QE_07: 249.804,
        VE_07: 1.00627,
        QA_08: 67.489,
        VA_08: 0.70549,
        QC_08: 152.318,
        VC_08: 0.92708,
        QE_08: 278.009,
        VE_08: 1.04706,
        QA_09: 57.885,
        VA_09: 0.6744,
        QC_09: 140.776,
        VC_09: 0.90172,
        QE_09: 226.145,
        VE_09: 0.97035,
        QA_10: 44.708,
        VA_10: 0.62749,
        QC_10: 114.485,
        VC_10: 0.84004,
        QE_10: 235.356,
        VE_10: 0.98453,
        QA_11: 66.889,
        VA_11: 0.70361,
        QC_11: 94.298,
        VC_11: 0.788,
        QE_11: 232.199,
        VE_11: 0.9797,
        QA_12: 315.015,
        VA_12: 1.20828,
        QC_12: 247.892,
        VC_12: 1.10967,
        QE_12: 305.251,
        VE_12: 1.08465,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.909458584852302, 35.017922678978493, 0.0],
          [-80.904304984860289, 35.009197478992121, 0.0],
          [-80.902968784862367, 35.009243612325406, 0.0],
          [-80.90369311819461, 35.010823878989584, 0.0],
          [-80.901577918197859, 35.012885678986436, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285901.0,
        COMID: 9754078,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.617,
        REACHCODE: "03050101002576",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0067773275142450004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061513.0,
        ToNode: 250061511.0,
        Hydroseq: 250011312.0,
        LevelPathI: 250003258.0,
        Pathlength: 610.446,
        TerminalPa: 250002604.0,
        ArbolateSu: 2580.268,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011393.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011236.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.648,
        TotDASqKM: 2794.2444,
        DivDASqKM: 2794.2444,
        Tidal: 0,
        TOTMA: 0.22722026998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30124.0,
        MAXELEVSMO: 30176.0,
        MINELEVSMO: 30176.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.61699998378753695,
        QA_MA: 1733.826,
        VA_MA: -9998.0,
        QC_MA: 1733.826,
        VC_MA: -9998.0,
        QE_MA: 1928.87,
        VE_MA: -9998.0,
        QA_01: 2753.689,
        VA_01: -9998.0,
        QC_01: 2029.119,
        VC_01: -9998.0,
        QE_01: 2234.393,
        VE_01: -9998.0,
        QA_02: 2916.726,
        VA_02: -9998.0,
        QC_02: 2916.726,
        VC_02: -9998.0,
        QE_02: 2988.548,
        VE_02: -9998.0,
        QA_03: 3321.308,
        VA_03: -9998.0,
        QC_03: 3321.308,
        VC_03: -9998.0,
        QE_03: 3585.427,
        VE_03: -9998.0,
        QA_04: 2551.119,
        VA_04: -9998.0,
        QC_04: 2551.119,
        VC_04: -9998.0,
        QE_04: 2743.245,
        VE_04: -9998.0,
        QA_05: 1970.049,
        VA_05: -9998.0,
        QC_05: 1970.049,
        VC_05: -9998.0,
        QE_05: 2192.171,
        VE_05: -9998.0,
        QA_06: 1149.575,
        VA_06: -9998.0,
        QC_06: 1149.575,
        VC_06: -9998.0,
        QE_06: 1409.188,
        VE_06: -9998.0,
        QA_07: 680.259,
        VA_07: -9998.0,
        QC_07: 680.259,
        VC_07: -9998.0,
        QE_07: 827.153,
        VE_07: -9998.0,
        QA_08: 484.626,
        VA_08: -9998.0,
        QC_08: 484.626,
        VC_08: -9998.0,
        QE_08: 784.242,
        VE_08: -9998.0,
        QA_09: 490.305,
        VA_09: -9998.0,
        QC_09: 928.459,
        VC_09: -9998.0,
        QE_09: 964.965,
        VE_09: -9998.0,
        QA_10: 646.81,
        VA_10: -9998.0,
        QC_10: 967.604,
        VC_10: -9998.0,
        QE_10: 928.102,
        VE_10: -9998.0,
        QA_11: 1660.251,
        VA_11: -9998.0,
        QC_11: 1660.251,
        VC_11: -9998.0,
        QE_11: 1637.451,
        VE_11: -9998.0,
        QA_12: 2192.542,
        VA_12: -9998.0,
        QC_12: 2192.542,
        VC_12: -9998.0,
        QE_12: 2216.254,
        VE_12: -9998.0,
        LakeFract: 0.02955262256,
        SurfArea: 259845.544869,
        RAreaHLoad: 0.05492446791,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.485534983958132, 35.781913477792727, 0.0],
          [-81.478966917301705, 35.782595277791586, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286943.0,
        COMID: 9733158,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.255,
        REACHCODE: "03050103000224",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0027263503304039999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058954.0,
        ToNode: 250058972.0,
        Hydroseq: 250023663.0,
        LevelPathI: 250019853.0,
        Pathlength: 427.834,
        TerminalPa: 250002604.0,
        ArbolateSu: 341.295,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250024248.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023121.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0558,
        TotDASqKM: 690.4404,
        DivDASqKM: 690.4404,
        Tidal: 0,
        TOTMA: 0.00711145962585,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14911.0,
        MAXELEVSMO: 14929.0,
        MINELEVSMO: 14911.0,
        SLOPE: 0.00070588,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25499999523162797,
        QA_MA: 274.372,
        VA_MA: 1.33802,
        QC_MA: 267.229,
        VC_MA: 1.33582,
        QE_MA: 362.902,
        VE_MA: 1.36161,
        QA_01: 581.139,
        VA_01: 1.84214,
        QC_01: 446.045,
        VC_01: 1.65703,
        QE_01: 580.437,
        VE_01: 1.65991,
        QA_02: 619.666,
        VA_02: 1.89531,
        QC_02: 506.673,
        VC_02: 1.75146,
        QE_02: 593.54,
        VE_02: 1.67603,
        QA_03: 667.488,
        VA_03: 1.95921,
        QC_03: 548.36,
        VC_03: 1.81333,
        QE_03: 639.281,
        VE_03: 1.73102,
        QA_04: 396.465,
        VA_04: 1.56028,
        QC_04: 362.147,
        VC_04: 1.51577,
        QE_04: 395.128,
        VE_04: 1.41028,
        QA_05: 222.802,
        VA_05: 1.23025,
        QC_05: 206.691,
        VC_05: 1.20481,
        QE_05: 284.101,
        VE_05: 1.23322,
        QA_06: 136.807,
        VA_06: 1.01992,
        QC_06: 170.639,
        VC_06: 1.11806,
        QE_06: 252.28,
        VE_06: 1.17669,
        QA_07: 94.847,
        VA_07: 0.89415,
        QC_07: 141.888,
        VC_07: 1.0425,
        QE_07: 255.292,
        VE_07: 1.18218,
        QA_08: 70.221,
        VA_08: 0.80773,
        QC_08: 158.61,
        VC_08: 1.08724,
        QE_08: 284.301,
        VE_08: 1.23357,
        QA_09: 60.276,
        VA_09: 0.76883,
        QC_09: 145.896,
        VC_09: 1.05344,
        QE_09: 231.266,
        VE_09: 1.1375,
        QA_10: 46.416,
        VA_10: 0.70918,
        QC_10: 117.965,
        VC_10: 0.97393,
        QE_10: 238.835,
        VE_10: 1.1518,
        QA_11: 70.096,
        VA_11: 0.80725,
        QC_11: 97.726,
        VC_11: 0.91063,
        QE_11: 235.626,
        VE_11: 1.14576,
        QA_12: 327.946,
        VA_12: 1.4403,
        QC_12: 257.133,
        VC_12: 1.31502,
        QE_12: 314.493,
        VE_12: 1.2845,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886150518221768, 34.988273012357922, 0.0],
          [-80.888655118217969, 34.98758541235896, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286944.0,
        COMID: 9733150,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.411,
        REACHCODE: "03050103000225",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0037739723732989998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058952.0,
        ToNode: 250058954.0,
        Hydroseq: 250024248.0,
        LevelPathI: 250019853.0,
        Pathlength: 428.089,
        TerminalPa: 250002604.0,
        ArbolateSu: 338.379,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250024873.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023663.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.117,
        TotDASqKM: 687.6819,
        DivDASqKM: 687.6819,
        Tidal: 0,
        TOTMA: 0.011022822961399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14903.0,
        MAXELEVSMO: 14969.0,
        MINELEVSMO: 14929.0,
        SLOPE: 0.00097323,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.41100001335143999,
        QA_MA: 273.233,
        VA_MA: 1.39095,
        QC_MA: 266.163,
        VC_MA: 1.38866,
        QE_MA: 361.836,
        VE_MA: 1.41586,
        QA_01: 578.691,
        VA_01: 1.92094,
        QC_01: 444.216,
        VC_01: 1.7263,
        QE_01: 578.607,
        VE_01: 1.72937,
        QA_02: 617.107,
        VA_02: 1.97692,
        QC_02: 504.638,
        VC_02: 1.82565,
        QE_02: 591.504,
        VE_02: 1.7461,
        QA_03: 664.744,
        VA_03: 2.04412,
        QC_03: 546.18,
        VC_03: 1.89074,
        QE_03: 637.101,
        VE_03: 1.80393,
        QA_04: 394.833,
        VA_04: 1.62467,
        QC_04: 360.69,
        VC_04: 1.57784,
        QE_04: 393.671,
        VE_04: 1.46658,
        QA_05: 221.909,
        VA_05: 1.27772,
        QC_05: 205.893,
        VC_05: 1.25098,
        QE_05: 283.303,
        VE_05: 1.28093,
        QA_06: 136.25,
        VA_06: 1.05654,
        QC_06: 169.954,
        VC_06: 1.15969,
        QE_06: 251.595,
        VE_06: 1.22153,
        QA_07: 94.439,
        VA_07: 0.92421,
        QC_07: 141.292,
        VC_07: 1.08016,
        QE_07: 254.697,
        VE_07: 1.22749,
        QA_08: 69.924,
        VA_08: 0.83338,
        QC_08: 157.927,
        VC_08: 1.12715,
        QE_08: 283.617,
        VE_08: 1.28151,
        QA_09: 60.016,
        VA_09: 0.79246,
        QC_09: 145.341,
        VC_09: 1.09183,
        QE_09: 230.711,
        VE_09: 1.18046,
        QA_10: 46.23,
        VA_10: 0.72982,
        QC_10: 117.588,
        VC_10: 1.00846,
        QE_10: 238.459,
        VE_10: 1.19589,
        QA_11: 69.789,
        VA_11: 0.83284,
        QC_11: 97.399,
        VC_11: 0.94182,
        QE_11: 235.3,
        VE_11: 1.18963,
        QA_12: 326.571,
        VA_12: 1.49847,
        QC_12: 256.152,
        VC_12: 1.36689,
        QE_12: 313.512,
        VE_12: 1.33469,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885844718222316, 34.991731612352567, 0.0],
          [-80.886150518221768, 34.988273012357922, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286945.0,
        COMID: 9733140,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.501,
        REACHCODE: "03050103000226",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00539760185852,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058949.0,
        ToNode: 250058952.0,
        Hydroseq: 250024873.0,
        LevelPathI: 250019853.0,
        Pathlength: 428.5,
        TerminalPa: 250002604.0,
        ArbolateSu: 336.708,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250025549.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024248.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2259,
        TotDASqKM: 686.2968,
        DivDASqKM: 686.2968,
        Tidal: 0,
        TOTMA: 0.022567931373100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14940.0,
        MAXELEVSMO: 14969.0,
        MINELEVSMO: 14969.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50099998712539695,
        QA_MA: 272.66,
        VA_MA: 0.83093,
        QC_MA: 265.627,
        VC_MA: 0.82983,
        QE_MA: 361.3,
        VE_MA: 0.84298,
        QA_01: 577.462,
        VA_01: 1.08674,
        QC_01: 443.297,
        VC_01: 0.99279,
        QE_01: 577.689,
        VE_01: 0.99428,
        QA_02: 615.822,
        VA_02: 1.11378,
        QC_02: 503.615,
        VC_02: 1.04076,
        QE_02: 590.482,
        VE_02: 1.00231,
        QA_03: 663.366,
        VA_03: 1.14623,
        QC_03: 545.085,
        VC_03: 1.07219,
        QE_03: 636.006,
        VE_03: 1.03022,
        QA_04: 394.014,
        VA_04: 0.94375,
        QC_04: 359.958,
        VC_04: 0.92114,
        QE_04: 392.939,
        VE_04: 0.86735,
        QA_05: 221.451,
        VA_05: 0.77628,
        QC_05: 205.484,
        VC_05: 0.76338,
        QE_05: 282.894,
        VE_05: 0.77786,
        QA_06: 135.971,
        VA_06: 0.66952,
        QC_06: 169.61,
        VC_06: 0.7193,
        QE_06: 251.252,
        VE_06: 0.7492,
        QA_07: 94.234,
        VA_07: 0.60563,
        QC_07: 140.993,
        VC_07: 0.6809,
        QE_07: 254.397,
        VE_07: 0.75212,
        QA_08: 69.775,
        VA_08: 0.56179,
        QC_08: 157.584,
        VC_08: 0.70357,
        QE_08: 283.274,
        VE_08: 0.77819,
        QA_09: 59.886,
        VA_09: 0.54204,
        QC_09: 145.063,
        VC_09: 0.68657,
        QE_09: 230.432,
        VE_09: 0.72941,
        QA_10: 46.137,
        VA_10: 0.51182,
        QC_10: 117.399,
        VC_10: 0.64638,
        QE_10: 238.27,
        VE_10: 0.73696,
        QA_11: 69.635,
        VA_11: 0.56152,
        QC_11: 97.235,
        VC_11: 0.6142,
        QE_11: 235.136,
        VE_11: 0.73395,
        QA_12: 325.879,
        VA_12: 0.88282,
        QC_12: 255.658,
        VC_12: 0.81934,
        QE_12: 313.018,
        VE_12: 0.80377,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890910118214379, 34.99097521235376, 0.0],
          [-80.885844718222316, 34.991731612352567, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283767.0,
        COMID: 9751874,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.95,
        REACHCODE: "03050101000185",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.029842335988194,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060896.0,
        ToNode: 250060913.0,
        Hydroseq: 250018596.0,
        LevelPathI: 250015617.0,
        Pathlength: 654.84,
        TerminalPa: 250002604.0,
        ArbolateSu: 301.625,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250018882.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018326.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1877,
        TotDASqKM: 288.7551,
        DivDASqKM: 288.7551,
        Tidal: 0,
        TOTMA: 0.097704706550300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32479.0,
        MAXELEVSMO: 32996.0,
        MINELEVSMO: 32833.0,
        SLOPE: 0.00055254,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9500000476837158,
        QA_MA: 172.155,
        VA_MA: 1.14603,
        QC_MA: 170.734,
        VC_MA: 1.14546,
        QE_MA: 173.387,
        VE_MA: 1.14651,
        QA_01: 263.958,
        VA_01: 1.35942,
        QC_01: 206.831,
        VC_01: 1.23521,
        QE_01: 212.835,
        VE_01: 1.24289,
        QA_02: 279.145,
        VA_02: 1.39111,
        QC_02: 233.26,
        VC_02: 1.29632,
        QE_02: 234.129,
        VE_02: 1.29142,
        QA_03: 326.421,
        VA_03: 1.48491,
        QC_03: 274.572,
        VC_03: 1.38567,
        QE_03: 278.859,
        VE_03: 1.38706,
        QA_04: 266.378,
        VA_04: 1.36453,
        QC_04: 245.53,
        VC_04: 1.32358,
        QE_04: 247.18,
        VE_04: 1.32015,
        QA_05: 201.061,
        VA_05: 1.21799,
        QC_05: 187.24,
        VC_05: 1.1875,
        QE_05: 187.991,
        VE_05: 1.18328,
        QA_06: 118.876,
        VA_06: 0.99649,
        QC_06: 148.559,
        VC_06: 1.08585,
        QE_06: 151.383,
        VE_06: 1.08824,
        QA_07: 71.449,
        VA_07: 0.8335,
        QC_07: 107.612,
        VC_07: 0.9635,
        QE_07: 109.919,
        VE_07: 0.96634,
        QA_08: 48.734,
        VA_08: 0.73696,
        QC_08: 109.276,
        VC_08: 0.96886,
        QE_08: 114.218,
        VE_08: 0.97989,
        QA_09: 47.597,
        VA_09: 0.73162,
        QC_09: 118.439,
        VC_09: 0.99772,
        QE_09: 117.541,
        VE_09: 0.99019,
        QA_10: 67.901,
        VA_10: 0.81949,
        QC_10: 159.856,
        VC_10: 1.11671,
        QE_10: 157.8,
        VE_10: 1.10562,
        QA_11: 168.306,
        VA_11: 1.13603,
        QC_11: 190.598,
        VC_11: 1.19584,
        QE_11: 190.948,
        VE_11: 1.19056,
        QA_12: 206.898,
        VA_12: 1.23191,
        QC_12: 169.103,
        VC_12: 1.14121,
        QE_12: 169.441,
        VE_12: 1.13633,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.67200791700202, 35.921651210909204, 0.0],
          [-81.673188583666843, 35.919223077579602, 0.0],
          [-81.681574983653832, 35.915280410919024, 0.0],
          [-81.685906583647011, 35.910743877592608, 0.0],
          [-81.691340116971958, 35.911450677591631, 0.0],
          [-81.694320183634034, 35.907395610931189, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286946.0,
        COMID: 9731454,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 1.836,
        REACHCODE: "03050103000227",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018210551969725,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058908.0,
        ToNode: 250058950.0,
        Hydroseq: 250026291.0,
        LevelPathI: 250019853.0,
        Pathlength: 430.318,
        TerminalPa: 250002604.0,
        ArbolateSu: 334.89,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250027089.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025549.0,
        FromMeas: 41.7011,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.3885,
        TotDASqKM: 684.4842,
        DivDASqKM: 684.4842,
        Tidal: 0,
        TOTMA: 0.059990404591800002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14722.0,
        MAXELEVSMO: 15004.0,
        MINELEVSMO: 14969.0,
        SLOPE: 0.00019063,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8359999656677251,
        QA_MA: 271.907,
        VA_MA: 1.14285,
        QC_MA: 264.921,
        VC_MA: 1.14109,
        QE_MA: 360.594,
        VE_MA: 1.16215,
        QA_01: 575.853,
        VA_01: 1.55134,
        QC_01: 442.094,
        VC_01: 1.40129,
        QE_01: 576.486,
        VE_01: 1.4037,
        QA_02: 614.14,
        VA_02: 1.59456,
        QC_02: 502.277,
        VC_02: 1.47793,
        QE_02: 589.143,
        VE_02: 1.4164,
        QA_03: 661.561,
        VA_03: 1.64637,
        QC_03: 543.651,
        VC_03: 1.52813,
        QE_03: 634.572,
        VE_03: 1.46098,
        QA_04: 392.941,
        VA_04: 1.32304,
        QC_04: 359.0,
        VC_04: 1.28691,
        QE_04: 391.981,
        VE_04: 1.20084,
        QA_05: 220.841,
        VA_05: 1.05559,
        QC_05: 204.94,
        VC_05: 1.03498,
        QE_05: 282.35,
        VE_05: 1.05816,
        QA_06: 135.605,
        VA_06: 0.88511,
        QC_06: 169.161,
        VC_06: 0.9646,
        QE_06: 250.802,
        VE_06: 1.01244,
        QA_07: 93.966,
        VA_07: 0.78304,
        QC_07: 140.601,
        VC_07: 0.90322,
        QE_07: 254.006,
        VE_07: 1.0172,
        QA_08: 69.58,
        VA_08: 0.71304,
        QC_08: 157.135,
        VC_08: 0.9394,
        QE_08: 282.825,
        VE_08: 1.05883,
        QA_09: 59.715,
        VA_09: 0.68149,
        QC_09: 144.698,
        VC_09: 0.91236,
        QE_09: 230.067,
        VE_09: 0.9809,
        QA_10: 46.015,
        VA_10: 0.63327,
        QC_10: 117.151,
        VC_10: 0.84829,
        QE_10: 238.022,
        VE_10: 0.99315,
        QA_11: 69.425,
        VA_11: 0.71257,
        QC_11: 97.011,
        VC_11: 0.79683,
        QE_11: 234.912,
        VE_11: 0.98839,
        QA_12: 324.963,
        VA_12: 1.22569,
        QC_12: 255.005,
        VC_12: 1.12439,
        QE_12: 312.364,
        VE_12: 1.09948,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.899350384867887, 35.011741012321522, 0.0],
          [-80.900491384866271, 35.010274878990515, 0.0],
          [-80.899516318201108, 35.008030478993987, 0.0],
          [-80.902522184863074, 35.006266212330047, 0.0],
          [-80.89494858487484, 35.00015687900617, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285897.0,
        COMID: 9754068,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.379,
        REACHCODE: "03050101002572",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0041915061265450004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061508.0,
        ToNode: 250061515.0,
        Hydroseq: 250011090.0,
        LevelPathI: 250003258.0,
        Pathlength: 607.792,
        TerminalPa: 250002604.0,
        ArbolateSu: 2596.821,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011164.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011012.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2898,
        TotDASqKM: 2812.7943,
        DivDASqKM: 2812.7943,
        Tidal: 0,
        TOTMA: 0.13957290491,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30170.0,
        MAXELEVSMO: 30173.0,
        MINELEVSMO: 30173.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37900000810623202,
        QA_MA: 1743.393,
        VA_MA: -9998.0,
        QC_MA: 1743.393,
        VC_MA: -9998.0,
        QE_MA: 1938.437,
        VE_MA: -9998.0,
        QA_01: 2770.968,
        VA_01: -9998.0,
        QC_01: 2041.516,
        VC_01: -9998.0,
        QE_01: 2246.791,
        VE_01: -9998.0,
        QA_02: 2935.266,
        VA_02: -9998.0,
        QC_02: 2935.266,
        VC_02: -9998.0,
        QE_02: 3007.088,
        VE_02: -9998.0,
        QA_03: 3341.22,
        VA_03: -9998.0,
        QC_03: 3341.22,
        VC_03: -9998.0,
        QE_03: 3605.339,
        VE_03: -9998.0,
        QA_04: 2565.504,
        VA_04: -9998.0,
        QC_04: 2565.504,
        VC_04: -9998.0,
        QE_04: 2757.63,
        VE_04: -9998.0,
        QA_05: 1980.051,
        VA_05: -9998.0,
        QC_05: 1980.051,
        VC_05: -9998.0,
        QE_05: 2202.173,
        VE_05: -9998.0,
        QA_06: 1155.3,
        VA_06: -9998.0,
        QC_06: 1155.3,
        VC_06: -9998.0,
        QE_06: 1414.913,
        VE_06: -9998.0,
        QA_07: 683.723,
        VA_07: -9998.0,
        QC_07: 683.723,
        VC_07: -9998.0,
        QE_07: 830.616,
        VE_07: -9998.0,
        QA_08: 486.948,
        VA_08: -9998.0,
        QC_08: 486.948,
        VC_08: -9998.0,
        QE_08: 786.564,
        VE_08: -9998.0,
        QA_09: 492.173,
        VA_09: -9998.0,
        QC_09: 931.581,
        VC_09: -9998.0,
        QE_09: 968.088,
        VE_09: -9998.0,
        QA_10: 648.306,
        VA_10: -9998.0,
        QC_10: 969.392,
        VC_10: -9998.0,
        QE_10: 929.889,
        VE_10: -9998.0,
        QA_11: 1667.644,
        VA_11: -9998.0,
        QC_11: 1667.644,
        VC_11: -9998.0,
        QE_11: 1644.844,
        VE_11: -9998.0,
        QA_12: 2205.127,
        VA_12: -9998.0,
        QC_12: 2205.127,
        VC_12: -9998.0,
        QE_12: 2228.839,
        VE_12: -9998.0,
        LakeFract: 0.01824288192,
        SurfArea: 160403.07701499999,
        RAreaHLoad: 0.03373804431,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.454335917339847, 35.784708411121585, 0.0],
          [-81.450211584013005, 35.785012611121211, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286923.0,
        COMID: 9736366,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.98,
        REACHCODE: "03050103000208",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0091107079148850002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059943.0,
        ToNode: 250059948.0,
        Hydroseq: 250006015.0,
        LevelPathI: 250003258.0,
        Pathlength: 410.44,
        TerminalPa: 250002604.0,
        ArbolateSu: 6437.639,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006035.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005996.0,
        FromMeas: 0.0,
        ToMeas: 34.09945,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3555,
        TotDASqKM: 8732.2491,
        DivDASqKM: 8732.2491,
        Tidal: 0,
        TOTMA: 0.029301065491199998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13582.0,
        MAXELEVSMO: 13758.0,
        MINELEVSMO: 13758.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.980000019073486,
        QA_MA: 4433.757,
        VA_MA: 1.26456,
        QC_MA: 4433.757,
        VC_MA: 1.26456,
        QE_MA: 4756.865,
        VE_MA: 1.27003,
        QA_01: 8000.539,
        VA_01: 1.61654,
        QC_01: 8000.539,
        VC_01: 1.61654,
        QE_01: 6709.877,
        VE_01: 1.46274,
        QA_02: 8469.642,
        VA_02: 1.65672,
        QC_02: 8469.642,
        VC_02: 1.65672,
        QE_02: 6879.188,
        VE_02: 1.47812,
        QA_03: 9321.638,
        VA_03: 1.72712,
        QC_03: 9321.638,
        VC_03: 1.72712,
        QE_03: 7036.952,
        VE_03: 1.49229,
        QA_04: 6520.932,
        VA_04: 1.48193,
        QC_04: 6520.932,
        VC_04: 1.48193,
        QE_04: 5869.897,
        VE_04: 1.38361,
        QA_05: 4520.655,
        VA_05: 1.27447,
        QC_05: 4520.655,
        VC_05: 1.27447,
        QE_05: 4819.976,
        VE_05: 1.27678,
        QA_06: 2656.065,
        VA_06: 1.03679,
        QC_06: 2656.065,
        VC_06: 1.03679,
        QE_06: 4420.963,
        VE_06: 1.23335,
        QA_07: 1631.622,
        VA_07: 0.87073,
        QC_07: 1631.622,
        VC_07: 0.87073,
        QE_07: 3376.784,
        VE_07: 1.11005,
        QA_08: 1158.534,
        VA_08: 0.77724,
        QC_08: 1158.534,
        VC_08: 0.77724,
        QE_08: 3295.693,
        VE_08: 1.09977,
        QA_09: 1065.5,
        VA_09: 0.75686,
        QC_09: 1065.5,
        VC_09: 0.75686,
        QE_09: 2736.164,
        VE_09: 1.02532,
        QA_10: 1173.179,
        VA_10: 0.78038,
        QC_10: 1173.179,
        VC_10: 0.78038,
        QE_10: 3585.519,
        VE_10: 1.13599,
        QA_11: 3073.673,
        VA_11: 1.09552,
        QC_11: 3073.673,
        VC_11: 1.09552,
        QE_11: 3836.996,
        VE_11: 1.16632,
        QA_12: 5643.23,
        VA_12: 1.39522,
        QC_12: 5643.23,
        VC_12: 1.39522,
        QE_12: 4860.06,
        VE_12: 1.28105,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.879620118231912, 34.868191012544344, 0.0],
          [-80.879726784898367, 34.864266679217053, 0.0],
          [-80.87667798490321, 34.860203479223344, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286924.0,
        COMID: 9736342,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.211,
        REACHCODE: "03050103000209",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010889575922305999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059931.0,
        ToNode: 250059933.0,
        Hydroseq: 250006072.0,
        LevelPathI: 250003258.0,
        Pathlength: 413.317,
        TerminalPa: 250002604.0,
        ArbolateSu: 6427.321,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006091.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006054.0,
        FromMeas: 0.0,
        ToMeas: 52.3363,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6786,
        TotDASqKM: 8724.2967,
        DivDASqKM: 8724.2967,
        Tidal: 0,
        TOTMA: 0.024538247104099999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13875.0,
        MAXELEVSMO: 13999.0,
        MINELEVSMO: 13973.0,
        SLOPE: 0.00021469,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2109999656677251,
        QA_MA: 4430.547,
        VA_MA: 1.86511,
        QC_MA: 4430.547,
        VC_MA: 1.86511,
        QE_MA: 4753.636,
        VE_MA: 1.87401,
        QA_01: 7993.536,
        VA_01: 2.43798,
        QC_01: 7993.536,
        VC_01: 2.43798,
        QE_01: 6702.913,
        VE_01: 2.18741,
        QA_02: 8462.372,
        VA_02: 2.50341,
        QC_02: 8462.372,
        VC_02: 2.50341,
        QE_02: 6871.966,
        VE_02: 2.21243,
        QA_03: 9313.84,
        VA_03: 2.61805,
        QC_03: 9313.84,
        VC_03: 2.61805,
        QE_03: 7029.214,
        VE_03: 2.23544,
        QA_04: 6516.334,
        VA_04: 2.21902,
        QC_04: 6516.334,
        VC_04: 2.21902,
        QE_04: 5865.306,
        VE_04: 2.05883,
        QA_05: 4518.089,
        VA_05: 1.88137,
        QC_05: 4518.089,
        VC_05: 1.88137,
        QE_05: 4817.383,
        VE_05: 1.88512,
        QA_06: 2654.462,
        VA_06: 1.49436,
        QC_06: 2654.462,
        VC_06: 1.49436,
        QE_06: 4419.298,
        VE_06: 1.81454,
        QA_07: 1630.446,
        VA_07: 1.22393,
        QC_07: 1630.446,
        VC_07: 1.22393,
        QE_07: 3375.552,
        VE_07: 1.61377,
        QA_08: 1157.678,
        VA_08: 1.07171,
        QC_08: 1157.678,
        VC_08: 1.07171,
        QE_08: 3294.774,
        VE_08: 1.59709,
        QA_09: 1064.819,
        VA_09: 1.03856,
        QC_09: 1064.819,
        VC_09: 1.03856,
        QE_09: 2735.425,
        VE_09: 1.47586,
        QA_10: 1172.645,
        VA_10: 1.07694,
        QC_10: 1172.645,
        VC_10: 1.07694,
        QE_10: 3584.897,
        VE_10: 1.65615,
        QA_11: 3073.015,
        VA_11: 1.59024,
        QC_11: 3073.015,
        VC_11: 1.59024,
        QE_11: 3836.3,
        VE_11: 1.70554,
        QA_12: 5639.443,
        VA_12: 2.07788,
        QC_12: 5639.443,
        VC_12: 2.07788,
        QE_12: 4856.281,
        VE_12: 1.89187,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878898984899763, 34.895528212501915, 0.0],
          [-80.878166518234252, 34.884840879185106, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285907.0,
        COMID: 9754098,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.627,
        REACHCODE: "03050101002582",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.017905329082952001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061523.0,
        ToNode: 250061513.0,
        Hydroseq: 250011393.0,
        LevelPathI: 250003258.0,
        Pathlength: 611.063,
        TerminalPa: 250002604.0,
        ArbolateSu: 2559.179,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011471.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011312.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4805,
        TotDASqKM: 2772.6057,
        DivDASqKM: 2772.6057,
        Tidal: 0,
        TOTMA: 0.59916917218,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30167.0,
        MAXELEVSMO: 30178.0,
        MINELEVSMO: 30176.0,
        SLOPE: 1.229e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.626999974250793,
        QA_MA: 1722.5,
        VA_MA: -9998.0,
        QC_MA: 1722.5,
        VC_MA: -9998.0,
        QE_MA: 1917.544,
        VE_MA: -9998.0,
        QA_01: 2733.372,
        VA_01: -9998.0,
        QC_01: 2014.539,
        VC_01: -9998.0,
        QE_01: 2219.814,
        VE_01: -9998.0,
        QA_02: 2895.113,
        VA_02: -9998.0,
        QC_02: 2895.113,
        VC_02: -9998.0,
        QE_02: 2966.935,
        VE_02: -9998.0,
        QA_03: 3298.041,
        VA_03: -9998.0,
        QC_03: 3298.041,
        VC_03: -9998.0,
        QE_03: 3562.161,
        VE_03: -9998.0,
        QA_04: 2533.96,
        VA_04: -9998.0,
        QC_04: 2533.96,
        VC_04: -9998.0,
        QE_04: 2726.086,
        VE_04: -9998.0,
        QA_05: 1958.011,
        VA_05: -9998.0,
        QC_05: 1958.011,
        VC_05: -9998.0,
        QE_05: 2180.133,
        VE_05: -9998.0,
        QA_06: 1142.718,
        VA_06: -9998.0,
        QC_06: 1142.718,
        VC_06: -9998.0,
        QE_06: 1402.332,
        VE_06: -9998.0,
        QA_07: 676.135,
        VA_07: -9998.0,
        QC_07: 676.135,
        VC_07: -9998.0,
        QE_07: 823.029,
        VE_07: -9998.0,
        QA_08: 481.799,
        VA_08: -9998.0,
        QC_08: 481.799,
        VC_08: -9998.0,
        QE_08: 781.415,
        VE_08: -9998.0,
        QA_09: 488.005,
        VA_09: -9998.0,
        QC_09: 924.612,
        VC_09: -9998.0,
        QE_09: 961.119,
        VE_09: -9998.0,
        QA_10: 645.064,
        VA_10: -9998.0,
        QC_10: 965.518,
        VC_10: -9998.0,
        QE_10: 926.015,
        VE_10: -9998.0,
        QA_11: 1651.393,
        VA_11: -9998.0,
        QC_11: 1651.393,
        VC_11: -9998.0,
        QE_11: 1628.593,
        VE_11: -9998.0,
        QA_12: 2177.624,
        VA_12: -9998.0,
        QC_12: 2177.624,
        VC_12: -9998.0,
        QE_12: 2201.337,
        VE_12: -9998.0,
        LakeFract: 0.07747243532,
        SurfArea: 681187.164597,
        RAreaHLoad: 0.14483324034,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.502953583931117, 35.780312211128603, 0.0],
          [-81.488678983953264, 35.780538611128122, 0.0],
          [-81.485534983958132, 35.781913477792727, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286925.0,
        COMID: 9736332,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.102,
        REACHCODE: "03050103000209",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010053307067462,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059926.0,
        ToNode: 250059931.0,
        Hydroseq: 250006091.0,
        LevelPathI: 250003258.0,
        Pathlength: 414.528,
        TerminalPa: 250002604.0,
        ArbolateSu: 6424.075,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006108.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006072.0,
        FromMeas: 52.3363,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8064,
        TotDASqKM: 8721.5913,
        DivDASqKM: 8721.5913,
        Tidal: 0,
        TOTMA: 0.032955235553900002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13837.0,
        MAXELEVSMO: 13999.0,
        MINELEVSMO: 13999.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1019999980926509,
        QA_MA: 4429.453,
        VA_MA: 1.26432,
        QC_MA: 4429.453,
        VC_MA: 1.26432,
        QE_MA: 4752.536,
        VE_MA: 1.26978,
        QA_01: 7991.148,
        VA_01: 1.61606,
        QC_01: 7991.148,
        VC_01: 1.61606,
        QE_01: 6700.539,
        VE_01: 1.46215,
        QA_02: 8459.898,
        VA_02: 1.65624,
        QC_02: 8459.898,
        VC_02: 1.65624,
        QE_02: 6869.509,
        VE_02: 1.47751,
        QA_03: 9311.185,
        VA_03: 1.72664,
        QC_03: 9311.185,
        VC_03: 1.72664,
        QE_03: 7026.58,
        VE_03: 1.49163,
        QA_04: 6514.77,
        VA_04: 1.48164,
        QC_04: 6514.77,
        VC_04: 1.48164,
        QE_04: 5863.744,
        VE_04: 1.38326,
        QA_05: 4517.216,
        VA_05: 1.27433,
        QC_05: 4517.216,
        VC_05: 1.27433,
        QE_05: 4816.501,
        VE_05: 1.27663,
        QA_06: 2653.916,
        VA_06: 1.03667,
        QC_06: 2653.916,
        VC_06: 1.03667,
        QE_06: 4418.732,
        VE_06: 1.23331,
        QA_07: 1630.046,
        VA_07: 0.87059,
        QC_07: 1630.046,
        VC_07: 0.87059,
        QE_07: 3375.132,
        VE_07: 1.11002,
        QA_08: 1157.387,
        VA_08: 0.77711,
        QC_08: 1157.387,
        VC_08: 0.77711,
        QE_08: 3294.462,
        VE_08: 1.09979,
        QA_09: 1064.566,
        VA_09: 0.75676,
        QC_09: 1064.566,
        VC_09: 0.75676,
        QE_09: 2735.152,
        VE_09: 1.02534,
        QA_10: 1172.463,
        VA_10: 0.78035,
        QC_10: 1172.463,
        VC_10: 0.78035,
        QE_10: 3584.685,
        VE_10: 1.13608,
        QA_11: 3072.788,
        VA_11: 1.0956,
        QC_11: 3072.788,
        VC_11: 1.0956,
        QE_11: 3836.061,
        VE_11: 1.16641,
        QA_12: 5638.152,
        VA_12: 1.39498,
        QC_12: 5638.152,
        VC_12: 1.39498,
        QE_12: 4854.992,
        VE_12: 1.28073,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877200518235725, 34.904948812487191, 0.0],
          [-80.87920371823266, 34.899913212495107, 0.0],
          [-80.878898984899763, 34.895528212501915, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285908.0,
        COMID: 9754106,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.89,
        REACHCODE: "03050101002583",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.031963673599764,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061527.0,
        ToNode: 250061523.0,
        Hydroseq: 250011471.0,
        LevelPathI: 250003258.0,
        Pathlength: 612.69,
        TerminalPa: 250002604.0,
        ArbolateSu: 2543.571,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011552.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011393.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.0588,
        TotDASqKM: 2756.1726,
        DivDASqKM: 2756.1726,
        Tidal: 0,
        TOTMA: 1.0642894331399999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30163.0,
        MAXELEVSMO: 30179.0,
        MINELEVSMO: 30178.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.8900001049041748,
        QA_MA: 1713.646,
        VA_MA: -9998.0,
        QC_MA: 1713.646,
        VC_MA: -9998.0,
        QE_MA: 1908.69,
        VE_MA: -9998.0,
        QA_01: 2717.579,
        VA_01: -9998.0,
        QC_01: 2003.204,
        VC_01: -9998.0,
        QE_01: 2208.478,
        VE_01: -9998.0,
        QA_02: 2878.38,
        VA_02: -9998.0,
        QC_02: 2878.38,
        VC_02: -9998.0,
        QE_02: 2950.202,
        VE_02: -9998.0,
        QA_03: 3280.071,
        VA_03: -9998.0,
        QC_03: 3280.071,
        VC_03: -9998.0,
        QE_03: 3544.191,
        VE_03: -9998.0,
        QA_04: 2520.687,
        VA_04: -9998.0,
        QC_04: 2520.687,
        VC_04: -9998.0,
        QE_04: 2712.813,
        VE_04: -9998.0,
        QA_05: 1948.568,
        VA_05: -9998.0,
        QC_05: 1948.568,
        VC_05: -9998.0,
        QE_05: 2170.69,
        VE_05: -9998.0,
        QA_06: 1137.357,
        VA_06: -9998.0,
        QC_06: 1137.357,
        VC_06: -9998.0,
        QE_06: 1396.971,
        VE_06: -9998.0,
        QA_07: 672.96,
        VA_07: -9998.0,
        QC_07: 672.96,
        VC_07: -9998.0,
        QE_07: 819.854,
        VE_07: -9998.0,
        QA_08: 479.62,
        VA_08: -9998.0,
        QC_08: 479.62,
        VC_08: -9998.0,
        QE_08: 779.236,
        VE_08: -9998.0,
        QA_09: 486.249,
        VA_09: -9998.0,
        QC_09: 921.674,
        VC_09: -9998.0,
        QE_09: 958.18,
        VE_09: -9998.0,
        QA_10: 643.508,
        VA_10: -9998.0,
        QC_10: 963.657,
        VC_10: -9998.0,
        QE_10: 924.155,
        VE_10: -9998.0,
        QA_11: 1644.118,
        VA_11: -9998.0,
        QC_11: 1644.118,
        VC_11: -9998.0,
        QE_11: 1621.318,
        VE_11: -9998.0,
        QA_12: 2165.922,
        VA_12: -9998.0,
        QC_12: 2165.922,
        VC_12: -9998.0,
        QE_12: 2189.635,
        VE_12: -9998.0,
        LakeFract: 0.13697856869,
        SurfArea: 1204403.12004,
        RAreaHLoad: 0.25726371518000002,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.534385983882316, 35.779788011129426, 0.0],
          [-81.502953583931117, 35.780312211128603, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286926.0,
        COMID: 9736320,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.599,
        REACHCODE: "03050103000210",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0062396842070790003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059920.0,
        ToNode: 250059926.0,
        Hydroseq: 250006108.0,
        LevelPathI: 250003258.0,
        Pathlength: 415.63,
        TerminalPa: 250002604.0,
        ArbolateSu: 6415.109,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006130.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006091.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3024,
        TotDASqKM: 8713.2033,
        DivDASqKM: 8713.2033,
        Tidal: 0,
        TOTMA: 0.0097066276151299997,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13889.0,
        MAXELEVSMO: 14066.0,
        MINELEVSMO: 13999.0,
        SLOPE: 0.00111853,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59899997711181596,
        QA_MA: 4426.043,
        VA_MA: 2.33173,
        QC_MA: 4426.043,
        VC_MA: 2.33173,
        QE_MA: 4749.106,
        VE_MA: 2.34331,
        QA_01: 7983.735,
        VA_01: 3.076,
        QC_01: 7983.735,
        VC_01: 3.076,
        QE_01: 6693.166,
        VE_01: 2.75,
        QA_02: 8452.228,
        VA_02: 3.16109,
        QC_02: 8452.228,
        VC_02: 3.16109,
        QE_02: 6861.891,
        VE_02: 2.78249,
        QA_03: 9302.952,
        VA_03: 3.31012,
        QC_03: 9302.952,
        VC_03: 3.31012,
        QE_03: 7018.41,
        VE_03: 2.8123,
        QA_04: 6509.895,
        VA_04: 2.79176,
        QC_04: 6509.895,
        VC_04: 2.79176,
        QE_04: 5858.874,
        VE_04: 2.58336,
        QA_05: 4514.509,
        VA_05: 2.3531,
        QC_05: 4514.509,
        VC_05: 2.3531,
        QE_05: 4813.766,
        VE_05: 2.35797,
        QA_06: 2652.224,
        VA_06: 1.84999,
        QC_06: 2652.224,
        VC_06: 1.84999,
        QE_06: 4416.976,
        VE_06: 2.26646,
        QA_07: 1628.805,
        VA_07: 1.49836,
        QC_07: 1628.805,
        VC_07: 1.49836,
        QE_07: 3373.832,
        VE_07: 2.00546,
        QA_08: 1156.485,
        VA_08: 1.30051,
        QC_08: 1156.485,
        VC_08: 1.30051,
        QE_08: 3293.493,
        VE_08: 1.98388,
        QA_09: 1063.777,
        VA_09: 1.25745,
        QC_09: 1063.777,
        VC_09: 1.25745,
        QE_09: 2734.301,
        VE_09: 1.82625,
        QA_10: 1171.899,
        VA_10: 1.30751,
        QC_10: 1171.899,
        VC_10: 1.30751,
        QE_10: 3584.029,
        VE_10: 2.06081,
        QA_11: 3072.003,
        VA_11: 1.97508,
        QC_11: 3072.003,
        VC_11: 1.97508,
        QE_11: 3835.236,
        VE_11: 2.12499,
        QA_12: 5634.105,
        VA_12: 2.60836,
        QC_12: 5634.105,
        VC_12: 2.60836,
        QE_12: 4850.953,
        VE_12: 2.36636,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87175298491087, 34.90790687914938, 0.0],
          [-80.877200518235725, 34.904948812487191, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286920.0,
        COMID: 9736376,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.159,
        REACHCODE: "03050103000207",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011800436779552999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059948.0,
        ToNode: 250059949.0,
        Hydroseq: 250005996.0,
        LevelPathI: 250003258.0,
        Pathlength: 409.281,
        TerminalPa: 250002604.0,
        ArbolateSu: 6463.807,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006015.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005979.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6867,
        TotDASqKM: 8761.2732,
        DivDASqKM: 8761.2732,
        Tidal: 0,
        TOTMA: 0.034634723864299999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13600.0,
        MAXELEVSMO: 13758.0,
        MINELEVSMO: 13758.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.159000039100647,
        QA_MA: 4445.519,
        VA_MA: 1.26524,
        QC_MA: 4445.519,
        VC_MA: 1.26524,
        QE_MA: 4768.697,
        VE_MA: 1.2707,
        QA_01: 8026.218,
        VA_01: 1.61785,
        QC_01: 8026.218,
        VC_01: 1.61785,
        QE_01: 6735.416,
        VE_01: 1.46435,
        QA_02: 8496.178,
        VA_02: 1.65802,
        QC_02: 8496.178,
        VC_02: 1.65802,
        QE_02: 6905.545,
        VE_02: 1.47977,
        QA_03: 9350.069,
        VA_03: 1.72843,
        QC_03: 9350.069,
        VC_03: 1.72843,
        QE_03: 7065.163,
        VE_03: 1.49407,
        QA_04: 6537.795,
        VA_04: 1.48272,
        QC_04: 6537.795,
        VC_04: 1.48272,
        QE_04: 5886.74,
        VE_04: 1.38457,
        QA_05: 4530.02,
        VA_05: 1.27486,
        QC_05: 4530.02,
        VC_05: 1.27486,
        QE_05: 4829.44,
        VE_05: 1.27719,
        QA_06: 2661.919,
        VA_06: 1.03714,
        QC_06: 2661.919,
        VC_06: 1.03714,
        QE_06: 4427.037,
        VE_06: 1.23345,
        QA_07: 1635.915,
        VA_07: 0.87112,
        QC_07: 1635.915,
        VC_07: 0.87112,
        QE_07: 3381.284,
        VE_07: 1.11012,
        QA_08: 1161.656,
        VA_08: 0.77758,
        QC_08: 1161.656,
        VC_08: 0.77758,
        QE_08: 3299.045,
        VE_08: 1.0997,
        QA_09: 1068.12,
        VA_09: 0.75713,
        QC_09: 1068.12,
        VC_09: 0.75713,
        QE_09: 2738.996,
        VE_09: 1.02527,
        QA_10: 1175.13,
        VA_10: 0.78047,
        QC_10: 1175.13,
        VC_10: 0.78047,
        QE_10: 3587.791,
        VE_10: 1.13575,
        QA_11: 3076.276,
        VA_11: 1.09533,
        QC_11: 3076.276,
        VC_11: 1.09533,
        QE_11: 3839.734,
        VE_11: 1.16611,
        QA_12: 5657.195,
        VA_12: 1.39589,
        QC_12: 5657.195,
        VC_12: 1.39589,
        QE_12: 4873.997,
        VE_12: 1.28192,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87667798490321, 34.860203479223344, 0.0],
          [-80.867111318251375, 34.85352627923379, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285903.0,
        COMID: 9754082,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.552,
        REACHCODE: "03050101002578",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015128591726889001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061515.0,
        ToNode: 250061520.0,
        Hydroseq: 250011012.0,
        LevelPathI: 250003258.0,
        Pathlength: 606.24,
        TerminalPa: 250002604.0,
        ArbolateSu: 2600.689,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011090.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010935.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.7314,
        TotDASqKM: 2818.8063,
        DivDASqKM: 2818.8063,
        Tidal: 0,
        TOTMA: 0.57154920422,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30164.0,
        MAXELEVSMO: 30173.0,
        MINELEVSMO: 30171.0,
        SLOPE: 1.288e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.552000045776367,
        QA_MA: 1746.436,
        VA_MA: -9998.0,
        QC_MA: 1746.436,
        VC_MA: -9998.0,
        QE_MA: 1941.48,
        VE_MA: -9998.0,
        QA_01: 2776.5,
        VA_01: -9998.0,
        QC_01: 2045.485,
        VC_01: -9998.0,
        QE_01: 2250.76,
        VE_01: -9998.0,
        QA_02: 2941.247,
        VA_02: -9998.0,
        QC_02: 2941.247,
        VC_02: -9998.0,
        QE_02: 3013.07,
        VE_02: -9998.0,
        QA_03: 3347.608,
        VA_03: -9998.0,
        QC_03: 3347.608,
        VC_03: -9998.0,
        QE_03: 3611.728,
        VE_03: -9998.0,
        QA_04: 2570.079,
        VA_04: -9998.0,
        QC_04: 2570.079,
        VC_04: -9998.0,
        QE_04: 2762.204,
        VE_04: -9998.0,
        QA_05: 1983.19,
        VA_05: -9998.0,
        QC_05: 1983.19,
        VC_05: -9998.0,
        QE_05: 2205.312,
        VE_05: -9998.0,
        QA_06: 1157.095,
        VA_06: -9998.0,
        QC_06: 1157.095,
        VC_06: -9998.0,
        QE_06: 1416.708,
        VE_06: -9998.0,
        QA_07: 684.819,
        VA_07: -9998.0,
        QC_07: 684.819,
        VC_07: -9998.0,
        QE_07: 831.713,
        VE_07: -9998.0,
        QA_08: 487.675,
        VA_08: -9998.0,
        QC_08: 487.675,
        VC_08: -9998.0,
        QE_08: 787.291,
        VE_08: -9998.0,
        QA_09: 492.741,
        VA_09: -9998.0,
        QC_09: 932.531,
        VC_09: -9998.0,
        QE_09: 969.037,
        VE_09: -9998.0,
        QA_10: 648.791,
        VA_10: -9998.0,
        QC_10: 969.972,
        VC_10: -9998.0,
        QE_10: 930.469,
        VE_10: -9998.0,
        QA_11: 1669.907,
        VA_11: -9998.0,
        QC_11: 1669.907,
        VC_11: -9998.0,
        QE_11: 1647.107,
        VE_11: -9998.0,
        QA_12: 2209.125,
        VA_12: -9998.0,
        QC_12: 2209.125,
        VC_12: -9998.0,
        QE_12: 2232.838,
        VE_12: -9998.0,
        LakeFract: 0.07482134157000001,
        SurfArea: 657877.05401199998,
        RAreaHLoad: 0.13815684635,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.450211584013005, 35.785012611121211, 0.0],
          [-81.44561631735337, 35.785122877787728, 0.0],
          [-81.443860584022843, 35.783686011123336, 0.0],
          [-81.44316571735726, 35.775873277802084, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288923.0,
        COMID: 9714525,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 1.117,
        REACHCODE: "03050104000142",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010098054871993001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057126.0,
        ToNode: 250057140.0,
        Hydroseq: 250032464.0,
        LevelPathI: 250021319.0,
        Pathlength: 359.006,
        TerminalPa: 250002604.0,
        ArbolateSu: 50.076,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250033944.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031162.0,
        FromMeas: 0.0,
        ToMeas: 95.29584,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1115,
        TotDASqKM: 50.994,
        DivDASqKM: 50.994,
        Tidal: 0,
        TOTMA: 0.071069147709899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8694.0,
        MAXELEVSMO: 8962.0,
        MINELEVSMO: 8962.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1169999837875371,
        QA_MA: 19.485,
        VA_MA: 0.59505,
        QC_MA: 21.029,
        VC_MA: 0.59682,
        QE_MA: 21.029,
        VE_MA: 0.59682,
        QA_01: 43.332,
        VA_01: 0.7466,
        QC_01: 35.601,
        VC_01: 0.68986,
        QE_01: 35.601,
        VE_01: 0.68986,
        QA_02: 44.59,
        VA_02: 0.75331,
        QC_02: 39.171,
        VC_02: 0.70971,
        QE_02: 39.171,
        VE_02: 0.70971,
        QA_03: 47.692,
        VA_03: 0.76948,
        QC_03: 42.746,
        VC_03: 0.72876,
        QE_03: 42.746,
        VE_03: 0.72876,
        QA_04: 28.016,
        VA_04: 0.65601,
        QC_04: 27.179,
        VC_04: 0.63892,
        QE_04: 27.179,
        VE_04: 0.63892,
        QA_05: 15.539,
        VA_05: 0.56259,
        QC_05: 15.925,
        VC_05: 0.55724,
        QE_05: 15.925,
        VE_05: 0.55724,
        QA_06: 9.862,
        VA_06: 0.50807,
        QC_06: 12.753,
        VC_06: 0.52955,
        QE_06: 12.753,
        VE_06: 0.52955,
        QA_07: 7.542,
        VA_07: 0.48156,
        QC_07: 11.987,
        VC_07: 0.5224,
        QE_07: 11.987,
        VE_07: 0.5224,
        QA_08: 5.485,
        VA_08: 0.45462,
        QC_08: 11.773,
        VC_08: 0.52036,
        QE_08: 11.773,
        VE_08: 0.52036,
        QA_09: 4.176,
        VA_09: 0.43491,
        QC_09: 13.819,
        VC_09: 0.53918,
        QE_09: 13.819,
        VE_09: 0.53918,
        QA_10: 3.428,
        VA_10: 0.42232,
        QC_10: 14.716,
        VC_10: 0.54702,
        QE_10: 14.716,
        VE_10: 0.54702,
        QA_11: 3.179,
        VA_11: 0.41785,
        QC_11: 9.237,
        VC_11: 0.49474,
        QE_11: 9.237,
        VE_11: 0.49474,
        QA_12: 21.071,
        VA_12: 0.60721,
        QC_12: 21.161,
        VC_12: 0.59778,
        QE_12: 21.161,
        VE_12: 0.59778,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.710066718495057, 34.50016401311558, 0.0],
          [-80.712351585158217, 34.490405879797322, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286921.0,
        COMID: 9736352,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.995,
        REACHCODE: "03050103000208",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0089697990933990009,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059936.0,
        ToNode: 250059943.0,
        Hydroseq: 250006035.0,
        LevelPathI: 250003258.0,
        Pathlength: 411.42,
        TerminalPa: 250002604.0,
        ArbolateSu: 6434.961,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006054.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006015.0,
        FromMeas: 34.09945,
        ToMeas: 68.6617,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8433,
        TotDASqKM: 8730.612,
        DivDASqKM: 8730.612,
        Tidal: 0,
        TOTMA: 0.014708068567799999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13643.0,
        MAXELEVSMO: 13973.0,
        MINELEVSMO: 13758.0,
        SLOPE: 0.0021608,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.99500000476837203,
        QA_MA: 4433.1,
        VA_MA: 2.55601,
        QC_MA: 4433.1,
        VC_MA: 2.55601,
        QE_MA: 4756.204,
        VE_MA: 2.56885,
        QA_01: 7999.108,
        VA_01: 3.38332,
        QC_01: 7999.108,
        VC_01: 3.38332,
        QE_01: 6708.454,
        VE_01: 3.02175,
        QA_02: 8468.145,
        VA_02: 3.47778,
        QC_02: 8468.145,
        VC_02: 3.47778,
        QE_02: 6877.701,
        VE_02: 3.05789,
        QA_03: 9320.037,
        VA_03: 3.64328,
        QC_03: 9320.037,
        VC_03: 3.64328,
        QE_03: 7035.363,
        VE_03: 3.09118,
        QA_04: 6519.985,
        VA_04: 3.06697,
        QC_04: 6519.985,
        VC_04: 3.06697,
        QE_04: 5868.952,
        VE_04: 2.83581,
        QA_05: 4520.126,
        VA_05: 2.57933,
        QC_05: 4520.126,
        VC_05: 2.57933,
        QE_05: 4819.442,
        VE_05: 2.58476,
        QA_06: 2655.735,
        VA_06: 2.02062,
        QC_06: 2655.735,
        VC_06: 2.02062,
        QE_06: 4420.62,
        VE_06: 2.48271,
        QA_07: 1631.38,
        VA_07: 1.63026,
        QC_07: 1631.38,
        VC_07: 1.63026,
        QE_07: 3376.531,
        VE_07: 2.19286,
        QA_08: 1158.358,
        VA_08: 1.41049,
        QC_08: 1158.358,
        VC_08: 1.41049,
        QE_08: 3295.504,
        VE_08: 2.16871,
        QA_09: 1065.368,
        VA_09: 1.36259,
        QC_09: 1065.368,
        VC_09: 1.36259,
        QE_09: 2736.02,
        VE_09: 1.99371,
        QA_10: 1173.069,
        VA_10: 1.4179,
        QC_10: 1173.069,
        VC_10: 1.4179,
        QE_10: 3585.391,
        VE_10: 2.25389,
        QA_11: 3073.548,
        VA_11: 2.15876,
        QC_11: 3073.548,
        VC_11: 2.15876,
        QE_11: 3836.863,
        VE_11: 2.32519,
        QA_12: 5642.46,
        VA_12: 2.86315,
        QC_12: 5642.46,
        VC_12: 2.86315,
        QE_12: 4859.291,
        VE_12: 2.59474,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880066184897942, 34.876940079197368, 0.0],
          [-80.879620118231912, 34.868191012544344, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288924.0,
        COMID: 9713403,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.055,
        REACHCODE: "03050104000142",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00056645649344699996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057072.0,
        ToNode: 250057126.0,
        Hydroseq: 250033944.0,
        LevelPathI: 250021319.0,
        Pathlength: 360.123,
        TerminalPa: 250002604.0,
        ArbolateSu: 48.959,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250035614.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032464.0,
        FromMeas: 95.29584,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0927,
        TotDASqKM: 49.8825,
        DivDASqKM: 49.8825,
        Tidal: 0,
        TOTMA: 0.0035077219917200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8792.0,
        MAXELEVSMO: 8962.0,
        MINELEVSMO: 8962.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054999999701976998,
        QA_MA: 19.072,
        VA_MA: 0.59361,
        QC_MA: 20.6,
        VC_MA: 0.5954,
        QE_MA: 20.6,
        VE_MA: 0.5954,
        QA_01: 42.411,
        VA_01: 0.74439,
        QC_01: 34.863,
        VC_01: 0.68791,
        QE_01: 34.863,
        VE_01: 0.68791,
        QA_02: 43.634,
        VA_02: 0.75102,
        QC_02: 38.354,
        VC_02: 0.70763,
        QE_02: 38.354,
        VE_02: 0.70763,
        QA_03: 46.661,
        VA_03: 0.76707,
        QC_03: 41.852,
        VC_03: 0.72657,
        QE_03: 41.852,
        VE_03: 0.72657,
        QA_04: 27.423,
        VA_04: 0.65428,
        QC_04: 26.617,
        VC_04: 0.63724,
        QE_04: 26.617,
        VE_04: 0.63724,
        QA_05: 15.21,
        VA_05: 0.56132,
        QC_05: 15.601,
        VC_05: 0.55602,
        QE_05: 15.601,
        VE_05: 0.55602,
        QA_06: 9.653,
        VA_06: 0.50707,
        QC_06: 12.486,
        VC_06: 0.5284,
        QE_06: 12.486,
        VE_06: 0.5284,
        QA_07: 7.377,
        VA_07: 0.48063,
        QC_07: 11.732,
        VC_07: 0.52124,
        QE_07: 11.732,
        VE_07: 0.52124,
        QA_08: 5.365,
        VA_08: 0.45383,
        QC_08: 11.511,
        VC_08: 0.5191,
        QE_08: 11.511,
        VE_08: 0.5191,
        QA_09: 4.087,
        VA_09: 0.43427,
        QC_09: 13.556,
        VC_09: 0.53821,
        QE_09: 13.556,
        VE_09: 0.53821,
        QA_10: 3.353,
        VA_10: 0.42171,
        QC_10: 14.459,
        VC_10: 0.54622,
        QE_10: 14.459,
        VE_10: 0.54622,
        QA_11: 3.12,
        VA_11: 0.41746,
        QC_11: 9.104,
        VC_11: 0.49442,
        QE_11: 9.104,
        VE_11: 0.49442,
        QA_12: 20.654,
        VA_12: 0.60594,
        QC_12: 20.78,
        VC_12: 0.59673,
        QE_12: 20.78,
        VE_12: 0.59673,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.709570718495868, 34.500437613115139, 0.0],
          [-80.710066718495057, 34.50016401311558, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286922.0,
        COMID: 9736346,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.902,
        REACHCODE: "03050103000208",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0081519069565440007,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059933.0,
        ToNode: 250059936.0,
        Hydroseq: 250006054.0,
        LevelPathI: 250003258.0,
        Pathlength: 412.415,
        TerminalPa: 250002604.0,
        ArbolateSu: 6432.209,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006072.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006035.0,
        FromMeas: 68.6617,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9531,
        TotDASqKM: 8728.4043,
        DivDASqKM: 8728.4043,
        Tidal: 0,
        TOTMA: 0.026970850919900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13783.0,
        MAXELEVSMO: 13973.0,
        MINELEVSMO: 13973.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.90200001001357999,
        QA_MA: 4432.211,
        VA_MA: 1.26447,
        QC_MA: 4432.211,
        VC_MA: 1.26447,
        QE_MA: 4755.31,
        VE_MA: 1.26994,
        QA_01: 7997.164,
        VA_01: 1.61636,
        QC_01: 7997.164,
        VC_01: 1.61636,
        QE_01: 6706.521,
        VE_01: 1.46253,
        QA_02: 8466.127,
        VA_02: 1.65655,
        QC_02: 8466.127,
        VC_02: 1.65655,
        QE_02: 6875.696,
        VE_02: 1.4779,
        QA_03: 9317.872,
        VA_03: 1.72695,
        QC_03: 9317.872,
        VC_03: 1.72695,
        QE_03: 7033.215,
        VE_03: 1.49205,
        QA_04: 6518.709,
        VA_04: 1.48183,
        QC_04: 6518.709,
        VC_04: 1.48183,
        QE_04: 5867.677,
        VE_04: 1.38348,
        QA_05: 4519.414,
        VA_05: 1.27442,
        QC_05: 4519.414,
        VC_05: 1.27442,
        QE_05: 4818.722,
        VE_05: 1.27673,
        QA_06: 2655.29,
        VA_06: 1.03675,
        QC_06: 2655.29,
        VC_06: 1.03675,
        QE_06: 4420.158,
        VE_06: 1.23334,
        QA_07: 1631.054,
        VA_07: 0.87068,
        QC_07: 1631.054,
        VC_07: 0.87068,
        QE_07: 3376.188,
        VE_07: 1.11004,
        QA_08: 1158.12,
        VA_08: 0.77719,
        QC_08: 1158.12,
        VC_08: 0.77719,
        QE_08: 3295.249,
        VE_08: 1.09978,
        QA_09: 1065.187,
        VA_09: 0.75683,
        QC_09: 1065.187,
        VC_09: 0.75683,
        QE_09: 2735.823,
        VE_09: 1.02533,
        QA_10: 1172.921,
        VA_10: 0.78037,
        QC_10: 1172.921,
        VC_10: 0.78037,
        QE_10: 3585.219,
        VE_10: 1.13602,
        QA_11: 3073.372,
        VA_11: 1.09555,
        QC_11: 3073.372,
        VC_11: 1.09555,
        QE_11: 3836.676,
        VE_11: 1.16636,
        QA_12: 5641.415,
        VA_12: 1.39514,
        QC_12: 5641.415,
        VC_12: 1.39514,
        QE_12: 4858.249,
        VE_12: 1.28094,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878166518234252, 34.884840879185106, 0.0],
          [-80.880066184897942, 34.876940079197368, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288925.0,
        COMID: 9713389,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.271,
        REACHCODE: "03050104000143",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00250935651714,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057069.0,
        ToNode: 250057072.0,
        Hydroseq: 250035614.0,
        LevelPathI: 250021319.0,
        Pathlength: 360.178,
        TerminalPa: 250002604.0,
        ArbolateSu: 46.472,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250037533.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033944.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0819,
        TotDASqKM: 47.6838,
        DivDASqKM: 47.6838,
        Tidal: 0,
        TOTMA: 0.0093337060585800009,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8795.0,
        MAXELEVSMO: 9136.0,
        MINELEVSMO: 8962.0,
        SLOPE: 0.00642066,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.27099999785423301,
        QA_MA: 18.242,
        VA_MA: 1.09746,
        QC_MA: 19.738,
        VC_MA: 1.10252,
        QE_MA: 19.738,
        VE_MA: 1.10252,
        QA_01: 40.564,
        VA_01: 1.51456,
        QC_01: 33.384,
        VC_01: 1.35813,
        QE_01: 33.384,
        VE_01: 1.35813,
        QA_02: 41.731,
        VA_02: 1.53286,
        QC_02: 36.725,
        VC_02: 1.41266,
        QE_02: 36.725,
        VE_02: 1.41266,
        QA_03: 44.621,
        VA_03: 1.57718,
        QC_03: 40.081,
        VC_03: 1.46515,
        QE_03: 40.081,
        VE_03: 1.46515,
        QA_04: 26.23,
        VA_04: 1.26529,
        QC_04: 25.485,
        VC_04: 1.21793,
        QE_04: 25.485,
        VE_04: 1.21793,
        QA_05: 14.548,
        VA_05: 1.00813,
        QC_05: 14.947,
        VC_05: 0.99351,
        QE_05: 14.947,
        VE_05: 0.99351,
        QA_06: 9.239,
        VA_06: 0.85822,
        QC_06: 11.958,
        VC_06: 0.91697,
        QE_06: 11.958,
        VE_06: 0.91697,
        QA_07: 7.052,
        VA_07: 0.78475,
        QC_07: 11.227,
        VC_07: 0.89692,
        QE_07: 11.227,
        VE_07: 0.89692,
        QA_08: 5.129,
        VA_08: 0.71065,
        QC_08: 10.994,
        VC_08: 0.8904,
        QE_08: 10.994,
        VE_08: 0.8904,
        QA_09: 3.909,
        VA_09: 0.65662,
        QC_09: 13.036,
        VC_09: 0.94551,
        QE_09: 13.036,
        VE_09: 0.94551,
        QA_10: 3.206,
        VA_10: 0.62183,
        QC_10: 13.948,
        VC_10: 0.9688,
        QE_10: 13.948,
        VE_10: 0.9688,
        QA_11: 3.001,
        VA_11: 0.61102,
        QC_11: 8.84,
        VC_11: 0.82677,
        QE_11: 8.84,
        VE_11: 0.82677,
        QA_12: 19.777,
        VA_12: 1.13205,
        QC_12: 19.975,
        VC_12: 1.10756,
        QE_12: 19.975,
        VE_12: 1.10756,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.709434518496039, 34.502682479778343, 0.0],
          [-80.709570718495868, 34.500437613115139, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286931.0,
        COMID: 9736272,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.279,
        REACHCODE: "03050103000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0026266177315180002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059888.0,
        ToNode: 250059900.0,
        Hydroseq: 250006311.0,
        LevelPathI: 250003258.0,
        Pathlength: 420.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 6390.172,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006334.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006254.0,
        FromMeas: 81.23658,
        ToMeas: 93.47308,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1098,
        TotDASqKM: 8694.7227,
        DivDASqKM: 8694.7227,
        Tidal: 0,
        TOTMA: 0.0083475524763000006,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14346.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.279000014066696,
        QA_MA: 4418.525,
        VA_MA: 1.26369,
        QC_MA: 4418.525,
        VC_MA: 1.26369,
        QE_MA: 4741.543,
        VE_MA: 1.26916,
        QA_01: 7967.366,
        VA_01: 1.61484,
        QC_01: 7967.366,
        VC_01: 1.61484,
        QE_01: 6676.887,
        VE_01: 1.46066,
        QA_02: 8435.302,
        VA_02: 1.65504,
        QC_02: 8435.302,
        VC_02: 1.65504,
        QE_02: 6845.079,
        VE_02: 1.47598,
        QA_03: 9284.809,
        VA_03: 1.72543,
        QC_03: 9284.809,
        VC_03: 1.72543,
        QE_03: 7000.407,
        VE_03: 1.48998,
        QA_04: 6499.189,
        VA_04: 1.48092,
        QC_04: 6499.189,
        VC_04: 1.48092,
        QE_04: 5848.182,
        VE_04: 1.38237,
        QA_05: 4508.546,
        VA_05: 1.27397,
        QC_05: 4508.546,
        VC_05: 1.27397,
        QE_05: 4807.74,
        VE_05: 1.27626,
        QA_06: 2648.497,
        VA_06: 1.03635,
        QC_06: 2648.497,
        VC_06: 1.03635,
        QE_06: 4413.108,
        VE_06: 1.23323,
        QA_07: 1626.072,
        VA_07: 0.87022,
        QC_07: 1626.072,
        VC_07: 0.87022,
        QE_07: 3370.967,
        VE_07: 1.10997,
        QA_08: 1154.497,
        VA_08: 0.7768,
        QC_08: 1154.497,
        VC_08: 0.7768,
        QE_08: 3291.358,
        VE_08: 1.09986,
        QA_09: 1062.037,
        VA_09: 0.75649,
        QC_09: 1062.037,
        VC_09: 0.75649,
        QE_09: 2732.427,
        VE_09: 1.02538,
        QA_10: 1170.656,
        VA_10: 0.78027,
        QC_10: 1170.656,
        VC_10: 0.78027,
        QE_10: 3582.583,
        VE_10: 1.1363,
        QA_11: 3070.254,
        VA_11: 1.09577,
        QC_11: 3070.254,
        VC_11: 1.09577,
        QE_11: 3833.401,
        VE_11: 1.16659,
        QA_12: 5625.133,
        VA_12: 1.39435,
        QC_12: 5625.133,
        VC_12: 1.39435,
        QE_12: 4842.0,
        VE_12: 1.27992,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.869395184914538, 34.942634879095522, 0.0],
          [-80.867990518250053, 34.940415412432287, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285906.0,
        COMID: 9754092,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.646,
        REACHCODE: "03050101002581",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751288,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0066454867970629997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061520.0,
        ToNode: 250061522.0,
        Hydroseq: 250010935.0,
        LevelPathI: 250003258.0,
        Pathlength: 605.594,
        TerminalPa: 250002604.0,
        ArbolateSu: 2605.578,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250011012.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010859.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6164,
        TotDASqKM: 2824.7058,
        DivDASqKM: 2824.7058,
        Tidal: 0,
        TOTMA: 0.23789999091,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29236.0,
        MAXELEVSMO: 30171.0,
        MINELEVSMO: 29236.0,
        SLOPE: 0.01447368,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.64600002765655495,
        QA_MA: 1749.448,
        VA_MA: -9998.0,
        QC_MA: 1749.448,
        VC_MA: -9998.0,
        QE_MA: 1944.492,
        VE_MA: -9998.0,
        QA_01: 2781.968,
        VA_01: -9998.0,
        QC_01: 2049.408,
        VC_01: -9998.0,
        QE_01: 2254.683,
        VE_01: -9998.0,
        QA_02: 2947.136,
        VA_02: -9998.0,
        QC_02: 2947.136,
        VC_02: -9998.0,
        QE_02: 3018.959,
        VE_02: -9998.0,
        QA_03: 3353.908,
        VA_03: -9998.0,
        QC_03: 3353.908,
        VC_03: -9998.0,
        QE_03: 3618.027,
        VE_03: -9998.0,
        QA_04: 2574.59,
        VA_04: -9998.0,
        QC_04: 2574.59,
        VC_04: -9998.0,
        QE_04: 2766.716,
        VE_04: -9998.0,
        QA_05: 1986.297,
        VA_05: -9998.0,
        QC_05: 1986.297,
        VC_05: -9998.0,
        QE_05: 2208.419,
        VE_05: -9998.0,
        QA_06: 1158.873,
        VA_06: -9998.0,
        QC_06: 1158.873,
        VC_06: -9998.0,
        QE_06: 1418.487,
        VE_06: -9998.0,
        QA_07: 685.909,
        VA_07: -9998.0,
        QC_07: 685.909,
        VC_07: -9998.0,
        QE_07: 832.803,
        VE_07: -9998.0,
        QA_08: 488.389,
        VA_08: -9998.0,
        QC_08: 488.389,
        VC_08: -9998.0,
        QE_08: 788.005,
        VE_08: -9998.0,
        QA_09: 493.305,
        VA_09: -9998.0,
        QC_09: 933.472,
        VC_09: -9998.0,
        QE_09: 969.979,
        VE_09: -9998.0,
        QA_10: 649.267,
        VA_10: -9998.0,
        QC_10: 970.54,
        VC_10: -9998.0,
        QE_10: 931.037,
        VE_10: -9998.0,
        QA_11: 1672.173,
        VA_11: -9998.0,
        QC_11: 1672.173,
        VC_11: -9998.0,
        QE_11: 1649.373,
        VE_11: -9998.0,
        QA_12: 2213.098,
        VA_12: -9998.0,
        QC_12: 2213.098,
        VC_12: -9998.0,
        QE_12: 2236.811,
        VE_12: -9998.0,
        LakeFract: 0.03119161494,
        SurfArea: 274256.61336700001,
        RAreaHLoad: 0.05750600692,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.44316571735726, 35.775873277802084, 0.0],
          [-81.441258117360121, 35.773468611139151, 0.0],
          [-81.43794751736533, 35.773381077806, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288926.0,
        COMID: 9713371,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.872,
        REACHCODE: "03050104000144",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0080998683808989995,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057063.0,
        ToNode: 250057069.0,
        Hydroseq: 250037533.0,
        LevelPathI: 250021319.0,
        Pathlength: 360.449,
        TerminalPa: 250002604.0,
        ArbolateSu: 43.874,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250050359.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250035614.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8928,
        TotDASqKM: 46.0143,
        DivDASqKM: 46.0143,
        Tidal: 0,
        TOTMA: 0.034314562971299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9085.0,
        MAXELEVSMO: 9314.0,
        MINELEVSMO: 9136.0,
        SLOPE: 0.00204128,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.87199997901916504,
        QA_MA: 17.62,
        VA_MA: 0.96071,
        QC_MA: 19.09,
        VC_MA: 0.96496,
        QE_MA: 19.09,
        VE_MA: 0.96496,
        QA_01: 39.179,
        VA_01: 1.3055,
        QC_01: 32.273,
        VC_01: 1.17609,
        QE_01: 32.273,
        VE_01: 1.17609,
        QA_02: 40.293,
        VA_02: 1.32046,
        QC_02: 35.494,
        VC_02: 1.22104,
        QE_02: 35.494,
        VE_02: 1.22104,
        QA_03: 43.072,
        VA_03: 1.35694,
        QC_03: 38.735,
        VC_03: 1.26439,
        QE_03: 38.735,
        VE_03: 1.26439,
        QA_04: 25.338,
        VA_04: 1.0995,
        QC_04: 24.638,
        VC_04: 1.06021,
        QE_04: 24.638,
        VE_04: 1.06021,
        QA_05: 14.054,
        VA_05: 0.8869,
        QC_05: 14.458,
        VC_05: 0.87488,
        QE_05: 14.458,
        VE_05: 0.87488,
        QA_06: 8.925,
        VA_06: 0.76296,
        QC_06: 11.557,
        VC_06: 0.81136,
        QE_06: 11.557,
        VE_06: 0.81136,
        QA_07: 6.805,
        VA_07: 0.702,
        QC_07: 10.843,
        VC_07: 0.79461,
        QE_07: 10.843,
        VE_07: 0.79461,
        QA_08: 4.949,
        VA_08: 0.64076,
        QC_08: 10.601,
        VC_08: 0.78882,
        QE_08: 10.601,
        VE_08: 0.78882,
        QA_09: 3.775,
        VA_09: 0.59625,
        QC_09: 12.638,
        VC_09: 0.83582,
        QE_09: 12.638,
        VE_09: 0.83582,
        QA_10: 3.093,
        VA_10: 0.56734,
        QC_10: 13.557,
        VC_10: 0.85585,
        QE_10: 13.557,
        VE_10: 0.85585,
        QA_11: 2.911,
        VA_11: 0.55914,
        QC_11: 8.637,
        VC_11: 0.73929,
        QE_11: 8.637,
        VE_11: 0.73929,
        QA_12: 19.137,
        VA_12: 0.98995,
        QC_12: 19.386,
        VC_12: 0.97034,
        QE_12: 19.386,
        VE_12: 0.97034,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.706231718501044, 34.50983167976716, 0.0],
          [-80.709434518496039, 34.502682479778343, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286932.0,
        COMID: 9736280,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.054,
        REACHCODE: "03050103000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00050930807884499996,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059901.0,
        ToNode: 250059906.0,
        Hydroseq: 250006234.0,
        LevelPathI: 250003258.0,
        Pathlength: 419.377,
        TerminalPa: 250002604.0,
        ArbolateSu: 6391.69,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006254.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006215.0,
        FromMeas: 44.72909,
        ToMeas: 47.10356,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 8696.0061,
        DivDASqKM: 8696.0061,
        Tidal: 0,
        TOTMA: 0.001615617089,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14418.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054000001400709,
        QA_MA: 4419.048,
        VA_MA: 1.26372,
        QC_MA: 4419.048,
        VC_MA: 1.26372,
        QE_MA: 4742.069,
        VE_MA: 1.26919,
        QA_01: 7968.505,
        VA_01: 1.6149,
        QC_01: 7968.505,
        VC_01: 1.6149,
        QE_01: 6678.019,
        VE_01: 1.46073,
        QA_02: 8436.479,
        VA_02: 1.6551,
        QC_02: 8436.479,
        VC_02: 1.6551,
        QE_02: 6846.248,
        VE_02: 1.47606,
        QA_03: 9286.068,
        VA_03: 1.72549,
        QC_03: 9286.068,
        VC_03: 1.72549,
        QE_03: 7001.657,
        VE_03: 1.49006,
        QA_04: 6499.934,
        VA_04: 1.48095,
        QC_04: 6499.934,
        VC_04: 1.48095,
        QE_04: 5848.927,
        VE_04: 1.38241,
        QA_05: 4508.96,
        VA_05: 1.27399,
        QC_05: 4508.96,
        VC_05: 1.27399,
        QE_05: 4808.158,
        VE_05: 1.27628,
        QA_06: 2648.756,
        VA_06: 1.03637,
        QC_06: 2648.756,
        VC_06: 1.03637,
        QE_06: 4413.377,
        VE_06: 1.23323,
        QA_07: 1626.262,
        VA_07: 0.87024,
        QC_07: 1626.262,
        VC_07: 0.87024,
        QE_07: 3371.166,
        VE_07: 1.10997,
        QA_08: 1154.635,
        VA_08: 0.77681,
        QC_08: 1154.635,
        VC_08: 0.77681,
        QE_08: 3291.506,
        VE_08: 1.09985,
        QA_09: 1062.158,
        VA_09: 0.7565,
        QC_09: 1062.158,
        VC_09: 0.7565,
        QE_09: 2732.557,
        VE_09: 1.02538,
        QA_10: 1170.743,
        VA_10: 0.78027,
        QC_10: 1170.743,
        VC_10: 0.78027,
        QE_10: 3582.683,
        VE_10: 1.13629,
        QA_11: 3070.377,
        VA_11: 1.09576,
        QC_11: 3070.377,
        VC_11: 1.09576,
        QE_11: 3833.529,
        VE_11: 1.16658,
        QA_12: 5625.758,
        VA_12: 1.39439,
        QC_12: 5625.758,
        VC_12: 1.39439,
        QE_12: 4842.624,
        VE_12: 1.27996,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.870426318246246, 34.934129279108504, 0.0],
          [-80.87015591824661, 34.93369767910923, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288919.0,
        COMID: 9714607,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.714,
        REACHCODE: "03050104000138",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.007649083671949,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057156.0,
        ToNode: 250057158.0,
        Hydroseq: 250027979.0,
        LevelPathI: 250021319.0,
        Pathlength: 356.796,
        TerminalPa: 250002604.0,
        ArbolateSu: 64.6,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250028943.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027104.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5508,
        TotDASqKM: 65.7936,
        DivDASqKM: 65.7936,
        Tidal: 0,
        TOTMA: 0.023391393791000002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8248.0,
        MAXELEVSMO: 8696.0,
        MINELEVSMO: 8248.0,
        SLOPE: 0.0062745,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.71399998664856001,
        QA_MA: 25.015,
        VA_MA: 1.15451,
        QC_MA: 26.737,
        VC_MA: 1.15908,
        QE_MA: 26.737,
        VE_MA: 1.15908,
        QA_01: 55.657,
        VA_01: 1.60216,
        QC_01: 45.428,
        VC_01: 1.43562,
        QE_01: 45.428,
        VE_01: 1.43562,
        QA_02: 57.384,
        VA_02: 1.62332,
        QC_02: 50.064,
        VC_02: 1.49531,
        QE_02: 50.064,
        VE_02: 1.49531,
        QA_03: 61.398,
        VA_03: 1.67139,
        QC_03: 54.574,
        VC_03: 1.55094,
        QE_03: 54.574,
        VE_03: 1.55094,
        QA_04: 35.901,
        VA_04: 1.33345,
        QC_04: 34.634,
        VC_04: 1.28439,
        QE_04: 34.634,
        VE_04: 1.28439,
        QA_05: 19.942,
        VA_05: 1.05857,
        QC_05: 20.248,
        VC_05: 1.04234,
        QE_05: 20.248,
        VE_05: 1.04234,
        QA_06: 12.656,
        VA_06: 0.89764,
        QC_06: 16.311,
        VC_06: 0.96273,
        QE_06: 16.311,
        VE_06: 0.96273,
        QA_07: 9.731,
        VA_07: 0.82089,
        QC_07: 15.372,
        VC_07: 0.94244,
        QE_07: 15.372,
        VE_07: 0.94244,
        QA_08: 7.077,
        VA_08: 0.74116,
        QC_08: 15.267,
        VC_08: 0.94014,
        QE_08: 15.267,
        VE_08: 0.94014,
        QA_09: 5.37,
        VA_09: 0.68216,
        QC_09: 17.254,
        VC_09: 0.98255,
        QE_09: 17.254,
        VE_09: 0.98255,
        QA_10: 4.423,
        VA_10: 0.64557,
        QC_10: 18.038,
        VC_10: 0.99864,
        QE_10: 18.038,
        VE_10: 0.99864,
        QA_11: 3.979,
        VA_11: 0.62715,
        QC_11: 10.961,
        VC_11: 0.83822,
        QE_11: 10.961,
        VE_11: 0.83822,
        QA_12: 26.766,
        VA_12: 1.18546,
        QC_12: 26.307,
        VC_12: 1.15179,
        QE_12: 26.307,
        VE_12: 1.15179,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.724930985138712, 34.483501013141449, 0.0],
          [-80.732176185127514, 34.481708013144271, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286933.0,
        COMID: 9736290,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.02,
        REACHCODE: "03050103000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0095857274482540004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059906.0,
        ToNode: 250059907.0,
        Hydroseq: 250006215.0,
        LevelPathI: 250003258.0,
        Pathlength: 418.357,
        TerminalPa: 250002604.0,
        ArbolateSu: 6392.71,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006234.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006192.0,
        FromMeas: 0.0,
        ToMeas: 44.72909,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7821,
        TotDASqKM: 8696.7882,
        DivDASqKM: 8696.7882,
        Tidal: 0,
        TOTMA: 0.015200876823800001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14067.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14209.0,
        SLOPE: 0.00204901,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0199999809265139,
        QA_MA: 4419.365,
        VA_MA: 2.53526,
        QC_MA: 4419.365,
        VC_MA: 2.53526,
        QE_MA: 4742.389,
        VE_MA: 2.54802,
        QA_01: 7969.199,
        VA_01: 3.35389,
        QC_01: 7969.199,
        VC_01: 3.35389,
        QE_01: 6678.709,
        VE_01: 2.99456,
        QA_02: 8437.194,
        VA_02: 3.44759,
        QC_02: 8437.194,
        VC_02: 3.44759,
        QE_02: 6846.958,
        VE_02: 3.03028,
        QA_03: 9286.836,
        VA_03: 3.61167,
        QC_03: 9286.836,
        VC_03: 3.61167,
        QE_03: 7002.419,
        VE_03: 3.06292,
        QA_04: 6500.387,
        VA_04: 3.04163,
        QC_04: 6500.387,
        VC_04: 3.04163,
        QE_04: 5849.378,
        VE_04: 2.81194,
        QA_05: 4509.212,
        VA_05: 2.55918,
        QC_05: 4509.212,
        VC_05: 2.55918,
        QE_05: 4808.413,
        VE_05: 2.56452,
        QA_06: 2648.914,
        VA_06: 2.00529,
        QC_06: 2648.914,
        VC_06: 2.00529,
        QE_06: 4413.541,
        VE_06: 2.46416,
        QA_07: 1626.378,
        VA_07: 1.61808,
        QC_07: 1626.378,
        VC_07: 1.61808,
        QE_07: 3371.287,
        VE_07: 2.17684,
        QA_08: 1154.719,
        VA_08: 1.40029,
        QC_08: 1154.719,
        VC_08: 1.40029,
        QE_08: 3291.597,
        VE_08: 2.15325,
        QA_09: 1062.232,
        VA_09: 1.35295,
        QC_09: 1062.232,
        VC_09: 1.35295,
        QE_09: 2732.636,
        VE_09: 1.97966,
        QA_10: 1170.795,
        VA_10: 1.40834,
        QC_10: 1170.795,
        VC_10: 1.40834,
        QE_10: 3582.744,
        VE_10: 2.23818,
        QA_11: 3070.45,
        VA_11: 2.14371,
        QC_11: 3070.45,
        VC_11: 2.14371,
        QE_11: 3833.606,
        VE_11: 2.30879,
        QA_12: 5626.137,
        VA_12: 2.83984,
        QC_12: 5626.137,
        VC_12: 2.83984,
        QE_12: 4843.001,
        VE_12: 2.57312,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87015591824661, 34.93369767910923, 0.0],
          [-80.865568584920439, 34.92557061245526, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288920.0,
        COMID: 9714587,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.052,
        REACHCODE: "03050104000139",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00054807688346800003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057149.0,
        ToNode: 250057156.0,
        Hydroseq: 250028943.0,
        LevelPathI: 250021319.0,
        Pathlength: 357.51,
        TerminalPa: 250002604.0,
        ArbolateSu: 61.943,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250029994.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027979.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 63.5985,
        DivDASqKM: 63.5985,
        Tidal: 0,
        TOTMA: 0.0032289170938,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8696.0,
        MAXELEVSMO: 8696.0,
        MINELEVSMO: 8696.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052000001072884001,
        QA_MA: 24.203,
        VA_MA: 0.60987,
        QC_MA: 25.902,
        VC_MA: 0.61153,
        QE_MA: 25.902,
        VE_MA: 0.61153,
        QA_01: 53.848,
        VA_01: 0.76936,
        QC_01: 43.989,
        VC_01: 0.70998,
        QE_01: 43.989,
        VE_01: 0.70998,
        QA_02: 55.495,
        VA_02: 0.7768,
        QC_02: 48.46,
        VC_02: 0.73116,
        QE_02: 48.46,
        VE_02: 0.73116,
        QA_03: 59.37,
        VA_03: 0.79389,
        QC_03: 52.83,
        VC_03: 0.75099,
        QE_03: 52.83,
        VE_03: 0.75099,
        QA_04: 34.75,
        VA_04: 0.67371,
        QC_04: 33.548,
        VC_04: 0.65616,
        QE_04: 33.548,
        VE_04: 0.65616,
        QA_05: 19.293,
        VA_05: 0.57567,
        QC_05: 19.613,
        VC_05: 0.56991,
        QE_05: 19.613,
        VE_05: 0.56991,
        QA_06: 12.243,
        VA_06: 0.51833,
        QC_06: 15.786,
        VC_06: 0.54144,
        QE_06: 15.786,
        VE_06: 0.54144,
        QA_07: 9.406,
        VA_07: 0.49091,
        QC_07: 14.871,
        VC_07: 0.53417,
        QE_07: 14.871,
        VE_07: 0.53417,
        QA_08: 6.841,
        VA_08: 0.46252,
        QC_08: 14.748,
        VC_08: 0.53317,
        QE_08: 14.748,
        VE_08: 0.53317,
        QA_09: 5.193,
        VA_09: 0.44153,
        QC_09: 16.75,
        VC_09: 0.54889,
        QE_09: 16.75,
        VE_09: 0.54889,
        QA_10: 4.275,
        VA_10: 0.42847,
        QC_10: 17.556,
        VC_10: 0.55497,
        QE_10: 17.556,
        VE_10: 0.55497,
        QA_11: 3.861,
        VA_11: 0.42214,
        QC_11: 10.713,
        VC_11: 0.49808,
        QE_11: 10.713,
        VE_11: 0.49808,
        QA_12: 25.948,
        VA_12: 0.62122,
        QC_12: 25.574,
        VC_12: 0.60949,
        QE_12: 25.574,
        VE_12: 0.60949,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.724433318472791, 34.483730613141063, 0.0],
          [-80.724930985138712, 34.483501013141449, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283755.0,
        COMID: 9752648,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.98,
        REACHCODE: "03050101000173",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.030235637727553,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061102.0,
        ToNode: 250061105.0,
        Hydroseq: 250015944.0,
        LevelPathI: 250015617.0,
        Pathlength: 632.433,
        TerminalPa: 250002604.0,
        ArbolateSu: 555.562,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250016107.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 250015777.0,
        DnDrainCou: 2,
        DnHydroseq: 250015778.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.7782,
        TotDASqKM: 534.7611,
        DivDASqKM: 534.7611,
        Tidal: 0,
        TOTMA: 0.095375787075799995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30270.0,
        MAXELEVSMO: 30378.0,
        MINELEVSMO: 30293.0,
        SLOPE: 0.00028523,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9800000190734859,
        QA_MA: 334.671,
        VA_MA: 1.18054,
        QC_MA: 323.455,
        VC_MA: 1.17813,
        QE_MA: 363.772,
        VE_MA: 1.18645,
        QA_01: 499.098,
        VA_01: 1.38673,
        QC_01: 384.607,
        VC_01: 1.2619,
        QE_01: 475.82,
        VE_01: 1.32101,
        QA_02: 527.836,
        VA_02: 1.41926,
        QC_02: 433.479,
        VC_02: 1.32443,
        QE_02: 446.682,
        VE_02: 1.28759,
        QA_03: 623.563,
        VA_03: 1.52205,
        QC_03: 513.426,
        VC_03: 1.41998,
        QE_03: 578.563,
        VE_03: 1.43178,
        QA_04: 507.067,
        VA_04: 1.39583,
        QC_04: 460.592,
        VC_04: 1.35769,
        QE_04: 485.665,
        VE_04: 1.33208,
        QA_05: 388.166,
        VA_05: 1.25199,
        QC_05: 352.696,
        VC_05: 1.21904,
        QE_05: 364.105,
        VE_05: 1.18687,
        QA_06: 236.178,
        VA_06: 1.03317,
        QC_06: 292.384,
        VC_06: 1.13272,
        QE_06: 335.278,
        VE_06: 1.14923,
        QA_07: 141.653,
        VA_07: 0.86087,
        QC_07: 209.884,
        VC_07: 0.99966,
        QE_07: 244.936,
        VE_07: 1.02011,
        QA_08: 100.666,
        VA_08: 0.76923,
        QC_08: 229.022,
        VC_08: 1.03245,
        QE_08: 304.12,
        VE_08: 1.10679,
        QA_09: 107.851,
        VA_09: 0.78641,
        QC_09: 243.857,
        VC_09: 1.05699,
        QE_09: 230.225,
        VE_09: 0.99708,
        QA_10: 149.888,
        VA_10: 0.8777,
        QC_10: 300.911,
        VC_10: 1.1454,
        QE_10: 269.668,
        VE_10: 1.05741,
        QA_11: 333.877,
        VA_11: 1.17944,
        QC_11: 321.359,
        VC_11: 1.17514,
        QE_11: 326.679,
        VE_11: 1.13771,
        QA_12: 402.103,
        VA_12: 1.26983,
        QC_12: 309.535,
        VC_12: 1.15805,
        QE_12: 314.67,
        VE_12: 1.12138,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.698029316961595, 35.813661077743461, 0.0],
          [-81.702131516955276, 35.811070611080766, 0.0],
          [-81.702352583621575, 35.807383411086562, 0.0],
          [-81.697570183629011, 35.804065877758319, 0.0],
          [-81.690852316972723, 35.804894277757057, 0.0],
          [-81.693211516969143, 35.802351011094345, 0.0],
          [-81.690060583640687, 35.799467211098772, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286934.0,
        COMID: 9733394,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 0.081,
        REACHCODE: "03050103000216",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00073643131272800003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059041.0,
        ToNode: 250059882.0,
        Hydroseq: 250020204.0,
        LevelPathI: 250019853.0,
        Pathlength: 420.839,
        TerminalPa: 250002604.0,
        ArbolateSu: 366.209,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250020556.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019853.0,
        FromMeas: 71.35484,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0189,
        TotDASqKM: 713.439,
        DivDASqKM: 713.439,
        Tidal: 0,
        TOTMA: 0.0015211083241700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14424.0,
        MAXELEVSMO: 14541.0,
        MINELEVSMO: 14424.0,
        SLOPE: 0.01444444,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.081000000238418995,
        QA_MA: 283.881,
        VA_MA: 1.98502,
        QC_MA: 276.125,
        VC_MA: 1.98127,
        QE_MA: 371.798,
        VE_MA: 2.02207,
        QA_01: 601.576,
        VA_01: 2.80658,
        QC_01: 461.314,
        VC_01: 2.50533,
        QE_01: 595.705,
        VE_01: 2.50948,
        QA_02: 641.05,
        VA_02: 2.89233,
        QC_02: 523.673,
        VC_02: 2.65833,
        QE_02: 610.54,
        VE_02: 2.53842,
        QA_03: 690.368,
        VA_03: 2.99605,
        QC_03: 566.526,
        VC_03: 2.75856,
        QE_03: 657.447,
        VE_03: 2.62781,
        QA_04: 410.088,
        VA_04: 2.34665,
        QC_04: 374.302,
        VC_04: 2.27454,
        QE_04: 407.283,
        VE_04: 2.10706,
        QA_05: 230.342,
        VA_05: 1.80892,
        QC_05: 213.42,
        VC_05: 1.76741,
        QE_05: 290.83,
        VE_05: 1.81253,
        QA_06: 141.449,
        VA_06: 1.46661,
        QC_06: 176.348,
        VC_06: 1.62683,
        QE_06: 257.989,
        VE_06: 1.71981,
        QA_07: 98.248,
        VA_07: 1.26283,
        QC_07: 146.852,
        VC_07: 1.50472,
        QE_07: 260.257,
        VE_07: 1.72639,
        QA_08: 72.695,
        VA_08: 1.12175,
        QC_08: 164.312,
        VC_08: 1.57826,
        QE_08: 290.002,
        VE_08: 1.81026,
        QA_09: 62.44,
        VA_09: 1.05866,
        QC_09: 150.512,
        VC_09: 1.52046,
        QE_09: 235.882,
        VE_09: 1.65424,
        QA_10: 47.962,
        VA_10: 0.96059,
        QC_10: 121.093,
        VC_10: 1.38827,
        QE_10: 241.964,
        VE_10: 1.67256,
        QA_11: 72.701,
        VA_11: 1.12179,
        QC_11: 100.484,
        VC_11: 1.28642,
        QE_11: 238.385,
        VE_11: 1.66181,
        QA_12: 339.481,
        VA_12: 2.15207,
        QC_12: 265.349,
        VC_12: 1.94628,
        QE_12: 322.709,
        VE_12: 1.89794,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8693713182479, 34.945784012423871, 0.0],
          [-80.869176518248196, 34.945073812424937, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288921.0,
        COMID: 9714583,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 1.061,
        REACHCODE: "03050104000140",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011314145132164,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057147.0,
        ToNode: 250057149.0,
        Hydroseq: 250029994.0,
        LevelPathI: 250021319.0,
        Pathlength: 357.562,
        TerminalPa: 250002604.0,
        ArbolateSu: 57.499,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250031162.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028943.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1727,
        TotDASqKM: 60.7482,
        DivDASqKM: 60.7482,
        Tidal: 0,
        TOTMA: 0.057262068226000003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8338.0,
        MAXELEVSMO: 8702.0,
        MINELEVSMO: 8696.0,
        SLOPE: 5.655e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.060999989509583,
        QA_MA: 23.148,
        VA_MA: 0.70137,
        QC_MA: 24.816,
        VC_MA: 0.70359,
        QE_MA: 24.816,
        VE_MA: 0.70359,
        QA_01: 51.5,
        VA_01: 0.90926,
        QC_01: 42.12,
        VC_01: 0.83177,
        QE_01: 42.12,
        VE_01: 0.83177,
        QA_02: 53.042,
        VA_02: 0.91875,
        QC_02: 46.376,
        VC_02: 0.85922,
        QE_02: 46.376,
        VE_02: 0.85922,
        QA_03: 56.739,
        VA_03: 0.94098,
        QC_03: 50.565,
        VC_03: 0.8851,
        QE_03: 50.565,
        VE_03: 0.8851,
        QA_04: 33.246,
        VA_04: 0.78466,
        QC_04: 32.128,
        VC_04: 0.76167,
        QE_04: 32.128,
        VE_04: 0.76167,
        QA_05: 18.45,
        VA_05: 0.65677,
        QC_05: 18.788,
        VC_05: 0.6493,
        QE_05: 18.788,
        VE_05: 0.6493,
        QA_06: 11.707,
        VA_06: 0.58201,
        QC_06: 15.103,
        VC_06: 0.61199,
        QE_06: 15.103,
        VE_06: 0.61199,
        QA_07: 8.984,
        VA_07: 0.54613,
        QC_07: 14.22,
        VC_07: 0.60243,
        QE_07: 14.22,
        VE_07: 0.60243,
        QA_08: 6.534,
        VA_08: 0.50915,
        QC_08: 14.074,
        VC_08: 0.60082,
        QE_08: 14.074,
        VE_08: 0.60082,
        QA_09: 4.963,
        VA_09: 0.48187,
        QC_09: 16.094,
        VC_09: 0.62241,
        QE_09: 16.094,
        VE_09: 0.62241,
        QA_10: 4.084,
        VA_10: 0.46481,
        QC_10: 16.925,
        VC_10: 0.63091,
        QE_10: 16.925,
        VE_10: 0.63091,
        QA_11: 3.708,
        VA_11: 0.45699,
        QC_11: 10.387,
        VC_11: 0.55726,
        QE_11: 10.387,
        VE_11: 0.55726,
        QA_12: 24.896,
        VA_12: 0.71686,
        QC_12: 24.628,
        VC_12: 0.702,
        QE_12: 24.628,
        VE_12: 0.702,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.713814785155989, 34.487426879802001, 0.0],
          [-80.724433318472791, 34.483730613141063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283756.0,
        COMID: 9752566,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.939,
        REACHCODE: "03050101000174",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0087411647381619995,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061087.0,
        ToNode: 250061102.0,
        Hydroseq: 250016107.0,
        LevelPathI: 250015617.0,
        Pathlength: 635.413,
        TerminalPa: 250002604.0,
        ArbolateSu: 550.998,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250016307.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015944.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5409,
        TotDASqKM: 529.4628,
        DivDASqKM: 529.4628,
        Tidal: 0,
        TOTMA: 0.0246650930308,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30294.0,
        MAXELEVSMO: 30516.0,
        MINELEVSMO: 30378.0,
        SLOPE: 0.00146964,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.93900001049041704,
        QA_MA: 332.01,
        VA_MA: 1.43786,
        QC_MA: 320.982,
        VC_MA: 1.43478,
        QE_MA: 361.299,
        VE_MA: 1.44562,
        QA_01: 494.187,
        VA_01: 1.70348,
        QC_01: 380.921,
        VC_01: 1.54198,
        QE_01: 472.134,
        VE_01: 1.61923,
        QA_02: 522.56,
        VA_02: 1.74545,
        QC_02: 429.263,
        VC_02: 1.62277,
        QE_02: 442.466,
        VE_02: 1.57483,
        QA_03: 617.885,
        VA_03: 1.87917,
        QC_03: 508.905,
        VC_03: 1.74709,
        QE_03: 574.041,
        VE_03: 1.76258,
        QA_04: 502.975,
        VA_04: 1.71659,
        QC_04: 456.958,
        VC_04: 1.66714,
        QE_04: 482.032,
        VE_04: 1.63376,
        QA_05: 385.403,
        VA_05: 1.53094,
        QC_05: 350.279,
        VC_05: 1.48825,
        QE_05: 361.688,
        VE_05: 1.44627,
        QA_06: 234.593,
        VA_06: 1.24764,
        QC_06: 290.448,
        VC_06: 1.37655,
        QE_06: 333.343,
        VE_06: 1.39802,
        QA_07: 140.721,
        VA_07: 1.02441,
        QC_07: 208.537,
        VC_07: 1.20424,
        QE_07: 243.589,
        VE_07: 1.23083,
        QA_08: 100.024,
        VA_08: 0.9057,
        QC_08: 227.533,
        VC_08: 1.24668,
        QE_08: 302.631,
        VE_08: 1.3435,
        QA_09: 107.352,
        VA_09: 0.92855,
        QC_09: 242.86,
        VC_09: 1.27973,
        QE_09: 229.228,
        VE_09: 1.20154,
        QA_10: 149.461,
        VA_10: 1.04769,
        QC_10: 300.225,
        VC_10: 1.39549,
        QE_10: 268.982,
        VE_10: 1.2807,
        QA_11: 332.12,
        VA_11: 1.43806,
        QC_11: 320.069,
        VC_11: 1.43307,
        QE_11: 325.389,
        VE_11: 1.38413,
        QA_12: 398.666,
        VA_12: 1.5531,
        QC_12: 307.128,
        VC_12: 1.4087,
        QE_12: 312.262,
        VE_12: 1.36087,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.699808116958877, 35.821331611064863, 0.0],
          [-81.698029316961595, 35.813661077743461, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286927.0,
        COMID: 9736312,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.354,
        REACHCODE: "03050103000211",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012617633709332001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059917.0,
        ToNode: 250059920.0,
        Hydroseq: 250006130.0,
        LevelPathI: 250003258.0,
        Pathlength: 416.229,
        TerminalPa: 250002604.0,
        ArbolateSu: 6412.103,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006153.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006108.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1115,
        TotDASqKM: 8710.2522,
        DivDASqKM: 8710.2522,
        Tidal: 0,
        TOTMA: 0.0290291701384,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13898.0,
        MAXELEVSMO: 14085.0,
        MINELEVSMO: 14066.0,
        SLOPE: 0.00014032,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.353999972343445,
        QA_MA: 4424.846,
        VA_MA: 1.76283,
        QC_MA: 4424.846,
        VC_MA: 1.76283,
        QE_MA: 4747.901,
        VE_MA: 1.77115,
        QA_01: 7981.13,
        VA_01: 2.29777,
        QC_01: 7981.13,
        VC_01: 2.29777,
        QE_01: 6690.576,
        VE_01: 2.06337,
        QA_02: 8449.53,
        VA_02: 2.35894,
        QC_02: 8449.53,
        VC_02: 2.35894,
        QE_02: 6859.211,
        VE_02: 2.08672,
        QA_03: 9300.056,
        VA_03: 2.46607,
        QC_03: 9300.056,
        VC_03: 2.46607,
        QE_03: 7015.536,
        VE_03: 2.10813,
        QA_04: 6508.189,
        VA_04: 2.09352,
        QC_04: 6508.189,
        VC_04: 2.09352,
        QE_04: 5857.17,
        VE_04: 1.94367,
        QA_05: 4513.557,
        VA_05: 1.77824,
        QC_05: 4513.557,
        VC_05: 1.77824,
        QE_05: 4812.804,
        VE_05: 1.78174,
        QA_06: 2651.629,
        VA_06: 1.41657,
        QC_06: 2651.629,
        VC_06: 1.41657,
        QE_06: 4416.359,
        VE_06: 1.71599,
        QA_07: 1628.369,
        VA_07: 1.16378,
        QC_07: 1628.369,
        VC_07: 1.16378,
        QE_07: 3373.374,
        VE_07: 1.52837,
        QA_08: 1156.168,
        VA_08: 1.02155,
        QC_08: 1156.168,
        VC_08: 1.02155,
        QE_08: 3293.152,
        VE_08: 1.51288,
        QA_09: 1063.499,
        VA_09: 0.9906,
        QC_09: 1063.499,
        VC_09: 0.9906,
        QE_09: 2734.002,
        VE_09: 1.39956,
        QA_10: 1171.7,
        VA_10: 1.02662,
        QC_10: 1171.7,
        VC_10: 1.02662,
        QE_10: 3583.798,
        VE_10: 1.56821,
        QA_11: 3071.74,
        VA_11: 1.50658,
        QC_11: 3071.74,
        VC_11: 1.50658,
        QE_11: 3834.959,
        VE_11: 1.61435,
        QA_12: 5632.68,
        VA_12: 1.96169,
        QC_12: 5632.68,
        VC_12: 1.96169,
        QE_12: 4849.531,
        VE_12: 1.78769,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.86639078491919, 34.918887812465641, 0.0],
          [-80.868056318249899, 34.912178879142573, 0.0],
          [-80.87175298491087, 34.90790687914938, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288922.0,
        COMID: 9714563,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.383,
        REACHCODE: "03050104000141",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0036161259361859998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057140.0,
        ToNode: 250057147.0,
        Hydroseq: 250031162.0,
        LevelPathI: 250021319.0,
        Pathlength: 358.623,
        TerminalPa: 250002604.0,
        ArbolateSu: 53.47,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250032464.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029994.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6111,
        TotDASqKM: 54.8046,
        DivDASqKM: 54.8046,
        Tidal: 0,
        TOTMA: 0.0128124480534,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8672.0,
        MAXELEVSMO: 8962.0,
        MINELEVSMO: 8702.0,
        SLOPE: 0.00678851,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.38299998641014099,
        QA_MA: 20.922,
        VA_MA: 1.13021,
        QC_MA: 22.517,
        VC_MA: 1.13511,
        QE_MA: 22.517,
        VE_MA: 1.13511,
        QA_01: 46.531,
        VA_01: 1.56473,
        QC_01: 38.157,
        VC_01: 1.40233,
        QE_01: 38.157,
        VE_01: 1.40233,
        QA_02: 47.894,
        VA_02: 1.58414,
        QC_02: 41.991,
        VC_02: 1.45938,
        QE_02: 41.991,
        VE_02: 1.45938,
        QA_03: 51.234,
        VA_03: 1.63063,
        QC_03: 45.812,
        VC_03: 1.51386,
        QE_03: 45.812,
        VE_03: 1.51386,
        QA_04: 30.076,
        VA_04: 1.30487,
        QC_04: 29.131,
        VC_04: 1.25626,
        QE_04: 29.131,
        VE_04: 1.25626,
        QA_05: 16.687,
        VA_05: 1.03721,
        QC_05: 17.057,
        VC_05: 1.02177,
        QE_05: 17.057,
        VE_05: 1.02177,
        QA_06: 10.587,
        VA_06: 0.88077,
        QC_06: 13.677,
        VC_06: 0.94282,
        QE_06: 13.677,
        VE_06: 0.94282,
        QA_07: 8.105,
        VA_07: 0.80508,
        QC_07: 12.86,
        VC_07: 0.92241,
        QE_07: 12.86,
        VE_07: 0.92241,
        QA_08: 5.895,
        VA_08: 0.72782,
        QC_08: 12.671,
        VC_08: 0.9176,
        QE_08: 12.671,
        VE_08: 0.9176,
        QA_09: 4.484,
        VA_09: 0.67111,
        QC_09: 14.713,
        VC_09: 0.9679,
        QE_09: 14.713,
        VE_09: 0.9679,
        QA_10: 3.684,
        VA_10: 0.63517,
        QC_10: 15.588,
        VC_10: 0.98845,
        QE_10: 15.588,
        VE_10: 0.98845,
        QA_11: 3.388,
        VA_11: 0.62095,
        QC_11: 9.697,
        VC_11: 0.83693,
        QE_11: 9.697,
        VE_11: 0.83693,
        QA_12: 22.586,
        VA_12: 1.16429,
        QC_12: 22.541,
        VC_12: 1.13558,
        QE_12: 22.541,
        VE_12: 1.13558,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.712351585158217, 34.490405879797322, 0.0],
          [-80.713814785155989, 34.487426879802001, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283757.0,
        COMID: 9752546,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.313,
        REACHCODE: "03050101000175",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.022687197429128999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061081.0,
        ToNode: 250061087.0,
        Hydroseq: 250016307.0,
        LevelPathI: 250015617.0,
        Pathlength: 636.352,
        TerminalPa: 250002604.0,
        ArbolateSu: 547.471,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250016506.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016107.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.114,
        TotDASqKM: 525.9762,
        DivDASqKM: 525.9762,
        Tidal: 0,
        TOTMA: 0.063128598761700003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30318.0,
        MAXELEVSMO: 30768.0,
        MINELEVSMO: 30516.0,
        SLOPE: 0.00108949,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.312999963760376,
        QA_MA: 330.217,
        VA_MA: 1.38384,
        QC_MA: 319.317,
        VC_MA: 1.38092,
        QE_MA: 359.633,
        VE_MA: 1.3913,
        QA_01: 490.962,
        VA_01: 1.63595,
        QC_01: 378.5,
        VC_01: 1.48226,
        QE_01: 469.713,
        VE_01: 1.55627,
        QA_02: 519.091,
        VA_02: 1.67581,
        QC_02: 426.491,
        VC_02: 1.55908,
        QE_02: 439.694,
        VE_02: 1.51327,
        QA_03: 614.132,
        VA_03: 1.80351,
        QC_03: 505.916,
        VC_03: 1.6778,
        QE_03: 571.052,
        VE_03: 1.69268,
        QA_04: 500.232,
        VA_04: 1.6492,
        QC_04: 454.523,
        VC_04: 1.60208,
        QE_04: 479.597,
        VE_04: 1.57015,
        QA_05: 383.482,
        VA_05: 1.47272,
        QC_05: 348.599,
        VC_05: 1.43207,
        QE_05: 360.008,
        VE_05: 1.3919,
        QA_06: 233.499,
        VA_06: 1.20307,
        QC_06: 289.113,
        VC_06: 1.3258,
        QE_06: 332.008,
        VE_06: 1.3463,
        QA_07: 140.072,
        VA_07: 0.99044,
        QC_07: 207.598,
        VC_07: 1.16171,
        QE_07: 242.65,
        VE_07: 1.18712,
        QA_08: 99.571,
        VA_08: 0.87739,
        QC_08: 226.482,
        VC_08: 1.20209,
        QE_08: 301.58,
        VE_08: 1.29466,
        QA_09: 107.003,
        VA_09: 0.89956,
        QC_09: 242.164,
        VC_09: 1.23443,
        QE_09: 228.532,
        VE_09: 1.15959,
        QA_10: 149.18,
        VA_10: 1.01365,
        QC_10: 299.774,
        VC_10: 1.34555,
        QE_10: 268.53,
        VE_10: 1.2357,
        QA_11: 330.848,
        VA_11: 1.38493,
        QC_11: 319.133,
        VC_11: 1.38059,
        QE_11: 324.454,
        VE_11: 1.3337,
        QA_12: 396.359,
        VA_12: 1.49332,
        QC_12: 305.51,
        VC_12: 1.35604,
        QE_12: 310.645,
        VE_12: 1.31029,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.710555983608856, 35.827438811055401, 0.0],
          [-81.706839716947968, 35.823319277728501, 0.0],
          [-81.706214383615588, 35.817067811071411, 0.0],
          [-81.704864583617677, 35.816885611071825, 0.0],
          [-81.705176383617186, 35.819267077734594, 0.0],
          [-81.699808116958877, 35.821331611064863, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286928.0,
        COMID: 9736304,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.245,
        REACHCODE: "03050103000212",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0022544214235649999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059913.0,
        ToNode: 250059917.0,
        Hydroseq: 250006153.0,
        LevelPathI: 250003258.0,
        Pathlength: 417.583,
        TerminalPa: 250002604.0,
        ArbolateSu: 6409.937,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006172.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006130.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0576,
        TotDASqKM: 8708.4801,
        DivDASqKM: 8708.4801,
        Tidal: 0,
        TOTMA: 0.00321352240944,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14085.0,
        MAXELEVSMO: 14209.0,
        MINELEVSMO: 14085.0,
        SLOPE: 0.00506122,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.245000004768372,
        QA_MA: 4424.128,
        VA_MA: 2.88032,
        QC_MA: 4424.128,
        VC_MA: 2.88032,
        QE_MA: 4747.179,
        VE_MA: 2.89505,
        QA_01: 7979.573,
        VA_01: 3.82623,
        QC_01: 7979.573,
        VC_01: 3.82623,
        QE_01: 6689.027,
        VE_01: 3.41166,
        QA_02: 8447.91,
        VA_02: 3.9344,
        QC_02: 8447.91,
        VC_02: 3.9344,
        QE_02: 6857.602,
        VE_02: 3.45294,
        QA_03: 9298.316,
        VA_03: 4.12386,
        QC_03: 9298.316,
        VC_03: 4.12386,
        QE_03: 7013.81,
        VE_03: 3.49078,
        QA_04: 6507.164,
        VA_04: 3.46511,
        QC_04: 6507.164,
        VC_04: 3.46511,
        QE_04: 5856.147,
        VE_04: 3.20009,
        QA_05: 4512.985,
        VA_05: 2.90762,
        QC_05: 4512.985,
        VC_05: 2.90762,
        QE_05: 4812.226,
        VE_05: 2.91381,
        QA_06: 2651.272,
        VA_06: 2.26805,
        QC_06: 2651.272,
        VC_06: 2.26805,
        QE_06: 4415.988,
        VE_06: 2.7976,
        QA_07: 1628.107,
        VA_07: 1.82101,
        QC_07: 1628.107,
        VC_07: 1.82101,
        QE_07: 3373.1,
        VE_07: 2.46581,
        QA_08: 1155.977,
        VA_08: 1.5695,
        QC_08: 1155.977,
        VC_08: 1.5695,
        QE_08: 3292.947,
        VE_08: 2.43844,
        QA_09: 1063.332,
        VA_09: 1.51478,
        QC_09: 1063.332,
        VC_09: 1.51478,
        QE_09: 2733.822,
        VE_09: 2.23803,
        QA_10: 1171.581,
        VA_10: 1.57851,
        QC_10: 1171.581,
        VC_10: 1.57851,
        QE_10: 3583.659,
        VE_10: 2.53631,
        QA_11: 3071.588,
        VA_11: 2.42731,
        QC_11: 3071.588,
        VC_11: 2.42731,
        QE_11: 3834.799,
        VE_11: 2.6179,
        QA_12: 5631.823,
        VA_12: 3.232,
        QC_12: 5631.823,
        VC_12: 3.232,
        QE_12: 4848.676,
        VE_12: 2.92427,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865480384920602, 34.920942079129077, 0.0],
          [-80.86639078491919, 34.918887812465641, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283758.0,
        COMID: 9752476,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.171,
        REACHCODE: "03050101000176",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010735255292119999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061065.0,
        ToNode: 250061081.0,
        Hydroseq: 250016506.0,
        LevelPathI: 250015617.0,
        Pathlength: 638.665,
        TerminalPa: 250002604.0,
        ArbolateSu: 542.881,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250016713.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016307.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0737,
        TotDASqKM: 521.6238,
        DivDASqKM: 521.6238,
        Tidal: 0,
        TOTMA: 0.054149584734799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30523.0,
        MAXELEVSMO: 30768.0,
        MINELEVSMO: 30768.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.171000003814697,
        QA_MA: 327.969,
        VA_MA: 0.8176,
        QC_MA: 317.227,
        VC_MA: 0.81622,
        QE_MA: 357.543,
        VE_MA: 0.82117,
        QA_01: 486.915,
        VA_01: 0.93648,
        QC_01: 375.462,
        VC_01: 0.86377,
        QE_01: 466.675,
        VE_01: 0.89908,
        QA_02: 514.76,
        VA_02: 0.95531,
        QC_02: 423.029,
        VC_02: 0.90008,
        QE_02: 436.233,
        VE_02: 0.8783,
        QA_03: 609.424,
        VA_03: 1.01598,
        QC_03: 502.165,
        VC_03: 0.95649,
        QE_03: 567.301,
        VE_03: 0.96361,
        QA_04: 496.791,
        VA_04: 0.94322,
        QC_04: 451.467,
        VC_04: 0.92088,
        QE_04: 476.541,
        VE_04: 0.90568,
        QA_05: 381.072,
        VA_05: 0.85983,
        QC_05: 346.489,
        VC_05: 0.84058,
        QE_05: 357.899,
        VE_05: 0.82144,
        QA_06: 232.12,
        VA_06: 0.73221,
        QC_06: 287.429,
        VC_06: 0.79031,
        QE_06: 330.323,
        VE_06: 0.80006,
        QA_07: 139.249,
        VA_07: 0.63149,
        QC_07: 206.407,
        VC_07: 0.71261,
        QE_07: 241.459,
        VE_07: 0.72469,
        QA_08: 99.006,
        VA_08: 0.57797,
        QC_08: 225.171,
        VC_08: 0.73172,
        QE_08: 300.269,
        VE_08: 0.77577,
        QA_09: 106.556,
        VA_09: 0.58869,
        QC_09: 241.271,
        VC_09: 0.74753,
        QE_09: 227.639,
        VE_09: 0.71186,
        QA_10: 148.829,
        VA_10: 0.64311,
        QC_10: 299.21,
        VC_10: 0.8007,
        QE_10: 267.967,
        VE_10: 0.74836,
        QA_11: 329.228,
        VA_11: 0.81863,
        QC_11: 317.941,
        VC_11: 0.81683,
        QE_11: 323.261,
        VE_11: 0.79445,
        QA_12: 393.463,
        VA_12: 0.86928,
        QC_12: 303.478,
        VC_12: 0.80442,
        QE_12: 308.613,
        VE_12: 0.78262,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.713716916937301, 35.837352477706702, 0.0],
          [-81.710898716941529, 35.832247611047933, 0.0],
          [-81.710555983608856, 35.827438811055401, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286929.0,
        COMID: 9736254,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.148,
        REACHCODE: "03050103000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001442044968405,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059889.0,
        ToNode: 250059888.0,
        Hydroseq: 250006334.0,
        LevelPathI: 250003258.0,
        Pathlength: 420.489,
        TerminalPa: 250002604.0,
        ArbolateSu: 6389.893,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006357.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250006312.0,
        DnDrainCou: 2,
        DnHydroseq: 250006311.0,
        FromMeas: 93.47308,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0558,
        TotDASqKM: 8694.6129,
        DivDASqKM: 8694.6129,
        Tidal: 0,
        TOTMA: 0.00442809218683,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13689.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 4418.48,
        VA_MA: 1.26368,
        QC_MA: 4418.48,
        VC_MA: 1.26368,
        QE_MA: 4741.498,
        VE_MA: 1.26916,
        QA_01: 7967.268,
        VA_01: 1.61484,
        QC_01: 7967.268,
        VC_01: 1.61484,
        QE_01: 6676.79,
        VE_01: 1.46066,
        QA_02: 8435.202,
        VA_02: 1.65503,
        QC_02: 8435.202,
        VC_02: 1.65503,
        QE_02: 6844.979,
        VE_02: 1.47598,
        QA_03: 9284.701,
        VA_03: 1.72543,
        QC_03: 9284.701,
        VC_03: 1.72543,
        QE_03: 7000.3,
        VE_03: 1.48997,
        QA_04: 6499.125,
        VA_04: 1.48092,
        QC_04: 6499.125,
        VC_04: 1.48092,
        QE_04: 5848.119,
        VE_04: 1.38236,
        QA_05: 4508.51,
        VA_05: 1.27397,
        QC_05: 4508.51,
        VC_05: 1.27397,
        QE_05: 4807.704,
        VE_05: 1.27626,
        QA_06: 2648.475,
        VA_06: 1.03635,
        QC_06: 2648.475,
        VC_06: 1.03635,
        QE_06: 4413.085,
        VE_06: 1.23323,
        QA_07: 1626.056,
        VA_07: 0.87022,
        QC_07: 1626.056,
        VC_07: 0.87022,
        QE_07: 3370.95,
        VE_07: 1.10997,
        QA_08: 1154.485,
        VA_08: 0.7768,
        QC_08: 1154.485,
        VC_08: 0.7768,
        QE_08: 3291.345,
        VE_08: 1.09986,
        QA_09: 1062.027,
        VA_09: 0.75649,
        QC_09: 1062.027,
        VC_09: 0.75649,
        QE_09: 2732.416,
        VE_09: 1.02538,
        QA_10: 1170.649,
        VA_10: 0.78027,
        QC_10: 1170.649,
        VC_10: 0.78027,
        QE_10: 3582.574,
        VE_10: 1.1363,
        QA_11: 3070.244,
        VA_11: 1.09577,
        QC_11: 3070.244,
        VC_11: 1.09577,
        QE_11: 3833.39,
        VE_11: 1.16659,
        QA_12: 5625.08,
        VA_12: 1.39435,
        QC_12: 5625.08,
        VC_12: 1.39435,
        QE_12: 4841.947,
        VE_12: 1.27991,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.870355918246275, 34.94371027909375, 0.0],
          [-80.869395184914538, 34.942634879095522, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288932.0,
        COMID: 9713497,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.896,
        REACHCODE: "03050104000149",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.017128437057354999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250057093.0,
        ToNode: 250057088.0,
        Hydroseq: 250004236.0,
        LevelPathI: 250003258.0,
        Pathlength: 365.0,
        TerminalPa: 250002604.0,
        ArbolateSu: 8432.914,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004244.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004228.0,
        FromMeas: 42.20322,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2041,
        TotDASqKM: 11360.3652,
        DivDASqKM: 11360.3652,
        Tidal: 0,
        TOTMA: 0.0541447034378,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6796.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.896000027656555,
        QA_MA: 5472.359,
        VA_MA: 1.31982,
        QC_MA: 5472.359,
        VC_MA: 1.31982,
        QE_MA: 6170.031,
        VE_MA: 1.3297,
        QA_01: 10262.907,
        VA_01: 1.72077,
        QC_01: 10262.907,
        VC_01: 1.72077,
        QE_01: 8870.413,
        VE_01: 1.54683,
        QA_02: 10848.595,
        VA_02: 1.76301,
        QC_02: 10848.595,
        VC_02: 1.76301,
        QE_02: 9008.692,
        VE_02: 1.55704,
        QA_03: 11877.789,
        VA_03: 1.83473,
        QC_03: 11877.789,
        VC_03: 1.83473,
        QE_03: 9628.639,
        VE_03: 1.60196,
        QA_04: 7993.979,
        VA_04: 1.54529,
        QC_04: 7993.979,
        VC_04: 1.54529,
        QE_04: 7742.828,
        VE_04: 1.46057,
        QA_05: 5340.808,
        VA_05: 1.30684,
        QC_05: 5340.808,
        VC_05: 1.30684,
        QE_05: 6296.139,
        VE_05: 1.34073,
        QA_06: 3179.815,
        VA_06: 1.06652,
        QC_06: 3179.815,
        VC_06: 1.06652,
        QE_06: 5403.566,
        VE_06: 1.26024,
        QA_07: 2006.381,
        VA_07: 0.90206,
        QC_07: 2006.381,
        VC_07: 0.90206,
        QE_07: 4082.207,
        VE_07: 1.12856,
        QA_08: 1441.218,
        VA_08: 0.80641,
        QC_08: 1441.218,
        VC_08: 0.80641,
        QE_08: 3736.258,
        VE_08: 1.09088,
        QA_09: 1292.263,
        VA_09: 0.77838,
        QC_09: 1292.263,
        VC_09: 0.77838,
        QE_09: 3429.048,
        VE_09: 1.05603,
        QA_10: 1350.376,
        VA_10: 0.78949,
        QC_10: 1350.376,
        VC_10: 0.78949,
        QE_10: 4669.868,
        VE_10: 1.18927,
        QA_11: 3297.31,
        VA_11: 1.08127,
        QC_11: 3297.31,
        VC_11: 1.08127,
        QE_11: 4743.35,
        VE_11: 1.1966,
        QA_12: 6814.687,
        VA_12: 1.44478,
        QC_12: 6814.687,
        VC_12: 1.44478,
        QE_12: 6523.376,
        VE_12: 1.36035,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8752163849054, 34.53756581305754, 0.0],
          [-80.875007918239078, 34.529074013070613, 0.0],
          [-80.877654184901587, 34.520965079749885, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286930.0,
        COMID: 9736278,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.779,
        REACHCODE: "03050103000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0072694452172480002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059900.0,
        ToNode: 250059901.0,
        Hydroseq: 250006254.0,
        LevelPathI: 250003258.0,
        Pathlength: 419.431,
        TerminalPa: 250002604.0,
        ArbolateSu: 6391.636,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006311.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006234.0,
        FromMeas: 47.10356,
        ToMeas: 81.23658,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6471,
        TotDASqKM: 8695.9926,
        DivDASqKM: 8695.9926,
        Tidal: 0,
        TOTMA: 0.0233067713752,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14115.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.778999984264374,
        QA_MA: 4419.042,
        VA_MA: 1.26372,
        QC_MA: 4419.042,
        VC_MA: 1.26372,
        QE_MA: 4742.064,
        VE_MA: 1.26919,
        QA_01: 7968.493,
        VA_01: 1.6149,
        QC_01: 7968.493,
        VC_01: 1.6149,
        QE_01: 6678.007,
        VE_01: 1.46073,
        QA_02: 8436.467,
        VA_02: 1.6551,
        QC_02: 8436.467,
        VC_02: 1.6551,
        QE_02: 6846.236,
        VE_02: 1.47606,
        QA_03: 9286.055,
        VA_03: 1.72549,
        QC_03: 9286.055,
        VC_03: 1.72549,
        QE_03: 7001.644,
        VE_03: 1.49006,
        QA_04: 6499.927,
        VA_04: 1.48095,
        QC_04: 6499.927,
        VC_04: 1.48095,
        QE_04: 5848.919,
        VE_04: 1.38241,
        QA_05: 4508.955,
        VA_05: 1.27399,
        QC_05: 4508.955,
        VC_05: 1.27399,
        QE_05: 4808.154,
        VE_05: 1.27628,
        QA_06: 2648.753,
        VA_06: 1.03637,
        QC_06: 2648.753,
        VC_06: 1.03637,
        QE_06: 4413.374,
        VE_06: 1.23323,
        QA_07: 1626.26,
        VA_07: 0.87024,
        QC_07: 1626.26,
        VC_07: 0.87024,
        QE_07: 3371.164,
        VE_07: 1.10997,
        QA_08: 1154.634,
        VA_08: 0.77681,
        QC_08: 1154.634,
        VC_08: 0.77681,
        QE_08: 3291.505,
        VE_08: 1.09985,
        QA_09: 1062.157,
        VA_09: 0.7565,
        QC_09: 1062.157,
        VC_09: 0.7565,
        QE_09: 2732.556,
        VE_09: 1.02538,
        QA_10: 1170.742,
        VA_10: 0.78027,
        QC_10: 1170.742,
        VC_10: 0.78027,
        QE_10: 3582.682,
        VE_10: 1.13629,
        QA_11: 3070.375,
        VA_11: 1.09576,
        QC_11: 3070.375,
        VC_11: 1.09576,
        QE_11: 3833.528,
        VE_11: 1.16658,
        QA_12: 5625.752,
        VA_12: 1.39438,
        QC_12: 5625.752,
        VC_12: 1.39438,
        QE_12: 4842.617,
        VE_12: 1.27996,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.867990518250053, 34.940415412432287, 0.0],
          [-80.870426318246246, 34.934129279108504, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288933.0,
        COMID: 9713487,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.387,
        REACHCODE: "03050104000149",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0034872041915279999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250057088.0,
        ToNode: 250057091.0,
        Hydroseq: 250004228.0,
        LevelPathI: 250003258.0,
        Pathlength: 364.613,
        TerminalPa: 250002604.0,
        ArbolateSu: 8434.975,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004236.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004219.0,
        FromMeas: 30.41942,
        ToMeas: 42.20322,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1206,
        TotDASqKM: 11361.7386,
        DivDASqKM: 11361.7386,
        Tidal: 0,
        TOTMA: 0.011051438230599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6791.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.38699999451637301,
        QA_MA: 5472.811,
        VA_MA: 1.31985,
        QC_MA: 5472.811,
        VC_MA: 1.31985,
        QE_MA: 6170.483,
        VE_MA: 1.32973,
        QA_01: 10263.886,
        VA_01: 1.72081,
        QC_01: 10263.886,
        VC_01: 1.72081,
        QE_01: 8871.389,
        VE_01: 1.54688,
        QA_02: 10849.684,
        VA_02: 1.76306,
        QC_02: 10849.684,
        VC_02: 1.76306,
        QE_02: 9009.779,
        VE_02: 1.5571,
        QA_03: 11878.991,
        VA_03: 1.83478,
        QC_03: 11878.991,
        VC_03: 1.83478,
        QE_03: 9629.837,
        VE_03: 1.60202,
        QA_04: 7994.607,
        VA_04: 1.54532,
        QC_04: 7994.607,
        VC_04: 1.54532,
        QE_04: 7743.455,
        VE_04: 1.46061,
        QA_05: 5341.159,
        VA_05: 1.30686,
        QC_05: 5341.159,
        VC_05: 1.30686,
        QE_05: 6296.49,
        VE_05: 1.34075,
        QA_06: 3180.055,
        VA_06: 1.06653,
        QC_06: 3180.055,
        VC_06: 1.06653,
        QE_06: 5403.808,
        VE_06: 1.26025,
        QA_07: 2006.566,
        VA_07: 0.90208,
        QC_07: 2006.566,
        VC_07: 0.90208,
        QE_07: 4082.394,
        VE_07: 1.12856,
        QA_08: 1441.366,
        VA_08: 0.80643,
        QC_08: 1441.366,
        VC_08: 0.80643,
        QE_08: 3736.408,
        VE_08: 1.09089,
        QA_09: 1292.374,
        VA_09: 0.7784,
        QC_09: 1292.374,
        VC_09: 0.7784,
        QE_09: 3429.161,
        VE_09: 1.05603,
        QA_10: 1350.468,
        VA_10: 0.7895,
        QC_10: 1350.468,
        VC_10: 0.7895,
        QE_10: 4669.964,
        VE_10: 1.18927,
        QA_11: 3297.384,
        VA_11: 1.08126,
        QC_11: 3297.384,
        VC_11: 1.08126,
        QE_11: 4743.426,
        VE_11: 1.1966,
        QA_12: 6815.019,
        VA_12: 1.44479,
        QC_12: 6815.019,
        VC_12: 1.44479,
        QE_12: 6523.707,
        VE_12: 1.36037,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877654184901587, 34.520965079749885, 0.0],
          [-80.878442784900471, 34.517568213088509, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283752.0,
        COMID: 9752774,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.496,
        REACHCODE: "03050101000170",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0055257048148250002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061136.0,
        ToNode: 250061556.0,
        Hydroseq: 250012245.0,
        LevelPathI: 250003258.0,
        Pathlength: 626.924,
        TerminalPa: 250002604.0,
        ArbolateSu: 2198.962,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012336.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012151.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2034,
        TotDASqKM: 2374.5123,
        DivDASqKM: 2374.5123,
        Tidal: 0,
        TOTMA: 0.018153513261600002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30209.0,
        MAXELEVSMO: 30210.0,
        MINELEVSMO: 30210.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.49599999189376798,
        QA_MA: 1510.365,
        VA_MA: 1.03038,
        QC_MA: 1510.365,
        VC_MA: 1.03038,
        QE_MA: 1705.409,
        VE_MA: 1.03751,
        QA_01: 2359.623,
        VA_01: 1.22338,
        QC_01: 1745.794,
        VC_01: 1.0881,
        QE_01: 1951.069,
        VE_01: 1.09152,
        QA_02: 2500.46,
        VA_02: 1.25198,
        QC_02: 1968.228,
        VC_02: 1.13937,
        QE_02: 2040.05,
        VE_02: 1.11029,
        QA_03: 2869.697,
        VA_03: 1.32358,
        QC_03: 2869.697,
        VC_03: 1.32358,
        QE_03: 3133.817,
        VE_03: 1.31559,
        QA_04: 2209.944,
        VA_04: 1.19209,
        QC_04: 2209.944,
        VC_04: 1.19209,
        QE_04: 2402.07,
        VE_04: 1.18295,
        QA_05: 1728.033,
        VA_05: 1.08388,
        QC_05: 1728.033,
        VC_05: 1.08388,
        QE_05: 1950.155,
        VE_05: 1.09133,
        QA_06: 1012.852,
        VA_06: 0.89234,
        QC_06: 1012.852,
        VC_06: 0.89234,
        QE_06: 1272.465,
        VE_06: 0.93251,
        QA_07: 597.857,
        VA_07: 0.74976,
        QC_07: 597.857,
        VC_07: 0.74976,
        QE_07: 744.751,
        VE_07: 0.778,
        QA_08: 429.164,
        VA_08: 0.67852,
        QC_08: 1005.147,
        VC_08: 0.88998,
        QE_08: 1304.763,
        VE_08: 0.94087,
        QA_09: 445.883,
        VA_09: 0.68611,
        QC_09: 853.784,
        VC_09: 0.8417,
        QE_09: 890.29,
        VE_09: 0.82469,
        QA_10: 604.597,
        VA_10: 0.7524,
        QC_10: 916.819,
        VC_10: 0.86226,
        QE_10: 877.317,
        VE_10: 0.82068,
        QA_11: 1477.976,
        VA_11: 1.02211,
        QC_11: 1477.976,
        VC_11: 1.02211,
        QE_11: 1455.176,
        VE_11: 0.97859,
        QA_12: 1898.342,
        VA_12: 1.12357,
        QC_12: 1898.342,
        VC_12: 1.12357,
        QE_12: 1922.055,
        VE_12: 1.08532,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.652890783698354, 35.77832167779826, 0.0],
          [-81.647382517040114, 35.777979477798681, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286843.0,
        COMID: 9736516,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.771,
        REACHCODE: "03050103000127",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.007526696815892,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060010.0,
        ToNode: 250060016.0,
        Hydroseq: 250005235.0,
        LevelPathI: 250003258.0,
        Pathlength: 389.597,
        TerminalPa: 250002604.0,
        ArbolateSu: 7051.975,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005246.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005225.0,
        FromMeas: 55.79129,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5922,
        TotDASqKM: 9388.9395,
        DivDASqKM: 9388.9395,
        Tidal: 0,
        TOTMA: 0.015416218907900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12636.0,
        MAXELEVSMO: 12675.0,
        MINELEVSMO: 12659.0,
        SLOPE: 0.00020752,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.77100002765655495,
        QA_MA: 4701.751,
        VA_MA: 1.88128,
        QC_MA: 4701.751,
        VC_MA: 1.88128,
        QE_MA: 5403.596,
        VE_MA: 1.8991,
        QA_01: 8582.412,
        VA_01: 2.47347,
        QC_01: 8582.412,
        VC_01: 2.47347,
        QE_01: 7146.965,
        VE_01: 2.15373,
        QA_02: 9073.367,
        VA_02: 2.53838,
        QC_02: 9073.367,
        VC_02: 2.53838,
        QE_02: 7262.149,
        VE_02: 2.16946,
        QA_03: 9967.585,
        VA_03: 2.6525,
        QC_03: 9967.585,
        VC_03: 2.6525,
        QE_03: 7731.803,
        VE_03: 2.23243,
        QA_04: 6897.364,
        VA_04: 2.23622,
        QC_04: 6897.364,
        VC_04: 2.23622,
        QE_04: 6676.117,
        VE_04: 2.08814,
        QA_05: 4730.586,
        VA_05: 1.88639,
        QC_05: 4730.586,
        VC_05: 1.88639,
        QE_05: 5716.179,
        VE_05: 1.94732,
        QA_06: 2788.411,
        VA_06: 1.50021,
        QC_06: 2788.411,
        VC_06: 1.50021,
        QE_06: 5027.414,
        VE_06: 1.83931,
        QA_07: 1728.319,
        VA_07: 1.23289,
        QC_07: 1728.319,
        VC_07: 1.23289,
        QE_07: 3819.969,
        VE_07: 1.63155,
        QA_08: 1229.168,
        VA_08: 1.07985,
        QC_08: 1229.168,
        VC_08: 1.07985,
        QE_08: 3567.057,
        VE_08: 1.58428,
        QA_09: 1124.685,
        VA_09: 1.0443,
        QC_09: 1124.685,
        VC_09: 1.0443,
        QE_09: 3269.121,
        VE_09: 1.52654,
        QA_10: 1217.772,
        VA_10: 1.07604,
        QC_10: 1217.772,
        VC_10: 1.07604,
        QE_10: 4461.162,
        VE_10: 1.74518,
        QA_11: 3146.896,
        VA_11: 1.57927,
        QC_11: 3146.896,
        VC_11: 1.57927,
        QE_11: 4523.442,
        VE_11: 1.7558,
        QA_12: 5966.547,
        VA_12: 2.09338,
        QC_12: 5966.547,
        VC_12: 2.09338,
        QE_12: 5675.208,
        VE_12: 1.94107,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.866927984918334, 34.708996079458075, 0.0],
          [-80.865163718254394, 34.705234412797267, 0.0],
          [-80.862276384925508, 34.70377101279945, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288934.0,
        COMID: 9713493,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.997,
        REACHCODE: "03050104000149",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0091193644246700001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250057091.0,
        ToNode: 250057092.0,
        Hydroseq: 250004219.0,
        LevelPathI: 250003258.0,
        Pathlength: 363.616,
        TerminalPa: 250002604.0,
        ArbolateSu: 8439.685,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004228.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004209.0,
        FromMeas: 0.0,
        ToMeas: 30.41942,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1781,
        TotDASqKM: 11366.2683,
        DivDASqKM: 11366.2683,
        Tidal: 0,
        TOTMA: 0.028469304887900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6793.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.99699997901916504,
        QA_MA: 5474.309,
        VA_MA: 1.31993,
        QC_MA: 5474.309,
        VC_MA: 1.31993,
        QE_MA: 6171.983,
        VE_MA: 1.32981,
        QA_01: 10267.129,
        VA_01: 1.72096,
        QC_01: 10267.129,
        VC_01: 1.72096,
        QE_01: 8874.623,
        VE_01: 1.54706,
        QA_02: 10853.286,
        VA_02: 1.76322,
        QC_02: 10853.286,
        VC_02: 1.76322,
        QE_02: 9013.372,
        VE_02: 1.5573,
        QA_03: 11882.984,
        VA_03: 1.83496,
        QC_03: 11882.984,
        VC_03: 1.83496,
        QE_03: 9633.816,
        VE_03: 1.60224,
        QA_04: 7996.693,
        VA_04: 1.54541,
        QC_04: 7996.693,
        VC_04: 1.54541,
        QE_04: 7745.537,
        VE_04: 1.46071,
        QA_05: 5342.321,
        VA_05: 1.30691,
        QC_05: 5342.321,
        VC_05: 1.30691,
        QE_05: 6297.652,
        VE_05: 1.3408,
        QA_06: 3180.847,
        VA_06: 1.06659,
        QC_06: 3180.847,
        VC_06: 1.06659,
        QE_06: 5404.608,
        VE_06: 1.26028,
        QA_07: 2007.175,
        VA_07: 0.90214,
        QC_07: 2007.175,
        VC_07: 0.90214,
        QE_07: 4083.011,
        VE_07: 1.12859,
        QA_08: 1441.853,
        VA_08: 0.80649,
        QC_08: 1441.853,
        VC_08: 0.80649,
        QE_08: 3736.906,
        VE_08: 1.0909,
        QA_09: 1292.74,
        VA_09: 0.77844,
        QC_09: 1292.74,
        VC_09: 0.77844,
        QE_09: 3429.535,
        VE_09: 1.05604,
        QA_10: 1350.773,
        VA_10: 0.78953,
        QC_10: 1350.773,
        VC_10: 0.78953,
        QE_10: 4670.283,
        VE_10: 1.18926,
        QA_11: 3297.627,
        VA_11: 1.08124,
        QC_11: 3297.627,
        VC_11: 1.08124,
        QE_11: 4743.677,
        VE_11: 1.19658,
        QA_12: 6816.116,
        VA_12: 1.44481,
        QC_12: 6816.116,
        VC_12: 1.44481,
        QE_12: 6524.8,
        VE_12: 1.36041,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878442784900471, 34.517568213088509, 0.0],
          [-80.880275184897641, 34.509113213101614, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283753.0,
        COMID: 9752772,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 4.822,
        REACHCODE: "03050101000171",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.049414264774447998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061135.0,
        ToNode: 250061136.0,
        Hydroseq: 250015617.0,
        LevelPathI: 250015617.0,
        Pathlength: 627.42,
        TerminalPa: 250002604.0,
        ArbolateSu: 560.767,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250015778.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012245.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.9695,
        TotDASqKM: 543.0258,
        DivDASqKM: 543.0258,
        Tidal: 0,
        TOTMA: 0.16330662663100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30210.0,
        MAXELEVSMO: 30293.0,
        MINELEVSMO: 30210.0,
        SLOPE: 0.00017212,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.8220000267028809,
        QA_MA: 338.779,
        VA_MA: 1.11587,
        QC_MA: 327.27,
        VC_MA: 1.11361,
        QE_MA: 367.587,
        VE_MA: 1.12123,
        QA_01: 506.786,
        VA_01: 1.30841,
        QC_01: 390.375,
        VC_01: 1.19264,
        QE_01: 481.588,
        VE_01: 1.24659,
        QA_02: 536.149,
        VA_02: 1.33877,
        QC_02: 440.118,
        VC_02: 1.25079,
        QE_02: 453.321,
        VE_02: 1.21694,
        QA_03: 632.383,
        VA_03: 1.43316,
        QC_03: 520.448,
        VC_03: 1.33853,
        QE_03: 585.584,
        VE_03: 1.34923,
        QA_04: 513.327,
        VA_04: 1.31524,
        QC_04: 466.148,
        VC_04: 1.27999,
        QE_04: 491.221,
        VE_04: 1.25651,
        QA_05: 392.299,
        VA_05: 1.18127,
        QC_05: 356.31,
        VC_05: 1.1508,
        QE_05: 367.72,
        VE_05: 1.12138,
        QA_06: 238.558,
        VA_06: 0.97865,
        QC_06: 295.289,
        VC_06: 1.07082,
        QE_06: 338.184,
        VE_06: 1.08603,
        QA_07: 143.097,
        VA_07: 0.81935,
        QC_07: 211.973,
        VC_07: 0.94776,
        QE_07: 247.025,
        VE_07: 0.96656,
        QA_08: 101.666,
        VA_08: 0.73453,
        QC_08: 231.343,
        VC_08: 0.97815,
        QE_08: 306.441,
        VE_08: 1.04637,
        QA_09: 108.629,
        VA_09: 0.74979,
        QC_09: 245.409,
        VC_09: 0.99947,
        QE_09: 231.777,
        VE_09: 0.94467,
        QA_10: 150.555,
        VA_10: 0.83332,
        QC_10: 301.979,
        VC_10: 1.07995,
        QE_10: 270.736,
        VE_10: 0.99939,
        QA_11: 336.434,
        VA_11: 1.1129,
        QC_11: 323.234,
        VC_11: 1.10832,
        QE_11: 328.555,
        VE_11: 1.07419,
        QA_12: 407.408,
        VA_12: 1.19897,
        QC_12: 313.248,
        VC_12: 1.0951,
        QE_12: 318.383,
        VE_12: 1.06151,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.688963116975742, 35.798139611100851, 0.0],
          [-81.684769783648846, 35.791798211110631, 0.0],
          [-81.677575316993398, 35.793152877775192, 0.0],
          [-81.678134516992486, 35.788961811115087, 0.0],
          [-81.670092917004979, 35.783743811123145, 0.0],
          [-81.660368583686761, 35.783564011123531, 0.0],
          [-81.653790783696991, 35.780199411128649, 0.0],
          [-81.652890783698354, 35.77832167779826, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286844.0,
        COMID: 9736466,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.831,
        REACHCODE: "03050103000129",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0075064756028809998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059989.0,
        ToNode: 250060082.0,
        Hydroseq: 250005413.0,
        LevelPathI: 250003258.0,
        Pathlength: 395.233,
        TerminalPa: 250002604.0,
        ArbolateSu: 7007.285,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005423.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005404.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1052,
        TotDASqKM: 9346.9356,
        DivDASqKM: 9346.9356,
        Tidal: 0,
        TOTMA: 0.024468111709600001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12675.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.83099997043609597,
        QA_MA: 4685.457,
        VA_MA: 1.27862,
        QC_MA: 4685.457,
        VC_MA: 1.27862,
        QE_MA: 5387.284,
        VE_MA: 1.28965,
        QA_01: 8546.65,
        VA_01: 1.64341,
        QC_01: 8546.65,
        VC_01: 1.64341,
        QE_01: 7111.283,
        VE_01: 1.44552,
        QA_02: 9035.764,
        VA_02: 1.68345,
        QC_02: 9035.764,
        VC_02: 1.68345,
        QE_02: 7224.629,
        VE_02: 1.45511,
        QA_03: 9927.136,
        VA_03: 1.75389,
        QC_03: 9927.136,
        VC_03: 1.75389,
        QE_03: 7691.479,
        VE_03: 1.49388,
        QA_04: 6874.147,
        VA_04: 1.49762,
        QC_04: 6874.147,
        VC_04: 1.49762,
        QE_04: 6652.93,
        VE_04: 1.406,
        QA_05: 4717.679,
        VA_05: 1.28216,
        QC_05: 4717.679,
        VC_05: 1.28216,
        QE_05: 5703.271,
        VE_05: 1.3198,
        QA_06: 2780.034,
        VA_06: 1.04374,
        QC_06: 2780.034,
        VC_06: 1.04374,
        QE_06: 5018.959,
        VE_06: 1.25344,
        QA_07: 1722.482,
        VA_07: 0.87869,
        QC_07: 1722.482,
        VC_07: 0.87869,
        QE_07: 3814.062,
        VE_07: 1.12524,
        QA_08: 1224.65,
        VA_08: 0.7842,
        QC_08: 1224.65,
        VC_08: 0.7842,
        QE_08: 3562.441,
        VE_08: 1.09617,
        QA_09: 1121.296,
        VA_09: 0.76244,
        QC_09: 1121.296,
        VC_09: 0.76244,
        QE_09: 3265.653,
        VE_09: 1.06061,
        QA_10: 1214.948,
        VA_10: 0.7822,
        QC_10: 1214.948,
        VC_10: 0.7822,
        QE_10: 4458.209,
        VE_10: 1.19581,
        QA_11: 3144.305,
        VA_11: 1.09343,
        QC_11: 3144.305,
        VC_11: 1.09343,
        QE_11: 4520.783,
        VE_11: 1.20241,
        QA_12: 5948.311,
        VA_12: 1.40969,
        QC_12: 5948.311,
        VC_12: 1.40969,
        QE_12: 5657.009,
        VE_12: 1.31544,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878305118234039, 34.757201279383196, 0.0],
          [-80.877735118234853, 34.749928812727831, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283754.0,
        COMID: 9752654,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.191,
        REACHCODE: "03050101000172",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018612347804990001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061105.0,
        ToNode: 250061135.0,
        Hydroseq: 250015778.0,
        LevelPathI: 250015617.0,
        Pathlength: 632.242,
        TerminalPa: 250002604.0,
        ArbolateSu: 555.753,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250015944.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015617.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2736,
        TotDASqKM: 535.0347,
        DivDASqKM: 535.0347,
        Tidal: 0,
        TOTMA: 0.0088036303854999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30279.0,
        MAXELEVSMO: 30293.0,
        MINELEVSMO: 30293.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.19099999964237199,
        QA_MA: 334.807,
        VA_MA: 0.82038,
        QC_MA: 323.582,
        VC_MA: 0.81896,
        QE_MA: 363.898,
        VE_MA: 0.82384,
        QA_01: 499.352,
        VA_01: 0.94145,
        QC_01: 384.797,
        VC_01: 0.86817,
        QE_01: 476.011,
        VE_01: 0.90285,
        QA_02: 528.109,
        VA_02: 0.96056,
        QC_02: 433.697,
        VC_02: 0.90488,
        QE_02: 446.9,
        VE_02: 0.88326,
        QA_03: 623.854,
        VA_03: 1.02089,
        QC_03: 513.659,
        VC_03: 0.96096,
        QE_03: 578.795,
        VE_03: 0.96788,
        QA_04: 507.277,
        VA_04: 0.94677,
        QC_04: 460.778,
        VC_04: 0.92438,
        QE_04: 485.852,
        VE_04: 0.90935,
        QA_05: 388.303,
        VA_05: 0.8623,
        QC_05: 352.816,
        VC_05: 0.84296,
        QE_05: 364.225,
        VE_05: 0.82409,
        QA_06: 236.259,
        VA_06: 0.73385,
        QC_06: 292.483,
        VC_06: 0.79229,
        QE_06: 335.377,
        VE_06: 0.80198,
        QA_07: 141.7,
        VA_07: 0.63271,
        QC_07: 209.953,
        VC_07: 0.71418,
        QE_07: 245.006,
        VE_07: 0.72618,
        QA_08: 100.699,
        VA_08: 0.57891,
        QC_08: 229.099,
        VC_08: 0.73343,
        QE_08: 304.197,
        VE_08: 0.77706,
        QA_09: 107.877,
        VA_09: 0.58898,
        QC_09: 243.908,
        VC_09: 0.7478,
        QE_09: 230.277,
        VE_09: 0.71265,
        QA_10: 149.91,
        VA_10: 0.64255,
        QC_10: 300.946,
        VC_10: 0.79968,
        QE_10: 269.703,
        VE_10: 0.74804,
        QA_11: 333.962,
        VA_11: 0.81969,
        QC_11: 321.422,
        VC_11: 0.81715,
        QE_11: 326.742,
        VE_11: 0.79519,
        QA_12: 402.279,
        VA_12: 0.8728,
        QC_12: 309.659,
        VC_12: 0.80718,
        QE_12: 314.794,
        VE_12: 0.78566,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.690060583640687, 35.799467211098772, 0.0],
          [-81.688963116975742, 35.798139611100851, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286845.0,
        COMID: 9736438,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.077,
        REACHCODE: "03050103000135",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00069134751677299997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059976.0,
        ToNode: 250060134.0,
        Hydroseq: 250005577.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.71,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.022,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005587.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 3,
        DnHydroseq: 250005564.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 9332.8164,
        DivDASqKM: 9332.8164,
        Tidal: 0,
        TOTMA: 0.0010153758980099999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13239.0,
        MAXELEVSMO: 13272.0,
        MINELEVSMO: 13239.0,
        SLOPE: 0.00428571,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.076999999582767001,
        QA_MA: 4679.92,
        VA_MA: 2.85068,
        QC_MA: 4679.92,
        VC_MA: 2.85068,
        QE_MA: 5381.741,
        VE_MA: 2.87962,
        QA_01: 8534.512,
        VA_01: 3.8061,
        QC_01: 8534.512,
        VC_01: 3.8061,
        QE_01: 7099.171,
        VE_01: 3.28699,
        QA_02: 9023.048,
        VA_02: 3.91103,
        QC_02: 9023.048,
        VC_02: 3.91103,
        QE_02: 7211.94,
        VE_02: 3.31202,
        QA_03: 9913.471,
        VA_03: 4.09564,
        QC_03: 9913.471,
        VC_03: 4.09564,
        QE_03: 7677.855,
        VE_03: 3.41354,
        QA_04: 6866.294,
        VA_04: 3.42461,
        QC_04: 6866.294,
        VC_04: 3.42461,
        QE_04: 6645.088,
        VE_04: 3.18425,
        QA_05: 4713.313,
        VA_05: 2.8603,
        QC_05: 4713.313,
        VC_05: 2.8603,
        QE_05: 5698.904,
        VE_05: 2.959,
        QA_06: 2777.187,
        VA_06: 2.2354,
        QC_06: 2777.187,
        VC_06: 2.2354,
        QE_06: 5016.085,
        VE_06: 2.78532,
        QA_07: 1720.426,
        VA_07: 1.80274,
        QC_07: 1720.426,
        VC_07: 1.80274,
        QE_07: 3811.983,
        VE_07: 2.44937,
        QA_08: 1223.132,
        VA_08: 1.55513,
        QC_08: 1223.132,
        VC_08: 1.55513,
        QE_08: 3560.89,
        VE_08: 2.37329,
        QA_09: 1120.157,
        VA_09: 1.49825,
        QC_09: 1120.157,
        VC_09: 1.49825,
        QE_09: 3264.488,
        VE_09: 2.28016,
        QA_10: 1213.999,
        VA_10: 1.55018,
        QC_10: 1213.999,
        VC_10: 1.55018,
        QE_10: 4457.216,
        VE_10: 2.63473,
        QA_11: 3143.356,
        VA_11: 2.36639,
        QC_11: 3143.356,
        VC_11: 2.36639,
        QE_11: 4519.811,
        VE_11: 2.65202,
        QA_12: 5942.052,
        VA_12: 3.19434,
        QC_12: 5942.052,
        VC_12: 3.19434,
        QE_12: 5650.761,
        VE_12: 2.94708,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876164318237343, 34.779875012681373, 0.0],
          [-80.87613231823741, 34.779191679349083, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283763.0,
        COMID: 9752194,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.304,
        REACHCODE: "03050101000181",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012338589151753,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060986.0,
        ToNode: 250060992.0,
        Hydroseq: 250017590.0,
        LevelPathI: 250015617.0,
        Pathlength: 643.292,
        TerminalPa: 250002604.0,
        ArbolateSu: 502.563,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250017821.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017361.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8568,
        TotDASqKM: 483.1857,
        DivDASqKM: 483.1857,
        Tidal: 0,
        TOTMA: 0.034579686610500003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30970.0,
        MAXELEVSMO: 31368.0,
        MINELEVSMO: 31156.0,
        SLOPE: 0.00162576,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3040000200271611,
        QA_MA: 306.735,
        VA_MA: 1.43339,
        QC_MA: 297.46,
        VC_MA: 1.4306,
        QE_MA: 301.901,
        VE_MA: 1.43195,
        QA_01: 450.877,
        VA_01: 1.68873,
        QC_01: 348.374,
        VC_01: 1.52881,
        QE_01: 358.42,
        VE_01: 1.53914,
        QA_02: 476.832,
        VA_02: 1.73037,
        QC_02: 392.679,
        VC_02: 1.60891,
        QE_02: 394.133,
        VE_02: 1.6028,
        QA_03: 567.045,
        VA_03: 1.86741,
        QC_03: 468.358,
        VC_03: 1.73649,
        QE_03: 475.532,
        VE_03: 1.7385,
        QA_04: 464.392,
        VA_04: 1.71055,
        QC_04: 422.672,
        VC_04: 1.66074,
        QE_04: 425.433,
        VE_04: 1.65641,
        QA_05: 356.816,
        VA_05: 1.52746,
        QC_05: 325.234,
        VC_05: 1.48507,
        QE_05: 326.49,
        VE_05: 1.47965,
        QA_06: 218.586,
        VA_06: 1.24816,
        QC_06: 270.894,
        VC_06: 1.37621,
        QE_06: 275.618,
        VE_06: 1.3789,
        QA_07: 131.263,
        VA_07: 1.02522,
        QC_07: 194.844,
        VC_07: 1.2048,
        QE_07: 198.705,
        VE_07: 1.20817,
        QA_08: 93.657,
        VA_08: 0.90759,
        QC_08: 212.769,
        VC_08: 1.24769,
        QE_08: 221.04,
        VE_08: 1.26053,
        QA_09: 102.041,
        VA_09: 0.93549,
        QC_09: 232.222,
        VC_09: 1.29235,
        QE_09: 230.721,
        VE_09: 1.28245,
        QA_10: 143.996,
        VA_10: 1.06134,
        QC_10: 291.423,
        VC_10: 1.41845,
        QE_10: 287.982,
        VE_10: 1.40414,
        QA_11: 311.046,
        VA_11: 1.44176,
        QC_11: 304.46,
        VC_11: 1.44454,
        QE_11: 305.046,
        VE_11: 1.43815,
        QA_12: 366.0,
        VA_12: 1.54403,
        QC_12: 284.144,
        VC_12: 1.40364,
        QE_12: 284.709,
        VE_12: 1.3975,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.728048983581687, 35.87109621098756, 0.0],
          [-81.72711598358319, 35.867730677659438, 0.0],
          [-81.732283116908491, 35.860901677670093, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286846.0,
        COMID: 9734268,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.56,
        REACHCODE: "03050103000138",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015397069504220999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059329.0,
        ToNode: 250095021.0,
        Hydroseq: 250018603.0,
        LevelPathI: 250005808.0,
        Pathlength: 400.889,
        TerminalPa: 250002604.0,
        ArbolateSu: 159.784,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250018888.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018234.0,
        FromMeas: 47.14316,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0746,
        TotDASqKM: 136.026,
        DivDASqKM: 136.026,
        Tidal: 0,
        TOTMA: 0.056393464310899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13599.0,
        MAXELEVSMO: 13772.0,
        MINELEVSMO: 13629.0,
        SLOPE: 0.00091666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.559999942779541,
        QA_MA: 54.867,
        VA_MA: 1.04827,
        QC_MA: 56.883,
        VC_MA: 1.05043,
        QE_MA: 56.883,
        VE_MA: 1.05043,
        QA_01: 120.054,
        VA_01: 1.42972,
        QC_01: 96.034,
        VC_01: 1.28833,
        QE_01: 96.034,
        VE_01: 1.28833,
        QA_02: 124.666,
        VA_02: 1.45239,
        QC_02: 106.488,
        VC_02: 1.3436,
        QE_02: 106.488,
        VE_02: 1.3436,
        QA_03: 133.14,
        VA_03: 1.49304,
        QC_03: 115.356,
        VC_03: 1.38852,
        QE_03: 115.356,
        VE_03: 1.38852,
        QA_04: 76.793,
        VA_04: 1.19287,
        QC_04: 72.812,
        VC_04: 1.15437,
        QE_04: 72.812,
        VE_04: 1.15437,
        QA_05: 42.796,
        VA_05: 0.95681,
        QC_05: 42.229,
        VC_05: 0.94186,
        QE_05: 42.229,
        VE_05: 0.94186,
        QA_06: 27.433,
        VA_06: 0.82043,
        QC_06: 34.981,
        VC_06: 0.88159,
        QE_06: 34.981,
        VE_06: 0.88159,
        QA_07: 20.118,
        VA_07: 0.74268,
        QC_07: 31.233,
        VC_07: 0.84811,
        QE_07: 31.233,
        VE_07: 0.84811,
        QA_08: 14.631,
        VA_08: 0.67512,
        QC_08: 32.026,
        VC_08: 0.85534,
        QE_08: 32.026,
        VE_08: 0.85534,
        QA_09: 11.856,
        VA_09: 0.63637,
        QC_09: 34.717,
        VC_09: 0.87929,
        QE_09: 34.717,
        VE_09: 0.87929,
        QA_10: 9.144,
        VA_10: 0.59411,
        QC_10: 32.224,
        VC_10: 0.85714,
        QE_10: 32.224,
        VE_10: 0.85714,
        QA_11: 12.587,
        VA_11: 0.64695,
        QC_11: 26.38,
        VC_11: 0.80182,
        QE_11: 26.38,
        VE_11: 0.80182,
        QA_12: 65.584,
        VA_12: 1.12179,
        QC_12: 59.454,
        VC_12: 1.06805,
        QE_12: 59.454,
        VE_12: 1.06805,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.866270584919278, 34.803402012644881, 0.0],
          [-80.866798384918539, 34.801500812647816, 0.0],
          [-80.87063058491259, 34.799530879317558, 0.0],
          [-80.869714318247304, 34.797813012653535, 0.0],
          [-80.871352718244736, 34.796301212655806, 0.0],
          [-80.874101584907123, 34.797148812654541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283764.0,
        COMID: 9752130,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 3.907,
        REACHCODE: "03050101000182",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.038370018108901997,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060970.0,
        ToNode: 250060986.0,
        Hydroseq: 250017821.0,
        LevelPathI: 250015617.0,
        Pathlength: 644.596,
        TerminalPa: 250002604.0,
        ArbolateSu: 499.585,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250018070.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017590.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.4011,
        TotDASqKM: 480.8421,
        DivDASqKM: 480.8421,
        Tidal: 0,
        TOTMA: 0.12124818852700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31086.0,
        MAXELEVSMO: 31544.0,
        MINELEVSMO: 31368.0,
        SLOPE: 0.00045047,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.907000064849854,
        QA_MA: 305.499,
        VA_MA: 1.22476,
        QC_MA: 296.308,
        VC_MA: 1.2225,
        QE_MA: 300.727,
        VE_MA: 1.2236,
        QA_01: 448.733,
        VA_01: 1.43232,
        QC_01: 346.76,
        VC_01: 1.3021,
        QE_01: 356.757,
        VE_01: 1.31051,
        QA_02: 474.551,
        VA_02: 1.4662,
        QC_02: 390.851,
        VC_02: 1.3673,
        QE_02: 392.298,
        VE_02: 1.36234,
        QA_03: 564.515,
        VA_03: 1.578,
        QC_03: 466.337,
        VC_03: 1.47139,
        QE_03: 473.476,
        VE_03: 1.47303,
        QA_04: 462.47,
        VA_04: 1.45046,
        QC_04: 420.962,
        VC_04: 1.40987,
        QE_04: 423.71,
        VE_04: 1.40634,
        QA_05: 355.417,
        VA_05: 1.30145,
        QC_05: 324.006,
        VC_05: 1.26692,
        QE_05: 325.257,
        VE_05: 1.26251,
        QA_06: 217.799,
        VA_06: 1.07406,
        QC_06: 269.931,
        VC_06: 1.17835,
        QE_06: 274.632,
        VE_06: 1.18053,
        QA_07: 130.79,
        VA_07: 0.89241,
        QC_07: 194.16,
        VC_07: 1.03871,
        QE_07: 198.001,
        VE_07: 1.04145,
        QA_08: 93.342,
        VA_08: 0.79664,
        QC_08: 212.039,
        VC_08: 1.07369,
        QE_08: 220.269,
        VE_08: 1.08413,
        QA_09: 101.789,
        VA_09: 0.81962,
        QC_09: 231.716,
        VC_09: 1.11061,
        QE_09: 230.222,
        VE_09: 1.10255,
        QA_10: 143.807,
        VA_10: 0.92259,
        QC_10: 291.117,
        VC_10: 1.21396,
        QE_10: 287.693,
        VE_10: 1.20231,
        QA_11: 310.087,
        VA_11: 1.23205,
        QC_11: 303.744,
        VC_11: 1.23461,
        QE_11: 304.327,
        VE_11: 1.2294,
        QA_12: 364.413,
        VA_12: 1.31472,
        QC_12: 283.022,
        VC_12: 1.2005,
        QE_12: 283.585,
        VE_12: 1.19551,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.709012983611217, 35.881622810971237, 0.0],
          [-81.707489516946964, 35.877891210977062, 0.0],
          [-81.71280258360531, 35.873696477650128, 0.0],
          [-81.719817316927788, 35.880652677639318, 0.0],
          [-81.723110516922702, 35.881497277638175, 0.0],
          [-81.724568316920397, 35.877305210977966, 0.0],
          [-81.723749316921726, 35.874970010981542, 0.0],
          [-81.728048983581687, 35.87109621098756, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283765.0,
        COMID: 9752026,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 3.254,
        REACHCODE: "03050101000183",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031925514470218998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060937.0,
        ToNode: 250060970.0,
        Hydroseq: 250018070.0,
        LevelPathI: 250015617.0,
        Pathlength: 648.503,
        TerminalPa: 250002604.0,
        ArbolateSu: 312.996,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250018326.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017821.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1238,
        TotDASqKM: 298.9809,
        DivDASqKM: 298.9809,
        Tidal: 0,
        TOTMA: 0.092630189991300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31544.0,
        MAXELEVSMO: 32154.0,
        MINELEVSMO: 31544.0,
        SLOPE: 0.00187461,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.2539999485015869,
        QA_MA: 177.55,
        VA_MA: 1.33344,
        QC_MA: 175.873,
        VC_MA: 1.33265,
        QE_MA: 178.621,
        VE_MA: 1.33394,
        QA_01: 273.283,
        VA_01: 1.59722,
        QC_01: 213.944,
        VC_01: 1.44497,
        QE_01: 220.16,
        VE_01: 1.45436,
        QA_02: 288.98,
        VA_02: 1.63602,
        QC_02: 241.25,
        VC_02: 1.51983,
        QE_02: 242.15,
        VE_02: 1.51379,
        QA_03: 337.351,
        VA_03: 1.74973,
        QC_03: 283.457,
        VC_03: 1.62811,
        QE_03: 287.896,
        VE_03: 1.62979,
        QA_04: 274.765,
        VA_04: 1.60093,
        QC_04: 253.082,
        VC_04: 1.55102,
        QE_04: 254.79,
        VE_04: 1.5468,
        QA_05: 207.175,
        VA_05: 1.42097,
        QC_05: 192.718,
        VC_05: 1.38363,
        QE_05: 193.495,
        VE_05: 1.37844,
        QA_06: 122.325,
        VA_06: 1.14945,
        QC_06: 152.811,
        VC_06: 1.25899,
        QE_06: 155.734,
        VE_06: 1.26193,
        QA_07: 73.512,
        VA_07: 0.95018,
        QC_07: 110.644,
        VC_07: 1.10921,
        QE_07: 113.032,
        VE_07: 1.11272,
        QA_08: 50.11,
        VA_08: 0.83201,
        QC_08: 112.422,
        VC_08: 1.11602,
        QE_08: 117.54,
        VE_08: 1.12959,
        QA_09: 48.697,
        VA_09: 0.82411,
        QC_09: 120.852,
        VC_09: 1.14763,
        QE_09: 119.923,
        VE_09: 1.13839,
        QA_10: 68.731,
        VA_10: 0.92766,
        QC_10: 161.415,
        VC_10: 1.28704,
        QE_10: 159.286,
        VE_10: 1.27341,
        QA_11: 172.701,
        VA_11: 1.31848,
        QC_11: 194.382,
        VC_11: 1.38855,
        QE_11: 194.745,
        VE_11: 1.3821,
        QA_12: 213.889,
        VA_12: 1.43997,
        QC_12: 174.293,
        VC_12: 1.32776,
        QE_12: 174.643,
        VE_12: 1.32175,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.692228183637326, 35.897481010946649, 0.0],
          [-81.692929783636203, 35.895213410950134, 0.0],
          [-81.696558383630588, 35.89324181095327, 0.0],
          [-81.701852583622383, 35.896215410948685, 0.0],
          [-81.704129116952174, 35.893397077619625, 0.0],
          [-81.702998916953845, 35.889000877626472, 0.0],
          [-81.704512916951558, 35.883687010968117, 0.0],
          [-81.709012983611217, 35.881622810971237, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283766.0,
        COMID: 9751930,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 3.083,
        REACHCODE: "03050101000184",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.030152481099171,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060913.0,
        ToNode: 250060937.0,
        Hydroseq: 250018326.0,
        LevelPathI: 250015617.0,
        Pathlength: 651.757,
        TerminalPa: 250002604.0,
        ArbolateSu: 307.629,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250018596.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018070.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.178,
        TotDASqKM: 293.2164,
        DivDASqKM: 293.2164,
        Tidal: 0,
        TOTMA: 0.086328284289899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32011.0,
        MAXELEVSMO: 32833.0,
        MINELEVSMO: 32154.0,
        SLOPE: 0.0022024,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0829999446868901,
        QA_MA: 174.509,
        VA_MA: 1.35554,
        QC_MA: 172.977,
        VC_MA: 1.35479,
        QE_MA: 175.671,
        VE_MA: 1.3561,
        QA_01: 268.003,
        VA_01: 1.62345,
        QC_01: 209.917,
        VC_01: 1.46806,
        QE_01: 216.013,
        VE_01: 1.47766,
        QA_02: 283.39,
        VA_02: 1.66301,
        QC_02: 236.71,
        VC_02: 1.54444,
        QE_02: 237.592,
        VE_02: 1.5383,
        QA_03: 331.171,
        VA_03: 1.77985,
        QC_03: 278.434,
        VC_03: 1.65572,
        QE_03: 282.787,
        VE_03: 1.65745,
        QA_04: 270.061,
        VA_04: 1.6288,
        QC_04: 248.846,
        VC_04: 1.57769,
        QE_04: 250.522,
        VE_04: 1.5734,
        QA_05: 203.751,
        VA_05: 1.44532,
        QC_05: 189.651,
        VC_05: 1.40719,
        QE_05: 190.413,
        VE_05: 1.4019,
        QA_06: 120.388,
        VA_06: 1.16818,
        QC_06: 150.423,
        VC_06: 1.27997,
        QE_06: 153.29,
        VE_06: 1.28296,
        QA_07: 72.349,
        VA_07: 0.96447,
        QC_07: 108.935,
        VC_07: 1.12697,
        QE_07: 111.278,
        VE_07: 1.13054,
        QA_08: 49.335,
        VA_08: 0.84377,
        QC_08: 110.649,
        VC_08: 1.13379,
        QE_08: 115.668,
        VE_08: 1.1476,
        QA_09: 48.077,
        VA_09: 0.83648,
        QC_09: 119.492,
        VC_09: 1.16818,
        QE_09: 118.581,
        VE_09: 1.15876,
        QA_10: 68.266,
        VA_10: 0.94455,
        QC_10: 160.542,
        VC_10: 1.31415,
        QE_10: 158.454,
        VE_10: 1.30026,
        QA_11: 170.246,
        VA_11: 1.34188,
        QC_11: 192.271,
        VC_11: 1.41523,
        QE_11: 192.626,
        VE_11: 1.40863,
        QA_12: 209.951,
        VA_12: 1.46356,
        QC_12: 171.371,
        VC_12: 1.34962,
        QE_12: 171.715,
        VE_12: 1.34351,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.694320183634034, 35.907395610931189, 0.0],
          [-81.689451116974908, 35.907993810930407, 0.0],
          [-81.686461516979548, 35.900942077607965, 0.0],
          [-81.68800851697722, 35.899819210942894, 0.0],
          [-81.691389183638591, 35.903389810937426, 0.0],
          [-81.693190516969082, 35.903320010937534, 0.0],
          [-81.691076183639098, 35.899634210943248, 0.0],
          [-81.692228183637326, 35.897481010946649, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283759.0,
        COMID: 9752416,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.984,
        REACHCODE: "03050101000177",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020206212605263001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061049.0,
        ToNode: 250061065.0,
        Hydroseq: 250016713.0,
        LevelPathI: 250015617.0,
        Pathlength: 639.836,
        TerminalPa: 250002604.0,
        ArbolateSu: 537.957,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250016926.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016506.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.862,
        TotDASqKM: 515.4786,
        DivDASqKM: 515.4786,
        Tidal: 0,
        TOTMA: 0.062670265425700006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30627.0,
        MAXELEVSMO: 30838.0,
        MINELEVSMO: 30768.0,
        SLOPE: 0.00035282,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.983999967575073,
        QA_MA: 324.743,
        VA_MA: 1.20343,
        QC_MA: 314.227,
        VC_MA: 1.20105,
        QE_MA: 318.964,
        VE_MA: 1.20213,
        QA_01: 481.212,
        VA_01: 1.41129,
        QC_01: 371.178,
        VC_01: 1.2836,
        QE_01: 381.896,
        VE_01: 1.29181,
        QA_02: 508.659,
        VA_02: 1.44426,
        QC_02: 418.152,
        VC_02: 1.3473,
        QE_02: 419.703,
        VE_02: 1.34236,
        QA_03: 602.79,
        VA_03: 1.55142,
        QC_03: 496.878,
        VC_03: 1.44695,
        QE_03: 504.532,
        VE_03: 1.44853,
        QA_04: 491.839,
        VA_04: 1.42416,
        QC_04: 447.069,
        VC_04: 1.38485,
        QE_04: 450.015,
        VE_04: 1.38136,
        QA_05: 377.518,
        VA_05: 1.27794,
        QC_05: 343.378,
        VC_05: 1.24411,
        QE_05: 344.719,
        VE_05: 1.23975,
        QA_06: 230.112,
        VA_06: 1.05382,
        QC_06: 284.976,
        VC_06: 1.15591,
        QE_06: 290.015,
        VE_06: 1.1581,
        QA_07: 138.066,
        VA_07: 0.87672,
        QC_07: 204.695,
        VC_07: 1.01935,
        QE_07: 208.814,
        VE_07: 1.02209,
        QA_08: 98.18,
        VA_08: 0.78261,
        QC_08: 223.255,
        VC_08: 1.05289,
        QE_08: 232.079,
        VE_08: 1.06327,
        QA_09: 105.895,
        VA_09: 0.80204,
        QC_09: 239.949,
        VC_09: 1.08195,
        QE_09: 238.348,
        VE_09: 1.07403,
        QA_10: 148.333,
        VA_10: 0.89878,
        QC_10: 298.413,
        VC_10: 1.1769,
        QE_10: 294.743,
        VE_10: 1.16543,
        QA_11: 326.765,
        VA_11: 1.20639,
        QC_11: 316.125,
        VC_11: 1.20391,
        QE_11: 316.75,
        VE_11: 1.19883,
        QA_12: 389.309,
        VA_12: 1.2939,
        QC_12: 300.562,
        VC_12: 1.18022,
        QE_12: 301.165,
        VE_12: 1.17529,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.729256783579856, 35.849041811021891, 0.0],
          [-81.713716916937301, 35.837352477706702, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286842.0,
        COMID: 9736528,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.977,
        REACHCODE: "03050103000127",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0090740925523450002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060016.0,
        ToNode: 250060018.0,
        Hydroseq: 250005225.0,
        LevelPathI: 250003258.0,
        Pathlength: 388.62,
        TerminalPa: 250002604.0,
        ArbolateSu: 7054.885,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005235.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005217.0,
        FromMeas: 0.0,
        ToMeas: 55.79129,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8118,
        TotDASqKM: 9390.7458,
        DivDASqKM: 9390.7458,
        Tidal: 0,
        TOTMA: 0.021443946079,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12636.0,
        MAXELEVSMO: 12659.0,
        MINELEVSMO: 12649.0,
        SLOPE: 0.00010235,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97699999809265103,
        QA_MA: 4702.438,
        VA_MA: 1.71414,
        QC_MA: 4702.438,
        VC_MA: 1.71414,
        QE_MA: 5404.283,
        VE_MA: 1.73006,
        QA_01: 8583.928,
        VA_01: 2.24343,
        QC_01: 8583.928,
        VC_01: 2.24343,
        QE_01: 7148.478,
        VE_01: 1.9577,
        QA_02: 9074.97,
        VA_02: 2.30144,
        QC_02: 9074.97,
        VC_02: 2.30144,
        QE_02: 7263.749,
        VE_02: 1.97177,
        QA_03: 9969.309,
        VA_03: 2.40344,
        QC_03: 9969.309,
        VC_03: 2.40344,
        QE_03: 7733.521,
        VE_03: 2.02806,
        QA_04: 6898.335,
        VA_04: 2.03135,
        QC_04: 6898.335,
        VC_04: 2.03135,
        QE_04: 6677.087,
        VE_04: 1.89903,
        QA_05: 4731.12,
        VA_05: 1.71868,
        QC_05: 4731.12,
        VC_05: 1.71868,
        QE_05: 5716.713,
        VE_05: 1.77313,
        QA_06: 2788.751,
        VA_06: 1.37355,
        QC_06: 2788.751,
        VC_06: 1.37355,
        QE_06: 5027.757,
        VE_06: 1.67658,
        QA_07: 1728.562,
        VA_07: 1.13465,
        QC_07: 1728.562,
        VC_07: 1.13465,
        QE_07: 3820.215,
        VE_07: 1.4909,
        QA_08: 1229.363,
        VA_08: 0.99787,
        QC_08: 1229.363,
        VC_08: 0.99787,
        QE_08: 3567.255,
        VE_08: 1.44866,
        QA_09: 1124.831,
        VA_09: 0.96609,
        QC_09: 1124.831,
        VC_09: 0.96609,
        QE_09: 3269.27,
        VE_09: 1.39704,
        QA_10: 1217.893,
        VA_10: 0.99445,
        QC_10: 1217.893,
        VC_10: 0.99445,
        QE_10: 4461.289,
        VE_10: 1.59243,
        QA_11: 3146.993,
        VA_11: 1.44415,
        QC_11: 3146.993,
        VC_11: 1.44415,
        QE_11: 4523.542,
        VE_11: 1.60192,
        QA_12: 5967.299,
        VA_12: 1.90368,
        QC_12: 5967.299,
        VC_12: 1.90368,
        QE_12: 5675.958,
        VE_12: 1.76758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.862276384925508, 34.70377101279945, 0.0],
          [-80.859455984929923, 34.700278879471512, 0.0],
          [-80.859925784929089, 34.695789879478639, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283760.0,
        COMID: 9752292,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.37,
        REACHCODE: "03050101000178",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003438217831024,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061010.0,
        ToNode: 250061049.0,
        Hydroseq: 250016926.0,
        LevelPathI: 250015617.0,
        Pathlength: 641.82,
        TerminalPa: 250002604.0,
        ArbolateSu: 534.159,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250017139.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016713.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1143,
        TotDASqKM: 511.2027,
        DivDASqKM: 511.2027,
        Tidal: 0,
        TOTMA: 0.0078463848832200003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30803.0,
        MAXELEVSMO: 31156.0,
        MINELEVSMO: 30838.0,
        SLOPE: 0.00859459,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37000000476837203,
        QA_MA: 322.476,
        VA_MA: 1.79275,
        QC_MA: 312.118,
        VC_MA: 1.78883,
        QE_MA: 316.816,
        VE_MA: 1.79062,
        QA_01: 477.231,
        VA_01: 2.1362,
        QC_01: 368.188,
        VC_01: 1.92457,
        QE_01: 378.817,
        VE_01: 1.93819,
        QA_02: 504.419,
        VA_02: 2.19077,
        QC_02: 414.76,
        VC_02: 2.03009,
        QE_02: 416.299,
        VE_02: 2.02192,
        QA_03: 598.14,
        VA_03: 2.36906,
        QC_03: 493.171,
        VC_03: 2.1959,
        QE_03: 500.761,
        VE_03: 2.19852,
        QA_04: 488.363,
        VA_04: 2.15872,
        QC_04: 443.981,
        VC_04: 2.09347,
        QE_04: 446.903,
        VE_04: 2.08769,
        QA_05: 374.994,
        VA_05: 1.91656,
        QC_05: 341.168,
        VC_05: 1.86047,
        QE_05: 342.497,
        VE_05: 1.85325,
        QA_06: 228.684,
        VA_06: 1.54514,
        QC_06: 283.231,
        VC_06: 1.71442,
        QE_06: 288.229,
        VE_06: 1.71803,
        QA_07: 137.213,
        VA_07: 1.25133,
        QC_07: 203.461,
        VC_07: 1.48792,
        QE_07: 207.545,
        VE_07: 1.49245,
        QA_08: 97.605,
        VA_08: 1.09532,
        QC_08: 221.922,
        VC_08: 1.5436,
        QE_08: 230.672,
        VE_08: 1.56077,
        QA_09: 105.436,
        VA_09: 1.12824,
        QC_09: 239.029,
        VC_09: 1.59328,
        QE_09: 237.441,
        VE_09: 1.58016,
        QA_10: 147.988,
        VA_10: 1.28995,
        QC_10: 297.859,
        VC_10: 1.75252,
        QE_10: 294.218,
        VE_10: 1.73351,
        QA_11: 325.008,
        VA_11: 1.79893,
        QC_11: 314.828,
        VC_11: 1.79564,
        QE_11: 315.448,
        VE_11: 1.78722,
        QA_12: 386.389,
        VA_12: 1.94233,
        QC_12: 298.51,
        VC_12: 1.7542,
        QE_12: 299.108,
        VE_12: 1.74604,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.730723583577571, 35.852063477683828, 0.0],
          [-81.729256783579856, 35.849041811021891, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286851.0,
        COMID: 9734150,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.626,
        REACHCODE: "03050103000140",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0059982324612479999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059289.0,
        ToNode: 250059308.0,
        Hydroseq: 250019190.0,
        LevelPathI: 250005808.0,
        Pathlength: 404.689,
        TerminalPa: 250002604.0,
        ArbolateSu: 145.452,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250019514.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018888.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3105,
        TotDASqKM: 123.6303,
        DivDASqKM: 123.6303,
        Tidal: 0,
        TOTMA: 0.032373514425300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13930.0,
        MAXELEVSMO: 14011.0,
        MINELEVSMO: 14009.0,
        SLOPE: 3.194e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.62599998712539695,
        QA_MA: 49.961,
        VA_MA: 0.7329,
        QC_MA: 51.985,
        VC_MA: 0.73427,
        QE_MA: 51.985,
        VE_MA: 0.73427,
        QA_01: 109.261,
        VA_01: 0.95158,
        QC_01: 87.616,
        VC_01: 0.8703,
        QE_01: 87.616,
        VE_01: 0.8703,
        QA_02: 113.415,
        VA_02: 0.96445,
        QC_02: 97.128,
        VC_02: 0.9019,
        QE_02: 97.128,
        VE_02: 0.9019,
        QA_03: 121.125,
        VA_03: 0.98777,
        QC_03: 105.275,
        VC_03: 0.92784,
        QE_03: 105.275,
        VE_03: 0.92784,
        QA_04: 69.816,
        VA_04: 0.81544,
        QC_04: 66.34,
        VC_04: 0.79314,
        QE_04: 66.34,
        VE_04: 0.79314,
        QA_05: 38.929,
        VA_05: 0.68022,
        QC_05: 38.549,
        VC_05: 0.67174,
        QE_05: 38.549,
        VE_05: 0.67174,
        QA_06: 24.934,
        VA_06: 0.60189,
        QC_06: 31.835,
        VC_06: 0.63663,
        QE_06: 31.835,
        VE_06: 0.63663,
        QA_07: 18.285,
        VA_07: 0.55732,
        QC_07: 28.452,
        VC_07: 0.61762,
        QE_07: 28.452,
        VE_07: 0.61762,
        QA_08: 13.298,
        VA_08: 0.5186,
        QC_08: 29.052,
        VC_08: 0.62107,
        QE_08: 29.052,
        VE_08: 0.62107,
        QA_09: 10.856,
        VA_09: 0.49713,
        QC_09: 32.119,
        VC_09: 0.63818,
        QE_09: 32.119,
        VE_09: 0.63818,
        QA_10: 8.311,
        VA_10: 0.47217,
        QC_10: 29.856,
        VC_10: 0.62563,
        QE_10: 29.856,
        VE_10: 0.62563,
        QA_11: 11.754,
        VA_11: 0.50526,
        QC_11: 25.037,
        VC_11: 0.59732,
        QE_11: 25.037,
        VE_11: 0.59732,
        QA_12: 59.885,
        VA_12: 0.77577,
        QC_12: 54.735,
        VC_12: 0.74608,
        QE_12: 54.735,
        VE_12: 0.74608,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.854408984937777, 34.819434879286696, 0.0],
          [-80.857492984932946, 34.814922879293647, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283761.0,
        COMID: 9752272,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.842,
        REACHCODE: "03050101000179",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0078273462889860007,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061007.0,
        ToNode: 250061010.0,
        Hydroseq: 250017139.0,
        LevelPathI: 250015617.0,
        Pathlength: 642.19,
        TerminalPa: 250002604.0,
        ArbolateSu: 531.734,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250017361.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016926.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1547,
        TotDASqKM: 509.3262,
        DivDASqKM: 509.3262,
        Tidal: 0,
        TOTMA: 0.039266811440799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30867.0,
        MAXELEVSMO: 31156.0,
        MINELEVSMO: 31156.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.84200000762939498,
        QA_MA: 321.473,
        VA_MA: 0.81497,
        QC_MA: 311.185,
        VC_MA: 0.81364,
        QE_MA: 315.865,
        VE_MA: 0.81425,
        QA_01: 475.49,
        VA_01: 0.93201,
        QC_01: 366.88,
        VC_01: 0.8598,
        QE_01: 377.469,
        VE_01: 0.86445,
        QA_02: 502.556,
        VA_02: 0.95061,
        QC_02: 413.27,
        VC_02: 0.89579,
        QE_02: 414.803,
        VE_02: 0.893,
        QA_03: 596.094,
        VA_03: 1.01154,
        QC_03: 491.54,
        VC_03: 0.95245,
        QE_03: 499.102,
        VE_03: 0.95335,
        QA_04: 486.821,
        VA_04: 0.93985,
        QC_04: 442.611,
        VC_04: 0.91757,
        QE_04: 445.522,
        VE_04: 0.91561,
        QA_05: 373.855,
        VA_05: 0.85725,
        QC_05: 340.17,
        VC_05: 0.8381,
        QE_05: 341.495,
        VE_05: 0.83565,
        QA_06: 228.051,
        VA_06: 0.73054,
        QC_06: 282.459,
        VC_06: 0.78831,
        QE_06: 287.439,
        VE_06: 0.78954,
        QA_07: 136.834,
        VA_07: 0.63025,
        QC_07: 202.913,
        VC_07: 0.71101,
        QE_07: 206.982,
        VE_07: 0.71255,
        QA_08: 97.352,
        VA_08: 0.57703,
        QC_08: 221.336,
        VC_08: 0.73002,
        QE_08: 230.055,
        VE_08: 0.73588,
        QA_09: 105.234,
        VA_09: 0.58837,
        QC_09: 238.625,
        VC_09: 0.7472,
        QE_09: 237.042,
        VE_09: 0.74273,
        QA_10: 147.837,
        VA_10: 0.64375,
        QC_10: 297.616,
        VC_10: 0.80182,
        QE_10: 293.988,
        VE_10: 0.79533,
        QA_11: 324.21,
        VA_11: 0.81726,
        QC_11: 314.238,
        VC_11: 0.81626,
        QE_11: 314.856,
        VE_11: 0.81339,
        QA_12: 385.088,
        VA_12: 0.86594,
        QC_12: 297.595,
        VC_12: 0.8018,
        QE_12: 298.191,
        VE_12: 0.79902,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.73363058357296, 35.858930877673117, 0.0],
          [-81.730723583577571, 35.852063477683828, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286852.0,
        COMID: 9734128,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.898,
        REACHCODE: "03050103000141",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.019164597235960999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059281.0,
        ToNode: 250059289.0,
        Hydroseq: 250019514.0,
        LevelPathI: 250005808.0,
        Pathlength: 405.315,
        TerminalPa: 250002604.0,
        ArbolateSu: 142.526,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250019857.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019190.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0322,
        TotDASqKM: 120.9528,
        DivDASqKM: 120.9528,
        Tidal: 0,
        TOTMA: 0.068480361588600006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14011.0,
        MAXELEVSMO: 14220.0,
        MINELEVSMO: 14011.0,
        SLOPE: 0.00110115,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8980000019073491,
        QA_MA: 48.897,
        VA_MA: 1.05001,
        QC_MA: 50.92,
        VC_MA: 1.05245,
        QE_MA: 50.92,
        VE_MA: 1.05245,
        QA_01: 106.921,
        VA_01: 1.43197,
        QC_01: 85.789,
        VC_01: 1.28991,
        QE_01: 85.789,
        VE_01: 1.28991,
        QA_02: 110.987,
        VA_02: 1.45446,
        QC_02: 95.104,
        VC_02: 1.34514,
        QE_02: 95.104,
        VE_02: 1.34514,
        QA_03: 118.527,
        VA_03: 1.49516,
        QC_03: 103.09,
        VC_03: 1.39049,
        QE_03: 103.09,
        VE_03: 1.39049,
        QA_04: 68.304,
        VA_04: 1.19404,
        QC_04: 64.936,
        VC_04: 1.15499,
        QE_04: 64.936,
        VE_04: 1.15499,
        QA_05: 38.091,
        VA_05: 0.9579,
        QC_05: 37.75,
        VC_05: 0.94312,
        QE_05: 37.75,
        VE_05: 0.94312,
        QA_06: 24.394,
        VA_06: 0.82104,
        QC_06: 31.155,
        VC_06: 0.8816,
        QE_06: 31.155,
        VE_06: 0.8816,
        QA_07: 17.889,
        VA_07: 0.74319,
        QC_07: 27.85,
        VC_07: 0.84846,
        QE_07: 27.85,
        VE_07: 0.84846,
        QA_08: 13.01,
        VA_08: 0.67555,
        QC_08: 28.41,
        VC_08: 0.8542,
        QE_08: 28.41,
        VE_08: 0.8542,
        QA_09: 10.64,
        VA_09: 0.63836,
        QC_09: 31.554,
        VC_09: 0.88548,
        QE_09: 31.554,
        VE_09: 0.88548,
        QA_10: 8.131,
        VA_10: 0.59445,
        QC_10: 29.338,
        VC_10: 0.86359,
        QE_10: 29.338,
        VE_10: 0.86359,
        QA_11: 11.574,
        VA_11: 0.65344,
        QC_11: 24.744,
        VC_11: 0.81559,
        QE_11: 24.744,
        VE_11: 0.81559,
        QA_12: 58.628,
        VA_12: 1.12504,
        QC_12: 53.689,
        VC_12: 1.07367,
        QE_12: 53.689,
        VE_12: 1.07367,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.843546318287963, 34.826762679275362, 0.0],
          [-80.85082518494329, 34.822435079281945, 0.0],
          [-80.853880584938565, 34.822435479281978, 0.0],
          [-80.852936984940087, 34.818976612620645, 0.0],
          [-80.854408984937777, 34.819434879286696, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283762.0,
        COMID: 9752222,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.26,
        REACHCODE: "03050101000180",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024800494444029999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060992.0,
        ToNode: 250061007.0,
        Hydroseq: 250017361.0,
        LevelPathI: 250015617.0,
        Pathlength: 643.032,
        TerminalPa: 250002604.0,
        ArbolateSu: 504.744,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250017590.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017139.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0603,
        TotDASqKM: 484.5933,
        DivDASqKM: 484.5933,
        Tidal: 0,
        TOTMA: 0.0122071487747,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30909.0,
        MAXELEVSMO: 31156.0,
        MINELEVSMO: 31156.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.259999990463257,
        QA_MA: 307.481,
        VA_MA: 0.80942,
        QC_MA: 298.155,
        VC_MA: 0.80818,
        QE_MA: 302.609,
        VE_MA: 0.80878,
        QA_01: 452.176,
        VA_01: 0.92329,
        QC_01: 349.351,
        VC_01: 0.85205,
        QE_01: 359.427,
        VE_01: 0.85665,
        QA_02: 478.214,
        VA_02: 0.94184,
        QC_02: 393.785,
        VC_02: 0.88774,
        QE_02: 395.244,
        VE_02: 0.88501,
        QA_03: 568.572,
        VA_03: 1.00282,
        QC_03: 469.577,
        VC_03: 0.9445,
        QE_03: 476.772,
        VE_03: 0.94539,
        QA_04: 465.547,
        VA_04: 0.93288,
        QC_04: 423.699,
        VC_04: 0.9107,
        QE_04: 426.468,
        VE_04: 0.90877,
        QA_05: 357.648,
        VA_05: 0.85129,
        QC_05: 325.964,
        VC_05: 0.83241,
        QE_05: 327.224,
        VE_05: 0.82999,
        QA_06: 219.06,
        VA_06: 0.72687,
        QC_06: 271.472,
        VC_06: 0.7839,
        QE_06: 276.21,
        VE_06: 0.7851,
        QA_07: 131.546,
        VA_07: 0.62759,
        QC_07: 195.256,
        VC_07: 0.70757,
        QE_07: 199.127,
        VE_07: 0.70907,
        QA_08: 93.846,
        VA_08: 0.57519,
        QC_08: 213.207,
        VC_08: 0.72665,
        QE_08: 221.502,
        VE_08: 0.73238,
        QA_09: 102.193,
        VA_09: 0.58754,
        QC_09: 232.526,
        VC_09: 0.74637,
        QE_09: 231.021,
        VE_09: 0.74196,
        QA_10: 144.11,
        VA_10: 0.64343,
        QC_10: 291.607,
        VC_10: 0.80232,
        QE_10: 288.156,
        VE_10: 0.79594,
        QA_11: 311.623,
        VA_11: 0.813,
        QC_11: 304.891,
        VC_11: 0.81414,
        QE_11: 305.478,
        VE_11: 0.81129,
        QA_12: 366.959,
        VA_12: 0.85875,
        QC_12: 284.821,
        VC_12: 0.79618,
        QE_12: 285.388,
        VE_12: 0.79345,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.732283116908491, 35.860901677670093, 0.0],
          [-81.73363058357296, 35.858930877673117, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286853.0,
        COMID: 9734098,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.466,
        REACHCODE: "03050103000142",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0046492313227180001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059272.0,
        ToNode: 250059281.0,
        Hydroseq: 250019857.0,
        LevelPathI: 250005808.0,
        Pathlength: 407.213,
        TerminalPa: 250002604.0,
        ArbolateSu: 134.68,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250020202.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019514.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0891,
        TotDASqKM: 113.1579,
        DivDASqKM: 113.1579,
        Tidal: 0,
        TOTMA: 0.018472405022499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14220.0,
        MAXELEVSMO: 14244.0,
        MINELEVSMO: 14220.0,
        SLOPE: 0.00051502,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.46599999070167503,
        QA_MA: 45.786,
        VA_MA: 0.95567,
        QC_MA: 47.802,
        VC_MA: 0.95793,
        QE_MA: 47.802,
        VE_MA: 0.95793,
        QA_01: 100.109,
        VA_01: 1.28899,
        QC_01: 80.462,
        VC_01: 1.16482,
        QE_01: 80.462,
        VE_01: 1.16482,
        QA_02: 103.861,
        VA_02: 1.30834,
        QC_02: 89.159,
        VC_02: 1.21279,
        QE_02: 89.159,
        VE_02: 1.21279,
        QA_03: 110.93,
        VA_03: 1.34392,
        QC_03: 96.694,
        VC_03: 1.2526,
        QE_03: 96.694,
        VE_03: 1.2526,
        QA_04: 63.902,
        VA_04: 1.08102,
        QC_04: 60.843,
        VC_04: 1.0467,
        QE_04: 60.843,
        VE_04: 1.0467,
        QA_05: 35.585,
        VA_05: 0.87458,
        QC_05: 35.357,
        VC_05: 0.86182,
        QE_05: 35.357,
        VE_05: 0.86182,
        QA_06: 22.821,
        VA_06: 0.7556,
        QC_06: 29.174,
        VC_06: 0.80812,
        QE_06: 29.174,
        VE_06: 0.80812,
        QA_07: 16.736,
        VA_07: 0.6877,
        QC_07: 26.097,
        VC_07: 0.7794,
        QE_07: 26.097,
        VE_07: 0.7794,
        QA_08: 12.171,
        VA_08: 0.62868,
        QC_08: 26.544,
        VC_08: 0.78367,
        QE_08: 26.544,
        VE_08: 0.78367,
        QA_09: 10.011,
        VA_09: 0.59712,
        QC_09: 29.902,
        VC_09: 0.8147,
        QE_09: 29.902,
        VE_09: 0.8147,
        QA_10: 7.607,
        VA_10: 0.55794,
        QC_10: 27.818,
        VC_10: 0.79564,
        QE_10: 27.818,
        VE_10: 0.79564,
        QA_11: 11.05,
        VA_11: 0.61266,
        QC_11: 23.885,
        VC_11: 0.75776,
        QE_11: 23.885,
        VE_11: 0.75776,
        QA_12: 54.96,
        VA_12: 1.02158,
        QC_12: 50.624,
        VC_12: 0.97802,
        QE_12: 50.624,
        VE_12: 0.97802,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.840380318292887, 34.825112812611053, 0.0],
          [-80.841573984957677, 34.82689967927513, 0.0],
          [-80.843546318287963, 34.826762679275362, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283803.0,
        COMID: 9750436,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.129,
        REACHCODE: "03050101000221",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0012721005187339999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060538.0,
        ToNode: 250060552.0,
        Hydroseq: 250027076.0,
        LevelPathI: 250015617.0,
        Pathlength: 677.52,
        TerminalPa: 250002604.0,
        ArbolateSu: 111.66,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250027948.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026279.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.018,
        TotDASqKM: 103.0266,
        DivDASqKM: 103.0266,
        Tidal: 0,
        TOTMA: 0.0044988025154000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38788.0,
        MAXELEVSMO: 38879.0,
        MINELEVSMO: 38860.0,
        SLOPE: 0.00147286,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.12899999320507,
        QA_MA: 67.727,
        VA_MA: 1.08708,
        QC_MA: 69.644,
        VC_MA: 1.08884,
        QE_MA: 69.644,
        VE_MA: 1.08884,
        QA_01: 94.442,
        VA_01: 1.23744,
        QC_01: 76.023,
        VC_01: 1.12602,
        QE_01: 76.023,
        VE_01: 1.12602,
        QA_02: 103.07,
        VA_02: 1.28159,
        QC_02: 88.499,
        VC_02: 1.19472,
        QE_02: 88.499,
        VE_02: 1.19472,
        QA_03: 124.357,
        VA_03: 1.38361,
        QC_03: 107.99,
        VC_03: 1.29353,
        QE_03: 107.99,
        VE_03: 1.29353,
        QA_04: 103.24,
        VA_04: 1.28244,
        QC_04: 97.233,
        VC_04: 1.24014,
        QE_04: 97.233,
        VE_04: 1.24014,
        QA_05: 77.993,
        VA_05: 1.14767,
        QC_05: 75.251,
        VC_05: 1.1216,
        QE_05: 75.251,
        VE_05: 1.1216,
        QA_06: 50.21,
        VA_06: 0.97266,
        QC_06: 63.495,
        VC_06: 1.05146,
        QE_06: 63.495,
        VE_06: 1.05146,
        QA_07: 30.369,
        VA_07: 0.81701,
        QC_07: 46.686,
        VC_07: 0.9395,
        QE_07: 46.686,
        VE_07: 0.9395,
        QA_08: 21.587,
        VA_08: 0.7327,
        QC_08: 47.621,
        VC_08: 0.94618,
        QE_08: 47.621,
        VE_08: 0.94618,
        QA_09: 25.855,
        VA_09: 0.77536,
        QC_09: 69.103,
        VC_09: 1.08561,
        QE_09: 69.103,
        VE_09: 1.08561,
        QA_10: 36.861,
        VA_10: 0.87212,
        QC_10: 98.128,
        VC_10: 1.24469,
        QE_10: 98.128,
        VE_10: 1.24469,
        QA_11: 69.645,
        VA_11: 1.09871,
        QC_11: 97.246,
        VC_11: 1.24021,
        QE_11: 97.246,
        VE_11: 1.24021,
        QA_12: 75.576,
        VA_12: 1.13376,
        QC_12: 67.642,
        VC_12: 1.07684,
        QE_12: 67.642,
        VE_12: 1.07684,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.687407983644732, 36.028977010742551, 0.0],
          [-81.686533316979421, 36.028061477410574, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286854.0,
        COMID: 9734110,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.886,
        REACHCODE: "03050103000143",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0088291230415570004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059275.0,
        ToNode: 250059272.0,
        Hydroseq: 250020202.0,
        LevelPathI: 250005808.0,
        Pathlength: 407.679,
        TerminalPa: 250002604.0,
        ArbolateSu: 132.501,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250020559.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019857.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.441,
        TotDASqKM: 111.3822,
        DivDASqKM: 111.3822,
        Tidal: 0,
        TOTMA: 0.031734941492300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14201.0,
        MAXELEVSMO: 14361.0,
        MINELEVSMO: 14244.0,
        SLOPE: 0.00132054,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.88599997758865401,
        QA_MA: 45.079,
        VA_MA: 1.05749,
        QC_MA: 47.093,
        VC_MA: 1.06015,
        QE_MA: 47.093,
        VE_MA: 1.06015,
        QA_01: 98.557,
        VA_01: 1.44321,
        QC_01: 79.247,
        VC_01: 1.29945,
        QE_01: 79.247,
        VE_01: 1.29945,
        QA_02: 102.237,
        VA_02: 1.46552,
        QC_02: 87.803,
        VC_02: 1.3549,
        QE_02: 87.803,
        VE_02: 1.3549,
        QA_03: 109.2,
        VA_03: 1.50672,
        QC_03: 95.236,
        VC_03: 1.40104,
        QE_03: 95.236,
        VE_03: 1.40104,
        QA_04: 62.899,
        VA_04: 1.20244,
        QC_04: 59.91,
        VC_04: 1.16265,
        QE_04: 59.91,
        VE_04: 1.16265,
        QA_05: 35.032,
        VA_05: 0.96361,
        QC_05: 34.828,
        VC_05: 0.94886,
        QE_05: 34.828,
        VE_05: 0.94886,
        QA_06: 22.463,
        VA_06: 0.82589,
        QC_06: 28.722,
        VC_06: 0.88655,
        QE_06: 28.722,
        VE_06: 0.88655,
        QA_07: 16.473,
        VA_07: 0.74731,
        QC_07: 25.697,
        VC_07: 0.85337,
        QE_07: 25.697,
        VE_07: 0.85337,
        QA_08: 11.98,
        VA_08: 0.67902,
        QC_08: 26.119,
        VC_08: 0.85811,
        QE_08: 26.119,
        VE_08: 0.85811,
        QA_09: 9.868,
        VA_09: 0.64275,
        QC_09: 29.524,
        VC_09: 0.89507,
        QE_09: 29.524,
        VE_09: 0.89507,
        QA_10: 7.488,
        VA_10: 0.59717,
        QC_10: 27.469,
        VC_10: 0.87302,
        QE_10: 27.469,
        VE_10: 0.87302,
        QA_11: 10.93,
        VA_11: 0.6614,
        QC_11: 23.688,
        VC_11: 0.83032,
        QE_11: 23.688,
        VE_11: 0.83032,
        QA_12: 54.125,
        VA_12: 1.13389,
        QC_12: 49.923,
        VC_12: 1.08381,
        QE_12: 49.923,
        VE_12: 1.08381,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.834825784968189, 34.823278679280691, 0.0],
          [-80.836185318299385, 34.826669079275405, 0.0],
          [-80.840380318292887, 34.825112812611053, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283804.0,
        COMID: 9750430,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.232,
        REACHCODE: "03050101000222",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0025890089913100001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060537.0,
        ToNode: 250060538.0,
        Hydroseq: 250027948.0,
        LevelPathI: 250015617.0,
        Pathlength: 677.649,
        TerminalPa: 250002604.0,
        ArbolateSu: 109.908,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250028910.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027076.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0423,
        TotDASqKM: 102.1698,
        DivDASqKM: 102.1698,
        Tidal: 0,
        TOTMA: 0.0085133965108899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38770.0,
        MAXELEVSMO: 38901.0,
        MINELEVSMO: 38879.0,
        SLOPE: 0.00094827,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.23199999332428001,
        QA_MA: 67.29,
        VA_MA: 1.03314,
        QC_MA: 69.212,
        VC_MA: 1.0348,
        QE_MA: 69.212,
        VE_MA: 1.0348,
        QA_01: 93.713,
        VA_01: 1.17251,
        QC_01: 75.452,
        VC_01: 1.06887,
        QE_01: 75.452,
        VE_01: 1.06887,
        QA_02: 102.271,
        VA_02: 1.21355,
        QC_02: 87.831,
        VC_02: 1.13276,
        QE_02: 87.831,
        VE_02: 1.13276,
        QA_03: 123.431,
        VA_03: 1.30861,
        QC_03: 107.212,
        VC_03: 1.22483,
        QE_03: 107.212,
        VE_03: 1.22483,
        QA_04: 102.491,
        VA_04: 1.21458,
        QC_04: 96.543,
        VC_04: 1.17521,
        QE_04: 96.543,
        VE_04: 1.17521,
        QA_05: 77.472,
        VA_05: 1.08945,
        QC_05: 74.767,
        VC_05: 1.06519,
        QE_05: 74.767,
        VE_05: 1.06519,
        QA_06: 49.923,
        VA_06: 0.92689,
        QC_06: 63.137,
        VC_06: 1.00021,
        QE_06: 63.137,
        VE_06: 1.00021,
        QA_07: 30.196,
        VA_07: 0.78198,
        QC_07: 46.426,
        VC_07: 0.896,
        QE_07: 46.426,
        VE_07: 0.896,
        QA_08: 21.473,
        VA_08: 0.70356,
        QC_08: 47.365,
        VC_08: 0.90228,
        QE_08: 47.365,
        VE_08: 0.90228,
        QA_09: 25.774,
        VA_09: 0.74378,
        QC_09: 68.912,
        VC_09: 1.03312,
        QE_09: 68.912,
        VE_09: 1.03312,
        QA_10: 36.803,
        VA_10: 0.83444,
        QC_10: 98.004,
        VC_10: 1.18215,
        QE_10: 98.004,
        VE_10: 1.18215,
        QA_11: 69.312,
        VA_11: 1.04463,
        QC_11: 96.892,
        VC_11: 1.17688,
        QE_11: 96.892,
        VE_11: 1.17688,
        QA_12: 75.091,
        VA_12: 1.07661,
        QC_12: 67.247,
        VC_12: 1.02377,
        QE_12: 67.247,
        VE_12: 1.02377,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.689973183640802, 36.028677877409677, 0.0],
          [-81.687407983644732, 36.028977010742551, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286847.0,
        COMID: 933050106,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.178,
        REACHCODE: "03050103000138",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010945796911245,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250095020.0,
        ToNode: 250059968.0,
        Hydroseq: 250005828.0,
        LevelPathI: 250005808.0,
        Pathlength: 399.678,
        TerminalPa: 250002604.0,
        ArbolateSu: 6979.746,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250018234.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005808.0,
        FromMeas: 6.31006,
        ToMeas: 46.04568,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2555,
        TotDASqKM: 9325.9791,
        DivDASqKM: 138.9303,
        Tidal: 0,
        TOTMA: 0.042714012197899998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13527.0,
        MAXELEVSMO: 13629.0,
        MINELEVSMO: 13527.0,
        SLOPE: 0.00086587,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1779999732971189,
        QA_MA: 56.018,
        VA_MA: 1.04514,
        QC_MA: 58.03,
        VC_MA: 1.04724,
        QE_MA: 58.03,
        VE_MA: 1.04724,
        QA_01: 122.587,
        VA_01: 1.42504,
        QC_01: 98.006,
        VC_01: 1.28431,
        QE_01: 98.006,
        VE_01: 1.28431,
        QA_02: 127.309,
        VA_02: 1.44768,
        QC_02: 108.683,
        VC_02: 1.33939,
        QE_02: 108.683,
        VE_02: 1.33939,
        QA_03: 135.951,
        VA_03: 1.48811,
        QC_03: 117.711,
        VC_03: 1.38402,
        QE_03: 117.711,
        VE_03: 1.38402,
        QA_04: 78.433,
        VA_04: 1.1893,
        QC_04: 74.331,
        VC_04: 1.15105,
        QE_04: 74.331,
        VE_04: 1.15105,
        QA_05: 43.703,
        VA_05: 0.95414,
        QC_05: 43.09,
        VC_05: 0.93922,
        QE_05: 43.09,
        VE_05: 0.93922,
        QA_06: 28.019,
        VA_06: 0.81837,
        QC_06: 35.718,
        VC_06: 0.87939,
        QE_06: 35.718,
        VE_06: 0.87939,
        QA_07: 20.547,
        VA_07: 0.74093,
        QC_07: 31.884,
        VC_07: 0.84598,
        QE_07: 31.884,
        VE_07: 0.84598,
        QA_08: 14.944,
        VA_08: 0.67364,
        QC_08: 32.724,
        VC_08: 0.85346,
        QE_08: 32.724,
        VE_08: 0.85346,
        QA_09: 12.09,
        VA_09: 0.63477,
        QC_09: 35.322,
        VC_09: 0.87602,
        QE_09: 35.322,
        VE_09: 0.87602,
        QA_10: 9.34,
        VA_10: 0.59297,
        QC_10: 32.772,
        VC_10: 0.85388,
        QE_10: 32.772,
        VE_10: 0.85388,
        QA_11: 12.783,
        VA_11: 0.64457,
        QC_11: 26.691,
        VC_11: 0.79756,
        QE_11: 26.691,
        VE_11: 0.79756,
        QA_12: 66.907,
        VA_12: 1.11801,
        QC_12: 60.544,
        VC_12: 1.06407,
        QE_12: 60.544,
        VE_12: 1.06407,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874143518240487, 34.796859812654986, 0.0],
          [-80.872225718243442, 34.794708679325026, 0.0],
          [-80.873967584907405, 34.787268879336523, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283805.0,
        COMID: 9750438,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.594,
        REACHCODE: "03050101000223",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017172915985071001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060539.0,
        ToNode: 250060537.0,
        Hydroseq: 250028910.0,
        LevelPathI: 250015617.0,
        Pathlength: 677.881,
        TerminalPa: 250002604.0,
        ArbolateSu: 107.004,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250029956.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027948.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8423,
        TotDASqKM: 99.6471,
        DivDASqKM: 99.6471,
        Tidal: 0,
        TOTMA: 0.047973733452599997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38871.0,
        MAXELEVSMO: 39764.0,
        MINELEVSMO: 38901.0,
        SLOPE: 0.00541405,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.593999981880188,
        QA_MA: 66.052,
        VA_MA: 1.25947,
        QC_MA: 67.987,
        VC_MA: 1.2617,
        QE_MA: 67.987,
        VE_MA: 1.2617,
        QA_01: 91.627,
        VA_01: 1.43999,
        QC_01: 73.816,
        VC_01: 1.30426,
        QE_01: 73.816,
        VE_01: 1.30426,
        QA_02: 99.961,
        VA_02: 1.49353,
        QC_02: 85.901,
        VC_02: 1.38776,
        QE_02: 85.901,
        VE_02: 1.38776,
        QA_03: 120.735,
        VA_03: 1.61851,
        QC_03: 104.947,
        VC_03: 1.50886,
        QE_03: 104.947,
        VE_03: 1.50886,
        QA_04: 100.311,
        VA_04: 1.49573,
        QC_04: 94.536,
        VC_04: 1.44407,
        QE_04: 94.536,
        VE_04: 1.44407,
        QA_05: 76.025,
        VA_05: 1.33321,
        QC_05: 73.422,
        VC_05: 1.30144,
        QE_05: 73.422,
        VE_05: 1.30144,
        QA_06: 49.1,
        VA_06: 1.12089,
        QC_06: 62.111,
        VC_06: 1.21702,
        QE_06: 62.111,
        VE_06: 1.21702,
        QA_07: 29.689,
        VA_07: 0.93041,
        QC_07: 45.665,
        VC_07: 1.0801,
        QE_07: 45.665,
        VE_07: 1.0801,
        QA_08: 21.163,
        VA_08: 0.82807,
        QC_08: 46.666,
        VC_08: 1.08904,
        QE_08: 46.666,
        VE_08: 1.08904,
        QA_09: 25.542,
        VA_09: 0.88265,
        QC_09: 68.365,
        VC_09: 1.26451,
        QE_09: 68.365,
        VE_09: 1.26451,
        QA_10: 36.627,
        VA_10: 1.0038,
        QC_10: 97.63,
        VC_10: 1.46366,
        QE_10: 97.63,
        VE_10: 1.46366,
        QA_11: 68.522,
        VA_11: 1.2782,
        QC_11: 96.048,
        VC_11: 1.45368,
        QE_11: 96.048,
        VE_11: 1.45368,
        QA_12: 73.8,
        VA_12: 1.31717,
        QC_12: 66.194,
        VC_12: 1.24827,
        QE_12: 66.194,
        VE_12: 1.24827,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.705791316949615, 36.031187210739063, 0.0],
          [-81.701140116956822, 36.031785610738162, 0.0],
          [-81.69143838363857, 36.027784010744377, 0.0],
          [-81.689973183640802, 36.028677877409677, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286848.0,
        COMID: 9736422,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.186,
        REACHCODE: "03050103000138",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001837472409018,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059968.0,
        ToNode: 250059972.0,
        Hydroseq: 250005808.0,
        LevelPathI: 250005808.0,
        Pathlength: 399.492,
        TerminalPa: 250002604.0,
        ArbolateSu: 6979.932,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250005828.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005654.0,
        FromMeas: 0.0,
        ToMeas: 6.31006,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0468,
        TotDASqKM: 9326.0259,
        DivDASqKM: 138.9771,
        Tidal: 0,
        TOTMA: 0.0058660661378299997,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13473.0,
        MAXELEVSMO: 13527.0,
        MINELEVSMO: 13473.0,
        SLOPE: 0.00290322,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18600000441074399,
        QA_MA: 56.037,
        VA_MA: 1.20148,
        QC_MA: 58.048,
        VC_MA: 1.20403,
        QE_MA: 58.048,
        VE_MA: 1.20403,
        QA_01: 122.628,
        VA_01: 1.662,
        QC_01: 98.038,
        VC_01: 1.49141,
        QE_01: 98.038,
        VE_01: 1.49141,
        QA_02: 127.351,
        VA_02: 1.68944,
        QC_02: 108.718,
        VC_02: 1.55818,
        QE_02: 108.718,
        VE_02: 1.55818,
        QA_03: 135.997,
        VA_03: 1.73846,
        QC_03: 117.749,
        VC_03: 1.61228,
        QE_03: 117.749,
        VE_03: 1.61228,
        QA_04: 78.459,
        VA_04: 1.37623,
        QC_04: 74.356,
        VC_04: 1.32987,
        QE_04: 74.356,
        VE_04: 1.32987,
        QA_05: 43.717,
        VA_05: 1.09117,
        QC_05: 43.104,
        VC_05: 1.07309,
        QE_05: 43.104,
        VE_05: 1.07309,
        QA_06: 28.029,
        VA_06: 0.92659,
        QC_06: 35.729,
        VC_06: 1.00057,
        QE_06: 35.729,
        VE_06: 1.00057,
        QA_07: 20.554,
        VA_07: 0.83271,
        QC_07: 31.894,
        VC_07: 0.96006,
        QE_07: 31.894,
        VE_07: 0.96006,
        QA_08: 14.949,
        VA_08: 0.75115,
        QC_08: 32.735,
        VC_08: 0.96913,
        QE_08: 32.735,
        VE_08: 0.96913,
        QA_09: 12.094,
        VA_09: 0.70403,
        QC_09: 35.332,
        VC_09: 0.99647,
        QE_09: 35.332,
        VE_09: 0.99647,
        QA_10: 9.343,
        VA_10: 0.65336,
        QC_10: 32.781,
        VC_10: 0.96962,
        QE_10: 32.781,
        VE_10: 0.96962,
        QA_11: 12.786,
        VA_11: 0.71589,
        QC_11: 26.696,
        VC_11: 0.90132,
        QE_11: 26.696,
        VE_11: 0.90132,
        QA_12: 66.928,
        VA_12: 1.2898,
        QC_12: 60.562,
        VC_12: 1.22442,
        QE_12: 60.562,
        VE_12: 1.22442,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.873967584907405, 34.787268879336523, 0.0],
          [-80.875286118238648, 34.78600787933857, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288915.0,
        COMID: 9714699,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.227,
        REACHCODE: "03050104000134",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0023914386626209998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057182.0,
        ToNode: 250057187.0,
        Hydroseq: 250024885.0,
        LevelPathI: 250021319.0,
        Pathlength: 353.351,
        TerminalPa: 250002604.0,
        ArbolateSu: 89.443,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250025557.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024261.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0567,
        TotDASqKM: 94.4784,
        DivDASqKM: 94.4784,
        Tidal: 0,
        TOTMA: 0.0071976214435699997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7289.0,
        MAXELEVSMO: 7418.0,
        MINELEVSMO: 7305.0,
        SLOPE: 0.00497797,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.22699999809265101,
        QA_MA: 35.497,
        VA_MA: 1.19378,
        QC_MA: 37.428,
        VC_MA: 1.19759,
        QE_MA: 37.428,
        VE_MA: 1.19759,
        QA_01: 78.884,
        VA_01: 1.66134,
        QC_01: 63.799,
        VC_01: 1.48867,
        QE_01: 63.799,
        VE_01: 1.48867,
        QA_02: 81.85,
        VA_02: 1.68812,
        QC_02: 70.722,
        VC_02: 1.55503,
        QE_02: 70.722,
        VE_02: 1.55503,
        QA_03: 87.727,
        VA_03: 1.73987,
        QC_03: 77.064,
        VC_03: 1.6132,
        QE_03: 77.064,
        VE_03: 1.6132,
        QA_04: 50.692,
        VA_04: 1.37819,
        QC_04: 48.521,
        VC_04: 1.329,
        QE_04: 48.521,
        VE_04: 1.329,
        QA_05: 28.204,
        VA_05: 1.092,
        QC_05: 28.268,
        VC_05: 1.07446,
        QE_05: 28.268,
        VE_05: 1.07446,
        QA_06: 18.056,
        VA_06: 0.92676,
        QC_06: 23.156,
        VC_06: 0.99746,
        QE_06: 23.156,
        VE_06: 0.99746,
        QA_07: 13.875,
        VA_07: 0.84605,
        QC_07: 21.733,
        VC_07: 0.97464,
        QE_07: 21.733,
        VE_07: 0.97464,
        QA_08: 10.162,
        VA_08: 0.7641,
        QC_08: 22.082,
        VC_08: 0.9803,
        QE_08: 22.082,
        VE_08: 0.9803,
        QA_09: 7.684,
        VA_09: 0.70124,
        QC_09: 23.674,
        VC_09: 1.00561,
        QE_09: 23.674,
        VE_09: 1.00561,
        QA_10: 6.351,
        VA_10: 0.66344,
        QC_10: 24.084,
        VC_10: 1.01199,
        QE_10: 24.084,
        VE_10: 1.01199,
        QA_11: 5.522,
        VA_11: 0.63802,
        QC_11: 14.073,
        VC_11: 0.83735,
        QE_11: 14.073,
        VE_11: 0.83735,
        QA_12: 37.072,
        VA_12: 1.21443,
        QC_12: 35.381,
        VC_12: 1.17142,
        QE_12: 35.381,
        VE_12: 1.17142,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.758476318420037, 34.467266613166714, 0.0],
          [-80.760662518416609, 34.46630647983477, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283806.0,
        COMID: 9750410,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.606,
        REACHCODE: "03050101000224",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0059702245000919996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060534.0,
        ToNode: 250060539.0,
        Hydroseq: 250029956.0,
        LevelPathI: 250015617.0,
        Pathlength: 679.475,
        TerminalPa: 250002604.0,
        ArbolateSu: 103.735,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250031120.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028910.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6993,
        TotDASqKM: 95.6277,
        DivDASqKM: 95.6277,
        Tidal: 0,
        TOTMA: 0.019803481490899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39579.0,
        MAXELEVSMO: 39937.0,
        MINELEVSMO: 39764.0,
        SLOPE: 0.00285478,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60600000619888295,
        QA_MA: 63.889,
        VA_MA: 1.15991,
        QC_MA: 65.846,
        VC_MA: 1.16199,
        QE_MA: 65.846,
        VE_MA: 1.16199,
        QA_01: 88.108,
        VA_01: 1.31838,
        QC_01: 71.053,
        VC_01: 1.19719,
        QE_01: 71.053,
        VE_01: 1.19719,
        QA_02: 96.142,
        VA_02: 1.36628,
        QC_02: 82.707,
        VC_02: 1.27184,
        QE_02: 82.707,
        VE_02: 1.27184,
        QA_03: 116.291,
        VA_03: 1.47874,
        QC_03: 101.209,
        VC_03: 1.38086,
        QE_03: 101.209,
        VE_03: 1.38086,
        QA_04: 96.741,
        VA_04: 1.36977,
        QC_04: 91.246,
        VC_04: 1.32344,
        QE_04: 91.246,
        VE_04: 1.32344,
        QA_05: 73.362,
        VA_05: 1.22477,
        QC_05: 70.945,
        VC_05: 1.19648,
        QE_05: 70.945,
        VE_05: 1.19648,
        QA_06: 47.63,
        VA_06: 1.03695,
        QC_06: 60.276,
        VC_06: 1.12286,
        QE_06: 60.276,
        VE_06: 1.12286,
        QA_07: 28.815,
        VA_07: 0.8663,
        QC_07: 44.352,
        VC_07: 1.00042,
        QE_07: 44.352,
        VE_07: 1.00042,
        QA_08: 20.616,
        VA_08: 0.77543,
        QC_08: 45.437,
        VC_08: 1.00936,
        QE_08: 45.437,
        VE_08: 1.00936,
        QA_09: 25.148,
        VA_09: 0.8274,
        QC_09: 67.431,
        VC_09: 1.17284,
        QE_09: 67.431,
        VE_09: 1.17284,
        QA_10: 36.242,
        VA_10: 0.93855,
        QC_10: 96.81,
        VC_10: 1.35585,
        QE_10: 96.81,
        VE_10: 1.35585,
        QA_11: 66.699,
        VA_11: 1.17959,
        QC_11: 94.093,
        VC_11: 1.34014,
        QE_11: 94.093,
        VE_11: 1.34014,
        QA_12: 71.401,
        VA_12: 1.21168,
        QC_12: 64.234,
        VC_12: 1.15083,
        QE_12: 64.234,
        VE_12: 1.15083,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.709883783609939, 36.035397810732547, 0.0],
          [-81.705791316949615, 36.031187210739063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286849.0,
        COMID: 933050107,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.033,
        REACHCODE: "03050103000138",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00029271600725000002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250095021.0,
        ToNode: 250095020.0,
        Hydroseq: 250018234.0,
        LevelPathI: 250005808.0,
        Pathlength: 400.856,
        TerminalPa: 250002604.0,
        ArbolateSu: 159.817,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250018603.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005828.0,
        FromMeas: 46.04568,
        ToMeas: 47.14316,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0009,
        TotDASqKM: 136.0269,
        DivDASqKM: 136.0269,
        Tidal: 0,
        TOTMA: 0.0018698498930400001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13629.0,
        MAXELEVSMO: 13629.0,
        MINELEVSMO: 13629.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.032999999821186003,
        QA_MA: 54.868,
        VA_MA: 0.66911,
        QC_MA: 56.884,
        VC_MA: 0.67016,
        QE_MA: 56.884,
        VE_MA: 0.67016,
        QA_01: 120.054,
        VA_01: 0.85507,
        QC_01: 96.034,
        VC_01: 0.78614,
        QE_01: 96.034,
        VE_01: 0.78614,
        QA_02: 124.667,
        VA_02: 0.86613,
        QC_02: 106.488,
        VC_02: 0.81309,
        QE_02: 106.488,
        VE_02: 0.81309,
        QA_03: 133.14,
        VA_03: 0.88595,
        QC_03: 115.357,
        VC_03: 0.83499,
        QE_03: 115.357,
        VE_03: 0.83499,
        QA_04: 76.793,
        VA_04: 0.7396,
        QC_04: 72.813,
        VC_04: 0.72084,
        QE_04: 72.813,
        VE_04: 0.72084,
        QA_05: 42.796,
        VA_05: 0.62452,
        QC_05: 42.229,
        VC_05: 0.61723,
        QE_05: 42.229,
        VE_05: 0.61723,
        QA_06: 27.434,
        VA_06: 0.55803,
        QC_06: 34.981,
        VC_06: 0.58784,
        QE_06: 34.981,
        VE_06: 0.58784,
        QA_07: 20.118,
        VA_07: 0.52012,
        QC_07: 31.233,
        VC_07: 0.57152,
        QE_07: 31.233,
        VE_07: 0.57152,
        QA_08: 14.631,
        VA_08: 0.48718,
        QC_08: 32.026,
        VC_08: 0.57505,
        QE_08: 32.026,
        VE_08: 0.57505,
        QA_09: 11.856,
        VA_09: 0.46829,
        QC_09: 34.717,
        VC_09: 0.58672,
        QE_09: 34.717,
        VE_09: 0.58672,
        QA_10: 9.145,
        VA_10: 0.4477,
        QC_10: 32.224,
        VC_10: 0.57592,
        QE_10: 32.224,
        VE_10: 0.57592,
        QA_11: 12.587,
        VA_11: 0.47345,
        QC_11: 26.38,
        VC_11: 0.54895,
        QE_11: 26.38,
        VE_11: 0.54895,
        QA_12: 65.584,
        VA_12: 0.70495,
        QC_12: 59.454,
        VC_12: 0.67875,
        QE_12: 59.454,
        VE_12: 0.67875,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874101584907123, 34.797148812654541, 0.0],
          [-80.874143518240487, 34.796859812654986, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288916.0,
        COMID: 9717507,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 1.374,
        REACHCODE: "03050104000135",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013592780142762001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057920.0,
        ToNode: 250057182.0,
        Hydroseq: 250025557.0,
        LevelPathI: 250021319.0,
        Pathlength: 353.578,
        TerminalPa: 250002604.0,
        ArbolateSu: 86.932,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250026296.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024885.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4166,
        TotDASqKM: 91.8981,
        DivDASqKM: 91.8981,
        Tidal: 0,
        TOTMA: 0.048079090722300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7290.0,
        MAXELEVSMO: 7722.0,
        MINELEVSMO: 7418.0,
        SLOPE: 0.00221251,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3739999532699581,
        QA_MA: 34.555,
        VA_MA: 1.08179,
        QC_MA: 36.473,
        VC_MA: 1.08518,
        QE_MA: 36.473,
        VE_MA: 1.08518,
        QA_01: 76.789,
        VA_01: 1.4902,
        QC_01: 62.149,
        VC_01: 1.33928,
        QE_01: 62.149,
        VE_01: 1.33928,
        QA_02: 79.641,
        VA_02: 1.51331,
        QC_02: 68.864,
        VC_02: 1.397,
        QE_02: 68.864,
        VE_02: 1.397,
        QA_03: 85.368,
        VA_03: 1.55857,
        QC_03: 75.059,
        VC_03: 1.44795,
        QE_03: 75.059,
        VE_03: 1.44795,
        QA_04: 49.374,
        VA_04: 1.24316,
        QC_04: 47.287,
        VC_04: 1.20002,
        QE_04: 47.287,
        VE_04: 1.20002,
        QA_05: 27.467,
        VA_05: 0.99303,
        QC_05: 27.557,
        VC_05: 0.97775,
        QE_05: 27.557,
        VE_05: 0.97775,
        QA_06: 17.57,
        VA_06: 0.84843,
        QC_06: 22.542,
        VC_06: 0.91003,
        QE_06: 22.542,
        VE_06: 0.91003,
        QA_07: 13.505,
        VA_07: 0.77801,
        QC_07: 21.167,
        VC_07: 0.89025,
        QE_07: 21.167,
        VE_07: 0.89025,
        QA_08: 9.885,
        VA_08: 0.7063,
        QC_08: 21.467,
        VC_08: 0.89462,
        QE_08: 21.467,
        VE_08: 0.89462,
        QA_09: 7.476,
        VA_09: 0.65145,
        QC_09: 23.107,
        VC_09: 0.91799,
        QE_09: 23.107,
        VE_09: 0.91799,
        QA_10: 6.178,
        VA_10: 0.61841,
        QC_10: 23.557,
        VC_10: 0.92426,
        QE_10: 23.557,
        VE_10: 0.92426,
        QA_11: 5.383,
        VA_11: 0.59655,
        QC_11: 13.802,
        VC_11: 0.77206,
        QE_11: 13.802,
        VE_11: 0.77206,
        QA_12: 36.147,
        VA_12: 1.10051,
        QC_12: 34.577,
        VC_12: 1.06347,
        QE_12: 34.577,
        VE_12: 1.06347,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.748707518435083, 34.47461401315519, 0.0],
          [-80.752242385096338, 34.47459081315526, 0.0],
          [-80.758476318420037, 34.467266613166714, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283799.0,
        COMID: 9751378,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.556,
        REACHCODE: "03050101000217",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0051845638154019999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060760.0,
        ToNode: 250060772.0,
        Hydroseq: 250023652.0,
        LevelPathI: 250015617.0,
        Pathlength: 673.089,
        TerminalPa: 250002604.0,
        ArbolateSu: 127.905,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250024236.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023111.0,
        FromMeas: 0.0,
        ToMeas: 84.20653,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3321,
        TotDASqKM: 120.7386,
        DivDASqKM: 120.7386,
        Tidal: 0,
        TOTMA: 0.0313427840229,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36224.0,
        MAXELEVSMO: 37478.0,
        MINELEVSMO: 37478.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.55599999427795399,
        QA_MA: 77.83,
        VA_MA: 0.67294,
        QC_MA: 79.603,
        VC_MA: 0.67361,
        QE_MA: 79.603,
        VE_MA: 0.67361,
        QA_01: 110.639,
        VA_01: 0.74781,
        QC_01: 88.692,
        VC_01: 0.69519,
        QE_01: 88.692,
        VE_01: 0.69519,
        QA_02: 120.206,
        VA_02: 0.76759,
        QC_02: 102.781,
        VC_02: 0.72669,
        QE_02: 102.781,
        VE_02: 0.72669,
        QA_03: 144.115,
        VA_03: 0.81402,
        QC_03: 124.539,
        VC_03: 0.77159,
        QE_03: 124.539,
        VE_03: 0.77159,
        QA_04: 119.224,
        VA_04: 0.7656,
        QC_04: 111.919,
        VC_04: 0.74604,
        QE_04: 111.919,
        VE_04: 0.74604,
        QA_05: 90.127,
        VA_05: 0.70248,
        QC_05: 86.49,
        VC_05: 0.69006,
        QE_05: 86.49,
        VE_05: 0.69006,
        QA_06: 56.898,
        VA_06: 0.61708,
        QC_06: 71.828,
        VC_06: 0.65421,
        QE_06: 71.828,
        VE_06: 0.65421,
        QA_07: 34.346,
        VA_07: 0.5445,
        QC_07: 52.644,
        VC_07: 0.60161,
        QE_07: 52.644,
        VE_07: 0.60161,
        QA_08: 24.15,
        VA_08: 0.50423,
        QC_08: 53.395,
        VC_08: 0.60383,
        QE_08: 53.395,
        VE_08: 0.60383,
        QA_09: 27.843,
        VA_09: 0.5196,
        QC_09: 73.773,
        VC_09: 0.65915,
        QE_09: 73.773,
        VE_09: 0.65915,
        QA_10: 40.081,
        VA_10: 0.56467,
        QC_10: 104.918,
        VC_10: 0.73128,
        QE_10: 104.918,
        VE_10: 0.73128,
        QA_11: 79.26,
        VA_11: 0.67649,
        QC_11: 107.326,
        VC_11: 0.73641,
        QE_11: 107.326,
        VE_11: 0.73641,
        QA_12: 87.594,
        VA_12: 0.69655,
        QC_12: 77.362,
        VC_12: 0.66811,
        QE_12: 77.362,
        VE_12: 0.66811,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.684512983649313, 36.000146410787352, 0.0],
          [-81.68572411698068, 35.998291810790192, 0.0],
          [-81.684653183649061, 35.99561301079433, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286850.0,
        COMID: 9734212,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 2.24,
        REACHCODE: "03050103000139",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.021980387919493,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059308.0,
        ToNode: 250059329.0,
        Hydroseq: 250018888.0,
        LevelPathI: 250005808.0,
        Pathlength: 402.449,
        TerminalPa: 250002604.0,
        ArbolateSu: 149.54,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250019190.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018603.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8144,
        TotDASqKM: 126.5373,
        DivDASqKM: 126.5373,
        Tidal: 0,
        TOTMA: 0.080601549490099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13770.0,
        MAXELEVSMO: 14009.0,
        MINELEVSMO: 13772.0,
        SLOPE: 0.00105803,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2400000095367432,
        QA_MA: 51.117,
        VA_MA: 1.05295,
        QC_MA: 53.141,
        VC_MA: 1.0553,
        QE_MA: 53.141,
        VE_MA: 1.0553,
        QA_01: 111.802,
        VA_01: 1.43657,
        QC_01: 89.6,
        VC_01: 1.29408,
        QE_01: 89.6,
        VE_01: 1.29408,
        QA_02: 116.063,
        VA_02: 1.4592,
        QC_02: 99.333,
        VC_02: 1.34955,
        QE_02: 99.333,
        VE_02: 1.34955,
        QA_03: 123.944,
        VA_03: 1.50005,
        QC_03: 107.643,
        VC_03: 1.39493,
        QE_03: 107.643,
        VE_03: 1.39493,
        QA_04: 71.457,
        VA_04: 1.1979,
        QC_04: 67.864,
        VC_04: 1.15887,
        QE_04: 67.864,
        VE_04: 1.15887,
        QA_05: 39.851,
        VA_05: 0.96074,
        QC_05: 39.428,
        VC_05: 0.94582,
        QE_05: 39.428,
        VE_05: 0.94582,
        QA_06: 25.52,
        VA_06: 0.82327,
        QC_06: 32.573,
        VC_06: 0.88434,
        QE_06: 32.573,
        VE_06: 0.88434,
        QA_07: 18.715,
        VA_07: 0.74509,
        QC_07: 29.105,
        VC_07: 0.85092,
        QE_07: 29.105,
        VE_07: 0.85092,
        QA_08: 13.611,
        VA_08: 0.67715,
        QC_08: 29.749,
        VC_08: 0.85727,
        QE_08: 29.749,
        VE_08: 0.85727,
        QA_09: 11.091,
        VA_09: 0.63916,
        QC_09: 32.731,
        VC_09: 0.88583,
        QE_09: 32.731,
        VE_09: 0.88583,
        QA_10: 8.507,
        VA_10: 0.59571,
        QC_10: 30.415,
        VC_10: 0.86376,
        QE_10: 30.415,
        VE_10: 0.86376,
        QA_11: 11.95,
        VA_11: 0.65253,
        QC_11: 25.354,
        VC_11: 0.8126,
        QE_11: 25.354,
        VE_11: 0.8126,
        QA_12: 61.241,
        VA_12: 1.12793,
        QC_12: 55.861,
        VC_12: 1.07536,
        QE_12: 55.861,
        VE_12: 1.07536,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.857492984932946, 34.814922879293647, 0.0],
          [-80.858048784932137, 34.813319612629527, 0.0],
          [-80.860270584928685, 34.813754879295459, 0.0],
          [-80.859215384930337, 34.811693412631939, 0.0],
          [-80.863576384923476, 34.806677412639658, 0.0],
          [-80.862826784924607, 34.80537167930845, 0.0],
          [-80.865409518254012, 34.806059012640731, 0.0],
          [-80.866270584919278, 34.803402012644881, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288917.0,
        COMID: 9717503,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 1.246,
        REACHCODE: "03050104000136",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012664334908029999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057919.0,
        ToNode: 250057920.0,
        Hydroseq: 250026296.0,
        LevelPathI: 250021319.0,
        Pathlength: 354.952,
        TerminalPa: 250002604.0,
        ArbolateSu: 74.974,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250027104.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025557.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6578,
        TotDASqKM: 76.8177,
        DivDASqKM: 76.8177,
        Tidal: 0,
        TOTMA: 0.044387083415799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7580.0,
        MAXELEVSMO: 8025.0,
        MINELEVSMO: 7722.0,
        SLOPE: 0.00243178,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2460000514984131,
        QA_MA: 29.073,
        VA_MA: 1.06222,
        QC_MA: 30.893,
        VC_MA: 1.06594,
        QE_MA: 30.893,
        VE_MA: 1.06594,
        QA_01: 64.658,
        VA_01: 1.46078,
        QC_01: 52.567,
        VC_01: 1.31299,
        QE_01: 52.567,
        VE_01: 1.31299,
        QA_02: 66.834,
        VA_02: 1.48121,
        QC_02: 58.067,
        VC_02: 1.3675,
        QE_02: 58.067,
        VE_02: 1.3675,
        QA_03: 71.553,
        VA_03: 1.52448,
        QC_03: 63.279,
        VC_03: 1.41696,
        QE_03: 63.279,
        VE_03: 1.41696,
        QA_04: 41.639,
        VA_04: 1.22064,
        QC_04: 40.034,
        VC_04: 1.17772,
        QE_04: 40.034,
        VE_04: 1.17772,
        QA_05: 23.166,
        VA_05: 0.97657,
        QC_05: 23.39,
        VC_05: 0.96189,
        QE_05: 23.39,
        VE_05: 0.96189,
        QA_06: 14.732,
        VA_06: 0.83381,
        QC_06: 18.946,
        VC_06: 0.89271,
        QE_06: 18.946,
        VE_06: 0.89271,
        QA_07: 11.36,
        VA_07: 0.76607,
        QC_07: 17.879,
        VC_07: 0.875,
        QE_07: 17.879,
        VE_07: 0.875,
        QA_08: 8.263,
        VA_08: 0.6949,
        QC_08: 17.88,
        VC_08: 0.87502,
        QE_08: 17.88,
        VE_08: 0.87502,
        QA_09: 6.26,
        VA_09: 0.64192,
        QC_09: 19.753,
        VC_09: 0.9058,
        QE_09: 19.753,
        VE_09: 0.9058,
        QA_10: 5.164,
        VA_10: 0.60952,
        QC_10: 20.415,
        VC_10: 0.91635,
        QE_10: 20.415,
        VE_10: 0.91635,
        QA_11: 4.572,
        VA_11: 0.59067,
        QC_11: 12.186,
        VC_11: 0.77065,
        QE_11: 12.186,
        VE_11: 0.77065,
        QA_12: 30.792,
        VA_12: 1.08557,
        QC_12: 29.883,
        VC_12: 1.05268,
        QE_12: 29.883,
        VE_12: 1.05268,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.738372385117827, 34.48167947981085, 0.0],
          [-80.748707518435083, 34.47461401315519, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283800.0,
        COMID: 9750534,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.986,
        REACHCODE: "03050101000218",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010184756697391001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060563.0,
        ToNode: 250060568.0,
        Hydroseq: 250024860.0,
        LevelPathI: 250015617.0,
        Pathlength: 673.749,
        TerminalPa: 250002604.0,
        ArbolateSu: 124.745,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250025537.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024236.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7407,
        TotDASqKM: 117.3717,
        DivDASqKM: 117.3717,
        Tidal: 0,
        TOTMA: 0.032316899985299997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36942.0,
        MAXELEVSMO: 37939.0,
        MINELEVSMO: 37731.0,
        SLOPE: 0.00210953,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.98600000143051103,
        QA_MA: 76.08,
        VA_MA: 1.15695,
        QC_MA: 77.881,
        VC_MA: 1.15856,
        QE_MA: 77.881,
        VE_MA: 1.15856,
        QA_01: 107.726,
        VA_01: 1.32907,
        QC_01: 86.418,
        VC_01: 1.20684,
        QE_01: 86.418,
        VE_01: 1.20684,
        QA_02: 117.095,
        VA_02: 1.37528,
        QC_02: 100.192,
        VC_02: 1.28024,
        QE_02: 100.192,
        VE_02: 1.28024,
        QA_03: 140.512,
        VA_03: 1.48373,
        QC_03: 121.528,
        VC_03: 1.38515,
        QE_03: 121.528,
        VE_03: 1.38515,
        QA_04: 116.329,
        VA_04: 1.37157,
        QC_04: 109.263,
        VC_04: 1.32601,
        QE_04: 109.263,
        VE_04: 1.32601,
        QA_05: 88.004,
        VA_05: 1.22516,
        QC_05: 84.528,
        VC_05: 1.19635,
        QE_05: 84.528,
        VE_05: 1.19635,
        QA_06: 55.713,
        VA_06: 1.02756,
        QC_06: 70.353,
        VC_06: 1.11388,
        QE_06: 70.353,
        VE_06: 1.11388,
        QA_07: 33.633,
        VA_07: 0.85849,
        QC_07: 51.577,
        VC_07: 0.99146,
        QE_07: 51.577,
        VE_07: 0.99146,
        QA_08: 23.691,
        VA_08: 0.76509,
        QC_08: 52.36,
        VC_08: 0.99695,
        QE_08: 52.36,
        VE_08: 0.99695,
        QA_09: 27.525,
        VA_09: 0.80295,
        QC_09: 73.028,
        VC_09: 1.13,
        QE_09: 73.028,
        VE_09: 1.13,
        QA_10: 39.821,
        VA_10: 0.9101,
        QC_10: 104.372,
        VC_10: 1.30156,
        QE_10: 104.372,
        VE_10: 1.30156,
        QA_11: 77.863,
        VA_11: 1.16745,
        QC_11: 105.88,
        VC_11: 1.30915,
        QE_11: 105.88,
        VE_11: 1.30915,
        QA_12: 85.55,
        VA_12: 1.21149,
        QC_12: 75.717,
        VC_12: 1.14593,
        QE_12: 75.717,
        VE_12: 1.14593,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.692462183636962, 36.005845477445007, 0.0],
          [-81.684285316982994, 36.00104087745251, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288918.0,
        COMID: 9714615,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.598,
        REACHCODE: "03050104000137",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0064454597734920002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057158.0,
        ToNode: 250057919.0,
        Hydroseq: 250027104.0,
        LevelPathI: 250021319.0,
        Pathlength: 356.198,
        TerminalPa: 250002604.0,
        ArbolateSu: 67.519,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250027979.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026296.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.333,
        TotDASqKM: 68.5566,
        DivDASqKM: 68.5566,
        Tidal: 0,
        TOTMA: 0.0206658767387,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7975.0,
        MAXELEVSMO: 8248.0,
        MINELEVSMO: 8025.0,
        SLOPE: 0.00372909,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59799998998642001,
        QA_MA: 26.034,
        VA_MA: 1.09464,
        QC_MA: 27.783,
        VC_MA: 1.0988,
        QE_MA: 27.783,
        VE_MA: 1.0988,
        QA_01: 57.927,
        VA_01: 1.51065,
        QC_01: 47.23,
        VC_01: 1.35603,
        QE_01: 47.23,
        VE_01: 1.35603,
        QA_02: 59.759,
        VA_02: 1.53069,
        QC_02: 52.079,
        VC_02: 1.41183,
        QE_02: 52.079,
        VE_02: 1.41183,
        QA_03: 63.947,
        VA_03: 1.57546,
        QC_03: 56.763,
        VC_03: 1.46347,
        QE_03: 56.763,
        VE_03: 1.46347,
        QA_04: 37.342,
        VA_04: 1.26063,
        QC_04: 35.991,
        VC_04: 1.21525,
        QE_04: 35.991,
        VE_04: 1.21525,
        QA_05: 20.758,
        VA_05: 1.00556,
        QC_05: 21.045,
        VC_05: 0.9904,
        QE_05: 21.045,
        VE_05: 0.9904,
        QA_06: 13.177,
        VA_06: 0.85607,
        QC_06: 16.972,
        VC_06: 0.91679,
        QE_06: 16.972,
        VE_06: 0.91679,
        QA_07: 10.139,
        VA_07: 0.78492,
        QC_07: 16.002,
        VC_07: 0.89807,
        QE_07: 16.002,
        VE_07: 0.89807,
        QA_08: 7.374,
        VA_08: 0.71079,
        QC_08: 15.921,
        VC_08: 0.89648,
        QE_08: 15.921,
        VE_08: 0.89648,
        QA_09: 5.593,
        VA_09: 0.65585,
        QC_09: 17.884,
        VC_09: 0.93394,
        QE_09: 17.884,
        VE_09: 0.93394,
        QA_10: 4.609,
        VA_10: 0.62192,
        QC_10: 18.641,
        VC_10: 0.94786,
        QE_10: 18.641,
        VE_10: 0.94786,
        QA_11: 4.128,
        VA_11: 0.6041,
        QC_11: 11.272,
        VC_11: 0.79796,
        QE_11: 11.272,
        VE_11: 0.79796,
        QA_12: 27.78,
        VA_12: 1.12222,
        QC_12: 27.212,
        VC_12: 1.09013,
        QE_12: 27.212,
        VE_12: 1.09013,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.732176185127514, 34.481708013144271, 0.0],
          [-80.738372385117827, 34.48167947981085, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283801.0,
        COMID: 9750504,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.148,
        REACHCODE: "03050101000219",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0013301729133970001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060555.0,
        ToNode: 250060563.0,
        Hydroseq: 250025537.0,
        LevelPathI: 250015617.0,
        Pathlength: 674.735,
        TerminalPa: 250002604.0,
        ArbolateSu: 121.815,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250026279.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024860.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0189,
        TotDASqKM: 114.9831,
        DivDASqKM: 114.9831,
        Tidal: 0,
        TOTMA: 0.0083908766887699996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37563.0,
        MAXELEVSMO: 37939.0,
        MINELEVSMO: 37939.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 74.597,
        VA_MA: 0.66906,
        QC_MA: 76.421,
        VC_MA: 0.66977,
        QE_MA: 76.421,
        VE_MA: 0.66977,
        QA_01: 105.428,
        VA_01: 0.74179,
        QC_01: 84.622,
        VC_01: 0.68987,
        QE_01: 84.622,
        VE_01: 0.68987,
        QA_02: 114.716,
        VA_02: 0.76166,
        QC_02: 98.212,
        VC_02: 0.72126,
        QE_02: 98.212,
        VE_02: 0.72126,
        QA_03: 137.785,
        VA_03: 0.80798,
        QC_03: 119.246,
        VC_03: 0.76608,
        QE_03: 119.246,
        VE_03: 0.76608,
        QA_04: 114.108,
        VA_04: 0.76038,
        QC_04: 107.224,
        VC_04: 0.74097,
        QE_04: 107.224,
        VE_04: 0.74097,
        QA_05: 86.257,
        VA_05: 0.69798,
        QC_05: 82.911,
        VC_05: 0.68575,
        QE_05: 82.911,
        VE_05: 0.68575,
        QA_06: 54.752,
        VA_06: 0.61444,
        QC_06: 69.157,
        VC_06: 0.6511,
        QE_06: 69.157,
        VE_06: 0.6511,
        QA_07: 33.067,
        VA_07: 0.54254,
        QC_07: 50.73,
        VC_07: 0.59911,
        QE_07: 50.73,
        VE_07: 0.59911,
        QA_08: 23.327,
        VA_08: 0.50294,
        QC_08: 51.54,
        VC_08: 0.60156,
        QE_08: 51.54,
        VE_08: 0.60156,
        QA_09: 27.144,
        VA_09: 0.51924,
        QC_09: 72.137,
        VC_09: 0.65887,
        QE_09: 72.137,
        VE_09: 0.65887,
        QA_10: 39.05,
        VA_10: 0.56416,
        QC_10: 102.755,
        VC_10: 0.7313,
        QE_10: 102.755,
        VE_10: 0.7313,
        QA_11: 76.308,
        VA_11: 0.67343,
        QC_11: 104.264,
        VC_11: 0.73458,
        QE_11: 104.264,
        VE_11: 0.73458,
        QA_12: 83.727,
        VA_12: 0.69187,
        QC_12: 74.248,
        VC_12: 0.66428,
        QE_12: 74.248,
        VE_12: 0.66428,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.692266116970586, 36.007150810776409, 0.0],
          [-81.692462183636962, 36.005845477445007, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288911.0,
        COMID: 9715001,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 2.724,
        REACHCODE: "03050104000130",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.025730878376182001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057293.0,
        ToNode: 250058184.0,
        Hydroseq: 250022634.0,
        LevelPathI: 250021319.0,
        Pathlength: 348.581,
        TerminalPa: 250002604.0,
        ArbolateSu: 112.397,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250023134.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022158.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.1865,
        TotDASqKM: 117.2565,
        DivDASqKM: 117.2565,
        Tidal: 0,
        TOTMA: 0.11183167943400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6751.0,
        MAXELEVSMO: 6980.0,
        MINELEVSMO: 6880.0,
        SLOPE: 0.0003671,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.723999977111816,
        QA_MA: 43.724,
        VA_MA: 0.9227,
        QC_MA: 45.732,
        VC_MA: 0.92494,
        QE_MA: 45.732,
        VE_MA: 0.92494,
        QA_01: 97.2,
        VA_01: 1.24729,
        QC_01: 78.184,
        VC_01: 1.12806,
        QE_01: 78.184,
        VE_01: 1.12806,
        QA_02: 101.192,
        VA_02: 1.26757,
        QC_02: 86.93,
        VC_02: 1.17553,
        QE_02: 86.93,
        VE_02: 1.17553,
        QA_03: 108.553,
        VA_03: 1.30401,
        QC_03: 94.69,
        VC_03: 1.21581,
        QE_03: 94.69,
        VE_03: 1.21581,
        QA_04: 62.29,
        VA_04: 1.04971,
        QC_04: 59.343,
        VC_04: 1.01642,
        QE_04: 59.343,
        VE_04: 1.01642,
        QA_05: 34.637,
        VA_05: 0.85122,
        QC_05: 34.449,
        VC_05: 0.83882,
        QE_05: 34.449,
        VE_05: 0.83882,
        QA_06: 22.343,
        VA_06: 0.73848,
        QC_06: 28.571,
        VC_06: 0.78866,
        QE_06: 28.571,
        VE_06: 0.78866,
        QA_07: 16.965,
        VA_07: 0.67997,
        QC_07: 26.445,
        VC_07: 0.76934,
        QE_07: 26.445,
        VE_07: 0.76934,
        QA_08: 12.612,
        VA_08: 0.62584,
        QC_08: 27.525,
        VC_08: 0.77924,
        QE_08: 27.525,
        VE_08: 0.77924,
        QA_09: 9.522,
        VA_09: 0.58183,
        QC_09: 28.607,
        VC_09: 0.78898,
        QE_09: 28.607,
        VE_09: 0.78898,
        QA_10: 7.883,
        VA_10: 0.55574,
        QC_10: 28.62,
        VC_10: 0.7891,
        QE_10: 28.62,
        VE_10: 0.7891,
        QA_11: 6.747,
        VA_11: 0.53612,
        QC_11: 16.396,
        VC_11: 0.666,
        QE_11: 16.396,
        VE_11: 0.666,
        QA_12: 44.923,
        VA_12: 0.93159,
        QC_12: 42.137,
        VC_12: 0.89871,
        QE_12: 42.137,
        VE_12: 0.89871,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.765163185076233, 34.451191879858186, 0.0],
          [-80.76531518507602, 34.441021279874121, 0.0],
          [-80.770274318401675, 34.433214279886101, 0.0],
          [-80.77444618506189, 34.43530227988299, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283802.0,
        COMID: 9750488,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.637,
        REACHCODE: "03050101000220",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024742284255394001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060552.0,
        ToNode: 250060555.0,
        Hydroseq: 250026279.0,
        LevelPathI: 250015617.0,
        Pathlength: 674.883,
        TerminalPa: 250002604.0,
        ArbolateSu: 116.006,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250027076.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025537.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6558,
        TotDASqKM: 108.3933,
        DivDASqKM: 108.3933,
        Tidal: 0,
        TOTMA: 0.082526186803699997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37572.0,
        MAXELEVSMO: 38860.0,
        MINELEVSMO: 37939.0,
        SLOPE: 0.0034926,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.6370000839233398,
        QA_MA: 70.567,
        VA_MA: 1.21144,
        QC_MA: 72.449,
        VC_MA: 1.21336,
        QE_MA: 72.449,
        VE_MA: 1.21336,
        QA_01: 99.127,
        VA_01: 1.39002,
        QC_01: 79.693,
        VC_01: 1.26034,
        QE_01: 79.693,
        VE_01: 1.26034,
        QA_02: 108.117,
        VA_02: 1.44105,
        QC_02: 92.711,
        VC_02: 1.33998,
        QE_02: 92.711,
        VE_02: 1.33998,
        QA_03: 130.198,
        VA_03: 1.55853,
        QC_03: 112.89,
        VC_03: 1.45369,
        QE_03: 112.89,
        VE_03: 1.45369,
        QA_04: 107.95,
        VA_04: 1.44012,
        QC_04: 101.565,
        VC_04: 1.39117,
        QE_04: 101.565,
        VE_04: 1.39117,
        QA_05: 81.405,
        VA_05: 1.28261,
        QC_05: 78.418,
        VC_05: 1.25222,
        QE_05: 78.418,
        VE_05: 1.25222,
        QA_06: 52.111,
        VA_06: 1.07715,
        QC_06: 65.865,
        VC_06: 1.16872,
        QE_06: 65.865,
        VE_06: 1.16872,
        QA_07: 31.51,
        VA_07: 0.89693,
        QC_07: 48.397,
        VC_07: 1.03885,
        QE_07: 48.397,
        VE_07: 1.03885,
        QA_08: 22.326,
        VA_08: 0.79853,
        QC_08: 49.285,
        VC_08: 1.04594,
        QE_08: 49.285,
        VE_08: 1.04594,
        QA_09: 26.378,
        VA_09: 0.84392,
        QC_09: 70.335,
        VC_09: 1.19925,
        QE_09: 70.335,
        VE_09: 1.19925,
        QA_10: 37.335,
        VA_10: 0.9524,
        QC_10: 99.135,
        VC_10: 1.37733,
        QE_10: 99.135,
        VE_10: 1.37733,
        QA_11: 72.016,
        VA_11: 1.22124,
        QC_11: 99.761,
        VC_11: 1.38091,
        QE_11: 99.761,
        VE_11: 1.38091,
        QA_12: 78.848,
        VA_12: 1.26624,
        QC_12: 70.301,
        VC_12: 1.19902,
        QE_12: 70.301,
        VE_12: 1.19902,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.686533316979421, 36.028061477410574, 0.0],
          [-81.689885116974153, 36.024578877416047, 0.0],
          [-81.688276116976738, 36.021900477420218, 0.0],
          [-81.692266116970586, 36.007150810776409, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288912.0,
        COMID: 9714811,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.879,
        REACHCODE: "03050104000131",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0082402800403090001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057221.0,
        ToNode: 250057293.0,
        Hydroseq: 250023134.0,
        LevelPathI: 250021319.0,
        Pathlength: 351.305,
        TerminalPa: 250002604.0,
        ArbolateSu: 106.958,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250023676.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022634.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7398,
        TotDASqKM: 107.4618,
        DivDASqKM: 107.4618,
        Tidal: 0,
        TOTMA: 0.034892681907199997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6931.0,
        MAXELEVSMO: 7030.0,
        MINELEVSMO: 6980.0,
        SLOPE: 0.00056882,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.87900000810623202,
        QA_MA: 40.176,
        VA_MA: 0.95407,
        QC_MA: 42.159,
        VC_MA: 0.95659,
        QE_MA: 42.159,
        VE_MA: 0.95659,
        QA_01: 89.262,
        VA_01: 1.29493,
        QC_01: 71.959,
        VC_01: 1.1694,
        QE_01: 71.959,
        VE_01: 1.1694,
        QA_02: 92.849,
        VA_02: 1.31579,
        QC_02: 79.95,
        VC_02: 1.21892,
        QE_02: 79.95,
        VE_02: 1.21892,
        QA_03: 99.598,
        VA_03: 1.35403,
        QC_03: 87.125,
        VC_03: 1.26143,
        QE_03: 87.125,
        VE_03: 1.26143,
        QA_04: 57.292,
        VA_04: 1.08797,
        QC_04: 54.685,
        VC_04: 1.05261,
        QE_04: 54.685,
        VE_04: 1.05261,
        QA_05: 31.87,
        VA_05: 0.87935,
        QC_05: 31.796,
        VC_05: 0.86641,
        QE_05: 31.796,
        VE_05: 0.86641,
        QA_06: 20.5,
        VA_06: 0.76009,
        QC_06: 26.245,
        VC_06: 0.81236,
        QE_06: 26.245,
        VE_06: 0.81236,
        QA_07: 15.623,
        VA_07: 0.69941,
        QC_07: 24.402,
        VC_07: 0.79325,
        QE_07: 24.402,
        VE_07: 0.79325,
        QA_08: 11.559,
        VA_08: 0.6416,
        QC_08: 25.182,
        VC_08: 0.80142,
        QE_08: 25.182,
        VE_08: 0.80142,
        QA_09: 8.732,
        VA_09: 0.59549,
        QC_09: 26.501,
        VC_09: 0.81497,
        QE_09: 26.501,
        VE_09: 0.81497,
        QA_10: 7.224,
        VA_10: 0.568,
        QC_10: 26.693,
        VC_10: 0.81691,
        QE_10: 26.693,
        VE_10: 0.81691,
        QA_11: 6.22,
        VA_11: 0.54817,
        QC_11: 15.41,
        VC_11: 0.68824,
        QE_11: 15.41,
        VE_11: 0.68824,
        QA_12: 41.512,
        VA_12: 0.96538,
        QC_12: 39.217,
        VC_12: 0.93217,
        QE_12: 39.217,
        VE_12: 0.93217,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.767421585072668, 34.458088879847594, 0.0],
          [-80.767234785073015, 34.45293481318879, 0.0],
          [-80.765163185076233, 34.451191879858186, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288913.0,
        COMID: 9714737,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.696,
        REACHCODE: "03050104000132",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0068343332613279998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057195.0,
        ToNode: 250057221.0,
        Hydroseq: 250023676.0,
        LevelPathI: 250021319.0,
        Pathlength: 352.184,
        TerminalPa: 250002604.0,
        ArbolateSu: 104.837,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250024261.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023134.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2114,
        TotDASqKM: 106.1091,
        DivDASqKM: 106.1091,
        Tidal: 0,
        TOTMA: 0.0223229117425,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6991.0,
        MAXELEVSMO: 7297.0,
        MINELEVSMO: 7030.0,
        SLOPE: 0.0038362,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.69599997997283902,
        QA_MA: 39.687,
        VA_MA: 1.18049,
        QC_MA: 41.665,
        VC_MA: 1.18394,
        QE_MA: 41.665,
        VE_MA: 1.18394,
        QA_01: 88.171,
        VA_01: 1.64085,
        QC_01: 71.103,
        VC_01: 1.47126,
        QE_01: 71.103,
        VE_01: 1.47126,
        QA_02: 91.703,
        VA_02: 1.66893,
        QC_02: 78.99,
        VC_02: 1.53806,
        QE_02: 78.99,
        VE_02: 1.53806,
        QA_03: 98.361,
        VA_03: 1.72052,
        QC_03: 86.079,
        VC_03: 1.59548,
        QE_03: 86.079,
        VE_03: 1.59548,
        QA_04: 56.601,
        VA_04: 1.36141,
        QC_04: 54.04,
        VC_04: 1.31359,
        QE_04: 54.04,
        VE_04: 1.31359,
        QA_05: 31.488,
        VA_05: 1.07965,
        QC_05: 31.429,
        VC_05: 1.0622,
        QE_05: 31.429,
        VE_05: 1.0622,
        QA_06: 20.245,
        VA_06: 0.9184,
        QC_06: 25.923,
        VC_06: 0.98892,
        QE_06: 25.923,
        VE_06: 0.98892,
        QA_07: 15.441,
        VA_07: 0.83668,
        QC_07: 24.125,
        VC_07: 0.96343,
        QE_07: 24.125,
        VE_07: 0.96343,
        QA_08: 11.413,
        VA_08: 0.75834,
        QC_08: 24.859,
        VC_08: 0.97394,
        QE_08: 24.859,
        VE_08: 0.97394,
        QA_09: 8.623,
        VA_09: 0.69611,
        QC_09: 26.208,
        VC_09: 0.99288,
        QE_09: 26.208,
        VE_09: 0.99288,
        QA_10: 7.133,
        VA_10: 0.65896,
        QC_10: 26.425,
        VC_10: 0.99588,
        QE_10: 26.425,
        VE_10: 0.99588,
        QA_11: 6.148,
        VA_11: 0.63236,
        QC_11: 15.273,
        VC_11: 0.82225,
        QE_11: 15.273,
        VE_11: 0.82225,
        QA_12: 41.043,
        VA_12: 1.19619,
        QC_12: 38.813,
        VC_12: 1.15158,
        QE_12: 38.813,
        VE_12: 1.15158,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.762907585079745, 34.462712079840401, 0.0],
          [-80.767421585072668, 34.458088879847594, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288914.0,
        COMID: 9714715,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220425",
        GNIS_NAME: "Beaver Creek",
        LENGTHKM: 0.471,
        REACHCODE: "03050104000133",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0044664422412949998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057187.0,
        ToNode: 250057195.0,
        Hydroseq: 250024261.0,
        LevelPathI: 250021319.0,
        Pathlength: 352.88,
        TerminalPa: 250002604.0,
        ArbolateSu: 101.935,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250024885.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023676.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2988,
        TotDASqKM: 103.2489,
        DivDASqKM: 103.2489,
        Tidal: 0,
        TOTMA: 0.021330169934100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7287.0,
        MAXELEVSMO: 7305.0,
        MINELEVSMO: 7297.0,
        SLOPE: 0.00016985,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.470999985933304,
        QA_MA: 38.653,
        VA_MA: 0.83636,
        QC_MA: 40.621,
        VC_MA: 0.83849,
        QE_MA: 40.621,
        VE_MA: 0.83849,
        QA_01: 85.87,
        VA_01: 1.11505,
        QC_01: 69.295,
        VC_01: 1.01232,
        QE_01: 69.295,
        VE_01: 1.01232,
        QA_02: 89.279,
        VA_02: 1.1319,
        QC_02: 76.958,
        VC_02: 1.05263,
        QE_02: 76.958,
        VE_02: 1.05263,
        QA_03: 95.746,
        VA_03: 1.16305,
        QC_03: 83.865,
        VC_03: 1.08739,
        QE_03: 83.865,
        VE_03: 1.08739,
        QA_04: 55.14,
        VA_04: 0.94597,
        QC_04: 52.676,
        VC_04: 0.91693,
        QE_04: 52.676,
        VE_04: 0.91693,
        QA_05: 30.68,
        VA_05: 0.77541,
        QC_05: 30.653,
        VC_05: 0.76488,
        QE_05: 30.653,
        VE_05: 0.76488,
        QA_06: 19.707,
        VA_06: 0.67759,
        QC_06: 25.243,
        VC_06: 0.72015,
        QE_06: 25.243,
        VE_06: 0.72015,
        QA_07: 15.055,
        VA_07: 0.6284,
        QC_07: 23.535,
        VC_07: 0.70512,
        QE_07: 23.535,
        VE_07: 0.70512,
        QA_08: 11.106,
        VA_08: 0.58067,
        QC_08: 24.175,
        VC_08: 0.71081,
        QE_08: 24.175,
        VE_08: 0.71081,
        QA_09: 8.392,
        VA_09: 0.54302,
        QC_09: 25.588,
        VC_09: 0.72313,
        QE_09: 25.588,
        VE_09: 0.72313,
        QA_10: 6.941,
        VA_10: 0.52053,
        QC_10: 25.854,
        VC_10: 0.72542,
        QE_10: 25.854,
        VE_10: 0.72542,
        QA_11: 5.994,
        VA_11: 0.50463,
        QC_11: 14.98,
        VC_11: 0.6205,
        QE_11: 14.98,
        VE_11: 0.6205,
        QA_12: 40.05,
        VA_12: 0.84641,
        QC_12: 37.958,
        VC_12: 0.81974,
        QE_12: 37.958,
        VE_12: 0.81974,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.760662518416609, 34.46630647983477, 0.0],
          [-80.762907585079745, 34.462712079840401, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283807.0,
        COMID: 9750392,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.542,
        REACHCODE: "03050101000225",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0050043967795649997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060529.0,
        ToNode: 250060534.0,
        Hydroseq: 250031120.0,
        LevelPathI: 250015617.0,
        Pathlength: 680.081,
        TerminalPa: 250002604.0,
        ArbolateSu: 94.974,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250032419.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029956.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2556,
        TotDASqKM: 89.7777,
        DivDASqKM: 89.7777,
        Tidal: 0,
        TOTMA: 0.016632344076700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39937.0,
        MAXELEVSMO: 40224.0,
        MINELEVSMO: 39937.0,
        SLOPE: 0.0052952,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.54199999570846602,
        QA_MA: 60.56,
        VA_MA: 1.23499,
        QC_MA: 62.545,
        VC_MA: 1.23742,
        QE_MA: 62.545,
        VE_MA: 1.23742,
        QA_01: 82.902,
        VA_01: 1.40313,
        QC_01: 66.962,
        VC_01: 1.2717,
        QE_01: 66.962,
        VE_01: 1.2717,
        QA_02: 90.529,
        VA_02: 1.45551,
        QC_02: 78.006,
        VC_02: 1.35304,
        QE_02: 78.006,
        VE_02: 1.35304,
        QA_03: 109.724,
        VA_03: 1.57883,
        QC_03: 95.677,
        VC_03: 1.47267,
        QE_03: 95.677,
        VE_03: 1.47267,
        QA_04: 91.395,
        VA_04: 1.46132,
        QC_04: 86.315,
        VC_04: 1.41072,
        QE_04: 86.315,
        VE_04: 1.41072,
        QA_05: 69.441,
        VA_05: 1.30483,
        QC_05: 67.292,
        VC_05: 1.27422,
        QE_05: 67.292,
        VE_05: 1.27422,
        QA_06: 45.427,
        VA_06: 1.10379,
        QC_06: 57.525,
        VC_06: 1.19705,
        QE_06: 57.525,
        VE_06: 1.19705,
        QA_07: 27.497,
        VA_07: 0.91766,
        QC_07: 42.372,
        VC_07: 1.06388,
        QE_07: 42.372,
        VE_07: 1.06388,
        QA_08: 19.803,
        VA_08: 0.82021,
        QC_08: 43.611,
        VC_08: 1.07553,
        QE_08: 43.611,
        VE_08: 1.07553,
        QA_09: 24.532,
        VA_09: 0.88185,
        QC_09: 65.971,
        VC_09: 1.2641,
        QE_09: 65.971,
        VE_09: 1.2641,
        QA_10: 35.033,
        VA_10: 1.00129,
        QC_10: 94.222,
        VC_10: 1.46324,
        QE_10: 94.222,
        VE_10: 1.46324,
        QA_11: 63.368,
        VA_11: 1.25757,
        QC_11: 90.488,
        VC_11: 1.43871,
        QE_11: 90.488,
        VE_11: 1.43871,
        QA_12: 67.58,
        VA_12: 1.29056,
        QC_12: 61.098,
        VC_12: 1.22594,
        QE_12: 61.098,
        VE_12: 1.22594,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.70825338361243, 36.03993307739222, 0.0],
          [-81.709883783609939, 36.035397810732547, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283808.0,
        COMID: 9750378,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.373,
        REACHCODE: "03050101000226",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0036211867815239998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060525.0,
        ToNode: 250060529.0,
        Hydroseq: 250032419.0,
        LevelPathI: 250015617.0,
        Pathlength: 680.623,
        TerminalPa: 250002604.0,
        ArbolateSu: 92.102,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250033891.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031120.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1107,
        TotDASqKM: 87.7905,
        DivDASqKM: 87.7905,
        Tidal: 0,
        TOTMA: 0.0112388010571,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 40192.0,
        MAXELEVSMO: 40461.0,
        MINELEVSMO: 40224.0,
        SLOPE: 0.00635388,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37299999594688399,
        QA_MA: 59.514,
        VA_MA: 1.25773,
        QC_MA: 61.506,
        VC_MA: 1.26026,
        QE_MA: 61.506,
        VE_MA: 1.26026,
        QA_01: 81.22,
        VA_01: 1.42816,
        QC_01: 65.638,
        VC_01: 1.2937,
        QE_01: 65.638,
        VE_01: 1.2937,
        QA_02: 88.69,
        VA_02: 1.48172,
        QC_02: 76.464,
        VC_02: 1.3769,
        QE_02: 76.464,
        VE_02: 1.3769,
        QA_03: 107.556,
        VA_03: 1.60825,
        QC_03: 93.848,
        VC_03: 1.49969,
        QE_03: 93.848,
        VE_03: 1.49969,
        QA_04: 89.632,
        VA_04: 1.48832,
        QC_04: 84.687,
        VC_04: 1.43646,
        QE_04: 84.687,
        VE_04: 1.43646,
        QA_05: 68.186,
        VA_05: 1.32884,
        QC_05: 66.12,
        VC_05: 1.29754,
        QE_05: 66.12,
        VE_05: 1.29754,
        QA_06: 44.72,
        VA_06: 1.12406,
        QC_06: 56.642,
        VC_06: 1.21952,
        QE_06: 56.642,
        VE_06: 1.21952,
        QA_07: 27.08,
        VA_07: 0.93332,
        QC_07: 41.744,
        VC_07: 1.08321,
        QE_07: 41.744,
        VE_07: 1.08321,
        QA_08: 19.536,
        VA_08: 0.83384,
        QC_08: 43.01,
        VC_08: 1.0956,
        QE_08: 43.01,
        VE_08: 1.0956,
        QA_09: 24.338,
        VA_09: 0.89888,
        QC_09: 65.511,
        VC_09: 1.29269,
        QE_09: 65.511,
        VE_09: 1.29269,
        QA_10: 34.795,
        VA_10: 1.0223,
        QC_10: 93.71,
        VC_10: 1.49876,
        QE_10: 93.71,
        VE_10: 1.49876,
        QA_11: 62.477,
        VA_11: 1.28254,
        QC_11: 89.515,
        VC_11: 1.47016,
        QE_11: 89.515,
        VE_11: 1.47016,
        QA_12: 66.448,
        VA_12: 1.31494,
        QC_12: 60.167,
        VC_12: 1.2492,
        QE_12: 60.167,
        VE_12: 1.2492,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.706000716949291, 36.042682677387859, 0.0],
          [-81.70825338361243, 36.03993307739222, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283787.0,
        COMID: 9751746,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.711,
        REACHCODE: "03050101000206",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00753807343224,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060858.0,
        ToNode: 250060875.0,
        Hydroseq: 250019184.0,
        LevelPathI: 250015617.0,
        Pathlength: 659.386,
        TerminalPa: 250002604.0,
        ArbolateSu: 176.946,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250019508.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018882.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0602,
        TotDASqKM: 166.473,
        DivDASqKM: 166.473,
        Tidal: 0,
        TOTMA: 0.027863461986399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33285.0,
        MAXELEVSMO: 33442.0,
        MINELEVSMO: 33423.0,
        SLOPE: 0.00026722,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.71100002527236905,
        QA_MA: 103.822,
        VA_MA: 0.96835,
        QC_MA: 105.005,
        VC_MA: 0.96896,
        QE_MA: 105.005,
        VE_MA: 0.96896,
        QA_01: 152.848,
        VA_01: 1.11882,
        QC_01: 121.495,
        VC_01: 1.02216,
        QE_01: 121.495,
        VE_01: 1.02216,
        QA_02: 163.81,
        VA_02: 1.14918,
        QC_02: 138.887,
        VC_02: 1.07471,
        QE_02: 138.887,
        VE_02: 1.07471,
        QA_03: 194.388,
        VA_03: 1.22917,
        QC_03: 166.333,
        VC_03: 1.15172,
        QE_03: 166.333,
        VE_03: 1.15172,
        QA_04: 159.551,
        VA_04: 1.1375,
        QC_04: 148.786,
        VC_04: 1.10325,
        QE_04: 148.786,
        VE_04: 1.10325,
        QA_05: 121.174,
        VA_05: 1.0248,
        QC_05: 115.002,
        VC_05: 1.00165,
        QE_05: 115.002,
        VE_05: 1.00165,
        QA_06: 74.014,
        VA_06: 0.8598,
        QC_06: 93.099,
        VC_06: 0.92807,
        QE_06: 93.099,
        VE_06: 0.92807,
        QA_07: 44.586,
        VA_07: 0.72969,
        QC_07: 67.915,
        VC_07: 0.83251,
        QE_07: 67.915,
        VE_07: 0.83251,
        QA_08: 30.793,
        VA_08: 0.65452,
        QC_08: 68.413,
        VC_08: 0.83455,
        QE_08: 68.413,
        VE_08: 0.83455,
        QA_09: 33.23,
        VA_09: 0.66881,
        QC_09: 86.243,
        VC_09: 0.9034,
        QE_09: 86.243,
        VE_09: 0.9034,
        QA_10: 48.122,
        VA_10: 0.74712,
        QC_10: 121.417,
        VC_10: 1.02192,
        QE_10: 121.417,
        VE_10: 1.02192,
        QA_11: 103.675,
        VA_11: 0.96786,
        QC_11: 131.717,
        VC_11: 1.05345,
        QE_11: 131.717,
        VE_11: 1.05345,
        QA_12: 120.185,
        VA_12: 1.02169,
        QC_12: 103.161,
        VC_12: 0.96277,
        QE_12: 103.161,
        VE_12: 0.96277,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.690345783640225, 35.933046410891393, 0.0],
          [-81.683698116983862, 35.929958477562934, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283788.0,
        COMID: 9751714,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.164,
        REACHCODE: "03050101000207",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015343882370750001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060849.0,
        ToNode: 250060858.0,
        Hydroseq: 250019508.0,
        LevelPathI: 250015617.0,
        Pathlength: 660.097,
        TerminalPa: 250002604.0,
        ArbolateSu: 176.04,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250019850.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019184.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0909,
        TotDASqKM: 165.3822,
        DivDASqKM: 165.3822,
        Tidal: 0,
        TOTMA: 0.0056520307694400002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33411.0,
        MAXELEVSMO: 33456.0,
        MINELEVSMO: 33442.0,
        SLOPE: 0.00085365,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.164000004529953,
        QA_MA: 103.246,
        VA_MA: 1.10108,
        QC_MA: 104.445,
        VC_MA: 1.10182,
        QE_MA: 104.445,
        VE_MA: 1.10182,
        QA_01: 151.882,
        VA_01: 1.2814,
        QC_01: 120.748,
        VC_01: 1.16535,
        QE_01: 120.748,
        VE_01: 1.16535,
        QA_02: 162.801,
        VA_02: 1.31794,
        QC_02: 138.054,
        VC_02: 1.22851,
        QE_02: 138.054,
        VE_02: 1.22851,
        QA_03: 193.233,
        VA_03: 1.41411,
        QC_03: 165.377,
        VC_03: 1.32111,
        QE_03: 165.377,
        VE_03: 1.32111,
        QA_04: 158.643,
        VA_04: 1.30416,
        QC_04: 147.959,
        VC_04: 1.263,
        QE_04: 147.959,
        VE_04: 1.263,
        QA_05: 120.503,
        VA_05: 1.16888,
        QC_05: 114.39,
        VC_05: 1.14108,
        QE_05: 114.39,
        VE_05: 1.14108,
        QA_06: 73.637,
        VA_06: 0.97085,
        QC_06: 92.631,
        VC_06: 1.05283,
        QE_06: 92.631,
        VE_06: 1.05283,
        QA_07: 44.366,
        VA_07: 0.81459,
        QC_07: 67.588,
        VC_07: 0.9381,
        QE_07: 67.588,
        VE_07: 0.9381,
        QA_08: 30.635,
        VA_08: 0.72422,
        QC_08: 68.058,
        VC_08: 0.94042,
        QE_08: 68.058,
        VE_08: 0.94042,
        QA_09: 33.113,
        VA_09: 0.74176,
        QC_09: 85.974,
        VC_09: 1.02392,
        QE_09: 85.974,
        VE_09: 1.02392,
        QA_10: 48.034,
        VA_10: 0.83639,
        QC_10: 121.239,
        VC_10: 1.1672,
        QE_10: 121.239,
        VE_10: 1.1672,
        QA_11: 103.182,
        VA_11: 1.10082,
        QC_11: 131.239,
        VC_11: 1.20411,
        QE_11: 131.239,
        VE_11: 1.20411,
        QA_12: 119.443,
        VA_12: 1.16485,
        QC_12: 102.581,
        VC_12: 1.09427,
        QE_12: 102.581,
        VE_12: 1.09427,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.690797516972793, 35.934374410889347, 0.0],
          [-81.690345783640225, 35.933046410891393, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285854.0,
        COMID: 9753958,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.385,
        REACHCODE: "03050101002529",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.004161495167228,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061453.0,
        ToNode: 250061450.0,
        Hydroseq: 250010075.0,
        LevelPathI: 250003258.0,
        Pathlength: 577.15,
        TerminalPa: 250002604.0,
        ArbolateSu: 3063.794,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010127.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010020.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4167,
        TotDASqKM: 3395.385,
        DivDASqKM: 3395.385,
        Tidal: 0,
        TOTMA: 0.16418238694000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26526.0,
        MAXELEVSMO: 28329.0,
        MINELEVSMO: 26526.0,
        SLOPE: 0.04683116,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.384999990463257,
        QA_MA: 2054.392,
        VA_MA: -9998.0,
        QC_MA: 2054.392,
        VC_MA: -9998.0,
        QE_MA: 2249.436,
        VE_MA: -9998.0,
        QA_01: 3316.966,
        VA_01: -9998.0,
        QC_01: 3316.966,
        VC_01: -9998.0,
        QE_01: 3522.241,
        VE_01: -9998.0,
        QA_02: 3511.467,
        VA_02: -9998.0,
        QC_02: 3511.467,
        VC_02: -9998.0,
        QE_02: 3583.29,
        VE_02: -9998.0,
        QA_03: 3969.009,
        VA_03: -9998.0,
        QC_03: 3969.009,
        VC_03: -9998.0,
        QE_03: 4233.129,
        VE_03: -9998.0,
        QA_04: 3039.506,
        VA_04: -9998.0,
        QC_04: 3039.506,
        VC_04: -9998.0,
        QE_04: 3231.632,
        VE_04: -9998.0,
        QA_05: 2320.435,
        VA_05: -9998.0,
        QC_05: 2320.435,
        VC_05: -9998.0,
        QE_05: 2542.557,
        VE_05: -9998.0,
        QA_06: 1349.382,
        VA_06: -9998.0,
        QC_06: 1349.382,
        VC_06: -9998.0,
        QE_06: 1608.995,
        VE_06: -9998.0,
        QA_07: 799.476,
        VA_07: -9998.0,
        QC_07: 799.476,
        VC_07: -9998.0,
        QE_07: 946.37,
        VE_07: -9998.0,
        QA_08: 564.027,
        VA_08: -9998.0,
        QC_08: 564.027,
        VC_08: -9998.0,
        QE_08: 863.643,
        VE_08: -9998.0,
        QA_09: 553.843,
        VA_09: -9998.0,
        QC_09: 1033.918,
        VC_09: -9998.0,
        QE_09: 1070.424,
        VE_09: -9998.0,
        QA_10: 712.224,
        VA_10: -9998.0,
        QC_10: 1045.011,
        VC_10: -9998.0,
        QE_10: 1005.508,
        VE_10: -9998.0,
        QA_11: 1915.484,
        VA_11: -9998.0,
        QC_11: 1915.484,
        VC_11: -9998.0,
        QE_11: 1892.685,
        VE_11: -9998.0,
        QA_12: 2614.199,
        VA_12: -9998.0,
        QC_12: 2614.199,
        VC_12: -9998.0,
        QE_12: 2637.911,
        VE_12: -9998.0,
        LakeFract: 0.01680165697,
        SurfArea: 261937.6612,
        RAreaHLoad: 0.04749332689,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.196736317739692, 35.82123661106499, 0.0],
          [-81.192851184412461, 35.822147011063578, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283789.0,
        COMID: 9751708,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.157,
        REACHCODE: "03050101000208",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00141251472977,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060847.0,
        ToNode: 250060849.0,
        Hydroseq: 250019850.0,
        LevelPathI: 250015617.0,
        Pathlength: 660.261,
        TerminalPa: 250002604.0,
        ArbolateSu: 175.876,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250020195.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 250019507.0,
        DnDrainCou: 2,
        DnHydroseq: 250019508.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1467,
        TotDASqKM: 165.2913,
        DivDASqKM: 165.2913,
        Tidal: 0,
        TOTMA: 0.0038445046290099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33456.0,
        MAXELEVSMO: 33681.0,
        MINELEVSMO: 33456.0,
        SLOPE: 0.01433121,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15700000524520899,
        QA_MA: 103.198,
        VA_MA: 1.54955,
        QC_MA: 104.399,
        VC_MA: 1.55071,
        QE_MA: 104.399,
        VE_MA: 1.55071,
        QA_01: 151.803,
        VA_01: 1.83183,
        QC_01: 120.686,
        VC_01: 1.65012,
        QE_01: 120.686,
        VE_01: 1.65012,
        QA_02: 162.718,
        VA_02: 1.88905,
        QC_02: 137.986,
        VC_02: 1.74904,
        QE_02: 137.986,
        VE_02: 1.74904,
        QA_03: 193.138,
        VA_03: 2.03965,
        QC_03: 165.298,
        VC_03: 1.89403,
        QE_03: 165.298,
        VE_03: 1.89403,
        QA_04: 158.567,
        VA_04: 1.86751,
        QC_04: 147.89,
        VC_04: 1.80305,
        QE_04: 147.89,
        VE_04: 1.80305,
        QA_05: 120.448,
        VA_05: 1.65571,
        QC_05: 114.339,
        VC_05: 1.61218,
        QE_05: 114.339,
        VE_05: 1.61218,
        QA_06: 73.606,
        VA_06: 1.34568,
        QC_06: 92.593,
        VC_06: 1.47402,
        QE_06: 92.593,
        VE_06: 1.47402,
        QA_07: 44.348,
        VA_07: 1.101,
        QC_07: 67.56,
        VC_07: 1.29438,
        QE_07: 67.56,
        VE_07: 1.29438,
        QA_08: 30.623,
        VA_08: 0.9595,
        QC_08: 68.03,
        VC_08: 1.29801,
        QE_08: 68.03,
        VE_08: 1.29801,
        QA_09: 33.103,
        VA_09: 0.98699,
        QC_09: 85.952,
        VC_09: 1.42885,
        QE_09: 85.952,
        VE_09: 1.42885,
        QA_10: 48.027,
        VA_10: 1.13526,
        QC_10: 121.224,
        VC_10: 1.65329,
        QE_10: 121.224,
        VE_10: 1.65329,
        QA_11: 103.143,
        VA_11: 1.5492,
        QC_11: 131.201,
        VC_11: 1.71098,
        QE_11: 131.201,
        VE_11: 1.71098,
        QA_12: 119.382,
        VA_12: 1.64937,
        QC_12: 102.534,
        VC_12: 1.53888,
        QE_12: 102.534,
        VE_12: 1.53888,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.691080383639076, 35.935748277553841, 0.0],
          [-81.690797516972793, 35.934374410889347, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283790.0,
        COMID: 9751698,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.516,
        REACHCODE: "03050101000209",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015313844491682001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060843.0,
        ToNode: 250060847.0,
        Hydroseq: 250020195.0,
        LevelPathI: 250015617.0,
        Pathlength: 660.418,
        TerminalPa: 250002604.0,
        ArbolateSu: 172.917,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250020552.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019850.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9287,
        TotDASqKM: 162.9288,
        DivDASqKM: 162.9288,
        Tidal: 0,
        TOTMA: 0.0477323051433,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33504.0,
        MAXELEVSMO: 33968.0,
        MINELEVSMO: 33681.0,
        SLOPE: 0.00189313,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.516000032424927,
        QA_MA: 101.904,
        VA_MA: 1.20515,
        QC_MA: 103.14,
        VC_MA: 1.20603,
        QE_MA: 103.14,
        VE_MA: 1.20603,
        QA_01: 149.659,
        VA_01: 1.40817,
        QC_01: 119.026,
        VC_01: 1.27697,
        QE_01: 119.026,
        VE_01: 1.27697,
        QA_02: 160.492,
        VA_02: 1.44975,
        QC_02: 136.149,
        VC_02: 1.34863,
        QE_02: 136.149,
        VE_02: 1.34863,
        QA_03: 190.604,
        VA_03: 1.55887,
        QC_03: 163.201,
        VC_03: 1.45371,
        QE_03: 163.201,
        VE_03: 1.45371,
        QA_04: 156.557,
        VA_04: 1.4348,
        QC_04: 146.057,
        VC_04: 1.38816,
        QE_04: 146.057,
        VE_04: 1.38816,
        QA_05: 118.925,
        VA_05: 1.2818,
        QC_05: 112.947,
        VC_05: 1.25038,
        QE_05: 112.947,
        VE_05: 1.25038,
        QA_06: 72.76,
        VA_06: 1.05827,
        QC_06: 91.543,
        VC_06: 1.15094,
        QE_06: 91.543,
        VE_06: 1.15094,
        QA_07: 43.853,
        VA_07: 0.88148,
        QC_07: 66.824,
        VC_07: 1.02126,
        QE_07: 66.824,
        VE_07: 1.02126,
        QA_08: 30.284,
        VA_08: 0.7792,
        QC_08: 67.261,
        VC_08: 1.02373,
        QE_08: 67.261,
        VE_08: 1.02373,
        QA_09: 32.849,
        VA_09: 0.79997,
        QC_09: 85.369,
        VC_09: 1.12027,
        QE_09: 85.369,
        VE_09: 1.12027,
        QA_10: 47.736,
        VA_10: 0.90789,
        QC_10: 120.639,
        VC_10: 1.28392,
        QE_10: 120.639,
        VE_10: 1.28392,
        QA_11: 101.975,
        VA_11: 1.20548,
        QC_11: 130.067,
        VC_11: 1.32369,
        QE_11: 130.067,
        VE_11: 1.32369,
        QA_12: 117.71,
        VA_12: 1.27651,
        QC_12: 101.226,
        VC_12: 1.19714,
        QE_12: 101.226,
        VE_12: 1.19714,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.702155183621926, 35.943985410874518, 0.0],
          [-81.695478383632235, 35.941310277545256, 0.0],
          [-81.691080383639076, 35.935748277553841, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285859.0,
        COMID: 9753968,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989784",
        GNIS_NAME: "Middle Little River",
        LENGTHKM: 0.44,
        REACHCODE: "03050101002534",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0047833152419550001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061458.0,
        ToNode: 250061462.0,
        Hydroseq: 250023106.0,
        LevelPathI: 250022607.0,
        Pathlength: 584.213,
        TerminalPa: 250002604.0,
        ArbolateSu: 130.641,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022607.0,
        UpHydroseq: 250023647.0,
        DnLevelPat: 250022607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022607.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2799,
        TotDASqKM: 142.4979,
        DivDASqKM: 142.4979,
        Tidal: 0,
        TOTMA: 0.18763701296,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28351.0,
        MAXELEVSMO: 28358.0,
        MINELEVSMO: 28354.0,
        SLOPE: 9.09e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.43999999761581399,
        QA_MA: 80.856,
        VA_MA: -9998.0,
        QC_MA: 82.575,
        VC_MA: -9998.0,
        QE_MA: 82.575,
        VE_MA: -9998.0,
        QA_01: 136.189,
        VA_01: -9998.0,
        QC_01: 108.582,
        VC_01: -9998.0,
        QE_01: 108.582,
        VE_01: -9998.0,
        QA_02: 141.372,
        VA_02: -9998.0,
        QC_02: 120.345,
        VC_02: -9998.0,
        QE_02: 120.345,
        VE_02: -9998.0,
        QA_03: 156.32,
        VA_03: -9998.0,
        QC_03: 134.725,
        VC_03: -9998.0,
        QE_03: 134.725,
        VE_03: -9998.0,
        QA_04: 123.805,
        VA_04: -9998.0,
        QC_04: 116.12,
        VC_04: -9998.0,
        QE_04: 116.12,
        VE_04: -9998.0,
        QA_05: 91.61,
        VA_05: -9998.0,
        QC_05: 87.858,
        VC_05: -9998.0,
        QE_05: 87.858,
        VE_05: -9998.0,
        QA_06: 52.408,
        VA_06: -9998.0,
        QC_06: 66.236,
        VC_06: -9998.0,
        QE_06: 66.236,
        VE_06: -9998.0,
        QA_07: 30.638,
        VA_07: -9998.0,
        QC_07: 47.09,
        VC_07: -9998.0,
        QE_07: 47.09,
        VE_07: -9998.0,
        QA_08: 20.274,
        VA_08: -9998.0,
        QC_08: 44.669,
        VC_08: -9998.0,
        QE_08: 44.669,
        VE_08: -9998.0,
        QA_09: 16.484,
        VA_09: -9998.0,
        QC_09: 46.442,
        VC_09: -9998.0,
        QE_09: 46.442,
        VE_09: -9998.0,
        QA_10: 23.838,
        VA_10: -9998.0,
        QC_10: 69.276,
        VC_10: -9998.0,
        QE_10: 69.276,
        VE_10: -9998.0,
        QA_11: 71.179,
        VA_11: -9998.0,
        QC_11: 98.875,
        VC_11: -9998.0,
        QE_11: 98.875,
        VE_11: -9998.0,
        QA_12: 106.528,
        VA_12: -9998.0,
        QC_12: 92.438,
        VC_12: -9998.0,
        QE_12: 92.438,
        VE_12: -9998.0,
        LakeFract: 0.00070276135,
        SurfArea: 10956.042295699999,
        RAreaHLoad: 0.05427808767,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.258228184310838, 35.823248277728453, 0.0],
          [-81.253697784317978, 35.822139211063643, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283786.0,
        COMID: 9751800,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.596,
        REACHCODE: "03050101000205",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016219603019409001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060875.0,
        ToNode: 250060896.0,
        Hydroseq: 250018882.0,
        LevelPathI: 250015617.0,
        Pathlength: 657.79,
        TerminalPa: 250002604.0,
        ArbolateSu: 186.901,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250019184.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018596.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8324,
        TotDASqKM: 176.9436,
        DivDASqKM: 176.9436,
        Tidal: 0,
        TOTMA: 0.047579138840700003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32911.0,
        MAXELEVSMO: 33423.0,
        MINELEVSMO: 32996.0,
        SLOPE: 0.00267543,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.595999956130981,
        QA_MA: 109.33,
        VA_MA: 1.27303,
        QC_MA: 110.354,
        VC_MA: 1.27376,
        QE_MA: 110.354,
        VE_MA: 1.27376,
        QA_01: 162.029,
        VA_01: 1.49713,
        QC_01: 128.597,
        VC_01: 1.35546,
        QE_01: 128.597,
        VE_01: 1.35546,
        QA_02: 173.41,
        VA_02: 1.54076,
        QC_02: 146.798,
        VC_02: 1.4317,
        QE_02: 146.798,
        VE_02: 1.4317,
        QA_03: 205.418,
        VA_03: 1.65675,
        QC_03: 175.451,
        VC_03: 1.54325,
        QE_03: 175.451,
        VE_03: 1.54325,
        QA_04: 168.331,
        VA_04: 1.52146,
        QC_04: 156.783,
        VC_04: 1.47165,
        QE_04: 156.783,
        VE_04: 1.47165,
        QA_05: 127.705,
        VA_05: 1.35598,
        QC_05: 120.964,
        VC_05: 1.32198,
        QE_05: 120.964,
        VE_05: 1.32198,
        QA_06: 77.646,
        VA_06: 1.11275,
        QC_06: 97.604,
        VC_06: 1.21283,
        QE_06: 97.604,
        VE_06: 1.21283,
        QA_07: 46.785,
        VA_07: 0.92303,
        QC_07: 71.182,
        VC_07: 1.07325,
        QE_07: 71.182,
        VE_07: 1.07325,
        QA_08: 32.291,
        VA_08: 0.81318,
        QC_08: 71.812,
        VC_08: 1.07684,
        QE_08: 71.812,
        VE_08: 1.07684,
        QA_09: 34.357,
        VA_09: 0.83008,
        QC_09: 88.818,
        VC_09: 1.16864,
        QE_09: 88.818,
        VE_09: 1.16864,
        QA_10: 49.015,
        VA_10: 0.93841,
        QC_10: 123.213,
        VC_10: 1.33194,
        QE_10: 123.213,
        VE_10: 1.33194,
        QA_11: 108.34,
        VA_11: 1.26838,
        QC_11: 136.213,
        VC_11: 1.38794,
        QE_11: 136.213,
        VE_11: 1.38794,
        QA_12: 127.198,
        VA_12: 1.35377,
        QC_12: 108.624,
        VC_12: 1.26569,
        QE_12: 108.624,
        VE_12: 1.26569,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.683698116983862, 35.929958477562934, 0.0],
          [-81.681865583653348, 35.926845010901047, 0.0],
          [-81.673360983666612, 35.924398610904859, 0.0],
          [-81.67200791700202, 35.921651210909204, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283795.0,
        COMID: 9751456,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.461,
        REACHCODE: "03050101000214",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.004659493677839,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060784.0,
        ToNode: 250060796.0,
        Hydroseq: 250022143.0,
        LevelPathI: 250015617.0,
        Pathlength: 669.955,
        TerminalPa: 250002604.0,
        ArbolateSu: 136.775,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250022613.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021704.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3429,
        TotDASqKM: 129.1401,
        DivDASqKM: 129.1401,
        Tidal: 0,
        TOTMA: 0.013940201233000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35704.0,
        MAXELEVSMO: 35970.0,
        MINELEVSMO: 35801.0,
        SLOPE: 0.00366594,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.460999995470047,
        QA_MA: 82.347,
        VA_MA: 1.25419,
        QC_MA: 84.038,
        VC_MA: 1.25575,
        QE_MA: 84.038,
        VE_MA: 1.25575,
        QA_01: 118.042,
        VA_01: 1.45348,
        QC_01: 94.466,
        VC_01: 1.31646,
        QE_01: 94.466,
        VE_01: 1.31646,
        QA_02: 127.999,
        VA_02: 1.5038,
        QC_02: 109.257,
        VC_02: 1.39741,
        QE_02: 109.257,
        VE_02: 1.39741,
        QA_03: 153.125,
        VA_03: 1.62316,
        QC_03: 132.062,
        VC_03: 1.51273,
        QE_03: 132.062,
        VE_03: 1.51273,
        QA_04: 126.486,
        VA_04: 1.49628,
        QC_04: 118.577,
        VC_04: 1.44579,
        QE_04: 118.577,
        VE_04: 1.44579,
        QA_05: 95.596,
        VA_05: 1.33216,
        QC_05: 91.536,
        VC_05: 1.29973,
        QE_05: 91.536,
        VE_05: 1.29973,
        QA_06: 59.932,
        VA_06: 1.10735,
        QC_06: 75.605,
        VC_06: 1.20402,
        QE_06: 75.605,
        VE_06: 1.20402,
        QA_07: 36.185,
        VA_07: 0.91957,
        QC_07: 55.394,
        VC_07: 1.06766,
        QE_07: 55.394,
        VE_07: 1.06766,
        QA_08: 25.323,
        VA_08: 0.81405,
        QC_08: 56.041,
        VC_08: 1.07236,
        QE_08: 56.041,
        VE_08: 1.07236,
        QA_09: 28.743,
        VA_09: 0.84923,
        QC_09: 75.874,
        VC_09: 1.20571,
        QE_09: 75.874,
        VE_09: 1.20571,
        QA_10: 41.169,
        VA_10: 0.96291,
        QC_10: 107.186,
        VC_10: 1.3864,
        QE_10: 107.186,
        VE_10: 1.3864,
        QA_11: 83.059,
        VA_11: 1.25852,
        QC_11: 111.227,
        VC_11: 1.40779,
        QE_11: 111.227,
        VE_11: 1.40779,
        QA_12: 93.054,
        VA_12: 1.31762,
        QC_12: 81.737,
        VC_12: 1.24189,
        QE_12: 81.737,
        VE_12: 1.24189,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.684808783648805, 35.979422477486139, 0.0],
          [-81.688102916977073, 35.976855877490152, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283796.0,
        COMID: 9751442,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.895,
        REACHCODE: "03050101000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0088190661868739993,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060781.0,
        ToNode: 250060784.0,
        Hydroseq: 250022613.0,
        LevelPathI: 250015617.0,
        Pathlength: 670.416,
        TerminalPa: 250002604.0,
        ArbolateSu: 134.581,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250023111.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022143.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2465,
        TotDASqKM: 127.5471,
        DivDASqKM: 127.5471,
        Tidal: 0,
        TOTMA: 0.0284084128966,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35850.0,
        MAXELEVSMO: 36192.0,
        MINELEVSMO: 35970.0,
        SLOPE: 0.00248044,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.894999980926514,
        QA_MA: 81.476,
        VA_MA: 1.19483,
        QC_MA: 83.183,
        VC_MA: 1.19632,
        QE_MA: 83.183,
        VE_MA: 1.19632,
        QA_01: 116.615,
        VA_01: 1.38074,
        QC_01: 93.354,
        VC_01: 1.25241,
        QE_01: 93.354,
        VE_01: 1.25241,
        QA_02: 126.502,
        VA_02: 1.4281,
        QC_02: 108.013,
        VC_02: 1.32842,
        QE_02: 108.013,
        VE_02: 1.32842,
        QA_03: 151.399,
        VA_03: 1.54018,
        QC_03: 130.621,
        VC_03: 1.43673,
        QE_03: 130.621,
        VE_03: 1.43673,
        QA_04: 125.102,
        VA_04: 1.4215,
        QC_04: 117.308,
        VC_04: 1.37413,
        QE_04: 117.308,
        VE_04: 1.37413,
        QA_05: 94.55,
        VA_05: 1.26772,
        QC_05: 90.571,
        VC_05: 1.23736,
        QE_05: 90.571,
        VE_05: 1.23736,
        QA_06: 59.348,
        VA_06: 1.05754,
        QC_06: 74.878,
        VC_06: 1.14808,
        QE_06: 74.878,
        VE_06: 1.14808,
        QA_07: 35.835,
        VA_07: 0.88148,
        QC_07: 54.871,
        VC_07: 1.02031,
        QE_07: 54.871,
        VE_07: 1.02031,
        QA_08: 25.095,
        VA_08: 0.78271,
        QC_08: 55.527,
        VC_08: 1.02482,
        QE_08: 55.527,
        VE_08: 1.02482,
        QA_09: 28.569,
        VA_09: 0.81651,
        QC_09: 75.468,
        VC_09: 1.15159,
        QE_09: 75.468,
        VE_09: 1.15159,
        QA_10: 40.951,
        VA_10: 0.92357,
        QC_10: 106.732,
        VC_10: 1.32198,
        QE_10: 106.732,
        VE_10: 1.32198,
        QA_11: 82.291,
        VA_11: 1.19953,
        QC_11: 110.442,
        VC_11: 1.34054,
        QE_11: 110.442,
        VE_11: 1.34054,
        QA_12: 91.983,
        VA_12: 1.2538,
        QC_12: 80.881,
        VC_12: 1.18318,
        QE_12: 80.881,
        VE_12: 1.18318,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.67951638365696, 35.985012877477459, 0.0],
          [-81.680978183654702, 35.980615410817677, 0.0],
          [-81.684808783648805, 35.979422477486139, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285862.0,
        COMID: 9753976,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989784",
        GNIS_NAME: "Middle Little River",
        LENGTHKM: 0.944,
        REACHCODE: "03050101002537",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0089722380264150008,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061462.0,
        ToNode: 250061463.0,
        Hydroseq: 250022607.0,
        LevelPathI: 250022607.0,
        Pathlength: 583.269,
        TerminalPa: 250002604.0,
        ArbolateSu: 143.809,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250022607.0,
        UpHydroseq: 250023106.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010242.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4104,
        TotDASqKM: 156.3003,
        DivDASqKM: 156.3003,
        Tidal: 0,
        TOTMA: 0.40256668365999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28333.0,
        MAXELEVSMO: 28354.0,
        MINELEVSMO: 28333.0,
        SLOPE: 0.00022245,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.94400000572204601,
        QA_MA: 87.775,
        VA_MA: -9998.0,
        QC_MA: 89.356,
        VC_MA: -9998.0,
        QE_MA: 89.356,
        VE_MA: -9998.0,
        QA_01: 148.385,
        VA_01: -9998.0,
        QC_01: 118.039,
        VC_01: -9998.0,
        QE_01: 118.039,
        VE_01: -9998.0,
        QA_02: 154.27,
        VA_02: -9998.0,
        QC_02: 131.012,
        VC_02: -9998.0,
        QE_02: 131.012,
        VE_02: -9998.0,
        QA_03: 170.737,
        VA_03: -9998.0,
        QC_03: 146.722,
        VC_03: -9998.0,
        QE_03: 146.722,
        VE_03: -9998.0,
        QA_04: 134.827,
        VA_04: -9998.0,
        QC_04: 126.213,
        VC_04: -9998.0,
        QE_04: 126.213,
        VE_04: -9998.0,
        QA_05: 99.187,
        VA_05: -9998.0,
        QC_05: 94.843,
        VC_05: -9998.0,
        QE_05: 94.843,
        VE_05: -9998.0,
        QA_06: 56.773,
        VA_06: -9998.0,
        QC_06: 71.673,
        VC_06: -9998.0,
        QE_06: 71.673,
        VE_06: -9998.0,
        QA_07: 33.238,
        VA_07: -9998.0,
        QC_07: 50.986,
        VC_07: -9998.0,
        QE_07: 50.986,
        VE_07: -9998.0,
        QA_08: 21.999,
        VA_08: -9998.0,
        QC_08: 48.548,
        VC_08: -9998.0,
        QE_08: 48.548,
        VE_08: -9998.0,
        QA_09: 17.887,
        VA_09: -9998.0,
        QC_09: 49.914,
        VC_09: -9998.0,
        QE_09: 49.914,
        VE_09: -9998.0,
        QA_10: 24.886,
        VA_10: -9998.0,
        QC_10: 71.698,
        VC_10: -9998.0,
        QE_10: 71.698,
        VE_10: -9998.0,
        QA_11: 75.876,
        VA_11: -9998.0,
        QC_11: 103.813,
        VC_11: -9998.0,
        QE_11: 103.813,
        VE_11: -9998.0,
        QA_12: 115.535,
        VA_12: -9998.0,
        QC_12: 99.523,
        VC_12: -9998.0,
        QE_12: 99.523,
        VE_12: -9998.0,
        LakeFract: 0.00163093433,
        SurfArea: 25426.249609499999,
        RAreaHLoad: 0.1164511703,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.253697784317978, 35.822139211063643, 0.0],
          [-81.25310431765223, 35.816505477738986, 0.0],
          [-81.250663784322683, 35.815115277741199, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283797.0,
        COMID: 9751416,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.778,
        REACHCODE: "03050101000216",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017070040360875002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060772.0,
        ToNode: 250060781.0,
        Hydroseq: 250023111.0,
        LevelPathI: 250015617.0,
        Pathlength: 671.311,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.545,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250023652.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022613.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6551,
        TotDASqKM: 123.8373,
        DivDASqKM: 123.8373,
        Tidal: 0,
        TOTMA: 0.049846753799,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36033.0,
        MAXELEVSMO: 37478.0,
        MINELEVSMO: 36192.0,
        SLOPE: 0.00723284,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7779999971389771,
        QA_MA: 79.55,
        VA_MA: 1.35263,
        QC_MA: 81.293,
        VC_MA: 1.35446,
        QE_MA: 81.293,
        VE_MA: 1.35446,
        QA_01: 113.421,
        VA_01: 1.56906,
        QC_01: 90.864,
        VC_01: 1.41815,
        QE_01: 90.864,
        VE_01: 1.41815,
        QA_02: 123.135,
        VA_02: 1.62529,
        QC_02: 105.216,
        VC_02: 1.50802,
        QE_02: 105.216,
        VE_02: 1.50802,
        QA_03: 147.492,
        VA_03: 1.75775,
        QC_03: 127.361,
        VC_03: 1.63608,
        QE_03: 127.361,
        VE_03: 1.63608,
        QA_04: 121.952,
        VA_04: 1.61855,
        QC_04: 114.421,
        VC_04: 1.56266,
        QE_04: 114.421,
        VE_04: 1.56266,
        QA_05: 92.195,
        VA_05: 1.43772,
        QC_05: 88.399,
        VC_05: 1.40206,
        QE_05: 88.399,
        VE_05: 1.40206,
        QA_06: 58.045,
        VA_06: 1.19171,
        QC_06: 73.257,
        VC_06: 1.29822,
        QE_06: 73.257,
        VE_06: 1.29822,
        QA_07: 35.035,
        VA_07: 0.98399,
        QC_07: 53.675,
        VC_07: 1.14753,
        QE_07: 53.675,
        VE_07: 1.14753,
        QA_08: 24.585,
        VA_08: 0.86815,
        QC_08: 54.376,
        VC_08: 1.15333,
        QE_08: 54.376,
        VE_08: 1.15333,
        QA_09: 28.203,
        VA_09: 0.91048,
        QC_09: 74.616,
        VC_09: 1.30792,
        QE_09: 74.616,
        VE_09: 1.30792,
        QA_10: 40.628,
        VA_10: 1.03927,
        QC_10: 106.061,
        VC_10: 1.51313,
        QE_10: 106.061,
        VE_10: 1.51313,
        QA_11: 80.775,
        VA_11: 1.36114,
        QC_11: 108.887,
        VC_11: 1.53007,
        QE_11: 108.887,
        VE_11: 1.53007,
        QA_12: 89.663,
        VA_12: 1.42114,
        QC_12: 79.022,
        VC_12: 1.33884,
        QE_12: 79.022,
        VE_12: 1.33884,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.684653183649061, 35.99561301079433, 0.0],
          [-81.682286916986016, 35.99659901079275, 0.0],
          [-81.67991391698979, 35.989043210804596, 0.0],
          [-81.677715116993113, 35.987738877473305, 0.0],
          [-81.67951638365696, 35.985012877477459, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291132.0,
        COMID: 9718271,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.873,
        REACHCODE: "03050104002425",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0081255675457920003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058163.0,
        ToNode: 250058165.0,
        Hydroseq: 250004160.0,
        LevelPathI: 250003258.0,
        Pathlength: 358.421,
        TerminalPa: 250002604.0,
        ArbolateSu: 8472.502,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004175.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004142.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2537,
        TotDASqKM: 11396.5578,
        DivDASqKM: 11396.5578,
        Tidal: 0,
        TOTMA: 0.44081771940999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6784.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.87300002574920699,
        QA_MA: 5484.509,
        VA_MA: -9998.0,
        QC_MA: 5484.509,
        VC_MA: -9998.0,
        QE_MA: 6182.197,
        VE_MA: -9998.0,
        QA_01: 10289.244,
        VA_01: -9998.0,
        QC_01: 10289.244,
        VC_01: -9998.0,
        QE_01: 8896.682,
        VE_01: -9998.0,
        QA_02: 10877.794,
        VA_02: -9998.0,
        QC_02: 10877.794,
        VC_02: -9998.0,
        QE_02: 9037.82,
        VE_02: -9998.0,
        QA_03: 11909.961,
        VA_03: -9998.0,
        QC_03: 11909.961,
        VC_03: -9998.0,
        QE_03: 9660.703,
        VE_03: -9998.0,
        QA_04: 8010.969,
        VA_04: -9998.0,
        QC_04: 8010.969,
        VC_04: -9998.0,
        QE_04: 7759.791,
        VE_04: -9998.0,
        QA_05: 5350.387,
        VA_05: -9998.0,
        QC_05: 5350.387,
        VC_05: -9998.0,
        QE_05: 6305.719,
        VE_05: -9998.0,
        QA_06: 3186.141,
        VA_06: -9998.0,
        QC_06: 3186.141,
        VC_06: -9998.0,
        QE_06: 5409.959,
        VE_06: -9998.0,
        QA_07: 2011.247,
        VA_07: -9998.0,
        QC_07: 2011.247,
        VC_07: -9998.0,
        QE_07: 4087.134,
        VE_07: -9998.0,
        QA_08: 1445.111,
        VA_08: -9998.0,
        QC_08: 1445.111,
        VC_08: -9998.0,
        QE_08: 3740.235,
        VE_08: -9998.0,
        QA_09: 1295.183,
        VA_09: -9998.0,
        QC_09: 1295.183,
        VC_09: -9998.0,
        QE_09: 3432.036,
        VE_09: -9998.0,
        QA_10: 1352.809,
        VA_10: -9998.0,
        QC_10: 1352.809,
        VC_10: -9998.0,
        QE_10: 4672.413,
        VE_10: -9998.0,
        QA_11: 3299.256,
        VA_11: -9998.0,
        QC_11: 3299.256,
        VC_11: -9998.0,
        QE_11: 4745.355,
        VE_11: -9998.0,
        QA_12: 6823.866,
        VA_12: -9998.0,
        QC_12: 6823.866,
        VC_12: -9998.0,
        QE_12: 6532.524,
        VE_12: -9998.0,
        LakeFract: 0.02755331267,
        SurfArea: 1249499.01346,
        RAreaHLoad: 0.08259753092,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.894021984876247, 34.479935213146973, 0.0],
          [-80.890423184881911, 34.472808079824688, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285855.0,
        COMID: 9753960,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.751,
        REACHCODE: "03050101002530",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0081129327820279994,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061454.0,
        ToNode: 250061453.0,
        Hydroseq: 250010127.0,
        LevelPathI: 250003258.0,
        Pathlength: 577.535,
        TerminalPa: 250002604.0,
        ArbolateSu: 3059.124,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250010186.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250010075.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5724,
        TotDASqKM: 3390.0372,
        DivDASqKM: 3390.0372,
        Tidal: 0,
        TOTMA: 0.32026226638,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28325.0,
        MAXELEVSMO: 28329.0,
        MINELEVSMO: 28329.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.75099998712539695,
        QA_MA: 2051.933,
        VA_MA: -9998.0,
        QC_MA: 2051.933,
        VC_MA: -9998.0,
        QE_MA: 2246.977,
        VE_MA: -9998.0,
        QA_01: 3312.377,
        VA_01: -9998.0,
        QC_01: 3312.377,
        VC_01: -9998.0,
        QE_01: 3517.652,
        VE_01: -9998.0,
        QA_02: 3506.506,
        VA_02: -9998.0,
        QC_02: 3506.506,
        VC_02: -9998.0,
        QE_02: 3578.329,
        VE_02: -9998.0,
        QA_03: 3963.596,
        VA_03: -9998.0,
        QC_03: 3963.596,
        VC_03: -9998.0,
        QE_03: 4227.715,
        VE_03: -9998.0,
        QA_04: 3035.597,
        VA_04: -9998.0,
        QC_04: 3035.597,
        VC_04: -9998.0,
        QE_04: 3227.723,
        VE_04: -9998.0,
        QA_05: 2317.989,
        VA_05: -9998.0,
        QC_05: 2317.989,
        VC_05: -9998.0,
        QE_05: 2540.111,
        VE_05: -9998.0,
        QA_06: 1347.944,
        VA_06: -9998.0,
        QC_06: 1347.944,
        VC_06: -9998.0,
        QE_06: 1607.557,
        VE_06: -9998.0,
        QA_07: 798.593,
        VA_07: -9998.0,
        QC_07: 798.593,
        VC_07: -9998.0,
        QE_07: 945.487,
        VE_07: -9998.0,
        QA_08: 563.451,
        VA_08: -9998.0,
        QC_08: 563.451,
        VC_08: -9998.0,
        QE_08: 863.068,
        VE_08: -9998.0,
        QA_09: 553.34,
        VA_09: -9998.0,
        QC_09: 1033.089,
        VC_09: -9998.0,
        QE_09: 1069.595,
        VE_09: -9998.0,
        QA_10: 711.865,
        VA_10: -9998.0,
        QC_10: 1044.589,
        VC_10: -9998.0,
        QE_10: 1005.087,
        VE_10: -9998.0,
        QA_11: 1914.207,
        VA_11: -9998.0,
        QC_11: 1914.207,
        VC_11: -9998.0,
        QE_11: 1891.407,
        VE_11: -9998.0,
        QA_12: 2611.056,
        VA_12: -9998.0,
        QC_12: 2611.056,
        VC_12: -9998.0,
        QE_12: 2634.769,
        VE_12: -9998.0,
        LakeFract: 0.0327383906,
        SurfArea: 510391.176331,
        RAreaHLoad: 0.09264282723,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.204279984394702, 35.823404277728237, 0.0],
          [-81.196736317739692, 35.82123661106499, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283798.0,
        COMID: 9750548,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 0.104,
        REACHCODE: "03050101000217",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00095229951268299999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060568.0,
        ToNode: 250060760.0,
        Hydroseq: 250024236.0,
        LevelPathI: 250015617.0,
        Pathlength: 673.645,
        TerminalPa: 250002604.0,
        ArbolateSu: 127.349,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250024860.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023652.0,
        FromMeas: 84.20653,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 120.4065,
        DivDASqKM: 120.4065,
        Tidal: 0,
        TOTMA: 0.0025164940902299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36487.0,
        MAXELEVSMO: 37731.0,
        MINELEVSMO: 37478.0,
        SLOPE: 0.02432692,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.104000002145767,
        QA_MA: 77.65,
        VA_MA: 1.567,
        QC_MA: 79.425,
        VC_MA: 1.56931,
        QE_MA: 79.425,
        VE_MA: 1.56931,
        QA_01: 110.344,
        VA_01: 1.82518,
        QC_01: 88.462,
        VC_01: 1.64356,
        QE_01: 88.462,
        VE_01: 1.64356,
        QA_02: 119.891,
        VA_02: 1.89351,
        QC_02: 102.518,
        VC_02: 1.75231,
        QE_02: 102.518,
        VE_02: 1.75231,
        QA_03: 143.754,
        VA_03: 2.0539,
        QC_03: 124.238,
        VC_03: 1.90743,
        QE_03: 124.238,
        VE_03: 1.90743,
        QA_04: 118.935,
        VA_04: 1.88678,
        QC_04: 111.654,
        VC_04: 1.81927,
        QE_04: 111.654,
        VE_04: 1.81927,
        QA_05: 89.909,
        VA_05: 1.66888,
        QC_05: 86.288,
        VC_05: 1.62604,
        QE_05: 86.288,
        VE_05: 1.62604,
        QA_06: 56.776,
        VA_06: 1.37422,
        QC_06: 71.677,
        VC_06: 1.50242,
        QE_06: 71.677,
        VE_06: 1.50242,
        QA_07: 34.273,
        VA_07: 1.12363,
        QC_07: 52.536,
        VC_07: 1.32084,
        QE_07: 52.536,
        VE_07: 1.32084,
        QA_08: 24.104,
        VA_08: 0.98465,
        QC_08: 53.292,
        VC_08: 1.32855,
        QE_08: 53.292,
        VE_08: 1.32855,
        QA_09: 27.81,
        VA_09: 1.03801,
        QC_09: 73.697,
        VC_09: 1.52017,
        QE_09: 73.697,
        VE_09: 1.52017,
        QA_10: 40.041,
        VA_10: 1.19383,
        QC_10: 104.833,
        VC_10: 1.76954,
        QE_10: 104.833,
        VE_10: 1.76954,
        QA_11: 79.101,
        VA_11: 1.57944,
        QC_11: 107.161,
        VC_11: 1.78668,
        QE_11: 107.161,
        VE_11: 1.78668,
        QA_12: 87.376,
        VA_12: 1.64839,
        QC_12: 77.187,
        VC_12: 1.55031,
        QE_12: 77.187,
        VE_12: 1.55031,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.684285316982994, 36.00104087745251, 0.0],
          [-81.684512983649313, 36.000146410787352, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285856.0,
        COMID: 9753962,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989784",
        GNIS_NAME: "Middle Little River",
        LENGTHKM: 2.296,
        REACHCODE: "03050101002531",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023700763474640001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061455.0,
        ToNode: 250061456.0,
        Hydroseq: 250024229.0,
        LevelPathI: 250022607.0,
        Pathlength: 585.859,
        TerminalPa: 250002604.0,
        ArbolateSu: 123.346,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022607.0,
        UpHydroseq: 250024848.0,
        DnLevelPat: 250022607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023647.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.3121,
        TotDASqKM: 136.1637,
        DivDASqKM: 136.1637,
        Tidal: 0,
        TOTMA: 0.97912405241,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28850.0,
        MAXELEVSMO: 28928.0,
        MINELEVSMO: 28859.0,
        SLOPE: 0.00030052,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2960000038146968,
        QA_MA: 77.737,
        VA_MA: -9998.0,
        QC_MA: 79.511,
        VC_MA: -9998.0,
        QE_MA: 79.511,
        VE_MA: -9998.0,
        QA_01: 130.594,
        VA_01: -9998.0,
        QC_01: 104.235,
        VC_01: -9998.0,
        QE_01: 104.235,
        VE_01: -9998.0,
        QA_02: 135.406,
        VA_02: -9998.0,
        QC_02: 115.401,
        VC_02: -9998.0,
        QE_02: 115.401,
        VE_02: -9998.0,
        QA_03: 149.749,
        VA_03: -9998.0,
        QC_03: 129.245,
        VC_03: -9998.0,
        QE_03: 129.245,
        VE_03: -9998.0,
        QA_04: 118.85,
        VA_04: -9998.0,
        QC_04: 111.576,
        VC_04: -9998.0,
        QE_04: 111.576,
        VE_04: -9998.0,
        QA_05: 88.239,
        VA_05: -9998.0,
        QC_05: 84.744,
        VC_05: -9998.0,
        QE_05: 84.744,
        VE_05: -9998.0,
        QA_06: 50.456,
        VA_06: -9998.0,
        QC_06: 63.801,
        VC_06: -9998.0,
        QE_06: 63.801,
        VE_06: -9998.0,
        QA_07: 29.463,
        VA_07: -9998.0,
        QC_07: 45.326,
        VC_07: -9998.0,
        QE_07: 45.326,
        VE_07: -9998.0,
        QA_08: 19.505,
        VA_08: -9998.0,
        QC_08: 42.94,
        VC_08: -9998.0,
        QE_08: 42.94,
        VE_08: -9998.0,
        QA_09: 15.843,
        VA_09: -9998.0,
        QC_09: 44.842,
        VC_09: -9998.0,
        QE_09: 44.842,
        VE_09: -9998.0,
        QA_10: 23.409,
        VA_10: -9998.0,
        QC_10: 68.278,
        VC_10: -9998.0,
        QE_10: 68.278,
        VE_10: -9998.0,
        QA_11: 69.158,
        VA_11: -9998.0,
        QC_11: 96.727,
        VC_11: -9998.0,
        QE_11: 96.727,
        VE_11: -9998.0,
        QA_12: 102.483,
        VA_12: -9998.0,
        QC_12: 89.239,
        VC_12: -9998.0,
        QE_12: 89.239,
        VE_12: -9998.0,
        LakeFract: 0.00353170186,
        SurfArea: 55059.196061299997,
        RAreaHLoad: 0.28323293108999997,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.286449984267108, 35.834660077710794, 0.0],
          [-81.282573984273142, 35.83401907771173, 0.0],
          [-81.278269917613216, 35.829142811052691, 0.0],
          [-81.274877117618473, 35.830888477716712, 0.0],
          [-81.271380384290524, 35.828824611053165, 0.0],
          [-81.267307184296783, 35.830146077717814, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283791.0,
        COMID: 9751628,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.637,
        REACHCODE: "03050101000210",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.027371613987439002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060826.0,
        ToNode: 250060843.0,
        Hydroseq: 250020552.0,
        LevelPathI: 250015617.0,
        Pathlength: 661.934,
        TerminalPa: 250002604.0,
        ArbolateSu: 167.206,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250020921.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020195.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8063,
        TotDASqKM: 157.7322,
        DivDASqKM: 157.7322,
        Tidal: 0,
        TOTMA: 0.080189293052200003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33750.0,
        MAXELEVSMO: 34667.0,
        MINELEVSMO: 33968.0,
        SLOPE: 0.00265073,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.6370000839233398,
        QA_MA: 99.029,
        VA_MA: 1.24772,
        QC_MA: 100.342,
        VC_MA: 1.24872,
        QE_MA: 100.342,
        VE_MA: 1.24872,
        QA_01: 144.912,
        VA_01: 1.45817,
        QC_01: 115.348,
        VC_01: 1.32095,
        QE_01: 115.348,
        VE_01: 1.32095,
        QA_02: 155.593,
        VA_02: 1.50242,
        QC_02: 132.105,
        VC_02: 1.39657,
        QE_02: 132.105,
        VE_02: 1.39657,
        QA_03: 184.995,
        VA_03: 1.61736,
        QC_03: 158.554,
        VC_03: 1.5073,
        QE_03: 158.554,
        VE_03: 1.5073,
        QA_04: 152.086,
        VA_04: 1.48805,
        QC_04: 141.98,
        VC_04: 1.43904,
        QE_04: 141.98,
        VE_04: 1.43904,
        QA_05: 115.521,
        VA_05: 1.32778,
        QC_05: 109.834,
        VC_05: 1.29496,
        QE_05: 109.834,
        VE_05: 1.29496,
        QA_06: 70.873,
        VA_06: 1.09485,
        QC_06: 89.202,
        VC_06: 1.19176,
        QE_06: 89.202,
        VE_06: 1.19176,
        QA_07: 42.739,
        VA_07: 0.90961,
        QC_07: 65.167,
        VC_07: 1.05612,
        QE_07: 65.167,
        VE_07: 1.05612,
        QA_08: 29.545,
        VA_08: 0.80258,
        QC_08: 65.587,
        VC_08: 1.05867,
        QE_08: 65.587,
        VE_08: 1.05867,
        QA_09: 32.29,
        VA_09: 0.82645,
        QC_09: 84.085,
        VC_09: 1.16448,
        QE_09: 84.085,
        VE_09: 1.16448,
        QA_10: 46.989,
        VA_10: 0.94065,
        QC_10: 119.128,
        VC_10: 1.33844,
        QE_10: 119.128,
        VE_10: 1.33844,
        QA_11: 99.366,
        VA_11: 1.24942,
        QC_11: 127.521,
        VC_11: 1.37636,
        QE_11: 127.521,
        VE_11: 1.37636,
        QA_12: 114.016,
        VA_12: 1.32071,
        QC_12: 98.332,
        VC_12: 1.23867,
        QE_12: 98.332,
        VE_12: 1.23867,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.712952716938446, 35.953870477525818, 0.0],
          [-81.707713983613189, 35.953828677525905, 0.0],
          [-81.708892983611349, 35.950392810864514, 0.0],
          [-81.701428916956388, 35.949711010865656, 0.0],
          [-81.705284583617072, 35.947258010869461, 0.0],
          [-81.702155183621926, 35.943985410874518, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291134.0,
        COMID: 9718275,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.578,
        REACHCODE: "03050104002427",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0053956528702080002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058165.0,
        ToNode: 250058174.0,
        Hydroseq: 250004142.0,
        LevelPathI: 250003258.0,
        Pathlength: 357.843,
        TerminalPa: 250002604.0,
        ArbolateSu: 8475.286,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004160.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004126.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.432,
        TotDASqKM: 11398.6791,
        DivDASqKM: 11398.6791,
        Tidal: 0,
        TOTMA: 0.29185869632,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6785.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.57800000905990601,
        QA_MA: 5485.235,
        VA_MA: -9998.0,
        QC_MA: 5485.235,
        VC_MA: -9998.0,
        QE_MA: 6182.923,
        VE_MA: -9998.0,
        QA_01: 10290.832,
        VA_01: -9998.0,
        QC_01: 10290.832,
        VC_01: -9998.0,
        QE_01: 8898.265,
        VE_01: -9998.0,
        QA_02: 10879.534,
        VA_02: -9998.0,
        QC_02: 10879.534,
        VC_02: -9998.0,
        QE_02: 9039.556,
        VE_02: -9998.0,
        QA_03: 11911.872,
        VA_03: -9998.0,
        QC_03: 11911.872,
        VC_03: -9998.0,
        QE_03: 9662.608,
        VE_03: -9998.0,
        QA_04: 8011.995,
        VA_04: -9998.0,
        QC_04: 8011.995,
        VC_04: -9998.0,
        QE_04: 7760.816,
        VE_04: -9998.0,
        QA_05: 5350.957,
        VA_05: -9998.0,
        QC_05: 5350.957,
        VC_05: -9998.0,
        QE_05: 6306.29,
        VE_05: -9998.0,
        QA_06: 3186.512,
        VA_06: -9998.0,
        QC_06: 3186.512,
        VC_06: -9998.0,
        QE_06: 5410.333,
        VE_06: -9998.0,
        QA_07: 2011.532,
        VA_07: -9998.0,
        QC_07: 2011.532,
        VC_07: -9998.0,
        QE_07: 4087.423,
        VE_07: -9998.0,
        QA_08: 1445.339,
        VA_08: -9998.0,
        QC_08: 1445.339,
        VC_08: -9998.0,
        QE_08: 3740.468,
        VE_08: -9998.0,
        QA_09: 1295.354,
        VA_09: -9998.0,
        QC_09: 1295.354,
        VC_09: -9998.0,
        QE_09: 3432.211,
        VE_09: -9998.0,
        QA_10: 1352.952,
        VA_10: -9998.0,
        QC_10: 1352.952,
        VC_10: -9998.0,
        QE_10: 4672.562,
        VE_10: -9998.0,
        QA_11: 3299.37,
        VA_11: -9998.0,
        QC_11: 3299.37,
        VC_11: -9998.0,
        QE_11: 4745.472,
        VE_11: -9998.0,
        QA_12: 6824.439,
        VA_12: -9998.0,
        QC_12: 6824.439,
        VC_12: -9998.0,
        QE_12: 6533.095,
        VE_12: -9998.0,
        LakeFract: 0.01824477348,
        SurfArea: 827371.53013299999,
        RAreaHLoad: 0.05468656688,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890423184881911, 34.472808079824688, 0.0],
          [-80.887844518219211, 34.468123613165289, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285857.0,
        COMID: 9753964,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989784",
        GNIS_NAME: "Middle Little River",
        LENGTHKM: 1.206,
        REACHCODE: "03050101002532",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751274,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012226667257817,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061456.0,
        ToNode: 250061458.0,
        Hydroseq: 250023647.0,
        LevelPathI: 250022607.0,
        Pathlength: 584.653,
        TerminalPa: 250002604.0,
        ArbolateSu: 127.881,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022607.0,
        UpHydroseq: 250024229.0,
        DnLevelPat: 250022607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023106.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8433,
        TotDASqKM: 140.2119,
        DivDASqKM: 140.2119,
        Tidal: 0,
        TOTMA: 0.51429599682000005,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28353.0,
        MAXELEVSMO: 28859.0,
        MINELEVSMO: 28358.0,
        SLOPE: 0.00415422,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.205999970436096,
        QA_MA: 79.741,
        VA_MA: -9998.0,
        QC_MA: 81.48,
        VC_MA: -9998.0,
        QE_MA: 81.48,
        VE_MA: -9998.0,
        QA_01: 134.174,
        VA_01: -9998.0,
        QC_01: 107.017,
        VC_01: -9998.0,
        QE_01: 107.017,
        VE_01: -9998.0,
        QA_02: 139.216,
        VA_02: -9998.0,
        QC_02: 118.559,
        VC_02: -9998.0,
        QE_02: 118.559,
        VE_02: -9998.0,
        QA_03: 153.957,
        VA_03: -9998.0,
        QC_03: 132.755,
        VC_03: -9998.0,
        QE_03: 132.755,
        VE_03: -9998.0,
        QA_04: 122.047,
        VA_04: -9998.0,
        QC_04: 114.508,
        VC_04: -9998.0,
        QE_04: 114.508,
        VE_04: -9998.0,
        QA_05: 90.42,
        VA_05: -9998.0,
        QC_05: 86.76,
        VC_05: -9998.0,
        QE_05: 86.76,
        VE_05: -9998.0,
        QA_06: 51.718,
        VA_06: -9998.0,
        QC_06: 65.375,
        VC_06: -9998.0,
        QE_06: 65.375,
        VE_06: -9998.0,
        QA_07: 30.221,
        VA_07: -9998.0,
        QC_07: 46.464,
        VC_07: -9998.0,
        QE_07: 46.464,
        VE_07: -9998.0,
        QA_08: 19.998,
        VA_08: -9998.0,
        QC_08: 44.048,
        VC_08: -9998.0,
        QE_08: 44.048,
        VE_08: -9998.0,
        QA_09: 16.262,
        VA_09: -9998.0,
        QC_09: 45.889,
        VC_09: -9998.0,
        QE_09: 45.889,
        VE_09: -9998.0,
        QA_10: 23.685,
        VA_10: -9998.0,
        QC_10: 68.919,
        VC_10: -9998.0,
        QE_10: 68.919,
        VE_10: -9998.0,
        QA_11: 70.484,
        VA_11: -9998.0,
        QC_11: 98.138,
        VC_11: -9998.0,
        QE_11: 98.138,
        VE_11: -9998.0,
        QA_12: 105.09,
        VA_12: -9998.0,
        QC_12: 91.302,
        VC_12: -9998.0,
        QE_12: 91.302,
        VE_12: -9998.0,
        LakeFract: 0.00190078,
        SurfArea: 29633.140859,
        RAreaHLoad: 0.14877130458000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.267307184296783, 35.830146077717814, 0.0],
          [-81.262478917637736, 35.828390011053791, 0.0],
          [-81.258228184310838, 35.823248277728453, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283792.0,
        COMID: 9751576,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.133,
        REACHCODE: "03050101000211",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011353479790231999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060810.0,
        ToNode: 250060826.0,
        Hydroseq: 250020921.0,
        LevelPathI: 250015617.0,
        Pathlength: 664.571,
        TerminalPa: 250002604.0,
        ArbolateSu: 156.884,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250021303.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020552.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3113,
        TotDASqKM: 146.3652,
        DivDASqKM: 146.3652,
        Tidal: 0,
        TOTMA: 0.062416476273299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 34399.0,
        MAXELEVSMO: 34667.0,
        MINELEVSMO: 34667.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1330000162124629,
        QA_MA: 92.463,
        VA_MA: 0.6888,
        QC_MA: 93.938,
        VC_MA: 0.68929,
        QE_MA: 93.938,
        VE_MA: 0.68929,
        QA_01: 134.192,
        VA_01: 0.772,
        QC_01: 107.03,
        VC_01: 0.71662,
        QE_01: 107.03,
        VE_01: 0.71662,
        QA_02: 144.702,
        VA_02: 0.79094,
        QC_02: 123.101,
        VC_02: 0.7481,
        QE_02: 123.101,
        VE_02: 0.7481,
        QA_03: 172.336,
        VA_03: 0.83786,
        QC_03: 148.051,
        VC_03: 0.79337,
        QE_03: 148.051,
        VE_03: 0.79337,
        QA_04: 141.911,
        VA_04: 0.78597,
        QC_04: 132.69,
        VC_04: 0.76596,
        QE_04: 132.69,
        VE_04: 0.76596,
        QA_05: 107.59,
        VA_05: 0.72068,
        QC_05: 102.566,
        VC_05: 0.70749,
        QE_05: 102.566,
        VE_05: 0.70749,
        QA_06: 66.525,
        VA_06: 0.62779,
        QC_06: 83.802,
        VC_06: 0.66688,
        QE_06: 83.802,
        VE_06: 0.66688,
        QA_07: 40.14,
        VA_07: 0.55264,
        QC_07: 61.296,
        VC_07: 0.61203,
        QE_07: 61.296,
        VE_07: 0.61203,
        QA_08: 27.891,
        VA_08: 0.5097,
        QC_08: 61.843,
        VC_08: 0.61347,
        QE_08: 61.843,
        VE_08: 0.61347,
        QA_09: 31.063,
        VA_09: 0.52155,
        QC_09: 81.258,
        VC_09: 0.66106,
        QE_09: 81.258,
        VE_09: 0.66106,
        QA_10: 45.087,
        VA_10: 0.56818,
        QC_10: 115.261,
        VC_10: 0.733,
        QE_10: 115.261,
        VE_10: 0.733,
        QA_11: 92.935,
        VA_11: 0.68983,
        QC_11: 121.177,
        VC_11: 0.74443,
        QE_11: 121.177,
        VE_11: 0.74443,
        QA_12: 105.728,
        VA_12: 0.71687,
        QC_12: 91.807,
        VC_12: 0.68468,
        QE_12: 91.807,
        VE_12: 0.68468,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.707270716947221, 35.960561677515443, 0.0],
          [-81.712478783605889, 35.958176210852514, 0.0],
          [-81.714024783603406, 35.95547281085652, 0.0],
          [-81.712952716938446, 35.953870477525818, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291127.0,
        COMID: 9718251,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.176,
        REACHCODE: "03050104002420",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012363242166115,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058153.0,
        ToNode: 250058159.0,
        Hydroseq: 250004184.0,
        LevelPathI: 250003258.0,
        Pathlength: 361.095,
        TerminalPa: 250002604.0,
        ArbolateSu: 8453.984,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004193.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004175.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7596,
        TotDASqKM: 11379.0024,
        DivDASqKM: 11379.0024,
        Tidal: 0,
        TOTMA: 0.59381630935999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6785.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1759999990463259,
        QA_MA: 5478.603,
        VA_MA: -9998.0,
        QC_MA: 5478.603,
        VC_MA: -9998.0,
        QE_MA: 6176.283,
        VE_MA: -9998.0,
        QA_01: 10276.457,
        VA_01: -9998.0,
        QC_01: 10276.457,
        VC_01: -9998.0,
        QE_01: 8883.927,
        VE_01: -9998.0,
        QA_02: 10863.597,
        VA_02: -9998.0,
        QC_02: 10863.597,
        VC_02: -9998.0,
        QE_02: 9023.658,
        VE_02: -9998.0,
        QA_03: 11894.328,
        VA_03: -9998.0,
        QC_03: 11894.328,
        VC_03: -9998.0,
        QE_03: 9645.123,
        VE_03: -9998.0,
        QA_04: 8002.695,
        VA_04: -9998.0,
        QC_04: 8002.695,
        VC_04: -9998.0,
        QE_04: 7751.53,
        VE_04: -9998.0,
        QA_05: 5345.73,
        VA_05: -9998.0,
        QC_05: 5345.73,
        VC_05: -9998.0,
        QE_05: 6301.062,
        VE_05: -9998.0,
        QA_06: 3183.073,
        VA_06: -9998.0,
        QC_06: 3183.073,
        VC_06: -9998.0,
        QE_06: 5406.858,
        VE_06: -9998.0,
        QA_07: 2008.887,
        VA_07: -9998.0,
        QC_07: 2008.887,
        VC_07: -9998.0,
        QE_07: 4084.744,
        VE_07: -9998.0,
        QA_08: 1443.223,
        VA_08: -9998.0,
        QC_08: 1443.223,
        VC_08: -9998.0,
        QE_08: 3738.305,
        VE_08: -9998.0,
        QA_09: 1293.767,
        VA_09: -9998.0,
        QC_09: 1293.767,
        VC_09: -9998.0,
        QE_09: 3430.587,
        VE_09: -9998.0,
        QA_10: 1351.629,
        VA_10: -9998.0,
        QC_10: 1351.629,
        VC_10: -9998.0,
        QE_10: 4671.178,
        VE_10: -9998.0,
        QA_11: 3298.312,
        VA_11: -9998.0,
        QC_11: 3298.312,
        VC_11: -9998.0,
        QE_11: 4744.382,
        VE_11: -9998.0,
        QA_12: 6819.436,
        VA_12: -9998.0,
        QC_12: 6819.436,
        VC_12: -9998.0,
        QE_12: 6528.109,
        VE_12: -9998.0,
        LakeFract: 0.03708098907,
        SurfArea: 1681564.0215700001,
        RAreaHLoad: 0.11126540248,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883467718226029, 34.497983013118983, 0.0],
          [-80.894641118208654, 34.493737079792254, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286883.0,
        COMID: 9736406,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.567,
        REACHCODE: "03050103000172",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0052244242183669999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059961.0,
        ToNode: 250059965.0,
        Hydroseq: 250005902.0,
        LevelPathI: 250003258.0,
        Pathlength: 401.214,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.681,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005921.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005876.0,
        DnDrainCou: 2,
        DnHydroseq: 250005881.0,
        FromMeas: 9.93169,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2799,
        TotDASqKM: 9187.0488,
        DivDASqKM: 9187.0488,
        Tidal: 0,
        TOTMA: 0.016739889775700002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13623.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.566999971866608,
        QA_MA: 4621.205,
        VA_MA: 1.27505,
        QC_MA: 4621.205,
        VC_MA: 1.27505,
        QE_MA: 5322.961,
        VE_MA: 1.28618,
        QA_01: 8405.986,
        VA_01: 1.63653,
        QC_01: 8405.986,
        VC_01: 1.63653,
        QE_01: 6970.921,
        VE_01: 1.43675,
        QA_02: 8889.579,
        VA_02: 1.67657,
        QC_02: 8889.579,
        VC_02: 1.67657,
        QE_02: 7078.756,
        VE_02: 1.44598,
        QA_03: 9770.9,
        VA_03: 1.747,
        QC_03: 9770.9,
        VC_03: 1.747,
        QE_03: 7535.718,
        VE_03: 1.4844,
        QA_04: 6784.013,
        VA_04: 1.49363,
        QC_04: 6784.013,
        VC_04: 1.49363,
        QE_04: 6562.912,
        VE_04: 1.40118,
        QA_05: 4667.496,
        VA_05: 1.28018,
        QC_05: 4667.496,
        VC_05: 1.28018,
        QE_05: 5653.08,
        VE_05: 1.31793,
        QA_06: 2747.788,
        VA_06: 1.04187,
        QC_06: 2747.788,
        VC_06: 1.04187,
        QE_06: 4986.417,
        VE_06: 1.25285,
        QA_07: 1698.868,
        VA_07: 0.87659,
        QC_07: 1698.868,
        VC_07: 0.87659,
        QE_07: 3790.179,
        VE_07: 1.12484,
        QA_08: 1207.453,
        VA_08: 0.78237,
        QC_08: 1207.453,
        VC_08: 0.78237,
        QE_08: 3544.872,
        VE_08: 1.09634,
        QA_09: 1107.515,
        VA_09: 0.76112,
        QC_09: 1107.515,
        VC_09: 0.76112,
        QE_09: 3251.572,
        VE_09: 1.06102,
        QA_10: 1204.199,
        VA_10: 0.78169,
        QC_10: 1204.199,
        VC_10: 0.78169,
        QE_10: 4446.967,
        VE_10: 1.19714,
        QA_11: 3130.113,
        VA_11: 1.09441,
        QC_11: 3130.113,
        VC_11: 1.09441,
        QE_11: 4506.336,
        VE_11: 1.20343,
        QA_12: 5872.035,
        VA_12: 1.40617,
        QC_12: 5872.035,
        VC_12: 1.40617,
        QE_12: 5580.872,
        VE_12: 1.31106,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886772384887479, 34.803257012645133, 0.0],
          [-80.884698584890771, 34.798505612652434, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283793.0,
        COMID: 9751524,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 2.414,
        REACHCODE: "03050101000212",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024241866926345002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060801.0,
        ToNode: 250060810.0,
        Hydroseq: 250021303.0,
        LevelPathI: 250015617.0,
        Pathlength: 665.704,
        TerminalPa: 250002604.0,
        ArbolateSu: 148.317,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250021704.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020921.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9845,
        TotDASqKM: 139.5495,
        DivDASqKM: 139.5495,
        Tidal: 0,
        TOTMA: 0.075298441181199993,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 34606.0,
        MAXELEVSMO: 35280.0,
        MINELEVSMO: 34667.0,
        SLOPE: 0.00253935,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.4140000343322749,
        QA_MA: 88.373,
        VA_MA: 1.21608,
        QC_MA: 89.941,
        VC_MA: 1.21737,
        QE_MA: 89.941,
        VE_MA: 1.21737,
        QA_01: 127.702,
        VA_01: 1.41204,
        QC_01: 101.987,
        VC_01: 1.28011,
        QE_01: 101.987,
        VE_01: 1.28011,
        QA_02: 138.016,
        VA_02: 1.45851,
        QC_02: 117.565,
        VC_02: 1.35629,
        QE_02: 117.565,
        VE_02: 1.35629,
        QA_03: 164.653,
        VA_03: 1.57149,
        QC_03: 141.663,
        VC_03: 1.46536,
        QE_03: 141.663,
        VE_03: 1.46536,
        QA_04: 135.714,
        VA_04: 1.44828,
        QC_04: 127.025,
        VC_04: 1.40026,
        QE_04: 127.025,
        VE_04: 1.40026,
        QA_05: 102.736,
        VA_05: 1.29164,
        QC_05: 98.108,
        VC_05: 1.26031,
        QE_05: 98.108,
        VE_05: 1.26031,
        QA_06: 63.865,
        VA_06: 1.07229,
        QC_06: 80.497,
        VC_06: 1.16538,
        QE_06: 80.497,
        VE_06: 1.16538,
        QA_07: 38.549,
        VA_07: 0.89266,
        QC_07: 58.924,
        VC_07: 1.03455,
        QE_07: 58.924,
        VE_07: 1.03455,
        QA_08: 26.859,
        VA_08: 0.79066,
        QC_08: 59.51,
        VC_08: 1.03837,
        QE_08: 59.51,
        VE_08: 1.03837,
        QA_09: 30.149,
        VA_09: 0.82117,
        QC_09: 79.142,
        VC_09: 1.15769,
        QE_09: 79.142,
        VE_09: 1.15769,
        QA_10: 43.422,
        VA_10: 0.93078,
        QC_10: 111.847,
        VC_10: 1.32892,
        QE_10: 111.847,
        VE_10: 1.32892,
        QA_11: 88.794,
        VA_11: 1.21837,
        QC_11: 117.037,
        VC_11: 1.35379,
        QE_11: 117.037,
        VE_11: 1.35379,
        QA_12: 100.587,
        VA_12: 1.28067,
        QC_12: 87.736,
        VC_12: 1.20547,
        QE_12: 87.736,
        VE_12: 1.20547,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.697674516962195, 35.969361810835096, 0.0],
          [-81.699647116959056, 35.970116277500608, 0.0],
          [-81.699474116959379, 35.966154677506779, 0.0],
          [-81.706206783615585, 35.966401877506428, 0.0],
          [-81.704117516952181, 35.961960877513263, 0.0],
          [-81.707270716947221, 35.960561677515443, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286884.0,
        COMID: 9736414,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.062,
        REACHCODE: "03050103000172",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00059231785552700002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059965.0,
        ToNode: 250094179.0,
        Hydroseq: 250005881.0,
        LevelPathI: 250003258.0,
        Pathlength: 401.152,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.743,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005902.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005855.0,
        DnDrainCou: 2,
        DnHydroseq: 250005854.0,
        FromMeas: 0.0,
        ToMeas: 9.93169,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9187.0551,
        DivDASqKM: 9187.0551,
        Tidal: 0,
        TOTMA: 0.00183046419864,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13639.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.061999998986720997,
        QA_MA: 4621.207,
        VA_MA: 1.27505,
        QC_MA: 4621.207,
        VC_MA: 1.27505,
        QE_MA: 5322.963,
        VE_MA: 1.28618,
        QA_01: 8405.992,
        VA_01: 1.63653,
        QC_01: 8405.992,
        VC_01: 1.63653,
        QE_01: 6970.926,
        VE_01: 1.43675,
        QA_02: 8889.585,
        VA_02: 1.67657,
        QC_02: 8889.585,
        VC_02: 1.67657,
        QE_02: 7078.762,
        VE_02: 1.44598,
        QA_03: 9770.906,
        VA_03: 1.747,
        QC_03: 9770.906,
        VC_03: 1.747,
        QE_03: 7535.724,
        VE_03: 1.4844,
        QA_04: 6784.016,
        VA_04: 1.49363,
        QC_04: 6784.016,
        VC_04: 1.49363,
        QE_04: 6562.915,
        VE_04: 1.40118,
        QA_05: 4667.498,
        VA_05: 1.28018,
        QC_05: 4667.498,
        VC_05: 1.28018,
        QE_05: 5653.082,
        VE_05: 1.31793,
        QA_06: 2747.79,
        VA_06: 1.04187,
        QC_06: 2747.79,
        VC_06: 1.04187,
        QE_06: 4986.418,
        VE_06: 1.25285,
        QA_07: 1698.869,
        VA_07: 0.87659,
        QC_07: 1698.869,
        VC_07: 0.87659,
        QE_07: 3790.18,
        VE_07: 1.12484,
        QA_08: 1207.453,
        VA_08: 0.78237,
        QC_08: 1207.453,
        VC_08: 0.78237,
        QE_08: 3544.873,
        VE_08: 1.09634,
        QA_09: 1107.516,
        VA_09: 0.76112,
        QC_09: 1107.516,
        VC_09: 0.76112,
        QE_09: 3251.572,
        VE_09: 1.06102,
        QA_10: 1204.2,
        VA_10: 0.78169,
        QC_10: 1204.2,
        VC_10: 0.78169,
        QE_10: 4446.967,
        VE_10: 1.19714,
        QA_11: 3130.114,
        VA_11: 1.09441,
        QC_11: 3130.114,
        VC_11: 1.09441,
        QE_11: 4506.336,
        VE_11: 1.20342,
        QA_12: 5872.038,
        VA_12: 1.40617,
        QC_12: 5872.038,
        VC_12: 1.40617,
        QE_12: 5580.875,
        VE_12: 1.31106,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884698584890771, 34.798505612652434, 0.0],
          [-80.88479298489051, 34.798023745986541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291129.0,
        COMID: 9718263,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.801,
        REACHCODE: "03050104002422",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.017090148106021,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058159.0,
        ToNode: 250058163.0,
        Hydroseq: 250004175.0,
        LevelPathI: 250003258.0,
        Pathlength: 359.294,
        TerminalPa: 250002604.0,
        ArbolateSu: 8468.669,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004184.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004160.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9926,
        TotDASqKM: 11392.9326,
        DivDASqKM: 11392.9326,
        Tidal: 0,
        TOTMA: 0.90940746020999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6781.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8009999990463259,
        QA_MA: 5483.273,
        VA_MA: -9998.0,
        QC_MA: 5483.273,
        VC_MA: -9998.0,
        QE_MA: 6180.959,
        VE_MA: -9998.0,
        QA_01: 10286.553,
        VA_01: -9998.0,
        QC_01: 10286.553,
        VC_01: -9998.0,
        QE_01: 8893.997,
        VE_01: -9998.0,
        QA_02: 10874.826,
        VA_02: -9998.0,
        QC_02: 10874.826,
        VC_02: -9998.0,
        QE_02: 9034.86,
        VE_02: -9998.0,
        QA_03: 11906.701,
        VA_03: -9998.0,
        QC_03: 11906.701,
        VC_03: -9998.0,
        QE_03: 9657.454,
        VE_03: -9998.0,
        QA_04: 8009.225,
        VA_04: -9998.0,
        QC_04: 8009.225,
        VC_04: -9998.0,
        QE_04: 7758.05,
        VE_04: -9998.0,
        QA_05: 5349.412,
        VA_05: -9998.0,
        QC_05: 5349.412,
        VC_05: -9998.0,
        QE_05: 6304.744,
        VE_05: -9998.0,
        QA_06: 3185.508,
        VA_06: -9998.0,
        QC_06: 3185.508,
        VC_06: -9998.0,
        QE_06: 5409.318,
        VE_06: -9998.0,
        QA_07: 2010.76,
        VA_07: -9998.0,
        QC_07: 2010.76,
        VC_07: -9998.0,
        QE_07: 4086.641,
        VE_07: -9998.0,
        QA_08: 1444.721,
        VA_08: -9998.0,
        QC_08: 1444.721,
        VC_08: -9998.0,
        QE_08: 3739.836,
        VE_08: -9998.0,
        QA_09: 1294.891,
        VA_09: -9998.0,
        QC_09: 1294.891,
        VC_09: -9998.0,
        QE_09: 3431.736,
        VE_09: -9998.0,
        QA_10: 1352.565,
        VA_10: -9998.0,
        QC_10: 1352.565,
        VC_10: -9998.0,
        QE_10: 4672.158,
        VE_10: -9998.0,
        QA_11: 3299.061,
        VA_11: -9998.0,
        QC_11: 3299.061,
        VC_11: -9998.0,
        QE_11: 4745.154,
        VE_11: -9998.0,
        QA_12: 6822.911,
        VA_12: -9998.0,
        QC_12: 6822.911,
        VC_12: -9998.0,
        QE_12: 6531.572,
        VE_12: -9998.0,
        LakeFract: 0.0568311345,
        SurfArea: 2577201.7812100002,
        RAreaHLoad: 0.17039880092000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.894641118208654, 34.493737079792254, 0.0],
          [-80.898759318202224, 34.487210679802388, 0.0],
          [-80.894021984876247, 34.479935213146973, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283794.0,
        COMID: 9751492,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012515",
        GNIS_NAME: "Johns River",
        LENGTHKM: 1.837,
        REACHCODE: "03050101000213",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017903836171879,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060796.0,
        ToNode: 250060801.0,
        Hydroseq: 250021704.0,
        LevelPathI: 250015617.0,
        Pathlength: 668.118,
        TerminalPa: 250002604.0,
        ArbolateSu: 140.82,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250022143.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021303.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.3949,
        TotDASqKM: 132.7923,
        DivDASqKM: 132.7923,
        Tidal: 0,
        TOTMA: 0.057020332003099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35186.0,
        MAXELEVSMO: 35801.0,
        MINELEVSMO: 35280.0,
        SLOPE: 0.00283614,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8370000123977659,
        QA_MA: 84.333,
        VA_MA: 1.22191,
        QC_MA: 85.986,
        VC_MA: 1.22335,
        QE_MA: 85.986,
        VE_MA: 1.22335,
        QA_01: 121.31,
        VA_01: 1.41645,
        QC_01: 97.012,
        VC_01: 1.28388,
        QE_01: 97.012,
        VE_01: 1.28388,
        QA_02: 131.412,
        VA_02: 1.46452,
        QC_02: 112.089,
        VC_02: 1.36165,
        QE_02: 112.089,
        VE_02: 1.36165,
        QA_03: 157.063,
        VA_03: 1.57934,
        QC_03: 135.344,
        VC_03: 1.47255,
        QE_03: 135.344,
        VE_03: 1.47255,
        QA_04: 129.621,
        VA_04: 1.45613,
        QC_04: 121.448,
        VC_04: 1.40747,
        QE_04: 121.448,
        VE_04: 1.40747,
        QA_05: 97.982,
        VA_05: 1.29765,
        QC_05: 93.734,
        VC_05: 1.26624,
        QE_05: 93.734,
        VE_05: 1.26624,
        QA_06: 61.242,
        VA_06: 1.07918,
        QC_06: 77.234,
        VC_06: 1.17265,
        QE_06: 77.234,
        VE_06: 1.17265,
        QA_07: 36.986,
        VA_07: 0.8981,
        QC_07: 56.591,
        VC_07: 1.04109,
        QE_07: 56.591,
        VE_07: 1.04109,
        QA_08: 25.836,
        VA_08: 0.79581,
        QC_08: 57.201,
        VC_08: 1.04528,
        QE_08: 57.201,
        VE_08: 1.04528,
        QA_09: 29.148,
        VA_09: 0.82805,
        QC_09: 76.818,
        VC_09: 1.17018,
        QE_09: 76.818,
        VE_09: 1.17018,
        QA_10: 41.652,
        VA_10: 0.9365,
        QC_10: 108.19,
        VC_10: 1.34204,
        QE_10: 108.19,
        VE_10: 1.34204,
        QA_11: 84.783,
        VA_11: 1.22449,
        QC_11: 112.983,
        VC_11: 1.36611,
        QE_11: 112.983,
        VE_11: 1.36611,
        QA_12: 95.526,
        VA_12: 1.28441,
        QC_12: 83.71,
        VC_12: 1.21041,
        QE_12: 83.71,
        VE_12: 1.21041,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.688102916977073, 35.976855877490152, 0.0],
          [-81.689252783641791, 35.97099281083257, 0.0],
          [-81.69161898363825, 35.970785410832832, 0.0],
          [-81.693193916969108, 35.967807410837565, 0.0],
          [-81.696320183630917, 35.967233010838356, 0.0],
          [-81.697674516962195, 35.969361810835096, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286885.0,
        COMID: 9736402,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.046,
        REACHCODE: "03050103000173",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019490986454612998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059960.0,
        ToNode: 250059961.0,
        Hydroseq: 250005921.0,
        LevelPathI: 250003258.0,
        Pathlength: 401.781,
        TerminalPa: 250002604.0,
        ArbolateSu: 6809.026,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005942.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005902.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2131,
        TotDASqKM: 9181.9728,
        DivDASqKM: 9181.9728,
        Tidal: 0,
        TOTMA: 0.060410486236200003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13606.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.0460000038146968,
        QA_MA: 4619.186,
        VA_MA: 1.27494,
        QC_MA: 4619.186,
        VC_MA: 1.27494,
        QE_MA: 5320.939,
        VE_MA: 1.28607,
        QA_01: 8401.55,
        VA_01: 1.63631,
        QC_01: 8401.55,
        VC_01: 1.63631,
        QE_01: 6966.494,
        VE_01: 1.43647,
        QA_02: 8884.959,
        VA_02: 1.67635,
        QC_02: 8884.959,
        VC_02: 1.67635,
        QE_02: 7074.146,
        VE_02: 1.44569,
        QA_03: 9765.976,
        VA_03: 1.74678,
        QC_03: 9765.976,
        VC_03: 1.74678,
        QE_03: 7530.808,
        VE_03: 1.4841,
        QA_04: 6781.146,
        VA_04: 1.4935,
        QC_04: 6781.146,
        VC_04: 1.4935,
        QE_04: 6560.049,
        VE_04: 1.40103,
        QA_05: 4665.865,
        VA_05: 1.28011,
        QC_05: 4665.865,
        VC_05: 1.28011,
        QE_05: 5651.449,
        VE_05: 1.31786,
        QA_06: 2746.765,
        VA_06: 1.04181,
        QC_06: 2746.765,
        VC_06: 1.04181,
        QE_06: 4985.384,
        VE_06: 1.25283,
        QA_07: 1698.117,
        VA_07: 0.87652,
        QC_07: 1698.117,
        VC_07: 0.87652,
        QE_07: 3789.42,
        VE_07: 1.12482,
        QA_08: 1206.907,
        VA_08: 0.78231,
        QC_08: 1206.907,
        VC_08: 0.78231,
        QE_08: 3544.314,
        VE_08: 1.09634,
        QA_09: 1107.106,
        VA_09: 0.76108,
        QC_09: 1107.106,
        VC_09: 0.76108,
        QE_09: 3251.153,
        VE_09: 1.06104,
        QA_10: 1203.858,
        VA_10: 0.78167,
        QC_10: 1203.858,
        VC_10: 0.78167,
        QE_10: 4446.61,
        VE_10: 1.19719,
        QA_11: 3129.772,
        VA_11: 1.09445,
        QC_11: 3129.772,
        VC_11: 1.09445,
        QE_11: 4505.986,
        VE_11: 1.20347,
        QA_12: 5869.673,
        VA_12: 1.40606,
        QC_12: 5869.673,
        VC_12: 1.40606,
        QE_12: 5578.514,
        VE_12: 1.31092,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.897598384870719, 34.818788612621063, 0.0],
          [-80.891641118213343, 34.807421412638689, 0.0],
          [-80.886772384887479, 34.803257012645133, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283963.0,
        COMID: 9754726,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.722,
        REACHCODE: "03050101000376",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0073586706884600003,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061691.0,
        ToNode: 250061688.0,
        Hydroseq: 250017819.0,
        LevelPathI: 250003258.0,
        Pathlength: 686.645,
        TerminalPa: 250002604.0,
        ArbolateSu: 399.128,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018069.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017589.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3554,
        TotDASqKM: 419.0949,
        DivDASqKM: 419.0949,
        Tidal: 0,
        TOTMA: 0.028083543813299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36959.0,
        MAXELEVSMO: 37100.0,
        MINELEVSMO: 37095.0,
        SLOPE: 6.925e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.72200000286102295,
        QA_MA: 278.752,
        VA_MA: 0.97009,
        QC_MA: 271.328,
        VC_MA: 0.96865,
        QE_MA: 312.491,
        VE_MA: 0.97624,
        QA_01: 439.95,
        VA_01: 1.15153,
        QC_01: 340.15,
        VC_01: 1.05286,
        QE_01: 368.033,
        VE_01: 1.03686,
        QA_02: 463.531,
        VA_02: 1.17524,
        QC_02: 382.02,
        VC_02: 1.10019,
        QE_02: 429.384,
        VE_02: 1.09901,
        QA_03: 523.529,
        VA_03: 1.23311,
        QC_03: 433.556,
        VC_03: 1.15523,
        QE_03: 459.418,
        VE_03: 1.12791,
        QA_04: 395.116,
        VA_04: 1.10476,
        QC_04: 360.942,
        VC_04: 1.07669,
        QE_04: 414.566,
        VE_04: 1.0844,
        QA_05: 317.006,
        VA_05: 1.01686,
        QC_05: 290.229,
        VC_05: 0.99268,
        QE_05: 328.431,
        VE_05: 0.99412,
        QA_06: 182.472,
        VA_06: 0.83677,
        QC_06: 226.699,
        VC_06: 0.90856,
        QE_06: 274.362,
        VE_06: 0.93165,
        QA_07: 105.632,
        VA_07: 0.70365,
        QC_07: 157.616,
        VC_07: 0.80322,
        QE_07: 206.174,
        VE_07: 0.84392,
        QA_08: 71.904,
        VA_08: 0.63064,
        QC_08: 162.488,
        VC_08: 0.81128,
        QE_08: 241.395,
        VE_08: 0.8907,
        QA_09: 82.625,
        VA_09: 0.65532,
        QC_09: 192.74,
        VC_09: 0.859,
        QE_09: 208.049,
        VE_09: 0.8465,
        QA_10: 126.893,
        VA_10: 0.74408,
        QC_10: 263.427,
        VC_10: 0.95837,
        QE_10: 237.904,
        VE_10: 0.88621,
        QA_11: 283.326,
        VA_11: 0.97584,
        QC_11: 283.541,
        VC_11: 0.98426,
        QE_11: 266.818,
        VE_11: 0.9225,
        QA_12: 355.025,
        VA_12: 1.06078,
        QC_12: 276.381,
        VC_12: 0.97515,
        QE_12: 288.535,
        VE_12: 0.94855,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.049065183083385, 35.703897277913882, 0.0],
          [-82.046928716420041, 35.706987411242324, 0.0],
          [-82.043868583091466, 35.706825411242619, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286886.0,
        COMID: 9736388,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.152,
        REACHCODE: "03050103000174",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010590984901675,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059953.0,
        ToNode: 250059960.0,
        Hydroseq: 250005942.0,
        LevelPathI: 250003258.0,
        Pathlength: 403.827,
        TerminalPa: 250002604.0,
        ArbolateSu: 6793.388,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005960.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005921.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4509,
        TotDASqKM: 9168.2496,
        DivDASqKM: 9168.2496,
        Tidal: 0,
        TOTMA: 0.031805877334100001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13539.0,
        MAXELEVSMO: 13651.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.736e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.151999950408936,
        QA_MA: 4613.754,
        VA_MA: 1.3632,
        QC_MA: 4613.754,
        VC_MA: 1.3632,
        QE_MA: 5315.502,
        VE_MA: 1.37536,
        QA_01: 8389.561,
        VA_01: 1.75738,
        QC_01: 8389.561,
        VC_01: 1.75738,
        QE_01: 6954.531,
        VE_01: 1.53904,
        QA_02: 8872.595,
        VA_02: 1.8011,
        QC_02: 8872.595,
        VC_02: 1.8011,
        QE_02: 7061.809,
        VE_02: 1.54908,
        QA_03: 9752.691,
        VA_03: 1.87797,
        QC_03: 9752.691,
        VC_03: 1.87797,
        QE_03: 7517.564,
        VE_03: 1.59097,
        QA_04: 6773.395,
        VA_04: 1.60174,
        QC_04: 6773.395,
        VC_04: 1.60174,
        QE_04: 6552.308,
        VE_04: 1.50071,
        QA_05: 4661.539,
        VA_05: 1.36898,
        QC_05: 4661.539,
        VC_05: 1.36898,
        QE_05: 5647.122,
        VE_05: 1.41021,
        QA_06: 2743.997,
        VA_06: 1.10885,
        QC_06: 2743.997,
        VC_06: 1.10885,
        QE_06: 4982.59,
        VE_06: 1.33934,
        QA_07: 1696.105,
        VA_07: 0.9284,
        QC_07: 1696.105,
        VC_07: 0.9284,
        QE_07: 3787.385,
        VE_07: 1.19962,
        QA_08: 1205.431,
        VA_08: 0.82557,
        QC_08: 1205.431,
        VC_08: 0.82557,
        QE_08: 3542.807,
        VE_08: 1.16858,
        QA_09: 1105.999,
        VA_09: 0.80246,
        QC_09: 1105.999,
        VC_09: 0.80246,
        QE_09: 3250.02,
        VE_09: 1.13007,
        QA_10: 1202.936,
        VA_10: 0.825,
        QC_10: 1202.936,
        VC_10: 0.825,
        QE_10: 4445.645,
        VE_10: 1.27877,
        QA_11: 3128.849,
        VA_11: 1.16663,
        QC_11: 3128.849,
        VC_11: 1.16663,
        QE_11: 4505.042,
        VE_11: 1.28564,
        QA_12: 5863.396,
        VA_12: 1.50636,
        QC_12: 5863.396,
        VC_12: 1.50636,
        QE_12: 5572.249,
        VE_12: 1.40243,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.89685731820515, 34.828470012606033, 0.0],
          [-80.899026784868454, 34.824270479279107, 0.0],
          [-80.897598384870719, 34.818788612621063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285837.0,
        COMID: 9756876,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.863,
        REACHCODE: "03050101002046",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.026236112219826,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061988.0,
        ToNode: 250061989.0,
        Hydroseq: 250008050.0,
        LevelPathI: 250003258.0,
        Pathlength: 475.712,
        TerminalPa: 250002604.0,
        ArbolateSu: 4338.035,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008080.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008024.0,
        FromMeas: 0.0,
        ToMeas: 91.72974,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.2288,
        TotDASqKM: 5320.5174,
        DivDASqKM: 5320.5174,
        Tidal: 0,
        TOTMA: 0.092837753514800003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17196.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.8629999160766602,
        QA_MA: 2894.154,
        VA_MA: 1.16647,
        QC_MA: 2894.154,
        VC_MA: 1.16647,
        QE_MA: 3090.007,
        VE_MA: 1.17103,
        QA_01: 4939.306,
        VA_01: 1.4481,
        QC_01: 4939.306,
        VC_01: 1.4481,
        QE_01: 5122.275,
        VE_01: 1.43659,
        QA_02: 5242.564,
        VA_02: 1.48473,
        QC_02: 5242.564,
        VC_02: 1.48473,
        QE_02: 5286.065,
        VE_02: 1.4556,
        QA_03: 5851.486,
        VA_03: 1.55541,
        QC_03: 5851.486,
        VC_03: 1.55541,
        QE_03: 6077.044,
        VE_03: 1.54377,
        QA_04: 4312.56,
        VA_04: 1.36887,
        QC_04: 4312.56,
        VC_04: 1.36887,
        QE_04: 4489.5,
        VE_04: 1.3603,
        QA_05: 3122.997,
        VA_05: 1.20185,
        QC_05: 3122.997,
        VC_05: 1.20185,
        QE_05: 3349.682,
        VE_05: 1.20879,
        QA_06: 1829.024,
        VA_06: 0.98089,
        QC_06: 1829.024,
        VC_06: 0.98089,
        QE_06: 2113.513,
        VE_06: 1.01347,
        QA_07: 1100.134,
        VA_07: 0.8218,
        QC_07: 1100.134,
        VC_07: 0.8218,
        QE_07: 1270.683,
        VE_07: 0.84654,
        QA_08: 772.236,
        VA_08: 0.73384,
        QC_08: 772.236,
        VC_08: 0.73384,
        QE_08: 1099.658,
        VE_08: 0.80678,
        QA_09: 731.891,
        VA_09: 0.72189,
        QC_09: 1322.417,
        VC_09: 0.8745,
        QE_09: 1373.922,
        VE_09: 0.86933,
        QA_10: 863.296,
        VA_10: 0.75978,
        QC_10: 863.296,
        VC_10: 0.75978,
        QE_10: 837.509,
        VE_10: 0.73968,
        QA_11: 2310.64,
        VA_11: 1.06977,
        QC_11: 2310.64,
        VC_11: 1.06977,
        QE_11: 2265.922,
        VE_11: 1.04003,
        QA_12: 3673.404,
        VA_12: 1.28228,
        QC_12: 3673.404,
        VC_12: 1.28228,
        QE_12: 3669.467,
        VE_12: 1.25346,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.011679718026983, 35.277566478575523, 0.0],
          [-81.015152118021604, 35.267222278591476, 0.0],
          [-81.011866118026674, 35.253361211946469, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283964.0,
        COMID: 9754728,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.103,
        REACHCODE: "03050101000377",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00091775935709300001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061693.0,
        ToNode: 250061691.0,
        Hydroseq: 250018069.0,
        LevelPathI: 250003258.0,
        Pathlength: 687.367,
        TerminalPa: 250002604.0,
        ArbolateSu: 398.406,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018324.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250017820.0,
        DnDrainCou: 2,
        DnHydroseq: 250017819.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 417.7395,
        DivDASqKM: 417.7395,
        Tidal: 0,
        TOTMA: 0.0028346245288100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37100.0,
        MAXELEVSMO: 37114.0,
        MINELEVSMO: 37100.0,
        SLOPE: 0.00135922,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 277.958,
        VA_MA: 1.36989,
        QC_MA: 270.586,
        VC_MA: 1.36758,
        QE_MA: 311.71,
        VE_MA: 1.37979,
        QA_01: 438.626,
        VA_01: 1.66084,
        QC_01: 339.153,
        VC_01: 1.50255,
        QE_01: 367.021,
        VE_01: 1.47687,
        QA_02: 462.117,
        VA_02: 1.69883,
        QC_02: 380.886,
        VC_02: 1.57845,
        QE_02: 428.247,
        VE_02: 1.57663,
        QA_03: 521.9,
        VA_03: 1.79161,
        QC_03: 432.252,
        VC_03: 1.66669,
        QE_03: 458.073,
        VE_03: 1.62279,
        QA_04: 393.906,
        VA_04: 1.58577,
        QC_04: 359.862,
        VC_04: 1.54073,
        QE_04: 413.447,
        VE_04: 1.55316,
        QA_05: 316.085,
        VA_05: 1.44488,
        QC_05: 289.417,
        VC_05: 1.40611,
        QE_05: 327.565,
        VE_05: 1.40839,
        QA_06: 181.97,
        VA_06: 1.15606,
        QC_06: 226.083,
        VC_06: 1.27119,
        QE_06: 273.691,
        VE_06: 1.30829,
        QA_07: 105.332,
        VA_07: 0.94247,
        QC_07: 157.178,
        VC_07: 1.10218,
        QE_07: 205.706,
        VE_07: 1.16761,
        QA_08: 71.703,
        VA_08: 0.82535,
        QC_08: 162.026,
        VC_08: 1.11509,
        QE_08: 240.901,
        VE_08: 1.24278,
        QA_09: 82.479,
        VA_09: 0.86525,
        QC_09: 192.44,
        VC_09: 1.19225,
        QE_09: 207.711,
        VE_09: 1.17205,
        QA_10: 126.784,
        VA_10: 1.00806,
        QC_10: 263.245,
        VC_10: 1.35223,
        QE_10: 237.701,
        VE_10: 1.23617,
        QA_11: 282.607,
        VA_11: 1.37928,
        QC_11: 282.992,
        VC_11: 1.3931,
        QE_11: 266.24,
        VE_11: 1.29374,
        QA_12: 353.978,
        VA_12: 1.5153,
        QC_12: 275.639,
        VC_12: 1.37804,
        QE_12: 287.767,
        VE_12: 1.33527,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.049121983083353, 35.702981277915228, 0.0],
          [-82.049065183083385, 35.703897277913882, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285838.0,
        COMID: 9756872,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.257,
        REACHCODE: "03050101002046",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.002538156517652,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061986.0,
        ToNode: 250061988.0,
        Hydroseq: 250008080.0,
        LevelPathI: 250003258.0,
        Pathlength: 478.575,
        TerminalPa: 250002604.0,
        ArbolateSu: 4329.106,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008111.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008050.0,
        FromMeas: 91.72974,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0648,
        TotDASqKM: 5301.7524,
        DivDASqKM: 5301.7524,
        Tidal: 0,
        TOTMA: 0.0083380154691799994,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17256.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25699999928474399,
        QA_MA: 2887.248,
        VA_MA: 1.16585,
        QC_MA: 2887.248,
        VC_MA: 1.16585,
        QE_MA: 3083.092,
        VE_MA: 1.17042,
        QA_01: 4924.744,
        VA_01: 1.44694,
        QC_01: 4924.744,
        VC_01: 1.44694,
        QE_01: 5107.774,
        VE_01: 1.43543,
        QA_02: 5226.395,
        VA_02: 1.48345,
        QC_02: 5226.395,
        VC_02: 1.48345,
        QE_02: 5269.969,
        VE_02: 1.4543,
        QA_03: 5834.174,
        VA_03: 1.55414,
        QC_03: 5834.174,
        VC_03: 1.55414,
        QE_03: 6059.836,
        VE_03: 1.54251,
        QA_04: 4302.609,
        VA_04: 1.36815,
        QC_04: 4302.609,
        VC_04: 1.36815,
        QE_04: 4479.579,
        VE_04: 1.35957,
        QA_05: 3117.274,
        VA_05: 1.20147,
        QC_05: 3117.274,
        VC_05: 1.20147,
        QE_05: 3343.951,
        VE_05: 1.20841,
        QA_06: 1825.532,
        VA_06: 0.98058,
        QC_06: 1825.532,
        VC_06: 0.98058,
        QE_06: 2109.949,
        VE_06: 1.01318,
        QA_07: 1097.863,
        VA_07: 0.82152,
        QC_07: 1097.863,
        VC_07: 0.82152,
        QE_07: 1268.342,
        VE_07: 0.84627,
        QA_08: 770.47,
        VA_08: 0.73356,
        QC_08: 770.47,
        VC_08: 0.73356,
        QE_08: 1097.805,
        VE_08: 0.80657,
        QA_09: 730.377,
        VA_09: 0.72166,
        QC_09: 1320.002,
        VC_09: 0.87426,
        QE_09: 1371.438,
        VE_09: 0.86906,
        QA_10: 862.035,
        VA_10: 0.75968,
        QC_10: 862.035,
        VC_10: 0.75968,
        QE_10: 836.15,
        VE_10: 0.73951,
        QA_11: 2309.379,
        VA_11: 1.06996,
        QC_11: 2309.379,
        VC_11: 1.06996,
        QE_11: 2264.635,
        VE_11: 1.04016,
        QA_12: 3665.631,
        VA_12: 1.28172,
        QC_12: 3665.631,
        VC_12: 1.28172,
        QE_12: 3661.732,
        VE_12: 1.25286,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.009904784696346, 35.279306878572811, 0.0],
          [-81.011679718026983, 35.277566478575523, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291140.0,
        COMID: 9718293,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.897,
        REACHCODE: "03050104002433",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0088397892805720003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058174.0,
        ToNode: 250058178.0,
        Hydroseq: 250004126.0,
        LevelPathI: 250003258.0,
        Pathlength: 356.946,
        TerminalPa: 250002604.0,
        ArbolateSu: 8709.447,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004142.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004114.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7533,
        TotDASqKM: 11766.2364,
        DivDASqKM: 11766.2364,
        Tidal: 0,
        TOTMA: 0.45293641963999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6784.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.89700001478195202,
        QA_MA: 5619.143,
        VA_MA: -9998.0,
        QC_MA: 5619.143,
        VC_MA: -9998.0,
        QE_MA: 6316.993,
        VE_MA: -9998.0,
        QA_01: 10584.731,
        VA_01: -9998.0,
        QC_01: 10584.731,
        VC_01: -9998.0,
        QE_01: 9191.469,
        VE_01: -9998.0,
        QA_02: 11202.191,
        VA_02: -9998.0,
        QC_02: 11202.191,
        VC_02: -9998.0,
        QE_02: 9361.495,
        VE_02: -9998.0,
        QA_03: 12261.098,
        VA_03: -9998.0,
        QC_03: 12261.098,
        VC_03: -9998.0,
        QE_03: 10010.743,
        VE_03: -9998.0,
        QA_04: 8202.864,
        VA_04: -9998.0,
        QC_04: 8202.864,
        VC_04: -9998.0,
        QE_04: 7951.419,
        VE_04: -9998.0,
        QA_05: 5458.196,
        VA_05: -9998.0,
        QC_05: 5458.196,
        VC_05: -9998.0,
        QE_05: 6413.546,
        VE_05: -9998.0,
        QA_06: 3255.65,
        VA_06: -9998.0,
        QC_06: 3255.65,
        VC_06: -9998.0,
        QE_06: 5480.154,
        VE_06: -9998.0,
        QA_07: 2060.951,
        VA_07: -9998.0,
        QC_07: 2060.951,
        VC_07: -9998.0,
        QE_07: 4137.459,
        VE_07: -9998.0,
        QA_08: 1484.874,
        VA_08: -9998.0,
        QC_08: 1484.874,
        VC_08: -9998.0,
        QE_08: 3780.857,
        VE_08: -9998.0,
        QA_09: 1325.005,
        VA_09: -9998.0,
        QC_09: 1325.005,
        VC_09: -9998.0,
        QE_09: 3462.553,
        VE_09: -9998.0,
        QA_10: 1377.661,
        VA_10: -9998.0,
        QC_10: 1377.661,
        VC_10: -9998.0,
        QE_10: 4698.406,
        VE_10: -9998.0,
        QA_11: 3319.138,
        VA_11: -9998.0,
        QC_11: 3319.138,
        VC_11: -9998.0,
        QE_11: 4765.828,
        VE_11: -9998.0,
        QA_12: 6935.989,
        VA_12: -9998.0,
        QC_12: 6935.989,
        VC_12: -9998.0,
        QE_12: 6644.324,
        VE_12: -9998.0,
        LakeFract: 0.02892798765,
        SurfArea: 1311838.34274,
        RAreaHLoad: 0.08486825342,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887844518219211, 34.468123613165289, 0.0],
          [-80.881522784895708, 34.462134479841268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283965.0,
        COMID: 9754740,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.88,
        REACHCODE: "03050101000378",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0089867898980700005,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061695.0,
        ToNode: 250061693.0,
        Hydroseq: 250018324.0,
        LevelPathI: 250003258.0,
        Pathlength: 687.47,
        TerminalPa: 250002604.0,
        ArbolateSu: 397.756,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018595.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018069.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6516,
        TotDASqKM: 417.5559,
        DivDASqKM: 417.5559,
        Tidal: 0,
        TOTMA: 0.032811895780600002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37114.0,
        MAXELEVSMO: 37123.0,
        MINELEVSMO: 37114.0,
        SLOPE: 0.00010227,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.87999999523162797,
        QA_MA: 277.849,
        VA_MA: 1.01185,
        QC_MA: 270.484,
        VC_MA: 1.01032,
        QE_MA: 311.602,
        VE_MA: 1.01841,
        QA_01: 438.446,
        VA_01: 1.20464,
        QC_01: 339.017,
        VC_01: 1.09975,
        QE_01: 366.883,
        VE_01: 1.08273,
        QA_02: 461.924,
        VA_02: 1.22982,
        QC_02: 380.731,
        VC_02: 1.15004,
        QE_02: 428.092,
        VE_02: 1.14884,
        QA_03: 521.678,
        VA_03: 1.2913,
        QC_03: 432.074,
        VC_03: 1.20852,
        QE_03: 457.89,
        VE_03: 1.17942,
        QA_04: 393.741,
        VA_04: 1.1549,
        QC_04: 359.715,
        VC_04: 1.12504,
        QE_04: 413.294,
        VE_04: 1.13329,
        QA_05: 315.956,
        VA_05: 1.06153,
        QC_05: 289.304,
        VC_05: 1.03584,
        QE_05: 327.445,
        VE_05: 1.03735,
        QA_06: 181.901,
        VA_06: 0.87014,
        QC_06: 225.999,
        VC_06: 0.94644,
        QE_06: 273.598,
        VE_06: 0.97102,
        QA_07: 105.29,
        VA_07: 0.72859,
        QC_07: 157.117,
        VC_07: 0.83443,
        QE_07: 205.641,
        VE_07: 0.8778,
        QA_08: 71.676,
        VA_08: 0.65098,
        QC_08: 161.964,
        VC_08: 0.84298,
        QE_08: 240.834,
        VE_08: 0.92763,
        QA_09: 82.459,
        VA_09: 0.67745,
        QC_09: 192.399,
        VC_09: 0.89417,
        QE_09: 207.666,
        VE_09: 0.88077,
        QA_10: 126.769,
        VA_10: 0.77213,
        QC_10: 263.221,
        VC_10: 1.00024,
        QE_10: 237.674,
        VE_10: 0.9233,
        QA_11: 282.506,
        VA_11: 1.01808,
        QC_11: 282.915,
        VC_11: 1.02727,
        QE_11: 266.159,
        VE_11: 0.96139,
        QA_12: 353.832,
        VA_12: 1.1082,
        QC_12: 275.536,
        VC_12: 1.01725,
        QE_12: 287.661,
        VE_12: 0.98889,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.04864851641743, 35.69865301125526, 0.0],
          [-82.052325516411656, 35.699387811254098, 0.0],
          [-82.049121983083353, 35.702981277915228, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283966.0,
        COMID: 9754748,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.685,
        REACHCODE: "03050101000379",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0065447855355069999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061698.0,
        ToNode: 250061695.0,
        Hydroseq: 250018595.0,
        LevelPathI: 250003258.0,
        Pathlength: 688.35,
        TerminalPa: 250002604.0,
        ArbolateSu: 396.876,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018881.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250018325.0,
        DnDrainCou: 2,
        DnHydroseq: 250018324.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.477,
        TotDASqKM: 416.9043,
        DivDASqKM: 416.9043,
        Tidal: 0,
        TOTMA: 0.015791694406899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37123.0,
        MAXELEVSMO: 37502.0,
        MINELEVSMO: 37123.0,
        SLOPE: 0.00553284,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68500000238418601,
        QA_MA: 277.459,
        VA_MA: 1.63476,
        QC_MA: 270.119,
        VC_MA: 1.63188,
        QE_MA: 311.219,
        VE_MA: 1.64715,
        QA_01: 437.801,
        VA_01: 1.99822,
        QC_01: 338.532,
        VC_01: 1.80044,
        QE_01: 366.389,
        VE_01: 1.76834,
        QA_02: 461.233,
        VA_02: 2.04566,
        QC_02: 380.177,
        VC_02: 1.89524,
        QE_02: 427.537,
        VE_02: 1.89303,
        QA_03: 520.887,
        VA_03: 2.16156,
        QC_03: 431.441,
        VC_03: 2.00549,
        QE_03: 457.237,
        VE_03: 1.95057,
        QA_04: 393.152,
        VA_04: 1.9044,
        QC_04: 359.189,
        VC_04: 1.84811,
        QE_04: 412.749,
        VE_04: 1.86369,
        QA_05: 315.501,
        VA_05: 1.72841,
        QC_05: 288.903,
        VC_05: 1.67998,
        QE_05: 327.017,
        VE_05: 1.68282,
        QA_06: 181.654,
        VA_06: 1.36761,
        QC_06: 225.696,
        VC_06: 1.51145,
        QE_06: 273.269,
        VE_06: 1.55783,
        QA_07: 105.141,
        VA_07: 1.10069,
        QC_07: 156.9,
        VC_07: 1.30023,
        QE_07: 205.409,
        VE_07: 1.3821,
        QA_08: 71.58,
        VA_08: 0.95437,
        QC_08: 161.742,
        VC_08: 1.31637,
        QE_08: 240.597,
        VE_08: 1.47614,
        QA_09: 82.389,
        VA_09: 1.00446,
        QC_09: 192.255,
        VC_09: 1.41324,
        QE_09: 207.503,
        VE_09: 1.3879,
        QA_10: 126.717,
        VA_10: 1.18324,
        QC_10: 263.133,
        VC_10: 1.61359,
        QE_10: 237.576,
        VE_10: 1.46833,
        QA_11: 282.137,
        VA_11: 1.64658,
        QC_11: 282.632,
        VC_11: 1.66409,
        QE_11: 265.862,
        VE_11: 1.53973,
        QA_12: 353.31,
        VA_12: 1.81637,
        QC_12: 275.166,
        VC_12: 1.64495,
        QE_12: 287.279,
        VE_12: 1.59144,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.046350983087677, 35.69352207792997, 0.0],
          [-82.045843516421783, 35.696017877925954, 0.0],
          [-82.04864851641743, 35.69865301125526, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285832.0,
        COMID: 9755534,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.967,
        REACHCODE: "03050101002040",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0094840757141499994,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061870.0,
        ToNode: 250061982.0,
        Hydroseq: 250008167.0,
        LevelPathI: 250003258.0,
        Pathlength: 482.057,
        TerminalPa: 250002604.0,
        ArbolateSu: 4039.785,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008196.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008139.0,
        FromMeas: 17.77842,
        ToMeas: 38.83589,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4057,
        TotDASqKM: 4837.0293,
        DivDASqKM: 4837.0293,
        Tidal: 0,
        TOTMA: 0.031809200571599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17179.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.96700000762939498,
        QA_MA: 2699.786,
        VA_MA: 1.14964,
        QC_MA: 2699.786,
        VC_MA: 1.14964,
        QE_MA: 2893.188,
        VE_MA: 1.15437,
        QA_01: 4543.929,
        VA_01: 1.41753,
        QC_01: 4543.929,
        VC_01: 1.41753,
        QE_01: 4723.006,
        VE_01: 1.40582,
        QA_02: 4811.965,
        VA_02: 1.4518,
        QC_02: 4811.965,
        VC_02: 1.4518,
        QE_02: 4854.663,
        VE_02: 1.42196,
        QA_03: 5387.559,
        VA_03: 1.5225,
        QC_03: 5387.559,
        VC_03: 1.5225,
        QE_03: 5615.48,
        VE_03: 1.51146,
        QA_04: 4027.356,
        VA_04: 1.34868,
        QC_04: 4027.356,
        VC_04: 1.34868,
        QE_04: 4207.233,
        VE_04: 1.34046,
        QA_05: 2954.362,
        VA_05: 1.19087,
        QC_05: 2954.362,
        VC_05: 1.19087,
        QE_05: 3176.746,
        VE_05: 1.19743,
        QA_06: 1728.263,
        VA_06: 0.97222,
        QC_06: 1728.263,
        VC_06: 0.97222,
        QE_06: 2008.922,
        VE_06: 1.0054,
        QA_07: 1034.027,
        VA_07: 0.81375,
        QC_07: 1034.027,
        VC_07: 0.81375,
        QE_07: 1203.17,
        VE_07: 0.8393,
        QA_08: 724.593,
        VA_08: 0.72679,
        QC_08: 724.593,
        VC_08: 0.72679,
        QE_08: 1049.314,
        VE_08: 0.80209,
        QA_09: 690.881,
        VA_09: 0.71634,
        QC_09: 1256.776,
        VC_09: 0.86891,
        QE_09: 1308.706,
        VE_09: 0.86354,
        QA_10: 828.763,
        VA_10: 0.75773,
        QC_10: 828.763,
        VC_10: 0.75773,
        QE_10: 796.371,
        VE_10: 0.73483,
        QA_11: 2247.125,
        VA_11: 1.07153,
        QC_11: 2247.125,
        VC_11: 1.07153,
        QE_11: 2194.251,
        VE_11: 1.03884,
        QA_12: 3436.509,
        VA_12: 1.26463,
        QC_12: 3436.509,
        VC_12: 1.26463,
        QE_12: 3432.357,
        VE_12: 1.23472,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.993378384722064, 35.313084211853777, 0.0],
          [-80.999796718045445, 35.306407411864086, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291142.0,
        COMID: 9718301,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.812,
        REACHCODE: "03050104002435",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0077442066927820002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058178.0,
        ToNode: 250058183.0,
        Hydroseq: 250004114.0,
        LevelPathI: 250003258.0,
        Pathlength: 356.134,
        TerminalPa: 250002604.0,
        ArbolateSu: 8714.336,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004126.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004102.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8261,
        TotDASqKM: 11770.2189,
        DivDASqKM: 11770.2189,
        Tidal: 0,
        TOTMA: 0.41001602309000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6633.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.81199997663497903,
        QA_MA: 5620.526,
        VA_MA: -9998.0,
        QC_MA: 5620.526,
        VC_MA: -9998.0,
        QE_MA: 6318.378,
        VE_MA: -9998.0,
        QA_01: 10587.76,
        VA_01: -9998.0,
        QC_01: 10587.76,
        VC_01: -9998.0,
        QE_01: 9194.491,
        VE_01: -9998.0,
        QA_02: 11205.488,
        VA_02: -9998.0,
        QC_02: 11205.488,
        VC_02: -9998.0,
        QE_02: 9364.784,
        VE_02: -9998.0,
        QA_03: 12264.71,
        VA_03: -9998.0,
        QC_03: 12264.71,
        VC_03: -9998.0,
        QE_03: 10014.343,
        VE_03: -9998.0,
        QA_04: 8204.807,
        VA_04: -9998.0,
        QC_04: 8204.807,
        VC_04: -9998.0,
        QE_04: 7953.359,
        VE_04: -9998.0,
        QA_05: 5459.283,
        VA_05: -9998.0,
        QC_05: 5459.283,
        VC_05: -9998.0,
        QE_05: 6414.632,
        VE_05: -9998.0,
        QA_06: 3256.377,
        VA_06: -9998.0,
        QC_06: 3256.377,
        VC_06: -9998.0,
        QE_06: 5480.888,
        VE_06: -9998.0,
        QA_07: 2061.487,
        VA_07: -9998.0,
        QC_07: 2061.487,
        VC_07: -9998.0,
        QE_07: 4138.001,
        VE_07: -9998.0,
        QA_08: 1485.303,
        VA_08: -9998.0,
        QC_08: 1485.303,
        VC_08: -9998.0,
        QE_08: 3781.294,
        VE_08: -9998.0,
        QA_09: 1325.327,
        VA_09: -9998.0,
        QC_09: 1325.327,
        VC_09: -9998.0,
        QE_09: 3462.882,
        VE_09: -9998.0,
        QA_10: 1377.929,
        VA_10: -9998.0,
        QC_10: 1377.929,
        VC_10: -9998.0,
        QE_10: 4698.686,
        VE_10: -9998.0,
        QA_11: 3319.353,
        VA_11: -9998.0,
        QC_11: 3319.353,
        VC_11: -9998.0,
        QE_11: 4766.049,
        VE_11: -9998.0,
        QA_12: 6937.122,
        VA_12: -9998.0,
        QC_12: 6937.122,
        VC_12: -9998.0,
        QE_12: 6645.454,
        VE_12: -9998.0,
        LakeFract: 0.02619250309,
        SurfArea: 1187788.4580699999,
        RAreaHLoad: 0.07682611123,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881522784895708, 34.462134479841268, 0.0],
          [-80.876904984902808, 34.455960213184198, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283959.0,
        COMID: 9754704,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.319,
        REACHCODE: "03050101000373",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.023969796585539001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061685.0,
        ToNode: 250061682.0,
        Hydroseq: 250016925.0,
        LevelPathI: 250003258.0,
        Pathlength: 682.343,
        TerminalPa: 250002604.0,
        ArbolateSu: 432.404,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250017138.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016712.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8441,
        TotDASqKM: 456.9435,
        DivDASqKM: 456.9435,
        Tidal: 0,
        TOTMA: 0.070383299226899995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36539.0,
        MAXELEVSMO: 36728.0,
        MINELEVSMO: 36618.0,
        SLOPE: 0.00047434,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.3190000057220459,
        QA_MA: 301.622,
        VA_MA: 1.22439,
        QC_MA: 292.692,
        VC_MA: 1.22216,
        QE_MA: 430.287,
        VE_MA: 1.25113,
        QA_01: 477.626,
        VA_01: 1.47761,
        QC_01: 368.485,
        VC_01: 1.34102,
        QE_01: 425.871,
        VE_01: 1.24598,
        QA_02: 503.442,
        VA_02: 1.51075,
        QC_02: 413.979,
        VC_02: 1.40686,
        QE_02: 424.956,
        VE_02: 1.24491,
        QA_03: 569.37,
        VA_03: 1.59194,
        QC_03: 470.215,
        VC_03: 1.48373,
        QE_03: 612.874,
        VE_03: 1.44592,
        QA_04: 429.262,
        VA_04: 1.41317,
        QC_04: 391.397,
        VC_04: 1.37463,
        QE_04: 530.169,
        VE_04: 1.36164,
        QA_05: 343.815,
        VA_05: 1.29034,
        QC_05: 313.819,
        VC_05: 1.25661,
        QE_05: 506.069,
        VE_05: 1.33594,
        QA_06: 196.94,
        VA_06: 1.03884,
        QC_06: 244.417,
        VC_06: 1.13876,
        QE_06: 441.503,
        VE_06: 1.2641,
        QA_07: 114.198,
        VA_07: 0.8553,
        QC_07: 170.079,
        VC_07: 0.99332,
        QE_07: 277.516,
        VE_07: 1.05528,
        QA_08: 77.481,
        VA_08: 0.75349,
        QC_08: 175.355,
        VC_08: 1.00453,
        QE_08: 288.698,
        VE_08: 1.07111,
        QA_09: 87.213,
        VA_09: 0.78236,
        QC_09: 202.161,
        VC_09: 1.05915,
        QE_09: 334.814,
        VE_09: 1.13355,
        QA_10: 132.32,
        VA_10: 0.89979,
        QC_10: 272.388,
        VC_10: 1.18794,
        QE_10: 349.628,
        VE_10: 1.15274,
        QA_11: 305.267,
        VA_11: 1.23025,
        QC_11: 300.137,
        VC_11: 1.23443,
        QE_11: 404.864,
        VE_11: 1.22113,
        QA_12: 384.707,
        VA_12: 1.35072,
        QC_12: 297.328,
        VC_12: 1.22982,
        QE_12: 387.071,
        VE_12: 1.19961,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.031253316444406, 35.715106811229703, 0.0],
          [-82.022437783124701, 35.714024077898102, 0.0],
          [-82.019456583129397, 35.717937611225352, 0.0],
          [-82.022994383123887, 35.718215211224845, 0.0],
          [-82.019087183129955, 35.721486811219847, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285833.0,
        COMID: 9756864,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.819,
        REACHCODE: "03050101002040",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0076241825575789996,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061982.0,
        ToNode: 250061984.0,
        Hydroseq: 250008139.0,
        LevelPathI: 250003258.0,
        Pathlength: 481.238,
        TerminalPa: 250002604.0,
        ArbolateSu: 4040.604,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008167.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008111.0,
        FromMeas: 0.0,
        ToMeas: 17.77842,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.232,
        TotDASqKM: 4839.2613,
        DivDASqKM: 4839.2613,
        Tidal: 0,
        TOTMA: 0.026939147451500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17259.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.81900000572204601,
        QA_MA: 2700.575,
        VA_MA: 1.14972,
        QC_MA: 2700.575,
        VC_MA: 1.14972,
        QE_MA: 2893.979,
        VE_MA: 1.15444,
        QA_01: 4545.539,
        VA_01: 1.41766,
        QC_01: 4545.539,
        VC_01: 1.41766,
        QE_01: 4724.608,
        VE_01: 1.40596,
        QA_02: 4813.838,
        VA_02: 1.45196,
        QC_02: 4813.838,
        VC_02: 1.45196,
        QE_02: 4856.528,
        VE_02: 1.42213,
        QA_03: 5389.585,
        VA_03: 1.52266,
        QC_03: 5389.585,
        VC_03: 1.52266,
        QE_03: 5617.494,
        VE_03: 1.51163,
        QA_04: 4028.523,
        VA_04: 1.34877,
        QC_04: 4028.523,
        VC_04: 1.34877,
        QE_04: 4208.396,
        VE_04: 1.34056,
        QA_05: 2955.024,
        VA_05: 1.19092,
        QC_05: 2955.024,
        VC_05: 1.19092,
        QE_05: 3177.41,
        VE_05: 1.19748,
        QA_06: 1728.679,
        VA_06: 0.97226,
        QC_06: 1728.679,
        VC_06: 0.97226,
        QE_06: 2009.347,
        VE_06: 1.00545,
        QA_07: 1034.298,
        VA_07: 0.81379,
        QC_07: 1034.298,
        VC_07: 0.81379,
        QE_07: 1203.449,
        VE_07: 0.83933,
        QA_08: 724.803,
        VA_08: 0.72683,
        QC_08: 724.803,
        VC_08: 0.72683,
        QE_08: 1049.535,
        VE_08: 0.80212,
        QA_09: 691.061,
        VA_09: 0.71637,
        QC_09: 1257.065,
        VC_09: 0.86895,
        QE_09: 1309.003,
        VE_09: 0.86357,
        QA_10: 828.913,
        VA_10: 0.75774,
        QC_10: 828.913,
        VC_10: 0.75774,
        QE_10: 796.533,
        VE_10: 0.73485,
        QA_11: 2247.276,
        VA_11: 1.07151,
        QC_11: 2247.276,
        VC_11: 1.07151,
        QE_11: 2194.404,
        VE_11: 1.03883,
        QA_12: 3437.273,
        VA_12: 1.26468,
        QC_12: 3437.273,
        VC_12: 1.26468,
        QE_12: 3433.116,
        VE_12: 1.23478,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.999796718045445, 35.306407411864086, 0.0],
          [-81.002749318040856, 35.299764611874366, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283960.0,
        COMID: 9755242,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.272,
        REACHCODE: "03050101000374",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "Connector",
        FCODE: 33400,
        Shape_Leng: 0.0030110573216100001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061811.0,
        ToNode: 250061689.0,
        Hydroseq: 250017360.0,
        LevelPathI: 250003258.0,
        Pathlength: 686.038,
        TerminalPa: 250002604.0,
        ArbolateSu: 426.653,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250017589.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017138.0,
        FromMeas: 83.34506,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0999,
        TotDASqKM: 446.508,
        DivDASqKM: 446.508,
        Tidal: 0,
        TOTMA: 0.0063211122116999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36906.0,
        MAXELEVSMO: 37035.0,
        MINELEVSMO: 36906.0,
        SLOPE: 0.00474264,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.27200001478195202,
        QA_MA: 295.198,
        VA_MA: 1.62251,
        QC_MA: 286.698,
        VC_MA: 1.6194,
        QE_MA: 328.647,
        VE_MA: 1.63398,
        QA_01: 467.177,
        VA_01: 1.98525,
        QC_01: 360.632,
        VC_01: 1.78925,
        QE_01: 388.844,
        VE_01: 1.75782,
        QA_02: 492.217,
        VA_02: 2.03239,
        QC_02: 404.998,
        VC_02: 1.88335,
        QE_02: 452.425,
        VE_02: 1.87919,
        QA_03: 556.548,
        VA_03: 2.14859,
        QC_03: 459.971,
        VC_03: 1.99348,
        QE_03: 486.647,
        VE_03: 1.9412,
        QA_04: 419.674,
        VA_04: 1.89244,
        QC_04: 382.851,
        VC_04: 1.83702,
        QE_04: 437.267,
        VE_04: 1.85102,
        QA_05: 336.325,
        VA_05: 1.71675,
        QC_05: 307.235,
        VC_05: 1.66846,
        QE_05: 346.536,
        VE_05: 1.67182,
        QA_06: 192.862,
        VA_06: 1.35665,
        QC_06: 239.424,
        VC_06: 1.49977,
        QE_06: 288.213,
        VE_06: 1.54472,
        QA_07: 111.797,
        VA_07: 1.09312,
        QC_07: 166.589,
        VC_07: 1.29106,
        QE_07: 215.761,
        VE_07: 1.36854,
        QA_08: 75.904,
        VA_08: 0.94727,
        QC_08: 171.714,
        VC_08: 1.307,
        QE_08: 251.268,
        VE_08: 1.45786,
        QA_09: 86.04,
        VA_09: 0.99124,
        QC_09: 199.758,
        VC_09: 1.39053,
        QE_09: 215.824,
        VE_09: 1.3687,
        QA_10: 130.984,
        VA_10: 1.16198,
        QC_10: 270.188,
        VC_10: 1.57875,
        QE_10: 245.107,
        VE_10: 1.44281,
        QA_11: 298.968,
        VA_11: 1.63139,
        QC_11: 295.402,
        VC_11: 1.64039,
        QE_11: 279.277,
        VE_11: 1.52422,
        QA_12: 376.045,
        VA_12: 1.80275,
        QC_12: 291.23,
        VC_12: 1.63036,
        QE_12: 303.896,
        VE_12: 1.58,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.040162116430565, 35.707212277908582, 0.0],
          [-82.037186383101869, 35.706752411242576, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286859.0,
        COMID: 9734078,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.682,
        REACHCODE: "03050103000148",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00694645823046,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059264.0,
        ToNode: 250059271.0,
        Hydroseq: 250022151.0,
        LevelPathI: 250005808.0,
        Pathlength: 411.217,
        TerminalPa: 250002604.0,
        ArbolateSu: 112.405,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250022621.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021712.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.531,
        TotDASqKM: 96.3594,
        DivDASqKM: 96.3594,
        Tidal: 0,
        TOTMA: 0.026243788599599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14527.0,
        MAXELEVSMO: 14614.0,
        MINELEVSMO: 14556.0,
        SLOPE: 0.00085043,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68199998140335105,
        QA_MA: 39.07,
        VA_MA: 0.9841,
        QC_MA: 41.043,
        VC_MA: 0.9868,
        QE_MA: 41.043,
        VE_MA: 0.9868,
        QA_01: 85.418,
        VA_01: 1.33203,
        QC_01: 68.94,
        VC_01: 1.20189,
        QE_01: 68.94,
        VE_01: 1.20189,
        QA_02: 88.502,
        VA_02: 1.35149,
        QC_02: 76.307,
        VC_02: 1.25138,
        QE_02: 76.307,
        VE_02: 1.25138,
        QA_03: 94.493,
        VA_03: 1.38841,
        QC_03: 82.803,
        VC_03: 1.29319,
        QE_03: 82.803,
        VE_03: 1.29319,
        QA_04: 54.412,
        VA_04: 1.11404,
        QC_04: 51.997,
        VC_04: 1.07761,
        QE_04: 51.997,
        VE_04: 1.07761,
        QA_05: 30.245,
        VA_05: 0.89821,
        QC_05: 30.234,
        VC_05: 0.88516,
        QE_05: 30.234,
        VE_05: 0.88516,
        QA_06: 19.434,
        VA_06: 0.77475,
        QC_06: 24.898,
        VC_06: 0.82866,
        QE_06: 24.898,
        VE_06: 0.82866,
        QA_07: 14.251,
        VA_07: 0.70392,
        QC_07: 22.308,
        VC_07: 0.79918,
        QE_07: 22.308,
        VE_07: 0.79918,
        QA_08: 10.365,
        VA_08: 0.6424,
        QC_08: 22.531,
        VC_08: 0.80178,
        QE_08: 22.531,
        VE_08: 0.80178,
        QA_09: 8.644,
        VA_09: 0.6117,
        QC_09: 26.265,
        VC_09: 0.84363,
        QE_09: 26.265,
        VE_09: 0.84363,
        QA_10: 6.478,
        VA_10: 0.56863,
        QC_10: 24.467,
        VC_10: 0.82386,
        QE_10: 24.467,
        VE_10: 0.82386,
        QA_11: 9.811,
        VA_11: 0.6328,
        QC_11: 21.815,
        VC_11: 0.79339,
        QE_11: 21.815,
        VE_11: 0.79339,
        QA_12: 47.043,
        VA_12: 1.05413,
        QC_12: 43.943,
        VC_12: 1.01185,
        QE_12: 43.943,
        VE_12: 1.01185,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.807793918343464, 34.830276612603143, 0.0],
          [-80.810296185006223, 34.827552079274028, 0.0],
          [-80.813018518335355, 34.827393079274373, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283961.0,
        COMID: 9754718,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.376,
        REACHCODE: "03050101000374",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013271095373176001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061689.0,
        ToNode: 250061685.0,
        Hydroseq: 250017138.0,
        LevelPathI: 250003258.0,
        Pathlength: 684.662,
        TerminalPa: 250002604.0,
        ArbolateSu: 428.029,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250017360.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016925.0,
        FromMeas: 0.0,
        ToMeas: 83.34506,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.6546,
        TotDASqKM: 453.1626,
        DivDASqKM: 453.1626,
        Tidal: 0,
        TOTMA: 0.036994588276000002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36687.0,
        MAXELEVSMO: 36906.0,
        MINELEVSMO: 36728.0,
        SLOPE: 0.0012936,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3760000467300419,
        QA_MA: 299.406,
        VA_MA: 1.38084,
        QC_MA: 290.625,
        VC_MA: 1.37826,
        QE_MA: 428.22,
        VE_MA: 1.41238,
        QA_01: 473.943,
        VA_01: 1.67704,
        QC_01: 365.717,
        VC_01: 1.51712,
        QE_01: 423.104,
        VE_01: 1.40536,
        QA_02: 499.474,
        VA_02: 1.71571,
        QC_02: 410.805,
        VC_02: 1.59409,
        QE_02: 421.782,
        VE_02: 1.40353,
        QA_03: 564.834,
        VA_03: 1.81067,
        QC_03: 466.591,
        VC_03: 1.68403,
        QE_03: 609.251,
        VE_03: 1.63969,
        QA_04: 425.902,
        VA_04: 1.60153,
        QC_04: 388.403,
        VC_04: 1.55637,
        QE_04: 527.175,
        VE_04: 1.54123,
        QA_05: 341.218,
        VA_05: 1.45793,
        QC_05: 311.537,
        VC_05: 1.41847,
        QE_05: 503.786,
        VE_05: 1.51188,
        QA_06: 195.531,
        VA_06: 1.16369,
        QC_06: 242.692,
        VC_06: 1.28061,
        QE_06: 439.777,
        VE_06: 1.4281,
        QA_07: 113.366,
        VA_07: 0.94873,
        QC_07: 168.87,
        VC_07: 1.11031,
        QE_07: 276.307,
        VE_07: 1.18323,
        QA_08: 76.922,
        VA_08: 0.82955,
        QC_08: 174.064,
        VC_08: 1.12331,
        QE_08: 287.408,
        VE_08: 1.20172,
        QA_09: 86.807,
        VA_09: 0.86411,
        QC_09: 201.328,
        VC_09: 1.18878,
        QE_09: 333.981,
        VE_09: 1.27588,
        QA_10: 132.015,
        VA_10: 1.00267,
        QC_10: 271.886,
        VC_10: 1.34106,
        QE_10: 349.126,
        VE_10: 1.29893,
        QA_11: 303.261,
        VA_11: 1.38815,
        QC_11: 298.632,
        VC_11: 1.39381,
        QE_11: 403.359,
        VE_11: 1.37787,
        QA_12: 381.778,
        VA_12: 1.52857,
        QC_12: 295.267,
        VC_12: 1.3873,
        QE_12: 385.01,
        VE_12: 1.35175,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.037186383101869, 35.706752411242576, 0.0],
          [-82.032581516442292, 35.707138677908688, 0.0],
          [-82.031253316444406, 35.715106811229703, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286860.0,
        COMID: 9734062,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.374,
        REACHCODE: "03050103000149",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003739345599796,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059258.0,
        ToNode: 250059264.0,
        Hydroseq: 250022621.0,
        LevelPathI: 250005808.0,
        Pathlength: 411.899,
        TerminalPa: 250002604.0,
        ArbolateSu: 110.746,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250023119.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022151.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4428,
        TotDASqKM: 95.0715,
        DivDASqKM: 95.0715,
        Tidal: 0,
        TOTMA: 0.013492741198200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14604.0,
        MAXELEVSMO: 14672.0,
        MINELEVSMO: 14614.0,
        SLOPE: 0.0015508,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37400001287460299,
        QA_MA: 38.556,
        VA_MA: 1.04955,
        QC_MA: 40.523,
        VC_MA: 1.05255,
        QE_MA: 40.523,
        VE_MA: 1.05255,
        QA_01: 84.292,
        VA_01: 1.43116,
        QC_01: 68.055,
        VC_01: 1.28838,
        QE_01: 68.055,
        VE_01: 1.28838,
        QA_02: 87.325,
        VA_02: 1.45243,
        QC_02: 75.319,
        VC_02: 1.3426,
        QE_02: 75.319,
        VE_02: 1.3426,
        QA_03: 93.237,
        VA_03: 1.49293,
        QC_03: 81.739,
        VC_03: 1.38851,
        QE_03: 81.739,
        VE_03: 1.38851,
        QA_04: 53.685,
        VA_04: 1.19198,
        QC_04: 51.318,
        VC_04: 1.15197,
        QE_04: 51.318,
        VE_04: 1.15197,
        QA_05: 29.841,
        VA_05: 0.95527,
        QC_05: 29.846,
        VC_05: 0.941,
        QE_05: 29.846,
        VE_05: 0.941,
        QA_06: 19.174,
        VA_06: 0.81986,
        QC_06: 24.57,
        VC_06: 0.87892,
        QE_06: 24.57,
        VE_06: 0.87892,
        QA_07: 14.061,
        VA_07: 0.74219,
        QC_07: 22.017,
        VC_07: 0.84663,
        QE_07: 22.017,
        VE_07: 0.84663,
        QA_08: 10.226,
        VA_08: 0.6747,
        QC_08: 22.224,
        VC_08: 0.84931,
        QE_08: 22.224,
        VE_08: 0.84931,
        QA_09: 8.54,
        VA_09: 0.64128,
        QC_09: 25.986,
        VC_09: 0.89615,
        QE_09: 25.986,
        VE_09: 0.89615,
        QA_10: 6.391,
        VA_10: 0.59379,
        QC_10: 24.205,
        VC_10: 0.8744,
        QE_10: 24.205,
        VE_10: 0.8744,
        QA_11: 9.719,
        VA_11: 0.66494,
        QC_11: 21.658,
        VC_11: 0.84195,
        QE_11: 21.658,
        VE_11: 0.84195,
        QA_12: 46.437,
        VA_12: 1.12648,
        QC_12: 43.427,
        VC_12: 1.0804,
        QE_12: 43.427,
        VE_12: 1.0804,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.805125385014207, 34.832313679266747, 0.0],
          [-80.807793918343464, 34.830276612603143, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291137.0,
        COMID: 9718281,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 1.057,
        REACHCODE: "03050104002430",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011312631048158,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058168.0,
        ToNode: 250058169.0,
        Hydroseq: 250015006.0,
        LevelPathI: 250014857.0,
        Pathlength: 359.685,
        TerminalPa: 250002604.0,
        ArbolateSu: 229.794,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015146.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014857.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1817,
        TotDASqKM: 361.6227,
        DivDASqKM: 361.6227,
        Tidal: 0,
        TOTMA: 0.5337277515,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6760.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.057000041007996,
        QA_MA: 131.848,
        VA_MA: -9998.0,
        QC_MA: 132.12,
        VC_MA: -9998.0,
        QE_MA: 132.12,
        VE_MA: -9998.0,
        QA_01: 289.393,
        VA_01: -9998.0,
        QC_01: 226.215,
        VC_01: -9998.0,
        QE_01: 226.215,
        VE_01: -9998.0,
        QA_02: 317.735,
        VA_02: -9998.0,
        QC_02: 264.571,
        VC_02: -9998.0,
        QE_02: 264.571,
        VE_02: -9998.0,
        QA_03: 343.839,
        VA_03: -9998.0,
        QC_03: 288.727,
        VC_03: -9998.0,
        QE_03: 288.727,
        VE_03: -9998.0,
        QA_04: 187.97,
        VA_04: -9998.0,
        QC_04: 174.637,
        VC_04: -9998.0,
        QE_04: 174.637,
        VE_04: -9998.0,
        QA_05: 105.617,
        VA_05: -9998.0,
        QC_05: 100.755,
        VC_05: -9998.0,
        QE_05: 100.755,
        VE_05: -9998.0,
        QA_06: 68.062,
        VA_06: -9998.0,
        QC_06: 85.711,
        VC_06: -9998.0,
        QE_06: 85.711,
        VE_06: -9998.0,
        QA_07: 48.621,
        VA_07: -9998.0,
        QC_07: 73.907,
        VC_07: -9998.0,
        QE_07: 73.907,
        VE_07: -9998.0,
        QA_08: 38.897,
        VA_08: -9998.0,
        QC_08: 86.824,
        VC_08: -9998.0,
        QE_08: 86.824,
        VE_08: -9998.0,
        QA_09: 29.173,
        VA_09: -9998.0,
        QC_09: 76.875,
        VC_09: -9998.0,
        QE_09: 76.875,
        VE_09: -9998.0,
        QA_10: 24.31,
        VA_10: -9998.0,
        QC_10: 70.37,
        VC_10: -9998.0,
        QE_10: 70.37,
        VE_10: -9998.0,
        QA_11: 19.449,
        VA_11: -9998.0,
        QC_11: 36.76,
        VC_11: -9998.0,
        QE_11: 36.76,
        VE_11: -9998.0,
        QA_12: 109.9,
        VA_12: -9998.0,
        QC_12: 95.096,
        VC_12: -9998.0,
        QE_12: 95.096,
        VE_12: -9998.0,
        LakeFract: 0.00071061977,
        SurfArea: 32225.479099100001,
        RAreaHLoad: 0.10000640291,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.91710358484039, 34.469986013162384, 0.0],
          [-80.914469584844483, 34.471736613159635, 0.0],
          [-80.906506518190156, 34.470697413161361, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283962.0,
        COMID: 9754716,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.335,
        REACHCODE: "03050101000375",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0037284953956829999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061688.0,
        ToNode: 250061811.0,
        Hydroseq: 250017589.0,
        LevelPathI: 250003258.0,
        Pathlength: 686.31,
        TerminalPa: 250002604.0,
        ArbolateSu: 400.062,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250017819.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017360.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5031,
        TotDASqKM: 419.7447,
        DivDASqKM: 419.7447,
        Tidal: 0,
        TOTMA: 0.0089021112087099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36990.0,
        MAXELEVSMO: 37095.0,
        MINELEVSMO: 37035.0,
        SLOPE: 0.00179104,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.33500000834464999,
        QA_MA: 279.141,
        VA_MA: 1.41866,
        QC_MA: 271.692,
        VC_MA: 1.41623,
        QE_MA: 312.873,
        VE_MA: 1.42897,
        QA_01: 440.591,
        VA_01: 1.72314,
        QC_01: 340.633,
        VC_01: 1.55758,
        QE_01: 368.524,
        VE_01: 1.53074,
        QA_02: 464.217,
        VA_02: 1.76293,
        QC_02: 382.569,
        VC_02: 1.63702,
        QE_02: 429.935,
        VE_02: 1.63499,
        QA_03: 524.318,
        VA_03: 1.86007,
        QC_03: 434.189,
        VC_03: 1.72938,
        QE_03: 460.069,
        VE_03: 1.68358,
        QA_04: 395.705,
        VA_04: 1.64468,
        QC_04: 361.468,
        VC_04: 1.59759,
        QE_04: 415.11,
        VE_04: 1.6105,
        QA_05: 317.464,
        VA_05: 1.49716,
        QC_05: 290.632,
        VC_05: 1.45659,
        QE_05: 328.861,
        VE_05: 1.45902,
        QA_06: 182.718,
        VA_06: 1.19494,
        QC_06: 227.0,
        VC_06: 1.3154,
        QE_06: 274.689,
        VE_06: 1.35414,
        QA_07: 105.78,
        VA_07: 0.9716,
        QC_07: 157.831,
        VC_07: 1.13867,
        QE_07: 206.404,
        VE_07: 1.20689,
        QA_08: 72.0,
        VA_08: 0.84907,
        QC_08: 162.71,
        VC_08: 1.1522,
        QE_08: 241.632,
        VE_08: 1.28531,
        QA_09: 82.695,
        VA_09: 0.89033,
        QC_09: 192.884,
        VC_09: 1.23197,
        QE_09: 208.211,
        VE_09: 1.21106,
        QA_10: 126.948,
        VA_10: 1.03907,
        QC_10: 263.516,
        VC_10: 1.39841,
        QE_10: 238.005,
        VE_10: 1.27749,
        QA_11: 283.694,
        VA_11: 1.42824,
        QC_11: 283.821,
        VC_11: 1.44223,
        QE_11: 267.113,
        VE_11: 1.33872,
        QA_12: 355.547,
        VA_12: 1.57087,
        QC_12: 276.751,
        VC_12: 1.42714,
        QE_12: 288.916,
        VE_12: 1.38256,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.043868583091466, 35.706825411242619, 0.0],
          [-82.040162116430565, 35.707212277908582, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286861.0,
        COMID: 9734048,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.604,
        REACHCODE: "03050103000150",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016822383718445001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059253.0,
        ToNode: 250059258.0,
        Hydroseq: 250023119.0,
        LevelPathI: 250005808.0,
        Pathlength: 412.273,
        TerminalPa: 250002604.0,
        ArbolateSu: 108.015,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250023661.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022621.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8936,
        TotDASqKM: 92.6676,
        DivDASqKM: 92.6676,
        Tidal: 0,
        TOTMA: 0.061106783031700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14642.0,
        MAXELEVSMO: 14830.0,
        MINELEVSMO: 14672.0,
        SLOPE: 0.00098503,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.603999972343445,
        QA_MA: 37.597,
        VA_MA: 0.99393,
        QC_MA: 39.554,
        VC_MA: 0.99675,
        QE_MA: 39.554,
        VE_MA: 0.99675,
        QA_01: 82.191,
        VA_01: 1.34687,
        QC_01: 66.403,
        VC_01: 1.21473,
        QE_01: 66.403,
        VE_01: 1.21473,
        QA_02: 85.127,
        VA_02: 1.3664,
        QC_02: 73.474,
        VC_02: 1.26476,
        QE_02: 73.474,
        VE_02: 1.26476,
        QA_03: 90.898,
        VA_03: 1.4039,
        QC_03: 79.756,
        VC_03: 1.30734,
        QE_03: 79.756,
        VE_03: 1.30734,
        QA_04: 52.327,
        VA_04: 1.12548,
        QC_04: 50.049,
        VC_04: 1.08838,
        QE_04: 50.049,
        VE_04: 1.08838,
        QA_05: 29.088,
        VA_05: 0.9066,
        QC_05: 29.12,
        VC_05: 0.89344,
        QE_05: 29.12,
        VE_05: 0.89344,
        QA_06: 18.689,
        VA_06: 0.78136,
        QC_06: 23.957,
        VC_06: 0.83585,
        QE_06: 23.957,
        VE_06: 0.83585,
        QA_07: 13.705,
        VA_07: 0.70954,
        QC_07: 21.474,
        VC_07: 0.80607,
        QE_07: 21.474,
        VE_07: 0.80607,
        QA_08: 9.967,
        VA_08: 0.64713,
        QC_08: 21.651,
        VC_08: 0.80825,
        QE_08: 21.651,
        VE_08: 0.80825,
        QA_09: 8.346,
        VA_09: 0.61666,
        QC_09: 25.464,
        VC_09: 0.85321,
        QE_09: 25.464,
        VE_09: 0.85321,
        QA_10: 6.23,
        VA_10: 0.57233,
        QC_10: 23.715,
        VC_10: 0.83301,
        QE_10: 23.715,
        VE_10: 0.83301,
        QA_11: 9.546,
        VA_11: 0.63945,
        QC_11: 21.363,
        VC_11: 0.80471,
        QE_11: 21.363,
        VE_11: 0.80471,
        QA_12: 45.305,
        VA_12: 1.06529,
        QC_12: 42.464,
        VC_12: 1.0232,
        QE_12: 42.464,
        VE_12: 1.0232,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.790925585036291, 34.837023612592766, 0.0],
          [-80.7960679183617, 34.834095012597174, 0.0],
          [-80.802734585018015, 34.834419679263362, 0.0],
          [-80.805125385014207, 34.832313679266747, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291138.0,
        COMID: 9718283,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 1.842,
        REACHCODE: "03050104002431",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019742613343533999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058169.0,
        ToNode: 250058174.0,
        Hydroseq: 250014857.0,
        LevelPathI: 250014857.0,
        Pathlength: 357.843,
        TerminalPa: 250002604.0,
        ArbolateSu: 233.264,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015006.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004126.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.9294,
        TotDASqKM: 366.804,
        DivDASqKM: 366.804,
        Tidal: 0,
        TOTMA: 0.93011023905000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6778.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.842000007629395,
        QA_MA: 133.646,
        VA_MA: -9998.0,
        QC_MA: 133.851,
        VC_MA: -9998.0,
        QE_MA: 133.851,
        VE_MA: -9998.0,
        QA_01: 293.327,
        VA_01: -9998.0,
        QC_01: 229.209,
        VC_01: -9998.0,
        QE_01: 229.209,
        VE_01: -9998.0,
        QA_02: 322.035,
        VA_02: -9998.0,
        QC_02: 268.053,
        VC_02: -9998.0,
        QE_02: 268.053,
        VE_02: -9998.0,
        QA_03: 348.543,
        VA_03: -9998.0,
        QC_03: 292.545,
        VC_03: -9998.0,
        QE_03: 292.545,
        VE_03: -9998.0,
        QA_04: 190.505,
        VA_04: -9998.0,
        QC_04: 176.937,
        VC_04: -9998.0,
        QE_04: 176.937,
        VE_04: -9998.0,
        QA_05: 107.037,
        VA_05: -9998.0,
        QC_05: 102.058,
        VC_05: -9998.0,
        QE_05: 102.058,
        VE_05: -9998.0,
        QA_06: 69.002,
        VA_06: -9998.0,
        QC_06: 86.878,
        VC_06: -9998.0,
        QE_06: 86.878,
        VE_06: -9998.0,
        QA_07: 49.318,
        VA_07: -9998.0,
        QC_07: 74.941,
        VC_07: -9998.0,
        QE_07: 74.941,
        VE_07: -9998.0,
        QA_08: 39.454,
        VA_08: -9998.0,
        QC_08: 88.093,
        VC_08: -9998.0,
        QE_08: 88.093,
        VE_08: -9998.0,
        QA_09: 29.591,
        VA_09: -9998.0,
        QC_09: 77.847,
        VC_09: -9998.0,
        QE_09: 77.847,
        VE_09: -9998.0,
        QA_10: 24.659,
        VA_10: -9998.0,
        QC_10: 71.174,
        VC_10: -9998.0,
        QE_10: 71.174,
        VE_10: -9998.0,
        QA_11: 19.728,
        VA_11: -9998.0,
        QC_11: 37.161,
        VC_11: -9998.0,
        QE_11: 37.161,
        VE_11: -9998.0,
        QA_12: 111.343,
        VA_12: -9998.0,
        QC_12: 96.232,
        VC_12: -9998.0,
        QE_12: 96.232,
        VE_12: -9998.0,
        LakeFract: 0.00125452765,
        SurfArea: 56890.838492000003,
        RAreaHLoad: 0.17427795173,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.906506518190156, 34.470697413161361, 0.0],
          [-80.902860318195849, 34.468047679832068, 0.0],
          [-80.887844518219211, 34.468123613165289, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286862.0,
        COMID: 9734022,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.109,
        REACHCODE: "03050103000151",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00114633131956,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059243.0,
        ToNode: 250059253.0,
        Hydroseq: 250023661.0,
        LevelPathI: 250005808.0,
        Pathlength: 413.877,
        TerminalPa: 250002604.0,
        ArbolateSu: 103.024,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250024246.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023119.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0144,
        TotDASqKM: 87.0165,
        DivDASqKM: 87.0165,
        Tidal: 0,
        TOTMA: 0.0036479694217400002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14830.0,
        MAXELEVSMO: 14867.0,
        MINELEVSMO: 14830.0,
        SLOPE: 0.00339449,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.108999997377396,
        QA_MA: 35.342,
        VA_MA: 1.13106,
        QC_MA: 37.272,
        VC_MA: 1.13461,
        QE_MA: 37.272,
        VE_MA: 1.13461,
        QA_01: 77.253,
        VA_01: 1.55453,
        QC_01: 62.514,
        VC_01: 1.39571,
        QE_01: 62.514,
        VE_01: 1.39571,
        QA_02: 79.96,
        VA_02: 1.57753,
        QC_02: 69.133,
        VC_02: 1.4554,
        QE_02: 69.133,
        VE_02: 1.4554,
        QA_03: 85.391,
        VA_03: 1.6226,
        QC_03: 75.078,
        VC_03: 1.50676,
        QE_03: 75.078,
        VE_03: 1.50676,
        QA_04: 49.136,
        VA_04: 1.28837,
        QC_04: 47.064,
        VC_04: 1.24356,
        QE_04: 47.064,
        VE_04: 1.24356,
        QA_05: 27.322,
        VA_05: 1.02597,
        QC_05: 27.416,
        VC_05: 1.01029,
        QE_05: 27.416,
        VE_05: 1.01029,
        QA_06: 17.549,
        VA_06: 0.87565,
        QC_06: 22.515,
        VC_06: 0.94059,
        QE_06: 22.515,
        VE_06: 0.94059,
        QA_07: 12.869,
        VA_07: 0.78951,
        QC_07: 20.195,
        VC_07: 0.90512,
        QE_07: 20.195,
        VE_07: 0.90512,
        QA_08: 9.36,
        VA_08: 0.71467,
        QC_08: 20.305,
        VC_08: 0.90684,
        QE_08: 20.305,
        VE_08: 0.90684,
        QA_09: 7.89,
        VA_09: 0.67944,
        QC_09: 24.232,
        VC_09: 0.96575,
        QE_09: 24.232,
        VE_09: 0.96575,
        QA_10: 5.85,
        VA_10: 0.62494,
        QC_10: 22.552,
        VC_10: 0.94114,
        QE_10: 22.552,
        VE_10: 0.94114,
        QA_11: 9.122,
        VA_11: 0.70915,
        QC_11: 20.636,
        VC_11: 0.912,
        QE_11: 20.636,
        VE_11: 0.912,
        QA_12: 42.646,
        VA_12: 1.21736,
        QC_12: 40.19,
        VC_12: 1.16835,
        QE_12: 40.19,
        VE_12: 1.16835,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.789897318371231, 34.837481012592036, 0.0],
          [-80.790925585036291, 34.837023612592766, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286855.0,
        COMID: 9734112,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.361,
        REACHCODE: "03050103000144",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0036737232185280002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059274.0,
        ToNode: 250059275.0,
        Hydroseq: 250020559.0,
        LevelPathI: 250005808.0,
        Pathlength: 408.565,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.382,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250020927.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020202.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3114,
        TotDASqKM: 110.925,
        DivDASqKM: 110.925,
        Tidal: 0,
        TOTMA: 0.020963342671300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14316.0,
        MAXELEVSMO: 14361.0,
        MINELEVSMO: 14361.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.36100000143051098,
        QA_MA: 44.896,
        VA_MA: 0.65269,
        QC_MA: 46.909,
        VC_MA: 0.65391,
        QE_MA: 46.909,
        VE_MA: 0.65391,
        QA_01: 98.157,
        VA_01: 0.82997,
        QC_01: 78.934,
        VC_01: 0.76389,
        QE_01: 78.934,
        VE_01: 0.76389,
        QA_02: 101.819,
        VA_02: 0.84021,
        QC_02: 87.454,
        VC_02: 0.78936,
        QE_02: 87.454,
        VE_02: 0.78936,
        QA_03: 108.752,
        VA_03: 0.85914,
        QC_03: 94.857,
        VC_03: 0.81057,
        QE_03: 94.857,
        VE_03: 0.81057,
        QA_04: 62.641,
        VA_04: 0.71929,
        QC_04: 59.67,
        VC_04: 0.701,
        QE_04: 59.67,
        VE_04: 0.701,
        QA_05: 34.885,
        VA_05: 0.60952,
        QC_05: 34.687,
        VC_05: 0.60274,
        QE_05: 34.687,
        VE_05: 0.60274,
        QA_06: 22.371,
        VA_06: 0.54623,
        QC_06: 28.606,
        VC_06: 0.5741,
        QE_06: 28.606,
        VE_06: 0.5741,
        QA_07: 16.405,
        VA_07: 0.51012,
        QC_07: 25.594,
        VC_07: 0.55886,
        QE_07: 25.594,
        VE_07: 0.55886,
        QA_08: 11.931,
        VA_08: 0.47873,
        QC_08: 26.01,
        VC_08: 0.56102,
        QE_08: 26.01,
        VE_08: 0.56102,
        QA_09: 9.831,
        VA_09: 0.46209,
        QC_09: 29.426,
        VC_09: 0.57812,
        QE_09: 29.426,
        VE_09: 0.57812,
        QA_10: 7.457,
        VA_10: 0.44111,
        QC_10: 27.378,
        VC_10: 0.56799,
        QE_10: 27.378,
        VE_10: 0.56799,
        QA_11: 10.896,
        VA_11: 0.47072,
        QC_11: 23.631,
        VC_11: 0.54847,
        QE_11: 23.631,
        VE_11: 0.54847,
        QA_12: 53.909,
        VA_12: 0.68781,
        QC_12: 49.742,
        VC_12: 0.66484,
        QE_12: 49.742,
        VE_12: 0.66484,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.83243678497189, 34.823873479279825, 0.0],
          [-80.834825784968189, 34.823278679280691, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286856.0,
        COMID: 9734106,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.161,
        REACHCODE: "03050103000145",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012218782252023001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059273.0,
        ToNode: 250059274.0,
        Hydroseq: 250020927.0,
        LevelPathI: 250005808.0,
        Pathlength: 408.926,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.021,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250021310.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 250020560.0,
        DnDrainCou: 2,
        DnHydroseq: 250020559.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.611,
        TotDASqKM: 110.6136,
        DivDASqKM: 110.6136,
        Tidal: 0,
        TOTMA: 0.044567618284500003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14325.0,
        MAXELEVSMO: 14444.0,
        MINELEVSMO: 14361.0,
        SLOPE: 0.0007149,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.16100001335144,
        QA_MA: 44.772,
        VA_MA: 0.98678,
        QC_MA: 46.784,
        VC_MA: 0.9892,
        QE_MA: 46.784,
        VE_MA: 0.9892,
        QA_01: 97.885,
        VA_01: 1.33609,
        QC_01: 78.721,
        VC_01: 1.20588,
        QE_01: 78.721,
        VE_01: 1.20588,
        QA_02: 101.535,
        VA_02: 1.35626,
        QC_02: 87.216,
        VC_02: 1.25607,
        QE_02: 87.216,
        VE_02: 1.25607,
        QA_03: 108.446,
        VA_03: 1.39355,
        QC_03: 94.6,
        VC_03: 1.29786,
        QE_03: 94.6,
        VE_03: 1.29786,
        QA_04: 62.465,
        VA_04: 1.118,
        QC_04: 59.506,
        VC_04: 1.08195,
        QE_04: 59.506,
        VE_04: 1.08195,
        QA_05: 34.788,
        VA_05: 0.90171,
        QC_05: 34.594,
        VC_05: 0.88837,
        QE_05: 34.594,
        VE_05: 0.88837,
        QA_06: 22.308,
        VA_06: 0.77701,
        QC_06: 28.527,
        VC_06: 0.83192,
        QE_06: 28.527,
        VE_06: 0.83192,
        QA_07: 16.359,
        VA_07: 0.70585,
        QC_07: 25.524,
        VC_07: 0.80189,
        QE_07: 25.524,
        VE_07: 0.80189,
        QA_08: 11.898,
        VA_08: 0.64403,
        QC_08: 25.935,
        VC_08: 0.8061,
        QE_08: 25.935,
        VE_08: 0.8061,
        QA_09: 9.806,
        VA_09: 0.61127,
        QC_09: 29.36,
        VC_09: 0.83998,
        QE_09: 29.36,
        VE_09: 0.83998,
        QA_10: 7.436,
        VA_10: 0.56989,
        QC_10: 27.317,
        VC_10: 0.82001,
        QE_10: 27.317,
        VE_10: 0.82001,
        QA_11: 10.875,
        VA_11: 0.62838,
        QC_11: 23.596,
        VC_11: 0.78174,
        QE_11: 23.596,
        VE_11: 0.78174,
        QA_12: 53.763,
        VA_12: 1.05601,
        QC_12: 49.619,
        VC_12: 1.0108,
        QE_12: 49.619,
        VE_12: 1.0108,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.822018784987961, 34.826847412608572, 0.0],
          [-80.83243678497189, 34.823873479279825, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285839.0,
        COMID: 9753970,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.49,
        REACHCODE: "03050101002047",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 19489058,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023046723118261999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061459.0,
        ToNode: 250061615.0,
        Hydroseq: 250009822.0,
        LevelPathI: 250003258.0,
        Pathlength: 569.715,
        TerminalPa: 250002604.0,
        ArbolateSu: 3290.181,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009873.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009773.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.3047,
        TotDASqKM: 3672.2862,
        DivDASqKM: 3672.2862,
        Tidal: 0,
        TOTMA: 0.076989835172099993,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25430.0,
        MAXELEVSMO: 25436.0,
        MINELEVSMO: 25430.0,
        SLOPE: 2.409e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.4900000095367432,
        QA_MA: 2205.094,
        VA_MA: 1.22191,
        QC_MA: 2205.094,
        VC_MA: 1.22191,
        QE_MA: 2397.149,
        VE_MA: 1.22811,
        QA_01: 3565.332,
        VA_01: 1.48742,
        QC_01: 3565.332,
        VC_01: 1.48742,
        QE_01: 3763.472,
        VE_01: 1.47702,
        QA_02: 3767.924,
        VA_02: 1.52253,
        QC_02: 3767.924,
        VC_02: 1.52253,
        QE_02: 3831.878,
        VE_02: 1.48825,
        QA_03: 4261.8,
        VA_03: 1.60459,
        QC_03: 4261.8,
        VC_03: 1.60459,
        QE_03: 4520.338,
        VE_03: 1.59645,
        QA_04: 3281.126,
        VA_04: 1.43654,
        QC_04: 3281.126,
        VC_04: 1.43654,
        QE_04: 3470.238,
        VE_04: 1.42775,
        QA_05: 2496.948,
        VA_05: 1.28424,
        QC_05: 2496.948,
        VC_05: 1.28424,
        QE_05: 2718.156,
        VE_05: 1.29158,
        QA_06: 1455.794,
        VA_06: 1.04116,
        QC_06: 1455.794,
        VC_06: 1.04116,
        QE_06: 1717.578,
        VE_06: 1.0789,
        QA_07: 860.093,
        VA_07: 0.86251,
        QC_07: 860.093,
        VC_07: 0.86251,
        QE_07: 1010.435,
        VE_07: 0.88973,
        QA_08: 603.888,
        VA_08: 0.76764,
        QC_08: 603.888,
        VC_08: 0.76764,
        QE_08: 905.343,
        VE_08: 0.8568,
        QA_09: 585.71,
        VA_09: 0.76024,
        QC_09: 1086.267,
        VC_09: 0.93564,
        QE_09: 1122.972,
        VE_09: 0.92326,
        QA_10: 749.621,
        VA_10: 0.8235,
        QC_10: 749.621,
        VC_10: 0.8235,
        QE_10: 690.051,
        VE_10: 0.78316,
        QA_11: 2036.828,
        VA_11: 1.18421,
        QC_11: 2036.828,
        VC_11: 1.18421,
        QE_11: 1981.047,
        VE_11: 1.13956,
        QA_12: 2810.433,
        VA_12: 1.34749,
        QC_12: 2810.433,
        VC_12: 1.34749,
        QE_12: 2819.8,
        VE_12: 1.31094,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.141258717825849, 35.827410411055325, 0.0],
          [-81.141175584492544, 35.812345877745486, 0.0],
          [-81.135974984500649, 35.806932077753913, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286857.0,
        COMID: 9734092,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.148,
        REACHCODE: "03050103000146",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015125601260469999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059270.0,
        ToNode: 250059273.0,
        Hydroseq: 250021310.0,
        LevelPathI: 250005808.0,
        Pathlength: 410.087,
        TerminalPa: 250002604.0,
        ArbolateSu: 117.147,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250021712.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020927.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0234,
        TotDASqKM: 99.5004,
        DivDASqKM: 99.5004,
        Tidal: 0,
        TOTMA: 0.00510431915845,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14439.0,
        MAXELEVSMO: 14476.0,
        MINELEVSMO: 14444.0,
        SLOPE: 0.00216216,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 40.322,
        VA_MA: 1.09794,
        QC_MA: 42.306,
        VC_MA: 1.10102,
        QE_MA: 42.306,
        VE_MA: 1.10102,
        QA_01: 88.163,
        VA_01: 1.50453,
        QC_01: 71.096,
        VC_01: 1.35258,
        QE_01: 71.096,
        VE_01: 1.35258,
        QA_02: 91.374,
        VA_02: 1.52747,
        QC_02: 78.714,
        VC_02: 1.41057,
        QE_02: 78.714,
        VE_02: 1.41057,
        QA_03: 97.552,
        VA_03: 1.57057,
        QC_03: 85.394,
        VC_03: 1.45929,
        QE_03: 85.394,
        VE_03: 1.45929,
        QA_04: 56.186,
        VA_04: 1.25004,
        QC_04: 53.653,
        VC_04: 1.2076,
        QE_04: 53.653,
        VE_04: 1.2076,
        QA_05: 31.222,
        VA_05: 0.99766,
        QC_05: 31.174,
        VC_05: 0.98238,
        QE_05: 31.174,
        VE_05: 0.98238,
        QA_06: 20.067,
        VA_06: 0.85346,
        QC_06: 25.698,
        VC_06: 0.91667,
        QE_06: 25.698,
        VE_06: 0.91667,
        QA_07: 14.716,
        VA_07: 0.7707,
        QC_07: 23.018,
        VC_07: 0.88212,
        QE_07: 23.018,
        VE_07: 0.88212,
        QA_08: 10.702,
        VA_08: 0.69876,
        QC_08: 23.28,
        VC_08: 0.88557,
        QE_08: 23.28,
        VE_08: 0.88557,
        QA_09: 8.897,
        VA_09: 0.66229,
        QC_09: 26.943,
        VC_09: 0.93214,
        QE_09: 26.943,
        VE_09: 0.93214,
        QA_10: 6.689,
        VA_10: 0.61255,
        QC_10: 25.102,
        VC_10: 0.90914,
        QE_10: 25.102,
        VE_10: 0.90914,
        QA_11: 10.028,
        VA_11: 0.68551,
        QC_11: 22.181,
        VC_11: 0.87094,
        QE_11: 22.181,
        VE_11: 0.87094,
        QA_12: 48.521,
        VA_12: 1.17948,
        QC_12: 45.197,
        VC_12: 1.12933,
        QE_12: 45.197,
        VE_12: 1.12933,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.820852518323193, 34.826113879276363, 0.0],
          [-80.822018784987961, 34.826847412608572, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283967.0,
        COMID: 9754750,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.048,
        REACHCODE: "03050101000380",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00047821430775799999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061699.0,
        ToNode: 250061698.0,
        Hydroseq: 250018881.0,
        LevelPathI: 250003258.0,
        Pathlength: 689.035,
        TerminalPa: 250002604.0,
        ArbolateSu: 395.341,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250019183.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018595.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 415.4922,
        DivDASqKM: 415.4922,
        Tidal: 0,
        TOTMA: 0.0022827554351300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37355.0,
        MAXELEVSMO: 37502.0,
        MINELEVSMO: 37502.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.048000000417232999,
        QA_MA: 276.585,
        VA_MA: 0.79391,
        QC_MA: 269.301,
        VC_MA: 0.79286,
        QE_MA: 310.36,
        VE_MA: 0.79846,
        QA_01: 436.381,
        VA_01: 0.92692,
        QC_01: 337.462,
        VC_01: 0.85452,
        QE_01: 365.303,
        VE_01: 0.84276,
        QA_02: 459.712,
        VA_02: 0.94427,
        QC_02: 378.957,
        VC_02: 0.88921,
        QE_02: 426.313,
        VE_02: 0.88843,
        QA_03: 519.145,
        VA_03: 0.98667,
        QC_03: 430.045,
        VC_03: 0.92955,
        QE_03: 455.799,
        VE_03: 0.9094,
        QA_04: 391.846,
        VA_04: 0.89256,
        QC_04: 358.023,
        VC_04: 0.87194,
        QE_04: 411.542,
        VE_04: 0.87767,
        QA_05: 314.48,
        VA_05: 0.82816,
        QC_05: 288.003,
        VC_05: 0.81044,
        QE_05: 326.061,
        VE_05: 0.81147,
        QA_06: 181.1,
        VA_06: 0.69614,
        QC_06: 225.017,
        VC_06: 0.74878,
        QE_06: 272.532,
        VE_06: 0.76578,
        QA_07: 104.815,
        VA_07: 0.59842,
        QC_07: 156.425,
        VC_07: 0.67146,
        QE_07: 204.903,
        VE_07: 0.70149,
        QA_08: 71.37,
        VA_08: 0.54488,
        QC_08: 161.258,
        VC_08: 0.67737,
        QE_08: 240.08,
        VE_08: 0.736,
        QA_09: 82.234,
        VA_09: 0.56336,
        QC_09: 191.935,
        VC_09: 0.71312,
        QE_09: 207.144,
        VE_09: 0.70377,
        QA_10: 126.565,
        VA_10: 0.62896,
        QC_10: 262.882,
        VC_10: 0.78669,
        QE_10: 237.302,
        VE_10: 0.73336,
        QA_11: 281.27,
        VA_11: 0.79826,
        QC_11: 281.97,
        VC_11: 0.80483,
        QE_11: 265.169,
        VE_11: 0.75917,
        QA_12: 352.123,
        VA_12: 0.86032,
        QC_12: 274.325,
        VC_12: 0.79764,
        QE_12: 286.411,
        VE_12: 0.778,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.046688116420398, 35.693201677930467, 0.0],
          [-82.046350983087677, 35.69352207792997, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286858.0,
        COMID: 9734096,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.982,
        REACHCODE: "03050103000147",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010000545844516999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059271.0,
        ToNode: 250059270.0,
        Hydroseq: 250021712.0,
        LevelPathI: 250005808.0,
        Pathlength: 410.235,
        TerminalPa: 250002604.0,
        ArbolateSu: 115.068,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250022151.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021310.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.441,
        TotDASqKM: 97.9461,
        DivDASqKM: 97.9461,
        Tidal: 0,
        TOTMA: 0.037869719396700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14417.0,
        MAXELEVSMO: 14556.0,
        MINELEVSMO: 14476.0,
        SLOPE: 0.00081466,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.98199999332428001,
        QA_MA: 39.703,
        VA_MA: 0.98201,
        QC_MA: 41.682,
        VC_MA: 0.98467,
        QE_MA: 41.682,
        VE_MA: 0.98467,
        QA_01: 86.804,
        VA_01: 1.32887,
        QC_01: 70.029,
        VC_01: 1.19918,
        QE_01: 70.029,
        VE_01: 1.19918,
        QA_02: 89.953,
        VA_02: 1.34836,
        QC_02: 77.523,
        VC_02: 1.24859,
        QE_02: 77.523,
        VE_02: 1.24859,
        QA_03: 96.039,
        VA_03: 1.38515,
        QC_03: 84.113,
        VC_03: 1.29022,
        QE_03: 84.113,
        VE_03: 1.29022,
        QA_04: 55.308,
        VA_04: 1.11166,
        QC_04: 52.834,
        VC_04: 1.0754,
        QE_04: 52.834,
        VE_04: 1.0754,
        QA_05: 30.739,
        VA_05: 0.89642,
        QC_05: 30.709,
        VC_05: 0.88339,
        QE_05: 30.709,
        VE_05: 0.88339,
        QA_06: 19.754,
        VA_06: 0.77337,
        QC_06: 25.302,
        VC_06: 0.8272,
        QE_06: 25.302,
        VE_06: 0.8272,
        QA_07: 14.486,
        VA_07: 0.70276,
        QC_07: 22.667,
        VC_07: 0.79777,
        QE_07: 22.667,
        VE_07: 0.79777,
        QA_08: 10.535,
        VA_08: 0.6414,
        QC_08: 22.909,
        VC_08: 0.80054,
        QE_08: 22.909,
        VE_08: 0.80054,
        QA_09: 8.772,
        VA_09: 0.61054,
        QC_09: 26.608,
        VC_09: 0.84125,
        QE_09: 26.608,
        VE_09: 0.84125,
        QA_10: 6.585,
        VA_10: 0.56786,
        QC_10: 24.788,
        VC_10: 0.82157,
        QE_10: 24.788,
        VE_10: 0.82157,
        QA_11: 9.921,
        VA_11: 0.63095,
        QC_11: 22.0,
        VC_11: 0.79007,
        QE_11: 22.0,
        VE_11: 0.79007,
        QA_12: 47.789,
        VA_12: 1.05169,
        QC_12: 44.577,
        VC_12: 1.00922,
        QE_12: 44.577,
        VE_12: 1.00922,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.813018518335355, 34.827393079274373, 0.0],
          [-80.817130718328883, 34.825608279277162, 0.0],
          [-80.818935184992768, 34.827143879274736, 0.0],
          [-80.820852518323193, 34.826113879276363, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283968.0,
        COMID: 9754764,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.638,
        REACHCODE: "03050101000381",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0063522302848369998,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061703.0,
        ToNode: 250061699.0,
        Hydroseq: 250019183.0,
        LevelPathI: 250003258.0,
        Pathlength: 689.083,
        TerminalPa: 250002604.0,
        ArbolateSu: 378.806,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250019505.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018881.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2268,
        TotDASqKM: 399.1635,
        DivDASqKM: 399.1635,
        Tidal: 0,
        TOTMA: 0.017429565805500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37307.0,
        MAXELEVSMO: 37600.0,
        MINELEVSMO: 37502.0,
        SLOPE: 0.00153605,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.63800001144409202,
        QA_MA: 265.536,
        VA_MA: 1.37946,
        QC_MA: 258.952,
        VC_MA: 1.37729,
        QE_MA: 299.543,
        VE_MA: 1.38997,
        QA_01: 419.091,
        VA_01: 1.67315,
        QC_01: 324.435,
        VC_01: 1.51322,
        QE_01: 352.08,
        VE_01: 1.48688,
        QA_02: 441.258,
        VA_02: 1.71101,
        QC_02: 364.151,
        VC_02: 1.58941,
        QE_02: 411.47,
        VE_02: 1.58857,
        QA_03: 498.22,
        VA_03: 1.80442,
        QC_03: 413.272,
        VC_03: 1.67844,
        QE_03: 438.541,
        VE_03: 1.63263,
        QA_04: 375.955,
        VA_04: 1.59666,
        QC_04: 343.826,
        VC_04: 1.55094,
        QE_04: 396.874,
        VE_04: 1.56426,
        QA_05: 301.747,
        VA_05: 1.4547,
        QC_05: 276.769,
        VC_05: 1.41573,
        QE_05: 314.173,
        VE_05: 1.41771,
        QA_06: 174.076,
        VA_06: 1.16433,
        QC_06: 216.407,
        VC_06: 1.28012,
        QE_06: 263.252,
        VE_06: 1.31829,
        QA_07: 100.763,
        VA_07: 0.94866,
        QC_07: 150.52,
        VC_07: 1.10974,
        QE_07: 198.632,
        VE_07: 1.178,
        QA_08: 68.75,
        VA_08: 0.83103,
        QC_08: 155.223,
        VC_08: 1.12294,
        QE_08: 233.659,
        VE_08: 1.25632,
        QA_09: 80.256,
        VA_09: 0.87579,
        QC_09: 187.853,
        VC_09: 1.20978,
        QE_09: 202.611,
        VE_09: 1.1872,
        QA_10: 121.972,
        VA_10: 1.01701,
        QC_10: 255.233,
        VC_10: 1.36911,
        QE_10: 229.39,
        VE_10: 1.24708,
        QA_11: 269.351,
        VA_11: 1.38761,
        QC_11: 272.811,
        VC_11: 1.40729,
        QE_11: 255.653,
        VE_11: 1.3027,
        QA_12: 337.071,
        VA_12: 1.5241,
        QC_12: 263.635,
        VC_12: 1.38751,
        QE_12: 275.415,
        VE_12: 1.3428,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.051098916413537, 35.688990611270249, 0.0],
          [-82.046688116420398, 35.693201677930467, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286867.0,
        COMID: 9733946,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.907,
        REACHCODE: "03050103000156",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0091885883299599996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059219.0,
        ToNode: 250059220.0,
        Hydroseq: 250027087.0,
        LevelPathI: 250005808.0,
        Pathlength: 416.811,
        TerminalPa: 250002604.0,
        ArbolateSu: 83.929,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250027961.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026289.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4464,
        TotDASqKM: 72.7308,
        DivDASqKM: 72.7308,
        Tidal: 0,
        TOTMA: 0.036551722096000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15052.0,
        MAXELEVSMO: 15212.0,
        MINELEVSMO: 15137.0,
        SLOPE: 0.0008269,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.90700000524520896,
        QA_MA: 29.601,
        VA_MA: 0.93918,
        QC_MA: 31.433,
        VC_MA: 0.94226,
        QE_MA: 31.433,
        VE_MA: 0.94226,
        QA_01: 64.726,
        VA_01: 1.26406,
        QC_01: 52.621,
        VC_01: 1.14173,
        QE_01: 52.621,
        VE_01: 1.14173,
        QA_02: 66.899,
        VA_02: 1.28096,
        QC_02: 58.122,
        VC_02: 1.18691,
        QE_02: 58.122,
        VE_02: 1.18691,
        QA_03: 71.385,
        VA_03: 1.31506,
        QC_03: 63.136,
        VC_03: 1.22637,
        QE_03: 63.136,
        VE_03: 1.22637,
        QA_04: 41.069,
        VA_04: 1.05898,
        QC_04: 39.498,
        VC_04: 1.02399,
        QE_04: 39.498,
        VE_04: 1.02399,
        QA_05: 22.783,
        VA_05: 0.85732,
        QC_05: 23.018,
        VC_05: 0.8456,
        QE_05: 23.018,
        VE_05: 0.8456,
        QA_06: 14.668,
        VA_06: 0.74286,
        QC_06: 18.865,
        VC_06: 0.79174,
        QE_06: 18.865,
        VE_06: 0.79174,
        QA_07: 10.757,
        VA_07: 0.6769,
        QC_07: 16.952,
        VC_07: 0.76506,
        QE_07: 16.952,
        VE_07: 0.76506,
        QA_08: 7.823,
        VA_08: 0.61956,
        QC_08: 16.911,
        VC_08: 0.76447,
        QE_08: 16.911,
        VE_08: 0.76447,
        QA_09: 6.664,
        VA_09: 0.59417,
        QC_09: 20.875,
        VC_09: 0.81843,
        QE_09: 20.875,
        VE_09: 0.81843,
        QA_10: 4.889,
        VA_10: 0.55083,
        QC_10: 19.542,
        VC_10: 0.80087,
        QE_10: 19.542,
        VE_10: 0.80087,
        QA_11: 7.912,
        VA_11: 0.62144,
        QC_11: 18.513,
        VC_11: 0.78693,
        QE_11: 18.513,
        VE_11: 0.78693,
        QA_12: 35.828,
        VA_12: 1.00649,
        QC_12: 34.299,
        VC_12: 0.97232,
        QE_12: 34.299,
        VE_12: 0.97232,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.764374585077519, 34.852395412568796, 0.0],
          [-80.76656918507399, 34.852763812568185, 0.0],
          [-80.77118598506695, 34.849286212573702, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286868.0,
        COMID: 9733924,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.973,
        REACHCODE: "03050103000157",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0098788958413910007,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059213.0,
        ToNode: 250059219.0,
        Hydroseq: 250027961.0,
        LevelPathI: 250005808.0,
        Pathlength: 417.718,
        TerminalPa: 250002604.0,
        ArbolateSu: 80.271,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250028923.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027087.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9225,
        TotDASqKM: 70.4655,
        DivDASqKM: 70.4655,
        Tidal: 0,
        TOTMA: 0.037078308734800003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15192.0,
        MAXELEVSMO: 15353.0,
        MINELEVSMO: 15212.0,
        SLOPE: 0.00144912,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97299998998642001,
        QA_MA: 28.694,
        VA_MA: 0.99307,
        QC_MA: 30.506,
        VC_MA: 0.99647,
        QE_MA: 30.506,
        VE_MA: 0.99647,
        QA_01: 62.747,
        VA_01: 1.34574,
        QC_01: 51.053,
        VC_01: 1.21286,
        QE_01: 51.053,
        VE_01: 1.21286,
        QA_02: 64.828,
        VA_02: 1.36386,
        QC_02: 56.371,
        VC_02: 1.26172,
        QE_02: 56.371,
        VE_02: 1.26172,
        QA_03: 69.17,
        VA_03: 1.40083,
        QC_03: 61.241,
        VC_03: 1.30461,
        QE_03: 61.241,
        VE_03: 1.30461,
        QA_04: 39.79,
        VA_04: 1.12287,
        QC_04: 38.295,
        VC_04: 1.08479,
        QE_04: 38.295,
        VE_04: 1.08479,
        QA_05: 22.075,
        VA_05: 0.90407,
        QC_05: 22.329,
        VC_05: 0.89141,
        QE_05: 22.329,
        VE_05: 0.89141,
        QA_06: 14.211,
        VA_06: 0.77985,
        QC_06: 18.286,
        VC_06: 0.83274,
        QE_06: 18.286,
        VE_06: 0.83274,
        QA_07: 10.422,
        VA_07: 0.70827,
        QC_07: 16.436,
        VC_07: 0.80386,
        QE_07: 16.436,
        VE_07: 0.80386,
        QA_08: 7.579,
        VA_08: 0.64605,
        QC_08: 16.373,
        VC_08: 0.80285,
        QE_08: 16.373,
        VE_08: 0.80285,
        QA_09: 6.481,
        VA_09: 0.61912,
        QC_09: 20.369,
        VC_09: 0.86365,
        QE_09: 20.369,
        VE_09: 0.86365,
        QA_10: 4.737,
        VA_10: 0.57147,
        QC_10: 19.055,
        VC_10: 0.84433,
        QE_10: 19.055,
        VE_10: 0.84433,
        QA_11: 7.725,
        VA_11: 0.64948,
        QC_11: 18.179,
        VC_11: 0.8311,
        QE_11: 18.179,
        VE_11: 0.8311,
        QA_12: 34.755,
        VA_12: 1.06641,
        QC_12: 33.363,
        VC_12: 1.02998,
        QE_12: 33.363,
        VE_12: 1.02998,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.757841118421041, 34.855115079231325, 0.0],
          [-80.764510318410601, 34.854869279231593, 0.0],
          [-80.764374585077519, 34.852395412568796, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286012.0,
        COMID: 9751096,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.003,
        REACHCODE: "03050101002687",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0096037627587119995,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060710.0,
        ToNode: 250060716.0,
        Hydroseq: 250008737.0,
        LevelPathI: 250003258.0,
        Pathlength: 535.779,
        TerminalPa: 250002604.0,
        ArbolateSu: 3719.369,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008772.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008702.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5922,
        TotDASqKM: 4236.3729,
        DivDASqKM: 4236.3729,
        Tidal: 0,
        TOTMA: 1.2508546568000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23050.0,
        MAXELEVSMO: 23057.0,
        MINELEVSMO: 23054.0,
        SLOPE: 2.991e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.003000020980835,
        QA_MA: 2457.764,
        VA_MA: -9998.0,
        QC_MA: 2457.764,
        VC_MA: -9998.0,
        QE_MA: 2650.871,
        VE_MA: -9998.0,
        QA_01: 4054.076,
        VA_01: -9998.0,
        QC_01: 4054.076,
        VC_01: -9998.0,
        QE_01: 4235.132,
        VE_01: -9998.0,
        QA_02: 4281.813,
        VA_02: -9998.0,
        QC_02: 4281.813,
        VC_02: -9998.0,
        QE_02: 4326.837,
        VE_02: -9998.0,
        QA_03: 4815.384,
        VA_03: -9998.0,
        QC_03: 4815.384,
        VC_03: -9998.0,
        QE_03: 5046.612,
        VE_03: -9998.0,
        QA_04: 3664.428,
        VA_04: -9998.0,
        QC_04: 3664.428,
        VC_04: -9998.0,
        QE_04: 3845.275,
        VE_04: -9998.0,
        QA_05: 2742.97,
        VA_05: -9998.0,
        QC_05: 2742.97,
        VC_05: -9998.0,
        QE_05: 2965.079,
        VE_05: -9998.0,
        QA_06: 1601.11,
        VA_06: -9998.0,
        QC_06: 1601.11,
        VC_06: -9998.0,
        QE_06: 1879.455,
        VE_06: -9998.0,
        QA_07: 950.605,
        VA_07: -9998.0,
        QC_07: 950.605,
        VC_07: -9998.0,
        QE_07: 1117.5,
        VE_07: -9998.0,
        QA_08: 665.654,
        VA_08: -9998.0,
        QC_08: 665.654,
        VC_08: -9998.0,
        QE_08: 987.593,
        VE_08: -9998.0,
        QA_09: 638.946,
        VA_09: -9998.0,
        QC_09: 1172.987,
        VC_09: -9998.0,
        QE_09: 1222.738,
        VE_09: -9998.0,
        QA_10: 787.829,
        VA_10: -9998.0,
        QC_10: 787.829,
        VC_10: -9998.0,
        QE_10: 752.293,
        VE_10: -9998.0,
        QA_11: 2167.566,
        VA_11: -9998.0,
        QC_11: 2167.566,
        VC_11: -9998.0,
        QE_11: 2113.854,
        VE_11: -9998.0,
        QA_12: 3138.937,
        VA_12: -9998.0,
        QC_12: 3138.937,
        VC_12: -9998.0,
        QE_12: 3135.976,
        VE_12: -9998.0,
        LakeFract: 0.01685309606,
        SurfArea: 2204642.2464600001,
        RAreaHLoad: 0.33933511254999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.926203584826283, 35.6228934780396, 0.0],
          [-80.926335384826075, 35.618839611379087, 0.0],
          [-80.930069851486962, 35.615383611384459, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286863.0,
        COMID: 9734016,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 1.72,
        REACHCODE: "03050103000152",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016756532599317999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059242.0,
        ToNode: 250059243.0,
        Hydroseq: 250024246.0,
        LevelPathI: 250005808.0,
        Pathlength: 413.986,
        TerminalPa: 250002604.0,
        ArbolateSu: 100.527,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250024870.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023661.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5902,
        TotDASqKM: 85.6854,
        DivDASqKM: 85.6854,
        Tidal: 0,
        TOTMA: 0.0648158817916,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14852.0,
        MAXELEVSMO: 15076.0,
        MINELEVSMO: 14867.0,
        SLOPE: 0.00121511,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.720000028610229,
        QA_MA: 34.81,
        VA_MA: 1.00463,
        QC_MA: 36.732,
        VC_MA: 1.00767,
        QE_MA: 36.732,
        VE_MA: 1.00767,
        QA_01: 76.089,
        VA_01: 1.36301,
        QC_01: 61.597,
        VC_01: 1.22856,
        QE_01: 61.597,
        VE_01: 1.22856,
        QA_02: 78.743,
        VA_02: 1.38238,
        QC_02: 68.109,
        VC_02: 1.27899,
        QE_02: 68.109,
        VE_02: 1.27899,
        QA_03: 84.085,
        VA_03: 1.42048,
        QC_03: 73.967,
        VC_03: 1.32246,
        QE_03: 73.967,
        VE_03: 1.32246,
        QA_04: 48.385,
        VA_04: 1.13766,
        QC_04: 46.361,
        VC_04: 1.09968,
        QE_04: 46.361,
        VE_04: 1.09968,
        QA_05: 26.908,
        VA_05: 0.91565,
        QC_05: 27.016,
        VC_05: 0.90241,
        QE_05: 27.016,
        VE_05: 0.90241,
        QA_06: 17.281,
        VA_06: 0.78841,
        QC_06: 22.176,
        VC_06: 0.84329,
        QE_06: 22.176,
        VE_06: 0.84329,
        QA_07: 12.673,
        VA_07: 0.71553,
        QC_07: 19.893,
        VC_07: 0.8133,
        QE_07: 19.893,
        VE_07: 0.8133,
        QA_08: 9.216,
        VA_08: 0.65217,
        QC_08: 19.988,
        VC_08: 0.81458,
        QE_08: 19.988,
        VE_08: 0.81458,
        QA_09: 7.783,
        VA_09: 0.62267,
        QC_09: 23.94,
        VC_09: 0.86548,
        QE_09: 23.94,
        VE_09: 0.86548,
        QA_10: 5.76,
        VA_10: 0.57624,
        QC_10: 22.276,
        VC_10: 0.84457,
        QE_10: 22.276,
        VE_10: 0.84457,
        QA_11: 9.015,
        VA_11: 0.64817,
        QC_11: 20.451,
        VC_11: 0.82078,
        QE_11: 20.451,
        VE_11: 0.82078,
        QA_12: 42.02,
        VA_12: 1.07782,
        QC_12: 39.652,
        VC_12: 1.03665,
        QE_12: 39.652,
        VE_12: 1.03665,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.780077785053152, 34.848789279240975, 0.0],
          [-80.78472298504596, 34.843959679248542, 0.0],
          [-80.786561318376414, 34.839586079255412, 0.0],
          [-80.789897318371231, 34.837481012592036, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291124.0,
        COMID: 9718245,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.109,
        REACHCODE: "03050104002417",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00097395644056000005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058150.0,
        ToNode: 250058151.0,
        Hydroseq: 250004201.0,
        LevelPathI: 250003258.0,
        Pathlength: 362.449,
        TerminalPa: 250002604.0,
        ArbolateSu: 8446.59,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004209.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004193.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0639,
        TotDASqKM: 11372.9643,
        DivDASqKM: 11372.9643,
        Tidal: 0,
        TOTMA: 0.0550390967,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6799.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.108999997377396,
        QA_MA: 5476.555,
        VA_MA: -9998.0,
        QC_MA: 5476.555,
        VC_MA: -9998.0,
        QE_MA: 6174.232,
        VE_MA: -9998.0,
        QA_01: 10272.003,
        VA_01: -9998.0,
        QC_01: 10272.003,
        VC_01: -9998.0,
        QE_01: 8879.485,
        VE_01: -9998.0,
        QA_02: 10858.681,
        VA_02: -9998.0,
        QC_02: 10858.681,
        VC_02: -9998.0,
        QE_02: 9018.753,
        VE_02: -9998.0,
        QA_03: 11888.926,
        VA_03: -9998.0,
        QC_03: 11888.926,
        VC_03: -9998.0,
        QE_03: 9639.738,
        VE_03: -9998.0,
        QA_04: 7999.841,
        VA_04: -9998.0,
        QC_04: 7999.841,
        VC_04: -9998.0,
        QE_04: 7748.68,
        VE_04: -9998.0,
        QA_05: 5344.106,
        VA_05: -9998.0,
        QC_05: 5344.106,
        VC_05: -9998.0,
        QE_05: 6299.438,
        VE_05: -9998.0,
        QA_06: 3182.017,
        VA_06: -9998.0,
        QC_06: 3182.017,
        VC_06: -9998.0,
        QE_06: 5405.791,
        VE_06: -9998.0,
        QA_07: 2008.075,
        VA_07: -9998.0,
        QC_07: 2008.075,
        VC_07: -9998.0,
        QE_07: 4083.922,
        VE_07: -9998.0,
        QA_08: 1442.573,
        VA_08: -9998.0,
        QC_08: 1442.573,
        VC_08: -9998.0,
        QE_08: 3737.642,
        VE_08: -9998.0,
        QA_09: 1293.28,
        VA_09: -9998.0,
        QC_09: 1293.28,
        VC_09: -9998.0,
        QE_09: 3430.088,
        VE_09: -9998.0,
        QA_10: 1351.223,
        VA_10: -9998.0,
        QC_10: 1351.223,
        VC_10: -9998.0,
        QE_10: 4670.754,
        VE_10: -9998.0,
        QA_11: 3297.987,
        VA_11: -9998.0,
        QC_11: 3297.987,
        VC_11: -9998.0,
        QE_11: 4744.048,
        VE_11: -9998.0,
        QA_12: 6817.823,
        VA_12: -9998.0,
        QC_12: 6817.823,
        VC_12: -9998.0,
        QE_12: 6526.501,
        VE_12: -9998.0,
        LakeFract: 0.00343578726,
        SurfArea: 155807.50101599999,
        RAreaHLoad: 0.01031286469,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882133384894701, 34.500149013115561, 0.0],
          [-80.882231518227911, 34.499180013117098, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286864.0,
        COMID: 9733952,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.361,
        REACHCODE: "03050103000153",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003541919377982,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059221.0,
        ToNode: 250059242.0,
        Hydroseq: 250024870.0,
        LevelPathI: 250005808.0,
        Pathlength: 415.706,
        TerminalPa: 250002604.0,
        ArbolateSu: 96.74,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250025547.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024246.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1629,
        TotDASqKM: 81.6885,
        DivDASqKM: 81.6885,
        Tidal: 0,
        TOTMA: 0.021728600377500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15009.0,
        MAXELEVSMO: 15076.0,
        MINELEVSMO: 15076.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.36100000143051098,
        QA_MA: 33.21,
        VA_MA: 0.62943,
        QC_MA: 35.107,
        VC_MA: 0.63088,
        QE_MA: 35.107,
        VE_MA: 0.63088,
        QA_01: 72.596,
        VA_01: 0.7947,
        QC_01: 58.842,
        VC_01: 0.73263,
        QE_01: 58.842,
        VE_01: 0.73263,
        QA_02: 75.089,
        VA_02: 0.8035,
        QC_02: 65.033,
        VC_02: 0.75578,
        QE_02: 65.033,
        VE_02: 0.75578,
        QA_03: 80.166,
        VA_03: 0.821,
        QC_03: 70.631,
        VC_03: 0.77583,
        QE_03: 70.631,
        VE_03: 0.77583,
        QA_04: 46.128,
        VA_04: 0.69063,
        QC_04: 44.246,
        VC_04: 0.67303,
        QE_04: 44.246,
        VE_04: 0.67303,
        QA_05: 25.645,
        VA_05: 0.58825,
        QC_05: 25.795,
        VC_05: 0.58219,
        QE_05: 25.795,
        VE_05: 0.58219,
        QA_06: 16.475,
        VA_06: 0.52965,
        QC_06: 21.155,
        VC_06: 0.55483,
        QE_06: 21.155,
        VE_06: 0.55483,
        QA_07: 12.082,
        VA_07: 0.49605,
        QC_07: 18.987,
        VC_07: 0.54108,
        QE_07: 18.987,
        VE_07: 0.54108,
        QA_08: 8.787,
        VA_08: 0.46686,
        QC_08: 19.037,
        VC_08: 0.5414,
        QE_08: 19.037,
        VE_08: 0.5414,
        QA_09: 7.46,
        VA_09: 0.45367,
        QC_09: 23.062,
        VC_09: 0.56638,
        QE_09: 23.062,
        VE_09: 0.56638,
        QA_10: 5.492,
        VA_10: 0.43186,
        QC_10: 21.442,
        VC_10: 0.5566,
        QE_10: 21.442,
        VE_10: 0.5566,
        QA_11: 8.691,
        VA_11: 0.46594,
        QC_11: 19.889,
        VC_11: 0.54688,
        QE_11: 19.889,
        VE_11: 0.54688,
        QA_12: 40.129,
        VA_12: 0.66337,
        QC_12: 38.026,
        VC_12: 0.64485,
        QE_12: 38.026,
        VE_12: 0.64485,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.778018985056349, 34.851124079237543, 0.0],
          [-80.780077785053152, 34.848789279240975, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291125.0,
        COMID: 9718247,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.178,
        REACHCODE: "03050104002418",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0017499705808609999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058151.0,
        ToNode: 250058153.0,
        Hydroseq: 250004193.0,
        LevelPathI: 250003258.0,
        Pathlength: 362.271,
        TerminalPa: 250002604.0,
        ArbolateSu: 8446.768,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004201.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004184.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0459,
        TotDASqKM: 11373.0102,
        DivDASqKM: 11373.0102,
        Tidal: 0,
        TOTMA: 0.08988035982000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6804.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.178000003099442,
        QA_MA: 5476.57,
        VA_MA: -9998.0,
        QC_MA: 5476.57,
        VC_MA: -9998.0,
        QE_MA: 6174.247,
        VE_MA: -9998.0,
        QA_01: 10272.037,
        VA_01: -9998.0,
        QC_01: 10272.037,
        VC_01: -9998.0,
        QE_01: 8879.519,
        VE_01: -9998.0,
        QA_02: 10858.718,
        VA_02: -9998.0,
        QC_02: 10858.718,
        VC_02: -9998.0,
        QE_02: 9018.79,
        VE_02: -9998.0,
        QA_03: 11888.966,
        VA_03: -9998.0,
        QC_03: 11888.966,
        VC_03: -9998.0,
        QE_03: 9639.779,
        VE_03: -9998.0,
        QA_04: 7999.862,
        VA_04: -9998.0,
        QC_04: 7999.862,
        VC_04: -9998.0,
        QE_04: 7748.701,
        VE_04: -9998.0,
        QA_05: 5344.119,
        VA_05: -9998.0,
        QC_05: 5344.119,
        VC_05: -9998.0,
        QE_05: 6299.45,
        VE_05: -9998.0,
        QA_06: 3182.025,
        VA_06: -9998.0,
        QC_06: 3182.025,
        VC_06: -9998.0,
        QE_06: 5405.799,
        VE_06: -9998.0,
        QA_07: 2008.081,
        VA_07: -9998.0,
        QC_07: 2008.081,
        VC_07: -9998.0,
        QE_07: 4083.929,
        VE_07: -9998.0,
        QA_08: 1442.578,
        VA_08: -9998.0,
        QC_08: 1442.578,
        VC_08: -9998.0,
        QE_08: 3737.647,
        VE_08: -9998.0,
        QA_09: 1293.283,
        VA_09: -9998.0,
        QC_09: 1293.283,
        VC_09: -9998.0,
        QE_09: 3430.092,
        VE_09: -9998.0,
        QA_10: 1351.226,
        VA_10: -9998.0,
        QC_10: 1351.226,
        VC_10: -9998.0,
        QE_10: 4670.757,
        VE_10: -9998.0,
        QA_11: 3297.99,
        VA_11: -9998.0,
        QC_11: 3297.99,
        VC_11: -9998.0,
        QE_11: 4744.05,
        VE_11: -9998.0,
        QA_12: 6817.834,
        VA_12: -9998.0,
        QC_12: 6817.834,
        VC_12: -9998.0,
        QE_12: 6526.513,
        VE_12: -9998.0,
        LakeFract: 0.0056107497,
        SurfArea: 254438.59687099999,
        RAreaHLoad: 0.01684119189,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882231518227911, 34.499180013117098, 0.0],
          [-80.883467718226029, 34.497983013118983, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286865.0,
        COMID: 9733934,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.495,
        REACHCODE: "03050103000154",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0053744453686430001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059216.0,
        ToNode: 250059221.0,
        Hydroseq: 250025547.0,
        LevelPathI: 250005808.0,
        Pathlength: 416.067,
        TerminalPa: 250002604.0,
        ArbolateSu: 88.98,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250026289.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024870.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2106,
        TotDASqKM: 76.0248,
        DivDASqKM: 76.0248,
        Tidal: 0,
        TOTMA: 0.018975800663999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15041.0,
        MAXELEVSMO: 15137.0,
        MINELEVSMO: 15076.0,
        SLOPE: 0.00123232,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.49500000476837203,
        QA_MA: 30.923,
        VA_MA: 0.98733,
        QC_MA: 32.78,
        VC_MA: 0.99055,
        QE_MA: 32.78,
        VE_MA: 0.99055,
        QA_01: 67.615,
        VA_01: 1.337,
        QC_01: 54.907,
        VC_01: 1.20549,
        QE_01: 54.907,
        VE_01: 1.20549,
        QA_02: 69.911,
        VA_02: 1.3554,
        QC_02: 60.666,
        VC_02: 1.25429,
        QE_02: 60.666,
        VE_02: 1.25429,
        QA_03: 74.607,
        VA_03: 1.39217,
        QC_03: 65.889,
        VC_03: 1.29669,
        QE_03: 65.889,
        VE_03: 1.29669,
        QA_04: 42.929,
        VA_04: 1.11653,
        QC_04: 41.246,
        VC_04: 1.07906,
        QE_04: 41.246,
        VE_04: 1.07906,
        QA_05: 23.82,
        VA_05: 0.89948,
        QC_05: 24.025,
        VC_05: 0.88679,
        QE_05: 24.025,
        VE_05: 0.88679,
        QA_06: 15.333,
        VA_06: 0.77619,
        QC_06: 19.708,
        VC_06: 0.82905,
        QE_06: 19.708,
        VE_06: 0.82905,
        QA_07: 11.244,
        VA_07: 0.70515,
        QC_07: 17.701,
        VC_07: 0.80018,
        QE_07: 17.701,
        VE_07: 0.80018,
        QA_08: 8.177,
        VA_08: 0.64342,
        QC_08: 17.692,
        VC_08: 0.80005,
        QE_08: 17.692,
        VE_08: 0.80005,
        QA_09: 6.939,
        VA_09: 0.61545,
        QC_09: 21.635,
        VC_09: 0.85549,
        QE_09: 21.635,
        VE_09: 0.85549,
        QA_10: 5.111,
        VA_10: 0.56943,
        QC_10: 20.246,
        VC_10: 0.83655,
        QE_10: 20.246,
        VE_10: 0.83655,
        QA_11: 8.192,
        VA_11: 0.64374,
        QC_11: 19.012,
        VC_11: 0.8192,
        QE_11: 19.012,
        VE_11: 0.8192,
        QA_12: 37.396,
        VA_12: 1.05944,
        QC_12: 35.662,
        VC_12: 1.02177,
        QE_12: 35.662,
        VE_12: 1.02177,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.772823518397672, 34.850890879237795, 0.0],
          [-80.778018985056349, 34.851124079237543, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286866.0,
        COMID: 9733948,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "996883",
        GNIS_NAME: "Waxhaw Creek",
        LENGTHKM: 0.249,
        REACHCODE: "03050103000155",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002435227657729,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059220.0,
        ToNode: 250059216.0,
        Hydroseq: 250026289.0,
        LevelPathI: 250005808.0,
        Pathlength: 416.562,
        TerminalPa: 250002604.0,
        ArbolateSu: 86.608,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250027087.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025547.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0612,
        TotDASqKM: 74.8422,
        DivDASqKM: 74.8422,
        Tidal: 0,
        TOTMA: 0.0151389754934,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15137.0,
        MAXELEVSMO: 15137.0,
        MINELEVSMO: 15137.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.24899999797344199,
        QA_MA: 30.445,
        VA_MA: 0.62305,
        QC_MA: 32.293,
        VC_MA: 0.62456,
        QE_MA: 32.293,
        VE_MA: 0.62456,
        QA_01: 66.572,
        VA_01: 0.78512,
        QC_01: 54.082,
        VC_01: 0.72414,
        QE_01: 54.082,
        VE_01: 0.72414,
        QA_02: 68.83,
        VA_02: 0.79363,
        QC_02: 59.753,
        VC_02: 0.74675,
        QE_02: 59.753,
        VE_02: 0.74675,
        QA_03: 73.446,
        VA_03: 0.81065,
        QC_03: 64.898,
        VC_03: 0.7664,
        QE_03: 64.898,
        VE_03: 0.7664,
        QA_04: 42.261,
        VA_04: 0.68291,
        QC_04: 40.618,
        VC_04: 0.66551,
        QE_04: 40.618,
        VE_04: 0.66551,
        QA_05: 23.438,
        VA_05: 0.58225,
        QC_05: 23.655,
        VC_05: 0.57639,
        QE_05: 23.655,
        VE_05: 0.57639,
        QA_06: 15.094,
        VA_06: 0.52518,
        QC_06: 19.405,
        VC_06: 0.54964,
        QE_06: 19.405,
        VE_06: 0.54964,
        QA_07: 11.069,
        VA_07: 0.49227,
        QC_07: 17.432,
        VC_07: 0.53629,
        QE_07: 17.432,
        VE_07: 0.53629,
        QA_08: 8.05,
        VA_08: 0.46366,
        QC_08: 17.411,
        VC_08: 0.53614,
        QE_08: 17.411,
        VE_08: 0.53614,
        QA_09: 6.834,
        VA_09: 0.45073,
        QC_09: 21.345,
        VC_09: 0.56216,
        QE_09: 21.345,
        VE_09: 0.56216,
        QA_10: 5.031,
        VA_10: 0.42936,
        QC_10: 19.994,
        VC_10: 0.5535,
        QE_10: 19.994,
        VE_10: 0.5535,
        QA_11: 8.082,
        VA_11: 0.46398,
        QC_11: 18.816,
        VC_11: 0.54572,
        QE_11: 18.816,
        VE_11: 0.54572,
        QA_12: 36.824,
        VA_12: 0.6565,
        QC_12: 35.165,
        VC_12: 0.63919,
        QE_12: 35.165,
        VE_12: 0.63919,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.77118598506695, 34.849286212573702, 0.0],
          [-80.772823518397672, 34.850890879237795, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286009.0,
        COMID: 9751090,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.305,
        REACHCODE: "03050101002684",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023118491081183998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060707.0,
        ToNode: 250060710.0,
        Hydroseq: 250008772.0,
        LevelPathI: 250003258.0,
        Pathlength: 536.782,
        TerminalPa: 250002604.0,
        ArbolateSu: 3698.636,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008807.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008737.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.5028,
        TotDASqKM: 4211.3979,
        DivDASqKM: 4211.3979,
        Tidal: 0,
        TOTMA: 2.87459619438,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23049.0,
        MAXELEVSMO: 23059.0,
        MINELEVSMO: 23057.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.3050000667572021,
        QA_MA: 2447.213,
        VA_MA: -9998.0,
        QC_MA: 2447.213,
        VC_MA: -9998.0,
        QE_MA: 2640.307,
        VE_MA: -9998.0,
        QA_01: 4033.254,
        VA_01: -9998.0,
        QC_01: 4033.254,
        VC_01: -9998.0,
        QE_01: 4214.393,
        VE_01: -9998.0,
        QA_02: 4259.76,
        VA_02: -9998.0,
        QC_02: 4259.76,
        VC_02: -9998.0,
        QE_02: 4304.88,
        VE_02: -9998.0,
        QA_03: 4791.512,
        VA_03: -9998.0,
        QC_03: 4791.512,
        VC_03: -9998.0,
        QE_03: 5022.878,
        VE_03: -9998.0,
        QA_04: 3648.366,
        VA_04: -9998.0,
        QC_04: 3648.366,
        VC_04: -9998.0,
        QE_04: 3829.253,
        VE_04: -9998.0,
        QA_05: 2733.373,
        VA_05: -9998.0,
        QC_05: 2733.373,
        VC_05: -9998.0,
        QE_05: 2955.471,
        VE_05: -9998.0,
        QA_06: 1595.392,
        VA_06: -9998.0,
        QC_06: 1595.392,
        VC_06: -9998.0,
        QE_06: 1873.64,
        VE_06: -9998.0,
        QA_07: 946.912,
        VA_07: -9998.0,
        QC_07: 946.912,
        VC_07: -9998.0,
        QE_07: 1113.713,
        VE_07: -9998.0,
        QA_08: 662.967,
        VA_08: -9998.0,
        QC_08: 662.967,
        VC_08: -9998.0,
        QE_08: 984.791,
        VE_08: -9998.0,
        QA_09: 636.595,
        VA_09: -9998.0,
        QC_09: 1169.177,
        VC_09: -9998.0,
        QE_09: 1218.836,
        VE_09: -9998.0,
        QA_10: 786.15,
        VA_10: -9998.0,
        QC_10: 786.15,
        VC_10: -9998.0,
        QE_10: 750.484,
        VE_10: -9998.0,
        QA_11: 2163.105,
        VA_11: -9998.0,
        QC_11: 2163.105,
        VC_11: -9998.0,
        QE_11: 2109.358,
        VE_11: -9998.0,
        QA_12: 3125.298,
        VA_12: -9998.0,
        QC_12: 3125.298,
        VC_12: -9998.0,
        QE_12: 3122.387,
        VE_12: -9998.0,
        LakeFract: 0.03857613682,
        SurfArea: 5046347.6049499996,
        RAreaHLoad: 0.77982795030999996,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.9433247847997, 35.637164811350772, 0.0],
          [-80.936082984811037, 35.628502478030782, 0.0],
          [-80.929001784821992, 35.625830478034977, 0.0],
          [-80.926203584826283, 35.6228934780396, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283955.0,
        COMID: 9754686,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.068,
        REACHCODE: "03050101000370",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00075764287270499995,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061683.0,
        ToNode: 250061217.0,
        Hydroseq: 250016306.0,
        LevelPathI: 250003258.0,
        Pathlength: 680.142,
        TerminalPa: 250002604.0,
        ArbolateSu: 443.184,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250016505.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016106.0,
        FromMeas: 43.10818,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 468.4041,
        DivDASqKM: 468.4041,
        Tidal: 0,
        TOTMA: 0.0031441233384300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36428.0,
        MAXELEVSMO: 36618.0,
        MINELEVSMO: 36618.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.068000003695488004,
        QA_MA: 308.414,
        VA_MA: 0.80702,
        QC_MA: 299.025,
        VC_MA: 0.80577,
        QE_MA: 436.62,
        VE_MA: 0.82126,
        QA_01: 488.846,
        VA_01: 0.94518,
        QC_01: 376.911,
        VC_01: 0.87083,
        QE_01: 434.298,
        VE_01: 0.81981,
        QA_02: 515.487,
        VA_02: 0.96338,
        QC_02: 423.61,
        VC_02: 0.90681,
        QE_02: 434.588,
        VE_02: 0.81999,
        QA_03: 583.219,
        VA_03: 1.00776,
        QC_03: 481.27,
        VC_03: 0.94877,
        QE_03: 623.929,
        VE_03: 0.9283,
        QA_04: 439.579,
        VA_04: 0.91026,
        QC_04: 400.587,
        VC_04: 0.88932,
        QE_04: 539.359,
        VE_04: 0.88216,
        QA_05: 351.802,
        VA_05: 0.84312,
        QC_05: 320.833,
        VC_05: 0.82472,
        QE_05: 513.083,
        VE_05: 0.86715,
        QA_06: 201.273,
        VA_06: 0.70591,
        QC_06: 249.72,
        VC_06: 0.76039,
        QE_06: 446.805,
        VE_06: 0.82758,
        QA_07: 116.757,
        VA_07: 0.60609,
        QC_07: 173.799,
        VC_07: 0.68126,
        QE_07: 281.235,
        VE_07: 0.71443,
        QA_08: 79.184,
        VA_08: 0.55062,
        QC_08: 179.286,
        VC_08: 0.68746,
        QE_08: 292.629,
        VE_08: 0.72308,
        QA_09: 88.467,
        VA_09: 0.5653,
        QC_09: 204.724,
        VC_09: 0.71513,
        QE_09: 337.377,
        VE_09: 0.75563,
        QA_10: 133.416,
        VA_10: 0.62794,
        QC_10: 274.189,
        VC_10: 0.78339,
        QE_10: 351.429,
        VE_10: 0.76543,
        QA_11: 311.523,
        VA_11: 0.80968,
        QC_11: 304.817,
        VC_11: 0.81087,
        QE_11: 409.544,
        VE_11: 0.80412,
        QA_12: 393.703,
        VA_12: 0.87604,
        QC_12: 303.646,
        VC_12: 0.80984,
        QE_12: 393.389,
        VE_12: 0.79364,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.000585383158636, 35.718722477890879, 0.0],
          [-81.999830516493205, 35.718787277890726, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291163.0,
        COMID: 9718361,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 1.125,
        REACHCODE: "03050104002456",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010909353018335999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058208.0,
        ToNode: 250058210.0,
        Hydroseq: 250004060.0,
        LevelPathI: 250003258.0,
        Pathlength: 351.575,
        TerminalPa: 250002604.0,
        ArbolateSu: 8751.066,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004070.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004049.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0404,
        TotDASqKM: 11801.5389,
        DivDASqKM: 11801.5389,
        Tidal: 0,
        TOTMA: 0.5680640714,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6742.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.125,
        QA_MA: 5631.637,
        VA_MA: -9998.0,
        QC_MA: 5631.637,
        VC_MA: -9998.0,
        QE_MA: 6329.503,
        VE_MA: -9998.0,
        QA_01: 10612.236,
        VA_01: -9998.0,
        QC_01: 10612.236,
        VC_01: -9998.0,
        QE_01: 9218.908,
        VE_01: -9998.0,
        QA_02: 11232.037,
        VA_02: -9998.0,
        QC_02: 11232.037,
        VC_02: -9998.0,
        QE_02: 9391.271,
        VE_02: -9998.0,
        QA_03: 12293.516,
        VA_03: -9998.0,
        QC_03: 12293.516,
        VC_03: -9998.0,
        QE_03: 10043.056,
        VE_03: -9998.0,
        QA_04: 8220.472,
        VA_04: -9998.0,
        QC_04: 8220.472,
        VC_04: -9998.0,
        QE_04: 7969.001,
        VE_04: -9998.0,
        QA_05: 5468.092,
        VA_05: -9998.0,
        QC_05: 5468.092,
        VC_05: -9998.0,
        QE_05: 6423.443,
        VE_05: -9998.0,
        QA_06: 3262.254,
        VA_06: -9998.0,
        QC_06: 3262.254,
        VC_06: -9998.0,
        QE_06: 5486.823,
        VE_06: -9998.0,
        QA_07: 2065.698,
        VA_07: -9998.0,
        QC_07: 2065.698,
        VC_07: -9998.0,
        QE_07: 4142.265,
        VE_07: -9998.0,
        QA_08: 1488.671,
        VA_08: -9998.0,
        QC_08: 1488.671,
        VC_08: -9998.0,
        QE_08: 3784.736,
        VE_08: -9998.0,
        QA_09: 1327.853,
        VA_09: -9998.0,
        QC_09: 1327.853,
        VC_09: -9998.0,
        QE_09: 3465.467,
        VE_09: -9998.0,
        QA_10: 1380.034,
        VA_10: -9998.0,
        QC_10: 1380.034,
        VC_10: -9998.0,
        QE_10: 4700.888,
        VE_10: -9998.0,
        QA_11: 3321.037,
        VA_11: -9998.0,
        QC_11: 3321.037,
        VC_11: -9998.0,
        QE_11: 4767.784,
        VE_11: -9998.0,
        QA_12: 6946.52,
        VA_12: -9998.0,
        QC_12: 6946.52,
        VC_12: -9998.0,
        QE_12: 6654.825,
        VE_12: -9998.0,
        LakeFract: 0.03635275417,
        SurfArea: 1648539.72428,
        RAreaHLoad: 0.10644011716,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.853612318272326, 34.432937613219792, 0.0],
          [-80.846520718283386, 34.424915213232282, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283956.0,
        COMID: 9753064,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.052,
        REACHCODE: "03050101000370",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00057513862208800003,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061217.0,
        ToNode: 250061589.0,
        Hydroseq: 250016106.0,
        LevelPathI: 250003258.0,
        Pathlength: 680.09,
        TerminalPa: 250002604.0,
        ArbolateSu: 443.236,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250016306.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015943.0,
        FromMeas: 0.0,
        ToMeas: 43.10818,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 468.4122,
        DivDASqKM: 468.4122,
        Tidal: 0,
        TOTMA: 0.0024043295306800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36618.0,
        MAXELEVSMO: 36618.0,
        MINELEVSMO: 36618.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052000001072884001,
        QA_MA: 308.419,
        VA_MA: 0.80702,
        QC_MA: 299.03,
        VC_MA: 0.80578,
        QE_MA: 436.624,
        VE_MA: 0.82126,
        QA_01: 488.853,
        VA_01: 0.94519,
        QC_01: 376.917,
        VC_01: 0.87083,
        QE_01: 434.304,
        VE_01: 0.81981,
        QA_02: 515.495,
        VA_02: 0.96338,
        QC_02: 423.617,
        VC_02: 0.90682,
        QE_02: 434.595,
        VE_02: 0.81999,
        QA_03: 583.228,
        VA_03: 1.00776,
        QC_03: 481.278,
        VC_03: 0.94877,
        QE_03: 623.937,
        VE_03: 0.9283,
        QA_04: 439.586,
        VA_04: 0.91026,
        QC_04: 400.594,
        VC_04: 0.88932,
        QE_04: 539.365,
        VE_04: 0.88216,
        QA_05: 351.808,
        VA_05: 0.84312,
        QC_05: 320.838,
        VC_05: 0.82472,
        QE_05: 513.088,
        VE_05: 0.86715,
        QA_06: 201.276,
        VA_06: 0.70591,
        QC_06: 249.723,
        VC_06: 0.76039,
        QE_06: 446.809,
        VE_06: 0.82758,
        QA_07: 116.759,
        VA_07: 0.60609,
        QC_07: 173.801,
        VC_07: 0.68126,
        QE_07: 281.238,
        VE_07: 0.71443,
        QA_08: 79.185,
        VA_08: 0.55062,
        QC_08: 179.288,
        VC_08: 0.68746,
        QE_08: 292.632,
        VE_08: 0.72308,
        QA_09: 88.468,
        VA_09: 0.56531,
        QC_09: 204.726,
        VC_09: 0.71513,
        QE_09: 337.379,
        VE_09: 0.75563,
        QA_10: 133.417,
        VA_10: 0.62794,
        QC_10: 274.19,
        VC_10: 0.78339,
        QE_10: 351.43,
        VE_10: 0.76543,
        QA_11: 311.528,
        VA_11: 0.80968,
        QC_11: 304.82,
        VC_11: 0.81087,
        QE_11: 409.547,
        VE_11: 0.80412,
        QA_12: 393.709,
        VA_12: 0.87604,
        QC_12: 303.651,
        VC_12: 0.80984,
        QE_12: 393.394,
        VE_12: 0.79364,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.999830516493205, 35.718787277890726, 0.0],
          [-81.999255383160687, 35.718784811224054, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283957.0,
        COMID: 9754690,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.235,
        REACHCODE: "03050101000371",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002597980715103,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061684.0,
        ToNode: 250061683.0,
        Hydroseq: 250016505.0,
        LevelPathI: 250003258.0,
        Pathlength: 680.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 439.905,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250016712.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016306.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1728,
        TotDASqKM: 466.0425,
        DivDASqKM: 466.0425,
        Tidal: 0,
        TOTMA: 0.010872471514899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36458.0,
        MAXELEVSMO: 36618.0,
        MINELEVSMO: 36618.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.234999999403954,
        QA_MA: 307.05,
        VA_MA: 0.80647,
        QC_MA: 297.754,
        VC_MA: 0.80523,
        QE_MA: 435.349,
        VE_MA: 0.82075,
        QA_01: 486.571,
        VA_01: 0.9444,
        QC_01: 375.203,
        VC_01: 0.87013,
        QE_01: 432.59,
        VE_01: 0.81903,
        QA_02: 513.049,
        VA_02: 0.96255,
        QC_02: 421.661,
        VC_02: 0.90605,
        QE_02: 432.639,
        VE_02: 0.81906,
        QA_03: 580.397,
        VA_03: 1.00683,
        QC_03: 479.018,
        VC_03: 0.94792,
        QE_03: 621.678,
        VE_03: 0.92746,
        QA_04: 437.487,
        VA_04: 0.90948,
        QC_04: 398.724,
        VC_04: 0.88856,
        QE_04: 537.496,
        VE_04: 0.88143,
        QA_05: 350.195,
        VA_05: 0.84248,
        QC_05: 319.422,
        VC_05: 0.82411,
        QE_05: 511.672,
        VE_05: 0.86664,
        QA_06: 200.405,
        VA_06: 0.70549,
        QC_06: 248.658,
        VC_06: 0.75989,
        QE_06: 445.743,
        VE_06: 0.82721,
        QA_07: 116.241,
        VA_07: 0.60576,
        QC_07: 173.049,
        VC_07: 0.68084,
        QE_07: 280.486,
        VE_07: 0.71408,
        QA_08: 78.834,
        VA_08: 0.55035,
        QC_08: 178.479,
        VC_08: 0.687,
        QE_08: 291.822,
        VE_08: 0.72271,
        QA_09: 88.213,
        VA_09: 0.56523,
        QC_09: 204.205,
        VC_09: 0.71506,
        QE_09: 336.858,
        VE_09: 0.75552,
        QA_10: 133.22,
        VA_10: 0.62808,
        QC_10: 273.867,
        VC_10: 0.78365,
        QE_10: 351.107,
        VE_10: 0.76546,
        QA_11: 310.334,
        VA_11: 0.80929,
        QC_11: 303.928,
        VC_11: 0.81067,
        QE_11: 408.656,
        VE_11: 0.80382,
        QA_12: 391.921,
        VA_12: 0.87538,
        QC_12: 302.396,
        VC_12: 0.80933,
        QE_12: 392.139,
        VE_12: 0.79309,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.003140183154642, 35.719045477890234, 0.0],
          [-82.000585383158636, 35.718722477890879, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291165.0,
        COMID: 9718365,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.392,
        REACHCODE: "03050104002458",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.004010944512696,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058210.0,
        ToNode: 250058219.0,
        Hydroseq: 250004049.0,
        LevelPathI: 250003258.0,
        Pathlength: 351.183,
        TerminalPa: 250002604.0,
        ArbolateSu: 8882.001,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004060.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004036.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1323,
        TotDASqKM: 11912.2524,
        DivDASqKM: 11912.2524,
        Tidal: 0,
        TOTMA: 0.19793876977,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6772.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.39199998974800099,
        QA_MA: 5671.844,
        VA_MA: -9998.0,
        QC_MA: 5671.844,
        VC_MA: -9998.0,
        QE_MA: 6369.759,
        VE_MA: -9998.0,
        QA_01: 10701.657,
        VA_01: -9998.0,
        QC_01: 10701.657,
        VC_01: -9998.0,
        QE_01: 9308.119,
        VE_01: -9998.0,
        QA_02: 11328.304,
        VA_02: -9998.0,
        QC_02: 11328.304,
        VC_02: -9998.0,
        QE_02: 9487.322,
        VE_02: -9998.0,
        QA_03: 12397.684,
        VA_03: -9998.0,
        QC_03: 12397.684,
        VC_03: -9998.0,
        QE_03: 10146.896,
        VE_03: -9998.0,
        QA_04: 8277.147,
        VA_04: -9998.0,
        QC_04: 8277.147,
        VC_04: -9998.0,
        QE_04: 8025.596,
        VE_04: -9998.0,
        QA_05: 5499.938,
        VA_05: -9998.0,
        QC_05: 5499.938,
        VC_05: -9998.0,
        QE_05: 6455.294,
        VE_05: -9998.0,
        QA_06: 3283.094,
        VA_06: -9998.0,
        QC_06: 3283.094,
        VC_06: -9998.0,
        QE_06: 5507.869,
        VE_06: -9998.0,
        QA_07: 2080.583,
        VA_07: -9998.0,
        QC_07: 2080.583,
        VC_07: -9998.0,
        QE_07: 4157.337,
        VE_07: -9998.0,
        QA_08: 1500.58,
        VA_08: -9998.0,
        QC_08: 1500.58,
        VC_08: -9998.0,
        QE_08: 3796.901,
        VE_08: -9998.0,
        QA_09: 1336.785,
        VA_09: -9998.0,
        QC_09: 1336.785,
        VC_09: -9998.0,
        QE_09: 3474.607,
        VE_09: -9998.0,
        QA_10: 1387.477,
        VA_10: -9998.0,
        QC_10: 1387.477,
        VC_10: -9998.0,
        QE_10: 4708.673,
        VE_10: -9998.0,
        QA_11: 3326.991,
        VA_11: -9998.0,
        QC_11: 3326.991,
        VC_11: -9998.0,
        QE_11: 4773.915,
        VE_11: -9998.0,
        QA_12: 6981.0,
        VA_12: -9998.0,
        QC_12: 6981.0,
        VC_12: -9998.0,
        QE_12: 6689.207,
        VE_12: -9998.0,
        LakeFract: 0.01274746547,
        SurfArea: 578077.33391699998,
        RAreaHLoad: 0.03708846749,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.846520718283386, 34.424915213232282, 0.0],
          [-80.843239584955086, 34.4230174132353, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283958.0,
        COMID: 9754684,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.898,
        REACHCODE: "03050101000372",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.019599810208901999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061682.0,
        ToNode: 250061684.0,
        Hydroseq: 250016712.0,
        LevelPathI: 250003258.0,
        Pathlength: 680.445,
        TerminalPa: 250002604.0,
        ArbolateSu: 436.654,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250016925.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016505.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5093,
        TotDASqKM: 462.2472,
        DivDASqKM: 462.2472,
        Tidal: 0,
        TOTMA: 0.087901449597399997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36446.0,
        MAXELEVSMO: 36618.0,
        MINELEVSMO: 36618.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8980000019073491,
        QA_MA: 304.696,
        VA_MA: 0.80555,
        QC_MA: 295.559,
        VC_MA: 0.80433,
        QE_MA: 433.154,
        VE_MA: 0.81992,
        QA_01: 482.762,
        VA_01: 0.94317,
        QC_01: 372.343,
        VC_01: 0.86903,
        QE_01: 429.729,
        VE_01: 0.81777,
        QA_02: 508.95,
        VA_02: 0.96123,
        QC_02: 418.384,
        VC_02: 0.90483,
        QE_02: 429.361,
        VE_02: 0.81754,
        QA_03: 575.712,
        VA_03: 1.0054,
        QC_03: 475.278,
        VC_03: 0.94661,
        QE_03: 617.938,
        VE_03: 0.92613,
        QA_04: 433.96,
        VA_04: 0.90825,
        QC_04: 395.583,
        VC_04: 0.88735,
        QE_04: 534.354,
        VE_04: 0.88025,
        QA_05: 347.433,
        VA_05: 0.84144,
        QC_05: 316.997,
        VC_05: 0.82311,
        QE_05: 509.246,
        VE_05: 0.86582,
        QA_06: 198.899,
        VA_06: 0.70479,
        QC_06: 246.815,
        VC_06: 0.75906,
        QE_06: 443.901,
        VE_06: 0.82662,
        QA_07: 115.353,
        VA_07: 0.60522,
        QC_07: 171.758,
        VC_07: 0.68014,
        QE_07: 279.195,
        VE_07: 0.71352,
        QA_08: 78.266,
        VA_08: 0.54996,
        QC_08: 177.166,
        VC_08: 0.68631,
        QE_08: 290.509,
        VE_08: 0.72215,
        QA_09: 87.784,
        VA_09: 0.56514,
        QC_09: 203.328,
        VC_09: 0.71499,
        QE_09: 335.981,
        VE_09: 0.75537,
        QA_10: 132.759,
        VA_10: 0.62821,
        QC_10: 273.109,
        VC_10: 0.78395,
        QE_10: 350.349,
        VE_10: 0.76542,
        QA_11: 307.984,
        VA_11: 0.80839,
        QC_11: 302.172,
        VC_11: 0.81019,
        QE_11: 406.899,
        VE_11: 0.80322,
        QA_12: 388.72,
        VA_12: 0.87419,
        QC_12: 300.148,
        VC_12: 0.8084,
        QE_12: 389.891,
        VE_12: 0.79212,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.019087183129955, 35.721486811219847, 0.0],
          [-82.013101516472602, 35.724802277881281, 0.0],
          [-82.006055516483514, 35.722826611217727, 0.0],
          [-82.003140183154642, 35.719045477890234, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287043.0,
        COMID: 9734778,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.133,
        REACHCODE: "03050103000316",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00140031297995,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059512.0,
        ToNode: 250059517.0,
        Hydroseq: 250020557.0,
        LevelPathI: 250015946.0,
        Pathlength: 401.588,
        TerminalPa: 250002604.0,
        ArbolateSu: 227.353,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250020925.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020200.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0306,
        TotDASqKM: 605.9475,
        DivDASqKM: 603.4419,
        Tidal: 0,
        TOTMA: 0.00618387067396,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12032.0,
        MAXELEVSMO: 12137.0,
        MINELEVSMO: 12137.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13300000131130199,
        QA_MA: 251.313,
        VA_MA: 0.81765,
        QC_MA: 245.606,
        VC_MA: 0.8167,
        QE_MA: 245.606,
        VE_MA: 0.8167,
        QA_01: 539.92,
        VA_01: 1.07275,
        QC_01: 415.208,
        VC_01: 0.98015,
        QE_01: 415.208,
        VE_01: 0.98015,
        QA_02: 566.209,
        VA_02: 1.09229,
        QC_02: 464.104,
        VC_02: 1.02107,
        QE_02: 464.104,
        VE_02: 1.02107,
        QA_03: 608.775,
        VA_03: 1.12305,
        QC_03: 501.648,
        VC_03: 1.05112,
        QE_03: 501.648,
        VE_03: 1.05112,
        QA_04: 361.205,
        VA_04: 0.92583,
        QC_04: 330.638,
        VC_04: 0.90363,
        QE_04: 330.638,
        VE_04: 0.90363,
        QA_05: 200.265,
        VA_05: 0.75981,
        QC_05: 186.527,
        VC_05: 0.74761,
        QE_05: 186.527,
        VE_05: 0.74761,
        QA_06: 127.067,
        VA_06: 0.66293,
        QC_06: 158.652,
        VC_06: 0.71143,
        QE_06: 158.652,
        VE_06: 0.71143,
        QA_07: 87.672,
        VA_07: 0.59952,
        QC_07: 131.401,
        VC_07: 0.67305,
        QE_07: 131.401,
        VE_07: 0.67305,
        QA_08: 64.907,
        VA_08: 0.55656,
        QC_08: 146.377,
        VC_08: 0.69456,
        QE_08: 146.377,
        VE_08: 0.69456,
        QA_09: 55.814,
        VA_09: 0.53745,
        QC_09: 136.319,
        VC_09: 0.68024,
        QE_09: 136.319,
        VE_09: 0.68024,
        QA_10: 40.64,
        VA_10: 0.50193,
        QC_10: 106.085,
        VC_10: 0.63388,
        QE_10: 106.085,
        VE_10: 0.63388,
        QA_11: 64.486,
        VA_11: 0.55571,
        QC_11: 91.702,
        VC_11: 0.60965,
        QE_11: 91.702,
        VE_11: 0.60965,
        QA_12: 300.578,
        VA_12: 0.86843,
        QC_12: 237.534,
        VC_12: 0.80776,
        QE_12: 237.534,
        VE_12: 0.80776,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.962181184770486, 34.718679812776429, 0.0],
          [-80.960959984772387, 34.718131012777235, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286018.0,
        COMID: 9751108,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 4.317,
        REACHCODE: "03050101002693",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.04264128422229,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060716.0,
        ToNode: 250060734.0,
        Hydroseq: 250008702.0,
        LevelPathI: 250003258.0,
        Pathlength: 531.462,
        TerminalPa: 250002604.0,
        ArbolateSu: 3758.316,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008737.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008667.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 14.5629,
        TotDASqKM: 4296.3858,
        DivDASqKM: 4296.3858,
        Tidal: 0,
        TOTMA: 5.3837881873000004,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23048.0,
        MAXELEVSMO: 23054.0,
        MINELEVSMO: 23052.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.3169999122619629,
        QA_MA: 2482.881,
        VA_MA: -9998.0,
        QC_MA: 2482.881,
        VC_MA: -9998.0,
        QE_MA: 2676.018,
        VE_MA: -9998.0,
        QA_01: 4104.163,
        VA_01: -9998.0,
        QC_01: 4104.163,
        VC_01: -9998.0,
        QE_01: 4285.022,
        VE_01: -9998.0,
        QA_02: 4334.863,
        VA_02: -9998.0,
        QC_02: 4334.863,
        VC_02: -9998.0,
        QE_02: 4379.654,
        VE_02: -9998.0,
        QA_03: 4872.767,
        VA_03: -9998.0,
        QC_03: 4872.767,
        VC_03: -9998.0,
        QE_03: 5103.665,
        VE_03: -9998.0,
        QA_04: 3702.563,
        VA_04: -9998.0,
        QC_04: 3702.563,
        VC_04: -9998.0,
        QE_04: 3883.313,
        VE_04: -9998.0,
        QA_05: 2765.233,
        VA_05: -9998.0,
        QC_05: 2765.233,
        VC_05: -9998.0,
        QE_05: 2987.369,
        VE_05: -9998.0,
        QA_06: 1614.408,
        VA_06: -9998.0,
        QC_06: 1614.408,
        VC_06: -9998.0,
        QE_06: 1892.984,
        VE_06: -9998.0,
        QA_07: 959.424,
        VA_07: -9998.0,
        QC_07: 959.424,
        VC_07: -9998.0,
        QE_07: 1126.544,
        VE_07: -9998.0,
        QA_08: 671.959,
        VA_08: -9998.0,
        QC_08: 671.959,
        VC_08: -9998.0,
        QE_08: 994.177,
        VE_08: -9998.0,
        QA_09: 644.594,
        VA_09: -9998.0,
        QC_09: 1182.137,
        VC_09: -9998.0,
        QE_09: 1232.105,
        VE_09: -9998.0,
        QA_10: 791.863,
        VA_10: -9998.0,
        QC_10: 791.863,
        VC_10: -9998.0,
        QE_10: 756.642,
        VE_10: -9998.0,
        QA_11: 2177.946,
        VA_11: -9998.0,
        QC_11: 2177.946,
        VC_11: -9998.0,
        QE_11: 2124.318,
        VE_11: -9998.0,
        QA_12: 3171.255,
        VA_12: -9998.0,
        QC_12: 3171.255,
        VC_12: -9998.0,
        QE_12: 3168.176,
        VE_12: -9998.0,
        LakeFract: 0.07322410971,
        SurfArea: 9578831.3999400008,
        RAreaHLoad: 1.46052809615,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.930069851486962, 35.615383611384459, 0.0],
          [-80.936080584811009, 35.614933211385221, 0.0],
          [-80.940672318137217, 35.612265278055986, 0.0],
          [-80.941627984802381, 35.608317078062214, 0.0],
          [-80.943811318132362, 35.606697278064644, 0.0],
          [-80.943506784799482, 35.603130278070239, 0.0],
          [-80.933913384814332, 35.595482211415401, 0.0],
          [-80.937342184808927, 35.588228011426622, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287044.0,
        COMID: 9734790,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.389,
        REACHCODE: "03050103000317",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014692808733456001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059518.0,
        ToNode: 250059512.0,
        Hydroseq: 250020925.0,
        LevelPathI: 250015946.0,
        Pathlength: 401.721,
        TerminalPa: 250002604.0,
        ArbolateSu: 224.602,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250021308.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020557.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6992,
        TotDASqKM: 603.4113,
        DivDASqKM: 603.4113,
        Tidal: 0,
        TOTMA: 0.038978149036199998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12029.0,
        MAXELEVSMO: 12266.0,
        MINELEVSMO: 12137.0,
        SLOPE: 0.00092872,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3890000581741331,
        QA_MA: 251.301,
        VA_MA: 1.35511,
        QC_MA: 245.594,
        VC_MA: 1.35317,
        QE_MA: 245.594,
        VE_MA: 1.35317,
        QA_01: 539.894,
        VA_01: 1.87942,
        QC_01: 415.188,
        VC_01: 1.68911,
        QE_01: 415.188,
        VE_01: 1.68911,
        QA_02: 566.182,
        VA_02: 1.91958,
        QC_02: 464.082,
        VC_02: 1.77319,
        QE_02: 464.082,
        VE_02: 1.77319,
        QA_03: 608.746,
        VA_03: 1.98281,
        QC_03: 501.625,
        VC_03: 1.83497,
        QE_03: 501.625,
        VE_03: 1.83497,
        QA_04: 361.188,
        VA_04: 1.57746,
        QC_04: 330.623,
        VC_04: 1.53183,
        QE_04: 330.623,
        VE_04: 1.53183,
        QA_05: 200.256,
        VA_05: 1.23622,
        QC_05: 186.518,
        VC_05: 1.21115,
        QE_05: 186.518,
        VE_05: 1.21115,
        QA_06: 127.061,
        VA_06: 1.03711,
        QC_06: 158.644,
        VC_06: 1.1368,
        QE_06: 158.644,
        VE_06: 1.1368,
        QA_07: 87.668,
        VA_07: 0.90677,
        QC_07: 131.395,
        VC_07: 1.05791,
        QE_07: 131.395,
        VE_07: 1.05791,
        QA_08: 64.904,
        VA_08: 0.81848,
        QC_08: 146.37,
        VC_08: 1.10212,
        QE_08: 146.37,
        VE_08: 1.10212,
        QA_09: 55.811,
        VA_09: 0.77919,
        QC_09: 136.313,
        VC_09: 1.07268,
        QE_09: 136.313,
        VE_09: 1.07268,
        QA_10: 40.638,
        VA_10: 0.70619,
        QC_10: 106.081,
        VC_10: 0.9774,
        QE_10: 106.081,
        VE_10: 0.9774,
        QA_11: 64.484,
        VA_11: 0.81672,
        QC_11: 91.7,
        VC_11: 0.92759,
        QE_11: 91.7,
        VE_11: 0.92759,
        QA_12: 300.564,
        VA_12: 1.45949,
        QC_12: 237.524,
        VC_12: 1.33479,
        QE_12: 237.524,
        VE_12: 1.33479,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.975244984750248, 34.716676612779452, 0.0],
          [-80.971167784756517, 34.71732141277846, 0.0],
          [-80.969422518092529, 34.719384279441897, 0.0],
          [-80.962181184770486, 34.718679812776429, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287045.0,
        COMID: 9734792,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.979,
        REACHCODE: "03050103000318",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0093213234128099991,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059519.0,
        ToNode: 250059518.0,
        Hydroseq: 250021308.0,
        LevelPathI: 250015946.0,
        Pathlength: 403.11,
        TerminalPa: 250002604.0,
        ArbolateSu: 221.178,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250021710.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020925.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0323,
        TotDASqKM: 599.1165,
        DivDASqKM: 599.1165,
        Tidal: 0,
        TOTMA: 0.045563497666500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12089.0,
        MAXELEVSMO: 12266.0,
        MINELEVSMO: 12266.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97899997234344505,
        QA_MA: 249.629,
        VA_MA: 0.81683,
        QC_MA: 244.023,
        VC_MA: 0.8159,
        QE_MA: 244.023,
        VE_MA: 0.8159,
        QA_01: 536.235,
        VA_01: 1.07146,
        QC_01: 412.448,
        VC_01: 0.97901,
        QE_01: 412.448,
        VE_01: 0.97901,
        QA_02: 562.314,
        VA_02: 1.09095,
        QC_02: 460.998,
        VC_02: 1.01984,
        QE_02: 460.998,
        VE_02: 1.01984,
        QA_03: 604.586,
        VA_03: 1.12166,
        QC_03: 498.309,
        VC_03: 1.04985,
        QE_03: 498.309,
        VE_03: 1.04985,
        QA_04: 358.8,
        VA_04: 0.92485,
        QC_04: 328.486,
        VC_04: 0.90266,
        QE_04: 328.486,
        VE_04: 0.90266,
        QA_05: 198.928,
        VA_05: 0.75909,
        QC_05: 185.327,
        VC_05: 0.74691,
        QE_05: 185.327,
        VE_05: 0.74691,
        QA_06: 126.195,
        VA_06: 0.66233,
        QC_06: 157.577,
        VC_06: 0.71072,
        QE_06: 157.577,
        VE_06: 0.71072,
        QA_07: 87.09,
        VA_07: 0.59906,
        QC_07: 130.551,
        VC_07: 0.67247,
        QE_07: 130.551,
        VE_07: 0.67247,
        QA_08: 64.442,
        VA_08: 0.5561,
        QC_08: 145.307,
        VC_08: 0.69377,
        QE_08: 145.307,
        VE_08: 0.69377,
        QA_09: 55.465,
        VA_09: 0.53713,
        QC_09: 135.566,
        VC_09: 0.67983,
        QE_09: 135.566,
        VE_09: 0.67983,
        QA_10: 40.35,
        VA_10: 0.50158,
        QC_10: 105.479,
        VC_10: 0.63349,
        QE_10: 105.479,
        VE_10: 0.63349,
        QA_11: 64.199,
        VA_11: 0.5556,
        QC_11: 91.391,
        VC_11: 0.60966,
        QE_11: 91.391,
        VE_11: 0.60966,
        QA_12: 298.723,
        VA_12: 0.86769,
        QC_12: 236.2,
        VC_12: 0.80719,
        QE_12: 236.2,
        VE_12: 0.80719,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.980080984742699, 34.724048079434681, 0.0],
          [-80.975244984750248, 34.716676612779452, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287046.0,
        COMID: 9734750,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.622,
        REACHCODE: "03050103000319",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015429109769243001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059503.0,
        ToNode: 250059519.0,
        Hydroseq: 250021710.0,
        LevelPathI: 250015946.0,
        Pathlength: 404.089,
        TerminalPa: 250002604.0,
        ArbolateSu: 216.348,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250022149.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021308.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2393,
        TotDASqKM: 591.3486,
        DivDASqKM: 591.3486,
        Tidal: 0,
        TOTMA: 0.046291445881099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12109.0,
        MAXELEVSMO: 12401.0,
        MINELEVSMO: 12266.0,
        SLOPE: 0.0008323,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.621999979019165,
        QA_MA: 246.575,
        VA_MA: 1.33237,
        QC_MA: 241.154,
        VC_MA: 1.33052,
        QE_MA: 241.154,
        VE_MA: 1.33052,
        QA_01: 529.551,
        VA_01: 1.84498,
        QC_01: 407.441,
        VC_01: 1.65876,
        QE_01: 407.441,
        VE_01: 1.65876,
        QA_02: 555.249,
        VA_02: 1.88414,
        QC_02: 455.362,
        VC_02: 1.7409,
        QE_02: 455.362,
        VE_02: 1.7409,
        QA_03: 596.999,
        VA_03: 1.94598,
        QC_03: 492.261,
        VC_03: 1.80141,
        QE_03: 492.261,
        VE_03: 1.80141,
        QA_04: 354.422,
        VA_04: 1.54993,
        QC_04: 324.569,
        VC_04: 1.50517,
        QE_04: 324.569,
        VE_04: 1.50517,
        QA_05: 196.524,
        VA_05: 1.21615,
        QC_05: 183.171,
        VC_05: 1.19165,
        QE_05: 183.171,
        VE_05: 1.19165,
        QA_06: 124.628,
        VA_06: 1.02114,
        QC_06: 155.648,
        VC_06: 1.11849,
        QE_06: 155.648,
        VE_06: 1.11849,
        QA_07: 86.046,
        VA_07: 0.89386,
        QC_07: 129.022,
        VC_07: 1.04168,
        QE_07: 129.022,
        VE_07: 1.04168,
        QA_08: 63.606,
        VA_08: 0.80707,
        QC_08: 143.386,
        VC_08: 1.08395,
        QE_08: 143.386,
        VE_08: 1.08395,
        QA_09: 54.838,
        VA_09: 0.7693,
        QC_09: 134.213,
        VC_09: 1.0572,
        QE_09: 134.213,
        VE_09: 1.0572,
        QA_10: 39.827,
        VA_10: 0.69731,
        QC_10: 104.387,
        VC_10: 0.96364,
        QE_10: 104.387,
        VE_10: 0.96364,
        QA_11: 63.677,
        VA_11: 0.80736,
        QC_11: 90.824,
        VC_11: 0.91696,
        QE_11: 90.824,
        VE_11: 0.91696,
        QA_12: 295.287,
        VA_12: 1.43524,
        QC_12: 233.727,
        VC_12: 1.31369,
        QE_12: 233.727,
        VE_12: 1.31369,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.977737784746353, 34.736350612748879, 0.0],
          [-80.976317118081852, 34.731610412756368, 0.0],
          [-80.981360584740742, 34.726727012763888, 0.0],
          [-80.980080984742699, 34.724048079434681, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291171.0,
        COMID: 9718379,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.977,
        REACHCODE: "03050104002464",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.018393227913443,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058217.0,
        ToNode: 250058223.0,
        Hydroseq: 250021319.0,
        LevelPathI: 250021319.0,
        Pathlength: 344.867,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.593,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250021720.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003976.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5911,
        TotDASqKM: 135.4905,
        DivDASqKM: 135.4905,
        Tidal: 0,
        TOTMA: 0.99827793607000004,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6753.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.9769999980926509,
        QA_MA: 50.273,
        VA_MA: -9998.0,
        QC_MA: 52.298,
        VC_MA: -9998.0,
        QE_MA: 52.298,
        VE_MA: -9998.0,
        QA_01: 111.779,
        VA_01: -9998.0,
        QC_01: 89.582,
        VC_01: -9998.0,
        QE_01: 89.582,
        VE_01: -9998.0,
        QA_02: 116.634,
        VA_02: -9998.0,
        QC_02: 99.808,
        VC_02: -9998.0,
        QE_02: 99.808,
        VE_02: -9998.0,
        QA_03: 125.224,
        VA_03: -9998.0,
        QC_03: 108.717,
        VC_03: -9998.0,
        QE_03: 108.717,
        VE_03: -9998.0,
        QA_04: 71.469,
        VA_04: -9998.0,
        QC_04: 67.875,
        VC_04: -9998.0,
        QE_04: 67.875,
        VE_04: -9998.0,
        QA_05: 39.785,
        VA_05: -9998.0,
        QC_05: 39.365,
        VC_05: -9998.0,
        QE_05: 39.365,
        VE_05: -9998.0,
        QA_06: 25.776,
        VA_06: -9998.0,
        QC_06: 32.895,
        VC_06: -9998.0,
        QE_06: 32.895,
        VE_06: -9998.0,
        QA_07: 19.416,
        VA_07: -9998.0,
        QC_07: 30.169,
        VC_07: -9998.0,
        QE_07: 30.169,
        VE_07: -9998.0,
        QA_08: 14.574,
        VA_08: -9998.0,
        QC_08: 31.898,
        VC_08: -9998.0,
        QE_08: 31.898,
        VE_08: -9998.0,
        QA_09: 10.993,
        VA_09: -9998.0,
        QC_09: 32.476,
        VC_09: -9998.0,
        QE_09: 32.476,
        VE_09: -9998.0,
        QA_10: 9.108,
        VA_10: -9998.0,
        QC_10: 32.123,
        VC_10: -9998.0,
        QE_10: 32.123,
        VE_10: -9998.0,
        QA_11: 7.728,
        VA_11: -9998.0,
        QC_11: 18.184,
        VC_11: -9998.0,
        QE_11: 18.184,
        VE_11: -9998.0,
        QA_12: 51.015,
        VA_12: -9998.0,
        QC_12: 47.306,
        VC_12: -9998.0,
        QE_12: 47.306,
        VE_12: -9998.0,
        LakeFract: 0.00052845282,
        SurfArea: 23964.496942999998,
        RAreaHLoad: 0.18705076738000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.783210585048209, 34.423279013234946, 0.0],
          [-80.787136585042219, 34.411022613253976, 0.0],
          [-80.784220185046649, 34.407241213259795, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291172.0,
        COMID: 9718381,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 2.402,
        REACHCODE: "03050104002465",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.025347227050238001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058218.0,
        ToNode: 250058223.0,
        Hydroseq: 250003986.0,
        LevelPathI: 250003258.0,
        Pathlength: 344.867,
        TerminalPa: 250002604.0,
        ArbolateSu: 8987.147,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003997.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003976.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5324,
        TotDASqKM: 12014.2809,
        DivDASqKM: 12014.2809,
        Tidal: 0,
        TOTMA: 1.2128799107,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6648.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.401999950408936,
        QA_MA: 5708.048,
        VA_MA: -9998.0,
        QC_MA: 5708.048,
        VC_MA: -9998.0,
        QE_MA: 6406.009,
        VE_MA: -9998.0,
        QA_01: 10781.782,
        VA_01: -9998.0,
        QC_01: 10781.782,
        VC_01: -9998.0,
        QE_01: 9388.051,
        VE_01: -9998.0,
        QA_02: 11414.221,
        VA_02: -9998.0,
        QC_02: 11414.221,
        VC_02: -9998.0,
        QE_02: 9573.039,
        VE_02: -9998.0,
        QA_03: 12490.86,
        VA_03: -9998.0,
        QC_03: 12490.86,
        VC_03: -9998.0,
        QE_03: 10239.769,
        VE_03: -9998.0,
        QA_04: 8327.691,
        VA_04: -9998.0,
        QC_04: 8327.691,
        VC_04: -9998.0,
        QE_04: 8076.066,
        VE_04: -9998.0,
        QA_05: 5528.497,
        VA_05: -9998.0,
        QC_05: 5528.497,
        VC_05: -9998.0,
        QE_05: 6483.857,
        VE_05: -9998.0,
        QA_06: 3302.061,
        VA_06: -9998.0,
        QC_06: 3302.061,
        VC_06: -9998.0,
        QE_06: 5527.025,
        VE_06: -9998.0,
        QA_07: 2094.301,
        VA_07: -9998.0,
        QC_07: 2094.301,
        VC_07: -9998.0,
        QE_07: 4171.226,
        VE_07: -9998.0,
        QA_08: 1511.554,
        VA_08: -9998.0,
        QC_08: 1511.554,
        VC_08: -9998.0,
        QE_08: 3808.113,
        VE_08: -9998.0,
        QA_09: 1345.016,
        VA_09: -9998.0,
        QC_09: 1345.016,
        VC_09: -9998.0,
        QE_09: 3483.03,
        VE_09: -9998.0,
        QA_10: 1394.336,
        VA_10: -9998.0,
        QC_10: 1394.336,
        VC_10: -9998.0,
        QE_10: 4715.847,
        VE_10: -9998.0,
        QA_11: 3332.478,
        VA_11: -9998.0,
        QC_11: 3332.478,
        VC_11: -9998.0,
        QE_11: 4779.565,
        VE_11: -9998.0,
        QA_12: 7013.037,
        VA_12: -9998.0,
        QC_12: 7013.037,
        VC_12: -9998.0,
        QE_12: 6721.155,
        VE_12: -9998.0,
        LakeFract: 0.07855520349,
        SurfArea: 3562353.84247,
        RAreaHLoad: 0.22726147682,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.807111785011216, 34.414467013248554, 0.0],
          [-80.789498185038553, 34.412556613251581, 0.0],
          [-80.784220185046649, 34.407241213259795, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285991.0,
        COMID: 9751044,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.922,
        REACHCODE: "03050101002666",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0086144241791929997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060685.0,
        ToNode: 250060697.0,
        Hydroseq: 250008881.0,
        LevelPathI: 250003258.0,
        Pathlength: 542.725,
        TerminalPa: 250002604.0,
        ArbolateSu: 3637.529,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008917.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008846.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8586,
        TotDASqKM: 4131.3078,
        DivDASqKM: 4131.3078,
        Tidal: 0,
        TOTMA: 1.1498384780399999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23060.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23065.0,
        SLOPE: 9.761e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.92199999094009399,
        QA_MA: 2412.716,
        VA_MA: -9998.0,
        QC_MA: 2412.716,
        VC_MA: -9998.0,
        QE_MA: 2606.805,
        VE_MA: -9998.0,
        QA_01: 3965.522,
        VA_01: -9998.0,
        QC_01: 3965.522,
        VC_01: -9998.0,
        QE_01: 4150.044,
        VE_01: -9998.0,
        QA_02: 4188.355,
        VA_02: -9998.0,
        QC_02: 4188.355,
        VC_02: -9998.0,
        QE_02: 4236.317,
        VE_02: -9998.0,
        QA_03: 4714.165,
        VA_03: -9998.0,
        QC_03: 4714.165,
        VC_03: -9998.0,
        QE_03: 4949.955,
        VE_03: -9998.0,
        QA_04: 3596.148,
        VA_04: -9998.0,
        QC_04: 3596.148,
        VC_04: -9998.0,
        QE_04: 3778.586,
        VE_04: -9998.0,
        QA_05: 2701.146,
        VA_05: -9998.0,
        QC_05: 2701.146,
        VC_05: -9998.0,
        QE_05: 2924.249,
        VE_05: -9998.0,
        QA_06: 1576.234,
        VA_06: -9998.0,
        QC_06: 1576.234,
        VC_06: -9998.0,
        QE_06: 1853.942,
        VE_06: -9998.0,
        QA_07: 934.865,
        VA_07: -9998.0,
        QC_07: 934.865,
        VC_07: -9998.0,
        QE_07: 1100.667,
        VE_07: -9998.0,
        QA_08: 654.353,
        VA_08: -9998.0,
        QC_08: 654.353,
        VC_08: -9998.0,
        QE_08: 974.936,
        VE_08: -9998.0,
        QA_09: 629.057,
        VA_09: -9998.0,
        QC_09: 1156.945,
        VC_09: -9998.0,
        QE_09: 1208.637,
        VE_09: -9998.0,
        QA_10: 780.766,
        VA_10: -9998.0,
        QC_10: 780.766,
        VC_10: -9998.0,
        QE_10: 742.817,
        VE_10: -9998.0,
        QA_11: 2147.349,
        VA_11: -9998.0,
        QC_11: 2147.349,
        VC_11: -9998.0,
        QE_11: 2097.327,
        VE_11: -9998.0,
        QA_12: 3080.319,
        VA_12: -9998.0,
        QC_12: 3080.319,
        VC_12: -9998.0,
        QE_12: 3081.49,
        VE_12: -9998.0,
        LakeFract: 0.01523553794,
        SurfArea: 1993040.9504800001,
        RAreaHLoad: 0.3119311802,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.967121918096154, 35.666397077971965, 0.0],
          [-80.966974118096346, 35.662036477978859, 0.0],
          [-80.964529584766808, 35.659010277983555, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291173.0,
        COMID: 9718383,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 2.588,
        REACHCODE: "03050104002466",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.026587074003344001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058219.0,
        ToNode: 250058216.0,
        Hydroseq: 250004036.0,
        LevelPathI: 250003258.0,
        Pathlength: 348.595,
        TerminalPa: 250002604.0,
        ArbolateSu: 8938.868,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004049.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004025.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.5829,
        TotDASqKM: 11966.7762,
        DivDASqKM: 11966.7762,
        Tidal: 0,
        TOTMA: 1.3067998372,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6736.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5880000591278081,
        QA_MA: 5691.01,
        VA_MA: -9998.0,
        QC_MA: 5691.01,
        VC_MA: -9998.0,
        QE_MA: 6388.949,
        VE_MA: -9998.0,
        QA_01: 10743.767,
        VA_01: -9998.0,
        QC_01: 10743.767,
        VC_01: -9998.0,
        QE_01: 9350.127,
        VE_01: -9998.0,
        QA_02: 11373.775,
        VA_02: -9998.0,
        QC_02: 11373.775,
        VC_02: -9998.0,
        QE_02: 9532.686,
        VE_02: -9998.0,
        QA_03: 12447.184,
        VA_03: -9998.0,
        QC_03: 12447.184,
        VC_03: -9998.0,
        QE_03: 10196.233,
        VE_03: -9998.0,
        QA_04: 8303.983,
        VA_04: -9998.0,
        QC_04: 8303.983,
        VC_04: -9998.0,
        QE_04: 8052.392,
        VE_04: -9998.0,
        QA_05: 5515.084,
        VA_05: -9998.0,
        QC_05: 5515.084,
        VC_05: -9998.0,
        QE_05: 6470.442,
        VE_05: -9998.0,
        QA_06: 3293.119,
        VA_06: -9998.0,
        QC_06: 3293.119,
        VC_06: -9998.0,
        QE_06: 5517.995,
        VE_06: -9998.0,
        QA_07: 2087.914,
        VA_07: -9998.0,
        QC_07: 2087.914,
        VC_07: -9998.0,
        QE_07: 4164.759,
        VE_07: -9998.0,
        QA_08: 1506.445,
        VA_08: -9998.0,
        QC_08: 1506.445,
        VC_08: -9998.0,
        QE_08: 3802.893,
        VE_08: -9998.0,
        QA_09: 1341.183,
        VA_09: -9998.0,
        QC_09: 1341.183,
        VC_09: -9998.0,
        QE_09: 3479.108,
        VE_09: -9998.0,
        QA_10: 1391.143,
        VA_10: -9998.0,
        QC_10: 1391.143,
        VC_10: -9998.0,
        QE_10: 4712.507,
        VE_10: -9998.0,
        QA_11: 3329.924,
        VA_11: -9998.0,
        QC_11: 3329.924,
        VC_11: -9998.0,
        QE_11: 4776.935,
        VE_11: -9998.0,
        QA_12: 6997.8,
        VA_12: -9998.0,
        QC_12: 6997.8,
        VC_12: -9998.0,
        QE_12: 6705.96,
        VE_12: -9998.0,
        LakeFract: 0.08441280722,
        SurfArea: 3827986.87794,
        RAreaHLoad: 0.2448595762,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.843239584955086, 34.4230174132353, 0.0],
          [-80.838009318296542, 34.417439613244028, 0.0],
          [-80.829239584976847, 34.412076013252317, 0.0],
          [-80.820925918323042, 34.412308613251867, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291174.0,
        COMID: 9718385,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 3.837,
        REACHCODE: "03050104002467",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.040881654307108002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058220.0,
        ToNode: 250058210.0,
        Hydroseq: 250018611.0,
        LevelPathI: 250018611.0,
        Pathlength: 351.575,
        TerminalPa: 250002604.0,
        ArbolateSu: 130.543,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250018900.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004049.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.272,
        TotDASqKM: 110.5812,
        DivDASqKM: 110.5812,
        Tidal: 0,
        TOTMA: 1.93747720026,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6741.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.836999893188477,
        QA_MA: 40.16,
        VA_MA: -9998.0,
        QC_MA: 42.143,
        VC_MA: -9998.0,
        QE_MA: 42.143,
        VE_MA: -9998.0,
        QA_01: 89.315,
        VA_01: -9998.0,
        QC_01: 72.001,
        VC_01: -9998.0,
        QE_01: 72.001,
        VE_01: -9998.0,
        QA_02: 96.155,
        VA_02: -9998.0,
        QC_02: 82.718,
        VC_02: -9998.0,
        QE_02: 82.718,
        VE_02: -9998.0,
        QA_03: 104.047,
        VA_03: -9998.0,
        QC_03: 90.886,
        VC_03: -9998.0,
        QE_03: 90.886,
        VE_03: -9998.0,
        QA_04: 56.609,
        VA_04: -9998.0,
        QC_04: 54.048,
        VC_04: -9998.0,
        QE_04: 54.048,
        VE_04: -9998.0,
        QA_05: 31.809,
        VA_05: -9998.0,
        QC_05: 31.738,
        VC_05: -9998.0,
        QE_05: 31.738,
        VE_05: -9998.0,
        QA_06: 20.815,
        VA_06: -9998.0,
        QC_06: 26.643,
        VC_06: -9998.0,
        QE_06: 26.643,
        VE_06: -9998.0,
        QA_07: 14.868,
        VA_07: -9998.0,
        QC_07: 23.25,
        VC_07: -9998.0,
        QE_07: 23.25,
        VE_07: -9998.0,
        QA_08: 11.894,
        VA_08: -9998.0,
        QC_08: 25.928,
        VC_08: -9998.0,
        QE_08: 25.928,
        VE_08: -9998.0,
        QA_09: 8.921,
        VA_09: -9998.0,
        QC_09: 27.007,
        VC_09: -9998.0,
        QE_09: 27.007,
        VE_09: -9998.0,
        QA_10: 7.434,
        VA_10: -9998.0,
        QC_10: 27.311,
        VC_10: -9998.0,
        QE_10: 27.311,
        VE_10: -9998.0,
        QA_11: 5.947,
        VA_11: -9998.0,
        QC_11: 14.892,
        VC_11: -9998.0,
        QE_11: 14.892,
        VE_11: -9998.0,
        QA_12: 34.438,
        VA_12: -9998.0,
        QC_12: 33.086,
        VC_12: -9998.0,
        QE_12: 33.086,
        VE_12: -9998.0,
        LakeFract: 0.00082732498,
        SurfArea: 37517.875207899997,
        RAreaHLoad: 0.36303176098000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884800118223893, 34.419223213241139, 0.0],
          [-80.87212298491022, 34.423542079901154, 0.0],
          [-80.857312918266587, 34.420922613238588, 0.0],
          [-80.849149518279219, 34.425151279898614, 0.0],
          [-80.846520718283386, 34.424915213232282, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283991.0,
        COMID: 9754870,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.148,
        REACHCODE: "03050101000405",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011401612177240001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061737.0,
        ToNode: 250061733.0,
        Hydroseq: 250027063.0,
        LevelPathI: 250003258.0,
        Pathlength: 696.998,
        TerminalPa: 250002604.0,
        ArbolateSu: 234.493,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250027935.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026265.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8658,
        TotDASqKM: 258.7806,
        DivDASqKM: 258.7806,
        Tidal: 0,
        TOTMA: 0.0322054423566,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39008.0,
        MAXELEVSMO: 39437.0,
        MINELEVSMO: 39155.0,
        SLOPE: 0.00245644,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1480000019073491,
        QA_MA: 172.177,
        VA_MA: 1.35312,
        QC_MA: 170.754,
        VC_MA: 1.35241,
        QE_MA: 173.126,
        VE_MA: 1.35358,
        QA_01: 270.803,
        VA_01: 1.63712,
        QC_01: 212.052,
        VC_01: 1.47967,
        QE_01: 213.913,
        VE_01: 1.47784,
        QA_02: 285.1,
        VA_02: 1.67392,
        QC_02: 238.099,
        VC_02: 1.55399,
        QE_02: 241.874,
        VE_02: 1.55667,
        QA_03: 321.457,
        VA_03: 1.76379,
        QC_03: 270.533,
        VC_03: 1.64139,
        QE_03: 271.624,
        VE_03: 1.63597,
        QA_04: 242.327,
        VA_04: 1.561,
        QC_04: 223.842,
        VC_04: 1.51381,
        QE_04: 227.219,
        VE_04: 1.51592,
        QA_05: 193.676,
        VA_05: 1.42047,
        QC_05: 180.615,
        VC_05: 1.38401,
        QE_05: 182.357,
        VE_05: 1.38281,
        QA_06: 114.13,
        VA_06: 1.1482,
        QC_06: 142.708,
        VC_06: 1.25754,
        QE_06: 145.186,
        VE_06: 1.26033,
        QA_07: 65.99,
        VA_07: 0.93622,
        QC_07: 99.579,
        VC_07: 1.09245,
        QE_07: 102.766,
        VE_07: 1.10074,
        QA_08: 45.704,
        VA_08: 0.82496,
        QC_08: 102.349,
        VC_08: 1.10396,
        QE_08: 107.963,
        VE_08: 1.12177,
        QA_09: 54.737,
        VA_09: 0.87688,
        QC_09: 133.994,
        VC_09: 1.22631,
        QE_09: 134.297,
        VE_09: 1.22172,
        QA_10: 82.606,
        VA_10: 1.01574,
        QC_10: 186.955,
        VC_10: 1.40389,
        QE_10: 184.33,
        VE_10: 1.38897,
        QA_11: 172.984,
        VA_11: 1.35571,
        QC_11: 194.625,
        VC_11: 1.42753,
        QE_11: 192.521,
        VE_11: 1.41421,
        QA_12: 217.968,
        VA_12: 1.49248,
        QC_12: 177.315,
        VC_12: 1.37353,
        QE_12: 177.666,
        VE_12: 1.36805,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.097507583008223, 35.6530546779926, 0.0],
          [-82.097198383008731, 35.654703411323453, 0.0],
          [-82.10452038299735, 35.660704077980881, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287042.0,
        COMID: 9734788,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.279,
        REACHCODE: "03050103000315",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002938899581881,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059517.0,
        ToNode: 250059531.0,
        Hydroseq: 250020200.0,
        LevelPathI: 250015946.0,
        Pathlength: 401.309,
        TerminalPa: 250002604.0,
        ArbolateSu: 227.787,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250020557.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019855.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0855,
        TotDASqKM: 606.0537,
        DivDASqKM: 606.0537,
        Tidal: 0,
        TOTMA: 0.0066640120398399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12044.0,
        MAXELEVSMO: 12137.0,
        MINELEVSMO: 12044.0,
        SLOPE: 0.00333333,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.279000014066696,
        QA_MA: 252.319,
        VA_MA: 1.5922,
        QC_MA: 246.551,
        VC_MA: 1.58979,
        QE_MA: 246.551,
        VE_MA: 1.58979,
        QA_01: 542.125,
        VA_01: 2.23534,
        QC_01: 416.859,
        VC_01: 2.00194,
        QE_01: 416.859,
        VE_01: 2.00194,
        QA_02: 568.543,
        VA_02: 2.28464,
        QC_02: 465.965,
        VC_02: 2.1051,
        QE_02: 465.965,
        VE_02: 2.1051,
        QA_03: 611.287,
        VA_03: 2.3622,
        QC_03: 503.65,
        VC_03: 2.18085,
        QE_03: 503.65,
        VE_03: 2.18085,
        QA_04: 362.638,
        VA_04: 1.86487,
        QC_04: 331.92,
        VC_04: 1.80895,
        QE_04: 331.92,
        VE_04: 1.80895,
        QA_05: 201.065,
        VA_05: 1.44637,
        QC_05: 187.244,
        VC_05: 1.41561,
        QE_05: 187.244,
        VE_05: 1.41561,
        QA_06: 127.586,
        VA_06: 1.2022,
        QC_06: 159.291,
        VC_06: 1.32452,
        QE_06: 159.291,
        VE_06: 1.32452,
        QA_07: 88.023,
        VA_07: 1.04231,
        QC_07: 131.915,
        VC_07: 1.2277,
        QE_07: 131.915,
        VE_07: 1.2277,
        QA_08: 65.188,
        VA_08: 0.93412,
        QC_08: 147.023,
        VC_08: 1.28218,
        QE_08: 147.023,
        VE_08: 1.28218,
        QA_09: 56.025,
        VA_09: 0.88576,
        QC_09: 136.773,
        VC_09: 1.24552,
        QE_09: 136.773,
        VE_09: 1.24552,
        QA_10: 40.816,
        VA_10: 0.79638,
        QC_10: 106.451,
        VC_10: 1.12874,
        QE_10: 106.451,
        VE_10: 1.12874,
        QA_11: 64.651,
        VA_11: 0.93138,
        QC_11: 91.882,
        VC_11: 1.06707,
        QE_11: 91.882,
        VE_11: 1.06707,
        QA_12: 301.672,
        VA_12: 1.71994,
        QC_12: 238.321,
        VC_12: 1.5669,
        QE_12: 238.321,
        VE_12: 1.5669,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.960959984772387, 34.718131012777235, 0.0],
          [-80.95829571810981, 34.716987612779064, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291167.0,
        COMID: 9718371,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.511,
        REACHCODE: "03050104002460",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0055669072184460003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058213.0,
        ToNode: 250058218.0,
        Hydroseq: 250003997.0,
        LevelPathI: 250003258.0,
        Pathlength: 347.269,
        TerminalPa: 250002604.0,
        ArbolateSu: 8981.045,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004012.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003986.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5499,
        TotDASqKM: 12006.5949,
        DivDASqKM: 12006.5949,
        Tidal: 0,
        TOTMA: 0.25802732486000002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6746.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.51099997758865401,
        QA_MA: 5705.295,
        VA_MA: -9998.0,
        QC_MA: 5705.295,
        VC_MA: -9998.0,
        QE_MA: 6403.252,
        VE_MA: -9998.0,
        QA_01: 10775.642,
        VA_01: -9998.0,
        QC_01: 10775.642,
        VC_01: -9998.0,
        QE_01: 9381.926,
        VE_01: -9998.0,
        QA_02: 11407.711,
        VA_02: -9998.0,
        QC_02: 11407.711,
        VC_02: -9998.0,
        QE_02: 9566.544,
        VE_02: -9998.0,
        QA_03: 12483.833,
        VA_03: -9998.0,
        QC_03: 12483.833,
        VC_03: -9998.0,
        QE_03: 10232.764,
        VE_03: -9998.0,
        QA_04: 8323.864,
        VA_04: -9998.0,
        QC_04: 8323.864,
        VC_04: -9998.0,
        QE_04: 8072.245,
        VE_04: -9998.0,
        QA_05: 5526.327,
        VA_05: -9998.0,
        QC_05: 5526.327,
        VC_05: -9998.0,
        QE_05: 6481.686,
        VE_05: -9998.0,
        QA_06: 3300.614,
        VA_06: -9998.0,
        QC_06: 3300.614,
        VC_06: -9998.0,
        QE_06: 5525.564,
        VE_06: -9998.0,
        QA_07: 2093.268,
        VA_07: -9998.0,
        QC_07: 2093.268,
        VC_07: -9998.0,
        QE_07: 4170.18,
        VE_07: -9998.0,
        QA_08: 1510.728,
        VA_08: -9998.0,
        QC_08: 1510.728,
        VC_08: -9998.0,
        QE_08: 3807.268,
        VE_08: -9998.0,
        QA_09: 1344.395,
        VA_09: -9998.0,
        QC_09: 1344.395,
        VC_09: -9998.0,
        QE_09: 3482.395,
        VE_09: -9998.0,
        QA_10: 1393.82,
        VA_10: -9998.0,
        QC_10: 1393.82,
        VC_10: -9998.0,
        QE_10: 4715.306,
        VE_10: -9998.0,
        QA_11: 3332.065,
        VA_11: -9998.0,
        QC_11: 3332.065,
        VC_11: -9998.0,
        QE_11: 4779.14,
        VE_11: -9998.0,
        QA_12: 7010.518,
        VA_12: -9998.0,
        QC_12: 7010.518,
        VC_12: -9998.0,
        QE_12: 6718.644,
        VE_12: -9998.0,
        LakeFract: 0.01670459428,
        SurfArea: 757526.84706399997,
        RAreaHLoad: 0.04834746655,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.812600718336, 34.414509413248481, 0.0],
          [-80.807111785011216, 34.414467013248554, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283992.0,
        COMID: 9754888,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.156,
        REACHCODE: "03050101000406",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.022281345895046,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061740.0,
        ToNode: 250061737.0,
        Hydroseq: 250027935.0,
        LevelPathI: 250003258.0,
        Pathlength: 698.146,
        TerminalPa: 250002604.0,
        ArbolateSu: 226.874,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250032401.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027063.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9557,
        TotDASqKM: 248.8734,
        DivDASqKM: 248.8734,
        Tidal: 0,
        TOTMA: 0.059936969530099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39270.0,
        MAXELEVSMO: 40040.0,
        MINELEVSMO: 39437.0,
        SLOPE: 0.00279684,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.155999898910522,
        QA_MA: 165.469,
        VA_MA: 1.36531,
        QC_MA: 164.355,
        VC_MA: 1.36474,
        QE_MA: 166.636,
        VE_MA: 1.36592,
        QA_01: 260.144,
        VA_01: 1.65234,
        QC_01: 203.921,
        VC_01: 1.49293,
        QE_01: 205.71,
        VE_01: 1.49108,
        QA_02: 273.846,
        VA_02: 1.68948,
        QC_02: 228.952,
        VC_02: 1.56804,
        QE_02: 232.582,
        VE_02: 1.57075,
        QA_03: 308.767,
        VA_03: 1.78036,
        QC_03: 260.199,
        VC_03: 1.65658,
        QE_03: 261.248,
        VE_03: 1.65111,
        QA_04: 232.75,
        VA_04: 1.57523,
        QC_04: 215.193,
        VC_04: 1.52726,
        QE_04: 218.44,
        VE_04: 1.52939,
        QA_05: 186.047,
        VA_05: 1.43319,
        QC_05: 173.762,
        VC_05: 1.39642,
        QE_05: 175.438,
        VE_05: 1.39522,
        QA_06: 109.897,
        VA_06: 1.15888,
        QC_06: 137.488,
        VC_06: 1.26922,
        QE_06: 139.87,
        VE_06: 1.27202,
        QA_07: 63.556,
        VA_07: 0.94428,
        QC_07: 95.992,
        VC_07: 1.10234,
        QE_07: 99.057,
        VE_07: 1.11071,
        QA_08: 44.124,
        VA_08: 0.83226,
        QC_08: 98.742,
        VC_08: 1.11434,
        QE_08: 104.141,
        VE_08: 1.13232,
        QA_09: 53.453,
        VA_09: 0.88843,
        QC_09: 131.215,
        VC_09: 1.24568,
        QE_09: 131.507,
        VE_09: 1.24099,
        QA_10: 79.655,
        VA_10: 1.02528,
        QC_10: 181.601,
        VC_10: 1.42222,
        QE_10: 179.076,
        VE_10: 1.40713,
        QA_11: 165.783,
        VA_11: 1.36638,
        QC_11: 188.415,
        VC_11: 1.44422,
        QE_11: 186.391,
        VE_11: 1.43074,
        QA_12: 208.927,
        VA_12: 1.50464,
        QC_12: 170.61,
        VC_12: 1.3859,
        QE_12: 170.948,
        VE_12: 1.38036,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.112490716318348, 35.644148411339927, 0.0],
          [-82.103316716332529, 35.645979611337054, 0.0],
          [-82.103147182999464, 35.65220821132732, 0.0],
          [-82.097507583008223, 35.6530546779926, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287019.0,
        COMID: 9735066,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.055,
        REACHCODE: "03050103000293",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00051602980444300005,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059606.0,
        ToNode: 250059621.0,
        Hydroseq: 250017824.0,
        LevelPathI: 250015946.0,
        Pathlength: 391.841,
        TerminalPa: 250002604.0,
        ArbolateSu: 334.537,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250018073.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017592.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 714.3192,
        DivDASqKM: 714.3192,
        Tidal: 0,
        TOTMA: 0.00249778466987,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11571.0,
        MAXELEVSMO: 11639.0,
        MINELEVSMO: 11639.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054999999701976998,
        QA_MA: 294.796,
        VA_MA: 0.83739,
        QC_MA: 286.322,
        VC_MA: 0.83614,
        QE_MA: 286.322,
        VE_MA: 0.83614,
        QA_01: 635.275,
        VA_01: 1.10365,
        QC_01: 486.46,
        VC_01: 1.00769,
        QE_01: 486.46,
        VE_01: 1.00769,
        QA_02: 666.186,
        VA_02: 1.12397,
        QC_02: 543.637,
        VC_02: 1.05019,
        QE_02: 543.637,
        VE_02: 1.05019,
        QA_03: 716.273,
        VA_03: 1.15598,
        QC_03: 587.07,
        VC_03: 1.08109,
        QE_03: 587.07,
        VE_03: 1.08109,
        QA_04: 423.272,
        VA_04: 0.94941,
        QC_04: 386.059,
        VC_04: 0.9269,
        QE_04: 386.059,
        VE_04: 0.9269,
        QA_05: 234.829,
        VA_05: 0.77721,
        QC_05: 217.42,
        VC_05: 0.76437,
        QE_05: 217.42,
        VE_05: 0.76437,
        QA_06: 149.117,
        VA_06: 0.67676,
        QC_06: 185.774,
        VC_06: 0.72783,
        QE_06: 185.774,
        VE_06: 0.72783,
        QA_07: 103.255,
        VA_07: 0.61145,
        QC_07: 154.152,
        VC_07: 0.68826,
        QE_07: 154.152,
        VE_07: 0.68826,
        QA_08: 76.833,
        VA_08: 0.56743,
        QC_08: 173.859,
        VC_08: 0.71332,
        QE_08: 173.859,
        VE_08: 0.71332,
        QA_09: 64.938,
        VA_09: 0.54529,
        QC_09: 155.815,
        VC_09: 0.69043,
        QE_09: 155.815,
        VE_09: 0.69043,
        QA_10: 48.094,
        VA_10: 0.51038,
        QC_10: 121.361,
        VC_10: 0.64296,
        QE_10: 121.361,
        VE_10: 0.64296,
        QA_11: 71.916,
        VA_11: 0.55849,
        QC_11: 99.655,
        VC_11: 0.60972,
        QE_11: 99.655,
        VE_11: 0.60972,
        QA_12: 349.732,
        VA_12: 0.88763,
        QC_12: 272.629,
        VC_12: 0.82258,
        QE_12: 272.629,
        VE_12: 0.82258,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.942721518134022, 34.667085412856352, 0.0],
          [-80.942443918134416, 34.666650412857166, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283993.0,
        COMID: 9754904,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.319,
        REACHCODE: "03050101000407",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.023350946100697999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061745.0,
        ToNode: 250061740.0,
        Hydroseq: 250032401.0,
        LevelPathI: 250003258.0,
        Pathlength: 700.302,
        TerminalPa: 250002604.0,
        ArbolateSu: 132.877,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250033869.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027935.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6136,
        TotDASqKM: 155.3247,
        DivDASqKM: 155.3247,
        Tidal: 0,
        TOTMA: 0.069041245961599995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39998.0,
        MAXELEVSMO: 40777.0,
        MINELEVSMO: 40040.0,
        SLOPE: 0.00317809,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.3190000057220459,
        QA_MA: 100.912,
        VA_MA: 1.27448,
        QC_MA: 102.175,
        VC_MA: 1.27545,
        QE_MA: 102.175,
        VE_MA: 1.27545,
        QA_01: 157.167,
        VA_01: 1.53073,
        QC_01: 124.837,
        VC_01: 1.38399,
        QE_01: 124.837,
        VE_01: 1.38399,
        QA_02: 167.661,
        VA_02: 1.57341,
        QC_02: 142.062,
        VC_02: 1.4604,
        QE_02: 142.062,
        VE_02: 1.4604,
        QA_03: 189.842,
        VA_03: 1.65968,
        QC_03: 162.57,
        VC_03: 1.54592,
        QE_03: 162.57,
        VE_03: 1.54592,
        QA_04: 143.719,
        VA_04: 1.47403,
        QC_04: 134.341,
        VC_04: 1.42672,
        QE_04: 134.341,
        VE_04: 1.42672,
        QA_05: 114.694,
        VA_05: 1.34243,
        QC_05: 109.076,
        VC_05: 1.3096,
        QE_05: 109.076,
        VE_05: 1.3096,
        QA_06: 67.967,
        VA_06: 1.09159,
        QC_06: 85.593,
        VC_06: 1.18866,
        QE_06: 85.593,
        VE_06: 1.18866,
        QA_07: 39.549,
        VA_07: 0.89588,
        QC_07: 60.415,
        VC_07: 1.04,
        QE_07: 60.415,
        VE_07: 1.04,
        QA_08: 27.943,
        VA_08: 0.79692,
        QC_08: 61.963,
        VC_08: 1.0499,
        QE_08: 61.963,
        VE_08: 1.0499,
        QA_09: 33.585,
        VA_09: 0.84704,
        QC_09: 87.055,
        VC_09: 1.19662,
        QE_09: 87.055,
        VE_09: 1.19662,
        QA_10: 47.175,
        VA_10: 0.95354,
        QC_10: 119.504,
        VC_10: 1.35934,
        QE_10: 119.504,
        VE_10: 1.35934,
        QA_11: 97.296,
        VA_11: 1.25594,
        QC_11: 125.49,
        VC_11: 1.38697,
        QE_11: 125.49,
        VE_11: 1.38697,
        QA_12: 125.207,
        VA_12: 1.39173,
        QC_12: 107.075,
        VC_12: 1.29981,
        QE_12: 107.075,
        VE_12: 1.29981,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.128031382960899, 35.63581267801942, 0.0],
          [-82.124497582966285, 35.642820211341927, 0.0],
          [-82.119532182974069, 35.641789878010229, 0.0],
          [-82.116249782979139, 35.64492707800531, 0.0],
          [-82.112490716318348, 35.644148411339927, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287020.0,
        COMID: 9735060,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.437,
        REACHCODE: "03050103000294",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0040274161464259996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059603.0,
        ToNode: 250059606.0,
        Hydroseq: 250018073.0,
        LevelPathI: 250015946.0,
        Pathlength: 391.896,
        TerminalPa: 250002604.0,
        ArbolateSu: 330.178,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250018330.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017824.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1332,
        TotDASqKM: 709.4196,
        DivDASqKM: 709.4196,
        Tidal: 0,
        TOTMA: 0.0134589385293,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11562.0,
        MAXELEVSMO: 11654.0,
        MINELEVSMO: 11639.0,
        SLOPE: 0.00034324,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.43700000643730202,
        QA_MA: 292.952,
        VA_MA: 1.23511,
        QC_MA: 284.601,
        VC_MA: 1.23294,
        QE_MA: 284.601,
        VE_MA: 1.23294,
        QA_01: 631.235,
        VA_01: 1.70148,
        QC_01: 483.447,
        VC_01: 1.53335,
        QE_01: 483.447,
        VE_01: 1.53335,
        QA_02: 661.882,
        VA_02: 1.737,
        QC_02: 540.22,
        VC_02: 1.60774,
        QE_02: 540.22,
        VE_02: 1.60774,
        QA_03: 711.611,
        VA_03: 1.79302,
        QC_03: 583.374,
        VC_03: 1.66186,
        QE_03: 583.374,
        VE_03: 1.66186,
        QA_04: 420.647,
        VA_04: 1.43139,
        QC_04: 383.719,
        VC_04: 1.39192,
        QE_04: 383.719,
        VE_04: 1.39192,
        QA_05: 233.379,
        VA_05: 1.12973,
        QC_05: 216.128,
        VC_05: 1.10724,
        QE_05: 216.128,
        VE_05: 1.10724,
        QA_06: 148.195,
        VA_06: 0.95373,
        QC_06: 184.641,
        VC_06: 1.04316,
        QE_06: 184.641,
        VE_06: 1.04316,
        QA_07: 102.596,
        VA_07: 0.83926,
        QC_07: 153.192,
        VC_07: 0.9738,
        QE_07: 153.192,
        VE_07: 0.9738,
        QA_08: 76.306,
        VA_08: 0.76203,
        QC_08: 172.643,
        VC_08: 1.01741,
        QE_08: 172.643,
        VE_08: 1.01741,
        QA_09: 64.542,
        VA_09: 0.72344,
        QC_09: 154.978,
        VC_09: 0.97791,
        QE_09: 154.978,
        VE_09: 0.97791,
        QA_10: 47.765,
        VA_10: 0.66213,
        QC_10: 120.696,
        VC_10: 0.89467,
        QE_10: 120.696,
        VE_10: 0.89467,
        QA_11: 71.652,
        VA_11: 0.74713,
        QC_11: 99.376,
        VC_11: 0.83718,
        QE_11: 99.376,
        VE_11: 0.83718,
        QA_12: 347.797,
        VA_12: 1.32352,
        QC_12: 271.257,
        VC_12: 1.20966,
        QE_12: 271.257,
        VE_12: 1.20966,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.941365784802827, 34.670728212850804, 0.0],
          [-80.942721518134022, 34.667085412856352, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286003.0,
        COMID: 9751078,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.303,
        REACHCODE: "03050101002678",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0032449498600640001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060701.0,
        ToNode: 250060707.0,
        Hydroseq: 250008807.0,
        LevelPathI: 250003258.0,
        Pathlength: 539.087,
        TerminalPa: 250002604.0,
        ArbolateSu: 3662.717,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008846.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008772.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1305,
        TotDASqKM: 4165.5627,
        DivDASqKM: 4165.5627,
        Tidal: 0,
        TOTMA: 0.37787533523,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23057.0,
        MAXELEVSMO: 23059.0,
        MINELEVSMO: 23059.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30300000309944197,
        QA_MA: 2427.566,
        VA_MA: -9998.0,
        QC_MA: 2427.566,
        VC_MA: -9998.0,
        QE_MA: 2621.672,
        VE_MA: -9998.0,
        QA_01: 3994.71,
        VA_01: -9998.0,
        QC_01: 3994.71,
        VC_01: -9998.0,
        QE_01: 4179.119,
        VE_01: -9998.0,
        QA_02: 4219.076,
        VA_02: -9998.0,
        QC_02: 4219.076,
        VC_02: -9998.0,
        QE_02: 4266.906,
        VE_02: -9998.0,
        QA_03: 4747.388,
        VA_03: -9998.0,
        QC_03: 4747.388,
        VC_03: -9998.0,
        QE_03: 4982.989,
        VE_03: -9998.0,
        QA_04: 3618.58,
        VA_04: -9998.0,
        QC_04: 3618.58,
        VC_04: -9998.0,
        QE_04: 3800.962,
        VE_04: -9998.0,
        QA_05: 2715.058,
        VA_05: -9998.0,
        QC_05: 2715.058,
        VC_05: -9998.0,
        QE_05: 2938.177,
        VE_05: -9998.0,
        QA_06: 1584.499,
        VA_06: -9998.0,
        QC_06: 1584.499,
        VC_06: -9998.0,
        QE_06: 1862.339,
        VE_06: -9998.0,
        QA_07: 940.079,
        VA_07: -9998.0,
        QC_07: 940.079,
        VC_07: -9998.0,
        QE_07: 1106.009,
        VE_07: -9998.0,
        QA_08: 658.037,
        VA_08: -9998.0,
        QC_08: 658.037,
        VC_08: -9998.0,
        QE_08: 978.78,
        VE_08: -9998.0,
        QA_09: 632.281,
        VA_09: -9998.0,
        QC_09: 1162.179,
        VC_09: -9998.0,
        QE_09: 1213.995,
        VE_09: -9998.0,
        QA_10: 783.069,
        VA_10: -9998.0,
        QC_10: 783.069,
        VC_10: -9998.0,
        QE_10: 745.299,
        VE_10: -9998.0,
        QA_11: 2154.264,
        VA_11: -9998.0,
        QC_11: 2154.264,
        VC_11: -9998.0,
        QE_11: 2104.289,
        VE_11: -9998.0,
        QA_12: 3099.625,
        VA_12: -9998.0,
        QC_12: 3099.625,
        VC_12: -9998.0,
        QE_12: 3100.728,
        VE_12: -9998.0,
        LakeFract: 0.00503541192,
        SurfArea: 658708.75046500005,
        RAreaHLoad: 0.10251100615,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.946301784795139, 35.638360211348811, 0.0],
          [-80.9433247847997, 35.637164811350772, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291169.0,
        COMID: 9718375,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.652,
        REACHCODE: "03050104002462",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0069969359797300004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058215.0,
        ToNode: 250058213.0,
        Hydroseq: 250004012.0,
        LevelPathI: 250003258.0,
        Pathlength: 347.78,
        TerminalPa: 250002604.0,
        ArbolateSu: 8976.215,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004025.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003997.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4194,
        TotDASqKM: 12002.8806,
        DivDASqKM: 12002.8806,
        Tidal: 0,
        TOTMA: 0.32922468846000003,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6747.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.65200001001357999,
        QA_MA: 5703.968,
        VA_MA: -9998.0,
        QC_MA: 5703.968,
        VC_MA: -9998.0,
        QE_MA: 6401.923,
        VE_MA: -9998.0,
        QA_01: 10772.695,
        VA_01: -9998.0,
        QC_01: 10772.695,
        VC_01: -9998.0,
        QE_01: 9378.986,
        VE_01: -9998.0,
        QA_02: 11404.564,
        VA_02: -9998.0,
        QC_02: 11404.564,
        VC_02: -9998.0,
        QE_02: 9563.405,
        VE_02: -9998.0,
        QA_03: 12480.437,
        VA_03: -9998.0,
        QC_03: 12480.437,
        VC_03: -9998.0,
        QE_03: 10229.379,
        VE_03: -9998.0,
        QA_04: 8322.016,
        VA_04: -9998.0,
        QC_04: 8322.016,
        VC_04: -9998.0,
        QE_04: 8070.4,
        VE_04: -9998.0,
        QA_05: 5525.278,
        VA_05: -9998.0,
        QC_05: 5525.278,
        VC_05: -9998.0,
        QE_05: 6480.638,
        VE_05: -9998.0,
        QA_06: 3299.915,
        VA_06: -9998.0,
        QC_06: 3299.915,
        VC_06: -9998.0,
        QE_06: 5524.858,
        VE_06: -9998.0,
        QA_07: 2092.769,
        VA_07: -9998.0,
        QC_07: 2092.769,
        VC_07: -9998.0,
        QE_07: 4169.674,
        VE_07: -9998.0,
        QA_08: 1510.328,
        VA_08: -9998.0,
        QC_08: 1510.328,
        VC_08: -9998.0,
        QE_08: 3806.86,
        VE_08: -9998.0,
        QA_09: 1344.096,
        VA_09: -9998.0,
        QC_09: 1344.096,
        VC_09: -9998.0,
        QE_09: 3482.088,
        VE_09: -9998.0,
        QA_10: 1393.57,
        VA_10: -9998.0,
        QC_10: 1393.57,
        VC_10: -9998.0,
        QE_10: 4715.045,
        VE_10: -9998.0,
        QA_11: 3331.865,
        VA_11: -9998.0,
        QC_11: 3331.865,
        VC_11: -9998.0,
        QE_11: 4778.934,
        VE_11: -9998.0,
        QA_12: 7009.32,
        VA_12: -9998.0,
        QC_12: 7009.32,
        VC_12: -9998.0,
        QE_12: 6717.449,
        VE_12: -9998.0,
        LakeFract: 0.02130946573,
        SurfArea: 966350.46122499998,
        RAreaHLoad: 0.06168796123,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.819267784992348, 34.412804079917919, 0.0],
          [-80.812600718336, 34.414509413248481, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283994.0,
        COMID: 9754908,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.938,
        REACHCODE: "03050101000408",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031816819183364001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061746.0,
        ToNode: 250061745.0,
        Hydroseq: 250033869.0,
        LevelPathI: 250003258.0,
        Pathlength: 702.621,
        TerminalPa: 250002604.0,
        ArbolateSu: 124.977,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250035527.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032401.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8448,
        TotDASqKM: 145.0566,
        DivDASqKM: 145.0566,
        Tidal: 0,
        TOTMA: 0.088860014096299997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 40667.0,
        MAXELEVSMO: 41675.0,
        MINELEVSMO: 40777.0,
        SLOPE: 0.0030565,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.937999963760376,
        QA_MA: 95.28,
        VA_MA: 1.25438,
        QC_MA: 96.688,
        VC_MA: 1.2555,
        QE_MA: 96.688,
        VE_MA: 1.2555,
        QA_01: 147.317,
        VA_01: 1.50067,
        QC_01: 117.212,
        VC_01: 1.35743,
        QE_01: 117.212,
        VE_01: 1.35743,
        QA_02: 157.342,
        VA_02: 1.54308,
        QC_02: 133.549,
        VC_02: 1.43269,
        QE_02: 133.549,
        VE_02: 1.43269,
        QA_03: 178.279,
        VA_03: 1.62776,
        QC_03: 152.985,
        VC_03: 1.5168,
        QE_03: 152.985,
        VE_03: 1.5168,
        QA_04: 135.332,
        VA_04: 1.44814,
        QC_04: 126.674,
        VC_04: 1.40158,
        QE_04: 126.674,
        VE_04: 1.40158,
        QA_05: 108.451,
        VA_05: 1.3217,
        QC_05: 103.356,
        VC_05: 1.28964,
        QE_05: 103.356,
        VE_05: 1.28964,
        QA_06: 64.505,
        VA_06: 1.0774,
        QC_06: 81.292,
        VC_06: 1.17217,
        QE_06: 81.292,
        VE_06: 1.17217,
        QA_07: 37.509,
        VA_07: 0.88502,
        QC_07: 57.371,
        VC_07: 1.02624,
        QE_07: 57.371,
        VE_07: 1.02624,
        QA_08: 26.561,
        VA_08: 0.78846,
        QC_08: 58.838,
        VC_08: 1.03593,
        QE_08: 58.838,
        VE_08: 1.03593,
        QA_09: 32.501,
        VA_09: 0.84277,
        QC_09: 84.57,
        VC_09: 1.19049,
        QE_09: 84.57,
        VE_09: 1.19049,
        QA_10: 46.341,
        VA_10: 0.95354,
        QC_10: 117.812,
        VC_10: 1.36028,
        QE_10: 117.812,
        VE_10: 1.36028,
        QA_11: 92.302,
        VA_11: 1.23856,
        QC_11: 120.548,
        VC_11: 1.37318,
        QE_11: 120.548,
        VE_11: 1.37318,
        QA_12: 117.726,
        VA_12: 1.36683,
        QC_12: 101.238,
        VC_12: 1.27891,
        QE_12: 101.238,
        VE_12: 1.27891,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.156979982915914, 35.63610461135238, 0.0],
          [-82.14898471626168, 35.634046011355622, 0.0],
          [-82.144778116268185, 35.636588811351601, 0.0],
          [-82.135773916282176, 35.638056011349363, 0.0],
          [-82.128031382960899, 35.63581267801942, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287021.0,
        COMID: 9735042,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 2.77,
        REACHCODE: "03050103000295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.027590665034280999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059597.0,
        ToNode: 250059603.0,
        Hydroseq: 250018330.0,
        LevelPathI: 250015946.0,
        Pathlength: 392.333,
        TerminalPa: 250002604.0,
        ArbolateSu: 328.311,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250018601.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018073.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3365,
        TotDASqKM: 707.8941,
        DivDASqKM: 707.8941,
        Tidal: 0,
        TOTMA: 0.081898854832699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11580.0,
        MAXELEVSMO: 11788.0,
        MINELEVSMO: 11654.0,
        SLOPE: 0.00048375,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.7699999809265141,
        QA_MA: 292.383,
        VA_MA: 1.28661,
        QC_MA: 284.069,
        VC_MA: 1.28432,
        QE_MA: 284.069,
        VE_MA: 1.28432,
        QA_01: 629.988,
        VA_01: 1.77887,
        QC_01: 482.517,
        VC_01: 1.60139,
        QE_01: 482.517,
        VE_01: 1.60139,
        QA_02: 660.549,
        VA_02: 1.81633,
        QC_02: 539.161,
        VC_02: 1.67989,
        QE_02: 539.161,
        VE_02: 1.67989,
        QA_03: 710.171,
        VA_03: 1.87546,
        QC_03: 582.233,
        VC_03: 1.73702,
        QE_03: 582.233,
        VE_03: 1.73702,
        QA_04: 419.84,
        VA_04: 1.49381,
        QC_04: 382.999,
        VC_04: 1.45214,
        QE_04: 382.999,
        VE_04: 1.45214,
        QA_05: 232.928,
        VA_05: 1.17537,
        QC_05: 215.726,
        VC_05: 1.15164,
        QE_05: 215.726,
        VE_05: 1.15164,
        QA_06: 147.908,
        VA_06: 0.9896,
        QC_06: 184.288,
        VC_06: 1.08398,
        QE_06: 184.288,
        VE_06: 1.08398,
        QA_07: 102.391,
        VA_07: 0.86874,
        QC_07: 152.893,
        VC_07: 1.01076,
        QE_07: 152.893,
        VE_07: 1.01076,
        QA_08: 76.142,
        VA_08: 0.78719,
        QC_08: 172.264,
        VC_08: 1.05668,
        QE_08: 172.264,
        VE_08: 1.05668,
        QA_09: 64.419,
        VA_09: 0.74652,
        QC_09: 154.717,
        VC_09: 1.01519,
        QE_09: 154.717,
        VE_09: 1.01519,
        QA_10: 47.662,
        VA_10: 0.68175,
        QC_10: 120.489,
        VC_10: 0.92731,
        QE_10: 120.489,
        VE_10: 0.92731,
        QA_11: 71.57,
        VA_11: 0.7717,
        QC_11: 99.289,
        VC_11: 0.86687,
        QE_11: 99.289,
        VE_11: 0.86687,
        QA_12: 347.207,
        VA_12: 1.38008,
        QC_12: 270.838,
        VC_12: 1.25992,
        QE_12: 270.838,
        VE_12: 1.25992,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.931975118150717, 34.683079612831648, 0.0],
          [-80.935414518145365, 34.684566679495958, 0.0],
          [-80.941125984803136, 34.683303812831184, 0.0],
          [-80.941235784802984, 34.681998079499976, 0.0],
          [-80.937796984808358, 34.681244079501198, 0.0],
          [-80.936878718143078, 34.676823679507947, 0.0],
          [-80.940203984804555, 34.674164679512103, 0.0],
          [-80.941365784802827, 34.670728212850804, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291170.0,
        COMID: 9718377,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.163,
        REACHCODE: "03050104002463",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001747601913517,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058216.0,
        ToNode: 250058215.0,
        Hydroseq: 250004025.0,
        LevelPathI: 250003258.0,
        Pathlength: 348.432,
        TerminalPa: 250002604.0,
        ArbolateSu: 8962.575,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004036.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004012.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1287,
        TotDASqKM: 11988.5463,
        DivDASqKM: 11988.5463,
        Tidal: 0,
        TOTMA: 0.08230617215,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6751.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.163000002503395,
        QA_MA: 5698.819,
        VA_MA: -9998.0,
        QC_MA: 5698.819,
        VC_MA: -9998.0,
        QE_MA: 6396.768,
        VE_MA: -9998.0,
        QA_01: 10761.166,
        VA_01: -9998.0,
        QC_01: 10761.166,
        VC_01: -9998.0,
        QE_01: 9367.484,
        VE_01: -9998.0,
        QA_02: 11392.348,
        VA_02: -9998.0,
        QC_02: 11392.348,
        VC_02: -9998.0,
        QE_02: 9551.217,
        VE_02: -9998.0,
        QA_03: 12467.267,
        VA_03: -9998.0,
        QC_03: 12467.267,
        VC_03: -9998.0,
        QE_03: 10216.252,
        VE_03: -9998.0,
        QA_04: 8314.883,
        VA_04: -9998.0,
        QC_04: 8314.883,
        VC_04: -9998.0,
        QE_04: 8063.276,
        VE_04: -9998.0,
        QA_05: 5521.231,
        VA_05: -9998.0,
        QC_05: 5521.231,
        VC_05: -9998.0,
        QE_05: 6476.59,
        VE_05: -9998.0,
        QA_06: 3297.217,
        VA_06: -9998.0,
        QC_06: 3297.217,
        VC_06: -9998.0,
        QE_06: 5522.133,
        VE_06: -9998.0,
        QA_07: 2090.841,
        VA_07: -9998.0,
        QC_07: 2090.841,
        VC_07: -9998.0,
        QE_07: 4167.723,
        VE_07: -9998.0,
        QA_08: 1508.786,
        VA_08: -9998.0,
        QC_08: 1508.786,
        VC_08: -9998.0,
        QE_08: 3805.285,
        VE_08: -9998.0,
        QA_09: 1342.939,
        VA_09: -9998.0,
        QC_09: 1342.939,
        VC_09: -9998.0,
        QE_09: 3480.905,
        VE_09: -9998.0,
        QA_10: 1392.606,
        VA_10: -9998.0,
        QC_10: 1392.606,
        VC_10: -9998.0,
        QE_10: 4714.037,
        VE_10: -9998.0,
        QA_11: 3331.094,
        VA_11: -9998.0,
        QC_11: 3331.094,
        VC_11: -9998.0,
        QE_11: 4778.14,
        VE_11: -9998.0,
        QA_12: 7004.664,
        VA_12: -9998.0,
        QC_12: 7004.664,
        VC_12: -9998.0,
        QE_12: 6712.805,
        VE_12: -9998.0,
        LakeFract: 0.0053230765,
        SurfArea: 241393.07367400001,
        RAreaHLoad: 0.01542199031,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.820925918323042, 34.412308613251867, 0.0],
          [-80.819267784992348, 34.412804079917919, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284003.0,
        COMID: 9754922,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.788,
        REACHCODE: "03050101000417",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018133892096314001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061751.0,
        ToNode: 250061744.0,
        Hydroseq: 250037438.0,
        LevelPathI: 250003258.0,
        Pathlength: 705.915,
        TerminalPa: 250002604.0,
        ArbolateSu: 84.18,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250039677.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250035527.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.1446,
        TotDASqKM: 94.2606,
        DivDASqKM: 94.2606,
        Tidal: 0,
        TOTMA: 0.055045815847099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41838.0,
        MAXELEVSMO: 42814.0,
        MINELEVSMO: 41936.0,
        SLOPE: 0.00491051,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7879999876022341,
        QA_MA: 60.902,
        VA_MA: 1.23103,
        QC_MA: 62.885,
        VC_MA: 1.23343,
        QE_MA: 62.885,
        VE_MA: 1.23343,
        QA_01: 94.808,
        VA_01: 1.47546,
        QC_01: 76.31,
        VC_01: 1.33353,
        QE_01: 76.31,
        VE_01: 1.33353,
        QA_02: 100.655,
        VA_02: 1.51314,
        QC_02: 86.481,
        VC_02: 1.40395,
        QE_02: 86.481,
        VE_02: 1.40395,
        QA_03: 114.055,
        VA_03: 1.59581,
        QC_03: 99.327,
        VC_03: 1.48755,
        QE_03: 99.327,
        VE_03: 1.48755,
        QA_04: 86.424,
        VA_04: 1.41947,
        QC_04: 81.724,
        VC_04: 1.37152,
        QE_04: 81.724,
        VE_04: 1.37152,
        QA_05: 69.366,
        VA_05: 1.29704,
        QC_05: 67.221,
        VC_05: 1.26677,
        QE_05: 67.221,
        VE_05: 1.26677,
        QA_06: 41.018,
        VA_06: 1.05636,
        QC_06: 52.015,
        VC_06: 1.14475,
        QE_06: 52.015,
        VE_06: 1.14475,
        QA_07: 23.858,
        VA_07: 0.86933,
        QC_07: 36.889,
        VC_07: 1.00526,
        QE_07: 36.889,
        VE_07: 1.00526,
        QA_08: 16.611,
        VA_08: 0.77122,
        QC_08: 36.452,
        VC_08: 1.00087,
        QE_08: 36.452,
        VE_08: 1.00087,
        QA_09: 20.139,
        VA_09: 0.82106,
        QC_09: 55.425,
        VC_09: 1.17343,
        QE_09: 55.425,
        VE_09: 1.17343,
        QA_10: 29.808,
        VA_10: 0.93973,
        QC_10: 82.816,
        VC_10: 1.37904,
        QE_10: 82.816,
        VE_10: 1.37904,
        QA_11: 59.079,
        VA_11: 1.21626,
        QC_11: 85.779,
        VC_11: 1.39922,
        QE_11: 85.779,
        VE_11: 1.39922,
        QA_12: 75.504,
        VA_12: 1.34257,
        QC_12: 67.583,
        VC_12: 1.2695,
        QE_12: 67.583,
        VE_12: 1.2695,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.175177116220993, 35.62501421136966, 0.0],
          [-82.160878916243121, 35.635943011352538, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287022.0,
        COMID: 9734986,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.359,
        REACHCODE: "03050103000296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013629869207705,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059579.0,
        ToNode: 250059597.0,
        Hydroseq: 250018601.0,
        LevelPathI: 250015946.0,
        Pathlength: 395.103,
        TerminalPa: 250002604.0,
        ArbolateSu: 323.44,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250018886.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018330.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0215,
        TotDASqKM: 703.5147,
        DivDASqKM: 703.5147,
        Tidal: 0,
        TOTMA: 0.039100528819099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11668.0,
        MAXELEVSMO: 11871.0,
        MINELEVSMO: 11788.0,
        SLOPE: 0.00061074,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.358999967575073,
        QA_MA: 290.739,
        VA_MA: 1.32214,
        QC_MA: 282.534,
        VC_MA: 1.3198,
        QE_MA: 282.534,
        VE_MA: 1.3198,
        QA_01: 626.374,
        VA_01: 1.8322,
        QC_01: 479.821,
        VC_01: 1.64825,
        QE_01: 479.821,
        VE_01: 1.64825,
        QA_02: 656.697,
        VA_02: 1.87094,
        QC_02: 536.103,
        VC_02: 1.72953,
        QE_02: 536.103,
        VE_02: 1.72953,
        QA_03: 706.01,
        VA_03: 1.93218,
        QC_03: 578.933,
        VC_03: 1.78873,
        QE_03: 578.933,
        VE_03: 1.78873,
        QA_04: 417.501,
        VA_04: 1.53691,
        QC_04: 380.914,
        VC_04: 1.49368,
        QE_04: 380.914,
        VE_04: 1.49368,
        QA_05: 231.633,
        VA_05: 1.2069,
        QC_05: 214.571,
        VC_05: 1.18231,
        QE_05: 214.571,
        VE_05: 1.18231,
        QA_06: 147.084,
        VA_06: 1.01437,
        QC_06: 183.275,
        VC_06: 1.11213,
        QE_06: 183.275,
        VE_06: 1.11213,
        QA_07: 101.802,
        VA_07: 0.88906,
        QC_07: 152.035,
        VC_07: 1.0362,
        QE_07: 152.035,
        VE_07: 1.0362,
        QA_08: 75.671,
        VA_08: 0.80444,
        QC_08: 171.177,
        VC_08: 1.08351,
        QE_08: 171.177,
        VE_08: 1.08351,
        QA_09: 64.066,
        VA_09: 0.76247,
        QC_09: 153.967,
        VC_09: 1.0411,
        QE_09: 153.967,
        VE_09: 1.0411,
        QA_10: 47.368,
        VA_10: 0.6952,
        QC_10: 119.894,
        VC_10: 0.94996,
        QE_10: 119.894,
        VE_10: 0.94996,
        QA_11: 71.335,
        VA_11: 0.78913,
        QC_11: 99.04,
        VC_11: 0.88806,
        QE_11: 99.04,
        VE_11: 0.88806,
        QA_12: 345.469,
        VA_12: 1.41937,
        QC_12: 269.604,
        VC_12: 1.29495,
        QE_12: 269.604,
        VE_12: 1.29495,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.940077118138049, 34.689649279488037, 0.0],
          [-80.939215584806107, 34.687244679491926, 0.0],
          [-80.932282318150214, 34.686446612826387, 0.0],
          [-80.931975118150717, 34.683079612831648, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287015.0,
        COMID: 9735258,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 4.807,
        REACHCODE: "03050103000289",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.049281069547761999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059666.0,
        ToNode: 250059685.0,
        Hydroseq: 250016928.0,
        LevelPathI: 250015946.0,
        Pathlength: 381.063,
        TerminalPa: 250002604.0,
        ArbolateSu: 359.487,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250017141.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016716.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6009,
        TotDASqKM: 739.0746,
        DivDASqKM: 739.0746,
        Tidal: 0,
        TOTMA: 0.153811874352,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10897.0,
        MAXELEVSMO: 11303.0,
        MINELEVSMO: 11190.0,
        SLOPE: 0.00023507,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.8070001602172852,
        QA_MA: 303.802,
        VA_MA: 1.1889,
        QC_MA: 294.726,
        VC_MA: 1.18674,
        QE_MA: 294.726,
        VE_MA: 1.18674,
        QA_01: 654.956,
        VA_01: 1.63238,
        QC_01: 501.13,
        VC_01: 1.47273,
        QE_01: 501.13,
        VE_01: 1.47273,
        QA_02: 687.383,
        VA_02: 1.6668,
        QC_02: 560.456,
        VC_02: 1.544,
        QE_02: 560.456,
        VE_02: 1.544,
        QA_03: 739.306,
        VA_03: 1.72035,
        QC_03: 605.315,
        VC_03: 1.59556,
        QE_03: 605.315,
        VE_03: 1.59556,
        QA_04: 435.997,
        VA_04: 1.37509,
        QC_04: 397.397,
        VC_04: 1.33781,
        QE_04: 397.397,
        VE_04: 1.33781,
        QA_05: 241.941,
        VA_05: 1.08863,
        QC_05: 223.756,
        VC_05: 1.06721,
        QE_05: 223.756,
        VE_05: 1.06721,
        QA_06: 153.754,
        VA_06: 0.9217,
        QC_06: 191.469,
        VC_06: 1.00695,
        QE_06: 191.469,
        VE_06: 1.00695,
        QA_07: 106.583,
        VA_07: 0.81326,
        QC_07: 159.001,
        VC_07: 0.94131,
        QE_07: 159.001,
        VE_07: 0.94131,
        QA_08: 79.496,
        VA_08: 0.74047,
        QC_08: 180.007,
        VC_08: 0.98441,
        QE_08: 180.007,
        VE_08: 0.98441,
        QA_09: 66.935,
        VA_09: 0.70275,
        QC_09: 160.039,
        VC_09: 0.9435,
        QE_09: 160.039,
        VE_09: 0.9435,
        QA_10: 49.758,
        VA_10: 0.6453,
        QC_10: 124.704,
        VC_10: 0.8647,
        QE_10: 124.704,
        VE_10: 0.8647,
        QA_11: 73.247,
        VA_11: 0.72208,
        QC_11: 101.059,
        VC_11: 0.80594,
        QE_11: 101.059,
        VE_11: 0.80594,
        QA_12: 358.571,
        VA_12: 1.26991,
        QC_12: 278.891,
        VC_12: 1.16136,
        QE_12: 278.891,
        VE_12: 1.16136,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.921442118167022, 34.638577679567334, 0.0],
          [-80.91861538483812, 34.63901401290002, 0.0],
          [-80.917199984840295, 34.634960079573034, 0.0],
          [-80.923184918164338, 34.633056479575941, 0.0],
          [-80.923350318164069, 34.631292612911977, 0.0],
          [-80.914122318178386, 34.631456479578389, 0.0],
          [-80.911185384849603, 34.632923479576107, 0.0],
          [-80.906306918190523, 34.629992879580755, 0.0],
          [-80.91741498483988, 34.620826812928215, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287993.0,
        COMID: 9734108,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.233,
        REACHCODE: "03050103000994",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0025125724786159999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059274.0,
        ToNode: 250059275.0,
        Hydroseq: 250020560.0,
        LevelPathI: 250020560.0,
        Pathlength: 408.565,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.254,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005808.0,
        UpHydroseq: 250020927.0,
        DnLevelPat: 250005808.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020202.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0162,
        TotDASqKM: 110.6298,
        DivDASqKM: 0.0162,
        Tidal: 0,
        TOTMA: 0.025040713983,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14361.0,
        MAXELEVSMO: 14361.0,
        MINELEVSMO: 14361.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.082999996840953993,
        QA_MA: 0.006,
        VA_MA: 0.35333,
        QC_MA: 0.006,
        VC_MA: 0.35333,
        QE_MA: 0.006,
        VE_MA: 0.35333,
        QA_01: 0.014,
        VA_01: 0.37886,
        QC_01: 0.014,
        VC_01: 0.37886,
        QE_01: 0.014,
        VE_01: 0.37886,
        QA_02: 0.014,
        VA_02: 0.37886,
        QC_02: 0.014,
        VC_02: 0.37886,
        QE_02: 0.014,
        VE_02: 0.37886,
        QA_03: 0.015,
        VA_03: 0.38149,
        QC_03: 0.015,
        VC_03: 0.38149,
        QE_03: 0.015,
        VE_03: 0.38149,
        QA_04: 0.009,
        VA_04: 0.36412,
        QC_04: 0.009,
        VC_04: 0.36412,
        QE_04: 0.009,
        VE_04: 0.36412,
        QA_05: 0.005,
        VA_05: 0.34918,
        QC_05: 0.005,
        VC_05: 0.34918,
        QE_05: 0.005,
        VE_05: 0.34918,
        QA_06: 0.003,
        VA_06: 0.33949,
        QC_06: 0.003,
        VC_06: 0.33949,
        QE_06: 0.003,
        VE_06: 0.33949,
        QA_07: 0.002,
        VA_07: 0.33347,
        QC_07: 0.002,
        VC_07: 0.33347,
        QE_07: 0.002,
        VE_07: 0.33347,
        QA_08: 0.001,
        VA_08: 0.32574,
        QC_08: 0.001,
        VC_08: 0.32574,
        QE_08: 0.001,
        VE_08: 0.32574,
        QA_09: 0.001,
        VA_09: 0.32574,
        QC_09: 0.001,
        VC_09: 0.32574,
        QE_09: 0.001,
        VE_09: 0.32574,
        QA_10: 0.001,
        VA_10: 0.32574,
        QC_10: 0.001,
        VC_10: 0.32574,
        QE_10: 0.001,
        VE_10: 0.32574,
        QA_11: 0.001,
        VA_11: 0.32574,
        QC_11: 0.001,
        VC_11: 0.32574,
        QE_11: 0.001,
        VE_11: 0.32574,
        QA_12: 0.007,
        VA_12: 0.35716,
        QC_12: 0.007,
        VC_12: 0.35716,
        QE_12: 0.007,
        VE_12: 0.35716,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.83243678497189, 34.823873479279825, 0.0],
          [-80.834825784968189, 34.823278679280691, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287016.0,
        COMID: 9735208,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 2.897,
        REACHCODE: "03050103000290",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.029100004353176001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059647.0,
        ToNode: 250059666.0,
        Hydroseq: 250017141.0,
        LevelPathI: 250015946.0,
        Pathlength: 385.87,
        TerminalPa: 250002604.0,
        ArbolateSu: 345.902,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250017363.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016928.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.3444,
        TotDASqKM: 725.7096,
        DivDASqKM: 725.7096,
        Tidal: 0,
        TOTMA: 0.090317628740699998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11174.0,
        MAXELEVSMO: 11390.0,
        MINELEVSMO: 11303.0,
        SLOPE: 0.00030031,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.8970000743865971,
        QA_MA: 298.956,
        VA_MA: 1.2202,
        QC_MA: 290.205,
        VC_MA: 1.218,
        QE_MA: 290.205,
        VE_MA: 1.218,
        QA_01: 644.375,
        VA_01: 1.67929,
        QC_01: 493.244,
        VC_01: 1.51392,
        QE_01: 493.244,
        VE_01: 1.51392,
        QA_02: 675.977,
        VA_02: 1.71459,
        QC_02: 551.407,
        VC_02: 1.58742,
        QE_02: 551.407,
        VE_02: 1.58742,
        QA_03: 726.899,
        VA_03: 1.76988,
        QC_03: 595.49,
        VC_03: 1.64074,
        QE_03: 595.49,
        VE_03: 1.64074,
        QA_04: 429.154,
        VA_04: 1.41316,
        QC_04: 391.3,
        VC_04: 1.37445,
        QE_04: 391.3,
        VE_04: 1.37445,
        QA_05: 238.12,
        VA_05: 1.11643,
        QC_05: 220.352,
        VC_05: 1.09427,
        QE_05: 220.352,
        VE_05: 1.09427,
        QA_06: 151.261,
        VA_06: 0.94341,
        QC_06: 188.408,
        VC_06: 1.03156,
        QE_06: 188.408,
        VE_06: 1.03156,
        QA_07: 104.786,
        VA_07: 0.83095,
        QC_07: 156.383,
        VC_07: 0.96344,
        QE_07: 156.383,
        VE_07: 0.96344,
        QA_08: 78.058,
        VA_08: 0.75531,
        QC_08: 176.687,
        VC_08: 1.00731,
        QE_08: 176.687,
        VE_08: 1.00731,
        QA_09: 65.857,
        VA_09: 0.71674,
        QC_09: 157.76,
        VC_09: 0.96649,
        QE_09: 157.76,
        VE_09: 0.96649,
        QA_10: 48.86,
        VA_10: 0.65688,
        QC_10: 122.902,
        VC_10: 0.88478,
        QE_10: 122.902,
        VE_10: 0.88478,
        QA_11: 72.528,
        VA_11: 0.7382,
        QC_11: 100.302,
        VC_11: 0.82582,
        QE_11: 100.302,
        VE_11: 0.82582,
        QA_12: 353.832,
        VA_12: 1.30555,
        QC_12: 275.536,
        VC_12: 1.19329,
        QE_12: 275.536,
        VE_12: 1.19329,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.933919984814338, 34.648009279552809, 0.0],
          [-80.934805518146334, 34.645832812889353, 0.0],
          [-80.927623984824095, 34.641186279563271, 0.0],
          [-80.93094631815228, 34.635687212905225, 0.0],
          [-80.928978184822029, 34.635161412905973, 0.0],
          [-80.921442118167022, 34.638577679567334, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285999.0,
        COMID: 9751070,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.335,
        REACHCODE: "03050101002674",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.032991602506692003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060697.0,
        ToNode: 250060701.0,
        Hydroseq: 250008846.0,
        LevelPathI: 250003258.0,
        Pathlength: 539.39,
        TerminalPa: 250002604.0,
        ArbolateSu: 3657.573,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008881.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008807.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.9742,
        TotDASqKM: 4158.0756,
        DivDASqKM: 4158.0756,
        Tidal: 0,
        TOTMA: 4.1591229104399998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23058.0,
        MAXELEVSMO: 23065.0,
        MINELEVSMO: 23059.0,
        SLOPE: 1.799e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.3350000381469731,
        QA_MA: 2424.364,
        VA_MA: -9998.0,
        QC_MA: 2424.364,
        VC_MA: -9998.0,
        QE_MA: 2618.466,
        VE_MA: -9998.0,
        QA_01: 3988.379,
        VA_01: -9998.0,
        QC_01: 3988.379,
        VC_01: -9998.0,
        QE_01: 4172.813,
        VE_01: -9998.0,
        QA_02: 4212.392,
        VA_02: -9998.0,
        QC_02: 4212.392,
        VC_02: -9998.0,
        QE_02: 4260.251,
        VE_02: -9998.0,
        QA_03: 4740.157,
        VA_03: -9998.0,
        QC_03: 4740.157,
        VC_03: -9998.0,
        QE_03: 4975.799,
        VE_03: -9998.0,
        QA_04: 3613.723,
        VA_04: -9998.0,
        QC_04: 3613.723,
        VC_04: -9998.0,
        QE_04: 3796.117,
        VE_04: -9998.0,
        QA_05: 2712.089,
        VA_05: -9998.0,
        QC_05: 2712.089,
        VC_05: -9998.0,
        QE_05: 2935.204,
        VE_05: -9998.0,
        QA_06: 1582.735,
        VA_06: -9998.0,
        QC_06: 1582.735,
        VC_06: -9998.0,
        QE_06: 1860.546,
        VE_06: -9998.0,
        QA_07: 938.971,
        VA_07: -9998.0,
        QC_07: 938.971,
        VC_07: -9998.0,
        QE_07: 1104.873,
        VE_07: -9998.0,
        QA_08: 657.232,
        VA_08: -9998.0,
        QC_08: 657.232,
        VC_08: -9998.0,
        QE_08: 977.94,
        VE_08: -9998.0,
        QA_09: 631.577,
        VA_09: -9998.0,
        QC_09: 1161.035,
        VC_09: -9998.0,
        QE_09: 1212.824,
        VE_09: -9998.0,
        QA_10: 782.565,
        VA_10: -9998.0,
        QC_10: 782.565,
        VC_10: -9998.0,
        QE_10: 744.757,
        VE_10: -9998.0,
        QA_11: 2152.855,
        VA_11: -9998.0,
        QC_11: 2152.855,
        VC_11: -9998.0,
        QE_11: 2102.87,
        VE_11: -9998.0,
        QA_12: 3095.474,
        VA_12: -9998.0,
        QC_12: 3095.474,
        VC_12: -9998.0,
        QE_12: 3096.592,
        VE_12: -9998.0,
        LakeFract: 0.05535509383,
        SurfArea: 7241291.3318499997,
        RAreaHLoad: 1.12829770688,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.964529584766808, 35.659010277983555, 0.0],
          [-80.956824584778758, 35.657753277985421, 0.0],
          [-80.954664984782141, 35.655848811321789, 0.0],
          [-80.955189118114617, 35.64641807800308, 0.0],
          [-80.946301784795139, 35.638360211348811, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291149.0,
        COMID: 9718331,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.827,
        REACHCODE: "03050104002442",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0078376872103379999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058193.0,
        ToNode: 250058195.0,
        Hydroseq: 250004091.0,
        LevelPathI: 250003258.0,
        Pathlength: 354.286,
        TerminalPa: 250002604.0,
        ArbolateSu: 8721.783,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004102.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004081.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5229,
        TotDASqKM: 11777.0517,
        DivDASqKM: 11777.0517,
        Tidal: 0,
        TOTMA: 0.41759021073000002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6710.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.82700002193450906,
        QA_MA: 5622.917,
        VA_MA: -9998.0,
        QC_MA: 5622.917,
        VC_MA: -9998.0,
        QE_MA: 6320.772,
        VE_MA: -9998.0,
        QA_01: 10593.02,
        VA_01: -9998.0,
        QC_01: 10593.02,
        VC_01: -9998.0,
        QE_01: 9199.738,
        VE_01: -9998.0,
        QA_02: 11211.186,
        VA_02: -9998.0,
        QC_02: 11211.186,
        VC_02: -9998.0,
        QE_02: 9370.468,
        VE_02: -9998.0,
        QA_03: 12270.923,
        VA_03: -9998.0,
        QC_03: 12270.923,
        VC_03: -9998.0,
        QE_03: 10020.536,
        VE_03: -9998.0,
        QA_04: 8208.183,
        VA_04: -9998.0,
        QC_04: 8208.183,
        VC_04: -9998.0,
        QE_04: 7956.73,
        VE_04: -9998.0,
        QA_05: 5461.178,
        VA_05: -9998.0,
        QC_05: 5461.178,
        VC_05: -9998.0,
        QE_05: 6416.527,
        VE_05: -9998.0,
        QA_06: 3257.645,
        VA_06: -9998.0,
        QC_06: 3257.645,
        VC_06: -9998.0,
        QE_06: 5482.168,
        VE_06: -9998.0,
        QA_07: 2062.405,
        VA_07: -9998.0,
        QC_07: 2062.405,
        VC_07: -9998.0,
        QE_07: 4138.932,
        VE_07: -9998.0,
        QA_08: 1486.038,
        VA_08: -9998.0,
        QC_08: 1486.038,
        VC_08: -9998.0,
        QE_08: 3782.045,
        VE_08: -9998.0,
        QA_09: 1325.878,
        VA_09: -9998.0,
        QC_09: 1325.878,
        VC_09: -9998.0,
        QE_09: 3463.446,
        VE_09: -9998.0,
        QA_10: 1378.388,
        VA_10: -9998.0,
        QC_10: 1378.388,
        VC_10: -9998.0,
        QE_10: 4699.167,
        VE_10: -9998.0,
        QA_11: 3319.72,
        VA_11: -9998.0,
        QC_11: 3319.72,
        VC_11: -9998.0,
        QE_11: 4766.427,
        VE_11: -9998.0,
        QA_12: 6939.126,
        VA_12: -9998.0,
        QC_12: 6939.126,
        VC_12: -9998.0,
        QE_12: 6647.452,
        VE_12: -9998.0,
        LakeFract: 0.02668645654,
        SurfArea: 1210188.4633200001,
        RAreaHLoad: 0.0782453128,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.869792984913886, 34.448915613195197, 0.0],
          [-80.865553984920496, 34.442518413204994, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287017.0,
        COMID: 9735146,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.527,
        REACHCODE: "03050103000291",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0050455997036239998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059627.0,
        ToNode: 250059647.0,
        Hydroseq: 250017363.0,
        LevelPathI: 250015946.0,
        Pathlength: 388.767,
        TerminalPa: 250002604.0,
        ArbolateSu: 341.32,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250017592.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017141.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4392,
        TotDASqKM: 720.8937,
        DivDASqKM: 720.8937,
        Tidal: 0,
        TOTMA: 0.01270739489,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11362.0,
        MAXELEVSMO: 11518.0,
        MINELEVSMO: 11390.0,
        SLOPE: 0.00242884,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.52700001001357999,
        QA_MA: 297.229,
        VA_MA: 1.57783,
        QC_MA: 288.593,
        VC_MA: 1.5748,
        QE_MA: 288.593,
        VE_MA: 1.5748,
        QA_01: 640.593,
        VA_01: 2.21691,
        QC_01: 490.425,
        VC_01: 1.98664,
        QE_01: 490.425,
        VE_01: 1.98664,
        QA_02: 671.894,
        VA_02: 2.26587,
        QC_02: 548.167,
        VC_02: 2.08882,
        QE_02: 548.167,
        VE_02: 2.08882,
        QA_03: 722.458,
        VA_03: 2.34276,
        QC_03: 591.971,
        VC_03: 2.16301,
        QE_03: 591.971,
        VE_03: 2.16301,
        QA_04: 426.719,
        VA_04: 1.84657,
        QC_04: 389.131,
        VC_04: 1.79263,
        QE_04: 389.131,
        VE_04: 1.79263,
        QA_05: 236.764,
        VA_05: 1.43342,
        QC_05: 219.145,
        VC_05: 1.40258,
        QE_05: 219.145,
        VE_05: 1.40258,
        QA_06: 150.355,
        VA_06: 1.19239,
        QC_06: 187.294,
        VC_06: 1.31503,
        QE_06: 187.294,
        VE_06: 1.31503,
        QA_07: 104.139,
        VA_07: 1.03576,
        QC_07: 155.44,
        VC_07: 1.22016,
        QE_07: 155.44,
        VE_07: 1.22016,
        QA_08: 77.54,
        VA_08: 0.93031,
        QC_08: 175.491,
        VC_08: 1.28083,
        QE_08: 175.491,
        VE_08: 1.28083,
        QA_09: 65.468,
        VA_09: 0.87686,
        QC_09: 156.938,
        VC_09: 1.22481,
        QE_09: 156.938,
        VE_09: 1.22481,
        QA_10: 48.536,
        VA_10: 0.79334,
        QC_10: 122.251,
        VC_10: 1.11098,
        QE_10: 122.251,
        VE_10: 1.11098,
        QA_11: 72.269,
        VA_11: 0.9075,
        QC_11: 100.028,
        VC_11: 1.02988,
        QE_11: 100.028,
        VE_11: 1.02988,
        QA_12: 352.21,
        VA_12: 1.69755,
        QC_12: 274.386,
        VC_12: 1.54131,
        QE_12: 274.386,
        VE_12: 1.54131,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.932924584815908, 34.65172061288024, 0.0],
          [-80.932257318150278, 34.648651479551745, 0.0],
          [-80.933919984814338, 34.648009279552809, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287018.0,
        COMID: 9735128,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 2.547,
        REACHCODE: "03050103000292",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024856731109328001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059621.0,
        ToNode: 250059627.0,
        Hydroseq: 250017592.0,
        LevelPathI: 250015946.0,
        Pathlength: 389.294,
        TerminalPa: 250002604.0,
        ArbolateSu: 339.213,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250017824.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017363.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9016,
        TotDASqKM: 719.2683,
        DivDASqKM: 719.2683,
        Tidal: 0,
        TOTMA: 0.075270387696699997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11375.0,
        MAXELEVSMO: 11639.0,
        MINELEVSMO: 11518.0,
        SLOPE: 0.00047506,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5469999313354492,
        QA_MA: 296.63,
        VA_MA: 1.28725,
        QC_MA: 288.035,
        VC_MA: 1.28492,
        QE_MA: 288.035,
        VE_MA: 1.28492,
        QA_01: 639.287,
        VA_01: 1.78002,
        QC_01: 489.451,
        VC_01: 1.60246,
        QE_01: 489.451,
        VE_01: 1.60246,
        QA_02: 670.494,
        VA_02: 1.81774,
        QC_02: 547.056,
        VC_02: 1.68122,
        QE_02: 547.056,
        VE_02: 1.68122,
        QA_03: 720.933,
        VA_03: 1.877,
        QC_03: 590.762,
        VC_03: 1.7384,
        QE_03: 590.762,
        VE_03: 1.7384,
        QA_04: 425.872,
        VA_04: 1.49449,
        QC_04: 388.376,
        VC_04: 1.45288,
        QE_04: 388.376,
        VE_04: 1.45288,
        QA_05: 236.288,
        VA_05: 1.1759,
        QC_05: 218.721,
        VC_05: 1.15212,
        QE_05: 218.721,
        VE_05: 1.15212,
        QA_06: 150.049,
        VA_06: 0.99003,
        QC_06: 186.918,
        VC_06: 1.08458,
        QE_06: 186.918,
        VE_06: 1.08458,
        QA_07: 103.92,
        VA_07: 0.86923,
        QC_07: 155.122,
        VC_07: 1.01141,
        QE_07: 155.122,
        VE_07: 1.01141,
        QA_08: 77.366,
        VA_08: 0.78789,
        QC_08: 175.088,
        VC_08: 1.0581,
        QE_08: 175.088,
        VE_08: 1.0581,
        QA_09: 65.337,
        VA_09: 0.74674,
        QC_09: 156.661,
        VC_09: 1.01511,
        QE_09: 156.661,
        VE_09: 1.01511,
        QA_10: 48.427,
        VA_10: 0.68229,
        QC_10: 122.031,
        VC_10: 0.92731,
        QE_10: 122.031,
        VE_10: 0.92731,
        QA_11: 72.182,
        VA_11: 0.77056,
        QC_11: 99.936,
        VC_11: 0.86503,
        QE_11: 99.936,
        VE_11: 0.86503,
        QA_12: 351.604,
        VA_12: 1.37973,
        QC_12: 273.957,
        VC_12: 1.25928,
        QE_12: 273.957,
        VE_12: 1.25928,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.942443918134416, 34.666650412857166, 0.0],
          [-80.937451184808879, 34.663308812862397, 0.0],
          [-80.932100918150468, 34.663701012861793, 0.0],
          [-80.930406384819833, 34.658250079536799, 0.0],
          [-80.932924584815908, 34.65172061288024, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288004.0,
        COMID: 9734426,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.059,
        REACHCODE: "03050103001001",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00052957240945000002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059382.0,
        ToNode: 250094142.0,
        Hydroseq: 250005549.0,
        LevelPathI: 250005531.0,
        Pathlength: 398.45,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.468,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005531.0,
        UpHydroseq: 250005560.0,
        DnLevelPat: 250005531.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005531.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9333.0117,
        DivDASqKM: 0.2007,
        Tidal: 0,
        TOTMA: 0.0044074371252499998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13097.0,
        MAXELEVSMO: 13115.0,
        MINELEVSMO: 13097.0,
        SLOPE: 0.00305084,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.059000000357628,
        QA_MA: 0.079,
        VA_MA: 0.50832,
        QC_MA: 0.079,
        VC_MA: 0.50832,
        QE_MA: 0.079,
        VE_MA: 0.50832,
        QA_01: 0.175,
        VA_01: 0.61339,
        QC_01: 0.175,
        VC_01: 0.61339,
        QE_01: 0.175,
        VE_01: 0.61339,
        QA_02: 0.18,
        VA_02: 0.61799,
        QC_02: 0.18,
        VC_02: 0.61799,
        QE_02: 0.18,
        VE_02: 0.61799,
        QA_03: 0.194,
        VA_03: 0.63055,
        QC_03: 0.194,
        VC_03: 0.63055,
        QE_03: 0.194,
        VE_03: 0.63055,
        QA_04: 0.113,
        VA_04: 0.55018,
        QC_04: 0.113,
        VC_04: 0.55018,
        QE_04: 0.113,
        VE_04: 0.55018,
        QA_05: 0.062,
        VA_05: 0.48419,
        QC_05: 0.062,
        VC_05: 0.48419,
        QE_05: 0.062,
        VE_05: 0.48419,
        QA_06: 0.04,
        VA_06: 0.44769,
        QC_06: 0.04,
        VC_06: 0.44769,
        QE_06: 0.04,
        VE_06: 0.44769,
        QA_07: 0.029,
        VA_07: 0.42582,
        QC_07: 0.029,
        VC_07: 0.42582,
        QE_07: 0.029,
        VE_07: 0.42582,
        QA_08: 0.021,
        VA_08: 0.40732,
        QC_08: 0.021,
        VC_08: 0.40732,
        QE_08: 0.021,
        VE_08: 0.40732,
        QA_09: 0.016,
        VA_09: 0.39402,
        QC_09: 0.016,
        VC_09: 0.39402,
        QE_09: 0.016,
        VE_09: 0.39402,
        QA_10: 0.013,
        VA_10: 0.38508,
        QC_10: 0.013,
        VC_10: 0.38508,
        QE_10: 0.013,
        VE_10: 0.38508,
        QA_11: 0.013,
        VA_11: 0.38508,
        QC_11: 0.013,
        VC_11: 0.38508,
        QE_11: 0.013,
        VE_11: 0.38508,
        QA_12: 0.091,
        VA_12: 0.52392,
        QC_12: 0.091,
        VC_12: 0.52392,
        QE_12: 0.091,
        VE_12: 0.52392,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872713784909365, 34.777243279352149, 0.0],
          [-80.872769184909316, 34.776716612686357, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288005.0,
        COMID: 9734428,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.069,
        REACHCODE: "03050103001002",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00061853333586499998,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059383.0,
        ToNode: 250094143.0,
        Hydroseq: 250005548.0,
        LevelPathI: 250005530.0,
        Pathlength: 398.475,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.492,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005530.0,
        UpHydroseq: 250005559.0,
        DnLevelPat: 250005530.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005530.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4536,
        TotDASqKM: 9333.432,
        DivDASqKM: 0.4536,
        Tidal: 0,
        TOTMA: 0.0050867134134600003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13100.0,
        MAXELEVSMO: 13108.0,
        MINELEVSMO: 13100.0,
        SLOPE: 0.00115942,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.068999998271464996,
        QA_MA: 0.178,
        VA_MA: 0.51509,
        QC_MA: 0.178,
        VC_MA: 0.51509,
        QE_MA: 0.178,
        VE_MA: 0.51509,
        QA_01: 0.392,
        VA_01: 0.62273,
        QC_01: 0.392,
        VC_01: 0.62273,
        QE_01: 0.392,
        VE_01: 0.62273,
        QA_02: 0.408,
        VA_02: 0.62947,
        QC_02: 0.408,
        VC_02: 0.62947,
        QE_02: 0.408,
        VE_02: 0.62947,
        QA_03: 0.439,
        VA_03: 0.64221,
        QC_03: 0.439,
        VC_03: 0.64221,
        QE_03: 0.439,
        VE_03: 0.64221,
        QA_04: 0.252,
        VA_04: 0.55699,
        QC_04: 0.252,
        VC_04: 0.55699,
        QE_04: 0.252,
        VE_04: 0.55699,
        QA_05: 0.14,
        VA_05: 0.49034,
        QC_05: 0.14,
        VC_05: 0.49034,
        QE_05: 0.14,
        VE_05: 0.49034,
        QA_06: 0.091,
        VA_06: 0.45314,
        QC_06: 0.091,
        VC_06: 0.45314,
        QE_06: 0.091,
        VE_06: 0.45314,
        QA_07: 0.067,
        VA_07: 0.43142,
        QC_07: 0.067,
        VC_07: 0.43142,
        QE_07: 0.067,
        VE_07: 0.43142,
        QA_08: 0.048,
        VA_08: 0.41145,
        QC_08: 0.048,
        VC_08: 0.41145,
        QE_08: 0.048,
        VE_08: 0.41145,
        QA_09: 0.036,
        VA_09: 0.39685,
        QC_09: 0.036,
        VC_09: 0.39685,
        QE_09: 0.036,
        VE_09: 0.39685,
        QA_10: 0.03,
        VA_10: 0.38869,
        QC_10: 0.03,
        VC_10: 0.38869,
        QE_10: 0.03,
        VE_10: 0.38869,
        QA_11: 0.03,
        VA_11: 0.38869,
        QC_11: 0.03,
        VC_11: 0.38869,
        QE_11: 0.03,
        VE_11: 0.38869,
        QA_12: 0.203,
        VA_12: 0.53003,
        QC_12: 0.203,
        VC_12: 0.53003,
        QE_12: 0.203,
        VE_12: 0.53003,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.872297318243341, 34.777289212685389, 0.0],
          [-80.872297384909984, 34.776670679353117, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291144.0,
        COMID: 9718311,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 1.021,
        REACHCODE: "03050104002437",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010015922047572,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058183.0,
        ToNode: 250058193.0,
        Hydroseq: 250004102.0,
        LevelPathI: 250003258.0,
        Pathlength: 355.113,
        TerminalPa: 250002604.0,
        ArbolateSu: 8718.691,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004114.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004091.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6245,
        TotDASqKM: 11774.5335,
        DivDASqKM: 11774.5335,
        Tidal: 0,
        TOTMA: 0.515549704,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6617.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.021000027656555,
        QA_MA: 5622.031,
        VA_MA: -9998.0,
        QC_MA: 5622.031,
        VC_MA: -9998.0,
        QE_MA: 6319.886,
        VE_MA: -9998.0,
        QA_01: 10591.077,
        VA_01: -9998.0,
        QC_01: 10591.077,
        VC_01: -9998.0,
        QE_01: 9197.8,
        VE_01: -9998.0,
        QA_02: 11209.085,
        VA_02: -9998.0,
        QC_02: 11209.085,
        VC_02: -9998.0,
        QE_02: 9368.372,
        VE_02: -9998.0,
        QA_03: 12268.624,
        VA_03: -9998.0,
        QC_03: 12268.624,
        VC_03: -9998.0,
        QE_03: 10018.244,
        VE_03: -9998.0,
        QA_04: 8206.931,
        VA_04: -9998.0,
        QC_04: 8206.931,
        VC_04: -9998.0,
        QE_04: 7955.479,
        VE_04: -9998.0,
        QA_05: 5460.47,
        VA_05: -9998.0,
        QC_05: 5460.47,
        VC_05: -9998.0,
        QE_05: 6415.82,
        VE_05: -9998.0,
        QA_06: 3257.171,
        VA_06: -9998.0,
        QC_06: 3257.171,
        VC_06: -9998.0,
        QE_06: 5481.69,
        VE_06: -9998.0,
        QA_07: 2062.067,
        VA_07: -9998.0,
        QC_07: 2062.067,
        VC_07: -9998.0,
        QE_07: 4138.589,
        VE_07: -9998.0,
        QA_08: 1485.767,
        VA_08: -9998.0,
        QC_08: 1485.767,
        VC_08: -9998.0,
        QE_08: 3781.768,
        VE_08: -9998.0,
        QA_09: 1325.675,
        VA_09: -9998.0,
        QC_09: 1325.675,
        VC_09: -9998.0,
        QE_09: 3463.238,
        VE_09: -9998.0,
        QA_10: 1378.219,
        VA_10: -9998.0,
        QC_10: 1378.219,
        VC_10: -9998.0,
        QE_10: 4698.989,
        VE_10: -9998.0,
        QA_11: 3319.585,
        VA_11: -9998.0,
        QC_11: 3319.585,
        VC_11: -9998.0,
        QE_11: 4766.288,
        VE_11: -9998.0,
        QA_12: 6938.379,
        VA_12: -9998.0,
        QC_12: 6938.379,
        VC_12: -9998.0,
        QE_12: 6646.707,
        VE_12: -9998.0,
        LakeFract: 0.0329420235,
        SurfArea: 1493868.4998600001,
        RAreaHLoad: 0.09660031968,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876904984902808, 34.455960213184198, 0.0],
          [-80.869792984913886, 34.448915613195197, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291145.0,
        COMID: 9718313,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.119,
        REACHCODE: "03050104002438",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001296622650705,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058184.0,
        ToNode: 250058201.0,
        Hydroseq: 250022158.0,
        LevelPathI: 250021319.0,
        Pathlength: 348.462,
        TerminalPa: 250002604.0,
        ArbolateSu: 112.516,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250022634.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021720.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 117.2862,
        DivDASqKM: 117.2862,
        Tidal: 0,
        TOTMA: 0.06008855492,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6847.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.11900000274181401,
        QA_MA: 43.735,
        VA_MA: -9998.0,
        QC_MA: 45.743,
        VC_MA: -9998.0,
        QE_MA: 45.743,
        VE_MA: -9998.0,
        QA_01: 97.224,
        VA_01: -9998.0,
        QC_01: 78.203,
        VC_01: -9998.0,
        QE_01: 78.203,
        VE_01: -9998.0,
        QA_02: 101.217,
        VA_02: -9998.0,
        QC_02: 86.951,
        VC_02: -9998.0,
        QE_02: 86.951,
        VE_02: -9998.0,
        QA_03: 108.58,
        VA_03: -9998.0,
        QC_03: 94.712,
        VC_03: -9998.0,
        QE_03: 94.712,
        VE_03: -9998.0,
        QA_04: 62.306,
        VA_04: -9998.0,
        QC_04: 59.357,
        VC_04: -9998.0,
        QE_04: 59.357,
        VE_04: -9998.0,
        QA_05: 34.645,
        VA_05: -9998.0,
        QC_05: 34.457,
        VC_05: -9998.0,
        QE_05: 34.457,
        VE_05: -9998.0,
        QA_06: 22.349,
        VA_06: -9998.0,
        QC_06: 28.578,
        VC_06: -9998.0,
        QE_06: 28.578,
        VE_06: -9998.0,
        QA_07: 16.969,
        VA_07: -9998.0,
        QC_07: 26.451,
        VC_07: -9998.0,
        QE_07: 26.451,
        VE_07: -9998.0,
        QA_08: 12.615,
        VA_08: -9998.0,
        QC_08: 27.532,
        VC_08: -9998.0,
        QE_08: 27.532,
        VE_08: -9998.0,
        QA_09: 9.524,
        VA_09: -9998.0,
        QC_09: 28.614,
        VC_09: -9998.0,
        QE_09: 28.614,
        VE_09: -9998.0,
        QA_10: 7.885,
        VA_10: -9998.0,
        QC_10: 28.626,
        VC_10: -9998.0,
        QE_10: 28.626,
        VE_10: -9998.0,
        QA_11: 6.749,
        VA_11: -9998.0,
        QC_11: 16.399,
        VC_11: -9998.0,
        QE_11: 16.399,
        VE_11: -9998.0,
        QA_12: 44.933,
        VA_12: -9998.0,
        QC_12: 42.146,
        VC_12: -9998.0,
        QE_12: 42.146,
        VE_12: -9998.0,
        LakeFract: 2.783918e-5,
        SurfArea: 1262.4626433200001,
        RAreaHLoad: 0.01125899903,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.77444618506189, 34.43530227988299, 0.0],
          [-80.775726318393254, 34.435406079882739, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284002.0,
        COMID: 9754900,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.356,
        REACHCODE: "03050101000416",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0039407119731490001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061744.0,
        ToNode: 250061746.0,
        Hydroseq: 250035527.0,
        LevelPathI: 250003258.0,
        Pathlength: 705.559,
        TerminalPa: 250002604.0,
        ArbolateSu: 86.654,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250037438.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033869.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2583,
        TotDASqKM: 97.0047,
        DivDASqKM: 97.0047,
        Tidal: 0,
        TOTMA: 0.0103972343532,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41675.0,
        MAXELEVSMO: 41936.0,
        MINELEVSMO: 41675.0,
        SLOPE: 0.00733146,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.356000006198883,
        QA_MA: 62.231,
        VA_MA: 1.29767,
        QC_MA: 64.203,
        VC_MA: 1.30018,
        QE_MA: 64.203,
        VE_MA: 1.30018,
        QA_01: 97.178,
        VA_01: 1.56182,
        QC_01: 78.166,
        VC_01: 1.40942,
        QE_01: 78.166,
        VE_01: 1.40942,
        QA_02: 103.188,
        VA_02: 1.60241,
        QC_02: 88.597,
        VC_02: 1.48514,
        QE_02: 88.597,
        VE_02: 1.48514,
        QA_03: 116.906,
        VA_03: 1.69108,
        QC_03: 101.726,
        VC_03: 1.57474,
        QE_03: 101.726,
        VE_03: 1.57474,
        QA_04: 88.484,
        VA_04: 1.50097,
        QC_04: 83.628,
        VC_04: 1.44963,
        QE_04: 83.628,
        VE_04: 1.44963,
        QA_05: 70.852,
        VA_05: 1.36823,
        QC_05: 68.607,
        VC_05: 1.33574,
        QE_05: 68.607,
        VE_05: 1.33574,
        QA_06: 41.845,
        VA_06: 1.10969,
        QC_06: 53.049,
        VC_06: 1.2046,
        QE_06: 53.049,
        VE_06: 1.2046,
        QA_07: 24.347,
        VA_07: 0.90943,
        QC_07: 37.627,
        VC_07: 1.05518,
        QE_07: 37.627,
        VE_07: 1.05518,
        QA_08: 16.943,
        VA_08: 0.80418,
        QC_08: 37.195,
        VC_08: 1.05061,
        QE_08: 37.195,
        VE_08: 1.05061,
        QA_09: 20.398,
        VA_09: 0.85552,
        QC_09: 56.052,
        VC_09: 1.23119,
        QE_09: 56.052,
        VE_09: 1.23119,
        QA_10: 29.993,
        VA_10: 0.97982,
        QC_10: 83.225,
        VC_10: 1.4467,
        QE_10: 83.225,
        VE_10: 1.4467,
        QA_11: 59.977,
        VA_11: 1.27848,
        QC_11: 86.77,
        VC_11: 1.4722,
        QE_11: 86.77,
        VE_11: 1.4722,
        QA_12: 77.187,
        VA_12: 1.41754,
        QC_12: 68.952,
        VC_12: 1.33848,
        QE_12: 68.952,
        VE_12: 1.33848,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.160878916243121, 35.635943011352538, 0.0],
          [-82.156979982915914, 35.63610461135238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283979.0,
        COMID: 9754770,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.263,
        REACHCODE: "03050101000393",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024019112297499999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061705.0,
        ToNode: 250061703.0,
        Hydroseq: 250019505.0,
        LevelPathI: 250003258.0,
        Pathlength: 689.721,
        TerminalPa: 250002604.0,
        ArbolateSu: 310.56,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250019847.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019183.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0747,
        TotDASqKM: 332.442,
        DivDASqKM: 332.442,
        Tidal: 0,
        TOTMA: 0.0066053869505400004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37476.0,
        MAXELEVSMO: 37702.0,
        MINELEVSMO: 37600.0,
        SLOPE: 0.00387832,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.26300001144409202,
        QA_MA: 219.618,
        VA_MA: 1.49766,
        QC_MA: 215.756,
        VC_MA: 1.49595,
        QE_MA: 254.435,
        VE_MA: 1.51192,
        QA_01: 347.143,
        VA_01: 1.82497,
        QC_01: 270.067,
        VC_01: 1.64632,
        QE_01: 296.915,
        VE_01: 1.61475,
        QA_02: 365.497,
        VA_02: 1.86703,
        QC_02: 303.182,
        VC_02: 1.73107,
        QE_02: 350.349,
        VE_02: 1.73474,
        QA_03: 412.251,
        VA_03: 1.96991,
        QC_03: 344.106,
        VC_03: 1.83001,
        QE_03: 367.392,
        VE_03: 1.77117,
        QA_04: 310.543,
        VA_04: 1.73785,
        QC_04: 285.241,
        VC_04: 1.68572,
        QE_04: 336.36,
        VE_04: 1.70421,
        QA_05: 248.235,
        VA_05: 1.57758,
        QC_05: 229.356,
        VC_05: 1.53513,
        QE_05: 264.088,
        VE_05: 1.53595,
        QA_06: 144.369,
        VA_06: 1.26017,
        QC_06: 179.938,
        VC_06: 1.38682,
        QE_06: 224.043,
        VE_06: 1.43331,
        QA_07: 83.586,
        VA_07: 1.02044,
        QC_07: 125.42,
        VC_07: 1.19873,
        QE_07: 172.039,
        VE_07: 1.28611,
        QA_08: 57.356,
        VA_08: 0.89138,
        QC_08: 129.029,
        VC_08: 1.21224,
        QE_08: 205.889,
        VE_08: 1.38395,
        QA_09: 66.944,
        VA_09: 0.94125,
        QC_09: 160.059,
        VC_09: 1.32182,
        QE_09: 172.973,
        VE_09: 1.28892,
        QA_10: 100.574,
        VA_10: 1.09394,
        QC_10: 218.784,
        VC_10: 1.50477,
        QE_10: 191.868,
        VE_10: 1.34442,
        QA_11: 221.164,
        VA_11: 1.5021,
        QC_11: 234.735,
        VC_11: 1.55032,
        QE_11: 216.122,
        VE_11: 1.41201,
        QA_12: 279.589,
        VA_12: 1.66033,
        QC_12: 222.394,
        VC_12: 1.51521,
        QE_12: 232.927,
        VE_12: 1.45678,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.050707783080895, 35.686746211273771, 0.0],
          [-82.051098916413537, 35.688990611270249, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286045.0,
        COMID: 9751172,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.295,
        REACHCODE: "03050101002720",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0027469308114159998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060748.0,
        ToNode: 250061845.0,
        Hydroseq: 250008594.0,
        LevelPathI: 250003258.0,
        Pathlength: 519.417,
        TerminalPa: 250002604.0,
        ArbolateSu: 3876.335,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008629.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008561.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3105,
        TotDASqKM: 4497.4557,
        DivDASqKM: 4497.4557,
        Tidal: 0,
        TOTMA: 0.36789842813000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23030.0,
        MAXELEVSMO: 23052.0,
        MINELEVSMO: 23050.0,
        SLOPE: 6.779e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.29499998688697798,
        QA_MA: 2568.926,
        VA_MA: -9998.0,
        QC_MA: 2568.926,
        VC_MA: -9998.0,
        QE_MA: 2762.161,
        VE_MA: -9998.0,
        QA_01: 4275.234,
        VA_01: -9998.0,
        QC_01: 4275.234,
        VC_01: -9998.0,
        QE_01: 4455.43,
        VE_01: -9998.0,
        QA_02: 4516.213,
        VA_02: -9998.0,
        QC_02: 4516.213,
        VC_02: -9998.0,
        QE_02: 4560.226,
        VE_02: -9998.0,
        QA_03: 5068.58,
        VA_03: -9998.0,
        QC_03: 5068.58,
        VC_03: -9998.0,
        QE_03: 5298.371,
        VE_03: -9998.0,
        QA_04: 3831.398,
        VA_04: -9998.0,
        QC_04: 3831.398,
        VC_04: -9998.0,
        QE_04: 4011.824,
        VE_04: -9998.0,
        QA_05: 2842.836,
        VA_05: -9998.0,
        QC_05: 2842.836,
        VC_05: -9998.0,
        QE_05: 3065.065,
        VE_05: -9998.0,
        QA_06: 1660.624,
        VA_06: -9998.0,
        QC_06: 1660.624,
        VC_06: -9998.0,
        QE_06: 1939.974,
        VE_06: -9998.0,
        QA_07: 988.894,
        VA_07: -9998.0,
        QC_07: 988.894,
        VC_07: -9998.0,
        QE_07: 1156.766,
        VE_07: -9998.0,
        QA_08: 692.628,
        VA_08: -9998.0,
        QC_08: 692.628,
        VC_08: -9998.0,
        QE_08: 1015.776,
        VE_08: -9998.0,
        QA_09: 662.974,
        VA_09: -9998.0,
        QC_09: 1211.848,
        VC_09: -9998.0,
        QE_09: 1262.546,
        VE_09: -9998.0,
        QA_10: 805.935,
        VA_10: -9998.0,
        QC_10: 805.935,
        VC_10: -9998.0,
        QE_10: 771.766,
        VE_10: -9998.0,
        QA_11: 2215.967,
        VA_11: -9998.0,
        QC_11: 2215.967,
        VC_11: -9998.0,
        QE_11: 2162.619,
        VE_11: -9998.0,
        QA_12: 3282.736,
        VA_12: -9998.0,
        QC_12: 3282.736,
        VC_12: -9998.0,
        QE_12: 3279.257,
        VE_12: -9998.0,
        LakeFract: 0.00516452855,
        SurfArea: 675599.176782,
        RAreaHLoad: 0.09980444477,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.964522518100182, 35.502561011559578, 0.0],
          [-80.963236184768789, 35.500148278230142, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291155.0,
        COMID: 9718345,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 1.232,
        REACHCODE: "03050104002448",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012143856885139,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058200.0,
        ToNode: 250058208.0,
        Hydroseq: 250004070.0,
        LevelPathI: 250003258.0,
        Pathlength: 352.7,
        TerminalPa: 250002604.0,
        ArbolateSu: 8747.795,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004081.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004060.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9936,
        TotDASqKM: 11799.2529,
        DivDASqKM: 11799.2529,
        Tidal: 0,
        TOTMA: 0.62209327646000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6721.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.23199999332428,
        QA_MA: 5630.825,
        VA_MA: -9998.0,
        QC_MA: 5630.825,
        VC_MA: -9998.0,
        QE_MA: 6328.691,
        VE_MA: -9998.0,
        QA_01: 10610.453,
        VA_01: -9998.0,
        QC_01: 10610.453,
        VC_01: -9998.0,
        QE_01: 9217.129,
        VE_01: -9998.0,
        QA_02: 11230.101,
        VA_02: -9998.0,
        QC_02: 11230.101,
        VC_02: -9998.0,
        QE_02: 9389.34,
        VE_02: -9998.0,
        QA_03: 12291.426,
        VA_03: -9998.0,
        QC_03: 12291.426,
        VC_03: -9998.0,
        QE_03: 10040.973,
        VE_03: -9998.0,
        QA_04: 8219.334,
        VA_04: -9998.0,
        QC_04: 8219.334,
        VC_04: -9998.0,
        QE_04: 7967.865,
        VE_04: -9998.0,
        QA_05: 5467.446,
        VA_05: -9998.0,
        QC_05: 5467.446,
        VC_05: -9998.0,
        QE_05: 6422.797,
        VE_05: -9998.0,
        QA_06: 3261.824,
        VA_06: -9998.0,
        QC_06: 3261.824,
        VC_06: -9998.0,
        QE_06: 5486.389,
        VE_06: -9998.0,
        QA_07: 2065.39,
        VA_07: -9998.0,
        QC_07: 2065.39,
        VC_07: -9998.0,
        QE_07: 4141.954,
        VE_07: -9998.0,
        QA_08: 1488.426,
        VA_08: -9998.0,
        QC_08: 1488.426,
        VC_08: -9998.0,
        QE_08: 3784.485,
        VE_08: -9998.0,
        QA_09: 1327.669,
        VA_09: -9998.0,
        QC_09: 1327.669,
        VC_09: -9998.0,
        QE_09: 3465.279,
        VE_09: -9998.0,
        QA_10: 1379.881,
        VA_10: -9998.0,
        QC_10: 1379.881,
        VC_10: -9998.0,
        QE_10: 4700.728,
        VE_10: -9998.0,
        QA_11: 3320.914,
        VA_11: -9998.0,
        QC_11: 3320.914,
        VC_11: -9998.0,
        QE_11: 4767.657,
        VE_11: -9998.0,
        QA_12: 6945.817,
        VA_12: -9998.0,
        QC_12: 6945.817,
        VC_12: -9998.0,
        QE_12: 6654.123,
        VE_12: -9998.0,
        LakeFract: 0.03980519859,
        SurfArea: 1805102.6010700001,
        RAreaHLoad: 0.11656375498,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.862162984925703, 34.441123213207163, 0.0],
          [-80.853612318272326, 34.432937613219792, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283980.0,
        COMID: 9754774,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.453,
        REACHCODE: "03050101000394",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.005015160979812,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061707.0,
        ToNode: 250061705.0,
        Hydroseq: 250019847.0,
        LevelPathI: 250003258.0,
        Pathlength: 689.984,
        TerminalPa: 250002604.0,
        ArbolateSu: 309.659,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250020192.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019505.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.054,
        TotDASqKM: 331.2099,
        DivDASqKM: 331.2099,
        Tidal: 0,
        TOTMA: 0.013838353337699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37613.0,
        MAXELEVSMO: 37738.0,
        MINELEVSMO: 37702.0,
        SLOPE: 0.0007947,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.45300000905990601,
        QA_MA: 218.833,
        VA_MA: 1.23192,
        QC_MA: 215.015,
        VC_MA: 1.23061,
        QE_MA: 253.659,
        VE_MA: 1.24304,
        QA_01: 345.887,
        VA_01: 1.48607,
        QC_01: 269.116,
        VC_01: 1.34733,
        QE_01: 295.949,
        VE_01: 1.32279,
        QA_02: 364.146,
        VA_02: 1.51868,
        QC_02: 302.092,
        VC_02: 1.4131,
        QE_02: 349.256,
        VE_02: 1.41603,
        QA_03: 410.714,
        VA_03: 1.59854,
        QC_03: 342.865,
        VC_03: 1.48991,
        QE_03: 366.115,
        VE_03: 1.44411,
        QA_04: 309.387,
        VA_04: 1.41835,
        QC_04: 284.203,
        VC_04: 1.37786,
        QE_04: 335.286,
        VE_04: 1.39228,
        QA_05: 247.326,
        VA_05: 1.29394,
        QC_05: 228.547,
        VC_05: 1.26099,
        QE_05: 263.23,
        VE_05: 1.2616,
        QA_06: 143.871,
        VA_06: 1.04755,
        QC_06: 179.325,
        VC_06: 1.14587,
        QE_06: 223.38,
        VE_06: 1.18203,
        QA_07: 83.292,
        VA_07: 0.86135,
        QC_07: 124.99,
        VC_07: 0.99979,
        QE_07: 171.581,
        VE_07: 1.06783,
        QA_08: 57.173,
        VA_08: 0.7612,
        QC_08: 128.607,
        VC_08: 1.01035,
        QE_08: 205.439,
        VE_08: 1.14404,
        QA_09: 66.796,
        VA_09: 0.8002,
        QC_09: 159.745,
        VC_09: 1.096,
        QE_09: 172.625,
        VE_09: 1.07028,
        QA_10: 100.379,
        VA_10: 0.91894,
        QC_10: 218.445,
        VC_10: 1.23839,
        QE_10: 191.509,
        VE_10: 1.11346,
        QA_11: 220.354,
        VA_11: 1.23533,
        QC_11: 234.079,
        VC_11: 1.27316,
        QE_11: 215.439,
        VE_11: 1.1654,
        QA_12: 278.518,
        VA_12: 1.3581,
        QC_12: 221.619,
        VC_12: 1.24554,
        QE_12: 232.129,
        VE_12: 1.20004,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.05564811640653, 35.686634277940584, 0.0],
          [-82.050707783080895, 35.686746211273771, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287023.0,
        COMID: 9734954,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.687,
        REACHCODE: "03050103000297",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0063050298369950004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059568.0,
        ToNode: 250059579.0,
        Hydroseq: 250018886.0,
        LevelPathI: 250015946.0,
        Pathlength: 396.462,
        TerminalPa: 250002604.0,
        ArbolateSu: 320.074,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250019188.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018601.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8919,
        TotDASqKM: 700.9839,
        DivDASqKM: 700.9839,
        Tidal: 0,
        TOTMA: 0.023724941418,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11760.0,
        MAXELEVSMO: 11880.0,
        MINELEVSMO: 11871.0,
        SLOPE: 0.000131,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68699997663497903,
        QA_MA: 289.781,
        VA_MA: 1.1014,
        QC_MA: 281.639,
        VC_MA: 1.09957,
        QE_MA: 281.639,
        VE_MA: 1.09957,
        QA_01: 624.264,
        VA_01: 1.50035,
        QC_01: 478.248,
        VC_01: 1.35645,
        QE_01: 478.248,
        VE_01: 1.35645,
        QA_02: 654.451,
        VA_02: 1.53061,
        QC_02: 534.319,
        VC_02: 1.42,
        QE_02: 534.319,
        VE_02: 1.42,
        QA_03: 703.594,
        VA_03: 1.57852,
        QC_03: 577.018,
        VC_03: 1.46632,
        QE_03: 577.018,
        VE_03: 1.46632,
        QA_04: 416.139,
        VA_04: 1.26942,
        QC_04: 379.7,
        VC_04: 1.23559,
        QE_04: 379.7,
        VE_04: 1.23559,
        QA_05: 230.884,
        VA_05: 1.01127,
        QC_05: 213.903,
        VC_05: 0.99204,
        QE_05: 213.903,
        VE_05: 0.99204,
        QA_06: 146.607,
        VA_06: 0.86065,
        QC_06: 182.689,
        VC_06: 0.93711,
        QE_06: 182.689,
        VE_06: 0.93711,
        QA_07: 101.462,
        VA_07: 0.76261,
        QC_07: 151.539,
        VC_07: 0.8777,
        QE_07: 151.539,
        VE_07: 0.8777,
        QA_08: 75.399,
        VA_08: 0.69636,
        QC_08: 170.549,
        VC_08: 0.91457,
        QE_08: 170.549,
        VE_08: 0.91457,
        QA_09: 63.862,
        VA_09: 0.66361,
        QC_09: 153.534,
        VC_09: 0.88166,
        QE_09: 153.534,
        VE_09: 0.88166,
        QA_10: 47.198,
        VA_10: 0.61092,
        QC_10: 119.55,
        VC_10: 0.81035,
        QE_10: 119.55,
        VE_10: 0.81035,
        QA_11: 71.199,
        VA_11: 0.68473,
        QC_11: 98.896,
        VC_11: 0.76226,
        QE_11: 98.896,
        VE_11: 0.76226,
        QA_12: 344.446,
        VA_12: 1.17761,
        QC_12: 268.877,
        VC_12: 1.08033,
        QE_12: 268.877,
        VE_12: 1.08033,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.938223318141013, 34.695445612812478, 0.0],
          [-80.940077118138049, 34.689649279488037, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286046.0,
        COMID: 9753984,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.781,
        REACHCODE: "03050101002721",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0070411130186420002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061466.0,
        ToNode: 250061472.0,
        Hydroseq: 250009678.0,
        LevelPathI: 250003258.0,
        Pathlength: 567.914,
        TerminalPa: 250002604.0,
        ArbolateSu: 3291.982,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009724.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009638.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5355,
        TotDASqKM: 3675.8142,
        DivDASqKM: 3675.8142,
        Tidal: 0,
        TOTMA: 0.97399549999000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25420.0,
        MAXELEVSMO: 25428.0,
        MINELEVSMO: 25422.0,
        SLOPE: 7.682e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.78100001811981201,
        QA_MA: 2206.669,
        VA_MA: -9998.0,
        QC_MA: 2206.669,
        VC_MA: -9998.0,
        QE_MA: 2398.724,
        VE_MA: -9998.0,
        QA_01: 3568.348,
        VA_01: -9998.0,
        QC_01: 3568.348,
        VC_01: -9998.0,
        QE_01: 3766.488,
        VE_01: -9998.0,
        QA_02: 3771.149,
        VA_02: -9998.0,
        QC_02: 3771.149,
        VC_02: -9998.0,
        QE_02: 3835.104,
        VE_02: -9998.0,
        QA_03: 4265.274,
        VA_03: -9998.0,
        QC_03: 4265.274,
        VC_03: -9998.0,
        QE_03: 4523.811,
        VE_03: -9998.0,
        QA_04: 3283.599,
        VA_04: -9998.0,
        QC_04: 3283.599,
        VC_04: -9998.0,
        QE_04: 3472.711,
        VE_04: -9998.0,
        QA_05: 2498.493,
        VA_05: -9998.0,
        QC_05: 2498.493,
        VC_05: -9998.0,
        QE_05: 2719.701,
        VE_05: -9998.0,
        QA_06: 1456.711,
        VA_06: -9998.0,
        QC_06: 1456.711,
        VC_06: -9998.0,
        QE_06: 1718.495,
        VE_06: -9998.0,
        QA_07: 860.662,
        VA_07: -9998.0,
        QC_07: 860.662,
        VC_07: -9998.0,
        QE_07: 1011.004,
        VE_07: -9998.0,
        QA_08: 604.268,
        VA_08: -9998.0,
        QC_08: 604.268,
        VC_08: -9998.0,
        QE_08: 905.723,
        VE_08: -9998.0,
        QA_09: 586.042,
        VA_09: -9998.0,
        QC_09: 1086.811,
        VC_09: -9998.0,
        QE_09: 1123.515,
        VE_09: -9998.0,
        QA_10: 749.858,
        VA_10: -9998.0,
        QC_10: 749.858,
        VC_10: -9998.0,
        QE_10: 690.288,
        VE_10: -9998.0,
        QA_11: 2037.56,
        VA_11: -9998.0,
        QC_11: 2037.56,
        VC_11: -9998.0,
        QE_11: 1981.779,
        VE_11: -9998.0,
        QA_12: 2812.453,
        VA_12: -9998.0,
        QC_12: 2812.453,
        VC_12: -9998.0,
        QE_12: 2821.819,
        VE_12: -9998.0,
        LakeFract: 0.01187730904,
        SurfArea: 1553733.3431500001,
        RAreaHLoad: 0.26422803865,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.128998184511545, 35.800156011097677, 0.0],
          [-81.128826117845108, 35.793292211108337, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291156.0,
        COMID: 9718347,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.618,
        REACHCODE: "03050104002449",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015410390232304999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058201.0,
        ToNode: 250058217.0,
        Hydroseq: 250021720.0,
        LevelPathI: 250021319.0,
        Pathlength: 346.844,
        TerminalPa: 250002604.0,
        ArbolateSu: 126.985,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250021319.0,
        UpHydroseq: 250022158.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021319.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8575,
        TotDASqKM: 131.2317,
        DivDASqKM: 131.2317,
        Tidal: 0,
        TOTMA: 0.8170023768,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6748.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6180000305175779,
        QA_MA: 48.743,
        VA_MA: -9998.0,
        QC_MA: 50.766,
        VC_MA: -9998.0,
        QE_MA: 50.766,
        VE_MA: -9998.0,
        QA_01: 108.351,
        VA_01: -9998.0,
        QC_01: 86.906,
        VC_01: -9998.0,
        QE_01: 86.906,
        VE_01: -9998.0,
        QA_02: 113.027,
        VA_02: -9998.0,
        QC_02: 96.804,
        VC_02: -9998.0,
        QE_02: 96.804,
        VE_02: -9998.0,
        QA_03: 121.33,
        VA_03: -9998.0,
        QC_03: 105.447,
        VC_03: -9998.0,
        QE_03: 105.447,
        VE_03: -9998.0,
        QA_04: 69.331,
        VA_04: -9998.0,
        QC_04: 65.89,
        VC_04: -9998.0,
        QE_04: 65.89,
        VE_04: -9998.0,
        QA_05: 38.583,
        VA_05: -9998.0,
        QC_05: 38.219,
        VC_05: -9998.0,
        QE_05: 38.219,
        VE_05: -9998.0,
        QA_06: 24.974,
        VA_06: -9998.0,
        QC_06: 31.886,
        VC_06: -9998.0,
        QE_06: 31.886,
        VE_06: -9998.0,
        QA_07: 18.844,
        VA_07: -9998.0,
        QC_07: 29.301,
        VC_07: -9998.0,
        QE_07: 29.301,
        VE_07: -9998.0,
        QA_08: 14.115,
        VA_08: -9998.0,
        QC_08: 30.875,
        VC_08: -9998.0,
        QE_08: 30.875,
        VE_08: -9998.0,
        QA_09: 10.649,
        VA_09: -9998.0,
        QC_09: 31.578,
        VC_09: -9998.0,
        QE_09: 31.578,
        VE_09: -9998.0,
        QA_10: 8.822,
        VA_10: -9998.0,
        QC_10: 31.313,
        VC_10: -9998.0,
        QE_10: 31.313,
        VE_10: -9998.0,
        QA_11: 7.499,
        VA_11: -9998.0,
        QC_11: 17.771,
        VC_11: -9998.0,
        QE_11: 17.771,
        VE_11: -9998.0,
        QA_12: 49.595,
        VA_12: -9998.0,
        QC_12: 46.107,
        VC_12: -9998.0,
        QE_12: 46.107,
        VE_12: -9998.0,
        LakeFract: 0.00041988872,
        SurfArea: 19041.287255899999,
        RAreaHLoad: 0.1530845429,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.775726318393254, 34.435406079882739, 0.0],
          [-80.781312185051092, 34.431182213222712, 0.0],
          [-80.783210585048209, 34.423279013234946, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283981.0,
        COMID: 9754772,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.058,
        REACHCODE: "03050101000395",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00062430569329599995,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061706.0,
        ToNode: 250061707.0,
        Hydroseq: 250020192.0,
        LevelPathI: 250003258.0,
        Pathlength: 690.437,
        TerminalPa: 250002604.0,
        ArbolateSu: 309.206,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250020549.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250019846.0,
        DnDrainCou: 2,
        DnHydroseq: 250019847.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0126,
        TotDASqKM: 331.1559,
        DivDASqKM: 331.1559,
        Tidal: 0,
        TOTMA: 0.0028436245850500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37738.0,
        MAXELEVSMO: 37738.0,
        MINELEVSMO: 37738.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.057999998331070002,
        QA_MA: 218.799,
        VA_MA: 0.76897,
        QC_MA: 214.983,
        VC_MA: 0.76831,
        QE_MA: 253.625,
        VE_MA: 0.77451,
        QA_01: 345.832,
        VA_01: 0.89571,
        QC_01: 269.074,
        VC_01: 0.82652,
        QE_01: 295.907,
        VE_01: 0.81428,
        QA_02: 364.088,
        VA_02: 0.91198,
        QC_02: 302.045,
        VC_02: 0.85932,
        QE_02: 349.208,
        VE_02: 0.86079,
        QA_03: 410.647,
        VA_03: 0.9518,
        QC_03: 342.811,
        VC_03: 0.89763,
        QE_03: 366.059,
        VE_03: 0.87478,
        QA_04: 309.337,
        VA_04: 0.86194,
        QC_04: 284.159,
        VC_04: 0.84175,
        QE_04: 335.24,
        VE_04: 0.84894,
        QA_05: 247.287,
        VA_05: 0.7999,
        QC_05: 228.512,
        VC_05: 0.78346,
        QE_05: 263.193,
        VE_05: 0.78377,
        QA_06: 143.849,
        VA_06: 0.67702,
        QC_06: 179.299,
        VC_06: 0.72605,
        QE_06: 223.351,
        VE_06: 0.74409,
        QA_07: 83.28,
        VA_07: 0.58416,
        QC_07: 124.972,
        VC_07: 0.6532,
        QE_07: 171.562,
        VE_07: 0.68714,
        QA_08: 57.165,
        VA_08: 0.53422,
        QC_08: 128.589,
        VC_08: 0.65847,
        QE_08: 205.419,
        VE_08: 0.72515,
        QA_09: 66.789,
        VA_09: 0.55367,
        QC_09: 159.732,
        VC_09: 0.7012,
        QE_09: 172.61,
        VE_09: 0.68836,
        QA_10: 100.373,
        VA_10: 0.6129,
        QC_10: 218.434,
        VC_10: 0.77222,
        QE_10: 191.497,
        VE_10: 0.7099,
        QA_11: 220.321,
        VA_11: 0.77067,
        QC_11: 234.052,
        VC_11: 0.78954,
        QE_11: 215.411,
        VE_11: 0.73579,
        QA_12: 278.472,
        VA_12: 0.83189,
        QC_12: 221.585,
        VC_12: 0.77576,
        QE_12: 232.095,
        VE_12: 0.75306,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.056237316405543, 35.686840677940211, 0.0],
          [-82.05564811640653, 35.686634277940584, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287024.0,
        COMID: 9734924,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.471,
        REACHCODE: "03050103000298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014836332950110999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059554.0,
        ToNode: 250059568.0,
        Hydroseq: 250019188.0,
        LevelPathI: 250015946.0,
        Pathlength: 397.149,
        TerminalPa: 250002604.0,
        ArbolateSu: 301.639,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250019513.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018886.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7479,
        TotDASqKM: 680.7294,
        DivDASqKM: 680.7294,
        Tidal: 0,
        TOTMA: 0.0413388036305,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11781.0,
        MAXELEVSMO: 11994.0,
        MINELEVSMO: 11880.0,
        SLOPE: 0.00077498,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.470999956130981,
        QA_MA: 282.014,
        VA_MA: 1.35354,
        QC_MA: 274.38,
        VC_MA: 1.35122,
        QE_MA: 274.38,
        VE_MA: 1.35122,
        QA_01: 607.237,
        VA_01: 1.87893,
        QC_01: 465.541,
        VC_01: 1.6892,
        QE_01: 465.541,
        VE_01: 1.6892,
        QA_02: 636.422,
        VA_02: 1.91857,
        QC_02: 519.995,
        VC_02: 1.77274,
        QE_02: 519.995,
        VE_02: 1.77274,
        QA_03: 684.162,
        VA_03: 1.98162,
        QC_03: 561.6,
        VC_03: 1.83383,
        QE_03: 561.6,
        VE_03: 1.83383,
        QA_04: 405.085,
        VA_04: 1.57515,
        QC_04: 369.839,
        VC_04: 1.53037,
        QE_04: 369.839,
        VE_04: 1.53037,
        QA_05: 224.716,
        VA_05: 1.2348,
        QC_05: 208.4,
        VC_05: 1.20951,
        QE_05: 208.4,
        VE_05: 1.20951,
        QA_06: 142.633,
        VA_06: 1.03613,
        QC_06: 177.804,
        VC_06: 1.13665,
        QE_06: 177.804,
        VE_06: 1.13665,
        QA_07: 98.738,
        VA_07: 0.90702,
        QC_07: 147.568,
        VC_07: 1.0586,
        QE_07: 147.568,
        VE_07: 1.0586,
        QA_08: 73.22,
        VA_08: 0.81913,
        QC_08: 165.524,
        VC_08: 1.10577,
        QE_08: 165.524,
        VE_08: 1.10577,
        QA_09: 62.228,
        VA_09: 0.77687,
        QC_09: 150.061,
        VC_09: 1.06531,
        QE_09: 150.061,
        VE_09: 1.06531,
        QA_10: 45.836,
        VA_10: 0.70667,
        QC_10: 116.787,
        VC_10: 0.97096,
        QE_10: 116.787,
        VE_10: 0.97096,
        QA_11: 70.028,
        VA_11: 0.80719,
        QC_11: 97.654,
        VC_11: 0.91091,
        QE_11: 97.654,
        VE_11: 0.91091,
        QA_12: 335.968,
        VA_12: 1.45536,
        QC_12: 262.85,
        VC_12: 1.32772,
        QE_12: 262.85,
        VE_12: 1.32772,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.947463784793285, 34.701647812802833, 0.0],
          [-80.947268318126987, 34.70002147947207, 0.0],
          [-80.938781118140071, 34.6997286794724, 0.0],
          [-80.938223318141013, 34.695445612812478, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283982.0,
        COMID: 9754776,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.672,
        REACHCODE: "03050101000396",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0073743332162300003,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061708.0,
        ToNode: 250061706.0,
        Hydroseq: 250020549.0,
        LevelPathI: 250003258.0,
        Pathlength: 690.495,
        TerminalPa: 250002604.0,
        ArbolateSu: 305.495,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250020917.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020192.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4041,
        TotDASqKM: 328.635,
        DivDASqKM: 328.635,
        Tidal: 0,
        TOTMA: 0.016932630905199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37667.0,
        MAXELEVSMO: 38001.0,
        MINELEVSMO: 37738.0,
        SLOPE: 0.00391369,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.67199999094009399,
        QA_MA: 217.293,
        VA_MA: 1.49631,
        QC_MA: 213.56,
        VC_MA: 1.49465,
        QE_MA: 242.71,
        VE_MA: 1.50701,
        QA_01: 343.311,
        VA_01: 1.82288,
        QC_01: 267.164,
        VC_01: 1.64444,
        QE_01: 290.037,
        VE_01: 1.62592,
        QA_02: 361.399,
        VA_02: 1.86474,
        QC_02: 299.875,
        VC_02: 1.72895,
        QE_02: 346.281,
        VE_02: 1.75594,
        QA_03: 407.586,
        VA_03: 1.96738,
        QC_03: 340.34,
        VC_03: 1.82771,
        QE_03: 353.746,
        VE_03: 1.77243,
        QA_04: 307.058,
        VA_04: 1.73574,
        QC_04: 282.113,
        VC_04: 1.68363,
        QE_04: 323.621,
        VE_04: 1.70485,
        QA_05: 245.542,
        VA_05: 1.57597,
        QC_05: 226.96,
        VC_05: 1.53361,
        QE_05: 248.377,
        VE_05: 1.52179,
        QA_06: 142.892,
        VA_06: 1.25927,
        QC_06: 178.123,
        VC_06: 1.38569,
        QE_06: 208.579,
        VE_06: 1.41433,
        QA_07: 82.72,
        VA_07: 1.01972,
        QC_07: 124.152,
        VC_07: 1.19778,
        QE_07: 163.33,
        VE_07: 1.27965,
        QA_08: 56.792,
        VA_08: 0.89095,
        QC_08: 127.733,
        VC_08: 1.21131,
        QE_08: 196.744,
        VE_08: 1.38055,
        QA_09: 66.518,
        VA_09: 0.94196,
        QC_09: 159.159,
        VC_09: 1.32318,
        QE_09: 162.886,
        VE_09: 1.27825,
        QA_10: 100.164,
        VA_10: 1.09579,
        QC_10: 218.071,
        VC_10: 1.50789,
        QE_10: 185.805,
        VE_10: 1.34847,
        QA_11: 218.872,
        VA_11: 1.50089,
        QC_11: 232.878,
        VC_11: 1.55047,
        QE_11: 207.012,
        VE_11: 1.40991,
        QA_12: 276.465,
        VA_12: 1.65837,
        QC_12: 220.132,
        VC_12: 1.51389,
        QE_12: 224.45,
        VE_12: 1.45825,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.063396116394529, 35.685172477942899, 0.0],
          [-82.056237316405543, 35.686840677940211, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287025.0,
        COMID: 9734878,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.98,
        REACHCODE: "03050103000299",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0097474987826980004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059542.0,
        ToNode: 250059554.0,
        Hydroseq: 250019513.0,
        LevelPathI: 250015946.0,
        Pathlength: 398.62,
        TerminalPa: 250002604.0,
        ArbolateSu: 298.445,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250019855.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019188.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1772,
        TotDASqKM: 678.8871,
        DivDASqKM: 678.8871,
        Tidal: 0,
        TOTMA: 0.032320828409400003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11869.0,
        MAXELEVSMO: 12014.0,
        MINELEVSMO: 11994.0,
        SLOPE: 0.00020408,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.980000019073486,
        QA_MA: 281.313,
        VA_MA: 1.15324,
        QC_MA: 273.724,
        VC_MA: 1.15137,
        QE_MA: 273.724,
        VE_MA: 1.15137,
        QA_01: 605.692,
        VA_01: 1.57789,
        QC_01: 464.387,
        VC_01: 1.42452,
        QE_01: 464.387,
        VE_01: 1.42452,
        QA_02: 634.788,
        VA_02: 1.60992,
        QC_02: 518.696,
        VC_02: 1.49204,
        QE_02: 518.696,
        VE_02: 1.49204,
        QA_03: 682.403,
        VA_03: 1.66088,
        QC_03: 560.204,
        VC_03: 1.54142,
        QE_03: 560.204,
        VE_03: 1.54142,
        QA_04: 404.083,
        VA_04: 1.33238,
        QC_04: 368.945,
        VC_04: 1.29617,
        QE_04: 368.945,
        VE_04: 1.29617,
        QA_05: 224.16,
        VA_05: 1.05726,
        QC_05: 207.903,
        VC_05: 1.03682,
        QE_05: 207.903,
        VE_05: 1.03682,
        QA_06: 142.275,
        VA_06: 0.89665,
        QC_06: 177.363,
        VC_06: 0.97789,
        QE_06: 177.363,
        VE_06: 0.97789,
        QA_07: 98.491,
        VA_07: 0.79228,
        QC_07: 147.206,
        VC_07: 0.9148,
        QE_07: 147.206,
        VE_07: 0.9148,
        QA_08: 73.022,
        VA_08: 0.7212,
        QC_08: 165.067,
        VC_08: 0.95282,
        QE_08: 165.067,
        VE_08: 0.95282,
        QA_09: 62.079,
        VA_09: 0.6871,
        QC_09: 149.744,
        VC_09: 0.92033,
        QE_09: 149.744,
        VE_09: 0.92033,
        QA_10: 45.712,
        VA_10: 0.6303,
        QC_10: 116.535,
        VC_10: 0.84405,
        QE_10: 116.535,
        VE_10: 0.84405,
        QA_11: 69.929,
        VA_11: 0.71182,
        QC_11: 97.549,
        VC_11: 0.79578,
        QE_11: 97.549,
        VE_11: 0.79578,
        QA_12: 335.214,
        VA_12: 1.23566,
        QC_12: 262.313,
        VC_12: 1.13252,
        QE_12: 262.313,
        VE_12: 1.13252,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.951212918120802, 34.706982479461146, 0.0],
          [-80.94633038479509, 34.706115079462563, 0.0],
          [-80.947463784793285, 34.701647812802833, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287026.0,
        COMID: 9734838,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.709,
        REACHCODE: "03050103000300",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016613839339710001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059531.0,
        ToNode: 250059542.0,
        Hydroseq: 250019855.0,
        LevelPathI: 250015946.0,
        Pathlength: 399.6,
        TerminalPa: 250002604.0,
        ArbolateSu: 296.207,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250020200.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019513.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9935,
        TotDASqKM: 676.8828,
        DivDASqKM: 676.8828,
        Tidal: 0,
        TOTMA: 0.0573847964908,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 11925.0,
        MAXELEVSMO: 12044.0,
        MINELEVSMO: 12014.0,
        SLOPE: 0.00017554,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7089999914169309,
        QA_MA: 280.543,
        VA_MA: 1.1327,
        QC_MA: 273.004,
        VC_MA: 1.13088,
        QE_MA: 273.004,
        VE_MA: 1.13088,
        QA_01: 603.995,
        VA_01: 1.54698,
        QC_01: 463.12,
        VC_01: 1.39734,
        QE_01: 463.12,
        VE_01: 1.39734,
        QA_02: 633.002,
        VA_02: 1.57822,
        QC_02: 517.277,
        VC_02: 1.4632,
        QE_02: 517.277,
        VE_02: 1.4632,
        QA_03: 680.481,
        VA_03: 1.62794,
        QC_03: 558.678,
        VC_03: 1.51139,
        QE_03: 558.678,
        VE_03: 1.51139,
        QA_04: 402.978,
        VA_04: 1.30748,
        QC_04: 367.96,
        VC_04: 1.27214,
        QE_04: 367.96,
        VE_04: 1.27214,
        QA_05: 223.541,
        VA_05: 1.03904,
        QC_05: 207.351,
        VC_05: 1.01911,
        QE_05: 207.351,
        VE_05: 1.01911,
        QA_06: 141.871,
        VA_06: 0.88231,
        QC_06: 176.867,
        VC_06: 0.96156,
        QE_06: 176.867,
        VE_06: 0.96156,
        QA_07: 98.221,
        VA_07: 0.78052,
        QC_07: 146.813,
        VC_07: 0.90005,
        QE_07: 146.813,
        VE_07: 0.90005,
        QA_08: 72.807,
        VA_08: 0.71112,
        QC_08: 164.57,
        VC_08: 0.93703,
        QE_08: 164.57,
        VE_08: 0.93703,
        QA_09: 61.918,
        VA_09: 0.67792,
        QC_09: 149.4,
        VC_09: 0.90557,
        QE_09: 149.4,
        VE_09: 0.90557,
        QA_10: 45.578,
        VA_10: 0.62244,
        QC_10: 116.26,
        VC_10: 0.83111,
        QE_10: 116.26,
        VE_10: 0.83111,
        QA_11: 69.817,
        VA_11: 0.70225,
        QC_11: 97.429,
        VC_11: 0.78429,
        QE_11: 97.429,
        VE_11: 0.78429,
        QA_12: 334.373,
        VA_12: 1.21322,
        QC_12: 261.714,
        VC_12: 1.11264,
        QE_12: 261.714,
        VE_12: 1.11264,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.95829571810981, 34.716987612779064, 0.0],
          [-80.955050518114888, 34.717493679444772, 0.0],
          [-80.950748518121543, 34.715091479448517, 0.0],
          [-80.951212918120802, 34.706982479461146, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291151.0,
        COMID: 9718335,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.354,
        REACHCODE: "03050104002444",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.003726143963014,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058195.0,
        ToNode: 250058200.0,
        Hydroseq: 250004081.0,
        LevelPathI: 250003258.0,
        Pathlength: 353.932,
        TerminalPa: 250002604.0,
        ArbolateSu: 8744.18,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004091.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004070.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1296,
        TotDASqKM: 11796.1821,
        DivDASqKM: 11796.1821,
        Tidal: 0,
        TOTMA: 0.17875082786,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6757.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.35400000214576699,
        QA_MA: 5629.739,
        VA_MA: -9998.0,
        QC_MA: 5629.739,
        VC_MA: -9998.0,
        QE_MA: 6327.603,
        VE_MA: -9998.0,
        QA_01: 10608.059,
        VA_01: -9998.0,
        QC_01: 10608.059,
        VC_01: -9998.0,
        QE_01: 9214.741,
        VE_01: -9998.0,
        QA_02: 11227.525,
        VA_02: -9998.0,
        QC_02: 11227.525,
        VC_02: -9998.0,
        QE_02: 9386.77,
        VE_02: -9998.0,
        QA_03: 12288.618,
        VA_03: -9998.0,
        QC_03: 12288.618,
        VC_03: -9998.0,
        QE_03: 10038.174,
        VE_03: -9998.0,
        QA_04: 8217.807,
        VA_04: -9998.0,
        QC_04: 8217.807,
        VC_04: -9998.0,
        QE_04: 7966.34,
        VE_04: -9998.0,
        QA_05: 5466.579,
        VA_05: -9998.0,
        QC_05: 5466.579,
        VC_05: -9998.0,
        QE_05: 6421.93,
        VE_05: -9998.0,
        QA_06: 3261.246,
        VA_06: -9998.0,
        QC_06: 3261.246,
        VC_06: -9998.0,
        QE_06: 5485.805,
        VE_06: -9998.0,
        QA_07: 2064.977,
        VA_07: -9998.0,
        QC_07: 2064.977,
        VC_07: -9998.0,
        QE_07: 4141.536,
        VE_07: -9998.0,
        QA_08: 1488.095,
        VA_08: -9998.0,
        QC_08: 1488.095,
        VC_08: -9998.0,
        QE_08: 3784.147,
        VE_08: -9998.0,
        QA_09: 1327.421,
        VA_09: -9998.0,
        QC_09: 1327.421,
        VC_09: -9998.0,
        QE_09: 3465.025,
        VE_09: -9998.0,
        QA_10: 1379.674,
        VA_10: -9998.0,
        QC_10: 1379.674,
        VC_10: -9998.0,
        QE_10: 4700.512,
        VE_10: -9998.0,
        QA_11: 3320.749,
        VA_11: -9998.0,
        QC_11: 3320.749,
        VC_11: -9998.0,
        QE_11: 4767.487,
        VE_11: -9998.0,
        QA_12: 6944.884,
        VA_12: -9998.0,
        QC_12: 6944.884,
        VC_12: -9998.0,
        QE_12: 6653.194,
        VE_12: -9998.0,
        LakeFract: 0.01143556672,
        SurfArea: 518584.807065,
        RAreaHLoad: 0.03349315688,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865553984920496, 34.442518413204994, 0.0],
          [-80.862162984925703, 34.441123213207163, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286042.0,
        COMID: 9751166,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 5.553,
        REACHCODE: "03050101002717",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.053121526523615001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060745.0,
        ToNode: 250060748.0,
        Hydroseq: 250008629.0,
        LevelPathI: 250003258.0,
        Pathlength: 519.712,
        TerminalPa: 250002604.0,
        ArbolateSu: 3867.788,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008667.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008594.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 18.3726,
        TotDASqKM: 4486.4037,
        DivDASqKM: 4486.4037,
        Tidal: 0,
        TOTMA: 6.9252202466000004,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23030.0,
        MAXELEVSMO: 23052.0,
        MINELEVSMO: 23052.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.5529999732971191,
        QA_MA: 2564.477,
        VA_MA: -9998.0,
        QC_MA: 2564.477,
        VC_MA: -9998.0,
        QE_MA: 2757.706,
        VE_MA: -9998.0,
        QA_01: 4266.119,
        VA_01: -9998.0,
        QC_01: 4266.119,
        VC_01: -9998.0,
        QE_01: 4446.351,
        VE_01: -9998.0,
        QA_02: 4506.383,
        VA_02: -9998.0,
        QC_02: 4506.383,
        VC_02: -9998.0,
        QE_02: 4550.439,
        VE_02: -9998.0,
        QA_03: 5057.994,
        VA_03: -9998.0,
        QC_03: 5057.994,
        VC_03: -9998.0,
        QE_03: 5287.846,
        VE_03: -9998.0,
        QA_04: 3824.739,
        VA_04: -9998.0,
        QC_04: 3824.739,
        VC_04: -9998.0,
        QE_04: 4005.182,
        VE_04: -9998.0,
        QA_05: 2839.043,
        VA_05: -9998.0,
        QC_05: 2839.043,
        VC_05: -9998.0,
        QE_05: 3061.267,
        VE_05: -9998.0,
        QA_06: 1658.367,
        VA_06: -9998.0,
        QC_06: 1658.367,
        VC_06: -9998.0,
        QE_06: 1937.675,
        VE_06: -9998.0,
        QA_07: 987.408,
        VA_07: -9998.0,
        QC_07: 987.408,
        VC_07: -9998.0,
        QE_07: 1155.238,
        VE_07: -9998.0,
        QA_08: 691.588,
        VA_08: -9998.0,
        QC_08: 691.588,
        VC_08: -9998.0,
        QE_08: 1014.685,
        VE_08: -9998.0,
        QA_09: 662.082,
        VA_09: -9998.0,
        QC_09: 1210.409,
        VC_09: -9998.0,
        QE_09: 1261.067,
        VE_09: -9998.0,
        QA_10: 805.192,
        VA_10: -9998.0,
        QC_10: 805.192,
        VC_10: -9998.0,
        QE_10: 770.965,
        VE_10: -9998.0,
        QA_11: 2214.628,
        VA_11: -9998.0,
        QC_11: 2214.628,
        VC_11: -9998.0,
        QE_11: 2161.265,
        VE_11: -9998.0,
        QA_12: 3277.112,
        VA_12: -9998.0,
        QC_12: 3277.112,
        VC_12: -9998.0,
        QE_12: 3273.656,
        VE_12: -9998.0,
        LakeFract: 0.09705915254,
        SurfArea: 12696818.871300001,
        RAreaHLoad: 1.87869180405,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.958843184775674, 35.543898678162179, 0.0],
          [-80.951062784787666, 35.538830611503386, 0.0],
          [-80.951510184787026, 35.536581078173469, 0.0],
          [-80.957004784778462, 35.531043478182141, 0.0],
          [-80.95886678477558, 35.524226011526025, 0.0],
          [-80.959445318107953, 35.521314878197131, 0.0],
          [-80.956662184779077, 35.516615811537804, 0.0],
          [-80.957267118111474, 35.513572278209153, 0.0],
          [-80.963537784768334, 35.509219611549327, 0.0],
          [-80.964522518100182, 35.502561011559578, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283987.0,
        COMID: 9754822,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.601,
        REACHCODE: "03050101000401",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0064085728261730002,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061721.0,
        ToNode: 250061720.0,
        Hydroseq: 250022605.0,
        LevelPathI: 250003258.0,
        Pathlength: 694.986,
        TerminalPa: 250002604.0,
        ArbolateSu: 272.064,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250023104.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022138.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2187,
        TotDASqKM: 293.6529,
        DivDASqKM: 293.6529,
        Tidal: 0,
        TOTMA: 0.017526886937699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38516.0,
        MAXELEVSMO: 38689.0,
        MINELEVSMO: 38599.0,
        SLOPE: 0.0014975,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60100001096725497,
        QA_MA: 194.156,
        VA_MA: 1.30201,
        QC_MA: 191.656,
        VC_MA: 1.30097,
        QE_MA: 194.347,
        VE_MA: 1.30209,
        QA_01: 306.412,
        VA_01: 1.57441,
        QC_01: 239.16,
        VC_01: 1.42481,
        QE_01: 241.272,
        VE_01: 1.42302,
        QA_02: 322.502,
        VA_02: 1.60928,
        QC_02: 268.431,
        VC_02: 1.4954,
        QE_02: 272.715,
        VE_02: 1.49793,
        QA_03: 363.581,
        VA_03: 1.6948,
        QC_03: 304.742,
        VC_03: 1.57812,
        QE_03: 305.98,
        VE_03: 1.5729,
        QA_04: 273.775,
        VA_04: 1.50092,
        QC_04: 252.19,
        VC_04: 1.45671,
        QE_04: 256.022,
        VE_04: 1.4587,
        QA_05: 218.824,
        VA_05: 1.36716,
        QC_05: 203.137,
        VC_05: 1.33211,
        QE_05: 205.114,
        VE_05: 1.33095,
        QA_06: 128.096,
        VA_06: 1.10512,
        QC_06: 159.919,
        VC_06: 1.20999,
        QE_06: 162.73,
        VE_06: 1.21268,
        QA_07: 74.116,
        VA_07: 0.90423,
        QC_07: 111.531,
        VC_07: 1.05297,
        QE_07: 115.148,
        VE_07: 1.06096,
        QA_08: 51.046,
        VA_08: 0.7972,
        QC_08: 114.565,
        VC_08: 1.06366,
        QE_08: 120.936,
        VE_08: 1.08082,
        QA_09: 60.762,
        VA_09: 0.84458,
        QC_09: 146.936,
        VC_09: 1.17036,
        QE_09: 147.28,
        VE_09: 1.16603,
        QA_10: 91.577,
        VA_10: 0.97507,
        QC_10: 203.003,
        VC_10: 1.33175,
        QE_10: 200.024,
        VE_10: 1.3174,
        QA_11: 194.669,
        VA_11: 1.3034,
        QC_11: 212.968,
        VC_11: 1.35812,
        QE_11: 210.581,
        VE_11: 1.34533,
        QA_12: 246.006,
        VA_12: 1.43508,
        QC_12: 197.952,
        VC_12: 1.31815,
        QE_12: 198.351,
        VE_12: 1.31291,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.097307116341824, 35.668099677969337, 0.0],
          [-82.091581783017432, 35.670090877966175, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286053.0,
        COMID: 9754028,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.313,
        REACHCODE: "03050101002728",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013966255813639,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061488.0,
        ToNode: 250061492.0,
        Hydroseq: 250009546.0,
        LevelPathI: 250003258.0,
        Pathlength: 562.579,
        TerminalPa: 250002604.0,
        ArbolateSu: 3344.778,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009593.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009499.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7577,
        TotDASqKM: 3749.3892,
        DivDASqKM: 3749.3892,
        Tidal: 0,
        TOTMA: 1.6374597843900001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24586.0,
        MAXELEVSMO: 25422.0,
        MINELEVSMO: 25218.0,
        SLOPE: 0.00155369,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.312999963760376,
        QA_MA: 2240.662,
        VA_MA: -9998.0,
        QC_MA: 2240.662,
        VC_MA: -9998.0,
        QE_MA: 2432.718,
        VE_MA: -9998.0,
        QA_01: 3632.261,
        VA_01: -9998.0,
        QC_01: 3632.261,
        VC_01: -9998.0,
        QE_01: 3830.401,
        VE_01: -9998.0,
        QA_02: 3838.545,
        VA_02: -9998.0,
        QC_02: 3838.545,
        VC_02: -9998.0,
        QE_02: 3902.499,
        VE_02: -9998.0,
        QA_03: 4338.681,
        VA_03: -9998.0,
        QC_03: 4338.681,
        VC_03: -9998.0,
        QE_03: 4597.218,
        VE_03: -9998.0,
        QA_04: 3336.552,
        VA_04: -9998.0,
        QC_04: 3336.552,
        VC_04: -9998.0,
        QE_04: 3525.663,
        VE_04: -9998.0,
        QA_05: 2532.96,
        VA_05: -9998.0,
        QC_05: 2532.96,
        VC_05: -9998.0,
        QE_05: 2754.167,
        VE_05: -9998.0,
        QA_06: 1476.969,
        VA_06: -9998.0,
        QC_06: 1476.969,
        VC_06: -9998.0,
        QE_06: 1738.753,
        VE_06: -9998.0,
        QA_07: 873.039,
        VA_07: -9998.0,
        QC_07: 873.039,
        VC_07: -9998.0,
        QE_07: 1023.381,
        VE_07: -9998.0,
        QA_08: 612.551,
        VA_08: -9998.0,
        QC_08: 612.551,
        VC_08: -9998.0,
        QE_08: 914.005,
        VE_08: -9998.0,
        QA_09: 593.085,
        VA_09: -9998.0,
        QC_09: 1098.334,
        VC_09: -9998.0,
        QE_09: 1135.038,
        VE_09: -9998.0,
        QA_10: 754.805,
        VA_10: -9998.0,
        QC_10: 754.805,
        VC_10: -9998.0,
        QE_10: 695.234,
        VE_10: -9998.0,
        QA_11: 2056.498,
        VA_11: -9998.0,
        QC_11: 2056.498,
        VC_11: -9998.0,
        QE_11: 2000.717,
        VE_11: -9998.0,
        QA_12: 2856.471,
        VA_12: -9998.0,
        QC_12: 2856.471,
        VC_12: -9998.0,
        QE_12: 2865.838,
        VE_12: -9998.0,
        LakeFract: 0.02025029065,
        SurfArea: 2649047.1608899999,
        RAreaHLoad: 0.44421435952,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.101637184554036, 35.767883877814427, 0.0],
          [-81.092586984568072, 35.768230611147317, 0.0],
          [-81.08916831790674, 35.764949411152429, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283988.0,
        COMID: 9754832,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.386,
        REACHCODE: "03050101000402",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0042482444366010001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061726.0,
        ToNode: 250061721.0,
        Hydroseq: 250023104.0,
        LevelPathI: 250003258.0,
        Pathlength: 695.587,
        TerminalPa: 250002604.0,
        ArbolateSu: 270.884,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250025524.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022605.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1566,
        TotDASqKM: 293.2884,
        DivDASqKM: 270.5436,
        Tidal: 0,
        TOTMA: 0.0108796989097,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38689.0,
        MAXELEVSMO: 38775.0,
        MINELEVSMO: 38689.0,
        SLOPE: 0.00222797,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.38600000739097601,
        QA_MA: 178.483,
        VA_MA: 1.34687,
        QC_MA: 176.762,
        VC_MA: 1.34606,
        QE_MA: 179.241,
        VE_MA: 1.34723,
        QA_01: 281.766,
        VA_01: 1.63179,
        QC_01: 220.407,
        VC_01: 1.47506,
        QE_01: 222.353,
        VE_01: 1.47319,
        QA_02: 296.774,
        VA_02: 1.66876,
        QC_02: 247.578,
        VC_02: 1.54934,
        QE_02: 251.524,
        VE_02: 1.55199,
        QA_03: 334.577,
        VA_03: 1.75819,
        QC_03: 281.203,
        VC_03: 1.63616,
        QE_03: 282.343,
        VE_03: 1.6307,
        QA_04: 251.818,
        VA_04: 1.55513,
        QC_04: 232.406,
        VC_04: 1.50836,
        QE_04: 235.936,
        VE_04: 1.51045,
        QA_05: 200.745,
        VA_05: 1.41375,
        QC_05: 186.957,
        VC_05: 1.37742,
        QE_05: 188.778,
        VE_05: 1.37622,
        QA_06: 118.038,
        VA_06: 1.14216,
        QC_06: 147.528,
        VC_06: 1.25107,
        QE_06: 150.118,
        VE_06: 1.25388,
        QA_07: 68.312,
        VA_07: 0.932,
        QC_07: 102.998,
        VC_07: 1.08733,
        QE_07: 106.33,
        VE_07: 1.09567,
        QA_08: 47.249,
        VA_08: 0.82113,
        QC_08: 105.88,
        VC_08: 1.09883,
        QE_08: 111.749,
        VE_08: 1.11672,
        QA_09: 55.93,
        VA_09: 0.86918,
        QC_09: 136.57,
        VC_09: 1.21322,
        QE_09: 136.887,
        VE_09: 1.20868,
        QA_10: 83.701,
        VA_10: 1.00305,
        QC_10: 188.932,
        VC_10: 1.3834,
        QE_10: 186.188,
        VE_10: 1.36842,
        QA_11: 178.18,
        VA_11: 1.34593,
        QC_11: 199.068,
        VC_11: 1.41365,
        QE_11: 196.868,
        VE_11: 1.40028,
        QA_12: 226.111,
        VA_12: 1.48585,
        QC_12: 183.332,
        VC_12: 1.36636,
        QE_12: 183.699,
        VE_12: 1.36087,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.101432383002077, 35.667985677969455, 0.0],
          [-82.097307116341824, 35.668099677969337, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283989.0,
        COMID: 9754848,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.836,
        REACHCODE: "03050101000403",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0080956106251729998,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061731.0,
        ToNode: 250061726.0,
        Hydroseq: 250025524.0,
        LevelPathI: 250003258.0,
        Pathlength: 695.973,
        TerminalPa: 250002604.0,
        ArbolateSu: 248.364,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250026265.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023104.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3069,
        TotDASqKM: 270.3807,
        DivDASqKM: 270.3807,
        Tidal: 0,
        TOTMA: 0.0233895005854,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38775.0,
        MAXELEVSMO: 38973.0,
        MINELEVSMO: 38775.0,
        SLOPE: 0.00236842,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.83600002527236905,
        QA_MA: 178.387,
        VA_MA: 1.35687,
        QC_MA: 176.67,
        VC_MA: 1.35605,
        QE_MA: 179.148,
        VE_MA: 1.35724,
        QA_01: 281.602,
        VA_01: 1.64451,
        QC_01: 220.283,
        VC_01: 1.48627,
        QE_01: 222.227,
        VE_01: 1.48438,
        QA_02: 296.601,
        VA_02: 1.68183,
        QC_02: 247.437,
        VC_02: 1.56126,
        QE_02: 251.382,
        VE_02: 1.56394,
        QA_03: 334.383,
        VA_03: 1.77212,
        QC_03: 281.045,
        VC_03: 1.64892,
        QE_03: 282.185,
        VE_03: 1.64341,
        QA_04: 251.674,
        VA_04: 1.56712,
        QC_04: 232.276,
        VC_04: 1.5199,
        QE_04: 235.804,
        VE_04: 1.522,
        QA_05: 200.637,
        VA_05: 1.4244,
        QC_05: 186.86,
        VC_05: 1.38772,
        QE_05: 188.68,
        VE_05: 1.38651,
        QA_06: 117.978,
        VA_06: 1.1502,
        QC_06: 147.453,
        VC_06: 1.26016,
        QE_06: 150.042,
        VE_06: 1.263,
        QA_07: 68.277,
        VA_07: 0.93802,
        QC_07: 102.947,
        VC_07: 1.09485,
        QE_07: 106.277,
        VE_07: 1.10326,
        QA_08: 47.225,
        VA_08: 0.82608,
        QC_08: 105.825,
        VC_08: 1.10645,
        QE_08: 111.69,
        VE_08: 1.12451,
        QA_09: 55.913,
        VA_09: 0.87465,
        QC_09: 136.532,
        VC_09: 1.22206,
        QE_09: 136.849,
        VE_09: 1.21747,
        QA_10: 83.688,
        VA_10: 1.00988,
        QC_10: 188.909,
        VC_10: 1.39399,
        QE_10: 186.166,
        VE_10: 1.37886,
        QA_11: 178.089,
        VA_11: 1.35594,
        QC_11: 198.99,
        VC_11: 1.42437,
        QE_11: 196.792,
        VE_11: 1.41088,
        QA_12: 225.981,
        VA_12: 1.49717,
        QC_12: 183.236,
        VC_12: 1.37655,
        QE_12: 183.603,
        VE_12: 1.37101,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.105558382995753, 35.662146877978614, 0.0],
          [-82.10468791633042, 35.66610847797245, 0.0],
          [-82.101432383002077, 35.667985677969455, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289003.0,
        COMID: 9715245,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 1.578,
        REACHCODE: "03050104000212",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014902603742907999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057369.0,
        ToNode: 250057349.0,
        Hydroseq: 250021727.0,
        LevelPathI: 250018611.0,
        Pathlength: 360.268,
        TerminalPa: 250002604.0,
        ArbolateSu: 90.106,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250022164.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021323.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0989,
        TotDASqKM: 72.7056,
        DivDASqKM: 72.7056,
        Tidal: 0,
        TOTMA: 0.063380856245300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7147.0,
        MAXELEVSMO: 7384.0,
        MINELEVSMO: 7242.0,
        SLOPE: 0.00089987,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.577999949455261,
        QA_MA: 26.562,
        VA_MA: 0.9421,
        QC_MA: 28.324,
        VC_MA: 0.94541,
        QE_MA: 28.324,
        VE_MA: 0.94541,
        QA_01: 59.129,
        VA_01: 1.27764,
        QC_01: 48.185,
        VC_01: 1.15306,
        QE_01: 48.185,
        VE_01: 1.15306,
        QA_02: 63.716,
        VA_02: 1.31687,
        QC_02: 55.43,
        VC_02: 1.21828,
        QE_02: 55.43,
        VE_02: 1.21828,
        QA_03: 68.916,
        VA_03: 1.35977,
        QC_03: 61.024,
        VC_03: 1.26594,
        QE_03: 61.024,
        VE_03: 1.26594,
        QA_04: 37.451,
        VA_04: 1.06893,
        QC_04: 36.094,
        VC_04: 1.03292,
        QE_04: 36.094,
        VE_04: 1.03292,
        QA_05: 21.115,
        VA_05: 0.8694,
        QC_05: 21.393,
        VC_05: 0.85721,
        QE_05: 21.393,
        VE_05: 0.85721,
        QA_06: 13.686,
        VA_06: 0.75402,
        QC_06: 17.618,
        VC_06: 0.80345,
        QE_06: 17.618,
        VE_06: 0.80345,
        QA_07: 9.775,
        VA_07: 0.68109,
        QC_07: 15.441,
        VC_07: 0.76997,
        QE_07: 15.441,
        VE_07: 0.76997,
        QA_08: 7.82,
        VA_08: 0.63945,
        QC_08: 16.905,
        VC_08: 0.79271,
        QE_08: 16.905,
        VE_08: 0.79271,
        QA_09: 5.865,
        VA_09: 0.59255,
        QC_09: 18.65,
        VC_09: 0.81865,
        QE_09: 18.65,
        VE_09: 0.81865,
        QA_10: 4.888,
        VA_10: 0.56634,
        QC_10: 19.537,
        VC_10: 0.83139,
        QE_10: 19.537,
        VE_10: 0.83139,
        QA_11: 3.91,
        VA_11: 0.53751,
        QC_11: 10.816,
        VC_11: 0.69046,
        QE_11: 10.816,
        VE_11: 0.69046,
        QA_12: 22.707,
        VA_12: 0.89147,
        QC_12: 22.651,
        VC_12: 0.87412,
        QE_12: 22.651,
        VE_12: 0.87412,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.923158918164404, 34.387182213290998, 0.0],
          [-80.915483318176257, 34.399509013271768, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283990.0,
        COMID: 9754854,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.189,
        REACHCODE: "03050101000404",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018100699203729999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061733.0,
        ToNode: 250061731.0,
        Hydroseq: 250026265.0,
        LevelPathI: 250003258.0,
        Pathlength: 696.809,
        TerminalPa: 250002604.0,
        ArbolateSu: 247.528,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250027063.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250025523.0,
        DnDrainCou: 2,
        DnHydroseq: 250025524.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0504,
        TotDASqKM: 270.0738,
        DivDASqKM: 270.0738,
        Tidal: 0,
        TOTMA: 0.0044329923731999997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38973.0,
        MAXELEVSMO: 39155.0,
        MINELEVSMO: 38973.0,
        SLOPE: 0.00962962,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18899999558925601,
        QA_MA: 178.209,
        VA_MA: 1.6185,
        QC_MA: 176.501,
        VC_MA: 1.61748,
        QE_MA: 178.976,
        VE_MA: 1.61896,
        QA_01: 281.297,
        VA_01: 1.97783,
        QC_01: 220.05,
        VC_01: 1.78011,
        QE_01: 221.992,
        VE_01: 1.77775,
        QA_02: 296.278,
        VA_02: 2.02446,
        QC_02: 247.175,
        VC_02: 1.87381,
        QE_02: 251.114,
        VE_02: 1.87715,
        QA_03: 334.02,
        VA_03: 2.13728,
        QC_03: 280.75,
        VC_03: 1.98335,
        QE_03: 281.888,
        VE_03: 1.97646,
        QA_04: 251.408,
        VA_04: 1.88116,
        QC_04: 232.036,
        VC_04: 1.82215,
        QE_04: 235.56,
        VE_04: 1.82478,
        QA_05: 200.437,
        VA_05: 1.70288,
        QC_05: 186.68,
        VC_05: 1.65704,
        QE_05: 188.499,
        VE_05: 1.65553,
        QA_06: 117.867,
        VA_06: 1.36029,
        QC_06: 147.316,
        VC_06: 1.49768,
        QE_06: 149.902,
        VE_06: 1.50122,
        QA_07: 68.211,
        VA_07: 1.09515,
        QC_07: 102.85,
        VC_07: 1.2911,
        QE_07: 106.176,
        VE_07: 1.30161,
        QA_08: 47.18,
        VA_08: 0.95528,
        QC_08: 105.721,
        VC_08: 1.30558,
        QE_08: 111.58,
        VE_08: 1.32814,
        QA_09: 55.88,
        VA_09: 1.01611,
        QC_09: 136.461,
        VC_09: 1.45031,
        QE_09: 136.777,
        VE_09: 1.44457,
        QA_10: 83.663,
        VA_10: 1.18525,
        QC_10: 188.864,
        VC_10: 1.66539,
        QE_10: 186.125,
        VE_10: 1.6465,
        QA_11: 177.924,
        VA_11: 1.61739,
        QC_11: 198.849,
        VC_11: 1.70303,
        QE_11: 196.653,
        VE_11: 1.68617,
        QA_12: 225.744,
        VA_12: 1.79376,
        QC_12: 183.061,
        VC_12: 1.64309,
        QE_12: 183.428,
        VE_12: 1.63617,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.10452038299735, 35.660704077980881, 0.0],
          [-82.105558382995753, 35.662146877978614, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289004.0,
        COMID: 9715251,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.193,
        REACHCODE: "03050104000213",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018257167483250001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057370.0,
        ToNode: 250057369.0,
        Hydroseq: 250022164.0,
        LevelPathI: 250018611.0,
        Pathlength: 361.846,
        TerminalPa: 250002604.0,
        ArbolateSu: 88.299,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250022633.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021727.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5688,
        TotDASqKM: 71.5707,
        DivDASqKM: 71.5707,
        Tidal: 0,
        TOTMA: 0.0118440266675,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7344.0,
        MAXELEVSMO: 7384.0,
        MINELEVSMO: 7384.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.193000003695488,
        QA_MA: 26.152,
        VA_MA: 0.61714,
        QC_MA: 27.904,
        VC_MA: 0.61877,
        QE_MA: 27.904,
        VE_MA: 0.61877,
        QA_01: 58.221,
        VA_01: 0.78064,
        QC_01: 47.464,
        VC_01: 0.71991,
        QE_01: 47.464,
        VE_01: 0.71991,
        QA_02: 62.739,
        VA_02: 0.79976,
        QC_02: 54.603,
        VC_02: 0.7517,
        QE_02: 54.603,
        VE_02: 0.7517,
        QA_03: 67.852,
        VA_03: 0.82064,
        QC_03: 60.112,
        VC_03: 0.77491,
        QE_03: 60.112,
        VE_03: 0.77491,
        QA_04: 36.871,
        VA_04: 0.67892,
        QC_04: 35.548,
        VC_04: 0.66135,
        QE_04: 35.548,
        VE_04: 0.66135,
        QA_05: 20.795,
        VA_05: 0.58176,
        QC_05: 21.081,
        VC_05: 0.57583,
        QE_05: 21.081,
        VE_05: 0.57583,
        QA_06: 13.472,
        VA_06: 0.52548,
        QC_06: 17.347,
        VC_06: 0.54953,
        QE_06: 17.347,
        VE_06: 0.54953,
        QA_07: 9.623,
        VA_07: 0.48997,
        QC_07: 15.205,
        VC_07: 0.53323,
        QE_07: 15.205,
        VE_07: 0.53323,
        QA_08: 7.698,
        VA_08: 0.46967,
        QC_08: 16.635,
        VC_08: 0.54423,
        QE_08: 16.635,
        VE_08: 0.54423,
        QA_09: 5.774,
        VA_09: 0.44683,
        QC_09: 18.393,
        VC_09: 0.55715,
        QE_09: 18.393,
        VE_09: 0.55715,
        QA_10: 4.811,
        VA_10: 0.43405,
        QC_10: 19.293,
        VC_10: 0.56354,
        QE_10: 19.293,
        VE_10: 0.56354,
        QA_11: 3.849,
        VA_11: 0.42001,
        QC_11: 10.687,
        VC_11: 0.49484,
        QE_11: 10.687,
        VE_11: 0.49484,
        QA_12: 22.356,
        VA_12: 0.59247,
        QC_12: 22.332,
        VC_12: 0.58415,
        QE_12: 22.332,
        VE_12: 0.58415,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.923931718163146, 34.385715679959958, 0.0],
          [-80.923158918164404, 34.387182213290998, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283983.0,
        COMID: 9754794,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.989,
        REACHCODE: "03050101000397",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020591218678019999,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061713.0,
        ToNode: 250061708.0,
        Hydroseq: 250020917.0,
        LevelPathI: 250003258.0,
        Pathlength: 691.167,
        TerminalPa: 250002604.0,
        ArbolateSu: 302.431,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250021299.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020549.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.305,
        TotDASqKM: 325.9575,
        DivDASqKM: 325.9575,
        Tidal: 0,
        TOTMA: 0.059360765057599997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37732.0,
        MAXELEVSMO: 38213.0,
        MINELEVSMO: 38001.0,
        SLOPE: 0.00106586,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.9889999628067021,
        QA_MA: 215.581,
        VA_MA: 1.27259,
        QC_MA: 211.943,
        VC_MA: 1.27126,
        QE_MA: 214.93,
        VE_MA: 1.27235,
        QA_01: 340.554,
        VA_01: 1.53755,
        QC_01: 265.074,
        VC_01: 1.3927,
        QE_01: 267.418,
        VE_01: 1.39094,
        QA_02: 358.458,
        VA_02: 1.57145,
        QC_02: 297.501,
        VC_02: 1.46122,
        QE_02: 302.256,
        VE_02: 1.46367,
        QA_03: 404.241,
        VA_03: 1.6547,
        QC_03: 337.639,
        VC_03: 1.54136,
        QE_03: 339.012,
        VE_03: 1.53626,
        QA_04: 304.531,
        VA_04: 1.4667,
        QC_04: 279.843,
        VC_04: 1.42437,
        QE_04: 284.096,
        VE_04: 1.42628,
        QA_05: 243.552,
        VA_05: 1.33711,
        QC_05: 225.19,
        VC_05: 1.30276,
        QE_05: 227.384,
        VE_05: 1.30162,
        QA_06: 141.801,
        VA_06: 1.08029,
        QC_06: 176.781,
        VC_06: 1.18284,
        QE_06: 179.902,
        VE_06: 1.18546,
        QA_07: 82.079,
        VA_07: 0.88579,
        QC_07: 123.213,
        VC_07: 1.0303,
        QE_07: 127.227,
        VE_07: 1.03809,
        QA_08: 56.377,
        VA_08: 0.78138,
        QC_08: 126.782,
        VC_08: 1.04133,
        QE_08: 133.853,
        VE_08: 1.05802,
        QA_09: 66.202,
        VA_09: 0.8235,
        QC_09: 158.49,
        VC_09: 1.13356,
        QE_09: 158.872,
        VE_09: 1.12943,
        QA_10: 99.72,
        VA_10: 0.94867,
        QC_10: 217.299,
        VC_10: 1.2841,
        QE_10: 213.993,
        VE_10: 1.27012,
        QA_11: 217.098,
        VA_11: 1.27618,
        QC_11: 231.436,
        VC_11: 1.31731,
        QE_11: 228.786,
        VE_11: 1.30486,
        QA_12: 274.132,
        VA_12: 1.40379,
        QC_12: 218.441,
        VC_12: 1.28682,
        QE_12: 218.883,
        VE_12: 1.28172,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.077855383038695, 35.677163077955186, 0.0],
          [-82.071650383048336, 35.681328677948841, 0.0],
          [-82.07251878304703, 35.684489211277253, 0.0],
          [-82.063396116394529, 35.685172477942899, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286049.0,
        COMID: 9753996,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.113,
        REACHCODE: "03050101002724",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010756882478005001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061472.0,
        ToNode: 250061482.0,
        Hydroseq: 250009638.0,
        LevelPathI: 250003258.0,
        Pathlength: 566.801,
        TerminalPa: 250002604.0,
        ArbolateSu: 3302.235,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009678.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009593.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4778,
        TotDASqKM: 3691.1781,
        DivDASqKM: 3691.1781,
        Tidal: 0,
        TOTMA: 1.38803712125,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25416.0,
        MAXELEVSMO: 25422.0,
        MINELEVSMO: 25422.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1130000352859499,
        QA_MA: 2213.623,
        VA_MA: -9998.0,
        QC_MA: 2213.623,
        VC_MA: -9998.0,
        QE_MA: 2405.679,
        VE_MA: -9998.0,
        QA_01: 3581.57,
        VA_01: -9998.0,
        QC_01: 3581.57,
        VC_01: -9998.0,
        QE_01: 3779.71,
        VE_01: -9998.0,
        QA_02: 3785.313,
        VA_02: -9998.0,
        QC_02: 3785.313,
        VC_02: -9998.0,
        QE_02: 3849.268,
        VE_02: -9998.0,
        QA_03: 4280.581,
        VA_03: -9998.0,
        QC_03: 4280.581,
        VC_03: -9998.0,
        QE_03: 4539.119,
        VE_03: -9998.0,
        QA_04: 3294.475,
        VA_04: -9998.0,
        QC_04: 3294.475,
        VC_04: -9998.0,
        QE_04: 3483.586,
        VE_04: -9998.0,
        QA_05: 2505.307,
        VA_05: -9998.0,
        QC_05: 2505.307,
        VC_05: -9998.0,
        QE_05: 2726.515,
        VE_05: -9998.0,
        QA_06: 1460.757,
        VA_06: -9998.0,
        QC_06: 1460.757,
        VC_06: -9998.0,
        QE_06: 1722.541,
        VE_06: -9998.0,
        QA_07: 863.141,
        VA_07: -9998.0,
        QC_07: 863.141,
        VC_07: -9998.0,
        QE_07: 1013.483,
        VE_07: -9998.0,
        QA_08: 605.921,
        VA_08: -9998.0,
        QC_08: 605.921,
        VC_08: -9998.0,
        QE_08: 907.375,
        VE_08: -9998.0,
        QA_09: 587.488,
        VA_09: -9998.0,
        QC_09: 1089.178,
        VC_09: -9998.0,
        QE_09: 1125.883,
        VE_09: -9998.0,
        QA_10: 750.891,
        VA_10: -9998.0,
        QC_10: 750.891,
        VC_10: -9998.0,
        QE_10: 691.321,
        VE_10: -9998.0,
        QA_11: 2041.071,
        VA_11: -9998.0,
        QC_11: 2041.071,
        VC_11: -9998.0,
        QE_11: 1985.29,
        VE_11: -9998.0,
        QA_12: 2821.374,
        VA_12: -9998.0,
        QC_12: 2821.374,
        VC_12: -9998.0,
        QE_12: 2830.741,
        VE_12: -9998.0,
        LakeFract: 0.01697528624,
        SurfArea: 2220626.5873699998,
        RAreaHLoad: 0.37655032916999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.128826117845108, 35.793292211108337, 0.0],
          [-81.128816917845143, 35.789900411113649, 0.0],
          [-81.123358784520292, 35.785345811120692, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289005.0,
        COMID: 9715261,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.402,
        REACHCODE: "03050104000214",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0041195906136680003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057375.0,
        ToNode: 250057370.0,
        Hydroseq: 250022633.0,
        LevelPathI: 250018611.0,
        Pathlength: 362.039,
        TerminalPa: 250002604.0,
        ArbolateSu: 88.106,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250023131.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 250022165.0,
        DnDrainCou: 2,
        DnHydroseq: 250022164.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.198,
        TotDASqKM: 71.0019,
        DivDASqKM: 71.0019,
        Tidal: 0,
        TOTMA: 0.014339141460600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7380.0,
        MAXELEVSMO: 7494.0,
        MINELEVSMO: 7384.0,
        SLOPE: 0.00273631,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40200001001357999,
        QA_MA: 25.946,
        VA_MA: 1.06059,
        QC_MA: 27.693,
        VC_MA: 1.06457,
        QE_MA: 27.693,
        VE_MA: 1.06457,
        QA_01: 57.762,
        VA_01: 1.4589,
        QC_01: 47.099,
        VC_01: 1.31091,
        QE_01: 47.099,
        VE_01: 1.31091,
        QA_02: 62.249,
        VA_02: 1.50553,
        QC_02: 54.189,
        VC_02: 1.38841,
        QE_02: 54.189,
        VE_02: 1.38841,
        QA_03: 67.316,
        VA_03: 1.55632,
        QC_03: 59.653,
        VC_03: 1.44493,
        QE_03: 59.653,
        VE_03: 1.44493,
        QA_04: 36.581,
        VA_04: 1.2111,
        QC_04: 35.274,
        VC_04: 1.16824,
        QE_04: 35.274,
        VE_04: 1.16824,
        QA_05: 20.634,
        VA_05: 0.97443,
        QC_05: 20.924,
        VC_05: 0.96,
        QE_05: 20.924,
        VE_05: 0.96,
        QA_06: 13.365,
        VA_06: 0.83726,
        QC_06: 17.211,
        VC_06: 0.89579,
        QE_06: 17.211,
        VE_06: 0.89579,
        QA_07: 9.546,
        VA_07: 0.75072,
        QC_07: 15.087,
        VC_07: 0.85611,
        QE_07: 15.087,
        VE_07: 0.85611,
        QA_08: 7.637,
        VA_08: 0.7013,
        QC_08: 16.501,
        VC_08: 0.8828,
        QE_08: 16.501,
        VE_08: 0.8828,
        QA_09: 5.728,
        VA_09: 0.64564,
        QC_09: 18.264,
        VC_09: 0.91461,
        QE_09: 18.264,
        VE_09: 0.91461,
        QA_10: 4.773,
        VA_10: 0.61451,
        QC_10: 19.17,
        VC_10: 0.9304,
        QE_10: 19.17,
        VE_10: 0.9304,
        QA_11: 3.819,
        VA_11: 0.58033,
        QC_11: 10.622,
        VC_11: 0.763,
        QE_11: 10.622,
        VE_11: 0.763,
        QA_12: 22.18,
        VA_12: 1.00049,
        QC_12: 22.172,
        VC_12: 0.98035,
        QE_12: 22.172,
        VE_12: 0.98035,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.927301784824635, 34.384271213295449, 0.0],
          [-80.923931718163146, 34.385715679959958, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283984.0,
        COMID: 9754798,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.021,
        REACHCODE: "03050101000398",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00019565995419000001,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061714.0,
        ToNode: 250061713.0,
        Hydroseq: 250021299.0,
        LevelPathI: 250003258.0,
        Pathlength: 693.156,
        TerminalPa: 250002604.0,
        ArbolateSu: 292.481,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250021701.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 311.2848,
        DivDASqKM: 311.2848,
        Tidal: 0,
        TOTMA: 0.00038196223524199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 38326.0,
        MINELEVSMO: 38213.0,
        SLOPE: 0.05380952,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.020999999716878,
        QA_MA: 205.426,
        VA_MA: 2.08787,
        QC_MA: 202.338,
        VC_MA: 2.08569,
        QE_MA: 205.19,
        VE_MA: 2.08771,
        QA_01: 324.799,
        VA_01: 2.57795,
        QC_01: 253.125,
        VC_01: 2.31012,
        QE_01: 255.363,
        VE_01: 2.30687,
        QA_02: 341.941,
        VA_02: 2.64078,
        QC_02: 284.157,
        VC_02: 2.43689,
        QE_02: 288.698,
        VE_02: 2.44141,
        QA_03: 385.484,
        VA_03: 2.79406,
        QC_03: 322.478,
        VC_03: 2.58478,
        QE_03: 323.789,
        VE_03: 2.57537,
        QA_04: 290.208,
        VA_04: 2.44622,
        QC_04: 266.974,
        VC_04: 2.36754,
        QE_04: 271.035,
        VE_04: 2.37108,
        QA_05: 231.714,
        VA_05: 2.20537,
        QC_05: 214.644,
        VC_05: 2.14229,
        QE_05: 216.739,
        VE_05: 2.1402,
        QA_06: 135.2,
        VA_06: 1.73342,
        QC_06: 168.662,
        VC_06: 1.92193,
        QE_06: 171.642,
        VE_06: 1.92678,
        QA_07: 78.259,
        VA_07: 1.37435,
        QC_07: 117.613,
        VC_07: 1.64083,
        QE_07: 121.447,
        VE_07: 1.65521,
        QA_08: 53.761,
        VA_08: 1.18167,
        QC_08: 120.785,
        VC_08: 1.65979,
        QE_08: 127.538,
        VE_08: 1.69066,
        QA_09: 62.963,
        VA_09: 1.2581,
        QC_09: 151.625,
        VC_09: 1.83323,
        QE_09: 151.99,
        VE_09: 1.82559,
        QA_10: 95.018,
        VA_10: 1.49004,
        QC_10: 209.074,
        VC_10: 2.11687,
        QE_10: 205.916,
        VE_10: 2.09105,
        QA_11: 206.329,
        VA_11: 2.09202,
        QC_11: 222.628,
        VC_11: 2.17821,
        QE_11: 220.097,
        VE_11: 2.15522,
        QA_12: 261.114,
        VA_12: 2.3296,
        QC_12: 208.983,
        VC_12: 2.11645,
        QE_12: 209.405,
        VE_12: 2.10702,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.077967716371916, 35.677002877955431, 0.0],
          [-82.077855383038695, 35.677163077955186, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286050.0,
        COMID: 9754016,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.909,
        REACHCODE: "03050101002725",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.029265674777974999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061482.0,
        ToNode: 250061488.0,
        Hydroseq: 250009593.0,
        LevelPathI: 250003258.0,
        Pathlength: 563.892,
        TerminalPa: 250002604.0,
        ArbolateSu: 3340.134,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009638.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009546.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.2839,
        TotDASqKM: 3741.0858,
        DivDASqKM: 3741.0858,
        Tidal: 0,
        TOTMA: 3.6278526380499998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25409.0,
        MAXELEVSMO: 25422.0,
        MINELEVSMO: 25422.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.908999919891357,
        QA_MA: 2236.945,
        VA_MA: -9998.0,
        QC_MA: 2236.945,
        VC_MA: -9998.0,
        QE_MA: 2429.001,
        VE_MA: -9998.0,
        QA_01: 3625.005,
        VA_01: -9998.0,
        QC_01: 3625.005,
        VC_01: -9998.0,
        QE_01: 3823.145,
        VE_01: -9998.0,
        QA_02: 3830.951,
        VA_02: -9998.0,
        QC_02: 3830.951,
        VC_02: -9998.0,
        QE_02: 3894.906,
        VE_02: -9998.0,
        QA_03: 4330.575,
        VA_03: -9998.0,
        QC_03: 4330.575,
        VC_03: -9998.0,
        QE_03: 4589.112,
        VE_03: -9998.0,
        QA_04: 3330.934,
        VA_04: -9998.0,
        QC_04: 3330.934,
        VC_04: -9998.0,
        QE_04: 3520.045,
        VE_04: -9998.0,
        QA_05: 2529.341,
        VA_05: -9998.0,
        QC_05: 2529.341,
        VC_05: -9998.0,
        QE_05: 2750.549,
        VE_05: -9998.0,
        QA_06: 1474.836,
        VA_06: -9998.0,
        QC_06: 1474.836,
        VC_06: -9998.0,
        QE_06: 1736.62,
        VE_06: -9998.0,
        QA_07: 871.699,
        VA_07: -9998.0,
        QC_07: 871.699,
        VC_07: -9998.0,
        QE_07: 1022.041,
        VE_07: -9998.0,
        QA_08: 611.657,
        VA_08: -9998.0,
        QC_08: 611.657,
        VC_08: -9998.0,
        QE_08: 913.112,
        VE_08: -9998.0,
        QA_09: 592.303,
        VA_09: -9998.0,
        QC_09: 1097.056,
        VC_09: -9998.0,
        QE_09: 1133.76,
        VE_09: -9998.0,
        QA_10: 754.246,
        VA_10: -9998.0,
        QC_10: 754.246,
        VC_10: -9998.0,
        QE_10: 694.676,
        VE_10: -9998.0,
        QA_11: 2054.576,
        VA_11: -9998.0,
        QC_11: 2054.576,
        VC_11: -9998.0,
        QE_11: 1998.795,
        VE_11: -9998.0,
        QA_12: 2851.662,
        VA_12: -9998.0,
        QC_12: 2851.662,
        VC_12: -9998.0,
        QE_12: 2861.029,
        VE_12: -9998.0,
        LakeFract: 0.04479684978,
        SurfArea: 5860111.8264300004,
        RAreaHLoad: 0.98417332224999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.123358784520292, 35.785345811120692, 0.0],
          [-81.116299917864467, 35.782894277791115, 0.0],
          [-81.101637184554036, 35.767883877814427, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289006.0,
        COMID: 9715265,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.178,
        REACHCODE: "03050104000215",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001902416292898,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057377.0,
        ToNode: 250057375.0,
        Hydroseq: 250023131.0,
        LevelPathI: 250018611.0,
        Pathlength: 362.441,
        TerminalPa: 250002604.0,
        ArbolateSu: 84.798,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250023673.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022633.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1557,
        TotDASqKM: 68.1642,
        DivDASqKM: 68.1642,
        Tidal: 0,
        TOTMA: 0.0094891732144600002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7450.0,
        MAXELEVSMO: 7495.0,
        MINELEVSMO: 7494.0,
        SLOPE: 5.617e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.178000003099442,
        QA_MA: 24.92,
        VA_MA: 0.71012,
        QC_MA: 26.639,
        VC_MA: 0.7123,
        QE_MA: 26.639,
        VE_MA: 0.7123,
        QA_01: 55.473,
        VA_01: 0.92283,
        QC_01: 45.281,
        VC_01: 0.84372,
        QE_01: 45.281,
        VE_01: 0.84372,
        QA_02: 59.807,
        VA_02: 0.94787,
        QC_02: 52.12,
        VC_02: 0.88524,
        QE_02: 52.12,
        VE_02: 0.88524,
        QA_03: 64.646,
        VA_03: 0.97484,
        QC_03: 57.363,
        VC_03: 0.91536,
        QE_03: 57.363,
        VE_03: 0.91536,
        QA_04: 35.131,
        VA_04: 0.79048,
        QC_04: 33.907,
        VC_04: 0.7675,
        QE_04: 33.907,
        VE_04: 0.7675,
        QA_05: 19.833,
        VA_05: 0.66425,
        QC_05: 20.141,
        VC_05: 0.65657,
        QE_05: 20.141,
        VE_05: 0.65657,
        QA_06: 12.831,
        VA_06: 0.59078,
        QC_06: 16.532,
        VC_06: 0.62191,
        QE_06: 16.532,
        VE_06: 0.62191,
        QA_07: 9.165,
        VA_07: 0.54458,
        QC_07: 14.499,
        VC_07: 0.60081,
        QE_07: 14.499,
        VE_07: 0.60081,
        QA_08: 7.332,
        VA_08: 0.51819,
        QC_08: 15.828,
        VC_08: 0.61475,
        QE_08: 15.828,
        VE_08: 0.61475,
        QA_09: 5.499,
        VA_09: 0.48847,
        QC_09: 17.618,
        VC_09: 0.63268,
        QE_09: 17.618,
        VE_09: 0.63268,
        QA_10: 4.582,
        VA_10: 0.47184,
        QC_10: 18.556,
        VC_10: 0.64174,
        QE_10: 18.556,
        VE_10: 0.64174,
        QA_11: 3.666,
        VA_11: 0.45359,
        QC_11: 10.297,
        VC_11: 0.55222,
        QE_11: 10.297,
        VE_11: 0.55222,
        QA_12: 21.298,
        VA_12: 0.67798,
        QC_12: 21.368,
        VC_12: 0.66767,
        QE_12: 21.368,
        VE_12: 0.66767,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.929069784821877, 34.383766413296314, 0.0],
          [-80.927301784824635, 34.384271213295449, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283985.0,
        COMID: 9754814,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.461,
        REACHCODE: "03050101000399",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0045704148675869997,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061718.0,
        ToNode: 250061714.0,
        Hydroseq: 250021701.0,
        LevelPathI: 250003258.0,
        Pathlength: 693.177,
        TerminalPa: 250002604.0,
        ArbolateSu: 282.289,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250022138.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021299.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2394,
        TotDASqKM: 303.5133,
        DivDASqKM: 303.5133,
        Tidal: 0,
        TOTMA: 0.0124276103752,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38106.0,
        MAXELEVSMO: 38451.0,
        MINELEVSMO: 38326.0,
        SLOPE: 0.00271149,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.460999995470047,
        QA_MA: 200.502,
        VA_MA: 1.40861,
        QC_MA: 197.674,
        VC_MA: 1.40735,
        QE_MA: 200.455,
        VE_MA: 1.40859,
        QA_01: 316.711,
        VA_01: 1.71091,
        QC_01: 246.985,
        VC_01: 1.54531,
        QE_01: 249.168,
        VE_01: 1.54331,
        QA_02: 333.434,
        VA_02: 1.74976,
        QC_02: 277.279,
        VC_02: 1.62368,
        QE_02: 281.706,
        VE_02: 1.62648,
        QA_03: 375.914,
        VA_03: 1.84452,
        QC_03: 314.733,
        VC_03: 1.71521,
        QE_03: 316.012,
        VE_03: 1.70941,
        QA_04: 283.038,
        VA_04: 1.62964,
        QC_04: 260.526,
        VC_04: 1.58086,
        QE_04: 264.486,
        VE_04: 1.58306,
        QA_05: 226.078,
        VA_05: 1.48104,
        QC_05: 209.615,
        VC_05: 1.44211,
        QE_05: 211.659,
        VE_05: 1.44083,
        QA_06: 132.109,
        VA_06: 1.18999,
        QC_06: 164.858,
        VC_06: 1.30636,
        QE_06: 167.764,
        VE_06: 1.30935,
        QA_07: 76.45,
        VA_07: 0.96776,
        QC_07: 114.958,
        VC_07: 1.13249,
        QE_07: 118.696,
        VE_07: 1.14136,
        QA_08: 52.561,
        VA_08: 0.8488,
        QC_08: 118.034,
        VC_08: 1.14413,
        QE_08: 124.618,
        VE_08: 1.16317,
        QA_09: 61.905,
        VA_09: 0.89786,
        QC_09: 149.374,
        VC_09: 1.25544,
        QE_09: 149.729,
        VE_09: 1.25069,
        QA_10: 93.512,
        VA_10: 1.04221,
        QC_10: 206.422,
        VC_10: 1.43291,
        QE_10: 203.344,
        VE_10: 1.41699,
        QA_11: 201.284,
        VA_11: 1.41089,
        QC_11: 218.465,
        VC_11: 1.46729,
        QE_11: 215.997,
        VE_11: 1.4531,
        QA_12: 254.63,
        VA_12: 1.55748,
        QC_12: 204.256,
        VC_12: 1.42663,
        QE_12: 204.668,
        VE_12: 1.42081,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.080242383035056, 35.674186877959983, 0.0],
          [-82.078165316371553, 35.674896077958749, 0.0],
          [-82.077967716371916, 35.677002877955431, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288999.0,
        COMID: 9715159,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.573,
        REACHCODE: "03050104000208",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0053830757242330001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057342.0,
        ToNode: 250057334.0,
        Hydroseq: 250020214.0,
        LevelPathI: 250018611.0,
        Pathlength: 357.66,
        TerminalPa: 250002604.0,
        ArbolateSu: 116.545,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250020569.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019868.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2574,
        TotDASqKM: 95.9697,
        DivDASqKM: 95.9697,
        Tidal: 0,
        TOTMA: 0.0339963581391,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6795.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.57300001382827803,
        QA_MA: 34.952,
        VA_MA: 0.63858,
        QC_MA: 36.875,
        VC_MA: 0.64002,
        QE_MA: 36.875,
        VE_MA: 0.64002,
        QA_01: 77.762,
        VA_01: 0.81326,
        QC_01: 62.916,
        VC_01: 0.7488,
        QE_01: 62.916,
        VE_01: 0.7488,
        QA_02: 83.744,
        VA_02: 0.83353,
        QC_02: 72.313,
        VC_02: 0.78259,
        QE_02: 72.313,
        VE_02: 0.78259,
        QA_03: 90.604,
        VA_03: 0.85595,
        QC_03: 79.506,
        VC_03: 0.80708,
        QE_03: 79.506,
        VE_03: 0.80708,
        QA_04: 49.315,
        VA_04: 0.70481,
        QC_04: 47.232,
        VC_04: 0.6866,
        QE_04: 47.232,
        VE_04: 0.6866,
        QA_05: 27.684,
        VA_05: 0.60014,
        QC_05: 27.766,
        VC_05: 0.59364,
        QE_05: 27.766,
        VE_05: 0.59364,
        QA_06: 18.065,
        VA_06: 0.54097,
        QC_06: 23.167,
        VC_06: 0.56749,
        QE_06: 23.167,
        VE_06: 0.56749,
        QA_07: 12.903,
        VA_07: 0.50291,
        QC_07: 20.246,
        VC_07: 0.54959,
        QE_07: 20.246,
        VE_07: 0.54959,
        QA_08: 10.323,
        VA_08: 0.48118,
        QC_08: 22.438,
        VC_08: 0.56313,
        QE_08: 22.438,
        VE_08: 0.56313,
        QA_09: 7.742,
        VA_09: 0.4567,
        QC_09: 23.83,
        VC_09: 0.5714,
        QE_09: 23.83,
        VE_09: 0.5714,
        QA_10: 6.452,
        VA_10: 0.44302,
        QC_10: 24.387,
        VC_10: 0.57465,
        QE_10: 24.387,
        VE_10: 0.57465,
        QA_11: 5.161,
        VA_11: 0.42797,
        QC_11: 13.366,
        VC_11: 0.50186,
        QE_11: 13.366,
        VE_11: 0.50186,
        QA_12: 29.953,
        VA_12: 0.6126,
        QC_12: 29.141,
        VC_12: 0.60105,
        QE_12: 29.141,
        VE_12: 0.60105,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.896940584871629, 34.400979879936131, 0.0],
          [-80.894813584875067, 34.405401279929265, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283986.0,
        COMID: 9754820,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.348,
        REACHCODE: "03050101000400",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014039279998254,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061720.0,
        ToNode: 250061718.0,
        Hydroseq: 250022138.0,
        LevelPathI: 250003258.0,
        Pathlength: 693.638,
        TerminalPa: 250002604.0,
        ArbolateSu: 278.237,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250022605.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021701.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6822,
        TotDASqKM: 299.4453,
        DivDASqKM: 299.4453,
        Tidal: 0,
        TOTMA: 0.040676723045099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38271.0,
        MAXELEVSMO: 38599.0,
        MINELEVSMO: 38451.0,
        SLOPE: 0.00109792,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.348000049591064,
        QA_MA: 197.873,
        VA_MA: 1.25837,
        QC_MA: 195.182,
        VC_MA: 1.25732,
        QE_MA: 197.925,
        VE_MA: 1.25839,
        QA_01: 312.471,
        VA_01: 1.51921,
        QC_01: 243.764,
        VC_01: 1.37619,
        QE_01: 245.917,
        VE_01: 1.37448,
        QA_02: 328.925,
        VA_02: 1.55266,
        QC_02: 273.63,
        VC_02: 1.44378,
        QE_02: 277.999,
        VE_02: 1.4462,
        QA_03: 370.819,
        VA_03: 1.63444,
        QC_03: 310.607,
        VC_03: 1.52283,
        QE_03: 311.869,
        VE_03: 1.51782,
        QA_04: 279.201,
        VA_04: 1.44895,
        QC_04: 257.074,
        VC_04: 1.40677,
        QE_04: 260.981,
        VE_04: 1.40867,
        QA_05: 223.058,
        VA_05: 1.32077,
        QC_05: 206.919,
        VC_05: 1.28721,
        QE_05: 208.935,
        VE_05: 1.2861,
        QA_06: 130.45,
        VA_06: 1.06983,
        QC_06: 162.816,
        VC_06: 1.17022,
        QE_06: 165.683,
        VE_06: 1.1728,
        QA_07: 75.475,
        VA_07: 0.87783,
        QC_07: 113.527,
        VC_07: 1.02005,
        QE_07: 117.215,
        VE_07: 1.0277,
        QA_08: 51.937,
        VA_08: 0.77532,
        QC_08: 116.605,
        VC_08: 1.03023,
        QE_08: 123.101,
        VE_08: 1.04666,
        QA_09: 61.423,
        VA_09: 0.81882,
        QC_09: 148.345,
        VC_09: 1.12866,
        QE_09: 148.696,
        VE_09: 1.12455,
        QA_10: 92.701,
        VA_10: 0.94351,
        QC_10: 204.991,
        VC_10: 1.28235,
        QE_10: 201.954,
        VE_10: 1.26861,
        QA_11: 198.525,
        VA_11: 1.26004,
        QC_11: 216.177,
        VC_11: 1.31022,
        QE_11: 213.743,
        VE_11: 1.29798,
        QA_12: 251.06,
        VA_12: 1.38638,
        QC_12: 201.648,
        VC_12: 1.27389,
        QE_12: 202.055,
        VE_12: 1.26887,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.091581783017432, 35.670090877966175, 0.0],
          [-82.088886516354933, 35.673319077961139, 0.0],
          [-82.080242383035056, 35.674186877959983, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289000.0,
        COMID: 9715193,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 1.009,
        REACHCODE: "03050104000209",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01050778228373,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057354.0,
        ToNode: 250057342.0,
        Hydroseq: 250020569.0,
        LevelPathI: 250018611.0,
        Pathlength: 358.233,
        TerminalPa: 250002604.0,
        ArbolateSu: 104.122,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250020938.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020214.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5247,
        TotDASqKM: 83.3913,
        DivDASqKM: 83.3913,
        Tidal: 0,
        TOTMA: 0.038259343760200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6851.0,
        MAXELEVSMO: 7007.0,
        MINELEVSMO: 6880.0,
        SLOPE: 0.00125867,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0089999437332151,
        QA_MA: 30.413,
        VA_MA: 0.99813,
        QC_MA: 32.26,
        VC_MA: 1.00144,
        QE_MA: 32.26,
        VE_MA: 1.00144,
        QA_01: 67.656,
        VA_01: 1.36296,
        QC_01: 54.939,
        VC_01: 1.22786,
        QE_01: 54.939,
        VE_01: 1.22786,
        QA_02: 72.923,
        VA_02: 1.40578,
        QC_02: 63.207,
        VC_02: 1.29892,
        QE_02: 63.207,
        VE_02: 1.29892,
        QA_03: 78.898,
        VA_03: 1.45265,
        QC_03: 69.551,
        VC_03: 1.35052,
        QE_03: 69.551,
        VE_03: 1.35052,
        QA_04: 42.911,
        VA_04: 1.13648,
        QC_04: 41.228,
        VC_04: 1.09785,
        QE_04: 41.228,
        VE_04: 1.09785,
        QA_05: 24.132,
        VA_05: 0.9184,
        QC_05: 24.328,
        VC_05: 0.90499,
        QE_05: 24.328,
        VE_05: 0.90499,
        QA_06: 15.697,
        VA_06: 0.79386,
        QC_06: 20.17,
        VC_06: 0.84847,
        QE_06: 20.17,
        VE_06: 0.84847,
        QA_07: 11.212,
        VA_07: 0.71442,
        QC_07: 17.652,
        VC_07: 0.81155,
        QE_07: 17.652,
        VE_07: 0.81155,
        QA_08: 8.97,
        VA_08: 0.66906,
        QC_08: 19.442,
        VC_08: 0.83803,
        QE_08: 19.442,
        VE_08: 0.83803,
        QA_09: 6.727,
        VA_09: 0.61796,
        QC_09: 21.051,
        VC_09: 0.86087,
        QE_09: 21.051,
        VE_09: 0.86087,
        QA_10: 5.606,
        VA_10: 0.5894,
        QC_10: 21.799,
        VC_10: 0.87121,
        QE_10: 21.799,
        VE_10: 0.87121,
        QA_11: 4.485,
        VA_11: 0.558,
        QC_11: 12.008,
        VC_11: 0.71846,
        QE_11: 12.008,
        VE_11: 0.71846,
        QA_12: 26.005,
        VA_12: 0.9431,
        QC_12: 25.625,
        VC_12: 0.92167,
        QE_12: 25.625,
        VE_12: 0.92167,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.905340584858664, 34.397748079941209, 0.0],
          [-80.903406318195039, 34.398206679940472, 0.0],
          [-80.90301998486234, 34.399970679937724, 0.0],
          [-80.896940584871629, 34.400979879936131, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289001.0,
        COMID: 9715195,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.25,
        REACHCODE: "03050104000210",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0025410079638379999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057355.0,
        ToNode: 250057354.0,
        Hydroseq: 250020938.0,
        LevelPathI: 250018611.0,
        Pathlength: 359.242,
        TerminalPa: 250002604.0,
        ArbolateSu: 101.566,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250021323.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020569.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0576,
        TotDASqKM: 81.5337,
        DivDASqKM: 81.5337,
        Tidal: 0,
        TOTMA: 0.0094954502043499995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7007.0,
        MAXELEVSMO: 7039.0,
        MINELEVSMO: 7007.0,
        SLOPE: 0.00128,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25,
        QA_MA: 29.745,
        VA_MA: 0.99642,
        QC_MA: 31.579,
        VC_MA: 0.99976,
        QE_MA: 31.579,
        VE_MA: 0.99976,
        QA_01: 66.182,
        VA_01: 1.36045,
        QC_01: 53.773,
        VC_01: 1.22559,
        QE_01: 53.773,
        VE_01: 1.22559,
        QA_02: 71.324,
        VA_02: 1.40309,
        QC_02: 61.859,
        VC_02: 1.29642,
        QE_02: 61.859,
        VE_02: 1.29642,
        QA_03: 77.174,
        VA_03: 1.44989,
        QC_03: 68.08,
        VC_03: 1.34799,
        QE_03: 68.08,
        VE_03: 1.34799,
        QA_04: 41.961,
        VA_04: 1.13435,
        QC_04: 40.337,
        VC_04: 1.09573,
        QE_04: 40.337,
        VE_04: 1.09573,
        QA_05: 23.608,
        VA_05: 0.91697,
        QC_05: 23.819,
        VC_05: 0.90361,
        QE_05: 23.819,
        VE_05: 0.90361,
        QA_06: 15.347,
        VA_06: 0.79257,
        QC_06: 19.726,
        VC_06: 0.84691,
        QE_06: 19.726,
        VE_06: 0.84691,
        QA_07: 10.962,
        VA_07: 0.71335,
        QC_07: 17.268,
        VC_07: 0.81016,
        QE_07: 17.268,
        VE_07: 0.81016,
        QA_08: 8.77,
        VA_08: 0.6681,
        QC_08: 19.001,
        VC_08: 0.8363,
        QE_08: 19.001,
        VE_08: 0.8363,
        QA_09: 6.577,
        VA_09: 0.61713,
        QC_09: 20.636,
        VC_09: 0.85996,
        QE_09: 20.636,
        VE_09: 0.85996,
        QA_10: 5.481,
        VA_10: 0.58865,
        QC_10: 21.41,
        VC_10: 0.87085,
        QE_10: 21.41,
        VE_10: 0.87085,
        QA_11: 4.385,
        VA_11: 0.55734,
        QC_11: 11.804,
        VC_11: 0.71839,
        QE_11: 11.804,
        VE_11: 0.71839,
        QA_12: 25.434,
        VA_12: 0.94153,
        QC_12: 25.113,
        VC_12: 0.92057,
        QE_12: 25.113,
        VE_12: 0.92057,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.90741371818882, 34.399122013272404, 0.0],
          [-80.905340584858664, 34.397748079941209, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291075.0,
        COMID: 9718287,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 0.279,
        REACHCODE: "03050104002369",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9713751,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.002869343726165,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058171.0,
        ToNode: 250058480.0,
        Hydroseq: 250015299.0,
        LevelPathI: 250014857.0,
        Pathlength: 360.816,
        TerminalPa: 250002604.0,
        ArbolateSu: 228.663,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015464.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015146.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1764,
        TotDASqKM: 360.4347,
        DivDASqKM: 360.4347,
        Tidal: 0,
        TOTMA: 0.854630378,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6769.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.279000014066696,
        QA_MA: 131.437,
        VA_MA: -9998.0,
        QC_MA: 131.724,
        VC_MA: -9998.0,
        QE_MA: 131.724,
        VE_MA: -9998.0,
        QA_01: 288.497,
        VA_01: -9998.0,
        QC_01: 225.533,
        VC_01: -9998.0,
        QE_01: 225.533,
        VE_01: -9998.0,
        QA_02: 316.747,
        VA_02: -9998.0,
        QC_02: 263.77,
        VC_02: -9998.0,
        QE_02: 263.77,
        VE_02: -9998.0,
        QA_03: 342.756,
        VA_03: -9998.0,
        QC_03: 287.847,
        VC_03: -9998.0,
        QE_03: 287.847,
        VE_03: -9998.0,
        QA_04: 187.394,
        VA_04: -9998.0,
        QC_04: 174.113,
        VC_04: -9998.0,
        QE_04: 174.113,
        VE_04: -9998.0,
        QA_05: 105.296,
        VA_05: -9998.0,
        QC_05: 100.46,
        VC_05: -9998.0,
        QE_05: 100.46,
        VE_05: -9998.0,
        QA_06: 67.85,
        VA_06: -9998.0,
        QC_06: 85.448,
        VC_06: -9998.0,
        QE_06: 85.448,
        VE_06: -9998.0,
        QA_07: 48.461,
        VA_07: -9998.0,
        QC_07: 73.67,
        VC_07: -9998.0,
        QE_07: 73.67,
        VE_07: -9998.0,
        QA_08: 38.769,
        VA_08: -9998.0,
        QC_08: 86.533,
        VC_08: -9998.0,
        QE_08: 86.533,
        VE_08: -9998.0,
        QA_09: 29.077,
        VA_09: -9998.0,
        QC_09: 76.652,
        VC_09: -9998.0,
        QE_09: 76.652,
        VE_09: -9998.0,
        QA_10: 24.231,
        VA_10: -9998.0,
        QC_10: 70.185,
        VC_10: -9998.0,
        QE_10: 70.185,
        VE_10: -9998.0,
        QA_11: 19.385,
        VA_11: -9998.0,
        QC_11: 36.668,
        VC_11: -9998.0,
        QE_11: 36.668,
        VE_11: -9998.0,
        QA_12: 109.57,
        VA_12: -9998.0,
        QC_12: 94.837,
        VC_12: -9998.0,
        QE_12: 94.837,
        VE_12: -9998.0,
        LakeFract: 0.10572736641,
        SurfArea: 128730.93483300001,
        RAreaHLoad: 0.4006906089,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.919972584835989, 34.467903613165561, 0.0],
          [-80.917538918173136, 34.469423613163265, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283900.0,
        COMID: 9753070,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.541,
        REACHCODE: "03050101000317",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.025642019241247,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061219.0,
        ToNode: 250061233.0,
        Hydroseq: 250014704.0,
        LevelPathI: 250003258.0,
        Pathlength: 661.019,
        TerminalPa: 250002604.0,
        ArbolateSu: 909.31,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014852.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014567.0,
        FromMeas: 0.0,
        ToMeas: 97.47083,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.9141,
        TotDASqKM: 1004.8158,
        DivDASqKM: 1004.8158,
        Tidal: 0,
        TOTMA: 0.046947601669300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32634.0,
        MAXELEVSMO: 34784.0,
        MINELEVSMO: 32740.0,
        SLOPE: 0.00804407,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5409998893737789,
        QA_MA: 678.216,
        VA_MA: 2.03334,
        QC_MA: 637.765,
        VC_MA: 2.02477,
        QE_MA: 792.492,
        VE_MA: 2.05524,
        QA_01: 1023.393,
        VA_01: 2.4545,
        QC_01: 773.929,
        VC_01: 2.21051,
        QE_01: 887.99,
        VE_01: 2.16403,
        QA_02: 1087.863,
        VA_02: 2.52526,
        QC_02: 875.955,
        VC_02: 2.33979,
        QE_02: 934.574,
        VE_02: 2.2151,
        QA_03: 1269.201,
        VA_03: 2.71438,
        QC_03: 1020.798,
        VC_03: 2.51175,
        QE_03: 1219.782,
        VE_03: 2.50475,
        QA_04: 970.782,
        VA_04: 2.39519,
        QC_04: 868.882,
        VC_04: 2.33107,
        QE_04: 1035.934,
        VE_04: 2.32225,
        QA_05: 784.361,
        VA_05: 2.1718,
        QC_05: 694.177,
        VC_05: 2.10378,
        QE_05: 904.89,
        VE_05: 2.1827,
        QA_06: 464.069,
        VA_06: 1.71857,
        QC_06: 569.205,
        VC_06: 1.92419,
        QE_06: 785.924,
        VE_06: 2.04753,
        QA_07: 273.336,
        VA_07: 1.37304,
        QC_07: 398.678,
        VC_07: 1.64582,
        QE_07: 494.579,
        VE_07: 1.66836,
        QA_08: 203.619,
        VA_08: 1.21894,
        QC_08: 469.83,
        VC_08: 1.76767,
        QE_08: 543.788,
        VE_08: 1.73861,
        QA_09: 231.672,
        VA_09: 1.28354,
        QC_09: 478.961,
        VC_09: 1.78266,
        QE_09: 520.808,
        VE_09: 1.70619,
        QA_10: 307.651,
        VA_10: 1.44204,
        QC_10: 534.444,
        VC_10: 1.87102,
        QE_10: 512.13,
        VE_10: 1.69377,
        QA_11: 682.025,
        VA_11: 2.03848,
        QC_11: 554.075,
        VC_11: 1.90124,
        QE_11: 635.329,
        VE_11: 1.86178,
        QA_12: 844.973,
        VA_12: 2.24693,
        QC_12: 608.326,
        VC_12: 1.98223,
        QE_12: 704.558,
        VE_12: 1.94948,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.888648783332428, 35.734243477866642, 0.0],
          [-81.880531516678388, 35.732275077869645, 0.0],
          [-81.869353183362364, 35.719543011222811, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289002.0,
        COMID: 9715179,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.776,
        REACHCODE: "03050104000211",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00836924696339,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057349.0,
        ToNode: 250057355.0,
        Hydroseq: 250021323.0,
        LevelPathI: 250018611.0,
        Pathlength: 359.492,
        TerminalPa: 250002604.0,
        ArbolateSu: 97.374,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250021727.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020938.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3456,
        TotDASqKM: 78.8553,
        DivDASqKM: 78.8553,
        Tidal: 0,
        TOTMA: 0.027355760532499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6998.0,
        MAXELEVSMO: 7242.0,
        MINELEVSMO: 7039.0,
        SLOPE: 0.00261597,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.77600002288818404,
        QA_MA: 28.782,
        VA_MA: 1.07337,
        QC_MA: 30.596,
        VC_MA: 1.07717,
        QE_MA: 30.596,
        VE_MA: 1.07717,
        QA_01: 64.057,
        VA_01: 1.47828,
        QC_01: 52.091,
        VC_01: 1.32819,
        QE_01: 52.091,
        VE_01: 1.32819,
        QA_02: 69.02,
        VA_02: 1.52557,
        QC_02: 59.913,
        VC_02: 1.40683,
        QE_02: 59.913,
        VE_02: 1.40683,
        QA_03: 74.684,
        VA_03: 1.57763,
        QC_03: 65.955,
        VC_03: 1.46433,
        QE_03: 65.955,
        VE_03: 1.46433,
        QA_04: 40.593,
        VA_04: 1.22661,
        QC_04: 39.051,
        VC_04: 1.18352,
        QE_04: 39.051,
        VE_04: 1.18352,
        QA_05: 22.851,
        VA_05: 0.98515,
        QC_05: 23.084,
        VC_05: 0.97035,
        QE_05: 23.084,
        VE_05: 0.97035,
        QA_06: 14.843,
        VA_06: 0.84658,
        QC_06: 19.087,
        VC_06: 0.90678,
        QE_06: 19.087,
        VE_06: 0.90678,
        QA_07: 10.602,
        VA_07: 0.75852,
        QC_07: 16.714,
        VC_07: 0.86605,
        QE_07: 16.714,
        VE_07: 0.86605,
        QA_08: 8.482,
        VA_08: 0.70823,
        QC_08: 18.364,
        VC_08: 0.89463,
        QE_08: 18.364,
        VE_08: 0.89463,
        QA_09: 6.361,
        VA_09: 0.65158,
        QC_09: 20.036,
        VC_09: 0.9224,
        QE_09: 20.036,
        VE_09: 0.9224,
        QA_10: 5.301,
        VA_10: 0.61991,
        QC_10: 20.846,
        VC_10: 0.93546,
        QE_10: 20.846,
        VE_10: 0.93546,
        QA_11: 4.241,
        VA_11: 0.58511,
        QC_11: 11.507,
        VC_11: 0.76578,
        QE_11: 11.507,
        VE_11: 0.76578,
        QA_12: 24.611,
        VA_12: 1.01235,
        QC_12: 24.372,
        VC_12: 0.98971,
        QE_12: 24.372,
        VE_12: 0.98971,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.915483318176257, 34.399509013271768, 0.0],
          [-80.90741371818882, 34.399122013272404, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291076.0,
        COMID: 9718289,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 0.41,
        REACHCODE: "03050104002370",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9713751,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0041494264883230004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058172.0,
        ToNode: 250058171.0,
        Hydroseq: 250015464.0,
        LevelPathI: 250014857.0,
        Pathlength: 361.095,
        TerminalPa: 250002604.0,
        ArbolateSu: 225.588,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015621.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015299.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.117,
        TotDASqKM: 357.2712,
        DivDASqKM: 357.2712,
        Tidal: 0,
        TOTMA: 1.2559084407600001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6764.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40999999642372098,
        QA_MA: 130.324,
        VA_MA: -9998.0,
        QC_MA: 130.652,
        VC_MA: -9998.0,
        QE_MA: 130.652,
        VE_MA: -9998.0,
        QA_01: 286.061,
        VA_01: -9998.0,
        QC_01: 223.679,
        VC_01: -9998.0,
        QE_01: 223.679,
        VE_01: -9998.0,
        QA_02: 314.082,
        VA_02: -9998.0,
        QC_02: 261.612,
        VC_02: -9998.0,
        QE_02: 261.612,
        VE_02: -9998.0,
        QA_03: 339.852,
        VA_03: -9998.0,
        QC_03: 285.489,
        VC_03: -9998.0,
        QE_03: 285.489,
        VE_03: -9998.0,
        QA_04: 185.821,
        VA_04: -9998.0,
        QC_04: 172.685,
        VC_04: -9998.0,
        QE_04: 172.685,
        VE_04: -9998.0,
        QA_05: 104.404,
        VA_05: -9998.0,
        QC_05: 99.641,
        VC_05: -9998.0,
        QE_05: 99.641,
        VE_05: -9998.0,
        QA_06: 67.255,
        VA_06: -9998.0,
        QC_06: 84.709,
        VC_06: -9998.0,
        QE_06: 84.709,
        VE_06: -9998.0,
        QA_07: 48.036,
        VA_07: -9998.0,
        QC_07: 73.039,
        VC_07: -9998.0,
        QE_07: 73.039,
        VE_07: -9998.0,
        QA_08: 38.429,
        VA_08: -9998.0,
        QC_08: 85.759,
        VC_08: -9998.0,
        QE_08: 85.759,
        VE_08: -9998.0,
        QA_09: 28.821,
        VA_09: -9998.0,
        QC_09: 76.058,
        VC_09: -9998.0,
        QE_09: 76.058,
        VE_09: -9998.0,
        QA_10: 24.018,
        VA_10: -9998.0,
        QC_10: 69.693,
        VC_10: -9998.0,
        QE_10: 69.693,
        VE_10: -9998.0,
        QA_11: 19.215,
        VA_11: -9998.0,
        QC_11: 36.423,
        VC_11: -9998.0,
        QE_11: 36.423,
        VE_11: -9998.0,
        QA_12: 108.665,
        VA_12: -9998.0,
        QC_12: 94.124,
        VC_12: -9998.0,
        QE_12: 94.124,
        VE_12: -9998.0,
        LakeFract: 0.1541122391,
        SurfArea: 187643.11722,
        RAreaHLoad: 0.58882849334999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.923277984830861, 34.470168613162059, 0.0],
          [-80.919972584835989, 34.467903613165561, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283901.0,
        COMID: 9753828,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 1.095,
        REACHCODE: "03050101000319",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011499135284860001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061416.0,
        ToNode: 250061559.0,
        Hydroseq: 250021301.0,
        LevelPathI: 250019506.0,
        Pathlength: 677.101,
        TerminalPa: 250002604.0,
        ArbolateSu: 142.476,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250021702.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020919.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3383,
        TotDASqKM: 175.0644,
        DivDASqKM: 175.0644,
        Tidal: 0,
        TOTMA: 0.029192737191699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36449.0,
        MAXELEVSMO: 37065.0,
        MINELEVSMO: 36464.0,
        SLOPE: 0.00548858,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.095000028610229,
        QA_MA: 147.588,
        VA_MA: 1.41463,
        QC_MA: 147.246,
        VC_MA: 1.41442,
        QE_MA: 164.379,
        VE_MA: 1.42433,
        QA_01: 174.57,
        VA_01: 1.51779,
        QC_01: 138.279,
        VC_01: 1.37813,
        QE_01: 194.954,
        VE_01: 1.53013,
        QA_02: 193.406,
        VA_02: 1.58541,
        QC_02: 163.239,
        VC_02: 1.47667,
        QE_02: 210.881,
        VE_02: 1.58215,
        QA_03: 249.846,
        VA_03: 1.7714,
        QC_03: 212.023,
        VC_03: 1.65068,
        QE_03: 268.347,
        VE_03: 1.75604,
        QA_04: 206.453,
        VA_04: 1.63045,
        QC_04: 191.4,
        VC_04: 1.57969,
        QE_04: 219.681,
        VE_04: 1.61011,
        QA_05: 170.871,
        VA_05: 1.50411,
        QC_05: 160.097,
        VC_05: 1.46467,
        QE_05: 178.56,
        VE_05: 1.47445,
        QA_06: 119.967,
        VA_06: 1.29937,
        QC_06: 149.905,
        VC_06: 1.42498,
        QE_06: 169.538,
        VE_06: 1.44279,
        QA_07: 72.684,
        VA_07: 1.06785,
        QC_07: 109.427,
        VC_07: 1.25313,
        QE_07: 93.751,
        VE_07: 1.13661,
        QA_08: 66.478,
        VA_08: 1.0327,
        QC_08: 149.991,
        VC_08: 1.42532,
        QE_08: 102.293,
        VE_08: 1.17586,
        QA_09: 87.372,
        VA_09: 1.14583,
        QC_09: 202.486,
        VC_09: 1.61827,
        QE_09: 109.824,
        VE_09: 1.2092,
        QA_10: 102.437,
        VA_10: 1.21964,
        QC_10: 222.015,
        VC_10: 1.6839,
        QE_10: 122.767,
        VE_10: 1.2641,
        QA_11: 158.223,
        VA_11: 1.45627,
        QC_11: 181.826,
        VC_11: 1.54551,
        QE_11: 159.394,
        VE_11: 1.40623,
        QA_12: 169.693,
        VA_12: 1.49973,
        QC_12: 141.195,
        VC_12: 1.39005,
        QE_12: 146.997,
        VE_12: 1.36003,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.893320183325159, 35.796712811103021, 0.0],
          [-81.883312916674072, 35.791905077777074, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291077.0,
        COMID: 9718295,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 0.57,
        REACHCODE: "03050104002371",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9713751,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0057067098505829998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058175.0,
        ToNode: 250058172.0,
        Hydroseq: 250015621.0,
        LevelPathI: 250014857.0,
        Pathlength: 361.505,
        TerminalPa: 250002604.0,
        ArbolateSu: 209.971,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250015783.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015464.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4563,
        TotDASqKM: 345.9177,
        DivDASqKM: 345.9177,
        Tidal: 0,
        TOTMA: 1.7460190518000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6772.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.56999999284744296,
        QA_MA: 126.438,
        VA_MA: -9998.0,
        QC_MA: 126.905,
        VC_MA: -9998.0,
        QE_MA: 126.905,
        VE_MA: -9998.0,
        QA_01: 277.581,
        VA_01: -9998.0,
        QC_01: 217.22,
        VC_01: -9998.0,
        QE_01: 217.22,
        VE_01: -9998.0,
        QA_02: 304.717,
        VA_02: -9998.0,
        QC_02: 254.02,
        VC_02: -9998.0,
        QE_02: 254.02,
        VE_02: -9998.0,
        QA_03: 329.588,
        VA_03: -9998.0,
        QC_03: 277.147,
        VC_03: -9998.0,
        QE_03: 277.147,
        VE_03: -9998.0,
        QA_04: 180.338,
        VA_04: -9998.0,
        QC_04: 167.704,
        VC_04: -9998.0,
        QE_04: 167.704,
        VE_04: -9998.0,
        QA_05: 101.345,
        VA_05: -9998.0,
        QC_05: 96.828,
        VC_05: -9998.0,
        QE_05: 96.828,
        VE_05: -9998.0,
        QA_06: 65.258,
        VA_06: -9998.0,
        QC_06: 82.228,
        VC_06: -9998.0,
        QE_06: 82.228,
        VE_06: -9998.0,
        QA_07: 46.509,
        VA_07: -9998.0,
        QC_07: 70.773,
        VC_07: -9998.0,
        QE_07: 70.773,
        VE_07: -9998.0,
        QA_08: 37.207,
        VA_08: -9998.0,
        QC_08: 82.98,
        VC_08: -9998.0,
        QE_08: 82.98,
        VE_08: -9998.0,
        QA_09: 27.906,
        VA_09: -9998.0,
        QC_09: 73.92,
        VC_09: -9998.0,
        QE_09: 73.92,
        VE_09: -9998.0,
        QA_10: 23.255,
        VA_10: -9998.0,
        QC_10: 67.918,
        VC_10: -9998.0,
        QE_10: 67.918,
        VE_10: -9998.0,
        QA_11: 18.604,
        VA_11: -9998.0,
        QC_11: 35.537,
        VC_11: -9998.0,
        QE_11: 35.537,
        VE_11: -9998.0,
        QA_12: 105.663,
        VA_12: -9998.0,
        QC_12: 91.755,
        VC_12: -9998.0,
        QE_12: 91.755,
        VE_12: -9998.0,
        LakeFract: 0.20813780296000001,
        SurfArea: 253423.260779,
        RAreaHLoad: 0.81861522246999996,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.927392718157819, 34.467037213166918, 0.0],
          [-80.923277984830861, 34.470168613162059, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283902.0,
        COMID: 9752658,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 1.289,
        REACHCODE: "03050101000320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012825061536638,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061107.0,
        ToNode: 250061416.0,
        Hydroseq: 250021702.0,
        LevelPathI: 250019506.0,
        Pathlength: 678.196,
        TerminalPa: 250002604.0,
        ArbolateSu: 131.114,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250022141.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021301.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5326,
        TotDASqKM: 163.7982,
        DivDASqKM: 163.7982,
        Tidal: 0,
        TOTMA: 0.035089570300199997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36857.0,
        MAXELEVSMO: 37750.0,
        MINELEVSMO: 37065.0,
        SLOPE: 0.00531419,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2890000343322749,
        QA_MA: 140.794,
        VA_MA: 1.39398,
        QC_MA: 140.725,
        VC_MA: 1.39393,
        QE_MA: 142.296,
        VE_MA: 1.39491,
        QA_01: 163.229,
        VA_01: 1.48264,
        QC_01: 129.523,
        VC_01: 1.34716,
        QE_01: 134.721,
        VE_01: 1.3638,
        QA_02: 181.6,
        VA_02: 1.55106,
        QC_02: 153.538,
        VC_02: 1.44534,
        QE_02: 157.907,
        VE_02: 1.45666,
        QA_03: 236.527,
        VA_03: 1.73826,
        QC_03: 201.083,
        VC_03: 1.62045,
        QE_03: 206.248,
        VE_03: 1.63161,
        QA_04: 196.424,
        VA_04: 1.60393,
        QC_04: 182.308,
        VC_04: 1.55391,
        QE_04: 184.902,
        VE_04: 1.55703,
        QA_05: 162.885,
        VA_05: 1.48133,
        QC_05: 152.888,
        VC_05: 1.44278,
        QE_05: 154.581,
        VE_05: 1.44375,
        QA_06: 115.644,
        VA_06: 1.28627,
        QC_06: 144.575,
        VC_06: 1.4096,
        QE_06: 146.376,
        VE_06: 1.41134,
        QA_07: 70.112,
        VA_07: 1.05808,
        QC_07: 105.647,
        VC_07: 1.24064,
        QE_07: 104.209,
        VE_07: 1.22926,
        QA_08: 64.771,
        VA_08: 1.02719,
        QC_08: 146.063,
        VC_08: 1.41561,
        QE_08: 141.689,
        VE_08: 1.39245,
        QA_09: 86.133,
        VA_09: 1.14465,
        QC_09: 199.949,
        VC_09: 1.61652,
        QE_09: 191.452,
        VE_09: 1.58033,
        QA_10: 100.744,
        VA_10: 1.21721,
        QC_10: 219.079,
        VC_10: 1.68155,
        QE_10: 209.978,
        VE_10: 1.64427,
        QA_11: 151.498,
        VA_11: 1.43705,
        QC_11: 175.902,
        VC_11: 1.53047,
        QE_11: 173.845,
        VE_11: 1.51681,
        QA_12: 160.841,
        VA_12: 1.47349,
        QC_12: 134.478,
        VC_12: 1.36807,
        QE_12: 135.01,
        VE_12: 1.365,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.899619383315382, 35.804612077757497, 0.0],
          [-81.893349783325107, 35.802277611094496, 0.0],
          [-81.893320183325159, 35.796712811103021, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283895.0,
        COMID: 9752994,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.245,
        REACHCODE: "03050101000313",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002608681950721,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061195.0,
        ToNode: 250061198.0,
        Hydroseq: 250014160.0,
        LevelPathI: 250003258.0,
        Pathlength: 654.721,
        TerminalPa: 250002604.0,
        ArbolateSu: 1153.812,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014293.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014034.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 1287.9648,
        DivDASqKM: 1287.9648,
        Tidal: 0,
        TOTMA: 0.0098608399417399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32026.0,
        MAXELEVSMO: 32075.0,
        MINELEVSMO: 32075.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.245000004768372,
        QA_MA: 857.007,
        VA_MA: 0.938,
        QC_MA: 798.607,
        VC_MA: 0.93441,
        QE_MA: 953.335,
        VE_MA: 0.94346,
        QA_01: 1317.24,
        VA_01: 1.09944,
        QC_01: 989.578,
        VC_01: 1.00991,
        QE_01: 1103.64,
        VE_01: 0.9948,
        QA_02: 1398.605,
        VA_02: 1.12502,
        QC_02: 1118.481,
        VC_02: 1.05704,
        QE_02: 1177.1,
        VE_02: 1.0187,
        QA_03: 1613.197,
        VA_03: 1.18932,
        QC_03: 1287.238,
        VC_03: 1.11504,
        QE_03: 1486.221,
        VE_03: 1.11232,
        QA_04: 1229.85,
        VA_04: 1.07112,
        QC_04: 1094.855,
        VC_04: 1.0486,
        QE_04: 1261.907,
        VE_04: 1.04543,
        QA_05: 985.982,
        VA_05: 0.98666,
        QC_05: 985.982,
        VC_05: 1.00855,
        QE_05: 1196.695,
        VE_05: 1.02495,
        QA_06: 575.852,
        VA_06: 0.81817,
        QC_06: 575.852,
        VC_06: 0.83462,
        QE_06: 792.571,
        VE_06: 0.88414,
        QA_07: 338.503,
        VA_07: 0.69286,
        QC_07: 491.21,
        VC_07: 0.79202,
        QE_07: 588.278,
        VE_07: 0.79985,
        QA_08: 247.21,
        VA_08: 0.63376,
        QC_08: 572.632,
        VC_08: 0.83306,
        QE_08: 648.932,
        VE_08: 0.82614,
        QA_09: 264.558,
        VA_09: 0.64569,
        QC_09: 538.512,
        VC_09: 0.81622,
        QE_09: 580.882,
        VE_09: 0.79656,
        QA_10: 367.978,
        VA_10: 0.71028,
        QC_10: 616.626,
        VC_10: 0.85409,
        QE_10: 594.225,
        VE_10: 0.80248,
        QA_11: 864.833,
        VA_11: 0.94105,
        QC_11: 664.079,
        VC_11: 0.876,
        QE_11: 745.04,
        VE_11: 0.86554,
        QA_12: 1085.813,
        VA_12: 1.02231,
        QC_12: 764.237,
        VC_12: 0.91996,
        QE_12: 860.663,
        VE_12: 0.90989,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.832616583419394, 35.732362077869595, 0.0],
          [-81.830257783423065, 35.731376811204541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283896.0,
        COMID: 9753032,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.877,
        REACHCODE: "03050101000314",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018376346251242001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061210.0,
        ToNode: 250061195.0,
        Hydroseq: 250014293.0,
        LevelPathI: 250003258.0,
        Pathlength: 654.966,
        TerminalPa: 250002604.0,
        ArbolateSu: 1153.567,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014427.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250014159.0,
        DnDrainCou: 2,
        DnHydroseq: 250014160.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5254,
        TotDASqKM: 1287.9441,
        DivDASqKM: 1287.9441,
        Tidal: 0,
        TOTMA: 0.042886721531499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32022.0,
        MAXELEVSMO: 32294.0,
        MINELEVSMO: 32075.0,
        SLOPE: 0.00116675,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.876999974250793,
        QA_MA: 856.996,
        VA_MA: 1.6503,
        QC_MA: 798.597,
        VC_MA: 1.64265,
        QE_MA: 953.325,
        VE_MA: 1.66193,
        QA_01: 1317.22,
        VA_01: 1.99436,
        QC_01: 989.564,
        VC_01: 1.80354,
        QE_01: 1103.625,
        VE_01: 1.77135,
        QA_02: 1398.584,
        VA_02: 2.04888,
        QC_02: 1118.464,
        VC_02: 1.90398,
        QE_02: 1177.083,
        VE_02: 1.82227,
        QA_03: 1613.174,
        VA_03: 2.18593,
        QC_03: 1287.22,
        VC_03: 2.0276,
        QE_03: 1486.203,
        VE_03: 2.02182,
        QA_04: 1229.833,
        VA_04: 1.93401,
        QC_04: 1094.84,
        VC_04: 1.886,
        QE_04: 1261.892,
        VE_04: 1.87925,
        QA_05: 985.969,
        VA_05: 1.75401,
        QC_05: 985.969,
        VC_05: 1.80065,
        QE_05: 1196.682,
        VE_05: 1.8356,
        QA_06: 575.845,
        VA_06: 1.3949,
        QC_06: 575.845,
        VC_06: 1.42996,
        QE_06: 792.564,
        VE_06: 1.53549,
        QA_07: 338.499,
        VA_07: 1.12781,
        QC_07: 491.204,
        VC_07: 1.33917,
        QE_07: 588.272,
        VE_07: 1.35586,
        QA_08: 247.207,
        VA_08: 1.00186,
        QC_08: 572.625,
        VC_08: 1.42663,
        QE_08: 648.925,
        VE_08: 1.41188,
        QA_09: 264.555,
        VA_09: 1.02729,
        QC_09: 538.508,
        VC_09: 1.39074,
        QE_09: 580.878,
        VE_09: 1.34884,
        QA_10: 367.976,
        VA_10: 1.16496,
        QC_10: 616.624,
        VC_10: 1.47146,
        QE_10: 594.223,
        VE_10: 1.36147,
        QA_11: 864.824,
        VA_11: 1.65679,
        QC_11: 664.074,
        VC_11: 1.51816,
        QE_11: 745.034,
        VE_11: 1.49585,
        QA_12: 1085.798,
        VA_12: 1.82997,
        QC_12: 764.227,
        VC_12: 1.61184,
        QE_12: 860.653,
        VE_12: 1.59039,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.834389583416623, 35.72404967788259, 0.0],
          [-81.838991983409528, 35.732707411202341, 0.0],
          [-81.83719378341226, 35.734699277865957, 0.0],
          [-81.832616583419394, 35.732362077869595, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287051.0,
        COMID: 9734586,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.144,
        REACHCODE: "03050103000324",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001507457229348,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059441.0,
        ToNode: 250059829.0,
        Hydroseq: 250024244.0,
        LevelPathI: 250015946.0,
        Pathlength: 409.349,
        TerminalPa: 250002604.0,
        ArbolateSu: 201.173,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250024868.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023655.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0261,
        TotDASqKM: 575.7282,
        DivDASqKM: 575.7282,
        Tidal: 0,
        TOTMA: 0.0067388854493099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12476.0,
        MAXELEVSMO: 12794.0,
        MINELEVSMO: 12794.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.143999993801117,
        QA_MA: 240.379,
        VA_MA: 0.81228,
        QC_MA: 235.326,
        VC_MA: 0.81142,
        QE_MA: 235.326,
        VE_MA: 0.81142,
        QA_01: 515.955,
        VA_01: 1.06432,
        QC_01: 397.251,
        VC_01: 0.97265,
        QE_01: 397.251,
        VE_01: 0.97265,
        QA_02: 540.975,
        VA_02: 1.08357,
        QC_02: 443.971,
        VC_02: 1.01306,
        QE_02: 443.971,
        VE_02: 1.01306,
        QA_03: 581.676,
        VA_03: 1.11401,
        QC_03: 480.038,
        VC_03: 1.0429,
        QE_03: 480.038,
        VE_03: 1.0429,
        QA_04: 345.56,
        VA_04: 0.91939,
        QC_04: 316.635,
        VC_04: 0.89728,
        QE_04: 316.635,
        VE_04: 0.89728,
        QA_05: 191.591,
        VA_05: 0.7551,
        QC_05: 178.743,
        VC_05: 0.74307,
        QE_05: 178.743,
        VE_05: 0.74307,
        QA_06: 121.478,
        VA_06: 0.6591,
        QC_06: 151.767,
        VC_06: 0.7069,
        QE_06: 151.767,
        VE_06: 0.7069,
        QA_07: 83.911,
        VA_07: 0.59655,
        QC_07: 125.896,
        VC_07: 0.66925,
        QE_07: 125.896,
        VE_07: 0.66925,
        QA_08: 61.926,
        VA_08: 0.55362,
        QC_08: 139.523,
        VC_08: 0.68949,
        QE_08: 139.523,
        VE_08: 0.68949,
        QA_09: 53.578,
        VA_09: 0.53547,
        QC_09: 131.486,
        VC_09: 0.67767,
        QE_09: 131.486,
        VE_09: 0.67767,
        QA_10: 38.777,
        VA_10: 0.49965,
        QC_10: 102.182,
        VC_10: 0.6314,
        QE_10: 102.182,
        VE_10: 0.6314,
        QA_11: 62.627,
        VA_11: 0.55509,
        QC_11: 89.679,
        VC_11: 0.60977,
        QE_11: 89.679,
        VE_11: 0.60977,
        QA_12: 288.199,
        VA_12: 0.86325,
        QC_12: 228.617,
        VC_12: 0.80375,
        QE_12: 228.617,
        VE_12: 0.80375,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.991892984724359, 34.758309012714733, 0.0],
          [-80.993224184722237, 34.757643479382473, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283897.0,
        COMID: 9753118,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.67,
        REACHCODE: "03050101000315",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016670074304124999,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061232.0,
        ToNode: 250061210.0,
        Hydroseq: 250014427.0,
        LevelPathI: 250003258.0,
        Pathlength: 656.843,
        TerminalPa: 250002604.0,
        ArbolateSu: 1143.712,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014567.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014293.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3842,
        TotDASqKM: 1276.6203,
        DivDASqKM: 1276.6203,
        Tidal: 0,
        TOTMA: 0.043384289849200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32127.0,
        MAXELEVSMO: 32366.0,
        MINELEVSMO: 32294.0,
        SLOPE: 0.00043113,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.669999957084656,
        QA_MA: 850.628,
        VA_MA: 1.45165,
        QC_MA: 792.893,
        VC_MA: 1.44516,
        QE_MA: 947.62,
        VE_MA: 1.46169,
        QA_01: 1306.058,
        VA_01: 1.74397,
        QC_01: 981.398,
        VC_01: 1.58148,
        QE_01: 1095.459,
        VE_01: 1.55398,
        QA_02: 1386.791,
        VA_02: 1.79043,
        QC_02: 1109.289,
        VC_02: 1.66704,
        QE_02: 1167.908,
        VE_02: 1.59707,
        QA_03: 1600.478,
        VA_03: 1.90761,
        QC_03: 1277.422,
        VC_03: 1.77276,
        QE_03: 1476.405,
        VE_03: 1.76787,
        QA_04: 1220.352,
        VA_04: 1.69315,
        QC_04: 1086.591,
        VC_04: 1.6522,
        QE_04: 1253.643,
        VE_04: 1.64646,
        QA_05: 978.901,
        VA_05: 1.54017,
        QC_05: 978.901,
        VC_05: 1.57976,
        QE_05: 1189.614,
        VE_05: 1.60973,
        QA_06: 571.881,
        VA_06: 1.23433,
        QC_06: 571.881,
        VC_06: 1.26408,
        QE_06: 788.6,
        VE_06: 1.35451,
        QA_07: 336.21,
        VA_07: 1.00676,
        QC_07: 487.962,
        VC_07: 1.18685,
        QE_07: 584.983,
        VE_07: 1.20109,
        QA_08: 245.615,
        VA_08: 0.89951,
        QC_08: 568.864,
        VC_08: 1.2614,
        QE_08: 645.07,
        VE_08: 1.24866,
        QA_09: 263.337,
        VA_09: 0.92179,
        QC_09: 536.318,
        VC_09: 1.23205,
        QE_09: 578.668,
        VE_09: 1.19596,
        QA_10: 367.016,
        VA_10: 1.04004,
        QC_10: 615.338,
        VC_10: 1.30198,
        QE_10: 592.941,
        VE_10: 1.20751,
        QA_11: 859.057,
        VA_11: 1.45765,
        QC_11: 660.694,
        VC_11: 1.34022,
        QE_11: 741.667,
        VE_11: 1.32097,
        QA_12: 1077.365,
        VA_12: 1.60448,
        QC_12: 758.825,
        VC_12: 1.41895,
        QE_12: 855.244,
        VE_12: 1.40056,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.846047316731813, 35.713609677898773, 0.0],
          [-81.842759783403665, 35.720021211222047, 0.0],
          [-81.834389583416623, 35.72404967788259, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287052.0,
        COMID: 9734582,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.786,
        REACHCODE: "03050103000325",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0070699254761839997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059439.0,
        ToNode: 250059441.0,
        Hydroseq: 250024868.0,
        LevelPathI: 250015946.0,
        Pathlength: 409.493,
        TerminalPa: 250002604.0,
        ArbolateSu: 199.091,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250025546.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024244.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5238,
        TotDASqKM: 573.9588,
        DivDASqKM: 573.9588,
        Tidal: 0,
        TOTMA: 0.036798958243499998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12485.0,
        MAXELEVSMO: 12794.0,
        MINELEVSMO: 12794.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.78600001335143999,
        QA_MA: 239.675,
        VA_MA: 0.81193,
        QC_MA: 234.663,
        VC_MA: 0.81107,
        QE_MA: 234.663,
        VE_MA: 0.81107,
        QA_01: 514.409,
        VA_01: 1.06377,
        QC_01: 396.092,
        VC_01: 0.97216,
        QE_01: 396.092,
        VE_01: 0.97216,
        QA_02: 539.357,
        VA_02: 1.08301,
        QC_02: 442.68,
        VC_02: 1.01254,
        QE_02: 442.68,
        VE_02: 1.01254,
        QA_03: 579.939,
        VA_03: 1.11343,
        QC_03: 478.652,
        VC_03: 1.04236,
        QE_03: 478.652,
        VE_03: 1.04236,
        QA_04: 344.548,
        VA_04: 0.91896,
        QC_04: 315.729,
        VC_04: 0.89686,
        QE_04: 315.729,
        VE_04: 0.89686,
        QA_05: 191.031,
        VA_05: 0.75479,
        QC_05: 178.24,
        VC_05: 0.74277,
        QE_05: 178.24,
        VE_05: 0.74277,
        QA_06: 121.121,
        VA_06: 0.65886,
        QC_06: 151.327,
        VC_06: 0.70661,
        QE_06: 151.327,
        VE_06: 0.70661,
        QA_07: 83.661,
        VA_07: 0.59634,
        QC_07: 125.531,
        VC_07: 0.66899,
        QE_07: 125.531,
        VE_07: 0.66899,
        QA_08: 61.736,
        VA_08: 0.55343,
        QC_08: 139.086,
        VC_08: 0.68917,
        QE_08: 139.086,
        VE_08: 0.68917,
        QA_09: 53.435,
        VA_09: 0.53534,
        QC_09: 131.177,
        VC_09: 0.67751,
        QE_09: 131.177,
        VE_09: 0.67751,
        QA_10: 38.658,
        VA_10: 0.4995,
        QC_10: 101.932,
        VC_10: 0.63124,
        QE_10: 101.932,
        VE_10: 0.63124,
        QA_11: 62.508,
        VA_11: 0.55505,
        QC_11: 89.549,
        VC_11: 0.60978,
        QE_11: 89.549,
        VE_11: 0.60978,
        QA_12: 287.387,
        VA_12: 0.86289,
        QC_12: 228.03,
        VC_12: 0.80348,
        QE_12: 228.03,
        VE_12: 0.80348,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.992874118056193, 34.765248679370814, 0.0],
          [-80.991892984724359, 34.758309012714733, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283898.0,
        COMID: 9753122,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.506,
        REACHCODE: "03050101000316",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026516890373077001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061233.0,
        ToNode: 250061232.0,
        Hydroseq: 250014567.0,
        LevelPathI: 250003258.0,
        Pathlength: 658.513,
        TerminalPa: 250002604.0,
        ArbolateSu: 923.174,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014704.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014427.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.1824,
        TotDASqKM: 1019.8962,
        DivDASqKM: 1019.8962,
        Tidal: 0,
        TOTMA: 0.057713729010700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32366.0,
        MAXELEVSMO: 32740.0,
        MINELEVSMO: 32366.0,
        SLOPE: 0.00149241,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5060000419616699,
        QA_MA: 686.964,
        VA_MA: 1.63233,
        QC_MA: 645.669,
        VC_MA: 1.6257,
        QE_MA: 800.397,
        VE_MA: 1.64882,
        QA_01: 1038.389,
        VA_01: 1.95709,
        QC_01: 784.97,
        VC_01: 1.76969,
        QE_01: 899.031,
        VE_01: 1.73414,
        QA_02: 1103.728,
        VA_02: 2.01139,
        QC_02: 888.379,
        VC_02: 1.86894,
        QE_02: 946.999,
        VE_02: 1.77404,
        QA_03: 1286.503,
        VA_03: 2.15576,
        QC_03: 1034.251,
        VC_03: 2.00015,
        QE_03: 1233.235,
        VE_03: 1.99469,
        QA_04: 983.77,
        VA_04: 1.91046,
        QC_04: 880.241,
        VC_04: 1.86133,
        QE_04: 1047.293,
        VE_04: 1.85452,
        QA_05: 794.22,
        VA_05: 1.73835,
        QC_05: 702.574,
        VC_05: 1.68612,
        QE_05: 913.287,
        VE_05: 1.7461,
        QA_06: 469.566,
        VA_06: 1.39014,
        QC_06: 575.854,
        VC_06: 1.54804,
        QE_06: 792.573,
        VE_06: 1.64185,
        QA_07: 276.554,
        VA_07: 1.12505,
        QC_07: 403.259,
        VC_07: 1.33437,
        QE_07: 499.223,
        VE_07: 1.35163,
        QA_08: 205.839,
        VA_08: 1.00653,
        QC_08: 475.055,
        VC_08: 1.42763,
        QE_08: 549.138,
        VE_08: 1.40578,
        QA_09: 233.294,
        VA_09: 1.05452,
        QC_09: 481.92,
        VC_09: 1.43619,
        QE_09: 523.796,
        VE_09: 1.37859,
        QA_10: 309.189,
        VA_10: 1.17488,
        QC_10: 536.578,
        VC_10: 1.5024,
        QE_10: 514.259,
        VE_10: 1.3682,
        QA_11: 690.245,
        VA_11: 1.63568,
        QC_11: 559.16,
        VC_11: 1.52882,
        QE_11: 640.399,
        VE_11: 1.49912,
        QA_12: 856.628,
        VA_12: 1.79696,
        QC_12: 615.955,
        VC_12: 1.59315,
        QE_12: 712.198,
        VE_12: 1.56824,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.869353183362364, 35.719543011222811, 0.0],
          [-81.86373678337111, 35.721100211220403, 0.0],
          [-81.858064383379883, 35.719931877888826, 0.0],
          [-81.85354418338693, 35.714916211230104, 0.0],
          [-81.846047316731813, 35.713609677898773, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287053.0,
        COMID: 9734522,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.89,
        REACHCODE: "03050103000326",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.008932805666571,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059420.0,
        ToNode: 250059439.0,
        Hydroseq: 250025546.0,
        LevelPathI: 250015946.0,
        Pathlength: 410.279,
        TerminalPa: 250002604.0,
        ArbolateSu: 195.385,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250026287.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024868.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6354,
        TotDASqKM: 570.3417,
        DivDASqKM: 570.3417,
        Tidal: 0,
        TOTMA: 0.041704537824700003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12499.0,
        MAXELEVSMO: 12794.0,
        MINELEVSMO: 12794.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.88999998569488503,
        QA_MA: 238.234,
        VA_MA: 0.81121,
        QC_MA: 233.307,
        VC_MA: 0.81036,
        QE_MA: 233.307,
        VE_MA: 0.81036,
        QA_01: 511.248,
        VA_01: 1.06264,
        QC_01: 393.721,
        VC_01: 0.97115,
        QE_01: 393.721,
        VE_01: 0.97115,
        QA_02: 536.05,
        VA_02: 1.08185,
        QC_02: 440.039,
        VC_02: 1.01148,
        QE_02: 440.039,
        VE_02: 1.01148,
        QA_03: 576.389,
        VA_03: 1.11223,
        QC_03: 475.819,
        VC_03: 1.04128,
        QE_03: 475.819,
        VE_03: 1.04128,
        QA_04: 342.487,
        VA_04: 0.9181,
        QC_04: 313.883,
        VC_04: 0.89601,
        QE_04: 313.883,
        VE_04: 0.89601,
        QA_05: 189.879,
        VA_05: 0.75415,
        QC_05: 177.206,
        VC_05: 0.74215,
        QE_05: 177.206,
        VE_05: 0.74215,
        QA_06: 120.392,
        VA_06: 0.65836,
        QC_06: 150.428,
        VC_06: 0.70601,
        QE_06: 150.428,
        VE_06: 0.70601,
        QA_07: 83.161,
        VA_07: 0.59593,
        QC_07: 124.798,
        VC_07: 0.66847,
        QE_07: 124.798,
        VE_07: 0.66847,
        QA_08: 61.347,
        VA_08: 0.55304,
        QC_08: 138.192,
        VC_08: 0.6885,
        QE_08: 138.192,
        VE_08: 0.6885,
        QA_09: 53.144,
        VA_09: 0.53509,
        QC_09: 130.544,
        VC_09: 0.67718,
        QE_09: 130.544,
        VE_09: 0.67718,
        QA_10: 38.415,
        VA_10: 0.4992,
        QC_10: 101.419,
        VC_10: 0.63091,
        QE_10: 101.419,
        VE_10: 0.63091,
        QA_11: 62.264,
        VA_11: 0.55498,
        QC_11: 89.283,
        VC_11: 0.60981,
        QE_11: 89.283,
        VE_11: 0.60981,
        QA_12: 285.72,
        VA_12: 0.86216,
        QC_12: 226.826,
        VC_12: 0.80291,
        QE_12: 226.826,
        VE_12: 0.80291,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.99942911804601, 34.768128212699594, 0.0],
          [-80.99801171804819, 34.766961479368149, 0.0],
          [-80.994460184720424, 34.767973012699883, 0.0],
          [-80.992874118056193, 34.765248679370814, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283907.0,
        COMID: 9751656,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 2.777,
        REACHCODE: "03050101000325",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.028173937030669002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060833.0,
        ToNode: 250060859.0,
        Hydroseq: 250024235.0,
        LevelPathI: 250019506.0,
        Pathlength: 701.134,
        TerminalPa: 250002604.0,
        ArbolateSu: 100.966,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250024859.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023651.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4418,
        TotDASqKM: 118.1385,
        DivDASqKM: 118.1385,
        Tidal: 0,
        TOTMA: 0.072568590730300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 89726.0,
        MAXELEVSMO: 92880.0,
        MINELEVSMO: 89726.0,
        SLOPE: 0.01135758,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.776999950408936,
        QA_MA: 109.039,
        VA_MA: 1.45129,
        QC_MA: 110.072,
        VC_MA: 1.45216,
        QE_MA: 111.205,
        VE_MA: 1.45311,
        QA_01: 115.68,
        VA_01: 1.48774,
        QC_01: 92.625,
        VC_01: 1.35201,
        QE_01: 96.373,
        VE_01: 1.36933,
        QA_02: 131.97,
        VA_02: 1.5732,
        QC_02: 112.552,
        VC_02: 1.46578,
        QE_02: 115.703,
        VE_02: 1.47747,
        QA_03: 178.419,
        VA_03: 1.79285,
        QC_03: 153.101,
        VC_03: 1.67119,
        QE_03: 156.827,
        VE_03: 1.68236,
        QA_04: 151.388,
        VA_04: 1.66883,
        QC_04: 141.343,
        VC_04: 1.61458,
        QE_04: 143.213,
        VE_04: 1.61768,
        QA_05: 125.324,
        VA_05: 1.53897,
        QC_05: 118.791,
        VC_05: 1.49941,
        QE_05: 120.012,
        VE_05: 1.50039,
        QA_06: 94.682,
        VA_06: 1.36874,
        QC_06: 118.695,
        VC_06: 1.4989,
        QE_06: 119.993,
        VE_06: 1.50029,
        QA_07: 57.995,
        VA_07: 1.12575,
        QC_07: 87.786,
        VC_07: 1.3227,
        QE_07: 86.75,
        VE_07: 1.31169,
        QA_08: 56.437,
        VA_08: 1.11402,
        QC_08: 126.92,
        VC_08: 1.54202,
        QE_08: 123.765,
        VE_08: 1.52004,
        QA_09: 75.162,
        VA_09: 1.2464,
        QC_09: 177.287,
        VC_09: 1.78157,
        QE_09: 171.159,
        VE_09: 1.74766,
        QA_10: 84.321,
        VA_10: 1.30546,
        QC_10: 190.048,
        VC_10: 1.83696,
        QE_10: 183.484,
        VE_10: 1.8018,
        QA_11: 117.015,
        VA_11: 1.49495,
        QC_11: 144.455,
        VC_11: 1.62977,
        QE_11: 142.971,
        VE_11: 1.6165,
        QA_12: 120.866,
        VA_12: 1.51553,
        QC_12: 103.693,
        VC_12: 1.41647,
        QE_12: 104.077,
        VE_12: 1.41355,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.922401583279964, 35.950659610864193, 0.0],
          [-81.921921316614089, 35.948530210867432, 0.0],
          [-81.926028116607654, 35.941727077544613, 0.0],
          [-81.928422583270731, 35.943031210875859, 0.0],
          [-81.930111316601426, 35.941793810877925, 0.0],
          [-81.918507783286088, 35.939165677548658, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287054.0,
        COMID: 9734492,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.115,
        REACHCODE: "03050103000327",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010543686984319999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059410.0,
        ToNode: 250059420.0,
        Hydroseq: 250026287.0,
        LevelPathI: 250015946.0,
        Pathlength: 411.169,
        TerminalPa: 250002604.0,
        ArbolateSu: 194.461,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250027080.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025546.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7837,
        TotDASqKM: 569.7063,
        DivDASqKM: 569.7063,
        Tidal: 0,
        TOTMA: 0.00252546591379,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12738.0,
        MAXELEVSMO: 12874.0,
        MINELEVSMO: 12794.0,
        SLOPE: 0.00695652,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.115000002086163,
        QA_MA: 237.98,
        VA_MA: 1.73153,
        QC_MA: 233.068,
        VC_MA: 1.72913,
        QE_MA: 233.068,
        VE_MA: 1.72913,
        QA_01: 510.693,
        VA_01: 2.4431,
        QC_01: 393.305,
        VC_01: 2.18417,
        QE_01: 393.305,
        VE_01: 2.18417,
        QA_02: 535.469,
        VA_02: 2.49748,
        QC_02: 439.575,
        VC_02: 2.29829,
        QE_02: 439.575,
        VE_02: 2.29829,
        QA_03: 575.765,
        VA_03: 2.58346,
        QC_03: 475.321,
        VC_03: 2.38264,
        QE_03: 475.321,
        VE_03: 2.38264,
        QA_04: 342.12,
        VA_04: 2.03405,
        QC_04: 313.554,
        VC_04: 1.97151,
        QE_04: 313.554,
        VE_04: 1.97151,
        QA_05: 189.674,
        VA_05: 1.57001,
        QC_05: 177.022,
        VC_05: 1.53607,
        QE_05: 177.022,
        VE_05: 1.53607,
        QA_06: 120.264,
        VA_06: 1.2989,
        QC_06: 150.27,
        VC_06: 1.43378,
        QE_06: 150.27,
        VE_06: 1.43378,
        QA_07: 83.067,
        VA_07: 1.1222,
        QC_07: 124.66,
        VC_07: 1.32748,
        QE_07: 124.66,
        VE_07: 1.32748,
        QA_08: 61.279,
        VA_08: 1.00081,
        QC_08: 138.035,
        VC_08: 1.38415,
        QE_08: 138.035,
        VE_08: 1.38415,
        QA_09: 53.092,
        VA_09: 0.95003,
        QC_09: 130.433,
        VC_09: 1.35228,
        QE_09: 130.433,
        VE_09: 1.35228,
        QA_10: 38.372,
        VA_10: 0.84842,
        QC_10: 101.329,
        VC_10: 1.2213,
        QE_10: 101.329,
        VE_10: 1.2213,
        QA_11: 62.222,
        VA_11: 1.00645,
        QC_11: 89.237,
        VC_11: 1.16173,
        QE_11: 89.237,
        VE_11: 1.16173,
        QA_12: 285.421,
        VA_12: 1.87575,
        QC_12: 226.611,
        VC_12: 1.70809,
        QE_12: 226.611,
        VE_12: 1.70809,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.999802518045385, 34.769112812698097, 0.0],
          [-80.99942911804601, 34.768128212699594, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283908.0,
        COMID: 9751598,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.463,
        REACHCODE: "03050101000326",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0050020420553269999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060817.0,
        ToNode: 250060833.0,
        Hydroseq: 250024859.0,
        LevelPathI: 250019506.0,
        Pathlength: 703.911,
        TerminalPa: 250002604.0,
        ArbolateSu: 96.329,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250025536.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024235.0,
        FromMeas: 0.0,
        ToMeas: 35.32804,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1422,
        TotDASqKM: 115.1244,
        DivDASqKM: 115.1244,
        Tidal: 0,
        TOTMA: 0.0096579085055799995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 92880.0,
        MAXELEVSMO: 96017.0,
        MINELEVSMO: 92880.0,
        SLOPE: 0.06775377,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.46299999952316301,
        QA_MA: 106.487,
        VA_MA: 1.81789,
        QC_MA: 107.595,
        VC_MA: 1.81916,
        QE_MA: 108.699,
        VE_MA: 1.82041,
        QA_01: 112.505,
        VA_01: 1.8626,
        QC_01: 90.149,
        VC_01: 1.6837,
        QE_01: 93.802,
        VE_01: 1.70658,
        QA_02: 128.423,
        VA_02: 1.97574,
        QC_02: 109.609,
        VC_02: 1.83411,
        QE_02: 112.679,
        VE_02: 1.84956,
        QA_03: 174.123,
        VA_03: 2.26829,
        QC_03: 149.535,
        VC_03: 2.10769,
        QE_03: 153.165,
        VE_03: 2.1224,
        QA_04: 147.871,
        VA_04: 2.10538,
        QC_04: 138.133,
        VC_04: 2.03349,
        QE_04: 139.956,
        VE_04: 2.03758,
        QA_05: 122.377,
        VA_05: 1.93359,
        QC_05: 116.101,
        VC_05: 1.88144,
        QE_05: 117.291,
        VE_05: 1.88274,
        QA_06: 92.797,
        VA_06: 1.71153,
        QC_06: 116.364,
        VC_06: 1.88334,
        QE_06: 117.629,
        VE_06: 1.88514,
        QA_07: 56.925,
        VA_07: 1.39083,
        QC_07: 86.205,
        VC_07: 1.65141,
        QE_07: 85.194,
        VE_07: 1.63691,
        QA_08: 55.482,
        VA_08: 1.37618,
        QC_08: 124.73,
        VC_08: 1.94248,
        QE_08: 121.656,
        VE_08: 1.91358,
        QA_09: 73.656,
        VA_09: 1.54955,
        QC_09: 174.147,
        VC_09: 2.25932,
        QE_09: 168.175,
        VE_09: 2.21473,
        QA_10: 82.485,
        VA_10: 1.62645,
        QC_10: 186.735,
        VC_10: 2.33298,
        QE_10: 180.339,
        VE_10: 2.28674,
        QA_11: 114.139,
        VA_11: 1.87455,
        QC_11: 141.739,
        VC_11: 2.05726,
        QE_11: 140.294,
        VE_11: 2.03979,
        QA_12: 117.854,
        VA_12: 1.90141,
        QC_12: 101.339,
        VC_12: 1.77186,
        QE_12: 101.713,
        VE_12: 1.768,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.927047583272781, 35.9495810775324, 0.0],
          [-81.922401583279964, 35.950659610864193, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287047.0,
        COMID: 9734678,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.56,
        REACHCODE: "03050103000320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0056484323892559999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059473.0,
        ToNode: 250059503.0,
        Hydroseq: 250022149.0,
        LevelPathI: 250015946.0,
        Pathlength: 405.711,
        TerminalPa: 250002604.0,
        ArbolateSu: 211.715,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250022619.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021710.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2043,
        TotDASqKM: 586.8855,
        DivDASqKM: 586.8855,
        Tidal: 0,
        TOTMA: 0.017171665585299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12215.0,
        MAXELEVSMO: 12427.0,
        MINELEVSMO: 12401.0,
        SLOPE: 0.00046428,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.56000000238418601,
        QA_MA: 244.812,
        VA_MA: 1.24002,
        QC_MA: 239.496,
        VC_MA: 1.23836,
        QE_MA: 239.496,
        VE_MA: 1.23836,
        QA_01: 525.691,
        VA_01: 1.7063,
        QC_01: 404.549,
        VC_01: 1.53686,
        QE_01: 404.549,
        VE_01: 1.53686,
        QA_02: 551.175,
        VA_02: 1.74188,
        QC_02: 452.112,
        VC_02: 1.61155,
        QE_02: 452.112,
        VE_02: 1.61155,
        QA_03: 592.626,
        VA_03: 1.79815,
        QC_03: 488.775,
        VC_03: 1.66664,
        QE_03: 488.775,
        VE_03: 1.66664,
        QA_04: 351.902,
        VA_04: 1.43798,
        QC_04: 322.313,
        VC_04: 1.39721,
        QE_04: 322.313,
        VE_04: 1.39721,
        QA_05: 195.143,
        VA_05: 1.13433,
        QC_05: 181.932,
        VC_05: 1.11205,
        QE_05: 181.932,
        VE_05: 1.11205,
        QA_06: 123.728,
        VA_06: 0.95683,
        QC_06: 154.539,
        VC_06: 1.04535,
        QE_06: 154.539,
        VE_06: 1.04535,
        QA_07: 85.424,
        VA_07: 0.84104,
        QC_07: 128.113,
        VC_07: 0.97549,
        QE_07: 128.113,
        VE_07: 0.97549,
        QA_08: 63.126,
        VA_08: 0.762,
        QC_08: 142.282,
        VC_08: 1.01371,
        QE_08: 142.282,
        VE_08: 1.01371,
        QA_09: 54.478,
        VA_09: 0.72786,
        QC_09: 133.435,
        VC_09: 0.99007,
        QE_09: 133.435,
        VE_09: 0.99007,
        QA_10: 39.527,
        VA_10: 0.66216,
        QC_10: 103.758,
        VC_10: 0.90483,
        QE_10: 103.758,
        VE_10: 0.90483,
        QA_11: 63.377,
        VA_11: 0.76295,
        QC_11: 90.497,
        VC_11: 0.86306,
        QE_11: 90.497,
        VE_11: 0.86306,
        QA_12: 293.276,
        VA_12: 1.33379,
        QC_12: 232.278,
        VC_12: 1.22337,
        QE_12: 232.278,
        VE_12: 1.22337,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.980655184741806, 34.739486212744168, 0.0],
          [-80.977129318080586, 34.737954612746421, 0.0],
          [-80.977737784746353, 34.736350612748879, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283909.0,
        COMID: 9751596,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.856,
        REACHCODE: "03050101000326",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.008305301757163,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060816.0,
        ToNode: 250060817.0,
        Hydroseq: 250025536.0,
        LevelPathI: 250019506.0,
        Pathlength: 704.374,
        TerminalPa: 250002604.0,
        ArbolateSu: 95.866,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250026278.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024859.0,
        FromMeas: 35.32804,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6975,
        TotDASqKM: 114.9822,
        DivDASqKM: 114.9822,
        Tidal: 0,
        TOTMA: 0.023081404537399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 96017.0,
        MAXELEVSMO: 96802.0,
        MINELEVSMO: 96017.0,
        SLOPE: 0.00917056,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.85600000619888295,
        QA_MA: 106.37,
        VA_MA: 1.40643,
        QC_MA: 107.481,
        VC_MA: 1.40735,
        QE_MA: 108.584,
        VE_MA: 1.40826,
        QA_01: 112.348,
        VA_01: 1.43878,
        QC_01: 90.026,
        VC_01: 1.30866,
        QE_01: 93.675,
        VE_01: 1.3253,
        QA_02: 128.251,
        VA_02: 1.5211,
        QC_02: 109.466,
        VC_02: 1.41808,
        QE_02: 112.532,
        VE_02: 1.42932,
        QA_03: 173.929,
        VA_03: 1.73404,
        QC_03: 149.374,
        VC_03: 1.61723,
        QE_03: 153.0,
        VE_03: 1.62793,
        QA_04: 147.711,
        VA_04: 1.61557,
        QC_04: 137.988,
        VC_04: 1.56327,
        QE_04: 139.808,
        VE_04: 1.56624,
        QA_05: 122.243,
        VA_05: 1.49059,
        QC_05: 115.979,
        VC_05: 1.45267,
        QE_05: 117.168,
        VE_05: 1.45361,
        QA_06: 92.714,
        VA_06: 1.32917,
        QC_06: 116.261,
        VC_06: 1.45414,
        QE_06: 117.525,
        VE_06: 1.45546,
        QA_07: 56.879,
        VA_07: 1.0959,
        QC_07: 86.137,
        VC_07: 1.28547,
        QE_07: 85.128,
        VE_07: 1.27493,
        QA_08: 55.442,
        VA_08: 1.08527,
        QC_08: 124.638,
        VC_08: 1.49727,
        QE_08: 121.568,
        VE_08: 1.47625,
        QA_09: 73.589,
        VA_09: 1.21132,
        QC_09: 174.007,
        VC_09: 1.72773,
        QE_09: 168.043,
        VE_09: 1.6953,
        QA_10: 82.402,
        VA_10: 1.26722,
        QC_10: 186.587,
        VC_10: 1.78133,
        QE_10: 180.198,
        VE_10: 1.7477,
        QA_11: 114.005,
        VA_11: 1.4476,
        QC_11: 141.613,
        VC_11: 1.58067,
        QE_11: 140.169,
        VE_11: 1.56796,
        QA_12: 117.713,
        VA_12: 1.46713,
        QC_12: 101.229,
        VC_12: 1.37293,
        QE_12: 101.602,
        VE_12: 1.37012,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.929192716602756, 35.955717210856335, 0.0],
          [-81.927359916605667, 35.952993010860439, 0.0],
          [-81.928681716603592, 35.950519210864286, 0.0],
          [-81.927047583272781, 35.9495810775324, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287048.0,
        COMID: 9734654,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.967,
        REACHCODE: "03050103000321",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010342897771584,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059463.0,
        ToNode: 250059473.0,
        Hydroseq: 250022619.0,
        LevelPathI: 250015946.0,
        Pathlength: 406.271,
        TerminalPa: 250002604.0,
        ArbolateSu: 209.265,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250023117.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022149.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7767,
        TotDASqKM: 585.333,
        DivDASqKM: 585.333,
        Tidal: 0,
        TOTMA: 0.027542444392300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12167.0,
        MAXELEVSMO: 12510.0,
        MINELEVSMO: 12427.0,
        SLOPE: 0.00085832,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.96700000762939498,
        QA_MA: 244.196,
        VA_MA: 1.33502,
        QC_MA: 238.917,
        VC_MA: 1.3332,
        QE_MA: 238.917,
        VE_MA: 1.3332,
        QA_01: 524.341,
        VA_01: 1.84881,
        QC_01: 403.537,
        VC_01: 1.66208,
        QE_01: 403.537,
        VE_01: 1.66208,
        QA_02: 549.756,
        VA_02: 1.88802,
        QC_02: 450.98,
        VC_02: 1.74439,
        QE_02: 450.98,
        VE_02: 1.74439,
        QA_03: 591.103,
        VA_03: 1.95003,
        QC_03: 487.559,
        VC_03: 1.8051,
        QE_03: 487.559,
        VE_03: 1.8051,
        QA_04: 351.025,
        VA_04: 1.55319,
        QC_04: 321.529,
        VC_04: 1.50825,
        QE_04: 321.529,
        VE_04: 1.50825,
        QA_05: 194.652,
        VA_05: 1.21856,
        QC_05: 181.491,
        VC_05: 1.19401,
        QE_05: 181.491,
        VE_05: 1.19401,
        QA_06: 123.415,
        VA_06: 1.02296,
        QC_06: 154.153,
        VC_06: 1.12047,
        QE_06: 154.153,
        VE_06: 1.12047,
        QA_07: 85.206,
        VA_07: 0.89534,
        QC_07: 127.793,
        VC_07: 1.0435,
        QE_07: 127.793,
        VE_07: 1.0435,
        QA_08: 62.959,
        VA_08: 0.80822,
        QC_08: 141.898,
        VC_08: 1.08552,
        QE_08: 141.898,
        VE_08: 1.08552,
        QA_09: 54.353,
        VA_09: 0.77069,
        QC_09: 133.164,
        VC_09: 1.05975,
        QE_09: 133.164,
        VE_09: 1.05975,
        QA_10: 39.423,
        VA_10: 0.69821,
        QC_10: 103.539,
        VC_10: 0.96577,
        QE_10: 103.539,
        VE_10: 0.96577,
        QA_11: 63.272,
        VA_11: 0.80954,
        QC_11: 90.383,
        VC_11: 0.92,
        QE_11: 90.383,
        VE_11: 0.92,
        QA_12: 292.573,
        VA_12: 1.43843,
        QC_12: 231.771,
        VC_12: 1.31681,
        QE_12: 231.771,
        VE_12: 1.31681,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.990315718060174, 34.742454879406182, 0.0],
          [-80.980655184741806, 34.739486212744168, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286031.0,
        COMID: 9751140,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "990691",
        GNIS_NAME: "Mountain Creek",
        LENGTHKM: 3.514,
        REACHCODE: "03050101002706",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.036018513449338999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060732.0,
        ToNode: 250060733.0,
        Hydroseq: 250032416.0,
        LevelPathI: 250029954.0,
        Pathlength: 526.562,
        TerminalPa: 250002604.0,
        ArbolateSu: 75.232,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250029954.0,
        UpHydroseq: 250033888.0,
        DnLevelPat: 250029954.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031119.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 8.4285,
        TotDASqKM: 115.6698,
        DivDASqKM: 115.6698,
        Tidal: 0,
        TOTMA: 4.3823562062899999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22922.0,
        MAXELEVSMO: 23055.0,
        MINELEVSMO: 23055.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.5139999389648442,
        QA_MA: 51.177,
        VA_MA: -9998.0,
        QC_MA: 53.2,
        VC_MA: -9998.0,
        QE_MA: 53.2,
        VE_MA: -9998.0,
        QA_01: 100.252,
        VA_01: -9998.0,
        QC_01: 80.573,
        VC_01: -9998.0,
        QE_01: 80.573,
        VE_01: -9998.0,
        QA_02: 105.476,
        VA_02: -9998.0,
        QC_02: 90.507,
        VC_02: -9998.0,
        QE_02: 90.507,
        VE_02: -9998.0,
        QA_03: 114.058,
        VA_03: -9998.0,
        QC_03: 99.329,
        VC_03: -9998.0,
        QE_03: 99.329,
        VE_03: -9998.0,
        QA_04: 76.162,
        VA_04: -9998.0,
        QC_04: 72.227,
        VC_04: -9998.0,
        QE_04: 72.227,
        VE_04: -9998.0,
        QA_05: 47.502,
        VA_05: -9998.0,
        QC_05: 46.69,
        VC_05: -9998.0,
        QE_05: 46.69,
        VE_05: -9998.0,
        QA_06: 28.065,
        VA_06: -9998.0,
        QC_06: 35.776,
        VC_06: -9998.0,
        QE_06: 35.776,
        VE_06: -9998.0,
        QA_07: 17.786,
        VA_07: -9998.0,
        QC_07: 27.694,
        VC_07: -9998.0,
        QE_07: 27.694,
        VE_07: -9998.0,
        QA_08: 12.429,
        VA_08: -9998.0,
        QC_08: 27.118,
        VC_08: -9998.0,
        QE_08: 27.118,
        VE_08: -9998.0,
        QA_09: 10.886,
        VA_09: -9998.0,
        QC_09: 32.196,
        VC_09: -9998.0,
        QE_09: 32.196,
        VE_09: -9998.0,
        QA_10: 8.331,
        VA_10: -9998.0,
        QC_10: 29.912,
        VC_10: -9998.0,
        QE_10: 29.912,
        VE_10: -9998.0,
        QA_11: 26.175,
        VA_11: -9998.0,
        QC_11: 46.106,
        VC_11: -9998.0,
        QE_11: 46.106,
        VE_11: -9998.0,
        QA_12: 67.156,
        VA_12: -9998.0,
        QC_12: 60.75,
        VC_12: -9998.0,
        QE_12: 60.75,
        VE_12: -9998.0,
        LakeFract: 0.00118435174,
        SurfArea: 154931.288077,
        RAreaHLoad: 1.1888570174,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.996846984716569, 35.565315678128911, 0.0],
          [-80.993680984721607, 35.564232678130622, 0.0],
          [-80.991829718057829, 35.565895678128072, 0.0],
          [-80.985553118067571, 35.566857078126418, 0.0],
          [-80.967806718095005, 35.552374278148989, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283910.0,
        COMID: 9751556,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.091,
        REACHCODE: "03050101000327",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00088167127060599995,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060805.0,
        ToNode: 250060816.0,
        Hydroseq: 250026278.0,
        LevelPathI: 250019506.0,
        Pathlength: 705.23,
        TerminalPa: 250002604.0,
        ArbolateSu: 92.572,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250027075.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025536.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0108,
        TotDASqKM: 111.9384,
        DivDASqKM: 111.9384,
        Tidal: 0,
        TOTMA: 0.00194934943824,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 96733.0,
        MAXELEVSMO: 97326.0,
        MINELEVSMO: 96802.0,
        SLOPE: 0.05758241,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.090999998152255998,
        QA_MA: 103.713,
        VA_MA: 1.77009,
        QC_MA: 104.9,
        VC_MA: 1.77144,
        QE_MA: 105.973,
        VE_MA: 1.77265,
        QA_01: 109.252,
        VA_01: 1.81104,
        QC_01: 87.609,
        VC_01: 1.63799,
        QE_01: 91.161,
        VE_01: 1.66014,
        QA_02: 124.717,
        VA_02: 1.92048,
        QC_02: 106.531,
        VC_02: 1.78348,
        QE_02: 109.516,
        VE_02: 1.79844,
        QA_03: 169.457,
        VA_03: 2.20546,
        QC_03: 145.658,
        VC_03: 2.05003,
        QE_03: 149.188,
        VE_03: 2.06426,
        QA_04: 144.029,
        VA_04: 2.04855,
        QC_04: 134.625,
        VC_04: 1.97869,
        QE_04: 136.397,
        VE_04: 1.98264,
        QA_05: 119.194,
        VA_05: 1.88217,
        QC_05: 113.193,
        VC_05: 1.83176,
        QE_05: 114.35,
        VE_05: 1.83301,
        QA_06: 90.669,
        VA_06: 1.6694,
        QC_06: 113.732,
        VC_06: 1.83561,
        QE_06: 114.962,
        VE_06: 1.83734,
        QA_07: 55.7,
        VA_07: 1.35915,
        QC_07: 84.394,
        VC_07: 1.61186,
        QE_07: 83.411,
        VE_07: 1.59785,
        QA_08: 54.324,
        VA_08: 1.34528,
        QC_08: 122.074,
        VC_08: 1.8941,
        QE_08: 119.085,
        VE_08: 1.86622,
        QA_09: 71.935,
        VA_09: 1.51201,
        QC_09: 170.548,
        VC_09: 2.20221,
        QE_09: 164.742,
        VE_09: 2.1592,
        QA_10: 80.44,
        VA_10: 1.58559,
        QC_10: 183.028,
        VC_10: 2.27458,
        QE_10: 176.809,
        VE_10: 2.22999,
        QA_11: 111.023,
        VA_11: 1.82393,
        QC_11: 138.778,
        VC_11: 2.00585,
        QE_11: 137.372,
        VE_11: 1.98898,
        QA_12: 114.616,
        VA_12: 1.84978,
        QC_12: 98.802,
        VC_12: 1.72565,
        QE_12: 99.166,
        VE_12: 1.72193,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.929756383268625, 35.956380877521951, 0.0],
          [-81.929192716602756, 35.955717210856335, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287049.0,
        COMID: 9734636,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.97,
        REACHCODE: "03050103000322",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0093447153191869997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059457.0,
        ToNode: 250059463.0,
        Hydroseq: 250023117.0,
        LevelPathI: 250015946.0,
        Pathlength: 407.238,
        TerminalPa: 250002604.0,
        ArbolateSu: 207.114,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250023655.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022619.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6057,
        TotDASqKM: 582.0687,
        DivDASqKM: 582.0687,
        Tidal: 0,
        TOTMA: 0.0296332247631,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12378.0,
        MAXELEVSMO: 12557.0,
        MINELEVSMO: 12510.0,
        SLOPE: 0.00048453,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97000002861022905,
        QA_MA: 242.904,
        VA_MA: 1.24462,
        QC_MA: 237.701,
        VC_MA: 1.24298,
        QE_MA: 237.701,
        VE_MA: 1.24298,
        QA_01: 521.496,
        VA_01: 1.71307,
        QC_01: 401.405,
        VC_01: 1.54277,
        QE_01: 401.405,
        VE_01: 1.54277,
        QA_02: 546.772,
        VA_02: 1.74882,
        QC_02: 448.598,
        VC_02: 1.61782,
        QE_02: 448.598,
        VE_02: 1.61782,
        QA_03: 587.899,
        VA_03: 1.80537,
        QC_03: 485.004,
        VC_03: 1.67322,
        QE_03: 485.004,
        VE_03: 1.67322,
        QA_04: 349.182,
        VA_04: 1.4436,
        QC_04: 319.878,
        VC_04: 1.40258,
        QE_04: 319.878,
        VE_04: 1.40258,
        QA_05: 193.632,
        VA_05: 1.13843,
        QC_05: 180.576,
        VC_05: 1.11606,
        QE_05: 180.576,
        VE_05: 1.11606,
        QA_06: 122.757,
        VA_06: 0.96001,
        QC_06: 153.342,
        VC_06: 1.04891,
        QE_06: 153.342,
        VE_06: 1.04891,
        QA_07: 84.767,
        VA_07: 0.8437,
        QC_07: 127.15,
        VC_07: 0.9788,
        QE_07: 127.15,
        VE_07: 0.9788,
        QA_08: 62.608,
        VA_08: 0.76414,
        QC_08: 141.091,
        VC_08: 1.01688,
        QE_08: 141.091,
        VE_08: 1.01688,
        QA_09: 54.09,
        VA_09: 0.73009,
        QC_09: 132.594,
        VC_09: 0.99389,
        QE_09: 132.594,
        VE_09: 0.99389,
        QA_10: 39.204,
        VA_10: 0.66384,
        QC_10: 103.079,
        VC_10: 0.9081,
        QE_10: 103.079,
        VE_10: 0.9081,
        QA_11: 63.053,
        VA_11: 0.76586,
        QC_11: 90.144,
        VC_11: 0.86688,
        QE_11: 90.144,
        VE_11: 0.86688,
        QA_12: 291.099,
        VA_12: 1.33907,
        QC_12: 230.709,
        VC_12: 1.22828,
        QE_12: 230.709,
        VE_12: 1.22828,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.991629318058017, 34.749256812728902, 0.0],
          [-80.988571584729527, 34.745571812734681, 0.0],
          [-80.990315718060174, 34.742454879406182, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286032.0,
        COMID: 9751142,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.031,
        REACHCODE: "03050101002707",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0099392987174660007,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060733.0,
        ToNode: 250060735.0,
        Hydroseq: 250031119.0,
        LevelPathI: 250029954.0,
        Pathlength: 525.531,
        TerminalPa: 250002604.0,
        ArbolateSu: 82.529,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250029954.0,
        UpHydroseq: 250032416.0,
        DnLevelPat: 250029954.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029954.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4761,
        TotDASqKM: 124.731,
        DivDASqKM: 124.731,
        Tidal: 0,
        TOTMA: 1.28577382871,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23048.0,
        MAXELEVSMO: 23055.0,
        MINELEVSMO: 23052.0,
        SLOPE: 2.909e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.031000018119812,
        QA_MA: 54.953,
        VA_MA: -9998.0,
        QC_MA: 56.968,
        VC_MA: -9998.0,
        QE_MA: 56.968,
        VE_MA: -9998.0,
        QA_01: 107.834,
        VA_01: -9998.0,
        QC_01: 86.502,
        VC_01: -9998.0,
        QE_01: 86.502,
        VE_01: -9998.0,
        QA_02: 113.525,
        VA_02: -9998.0,
        QC_02: 97.22,
        VC_02: -9998.0,
        QE_02: 97.22,
        VE_02: -9998.0,
        QA_03: 122.766,
        VA_03: -9998.0,
        QC_03: 106.654,
        VC_03: -9998.0,
        QE_03: 106.654,
        VE_03: -9998.0,
        QA_04: 81.869,
        VA_04: -9998.0,
        QC_04: 77.512,
        VC_04: -9998.0,
        QE_04: 77.512,
        VE_04: -9998.0,
        QA_05: 50.833,
        VA_05: -9998.0,
        QC_05: 49.838,
        VC_05: -9998.0,
        QE_05: 49.838,
        VE_05: -9998.0,
        QA_06: 30.075,
        VA_06: -9998.0,
        QC_06: 38.301,
        VC_06: -9998.0,
        QE_06: 38.301,
        VE_06: -9998.0,
        QA_07: 19.09,
        VA_07: -9998.0,
        QC_07: 29.675,
        VC_07: -9998.0,
        QE_07: 29.675,
        VE_07: -9998.0,
        QA_08: 13.368,
        VA_08: -9998.0,
        QC_08: 29.209,
        VC_08: -9998.0,
        QE_08: 29.209,
        VE_08: -9998.0,
        QA_09: 11.733,
        VA_09: -9998.0,
        QC_09: 34.398,
        VC_09: -9998.0,
        QE_09: 34.398,
        VE_09: -9998.0,
        QA_10: 8.94,
        VA_10: -9998.0,
        QC_10: 31.646,
        VC_10: -9998.0,
        QE_10: 31.646,
        VE_10: -9998.0,
        QA_11: 27.602,
        VA_11: -9998.0,
        QC_11: 48.011,
        VC_11: -9998.0,
        QE_11: 48.011,
        VE_11: -9998.0,
        QA_12: 71.998,
        VA_12: -9998.0,
        QC_12: 64.722,
        VC_12: -9998.0,
        QE_12: 64.722,
        VE_12: -9998.0,
        LakeFract: 0.00037196932,
        SurfArea: 48659.265593600001,
        RAreaHLoad: 0.34880807655000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.967806718095005, 35.552374278148989, 0.0],
          [-80.961566118104713, 35.544654678160953, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283903.0,
        COMID: 9752618,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.308,
        REACHCODE: "03050101000321",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0028320471647299999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061095.0,
        ToNode: 250061107.0,
        Hydroseq: 250022141.0,
        LevelPathI: 250019506.0,
        Pathlength: 679.485,
        TerminalPa: 250002604.0,
        ArbolateSu: 129.467,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250022612.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021702.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0684,
        TotDASqKM: 161.2242,
        DivDASqKM: 161.2242,
        Tidal: 0,
        TOTMA: 0.0075797220845399997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37686.0,
        MAXELEVSMO: 38123.0,
        MINELEVSMO: 37750.0,
        SLOPE: 0.01211038,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30799999833107,
        QA_MA: 139.318,
        VA_MA: 1.54191,
        QC_MA: 139.307,
        VC_MA: 1.54191,
        QE_MA: 140.854,
        VE_MA: 1.54301,
        QA_01: 160.7,
        VA_01: 1.63907,
        QC_01: 127.569,
        VC_01: 1.48558,
        QE_01: 132.685,
        VE_01: 1.50446,
        QA_02: 178.949,
        VA_02: 1.71729,
        QC_02: 151.357,
        VC_02: 1.59746,
        QE_02: 155.657,
        VE_02: 1.61029,
        QA_03: 233.56,
        VA_03: 1.93132,
        QC_03: 198.644,
        VC_03: 1.79766,
        QE_03: 203.728,
        VE_03: 1.81029,
        QA_04: 194.201,
        VA_04: 1.77982,
        QC_04: 180.292,
        VC_04: 1.72294,
        QE_04: 182.845,
        VE_04: 1.72648,
        QA_05: 161.172,
        VA_05: 1.64114,
        QC_05: 151.34,
        VC_05: 1.59738,
        QE_05: 153.007,
        VE_05: 1.59848,
        QA_06: 114.712,
        VA_06: 1.42097,
        QC_06: 143.426,
        VC_06: 1.56114,
        QE_06: 145.198,
        VE_06: 1.56308,
        QA_07: 69.554,
        VA_07: 1.1614,
        QC_07: 104.825,
        VC_07: 1.36902,
        QE_07: 103.41,
        VE_07: 1.35617,
        QA_08: 64.39,
        VA_08: 1.12716,
        QC_08: 145.188,
        VC_08: 1.56929,
        QE_08: 140.883,
        VE_08: 1.54314,
        QA_09: 85.857,
        VA_09: 1.26232,
        QC_09: 199.381,
        VC_09: 1.80059,
        QE_09: 191.018,
        VE_09: 1.75979,
        QA_10: 100.516,
        VA_10: 1.3456,
        QC_10: 218.682,
        VC_10: 1.87563,
        QE_10: 209.724,
        VE_10: 1.83361,
        QA_11: 150.175,
        VA_11: 1.59206,
        QC_11: 174.73,
        VC_11: 1.6996,
        QE_11: 172.705,
        VE_11: 1.68416,
        QA_12: 158.925,
        VA_12: 1.63125,
        QC_12: 133.02,
        VC_12: 1.51203,
        QE_12: 133.543,
        VE_12: 1.50856,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.900660516647122, 35.807199611086787, 0.0],
          [-81.899619383315382, 35.804612077757497, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287050.0,
        COMID: 9736094,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.141,
        REACHCODE: "03050103000323",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010998201964272,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059829.0,
        ToNode: 250059457.0,
        Hydroseq: 250023655.0,
        LevelPathI: 250015946.0,
        Pathlength: 408.208,
        TerminalPa: 250002604.0,
        ArbolateSu: 202.927,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250024244.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023117.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0827,
        TotDASqKM: 577.1655,
        DivDASqKM: 577.1655,
        Tidal: 0,
        TOTMA: 0.029193423101499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12491.0,
        MAXELEVSMO: 12794.0,
        MINELEVSMO: 12557.0,
        SLOPE: 0.00207712,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.141000032424927,
        QA_MA: 240.952,
        VA_MA: 1.48616,
        QC_MA: 235.865,
        VC_MA: 1.48413,
        QE_MA: 235.865,
        VE_MA: 1.48413,
        QA_01: 517.211,
        VA_01: 2.0753,
        QC_01: 398.193,
        VC_01: 1.86105,
        QE_01: 398.193,
        VE_01: 1.86105,
        QA_02: 542.289,
        VA_02: 2.12028,
        QC_02: 445.02,
        VC_02: 1.95547,
        QE_02: 445.02,
        VE_02: 1.95547,
        QA_03: 583.086,
        VA_03: 2.19143,
        QC_03: 481.164,
        VC_03: 2.02521,
        QE_03: 481.164,
        VE_03: 2.02521,
        QA_04: 346.385,
        VA_04: 1.7365,
        QC_04: 317.374,
        VC_04: 1.68484,
        QE_04: 317.374,
        VE_04: 1.68484,
        QA_05: 192.053,
        VA_05: 1.35252,
        QC_05: 179.158,
        VC_05: 1.32439,
        QE_05: 179.158,
        VE_05: 1.32439,
        QA_06: 121.768,
        VA_06: 1.12813,
        QC_06: 152.124,
        VC_06: 1.23987,
        QE_06: 152.124,
        VE_06: 1.23987,
        QA_07: 84.108,
        VA_07: 0.9819,
        QC_07: 126.185,
        VC_07: 1.15185,
        QE_07: 126.185,
        VE_07: 1.15185,
        QA_08: 62.081,
        VA_08: 0.8816,
        QC_08: 139.879,
        VC_08: 1.19928,
        QE_08: 139.879,
        VE_08: 1.19928,
        QA_09: 53.694,
        VA_09: 0.83909,
        QC_09: 131.738,
        VC_09: 1.17136,
        QE_09: 131.738,
        VE_09: 1.17136,
        QA_10: 38.874,
        VA_10: 0.75545,
        QC_10: 102.386,
        VC_10: 1.06325,
        QE_10: 102.386,
        VE_10: 1.06325,
        QA_11: 62.723,
        VA_11: 0.88474,
        QC_11: 89.785,
        VC_11: 1.0124,
        QE_11: 89.785,
        VE_11: 1.0124,
        QA_12: 288.858,
        VA_12: 1.60521,
        QC_12: 229.092,
        VC_12: 1.46608,
        QE_12: 229.092,
        VE_12: 1.46608,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.993224184722237, 34.757643479382473, 0.0],
          [-80.994442784720434, 34.755786812718668, 0.0],
          [-80.990385318059964, 34.751850879391505, 0.0],
          [-80.991629318058017, 34.749256812728902, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286033.0,
        COMID: 9751144,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 6.197,
        REACHCODE: "03050101002708",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.059699037764678997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060734.0,
        ToNode: 250060745.0,
        Hydroseq: 250008667.0,
        LevelPathI: 250003258.0,
        Pathlength: 525.265,
        TerminalPa: 250002604.0,
        ArbolateSu: 3771.666,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008702.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008629.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 14.8509,
        TotDASqKM: 4329.027,
        DivDASqKM: 4329.027,
        Tidal: 0,
        TOTMA: 7.7283612221900002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23039.0,
        MAXELEVSMO: 23052.0,
        MINELEVSMO: 23052.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 6.1970000267028809,
        QA_MA: 2496.282,
        VA_MA: -9998.0,
        QC_MA: 2496.282,
        VC_MA: -9998.0,
        QE_MA: 2689.435,
        VE_MA: -9998.0,
        QA_01: 4131.268,
        VA_01: -9998.0,
        QC_01: 4131.268,
        VC_01: -9998.0,
        QE_01: 4312.019,
        VE_01: -9998.0,
        QA_02: 4363.828,
        VA_02: -9998.0,
        QC_02: 4363.828,
        VC_02: -9998.0,
        QE_02: 4408.493,
        VE_02: -9998.0,
        QA_03: 4903.936,
        VA_03: -9998.0,
        QC_03: 4903.936,
        VC_03: -9998.0,
        QE_03: 5134.654,
        VE_03: -9998.0,
        QA_04: 3722.895,
        VA_04: -9998.0,
        QC_04: 3722.895,
        VC_04: -9998.0,
        QE_04: 3903.592,
        VE_04: -9998.0,
        QA_05: 2776.811,
        VA_05: -9998.0,
        QC_05: 2776.811,
        VC_05: -9998.0,
        QE_05: 2998.962,
        VE_05: -9998.0,
        QA_06: 1621.447,
        VA_06: -9998.0,
        QC_06: 1621.447,
        VC_06: -9998.0,
        QE_06: 1900.148,
        VE_06: -9998.0,
        QA_07: 963.869,
        VA_07: -9998.0,
        QC_07: 963.869,
        VC_07: -9998.0,
        QE_07: 1131.111,
        VE_07: -9998.0,
        QA_08: 675.088,
        VA_08: -9998.0,
        QC_08: 675.088,
        VC_08: -9998.0,
        QE_08: 997.456,
        VE_08: -9998.0,
        QA_09: 647.622,
        VA_09: -9998.0,
        QC_09: 1187.04,
        VC_09: -9998.0,
        QE_09: 1237.126,
        VE_09: -9998.0,
        QA_10: 794.058,
        VA_10: -9998.0,
        QC_10: 794.058,
        VC_10: -9998.0,
        QE_10: 759.007,
        VE_10: -9998.0,
        QA_11: 2182.821,
        VA_11: -9998.0,
        QC_11: 2182.821,
        VC_11: -9998.0,
        QE_11: 2129.238,
        VE_11: -9998.0,
        QA_12: 3188.352,
        VA_12: -9998.0,
        QC_12: 3188.352,
        VC_12: -9998.0,
        QE_12: 3185.207,
        VE_12: -9998.0,
        LakeFract: 0.10563839897000001,
        SurfArea: 13819115.2764,
        RAreaHLoad: 2.0965699818700001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.937342184808927, 35.588228011426622, 0.0],
          [-80.945395118129909, 35.586003878096847, 0.0],
          [-80.953940718116669, 35.580450211438745, 0.0],
          [-80.95885291810896, 35.564253811463914, 0.0],
          [-80.953811784783511, 35.555602011477276, 0.0],
          [-80.95856318477604, 35.548748278154505, 0.0],
          [-80.958843184775674, 35.543898678162179, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283904.0,
        COMID: 9752600,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.048,
        REACHCODE: "03050101000322",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00052427076894799997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061092.0,
        ToNode: 250061095.0,
        Hydroseq: 250022612.0,
        LevelPathI: 250019506.0,
        Pathlength: 679.793,
        TerminalPa: 250002604.0,
        ArbolateSu: 126.637,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250023108.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022141.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 159.4872,
        DivDASqKM: 159.4872,
        Tidal: 0,
        TOTMA: 0.0025786807361500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37993.0,
        MAXELEVSMO: 38123.0,
        MINELEVSMO: 38123.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.048000000417232999,
        QA_MA: 138.307,
        VA_MA: 0.70647,
        QC_MA: 138.335,
        VC_MA: 0.70647,
        QE_MA: 139.865,
        VE_MA: 0.70683,
        QA_01: 158.983,
        VA_01: 0.73703,
        QC_01: 126.241,
        VC_01: 0.6876,
        QE_01: 131.302,
        VE_01: 0.69368,
        QA_02: 177.166,
        VA_02: 0.7624,
        QC_02: 149.89,
        VC_02: 0.7238,
        QE_02: 154.143,
        VE_02: 0.72793,
        QA_03: 231.539,
        VA_03: 0.83174,
        QC_03: 196.982,
        VC_03: 0.78865,
        QE_03: 202.011,
        VE_03: 0.79272,
        QA_04: 192.685,
        VA_04: 0.78311,
        QC_04: 178.916,
        VC_04: 0.76474,
        QE_04: 181.441,
        VE_04: 0.76588,
        QA_05: 159.98,
        VA_05: 0.73846,
        QC_05: 150.263,
        VC_05: 0.72435,
        QE_05: 151.911,
        VE_05: 0.7247,
        QA_06: 114.069,
        VA_06: 0.66775,
        QC_06: 142.633,
        VC_06: 0.71299,
        QE_06: 144.386,
        VE_06: 0.71362,
        QA_07: 69.17,
        VA_07: 0.58392,
        QC_07: 104.261,
        VC_07: 0.65097,
        QE_07: 102.861,
        VE_07: 0.64684,
        QA_08: 64.132,
        VA_08: 0.57308,
        QC_08: 144.595,
        VC_08: 0.71594,
        QE_08: 140.336,
        VE_08: 0.70754,
        QA_09: 85.665,
        VA_09: 0.61706,
        QC_09: 198.988,
        VC_09: 0.79125,
        QE_09: 190.715,
        VE_09: 0.77815,
        QA_10: 100.32,
        VA_10: 0.64406,
        QC_10: 218.343,
        VC_10: 0.81564,
        QE_10: 209.482,
        VE_10: 0.80215,
        QA_11: 149.242,
        VA_11: 0.72288,
        QC_11: 173.901,
        VC_11: 0.7579,
        QE_11: 171.898,
        VE_11: 0.75294,
        QA_12: 157.621,
        VA_12: 0.73508,
        QC_12: 132.026,
        VC_12: 0.69673,
        QE_12: 132.544,
        VE_12: 0.69562,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.90116651664637, 35.8073368110866, 0.0],
          [-81.900660516647122, 35.807199611086787, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287059.0,
        COMID: 9737038,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.061,
        REACHCODE: "03050103000331",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011166610970984999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060107.0,
        ToNode: 250060108.0,
        Hydroseq: 250029962.0,
        LevelPathI: 250015946.0,
        Pathlength: 418.261,
        TerminalPa: 250002604.0,
        ArbolateSu: 113.627,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250031126.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028914.0,
        FromMeas: 0.0,
        ToMeas: 17.88318,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7964,
        TotDASqKM: 337.4316,
        DivDASqKM: 337.4316,
        Tidal: 0,
        TOTMA: 0.039092400212599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14254.0,
        MAXELEVSMO: 14295.0,
        MINELEVSMO: 14273.0,
        SLOPE: 0.00020735,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.060999989509583,
        QA_MA: 142.132,
        VA_MA: 1.03066,
        QC_MA: 142.011,
        VC_MA: 1.03061,
        QE_MA: 142.011,
        VE_MA: 1.03061,
        QA_01: 302.851,
        VA_01: 1.38771,
        QC_01: 236.454,
        VC_01: 1.25516,
        QE_01: 236.454,
        VE_01: 1.25516,
        QA_02: 317.647,
        VA_02: 1.4154,
        QC_02: 264.5,
        VC_02: 1.31322,
        QE_02: 264.5,
        VE_02: 1.31322,
        QA_03: 342.667,
        VA_03: 1.46087,
        QC_03: 287.775,
        VC_03: 1.35924,
        QE_03: 287.775,
        VE_03: 1.35924,
        QA_04: 203.861,
        VA_04: 1.18312,
        QC_04: 189.051,
        VC_04: 1.1491,
        QE_04: 189.051,
        VE_04: 1.1491,
        QA_05: 113.005,
        VA_05: 0.94785,
        QC_05: 107.53,
        VC_05: 0.93144,
        QE_05: 107.53,
        VE_05: 0.93144,
        QA_06: 71.705,
        VA_06: 0.81063,
        QC_06: 90.234,
        VC_06: 0.87604,
        QE_06: 90.234,
        VE_06: 0.87604,
        QA_07: 49.905,
        VA_07: 0.72271,
        QC_07: 75.812,
        VC_07: 0.82591,
        QE_07: 75.812,
        VE_07: 0.82591,
        QA_08: 36.295,
        VA_08: 0.65825,
        QC_08: 80.904,
        VC_08: 0.84408,
        QE_08: 80.904,
        VE_08: 0.84408,
        QA_09: 31.758,
        VA_09: 0.6343,
        QC_09: 82.86,
        VC_09: 0.85092,
        QE_09: 82.86,
        VE_09: 0.85092,
        QA_10: 22.758,
        VA_10: 0.58145,
        QC_10: 66.755,
        VC_10: 0.7921,
        QE_10: 66.755,
        VE_10: 0.7921,
        QA_11: 41.845,
        VA_11: 0.68571,
        QC_11: 65.94,
        VC_11: 0.78895,
        QE_11: 65.94,
        VE_11: 0.78895,
        QA_12: 172.252,
        VA_12: 1.10826,
        QC_12: 143.132,
        VC_12: 1.03363,
        QE_12: 143.132,
        VE_12: 1.03363,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.049545584634814, 34.809421879302135, 0.0],
          [-81.043827717977081, 34.806578012639989, 0.0],
          [-81.039578384650326, 34.807079212639167, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286034.0,
        COMID: 9751146,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.266,
        REACHCODE: "03050101002709",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0028776773498330002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060735.0,
        ToNode: 250060745.0,
        Hydroseq: 250029954.0,
        LevelPathI: 250029954.0,
        Pathlength: 525.265,
        TerminalPa: 250002604.0,
        ArbolateSu: 90.569,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250029954.0,
        UpHydroseq: 250031119.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008629.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5796,
        TotDASqKM: 139.0041,
        DivDASqKM: 139.0041,
        Tidal: 0,
        TOTMA: 0.33173214723,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23049.0,
        MAXELEVSMO: 23052.0,
        MINELEVSMO: 23052.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.26600000262260398,
        QA_MA: 60.842,
        VA_MA: -9998.0,
        QC_MA: 62.824,
        VC_MA: -9998.0,
        QE_MA: 62.824,
        VE_MA: -9998.0,
        QA_01: 119.776,
        VA_01: -9998.0,
        QC_01: 95.818,
        VC_01: -9998.0,
        QE_01: 95.818,
        VE_01: -9998.0,
        QA_02: 126.273,
        VA_02: -9998.0,
        QC_02: 107.823,
        VC_02: -9998.0,
        QE_02: 107.823,
        VE_02: -9998.0,
        QA_03: 136.519,
        VA_03: -9998.0,
        QC_03: 118.187,
        VC_03: -9998.0,
        QE_03: 118.187,
        VE_03: -9998.0,
        QA_04: 90.742,
        VA_04: -9998.0,
        QC_04: 85.713,
        VC_04: -9998.0,
        QE_04: 85.713,
        VE_04: -9998.0,
        QA_05: 55.92,
        VA_05: -9998.0,
        QC_05: 54.63,
        VC_05: -9998.0,
        QE_05: 54.63,
        VE_05: -9998.0,
        QA_06: 33.154,
        VA_06: -9998.0,
        QC_06: 42.165,
        VC_06: -9998.0,
        QE_06: 42.165,
        VE_06: -9998.0,
        QA_07: 21.068,
        VA_07: -9998.0,
        QC_07: 32.673,
        VC_07: -9998.0,
        QE_07: 32.673,
        VE_07: -9998.0,
        QA_08: 14.771,
        VA_08: -9998.0,
        QC_08: 32.338,
        VC_08: -9998.0,
        QE_08: 32.338,
        VE_08: -9998.0,
        QA_09: 12.978,
        VA_09: -9998.0,
        QC_09: 37.601,
        VC_09: -9998.0,
        QE_09: 37.601,
        VE_09: -9998.0,
        QA_10: 9.899,
        VA_10: -9998.0,
        QC_10: 34.332,
        VC_10: -9998.0,
        QE_10: 34.332,
        VE_10: -9998.0,
        QA_11: 29.741,
        VA_11: -9998.0,
        QC_11: 50.822,
        VC_11: -9998.0,
        QE_11: 50.822,
        VE_11: -9998.0,
        QA_12: 79.523,
        VA_12: -9998.0,
        QC_12: 70.848,
        VC_12: -9998.0,
        QE_12: 70.848,
        VE_12: -9998.0,
        LakeFract: 0.00010578466,
        SurfArea: 13838.248452,
        RAreaHLoad: 0.0899931618,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.961566118104713, 35.544654678160953, 0.0],
          [-80.958843184775674, 35.543898678162179, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283905.0,
        COMID: 9752596,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 16.38,
        REACHCODE: "03050101000323",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.15545277846011099,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061091.0,
        ToNode: 250061092.0,
        Hydroseq: 250023108.0,
        LevelPathI: 250019506.0,
        Pathlength: 679.841,
        TerminalPa: 250002604.0,
        ArbolateSu: 126.589,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250023651.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 250022611.0,
        DnDrainCou: 2,
        DnHydroseq: 250022612.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 34.6491,
        TotDASqKM: 159.4854,
        DivDASqKM: 159.4854,
        Tidal: 0,
        TOTMA: 0.36718254944899997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37948.0,
        MAXELEVSMO: 79667.0,
        MINELEVSMO: 38123.0,
        SLOPE: 0.02536263,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 16.379999160766602,
        QA_MA: 138.306,
        VA_MA: 1.69271,
        QC_MA: 138.334,
        VC_MA: 1.69273,
        QE_MA: 139.864,
        VE_MA: 1.69396,
        QA_01: 158.981,
        VA_01: 1.799,
        QC_01: 126.24,
        VC_01: 1.62709,
        QE_01: 131.3,
        VE_01: 1.64825,
        QA_02: 177.164,
        VA_02: 1.88722,
        QC_02: 149.888,
        VC_02: 1.75297,
        QE_02: 154.142,
        VE_02: 1.76736,
        QA_03: 231.537,
        VA_03: 2.12835,
        QC_03: 196.98,
        VC_03: 1.97851,
        QE_03: 202.009,
        VE_03: 1.99266,
        QA_04: 192.683,
        VA_04: 1.95921,
        QC_04: 178.915,
        VC_04: 1.89534,
        QE_04: 181.44,
        VE_04: 1.8993,
        QA_05: 159.979,
        VA_05: 1.80396,
        QC_05: 150.262,
        VC_05: 1.75488,
        QE_05: 151.91,
        VE_05: 1.7561,
        QA_06: 114.068,
        VA_06: 1.55808,
        QC_06: 142.633,
        VC_06: 1.71541,
        QE_06: 144.386,
        VE_06: 1.71757,
        QA_07: 69.17,
        VA_07: 1.26657,
        QC_07: 104.26,
        VC_07: 1.49971,
        QE_07: 102.861,
        VE_07: 1.48536,
        QA_08: 64.132,
        VA_08: 1.22885,
        QC_08: 144.595,
        VC_08: 1.72565,
        QE_08: 140.336,
        VE_08: 1.69644,
        QA_09: 85.665,
        VA_09: 1.3818,
        QC_09: 198.988,
        VC_09: 1.98753,
        QE_09: 190.715,
        VE_09: 1.94198,
        QA_10: 100.32,
        VA_10: 1.4757,
        QC_10: 218.343,
        VC_10: 2.07236,
        QE_10: 209.482,
        VE_10: 2.02546,
        QA_11: 149.241,
        VA_11: 1.74979,
        QC_11: 173.9,
        VC_11: 1.87157,
        QE_11: 171.898,
        VE_11: 1.85431,
        QA_12: 157.62,
        VA_12: 1.79221,
        QC_12: 132.025,
        VC_12: 1.65884,
        QE_12: 132.543,
        VE_12: 1.65497,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.90904318330081, 35.932803077558503, 0.0],
          [-81.908786316634462, 35.925818810902626, 0.0],
          [-81.906081783305353, 35.922087010908456, 0.0],
          [-81.902759116643892, 35.920714010910672, 0.0],
          [-81.902305916644536, 35.914027410921051, 0.0],
          [-81.89729191665225, 35.904410677602584, 0.0],
          [-81.897459716652008, 35.901525410940394, 0.0],
          [-81.900413716647449, 35.898456077611741, 0.0],
          [-81.896639383320007, 35.889800810958491, 0.0],
          [-81.899896583314955, 35.870953477654496, 0.0],
          [-81.897839516651459, 35.861473411002635, 0.0],
          [-81.900847183313488, 35.855381211011888, 0.0],
          [-81.897132983319182, 35.851924077684032, 0.0],
          [-81.91042998329857, 35.83692121104059, 0.0],
          [-81.914506783292268, 35.835110611043547, 0.0],
          [-81.913858916626637, 35.833004011046739, 0.0],
          [-81.916416116622656, 35.829888677718145, 0.0],
          [-81.915205316624508, 35.82679761105635, 0.0],
          [-81.911774183296473, 35.825837011057843, 0.0],
          [-81.907075716637166, 35.81972421106741, 0.0],
          [-81.907184583303604, 35.812098411079148, 0.0],
          [-81.90116651664637, 35.8073368110866, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287060.0,
        COMID: 9737114,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.294,
        REACHCODE: "03050103000331",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736938,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0032128553732580002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060125.0,
        ToNode: 250060107.0,
        Hydroseq: 250031126.0,
        LevelPathI: 250015946.0,
        Pathlength: 419.322,
        TerminalPa: 250002604.0,
        ArbolateSu: 112.566,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250032425.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029962.0,
        FromMeas: 17.88318,
        ToMeas: 22.8509,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1935,
        TotDASqKM: 335.6352,
        DivDASqKM: 335.6352,
        Tidal: 0,
        TOTMA: 0.00741158978,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14295.0,
        MAXELEVSMO: 14316.0,
        MINELEVSMO: 14295.0,
        SLOPE: 0.00071428,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.29399999976158098,
        QA_MA: 141.398,
        VA_MA: -9998.0,
        QC_MA: 141.306,
        VC_MA: -9998.0,
        QE_MA: 141.306,
        VE_MA: -9998.0,
        QA_01: 301.257,
        VA_01: -9998.0,
        QC_01: 235.242,
        VC_01: -9998.0,
        QE_01: 235.242,
        VE_01: -9998.0,
        QA_02: 315.98,
        VA_02: -9998.0,
        QC_02: 263.15,
        VC_02: -9998.0,
        QE_02: 263.15,
        VE_02: -9998.0,
        QA_03: 340.879,
        VA_03: -9998.0,
        QC_03: 286.323,
        VC_03: -9998.0,
        QE_03: 286.323,
        VE_03: -9998.0,
        QA_04: 202.798,
        VA_04: -9998.0,
        QC_04: 188.088,
        VC_04: -9998.0,
        QE_04: 188.088,
        VE_04: -9998.0,
        QA_05: 112.426,
        VA_05: -9998.0,
        QC_05: 106.999,
        VC_05: -9998.0,
        QE_05: 106.999,
        VE_05: -9998.0,
        QA_06: 71.343,
        VA_06: -9998.0,
        QC_06: 89.785,
        VC_06: -9998.0,
        QE_06: 89.785,
        VE_06: -9998.0,
        QA_07: 49.64,
        VA_07: -9998.0,
        QC_07: 75.418,
        VC_07: -9998.0,
        QE_07: 75.418,
        VE_07: -9998.0,
        QA_08: 36.101,
        VA_08: -9998.0,
        QC_08: 80.464,
        VC_08: -9998.0,
        QE_08: 80.464,
        VE_08: -9998.0,
        QA_09: 31.589,
        VA_09: -9998.0,
        QC_09: 82.47,
        VC_09: -9998.0,
        QE_09: 82.47,
        VE_09: -9998.0,
        QA_10: 22.637,
        VA_10: -9998.0,
        QC_10: 66.472,
        VC_10: -9998.0,
        QE_10: 66.472,
        VE_10: -9998.0,
        QA_11: 41.7,
        VA_11: -9998.0,
        QC_11: 65.765,
        VC_11: -9998.0,
        QE_11: 65.765,
        VE_11: -9998.0,
        QA_12: 171.383,
        VA_12: -9998.0,
        QC_12: 142.474,
        VC_12: -9998.0,
        QE_12: 142.474,
        VE_12: -9998.0,
        LakeFract: 1.0,
        SurfArea: 23462.479126400001,
        RAreaHLoad: 0.06809999093000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.052711117963327, 34.80979021263505, 0.0],
          [-81.049545584634814, 34.809421879302135, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285947.0,
        COMID: 9754226,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 5.615,
        REACHCODE: "03050101002622",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.056591442003820998,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061585.0,
        ToNode: 250061574.0,
        Hydroseq: 250015616.0,
        LevelPathI: 250003258.0,
        Pathlength: 671.273,
        TerminalPa: 250002604.0,
        ArbolateSu: 676.546,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015776.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015460.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.0098,
        TotDASqKM: 711.8199,
        DivDASqKM: 711.8199,
        Tidal: 0,
        TOTMA: 25.461422622499999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36429.0,
        MAXELEVSMO: 36454.0,
        MINELEVSMO: 36452.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.6149997711181641,
        QA_MA: 461.144,
        VA_MA: -9998.0,
        QC_MA: 440.18,
        VC_MA: -9998.0,
        QE_MA: 577.775,
        VE_MA: -9998.0,
        QA_01: 731.489,
        VA_01: -9998.0,
        QC_01: 558.07,
        VC_01: -9998.0,
        QE_01: 615.456,
        VE_01: -9998.0,
        QA_02: 771.242,
        VA_02: -9998.0,
        QC_02: 626.86,
        VC_02: -9998.0,
        QE_02: 637.837,
        VE_02: -9998.0,
        QA_03: 881.988,
        VA_03: -9998.0,
        QC_03: 717.943,
        VC_03: -9998.0,
        QE_03: 860.603,
        VE_03: -9998.0,
        QA_04: 661.173,
        VA_04: -9998.0,
        QC_04: 596.961,
        VC_04: -9998.0,
        QE_04: 735.732,
        VE_04: -9998.0,
        QA_05: 533.586,
        VA_05: -9998.0,
        QC_05: 479.091,
        VC_05: -9998.0,
        QE_05: 671.341,
        VE_05: -9998.0,
        QA_06: 300.262,
        VA_06: -9998.0,
        QC_06: 370.495,
        VC_06: -9998.0,
        QE_06: 567.58,
        VE_06: -9998.0,
        QA_07: 174.91,
        VA_07: -9998.0,
        QC_07: 257.858,
        VC_07: -9998.0,
        QE_07: 368.227,
        VE_07: -9998.0,
        QA_08: 119.56,
        VA_08: -9998.0,
        QC_08: 272.948,
        VC_08: -9998.0,
        QE_08: 392.179,
        VE_08: -9998.0,
        QA_09: 130.687,
        VA_09: -9998.0,
        QC_09: 288.919,
        VC_09: -9998.0,
        QE_09: 422.886,
        VE_09: -9998.0,
        QA_10: 190.114,
        VA_10: -9998.0,
        QC_10: 363.843,
        VC_10: -9998.0,
        QE_10: 440.866,
        VE_10: -9998.0,
        QA_11: 457.439,
        VA_11: -9998.0,
        QC_11: 408.58,
        VC_11: -9998.0,
        QE_11: 512.57,
        VE_11: -9998.0,
        QA_12: 584.171,
        VA_12: -9998.0,
        QC_12: 434.798,
        VC_12: -9998.0,
        QE_12: 525.028,
        VE_12: -9998.0,
        LakeFract: 0.26766587959,
        SurfArea: 6639128.1938800002,
        RAreaHLoad: 4.7121230266699996,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.977639916527664, 35.718750211224005, 0.0],
          [-81.967427983210086, 35.720920411220789, 0.0],
          [-81.970520116538694, 35.725216811214068, 0.0],
          [-81.965521583213047, 35.729362211207672, 0.0],
          [-81.969399116540444, 35.733074877868546, 0.0],
          [-81.968812583207978, 35.738964811192716, 0.0],
          [-81.962999383216982, 35.741116877856086, 0.0],
          [-81.96257391655098, 35.744317011184364, 0.0],
          [-81.957735583225201, 35.745772277848857, 0.0],
          [-81.953066183232465, 35.749956477842261, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283906.0,
        COMID: 9751752,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 4.913,
        REACHCODE: "03050101000324",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.050114876850905002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060859.0,
        ToNode: 250061091.0,
        Hydroseq: 250023651.0,
        LevelPathI: 250019506.0,
        Pathlength: 696.221,
        TerminalPa: 250002604.0,
        ArbolateSu: 108.287,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250024235.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023108.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6928,
        TotDASqKM: 122.7834,
        DivDASqKM: 122.7834,
        Tidal: 0,
        TOTMA: 0.118358989463,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 79667.0,
        MAXELEVSMO: 89726.0,
        MINELEVSMO: 79667.0,
        SLOPE: 0.02047425,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.9130001068115234,
        QA_MA: 112.892,
        VA_MA: 1.57433,
        QC_MA: 113.808,
        VC_MA: 1.57516,
        QE_MA: 114.986,
        VE_MA: 1.57622,
        QA_01: 120.487,
        VA_01: 1.61886,
        QC_01: 96.371,
        VC_01: 1.46809,
        QE_01: 100.267,
        VE_01: 1.48728,
        QA_02: 137.333,
        VA_02: 1.71317,
        QC_02: 116.999,
        VC_02: 1.5939,
        QE_02: 120.274,
        VE_02: 1.60685,
        QA_03: 185.09,
        VA_03: 1.95439,
        QC_03: 158.633,
        VC_03: 1.81944,
        QE_03: 162.505,
        VE_03: 1.83184,
        QA_04: 156.72,
        VA_04: 1.8152,
        QC_04: 146.207,
        VC_04: 1.75539,
        QE_04: 148.15,
        VE_04: 1.75883,
        QA_05: 129.843,
        VA_05: 1.67195,
        QC_05: 122.912,
        VC_05: 1.62799,
        QE_05: 124.181,
        VE_05: 1.62908,
        QA_06: 97.467,
        VA_06: 1.47932,
        QC_06: 122.138,
        VC_06: 1.62358,
        QE_06: 123.487,
        VE_06: 1.62515,
        QA_07: 59.577,
        VA_07: 1.20999,
        QC_07: 90.122,
        VC_07: 1.42753,
        QE_07: 89.044,
        VE_07: 1.41526,
        QA_08: 57.754,
        VA_08: 1.19524,
        QC_08: 129.941,
        VC_08: 1.66754,
        QE_08: 126.662,
        VE_08: 1.64302,
        QA_09: 77.336,
        VA_09: 1.34396,
        QC_09: 181.806,
        VC_09: 1.93289,
        QE_09: 175.436,
        VE_09: 1.89505,
        QA_10: 87.051,
        VA_10: 1.41112,
        QC_10: 194.949,
        VC_10: 1.99423,
        QE_10: 188.127,
        VE_10: 1.955,
        QA_11: 121.392,
        VA_11: 1.62408,
        QC_11: 148.557,
        VC_11: 1.76769,
        QE_11: 147.015,
        VE_11: 1.75292,
        QA_12: 125.451,
        VA_12: 1.64726,
        QC_12: 107.265,
        VC_12: 1.53595,
        QE_12: 107.664,
        VE_12: 1.53269,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.918507783286088, 35.939165677548658, 0.0],
          [-81.915945716623412, 35.939693410881091, 0.0],
          [-81.925402116608723, 35.932796477558497, 0.0],
          [-81.918839716618891, 35.929799610896453, 0.0],
          [-81.919827583283961, 35.933463210890864, 0.0],
          [-81.916955716621828, 35.93369321089051, 0.0],
          [-81.915321183290985, 35.931152077560967, 0.0],
          [-81.91706558328832, 35.929090410897629, 0.0],
          [-81.915178516624508, 35.92808361089908, 0.0],
          [-81.912420516628856, 35.92984787756302, 0.0],
          [-81.913323383294085, 35.933328277557735, 0.0],
          [-81.90904318330081, 35.932803077558503, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287061.0,
        COMID: 9737026,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 2.936,
        REACHCODE: "03050103000332",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.028449989163216002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060103.0,
        ToNode: 250060106.0,
        Hydroseq: 250033899.0,
        LevelPathI: 250015946.0,
        Pathlength: 424.222,
        TerminalPa: 250002604.0,
        ArbolateSu: 87.329,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250035561.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032425.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9574,
        TotDASqKM: 266.805,
        DivDASqKM: 266.805,
        Tidal: 0,
        TOTMA: 0.093568503690299995,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14551.0,
        MAXELEVSMO: 14893.0,
        MINELEVSMO: 14592.0,
        SLOPE: 0.0010252,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9360001087188721,
        QA_MA: 112.712,
        VA_MA: 1.19092,
        QC_MA: 113.634,
        VC_MA: 1.19151,
        QE_MA: 113.634,
        VE_MA: 1.19151,
        QA_01: 239.67,
        VA_01: 1.62576,
        QC_01: 188.276,
        VC_01: 1.46306,
        QE_01: 188.276,
        VE_01: 1.46306,
        QA_02: 251.286,
        VA_02: 1.65929,
        QC_02: 210.583,
        VC_02: 1.53379,
        QE_02: 210.583,
        VE_02: 1.53379,
        QA_03: 271.42,
        VA_03: 1.71573,
        QC_03: 229.702,
        VC_03: 1.59167,
        QE_03: 229.702,
        VE_03: 1.59167,
        QA_04: 161.47,
        VA_04: 1.37654,
        QC_04: 150.535,
        VC_04: 1.33373,
        QE_04: 150.535,
        VE_04: 1.33373,
        QA_05: 89.51,
        VA_05: 1.08926,
        QC_05: 85.919,
        VC_05: 1.06967,
        QE_05: 85.919,
        VE_05: 1.06967,
        QA_06: 56.868,
        VA_06: 0.92211,
        QC_06: 71.792,
        VC_06: 1.00041,
        QE_06: 71.792,
        VE_06: 1.00041,
        QA_07: 39.46,
        VA_07: 0.81386,
        QC_07: 60.282,
        VC_07: 0.93909,
        QE_07: 60.282,
        VE_07: 0.93909,
        QA_08: 28.698,
        VA_08: 0.73522,
        QC_08: 63.67,
        VC_08: 0.95767,
        QE_08: 63.67,
        VE_08: 0.95767,
        QA_09: 25.111,
        VA_09: 0.706,
        QC_09: 67.343,
        VC_09: 0.97729,
        QE_09: 67.343,
        VE_09: 0.97729,
        QA_10: 18.01,
        VA_10: 0.64167,
        QC_10: 55.374,
        VC_10: 0.91128,
        QE_10: 55.374,
        VE_10: 0.91128,
        QA_11: 34.607,
        VA_11: 0.77983,
        QC_11: 57.049,
        VC_11: 0.92089,
        QE_11: 57.049,
        VE_11: 0.92089,
        QA_12: 137.134,
        VA_12: 1.28779,
        QC_12: 116.317,
        VC_12: 1.20252,
        QE_12: 116.317,
        VE_12: 1.20252,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.061977717948935, 34.856729479228818, 0.0],
          [-81.06523038461053, 34.854486279232219, 0.0],
          [-81.064873784611052, 34.848438879241655, 0.0],
          [-81.068042784606178, 34.846126812578575, 0.0],
          [-81.067320984607306, 34.845210279246658, 0.0],
          [-81.065069517944153, 34.846721212577677, 0.0],
          [-81.064237184612011, 34.845300479246475, 0.0],
          [-81.065099984610754, 34.843147812583254, 0.0],
          [-81.063351917946704, 34.840352412587492, 0.0],
          [-81.06521398461058, 34.839345412589125, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285948.0,
        COMID: 9754228,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.024,
        REACHCODE: "03050101002623",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010235800041254,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061586.0,
        ToNode: 250061585.0,
        Hydroseq: 250015776.0,
        LevelPathI: 250003258.0,
        Pathlength: 676.888,
        TerminalPa: 250002604.0,
        ArbolateSu: 665.238,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015943.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015616.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8235,
        TotDASqKM: 693.1485,
        DivDASqKM: 693.1485,
        Tidal: 0,
        TOTMA: 4.6433654077100002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36443.0,
        MAXELEVSMO: 36454.0,
        MINELEVSMO: 36454.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0240000486373899,
        QA_MA: 449.646,
        VA_MA: -9998.0,
        QC_MA: 429.625,
        VC_MA: -9998.0,
        QE_MA: 567.22,
        VE_MA: -9998.0,
        QA_01: 712.748,
        VA_01: -9998.0,
        QC_01: 544.142,
        VC_01: -9998.0,
        QE_01: 601.528,
        VE_01: -9998.0,
        QA_02: 751.137,
        VA_02: -9998.0,
        QC_02: 610.958,
        VC_02: -9998.0,
        QE_02: 621.935,
        VE_02: -9998.0,
        QA_03: 859.179,
        VA_03: -9998.0,
        QC_03: 699.982,
        VC_03: -9998.0,
        QE_03: 842.641,
        VE_03: -9998.0,
        QA_04: 644.054,
        VA_04: -9998.0,
        QC_04: 581.851,
        VC_04: -9998.0,
        QE_04: 720.623,
        VE_04: -9998.0,
        QA_05: 520.288,
        VA_05: -9998.0,
        QC_05: 467.593,
        VC_05: -9998.0,
        QE_05: 659.842,
        VE_05: -9998.0,
        QA_06: 292.979,
        VA_06: -9998.0,
        QC_06: 361.63,
        VC_06: -9998.0,
        QE_06: 558.716,
        VE_06: -9998.0,
        QA_07: 170.617,
        VA_07: -9998.0,
        QC_07: 251.678,
        VC_07: -9998.0,
        QE_07: 361.971,
        VE_07: -9998.0,
        QA_08: 116.751,
        VA_08: -9998.0,
        QC_08: 266.408,
        VC_08: -9998.0,
        QE_08: 385.485,
        VE_08: -9998.0,
        QA_09: 128.582,
        VA_09: -9998.0,
        QC_09: 284.805,
        VC_09: -9998.0,
        QE_09: 418.739,
        VE_09: -9998.0,
        QA_10: 187.673,
        VA_10: -9998.0,
        QC_10: 360.105,
        VC_10: -9998.0,
        QE_10: 437.134,
        VE_10: -9998.0,
        QA_11: 446.015,
        VA_11: -9998.0,
        QC_11: 400.775,
        VC_11: -9998.0,
        QE_11: 504.784,
        VE_11: -9998.0,
        QA_12: 568.579,
        VA_12: -9998.0,
        QC_12: 424.227,
        VC_12: -9998.0,
        QE_12: 514.444,
        VE_12: -9998.0,
        LakeFract: 0.04793131038,
        SurfArea: 1188878.1439,
        RAreaHLoad: 0.85934354036000005,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.985211583182547, 35.725340211213791, 0.0],
          [-81.977639916527664, 35.718750211224005, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283883.0,
        COMID: 9753898,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 5.813,
        REACHCODE: "03050101000301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.060523085794835001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061435.0,
        ToNode: 250061174.0,
        Hydroseq: 250012898.0,
        LevelPathI: 250003258.0,
        Pathlength: 637.58,
        TerminalPa: 250002604.0,
        ArbolateSu: 1188.024,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012996.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012799.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.8579,
        TotDASqKM: 1325.1213,
        DivDASqKM: 1325.1213,
        Tidal: 0,
        TOTMA: 0.13771507240799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30529.0,
        MAXELEVSMO: 31129.0,
        MINELEVSMO: 30635.0,
        SLOPE: 0.00084981,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.8130002021789551,
        QA_MA: 877.264,
        VA_MA: 1.59219,
        QC_MA: 816.744,
        VC_MA: 1.58478,
        QE_MA: 971.471,
        VE_MA: 1.60284,
        QA_01: 1353.279,
        VA_01: 1.92446,
        QC_01: 1015.934,
        VC_01: 1.7416,
        QE_01: 1129.996,
        VE_01: 1.71102,
        QA_02: 1436.88,
        VA_02: 1.97673,
        QC_02: 1148.244,
        VC_02: 1.83786,
        QE_02: 1206.863,
        VE_02: 1.7609,
        QA_03: 1654.237,
        VA_03: 2.10631,
        QC_03: 1318.891,
        VC_03: 1.95464,
        QE_03: 1517.874,
        VE_03: 1.94896,
        QA_04: 1260.285,
        VA_04: 1.86451,
        QC_04: 1121.326,
        VC_04: 1.81872,
        QE_04: 1288.378,
        VE_04: 1.8122,
        QA_05: 1008.227,
        VA_05: 1.69063,
        QC_05: 1008.227,
        VC_05: 1.73582,
        QE_05: 1218.94,
        VE_05: 1.7686,
        QA_06: 588.372,
        VA_06: 1.34683,
        QC_06: 588.372,
        VC_06: 1.38077,
        QE_06: 805.091,
        VE_06: 1.47995,
        QA_07: 345.835,
        VA_07: 1.09152,
        QC_07: 501.592,
        VC_07: 1.29365,
        QE_07: 613.434,
        VE_07: 1.32245,
        QA_08: 252.247,
        VA_08: 0.9707,
        QC_08: 584.536,
        VC_08: 1.37706,
        QE_08: 809.054,
        VE_08: 1.48301,
        QA_09: 268.512,
        VA_09: 0.99305,
        QC_09: 545.613,
        VC_09: 1.33866,
        QE_09: 595.751,
        VE_09: 1.30682,
        QA_10: 370.977,
        VA_10: 1.12126,
        QC_10: 620.637,
        VC_10: 1.41161,
        QE_10: 612.378,
        VE_10: 1.32153,
        QA_11: 881.752,
        VA_11: 1.59567,
        QC_11: 673.964,
        VC_11: 1.46097,
        QE_11: 645.844,
        VE_11: 1.35056,
        QA_12: 1112.363,
        VA_12: 1.76469,
        QC_12: 781.22,
        VC_12: 1.55499,
        QE_12: 799.798,
        VE_12: 1.47585,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.769748183516924, 35.741258611189217, 0.0],
          [-81.753606716875311, 35.734282477866657, 0.0],
          [-81.747001783552207, 35.735903077864123, 0.0],
          [-81.746503583553078, 35.740552477856966, 0.0],
          [-81.740246783562782, 35.745299477849528, 0.0],
          [-81.732288316908466, 35.738963611192787, 0.0],
          [-81.720296183593746, 35.740325411190497, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283884.0,
        COMID: 9753846,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.143,
        REACHCODE: "03050101000302",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.032014772334078997,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061421.0,
        ToNode: 250061435.0,
        Hydroseq: 250012996.0,
        LevelPathI: 250003258.0,
        Pathlength: 643.393,
        TerminalPa: 250002604.0,
        ArbolateSu: 1179.495,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013087.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012898.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5603,
        TotDASqKM: 1314.3942,
        DivDASqKM: 1314.3942,
        Tidal: 0,
        TOTMA: 0.086779717344500004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30925.0,
        MAXELEVSMO: 31209.0,
        MINELEVSMO: 31129.0,
        SLOPE: 0.00025453,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.1429998874664311,
        QA_MA: 871.58,
        VA_MA: 1.36641,
        QC_MA: 811.657,
        VC_MA: 1.36032,
        QE_MA: 966.384,
        VE_MA: 1.3753,
        QA_01: 1342.991,
        VA_01: 1.63945,
        QC_01: 1008.413,
        VC_01: 1.48883,
        QE_01: 1122.474,
        VE_01: 1.46358,
        QA_02: 1425.884,
        VA_02: 1.68246,
        QC_02: 1139.696,
        VC_02: 1.56809,
        QE_02: 1198.315,
        VE_02: 1.50439,
        QA_03: 1642.52,
        VA_03: 1.78963,
        QC_03: 1309.856,
        VC_03: 1.66469,
        QE_03: 1508.84,
        VE_03: 1.66006,
        QA_04: 1251.734,
        VA_04: 1.59063,
        QC_04: 1113.89,
        VC_04: 1.55286,
        QE_04: 1280.941,
        VE_04: 1.5475,
        QA_05: 1002.162,
        VA_05: 1.44782,
        QC_05: 1002.162,
        VC_05: 1.48494,
        QE_05: 1212.875,
        VE_05: 1.51209,
        QA_06: 584.937,
        VA_06: 1.16449,
        QC_06: 584.937,
        VC_06: 1.19238,
        QE_06: 801.656,
        VE_06: 1.27451,
        QA_07: 343.817,
        VA_07: 0.95401,
        QC_07: 498.735,
        VC_07: 1.12062,
        QE_07: 595.912,
        VE_07: 1.13373,
        QA_08: 250.847,
        VA_08: 0.85449,
        QC_08: 581.226,
        VC_08: 1.1894,
        QE_08: 657.745,
        VE_08: 1.17815,
        QA_09: 267.4,
        VA_09: 0.87334,
        QC_09: 543.617,
        VC_09: 1.15865,
        QE_09: 586.037,
        VE_09: 1.12644,
        QA_10: 370.112,
        VA_10: 0.97978,
        QC_10: 619.481,
        VC_10: 1.21973,
        QE_10: 597.072,
        VE_10: 1.13458,
        QA_11: 877.356,
        VA_11: 1.37013,
        QC_11: 671.4,
        VC_11: 1.25952,
        QE_11: 752.333,
        VE_11: 1.24247,
        QA_12: 1104.919,
        VA_12: 1.50844,
        QC_12: 776.462,
        VC_12: 1.33585,
        QE_12: 872.906,
        VE_12: 1.31919,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.793432316813551, 35.757162477831173, 0.0],
          [-81.790932516817406, 35.756451011165495, 0.0],
          [-81.789448983486352, 35.751091077840556, 0.0],
          [-81.778531783503297, 35.748799811177435, 0.0],
          [-81.769748183516924, 35.741258611189217, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287055.0,
        COMID: 9737054,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 4.075,
        REACHCODE: "03050103000328",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.040927633970559998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060112.0,
        ToNode: 250059410.0,
        Hydroseq: 250027080.0,
        LevelPathI: 250015946.0,
        Pathlength: 411.284,
        TerminalPa: 250002604.0,
        ArbolateSu: 179.013,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250027952.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026287.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 13.788,
        TotDASqKM: 548.3079,
        DivDASqKM: 548.3079,
        Tidal: 0,
        TOTMA: 0.10073477305300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12548.0,
        MAXELEVSMO: 14068.0,
        MINELEVSMO: 12874.0,
        SLOPE: 0.00293006,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.0749998092651367,
        QA_MA: 229.406,
        VA_MA: 1.53803,
        QC_MA: 224.991,
        VC_MA: 1.5361,
        QE_MA: 224.991,
        VE_MA: 1.5361,
        QA_01: 491.931,
        VA_01: 2.15212,
        QC_01: 379.227,
        VC_01: 1.9283,
        QE_01: 379.227,
        VE_01: 1.9283,
        QA_02: 515.903,
        VA_02: 2.19929,
        QC_02: 423.943,
        VC_02: 2.02707,
        QE_02: 423.943,
        VE_02: 2.02707,
        QA_03: 554.763,
        VA_03: 2.27364,
        QC_03: 458.545,
        VC_03: 2.10018,
        QE_03: 458.545,
        VE_03: 2.10018,
        QA_04: 329.784,
        VA_04: 1.79939,
        QC_04: 302.501,
        VC_04: 1.74507,
        QE_04: 302.501,
        VE_04: 1.74507,
        QA_05: 182.773,
        VA_05: 1.39825,
        QC_05: 170.818,
        VC_05: 1.36904,
        QE_05: 170.818,
        VE_05: 1.36904,
        QA_06: 115.948,
        VA_06: 1.16429,
        QC_06: 144.951,
        VC_06: 1.28047,
        QE_06: 144.951,
        VE_06: 1.28047,
        QA_07: 79.999,
        VA_07: 1.0112,
        QC_07: 120.165,
        VC_07: 1.18834,
        QE_07: 120.165,
        VE_07: 1.18834,
        QA_08: 58.977,
        VA_08: 0.90615,
        QC_08: 132.748,
        VC_08: 1.23612,
        QE_08: 132.748,
        VE_08: 1.23612,
        QA_09: 51.314,
        VA_09: 0.86357,
        QC_09: 126.568,
        VC_09: 1.21293,
        QE_09: 126.568,
        VE_09: 1.21293,
        QA_10: 36.934,
        VA_10: 0.77462,
        QC_10: 98.283,
        VC_10: 1.09925,
        QE_10: 98.283,
        VE_10: 1.09925,
        QA_11: 60.722,
        VA_11: 0.91548,
        QC_11: 87.592,
        VC_11: 1.05234,
        QE_11: 87.592,
        VE_11: 1.05234,
        QA_12: 275.408,
        VA_12: 1.66334,
        QC_12: 219.366,
        VC_12: 1.5197,
        QE_12: 219.366,
        VE_12: 1.5197,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.02999198466523, 34.788037212668769, 0.0],
          [-81.027970318001735, 34.783729279342083, 0.0],
          [-81.023056318009367, 34.783817012675172, 0.0],
          [-81.019423718014991, 34.780423879347268, 0.0],
          [-81.012875184691723, 34.778081679350862, 0.0],
          [-81.009070384697623, 34.779177812682526, 0.0],
          [-81.003773918039258, 34.775255812688556, 0.0],
          [-80.999802518045385, 34.769112812698097, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283885.0,
        COMID: 9752864,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.187,
        REACHCODE: "03050101000303",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018796835023010001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061156.0,
        ToNode: 250061421.0,
        Hydroseq: 250013087.0,
        LevelPathI: 250003258.0,
        Pathlength: 646.536,
        TerminalPa: 250002604.0,
        ArbolateSu: 1174.374,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013182.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012996.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 1307.817,
        DivDASqKM: 1307.817,
        Tidal: 0,
        TOTMA: 0.0075106747906499996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31158.0,
        MAXELEVSMO: 31209.0,
        MINELEVSMO: 31209.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.187000006437302,
        QA_MA: 868.01,
        VA_MA: 0.94009,
        QC_MA: 808.461,
        VC_MA: 0.93646,
        QE_MA: 963.188,
        VE_MA: 0.94544,
        QA_01: 1336.64,
        VA_01: 1.10285,
        QC_01: 1003.768,
        VC_01: 1.01294,
        QE_01: 1117.83,
        VE_01: 0.99785,
        QA_02: 1419.148,
        VA_02: 1.12852,
        QC_02: 1134.458,
        VC_02: 1.06025,
        QE_02: 1193.077,
        VE_02: 1.02212,
        QA_03: 1635.273,
        VA_03: 1.19264,
        QC_03: 1304.267,
        VC_03: 1.11806,
        QE_03: 1503.251,
        VE_03: 1.1153,
        QA_04: 1246.345,
        VA_04: 1.07388,
        QC_04: 1109.203,
        VC_04: 1.05132,
        QE_04: 1276.255,
        VE_04: 1.04812,
        QA_05: 998.237,
        VA_05: 0.98876,
        QC_05: 998.237,
        VC_05: 1.01088,
        QE_05: 1208.95,
        VE_05: 1.02715,
        QA_06: 582.731,
        VA_06: 0.81962,
        QC_06: 582.731,
        VC_06: 0.83624,
        QE_06: 799.45,
        VE_06: 0.88543,
        QA_07: 342.507,
        VA_07: 0.69392,
        QC_07: 496.88,
        VC_07: 0.7934,
        QE_07: 594.03,
        VE_07: 0.80124,
        QA_08: 249.962,
        VA_08: 0.63455,
        QC_08: 579.135,
        VC_08: 0.8345,
        QE_08: 655.599,
        VE_08: 0.82773,
        QA_09: 266.693,
        VA_09: 0.64596,
        QC_09: 542.347,
        VC_09: 0.81648,
        QE_09: 584.755,
        VE_09: 0.79714,
        QA_10: 369.581,
        VA_10: 0.70982,
        QC_10: 618.771,
        VC_10: 0.85333,
        QE_10: 596.365,
        VE_10: 0.80227,
        QA_11: 874.415,
        VA_11: 0.94256,
        QC_11: 669.683,
        VC_11: 0.87669,
        QE_11: 750.623,
        VE_11: 0.86644,
        QA_12: 1100.266,
        VA_12: 1.02485,
        QC_12: 773.487,
        VC_12: 0.92189,
        QE_12: 869.926,
        VE_12: 0.9119,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.794866183477893, 35.755972611166214, 0.0],
          [-81.793432316813551, 35.757162477831173, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287056.0,
        COMID: 9737046,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.077,
        REACHCODE: "03050103000329",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0099418395217630001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060109.0,
        ToNode: 250060112.0,
        Hydroseq: 250027952.0,
        LevelPathI: 250015946.0,
        Pathlength: 415.359,
        TerminalPa: 250002604.0,
        ArbolateSu: 127.221,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250028914.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027080.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7101,
        TotDASqKM: 362.7504,
        DivDASqKM: 362.7504,
        Tidal: 0,
        TOTMA: 0.031212094191599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14068.0,
        MAXELEVSMO: 14226.0,
        MINELEVSMO: 14068.0,
        SLOPE: 0.00146703,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0770000219345091,
        QA_MA: 152.456,
        VA_MA: 1.31057,
        QC_MA: 151.912,
        VC_MA: 1.31028,
        QE_MA: 151.912,
        VE_MA: 1.31028,
        QA_01: 325.299,
        VA_01: 1.8071,
        QC_01: 253.505,
        VC_01: 1.62335,
        QE_01: 253.505,
        VE_01: 1.62335,
        QA_02: 341.025,
        VA_02: 1.84515,
        QC_02: 283.417,
        VC_02: 1.70358,
        QE_02: 283.417,
        VE_02: 1.70358,
        QA_03: 367.768,
        VA_03: 1.908,
        QC_03: 308.136,
        VC_03: 1.76692,
        QE_03: 308.136,
        VE_03: 1.76692,
        QA_04: 218.702,
        VA_04: 1.52222,
        QC_04: 202.491,
        VC_04: 1.47546,
        QE_04: 202.491,
        VE_04: 1.47546,
        QA_05: 121.175,
        VA_05: 1.19552,
        QC_05: 115.004,
        VC_05: 1.17263,
        QE_05: 115.004,
        VE_05: 1.17263,
        QA_06: 76.811,
        VA_06: 1.00479,
        QC_06: 96.569,
        VC_06: 1.09606,
        QE_06: 96.569,
        VE_06: 1.09606,
        QA_07: 53.65,
        VA_07: 0.88396,
        QC_07: 81.36,
        VC_07: 1.02755,
        QE_07: 81.36,
        VE_07: 1.02755,
        QA_08: 39.018,
        VA_08: 0.79441,
        QC_08: 87.1,
        VC_08: 1.05406,
        QE_08: 87.1,
        VE_08: 1.05406,
        QA_09: 34.141,
        VA_09: 0.76115,
        QC_09: 88.325,
        VC_09: 1.05961,
        QE_09: 88.325,
        VE_09: 1.05961,
        QA_10: 24.46,
        VA_10: 0.68767,
        QC_10: 70.715,
        VC_10: 0.97594,
        QE_10: 70.715,
        VE_10: 0.97594,
        QA_11: 43.978,
        VA_11: 0.8263,
        QC_11: 68.487,
        VC_11: 0.96469,
        QE_11: 68.487,
        VE_11: 0.96469,
        QA_12: 184.469,
        VA_12: 1.41731,
        QC_12: 152.339,
        VC_12: 1.31178,
        QE_12: 152.339,
        VE_12: 1.31178,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.029759517998983, 34.796970479321544, 0.0],
          [-81.028546184667448, 34.789799879332577, 0.0],
          [-81.02999198466523, 34.788037212668769, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285943.0,
        COMID: 9754210,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.8,
        REACHCODE: "03050101002618",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.017795436259026999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061577.0,
        ToNode: 250061579.0,
        Hydroseq: 250019506.0,
        LevelPathI: 250019506.0,
        Pathlength: 663.718,
        TerminalPa: 250002604.0,
        ArbolateSu: 191.771,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250019849.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015002.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3401,
        TotDASqKM: 240.237,
        DivDASqKM: 240.237,
        Tidal: 0,
        TOTMA: 8.1621657566200003,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35373.0,
        MAXELEVSMO: 36464.0,
        MINELEVSMO: 35708.0,
        SLOPE: 0.0042,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.799999952316284,
        QA_MA: 186.134,
        VA_MA: -9998.0,
        QC_MA: 184.039,
        VC_MA: -9998.0,
        QE_MA: 201.172,
        VE_MA: -9998.0,
        QA_01: 239.822,
        VA_01: -9998.0,
        QC_01: 188.391,
        VC_01: -9998.0,
        QE_01: 245.066,
        VE_01: -9998.0,
        QA_02: 261.769,
        VA_02: -9998.0,
        QC_02: 219.124,
        VC_02: -9998.0,
        QE_02: 266.765,
        VE_02: -9998.0,
        QA_03: 325.293,
        VA_03: -9998.0,
        QC_03: 273.654,
        VC_03: -9998.0,
        QE_03: 329.978,
        VE_03: -9998.0,
        QA_04: 263.335,
        VA_04: -9998.0,
        QC_04: 242.788,
        VC_04: -9998.0,
        QE_04: 271.068,
        VE_04: -9998.0,
        QA_05: 214.998,
        VA_05: -9998.0,
        QC_05: 199.718,
        VC_05: -9998.0,
        QE_05: 218.181,
        VE_05: -9998.0,
        QA_06: 144.213,
        VA_06: -9998.0,
        QC_06: 179.746,
        VC_06: -9998.0,
        QE_06: 199.379,
        VE_06: -9998.0,
        QA_07: 86.901,
        VA_07: -9998.0,
        QC_07: 130.274,
        VC_07: -9998.0,
        QE_07: 114.598,
        VE_07: -9998.0,
        QA_08: 76.271,
        VA_08: -9998.0,
        QC_08: 172.56,
        VC_08: -9998.0,
        QE_08: 124.863,
        VE_08: -9998.0,
        QA_09: 94.999,
        VA_09: -9998.0,
        QC_09: 218.014,
        VC_09: -9998.0,
        QE_09: 125.353,
        VE_09: -9998.0,
        QA_10: 111.585,
        VA_10: -9998.0,
        QC_10: 237.716,
        VC_10: -9998.0,
        QE_10: 138.467,
        VE_10: -9998.0,
        QA_11: 195.452,
        VA_11: -9998.0,
        QC_11: 213.621,
        VC_11: -9998.0,
        QE_11: 191.189,
        VE_11: -9998.0,
        QA_12: 220.275,
        VA_12: -9998.0,
        QC_12: 179.022,
        VC_12: -9998.0,
        QE_12: 184.823,
        VE_12: -9998.0,
        LakeFract: 0.02992522644,
        SurfArea: 742259.02408799995,
        RAreaHLoad: 1.5105648172,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.879940516679312, 35.746499411181048, 0.0],
          [-81.887872583333674, 35.740270011190724, 0.0],
          [-81.890931916662225, 35.736572411196391, 0.0],
          [-81.890335116663096, 35.734333477866414, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283886.0,
        COMID: 9753844,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.624,
        REACHCODE: "03050101000304",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015709915780564,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061420.0,
        ToNode: 250061156.0,
        Hydroseq: 250013182.0,
        LevelPathI: 250003258.0,
        Pathlength: 646.723,
        TerminalPa: 250002604.0,
        ArbolateSu: 1172.238,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013280.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013087.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3797,
        TotDASqKM: 1304.7984,
        DivDASqKM: 1304.7984,
        Tidal: 0,
        TOTMA: 0.045214193831100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31170.0,
        MAXELEVSMO: 31248.0,
        MINELEVSMO: 31209.0,
        SLOPE: 0.00024014,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6239999532699581,
        QA_MA: 866.331,
        VA_MA: 1.35501,
        QC_MA: 806.957,
        VC_MA: 1.34901,
        QE_MA: 961.685,
        VE_MA: 1.3639,
        QA_01: 1333.694,
        VA_01: 1.62447,
        QC_01: 1001.614,
        VC_01: 1.47554,
        QE_01: 1115.676,
        VE_01: 1.45052,
        QA_02: 1416.024,
        VA_02: 1.66701,
        QC_02: 1132.029,
        VC_02: 1.55391,
        QE_02: 1190.648,
        VE_02: 1.49065,
        QA_03: 1631.906,
        VA_03: 1.77333,
        QC_03: 1301.67,
        VC_03: 1.64978,
        QE_03: 1500.654,
        VE_03: 1.64522,
        QA_04: 1243.825,
        VA_04: 1.57661,
        QC_04: 1107.011,
        VC_04: 1.53922,
        QE_04: 1274.063,
        VE_04: 1.53393,
        QA_05: 996.356,
        VA_05: 1.43568,
        QC_05: 996.356,
        VC_05: 1.47228,
        QE_05: 1207.069,
        VE_05: 1.49928,
        QA_06: 581.677,
        VA_06: 1.15547,
        QC_06: 581.677,
        VC_06: 1.18297,
        QE_06: 798.396,
        VE_06: 1.26459,
        QA_07: 341.898,
        VA_07: 0.94721,
        QC_07: 496.018,
        VC_07: 1.11203,
        QE_07: 593.155,
        VE_07: 1.12502,
        QA_08: 249.533,
        VA_08: 0.84884,
        QC_08: 578.121,
        VC_08: 1.18013,
        QE_08: 654.56,
        VE_08: 1.16887,
        QA_09: 266.368,
        VA_09: 0.8679,
        QC_09: 541.764,
        VC_09: 1.15058,
        QE_09: 584.166,
        VE_09: 1.11842,
        QA_10: 369.337,
        VA_10: 0.97394,
        QC_10: 618.445,
        VC_10: 1.2119,
        QE_10: 596.04,
        VE_10: 1.12713,
        QA_11: 872.946,
        VA_11: 1.35925,
        QC_11: 668.825,
        VC_11: 1.25027,
        QE_11: 749.768,
        VE_11: 1.23321,
        QA_12: 1098.06,
        VA_12: 1.49539,
        QC_12: 772.076,
        VC_12: 1.32488,
        QE_12: 868.513,
        VE_12: 1.3083,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.793565516813374, 35.744138277851334, 0.0],
          [-81.792328383481959, 35.745282611182859, 0.0],
          [-81.793084783480765, 35.747481477846236, 0.0],
          [-81.797679583473609, 35.751875211172717, 0.0],
          [-81.794866183477893, 35.755972611166214, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287057.0,
        COMID: 9737044,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 1.825,
        REACHCODE: "03050103000330",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018163367946560999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060108.0,
        ToNode: 250060109.0,
        Hydroseq: 250028914.0,
        LevelPathI: 250015946.0,
        Pathlength: 416.436,
        TerminalPa: 250002604.0,
        ArbolateSu: 120.306,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250029962.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027952.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5965,
        TotDASqKM: 349.47,
        DivDASqKM: 349.47,
        Tidal: 0,
        TOTMA: 0.065264827960800006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14078.0,
        MAXELEVSMO: 14273.0,
        MINELEVSMO: 14226.0,
        SLOPE: 0.00025753,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.825000047683716,
        QA_MA: 147.06,
        VA_MA: 1.06197,
        QC_MA: 146.74,
        VC_MA: 1.06183,
        QE_MA: 146.74,
        VE_MA: 1.06183,
        QA_01: 313.534,
        VA_01: 1.43485,
        QC_01: 244.572,
        VC_01: 1.29662,
        QE_01: 244.572,
        VE_01: 1.29662,
        QA_02: 328.814,
        VA_02: 1.46367,
        QC_02: 273.54,
        VC_02: 1.35714,
        QE_02: 273.54,
        VE_02: 1.35714,
        QA_03: 354.644,
        VA_03: 1.51101,
        QC_03: 297.496,
        VC_03: 1.40495,
        QE_03: 297.496,
        VE_03: 1.40495,
        QA_04: 210.978,
        VA_04: 1.22115,
        QC_04: 195.499,
        VC_04: 1.18582,
        QE_04: 195.499,
        VE_04: 1.18582,
        QA_05: 116.89,
        VA_05: 0.97547,
        QC_05: 111.086,
        VC_05: 0.9583,
        QE_05: 111.086,
        VE_05: 0.9583,
        QA_06: 74.133,
        VA_06: 0.83218,
        QC_06: 93.247,
        VC_06: 0.90062,
        QE_06: 93.247,
        VE_06: 0.90062,
        QA_07: 51.686,
        VA_07: 0.74089,
        QC_07: 78.451,
        VC_07: 0.8487,
        QE_07: 78.451,
        VE_07: 0.8487,
        QA_08: 37.59,
        VA_08: 0.67361,
        QC_08: 83.849,
        VC_08: 0.86813,
        QE_08: 83.849,
        VE_08: 0.86813,
        QA_09: 32.891,
        VA_09: 0.6486,
        QC_09: 85.464,
        VC_09: 0.87383,
        QE_09: 85.464,
        VE_09: 0.87383,
        QA_10: 23.567,
        VA_10: 0.59341,
        QC_10: 68.645,
        VC_10: 0.81172,
        QE_10: 68.645,
        VE_10: 0.81172,
        QA_11: 42.918,
        VA_11: 0.70024,
        QC_11: 67.225,
        VC_11: 0.80616,
        QE_11: 67.225,
        VE_11: 0.80616,
        QA_12: 178.083,
        VA_12: 1.14259,
        QC_12: 147.533,
        VC_12: 1.06399,
        QE_12: 147.533,
        VE_12: 1.06399,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.039578384650326, 34.807079212639167, 0.0],
          [-81.036334984655355, 34.801808612647335, 0.0],
          [-81.028756184667145, 34.800039212650177, 0.0],
          [-81.029759517998983, 34.796970479321544, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285944.0,
        COMID: 9754212,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 4.327,
        REACHCODE: "03050101002619",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.044802003097832999,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061578.0,
        ToNode: 250061579.0,
        Hydroseq: 250015143.0,
        LevelPathI: 250003258.0,
        Pathlength: 663.718,
        TerminalPa: 250002604.0,
        ArbolateSu: 714.84,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015296.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015002.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.9404,
        TotDASqKM: 760.5918,
        DivDASqKM: 760.5918,
        Tidal: 0,
        TOTMA: 19.620939570400001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35708.0,
        MAXELEVSMO: 36429.0,
        MINELEVSMO: 35708.0,
        SLOPE: 0.00166628,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.3270001411437988,
        QA_MA: 489.836,
        VA_MA: -9998.0,
        QC_MA: 466.474,
        VC_MA: -9998.0,
        QE_MA: 604.068,
        VE_MA: -9998.0,
        QA_01: 779.648,
        VA_01: -9998.0,
        QC_01: 593.818,
        VC_01: -9998.0,
        QE_01: 651.204,
        VE_01: -9998.0,
        QA_02: 821.956,
        VA_02: -9998.0,
        QC_02: 666.92,
        VC_02: -9998.0,
        QE_02: 677.898,
        VE_02: -9998.0,
        QA_03: 939.431,
        VA_03: -9998.0,
        QC_03: 763.111,
        VC_03: -9998.0,
        QE_03: 905.77,
        VE_03: -9998.0,
        QA_04: 704.092,
        VA_04: -9998.0,
        QC_04: 634.804,
        VC_04: -9998.0,
        QE_04: 773.575,
        VE_04: -9998.0,
        QA_05: 566.856,
        VA_05: -9998.0,
        QC_05: 507.812,
        VC_05: -9998.0,
        QE_05: 700.062,
        VE_05: -9998.0,
        QA_06: 318.455,
        VA_06: -9998.0,
        QC_06: 392.626,
        VC_06: -9998.0,
        QE_06: 589.712,
        VE_06: -9998.0,
        QA_07: 185.62,
        VA_07: -9998.0,
        QC_07: 273.257,
        VC_07: -9998.0,
        QE_07: 383.828,
        VE_07: -9998.0,
        QA_08: 126.759,
        VA_08: -9998.0,
        QC_08: 289.72,
        VC_08: -9998.0,
        QE_08: 409.355,
        VE_08: -9998.0,
        QA_09: 136.244,
        VA_09: -9998.0,
        QC_09: 299.739,
        VC_09: -9998.0,
        QE_09: 433.797,
        VE_09: -9998.0,
        QA_10: 195.744,
        VA_10: -9998.0,
        QC_10: 372.424,
        VC_10: -9998.0,
        QE_10: 449.433,
        VE_10: -9998.0,
        QA_11: 484.549,
        VA_11: -9998.0,
        QC_11: 426.92,
        VC_11: -9998.0,
        QE_11: 530.859,
        VE_11: -9998.0,
        QA_12: 621.743,
        VA_12: -9998.0,
        QC_12: 460.169,
        VC_12: -9998.0,
        QE_12: 550.432,
        VE_12: -9998.0,
        LakeFract: 0.21555609565,
        SurfArea: 5346608.0704199998,
        RAreaHLoad: 3.6312299797800001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.926846116606498, 35.75265261117147, 0.0],
          [-81.919597783284416, 35.753412277836901, 0.0],
          [-81.909045716634068, 35.741043077856148, 0.0],
          [-81.890335116663096, 35.734333477866414, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283879.0,
        COMID: 9752840,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.44,
        REACHCODE: "03050101000297",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.025157649145472999,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061148.0,
        ToNode: 250061142.0,
        Hydroseq: 250012521.0,
        LevelPathI: 250003258.0,
        Pathlength: 631.458,
        TerminalPa: 250002604.0,
        ArbolateSu: 1361.892,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012607.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012429.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4984,
        TotDASqKM: 1534.0554,
        DivDASqKM: 1534.0554,
        Tidal: 0,
        TOTMA: 0.0536924798622,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30235.0,
        MAXELEVSMO: 30538.0,
        MINELEVSMO: 30235.0,
        SLOPE: 0.0012418,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.440000057220459,
        QA_MA: 998.258,
        VA_MA: 1.71668,
        QC_MA: 924.741,
        VC_MA: 1.70798,
        QE_MA: 1079.469,
        VE_MA: 1.72563,
        QA_01: 1565.609,
        VA_01: 2.09681,
        QC_01: 1170.854,
        VC_01: 1.89481,
        QE_01: 1284.915,
        VE_01: 1.863,
        QA_02: 1661.374,
        VA_02: 2.15409,
        QC_02: 1322.4,
        VC_02: 2.00073,
        QE_02: 1381.019,
        VE_02: 1.9237,
        QA_03: 1893.409,
        VA_03: 2.28677,
        QC_03: 1502.863,
        VC_03: 2.11968,
        QE_03: 1701.847,
        VE_03: 2.11318,
        QA_04: 1437.283,
        VA_04: 2.01742,
        QC_04: 1437.283,
        VC_04: 2.07727,
        QE_04: 1604.334,
        VE_04: 2.05751,
        QA_05: 1138.199,
        VA_05: 1.81828,
        QC_05: 1138.199,
        VC_05: 1.87116,
        QE_05: 1348.912,
        VE_05: 1.90364,
        QA_06: 662.467,
        VA_06: 1.44114,
        QC_06: 662.467,
        VC_06: 1.48081,
        QE_06: 879.186,
        VE_06: 1.5793,
        QA_07: 389.065,
        VA_07: 1.16227,
        QC_07: 562.705,
        VC_07: 1.38348,
        QE_07: 674.547,
        VE_07: 1.41251,
        QA_08: 281.993,
        VA_08: 1.02812,
        QC_08: 654.926,
        VC_08: 1.4737,
        QE_08: 879.444,
        VE_08: 1.5795,
        QA_09: 293.286,
        VA_09: 1.04329,
        QC_09: 589.825,
        VC_09: 1.41069,
        QE_09: 639.963,
        VE_09: 1.38208,
        QA_10: 402.876,
        VA_10: 1.17823,
        QC_10: 662.911,
        VC_10: 1.48123,
        QE_10: 654.652,
        VE_10: 1.39509,
        QA_11: 988.583,
        VA_11: 1.70942,
        QC_11: 735.384,
        VC_11: 1.54765,
        QE_11: 707.264,
        VE_11: 1.44063,
        QA_12: 1271.518,
        VA_12: 1.90975,
        QC_12: 882.293,
        VC_12: 1.67349,
        QE_12: 900.871,
        VE_12: 1.59585,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.710681516942032, 35.763545411154553, 0.0],
          [-81.707930516946249, 35.765929011150888, 0.0],
          [-81.694864383633217, 35.765410677818352, 0.0],
          [-81.691329983638695, 35.772237277807676, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287058.0,
        COMID: 9737032,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 4.606,
        REACHCODE: "03050103000331",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.044662628538741998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060106.0,
        ToNode: 250060125.0,
        Hydroseq: 250032425.0,
        LevelPathI: 250015946.0,
        Pathlength: 419.616,
        TerminalPa: 250002604.0,
        ArbolateSu: 112.272,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250033899.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031126.0,
        FromMeas: 22.8509,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 18.6993,
        TotDASqKM: 335.4417,
        DivDASqKM: 335.4417,
        Tidal: 0,
        TOTMA: 0.150489740269,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14316.0,
        MAXELEVSMO: 14592.0,
        MINELEVSMO: 14316.0,
        SLOPE: 0.00059921,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.6059999465942383,
        QA_MA: 141.319,
        VA_MA: 1.16226,
        QC_MA: 141.23,
        VC_MA: 1.16222,
        QE_MA: 141.23,
        VE_MA: 1.16222,
        QA_01: 301.086,
        VA_01: 1.5843,
        QC_01: 235.111,
        VC_01: 1.42758,
        QE_01: 235.111,
        VE_01: 1.42758,
        QA_02: 315.801,
        VA_02: 1.61704,
        QC_02: 263.004,
        VC_02: 1.49623,
        QE_02: 263.004,
        VE_02: 1.49623,
        QA_03: 340.687,
        VA_03: 1.67083,
        QC_03: 286.167,
        VC_03: 1.55068,
        QE_03: 286.167,
        VE_03: 1.55068,
        QA_04: 202.683,
        VA_04: 1.34248,
        QC_04: 187.984,
        VC_04: 1.30223,
        QE_04: 187.984,
        VE_04: 1.30223,
        QA_05: 112.363,
        VA_05: 1.06438,
        QC_05: 106.942,
        VC_05: 1.045,
        QE_05: 106.942,
        VE_05: 1.045,
        QA_06: 71.304,
        VA_06: 0.90219,
        QC_06: 89.736,
        VC_06: 0.97948,
        QE_06: 89.736,
        VE_06: 0.97948,
        QA_07: 49.611,
        VA_07: 0.79815,
        QC_07: 75.376,
        VC_07: 0.92013,
        QE_07: 75.376,
        VE_07: 0.92013,
        QA_08: 36.081,
        VA_08: 0.72196,
        QC_08: 80.417,
        VC_08: 0.94153,
        QE_08: 80.417,
        VE_08: 0.94153,
        QA_09: 31.571,
        VA_09: 0.69365,
        QC_09: 82.428,
        VC_09: 0.94988,
        QE_09: 82.428,
        VE_09: 0.94988,
        QA_10: 22.624,
        VA_10: 0.63117,
        QC_10: 66.442,
        VC_10: 0.88049,
        QE_10: 66.442,
        VE_10: 0.88049,
        QA_11: 41.684,
        VA_11: 0.75491,
        QC_11: 65.746,
        VC_11: 0.8773,
        QE_11: 65.746,
        VE_11: 0.8773,
        QA_12: 171.289,
        VA_12: 1.25408,
        QC_12: 142.403,
        VC_12: 1.16598,
        QE_12: 142.403,
        VE_12: 1.16598,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.06521398461058, 34.839345412589125, 0.0],
          [-81.065020584610863, 34.837925279258002, 0.0],
          [-81.062214917948552, 34.837694812591621, 0.0],
          [-81.06269071794776, 34.833205479265189, 0.0],
          [-81.058553784620926, 34.83050047926946, 0.0],
          [-81.057142117956417, 34.824635879278617, 0.0],
          [-81.054254517960828, 34.823351612613862, 0.0],
          [-81.057338584622812, 34.822139212615866, 0.0],
          [-81.055592184625482, 34.818129679288631, 0.0],
          [-81.057512917955819, 34.813114279296428, 0.0],
          [-81.052711117963327, 34.80979021263505, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285945.0,
        COMID: 9754214,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.093,
        REACHCODE: "03050101002620",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0010205159247200001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061579.0,
        ToNode: 250061619.0,
        Hydroseq: 250015002.0,
        LevelPathI: 250003258.0,
        Pathlength: 663.625,
        TerminalPa: 250002604.0,
        ArbolateSu: 906.704,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015143.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014852.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0603,
        TotDASqKM: 1000.8891,
        DivDASqKM: 1000.8891,
        Tidal: 0,
        TOTMA: 0.42171189742999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 35313.0,
        MAXELEVSMO: 35708.0,
        MINELEVSMO: 35313.0,
        SLOPE: 0.04247311,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.093000002205371995,
        QA_MA: 676.005,
        VA_MA: -9998.0,
        QC_MA: 635.766,
        VC_MA: -9998.0,
        QE_MA: 790.493,
        VE_MA: -9998.0,
        QA_01: 1019.53,
        VA_01: -9998.0,
        QC_01: 771.083,
        VC_01: -9998.0,
        QE_01: 885.145,
        VE_01: -9998.0,
        QA_02: 1083.788,
        VA_02: -9998.0,
        QC_02: 872.763,
        VC_02: -9998.0,
        QE_02: 931.382,
        VE_02: -9998.0,
        QA_03: 1264.792,
        VA_03: -9998.0,
        QC_03: 1017.369,
        VC_03: -9998.0,
        QE_03: 1216.353,
        VE_03: -9998.0,
        QA_04: 967.478,
        VA_04: -9998.0,
        QC_04: 865.992,
        VC_04: -9998.0,
        QE_04: 1033.044,
        VE_04: -9998.0,
        QA_05: 781.892,
        VA_05: -9998.0,
        QC_05: 692.074,
        VC_05: -9998.0,
        QE_05: 902.787,
        VE_05: -9998.0,
        QA_06: 462.69,
        VA_06: -9998.0,
        QC_06: 567.536,
        VC_06: -9998.0,
        QE_06: 784.254,
        VE_06: -9998.0,
        QA_07: 272.534,
        VA_07: -9998.0,
        QC_07: 397.537,
        VC_07: -9998.0,
        QE_07: 493.422,
        VE_07: -9998.0,
        QA_08: 203.038,
        VA_08: -9998.0,
        QC_08: 468.463,
        VC_08: -9998.0,
        QE_08: 542.389,
        VE_08: -9998.0,
        QA_09: 231.25,
        VA_09: -9998.0,
        QC_09: 478.19,
        VC_09: -9998.0,
        QE_09: 520.03,
        VE_09: -9998.0,
        QA_10: 307.334,
        VA_10: -9998.0,
        QC_10: 534.005,
        VC_10: -9998.0,
        QE_10: 511.691,
        VE_10: -9998.0,
        QA_11: 680.032,
        VA_11: -9998.0,
        QC_11: 552.84,
        VC_11: -9998.0,
        QE_11: 634.098,
        VE_11: -9998.0,
        QA_12: 842.063,
        VA_12: -9998.0,
        QC_12: 606.419,
        VC_12: -9998.0,
        QE_12: 702.649,
        VE_12: -9998.0,
        LakeFract: 0.00605317641,
        SurfArea: 150141.71484100001,
        RAreaHLoad: 0.07804584889000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.890335116663096, 35.734333477866414, 0.0],
          [-81.889350783331295, 35.734083011200369, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283880.0,
        COMID: 9752856,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.682,
        REACHCODE: "03050101000298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0066141343262800002,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061154.0,
        ToNode: 250061148.0,
        Hydroseq: 250012607.0,
        LevelPathI: 250003258.0,
        Pathlength: 633.898,
        TerminalPa: 250002604.0,
        ArbolateSu: 1357.387,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012699.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012521.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2457,
        TotDASqKM: 1528.7517,
        DivDASqKM: 1528.7517,
        Tidal: 0,
        TOTMA: 0.026799717063700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30318.0,
        MAXELEVSMO: 30538.0,
        MINELEVSMO: 30538.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68199998140335105,
        QA_MA: 995.598,
        VA_MA: 0.96215,
        QC_MA: 922.374,
        VC_MA: 0.95812,
        QE_MA: 1077.101,
        VE_MA: 0.96633,
        QA_01: 1560.614,
        VA_01: 1.13839,
        QC_01: 1167.216,
        VC_01: 1.04464,
        QE_01: 1281.278,
        VE_01: 1.02986,
        QA_02: 1656.018,
        VA_02: 1.16496,
        QC_02: 1318.252,
        VC_02: 1.09378,
        QE_02: 1376.871,
        VE_02: 1.05796,
        QA_03: 1887.701,
        VA_03: 1.22664,
        QC_03: 1498.482,
        VC_03: 1.14909,
        QE_03: 1697.466,
        VE_03: 1.14608,
        QA_04: 1433.262,
        VA_04: 1.10171,
        QC_04: 1433.262,
        VC_04: 1.12945,
        QE_04: 1600.314,
        VE_04: 1.12027,
        QA_05: 1135.534,
        VA_05: 1.00944,
        QC_05: 1135.534,
        VC_05: 1.03396,
        QE_05: 1346.247,
        VE_05: 1.04906,
        QA_06: 660.943,
        VA_06: 0.83434,
        QC_06: 660.943,
        VC_06: 0.85273,
        QE_06: 877.661,
        VE_06: 0.89854,
        QA_07: 388.138,
        VA_07: 0.70484,
        QC_07: 561.396,
        VC_07: 0.80754,
        QE_07: 673.238,
        VE_07: 0.82103,
        QA_08: 281.351,
        VA_08: 0.64258,
        QC_08: 653.406,
        VC_08: 0.84943,
        QE_08: 877.924,
        VE_08: 0.89864,
        QA_09: 292.787,
        VA_09: 0.64972,
        QC_09: 588.939,
        VC_09: 0.82039,
        QE_09: 639.077,
        VE_09: 0.80705,
        QA_10: 402.448,
        VA_10: 0.71253,
        QC_10: 662.349,
        VC_10: 0.85335,
        QE_10: 654.089,
        VE_10: 0.81324,
        QA_11: 986.898,
        VA_11: 0.95911,
        QC_11: 734.428,
        VC_11: 0.88407,
        QE_11: 706.308,
        VE_11: 0.83426,
        QA_12: 1268.068,
        VA_12: 1.05176,
        QC_12: 880.115,
        VC_12: 0.94214,
        QE_12: 898.693,
        VE_12: 0.90601,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.706630316948235, 35.758647411162144, 0.0],
          [-81.710681516942032, 35.763545411154553, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285946.0,
        COMID: 9754218,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.597,
        REACHCODE: "03050101002621",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.016040872021028,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061581.0,
        ToNode: 250061586.0,
        Hydroseq: 250019848.0,
        LevelPathI: 250019848.0,
        Pathlength: 677.912,
        TerminalPa: 250002604.0,
        ArbolateSu: 218.8,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250020194.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015776.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.503,
        TotDASqKM: 220.9464,
        DivDASqKM: 220.9464,
        Tidal: 0,
        TOTMA: 7.2416548415199999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36440.0,
        MAXELEVSMO: 36470.0,
        MINELEVSMO: 36454.0,
        SLOPE: 0.00010018,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5970000028610229,
        QA_MA: 138.973,
        VA_MA: -9998.0,
        QC_MA: 138.976,
        VC_MA: -9998.0,
        QE_MA: 138.976,
        VE_MA: -9998.0,
        QA_01: 220.173,
        VA_01: -9998.0,
        QC_01: 173.345,
        VC_01: -9998.0,
        QE_01: 173.345,
        VE_01: -9998.0,
        QA_02: 231.661,
        VA_02: -9998.0,
        QC_02: 194.568,
        VC_02: -9998.0,
        QE_02: 194.568,
        VE_02: -9998.0,
        QA_03: 271.367,
        VA_03: -9998.0,
        QC_03: 229.658,
        VC_03: -9998.0,
        QE_03: 229.658,
        VE_03: -9998.0,
        QA_04: 201.056,
        VA_04: -9998.0,
        QC_04: 186.509,
        VC_04: -9998.0,
        QE_04: 186.509,
        VE_04: -9998.0,
        QA_05: 165.841,
        VA_05: -9998.0,
        QC_05: 155.558,
        VC_05: -9998.0,
        QE_05: 155.558,
        VE_05: -9998.0,
        QA_06: 90.27,
        VA_06: -9998.0,
        QC_06: 113.238,
        VC_06: -9998.0,
        QE_06: 113.238,
        VE_06: -9998.0,
        QA_07: 53.008,
        VA_07: -9998.0,
        QC_07: 80.409,
        VC_07: -9998.0,
        QE_07: 80.409,
        VE_07: -9998.0,
        QA_08: 37.006,
        VA_08: -9998.0,
        QC_08: 82.521,
        VC_08: -9998.0,
        QE_08: 82.521,
        VE_08: -9998.0,
        QA_09: 39.706,
        VA_09: -9998.0,
        QC_09: 100.923,
        VC_09: -9998.0,
        QE_09: 100.923,
        VE_09: -9998.0,
        QA_10: 53.934,
        VA_10: -9998.0,
        QC_10: 132.996,
        VC_10: -9998.0,
        QE_10: 132.996,
        VE_10: -9998.0,
        QA_11: 132.37,
        VA_11: -9998.0,
        QC_11: 158.697,
        VC_11: -9998.0,
        QE_11: 158.697,
        VE_11: -9998.0,
        QA_12: 171.859,
        VA_12: -9998.0,
        QC_12: 142.834,
        VC_12: -9998.0,
        QE_12: 142.834,
        VE_12: -9998.0,
        LakeFract: 0.0183102505,
        SurfArea: 454163.60321199999,
        RAreaHLoad: 1.34020667407,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.98147498318832, 35.734809877865871, 0.0],
          [-81.978876783192391, 35.731223277871266, 0.0],
          [-81.986092983181152, 35.727883077876641, 0.0],
          [-81.985211583182547, 35.725340211213791, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283881.0,
        COMID: 9753848,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.228,
        REACHCODE: "03050101000299",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020807303550888001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061422.0,
        ToNode: 250061154.0,
        Hydroseq: 250012699.0,
        LevelPathI: 250003258.0,
        Pathlength: 634.58,
        TerminalPa: 250002604.0,
        ArbolateSu: 1355.006,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012799.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012607.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.2193,
        TotDASqKM: 1527.0993,
        DivDASqKM: 1527.0993,
        Tidal: 0,
        TOTMA: 0.087563667361400005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30346.0,
        MAXELEVSMO: 30538.0,
        MINELEVSMO: 30538.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2279999256134029,
        QA_MA: 994.771,
        VA_MA: 0.962,
        QC_MA: 921.637,
        VC_MA: 0.95797,
        QE_MA: 1076.364,
        VE_MA: 0.96619,
        QA_01: 1559.053,
        VA_01: 1.13813,
        QC_01: 1166.079,
        VC_01: 1.04441,
        QE_01: 1280.141,
        VE_01: 1.02963,
        QA_02: 1654.34,
        VA_02: 1.16468,
        QC_02: 1316.953,
        VC_02: 1.09353,
        QE_02: 1375.572,
        VE_02: 1.0577,
        QA_03: 1885.923,
        VA_03: 1.22637,
        QC_03: 1497.118,
        VC_03: 1.14885,
        QE_03: 1696.101,
        VE_03: 1.14585,
        QA_04: 1432.018,
        VA_04: 1.10151,
        QC_04: 1432.018,
        VC_04: 1.12924,
        QE_04: 1599.069,
        VE_04: 1.12006,
        QA_05: 1134.713,
        VA_05: 1.00932,
        QC_05: 1134.713,
        VC_05: 1.03383,
        QE_05: 1345.426,
        VE_05: 1.04893,
        QA_06: 660.475,
        VA_06: 0.83425,
        QC_06: 660.475,
        VC_06: 0.85264,
        QE_06: 877.194,
        VE_06: 0.89846,
        QA_07: 387.849,
        VA_07: 0.70477,
        QC_07: 560.989,
        VC_07: 0.80744,
        QE_07: 672.83,
        VE_07: 0.82094,
        QA_08: 281.151,
        VA_08: 0.64252,
        QC_08: 652.932,
        VC_08: 0.84933,
        QE_08: 877.45,
        VE_08: 0.89856,
        QA_09: 292.631,
        VA_09: 0.6497,
        QC_09: 588.663,
        VC_09: 0.82037,
        QE_09: 638.801,
        VE_09: 0.80701,
        QA_10: 402.315,
        VA_10: 0.71255,
        QC_10: 662.174,
        VC_10: 0.85338,
        QE_10: 653.914,
        VE_10: 0.81324,
        QA_11: 986.375,
        VA_11: 0.95907,
        QC_11: 734.131,
        VC_11: 0.88407,
        QE_11: 706.01,
        VE_11: 0.83421,
        QA_12: 1266.996,
        VA_12: 1.05159,
        QC_12: 879.438,
        VC_12: 0.942,
        QE_12: 898.016,
        VE_12: 0.90586,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.71215091693972, 35.740790011189858, 0.0],
          [-81.705726383616309, 35.74837487784481, 0.0],
          [-81.706630316948235, 35.758647411162144, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283882.0,
        COMID: 9752944,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.772,
        REACHCODE: "03050101000300",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0084604111473550007,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061174.0,
        ToNode: 250061422.0,
        Hydroseq: 250012799.0,
        LevelPathI: 250003258.0,
        Pathlength: 636.808,
        TerminalPa: 250002604.0,
        ArbolateSu: 1220.433,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250012898.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012699.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5634,
        TotDASqKM: 1365.741,
        DivDASqKM: 1365.741,
        Tidal: 0,
        TOTMA: 0.0173014669399,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30437.0,
        MAXELEVSMO: 30635.0,
        MINELEVSMO: 30538.0,
        SLOPE: 0.00125647,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.77200001478195202,
        QA_MA: 899.551,
        VA_MA: 1.68348,
        QC_MA: 836.678,
        VC_MA: 1.67544,
        QE_MA: 991.405,
        VE_MA: 1.69436,
        QA_01: 1392.687,
        VA_01: 2.04272,
        QC_01: 1044.732,
        VC_01: 1.84653,
        QE_01: 1158.794,
        VE_01: 1.81406,
        QA_02: 1478.658,
        VA_02: 2.09877,
        QC_02: 1180.707,
        VC_02: 1.94978,
        QE_02: 1239.326,
        VE_02: 1.86875,
        QA_03: 1699.319,
        VA_03: 2.23601,
        QC_03: 1353.632,
        VC_03: 2.07334,
        QE_03: 1552.615,
        VE_03: 2.06712,
        QA_04: 1293.772,
        VA_04: 1.97618,
        QC_04: 1150.434,
        VC_04: 1.9273,
        QE_04: 1317.486,
        VE_04: 1.92025,
        QA_05: 1032.756,
        VA_05: 1.7881,
        QC_05: 1032.756,
        VC_05: 1.83715,
        QE_05: 1243.469,
        VE_05: 1.87152,
        QA_06: 602.148,
        VA_06: 1.41953,
        QC_06: 602.148,
        VC_06: 1.45635,
        QE_06: 818.867,
        VE_06: 1.56055,
        QA_07: 353.935,
        VA_07: 1.14635,
        QC_07: 513.056,
        VC_07: 1.36278,
        QE_07: 624.897,
        VE_07: 1.39311,
        QA_08: 257.904,
        VA_08: 1.01671,
        QC_08: 597.91,
        VC_08: 1.45206,
        QE_08: 822.428,
        VE_08: 1.56344,
        QA_09: 272.828,
        VA_09: 1.03819,
        QC_09: 553.347,
        VC_09: 1.40597,
        QE_09: 603.485,
        VE_09: 1.37321,
        QA_10: 374.316,
        VA_10: 1.17164,
        QC_10: 625.096,
        VC_10: 1.47938,
        QE_10: 616.836,
        VE_10: 1.38566,
        QA_11: 900.654,
        VA_11: 1.68438,
        QC_11: 684.955,
        VC_11: 1.53765,
        QE_11: 656.835,
        VE_11: 1.42221,
        QA_12: 1141.608,
        VA_12: 1.86897,
        QC_12: 799.886,
        VC_12: 1.64318,
        QE_12: 818.463,
        VE_12: 1.56022,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.720296183593746, 35.740325411190497, 0.0],
          [-81.71215091693972, 35.740790011189858, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283891.0,
        COMID: 9753022,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.861,
        REACHCODE: "03050101000309",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0093237590170660006,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061207.0,
        ToNode: 250061200.0,
        Hydroseq: 250013692.0,
        LevelPathI: 250003258.0,
        Pathlength: 652.128,
        TerminalPa: 250002604.0,
        ArbolateSu: 1163.528,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013802.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013586.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5868,
        TotDASqKM: 1295.2368,
        DivDASqKM: 1295.2368,
        Tidal: 0,
        TOTMA: 0.017428970023899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31668.0,
        MAXELEVSMO: 32048.0,
        MINELEVSMO: 31797.0,
        SLOPE: 0.00291521,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.86100000143051103,
        QA_MA: 861.046,
        VA_MA: 1.86252,
        QC_MA: 802.225,
        VC_MA: 1.85364,
        QE_MA: 956.952,
        VE_MA: 1.87587,
        QA_01: 1324.356,
        VA_01: 2.2617,
        QC_01: 994.784,
        VC_01: 2.04064,
        QE_01: 1108.846,
        VE_01: 2.00341,
        QA_02: 1406.139,
        VA_02: 2.32485,
        QC_02: 1124.341,
        VC_02: 2.15699,
        QE_02: 1182.96,
        VE_02: 2.06266,
        QA_03: 1621.299,
        VA_03: 2.48322,
        QC_03: 1293.489,
        VC_03: 2.29981,
        QE_03: 1492.472,
        VE_03: 2.29308,
        QA_04: 1235.912,
        VA_04: 2.19131,
        QC_04: 1100.128,
        VC_04: 2.13574,
        QE_04: 1267.18,
        VE_04: 2.12791,
        QA_05: 990.492,
        VA_05: 1.9825,
        QC_05: 990.492,
        VC_05: 2.03667,
        QE_05: 1201.205,
        VE_05: 2.07697,
        QA_06: 578.394,
        VA_06: 1.56653,
        QC_06: 578.394,
        VC_06: 1.60724,
        QE_06: 795.113,
        VE_06: 1.72901,
        QA_07: 339.97,
        VA_07: 1.25721,
        QC_07: 493.287,
        VC_07: 1.50197,
        QE_07: 590.385,
        VE_07: 1.52128,
        QA_08: 248.23,
        VA_08: 1.11128,
        QC_08: 575.043,
        VC_08: 1.60323,
        QE_08: 651.403,
        VE_08: 1.58631,
        QA_09: 265.34,
        VA_09: 1.14021,
        QC_09: 539.917,
        VC_09: 1.56061,
        QE_09: 582.301,
        VE_09: 1.51244,
        QA_10: 368.564,
        VA_10: 1.29878,
        QC_10: 617.411,
        VC_10: 1.65305,
        QE_10: 595.008,
        VE_10: 1.52632,
        QA_11: 868.362,
        VA_11: 1.86952,
        QC_11: 666.145,
        VC_11: 1.7084,
        QE_11: 747.098,
        VE_11: 1.68279,
        QA_12: 1091.122,
        VA_12: 2.07077,
        QC_12: 767.636,
        VC_12: 1.81789,
        QE_12: 864.067,
        VE_12: 1.79315,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.813634116782225, 35.726858877878215, 0.0],
          [-81.804841783462507, 35.729373677874207, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283892.0,
        COMID: 9753020,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.133,
        REACHCODE: "03050101000310",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012508337322606001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061206.0,
        ToNode: 250061207.0,
        Hydroseq: 250013802.0,
        LevelPathI: 250003258.0,
        Pathlength: 652.989,
        TerminalPa: 250002604.0,
        ArbolateSu: 1160.915,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013924.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013692.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3923,
        TotDASqKM: 1293.669,
        DivDASqKM: 1293.669,
        Tidal: 0,
        TOTMA: 0.045573819614300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31698.0,
        MAXELEVSMO: 32048.0,
        MINELEVSMO: 32048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1330000162124629,
        QA_MA: 860.17,
        VA_MA: 0.93861,
        QC_MA: 801.441,
        VC_MA: 0.93501,
        QE_MA: 956.168,
        VE_MA: 0.94403,
        QA_01: 1322.812,
        VA_01: 1.10042,
        QC_01: 993.655,
        VC_01: 1.01078,
        QE_01: 1107.716,
        VE_01: 0.99568,
        QA_02: 1404.511,
        VA_02: 1.12603,
        QC_02: 1123.074,
        VC_02: 1.05796,
        QE_02: 1181.693,
        VE_02: 1.01969,
        QA_03: 1619.55,
        VA_03: 1.19028,
        QC_03: 1292.139,
        VC_03: 1.11591,
        QE_03: 1491.123,
        VE_03: 1.11319,
        QA_04: 1234.605,
        VA_04: 1.07192,
        QC_04: 1098.992,
        VC_04: 1.04939,
        QE_04: 1266.043,
        VE_04: 1.04621,
        QA_05: 989.51,
        VA_05: 0.98727,
        QC_05: 989.51,
        VC_05: 1.00923,
        QE_05: 1200.223,
        VE_05: 1.02559,
        QA_06: 577.846,
        VA_06: 0.8186,
        QC_06: 577.846,
        VC_06: 0.8351,
        QE_06: 794.565,
        VE_06: 0.88451,
        QA_07: 339.653,
        VA_07: 0.69316,
        QC_07: 492.839,
        VC_07: 0.79242,
        QE_07: 589.931,
        VE_07: 0.80025,
        QA_08: 248.007,
        VA_08: 0.63399,
        QC_08: 574.515,
        VC_08: 0.83348,
        QE_08: 650.863,
        VE_08: 0.82661,
        QA_09: 265.171,
        VA_09: 0.64577,
        QC_09: 539.614,
        VC_09: 0.81629,
        QE_09: 581.995,
        VE_09: 0.79673,
        QA_10: 368.438,
        VA_10: 0.71015,
        QC_10: 617.242,
        VC_10: 0.85387,
        QE_10: 594.839,
        VE_10: 0.80242,
        QA_11: 867.578,
        VA_11: 0.94148,
        QC_11: 665.686,
        VC_11: 0.8762,
        QE_11: 746.641,
        VE_11: 0.86579,
        QA_12: 1089.964,
        VA_12: 1.02304,
        QC_12: 766.895,
        VC_12: 0.92052,
        QE_12: 863.325,
        VE_12: 0.91047,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.825906316763167, 35.728054611209586, 0.0],
          [-81.813634116782225, 35.726858877878215, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286967.0,
        COMID: 9731400,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 4.495,
        REACHCODE: "03050103000244",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.043506593996169997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058889.0,
        ToNode: 250058894.0,
        Hydroseq: 250031139.0,
        LevelPathI: 250019853.0,
        Pathlength: 437.386,
        TerminalPa: 250002604.0,
        ArbolateSu: 162.929,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250032439.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029973.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.1946,
        TotDASqKM: 324.1332,
        DivDASqKM: 324.1332,
        Tidal: 0,
        TOTMA: 0.13642426212799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15131.0,
        MAXELEVSMO: 15690.0,
        MINELEVSMO: 15233.0,
        SLOPE: 0.00101668,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.494999885559082,
        QA_MA: 124.324,
        VA_MA: 1.22288,
        QC_MA: 124.864,
        VC_MA: 1.2232,
        QE_MA: 181.043,
        VE_MA: 1.25115,
        QA_01: 262.987,
        VA_01: 1.66969,
        QC_01: 206.09,
        VC_01: 1.50206,
        QE_01: 262.52,
        VE_01: 1.45679,
        QA_02: 284.031,
        VA_02: 1.72648,
        QC_02: 237.23,
        VC_02: 1.59467,
        QE_02: 280.689,
        VE_02: 1.49833,
        QA_03: 306.617,
        VA_03: 1.78529,
        QC_03: 258.447,
        VC_03: 1.65453,
        QE_03: 294.634,
        VE_03: 1.52936,
        QA_04: 181.194,
        VA_04: 1.42536,
        QC_04: 168.482,
        VC_04: 1.38095,
        QE_04: 201.931,
        VE_04: 1.30742,
        QA_05: 102.365,
        VA_05: 1.13321,
        QC_05: 97.766,
        VC_05: 1.11175,
        QE_05: 155.529,
        VE_05: 1.17809,
        QA_06: 62.638,
        VA_06: 0.94385,
        QC_06: 78.97,
        VC_06: 1.02565,
        QE_06: 145.238,
        VE_06: 1.14704,
        QA_07: 43.182,
        VA_07: 0.82996,
        QC_07: 65.826,
        VC_07: 0.95955,
        QE_07: 136.445,
        VE_07: 1.11969,
        QA_08: 31.23,
        VA_08: 0.74751,
        QC_08: 69.405,
        VC_08: 0.97812,
        QE_08: 141.455,
        VE_08: 1.13537,
        QA_09: 26.827,
        VA_09: 0.71347,
        QC_09: 71.392,
        VC_09: 0.98823,
        QE_09: 128.207,
        VE_09: 1.0933,
        QA_10: 21.79,
        VA_10: 0.67112,
        QC_10: 64.479,
        VC_10: 0.95244,
        QE_10: 131.851,
        VE_10: 1.10507,
        QA_11: 25.793,
        VA_11: 0.7051,
        QC_11: 45.592,
        VC_11: 0.84417,
        QE_11: 124.391,
        VE_11: 1.0808,
        QA_12: 143.994,
        VA_12: 1.29706,
        QC_12: 121.6,
        VC_12: 1.21042,
        QE_12: 154.796,
        VE_12: 1.17591,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.903432984861695, 35.064969412238959, 0.0],
          [-80.8995593848677, 35.060091812246412, 0.0],
          [-80.901647318197718, 35.056174678919206, 0.0],
          [-80.905072918192445, 35.054479012255229, 0.0],
          [-80.903038518195615, 35.051433212259894, 0.0],
          [-80.905989184857731, 35.047195212266445, 0.0],
          [-80.902813784862587, 35.045730078935378, 0.0],
          [-80.898383784869509, 35.039638612278225, 0.0],
          [-80.897435584871005, 35.034164678953289, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283893.0,
        COMID: 9753010,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.295,
        REACHCODE: "03050101000311",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002967256946608,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061202.0,
        ToNode: 250061206.0,
        Hydroseq: 250013924.0,
        LevelPathI: 250003258.0,
        Pathlength: 654.122,
        TerminalPa: 250002604.0,
        ArbolateSu: 1158.956,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014034.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013802.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1089,
        TotDASqKM: 1291.9887,
        DivDASqKM: 1291.9887,
        Tidal: 0,
        TOTMA: 0.011868223710799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31907.0,
        MAXELEVSMO: 32048.0,
        MINELEVSMO: 32048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.29499998688697798,
        QA_MA: 859.239,
        VA_MA: 0.93843,
        QC_MA: 800.607,
        VC_MA: 0.93483,
        QE_MA: 955.334,
        VE_MA: 0.94386,
        QA_01: 1321.177,
        VA_01: 1.10013,
        QC_01: 992.459,
        VC_01: 1.01053,
        QE_01: 1106.521,
        VE_01: 0.99542,
        QA_02: 1402.771,
        VA_02: 1.12573,
        QC_02: 1121.721,
        VC_02: 1.05769,
        QE_02: 1180.34,
        VE_02: 1.01939,
        QA_03: 1617.677,
        VA_03: 1.19,
        QC_03: 1290.694,
        VC_03: 1.11565,
        QE_03: 1489.677,
        VE_03: 1.11293,
        QA_04: 1233.205,
        VA_04: 1.07169,
        QC_04: 1097.773,
        VC_04: 1.04915,
        QE_04: 1264.825,
        VE_04: 1.04598,
        QA_05: 988.471,
        VA_05: 0.98709,
        QC_05: 988.471,
        VC_05: 1.00903,
        QE_05: 1199.183,
        VE_05: 1.0254,
        QA_06: 577.259,
        VA_06: 0.81847,
        QC_06: 577.259,
        VC_06: 0.83496,
        QE_06: 793.977,
        VE_06: 0.8844,
        QA_07: 339.314,
        VA_07: 0.69307,
        QC_07: 492.359,
        VC_07: 0.7923,
        QE_07: 589.444,
        VE_07: 0.80013,
        QA_08: 247.781,
        VA_08: 0.63393,
        QC_08: 573.981,
        VC_08: 0.83337,
        QE_08: 650.315,
        VE_08: 0.82648,
        QA_09: 264.99,
        VA_09: 0.64575,
        QC_09: 539.29,
        VC_09: 0.81627,
        QE_09: 581.668,
        VE_09: 0.79668,
        QA_10: 368.302,
        VA_10: 0.71019,
        QC_10: 617.06,
        VC_10: 0.85393,
        QE_10: 594.659,
        VE_10: 0.80244,
        QA_11: 866.779,
        VA_11: 0.94136,
        QC_11: 665.219,
        VC_11: 0.87614,
        QE_11: 746.175,
        VE_11: 0.86572,
        QA_12: 1088.744,
        VA_12: 1.02283,
        QC_12: 766.114,
        VC_12: 0.92035,
        QE_12: 862.542,
        VE_12: 0.9103,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.828152183426312, 35.729933277873442, 0.0],
          [-81.825906316763167, 35.728054611209586, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285951.0,
        COMID: 9754234,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.178,
        REACHCODE: "03050101002626",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022049766400428002,
        StreamLeve: 2,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061589.0,
        ToNode: 250061586.0,
        Hydroseq: 250015943.0,
        LevelPathI: 250003258.0,
        Pathlength: 677.912,
        TerminalPa: 250002604.0,
        ArbolateSu: 445.414,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250016106.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015776.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9664,
        TotDASqKM: 471.3786,
        DivDASqKM: 471.3786,
        Tidal: 0,
        TOTMA: 9.8762205648699997,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36375.0,
        MAXELEVSMO: 36618.0,
        MINELEVSMO: 36454.0,
        SLOPE: 0.00075298,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1779999732971191,
        QA_MA: 310.181,
        VA_MA: -9998.0,
        QC_MA: 300.671,
        VC_MA: -9998.0,
        QE_MA: 438.266,
        VE_MA: -9998.0,
        QA_01: 491.761,
        VA_01: -9998.0,
        QC_01: 379.1,
        VC_01: -9998.0,
        QE_01: 436.486,
        VE_01: -9998.0,
        QA_02: 518.605,
        VA_02: -9998.0,
        QC_02: 426.103,
        VC_02: -9998.0,
        QE_02: 437.08,
        VE_02: -9998.0,
        QA_03: 586.815,
        VA_03: -9998.0,
        QC_03: 484.139,
        VC_03: -9998.0,
        QE_03: 626.799,
        VE_03: -9998.0,
        QA_04: 442.255,
        VA_04: -9998.0,
        QC_04: 402.971,
        VC_04: -9998.0,
        QE_04: 541.743,
        VE_04: -9998.0,
        QA_05: 353.877,
        VA_05: -9998.0,
        QC_05: 322.655,
        VC_05: -9998.0,
        QE_05: 514.904,
        VE_05: -9998.0,
        QA_06: 202.394,
        VA_06: -9998.0,
        QC_06: 251.091,
        VC_06: -9998.0,
        QE_06: 448.177,
        VE_06: -9998.0,
        QA_07: 117.427,
        VA_07: -9998.0,
        QC_07: 174.772,
        VC_07: -9998.0,
        QE_07: 282.208,
        VE_07: -9998.0,
        QA_08: 79.624,
        VA_08: -9998.0,
        QC_08: 180.302,
        VC_08: -9998.0,
        QE_08: 293.645,
        VE_08: -9998.0,
        QA_09: 88.787,
        VA_09: -9998.0,
        QC_09: 205.378,
        VC_09: -9998.0,
        QE_09: 338.03,
        VE_09: -9998.0,
        QA_10: 133.671,
        VA_10: -9998.0,
        QC_10: 274.608,
        VC_10: -9998.0,
        QE_10: 351.848,
        VE_10: -9998.0,
        QA_11: 313.174,
        VA_11: -9998.0,
        QC_11: 306.048,
        VC_11: -9998.0,
        QE_11: 410.775,
        VE_11: -9998.0,
        QA_12: 396.061,
        VA_12: -9998.0,
        QC_12: 305.301,
        VC_12: -9998.0,
        QE_12: 395.043,
        VE_12: -9998.0,
        LakeFract: 0.07899287865,
        SurfArea: 1959322.7518,
        RAreaHLoad: 1.8277834286900001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.999255383160687, 35.718784811224054, 0.0],
          [-81.992202183171628, 35.716825811226954, 0.0],
          [-81.988866716510188, 35.720221611221746, 0.0],
          [-81.989404316509365, 35.724619877881651, 0.0],
          [-81.985211583182547, 35.725340211213791, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283894.0,
        COMID: 9753002,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.304,
        REACHCODE: "03050101000312",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00304059112049,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061198.0,
        ToNode: 250061202.0,
        Hydroseq: 250014034.0,
        LevelPathI: 250003258.0,
        Pathlength: 654.417,
        TerminalPa: 250002604.0,
        ArbolateSu: 1158.311,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014160.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013924.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 1291.8051,
        DivDASqKM: 1291.8051,
        Tidal: 0,
        TOTMA: 0.0071912137829699997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31983.0,
        MAXELEVSMO: 32075.0,
        MINELEVSMO: 32048.0,
        SLOPE: 0.00088815,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30399999022483798,
        QA_MA: 859.138,
        VA_MA: 1.59416,
        QC_MA: 800.516,
        VC_MA: 1.58682,
        QE_MA: 955.244,
        VE_MA: 1.60525,
        QA_01: 1321.0,
        VA_01: 1.92415,
        QC_01: 992.329,
        VC_01: 1.74128,
        QE_01: 1106.391,
        VE_01: 1.71046,
        QA_02: 1402.581,
        VA_02: 1.97639,
        QC_02: 1121.573,
        VC_02: 1.83753,
        QE_02: 1180.192,
        VE_02: 1.75937,
        QA_03: 1617.474,
        VA_03: 2.10755,
        QC_03: 1290.537,
        VC_03: 1.95582,
        QE_03: 1489.521,
        VE_03: 1.95027,
        QA_04: 1233.052,
        VA_04: 1.8661,
        QC_04: 1097.64,
        VC_04: 1.82012,
        QE_04: 1264.692,
        VE_04: 1.81364,
        QA_05: 988.357,
        VA_05: 1.69347,
        QC_05: 988.357,
        VC_05: 1.73823,
        QE_05: 1199.07,
        VE_05: 1.77165,
        QA_06: 577.195,
        VA_06: 1.34936,
        QC_06: 577.195,
        VC_06: 1.383,
        QE_06: 793.913,
        VE_06: 1.48392,
        QA_07: 339.277,
        VA_07: 1.09345,
        QC_07: 492.307,
        VC_07: 1.29596,
        QE_07: 589.391,
        VE_07: 1.31194,
        QA_08: 247.756,
        VA_08: 0.97275,
        QC_08: 573.923,
        VC_08: 1.37976,
        QE_08: 650.255,
        VE_08: 1.3657,
        QA_09: 264.971,
        VA_09: 0.99687,
        QC_09: 539.254,
        VC_09: 1.3449,
        QE_09: 581.632,
        VE_09: 1.3049,
        QA_10: 368.287,
        VA_10: 1.12841,
        QC_10: 617.04,
        VC_10: 1.42178,
        QE_10: 594.639,
        VE_10: 1.31667,
        QA_11: 866.693,
        VA_11: 1.60015,
        QC_11: 665.168,
        VC_11: 1.46708,
        QE_11: 746.125,
        VE_11: 1.4458,
        QA_12: 1088.611,
        VA_12: 1.76639,
        QC_12: 766.028,
        VC_12: 1.55728,
        QE_12: 862.457,
        VE_12: 1.53676,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.830257783423065, 35.731376811204541, 0.0],
          [-81.828152183426312, 35.729933277873442, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283887.0,
        COMID: 9752926,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.442,
        REACHCODE: "03050101000305",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0046972548743380001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061170.0,
        ToNode: 250061420.0,
        Hydroseq: 250013280.0,
        LevelPathI: 250003258.0,
        Pathlength: 648.347,
        TerminalPa: 250002604.0,
        ArbolateSu: 1170.153,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013381.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013182.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1044,
        TotDASqKM: 1302.5115,
        DivDASqKM: 1302.5115,
        Tidal: 0,
        TOTMA: 0.0089374138232499994,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31248.0,
        MAXELEVSMO: 31377.0,
        MINELEVSMO: 31248.0,
        SLOPE: 0.00291855,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.44200000166893,
        QA_MA: 865.075,
        VA_MA: 1.86468,
        QC_MA: 805.832,
        VC_MA: 1.85577,
        QE_MA: 960.56,
        VE_MA: 1.87794,
        QA_01: 1331.47,
        VA_01: 2.26514,
        QC_01: 999.987,
        VC_01: 2.0437,
        QE_01: 1114.049,
        VE_01: 2.00648,
        QA_02: 1413.669,
        VA_02: 2.32839,
        QC_02: 1130.197,
        VC_02: 2.16024,
        QE_02: 1188.816,
        VE_02: 2.06607,
        QA_03: 1629.378,
        VA_03: 2.4866,
        QC_03: 1299.721,
        VC_03: 2.30289,
        QE_03: 1498.704,
        VE_03: 2.29613,
        QA_04: 1241.934,
        VA_04: 2.19413,
        QC_04: 1105.367,
        VC_04: 2.13852,
        QE_04: 1272.418,
        VE_04: 2.13066,
        QA_05: 994.957,
        VA_05: 1.98468,
        QC_05: 994.957,
        VC_05: 2.03906,
        QE_05: 1205.67,
        VE_05: 2.07926,
        QA_06: 580.893,
        VA_06: 1.56805,
        QC_06: 580.893,
        VC_06: 1.60891,
        QE_06: 797.612,
        VE_06: 1.73041,
        QA_07: 341.437,
        VA_07: 1.25835,
        QC_07: 495.365,
        VC_07: 1.50344,
        QE_07: 592.493,
        VE_07: 1.52276,
        QA_08: 249.225,
        VA_08: 1.11212,
        QC_08: 577.393,
        VC_08: 1.60474,
        QE_08: 653.814,
        VE_08: 1.58795,
        QA_09: 266.122,
        VA_09: 1.1406,
        QC_09: 541.323,
        VC_09: 1.56109,
        QE_09: 583.72,
        VE_09: 1.51317,
        QA_10: 369.153,
        VA_10: 1.29855,
        QC_10: 618.199,
        VC_10: 1.65261,
        QE_10: 595.794,
        VE_10: 1.52634,
        QA_11: 871.873,
        VA_11: 1.87117,
        QC_11: 668.198,
        VC_11: 1.70928,
        QE_11: 749.143,
        VE_11: 1.68385,
        QA_12: 1096.413,
        VA_12: 2.07338,
        QC_12: 771.022,
        VC_12: 1.81991,
        QE_12: 867.458,
        VE_12: 1.79523,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.797779383473426, 35.743911811184951, 0.0],
          [-81.793565516813374, 35.744138277851334, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283888.0,
        COMID: 9752940,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.622,
        REACHCODE: "03050101000306",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017471574604984001,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061173.0,
        ToNode: 250061170.0,
        Hydroseq: 250013381.0,
        LevelPathI: 250003258.0,
        Pathlength: 648.789,
        TerminalPa: 250002604.0,
        ArbolateSu: 1169.711,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013482.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250013279.0,
        DnDrainCou: 2,
        DnHydroseq: 250013280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0007,
        TotDASqKM: 1302.4071,
        DivDASqKM: 1302.4071,
        Tidal: 0,
        TOTMA: 0.035234313795700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31377.0,
        MAXELEVSMO: 31652.0,
        MINELEVSMO: 31377.0,
        SLOPE: 0.00169543,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.621999979019165,
        QA_MA: 865.018,
        VA_MA: 1.7359,
        QC_MA: 805.781,
        VC_MA: 1.72772,
        QE_MA: 960.509,
        VE_MA: 1.74806,
        QA_01: 1331.369,
        VA_01: 2.10321,
        QC_01: 999.913,
        VC_01: 1.90009,
        QE_01: 1113.975,
        VE_01: 1.86595,
        QA_02: 1413.562,
        VA_02: 2.16122,
        QC_02: 1130.114,
        VC_02: 2.00698,
        QE_02: 1188.733,
        VE_02: 1.92061,
        QA_03: 1629.263,
        VA_03: 2.30634,
        QC_03: 1299.632,
        VC_03: 2.13784,
        QE_03: 1498.616,
        VE_03: 2.13163,
        QA_04: 1241.848,
        VA_04: 2.03808,
        QC_04: 1105.292,
        VC_04: 1.98707,
        QE_04: 1272.344,
        VE_04: 1.97986,
        QA_05: 994.894,
        VA_05: 1.84597,
        QC_05: 994.894,
        VC_05: 1.89584,
        QE_05: 1205.607,
        VE_05: 1.93272,
        QA_06: 580.858,
        VA_06: 1.46381,
        QC_06: 580.858,
        VC_06: 1.50129,
        QE_06: 797.577,
        VE_06: 1.61274,
        QA_07: 341.416,
        VA_07: 1.17974,
        QC_07: 495.335,
        VC_07: 1.40455,
        QE_07: 592.463,
        VE_07: 1.42227,
        QA_08: 249.211,
        VA_08: 1.04561,
        QC_08: 577.36,
        VC_08: 1.49747,
        QE_08: 653.78,
        VE_08: 1.48207,
        QA_09: 266.111,
        VA_09: 1.07175,
        QC_09: 541.303,
        VC_09: 1.45744,
        QE_09: 583.7,
        VE_09: 1.41349,
        QA_10: 369.144,
        VA_10: 1.21663,
        QC_10: 618.187,
        VC_10: 1.5414,
        QE_10: 595.782,
        VE_10: 1.42558,
        QA_11: 871.826,
        VA_11: 1.74185,
        QC_11: 668.171,
        VC_11: 1.59337,
        QE_11: 749.116,
        VE_11: 1.57004,
        QA_12: 1096.338,
        VA_12: 1.92732,
        QC_12: 770.974,
        VC_12: 1.69483,
        QE_12: 867.41,
        VE_12: 1.67219,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.813792583448617, 35.741309277855812, 0.0],
          [-81.799969183470068, 35.745287077849582, 0.0],
          [-81.797779383473426, 35.743911811184951, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283889.0,
        COMID: 9752950,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.229,
        REACHCODE: "03050101000307",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002214246268162,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061176.0,
        ToNode: 250061173.0,
        Hydroseq: 250013482.0,
        LevelPathI: 250003258.0,
        Pathlength: 650.411,
        TerminalPa: 250002604.0,
        ArbolateSu: 1167.903,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013586.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013381.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0756,
        TotDASqKM: 1300.4001,
        DivDASqKM: 1300.4001,
        Tidal: 0,
        TOTMA: 0.0092047686978800006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31503.0,
        MAXELEVSMO: 31652.0,
        MINELEVSMO: 31652.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.22900000214576699,
        QA_MA: 863.912,
        VA_MA: 0.93931,
        QC_MA: 804.791,
        VC_MA: 0.9357,
        QE_MA: 959.518,
        VE_MA: 0.9447,
        QA_01: 1329.417,
        VA_01: 1.10158,
        QC_01: 998.486,
        VC_01: 1.01182,
        QE_01: 1112.547,
        VE_01: 0.99672,
        QA_02: 1411.496,
        VA_02: 1.12722,
        QC_02: 1128.508,
        VC_02: 1.05906,
        QE_02: 1187.127,
        VE_02: 1.02085,
        QA_03: 1627.042,
        VA_03: 1.1914,
        QC_03: 1297.918,
        VC_03: 1.11693,
        QE_03: 1496.902,
        VE_03: 1.11419,
        QA_04: 1240.193,
        VA_04: 1.07285,
        QC_04: 1103.852,
        VC_04: 1.0503,
        QE_04: 1270.904,
        VE_04: 1.04712,
        QA_05: 993.671,
        VA_05: 0.98798,
        QC_05: 993.671,
        VC_05: 1.01001,
        QE_05: 1204.383,
        VE_05: 1.02633,
        QA_06: 580.174,
        VA_06: 0.81908,
        QC_06: 580.174,
        VC_06: 0.83564,
        QE_06: 796.893,
        VE_06: 0.88495,
        QA_07: 341.011,
        VA_07: 0.69352,
        QC_07: 494.762,
        VC_07: 0.79289,
        QE_07: 591.881,
        VE_07: 0.80072,
        QA_08: 248.935,
        VA_08: 0.63425,
        QC_08: 576.707,
        VC_08: 0.83396,
        QE_08: 653.11,
        VE_08: 0.82714,
        QA_09: 265.895,
        VA_09: 0.64586,
        QC_09: 540.915,
        VC_09: 0.81638,
        QE_09: 583.308,
        VE_09: 0.79692,
        QA_10: 368.983,
        VA_10: 0.70999,
        QC_10: 617.971,
        VC_10: 0.85361,
        QE_10: 595.566,
        VE_10: 0.80234,
        QA_11: 870.876,
        VA_11: 0.94201,
        QC_11: 667.615,
        VC_11: 0.87644,
        QE_11: 748.563,
        VE_11: 0.86611,
        QA_12: 1094.893,
        VA_12: 1.02391,
        QC_12: 770.049,
        VC_12: 0.92117,
        QE_12: 866.484,
        VE_12: 0.91116,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.814046583448203, 35.739706411191548, 0.0],
          [-81.813792583448617, 35.741309277855812, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283890.0,
        COMID: 9753006,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.488,
        REACHCODE: "03050101000308",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014629715531804,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061200.0,
        ToNode: 250061176.0,
        Hydroseq: 250013586.0,
        LevelPathI: 250003258.0,
        Pathlength: 650.64,
        TerminalPa: 250002604.0,
        ArbolateSu: 1167.674,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013692.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250013483.0,
        DnDrainCou: 2,
        DnHydroseq: 250013482.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6488,
        TotDASqKM: 1300.3245,
        DivDASqKM: 1300.3245,
        Tidal: 0,
        TOTMA: 0.034743929025400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31510.0,
        MAXELEVSMO: 31797.0,
        MINELEVSMO: 31652.0,
        SLOPE: 0.00097446,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4880000352859499,
        QA_MA: 863.87,
        VA_MA: 1.61512,
        QC_MA: 804.753,
        VC_MA: 1.60764,
        QE_MA: 959.481,
        VE_MA: 1.62628,
        QA_01: 1329.343,
        VA_01: 1.95118,
        QC_01: 998.432,
        VC_01: 1.76527,
        QE_01: 1112.493,
        VE_01: 1.734,
        QA_02: 1411.418,
        VA_02: 2.00429,
        QC_02: 1128.447,
        VC_02: 1.86311,
        QE_02: 1187.066,
        VE_02: 1.78397,
        QA_03: 1626.957,
        VA_03: 2.13722,
        QC_03: 1297.853,
        VC_03: 1.98298,
        QE_03: 1496.837,
        VE_03: 1.97731,
        QA_04: 1240.13,
        VA_04: 1.89169,
        QC_04: 1103.798,
        VC_04: 1.84499,
        QE_04: 1270.849,
        VE_04: 1.83839,
        QA_05: 993.624,
        VA_05: 1.71592,
        QC_05: 993.624,
        VC_05: 1.76154,
        QE_05: 1204.337,
        VE_05: 1.79533,
        QA_06: 580.148,
        VA_06: 1.36611,
        QC_06: 580.148,
        VC_06: 1.4004,
        QE_06: 796.867,
        VE_06: 1.50253,
        QA_07: 340.996,
        VA_07: 1.10606,
        QC_07: 494.74,
        VC_07: 1.31185,
        QE_07: 591.859,
        VE_07: 1.32808,
        QA_08: 248.924,
        VA_08: 0.98329,
        QC_08: 576.683,
        VC_08: 1.39693,
        QE_08: 653.085,
        VE_08: 1.38279,
        QA_09: 265.887,
        VA_09: 1.00734,
        QC_09: 540.9,
        VC_09: 1.36053,
        QE_09: 583.294,
        VE_09: 1.32021,
        QA_10: 368.976,
        VA_10: 1.14017,
        QC_10: 617.963,
        VC_10: 1.43763,
        QE_10: 595.558,
        VE_10: 1.33145,
        QA_11: 870.84,
        VA_11: 1.62071,
        QC_11: 667.594,
        VC_11: 1.48492,
        QE_11: 748.542,
        VE_11: 1.46351,
        QA_12: 1094.838,
        VA_12: 1.79032,
        QC_12: 770.014,
        VC_12: 1.57755,
        QE_12: 866.449,
        VE_12: 1.55681,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.804841783462507, 35.729373677874207, 0.0],
          [-81.805345116795024, 35.732076211203434, 0.0],
          [-81.814046583448203, 35.739706411191548, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283931.0,
        COMID: 9754594,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.071,
        REACHCODE: "03050101000347",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00069243060357300002,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061656.0,
        ToNode: 250061658.0,
        Hydroseq: 250022606.0,
        LevelPathI: 250019848.0,
        Pathlength: 689.091,
        TerminalPa: 250002604.0,
        ArbolateSu: 190.121,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250023105.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022139.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 189.5175,
        DivDASqKM: 189.5175,
        Tidal: 0,
        TOTMA: 0.0016294236928500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41567.0,
        MAXELEVSMO: 41722.0,
        MINELEVSMO: 41585.0,
        SLOPE: 0.01929577,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.071000002324580994,
        QA_MA: 121.459,
        VA_MA: 1.65404,
        QC_MA: 122.098,
        VC_MA: 1.65461,
        QE_MA: 122.098,
        VE_MA: 1.65461,
        QA_01: 190.562,
        VA_01: 2.01762,
        QC_01: 150.6,
        VC_01: 1.81326,
        QE_01: 150.6,
        VE_01: 1.81326,
        QA_02: 200.303,
        VA_02: 2.06347,
        QC_02: 168.899,
        VC_02: 1.90775,
        QE_02: 168.899,
        VE_02: 1.90775,
        QA_03: 235.019,
        VA_03: 2.21893,
        QC_03: 199.843,
        VC_03: 2.05719,
        QE_03: 199.843,
        VE_03: 2.05719,
        QA_04: 174.36,
        VA_04: 1.93885,
        QC_04: 162.269,
        VC_04: 1.8741,
        QE_04: 162.269,
        VE_04: 1.8741,
        QA_05: 144.906,
        VA_05: 1.78626,
        QC_05: 136.609,
        VC_05: 1.73733,
        QE_05: 136.609,
        VE_05: 1.73733,
        QA_06: 79.001,
        VA_06: 1.37928,
        QC_06: 99.283,
        VC_06: 1.51458,
        QE_06: 99.283,
        VE_06: 1.51458,
        QA_07: 46.323,
        VA_07: 1.11496,
        QC_07: 70.496,
        VC_07: 1.31405,
        QE_07: 70.496,
        VE_07: 1.31405,
        QA_08: 32.554,
        VA_08: 0.97718,
        QC_08: 72.408,
        VC_08: 1.32844,
        QE_08: 72.408,
        VE_08: 1.32844,
        QA_09: 36.333,
        VA_09: 1.01735,
        QC_09: 93.315,
        VC_09: 1.47552,
        QE_09: 93.315,
        VE_09: 1.47552,
        QA_10: 51.011,
        VA_10: 1.15732,
        QC_10: 127.205,
        VC_10: 1.68423,
        QE_10: 127.205,
        VE_10: 1.68423,
        QA_11: 117.858,
        VA_11: 1.63271,
        QC_11: 145.247,
        VC_11: 1.78462,
        QE_11: 145.247,
        VE_11: 1.78462,
        QA_12: 149.841,
        VA_12: 1.81278,
        QC_12: 126.084,
        VC_12: 1.67778,
        QE_12: 126.084,
        VE_12: 1.67778,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.01946278312937, 35.794060277773895, 0.0],
          [-82.019013716463405, 35.793533211108013, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285933.0,
        COMID: 9754190,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 6.368,
        REACHCODE: "03050101002608",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.063014882899129002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061567.0,
        ToNode: 250061572.0,
        Hydroseq: 250020551.0,
        LevelPathI: 250019506.0,
        Pathlength: 669.356,
        TerminalPa: 250002604.0,
        ArbolateSu: 156.091,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250020919.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020193.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 16.2648,
        TotDASqKM: 201.4479,
        DivDASqKM: 201.4479,
        Tidal: 0,
        TOTMA: 28.875928631000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36438.0,
        MAXELEVSMO: 36464.0,
        MINELEVSMO: 36464.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 6.3680000305175781,
        QA_MA: 162.639,
        VA_MA: -9998.0,
        QC_MA: 161.652,
        VC_MA: -9998.0,
        QE_MA: 178.784,
        VE_MA: -9998.0,
        QA_01: 200.636,
        VA_01: -9998.0,
        QC_01: 158.348,
        VC_01: -9998.0,
        QE_01: 215.023,
        VE_01: -9998.0,
        QA_02: 220.888,
        VA_02: -9998.0,
        QC_02: 185.76,
        VC_02: -9998.0,
        QE_02: 233.402,
        VE_02: -9998.0,
        QA_03: 279.774,
        VA_03: -9998.0,
        QC_03: 236.535,
        VC_03: -9998.0,
        QE_03: 292.859,
        VE_03: -9998.0,
        QA_04: 228.912,
        VA_04: -9998.0,
        QC_04: 211.724,
        VC_04: -9998.0,
        QE_04: 240.004,
        VE_04: -9998.0,
        QA_05: 187.898,
        VA_05: -9998.0,
        QC_05: 175.426,
        VC_05: -9998.0,
        QE_05: 193.889,
        VE_05: -9998.0,
        QA_06: 129.403,
        VA_06: -9998.0,
        QC_06: 161.528,
        VC_06: -9998.0,
        QE_06: 181.161,
        VE_06: -9998.0,
        QA_07: 78.222,
        VA_07: -9998.0,
        QC_07: 117.558,
        VC_07: -9998.0,
        QE_07: 101.883,
        VE_07: -9998.0,
        QA_08: 70.39,
        VA_08: -9998.0,
        QC_08: 159.001,
        VC_08: -9998.0,
        QE_08: 111.303,
        VE_08: -9998.0,
        QA_09: 90.229,
        VA_09: -9998.0,
        QC_09: 208.319,
        VC_09: -9998.0,
        QE_09: 115.658,
        VE_09: -9998.0,
        QA_10: 104.877,
        VA_10: -9998.0,
        QC_10: 226.23,
        VC_10: -9998.0,
        QE_10: 126.981,
        VE_10: -9998.0,
        QA_11: 172.005,
        VA_11: -9998.0,
        QC_11: 193.784,
        VC_11: -9998.0,
        QE_11: 171.352,
        VE_11: -9998.0,
        QA_12: 189.528,
        VA_12: -9998.0,
        QC_12: 156.135,
        VC_12: -9998.0,
        QE_12: 161.937,
        VE_12: -9998.0,
        LakeFract: 0.09417564965,
        SurfArea: 2335913.0111199999,
        RAreaHLoad: 5.3440426419199998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.870058916694632, 35.787106477784732, 0.0],
          [-81.862106116706968, 35.781208477793712, 0.0],
          [-81.854696716718479, 35.772868811140142, 0.0],
          [-81.856103316716258, 35.766264077817027, 0.0],
          [-81.853992716719517, 35.763978677820489, 0.0],
          [-81.842025916738123, 35.757393611164112, 0.0],
          [-81.836143116747223, 35.750861211174254, 0.0],
          [-81.842018983404841, 35.744793611183638, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283932.0,
        COMID: 9754590,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 1.044,
        REACHCODE: "03050101000348",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0094039819795810001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061654.0,
        ToNode: 250061656.0,
        Hydroseq: 250023105.0,
        LevelPathI: 250019848.0,
        Pathlength: 689.162,
        TerminalPa: 250002604.0,
        ArbolateSu: 105.813,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250023646.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022606.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6282,
        TotDASqKM: 115.7157,
        DivDASqKM: 115.7157,
        Tidal: 0,
        TOTMA: 0.030318673296799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41620.0,
        MAXELEVSMO: 42360.0,
        MINELEVSMO: 41722.0,
        SLOPE: 0.00611111,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.044000029563904,
        QA_MA: 70.873,
        VA_MA: 1.30544,
        QC_MA: 72.751,
        VC_MA: 1.30756,
        QE_MA: 72.751,
        VE_MA: 1.30756,
        QA_01: 111.289,
        VA_01: 1.57539,
        QC_01: 89.2,
        VC_01: 1.42177,
        QE_01: 89.2,
        VE_01: 1.42177,
        QA_02: 115.956,
        VA_02: 1.60334,
        QC_02: 99.243,
        VC_02: 1.48667,
        QE_02: 99.243,
        VE_02: 1.48667,
        QA_03: 138.459,
        VA_03: 1.73122,
        QC_03: 119.81,
        VC_03: 1.6106,
        QE_03: 119.81,
        VE_03: 1.6106,
        QA_04: 102.353,
        VA_04: 1.52031,
        QC_04: 96.415,
        VC_04: 1.46872,
        QE_04: 96.415,
        VE_04: 1.46872,
        QA_05: 85.871,
        VA_05: 1.41243,
        QC_05: 82.554,
        VC_05: 1.37693,
        QE_05: 82.554,
        VE_05: 1.37693,
        QA_06: 46.819,
        VA_06: 1.10842,
        QC_06: 59.263,
        VC_06: 1.20448,
        QE_06: 59.263,
        VE_06: 1.20448,
        QA_07: 27.392,
        VA_07: 0.91024,
        QC_07: 42.214,
        VC_07: 1.05677,
        QE_07: 42.214,
        VE_07: 1.05677,
        QA_08: 18.925,
        VA_08: 0.80295,
        QC_08: 41.639,
        VC_08: 1.05133,
        QE_08: 41.639,
        VE_08: 1.05133,
        QA_09: 20.029,
        VA_09: 0.81806,
        QC_09: 55.157,
        VC_09: 1.17095,
        QE_09: 55.157,
        VE_09: 1.17095,
        QA_10: 27.086,
        VA_10: 0.90666,
        QC_10: 76.717,
        VC_10: 1.33612,
        QE_10: 76.717,
        VE_10: 1.33612,
        QA_11: 68.394,
        VA_11: 1.28677,
        QC_11: 95.911,
        VC_11: 1.4655,
        QE_11: 95.911,
        VE_11: 1.4655,
        QA_12: 88.102,
        VA_12: 1.42757,
        QC_12: 77.769,
        VC_12: 1.34358,
        QE_12: 77.769,
        VE_12: 1.34358,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.017961183131717, 35.80324181109296, 0.0],
          [-82.01946278312937, 35.794060277773895, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283933.0,
        COMID: 9754542,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.599,
        REACHCODE: "03050101000349",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0056286194203549996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061637.0,
        ToNode: 250061654.0,
        Hydroseq: 250023646.0,
        LevelPathI: 250019848.0,
        Pathlength: 690.206,
        TerminalPa: 250002604.0,
        ArbolateSu: 96.949,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250024228.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023105.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3105,
        TotDASqKM: 110.4714,
        DivDASqKM: 110.4714,
        Tidal: 0,
        TOTMA: 0.019932031929600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 42190.0,
        MAXELEVSMO: 42484.0,
        MINELEVSMO: 42360.0,
        SLOPE: 0.00207011,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59899997711181596,
        QA_MA: 68.065,
        VA_MA: 1.13929,
        QC_MA: 69.978,
        VC_MA: 1.14116,
        QE_MA: 69.978,
        VE_MA: 1.14116,
        QA_01: 106.529,
        VA_01: 1.36242,
        QC_01: 85.482,
        VC_01: 1.23453,
        QE_01: 85.482,
        VE_01: 1.23453,
        QA_02: 110.95,
        VA_02: 1.38542,
        QC_02: 95.074,
        VC_02: 1.28833,
        QE_02: 95.074,
        VE_02: 1.28833,
        QA_03: 132.587,
        VA_03: 1.49229,
        QC_03: 114.893,
        VC_03: 1.39198,
        QE_03: 114.893,
        VE_03: 1.39198,
        QA_04: 98.102,
        VA_04: 1.31729,
        QC_04: 92.501,
        VC_04: 1.27416,
        QE_04: 92.501,
        VE_04: 1.27416,
        QA_05: 82.456,
        VA_05: 1.22838,
        QC_05: 79.391,
        VC_05: 1.19888,
        QE_05: 79.391,
        VE_05: 1.19888,
        QA_06: 45.004,
        VA_06: 0.97541,
        QC_06: 56.997,
        VC_06: 1.0552,
        QE_06: 56.997,
        VE_06: 1.0552,
        QA_07: 26.305,
        VA_07: 0.80993,
        QC_07: 40.578,
        VC_07: 0.93191,
        QE_07: 40.578,
        VE_07: 0.93191,
        QA_08: 18.18,
        VA_08: 0.72059,
        QC_08: 39.968,
        VC_08: 0.92691,
        QE_08: 39.968,
        VE_08: 0.92691,
        QA_09: 19.484,
        VA_09: 0.73604,
        QC_09: 53.83,
        VC_09: 1.03287,
        QE_09: 53.83,
        VE_09: 1.03287,
        QA_10: 26.47,
        VA_10: 0.8116,
        QC_10: 75.32,
        VC_10: 1.17433,
        QE_10: 75.32,
        VE_10: 1.17433,
        QA_11: 66.259,
        VA_11: 1.12751,
        QC_11: 93.619,
        VC_11: 1.28034,
        QE_11: 93.619,
        VE_11: 1.28034,
        QA_12: 84.665,
        VA_12: 1.24138,
        QC_12: 75.005,
        VC_12: 1.17241,
        QE_12: 75.005,
        VE_12: 1.17241,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.019866716462047, 35.807937811085708, 0.0],
          [-82.017961183131717, 35.80324181109296, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283934.0,
        COMID: 9754528,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 2.25,
        REACHCODE: "03050101000350",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.021272392429511001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061632.0,
        ToNode: 250061637.0,
        Hydroseq: 250024228.0,
        LevelPathI: 250019848.0,
        Pathlength: 690.805,
        TerminalPa: 250002604.0,
        ArbolateSu: 93.016,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250024847.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023646.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4894,
        TotDASqKM: 106.1874,
        DivDASqKM: 106.1874,
        Tidal: 0,
        TOTMA: 0.068080689158800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 42407.0,
        MAXELEVSMO: 43581.0,
        MINELEVSMO: 42484.0,
        SLOPE: 0.00487555,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.25,
        QA_MA: 66.143,
        VA_MA: 1.25275,
        QC_MA: 68.078,
        VC_MA: 1.25496,
        QE_MA: 68.078,
        VE_MA: 1.25496,
        QA_01: 103.094,
        VA_01: 1.50372,
        QC_01: 82.797,
        VC_01: 1.35864,
        QE_01: 82.797,
        VE_01: 1.35864,
        QA_02: 107.191,
        VA_02: 1.52872,
        QC_02: 91.939,
        VC_02: 1.4187,
        QE_02: 91.939,
        VE_02: 1.4187,
        QA_03: 128.202,
        VA_03: 1.65039,
        QC_03: 111.217,
        VC_03: 1.5368,
        QE_03: 111.217,
        VE_03: 1.5368,
        QA_04: 95.077,
        VA_04: 1.45343,
        QC_04: 89.712,
        VC_04: 1.40434,
        QE_04: 89.712,
        VE_04: 1.40434,
        QA_05: 80.151,
        VA_05: 1.35416,
        QC_05: 77.254,
        VC_05: 1.3207,
        QE_05: 77.254,
        VE_05: 1.3207,
        QA_06: 43.788,
        VA_06: 1.06701,
        QC_06: 55.478,
        VC_06: 1.15748,
        QE_06: 55.478,
        VE_06: 1.15748,
        QA_07: 25.552,
        VA_07: 0.8783,
        QC_07: 39.444,
        VC_07: 1.01681,
        QE_07: 39.444,
        VE_07: 1.01681,
        QA_08: 17.658,
        VA_08: 0.77676,
        QC_08: 38.797,
        VC_08: 1.01062,
        QE_08: 38.797,
        VE_08: 1.01062,
        QA_09: 19.081,
        VA_09: 0.79643,
        QC_09: 52.845,
        VC_09: 1.13584,
        QE_09: 52.845,
        VE_09: 1.13584,
        QA_10: 26.182,
        VA_10: 0.88571,
        QC_10: 74.664,
        VC_10: 1.30253,
        QE_10: 74.664,
        VE_10: 1.30253,
        QA_11: 65.52,
        VA_11: 1.24802,
        QC_11: 92.822,
        VC_11: 1.42435,
        QE_11: 92.822,
        VE_11: 1.42435,
        QA_12: 82.416,
        VA_12: 1.36975,
        QC_12: 73.189,
        VC_12: 1.29206,
        QE_12: 73.189,
        VE_12: 1.29206,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.009215783145294, 35.825515477725105, 0.0],
          [-82.016461716467347, 35.810591277748138, 0.0],
          [-82.019866716462047, 35.807937811085708, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285928.0,
        COMID: 9754174,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 1.377,
        REACHCODE: "03050101002603",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.014697494733112999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061559.0,
        ToNode: 250061567.0,
        Hydroseq: 250020919.0,
        LevelPathI: 250019506.0,
        Pathlength: 675.724,
        TerminalPa: 250002604.0,
        ArbolateSu: 143.853,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250021301.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020551.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1742,
        TotDASqKM: 179.2386,
        DivDASqKM: 179.2386,
        Tidal: 0,
        TOTMA: 6.2440568045699996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36448.0,
        MAXELEVSMO: 36464.0,
        MINELEVSMO: 36464.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.376999974250793,
        QA_MA: 149.972,
        VA_MA: -9998.0,
        QC_MA: 149.532,
        VC_MA: -9998.0,
        QE_MA: 166.665,
        VE_MA: -9998.0,
        QA_01: 178.688,
        VA_01: -9998.0,
        QC_01: 141.454,
        VC_01: -9998.0,
        QE_01: 198.129,
        VE_01: -9998.0,
        QA_02: 197.747,
        VA_02: -9998.0,
        QC_02: 166.802,
        VC_02: -9998.0,
        QE_02: 214.444,
        VE_02: -9998.0,
        QA_03: 254.616,
        VA_03: -9998.0,
        QC_03: 215.936,
        VC_03: -9998.0,
        QE_03: 272.26,
        VE_03: -9998.0,
        QA_04: 210.029,
        VA_04: -9998.0,
        QC_04: 194.639,
        VC_04: -9998.0,
        QE_04: 222.92,
        VE_04: -9998.0,
        QA_05: 173.592,
        VA_05: -9998.0,
        QC_05: 162.55,
        VC_05: -9998.0,
        QE_05: 181.013,
        VE_05: -9998.0,
        QA_06: 121.469,
        VA_06: -9998.0,
        QC_06: 151.756,
        VC_06: -9998.0,
        QE_06: 171.389,
        VE_06: -9998.0,
        QA_07: 73.581,
        VA_07: -9998.0,
        QC_07: 110.746,
        VC_07: -9998.0,
        QE_07: 95.07,
        VE_07: -9998.0,
        QA_08: 67.095,
        VA_08: -9998.0,
        QC_08: 151.412,
        VC_08: -9998.0,
        QE_08: 103.714,
        VE_08: -9998.0,
        QA_09: 87.821,
        VA_09: -9998.0,
        QC_09: 203.404,
        VC_09: -9998.0,
        QE_09: 110.743,
        VE_09: -9998.0,
        QA_10: 102.805,
        VA_10: -9998.0,
        QC_10: 222.652,
        VC_10: -9998.0,
        QE_10: 123.404,
        VE_10: -9998.0,
        QA_11: 160.389,
        VA_11: -9998.0,
        QC_11: 183.722,
        VC_11: -9998.0,
        QE_11: 161.289,
        VE_11: -9998.0,
        QA_12: 172.824,
        VA_12: -9998.0,
        QC_12: 143.563,
        VC_12: -9998.0,
        QE_12: 149.365,
        VE_12: -9998.0,
        LakeFract: 0.01899478877,
        SurfArea: 471142.74652,
        RAreaHLoad: 1.1555820853000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.883312916674072, 35.791905077777074, 0.0],
          [-81.875354383353056, 35.790924477778731, 0.0],
          [-81.870058916694632, 35.787106477784732, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283927.0,
        COMID: 9754632,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.627,
        REACHCODE: "03050101000343",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0063123100584950003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061662.0,
        ToNode: 250061661.0,
        Hydroseq: 250020918.0,
        LevelPathI: 250019848.0,
        Pathlength: 681.899,
        TerminalPa: 250002604.0,
        ArbolateSu: 214.361,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250021300.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020550.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.135,
        TotDASqKM: 216.4392,
        DivDASqKM: 213.5232,
        Tidal: 0,
        TOTMA: 0.0169472642741,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37185.0,
        MAXELEVSMO: 37501.0,
        MINELEVSMO: 37218.0,
        SLOPE: 0.00451355,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.62699997425079301,
        QA_MA: 134.715,
        VA_MA: 1.40477,
        QC_MA: 134.88,
        VC_MA: 1.40488,
        QE_MA: 134.88,
        VE_MA: 1.40488,
        QA_01: 213.008,
        VA_01: 1.70675,
        QC_01: 167.849,
        VC_01: 1.53989,
        QE_01: 167.849,
        VE_01: 1.53989,
        QA_02: 224.056,
        VA_02: 1.74481,
        QC_02: 188.351,
        VC_02: 1.6176,
        QE_02: 188.351,
        VE_02: 1.6176,
        QA_03: 262.591,
        VA_03: 1.8711,
        QC_03: 222.473,
        VC_03: 1.73862,
        QE_03: 222.473,
        VE_03: 1.73862,
        QA_04: 194.564,
        VA_04: 1.64109,
        QC_04: 180.621,
        VC_04: 1.58879,
        QE_04: 180.621,
        VE_04: 1.58879,
        QA_05: 160.843,
        VA_05: 1.51299,
        QC_05: 151.042,
        VC_05: 1.47279,
        QE_05: 151.042,
        VE_05: 1.47279,
        QA_06: 87.545,
        VA_06: 1.18049,
        QC_06: 109.866,
        VC_06: 1.29172,
        QE_06: 109.866,
        VE_06: 1.29172,
        QA_07: 51.394,
        VA_07: 0.96565,
        QC_07: 78.019,
        VC_07: 1.12829,
        QE_07: 78.019,
        VE_07: 1.12829,
        QA_08: 35.925,
        VA_08: 0.85184,
        QC_08: 80.064,
        VC_08: 1.13963,
        QE_08: 80.064,
        VE_08: 1.13963,
        QA_09: 38.908,
        VA_09: 0.87535,
        QC_09: 99.129,
        VC_09: 1.23947,
        QE_09: 99.129,
        VE_09: 1.23947,
        QA_10: 53.324,
        VA_10: 0.97864,
        QC_10: 131.793,
        VC_10: 1.39148,
        QE_10: 131.793,
        VE_10: 1.39148,
        QA_11: 128.628,
        VA_11: 1.37818,
        QC_11: 155.264,
        VC_11: 1.48996,
        QE_11: 155.264,
        VE_11: 1.48996,
        QA_12: 166.343,
        VA_12: 1.53469,
        QC_12: 138.657,
        VC_12: 1.42108,
        QE_12: 138.657,
        VE_12: 1.42108,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.006808583148995, 35.746917877847011, 0.0],
          [-82.004902783151977, 35.743870611185059, 0.0],
          [-82.002261916489374, 35.744120077851392, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283928.0,
        COMID: 9754628,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.271,
        REACHCODE: "03050101000344",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002572008715644,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061660.0,
        ToNode: 250061662.0,
        Hydroseq: 250021300.0,
        LevelPathI: 250019848.0,
        Pathlength: 682.526,
        TerminalPa: 250002604.0,
        ArbolateSu: 208.894,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250021700.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020918.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0747,
        TotDASqKM: 213.2892,
        DivDASqKM: 213.2892,
        Tidal: 0,
        TOTMA: 0.0070108990350899996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37501.0,
        MAXELEVSMO: 37675.0,
        MINELEVSMO: 37501.0,
        SLOPE: 0.00642066,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.27099999785423301,
        QA_MA: 134.585,
        VA_MA: 1.46768,
        QC_MA: 134.755,
        VC_MA: 1.4678,
        QE_MA: 134.755,
        VE_MA: 1.4678,
        QA_01: 212.785,
        VA_01: 1.78693,
        QC_01: 167.678,
        VC_01: 1.6105,
        QE_01: 167.678,
        VE_01: 1.6105,
        QA_02: 223.82,
        VA_02: 1.82716,
        QC_02: 188.158,
        VC_02: 1.69266,
        QE_02: 188.158,
        VE_02: 1.69266,
        QA_03: 262.318,
        VA_03: 1.96071,
        QC_03: 222.249,
        VC_03: 1.82063,
        QE_03: 222.249,
        VE_03: 1.82063,
        QA_04: 194.363,
        VA_04: 1.71752,
        QC_04: 180.439,
        VC_04: 1.66221,
        QE_04: 180.439,
        VE_04: 1.66221,
        QA_05: 160.689,
        VA_05: 1.58211,
        QC_05: 150.903,
        VC_05: 1.53962,
        QE_05: 150.903,
        VE_05: 1.53962,
        QA_06: 87.46,
        VA_06: 1.23053,
        QC_06: 109.761,
        VC_06: 1.34814,
        QE_06: 109.761,
        VE_06: 1.34814,
        QA_07: 51.344,
        VA_07: 1.00336,
        QC_07: 77.945,
        VC_07: 1.17533,
        QE_07: 77.945,
        VE_07: 1.17533,
        QA_08: 35.894,
        VA_08: 0.88305,
        QC_08: 79.993,
        VC_08: 1.18735,
        QE_08: 79.993,
        VE_08: 1.18735,
        QA_09: 38.882,
        VA_09: 0.90798,
        QC_09: 99.072,
        VC_09: 1.29308,
        QE_09: 99.072,
        VE_09: 1.29308,
        QA_10: 53.305,
        VA_10: 1.01733,
        QC_10: 131.755,
        VC_10: 1.45402,
        QE_10: 131.755,
        VE_10: 1.45402,
        QA_11: 128.524,
        VA_11: 1.43966,
        QC_11: 155.168,
        VC_11: 1.55797,
        QE_11: 155.168,
        VE_11: 1.55797,
        QA_12: 166.179,
        VA_12: 1.60504,
        QC_12: 138.533,
        VC_12: 1.48495,
        QE_12: 138.533,
        VE_12: 1.48495,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.005401183151207, 35.749023477843764, 0.0],
          [-82.006808583148995, 35.746917877847011, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283929.0,
        COMID: 9755190,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 2.7,
        REACHCODE: "03050101000345",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026429569869726999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061795.0,
        ToNode: 250061660.0,
        Hydroseq: 250021700.0,
        LevelPathI: 250019848.0,
        Pathlength: 682.797,
        TerminalPa: 250002604.0,
        ArbolateSu: 207.716,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250022139.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021300.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6756,
        TotDASqKM: 212.6358,
        DivDASqKM: 212.6358,
        Tidal: 0,
        TOTMA: 0.068284825875399996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37541.0,
        MAXELEVSMO: 39759.0,
        MINELEVSMO: 37675.0,
        SLOPE: 0.00771851,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.7000000476837158,
        QA_MA: 134.222,
        VA_MA: 1.50131,
        QC_MA: 134.406,
        VC_MA: 1.50145,
        QE_MA: 134.406,
        VE_MA: 1.50145,
        QA_01: 212.166,
        VA_01: 1.82965,
        QC_01: 167.202,
        VC_01: 1.6481,
        QE_01: 167.202,
        VE_01: 1.6481,
        QA_02: 223.166,
        VA_02: 1.87103,
        QC_02: 187.623,
        VC_02: 1.73264,
        QE_02: 187.623,
        VE_02: 1.73264,
        QA_03: 261.558,
        VA_03: 2.00846,
        QC_03: 221.626,
        VC_03: 1.86434,
        QE_03: 221.626,
        VE_03: 1.86434,
        QA_04: 193.805,
        VA_04: 1.75826,
        QC_04: 179.933,
        VC_04: 1.70134,
        QE_04: 179.933,
        VE_04: 1.70134,
        QA_05: 160.262,
        VA_05: 1.61909,
        QC_05: 150.517,
        VC_05: 1.57537,
        QE_05: 150.517,
        VE_05: 1.57537,
        QA_06: 87.227,
        VA_06: 1.2573,
        QC_06: 109.473,
        VC_06: 1.37829,
        QE_06: 109.473,
        VE_06: 1.37829,
        QA_07: 51.208,
        VA_07: 1.02354,
        QC_07: 77.743,
        VC_07: 1.20049,
        QE_07: 77.743,
        VE_07: 1.20049,
        QA_08: 35.805,
        VA_08: 0.89979,
        QC_08: 79.791,
        VC_08: 1.21289,
        QE_08: 79.791,
        VE_08: 1.21289,
        QA_09: 38.812,
        VA_09: 0.92566,
        QC_09: 98.914,
        VC_09: 1.32219,
        QE_09: 98.914,
        VE_09: 1.32219,
        QA_10: 53.253,
        VA_10: 1.03857,
        QC_10: 131.651,
        VC_10: 1.4884,
        QE_10: 131.651,
        VE_10: 1.4884,
        QA_11: 128.239,
        VA_11: 1.47278,
        QC_11: 154.906,
        VC_11: 1.59485,
        QE_11: 154.906,
        VE_11: 1.59485,
        QA_12: 165.72,
        VA_12: 1.64261,
        QC_12: 138.184,
        VC_12: 1.51914,
        QE_12: 138.184,
        VE_12: 1.51914,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.011828583141209, 35.765658011151174, 0.0],
          [-82.007925983147288, 35.763685011154394, 0.0],
          [-82.005845983150436, 35.764232677820189, 0.0],
          [-82.000858916491609, 35.754999077834441, 0.0],
          [-82.000750183158402, 35.752502877838367, 0.0],
          [-82.005401183151207, 35.749023477843764, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284914.0,
        COMID: 9752656,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.192,
        REACHCODE: "03050101001231",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001875075045084,
        StreamLeve: 4,
        StreamOrde: 5,
        StreamCalc: 0,
        FromNode: 250061105.0,
        ToNode: 250061135.0,
        Hydroseq: 250015777.0,
        LevelPathI: 250015777.0,
        Pathlength: 632.242,
        TerminalPa: 250002604.0,
        ArbolateSu: 555.754,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015617.0,
        UpHydroseq: 250015944.0,
        DnLevelPat: 250015617.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015617.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0216,
        TotDASqKM: 534.7827,
        DivDASqKM: 0.0216,
        Tidal: 0,
        TOTMA: 0.0204068508941,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30293.0,
        MAXELEVSMO: 30293.0,
        MINELEVSMO: 30293.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.041999999433756,
        QA_MA: 0.01,
        VA_MA: 0.35727,
        QC_MA: 0.01,
        VC_MA: 0.35727,
        QE_MA: 0.01,
        VE_MA: 0.35727,
        QA_01: 0.02,
        VA_01: 0.37902,
        QC_01: 0.02,
        VC_01: 0.37902,
        QE_01: 0.02,
        VE_01: 0.37902,
        QA_02: 0.021,
        VA_02: 0.38088,
        QC_02: 0.021,
        VC_02: 0.38088,
        QE_02: 0.021,
        VE_02: 0.38088,
        QA_03: 0.023,
        VA_03: 0.38446,
        QC_03: 0.023,
        VC_03: 0.38446,
        QE_03: 0.023,
        VE_03: 0.38446,
        QA_04: 0.016,
        VA_04: 0.37113,
        QC_04: 0.016,
        VC_04: 0.37113,
        QE_04: 0.016,
        VE_04: 0.37113,
        QA_05: 0.011,
        VA_05: 0.35981,
        QC_05: 0.011,
        VC_05: 0.35981,
        QE_05: 0.011,
        VE_05: 0.35981,
        QA_06: 0.006,
        VA_06: 0.34566,
        QC_06: 0.006,
        VC_06: 0.34566,
        QE_06: 0.006,
        VE_06: 0.34566,
        QA_07: 0.003,
        VA_07: 0.33418,
        QC_07: 0.003,
        VC_07: 0.33418,
        QE_07: 0.003,
        VE_07: 0.33418,
        QA_08: 0.002,
        VA_08: 0.32919,
        QC_08: 0.002,
        VC_08: 0.32919,
        QE_08: 0.002,
        VE_08: 0.32919,
        QA_09: 0.002,
        VA_09: 0.32919,
        QC_09: 0.002,
        VC_09: 0.32919,
        QE_09: 0.002,
        VE_09: 0.32919,
        QA_10: 0.001,
        VA_10: 0.32278,
        QC_10: 0.001,
        VC_10: 0.32278,
        QE_10: 0.001,
        VE_10: 0.32278,
        QA_11: 0.006,
        VA_11: 0.34566,
        QC_11: 0.006,
        VC_11: 0.34566,
        QE_11: 0.006,
        VE_11: 0.34566,
        QA_12: 0.013,
        VA_12: 0.36458,
        QC_12: 0.013,
        VC_12: 0.36458,
        QE_12: 0.013,
        VE_12: 0.36458,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.690060583640687, 35.799467211098772, 0.0],
          [-81.688963116975742, 35.798139611100851, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283930.0,
        COMID: 9754616,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 3.594,
        REACHCODE: "03050101000346",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.033792095878011998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061658.0,
        ToNode: 250061795.0,
        Hydroseq: 250022139.0,
        LevelPathI: 250019848.0,
        Pathlength: 685.497,
        TerminalPa: 250002604.0,
        ArbolateSu: 202.89,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250022606.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021700.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.488,
        TotDASqKM: 207.1035,
        DivDASqKM: 207.1035,
        Tidal: 0,
        TOTMA: 0.096222174399400007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 39385.0,
        MAXELEVSMO: 41585.0,
        MINELEVSMO: 39759.0,
        SLOPE: 0.00508069,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.594000101089478,
        QA_MA: 131.14,
        VA_MA: 1.41812,
        QC_MA: 131.438,
        VC_MA: 1.41832,
        QE_MA: 131.438,
        VE_MA: 1.41832,
        QA_01: 206.933,
        VA_01: 1.72224,
        QC_01: 163.186,
        VC_01: 1.55345,
        QE_01: 163.186,
        VE_01: 1.55345,
        QA_02: 217.643,
        VA_02: 1.76064,
        QC_02: 183.105,
        VC_02: 1.63197,
        QE_02: 183.105,
        VE_02: 1.63197,
        QA_03: 255.134,
        VA_03: 1.88852,
        QC_03: 216.361,
        VC_03: 1.75461,
        QE_03: 216.361,
        VE_03: 1.75461,
        QA_04: 189.086,
        VA_04: 1.65613,
        QC_04: 175.65,
        VC_04: 1.60307,
        QE_04: 175.65,
        VE_04: 1.60307,
        QA_05: 156.62,
        VA_05: 1.52784,
        QC_05: 147.223,
        VC_05: 1.48722,
        QE_05: 147.223,
        VE_05: 1.48722,
        QA_06: 85.26,
        VA_06: 1.19132,
        QC_06: 107.037,
        VC_06: 1.30365,
        QE_06: 107.037,
        VE_06: 1.30365,
        QA_07: 50.035,
        VA_07: 0.97368,
        QC_07: 76.004,
        VC_07: 1.1382,
        QE_07: 76.004,
        VE_07: 1.1382,
        QA_08: 35.032,
        VA_08: 0.85895,
        QC_08: 78.033,
        VC_08: 1.14989,
        QE_08: 78.033,
        VE_08: 1.14989,
        QA_09: 38.217,
        VA_09: 0.88499,
        QC_09: 97.574,
        VC_09: 1.25592,
        QE_09: 97.574,
        VE_09: 1.25592,
        QA_10: 52.806,
        VA_10: 0.993,
        QC_10: 130.769,
        VC_10: 1.41532,
        QE_10: 130.769,
        VE_10: 1.41532,
        QA_11: 125.689,
        VA_11: 1.39338,
        QC_11: 152.552,
        VC_11: 1.50969,
        QE_11: 152.552,
        VE_11: 1.50969,
        QA_12: 161.826,
        VA_12: 1.5492,
        QC_12: 135.227,
        VC_12: 1.4352,
        QE_12: 135.227,
        VE_12: 1.4352,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.019013716463405, 35.793533211108013, 0.0],
          [-82.013031583139309, 35.790161811113251, 0.0],
          [-82.014862316469873, 35.787255211117724, 0.0],
          [-82.010357983143535, 35.772480811140724, 0.0],
          [-82.011828583141209, 35.765658011151174, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285940.0,
        COMID: 9754204,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.603,
        REACHCODE: "03050101002615",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0066015539800709996,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061574.0,
        ToNode: 250061576.0,
        Hydroseq: 250015460.0,
        LevelPathI: 250003258.0,
        Pathlength: 670.67,
        TerminalPa: 250002604.0,
        ArbolateSu: 686.683,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015616.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015296.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2646,
        TotDASqKM: 724.4136,
        DivDASqKM: 724.4136,
        Tidal: 0,
        TOTMA: 2.7343255286899999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36440.0,
        MAXELEVSMO: 36452.0,
        MINELEVSMO: 36452.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60299998521804798,
        QA_MA: 468.331,
        VA_MA: -9998.0,
        QC_MA: 446.772,
        VC_MA: -9998.0,
        QE_MA: 584.367,
        VE_MA: -9998.0,
        QA_01: 743.654,
        VA_01: -9998.0,
        QC_01: 567.105,
        VC_01: -9998.0,
        QE_01: 624.491,
        VE_01: -9998.0,
        QA_02: 784.043,
        VA_02: -9998.0,
        QC_02: 636.979,
        VC_02: -9998.0,
        QE_02: 647.956,
        VE_02: -9998.0,
        QA_03: 896.769,
        VA_03: -9998.0,
        QC_03: 729.575,
        VC_03: -9998.0,
        QE_03: 872.234,
        VE_03: -9998.0,
        QA_04: 672.091,
        VA_04: -9998.0,
        QC_04: 606.593,
        VC_04: -9998.0,
        QE_04: 745.364,
        VE_04: -9998.0,
        QA_05: 541.997,
        VA_05: -9998.0,
        QC_05: 486.358,
        VC_05: -9998.0,
        QE_05: 678.608,
        VE_05: -9998.0,
        QA_06: 304.847,
        VA_06: -9998.0,
        QC_06: 376.075,
        VC_06: -9998.0,
        QE_06: 573.16,
        VE_06: -9998.0,
        QA_07: 177.638,
        VA_07: -9998.0,
        QC_07: 261.781,
        VC_07: -9998.0,
        QE_07: 372.203,
        VE_07: -9998.0,
        QA_08: 121.353,
        VA_08: -9998.0,
        QC_08: 277.122,
        VC_08: -9998.0,
        QE_08: 396.458,
        VE_08: -9998.0,
        QA_09: 132.041,
        VA_09: -9998.0,
        QC_09: 291.561,
        VC_09: -9998.0,
        QE_09: 425.552,
        VE_09: -9998.0,
        QA_10: 191.251,
        VA_10: -9998.0,
        QC_10: 365.58,
        VC_10: -9998.0,
        QE_10: 442.599,
        VE_10: -9998.0,
        QA_11: 463.81,
        VA_11: -9998.0,
        QC_11: 412.913,
        VC_11: -9998.0,
        QE_11: 516.889,
        VE_11: -9998.0,
        QA_12: 593.456,
        VA_12: -9998.0,
        QC_12: 441.081,
        VC_12: -9998.0,
        QE_12: 531.32,
        VE_12: -9998.0,
        LakeFract: 0.02906947743,
        SurfArea: 721033.205586,
        RAreaHLoad: 0.5060392138000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.953066183232465, 35.749956477842261, 0.0],
          [-81.946709516575595, 35.748656811177682, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286951.0,
        COMID: 9731420,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995644",
        GNIS_NAME: "Sugar Creek",
        LENGTHKM: 2.578,
        REACHCODE: "03050103000231",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024691234943181001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058894.0,
        ToNode: 250058900.0,
        Hydroseq: 250029973.0,
        LevelPathI: 250019853.0,
        Pathlength: 434.808,
        TerminalPa: 250002604.0,
        ArbolateSu: 278.977,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019853.0,
        UpHydroseq: 250031139.0,
        DnLevelPat: 250019853.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028925.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6612,
        TotDASqKM: 575.3196,
        DivDASqKM: 575.3196,
        Tidal: 0,
        TOTMA: 0.075841818010199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15084.0,
        MAXELEVSMO: 15233.0,
        MINELEVSMO: 15084.0,
        SLOPE: 0.00057796,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5780000686645508,
        QA_MA: 226.52,
        VA_MA: 1.26415,
        QC_MA: 222.27,
        VC_MA: 1.26268,
        QE_MA: 317.944,
        VE_MA: 1.29076,
        QA_01: 480.288,
        VA_01: 1.73289,
        QC_01: 370.485,
        VC_01: 1.5601,
        QE_01: 504.877,
        VE_01: 1.56418,
        QA_02: 512.91,
        VA_02: 1.78348,
        QC_02: 421.55,
        VC_02: 1.64894,
        QE_02: 508.416,
        VE_02: 1.56885,
        QA_03: 552.258,
        VA_03: 1.84252,
        QC_03: 456.543,
        VC_03: 1.70692,
        QE_03: 547.463,
        VE_03: 1.61936,
        QA_04: 327.673,
        VA_04: 1.47114,
        QC_04: 300.609,
        VC_04: 1.42862,
        QE_04: 333.59,
        VE_04: 1.31614,
        QA_05: 184.298,
        VA_05: 1.16499,
        QC_05: 172.189,
        VC_05: 1.1417,
        QE_05: 249.599,
        VE_05: 1.17229,
        QA_06: 113.296,
        VA_06: 0.96995,
        QC_06: 141.681,
        VC_06: 1.05973,
        QE_06: 223.322,
        VE_06: 1.12274,
        QA_07: 78.216,
        VA_07: 0.8518,
        QC_07: 117.549,
        VC_07: 0.9888,
        QE_07: 230.953,
        VE_07: 1.1374,
        QA_08: 58.002,
        VA_08: 0.77202,
        QC_08: 130.51,
        VC_08: 1.02767,
        QE_08: 256.201,
        VE_08: 1.18435,
        QA_09: 49.57,
        VA_09: 0.73492,
        QC_09: 122.764,
        VC_09: 1.00467,
        QE_09: 208.133,
        VE_09: 1.09284,
        QA_10: 38.676,
        VA_10: 0.68226,
        QC_10: 101.97,
        VC_10: 0.93932,
        QE_10: 222.841,
        VE_10: 1.12181,
        QA_11: 55.263,
        VA_11: 0.76026,
        QC_11: 81.52,
        VC_11: 0.86862,
        QE_11: 219.421,
        VE_11: 1.11515,
        QA_12: 269.243,
        VA_12: 1.35598,
        QC_12: 214.894,
        VC_12: 1.24573,
        QE_12: 272.253,
        VE_12: 1.21307,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.897435584871005, 35.034164678953289, 0.0],
          [-80.903362718195069, 35.021405678973224, 0.0],
          [-80.903250384861977, 35.018817612310556, 0.0],
          [-80.907844518188142, 35.019320278976352, 0.0],
          [-80.909458584852302, 35.017922678978493, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285942.0,
        COMID: 9754208,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.625,
        REACHCODE: "03050101002617",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.026702390731794,
        StreamLeve: 2,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061576.0,
        ToNode: 250061578.0,
        Hydroseq: 250015296.0,
        LevelPathI: 250003258.0,
        Pathlength: 668.045,
        TerminalPa: 250002604.0,
        ArbolateSu: 704.559,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250015460.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015143.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.2435,
        TotDASqKM: 740.8503,
        DivDASqKM: 740.8503,
        Tidal: 0,
        TOTMA: 11.9031583941,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36427.0,
        MAXELEVSMO: 36452.0,
        MINELEVSMO: 36429.0,
        SLOPE: 8.761e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.625,
        QA_MA: 478.401,
        VA_MA: -9998.0,
        QC_MA: 456.001,
        VC_MA: -9998.0,
        QE_MA: 593.596,
        VE_MA: -9998.0,
        QA_01: 760.138,
        VA_01: -9998.0,
        QC_01: 579.343,
        VC_01: -9998.0,
        QE_01: 636.729,
        VE_01: -9998.0,
        QA_02: 801.31,
        VA_02: -9998.0,
        QC_02: 650.62,
        VC_02: -9998.0,
        QE_02: 661.597,
        VE_02: -9998.0,
        QA_03: 916.484,
        VA_03: -9998.0,
        QC_03: 745.079,
        VC_03: -9998.0,
        QE_03: 887.738,
        VE_03: -9998.0,
        QA_04: 686.923,
        VA_04: -9998.0,
        QC_04: 619.672,
        VC_04: -9998.0,
        QE_04: 758.444,
        VE_04: -9998.0,
        QA_05: 553.793,
        VA_05: -9998.0,
        QC_05: 496.543,
        VC_05: -9998.0,
        QE_05: 688.793,
        VE_05: -9998.0,
        QA_06: 311.236,
        VA_06: -9998.0,
        QC_06: 383.847,
        VC_06: -9998.0,
        QE_06: 580.933,
        VE_06: -9998.0,
        QA_07: 181.366,
        VA_07: -9998.0,
        QC_07: 267.143,
        VC_07: -9998.0,
        QE_07: 377.632,
        VE_07: -9998.0,
        QA_08: 123.839,
        VA_08: -9998.0,
        QC_08: 282.915,
        VC_08: -9998.0,
        QE_08: 402.387,
        VE_08: -9998.0,
        QA_09: 134.121,
        VA_09: -9998.0,
        QC_09: 295.611,
        VC_09: -9998.0,
        QE_09: 429.632,
        VE_09: -9998.0,
        QA_10: 194.079,
        VA_10: -9998.0,
        QC_10: 369.891,
        VC_10: -9998.0,
        QE_10: 446.906,
        VE_10: -9998.0,
        QA_11: 473.925,
        VA_11: -9998.0,
        QC_11: 419.763,
        VC_11: -9998.0,
        QE_11: 523.722,
        VE_11: -9998.0,
        QA_12: 606.586,
        VA_12: -9998.0,
        QC_12: 449.951,
        VC_12: -9998.0,
        QE_12: 540.201,
        VE_12: -9998.0,
        LakeFract: 0.12852421346000001,
        SurfArea: 3187887.5652200002,
        RAreaHLoad: 2.2029070249,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.946709516575595, 35.748656811177682, 0.0],
          [-81.94193811658306, 35.744294877851132, 0.0],
          [-81.934440983261368, 35.743671077852127, 0.0],
          [-81.931509783265952, 35.749416677843101, 0.0],
          [-81.926846116606498, 35.75265261117147, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285936.0,
        COMID: 9754196,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.461,
        REACHCODE: "03050101002611",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015935009528883,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061570.0,
        ToNode: 250061577.0,
        Hydroseq: 250019849.0,
        LevelPathI: 250019506.0,
        Pathlength: 665.518,
        TerminalPa: 250002604.0,
        ArbolateSu: 172.012,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250020193.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019506.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5048,
        TotDASqKM: 217.9233,
        DivDASqKM: 217.9233,
        Tidal: 0,
        TOTMA: 6.6249578734199996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36439.0,
        MAXELEVSMO: 36464.0,
        MINELEVSMO: 36464.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4609999656677251,
        QA_MA: 172.07,
        VA_MA: -9998.0,
        QC_MA: 170.653,
        VC_MA: -9998.0,
        QE_MA: 187.785,
        VE_MA: -9998.0,
        QA_01: 216.942,
        VA_01: -9998.0,
        QC_01: 170.867,
        VC_01: -9998.0,
        QE_01: 227.542,
        VE_01: -9998.0,
        QA_02: 238.101,
        VA_02: -9998.0,
        QC_02: 199.827,
        VC_02: -9998.0,
        QE_02: 247.469,
        VE_02: -9998.0,
        QA_03: 298.461,
        VA_03: -9998.0,
        QC_03: 251.796,
        VC_03: -9998.0,
        QE_03: 308.12,
        VE_03: -9998.0,
        QA_04: 242.946,
        VA_04: -9998.0,
        QC_04: 224.401,
        VC_04: -9998.0,
        QE_04: 252.681,
        VE_04: -9998.0,
        QA_05: 198.505,
        VA_05: -9998.0,
        QC_05: 184.948,
        VC_05: -9998.0,
        QE_05: 203.411,
        VE_05: -9998.0,
        QA_06: 135.295,
        VA_06: -9998.0,
        QC_06: 168.778,
        VC_06: -9998.0,
        QE_06: 188.411,
        VE_06: -9998.0,
        QA_07: 81.675,
        VA_07: -9998.0,
        QC_07: 122.621,
        VC_07: -9998.0,
        QE_07: 106.945,
        VE_07: -9998.0,
        QA_08: 72.827,
        VA_08: -9998.0,
        QC_08: 164.617,
        VC_08: -9998.0,
        QE_08: 116.919,
        VE_08: -9998.0,
        QA_09: 92.001,
        VA_09: -9998.0,
        QC_09: 211.927,
        VC_09: -9998.0,
        QE_09: 119.266,
        VE_09: -9998.0,
        QA_10: 106.435,
        VA_10: -9998.0,
        QC_10: 228.91,
        VC_10: -9998.0,
        QE_10: 129.661,
        VE_10: -9998.0,
        QA_11: 180.752,
        VA_11: -9998.0,
        QC_11: 201.256,
        VC_11: -9998.0,
        QE_11: 178.824,
        VE_11: -9998.0,
        QA_12: 201.99,
        VA_12: -9998.0,
        QC_12: 165.449,
        VC_12: -9998.0,
        QE_12: 171.251,
        VE_12: -9998.0,
        LakeFract: 0.02268532408,
        SurfArea: 562682.00832200004,
        RAreaHLoad: 1.22607511014,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.864715716702847, 35.747383277846268, 0.0],
          [-81.874910116687033, 35.748063877845198, 0.0],
          [-81.879940516679312, 35.746499411181048, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283935.0,
        COMID: 9754506,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.567,
        REACHCODE: "03050101000351",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0054255585716250004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061627.0,
        ToNode: 250061632.0,
        Hydroseq: 250024847.0,
        LevelPathI: 250019848.0,
        Pathlength: 693.055,
        TerminalPa: 250002604.0,
        ArbolateSu: 88.15,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250025525.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024228.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2799,
        TotDASqKM: 102.1743,
        DivDASqKM: 102.1743,
        Tidal: 0,
        TOTMA: 0.016397203046100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 43476.0,
        MAXELEVSMO: 44004.0,
        MINELEVSMO: 43581.0,
        SLOPE: 0.00746031,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.566999971866608,
        QA_MA: 64.187,
        VA_MA: 1.31062,
        QC_MA: 66.142,
        VC_MA: 1.31306,
        QE_MA: 66.142,
        VE_MA: 1.31306,
        QA_01: 99.683,
        VA_01: 1.57453,
        QC_01: 80.129,
        VC_01: 1.42079,
        QE_01: 80.129,
        VE_01: 1.42079,
        QA_02: 103.55,
        VA_02: 1.60037,
        QC_02: 88.9,
        VC_02: 1.48387,
        QE_02: 88.9,
        VE_02: 1.48387,
        QA_03: 123.928,
        VA_03: 1.72969,
        QC_03: 107.63,
        VC_03: 1.60947,
        QE_03: 107.63,
        VE_03: 1.60947,
        QA_04: 92.073,
        VA_04: 1.52224,
        QC_04: 86.94,
        VC_04: 1.47004,
        QE_04: 86.94,
        VE_04: 1.47004,
        QA_05: 77.788,
        VA_05: 1.4183,
        QC_05: 75.061,
        VC_05: 1.38286,
        QE_05: 75.061,
        VE_05: 1.38286,
        QA_06: 42.524,
        VA_06: 1.1138,
        QC_06: 53.898,
        VC_06: 1.20958,
        QE_06: 53.898,
        VE_06: 1.20958,
        QA_07: 24.801,
        VA_07: 0.91328,
        QC_07: 38.311,
        VC_07: 1.06018,
        QE_07: 38.311,
        VE_07: 1.06018,
        QA_08: 17.136,
        VA_08: 0.80546,
        QC_08: 37.627,
        VC_08: 1.05302,
        QE_08: 37.627,
        VE_08: 1.05302,
        QA_09: 18.694,
        VA_09: 0.82897,
        QC_09: 51.898,
        VC_09: 1.19166,
        QE_09: 51.898,
        VE_09: 1.19166,
        QA_10: 25.871,
        VA_10: 0.927,
        QC_10: 73.955,
        VC_10: 1.37442,
        QE_10: 73.955,
        VE_10: 1.37442,
        QA_11: 64.322,
        VA_11: 1.31174,
        QC_11: 91.524,
        VC_11: 1.50217,
        QE_11: 91.524,
        VE_11: 1.50217,
        QA_12: 80.049,
        VA_12: 1.43531,
        QC_12: 71.274,
        VC_12: 1.35372,
        QE_12: 71.274,
        VE_12: 1.35372,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.006060383150157, 35.829817811051669, 0.0],
          [-82.009215783145294, 35.825515477725105, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291079.0,
        COMID: 9718303,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1220742",
        GNIS_NAME: "Big Wateree Creek",
        LENGTHKM: 0.574,
        REACHCODE: "03050104002373",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9713751,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0054877437705159997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058179.0,
        ToNode: 250058175.0,
        Hydroseq: 250015783.0,
        LevelPathI: 250014857.0,
        Pathlength: 362.075,
        TerminalPa: 250002604.0,
        ArbolateSu: 207.217,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250014857.0,
        UpHydroseq: 250023125.0,
        DnLevelPat: 250014857.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015621.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2889,
        TotDASqKM: 344.3895,
        DivDASqKM: 344.3895,
        Tidal: 0,
        TOTMA: 1.75827181704,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6767.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.57400000095367398,
        QA_MA: 125.907,
        VA_MA: -9998.0,
        QC_MA: 126.393,
        VC_MA: -9998.0,
        QE_MA: 126.393,
        VE_MA: -9998.0,
        QA_01: 276.427,
        VA_01: -9998.0,
        QC_01: 216.34,
        VC_01: -9998.0,
        QE_01: 216.34,
        VE_01: -9998.0,
        QA_02: 303.443,
        VA_02: -9998.0,
        QC_02: 252.987,
        VC_02: -9998.0,
        QE_02: 252.987,
        VE_02: -9998.0,
        QA_03: 328.191,
        VA_03: -9998.0,
        QC_03: 276.011,
        VC_03: -9998.0,
        QE_03: 276.011,
        VE_03: -9998.0,
        QA_04: 179.581,
        VA_04: -9998.0,
        QC_04: 167.016,
        VC_04: -9998.0,
        QE_04: 167.016,
        VE_04: -9998.0,
        QA_05: 100.917,
        VA_05: -9998.0,
        QC_05: 96.435,
        VC_05: -9998.0,
        QE_05: 96.435,
        VE_05: -9998.0,
        QA_06: 64.973,
        VA_06: -9998.0,
        QC_06: 81.873,
        VC_06: -9998.0,
        QE_06: 81.873,
        VE_06: -9998.0,
        QA_07: 46.304,
        VA_07: -9998.0,
        QC_07: 70.468,
        VC_07: -9998.0,
        QE_07: 70.468,
        VE_07: -9998.0,
        QA_08: 37.043,
        VA_08: -9998.0,
        QC_08: 82.606,
        VC_08: -9998.0,
        QE_08: 82.606,
        VE_08: -9998.0,
        QA_09: 27.782,
        VA_09: -9998.0,
        QC_09: 73.631,
        VC_09: -9998.0,
        QE_09: 73.631,
        VE_09: -9998.0,
        QA_10: 23.152,
        VA_10: -9998.0,
        QC_10: 67.678,
        VC_10: -9998.0,
        QE_10: 67.678,
        VE_10: -9998.0,
        QA_11: 18.522,
        VA_11: -9998.0,
        QC_11: 35.417,
        VC_11: -9998.0,
        QE_11: 35.417,
        VE_11: -9998.0,
        QA_12: 105.251,
        VA_12: -9998.0,
        QC_12: 91.429,
        VC_12: -9998.0,
        QE_12: 91.429,
        VE_12: -9998.0,
        LakeFract: 0.2087569378,
        SurfArea: 254177.10351099999,
        RAreaHLoad: 0.82435989068000004,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.930226384820116, 34.462787813173577, 0.0],
          [-80.927392718157819, 34.467037213166918, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285938.0,
        COMID: 9754200,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 2.377,
        REACHCODE: "03050101002613",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9751286,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.025264587942535,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061572.0,
        ToNode: 250061570.0,
        Hydroseq: 250020193.0,
        LevelPathI: 250019506.0,
        Pathlength: 666.979,
        TerminalPa: 250002604.0,
        ArbolateSu: 158.468,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250020551.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019849.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5378,
        TotDASqKM: 205.9857,
        DivDASqKM: 205.9857,
        Tidal: 0,
        TOTMA: 10.7785933362,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36337.0,
        MAXELEVSMO: 36464.0,
        MINELEVSMO: 36464.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.377000093460083,
        QA_MA: 165.182,
        VA_MA: -9998.0,
        QC_MA: 164.082,
        VC_MA: -9998.0,
        QE_MA: 181.214,
        VE_MA: -9998.0,
        QA_01: 205.09,
        VA_01: -9998.0,
        QC_01: 161.77,
        VC_01: -9998.0,
        QE_01: 218.445,
        VE_01: -9998.0,
        QA_02: 225.586,
        VA_02: -9998.0,
        QC_02: 189.602,
        VC_02: -9998.0,
        QE_02: 237.244,
        VE_02: -9998.0,
        QA_03: 284.841,
        VA_03: -9998.0,
        QC_03: 240.677,
        VC_03: -9998.0,
        QE_03: 297.0,
        VE_03: -9998.0,
        QA_04: 232.707,
        VA_04: -9998.0,
        QC_04: 215.154,
        VC_04: -9998.0,
        QE_04: 243.434,
        VE_04: -9998.0,
        QA_05: 190.743,
        VA_05: -9998.0,
        QC_05: 177.981,
        VC_05: -9998.0,
        QE_05: 196.445,
        VE_05: -9998.0,
        QA_06: 130.99,
        VA_06: -9998.0,
        QC_06: 163.48,
        VC_06: -9998.0,
        QE_06: 183.114,
        VE_06: -9998.0,
        QA_07: 79.137,
        VA_07: -9998.0,
        QC_07: 118.901,
        VC_07: -9998.0,
        QE_07: 103.225,
        VE_07: -9998.0,
        QA_08: 71.061,
        VA_08: -9998.0,
        QC_08: 160.547,
        VC_08: -9998.0,
        QE_08: 112.849,
        VE_08: -9998.0,
        QA_09: 90.717,
        VA_09: -9998.0,
        QC_09: 209.314,
        VC_09: -9998.0,
        QE_09: 116.652,
        VE_09: -9998.0,
        QA_10: 105.243,
        VA_10: -9998.0,
        QC_10: 226.86,
        VC_10: -9998.0,
        QE_10: 127.612,
        VE_10: -9998.0,
        QA_11: 174.288,
        VA_11: -9998.0,
        QC_11: 195.743,
        VC_11: -9998.0,
        QE_11: 173.311,
        VE_11: -9998.0,
        QA_12: 192.881,
        VA_12: -9998.0,
        QC_12: 158.646,
        VC_12: -9998.0,
        QE_12: 164.448,
        VE_12: -9998.0,
        LakeFract: 0.03562698958,
        SurfArea: 883684.35807399999,
        RAreaHLoad: 1.9947847615400001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.842018983404841, 35.744793611183638, 0.0],
          [-81.848415116728233, 35.741362277855558, 0.0],
          [-81.856779983381898, 35.742773211186829, 0.0],
          [-81.864715716702847, 35.747383277846268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283915.0,
        COMID: 9751352,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.05,
        REACHCODE: "03050101000332",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00046447669881000001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060750.0,
        ToNode: 250060758.0,
        Hydroseq: 250031117.0,
        LevelPathI: 250019506.0,
        Pathlength: 713.359,
        TerminalPa: 250002604.0,
        ArbolateSu: 68.149,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250032418.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029953.0,
        FromMeas: 0.0,
        ToMeas: 11.09862,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 85.6845,
        DivDASqKM: 85.6845,
        Tidal: 0,
        TOTMA: 0.00289218743819,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 99290.0,
        MAXELEVSMO: 99290.0,
        MINELEVSMO: 99290.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.050000000745057997,
        QA_MA: 80.834,
        VA_MA: 0.65588,
        QC_MA: 82.553,
        VC_MA: 0.65647,
        QE_MA: 82.553,
        VE_MA: 0.65647,
        QA_01: 81.649,
        VA_01: 0.65773,
        QC_01: 65.976,
        VC_01: 0.61741,
        QE_01: 65.976,
        VE_01: 0.61741,
        QA_02: 93.441,
        VA_02: 0.68368,
        QC_02: 80.446,
        VC_02: 0.65172,
        QE_02: 80.446,
        VE_02: 0.65172,
        QA_03: 132.157,
        VA_03: 0.75952,
        QC_03: 114.533,
        VC_03: 0.72257,
        QE_03: 114.533,
        VE_03: 0.72257,
        QA_04: 112.594,
        VA_04: 0.72273,
        QC_04: 105.833,
        VC_04: 0.70555,
        QE_04: 105.833,
        VE_04: 0.70555,
        QA_05: 93.658,
        VA_05: 0.68414,
        QC_05: 89.748,
        VC_05: 0.67226,
        QE_05: 89.748,
        VE_05: 0.67226,
        QA_06: 72.907,
        VA_06: 0.63734,
        QC_06: 91.726,
        VC_06: 0.6765,
        QE_06: 91.726,
        VE_06: 0.6765,
        QA_07: 45.609,
        VA_07: 0.56482,
        QC_07: 69.436,
        VC_07: 0.62591,
        QE_07: 69.436,
        VE_07: 0.62591,
        QA_08: 44.393,
        VA_08: 0.56116,
        QC_08: 99.355,
        VC_08: 0.69245,
        QE_08: 99.355,
        VE_08: 0.69245,
        QA_09: 57.644,
        VA_09: 0.59877,
        QC_09: 140.258,
        VC_09: 0.76961,
        QE_09: 140.258,
        VE_09: 0.76961,
        QA_10: 63.566,
        VA_10: 0.61425,
        QC_10: 151.65,
        VC_10: 0.78914,
        QE_10: 151.65,
        VE_10: 0.78914,
        QA_11: 85.289,
        VA_11: 0.66592,
        QC_11: 113.497,
        VC_11: 0.72057,
        QE_11: 113.497,
        VE_11: 0.72057,
        QA_12: 87.683,
        VA_12: 0.67121,
        QC_12: 77.433,
        VC_12: 0.64483,
        QE_12: 77.433,
        VE_12: 0.64483,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.937705183256298, 36.000143610787291, 0.0],
          [-81.937906183255961, 35.999724877454639, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283916.0,
        COMID: 9750524,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.402,
        REACHCODE: "03050101000332",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0037457545764060002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060559.0,
        ToNode: 250060750.0,
        Hydroseq: 250032418.0,
        LevelPathI: 250019506.0,
        Pathlength: 713.409,
        TerminalPa: 250002604.0,
        ArbolateSu: 68.099,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250033890.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031117.0,
        FromMeas: 11.09862,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1899,
        TotDASqKM: 85.6548,
        DivDASqKM: 85.6548,
        Tidal: 0,
        TOTMA: 0.011422835033,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 99144.0,
        MAXELEVSMO: 99655.0,
        MINELEVSMO: 99290.0,
        SLOPE: 0.0090796,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40200001001357999,
        QA_MA: 80.808,
        VA_MA: 1.33461,
        QC_MA: 82.528,
        VC_MA: 1.33636,
        QE_MA: 82.528,
        VE_MA: 1.33636,
        QA_01: 81.616,
        VA_01: 1.34004,
        QC_01: 65.951,
        VC_01: 1.22097,
        QE_01: 65.951,
        VE_01: 1.22097,
        QA_02: 93.404,
        VA_02: 1.41666,
        QC_02: 80.415,
        VC_02: 1.3223,
        QE_02: 80.415,
        VE_02: 1.3223,
        QA_03: 132.117,
        VA_03: 1.64071,
        QC_03: 114.499,
        VC_03: 1.53156,
        QE_03: 114.499,
        VE_03: 1.53156,
        QA_04: 112.56,
        VA_04: 1.53206,
        QC_04: 105.802,
        VC_04: 1.48132,
        QE_04: 105.802,
        VE_04: 1.48132,
        QA_05: 93.63,
        VA_05: 1.41808,
        QC_05: 89.723,
        VC_05: 1.38301,
        QE_05: 89.723,
        VE_05: 1.38301,
        QA_06: 72.888,
        VA_06: 1.27991,
        QC_06: 91.702,
        VC_06: 1.39554,
        QE_06: 91.702,
        VE_06: 1.39554,
        QA_07: 45.599,
        VA_07: 1.06572,
        QC_07: 69.42,
        VC_07: 1.24615,
        QE_07: 69.42,
        VE_07: 1.24615,
        QA_08: 44.382,
        VA_08: 1.05492,
        QC_08: 99.331,
        VC_08: 1.44266,
        QE_08: 99.331,
        VE_08: 1.44266,
        QA_09: 57.629,
        VA_09: 1.16598,
        QC_09: 140.226,
        VC_09: 1.67056,
        QE_09: 140.226,
        VE_09: 1.67056,
        QA_10: 63.548,
        VA_10: 1.21168,
        QC_10: 151.615,
        VC_10: 1.72823,
        QE_10: 151.615,
        VE_10: 1.72823,
        QA_11: 85.26,
        VA_11: 1.36425,
        QC_11: 113.468,
        VC_11: 1.5257,
        QE_11: 113.468,
        VE_11: 1.5257,
        QA_12: 87.652,
        VA_12: 1.37988,
        QC_12: 77.409,
        VC_12: 1.30199,
        QE_12: 77.409,
        VE_12: 1.30199,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.936069116592193, 36.003423210782216, 0.0],
          [-81.937705183256298, 36.000143610787291, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285982.0,
        COMID: 9751026,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.527,
        REACHCODE: "03050101002657",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015650695705506,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060676.0,
        ToNode: 250060680.0,
        Hydroseq: 250008994.0,
        LevelPathI: 250003258.0,
        Pathlength: 544.717,
        TerminalPa: 250002604.0,
        ArbolateSu: 3629.241,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009038.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008957.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9034,
        TotDASqKM: 4122.297,
        DivDASqKM: 4122.297,
        Tidal: 0,
        TOTMA: 1.9043420342899999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23067.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.526999950408936,
        QA_MA: 2408.857,
        VA_MA: -9998.0,
        QC_MA: 2408.857,
        VC_MA: -9998.0,
        QE_MA: 2602.942,
        VE_MA: -9998.0,
        QA_01: 3957.924,
        VA_01: -9998.0,
        QC_01: 3957.924,
        VC_01: -9998.0,
        QE_01: 4142.475,
        VE_01: -9998.0,
        QA_02: 4180.352,
        VA_02: -9998.0,
        QC_02: 4180.352,
        VC_02: -9998.0,
        QE_02: 4228.349,
        VE_02: -9998.0,
        QA_03: 4705.511,
        VA_03: -9998.0,
        QC_03: 4705.511,
        VC_03: -9998.0,
        QE_03: 4941.35,
        VE_03: -9998.0,
        QA_04: 3590.305,
        VA_04: -9998.0,
        QC_04: 3590.305,
        VC_04: -9998.0,
        QE_04: 3772.757,
        VE_04: -9998.0,
        QA_05: 2697.538,
        VA_05: -9998.0,
        QC_05: 2697.538,
        VC_05: -9998.0,
        QE_05: 2920.636,
        VE_05: -9998.0,
        QA_06: 1574.06,
        VA_06: -9998.0,
        QC_06: 1574.06,
        VC_06: -9998.0,
        QE_06: 1851.734,
        VE_06: -9998.0,
        QA_07: 933.527,
        VA_07: -9998.0,
        QC_07: 933.527,
        VC_07: -9998.0,
        QE_07: 1099.295,
        VE_07: -9998.0,
        QA_08: 653.383,
        VA_08: -9998.0,
        QC_08: 653.383,
        VC_08: -9998.0,
        QE_08: 973.925,
        VE_08: -9998.0,
        QA_09: 628.209,
        VA_09: -9998.0,
        QC_09: 1155.568,
        VC_09: -9998.0,
        QE_09: 1207.227,
        VE_09: -9998.0,
        QA_10: 780.16,
        VA_10: -9998.0,
        QC_10: 780.16,
        VC_10: -9998.0,
        QE_10: 742.164,
        VE_10: -9998.0,
        QA_11: 2145.676,
        VA_11: -9998.0,
        QC_11: 2145.676,
        VC_11: -9998.0,
        QE_11: 2095.641,
        VE_11: -9998.0,
        QA_12: 3075.314,
        VA_12: -9998.0,
        QC_12: 3075.314,
        VC_12: -9998.0,
        QE_12: 3076.503,
        VE_12: -9998.0,
        LakeFract: 0.02519550255,
        SurfArea: 3295956.3717399999,
        RAreaHLoad: 0.5166148721,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.982700584738666, 35.682972677946339, 0.0],
          [-80.974464118084768, 35.676290211290052, 0.0],
          [-80.970051984758243, 35.674640877959177, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283917.0,
        COMID: 9750508,
        FDATE: "1999/09/13 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 1.995,
        REACHCODE: "03050101000333",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.019180967105246,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060556.0,
        ToNode: 250060559.0,
        Hydroseq: 250033890.0,
        LevelPathI: 250019506.0,
        Pathlength: 713.811,
        TerminalPa: 250002604.0,
        ArbolateSu: 62.463,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250035550.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032418.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.872,
        TotDASqKM: 80.8416,
        DivDASqKM: 80.8416,
        Tidal: 0,
        TOTMA: 0.059981082293199997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 99551.0,
        MAXELEVSMO: 100888.0,
        MINELEVSMO: 99655.0,
        SLOPE: 0.00618045,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.995000004768372,
        QA_MA: 76.483,
        VA_MA: 1.2612,
        QC_MA: 78.277,
        VC_MA: 1.26299,
        QE_MA: 78.277,
        VE_MA: 1.26299,
        QA_01: 76.4,
        VA_01: 1.26065,
        QC_01: 61.842,
        VC_01: 1.15071,
        QE_01: 61.842,
        VE_01: 1.15071,
        QA_02: 87.521,
        VA_02: 1.33191,
        QC_02: 75.484,
        VC_02: 1.24475,
        QE_02: 75.484,
        VE_02: 1.24475,
        QA_03: 125.116,
        VA_03: 1.54578,
        QC_03: 108.627,
        VC_03: 1.44441,
        QE_03: 108.627,
        VE_03: 1.44441,
        QA_04: 106.588,
        VA_04: 1.44483,
        QC_04: 100.313,
        VC_04: 1.39738,
        QE_04: 100.313,
        VE_04: 1.39738,
        QA_05: 88.907,
        VA_05: 1.34048,
        QC_05: 85.362,
        VC_05: 1.30794,
        QE_05: 85.362,
        VE_05: 1.30794,
        QA_06: 69.505,
        VA_06: 1.21401,
        QC_06: 87.503,
        VC_06: 1.32118,
        QE_06: 87.503,
        VE_06: 1.32118,
        QA_07: 43.721,
        VA_07: 1.0164,
        QC_07: 66.628,
        VC_07: 1.18472,
        QE_07: 66.628,
        VE_07: 1.18472,
        QA_08: 42.334,
        VA_08: 1.00439,
        QC_08: 94.658,
        VC_08: 1.36434,
        QE_08: 94.658,
        VE_08: 1.36434,
        QA_09: 54.896,
        VA_09: 1.10736,
        QC_09: 134.337,
        VC_09: 1.58006,
        QE_09: 134.337,
        VE_09: 1.58006,
        QA_10: 60.404,
        VA_10: 1.14897,
        QC_10: 145.593,
        VC_10: 1.63557,
        QE_10: 145.593,
        VE_10: 1.63557,
        QA_11: 80.411,
        VA_11: 1.28688,
        QC_11: 108.513,
        VC_11: 1.44378,
        QE_11: 108.513,
        VE_11: 1.44378,
        QA_12: 82.52,
        VA_12: 1.30043,
        QC_12: 73.274,
        VC_12: 1.2301,
        QE_12: 73.274,
        VE_12: 1.2301,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.926185516607518, 36.015449877430228, 0.0],
          [-81.928131116604447, 36.016685477428155, 0.0],
          [-81.930385116600974, 36.01542507743028, 0.0],
          [-81.932632783264069, 36.00582941077846, 0.0],
          [-81.936069116592193, 36.003423210782216, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285975.0,
        COMID: 9751010,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.695,
        REACHCODE: "03050101002650",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0074120166010270003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060668.0,
        ToNode: 250060672.0,
        Hydroseq: 250009078.0,
        LevelPathI: 250003258.0,
        Pathlength: 548.681,
        TerminalPa: 250002604.0,
        ArbolateSu: 3568.995,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009115.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009038.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4221,
        TotDASqKM: 4045.1526,
        DivDASqKM: 4045.1526,
        Tidal: 0,
        TOTMA: 0.86674375511000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22968.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.69599997997283902,
        QA_MA: 2374.652,
        VA_MA: -9998.0,
        QC_MA: 2374.652,
        VC_MA: -9998.0,
        QE_MA: 2568.699,
        VE_MA: -9998.0,
        QA_01: 3891.186,
        VA_01: -9998.0,
        QC_01: 3891.186,
        VC_01: -9998.0,
        QE_01: 4075.992,
        VE_01: -9998.0,
        QA_02: 4110.545,
        VA_02: -9998.0,
        QC_02: 4110.545,
        VC_02: -9998.0,
        QE_02: 4158.841,
        VE_02: -9998.0,
        QA_03: 4630.317,
        VA_03: -9998.0,
        QC_03: 4630.317,
        VC_03: -9998.0,
        QE_03: 4866.581,
        VE_03: -9998.0,
        QA_04: 3539.072,
        VA_04: -9998.0,
        QC_04: 3539.072,
        VC_04: -9998.0,
        QE_04: 3721.649,
        VE_04: -9998.0,
        QA_05: 2664.826,
        VA_05: -9998.0,
        QC_05: 2664.826,
        VC_05: -9998.0,
        QE_05: 2887.889,
        VE_05: -9998.0,
        QA_06: 1554.733,
        VA_06: -9998.0,
        QC_06: 1554.733,
        VC_06: -9998.0,
        QE_06: 1832.109,
        VE_06: -9998.0,
        QA_07: 921.314,
        VA_07: -9998.0,
        QC_07: 921.314,
        VC_07: -9998.0,
        QE_07: 1086.794,
        VE_07: -9998.0,
        QA_08: 645.082,
        VA_08: -9998.0,
        QC_08: 645.082,
        VC_08: -9998.0,
        QE_08: 965.267,
        VE_08: -9998.0,
        QA_09: 620.949,
        VA_09: -9998.0,
        QC_09: 1143.769,
        VC_09: -9998.0,
        QE_09: 1195.148,
        VE_09: -9998.0,
        QA_10: 774.893,
        VA_10: -9998.0,
        QC_10: 774.893,
        VC_10: -9998.0,
        QE_10: 736.494,
        VE_10: -9998.0,
        QA_11: 2127.992,
        VA_11: -9998.0,
        QC_11: 2127.992,
        VC_11: -9998.0,
        QE_11: 2077.849,
        VE_11: -9998.0,
        QA_12: 3030.495,
        VA_12: -9998.0,
        QC_12: 3030.495,
        VC_12: -9998.0,
        QE_12: 3031.838,
        VE_12: -9998.0,
        LakeFract: 0.01131691486,
        SurfArea: 1480425.2293499999,
        RAreaHLoad: 0.23513250568,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.999799518045393, 35.70373081124734, 0.0],
          [-80.99307571805582, 35.701052211251643, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283911.0,
        COMID: 9751542,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 3.964,
        REACHCODE: "03050101000328",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.039061529436384003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060803.0,
        ToNode: 250060805.0,
        Hydroseq: 250027075.0,
        LevelPathI: 250019506.0,
        Pathlength: 705.321,
        TerminalPa: 250002604.0,
        ArbolateSu: 89.546,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250027947.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026278.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.2876,
        TotDASqKM: 109.6227,
        DivDASqKM: 109.6227,
        Tidal: 0,
        TOTMA: 0.12876941807699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 96757.0,
        MAXELEVSMO: 98156.0,
        MINELEVSMO: 97326.0,
        SLOPE: 0.00209384,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.96399998664856,
        QA_MA: 101.785,
        VA_MA: 1.16739,
        QC_MA: 103.024,
        VC_MA: 1.16823,
        QE_MA: 104.075,
        VE_MA: 1.16894,
        QA_01: 106.731,
        VA_01: 1.18931,
        QC_01: 85.64,
        VC_01: 1.08786,
        QE_01: 89.118,
        VE_01: 1.10089,
        QA_02: 121.898,
        VA_02: 1.25371,
        QC_02: 104.187,
        VC_02: 1.17337,
        QE_02: 107.111,
        VE_02: 1.18218,
        QA_03: 166.314,
        VA_03: 1.42327,
        QC_03: 143.045,
        VC_03: 1.33193,
        QE_03: 146.502,
        VE_03: 1.34027,
        QA_04: 141.404,
        VA_04: 1.33123,
        QC_04: 132.226,
        VC_04: 1.29007,
        QE_04: 133.962,
        VE_04: 1.29238,
        QA_05: 117.042,
        VA_05: 1.23352,
        QC_05: 111.225,
        VC_05: 1.20393,
        QE_05: 112.358,
        VE_05: 1.20465,
        QA_06: 89.279,
        VA_06: 1.10963,
        QC_06: 112.012,
        VC_06: 1.20728,
        QE_06: 113.217,
        VE_06: 1.20829,
        QA_07: 54.923,
        VA_07: 0.92743,
        QC_07: 83.244,
        VC_07: 1.07621,
        QE_07: 82.282,
        VE_07: 1.068,
        QA_08: 53.604,
        VA_08: 0.91949,
        QC_08: 120.425,
        VC_08: 1.24252,
        QE_08: 117.498,
        VE_08: 1.2262,
        QA_09: 70.8,
        VA_09: 1.0168,
        QC_09: 168.17,
        VC_09: 1.42377,
        QE_09: 162.483,
        VE_09: 1.39858,
        QA_10: 79.056,
        VA_10: 1.05952,
        QC_10: 180.508,
        VC_10: 1.4665,
        QE_10: 174.417,
        VE_10: 1.4404,
        QA_11: 108.825,
        VA_11: 1.19844,
        QC_11: 136.678,
        VC_11: 1.30748,
        QE_11: 135.302,
        VE_11: 1.2976,
        QA_12: 112.314,
        VA_12: 1.21348,
        QC_12: 96.996,
        VC_12: 1.14114,
        QE_12: 97.352,
        VE_12: 1.13896,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.936368383258298, 35.981200410816655, 0.0],
          [-81.939546183253356, 35.97480961082664, 0.0],
          [-81.935936983259012, 35.971033210832502, 0.0],
          [-81.930584183267342, 35.970372077500258, 0.0],
          [-81.934300983261494, 35.968400810836613, 0.0],
          [-81.935341183259936, 35.966041610840136, 0.0],
          [-81.931058383266588, 35.964486677509285, 0.0],
          [-81.931420983266037, 35.95988367751653, 0.0],
          [-81.929756383268625, 35.956380877521951, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283912.0,
        COMID: 9751432,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 3.02,
        REACHCODE: "03050101000329",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031116499540176,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060777.0,
        ToNode: 250060803.0,
        Hydroseq: 250027947.0,
        LevelPathI: 250019506.0,
        Pathlength: 709.285,
        TerminalPa: 250002604.0,
        ArbolateSu: 79.252,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250028909.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027075.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.2615,
        TotDASqKM: 95.9571,
        DivDASqKM: 95.9571,
        Tidal: 0,
        TOTMA: 0.095921097190399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 98156.0,
        MAXELEVSMO: 99079.0,
        MINELEVSMO: 98156.0,
        SLOPE: 0.00305629,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0199999809265141,
        QA_MA: 89.651,
        VA_MA: 1.19359,
        QC_MA: 91.191,
        VC_MA: 1.19482,
        QE_MA: 92.111,
        VE_MA: 1.19554,
        QA_01: 92.807,
        VA_01: 1.21001,
        QC_01: 74.741,
        VC_01: 1.10596,
        QE_01: 77.786,
        VE_01: 1.11938,
        QA_02: 105.958,
        VA_02: 1.27574,
        QC_02: 90.91,
        VC_02: 1.19336,
        QE_02: 93.469,
        VE_02: 1.20246,
        QA_03: 146.239,
        VA_03: 1.45624,
        QC_03: 126.314,
        VC_03: 1.36224,
        QE_03: 129.34,
        VE_03: 1.37076,
        QA_04: 124.623,
        VA_04: 1.36277,
        QC_04: 116.869,
        VC_04: 1.31963,
        QE_04: 118.389,
        VE_04: 1.32201,
        QA_05: 103.332,
        VA_05: 1.26293,
        QC_05: 98.656,
        VC_05: 1.23264,
        QE_05: 99.647,
        VE_05: 1.23337,
        QA_06: 79.584,
        VA_06: 1.13934,
        QC_06: 100.006,
        VC_06: 1.23933,
        QE_06: 101.06,
        VE_06: 1.24031,
        QA_07: 49.34,
        VA_07: 0.95304,
        QC_07: 74.975,
        VC_07: 1.10728,
        QE_07: 74.133,
        VE_07: 1.09893,
        QA_08: 48.129,
        VA_08: 0.94459,
        QC_08: 107.891,
        VC_08: 1.27761,
        QE_08: 105.329,
        VE_08: 1.26101,
        QA_09: 63.061,
        VA_09: 1.04275,
        QC_09: 151.833,
        VC_09: 1.47041,
        QE_09: 146.855,
        VE_09: 1.44487,
        QA_10: 69.977,
        VA_10: 1.08447,
        QC_10: 163.748,
        VC_10: 1.51797,
        QE_10: 158.417,
        VE_10: 1.49154,
        QA_11: 95.266,
        VA_11: 1.22261,
        QC_11: 123.488,
        VC_11: 1.34965,
        QE_11: 122.283,
        VE_11: 1.33958,
        QA_12: 98.191,
        VA_12: 1.23741,
        QC_12: 85.832,
        VC_12: 1.16676,
        QE_12: 86.144,
        VE_12: 1.16454,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.937025116590632, 35.991229877467788, 0.0],
          [-81.94249038324881, 35.989967077469714, 0.0],
          [-81.948768183239054, 35.984673410811297, 0.0],
          [-81.948371783239736, 35.982681410814337, 0.0],
          [-81.940959316584497, 35.979709210819067, 0.0],
          [-81.939553116586751, 35.982435010814754, 0.0],
          [-81.936368383258298, 35.981200410816655, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287003.0,
        COMID: 25122791,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.347,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00365510056121,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250093423.0,
        ToNode: 250059852.0,
        Hydroseq: 250007079.0,
        LevelPathI: 250003258.0,
        Pathlength: 434.595,
        TerminalPa: 250002604.0,
        ArbolateSu: 5928.703,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007106.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007056.0,
        FromMeas: 86.09997,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.153,
        TotDASqKM: 7878.1896,
        DivDASqKM: 7878.1896,
        Tidal: 0,
        TOTMA: 0.010554473913599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14981.0,
        MAXELEVSMO: 15153.0,
        MINELEVSMO: 15153.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.347000002861023,
        QA_MA: 4091.353,
        VA_MA: 1.24393,
        QC_MA: 4091.353,
        VC_MA: 1.24393,
        QE_MA: 4341.397,
        VE_MA: 1.24843,
        QA_01: 7273.467,
        VA_01: 1.57821,
        QC_01: 7273.467,
        VC_01: 1.57821,
        QE_01: 7432.983,
        VE_01: 1.55909,
        QA_02: 7697.736,
        VA_02: 1.61702,
        QC_02: 7697.736,
        VC_02: 1.61702,
        QE_02: 7710.361,
        VE_02: 1.58366,
        QA_03: 8490.688,
        VA_03: 1.68696,
        QC_03: 8490.688,
        VC_03: 1.68696,
        QE_03: 8710.401,
        VE_03: 1.66898,
        QA_04: 6027.031,
        VA_04: 1.45759,
        QC_04: 6027.031,
        VC_04: 1.45759,
        QE_04: 6192.499,
        VE_04: 1.44352,
        QA_05: 4243.824,
        VA_05: 1.26228,
        QC_05: 4243.824,
        VC_05: 1.26228,
        QE_05: 4563.215,
        VE_05: 1.27364,
        QA_06: 2485.529,
        VA_06: 1.0264,
        QC_06: 2485.529,
        VC_06: 1.0264,
        QE_06: 2834.624,
        VE_06: 1.05801,
        QA_07: 1512.577,
        VA_07: 0.85994,
        QC_07: 1512.577,
        VC_07: 0.85994,
        QE_07: 1769.162,
        VE_07: 0.89201,
        QA_08: 1070.714,
        VA_08: 0.7675,
        QC_08: 1070.714,
        VC_08: 0.7675,
        QE_08: 1481.907,
        VE_08: 0.8396,
        QA_09: 989.894,
        VA_09: 0.74876,
        QC_09: 989.894,
        VC_09: 0.74876,
        QE_09: 1111.079,
        VE_09: 0.76427,
        QA_10: 1115.764,
        VA_10: 0.77765,
        QC_10: 1115.764,
        VC_10: 0.77765,
        QE_10: 1391.792,
        VE_10: 0.8222,
        QA_11: 2984.081,
        VA_11: 1.09959,
        QC_11: 2984.081,
        VC_11: 1.09959,
        QE_11: 3146.669,
        VE_11: 1.10076,
        QA_12: 5232.875,
        VA_12: 1.37452,
        QC_12: 5232.875,
        VC_12: 1.37452,
        QE_12: 5233.505,
        VE_12: 1.3465,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.992851184722838, 34.991063279020238, 0.0],
          [-80.989554584727898, 34.989484612356023, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285978.0,
        COMID: 9751018,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.437,
        REACHCODE: "03050101002653",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.023299710318654,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060672.0,
        ToNode: 250060676.0,
        Hydroseq: 250009038.0,
        LevelPathI: 250003258.0,
        Pathlength: 546.244,
        TerminalPa: 250002604.0,
        ArbolateSu: 3589.296,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009078.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008994.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.4965,
        TotDASqKM: 4069.2042,
        DivDASqKM: 4069.2042,
        Tidal: 0,
        TOTMA: 3.0392151525100002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23058.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.437000036239624,
        QA_MA: 2385.089,
        VA_MA: -9998.0,
        QC_MA: 2385.089,
        VC_MA: -9998.0,
        QE_MA: 2579.148,
        VE_MA: -9998.0,
        QA_01: 3911.632,
        VA_01: -9998.0,
        QC_01: 3911.632,
        VC_01: -9998.0,
        QE_01: 4096.358,
        VE_01: -9998.0,
        QA_02: 4131.984,
        VA_02: -9998.0,
        QC_02: 4131.984,
        VC_02: -9998.0,
        QE_02: 4180.187,
        VE_02: -9998.0,
        QA_03: 4653.475,
        VA_03: -9998.0,
        QC_03: 4653.475,
        VC_03: -9998.0,
        QE_03: 4889.606,
        VE_03: -9998.0,
        QA_04: 3554.824,
        VA_04: -9998.0,
        QC_04: 3554.824,
        VC_04: -9998.0,
        QE_04: 3737.361,
        VE_04: -9998.0,
        QA_05: 2674.771,
        VA_05: -9998.0,
        QC_05: 2674.771,
        VC_05: -9998.0,
        QE_05: 2897.845,
        VE_05: -9998.0,
        QA_06: 1560.603,
        VA_06: -9998.0,
        QC_06: 1560.603,
        VC_06: -9998.0,
        QE_06: 1838.072,
        VE_06: -9998.0,
        QA_07: 925.045,
        VA_07: -9998.0,
        QC_07: 925.045,
        VC_07: -9998.0,
        QE_07: 1090.614,
        VE_07: -9998.0,
        QA_08: 647.669,
        VA_08: -9998.0,
        QC_08: 647.669,
        VC_08: -9998.0,
        QE_08: 967.965,
        VE_08: -9998.0,
        QA_09: 623.212,
        VA_09: -9998.0,
        QC_09: 1147.449,
        VC_09: -9998.0,
        QE_09: 1198.915,
        VE_09: -9998.0,
        QA_10: 776.51,
        VA_10: -9998.0,
        QC_10: 776.51,
        VC_10: -9998.0,
        QE_10: 738.236,
        VE_10: -9998.0,
        QA_11: 2132.89,
        VA_11: -9998.0,
        QC_11: 2132.89,
        VC_11: -9998.0,
        QE_11: 2082.781,
        VE_11: -9998.0,
        QA_12: 3044.131,
        VA_12: -9998.0,
        QC_12: 3044.131,
        VC_12: -9998.0,
        QE_12: 3045.425,
        VE_12: -9998.0,
        LakeFract: 0.03984360058,
        SurfArea: 5212151.2140499996,
        RAreaHLoad: 0.82448621047000004,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.99307571805582, 35.701052211251643, 0.0],
          [-80.991451584724985, 35.694739877928043, 0.0],
          [-80.986865584732072, 35.692255277931906, 0.0],
          [-80.982250984739267, 35.686510611274173, 0.0],
          [-80.982700584738666, 35.682972677946339, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283913.0,
        COMID: 9751390,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.449,
        REACHCODE: "03050101000330",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0042632078096710001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060763.0,
        ToNode: 250060777.0,
        Hydroseq: 250028909.0,
        LevelPathI: 250019506.0,
        Pathlength: 712.305,
        TerminalPa: 250002604.0,
        ArbolateSu: 74.747,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250029953.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027947.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.342,
        TotDASqKM: 90.8838,
        DivDASqKM: 90.8838,
        Tidal: 0,
        TOTMA: 0.0138436782406,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 99079.0,
        MAXELEVSMO: 99269.0,
        MINELEVSMO: 99079.0,
        SLOPE: 0.00423162,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.44900000095367398,
        QA_MA: 85.369,
        VA_MA: 1.22944,
        QC_MA: 87.0,
        VC_MA: 1.23085,
        QE_MA: 87.872,
        VE_MA: 1.23159,
        QA_01: 87.238,
        VA_01: 1.24009,
        QC_01: 70.37,
        VC_01: 1.13258,
        QE_01: 73.254,
        VE_01: 1.14657,
        QA_02: 99.756,
        VA_02: 1.30884,
        QC_02: 85.73,
        VC_02: 1.22367,
        QE_02: 88.154,
        VE_02: 1.23317,
        QA_03: 139.425,
        VA_03: 1.50349,
        QC_03: 120.618,
        VC_03: 1.4056,
        QE_03: 123.484,
        VE_03: 1.4144,
        QA_04: 118.806,
        VA_04: 1.40613,
        QC_04: 111.536,
        VC_04: 1.36093,
        QE_04: 112.975,
        VE_04: 1.36337,
        QA_05: 98.632,
        VA_05: 1.30284,
        QC_05: 94.332,
        VC_05: 1.27134,
        QE_05: 95.272,
        VE_05: 1.27209,
        QA_06: 76.402,
        VA_06: 1.17673,
        QC_06: 96.062,
        VC_06: 1.28068,
        QE_06: 97.061,
        VE_06: 1.28166,
        QA_07: 47.568,
        VA_07: 0.98356,
        QC_07: 72.344,
        VC_07: 1.14477,
        QE_07: 71.547,
        VE_07: 1.13614,
        QA_08: 46.404,
        VA_08: 0.97473,
        QC_08: 103.949,
        VC_08: 1.32229,
        QE_08: 101.522,
        VE_08: 1.30516,
        QA_09: 60.481,
        VA_09: 1.0754,
        QC_09: 146.336,
        VC_09: 1.52419,
        QE_09: 141.621,
        VE_09: 1.49788,
        QA_10: 66.881,
        VA_10: 1.11748,
        QC_10: 157.935,
        VC_10: 1.57445,
        QE_10: 152.885,
        VE_10: 1.54722,
        QA_11: 90.405,
        VA_11: 1.2579,
        QC_11: 118.653,
        VC_11: 1.39608,
        QE_11: 117.512,
        VE_11: 1.38566,
        QA_12: 93.083,
        VA_12: 1.27273,
        QC_12: 81.76,
        VC_12: 1.20092,
        QE_12: 82.055,
        VE_12: 1.19862,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.937338183256827, 35.994710410795676, 0.0],
          [-81.936153516591958, 35.993337077464503, 0.0],
          [-81.937025116590632, 35.991229877467788, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287004.0,
        COMID: 9736170,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.001,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 1.4866068601000001e-5,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059852.0,
        ToNode: 250059856.0,
        Hydroseq: 250007056.0,
        LevelPathI: 250003258.0,
        Pathlength: 434.594,
        TerminalPa: 250002604.0,
        ArbolateSu: 5932.469,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007079.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007031.0,
        FromMeas: 86.04534,
        ToMeas: 86.09997,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 7881.4296,
        DivDASqKM: 7881.4296,
        Tidal: 0,
        TOTMA: 1.9948772e-5,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 15153.0,
        MINELEVSMO: 15153.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.0010000000474970001,
        QA_MA: 4092.737,
        VA_MA: 1.8841,
        QC_MA: 4092.737,
        VC_MA: 1.8841,
        QE_MA: 4342.782,
        VE_MA: 1.90351,
        QA_01: 7276.386,
        VA_01: 2.53966,
        QC_01: 7276.386,
        VC_01: 2.53966,
        QE_01: 7435.891,
        VE_01: 2.51624,
        QA_02: 7700.785,
        VA_02: 2.61584,
        QC_02: 7700.785,
        VC_02: 2.61584,
        QE_02: 7713.398,
        VE_02: 2.56475,
        QA_03: 8493.999,
        VA_03: 2.75317,
        QC_03: 8493.999,
        VC_03: 2.75317,
        QE_03: 8713.693,
        VE_03: 2.73322,
        QA_04: 6029.033,
        VA_04: 2.30287,
        QC_04: 6029.033,
        VC_04: 2.30287,
        QE_04: 6194.497,
        VE_04: 2.28809,
        QA_05: 4244.913,
        VA_05: 1.91997,
        QC_05: 4244.913,
        VC_05: 1.91997,
        QE_05: 4564.306,
        VE_05: 1.95308,
        QA_06: 2486.226,
        VA_06: 1.45844,
        QC_06: 2486.226,
        VC_06: 1.45844,
        QE_06: 2835.334,
        VE_06: 1.52866,
        QA_07: 1513.056,
        VA_07: 1.13345,
        QC_07: 1513.056,
        VC_07: 1.13345,
        QE_07: 1769.653,
        VE_07: 1.20255,
        QA_08: 1071.062,
        VA_08: 0.95324,
        QC_08: 1071.062,
        VC_08: 0.95324,
        QE_08: 1482.271,
        VE_08: 1.09971,
        QA_09: 990.199,
        VA_09: 0.91674,
        QC_09: 990.199,
        VC_09: 0.91674,
        QE_09: 1111.396,
        VE_09: 0.95206,
        QA_10: 1115.982,
        VA_10: 0.97297,
        QC_10: 1115.982,
        VC_10: 0.97297,
        QE_10: 1392.026,
        VE_10: 1.06553,
        QA_11: 2984.572,
        VA_11: 1.60145,
        QC_11: 2984.572,
        VC_11: 1.60145,
        QE_11: 3147.164,
        VE_11: 1.61265,
        QA_12: 5234.574,
        VA_12: 2.13997,
        QC_12: 5234.574,
        VC_12: 2.13997,
        QE_12: 5235.197,
        VE_12: 2.09677,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.989554584727898, 34.989484612356023, 0.0],
          [-80.98953998472804, 34.989481812356132, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283914.0,
        COMID: 9751374,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1012897",
        GNIS_NAME: "Linville River",
        LENGTHKM: 0.605,
        REACHCODE: "03050101000331",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0055713482251389997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060758.0,
        ToNode: 250060763.0,
        Hydroseq: 250029953.0,
        LevelPathI: 250019506.0,
        Pathlength: 712.754,
        TerminalPa: 250002604.0,
        ArbolateSu: 71.203,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250031117.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028909.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.126,
        TotDASqKM: 87.7473,
        DivDASqKM: 87.7473,
        Tidal: 0,
        TOTMA: 0.0248652212976,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 99085.0,
        MAXELEVSMO: 99290.0,
        MINELEVSMO: 99269.0,
        SLOPE: 0.0003471,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.605000019073486,
        QA_MA: 82.656,
        VA_MA: 0.92242,
        QC_MA: 84.341,
        VC_MA: 0.92343,
        QE_MA: 85.183,
        VE_MA: 0.92392,
        QA_01: 83.811,
        VA_01: 0.92696,
        QC_01: 67.677,
        VC_01: 0.85558,
        QE_01: 70.461,
        VE_01: 0.86493,
        QA_02: 95.906,
        VA_02: 0.97287,
        QC_02: 82.509,
        VC_02: 0.9163,
        QE_02: 84.849,
        VE_02: 0.92264,
        QA_03: 135.101,
        VA_03: 1.10547,
        QC_03: 117.0,
        VC_03: 1.04018,
        QE_03: 119.766,
        VE_03: 1.04601,
        QA_04: 115.1,
        VA_04: 1.04046,
        QC_04: 108.134,
        VC_04: 1.01019,
        QE_04: 109.523,
        VE_04: 1.01181,
        QA_05: 95.683,
        VA_05: 0.97204,
        QC_05: 91.616,
        VC_05: 0.95105,
        QE_05: 92.523,
        VE_05: 0.95154,
        QA_06: 74.338,
        VA_06: 0.8888,
        QC_06: 93.501,
        VC_06: 0.95804,
        QE_06: 94.465,
        VE_06: 0.95867,
        QA_07: 46.423,
        VA_07: 0.76041,
        QC_07: 70.645,
        VC_07: 0.8682,
        QE_07: 69.875,
        VE_07: 0.86246,
        QA_08: 45.227,
        VA_08: 0.75418,
        QC_08: 101.259,
        VC_08: 0.98613,
        QE_08: 98.916,
        VE_08: 0.97477,
        QA_09: 58.81,
        VA_09: 0.82089,
        QC_09: 142.76,
        VC_09: 1.12173,
        QE_09: 138.208,
        VE_09: 1.10429,
        QA_10: 64.919,
        VA_10: 0.84851,
        QC_10: 154.222,
        VC_10: 1.15578,
        QE_10: 149.347,
        VE_10: 1.13773,
        QA_11: 87.336,
        VA_11: 0.94065,
        QC_11: 115.569,
        VC_11: 1.03541,
        QE_11: 114.467,
        VE_11: 1.02849,
        QA_12: 89.841,
        VA_12: 0.95021,
        QC_12: 79.165,
        VC_12: 0.90309,
        QE_12: 79.45,
        VE_12: 0.90157,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.937906183255961, 35.999724877454639, 0.0],
          [-81.937338183256827, 35.994710410795676, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285989.0,
        COMID: 9751040,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.739,
        REACHCODE: "03050101002664",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0068410929551979997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060683.0,
        ToNode: 250060685.0,
        Hydroseq: 250008917.0,
        LevelPathI: 250003258.0,
        Pathlength: 543.647,
        TerminalPa: 250002604.0,
        ArbolateSu: 3634.315,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008957.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008881.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9152,
        TotDASqKM: 4128.9948,
        DivDASqKM: 4128.9948,
        Tidal: 0,
        TOTMA: 0.92161674067,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23063.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.73900002241134599,
        QA_MA: 2411.72,
        VA_MA: -9998.0,
        QC_MA: 2411.72,
        VC_MA: -9998.0,
        QE_MA: 2605.808,
        VE_MA: -9998.0,
        QA_01: 3963.559,
        VA_01: -9998.0,
        QC_01: 3963.559,
        VC_01: -9998.0,
        QE_01: 4148.088,
        VE_01: -9998.0,
        QA_02: 4186.296,
        VA_02: -9998.0,
        QC_02: 4186.296,
        VC_02: -9998.0,
        QE_02: 4234.267,
        VE_02: -9998.0,
        QA_03: 4711.927,
        VA_03: -9998.0,
        QC_03: 4711.927,
        VC_03: -9998.0,
        QE_03: 4947.729,
        VE_03: -9998.0,
        QA_04: 3594.636,
        VA_04: -9998.0,
        QC_04: 3594.636,
        VC_04: -9998.0,
        QE_04: 3777.078,
        VE_04: -9998.0,
        QA_05: 2700.209,
        VA_05: -9998.0,
        QC_05: 2700.209,
        VC_05: -9998.0,
        QE_05: 2923.31,
        VE_05: -9998.0,
        QA_06: 1575.674,
        VA_06: -9998.0,
        QC_06: 1575.674,
        VC_06: -9998.0,
        QE_06: 1853.374,
        VE_06: -9998.0,
        QA_07: 934.518,
        VA_07: -9998.0,
        QC_07: 934.518,
        VC_07: -9998.0,
        QE_07: 1100.311,
        VE_07: -9998.0,
        QA_08: 654.104,
        VA_08: -9998.0,
        QC_08: 654.104,
        VC_08: -9998.0,
        QE_08: 974.677,
        VE_08: -9998.0,
        QA_09: 628.84,
        VA_09: -9998.0,
        QC_09: 1156.592,
        VC_09: -9998.0,
        QE_09: 1208.275,
        VE_09: -9998.0,
        QA_10: 780.61,
        VA_10: -9998.0,
        QC_10: 780.61,
        VC_10: -9998.0,
        QE_10: 742.65,
        VE_10: -9998.0,
        QA_11: 2146.9,
        VA_11: -9998.0,
        QC_11: 2146.9,
        VC_11: -9998.0,
        QE_11: 2096.874,
        VE_11: -9998.0,
        QA_12: 3079.026,
        VA_12: -9998.0,
        QC_12: 3079.026,
        VC_12: -9998.0,
        QE_12: 3080.201,
        VE_12: -9998.0,
        LakeFract: 0.01220690261,
        SurfArea: 1596849.21373,
        RAreaHLoad: 0.25001859226000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.96763298476202, 35.672497811295841, 0.0],
          [-80.966133384764362, 35.669662611300339, 0.0],
          [-80.967121918096154, 35.666397077971965, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286999.0,
        COMID: 9736178,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.724,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0077951605431289999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059856.0,
        ToNode: 250059857.0,
        Hydroseq: 250007031.0,
        LevelPathI: 250003258.0,
        Pathlength: 433.87,
        TerminalPa: 250002604.0,
        ArbolateSu: 5933.193,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007056.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007010.0,
        FromMeas: 56.9588,
        ToMeas: 86.04534,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.017,
        TotDASqKM: 7882.4466,
        DivDASqKM: 7882.4466,
        Tidal: 0,
        TOTMA: 0.0220194973368,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14995.0,
        MAXELEVSMO: 15153.0,
        MINELEVSMO: 15153.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.72399997711181596,
        QA_MA: 4093.171,
        VA_MA: 1.24404,
        QC_MA: 4093.171,
        VC_MA: 1.24404,
        QE_MA: 4343.216,
        VE_MA: 1.24854,
        QA_01: 7277.302,
        VA_01: 1.57842,
        QC_01: 7277.302,
        VC_01: 1.57842,
        QE_01: 7436.804,
        VE_01: 1.5593,
        QA_02: 7701.743,
        VA_02: 1.61723,
        QC_02: 7701.743,
        VC_02: 1.61723,
        QE_02: 7714.351,
        VE_02: 1.58387,
        QA_03: 8495.038,
        VA_03: 1.68717,
        QC_03: 8495.038,
        VC_03: 1.68717,
        QE_03: 8714.727,
        VE_03: 1.66919,
        QA_04: 6029.662,
        VA_04: 1.45771,
        QC_04: 6029.662,
        VC_04: 1.45771,
        QE_04: 6195.124,
        VE_04: 1.44366,
        QA_05: 4245.255,
        VA_05: 1.26234,
        QC_05: 4245.255,
        VC_05: 1.26234,
        QE_05: 4564.648,
        VE_05: 1.27369,
        QA_06: 2486.445,
        VA_06: 1.02645,
        QC_06: 2486.445,
        VC_06: 1.02645,
        QE_06: 2835.556,
        VE_06: 1.05806,
        QA_07: 1513.206,
        VA_07: 0.86,
        QC_07: 1513.206,
        VC_07: 0.86,
        QE_07: 1769.807,
        VE_07: 0.89206,
        QA_08: 1071.171,
        VA_08: 0.76755,
        QC_08: 1071.171,
        VC_08: 0.76755,
        QE_08: 1482.385,
        VE_08: 0.83964,
        QA_09: 990.295,
        VA_09: 0.7488,
        QC_09: 990.295,
        VC_09: 0.7488,
        QE_09: 1111.495,
        VE_09: 0.76432,
        QA_10: 1116.05,
        VA_10: 0.77766,
        QC_10: 1116.05,
        VC_10: 0.77766,
        QE_10: 1392.1,
        VE_10: 0.8222,
        QA_11: 2984.727,
        VA_11: 1.09958,
        QC_11: 2984.727,
        VC_11: 1.09958,
        QE_11: 3147.321,
        VE_11: 1.10076,
        QA_12: 5235.107,
        VA_12: 1.37463,
        QC_12: 5235.107,
        VC_12: 1.37463,
        QE_12: 5235.728,
        VE_12: 1.34663,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.98953998472804, 34.989481812356132, 0.0],
          [-80.9821675847395, 34.987378412359192, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283925.0,
        COMID: 9754630,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 0.264,
        REACHCODE: "03050101000342",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002777651690685,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061661.0,
        ToNode: 250061182.0,
        Hydroseq: 250020550.0,
        LevelPathI: 250019848.0,
        Pathlength: 681.635,
        TerminalPa: 250002604.0,
        ArbolateSu: 215.077,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250020918.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020194.0,
        FromMeas: 88.94015,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2718,
        TotDASqKM: 216.9774,
        DivDASqKM: 216.9774,
        Tidal: 0,
        TOTMA: 0.0061505545337599997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36841.0,
        MAXELEVSMO: 37218.0,
        MINELEVSMO: 36841.0,
        SLOPE: 0.0142803,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.26399999856948902,
        QA_MA: 136.695,
        VA_MA: 1.62983,
        QC_MA: 136.785,
        VC_MA: 1.6299,
        QE_MA: 136.785,
        VE_MA: 1.6299,
        QA_01: 216.347,
        VA_01: 1.99466,
        QC_01: 170.411,
        VC_01: 1.7935,
        QE_01: 170.411,
        VE_01: 1.7935,
        QA_02: 227.58,
        VA_02: 2.0406,
        QC_02: 191.233,
        VC_02: 1.88725,
        QE_02: 191.233,
        VE_02: 1.88725,
        QA_03: 266.679,
        VA_03: 2.19274,
        QC_03: 225.821,
        VC_03: 2.03296,
        QE_03: 225.821,
        VE_03: 2.03296,
        QA_04: 197.588,
        VA_04: 1.91537,
        QC_04: 183.364,
        VC_04: 1.85241,
        QE_04: 183.364,
        VE_04: 1.85241,
        QA_05: 163.182,
        VA_05: 1.76014,
        QC_05: 153.156,
        VC_05: 1.71165,
        QE_05: 153.156,
        VE_05: 1.71165,
        QA_06: 88.821,
        VA_06: 1.35944,
        QC_06: 111.445,
        VC_06: 1.49367,
        QE_06: 111.445,
        VE_06: 1.49367,
        QA_07: 52.149,
        VA_07: 1.10056,
        QC_07: 79.138,
        VC_07: 1.29666,
        QE_07: 79.138,
        VE_07: 1.29666,
        QA_08: 36.426,
        VA_08: 0.96313,
        QC_08: 81.203,
        VC_08: 1.31027,
        QE_08: 81.203,
        VE_08: 1.31027,
        QA_09: 39.279,
        VA_09: 0.98988,
        QC_09: 99.964,
        VC_09: 1.42718,
        QE_09: 99.964,
        VE_09: 1.42718,
        QA_10: 53.614,
        VA_10: 1.1123,
        QC_10: 132.365,
        VC_10: 1.60705,
        QE_10: 132.365,
        VE_10: 1.60705,
        QA_11: 130.371,
        VA_11: 1.597,
        QC_11: 156.866,
        VC_11: 1.7296,
        QE_11: 156.866,
        VE_11: 1.7296,
        QA_12: 168.893,
        VA_12: 1.7869,
        QC_12: 140.59,
        VC_12: 1.64929,
        QE_12: 140.59,
        VE_12: 1.64929,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.002261916489374, 35.744120077851392, 0.0],
          [-81.999830983159882, 35.74477867785032, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287000.0,
        COMID: 9736188,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.762,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.008220512103851,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059860.0,
        ToNode: 250059859.0,
        Hydroseq: 250006966.0,
        LevelPathI: 250003258.0,
        Pathlength: 432.452,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.184,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006988.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250006945.0,
        DnDrainCou: 2,
        DnHydroseq: 250006944.0,
        FromMeas: 0.0,
        ToMeas: 30.59982,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9404,
        TotDASqKM: 7886.0718,
        DivDASqKM: 7886.0718,
        Tidal: 0,
        TOTMA: 0.0231876614027,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14982.0,
        MAXELEVSMO: 15091.0,
        MINELEVSMO: 15091.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.76200002431869496,
        QA_MA: 4094.717,
        VA_MA: 1.24413,
        QC_MA: 4094.717,
        VC_MA: 1.24413,
        QE_MA: 4301.24,
        VE_MA: 1.24787,
        QA_01: 7280.567,
        VA_01: 1.57859,
        QC_01: 7280.567,
        VC_01: 1.57859,
        QE_01: 5898.007,
        VE_01: 1.41933,
        QA_02: 7705.157,
        VA_02: 1.6174,
        QC_02: 7705.157,
        VC_02: 1.6174,
        QE_02: 6081.575,
        VE_02: 1.43756,
        QA_03: 8498.743,
        VA_03: 1.68734,
        QC_03: 8498.743,
        VC_03: 1.68734,
        QE_03: 6189.314,
        VE_03: 1.44814,
        QA_04: 6031.887,
        VA_04: 1.45782,
        QC_04: 6031.887,
        VC_04: 1.45782,
        QE_04: 5354.299,
        VE_04: 1.36372,
        QA_05: 4246.474,
        VA_05: 1.26239,
        QC_05: 4246.474,
        VC_05: 1.26239,
        QE_05: 4438.856,
        VE_05: 1.26371,
        QA_06: 2487.225,
        VA_06: 1.0265,
        QC_06: 2487.225,
        VC_06: 1.0265,
        QE_06: 4103.893,
        VE_06: 1.22472,
        QA_07: 1513.742,
        VA_07: 0.86005,
        QC_07: 1513.742,
        VC_07: 0.86005,
        QE_07: 3083.279,
        VE_07: 1.09564,
        QA_08: 1071.561,
        VA_08: 0.76759,
        QC_08: 1071.561,
        VC_08: 0.76759,
        QE_08: 3013.609,
        VE_08: 1.08614,
        QA_09: 990.636,
        VA_09: 0.74883,
        QC_09: 990.636,
        VC_09: 0.74883,
        QE_09: 2512.183,
        VE_09: 1.0145,
        QA_10: 1116.294,
        VA_10: 0.77767,
        QC_10: 1116.294,
        VC_10: 0.77767,
        QE_10: 3311.417,
        VE_10: 1.12605,
        QA_11: 2985.273,
        VA_11: 1.09958,
        QC_11: 2985.273,
        VC_11: 1.09958,
        QE_11: 3569.885,
        VE_11: 1.15935,
        QA_12: 5237.011,
        VA_12: 1.37473,
        QC_12: 5237.011,
        VC_12: 1.37473,
        QE_12: 4404.969,
        VE_12: 1.25983,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.975582584749645, 34.985507612362142, 0.0],
          [-80.96775511809517, 34.983889879031381, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 283926.0,
        COMID: 9752964,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1024192",
        GNIS_NAME: "North Fork Catawba River",
        LENGTHKM: 2.126,
        REACHCODE: "03050101000342",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.022055004076536999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250061182.0,
        ToNode: 250061581.0,
        Hydroseq: 250020194.0,
        LevelPathI: 250019848.0,
        Pathlength: 679.509,
        TerminalPa: 250002604.0,
        ArbolateSu: 217.203,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019848.0,
        UpHydroseq: 250020550.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019848.0,
        FromMeas: 0.0,
        ToMeas: 88.94015,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.466,
        TotDASqKM: 219.4434,
        DivDASqKM: 219.4434,
        Tidal: 0,
        TOTMA: 0.064235016153400007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 36470.0,
        MAXELEVSMO: 36841.0,
        MINELEVSMO: 36470.0,
        SLOPE: 0.00174506,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.125999927520752,
        QA_MA: 138.099,
        VA_MA: 1.25677,
        QC_MA: 138.136,
        VC_MA: 1.25679,
        QE_MA: 138.136,
        VE_MA: 1.25679,
        QA_01: 218.715,
        VA_01: 1.51903,
        QC_01: 172.226,
        VC_01: 1.37463,
        QE_01: 172.226,
        VE_01: 1.37463,
        QA_02: 230.101,
        VA_02: 1.5521,
        QC_02: 193.293,
        VC_02: 1.44201,
        QE_02: 193.293,
        VE_02: 1.44201,
        QA_03: 269.579,
        VA_03: 1.66119,
        QC_03: 228.195,
        VC_03: 1.54647,
        QE_03: 228.195,
        VE_03: 1.54647,
        QA_04: 199.733,
        VA_04: 1.46205,
        QC_04: 185.31,
        VC_04: 1.4169,
        QE_04: 185.31,
        VE_04: 1.4169,
        QA_05: 164.821,
        VA_05: 1.35016,
        QC_05: 154.637,
        VC_05: 1.31535,
        QE_05: 154.637,
        VE_05: 1.31535,
        QA_06: 89.715,
        VA_06: 1.06263,
        QC_06: 112.551,
        VC_06: 1.15905,
        QE_06: 112.551,
        VE_06: 1.15905,
        QA_07: 52.68,
        VA_07: 0.87689,
        QC_07: 79.924,
        VC_07: 1.01766,
        QE_07: 79.924,
        VE_07: 1.01766,
        QA_08: 36.783,
        VA_08: 0.77817,
        QC_08: 82.015,
        VC_08: 1.02745,
        QE_08: 82.015,
        VE_08: 1.02745,
        QA_09: 39.544,
        VA_09: 0.79658,
        QC_09: 100.56,
        VC_09: 1.10965,
        QE_09: 100.56,
        VE_09: 1.10965,
        QA_10: 53.813,
        VA_10: 0.88335,
        QC_10: 132.757,
        VC_10: 1.237,
        QE_10: 132.757,
        VE_10: 1.237,
        QA_11: 131.58,
        VA_11: 1.23273,
        QC_11: 157.974,
        VC_11: 1.32683,
        QE_11: 157.974,
        VE_11: 1.32683,
        QA_12: 170.71,
        VA_12: 1.36977,
        QC_12: 141.965,
        VC_12: 1.27066,
        QE_12: 141.965,
        VE_12: 1.27066,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.999830983159882, 35.74477867785032, 0.0],
          [-81.995951183165744, 35.742171877854332, 0.0],
          [-81.992917983170585, 35.742449611187226, 0.0],
          [-81.98147498318832, 35.734809877865871, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287001.0,
        COMID: 9736184,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.036,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: -9998,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00035898620790599997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059858.0,
        ToNode: 250059860.0,
        Hydroseq: 250006988.0,
        LevelPathI: 250003258.0,
        Pathlength: 433.214,
        TerminalPa: 250002604.0,
        ArbolateSu: 5935.422,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007010.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006966.0,
        FromMeas: 30.59982,
        ToMeas: 32.04902,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 7884.1314,
        DivDASqKM: 7884.1314,
        Tidal: 0,
        TOTMA: 0.0010948570438599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15061.0,
        MAXELEVSMO: 15091.0,
        MINELEVSMO: 15091.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.035999998450279,
        QA_MA: 4093.89,
        VA_MA: 1.24408,
        QC_MA: 4093.89,
        VC_MA: 1.24408,
        QE_MA: 4343.937,
        VE_MA: 1.24858,
        QA_01: 7278.82,
        VA_01: 1.5785,
        QC_01: 7278.82,
        VC_01: 1.5785,
        QE_01: 7438.316,
        VE_01: 1.55938,
        QA_02: 7703.331,
        VA_02: 1.61731,
        QC_02: 7703.331,
        VC_02: 1.61731,
        QE_02: 7715.933,
        VE_02: 1.58396,
        QA_03: 8496.76,
        VA_03: 1.68725,
        QC_03: 8496.76,
        VC_03: 1.68725,
        QE_03: 8716.439,
        VE_03: 1.66927,
        QA_04: 6030.704,
        VA_04: 1.45777,
        QC_04: 6030.704,
        VC_04: 1.45777,
        QE_04: 6196.163,
        VE_04: 1.44371,
        QA_05: 4245.822,
        VA_05: 1.26236,
        QC_05: 4245.822,
        VC_05: 1.26236,
        QE_05: 4565.215,
        VE_05: 1.27372,
        QA_06: 2486.807,
        VA_06: 1.02647,
        QC_06: 2486.807,
        VC_06: 1.02647,
        QE_06: 2835.925,
        VE_06: 1.05808,
        QA_07: 1513.455,
        VA_07: 0.86002,
        QC_07: 1513.455,
        VC_07: 0.86002,
        QE_07: 1770.063,
        VE_07: 0.89208,
        QA_08: 1071.353,
        VA_08: 0.76757,
        QC_08: 1071.353,
        VC_08: 0.76757,
        QE_08: 1482.574,
        VE_08: 0.83965,
        QA_09: 990.453,
        VA_09: 0.74882,
        QC_09: 990.453,
        VC_09: 0.74882,
        QE_09: 1111.66,
        VE_09: 0.76433,
        QA_10: 1116.164,
        VA_10: 0.77767,
        QC_10: 1116.164,
        VC_10: 0.77767,
        QE_10: 1392.222,
        VE_10: 0.82221,
        QA_11: 2984.981,
        VA_11: 1.09958,
        QC_11: 2984.981,
        VC_11: 1.09958,
        QE_11: 3147.578,
        VE_11: 1.10076,
        QA_12: 5235.993,
        VA_12: 1.37468,
        QC_12: 5235.993,
        VC_12: 1.37468,
        QE_12: 5236.611,
        VE_12: 1.34667,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.975842518082629, 34.98575521236188, 0.0],
          [-80.975582584749645, 34.985507612362142, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287002.0,
        COMID: 9736182,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.62,
        REACHCODE: "03050103000276",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0066862686880870001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059857.0,
        ToNode: 250059858.0,
        Hydroseq: 250007010.0,
        LevelPathI: 250003258.0,
        Pathlength: 433.25,
        TerminalPa: 250002604.0,
        ArbolateSu: 5935.386,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007031.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006988.0,
        FromMeas: 32.04902,
        ToMeas: 56.9588,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4176,
        TotDASqKM: 7884.1296,
        DivDASqKM: 7884.1296,
        Tidal: 0,
        TOTMA: 0.0104002584247,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15008.0,
        MAXELEVSMO: 15153.0,
        MINELEVSMO: 15091.0,
        SLOPE: 0.001,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.62000000476837203,
        QA_MA: 4093.89,
        VA_MA: 2.25433,
        QC_MA: 4093.89,
        VC_MA: 2.25433,
        QE_MA: 4343.936,
        VE_MA: 2.2637,
        QA_01: 7278.818,
        VA_01: 2.94982,
        QC_01: 7278.818,
        VC_01: 2.94982,
        QE_01: 7438.314,
        VE_01: 2.91006,
        QA_02: 7703.329,
        VA_02: 3.03054,
        QC_02: 7703.329,
        VC_02: 3.03054,
        QE_02: 7715.931,
        VE_02: 2.96118,
        QA_03: 8496.758,
        VA_03: 3.17599,
        QC_03: 8496.758,
        VC_03: 3.17599,
        QE_03: 8716.438,
        VE_03: 3.1386,
        QA_04: 6030.703,
        VA_04: 2.69874,
        QC_04: 6030.703,
        VC_04: 2.69874,
        QE_04: 6196.162,
        VE_04: 2.6695,
        QA_05: 4245.821,
        VA_05: 2.29235,
        QC_05: 4245.821,
        VC_05: 2.29235,
        QE_05: 4565.215,
        VE_05: 2.31597,
        QA_06: 2486.807,
        VA_06: 1.80178,
        QC_06: 2486.807,
        VC_06: 1.80178,
        QE_06: 2835.925,
        VE_06: 1.86751,
        QA_07: 1513.455,
        VA_07: 1.45562,
        QC_07: 1513.455,
        VC_07: 1.45562,
        QE_07: 1770.062,
        VE_07: 1.52229,
        QA_08: 1071.352,
        VA_08: 1.26334,
        QC_08: 1071.352,
        VC_08: 1.26334,
        QE_08: 1482.574,
        VE_08: 1.41326,
        QA_09: 990.453,
        VA_09: 1.22435,
        QC_09: 990.453,
        VC_09: 1.22435,
        QE_09: 1111.659,
        VE_09: 1.25662,
        QA_10: 1116.164,
        VA_10: 1.28435,
        QC_10: 1116.164,
        VC_10: 1.28435,
        QE_10: 1392.222,
        VE_10: 1.37698,
        QA_11: 2984.981,
        VA_11: 1.95382,
        QC_11: 2984.981,
        VC_11: 1.95382,
        QE_11: 3147.577,
        VE_11: 1.95627,
        QA_12: 5235.993,
        VA_12: 2.52594,
        QC_12: 5235.993,
        VC_12: 2.52594,
        QE_12: 5236.61,
        VE_12: 2.4677,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.9821675847395, 34.987378412359192, 0.0],
          [-80.975842518082629, 34.98575521236188, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287011.0,
        COMID: 9735372,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.221,
        REACHCODE: "03050103000285",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0020310777593140002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059701.0,
        ToNode: 250060056.0,
        Hydroseq: 250016110.0,
        LevelPathI: 250015946.0,
        Pathlength: 375.742,
        TerminalPa: 250002604.0,
        ArbolateSu: 368.809,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016310.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015946.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0549,
        TotDASqKM: 748.7235,
        DivDASqKM: 748.7235,
        Tidal: 0,
        TOTMA: 0.0099722689121200005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10811.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.221000000834465,
        QA_MA: 307.072,
        VA_MA: 0.84286,
        QC_MA: 297.774,
        VC_MA: 0.84153,
        QE_MA: 297.774,
        VE_MA: 0.84153,
        QA_01: 662.054,
        VA_01: 1.1121,
        QC_01: 506.418,
        VC_01: 1.01521,
        QE_01: 506.418,
        VE_01: 1.01521,
        QA_02: 695.205,
        VA_02: 1.13322,
        QC_02: 566.658,
        VC_02: 1.05868,
        QE_02: 566.658,
        VE_02: 1.05868,
        QA_03: 747.916,
        VA_03: 1.16586,
        QC_03: 612.131,
        VC_03: 1.09007,
        QE_03: 612.131,
        VE_03: 1.09007,
        QA_04: 440.549,
        VA_04: 0.95577,
        QC_04: 401.452,
        VC_04: 0.93319,
        QE_04: 401.452,
        VE_04: 0.93319,
        QA_05: 244.487,
        VA_05: 0.78194,
        QC_05: 226.021,
        VC_05: 0.76893,
        QE_05: 226.021,
        VE_05: 0.76893,
        QA_06: 155.442,
        VA_06: 0.68072,
        QC_06: 193.543,
        VC_06: 0.73251,
        QE_06: 193.543,
        VE_06: 0.73251,
        QA_07: 107.88,
        VA_07: 0.61508,
        QC_07: 160.889,
        VC_07: 0.69287,
        QE_07: 160.889,
        VE_07: 0.69287,
        QA_08: 80.534,
        VA_08: 0.57098,
        QC_08: 182.404,
        VC_08: 0.71937,
        QE_08: 182.404,
        VE_08: 0.71937,
        QA_09: 67.713,
        VA_09: 0.54788,
        QC_09: 161.681,
        VC_09: 0.69388,
        QE_09: 161.681,
        VE_09: 0.69388,
        QA_10: 50.407,
        VA_10: 0.51314,
        QC_10: 126.001,
        VC_10: 0.64607,
        QE_10: 126.001,
        VE_10: 0.64607,
        QA_11: 73.766,
        VA_11: 0.55902,
        QC_11: 101.604,
        VC_11: 0.60961,
        QE_11: 101.604,
        VE_11: 0.60961,
        QA_12: 361.244,
        VA_12: 0.89102,
        QC_12: 280.783,
        VC_12: 0.82516,
        QE_12: 280.783,
        VE_12: 0.82516,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.896157518206337, 34.599071879628752, 0.0],
          [-80.895603118207191, 34.597216612964928, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285986.0,
        COMID: 9751034,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.331,
        REACHCODE: "03050101002661",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.003295439678959,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060680.0,
        ToNode: 250060683.0,
        Hydroseq: 250008957.0,
        LevelPathI: 250003258.0,
        Pathlength: 544.386,
        TerminalPa: 250002604.0,
        ArbolateSu: 3633.576,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008994.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2331,
        TotDASqKM: 4127.0796,
        DivDASqKM: 4127.0796,
        Tidal: 0,
        TOTMA: 0.41279450745000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23065.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.33100000023841902,
        QA_MA: 2410.906,
        VA_MA: -9998.0,
        QC_MA: 2410.906,
        VC_MA: -9998.0,
        QE_MA: 2604.994,
        VE_MA: -9998.0,
        QA_01: 3961.957,
        VA_01: -9998.0,
        QC_01: 3961.957,
        VC_01: -9998.0,
        QE_01: 4146.493,
        VE_01: -9998.0,
        QA_02: 4184.596,
        VA_02: -9998.0,
        QC_02: 4184.596,
        VC_02: -9998.0,
        QE_02: 4232.575,
        VE_02: -9998.0,
        QA_03: 4710.094,
        VA_03: -9998.0,
        QC_03: 4710.094,
        VC_03: -9998.0,
        QE_03: 4945.907,
        VE_03: -9998.0,
        QA_04: 3593.4,
        VA_04: -9998.0,
        QC_04: 3593.4,
        VC_04: -9998.0,
        QE_04: 3775.845,
        VE_04: -9998.0,
        QA_05: 2699.457,
        VA_05: -9998.0,
        QC_05: 2699.457,
        VC_05: -9998.0,
        QE_05: 2922.558,
        VE_05: -9998.0,
        QA_06: 1575.218,
        VA_06: -9998.0,
        QC_06: 1575.218,
        VC_06: -9998.0,
        QE_06: 1852.91,
        VE_06: -9998.0,
        QA_07: 934.235,
        VA_07: -9998.0,
        QC_07: 934.235,
        VC_07: -9998.0,
        QE_07: 1100.021,
        VE_07: -9998.0,
        QA_08: 653.898,
        VA_08: -9998.0,
        QC_08: 653.898,
        VC_08: -9998.0,
        QE_08: 974.462,
        VE_08: -9998.0,
        QA_09: 628.659,
        VA_09: -9998.0,
        QC_09: 1156.299,
        VC_09: -9998.0,
        QE_09: 1207.975,
        VE_09: -9998.0,
        QA_10: 780.482,
        VA_10: -9998.0,
        QC_10: 780.482,
        VC_10: -9998.0,
        QE_10: 742.511,
        VE_10: -9998.0,
        QA_11: 2146.571,
        VA_11: -9998.0,
        QC_11: 2146.571,
        VC_11: -9998.0,
        QE_11: 2096.543,
        VE_11: -9998.0,
        QA_12: 3077.976,
        VA_12: -9998.0,
        QC_12: 3077.976,
        VC_12: -9998.0,
        QE_12: 3079.156,
        VE_12: -9998.0,
        LakeFract: 0.00546579823,
        SurfArea: 715009.85015199997,
        RAreaHLoad: 0.11198397023,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.970051984758243, 35.674640877959177, 0.0],
          [-80.96763298476202, 35.672497811295841, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287012.0,
        COMID: 9735362,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.854,
        REACHCODE: "03050103000286",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0077920616710710003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059699.0,
        ToNode: 250059701.0,
        Hydroseq: 250016310.0,
        LevelPathI: 250015946.0,
        Pathlength: 375.963,
        TerminalPa: 250002604.0,
        ArbolateSu: 366.131,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016510.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016110.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7704,
        TotDASqKM: 745.9803,
        DivDASqKM: 745.9803,
        Tidal: 0,
        TOTMA: 0.038554156479399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10807.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.85399997234344505,
        QA_MA: 306.154,
        VA_MA: 0.84244,
        QC_MA: 296.918,
        VC_MA: 0.84112,
        QE_MA: 296.918,
        VE_MA: 0.84112,
        QA_01: 660.065,
        VA_01: 1.11146,
        QC_01: 504.936,
        VC_01: 1.01464,
        QE_01: 504.936,
        VE_01: 1.01464,
        QA_02: 693.001,
        VA_02: 1.13249,
        QC_02: 564.91,
        VC_02: 1.05801,
        QE_02: 564.91,
        VE_02: 1.05801,
        QA_03: 745.482,
        VA_03: 1.16506,
        QC_03: 610.204,
        VC_03: 1.08934,
        QE_03: 610.204,
        VE_03: 1.08934,
        QA_04: 439.279,
        VA_04: 0.9553,
        QC_04: 400.321,
        VC_04: 0.93272,
        QE_04: 400.321,
        VE_04: 0.93272,
        QA_05: 243.783,
        VA_05: 0.78159,
        QC_05: 225.395,
        VC_05: 0.76859,
        QE_05: 225.395,
        VE_05: 0.76859,
        QA_06: 154.962,
        VA_06: 0.6804,
        QC_06: 192.954,
        VC_06: 0.73214,
        QE_06: 192.954,
        VE_06: 0.73214,
        QA_07: 107.511,
        VA_07: 0.61476,
        QC_07: 160.352,
        VC_07: 0.69248,
        QE_07: 160.352,
        VE_07: 0.69248,
        QA_08: 80.239,
        VA_08: 0.57068,
        QC_08: 181.723,
        VC_08: 0.71886,
        QE_08: 181.723,
        VE_08: 0.71886,
        QA_09: 67.492,
        VA_09: 0.54766,
        QC_09: 161.214,
        VC_09: 0.69357,
        QE_09: 161.214,
        VE_09: 0.69357,
        QA_10: 50.223,
        VA_10: 0.51291,
        QC_10: 125.632,
        VC_10: 0.6458,
        QE_10: 125.632,
        VE_10: 0.6458,
        QA_11: 73.618,
        VA_11: 0.55895,
        QC_11: 101.449,
        VC_11: 0.60959,
        QE_11: 101.449,
        VE_11: 0.60959,
        QA_12: 360.525,
        VA_12: 0.89087,
        QC_12: 280.274,
        VC_12: 0.82505,
        QE_12: 280.274,
        VE_12: 0.82505,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.898375384869553, 34.606378479617433, 0.0],
          [-80.896157518206337, 34.599071879628752, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287013.0,
        COMID: 9735328,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 0.144,
        REACHCODE: "03050103000287",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001334493691551,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059686.0,
        ToNode: 250059699.0,
        Hydroseq: 250016510.0,
        LevelPathI: 250015946.0,
        Pathlength: 376.817,
        TerminalPa: 250002604.0,
        ArbolateSu: 365.115,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016716.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016310.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3906,
        TotDASqKM: 745.1784,
        DivDASqKM: 745.1784,
        Tidal: 0,
        TOTMA: 0.0065018625817799998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10846.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.143999993801117,
        QA_MA: 305.885,
        VA_MA: 0.84232,
        QC_MA: 296.668,
        VC_MA: 0.841,
        QE_MA: 296.668,
        VE_MA: 0.841,
        QA_01: 659.482,
        VA_01: 1.11127,
        QC_01: 504.502,
        VC_01: 1.01447,
        QE_01: 504.502,
        VE_01: 1.01447,
        QA_02: 692.357,
        VA_02: 1.13228,
        QC_02: 564.4,
        VC_02: 1.05781,
        QE_02: 564.4,
        VE_02: 1.05781,
        QA_03: 744.771,
        VA_03: 1.16483,
        QC_03: 609.641,
        VC_03: 1.08913,
        QE_03: 609.641,
        VE_03: 1.08913,
        QA_04: 438.906,
        VA_04: 0.95516,
        QC_04: 399.988,
        VC_04: 0.93258,
        QE_04: 399.988,
        VE_04: 0.93258,
        QA_05: 243.578,
        VA_05: 0.78149,
        QC_05: 225.212,
        VC_05: 0.76849,
        QE_05: 225.212,
        VE_05: 0.76849,
        QA_06: 154.822,
        VA_06: 0.68031,
        QC_06: 192.782,
        VC_06: 0.73203,
        QE_06: 192.782,
        VE_06: 0.73203,
        QA_07: 107.404,
        VA_07: 0.61467,
        QC_07: 160.195,
        VC_07: 0.69236,
        QE_07: 160.195,
        VE_07: 0.69236,
        QA_08: 80.153,
        VA_08: 0.57059,
        QC_08: 181.523,
        VC_08: 0.71871,
        QE_08: 181.523,
        VE_08: 0.71871,
        QA_09: 67.427,
        VA_09: 0.54759,
        QC_09: 161.078,
        VC_09: 0.69348,
        QE_09: 161.078,
        VE_09: 0.69348,
        QA_10: 50.169,
        VA_10: 0.51284,
        QC_10: 125.525,
        VC_10: 0.64572,
        QE_10: 125.525,
        VE_10: 0.64572,
        QA_11: 73.575,
        VA_11: 0.55893,
        QC_11: 101.404,
        VC_11: 0.60958,
        QE_11: 101.404,
        VE_11: 0.60958,
        QA_12: 360.315,
        VA_12: 0.89083,
        QC_12: 280.125,
        VC_12: 0.82501,
        QE_12: 280.125,
        VE_12: 0.82501,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.898763718202247, 34.607569479615449, 0.0],
          [-80.898375384869553, 34.606378479617433, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287014.0,
        COMID: 9735324,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222409",
        GNIS_NAME: "Fishing Creek",
        LENGTHKM: 4.102,
        REACHCODE: "03050103000288",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.040195221753970001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059685.0,
        ToNode: 250059686.0,
        Hydroseq: 250016716.0,
        LevelPathI: 250015946.0,
        Pathlength: 376.961,
        TerminalPa: 250002604.0,
        ArbolateSu: 364.971,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016928.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 250016509.0,
        DnDrainCou: 2,
        DnHydroseq: 250016510.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5837,
        TotDASqKM: 744.7878,
        DivDASqKM: 744.7878,
        Tidal: 0,
        TOTMA: 0.120079838117,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10844.0,
        MAXELEVSMO: 11190.0,
        MINELEVSMO: 10989.0,
        SLOPE: 0.00049,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.1020002365112296,
        QA_MA: 305.753,
        VA_MA: 1.29963,
        QC_MA: 296.545,
        VC_MA: 1.29717,
        QE_MA: 296.545,
        VE_MA: 1.29717,
        QA_01: 659.195,
        VA_01: 1.79892,
        QC_01: 504.288,
        VC_01: 1.61921,
        QE_01: 504.288,
        VE_01: 1.61921,
        QA_02: 692.042,
        VA_02: 1.83791,
        QC_02: 564.15,
        VC_02: 1.69966,
        QE_02: 564.15,
        VE_02: 1.69966,
        QA_03: 744.424,
        VA_03: 1.89833,
        QC_03: 609.367,
        VC_03: 1.7578,
        QE_03: 609.367,
        VE_03: 1.7578,
        QA_04: 438.722,
        VA_04: 1.50913,
        QC_04: 399.824,
        VC_04: 1.4672,
        QE_04: 399.824,
        VE_04: 1.4672,
        QA_05: 243.475,
        VA_05: 1.18671,
        QC_05: 225.121,
        VC_05: 1.16258,
        QE_05: 225.121,
        VE_05: 1.16258,
        QA_06: 154.754,
        VA_06: 0.99886,
        QC_06: 192.698,
        VC_06: 1.09488,
        QE_06: 192.698,
        VE_06: 1.09488,
        QA_07: 107.351,
        VA_07: 0.87698,
        QC_07: 160.119,
        VC_07: 1.02121,
        QE_07: 160.119,
        VE_07: 1.02121,
        QA_08: 80.111,
        VA_08: 0.79514,
        QC_08: 181.426,
        VC_08: 1.0701,
        QE_08: 181.426,
        VE_08: 1.0701,
        QA_09: 67.396,
        VA_09: 0.75246,
        QC_09: 161.011,
        VC_09: 1.02332,
        QE_09: 161.011,
        VE_09: 1.02332,
        QA_10: 50.142,
        VA_10: 0.68792,
        QC_10: 125.472,
        VC_10: 0.93464,
        QE_10: 125.472,
        VE_10: 0.93464,
        QA_11: 73.554,
        VA_11: 0.77356,
        QC_11: 101.382,
        VC_11: 0.86761,
        QE_11: 101.382,
        VE_11: 0.86761,
        QA_12: 360.207,
        VA_12: 1.38976,
        QC_12: 280.049,
        VC_12: 1.26758,
        QE_12: 280.049,
        VE_12: 1.26758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.91741498483988, 34.620826812928215, 0.0],
          [-80.912591318180773, 34.615376812936802, 0.0],
          [-80.914030318178504, 34.611459412942736, 0.0],
          [-80.9077413181883, 34.611507212942797, 0.0],
          [-80.905914718191184, 34.617326012933574, 0.0],
          [-80.897906184870237, 34.613433812939832, 0.0],
          [-80.898763718202247, 34.607569479615449, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284093.0,
        COMID: 9753138,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989140",
        GNIS_NAME: "Lyle Creek",
        LENGTHKM: 0.649,
        REACHCODE: "03050101000506",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0071345775029479997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061239.0,
        ToNode: 250061551.0,
        Hydroseq: 250025529.0,
        LevelPathI: 250024849.0,
        Pathlength: 557.559,
        TerminalPa: 250002604.0,
        ArbolateSu: 153.088,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024849.0,
        UpHydroseq: 250026270.0,
        DnLevelPat: 250024849.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024849.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6758,
        TotDASqKM: 189.3987,
        DivDASqKM: 189.3987,
        Tidal: 0,
        TOTMA: 0.024692175088399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23116.0,
        MAXELEVSMO: 23138.0,
        MINELEVSMO: 23116.0,
        SLOPE: 0.00033898,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.64899998903274503,
        QA_MA: 86.845,
        VA_MA: 0.99704,
        QC_MA: 88.446,
        VC_MA: 0.99806,
        QE_MA: 88.446,
        VE_MA: 0.99806,
        QA_01: 166.268,
        VA_01: 1.28064,
        QC_01: 131.871,
        VC_01: 1.16101,
        QE_01: 131.871,
        VE_01: 1.16101,
        QA_02: 175.479,
        VA_02: 1.30888,
        QC_02: 148.501,
        VC_02: 1.21652,
        QE_02: 148.501,
        VE_02: 1.21652,
        QA_03: 188.815,
        VA_03: 1.34856,
        QC_03: 161.72,
        VC_03: 1.25858,
        QE_03: 161.72,
        VE_03: 1.25858,
        QA_04: 131.854,
        VA_04: 1.16799,
        QC_04: 123.492,
        VC_04: 1.13181,
        QE_04: 123.492,
        VE_04: 1.13181,
        QA_05: 86.26,
        VA_05: 0.99457,
        QC_05: 82.914,
        VC_05: 0.97481,
        QE_05: 82.914,
        VE_05: 0.97481,
        QA_06: 50.712,
        VA_06: 0.82593,
        QC_06: 64.121,
        VC_06: 0.88979,
        QE_06: 64.121,
        VE_06: 0.88979,
        QA_07: 31.334,
        VA_07: 0.70917,
        QC_07: 48.133,
        VC_07: 0.80766,
        QE_07: 48.133,
        VE_07: 0.80766,
        QA_08: 21.066,
        VA_08: 0.63299,
        QC_08: 46.45,
        VC_08: 0.79831,
        QE_08: 46.45,
        VE_08: 0.79831,
        QA_09: 17.853,
        VA_09: 0.60568,
        QC_09: 49.831,
        VC_09: 0.81693,
        QE_09: 49.831,
        VE_09: 0.81693,
        QA_10: 12.938,
        VA_10: 0.55896,
        QC_10: 42.518,
        VC_10: 0.77583,
        QE_10: 42.518,
        VE_10: 0.77583,
        QA_11: 47.379,
        VA_11: 0.80758,
        QC_11: 72.491,
        VC_11: 0.92893,
        QE_11: 72.491,
        VE_11: 0.92893,
        QA_12: 112.649,
        VA_12: 1.09906,
        QC_12: 97.259,
        VC_12: 1.03374,
        QE_12: 97.259,
        VE_12: 1.03374,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.087623117909018, 35.714558877897218, 0.0],
          [-81.080687517919841, 35.713434411232356, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285116.0,
        COMID: 9754734,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.547,
        REACHCODE: "03050101001428",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0051462332328040001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250061695.0,
        ToNode: 250061693.0,
        Hydroseq: 250018325.0,
        LevelPathI: 250018325.0,
        Pathlength: 687.47,
        TerminalPa: 250002604.0,
        ArbolateSu: 397.423,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018595.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018069.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1629,
        TotDASqKM: 417.0672,
        DivDASqKM: 0.1629,
        Tidal: 0,
        TOTMA: 0.047354395395399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37093.0,
        MAXELEVSMO: 37123.0,
        MINELEVSMO: 37114.0,
        SLOPE: 0.0002267,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.39700001478195202,
        QA_MA: 0.096,
        VA_MA: 0.43863,
        QC_MA: 0.096,
        VC_MA: 0.43863,
        QE_MA: 0.096,
        VE_MA: 0.43863,
        QA_01: 0.159,
        VA_01: 0.47865,
        QC_01: 0.159,
        VC_01: 0.47865,
        QE_01: 0.159,
        VE_01: 0.47865,
        QA_02: 0.17,
        VA_02: 0.48481,
        QC_02: 0.17,
        VC_02: 0.48481,
        QE_02: 0.17,
        VE_02: 0.48481,
        QA_03: 0.197,
        VA_03: 0.49917,
        QC_03: 0.197,
        VC_03: 0.49917,
        QE_03: 0.197,
        VE_03: 0.49917,
        QA_04: 0.146,
        VA_04: 0.47111,
        QC_04: 0.146,
        VC_04: 0.47111,
        QE_04: 0.146,
        VE_04: 0.47111,
        QA_05: 0.113,
        VA_05: 0.45041,
        QC_05: 0.113,
        VC_05: 0.45041,
        QE_05: 0.113,
        VE_05: 0.45041,
        QA_06: 0.061,
        VA_06: 0.41076,
        QC_06: 0.061,
        VC_06: 0.41076,
        QE_06: 0.061,
        VE_06: 0.41076,
        QA_07: 0.037,
        VA_07: 0.38689,
        QC_07: 0.037,
        VC_07: 0.38689,
        QE_07: 0.037,
        VE_07: 0.38689,
        QA_08: 0.024,
        VA_08: 0.37077,
        QC_08: 0.024,
        VC_08: 0.37077,
        QE_08: 0.024,
        VE_08: 0.37077,
        QA_09: 0.017,
        VA_09: 0.36033,
        QC_09: 0.017,
        VC_09: 0.36033,
        QE_09: 0.017,
        VE_09: 0.36033,
        QA_10: 0.013,
        VA_10: 0.35344,
        QC_10: 0.013,
        VC_10: 0.35344,
        QE_10: 0.013,
        VE_10: 0.35344,
        QA_11: 0.089,
        VA_11: 0.4335,
        QC_11: 0.089,
        VC_11: 0.4335,
        QE_11: 0.089,
        VE_11: 0.4335,
        QA_12: 0.129,
        VA_12: 0.46076,
        QC_12: 0.129,
        VC_12: 0.46076,
        QE_12: 0.129,
        VE_12: 0.46076,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.04864851641743, 35.69865301125526, 0.0],
          [-82.049121983083353, 35.702981277915228, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291243.0,
        COMID: 9736646,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.455,
        REACHCODE: "03050104006924",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0042593760486649996,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060072.0,
        ToNode: 250057093.0,
        Hydroseq: 250004244.0,
        LevelPathI: 250003258.0,
        Pathlength: 366.896,
        TerminalPa: 250002604.0,
        ArbolateSu: 8343.492,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004252.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004236.0,
        FromMeas: 0.0,
        ToMeas: 87.60677,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2664,
        TotDASqKM: 11273.832,
        DivDASqKM: 11273.832,
        Tidal: 0,
        TOTMA: 0.013008848658399999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6801.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.45500001311302202,
        QA_MA: 5442.144,
        VA_MA: 1.31822,
        QC_MA: 5442.144,
        VC_MA: 1.31822,
        QE_MA: 6139.778,
        VE_MA: 1.32814,
        QA_01: 10196.736,
        VA_01: 1.71783,
        QC_01: 10196.736,
        VC_01: 1.71783,
        QE_01: 8804.405,
        VE_01: 1.54325,
        QA_02: 10777.041,
        VA_02: 1.75987,
        QC_02: 10777.041,
        VC_02: 1.75987,
        QE_02: 8937.308,
        VE_02: 1.55311,
        QA_03: 11799.718,
        VA_03: 1.83145,
        QC_03: 11799.718,
        VC_03: 1.83145,
        QE_03: 9550.825,
        VE_03: 1.59778,
        QA_04: 7951.594,
        VA_04: 1.54347,
        QC_04: 7951.594,
        VC_04: 1.54347,
        QE_04: 7700.505,
        VE_04: 1.45846,
        QA_05: 5317.062,
        VA_05: 1.30583,
        QC_05: 5317.062,
        VC_05: 1.30583,
        QE_05: 6272.389,
        VE_05: 1.33977,
        QA_06: 3164.197,
        VA_06: 1.06556,
        QC_06: 3164.197,
        VC_06: 1.06556,
        QE_06: 5387.787,
        VE_06: 1.25979,
        QA_07: 1994.517,
        VA_07: 0.901,
        QC_07: 1994.517,
        VC_07: 0.901,
        QE_07: 4070.198,
        VE_07: 1.12815,
        QA_08: 1431.91,
        VA_08: 0.80538,
        QC_08: 1431.91,
        VC_08: 0.80538,
        QE_08: 3726.749,
        VE_08: 1.09067,
        QA_09: 1285.283,
        VA_09: 0.77767,
        QC_09: 1285.283,
        VC_09: 0.77767,
        QE_09: 3421.905,
        VE_09: 1.05601,
        QA_10: 1344.559,
        VA_10: 0.78904,
        QC_10: 1344.559,
        VC_10: 0.78904,
        QE_10: 4663.783,
        VE_10: 1.18961,
        QA_11: 3292.656,
        VA_11: 1.08173,
        QC_11: 3292.656,
        VC_11: 1.08173,
        QE_11: 4738.558,
        VE_11: 1.19708,
        QA_12: 6788.014,
        VA_12: 1.44395,
        QC_12: 6788.014,
        VC_12: 1.44395,
        QE_12: 6496.779,
        VE_12: 1.3592,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877003518236052, 34.541195613051968, 0.0],
          [-80.8752163849054, 34.53756581305754, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285117.0,
        COMID: 9754724,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.599,
        REACHCODE: "03050101001429",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0062283329388770001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250061691.0,
        ToNode: 250061688.0,
        Hydroseq: 250017820.0,
        LevelPathI: 250017820.0,
        Pathlength: 686.645,
        TerminalPa: 250002604.0,
        ArbolateSu: 399.005,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250018069.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017589.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1467,
        TotDASqKM: 417.8862,
        DivDASqKM: 0.1467,
        Tidal: 0,
        TOTMA: 0.053893229609800003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37095.0,
        MAXELEVSMO: 37100.0,
        MINELEVSMO: 37095.0,
        SLOPE: 0.0001116,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.44800001382827798,
        QA_MA: 0.087,
        VA_MA: 0.42205,
        QC_MA: 0.087,
        VC_MA: 0.42205,
        QE_MA: 0.087,
        VE_MA: 0.42205,
        QA_01: 0.143,
        VA_01: 0.45637,
        QC_01: 0.143,
        VC_01: 0.45637,
        QE_01: 0.143,
        VE_01: 0.45637,
        QA_02: 0.153,
        VA_02: 0.46177,
        QC_02: 0.153,
        VC_02: 0.46177,
        QE_02: 0.153,
        VE_02: 0.46177,
        QA_03: 0.177,
        VA_03: 0.47411,
        QC_03: 0.177,
        VC_03: 0.47411,
        QE_03: 0.177,
        VE_03: 0.47411,
        QA_04: 0.132,
        VA_04: 0.45021,
        QC_04: 0.132,
        VC_04: 0.45021,
        QE_04: 0.132,
        VE_04: 0.45021,
        QA_05: 0.102,
        VA_05: 0.43206,
        QC_05: 0.102,
        VC_05: 0.43206,
        QE_05: 0.102,
        VE_05: 0.43206,
        QA_06: 0.055,
        VA_06: 0.39748,
        QC_06: 0.055,
        VC_06: 0.39748,
        QE_06: 0.055,
        VE_06: 0.39748,
        QA_07: 0.033,
        VA_07: 0.37632,
        QC_07: 0.033,
        VC_07: 0.37632,
        QE_07: 0.033,
        VE_07: 0.37632,
        QA_08: 0.021,
        VA_08: 0.36182,
        QC_08: 0.021,
        VC_08: 0.36182,
        QE_08: 0.021,
        VE_08: 0.36182,
        QA_09: 0.015,
        VA_09: 0.35308,
        QC_09: 0.015,
        VC_09: 0.35308,
        QE_09: 0.015,
        VE_09: 0.35308,
        QA_10: 0.011,
        VA_10: 0.34629,
        QC_10: 0.011,
        VC_10: 0.34629,
        QE_10: 0.011,
        VE_10: 0.34629,
        QA_11: 0.08,
        VA_11: 0.41709,
        QC_11: 0.08,
        VC_11: 0.41709,
        QE_11: 0.08,
        VE_11: 0.41709,
        QA_12: 0.116,
        VA_12: 0.4408,
        QC_12: 0.116,
        VC_12: 0.4408,
        QE_12: 0.116,
        VE_12: 0.4408,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.049065183083385, 35.703897277913882, 0.0],
          [-82.043868583091466, 35.706825411242619, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285112.0,
        COMID: 9754778,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.638,
        REACHCODE: "03050101001424",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0065457770366700004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250061707.0,
        ToNode: 250061705.0,
        Hydroseq: 250019846.0,
        LevelPathI: 250019846.0,
        Pathlength: 689.984,
        TerminalPa: 250002604.0,
        ArbolateSu: 309.844,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250020192.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019505.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1574,
        TotDASqKM: 332.3133,
        DivDASqKM: 1.1574,
        Tidal: 0,
        TOTMA: 0.043535389560700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37702.0,
        MAXELEVSMO: 37738.0,
        MINELEVSMO: 37702.0,
        SLOPE: 0.0007377,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.48800000548362699,
        QA_MA: 0.737,
        VA_MA: 0.55648,
        QC_MA: 0.737,
        VC_MA: 0.55648,
        QE_MA: 0.737,
        VE_MA: 0.55648,
        QA_01: 1.181,
        VA_01: 0.62706,
        QC_01: 1.181,
        VC_01: 0.62706,
        QE_01: 1.181,
        VE_01: 0.62706,
        QA_02: 1.27,
        VA_02: 0.6396,
        QC_02: 1.27,
        VC_02: 0.6396,
        QE_02: 1.27,
        VE_02: 0.6396,
        QA_03: 1.445,
        VA_03: 0.6631,
        QC_03: 1.445,
        VC_03: 0.6631,
        QE_03: 1.445,
        VE_03: 0.6631,
        QA_04: 1.087,
        VA_04: 0.61333,
        QC_04: 1.087,
        VC_04: 0.61333,
        QE_04: 1.087,
        VE_04: 0.61333,
        QA_05: 0.854,
        VA_05: 0.57667,
        QC_05: 0.854,
        VC_05: 0.57667,
        QE_05: 0.854,
        VE_05: 0.57667,
        QA_06: 0.467,
        VA_06: 0.5031,
        QC_06: 0.467,
        VC_06: 0.5031,
        QE_06: 0.467,
        VE_06: 0.5031,
        QA_07: 0.276,
        VA_07: 0.45566,
        QC_07: 0.276,
        VC_07: 0.45566,
        QE_07: 0.276,
        VE_07: 0.45566,
        QA_08: 0.172,
        VA_08: 0.42295,
        QC_08: 0.172,
        VC_08: 0.42295,
        QE_08: 0.172,
        VE_08: 0.42295,
        QA_09: 0.139,
        VA_09: 0.4107,
        QC_09: 0.139,
        VC_09: 0.4107,
        QE_09: 0.139,
        VE_09: 0.4107,
        QA_10: 0.186,
        VA_10: 0.42781,
        QC_10: 0.186,
        VC_10: 0.42781,
        QE_10: 0.186,
        VE_10: 0.42781,
        QA_11: 0.762,
        VA_11: 0.56091,
        QC_11: 0.762,
        VC_11: 0.56091,
        QE_11: 0.762,
        VE_11: 0.56091,
        QA_12: 1.006,
        VA_12: 0.60105,
        QC_12: 1.006,
        VC_12: 0.60105,
        QE_12: 1.006,
        VE_12: 0.60105,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.05564811640653, 35.686634277940584, 0.0],
          [-82.052561716411333, 35.68512141127627, 0.0],
          [-82.050707783080895, 35.686746211273771, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286995.0,
        COMID: 9736240,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.1,
        REACHCODE: "03050103000272",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011716587194033999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059883.0,
        ToNode: 250059889.0,
        Hydroseq: 250006357.0,
        LevelPathI: 250003258.0,
        Pathlength: 420.637,
        TerminalPa: 250002604.0,
        ArbolateSu: 6023.334,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006430.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006334.0,
        FromMeas: 0.0,
        ToMeas: 41.56324,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6354,
        TotDASqKM: 7981.0965,
        DivDASqKM: 7981.0965,
        Tidal: 0,
        TOTMA: 0.033400465871100002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13840.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1000000238418579,
        QA_MA: 4134.568,
        VA_MA: 1.24653,
        QC_MA: 4134.568,
        VC_MA: 1.24653,
        QE_MA: 4360.204,
        VE_MA: 1.25058,
        QA_01: 7365.623,
        VA_01: 1.58316,
        QC_01: 7365.623,
        VC_01: 1.58316,
        QE_01: 5944.225,
        VE_01: 1.41911,
        QA_02: 7794.08,
        VA_02: 1.62201,
        QC_02: 7794.08,
        VC_02: 1.62201,
        QE_02: 6121.382,
        VE_02: 1.43657,
        QA_03: 8594.257,
        VA_03: 1.69198,
        QC_03: 8594.257,
        VC_03: 1.69198,
        QE_03: 6224.342,
        VE_03: 1.4466,
        QA_04: 6088.992,
        VA_04: 1.46062,
        QC_04: 6088.992,
        VC_04: 1.46062,
        QE_04: 5405.53,
        VE_04: 1.36447,
        QA_05: 4278.143,
        VA_05: 1.26369,
        QC_05: 4278.143,
        VC_05: 1.26369,
        QE_05: 4497.514,
        VE_05: 1.26622,
        QA_06: 2507.011,
        VA_06: 1.02767,
        QC_06: 2507.011,
        VC_06: 1.02767,
        QE_06: 4184.539,
        VE_06: 1.23023,
        QA_07: 1527.796,
        VA_07: 0.86133,
        QC_07: 1527.796,
        VC_07: 0.86133,
        QE_07: 3154.202,
        VE_07: 1.10175,
        QA_08: 1081.782,
        VA_08: 0.76872,
        QC_08: 1081.782,
        VC_08: 0.76872,
        QE_08: 3087.279,
        VE_08: 1.09277,
        QA_09: 999.579,
        VA_09: 0.7498,
        QC_09: 999.579,
        VC_09: 0.7498,
        QE_09: 2579.39,
        VE_09: 1.02137,
        QA_10: 1122.682,
        VA_10: 0.77788,
        QC_10: 1122.682,
        VC_10: 0.77788,
        QE_10: 3405.864,
        VE_10: 1.13476,
        QA_11: 2997.536,
        VA_11: 1.09926,
        QC_11: 2997.536,
        VC_11: 1.09926,
        QE_11: 3619.446,
        VE_11: 1.16188,
        QA_12: 5285.561,
        VA_12: 1.37721,
        QC_12: 5285.561,
        VC_12: 1.37721,
        QE_12: 4445.762,
        VE_12: 1.26035,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88110678489636, 34.947008479088652, 0.0],
          [-80.87595931823769, 34.94674701242235, 0.0],
          [-80.870355918246275, 34.94371027909375, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286996.0,
        COMID: 9736256,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.977,
        REACHCODE: "03050103000272",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010621040703836,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059890.0,
        ToNode: 250059883.0,
        Hydroseq: 250006430.0,
        LevelPathI: 250003258.0,
        Pathlength: 421.737,
        TerminalPa: 250002604.0,
        ArbolateSu: 6021.012,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006458.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006357.0,
        FromMeas: 41.56324,
        ToMeas: 78.54574,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3797,
        TotDASqKM: 7979.553,
        DivDASqKM: 7979.553,
        Tidal: 0,
        TOTMA: 0.017694144776800001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14349.0,
        MAXELEVSMO: 14473.0,
        MINELEVSMO: 14418.0,
        SLOPE: 0.00056294,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.97699999809265103,
        QA_MA: 4133.934,
        VA_MA: 2.08902,
        QC_MA: 4133.934,
        VC_MA: 2.08902,
        QE_MA: 4359.567,
        VE_MA: 2.0967,
        QA_01: 7364.254,
        VA_01: 2.72791,
        QC_01: 7364.254,
        VC_01: 2.72791,
        QE_01: 5942.863,
        VE_01: 2.41648,
        QA_02: 7792.648,
        VA_02: 2.80166,
        QC_02: 7792.648,
        VC_02: 2.80166,
        QE_02: 6119.959,
        VE_02: 2.44961,
        QA_03: 8592.742,
        VA_03: 2.93447,
        QC_03: 8592.742,
        VC_03: 2.93447,
        QE_03: 6222.839,
        VE_03: 2.46865,
        QA_04: 6088.079,
        VA_04: 2.49536,
        QC_04: 6088.079,
        VC_04: 2.49536,
        QE_04: 5404.618,
        VE_04: 2.31285,
        QA_05: 4277.645,
        VA_05: 2.12162,
        QC_05: 4277.645,
        VC_05: 2.12162,
        QE_05: 4497.011,
        VE_05: 2.12642,
        QA_06: 2506.699,
        VA_06: 1.67363,
        QC_06: 2506.699,
        VC_06: 1.67363,
        QE_06: 4184.216,
        VE_06: 2.05814,
        QA_07: 1527.568,
        VA_07: 1.35791,
        QC_07: 1527.568,
        VC_07: 1.35791,
        QE_07: 3153.963,
        VE_07: 1.81427,
        QA_08: 1081.616,
        VA_08: 1.18212,
        QC_08: 1081.616,
        VC_08: 1.18212,
        QE_08: 3087.101,
        VE_08: 1.79724,
        QA_09: 999.434,
        VA_09: 1.14622,
        QC_09: 999.434,
        VC_09: 1.14622,
        QE_09: 2579.234,
        VE_09: 1.66171,
        QA_10: 1122.578,
        VA_10: 1.19954,
        QC_10: 1122.578,
        VC_10: 1.19954,
        QE_10: 3405.743,
        VE_10: 1.87696,
        QA_11: 2997.378,
        VA_11: 1.80957,
        QC_11: 2997.378,
        VC_11: 1.80957,
        QE_11: 3619.282,
        VE_11: 1.92843,
        QA_12: 5284.793,
        VA_12: 2.33706,
        QC_12: 5284.793,
        VC_12: 2.33706,
        QE_12: 4444.996,
        VE_12: 2.11522,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.891383184880397, 34.9450840124249, 0.0],
          [-80.88110678489636, 34.947008479088652, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286997.0,
        COMID: 9736266,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.567,
        REACHCODE: "03050103000272",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0061846603387920003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059894.0,
        ToNode: 250059890.0,
        Hydroseq: 250006458.0,
        LevelPathI: 250003258.0,
        Pathlength: 422.714,
        TerminalPa: 250002604.0,
        ArbolateSu: 6020.035,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006485.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250006429.0,
        DnDrainCou: 2,
        DnHydroseq: 250006430.0,
        FromMeas: 78.54574,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1664,
        TotDASqKM: 7978.1733,
        DivDASqKM: 7978.1733,
        Tidal: 0,
        TOTMA: 0.017217384452500002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14473.0,
        MAXELEVSMO: 14473.0,
        MINELEVSMO: 14473.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.566999971866608,
        QA_MA: 4133.367,
        VA_MA: 1.24646,
        QC_MA: 4133.367,
        VC_MA: 1.24646,
        QE_MA: 4358.997,
        VE_MA: 1.25051,
        QA_01: 7363.029,
        VA_01: 1.58302,
        QC_01: 7363.029,
        VC_01: 1.58302,
        QE_01: 5941.646,
        VE_01: 1.41893,
        QA_02: 7791.368,
        VA_02: 1.62187,
        QC_02: 7791.368,
        VC_02: 1.62187,
        QE_02: 6118.688,
        VE_02: 1.43638,
        QA_03: 8591.382,
        VA_03: 1.69184,
        QC_03: 8591.382,
        VC_03: 1.69184,
        QE_03: 6221.49,
        VE_03: 1.4464,
        QA_04: 6087.264,
        VA_04: 1.46053,
        QC_04: 6087.264,
        VC_04: 1.46053,
        QE_04: 5403.804,
        VE_04: 1.36436,
        QA_05: 4277.199,
        VA_05: 1.26365,
        QC_05: 4277.199,
        VC_05: 1.26365,
        QE_05: 4496.561,
        VE_05: 1.26618,
        QA_06: 2506.421,
        VA_06: 1.02763,
        QC_06: 2506.421,
        VC_06: 1.02763,
        QE_06: 4183.927,
        VE_06: 1.23022,
        QA_07: 1527.364,
        VA_07: 0.86129,
        QC_07: 1527.364,
        VC_07: 0.86129,
        QE_07: 3153.749,
        VE_07: 1.10175,
        QA_08: 1081.468,
        VA_08: 0.76869,
        QC_08: 1081.468,
        VC_08: 0.76869,
        QE_08: 3086.942,
        VE_08: 1.09278,
        QA_09: 999.304,
        VA_09: 0.74977,
        QC_09: 999.304,
        VC_09: 0.74977,
        QE_09: 2579.094,
        VE_09: 1.02137,
        QA_10: 1122.486,
        VA_10: 0.77787,
        QC_10: 1122.486,
        VC_10: 0.77787,
        QE_10: 3405.635,
        VE_10: 1.13479,
        QA_11: 2997.233,
        VA_11: 1.09928,
        QC_11: 2997.233,
        VC_11: 1.09928,
        QE_11: 3619.13,
        VE_11: 1.1619,
        QA_12: 5284.108,
        VA_12: 1.37714,
        QC_12: 5284.108,
        VC_12: 1.37714,
        QE_12: 4444.312,
        VE_12: 1.26025,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.897420518204342, 34.944238212426342, 0.0],
          [-80.891383184880397, 34.9450840124249, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286998.0,
        COMID: 9736224,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.542,
        REACHCODE: "03050103000274",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0053297770769949997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059875.0,
        ToNode: 250059878.0,
        Hydroseq: 250006687.0,
        LevelPathI: 250003258.0,
        Pathlength: 428.258,
        TerminalPa: 250002604.0,
        ArbolateSu: 5961.31,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006712.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006662.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2475,
        TotDASqKM: 7912.2447,
        DivDASqKM: 7912.2447,
        Tidal: 0,
        TOTMA: 0.016479985913100001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14376.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.54199999570846602,
        QA_MA: 4105.829,
        VA_MA: 1.24479,
        QC_MA: 4105.829,
        VC_MA: 1.24479,
        QE_MA: 4331.3,
        VE_MA: 1.24886,
        QA_01: 7304.1,
        VA_01: 1.57985,
        QC_01: 7304.1,
        VC_01: 1.57985,
        QE_01: 5883.037,
        VE_01: 1.41491,
        QA_02: 7729.833,
        VA_02: 1.61867,
        QC_02: 7729.833,
        VC_02: 1.61867,
        QE_02: 6057.558,
        VE_02: 1.43222,
        QA_03: 8525.295,
        VA_03: 1.68863,
        QC_03: 8525.295,
        VC_03: 1.68863,
        QE_03: 6155.902,
        VE_03: 1.44187,
        QA_04: 6047.802,
        VA_04: 1.4586,
        QC_04: 6047.802,
        VC_04: 1.4586,
        QE_04: 5364.39,
        VE_04: 1.36199,
        QA_05: 4255.284,
        VA_05: 1.26274,
        QC_05: 4255.284,
        VC_05: 1.26274,
        QE_05: 4474.423,
        VE_05: 1.26523,
        QA_06: 2492.742,
        VA_06: 1.02682,
        QC_06: 2492.742,
        VC_06: 1.02682,
        QE_06: 4169.745,
        VE_06: 1.23007,
        QA_07: 1517.613,
        VA_07: 0.86039,
        QC_07: 1517.613,
        VC_07: 0.86039,
        QE_07: 3143.528,
        VE_07: 1.10167,
        QA_08: 1074.377,
        VA_08: 0.76789,
        QC_08: 1074.377,
        VC_08: 0.76789,
        QE_08: 3079.326,
        VE_08: 1.09303,
        QA_09: 993.099,
        VA_09: 0.7491,
        QC_09: 993.099,
        VC_09: 0.7491,
        QE_09: 2572.407,
        VE_09: 1.02156,
        QA_10: 1118.054,
        VA_10: 0.77772,
        QC_10: 1118.054,
        VC_10: 0.77772,
        QE_10: 3400.476,
        VE_10: 1.13547,
        QA_11: 2989.023,
        VA_11: 1.09953,
        QC_11: 2989.023,
        VC_11: 1.09953,
        QE_11: 3610.612,
        VE_11: 1.16223,
        QA_12: 5250.702,
        VA_12: 1.37544,
        QC_12: 5250.702,
        VC_12: 1.37544,
        QE_12: 4410.969,
        VE_12: 1.258,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.952164718119377, 34.960017812401816, 0.0],
          [-80.948375718125263, 34.956269479074194, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286137.0,
        COMID: 9756952,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.506,
        REACHCODE: "03050101002814",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0046682626123030002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062026.0,
        ToNode: 250062027.0,
        Hydroseq: 250007348.0,
        LevelPathI: 250003258.0,
        Pathlength: 449.741,
        TerminalPa: 250002604.0,
        ArbolateSu: 5785.636,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007376.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007320.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.549,
        TotDASqKM: 7588.8585,
        DivDASqKM: 7588.8585,
        Tidal: 0,
        TOTMA: 0.17376056107000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17307.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50599998235702504,
        QA_MA: 3967.362,
        VA_MA: -9998.0,
        QC_MA: 3967.362,
        VC_MA: -9998.0,
        QE_MA: 4217.263,
        VE_MA: -9998.0,
        QA_01: 7013.706,
        VA_01: -9998.0,
        QC_01: 7013.706,
        VC_01: -9998.0,
        QE_01: 7174.176,
        VE_01: -9998.0,
        QA_02: 7423.955,
        VA_02: -9998.0,
        QC_02: 7423.955,
        VC_02: -9998.0,
        QE_02: 7437.7,
        VE_02: -9998.0,
        QA_03: 8194.592,
        VA_03: -9998.0,
        QC_03: 8194.592,
        VC_03: -9998.0,
        QE_03: 8415.898,
        VE_03: -9998.0,
        QA_04: 5849.687,
        VA_04: -9998.0,
        QC_04: 5849.687,
        VC_04: -9998.0,
        QE_04: 6015.623,
        VE_04: -9998.0,
        QA_05: 4145.135,
        VA_05: -9998.0,
        QC_05: 4145.135,
        VC_05: -9998.0,
        QE_05: 4464.393,
        VE_05: -9998.0,
        QA_06: 2424.445,
        VA_06: -9998.0,
        QC_06: 2424.445,
        VC_06: -9998.0,
        QE_06: 2772.425,
        VE_06: -9998.0,
        QA_07: 1470.998,
        VA_07: -9998.0,
        QC_07: 1470.998,
        VC_07: -9998.0,
        QE_07: 1726.5,
        VE_07: -9998.0,
        QA_08: 1039.593,
        VA_08: -9998.0,
        QC_08: 1039.593,
        VC_08: -9998.0,
        QE_08: 1449.447,
        VE_08: -9998.0,
        QA_09: 962.663,
        VA_09: -9998.0,
        QC_09: 962.663,
        VC_09: -9998.0,
        QE_09: 1082.798,
        VE_09: -9998.0,
        QA_10: 1095.672,
        VA_10: -9998.0,
        QC_10: 1095.672,
        VC_10: -9998.0,
        QE_10: 1370.185,
        VE_10: -9998.0,
        QA_11: 2937.726,
        VA_11: -9998.0,
        QC_11: 2937.726,
        VC_11: -9998.0,
        QE_11: 3099.911,
        VE_11: -9998.0,
        QA_12: 5077.266,
        VA_12: -9998.0,
        QC_12: 5077.266,
        VC_12: -9998.0,
        QE_12: 5078.47,
        VE_12: -9998.0,
        LakeFract: 0.01408342686,
        SurfArea: 637765.85322100006,
        RAreaHLoad: 0.06255421224,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.061450184616433, 35.0857230788734, 0.0],
          [-81.060263117951536, 35.081499212213259, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286138.0,
        COMID: 9756954,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.027,
        REACHCODE: "03050101002815",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.018332215895622998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062027.0,
        ToNode: 250062037.0,
        Hydroseq: 250007320.0,
        LevelPathI: 250003258.0,
        Pathlength: 447.714,
        TerminalPa: 250002604.0,
        ArbolateSu: 5793.14,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007348.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007296.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8358,
        TotDASqKM: 7600.995,
        DivDASqKM: 7600.995,
        Tidal: 0,
        TOTMA: 0.69607244504,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17311.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.026999950408936,
        QA_MA: 3972.503,
        VA_MA: -9998.0,
        QC_MA: 3972.503,
        VC_MA: -9998.0,
        QE_MA: 4222.41,
        VE_MA: -9998.0,
        QA_01: 7024.427,
        VA_01: -9998.0,
        QC_01: 7024.427,
        VC_01: -9998.0,
        QE_01: 7184.857,
        VE_01: -9998.0,
        QA_02: 7435.315,
        VA_02: -9998.0,
        QC_02: 7435.315,
        VC_02: -9998.0,
        QE_02: 7449.013,
        VE_02: -9998.0,
        QA_03: 8206.96,
        VA_03: -9998.0,
        QC_03: 8206.96,
        VC_03: -9998.0,
        QE_03: 8428.198,
        VE_03: -9998.0,
        QA_04: 5857.105,
        VA_04: -9998.0,
        QC_04: 5857.105,
        VC_04: -9998.0,
        QE_04: 6023.021,
        VE_04: -9998.0,
        QA_05: 4149.259,
        VA_05: -9998.0,
        QC_05: 4149.259,
        VC_05: -9998.0,
        QE_05: 4468.523,
        VE_05: -9998.0,
        QA_06: 2426.957,
        VA_06: -9998.0,
        QC_06: 2426.957,
        VC_06: -9998.0,
        QE_06: 2774.983,
        VE_06: -9998.0,
        QA_07: 1472.793,
        VA_07: -9998.0,
        QC_07: 1472.793,
        VC_07: -9998.0,
        QE_07: 1728.34,
        VE_07: -9998.0,
        QA_08: 1040.898,
        VA_08: -9998.0,
        QC_08: 1040.898,
        VC_08: -9998.0,
        QE_08: 1450.808,
        VE_08: -9998.0,
        QA_09: 963.806,
        VA_09: -9998.0,
        QC_09: 963.806,
        VC_09: -9998.0,
        QE_09: 1083.985,
        VE_09: -9998.0,
        QA_10: 1096.488,
        VA_10: -9998.0,
        QC_10: 1096.488,
        VC_10: -9998.0,
        QE_10: 1371.065,
        VE_10: -9998.0,
        QA_11: 2939.48,
        VA_11: -9998.0,
        QC_11: 2939.48,
        VC_11: -9998.0,
        QE_11: 3101.682,
        VE_11: -9998.0,
        QA_12: 5083.674,
        VA_12: -9998.0,
        QC_12: 5083.674,
        VC_12: -9998.0,
        QE_12: 5084.854,
        VE_12: -9998.0,
        LakeFract: 0.05648688798,
        SurfArea: 2558000.1704500001,
        RAreaHLoad: 0.25058772366999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.060263117951536, 35.081499212213259, 0.0],
          [-81.063061317947188, 35.063805678907386, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286147.0,
        COMID: 9756978,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.823,
        REACHCODE: "03050101002824",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0077525440984810004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062038.0,
        ToNode: 250062043.0,
        Hydroseq: 250007273.0,
        LevelPathI: 250003258.0,
        Pathlength: 442.925,
        TerminalPa: 250002604.0,
        ArbolateSu: 5810.199,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007296.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007245.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0044,
        TotDASqKM: 7632.5526,
        DivDASqKM: 7632.5526,
        Tidal: 0,
        TOTMA: 0.28261846193000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17303.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17319.0,
        SLOPE: 2.43e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.82300001382827803,
        QA_MA: 3986.02,
        VA_MA: -9998.0,
        QC_MA: 3986.02,
        VC_MA: -9998.0,
        QE_MA: 4235.943,
        VE_MA: -9998.0,
        QA_01: 7052.572,
        VA_01: -9998.0,
        QC_01: 7052.572,
        VC_01: -9998.0,
        QE_01: 7212.898,
        VE_01: -9998.0,
        QA_02: 7465.036,
        VA_02: -9998.0,
        QC_02: 7465.036,
        VC_02: -9998.0,
        QE_02: 7478.612,
        VE_02: -9998.0,
        QA_03: 8239.411,
        VA_03: -9998.0,
        QC_03: 8239.411,
        VC_03: -9998.0,
        QE_03: 8460.476,
        VE_03: -9998.0,
        QA_04: 5876.52,
        VA_04: -9998.0,
        QC_04: 5876.52,
        VC_04: -9998.0,
        QE_04: 6042.386,
        VE_04: -9998.0,
        QA_05: 4160.128,
        VA_05: -9998.0,
        QC_05: 4160.128,
        VC_05: -9998.0,
        QE_05: 4479.406,
        VE_05: -9998.0,
        QA_06: 2433.655,
        VA_06: -9998.0,
        QC_06: 2433.655,
        VC_06: -9998.0,
        QE_06: 2781.803,
        VE_06: -9998.0,
        QA_07: 1477.453,
        VA_07: -9998.0,
        QC_07: 1477.453,
        VC_07: -9998.0,
        QE_07: 1733.119,
        VE_07: -9998.0,
        QA_08: 1044.292,
        VA_08: -9998.0,
        QC_08: 1044.292,
        VC_08: -9998.0,
        QE_08: 1454.349,
        VE_08: -9998.0,
        QA_09: 966.776,
        VA_09: -9998.0,
        QC_09: 966.776,
        VC_09: -9998.0,
        QE_09: 1087.069,
        VE_09: -9998.0,
        QA_10: 1098.609,
        VA_10: -9998.0,
        QC_10: 1098.609,
        VC_10: -9998.0,
        QE_10: 1373.351,
        VE_10: -9998.0,
        QA_11: 2944.45,
        VA_11: -9998.0,
        QC_11: 2944.45,
        VC_11: -9998.0,
        QE_11: 3106.696,
        VE_11: -9998.0,
        QA_12: 5100.564,
        VA_12: -9998.0,
        QC_12: 5100.564,
        VC_12: -9998.0,
        QE_12: 5101.681,
        VE_12: -9998.0,
        LakeFract: 0.02300912409,
        SurfArea: 1041964.70099,
        RAreaHLoad: 0.10174331357,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.045025717975193, 35.034210812286574, 0.0],
          [-81.044968317975247, 35.031081478958072, 0.0],
          [-81.041937917980022, 35.027706278963365, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286150.0,
        COMID: 9756984,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.298,
        REACHCODE: "03050101002827",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0031970164006769998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062041.0,
        ToNode: 250062042.0,
        Hydroseq: 250007216.0,
        LevelPathI: 250003258.0,
        Pathlength: 438.945,
        TerminalPa: 250002604.0,
        ArbolateSu: 5904.627,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007245.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007193.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6111,
        TotDASqKM: 7817.2731,
        DivDASqKM: 7817.2731,
        Tidal: 0,
        TOTMA: 0.10233329488,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15891.0,
        MAXELEVSMO: 17292.0,
        MINELEVSMO: 15891.0,
        SLOPE: 0.04701342,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.298000007867813,
        QA_MA: 4065.149,
        VA_MA: -9998.0,
        QC_MA: 4065.149,
        VC_MA: -9998.0,
        QE_MA: 4315.163,
        VE_MA: -9998.0,
        QA_01: 7218.592,
        VA_01: -9998.0,
        QC_01: 7218.592,
        VC_01: -9998.0,
        QE_01: 7378.309,
        VE_01: -9998.0,
        QA_02: 7639.929,
        VA_02: -9998.0,
        QC_02: 7639.929,
        VC_02: -9998.0,
        QE_02: 7652.79,
        VE_02: -9998.0,
        QA_03: 8427.959,
        VA_03: -9998.0,
        QC_03: 8427.959,
        VC_03: -9998.0,
        QE_03: 8648.006,
        VE_03: -9998.0,
        QA_04: 5989.36,
        VA_04: -9998.0,
        QC_04: 5989.36,
        VC_04: -9998.0,
        QE_04: 6154.927,
        VE_04: -9998.0,
        QA_05: 4222.997,
        VA_05: -9998.0,
        QC_05: 4222.997,
        VC_05: -9998.0,
        QE_05: 4542.36,
        VE_05: -9998.0,
        QA_06: 2472.425,
        VA_06: -9998.0,
        QC_06: 2472.425,
        VC_06: -9998.0,
        QE_06: 2821.285,
        VE_06: -9998.0,
        QA_07: 1503.567,
        VA_07: -9998.0,
        QC_07: 1503.567,
        VC_07: -9998.0,
        QE_07: 1759.924,
        VE_07: -9998.0,
        QA_08: 1064.161,
        VA_08: -9998.0,
        QC_08: 1064.161,
        VC_08: -9998.0,
        QE_08: 1475.073,
        VE_08: -9998.0,
        QA_09: 984.161,
        VA_09: -9998.0,
        QC_09: 984.161,
        VC_09: -9998.0,
        QE_09: 1105.125,
        VE_09: -9998.0,
        QA_10: 1111.669,
        VA_10: -9998.0,
        QC_10: 1111.669,
        VC_10: -9998.0,
        QE_10: 1387.378,
        VE_10: -9998.0,
        QA_11: 2974.31,
        VA_11: -9998.0,
        QC_11: 2974.31,
        VC_11: -9998.0,
        QE_11: 3136.813,
        VE_11: -9998.0,
        QA_12: 5200.377,
        VA_12: -9998.0,
        QC_12: 5200.377,
        VC_12: -9998.0,
        QE_12: 5201.128,
        VE_12: -9998.0,
        LakeFract: 0.00848904509,
        SurfArea: 384425.12171799998,
        RAreaHLoad: 0.03684022777,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.010642718028635, 35.0211386789735, 0.0],
          [-81.00767758469982, 35.020356812308023, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286146.0,
        COMID: 9756976,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.966,
        REACHCODE: "03050101002823",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.037882305889597997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062037.0,
        ToNode: 250062038.0,
        Hydroseq: 250007296.0,
        LevelPathI: 250003258.0,
        Pathlength: 443.748,
        TerminalPa: 250002604.0,
        ArbolateSu: 5802.789,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007320.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007273.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 11.799,
        TotDASqKM: 7620.8103,
        DivDASqKM: 7620.8103,
        Tidal: 0,
        TOTMA: 1.36192566216,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17269.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.966000080108643,
        QA_MA: 3980.946,
        VA_MA: -9998.0,
        QC_MA: 3980.946,
        VC_MA: -9998.0,
        QE_MA: 4230.863,
        VE_MA: -9998.0,
        QA_01: 7042.014,
        VA_01: -9998.0,
        QC_01: 7042.014,
        VC_01: -9998.0,
        QE_01: 7202.379,
        VE_01: -9998.0,
        QA_02: 7453.941,
        VA_02: -9998.0,
        QC_02: 7453.941,
        VC_02: -9998.0,
        QE_02: 7467.562,
        VE_02: -9998.0,
        QA_03: 8227.255,
        VA_03: -9998.0,
        QC_03: 8227.255,
        VC_03: -9998.0,
        QE_03: 8448.384,
        VE_03: -9998.0,
        QA_04: 5869.252,
        VA_04: -9998.0,
        QC_04: 5869.252,
        VC_04: -9998.0,
        QE_04: 6035.136,
        VE_04: -9998.0,
        QA_05: 4156.023,
        VA_05: -9998.0,
        QC_05: 4156.023,
        VC_05: -9998.0,
        QE_05: 4475.296,
        VE_05: -9998.0,
        QA_06: 2431.129,
        VA_06: -9998.0,
        QC_06: 2431.129,
        VC_06: -9998.0,
        QE_06: 2779.232,
        VE_06: -9998.0,
        QA_07: 1475.716,
        VA_07: -9998.0,
        QC_07: 1475.716,
        VC_07: -9998.0,
        QE_07: 1731.338,
        VE_07: -9998.0,
        QA_08: 1043.029,
        VA_08: -9998.0,
        QC_08: 1043.029,
        VC_08: -9998.0,
        QE_08: 1453.032,
        VE_08: -9998.0,
        QA_09: 965.671,
        VA_09: -9998.0,
        QC_09: 965.671,
        VC_09: -9998.0,
        QE_09: 1085.922,
        VE_09: -9998.0,
        QA_10: 1097.82,
        VA_10: -9998.0,
        QC_10: 1097.82,
        VC_10: -9998.0,
        QE_10: 1372.501,
        VE_10: -9998.0,
        QA_11: 2942.479,
        VA_11: -9998.0,
        QC_11: 2942.479,
        VC_11: -9998.0,
        QE_11: 3104.708,
        VE_11: -9998.0,
        QA_12: 5094.205,
        VA_12: -9998.0,
        QC_12: 5094.205,
        VC_12: -9998.0,
        QE_12: 5095.345,
        VE_12: -9998.0,
        LakeFract: 0.11074534404,
        SurfArea: 5015086.1387700001,
        RAreaHLoad: 0.49029645394,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.063061317947188, 35.063805678907386, 0.0],
          [-81.051921317964513, 35.053826878922848, 0.0],
          [-81.046370184639784, 35.043613478938767, 0.0],
          [-81.045025717975193, 35.034210812286574, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286124.0,
        COMID: 9756926,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.957,
        REACHCODE: "03050101002801",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0090716226159790001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062013.0,
        ToNode: 250062014.0,
        Hydroseq: 250007527.0,
        LevelPathI: 250003258.0,
        Pathlength: 457.858,
        TerminalPa: 250002604.0,
        ArbolateSu: 5615.791,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007553.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007500.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.1653,
        TotDASqKM: 7235.6949,
        DivDASqKM: 7235.6949,
        Tidal: 0,
        TOTMA: 0.32863410456999997,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17319.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.95700001716613803,
        QA_MA: 3818.586,
        VA_MA: -9998.0,
        QC_MA: 3818.586,
        VC_MA: -9998.0,
        QE_MA: 4068.313,
        VE_MA: -9998.0,
        QA_01: 6701.12,
        VA_01: -9998.0,
        QC_01: 6701.12,
        VC_01: -9998.0,
        QE_01: 6862.754,
        VE_01: -9998.0,
        QA_02: 7094.023,
        VA_02: -9998.0,
        QC_02: 7094.023,
        VC_02: -9998.0,
        QE_02: 7109.136,
        VE_02: -9998.0,
        QA_03: 7843.076,
        VA_03: -9998.0,
        QC_03: 7843.076,
        VC_03: -9998.0,
        QE_03: 8066.326,
        VE_03: -9998.0,
        QA_04: 5640.516,
        VA_04: -9998.0,
        QC_04: 5640.516,
        VC_04: -9998.0,
        QE_04: 5807.022,
        VE_04: -9998.0,
        QA_05: 4026.27,
        VA_05: -9998.0,
        QC_05: 4026.27,
        VC_05: -9998.0,
        QE_05: 4345.366,
        VE_05: -9998.0,
        QA_06: 2352.381,
        VA_06: -9998.0,
        QC_06: 2352.381,
        VC_06: -9998.0,
        QE_06: 2699.0,
        VE_06: -9998.0,
        QA_07: 1423.223,
        VA_07: -9998.0,
        QC_07: 1423.223,
        VC_07: -9998.0,
        QE_07: 1677.404,
        VE_07: -9998.0,
        QA_08: 1001.887,
        VA_08: -9998.0,
        QC_08: 1001.887,
        VC_08: -9998.0,
        QE_08: 1410.106,
        VE_08: -9998.0,
        QA_09: 929.427,
        VA_09: -9998.0,
        QC_09: 929.427,
        VC_09: -9998.0,
        QE_09: 1048.281,
        VE_09: -9998.0,
        QA_10: 1070.314,
        VA_10: -9998.0,
        QC_10: 1070.314,
        VC_10: -9998.0,
        QE_10: 1342.979,
        VE_10: -9998.0,
        QA_11: 2883.519,
        VA_11: -9998.0,
        QC_11: 2883.519,
        VC_11: -9998.0,
        QE_11: 3045.212,
        VE_11: -9998.0,
        QA_12: 4883.391,
        VA_12: -9998.0,
        QC_12: 4883.391,
        VC_12: -9998.0,
        QE_12: 4885.295,
        VE_12: -9998.0,
        LakeFract: 0.02568398498,
        SurfArea: 1163095.3714399999,
        RAreaHLoad: 0.11830905356,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.050886184632759, 35.143817678783137, 0.0],
          [-81.053040317962768, 35.137986078792153, 0.0],
          [-81.051189917965644, 35.136527612127736, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284123.0,
        COMID: 9756598,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 3.371,
        REACHCODE: "03050101000535",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.032348736294741998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061906.0,
        ToNode: 250061908.0,
        Hydroseq: 250024845.0,
        LevelPathI: 250022604.0,
        Pathlength: 487.689,
        TerminalPa: 250002604.0,
        ArbolateSu: 202.038,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250025522.0,
        DnLevelPat: 250022604.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024226.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.7932,
        TotDASqKM: 304.9749,
        DivDASqKM: 304.9749,
        Tidal: 0,
        TOTMA: 0.10474259366200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17755.0,
        MAXELEVSMO: 18185.0,
        MINELEVSMO: 17830.0,
        SLOPE: 0.00105309,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.3710000514984131,
        QA_MA: 127.991,
        VA_MA: 1.22186,
        QC_MA: 128.403,
        VC_MA: 1.2221,
        QE_MA: 128.403,
        VE_MA: 1.2221,
        QA_01: 257.519,
        VA_01: 1.63249,
        QC_01: 201.916,
        VC_01: 1.47037,
        QE_01: 201.916,
        VE_01: 1.47037,
        QA_02: 276.4,
        VA_02: 1.68318,
        QC_02: 231.029,
        VC_02: 1.55652,
        QE_02: 231.029,
        VE_02: 1.55652,
        QA_03: 298.182,
        VA_03: 1.73969,
        QC_03: 251.569,
        VC_03: 1.61427,
        QE_03: 251.569,
        VE_03: 1.61427,
        QA_04: 188.078,
        VA_04: 1.429,
        QC_04: 174.734,
        VC_04: 1.3845,
        QE_04: 174.734,
        VE_04: 1.3845,
        QA_05: 112.981,
        VA_05: 1.16331,
        QC_05: 107.508,
        VC_05: 1.13986,
        QE_05: 107.508,
        VE_05: 1.13986,
        QA_06: 66.841,
        VA_06: 0.95535,
        QC_06: 84.194,
        VC_06: 1.03865,
        QE_06: 84.194,
        VE_06: 1.03865,
        QA_07: 43.436,
        VA_07: 0.823,
        QC_07: 66.205,
        VC_07: 0.95114,
        QE_07: 66.205,
        VE_07: 0.95114,
        QA_08: 30.842,
        VA_08: 0.73745,
        QC_08: 68.526,
        VC_08: 0.96301,
        QE_08: 68.526,
        VE_08: 0.96301,
        QA_09: 26.609,
        VA_09: 0.7051,
        QC_09: 70.879,
        VC_09: 0.97485,
        QE_09: 70.879,
        VE_09: 0.97485,
        QA_10: 22.532,
        VA_10: 0.67157,
        QC_10: 66.227,
        VC_10: 0.95126,
        QE_10: 66.227,
        VE_10: 0.95126,
        QA_11: 50.797,
        VA_11: 0.86761,
        QC_11: 76.445,
        VC_11: 1.00216,
        QE_11: 76.445,
        VE_11: 1.00216,
        QA_12: 162.889,
        VA_12: 1.34663,
        QC_12: 136.035,
        VC_12: 1.25054,
        QE_12: 136.035,
        VE_12: 1.25054,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.016362318019674, 35.351060278461375, 0.0],
          [-81.015700918020741, 35.343936878472618, 0.0],
          [-81.010898318028126, 35.340084878478592, 0.0],
          [-81.020246118013574, 35.330634078493183, 0.0],
          [-81.02231998467704, 35.32607821183359, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286125.0,
        COMID: 9756928,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.085,
        REACHCODE: "03050101002802",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00093430844634099998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062014.0,
        ToNode: 250062015.0,
        Hydroseq: 250007500.0,
        LevelPathI: 250003258.0,
        Pathlength: 457.773,
        TerminalPa: 250002604.0,
        ArbolateSu: 5615.876,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007527.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007466.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 7235.7156,
        DivDASqKM: 7235.7156,
        Tidal: 0,
        TOTMA: 0.02918902708,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17321.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.085000000894070005,
        QA_MA: 3818.594,
        VA_MA: -9998.0,
        QC_MA: 3818.594,
        VC_MA: -9998.0,
        QE_MA: 4068.321,
        VE_MA: -9998.0,
        QA_01: 6701.137,
        VA_01: -9998.0,
        QC_01: 6701.137,
        VC_01: -9998.0,
        QE_01: 6862.771,
        VE_01: -9998.0,
        QA_02: 7094.042,
        VA_02: -9998.0,
        QC_02: 7094.042,
        VC_02: -9998.0,
        QE_02: 7109.154,
        VE_02: -9998.0,
        QA_03: 7843.096,
        VA_03: -9998.0,
        QC_03: 7843.096,
        VC_03: -9998.0,
        QE_03: 8066.346,
        VE_03: -9998.0,
        QA_04: 5640.527,
        VA_04: -9998.0,
        QC_04: 5640.527,
        VC_04: -9998.0,
        QE_04: 5807.034,
        VE_04: -9998.0,
        QA_05: 4026.276,
        VA_05: -9998.0,
        QC_05: 4026.276,
        VC_05: -9998.0,
        QE_05: 4345.373,
        VE_05: -9998.0,
        QA_06: 2352.385,
        VA_06: -9998.0,
        QC_06: 2352.385,
        VC_06: -9998.0,
        QE_06: 2699.004,
        VE_06: -9998.0,
        QA_07: 1423.226,
        VA_07: -9998.0,
        QC_07: 1423.226,
        VC_07: -9998.0,
        QE_07: 1677.407,
        VE_07: -9998.0,
        QA_08: 1001.889,
        VA_08: -9998.0,
        QC_08: 1001.889,
        VC_08: -9998.0,
        QE_08: 1410.108,
        VE_08: -9998.0,
        QA_09: 929.429,
        VA_09: -9998.0,
        QC_09: 929.429,
        VC_09: -9998.0,
        QE_09: 1048.283,
        VE_09: -9998.0,
        QA_10: 1070.315,
        VA_10: -9998.0,
        QC_10: 1070.315,
        VC_10: -9998.0,
        QE_10: 1342.98,
        VE_10: -9998.0,
        QA_11: 2883.521,
        VA_11: -9998.0,
        QC_11: 2883.521,
        VC_11: -9998.0,
        QE_11: 3045.213,
        VE_11: -9998.0,
        QA_12: 4883.401,
        VA_12: -9998.0,
        QC_12: 4883.401,
        VC_12: -9998.0,
        QE_12: 4885.305,
        VE_12: -9998.0,
        LakeFract: 0.00228123623,
        SurfArea: 103305.43731199999,
        RAreaHLoad: 0.01050811867,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.051189917965644, 35.136527612127736, 0.0],
          [-81.050263184633707, 35.136408878794668, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284124.0,
        COMID: 9756594,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 4.244,
        REACHCODE: "03050101000536",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.041707871900530998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061904.0,
        ToNode: 250061906.0,
        Hydroseq: 250025522.0,
        LevelPathI: 250022604.0,
        Pathlength: 491.06,
        TerminalPa: 250002604.0,
        ArbolateSu: 196.79,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250026264.0,
        DnLevelPat: 250022604.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024845.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.8219,
        TotDASqKM: 294.2208,
        DivDASqKM: 294.2208,
        Tidal: 0,
        TOTMA: 0.139109932267,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 18032.0,
        MAXELEVSMO: 18483.0,
        MINELEVSMO: 18185.0,
        SLOPE: 0.00070216,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.2439999580383301,
        QA_MA: 124.127,
        VA_MA: 1.15817,
        QC_MA: 124.675,
        VC_MA: 1.15848,
        QE_MA: 124.675,
        VE_MA: 1.15848,
        QA_01: 249.681,
        VA_01: 1.54001,
        QC_01: 195.929,
        VC_01: 1.3891,
        QE_01: 195.929,
        VE_01: 1.3891,
        QA_02: 267.281,
        VA_02: 1.58537,
        QC_02: 223.611,
        VC_02: 1.46766,
        QE_02: 223.611,
        VE_02: 1.46766,
        QA_03: 288.329,
        VA_03: 1.63782,
        QC_03: 243.526,
        VC_03: 1.52138,
        QE_03: 243.526,
        VE_03: 1.52138,
        QA_04: 182.362,
        VA_04: 1.35076,
        QC_04: 169.543,
        VC_04: 1.3092,
        QE_04: 169.543,
        VE_04: 1.3092,
        QA_05: 109.665,
        VA_05: 1.10408,
        QC_05: 104.469,
        VC_05: 1.08229,
        QE_05: 104.469,
        VE_05: 1.08229,
        QA_06: 64.816,
        VA_06: 0.91021,
        QC_06: 81.679,
        VC_06: 0.98749,
        QE_06: 81.679,
        VE_06: 0.98749,
        QA_07: 42.126,
        VA_07: 0.78713,
        QC_07: 64.254,
        VC_07: 0.90625,
        QE_07: 64.254,
        VE_07: 0.90625,
        QA_08: 29.83,
        VA_08: 0.70696,
        QC_08: 66.233,
        VC_08: 0.91595,
        QE_08: 66.233,
        VE_08: 0.91595,
        QA_09: 25.742,
        VA_09: 0.67695,
        QC_09: 68.835,
        VC_09: 0.92851,
        QE_09: 68.835,
        VE_09: 0.92851,
        QA_10: 21.809,
        VA_10: 0.64589,
        QC_10: 64.524,
        VC_10: 0.90758,
        QE_10: 64.524,
        VE_10: 0.90758,
        QA_11: 50.074,
        VA_11: 0.83315,
        QC_11: 75.614,
        VC_11: 0.96023,
        QE_11: 75.614,
        VE_11: 0.96023,
        QA_12: 159.011,
        VA_12: 1.27761,
        QC_12: 133.085,
        VC_12: 1.18846,
        QE_12: 133.085,
        VE_12: 1.18846,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.035956517989348, 35.379017878418097, 0.0],
          [-81.031289184663137, 35.376586678421745, 0.0],
          [-81.033364117993358, 35.371572478429641, 0.0],
          [-81.031494784662925, 35.36788381176865, 0.0],
          [-81.032283317995052, 35.362891411776388, 0.0],
          [-81.021356918011975, 35.358508211783203, 0.0],
          [-81.016362318019674, 35.351060278461375, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286126.0,
        COMID: 9756930,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.47,
        REACHCODE: "03050101002803",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0046025926873519999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062015.0,
        ToNode: 250062018.0,
        Hydroseq: 250007466.0,
        LevelPathI: 250003258.0,
        Pathlength: 457.303,
        TerminalPa: 250002604.0,
        ArbolateSu: 5616.346,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007500.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007433.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7002,
        TotDASqKM: 7236.4158,
        DivDASqKM: 7236.4158,
        Tidal: 0,
        TOTMA: 0.16139814962,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17293.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.46999999880790699,
        QA_MA: 3818.87,
        VA_MA: -9998.0,
        QC_MA: 3818.87,
        VC_MA: -9998.0,
        QE_MA: 4068.597,
        VE_MA: -9998.0,
        QA_01: 6701.724,
        VA_01: -9998.0,
        QC_01: 6701.724,
        VC_01: -9998.0,
        QE_01: 6863.355,
        VE_01: -9998.0,
        QA_02: 7094.674,
        VA_02: -9998.0,
        QC_02: 7094.674,
        VC_02: -9998.0,
        QE_02: 7109.783,
        VE_02: -9998.0,
        QA_03: 7843.776,
        VA_03: -9998.0,
        QC_03: 7843.776,
        VC_03: -9998.0,
        QE_03: 8067.022,
        VE_03: -9998.0,
        QA_04: 5640.926,
        VA_04: -9998.0,
        QC_04: 5640.926,
        VC_04: -9998.0,
        QE_04: 5807.431,
        VE_04: -9998.0,
        QA_05: 4026.502,
        VA_05: -9998.0,
        QC_05: 4026.502,
        VC_05: -9998.0,
        QE_05: 4345.599,
        VE_05: -9998.0,
        QA_06: 2352.527,
        VA_06: -9998.0,
        QC_06: 2352.527,
        VC_06: -9998.0,
        QE_06: 2699.148,
        VE_06: -9998.0,
        QA_07: 1423.32,
        VA_07: -9998.0,
        QC_07: 1423.32,
        VC_07: -9998.0,
        QE_07: 1677.504,
        VE_07: -9998.0,
        QA_08: 1001.957,
        VA_08: -9998.0,
        QC_08: 1001.957,
        VC_08: -9998.0,
        QE_08: 1410.179,
        VE_08: -9998.0,
        QA_09: 929.495,
        VA_09: -9998.0,
        QC_09: 929.495,
        VC_09: -9998.0,
        QE_09: 1048.351,
        VE_09: -9998.0,
        QA_10: 1070.362,
        VA_10: -9998.0,
        QC_10: 1070.362,
        VC_10: -9998.0,
        QE_10: 1343.031,
        VE_10: -9998.0,
        QA_11: 2883.569,
        VA_11: -9998.0,
        QC_11: 2883.569,
        VC_11: -9998.0,
        QE_11: 3045.263,
        VE_11: -9998.0,
        QA_12: 4883.733,
        VA_12: -9998.0,
        QC_12: 4883.733,
        VC_12: -9998.0,
        QE_12: 4885.636,
        VE_12: -9998.0,
        LakeFract: 0.01261476084,
        SurfArea: 571257.53485199995,
        RAreaHLoad: 0.05810371493,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.050263184633707, 35.136408878794668, 0.0],
          [-81.047098184638628, 35.133153278799739, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286119.0,
        COMID: 9756916,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.442,
        REACHCODE: "03050101002796",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0045960005898940002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062008.0,
        ToNode: 250062007.0,
        Hydroseq: 250007610.0,
        LevelPathI: 250003258.0,
        Pathlength: 460.904,
        TerminalPa: 250002604.0,
        ArbolateSu: 4383.682,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007644.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007582.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3708,
        TotDASqKM: 5434.6113,
        DivDASqKM: 5434.6113,
        Tidal: 0,
        TOTMA: 0.15178294063,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17185.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.44200000166893,
        QA_MA: 2935.644,
        VA_MA: -9998.0,
        QC_MA: 2935.644,
        VC_MA: -9998.0,
        QE_MA: 3131.553,
        VE_MA: -9998.0,
        QA_01: 5026.474,
        VA_01: -9998.0,
        QC_01: 5026.474,
        VC_01: -9998.0,
        QE_01: 5209.067,
        VE_01: -9998.0,
        QA_02: 5339.173,
        VA_02: -9998.0,
        QC_02: 5339.173,
        VC_02: -9998.0,
        QE_02: 5382.232,
        VE_02: -9998.0,
        QA_03: 5956.316,
        VA_03: -9998.0,
        QC_03: 5956.316,
        VC_03: -9998.0,
        QE_03: 6181.246,
        VE_03: -9998.0,
        QA_04: 4373.065,
        VA_04: -9998.0,
        QC_04: 4373.065,
        VC_04: -9998.0,
        QE_04: 4549.82,
        VE_04: -9998.0,
        QA_05: 3157.458,
        VA_05: -9998.0,
        QC_05: 3157.458,
        VC_05: -9998.0,
        QE_05: 3384.195,
        VE_05: -9998.0,
        QA_06: 1849.962,
        VA_06: -9998.0,
        QC_06: 1849.962,
        VC_06: -9998.0,
        QE_06: 2134.891,
        VE_06: -9998.0,
        QA_07: 1114.333,
        VA_07: -9998.0,
        QC_07: 1114.333,
        VC_07: -9998.0,
        QE_07: 1285.309,
        VE_07: -9998.0,
        QA_08: 783.001,
        VA_08: -9998.0,
        QC_08: 783.001,
        VC_08: -9998.0,
        QE_08: 1110.951,
        VE_08: -9998.0,
        QA_09: 741.16,
        VA_09: -9998.0,
        QC_09: 1337.192,
        VC_09: -9998.0,
        QE_09: 1389.111,
        VE_09: -9998.0,
        QA_10: 870.967,
        VA_10: -9998.0,
        QC_10: 870.967,
        VC_10: -9998.0,
        QE_10: 845.777,
        VE_10: -9998.0,
        QA_11: 2318.372,
        VA_11: -9998.0,
        QC_11: 2318.372,
        VC_11: -9998.0,
        QE_11: 2273.813,
        VE_11: -9998.0,
        QA_12: 3717.373,
        VA_12: -9998.0,
        QC_12: 3717.373,
        VC_12: -9998.0,
        QE_12: 3713.211,
        VE_12: -9998.0,
        LakeFract: 0.00925812528,
        SurfArea: 419252.80168899999,
        RAreaHLoad: 0.05464221699,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.028308318001166, 35.148818012108677, 0.0],
          [-81.032200384661806, 35.150788278772268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286120.0,
        COMID: 9756918,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.08,
        REACHCODE: "03050101002797",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00075573216774699995,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062009.0,
        ToNode: 250062008.0,
        Hydroseq: 250007644.0,
        LevelPathI: 250003258.0,
        Pathlength: 461.346,
        TerminalPa: 250002604.0,
        ArbolateSu: 4383.24,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007675.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007610.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0378,
        TotDASqKM: 5434.2405,
        DivDASqKM: 5434.2405,
        Tidal: 0,
        TOTMA: 0.0274720255,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17188.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.079999998211861004,
        QA_MA: 2935.499,
        VA_MA: -9998.0,
        QC_MA: 2935.499,
        VC_MA: -9998.0,
        QE_MA: 3131.408,
        VE_MA: -9998.0,
        QA_01: 5026.165,
        VA_01: -9998.0,
        QC_01: 5026.165,
        VC_01: -9998.0,
        QE_01: 5208.759,
        VE_01: -9998.0,
        QA_02: 5338.842,
        VA_02: -9998.0,
        QC_02: 5338.842,
        VC_02: -9998.0,
        QE_02: 5381.903,
        VE_02: -9998.0,
        QA_03: 5955.959,
        VA_03: -9998.0,
        QC_03: 5955.959,
        VC_03: -9998.0,
        QE_03: 6180.891,
        VE_03: -9998.0,
        QA_04: 4372.854,
        VA_04: -9998.0,
        QC_04: 4372.854,
        VC_04: -9998.0,
        QE_04: 4549.61,
        VE_04: -9998.0,
        QA_05: 3157.34,
        VA_05: -9998.0,
        QC_05: 3157.34,
        VC_05: -9998.0,
        QE_05: 3384.077,
        VE_05: -9998.0,
        QA_06: 1849.889,
        VA_06: -9998.0,
        QC_06: 1849.889,
        VC_06: -9998.0,
        QE_06: 2134.817,
        VE_06: -9998.0,
        QA_07: 1114.283,
        VA_07: -9998.0,
        QC_07: 1114.283,
        VC_07: -9998.0,
        QE_07: 1285.258,
        VE_07: -9998.0,
        QA_08: 782.966,
        VA_08: -9998.0,
        QC_08: 782.966,
        VC_08: -9998.0,
        QE_08: 1110.915,
        VE_08: -9998.0,
        QA_09: 741.13,
        VA_09: -9998.0,
        QC_09: 1337.145,
        VC_09: -9998.0,
        QE_09: 1389.062,
        VE_09: -9998.0,
        QA_10: 870.942,
        VA_10: -9998.0,
        QC_10: 870.942,
        VC_10: -9998.0,
        QE_10: 845.75,
        VE_10: -9998.0,
        QA_11: 2318.343,
        VA_11: -9998.0,
        QC_11: 2318.343,
        VC_11: -9998.0,
        QE_11: 2273.784,
        VE_11: -9998.0,
        QA_12: 3717.202,
        VA_12: -9998.0,
        QC_12: 3717.202,
        VC_12: -9998.0,
        QE_12: 3713.04,
        VE_12: -9998.0,
        LakeFract: 0.00167560131,
        SurfArea: 75879.351648900003,
        RAreaHLoad: 0.00988999404,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.027911118001782, 35.148175078776319, 0.0],
          [-81.028308318001166, 35.148818012108677, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284119.0,
        COMID: 9756612,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 3.291,
        REACHCODE: "03050101000532",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.032939318762234999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061910.0,
        ToNode: 250061983.0,
        Hydroseq: 250023103.0,
        LevelPathI: 250022604.0,
        Pathlength: 481.311,
        TerminalPa: 250002604.0,
        ArbolateSu: 237.099,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250023643.0,
        DnLevelPat: 250022604.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022604.0,
        FromMeas: 2.16552,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.952,
        TotDASqKM: 365.9661,
        DivDASqKM: 365.9661,
        Tidal: 0,
        TOTMA: 0.109546190101,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17258.0,
        MAXELEVSMO: 17471.0,
        MINELEVSMO: 17321.0,
        SLOPE: 0.00045578,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.2909998893737789,
        QA_MA: 150.917,
        VA_MA: 1.14099,
        QC_MA: 150.438,
        VC_MA: 1.14078,
        QE_MA: 150.438,
        VE_MA: 1.14078,
        QA_01: 305.295,
        VA_01: 1.51875,
        QC_01: 238.311,
        VC_01: 1.37109,
        QE_01: 238.311,
        VE_01: 1.37109,
        QA_02: 329.743,
        VA_02: 1.56928,
        QC_02: 274.292,
        VC_02: 1.45348,
        QE_02: 274.292,
        VE_02: 1.45348,
        QA_03: 355.086,
        VA_03: 1.61985,
        QC_03: 297.854,
        VC_03: 1.5047,
        QE_03: 297.854,
        VE_03: 1.5047,
        QA_04: 221.327,
        VA_04: 1.32872,
        QC_04: 204.866,
        VC_04: 1.2891,
        QE_04: 204.866,
        VE_04: 1.2891,
        QA_05: 132.104,
        VA_05: 1.08416,
        QC_05: 124.971,
        VC_05: 1.06271,
        QE_05: 124.971,
        VE_05: 1.06271,
        QA_06: 78.449,
        VA_06: 0.89663,
        QC_06: 98.599,
        VC_06: 0.9735,
        QE_06: 98.599,
        VE_06: 0.9735,
        QA_07: 51.103,
        VA_07: 0.77689,
        QC_07: 77.588,
        VC_07: 0.89403,
        QE_07: 77.588,
        VE_07: 0.89403,
        QA_08: 36.582,
        VA_08: 0.70069,
        QC_08: 81.558,
        VC_08: 0.90975,
        QE_08: 81.558,
        VE_08: 0.90975,
        QA_09: 31.529,
        VA_09: 0.67092,
        QC_09: 82.333,
        VC_09: 0.91278,
        QE_09: 82.333,
        VE_09: 0.91278,
        QA_10: 26.633,
        VA_10: 0.63984,
        QC_10: 75.69,
        VC_10: 0.88637,
        QE_10: 75.69,
        VE_10: 0.88637,
        QA_11: 55.199,
        VA_11: 0.79647,
        QC_11: 81.448,
        VC_11: 0.90932,
        QE_11: 81.448,
        VE_11: 0.90932,
        QA_12: 189.382,
        VA_12: 1.24767,
        QC_12: 156.026,
        VC_12: 1.15706,
        QE_12: 156.026,
        VE_12: 1.15706,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.020638384679728, 35.30775407852866, 0.0],
          [-81.012158318026252, 35.297829611877489, 0.0],
          [-81.011372584694129, 35.300416878540091, 0.0],
          [-81.013661918023899, 35.301564078538377, 0.0],
          [-81.013157518024627, 35.302640078536626, 0.0],
          [-81.005669184702924, 35.30315987853578, 0.0],
          [-81.003270918039959, 35.300248678540356, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286121.0,
        COMID: 9756920,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.478,
        REACHCODE: "03050101002798",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015199420297194999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062010.0,
        ToNode: 250062009.0,
        Hydroseq: 250007675.0,
        LevelPathI: 250003258.0,
        Pathlength: 461.426,
        TerminalPa: 250002604.0,
        ArbolateSu: 4383.16,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007708.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007644.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.9898,
        TotDASqKM: 5434.2027,
        DivDASqKM: 5434.2027,
        Tidal: 0,
        TOTMA: 0.50754567031999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17185.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4780000448226931,
        QA_MA: 2935.485,
        VA_MA: -9998.0,
        QC_MA: 2935.485,
        VC_MA: -9998.0,
        QE_MA: 3131.393,
        VE_MA: -9998.0,
        QA_01: 5026.134,
        VA_01: -9998.0,
        QC_01: 5026.134,
        VC_01: -9998.0,
        QE_01: 5208.728,
        VE_01: -9998.0,
        QA_02: 5338.809,
        VA_02: -9998.0,
        QC_02: 5338.809,
        VC_02: -9998.0,
        QE_02: 5381.87,
        VE_02: -9998.0,
        QA_03: 5955.923,
        VA_03: -9998.0,
        QC_03: 5955.923,
        VC_03: -9998.0,
        QE_03: 6180.855,
        VE_03: -9998.0,
        QA_04: 4372.832,
        VA_04: -9998.0,
        QC_04: 4372.832,
        VC_04: -9998.0,
        QE_04: 4549.589,
        VE_04: -9998.0,
        QA_05: 3157.328,
        VA_05: -9998.0,
        QC_05: 3157.328,
        VC_05: -9998.0,
        QE_05: 3384.065,
        VE_05: -9998.0,
        QA_06: 1849.882,
        VA_06: -9998.0,
        QC_06: 1849.882,
        VC_06: -9998.0,
        QE_06: 2134.809,
        VE_06: -9998.0,
        QA_07: 1114.278,
        VA_07: -9998.0,
        QC_07: 1114.278,
        VC_07: -9998.0,
        QE_07: 1285.253,
        VE_07: -9998.0,
        QA_08: 782.962,
        VA_08: -9998.0,
        QC_08: 782.962,
        VC_08: -9998.0,
        QE_08: 1110.911,
        VE_08: -9998.0,
        QA_09: 741.127,
        VA_09: -9998.0,
        QC_09: 1337.14,
        VC_09: -9998.0,
        QE_09: 1389.057,
        VE_09: -9998.0,
        QA_10: 870.939,
        VA_10: -9998.0,
        QC_10: 870.939,
        VC_10: -9998.0,
        QE_10: 845.747,
        VE_10: -9998.0,
        QA_11: 2318.341,
        VA_11: -9998.0,
        QC_11: 2318.341,
        VC_11: -9998.0,
        QE_11: 2273.781,
        VE_11: -9998.0,
        QA_12: 3717.184,
        VA_12: -9998.0,
        QC_12: 3717.184,
        VC_12: -9998.0,
        QE_12: 3713.022,
        VE_12: -9998.0,
        LakeFract: 0.03095659595,
        SurfArea: 1401864.76098,
        RAreaHLoad: 0.18271763965000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.015416384687853, 35.1534536121016, 0.0],
          [-81.023054318009315, 35.147715078777139, 0.0],
          [-81.027911118001782, 35.148175078776319, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284120.0,
        COMID: 9756866,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.073,
        REACHCODE: "03050101000532",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049434,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000722377028308,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061983.0,
        ToNode: 250061984.0,
        Hydroseq: 250022604.0,
        LevelPathI: 250022604.0,
        Pathlength: 481.238,
        TerminalPa: 250002604.0,
        ArbolateSu: 237.172,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250023103.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008111.0,
        FromMeas: 0.0,
        ToMeas: 2.16552,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 365.9742,
        DivDASqKM: 365.9742,
        Tidal: 0,
        TOTMA: 0.0036382805850900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17263.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.072999998927116005,
        QA_MA: 150.92,
        VA_MA: 0.76201,
        QC_MA: 150.44,
        VC_MA: 0.7619,
        QE_MA: 150.44,
        VE_MA: 0.7619,
        QA_01: 305.3,
        VA_01: 0.96782,
        QC_01: 238.316,
        VC_01: 0.88738,
        QE_01: 238.316,
        VE_01: 0.88738,
        QA_02: 329.75,
        VA_02: 0.99536,
        QC_02: 274.298,
        VC_02: 0.93227,
        QE_02: 274.298,
        VE_02: 0.93227,
        QA_03: 355.093,
        VA_03: 1.02291,
        QC_03: 297.86,
        VC_03: 0.96017,
        QE_03: 297.86,
        VE_03: 0.96017,
        QA_04: 221.331,
        VA_04: 0.86429,
        QC_04: 204.869,
        VC_04: 0.84271,
        QE_04: 204.869,
        VE_04: 0.84271,
        QA_05: 132.106,
        VA_05: 0.73105,
        QC_05: 124.974,
        VC_05: 0.71936,
        QE_05: 124.974,
        VE_05: 0.71936,
        QA_06: 78.45,
        VA_06: 0.62888,
        QC_06: 98.601,
        VC_06: 0.67076,
        QE_06: 98.601,
        VE_06: 0.67076,
        QA_07: 51.104,
        VA_07: 0.56364,
        QC_07: 77.59,
        VC_07: 0.62746,
        QE_07: 77.59,
        VE_07: 0.62746,
        QA_08: 36.583,
        VA_08: 0.52213,
        QC_08: 81.56,
        VC_08: 0.63603,
        QE_08: 81.56,
        VE_08: 0.63603,
        QA_09: 31.53,
        VA_09: 0.50591,
        QC_09: 82.335,
        VC_09: 0.63768,
        QE_09: 82.335,
        VE_09: 0.63768,
        QA_10: 26.633,
        VA_10: 0.48897,
        QC_10: 75.691,
        VC_10: 0.62329,
        QE_10: 75.691,
        VE_10: 0.62329,
        QA_11: 55.2,
        VA_11: 0.57431,
        QC_11: 81.449,
        VC_11: 0.63579,
        QE_11: 81.449,
        VE_11: 0.63579,
        QA_12: 189.385,
        VA_12: 0.82013,
        QC_12: 156.028,
        VC_12: 0.77076,
        QE_12: 156.028,
        VE_12: 0.77076,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.003270918039959, 35.300248678540356, 0.0],
          [-81.002749318040856, 35.299764611874366, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286122.0,
        COMID: 9756922,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.029,
        REACHCODE: "03050101002799",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.021193129173823998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062011.0,
        ToNode: 250062013.0,
        Hydroseq: 250007553.0,
        LevelPathI: 250003258.0,
        Pathlength: 458.815,
        TerminalPa: 250002604.0,
        ArbolateSu: 5576.818,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007582.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007527.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.0195,
        TotDASqKM: 7149.0492,
        DivDASqKM: 7149.0492,
        Tidal: 0,
        TOTMA: 0.69675924567000003,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17063.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.029000043869019,
        QA_MA: 3784.354,
        VA_MA: -9998.0,
        QC_MA: 3784.354,
        VC_MA: -9998.0,
        QE_MA: 4034.038,
        VE_MA: -9998.0,
        QA_01: 6627.465,
        VA_01: -9998.0,
        QC_01: 6627.465,
        VC_01: -9998.0,
        QE_01: 6789.384,
        VE_01: -9998.0,
        QA_02: 7015.632,
        VA_02: -9998.0,
        QC_02: 7015.632,
        VC_02: -9998.0,
        QE_02: 7031.08,
        VE_02: -9998.0,
        QA_03: 7760.355,
        VA_03: -9998.0,
        QC_03: 7760.355,
        VC_03: -9998.0,
        QE_03: 7984.082,
        VE_03: -9998.0,
        QA_04: 5592.813,
        VA_04: -9998.0,
        QC_04: 5592.813,
        VC_04: -9998.0,
        QE_04: 5759.459,
        VE_04: -9998.0,
        QA_05: 3999.003,
        VA_05: -9998.0,
        QC_05: 3999.003,
        VC_05: -9998.0,
        QE_05: 4318.06,
        VE_05: -9998.0,
        QA_06: 2335.891,
        VA_06: -9998.0,
        QC_06: 2335.891,
        VC_06: -9998.0,
        QE_06: 2682.175,
        VE_06: -9998.0,
        QA_07: 1412.149,
        VA_07: -9998.0,
        QC_07: 1412.149,
        VC_07: -9998.0,
        QE_07: 1666.006,
        VE_07: -9998.0,
        QA_08: 993.651,
        VA_08: -9998.0,
        QC_08: 993.651,
        VC_08: -9998.0,
        QE_08: 1401.469,
        VE_08: -9998.0,
        QA_09: 921.735,
        VA_09: -9998.0,
        QC_09: 921.735,
        VC_09: -9998.0,
        QE_09: 1040.275,
        VE_09: -9998.0,
        QA_10: 1064.489,
        VA_10: -9998.0,
        QC_10: 1064.489,
        VC_10: -9998.0,
        QE_10: 1336.7,
        VE_10: -9998.0,
        QA_11: 2875.963,
        VA_11: -9998.0,
        QC_11: 2875.963,
        VC_11: -9998.0,
        QE_11: 3037.534,
        VE_11: -9998.0,
        QA_12: 4839.004,
        VA_12: -9998.0,
        QC_12: 4839.004,
        VC_12: -9998.0,
        QE_12: 4841.08,
        VE_12: -9998.0,
        LakeFract: 0.05398985685,
        SurfArea: 2444922.49376,
        RAreaHLoad: 0.25083497352,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.032856384660818, 35.150872412105628, 0.0],
          [-81.042601917979042, 35.150899878772236, 0.0],
          [-81.050886184632759, 35.143817678783137, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284121.0,
        COMID: 9756608,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 1.969,
        REACHCODE: "03050101000533",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018700219026883999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061909.0,
        ToNode: 250061910.0,
        Hydroseq: 250023643.0,
        LevelPathI: 250022604.0,
        Pathlength: 484.602,
        TerminalPa: 250002604.0,
        ArbolateSu: 226.184,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250024226.0,
        DnLevelPat: 250022604.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023103.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9936,
        TotDASqKM: 347.2362,
        DivDASqKM: 347.2362,
        Tidal: 0,
        TOTMA: 0.056809573930600002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17406.0,
        MAXELEVSMO: 17792.0,
        MINELEVSMO: 17471.0,
        SLOPE: 0.00163026,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.968999981880188,
        QA_MA: 143.962,
        VA_MA: 1.31623,
        QC_MA: 143.768,
        VC_MA: 1.31612,
        QE_MA: 143.768,
        VE_MA: 1.31612,
        QA_01: 290.704,
        VA_01: 1.77209,
        QC_01: 227.214,
        VC_01: 1.59336,
        QE_01: 227.214,
        VE_01: 1.59336,
        QA_02: 313.477,
        VA_02: 1.8319,
        QC_02: 261.122,
        VC_02: 1.69185,
        QE_02: 261.122,
        VE_02: 1.69185,
        QA_03: 337.747,
        VA_03: 1.89344,
        QC_03: 283.779,
        VC_03: 1.75435,
        QE_03: 283.779,
        VE_03: 1.75435,
        QA_04: 211.302,
        VA_04: 1.54401,
        QC_04: 195.792,
        VC_04: 1.49571,
        QE_04: 195.792,
        VE_04: 1.49571,
        QA_05: 126.343,
        VA_05: 1.24873,
        QC_05: 119.721,
        VC_05: 1.22278,
        QE_05: 119.721,
        VE_05: 1.22278,
        QA_06: 74.923,
        VA_06: 1.02089,
        QC_06: 94.228,
        VC_06: 1.11361,
        QE_06: 94.228,
        VE_06: 1.11361,
        QA_07: 48.837,
        VA_07: 0.87605,
        QC_07: 74.228,
        VC_07: 1.0178,
        QE_07: 74.228,
        VE_07: 1.0178,
        QA_08: 34.82,
        VA_08: 0.78271,
        QC_08: 77.551,
        VC_08: 1.03449,
        QE_08: 77.551,
        VE_08: 1.03449,
        QA_09: 30.018,
        VA_09: 0.74677,
        QC_09: 78.84,
        VC_09: 1.04087,
        QE_09: 78.84,
        VE_09: 1.04087,
        QA_10: 25.373,
        VA_10: 0.70933,
        QC_10: 72.817,
        VC_10: 1.01061,
        QE_10: 72.817,
        VE_10: 1.01061,
        QA_11: 53.94,
        VA_11: 0.90681,
        QC_11: 80.027,
        VC_11: 1.04671,
        QE_11: 80.027,
        VE_11: 1.04671,
        QA_12: 181.455,
        VA_12: 1.44803,
        QC_12: 150.072,
        VC_12: 1.33935,
        QE_12: 150.072,
        VE_12: 1.33935,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.025402784672337, 35.318820411844854, 0.0],
          [-81.024903518006454, 35.315842478516117, 0.0],
          [-81.022586584676731, 35.313962611852332, 0.0],
          [-81.024184384674186, 35.309703878525681, 0.0],
          [-81.022676318009928, 35.309175811859689, 0.0],
          [-81.021472918011796, 35.310823878523991, 0.0],
          [-81.020638384679728, 35.30775407852866, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284122.0,
        COMID: 9756602,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "984457",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 1.118,
        REACHCODE: "03050101000534",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010883084592191001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061908.0,
        ToNode: 250061909.0,
        Hydroseq: 250024226.0,
        LevelPathI: 250022604.0,
        Pathlength: 486.571,
        TerminalPa: 250002604.0,
        ArbolateSu: 217.608,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022604.0,
        UpHydroseq: 250024845.0,
        DnLevelPat: 250022604.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023643.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6407,
        TotDASqKM: 334.0125,
        DivDASqKM: 334.0125,
        Tidal: 0,
        TOTMA: 0.039050953607999997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17656.0,
        MAXELEVSMO: 17830.0,
        MINELEVSMO: 17792.0,
        SLOPE: 0.00033989,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1180000305175779,
        QA_MA: 139.026,
        VA_MA: 1.08713,
        QC_MA: 139.026,
        VC_MA: 1.08713,
        QE_MA: 139.026,
        VE_MA: 1.08713,
        QA_01: 280.435,
        VA_01: 1.43872,
        QC_01: 219.394,
        VC_01: 1.30058,
        QE_01: 219.394,
        VE_01: 1.30058,
        QA_02: 301.954,
        VA_02: 1.48397,
        QC_02: 251.78,
        VC_02: 1.37584,
        QE_02: 251.78,
        VE_02: 1.37584,
        QA_03: 325.451,
        VA_03: 1.53169,
        QC_03: 273.783,
        VC_03: 1.4244,
        QE_03: 273.783,
        VE_03: 1.4244,
        QA_04: 204.154,
        VA_04: 1.26337,
        QC_04: 189.317,
        VC_04: 1.22586,
        QE_04: 189.317,
        VE_04: 1.22586,
        QA_05: 122.219,
        VA_05: 1.03563,
        QC_05: 115.957,
        VC_05: 1.0156,
        QE_05: 115.957,
        VE_05: 1.0156,
        QA_06: 72.434,
        VA_06: 0.85924,
        QC_06: 91.139,
        VC_06: 0.9307,
        QE_06: 91.139,
        VE_06: 0.9307,
        QA_07: 47.186,
        VA_07: 0.74712,
        QC_07: 71.778,
        VC_07: 0.85658,
        QE_07: 71.778,
        VE_07: 0.85658,
        QA_08: 33.575,
        VA_08: 0.67459,
        QC_08: 74.725,
        VC_08: 0.86842,
        QE_08: 74.725,
        VE_08: 0.86842,
        QA_09: 28.952,
        VA_09: 0.64689,
        QC_09: 76.361,
        VC_09: 0.8749,
        QE_09: 76.361,
        VE_09: 0.8749,
        QA_10: 24.484,
        VA_10: 0.61806,
        QC_10: 70.772,
        VC_10: 0.85249,
        QE_10: 70.772,
        VE_10: 0.85249,
        QA_11: 53.041,
        VA_11: 0.77523,
        QC_11: 79.007,
        VC_11: 0.88524,
        QE_11: 79.007,
        VE_11: 0.88524,
        QA_12: 175.831,
        VA_12: 1.19056,
        QC_12: 145.835,
        VC_12: 1.10715,
        QE_12: 145.835,
        VE_12: 1.10715,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.02231998467704, 35.32607821183359, 0.0],
          [-81.023917784674666, 35.322117211839782, 0.0],
          [-81.022328118010435, 35.319596478510334, 0.0],
          [-81.025402784672337, 35.318820411844854, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286132.0,
        COMID: 9756942,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 6.295,
        REACHCODE: "03050101002809",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.061236926691354998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062021.0,
        ToNode: 250062026.0,
        Hydroseq: 250007376.0,
        LevelPathI: 250003258.0,
        Pathlength: 450.247,
        TerminalPa: 250002604.0,
        ArbolateSu: 5635.789,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007406.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007348.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 20.4876,
        TotDASqKM: 7282.7775,
        DivDASqKM: 7282.7775,
        Tidal: 0,
        TOTMA: 2.1617050033899998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17065.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 6.2950000762939453,
        QA_MA: 3837.662,
        VA_MA: -9998.0,
        QC_MA: 3837.662,
        VC_MA: -9998.0,
        QE_MA: 4087.413,
        VE_MA: -9998.0,
        QA_01: 6741.52,
        VA_01: -9998.0,
        QC_01: 6741.52,
        VC_01: -9998.0,
        QE_01: 6902.999,
        VE_01: -9998.0,
        QA_02: 7137.102,
        VA_02: -9998.0,
        QC_02: 7137.102,
        VC_02: -9998.0,
        QE_02: 7152.032,
        VE_02: -9998.0,
        QA_03: 7889.425,
        VA_03: -9998.0,
        QC_03: 7889.425,
        VC_03: -9998.0,
        QE_03: 8112.416,
        VE_03: -9998.0,
        QA_04: 5667.887,
        VA_04: -9998.0,
        QC_04: 5667.887,
        VC_04: -9998.0,
        QE_04: 5834.318,
        VE_04: -9998.0,
        QA_05: 4041.64,
        VA_05: -9998.0,
        QC_05: 4041.64,
        VC_05: -9998.0,
        QE_05: 4360.758,
        VE_05: -9998.0,
        QA_06: 2361.88,
        VA_06: -9998.0,
        QC_06: 2361.88,
        VC_06: -9998.0,
        QE_06: 2708.68,
        VE_06: -9998.0,
        QA_07: 1429.614,
        VA_07: -9998.0,
        QC_07: 1429.614,
        VC_07: -9998.0,
        QE_07: 1683.971,
        VE_07: -9998.0,
        QA_08: 1006.858,
        VA_08: -9998.0,
        QC_08: 1006.858,
        VC_08: -9998.0,
        QE_08: 1415.295,
        VE_08: -9998.0,
        QA_09: 933.856,
        VA_09: -9998.0,
        QC_09: 933.856,
        VC_09: -9998.0,
        QE_09: 1052.881,
        VE_09: -9998.0,
        QA_10: 1073.479,
        VA_10: -9998.0,
        QC_10: 1073.479,
        VC_10: -9998.0,
        QE_10: 1346.39,
        VE_10: -9998.0,
        QA_11: 2888.024,
        VA_11: -9998.0,
        QC_11: 2888.024,
        VC_11: -9998.0,
        QE_11: 3049.782,
        VE_11: -9998.0,
        QA_12: 4907.03,
        VA_12: -9998.0,
        QC_12: 4907.03,
        VC_12: -9998.0,
        QE_12: 4908.841,
        VE_12: -9998.0,
        LakeFract: 0.16974834106,
        SurfArea: 7687027.9261699999,
        RAreaHLoad: 0.77821890509000002,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.042717917978848, 35.128074278807617, 0.0],
          [-81.037670317986567, 35.120408212152881, 0.0],
          [-81.034116717992106, 35.111130012167166, 0.0],
          [-81.034741384657877, 35.107952612172141, 0.0],
          [-81.040017184649685, 35.103335812179353, 0.0],
          [-81.05022471796714, 35.100979478849638, 0.0],
          [-81.052892984629707, 35.097656812188177, 0.0],
          [-81.054847917959933, 35.091120878864899, 0.0],
          [-81.060896717950584, 35.087688212203602, 0.0],
          [-81.061450184616433, 35.0857230788734, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286133.0,
        COMID: 9756944,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983804",
        GNIS_NAME: "Crowders Creek",
        LENGTHKM: 5.546,
        REACHCODE: "03050101002810",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.055230385039364997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250062022.0,
        ToNode: 250062026.0,
        Hydroseq: 250028891.0,
        LevelPathI: 250028891.0,
        Pathlength: 450.247,
        TerminalPa: 250002604.0,
        ArbolateSu: 149.341,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250028891.0,
        UpHydroseq: 250029935.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007348.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 15.9129,
        TotDASqKM: 305.532,
        DivDASqKM: 305.532,
        Tidal: 0,
        TOTMA: 1.90449816378,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17308.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.5460000038146973,
        QA_MA: 129.47,
        VA_MA: -9998.0,
        QC_MA: 129.829,
        VC_MA: -9998.0,
        QE_MA: 129.829,
        VE_MA: -9998.0,
        QA_01: 271.707,
        VA_01: -9998.0,
        QC_01: 212.742,
        VC_01: -9998.0,
        QE_01: 212.742,
        VE_01: -9998.0,
        QA_02: 286.344,
        VA_02: -9998.0,
        QC_02: 239.109,
        VC_02: -9998.0,
        QE_02: 239.109,
        VE_02: -9998.0,
        QA_03: 304.614,
        VA_03: -9998.0,
        QC_03: 256.814,
        VC_03: -9998.0,
        QE_03: 256.814,
        VE_03: -9998.0,
        QA_04: 181.467,
        VA_04: -9998.0,
        QC_04: 168.73,
        VC_04: -9998.0,
        QE_04: 168.73,
        VE_04: -9998.0,
        QA_05: 103.311,
        VA_05: -9998.0,
        QC_05: 98.636,
        VC_05: -9998.0,
        QE_05: 98.636,
        VE_05: -9998.0,
        QA_06: 62.454,
        VA_06: -9998.0,
        QC_06: 78.742,
        VC_06: -9998.0,
        QE_06: 78.742,
        VE_06: -9998.0,
        QA_07: 41.303,
        VA_07: -9998.0,
        QC_07: 63.029,
        VC_07: -9998.0,
        QE_07: 63.029,
        VE_07: -9998.0,
        QA_08: 32.675,
        VA_08: -9998.0,
        QC_08: 72.683,
        VC_08: -9998.0,
        QE_08: 72.683,
        VE_08: -9998.0,
        QA_09: 28.756,
        VA_09: -9998.0,
        QC_09: 75.904,
        VC_09: -9998.0,
        QE_09: 75.904,
        VE_09: -9998.0,
        QA_10: 22.156,
        VA_10: -9998.0,
        QC_10: 65.343,
        VC_10: -9998.0,
        QE_10: 65.343,
        VE_10: -9998.0,
        QA_11: 49.635,
        VA_11: -9998.0,
        QC_11: 75.108,
        VC_11: -9998.0,
        QE_11: 75.108,
        VE_11: -9998.0,
        QA_12: 169.952,
        VA_12: -9998.0,
        QC_12: 141.391,
        VC_12: -9998.0,
        QE_12: 141.391,
        VE_12: -9998.0,
        LakeFract: 0.00479450898,
        SurfArea: 217118.613304,
        RAreaHLoad: 0.68562383555999995,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.097261317894151, 35.107910478838903, 0.0],
          [-81.093051517900562, 35.111345278833539, 0.0],
          [-81.085157584579576, 35.110472212168197, 0.0],
          [-81.084228317914381, 35.104924612176887, 0.0],
          [-81.077274917925138, 35.10525701217631, 0.0],
          [-81.074421784596268, 35.100956078849663, 0.0],
          [-81.070126584602917, 35.099727878851581, 0.0],
          [-81.066712717941527, 35.096576412189741, 0.0],
          [-81.063592784613036, 35.092783812195705, 0.0],
          [-81.061450184616433, 35.0857230788734, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286129.0,
        COMID: 9756936,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.684,
        REACHCODE: "03050101002806",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0068018269012860002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062018.0,
        ToNode: 250062019.0,
        Hydroseq: 250007433.0,
        LevelPathI: 250003258.0,
        Pathlength: 456.619,
        TerminalPa: 250002604.0,
        ArbolateSu: 5629.417,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007466.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007406.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2024,
        TotDASqKM: 7262.2647,
        DivDASqKM: 7262.2647,
        Tidal: 0,
        TOTMA: 0.23488581772,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17118.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.68400001525878895,
        QA_MA: 3829.284,
        VA_MA: -9998.0,
        QC_MA: 3829.284,
        VC_MA: -9998.0,
        QE_MA: 4079.024,
        VE_MA: -9998.0,
        QA_01: 6723.838,
        VA_01: -9998.0,
        QC_01: 6723.838,
        VC_01: -9998.0,
        QE_01: 6885.384,
        VE_01: -9998.0,
        QA_02: 7118.263,
        VA_02: -9998.0,
        QC_02: 7118.263,
        VC_02: -9998.0,
        QE_02: 7133.273,
        VE_02: -9998.0,
        QA_03: 7869.015,
        VA_03: -9998.0,
        QC_03: 7869.015,
        VC_03: -9998.0,
        QE_03: 8092.119,
        VE_03: -9998.0,
        QA_04: 5655.752,
        VA_04: -9998.0,
        QC_04: 5655.752,
        VC_04: -9998.0,
        QE_04: 5822.216,
        VE_04: -9998.0,
        QA_05: 4034.848,
        VA_05: -9998.0,
        QC_05: 4034.848,
        VC_05: -9998.0,
        QE_05: 4353.957,
        VE_05: -9998.0,
        QA_06: 2357.74,
        VA_06: -9998.0,
        QC_06: 2357.74,
        VC_06: -9998.0,
        QE_06: 2704.461,
        VE_06: -9998.0,
        QA_07: 1426.796,
        VA_07: -9998.0,
        QC_07: 1426.796,
        VC_07: -9998.0,
        QE_07: 1681.076,
        VE_07: -9998.0,
        QA_08: 1004.662,
        VA_08: -9998.0,
        QC_08: 1004.662,
        VC_08: -9998.0,
        QE_08: 1413.004,
        VE_08: -9998.0,
        QA_09: 931.926,
        VA_09: -9998.0,
        QC_09: 931.926,
        VC_09: -9998.0,
        QE_09: 1050.876,
        VE_09: -9998.0,
        QA_10: 1072.1,
        VA_10: -9998.0,
        QC_10: 1072.1,
        VC_10: -9998.0,
        QE_10: 1344.904,
        VE_10: -9998.0,
        QA_11: 2885.944,
        VA_11: -9998.0,
        QC_11: 2885.944,
        VC_11: -9998.0,
        QE_11: 3047.674,
        VE_11: -9998.0,
        QA_12: 4896.74,
        VA_12: -9998.0,
        QC_12: 4896.74,
        VC_12: -9998.0,
        QE_12: 4898.592,
        VE_12: -9998.0,
        LakeFract: 0.01840613821,
        SurfArea: 833519.18227800005,
        RAreaHLoad: 0.08455944895,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.047098184638628, 35.133153278799739, 0.0],
          [-81.042584784645612, 35.128751278806419, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286130.0,
        COMID: 9756938,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.077,
        REACHCODE: "03050101002807",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00068996629105800005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062019.0,
        ToNode: 250062021.0,
        Hydroseq: 250007406.0,
        LevelPathI: 250003258.0,
        Pathlength: 456.542,
        TerminalPa: 250002604.0,
        ArbolateSu: 5629.494,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007433.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007376.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0252,
        TotDASqKM: 7262.2899,
        DivDASqKM: 7262.2899,
        Tidal: 0,
        TOTMA: 0.02644182452,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17289.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.076999999582767001,
        QA_MA: 3829.294,
        VA_MA: -9998.0,
        QC_MA: 3829.294,
        VC_MA: -9998.0,
        QE_MA: 4079.034,
        VE_MA: -9998.0,
        QA_01: 6723.859,
        VA_01: -9998.0,
        QC_01: 6723.859,
        VC_01: -9998.0,
        QE_01: 6885.406,
        VE_01: -9998.0,
        QA_02: 7118.286,
        VA_02: -9998.0,
        QC_02: 7118.286,
        VC_02: -9998.0,
        QE_02: 7133.295,
        VE_02: -9998.0,
        QA_03: 7869.04,
        VA_03: -9998.0,
        QC_03: 7869.04,
        VC_03: -9998.0,
        QE_03: 8092.143,
        VE_03: -9998.0,
        QA_04: 5655.767,
        VA_04: -9998.0,
        QC_04: 5655.767,
        VC_04: -9998.0,
        QE_04: 5822.231,
        VE_04: -9998.0,
        QA_05: 4034.857,
        VA_05: -9998.0,
        QC_05: 4034.857,
        VC_05: -9998.0,
        QE_05: 4353.965,
        VE_05: -9998.0,
        QA_06: 2357.745,
        VA_06: -9998.0,
        QC_06: 2357.745,
        VC_06: -9998.0,
        QE_06: 2704.466,
        VE_06: -9998.0,
        QA_07: 1426.799,
        VA_07: -9998.0,
        QC_07: 1426.799,
        VC_07: -9998.0,
        QE_07: 1681.079,
        VE_07: -9998.0,
        QA_08: 1004.665,
        VA_08: -9998.0,
        QC_08: 1004.665,
        VC_08: -9998.0,
        QE_08: 1413.007,
        VE_08: -9998.0,
        QA_09: 931.928,
        VA_09: -9998.0,
        QC_09: 931.928,
        VC_09: -9998.0,
        QE_09: 1050.878,
        VE_09: -9998.0,
        QA_10: 1072.101,
        VA_10: -9998.0,
        QC_10: 1072.101,
        VC_10: -9998.0,
        QE_10: 1344.906,
        VE_10: -9998.0,
        QA_11: 2885.946,
        VA_11: -9998.0,
        QC_11: 2885.946,
        VC_11: -9998.0,
        QE_11: 3047.676,
        VE_11: -9998.0,
        QA_12: 4896.752,
        VA_12: -9998.0,
        QC_12: 4896.752,
        VC_12: -9998.0,
        QE_12: 4898.604,
        VE_12: -9998.0,
        LakeFract: 0.00207204117,
        SurfArea: 93832.070690699999,
        RAreaHLoad: 0.00951911926,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.042584784645612, 35.128751278806419, 0.0],
          [-81.042717917978848, 35.128074278807617, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286174.0,
        COMID: 9747288,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 5.76,
        REACHCODE: "03050102000010",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.057822370355146001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060440.0,
        ToNode: 250060441.0,
        Hydroseq: 250012337.0,
        LevelPathI: 250011553.0,
        Pathlength: 490.011,
        TerminalPa: 250002604.0,
        ArbolateSu: 1040.696,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012430.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012246.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.7226,
        TotDASqKM: 1369.8225,
        DivDASqKM: 1369.8225,
        Tidal: 0,
        TOTMA: 0.15791224783800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19424.0,
        MAXELEVSMO: 19694.0,
        MINELEVSMO: 19515.0,
        SLOPE: 0.00031076,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.7600002288818359,
        QA_MA: 706.484,
        VA_MA: 1.38872,
        QC_MA: 663.295,
        VC_MA: 1.38322,
        QE_MA: 677.696,
        VE_MA: 1.38509,
        QA_01: 1303.251,
        VA_01: 1.80381,
        QC_01: 979.343,
        VC_01: 1.63029,
        QE_01: 983.577,
        VE_01: 1.62058,
        QA_02: 1360.301,
        VA_02: 1.83822,
        QC_02: 1088.672,
        VC_02: 1.7067,
        QE_02: 1076.45,
        VE_02: 1.68497,
        QA_03: 1468.757,
        VA_03: 1.90182,
        QC_03: 1175.617,
        VC_03: 1.76493,
        QE_03: 1188.533,
        VE_03: 1.75931,
        QA_04: 1020.179,
        VA_04: 1.62147,
        QC_04: 912.065,
        VC_04: 1.58126,
        QE_04: 943.155,
        VE_04: 1.59166,
        QA_05: 724.146,
        VA_05: 1.40298,
        QC_05: 642.804,
        VC_05: 1.36546,
        QE_05: 690.125,
        VE_05: 1.39553,
        QA_06: 415.676,
        VA_06: 1.12355,
        QC_06: 510.62,
        VC_06: 1.24382,
        QE_06: 555.9,
        VE_06: 1.27758,
        QA_07: 252.019,
        VA_07: 0.93334,
        QC_07: 368.3,
        VC_07: 1.09483,
        QE_07: 399.639,
        VE_07: 1.12178,
        QA_08: 176.221,
        VA_08: 0.82521,
        QC_08: 405.437,
        VC_08: 1.13599,
        QE_08: 427.425,
        VE_08: 1.15134,
        QA_09: 150.241,
        VA_09: 0.78324,
        QC_09: 326.768,
        VC_09: 1.04642,
        QE_09: 312.033,
        VE_09: 1.02163,
        QA_10: 168.632,
        VA_10: 0.81327,
        QC_10: 330.61,
        VC_10: 1.05102,
        QE_10: 315.352,
        VE_10: 1.02565,
        QA_11: 508.344,
        VA_11: 1.21549,
        QC_11: 442.818,
        VC_11: 1.17567,
        QE_11: 370.955,
        VE_11: 1.09024,
        QA_12: 934.964,
        VA_12: 1.56204,
        QC_12: 666.999,
        VC_12: 1.3864,
        QE_12: 632.908,
        VE_12: 1.3467,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.166288584453696, 35.358635478449742, 0.0],
          [-81.165253584455286, 35.357605211784687, 0.0],
          [-81.160473917796025, 35.359782478447869, 0.0],
          [-81.151218384477033, 35.355662411787534, 0.0],
          [-81.148167384481724, 35.342974678473979, 0.0],
          [-81.1405363178269, 35.341968278475633, 0.0],
          [-81.143385584489238, 35.334547211820393, 0.0],
          [-81.133491184504578, 35.33104421182594, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286167.0,
        COMID: 9747428,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.961,
        REACHCODE: "03050102000004",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019949040261092999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060474.0,
        ToNode: 250060475.0,
        Hydroseq: 250012152.0,
        LevelPathI: 250011553.0,
        Pathlength: 487.202,
        TerminalPa: 250002604.0,
        ArbolateSu: 1084.248,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012246.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012063.0,
        FromMeas: 21.78236,
        ToMeas: 76.45817,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.016,
        TotDASqKM: 1445.3757,
        DivDASqKM: 1445.3757,
        Tidal: 0,
        TOTMA: 0.054344478185699999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19336.0,
        MAXELEVSMO: 19388.0,
        MINELEVSMO: 19336.0,
        SLOPE: 0.00026517,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.9609999656677251,
        QA_MA: 737.968,
        VA_MA: 1.37402,
        QC_MA: 691.682,
        VC_MA: 1.36844,
        QE_MA: 706.222,
        VE_MA: 1.37023,
        QA_01: 1368.214,
        VA_01: 1.78741,
        QC_01: 1026.851,
        VC_01: 1.6159,
        QE_01: 1031.045,
        VE_01: 1.60652,
        QA_02: 1429.473,
        VA_02: 1.82221,
        QC_02: 1142.486,
        VC_02: 1.69213,
        QE_02: 1130.209,
        VE_02: 1.67139,
        QA_03: 1542.564,
        VA_03: 1.88467,
        QC_03: 1232.697,
        VC_03: 1.74911,
        QE_03: 1245.634,
        VE_03: 1.74362,
        QA_04: 1065.258,
        VA_04: 1.60334,
        QC_04: 951.431,
        VC_04: 1.564,
        QE_04: 982.502,
        VE_04: 1.5737,
        QA_05: 750.768,
        VA_05: 1.38379,
        QC_05: 665.536,
        VC_05: 1.34697,
        QE_05: 713.096,
        VE_05: 1.3757,
        QA_06: 431.514,
        VA_06: 1.10981,
        QC_06: 529.803,
        VC_06: 1.2285,
        QE_06: 575.227,
        VE_06: 1.26063,
        QA_07: 262.422,
        VA_07: 0.9238,
        QC_07: 383.134,
        VC_07: 1.08302,
        QE_07: 414.672,
        VE_07: 1.10872,
        QA_08: 183.757,
        VA_08: 0.81771,
        QC_08: 423.13,
        VC_08: 1.12496,
        QE_08: 445.307,
        VE_08: 1.1396,
        QA_09: 156.697,
        VA_09: 0.7764,
        QC_09: 339.135,
        VC_09: 1.03444,
        QE_09: 324.558,
        VE_09: 1.01109,
        QA_10: 174.213,
        VA_10: 0.80348,
        QC_10: 339.321,
        VC_10: 1.03465,
        QE_10: 324.818,
        VE_10: 1.01138,
        QA_11: 519.481,
        VA_11: 1.19278,
        QC_11: 450.197,
        VC_11: 1.15229,
        QE_11: 378.867,
        VE_11: 1.07125,
        QA_12: 976.309,
        VA_12: 1.54476,
        QC_12: 693.782,
        VC_12: 1.37015,
        QE_12: 659.716,
        VE_12: 1.3325,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.131785717840501, 35.324700278502412, 0.0],
          [-81.129021784511465, 35.320750678508489, 0.0],
          [-81.12380518451954, 35.321580211840512, 0.0],
          [-81.116702584530628, 35.316131678515717, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286168.0,
        COMID: 9747430,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.783,
        REACHCODE: "03050102000004",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0076907879875429998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060475.0,
        ToNode: 250060477.0,
        Hydroseq: 250012063.0,
        LevelPathI: 250011553.0,
        Pathlength: 486.419,
        TerminalPa: 250002604.0,
        ArbolateSu: 1088.359,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012152.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011975.0,
        FromMeas: 0.0,
        ToMeas: 21.78236,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8856,
        TotDASqKM: 1451.3013,
        DivDASqKM: 1451.3013,
        Tidal: 0,
        TOTMA: 0.022389183811799999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19320.0,
        MAXELEVSMO: 19336.0,
        MINELEVSMO: 19320.0,
        SLOPE: 0.00020434,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.78299999237060502,
        QA_MA: 740.265,
        VA_MA: 1.33165,
        QC_MA: 693.752,
        VC_MA: 1.32628,
        QE_MA: 708.302,
        VE_MA: 1.32799,
        QA_01: 1373.153,
        VA_01: 1.72898,
        QC_01: 1030.461,
        VC_01: 1.56425,
        QE_01: 1034.652,
        VE_01: 1.55526,
        QA_02: 1434.801,
        VA_02: 1.76249,
        QC_02: 1146.628,
        VC_02: 1.63754,
        QE_02: 1134.347,
        VE_02: 1.61768,
        QA_03: 1548.153,
        VA_03: 1.8224,
        QC_03: 1237.016,
        VC_03: 1.69219,
        QE_03: 1249.955,
        VE_03: 1.68692,
        QA_04: 1068.497,
        VA_04: 1.55181,
        QC_04: 954.258,
        VC_04: 1.51405,
        QE_04: 985.328,
        VE_04: 1.52333,
        QA_05: 752.624,
        VA_05: 1.34068,
        QC_05: 667.12,
        VC_05: 1.30534,
        QE_05: 714.699,
        VE_05: 1.33287,
        QA_06: 432.63,
        VA_06: 1.07773,
        QC_06: 531.154,
        VC_06: 1.1917,
        QE_06: 576.589,
        VE_06: 1.22247,
        QA_07: 263.151,
        VA_07: 0.89922,
        QC_07: 384.172,
        VC_07: 1.0521,
        QE_07: 415.727,
        VE_07: 1.07673,
        QA_08: 184.315,
        VA_08: 0.79744,
        QC_08: 424.44,
        VC_08: 1.09253,
        QE_08: 446.632,
        VE_08: 1.10655,
        QA_09: 157.175,
        VA_09: 0.75777,
        QC_09: 340.049,
        VC_09: 1.00545,
        QE_09: 325.484,
        VE_09: 0.9831,
        QA_10: 174.611,
        VA_10: 0.78359,
        QC_10: 339.941,
        VC_10: 1.00533,
        QE_10: 325.497,
        VE_10: 0.98311,
        QA_11: 519.892,
        VA_11: 1.15657,
        QC_11: 450.468,
        VC_11: 1.11771,
        QE_11: 379.18,
        VE_11: 1.04009,
        QA_12: 979.275,
        VA_12: 1.49555,
        QC_12: 695.699,
        VC_12: 1.3278,
        QE_12: 661.635,
        VE_12: 1.29175,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.116702584530628, 35.316131678515717, 0.0],
          [-81.113302984535949, 35.314138878518861, 0.0],
          [-81.114760984533632, 35.310897411857184, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286179.0,
        COMID: 9747484,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.753,
        REACHCODE: "03050102000014",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033477,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.018417546370221,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060488.0,
        ToNode: 250060471.0,
        Hydroseq: 250012800.0,
        LevelPathI: 250011553.0,
        Pathlength: 501.925,
        TerminalPa: 250002604.0,
        ArbolateSu: 1002.651,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012899.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012700.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8701,
        TotDASqKM: 1305.621,
        DivDASqKM: 1305.621,
        Tidal: 0,
        TOTMA: 0.058067883217499998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21200.0,
        MAXELEVSMO: 21212.0,
        MINELEVSMO: 21200.0,
        SLOPE: 6.845e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.753000020980835,
        QA_MA: 679.356,
        VA_MA: 1.14903,
        QC_MA: 638.795,
        VC_MA: 1.14485,
        QE_MA: 653.079,
        VE_MA: 1.14635,
        QA_01: 1247.017,
        VA_01: 1.46896,
        QC_01: 938.17,
        VC_01: 1.33422,
        QE_01: 942.437,
        VE_01: 1.32652,
        QA_02: 1300.921,
        VA_02: 1.49534,
        QC_02: 1042.417,
        VC_02: 1.39325,
        QE_02: 1030.242,
        VE_02: 1.37583,
        QA_03: 1405.618,
        VA_03: 1.54514,
        QC_03: 1126.713,
        VC_03: 1.43899,
        QE_03: 1139.611,
        VE_03: 1.43458,
        QA_04: 981.813,
        VA_04: 1.33058,
        QC_04: 878.53,
        VC_04: 1.29906,
        QE_04: 909.636,
        VE_04: 1.30754,
        QA_05: 701.64,
        VA_05: 1.16356,
        QC_05: 623.562,
        VC_05: 1.13419,
        QE_05: 670.679,
        VE_05: 1.15826,
        QA_06: 402.302,
        VA_06: 0.94486,
        QC_06: 494.413,
        VC_06: 1.03845,
        QE_06: 539.571,
        VE_06: 1.06556,
        QA_07: 243.234,
        VA_07: 0.79563,
        QC_07: 355.765,
        VC_07: 0.92139,
        QE_07: 386.934,
        VE_07: 0.943,
        QA_08: 169.584,
        VA_08: 0.7107,
        QC_08: 389.868,
        VC_08: 0.95192,
        QE_08: 411.697,
        VE_08: 0.96425,
        QA_09: 144.526,
        VA_09: 0.67796,
        QC_09: 315.77,
        VC_09: 0.88378,
        QE_09: 300.9,
        VE_09: 0.86367,
        QA_10: 163.757,
        VA_10: 0.7033,
        QC_10: 322.952,
        VC_10: 0.89069,
        QE_10: 307.054,
        VE_10: 0.86967,
        QA_11: 497.744,
        VA_11: 1.02104,
        QC_11: 435.758,
        VC_11: 0.99109,
        QE_11: 363.442,
        VE_11: 0.92224,
        QA_12: 898.775,
        VA_12: 1.28372,
        QC_12: 643.468,
        VC_12: 1.14809,
        QE_12: 609.355,
        VE_12: 1.11607,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.220241584369944, 35.405965678376333, 0.0],
          [-81.204052917728347, 35.397884211722101, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288110.0,
        COMID: 9734774,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 2.58,
        REACHCODE: "03050103001062",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026539879228334,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059510.0,
        ToNode: 250059513.0,
        Hydroseq: 250027084.0,
        LevelPathI: 250027084.0,
        Pathlength: 402.473,
        TerminalPa: 250002604.0,
        ArbolateSu: 79.585,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250027958.0,
        DnLevelPat: 250023658.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023658.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.662,
        TotDASqKM: 77.4036,
        DivDASqKM: 77.4036,
        Tidal: 0,
        TOTMA: 0.093743564971800003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12971.0,
        MAXELEVSMO: 13493.0,
        MINELEVSMO: 12979.0,
        SLOPE: 0.00199224,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5799999237060551,
        QA_MA: 30.08,
        VA_MA: 1.04155,
        QC_MA: 31.921,
        VC_MA: 1.04508,
        QE_MA: 31.921,
        VE_MA: 1.04508,
        QA_01: 66.936,
        VA_01: 1.42952,
        QC_01: 54.37,
        VC_01: 1.28584,
        QE_01: 54.37,
        VE_01: 1.28584,
        QA_02: 69.396,
        VA_02: 1.45121,
        QC_02: 60.231,
        VC_02: 1.34045,
        QE_02: 60.231,
        VE_02: 1.34045,
        QA_03: 73.913,
        VA_03: 1.49012,
        QC_03: 65.297,
        VC_03: 1.38567,
        QE_03: 65.297,
        VE_03: 1.38567,
        QA_04: 41.891,
        VA_04: 1.18252,
        QC_04: 40.27,
        VC_04: 1.14181,
        QE_04: 40.27,
        VE_04: 1.14181,
        QA_05: 23.841,
        VA_05: 0.95641,
        QC_05: 24.046,
        VC_05: 0.94219,
        QE_05: 24.046,
        VE_05: 0.94219,
        QA_06: 14.734,
        VA_06: 0.81028,
        QC_06: 18.949,
        VC_06: 0.86688,
        QE_06: 18.949,
        VE_06: 0.86688,
        QA_07: 11.448,
        VA_07: 0.74734,
        QC_07: 18.014,
        VC_07: 0.85207,
        QE_07: 18.014,
        VE_07: 0.85207,
        QA_08: 8.326,
        VA_08: 0.67905,
        QC_08: 18.019,
        VC_08: 0.85215,
        QE_08: 18.019,
        VE_08: 0.85215,
        QA_09: 6.244,
        VA_09: 0.62653,
        QC_09: 19.71,
        VC_09: 0.87868,
        QE_09: 19.71,
        VE_09: 0.87868,
        QA_10: 5.204,
        VA_10: 0.59719,
        QC_10: 20.539,
        VC_10: 0.89129,
        QE_10: 20.539,
        VE_10: 0.89129,
        QA_11: 4.783,
        VA_11: 0.58454,
        QC_11: 12.613,
        VC_11: 0.75833,
        QE_11: 12.613,
        VE_11: 0.75833,
        QA_12: 34.477,
        VA_12: 1.09663,
        QC_12: 33.12,
        VC_12: 1.05965,
        QE_12: 33.12,
        VE_12: 1.05965,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.773718918396355, 34.730676212757714, 0.0],
          [-80.785238385045091, 34.726058012765009, 0.0],
          [-80.795399718362717, 34.719009812775823, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286180.0,
        COMID: 9747480,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.844,
        REACHCODE: "03050102000015",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033477,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0090794083192469992,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060486.0,
        ToNode: 250060488.0,
        Hydroseq: 250012899.0,
        LevelPathI: 250011553.0,
        Pathlength: 503.678,
        TerminalPa: 250002604.0,
        ArbolateSu: 995.505,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012997.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012800.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6201,
        TotDASqKM: 1288.3824,
        DivDASqKM: 1288.3824,
        Tidal: 0,
        TOTMA: 0.023398173063299998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21212.0,
        MAXELEVSMO: 21238.0,
        MINELEVSMO: 21212.0,
        SLOPE: 0.00030805,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.84399998188018799,
        QA_MA: 671.644,
        VA_MA: 1.37306,
        QC_MA: 631.823,
        VC_MA: 1.3678,
        QE_MA: 646.075,
        VE_MA: 1.36972,
        QA_01: 1231.654,
        VA_01: 1.77749,
        QC_01: 926.912,
        VC_01: 1.6069,
        QE_01: 931.188,
        VE_01: 1.59708,
        QA_02: 1284.833,
        VA_02: 1.81084,
        QC_02: 1029.875,
        VC_02: 1.6816,
        QE_02: 1017.712,
        VE_02: 1.65933,
        QA_03: 1388.255,
        VA_03: 1.87389,
        QC_03: 1113.252,
        VC_03: 1.73955,
        QE_03: 1126.145,
        VE_03: 1.73395,
        QA_04: 970.734,
        VA_04: 1.60304,
        QC_04: 868.84,
        VC_04: 1.56305,
        QE_04: 899.951,
        VE_04: 1.57394,
        QA_05: 694.632,
        VA_05: 1.39225,
        QC_05: 617.566,
        VC_05: 1.35504,
        QE_05: 664.629,
        VE_05: 1.38579,
        QA_06: 398.25,
        VA_06: 1.11504,
        QC_06: 489.501,
        VC_06: 1.23353,
        QE_06: 534.627,
        VE_06: 1.2682,
        QA_07: 240.647,
        VA_07: 0.92572,
        QC_07: 352.07,
        VC_07: 1.08501,
        QE_07: 383.193,
        VE_07: 1.11263,
        QA_08: 167.73,
        VA_08: 0.81804,
        QC_08: 385.521,
        VC_08: 1.12336,
        QE_08: 407.306,
        VE_08: 1.13911,
        QA_09: 142.904,
        VA_09: 0.77648,
        QC_09: 312.638,
        VC_09: 1.03754,
        QE_09: 297.733,
        VE_09: 1.01177,
        QA_10: 162.366,
        VA_10: 0.80932,
        QC_10: 320.759,
        VC_10: 1.04754,
        QE_10: 304.689,
        VE_10: 1.02045,
        QA_11: 493.843,
        VA_11: 1.21266,
        QC_11: 433.151,
        VC_11: 1.17536,
        QE_11: 360.714,
        VE_11: 1.08722,
        QA_12: 888.54,
        VA_12: 1.54363,
        QC_12: 636.798,
        VC_12: 1.37222,
        QE_12: 602.679,
        VE_12: 1.33125,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.228702984356744, 35.405811678376381, 0.0],
          [-81.224362517696875, 35.404721878378268, 0.0],
          [-81.220241584369944, 35.405965678376333, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286181.0,
        COMID: 9747230,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.302,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013669026815535,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060420.0,
        ToNode: 250060468.0,
        Hydroseq: 250013088.0,
        LevelPathI: 250011553.0,
        Pathlength: 504.661,
        TerminalPa: 250002604.0,
        ArbolateSu: 968.303,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013183.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012997.0,
        FromMeas: 4.76876,
        ToMeas: 49.4493,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4966,
        TotDASqKM: 1225.215,
        DivDASqKM: 1225.215,
        Tidal: 0,
        TOTMA: 0.044793554505,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21259.0,
        MAXELEVSMO: 21266.0,
        MINELEVSMO: 21259.0,
        SLOPE: 5.376e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.302000045776367,
        QA_MA: 642.095,
        VA_MA: 1.10608,
        QC_MA: 605.082,
        VC_MA: 1.10226,
        QE_MA: 619.218,
        VE_MA: 1.10374,
        QA_01: 1173.439,
        VA_01: 1.4071,
        QC_01: 884.223,
        VC_01: 1.27941,
        QE_01: 888.531,
        VE_01: 1.27186,
        QA_02: 1224.456,
        VA_02: 1.43221,
        QC_02: 982.768,
        VC_02: 1.33545,
        QE_02: 970.651,
        VE_02: 1.31816,
        QA_03: 1323.028,
        VA_03: 1.47938,
        QC_03: 1062.633,
        VC_03: 1.37896,
        QE_03: 1075.508,
        VE_03: 1.37469,
        QA_04: 928.973,
        VA_04: 1.27892,
        QC_04: 832.295,
        VC_04: 1.2487,
        QE_04: 863.421,
        VE_04: 1.2573,
        QA_05: 666.982,
        VA_05: 1.12236,
        QC_05: 593.885,
        VC_05: 1.09442,
        QE_05: 640.748,
        VE_05: 1.11831,
        QA_06: 382.368,
        VA_06: 0.91414,
        QC_06: 470.243,
        VC_06: 1.00279,
        QE_06: 515.25,
        VE_06: 1.02979,
        QA_07: 230.666,
        VA_07: 0.77156,
        QC_07: 337.811,
        VC_07: 0.89093,
        QE_07: 368.766,
        VE_07: 0.91239,
        QA_08: 160.557,
        VA_08: 0.6905,
        QC_08: 368.71,
        VC_08: 0.91865,
        QE_08: 390.338,
        VE_08: 0.9309,
        QA_09: 136.959,
        VA_09: 0.65957,
        QC_09: 301.127,
        VC_09: 0.85644,
        QE_09: 286.089,
        VE_09: 0.83622,
        QA_10: 157.271,
        VA_10: 0.68633,
        QC_10: 312.692,
        VC_10: 0.86752,
        QE_10: 295.991,
        VE_10: 0.84584,
        QA_11: 476.15,
        VA_11: 0.98897,
        QC_11: 421.265,
        VC_11: 0.96339,
        QE_11: 348.382,
        VE_11: 0.89442,
        QA_12: 848.676,
        VA_12: 1.23344,
        QC_12: 610.751,
        VC_12: 1.1062,
        QE_12: 576.611,
        VE_12: 1.0742,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.241796117669764, 35.412590478365985, 0.0],
          [-81.229870517688198, 35.406623878375228, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291195.0,
        COMID: 9718437,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.905,
        REACHCODE: "03050104002488",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0095131734602680006,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058246.0,
        ToNode: 250058247.0,
        Hydroseq: 250003951.0,
        LevelPathI: 250003258.0,
        Pathlength: 340.408,
        TerminalPa: 250002604.0,
        ArbolateSu: 9158.069,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003964.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003942.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6588,
        TotDASqKM: 12187.134,
        DivDASqKM: 12187.134,
        Tidal: 0,
        TOTMA: 0.4569759864,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6739.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.90499997138977095,
        QA_MA: 5771.784,
        VA_MA: -9998.0,
        QC_MA: 5771.784,
        VC_MA: -9998.0,
        QE_MA: 6469.82,
        VE_MA: -9998.0,
        QA_01: 10923.839,
        VA_01: -9998.0,
        QC_01: 10923.839,
        VC_01: -9998.0,
        QE_01: 9529.782,
        VE_01: -9998.0,
        QA_02: 11562.619,
        VA_02: -9998.0,
        QC_02: 11562.619,
        VC_02: -9998.0,
        QE_02: 9721.1,
        VE_02: -9998.0,
        QA_03: 12650.273,
        VA_03: -9998.0,
        QC_03: 12650.273,
        VC_03: -9998.0,
        QE_03: 10398.669,
        VE_03: -9998.0,
        QA_04: 8417.798,
        VA_04: -9998.0,
        QC_04: 8417.798,
        VC_04: -9998.0,
        QE_04: 8166.048,
        VE_04: -9998.0,
        QA_05: 5578.831,
        VA_05: -9998.0,
        QC_05: 5578.831,
        VC_05: -9998.0,
        QE_05: 6534.199,
        VE_05: -9998.0,
        QA_06: 3334.869,
        VA_06: -9998.0,
        QC_06: 3334.869,
        VC_06: -9998.0,
        QE_06: 5560.154,
        VE_06: -9998.0,
        QA_07: 2118.742,
        VA_07: -9998.0,
        QC_07: 2118.742,
        VC_07: -9998.0,
        QE_07: 4195.957,
        VE_07: -9998.0,
        QA_08: 1530.147,
        VA_08: -9998.0,
        QC_08: 1530.147,
        VC_08: -9998.0,
        QE_08: 3827.107,
        VE_08: -9998.0,
        QA_09: 1359.022,
        VA_09: -9998.0,
        QC_09: 1359.022,
        VC_09: -9998.0,
        QE_09: 3497.361,
        VE_09: -9998.0,
        QA_10: 1405.956,
        VA_10: -9998.0,
        QC_10: 1405.956,
        VC_10: -9998.0,
        QE_10: 4728.001,
        VE_10: -9998.0,
        QA_11: 3342.216,
        VA_11: -9998.0,
        QC_11: 3342.216,
        VC_11: -9998.0,
        QE_11: 4789.579,
        VE_11: -9998.0,
        QA_12: 7076.666,
        VA_12: -9998.0,
        QC_12: 7076.666,
        VC_12: -9998.0,
        QE_12: 6784.634,
        VE_12: -9998.0,
        LakeFract: 0.02989197574,
        SurfArea: 1355553.67316,
        RAreaHLoad: 0.08562516093,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.77437431839536, 34.379580213302745, 0.0],
          [-80.765758785075334, 34.375997413308255, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286182.0,
        COMID: 9747216,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.346,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0035182690861229999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060414.0,
        ToNode: 250060465.0,
        Hydroseq: 250013484.0,
        LevelPathI: 250011553.0,
        Pathlength: 507.099,
        TerminalPa: 250002604.0,
        ArbolateSu: 965.865,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013587.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013382.0,
        FromMeas: 88.14704,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2124,
        TotDASqKM: 1220.2506,
        DivDASqKM: 1220.2506,
        Tidal: 0,
        TOTMA: 0.0086605332286900007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21723.0,
        MAXELEVSMO: 21798.0,
        MINELEVSMO: 21772.0,
        SLOPE: 0.00075144,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.345999985933304,
        QA_MA: 639.874,
        VA_MA: 1.52059,
        QC_MA: 603.07,
        VC_MA: 1.51479,
        QE_MA: 617.197,
        VE_MA: 1.51706,
        QA_01: 1169.003,
        VA_01: 1.97775,
        QC_01: 880.967,
        VC_01: 1.78372,
        QE_01: 885.278,
        VE_01: 1.77222,
        QA_02: 1219.834,
        VA_02: 2.01591,
        QC_02: 979.158,
        VC_02: 1.86887,
        QE_02: 967.045,
        VE_02: 1.84252,
        QA_03: 1318.034,
        VA_03: 2.08757,
        QC_03: 1058.754,
        VC_03: 1.935,
        QE_03: 1071.627,
        VE_03: 1.9285,
        QA_04: 925.781,
        VA_04: 1.78326,
        QC_04: 829.5,
        VC_04: 1.73731,
        QE_04: 860.627,
        VE_04: 1.75043,
        QA_05: 664.967,
        VA_05: 1.54561,
        QC_05: 592.158,
        VC_05: 1.50315,
        QE_05: 639.004,
        VE_05: 1.53955,
        QA_06: 381.183,
        VA_06: 1.2291,
        QC_06: 468.805,
        VC_06: 1.36378,
        QE_06: 513.802,
        VE_06: 1.40493,
        QA_07: 229.928,
        VA_07: 1.01235,
        QC_07: 336.755,
        VC_07: 1.19375,
        QE_07: 367.698,
        VE_07: 1.22644,
        QA_08: 160.023,
        VA_08: 0.8891,
        QC_08: 367.459,
        VC_08: 1.23574,
        QE_08: 389.075,
        VE_08: 1.25441,
        QA_09: 136.491,
        VA_09: 0.84207,
        QC_09: 300.219,
        VC_09: 1.14137,
        QE_09: 285.171,
        VE_09: 1.11054,
        QA_10: 156.87,
        VA_10: 0.883,
        QC_10: 312.056,
        VC_10: 1.15866,
        QE_10: 295.305,
        VE_10: 1.12555,
        QA_11: 475.054,
        VA_11: 1.34327,
        QC_11: 420.525,
        VC_11: 1.3046,
        QE_11: 347.607,
        VE_11: 1.19946,
        QA_12: 845.734,
        VA_12: 1.71412,
        QC_12: 608.824,
        VC_12: 1.52089,
        QE_12: 574.683,
        VE_12: 1.47211,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.248859717658775, 35.421378078352348, 0.0],
          [-81.246114717663033, 35.419411278355369, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291196.0,
        COMID: 9718439,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 1.151,
        REACHCODE: "03050104002489",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012463583910995,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058247.0,
        ToNode: 250058242.0,
        Hydroseq: 250003942.0,
        LevelPathI: 250003258.0,
        Pathlength: 339.257,
        TerminalPa: 250002604.0,
        ArbolateSu: 9171.021,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003951.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003933.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5732,
        TotDASqKM: 12194.5563,
        DivDASqKM: 12194.5563,
        Tidal: 0,
        TOTMA: 0.58119266326999997,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6743.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6761.0,
        SLOPE: 0.00103388,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.151000022888184,
        QA_MA: 5774.466,
        VA_MA: -9998.0,
        QC_MA: 5774.466,
        VC_MA: -9998.0,
        QE_MA: 6472.506,
        VE_MA: -9998.0,
        QA_01: 10929.894,
        VA_01: -9998.0,
        QC_01: 10929.894,
        VC_01: -9998.0,
        QE_01: 9535.823,
        VE_01: -9998.0,
        QA_02: 11568.914,
        VA_02: -9998.0,
        QC_02: 11568.914,
        VC_02: -9998.0,
        QE_02: 9727.38,
        VE_02: -9998.0,
        QA_03: 12657.025,
        VA_03: -9998.0,
        QC_03: 12657.025,
        VC_03: -9998.0,
        QE_03: 10405.399,
        VE_03: -9998.0,
        QA_04: 8421.49,
        VA_04: -9998.0,
        QC_04: 8421.49,
        VC_04: -9998.0,
        QE_04: 8169.735,
        VE_04: -9998.0,
        QA_05: 5580.927,
        VA_05: -9998.0,
        QC_05: 5580.927,
        VC_05: -9998.0,
        QE_05: 6536.295,
        VE_05: -9998.0,
        QA_06: 3336.267,
        VA_06: -9998.0,
        QC_06: 3336.267,
        VC_06: -9998.0,
        QE_06: 5561.565,
        VE_06: -9998.0,
        QA_07: 2119.739,
        VA_07: -9998.0,
        QC_07: 2119.739,
        VC_07: -9998.0,
        QE_07: 4196.967,
        VE_07: -9998.0,
        QA_08: 1530.945,
        VA_08: -9998.0,
        QC_08: 1530.945,
        VC_08: -9998.0,
        QE_08: 3827.922,
        VE_08: -9998.0,
        QA_09: 1359.621,
        VA_09: -9998.0,
        QC_09: 1359.621,
        VC_09: -9998.0,
        QE_09: 3497.974,
        VE_09: -9998.0,
        QA_10: 1406.455,
        VA_10: -9998.0,
        QC_10: 1406.455,
        VC_10: -9998.0,
        QE_10: 4728.523,
        VE_10: -9998.0,
        QA_11: 3342.615,
        VA_11: -9998.0,
        QC_11: 3342.615,
        VC_11: -9998.0,
        QE_11: 4789.99,
        VE_11: -9998.0,
        QA_12: 7079.259,
        VA_12: -9998.0,
        QC_12: 7079.259,
        VC_12: -9998.0,
        QE_12: 6787.221,
        VE_12: -9998.0,
        LakeFract: 0.03803308338,
        SurfArea: 1724739.9879399999,
        RAreaHLoad: 0.10890006653000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.765758785075334, 34.375997413308255, 0.0],
          [-80.759917118417718, 34.37522641330952, 0.0],
          [-80.753602785094245, 34.376838879973718, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286175.0,
        COMID: 9747266,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.972,
        REACHCODE: "03050102000011",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.028655022487276,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060433.0,
        ToNode: 250060440.0,
        Hydroseq: 250012430.0,
        LevelPathI: 250011553.0,
        Pathlength: 495.771,
        TerminalPa: 250002604.0,
        ArbolateSu: 1030.742,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012522.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012337.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6018,
        TotDASqKM: 1351.0269,
        DivDASqKM: 1351.0269,
        Tidal: 0,
        TOTMA: 0.083378141564900005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19622.0,
        MAXELEVSMO: 19772.0,
        MINELEVSMO: 19694.0,
        SLOPE: 0.00026244,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.971999883651733,
        QA_MA: 698.912,
        VA_MA: 1.35701,
        QC_MA: 656.46,
        VC_MA: 1.3517,
        QE_MA: 670.827,
        VE_MA: 1.35353,
        QA_01: 1287.125,
        VA_01: 1.75862,
        QC_01: 967.541,
        VC_01: 1.59032,
        QE_01: 971.784,
        VE_01: 1.58084,
        QA_02: 1343.159,
        VA_02: 1.79181,
        QC_02: 1075.325,
        VC_02: 1.66427,
        QE_02: 1063.117,
        VE_02: 1.64301,
        QA_03: 1450.726,
        VA_03: 1.85375,
        QC_03: 1161.659,
        VC_03: 1.72103,
        QE_03: 1174.569,
        VE_03: 1.71557,
        QA_04: 1009.575,
        VA_04: 1.58314,
        QC_04: 902.799,
        VC_04: 1.54403,
        QE_04: 933.894,
        VE_04: 1.55425,
        QA_05: 718.036,
        VA_05: 1.37214,
        QC_05: 637.582,
        VC_05: 1.33566,
        QE_05: 684.843,
        VE_05: 1.36507,
        QA_06: 412.002,
        VA_06: 1.10042,
        QC_06: 506.168,
        VC_06: 1.21716,
        QE_06: 551.413,
        VE_06: 1.25021,
        QA_07: 249.561,
        VA_07: 0.9153,
        QC_07: 364.794,
        VC_07: 1.07209,
        QE_07: 396.083,
        VE_07: 1.09847,
        QA_08: 174.408,
        VA_08: 0.81015,
        QC_08: 401.184,
        VC_08: 1.11164,
        QE_08: 423.125,
        VE_08: 1.12666,
        QA_09: 148.647,
        VA_09: 0.76933,
        QC_09: 323.707,
        VC_09: 1.02514,
        QE_09: 308.932,
        VE_09: 1.0008,
        QA_10: 167.357,
        VA_10: 0.79928,
        QC_10: 328.612,
        VC_10: 1.03088,
        QE_10: 313.167,
        VE_10: 1.00582,
        QA_11: 506.302,
        VA_11: 1.19202,
        QC_11: 441.46,
        VC_11: 1.1535,
        QE_11: 369.465,
        VE_11: 1.06981,
        QA_12: 924.881,
        VA_12: 1.52519,
        QC_12: 660.451,
        VC_12: 1.35506,
        QE_12: 626.354,
        VE_12: 1.31614,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.180308984431917, 35.378486678418938, 0.0],
          [-81.178569384434638, 35.369761411765751, 0.0],
          [-81.175100784440019, 35.367610078435746, 0.0],
          [-81.170123584447722, 35.367360078436207, 0.0],
          [-81.166737917786349, 35.362483011777044, 0.0],
          [-81.166288584453696, 35.358635478449742, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286176.0,
        COMID: 9747244,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.378,
        REACHCODE: "03050102000012",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024077177433713001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060425.0,
        ToNode: 250060433.0,
        Hydroseq: 250012522.0,
        LevelPathI: 250011553.0,
        Pathlength: 498.743,
        TerminalPa: 250002604.0,
        ArbolateSu: 1017.873,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012608.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012430.0,
        FromMeas: 0.0,
        ToMeas: 93.76427,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.2973,
        TotDASqKM: 1328.3838,
        DivDASqKM: 1328.3838,
        Tidal: 0,
        TOTMA: 0.052983069980100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19710.0,
        MAXELEVSMO: 20166.0,
        MINELEVSMO: 19772.0,
        SLOPE: 0.00165685,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.378000020980835,
        QA_MA: 689.259,
        VA_MA: 1.70886,
        QC_MA: 647.743,
        VC_MA: 1.70183,
        QE_MA: 662.068,
        VE_MA: 1.7043,
        QA_01: 1267.163,
        VA_01: 2.24346,
        QC_01: 952.925,
        VC_01: 2.01886,
        QE_01: 957.18,
        VE_01: 2.00611,
        QA_02: 1322.117,
        VA_02: 2.28758,
        QC_02: 1058.934,
        VC_02: 2.11739,
        QE_02: 1046.742,
        VE_02: 2.08869,
        QA_03: 1428.352,
        VA_03: 2.37049,
        QC_03: 1144.33,
        VC_03: 2.19345,
        QE_03: 1157.234,
        VE_03: 2.18613,
        QA_04: 995.946,
        VA_04: 2.01116,
        QC_04: 890.887,
        VC_04: 1.95879,
        QE_04: 921.988,
        VE_04: 1.97268,
        QA_05: 710.118,
        VA_05: 1.73121,
        QC_05: 630.813,
        VC_05: 1.68237,
        QE_05: 678.002,
        VE_05: 1.72204,
        QA_06: 407.293,
        VA_06: 1.36753,
        QC_06: 500.462,
        VC_06: 1.52346,
        QE_06: 545.664,
        VE_06: 1.56809,
        QA_07: 246.461,
        VA_07: 1.11956,
        QC_07: 360.37,
        VC_07: 1.32902,
        QE_07: 391.599,
        VE_07: 1.36462,
        QA_08: 172.028,
        VA_08: 0.97858,
        QC_08: 395.6,
        VC_08: 1.38084,
        QE_08: 417.485,
        VE_08: 1.40114,
        QA_09: 146.607,
        VA_09: 0.92402,
        QC_09: 319.78,
        VC_09: 1.26627,
        QE_09: 304.958,
        VE_09: 1.23328,
        QA_10: 165.593,
        VA_10: 0.96515,
        QC_10: 325.84,
        VC_10: 1.27587,
        QE_10: 310.169,
        VE_10: 1.24164,
        QA_11: 502.259,
        VA_11: 1.49221,
        QC_11: 438.769,
        VC_11: 1.44147,
        QE_11: 366.614,
        VE_11: 1.32828,
        QA_12: 911.965,
        VA_12: 1.93335,
        QC_12: 652.054,
        VC_12: 1.70675,
        QE_12: 617.949,
        VE_12: 1.65411,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.198722184403266, 35.392218411730937, 0.0],
          [-81.190492517749419, 35.383886611743776, 0.0],
          [-81.186296717755965, 35.382858211745372, 0.0],
          [-81.180308984431917, 35.378486678418938, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288115.0,
        COMID: 9734718,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 1.765,
        REACHCODE: "03050103001067",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018495374701734998,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059491.0,
        ToNode: 250059478.0,
        Hydroseq: 250032431.0,
        LevelPathI: 250027084.0,
        Pathlength: 409.299,
        TerminalPa: 250002604.0,
        ArbolateSu: 51.48,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250033905.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031132.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5299,
        TotDASqKM: 44.334,
        DivDASqKM: 44.334,
        Tidal: 0,
        TOTMA: 0.06856378841000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14124.0,
        MAXELEVSMO: 14641.0,
        MINELEVSMO: 14217.0,
        SLOPE: 0.00240226,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.764999985694885,
        QA_MA: 17.302,
        VA_MA: 0.97314,
        QC_MA: 18.759,
        VC_MA: 0.97751,
        QE_MA: 18.759,
        VE_MA: 0.97751,
        QA_01: 38.615,
        VA_01: 1.32651,
        QC_01: 31.821,
        VC_01: 1.19427,
        QE_01: 31.821,
        VE_01: 1.19427,
        QA_02: 39.893,
        VA_02: 1.34427,
        QC_02: 35.151,
        VC_02: 1.24234,
        QE_02: 35.151,
        VE_02: 1.24234,
        QA_03: 42.344,
        VA_03: 1.37759,
        QC_03: 38.103,
        VC_03: 1.2832,
        QE_03: 38.103,
        VE_03: 1.2832,
        QA_04: 23.937,
        VA_04: 1.0982,
        QC_04: 23.306,
        VC_04: 1.05925,
        QE_04: 23.306,
        VE_04: 1.05925,
        QA_05: 13.704,
        VA_05: 0.89574,
        QC_05: 14.111,
        VC_05: 0.88363,
        QE_05: 14.111,
        VE_05: 0.88363,
        QA_06: 8.393,
        VA_06: 0.76111,
        QC_06: 10.878,
        VC_06: 0.80939,
        QE_06: 10.878,
        VE_06: 0.80939,
        QA_07: 6.557,
        VA_07: 0.70549,
        QC_07: 10.456,
        VC_07: 0.79897,
        QE_07: 10.456,
        VE_07: 0.79897,
        QA_08: 4.769,
        VA_08: 0.64372,
        QC_08: 10.206,
        VC_08: 0.79271,
        QE_08: 10.206,
        VE_08: 0.79271,
        QA_09: 3.576,
        VA_09: 0.59619,
        QC_09: 12.051,
        VC_09: 0.83739,
        QE_09: 12.051,
        VE_09: 0.83739,
        QA_10: 2.98,
        VA_10: 0.56963,
        QC_10: 13.16,
        VC_10: 0.8627,
        QE_10: 13.16,
        VE_10: 0.8627,
        QA_11: 2.911,
        VA_11: 0.5664,
        QC_11: 8.635,
        VC_11: 0.75158,
        QE_11: 8.635,
        VE_11: 0.75158,
        QA_12: 20.072,
        VA_12: 1.02769,
        QC_12: 20.246,
        VC_12: 1.00518,
        QE_12: 20.246,
        VE_12: 1.00518,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.718284185149059, 34.73201321275576, 0.0],
          [-80.721696185143742, 34.731415012756543, 0.0],
          [-80.728525385133196, 34.735028412751035, 0.0],
          [-80.732491585127036, 34.734017079419289, 0.0],
          [-80.734573918457059, 34.735023079417601, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284111.0,
        COMID: 9753202,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989620",
        GNIS_NAME: "McLin Creek",
        LENGTHKM: 2.161,
        REACHCODE: "03050101000524",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.021393908701399001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061252.0,
        ToNode: 250061239.0,
        Hydroseq: 250037447.0,
        LevelPathI: 250037447.0,
        Pathlength: 558.208,
        TerminalPa: 250002604.0,
        ArbolateSu: 56.64,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250037447.0,
        UpHydroseq: 250039692.0,
        DnLevelPat: 250024849.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025529.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8701,
        TotDASqKM: 68.6565,
        DivDASqKM: 68.6565,
        Tidal: 0,
        TOTMA: 0.076735810049100003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23138.0,
        MAXELEVSMO: 23718.0,
        MINELEVSMO: 23138.0,
        SLOPE: 0.00268394,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.16100001335144,
        QA_MA: 31.219,
        VA_MA: 1.06581,
        QC_MA: 33.082,
        VC_MA: 1.06937,
        QE_MA: 33.082,
        VE_MA: 1.06937,
        QA_01: 60.425,
        VA_01: 1.38393,
        QC_01: 49.213,
        VC_01: 1.24804,
        QE_01: 49.213,
        VE_01: 1.24804,
        QA_02: 63.271,
        VA_02: 1.41053,
        QC_02: 55.053,
        VC_02: 1.30569,
        QE_02: 55.053,
        VE_02: 1.30569,
        QA_03: 67.941,
        VA_03: 1.45301,
        QC_03: 60.188,
        VC_03: 1.35405,
        QE_03: 60.188,
        VE_03: 1.35405,
        QA_04: 46.76,
        VA_04: 1.24704,
        QC_04: 44.839,
        VC_04: 1.20273,
        QE_04: 44.839,
        VE_04: 1.20273,
        QA_05: 30.467,
        VA_05: 1.05607,
        QC_05: 30.448,
        VC_05: 1.03657,
        QE_05: 30.448,
        VE_05: 1.03657,
        QA_06: 17.965,
        VA_06: 0.87319,
        QC_06: 23.041,
        VC_06: 0.93639,
        QE_06: 23.041,
        VE_06: 0.93639,
        QA_07: 11.123,
        VA_07: 0.74626,
        QC_07: 17.515,
        VC_07: 0.85129,
        QE_07: 17.515,
        VE_07: 0.85129,
        QA_08: 7.528,
        VA_08: 0.66428,
        QC_08: 16.26,
        VC_08: 0.83027,
        QE_08: 16.26,
        VE_08: 0.83027,
        QA_09: 6.462,
        VA_09: 0.63656,
        QC_09: 20.315,
        VC_09: 0.89577,
        QE_09: 20.315,
        VE_09: 0.89577,
        QA_10: 4.707,
        VA_10: 0.58574,
        QC_10: 18.959,
        VC_10: 0.87461,
        QE_10: 18.959,
        VE_10: 0.87461,
        QA_11: 17.334,
        VA_11: 0.86257,
        QC_11: 33.671,
        VC_11: 1.07654,
        QE_11: 33.671,
        VE_11: 1.07654,
        QA_12: 40.816,
        VA_12: 1.18166,
        QC_12: 38.618,
        VC_12: 1.13454,
        QE_12: 38.618,
        VE_12: 1.13454,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.102060717886673, 35.700500411252335, 0.0],
          [-81.095880184562986, 35.710896811236296, 0.0],
          [-81.087623117909018, 35.714558877897218, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286177.0,
        COMID: 9747424,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.159,
        REACHCODE: "03050102000012",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033477,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001528786180009,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060472.0,
        ToNode: 250060425.0,
        Hydroseq: 250012608.0,
        LevelPathI: 250011553.0,
        Pathlength: 501.121,
        TerminalPa: 250002604.0,
        ArbolateSu: 1015.495,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012700.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012522.0,
        FromMeas: 93.76427,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1413,
        TotDASqKM: 1322.0865,
        DivDASqKM: 1322.0865,
        Tidal: 0,
        TOTMA: 0.0036513741702200002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 20166.0,
        MAXELEVSMO: 20187.0,
        MINELEVSMO: 20166.0,
        SLOPE: 0.00132075,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158999994397163,
        QA_MA: 686.612,
        VA_MA: 1.6579,
        QC_MA: 645.352,
        VC_MA: 1.65115,
        QE_MA: 659.665,
        VE_MA: 1.65353,
        QA_01: 1261.689,
        VA_01: 2.17257,
        QC_01: 948.917,
        VC_01: 1.95618,
        QE_01: 953.175,
        VE_01: 1.94388,
        QA_02: 1316.325,
        VA_02: 2.21501,
        QC_02: 1054.421,
        VC_02: 2.05106,
        QE_02: 1042.234,
        VE_02: 2.02332,
        QA_03: 1422.152,
        VA_03: 2.29493,
        QC_03: 1139.527,
        VC_03: 2.12439,
        QE_03: 1152.429,
        VE_03: 2.11733,
        QA_04: 992.152,
        VA_04: 1.94923,
        QC_04: 887.57,
        VC_04: 1.89873,
        QE_04: 918.672,
        VE_04: 1.91217,
        QA_05: 707.924,
        VA_05: 1.67999,
        QC_05: 628.937,
        VC_05: 1.6329,
        QE_05: 676.106,
        VE_05: 1.67123,
        QA_06: 405.994,
        VA_06: 1.32935,
        QC_06: 498.888,
        VC_06: 1.4796,
        QE_06: 544.078,
        VE_06: 1.52274,
        QA_07: 245.606,
        VA_07: 1.0902,
        QC_07: 359.151,
        VC_07: 1.29206,
        QE_07: 390.363,
        VE_07: 1.32646,
        QA_08: 171.355,
        VA_08: 0.95416,
        QC_08: 394.022,
        VC_08: 1.34167,
        QE_08: 415.891,
        VE_08: 1.36129,
        QA_09: 146.07,
        VA_09: 0.90168,
        QC_09: 318.746,
        VC_09: 1.23165,
        QE_09: 303.911,
        VE_09: 1.19974,
        QA_10: 165.085,
        VA_10: 0.94151,
        QC_10: 325.043,
        VC_10: 1.24129,
        QE_10: 309.309,
        VE_10: 1.20811,
        QA_11: 501.266,
        VA_11: 1.45027,
        QC_11: 438.108,
        VC_11: 1.40153,
        QE_11: 365.908,
        VE_11: 1.29208,
        QA_12: 908.466,
        VA_12: 1.87422,
        QC_12: 649.778,
        VC_12: 1.65603,
        QE_12: 615.671,
        VE_12: 1.60513,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.199675984401722, 35.393399211729161, 0.0],
          [-81.198722184403266, 35.392218411730937, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288116.0,
        COMID: 9734716,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 2.18,
        REACHCODE: "03050103001068",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.021940756048552001,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059490.0,
        ToNode: 250059491.0,
        Hydroseq: 250033905.0,
        LevelPathI: 250027084.0,
        Pathlength: 411.064,
        TerminalPa: 250002604.0,
        ArbolateSu: 47.971,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250035569.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032431.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2077,
        TotDASqKM: 40.5261,
        DivDASqKM: 40.5261,
        Tidal: 0,
        TOTMA: 0.085394378153899997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14624.0,
        MAXELEVSMO: 15192.0,
        MINELEVSMO: 14641.0,
        SLOPE: 0.00252752,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1800000667572021,
        QA_MA: 15.829,
        VA_MA: 0.9649,
        QC_MA: 17.221,
        VC_MA: 0.96939,
        QE_MA: 17.221,
        VE_MA: 0.96939,
        QA_01: 35.338,
        VA_01: 1.31404,
        QC_01: 29.188,
        VC_01: 1.18314,
        QE_01: 29.188,
        VE_01: 1.18314,
        QA_02: 36.504,
        VA_02: 1.33153,
        QC_02: 32.243,
        VC_02: 1.23062,
        QE_02: 32.243,
        VE_02: 1.23062,
        QA_03: 38.709,
        VA_03: 1.36389,
        QC_03: 34.935,
        VC_03: 1.27073,
        QE_03: 34.935,
        VE_03: 1.27073,
        QA_04: 21.887,
        VA_04: 1.08817,
        QC_04: 21.353,
        VC_04: 1.04936,
        QE_04: 21.353,
        VE_04: 1.04936,
        QA_05: 12.526,
        VA_05: 0.88818,
        QC_05: 12.942,
        VC_05: 0.87637,
        QE_05: 12.942,
        VE_05: 0.87637,
        QA_06: 7.675,
        VA_06: 0.75539,
        QC_06: 9.959,
        VC_06: 0.8026,
        QE_06: 9.959,
        VE_06: 0.8026,
        QA_07: 5.994,
        VA_07: 0.7004,
        QC_07: 9.579,
        VC_07: 0.79249,
        QE_07: 9.579,
        VE_07: 0.79249,
        QA_08: 4.359,
        VA_08: 0.6394,
        QC_08: 9.313,
        VC_08: 0.78531,
        QE_08: 9.313,
        VE_08: 0.78531,
        QA_09: 3.269,
        VA_09: 0.5925,
        QC_09: 11.132,
        VC_09: 0.8327,
        QE_09: 11.132,
        VE_09: 0.8327,
        QA_10: 2.724,
        VA_10: 0.56627,
        QC_10: 12.249,
        VC_10: 0.86001,
        QE_10: 12.249,
        VE_10: 0.86001,
        QA_11: 2.705,
        VA_11: 0.56532,
        QC_11: 8.166,
        VC_11: 0.75316,
        QE_11: 8.166,
        VE_11: 0.75316,
        QA_12: 18.404,
        VA_12: 1.0196,
        QC_12: 18.709,
        VC_12: 0.99913,
        QE_12: 18.709,
        VE_12: 0.99913,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.704554785170274, 34.736306412748945, 0.0],
          [-80.709604718495825, 34.736577812748635, 0.0],
          [-80.711961518492217, 34.734904079417902, 0.0],
          [-80.716874985151264, 34.737465879413833, 0.0],
          [-80.718284185149059, 34.73201321275576, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291191.0,
        COMID: 9718429,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.472,
        REACHCODE: "03050104002484",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0051599404745780002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058242.0,
        ToNode: 250058245.0,
        Hydroseq: 250003933.0,
        LevelPathI: 250003258.0,
        Pathlength: 338.785,
        TerminalPa: 250002604.0,
        ArbolateSu: 9177.25,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003942.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003925.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2646,
        TotDASqKM: 12198.8277,
        DivDASqKM: 12198.8277,
        Tidal: 0,
        TOTMA: 0.23833443712,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6726.0,
        MAXELEVSMO: 6761.0,
        MINELEVSMO: 6761.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.472000002861023,
        QA_MA: 5776.002,
        VA_MA: -9998.0,
        QC_MA: 5776.002,
        VC_MA: -9998.0,
        QE_MA: 6474.043,
        VE_MA: -9998.0,
        QA_01: 10933.34,
        VA_01: -9998.0,
        QC_01: 10933.34,
        VC_01: -9998.0,
        QE_01: 9539.261,
        VE_01: -9998.0,
        QA_02: 11572.532,
        VA_02: -9998.0,
        QC_02: 11572.532,
        VC_02: -9998.0,
        QE_02: 9730.99,
        VE_02: -9998.0,
        QA_03: 12660.912,
        VA_03: -9998.0,
        QC_03: 12660.912,
        VC_03: -9998.0,
        QE_03: 10409.273,
        VE_03: -9998.0,
        QA_04: 8423.618,
        VA_04: -9998.0,
        QC_04: 8423.618,
        VC_04: -9998.0,
        QE_04: 8171.859,
        VE_04: -9998.0,
        QA_05: 5582.133,
        VA_05: -9998.0,
        QC_05: 5582.133,
        VC_05: -9998.0,
        QE_05: 6537.501,
        VE_05: -9998.0,
        QA_06: 3337.071,
        VA_06: -9998.0,
        QC_06: 3337.071,
        VC_06: -9998.0,
        QE_06: 5562.377,
        VE_06: -9998.0,
        QA_07: 2120.314,
        VA_07: -9998.0,
        QC_07: 2120.314,
        VC_07: -9998.0,
        QE_07: 4197.548,
        VE_07: -9998.0,
        QA_08: 1531.404,
        VA_08: -9998.0,
        QC_08: 1531.404,
        VC_08: -9998.0,
        QE_08: 3828.392,
        VE_08: -9998.0,
        QA_09: 1359.966,
        VA_09: -9998.0,
        QC_09: 1359.966,
        VC_09: -9998.0,
        QE_09: 3498.327,
        VE_09: -9998.0,
        QA_10: 1406.743,
        VA_10: -9998.0,
        QC_10: 1406.743,
        VC_10: -9998.0,
        QE_10: 4728.823,
        VE_10: -9998.0,
        QA_11: 3342.844,
        VA_11: -9998.0,
        QC_11: 3342.844,
        VC_11: -9998.0,
        QE_11: 4790.227,
        VE_11: -9998.0,
        QA_12: 7080.715,
        VA_12: -9998.0,
        QC_12: 7080.715,
        VC_12: -9998.0,
        QE_12: 6788.672,
        VE_12: -9998.0,
        LakeFract: 0.01560024362,
        SurfArea: 707446.29679699999,
        RAreaHLoad: 0.0446575425,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.753602785094245, 34.376838879973718, 0.0],
          [-80.74846731843553, 34.376704079973933, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284112.0,
        COMID: 9753208,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989620",
        GNIS_NAME: "McLin Creek",
        LENGTHKM: 1.213,
        REACHCODE: "03050101000525",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013009811614525,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061255.0,
        ToNode: 250061252.0,
        Hydroseq: 250039692.0,
        LevelPathI: 250037447.0,
        Pathlength: 560.369,
        TerminalPa: 250002604.0,
        ArbolateSu: 52.964,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250037447.0,
        UpHydroseq: 250042403.0,
        DnLevelPat: 250037447.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250037447.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3563,
        TotDASqKM: 63.1449,
        DivDASqKM: 63.1449,
        Tidal: 0,
        TOTMA: 0.043734209433,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23530.0,
        MAXELEVSMO: 24038.0,
        MINELEVSMO: 23718.0,
        SLOPE: 0.00263808,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2130000591278081,
        QA_MA: 28.793,
        VA_MA: 1.04952,
        QC_MA: 30.607,
        VC_MA: 1.0532,
        QE_MA: 30.607,
        VE_MA: 1.0532,
        QA_01: 55.656,
        VA_01: 1.36006,
        QC_01: 45.427,
        VC_01: 1.22695,
        QE_01: 45.427,
        VE_01: 1.22695,
        QA_02: 58.28,
        VA_02: 1.3861,
        QC_02: 50.825,
        VC_02: 1.28338,
        QE_02: 50.825,
        VE_02: 1.28338,
        QA_03: 62.634,
        VA_03: 1.42813,
        QC_03: 55.635,
        VC_03: 1.33133,
        QE_03: 55.635,
        VE_03: 1.33133,
        QA_04: 43.12,
        VA_04: 1.22678,
        QC_04: 41.425,
        VC_04: 1.18305,
        QE_04: 41.425,
        VE_04: 1.18305,
        QA_05: 28.144,
        VA_05: 1.0406,
        QC_05: 28.21,
        VC_05: 1.02163,
        QE_05: 28.21,
        VE_05: 1.02163,
        QA_06: 16.596,
        VA_06: 0.86153,
        QC_06: 21.308,
        VC_06: 0.9229,
        QE_06: 21.308,
        VE_06: 0.9229,
        QA_07: 10.243,
        VA_07: 0.73649,
        QC_07: 16.161,
        VC_07: 0.83899,
        QE_07: 16.161,
        VE_07: 0.83899,
        QA_08: 6.935,
        VA_08: 0.65641,
        QC_08: 14.955,
        VC_08: 0.81758,
        QE_08: 14.955,
        VE_08: 0.81758,
        QA_09: 5.943,
        VA_09: 0.62902,
        QC_09: 18.868,
        VC_09: 0.88447,
        QE_09: 18.868,
        VE_09: 0.88447,
        QA_10: 4.337,
        VA_10: 0.57963,
        QC_10: 17.757,
        VC_10: 0.8662,
        QE_10: 17.757,
        VE_10: 0.8662,
        QA_11: 16.121,
        VA_11: 0.85306,
        QC_11: 31.859,
        VC_11: 1.06922,
        QE_11: 31.859,
        VE_11: 1.06922,
        QA_12: 37.666,
        VA_12: 1.16314,
        QC_12: 35.896,
        VC_12: 1.11898,
        QE_12: 35.896,
        VE_12: 1.11898,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.114049584534655, 35.69850947792213, 0.0],
          [-81.102060717886673, 35.700500411252335, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286178.0,
        COMID: 9747422,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.645,
        REACHCODE: "03050102000013",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033477,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0063287261077850003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060471.0,
        ToNode: 250060472.0,
        Hydroseq: 250012700.0,
        LevelPathI: 250011553.0,
        Pathlength: 501.28,
        TerminalPa: 250002604.0,
        ArbolateSu: 1011.895,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012800.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012608.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1827,
        TotDASqKM: 1316.7774,
        DivDASqKM: 1316.7774,
        Tidal: 0,
        TOTMA: 0.0106455751573,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 20187.0,
        MAXELEVSMO: 21200.0,
        MINELEVSMO: 20187.0,
        SLOPE: 0.01570542,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.644999980926514,
        QA_MA: 684.327,
        VA_MA: 2.30715,
        QC_MA: 643.287,
        VC_MA: 2.29716,
        QE_MA: 657.591,
        VE_MA: 2.30071,
        QA_01: 1257.032,
        VA_01: 3.06889,
        QC_01: 945.506,
        VC_01: 2.74844,
        QE_01: 949.767,
        VE_01: 2.73019,
        QA_02: 1311.418,
        VA_02: 3.13168,
        QC_02: 1050.598,
        VC_02: 2.88889,
        QE_02: 1038.414,
        VE_02: 2.8477,
        QA_03: 1416.883,
        VA_03: 3.25006,
        QC_03: 1135.443,
        VC_03: 2.99754,
        QE_03: 1148.344,
        VE_03: 2.98708,
        QA_04: 988.869,
        VA_04: 2.73866,
        QC_04: 884.7,
        VC_04: 2.66382,
        QE_04: 915.803,
        VE_04: 2.68381,
        QA_05: 705.966,
        VA_05: 2.34046,
        QC_05: 627.262,
        VC_05: 2.2707,
        QE_05: 674.415,
        VE_05: 2.32761,
        QA_06: 404.835,
        VA_06: 1.82091,
        QC_06: 497.483,
        VC_06: 2.04344,
        QE_06: 542.662,
        VE_06: 2.10751,
        QA_07: 244.861,
        VA_07: 1.46651,
        QC_07: 358.088,
        VC_07: 1.7655,
        QE_07: 389.286,
        VE_07: 1.81658,
        QA_08: 170.784,
        VA_08: 1.26485,
        QC_08: 392.682,
        VC_08: 1.83863,
        QE_08: 414.539,
        VE_08: 1.86777,
        QA_09: 145.576,
        VA_09: 1.18708,
        QC_09: 317.795,
        VC_09: 1.676,
        QE_09: 302.948,
        VE_09: 1.62856,
        QA_10: 164.657,
        VA_10: 1.24647,
        QC_10: 324.369,
        VC_10: 1.69095,
        QE_10: 308.582,
        VE_10: 1.64154,
        QA_11: 500.288,
        VA_11: 2.00086,
        QC_11: 437.455,
        VC_11: 1.92892,
        QE_11: 365.218,
        VE_11: 1.76633,
        QA_12: 905.449,
        VA_12: 2.62755,
        QC_12: 647.814,
        VC_12: 2.30458,
        QE_12: 613.705,
        VE_12: 2.22896,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.204052917728347, 35.397884211722101, 0.0],
          [-81.199675984401722, 35.393399211729161, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288117.0,
        COMID: 9734682,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 0.339,
        REACHCODE: "03050103001069",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0035662601100249999,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059475.0,
        ToNode: 250059490.0,
        Hydroseq: 250035569.0,
        LevelPathI: 250027084.0,
        Pathlength: 413.244,
        TerminalPa: 250002604.0,
        ArbolateSu: 39.899,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250037486.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033905.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1125,
        TotDASqKM: 34.6923,
        DivDASqKM: 34.6923,
        Tidal: 0,
        TOTMA: 0.014227323214,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15192.0,
        MAXELEVSMO: 15248.0,
        MINELEVSMO: 15192.0,
        SLOPE: 0.00165191,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.33899998664856001,
        QA_MA: 13.549,
        VA_MA: 0.90044,
        QC_MA: 14.83,
        VC_MA: 0.90479,
        QE_MA: 14.83,
        VE_MA: 0.90479,
        QA_01: 30.285,
        VA_01: 1.2159,
        QC_01: 25.116,
        VC_01: 1.09732,
        QE_01: 25.116,
        VE_01: 1.09732,
        QA_02: 31.251,
        VA_02: 1.23115,
        QC_02: 27.72,
        VC_02: 1.13974,
        QE_02: 27.72,
        VE_02: 1.13974,
        QA_03: 33.119,
        VA_03: 1.26004,
        QC_03: 30.044,
        VC_03: 1.17605,
        QE_03: 30.044,
        VE_03: 1.17605,
        QA_04: 18.679,
        VA_04: 1.0105,
        QC_04: 18.289,
        VC_04: 0.97502,
        QE_04: 18.289,
        VE_04: 0.97502,
        QA_05: 10.722,
        VA_05: 0.83126,
        QC_05: 11.143,
        VC_05: 0.8208,
        QE_05: 11.143,
        VE_05: 0.8208,
        QA_06: 6.54,
        VA_06: 0.71054,
        QC_06: 8.505,
        VC_06: 0.75232,
        QE_06: 8.505,
        VE_06: 0.75232,
        QA_07: 5.131,
        VA_07: 0.66193,
        QC_07: 8.23,
        VC_07: 0.74464,
        QE_07: 8.23,
        VE_07: 0.74464,
        QA_08: 3.732,
        VA_08: 0.60694,
        QC_08: 7.948,
        VC_08: 0.73664,
        QE_08: 7.948,
        VE_08: 0.73664,
        QA_09: 2.799,
        VA_09: 0.56465,
        QC_09: 9.705,
        VC_09: 0.78455,
        QE_09: 9.705,
        VE_09: 0.78455,
        QA_10: 2.332,
        VA_10: 0.54097,
        QC_10: 10.818,
        VC_10: 0.81281,
        QE_10: 10.818,
        VE_10: 0.81281,
        QA_11: 2.32,
        VA_11: 0.54034,
        QC_11: 7.265,
        VC_11: 0.71669,
        QE_11: 7.265,
        VE_11: 0.71669,
        QA_12: 15.782,
        VA_12: 0.9504,
        QC_12: 16.268,
        VC_12: 0.93483,
        QE_12: 16.268,
        VE_12: 0.93483,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.70139091850865, 34.735231879417313, 0.0],
          [-80.704554785170274, 34.736306412748945, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284113.0,
        COMID: 9753216,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989620",
        GNIS_NAME: "McLin Creek",
        LENGTHKM: 0.936,
        REACHCODE: "03050101000526",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0097705118143250006,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061259.0,
        ToNode: 250061255.0,
        Hydroseq: 250042403.0,
        LevelPathI: 250037447.0,
        Pathlength: 561.582,
        TerminalPa: 250002604.0,
        ArbolateSu: 49.482,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250037447.0,
        UpHydroseq: 250045791.0,
        DnLevelPat: 250037447.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250039692.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5435,
        TotDASqKM: 60.2577,
        DivDASqKM: 60.2577,
        Tidal: 0,
        TOTMA: 0.0442830142969,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23823.0,
        MAXELEVSMO: 24058.0,
        MINELEVSMO: 24038.0,
        SLOPE: 0.00021367,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.93599998950958296,
        QA_MA: 27.518,
        VA_MA: 0.80011,
        QC_MA: 29.303,
        VC_MA: 0.80262,
        QE_MA: 29.303,
        VE_MA: 0.80262,
        QA_01: 53.152,
        VA_01: 1.00588,
        QC_01: 43.435,
        VC_01: 0.9175,
        QE_01: 43.435,
        VE_01: 0.9175,
        QA_02: 55.659,
        VA_02: 1.02316,
        QC_02: 48.6,
        VC_02: 0.95494,
        QE_02: 48.6,
        VE_02: 0.95494,
        QA_03: 59.847,
        VA_03: 1.05123,
        QC_03: 53.24,
        VC_03: 0.98702,
        QE_03: 53.24,
        VE_03: 0.98702,
        QA_04: 41.21,
        VA_04: 0.91771,
        QC_04: 39.63,
        VC_04: 0.88855,
        QE_04: 39.63,
        VE_04: 0.88855,
        QA_05: 26.921,
        VA_05: 0.79442,
        QC_05: 27.029,
        VC_05: 0.78187,
        QE_05: 27.029,
        VE_05: 0.78187,
        QA_06: 15.876,
        VA_06: 0.67557,
        QC_06: 20.396,
        VC_06: 0.71612,
        QE_06: 20.396,
        VE_06: 0.71612,
        QA_07: 9.781,
        VA_07: 0.5923,
        QC_07: 15.45,
        VC_07: 0.66021,
        QE_07: 15.45,
        VE_07: 0.66021,
        QA_08: 6.625,
        VA_08: 0.53924,
        QC_08: 14.273,
        VC_08: 0.64571,
        QE_08: 14.273,
        VE_08: 0.64571,
        QA_09: 5.671,
        VA_09: 0.52095,
        QC_09: 18.105,
        VC_09: 0.69112,
        QE_09: 18.105,
        VE_09: 0.69112,
        QA_10: 4.143,
        VA_10: 0.48831,
        QC_10: 17.119,
        VC_10: 0.67991,
        QE_10: 17.119,
        VE_10: 0.67991,
        QA_11: 15.485,
        VA_11: 0.67074,
        QC_11: 30.896,
        VC_11: 0.81671,
        QE_11: 30.896,
        VE_11: 0.81671,
        QA_12: 36.01,
        VA_12: 0.8756,
        QC_12: 34.458,
        VC_12: 0.84703,
        QE_12: 34.458,
        VE_12: 0.84703,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.122472317854943, 35.695486677926795, 0.0],
          [-81.119327984526592, 35.694982811260843, 0.0],
          [-81.114049584534655, 35.69850947792213, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288118.0,
        COMID: 9734684,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 0.923,
        REACHCODE: "03050103001070",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0099177485995529998,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059476.0,
        ToNode: 250059475.0,
        Hydroseq: 250037486.0,
        LevelPathI: 250027084.0,
        Pathlength: 413.583,
        TerminalPa: 250002604.0,
        ArbolateSu: 37.809,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250039737.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250035569.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7731,
        TotDASqKM: 33.2298,
        DivDASqKM: 33.2298,
        Tidal: 0,
        TOTMA: 0.039669489760299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15248.0,
        MAXELEVSMO: 15377.0,
        MINELEVSMO: 15248.0,
        SLOPE: 0.00139761,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.92299997806549094,
        QA_MA: 12.981,
        VA_MA: 0.87924,
        QC_MA: 14.233,
        VC_MA: 0.88352,
        QE_MA: 14.233,
        VE_MA: 0.88352,
        QA_01: 29.026,
        VA_01: 1.18358,
        QC_01: 24.099,
        VC_01: 1.06908,
        QE_01: 24.099,
        VE_01: 1.06908,
        QA_02: 29.934,
        VA_02: 1.19801,
        QC_02: 26.583,
        VC_02: 1.10976,
        QE_02: 26.583,
        VE_02: 1.10976,
        QA_03: 31.723,
        VA_03: 1.22586,
        QC_03: 28.819,
        VC_03: 1.14487,
        QE_03: 28.819,
        VE_03: 1.14487,
        QA_04: 17.889,
        VA_04: 0.98522,
        QC_04: 17.533,
        VC_04: 0.95086,
        QE_04: 17.533,
        VE_04: 0.95086,
        QA_05: 10.27,
        VA_05: 0.81247,
        QC_05: 10.69,
        VC_05: 0.80242,
        QE_05: 10.69,
        VE_05: 0.80242,
        QA_06: 6.265,
        VA_06: 0.69612,
        QC_06: 8.152,
        VC_06: 0.73619,
        QE_06: 8.152,
        VE_06: 0.73619,
        QA_07: 4.915,
        VA_07: 0.64924,
        QC_07: 7.892,
        VC_07: 0.72889,
        QE_07: 7.892,
        VE_07: 0.72889,
        QA_08: 3.574,
        VA_08: 0.59619,
        QC_08: 7.606,
        VC_08: 0.72073,
        QE_08: 7.606,
        VE_08: 0.72073,
        QA_09: 2.681,
        VA_09: 0.55544,
        QC_09: 9.343,
        VC_09: 0.76832,
        QE_09: 9.343,
        VE_09: 0.76832,
        QA_10: 2.234,
        VA_10: 0.53263,
        QC_10: 10.453,
        VC_10: 0.79657,
        QE_10: 10.453,
        VE_10: 0.79657,
        QA_11: 2.234,
        VA_11: 0.53263,
        QC_11: 7.057,
        VC_11: 0.70465,
        QE_11: 7.057,
        VE_11: 0.70465,
        QA_12: 15.133,
        VA_12: 0.92769,
        QC_12: 15.658,
        VC_12: 0.91341,
        QE_12: 15.658,
        VE_12: 0.91341,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.691987318523161, 34.737940412746468, 0.0],
          [-80.70139091850865, 34.735231879417313, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288111.0,
        COMID: 9734724,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 0.94,
        REACHCODE: "03050103001063",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010196681525005,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059493.0,
        ToNode: 250059510.0,
        Hydroseq: 250027958.0,
        LevelPathI: 250027084.0,
        Pathlength: 405.053,
        TerminalPa: 250002604.0,
        ArbolateSu: 75.159,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250028915.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027084.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7208,
        TotDASqKM: 71.0415,
        DivDASqKM: 71.0415,
        Tidal: 0,
        TOTMA: 0.036746750930200002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13307.0,
        MAXELEVSMO: 13602.0,
        MINELEVSMO: 13493.0,
        SLOPE: 0.00115957,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.93999999761581399,
        QA_MA: 27.629,
        VA_MA: 0.968,
        QC_MA: 29.417,
        VC_MA: 0.97136,
        QE_MA: 29.417,
        VE_MA: 0.97136,
        QA_01: 61.54,
        VA_01: 1.31757,
        QC_01: 50.097,
        VC_01: 1.18796,
        QE_01: 50.097,
        VE_01: 1.18796,
        QA_02: 63.749,
        VA_02: 1.33665,
        QC_02: 55.458,
        VC_02: 1.23675,
        QE_02: 55.458,
        VE_02: 1.23675,
        QA_03: 67.84,
        VA_03: 1.37118,
        QC_03: 60.101,
        VC_03: 1.27724,
        QE_03: 60.101,
        VE_03: 1.27724,
        QA_04: 38.426,
        VA_04: 1.09428,
        QC_04: 37.012,
        VC_04: 1.05736,
        QE_04: 37.012,
        VE_04: 1.05736,
        QA_05: 21.884,
        VA_05: 0.89121,
        QC_05: 22.143,
        VC_05: 0.87854,
        QE_05: 22.143,
        VE_05: 0.87854,
        QA_06: 13.508,
        VA_06: 0.75949,
        QC_06: 17.393,
        VC_06: 0.80993,
        QE_06: 17.393,
        VE_06: 0.80993,
        QA_07: 10.507,
        VA_07: 0.70315,
        QC_07: 16.567,
        VC_07: 0.79714,
        QE_07: 16.567,
        VE_07: 0.79714,
        QA_08: 7.641,
        VA_08: 0.64172,
        QC_08: 16.51,
        VC_08: 0.79625,
        QE_08: 16.51,
        VE_08: 0.79625,
        QA_09: 5.731,
        VA_09: 0.59451,
        QC_09: 18.273,
        VC_09: 0.82325,
        QE_09: 18.273,
        VE_09: 0.82325,
        QA_10: 4.776,
        VA_10: 0.56811,
        QC_10: 19.179,
        VC_10: 0.83665,
        QE_10: 19.179,
        VE_10: 0.83665,
        QA_11: 4.441,
        VA_11: 0.55827,
        QC_11: 11.919,
        VC_11: 0.71874,
        QE_11: 11.919,
        VE_11: 0.71874,
        QA_12: 31.746,
        VA_12: 1.0185,
        QC_12: 30.725,
        VC_12: 0.98686,
        QE_12: 30.725,
        VE_12: 0.98686,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.763841718411697, 34.730576212757796, 0.0],
          [-80.773718918396355, 34.730676212757714, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291194.0,
        COMID: 9718435,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 1.582,
        REACHCODE: "03050104002487",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.016924117660276999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058245.0,
        ToNode: 250058258.0,
        Hydroseq: 250003925.0,
        LevelPathI: 250003258.0,
        Pathlength: 337.203,
        TerminalPa: 250002604.0,
        ArbolateSu: 9181.899,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003933.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1769,
        TotDASqKM: 12205.5705,
        DivDASqKM: 12205.5705,
        Tidal: 0,
        TOTMA: 0.79882432086,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6742.0,
        MAXELEVSMO: 6761.0,
        MINELEVSMO: 6761.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5820000171661379,
        QA_MA: 5778.436,
        VA_MA: -9998.0,
        QC_MA: 5778.436,
        VC_MA: -9998.0,
        QE_MA: 6476.481,
        VE_MA: -9998.0,
        QA_01: 10938.836,
        VA_01: -9998.0,
        QC_01: 10938.836,
        VC_01: -9998.0,
        QE_01: 9544.744,
        VE_01: -9998.0,
        QA_02: 11578.253,
        VA_02: -9998.0,
        QC_02: 11578.253,
        VC_02: -9998.0,
        QE_02: 9736.697,
        VE_02: -9998.0,
        QA_03: 12667.003,
        VA_03: -9998.0,
        QC_03: 12667.003,
        VC_03: -9998.0,
        QE_03: 10415.344,
        VE_03: -9998.0,
        QA_04: 8426.972,
        VA_04: -9998.0,
        QC_04: 8426.972,
        VC_04: -9998.0,
        QE_04: 8175.209,
        VE_04: -9998.0,
        QA_05: 5584.037,
        VA_05: -9998.0,
        QC_05: 5584.037,
        VC_05: -9998.0,
        QE_05: 6539.405,
        VE_05: -9998.0,
        QA_06: 3338.34,
        VA_06: -9998.0,
        QC_06: 3338.34,
        VC_06: -9998.0,
        QE_06: 5563.659,
        VE_06: -9998.0,
        QA_07: 2121.22,
        VA_07: -9998.0,
        QC_07: 2121.22,
        VC_07: -9998.0,
        QE_07: 4198.466,
        VE_07: -9998.0,
        QA_08: 1532.13,
        VA_08: -9998.0,
        QC_08: 1532.13,
        VC_08: -9998.0,
        QE_08: 3829.133,
        VE_08: -9998.0,
        QA_09: 1360.51,
        VA_09: -9998.0,
        QC_09: 1360.51,
        VC_09: -9998.0,
        QE_09: 3498.883,
        VE_09: -9998.0,
        QA_10: 1407.196,
        VA_10: -9998.0,
        QC_10: 1407.196,
        VC_10: -9998.0,
        QE_10: 4729.297,
        VE_10: -9998.0,
        QA_11: 3343.207,
        VA_11: -9998.0,
        QC_11: 3343.207,
        VC_11: -9998.0,
        QE_11: 4790.6,
        VE_11: -9998.0,
        QA_12: 7083.093,
        VA_12: -9998.0,
        QC_12: 7083.093,
        VC_12: -9998.0,
        QE_12: 6791.045,
        VE_12: -9998.0,
        LakeFract: 0.05230693662,
        SurfArea: 2372036.5854500001,
        RAreaHLoad: 0.14967845809,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.74846731843553, 34.376704079973933, 0.0],
          [-80.735130718456219, 34.374261813310852, 0.0],
          [-80.732499185127054, 34.372418879980501, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288112.0,
        COMID: 9736106,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 1.479,
        REACHCODE: "03050103001064",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015695688407004998,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059834.0,
        ToNode: 250059493.0,
        Hydroseq: 250028915.0,
        LevelPathI: 250027084.0,
        Pathlength: 405.993,
        TerminalPa: 250002604.0,
        ArbolateSu: 61.272,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250029969.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027958.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3275,
        TotDASqKM: 53.5176,
        DivDASqKM: 53.5176,
        Tidal: 0,
        TOTMA: 0.057976257188700003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13602.0,
        MAXELEVSMO: 13852.0,
        MINELEVSMO: 13602.0,
        SLOPE: 0.00169033,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.478999972343445,
        QA_MA: 20.876,
        VA_MA: 0.96477,
        QC_MA: 22.47,
        VC_MA: 0.9687,
        QE_MA: 22.47,
        VE_MA: 0.9687,
        QA_01: 46.512,
        VA_01: 1.31278,
        QC_01: 38.142,
        VC_01: 1.18291,
        QE_01: 38.142,
        VE_01: 1.18291,
        QA_02: 48.149,
        VA_02: 1.3314,
        QC_02: 42.209,
        VC_02: 1.23125,
        QE_02: 42.209,
        VE_02: 1.23125,
        QA_03: 51.111,
        VA_03: 1.36435,
        QC_03: 45.706,
        VC_03: 1.27109,
        QE_03: 45.706,
        VE_03: 1.27109,
        QA_04: 28.994,
        VA_04: 1.08986,
        QC_04: 28.107,
        VC_04: 1.05204,
        QE_04: 28.107,
        VE_04: 1.05204,
        QA_05: 16.544,
        VA_05: 0.88852,
        QC_05: 16.916,
        VC_05: 0.87629,
        QE_05: 16.916,
        VE_05: 0.87629,
        QA_06: 10.202,
        VA_06: 0.75717,
        QC_06: 13.187,
        VC_06: 0.80595,
        QE_06: 13.187,
        VE_06: 0.80595,
        QA_07: 7.915,
        VA_07: 0.70058,
        QC_07: 12.566,
        VC_07: 0.79336,
        QE_07: 12.566,
        VE_07: 0.79336,
        QA_08: 5.756,
        VA_08: 0.63956,
        QC_08: 12.367,
        VC_08: 0.78927,
        QE_08: 12.367,
        VE_08: 0.78927,
        QA_09: 4.317,
        VA_09: 0.59264,
        QC_09: 14.23,
        VC_09: 0.82647,
        QE_09: 14.23,
        VE_09: 0.82647,
        QA_10: 3.598,
        VA_10: 0.56643,
        QC_10: 15.295,
        VC_10: 0.84671,
        QE_10: 15.295,
        VE_10: 0.84671,
        QA_11: 3.471,
        VA_11: 0.56156,
        QC_11: 9.877,
        VC_11: 0.73516,
        QE_11: 9.877,
        VE_11: 0.73516,
        QA_12: 24.129,
        VA_12: 1.01724,
        QC_12: 23.937,
        VC_12: 0.99125,
        QE_12: 23.937,
        VE_12: 0.99125,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.749555985100528, 34.734825412751263, 0.0],
          [-80.763841718411697, 34.730576212757796, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288113.0,
        COMID: 9734690,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 0.638,
        REACHCODE: "03050103001065",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00664867017767,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059479.0,
        ToNode: 250059834.0,
        Hydroseq: 250029969.0,
        LevelPathI: 250027084.0,
        Pathlength: 407.472,
        TerminalPa: 250002604.0,
        ArbolateSu: 57.556,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250031132.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028915.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2889,
        TotDASqKM: 49.3866,
        DivDASqKM: 49.3866,
        Tidal: 0,
        TOTMA: 0.024985121883099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13795.0,
        MAXELEVSMO: 13974.0,
        MINELEVSMO: 13852.0,
        SLOPE: 0.00191222,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.63800001144409202,
        QA_MA: 19.27,
        VA_MA: 0.96555,
        QC_MA: 20.806,
        VC_MA: 0.96964,
        QE_MA: 20.806,
        VE_MA: 0.96964,
        QA_01: 42.962,
        VA_01: 1.31432,
        QC_01: 35.305,
        VC_01: 1.184,
        QE_01: 35.305,
        VE_01: 1.184,
        QA_02: 44.444,
        VA_02: 1.33259,
        QC_02: 39.046,
        VC_02: 1.2321,
        QE_02: 39.046,
        VE_02: 1.2321,
        QA_03: 47.168,
        VA_03: 1.36546,
        QC_03: 42.292,
        VC_03: 1.27212,
        QE_03: 42.292,
        VE_03: 1.27212,
        QA_04: 26.722,
        VA_04: 1.09014,
        QC_04: 25.952,
        VC_04: 1.05198,
        QE_04: 25.952,
        VE_04: 1.05198,
        QA_05: 15.266,
        VA_05: 0.88909,
        QC_05: 15.656,
        VC_05: 0.87696,
        QE_05: 15.656,
        VE_05: 0.87696,
        QA_06: 9.394,
        VA_06: 0.75711,
        QC_06: 12.156,
        VC_06: 0.80548,
        QE_06: 12.156,
        VE_06: 0.80548,
        QA_07: 7.304,
        VA_07: 0.70099,
        QC_07: 11.618,
        VC_07: 0.79367,
        QE_07: 11.618,
        VE_07: 0.79367,
        QA_08: 5.312,
        VA_08: 0.63991,
        QC_08: 11.394,
        VC_08: 0.78868,
        QE_08: 11.394,
        VE_08: 0.78868,
        QA_09: 3.984,
        VA_09: 0.59294,
        QC_09: 13.255,
        VC_09: 0.82886,
        QE_09: 13.255,
        VE_09: 0.82886,
        QA_10: 3.32,
        VA_10: 0.56669,
        QC_10: 14.344,
        VC_10: 0.85114,
        QE_10: 14.344,
        VE_10: 0.85114,
        QA_11: 3.216,
        VA_11: 0.56236,
        QC_11: 9.317,
        VC_11: 0.74,
        QE_11: 9.317,
        VE_11: 0.74,
        QA_12: 22.313,
        VA_12: 1.01876,
        QC_12: 22.293,
        VC_12: 0.99433,
        QE_12: 22.293,
        VE_12: 0.99433,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.743870718442622, 34.736640679415189, 0.0],
          [-80.749555985100528, 34.734825412751263, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284029.0,
        COMID: 9753158,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013823",
        GNIS_NAME: "Muddy Creek",
        LENGTHKM: 1.839,
        REACHCODE: "03050101000443",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018747434620165999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061243.0,
        ToNode: 250061232.0,
        Hydroseq: 250022140.0,
        LevelPathI: 250022140.0,
        Pathlength: 658.513,
        TerminalPa: 250002604.0,
        ArbolateSu: 218.868,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250022140.0,
        UpHydroseq: 250022610.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014427.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.0439,
        TotDASqKM: 255.3399,
        DivDASqKM: 255.3399,
        Tidal: 0,
        TOTMA: 0.056333204972900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32366.0,
        MAXELEVSMO: 32595.0,
        MINELEVSMO: 32366.0,
        SLOPE: 0.00124524,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.83899998664856,
        QA_MA: 162.893,
        VA_MA: 1.24009,
        QC_MA: 161.895,
        VC_MA: 1.23962,
        QE_MA: 161.895,
        VE_MA: 1.23962,
        QA_01: 266.313,
        VA_01: 1.51798,
        QC_01: 208.628,
        VC_01: 1.37386,
        QE_01: 208.628,
        VE_01: 1.37386,
        QA_02: 281.63,
        VA_02: 1.55443,
        QC_02: 235.279,
        VC_02: 1.44409,
        QE_02: 235.279,
        VE_02: 1.44409,
        QA_03: 312.43,
        VA_03: 1.62503,
        QC_03: 263.183,
        VC_03: 1.51374,
        QE_03: 263.183,
        VE_03: 1.51374,
        QA_04: 235.428,
        VA_04: 1.44134,
        QC_04: 217.612,
        VC_04: 1.39798,
        QE_04: 217.612,
        VE_04: 1.39798,
        QA_05: 183.822,
        VA_05: 1.30185,
        QC_05: 171.761,
        VC_05: 1.26934,
        QE_05: 171.761,
        VE_05: 1.26934,
        QA_06: 101.831,
        VA_06: 1.03439,
        QC_06: 127.529,
        VC_06: 1.12881,
        QE_06: 127.529,
        VE_06: 1.12881,
        QA_07: 59.377,
        VA_07: 0.85357,
        QC_07: 89.827,
        VC_07: 0.98949,
        QE_07: 89.827,
        VE_07: 0.98949,
        QA_08: 39.587,
        VA_08: 0.74798,
        QC_08: 88.396,
        VC_08: 0.98371,
        QE_08: 88.396,
        VE_08: 0.98371,
        QA_09: 29.894,
        VA_09: 0.68708,
        QC_09: 78.551,
        VC_09: 0.94267,
        QE_09: 78.551,
        VE_09: 0.94267,
        QA_10: 57.715,
        VA_10: 0.84542,
        QC_10: 140.392,
        VC_10: 1.17175,
        QE_10: 140.392,
        VE_10: 1.17175,
        QA_11: 168.138,
        VA_11: 1.2559,
        QC_11: 190.453,
        VC_11: 1.32352,
        QE_11: 190.453,
        VE_11: 1.32352,
        QA_12: 219.725,
        VA_12: 1.40056,
        QC_12: 178.615,
        VC_12: 1.28951,
        QE_12: 178.615,
        VE_12: 1.28951,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.860537183376053, 35.705550477911231, 0.0],
          [-81.854134516719284, 35.71187041123477, 0.0],
          [-81.847760583395825, 35.711892477901472, 0.0],
          [-81.846047316731813, 35.713609677898773, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286151.0,
        COMID: 9756988,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.682,
        REACHCODE: "03050101002828",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.037974183304426,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062043.0,
        ToNode: 250062041.0,
        Hydroseq: 250007245.0,
        LevelPathI: 250003258.0,
        Pathlength: 439.243,
        TerminalPa: 250002604.0,
        ArbolateSu: 5904.329,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007273.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007216.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 12.33,
        TotDASqKM: 7816.662,
        DivDASqKM: 7816.662,
        Tidal: 0,
        TOTMA: 1.2643999717100001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17292.0,
        MAXELEVSMO: 17319.0,
        MINELEVSMO: 17292.0,
        SLOPE: 7.332e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.6819999217987061,
        QA_MA: 4064.886,
        VA_MA: -9998.0,
        QC_MA: 4064.886,
        VC_MA: -9998.0,
        QE_MA: 4314.899,
        VE_MA: -9998.0,
        QA_01: 7218.041,
        VA_01: -9998.0,
        QC_01: 7218.041,
        VC_01: -9998.0,
        QE_01: 7377.76,
        VE_01: -9998.0,
        QA_02: 7639.35,
        VA_02: -9998.0,
        QC_02: 7639.35,
        VC_02: -9998.0,
        QE_02: 7652.213,
        VE_02: -9998.0,
        QA_03: 8427.33,
        VA_03: -9998.0,
        QC_03: 8427.33,
        VC_03: -9998.0,
        QE_03: 8647.381,
        VE_03: -9998.0,
        QA_04: 5988.982,
        VA_04: -9998.0,
        QC_04: 5988.982,
        VC_04: -9998.0,
        QE_04: 6154.55,
        VE_04: -9998.0,
        QA_05: 4222.785,
        VA_05: -9998.0,
        QC_05: 4222.785,
        VC_05: -9998.0,
        QE_05: 4542.148,
        VE_05: -9998.0,
        QA_06: 2472.293,
        VA_06: -9998.0,
        QC_06: 2472.293,
        VC_06: -9998.0,
        QE_06: 2821.151,
        VE_06: -9998.0,
        QA_07: 1503.477,
        VA_07: -9998.0,
        QC_07: 1503.477,
        VC_07: -9998.0,
        QE_07: 1759.832,
        VE_07: -9998.0,
        QA_08: 1064.096,
        VA_08: -9998.0,
        QC_08: 1064.096,
        VC_08: -9998.0,
        QE_08: 1475.005,
        VE_08: -9998.0,
        QA_09: 984.103,
        VA_09: -9998.0,
        QC_09: 984.103,
        VC_09: -9998.0,
        QE_09: 1105.065,
        VE_09: -9998.0,
        QA_10: 1111.628,
        VA_10: -9998.0,
        QC_10: 1111.628,
        VC_10: -9998.0,
        QE_10: 1387.333,
        VE_10: -9998.0,
        QA_11: 2974.211,
        VA_11: -9998.0,
        QC_11: 2974.211,
        VC_11: -9998.0,
        QE_11: 3136.713,
        VE_11: -9998.0,
        QA_12: 5200.049,
        VA_12: -9998.0,
        QC_12: 5200.049,
        VC_12: -9998.0,
        QE_12: 5200.8,
        VE_12: -9998.0,
        LakeFract: 0.10488166652,
        SurfArea: 4749550.39,
        RAreaHLoad: 0.45518697512,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.041937917980022, 35.027706278963365, 0.0],
          [-81.031460717996254, 35.017809278978689, 0.0],
          [-81.020775784679472, 35.017522212312599, 0.0],
          [-81.015668384687501, 35.022159612305302, 0.0],
          [-81.010642718028635, 35.0211386789735, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288114.0,
        COMID: 9734688,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222705",
        GNIS_NAME: "Gills Creek",
        LENGTHKM: 1.189,
        REACHCODE: "03050103001066",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012033128922211001,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059478.0,
        ToNode: 250059479.0,
        Hydroseq: 250031132.0,
        LevelPathI: 250027084.0,
        Pathlength: 408.11,
        TerminalPa: 250002604.0,
        ArbolateSu: 55.34,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250027084.0,
        UpHydroseq: 250032431.0,
        DnLevelPat: 250027084.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029969.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9873,
        TotDASqKM: 47.3634,
        DivDASqKM: 47.3634,
        Tidal: 0,
        TOTMA: 0.046528152234200003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13958.0,
        MAXELEVSMO: 14217.0,
        MINELEVSMO: 13974.0,
        SLOPE: 0.00204373,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.189000010490417,
        QA_MA: 18.482,
        VA_MA: 0.96618,
        QC_MA: 19.987,
        VC_MA: 0.97037,
        QE_MA: 19.987,
        VE_MA: 0.97037,
        QA_01: 41.221,
        VA_01: 1.3155,
        QC_01: 33.911,
        VC_01: 1.18491,
        QE_01: 33.911,
        VE_01: 1.18491,
        QA_02: 42.621,
        VA_02: 1.33352,
        QC_02: 37.487,
        VC_02: 1.23283,
        QE_02: 37.487,
        VE_02: 1.23283,
        QA_03: 45.236,
        VA_03: 1.36645,
        QC_03: 40.616,
        VC_03: 1.27303,
        QE_03: 40.616,
        VE_03: 1.27303,
        QA_04: 25.606,
        VA_04: 1.09052,
        QC_04: 24.893,
        VC_04: 1.05221,
        QE_04: 24.893,
        VE_04: 1.05221,
        QA_05: 14.641,
        VA_05: 0.88964,
        QC_05: 15.038,
        VC_05: 0.87755,
        QE_05: 15.038,
        VE_05: 0.87755,
        QA_06: 8.988,
        VA_06: 0.75697,
        QC_06: 11.637,
        VC_06: 0.80511,
        QE_06: 11.637,
        VE_06: 0.80511,
        QA_07: 7.005,
        VA_07: 0.70136,
        QC_07: 11.153,
        VC_07: 0.79403,
        QE_07: 11.153,
        VE_07: 0.79403,
        QA_08: 5.094,
        VA_08: 0.6402,
        QC_08: 10.918,
        VC_08: 0.78857,
        QE_08: 10.918,
        VE_08: 0.78857,
        QA_09: 3.821,
        VA_09: 0.59322,
        QC_09: 12.775,
        VC_09: 0.83033,
        QE_09: 12.775,
        VE_09: 0.83033,
        QA_10: 3.184,
        VA_10: 0.56693,
        QC_10: 13.873,
        VC_10: 0.85369,
        QE_10: 13.873,
        VE_10: 0.85369,
        QA_11: 3.091,
        VA_11: 0.56289,
        QC_11: 9.041,
        VC_11: 0.7428,
        QE_11: 9.041,
        VE_11: 0.7428,
        QA_12: 21.416,
        VA_12: 1.01972,
        QC_12: 21.475,
        VC_12: 0.9961,
        QE_12: 21.475,
        VE_12: 0.9961,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.734573918457059, 34.735023079417601, 0.0],
          [-80.737435118452652, 34.737700412746847, 0.0],
          [-80.742314985111761, 34.735199012750741, 0.0],
          [-80.743870718442622, 34.736640679415189, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291205.0,
        COMID: 9718461,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 2.186,
        REACHCODE: "03050104002498",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.022146270191979001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058258.0,
        ToNode: 250058271.0,
        Hydroseq: 250003917.0,
        LevelPathI: 250003258.0,
        Pathlength: 335.017,
        TerminalPa: 250002604.0,
        ArbolateSu: 9218.775,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003925.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003910.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.6656,
        TotDASqKM: 12255.5754,
        DivDASqKM: 12255.5754,
        Tidal: 0,
        TOTMA: 1.1038116090900001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6511.0,
        MAXELEVSMO: 6761.0,
        MINELEVSMO: 6586.0,
        SLOPE: 0.00080054,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1860001087188721,
        QA_MA: 5796.788,
        VA_MA: -9998.0,
        QC_MA: 5796.788,
        VC_MA: -9998.0,
        QE_MA: 6494.855,
        VE_MA: -9998.0,
        QA_01: 10980.142,
        VA_01: -9998.0,
        QC_01: 10980.142,
        VC_01: -9998.0,
        QE_01: 9585.956,
        VE_01: -9998.0,
        QA_02: 11621.063,
        VA_02: -9998.0,
        QC_02: 11621.063,
        VC_02: -9998.0,
        QE_02: 9779.41,
        VE_02: -9998.0,
        QA_03: 12712.698,
        VA_03: -9998.0,
        QC_03: 12712.698,
        VC_03: -9998.0,
        QE_03: 10460.89,
        VE_03: -9998.0,
        QA_04: 8452.589,
        VA_04: -9998.0,
        QC_04: 8452.589,
        VC_04: -9998.0,
        QE_04: 8200.79,
        VE_04: -9998.0,
        QA_05: 5598.406,
        VA_05: -9998.0,
        QC_05: 5598.406,
        VC_05: -9998.0,
        QE_05: 6553.777,
        VE_05: -9998.0,
        QA_06: 3347.752,
        VA_06: -9998.0,
        QC_06: 3347.752,
        VC_06: -9998.0,
        QE_06: 5573.164,
        VE_06: -9998.0,
        QA_07: 2128.388,
        VA_07: -9998.0,
        QC_07: 2128.388,
        VC_07: -9998.0,
        QE_07: 4205.718,
        VE_07: -9998.0,
        QA_08: 1537.508,
        VA_08: -9998.0,
        QC_08: 1537.508,
        VC_08: -9998.0,
        QE_08: 3834.627,
        VE_08: -9998.0,
        QA_09: 1364.544,
        VA_09: -9998.0,
        QC_09: 1364.544,
        VC_09: -9998.0,
        QE_09: 3503.011,
        VE_09: -9998.0,
        QA_10: 1410.558,
        VA_10: -9998.0,
        QC_10: 1410.558,
        VC_10: -9998.0,
        QE_10: 4732.813,
        VE_10: -9998.0,
        QA_11: 3345.896,
        VA_11: -9998.0,
        QC_11: 3345.896,
        VC_11: -9998.0,
        QE_11: 4793.37,
        VE_11: -9998.0,
        QA_12: 7101.608,
        VA_12: -9998.0,
        QC_12: 7101.608,
        VC_12: -9998.0,
        QE_12: 6809.516,
        VE_12: -9998.0,
        LakeFract: 0.07248249742,
        SurfArea: 3286966.25716,
        RAreaHLoad: 0.20682497435,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.732499185127054, 34.372418879980501, 0.0],
          [-80.725057185138496, 34.365454279991297, 0.0],
          [-80.714678918488005, 34.359867013333428, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284030.0,
        COMID: 9753206,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1013823",
        GNIS_NAME: "Muddy Creek",
        LENGTHKM: 1.227,
        REACHCODE: "03050101000444",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011738350142389,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061254.0,
        ToNode: 250061243.0,
        Hydroseq: 250022610.0,
        LevelPathI: 250022140.0,
        Pathlength: 660.352,
        TerminalPa: 250002604.0,
        ArbolateSu: 213.726,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250022140.0,
        UpHydroseq: 250023107.0,
        DnLevelPat: 250022140.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022140.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5732,
        TotDASqKM: 250.74,
        DivDASqKM: 250.74,
        Tidal: 0,
        TOTMA: 0.037575796517500003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32530.0,
        MAXELEVSMO: 32752.0,
        MINELEVSMO: 32595.0,
        SLOPE: 0.00127954,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2269999980926509,
        QA_MA: 160.292,
        VA_MA: 1.24038,
        QC_MA: 159.41,
        VC_MA: 1.23996,
        QE_MA: 159.41,
        VE_MA: 1.23996,
        QA_01: 261.784,
        VA_01: 1.51768,
        QC_01: 205.172,
        VC_01: 1.37355,
        QE_01: 205.172,
        VE_01: 1.37355,
        QA_02: 276.838,
        VA_02: 1.55412,
        QC_02: 231.384,
        VC_02: 1.44377,
        QE_02: 231.384,
        VE_02: 1.44377,
        QA_03: 307.256,
        VA_03: 1.62502,
        QC_03: 258.967,
        VC_03: 1.51374,
        QE_03: 258.967,
        VE_03: 1.51374,
        QA_04: 231.559,
        VA_04: 1.44141,
        QC_04: 214.116,
        VC_04: 1.39796,
        QE_04: 214.116,
        VE_04: 1.39796,
        QA_05: 180.92,
        VA_05: 1.30225,
        QC_05: 169.15,
        VC_05: 1.26976,
        QE_05: 169.15,
        VE_05: 1.26976,
        QA_06: 100.209,
        VA_06: 1.03464,
        QC_06: 125.526,
        VC_06: 1.12895,
        QE_06: 125.526,
        VE_06: 1.12895,
        QA_07: 58.435,
        VA_07: 0.85377,
        QC_07: 88.436,
        VC_07: 0.98969,
        QE_07: 88.436,
        VE_07: 0.98969,
        QA_08: 38.914,
        VA_08: 0.74788,
        QC_08: 86.864,
        VC_08: 0.98323,
        QE_08: 86.864,
        VE_08: 0.98323,
        QA_09: 29.399,
        VA_09: 0.68708,
        QC_09: 77.402,
        VC_09: 0.94314,
        QE_09: 77.402,
        VE_09: 0.94314,
        QA_10: 57.315,
        VA_10: 0.8482,
        QC_10: 139.614,
        VC_10: 1.17663,
        QE_10: 139.614,
        VE_10: 1.17663,
        QA_11: 165.774,
        VA_11: 1.25717,
        QC_11: 188.408,
        VC_11: 1.32641,
        QE_11: 188.408,
        VE_11: 1.32641,
        QA_12: 216.285,
        VA_12: 1.40109,
        QC_12: 176.069,
        VC_12: 1.29045,
        QE_12: 176.069,
        VE_12: 1.29045,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.865647583368116, 35.696344611258787, 0.0],
          [-81.864833116702698, 35.701886611250131, 0.0],
          [-81.860537183376053, 35.705550477911231, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286163.0,
        COMID: 9747440,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 9.959,
        REACHCODE: "03050102000003",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.099298132894838007,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060479.0,
        ToNode: 250060480.0,
        Hydroseq: 250011795.0,
        LevelPathI: 250011553.0,
        Pathlength: 475.958,
        TerminalPa: 250002604.0,
        ArbolateSu: 1160.717,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250011884.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011712.0,
        FromMeas: 0.0,
        ToMeas: 95.18414,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 30.3795,
        TotDASqKM: 1639.737,
        DivDASqKM: 1639.737,
        Tidal: 0,
        TOTMA: 0.217389030727,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17332.0,
        MAXELEVSMO: 18846.0,
        MINELEVSMO: 17423.0,
        SLOPE: 0.00142885,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 9.9589996337890625,
        QA_MA: 820.212,
        VA_MA: 1.73983,
        QC_MA: 765.623,
        VC_MA: 1.73187,
        QE_MA: 818.554,
        VE_MA: 1.7396,
        QA_01: 1539.84,
        VA_01: 2.30838,
        QC_01: 1152.083,
        VC_01: 2.07682,
        QE_01: 1137.061,
        VE_01: 2.01249,
        QA_02: 1610.425,
        VA_02: 2.35655,
        QC_02: 1282.934,
        VC_02: 2.18078,
        QE_02: 1261.959,
        VE_02: 2.10945,
        QA_03: 1733.971,
        VA_03: 2.43854,
        QC_03: 1380.315,
        VC_03: 2.25495,
        QE_03: 1388.552,
        VE_03: 2.20324,
        QA_04: 1179.642,
        VA_04: 2.0445,
        QC_04: 1051.153,
        VC_04: 1.99278,
        QE_04: 1043.814,
        VE_04: 1.9368,
        QA_05: 818.496,
        VA_05: 1.73824,
        QC_05: 723.234,
        VC_05: 1.68946,
        QE_05: 814.767,
        VE_05: 1.73608,
        QA_06: 471.848,
        VA_06: 1.37565,
        QC_06: 578.613,
        VC_06: 1.53517,
        QE_06: 633.36,
        VE_06: 1.55736,
        QA_07: 288.564,
        VA_07: 1.13038,
        QC_07: 420.344,
        VC_07: 1.34371,
        QE_07: 496.809,
        VE_07: 1.40626,
        QA_08: 203.62,
        VA_08: 0.99145,
        QC_08: 469.832,
        VC_08: 1.40674,
        QE_08: 541.761,
        VE_08: 1.45794,
        QA_09: 174.387,
        VA_09: 0.93749,
        QC_09: 372.724,
        VC_09: 1.27967,
        QE_09: 433.125,
        VE_09: 1.32913,
        QA_10: 188.5,
        VA_10: 0.96403,
        QC_10: 361.373,
        VC_10: 1.26385,
        QE_10: 649.802,
        VE_10: 1.57447,
        QA_11: 552.222,
        VA_11: 1.46861,
        QC_11: 471.678,
        VC_11: 1.40903,
        QE_11: 675.419,
        VE_11: 1.60073,
        QA_12: 1086.737,
        VA_12: 1.9705,
        QC_12: 764.829,
        VC_12: 1.73108,
        QE_12: 774.469,
        VE_12: 1.69814,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.110284384540535, 35.309345011859477, 0.0],
          [-81.100830184555264, 35.30902347852674, 0.0],
          [-81.095201317897249, 35.301657678538106, 0.0],
          [-81.097822717893166, 35.298599411876182, 0.0],
          [-81.09791951789316, 35.29209847855293, 0.0],
          [-81.105374584548201, 35.288434411892013, 0.0],
          [-81.099737184556943, 35.285409678563326, 0.0],
          [-81.096147984562549, 35.287394878560235, 0.0],
          [-81.083041317916241, 35.281246211903181, 0.0],
          [-81.078832984589383, 35.27609561191116, 0.0],
          [-81.082431517917144, 35.272348011916904, 0.0],
          [-81.082164384584246, 35.270278478586818, 0.0],
          [-81.078698584589631, 35.26820787859009, 0.0],
          [-81.07669618459272, 35.262117211932832, 0.0],
          [-81.072669917932274, 35.258617878605037, 0.0],
          [-81.070796651268552, 35.254443278611518, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286164.0,
        COMID: 9747432,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.065,
        REACHCODE: "03050102000003",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00068106347548499999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060476.0,
        ToNode: 250060479.0,
        Hydroseq: 250011884.0,
        LevelPathI: 250011553.0,
        Pathlength: 485.917,
        TerminalPa: 250002604.0,
        ArbolateSu: 1150.758,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250011975.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011795.0,
        FromMeas: 95.18414,
        ToMeas: 95.8055,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 1609.3575,
        DivDASqKM: 1609.3575,
        Tidal: 0,
        TOTMA: 0.0025890285171099999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 18846.0,
        MAXELEVSMO: 18846.0,
        MINELEVSMO: 18846.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064999997615814001,
        QA_MA: 808.498,
        VA_MA: 0.95615,
        QC_MA: 755.109,
        VC_MA: 0.95258,
        QE_MA: 766.168,
        VE_MA: 0.95334,
        QA_01: 1514.594,
        VA_01: 1.21241,
        QC_01: 1133.686,
        VC_01: 1.10772,
        QE_01: 1133.029,
        VE_01: 1.10227,
        QA_02: 1583.275,
        VA_02: 1.23395,
        QC_02: 1261.89,
        VC_02: 1.15451,
        QE_02: 1240.201,
        VE_02: 1.14129,
        QA_03: 1705.484,
        VA_03: 1.27122,
        QC_03: 1358.38,
        VC_03: 1.18827,
        QE_03: 1361.283,
        VE_03: 1.18353,
        QA_04: 1163.329,
        VA_04: 1.09422,
        QC_04: 1036.946,
        VC_04: 1.07075,
        QE_04: 1061.756,
        VE_04: 1.07534,
        QA_05: 809.091,
        VA_05: 0.9564,
        QC_05: 715.233,
        VC_05: 0.93428,
        QE_05: 766.816,
        VE_05: 0.95363,
        QA_06: 466.129,
        VA_06: 0.79191,
        QC_06: 571.697,
        VC_06: 0.86409,
        QE_06: 618.929,
        VE_06: 0.88424,
        QA_07: 284.879,
        VA_07: 0.68066,
        QC_07: 415.104,
        VC_07: 0.77724,
        QE_07: 445.179,
        VE_07: 0.7918,
        QA_08: 200.761,
        VA_08: 0.61753,
        QC_08: 463.103,
        VC_08: 0.80528,
        QE_08: 485.079,
        VE_08: 0.81435,
        QA_09: 171.924,
        VA_09: 0.5931,
        QC_09: 368.072,
        VC_09: 0.74823,
        QE_09: 345.744,
        VE_09: 0.73108,
        QA_10: 186.458,
        VA_10: 0.60564,
        QC_10: 358.242,
        VC_10: 0.74196,
        QE_10: 348.838,
        VE_10: 0.73309,
        QA_11: 550.026,
        VA_11: 0.83633,
        QC_11: 470.247,
        VC_11: 0.80934,
        QE_11: 389.277,
        VE_11: 0.75856,
        QA_12: 1071.608,
        VA_12: 1.06069,
        QC_12: 755.135,
        VC_12: 0.95259,
        QE_12: 709.57,
        VE_12: 0.92758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.110889584539564, 35.309074011859991, 0.0],
          [-81.110284384540535, 35.309345011859477, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286165.0,
        COMID: 9747434,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.437,
        REACHCODE: "03050102000003",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0045569857517120003,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060477.0,
        ToNode: 250060476.0,
        Hydroseq: 250011975.0,
        LevelPathI: 250011553.0,
        Pathlength: 485.982,
        TerminalPa: 250002604.0,
        ArbolateSu: 1150.693,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012063.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011884.0,
        FromMeas: 95.8055,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2376,
        TotDASqKM: 1609.3503,
        DivDASqKM: 1609.3503,
        Tidal: 0,
        TOTMA: 0.0073170904911599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 18837.0,
        MAXELEVSMO: 19320.0,
        MINELEVSMO: 18846.0,
        SLOPE: 0.01084668,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.43700000643730202,
        QA_MA: 808.495,
        VA_MA: 2.2764,
        QC_MA: 755.107,
        VC_MA: 2.26554,
        QE_MA: 766.166,
        VE_MA: 2.26785,
        QA_01: 1514.588,
        VA_01: 3.05494,
        QC_01: 1133.682,
        VC_01: 2.73688,
        QE_01: 1133.025,
        VE_01: 2.72031,
        QA_02: 1583.269,
        VA_02: 3.12039,
        QC_02: 1261.885,
        VC_02: 2.87904,
        QE_02: 1240.196,
        VE_02: 2.83888,
        QA_03: 1705.477,
        VA_03: 3.23363,
        QC_03: 1358.375,
        VC_03: 2.98162,
        QE_03: 1361.278,
        VE_03: 2.9672,
        QA_04: 1163.326,
        VA_04: 2.69586,
        QC_04: 1036.942,
        VC_04: 2.62455,
        QE_04: 1061.753,
        VE_04: 2.63852,
        QA_05: 809.089,
        VA_05: 2.27717,
        QC_05: 715.231,
        VC_05: 2.20997,
        QE_05: 766.814,
        VE_05: 2.26873,
        QA_06: 466.128,
        VA_06: 1.77741,
        QC_06: 571.695,
        VC_06: 1.99672,
        QE_06: 618.927,
        VE_06: 2.05792,
        QA_07: 284.878,
        VA_07: 1.43943,
        QC_07: 415.102,
        VC_07: 1.73283,
        QE_07: 445.178,
        VE_07: 1.77709,
        QA_08: 200.76,
        VA_08: 1.24763,
        QC_08: 463.102,
        VC_08: 1.81804,
        QE_08: 485.077,
        VE_08: 1.84558,
        QA_09: 171.923,
        VA_09: 1.17339,
        QC_09: 368.071,
        VC_09: 1.64472,
        QE_09: 345.742,
        VE_09: 1.59261,
        QA_10: 186.458,
        VA_10: 1.21148,
        QC_10: 358.241,
        VC_10: 1.62565,
        QE_10: 348.837,
        VE_10: 1.5987,
        QA_11: 550.025,
        VA_11: 1.91235,
        QC_11: 470.247,
        VC_11: 1.83037,
        QE_11: 389.277,
        VE_11: 1.67608,
        QA_12: 1071.604,
        VA_12: 2.59399,
        QC_12: 755.132,
        VC_12: 2.26558,
        QE_12: 709.567,
        VE_12: 2.18961,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.114760984533632, 35.310897411857184, 0.0],
          [-81.110889584539564, 35.309074011859991, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291179.0,
        COMID: 9718403,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.447,
        REACHCODE: "03050104002472",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013946200247205999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058229.0,
        ToNode: 250058220.0,
        Hydroseq: 250018900.0,
        LevelPathI: 250018611.0,
        Pathlength: 355.412,
        TerminalPa: 250002604.0,
        ArbolateSu: 125.291,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250019198.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018611.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.1411,
        TotDASqKM: 102.5271,
        DivDASqKM: 102.5271,
        Tidal: 0,
        TOTMA: 0.73065663247000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6782.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4470000267028811,
        QA_MA: 37.297,
        VA_MA: -9998.0,
        QC_MA: 39.251,
        VC_MA: -9998.0,
        QE_MA: 39.251,
        VE_MA: -9998.0,
        QA_01: 82.961,
        VA_01: -9998.0,
        QC_01: 67.008,
        VC_01: -9998.0,
        QE_01: 67.008,
        VE_01: -9998.0,
        QA_02: 89.332,
        VA_02: -9998.0,
        QC_02: 77.003,
        VC_02: -9998.0,
        QE_02: 77.003,
        VE_02: -9998.0,
        QA_03: 96.673,
        VA_03: -9998.0,
        QC_03: 84.65,
        VC_03: -9998.0,
        QE_03: 84.65,
        VE_03: -9998.0,
        QA_04: 52.603,
        VA_04: -9998.0,
        QC_04: 50.307,
        VC_04: -9998.0,
        QE_04: 50.307,
        VE_04: -9998.0,
        QA_05: 29.535,
        VA_05: -9998.0,
        QC_05: 29.551,
        VC_05: -9998.0,
        QE_05: 29.551,
        VE_05: -9998.0,
        QA_06: 19.299,
        VA_06: -9998.0,
        QC_06: 24.728,
        VC_06: -9998.0,
        QE_06: 24.728,
        VE_06: -9998.0,
        QA_07: 13.785,
        VA_07: -9998.0,
        QC_07: 21.596,
        VC_07: -9998.0,
        QE_07: 21.596,
        VE_07: -9998.0,
        QA_08: 11.028,
        VA_08: -9998.0,
        QC_08: 24.003,
        VC_08: -9998.0,
        QE_08: 24.003,
        VE_08: -9998.0,
        QA_09: 8.271,
        VA_09: -9998.0,
        QC_09: 25.262,
        VC_09: -9998.0,
        QE_09: 25.262,
        VE_09: -9998.0,
        QA_10: 6.892,
        VA_10: -9998.0,
        QC_10: 25.71,
        VC_10: -9998.0,
        QE_10: 25.71,
        VE_10: -9998.0,
        QA_11: 5.514,
        VA_11: -9998.0,
        QC_11: 14.057,
        VC_11: -9998.0,
        QE_11: 14.057,
        VE_11: -9998.0,
        QA_12: 31.974,
        VA_12: -9998.0,
        QC_12: 30.926,
        VC_12: -9998.0,
        QE_12: 30.926,
        VE_12: -9998.0,
        LakeFract: 0.00029068969,
        SurfArea: 13182.3162328,
        RAreaHLoad: 0.13690564406,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890095318215629, 34.409658213256023, 0.0],
          [-80.888836918217635, 34.41309401325077, 0.0],
          [-80.884061918225086, 34.414507213248498, 0.0],
          [-80.884800118223893, 34.419223213241139, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286166.0,
        COMID: 9747292,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.848,
        REACHCODE: "03050102000004",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0081225093710129998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060441.0,
        ToNode: 250060474.0,
        Hydroseq: 250012246.0,
        LevelPathI: 250011553.0,
        Pathlength: 489.163,
        TerminalPa: 250002604.0,
        ArbolateSu: 1082.287,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250012337.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012152.0,
        FromMeas: 76.45817,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7685,
        TotDASqKM: 1443.3597,
        DivDASqKM: 1443.3597,
        Tidal: 0,
        TOTMA: 0.018871621602599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19388.0,
        MAXELEVSMO: 19515.0,
        MINELEVSMO: 19388.0,
        SLOPE: 0.00149764,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.84799998998642001,
        QA_MA: 737.185,
        VA_MA: 1.71129,
        QC_MA: 690.977,
        VC_MA: 1.70395,
        QE_MA: 705.513,
        VE_MA: 1.70631,
        QA_01: 1366.518,
        VA_01: 2.25534,
        QC_01: 1025.611,
        VC_01: 2.02956,
        QE_01: 1029.807,
        VE_01: 2.01722,
        QA_02: 1427.657,
        VA_02: 2.30112,
        QC_02: 1141.074,
        VC_02: 2.12988,
        QE_02: 1128.799,
        VE_02: 2.10256,
        QA_03: 1540.667,
        VA_03: 2.38338,
        QC_03: 1231.231,
        VC_03: 2.20494,
        QE_03: 1244.168,
        VE_03: 2.19771,
        QA_04: 1064.174,
        VA_04: 2.01324,
        QC_04: 950.485,
        VC_04: 1.96143,
        QE_04: 981.556,
        VE_04: 1.97422,
        QA_05: 750.145,
        VA_05: 1.72433,
        QC_05: 665.004,
        VC_05: 1.67585,
        QE_05: 712.558,
        VE_05: 1.7137,
        QA_06: 431.135,
        VA_06: 1.36356,
        QC_06: 529.344,
        VC_06: 1.51982,
        QE_06: 574.764,
        VE_06: 1.56215,
        QA_07: 262.178,
        VA_07: 1.11864,
        QC_07: 382.786,
        VC_07: 1.32826,
        QE_07: 414.319,
        VE_07: 1.36212,
        QA_08: 183.567,
        VA_08: 0.97892,
        QC_08: 422.685,
        VC_08: 1.38339,
        QE_08: 444.856,
        VE_08: 1.40267,
        QA_09: 156.534,
        VA_09: 0.92453,
        QC_09: 338.825,
        VC_09: 1.26429,
        QE_09: 324.243,
        VE_09: 1.23351,
        QA_10: 174.077,
        VA_10: 0.96028,
        QC_10: 339.11,
        VC_10: 1.26471,
        QE_10: 324.587,
        VE_10: 1.23403,
        QA_11: 519.346,
        VA_11: 1.47319,
        QC_11: 450.107,
        VC_11: 1.41988,
        QE_11: 378.763,
        VE_11: 1.3131,
        QA_12: 975.281,
        VA_12: 1.93607,
        QC_12: 693.117,
        VC_12: 1.70624,
        QE_12: 659.051,
        VE_12: 1.65664,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.133491184504578, 35.33104421182594, 0.0],
          [-81.130444717842522, 35.328502211829857, 0.0],
          [-81.131785717840501, 35.324700278502412, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291181.0,
        COMID: 9718407,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.086,
        REACHCODE: "03050104002474",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00080820566012700004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058231.0,
        ToNode: 250058229.0,
        Hydroseq: 250019198.0,
        LevelPathI: 250018611.0,
        Pathlength: 356.859,
        TerminalPa: 250002604.0,
        ArbolateSu: 121.865,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250019523.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018900.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 99.0144,
        DivDASqKM: 99.0144,
        Tidal: 0,
        TOTMA: 0.04342534771,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6787.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.086000002920627996,
        QA_MA: 36.043,
        VA_MA: -9998.0,
        QC_MA: 37.981,
        VC_MA: -9998.0,
        QE_MA: 37.981,
        VE_MA: -9998.0,
        QA_01: 80.178,
        VA_01: -9998.0,
        QC_01: 64.818,
        VC_01: -9998.0,
        QE_01: 64.818,
        VE_01: -9998.0,
        QA_02: 86.346,
        VA_02: -9998.0,
        QC_02: 74.497,
        VC_02: -9998.0,
        QE_02: 74.497,
        VE_02: -9998.0,
        QA_03: 93.428,
        VA_03: -9998.0,
        QC_03: 81.901,
        VC_03: -9998.0,
        QE_03: 81.901,
        VE_03: -9998.0,
        QA_04: 50.851,
        VA_04: -9998.0,
        QC_04: 48.669,
        VC_04: -9998.0,
        QE_04: 48.669,
        VE_04: -9998.0,
        QA_05: 28.543,
        VA_05: -9998.0,
        QC_05: 28.595,
        VC_05: -9998.0,
        QE_05: 28.595,
        VE_05: -9998.0,
        QA_06: 18.638,
        VA_06: -9998.0,
        QC_06: 23.892,
        VC_06: -9998.0,
        QE_06: 23.892,
        VE_06: -9998.0,
        QA_07: 13.313,
        VA_07: -9998.0,
        QC_07: 20.873,
        VC_07: -9998.0,
        QE_07: 20.873,
        VE_07: -9998.0,
        QA_08: 10.65,
        VA_08: -9998.0,
        QC_08: 23.164,
        VC_08: -9998.0,
        QE_08: 23.164,
        VE_08: -9998.0,
        QA_09: 7.988,
        VA_09: -9998.0,
        QC_09: 24.497,
        VC_09: -9998.0,
        QE_09: 24.497,
        VE_09: -9998.0,
        QA_10: 6.656,
        VA_10: -9998.0,
        QC_10: 25.004,
        VC_10: -9998.0,
        QE_10: 25.004,
        VE_10: -9998.0,
        QA_11: 5.325,
        VA_11: -9998.0,
        QC_11: 13.688,
        VC_11: -9998.0,
        QE_11: 13.688,
        VE_11: -9998.0,
        QA_12: 30.892,
        VA_12: -9998.0,
        QC_12: 29.971,
        VC_12: -9998.0,
        QE_12: 29.971,
        VE_12: -9998.0,
        LakeFract: 1.672046e-5,
        SurfArea: 758.24633229300002,
        RAreaHLoad: 0.00813675663,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.889644118216381, 34.408987679923712, 0.0],
          [-80.890095318215629, 34.409658213256023, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286162.0,
        COMID: 9747442,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.422,
        REACHCODE: "03050102000002",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12035359,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013218591174666,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060480.0,
        ToNode: 250060481.0,
        Hydroseq: 250011712.0,
        LevelPathI: 250011553.0,
        Pathlength: 474.536,
        TerminalPa: 250002604.0,
        ArbolateSu: 1164.849,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250011795.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250011633.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.557,
        TotDASqKM: 1645.4439,
        DivDASqKM: 1645.4439,
        Tidal: 0,
        TOTMA: 0.033918453395900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17320.0,
        MAXELEVSMO: 17423.0,
        MINELEVSMO: 17321.0,
        SLOPE: 0.00071729,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.422000050544739,
        QA_MA: 822.349,
        VA_MA: 1.59221,
        QC_MA: 767.54,
        VC_MA: 1.58505,
        QE_MA: 820.471,
        VE_MA: 1.59197,
        QA_01: 1544.421,
        VA_01: 2.10247,
        QC_01: 1155.421,
        VC_01: 1.89475,
        QE_01: 1140.399,
        VE_01: 1.83719,
        QA_02: 1615.422,
        VA_02: 2.1458,
        QC_02: 1286.807,
        VC_02: 1.98812,
        QE_02: 1265.831,
        VE_02: 1.92429,
        QA_03: 1739.266,
        VA_03: 2.21931,
        QC_03: 1384.39,
        VC_03: 2.0546,
        QE_03: 1392.627,
        VE_03: 2.00832,
        QA_04: 1182.64,
        VA_04: 1.8654,
        QC_04: 1053.764,
        VC_04: 1.81904,
        QE_04: 1046.425,
        VE_04: 1.76895,
        QA_05: 820.235,
        VA_05: 1.59045,
        QC_05: 724.713,
        VC_05: 1.54672,
        QE_05: 816.246,
        VE_05: 1.58845,
        QA_06: 472.922,
        VA_06: 1.26541,
        QC_06: 579.913,
        VC_06: 1.40849,
        QE_06: 634.66,
        VE_06: 1.42835,
        QA_07: 289.255,
        VA_07: 1.04553,
        QC_07: 421.326,
        VC_07: 1.23684,
        QE_07: 497.791,
        VE_07: 1.29283,
        QA_08: 204.157,
        VA_08: 0.92102,
        QC_08: 471.096,
        VC_08: 1.29356,
        QE_08: 543.025,
        VE_08: 1.33936,
        QA_09: 174.848,
        VA_09: 0.87262,
        QC_09: 373.593,
        VC_09: 1.17942,
        QE_09: 433.994,
        VE_09: 1.22368,
        QA_10: 188.884,
        VA_10: 0.89624,
        QC_10: 361.961,
        VC_10: 1.16491,
        QE_10: 650.389,
        VE_10: 1.44301,
        QA_11: 552.606,
        VA_11: 1.34791,
        QC_11: 471.928,
        VC_11: 1.29448,
        QE_11: 675.669,
        VE_11: 1.46622,
        QA_12: 1089.291,
        VA_12: 1.79889,
        QC_12: 766.464,
        VC_12: 1.5841,
        QE_12: 776.104,
        VE_12: 1.55463,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.070796651268552, 35.254443278611518, 0.0],
          [-81.070313717935903, 35.252617478614297, 0.0],
          [-81.073522984597673, 35.248277611954279, 0.0],
          [-81.073082117931563, 35.242810011962831, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291177.0,
        COMID: 9718391,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 0.9,
        REACHCODE: "03050104002470",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0082044522882270005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058223.0,
        ToNode: 250058241.0,
        Hydroseq: 250003976.0,
        LevelPathI: 250003258.0,
        Pathlength: 343.967,
        TerminalPa: 250002604.0,
        ArbolateSu: 9119.64,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003986.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003964.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8467,
        TotDASqKM: 12152.6181,
        DivDASqKM: 12152.6181,
        Tidal: 0,
        TOTMA: 0.45445125714000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6738.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.89999997615814198,
        QA_MA: 5759.344,
        VA_MA: -9998.0,
        QC_MA: 5759.344,
        VC_MA: -9998.0,
        QE_MA: 6457.366,
        VE_MA: -9998.0,
        QA_01: 10895.857,
        VA_01: -9998.0,
        QC_01: 10895.857,
        VC_01: -9998.0,
        QE_01: 9501.865,
        VE_01: -9998.0,
        QA_02: 11533.266,
        VA_02: -9998.0,
        QC_02: 11533.266,
        VC_02: -9998.0,
        QE_02: 9691.814,
        VE_02: -9998.0,
        QA_03: 12618.686,
        VA_03: -9998.0,
        QC_03: 12618.686,
        VC_03: -9998.0,
        QE_03: 10367.184,
        VE_03: -9998.0,
        QA_04: 8400.585,
        VA_04: -9998.0,
        QC_04: 8400.585,
        VC_04: -9998.0,
        QE_04: 8148.86,
        VE_04: -9998.0,
        QA_05: 5569.086,
        VA_05: -9998.0,
        QC_05: 5569.086,
        VC_05: -9998.0,
        QE_05: 6524.452,
        VE_05: -9998.0,
        QA_06: 3328.372,
        VA_06: -9998.0,
        QC_06: 3328.372,
        VC_06: -9998.0,
        QE_06: 5553.593,
        VE_06: -9998.0,
        QA_07: 2114.1,
        VA_07: -9998.0,
        QC_07: 2114.1,
        VC_07: -9998.0,
        QE_07: 4191.257,
        VE_07: -9998.0,
        QA_08: 1526.434,
        VA_08: -9998.0,
        QC_08: 1526.434,
        VC_08: -9998.0,
        QE_08: 3823.314,
        VE_08: -9998.0,
        QA_09: 1356.238,
        VA_09: -9998.0,
        QC_09: 1356.238,
        VC_09: -9998.0,
        QE_09: 3494.512,
        VE_09: -9998.0,
        QA_10: 1403.636,
        VA_10: -9998.0,
        QC_10: 1403.636,
        VC_10: -9998.0,
        QE_10: 4725.574,
        VE_10: -9998.0,
        QA_11: 3340.359,
        VA_11: -9998.0,
        QC_11: 3340.359,
        VC_11: -9998.0,
        QE_11: 4787.668,
        VE_11: -9998.0,
        QA_12: 7064.999,
        VA_12: -9998.0,
        QC_12: 7064.999,
        VC_12: -9998.0,
        QE_12: 6772.997,
        VE_12: -9998.0,
        LakeFract: 0.02966960821,
        SurfArea: 1345469.65848,
        RAreaHLoad: 0.08515209373,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.784220185046649, 34.407241213259795, 0.0],
          [-80.782634118382532, 34.404314479931031, 0.0],
          [-80.783415585047919, 34.399525413271704, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291190.0,
        COMID: 9718427,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 2.654,
        REACHCODE: "03050104002483",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.025154454302422,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058241.0,
        ToNode: 250058246.0,
        Hydroseq: 250003964.0,
        LevelPathI: 250003258.0,
        Pathlength: 341.313,
        TerminalPa: 250002604.0,
        ArbolateSu: 9127.605,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003976.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003951.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.3704,
        TotDASqKM: 12162.6504,
        DivDASqKM: 12162.6504,
        Tidal: 0,
        TOTMA: 1.3401262627599999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6697.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.654000043869019,
        QA_MA: 5762.959,
        VA_MA: -9998.0,
        QC_MA: 5762.959,
        VC_MA: -9998.0,
        QE_MA: 6460.985,
        VE_MA: -9998.0,
        QA_01: 10903.969,
        VA_01: -9998.0,
        QC_01: 10903.969,
        VC_01: -9998.0,
        QE_01: 9509.958,
        VE_01: -9998.0,
        QA_02: 11541.781,
        VA_02: -9998.0,
        QC_02: 11541.781,
        VC_02: -9998.0,
        QE_02: 9700.31,
        VE_02: -9998.0,
        QA_03: 12627.858,
        VA_03: -9998.0,
        QC_03: 12627.858,
        VC_03: -9998.0,
        QE_03: 10376.327,
        VE_03: -9998.0,
        QA_04: 8405.618,
        VA_04: -9998.0,
        QC_04: 8405.618,
        VC_04: -9998.0,
        QE_04: 8153.886,
        VE_04: -9998.0,
        QA_05: 5571.918,
        VA_05: -9998.0,
        QC_05: 5571.918,
        VC_05: -9998.0,
        QE_05: 6527.285,
        VE_05: -9998.0,
        QA_06: 3330.261,
        VA_06: -9998.0,
        QC_06: 3330.261,
        VC_06: -9998.0,
        QE_06: 5555.5,
        VE_06: -9998.0,
        QA_07: 2115.45,
        VA_07: -9998.0,
        QC_07: 2115.45,
        VC_07: -9998.0,
        QE_07: 4192.624,
        VE_07: -9998.0,
        QA_08: 1527.513,
        VA_08: -9998.0,
        QC_08: 1527.513,
        VC_08: -9998.0,
        QE_08: 3824.416,
        VE_08: -9998.0,
        QA_09: 1357.047,
        VA_09: -9998.0,
        QC_09: 1357.047,
        VC_09: -9998.0,
        QE_09: 3495.34,
        VE_09: -9998.0,
        QA_10: 1404.311,
        VA_10: -9998.0,
        QC_10: 1404.311,
        VC_10: -9998.0,
        QE_10: 4726.279,
        VE_10: -9998.0,
        QA_11: 3340.899,
        VA_11: -9998.0,
        QC_11: 3340.899,
        VC_11: -9998.0,
        QE_11: 4788.223,
        VE_11: -9998.0,
        QA_12: 7068.411,
        VA_12: -9998.0,
        QC_12: 7068.411,
        VC_12: -9998.0,
        QE_12: 6776.4,
        VE_12: -9998.0,
        LakeFract: 0.08754140548,
        SurfArea: 3969863.8451899998,
        RAreaHLoad: 0.25110406308,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.783415585047919, 34.399525413271704, 0.0],
          [-80.784596185046155, 34.389353079954162, 0.0],
          [-80.777975718389655, 34.380734479967543, 0.0],
          [-80.77437431839536, 34.379580213302745, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291183.0,
        COMID: 9718411,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1222164",
        GNIS_NAME: "Dutchmans Creek",
        LENGTHKM: 0.305,
        REACHCODE: "03050104002476",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0028746909336179998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250058233.0,
        ToNode: 250058231.0,
        Hydroseq: 250019523.0,
        LevelPathI: 250018611.0,
        Pathlength: 356.945,
        TerminalPa: 250002604.0,
        ArbolateSu: 119.067,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250019868.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019198.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0792,
        TotDASqKM: 97.2954,
        DivDASqKM: 97.2954,
        Tidal: 0,
        TOTMA: 0.15400847994,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6788.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30500000715255698,
        QA_MA: 35.427,
        VA_MA: -9998.0,
        QC_MA: 37.358,
        VC_MA: -9998.0,
        QE_MA: 37.358,
        VE_MA: -9998.0,
        QA_01: 78.814,
        VA_01: -9998.0,
        QC_01: 63.744,
        VC_01: -9998.0,
        QE_01: 63.744,
        VE_01: -9998.0,
        QA_02: 84.883,
        VA_02: -9998.0,
        QC_02: 73.269,
        VC_02: -9998.0,
        QE_02: 73.269,
        VE_02: -9998.0,
        QA_03: 91.834,
        VA_03: -9998.0,
        QC_03: 80.549,
        VC_03: -9998.0,
        QE_03: 80.549,
        VE_03: -9998.0,
        QA_04: 49.99,
        VA_04: -9998.0,
        QC_04: 47.864,
        VC_04: -9998.0,
        QE_04: 47.864,
        VE_04: -9998.0,
        QA_05: 28.058,
        VA_05: -9998.0,
        QC_05: 28.127,
        VC_05: -9998.0,
        QE_05: 28.127,
        VE_05: -9998.0,
        QA_06: 18.314,
        VA_06: -9998.0,
        QC_06: 23.483,
        VC_06: -9998.0,
        QE_06: 23.483,
        VE_06: -9998.0,
        QA_07: 13.082,
        VA_07: -9998.0,
        QC_07: 20.519,
        VC_07: -9998.0,
        QE_07: 20.519,
        VE_07: -9998.0,
        QA_08: 10.465,
        VA_08: -9998.0,
        QC_08: 22.754,
        VC_08: -9998.0,
        QE_08: 22.754,
        VE_08: -9998.0,
        QA_09: 7.849,
        VA_09: -9998.0,
        QC_09: 24.121,
        VC_09: -9998.0,
        QE_09: 24.121,
        VE_09: -9998.0,
        QA_10: 6.541,
        VA_10: -9998.0,
        QC_10: 24.656,
        VC_10: -9998.0,
        QE_10: 24.656,
        VE_10: -9998.0,
        QA_11: 5.233,
        VA_11: -9998.0,
        QC_11: 13.507,
        VC_11: -9998.0,
        QE_11: 13.507,
        VE_11: -9998.0,
        QA_12: 30.36,
        VA_12: -9998.0,
        QC_12: 29.502,
        VC_12: -9998.0,
        QE_12: 29.502,
        VE_12: -9998.0,
        LakeFract: 5.833093e-5,
        SurfArea: 2645.2151275599999,
        RAreaHLoad: 0.02885709812,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890944584881083, 34.406638879927414, 0.0],
          [-80.889644118216381, 34.408987679923712, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291228.0,
        COMID: 9735964,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1221439",
        GNIS_NAME: "Cedar Creek",
        LENGTHKM: 1.024,
        REACHCODE: "03050104006911",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010888256654183999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059801.0,
        ToNode: 250060071.0,
        Hydroseq: 250028917.0,
        LevelPathI: 250027953.0,
        Pathlength: 367.071,
        TerminalPa: 250002604.0,
        ArbolateSu: 87.35,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250027953.0,
        UpHydroseq: 250029965.0,
        DnLevelPat: 250027953.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027953.0,
        FromMeas: 14.55814,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1592,
        TotDASqKM: 84.2931,
        DivDASqKM: 84.2931,
        Tidal: 0,
        TOTMA: 0.038233676636899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6807.0,
        MAXELEVSMO: 7029.0,
        MINELEVSMO: 6880.0,
        SLOPE: 0.00145507,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0240000486373899,
        QA_MA: 29.479,
        VA_MA: 1.01357,
        QC_MA: 31.308,
        VC_MA: 1.01701,
        QE_MA: 31.308,
        VE_MA: 1.01701,
        QA_01: 64.576,
        VA_01: 1.37777,
        QC_01: 52.502,
        VC_01: 1.24086,
        QE_01: 52.502,
        VE_01: 1.24086,
        QA_02: 69.777,
        VA_02: 1.42268,
        QC_02: 60.553,
        VC_02: 1.31424,
        QE_02: 60.553,
        VE_02: 1.31424,
        QA_03: 76.114,
        VA_03: 1.47531,
        QC_03: 67.176,
        VC_03: 1.37124,
        QE_03: 67.176,
        VE_03: 1.37124,
        QA_04: 41.361,
        VA_04: 1.15249,
        QC_04: 39.773,
        VC_04: 1.11303,
        QE_04: 39.773,
        VE_04: 1.11303,
        QA_05: 23.174,
        VA_05: 0.92897,
        QC_05: 23.397,
        VC_05: 0.91547,
        QE_05: 23.397,
        VE_05: 0.91547,
        QA_06: 15.226,
        VA_06: 0.80491,
        QC_06: 19.573,
        VC_06: 0.86058,
        QE_06: 19.573,
        VE_06: 0.86058,
        QA_07: 11.563,
        VA_07: 0.73741,
        QC_07: 18.191,
        VC_07: 0.83952,
        QE_07: 18.191,
        VE_07: 0.83952,
        QA_08: 9.067,
        VA_08: 0.68544,
        QC_08: 19.657,
        VC_08: 0.86184,
        QE_08: 19.657,
        VE_08: 0.86184,
        QA_09: 6.8,
        VA_09: 0.63202,
        QC_09: 21.251,
        VC_09: 0.88523,
        QE_09: 21.251,
        VE_09: 0.88523,
        QA_10: 5.667,
        VA_10: 0.60216,
        QC_10: 21.987,
        VC_10: 0.89576,
        QE_10: 21.987,
        VE_10: 0.89576,
        QA_11: 4.534,
        VA_11: 0.56935,
        QC_11: 12.107,
        VC_11: 0.73626,
        QE_11: 12.107,
        VE_11: 0.73626,
        QA_12: 26.153,
        VA_12: 0.97013,
        QC_12: 25.758,
        VC_12: 0.94726,
        QE_12: 25.758,
        VE_12: 0.94726,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.863479718257054, 34.541235013051846, 0.0],
          [-80.873583718241321, 34.538280413056441, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291229.0,
        COMID: 9736644,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1221439",
        GNIS_NAME: "Cedar Creek",
        LENGTHKM: 0.175,
        REACHCODE: "03050104006911",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757070,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0018290880719710001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060071.0,
        ToNode: 250057093.0,
        Hydroseq: 250027953.0,
        LevelPathI: 250027953.0,
        Pathlength: 366.896,
        TerminalPa: 250002604.0,
        ArbolateSu: 87.525,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250027953.0,
        UpHydroseq: 250028917.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004236.0,
        FromMeas: 0.0,
        ToMeas: 14.55814,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.036,
        TotDASqKM: 84.3291,
        DivDASqKM: 84.3291,
        Tidal: 0,
        TOTMA: 0.010557519969199999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6809.0,
        MAXELEVSMO: 6880.0,
        MINELEVSMO: 6880.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.17499999701976801,
        QA_MA: 29.491,
        VA_MA: 0.62787,
        QC_MA: 31.32,
        VC_MA: 0.62943,
        QE_MA: 31.32,
        VE_MA: 0.62943,
        QA_01: 64.602,
        VA_01: 0.79287,
        QC_01: 52.522,
        VC_01: 0.73084,
        QE_01: 52.522,
        VE_01: 0.73084,
        QA_02: 69.805,
        VA_02: 0.81321,
        QC_02: 60.577,
        VC_02: 0.76409,
        QE_02: 60.577,
        VE_02: 0.76409,
        QA_03: 76.145,
        VA_03: 0.83706,
        QC_03: 67.203,
        VC_03: 0.78991,
        QE_03: 67.203,
        VE_03: 0.78991,
        QA_04: 41.378,
        VA_04: 0.69081,
        QC_04: 39.788,
        VC_04: 0.67293,
        QE_04: 39.788,
        VE_04: 0.67293,
        QA_05: 23.183,
        VA_05: 0.58954,
        QC_05: 23.406,
        VC_05: 0.58342,
        QE_05: 23.406,
        VE_05: 0.58342,
        QA_06: 15.233,
        VA_06: 0.53334,
        QC_06: 19.581,
        VC_06: 0.55856,
        QE_06: 19.581,
        VE_06: 0.55856,
        QA_07: 11.568,
        VA_07: 0.50276,
        QC_07: 18.198,
        VC_07: 0.54902,
        QE_07: 18.198,
        VE_07: 0.54902,
        QA_08: 9.071,
        VA_08: 0.47921,
        QC_08: 19.665,
        VC_08: 0.55913,
        QE_08: 19.665,
        VE_08: 0.55913,
        QA_09: 6.803,
        VA_09: 0.45501,
        QC_09: 21.259,
        VC_09: 0.56973,
        QE_09: 21.259,
        VE_09: 0.56973,
        QA_10: 5.669,
        VA_10: 0.44148,
        QC_10: 21.994,
        VC_10: 0.57449,
        QE_10: 21.994,
        VE_10: 0.57449,
        QA_11: 4.536,
        VA_11: 0.42662,
        QC_11: 12.111,
        VC_11: 0.50223,
        QE_11: 12.111,
        VE_11: 0.50223,
        QA_12: 26.161,
        VA_12: 0.60817,
        QC_12: 25.765,
        VC_12: 0.59781,
        QE_12: 25.765,
        VE_12: 0.59781,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.873583718241321, 34.538280413056441, 0.0],
          [-80.8752163849054, 34.53756581305754, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285033.0,
        COMID: 9754850,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.833,
        REACHCODE: "03050101001347",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0083873417013419996,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250061731.0,
        ToNode: 250061727.0,
        Hydroseq: 250025523.0,
        LevelPathI: 250025523.0,
        Pathlength: 696.424,
        TerminalPa: 250002604.0,
        ArbolateSu: 248.361,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250026265.0,
        DnLevelPat: 250023645.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024846.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0233,
        TotDASqKM: 271.0971,
        DivDASqKM: 1.0233,
        Tidal: 0,
        TOTMA: 0.073754860265900002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 38979.0,
        MAXELEVSMO: 38973.0,
        MINELEVSMO: 39064.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.68400001525878895,
        QA_MA: 0.575,
        VA_MA: 0.42887,
        QC_MA: 0.575,
        VC_MA: 0.42887,
        QE_MA: 0.575,
        VE_MA: 0.42887,
        QA_01: 1.003,
        VA_01: 0.47028,
        QC_01: 1.003,
        VC_01: 0.47028,
        QE_01: 1.003,
        VE_01: 0.47028,
        QA_02: 1.058,
        VA_02: 0.47494,
        QC_02: 1.058,
        VC_02: 0.47494,
        QE_02: 1.058,
        VE_02: 0.47494,
        QA_03: 1.184,
        VA_03: 0.48519,
        QC_03: 1.184,
        VC_03: 0.48519,
        QE_03: 1.184,
        VE_03: 0.48519,
        QA_04: 0.865,
        VA_04: 0.45805,
        QC_04: 0.865,
        VC_04: 0.45805,
        QE_04: 0.865,
        VE_04: 0.45805,
        QA_05: 0.645,
        VA_05: 0.43645,
        QC_05: 0.645,
        VC_05: 0.43645,
        QE_05: 0.645,
        VE_05: 0.43645,
        QA_06: 0.357,
        VA_06: 0.40193,
        QC_06: 0.357,
        VC_06: 0.40193,
        QE_06: 0.357,
        VE_06: 0.40193,
        QA_07: 0.21,
        VA_07: 0.37896,
        QC_07: 0.21,
        VC_07: 0.37896,
        QE_07: 0.21,
        VE_07: 0.37896,
        QA_08: 0.14,
        VA_08: 0.36529,
        QC_08: 0.14,
        VC_08: 0.36529,
        QE_08: 0.14,
        VE_08: 0.36529,
        QA_09: 0.11,
        VA_09: 0.35845,
        QC_09: 0.11,
        VC_09: 0.35845,
        QE_09: 0.11,
        VE_09: 0.35845,
        QA_10: 0.082,
        VA_10: 0.35122,
        QC_10: 0.082,
        VC_10: 0.35122,
        QE_10: 0.082,
        VE_10: 0.35122,
        QA_11: 0.509,
        VA_11: 0.42132,
        QC_11: 0.509,
        VC_11: 0.42132,
        QE_11: 0.509,
        VE_11: 0.42132,
        QA_12: 0.759,
        VA_12: 0.44801,
        QC_12: 0.759,
        VC_12: 0.44801,
        QE_12: 0.759,
        VE_12: 0.44801,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.105558382995753, 35.662146877978614, 0.0],
          [-82.108813182990616, 35.664368411308487, 0.0],
          [-82.105922382995075, 35.667276411303988, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286059.0,
        COMID: 9754058,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.888,
        REACHCODE: "03050101002734",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0087857238256650004,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061503.0,
        ToNode: 250061506.0,
        Hydroseq: 250009360.0,
        LevelPathI: 250003258.0,
        Pathlength: 559.56,
        TerminalPa: 250002604.0,
        ArbolateSu: 3356.302,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009405.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009317.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3662,
        TotDASqKM: 3764.9403,
        DivDASqKM: 3764.9403,
        Tidal: 0,
        TOTMA: 1.1074366252000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23099.0,
        MAXELEVSMO: 23157.0,
        MINELEVSMO: 23157.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.88800001144409202,
        QA_MA: 2247.569,
        VA_MA: -9998.0,
        QC_MA: 2247.569,
        VC_MA: -9998.0,
        QE_MA: 2439.624,
        VE_MA: -9998.0,
        QA_01: 3645.846,
        VA_01: -9998.0,
        QC_01: 3645.846,
        VC_01: -9998.0,
        QE_01: 3843.986,
        VE_01: -9998.0,
        QA_02: 3852.719,
        VA_02: -9998.0,
        QC_02: 3852.719,
        VC_02: -9998.0,
        QE_02: 3916.674,
        VE_02: -9998.0,
        QA_03: 4353.729,
        VA_03: -9998.0,
        QC_03: 4353.729,
        VC_03: -9998.0,
        QE_03: 4612.267,
        VE_03: -9998.0,
        QA_04: 3346.886,
        VA_04: -9998.0,
        QC_04: 3346.886,
        VC_04: -9998.0,
        QE_04: 3535.998,
        VE_04: -9998.0,
        QA_05: 2539.674,
        VA_05: -9998.0,
        QC_05: 2539.674,
        VC_05: -9998.0,
        QE_05: 2760.882,
        VE_05: -9998.0,
        QA_06: 1480.946,
        VA_06: -9998.0,
        QC_06: 1480.946,
        VC_06: -9998.0,
        QE_06: 1742.73,
        VE_06: -9998.0,
        QA_07: 875.531,
        VA_07: -9998.0,
        QC_07: 875.531,
        VC_07: -9998.0,
        QE_07: 1025.873,
        VE_07: -9998.0,
        QA_08: 614.223,
        VA_08: -9998.0,
        QC_08: 614.223,
        VC_08: -9998.0,
        QE_08: 915.678,
        VE_08: -9998.0,
        QA_09: 594.548,
        VA_09: -9998.0,
        QC_09: 1100.726,
        VC_09: -9998.0,
        QE_09: 1137.431,
        VE_09: -9998.0,
        QA_10: 755.85,
        VA_10: -9998.0,
        QC_10: 755.85,
        VC_10: -9998.0,
        QE_10: 696.28,
        VE_10: -9998.0,
        QA_11: 2060.006,
        VA_11: -9998.0,
        QC_11: 2060.006,
        VC_11: -9998.0,
        QE_11: 2004.225,
        VE_11: -9998.0,
        QA_12: 2865.423,
        VA_12: -9998.0,
        QC_12: 2865.423,
        VC_12: -9998.0,
        QE_12: 2874.79,
        VE_12: -9998.0,
        LakeFract: 0.01373435962,
        SurfArea: 1796663.9090199999,
        RAreaHLoad: 0.30042829501000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.081448317918671, 35.750000411175506, 0.0],
          [-81.075196384595074, 35.743917877851743, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286062.0,
        COMID: 9754064,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.94,
        REACHCODE: "03050101002737",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0095641777979400006,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061506.0,
        ToNode: 250061516.0,
        Hydroseq: 250009317.0,
        LevelPathI: 250003258.0,
        Pathlength: 558.62,
        TerminalPa: 250002604.0,
        ArbolateSu: 3358.945,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009360.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009282.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4257,
        TotDASqKM: 3767.1147,
        DivDASqKM: 3767.1147,
        Tidal: 0,
        TOTMA: 1.1722865175599999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23092.0,
        MAXELEVSMO: 23157.0,
        MINELEVSMO: 23157.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.93999999761581399,
        QA_MA: 2248.526,
        VA_MA: -9998.0,
        QC_MA: 2248.526,
        VC_MA: -9998.0,
        QE_MA: 2440.582,
        VE_MA: -9998.0,
        QA_01: 3647.741,
        VA_01: -9998.0,
        QC_01: 3647.741,
        VC_01: -9998.0,
        QE_01: 3845.881,
        VE_01: -9998.0,
        QA_02: 3854.695,
        VA_02: -9998.0,
        QC_02: 3854.695,
        VC_02: -9998.0,
        QE_02: 3918.65,
        VE_02: -9998.0,
        QA_03: 4355.825,
        VA_03: -9998.0,
        QC_03: 4355.825,
        VC_03: -9998.0,
        QE_03: 4614.363,
        VE_03: -9998.0,
        QA_04: 3348.315,
        VA_04: -9998.0,
        QC_04: 3348.315,
        VC_04: -9998.0,
        QE_04: 3537.427,
        VE_04: -9998.0,
        QA_05: 2540.604,
        VA_05: -9998.0,
        QC_05: 2540.604,
        VC_05: -9998.0,
        QE_05: 2761.812,
        VE_05: -9998.0,
        QA_06: 1481.497,
        VA_06: -9998.0,
        QC_06: 1481.497,
        VC_06: -9998.0,
        QE_06: 1743.281,
        VE_06: -9998.0,
        QA_07: 875.877,
        VA_07: -9998.0,
        QC_07: 875.877,
        VC_07: -9998.0,
        QE_07: 1026.219,
        VE_07: -9998.0,
        QA_08: 614.457,
        VA_08: -9998.0,
        QC_08: 614.457,
        VC_08: -9998.0,
        QE_08: 915.912,
        VE_08: -9998.0,
        QA_09: 594.753,
        VA_09: -9998.0,
        QC_09: 1101.061,
        VC_09: -9998.0,
        QE_09: 1137.765,
        VE_09: -9998.0,
        QA_10: 755.996,
        VA_10: -9998.0,
        QC_10: 755.996,
        VC_10: -9998.0,
        QE_10: 696.426,
        VE_10: -9998.0,
        QA_11: 2060.473,
        VA_11: -9998.0,
        QC_11: 2060.473,
        VC_11: -9998.0,
        QE_11: 2004.692,
        VE_11: -9998.0,
        QA_12: 2866.658,
        VA_12: -9998.0,
        QC_12: 2866.658,
        VC_12: -9998.0,
        QE_12: 2876.025,
        VE_12: -9998.0,
        LakeFract: 0.01454431597,
        SurfArea: 1902618.5645099999,
        RAreaHLoad: 0.31802094289,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.075196384595074, 35.743917877851743, 0.0],
          [-81.067631584606772, 35.738482211193457, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286056.0,
        COMID: 9754048,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.07,
        REACHCODE: "03050101002731",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010842746361962001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061498.0,
        ToNode: 250061499.0,
        Hydroseq: 250009454.0,
        LevelPathI: 250003258.0,
        Pathlength: 560.554,
        TerminalPa: 250002604.0,
        ArbolateSu: 3349.937,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009499.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009405.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4824,
        TotDASqKM: 3756.4983,
        DivDASqKM: 3756.4983,
        Tidal: 0,
        TOTMA: 1.3344112486599999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23115.0,
        MAXELEVSMO: 23303.0,
        MINELEVSMO: 23157.0,
        SLOPE: 0.00136448,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.070000052452087,
        QA_MA: 2243.836,
        VA_MA: -9998.0,
        QC_MA: 2243.836,
        VC_MA: -9998.0,
        QE_MA: 2435.891,
        VE_MA: -9998.0,
        QA_01: 3638.478,
        VA_01: -9998.0,
        QC_01: 3638.478,
        VC_01: -9998.0,
        QE_01: 3836.618,
        VE_01: -9998.0,
        QA_02: 3845.033,
        VA_02: -9998.0,
        QC_02: 3845.033,
        VC_02: -9998.0,
        QE_02: 3908.988,
        VE_02: -9998.0,
        QA_03: 4345.58,
        VA_03: -9998.0,
        QC_03: 4345.58,
        VC_03: -9998.0,
        QE_03: 4604.118,
        VE_03: -9998.0,
        QA_04: 3341.312,
        VA_04: -9998.0,
        QC_04: 3341.312,
        VC_04: -9998.0,
        QE_04: 3530.424,
        VE_04: -9998.0,
        QA_05: 2536.065,
        VA_05: -9998.0,
        QC_05: 2536.065,
        VC_05: -9998.0,
        QE_05: 2757.273,
        VE_05: -9998.0,
        QA_06: 1478.802,
        VA_06: -9998.0,
        QC_06: 1478.802,
        VC_06: -9998.0,
        QE_06: 1740.586,
        VE_06: -9998.0,
        QA_07: 874.182,
        VA_07: -9998.0,
        QC_07: 874.182,
        VC_07: -9998.0,
        QE_07: 1024.524,
        VE_07: -9998.0,
        QA_08: 613.315,
        VA_08: -9998.0,
        QC_08: 613.315,
        VC_08: -9998.0,
        QE_08: 914.77,
        VE_08: -9998.0,
        QA_09: 593.754,
        VA_09: -9998.0,
        QC_09: 1099.428,
        VC_09: -9998.0,
        QE_09: 1136.132,
        VE_09: -9998.0,
        QA_10: 755.283,
        VA_10: -9998.0,
        QC_10: 755.283,
        VC_10: -9998.0,
        QE_10: 695.712,
        VE_10: -9998.0,
        QA_11: 2058.136,
        VA_11: -9998.0,
        QC_11: 2058.136,
        VC_11: -9998.0,
        QE_11: 2002.355,
        VE_11: -9998.0,
        QA_12: 2860.591,
        VA_12: -9998.0,
        QC_12: 2860.591,
        VC_12: -9998.0,
        QE_12: 2869.958,
        VE_12: -9998.0,
        LakeFract: 0.0165240041,
        SurfArea: 2161591.9941199999,
        RAreaHLoad: 0.36200256264000003,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.090549384571148, 35.757196411164443, 0.0],
          [-81.082143984584263, 35.750766811174458, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286057.0,
        COMID: 9754050,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.106,
        REACHCODE: "03050101002732",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001035046408626,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061499.0,
        ToNode: 250061503.0,
        Hydroseq: 250009405.0,
        LevelPathI: 250003258.0,
        Pathlength: 560.448,
        TerminalPa: 250002604.0,
        ArbolateSu: 3352.82,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009454.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009360.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0414,
        TotDASqKM: 3758.9481,
        DivDASqKM: 3758.9481,
        Tidal: 0,
        TOTMA: 0.13219401195,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23106.0,
        MAXELEVSMO: 23157.0,
        MINELEVSMO: 23157.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.105999998748302,
        QA_MA: 2244.92,
        VA_MA: -9998.0,
        QC_MA: 2244.92,
        VC_MA: -9998.0,
        QE_MA: 2436.975,
        VE_MA: -9998.0,
        QA_01: 3640.615,
        VA_01: -9998.0,
        QC_01: 3640.615,
        VC_01: -9998.0,
        QE_01: 3838.755,
        VE_01: -9998.0,
        QA_02: 3847.264,
        VA_02: -9998.0,
        QC_02: 3847.264,
        VC_02: -9998.0,
        QE_02: 3911.218,
        VE_02: -9998.0,
        QA_03: 4347.943,
        VA_03: -9998.0,
        QC_03: 4347.943,
        VC_03: -9998.0,
        QE_03: 4606.48,
        VE_03: -9998.0,
        QA_04: 3342.931,
        VA_04: -9998.0,
        QC_04: 3342.931,
        VC_04: -9998.0,
        QE_04: 3532.043,
        VE_04: -9998.0,
        QA_05: 2537.111,
        VA_05: -9998.0,
        QC_05: 2537.111,
        VC_05: -9998.0,
        QE_05: 2758.318,
        VE_05: -9998.0,
        QA_06: 1479.421,
        VA_06: -9998.0,
        QC_06: 1479.421,
        VC_06: -9998.0,
        QE_06: 1741.205,
        VE_06: -9998.0,
        QA_07: 874.57,
        VA_07: -9998.0,
        QC_07: 874.57,
        VC_07: -9998.0,
        QE_07: 1024.912,
        VE_07: -9998.0,
        QA_08: 613.579,
        VA_08: -9998.0,
        QC_08: 613.579,
        VC_08: -9998.0,
        QE_08: 915.033,
        VE_08: -9998.0,
        QA_09: 593.984,
        VA_09: -9998.0,
        QC_09: 1099.805,
        VC_09: -9998.0,
        QE_09: 1136.509,
        VE_09: -9998.0,
        QA_10: 755.447,
        VA_10: -9998.0,
        QC_10: 755.447,
        VC_10: -9998.0,
        QE_10: 695.877,
        VE_10: -9998.0,
        QA_11: 2058.692,
        VA_11: -9998.0,
        QC_11: 2058.692,
        VC_11: -9998.0,
        QE_11: 2002.911,
        VE_11: -9998.0,
        QA_12: 2862.001,
        VA_12: -9998.0,
        QC_12: 2862.001,
        VC_12: -9998.0,
        QE_12: 2871.367,
        VE_12: -9998.0,
        LakeFract: 0.00163768447,
        SurfArea: 214234.129805,
        RAreaHLoad: 0.03586193622,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.082143984584263, 35.750766811174458, 0.0],
          [-81.081448317918671, 35.750000411175506, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287084.0,
        COMID: 9736782,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.679,
        REACHCODE: "03050103000352",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0066737267159090001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060076.0,
        ToNode: 250060085.0,
        Hydroseq: 250020924.0,
        LevelPathI: 250019187.0,
        Pathlength: 372.035,
        TerminalPa: 250002604.0,
        ArbolateSu: 257.762,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250021708.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020555.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5868,
        TotDASqKM: 515.0007,
        DivDASqKM: 515.0007,
        Tidal: 0,
        TOTMA: 0.023958087327699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8651.0,
        MAXELEVSMO: 8715.0,
        MINELEVSMO: 8703.0,
        SLOPE: 0.00017673,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.67900002002716098,
        QA_MA: 198.066,
        VA_MA: 1.07868,
        QC_MA: 195.365,
        VC_MA: 1.07782,
        QE_MA: 190.321,
        VE_MA: 1.07619,
        QA_01: 433.577,
        VA_01: 1.47608,
        QC_01: 335.35,
        VC_01: 1.33349,
        QE_01: 382.029,
        VE_01: 1.41995,
        QA_02: 462.848,
        VA_02: 1.5173,
        QC_02: 381.472,
        VC_02: 1.40609,
        QE_02: 356.642,
        VE_02: 1.3801,
        QA_03: 497.436,
        VA_03: 1.56446,
        QC_03: 412.643,
        VC_03: 1.45284,
        QE_03: 405.129,
        VE_03: 1.45515,
        QA_04: 284.452,
        VA_04: 1.24191,
        QC_04: 261.798,
        VC_04: 1.2072,
        QE_04: 233.321,
        VE_04: 1.1639,
        QA_05: 157.584,
        VA_05: 0.99061,
        QC_05: 148.095,
        VC_05: 0.97257,
        QE_05: 117.746,
        VE_05: 0.90338,
        QA_06: 101.711,
        VA_06: 0.84909,
        QC_06: 127.382,
        VC_06: 0.9215,
        QE_06: 108.47,
        VE_06: 0.87801,
        QA_07: 69.243,
        VA_07: 0.74923,
        QC_07: 104.368,
        VC_07: 0.85994,
        QE_07: 85.232,
        VE_07: 0.80956,
        QA_08: 55.394,
        VA_08: 0.69997,
        QC_08: 124.528,
        VC_08: 0.91417,
        QE_08: 77.099,
        VE_08: 0.78357,
        QA_09: 42.669,
        VA_09: 0.64929,
        QC_09: 107.543,
        VC_09: 0.86879,
        QE_09: 96.185,
        VE_09: 0.84279,
        QA_10: 34.621,
        VA_10: 0.61348,
        QC_10: 93.336,
        VC_10: 0.82817,
        QE_10: 163.35,
        VE_10: 1.01635,
        QA_11: 31.971,
        VA_11: 0.60085,
        QC_11: 53.703,
        VC_11: 0.69597,
        QE_11: 120.043,
        VE_11: 0.90952,
        QA_12: 206.782,
        VA_12: 1.09649,
        QC_12: 169.016,
        VC_12: 1.02085,
        QE_12: 170.766,
        VE_12: 1.03324,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.914645184844304, 34.561798079686582, 0.0],
          [-80.910047118184764, 34.557287013026951, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286067.0,
        COMID: 9754158,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "989140",
        GNIS_NAME: "Lyle Creek",
        LENGTHKM: 1.821,
        REACHCODE: "03050101002743",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019237669695236,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061551.0,
        ToNode: 250061555.0,
        Hydroseq: 250024849.0,
        LevelPathI: 250024849.0,
        Pathlength: 555.738,
        TerminalPa: 250002604.0,
        ArbolateSu: 154.909,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250024849.0,
        UpHydroseq: 250025529.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009201.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.1465,
        TotDASqKM: 191.5452,
        DivDASqKM: 191.5452,
        Tidal: 0,
        TOTMA: 2.27099335078,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23083.0,
        MAXELEVSMO: 23116.0,
        MINELEVSMO: 23095.0,
        SLOPE: 0.00011532,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8209999799728389,
        QA_MA: 87.776,
        VA_MA: -9998.0,
        QC_MA: 89.357,
        VC_MA: -9998.0,
        QE_MA: 89.357,
        VE_MA: -9998.0,
        QA_01: 168.117,
        VA_01: -9998.0,
        QC_01: 133.299,
        VC_01: -9998.0,
        QE_01: 133.299,
        VE_01: -9998.0,
        QA_02: 177.412,
        VA_02: -9998.0,
        QC_02: 150.093,
        VC_02: -9998.0,
        QE_02: 150.093,
        VE_02: -9998.0,
        QA_03: 190.865,
        VA_03: -9998.0,
        QC_03: 163.417,
        VC_03: -9998.0,
        QE_03: 163.417,
        VE_03: -9998.0,
        QA_04: 133.241,
        VA_04: -9998.0,
        QC_04: 124.762,
        VC_04: -9998.0,
        QE_04: 124.762,
        VE_04: -9998.0,
        QA_05: 87.151,
        VA_05: -9998.0,
        QC_05: 83.738,
        VC_05: -9998.0,
        QE_05: 83.738,
        VE_05: -9998.0,
        QA_06: 51.234,
        VA_06: -9998.0,
        QC_06: 64.771,
        VC_06: -9998.0,
        QE_06: 64.771,
        VE_06: -9998.0,
        QA_07: 31.663,
        VA_07: -9998.0,
        QC_07: 48.626,
        VC_07: -9998.0,
        QE_07: 48.626,
        VE_07: -9998.0,
        QA_08: 21.297,
        VA_08: -9998.0,
        QC_08: 46.969,
        VC_08: -9998.0,
        QE_08: 46.969,
        VE_08: -9998.0,
        QA_09: 18.055,
        VA_09: -9998.0,
        QC_09: 50.329,
        VC_09: -9998.0,
        QE_09: 50.329,
        VE_09: -9998.0,
        QA_10: 13.082,
        VA_10: -9998.0,
        QC_10: 42.896,
        VC_10: -9998.0,
        QE_10: 42.896,
        VE_10: -9998.0,
        QA_11: 47.811,
        VA_11: -9998.0,
        QC_11: 72.995,
        VC_11: -9998.0,
        QE_11: 72.995,
        VE_11: -9998.0,
        QA_12: 113.85,
        VA_12: -9998.0,
        QC_12: 98.201,
        VC_12: -9998.0,
        QE_12: 98.201,
        VE_12: -9998.0,
        LakeFract: 0.00102830678,
        SurfArea: 134518.22510400001,
        RAreaHLoad: 0.61608099716999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.080687517919841, 35.713434411232356, 0.0],
          [-81.074638117929283, 35.715904011228531, 0.0],
          [-81.069874717936614, 35.712586677900276, 0.0],
          [-81.06369991794611, 35.713837811231656, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287085.0,
        COMID: 9735882,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.159,
        REACHCODE: "03050103000353",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0016967779245019999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059772.0,
        ToNode: 250060076.0,
        Hydroseq: 250021708.0,
        LevelPathI: 250019187.0,
        Pathlength: 372.714,
        TerminalPa: 250002604.0,
        ArbolateSu: 250.606,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250022147.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020924.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1368,
        TotDASqKM: 510.1965,
        DivDASqKM: 510.1965,
        Tidal: 0,
        TOTMA: 0.0039350444374400004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8713.0,
        MAXELEVSMO: 8769.0,
        MINELEVSMO: 8715.0,
        SLOPE: 0.00339622,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158999994397163,
        QA_MA: 196.479,
        VA_MA: 1.53829,
        QC_MA: 193.86,
        VC_MA: 1.53695,
        QE_MA: 188.816,
        VE_MA: 1.53433,
        QA_01: 430.136,
        VA_01: 2.17289,
        QC_01: 332.758,
        VC_01: 1.94517,
        QE_01: 379.437,
        VE_01: 2.08428,
        QA_02: 459.018,
        VA_02: 2.23835,
        QC_02: 378.401,
        VC_02: 2.06079,
        QE_02: 353.571,
        VE_02: 2.01893,
        QA_03: 493.209,
        VA_03: 2.3134,
        QC_03: 409.253,
        VC_03: 2.13526,
        QE_03: 401.738,
        VE_03: 2.13896,
        QA_04: 282.254,
        VA_04: 1.79915,
        QC_04: 259.821,
        VC_04: 1.74365,
        QE_04: 231.344,
        VE_04: 1.67396,
        QA_05: 156.357,
        VA_05: 1.39781,
        QC_05: 146.985,
        VC_05: 1.36902,
        QE_05: 116.635,
        VE_05: 1.25764,
        QA_06: 100.872,
        VA_06: 1.17161,
        QC_06: 126.344,
        VC_06: 1.28713,
        QE_06: 107.433,
        VE_06: 1.2171,
        QA_07: 68.597,
        VA_07: 1.01178,
        QC_07: 103.417,
        VC_07: 1.1884,
        QE_07: 84.281,
        VE_07: 1.10722,
        QA_08: 54.878,
        VA_08: 0.93318,
        QC_08: 123.343,
        VC_08: 1.27471,
        QE_08: 75.915,
        VE_08: 1.06409,
        QA_09: 42.281,
        VA_09: 0.85239,
        QC_09: 106.68,
        VC_09: 1.20304,
        QE_09: 95.322,
        VE_09: 1.16119,
        QA_10: 34.298,
        VA_10: 0.79518,
        QC_10: 92.639,
        VC_10: 1.13845,
        QE_10: 162.654,
        VE_10: 1.44098,
        QA_11: 31.713,
        VA_11: 0.77534,
        QC_11: 53.372,
        VC_11: 0.92775,
        QE_11: 119.711,
        VE_11: 1.27085,
        QA_12: 205.612,
        VA_12: 1.56832,
        QC_12: 168.146,
        VC_12: 1.44754,
        QE_12: 169.896,
        VE_12: 1.46749,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.916195918175163, 34.562095213019404, 0.0],
          [-80.914645184844304, 34.561798079686582, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284067.0,
        COMID: 9753914,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 1.231,
        REACHCODE: "03050101000481",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012329420647804,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061438.0,
        ToNode: 250061228.0,
        Hydroseq: 250033873.0,
        LevelPathI: 250028899.0,
        Pathlength: 641.507,
        TerminalPa: 250002604.0,
        ArbolateSu: 90.842,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250035536.0,
        DnLevelPat: 250028899.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032406.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7803,
        TotDASqKM: 112.7016,
        DivDASqKM: 112.7016,
        Tidal: 0,
        TOTMA: 0.041708862469100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31117.0,
        MAXELEVSMO: 31491.0,
        MINELEVSMO: 31278.0,
        SLOPE: 0.0017303,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2309999465942381,
        QA_MA: 68.676,
        VA_MA: 1.11893,
        QC_MA: 70.582,
        VC_MA: 1.12073,
        QE_MA: 70.582,
        VE_MA: 1.12073,
        QA_01: 118.287,
        VA_01: 1.39022,
        QC_01: 94.657,
        VC_01: 1.25772,
        QE_01: 94.657,
        VE_01: 1.25772,
        QA_02: 124.493,
        VA_02: 1.42,
        QC_02: 106.344,
        VC_02: 1.31826,
        QE_02: 106.344,
        VE_02: 1.31826,
        QA_03: 132.478,
        VA_03: 1.4573,
        QC_03: 114.802,
        VC_03: 1.36014,
        QE_03: 114.802,
        VE_03: 1.36014,
        QA_04: 99.006,
        VA_04: 1.29269,
        QC_04: 93.333,
        VC_04: 1.25065,
        QE_04: 93.333,
        VE_04: 1.25065,
        QA_05: 74.345,
        VA_05: 1.15379,
        QC_05: 71.86,
        VC_05: 1.12851,
        QE_05: 71.86,
        VE_05: 1.12851,
        QA_06: 42.505,
        VA_06: 0.93664,
        QC_06: 53.874,
        VC_06: 1.01218,
        QE_06: 53.874,
        VE_06: 1.01218,
        QA_07: 24.62,
        VA_07: 0.7785,
        QC_07: 38.039,
        VC_07: 0.89342,
        QE_07: 38.039,
        VE_07: 0.89342,
        QA_08: 16.912,
        VA_08: 0.69351,
        QC_08: 37.127,
        VC_08: 0.88593,
        QE_08: 37.127,
        VE_08: 0.88593,
        QA_09: 14.631,
        VA_09: 0.665,
        QC_09: 41.799,
        VC_09: 0.92345,
        QE_09: 41.799,
        VE_09: 0.92345,
        QA_10: 22.55,
        VA_10: 0.75708,
        QC_10: 66.269,
        VC_10: 1.09398,
        QE_10: 66.269,
        VE_10: 1.09398,
        QA_11: 64.493,
        VA_11: 1.09233,
        QC_11: 91.71,
        VC_11: 1.24191,
        QE_11: 91.71,
        VE_11: 1.24191,
        QA_12: 90.41,
        VA_12: 1.24634,
        QC_12: 79.622,
        VC_12: 1.17441,
        QE_12: 79.622,
        VE_12: 1.17441,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.753614583542003, 35.709526411238357, 0.0],
          [-81.745259716888313, 35.717286211226337, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287086.0,
        COMID: 9735880,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.489,
        REACHCODE: "03050103000354",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0048342237185760003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059771.0,
        ToNode: 250059772.0,
        Hydroseq: 250022147.0,
        LevelPathI: 250019187.0,
        Pathlength: 372.873,
        TerminalPa: 250002604.0,
        ArbolateSu: 250.447,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250022617.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 250021707.0,
        DnDrainCou: 2,
        DnHydroseq: 250021708.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0936,
        TotDASqKM: 510.0597,
        DivDASqKM: 510.0597,
        Tidal: 0,
        TOTMA: 0.010925430268000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8769.0,
        MAXELEVSMO: 9137.0,
        MINELEVSMO: 8769.0,
        SLOPE: 0.00752556,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.48899999260902399,
        QA_MA: 196.434,
        VA_MA: 1.70406,
        QC_MA: 193.818,
        VC_MA: 1.70255,
        QE_MA: 188.774,
        VE_MA: 1.69958,
        QA_01: 430.038,
        VA_01: 2.42421,
        QC_01: 332.685,
        VC_01: 2.16579,
        QE_01: 379.364,
        VE_01: 2.32368,
        QA_02: 458.909,
        VA_02: 2.49848,
        QC_02: 378.314,
        VC_02: 2.29698,
        QE_02: 353.484,
        VE_02: 2.24947,
        QA_03: 493.09,
        VA_03: 2.58364,
        QC_03: 409.157,
        VC_03: 2.38149,
        QE_03: 401.642,
        VE_03: 2.38568,
        QA_04: 282.192,
        VA_04: 2.0001,
        QC_04: 259.765,
        VC_04: 1.93712,
        QE_04: 231.288,
        VE_04: 1.85801,
        QA_05: 156.322,
        VA_05: 1.54465,
        QC_05: 146.953,
        VC_05: 1.51198,
        QE_05: 116.604,
        VE_05: 1.38556,
        QA_06: 100.848,
        VA_06: 1.28796,
        QC_06: 126.315,
        VC_06: 1.41905,
        QE_06: 107.403,
        VE_06: 1.33956,
        QA_07: 68.579,
        VA_07: 1.10658,
        QC_07: 103.39,
        VC_07: 1.307,
        QE_07: 84.254,
        VE_07: 1.21485,
        QA_08: 54.863,
        VA_08: 1.01739,
        QC_08: 123.31,
        VC_08: 1.40494,
        QE_08: 75.881,
        VE_08: 1.16584,
        QA_09: 42.27,
        VA_09: 0.92571,
        QC_09: 106.655,
        VC_09: 1.32362,
        QE_09: 95.298,
        VE_09: 1.27612,
        QA_10: 34.289,
        VA_10: 0.86079,
        QC_10: 92.62,
        VC_10: 1.25034,
        QE_10: 162.634,
        VE_10: 1.59372,
        QA_11: 31.705,
        VA_11: 0.83828,
        QC_11: 53.362,
        VC_11: 1.01125,
        QE_11: 119.702,
        VE_11: 1.40066,
        QA_12: 205.581,
        VA_12: 1.73821,
        QC_12: 168.123,
        VC_12: 1.60114,
        QE_12: 169.872,
        VE_12: 1.62378,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.919409384836854, 34.565163479681303, 0.0],
          [-80.916195918175163, 34.562095213019404, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286069.0,
        COMID: 9754164,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.259,
        REACHCODE: "03050101002745",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.035482112241036,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061554.0,
        ToNode: 250061550.0,
        Hydroseq: 250009158.0,
        LevelPathI: 250003258.0,
        Pathlength: 550.499,
        TerminalPa: 250002604.0,
        ArbolateSu: 3526.294,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009201.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009115.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.7176,
        TotDASqKM: 3982.4721,
        DivDASqKM: 3982.4721,
        Tidal: 0,
        TOTMA: 4.0643422983099997,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23071.0,
        MAXELEVSMO: 23095.0,
        MINELEVSMO: 23074.0,
        SLOPE: 6.443e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.2590000629425049,
        QA_MA: 2346.648,
        VA_MA: -9998.0,
        QC_MA: 2346.648,
        VC_MA: -9998.0,
        QE_MA: 2540.664,
        VE_MA: -9998.0,
        QA_01: 3836.389,
        VA_01: -9998.0,
        QC_01: 3836.389,
        VC_01: -9998.0,
        QE_01: 4021.401,
        VE_01: -9998.0,
        QA_02: 4053.537,
        VA_02: -9998.0,
        QC_02: 4053.537,
        VC_02: -9998.0,
        QE_02: 4102.076,
        VE_02: -9998.0,
        QA_03: 4569.454,
        VA_03: -9998.0,
        QC_03: 4569.454,
        VC_03: -9998.0,
        QE_03: 4806.063,
        VE_03: -9998.0,
        QA_04: 3497.094,
        VA_04: -9998.0,
        QC_04: 3497.094,
        VC_04: -9998.0,
        QE_04: 3679.772,
        VE_04: -9998.0,
        QA_05: 2637.617,
        VA_05: -9998.0,
        QC_05: 2637.617,
        VC_05: -9998.0,
        QE_05: 2860.651,
        VE_05: -9998.0,
        QA_06: 1538.601,
        VA_06: -9998.0,
        QC_06: 1538.601,
        VC_06: -9998.0,
        QE_06: 1815.735,
        VE_06: -9998.0,
        QA_07: 911.247,
        VA_07: -9998.0,
        QC_07: 911.247,
        VC_07: -9998.0,
        QE_07: 1076.492,
        VE_07: -9998.0,
        QA_08: 638.316,
        VA_08: -9998.0,
        QC_08: 638.316,
        VC_08: -9998.0,
        QE_08: 958.21,
        VE_08: -9998.0,
        QA_09: 615.049,
        VA_09: -9998.0,
        QC_09: 1134.17,
        VC_09: -9998.0,
        QE_09: 1185.321,
        VE_09: -9998.0,
        QA_10: 770.679,
        VA_10: -9998.0,
        QC_10: 770.679,
        VC_10: -9998.0,
        QE_10: 731.952,
        VE_10: -9998.0,
        QA_11: 2113.149,
        VA_11: -9998.0,
        QC_11: 2113.149,
        VC_11: -9998.0,
        QE_11: 2062.919,
        VE_11: -9998.0,
        QA_12: 2993.857,
        VA_12: -9998.0,
        QC_12: 2993.857,
        VC_12: -9998.0,
        QE_12: 2995.323,
        VE_12: -9998.0,
        LakeFract: 0.05248926046,
        SurfArea: 6866396.5768299997,
        RAreaHLoad: 1.1025853753099999,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.043678984643975, 35.70803887790737, 0.0],
          [-81.038948917984612, 35.70798007790745, 0.0],
          [-81.022848718009641, 35.71230767790064, 0.0],
          [-81.016657918019291, 35.711926477901272, 0.0],
          [-81.009922718029713, 35.709169277905687, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284068.0,
        COMID: 9753170,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 1.151,
        REACHCODE: "03050101000482",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011139130536896,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061245.0,
        ToNode: 250061438.0,
        Hydroseq: 250035536.0,
        LevelPathI: 250028899.0,
        Pathlength: 642.738,
        TerminalPa: 250002604.0,
        ArbolateSu: 87.864,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250037448.0,
        DnLevelPat: 250028899.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033873.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.548,
        TotDASqKM: 110.7171,
        DivDASqKM: 110.7171,
        Tidal: 0,
        TOTMA: 0.044124865239700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31256.0,
        MAXELEVSMO: 31559.0,
        MINELEVSMO: 31491.0,
        SLOPE: 0.00059079,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.151000022888184,
        QA_MA: 67.63,
        VA_MA: 0.98897,
        QC_MA: 69.549,
        VC_MA: 0.99052,
        QE_MA: 69.549,
        VE_MA: 0.99052,
        QA_01: 116.38,
        VA_01: 1.21634,
        QC_01: 93.171,
        VC_01: 1.1051,
        QE_01: 93.171,
        VE_01: 1.1051,
        QA_02: 122.454,
        VA_02: 1.2412,
        QC_02: 104.65,
        VC_02: 1.1558,
        QE_02: 104.65,
        VE_02: 1.1558,
        QA_03: 130.304,
        VA_03: 1.27249,
        QC_03: 112.98,
        VC_03: 1.19097,
        QE_03: 112.98,
        VE_03: 1.19097,
        QA_04: 97.431,
        VA_04: 1.13457,
        QC_04: 91.882,
        VC_04: 1.09922,
        QE_04: 91.882,
        VE_04: 1.09922,
        QA_05: 73.25,
        VA_05: 1.01844,
        QC_05: 70.84,
        VC_05: 0.99721,
        QE_05: 70.84,
        VE_05: 0.99721,
        QA_06: 41.888,
        VA_06: 0.83611,
        QC_06: 53.102,
        VC_06: 0.89946,
        QE_06: 53.102,
        VE_06: 0.89946,
        QA_07: 24.247,
        VA_07: 0.70315,
        QC_07: 37.475,
        VC_07: 0.7996,
        QE_07: 37.475,
        VE_07: 0.7996,
        QA_08: 16.647,
        VA_08: 0.63169,
        QC_08: 36.532,
        VC_08: 0.79299,
        QE_08: 36.532,
        VE_08: 0.79299,
        QA_09: 14.417,
        VA_09: 0.60792,
        QC_09: 41.26,
        VC_09: 0.82535,
        QE_09: 41.26,
        VE_09: 0.82535,
        QA_10: 22.39,
        VA_10: 0.68679,
        QC_10: 65.893,
        VC_10: 0.97123,
        QE_10: 65.893,
        VE_10: 0.97123,
        QA_11: 63.706,
        VA_11: 0.96771,
        QC_11: 90.856,
        VC_11: 1.09452,
        QE_11: 90.856,
        VE_11: 1.09452,
        QA_12: 89.037,
        VA_12: 1.09598,
        QC_12: 78.521,
        VC_12: 1.03592,
        QE_12: 78.521,
        VE_12: 1.03592,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.75907131686688, 35.702752677915498, 0.0],
          [-81.758869316867219, 35.706691477909544, 0.0],
          [-81.756058916871552, 35.708566811239962, 0.0],
          [-81.754094116874626, 35.708061211240761, 0.0],
          [-81.753614583542003, 35.709526411238357, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286070.0,
        COMID: 9754166,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.98,
        REACHCODE: "03050101002746",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.021408427765196001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061555.0,
        ToNode: 250061554.0,
        Hydroseq: 250009201.0,
        LevelPathI: 250003258.0,
        Pathlength: 553.758,
        TerminalPa: 250002604.0,
        ArbolateSu: 3522.272,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009243.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009158.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.1488,
        TotDASqKM: 3974.7834,
        DivDASqKM: 3974.7834,
        Tidal: 0,
        TOTMA: 2.4692843665700002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23076.0,
        MAXELEVSMO: 23095.0,
        MINELEVSMO: 23095.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.9800000190734861,
        QA_MA: 2343.329,
        VA_MA: -9998.0,
        QC_MA: 2343.329,
        VC_MA: -9998.0,
        QE_MA: 2537.342,
        VE_MA: -9998.0,
        QA_01: 3829.803,
        VA_01: -9998.0,
        QC_01: 3829.803,
        VC_01: -9998.0,
        QE_01: 4014.841,
        VE_01: -9998.0,
        QA_02: 4046.641,
        VA_02: -9998.0,
        QC_02: 4046.641,
        VC_02: -9998.0,
        QE_02: 4095.209,
        VE_02: -9998.0,
        QA_03: 4562.123,
        VA_03: -9998.0,
        QC_03: 4562.123,
        VC_03: -9998.0,
        QE_03: 4798.775,
        VE_03: -9998.0,
        QA_04: 3492.084,
        VA_04: -9998.0,
        QC_04: 3492.084,
        VC_04: -9998.0,
        QE_04: 3674.775,
        VE_04: -9998.0,
        QA_05: 2634.453,
        VA_05: -9998.0,
        QC_05: 2634.453,
        VC_05: -9998.0,
        QE_05: 2857.484,
        VE_05: -9998.0,
        QA_06: 1536.739,
        VA_06: -9998.0,
        QC_06: 1536.739,
        VC_06: -9998.0,
        QE_06: 1813.844,
        VE_06: -9998.0,
        QA_07: 910.074,
        VA_07: -9998.0,
        QC_07: 910.074,
        VC_07: -9998.0,
        QE_07: 1075.291,
        VE_07: -9998.0,
        QA_08: 637.489,
        VA_08: -9998.0,
        QC_08: 637.489,
        VC_08: -9998.0,
        QE_08: 957.348,
        VE_08: -9998.0,
        QA_09: 614.326,
        VA_09: -9998.0,
        QC_09: 1132.991,
        VC_09: -9998.0,
        QE_09: 1184.115,
        VE_09: -9998.0,
        QA_10: 770.163,
        VA_10: -9998.0,
        QC_10: 770.163,
        VC_10: -9998.0,
        QE_10: 731.395,
        VE_10: -9998.0,
        QA_11: 2111.645,
        VA_11: -9998.0,
        QC_11: 2111.645,
        VC_11: -9998.0,
        QE_11: 2061.404,
        VE_11: -9998.0,
        QA_12: 2989.572,
        VA_12: -9998.0,
        QC_12: 2989.572,
        VC_12: -9998.0,
        QE_12: 2991.054,
        VE_12: -9998.0,
        LakeFract: 0.03184814322,
        SurfArea: 4166223.3315499998,
        RAreaHLoad: 0.66987390093,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.06369991794611, 35.713837811231656, 0.0],
          [-81.060180184618389, 35.711084877902692, 0.0],
          [-81.043678984643975, 35.70803887790737, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286063.0,
        COMID: 9754084,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.215,
        REACHCODE: "03050101002738",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011163556917005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061516.0,
        ToNode: 250061547.0,
        Hydroseq: 250009282.0,
        LevelPathI: 250003258.0,
        Pathlength: 557.405,
        TerminalPa: 250002604.0,
        ArbolateSu: 3362.379,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009317.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009243.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4084,
        TotDASqKM: 3772.4751,
        DivDASqKM: 3772.4751,
        Tidal: 0,
        TOTMA: 1.5152426799300001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23083.0,
        MAXELEVSMO: 23157.0,
        MINELEVSMO: 23095.0,
        SLOPE: 0.00051028,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2150000333786011,
        QA_MA: 2250.866,
        VA_MA: -9998.0,
        QC_MA: 2250.866,
        VC_MA: -9998.0,
        QE_MA: 2442.921,
        VE_MA: -9998.0,
        QA_01: 3652.394,
        VA_01: -9998.0,
        QC_01: 3652.394,
        VC_01: -9998.0,
        QE_01: 3850.534,
        VE_01: -9998.0,
        QA_02: 3859.536,
        VA_02: -9998.0,
        QC_02: 3859.536,
        VC_02: -9998.0,
        QE_02: 3923.49,
        VE_02: -9998.0,
        QA_03: 4360.954,
        VA_03: -9998.0,
        QC_03: 4360.954,
        VC_03: -9998.0,
        QE_03: 4619.492,
        VE_03: -9998.0,
        QA_04: 3351.814,
        VA_04: -9998.0,
        QC_04: 3351.814,
        VC_04: -9998.0,
        QE_04: 3540.926,
        VE_04: -9998.0,
        QA_05: 2542.847,
        VA_05: -9998.0,
        QC_05: 2542.847,
        VC_05: -9998.0,
        QE_05: 2764.054,
        VE_05: -9998.0,
        QA_06: 1482.834,
        VA_06: -9998.0,
        QC_06: 1482.834,
        VC_06: -9998.0,
        QE_06: 1744.618,
        VE_06: -9998.0,
        QA_07: 876.729,
        VA_07: -9998.0,
        QC_07: 876.729,
        VC_07: -9998.0,
        QE_07: 1027.071,
        VE_07: -9998.0,
        QA_08: 615.034,
        VA_08: -9998.0,
        QC_08: 615.034,
        VC_08: -9998.0,
        QE_08: 916.488,
        VE_08: -9998.0,
        QA_09: 595.258,
        VA_09: -9998.0,
        QC_09: 1101.885,
        VC_09: -9998.0,
        QE_09: 1138.59,
        VE_09: -9998.0,
        QA_10: 756.357,
        VA_10: -9998.0,
        QC_10: 756.357,
        VC_10: -9998.0,
        QE_10: 696.786,
        VE_10: -9998.0,
        QA_11: 2061.6,
        VA_11: -9998.0,
        QC_11: 2061.6,
        VC_11: -9998.0,
        QE_11: 2005.819,
        VE_11: -9998.0,
        QA_12: 2869.675,
        VA_12: -9998.0,
        QC_12: 2869.675,
        VC_12: -9998.0,
        QE_12: 2879.042,
        VE_12: -9998.0,
        LakeFract: 0.01881728392,
        SurfArea: 2461588.0041200002,
        RAreaHLoad: 0.41105898478000003,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.067631584606772, 35.738482211193457, 0.0],
          [-81.063898384612571, 35.728241877875973, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 291213.0,
        COMID: 9718487,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1249440",
        GNIS_NAME: "Wateree River",
        LENGTHKM: 2.984,
        REACHCODE: "03050104002506",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9737130,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.028306277289529998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250058271.0,
        ToNode: 250058274.0,
        Hydroseq: 250003910.0,
        LevelPathI: 250003258.0,
        Pathlength: 332.033,
        TerminalPa: 250002604.0,
        ArbolateSu: 9225.425,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250003917.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250003899.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.6128,
        TotDASqKM: 12270.5523,
        DivDASqKM: 12270.5523,
        Tidal: 0,
        TOTMA: 1.5067583904399999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 5176.0,
        MAXELEVSMO: 6586.0,
        MINELEVSMO: 5176.0,
        SLOPE: 0.0047252,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.9839999675750728,
        QA_MA: 5802.225,
        VA_MA: -9998.0,
        QC_MA: 5802.225,
        VC_MA: -9998.0,
        QE_MA: 6500.299,
        VE_MA: -9998.0,
        QA_01: 10992.533,
        VA_01: -9998.0,
        QC_01: 10992.533,
        VC_01: -9998.0,
        QE_01: 9598.318,
        VE_01: -9998.0,
        QA_02: 11633.759,
        VA_02: -9998.0,
        QC_02: 11633.759,
        VC_02: -9998.0,
        QE_02: 9792.076,
        VE_02: -9998.0,
        QA_03: 12726.185,
        VA_03: -9998.0,
        QC_03: 12726.185,
        VC_03: -9998.0,
        QE_03: 10474.333,
        VE_03: -9998.0,
        QA_04: 8460.04,
        VA_04: -9998.0,
        QC_04: 8460.04,
        VC_04: -9998.0,
        QE_04: 8208.229,
        VE_04: -9998.0,
        QA_05: 5602.634,
        VA_05: -9998.0,
        QC_05: 5602.634,
        VC_05: -9998.0,
        QE_05: 6558.006,
        VE_05: -9998.0,
        QA_06: 3350.572,
        VA_06: -9998.0,
        QC_06: 3350.572,
        VC_06: -9998.0,
        QE_06: 5576.011,
        VE_06: -9998.0,
        QA_07: 2130.402,
        VA_07: -9998.0,
        QC_07: 2130.402,
        VC_07: -9998.0,
        QE_07: 4207.757,
        VE_07: -9998.0,
        QA_08: 1539.119,
        VA_08: -9998.0,
        QC_08: 1539.119,
        VC_08: -9998.0,
        QE_08: 3836.273,
        VE_08: -9998.0,
        QA_09: 1365.752,
        VA_09: -9998.0,
        QC_09: 1365.752,
        VC_09: -9998.0,
        QE_09: 3504.248,
        VE_09: -9998.0,
        QA_10: 1411.564,
        VA_10: -9998.0,
        QC_10: 1411.564,
        VC_10: -9998.0,
        QE_10: 4733.866,
        VE_10: -9998.0,
        QA_11: 3346.702,
        VA_11: -9998.0,
        QC_11: 3346.702,
        VC_11: -9998.0,
        QE_11: 4794.199,
        VE_11: -9998.0,
        QA_12: 7107.177,
        VA_12: -9998.0,
        QC_12: 7107.177,
        VC_12: -9998.0,
        QE_12: 6815.072,
        VE_12: -9998.0,
        LakeFract: 0.0990251778,
        SurfArea: 4490634.6997300005,
        RAreaHLoad: 0.28232649746,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.714678918488005, 34.359867013333428, 0.0],
          [-80.711824985159069, 34.356634813338303, 0.0],
          [-80.712509385158057, 34.353191680010355, 0.0],
          [-80.708109385164789, 34.343502280025405, 0.0],
          [-80.701051518509132, 34.33726581336839, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284063.0,
        COMID: 9752970,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 0.767,
        REACHCODE: "03050101000477",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0074551290151399999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061185.0,
        ToNode: 250061422.0,
        Hydroseq: 250028899.0,
        LevelPathI: 250028899.0,
        Pathlength: 636.808,
        TerminalPa: 250002604.0,
        ArbolateSu: 132.345,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250029943.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012699.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4239,
        TotDASqKM: 158.139,
        DivDASqKM: 158.139,
        Tidal: 0,
        TOTMA: 0.022787771128600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30482.0,
        MAXELEVSMO: 30791.0,
        MINELEVSMO: 30538.0,
        SLOPE: 0.00329856,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.76700001955032304,
        QA_MA: 93.61,
        VA_MA: 1.2769,
        QC_MA: 95.058,
        VC_MA: 1.2781,
        QE_MA: 95.058,
        VE_MA: 1.2781,
        QA_01: 163.324,
        VA_01: 1.60994,
        QC_01: 129.597,
        VC_01: 1.45158,
        QE_01: 129.597,
        VE_01: 1.45158,
        QA_02: 172.412,
        VA_02: 1.64791,
        QC_02: 145.976,
        VC_02: 1.52616,
        QE_02: 145.976,
        VE_02: 1.52616,
        QA_03: 183.142,
        VA_03: 1.69155,
        QC_03: 157.018,
        VC_03: 1.57423,
        QE_03: 157.018,
        VE_03: 1.57423,
        QA_04: 135.815,
        VA_04: 1.48851,
        QC_04: 127.116,
        VC_04: 1.43991,
        QE_04: 127.116,
        VE_04: 1.43991,
        QA_05: 100.383,
        VA_05: 1.3135,
        QC_05: 95.943,
        VC_05: 1.28289,
        QE_05: 95.943,
        VE_05: 1.28289,
        QA_06: 57.411,
        VA_06: 1.05546,
        QC_06: 72.467,
        VC_06: 1.14798,
        QE_06: 72.467,
        VE_06: 1.14798,
        QA_07: 33.352,
        VA_07: 0.86829,
        QC_07: 51.157,
        VC_07: 1.00624,
        QE_07: 51.157,
        VE_07: 1.00624,
        QA_08: 22.857,
        VA_08: 0.7665,
        QC_08: 50.482,
        VC_08: 1.00133,
        QE_08: 50.482,
        VE_08: 1.00133,
        QA_09: 19.501,
        VA_09: 0.72946,
        QC_09: 53.87,
        VC_09: 1.02565,
        QE_09: 53.87,
        VE_09: 1.02565,
        QA_10: 27.739,
        VA_10: 0.81609,
        QC_10: 78.191,
        VC_10: 1.18256,
        QE_10: 78.191,
        VE_10: 1.18256,
        QA_11: 84.712,
        VA_11: 1.22687,
        QC_11: 112.911,
        VC_11: 1.3709,
        QE_11: 112.911,
        VE_11: 1.3709,
        QA_12: 123.298,
        VA_12: 1.42945,
        QC_12: 105.589,
        VC_12: 1.33374,
        QE_12: 105.589,
        VE_12: 1.33374,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.712762916938743, 35.735590877864581, 0.0],
          [-81.710404783609079, 35.73666901119617, 0.0],
          [-81.71215091693972, 35.740790011189858, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286065.0,
        COMID: 9754150,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.667,
        REACHCODE: "03050101002741",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.015140142441122001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061547.0,
        ToNode: 250061555.0,
        Hydroseq: 250009243.0,
        LevelPathI: 250003258.0,
        Pathlength: 555.738,
        TerminalPa: 250002604.0,
        ArbolateSu: 3365.383,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009282.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009201.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.3471,
        TotDASqKM: 3777.0894,
        DivDASqKM: 3777.0894,
        Tidal: 0,
        TOTMA: 2.07893789897,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23080.0,
        MAXELEVSMO: 23095.0,
        MINELEVSMO: 23095.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6670000553131099,
        QA_MA: 2252.871,
        VA_MA: -9998.0,
        QC_MA: 2252.871,
        VC_MA: -9998.0,
        QE_MA: 2444.926,
        VE_MA: -9998.0,
        QA_01: 3656.387,
        VA_01: -9998.0,
        QC_01: 3656.387,
        VC_01: -9998.0,
        QE_01: 3854.527,
        VE_01: -9998.0,
        QA_02: 3863.693,
        VA_02: -9998.0,
        QC_02: 3863.693,
        VC_02: -9998.0,
        QE_02: 3927.647,
        VE_02: -9998.0,
        QA_03: 4365.359,
        VA_03: -9998.0,
        QC_03: 4365.359,
        VC_03: -9998.0,
        QE_03: 4623.897,
        VE_03: -9998.0,
        QA_04: 3354.824,
        VA_04: -9998.0,
        QC_04: 3354.824,
        VC_04: -9998.0,
        QE_04: 3543.935,
        VE_04: -9998.0,
        QA_05: 2544.754,
        VA_05: -9998.0,
        QC_05: 2544.754,
        VC_05: -9998.0,
        QE_05: 2765.961,
        VE_05: -9998.0,
        QA_06: 1483.982,
        VA_06: -9998.0,
        QC_06: 1483.982,
        VC_06: -9998.0,
        QE_06: 1745.766,
        VE_06: -9998.0,
        QA_07: 877.447,
        VA_07: -9998.0,
        QC_07: 877.447,
        VC_07: -9998.0,
        QE_07: 1027.789,
        VE_07: -9998.0,
        QA_08: 615.53,
        VA_08: -9998.0,
        QC_08: 615.53,
        VC_08: -9998.0,
        QE_08: 916.985,
        VE_08: -9998.0,
        QA_09: 595.692,
        VA_09: -9998.0,
        QC_09: 1102.595,
        VC_09: -9998.0,
        QE_09: 1139.3,
        VE_09: -9998.0,
        QA_10: 756.667,
        VA_10: -9998.0,
        QC_10: 756.667,
        VC_10: -9998.0,
        QE_10: 697.096,
        VE_10: -9998.0,
        QA_11: 2062.541,
        VA_11: -9998.0,
        QC_11: 2062.541,
        VC_11: -9998.0,
        QE_11: 2006.76,
        VE_11: -9998.0,
        QA_12: 2872.267,
        VA_12: -9998.0,
        QC_12: 2872.267,
        VC_12: -9998.0,
        QE_12: 2881.633,
        VE_12: -9998.0,
        LakeFract: 0.02583877175,
        SurfArea: 3380105.8033400001,
        RAreaHLoad: 0.56397969348,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.063898384612571, 35.728241877875973, 0.0],
          [-81.065721317943087, 35.720741211220911, 0.0],
          [-81.06369991794611, 35.713837811231656, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 290198.0,
        COMID: 9715249,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.229,
        REACHCODE: "03050104001321",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0022464771014859999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250057370.0,
        ToNode: 250057369.0,
        Hydroseq: 250022165.0,
        LevelPathI: 250022165.0,
        Pathlength: 361.846,
        TerminalPa: 250002604.0,
        ArbolateSu: 88.335,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018611.0,
        UpHydroseq: 250022633.0,
        DnLevelPat: 250018611.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021727.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.036,
        TotDASqKM: 71.0379,
        DivDASqKM: 0.036,
        Tidal: 0,
        TOTMA: 0.024004154444,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 7384.0,
        MAXELEVSMO: 7384.0,
        MINELEVSMO: 7384.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.079000003635882998,
        QA_MA: 0.013,
        VA_MA: 0.36226,
        QC_MA: 0.013,
        VC_MA: 0.36226,
        QE_MA: 0.013,
        VE_MA: 0.36226,
        QA_01: 0.028,
        VA_01: 0.38936,
        QC_01: 0.028,
        VC_01: 0.38936,
        QE_01: 0.028,
        VE_01: 0.38936,
        QA_02: 0.03,
        VA_02: 0.39238,
        QC_02: 0.03,
        VC_02: 0.39238,
        QE_02: 0.03,
        VE_02: 0.39238,
        QA_03: 0.033,
        VA_03: 0.39674,
        QC_03: 0.033,
        VC_03: 0.39674,
        QE_03: 0.033,
        VE_03: 0.39674,
        QA_04: 0.018,
        VA_04: 0.37243,
        QC_04: 0.018,
        VC_04: 0.37243,
        QE_04: 0.018,
        VE_04: 0.37243,
        QA_05: 0.01,
        VA_05: 0.35526,
        QC_05: 0.01,
        VC_05: 0.35526,
        QE_05: 0.01,
        VE_05: 0.35526,
        QA_06: 0.006,
        VA_06: 0.34412,
        QC_06: 0.006,
        VC_06: 0.34412,
        QE_06: 0.006,
        VE_06: 0.34412,
        QA_07: 0.004,
        VA_07: 0.3372,
        QC_07: 0.004,
        VC_07: 0.3372,
        QE_07: 0.004,
        VE_07: 0.3372,
        QA_08: 0.003,
        VA_08: 0.33312,
        QC_08: 0.003,
        VC_08: 0.33312,
        QE_08: 0.003,
        VE_08: 0.33312,
        QA_09: 0.002,
        VA_09: 0.32833,
        QC_09: 0.002,
        VC_09: 0.32833,
        QE_09: 0.002,
        VE_09: 0.32833,
        QA_10: 0.002,
        VA_10: 0.32833,
        QC_10: 0.002,
        VC_10: 0.32833,
        QE_10: 0.002,
        VE_10: 0.32833,
        QA_11: 0.001,
        VA_11: 0.32219,
        QC_11: 0.001,
        VC_11: 0.32219,
        QE_11: 0.001,
        VE_11: 0.32219,
        QA_12: 0.011,
        VA_12: 0.35769,
        QC_12: 0.011,
        VC_12: 0.35769,
        QE_12: 0.011,
        VE_12: 0.35769,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.923931718163146, 34.385715679959958, 0.0],
          [-80.923158918164404, 34.387182213290998, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284064.0,
        COMID: 9753014,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 1.244,
        REACHCODE: "03050101000478",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012565003291582,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061204.0,
        ToNode: 250061185.0,
        Hydroseq: 250029943.0,
        LevelPathI: 250028899.0,
        Pathlength: 637.575,
        TerminalPa: 250002604.0,
        ArbolateSu: 129.019,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250031107.0,
        DnLevelPat: 250028899.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028899.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.035,
        TotDASqKM: 153.6237,
        DivDASqKM: 153.6237,
        Tidal: 0,
        TOTMA: 0.051012979240799998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30654.0,
        MAXELEVSMO: 30816.0,
        MINELEVSMO: 30791.0,
        SLOPE: 0.00020096,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2439999580383301,
        QA_MA: 91.314,
        VA_MA: 0.92518,
        QC_MA: 92.816,
        VC_MA: 0.926,
        QE_MA: 92.816,
        VE_MA: 0.926,
        QA_01: 159.02,
        VA_01: 1.13646,
        QC_01: 126.27,
        VC_01: 1.03566,
        QE_01: 126.27,
        VE_01: 1.03566,
        QA_02: 167.77,
        VA_02: 1.16035,
        QC_02: 142.151,
        VC_02: 1.08288,
        QE_02: 142.151,
        VE_02: 1.08288,
        QA_03: 178.257,
        VA_03: 1.18822,
        QC_03: 152.967,
        VC_03: 1.11363,
        QE_03: 152.967,
        VE_03: 1.11363,
        QA_04: 132.367,
        VA_04: 1.0596,
        QC_04: 123.962,
        VC_04: 1.02857,
        QE_04: 123.962,
        VE_04: 1.02857,
        QA_05: 98.108,
        VA_05: 0.94914,
        QC_05: 93.85,
        VC_05: 0.92964,
        QE_05: 93.85,
        VE_05: 0.92964,
        QA_06: 56.089,
        VA_06: 0.78454,
        QC_06: 70.821,
        VC_06: 0.84337,
        QE_06: 70.821,
        VE_06: 0.84337,
        QA_07: 32.563,
        VA_07: 0.66513,
        QC_07: 49.975,
        VC_07: 0.75296,
        QE_07: 49.975,
        VE_07: 0.75296,
        QA_08: 22.311,
        VA_08: 0.60024,
        QC_08: 49.251,
        VC_08: 0.74953,
        QE_08: 49.251,
        VE_08: 0.74953,
        QA_09: 19.076,
        VA_09: 0.57694,
        QC_09: 52.832,
        VC_09: 0.76628,
        QE_09: 52.832,
        VE_09: 0.76628,
        QA_10: 27.375,
        VA_10: 0.63372,
        QC_10: 77.37,
        VC_10: 0.8691,
        QE_10: 77.37,
        VE_10: 0.8691,
        QA_11: 83.173,
        VA_11: 0.89534,
        QC_11: 111.343,
        VC_11: 0.98866,
        QE_11: 111.343,
        VE_11: 0.98866,
        QA_12: 120.31,
        VA_12: 1.02245,
        QC_12: 103.258,
        VC_12: 0.96197,
        QE_12: 103.258,
        VE_12: 0.96197,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.722163183590851, 35.728598411208793, 0.0],
          [-81.71694658359894, 35.73451121119956, 0.0],
          [-81.712762916938743, 35.735590877864581, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287091.0,
        COMID: 9735844,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 1.347,
        REACHCODE: "03050103000359",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013151877285043001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059757.0,
        ToNode: 250059761.0,
        Hydroseq: 250024864.0,
        LevelPathI: 250019187.0,
        Pathlength: 374.498,
        TerminalPa: 250002604.0,
        ArbolateSu: 216.612,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250025542.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024241.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1367,
        TotDASqKM: 472.8645,
        DivDASqKM: 472.8645,
        Tidal: 0,
        TOTMA: 0.039101908916099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9166.0,
        MAXELEVSMO: 9397.0,
        MINELEVSMO: 9258.0,
        SLOPE: 0.00103192,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3470000028610229,
        QA_MA: 183.743,
        VA_MA: 1.30918,
        QC_MA: 181.766,
        VC_MA: 1.30831,
        QE_MA: 181.307,
        VE_MA: 1.3081,
        QA_01: 402.449,
        VA_01: 1.82596,
        QC_01: 311.883,
        VC_01: 1.64029,
        QE_01: 316.136,
        VE_01: 1.65143,
        QA_02: 428.423,
        VA_02: 1.8772,
        QC_02: 353.844,
        VC_02: 1.73262,
        QE_02: 351.581,
        VE_02: 1.72939,
        QA_03: 459.635,
        VA_03: 1.93689,
        QC_03: 382.282,
        VC_03: 1.7923,
        QE_03: 381.597,
        VE_03: 1.79257,
        QA_04: 264.446,
        VA_04: 1.52264,
        QC_04: 243.789,
        VC_04: 1.47699,
        QE_04: 241.194,
        VE_04: 1.47169,
        QA_05: 146.369,
        VA_05: 1.19535,
        QC_05: 137.936,
        VC_05: 1.17203,
        QE_05: 135.171,
        VE_05: 1.16376,
        QA_06: 94.243,
        VA_06: 1.01045,
        QC_06: 118.152,
        VC_06: 1.10387,
        QE_06: 116.429,
        VE_06: 1.09859,
        QA_07: 63.578,
        VA_07: 0.87803,
        QC_07: 96.025,
        VC_07: 1.02093,
        QE_07: 94.281,
        VE_07: 1.01483,
        QA_08: 50.862,
        VA_08: 0.81438,
        QC_08: 114.144,
        VC_08: 1.08942,
        QE_08: 109.823,
        VE_08: 1.07445,
        QA_09: 39.27,
        VA_09: 0.74944,
        QC_09: 99.943,
        VC_09: 1.03622,
        QE_09: 98.908,
        VE_09: 1.03303,
        QA_10: 31.789,
        VA_10: 0.70262,
        QC_10: 87.183,
        VC_10: 0.9853,
        QE_10: 93.563,
        VE_10: 1.01197,
        QA_11: 29.705,
        VA_11: 0.68868,
        QC_11: 50.775,
        VC_11: 0.81639,
        QE_11: 56.82,
        VE_11: 0.84827,
        QA_12: 195.489,
        VA_12: 1.34266,
        QC_12: 160.597,
        VC_12: 1.24468,
        QE_12: 160.756,
        VE_12: 1.24624,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.937472384808871, 34.577914012994938, 0.0],
          [-80.932648518149676, 34.570174213006851, 0.0],
          [-80.929933984820536, 34.568961479675465, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286066.0,
        COMID: 9754156,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.123,
        REACHCODE: "03050101002742",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011742110887392,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061550.0,
        ToNode: 250060668.0,
        Hydroseq: 250009115.0,
        LevelPathI: 250003258.0,
        Pathlength: 549.376,
        TerminalPa: 250002604.0,
        ArbolateSu: 3568.3,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009158.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009078.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.979,
        TotDASqKM: 4044.7305,
        DivDASqKM: 4044.7305,
        Tidal: 0,
        TOTMA: 1.40050825411,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22942.0,
        MAXELEVSMO: 23074.0,
        MINELEVSMO: 23074.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.123000025749207,
        QA_MA: 2374.472,
        VA_MA: -9998.0,
        QC_MA: 2374.472,
        VC_MA: -9998.0,
        QE_MA: 2568.518,
        VE_MA: -9998.0,
        QA_01: 3890.829,
        VA_01: -9998.0,
        QC_01: 3890.829,
        VC_01: -9998.0,
        QE_01: 4075.636,
        VE_01: -9998.0,
        QA_02: 4110.17,
        VA_02: -9998.0,
        QC_02: 4110.17,
        VC_02: -9998.0,
        QE_02: 4158.468,
        VE_02: -9998.0,
        QA_03: 4629.914,
        VA_03: -9998.0,
        QC_03: 4629.914,
        VC_03: -9998.0,
        QE_03: 4866.18,
        VE_03: -9998.0,
        QA_04: 3538.799,
        VA_04: -9998.0,
        QC_04: 3538.799,
        VC_04: -9998.0,
        QE_04: 3721.377,
        VE_04: -9998.0,
        QA_05: 2664.655,
        VA_05: -9998.0,
        QC_05: 2664.655,
        VC_05: -9998.0,
        QE_05: 2887.718,
        VE_05: -9998.0,
        QA_06: 1554.631,
        VA_06: -9998.0,
        QC_06: 1554.631,
        VC_06: -9998.0,
        QE_06: 1832.005,
        VE_06: -9998.0,
        QA_07: 921.252,
        VA_07: -9998.0,
        QC_07: 921.252,
        VC_07: -9998.0,
        QE_07: 1086.73,
        VE_07: -9998.0,
        QA_08: 645.036,
        VA_08: -9998.0,
        QC_08: 645.036,
        VC_08: -9998.0,
        QE_08: 965.219,
        VE_08: -9998.0,
        QA_09: 620.909,
        VA_09: -9998.0,
        QC_09: 1143.704,
        VC_09: -9998.0,
        QE_09: 1195.082,
        VE_09: -9998.0,
        QA_10: 774.865,
        VA_10: -9998.0,
        QC_10: 774.865,
        VC_10: -9998.0,
        QE_10: 736.463,
        VE_10: -9998.0,
        QA_11: 2127.913,
        VA_11: -9998.0,
        QC_11: 2127.913,
        VC_11: -9998.0,
        QE_11: 2077.77,
        VE_11: -9998.0,
        QA_12: 3030.262,
        VA_12: -9998.0,
        QC_12: 3030.262,
        VC_12: -9998.0,
        QE_12: 3031.605,
        VE_12: -9998.0,
        LakeFract: 0.01828489427,
        SurfArea: 2391943.31036,
        RAreaHLoad: 0.3799335306,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.009922718029713, 35.709169277905687, 0.0],
          [-80.999799518045393, 35.70373081124734, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284065.0,
        COMID: 9753024,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 1.055,
        REACHCODE: "03050101000479",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011681149298702001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061208.0,
        ToNode: 250061204.0,
        Hydroseq: 250031107.0,
        LevelPathI: 250028899.0,
        Pathlength: 638.819,
        TerminalPa: 250002604.0,
        ArbolateSu: 106.706,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250032406.0,
        DnLevelPat: 250028899.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029943.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6444,
        TotDASqKM: 129.5784,
        DivDASqKM: 129.5784,
        Tidal: 0,
        TOTMA: 0.035604558314400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30739.0,
        MAXELEVSMO: 30972.0,
        MINELEVSMO: 30816.0,
        SLOPE: 0.00147867,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.054999947547913,
        QA_MA: 77.769,
        VA_MA: 1.12368,
        QC_MA: 79.542,
        VC_MA: 1.12517,
        QE_MA: 79.542,
        VE_MA: 1.12517,
        QA_01: 134.675,
        VA_01: 1.39969,
        QC_01: 107.405,
        VC_01: 1.26638,
        QE_01: 107.405,
        VE_01: 1.26638,
        QA_02: 141.922,
        VA_02: 1.43049,
        QC_02: 120.8,
        VC_02: 1.32807,
        QE_02: 120.8,
        VE_02: 1.32807,
        QA_03: 151.087,
        VA_03: 1.4684,
        QC_03: 130.361,
        VC_03: 1.37016,
        QE_03: 130.361,
        VE_03: 1.37016,
        QA_04: 112.602,
        VA_04: 1.30074,
        QC_04: 105.84,
        VC_04: 1.25894,
        QE_04: 105.84,
        VE_04: 1.25894,
        QA_05: 84.084,
        VA_05: 1.15819,
        QC_05: 80.9,
        VC_05: 1.13254,
        QE_05: 80.9,
        VE_05: 1.13254,
        QA_06: 48.002,
        VA_06: 0.9394,
        QC_06: 60.74,
        VC_06: 1.01619,
        QE_06: 60.74,
        VE_06: 1.01619,
        QA_07: 27.838,
        VA_07: 0.78088,
        QC_07: 42.885,
        VC_07: 0.89674,
        QE_07: 42.885,
        VE_07: 0.89674,
        QA_08: 19.119,
        VA_08: 0.69542,
        QC_08: 42.075,
        VC_08: 0.89082,
        QE_08: 42.075,
        VE_08: 0.89082,
        QA_09: 16.379,
        VA_09: 0.6649,
        QC_09: 46.18,
        VC_09: 0.92033,
        QE_09: 46.18,
        VE_09: 0.92033,
        QA_10: 23.947,
        VA_10: 0.74458,
        QC_10: 69.527,
        VC_10: 1.06884,
        QE_10: 69.527,
        VE_10: 1.06884,
        QA_11: 71.859,
        VA_11: 1.09017,
        QC_11: 99.595,
        VC_11: 1.22873,
        QE_11: 99.595,
        VE_11: 1.22873,
        QA_12: 102.369,
        VA_12: 1.25178,
        QC_12: 89.149,
        VC_12: 1.17615,
        QE_12: 89.149,
        VE_12: 1.17615,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.733676116906167, 35.727214011210947, 0.0],
          [-81.722163183590851, 35.728598411208793, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287092.0,
        COMID: 9735664,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.92,
        REACHCODE: "03050103000360",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0091005585154009996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059739.0,
        ToNode: 250059757.0,
        Hydroseq: 250025542.0,
        LevelPathI: 250019187.0,
        Pathlength: 375.845,
        TerminalPa: 250002604.0,
        ArbolateSu: 212.829,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250026283.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024864.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2565,
        TotDASqKM: 469.9107,
        DivDASqKM: 469.9107,
        Tidal: 0,
        TOTMA: 0.0444435735692,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9228.0,
        MAXELEVSMO: 9397.0,
        MINELEVSMO: 9397.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.92000001668930098,
        QA_MA: 182.758,
        VA_MA: 0.78656,
        QC_MA: 180.83,
        VC_MA: 0.78614,
        QE_MA: 180.373,
        VE_MA: 0.78605,
        QA_01: 400.322,
        VA_01: 1.0335,
        QC_01: 310.278,
        VC_01: 0.94477,
        QE_01: 314.505,
        VE_01: 0.95009,
        QA_02: 426.054,
        VA_02: 1.05789,
        QC_02: 351.941,
        VC_02: 0.9888,
        QE_02: 349.692,
        VE_02: 0.98726,
        QA_03: 457.021,
        VA_03: 1.08634,
        QC_03: 380.18,
        VC_03: 1.01727,
        QE_03: 379.5,
        VE_03: 1.0174,
        QA_04: 263.071,
        VA_04: 0.88859,
        QC_04: 242.55,
        VC_04: 0.86676,
        QE_04: 239.971,
        VE_04: 0.86423,
        QA_05: 145.608,
        VA_05: 0.7322,
        QC_05: 137.245,
        VC_05: 0.72106,
        QE_05: 134.497,
        VE_05: 0.71712,
        QA_06: 93.726,
        VA_06: 0.6438,
        QC_06: 117.513,
        VC_06: 0.68841,
        QE_06: 115.801,
        VE_06: 0.68589,
        QA_07: 63.18,
        VA_07: 0.58043,
        QC_07: 95.439,
        VC_07: 0.64866,
        QE_07: 93.706,
        VE_07: 0.64575,
        QA_08: 50.544,
        VA_08: 0.55003,
        QC_08: 113.417,
        VC_08: 0.68132,
        QE_08: 109.122,
        VE_08: 0.67417,
        QA_09: 39.031,
        VA_09: 0.51904,
        QC_09: 99.407,
        VC_09: 0.6561,
        QE_09: 98.378,
        VE_09: 0.65458,
        QA_10: 31.59,
        VA_10: 0.49666,
        QC_10: 86.748,
        VC_10: 0.63184,
        QE_10: 93.088,
        VE_10: 0.64457,
        QA_11: 29.546,
        VA_11: 0.49009,
        QC_11: 50.568,
        VC_11: 0.55125,
        QE_11: 56.575,
        VE_11: 0.56646,
        QA_12: 194.741,
        VA_12: 0.80296,
        QC_12: 160.038,
        VC_12: 0.75614,
        QE_12: 160.196,
        VE_12: 0.75689,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.942129184801615, 34.583202879653356, 0.0],
          [-80.940465784804189, 34.581142212989903, 0.0],
          [-80.941268118136236, 34.579813212991837, 0.0],
          [-80.937472384808871, 34.577914012994938, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284066.0,
        COMID: 9753098,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015522",
        GNIS_NAME: "Silver Creek",
        LENGTHKM: 1.633,
        REACHCODE: "03050101000480",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016376390252931,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061228.0,
        ToNode: 250061208.0,
        Hydroseq: 250032406.0,
        LevelPathI: 250028899.0,
        Pathlength: 639.874,
        TerminalPa: 250002604.0,
        ArbolateSu: 94.296,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028899.0,
        UpHydroseq: 250033873.0,
        DnLevelPat: 250028899.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031107.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.575,
        TotDASqKM: 115.722,
        DivDASqKM: 115.722,
        Tidal: 0,
        TOTMA: 0.054596308724400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30886.0,
        MAXELEVSMO: 31278.0,
        MINELEVSMO: 30972.0,
        SLOPE: 0.00187385,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6330000162124629,
        QA_MA: 70.246,
        VA_MA: 1.13401,
        QC_MA: 72.132,
        VC_MA: 1.13578,
        QE_MA: 72.132,
        VE_MA: 1.13578,
        QA_01: 121.182,
        VA_01: 1.41127,
        QC_01: 96.913,
        VC_01: 1.27626,
        QE_01: 96.913,
        VE_01: 1.27626,
        QA_02: 127.592,
        VA_02: 1.44188,
        QC_02: 108.918,
        VC_02: 1.33817,
        QE_02: 108.918,
        VE_02: 1.33817,
        QA_03: 135.779,
        VA_03: 1.47993,
        QC_03: 117.567,
        VC_03: 1.38082,
        QE_03: 117.567,
        VE_03: 1.38082,
        QA_04: 101.363,
        VA_04: 1.31149,
        QC_04: 95.504,
        VC_04: 1.26876,
        QE_04: 95.504,
        VE_04: 1.26876,
        QA_05: 75.965,
        VA_05: 1.16905,
        QC_05: 73.367,
        VC_05: 1.14328,
        QE_05: 73.367,
        VE_05: 1.14328,
        QA_06: 43.425,
        VA_06: 0.94792,
        QC_06: 55.025,
        VC_06: 1.025,
        QE_06: 55.025,
        VE_06: 1.025,
        QA_07: 25.164,
        VA_07: 0.78706,
        QC_07: 38.858,
        VC_07: 0.90413,
        QE_07: 38.858,
        VE_07: 0.90413,
        QA_08: 17.282,
        VA_08: 0.70048,
        QC_08: 37.956,
        VC_08: 0.89675,
        QE_08: 37.956,
        VE_08: 0.89675,
        QA_09: 14.929,
        VA_09: 0.67116,
        QC_09: 42.551,
        VC_09: 0.93356,
        QE_09: 42.551,
        VE_09: 0.93356,
        QA_10: 22.794,
        VA_10: 0.76257,
        QC_10: 66.841,
        VC_10: 1.10298,
        QE_10: 66.841,
        VE_10: 1.10298,
        QA_11: 65.623,
        VA_11: 1.1047,
        QC_11: 92.933,
        VC_11: 1.25494,
        QE_11: 92.933,
        VE_11: 1.25494,
        QA_12: 92.477,
        VA_12: 1.2638,
        QC_12: 81.276,
        VC_12: 1.18992,
        QE_12: 81.276,
        VE_12: 1.18992,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.745259716888313, 35.717286211226337, 0.0],
          [-81.735552716903328, 35.72363961121647, 0.0],
          [-81.733676116906167, 35.727214011210947, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287093.0,
        COMID: 9735580,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 1.512,
        REACHCODE: "03050103000361",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014248317470410001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059727.0,
        ToNode: 250059739.0,
        Hydroseq: 250026283.0,
        LevelPathI: 250019187.0,
        Pathlength: 376.765,
        TerminalPa: 250002604.0,
        ArbolateSu: 151.847,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250027081.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025542.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1043,
        TotDASqKM: 326.6343,
        DivDASqKM: 326.6343,
        Tidal: 0,
        TOTMA: 0.044723508619199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9291.0,
        MAXELEVSMO: 9622.0,
        MINELEVSMO: 9397.0,
        SLOPE: 0.00148809,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5119999647140501,
        QA_MA: 128.55,
        VA_MA: 1.28373,
        QC_MA: 128.942,
        VC_MA: 1.28397,
        QE_MA: 128.624,
        VE_MA: 1.28377,
        QA_01: 281.645,
        VA_01: 1.7876,
        QC_01: 220.316,
        VC_01: 1.60497,
        QE_01: 223.254,
        VE_01: 1.61558,
        QA_02: 298.257,
        VA_02: 1.8333,
        QC_02: 248.78,
        VC_02: 1.69139,
        QE_02: 247.218,
        VE_02: 1.6883,
        QA_03: 319.306,
        VA_03: 1.88953,
        QC_03: 268.782,
        VC_03: 1.74936,
        QE_03: 268.309,
        VE_03: 1.74961,
        QA_04: 185.472,
        VA_04: 1.49332,
        QC_04: 172.368,
        VC_04: 1.44655,
        QE_04: 170.576,
        VE_04: 1.4415,
        QA_05: 102.417,
        VA_05: 1.17285,
        QC_05: 97.814,
        VC_05: 1.15084,
        QE_05: 95.904,
        VE_05: 1.143,
        QA_06: 65.823,
        VA_06: 0.99198,
        QC_06: 82.93,
        VC_06: 1.08015,
        QE_06: 81.74,
        VE_06: 1.0751,
        QA_07: 43.917,
        VA_07: 0.8598,
        QC_07: 66.919,
        VC_07: 0.99705,
        QE_07: 65.715,
        VE_07: 0.99121,
        QA_08: 35.133,
        VA_08: 0.79818,
        QC_08: 78.264,
        VC_08: 1.05678,
        QE_08: 75.279,
        VE_08: 1.0423,
        QA_09: 27.473,
        VA_09: 0.73822,
        QC_09: 72.907,
        VC_09: 1.02912,
        QE_09: 72.192,
        VE_09: 1.02616,
        QA_10: 21.958,
        VA_10: 0.69,
        QC_10: 64.876,
        VC_10: 0.98581,
        QE_10: 69.283,
        VE_10: 1.01065,
        QA_11: 21.07,
        VA_11: 0.68173,
        QC_11: 39.074,
        VC_11: 0.82592,
        QE_11: 43.25,
        VE_11: 0.85519,
        QA_12: 141.136,
        VA_12: 1.33332,
        QC_12: 119.402,
        VC_12: 1.24495,
        QE_12: 119.512,
        VE_12: 1.24645,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.946154718128696, 34.594791079635343, 0.0],
          [-80.943134118133344, 34.593029079638086, 0.0],
          [-80.942129184801615, 34.583202879653356, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287094.0,
        COMID: 9735406,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.289,
        REACHCODE: "03050103000362",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0028343482986880001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059706.0,
        ToNode: 250059727.0,
        Hydroseq: 250027081.0,
        LevelPathI: 250019187.0,
        Pathlength: 378.277,
        TerminalPa: 250002604.0,
        ArbolateSu: 148.634,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250031128.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026283.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1638,
        TotDASqKM: 324.5157,
        DivDASqKM: 324.5157,
        Tidal: 0,
        TOTMA: 0.014670284326599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9505.0,
        MAXELEVSMO: 9622.0,
        MINELEVSMO: 9622.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.289000004529953,
        QA_MA: 127.836,
        VA_MA: 0.74802,
        QC_MA: 128.253,
        VC_MA: 0.74814,
        QE_MA: 127.938,
        VE_MA: 0.74805,
        QA_01: 280.098,
        VA_01: 0.97516,
        QC_01: 219.137,
        VC_01: 0.89283,
        QE_01: 222.056,
        VE_01: 0.8976,
        QA_02: 296.547,
        VA_02: 0.99568,
        QC_02: 247.394,
        VC_02: 0.93171,
        QE_02: 245.841,
        VE_02: 0.93031,
        QA_03: 317.417,
        VA_03: 1.02096,
        QC_03: 267.245,
        VC_03: 0.95778,
        QE_03: 266.775,
        VE_03: 0.9579,
        QA_04: 184.475,
        VA_04: 0.84255,
        QC_04: 171.463,
        VC_04: 0.82145,
        QE_04: 169.682,
        VE_04: 0.81917,
        QA_05: 101.848,
        VA_05: 0.69804,
        QC_05: 97.291,
        VC_05: 0.68813,
        QE_05: 95.393,
        VE_05: 0.68459,
        QA_06: 65.453,
        VA_06: 0.61651,
        QC_06: 82.47,
        VC_06: 0.65622,
        QE_06: 81.287,
        VE_06: 0.65395,
        QA_07: 43.632,
        VA_07: 0.55681,
        QC_07: 66.496,
        VC_07: 0.61865,
        QE_07: 65.299,
        VE_07: 0.61601,
        QA_08: 34.905,
        VA_08: 0.52905,
        QC_08: 77.746,
        VC_08: 0.6455,
        QE_08: 74.78,
        VE_08: 0.63897,
        QA_09: 27.302,
        VA_09: 0.50207,
        QC_09: 72.506,
        VC_09: 0.63324,
        QE_09: 71.796,
        VE_09: 0.6319,
        QA_10: 21.816,
        VA_10: 0.48032,
        QC_10: 64.539,
        VC_10: 0.61377,
        QE_10: 68.918,
        VE_10: 0.62495,
        QA_11: 20.956,
        VA_11: 0.47669,
        QC_11: 38.913,
        VC_11: 0.54182,
        QE_11: 43.061,
        VE_11: 0.55499,
        QA_12: 140.574,
        VA_12: 0.77076,
        QC_12: 118.969,
        VC_12: 0.73094,
        QE_12: 119.079,
        VE_12: 0.73161,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.948095518125683, 34.596782812965614, 0.0],
          [-80.946154718128696, 34.594791079635343, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286109.0,
        COMID: 9756888,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.825,
        REACHCODE: "03050101002785",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0081925085000599997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061994.0,
        ToNode: 250061998.0,
        Hydroseq: 250007945.0,
        LevelPathI: 250003258.0,
        Pathlength: 469.962,
        TerminalPa: 250002604.0,
        ArbolateSu: 4343.785,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007970.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0179,
        TotDASqKM: 5343.4773,
        DivDASqKM: 5343.4773,
        Tidal: 0,
        TOTMA: 0.28330526258,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17118.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.82499998807907104,
        QA_MA: 2902.442,
        VA_MA: -9998.0,
        QC_MA: 2902.442,
        VC_MA: -9998.0,
        QE_MA: 3098.306,
        VE_MA: -9998.0,
        QA_01: 4956.755,
        VA_01: -9998.0,
        QC_01: 4956.755,
        VC_01: -9998.0,
        QE_01: 5139.648,
        VE_01: -9998.0,
        QA_02: 5261.949,
        VA_02: -9998.0,
        QC_02: 5261.949,
        VC_02: -9998.0,
        QE_02: 5305.362,
        VE_02: -9998.0,
        QA_03: 5872.416,
        VA_03: -9998.0,
        QC_03: 5872.416,
        VC_03: -9998.0,
        QE_03: 6097.848,
        VE_03: -9998.0,
        QA_04: 4324.574,
        VA_04: -9998.0,
        QC_04: 4324.574,
        VC_04: -9998.0,
        QE_04: 4501.477,
        VE_04: -9998.0,
        QA_05: 3129.869,
        VA_05: -9998.0,
        QC_05: 3129.869,
        VC_05: -9998.0,
        QE_05: 3356.564,
        VE_05: -9998.0,
        QA_06: 1833.193,
        VA_06: -9998.0,
        QC_06: 1833.193,
        VC_06: -9998.0,
        QE_06: 2117.771,
        VE_06: -9998.0,
        QA_07: 1102.912,
        VA_07: -9998.0,
        QC_07: 1102.912,
        VC_07: -9998.0,
        QE_07: 1273.547,
        VE_07: -9998.0,
        QA_08: 774.397,
        VA_08: -9998.0,
        QC_08: 774.397,
        VC_08: -9998.0,
        QE_08: 1101.926,
        VE_08: -9998.0,
        QA_09: 733.743,
        VA_09: -9998.0,
        QC_09: 1325.371,
        VC_09: -9998.0,
        QE_09: 1376.959,
        VE_09: -9998.0,
        QA_10: 864.84,
        VA_10: -9998.0,
        QC_10: 864.84,
        VC_10: -9998.0,
        QE_10: 839.173,
        VE_10: -9998.0,
        QA_11: 2312.184,
        VA_11: -9998.0,
        QC_11: 2312.184,
        VC_11: -9998.0,
        QE_11: 2267.498,
        VE_11: -9998.0,
        QA_12: 3682.19,
        VA_12: -9998.0,
        QC_12: 3682.19,
        VC_12: -9998.0,
        QE_12: 3678.208,
        VE_12: -9998.0,
        LakeFract: 0.01709724021,
        SurfArea: 774245.93450600002,
        RAreaHLoad: 0.10199056342,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.009428384697173, 35.214326012007007, 0.0],
          [-81.007749918033085, 35.214292812007102, 0.0],
          [-81.003498118039602, 35.209404012014716, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287087.0,
        COMID: 9735864,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.074,
        REACHCODE: "03050103000355",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00076500542882999996,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059765.0,
        ToNode: 250059771.0,
        Hydroseq: 250022617.0,
        LevelPathI: 250019187.0,
        Pathlength: 373.362,
        TerminalPa: 250002604.0,
        ArbolateSu: 247.658,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250023114.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022147.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0108,
        TotDASqKM: 507.3381,
        DivDASqKM: 507.3381,
        Tidal: 0,
        TOTMA: 0.003542898062,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9137.0,
        MAXELEVSMO: 9137.0,
        MINELEVSMO: 9137.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 195.538,
        VA_MA: 0.79469,
        QC_MA: 192.967,
        VC_MA: 0.79417,
        QE_MA: 187.923,
        VE_MA: 0.79313,
        QA_01: 428.099,
        VA_01: 1.04564,
        QC_01: 331.224,
        VC_01: 0.95558,
        QE_01: 377.903,
        VE_01: 1.01084,
        QA_02: 456.75,
        VA_02: 1.07144,
        QC_02: 376.582,
        VC_02: 1.00123,
        QE_02: 351.752,
        VE_02: 0.98459,
        QA_03: 490.697,
        VA_03: 1.10105,
        QC_03: 407.237,
        VC_03: 1.03063,
        QE_03: 399.722,
        VE_03: 1.0321,
        QA_04: 280.948,
        VA_04: 0.89789,
        QC_04: 258.646,
        VC_04: 0.87593,
        QE_04: 230.169,
        VE_04: 0.84824,
        QA_05: 155.627,
        VA_05: 0.73917,
        QC_05: 146.324,
        VC_05: 0.72779,
        QE_05: 115.974,
        VE_05: 0.68354,
        QA_06: 100.372,
        VA_06: 0.64968,
        QC_06: 125.727,
        VC_06: 0.69533,
        QE_06: 106.815,
        VE_06: 0.6675,
        QA_07: 68.213,
        VA_07: 0.58639,
        QC_07: 102.852,
        VC_07: 0.65619,
        QE_07: 83.716,
        VE_07: 0.62392,
        QA_08: 54.57,
        VA_08: 0.55533,
        QC_08: 122.639,
        VC_08: 0.69026,
        QE_08: 75.21,
        VE_08: 0.60647,
        QA_09: 42.051,
        VA_09: 0.52341,
        QC_09: 106.166,
        VC_09: 0.6621,
        QE_09: 94.809,
        VE_09: 0.64547,
        QA_10: 34.106,
        VA_10: 0.50079,
        QC_10: 92.225,
        VC_10: 0.63662,
        QE_10: 162.239,
        VE_10: 0.75674,
        QA_11: 31.559,
        VA_11: 0.49302,
        QC_11: 53.174,
        VC_11: 0.55341,
        QE_11: 119.514,
        VE_11: 0.68957,
        QA_12: 204.928,
        VA_12: 0.80696,
        QC_12: 167.637,
        VC_12: 0.7592,
        QE_12: 169.386,
        VE_12: 0.76712,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.920046118169125, 34.564888413015183, 0.0],
          [-80.919409384836854, 34.565163479681303, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286110.0,
        COMID: 9756896,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 5.276,
        REACHCODE: "03050101002786",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.048352475314728999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061998.0,
        ToNode: 250062000.0,
        Hydroseq: 250007917.0,
        LevelPathI: 250003258.0,
        Pathlength: 464.686,
        TerminalPa: 250002604.0,
        ArbolateSu: 4374.105,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007945.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007890.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 11.1582,
        TotDASqKM: 5402.8152,
        DivDASqKM: 5402.8152,
        Tidal: 0,
        TOTMA: 1.81178007906,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17058.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.2760000228881836,
        QA_MA: 2923.665,
        VA_MA: -9998.0,
        QC_MA: 2923.665,
        VC_MA: -9998.0,
        QE_MA: 3119.558,
        VE_MA: -9998.0,
        QA_01: 5001.122,
        VA_01: -9998.0,
        QC_01: 5001.122,
        VC_01: -9998.0,
        QE_01: 5183.82,
        VE_01: -9998.0,
        QA_02: 5311.542,
        VA_02: -9998.0,
        QC_02: 5311.542,
        VC_02: -9998.0,
        QE_02: 5354.725,
        VE_02: -9998.0,
        QA_03: 5926.379,
        VA_03: -9998.0,
        QC_03: 5926.379,
        VC_03: -9998.0,
        QE_03: 6151.484,
        VE_03: -9998.0,
        QA_04: 4355.615,
        VA_04: -9998.0,
        QC_04: 4355.615,
        VC_04: -9998.0,
        QE_04: 4532.422,
        VE_04: -9998.0,
        QA_05: 3147.601,
        VA_05: -9998.0,
        QC_05: 3147.601,
        VC_05: -9998.0,
        QE_05: 3374.324,
        VE_05: -9998.0,
        QA_06: 1843.937,
        VA_06: -9998.0,
        QC_06: 1843.937,
        VC_06: -9998.0,
        QE_06: 2128.743,
        VE_06: -9998.0,
        QA_07: 1110.157,
        VA_07: -9998.0,
        QC_07: 1110.157,
        VC_07: -9998.0,
        QE_07: 1281.014,
        VE_07: -9998.0,
        QA_08: 779.982,
        VA_08: -9998.0,
        QC_08: 779.982,
        VC_08: -9998.0,
        QE_08: 1107.785,
        VE_08: -9998.0,
        QA_09: 738.53,
        VA_09: -9998.0,
        QC_09: 1333.002,
        VC_09: -9998.0,
        QE_09: 1384.806,
        VE_09: -9998.0,
        QA_10: 868.829,
        VA_10: -9998.0,
        QC_10: 868.829,
        VC_10: -9998.0,
        QE_10: 843.473,
        VE_10: -9998.0,
        QA_11: 2316.173,
        VA_11: -9998.0,
        QC_11: 2316.173,
        VC_11: -9998.0,
        QE_11: 2271.57,
        VE_11: -9998.0,
        QA_12: 3703.965,
        VA_12: -9998.0,
        QC_12: 3703.965,
        VC_12: -9998.0,
        QE_12: 3699.865,
        VE_12: -9998.0,
        LakeFract: 0.11008836532000001,
        SurfArea: 4985334.9568999996,
        RAreaHLoad: 0.65224510614999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.003498118039602, 35.209404012014716, 0.0],
          [-81.005176718037092, 35.195775478702501, 0.0],
          [-81.000412184711081, 35.182405412056539, 0.0],
          [-81.000412718044458, 35.176433412065819, 0.0],
          [-81.001941584708732, 35.1703862120753, 0.0],
          [-81.005576184703159, 35.164745078750741, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287088.0,
        COMID: 9735866,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.086,
        REACHCODE: "03050103000356",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00091435992423999997,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059766.0,
        ToNode: 250059765.0,
        Hydroseq: 250023114.0,
        LevelPathI: 250019187.0,
        Pathlength: 373.436,
        TerminalPa: 250002604.0,
        ArbolateSu: 247.584,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250023656.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 250022616.0,
        DnDrainCou: 2,
        DnHydroseq: 250022617.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0144,
        TotDASqKM: 507.3273,
        DivDASqKM: 507.3273,
        Tidal: 0,
        TOTMA: 0.00185884213342,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9125.0,
        MAXELEVSMO: 9220.0,
        MINELEVSMO: 9137.0,
        SLOPE: 0.00965116,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.086000002920627996,
        QA_MA: 195.534,
        VA_MA: 1.75868,
        QC_MA: 192.964,
        VC_MA: 1.75712,
        QE_MA: 192.471,
        VE_MA: 1.75682,
        QA_01: 428.091,
        VA_01: 2.50706,
        QC_01: 331.218,
        VC_01: 2.23849,
        QE_01: 335.781,
        VE_01: 2.25481,
        QA_02: 456.742,
        VA_02: 2.58401,
        QC_02: 376.575,
        VC_02: 2.37461,
        QE_02: 374.148,
        VE_02: 2.3699,
        QA_03: 490.688,
        VA_03: 2.67231,
        QC_03: 407.229,
        VC_03: 2.46229,
        QE_03: 406.494,
        VE_03: 2.4627,
        QA_04: 280.943,
        VA_04: 2.06644,
        QC_04: 258.641,
        VC_04: 2.00095,
        QE_04: 255.857,
        VE_04: 1.99318,
        QA_05: 155.624,
        VA_05: 1.59311,
        QC_05: 146.322,
        VC_05: 1.55917,
        QE_05: 143.355,
        VE_05: 1.54706,
        QA_06: 100.37,
        VA_06: 1.3262,
        QC_06: 125.725,
        VC_06: 1.46236,
        QE_06: 123.876,
        VE_06: 1.45463,
        QA_07: 68.211,
        VA_07: 1.13746,
        QC_07: 102.85,
        VC_07: 1.34564,
        QE_07: 100.979,
        VE_07: 1.33676,
        QA_08: 54.569,
        VA_08: 1.04483,
        QC_08: 122.636,
        VC_08: 1.44722,
        QE_08: 117.999,
        VE_08: 1.42543,
        QA_09: 42.05,
        VA_09: 0.94965,
        QC_09: 106.164,
        VC_09: 1.36325,
        QE_09: 105.054,
        VE_09: 1.35859,
        QA_10: 34.106,
        VA_10: 0.88217,
        QC_10: 92.223,
        VC_10: 1.28727,
        QE_10: 99.068,
        VE_10: 1.32638,
        QA_11: 31.558,
        VA_11: 0.859,
        QC_11: 53.174,
        VC_11: 1.03911,
        QE_11: 59.659,
        VE_11: 1.08604,
        QA_12: 204.925,
        VA_12: 1.79526,
        QC_12: 167.635,
        VC_12: 1.65282,
        QE_12: 167.806,
        VE_12: 1.6551,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.920904318167914, 34.564590213015549, 0.0],
          [-80.920046118169125, 34.564888413015183, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287089.0,
        COMID: 9735868,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.464,
        REACHCODE: "03050103000357",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0048872115671990003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059767.0,
        ToNode: 250059766.0,
        Hydroseq: 250023656.0,
        LevelPathI: 250019187.0,
        Pathlength: 373.522,
        TerminalPa: 250002604.0,
        ArbolateSu: 230.621,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250024241.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023114.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2178,
        TotDASqKM: 492.7734,
        DivDASqKM: 492.7734,
        Tidal: 0,
        TOTMA: 0.0157610926012,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9150.0,
        MAXELEVSMO: 9232.0,
        MINELEVSMO: 9220.0,
        SLOPE: 0.00025862,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.46399998664856001,
        QA_MA: 190.609,
        VA_MA: 1.11887,
        QC_MA: 188.289,
        VC_MA: 1.11807,
        QE_MA: 187.81,
        VE_MA: 1.1179,
        QA_01: 417.397,
        VA_01: 1.53724,
        QC_01: 323.158,
        VC_01: 1.38703,
        QE_01: 327.591,
        VE_01: 1.39611,
        QA_02: 444.9,
        VA_02: 1.57959,
        QC_02: 367.075,
        VC_02: 1.46254,
        QE_02: 364.717,
        VE_02: 1.45992,
        QA_03: 477.677,
        VA_03: 1.62849,
        QC_03: 396.783,
        VC_03: 1.51124,
        QE_03: 396.07,
        VE_03: 1.51147,
        QA_04: 274.059,
        VA_04: 1.29123,
        QC_04: 252.446,
        VC_04: 1.25448,
        QE_04: 249.742,
        VE_04: 1.25016,
        QA_05: 151.766,
        VA_05: 1.0265,
        QC_05: 142.829,
        VC_05: 1.00757,
        QE_05: 139.947,
        VE_05: 1.00083,
        QA_06: 97.826,
        VA_06: 0.87713,
        QC_06: 122.582,
        VC_06: 0.95305,
        QE_06: 120.786,
        VE_06: 0.94875,
        QA_07: 66.254,
        VA_07: 0.77082,
        QC_07: 99.969,
        VC_07: 0.88689,
        QE_07: 98.152,
        VE_07: 0.88194,
        QA_08: 53.003,
        VA_08: 0.71915,
        QC_08: 119.048,
        VC_08: 0.94312,
        QE_08: 114.545,
        VE_08: 0.93096,
        QA_09: 40.876,
        VA_09: 0.66622,
        QC_09: 103.543,
        VC_09: 0.89778,
        QE_09: 102.464,
        VE_09: 0.89519,
        QA_10: 33.127,
        VA_10: 0.62843,
        QC_10: 90.103,
        VC_10: 0.85584,
        QE_10: 96.752,
        VE_10: 0.87758,
        QA_11: 30.775,
        VA_11: 0.61615,
        QC_11: 52.165,
        VC_11: 0.71794,
        QE_11: 58.464,
        VE_11: 0.744,
        QA_12: 201.087,
        VA_12: 1.14223,
        QC_12: 164.775,
        VC_12: 1.0627,
        QE_12: 164.942,
        VE_12: 1.06397,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.925335784827681, 34.566489679679307, 0.0],
          [-80.920904318167914, 34.564590213015549, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286104.0,
        COMID: 9756878,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.383,
        REACHCODE: "03050101002780",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012972622535793,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061989.0,
        ToNode: 250061990.0,
        Hydroseq: 250008024.0,
        LevelPathI: 250003258.0,
        Pathlength: 474.329,
        TerminalPa: 250002604.0,
        ArbolateSu: 4339.418,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008050.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007996.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 10.3365,
        TotDASqKM: 5330.8539,
        DivDASqKM: 5330.8539,
        Tidal: 0,
        TOTMA: 0.47492264013000002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17194.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3830000162124629,
        QA_MA: 2897.854,
        VA_MA: -9998.0,
        QC_MA: 2897.854,
        VC_MA: -9998.0,
        QE_MA: 3093.712,
        VE_MA: -9998.0,
        QA_01: 4947.062,
        VA_01: -9998.0,
        QC_01: 4947.062,
        VC_01: -9998.0,
        QE_01: 5129.997,
        VE_01: -9998.0,
        QA_02: 5251.258,
        VA_02: -9998.0,
        QC_02: 5251.258,
        VC_02: -9998.0,
        QE_02: 5294.719,
        VE_02: -9998.0,
        QA_03: 5860.866,
        VA_03: -9998.0,
        QC_03: 5860.866,
        VC_03: -9998.0,
        QE_03: 6086.367,
        VE_03: -9998.0,
        QA_04: 4317.936,
        VA_04: -9998.0,
        QC_04: 4317.936,
        VC_04: -9998.0,
        QE_04: 4494.86,
        VE_04: -9998.0,
        QA_05: 3126.072,
        VA_05: -9998.0,
        QC_05: 3126.072,
        VC_05: -9998.0,
        QE_05: 3352.762,
        VE_05: -9998.0,
        QA_06: 1830.881,
        VA_06: -9998.0,
        QC_06: 1830.881,
        VC_06: -9998.0,
        QE_06: 2115.41,
        VE_06: -9998.0,
        QA_07: 1101.384,
        VA_07: -9998.0,
        QC_07: 1101.384,
        VC_07: -9998.0,
        QE_07: 1271.972,
        VE_07: -9998.0,
        QA_08: 773.209,
        VA_08: -9998.0,
        QC_08: 773.209,
        VC_08: -9998.0,
        QE_08: 1100.679,
        VE_08: -9998.0,
        QA_09: 732.725,
        VA_09: -9998.0,
        QC_09: 1323.747,
        VC_09: -9998.0,
        QE_09: 1375.289,
        VE_09: -9998.0,
        QA_10: 863.991,
        VA_10: -9998.0,
        QC_10: 863.991,
        VC_10: -9998.0,
        QE_10: 838.258,
        VE_10: -9998.0,
        QA_11: 2311.335,
        VA_11: -9998.0,
        QC_11: 2311.335,
        VC_11: -9998.0,
        QE_11: 2266.632,
        VE_11: -9998.0,
        QA_12: 3677.253,
        VA_12: -9998.0,
        QC_12: 3677.253,
        VC_12: -9998.0,
        QE_12: 3673.296,
        VE_12: -9998.0,
        LakeFract: 0.02861875662,
        SurfArea: 1295996.06085,
        RAreaHLoad: 0.17097327176,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.011866118026674, 35.253361211946469, 0.0],
          [-81.008392184698721, 35.248576811953797, 0.0],
          [-81.010867918028168, 35.242327811963662, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287090.0,
        COMID: 9735854,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1250440",
        GNIS_NAME: "Rocky Creek",
        LENGTHKM: 0.512,
        REACHCODE: "03050103000358",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.005312732024235,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250059761.0,
        ToNode: 250059767.0,
        Hydroseq: 250024241.0,
        LevelPathI: 250019187.0,
        Pathlength: 373.986,
        TerminalPa: 250002604.0,
        ArbolateSu: 227.558,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250024864.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023656.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1827,
        TotDASqKM: 490.068,
        DivDASqKM: 490.068,
        Tidal: 0,
        TOTMA: 0.0160875919424,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9173.0,
        MAXELEVSMO: 9258.0,
        MINELEVSMO: 9232.0,
        SLOPE: 0.00050781,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.51200002431869496,
        QA_MA: 189.713,
        VA_MA: 1.20958,
        QC_MA: 187.439,
        VC_MA: 1.2087,
        QE_MA: 186.962,
        VE_MA: 1.20851,
        QA_01: 415.459,
        VA_01: 1.6748,
        QC_01: 321.697,
        VC_01: 1.50777,
        QE_01: 326.105,
        VE_01: 1.51785,
        QA_02: 442.743,
        VA_02: 1.72174,
        QC_02: 365.344,
        VC_02: 1.59159,
        QE_02: 362.999,
        VE_02: 1.58868,
        QA_03: 475.286,
        VA_03: 1.77599,
        QC_03: 394.862,
        VC_03: 1.64564,
        QE_03: 394.153,
        VE_03: 1.6459,
        QA_04: 272.82,
        VA_04: 1.40133,
        QC_04: 251.331,
        VC_04: 1.36043,
        QE_04: 248.641,
        VE_04: 1.35563,
        QA_05: 151.075,
        VA_05: 1.10693,
        QC_05: 142.203,
        VC_05: 1.08588,
        QE_05: 139.337,
        VE_05: 1.0784,
        QA_06: 97.353,
        VA_06: 0.94074,
        QC_06: 121.997,
        VC_06: 1.02512,
        QE_06: 120.211,
        VE_06: 1.02034,
        QA_07: 65.891,
        VA_07: 0.82236,
        QC_07: 99.433,
        VC_07: 0.95136,
        QE_07: 97.626,
        VE_07: 0.94585,
        QA_08: 52.712,
        VA_08: 0.76493,
        QC_08: 118.382,
        VC_08: 1.01376,
        QE_08: 113.903,
        VE_08: 1.00025,
        QA_09: 40.657,
        VA_09: 0.70612,
        QC_09: 103.055,
        VC_09: 0.96369,
        QE_09: 101.982,
        VE_09: 0.9608,
        QA_10: 32.945,
        VA_10: 0.66409,
        QC_10: 89.708,
        VC_10: 0.91716,
        QE_10: 96.32,
        VE_10: 0.94131,
        QA_11: 30.63,
        VA_11: 0.6506,
        QC_11: 51.977,
        VC_11: 0.764,
        QE_11: 58.242,
        VE_11: 0.79294,
        QA_12: 200.414,
        VA_12: 1.23622,
        QC_12: 164.274,
        VC_12: 1.14779,
        QE_12: 164.439,
        VE_12: 1.1492,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.929933984820536, 34.568961479675465, 0.0],
          [-80.925335784827681, 34.566489679679307, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286105.0,
        COMID: 9756880,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.405,
        REACHCODE: "03050101002781",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.031516236354485999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061990.0,
        ToNode: 250061991.0,
        Hydroseq: 250007996.0,
        LevelPathI: 250003258.0,
        Pathlength: 470.924,
        TerminalPa: 250002604.0,
        ArbolateSu: 4342.823,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008024.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007970.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 11.5434,
        TotDASqKM: 5342.3973,
        DivDASqKM: 5342.3973,
        Tidal: 0,
        TOTMA: 1.1692780836400001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17190.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.404999971389771,
        QA_MA: 2902.055,
        VA_MA: -9998.0,
        QC_MA: 2902.055,
        VC_MA: -9998.0,
        QE_MA: 3097.918,
        VE_MA: -9998.0,
        QA_01: 4955.941,
        VA_01: -9998.0,
        QC_01: 4955.941,
        VC_01: -9998.0,
        QE_01: 5138.837,
        VE_01: -9998.0,
        QA_02: 5261.047,
        VA_02: -9998.0,
        QC_02: 5261.047,
        VC_02: -9998.0,
        QE_02: 5304.463,
        VE_02: -9998.0,
        QA_03: 5871.441,
        VA_03: -9998.0,
        QC_03: 5871.441,
        VC_03: -9998.0,
        QE_03: 6096.878,
        VE_03: -9998.0,
        QA_04: 4324.014,
        VA_04: -9998.0,
        QC_04: 4324.014,
        VC_04: -9998.0,
        QE_04: 4500.919,
        VE_04: -9998.0,
        QA_05: 3129.548,
        VA_05: -9998.0,
        QC_05: 3129.548,
        VC_05: -9998.0,
        QE_05: 3356.243,
        VE_05: -9998.0,
        QA_06: 1833.003,
        VA_06: -9998.0,
        QC_06: 1833.003,
        VC_06: -9998.0,
        QE_06: 2117.577,
        VE_06: -9998.0,
        QA_07: 1102.781,
        VA_07: -9998.0,
        QC_07: 1102.781,
        VC_07: -9998.0,
        QE_07: 1273.412,
        VE_07: -9998.0,
        QA_08: 774.296,
        VA_08: -9998.0,
        QC_08: 774.296,
        VC_08: -9998.0,
        QE_08: 1101.819,
        VE_08: -9998.0,
        QA_09: 733.656,
        VA_09: -9998.0,
        QC_09: 1325.232,
        VC_09: -9998.0,
        QE_09: 1376.816,
        VE_09: -9998.0,
        QA_10: 864.767,
        VA_10: -9998.0,
        QC_10: 864.767,
        VC_10: -9998.0,
        QE_10: 839.095,
        VE_10: -9998.0,
        QA_11: 2312.111,
        VA_11: -9998.0,
        QC_11: 2312.111,
        VC_11: -9998.0,
        QE_11: 2267.424,
        VE_11: -9998.0,
        QA_12: 3681.789,
        VA_12: -9998.0,
        QC_12: 3681.789,
        VC_12: -9998.0,
        QE_12: 3677.809,
        VE_12: -9998.0,
        LakeFract: 0.07055617205,
        SurfArea: 3195125.5695699998,
        RAreaHLoad: 0.42094287081999998,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.010867918028168, 35.242327811963662, 0.0],
          [-81.015732584687385, 35.220745611997074, 0.0],
          [-81.010909184694867, 35.214426678673476, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286106.0,
        COMID: 9756882,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.137,
        REACHCODE: "03050101002782",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001503363543689,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061991.0,
        ToNode: 250061994.0,
        Hydroseq: 250007970.0,
        LevelPathI: 250003258.0,
        Pathlength: 470.787,
        TerminalPa: 250002604.0,
        ArbolateSu: 4342.96,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007996.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007945.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0621,
        TotDASqKM: 5342.4594,
        DivDASqKM: 5342.4594,
        Tidal: 0,
        TOTMA: 0.04704584365,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17193.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13699999451637301,
        QA_MA: 2902.077,
        VA_MA: -9998.0,
        QC_MA: 2902.077,
        VC_MA: -9998.0,
        QE_MA: 3097.941,
        VE_MA: -9998.0,
        QA_01: 4955.987,
        VA_01: -9998.0,
        QC_01: 4955.987,
        VC_01: -9998.0,
        QE_01: 5138.884,
        VE_01: -9998.0,
        QA_02: 5261.098,
        VA_02: -9998.0,
        QC_02: 5261.098,
        VC_02: -9998.0,
        QE_02: 5304.515,
        VE_02: -9998.0,
        QA_03: 5871.496,
        VA_03: -9998.0,
        QC_03: 5871.496,
        VC_03: -9998.0,
        QE_03: 6096.934,
        VE_03: -9998.0,
        QA_04: 4324.046,
        VA_04: -9998.0,
        QC_04: 4324.046,
        VC_04: -9998.0,
        QE_04: 4500.95,
        VE_04: -9998.0,
        QA_05: 3129.567,
        VA_05: -9998.0,
        QC_05: 3129.567,
        VC_05: -9998.0,
        QE_05: 3356.261,
        VE_05: -9998.0,
        QA_06: 1833.014,
        VA_06: -9998.0,
        QC_06: 1833.014,
        VC_06: -9998.0,
        QE_06: 2117.588,
        VE_06: -9998.0,
        QA_07: 1102.789,
        VA_07: -9998.0,
        QC_07: 1102.789,
        VC_07: -9998.0,
        QE_07: 1273.42,
        VE_07: -9998.0,
        QA_08: 774.301,
        VA_08: -9998.0,
        QC_08: 774.301,
        VC_08: -9998.0,
        QE_08: 1101.825,
        VE_08: -9998.0,
        QA_09: 733.661,
        VA_09: -9998.0,
        QC_09: 1325.24,
        VC_09: -9998.0,
        QE_09: 1376.825,
        VE_09: -9998.0,
        QA_10: 864.772,
        VA_10: -9998.0,
        QC_10: 864.772,
        VC_10: -9998.0,
        QE_10: 839.099,
        VE_10: -9998.0,
        QA_11: 2312.115,
        VA_11: -9998.0,
        QC_11: 2312.115,
        VC_11: -9998.0,
        QE_11: 2267.428,
        VE_11: -9998.0,
        QA_12: 3681.812,
        VA_12: -9998.0,
        QC_12: 3681.812,
        VC_12: -9998.0,
        QE_12: 3677.832,
        VE_12: -9998.0,
        LakeFract: 0.00283884409,
        SurfArea: 128556.624834,
        RAreaHLoad: 0.01693661479,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.010909184694867, 35.214426678673476, 0.0],
          [-81.009428384697173, 35.214326012007007, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286115.0,
        COMID: 9756906,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.087,
        REACHCODE: "03050101002791",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00090244716541999998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062003.0,
        ToNode: 250062004.0,
        Hydroseq: 250007798.0,
        LevelPathI: 250003258.0,
        Pathlength: 463.511,
        TerminalPa: 250002604.0,
        ArbolateSu: 4381.075,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007827.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007770.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0378,
        TotDASqKM: 5417.7003,
        DivDASqKM: 5417.7003,
        Tidal: 0,
        TOTMA: 0.02987582761,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17187.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.086999997496605003,
        QA_MA: 2929.097,
        VA_MA: -9998.0,
        QC_MA: 2929.097,
        VC_MA: -9998.0,
        QE_MA: 3124.997,
        VE_MA: -9998.0,
        QA_01: 5012.574,
        VA_01: -9998.0,
        QC_01: 5012.574,
        VC_01: -9998.0,
        QE_01: 5195.222,
        VE_01: -9998.0,
        QA_02: 5324.177,
        VA_02: -9998.0,
        QC_02: 5324.177,
        VC_02: -9998.0,
        QE_02: 5367.302,
        VE_02: -9998.0,
        QA_03: 5940.116,
        VA_03: -9998.0,
        QC_03: 5940.116,
        VC_03: -9998.0,
        QE_03: 6165.139,
        VE_03: -9998.0,
        QA_04: 4363.538,
        VA_04: -9998.0,
        QC_04: 4363.538,
        VC_04: -9998.0,
        QE_04: 4540.321,
        VE_04: -9998.0,
        QA_05: 3152.095,
        VA_05: -9998.0,
        QC_05: 3152.095,
        VC_05: -9998.0,
        QE_05: 3378.824,
        VE_05: -9998.0,
        QA_06: 1846.685,
        VA_06: -9998.0,
        QC_06: 1846.685,
        VC_06: -9998.0,
        QE_06: 2131.549,
        VE_06: -9998.0,
        QA_07: 1112.059,
        VA_07: -9998.0,
        QC_07: 1112.059,
        VC_07: -9998.0,
        QE_07: 1282.972,
        VE_07: -9998.0,
        QA_08: 781.383,
        VA_08: -9998.0,
        QC_08: 781.383,
        VC_08: -9998.0,
        QE_08: 1109.255,
        VE_08: -9998.0,
        QA_09: 739.731,
        VA_09: -9998.0,
        QC_09: 1334.916,
        VC_09: -9998.0,
        QE_09: 1386.773,
        VE_09: -9998.0,
        QA_10: 869.83,
        VA_10: -9998.0,
        QC_10: 869.83,
        VC_10: -9998.0,
        QE_10: 844.551,
        VE_10: -9998.0,
        QA_11: 2317.173,
        VA_11: -9998.0,
        QC_11: 2317.173,
        VC_11: -9998.0,
        QE_11: 2272.591,
        VE_11: -9998.0,
        QA_12: 3709.698,
        VA_12: -9998.0,
        QC_12: 3709.698,
        VC_12: -9998.0,
        QE_12: 3705.569,
        VE_12: -9998.0,
        LakeFract: 0.00181849173,
        SurfArea: 82350.122685900002,
        RAreaHLoad: 0.01075536848,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.008786518031457, 35.155935878764353, 0.0],
          [-81.009552184696986, 35.155458212098495, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286116.0,
        COMID: 9756908,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.202,
        REACHCODE: "03050101002792",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0020875675488549999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062004.0,
        ToNode: 250062005.0,
        Hydroseq: 250007770.0,
        LevelPathI: 250003258.0,
        Pathlength: 463.309,
        TerminalPa: 250002604.0,
        ArbolateSu: 4381.277,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007798.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007739.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2277,
        TotDASqKM: 5417.928,
        DivDASqKM: 5417.928,
        Tidal: 0,
        TOTMA: 0.06936686421,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17187.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.202000007033348,
        QA_MA: 2929.183,
        VA_MA: -9998.0,
        QC_MA: 2929.183,
        VC_MA: -9998.0,
        QE_MA: 3125.084,
        VE_MA: -9998.0,
        QA_01: 5012.757,
        VA_01: -9998.0,
        QC_01: 5012.757,
        VC_01: -9998.0,
        QE_01: 5195.405,
        VE_01: -9998.0,
        QA_02: 5324.376,
        VA_02: -9998.0,
        QC_02: 5324.376,
        VC_02: -9998.0,
        QE_02: 5367.5,
        VE_02: -9998.0,
        QA_03: 5940.33,
        VA_03: -9998.0,
        QC_03: 5940.33,
        VC_03: -9998.0,
        QE_03: 6165.352,
        VE_03: -9998.0,
        QA_04: 4363.664,
        VA_04: -9998.0,
        QC_04: 4363.664,
        VC_04: -9998.0,
        QE_04: 4540.446,
        VE_04: -9998.0,
        QA_05: 3152.165,
        VA_05: -9998.0,
        QC_05: 3152.165,
        VC_05: -9998.0,
        QE_05: 3378.894,
        VE_05: -9998.0,
        QA_06: 1846.728,
        VA_06: -9998.0,
        QC_06: 1846.728,
        VC_06: -9998.0,
        QE_06: 2131.593,
        VE_06: -9998.0,
        QA_07: 1112.09,
        VA_07: -9998.0,
        QC_07: 1112.09,
        VC_07: -9998.0,
        QE_07: 1283.004,
        VE_07: -9998.0,
        QA_08: 781.404,
        VA_08: -9998.0,
        QC_08: 781.404,
        VC_08: -9998.0,
        QE_08: 1109.277,
        VE_08: -9998.0,
        QA_09: 739.749,
        VA_09: -9998.0,
        QC_09: 1334.945,
        VC_09: -9998.0,
        QE_09: 1386.803,
        VE_09: -9998.0,
        QA_10: 869.845,
        VA_10: -9998.0,
        QC_10: 869.845,
        VC_10: -9998.0,
        QE_10: 844.568,
        VE_10: -9998.0,
        QA_11: 2317.189,
        VA_11: -9998.0,
        QC_11: 2317.189,
        VC_11: -9998.0,
        QE_11: 2272.607,
        VE_11: -9998.0,
        QA_12: 3709.797,
        VA_12: -9998.0,
        QC_12: 3709.797,
        VC_12: -9998.0,
        QE_12: 3705.667,
        VE_12: -9998.0,
        LakeFract: 0.0042223612,
        SurfArea: 191208.98770600001,
        RAreaHLoad: 0.02497223489,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.009552184696986, 35.155458212098495, 0.0],
          [-81.011323318027564, 35.154353212100148, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284051.0,
        COMID: 9753366,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015703",
        GNIS_NAME: "South Muddy Creek",
        LENGTHKM: 4.311,
        REACHCODE: "03050101000465",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.040338870983066002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061298.0,
        ToNode: 250061258.0,
        Hydroseq: 250031105.0,
        LevelPathI: 250029941.0,
        Pathlength: 662.035,
        TerminalPa: 250002604.0,
        ArbolateSu: 80.085,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029941.0,
        UpHydroseq: 250032405.0,
        DnLevelPat: 250029941.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029941.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.2243,
        TotDASqKM: 100.4238,
        DivDASqKM: 100.4238,
        Tidal: 0,
        TOTMA: 0.15320852136499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32810.0,
        MAXELEVSMO: 33467.0,
        MINELEVSMO: 32898.0,
        SLOPE: 0.00131987,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.310999870300293,
        QA_MA: 63.366,
        VA_MA: 1.06661,
        QC_MA: 65.328,
        VC_MA: 1.06848,
        QE_MA: 65.328,
        VE_MA: 1.06848,
        QA_01: 105.565,
        VA_01: 1.30264,
        QC_01: 84.729,
        VC_01: 1.18103,
        QE_01: 84.729,
        VE_01: 1.18103,
        QA_02: 110.586,
        VA_02: 1.32748,
        QC_02: 94.77,
        VC_02: 1.2345,
        QE_02: 94.77,
        VE_02: 1.2345,
        QA_03: 121.3,
        VA_03: 1.37877,
        QC_03: 105.421,
        VC_03: 1.28838,
        QE_03: 105.421,
        VE_03: 1.28838,
        QA_04: 91.094,
        VA_04: 1.22778,
        QC_04: 86.037,
        VC_04: 1.18816,
        QE_04: 86.037,
        VE_04: 1.18816,
        QA_05: 70.385,
        VA_05: 1.11011,
        QC_05: 68.172,
        VC_05: 1.08588,
        QE_05: 68.172,
        VE_05: 1.08588,
        QA_06: 39.015,
        VA_06: 0.89446,
        QC_06: 49.509,
        VC_06: 0.96442,
        QE_06: 49.509,
        VE_06: 0.96442,
        QA_07: 22.84,
        VA_07: 0.74943,
        QC_07: 35.352,
        VC_07: 0.85699,
        QE_07: 35.352,
        VE_07: 0.85699,
        QA_08: 15.457,
        VA_08: 0.66684,
        QC_08: 33.872,
        VC_08: 0.84467,
        QE_08: 33.872,
        VE_08: 0.84467,
        QA_09: 11.512,
        VA_09: 0.61492,
        QC_09: 33.827,
        VC_09: 0.84429,
        QE_09: 33.827,
        VE_09: 0.84429,
        QA_10: 23.459,
        VA_10: 0.75573,
        QC_10: 68.394,
        VC_10: 1.08722,
        QE_10: 68.394,
        VE_10: 1.08722,
        QA_11: 64.897,
        VA_11: 1.07628,
        QC_11: 92.148,
        VC_11: 1.2208,
        QE_11: 92.148,
        VE_11: 1.2208,
        QA_12: 84.811,
        VA_12: 1.19354,
        QC_12: 75.123,
        VC_12: 1.12701,
        QE_12: 75.123,
        VE_12: 1.12701,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.855744516716868, 35.65894687798346, 0.0],
          [-81.855770716716791, 35.66888587796808, 0.0],
          [-81.858772983378742, 35.672619077962395, 0.0],
          [-81.857172583381271, 35.676374611289937, 0.0],
          [-81.858070316713111, 35.678687677952951, 0.0],
          [-81.855683783383483, 35.683290477945832, 0.0],
          [-81.859528783377527, 35.686748877940431, 0.0],
          [-81.862503583372927, 35.693275811263675, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286117.0,
        COMID: 9756910,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.278,
        REACHCODE: "03050101002793",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0030280792945509998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062005.0,
        ToNode: 250062006.0,
        Hydroseq: 250007739.0,
        LevelPathI: 250003258.0,
        Pathlength: 463.031,
        TerminalPa: 250002604.0,
        ArbolateSu: 4381.555,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007770.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007708.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 12.9825,
        TotDASqKM: 5430.9105,
        DivDASqKM: 5430.9105,
        Tidal: 0,
        TOTMA: 0.09546528853,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17120.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.277999997138977,
        QA_MA: 2934.198,
        VA_MA: -9998.0,
        QC_MA: 2934.198,
        VC_MA: -9998.0,
        QE_MA: 3130.105,
        VE_MA: -9998.0,
        QA_01: 5023.399,
        VA_01: -9998.0,
        QC_01: 5023.399,
        VC_01: -9998.0,
        QE_01: 5206.004,
        VE_01: -9998.0,
        QA_02: 5335.873,
        VA_02: -9998.0,
        QC_02: 5335.873,
        VC_02: -9998.0,
        QE_02: 5378.947,
        VE_02: -9998.0,
        QA_03: 5952.757,
        VA_03: -9998.0,
        QC_03: 5952.757,
        VC_03: -9998.0,
        QE_03: 6177.707,
        VE_03: -9998.0,
        QA_04: 4370.97,
        VA_04: -9998.0,
        QC_04: 4370.97,
        VC_04: -9998.0,
        QE_04: 4547.732,
        VE_04: -9998.0,
        QA_05: 3156.278,
        VA_05: -9998.0,
        QC_05: 3156.278,
        VC_05: -9998.0,
        QE_05: 3383.013,
        VE_05: -9998.0,
        QA_06: 1849.236,
        VA_06: -9998.0,
        QC_06: 1849.236,
        VC_06: -9998.0,
        QE_06: 2134.151,
        VE_06: -9998.0,
        QA_07: 1113.836,
        VA_07: -9998.0,
        QC_07: 1113.836,
        VC_07: -9998.0,
        QE_07: 1284.798,
        VE_07: -9998.0,
        QA_08: 782.653,
        VA_08: -9998.0,
        QC_08: 782.653,
        VC_08: -9998.0,
        QE_08: 1110.586,
        VE_08: -9998.0,
        QA_09: 740.849,
        VA_09: -9998.0,
        QC_09: 1336.698,
        VC_09: -9998.0,
        QE_09: 1388.603,
        VE_09: -9998.0,
        QA_10: 870.718,
        VA_10: -9998.0,
        QC_10: 870.718,
        VC_10: -9998.0,
        QE_10: 845.508,
        VE_10: -9998.0,
        QA_11: 2318.104,
        VA_11: -9998.0,
        QC_11: 2318.104,
        VC_11: -9998.0,
        QE_11: 2273.54,
        VE_11: -9998.0,
        QA_12: 3715.645,
        VA_12: -9998.0,
        QC_12: 3715.645,
        VC_12: -9998.0,
        QE_12: 3711.489,
        VE_12: -9998.0,
        LakeFract: 0.00582029518,
        SurfArea: 263571.18607400003,
        RAreaHLoad: 0.03436772927,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.011323318027564, 35.154353212100148, 0.0],
          [-81.014243518023022, 35.154084478767174, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284052.0,
        COMID: 9753380,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015703",
        GNIS_NAME: "South Muddy Creek",
        LENGTHKM: 0.447,
        REACHCODE: "03050101000466",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0040737514107470002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061303.0,
        ToNode: 250061298.0,
        Hydroseq: 250032405.0,
        LevelPathI: 250029941.0,
        Pathlength: 666.346,
        TerminalPa: 250002604.0,
        ArbolateSu: 73.79,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029941.0,
        UpHydroseq: 250033875.0,
        DnLevelPat: 250029941.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031105.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.45,
        TotDASqKM: 92.3571,
        DivDASqKM: 92.3571,
        Tidal: 0,
        TOTMA: 0.0163956785349,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33397.0,
        MAXELEVSMO: 33517.0,
        MINELEVSMO: 33467.0,
        SLOPE: 0.00111856,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.44699999690055803,
        QA_MA: 58.725,
        VA_MA: 1.0333,
        QC_MA: 60.722,
        VC_MA: 1.03526,
        QE_MA: 60.722,
        VE_MA: 1.03526,
        QA_01: 97.525,
        VA_01: 1.25737,
        QC_01: 78.439,
        VC_01: 1.14111,
        QE_01: 78.439,
        VE_01: 1.14111,
        QA_02: 102.085,
        VA_02: 1.28068,
        QC_02: 87.676,
        VC_02: 1.19182,
        QE_02: 87.676,
        VE_02: 1.19182,
        QA_03: 112.098,
        VA_03: 1.33021,
        QC_03: 97.678,
        VC_03: 1.24397,
        QE_03: 97.678,
        VE_03: 1.24397,
        QA_04: 84.207,
        VA_04: 1.18619,
        QC_04: 79.675,
        VC_04: 1.14805,
        QE_04: 79.675,
        VE_04: 1.14805,
        QA_05: 65.21,
        VA_05: 1.07476,
        QC_05: 63.34,
        VC_05: 1.05174,
        QE_05: 63.34,
        VE_05: 1.05174,
        QA_06: 36.137,
        VA_06: 0.86854,
        QC_06: 45.905,
        VC_06: 0.93493,
        QE_06: 45.905,
        VE_06: 0.93493,
        QA_07: 21.151,
        VA_07: 0.72988,
        QC_07: 32.798,
        VC_07: 0.83249,
        QE_07: 32.798,
        VE_07: 0.83249,
        QA_08: 14.264,
        VA_08: 0.65032,
        QC_08: 31.207,
        VC_08: 0.81883,
        QE_08: 31.207,
        VE_08: 0.81883,
        QA_09: 10.645,
        VA_09: 0.60111,
        QC_09: 31.566,
        VC_09: 0.82194,
        QE_09: 31.566,
        VE_09: 0.82194,
        QA_10: 22.639,
        VA_10: 0.74537,
        QC_10: 66.478,
        VC_10: 1.07107,
        QE_10: 66.478,
        VE_10: 1.07107,
        QA_11: 60.578,
        VA_11: 1.04535,
        QC_11: 87.433,
        VC_11: 1.19052,
        QE_11: 87.433,
        VE_11: 1.19052,
        QA_12: 78.625,
        VA_12: 1.1548,
        QC_12: 70.12,
        VC_12: 1.09298,
        QE_12: 70.12,
        VE_12: 1.09298,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.854762983385001, 35.65514521132269, 0.0],
          [-81.855744516716868, 35.65894687798346, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286118.0,
        COMID: 9756912,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.127,
        REACHCODE: "03050101002794",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001331769110321,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062006.0,
        ToNode: 250062010.0,
        Hydroseq: 250007708.0,
        LevelPathI: 250003258.0,
        Pathlength: 462.904,
        TerminalPa: 250002604.0,
        ArbolateSu: 4381.682,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007739.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007675.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3024,
        TotDASqKM: 5431.2129,
        DivDASqKM: 5431.2129,
        Tidal: 0,
        TOTMA: 0.04361184041,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17188.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.12700000405311601,
        QA_MA: 2934.314,
        VA_MA: -9998.0,
        QC_MA: 2934.314,
        VC_MA: -9998.0,
        QE_MA: 3130.221,
        VE_MA: -9998.0,
        QA_01: 5023.645,
        VA_01: -9998.0,
        QC_01: 5023.645,
        VC_01: -9998.0,
        QE_01: 5206.249,
        VE_01: -9998.0,
        QA_02: 5336.139,
        VA_02: -9998.0,
        QC_02: 5336.139,
        VC_02: -9998.0,
        QE_02: 5379.212,
        VE_02: -9998.0,
        QA_03: 5953.043,
        VA_03: -9998.0,
        QC_03: 5953.043,
        VC_03: -9998.0,
        QE_03: 6177.992,
        VE_03: -9998.0,
        QA_04: 4371.139,
        VA_04: -9998.0,
        QC_04: 4371.139,
        VC_04: -9998.0,
        QE_04: 4547.9,
        VE_04: -9998.0,
        QA_05: 3156.373,
        VA_05: -9998.0,
        QC_05: 3156.373,
        VC_05: -9998.0,
        QE_05: 3383.108,
        VE_05: -9998.0,
        QA_06: 1849.293,
        VA_06: -9998.0,
        QC_06: 1849.293,
        VC_06: -9998.0,
        QE_06: 2134.209,
        VE_06: -9998.0,
        QA_07: 1113.876,
        VA_07: -9998.0,
        QC_07: 1113.876,
        VC_07: -9998.0,
        QE_07: 1284.84,
        VE_07: -9998.0,
        QA_08: 782.681,
        VA_08: -9998.0,
        QC_08: 782.681,
        VC_08: -9998.0,
        QE_08: 1110.616,
        VE_08: -9998.0,
        QA_09: 740.874,
        VA_09: -9998.0,
        QC_09: 1336.737,
        VC_09: -9998.0,
        QE_09: 1388.643,
        VE_09: -9998.0,
        QA_10: 870.738,
        VA_10: -9998.0,
        QC_10: 870.738,
        VC_10: -9998.0,
        QE_10: 845.53,
        VE_10: -9998.0,
        QA_11: 2318.125,
        VA_11: -9998.0,
        QC_11: 2318.125,
        VC_11: -9998.0,
        QE_11: 2273.561,
        VE_11: -9998.0,
        QA_12: 3715.782,
        VA_12: -9998.0,
        QC_12: 3715.782,
        VC_12: -9998.0,
        QE_12: 3711.626,
        VE_12: -9998.0,
        LakeFract: 0.00265901022,
        SurfArea: 120412.87525700001,
        RAreaHLoad: 0.01570036552,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.014243518023022, 35.154084478767174, 0.0],
          [-81.015416384687853, 35.1534536121016, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284053.0,
        COMID: 9753412,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015703",
        GNIS_NAME: "South Muddy Creek",
        LENGTHKM: 0.674,
        REACHCODE: "03050101000467",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0060339598376550002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061310.0,
        ToNode: 250061303.0,
        Hydroseq: 250033875.0,
        LevelPathI: 250029941.0,
        Pathlength: 666.793,
        TerminalPa: 250002604.0,
        ArbolateSu: 70.122,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029941.0,
        UpHydroseq: 250035534.0,
        DnLevelPat: 250029941.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032405.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.53,
        TotDASqKM: 88.4106,
        DivDASqKM: 88.4106,
        Tidal: 0,
        TOTMA: 0.022791186468200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33445.0,
        MAXELEVSMO: 33681.0,
        MINELEVSMO: 33517.0,
        SLOPE: 0.00243323,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.674000024795532,
        QA_MA: 56.381,
        VA_MA: 1.12065,
        QC_MA: 58.391,
        VC_MA: 1.12296,
        QE_MA: 58.391,
        VE_MA: 1.12296,
        QA_01: 93.523,
        VA_01: 1.37106,
        QC_01: 75.303,
        VC_01: 1.24075,
        QE_01: 75.303,
        VE_01: 1.24075,
        QA_02: 97.88,
        VA_02: 1.39707,
        QC_02: 84.161,
        VC_02: 1.29747,
        QE_02: 84.161,
        VE_02: 1.29747,
        QA_03: 107.513,
        VA_03: 1.45271,
        QC_03: 93.812,
        VC_03: 1.35616,
        QE_03: 93.812,
        VE_03: 1.35616,
        QA_04: 80.765,
        VA_04: 1.29144,
        QC_04: 76.491,
        VC_04: 1.24853,
        QE_04: 76.491,
        VE_04: 1.24853,
        QA_05: 62.581,
        VA_05: 1.16692,
        QC_05: 60.88,
        VC_05: 1.14121,
        QE_05: 60.88,
        VE_05: 1.14121,
        QA_06: 34.682,
        VA_06: 0.93592,
        QC_06: 44.082,
        VC_06: 1.01001,
        QE_06: 44.082,
        VE_06: 1.01001,
        QA_07: 20.298,
        VA_07: 0.78056,
        QC_07: 31.507,
        VC_07: 0.8954,
        QE_07: 31.507,
        VE_07: 0.8954,
        QA_08: 13.681,
        VA_08: 0.69132,
        QC_08: 29.905,
        VC_08: 0.87936,
        QE_08: 29.905,
        VE_08: 0.87936,
        QA_09: 10.22,
        VA_09: 0.63638,
        QC_09: 30.452,
        VC_09: 0.88488,
        QE_09: 30.452,
        VE_09: 0.88488,
        QA_10: 22.089,
        VA_10: 0.80224,
        QC_10: 65.184,
        VC_10: 1.17198,
        QE_10: 65.184,
        VE_10: 1.17198,
        QA_11: 58.295,
        VA_11: 1.13517,
        QC_11: 84.908,
        VC_11: 1.30212,
        QE_11: 84.908,
        VE_11: 1.30212,
        QA_12: 75.488,
        VA_12: 1.2568,
        QC_12: 67.57,
        VC_12: 1.18862,
        QE_12: 67.57,
        VE_12: 1.18862,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.855128916717717, 35.649145277998855, 0.0],
          [-81.854762983385001, 35.65514521132269, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284054.0,
        COMID: 9753442,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015703",
        GNIS_NAME: "South Muddy Creek",
        LENGTHKM: 1.055,
        REACHCODE: "03050101000468",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0099100952641880003,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061316.0,
        ToNode: 250061310.0,
        Hydroseq: 250035534.0,
        LevelPathI: 250029941.0,
        Pathlength: 667.467,
        TerminalPa: 250002604.0,
        ArbolateSu: 63.944,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029941.0,
        UpHydroseq: 250037444.0,
        DnLevelPat: 250029941.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033875.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7586,
        TotDASqKM: 81.27,
        DivDASqKM: 81.27,
        Tidal: 0,
        TOTMA: 0.037605186169900003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 33626.0,
        MAXELEVSMO: 33863.0,
        MINELEVSMO: 33681.0,
        SLOPE: 0.00172511,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.054999947547913,
        QA_MA: 52.071,
        VA_MA: 1.06297,
        QC_MA: 54.094,
        VC_MA: 1.06531,
        QE_MA: 54.094,
        VE_MA: 1.06531,
        QA_01: 86.227,
        VA_01: 1.29472,
        QC_01: 69.576,
        VC_01: 1.17354,
        QE_01: 69.576,
        VE_01: 1.17354,
        QA_02: 90.213,
        VA_02: 1.31867,
        QC_02: 77.741,
        VC_02: 1.22605,
        QE_02: 77.741,
        VE_02: 1.22605,
        QA_03: 99.101,
        VA_03: 1.37036,
        QC_03: 86.705,
        VC_03: 1.28079,
        QE_03: 86.705,
        VE_03: 1.28079,
        QA_04: 74.463,
        VA_04: 1.22081,
        QC_04: 70.653,
        VC_04: 1.18063,
        QE_04: 70.653,
        VE_04: 1.18063,
        QA_05: 57.75,
        VA_05: 1.10561,
        QC_05: 56.35,
        VC_05: 1.08191,
        QE_05: 56.35,
        VE_05: 1.08191,
        QA_06: 32.006,
        VA_06: 0.89112,
        QC_06: 40.725,
        VC_06: 0.95939,
        QE_06: 40.725,
        VE_06: 0.95939,
        QA_07: 18.723,
        VA_07: 0.74674,
        QC_07: 29.118,
        VC_07: 0.85316,
        QE_07: 29.118,
        VE_07: 0.85316,
        QA_08: 12.617,
        VA_08: 0.66386,
        QC_08: 27.535,
        VC_08: 0.83723,
        QE_08: 27.535,
        VE_08: 0.83723,
        QA_09: 9.445,
        VA_09: 0.6132,
        QC_09: 28.402,
        VC_09: 0.846,
        QE_09: 28.402,
        VE_09: 0.846,
        QA_10: 20.945,
        VA_10: 0.77364,
        QC_10: 62.472,
        VC_10: 1.12545,
        QE_10: 62.472,
        VE_10: 1.12545,
        QA_11: 54.043,
        VA_11: 1.07801,
        QC_11: 80.144,
        VC_11: 1.241,
        QE_11: 80.144,
        VE_11: 1.241,
        QA_12: 69.71,
        VA_12: 1.18941,
        QC_12: 62.848,
        VC_12: 1.12806,
        QE_12: 62.848,
        VE_12: 1.12806,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.852886316721268, 35.640717411345236, 0.0],
          [-81.851595183389804, 35.643076011341407, 0.0],
          [-81.855128916717717, 35.649145277998855, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286112.0,
        COMID: 9756900,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.094,
        REACHCODE: "03050101002788",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00094419118689800005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062000.0,
        ToNode: 250062001.0,
        Hydroseq: 250007890.0,
        LevelPathI: 250003258.0,
        Pathlength: 464.592,
        TerminalPa: 250002604.0,
        ArbolateSu: 4379.994,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007917.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007857.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0342,
        TotDASqKM: 5416.0425,
        DivDASqKM: 5416.0425,
        Tidal: 0,
        TOTMA: 0.03227962989,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17189.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.093999996781349002,
        QA_MA: 2928.472,
        VA_MA: -9998.0,
        QC_MA: 2928.472,
        VC_MA: -9998.0,
        QE_MA: 3124.372,
        VE_MA: -9998.0,
        QA_01: 5011.251,
        VA_01: -9998.0,
        QC_01: 5011.251,
        VC_01: -9998.0,
        QE_01: 5193.905,
        VE_01: -9998.0,
        QA_02: 5322.73,
        VA_02: -9998.0,
        QC_02: 5322.73,
        VC_02: -9998.0,
        QE_02: 5365.861,
        VE_02: -9998.0,
        QA_03: 5938.557,
        VA_03: -9998.0,
        QC_03: 5938.557,
        VC_03: -9998.0,
        QE_03: 6163.589,
        VE_03: -9998.0,
        QA_04: 4362.626,
        VA_04: -9998.0,
        QC_04: 4362.626,
        VC_04: -9998.0,
        QE_04: 4539.411,
        VE_04: -9998.0,
        QA_05: 3151.582,
        VA_05: -9998.0,
        QC_05: 3151.582,
        VC_05: -9998.0,
        QE_05: 3378.31,
        VE_05: -9998.0,
        QA_06: 1846.373,
        VA_06: -9998.0,
        QC_06: 1846.373,
        VC_06: -9998.0,
        QE_06: 2131.231,
        VE_06: -9998.0,
        QA_07: 1111.837,
        VA_07: -9998.0,
        QC_07: 1111.837,
        VC_07: -9998.0,
        QE_07: 1282.743,
        VE_07: -9998.0,
        QA_08: 781.227,
        VA_08: -9998.0,
        QC_08: 781.227,
        VC_08: -9998.0,
        QE_08: 1109.091,
        VE_08: -9998.0,
        QA_09: 739.597,
        VA_09: -9998.0,
        QC_09: 1334.703,
        VC_09: -9998.0,
        QE_09: 1386.554,
        VE_09: -9998.0,
        QA_10: 869.718,
        VA_10: -9998.0,
        QC_10: 869.718,
        VC_10: -9998.0,
        QE_10: 844.431,
        VE_10: -9998.0,
        QA_11: 2317.062,
        VA_11: -9998.0,
        QC_11: 2317.062,
        VC_11: -9998.0,
        QE_11: 2272.477,
        VE_11: -9998.0,
        QA_12: 3708.998,
        VA_12: -9998.0,
        QC_12: 3708.998,
        VC_12: -9998.0,
        QE_12: 3704.872,
        VE_12: -9998.0,
        LakeFract: 0.00196441414,
        SurfArea: 88958.1969312,
        RAreaHLoad: 0.01162074298,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.005576184703159, 35.164745078750741, 0.0],
          [-81.006319984701975, 35.164163478751448, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286113.0,
        COMID: 9756902,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.137,
        REACHCODE: "03050101002789",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001333572531536,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062001.0,
        ToNode: 250062002.0,
        Hydroseq: 250007857.0,
        LevelPathI: 250003258.0,
        Pathlength: 464.455,
        TerminalPa: 250002604.0,
        ArbolateSu: 4380.131,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007890.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007827.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0954,
        TotDASqKM: 5416.1379,
        DivDASqKM: 5416.1379,
        Tidal: 0,
        TOTMA: 0.04704584357,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17189.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13699999451637301,
        QA_MA: 2928.508,
        VA_MA: -9998.0,
        QC_MA: 2928.508,
        VC_MA: -9998.0,
        QE_MA: 3124.408,
        VE_MA: -9998.0,
        QA_01: 5011.326,
        VA_01: -9998.0,
        QC_01: 5011.326,
        VC_01: -9998.0,
        QE_01: 5193.979,
        VE_01: -9998.0,
        QA_02: 5322.812,
        VA_02: -9998.0,
        QC_02: 5322.812,
        VC_02: -9998.0,
        QE_02: 5365.944,
        VE_02: -9998.0,
        QA_03: 5938.646,
        VA_03: -9998.0,
        QC_03: 5938.646,
        VC_03: -9998.0,
        QE_03: 6163.678,
        VE_03: -9998.0,
        QA_04: 4362.678,
        VA_04: -9998.0,
        QC_04: 4362.678,
        VC_04: -9998.0,
        QE_04: 4539.463,
        VE_04: -9998.0,
        QA_05: 3151.611,
        VA_05: -9998.0,
        QC_05: 3151.611,
        VC_05: -9998.0,
        QE_05: 3378.34,
        VE_05: -9998.0,
        QA_06: 1846.391,
        VA_06: -9998.0,
        QC_06: 1846.391,
        VC_06: -9998.0,
        QE_06: 2131.249,
        VE_06: -9998.0,
        QA_07: 1111.849,
        VA_07: -9998.0,
        QC_07: 1111.849,
        VC_07: -9998.0,
        QE_07: 1282.756,
        VE_07: -9998.0,
        QA_08: 781.236,
        VA_08: -9998.0,
        QC_08: 781.236,
        VC_08: -9998.0,
        QE_08: 1109.101,
        VE_08: -9998.0,
        QA_09: 739.605,
        VA_09: -9998.0,
        QC_09: 1334.715,
        VC_09: -9998.0,
        QE_09: 1386.567,
        VE_09: -9998.0,
        QA_10: 869.725,
        VA_10: -9998.0,
        QC_10: 869.725,
        VC_10: -9998.0,
        QE_10: 844.438,
        VE_10: -9998.0,
        QA_11: 2317.068,
        VA_11: -9998.0,
        QC_11: 2317.068,
        VC_11: -9998.0,
        QE_11: 2272.484,
        VE_11: -9998.0,
        QA_12: 3709.037,
        VA_12: -9998.0,
        QC_12: 3709.037,
        VC_12: -9998.0,
        QE_12: 3704.911,
        VE_12: -9998.0,
        LakeFract: 0.00286306206,
        SurfArea: 129653.331939,
        RAreaHLoad: 0.01693661476,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.006319984701975, 35.164163478751448, 0.0],
          [-81.007129918034025, 35.163194278753167, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286114.0,
        COMID: 9756904,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.857,
        REACHCODE: "03050101002790",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756413,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0078358874524059995,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062002.0,
        ToNode: 250062003.0,
        Hydroseq: 250007827.0,
        LevelPathI: 250003258.0,
        Pathlength: 463.598,
        TerminalPa: 250002604.0,
        ArbolateSu: 4380.988,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007857.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007798.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5246,
        TotDASqKM: 5417.6625,
        DivDASqKM: 5417.6625,
        Tidal: 0,
        TOTMA: 0.2942940728,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17134.0,
        MAXELEVSMO: 17321.0,
        MINELEVSMO: 17321.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.85699999332428001,
        QA_MA: 2929.082,
        VA_MA: -9998.0,
        QC_MA: 2929.082,
        VC_MA: -9998.0,
        QE_MA: 3124.983,
        VE_MA: -9998.0,
        QA_01: 5012.544,
        VA_01: -9998.0,
        QC_01: 5012.544,
        VC_01: -9998.0,
        QE_01: 5195.192,
        VE_01: -9998.0,
        QA_02: 5324.144,
        VA_02: -9998.0,
        QC_02: 5324.144,
        VC_02: -9998.0,
        QE_02: 5367.269,
        VE_02: -9998.0,
        QA_03: 5940.08,
        VA_03: -9998.0,
        QC_03: 5940.08,
        VC_03: -9998.0,
        QE_03: 6165.103,
        VE_03: -9998.0,
        QA_04: 4363.517,
        VA_04: -9998.0,
        QC_04: 4363.517,
        VC_04: -9998.0,
        QE_04: 4540.3,
        VE_04: -9998.0,
        QA_05: 3152.083,
        VA_05: -9998.0,
        QC_05: 3152.083,
        VC_05: -9998.0,
        QE_05: 3378.812,
        VE_05: -9998.0,
        QA_06: 1846.678,
        VA_06: -9998.0,
        QC_06: 1846.678,
        VC_06: -9998.0,
        QE_06: 2131.542,
        VE_06: -9998.0,
        QA_07: 1112.054,
        VA_07: -9998.0,
        QC_07: 1112.054,
        VC_07: -9998.0,
        QE_07: 1282.967,
        VE_07: -9998.0,
        QA_08: 781.379,
        VA_08: -9998.0,
        QC_08: 781.379,
        VC_08: -9998.0,
        QE_08: 1109.251,
        VE_08: -9998.0,
        QA_09: 739.727,
        VA_09: -9998.0,
        QC_09: 1334.911,
        VC_09: -9998.0,
        QE_09: 1386.768,
        VE_09: -9998.0,
        QA_10: 869.827,
        VA_10: -9998.0,
        QC_10: 869.827,
        VC_10: -9998.0,
        QE_10: 844.548,
        VE_10: -9998.0,
        QA_11: 2317.171,
        VA_11: -9998.0,
        QC_11: 2317.171,
        VC_11: -9998.0,
        QE_11: 2272.589,
        VE_11: -9998.0,
        QA_12: 3709.681,
        VA_12: -9998.0,
        QC_12: 3709.681,
        VC_12: -9998.0,
        QE_12: 3705.552,
        VE_12: -9998.0,
        LakeFract: 0.01791310284,
        SurfArea: 811192.15018899995,
        RAreaHLoad: 0.10594656105,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.007129918034025, 35.163194278753167, 0.0],
          [-81.008786518031457, 35.155935878764353, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284050.0,
        COMID: 9753214,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1015703",
        GNIS_NAME: "South Muddy Creek",
        LENGTHKM: 0.456,
        REACHCODE: "03050101000464",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0045186862264279997,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061258.0,
        ToNode: 250061254.0,
        Hydroseq: 250029941.0,
        LevelPathI: 250029941.0,
        Pathlength: 661.579,
        TerminalPa: 250002604.0,
        ArbolateSu: 82.938,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250029941.0,
        UpHydroseq: 250031105.0,
        DnLevelPat: 250022140.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022610.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1485,
        TotDASqKM: 102.9897,
        DivDASqKM: 102.9897,
        Tidal: 0,
        TOTMA: 0.0145712039092,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 32752.0,
        MAXELEVSMO: 32898.0,
        MINELEVSMO: 32752.0,
        SLOPE: 0.00320175,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.45600000023841902,
        QA_MA: 64.822,
        VA_MA: 1.18623,
        QC_MA: 66.771,
        VC_MA: 1.18834,
        QE_MA: 66.771,
        VE_MA: 1.18834,
        QA_01: 108.106,
        VA_01: 1.46016,
        QC_01: 86.714,
        VC_01: 1.31933,
        QE_01: 86.714,
        VE_01: 1.31933,
        QA_02: 113.272,
        VA_02: 1.48906,
        QC_02: 97.009,
        VC_02: 1.38139,
        QE_02: 97.009,
        VE_02: 1.38139,
        QA_03: 124.192,
        VA_03: 1.5482,
        QC_03: 107.851,
        VC_03: 1.44348,
        QE_03: 107.851,
        VE_03: 1.44348,
        QA_04: 93.262,
        VA_04: 1.37328,
        QC_04: 88.038,
        VC_04: 1.3275,
        QE_04: 88.038,
        VE_04: 1.3275,
        QA_05: 72.002,
        VA_05: 1.23659,
        QC_05: 69.678,
        VC_05: 1.20848,
        QE_05: 69.678,
        VE_05: 1.20848,
        QA_06: 39.913,
        VA_06: 0.98694,
        QC_06: 50.633,
        VC_06: 1.06811,
        QE_06: 50.633,
        VE_06: 1.06811,
        QA_07: 23.366,
        VA_07: 0.81902,
        QC_07: 36.146,
        VC_07: 0.94362,
        QE_07: 36.146,
        VE_07: 0.94362,
        QA_08: 15.837,
        VA_08: 0.72374,
        QC_08: 34.72,
        VC_08: 0.93018,
        QE_08: 34.72,
        VE_08: 0.93018,
        QA_09: 11.788,
        VA_09: 0.66347,
        QC_09: 34.542,
        VC_09: 0.92849,
        QE_09: 34.542,
        VE_09: 0.92849,
        QA_10: 23.683,
        VA_10: 0.82269,
        QC_10: 68.915,
        VC_10: 1.20323,
        QE_10: 68.915,
        VE_10: 1.20323,
        QA_11: 66.229,
        VA_11: 1.1963,
        QC_11: 93.587,
        VC_11: 1.36112,
        QE_11: 93.587,
        VE_11: 1.36112,
        QA_12: 86.745,
        VA_12: 1.33309,
        QC_12: 76.679,
        VC_12: 1.25542,
        QE_12: 76.679,
        VE_12: 1.25542,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.862503583372927, 35.693275811263675, 0.0],
          [-81.865647583368116, 35.696344611258787, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286092.0,
        COMID: 9761480,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.604,
        REACHCODE: "03050101002768",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0057391382712980001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250062207.0,
        ToNode: 250061466.0,
        Hydroseq: 250009724.0,
        LevelPathI: 250003258.0,
        Pathlength: 568.695,
        TerminalPa: 250002604.0,
        ArbolateSu: 3291.201,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009773.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009678.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2699,
        TotDASqKM: 3675.2787,
        DivDASqKM: 3675.2787,
        Tidal: 0,
        TOTMA: 0.75325644323999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25428.0,
        MAXELEVSMO: 25430.0,
        MINELEVSMO: 25428.0,
        SLOPE: 3.311e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60399997234344505,
        QA_MA: 2206.429,
        VA_MA: -9998.0,
        QC_MA: 2206.429,
        VC_MA: -9998.0,
        QE_MA: 2398.485,
        VE_MA: -9998.0,
        QA_01: 3567.888,
        VA_01: -9998.0,
        QC_01: 3567.888,
        VC_01: -9998.0,
        QE_01: 3766.028,
        VE_01: -9998.0,
        QA_02: 3770.66,
        VA_02: -9998.0,
        QC_02: 3770.66,
        VC_02: -9998.0,
        QE_02: 3834.614,
        VE_02: -9998.0,
        QA_03: 4264.748,
        VA_03: -9998.0,
        QC_03: 4264.748,
        VC_03: -9998.0,
        QE_03: 4523.286,
        VE_03: -9998.0,
        QA_04: 3283.229,
        VA_04: -9998.0,
        QC_04: 3283.229,
        VC_04: -9998.0,
        QE_04: 3472.34,
        VE_04: -9998.0,
        QA_05: 2498.257,
        VA_05: -9998.0,
        QC_05: 2498.257,
        VC_05: -9998.0,
        QE_05: 2719.465,
        VE_05: -9998.0,
        QA_06: 1456.575,
        VA_06: -9998.0,
        QC_06: 1456.575,
        VC_06: -9998.0,
        QE_06: 1718.359,
        VE_06: -9998.0,
        QA_07: 860.576,
        VA_07: -9998.0,
        QC_07: 860.576,
        VC_07: -9998.0,
        QE_07: 1010.918,
        VE_07: -9998.0,
        QA_08: 604.21,
        VA_08: -9998.0,
        QC_08: 604.21,
        VC_08: -9998.0,
        QE_08: 905.665,
        VE_08: -9998.0,
        QA_09: 585.992,
        VA_09: -9998.0,
        QC_09: 1086.729,
        VC_09: -9998.0,
        QE_09: 1123.433,
        VE_09: -9998.0,
        QA_10: 749.822,
        VA_10: -9998.0,
        QC_10: 749.822,
        VC_10: -9998.0,
        QE_10: 690.252,
        VE_10: -9998.0,
        QA_11: 2037.446,
        VA_11: -9998.0,
        QC_11: 2037.446,
        VC_11: -9998.0,
        QE_11: 1981.665,
        VE_11: -9998.0,
        QA_12: 2812.144,
        VA_12: -9998.0,
        QC_12: 2812.144,
        VC_12: -9998.0,
        QE_12: 2821.511,
        VE_12: -9998.0,
        LakeFract: 0.00918461113,
        SurfArea: 1201487.34941,
        RAreaHLoad: 0.20434537183000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.132190517839888, 35.804853077757002, 0.0],
          [-81.128998184511545, 35.800156011097677, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286087.0,
        COMID: 9754310,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.416,
        REACHCODE: "03050101002763",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0043585527763219997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061615.0,
        ToNode: 250062207.0,
        Hydroseq: 250009773.0,
        LevelPathI: 250003258.0,
        Pathlength: 569.299,
        TerminalPa: 250002604.0,
        ArbolateSu: 3290.597,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250009822.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250009724.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7226,
        TotDASqKM: 3674.0088,
        DivDASqKM: 3674.0088,
        Tidal: 0,
        TOTMA: 0.51879913927,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25430.0,
        MAXELEVSMO: 25430.0,
        MINELEVSMO: 25430.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.41600000858306901,
        QA_MA: 2205.862,
        VA_MA: -9998.0,
        QC_MA: 2205.862,
        VC_MA: -9998.0,
        QE_MA: 2397.917,
        VE_MA: -9998.0,
        QA_01: 3566.802,
        VA_01: -9998.0,
        QC_01: 3566.802,
        VC_01: -9998.0,
        QE_01: 3764.942,
        VE_01: -9998.0,
        QA_02: 3769.498,
        VA_02: -9998.0,
        QC_02: 3769.498,
        VC_02: -9998.0,
        QE_02: 3833.453,
        VE_02: -9998.0,
        QA_03: 4263.502,
        VA_03: -9998.0,
        QC_03: 4263.502,
        VC_03: -9998.0,
        QE_03: 4522.039,
        VE_03: -9998.0,
        QA_04: 3282.341,
        VA_04: -9998.0,
        QC_04: 3282.341,
        VC_04: -9998.0,
        QE_04: 3471.452,
        VE_04: -9998.0,
        QA_05: 2497.7,
        VA_05: -9998.0,
        QC_05: 2497.7,
        VC_05: -9998.0,
        QE_05: 2718.908,
        VE_05: -9998.0,
        QA_06: 1456.245,
        VA_06: -9998.0,
        QC_06: 1456.245,
        VC_06: -9998.0,
        QE_06: 1718.029,
        VE_06: -9998.0,
        QA_07: 860.371,
        VA_07: -9998.0,
        QC_07: 860.371,
        VC_07: -9998.0,
        QE_07: 1010.713,
        VE_07: -9998.0,
        QA_08: 604.074,
        VA_08: -9998.0,
        QC_08: 604.074,
        VC_08: -9998.0,
        QE_08: 905.529,
        VE_08: -9998.0,
        QA_09: 585.872,
        VA_09: -9998.0,
        QC_09: 1086.533,
        VC_09: -9998.0,
        QE_09: 1123.237,
        VE_09: -9998.0,
        QA_10: 749.737,
        VA_10: -9998.0,
        QC_10: 749.737,
        VC_10: -9998.0,
        QE_10: 690.167,
        VE_10: -9998.0,
        QA_11: 2037.179,
        VA_11: -9998.0,
        QC_11: 2037.179,
        VC_11: -9998.0,
        QE_11: 1981.398,
        VE_11: -9998.0,
        QA_12: 2811.416,
        VA_12: -9998.0,
        QC_12: 2811.416,
        VC_12: -9998.0,
        QE_12: 2820.783,
        VE_12: -9998.0,
        LakeFract: 0.00632433241,
        SurfArea: 827319.227404,
        RAreaHLoad: 0.14074118312,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.135974984500649, 35.806932077753913, 0.0],
          [-81.132190517839888, 35.804853077757002, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286089.0,
        COMID: 9755482,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.906,
        REACHCODE: "03050101002765",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.029093708692226002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061845.0,
        ToNode: 250061846.0,
        Hydroseq: 250008561.0,
        LevelPathI: 250003258.0,
        Pathlength: 516.511,
        TerminalPa: 250002604.0,
        ArbolateSu: 3879.241,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008594.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008532.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.6059,
        TotDASqKM: 4505.0616,
        DivDASqKM: 4505.0616,
        Tidal: 0,
        TOTMA: 3.6241112976399998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23026.0,
        MAXELEVSMO: 23050.0,
        MINELEVSMO: 23050.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.905999898910522,
        QA_MA: 2571.933,
        VA_MA: -9998.0,
        QC_MA: 2571.933,
        VC_MA: -9998.0,
        QE_MA: 2765.172,
        VE_MA: -9998.0,
        QA_01: 4281.44,
        VA_01: -9998.0,
        QC_01: 4281.44,
        VC_01: -9998.0,
        QE_01: 4461.611,
        VE_01: -9998.0,
        QA_02: 4522.948,
        VA_02: -9998.0,
        QC_02: 4522.948,
        VC_02: -9998.0,
        QE_02: 4566.931,
        VE_02: -9998.0,
        QA_03: 5075.827,
        VA_03: -9998.0,
        QC_03: 5075.827,
        VC_03: -9998.0,
        QE_03: 5305.575,
        VE_03: -9998.0,
        QA_04: 3835.904,
        VA_04: -9998.0,
        QC_04: 3835.904,
        VC_04: -9998.0,
        QE_04: 4016.317,
        VE_04: -9998.0,
        QA_05: 2845.393,
        VA_05: -9998.0,
        QC_05: 2845.393,
        VC_05: -9998.0,
        QE_05: 3067.625,
        VE_05: -9998.0,
        QA_06: 1662.158,
        VA_06: -9998.0,
        QC_06: 1662.158,
        VC_06: -9998.0,
        QE_06: 1941.537,
        VE_06: -9998.0,
        QA_07: 989.916,
        VA_07: -9998.0,
        QC_07: 989.916,
        VC_07: -9998.0,
        QE_07: 1157.817,
        VE_07: -9998.0,
        QA_08: 693.344,
        VA_08: -9998.0,
        QC_08: 693.344,
        VC_08: -9998.0,
        QE_08: 1016.527,
        VE_08: -9998.0,
        QA_09: 663.587,
        VA_09: -9998.0,
        QC_09: 1212.838,
        VC_09: -9998.0,
        QE_09: 1263.564,
        VE_09: -9998.0,
        QA_10: 806.446,
        VA_10: -9998.0,
        QC_10: 806.446,
        VC_10: -9998.0,
        QE_10: 772.317,
        VE_10: -9998.0,
        QA_11: 2216.705,
        VA_11: -9998.0,
        QC_11: 2216.705,
        VC_11: -9998.0,
        QE_11: 2163.368,
        VE_11: -9998.0,
        QA_12: 3286.479,
        VA_12: -9998.0,
        QC_12: 3286.479,
        VC_12: -9998.0,
        QE_12: 3282.986,
        VE_12: -9998.0,
        LakeFract: 0.05093032957,
        SurfArea: 6662464.6175499996,
        RAreaHLoad: 0.98315836167000004,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.963236184768789, 35.500148278230142, 0.0],
          [-80.958358118109743, 35.493628811573501, 0.0],
          [-80.947652784793036, 35.492255411575684, 0.0],
          [-80.943778118132343, 35.487958411582326, 0.0],
          [-80.944455384797948, 35.483702811588898, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286090.0,
        COMID: 9755484,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 6.369,
        REACHCODE: "03050101002766",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 166756415,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.060698173602086999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061846.0,
        ToNode: 250061847.0,
        Hydroseq: 250008532.0,
        LevelPathI: 250003258.0,
        Pathlength: 510.142,
        TerminalPa: 250002604.0,
        ArbolateSu: 3930.895,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008561.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008498.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 40.1409,
        TotDASqKM: 4646.7126,
        DivDASqKM: 4646.7126,
        Tidal: 0,
        TOTMA: 7.9428647128999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22887.0,
        MAXELEVSMO: 23050.0,
        MINELEVSMO: 22887.0,
        SLOPE: 0.00025592,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 6.3689999580383301,
        QA_MA: 2628.462,
        VA_MA: -9998.0,
        QC_MA: 2628.462,
        VC_MA: -9998.0,
        QE_MA: 2821.771,
        VE_MA: -9998.0,
        QA_01: 4397.344,
        VA_01: -9998.0,
        QC_01: 4397.344,
        VC_01: -9998.0,
        QE_01: 4577.048,
        VE_01: -9998.0,
        QA_02: 4647.443,
        VA_02: -9998.0,
        QC_02: 4647.443,
        VC_02: -9998.0,
        QE_02: 4690.878,
        VE_02: -9998.0,
        QA_03: 5210.19,
        VA_03: -9998.0,
        QC_03: 5210.19,
        VC_03: -9998.0,
        QE_03: 5439.159,
        VE_03: -9998.0,
        QA_04: 3921.15,
        VA_04: -9998.0,
        QC_04: 3921.15,
        VC_04: -9998.0,
        QE_04: 4101.334,
        VE_04: -9998.0,
        QA_05: 2893.675,
        VA_05: -9998.0,
        QC_05: 2893.675,
        VC_05: -9998.0,
        QE_05: 3115.973,
        VE_05: -9998.0,
        QA_06: 1691.408,
        VA_06: -9998.0,
        QC_06: 1691.408,
        VC_06: -9998.0,
        QE_06: 1971.334,
        VE_06: -9998.0,
        QA_07: 1009.002,
        VA_07: -9998.0,
        QC_07: 1009.002,
        VC_07: -9998.0,
        QE_07: 1177.432,
        VE_07: -9998.0,
        QA_08: 706.681,
        VA_08: -9998.0,
        QC_08: 706.681,
        VC_08: -9998.0,
        QE_08: 1030.521,
        VE_08: -9998.0,
        QA_09: 675.528,
        VA_09: -9998.0,
        QC_09: 1232.086,
        VC_09: -9998.0,
        QE_09: 1283.325,
        VE_09: -9998.0,
        QA_10: 815.969,
        VA_10: -9998.0,
        QC_10: 815.969,
        VC_10: -9998.0,
        QE_10: 782.581,
        VE_10: -9998.0,
        QA_11: 2233.288,
        VA_11: -9998.0,
        QC_11: 2233.288,
        VC_11: -9998.0,
        QE_11: 2180.148,
        VE_11: -9998.0,
        QA_12: 3357.129,
        VA_12: -9998.0,
        QC_12: 3357.129,
        VC_12: -9998.0,
        QE_12: 3353.354,
        VE_12: -9998.0,
        LakeFract: 0.11390355181,
        SurfArea: 14900323.4449,
        RAreaHLoad: 2.1547610480900001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.944455384797948, 35.483702811588898, 0.0],
          [-80.954356718115889, 35.471267611608198, 0.0],
          [-80.95517158478134, 35.4671652782813, 0.0],
          [-80.94637718479504, 35.457894078295624, 0.0],
          [-80.946356718128357, 35.455448811632721, 0.0],
          [-80.947355918126846, 35.450420211640449, 0.0],
          [-80.952345318119114, 35.447396811645206, 0.0],
          [-80.953482318117267, 35.442521611652872, 0.0],
          [-80.957912184777058, 35.435318878330747, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286100.0,
        COMID: 9755528,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 6.078,
        REACHCODE: "03050101002776",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755308,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.061975119809715998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061867.0,
        ToNode: 250061869.0,
        Hydroseq: 250008224.0,
        LevelPathI: 250003258.0,
        Pathlength: 485.837,
        TerminalPa: 250002604.0,
        ArbolateSu: 4032.426,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008251.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008196.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 14.8527,
        TotDASqKM: 4824.5517,
        DivDASqKM: 4824.5517,
        Tidal: 0,
        TOTMA: 1.0941308385299999,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17809.0,
        MAXELEVSMO: 19584.0,
        MINELEVSMO: 17809.0,
        SLOPE: 0.00292036,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 6.0780000686645508,
        QA_MA: 2695.352,
        VA_MA: -9998.0,
        QC_MA: 2695.352,
        VC_MA: -9998.0,
        QE_MA: 2888.748,
        VE_MA: -9998.0,
        QA_01: 4534.911,
        VA_01: -9998.0,
        QC_01: 4534.911,
        VC_01: -9998.0,
        QE_01: 4714.029,
        VE_01: -9998.0,
        QA_02: 4801.446,
        VA_02: -9998.0,
        QC_02: 4801.446,
        VC_02: -9998.0,
        QE_02: 4844.193,
        VE_02: -9998.0,
        QA_03: 5376.194,
        VA_03: -9998.0,
        QC_03: 5376.194,
        VC_03: -9998.0,
        QE_03: 5604.183,
        VE_03: -9998.0,
        QA_04: 4020.795,
        VA_04: -9998.0,
        QC_04: 4020.795,
        VC_04: -9998.0,
        QE_04: 4200.692,
        VE_04: -9998.0,
        QA_05: 2950.555,
        VA_05: -9998.0,
        QC_05: 2950.555,
        VC_05: -9998.0,
        QE_05: 3172.934,
        VE_05: -9998.0,
        QA_06: 1725.914,
        VA_06: -9998.0,
        QC_06: 1725.914,
        VC_06: -9998.0,
        QE_06: 2006.526,
        VE_06: -9998.0,
        QA_07: 1032.518,
        VA_07: -9998.0,
        QC_07: 1032.518,
        VC_07: -9998.0,
        QE_07: 1201.614,
        VE_07: -9998.0,
        QA_08: 723.419,
        VA_08: -9998.0,
        QC_08: 723.419,
        VC_08: -9998.0,
        QE_08: 1048.082,
        VE_08: -9998.0,
        QA_09: 689.874,
        VA_09: -9998.0,
        QC_09: 1255.16,
        VC_09: -9998.0,
        QE_09: 1307.044,
        VE_09: -9998.0,
        QA_10: 827.925,
        VA_10: -9998.0,
        QC_10: 827.925,
        VC_10: -9998.0,
        QE_10: 795.467,
        VE_10: -9998.0,
        QA_11: 2246.287,
        VA_11: -9998.0,
        QC_11: 2246.287,
        VC_11: -9998.0,
        QE_11: 2193.395,
        VE_11: -9998.0,
        QA_12: 3432.219,
        VA_12: -9998.0,
        QC_12: 3432.219,
        VC_12: -9998.0,
        QE_12: 3428.092,
        VE_12: -9998.0,
        LakeFract: 0.48040972014,
        SurfArea: 3883814.4963600002,
        RAreaHLoad: 0.54864393178000004,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.940590584803942, 35.351972278459925, 0.0],
          [-80.944108118131851, 35.350388878462525, 0.0],
          [-80.955234184781261, 35.352090878459819, 0.0],
          [-80.962549784769934, 35.346652211801597, 0.0],
          [-80.969982718091728, 35.351678278460497, 0.0],
          [-80.973492318086244, 35.349278811797603, 0.0],
          [-80.974745584750906, 35.345504011803428, 0.0],
          [-80.984513184735761, 35.342143211808661, 0.0],
          [-80.987066184731873, 35.336906478483513, 0.0],
          [-80.986626984732482, 35.334234211820956, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286096.0,
        COMID: 9755518,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.719,
        REACHCODE: "03050101002772",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755308,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0079508017645020003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061862.0,
        ToNode: 250061865.0,
        Hydroseq: 250008280.0,
        LevelPathI: 250003258.0,
        Pathlength: 494.467,
        TerminalPa: 250002604.0,
        ArbolateSu: 4013.292,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008310.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008251.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4941,
        TotDASqKM: 4783.6332,
        DivDASqKM: 4783.6332,
        Tidal: 0,
        TOTMA: 0.12943074579,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19581.0,
        MAXELEVSMO: 19584.0,
        MINELEVSMO: 19584.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.71899998188018799,
        QA_MA: 2680.379,
        VA_MA: -9998.0,
        QC_MA: 2680.379,
        VC_MA: -9998.0,
        QE_MA: 2873.756,
        VE_MA: -9998.0,
        QA_01: 4504.258,
        VA_01: -9998.0,
        QC_01: 4504.258,
        VC_01: -9998.0,
        QE_01: 4683.511,
        VE_01: -9998.0,
        QA_02: 4766.475,
        VA_02: -9998.0,
        QC_02: 4766.475,
        VC_02: -9998.0,
        QE_02: 4809.38,
        VE_02: -9998.0,
        QA_03: 5338.447,
        VA_03: -9998.0,
        QC_03: 5338.447,
        VC_03: -9998.0,
        QE_03: 5566.662,
        VE_03: -9998.0,
        QA_04: 3998.529,
        VA_04: -9998.0,
        QC_04: 3998.529,
        VC_04: -9998.0,
        QE_04: 4178.492,
        VE_04: -9998.0,
        QA_05: 2937.788,
        VA_05: -9998.0,
        QC_05: 2937.788,
        VC_05: -9998.0,
        QE_05: 3160.148,
        VE_05: -9998.0,
        QA_06: 1718.149,
        VA_06: -9998.0,
        QC_06: 1718.149,
        VC_06: -9998.0,
        QE_06: 1998.603,
        VE_06: -9998.0,
        QA_07: 1027.239,
        VA_07: -9998.0,
        QC_07: 1027.239,
        VC_07: -9998.0,
        QE_07: 1196.182,
        VE_07: -9998.0,
        QA_08: 719.568,
        VA_08: -9998.0,
        QC_08: 719.568,
        VC_08: -9998.0,
        QE_08: 1044.041,
        VE_08: -9998.0,
        QA_09: 686.573,
        VA_09: -9998.0,
        QC_09: 1249.856,
        VC_09: -9998.0,
        QE_09: 1301.591,
        VE_09: -9998.0,
        QA_10: 825.174,
        VA_10: -9998.0,
        QC_10: 825.174,
        VC_10: -9998.0,
        QE_10: 792.502,
        VE_10: -9998.0,
        QA_11: 2243.528,
        VA_11: -9998.0,
        QC_11: 2243.528,
        VC_11: -9998.0,
        QE_11: 2190.579,
        VE_11: -9998.0,
        QA_12: 3416.537,
        VA_12: -9998.0,
        QC_12: 3416.537,
        VC_12: -9998.0,
        QE_12: 3412.491,
        VE_12: -9998.0,
        LakeFract: 0.0565358213,
        SurfArea: 457057.03511699999,
        RAreaHLoad: 0.06490210381,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.938554784807152, 35.363925211774877, 0.0],
          [-80.930669584819384, 35.363724878441872, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286097.0,
        COMID: 9755520,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 3.335,
        REACHCODE: "03050101002773",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755308,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.034550094948290998,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061863.0,
        ToNode: 250061862.0,
        Hydroseq: 250008310.0,
        LevelPathI: 250003258.0,
        Pathlength: 495.186,
        TerminalPa: 250002604.0,
        ArbolateSu: 3967.987,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008337.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.8006,
        TotDASqKM: 4707.4608,
        DivDASqKM: 4707.4608,
        Tidal: 0,
        TOTMA: 0.60034984311999995,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19570.0,
        MAXELEVSMO: 19584.0,
        MINELEVSMO: 19584.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.3350000381469731,
        QA_MA: 2651.094,
        VA_MA: -9998.0,
        QC_MA: 2651.094,
        VC_MA: -9998.0,
        QE_MA: 2844.432,
        VE_MA: -9998.0,
        QA_01: 4443.647,
        VA_01: -9998.0,
        QC_01: 4443.647,
        VC_01: -9998.0,
        QE_01: 4623.151,
        VE_01: -9998.0,
        QA_02: 4699.929,
        VA_02: -9998.0,
        QC_02: 4699.929,
        VC_02: -9998.0,
        QE_02: 4743.129,
        VE_02: -9998.0,
        QA_03: 5266.773,
        VA_03: -9998.0,
        QC_03: 5266.773,
        VC_03: -9998.0,
        QE_03: 5495.407,
        VE_03: -9998.0,
        QA_04: 3954.863,
        VA_04: -9998.0,
        QC_04: 3954.863,
        VC_04: -9998.0,
        QE_04: 4134.949,
        VE_04: -9998.0,
        QA_05: 2912.955,
        VA_05: -9998.0,
        QC_05: 2912.955,
        VC_05: -9998.0,
        QE_05: 3135.28,
        VE_05: -9998.0,
        QA_06: 1703.034,
        VA_06: -9998.0,
        QC_06: 1703.034,
        VC_06: -9998.0,
        QE_06: 1983.194,
        VE_06: -9998.0,
        QA_07: 1017.015,
        VA_07: -9998.0,
        QC_07: 1017.015,
        VC_07: -9998.0,
        QE_07: 1185.673,
        VE_07: -9998.0,
        QA_08: 712.399,
        VA_08: -9998.0,
        QC_08: 712.399,
        VC_08: -9998.0,
        QE_08: 1036.52,
        VE_08: -9998.0,
        QA_09: 680.428,
        VA_09: -9998.0,
        QC_09: 1239.974,
        VC_09: -9998.0,
        QE_09: 1291.433,
        VE_09: -9998.0,
        QA_10: 820.053,
        VA_10: -9998.0,
        QC_10: 820.053,
        VC_10: -9998.0,
        QE_10: 786.983,
        VE_10: -9998.0,
        QA_11: 2237.415,
        VA_11: -9998.0,
        QC_11: 2237.415,
        VC_11: -9998.0,
        QE_11: 2184.36,
        VE_11: -9998.0,
        QA_12: 3382.017,
        VA_12: -9998.0,
        QC_12: 3382.017,
        VC_12: -9998.0,
        QE_12: 3378.122,
        VE_12: -9998.0,
        LakeFract: 0.25956354988000002,
        SurfArea: 2098410.2433600002,
        RAreaHLoad: 0.30104105174000001,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.966797518096655, 35.369453678432883, 0.0],
          [-80.965700584765045, 35.372435478428258, 0.0],
          [-80.963031118102492, 35.373992678425793, 0.0],
          [-80.957446984777846, 35.374089678425662, 0.0],
          [-80.954426184782449, 35.369765878432418, 0.0],
          [-80.938554784807152, 35.363925211774877, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286098.0,
        COMID: 9755524,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.552,
        REACHCODE: "03050101002774",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9755308,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.026515838073332999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250061865.0,
        ToNode: 250061867.0,
        Hydroseq: 250008251.0,
        LevelPathI: 250003258.0,
        Pathlength: 491.915,
        TerminalPa: 250002604.0,
        ArbolateSu: 4017.257,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250008280.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250008224.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.338,
        TotDASqKM: 4791.9348,
        DivDASqKM: 4791.9348,
        Tidal: 0,
        TOTMA: 0.45939814083000002,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 19573.0,
        MAXELEVSMO: 19584.0,
        MINELEVSMO: 19584.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5520000457763672,
        QA_MA: 2683.408,
        VA_MA: -9998.0,
        QC_MA: 2683.408,
        VC_MA: -9998.0,
        QE_MA: 2876.788,
        VE_MA: -9998.0,
        QA_01: 4510.438,
        VA_01: -9998.0,
        QC_01: 4510.438,
        VC_01: -9998.0,
        QE_01: 4689.663,
        VE_01: -9998.0,
        QA_02: 4773.561,
        VA_02: -9998.0,
        QC_02: 4773.561,
        VC_02: -9998.0,
        QE_02: 4816.434,
        VE_02: -9998.0,
        QA_03: 5346.092,
        VA_03: -9998.0,
        QC_03: 5346.092,
        VC_03: -9998.0,
        QE_03: 5574.261,
        VE_03: -9998.0,
        QA_04: 4003.048,
        VA_04: -9998.0,
        QC_04: 4003.048,
        VC_04: -9998.0,
        QE_04: 4182.997,
        VE_04: -9998.0,
        QA_05: 2940.364,
        VA_05: -9998.0,
        QC_05: 2940.364,
        VC_05: -9998.0,
        QE_05: 3162.728,
        VE_05: -9998.0,
        QA_06: 1719.712,
        VA_06: -9998.0,
        QC_06: 1719.712,
        VC_06: -9998.0,
        QE_06: 2000.197,
        VE_06: -9998.0,
        QA_07: 1028.336,
        VA_07: -9998.0,
        QC_07: 1028.336,
        VC_07: -9998.0,
        QE_07: 1197.31,
        VE_07: -9998.0,
        QA_08: 720.349,
        VA_08: -9998.0,
        QC_08: 720.349,
        VC_08: -9998.0,
        QE_08: 1044.861,
        VE_08: -9998.0,
        QA_09: 687.243,
        VA_09: -9998.0,
        QC_09: 1250.932,
        VC_09: -9998.0,
        QE_09: 1302.698,
        VE_09: -9998.0,
        QA_10: 825.732,
        VA_10: -9998.0,
        QC_10: 825.732,
        VC_10: -9998.0,
        QE_10: 793.104,
        VE_10: -9998.0,
        QA_11: 2244.086,
        VA_11: -9998.0,
        QC_11: 2244.086,
        VC_11: -9998.0,
        QE_11: 2191.149,
        VE_11: -9998.0,
        QA_12: 3419.653,
        VA_12: -9998.0,
        QC_12: 3419.653,
        VC_12: -9998.0,
        QE_12: 3415.59,
        VE_12: -9998.0,
        LakeFract: 0.20087815603,
        SurfArea: 1623975.2479699999,
        RAreaHLoad: 0.23036184829,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.930669584819384, 35.363724878441872, 0.0],
          [-80.926282984826116, 35.362888678443028, 0.0],
          [-80.924862518161717, 35.3598556117812, 0.0],
          [-80.932674584816255, 35.357875411784164, 0.0],
          [-80.940590584803942, 35.351972278459925, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284203.0,
        COMID: 9754578,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1008770",
        GNIS_NAME: "Armstrong Creek",
        LENGTHKM: 1.228,
        REACHCODE: "03050101000606",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011610664823489001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061650.0,
        ToNode: 250061651.0,
        Hydroseq: 250032402.0,
        LevelPathI: 250029939.0,
        Pathlength: 690.743,
        TerminalPa: 250002604.0,
        ArbolateSu: 77.374,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029939.0,
        UpHydroseq: 250033871.0,
        DnLevelPat: 250029939.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031102.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1628,
        TotDASqKM: 69.1812,
        DivDASqKM: 69.1812,
        Tidal: 0,
        TOTMA: 0.039436126544,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 42341.0,
        MAXELEVSMO: 43054.0,
        MINELEVSMO: 42423.0,
        SLOPE: 0.00513843,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2280000448226931,
        QA_MA: 48.389,
        VA_MA: 1.17954,
        QC_MA: 50.412,
        VC_MA: 1.18243,
        QE_MA: 50.412,
        VE_MA: 1.18243,
        QA_01: 75.378,
        VA_01: 1.41071,
        QC_01: 61.036,
        VC_01: 1.27585,
        QE_01: 61.036,
        VE_01: 1.27585,
        QA_02: 80.108,
        VA_02: 1.44691,
        QC_02: 69.257,
        VC_02: 1.34299,
        QE_02: 69.257,
        VE_02: 1.34299,
        QA_03: 91.669,
        VA_03: 1.5314,
        QC_03: 80.409,
        VC_03: 1.42835,
        QE_03: 80.409,
        VE_03: 1.42835,
        QA_04: 68.586,
        VA_04: 1.3568,
        QC_04: 65.198,
        VC_04: 1.31034,
        QE_04: 65.198,
        VE_04: 1.31034,
        QA_05: 56.402,
        VA_05: 1.25338,
        QC_05: 55.082,
        VC_05: 1.22453,
        QE_05: 55.082,
        VE_05: 1.22453,
        QA_06: 30.784,
        VA_06: 0.99354,
        QC_06: 39.191,
        VC_06: 1.07305,
        QE_06: 39.191,
        VE_06: 1.07305,
        QA_07: 18.081,
        VA_07: 0.82489,
        QC_07: 28.142,
        VC_07: 0.94973,
        QE_07: 28.142,
        VE_07: 0.94973,
        QA_08: 13.037,
        VA_08: 0.74255,
        QC_08: 28.47,
        VC_08: 0.95369,
        QE_08: 28.47,
        VE_08: 0.95369,
        QA_09: 15.861,
        VA_09: 0.79019,
        QC_09: 44.888,
        VC_09: 1.13019,
        QE_09: 44.888,
        VE_09: 1.13019,
        QA_10: 23.601,
        VA_10: 0.90339,
        QC_10: 68.724,
        VC_10: 1.33875,
        QE_10: 68.724,
        VE_10: 1.33875,
        QA_11: 48.368,
        VA_11: 1.17934,
        QC_11: 73.641,
        VC_11: 1.37726,
        QE_11: 73.641,
        VE_11: 1.37726,
        QA_12: 59.112,
        VA_12: 1.27723,
        QC_12: 54.091,
        VC_12: 1.21574,
        QE_12: 54.091,
        VE_12: 1.21574,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.039800983097734, 35.805067277756791, 0.0],
          [-82.038571183099634, 35.798219611100706, 0.0],
          [-82.035454116437862, 35.795561077771595, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284204.0,
        COMID: 9754536,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1008770",
        GNIS_NAME: "Armstrong Creek",
        LENGTHKM: 2.588,
        REACHCODE: "03050101000607",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026515583926160002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061635.0,
        ToNode: 250061650.0,
        Hydroseq: 250033871.0,
        LevelPathI: 250029939.0,
        Pathlength: 691.971,
        TerminalPa: 250002604.0,
        ArbolateSu: 64.619,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029939.0,
        UpHydroseq: 250035529.0,
        DnLevelPat: 250029939.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032402.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.348,
        TotDASqKM: 56.799,
        DivDASqKM: 56.799,
        Tidal: 0,
        TOTMA: 0.0812055326518,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 42990.0,
        MAXELEVSMO: 45140.0,
        MINELEVSMO: 43054.0,
        SLOPE: 0.00806027,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.5880000591278081,
        QA_MA: 42.018,
        VA_MA: 1.2068,
        QC_MA: 44.015,
        VC_MA: 1.21018,
        QE_MA: 44.015,
        VE_MA: 1.21018,
        QA_01: 64.261,
        VA_01: 1.43415,
        QC_01: 52.252,
        VC_01: 1.29618,
        QE_01: 52.252,
        VE_01: 1.29618,
        QA_02: 68.299,
        VA_02: 1.47118,
        QC_02: 59.305,
        VC_02: 1.36488,
        QE_02: 59.305,
        VE_02: 1.36488,
        QA_03: 77.897,
        VA_03: 1.55527,
        QC_03: 68.697,
        VC_03: 1.45066,
        QE_03: 68.697,
        VE_03: 1.45066,
        QA_04: 58.944,
        VA_04: 1.38369,
        QC_04: 56.225,
        VC_04: 1.33538,
        QE_04: 56.225,
        VE_04: 1.33538,
        QA_05: 48.801,
        VA_05: 1.2811,
        QC_05: 47.918,
        VC_05: 1.2518,
        QE_05: 47.918,
        VE_05: 1.2518,
        QA_06: 26.768,
        VA_06: 1.01551,
        QC_06: 34.144,
        VC_06: 1.09642,
        QE_06: 34.144,
        VE_06: 1.09642,
        QA_07: 15.65,
        VA_07: 0.84015,
        QC_07: 24.442,
        VC_07: 0.96825,
        QE_07: 24.442,
        VE_07: 0.96825,
        QA_08: 11.367,
        VA_08: 0.75711,
        QC_08: 24.756,
        VC_08: 0.97274,
        QE_08: 24.756,
        VE_08: 0.97274,
        QA_09: 14.567,
        VA_09: 0.82028,
        QC_09: 41.64,
        VC_09: 1.18401,
        QE_09: 41.64,
        VE_09: 1.18401,
        QA_10: 22.404,
        VA_10: 0.95174,
        QC_10: 65.925,
        VC_10: 1.42594,
        QE_10: 65.925,
        VE_10: 1.42594,
        QA_11: 44.09,
        VA_11: 1.23005,
        QC_11: 68.62,
        VC_11: 1.44998,
        QE_11: 68.62,
        VE_11: 1.44998,
        QA_12: 51.349,
        VA_12: 1.30775,
        QC_12: 47.588,
        VC_12: 1.24834,
        QE_12: 47.588,
        VE_12: 1.24834,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.06074231639866, 35.806865477754059, 0.0],
          [-82.053147316410445, 35.812540677745062, 0.0],
          [-82.046938583086728, 35.807957211085522, 0.0],
          [-82.041794316428025, 35.807747877752661, 0.0],
          [-82.039800983097734, 35.805067277756791, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284205.0,
        COMID: 9754532,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1008770",
        GNIS_NAME: "Armstrong Creek",
        LENGTHKM: 1.563,
        REACHCODE: "03050101000608",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016252064938344999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061633.0,
        ToNode: 250061635.0,
        Hydroseq: 250035529.0,
        LevelPathI: 250029939.0,
        Pathlength: 694.559,
        TerminalPa: 250002604.0,
        ArbolateSu: 58.668,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029939.0,
        UpHydroseq: 250037440.0,
        DnLevelPat: 250029939.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250033871.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8469,
        TotDASqKM: 49.8654,
        DivDASqKM: 49.8654,
        Tidal: 0,
        TOTMA: 0.0481493575373,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 45061.0,
        MAXELEVSMO: 46881.0,
        MINELEVSMO: 45140.0,
        SLOPE: 0.01113883,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.562999963760376,
        QA_MA: 38.182,
        VA_MA: 1.22891,
        QC_MA: 40.146,
        VC_MA: 1.23265,
        QE_MA: 40.146,
        VE_MA: 1.23265,
        QA_01: 57.695,
        VA_01: 1.45451,
        QC_01: 47.046,
        VC_01: 1.31387,
        QE_01: 47.046,
        VE_01: 1.31387,
        QA_02: 61.364,
        VA_02: 1.49265,
        QC_02: 53.439,
        VC_02: 1.38425,
        QE_02: 53.439,
        VE_02: 1.38425,
        QA_03: 69.949,
        VA_03: 1.57792,
        QC_03: 61.908,
        VC_03: 1.47166,
        QE_03: 61.908,
        VE_03: 1.47166,
        QA_04: 53.262,
        VA_04: 1.40687,
        QC_04: 50.923,
        VC_04: 1.35705,
        QE_04: 50.923,
        VE_04: 1.35705,
        QA_05: 44.272,
        VA_05: 1.30416,
        QC_05: 43.63,
        VC_05: 1.27441,
        QE_05: 43.63,
        VE_05: 1.27441,
        QA_06: 24.372,
        VA_06: 1.03367,
        QC_06: 31.128,
        VC_06: 1.11586,
        QE_06: 31.128,
        VE_06: 1.11586,
        QA_07: 14.237,
        VA_07: 0.85355,
        QC_07: 22.287,
        VC_07: 0.98459,
        QE_07: 22.287,
        VE_07: 0.98459,
        QA_08: 10.411,
        VA_08: 0.77008,
        QC_08: 22.634,
        VC_08: 0.99016,
        QE_08: 22.634,
        VE_08: 0.99016,
        QA_09: 13.743,
        VA_09: 0.84343,
        QC_09: 39.553,
        VC_09: 1.22538,
        QE_09: 39.553,
        VE_09: 1.22538,
        QA_10: 21.371,
        VA_10: 0.98478,
        QC_10: 63.485,
        VC_10: 1.4873,
        QE_10: 63.485,
        VE_10: 1.4873,
        QA_11: 41.062,
        VA_11: 1.26515,
        QC_11: 64.997,
        VC_11: 1.50213,
        QE_11: 64.997,
        VE_11: 1.50213,
        QA_12: 46.616,
        VA_12: 1.33182,
        QC_12: 43.58,
        VC_12: 1.27383,
        QE_12: 43.58,
        VE_12: 1.27383,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.07414931637777, 35.810329077748634, 0.0],
          [-82.0706629830499, 35.810968811080897, 0.0],
          [-82.069033516385787, 35.809456877749994, 0.0],
          [-82.066784183055802, 35.809959677749134, 0.0],
          [-82.064537383059417, 35.806684011087668, 0.0],
          [-82.06074231639866, 35.806865477754059, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284201.0,
        COMID: 9754592,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1008770",
        GNIS_NAME: "Armstrong Creek",
        LENGTHKM: 0.83,
        REACHCODE: "03050101000604",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0089819355141399996,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061655.0,
        ToNode: 250061656.0,
        Hydroseq: 250029939.0,
        LevelPathI: 250029939.0,
        Pathlength: 689.162,
        TerminalPa: 250002604.0,
        ArbolateSu: 84.237,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250029939.0,
        UpHydroseq: 250031102.0,
        DnLevelPat: 250019848.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022606.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4392,
        TotDASqKM: 73.7928,
        DivDASqKM: 73.7928,
        Tidal: 0,
        TOTMA: 0.026045010842000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41722.0,
        MAXELEVSMO: 42200.0,
        MINELEVSMO: 41722.0,
        SLOPE: 0.00575903,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.82999998331069902,
        QA_MA: 50.582,
        VA_MA: 1.20725,
        QC_MA: 52.606,
        VC_MA: 1.21011,
        QE_MA: 52.606,
        VE_MA: 1.21011,
        QA_01: 79.266,
        VA_01: 1.44939,
        QC_01: 64.1,
        VC_01: 1.30987,
        QE_01: 64.1,
        VE_01: 1.30987,
        QA_02: 84.34,
        VA_02: 1.48761,
        QC_02: 72.813,
        VC_02: 1.38,
        QE_02: 72.813,
        VE_02: 1.38,
        QA_03: 96.55,
        VA_03: 1.57538,
        QC_03: 84.546,
        VC_03: 1.46847,
        QE_03: 84.546,
        VE_03: 1.46847,
        QA_04: 72.001,
        VA_04: 1.39261,
        QC_04: 68.368,
        VC_04: 1.34475,
        QE_04: 68.368,
        VE_04: 1.34475,
        QA_05: 59.031,
        VA_05: 1.28408,
        QC_05: 57.552,
        VC_05: 1.25418,
        QE_05: 57.552,
        VE_05: 1.25418,
        QA_06: 32.179,
        VA_06: 1.01535,
        QC_06: 40.942,
        VC_06: 1.09773,
        QE_06: 40.942,
        VE_06: 1.09773,
        QA_07: 18.929,
        VA_07: 0.84175,
        QC_07: 29.43,
        VC_07: 0.9708,
        QE_07: 29.43,
        VE_07: 0.9708,
        QA_08: 13.628,
        VA_08: 0.75636,
        QC_08: 29.788,
        VC_08: 0.97507,
        QE_08: 29.788,
        VE_08: 0.97507,
        QA_09: 16.303,
        VA_09: 0.80109,
        QC_09: 45.992,
        VC_09: 1.14802,
        QE_09: 45.992,
        VE_09: 1.14802,
        QA_10: 23.924,
        VA_10: 0.91238,
        QC_10: 69.476,
        VC_10: 1.35363,
        QE_10: 69.476,
        VE_10: 1.35363,
        QA_11: 49.462,
        VA_11: 1.19663,
        QC_11: 74.909,
        VC_11: 1.39627,
        QE_11: 74.909,
        VE_11: 1.39627,
        QA_12: 61.735,
        VA_12: 1.30757,
        QC_12: 56.271,
        VC_12: 1.24294,
        QE_12: 56.271,
        VE_12: 1.24294,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.027891716449574, 35.796837877769576, 0.0],
          [-82.01946278312937, 35.794060277773895, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284202.0,
        COMID: 9754582,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1008770",
        GNIS_NAME: "Armstrong Creek",
        LENGTHKM: 0.751,
        REACHCODE: "03050101000605",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0081005068302389995,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061651.0,
        ToNode: 250061655.0,
        Hydroseq: 250031102.0,
        LevelPathI: 250029939.0,
        Pathlength: 689.992,
        TerminalPa: 250002604.0,
        ArbolateSu: 80.586,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250029939.0,
        UpHydroseq: 250032402.0,
        DnLevelPat: 250029939.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029939.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4446,
        TotDASqKM: 71.2863,
        DivDASqKM: 71.2863,
        Tidal: 0,
        TOTMA: 0.025589991103,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 41996.0,
        MAXELEVSMO: 42423.0,
        MINELEVSMO: 42200.0,
        SLOPE: 0.00296937,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.75099998712539695,
        QA_MA: 49.459,
        VA_MA: 1.11178,
        QC_MA: 51.483,
        VC_MA: 1.1144,
        QE_MA: 51.483,
        VE_MA: 1.1144,
        QA_01: 77.243,
        VA_01: 1.32636,
        QC_01: 62.507,
        VC_01: 1.20187,
        QE_01: 62.507,
        VE_01: 1.20187,
        QA_02: 82.114,
        VA_02: 1.35996,
        QC_02: 70.944,
        VC_02: 1.264,
        QE_02: 70.944,
        VE_02: 1.264,
        QA_03: 93.975,
        VA_03: 1.43806,
        QC_03: 82.365,
        VC_03: 1.34283,
        QE_03: 82.365,
        VE_03: 1.34283,
        QA_04: 70.217,
        VA_04: 1.2761,
        QC_04: 66.713,
        VC_04: 1.2333,
        QE_04: 66.713,
        VE_04: 1.2333,
        QA_05: 57.685,
        VA_05: 1.18017,
        QC_05: 56.289,
        VC_05: 1.15351,
        QE_05: 56.289,
        VE_05: 1.15351,
        QA_06: 31.465,
        VA_06: 0.94026,
        QC_06: 40.046,
        VC_06: 1.01374,
        QE_06: 40.046,
        VE_06: 1.01374,
        QA_07: 18.488,
        VA_07: 0.78482,
        QC_07: 28.761,
        VC_07: 0.90004,
        QE_07: 28.761,
        VE_07: 0.90004,
        QA_08: 13.321,
        VA_08: 0.70871,
        QC_08: 29.103,
        VC_08: 0.90377,
        QE_08: 29.103,
        VE_08: 0.90377,
        QA_09: 16.067,
        VA_09: 0.7506,
        QC_09: 45.403,
        VC_09: 1.06237,
        QE_09: 45.403,
        VE_09: 1.06237,
        QA_10: 23.756,
        VA_10: 0.85266,
        QC_10: 69.085,
        VC_10: 1.25062,
        QE_10: 69.085,
        VE_10: 1.25062,
        QA_11: 49.082,
        VA_11: 1.10853,
        QC_11: 74.469,
        VC_11: 1.28893,
        QE_11: 74.469,
        VE_11: 1.28893,
        QA_12: 60.437,
        VA_12: 1.20202,
        QC_12: 55.194,
        VC_12: 1.14474,
        QE_12: 55.194,
        VE_12: 1.14474,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-82.035454116437862, 35.795561077771595, 0.0],
          [-82.031716383110393, 35.795008811105731, 0.0],
          [-82.027891716449574, 35.796837877769576, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286251.0,
        COMID: 9745076,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.731,
        REACHCODE: "03050102000078",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0074267971131750004,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060151.0,
        ToNode: 250060150.0,
        Hydroseq: 250020923.0,
        LevelPathI: 250011553.0,
        Pathlength: 566.425,
        TerminalPa: 250002604.0,
        ArbolateSu: 214.294,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250021305.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020554.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4968,
        TotDASqKM: 228.9384,
        DivDASqKM: 228.9384,
        Tidal: 0,
        TOTMA: 0.037959184292500003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26693.0,
        MAXELEVSMO: 26955.0,
        MINELEVSMO: 26955.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.73100000619888295,
        QA_MA: 139.415,
        VA_MA: 0.72873,
        QC_MA: 139.4,
        VC_MA: 0.72873,
        QE_MA: 150.132,
        VE_MA: 0.73126,
        QA_01: 239.279,
        VA_01: 0.86837,
        QC_01: 187.976,
        VC_01: 0.80104,
        QE_01: 189.779,
        VE_01: 0.78729,
        QA_02: 250.021,
        VA_02: 0.88158,
        QC_02: 209.552,
        VC_02: 0.8303,
        QE_02: 205.017,
        VE_02: 0.80734,
        QA_03: 267.391,
        VA_03: 0.90239,
        QC_03: 226.404,
        VC_03: 0.85219,
        QE_03: 237.649,
        VE_03: 0.84805,
        QA_04: 199.474,
        VA_04: 0.8168,
        QC_04: 185.074,
        VC_04: 0.79699,
        QE_04: 211.423,
        VE_04: 0.81556,
        QA_05: 148.778,
        VA_05: 0.74349,
        QC_05: 140.12,
        VC_05: 0.72988,
        QE_05: 165.378,
        VE_05: 0.75354,
        QA_06: 86.899,
        VA_06: 0.63542,
        QC_06: 109.067,
        VC_06: 0.67738,
        QE_06: 136.482,
        VE_06: 0.71039,
        QA_07: 50.814,
        VA_07: 0.55434,
        QC_07: 77.159,
        VC_07: 0.61543,
        QE_07: 98.905,
        VE_07: 0.6472,
        QA_08: 35.117,
        VA_08: 0.51053,
        QC_08: 78.226,
        VC_08: 0.61768,
        QE_08: 95.589,
        VE_08: 0.64112,
        QA_09: 34.047,
        VA_09: 0.50723,
        QC_09: 88.11,
        VC_09: 0.63785,
        QE_09: 89.229,
        VE_09: 0.62918,
        QA_10: 50.034,
        VA_10: 0.55233,
        QC_10: 125.256,
        VC_10: 0.70551,
        QE_10: 115.228,
        VE_10: 0.67583,
        QA_11: 127.958,
        VA_11: 0.71002,
        QC_11: 154.647,
        VC_11: 0.75254,
        QE_11: 120.567,
        VE_11: 0.68477,
        QA_12: 184.034,
        VA_12: 0.79551,
        QC_12: 152.012,
        VC_12: 0.74851,
        QE_12: 138.817,
        VE_12: 0.71403,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.390701184105353, 35.697979677923058, 0.0],
          [-81.387613517443413, 35.701552811250792, 0.0],
          [-81.385114717447266, 35.701644677917216, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 289351.0,
        COMID: 9713473,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1227194",
        GNIS_NAME: "Tranham Creek",
        LENGTHKM: 1.547,
        REACHCODE: "03050104000515",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015738011321440998,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250057083.0,
        ToNode: 250057063.0,
        Hydroseq: 250039791.0,
        LevelPathI: 250039791.0,
        Pathlength: 361.321,
        TerminalPa: 250002604.0,
        ArbolateSu: 27.939,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250039791.0,
        UpHydroseq: 250050360.0,
        DnLevelPat: 250021319.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250037533.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7109,
        TotDASqKM: 28.4094,
        DivDASqKM: 28.4094,
        Tidal: 0,
        TOTMA: 0.063134090558200004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9196.0,
        MAXELEVSMO: 9759.0,
        MINELEVSMO: 9314.0,
        SLOPE: 0.00287653,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.547000050544739,
        QA_MA: 10.985,
        VA_MA: 0.92551,
        QC_MA: 12.122,
        VC_MA: 0.93046,
        QE_MA: 12.122,
        VE_MA: 0.93046,
        QA_01: 24.417,
        VA_01: 1.25152,
        QC_01: 20.364,
        VC_01: 1.12774,
        QE_01: 20.364,
        VE_01: 1.12774,
        QA_02: 24.976,
        VA_02: 1.26293,
        QC_02: 22.29,
        VC_02: 1.16801,
        QE_02: 22.29,
        VE_02: 1.16801,
        QA_03: 26.65,
        VA_03: 1.29638,
        QC_03: 24.349,
        VC_03: 1.2093,
        QE_03: 24.349,
        VE_03: 1.2093,
        QA_04: 15.857,
        VA_04: 1.05833,
        QC_04: 15.584,
        VC_04: 1.01923,
        QE_04: 15.584,
        VE_04: 1.01923,
        QA_05: 8.795,
        VA_05: 0.85679,
        QC_05: 9.208,
        VC_05: 0.84595,
        QE_05: 9.208,
        VE_05: 0.84595,
        QA_06: 5.611,
        VA_06: 0.74035,
        QC_06: 7.312,
        VC_06: 0.78401,
        QE_06: 7.312,
        VE_06: 0.78401,
        QA_07: 4.202,
        VA_07: 0.67887,
        QC_07: 6.772,
        VC_07: 0.76502,
        QE_07: 6.772,
        VE_07: 0.76502,
        QA_08: 3.056,
        VA_08: 0.62123,
        QC_08: 6.482,
        VC_08: 0.75453,
        QE_08: 6.482,
        VE_08: 0.75453,
        QA_09: 2.354,
        VA_09: 0.58075,
        QC_09: 8.331,
        VC_09: 0.81813,
        QE_09: 8.331,
        VE_09: 0.81813,
        QA_10: 1.91,
        VA_10: 0.55213,
        QC_10: 9.223,
        VC_10: 0.84642,
        QE_10: 9.223,
        VE_10: 0.84642,
        QA_11: 1.962,
        VA_11: 0.55564,
        QC_11: 6.392,
        VC_11: 0.75123,
        QE_11: 6.392,
        VE_11: 0.75123,
        QA_12: 12.045,
        VA_12: 0.95645,
        QC_12: 12.722,
        VC_12: 0.94662,
        QE_12: 12.722,
        VE_12: 0.94662,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.694505585185937, 34.517719213088242, 0.0],
          [-80.700065785177287, 34.515196013092236, 0.0],
          [-80.700838318509454, 34.512469613096528, 0.0],
          [-80.706231718501044, 34.50983167976716, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286252.0,
        COMID: 9745122,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 2.607,
        REACHCODE: "03050102000079",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026298720045959002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060162.0,
        ToNode: 250060151.0,
        Hydroseq: 250021305.0,
        LevelPathI: 250011553.0,
        Pathlength: 567.156,
        TerminalPa: 250002604.0,
        ArbolateSu: 211.103,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250021706.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020923.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.3289,
        TotDASqKM: 221.7366,
        DivDASqKM: 221.7366,
        Tidal: 0,
        TOTMA: 0.077808968584700003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26777.0,
        MAXELEVSMO: 27439.0,
        MINELEVSMO: 26955.0,
        SLOPE: 0.00185654,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.6070001125335689,
        QA_MA: 135.731,
        VA_MA: 1.26629,
        QC_MA: 135.858,
        VC_MA: 1.26636,
        QE_MA: 146.59,
        VE_MA: 1.27228,
        QA_01: 232.606,
        VA_01: 1.58349,
        QC_01: 182.869,
        VC_01: 1.4301,
        QE_01: 184.672,
        VE_01: 1.39804,
        QA_02: 242.884,
        VA_02: 1.6131,
        QC_02: 203.73,
        VC_02: 1.49633,
        QE_02: 199.196,
        VE_02: 1.44273,
        QA_03: 259.617,
        VA_03: 1.66008,
        QC_03: 220.036,
        VC_03: 1.5459,
        QE_03: 231.281,
        VE_03: 1.53635,
        QA_04: 193.972,
        VA_04: 1.46625,
        QC_04: 180.084,
        VC_04: 1.421,
        QE_04: 206.432,
        VE_04: 1.46443,
        QA_05: 144.877,
        VA_05: 1.30004,
        QC_05: 136.582,
        VC_05: 1.26907,
        QE_05: 161.84,
        VE_05: 1.32429,
        QA_06: 84.676,
        VA_06: 1.054,
        QC_06: 106.314,
        VC_06: 1.14941,
        QE_06: 133.729,
        VE_06: 1.22641,
        QA_07: 49.462,
        VA_07: 0.86882,
        QC_07: 75.155,
        VC_07: 1.00794,
        QE_07: 96.901,
        VE_07: 1.08208,
        QA_08: 34.245,
        VA_08: 0.76943,
        QC_08: 76.246,
        VC_08: 1.01332,
        QE_08: 93.609,
        VE_08: 1.06801,
        QA_09: 33.353,
        VA_09: 0.76301,
        QC_09: 86.523,
        VC_09: 1.06227,
        QE_09: 87.642,
        VE_09: 1.0419,
        QA_10: 49.453,
        VA_10: 0.86877,
        QC_10: 124.093,
        VC_10: 1.22138,
        QE_10: 114.065,
        VE_10: 1.15206,
        QA_11: 125.289,
        VA_11: 1.22643,
        QC_11: 152.181,
        VC_11: 1.32585,
        QE_11: 118.101,
        VE_11: 1.16779,
        QA_12: 179.212,
        VA_12: 1.4186,
        QC_12: 148.384,
        VC_12: 1.31229,
        QE_12: 135.189,
        VE_12: 1.23172,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.402936717419607, 35.683961277944832, 0.0],
          [-81.394095117433324, 35.686482411274142, 0.0],
          [-81.393450584101004, 35.690696411267709, 0.0],
          [-81.388762717441693, 35.691888011265746, 0.0],
          [-81.391009317438147, 35.694613011261538, 0.0],
          [-81.390701184105353, 35.697979677923058, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286253.0,
        COMID: 9745124,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.813,
        REACHCODE: "03050102000080",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0083956069130719999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060163.0,
        ToNode: 250060162.0,
        Hydroseq: 250021706.0,
        LevelPathI: 250011553.0,
        Pathlength: 569.763,
        TerminalPa: 250002604.0,
        ArbolateSu: 205.436,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250022145.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021305.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2538,
        TotDASqKM: 211.1841,
        DivDASqKM: 211.1841,
        Tidal: 0,
        TOTMA: 0.025023744786200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 27228.0,
        MAXELEVSMO: 27568.0,
        MINELEVSMO: 27439.0,
        SLOPE: 0.00158671,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.81300002336502097,
        QA_MA: 130.247,
        VA_MA: 1.23282,
        QC_MA: 130.577,
        VC_MA: 1.23301,
        QE_MA: 131.79,
        VE_MA: 1.2337,
        QA_01: 222.697,
        VA_01: 1.53744,
        QC_01: 175.279,
        VC_01: 1.38947,
        QE_01: 175.483,
        VE_01: 1.38565,
        QA_02: 232.385,
        VA_02: 1.56555,
        QC_02: 195.159,
        VC_02: 1.45294,
        QE_02: 194.647,
        VE_02: 1.4466,
        QA_03: 248.197,
        VA_03: 1.61027,
        QC_03: 210.669,
        VC_03: 1.50037,
        QE_03: 211.94,
        VE_03: 1.49922,
        QA_04: 185.827,
        VA_04: 1.42481,
        QC_04: 172.69,
        VC_04: 1.38096,
        QE_04: 175.667,
        VE_04: 1.38625,
        QA_05: 138.982,
        VA_05: 1.26524,
        QC_05: 131.229,
        VC_05: 1.23546,
        QE_05: 134.083,
        VE_05: 1.24222,
        QA_06: 81.301,
        VA_06: 1.02816,
        QC_06: 102.134,
        VC_06: 1.11993,
        QE_06: 105.232,
        VE_06: 1.12948,
        QA_07: 47.438,
        VA_07: 0.84909,
        QC_07: 72.151,
        VC_07: 0.98317,
        QE_07: 74.609,
        VE_07: 0.99243,
        QA_08: 32.896,
        VA_08: 0.75357,
        QC_08: 73.183,
        VC_08: 0.98828,
        QE_08: 75.145,
        VE_08: 0.99503,
        QA_09: 32.287,
        VA_09: 0.74917,
        QC_09: 84.078,
        VC_09: 1.04028,
        QE_09: 84.204,
        VE_09: 1.03782,
        QA_10: 48.589,
        VA_10: 0.85601,
        QC_10: 122.356,
        VC_10: 1.20163,
        QE_10: 121.223,
        VE_10: 1.19354,
        QA_11: 121.142,
        VA_11: 1.19792,
        QC_11: 148.324,
        VC_11: 1.29774,
        QE_11: 144.474,
        VE_11: 1.27997,
        QA_12: 171.983,
        VA_12: 1.37985,
        QC_12: 142.928,
        VC_12: 1.27847,
        QE_12: 141.437,
        VE_12: 1.26907,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.409673184075928, 35.683089077946022, 0.0],
          [-81.405716317415283, 35.685563611275541, 0.0],
          [-81.402936717419607, 35.683961277944832, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286254.0,
        COMID: 9745126,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 4.82,
        REACHCODE: "03050102000081",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.047918902673828999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060164.0,
        ToNode: 250060163.0,
        Hydroseq: 250022145.0,
        LevelPathI: 250011553.0,
        Pathlength: 570.576,
        TerminalPa: 250002604.0,
        ArbolateSu: 203.012,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250022615.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021706.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.1316,
        TotDASqKM: 209.6811,
        DivDASqKM: 209.6811,
        Tidal: 0,
        TOTMA: 0.13887140762799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 27320.0,
        MAXELEVSMO: 28904.0,
        MINELEVSMO: 27568.0,
        SLOPE: 0.00277178,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.820000171661377,
        QA_MA: 129.459,
        VA_MA: 1.31699,
        QC_MA: 129.818,
        VC_MA: 1.31722,
        QE_MA: 131.022,
        VE_MA: 1.31797,
        QA_01: 221.277,
        VA_01: 1.64911,
        QC_01: 174.191,
        VC_01: 1.48768,
        QE_01: 174.393,
        VE_01: 1.48351,
        QA_02: 230.89,
        VA_02: 1.67973,
        QC_02: 193.938,
        VC_02: 1.55688,
        QE_02: 193.429,
        VE_02: 1.54997,
        QA_03: 246.562,
        VA_03: 1.72839,
        QC_03: 209.327,
        VC_03: 1.60854,
        QE_03: 210.589,
        VE_03: 1.60729,
        QA_04: 184.657,
        VA_04: 1.5263,
        QC_04: 171.628,
        VC_04: 1.47843,
        QE_04: 174.584,
        VE_04: 1.4842,
        QA_05: 138.136,
        VA_05: 1.35234,
        QC_05: 130.459,
        VC_05: 1.31986,
        QE_05: 133.293,
        VE_05: 1.32723,
        QA_06: 80.818,
        VA_06: 1.09374,
        QC_06: 101.535,
        VC_06: 1.19381,
        QE_06: 104.611,
        VE_06: 1.20422,
        QA_07: 47.155,
        VA_07: 0.89834,
        QC_07: 71.731,
        VC_07: 1.04463,
        QE_07: 74.171,
        VE_07: 1.05471,
        QA_08: 32.696,
        VA_08: 0.79409,
        QC_08: 72.729,
        VC_08: 1.05005,
        QE_08: 74.677,
        VE_08: 1.05741,
        QA_09: 32.127,
        VA_09: 0.78959,
        QC_09: 83.71,
        VC_09: 1.10755,
        QE_09: 83.836,
        VE_09: 1.10487,
        QA_10: 48.467,
        VA_10: 0.907,
        QC_10: 122.112,
        VC_10: 1.28496,
        QE_10: 120.987,
        VE_10: 1.27615,
        QA_11: 120.528,
        VA_11: 1.27942,
        QC_11: 147.751,
        VC_11: 1.38897,
        QE_11: 143.927,
        VE_11: 1.36961,
        QA_12: 170.942,
        VA_12: 1.4774,
        QC_12: 142.141,
        VC_12: 1.36698,
        QE_12: 140.66,
        VE_12: 1.35675,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.437837984032171, 35.695787611259789, 0.0],
          [-81.434751317370285, 35.697781677923217, 0.0],
          [-81.432419117373854, 35.695034677927538, 0.0],
          [-81.427336584048476, 35.693662877929683, 0.0],
          [-81.425599317384524, 35.698587677922035, 0.0],
          [-81.421302184057822, 35.695978677926064, 0.0],
          [-81.419868584059998, 35.693070811263965, 0.0],
          [-81.417818917396573, 35.692544811264838, 0.0],
          [-81.415040584067583, 35.694263411262114, 0.0],
          [-81.415769117399805, 35.69158367793284, 0.0],
          [-81.409562117409394, 35.685562611275657, 0.0],
          [-81.409673184075928, 35.683089077946022, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286247.0,
        COMID: 9745080,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 2.877,
        REACHCODE: "03050102000074",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.029252051746837999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060152.0,
        ToNode: 250060153.0,
        Hydroseq: 250019510.0,
        LevelPathI: 250011553.0,
        Pathlength: 561.192,
        TerminalPa: 250002604.0,
        ArbolateSu: 228.477,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250019852.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019186.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.2774,
        TotDASqKM: 248.3622,
        DivDASqKM: 248.3622,
        Tidal: 0,
        TOTMA: 0.082373792697199993,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25589.0,
        MAXELEVSMO: 26417.0,
        MINELEVSMO: 25769.0,
        SLOPE: 0.00225234,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.877000093460083,
        QA_MA: 149.25,
        VA_MA: 1.32074,
        QC_MA: 148.84,
        VC_MA: 1.32051,
        QE_MA: 159.571,
        VE_MA: 1.32624,
        QA_01: 257.282,
        VA_01: 1.66018,
        QC_01: 201.735,
        VC_01: 1.49787,
        QE_01: 203.538,
        VE_01: 1.46665,
        QA_02: 269.234,
        VA_02: 1.69317,
        QC_02: 225.2,
        VC_02: 1.56944,
        QE_02: 220.665,
        VE_02: 1.51742,
        QA_03: 288.135,
        VA_03: 1.74397,
        QC_03: 243.367,
        VC_03: 1.62248,
        QE_03: 254.613,
        VE_03: 1.61287,
        QA_04: 214.188,
        VA_04: 1.5348,
        QC_04: 198.405,
        VC_04: 1.48741,
        QE_04: 224.754,
        VE_04: 1.52926,
        QA_05: 159.374,
        VA_05: 1.35664,
        QC_05: 149.714,
        VC_05: 1.32367,
        QE_05: 174.972,
        VE_05: 1.37727,
        QA_06: 92.958,
        VA_06: 1.0957,
        QC_06: 116.563,
        VC_06: 1.19731,
        QE_06: 143.979,
        VE_06: 1.27216,
        QA_07: 54.458,
        VA_07: 0.90108,
        QC_07: 82.555,
        VC_07: 1.04853,
        QE_07: 104.302,
        VE_07: 1.12053,
        QA_08: 37.52,
        VA_08: 0.7947,
        QC_08: 83.691,
        VC_08: 1.05392,
        QE_08: 101.054,
        VE_08: 1.107,
        QA_09: 35.908,
        VA_09: 0.78349,
        QC_09: 92.35,
        VC_09: 1.09393,
        QE_09: 93.469,
        VE_09: 1.07459,
        QA_10: 51.601,
        VA_10: 0.88436,
        QC_10: 128.38,
        VC_10: 1.24408,
        QE_10: 118.352,
        VE_10: 1.17689,
        QA_11: 134.546,
        VA_11: 1.26649,
        QC_11: 160.682,
        VC_11: 1.36251,
        QE_11: 126.602,
        VE_11: 1.20853,
        QA_12: 196.786,
        VA_12: 1.48084,
        QC_12: 161.566,
        VC_12: 1.36558,
        QE_12: 148.371,
        VE_12: 1.28766,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.361389317484111, 35.704782411245674, 0.0],
          [-81.356110717492356, 35.705056611245254, 0.0],
          [-81.353920984162414, 35.703636277914143, 0.0],
          [-81.352798384164203, 35.701094077918242, 0.0],
          [-81.355241584160296, 35.698575211255388, 0.0],
          [-81.354399584161683, 35.697292611257467, 0.0],
          [-81.351872184165529, 35.700155011252889, 0.0],
          [-81.347688517505446, 35.70125341125123, 0.0],
          [-81.345442317508912, 35.700886477918459, 0.0],
          [-81.344628917510192, 35.698756477921791, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286248.0,
        COMID: 9745054,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.99,
        REACHCODE: "03050102000075",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010645146601001999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060147.0,
        ToNode: 250060152.0,
        Hydroseq: 250019852.0,
        LevelPathI: 250011553.0,
        Pathlength: 564.069,
        TerminalPa: 250002604.0,
        ArbolateSu: 221.914,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250020197.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019510.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.4427,
        TotDASqKM: 234.5814,
        DivDASqKM: 234.5814,
        Tidal: 0,
        TOTMA: 0.0277140193559,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26366.0,
        MAXELEVSMO: 26706.0,
        MINELEVSMO: 26417.0,
        SLOPE: 0.00291919,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.99000000953674305,
        QA_MA: 142.254,
        VA_MA: 1.35038,
        QC_MA: 142.127,
        VC_MA: 1.3503,
        QE_MA: 152.859,
        VE_MA: 1.35646,
        QA_01: 244.484,
        VA_01: 1.69753,
        QC_01: 191.957,
        VC_01: 1.53058,
        QE_01: 193.76,
        VE_01: 1.49708,
        QA_02: 255.59,
        VA_02: 1.73069,
        QC_02: 214.091,
        VC_02: 1.6035,
        QE_02: 209.556,
        VE_02: 1.54759,
        QA_03: 273.416,
        VA_03: 1.78253,
        QC_03: 231.335,
        VC_03: 1.65788,
        QE_03: 242.581,
        VE_03: 1.64773,
        QA_04: 203.725,
        VA_04: 1.56931,
        QC_04: 188.928,
        VC_04: 1.52031,
        QE_04: 215.276,
        VE_04: 1.56544,
        QA_05: 151.81,
        VA_05: 1.38698,
        QC_05: 142.868,
        VC_05: 1.35318,
        QE_05: 168.126,
        VE_05: 1.4108,
        QA_06: 88.627,
        VA_06: 1.11887,
        QC_06: 111.205,
        VC_06: 1.22303,
        QE_06: 138.62,
        VE_06: 1.30343,
        QA_07: 51.858,
        VA_07: 0.91814,
        QC_07: 78.706,
        VC_07: 1.06966,
        QE_07: 100.453,
        VE_07: 1.14703,
        QA_08: 35.799,
        VA_08: 0.80922,
        QC_08: 79.778,
        VC_08: 1.07515,
        QE_08: 97.141,
        VE_08: 1.13223,
        QA_09: 34.578,
        VA_09: 0.80007,
        QC_09: 89.323,
        VC_09: 1.12257,
        QE_09: 90.442,
        VE_09: 1.10156,
        QA_10: 50.49,
        VA_10: 0.90954,
        QC_10: 126.165,
        VC_10: 1.28643,
        QE_10: 116.138,
        VE_10: 1.21419,
        QA_11: 129.861,
        VA_11: 1.30114,
        QC_11: 156.398,
        VC_11: 1.40461,
        QE_11: 122.318,
        VE_11: 1.23948,
        QA_12: 187.739,
        VA_12: 1.51576,
        QC_12: 154.794,
        VC_12: 1.39862,
        QE_12: 141.599,
        VE_12: 1.31473,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.371188584135666, 35.703248411248069, 0.0],
          [-81.368464984139848, 35.705286611244958, 0.0],
          [-81.361389317484111, 35.704782411245674, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286249.0,
        COMID: 9745058,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.405,
        REACHCODE: "03050102000076",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0043906284669209998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060148.0,
        ToNode: 250060147.0,
        Hydroseq: 250020197.0,
        LevelPathI: 250011553.0,
        Pathlength: 565.059,
        TerminalPa: 250002604.0,
        ArbolateSu: 218.7,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250020554.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019852.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1449,
        TotDASqKM: 231.5385,
        DivDASqKM: 231.5385,
        Tidal: 0,
        TOTMA: 0.012286833121799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26525.0,
        MAXELEVSMO: 26768.0,
        MINELEVSMO: 26706.0,
        SLOPE: 0.00153086,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40500000119209301,
        QA_MA: 140.72,
        VA_MA: 1.24609,
        QC_MA: 140.655,
        VC_MA: 1.24606,
        QE_MA: 151.386,
        VE_MA: 1.25166,
        QA_01: 241.673,
        VA_01: 1.55802,
        QC_01: 189.808,
        VC_01: 1.40781,
        QE_01: 191.611,
        VE_01: 1.37738,
        QA_02: 252.584,
        VA_02: 1.58767,
        QC_02: 211.641,
        VC_02: 1.47324,
        QE_02: 207.106,
        VE_02: 1.42245,
        QA_03: 270.164,
        VA_03: 1.6342,
        QC_03: 228.674,
        VC_03: 1.52212,
        QE_03: 239.92,
        VE_03: 1.51294,
        QA_04: 201.43,
        VA_04: 1.44282,
        QC_04: 186.848,
        VC_04: 1.39867,
        QE_04: 213.197,
        VE_04: 1.43973,
        QA_05: 150.16,
        VA_05: 1.27899,
        QC_05: 141.373,
        VC_05: 1.2486,
        QE_05: 166.631,
        VE_05: 1.30096,
        QA_06: 87.687,
        VA_06: 1.03783,
        QC_06: 110.041,
        VC_06: 1.13147,
        QE_06: 137.457,
        VE_06: 1.20453,
        QA_07: 51.286,
        VA_07: 0.85704,
        QC_07: 77.859,
        VC_07: 0.99334,
        QE_07: 99.606,
        VE_07: 1.06366,
        QA_08: 35.431,
        VA_08: 0.75922,
        QC_08: 78.941,
        VC_08: 0.99838,
        QE_08: 96.304,
        VE_08: 1.05026,
        QA_09: 34.291,
        VA_09: 0.75145,
        QC_09: 88.669,
        VC_09: 1.04231,
        QE_09: 89.788,
        VE_09: 1.02317,
        QA_10: 50.244,
        VA_10: 0.85109,
        QC_10: 125.675,
        VC_10: 1.19163,
        QE_10: 115.648,
        VE_10: 1.12598,
        QA_11: 128.826,
        VA_11: 1.20314,
        QC_11: 155.447,
        VC_11: 1.29719,
        QE_11: 121.367,
        VE_11: 1.14721,
        QA_12: 185.739,
        VA_12: 1.395,
        QC_12: 153.293,
        VC_12: 1.28989,
        QE_12: 140.098,
        VE_12: 1.21363,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.37534391746243, 35.70187441125023, 0.0],
          [-81.371188584135666, 35.703248411248069, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286250.0,
        COMID: 9745070,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.961,
        REACHCODE: "03050102000077",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010401962164571,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060150.0,
        ToNode: 250060148.0,
        Hydroseq: 250020554.0,
        LevelPathI: 250011553.0,
        Pathlength: 565.464,
        TerminalPa: 250002604.0,
        ArbolateSu: 216.631,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250020923.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020197.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9828,
        TotDASqKM: 230.5071,
        DivDASqKM: 230.5071,
        Tidal: 0,
        TOTMA: 0.028344869135700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26581.0,
        MAXELEVSMO: 26955.0,
        MINELEVSMO: 26768.0,
        SLOPE: 0.00194588,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.96100002527236905,
        QA_MA: 140.202,
        VA_MA: 1.28161,
        QC_MA: 140.157,
        VC_MA: 1.28159,
        QE_MA: 150.888,
        VE_MA: 1.28742,
        QA_01: 240.723,
        VA_01: 1.60518,
        QC_01: 189.081,
        VC_01: 1.44929,
        QE_01: 190.884,
        VE_01: 1.41761,
        QA_02: 251.565,
        VA_02: 1.63588,
        QC_02: 210.81,
        VC_02: 1.51713,
        QE_02: 206.275,
        VE_02: 1.46424,
        QA_03: 269.061,
        VA_03: 1.68413,
        QC_03: 227.771,
        VC_03: 1.56784,
        QE_03: 239.017,
        VE_03: 1.55829,
        QA_04: 200.654,
        VA_04: 1.48568,
        QC_04: 186.144,
        VC_04: 1.43985,
        QE_04: 212.493,
        VE_04: 1.48261,
        QA_05: 149.612,
        VA_05: 1.31577,
        QC_05: 140.877,
        VC_05: 1.28424,
        QE_05: 166.134,
        VE_05: 1.33876,
        QA_06: 87.375,
        VA_06: 1.06551,
        QC_06: 109.655,
        VC_06: 1.16268,
        QE_06: 137.07,
        VE_06: 1.23874,
        QA_07: 51.099,
        VA_07: 0.87783,
        QC_07: 77.582,
        VC_07: 1.0193,
        QE_07: 99.328,
        VE_07: 1.0925,
        QA_08: 35.306,
        VA_08: 0.77633,
        QC_08: 78.657,
        VC_08: 1.02451,
        QE_08: 96.02,
        VE_08: 1.07852,
        QA_09: 34.194,
        VA_09: 0.76845,
        QC_09: 88.447,
        VC_09: 1.07053,
        QE_09: 89.566,
        VE_09: 1.05059,
        QA_10: 50.161,
        VA_10: 0.87226,
        QC_10: 125.509,
        VC_10: 1.22618,
        QE_10: 115.481,
        VE_10: 1.15781,
        QA_11: 128.481,
        VA_11: 1.23753,
        QC_11: 155.129,
        VC_11: 1.33547,
        QE_11: 121.048,
        VE_11: 1.17932,
        QA_12: 185.061,
        VA_12: 1.43618,
        QC_12: 152.783,
        VC_12: 1.3272,
        QE_12: 139.589,
        VE_12: 1.24778,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.385114717447266, 35.701644677917216, 0.0],
          [-81.379134184123302, 35.700408411252567, 0.0],
          [-81.37534391746243, 35.70187441125023, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285234.0,
        COMID: 9752620,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.358,
        REACHCODE: "03050101001542",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0033890490477200001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250061092.0,
        ToNode: 250061107.0,
        Hydroseq: 250022611.0,
        LevelPathI: 250022611.0,
        Pathlength: 679.485,
        TerminalPa: 250002604.0,
        ArbolateSu: 126.947,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019506.0,
        UpHydroseq: 250023108.0,
        DnLevelPat: 250019506.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021702.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0414,
        TotDASqKM: 159.5268,
        DivDASqKM: 0.0414,
        Tidal: 0,
        TOTMA: 0.027291057748299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 37750.0,
        MAXELEVSMO: 38123.0,
        MINELEVSMO: 37750.0,
        SLOPE: 0.01801932,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.207000002264977,
        QA_MA: 0.023,
        VA_MA: 0.49812,
        QC_MA: 0.023,
        VC_MA: 0.49812,
        QE_MA: 0.023,
        VE_MA: 0.49812,
        QA_01: 0.04,
        VA_01: 0.56293,
        QC_01: 0.04,
        VC_01: 0.56293,
        QE_01: 0.04,
        VE_01: 0.56293,
        QA_02: 0.042,
        VA_02: 0.56961,
        QC_02: 0.042,
        VC_02: 0.56961,
        QE_02: 0.042,
        VE_02: 0.56961,
        QA_03: 0.047,
        VA_03: 0.58569,
        QC_03: 0.047,
        VC_03: 0.58569,
        QE_03: 0.047,
        VE_03: 0.58569,
        QA_04: 0.035,
        VA_04: 0.54551,
        QC_04: 0.035,
        VC_04: 0.54551,
        QE_04: 0.035,
        VE_04: 0.54551,
        QA_05: 0.027,
        VA_05: 0.51499,
        QC_05: 0.027,
        VC_05: 0.51499,
        QE_05: 0.027,
        VE_05: 0.51499,
        QA_06: 0.014,
        VA_06: 0.45416,
        QC_06: 0.014,
        VC_06: 0.45416,
        QE_06: 0.014,
        VE_06: 0.45416,
        QA_07: 0.008,
        VA_07: 0.41669,
        QC_07: 0.008,
        VC_07: 0.41669,
        QE_07: 0.008,
        VE_07: 0.41669,
        QA_08: 0.006,
        VA_08: 0.40134,
        QC_08: 0.006,
        VC_08: 0.40134,
        QE_08: 0.006,
        VE_08: 0.40134,
        QA_09: 0.004,
        VA_09: 0.38334,
        QC_09: 0.004,
        VC_09: 0.38334,
        QE_09: 0.004,
        VE_09: 0.38334,
        QA_10: 0.003,
        VA_10: 0.37272,
        QC_10: 0.003,
        VC_10: 0.37272,
        QE_10: 0.003,
        VE_10: 0.37272,
        QA_11: 0.02,
        VA_11: 0.48455,
        QC_11: 0.02,
        VC_11: 0.48455,
        QE_11: 0.02,
        VE_11: 0.48455,
        QA_12: 0.03,
        VA_12: 0.52687,
        QC_12: 0.03,
        VC_12: 0.52687,
        QE_12: 0.03,
        VE_12: 0.52687,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.90116651664637, 35.8073368110866, 0.0],
          [-81.899619383315382, 35.804612077757497, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286259.0,
        COMID: 9745138,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.507,
        REACHCODE: "03050102000086",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01640179137989,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060167.0,
        ToNode: 250060165.0,
        Hydroseq: 250024863.0,
        LevelPathI: 250011553.0,
        Pathlength: 580.508,
        TerminalPa: 250002604.0,
        ArbolateSu: 173.45,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250025539.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024239.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.07,
        TotDASqKM: 172.0656,
        DivDASqKM: 172.0656,
        Tidal: 0,
        TOTMA: 0.081284122974400005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29451.0,
        MAXELEVSMO: 29975.0,
        MINELEVSMO: 29975.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5069999694824221,
        QA_MA: 109.45,
        VA_MA: 0.70343,
        QC_MA: 110.471,
        VC_MA: 0.70373,
        QE_MA: 111.459,
        VE_MA: 0.70401,
        QA_01: 185.57,
        VA_01: 0.83126,
        QC_01: 146.757,
        VC_01: 0.76808,
        QE_01: 146.923,
        VE_01: 0.76652,
        QA_02: 193.073,
        VA_02: 0.84238,
        QC_02: 162.966,
        VC_02: 0.79438,
        QE_02: 162.548,
        VE_02: 0.79177,
        QA_03: 205.487,
        VA_03: 0.86035,
        QC_03: 175.508,
        VC_03: 0.81389,
        QE_03: 176.543,
        VE_03: 0.81345,
        QA_04: 154.665,
        VA_04: 0.78305,
        QC_04: 144.332,
        VC_04: 0.76403,
        QE_04: 146.758,
        VE_04: 0.76624,
        QA_05: 116.685,
        VA_05: 0.71709,
        QC_05: 110.898,
        VC_05: 0.70454,
        QE_05: 113.224,
        VE_05: 0.70733,
        QA_06: 68.598,
        VA_06: 0.61664,
        QC_06: 86.377,
        VC_06: 0.65531,
        QE_06: 88.9,
        VE_06: 0.65925,
        QA_07: 39.926,
        VA_07: 0.53964,
        QC_07: 60.977,
        VC_07: 0.59674,
        QE_07: 62.979,
        VE_07: 0.60056,
        QA_08: 27.727,
        VA_08: 0.49894,
        QC_08: 61.473,
        VC_08: 0.59799,
        QE_08: 63.072,
        VE_08: 0.60079,
        QA_09: 28.121,
        VA_09: 0.50037,
        QC_09: 74.424,
        VC_09: 0.62893,
        QE_09: 74.527,
        VE_09: 0.62788,
        QA_10: 45.201,
        VA_10: 0.55539,
        QC_10: 115.493,
        VC_10: 0.71317,
        QE_10: 114.57,
        VE_10: 0.70984,
        QA_11: 104.525,
        VA_11: 0.69389,
        QC_11: 132.54,
        VC_11: 0.74387,
        QE_11: 129.403,
        VE_11: 0.73665,
        QA_12: 144.498,
        VA_12: 0.76622,
        QC_12: 121.987,
        VC_12: 0.7251,
        QE_12: 120.772,
        VE_12: 0.72123,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.494579783944118, 35.67934587795196, 0.0],
          [-81.479089717301406, 35.682223211280707, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286260.0,
        COMID: 9745886,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.194,
        REACHCODE: "03050102000087",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013179695479544,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060382.0,
        ToNode: 250060167.0,
        Hydroseq: 250025539.0,
        LevelPathI: 250011553.0,
        Pathlength: 582.015,
        TerminalPa: 250002604.0,
        ArbolateSu: 160.609,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250026282.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024863.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2141,
        TotDASqKM: 158.4054,
        DivDASqKM: 158.4054,
        Tidal: 0,
        TOTMA: 0.048094733617300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29548.0,
        MAXELEVSMO: 30001.0,
        MINELEVSMO: 29975.0,
        SLOPE: 0.00021775,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1940000057220459,
        QA_MA: 102.075,
        VA_MA: 0.94164,
        QC_MA: 103.307,
        VC_MA: 0.94226,
        QE_MA: 104.216,
        VE_MA: 0.94271,
        QA_01: 172.373,
        VA_01: 1.14478,
        QC_01: 136.585,
        VC_01: 1.04358,
        QE_01: 136.737,
        VE_01: 1.04112,
        QA_02: 179.21,
        VA_02: 1.16224,
        QC_02: 151.572,
        VC_02: 1.08537,
        QE_02: 151.187,
        VE_02: 1.08127,
        QA_03: 190.491,
        VA_03: 1.19037,
        QC_03: 163.107,
        VC_03: 1.11622,
        QE_03: 164.061,
        VE_03: 1.11554,
        QA_04: 143.671,
        VA_04: 1.06768,
        QC_04: 134.298,
        VC_04: 1.03702,
        QE_04: 136.531,
        VE_04: 1.04053,
        QA_05: 108.699,
        VA_05: 0.96314,
        QC_05: 103.583,
        VC_05: 0.94316,
        QE_05: 105.724,
        VE_05: 0.94757,
        QA_06: 64.095,
        VA_06: 0.803,
        QC_06: 80.782,
        VC_06: 0.86465,
        QE_06: 83.106,
        VE_06: 0.87087,
        QA_07: 37.23,
        VA_07: 0.67906,
        QC_07: 56.955,
        VC_07: 0.77043,
        QE_07: 58.798,
        VE_07: 0.77647,
        QA_08: 25.89,
        VA_08: 0.61403,
        QC_08: 57.322,
        VC_08: 0.77201,
        QE_08: 58.794,
        VE_08: 0.77645,
        QA_09: 26.652,
        VA_09: 0.61878,
        QC_09: 70.98,
        VC_09: 0.82773,
        QE_09: 71.075,
        VE_09: 0.82605,
        QA_10: 44.099,
        VA_10: 0.71393,
        QC_10: 113.238,
        VC_10: 0.97392,
        QE_10: 112.388,
        VE_10: 0.96865,
        QA_11: 98.43,
        VA_11: 0.92953,
        QC_11: 126.604,
        VC_11: 1.01454,
        QE_11: 123.716,
        VE_11: 1.0032,
        QA_12: 134.665,
        VA_12: 1.04202,
        QC_12: 114.41,
        VC_12: 0.97757,
        QE_12: 113.292,
        VE_12: 0.97147,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.507523317257323, 35.677541877954752, 0.0],
          [-81.494579783944118, 35.67934587795196, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286261.0,
        COMID: 9745158,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 3.058,
        REACHCODE: "03050102000088",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.032468107493749003,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060175.0,
        ToNode: 250060382.0,
        Hydroseq: 250026282.0,
        LevelPathI: 250011553.0,
        Pathlength: 583.209,
        TerminalPa: 250002604.0,
        ArbolateSu: 143.531,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250027079.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025539.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.1993,
        TotDASqKM: 142.7319,
        DivDASqKM: 142.7319,
        Tidal: 0,
        TOTMA: 0.099079748656500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29786.0,
        MAXELEVSMO: 30535.0,
        MINELEVSMO: 30001.0,
        SLOPE: 0.00174623,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0580000877380371,
        QA_MA: 93.356,
        VA_MA: 1.17031,
        QC_MA: 94.81,
        VC_MA: 1.17139,
        QE_MA: 95.63,
        VE_MA: 1.17199,
        QA_01: 156.88,
        VA_01: 1.44384,
        QC_01: 124.615,
        VC_01: 1.3062,
        QE_01: 124.753,
        VE_01: 1.30293,
        QA_02: 163.013,
        VA_02: 1.4672,
        QC_02: 138.23,
        VC_02: 1.36268,
        QE_02: 137.883,
        VE_02: 1.3572,
        QA_03: 173.079,
        VA_03: 1.50466,
        QC_03: 148.668,
        VC_03: 1.40423,
        QE_03: 149.527,
        VE_03: 1.40334,
        QA_04: 130.645,
        VA_04: 1.3387,
        QC_04: 122.385,
        VC_04: 1.29668,
        QE_04: 124.398,
        VE_04: 1.30142,
        QA_05: 99.265,
        VA_05: 1.19886,
        QC_05: 94.915,
        VC_05: 1.1719,
        QE_05: 96.844,
        VE_05: 1.1778,
        QA_06: 58.775,
        VA_06: 0.98255,
        QC_06: 74.165,
        VC_06: 1.06588,
        QE_06: 76.259,
        VE_06: 1.07419,
        QA_07: 34.096,
        VA_07: 0.81327,
        QC_07: 52.27,
        VC_07: 0.93745,
        QE_07: 53.931,
        VE_07: 0.94551,
        QA_08: 23.777,
        VA_08: 0.72532,
        QC_08: 52.553,
        VC_08: 0.93925,
        QE_08: 53.879,
        VE_08: 0.94518,
        QA_09: 24.966,
        VA_09: 0.73626,
        QC_09: 67.001,
        VC_09: 1.0261,
        QE_09: 67.087,
        VE_09: 1.02381,
        QA_10: 42.52,
        VA_10: 0.87607,
        QC_10: 109.987,
        VC_10: 1.24219,
        QE_10: 109.221,
        VE_10: 1.23513,
        QA_11: 90.84,
        VA_11: 1.1579,
        QC_11: 119.088,
        VC_11: 1.28245,
        QE_11: 116.486,
        VE_11: 1.26737,
        QA_12: 122.94,
        VA_12: 1.30597,
        QC_12: 105.31,
        VC_12: 1.22089,
        QE_12: 104.302,
        VE_12: 1.21273,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.536634183878789, 35.673076877961535, 0.0],
          [-81.533628383883411, 35.675593877957738, 0.0],
          [-81.521872383901723, 35.672378611296097, 0.0],
          [-81.507523317257323, 35.677541877954752, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286255.0,
        COMID: 9745106,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.34,
        REACHCODE: "03050102000082",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012863106539465,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060156.0,
        ToNode: 250060164.0,
        Hydroseq: 250022615.0,
        LevelPathI: 250011553.0,
        Pathlength: 575.396,
        TerminalPa: 250002604.0,
        ArbolateSu: 195.491,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250023113.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022145.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0854,
        TotDASqKM: 199.6551,
        DivDASqKM: 199.6551,
        Tidal: 0,
        TOTMA: 0.038080961102600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28685.0,
        MAXELEVSMO: 29348.0,
        MINELEVSMO: 28904.0,
        SLOPE: 0.00331343,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3400000333786011,
        QA_MA: 124.245,
        VA_MA: 1.33507,
        QC_MA: 124.788,
        VC_MA: 1.33543,
        QE_MA: 125.935,
        VE_MA: 1.33619,
        QA_01: 211.924,
        VA_01: 1.67163,
        QC_01: 167.017,
        VC_01: 1.50735,
        QE_01: 167.209,
        VE_01: 1.50315,
        QA_02: 220.901,
        VA_02: 1.70199,
        QC_02: 185.771,
        VC_02: 1.57705,
        QE_02: 185.287,
        VE_02: 1.57009,
        QA_03: 235.689,
        VA_03: 1.75078,
        QC_03: 200.394,
        VC_03: 1.62914,
        QE_03: 201.596,
        VE_03: 1.62789,
        QA_04: 176.862,
        VA_04: 1.5468,
        QC_04: 164.544,
        VC_04: 1.49789,
        QE_04: 167.359,
        VE_04: 1.50372,
        QA_05: 132.602,
        VA_05: 1.37117,
        QC_05: 125.425,
        VC_05: 1.33821,
        QE_05: 128.123,
        VE_05: 1.34563,
        QA_06: 77.647,
        VA_06: 1.10828,
        QC_06: 97.605,
        VC_06: 1.20981,
        QE_06: 100.533,
        VE_06: 1.22031,
        QA_07: 45.268,
        VA_07: 0.909,
        QC_07: 68.928,
        VC_07: 1.05778,
        QE_07: 71.251,
        VE_07: 1.06795,
        QA_08: 31.438,
        VA_08: 0.80329,
        QC_08: 69.876,
        VC_08: 1.06324,
        QE_08: 71.731,
        VE_08: 1.07067,
        QA_09: 31.089,
        VA_09: 0.80037,
        QC_09: 81.317,
        VC_09: 1.12653,
        QE_09: 81.437,
        VE_09: 1.1238,
        QA_10: 47.659,
        VA_10: 0.92564,
        QC_10: 120.482,
        VC_10: 1.31646,
        QE_10: 119.411,
        VE_10: 1.30756,
        QA_11: 116.563,
        VA_11: 1.30085,
        QC_11: 144.029,
        VC_11: 1.41669,
        QE_11: 140.389,
        VE_11: 1.39723,
        QA_12: 164.092,
        VA_12: 1.49849,
        QC_12: 136.949,
        VC_12: 1.38742,
        QE_12: 135.539,
        VE_12: 1.37709,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.442686384024682, 35.685983011274914, 0.0],
          [-81.440806317360909, 35.686831411273488, 0.0],
          [-81.440893184027402, 35.689991811268726, 0.0],
          [-81.437387117366143, 35.693658011262983, 0.0],
          [-81.437837984032171, 35.695787611259789, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286256.0,
        COMID: 9745108,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 2.096,
        REACHCODE: "03050102000083",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.022182350918334998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060157.0,
        ToNode: 250060156.0,
        Hydroseq: 250023113.0,
        LevelPathI: 250011553.0,
        Pathlength: 576.736,
        TerminalPa: 250002604.0,
        ArbolateSu: 191.604,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250023654.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022615.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5119,
        TotDASqKM: 193.4649,
        DivDASqKM: 193.4649,
        Tidal: 0,
        TOTMA: 0.111451341468,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28842.0,
        MAXELEVSMO: 29348.0,
        MINELEVSMO: 29348.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.095999956130981,
        QA_MA: 120.981,
        VA_MA: 0.71365,
        QC_MA: 121.636,
        VC_MA: 0.71383,
        QE_MA: 122.747,
        VE_MA: 0.71413,
        QA_01: 206.088,
        VA_01: 0.84613,
        QC_01: 162.536,
        VC_01: 0.78129,
        QE_01: 162.723,
        VE_01: 0.77965,
        QA_02: 214.728,
        VA_02: 0.85799,
        QC_02: 180.719,
        VC_02: 0.80869,
        QE_02: 180.25,
        VE_02: 0.80596,
        QA_03: 228.952,
        VA_03: 0.87703,
        QC_03: 194.853,
        VC_03: 0.8291,
        QE_03: 196.017,
        VE_03: 0.82861,
        QA_04: 171.979,
        VA_04: 0.79687,
        QC_04: 160.103,
        VC_04: 0.77752,
        QE_04: 162.831,
        VE_04: 0.77982,
        QA_05: 129.106,
        VA_05: 0.72788,
        QC_05: 122.24,
        VC_05: 0.7149,
        QE_05: 124.855,
        VE_05: 0.71781,
        QA_06: 75.653,
        VA_06: 0.62423,
        QC_06: 95.133,
        VC_06: 0.66423,
        QE_06: 97.971,
        VE_06: 0.66835,
        QA_07: 44.099,
        VA_07: 0.54553,
        QC_07: 67.191,
        VC_07: 0.60423,
        QE_07: 69.442,
        VE_07: 0.60823,
        QA_08: 30.611,
        VA_08: 0.50374,
        QC_08: 68.002,
        VC_08: 0.60612,
        QE_08: 69.8,
        VE_08: 0.60904,
        QA_09: 30.423,
        VA_09: 0.5031,
        QC_09: 79.777,
        VC_09: 0.63247,
        QE_09: 79.893,
        VE_09: 0.6314,
        QA_10: 47.159,
        VA_10: 0.55413,
        QC_10: 119.472,
        VC_10: 0.70998,
        QE_10: 118.434,
        VE_10: 0.70649,
        QA_11: 113.984,
        VA_11: 0.70103,
        QC_11: 141.592,
        VC_11: 0.74789,
        QE_11: 138.065,
        VE_11: 0.74027,
        QA_12: 159.782,
        VA_12: 0.77816,
        QC_12: 133.672,
        VC_12: 0.73466,
        QE_12: 132.307,
        VE_12: 0.73061,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.46230791732745, 35.685901211275052, 0.0],
          [-81.454083384006935, 35.686067411274792, 0.0],
          [-81.450521717345794, 35.689573677935982, 0.0],
          [-81.442686384024682, 35.685983011274914, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286257.0,
        COMID: 9745112,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.061,
        REACHCODE: "03050102000084",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00060973663861,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060159.0,
        ToNode: 250060157.0,
        Hydroseq: 250023654.0,
        LevelPathI: 250011553.0,
        Pathlength: 578.832,
        TerminalPa: 250002604.0,
        ArbolateSu: 179.36,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250024239.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023113.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 181.9971,
        DivDASqKM: 181.9971,
        Tidal: 0,
        TOTMA: 0.0011311328363699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29243.0,
        MAXELEVSMO: 29975.0,
        MINELEVSMO: 29348.0,
        SLOPE: 0.10278688,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.061000000685452999,
        QA_MA: 114.739,
        VA_MA: 2.04549,
        QC_MA: 115.597,
        VC_MA: 2.04654,
        QE_MA: 116.642,
        VE_MA: 2.0478,
        QA_01: 195.028,
        VA_01: 2.61067,
        QC_01: 154.036,
        VC_01: 2.33276,
        QE_01: 154.211,
        VE_01: 2.32579,
        QA_02: 203.065,
        VA_02: 2.66058,
        QC_02: 171.164,
        VC_02: 2.44933,
        QE_02: 170.722,
        VE_02: 2.43775,
        QA_03: 216.333,
        VA_03: 2.74097,
        QC_03: 184.458,
        VC_03: 2.53607,
        QE_03: 185.553,
        VE_03: 2.53405,
        QA_04: 162.6,
        VA_04: 2.39876,
        QC_04: 151.565,
        VC_04: 2.31545,
        QE_04: 154.131,
        VE_04: 2.32523,
        QA_05: 122.372,
        VA_05: 2.10592,
        QC_05: 116.097,
        VC_05: 2.05052,
        QE_05: 118.556,
        VE_05: 2.0629,
        QA_06: 71.825,
        VA_06: 1.66296,
        QC_06: 90.383,
        VC_06: 1.83365,
        QE_06: 93.053,
        VE_06: 1.85115,
        QA_07: 41.843,
        VA_07: 1.32511,
        QC_07: 63.834,
        VC_07: 1.57646,
        QE_07: 65.951,
        VE_07: 1.59341,
        QA_08: 29.062,
        VA_08: 1.1462,
        QC_08: 64.494,
        VC_08: 1.58341,
        QE_08: 66.185,
        VE_08: 1.59583,
        QA_09: 29.19,
        VA_09: 1.14816,
        QC_09: 76.915,
        VC_09: 1.70841,
        QE_09: 77.024,
        VE_09: 1.7038,
        QA_10: 46.011,
        VA_10: 1.37769,
        QC_10: 117.143,
        VC_10: 2.05884,
        QE_10: 116.167,
        VE_10: 2.04404,
        QA_11: 108.781,
        VA_11: 1.99699,
        QC_11: 136.636,
        VC_11: 2.20792,
        QE_11: 133.317,
        VE_11: 2.1757,
        QA_12: 151.512,
        VA_12: 2.32181,
        QC_12: 127.362,
        VC_12: 2.13833,
        QE_12: 126.077,
        VE_12: 2.12115,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.462756583993496, 35.685511477942327, 0.0],
          [-81.46230791732745, 35.685901211275052, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286258.0,
        COMID: 9745128,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.615,
        REACHCODE: "03050102000085",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.01750118450434,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060165.0,
        ToNode: 250060159.0,
        Hydroseq: 250024239.0,
        LevelPathI: 250011553.0,
        Pathlength: 578.893,
        TerminalPa: 250002604.0,
        ArbolateSu: 177.203,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250024863.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023654.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7234,
        TotDASqKM: 178.1244,
        DivDASqKM: 178.1244,
        Tidal: 0,
        TOTMA: 0.086745907740500006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29199.0,
        MAXELEVSMO: 29975.0,
        MINELEVSMO: 29975.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6150000095367429,
        QA_MA: 112.687,
        VA_MA: 0.70641,
        QC_MA: 113.61,
        VC_MA: 0.70667,
        QE_MA: 114.632,
        VE_MA: 0.70696,
        QA_01: 191.357,
        VA_01: 0.83564,
        QC_01: 151.212,
        VC_01: 0.77197,
        QE_01: 151.384,
        VE_01: 0.77038,
        QA_02: 199.186,
        VA_02: 0.84698,
        QC_02: 167.983,
        VC_02: 0.79859,
        QE_02: 167.55,
        VE_02: 0.79595,
        QA_03: 212.116,
        VA_03: 0.86528,
        QC_03: 180.98,
        VC_03: 0.81838,
        QE_03: 182.051,
        VE_03: 0.81792,
        QA_04: 159.529,
        VA_04: 0.78709,
        QC_04: 148.766,
        VC_04: 0.76797,
        QE_04: 151.277,
        VE_04: 0.77021,
        QA_05: 120.16,
        VA_05: 0.72021,
        QC_05: 114.076,
        VC_05: 0.70754,
        QE_05: 116.483,
        VE_05: 0.71036,
        QA_06: 70.575,
        VA_06: 0.61884,
        QC_06: 88.832,
        VC_06: 0.65789,
        QE_06: 91.445,
        VE_06: 0.66189,
        QA_07: 41.102,
        VA_07: 0.54137,
        QC_07: 62.73,
        VC_07: 0.59894,
        QE_07: 64.803,
        VE_07: 0.60281,
        QA_08: 28.542,
        VA_08: 0.50035,
        QC_08: 63.316,
        VC_08: 0.60038,
        QE_08: 64.971,
        VE_08: 0.60322,
        QA_09: 28.773,
        VA_09: 0.50118,
        QC_09: 75.945,
        VC_09: 0.62998,
        QE_09: 76.052,
        VE_09: 0.62893,
        QA_10: 45.699,
        VA_10: 0.55486,
        QC_10: 116.508,
        VC_10: 0.71203,
        QE_10: 115.552,
        VE_10: 0.70865,
        QA_11: 107.142,
        VA_11: 0.69588,
        QC_11: 135.063,
        VC_11: 0.74498,
        QE_11: 131.815,
        VE_11: 0.73764,
        QA_12: 148.792,
        VA_12: 0.7697,
        QC_12: 125.281,
        VC_12: 0.7279,
        QE_12: 124.023,
        VE_12: 0.72398,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.479089717301406, 35.682223211280707, 0.0],
          [-81.462756583993496, 35.685511477942327, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286299.0,
        COMID: 9745420,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.301,
        REACHCODE: "03050102000127",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0030775075609979999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060249.0,
        ToNode: 250060243.0,
        Hydroseq: 250025540.0,
        LevelPathI: 250018071.0,
        Pathlength: 586.007,
        TerminalPa: 250002604.0,
        ArbolateSu: 141.55,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250026281.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024862.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1521,
        TotDASqKM: 128.286,
        DivDASqKM: 128.286,
        Tidal: 0,
        TOTMA: 0.0087739816875300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30459.0,
        MAXELEVSMO: 30776.0,
        MINELEVSMO: 30626.0,
        SLOPE: 0.00498338,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30099999904632602,
        QA_MA: 82.344,
        VA_MA: 1.30052,
        QC_MA: 84.035,
        VC_MA: 1.30216,
        QE_MA: 84.596,
        VE_MA: 1.30269,
        QA_01: 137.703,
        VA_01: 1.612,
        QC_01: 109.756,
        VC_01: 1.45354,
        QE_01: 110.855,
        VE_01: 1.45618,
        QA_02: 141.269,
        VA_02: 1.62982,
        QC_02: 120.259,
        VC_02: 1.51049,
        QE_02: 121.254,
        VE_02: 1.51215,
        QA_03: 152.701,
        VA_03: 1.68556,
        QC_03: 131.708,
        VC_03: 1.56996,
        QE_03: 137.316,
        VE_03: 1.59435,
        QA_04: 116.807,
        VA_04: 1.50291,
        QC_04: 109.702,
        VC_04: 1.45325,
        QE_04: 118.229,
        VE_04: 1.49611,
        QA_05: 91.89,
        VA_05: 1.36002,
        QC_05: 88.117,
        VC_05: 1.32751,
        QE_05: 91.585,
        VE_05: 1.3455,
        QA_06: 51.761,
        VA_06: 1.08375,
        QC_06: 65.429,
        VC_06: 1.17849,
        QE_06: 70.809,
        VE_06: 1.21307,
        QA_07: 30.547,
        VA_07: 0.89437,
        QC_07: 46.952,
        VC_07: 1.03792,
        QE_07: 52.982,
        VE_07: 1.08394,
        QA_08: 20.962,
        VA_08: 0.78818,
        QC_08: 46.215,
        VC_08: 1.03182,
        QE_08: 50.357,
        VE_08: 1.06329,
        QA_09: 20.346,
        VA_09: 0.78064,
        QC_09: 55.927,
        VC_09: 1.10893,
        QE_09: 45.122,
        VE_09: 1.02055,
        QA_10: 37.52,
        VA_10: 0.96197,
        QC_10: 99.528,
        VC_10: 1.39558,
        QE_10: 54.118,
        VE_10: 1.09273,
        QA_11: 79.564,
        VA_11: 1.28259,
        QC_11: 107.64,
        VC_11: 1.44177,
        QE_11: 75.385,
        VE_11: 1.24366,
        QA_12: 107.494,
        VA_12: 1.45136,
        QC_12: 93.201,
        VC_12: 1.35832,
        QE_12: 84.092,
        VE_12: 1.29954,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.484156583960271, 35.611681011390203, 0.0],
          [-81.481690717297411, 35.613332078054384, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286300.0,
        COMID: 9745484,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.493,
        REACHCODE: "03050102000128",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.014794130229912999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060267.0,
        ToNode: 250060249.0,
        Hydroseq: 250026281.0,
        LevelPathI: 250018071.0,
        Pathlength: 586.308,
        TerminalPa: 250002604.0,
        ArbolateSu: 139.5,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250027078.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025540.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3257,
        TotDASqKM: 126.8379,
        DivDASqKM: 126.8379,
        Tidal: 0,
        TOTMA: 0.060698079420499998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30523.0,
        MAXELEVSMO: 30817.0,
        MINELEVSMO: 30776.0,
        SLOPE: 0.00027461,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4930000305175779,
        QA_MA: 81.569,
        VA_MA: 0.93263,
        QC_MA: 83.275,
        VC_MA: 0.93368,
        QE_MA: 83.836,
        VE_MA: 0.93402,
        QA_01: 136.309,
        VA_01: 1.1283,
        QC_01: 108.675,
        VC_01: 1.02863,
        QE_01: 109.773,
        VE_01: 1.0303,
        QA_02: 139.808,
        VA_02: 1.13941,
        QC_02: 119.05,
        VC_02: 1.06435,
        QE_02: 120.044,
        VE_02: 1.06541,
        QA_03: 151.113,
        VA_03: 1.17444,
        QC_03: 130.383,
        VC_03: 1.10175,
        QE_03: 135.991,
        VE_03: 1.11725,
        QA_04: 115.678,
        VA_04: 1.05988,
        QC_04: 108.665,
        VC_04: 1.02859,
        QE_04: 117.192,
        VE_04: 1.05581,
        QA_05: 91.053,
        VA_05: 0.97018,
        QC_05: 87.344,
        VC_05: 0.94972,
        QE_05: 90.812,
        VE_05: 0.96114,
        QA_06: 51.294,
        VA_06: 0.79634,
        QC_06: 64.846,
        VC_06: 0.85591,
        QE_06: 70.226,
        VE_06: 0.87785,
        QA_07: 30.255,
        VA_07: 0.67706,
        QC_07: 46.514,
        VC_07: 0.76735,
        QE_07: 52.544,
        VE_07: 0.79656,
        QA_08: 20.767,
        VA_08: 0.61029,
        QC_08: 45.777,
        VC_08: 0.76347,
        QE_08: 49.919,
        VE_08: 0.78346,
        QA_09: 20.191,
        VA_09: 0.60581,
        QC_09: 55.549,
        VC_09: 0.81271,
        QE_09: 44.744,
        VE_09: 0.75663,
        QA_10: 37.404,
        VA_10: 0.72101,
        QC_10: 99.28,
        VC_10: 0.99486,
        QE_10: 53.871,
        VE_10: 0.80307,
        QA_11: 78.932,
        VA_11: 0.92183,
        QC_11: 106.987,
        VC_11: 1.02266,
        QE_11: 74.732,
        VE_11: 0.89697,
        QA_12: 106.464,
        VA_12: 1.02747,
        QC_12: 92.388,
        VC_12: 0.96913,
        QE_12: 83.279,
        VE_12: 0.93181,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.493679583945394, 35.603450011403027, 0.0],
          [-81.491380783949069, 35.603887411402241, 0.0],
          [-81.490795917283322, 35.606682011398107, 0.0],
          [-81.484156583960271, 35.611681011390203, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286295.0,
        COMID: 9745404,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.369,
        REACHCODE: "03050102000123",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013191059646755,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060241.0,
        ToNode: 250060238.0,
        Hydroseq: 250023112.0,
        LevelPathI: 250018071.0,
        Pathlength: 581.901,
        TerminalPa: 250002604.0,
        ArbolateSu: 161.325,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250023653.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022614.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6822,
        TotDASqKM: 150.9858,
        DivDASqKM: 150.9858,
        Tidal: 0,
        TOTMA: 0.042979532728000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29736.0,
        MAXELEVSMO: 30145.0,
        MINELEVSMO: 29850.0,
        SLOPE: 0.00215485,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.3689999580383301,
        QA_MA: 94.519,
        VA_MA: 1.208,
        QC_MA: 95.945,
        VC_MA: 1.20909,
        QE_MA: 96.506,
        VE_MA: 1.20952,
        QA_01: 159.571,
        VA_01: 1.4964,
        QC_01: 126.696,
        VC_01: 1.35237,
        QE_01: 127.795,
        VE_01: 1.35442,
        QA_02: 164.227,
        VA_02: 1.51468,
        QC_02: 139.23,
        VC_02: 1.406,
        QE_02: 140.225,
        VE_02: 1.40727,
        QA_03: 177.581,
        VA_03: 1.56581,
        QC_03: 152.406,
        VC_03: 1.45999,
        QE_03: 158.014,
        VE_03: 1.47922,
        QA_04: 134.695,
        VA_04: 1.39416,
        QC_04: 126.093,
        VC_04: 1.34973,
        QE_04: 134.62,
        VE_04: 1.38372,
        QA_05: 105.119,
        VA_05: 1.26023,
        QC_05: 100.297,
        VC_05: 1.23056,
        QE_05: 103.764,
        VE_05: 1.24489,
        QA_06: 59.214,
        VA_06: 1.01019,
        QC_06: 74.712,
        VC_06: 1.09706,
        QE_06: 80.092,
        VE_06: 1.12458,
        QA_07: 35.052,
        VA_07: 0.83963,
        QC_07: 53.7,
        VC_07: 0.97021,
        QE_07: 59.73,
        VE_07: 1.00686,
        QA_08: 24.014,
        VA_08: 0.74298,
        QC_08: 53.088,
        VC_08: 0.96619,
        QE_08: 57.23,
        VE_08: 0.99117,
        QA_09: 22.77,
        VA_09: 0.73087,
        QC_09: 61.769,
        VC_09: 1.02129,
        QE_09: 50.964,
        VE_09: 0.9504,
        QA_10: 39.352,
        VA_10: 0.8733,
        QC_10: 103.389,
        VC_10: 1.24555,
        QE_10: 57.98,
        VE_10: 0.99591,
        QA_11: 89.487,
        VA_11: 1.18224,
        QC_11: 117.734,
        VC_11: 1.31249,
        QE_11: 85.478,
        VE_11: 1.15328,
        QA_12: 123.706,
        VA_12: 1.34619,
        QC_12: 105.907,
        VC_12: 1.2576,
        QE_12: 96.798,
        VE_12: 1.21096,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.457319184001904, 35.613511878054169, 0.0],
          [-81.45544131733817, 35.614566611385783, 0.0],
          [-81.456203517336917, 35.619352678045061, 0.0],
          [-81.453201517341654, 35.618530211379607, 0.0],
          [-81.453007584008617, 35.621026678042483, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286296.0,
        COMID: 9745406,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.856,
        REACHCODE: "03050102000124",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0091232460226569993,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060242.0,
        ToNode: 250060241.0,
        Hydroseq: 250023653.0,
        LevelPathI: 250018071.0,
        Pathlength: 583.27,
        TerminalPa: 250002604.0,
        ArbolateSu: 155.82,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250024238.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023112.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4878,
        TotDASqKM: 142.8741,
        DivDASqKM: 142.8741,
        Tidal: 0,
        TOTMA: 0.0263573046017,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29942.0,
        MAXELEVSMO: 30379.0,
        MINELEVSMO: 30145.0,
        SLOPE: 0.00273364,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.85600000619888295,
        QA_MA: 90.173,
        VA_MA: 1.23151,
        QC_MA: 91.701,
        VC_MA: 1.23277,
        QE_MA: 92.263,
        VE_MA: 1.23323,
        QA_01: 151.74,
        VA_01: 1.52535,
        QC_01: 120.637,
        VC_01: 1.37768,
        QE_01: 121.736,
        VE_01: 1.37989,
        QA_02: 156.02,
        VA_02: 1.54346,
        QC_02: 132.458,
        VC_02: 1.43209,
        QE_02: 133.453,
        VE_02: 1.43347,
        QA_03: 168.688,
        VA_03: 1.59573,
        QC_03: 145.019,
        VC_03: 1.48748,
        QE_03: 150.628,
        VE_03: 1.50817,
        QA_04: 128.397,
        VA_04: 1.42206,
        QC_04: 120.327,
        VC_04: 1.37622,
        QE_04: 128.854,
        VE_04: 1.41271,
        QA_05: 100.42,
        VA_05: 1.28581,
        QC_05: 95.977,
        VC_05: 1.25541,
        QE_05: 99.445,
        VE_05: 1.27078,
        QA_06: 56.565,
        VA_06: 1.02903,
        QC_06: 71.415,
        VC_06: 1.11784,
        QE_06: 76.795,
        VE_06: 1.14736,
        QA_07: 33.448,
        VA_07: 0.85358,
        QC_07: 51.301,
        VC_07: 0.98744,
        QE_07: 57.331,
        VE_07: 1.02675,
        QA_08: 22.923,
        VA_08: 0.75447,
        QC_08: 50.63,
        VC_08: 0.98271,
        QE_08: 54.772,
        VE_08: 1.00954,
        QA_09: 21.908,
        VA_09: 0.74387,
        QC_09: 59.701,
        VC_09: 1.04439,
        QE_09: 48.896,
        VE_09: 0.96853,
        QA_10: 38.697,
        VA_10: 0.89746,
        QC_10: 102.014,
        VC_10: 1.28659,
        QE_10: 56.604,
        VE_10: 1.0219,
        QA_11: 85.927,
        VA_11: 1.20817,
        QC_11: 114.144,
        VC_11: 1.34672,
        QE_11: 81.889,
        VE_11: 1.17647,
        QA_12: 117.905,
        VA_12: 1.37277,
        QC_12: 101.379,
        VC_12: 1.28335,
        QE_12: 92.27,
        VE_12: 1.23326,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.465399317322749, 35.616071011383447, 0.0],
          [-81.457319184001904, 35.613511878054169, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286297.0,
        COMID: 9745416,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.359,
        REACHCODE: "03050102000125",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013954745327320999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060247.0,
        ToNode: 250060242.0,
        Hydroseq: 250024238.0,
        LevelPathI: 250018071.0,
        Pathlength: 584.126,
        TerminalPa: 250002604.0,
        ArbolateSu: 153.265,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250024862.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250023653.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5804,
        TotDASqKM: 141.0516,
        DivDASqKM: 141.0516,
        Tidal: 0,
        TOTMA: 0.0751885041458,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30139.0,
        MAXELEVSMO: 30379.0,
        MINELEVSMO: 30379.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.358999967575073,
        QA_MA: 89.213,
        VA_MA: 0.68562,
        QC_MA: 90.763,
        VC_MA: 0.68615,
        QE_MA: 91.324,
        VE_MA: 0.68634,
        QA_01: 150.006,
        VA_01: 0.80549,
        QC_01: 119.295,
        VC_01: 0.74516,
        QE_01: 120.393,
        VE_01: 0.74607,
        QA_02: 154.196,
        VA_02: 0.81282,
        QC_02: 130.951,
        VC_02: 0.76732,
        QE_02: 131.945,
        VE_02: 0.76789,
        QA_03: 166.703,
        VA_03: 0.83414,
        QC_03: 143.369,
        VC_03: 0.78994,
        QE_03: 148.978,
        VE_03: 0.79849,
        QA_04: 126.981,
        VA_04: 0.7634,
        QC_04: 119.03,
        VC_04: 0.74464,
        QE_04: 127.558,
        VE_04: 0.75972,
        QA_05: 99.379,
        VA_05: 0.70787,
        QC_05: 95.02,
        VC_05: 0.69546,
        QE_05: 98.487,
        VE_05: 0.7018,
        QA_06: 55.977,
        VA_06: 0.60292,
        QC_06: 70.682,
        VC_06: 0.63918,
        QE_06: 76.062,
        VE_06: 0.65136,
        QA_07: 33.104,
        VA_07: 0.53123,
        QC_07: 50.785,
        VC_07: 0.58592,
        QE_07: 56.815,
        VE_07: 0.60214,
        QA_08: 22.678,
        VA_08: 0.49068,
        QC_08: 50.078,
        VC_08: 0.58386,
        QE_08: 54.22,
        VE_08: 0.59494,
        QA_09: 21.72,
        VA_09: 0.48655,
        QC_09: 59.247,
        VC_09: 0.60959,
        QE_09: 48.442,
        VE_09: 0.5783,
        QA_10: 38.55,
        VA_10: 0.55,
        QC_10: 101.704,
        VC_10: 0.70968,
        QE_10: 56.294,
        VE_10: 0.60071,
        QA_11: 85.187,
        VA_11: 0.67648,
        QC_11: 113.394,
        VC_11: 0.73355,
        QE_11: 81.139,
        VE_11: 0.66334,
        QA_12: 116.637,
        VA_12: 0.74332,
        QC_12: 100.386,
        VC_12: 0.70691,
        QE_12: 91.277,
        VE_12: 0.68623,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.476672783971935, 35.615077078051684, 0.0],
          [-81.473443717310204, 35.611919411389863, 0.0],
          [-81.465399317322749, 35.616071011383447, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286298.0,
        COMID: 9745408,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.522,
        REACHCODE: "03050102000126",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0055541847157809998,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060243.0,
        ToNode: 250060247.0,
        Hydroseq: 250024862.0,
        LevelPathI: 250018071.0,
        Pathlength: 585.485,
        TerminalPa: 250002604.0,
        ArbolateSu: 149.284,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250025540.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024238.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2295,
        TotDASqKM: 136.6893,
        DivDASqKM: 136.6893,
        Tidal: 0,
        TOTMA: 0.0151537737592,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 30334.0,
        MAXELEVSMO: 30626.0,
        MINELEVSMO: 30379.0,
        SLOPE: 0.0047318,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.52200001478195202,
        QA_MA: 86.892,
        VA_MA: 1.30605,
        QC_MA: 88.492,
        VC_MA: 1.30753,
        QE_MA: 89.053,
        VE_MA: 1.30804,
        QA_01: 145.838,
        VA_01: 1.6218,
        QC_01: 116.066,
        VC_01: 1.46231,
        QE_01: 117.164,
        VE_01: 1.4648,
        QA_02: 149.802,
        VA_02: 1.64063,
        QC_02: 127.32,
        VC_02: 1.52043,
        QE_02: 128.315,
        VE_02: 1.522,
        QA_03: 161.949,
        VA_03: 1.69695,
        QC_03: 139.413,
        VC_03: 1.58026,
        QE_03: 145.022,
        VE_03: 1.60349,
        QA_04: 123.553,
        VA_04: 1.51109,
        QC_04: 115.889,
        VC_04: 1.46138,
        QE_04: 124.416,
        VE_04: 1.50228,
        QA_05: 96.862,
        VA_05: 1.36529,
        QC_05: 92.702,
        VC_05: 1.33249,
        QE_05: 96.169,
        VE_05: 1.34969,
        QA_06: 54.569,
        VA_06: 1.08769,
        QC_06: 68.928,
        VC_06: 1.18339,
        QE_06: 74.308,
        VE_06: 1.21643,
        QA_07: 32.24,
        VA_07: 0.8977,
        QC_07: 49.491,
        VC_07: 1.04225,
        QE_07: 55.521,
        VE_07: 1.08623,
        QA_08: 22.092,
        VA_08: 0.79053,
        QC_08: 48.757,
        VC_08: 1.03645,
        QE_08: 52.899,
        VE_08: 1.06651,
        QA_09: 21.25,
        VA_09: 0.78068,
        QC_09: 58.115,
        VC_09: 1.10759,
        QE_09: 47.31,
        VE_09: 1.02286,
        QA_10: 38.198,
        VA_10: 0.95322,
        QC_10: 100.962,
        VC_10: 1.37997,
        QE_10: 55.552,
        VE_10: 1.08646,
        QA_11: 83.326,
        VA_11: 1.2841,
        QC_11: 111.499,
        VC_11: 1.43798,
        QE_11: 79.244,
        VE_11: 1.24797,
        QA_12: 113.557,
        VA_12: 1.4584,
        QC_12: 97.972,
        VC_12: 1.363,
        QE_12: 88.863,
        VE_12: 1.30691,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.481690717297411, 35.613332078054384, 0.0],
          [-81.476672783971935, 35.615077078051684, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287325.0,
        COMID: 9735884,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.427,
        REACHCODE: "03050103000567",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0040757651493570001,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059765.0,
        ToNode: 250059776.0,
        Hydroseq: 250022616.0,
        LevelPathI: 250022616.0,
        Pathlength: 373.019,
        TerminalPa: 250002604.0,
        ArbolateSu: 248.011,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250023114.0,
        DnLevelPat: 250021306.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022146.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0378,
        TotDASqKM: 507.3651,
        DivDASqKM: 0.0378,
        Tidal: 0,
        TOTMA: 0.0447526594601,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9057.0,
        MAXELEVSMO: 9137.0,
        MINELEVSMO: 9302.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.27700001001357999,
        QA_MA: 0.012,
        VA_MA: 0.36231,
        QC_MA: 0.012,
        VC_MA: 0.36231,
        QE_MA: 0.012,
        VE_MA: 0.36231,
        QA_01: 0.026,
        VA_01: 0.38968,
        QC_01: 0.026,
        VC_01: 0.38968,
        QE_01: 0.026,
        VE_01: 0.38968,
        QA_02: 0.029,
        VA_02: 0.39453,
        QC_02: 0.029,
        VC_02: 0.39453,
        QE_02: 0.029,
        VE_02: 0.39453,
        QA_03: 0.033,
        VA_03: 0.40065,
        QC_03: 0.033,
        VC_03: 0.40065,
        QE_03: 0.033,
        VE_03: 0.40065,
        QA_04: 0.017,
        VA_04: 0.37326,
        QC_04: 0.017,
        VC_04: 0.37326,
        QE_04: 0.017,
        VE_04: 0.37326,
        QA_05: 0.009,
        VA_05: 0.35467,
        QC_05: 0.009,
        VC_05: 0.35467,
        QE_05: 0.009,
        VE_05: 0.35467,
        QA_06: 0.006,
        VA_06: 0.3457,
        QC_06: 0.006,
        VC_06: 0.3457,
        QE_06: 0.006,
        VE_06: 0.3457,
        QA_07: 0.005,
        VA_07: 0.34226,
        QC_07: 0.005,
        VC_07: 0.34226,
        QE_07: 0.005,
        VE_07: 0.34226,
        QA_08: 0.004,
        VA_08: 0.33848,
        QC_08: 0.004,
        VC_08: 0.33848,
        QE_08: 0.004,
        VE_08: 0.33848,
        QA_09: 0.003,
        VA_09: 0.33421,
        QC_09: 0.003,
        VC_09: 0.33421,
        QE_09: 0.003,
        VE_09: 0.33421,
        QA_10: 0.002,
        VA_10: 0.32921,
        QC_10: 0.002,
        VC_10: 0.32921,
        QE_10: 0.002,
        VE_10: 0.32921,
        QA_11: 0.002,
        VA_11: 0.32921,
        QC_11: 0.002,
        VC_11: 0.32921,
        QE_11: 0.002,
        VE_11: 0.32921,
        QA_12: 0.008,
        VA_12: 0.35186,
        QC_12: 0.008,
        VC_12: 0.35186,
        QE_12: 0.008,
        VE_12: 0.35186,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.920046118169125, 34.564888413015183, 0.0],
          [-80.91796778483905, 34.561796813019953, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286283.0,
        COMID: 9745308,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.833,
        REACHCODE: "03050102000111",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0079935921888350005,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060218.0,
        ToNode: 250060214.0,
        Hydroseq: 250019851.0,
        LevelPathI: 250018071.0,
        Pathlength: 559.53,
        TerminalPa: 250002604.0,
        ArbolateSu: 221.491,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250020196.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019509.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3177,
        TotDASqKM: 218.4705,
        DivDASqKM: 218.4705,
        Tidal: 0,
        TOTMA: 0.023484654962300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25541.0,
        MAXELEVSMO: 25840.0,
        MINELEVSMO: 25574.0,
        SLOPE: 0.00319327,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.83300000429153398,
        QA_MA: 129.492,
        VA_MA: 1.34629,
        QC_MA: 129.85,
        VC_MA: 1.34652,
        QE_MA: 130.411,
        VE_MA: 1.34689,
        QA_01: 223.201,
        VA_01: 1.69423,
        QC_01: 175.665,
        VC_01: 1.52722,
        QE_01: 176.764,
        VE_01: 1.52889,
        QA_02: 231.31,
        VA_02: 1.72074,
        QC_02: 194.28,
        VC_02: 1.59418,
        QE_02: 195.275,
        VE_02: 1.59517,
        QA_03: 250.358,
        VA_03: 1.78135,
        QC_03: 212.443,
        VC_03: 1.65667,
        QE_03: 218.051,
        VE_03: 1.6728,
        QA_04: 186.371,
        VA_04: 1.56768,
        QC_04: 173.184,
        VC_04: 1.51805,
        QE_04: 181.712,
        VE_04: 1.54692,
        QA_05: 142.44,
        VA_05: 1.40017,
        QC_05: 134.37,
        VC_05: 1.36556,
        QE_05: 137.837,
        VE_05: 1.37788,
        QA_06: 80.414,
        VA_06: 1.1143,
        QC_06: 101.034,
        VC_06: 1.21702,
        QE_06: 106.414,
        VE_06: 1.24059,
        QA_07: 47.734,
        VA_07: 0.91935,
        QC_07: 72.592,
        VC_07: 1.07073,
        QE_07: 78.622,
        VE_07: 1.10218,
        QA_08: 32.81,
        VA_08: 0.80906,
        QC_08: 72.99,
        VC_08: 1.07295,
        QE_08: 77.131,
        VE_08: 1.09415,
        QA_09: 29.473,
        VA_09: 0.78134,
        QC_09: 77.574,
        VC_09: 1.09808,
        QE_09: 66.769,
        VE_09: 1.03621,
        QA_10: 44.803,
        VA_10: 0.89913,
        QC_10: 114.679,
        VC_10: 1.28024,
        QE_10: 69.27,
        VE_10: 1.05056,
        QA_11: 115.815,
        VA_11: 1.28656,
        QC_11: 143.324,
        VC_11: 1.4024,
        QE_11: 111.068,
        VE_11: 1.26202,
        QA_12: 169.921,
        VA_12: 1.50738,
        QC_12: 141.368,
        VC_12: 1.39444,
        QE_12: 132.259,
        VE_12: 1.35467,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.36282938414854, 35.637473478016886, 0.0],
          [-81.361426517484134, 35.638572678015066, 0.0],
          [-81.361874984150063, 35.642214211342946, 0.0],
          [-81.360247517485959, 35.643313278007838, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286284.0,
        COMID: 9745306,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 4.582,
        REACHCODE: "03050102000112",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.045557512008761999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060217.0,
        ToNode: 250060218.0,
        Hydroseq: 250020196.0,
        LevelPathI: 250018071.0,
        Pathlength: 560.363,
        TerminalPa: 250002604.0,
        ArbolateSu: 219.105,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250020553.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019851.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.6459,
        TotDASqKM: 216.018,
        DivDASqKM: 216.018,
        Tidal: 0,
        TOTMA: 0.13497601731299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25684.0,
        MAXELEVSMO: 26876.0,
        MINELEVSMO: 25840.0,
        SLOPE: 0.00226102,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.5819997787475586,
        QA_MA: 128.282,
        VA_MA: 1.28846,
        QC_MA: 128.683,
        VC_MA: 1.28871,
        QE_MA: 129.245,
        VE_MA: 1.28905,
        QA_01: 220.956,
        VA_01: 1.61651,
        QC_01: 173.945,
        VC_01: 1.45885,
        QE_01: 175.043,
        VE_01: 1.46043,
        QA_02: 228.931,
        VA_02: 1.64137,
        QC_02: 192.337,
        VC_02: 1.52191,
        QE_02: 193.332,
        VE_02: 1.52286,
        QA_03: 247.775,
        VA_03: 1.69855,
        QC_03: 210.323,
        VC_03: 1.58091,
        QE_03: 215.932,
        VE_03: 1.59629,
        QA_04: 184.571,
        VA_04: 1.49731,
        QC_04: 171.549,
        VC_04: 1.4504,
        QE_04: 180.077,
        VE_04: 1.47791,
        QA_05: 141.184,
        VA_05: 1.33962,
        QC_05: 133.23,
        VC_05: 1.30695,
        QE_05: 136.697,
        VE_05: 1.31868,
        QA_06: 79.688,
        VA_06: 1.06952,
        QC_06: 100.135,
        VC_06: 1.16646,
        QE_06: 105.515,
        VE_06: 1.18891,
        QA_07: 47.306,
        VA_07: 0.88542,
        QC_07: 71.955,
        VC_07: 1.02836,
        QE_07: 77.985,
        VE_07: 1.05832,
        QA_08: 32.514,
        VA_08: 0.78125,
        QC_08: 72.316,
        VC_08: 1.03027,
        QE_08: 76.458,
        VE_08: 1.05048,
        QA_09: 29.242,
        VA_09: 0.75535,
        QC_09: 77.037,
        VC_09: 1.05492,
        QE_09: 66.232,
        VE_09: 0.996,
        QA_10: 44.605,
        VA_10: 0.86769,
        QC_10: 114.274,
        VC_10: 1.2288,
        QE_10: 68.865,
        VE_10: 1.01039,
        QA_11: 115.016,
        VA_11: 1.23327,
        QC_11: 142.569,
        VC_11: 1.34353,
        QE_11: 110.313,
        VE_11: 1.20995,
        QA_12: 168.344,
        VA_12: 1.44061,
        QC_12: 140.174,
        VC_12: 1.33426,
        QE_12: 131.064,
        VE_12: 1.29636,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.388387984108817, 35.64260301134226, 0.0],
          [-81.384095184115552, 35.641321011344189, 0.0],
          [-81.380672917454206, 35.645649811337478, 0.0],
          [-81.374808784129925, 35.640359611345843, 0.0],
          [-81.374444184130596, 35.645237678004889, 0.0],
          [-81.368804584139355, 35.647184411335161, 0.0],
          [-81.366980984142174, 35.643474211340958, 0.0],
          [-81.368552384139605, 35.641184011344421, 0.0],
          [-81.36282938414854, 35.637473478016886, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286285.0,
        COMID: 9745290,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.372,
        REACHCODE: "03050102000113",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003824339923724,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060213.0,
        ToNode: 250060217.0,
        Hydroseq: 250020553.0,
        LevelPathI: 250018071.0,
        Pathlength: 564.945,
        TerminalPa: 250002604.0,
        ArbolateSu: 210.826,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250020922.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020196.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1368,
        TotDASqKM: 210.6387,
        DivDASqKM: 210.6387,
        Tidal: 0,
        TOTMA: 0.010615663823899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26709.0,
        MAXELEVSMO: 26989.0,
        MINELEVSMO: 26876.0,
        SLOPE: 0.00303763,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37200000882148698,
        QA_MA: 125.583,
        VA_MA: 1.32996,
        QC_MA: 126.08,
        VC_MA: 1.33029,
        QE_MA: 126.641,
        VE_MA: 1.33066,
        QA_01: 215.983,
        VA_01: 1.67082,
        QC_01: 170.131,
        VC_01: 1.50654,
        QE_01: 171.229,
        VE_01: 1.50824,
        QA_02: 223.661,
        VA_02: 1.69632,
        QC_02: 188.028,
        VC_02: 1.57189,
        QE_02: 189.023,
        VE_02: 1.57291,
        QA_03: 242.06,
        VA_03: 1.75582,
        QC_03: 205.63,
        VC_03: 1.63338,
        QE_03: 211.238,
        VE_03: 1.64977,
        QA_04: 180.551,
        VA_04: 1.54716,
        QC_04: 167.898,
        VC_04: 1.49816,
        QE_04: 176.425,
        VE_04: 1.52744,
        QA_05: 138.338,
        VA_05: 1.38381,
        QC_05: 130.643,
        VC_05: 1.34976,
        QE_05: 134.11,
        VE_05: 1.36224,
        QA_06: 78.062,
        VA_06: 1.10203,
        QC_06: 98.12,
        VC_06: 1.20291,
        QE_06: 103.5,
        VE_06: 1.22679,
        QA_07: 46.344,
        VA_07: 0.91009,
        QC_07: 70.528,
        VC_07: 1.05905,
        QE_07: 76.557,
        VE_07: 1.09091,
        QA_08: 31.852,
        VA_08: 0.80145,
        QC_08: 70.815,
        VC_08: 1.06067,
        QE_08: 74.957,
        VE_08: 1.08218,
        QA_09: 28.725,
        VA_09: 0.77513,
        QC_09: 75.833,
        VC_09: 1.08853,
        QE_09: 65.028,
        VE_09: 1.02594,
        QA_10: 44.168,
        VA_10: 0.89492,
        QC_10: 113.379,
        VC_10: 1.27427,
        QE_10: 67.969,
        VE_10: 1.043,
        QA_11: 113.187,
        VA_11: 1.27511,
        QC_11: 140.836,
        VC_11: 1.39215,
        QE_11: 108.581,
        VE_11: 1.25046,
        QA_12: 164.817,
        VA_12: 1.48861,
        QC_12: 137.499,
        VC_12: 1.37843,
        QE_12: 128.39,
        VE_12: 1.33813,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.391473717437407, 35.640633078012002, 0.0],
          [-81.388387984108817, 35.64260301134226, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287327.0,
        COMID: 9735888,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.044,
        REACHCODE: "03050103000569",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00039329301986999998,
        StreamLeve: 5,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059772.0,
        ToNode: 250059774.0,
        Hydroseq: 250021707.0,
        LevelPathI: 250021707.0,
        Pathlength: 372.861,
        TerminalPa: 250002604.0,
        ArbolateSu: 250.491,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250022147.0,
        DnLevelPat: 250021306.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021306.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 510.0597,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0054178090699899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8769.0,
        MINELEVSMO: 8767.0,
        SLOPE: 0.00045454,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.043999999761580998,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.916195918175163, 34.562095213019404, 0.0],
          [-80.916251118175126, 34.561705813020069, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286286.0,
        COMID: 9745368,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 4.154,
        REACHCODE: "03050102000114",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.041471431882359001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060234.0,
        ToNode: 250060213.0,
        Hydroseq: 250020922.0,
        LevelPathI: 250018071.0,
        Pathlength: 565.317,
        TerminalPa: 250002604.0,
        ArbolateSu: 207.94,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250021304.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020553.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 5.94,
        TotDASqKM: 208.7739,
        DivDASqKM: 208.7739,
        Tidal: 0,
        TOTMA: 0.13578249709199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 26737.0,
        MAXELEVSMO: 27399.0,
        MINELEVSMO: 26989.0,
        SLOPE: 0.000987,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.1539998054504386,
        QA_MA: 124.625,
        VA_MA: 1.16109,
        QC_MA: 125.155,
        VC_MA: 1.16139,
        QE_MA: 125.717,
        VE_MA: 1.1617,
        QA_01: 214.239,
        VA_01: 1.44533,
        QC_01: 168.794,
        VC_01: 1.30823,
        QE_01: 169.892,
        VE_01: 1.30965,
        QA_02: 221.819,
        VA_02: 1.46652,
        QC_02: 186.522,
        VC_02: 1.36268,
        QE_02: 187.516,
        VE_02: 1.36353,
        QA_03: 240.063,
        VA_03: 1.51616,
        QC_03: 203.99,
        VC_03: 1.41401,
        QE_03: 209.598,
        VE_03: 1.42778,
        QA_04: 179.134,
        VA_04: 1.34227,
        QC_04: 166.61,
        VC_04: 1.30134,
        QE_04: 175.137,
        VE_04: 1.32595,
        QA_05: 137.329,
        VA_05: 1.2062,
        QC_05: 129.726,
        VC_05: 1.17779,
        QE_05: 133.193,
        VE_05: 1.18828,
        QA_06: 77.484,
        VA_06: 0.97091,
        QC_06: 97.403,
        VC_06: 1.05507,
        QE_06: 102.783,
        VE_06: 1.07515,
        QA_07: 45.996,
        VA_07: 0.81066,
        QC_07: 70.01,
        VC_07: 0.93498,
        QE_07: 76.04,
        VE_07: 0.96176,
        QA_08: 31.616,
        VA_08: 0.71999,
        QC_08: 70.281,
        VC_08: 0.93626,
        QE_08: 74.423,
        VE_08: 0.95434,
        QA_09: 28.548,
        VA_09: 0.69828,
        QC_09: 75.419,
        VC_09: 0.96023,
        QE_09: 64.614,
        VE_09: 0.90764,
        QA_10: 44.015,
        VA_10: 0.79905,
        QC_10: 113.066,
        VC_10: 1.1166,
        QE_10: 67.656,
        VE_10: 0.92246,
        QA_11: 112.484,
        VA_11: 1.11593,
        QC_11: 140.169,
        VC_11: 1.21428,
        QE_11: 107.914,
        VE_11: 1.09524,
        QA_12: 163.557,
        VA_12: 1.29351,
        QC_12: 136.543,
        VC_12: 1.20176,
        QE_12: 127.433,
        VE_12: 1.16786,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.410012984075365, 35.629063211363302, 0.0],
          [-81.405721717415361, 35.630347011361323, 0.0],
          [-81.399380184091854, 35.625035211369436, 0.0],
          [-81.394555517432707, 35.624784278036486, 0.0],
          [-81.395257717431548, 35.628013411364861, 0.0],
          [-81.391751317437013, 35.628311678031196, 0.0],
          [-81.394725717432379, 35.631586078026032, 0.0],
          [-81.391864917436806, 35.633831078022638, 0.0],
          [-81.391473717437407, 35.640633078012002, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286279.0,
        COMID: 9745330,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 4.655,
        REACHCODE: "03050102000106",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.046666062740391999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060227.0,
        ToNode: 250060233.0,
        Hydroseq: 250018071.0,
        LevelPathI: 250018071.0,
        Pathlength: 549.358,
        TerminalPa: 250002604.0,
        ArbolateSu: 246.001,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250018328.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016927.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.1478,
        TotDASqKM: 249.2586,
        DivDASqKM: 249.2586,
        Tidal: 0,
        TOTMA: 0.14645659442100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24444.0,
        MAXELEVSMO: 24996.0,
        MINELEVSMO: 24495.0,
        SLOPE: 0.00107626,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.6550002098083496,
        QA_MA: 144.596,
        VA_MA: 1.20676,
        QC_MA: 144.376,
        VC_MA: 1.20665,
        QE_MA: 144.938,
        VE_MA: 1.20693,
        QA_01: 251.282,
        VA_01: 1.51314,
        QC_01: 197.153,
        VC_01: 1.36825,
        QE_01: 198.251,
        VE_01: 1.36953,
        QA_02: 261.15,
        VA_02: 1.53804,
        QC_02: 218.62,
        VC_02: 1.42805,
        QE_02: 219.614,
        VE_02: 1.42879,
        QA_03: 282.651,
        VA_03: 1.5908,
        QC_03: 238.887,
        VC_03: 1.48202,
        QE_03: 244.495,
        VE_03: 1.49449,
        QA_04: 208.971,
        VA_04: 1.40078,
        QC_04: 193.681,
        VC_04: 1.3583,
        QE_04: 202.208,
        VE_04: 1.38072,
        QA_05: 158.167,
        VA_05: 1.25059,
        QC_05: 148.622,
        VC_05: 1.22058,
        QE_05: 152.09,
        VE_05: 1.23021,
        QA_06: 89.468,
        VA_06: 1.00461,
        QC_06: 112.246,
        VC_06: 1.09426,
        QE_06: 117.626,
        VE_06: 1.11263,
        QA_07: 53.24,
        VA_07: 0.83689,
        QC_07: 80.754,
        VC_07: 0.96819,
        QE_07: 86.783,
        VE_07: 0.99271,
        QA_08: 36.57,
        VA_08: 0.74133,
        QC_08: 81.531,
        VC_08: 0.97155,
        QE_08: 85.672,
        VE_08: 0.98804,
        QA_09: 32.394,
        VA_09: 0.71433,
        QC_09: 84.324,
        VC_09: 0.98352,
        QE_09: 73.519,
        VE_09: 0.93501,
        QA_10: 47.299,
        VA_10: 0.8047,
        QC_10: 119.754,
        VC_10: 1.12175,
        QE_10: 74.345,
        VE_10: 0.93874,
        QA_11: 125.417,
        VA_11: 1.14138,
        QC_11: 152.299,
        VC_11: 1.2325,
        QE_11: 120.044,
        VE_11: 1.12137,
        QA_12: 189.578,
        VA_12: 1.34572,
        QC_12: 156.172,
        VC_12: 1.2449,
        QE_12: 147.063,
        VE_12: 1.2139,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.329664117533412, 35.646787411335708, 0.0],
          [-81.328038984202635, 35.64287047800849, 0.0],
          [-81.329667517533323, 35.640351878012382, 0.0],
          [-81.328264984202235, 35.639893278013176, 0.0],
          [-81.32756631753665, 35.63531261135347, 0.0],
          [-81.320103984214882, 35.635195011353687, 0.0],
          [-81.323299984209939, 35.638677611348385, 0.0],
          [-81.319454584215862, 35.641676011343691, 0.0],
          [-81.313285784225513, 35.636795011351239, 0.0],
          [-81.308040384233607, 35.635967811352657, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286280.0,
        COMID: 9745262,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.091,
        REACHCODE: "03050102000107",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010973125263613,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060206.0,
        ToNode: 250060227.0,
        Hydroseq: 250018328.0,
        LevelPathI: 250018071.0,
        Pathlength: 554.013,
        TerminalPa: 250002604.0,
        ArbolateSu: 240.525,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250018598.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018071.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2825,
        TotDASqKM: 241.938,
        DivDASqKM: 241.938,
        Tidal: 0,
        TOTMA: 0.0340434852351,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24996.0,
        MAXELEVSMO: 25127.0,
        MINELEVSMO: 24996.0,
        SLOPE: 0.00120073,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.090999960899353,
        QA_MA: 141.079,
        VA_MA: 1.21667,
        QC_MA: 141.0,
        VC_MA: 1.21663,
        QE_MA: 141.561,
        VE_MA: 1.21692,
        QA_01: 244.662,
        VA_01: 1.52509,
        QC_01: 192.093,
        VC_01: 1.3787,
        QE_01: 193.192,
        VE_01: 1.38003,
        QA_02: 254.129,
        VA_02: 1.54987,
        QC_02: 212.9,
        VC_02: 1.43877,
        QE_02: 213.894,
        VE_02: 1.43955,
        QA_03: 275.065,
        VA_03: 1.60317,
        QC_03: 232.684,
        VC_03: 1.49339,
        QE_03: 238.293,
        VE_03: 1.50633,
        QA_04: 203.716,
        VA_04: 1.41234,
        QC_04: 188.92,
        VC_04: 1.36928,
        QE_04: 197.447,
        VE_04: 1.3925,
        QA_05: 154.583,
        VA_05: 1.26185,
        QC_05: 145.38,
        VC_05: 1.2315,
        QE_05: 148.847,
        VE_05: 1.24146,
        QA_06: 87.404,
        VA_06: 1.01277,
        QC_06: 109.692,
        VC_06: 1.10326,
        QE_06: 115.072,
        VE_06: 1.12228,
        QA_07: 51.961,
        VA_07: 0.8428,
        QC_07: 78.859,
        VC_07: 0.97549,
        QE_07: 84.889,
        VE_07: 1.00087,
        QA_08: 35.685,
        VA_08: 0.74614,
        QC_08: 79.517,
        VC_08: 0.97844,
        QE_08: 83.658,
        VE_08: 0.99552,
        QA_09: 31.705,
        VA_09: 0.71949,
        QC_09: 82.739,
        VC_09: 0.99272,
        QE_09: 71.934,
        VE_09: 0.94258,
        QA_10: 46.708,
        VA_10: 0.8134,
        QC_10: 118.558,
        VC_10: 1.13676,
        QE_10: 73.149,
        VE_10: 0.94825,
        QA_11: 123.278,
        VA_11: 1.1539,
        QC_11: 150.314,
        VC_11: 1.24801,
        QE_11: 118.059,
        VE_11: 1.13343,
        QA_12: 184.994,
        VA_12: 1.35725,
        QC_12: 152.734,
        VC_12: 1.25601,
        QE_12: 143.624,
        VE_12: 1.22393,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.33075631753168, 35.650749811329604, 0.0],
          [-81.32722031753724, 35.651664477994821, 0.0],
          [-81.328904784201256, 35.649901677997661, 0.0],
          [-81.328204717535641, 35.647428011334853, 0.0],
          [-81.329664117533412, 35.646787411335708, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286281.0,
        COMID: 9745244,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 0.116,
        REACHCODE: "03050102000108",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001146795464152,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060201.0,
        ToNode: 250060206.0,
        Hydroseq: 250018598.0,
        LevelPathI: 250018071.0,
        Pathlength: 555.104,
        TerminalPa: 250002604.0,
        ArbolateSu: 232.776,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250018884.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018328.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0153,
        TotDASqKM: 231.4908,
        DivDASqKM: 231.4908,
        Tidal: 0,
        TOTMA: 0.0060444484380400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25127.0,
        MAXELEVSMO: 25127.0,
        MINELEVSMO: 25127.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.11599999666214,
        QA_MA: 135.965,
        VA_MA: 0.72857,
        QC_MA: 136.083,
        VC_MA: 0.7286,
        QE_MA: 136.644,
        VE_MA: 0.72874,
        QA_01: 235.156,
        VA_01: 0.87044,
        QC_01: 184.822,
        VC_01: 0.80277,
        QE_01: 185.92,
        VE_01: 0.80341,
        QA_02: 244.009,
        VA_02: 0.88158,
        QC_02: 204.649,
        VC_02: 0.83026,
        QE_02: 205.643,
        VE_02: 0.83064,
        QA_03: 264.112,
        VA_03: 0.90619,
        QC_03: 223.719,
        VC_03: 0.85554,
        QE_03: 229.327,
        VE_03: 0.86176,
        QA_04: 196.021,
        VA_04: 0.81866,
        QC_04: 181.943,
        VC_04: 0.79867,
        QE_04: 190.471,
        VE_04: 0.80981,
        QA_05: 149.239,
        VA_05: 0.74988,
        QC_05: 140.538,
        VC_05: 0.73585,
        QE_05: 144.005,
        VE_05: 0.74062,
        QA_06: 84.319,
        VA_06: 0.63442,
        QC_06: 105.873,
        VC_06: 0.67616,
        QE_06: 111.253,
        VE_06: 0.68527,
        QA_07: 50.098,
        VA_07: 0.55567,
        QC_07: 76.099,
        VC_07: 0.61701,
        QE_07: 82.128,
        VE_07: 0.62917,
        QA_08: 34.42,
        VA_08: 0.51099,
        QC_08: 76.645,
        VC_08: 0.61819,
        QE_08: 80.786,
        VE_08: 0.62638,
        QA_09: 30.722,
        VA_09: 0.49912,
        QC_09: 80.469,
        VC_09: 0.6263,
        QE_09: 69.664,
        VE_09: 0.60232,
        QA_10: 45.865,
        VA_10: 0.54435,
        QC_10: 116.846,
        VC_10: 0.69593,
        QE_10: 71.437,
        VE_10: 0.60627,
        QA_11: 120.105,
        VA_11: 0.70179,
        QC_11: 147.354,
        VC_11: 0.74674,
        QE_11: 115.099,
        VE_11: 0.69213,
        QA_12: 178.361,
        VA_12: 0.79371,
        QC_12: 147.743,
        VC_12: 0.74735,
        QE_12: 138.634,
        VE_12: 0.73198,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.331570517530452, 35.64997141133091, 0.0],
          [-81.33075631753168, 35.650749811329604, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286282.0,
        COMID: 9745298,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 2.366,
        REACHCODE: "03050102000110",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.024347273515298001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060214.0,
        ToNode: 250060212.0,
        Hydroseq: 250019509.0,
        LevelPathI: 250018071.0,
        Pathlength: 557.164,
        TerminalPa: 250002604.0,
        ArbolateSu: 228.873,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250019851.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019185.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2518,
        TotDASqKM: 227.8062,
        DivDASqKM: 227.8062,
        Tidal: 0,
        TOTMA: 0.077321202081000004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25031.0,
        MAXELEVSMO: 25574.0,
        MINELEVSMO: 25366.0,
        SLOPE: 0.00087912,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.3659999370574951,
        QA_MA: 134.178,
        VA_MA: 1.16157,
        QC_MA: 134.364,
        VC_MA: 1.16166,
        QE_MA: 134.925,
        VE_MA: 1.16195,
        QA_01: 231.81,
        VA_01: 1.44897,
        QC_01: 182.26,
        VC_01: 1.31161,
        QE_01: 183.358,
        VE_01: 1.31293,
        QA_02: 240.465,
        VA_02: 1.47139,
        QC_02: 201.756,
        VC_02: 1.36724,
        QE_02: 202.751,
        VE_02: 1.36802,
        QA_03: 260.277,
        VA_03: 1.52132,
        QC_03: 220.576,
        VC_03: 1.41859,
        QE_03: 226.185,
        VE_03: 1.43138,
        QA_04: 193.354,
        VA_04: 1.34423,
        QC_04: 179.523,
        VC_04: 1.30358,
        QE_04: 188.051,
        VE_04: 1.3265,
        QA_05: 147.403,
        VA_05: 1.20524,
        QC_05: 138.874,
        VC_05: 1.17675,
        QE_05: 142.341,
        VE_05: 1.18655,
        QA_06: 83.258,
        VA_06: 0.97058,
        QC_06: 104.558,
        VC_06: 1.05527,
        QE_06: 109.938,
        VE_06: 1.074,
        QA_07: 49.454,
        VA_07: 0.81057,
        QC_07: 75.144,
        VC_07: 0.9351,
        QE_07: 81.173,
        VE_07: 0.9601,
        QA_08: 33.975,
        VA_08: 0.71981,
        QC_08: 75.632,
        VC_08: 0.93726,
        QE_08: 79.774,
        VE_08: 0.95411,
        QA_09: 30.375,
        VA_09: 0.69605,
        QC_09: 79.667,
        VC_09: 0.95486,
        QE_09: 68.862,
        VE_09: 0.90559,
        QA_10: 45.568,
        VA_10: 0.78922,
        QC_10: 116.241,
        VC_10: 1.09848,
        QE_10: 70.832,
        VE_10: 0.9146,
        QA_11: 118.998,
        VA_11: 1.10887,
        QC_11: 146.318,
        VC_11: 1.20117,
        QE_11: 114.063,
        VE_11: 1.08912,
        QA_12: 176.042,
        VA_12: 1.2939,
        QC_12: 145.994,
        VC_12: 1.20012,
        QE_12: 136.885,
        VE_12: 1.16851,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.360247517485959, 35.643313278007838, 0.0],
          [-81.356824584157948, 35.643198411341416, 0.0],
          [-81.35617891749223, 35.645603011337698, 0.0],
          [-81.352755784164231, 35.645625411337619, 0.0],
          [-81.350960584166955, 35.644434078006043, 0.0],
          [-81.353879184162508, 35.6415260780106, 0.0],
          [-81.353374517496604, 35.640174811345958, 0.0],
          [-81.351298517499743, 35.639395811347242, 0.0],
          [-81.347342184172646, 35.641250078011012, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286291.0,
        COMID: 9745472,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 4.575,
        REACHCODE: "03050102000119",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.046874024354842003,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060263.0,
        ToNode: 250060234.0,
        Hydroseq: 250021304.0,
        LevelPathI: 250018071.0,
        Pathlength: 569.471,
        TerminalPa: 250002604.0,
        ArbolateSu: 185.169,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250021705.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250020922.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.5649,
        TotDASqKM: 175.563,
        DivDASqKM: 175.563,
        Tidal: 0,
        TOTMA: 0.141830242538,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 27384.0,
        MAXELEVSMO: 28294.0,
        MINELEVSMO: 27399.0,
        SLOPE: 0.00195628,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.5749998092651367,
        QA_MA: 107.331,
        VA_MA: 1.22375,
        QC_MA: 108.414,
        VC_MA: 1.22449,
        QE_MA: 108.976,
        VE_MA: 1.22488,
        QA_01: 182.917,
        VA_01: 1.52327,
        QC_01: 144.714,
        VC_01: 1.37633,
        QE_01: 145.812,
        VE_01: 1.37813,
        QA_02: 188.769,
        VA_02: 1.54376,
        QC_02: 159.431,
        VC_02: 1.43269,
        QE_02: 160.426,
        VE_02: 1.43378,
        QA_03: 204.163,
        VA_03: 1.59627,
        QC_03: 174.414,
        VC_03: 1.48761,
        QE_03: 180.022,
        VE_03: 1.5048,
        QA_04: 153.434,
        VA_04: 1.41502,
        QC_04: 143.209,
        VC_04: 1.37041,
        QE_04: 151.737,
        VE_04: 1.401,
        QA_05: 118.833,
        VA_05: 1.27459,
        QC_05: 112.863,
        VC_05: 1.24427,
        QE_05: 116.33,
        VE_05: 1.25722,
        QA_06: 66.971,
        VA_06: 1.02095,
        QC_06: 84.356,
        VC_06: 1.11022,
        QE_06: 89.736,
        VE_06: 1.13506,
        QA_07: 39.703,
        VA_07: 0.84821,
        QC_07: 60.645,
        VC_07: 0.98133,
        QE_07: 66.674,
        VE_07: 1.01442,
        QA_08: 27.27,
        VA_08: 0.75059,
        QC_08: 60.441,
        VC_08: 0.98013,
        QE_08: 64.582,
        VE_08: 1.00257,
        QA_09: 25.173,
        VA_09: 0.7322,
        QC_09: 67.49,
        VC_09: 1.02065,
        QE_09: 56.685,
        VE_09: 0.95612,
        QA_10: 41.334,
        VA_10: 0.85988,
        QC_10: 107.53,
        VC_10: 1.22052,
        QE_10: 62.12,
        VE_10: 0.98839,
        QA_11: 99.359,
        VA_11: 1.187,
        QC_11: 127.514,
        VC_11: 1.30693,
        QE_11: 95.259,
        VE_11: 1.16169,
        QA_12: 140.732,
        VA_12: 1.36539,
        QC_12: 119.091,
        VC_12: 1.27135,
        QE_12: 109.982,
        VE_12: 1.22936,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.416929384064531, 35.606685611398063, 0.0],
          [-81.414629784068154, 35.606594811398168, 0.0],
          [-81.410313984074833, 35.612917211388378, 0.0],
          [-81.41584031739967, 35.615297211384593, 0.0],
          [-81.414636584068091, 35.619786411377675, 0.0],
          [-81.409027717410197, 35.621368411375158, 0.0],
          [-81.417921517396394, 35.625625211368515, 0.0],
          [-81.412283984071848, 35.626543278033921, 0.0],
          [-81.410012984075365, 35.629063211363302, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286292.0,
        COMID: 9745464,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.8,
        REACHCODE: "03050102000120",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.018046415752990999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060261.0,
        ToNode: 250060263.0,
        Hydroseq: 250021705.0,
        LevelPathI: 250018071.0,
        Pathlength: 574.046,
        TerminalPa: 250002604.0,
        ArbolateSu: 178.599,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250022144.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021304.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6669,
        TotDASqKM: 167.3199,
        DivDASqKM: 167.3199,
        Tidal: 0,
        TOTMA: 0.058412524589099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28144.0,
        MAXELEVSMO: 28549.0,
        MINELEVSMO: 28294.0,
        SLOPE: 0.00141666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.799999952316284,
        QA_MA: 103.095,
        VA_MA: 1.16895,
        QC_MA: 104.299,
        VC_MA: 1.16976,
        QE_MA: 104.861,
        VE_MA: 1.17014,
        QA_01: 175.162,
        VA_01: 1.44869,
        QC_01: 138.736,
        VC_01: 1.31066,
        QE_01: 139.834,
        VE_01: 1.31244,
        QA_02: 180.6,
        VA_02: 1.46735,
        QC_02: 152.715,
        VC_02: 1.36308,
        QE_02: 153.71,
        VE_02: 1.36417,
        QA_03: 195.317,
        VA_03: 1.51658,
        QC_03: 167.102,
        VC_03: 1.41473,
        QE_03: 172.71,
        VE_03: 1.43157,
        QA_04: 147.227,
        VA_04: 1.3482,
        QC_04: 137.545,
        VC_04: 1.30609,
        QE_04: 146.072,
        VE_04: 1.33598,
        QA_05: 114.336,
        VA_05: 1.21757,
        QC_05: 108.749,
        VC_05: 1.18909,
        QE_05: 112.216,
        VE_05: 1.20172,
        QA_06: 64.427,
        VA_06: 0.97884,
        QC_06: 81.195,
        VC_06: 1.06252,
        QE_06: 86.575,
        VE_06: 1.08677,
        QA_07: 38.151,
        VA_07: 0.816,
        QC_07: 58.33,
        VC_07: 0.94105,
        QE_07: 64.36,
        VE_07: 0.97337,
        QA_08: 26.204,
        VA_08: 0.72421,
        QC_08: 58.032,
        VC_08: 0.93933,
        QE_08: 62.173,
        VE_08: 0.96127,
        QA_09: 24.397,
        VA_09: 0.70873,
        QC_09: 65.651,
        VC_09: 0.98204,
        QE_09: 54.845,
        VE_09: 0.91921,
        QA_10: 40.669,
        VA_10: 0.83352,
        QC_10: 106.146,
        VC_10: 1.17783,
        QE_10: 60.736,
        VE_10: 0.95321,
        QA_11: 96.219,
        VA_11: 1.13798,
        QC_11: 124.43,
        VC_11: 1.25439,
        QE_11: 92.174,
        VE_11: 1.1131,
        QA_12: 135.118,
        VA_12: 1.30188,
        QC_12: 114.76,
        VC_12: 1.21461,
        QE_12: 105.651,
        VE_12: 1.17358,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.422822184055349, 35.612454678055713, 0.0],
          [-81.42108291739153, 35.611882811390046, 0.0],
          [-81.417831117396531, 35.614311678052843, 0.0],
          [-81.415334184067092, 35.612869678055063, 0.0],
          [-81.414996984067614, 35.611449878057272, 0.0],
          [-81.418193184062602, 35.610028811392795, 0.0],
          [-81.416929384064531, 35.606685611398063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284163.0,
        COMID: 9756704,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983804",
        GNIS_NAME: "Crowders Creek",
        LENGTHKM: 3.086,
        REACHCODE: "03050101000568",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031041676287843,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061943.0,
        ToNode: 250061948.0,
        Hydroseq: 250032396.0,
        LevelPathI: 250028891.0,
        Pathlength: 468.846,
        TerminalPa: 250002604.0,
        ArbolateSu: 99.801,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028891.0,
        UpHydroseq: 250033865.0,
        DnLevelPat: 250028891.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031098.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.2487,
        TotDASqKM: 205.8066,
        DivDASqKM: 205.8066,
        Tidal: 0,
        TOTMA: 0.106145621951,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 18580.0,
        MAXELEVSMO: 18974.0,
        MINELEVSMO: 18738.0,
        SLOPE: 0.00076474,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0859999656677251,
        QA_MA: 88.15,
        VA_MA: 1.10286,
        QC_MA: 89.722,
        VC_MA: 1.10399,
        QE_MA: 89.722,
        VE_MA: 1.10399,
        QA_01: 184.403,
        VA_01: 1.48406,
        QC_01: 145.859,
        VC_01: 1.3382,
        QE_01: 145.859,
        VE_01: 1.3382,
        QA_02: 193.929,
        VA_02: 1.51592,
        QC_02: 163.668,
        VC_02: 1.40316,
        QE_02: 163.668,
        VE_02: 1.40316,
        QA_03: 205.712,
        VA_03: 1.55434,
        QC_03: 175.694,
        VC_03: 1.44516,
        QE_03: 175.694,
        VE_03: 1.44516,
        QA_04: 122.886,
        VA_04: 1.25613,
        QC_04: 115.278,
        VC_04: 1.21725,
        QE_04: 115.278,
        VE_04: 1.21725,
        QA_05: 70.297,
        VA_05: 1.0129,
        QC_05: 68.09,
        VC_05: 0.99557,
        QE_05: 68.09,
        VE_05: 0.99557,
        QA_06: 42.365,
        VA_06: 0.84678,
        QC_06: 53.7,
        VC_06: 0.91418,
        QE_06: 53.7,
        VE_06: 0.91418,
        QA_07: 27.867,
        VA_07: 0.73942,
        QC_07: 42.928,
        VC_07: 0.84628,
        QE_07: 42.928,
        VE_07: 0.84628,
        QA_08: 21.987,
        VA_08: 0.68845,
        QC_08: 48.521,
        VC_08: 0.88242,
        QE_08: 48.521,
        VE_08: 0.88242,
        QA_09: 19.37,
        VA_09: 0.66371,
        QC_09: 53.55,
        VC_09: 0.91328,
        QE_09: 53.55,
        VE_09: 0.91328,
        QA_10: 15.382,
        VA_10: 0.62278,
        QC_10: 48.819,
        VC_10: 0.88429,
        QE_10: 48.819,
        VE_10: 0.88429,
        QA_11: 36.811,
        VA_11: 0.80807,
        QC_11: 59.799,
        VC_11: 0.94979,
        QE_11: 59.799,
        VE_11: 0.94979,
        QA_12: 117.349,
        VA_12: 1.23321,
        QC_12: 100.944,
        VC_12: 1.15537,
        QE_12: 100.944,
        VE_12: 1.15537,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.192688384412691, 35.169458212076677, 0.0],
          [-81.19176431774747, 35.164992412083564, 0.0],
          [-81.187324984421025, 35.159314612092317, 0.0],
          [-81.183698517759979, 35.157873478761246, 0.0],
          [-81.181412384430132, 35.159042812092935, 0.0],
          [-81.171009117779704, 35.157398412095461, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286293.0,
        COMID: 9745418,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 5.012,
        REACHCODE: "03050102000121",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.049519750455838001,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060248.0,
        ToNode: 250060261.0,
        Hydroseq: 250022144.0,
        LevelPathI: 250018071.0,
        Pathlength: 575.846,
        TerminalPa: 250002604.0,
        ArbolateSu: 170.38,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250022614.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250021705.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1661,
        TotDASqKM: 158.5404,
        DivDASqKM: 158.5404,
        Tidal: 0,
        TOTMA: 0.15308931163799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 28549.0,
        MAXELEVSMO: 29823.0,
        MINELEVSMO: 28549.0,
        SLOPE: 0.00254189,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 5.0120000839233398,
        QA_MA: 98.452,
        VA_MA: 1.24175,
        QC_MA: 99.779,
        VC_MA: 1.24276,
        QE_MA: 100.341,
        VE_MA: 1.24319,
        QA_01: 166.723,
        VA_01: 1.54299,
        QC_01: 132.223,
        VC_01: 1.39342,
        QE_01: 133.321,
        VE_01: 1.39545,
        QA_02: 171.768,
        VA_02: 1.56268,
        QC_02: 145.445,
        VC_02: 1.44975,
        QE_02: 146.44,
        VE_02: 1.451,
        QA_03: 185.757,
        VA_03: 1.61593,
        QC_03: 159.186,
        VC_03: 1.50579,
        QE_03: 164.794,
        VE_03: 1.52493,
        QA_04: 140.489,
        VA_04: 1.4357,
        QC_04: 131.39,
        VC_04: 1.38979,
        QE_04: 139.918,
        VE_04: 1.42369,
        QA_05: 109.348,
        VA_05: 1.29525,
        QC_05: 104.178,
        VC_05: 1.26441,
        QE_05: 107.645,
        VE_05: 1.27872,
        QA_06: 61.621,
        VA_06: 1.03616,
        QC_06: 77.706,
        VC_06: 1.12659,
        QE_06: 83.085,
        VE_06: 1.15406,
        QA_07: 36.475,
        VA_07: 0.85928,
        QC_07: 55.827,
        VC_07: 0.99483,
        QE_07: 61.856,
        VE_07: 1.03142,
        QA_08: 25.03,
        VA_08: 0.75944,
        QC_08: 55.38,
        VC_08: 0.9919,
        QE_08: 59.521,
        VE_08: 1.0168,
        QA_09: 23.504,
        VA_09: 0.74462,
        QC_09: 63.525,
        VC_09: 1.04357,
        QE_09: 52.72,
        VE_09: 0.97259,
        QA_10: 39.961,
        VA_10: 0.88664,
        QC_10: 104.666,
        VC_10: 1.26679,
        QE_10: 59.257,
        VE_10: 1.01513,
        QA_11: 92.495,
        VA_11: 1.21132,
        QC_11: 120.739,
        VC_11: 1.34232,
        QE_11: 88.484,
        VE_11: 1.1828,
        QA_12: 128.915,
        VA_12: 1.38539,
        QC_12: 109.957,
        VC_12: 1.29222,
        QE_12: 100.847,
        VE_12: 1.24569,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.444733317354803, 35.620848678042705, 0.0],
          [-81.439547984029502, 35.625409078035602, 0.0],
          [-81.43920658403006, 35.61947781137826, 0.0],
          [-81.432838184039952, 35.617648878047589, 0.0],
          [-81.435975384035089, 35.612219478056147, 0.0],
          [-81.432301984040805, 35.613000011388124, 0.0],
          [-81.430172517377343, 35.615795011383852, 0.0],
          [-81.424003984053627, 35.618866678045663, 0.0],
          [-81.421841584057006, 35.614149811386483, 0.0],
          [-81.422822184055349, 35.612454678055713, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286294.0,
        COMID: 9745388,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "987505",
        GNIS_NAME: "Jacob Fork",
        LENGTHKM: 1.043,
        REACHCODE: "03050102000122",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010774892599829,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060238.0,
        ToNode: 250060248.0,
        Hydroseq: 250022614.0,
        LevelPathI: 250018071.0,
        Pathlength: 580.858,
        TerminalPa: 250002604.0,
        ArbolateSu: 163.836,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250018071.0,
        UpHydroseq: 250023112.0,
        DnLevelPat: 250018071.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250022144.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7181,
        TotDASqKM: 153.4977,
        DivDASqKM: 153.4977,
        Tidal: 0,
        TOTMA: 0.041514774474400003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 29483.0,
        MAXELEVSMO: 29850.0,
        MINELEVSMO: 29823.0,
        SLOPE: 0.00025886,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0429999828338621,
        QA_MA: 95.831,
        VA_MA: 0.95295,
        QC_MA: 97.225,
        VC_MA: 0.95371,
        QE_MA: 97.787,
        VE_MA: 0.95401,
        QA_01: 161.946,
        VA_01: 1.16005,
        QC_01: 128.532,
        VC_01: 1.05682,
        QE_01: 129.63,
        VE_01: 1.05826,
        QA_02: 166.727,
        VA_02: 1.17331,
        QC_02: 141.292,
        VC_02: 1.0954,
        QE_02: 142.287,
        VE_02: 1.09629,
        QA_03: 180.305,
        VA_03: 1.21002,
        QC_03: 154.666,
        VC_03: 1.13411,
        QE_03: 160.274,
        VE_03: 1.1477,
        QA_04: 136.631,
        VA_04: 1.08654,
        QC_04: 127.863,
        VC_04: 1.05475,
        QE_04: 136.39,
        VE_04: 1.07878,
        QA_05: 106.535,
        VA_05: 0.99023,
        QC_05: 101.598,
        VC_05: 0.96896,
        QE_05: 105.065,
        VE_05: 0.9791,
        QA_06: 60.025,
        VA_06: 0.81117,
        QC_06: 75.721,
        VC_06: 0.87349,
        QE_06: 81.1,
        VE_06: 0.89295,
        QA_07: 35.525,
        VA_07: 0.68895,
        QC_07: 54.408,
        VC_07: 0.78252,
        QE_07: 60.438,
        VE_07: 0.80844,
        QA_08: 24.352,
        VA_08: 0.6198,
        QC_08: 53.85,
        VC_08: 0.77994,
        QE_08: 57.992,
        VE_08: 0.79759,
        QA_09: 23.016,
        VA_09: 0.61061,
        QC_09: 62.359,
        VC_09: 0.81814,
        QE_09: 51.554,
        VE_09: 0.76796,
        QA_10: 39.554,
        VA_10: 0.71129,
        QC_10: 103.814,
        VC_10: 0.97657,
        QE_10: 58.405,
        VE_10: 0.79944,
        QA_11: 90.486,
        VA_11: 0.93361,
        QC_11: 118.734,
        VC_11: 1.02596,
        QE_11: 86.479,
        VE_11: 0.91322,
        QA_12: 125.439,
        VA_12: 1.05202,
        QC_12: 107.256,
        VC_12: 0.98825,
        QE_12: 98.147,
        VE_12: 0.95527,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.453007584008617, 35.621026678042483, 0.0],
          [-81.451522117344268, 35.622104078040707, 0.0],
          [-81.449584317347274, 35.619609011377918, 0.0],
          [-81.444733317354803, 35.620848678042705, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284161.0,
        COMID: 9756728,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983804",
        GNIS_NAME: "Crowders Creek",
        LENGTHKM: 1.728,
        REACHCODE: "03050101000566",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017761633377076001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061952.0,
        ToNode: 250062022.0,
        Hydroseq: 250029935.0,
        LevelPathI: 250028891.0,
        Pathlength: 455.793,
        TerminalPa: 250002604.0,
        ArbolateSu: 143.795,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028891.0,
        UpHydroseq: 250031098.0,
        DnLevelPat: 250028891.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028891.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2725,
        TotDASqKM: 289.6191,
        DivDASqKM: 289.6191,
        Tidal: 0,
        TOTMA: 0.071620770929000002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17299.0,
        MAXELEVSMO: 17336.0,
        MINELEVSMO: 17321.0,
        SLOPE: 8.68e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.7280000448226931,
        QA_MA: 122.89,
        VA_MA: 0.91593,
        QC_MA: 123.48,
        VC_MA: 0.91617,
        QE_MA: 123.48,
        VE_MA: 0.91617,
        QA_01: 257.835,
        VA_01: 1.20886,
        QC_01: 202.158,
        VC_01: 1.09803,
        QE_01: 202.158,
        VE_01: 1.09803,
        QA_02: 271.597,
        VA_02: 1.23407,
        QC_02: 227.122,
        VC_02: 1.1484,
        QE_02: 227.122,
        VE_02: 1.1484,
        QA_03: 288.69,
        VA_03: 1.26456,
        QC_03: 243.821,
        VC_03: 1.18065,
        QE_03: 243.821,
        VE_03: 1.18065,
        QA_04: 172.031,
        VA_04: 1.03475,
        QC_04: 160.151,
        VC_04: 1.00616,
        QE_04: 160.151,
        VE_04: 1.00616,
        QA_05: 98.019,
        VA_05: 0.84719,
        QC_05: 93.768,
        VC_05: 0.83352,
        QE_05: 93.768,
        VE_05: 0.83352,
        QA_06: 59.24,
        VA_06: 0.72077,
        QC_06: 74.745,
        VC_06: 0.77395,
        QE_06: 74.745,
        VE_06: 0.77395,
        QA_07: 39.136,
        VA_07: 0.63929,
        QC_07: 59.799,
        VC_07: 0.72194,
        QE_07: 59.799,
        VE_07: 0.72194,
        QA_08: 30.964,
        VA_08: 0.6006,
        QC_08: 68.801,
        VC_08: 0.75391,
        QE_08: 68.801,
        VE_08: 0.75391,
        QA_09: 27.258,
        VA_09: 0.58147,
        QC_09: 72.403,
        VC_09: 0.76615,
        QE_09: 72.403,
        VE_09: 0.76615,
        QA_10: 21.087,
        VA_10: 0.54667,
        QC_10: 62.81,
        VC_10: 0.73287,
        QE_10: 62.81,
        VE_10: 0.73287,
        QA_11: 47.756,
        VA_11: 0.67619,
        QC_11: 72.93,
        VC_11: 0.76792,
        QE_11: 72.93,
        VE_11: 0.76792,
        QA_12: 161.769,
        VA_12: 1.01141,
        QC_12: 135.184,
        VC_12: 0.94611,
        QE_12: 135.184,
        VE_12: 0.94611,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.111195584539189, 35.115173012161002, 0.0],
          [-81.106987117878987, 35.113294412163839, 0.0],
          [-81.103279917884777, 35.113752078829805, 0.0],
          [-81.097261317894151, 35.107910478838903, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284162.0,
        COMID: 9756720,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983804",
        GNIS_NAME: "Crowders Creek",
        LENGTHKM: 11.325,
        REACHCODE: "03050101000567",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.11306387439792601,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061948.0,
        ToNode: 250061952.0,
        Hydroseq: 250031098.0,
        LevelPathI: 250028891.0,
        Pathlength: 457.521,
        TerminalPa: 250002604.0,
        ArbolateSu: 115.137,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028891.0,
        UpHydroseq: 250032396.0,
        DnLevelPat: 250028891.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029935.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 23.8509,
        TotDASqKM: 238.113,
        DivDASqKM: 238.113,
        Tidal: 0,
        TOTMA: 0.35965296976700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 17108.0,
        MAXELEVSMO: 18738.0,
        MINELEVSMO: 17336.0,
        SLOPE: 0.00123796,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 11.324999809265137,
        QA_MA: 101.307,
        VA_MA: 1.19483,
        QC_MA: 102.559,
        VC_MA: 1.19571,
        QE_MA: 102.559,
        VE_MA: 1.19571,
        QA_01: 212.345,
        VA_01: 1.62154,
        QC_01: 167.34,
        VC_01: 1.45915,
        QE_01: 167.34,
        VE_01: 1.45915,
        QA_02: 223.54,
        VA_02: 1.65786,
        QC_02: 187.93,
        VC_02: 1.53229,
        QE_02: 187.93,
        VE_02: 1.53229,
        QA_03: 237.254,
        VA_03: 1.7012,
        QC_03: 201.681,
        VC_03: 1.57906,
        QE_03: 201.681,
        VE_03: 1.57906,
        QA_04: 141.409,
        VA_04: 1.36657,
        QC_04: 132.231,
        VC_04: 1.3239,
        QE_04: 132.231,
        VE_04: 1.3239,
        QA_05: 80.774,
        VA_05: 1.09438,
        QC_05: 77.832,
        VC_05: 1.0748,
        QE_05: 77.832,
        VE_05: 1.0748,
        QA_06: 48.853,
        VA_06: 0.91019,
        QC_06: 61.802,
        VC_06: 0.98646,
        QE_06: 61.802,
        VE_06: 0.98646,
        QA_07: 32.211,
        VA_07: 0.79079,
        QC_07: 49.447,
        VC_07: 0.91073,
        QE_07: 49.447,
        VE_07: 0.91073,
        QA_08: 25.423,
        VA_08: 0.73382,
        QC_08: 56.269,
        VC_08: 0.95352,
        QE_08: 56.269,
        VE_08: 0.95352,
        QA_09: 22.41,
        VA_09: 0.70626,
        QC_09: 60.908,
        VC_09: 0.98123,
        QE_09: 60.908,
        VE_09: 0.98123,
        QA_10: 17.554,
        VA_10: 0.65787,
        QC_10: 54.251,
        VC_10: 0.94113,
        QE_10: 54.251,
        VE_10: 0.94113,
        QA_11: 40.344,
        VA_11: 0.85204,
        QC_11: 64.128,
        VC_11: 0.99989,
        QE_11: 64.128,
        VE_11: 0.99989,
        QA_12: 134.188,
        VA_12: 1.33752,
        QC_12: 114.041,
        VC_12: 1.24715,
        QE_12: 114.041,
        VE_12: 1.24715,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.171009117779704, 35.157398412095461, 0.0],
          [-81.170086717781089, 35.153802812100992, 0.0],
          [-81.16626591778703, 35.153163012101864, 0.0],
          [-81.165260317788537, 35.150117078773405, 0.0],
          [-81.157674584467031, 35.148149878776508, 0.0],
          [-81.156446384468893, 35.145103878781185, 0.0],
          [-81.149084584480363, 35.143479612117062, 0.0],
          [-81.148832984480748, 35.141303812120384, 0.0],
          [-81.146853917817168, 35.143228278784079, 0.0],
          [-81.142141384491197, 35.142358812118687, 0.0],
          [-81.140272384494097, 35.13814481212529, 0.0],
          [-81.138487917830162, 35.13757247879289, 0.0],
          [-81.137456784498454, 35.140618878788075, 0.0],
          [-81.136117984500402, 35.137916278792375, 0.0],
          [-81.130708917842242, 35.136909078793963, 0.0],
          [-81.132520984506073, 35.134023012131706, 0.0],
          [-81.128506317845563, 35.13571827879565, 0.0],
          [-81.126805584514955, 35.133611078798936, 0.0],
          [-81.128561717845514, 35.130816678803228, 0.0],
          [-81.124602917851576, 35.128526478806862, 0.0],
          [-81.121815184522688, 35.123922812147384, 0.0],
          [-81.116155984531474, 35.126694078809749, 0.0],
          [-81.1168253178638, 35.124655678812871, 0.0],
          [-81.113034384536377, 35.122067278816871, 0.0],
          [-81.115348584532626, 35.117898812156682, 0.0],
          [-81.111334784538997, 35.116730478825218, 0.0],
          [-81.111195584539189, 35.115173012161002, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286199.0,
        COMID: 9745664,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 0.206,
        REACHCODE: "03050102000028",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0021305231002500002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060331.0,
        ToNode: 250060335.0,
        Hydroseq: 250032422.0,
        LevelPathI: 250024237.0,
        Pathlength: 528.845,
        TerminalPa: 250002604.0,
        ArbolateSu: 118.231,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250033896.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250031123.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0621,
        TotDASqKM: 169.1811,
        DivDASqKM: 169.1811,
        Tidal: 0,
        TOTMA: 0.0090910258088199997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23802.0,
        MAXELEVSMO: 24032.0,
        MINELEVSMO: 24030.0,
        SLOPE: 9.708e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.206000000238419,
        QA_MA: 79.427,
        VA_MA: 0.85948,
        QC_MA: 81.172,
        VC_MA: 0.86045,
        QE_MA: 81.172,
        VE_MA: 0.86045,
        QA_01: 151.284,
        VA_01: 1.0843,
        QC_01: 120.284,
        VC_01: 0.98866,
        QE_01: 120.284,
        VE_01: 0.98866,
        QA_02: 159.054,
        VA_02: 1.10521,
        QC_02: 134.963,
        VC_02: 1.03156,
        QE_02: 134.963,
        VE_02: 1.03156,
        QA_03: 172.041,
        VA_03: 1.13912,
        QC_03: 147.806,
        VC_03: 1.06732,
        QE_03: 147.806,
        VE_03: 1.06732,
        QA_04: 118.2,
        VA_04: 0.989,
        QC_04: 110.979,
        VC_04: 0.96019,
        QE_04: 110.979,
        VE_04: 0.96019,
        QA_05: 79.495,
        VA_05: 0.85973,
        QC_05: 76.646,
        VC_05: 0.84389,
        QE_05: 76.646,
        VE_05: 0.84389,
        QA_06: 46.244,
        VA_06: 0.7219,
        QC_06: 58.545,
        VC_06: 0.77251,
        QE_06: 58.545,
        VE_06: 0.77251,
        QA_07: 28.134,
        VA_07: 0.62599,
        QC_07: 43.329,
        VC_07: 0.70396,
        QE_07: 43.329,
        VE_07: 0.70396,
        QA_08: 19.664,
        VA_08: 0.57098,
        QC_08: 43.297,
        VC_08: 0.7038,
        QE_08: 43.297,
        VE_08: 0.7038,
        QA_09: 15.997,
        VA_09: 0.54372,
        QC_09: 45.227,
        VC_09: 0.71307,
        QE_09: 45.227,
        VE_09: 0.71307,
        QA_10: 13.323,
        VA_10: 0.52194,
        QC_10: 43.524,
        VC_10: 0.7049,
        QE_10: 43.524,
        VE_10: 0.7049,
        QA_11: 46.231,
        VA_11: 0.72184,
        QC_11: 71.147,
        VC_11: 0.82313,
        QE_11: 71.147,
        VE_11: 0.82313,
        QA_12: 103.965,
        VA_12: 0.94417,
        QC_12: 90.413,
        VC_12: 0.89298,
        QE_12: 90.413,
        VE_12: 0.89298,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.239568384339861, 35.576888478110902, 0.0],
          [-81.24136098433712, 35.575787411446015, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286187.0,
        COMID: 9747388,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 4.384,
        REACHCODE: "03050102000017",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.043072790905961,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060461.0,
        ToNode: 250060414.0,
        Hydroseq: 250013587.0,
        LevelPathI: 250011553.0,
        Pathlength: 507.445,
        TerminalPa: 250002604.0,
        ArbolateSu: 879.793,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013693.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013484.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 7.5474,
        TotDASqKM: 1037.8197,
        DivDASqKM: 1037.8197,
        Tidal: 0,
        TOTMA: 0.100824442459,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21745.0,
        MAXELEVSMO: 22597.0,
        MINELEVSMO: 21798.0,
        SLOPE: 0.00182253,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.3839998245239258,
        QA_MA: 550.249,
        VA_MA: 1.65421,
        QC_MA: 521.645,
        VC_MA: 1.6484,
        QE_MA: 534.84,
        VE_MA: 1.65111,
        QA_01: 998.094,
        VA_01: 2.15472,
        QC_01: 755.292,
        VC_01: 1.93942,
        QE_01: 757.653,
        VE_01: 1.92386,
        QA_02: 1042.781,
        VA_02: 2.19816,
        QC_02: 840.624,
        VC_02: 2.03481,
        QE_02: 836.33,
        VE_02: 2.01087,
        QA_03: 1125.92,
        VA_03: 2.27673,
        QC_03: 909.148,
        VC_03: 2.10816,
        QE_03: 926.298,
        VE_03: 2.10579,
        QA_04: 798.469,
        VA_04: 1.94841,
        QC_04: 717.838,
        VC_04: 1.89596,
        QE_04: 752.45,
        VE_04: 1.91796,
        QA_05: 574.795,
        VA_05: 1.68576,
        QC_05: 514.657,
        VC_05: 1.63884,
        QE_05: 546.67,
        VE_05: 1.6668,
        QA_06: 330.207,
        VA_06: 1.33457,
        QC_06: 406.913,
        VC_06: 1.48282,
        QE_06: 441.675,
        VE_06: 1.52136,
        QA_07: 198.39,
        VA_07: 1.09134,
        QC_07: 291.591,
        VC_07: 1.29235,
        QE_07: 322.114,
        VE_07: 1.33417,
        QA_08: 137.77,
        VA_08: 0.95351,
        QC_08: 315.414,
        VC_08: 1.33425,
        QE_08: 339.502,
        VE_08: 1.36321,
        QA_09: 119.25,
        VA_09: 0.9059,
        QC_09: 266.477,
        VC_09: 1.2464,
        QE_09: 258.961,
        VE_09: 1.22193,
        QA_10: 142.153,
        VA_10: 0.96433,
        QC_10: 288.44,
        VC_10: 1.28669,
        QE_10: 243.364,
        VE_10: 1.19229,
        QA_11: 413.853,
        VA_11: 1.46529,
        QC_11: 378.541,
        VC_11: 1.4386,
        QE_11: 319.524,
        VE_11: 1.32978,
        QA_12: 724.793,
        VA_12: 1.86623,
        QC_12: 529.072,
        VC_12: 1.6585,
        QE_12: 507.115,
        VE_12: 1.61369,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.264423117634578, 35.446631478313066, 0.0],
          [-81.261934117638532, 35.445094811648914, 0.0],
          [-81.261714517638893, 35.442025678320249, 0.0],
          [-81.259645584308714, 35.440535078322682, 0.0],
          [-81.256957784312931, 35.441357011654588, 0.0],
          [-81.255700317648177, 35.439912878323639, 0.0],
          [-81.247991584326883, 35.439151611658076, 0.0],
          [-81.250387584322993, 35.437021878327982, 0.0],
          [-81.252976317652383, 35.427474011676168, 0.0],
          [-81.248859717658775, 35.421378078352348, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286188.0,
        COMID: 9747204,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.134,
        REACHCODE: "03050102000018",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.011010713515754,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060412.0,
        ToNode: 250060461.0,
        Hydroseq: 250013693.0,
        LevelPathI: 250011553.0,
        Pathlength: 511.829,
        TerminalPa: 250002604.0,
        ArbolateSu: 872.165,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013803.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013587.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9081,
        TotDASqKM: 1024.1649,
        DivDASqKM: 1024.1649,
        Tidal: 0,
        TOTMA: 0.048188792246300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22490.0,
        MAXELEVSMO: 22598.0,
        MINELEVSMO: 22597.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1339999437332151,
        QA_MA: 543.961,
        VA_MA: 0.8949,
        QC_MA: 515.915,
        VC_MA: 0.89239,
        QE_MA: 529.085,
        VE_MA: 0.89359,
        QA_01: 985.744,
        VA_01: 1.11262,
        QC_01: 746.189,
        VC_01: 1.01882,
        QE_01: 748.558,
        VE_01: 1.01199,
        QA_02: 1029.977,
        VA_02: 1.13159,
        QC_02: 830.582,
        VC_02: 1.06041,
        QE_02: 826.298,
        VE_02: 1.04989,
        QA_03: 1112.052,
        VA_03: 1.16579,
        QC_03: 898.317,
        VC_03: 1.09238,
        QE_03: 915.463,
        VE_03: 1.09136,
        QA_04: 789.495,
        VA_04: 1.02319,
        QC_04: 709.952,
        VC_04: 1.00029,
        QE_04: 744.568,
        VE_04: 1.01,
        QA_05: 568.72,
        VA_05: 0.90893,
        QC_05: 509.42,
        VC_05: 0.88848,
        QE_05: 541.39,
        VE_05: 0.90077,
        QA_06: 326.757,
        VA_06: 0.75584,
        QC_06: 402.72,
        VC_06: 0.82042,
        QE_06: 437.455,
        VE_06: 0.83738,
        QA_07: 196.197,
        VA_07: 0.64967,
        QC_07: 288.446,
        VC_07: 0.73726,
        QE_07: 318.932,
        VE_07: 0.75566,
        QA_08: 136.252,
        VA_08: 0.5896,
        QC_08: 311.869,
        VC_08: 0.75542,
        QE_08: 335.923,
        VE_08: 0.76816,
        QA_09: 117.965,
        VA_09: 0.56888,
        QC_09: 263.94,
        VC_09: 0.71751,
        QE_09: 256.396,
        VE_09: 0.70672,
        QA_10: 141.052,
        VA_10: 0.59482,
        QC_10: 286.653,
        VC_10: 0.73585,
        QE_10: 241.441,
        VE_10: 0.69421,
        QA_11: 410.416,
        VA_11: 0.81342,
        QC_11: 376.141,
        VC_11: 0.80219,
        QE_11: 317.029,
        VE_11: 0.75424,
        QA_12: 716.419,
        VA_12: 0.98726,
        QC_12: 523.508,
        VC_12: 0.89694,
        QE_12: 501.546,
        VE_12: 0.87721,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.257778117644932, 35.454870478300336, 0.0],
          [-81.264423117634578, 35.446631478313066, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286189.0,
        COMID: 9747202,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 3.336,
        REACHCODE: "03050102000019",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031794376930727002,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060411.0,
        ToNode: 250060412.0,
        Hydroseq: 250013803.0,
        LevelPathI: 250011553.0,
        Pathlength: 512.963,
        TerminalPa: 250002604.0,
        ArbolateSu: 866.032,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013925.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013693.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.8449,
        TotDASqKM: 1011.2823,
        DivDASqKM: 1011.2823,
        Tidal: 0,
        TOTMA: 0.092844383226399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22562.0,
        MAXELEVSMO: 22737.0,
        MINELEVSMO: 22598.0,
        SLOPE: 0.00041666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.3359999656677251,
        QA_MA: 538.027,
        VA_MA: 1.36672,
        QC_MA: 510.504,
        VC_MA: 1.36223,
        QE_MA: 523.65,
        VE_MA: 1.3644,
        QA_01: 974.11,
        VA_01: 1.75888,
        QC_01: 737.612,
        VC_01: 1.58967,
        QE_01: 739.988,
        VE_01: 1.57725,
        QA_02: 1017.881,
        VA_02: 1.79313,
        QC_02: 821.092,
        VC_02: 1.66473,
        QE_02: 816.818,
        VE_02: 1.64559,
        QA_03: 1098.918,
        VA_03: 1.85477,
        QC_03: 888.056,
        VC_03: 1.72238,
        QE_03: 905.198,
        VE_03: 1.72056,
        QA_04: 780.988,
        VA_04: 1.5983,
        QC_04: 702.475,
        VC_04: 1.55689,
        QE_04: 737.094,
        VE_04: 1.57462,
        QA_05: 563.01,
        VA_05: 1.39254,
        QC_05: 504.496,
        VC_05: 1.35562,
        QE_05: 536.425,
        VE_05: 1.378,
        QA_06: 323.466,
        VA_06: 1.11615,
        QC_06: 398.719,
        VC_06: 1.23262,
        QE_06: 433.431,
        VE_06: 1.26352,
        QA_07: 194.119,
        VA_07: 0.92431,
        QC_07: 285.463,
        VC_07: 1.08236,
        QE_07: 315.915,
        VE_07: 1.11587,
        QA_08: 134.866,
        VA_08: 0.81601,
        QC_08: 308.634,
        VC_08: 1.11511,
        QE_08: 332.656,
        VE_08: 1.13831,
        QA_09: 116.753,
        VA_09: 0.77859,
        QC_09: 261.544,
        VC_09: 1.04722,
        QE_09: 253.972,
        VE_09: 1.02751,
        QA_10: 140.012,
        VA_10: 0.82621,
        QC_10: 284.965,
        VC_10: 1.08164,
        QE_10: 239.624,
        VE_10: 1.00564,
        QA_11: 407.121,
        VA_11: 1.22109,
        QC_11: 373.836,
        VC_11: 1.20153,
        QE_11: 314.633,
        VE_11: 1.11412,
        QA_12: 708.508,
        VA_12: 1.53328,
        QC_12: 518.246,
        VC_12: 1.37068,
        QE_12: 496.279,
        VE_12: 1.33472,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.269259317627132, 35.473088478272075, 0.0],
          [-81.26599538429889, 35.464107811619215, 0.0],
          [-81.266115784298734, 35.457603478296107, 0.0],
          [-81.264577384301106, 35.456754811630788, 0.0],
          [-81.264574117634368, 35.459228078293563, 0.0],
          [-81.261409984306056, 35.459866678292599, 0.0],
          [-81.257778117644932, 35.454870478300336, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286190.0,
        COMID: 9747190,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.264,
        REACHCODE: "03050102000020",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012252644675812,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060406.0,
        ToNode: 250060411.0,
        Hydroseq: 250024237.0,
        LevelPathI: 250024237.0,
        Pathlength: 516.299,
        TerminalPa: 250002604.0,
        ArbolateSu: 163.024,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250024861.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013803.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8108,
        TotDASqKM: 235.9935,
        DivDASqKM: 235.9935,
        Tidal: 0,
        TOTMA: 0.038068460433000002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22737.0,
        MAXELEVSMO: 22973.0,
        MINELEVSMO: 22737.0,
        SLOPE: 0.00186708,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.2640000581741331,
        QA_MA: 110.556,
        VA_MA: 1.26013,
        QC_MA: 111.544,
        VC_MA: 1.26082,
        QE_MA: 111.544,
        VE_MA: 1.26082,
        QA_01: 211.686,
        VA_01: 1.65214,
        QC_01: 166.834,
        VC_01: 1.48782,
        QE_01: 166.834,
        VE_01: 1.48782,
        QA_02: 221.817,
        VA_02: 1.68592,
        QC_02: 186.52,
        VC_02: 1.55978,
        QE_02: 186.52,
        VE_02: 1.55978,
        QA_03: 240.198,
        VA_03: 1.7454,
        QC_03: 204.1,
        VC_03: 1.62109,
        QE_03: 204.1,
        VE_03: 1.62109,
        QA_04: 163.139,
        VA_04: 1.47855,
        QC_04: 152.056,
        VC_04: 1.43114,
        QE_04: 152.056,
        VE_04: 1.43114,
        QA_05: 109.868,
        VA_05: 1.25698,
        QC_05: 104.655,
        VC_05: 1.22912,
        QE_05: 104.655,
        VE_05: 1.22912,
        QA_06: 63.687,
        VA_06: 1.01851,
        QC_06: 80.275,
        VC_06: 1.10817,
        QE_06: 80.275,
        VE_06: 1.10817,
        QA_07: 38.914,
        VA_07: 0.85506,
        QC_07: 59.468,
        VC_07: 0.99039,
        QE_07: 59.468,
        VE_07: 0.99039,
        QA_08: 27.368,
        VA_08: 0.76186,
        QC_08: 60.662,
        VC_08: 0.99762,
        QE_08: 60.662,
        VE_08: 0.99762,
        QA_09: 22.285,
        VA_09: 0.71499,
        QC_09: 60.606,
        VC_09: 0.99728,
        QE_09: 60.606,
        VE_09: 0.99728,
        QA_10: 18.713,
        VA_10: 0.67897,
        QC_10: 57.094,
        VC_10: 0.97579,
        QE_10: 57.094,
        VE_10: 0.97579,
        QA_11: 64.187,
        VA_11: 1.02146,
        QC_11: 91.378,
        VC_11: 1.16512,
        QE_11: 91.378,
        VE_11: 1.16512,
        QA_12: 145.373,
        VA_12: 1.40906,
        QC_12: 122.658,
        VC_12: 1.31009,
        QE_12: 122.658,
        VE_12: 1.31009,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.264011317635322, 35.482313678257697, 0.0],
          [-81.267853184296044, 35.477851078264678, 0.0],
          [-81.26673618429777, 35.475559678268155, 0.0],
          [-81.269259317627132, 35.473088478272075, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286183.0,
        COMID: 9747414,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.011,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: -9998,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00010355825055500001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060467.0,
        ToNode: 250060420.0,
        Hydroseq: 250013183.0,
        LevelPathI: 250011553.0,
        Pathlength: 505.963,
        TerminalPa: 250002604.0,
        ArbolateSu: 967.001,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013281.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013088.0,
        FromMeas: 49.4493,
        ToMeas: 49.81471,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 1222.7184,
        DivDASqKM: 1222.7184,
        Tidal: 0,
        TOTMA: 0.00022431517728499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21266.0,
        MAXELEVSMO: 21270.0,
        MINELEVSMO: 21266.0,
        SLOPE: 0.00363636,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.010999999940395,
        QA_MA: 640.986,
        VA_MA: 1.86667,
        QC_MA: 604.077,
        VC_MA: 1.8592,
        QE_MA: 618.209,
        VE_MA: 1.86211,
        QA_01: 1171.216,
        VA_01: 2.45452,
        QC_01: 882.592,
        VC_01: 2.20509,
        QE_01: 886.901,
        VE_01: 2.19031,
        QA_02: 1222.133,
        VA_02: 2.50356,
        QC_02: 980.953,
        VC_02: 2.31454,
        QE_02: 968.838,
        VE_02: 2.28072,
        QA_03: 1320.527,
        VA_03: 2.5957,
        QC_03: 1060.69,
        VC_03: 2.39955,
        QE_03: 1073.564,
        VE_03: 2.3912,
        QA_04: 927.378,
        VA_04: 2.20432,
        QC_04: 830.898,
        VC_04: 2.14526,
        QE_04: 862.025,
        VE_04: 2.1621,
        QA_05: 665.989,
        VA_05: 1.89866,
        QC_05: 593.034,
        VC_05: 1.84408,
        QE_05: 639.889,
        VE_05: 1.89081,
        QA_06: 381.78,
        VA_06: 1.49185,
        QC_06: 469.53,
        VC_06: 1.66499,
        QE_06: 514.531,
        VE_06: 1.71781,
        QA_07: 230.297,
        VA_07: 1.21326,
        QC_07: 337.283,
        VC_07: 1.44645,
        QE_07: 368.232,
        VE_07: 1.48841,
        QA_08: 160.288,
        VA_08: 1.05486,
        QC_08: 368.081,
        VC_08: 1.5005,
        QE_08: 389.703,
        VE_08: 1.52446,
        QA_09: 136.724,
        VA_09: 0.99442,
        QC_09: 300.67,
        VC_09: 1.37909,
        QE_09: 285.628,
        VE_09: 1.33951,
        QA_10: 157.069,
        VA_10: 1.04686,
        QC_10: 312.372,
        VC_10: 1.40101,
        QE_10: 295.646,
        VE_10: 1.35856,
        QA_11: 475.618,
        VA_11: 1.63833,
        QC_11: 420.906,
        VC_11: 1.58848,
        QE_11: 348.006,
        VE_11: 1.45354,
        QA_12: 847.215,
        VA_12: 2.11545,
        QC_12: 609.794,
        VC_12: 1.86698,
        QE_12: 575.653,
        VE_12: 1.80437,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.241750584336557, 35.41266961169913, 0.0],
          [-81.241796117669764, 35.412590478365985, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286184.0,
        COMID: 9747412,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.1,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033471,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.010559171812743001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060466.0,
        ToNode: 250060467.0,
        Hydroseq: 250013281.0,
        LevelPathI: 250011553.0,
        Pathlength: 505.974,
        TerminalPa: 250002604.0,
        ArbolateSu: 966.99,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013382.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013183.0,
        FromMeas: 49.81471,
        ToMeas: 87.28113,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.4561,
        TotDASqKM: 1222.7148,
        DivDASqKM: 1222.7148,
        Tidal: 0,
        TOTMA: 0.021763686132099998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21270.0,
        MAXELEVSMO: 21772.0,
        MINELEVSMO: 21270.0,
        SLOPE: 0.00456363,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1000000238418579,
        QA_MA: 640.985,
        VA_MA: 1.92397,
        QC_MA: 604.076,
        VC_MA: 1.91623,
        QE_MA: 618.208,
        VE_MA: 1.91925,
        QA_01: 1171.213,
        VA_01: 2.53344,
        QC_01: 882.589,
        VC_01: 2.27483,
        QE_01: 886.899,
        VE_01: 2.25952,
        QA_02: 1222.129,
        VA_02: 2.58429,
        QC_02: 980.951,
        VC_02: 2.38832,
        QE_02: 968.835,
        VE_02: 2.35325,
        QA_03: 1320.523,
        VA_03: 2.67981,
        QC_03: 1060.687,
        VC_03: 2.47645,
        QE_03: 1073.561,
        VE_03: 2.46779,
        QA_04: 927.375,
        VA_04: 2.27404,
        QC_04: 830.896,
        VC_04: 2.21281,
        QE_04: 862.023,
        VE_04: 2.23026,
        QA_05: 665.988,
        VA_05: 1.95714,
        QC_05: 593.033,
        VC_05: 1.90056,
        QE_05: 639.887,
        VE_05: 1.949,
        QA_06: 381.779,
        VA_06: 1.53537,
        QC_06: 469.529,
        VC_06: 1.71488,
        QE_06: 514.53,
        VE_06: 1.76964,
        QA_07: 230.296,
        VA_07: 1.24653,
        QC_07: 337.282,
        VC_07: 1.4883,
        QE_07: 368.231,
        VE_07: 1.53181,
        QA_08: 160.288,
        VA_08: 1.08231,
        QC_08: 368.08,
        VC_08: 1.54434,
        QE_08: 389.702,
        VE_08: 1.56918,
        QA_09: 136.723,
        VA_09: 1.01965,
        QC_09: 300.67,
        VC_09: 1.41846,
        QE_09: 285.627,
        VE_09: 1.37743,
        QA_10: 157.069,
        VA_10: 1.07402,
        QC_10: 312.371,
        VC_10: 1.44119,
        QE_10: 295.645,
        VE_10: 1.39718,
        QA_11: 475.617,
        VA_11: 1.68724,
        QC_11: 420.905,
        VC_11: 1.63556,
        QE_11: 348.005,
        VE_11: 1.49565,
        QA_12: 847.212,
        VA_12: 2.18191,
        QC_12: 609.792,
        VC_12: 1.92429,
        QE_12: 575.652,
        VE_12: 1.85938,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.24586858433014, 35.419396011688661, 0.0],
          [-81.243084984334473, 35.419424811688714, 0.0],
          [-81.243667717666881, 35.415564478361432, 0.0],
          [-81.241750584336557, 35.41266961169913, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288443.0,
        COMID: 9736242,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.895,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.020679279092087999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059884.0,
        ToNode: 250059880.0,
        Hydroseq: 250006606.0,
        LevelPathI: 250003258.0,
        Pathlength: 425.595,
        TerminalPa: 250002604.0,
        ArbolateSu: 5994.5,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006633.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006574.0,
        FromMeas: 46.49272,
        ToMeas: 84.60883,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8043,
        TotDASqKM: 7951.5486,
        DivDASqKM: 7951.5486,
        Tidal: 0,
        TOTMA: 0.0575739596282,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14381.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8949999809265139,
        QA_MA: 4122.376,
        VA_MA: 1.24579,
        QC_MA: 4122.376,
        VC_MA: 1.24579,
        QE_MA: 4347.941,
        VE_MA: 1.24984,
        QA_01: 7339.385,
        VA_01: 1.58174,
        QC_01: 7339.385,
        VC_01: 1.58174,
        QE_01: 5918.131,
        VE_01: 1.41731,
        QA_02: 7766.661,
        VA_02: 1.62058,
        QC_02: 7766.661,
        VC_02: 1.62058,
        QE_02: 6094.144,
        VE_02: 1.4347,
        QA_03: 8564.941,
        VA_03: 1.69055,
        QC_03: 8564.941,
        VC_03: 1.69055,
        QE_03: 6195.25,
        VE_03: 1.44459,
        QA_04: 6071.497,
        VA_04: 1.45975,
        QC_04: 6071.497,
        VC_04: 1.45975,
        QE_04: 5388.056,
        VE_04: 1.36341,
        QA_05: 4268.493,
        VA_05: 1.26329,
        QC_05: 4268.493,
        VC_05: 1.26329,
        QE_05: 4487.764,
        VE_05: 1.2658,
        QA_06: 2501.052,
        VA_06: 1.02732,
        QC_06: 2501.052,
        VC_06: 1.02732,
        QE_06: 4178.354,
        VE_06: 1.23016,
        QA_07: 1523.426,
        VA_07: 0.86092,
        QC_07: 1523.426,
        VC_07: 0.86092,
        QE_07: 3149.621,
        VE_07: 1.10171,
        QA_08: 1078.604,
        VA_08: 0.76836,
        QC_08: 1078.604,
        VC_08: 0.76836,
        QE_08: 3083.866,
        VE_08: 1.09287,
        QA_09: 996.798,
        VA_09: 0.74949,
        QC_09: 996.798,
        VC_09: 0.74949,
        QE_09: 2576.393,
        VE_09: 1.02144,
        QA_10: 1120.696,
        VA_10: 0.77781,
        QC_10: 1120.696,
        VC_10: 0.77781,
        QE_10: 3403.552,
        VE_10: 1.13505,
        QA_11: 2994.248,
        VA_11: 1.09942,
        QC_11: 2994.248,
        VC_11: 1.09942,
        QE_11: 3616.021,
        VE_11: 1.16206,
        QA_12: 5270.833,
        VA_12: 1.37646,
        QC_12: 5270.833,
        VC_12: 1.37646,
        QE_12: 4431.062,
        VE_12: 1.25936,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.941349184802846, 34.952781079079728, 0.0],
          [-80.935010718145975, 34.953454479078573, 0.0],
          [-80.921140118167443, 34.950557412416458, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286185.0,
        COMID: 9747416,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.139,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 12033477,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0014253173663420001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060468.0,
        ToNode: 250060486.0,
        Hydroseq: 250012997.0,
        LevelPathI: 250011553.0,
        Pathlength: 504.522,
        TerminalPa: 250002604.0,
        ArbolateSu: 968.442,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013088.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250012899.0,
        FromMeas: 0.0,
        ToMeas: 4.76876,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1728,
        TotDASqKM: 1225.3878,
        DivDASqKM: 1225.3878,
        Tidal: 0,
        TOTMA: 0.0031792191314499998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21238.0,
        MAXELEVSMO: 21259.0,
        MINELEVSMO: 21238.0,
        SLOPE: 0.00151079,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.138999998569489,
        QA_MA: 642.171,
        VA_MA: 1.6642,
        QC_MA: 605.151,
        VC_MA: 1.6577,
        QE_MA: 619.287,
        VE_MA: 1.66022,
        QA_01: 1173.593,
        VA_01: 2.17584,
        QC_01: 884.335,
        VC_01: 1.95881,
        QE_01: 888.644,
        VE_01: 1.94597,
        QA_02: 1224.617,
        VA_02: 2.21853,
        QC_02: 982.893,
        VC_02: 2.05406,
        QE_02: 970.776,
        VE_02: 2.02467,
        QA_03: 1323.2,
        VA_03: 2.29869,
        QC_03: 1062.766,
        VC_03: 2.128,
        QE_03: 1075.641,
        VE_03: 2.12074,
        QA_04: 929.082,
        VA_04: 1.95797,
        QC_04: 832.39,
        VC_04: 1.9066,
        QE_04: 863.517,
        VE_04: 1.92122,
        QA_05: 667.05,
        VA_05: 1.69184,
        QC_05: 593.943,
        VC_05: 1.64437,
        QE_05: 640.806,
        VE_05: 1.68497,
        QA_06: 382.408,
        VA_06: 1.33797,
        QC_06: 470.291,
        VC_06: 1.48862,
        QE_06: 515.298,
        VE_06: 1.53451,
        QA_07: 230.692,
        VA_07: 1.09563,
        QC_07: 337.847,
        VC_07: 1.29852,
        QE_07: 368.803,
        VE_07: 1.33498,
        QA_08: 160.575,
        VA_08: 0.95785,
        QC_08: 368.753,
        VC_08: 1.34563,
        QE_08: 390.382,
        VE_08: 1.36645,
        QA_09: 136.975,
        VA_09: 0.90528,
        QC_09: 301.158,
        VC_09: 1.23989,
        QE_09: 286.121,
        VE_09: 1.20552,
        QA_10: 157.284,
        VA_10: 0.95074,
        QC_10: 312.714,
        VC_10: 1.2587,
        QE_10: 296.015,
        VE_10: 1.22186,
        QA_11: 476.185,
        VA_11: 1.46513,
        QC_11: 421.288,
        VC_11: 1.42164,
        QE_11: 348.407,
        VE_11: 1.30443,
        QA_12: 848.776,
        VA_12: 1.88065,
        QC_12: 610.816,
        VC_12: 1.66439,
        QE_12: 576.677,
        VE_12: 1.61001,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.229870517688198, 35.406623878375228, 0.0],
          [-81.228702984356744, 35.405811678376381, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288444.0,
        COMID: 9736234,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.059,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00064917803649499999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059880.0,
        ToNode: 250059886.0,
        Hydroseq: 250006574.0,
        LevelPathI: 250003258.0,
        Pathlength: 425.536,
        TerminalPa: 250002604.0,
        ArbolateSu: 5994.559,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006606.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006541.0,
        FromMeas: 45.30586,
        ToMeas: 46.49272,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1089,
        TotDASqKM: 7951.6575,
        DivDASqKM: 7951.6575,
        Tidal: 0,
        TOTMA: 0.0017925258547099999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14386.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.059000000357628,
        QA_MA: 4122.421,
        VA_MA: 1.24579,
        QC_MA: 4122.421,
        VC_MA: 1.24579,
        QE_MA: 4347.986,
        VE_MA: 1.24985,
        QA_01: 7339.482,
        VA_01: 1.58174,
        QC_01: 7339.482,
        VC_01: 1.58174,
        QE_01: 5918.227,
        VE_01: 1.41732,
        QA_02: 7766.762,
        VA_02: 1.62058,
        QC_02: 7766.762,
        VC_02: 1.62058,
        QE_02: 6094.245,
        VE_02: 1.43471,
        QA_03: 8565.049,
        VA_03: 1.69055,
        QC_03: 8565.049,
        VC_03: 1.69055,
        QE_03: 6195.358,
        VE_03: 1.44459,
        QA_04: 6071.562,
        VA_04: 1.45975,
        QC_04: 6071.562,
        VC_04: 1.45975,
        QE_04: 5388.121,
        VE_04: 1.36341,
        QA_05: 4268.529,
        VA_05: 1.26329,
        QC_05: 4268.529,
        VC_05: 1.26329,
        QE_05: 4487.801,
        VE_05: 1.2658,
        QA_06: 2501.074,
        VA_06: 1.02732,
        QC_06: 2501.074,
        VC_06: 1.02732,
        QE_06: 4178.377,
        VE_06: 1.23016,
        QA_07: 1523.442,
        VA_07: 0.86092,
        QC_07: 1523.442,
        VC_07: 0.86092,
        QE_07: 3149.638,
        VE_07: 1.10171,
        QA_08: 1078.616,
        VA_08: 0.76836,
        QC_08: 1078.616,
        VC_08: 0.76836,
        QE_08: 3083.879,
        VE_08: 1.09287,
        QA_09: 996.809,
        VA_09: 0.74949,
        QC_09: 996.809,
        VC_09: 0.74949,
        QE_09: 2576.404,
        VE_09: 1.02144,
        QA_10: 1120.703,
        VA_10: 0.77781,
        QC_10: 1120.703,
        VC_10: 0.77781,
        QE_10: 3403.56,
        VE_10: 1.13505,
        QA_11: 2994.261,
        VA_11: 1.09942,
        QC_11: 2994.261,
        VC_11: 1.09942,
        QE_11: 3616.034,
        VE_11: 1.16206,
        QA_12: 5270.887,
        VA_12: 1.37646,
        QC_12: 5270.887,
        VC_12: 1.37646,
        QE_12: 4431.117,
        VE_12: 1.25936,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.921140118167443, 34.950557412416458, 0.0],
          [-80.920493984835161, 34.950620212416311, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286186.0,
        COMID: 9747410,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.025,
        REACHCODE: "03050102000016",
        FLOWDIR: "With Digitized",
        WBAREACOMI: -9998,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00026880077671199998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060465.0,
        ToNode: 250060466.0,
        Hydroseq: 250013382.0,
        LevelPathI: 250011553.0,
        Pathlength: 507.074,
        TerminalPa: 250002604.0,
        ArbolateSu: 965.89,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250013484.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013281.0,
        FromMeas: 87.28113,
        ToMeas: 88.14704,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 1220.2587,
        DivDASqKM: 1220.2587,
        Tidal: 0,
        TOTMA: 0.0010357166246000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 21772.0,
        MAXELEVSMO: 21772.0,
        MINELEVSMO: 21772.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.025000000372528999,
        QA_MA: 639.878,
        VA_MA: 0.91836,
        QC_MA: 603.073,
        VC_MA: 0.91544,
        QE_MA: 617.2,
        VE_MA: 0.91658,
        QA_01: 1169.01,
        VA_01: 1.1484,
        QC_01: 880.973,
        VC_01: 1.05077,
        QE_01: 885.284,
        VE_01: 1.04498,
        QA_02: 1219.841,
        VA_02: 1.1676,
        QC_02: 979.164,
        VC_02: 1.09361,
        QE_02: 967.051,
        VE_02: 1.08036,
        QA_03: 1318.042,
        VA_03: 1.20366,
        QC_03: 1058.76,
        VC_03: 1.12689,
        QE_03: 1071.633,
        VE_03: 1.12362,
        QA_04: 925.786,
        VA_04: 1.05054,
        QC_04: 829.504,
        VC_04: 1.02741,
        QE_04: 860.632,
        VE_04: 1.03402,
        QA_05: 664.97,
        VA_05: 0.93095,
        QC_05: 592.16,
        VC_05: 0.90958,
        QE_05: 639.007,
        VE_05: 0.9279,
        QA_06: 381.185,
        VA_06: 0.77168,
        QC_06: 468.808,
        VC_06: 0.83945,
        QE_06: 513.805,
        VE_06: 0.86016,
        QA_07: 229.929,
        VA_07: 0.66261,
        QC_07: 336.757,
        VC_07: 0.75389,
        QE_07: 367.699,
        VE_07: 0.77034,
        QA_08: 160.024,
        VA_08: 0.6006,
        QC_08: 367.461,
        VC_08: 0.77502,
        QE_08: 389.077,
        VE_08: 0.78442,
        QA_09: 136.492,
        VA_09: 0.57693,
        QC_09: 300.221,
        VC_09: 0.72754,
        QE_09: 285.173,
        VE_09: 0.71202,
        QA_10: 156.871,
        VA_10: 0.59753,
        QC_10: 312.057,
        VC_10: 0.73623,
        QE_10: 295.306,
        VE_10: 0.71957,
        QA_11: 475.055,
        VA_11: 0.82913,
        QC_11: 420.526,
        VC_11: 0.80967,
        QE_11: 347.609,
        VE_11: 0.75676,
        QA_12: 845.739,
        VA_12: 1.01574,
        QC_12: 608.828,
        VC_12: 0.91851,
        QE_12: 574.686,
        VE_12: 0.89396,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.246114717663033, 35.419411278355369, 0.0],
          [-81.24586858433014, 35.419396011688661, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288445.0,
        COMID: 9736230,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.329,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0033943695211959999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059878.0,
        ToNode: 250059885.0,
        Hydroseq: 250006662.0,
        LevelPathI: 250003258.0,
        Pathlength: 427.929,
        TerminalPa: 250002604.0,
        ArbolateSu: 5987.32,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006687.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006633.0,
        FromMeas: 93.40647,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1485,
        TotDASqKM: 7943.6835,
        DivDASqKM: 7943.6835,
        Tidal: 0,
        TOTMA: 0.0099972098436099995,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14389.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.32899999618530301,
        QA_MA: 4119.101,
        VA_MA: 1.24559,
        QC_MA: 4119.101,
        VC_MA: 1.24559,
        QE_MA: 4344.648,
        VE_MA: 1.24965,
        QA_01: 7332.379,
        VA_01: 1.58136,
        QC_01: 7332.379,
        VC_01: 1.58136,
        QE_01: 5911.163,
        VE_01: 1.41683,
        QA_02: 7759.331,
        VA_02: 1.6202,
        QC_02: 7759.331,
        VC_02: 1.6202,
        QE_02: 6086.863,
        VE_02: 1.43421,
        QA_03: 8557.085,
        VA_03: 1.69017,
        QC_03: 8557.085,
        VC_03: 1.69017,
        QE_03: 6187.454,
        VE_03: 1.44405,
        QA_04: 6066.8,
        VA_04: 1.45952,
        QC_04: 6066.8,
        VC_04: 1.45952,
        QE_04: 5383.364,
        VE_04: 1.36313,
        QA_05: 4265.851,
        VA_05: 1.26318,
        QC_05: 4265.851,
        VC_05: 1.26318,
        QE_05: 4485.096,
        VE_05: 1.26568,
        QA_06: 2499.464,
        VA_06: 1.02723,
        QC_06: 2499.464,
        VC_06: 1.02723,
        QE_06: 4176.707,
        VE_06: 1.23015,
        QA_07: 1522.263,
        VA_07: 0.86081,
        QC_07: 1522.263,
        VC_07: 0.86081,
        QE_07: 3148.402,
        VE_07: 1.1017,
        QA_08: 1077.758,
        VA_08: 0.76827,
        QC_08: 1077.758,
        VC_08: 0.76827,
        QE_08: 3082.958,
        VE_08: 1.0929,
        QA_09: 996.058,
        VA_09: 0.74941,
        QC_09: 996.058,
        VC_09: 0.74941,
        QE_09: 2575.596,
        VE_09: 1.02146,
        QA_10: 1120.167,
        VA_10: 0.77779,
        QC_10: 1120.167,
        VC_10: 0.77779,
        QE_10: 3402.936,
        VE_10: 1.13513,
        QA_11: 2993.287,
        VA_11: 1.09945,
        QC_11: 2993.287,
        VC_11: 1.09945,
        QE_11: 3615.023,
        VE_11: 1.1621,
        QA_12: 5266.855,
        VA_12: 1.37626,
        QC_12: 5266.855,
        VC_12: 1.37626,
        QE_12: 4427.093,
        VE_12: 1.25909,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.948375718125263, 34.956269479074194, 0.0],
          [-80.945503384796382, 34.954676079076819, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286195.0,
        COMID: 9745760,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 0.305,
        REACHCODE: "03050102000024",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0028699225205049998,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060348.0,
        ToNode: 250060390.0,
        Hydroseq: 250027950.0,
        LevelPathI: 250024237.0,
        Pathlength: 524.343,
        TerminalPa: 250002604.0,
        ArbolateSu: 140.288,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250028911.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027077.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.099,
        TotDASqKM: 197.982,
        DivDASqKM: 197.982,
        Tidal: 0,
        TOTMA: 0.010284852234799999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23479.0,
        MAXELEVSMO: 23619.0,
        MINELEVSMO: 23591.0,
        SLOPE: 0.00091803,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30500000715255698,
        QA_MA: 92.931,
        VA_MA: 1.12506,
        QC_MA: 94.396,
        VC_MA: 1.12609,
        QE_MA: 94.396,
        VE_MA: 1.12609,
        QA_01: 177.35,
        VA_01: 1.4594,
        QC_01: 140.423,
        VC_01: 1.31807,
        QE_01: 140.423,
        VE_01: 1.31807,
        QA_02: 186.17,
        VA_02: 1.48945,
        QC_02: 157.295,
        VC_02: 1.38077,
        QE_02: 157.295,
        VE_02: 1.38077,
        QA_03: 201.47,
        VA_03: 1.54004,
        QC_03: 172.19,
        VC_03: 1.43355,
        QE_03: 172.19,
        VE_03: 1.43355,
        QA_04: 137.8,
        VA_04: 1.31507,
        QC_04: 128.932,
        VC_04: 1.27332,
        QE_04: 128.932,
        VE_04: 1.27332,
        QA_05: 92.696,
        VA_05: 1.12396,
        QC_05: 88.861,
        VC_05: 1.10027,
        QE_05: 88.861,
        VE_05: 1.10027,
        QA_06: 53.915,
        VA_06: 0.92002,
        QC_06: 68.114,
        VC_06: 0.996,
        QE_06: 68.114,
        VE_06: 0.996,
        QA_07: 32.781,
        VA_07: 0.77801,
        QC_07: 50.301,
        VC_07: 0.89376,
        QE_07: 50.301,
        VE_07: 0.89376,
        QA_08: 23.097,
        VA_08: 0.69833,
        QC_08: 51.022,
        VC_08: 0.8982,
        QE_08: 51.022,
        VE_08: 0.8982,
        QA_09: 18.707,
        VA_09: 0.65703,
        QC_09: 51.93,
        VC_09: 0.90375,
        QE_09: 51.93,
        VE_09: 0.90375,
        QA_10: 15.646,
        VA_10: 0.62548,
        QC_10: 49.488,
        VC_10: 0.88872,
        QE_10: 49.488,
        VE_10: 0.88872,
        QA_11: 54.214,
        VA_11: 0.92182,
        QC_11: 80.337,
        VC_11: 1.05898,
        QE_11: 80.337,
        VE_11: 1.05898,
        QA_12: 121.885,
        VA_12: 1.25156,
        QC_12: 104.487,
        VC_12: 1.1714,
        QE_12: 104.487,
        VE_12: 1.1714,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.250169984323463, 35.540923078166827, 0.0],
          [-81.251658717654493, 35.538497011503864, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288446.0,
        COMID: 9736244,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.439,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.004625612372259,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059885.0,
        ToNode: 250059884.0,
        Hydroseq: 250006633.0,
        LevelPathI: 250003258.0,
        Pathlength: 427.49,
        TerminalPa: 250002604.0,
        ArbolateSu: 5989.712,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006662.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006606.0,
        FromMeas: 84.60883,
        ToMeas: 93.40647,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2592,
        TotDASqKM: 7944.9165,
        DivDASqKM: 7944.9165,
        Tidal: 0,
        TOTMA: 0.013339422247799999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14382.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.43900001049041698,
        QA_MA: 4119.618,
        VA_MA: 1.24562,
        QC_MA: 4119.618,
        VC_MA: 1.24562,
        QE_MA: 4345.168,
        VE_MA: 1.24968,
        QA_01: 7333.482,
        VA_01: 1.58142,
        QC_01: 7333.482,
        VC_01: 1.58142,
        QE_01: 5912.26,
        VE_01: 1.41691,
        QA_02: 7760.49,
        VA_02: 1.62026,
        QC_02: 7760.49,
        VC_02: 1.62026,
        QE_02: 6088.014,
        VE_02: 1.43428,
        QA_03: 8558.327,
        VA_03: 1.69023,
        QC_03: 8558.327,
        VC_03: 1.69023,
        QE_03: 6188.687,
        VE_03: 1.44413,
        QA_04: 6067.544,
        VA_04: 1.45956,
        QC_04: 6067.544,
        VC_04: 1.45956,
        QE_04: 5384.108,
        VE_04: 1.36317,
        QA_05: 4266.266,
        VA_05: 1.26319,
        QC_05: 4266.266,
        VC_05: 1.26319,
        QE_05: 4485.515,
        VE_05: 1.2657,
        QA_06: 2499.713,
        VA_06: 1.02724,
        QC_06: 2499.713,
        VC_06: 1.02724,
        QE_06: 4176.965,
        VE_06: 1.23015,
        QA_07: 1522.445,
        VA_07: 0.86083,
        QC_07: 1522.445,
        VC_07: 0.86083,
        QE_07: 3148.593,
        VE_07: 1.1017,
        QA_08: 1077.891,
        VA_08: 0.76828,
        QC_08: 1077.891,
        VC_08: 0.76828,
        QE_08: 3083.1,
        VE_08: 1.09289,
        QA_09: 996.174,
        VA_09: 0.74942,
        QC_09: 996.174,
        VC_09: 0.74942,
        QE_09: 2575.721,
        VE_09: 1.02146,
        QA_10: 1120.25,
        VA_10: 0.77779,
        QC_10: 1120.25,
        VC_10: 0.77779,
        QE_10: 3403.033,
        VE_10: 1.13512,
        QA_11: 2993.444,
        VA_11: 1.09944,
        QC_11: 2993.444,
        VC_11: 1.09944,
        QE_11: 3615.185,
        VE_11: 1.1621,
        QA_12: 5267.485,
        VA_12: 1.37629,
        QC_12: 5267.485,
        VC_12: 1.37629,
        QE_12: 4427.721,
        VE_12: 1.25913,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.945503384796382, 34.954676079076819, 0.0],
          [-80.941349184802846, 34.952781079079728, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286196.0,
        COMID: 9745920,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.698,
        REACHCODE: "03050102000025",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015476386244837,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060388.0,
        ToNode: 250060348.0,
        Hydroseq: 250028911.0,
        LevelPathI: 250024237.0,
        Pathlength: 524.648,
        TerminalPa: 250002604.0,
        ArbolateSu: 127.557,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250029959.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250027950.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7883,
        TotDASqKM: 177.7887,
        DivDASqKM: 177.7887,
        Tidal: 0,
        TOTMA: 0.0578985998098,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23468.0,
        MAXELEVSMO: 23784.0,
        MINELEVSMO: 23619.0,
        SLOPE: 0.00097173,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.697999954223633,
        QA_MA: 83.436,
        VA_MA: 1.11234,
        QC_MA: 85.106,
        VC_MA: 1.11363,
        QE_MA: 85.106,
        VE_MA: 1.11363,
        QA_01: 159.036,
        VA_01: 1.44075,
        QC_01: 126.283,
        VC_01: 1.30135,
        QE_01: 126.283,
        VE_01: 1.30135,
        QA_02: 167.154,
        VA_02: 1.47108,
        QC_02: 141.644,
        VC_02: 1.36376,
        QE_02: 141.644,
        VE_02: 1.36376,
        QA_03: 180.795,
        VA_03: 1.52054,
        QC_03: 155.072,
        VC_03: 1.41575,
        QE_03: 155.072,
        VE_03: 1.41575,
        QA_04: 124.043,
        VA_04: 1.30077,
        QC_04: 116.338,
        VC_04: 1.25903,
        QE_04: 116.338,
        VE_04: 1.25903,
        QA_05: 83.452,
        VA_05: 1.11242,
        QC_05: 80.314,
        VC_05: 1.08923,
        QE_05: 80.314,
        VE_05: 1.08923,
        QA_06: 48.516,
        VA_06: 0.91122,
        QC_06: 61.381,
        VC_06: 0.98535,
        QE_06: 61.381,
        VE_06: 0.98535,
        QA_07: 29.523,
        VA_07: 0.77146,
        QC_07: 45.415,
        VC_07: 0.88527,
        QE_07: 45.415,
        VE_07: 0.88527,
        QA_08: 20.668,
        VA_08: 0.69158,
        QC_08: 45.553,
        VC_08: 0.8862,
        QE_08: 45.553,
        VE_08: 0.8862,
        QA_09: 16.807,
        VA_09: 0.65173,
        QC_09: 47.243,
        VC_09: 0.89749,
        QE_09: 47.243,
        VE_09: 0.89749,
        QA_10: 14.017,
        VA_10: 0.62018,
        QC_10: 45.327,
        VC_10: 0.88468,
        QE_10: 45.327,
        VE_10: 0.88468,
        QA_11: 48.482,
        VA_11: 0.91099,
        QC_11: 73.774,
        VC_11: 1.05479,
        QE_11: 73.774,
        VE_11: 1.05479,
        QA_12: 109.238,
        VA_12: 1.236,
        QC_12: 94.576,
        VC_12: 1.16003,
        QE_12: 94.576,
        VE_12: 1.16003,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.247440917661038, 35.555489678144227, 0.0],
          [-81.249509317657839, 35.552052078149529, 0.0],
          [-81.250169984323463, 35.540923078166827, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288439.0,
        COMID: 9735790,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.064,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000617733566266,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059750.0,
        ToNode: 250059752.0,
        Hydroseq: 250004730.0,
        LevelPathI: 250004717.0,
        Pathlength: 371.901,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.485,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004765.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 250004715.0,
        DnDrainCou: 2,
        DnHydroseq: 250004717.0,
        FromMeas: 10.81391,
        ToMeas: 17.52481,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 10619.9523,
        DivDASqKM: 0.3186,
        Tidal: 0,
        TOTMA: 0.0061270975375199996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8777.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064000003039836995,
        QA_MA: 0.105,
        VA_MA: 0.39664,
        QC_MA: 0.105,
        VC_MA: 0.39664,
        QE_MA: 0.105,
        VE_MA: 0.39664,
        QA_01: 0.227,
        VA_01: 0.44129,
        QC_01: 0.227,
        VC_01: 0.44129,
        QE_01: 0.227,
        VE_01: 0.44129,
        QA_02: 0.252,
        VA_02: 0.44887,
        QC_02: 0.252,
        VC_02: 0.44887,
        QE_02: 0.252,
        VE_02: 0.44887,
        QA_03: 0.278,
        VA_03: 0.45639,
        QC_03: 0.278,
        VC_03: 0.45639,
        QE_03: 0.278,
        VE_03: 0.45639,
        QA_04: 0.145,
        VA_04: 0.41314,
        QC_04: 0.145,
        VC_04: 0.41314,
        QE_04: 0.145,
        VE_04: 0.41314,
        QA_05: 0.081,
        VA_05: 0.38528,
        QC_05: 0.081,
        VC_05: 0.38528,
        QE_05: 0.081,
        VE_05: 0.38528,
        QA_06: 0.055,
        VA_06: 0.371,
        QC_06: 0.055,
        VC_06: 0.371,
        QE_06: 0.055,
        VE_06: 0.371,
        QA_07: 0.042,
        VA_07: 0.36264,
        QC_07: 0.042,
        VC_07: 0.36264,
        QE_07: 0.042,
        VE_07: 0.36264,
        QA_08: 0.034,
        VA_08: 0.35688,
        QC_08: 0.034,
        VC_08: 0.35688,
        QE_08: 0.034,
        VE_08: 0.35688,
        QA_09: 0.025,
        VA_09: 0.34958,
        QC_09: 0.025,
        VC_09: 0.34958,
        QE_09: 0.025,
        VE_09: 0.34958,
        QA_10: 0.021,
        VA_10: 0.34594,
        QC_10: 0.021,
        VC_10: 0.34594,
        QE_10: 0.021,
        VE_10: 0.34594,
        QA_11: 0.017,
        VA_11: 0.34195,
        QC_11: 0.017,
        VC_11: 0.34195,
        QE_11: 0.017,
        VE_11: 0.34195,
        QA_12: 0.079,
        VA_12: 0.38427,
        QC_12: 0.079,
        VC_12: 0.38427,
        QE_12: 0.079,
        VE_12: 0.38427,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881003718229863, 34.572387879670146, 0.0],
          [-80.880616118230435, 34.571906879670848, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286197.0,
        COMID: 9745728,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.415,
        REACHCODE: "03050102000026",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013204925692052,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060341.0,
        ToNode: 250060388.0,
        Hydroseq: 250029959.0,
        LevelPathI: 250024237.0,
        Pathlength: 526.346,
        TerminalPa: 250002604.0,
        ArbolateSu: 124.349,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250031123.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250028911.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9755,
        TotDASqKM: 175.0995,
        DivDASqKM: 175.0995,
        Tidal: 0,
        TOTMA: 0.048074429919800002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23638.0,
        MAXELEVSMO: 23929.0,
        MINELEVSMO: 23784.0,
        SLOPE: 0.00102473,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4149999618530269,
        QA_MA: 82.184,
        VA_MA: 1.11633,
        QC_MA: 83.878,
        VC_MA: 1.11767,
        QE_MA: 83.878,
        VE_MA: 1.11767,
        QA_01: 156.614,
        VA_01: 1.44623,
        QC_01: 124.409,
        VC_01: 1.3061,
        QE_01: 124.409,
        VE_01: 1.3061,
        QA_02: 164.623,
        VA_02: 1.47677,
        QC_02: 139.557,
        VC_02: 1.36887,
        QE_02: 139.557,
        VE_02: 1.36887,
        QA_03: 178.055,
        VA_03: 1.52646,
        QC_03: 152.799,
        VC_03: 1.42117,
        QE_03: 152.799,
        VE_03: 1.42117,
        QA_04: 122.229,
        VA_04: 1.3059,
        QC_04: 114.675,
        VC_04: 1.26386,
        QE_04: 114.675,
        VE_04: 1.26386,
        QA_05: 82.222,
        VA_05: 1.11653,
        QC_05: 79.175,
        VC_05: 1.09325,
        QE_05: 79.175,
        VE_05: 1.09325,
        QA_06: 47.807,
        VA_06: 0.91434,
        QC_06: 60.497,
        VC_06: 0.98875,
        QE_06: 60.497,
        VE_06: 0.98875,
        QA_07: 29.089,
        VA_07: 0.77383,
        QC_07: 44.764,
        VC_07: 0.88819,
        QE_07: 44.764,
        VE_07: 0.88819,
        QA_08: 20.352,
        VA_08: 0.69342,
        QC_08: 44.843,
        VC_08: 0.88874,
        QE_08: 44.843,
        VE_08: 0.88874,
        QA_09: 16.554,
        VA_09: 0.65342,
        QC_09: 46.614,
        VC_09: 0.9008,
        QE_09: 46.614,
        VE_09: 0.9008,
        QA_10: 13.8,
        VA_10: 0.62165,
        QC_10: 44.766,
        VC_10: 0.88821,
        QE_10: 44.766,
        VE_10: 0.88821,
        QA_11: 47.784,
        VA_11: 0.91418,
        QC_11: 72.963,
        VC_11: 1.05992,
        QE_11: 72.963,
        VE_11: 1.05992,
        QA_12: 107.585,
        VA_12: 1.24054,
        QC_12: 93.272,
        VC_12: 1.1646,
        QE_12: 93.272,
        VE_12: 1.1646,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.24462731766539, 35.566852211459889, 0.0],
          [-81.244054317666269, 35.55856207813946, 0.0],
          [-81.247440917661038, 35.555489678144227, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288440.0,
        COMID: 9735734,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.19,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001815405170753,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059746.0,
        ToNode: 250059747.0,
        Hydroseq: 250004823.0,
        LevelPathI: 250004717.0,
        Pathlength: 372.198,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.888,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004884.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 3,
        DnHydroseq: 250004807.0,
        FromMeas: 41.95669,
        ToMeas: 61.78503,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 10619.8992,
        DivDASqKM: 0.2655,
        Tidal: 0,
        TOTMA: 0.0136033525551,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9081.0,
        MAXELEVSMO: 9163.0,
        MINELEVSMO: 9081.0,
        SLOPE: 0.00431578,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18999999761581399,
        QA_MA: 0.087,
        VA_MA: 0.53037,
        QC_MA: 0.087,
        VC_MA: 0.53037,
        QE_MA: 0.087,
        VE_MA: 0.53037,
        QA_01: 0.189,
        VA_01: 0.64353,
        QC_01: 0.189,
        VC_01: 0.64353,
        QE_01: 0.189,
        VE_01: 0.64353,
        QA_02: 0.21,
        VA_02: 0.66281,
        QC_02: 0.21,
        VC_02: 0.66281,
        QE_02: 0.21,
        VE_02: 0.66281,
        QA_03: 0.232,
        VA_03: 0.68207,
        QC_03: 0.232,
        VC_03: 0.68207,
        QE_03: 0.232,
        VE_03: 0.68207,
        QA_04: 0.121,
        VA_04: 0.57286,
        QC_04: 0.121,
        VC_04: 0.57286,
        QE_04: 0.121,
        VE_04: 0.57286,
        QA_05: 0.067,
        VA_05: 0.50162,
        QC_05: 0.067,
        VC_05: 0.50162,
        QE_05: 0.067,
        VE_05: 0.50162,
        QA_06: 0.046,
        VA_06: 0.46664,
        QC_06: 0.046,
        VC_06: 0.46664,
        QE_06: 0.046,
        VE_06: 0.46664,
        QA_07: 0.035,
        VA_07: 0.44527,
        QC_07: 0.035,
        VC_07: 0.44527,
        QE_07: 0.035,
        VE_07: 0.44527,
        QA_08: 0.028,
        VA_08: 0.42997,
        QC_08: 0.028,
        VC_08: 0.42997,
        QE_08: 0.028,
        VE_08: 0.42997,
        QA_09: 0.021,
        VA_09: 0.41275,
        QC_09: 0.021,
        VC_09: 0.41275,
        QE_09: 0.021,
        VE_09: 0.41275,
        QA_10: 0.017,
        VA_10: 0.40167,
        QC_10: 0.017,
        VC_10: 0.40167,
        QE_10: 0.017,
        VE_10: 0.40167,
        QA_11: 0.014,
        VA_11: 0.39253,
        QC_11: 0.014,
        VC_11: 0.39253,
        QE_11: 0.014,
        VE_11: 0.39253,
        QA_12: 0.066,
        VA_12: 0.50008,
        QC_12: 0.066,
        VC_12: 0.50008,
        QE_12: 0.066,
        VE_12: 0.50008,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883773184892107, 34.575365479665493, 0.0],
          [-80.882720718227176, 34.573922479667772, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286198.0,
        COMID: 9745698,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.084,
        REACHCODE: "03050102000027",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010059980477974,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060335.0,
        ToNode: 250060341.0,
        Hydroseq: 250031123.0,
        LevelPathI: 250024237.0,
        Pathlength: 527.761,
        TerminalPa: 250002604.0,
        ArbolateSu: 121.25,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250032422.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250029959.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.954,
        TotDASqKM: 171.6372,
        DivDASqKM: 171.6372,
        Tidal: 0,
        TOTMA: 0.037354814203100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23731.0,
        MAXELEVSMO: 24030.0,
        MINELEVSMO: 23929.0,
        SLOPE: 0.00093173,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0839999914169309,
        QA_MA: 80.571,
        VA_MA: 1.10057,
        QC_MA: 82.295,
        VC_MA: 1.10193,
        QE_MA: 82.295,
        VE_MA: 1.10193,
        QA_01: 153.495,
        VA_01: 1.42386,
        QC_01: 121.996,
        VC_01: 1.28642,
        QE_01: 121.996,
        VE_01: 1.28642,
        QA_02: 161.364,
        VA_02: 1.45387,
        QC_02: 136.869,
        VC_02: 1.34803,
        QE_02: 136.869,
        VE_02: 1.34803,
        QA_03: 174.528,
        VA_03: 1.50257,
        QC_03: 149.871,
        VC_03: 1.39935,
        QE_03: 149.871,
        VE_03: 1.39935,
        QA_04: 119.873,
        VA_04: 1.28662,
        QC_04: 112.514,
        VC_04: 1.24529,
        QE_04: 112.514,
        VE_04: 1.24529,
        QA_05: 80.625,
        VA_05: 1.10085,
        QC_05: 77.694,
        VC_05: 1.07805,
        QE_05: 77.694,
        VE_05: 1.07805,
        QA_06: 46.893,
        VA_06: 0.90268,
        QC_06: 59.356,
        VC_06: 0.97552,
        QE_06: 59.356,
        VE_06: 0.97552,
        QA_07: 28.53,
        VA_07: 0.76485,
        QC_07: 43.925,
        VC_07: 0.87696,
        QE_07: 43.925,
        VE_07: 0.87696,
        QA_08: 19.95,
        VA_08: 0.68588,
        QC_08: 43.94,
        VC_08: 0.87706,
        QE_08: 43.94,
        VE_08: 0.87706,
        QA_09: 16.228,
        VA_09: 0.64668,
        QC_09: 45.803,
        VC_09: 0.88974,
        QE_09: 45.803,
        VE_09: 0.88974,
        QA_10: 13.521,
        VA_10: 0.61544,
        QC_10: 44.041,
        VC_10: 0.87775,
        QE_10: 44.041,
        VE_10: 0.87775,
        QA_11: 46.871,
        VA_11: 0.90253,
        QC_11: 71.897,
        VC_11: 1.047,
        QE_11: 71.897,
        VE_11: 1.047,
        QA_12: 105.462,
        VA_12: 1.2223,
        QC_12: 91.596,
        VC_12: 1.14835,
        QE_12: 91.596,
        VE_12: 1.14835,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.24136098433712, 35.575787411446015, 0.0],
          [-81.245051917664625, 35.56957721145568, 0.0],
          [-81.24462731766539, 35.566852211459889, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288441.0,
        COMID: 9736568,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.315,
        REACHCODE: "03050103001299",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012779417868829,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060035.0,
        ToNode: 250060038.0,
        Hydroseq: 250005119.0,
        LevelPathI: 250003258.0,
        Pathlength: 384.586,
        TerminalPa: 250002604.0,
        ArbolateSu: 7524.495,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005129.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005106.0,
        FromMeas: 0.0,
        ToMeas: 50.06876,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.3905,
        TotDASqKM: 9828.4833,
        DivDASqKM: 9828.4833,
        Tidal: 0,
        TOTMA: 0.036501522800700001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12636.0,
        MAXELEVSMO: 12644.0,
        MINELEVSMO: 12642.0,
        SLOPE: 1.52e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.315000057220459,
        QA_MA: 4873.025,
        VA_MA: 1.35651,
        QC_MA: 4873.025,
        VC_MA: 1.35651,
        QE_MA: 5575.064,
        VE_MA: 1.368,
        QA_01: 8961.275,
        VA_01: 1.75683,
        QC_01: 8961.275,
        VC_01: 1.75683,
        QE_01: 7524.997,
        VE_01: 1.55094,
        QA_02: 9467.647,
        VA_02: 1.79973,
        QC_02: 9467.647,
        VC_02: 1.79973,
        QE_02: 7655.569,
        VE_02: 1.56234,
        QA_03: 10389.293,
        VA_03: 1.87514,
        QC_03: 10389.293,
        VC_03: 1.87514,
        QE_03: 8152.206,
        VE_03: 1.6049,
        QA_04: 7136.939,
        VA_04: 1.59192,
        QC_04: 7136.939,
        VC_04: 1.59192,
        QE_04: 6915.373,
        VE_04: 1.49643,
        QA_05: 4864.788,
        VA_05: 1.35557,
        QC_05: 4864.788,
        VC_05: 1.35557,
        QE_05: 5850.401,
        VE_05: 1.39548,
        QA_06: 2873.983,
        VA_06: 1.10025,
        QC_06: 2873.983,
        VC_06: 1.10025,
        QE_06: 5113.802,
        VE_06: 1.32051,
        QA_07: 1792.569,
        VA_07: 0.92468,
        QC_07: 1792.569,
        VC_07: 0.92468,
        QE_07: 3884.958,
        VE_07: 1.18308,
        QA_08: 1276.446,
        VA_08: 0.823,
        QC_08: 1276.446,
        VC_08: 0.823,
        QE_08: 3615.356,
        VE_08: 1.1503,
        QA_09: 1160.249,
        VA_09: 0.79757,
        QC_09: 1160.249,
        VC_09: 0.79757,
        QE_09: 3305.511,
        VE_09: 1.11119,
        QA_10: 1247.32,
        VA_10: 0.81673,
        QC_10: 1247.32,
        VC_10: 0.81673,
        QE_10: 4492.068,
        VE_10: 1.25319,
        QA_11: 3177.121,
        VA_11: 1.14356,
        QC_11: 3177.121,
        VC_11: 1.14356,
        QE_11: 4554.37,
        VE_11: 1.26012,
        QA_12: 6162.049,
        VA_12: 1.49562,
        QC_12: 6162.049,
        VC_12: 1.49562,
        QE_12: 5870.326,
        VE_12: 1.39744,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878291384900663, 34.67855921283865, 0.0],
          [-80.881070984896326, 34.672945279514067, 0.0],
          [-80.886362718221449, 34.669313479519644, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286191.0,
        COMID: 9745928,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.325,
        REACHCODE: "03050102000021",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012214938163343,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060392.0,
        ToNode: 250060403.0,
        Hydroseq: 250025538.0,
        LevelPathI: 250024237.0,
        Pathlength: 519.862,
        TerminalPa: 250002604.0,
        ArbolateSu: 155.569,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250026280.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024861.0,
        FromMeas: 63.48085,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7279,
        TotDASqKM: 220.0113,
        DivDASqKM: 220.0113,
        Tidal: 0,
        TOTMA: 0.045183657238200003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23088.0,
        MAXELEVSMO: 23183.0,
        MINELEVSMO: 23088.0,
        SLOPE: 0.00071698,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.325000047683716,
        QA_MA: 103.174,
        VA_MA: 1.11279,
        QC_MA: 104.376,
        VC_MA: 1.11354,
        QE_MA: 104.376,
        VE_MA: 1.11354,
        QA_01: 197.237,
        VA_01: 1.44315,
        QC_01: 155.735,
        VC_01: 1.30416,
        QE_01: 155.735,
        VE_01: 1.30416,
        QA_02: 206.86,
        VA_02: 1.47221,
        QC_02: 174.275,
        VC_02: 1.36544,
        QE_02: 174.275,
        VE_02: 1.36544,
        QA_03: 223.941,
        VA_03: 1.52229,
        QC_03: 190.728,
        VC_03: 1.41731,
        QE_03: 190.728,
        VE_03: 1.41731,
        QA_04: 152.529,
        VA_04: 1.29836,
        QC_04: 142.385,
        VC_04: 1.25788,
        QE_04: 142.385,
        VE_04: 1.25788,
        QA_05: 102.696,
        VA_05: 1.11081,
        QC_05: 98.071,
        VC_05: 1.08734,
        QE_05: 98.071,
        VE_05: 1.08734,
        QA_06: 59.604,
        VA_06: 0.90947,
        QC_06: 75.197,
        VC_06: 0.98488,
        QE_06: 75.197,
        VE_06: 0.98488,
        QA_07: 36.335,
        VA_07: 0.77055,
        QC_07: 55.618,
        VC_07: 0.88477,
        QE_07: 55.618,
        VE_07: 0.88477,
        QA_08: 25.624,
        VA_08: 0.69232,
        QC_08: 56.721,
        VC_08: 0.89081,
        QE_08: 56.721,
        VE_08: 0.89081,
        QA_09: 20.78,
        VA_09: 0.65189,
        QC_09: 56.979,
        VC_09: 0.89221,
        QE_09: 56.979,
        VE_09: 0.89221,
        QA_10: 17.423,
        VA_10: 0.62121,
        QC_10: 53.929,
        VC_10: 0.87541,
        QE_10: 53.929,
        VE_10: 0.87541,
        QA_11: 60.068,
        VA_11: 0.91195,
        QC_11: 86.871,
        VC_11: 1.03876,
        QE_11: 86.871,
        VE_11: 1.03876,
        QA_12: 135.523,
        VA_12: 1.23813,
        QC_12: 115.073,
        VC_12: 1.15635,
        QE_12: 115.073,
        VE_12: 1.15635,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.249277717658174, 35.511262678212745, 0.0],
          [-81.254295184317073, 35.500135611563337, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288442.0,
        COMID: 9736560,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.312,
        REACHCODE: "03050103001299",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.012707242920590001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060031.0,
        ToNode: 250060035.0,
        Hydroseq: 250005129.0,
        LevelPathI: 250003258.0,
        Pathlength: 385.901,
        TerminalPa: 250002604.0,
        ArbolateSu: 7520.909,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005140.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005119.0,
        FromMeas: 50.06876,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.5471,
        TotDASqKM: 9825.4872,
        DivDASqKM: 9825.4872,
        Tidal: 0,
        TOTMA: 0.032463958308600001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12638.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12644.0,
        SLOPE: 3.81e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.312000036239624,
        QA_MA: 4871.912,
        VA_MA: 1.52133,
        QC_MA: 4871.912,
        VC_MA: 1.52133,
        QE_MA: 5573.949,
        VE_MA: 1.53463,
        QA_01: 8958.831,
        VA_01: 1.98455,
        QC_01: 8958.831,
        VC_01: 1.98455,
        QE_01: 7522.558,
        VE_01: 1.74623,
        QA_02: 9465.042,
        VA_02: 2.03419,
        QC_02: 9465.042,
        VC_02: 2.03419,
        QE_02: 7652.971,
        VE_02: 1.75942,
        QA_03: 10386.472,
        VA_03: 2.12146,
        QC_03: 10386.472,
        VC_03: 2.12146,
        QE_03: 8149.394,
        VE_03: 1.80866,
        QA_04: 7135.366,
        VA_04: 1.79376,
        QC_04: 7135.366,
        VC_04: 1.79376,
        QE_04: 6913.803,
        VE_04: 1.68323,
        QA_05: 4863.902,
        VA_05: 1.52027,
        QC_05: 4863.902,
        VC_05: 1.52027,
        QE_05: 5849.514,
        VE_05: 1.56645,
        QA_06: 2873.419,
        VA_06: 1.22478,
        QC_06: 2873.419,
        VC_06: 1.22478,
        QE_06: 5113.233,
        VE_06: 1.47972,
        QA_07: 1792.166,
        VA_07: 1.0216,
        QC_07: 1792.166,
        VC_07: 1.0216,
        QE_07: 3884.55,
        VE_07: 1.32067,
        QA_08: 1276.124,
        VA_08: 0.90391,
        QC_08: 1276.124,
        VC_08: 0.90391,
        QE_08: 3615.027,
        VE_08: 1.28275,
        QA_09: 1160.007,
        VA_09: 0.8745,
        QC_09: 1160.007,
        VC_09: 0.8745,
        QE_09: 3305.264,
        VE_09: 1.23749,
        QA_10: 1247.119,
        VA_10: 0.89669,
        QC_10: 1247.119,
        VC_10: 0.89669,
        QE_10: 4491.858,
        VE_10: 1.40184,
        QA_11: 3176.96,
        VA_11: 1.27497,
        QC_11: 3176.96,
        VC_11: 1.27497,
        QE_11: 4554.204,
        VE_11: 1.40988,
        QA_12: 6160.894,
        VA_12: 1.68234,
        QC_12: 6160.894,
        VC_12: 1.68234,
        QE_12: 5869.173,
        VE_12: 1.5687,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.871767184910823, 34.68789521282423, 0.0],
          [-80.878400118233856, 34.682240679499614, 0.0],
          [-80.878291384900663, 34.67855921283865, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286192.0,
        COMID: 9747184,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 2.299,
        REACHCODE: "03050102000021",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.021838174508588999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060403.0,
        ToNode: 250060406.0,
        Hydroseq: 250024861.0,
        LevelPathI: 250024237.0,
        Pathlength: 517.563,
        TerminalPa: 250002604.0,
        ArbolateSu: 157.868,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250025538.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250024237.0,
        FromMeas: 0.0,
        ToMeas: 63.48085,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.7333,
        TotDASqKM: 222.7446,
        DivDASqKM: 222.7446,
        Tidal: 0,
        TOTMA: 0.08152251852,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22880.0,
        MAXELEVSMO: 23088.0,
        MINELEVSMO: 22973.0,
        SLOPE: 0.00050021,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2990000247955318,
        QA_MA: 104.426,
        VA_MA: 1.07017,
        QC_MA: 105.593,
        VC_MA: 1.07086,
        QE_MA: 105.593,
        VE_MA: 1.07086,
        QA_01: 199.699,
        VA_01: 1.38323,
        QC_01: 157.628,
        VC_01: 1.25161,
        QE_01: 157.628,
        VE_01: 1.25161,
        QA_02: 209.396,
        VA_02: 1.41063,
        QC_02: 176.353,
        VC_02: 1.30954,
        QE_02: 176.353,
        VE_02: 1.30954,
        QA_03: 226.698,
        VA_03: 1.45809,
        QC_03: 192.997,
        VC_03: 1.35866,
        QE_03: 192.997,
        VE_03: 1.35866,
        QA_04: 154.33,
        VA_04: 1.24576,
        QC_04: 144.027,
        VC_04: 1.20749,
        QE_04: 144.027,
        VE_04: 1.20749,
        QA_05: 103.921,
        VA_05: 1.06822,
        QC_05: 99.196,
        VC_05: 1.04597,
        QE_05: 99.196,
        VE_05: 1.04597,
        QA_06: 60.302,
        VA_06: 0.8775,
        QC_06: 76.066,
        VC_06: 0.94898,
        QE_06: 76.066,
        VE_06: 0.94898,
        QA_07: 36.776,
        VA_07: 0.74607,
        QC_07: 56.277,
        VC_07: 0.85427,
        QE_07: 56.277,
        VE_07: 0.85427,
        QA_08: 25.918,
        VA_08: 0.67186,
        QC_08: 57.385,
        VC_08: 0.85995,
        QE_08: 57.385,
        VE_08: 0.85995,
        QA_09: 21.038,
        VA_09: 0.63374,
        QC_09: 57.602,
        VC_09: 0.86106,
        QE_09: 57.602,
        VE_09: 0.86106,
        QA_10: 17.644,
        VA_10: 0.60473,
        QC_10: 54.474,
        VC_10: 0.84491,
        QE_10: 54.474,
        VE_10: 0.84491,
        QA_11: 60.734,
        VA_11: 0.87966,
        QC_11: 87.605,
        VC_11: 0.99887,
        QE_11: 87.605,
        VE_11: 0.99887,
        QA_12: 137.181,
        VA_12: 1.18893,
        QC_12: 116.353,
        VC_12: 1.11118,
        QE_12: 116.353,
        VE_12: 1.11118,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.254295184317073, 35.500135611563337, 0.0],
          [-81.26417451763507, 35.485703278252515, 0.0],
          [-81.264011317635322, 35.482313678257697, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288451.0,
        COMID: 9736198,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.157,
        REACHCODE: "03050103001306",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001539632129829,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059864.0,
        ToNode: 250059866.0,
        Hydroseq: 250006899.0,
        LevelPathI: 250003258.0,
        Pathlength: 432.05,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.586,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006920.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006853.0,
        FromMeas: 0.0,
        ToMeas: 38.98712,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5139,
        TotDASqKM: 7886.6784,
        DivDASqKM: 7886.6784,
        Tidal: 0,
        TOTMA: 0.00202761380737,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14947.0,
        MAXELEVSMO: 15049.0,
        MINELEVSMO: 14947.0,
        SLOPE: 0.00649681,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15700000524520899,
        QA_MA: 4094.975,
        VA_MA: 2.92886,
        QC_MA: 4094.975,
        VC_MA: 2.92886,
        QE_MA: 4320.385,
        VE_MA: 2.94026,
        QA_01: 7281.114,
        VA_01: 3.86553,
        QC_01: 7281.114,
        VC_01: 3.86553,
        QE_01: 5860.175,
        VE_01: 3.40269,
        QA_02: 7705.728,
        VA_02: 3.97421,
        QC_02: 7705.728,
        VC_02: 3.97421,
        QE_02: 6033.611,
        VE_02: 3.45098,
        QA_03: 8499.361,
        VA_03: 4.17008,
        QC_03: 8499.361,
        VC_03: 4.17008,
        QE_03: 6130.162,
        VE_03: 3.47759,
        QA_04: 6032.254,
        VA_04: 3.5273,
        QC_04: 6032.254,
        VC_04: 3.5273,
        QE_04: 5348.861,
        VE_04: 3.25626,
        QA_05: 4246.678,
        VA_05: 2.97997,
        QC_05: 4246.678,
        VC_05: 2.97997,
        QE_05: 4465.73,
        VE_05: 2.98691,
        QA_06: 2487.355,
        VA_06: 2.31938,
        QC_06: 2487.355,
        VC_06: 2.31938,
        QE_06: 4164.163,
        VE_06: 2.88929,
        QA_07: 1513.832,
        VA_07: 1.85327,
        QC_07: 1513.832,
        VC_07: 1.85327,
        QE_07: 3139.565,
        VE_07: 2.52986,
        QA_08: 1071.627,
        VA_08: 1.59435,
        QC_08: 1071.627,
        VC_08: 1.59435,
        QE_08: 3076.373,
        VE_08: 2.506,
        QA_09: 990.693,
        VA_09: 1.54183,
        QC_09: 990.693,
        VC_09: 1.54183,
        QE_09: 2569.815,
        VE_09: 2.30577,
        QA_10: 1116.335,
        VA_10: 1.62257,
        QC_10: 1116.335,
        VC_10: 1.62257,
        QE_10: 3398.475,
        VE_10: 2.62532,
        QA_11: 2985.361,
        VA_11: 2.52402,
        QC_11: 2985.361,
        VC_11: 2.52402,
        QE_11: 3606.83,
        VE_11: 2.6997,
        QA_12: 5237.329,
        VA_12: 3.29461,
        QC_12: 5237.329,
        VC_12: 3.29461,
        QE_12: 4397.622,
        VE_12: 2.96514,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.965831784764816, 34.982410479033717, 0.0],
          [-80.964736918099845, 34.981328012368579, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286193.0,
        COMID: 9745926,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.491,
        REACHCODE: "03050102000022",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013482698464872,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060391.0,
        ToNode: 250060392.0,
        Hydroseq: 250026280.0,
        LevelPathI: 250024237.0,
        Pathlength: 521.187,
        TerminalPa: 250002604.0,
        ArbolateSu: 148.914,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250027077.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250025538.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.2986,
        TotDASqKM: 208.6668,
        DivDASqKM: 208.6668,
        Tidal: 0,
        TOTMA: 0.046207622541599998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23161.0,
        MAXELEVSMO: 23444.0,
        MINELEVSMO: 23183.0,
        SLOPE: 0.0017505,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.4910000562667849,
        QA_MA: 97.894,
        VA_MA: 1.22427,
        QC_MA: 99.236,
        VC_MA: 1.22528,
        QE_MA: 99.236,
        VE_MA: 1.22528,
        QA_01: 186.975,
        VA_01: 1.5998,
        QC_01: 147.839,
        VC_01: 1.44143,
        QE_01: 147.839,
        VE_01: 1.44143,
        QA_02: 196.204,
        VA_02: 1.63326,
        QC_02: 165.536,
        VC_02: 1.51153,
        QE_02: 165.536,
        VE_02: 1.51153,
        QA_03: 212.364,
        VA_03: 1.69013,
        QC_03: 181.185,
        VC_03: 1.57065,
        QE_03: 181.185,
        VE_03: 1.57065,
        QA_04: 144.943,
        VA_04: 1.43648,
        QC_04: 135.46,
        VC_04: 1.39002,
        QE_04: 135.46,
        VE_04: 1.39002,
        QA_05: 97.549,
        VA_05: 1.22256,
        QC_05: 93.335,
        VC_05: 1.19591,
        QE_05: 93.335,
        VE_05: 1.19591,
        QA_06: 56.695,
        VA_06: 0.99368,
        QC_06: 71.576,
        VC_06: 1.07924,
        QE_06: 71.576,
        VE_06: 1.07924,
        QA_07: 34.505,
        VA_07: 0.83484,
        QC_07: 52.882,
        VC_07: 0.96478,
        QE_07: 52.882,
        VE_07: 0.96478,
        QA_08: 24.334,
        VA_08: 0.74574,
        QC_08: 53.81,
        VC_08: 0.97087,
        QE_08: 53.81,
        VE_08: 0.97087,
        QA_09: 19.713,
        VA_09: 0.69946,
        QC_09: 54.387,
        VC_09: 0.97463,
        QE_09: 54.387,
        VE_09: 0.97463,
        QA_10: 16.508,
        VA_10: 0.6643,
        QC_10: 51.654,
        VC_10: 0.95664,
        QE_10: 51.654,
        VE_10: 0.95664,
        QA_11: 57.018,
        VA_11: 0.99576,
        QC_11: 83.486,
        VC_11: 1.14489,
        QE_11: 83.486,
        VE_11: 1.14489,
        QA_12: 128.469,
        VA_12: 1.36647,
        QC_12: 109.61,
        VC_12: 1.27499,
        QE_12: 109.61,
        VE_12: 1.27499,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.248625584325794, 35.524249078192668, 0.0],
          [-81.250180917656792, 35.515523878206125, 0.0],
          [-81.249277717658174, 35.511262678212745, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288452.0,
        COMID: 9736190,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.053,
        REACHCODE: "03050103001306",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00051016875561400005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059859.0,
        ToNode: 250059862.0,
        Hydroseq: 250006944.0,
        LevelPathI: 250003258.0,
        Pathlength: 432.399,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.237,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006966.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006920.0,
        FromMeas: 86.93139,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 7886.0799,
        DivDASqKM: 7886.0799,
        Tidal: 0,
        TOTMA: 0.0016123636364799999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15091.0,
        MAXELEVSMO: 15091.0,
        MINELEVSMO: 15091.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052999999374151001,
        QA_MA: 4094.721,
        VA_MA: 1.24413,
        QC_MA: 4094.721,
        VC_MA: 1.24413,
        QE_MA: 4320.129,
        VE_MA: 1.2482,
        QA_01: 7280.575,
        VA_01: 1.57859,
        QC_01: 7280.575,
        VC_01: 1.57859,
        QE_01: 5859.639,
        VE_01: 1.41331,
        QA_02: 7705.165,
        VA_02: 1.6174,
        QC_02: 7705.165,
        VC_02: 1.6174,
        QE_02: 6033.051,
        VE_02: 1.43055,
        QA_03: 8498.751,
        VA_03: 1.68734,
        QC_03: 8498.751,
        VC_03: 1.68734,
        QE_03: 6129.557,
        VE_03: 1.44005,
        QA_04: 6031.892,
        VA_04: 1.45782,
        QC_04: 6031.892,
        VC_04: 1.45782,
        QE_04: 5348.499,
        VE_04: 1.36103,
        QA_05: 4246.477,
        VA_05: 1.26239,
        QC_05: 4246.477,
        VC_05: 1.26239,
        QE_05: 4465.527,
        VE_05: 1.26486,
        QA_06: 2487.227,
        VA_06: 1.0265,
        QC_06: 2487.227,
        VC_06: 1.0265,
        QE_06: 4164.03,
        VE_06: 1.23001,
        QA_07: 1513.744,
        VA_07: 0.86005,
        QC_07: 1513.744,
        VC_07: 0.86005,
        QE_07: 3139.472,
        VE_07: 1.10166,
        QA_08: 1071.562,
        VA_08: 0.76759,
        QC_08: 1071.562,
        VC_08: 0.76759,
        QE_08: 3076.304,
        VE_08: 1.09315,
        QA_09: 990.637,
        VA_09: 0.74884,
        QC_09: 990.637,
        VC_09: 0.74884,
        QE_09: 2569.754,
        VE_09: 1.02164,
        QA_10: 1116.295,
        VA_10: 0.77767,
        QC_10: 1116.295,
        VC_10: 0.77767,
        QE_10: 3398.428,
        VE_10: 1.13576,
        QA_11: 2985.274,
        VA_11: 1.09958,
        QC_11: 2985.274,
        VC_11: 1.09958,
        QE_11: 3606.741,
        VE_11: 1.16231,
        QA_12: 5237.015,
        VA_12: 1.37473,
        QC_12: 5237.015,
        VC_12: 1.37473,
        QE_12: 4397.308,
        VE_12: 1.25708,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.96775511809517, 34.983889879031381, 0.0],
          [-80.967414718095725, 34.983509879031942, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286194.0,
        COMID: 9745924,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983219",
        GNIS_NAME: "Clark Creek",
        LENGTHKM: 1.665,
        REACHCODE: "03050102000023",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015194590429627,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060390.0,
        ToNode: 250060391.0,
        Hydroseq: 250027077.0,
        LevelPathI: 250024237.0,
        Pathlength: 522.678,
        TerminalPa: 250002604.0,
        ArbolateSu: 145.459,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250024237.0,
        UpHydroseq: 250027950.0,
        DnLevelPat: 250024237.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250026280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.6991,
        TotDASqKM: 204.7194,
        DivDASqKM: 204.7194,
        Tidal: 0,
        TOTMA: 0.056089885898500003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23296.0,
        MAXELEVSMO: 23591.0,
        MINELEVSMO: 23444.0,
        SLOPE: 0.00088288,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6649999618530269,
        QA_MA: 96.068,
        VA_MA: 1.12625,
        QC_MA: 97.456,
        VC_MA: 1.1272,
        QE_MA: 97.456,
        VE_MA: 1.1272,
        QA_01: 183.419,
        VA_01: 1.46136,
        QC_01: 145.101,
        VC_01: 1.31991,
        QE_01: 145.101,
        VE_01: 1.31991,
        QA_02: 192.496,
        VA_02: 1.49131,
        QC_02: 162.492,
        VC_02: 1.38257,
        QE_02: 162.492,
        VE_02: 1.38257,
        QA_03: 208.338,
        VA_03: 1.54205,
        QC_03: 177.862,
        VC_03: 1.43538,
        QE_03: 177.862,
        VE_03: 1.43538,
        QA_04: 142.316,
        VA_04: 1.31603,
        QC_04: 133.06,
        VC_04: 1.27443,
        QE_04: 133.06,
        VE_04: 1.27443,
        QA_05: 95.762,
        VA_05: 1.12487,
        QC_05: 91.688,
        VC_05: 1.1011,
        QE_05: 91.688,
        VE_05: 1.1011,
        QA_06: 55.686,
        VA_06: 0.92062,
        QC_06: 70.32,
        VC_06: 0.99692,
        QE_06: 70.32,
        VE_06: 0.99692,
        QA_07: 33.868,
        VA_07: 0.77855,
        QC_07: 51.929,
        VC_07: 0.89454,
        QE_07: 51.929,
        VE_07: 0.89454,
        QA_08: 23.891,
        VA_08: 0.69902,
        QC_08: 52.811,
        VC_08: 0.8998,
        QE_08: 52.811,
        VE_08: 0.8998,
        QA_09: 19.341,
        VA_09: 0.65757,
        QC_09: 53.481,
        VC_09: 0.90377,
        QE_09: 53.481,
        VE_09: 0.90377,
        QA_10: 16.19,
        VA_10: 0.6261,
        QC_10: 50.857,
        VC_10: 0.88808,
        QE_10: 50.857,
        VE_10: 0.88808,
        QA_11: 55.996,
        VA_11: 0.92243,
        QC_11: 82.342,
        VC_11: 1.05711,
        QE_11: 82.342,
        VE_11: 1.05711,
        QA_12: 126.035,
        VA_12: 1.25308,
        QC_12: 107.719,
        VC_12: 1.17191,
        QE_12: 107.719,
        VE_12: 1.17191,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.251658717654493, 35.538497011503864, 0.0],
          [-81.252000184320593, 35.535039011509241, 0.0],
          [-81.248625584325794, 35.524249078192668, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288453.0,
        COMID: 9736192,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.192,
        REACHCODE: "03050103001306",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0019557579997859999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059862.0,
        ToNode: 250059864.0,
        Hydroseq: 250006920.0,
        LevelPathI: 250003258.0,
        Pathlength: 432.207,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.429,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006944.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006899.0,
        FromMeas: 38.98712,
        ToMeas: 86.93139,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0846,
        TotDASqKM: 7886.1645,
        DivDASqKM: 7886.1645,
        Tidal: 0,
        TOTMA: 0.0028909199702300001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15049.0,
        MAXELEVSMO: 15091.0,
        MINELEVSMO: 15049.0,
        SLOPE: 0.0021875,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.19200000166893,
        QA_MA: 4094.757,
        VA_MA: 2.51236,
        QC_MA: 4094.757,
        VC_MA: 2.51236,
        QE_MA: 4320.165,
        VE_MA: 2.52195,
        QA_01: 7280.651,
        VA_01: 3.30014,
        QC_01: 7280.651,
        VC_01: 3.30014,
        QE_01: 5859.715,
        VE_01: 2.91084,
        QA_02: 7705.245,
        VA_02: 3.39155,
        QC_02: 7705.245,
        VC_02: 3.39155,
        QE_02: 6033.131,
        VE_02: 2.95146,
        QA_03: 8498.837,
        VA_03: 3.55629,
        QC_03: 8498.837,
        VC_03: 3.55629,
        QE_03: 6129.642,
        VE_03: 2.97382,
        QA_04: 6031.943,
        VA_04: 3.01568,
        QC_04: 6031.943,
        VC_04: 3.01568,
        QE_04: 5348.55,
        VE_04: 2.78771,
        QA_05: 4246.505,
        VA_05: 2.55536,
        QC_05: 4246.505,
        VC_05: 2.55536,
        QE_05: 4465.556,
        VE_05: 2.5612,
        QA_06: 2487.245,
        VA_06: 1.99976,
        QC_06: 2487.245,
        VC_06: 1.99976,
        QE_06: 4164.049,
        VE_06: 2.47911,
        QA_07: 1513.756,
        VA_07: 1.60773,
        QC_07: 1513.756,
        VC_07: 1.60773,
        QE_07: 3139.485,
        VE_07: 2.1768,
        QA_08: 1071.571,
        VA_08: 1.38996,
        QC_08: 1071.571,
        VC_08: 1.38996,
        QE_08: 3076.314,
        VE_08: 2.15674,
        QA_09: 990.645,
        VA_09: 1.34579,
        QC_09: 990.645,
        VC_09: 1.34579,
        QE_09: 2569.762,
        VE_09: 1.98833,
        QA_10: 1116.3,
        VA_10: 1.4137,
        QC_10: 1116.3,
        VC_10: 1.4137,
        QE_10: 3398.435,
        VE_10: 2.25711,
        QA_11: 2985.286,
        VA_11: 2.17189,
        QC_11: 2985.286,
        VC_11: 2.17189,
        QE_11: 3606.753,
        VE_11: 2.31965,
        QA_12: 5237.06,
        VA_12: 2.81998,
        QC_12: 5237.06,
        VC_12: 2.81998,
        QE_12: 4397.353,
        VE_12: 2.54286,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.967414718095725, 34.983509879031942, 0.0],
          [-80.965831784764816, 34.982410479033717, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286235.0,
        COMID: 9745394,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.764,
        REACHCODE: "03050102000062",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0077398887114369999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060239.0,
        ToNode: 250060246.0,
        Hydroseq: 250016507.0,
        LevelPathI: 250011553.0,
        Pathlength: 546.382,
        TerminalPa: 250002604.0,
        ArbolateSu: 526.229,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250016714.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016308.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5697,
        TotDASqKM: 551.2932,
        DivDASqKM: 551.2932,
        Tidal: 0,
        TOTMA: 0.0223456480657,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24176.0,
        MAXELEVSMO: 24372.0,
        MINELEVSMO: 24324.0,
        SLOPE: 0.00062827,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.76399999856948897,
        QA_MA: 319.633,
        VA_MA: 1.29801,
        QC_MA: 309.473,
        VC_MA: 1.29542,
        QE_MA: 320.767,
        VE_MA: 1.29829,
        QA_01: 556.801,
        VA_01: 1.63721,
        QC_01: 427.845,
        VC_01: 1.48065,
        QE_01: 430.746,
        VE_01: 1.46604,
        QA_02: 581.774,
        VA_02: 1.66853,
        QC_02: 476.509,
        VC_02: 1.54966,
        QE_02: 472.969,
        VE_02: 1.52497,
        QA_03: 626.226,
        VA_03: 1.72276,
        QC_03: 515.547,
        VC_03: 1.60266,
        QE_03: 532.401,
        VE_03: 1.60389,
        QA_04: 461.988,
        VA_04: 1.51181,
        QC_04: 420.533,
        VC_04: 1.46997,
        QE_04: 455.409,
        VE_04: 1.50078,
        QA_05: 344.413,
        VA_05: 1.33804,
        QC_05: 314.344,
        VC_05: 1.30364,
        QE_05: 343.069,
        VE_05: 1.33426,
        QA_06: 197.948,
        VA_06: 1.07569,
        QC_06: 245.651,
        VC_06: 1.1815,
        QE_06: 278.446,
        VE_06: 1.22665,
        QA_07: 117.188,
        VA_07: 0.88925,
        QC_07: 174.425,
        VC_07: 1.03635,
        QE_07: 202.201,
        VE_07: 1.08317,
        QA_08: 80.62,
        VA_08: 0.78462,
        QC_08: 182.603,
        VC_08: 1.05428,
        QE_08: 204.107,
        VE_08: 1.08704,
        QA_09: 73.386,
        VA_09: 0.76143,
        QC_09: 173.583,
        VC_09: 1.03448,
        QE_09: 163.897,
        VE_09: 1.00141,
        QA_10: 103.23,
        VA_10: 0.85142,
        QC_10: 223.387,
        VC_10: 1.13855,
        QE_10: 167.95,
        VE_10: 1.01046,
        QA_11: 274.686,
        VA_11: 1.2215,
        QC_11: 276.922,
        VC_11: 1.23886,
        QE_11: 210.587,
        VE_11: 1.10007,
        QA_12: 419.513,
        VA_12: 1.45173,
        QC_12: 321.706,
        VC_12: 1.31595,
        QE_12: 299.402,
        VE_12: 1.26272,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.294196184255043, 35.621118811375652, 0.0],
          [-81.289544117595597, 35.617313878048094, 0.0],
          [-81.288281184264235, 35.617908478047241, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288454.0,
        COMID: 9734256,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.136,
        REACHCODE: "03050103001307",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0012898458827259999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059323.0,
        ToNode: 250059331.0,
        Hydroseq: 250005836.0,
        LevelPathI: 250005769.0,
        Pathlength: 400.955,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.947,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005769.0,
        UpHydroseq: 250005855.0,
        DnLevelPat: 250005769.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005790.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.018,
        TotDASqKM: 9187.0731,
        DivDASqKM: 0.018,
        Tidal: 0,
        TOTMA: 0.014563282101,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13633.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13600000739097601,
        QA_MA: 0.007,
        VA_MA: 0.35461,
        QC_MA: 0.007,
        VC_MA: 0.35461,
        QE_MA: 0.007,
        VE_MA: 0.35461,
        QA_01: 0.015,
        VA_01: 0.37767,
        QC_01: 0.015,
        VC_01: 0.37767,
        QE_01: 0.015,
        VE_01: 0.37767,
        QA_02: 0.016,
        VA_02: 0.38008,
        QC_02: 0.016,
        VC_02: 0.38008,
        QE_02: 0.016,
        VE_02: 0.38008,
        QA_03: 0.017,
        VA_03: 0.38243,
        QC_03: 0.017,
        VC_03: 0.38243,
        QE_03: 0.017,
        VE_03: 0.38243,
        QA_04: 0.01,
        VA_04: 0.36425,
        QC_04: 0.01,
        VC_04: 0.36425,
        QE_04: 0.01,
        VE_04: 0.36425,
        QA_05: 0.005,
        VA_05: 0.34705,
        QC_05: 0.005,
        VC_05: 0.34705,
        QE_05: 0.005,
        VE_05: 0.34705,
        QA_06: 0.003,
        VA_06: 0.33786,
        QC_06: 0.003,
        VC_06: 0.33786,
        QE_06: 0.003,
        VE_06: 0.33786,
        QA_07: 0.002,
        VA_07: 0.33215,
        QC_07: 0.002,
        VC_07: 0.33215,
        QE_07: 0.002,
        VE_07: 0.33215,
        QA_08: 0.001,
        VA_08: 0.32484,
        QC_08: 0.001,
        VC_08: 0.32484,
        QE_08: 0.001,
        VE_08: 0.32484,
        QA_09: 0.001,
        VA_09: 0.32484,
        QC_09: 0.001,
        VC_09: 0.32484,
        QE_09: 0.001,
        VE_09: 0.32484,
        QA_10: 0.001,
        VA_10: 0.32484,
        QC_10: 0.001,
        VC_10: 0.32484,
        QE_10: 0.001,
        VE_10: 0.32484,
        QA_11: 0.001,
        VA_11: 0.32484,
        QC_11: 0.001,
        VC_11: 0.32484,
        QE_11: 0.001,
        VE_11: 0.32484,
        QA_12: 0.008,
        VA_12: 0.35801,
        QC_12: 0.008,
        VC_12: 0.35801,
        QE_12: 0.008,
        VE_12: 0.35801,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884181318224933, 34.797674879320311, 0.0],
          [-80.883653718225673, 34.796667212655393, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286236.0,
        COMID: 9745382,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.607,
        REACHCODE: "03050102000063",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0058020201946339996,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060237.0,
        ToNode: 250060239.0,
        Hydroseq: 250016714.0,
        LevelPathI: 250011553.0,
        Pathlength: 547.146,
        TerminalPa: 250002604.0,
        ArbolateSu: 523.464,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250016927.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016507.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2493,
        TotDASqKM: 549.4158,
        DivDASqKM: 549.4158,
        Tidal: 0,
        TOTMA: 0.0153659724498,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24190.0,
        MAXELEVSMO: 24495.0,
        MINELEVSMO: 24372.0,
        SLOPE: 0.00202635,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60699999332428001,
        QA_MA: 318.742,
        VA_MA: 1.49967,
        QC_MA: 308.644,
        VC_MA: 1.49656,
        QE_MA: 319.937,
        VE_MA: 1.50003,
        QA_01: 555.116,
        VA_01: 1.90778,
        QC_01: 426.583,
        VC_01: 1.71933,
        QE_01: 429.484,
        VE_01: 1.7017,
        QA_02: 579.988,
        VA_02: 1.94544,
        QC_02: 475.086,
        VC_02: 1.80236,
        QE_02: 471.546,
        VE_02: 1.77257,
        QA_03: 624.298,
        VA_03: 2.0107,
        QC_03: 514.012,
        VC_03: 1.86616,
        QE_03: 530.866,
        VE_03: 1.86766,
        QA_04: 460.668,
        VA_04: 1.75698,
        QC_04: 419.358,
        VC_04: 1.70659,
        QE_04: 454.234,
        VE_04: 1.74378,
        QA_05: 343.51,
        VA_05: 1.54796,
        QC_05: 313.551,
        VC_05: 1.50656,
        QE_05: 342.276,
        VE_05: 1.54351,
        QA_06: 197.424,
        VA_06: 1.23212,
        QC_06: 245.01,
        VC_06: 1.35947,
        QE_06: 277.805,
        VE_06: 1.41395,
        QA_07: 116.866,
        VA_07: 1.00764,
        QC_07: 173.957,
        VC_07: 1.18469,
        QE_07: 201.733,
        VE_07: 1.2412,
        QA_08: 80.392,
        VA_08: 0.88165,
        QC_08: 182.078,
        VC_08: 1.20618,
        QE_08: 203.582,
        VE_08: 1.24573,
        QA_09: 73.21,
        VA_09: 0.85386,
        QC_09: 173.214,
        VC_09: 1.1827,
        QE_09: 163.528,
        VE_09: 1.14279,
        QA_10: 103.078,
        VA_10: 0.96254,
        QC_10: 223.125,
        VC_10: 1.30852,
        QE_10: 167.688,
        VE_10: 1.15399,
        QA_11: 274.154,
        VA_11: 1.40806,
        QC_11: 276.513,
        VC_11: 1.42919,
        QE_11: 210.178,
        VE_11: 1.26173,
        QA_12: 418.35,
        VA_12: 1.68472,
        QC_12: 320.894,
        VC_12: 1.52138,
        QE_12: 298.59,
        VE_12: 1.45712,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.297193184250489, 35.625655411368541, 0.0],
          [-81.294196184255043, 35.621118811375652, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288447.0,
        COMID: 9736268,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.573,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0061060005839560002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059895.0,
        ToNode: 250059894.0,
        Hydroseq: 250006485.0,
        LevelPathI: 250003258.0,
        Pathlength: 423.281,
        TerminalPa: 250002604.0,
        ArbolateSu: 6002.019,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006512.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006458.0,
        FromMeas: 0.0,
        ToMeas: 11.50699,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5904,
        TotDASqKM: 7958.7027,
        DivDASqKM: 7958.7027,
        Tidal: 0,
        TOTMA: 0.0091517767134400001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14446.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14473.0,
        SLOPE: 0.00141361,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.57300001382827803,
        QA_MA: 4125.343,
        VA_MA: 2.36859,
        QC_MA: 4125.343,
        VC_MA: 2.36859,
        QE_MA: 4350.926,
        VE_MA: 2.3775,
        QA_01: 7345.743,
        VA_01: 3.10715,
        QC_01: 7345.743,
        VC_01: 3.10715,
        QE_01: 5924.454,
        VE_01: 2.74604,
        QA_02: 7773.314,
        VA_02: 3.1925,
        QC_02: 7773.314,
        VC_02: 3.1925,
        QE_02: 6100.753,
        VE_02: 2.7843,
        QA_03: 8572.065,
        VA_03: 3.34626,
        QC_03: 8572.065,
        VC_03: 3.34626,
        QE_03: 6202.321,
        VE_03: 2.8061,
        QA_04: 6075.746,
        VA_04: 2.8388,
        QC_04: 6075.746,
        VC_04: 2.8388,
        QE_04: 5392.3,
        VE_04: 2.62721,
        QA_05: 4270.881,
        VA_05: 2.40687,
        QC_05: 4270.881,
        VC_05: 2.40687,
        QE_05: 4490.177,
        VE_05: 2.4124,
        QA_06: 2502.494,
        VA_06: 1.88832,
        QC_06: 2502.494,
        VC_06: 1.88832,
        QE_06: 4179.852,
        VE_06: 2.33389,
        QA_07: 1524.484,
        VA_07: 1.52273,
        QC_07: 1524.484,
        VC_07: 1.52273,
        QE_07: 3150.73,
        VE_07: 2.05161,
        QA_08: 1079.374,
        VA_08: 1.31931,
        QC_08: 1079.374,
        VC_08: 1.31931,
        QE_08: 3084.693,
        VE_08: 2.03211,
        QA_09: 997.472,
        VA_09: 1.27782,
        QC_09: 997.472,
        VC_09: 1.27782,
        QE_09: 2577.119,
        VE_09: 1.87517,
        QA_10: 1121.177,
        VA_10: 1.33991,
        QC_10: 1121.177,
        VC_10: 1.33991,
        QE_10: 3404.111,
        VE_10: 2.1247,
        QA_11: 2995.085,
        VA_11: 2.04649,
        QC_11: 2995.085,
        VC_11: 2.04649,
        QE_11: 3616.891,
        VE_11: 2.18413,
        QA_12: 5274.429,
        VA_12: 2.65573,
        QC_12: 5274.429,
        VC_12: 2.65573,
        QE_12: 4434.651,
        VE_12: 2.39854,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.903044784862232, 34.946325679089682, 0.0],
          [-80.897420518204342, 34.944238212426342, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286237.0,
        COMID: 9745364,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.605,
        REACHCODE: "03050102000064",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.015958859279034,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060233.0,
        ToNode: 250060237.0,
        Hydroseq: 250016927.0,
        LevelPathI: 250011553.0,
        Pathlength: 547.753,
        TerminalPa: 250002604.0,
        ArbolateSu: 520.381,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250017140.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016714.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7028,
        TotDASqKM: 547.596,
        DivDASqKM: 547.596,
        Tidal: 0,
        TOTMA: 0.074310086381300003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24265.0,
        MAXELEVSMO: 24495.0,
        MINELEVSMO: 24495.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.6050000190734861,
        QA_MA: 317.876,
        VA_MA: 0.81999,
        QC_MA: 307.837,
        VC_MA: 0.81866,
        QE_MA: 319.13,
        VE_MA: 0.82016,
        QA_01: 553.482,
        VA_01: 0.99518,
        QC_01: 425.361,
        VC_01: 0.91425,
        QE_01: 428.262,
        VE_01: 0.90667,
        QA_02: 578.257,
        VA_02: 1.01134,
        QC_02: 473.707,
        VC_02: 0.9499,
        QE_02: 470.167,
        VE_02: 0.93707,
        QA_03: 622.422,
        VA_03: 1.03935,
        QC_03: 512.518,
        VC_03: 0.97729,
        QE_03: 529.372,
        VE_03: 0.97794,
        QA_04: 459.378,
        VA_04: 0.93047,
        QC_04: 418.211,
        VC_04: 0.90883,
        QE_04: 453.087,
        VE_04: 0.92484,
        QA_05: 342.635,
        VA_05: 0.84078,
        QC_05: 312.782,
        VC_05: 0.823,
        QE_05: 341.507,
        VE_05: 0.83891,
        QA_06: 196.917,
        VA_06: 0.70512,
        QC_06: 244.388,
        VC_06: 0.7598,
        QE_06: 277.184,
        VE_06: 0.78326,
        QA_07: 116.55,
        VA_07: 0.60869,
        QC_07: 173.498,
        VC_07: 0.68472,
        QE_07: 201.274,
        VE_07: 0.70905,
        QA_08: 80.172,
        VA_08: 0.55458,
        QC_08: 181.569,
        VC_08: 0.69392,
        QE_08: 203.073,
        VE_08: 0.71095,
        QA_09: 73.038,
        VA_09: 0.54269,
        QC_09: 172.856,
        VC_09: 0.68398,
        QE_09: 163.17,
        VE_09: 0.6668,
        QA_10: 102.932,
        VA_10: 0.58951,
        QC_10: 222.871,
        VC_10: 0.73824,
        QE_10: 167.434,
        VE_10: 0.67174,
        QA_11: 273.64,
        VA_11: 0.78086,
        QC_11: 276.118,
        VC_11: 0.79003,
        QE_11: 209.782,
        VE_11: 0.71796,
        QA_12: 417.22,
        VA_12: 0.89947,
        QC_12: 320.105,
        VC_12: 0.82936,
        QE_12: 297.801,
        VE_12: 0.8017,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.308040384233607, 35.635967811352657, 0.0],
          [-81.302154317576083, 35.629918211361883, 0.0],
          [-81.297471784250092, 35.627648011365352, 0.0],
          [-81.297193184250489, 35.625655411368541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288448.0,
        COMID: 9736246,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.581,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0063232848738529997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059886.0,
        ToNode: 250059892.0,
        Hydroseq: 250006541.0,
        LevelPathI: 250003258.0,
        Pathlength: 424.955,
        TerminalPa: 250002604.0,
        ArbolateSu: 5995.14,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006574.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006512.0,
        FromMeas: 33.62872,
        ToMeas: 45.30586,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3933,
        TotDASqKM: 7952.0508,
        DivDASqKM: 7952.0508,
        Tidal: 0,
        TOTMA: 0.0176516801642,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14383.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.58099997043609597,
        QA_MA: 4122.583,
        VA_MA: 1.2458,
        QC_MA: 4122.583,
        VC_MA: 1.2458,
        QE_MA: 4348.15,
        VE_MA: 1.24986,
        QA_01: 7339.831,
        VA_01: 1.58176,
        QC_01: 7339.831,
        VC_01: 1.58176,
        QE_01: 5918.574,
        VE_01: 1.41734,
        QA_02: 7767.127,
        VA_02: 1.6206,
        QC_02: 7767.127,
        VC_02: 1.6206,
        QE_02: 6094.607,
        VE_02: 1.43473,
        QA_03: 8565.44,
        VA_03: 1.69057,
        QC_03: 8565.44,
        VC_03: 1.69057,
        QE_03: 6195.746,
        VE_03: 1.44462,
        QA_04: 6071.795,
        VA_04: 1.45977,
        QC_04: 6071.795,
        VC_04: 1.45977,
        QE_04: 5388.353,
        VE_04: 1.36343,
        QA_05: 4268.659,
        VA_05: 1.2633,
        QC_05: 4268.659,
        VC_05: 1.2633,
        QE_05: 4487.932,
        VE_05: 1.26581,
        QA_06: 2501.153,
        VA_06: 1.02732,
        QC_06: 2501.153,
        VC_06: 1.02732,
        QE_06: 4178.459,
        VE_06: 1.23017,
        QA_07: 1523.5,
        VA_07: 0.86093,
        QC_07: 1523.5,
        VC_07: 0.86093,
        QE_07: 3149.699,
        VE_07: 1.10171,
        QA_08: 1078.658,
        VA_08: 0.76837,
        QC_08: 1078.658,
        VC_08: 0.76837,
        QE_08: 3083.924,
        VE_08: 1.09287,
        QA_09: 996.846,
        VA_09: 0.7495,
        QC_09: 996.846,
        VC_09: 0.7495,
        QE_09: 2576.444,
        VE_09: 1.02144,
        QA_10: 1120.73,
        VA_10: 0.77781,
        QC_10: 1120.73,
        VC_10: 0.77781,
        QE_10: 3403.591,
        VE_10: 1.13505,
        QA_11: 2994.306,
        VA_11: 1.09941,
        QC_11: 2994.306,
        VC_11: 1.09941,
        QE_11: 3616.081,
        VE_11: 1.16206,
        QA_12: 5271.083,
        VA_12: 1.37647,
        QC_12: 5271.083,
        VC_12: 1.37647,
        QE_12: 4431.312,
        VE_12: 1.25938,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.920493984835161, 34.950620212416311, 0.0],
          [-80.914340384844706, 34.949419612418239, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286238.0,
        COMID: 9745318,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.599,
        REACHCODE: "03050102000065",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0060298279697450002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060222.0,
        ToNode: 250060233.0,
        Hydroseq: 250017140.0,
        LevelPathI: 250011553.0,
        Pathlength: 549.358,
        TerminalPa: 250002604.0,
        ArbolateSu: 272.775,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250017362.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016927.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7299,
        TotDASqKM: 296.6346,
        DivDASqKM: 296.6346,
        Tidal: 0,
        TOTMA: 0.018180801832699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24361.0,
        MAXELEVSMO: 24561.0,
        MINELEVSMO: 24495.0,
        SLOPE: 0.00110183,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.59899997711181596,
        QA_MA: 172.483,
        VA_MA: 1.24708,
        QC_MA: 171.046,
        VC_MA: 1.24644,
        QE_MA: 181.778,
        VE_MA: 1.25108,
        QA_01: 300.687,
        VA_01: 1.56931,
        QC_01: 234.808,
        VC_01: 1.41831,
        QE_01: 236.611,
        VE_01: 1.39273,
        QA_02: 315.502,
        VA_02: 1.60193,
        QC_02: 262.762,
        VC_02: 1.48662,
        QE_02: 258.228,
        VE_02: 1.44425,
        QA_03: 338.029,
        VA_03: 1.65018,
        QC_03: 284.008,
        VC_03: 1.53629,
        QE_03: 295.253,
        VE_03: 1.52801,
        QA_04: 249.214,
        VA_04: 1.44966,
        QC_04: 230.057,
        VC_04: 1.40633,
        QE_04: 256.406,
        VE_04: 1.43999,
        QA_05: 183.665,
        VA_05: 1.27892,
        QC_05: 171.62,
        VC_05: 1.24811,
        QE_05: 196.877,
        VE_05: 1.29188,
        QA_06: 106.988,
        VA_06: 1.03682,
        QC_06: 133.897,
        VC_06: 1.13207,
        QE_06: 161.313,
        VE_06: 1.19315,
        QA_07: 63.02,
        VA_07: 0.85835,
        QC_07: 95.203,
        VC_07: 0.99563,
        QE_07: 116.949,
        VE_07: 1.05425,
        QA_08: 43.396,
        VA_08: 0.75951,
        QC_08: 97.079,
        VC_08: 1.00279,
        QE_08: 114.442,
        VE_08: 1.04572,
        QA_09: 40.484,
        VA_09: 0.74318,
        QC_09: 102.666,
        VC_09: 1.02373,
        QE_09: 103.785,
        VE_09: 1.00843,
        QA_10: 55.495,
        VA_10: 0.82244,
        QC_10: 136.062,
        VC_10: 1.13912,
        QE_10: 126.034,
        VE_10: 1.08448,
        QA_11: 147.789,
        VA_11: 1.17314,
        QC_11: 172.609,
        VC_11: 1.25099,
        QE_11: 138.529,
        VE_11: 1.12443,
        QA_12: 226.61,
        VA_12: 1.39347,
        QC_12: 183.7,
        VC_12: 1.28268,
        QE_12: 170.505,
        VE_12: 1.21957,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.303857784240165, 35.638828278014728, 0.0],
          [-81.308040384233607, 35.635967811352657, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288449.0,
        COMID: 9736260,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.101,
        REACHCODE: "03050103001301",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011874008949289,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059892.0,
        ToNode: 250059895.0,
        Hydroseq: 250006512.0,
        LevelPathI: 250003258.0,
        Pathlength: 423.854,
        TerminalPa: 250002604.0,
        ArbolateSu: 5999.558,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006541.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006485.0,
        FromMeas: 11.50699,
        ToMeas: 33.62872,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6201,
        TotDASqKM: 7956.9549,
        DivDASqKM: 7956.9549,
        Tidal: 0,
        TOTMA: 0.033446874783599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14378.0,
        MAXELEVSMO: 14554.0,
        MINELEVSMO: 14554.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1009999513626101,
        QA_MA: 4124.622,
        VA_MA: 1.24593,
        QC_MA: 4124.622,
        VC_MA: 1.24593,
        QE_MA: 4350.2,
        VE_MA: 1.24998,
        QA_01: 7344.192,
        VA_01: 1.582,
        QC_01: 7344.192,
        VC_01: 1.582,
        QE_01: 5922.911,
        VE_01: 1.41764,
        QA_02: 7771.692,
        VA_02: 1.62084,
        QC_02: 7771.692,
        VC_02: 1.62084,
        QE_02: 6099.142,
        VE_02: 1.43504,
        QA_03: 8570.329,
        VA_03: 1.69081,
        QC_03: 8570.329,
        VC_03: 1.69081,
        QE_03: 6200.597,
        VE_03: 1.44496,
        QA_04: 6074.712,
        VA_04: 1.45991,
        QC_04: 6074.712,
        VC_04: 1.45991,
        QE_04: 5391.267,
        VE_04: 1.3636,
        QA_05: 4270.302,
        VA_05: 1.26337,
        QC_05: 4270.302,
        VC_05: 1.26337,
        QE_05: 4489.592,
        VE_05: 1.26588,
        QA_06: 2502.142,
        VA_06: 1.02738,
        QC_06: 2502.142,
        VC_06: 1.02738,
        QE_06: 4179.486,
        VE_06: 1.23017,
        QA_07: 1524.226,
        VA_07: 0.861,
        QC_07: 1524.226,
        VC_07: 0.861,
        QE_07: 3150.459,
        VE_07: 1.10171,
        QA_08: 1079.186,
        VA_08: 0.76843,
        QC_08: 1079.186,
        VC_08: 0.76843,
        QE_08: 3084.491,
        VE_08: 1.09285,
        QA_09: 997.307,
        VA_09: 0.74955,
        QC_09: 997.307,
        VC_09: 0.74955,
        QE_09: 2576.942,
        VE_09: 1.02142,
        QA_10: 1121.059,
        VA_10: 0.77782,
        QC_10: 1121.059,
        VC_10: 0.77782,
        QE_10: 3403.975,
        VE_10: 1.135,
        QA_11: 2994.897,
        VA_11: 1.09939,
        QC_11: 2994.897,
        VC_11: 1.09939,
        QE_11: 3616.695,
        VE_11: 1.16203,
        QA_12: 5273.559,
        VA_12: 1.3766,
        QC_12: 5273.559,
        VC_12: 1.3766,
        QE_12: 4433.784,
        VE_12: 1.25954,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.914340384844706, 34.949419612418239, 0.0],
          [-80.903044784862232, 34.946325679089682, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286231.0,
        COMID: 9745520,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.651,
        REACHCODE: "03050102000058",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.016967196214415,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060278.0,
        ToNode: 250060283.0,
        Hydroseq: 250015779.0,
        LevelPathI: 250011553.0,
        Pathlength: 541.592,
        TerminalPa: 250002604.0,
        ArbolateSu: 540.163,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015945.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015618.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9162,
        TotDASqKM: 566.559,
        DivDASqKM: 566.559,
        Tidal: 0,
        TOTMA: 0.046034764300200003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23895.0,
        MAXELEVSMO: 24129.0,
        MINELEVSMO: 23981.0,
        SLOPE: 0.00089642,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.651000022888184,
        QA_MA: 326.764,
        VA_MA: 1.36169,
        QC_MA: 316.107,
        VC_MA: 1.35887,
        QE_MA: 327.4,
        VE_MA: 1.36186,
        QA_01: 570.394,
        VA_01: 1.72426,
        QC_01: 438.013,
        VC_01: 1.55751,
        QE_01: 440.914,
        VE_01: 1.54225,
        QA_02: 596.163,
        VA_02: 1.75787,
        QC_02: 487.969,
        VC_02: 1.63124,
        QE_02: 484.429,
        VE_02: 1.60549,
        QA_03: 641.766,
        VA_03: 1.81573,
        QC_03: 527.913,
        VC_03: 1.68768,
        QE_03: 544.768,
        VE_03: 1.68891,
        QA_04: 472.575,
        VA_04: 1.58965,
        QC_04: 429.948,
        VC_04: 1.54524,
        QE_04: 464.824,
        VE_04: 1.57734,
        QA_05: 351.535,
        VA_05: 1.40337,
        QC_05: 320.599,
        VC_05: 1.36677,
        QE_05: 349.324,
        VE_05: 1.39875,
        QA_06: 202.071,
        VA_06: 1.12444,
        QC_06: 250.696,
        VC_06: 1.23719,
        QE_06: 283.491,
        VE_06: 1.28431,
        QA_07: 119.692,
        VA_07: 0.92633,
        QC_07: 178.061,
        VC_07: 1.0829,
        QE_07: 205.837,
        VE_07: 1.13176,
        QA_08: 82.467,
        VA_08: 0.81542,
        QC_08: 186.871,
        VC_08: 1.10302,
        QE_08: 208.376,
        VE_08: 1.13714,
        QA_09: 74.823,
        VA_09: 0.7899,
        QC_09: 176.58,
        VC_09: 1.07947,
        QE_09: 166.894,
        VE_09: 1.04499,
        QA_10: 104.461,
        VA_10: 0.88324,
        QC_10: 225.513,
        VC_10: 1.18642,
        QE_10: 170.076,
        VE_10: 1.05241,
        QA_11: 278.653,
        VA_11: 1.27628,
        QC_11: 279.967,
        VC_11: 1.29328,
        QE_11: 213.632,
        VE_11: 1.14818,
        QA_12: 428.794,
        VA_12: 1.52519,
        QC_12: 328.174,
        VC_12: 1.37997,
        QE_12: 305.87,
        VE_12: 1.32449,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.274894117618373, 35.601866678072099, 0.0],
          [-81.283614317604929, 35.602652078071003, 0.0],
          [-81.281571117608053, 35.599192278076202, 0.0],
          [-81.284124917604061, 35.597385011412428, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288450.0,
        COMID: 9736542,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.127,
        REACHCODE: "03050103001302",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001194122388543,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060023.0,
        ToNode: 250060021.0,
        Hydroseq: 250005199.0,
        LevelPathI: 250005199.0,
        Pathlength: 388.045,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.834,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005208.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005180.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9815.2083,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.015637767672200001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.12700000405311601,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865378984920767, 34.691883612817946, 0.0],
          [-80.865847584920004, 34.69289547948307, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286232.0,
        COMID: 9745502,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.165,
        REACHCODE: "03050102000059",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001545673027189,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060273.0,
        ToNode: 250060278.0,
        Hydroseq: 250015945.0,
        LevelPathI: 250011553.0,
        Pathlength: 543.243,
        TerminalPa: 250002604.0,
        ArbolateSu: 536.733,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250016108.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015779.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0324,
        TotDASqKM: 563.661,
        DivDASqKM: 563.661,
        Tidal: 0,
        TOTMA: 0.00761049631898,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23938.0,
        MAXELEVSMO: 24129.0,
        MINELEVSMO: 24129.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.16500000655651101,
        QA_MA: 325.419,
        VA_MA: 0.82317,
        QC_MA: 314.856,
        VC_MA: 0.8218,
        QE_MA: 326.149,
        VE_MA: 0.82327,
        QA_01: 567.818,
        VA_01: 1.00023,
        QC_01: 436.086,
        VC_01: 0.91874,
        QE_01: 438.987,
        VE_01: 0.91126,
        QA_02: 593.435,
        VA_02: 1.01663,
        QC_02: 485.798,
        VC_02: 0.95475,
        QE_02: 482.257,
        VE_02: 0.94212,
        QA_03: 638.824,
        VA_03: 1.0449,
        QC_03: 525.573,
        VC_03: 0.98233,
        QE_03: 542.427,
        VE_03: 0.98294,
        QA_04: 470.587,
        VA_04: 0.93456,
        QC_04: 428.181,
        VC_04: 0.91284,
        QE_04: 463.057,
        VE_04: 0.92859,
        QA_05: 350.206,
        VA_05: 0.84364,
        QC_05: 319.432,
        VC_05: 0.82575,
        QE_05: 348.157,
        VE_05: 0.84143,
        QA_06: 201.291,
        VA_06: 0.70728,
        QC_06: 249.742,
        VC_06: 0.76237,
        QE_06: 282.538,
        VE_06: 0.78548,
        QA_07: 119.224,
        VA_07: 0.61043,
        QC_07: 177.382,
        VC_07: 0.68695,
        QE_07: 205.158,
        VE_07: 0.71092,
        QA_08: 82.116,
        VA_08: 0.55618,
        QC_08: 186.061,
        VC_08: 0.69668,
        QE_08: 207.565,
        VE_08: 0.71342,
        QA_09: 74.55,
        VA_09: 0.54378,
        QC_09: 176.012,
        VC_09: 0.6854,
        QE_09: 166.326,
        VE_09: 0.66848,
        QA_10: 104.227,
        VA_10: 0.58962,
        QC_10: 225.11,
        VC_10: 0.73801,
        QE_10: 169.673,
        VE_10: 0.67231,
        QA_11: 277.911,
        VA_11: 0.78179,
        QC_11: 279.398,
        VC_11: 0.79024,
        QE_11: 213.063,
        VE_11: 0.71908,
        QA_12: 427.036,
        VA_12: 0.90309,
        QC_12: 326.95,
        VC_12: 0.83218,
        QE_12: 304.646,
        VE_12: 0.80495,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.274191384286212, 35.603194411403365, 0.0],
          [-81.274894117618373, 35.601866678072099, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288427.0,
        COMID: 9735612,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.09,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00090588530158400001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059732.0,
        ToNode: 250059734.0,
        Hydroseq: 250004582.0,
        LevelPathI: 250004457.0,
        Pathlength: 372.989,
        TerminalPa: 250002604.0,
        ArbolateSu: 7561.554,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004593.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004547.0,
        FromMeas: 82.97974,
        ToMeas: 89.29594,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 9868.518,
        DivDASqKM: 0.6588,
        Tidal: 0,
        TOTMA: 0.0047833244257499997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9487.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9487.0,
        SLOPE: 0.05155555,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.090000003576279006,
        QA_MA: 0.218,
        VA_MA: 0.71447,
        QC_MA: 0.218,
        VC_MA: 0.71447,
        QE_MA: 0.218,
        VE_MA: 0.71447,
        QA_01: 0.469,
        VA_01: 0.91832,
        QC_01: 0.469,
        VC_01: 0.91832,
        QE_01: 0.469,
        VE_01: 0.91832,
        QA_02: 0.522,
        VA_02: 0.954,
        QC_02: 0.522,
        VC_02: 0.954,
        QE_02: 0.522,
        VE_02: 0.954,
        QA_03: 0.581,
        VA_03: 0.99178,
        QC_03: 0.581,
        VC_03: 0.99178,
        QE_03: 0.581,
        VE_03: 0.99178,
        QA_04: 0.301,
        VA_04: 0.79035,
        QC_04: 0.301,
        VC_04: 0.79035,
        QE_04: 0.301,
        VE_04: 0.79035,
        QA_05: 0.168,
        VA_05: 0.662,
        QC_05: 0.168,
        VC_05: 0.662,
        QE_05: 0.168,
        VE_05: 0.662,
        QA_06: 0.115,
        VA_06: 0.59754,
        QC_06: 0.115,
        VC_06: 0.59754,
        QE_06: 0.115,
        VE_06: 0.59754,
        QA_07: 0.088,
        VA_07: 0.55924,
        QC_07: 0.088,
        VC_07: 0.55924,
        QE_07: 0.088,
        VE_07: 0.55924,
        QA_08: 0.07,
        VA_08: 0.53054,
        QC_08: 0.07,
        VC_08: 0.53054,
        QE_08: 0.07,
        VE_08: 0.53054,
        QA_09: 0.053,
        VA_09: 0.50003,
        QC_09: 0.053,
        VC_09: 0.50003,
        QE_09: 0.053,
        VE_09: 0.50003,
        QA_10: 0.044,
        VA_10: 0.482,
        QC_10: 0.044,
        VC_10: 0.482,
        QE_10: 0.044,
        VE_10: 0.482,
        QA_11: 0.035,
        VA_11: 0.46213,
        QC_11: 0.035,
        VC_11: 0.46213,
        QE_11: 0.035,
        VE_11: 0.46213,
        QA_12: 0.168,
        VA_12: 0.662,
        QC_12: 0.168,
        VC_12: 0.662,
        QE_12: 0.168,
        VE_12: 0.662,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880117918231178, 34.582169012988345, 0.0],
          [-80.879425518232267, 34.581642212988982, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286233.0,
        COMID: 9745494,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.291,
        REACHCODE: "03050102000060",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.023273072449829998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060271.0,
        ToNode: 250060273.0,
        Hydroseq: 250016108.0,
        LevelPathI: 250011553.0,
        Pathlength: 543.408,
        TerminalPa: 250002604.0,
        ArbolateSu: 533.168,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250016308.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015945.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7136,
        TotDASqKM: 558.7623,
        DivDASqKM: 558.7623,
        Tidal: 0,
        TOTMA: 0.064431981838500005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23962.0,
        MAXELEVSMO: 24324.0,
        MINELEVSMO: 24129.0,
        SLOPE: 0.00085115,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.2909998893737789,
        QA_MA: 323.143,
        VA_MA: 1.34996,
        QC_MA: 312.739,
        VC_MA: 1.3472,
        QE_MA: 324.032,
        VE_MA: 1.35019,
        QA_01: 563.471,
        VA_01: 1.70769,
        QC_01: 432.834,
        VC_01: 1.54286,
        QE_01: 435.735,
        VE_01: 1.52763,
        QA_02: 588.825,
        VA_02: 1.74078,
        QC_02: 482.126,
        VC_02: 1.61562,
        QE_02: 478.586,
        VE_02: 1.5899,
        QA_03: 633.872,
        VA_03: 1.79796,
        QC_03: 521.633,
        VC_03: 1.67145,
        QE_03: 538.487,
        VE_03: 1.67271,
        QA_04: 467.203,
        VA_04: 1.57519,
        QC_04: 425.172,
        VC_04: 1.53121,
        QE_04: 460.048,
        VE_04: 1.56329,
        QA_05: 347.941,
        VA_05: 1.39166,
        QC_05: 317.443,
        VC_05: 1.35547,
        QE_05: 346.168,
        VE_05: 1.3874,
        QA_06: 199.974,
        VA_06: 1.11566,
        QC_06: 248.131,
        VC_06: 1.22708,
        QE_06: 280.926,
        VE_06: 1.27414,
        QA_07: 118.434,
        VA_07: 0.91963,
        QC_07: 176.234,
        VC_07: 1.07446,
        QE_07: 204.01,
        VE_07: 1.12326,
        QA_08: 81.523,
        VA_08: 0.80968,
        QC_08: 184.691,
        VC_08: 1.09377,
        QE_08: 206.195,
        VE_08: 1.12788,
        QA_09: 74.089,
        VA_09: 0.78486,
        QC_09: 175.05,
        VC_09: 1.07172,
        QE_09: 165.364,
        VE_09: 1.03727,
        QA_10: 103.832,
        VA_10: 0.87838,
        QC_10: 224.428,
        VC_10: 1.17939,
        QE_10: 168.991,
        VE_10: 1.04571,
        QA_11: 276.685,
        VA_11: 1.26756,
        QC_11: 278.458,
        VC_11: 1.28509,
        QE_11: 212.123,
        VE_11: 1.14031,
        QA_12: 424.072,
        VA_12: 1.51168,
        QC_12: 324.885,
        VC_12: 1.36843,
        QE_12: 302.581,
        VE_12: 1.31298,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.289239717596161, 35.613237011387866, 0.0],
          [-81.289243984262839, 35.609160478060801, 0.0],
          [-81.285094984269278, 35.607623011396583, 0.0],
          [-81.277971184280261, 35.608167278062467, 0.0],
          [-81.274191384286212, 35.603194411403365, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288428.0,
        COMID: 9735636,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.013,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000114533410642,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059735.0,
        ToNode: 250059736.0,
        Hydroseq: 250004505.0,
        LevelPathI: 250004457.0,
        Pathlength: 372.727,
        TerminalPa: 250002604.0,
        ArbolateSu: 7670.6,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004547.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004493.0,
        DnDrainCou: 2,
        DnHydroseq: 250004494.0,
        FromMeas: 64.75092,
        ToMeas: 65.64306,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9975.6135,
        DivDASqKM: 0.7389,
        Tidal: 0,
        TOTMA: 0.00069649093853000005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9254.0,
        MAXELEVSMO: 9306.0,
        MINELEVSMO: 9254.0,
        SLOPE: 0.04,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.013000000268221,
        QA_MA: 0.244,
        VA_MA: 0.70876,
        QC_MA: 0.244,
        VC_MA: 0.70876,
        QE_MA: 0.244,
        VE_MA: 0.70876,
        QA_01: 0.526,
        VA_01: 0.9104,
        QC_01: 0.526,
        VC_01: 0.9104,
        QE_01: 0.526,
        VE_01: 0.9104,
        QA_02: 0.586,
        VA_02: 0.94594,
        QC_02: 0.586,
        VC_02: 0.94594,
        QE_02: 0.586,
        VE_02: 0.94594,
        QA_03: 0.651,
        VA_03: 0.98256,
        QC_03: 0.651,
        VC_03: 0.98256,
        QE_03: 0.651,
        VE_03: 0.98256,
        QA_04: 0.337,
        VA_04: 0.78365,
        QC_04: 0.337,
        VC_04: 0.78365,
        QE_04: 0.337,
        VE_04: 0.78365,
        QA_05: 0.188,
        VA_05: 0.657,
        QC_05: 0.188,
        VC_05: 0.657,
        QE_05: 0.188,
        VE_05: 0.657,
        QA_06: 0.129,
        VA_06: 0.59381,
        QC_06: 0.129,
        VC_06: 0.59381,
        QE_06: 0.129,
        VE_06: 0.59381,
        QA_07: 0.099,
        VA_07: 0.55638,
        QC_07: 0.099,
        VC_07: 0.55638,
        QE_07: 0.099,
        VE_07: 0.55638,
        QA_08: 0.079,
        VA_08: 0.52838,
        QC_08: 0.079,
        VC_08: 0.52838,
        QE_08: 0.079,
        VE_08: 0.52838,
        QA_09: 0.059,
        VA_09: 0.49679,
        QC_09: 0.059,
        VC_09: 0.49679,
        QE_09: 0.059,
        VE_09: 0.49679,
        QA_10: 0.049,
        VA_10: 0.4791,
        QC_10: 0.049,
        VC_10: 0.4791,
        QE_10: 0.049,
        VE_10: 0.4791,
        QA_11: 0.039,
        VA_11: 0.45961,
        QC_11: 0.039,
        VC_11: 0.45961,
        QE_11: 0.039,
        VE_11: 0.45961,
        QA_12: 0.188,
        VA_12: 0.657,
        QC_12: 0.188,
        VC_12: 0.657,
        QE_12: 0.188,
        VE_12: 0.657,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878677718233462, 34.579512012992325, 0.0],
          [-80.878677584900117, 34.579397479659292, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 284168.0,
        COMID: 9756698,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "983804",
        GNIS_NAME: "Crowders Creek",
        LENGTHKM: 0.449,
        REACHCODE: "03050101000571",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0049003415058540001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250061941.0,
        ToNode: 250061943.0,
        Hydroseq: 250033865.0,
        LevelPathI: 250028891.0,
        Pathlength: 471.932,
        TerminalPa: 250002604.0,
        ArbolateSu: 86.995,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250028891.0,
        UpHydroseq: 250035523.0,
        DnLevelPat: 250028891.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250032396.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.189,
        TotDASqKM: 181.9287,
        DivDASqKM: 181.9287,
        Tidal: 0,
        TOTMA: 0.0159936734279,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 18881.0,
        MAXELEVSMO: 19004.0,
        MINELEVSMO: 18974.0,
        SLOPE: 0.00066815,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.44900000095367398,
        QA_MA: 78.443,
        VA_MA: 1.06467,
        QC_MA: 80.205,
        VC_MA: 1.06603,
        QE_MA: 80.205,
        VE_MA: 1.06603,
        QA_01: 163.694,
        VA_01: 1.42608,
        QC_01: 129.883,
        VC_01: 1.28704,
        QE_01: 129.883,
        VE_01: 1.28704,
        QA_02: 172.036,
        VA_02: 1.45598,
        QC_02: 145.666,
        VC_02: 1.34849,
        QE_02: 145.666,
        VE_02: 1.34849,
        QA_03: 182.572,
        VA_03: 1.49278,
        QC_03: 156.546,
        VC_03: 1.38904,
        QE_03: 156.546,
        VE_03: 1.38904,
        QA_04: 109.366,
        VA_04: 1.21062,
        QC_04: 102.867,
        VC_04: 1.17306,
        QE_04: 102.867,
        VE_04: 1.17306,
        QA_05: 62.554,
        VA_05: 0.97902,
        QC_05: 60.855,
        VC_05: 0.96271,
        QE_05: 60.855,
        VE_05: 0.96271,
        QA_06: 37.673,
        VA_06: 0.82071,
        QC_06: 47.829,
        VC_06: 0.88415,
        QE_06: 47.829,
        VE_06: 0.88415,
        QA_07: 24.68,
        VA_07: 0.71766,
        QC_07: 38.129,
        VC_07: 0.81887,
        QE_07: 38.129,
        VE_07: 0.81887,
        QA_08: 19.463,
        VA_08: 0.66917,
        QC_08: 42.848,
        VC_08: 0.85149,
        QE_08: 42.848,
        VE_08: 0.85149,
        QA_09: 17.122,
        VA_09: 0.64543,
        QC_09: 48.026,
        VC_09: 0.88541,
        QE_09: 48.026,
        VE_09: 0.88541,
        QA_10: 13.776,
        VA_10: 0.60868,
        QC_10: 44.705,
        VC_10: 0.86387,
        QE_10: 44.705,
        VE_10: 0.86387,
        QA_11: 34.187,
        VA_11: 0.79497,
        QC_11: 56.519,
        VC_11: 0.93752,
        QE_11: 56.519,
        VE_11: 0.93752,
        QA_12: 104.617,
        VA_12: 1.1896,
        QC_12: 90.929,
        VC_12: 1.11824,
        QE_12: 90.929,
        VE_12: 1.11824,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.197458384405309, 35.169867478742731, 0.0],
          [-81.192688384412691, 35.169458212076677, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287259.0,
        COMID: 9734258,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.041,
        REACHCODE: "03050103000510",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00044654124543699999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059324.0,
        ToNode: 250059331.0,
        Hydroseq: 250005809.0,
        LevelPathI: 250005809.0,
        Pathlength: 400.955,
        TerminalPa: 250002604.0,
        ArbolateSu: 6815.965,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005809.0,
        UpHydroseq: 250005830.0,
        DnLevelPat: 250005769.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005790.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9187.1001,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0045863434148200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13637.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.041000001132488001,
        QA_MA: 0.001,
        VA_MA: 0.33946,
        QC_MA: 0.001,
        VC_MA: 0.33946,
        QE_MA: 0.001,
        VE_MA: 0.33946,
        QA_01: 0.003,
        VA_01: 0.36408,
        QC_01: 0.003,
        VC_01: 0.36408,
        QE_01: 0.003,
        VE_01: 0.36408,
        QA_02: 0.003,
        VA_02: 0.36408,
        QC_02: 0.003,
        VC_02: 0.36408,
        QE_02: 0.003,
        VE_02: 0.36408,
        QA_03: 0.003,
        VA_03: 0.36408,
        QC_03: 0.003,
        VC_03: 0.36408,
        QE_03: 0.003,
        VE_03: 0.36408,
        QA_04: 0.002,
        VA_04: 0.35329,
        QC_04: 0.002,
        VC_04: 0.35329,
        QE_04: 0.002,
        VE_04: 0.35329,
        QA_05: 0.001,
        VA_05: 0.33946,
        QC_05: 0.001,
        VC_05: 0.33946,
        QE_05: 0.001,
        VE_05: 0.33946,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.001,
        VA_12: 0.33946,
        QC_12: 0.001,
        VC_12: 0.33946,
        QE_12: 0.001,
        VE_12: 0.33946,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884097918225052, 34.796712879321888, 0.0],
          [-80.883653718225673, 34.796667212655393, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286234.0,
        COMID: 9745414,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.683,
        REACHCODE: "03050102000061",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0066397943785710001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060246.0,
        ToNode: 250060271.0,
        Hydroseq: 250016308.0,
        LevelPathI: 250011553.0,
        Pathlength: 545.699,
        TerminalPa: 250002604.0,
        ArbolateSu: 528.502,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250016507.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016108.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4518,
        TotDASqKM: 555.102,
        DivDASqKM: 555.102,
        Tidal: 0,
        TOTMA: 0.031566107263399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24123.0,
        MAXELEVSMO: 24324.0,
        MINELEVSMO: 24324.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.683000028133392,
        QA_MA: 321.415,
        VA_MA: 0.82149,
        QC_MA: 311.131,
        VC_MA: 0.82014,
        QE_MA: 322.424,
        VE_MA: 0.82162,
        QA_01: 560.182,
        VA_01: 0.99754,
        QC_01: 430.374,
        VC_01: 0.91635,
        QE_01: 433.275,
        VE_01: 0.90881,
        QA_02: 585.359,
        VA_02: 1.01382,
        QC_02: 479.365,
        VC_02: 0.95217,
        QE_02: 475.825,
        VE_02: 0.93944,
        QA_03: 630.117,
        VA_03: 1.04197,
        QC_03: 518.644,
        VC_03: 0.97966,
        QE_03: 535.499,
        VE_03: 0.98029,
        QA_04: 464.65,
        VA_04: 0.9324,
        QC_04: 422.901,
        VC_04: 0.91072,
        QE_04: 457.777,
        VE_04: 0.92661,
        QA_05: 346.205,
        VA_05: 0.84214,
        QC_05: 315.919,
        VC_05: 0.82431,
        QE_05: 344.644,
        VE_05: 0.84011,
        QA_06: 198.973,
        VA_06: 0.70614,
        QC_06: 246.905,
        VC_06: 0.76101,
        QE_06: 279.7,
        VE_06: 0.78431,
        QA_07: 117.812,
        VA_07: 0.60952,
        QC_07: 175.332,
        VC_07: 0.68578,
        QE_07: 203.108,
        VE_07: 0.70994,
        QA_08: 81.081,
        VA_08: 0.55533,
        QC_08: 183.667,
        VC_08: 0.69521,
        QE_08: 205.172,
        VE_08: 0.7121,
        QA_09: 73.745,
        VA_09: 0.5432,
        QC_09: 174.331,
        VC_09: 0.68464,
        QE_09: 164.646,
        VE_09: 0.66758,
        QA_10: 103.537,
        VA_10: 0.58956,
        QC_10: 223.918,
        VC_10: 0.73813,
        QE_10: 168.481,
        VE_10: 0.672,
        QA_11: 275.672,
        VA_11: 0.78132,
        QC_11: 277.68,
        VC_11: 0.79015,
        QE_11: 211.345,
        VE_11: 0.71851,
        QA_12: 421.818,
        VA_12: 0.90117,
        QC_12: 323.313,
        VC_12: 0.83068,
        QE_12: 301.009,
        VE_12: 0.80323,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.288281184264235, 35.617908478047241, 0.0],
          [-81.287077984266205, 35.615159411384923, 0.0],
          [-81.289239717596161, 35.613237011387866, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288429.0,
        COMID: 9735796,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.197,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018204257716280001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059751.0,
        ToNode: 250059753.0,
        Hydroseq: 250004470.0,
        LevelPathI: 250004457.0,
        Pathlength: 371.798,
        TerminalPa: 250002604.0,
        ArbolateSu: 7671.529,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004483.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004457.0,
        FromMeas: 0.0,
        ToMeas: 13.73298,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0189,
        TotDASqKM: 9975.7656,
        DivDASqKM: 0.891,
        Tidal: 0,
        TOTMA: 0.012409785772700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8740.0,
        MAXELEVSMO: 8938.0,
        MINELEVSMO: 8851.0,
        SLOPE: 0.00441624,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.196999996900558,
        QA_MA: 0.294,
        VA_MA: 0.6028,
        QC_MA: 0.294,
        VC_MA: 0.6028,
        QE_MA: 0.294,
        VE_MA: 0.6028,
        QA_01: 0.634,
        VA_01: 0.75115,
        QC_01: 0.634,
        VC_01: 0.75115,
        QE_01: 0.634,
        VE_01: 0.75115,
        QA_02: 0.706,
        VA_02: 0.77717,
        QC_02: 0.706,
        VC_02: 0.77717,
        QE_02: 0.706,
        VE_02: 0.77717,
        QA_03: 0.784,
        VA_03: 0.804,
        QC_03: 0.784,
        VC_03: 0.804,
        QE_03: 0.784,
        VE_03: 0.804,
        QA_04: 0.407,
        VA_04: 0.65829,
        QC_04: 0.407,
        VC_04: 0.65829,
        QE_04: 0.407,
        VE_04: 0.65829,
        QA_05: 0.227,
        VA_05: 0.56502,
        QC_05: 0.227,
        VC_05: 0.56502,
        QE_05: 0.227,
        VE_05: 0.56502,
        QA_06: 0.155,
        VA_06: 0.51796,
        QC_06: 0.155,
        VC_06: 0.51796,
        QE_06: 0.155,
        VE_06: 0.51796,
        QA_07: 0.119,
        VA_07: 0.49052,
        QC_07: 0.119,
        VC_07: 0.49052,
        QE_07: 0.119,
        VE_07: 0.49052,
        QA_08: 0.095,
        VA_08: 0.46998,
        QC_08: 0.095,
        VC_08: 0.46998,
        QE_08: 0.095,
        VE_08: 0.46998,
        QA_09: 0.071,
        VA_09: 0.44683,
        QC_09: 0.071,
        VC_09: 0.44683,
        QE_09: 0.071,
        VE_09: 0.44683,
        QA_10: 0.059,
        VA_10: 0.43387,
        QC_10: 0.059,
        VC_10: 0.43387,
        QE_10: 0.059,
        VE_10: 0.43387,
        QA_11: 0.047,
        VA_11: 0.4196,
        QC_11: 0.047,
        VC_11: 0.4196,
        QE_11: 0.047,
        VE_11: 0.4196,
        QA_12: 0.226,
        VA_12: 0.56442,
        QC_12: 0.226,
        VC_12: 0.56442,
        QE_12: 0.226,
        VE_12: 0.56442,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.879037584899493, 34.572937813002625, 0.0],
          [-80.879813118231652, 34.571311413005162, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287260.0,
        COMID: 9734304,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.098,
        REACHCODE: "03050103000512",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00093793897797900001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059342.0,
        ToNode: 250094187.0,
        Hydroseq: 250005729.0,
        LevelPathI: 250005706.0,
        Pathlength: 400.166,
        TerminalPa: 250002604.0,
        ArbolateSu: 6821.294,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005706.0,
        UpHydroseq: 250005739.0,
        DnLevelPat: 250005706.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005706.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.054,
        TotDASqKM: 9190.2375,
        DivDASqKM: 0.054,
        Tidal: 0,
        TOTMA: 0.0085000526514900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13585.0,
        MAXELEVSMO: 13600.0,
        MINELEVSMO: 13587.0,
        SLOPE: 0.00132653,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.097999997436999997,
        QA_MA: 0.021,
        VA_MA: 0.4378,
        QC_MA: 0.021,
        VC_MA: 0.4378,
        QE_MA: 0.021,
        VE_MA: 0.4378,
        QA_01: 0.047,
        VA_01: 0.50688,
        QC_01: 0.047,
        VC_01: 0.50688,
        QE_01: 0.047,
        VE_01: 0.50688,
        QA_02: 0.048,
        VA_02: 0.50911,
        QC_02: 0.048,
        VC_02: 0.50911,
        QE_02: 0.048,
        VE_02: 0.50911,
        QA_03: 0.052,
        VA_03: 0.51783,
        QC_03: 0.052,
        VC_03: 0.51783,
        QE_03: 0.052,
        VE_03: 0.51783,
        QA_04: 0.03,
        VA_04: 0.46478,
        QC_04: 0.03,
        VC_04: 0.46478,
        QE_04: 0.03,
        VE_04: 0.46478,
        QA_05: 0.016,
        VA_05: 0.4204,
        QC_05: 0.016,
        VC_05: 0.4204,
        QE_05: 0.016,
        VE_05: 0.4204,
        QA_06: 0.01,
        VA_06: 0.39566,
        QC_06: 0.01,
        VC_06: 0.39566,
        QE_06: 0.01,
        VE_06: 0.39566,
        QA_07: 0.007,
        VA_07: 0.3806,
        QC_07: 0.007,
        VC_07: 0.3806,
        QE_07: 0.007,
        VE_07: 0.3806,
        QA_08: 0.005,
        VA_08: 0.36879,
        QC_08: 0.005,
        VC_08: 0.36879,
        QE_08: 0.005,
        VE_08: 0.36879,
        QA_09: 0.004,
        VA_09: 0.36204,
        QC_09: 0.004,
        VC_09: 0.36204,
        QE_09: 0.004,
        VE_09: 0.36204,
        QA_10: 0.003,
        VA_10: 0.35444,
        QC_10: 0.003,
        VC_10: 0.35444,
        QE_10: 0.003,
        VE_10: 0.35444,
        QA_11: 0.003,
        VA_11: 0.35444,
        QC_11: 0.003,
        VC_11: 0.35444,
        QE_11: 0.003,
        VE_11: 0.35444,
        QA_12: 0.024,
        VA_12: 0.44731,
        QC_12: 0.024,
        VC_12: 0.44731,
        QE_12: 0.024,
        VE_12: 0.44731,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878766318233332, 34.791948879329368, 0.0],
          [-80.878210984900775, 34.791193012663769, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286243.0,
        COMID: 9745204,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.257,
        REACHCODE: "03050102000070",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024300325590689998,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060186.0,
        ToNode: 250060193.0,
        Hydroseq: 250018329.0,
        LevelPathI: 250011553.0,
        Pathlength: 553.578,
        TerminalPa: 250002604.0,
        ArbolateSu: 256.054,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250018599.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018072.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0495,
        TotDASqKM: 280.4256,
        DivDASqKM: 280.4256,
        Tidal: 0,
        TOTMA: 0.0130197852918,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24687.0,
        MAXELEVSMO: 24834.0,
        MINELEVSMO: 24834.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.25699999928474399,
        QA_MA: 164.807,
        VA_MA: 0.74752,
        QC_MA: 163.723,
        VC_MA: 0.74728,
        QE_MA: 174.455,
        VE_MA: 0.74955,
        QA_01: 286.242,
        VA_01: 0.8971,
        QC_01: 223.817,
        VC_01: 0.82655,
        QE_01: 225.619,
        VE_01: 0.8141,
        QA_02: 300.133,
        VA_02: 0.9121,
        QC_02: 250.303,
        VC_02: 0.85825,
        QE_02: 245.768,
        VE_02: 0.8376,
        QA_03: 321.399,
        VA_03: 0.93445,
        QC_03: 270.485,
        VC_03: 0.88136,
        QE_03: 281.731,
        VE_03: 0.8774,
        QA_04: 237.663,
        VA_04: 0.84174,
        QC_04: 219.631,
        VC_04: 0.82138,
        QE_04: 245.979,
        VE_04: 0.83784,
        QA_05: 175.822,
        VA_05: 0.76287,
        QC_05: 164.559,
        VC_05: 0.74847,
        QE_05: 189.817,
        VE_05: 0.76977,
        QA_06: 102.443,
        VA_06: 0.64954,
        QC_06: 128.286,
        VC_06: 0.69397,
        QE_06: 155.701,
        VE_06: 0.72369,
        QA_07: 60.21,
        VA_07: 0.56566,
        QC_07: 91.056,
        VC_07: 0.6298,
        QE_07: 112.803,
        VE_07: 0.65837,
        QA_08: 41.434,
        VA_08: 0.51935,
        QC_08: 92.606,
        VC_08: 0.63269,
        QE_08: 109.969,
        VE_08: 0.65367,
        QA_09: 38.958,
        VA_09: 0.51256,
        QC_09: 99.242,
        VC_09: 0.64483,
        QE_09: 100.361,
        VE_09: 0.63731,
        QA_10: 54.188,
        VA_10: 0.55166,
        QC_10: 133.495,
        VC_10: 0.7022,
        QE_10: 123.467,
        VE_10: 0.67556,
        QA_11: 143.517,
        VA_11: 0.71642,
        QC_11: 168.791,
        VC_11: 0.75445,
        QE_11: 134.711,
        VE_11: 0.69296,
        QA_12: 216.735,
        VA_12: 0.81626,
        QC_12: 176.402,
        VC_12: 0.76502,
        QE_12: 163.207,
        VE_12: 0.73421,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.308860384232389, 35.66306141131048, 0.0],
          [-81.308721784232546, 35.661046011313545, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288430.0,
        COMID: 9735762,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.606,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0054551265307330003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059748.0,
        ToNode: 250059751.0,
        Hydroseq: 250004483.0,
        LevelPathI: 250004457.0,
        Pathlength: 371.995,
        TerminalPa: 250002604.0,
        ArbolateSu: 7671.332,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004494.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004468.0,
        DnDrainCou: 2,
        DnHydroseq: 250004470.0,
        FromMeas: 13.73298,
        ToMeas: 55.93861,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0972,
        TotDASqKM: 9975.7467,
        DivDASqKM: 0.8721,
        Tidal: 0,
        TOTMA: 0.038443395131200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8938.0,
        MAXELEVSMO: 9190.0,
        MINELEVSMO: 8938.0,
        SLOPE: 0.00415841,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60600000619888295,
        QA_MA: 0.288,
        VA_MA: 0.59858,
        QC_MA: 0.288,
        VC_MA: 0.59858,
        QE_MA: 0.288,
        VE_MA: 0.59858,
        QA_01: 0.621,
        VA_01: 0.74478,
        QC_01: 0.621,
        VC_01: 0.74478,
        QE_01: 0.621,
        VE_01: 0.74478,
        QA_02: 0.691,
        VA_02: 0.77025,
        QC_02: 0.691,
        VC_02: 0.77025,
        QE_02: 0.691,
        VE_02: 0.77025,
        QA_03: 0.767,
        VA_03: 0.79656,
        QC_03: 0.767,
        VC_03: 0.79656,
        QE_03: 0.767,
        VE_03: 0.79656,
        QA_04: 0.398,
        VA_04: 0.65297,
        QC_04: 0.398,
        VC_04: 0.65297,
        QE_04: 0.398,
        VE_04: 0.65297,
        QA_05: 0.222,
        VA_05: 0.56112,
        QC_05: 0.222,
        VC_05: 0.56112,
        QE_05: 0.222,
        VE_05: 0.56112,
        QA_06: 0.152,
        VA_06: 0.51507,
        QC_06: 0.152,
        VC_06: 0.51507,
        QE_06: 0.152,
        VE_06: 0.51507,
        QA_07: 0.117,
        VA_07: 0.48826,
        QC_07: 0.117,
        VC_07: 0.48826,
        QE_07: 0.117,
        VE_07: 0.48826,
        QA_08: 0.093,
        VA_08: 0.46761,
        QC_08: 0.093,
        VC_08: 0.46761,
        QE_08: 0.093,
        VE_08: 0.46761,
        QA_09: 0.07,
        VA_09: 0.44532,
        QC_09: 0.07,
        VC_09: 0.44532,
        QE_09: 0.07,
        VE_09: 0.44532,
        QA_10: 0.058,
        VA_10: 0.43231,
        QC_10: 0.058,
        VC_10: 0.43231,
        QE_10: 0.058,
        VE_10: 0.43231,
        QA_11: 0.046,
        VA_11: 0.41795,
        QC_11: 0.046,
        VC_11: 0.41795,
        QE_11: 0.046,
        VE_11: 0.41795,
        QA_12: 0.221,
        VA_12: 0.56052,
        QC_12: 0.221,
        VC_12: 0.56052,
        QE_12: 0.221,
        VE_12: 0.56052,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878705384900002, 34.578275012994254, 0.0],
          [-80.879037584899493, 34.572937813002625, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287261.0,
        COMID: 9734680,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.19,
        REACHCODE: "03050103000515",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0017456596354990001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059474.0,
        ToNode: 250094169.0,
        Hydroseq: 250005360.0,
        LevelPathI: 250005331.0,
        Pathlength: 393.639,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.912,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005331.0,
        UpHydroseq: 250005368.0,
        DnLevelPat: 250005331.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005343.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0162,
        TotDASqKM: 9368.3052,
        DivDASqKM: 0.0918,
        Tidal: 0,
        TOTMA: 0.019214386787,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12709.0,
        MAXELEVSMO: 12739.0,
        MINELEVSMO: 12739.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18999999761581399,
        QA_MA: 0.035,
        VA_MA: 0.37549,
        QC_MA: 0.035,
        VC_MA: 0.37549,
        QE_MA: 0.035,
        VE_MA: 0.37549,
        QA_01: 0.077,
        VA_01: 0.41037,
        QC_01: 0.077,
        VC_01: 0.41037,
        QE_01: 0.077,
        VE_01: 0.41037,
        QA_02: 0.082,
        VA_02: 0.41384,
        QC_02: 0.082,
        VC_02: 0.41384,
        QE_02: 0.082,
        VE_02: 0.41384,
        QA_03: 0.088,
        VA_03: 0.41787,
        QC_03: 0.088,
        VC_03: 0.41787,
        QE_03: 0.088,
        VE_03: 0.41787,
        QA_04: 0.05,
        VA_04: 0.38948,
        QC_04: 0.05,
        VC_04: 0.38948,
        QE_04: 0.05,
        VE_04: 0.38948,
        QA_05: 0.028,
        VA_05: 0.36799,
        QC_05: 0.028,
        VC_05: 0.36799,
        QE_05: 0.028,
        VE_05: 0.36799,
        QA_06: 0.018,
        VA_06: 0.35553,
        QC_06: 0.018,
        VC_06: 0.35553,
        QE_06: 0.018,
        VE_06: 0.35553,
        QA_07: 0.012,
        VA_07: 0.3464,
        QC_07: 0.012,
        VC_07: 0.3464,
        QE_07: 0.012,
        VE_07: 0.3464,
        QA_08: 0.009,
        VA_08: 0.34101,
        QC_08: 0.009,
        VC_08: 0.34101,
        QE_08: 0.009,
        VE_08: 0.34101,
        QA_09: 0.007,
        VA_09: 0.33694,
        QC_09: 0.007,
        VC_09: 0.33694,
        QE_09: 0.007,
        VE_09: 0.33694,
        QA_10: 0.006,
        VA_10: 0.33469,
        QC_10: 0.006,
        VC_10: 0.33469,
        QE_10: 0.006,
        VE_10: 0.33469,
        QA_11: 0.006,
        VA_11: 0.33469,
        QC_11: 0.006,
        VC_11: 0.33469,
        QE_11: 0.006,
        VE_11: 0.33469,
        QA_12: 0.039,
        VA_12: 0.37945,
        QC_12: 0.039,
        VC_12: 0.37945,
        QE_12: 0.039,
        VE_12: 0.37945,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875655318238117, 34.737656279413613, 0.0],
          [-80.875294584905305, 34.736098679415988, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286244.0,
        COMID: 9745198,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 3.462,
        REACHCODE: "03050102000071",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.034306387468904,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060183.0,
        ToNode: 250060186.0,
        Hydroseq: 250018599.0,
        LevelPathI: 250011553.0,
        Pathlength: 553.835,
        TerminalPa: 250002604.0,
        ArbolateSu: 246.382,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250018885.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018329.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.5783,
        TotDASqKM: 268.1721,
        DivDASqKM: 268.1721,
        Tidal: 0,
        TOTMA: 0.105318276323,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24701.0,
        MAXELEVSMO: 25261.0,
        MINELEVSMO: 24834.0,
        SLOPE: 0.00123339,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.461999893188477,
        QA_MA: 158.981,
        VA_MA: 1.24364,
        QC_MA: 158.157,
        VC_MA: 1.24324,
        QE_MA: 168.888,
        VE_MA: 1.24823,
        QA_01: 275.304,
        VA_01: 1.56024,
        QC_01: 215.484,
        VC_01: 1.41011,
        QE_01: 217.287,
        VE_01: 1.38278,
        QA_02: 288.494,
        VA_02: 1.59174,
        QC_02: 240.856,
        VC_02: 1.47719,
        QE_02: 236.321,
        VE_02: 1.43177,
        QA_03: 308.842,
        VA_03: 1.63903,
        QC_03: 260.26,
        VC_03: 1.52628,
        QE_03: 271.506,
        VE_03: 1.51769,
        QA_04: 228.833,
        VA_04: 1.44318,
        QC_04: 211.652,
        VC_04: 1.39966,
        QE_04: 238.001,
        VE_04: 1.43601,
        QA_05: 169.75,
        VA_05: 1.27676,
        QC_05: 159.086,
        VC_05: 1.24616,
        QE_05: 184.343,
        VE_05: 1.29296,
        QA_06: 98.928,
        VA_06: 1.03538,
        QC_06: 123.943,
        VC_06: 1.12974,
        QE_06: 151.359,
        VE_06: 1.1951,
        QA_07: 58.054,
        VA_07: 0.85617,
        QC_07: 87.872,
        VC_07: 0.99263,
        QE_07: 109.619,
        VE_07: 1.05549,
        QA_08: 39.952,
        VA_08: 0.75758,
        QC_08: 89.227,
        VC_08: 0.99822,
        QE_08: 106.59,
        VE_08: 1.04446,
        QA_09: 37.805,
        VA_09: 0.74459,
        QC_09: 96.644,
        VC_09: 1.0281,
        QE_09: 97.763,
        VE_09: 1.01143,
        QA_10: 53.199,
        VA_10: 0.83135,
        QC_10: 131.546,
        VC_10: 1.15612,
        QE_10: 121.518,
        VE_10: 1.09751,
        QA_11: 140.339,
        VA_11: 1.1837,
        QC_11: 165.932,
        VC_11: 1.26737,
        QE_11: 131.852,
        VE_11: 1.13246,
        QA_12: 209.288,
        VA_12: 1.39064,
        QC_12: 170.879,
        VC_12: 1.28245,
        QE_12: 157.684,
        VE_12: 1.21459,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.319285984216208, 35.684159411277847, 0.0],
          [-81.317461917552293, 35.68342567794565, 0.0],
          [-81.320946184213597, 35.677976477953962, 0.0],
          [-81.317325384219203, 35.677791677954303, 0.0],
          [-81.309556984231278, 35.669382811300636, 0.0],
          [-81.308800784232403, 35.667321211303943, 0.0],
          [-81.311440584228365, 35.665192811307293, 0.0],
          [-81.308860384232389, 35.66306141131048, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288423.0,
        COMID: 9735514,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.162,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0016410043849580001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059715.0,
        ToNode: 250059717.0,
        Hydroseq: 250004766.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.242,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.17,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004787.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004744.0,
        DnDrainCou: 2,
        DnHydroseq: 250004746.0,
        FromMeas: 50.82151,
        ToMeas: 59.74054,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0306,
        TotDASqKM: 9867.5082,
        DivDASqKM: 0.2664,
        Tidal: 0,
        TOTMA: 0.0112837739019,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9795.0,
        MAXELEVSMO: 10055.0,
        MINELEVSMO: 9951.0,
        SLOPE: 0.00641975,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.16200000047683699,
        QA_MA: 0.088,
        VA_MA: 0.54517,
        QC_MA: 0.088,
        VC_MA: 0.54517,
        QE_MA: 0.088,
        VE_MA: 0.54517,
        QA_01: 0.189,
        VA_01: 0.66372,
        QC_01: 0.189,
        VC_01: 0.66372,
        QE_01: 0.189,
        VE_01: 0.66372,
        QA_02: 0.211,
        VA_02: 0.68511,
        QC_02: 0.211,
        VC_02: 0.68511,
        QE_02: 0.211,
        VE_02: 0.68511,
        QA_03: 0.236,
        VA_03: 0.70819,
        QC_03: 0.236,
        VC_03: 0.70819,
        QE_03: 0.236,
        VE_03: 0.70819,
        QA_04: 0.121,
        VA_04: 0.5888,
        QC_04: 0.121,
        VC_04: 0.5888,
        QE_04: 0.121,
        VE_04: 0.5888,
        QA_05: 0.068,
        VA_05: 0.51488,
        QC_05: 0.068,
        VC_05: 0.51488,
        QE_05: 0.068,
        VE_05: 0.51488,
        QA_06: 0.046,
        VA_06: 0.47618,
        QC_06: 0.046,
        VC_06: 0.47618,
        QE_06: 0.046,
        VE_06: 0.47618,
        QA_07: 0.035,
        VA_07: 0.45351,
        QC_07: 0.035,
        VC_07: 0.45351,
        QE_07: 0.035,
        VE_07: 0.45351,
        QA_08: 0.028,
        VA_08: 0.4373,
        QC_08: 0.028,
        VC_08: 0.4373,
        QE_08: 0.028,
        VE_08: 0.4373,
        QA_09: 0.021,
        VA_09: 0.41903,
        QC_09: 0.021,
        VC_09: 0.41903,
        QE_09: 0.021,
        VE_09: 0.41903,
        QA_10: 0.017,
        VA_10: 0.40729,
        QC_10: 0.017,
        VC_10: 0.40729,
        QE_10: 0.017,
        VE_10: 0.40729,
        QA_11: 0.014,
        VA_11: 0.3976,
        QC_11: 0.014,
        VC_11: 0.3976,
        QE_11: 0.014,
        VE_11: 0.3976,
        QA_12: 0.068,
        VA_12: 0.51488,
        QC_12: 0.068,
        VC_12: 0.51488,
        QE_12: 0.068,
        VE_12: 0.51488,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886156384888466, 34.590162812975848, 0.0],
          [-80.884826784890549, 34.589201012977412, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287262.0,
        COMID: 9734692,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.106,
        REACHCODE: "03050103000516",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00095959264741600004,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059480.0,
        ToNode: 250059482.0,
        Hydroseq: 250005347.0,
        LevelPathI: 250005304.0,
        Pathlength: 393.553,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.038,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005355.0,
        DnLevelPat: 250005304.0,
        DnMinorHyd: 250005334.0,
        DnDrainCou: 2,
        DnHydroseq: 250005335.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 9368.3466,
        DivDASqKM: 0.0297,
        Tidal: 0,
        TOTMA: 0.011179603883200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12694.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.105999998748302,
        QA_MA: 0.011,
        VA_MA: 0.36004,
        QC_MA: 0.011,
        VC_MA: 0.36004,
        QE_MA: 0.011,
        VE_MA: 0.36004,
        QA_01: 0.025,
        VA_01: 0.38826,
        QC_01: 0.025,
        VC_01: 0.38826,
        QE_01: 0.025,
        VE_01: 0.38826,
        QA_02: 0.026,
        VA_02: 0.38995,
        QC_02: 0.026,
        VC_02: 0.38995,
        QE_02: 0.026,
        VE_02: 0.38995,
        QA_03: 0.028,
        VA_03: 0.39322,
        QC_03: 0.028,
        VC_03: 0.39322,
        QE_03: 0.028,
        VE_03: 0.39322,
        QA_04: 0.016,
        VA_04: 0.37141,
        QC_04: 0.016,
        VC_04: 0.37141,
        QE_04: 0.016,
        VE_04: 0.37141,
        QA_05: 0.009,
        VA_05: 0.35482,
        QC_05: 0.009,
        VC_05: 0.35482,
        QE_05: 0.009,
        VE_05: 0.35482,
        QA_06: 0.005,
        VA_06: 0.34237,
        QC_06: 0.005,
        VC_06: 0.34237,
        QE_06: 0.005,
        VE_06: 0.34237,
        QA_07: 0.003,
        VA_07: 0.3343,
        QC_07: 0.003,
        VC_07: 0.3343,
        QE_07: 0.003,
        VE_07: 0.3343,
        QA_08: 0.003,
        VA_08: 0.3343,
        QC_08: 0.003,
        VC_08: 0.3343,
        QE_08: 0.003,
        VE_08: 0.3343,
        QA_09: 0.002,
        VA_09: 0.32928,
        QC_09: 0.002,
        VC_09: 0.32928,
        QE_09: 0.002,
        VE_09: 0.32928,
        QA_10: 0.001,
        VA_10: 0.32285,
        QC_10: 0.001,
        VC_10: 0.32285,
        QE_10: 0.001,
        VE_10: 0.32285,
        QA_11: 0.001,
        VA_11: 0.32285,
        QC_11: 0.001,
        VC_11: 0.32285,
        QE_11: 0.001,
        VE_11: 0.32285,
        QA_12: 0.012,
        VA_12: 0.36248,
        QC_12: 0.012,
        VC_12: 0.36248,
        QE_12: 0.012,
        VE_12: 0.36248,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876237918237123, 34.735640612749989, 0.0],
          [-80.876071518237438, 34.734724279418117, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286245.0,
        COMID: 9745120,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.999,
        REACHCODE: "03050102000072",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020858720901991999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060161.0,
        ToNode: 250060183.0,
        Hydroseq: 250018885.0,
        LevelPathI: 250011553.0,
        Pathlength: 557.297,
        TerminalPa: 250002604.0,
        ArbolateSu: 238.752,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250019186.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018599.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.6335,
        TotDASqKM: 259.7292,
        DivDASqKM: 259.7292,
        Tidal: 0,
        TOTMA: 0.060591645641800002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25070.0,
        MAXELEVSMO: 25526.0,
        MINELEVSMO: 25261.0,
        SLOPE: 0.00132566,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.9989999532699581,
        QA_MA: 154.906,
        VA_MA: 1.24796,
        QC_MA: 154.257,
        VC_MA: 1.24764,
        QE_MA: 164.989,
        VE_MA: 1.25277,
        QA_01: 267.701,
        VA_01: 1.56466,
        QC_01: 209.687,
        VC_01: 1.41394,
        QE_01: 211.49,
        VE_01: 1.38586,
        QA_02: 280.369,
        VA_02: 1.59589,
        QC_02: 234.255,
        VC_02: 1.48093,
        QE_02: 229.72,
        VE_02: 1.43422,
        QA_03: 300.1,
        VA_03: 1.64323,
        QC_03: 253.133,
        VC_03: 1.53019,
        QE_03: 264.379,
        VE_03: 1.52144,
        QA_04: 222.675,
        VA_04: 1.44763,
        QC_04: 206.085,
        VC_04: 1.40381,
        QE_04: 232.433,
        VE_04: 1.44126,
        QA_05: 165.459,
        VA_05: 1.28142,
        QC_05: 155.213,
        VC_05: 1.25073,
        QE_05: 180.47,
        VE_05: 1.29883,
        QA_06: 96.451,
        VA_06: 1.03897,
        QC_06: 120.881,
        VC_06: 1.13358,
        QE_06: 148.297,
        VE_06: 1.20077,
        QA_07: 56.548,
        VA_07: 0.85861,
        QC_07: 85.647,
        VC_07: 0.9956,
        QE_07: 107.393,
        VE_07: 1.06023,
        QA_08: 38.926,
        VA_08: 0.75964,
        QC_08: 86.89,
        VC_08: 1.00088,
        QE_08: 104.253,
        VE_08: 1.04848,
        QA_09: 37.006,
        VA_09: 0.74768,
        QC_09: 94.839,
        VC_09: 1.03383,
        QE_09: 95.958,
        VE_09: 1.0166,
        QA_10: 52.518,
        VA_10: 0.83742,
        QC_10: 130.199,
        VC_10: 1.16677,
        QE_10: 120.171,
        VE_10: 1.10648,
        QA_11: 138.041,
        VA_11: 1.19217,
        QC_11: 163.856,
        VC_11: 1.27823,
        QE_11: 129.776,
        VE_11: 1.13974,
        QA_12: 204.067,
        VA_12: 1.39604,
        QC_12: 166.996,
        VC_12: 1.28806,
        QE_12: 153.801,
        VE_12: 1.21821,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.333066117528062, 35.689592411269416, 0.0],
          [-81.328573984201796, 35.690690211267565, 0.0],
          [-81.329110117534299, 35.685491677942309, 0.0],
          [-81.319285984216208, 35.684159411277847, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288424.0,
        COMID: 9735598,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.152,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001579895008507,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059729.0,
        ToNode: 250059732.0,
        Hydroseq: 250004604.0,
        LevelPathI: 250004604.0,
        Pathlength: 373.079,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.758,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004616.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004582.0,
        FromMeas: 89.29594,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 9868.3236,
        DivDASqKM: 0.0081,
        Tidal: 0,
        TOTMA: 0.016707233515399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9951.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.019999999552965001,
        QA_MA: 0.002,
        VA_MA: 0.34547,
        QC_MA: 0.002,
        VC_MA: 0.34547,
        QE_MA: 0.002,
        VE_MA: 0.34547,
        QA_01: 0.005,
        VA_01: 0.36871,
        QC_01: 0.005,
        VC_01: 0.36871,
        QE_01: 0.005,
        VE_01: 0.36871,
        QA_02: 0.006,
        VA_02: 0.37484,
        QC_02: 0.006,
        VC_02: 0.37484,
        QE_02: 0.006,
        VE_02: 0.37484,
        QA_03: 0.007,
        VA_03: 0.38051,
        QC_03: 0.007,
        VC_03: 0.38051,
        QE_03: 0.007,
        VE_03: 0.38051,
        QA_04: 0.003,
        VA_04: 0.35438,
        QC_04: 0.003,
        VC_04: 0.35438,
        QE_04: 0.003,
        VE_04: 0.35438,
        QA_05: 0.002,
        VA_05: 0.34547,
        QC_05: 0.002,
        VC_05: 0.34547,
        QE_05: 0.002,
        VE_05: 0.34547,
        QA_06: 0.001,
        VA_06: 0.33405,
        QC_06: 0.001,
        VC_06: 0.33405,
        QE_06: 0.001,
        VE_06: 0.33405,
        QA_07: 0.001,
        VA_07: 0.33405,
        QC_07: 0.001,
        VC_07: 0.33405,
        QE_07: 0.001,
        VE_07: 0.33405,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.34547,
        QC_12: 0.002,
        VC_12: 0.34547,
        QE_12: 0.002,
        VE_12: 0.34547,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881502784895645, 34.582924879653781, 0.0],
          [-80.880117918231178, 34.582169012988345, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286246.0,
        COMID: 9745090,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.896,
        REACHCODE: "03050102000073",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.019173617191032,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060153.0,
        ToNode: 250060161.0,
        Hydroseq: 250019186.0,
        LevelPathI: 250011553.0,
        Pathlength: 559.296,
        TerminalPa: 250002604.0,
        ArbolateSu: 233.503,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250019510.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250018885.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9557,
        TotDASqKM: 255.2499,
        DivDASqKM: 255.2499,
        Tidal: 0,
        TOTMA: 0.057860350042399998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 25327.0,
        MAXELEVSMO: 25769.0,
        MINELEVSMO: 25526.0,
        SLOPE: 0.00128164,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.896000027656555,
        QA_MA: 152.707,
        VA_MA: 1.23943,
        QC_MA: 152.153,
        VC_MA: 1.23916,
        QE_MA: 162.884,
        VE_MA: 1.24431,
        QA_01: 263.641,
        VA_01: 1.55261,
        QC_01: 206.59,
        VC_01: 1.40329,
        QE_01: 208.393,
        VE_01: 1.37513,
        QA_02: 276.011,
        VA_02: 1.58328,
        QC_02: 230.712,
        VC_02: 1.46941,
        QE_02: 226.177,
        VE_02: 1.42254,
        QA_03: 295.405,
        VA_03: 1.63009,
        QC_03: 249.303,
        VC_03: 1.51819,
        QE_03: 260.548,
        VE_03: 1.50946,
        QA_04: 219.365,
        VA_04: 1.43689,
        QC_04: 203.09,
        VC_04: 1.3934,
        QE_04: 229.439,
        VE_04: 1.43104,
        QA_05: 163.124,
        VA_05: 1.27264,
        QC_05: 153.103,
        VC_05: 1.24224,
        QE_05: 178.361,
        VE_05: 1.29053,
        QA_06: 95.104,
        VA_06: 1.03243,
        QC_06: 119.217,
        VC_06: 1.12608,
        QE_06: 146.632,
        VE_06: 1.1935,
        QA_07: 55.74,
        VA_07: 0.85359,
        QC_07: 84.453,
        VC_07: 0.98929,
        QE_07: 106.199,
        VE_07: 1.05416,
        QA_08: 38.384,
        VA_08: 0.75561,
        QC_08: 85.656,
        VC_08: 0.99442,
        QE_08: 103.019,
        VE_08: 1.04221,
        QA_09: 36.584,
        VA_09: 0.74435,
        QC_09: 93.884,
        VC_09: 1.02866,
        QE_09: 95.003,
        VE_09: 1.01132,
        QA_10: 52.157,
        VA_10: 0.83469,
        QC_10: 129.483,
        VC_10: 1.16274,
        QE_10: 119.455,
        VE_10: 1.10222,
        QA_11: 136.709,
        VA_11: 1.1863,
        QC_11: 162.649,
        VC_11: 1.27272,
        QE_11: 128.569,
        VE_11: 1.13383,
        QA_12: 201.235,
        VA_12: 1.38636,
        QC_12: 164.886,
        VC_12: 1.27974,
        QE_12: 151.691,
        VE_12: 1.20959,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.344628917510192, 35.698756477921791, 0.0],
          [-81.344546584176896, 35.693076677930605, 0.0],
          [-81.336882517522156, 35.692845677931018, 0.0],
          [-81.333066117528062, 35.689592411269416, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288425.0,
        COMID: 9735652,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.126,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0011353552833810001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059736.0,
        ToNode: 250059748.0,
        Hydroseq: 250004494.0,
        LevelPathI: 250004457.0,
        Pathlength: 372.601,
        TerminalPa: 250002604.0,
        ArbolateSu: 7670.726,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004505.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004481.0,
        DnDrainCou: 2,
        DnHydroseq: 250004483.0,
        FromMeas: 55.93861,
        ToMeas: 64.75092,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.036,
        TotDASqKM: 9975.6495,
        DivDASqKM: 0.7749,
        Tidal: 0,
        TOTMA: 0.0079750615233000009,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9190.0,
        MAXELEVSMO: 9254.0,
        MINELEVSMO: 9190.0,
        SLOPE: 0.00507936,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.12600000202655801,
        QA_MA: 0.256,
        VA_MA: 0.59994,
        QC_MA: 0.256,
        VC_MA: 0.59994,
        QE_MA: 0.256,
        VE_MA: 0.59994,
        QA_01: 0.552,
        VA_01: 0.74683,
        QC_01: 0.552,
        VC_01: 0.74683,
        QE_01: 0.552,
        VE_01: 0.74683,
        QA_02: 0.614,
        VA_02: 0.77233,
        QC_02: 0.614,
        VC_02: 0.77233,
        QE_02: 0.614,
        VE_02: 0.77233,
        QA_03: 0.682,
        VA_03: 0.79894,
        QC_03: 0.682,
        VC_03: 0.79894,
        QE_03: 0.682,
        VE_03: 0.79894,
        QA_04: 0.354,
        VA_04: 0.6547,
        QC_04: 0.354,
        VC_04: 0.6547,
        QE_04: 0.354,
        VE_04: 0.6547,
        QA_05: 0.197,
        VA_05: 0.56208,
        QC_05: 0.197,
        VC_05: 0.56208,
        QE_05: 0.197,
        VE_05: 0.56208,
        QA_06: 0.135,
        VA_06: 0.51595,
        QC_06: 0.135,
        VC_06: 0.51595,
        QE_06: 0.135,
        VE_06: 0.51595,
        QA_07: 0.104,
        VA_07: 0.4891,
        QC_07: 0.104,
        VC_07: 0.4891,
        QE_07: 0.104,
        VE_07: 0.4891,
        QA_08: 0.083,
        VA_08: 0.46871,
        QC_08: 0.083,
        VC_08: 0.46871,
        QE_08: 0.083,
        VE_08: 0.46871,
        QA_09: 0.062,
        VA_09: 0.4457,
        QC_09: 0.062,
        VC_09: 0.4457,
        QE_09: 0.062,
        VE_09: 0.4457,
        QA_10: 0.052,
        VA_10: 0.43346,
        QC_10: 0.052,
        VC_10: 0.43346,
        QE_10: 0.052,
        VE_10: 0.43346,
        QA_11: 0.041,
        VA_11: 0.41863,
        QC_11: 0.041,
        VC_11: 0.41863,
        QE_11: 0.041,
        VE_11: 0.41863,
        QA_12: 0.197,
        VA_12: 0.56208,
        QC_12: 0.197,
        VC_12: 0.56208,
        QE_12: 0.197,
        VE_12: 0.56208,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878677584900117, 34.579397479659292, 0.0],
          [-80.878705384900002, 34.578275012994254, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286239.0,
        COMID: 9745302,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 1.815,
        REACHCODE: "03050102000066",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.017900540938775002,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060216.0,
        ToNode: 250060222.0,
        Hydroseq: 250017362.0,
        LevelPathI: 250011553.0,
        Pathlength: 549.957,
        TerminalPa: 250002604.0,
        ArbolateSu: 268.103,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250017591.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017140.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0494,
        TotDASqKM: 290.133,
        DivDASqKM: 290.133,
        Tidal: 0,
        TOTMA: 0.056643976711299997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24464.0,
        MAXELEVSMO: 24724.0,
        MINELEVSMO: 24561.0,
        SLOPE: 0.00089807,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.815000057220459,
        QA_MA: 169.434,
        VA_MA: 1.21275,
        QC_MA: 168.139,
        VC_MA: 1.21219,
        QE_MA: 178.871,
        VE_MA: 1.21673,
        QA_01: 294.918,
        VA_01: 1.52221,
        QC_01: 230.42,
        VC_01: 1.37681,
        QE_01: 232.223,
        VE_01: 1.35178,
        QA_02: 309.382,
        VA_02: 1.55347,
        QC_02: 257.803,
        VC_02: 1.44245,
        QE_02: 253.268,
        VE_02: 1.40097,
        QA_03: 331.402,
        VA_03: 1.59976,
        QC_03: 278.622,
        VC_03: 1.49019,
        QE_03: 289.868,
        VE_03: 1.48215,
        QA_04: 244.636,
        VA_04: 1.40752,
        QC_04: 225.926,
        VC_04: 1.36569,
        QE_04: 252.275,
        VE_04: 1.39869,
        QA_05: 180.581,
        VA_05: 1.24387,
        QC_05: 168.845,
        VC_05: 1.2142,
        QE_05: 194.102,
        VE_05: 1.25702,
        QA_06: 105.193,
        VA_06: 1.01052,
        QC_06: 131.682,
        VC_06: 1.10219,
        QE_06: 159.097,
        VE_06: 1.16195,
        QA_07: 61.912,
        VA_07: 0.83827,
        QC_07: 93.568,
        VC_07: 0.97047,
        QE_07: 115.314,
        VE_07: 1.02786,
        QA_08: 42.609,
        VA_08: 0.74291,
        QC_08: 95.285,
        VC_08: 0.97689,
        QE_08: 112.648,
        VE_08: 1.01898,
        QA_09: 39.872,
        VA_09: 0.72786,
        QC_09: 101.294,
        VC_09: 0.99896,
        QE_09: 102.413,
        VE_09: 0.98392,
        QA_10: 54.971,
        VA_10: 0.80584,
        QC_10: 135.034,
        VC_10: 1.11286,
        QE_10: 125.006,
        VE_10: 1.05936,
        QA_11: 146.124,
        VA_11: 1.1444,
        QC_11: 171.123,
        VC_11: 1.22067,
        QE_11: 137.043,
        VE_11: 1.09693,
        QA_12: 222.68,
        VA_12: 1.35398,
        QC_12: 180.799,
        VC_12: 1.24771,
        QE_12: 167.604,
        VE_12: 1.18589,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.306597917569206, 35.650028877997329, 0.0],
          [-81.302197317576088, 35.644736011338978, 0.0],
          [-81.304528517572408, 35.641897478010037, 0.0],
          [-81.302453784242289, 35.64031601134576, 0.0],
          [-81.303857784240165, 35.638828278014728, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288426.0,
        COMID: 9735634,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.249,
        REACHCODE: "03050103001296",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0022680859665509998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059734.0,
        ToNode: 250059735.0,
        Hydroseq: 250004547.0,
        LevelPathI: 250004457.0,
        Pathlength: 372.74,
        TerminalPa: 250002604.0,
        ArbolateSu: 7669.826,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004582.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004505.0,
        FromMeas: 65.64306,
        ToMeas: 82.97974,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0558,
        TotDASqKM: 9975.3201,
        DivDASqKM: 0.7146,
        Tidal: 0,
        TOTMA: 0.0154663502047,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9306.0,
        MAXELEVSMO: 9487.0,
        MINELEVSMO: 9306.0,
        SLOPE: 0.00726907,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.24899999797344199,
        QA_MA: 0.236,
        VA_MA: 0.61134,
        QC_MA: 0.236,
        VC_MA: 0.61134,
        QE_MA: 0.236,
        VE_MA: 0.61134,
        QA_01: 0.509,
        VA_01: 0.76404,
        QC_01: 0.509,
        VC_01: 0.76404,
        QE_01: 0.509,
        VE_01: 0.76404,
        QA_02: 0.566,
        VA_02: 0.79046,
        QC_02: 0.566,
        VC_02: 0.79046,
        QE_02: 0.566,
        VE_02: 0.79046,
        QA_03: 0.629,
        VA_03: 0.81824,
        QC_03: 0.629,
        VC_03: 0.81824,
        QE_03: 0.629,
        VE_03: 0.81824,
        QA_04: 0.326,
        VA_04: 0.66804,
        QC_04: 0.326,
        VC_04: 0.66804,
        QE_04: 0.326,
        VE_04: 0.66804,
        QA_05: 0.182,
        VA_05: 0.5723,
        QC_05: 0.182,
        VC_05: 0.5723,
        QE_05: 0.182,
        VE_05: 0.5723,
        QA_06: 0.124,
        VA_06: 0.52365,
        QC_06: 0.124,
        VC_06: 0.52365,
        QE_06: 0.124,
        VE_06: 0.52365,
        QA_07: 0.096,
        VA_07: 0.4963,
        QC_07: 0.096,
        VC_07: 0.4963,
        QE_07: 0.096,
        VE_07: 0.4963,
        QA_08: 0.076,
        VA_08: 0.47438,
        QC_08: 0.076,
        VC_08: 0.47438,
        QE_08: 0.076,
        VE_08: 0.47438,
        QA_09: 0.057,
        VA_09: 0.45086,
        QC_09: 0.057,
        VC_09: 0.45086,
        QE_09: 0.057,
        VE_09: 0.45086,
        QA_10: 0.048,
        VA_10: 0.43844,
        QC_10: 0.048,
        VC_10: 0.43844,
        QE_10: 0.048,
        VE_10: 0.43844,
        QA_11: 0.038,
        VA_11: 0.42327,
        QC_11: 0.038,
        VC_11: 0.42327,
        QE_11: 0.038,
        VE_11: 0.42327,
        QA_12: 0.182,
        VA_12: 0.5723,
        QC_12: 0.182,
        VC_12: 0.5723,
        QE_12: 0.182,
        VE_12: 0.5723,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.879425518232267, 34.581642212988982, 0.0],
          [-80.878677718233462, 34.579512012992325, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286240.0,
        COMID: 9745248,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.501,
        REACHCODE: "03050102000067",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0053577396920989999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060202.0,
        ToNode: 250060216.0,
        Hydroseq: 250017591.0,
        LevelPathI: 250011553.0,
        Pathlength: 551.772,
        TerminalPa: 250002604.0,
        ArbolateSu: 264.293,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250017823.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017362.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3681,
        TotDASqKM: 287.0946,
        DivDASqKM: 287.0946,
        Tidal: 0,
        TOTMA: 0.025307714027700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24586.0,
        MAXELEVSMO: 24724.0,
        MINELEVSMO: 24724.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50099998712539695,
        QA_MA: 168.013,
        VA_MA: 0.74974,
        QC_MA: 166.783,
        VC_MA: 0.74948,
        QE_MA: 177.515,
        VE_MA: 0.75172,
        QA_01: 292.222,
        VA_01: 0.90053,
        QC_01: 228.368,
        VC_01: 0.82959,
        QE_01: 230.171,
        VE_01: 0.81729,
        QA_02: 306.523,
        VA_02: 0.91575,
        QC_02: 255.485,
        VC_02: 0.86158,
        QE_02: 250.95,
        VE_02: 0.84119,
        QA_03: 328.301,
        VA_03: 0.93829,
        QC_03: 276.101,
        VC_03: 0.88486,
        QE_03: 287.347,
        VE_03: 0.88091,
        QA_04: 242.499,
        VA_04: 0.84469,
        QC_04: 223.997,
        VC_04: 0.82427,
        QE_04: 250.346,
        VE_04: 0.8405,
        QA_05: 179.141,
        VA_05: 0.76503,
        QC_05: 167.549,
        VC_05: 0.75056,
        QE_05: 192.806,
        VE_05: 0.7716,
        QA_06: 104.364,
        VA_06: 0.65114,
        QC_06: 130.657,
        VC_06: 0.69586,
        QE_06: 158.073,
        VE_06: 0.72523,
        QA_07: 61.385,
        VA_07: 0.56697,
        QC_07: 92.791,
        VC_07: 0.63147,
        QE_07: 114.537,
        VE_07: 0.65969,
        QA_08: 42.241,
        VA_08: 0.52042,
        QC_08: 94.446,
        VC_08: 0.63452,
        QE_08: 111.809,
        VE_08: 0.65522,
        QA_09: 39.586,
        VA_09: 0.51324,
        QC_09: 100.653,
        VC_09: 0.64573,
        QE_09: 101.772,
        VE_09: 0.63833,
        QA_10: 54.726,
        VA_10: 0.55167,
        QC_10: 134.552,
        VC_10: 0.70195,
        QE_10: 124.525,
        VE_10: 0.67564,
        QA_11: 145.37,
        VA_11: 0.71709,
        QC_11: 170.449,
        VC_11: 0.7546,
        QE_11: 136.369,
        VE_11: 0.69379,
        QA_12: 220.855,
        VA_12: 0.81872,
        QC_12: 179.451,
        VC_12: 0.76697,
        QE_12: 166.256,
        VE_12: 0.73656,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.311450784228214, 35.651840811327816, 0.0],
          [-81.306597917569206, 35.650028877997329, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288435.0,
        COMID: 9735756,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.135,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001363691241966,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059747.0,
        ToNode: 250059749.0,
        Hydroseq: 250004807.0,
        LevelPathI: 250004717.0,
        Pathlength: 372.063,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.023,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004823.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004765.0,
        FromMeas: 27.79033,
        ToMeas: 41.95669,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0279,
        TotDASqKM: 10619.9271,
        DivDASqKM: 0.2934,
        Tidal: 0,
        TOTMA: 0.0087807894755200008,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8859.0,
        MAXELEVSMO: 9081.0,
        MINELEVSMO: 8885.0,
        SLOPE: 0.01451851,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.135000005364418,
        QA_MA: 0.096,
        VA_MA: 0.58381,
        QC_MA: 0.096,
        VC_MA: 0.58381,
        QE_MA: 0.096,
        VE_MA: 0.58381,
        QA_01: 0.209,
        VA_01: 0.72469,
        QC_01: 0.209,
        VC_01: 0.72469,
        QE_01: 0.209,
        VE_01: 0.72469,
        QA_02: 0.232,
        VA_02: 0.74842,
        QC_02: 0.232,
        VC_02: 0.74842,
        QE_02: 0.232,
        VE_02: 0.74842,
        QA_03: 0.256,
        VA_03: 0.77204,
        QC_03: 0.256,
        VC_03: 0.77204,
        QE_03: 0.256,
        VE_03: 0.77204,
        QA_04: 0.134,
        VA_04: 0.63717,
        QC_04: 0.134,
        VC_04: 0.63717,
        QE_04: 0.134,
        VE_04: 0.63717,
        QA_05: 0.074,
        VA_05: 0.54826,
        QC_05: 0.074,
        VC_05: 0.54826,
        QE_05: 0.074,
        VE_05: 0.54826,
        QA_06: 0.051,
        VA_06: 0.50524,
        QC_06: 0.051,
        VC_06: 0.50524,
        QE_06: 0.051,
        VE_06: 0.50524,
        QA_07: 0.039,
        VA_07: 0.4791,
        QC_07: 0.039,
        VC_07: 0.4791,
        QE_07: 0.039,
        VE_07: 0.4791,
        QA_08: 0.031,
        VA_08: 0.45951,
        QC_08: 0.031,
        VC_08: 0.45951,
        QE_08: 0.031,
        VE_08: 0.45951,
        QA_09: 0.023,
        VA_09: 0.43736,
        QC_09: 0.023,
        VC_09: 0.43736,
        QE_09: 0.023,
        VE_09: 0.43736,
        QA_10: 0.019,
        VA_10: 0.42492,
        QC_10: 0.019,
        VC_10: 0.42492,
        QE_10: 0.019,
        VE_10: 0.42492,
        QA_11: 0.015,
        VA_11: 0.41117,
        QC_11: 0.015,
        VC_11: 0.41117,
        QE_11: 0.015,
        VE_11: 0.41117,
        QA_12: 0.072,
        VA_12: 0.54479,
        QC_12: 0.072,
        VC_12: 0.54479,
        QE_12: 0.072,
        VE_12: 0.54479,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882720718227176, 34.573922479667772, 0.0],
          [-80.881640718228766, 34.57309801300238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286241.0,
        COMID: 9745232,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.337,
        REACHCODE: "03050102000068",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0030934149517950001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060195.0,
        ToNode: 250060202.0,
        Hydroseq: 250017823.0,
        LevelPathI: 250011553.0,
        Pathlength: 552.273,
        TerminalPa: 250002604.0,
        ArbolateSu: 263.035,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250018072.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250017591.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0576,
        TotDASqKM: 286.1667,
        DivDASqKM: 286.1667,
        Tidal: 0,
        TOTMA: 0.017030150086699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24643.0,
        MAXELEVSMO: 24724.0,
        MINELEVSMO: 24724.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.337000012397766,
        QA_MA: 167.575,
        VA_MA: 0.74944,
        QC_MA: 166.365,
        VC_MA: 0.74918,
        QE_MA: 177.097,
        VE_MA: 0.75142,
        QA_01: 291.398,
        VA_01: 0.90005,
        QC_01: 227.742,
        VC_01: 0.82917,
        QE_01: 229.545,
        VE_01: 0.81684,
        QA_02: 305.643,
        VA_02: 0.91524,
        QC_02: 254.771,
        VC_02: 0.86112,
        QE_02: 250.237,
        VE_02: 0.84069,
        QA_03: 327.353,
        VA_03: 0.93776,
        QC_03: 275.33,
        VC_03: 0.88437,
        QE_03: 286.576,
        VE_03: 0.88043,
        QA_04: 241.841,
        VA_04: 0.84429,
        QC_04: 223.403,
        VC_04: 0.82387,
        QE_04: 249.751,
        VE_04: 0.84014,
        QA_05: 178.694,
        VA_05: 0.76474,
        QC_05: 167.147,
        VC_05: 0.75028,
        QE_05: 192.404,
        VE_05: 0.77136,
        QA_06: 104.104,
        VA_06: 0.65093,
        QC_06: 130.337,
        VC_06: 0.6956,
        QE_06: 157.752,
        VE_06: 0.72502,
        QA_07: 61.223,
        VA_07: 0.56678,
        QC_07: 92.552,
        VC_07: 0.63124,
        QE_07: 114.298,
        VE_07: 0.65951,
        QA_08: 42.129,
        VA_08: 0.52027,
        QC_08: 94.19,
        VC_08: 0.63426,
        QE_08: 111.553,
        VE_08: 0.655,
        QA_09: 39.498,
        VA_09: 0.51314,
        QC_09: 100.457,
        VC_09: 0.6456,
        QE_09: 101.576,
        VE_09: 0.63818,
        QA_10: 54.651,
        VA_10: 0.55166,
        QC_10: 134.405,
        VC_10: 0.70197,
        QE_10: 124.378,
        VE_10: 0.67562,
        QA_11: 145.128,
        VA_11: 0.71701,
        QC_11: 170.233,
        VC_11: 0.75459,
        QE_11: 136.153,
        VE_11: 0.69369,
        QA_12: 220.296,
        VA_12: 0.81839,
        QC_12: 179.037,
        VC_12: 0.7667,
        QE_12: 165.842,
        VE_12: 0.73624,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.311308317561853, 35.654680611323563, 0.0],
          [-81.311450784228214, 35.651840811327816, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288436.0,
        COMID: 9735780,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.098,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000953969783668,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059749.0,
        ToNode: 250059750.0,
        Hydroseq: 250004765.0,
        LevelPathI: 250004717.0,
        Pathlength: 371.965,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.278,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004807.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004730.0,
        FromMeas: 17.52481,
        ToMeas: 27.79033,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0126,
        TotDASqKM: 10619.9415,
        DivDASqKM: 0.3078,
        Tidal: 0,
        TOTMA: 0.0070183185614199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8828.0,
        MAXELEVSMO: 8885.0,
        MINELEVSMO: 8851.0,
        SLOPE: 0.00346938,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.097999997436999997,
        QA_MA: 0.101,
        VA_MA: 0.53023,
        QC_MA: 0.101,
        VC_MA: 0.53023,
        QE_MA: 0.101,
        VE_MA: 0.53023,
        QA_01: 0.219,
        VA_01: 0.64298,
        QC_01: 0.219,
        VC_01: 0.64298,
        QE_01: 0.219,
        VE_01: 0.64298,
        QA_02: 0.244,
        VA_02: 0.66275,
        QC_02: 0.244,
        VC_02: 0.66275,
        QE_02: 0.244,
        VE_02: 0.66275,
        QA_03: 0.268,
        VA_03: 0.68085,
        QC_03: 0.268,
        VC_03: 0.68085,
        QE_03: 0.268,
        VE_03: 0.68085,
        QA_04: 0.14,
        VA_04: 0.57223,
        QC_04: 0.14,
        VC_04: 0.57223,
        QE_04: 0.14,
        VE_04: 0.57223,
        QA_05: 0.078,
        VA_05: 0.50178,
        QC_05: 0.078,
        VC_05: 0.50178,
        QE_05: 0.078,
        VE_05: 0.50178,
        QA_06: 0.053,
        VA_06: 0.46591,
        QC_06: 0.053,
        VC_06: 0.46591,
        QE_06: 0.053,
        VE_06: 0.46591,
        QA_07: 0.041,
        VA_07: 0.44583,
        QC_07: 0.041,
        VC_07: 0.44583,
        QE_07: 0.041,
        VE_07: 0.44583,
        QA_08: 0.033,
        VA_08: 0.43087,
        QC_08: 0.033,
        VC_08: 0.43087,
        QE_08: 0.033,
        VE_08: 0.43087,
        QA_09: 0.024,
        VA_09: 0.41182,
        QC_09: 0.024,
        VC_09: 0.41182,
        QE_09: 0.024,
        VE_09: 0.41182,
        QA_10: 0.02,
        VA_10: 0.40227,
        QC_10: 0.02,
        VC_10: 0.40227,
        QE_10: 0.02,
        VE_10: 0.40227,
        QA_11: 0.016,
        VA_11: 0.39178,
        QC_11: 0.016,
        VC_11: 0.39178,
        QE_11: 0.016,
        VE_11: 0.39178,
        QA_12: 0.076,
        VA_12: 0.49913,
        QC_12: 0.076,
        VC_12: 0.49913,
        QE_12: 0.076,
        VE_12: 0.49913,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881640718228766, 34.57309801300238, 0.0],
          [-80.881003718229863, 34.572387879670146, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286242.0,
        COMID: 9745224,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "986626",
        GNIS_NAME: "Henry Fork",
        LENGTHKM: 0.968,
        REACHCODE: "03050102000069",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0095754080290539993,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060193.0,
        ToNode: 250060195.0,
        Hydroseq: 250018072.0,
        LevelPathI: 250011553.0,
        Pathlength: 552.61,
        TerminalPa: 250002604.0,
        ArbolateSu: 262.698,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250018329.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 250017822.0,
        DnDrainCou: 2,
        DnHydroseq: 250017823.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4932,
        TotDASqKM: 286.1091,
        DivDASqKM: 286.1091,
        Tidal: 0,
        TOTMA: 0.029438075874600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 24691.0,
        MAXELEVSMO: 24834.0,
        MINELEVSMO: 24724.0,
        SLOPE: 0.00113636,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.96799999475479104,
        QA_MA: 167.548,
        VA_MA: 1.24444,
        QC_MA: 166.339,
        VC_MA: 1.24389,
        QE_MA: 177.071,
        VE_MA: 1.24864,
        QA_01: 291.347,
        VA_01: 1.56408,
        QC_01: 227.703,
        VC_01: 1.41363,
        QE_01: 229.506,
        VE_01: 1.38748,
        QA_02: 305.588,
        VA_02: 1.5963,
        QC_02: 254.727,
        VC_02: 1.48145,
        QE_02: 250.192,
        VE_02: 1.43808,
        QA_03: 327.294,
        VA_03: 1.6441,
        QC_03: 275.282,
        VC_03: 1.5308,
        QE_03: 286.528,
        VE_03: 1.52242,
        QA_04: 241.8,
        VA_04: 1.44574,
        QC_04: 223.366,
        VC_04: 1.4024,
        QE_04: 249.715,
        VE_04: 1.43693,
        QA_05: 178.667,
        VA_05: 1.27692,
        QC_05: 167.122,
        VC_05: 1.24622,
        QE_05: 192.379,
        VE_05: 1.29096,
        QA_06: 104.088,
        VA_06: 1.03537,
        QC_06: 130.317,
        VC_06: 1.13018,
        QE_06: 157.733,
        VE_06: 1.19264,
        QA_07: 61.213,
        VA_07: 0.85678,
        QC_07: 92.537,
        VC_07: 0.99357,
        QE_07: 114.283,
        VE_07: 1.05358,
        QA_08: 42.122,
        VA_08: 0.75806,
        QC_08: 94.174,
        VC_08: 0.99998,
        QE_08: 111.537,
        VE_08: 1.04401,
        QA_09: 39.493,
        VA_09: 0.74293,
        QC_09: 100.444,
        VC_09: 1.02407,
        QE_09: 101.563,
        VE_09: 1.00832,
        QA_10: 54.646,
        VA_10: 0.82471,
        QC_10: 134.396,
        VC_10: 1.14374,
        QE_10: 124.369,
        VE_10: 1.0878,
        QA_11: 145.113,
        VA_11: 1.17564,
        QC_11: 170.22,
        VC_11: 1.25542,
        QE_11: 136.14,
        VE_11: 1.12614,
        QA_12: 220.261,
        VA_12: 1.39077,
        QC_12: 179.012,
        VC_12: 1.28108,
        QE_12: 165.817,
        VE_12: 1.21642,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.308721784232546, 35.661046011313545, 0.0],
          [-81.306702117569046, 35.65989981131537, 0.0],
          [-81.311308317561853, 35.654680611323563, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288437.0,
        COMID: 9735686,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.125,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0012442726545909999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059743.0,
        ToNode: 250059744.0,
        Hydroseq: 250004902.0,
        LevelPathI: 250004717.0,
        Pathlength: 372.555,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.979,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004938.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 250004879.0,
        DnDrainCou: 2,
        DnHydroseq: 250004884.0,
        FromMeas: 79.15064,
        ToMeas: 92.27032,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0225,
        TotDASqKM: 10619.6931,
        DivDASqKM: 0.0612,
        Tidal: 0,
        TOTMA: 0.0091670091120900007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9527.0,
        MAXELEVSMO: 9839.0,
        MINELEVSMO: 9527.0,
        SLOPE: 0.02496,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.125,
        QA_MA: 0.02,
        VA_MA: 0.51779,
        QC_MA: 0.02,
        VC_MA: 0.51779,
        QE_MA: 0.02,
        VE_MA: 0.51779,
        QA_01: 0.043,
        VA_01: 0.62281,
        QC_01: 0.043,
        VC_01: 0.62281,
        QE_01: 0.043,
        VE_01: 0.62281,
        QA_02: 0.048,
        VA_02: 0.64172,
        QC_02: 0.048,
        VC_02: 0.64172,
        QE_02: 0.048,
        VE_02: 0.64172,
        QA_03: 0.053,
        VA_03: 0.65973,
        QC_03: 0.053,
        VC_03: 0.65973,
        QE_03: 0.053,
        VE_03: 0.65973,
        QA_04: 0.027,
        VA_04: 0.55397,
        QC_04: 0.027,
        VC_04: 0.55397,
        QE_04: 0.027,
        VE_04: 0.55397,
        QA_05: 0.015,
        VA_05: 0.48813,
        QC_05: 0.015,
        VC_05: 0.48813,
        QE_05: 0.015,
        VE_05: 0.48813,
        QA_06: 0.01,
        VA_06: 0.45331,
        QC_06: 0.01,
        VC_06: 0.45331,
        QE_06: 0.01,
        VE_06: 0.45331,
        QA_07: 0.008,
        VA_07: 0.43712,
        QC_07: 0.008,
        VC_07: 0.43712,
        QE_07: 0.008,
        VE_07: 0.43712,
        QA_08: 0.006,
        VA_08: 0.41888,
        QC_08: 0.006,
        VC_08: 0.41888,
        QE_08: 0.006,
        VE_08: 0.41888,
        QA_09: 0.004,
        VA_09: 0.39748,
        QC_09: 0.004,
        VC_09: 0.39748,
        QE_09: 0.004,
        VE_09: 0.39748,
        QA_10: 0.004,
        VA_10: 0.39748,
        QC_10: 0.004,
        VC_10: 0.39748,
        QE_10: 0.004,
        VE_10: 0.39748,
        QA_11: 0.003,
        VA_11: 0.38486,
        QC_11: 0.003,
        VC_11: 0.38486,
        QE_11: 0.003,
        VE_11: 0.38486,
        QA_12: 0.015,
        VA_12: 0.48813,
        QC_12: 0.015,
        VC_12: 0.48813,
        QE_12: 0.015,
        VE_12: 0.48813,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885213518223281, 34.57761021299541, 0.0],
          [-80.884299584891323, 34.576785679663317, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286219.0,
        COMID: 9747186,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.85,
        REACHCODE: "03050102000047",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.027783108590056001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060404.0,
        ToNode: 250060410.0,
        Hydroseq: 250014035.0,
        LevelPathI: 250011553.0,
        Pathlength: 519.045,
        TerminalPa: 250002604.0,
        ArbolateSu: 639.667,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014161.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013925.0,
        FromMeas: 0.0,
        ToMeas: 36.41443,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.8151,
        TotDASqKM: 681.3144,
        DivDASqKM: 681.3144,
        Tidal: 0,
        TOTMA: 0.084468704706,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22827.0,
        MAXELEVSMO: 23005.0,
        MINELEVSMO: 22884.0,
        SLOPE: 0.00042456,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.8499999046325679,
        QA_MA: 381.87,
        VA_MA: 1.28191,
        QC_MA: 367.188,
        VC_MA: 1.27882,
        QE_MA: 378.481,
        VE_MA: 1.28121,
        QA_01: 675.313,
        VA_01: 1.62608,
        QC_01: 516.291,
        VC_01: 1.47133,
        QE_01: 519.192,
        VE_01: 1.459,
        QA_02: 705.803,
        VA_02: 1.65734,
        QC_02: 575.06,
        VC_02: 1.53984,
        QE_02: 571.52,
        VE_02: 1.51919,
        QA_03: 760.644,
        VA_03: 1.71202,
        QC_03: 622.201,
        VC_03: 1.59245,
        QE_03: 639.056,
        VE_03: 1.59317,
        QA_04: 552.667,
        VA_04: 1.49305,
        QC_04: 501.031,
        VC_04: 1.45295,
        QE_04: 535.906,
        VE_04: 1.47852,
        QA_05: 407.116,
        VA_05: 1.31557,
        QC_05: 369.256,
        VC_05: 1.28172,
        QE_05: 397.98,
        VE_05: 1.3075,
        QA_06: 233.741,
        VA_06: 1.05854,
        QC_06: 289.408,
        VC_06: 1.1636,
        QE_06: 322.203,
        VE_06: 1.2015,
        QA_07: 139.151,
        VA_07: 0.87795,
        QC_07: 206.265,
        VC_07: 1.02284,
        QE_07: 234.041,
        VE_07: 1.06206,
        QA_08: 96.169,
        VA_08: 0.77649,
        QC_08: 218.593,
        VC_08: 1.0452,
        QE_08: 240.097,
        VE_08: 1.07236,
        QA_09: 85.623,
        VA_09: 0.74849,
        QC_09: 198.903,
        VC_09: 1.00918,
        QE_09: 189.217,
        VE_09: 0.9816,
        QA_10: 113.719,
        VA_10: 0.82006,
        QC_10: 241.34,
        VC_10: 1.08497,
        QE_10: 185.903,
        VE_10: 0.97532,
        QA_11: 313.155,
        VA_11: 1.18457,
        QC_11: 306.034,
        VC_11: 1.18934,
        QE_11: 239.698,
        VE_11: 1.07168,
        QA_12: 501.83,
        VA_12: 1.43388,
        QC_12: 378.664,
        VC_12: 1.29481,
        QE_12: 356.36,
        VE_12: 1.25059,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.272863784288177, 35.500143211563454, 0.0],
          [-81.274489784285663, 35.498835411565381, 0.0],
          [-81.27424638428613, 35.492078878242467, 0.0],
          [-81.282177784273756, 35.487229678250173, 0.0],
          [-81.280390117609841, 35.483632611588916, 0.0],
          [-81.283697517604764, 35.480703611593526, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288438.0,
        COMID: 9735672,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.074,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00069586532741200001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059740.0,
        ToNode: 250059743.0,
        Hydroseq: 250004938.0,
        LevelPathI: 250004717.0,
        Pathlength: 372.68,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.668,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004954.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 250004896.0,
        DnDrainCou: 2,
        DnHydroseq: 250004902.0,
        FromMeas: 92.27032,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.036,
        TotDASqKM: 10619.6499,
        DivDASqKM: 0.036,
        Tidal: 0,
        TOTMA: 0.0051330375388900003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9839.0,
        MAXELEVSMO: 10785.0,
        MINELEVSMO: 9839.0,
        SLOPE: 0.12783783,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 0.011,
        VA_MA: 0.54743,
        QC_MA: 0.011,
        VC_MA: 0.54743,
        QE_MA: 0.011,
        VE_MA: 0.54743,
        QA_01: 0.025,
        VA_01: 0.67804,
        QC_01: 0.025,
        VC_01: 0.67804,
        QE_01: 0.025,
        VE_01: 0.67804,
        QA_02: 0.028,
        VA_02: 0.70097,
        QC_02: 0.028,
        VC_02: 0.70097,
        QE_02: 0.028,
        VE_02: 0.70097,
        QA_03: 0.031,
        VA_03: 0.72277,
        QC_03: 0.031,
        VC_03: 0.72277,
        QE_03: 0.031,
        VE_03: 0.72277,
        QA_04: 0.016,
        VA_04: 0.60005,
        QC_04: 0.016,
        VC_04: 0.60005,
        QE_04: 0.016,
        VE_04: 0.60005,
        QA_05: 0.009,
        VA_05: 0.52327,
        QC_05: 0.009,
        VC_05: 0.52327,
        QE_05: 0.009,
        VE_05: 0.52327,
        QA_06: 0.006,
        VA_06: 0.48164,
        QC_06: 0.006,
        VC_06: 0.48164,
        QE_06: 0.006,
        VE_06: 0.48164,
        QA_07: 0.004,
        VA_07: 0.44808,
        QC_07: 0.004,
        VC_07: 0.44808,
        QE_07: 0.004,
        VE_07: 0.44808,
        QA_08: 0.003,
        VA_08: 0.4283,
        QC_08: 0.003,
        VC_08: 0.4283,
        QE_08: 0.003,
        VE_08: 0.4283,
        QA_09: 0.002,
        VA_09: 0.40507,
        QC_09: 0.002,
        VC_09: 0.40507,
        QE_09: 0.002,
        VE_09: 0.40507,
        QA_10: 0.002,
        VA_10: 0.40507,
        QC_10: 0.002,
        VC_10: 0.40507,
        QE_10: 0.002,
        VE_10: 0.40507,
        QA_11: 0.001,
        VA_11: 0.3753,
        QC_11: 0.001,
        VC_11: 0.3753,
        QE_11: 0.001,
        VE_11: 0.3753,
        QA_12: 0.009,
        VA_12: 0.52327,
        QC_12: 0.009,
        VC_12: 0.52327,
        QE_12: 0.009,
        VE_12: 0.52327,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885573584889357, 34.578205679661039, 0.0],
          [-80.885213518223281, 34.57761021299541, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286220.0,
        COMID: 9745858,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 4.97,
        REACHCODE: "03050102000047",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.049265897470868,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060379.0,
        ToNode: 250060404.0,
        Hydroseq: 250014161.0,
        LevelPathI: 250011553.0,
        Pathlength: 521.895,
        TerminalPa: 250002604.0,
        ArbolateSu: 636.817,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014294.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014035.0,
        FromMeas: 36.41443,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.6114,
        TotDASqKM: 677.4993,
        DivDASqKM: 677.4993,
        Tidal: 0,
        TOTMA: 0.14893481281199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23005.0,
        MAXELEVSMO: 23199.0,
        MINELEVSMO: 23005.0,
        SLOPE: 0.00039034,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.9699997901916504,
        QA_MA: 380.12,
        VA_MA: 1.26783,
        QC_MA: 365.57,
        VC_MA: 1.2648,
        QE_MA: 376.863,
        VE_MA: 1.26716,
        QA_01: 671.876,
        VA_01: 1.60667,
        QC_01: 513.733,
        VC_01: 1.45419,
        QE_01: 516.634,
        VE_01: 1.44199,
        QA_02: 702.262,
        VA_02: 1.63753,
        QC_02: 572.253,
        VC_02: 1.52174,
        QE_02: 568.713,
        VE_02: 1.5013,
        QA_03: 756.795,
        VA_03: 1.69137,
        QC_03: 619.156,
        VC_03: 1.57357,
        QE_03: 636.011,
        VE_03: 1.57429,
        QA_04: 550.152,
        VA_04: 1.47594,
        QC_04: 498.802,
        VC_04: 1.43638,
        QE_04: 533.678,
        VE_04: 1.4617,
        QA_05: 405.386,
        VA_05: 1.30118,
        QC_05: 367.745,
        VC_05: 1.26782,
        QE_05: 396.469,
        VE_05: 1.29333,
        QA_06: 232.766,
        VA_06: 1.04785,
        QC_06: 288.217,
        VC_06: 1.15137,
        QE_06: 321.013,
        VE_06: 1.18888,
        QA_07: 138.535,
        VA_07: 0.86975,
        QC_07: 205.375,
        VC_07: 1.01255,
        QE_07: 233.151,
        VE_07: 1.05137,
        QA_08: 95.757,
        VA_08: 0.76979,
        QC_08: 217.637,
        VC_08: 1.03457,
        QE_08: 239.142,
        VE_08: 1.06145,
        QA_09: 85.264,
        VA_09: 0.74221,
        QC_09: 198.166,
        VC_09: 0.99931,
        QE_09: 188.48,
        VE_09: 0.97202,
        QA_10: 113.411,
        VA_10: 0.81317,
        QC_10: 240.818,
        VC_10: 1.07466,
        QE_10: 185.382,
        VE_10: 0.9662,
        QA_11: 312.23,
        VA_11: 1.17265,
        QC_11: 305.344,
        VC_11: 1.17761,
        QE_11: 239.008,
        VE_11: 1.06122,
        QA_12: 499.504,
        VA_12: 1.41757,
        QC_12: 377.067,
        VC_12: 1.28066,
        QE_12: 354.763,
        VE_12: 1.23688,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.292220117591583, 35.522430678195519, 0.0],
          [-81.289759717595302, 35.517962878202411, 0.0],
          [-81.284129984270749, 35.517363411536621, 0.0],
          [-81.285982917601245, 35.513814811542147, 0.0],
          [-81.28436058427036, 35.511798211545283, 0.0],
          [-81.28805671759801, 35.512969011543532, 0.0],
          [-81.288003984264662, 35.509991478214658, 0.0],
          [-81.283694984271392, 35.506461478220331, 0.0],
          [-81.278456784279513, 35.50682381155309, 0.0],
          [-81.27661418428238, 35.502127278226908, 0.0],
          [-81.274823384285241, 35.5008202782289, 0.0],
          [-81.273560984287087, 35.502445278226389, 0.0],
          [-81.272863784288177, 35.500143211563454, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286221.0,
        COMID: 9745810,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.841,
        REACHCODE: "03050102000048",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0086085068810520006,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060362.0,
        ToNode: 250060379.0,
        Hydroseq: 250014294.0,
        LevelPathI: 250011553.0,
        Pathlength: 526.865,
        TerminalPa: 250002604.0,
        ArbolateSu: 631.581,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014428.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014161.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2313,
        TotDASqKM: 670.833,
        DivDASqKM: 600.4737,
        Tidal: 0,
        TOTMA: 0.0227253526175,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23173.0,
        MAXELEVSMO: 23289.0,
        MINELEVSMO: 23199.0,
        SLOPE: 0.00107015,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.84100002050399802,
        QA_MA: 342.584,
        VA_MA: 1.40538,
        QC_MA: 330.803,
        VC_MA: 1.40228,
        QE_MA: 342.096,
        VE_MA: 1.40526,
        QA_01: 600.901,
        VA_01: 1.78677,
        QC_01: 460.809,
        VC_01: 1.61279,
        QE_01: 463.71,
        VE_01: 1.59753,
        QA_02: 628.086,
        VA_02: 1.82192,
        QC_02: 513.369,
        VC_02: 1.68979,
        QE_02: 509.829,
        VE_02: 1.66409,
        QA_03: 676.307,
        VA_03: 1.88255,
        QC_03: 555.364,
        VC_03: 1.74869,
        QE_03: 572.218,
        VE_03: 1.7498,
        QA_04: 495.662,
        VA_04: 1.6431,
        QC_04: 450.465,
        VC_04: 1.59716,
        QE_04: 485.341,
        VE_04: 1.62912,
        QA_05: 367.218,
        VA_05: 1.44659,
        QC_05: 334.356,
        VC_05: 1.4085,
        QE_05: 363.08,
        VE_05: 1.44049,
        QA_06: 211.092,
        VA_06: 1.15667,
        QC_06: 261.731,
        VC_06: 1.27436,
        QE_06: 294.526,
        VE_06: 1.32143,
        QA_07: 125.206,
        VA_07: 0.9512,
        QC_07: 186.065,
        VC_07: 1.11428,
        QE_07: 213.841,
        VE_07: 1.16306,
        QA_08: 86.481,
        VA_08: 0.83653,
        QC_08: 196.153,
        VC_08: 1.13719,
        QE_08: 217.658,
        VE_08: 1.17113,
        QA_09: 78.015,
        VA_09: 0.80841,
        QC_09: 183.214,
        VC_09: 1.1077,
        QE_09: 173.528,
        VE_09: 1.07333,
        QA_10: 107.197,
        VA_10: 0.90032,
        QC_10: 230.219,
        VC_10: 1.21075,
        QE_10: 174.782,
        VE_10: 1.07626,
        QA_11: 287.531,
        VA_11: 1.30794,
        QC_11: 286.744,
        VC_11: 1.32233,
        QE_11: 220.409,
        VE_11: 1.1769,
        QA_12: 449.594,
        VA_12: 1.57572,
        QC_12: 342.627,
        VC_12: 1.42287,
        QE_12: 320.323,
        VE_12: 1.36762,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.28627818426736, 35.524968678191613, 0.0],
          [-81.286559984266887, 35.523549011527052, 0.0],
          [-81.29073378426051, 35.523872478193312, 0.0],
          [-81.292220117591583, 35.522430678195519, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288432.0,
        COMID: 9735808,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.062,
        REACHCODE: "03050103001297",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00055257075897499998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059753.0,
        ToNode: 250059756.0,
        Hydroseq: 250004457.0,
        LevelPathI: 250004457.0,
        Pathlength: 371.736,
        TerminalPa: 250002604.0,
        ArbolateSu: 8046.02,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004470.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004304.0,
        FromMeas: 65.18635,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 10728.6516,
        DivDASqKM: 1.2186,
        Tidal: 0,
        TOTMA: 0.0054941691979299998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8851.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.061999998986720997,
        QA_MA: 0.402,
        VA_MA: 0.42851,
        QC_MA: 0.402,
        VC_MA: 0.42851,
        QE_MA: 0.402,
        VE_MA: 0.42851,
        QA_01: 0.868,
        VA_01: 0.48916,
        QC_01: 0.868,
        VC_01: 0.48916,
        QE_01: 0.868,
        VE_01: 0.48916,
        QA_02: 0.966,
        VA_02: 0.49973,
        QC_02: 0.966,
        VC_02: 0.49973,
        QE_02: 0.966,
        VE_02: 0.49973,
        QA_03: 1.07,
        VA_03: 0.5104,
        QC_03: 1.07,
        VC_03: 0.5104,
        QE_03: 1.07,
        VE_03: 0.5104,
        QA_04: 0.557,
        VA_04: 0.45122,
        QC_04: 0.557,
        VC_04: 0.45122,
        QE_04: 0.557,
        VE_04: 0.45122,
        QA_05: 0.311,
        VA_05: 0.41321,
        QC_05: 0.311,
        VC_05: 0.41321,
        QE_05: 0.311,
        VE_05: 0.41321,
        QA_06: 0.212,
        VA_06: 0.39391,
        QC_06: 0.212,
        VC_06: 0.39391,
        QE_06: 0.212,
        VE_06: 0.39391,
        QA_07: 0.163,
        VA_07: 0.38277,
        QC_07: 0.163,
        VC_07: 0.38277,
        QE_07: 0.163,
        VE_07: 0.38277,
        QA_08: 0.131,
        VA_08: 0.37462,
        QC_08: 0.131,
        VC_08: 0.37462,
        QE_08: 0.131,
        VE_08: 0.37462,
        QA_09: 0.098,
        VA_09: 0.36516,
        QC_09: 0.098,
        VC_09: 0.36516,
        QE_09: 0.098,
        VE_09: 0.36516,
        QA_10: 0.081,
        VA_10: 0.3597,
        QC_10: 0.081,
        VC_10: 0.3597,
        QE_10: 0.081,
        VE_10: 0.3597,
        QA_11: 0.065,
        VA_11: 0.35404,
        QC_11: 0.065,
        VC_11: 0.35404,
        QE_11: 0.065,
        VE_11: 0.35404,
        QA_12: 0.307,
        VA_12: 0.41249,
        QC_12: 0.307,
        VC_12: 0.41249,
        QE_12: 0.307,
        VE_12: 0.41249,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.879813118231652, 34.571311413005162, 0.0],
          [-80.879868384898273, 34.570761613005857, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287263.0,
        COMID: 9734694,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.087,
        REACHCODE: "03050103000517",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00077879999872700002,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059481.0,
        ToNode: 250059483.0,
        Hydroseq: 250005346.0,
        LevelPathI: 250005346.0,
        Pathlength: 393.515,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.033,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005346.0,
        UpHydroseq: 250005356.0,
        DnLevelPat: 250005304.0,
        DnMinorHyd: 250005321.0,
        DnDrainCou: 2,
        DnHydroseq: 250005322.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 9368.3223,
        DivDASqKM: 0.0054,
        Tidal: 0,
        TOTMA: 0.0096231387009900003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12689.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.086999997496605003,
        QA_MA: 0.002,
        VA_MA: 0.3433,
        QC_MA: 0.002,
        VC_MA: 0.3433,
        QE_MA: 0.002,
        VE_MA: 0.3433,
        QA_01: 0.004,
        VA_01: 0.35884,
        QC_01: 0.004,
        VC_01: 0.35884,
        QE_01: 0.004,
        VE_01: 0.35884,
        QA_02: 0.004,
        VA_02: 0.35884,
        QC_02: 0.004,
        VC_02: 0.35884,
        QE_02: 0.004,
        VE_02: 0.35884,
        QA_03: 0.005,
        VA_03: 0.36518,
        QC_03: 0.005,
        VC_03: 0.36518,
        QE_03: 0.005,
        VE_03: 0.36518,
        QA_04: 0.002,
        VA_04: 0.3433,
        QC_04: 0.002,
        VC_04: 0.3433,
        QE_04: 0.002,
        VE_04: 0.3433,
        QA_05: 0.001,
        VA_05: 0.33255,
        QC_05: 0.001,
        VC_05: 0.33255,
        QE_05: 0.001,
        VE_05: 0.33255,
        QA_06: 0.001,
        VA_06: 0.33255,
        QC_06: 0.001,
        VC_06: 0.33255,
        QE_06: 0.001,
        VE_06: 0.33255,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.3433,
        QC_12: 0.002,
        VC_12: 0.3433,
        QE_12: 0.002,
        VE_12: 0.3433,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875655318238117, 34.735457412750293, 0.0],
          [-80.875655318238117, 34.734678612751566, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286222.0,
        COMID: 9745798,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.306,
        REACHCODE: "03050102000049",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0033065942359309999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060358.0,
        ToNode: 250060362.0,
        Hydroseq: 250014428.0,
        LevelPathI: 250011553.0,
        Pathlength: 527.706,
        TerminalPa: 250002604.0,
        ArbolateSu: 628.44,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014568.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014294.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0648,
        TotDASqKM: 666.8775,
        DivDASqKM: 596.5182,
        Tidal: 0,
        TOTMA: 0.0140091887795,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23280.0,
        MAXELEVSMO: 23289.0,
        MINELEVSMO: 23289.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30599999427795399,
        QA_MA: 340.76,
        VA_MA: 0.82947,
        QC_MA: 329.109,
        VC_MA: 0.82801,
        QE_MA: 340.403,
        VE_MA: 0.82943,
        QA_01: 597.338,
        VA_01: 1.01041,
        QC_01: 458.148,
        VC_01: 0.92779,
        QE_01: 461.049,
        VE_01: 0.9205,
        QA_02: 624.397,
        VA_02: 1.02712,
        QC_02: 510.436,
        VC_02: 0.96437,
        QE_02: 506.895,
        VE_02: 0.9521,
        QA_03: 672.304,
        VA_03: 1.0559,
        QC_03: 552.185,
        VC_03: 0.99234,
        QE_03: 569.039,
        VE_03: 0.99287,
        QA_04: 493.019,
        VA_04: 0.94239,
        QC_04: 448.117,
        VC_04: 0.92055,
        QE_04: 482.993,
        VE_04: 0.9358,
        QA_05: 365.41,
        VA_05: 0.84916,
        QC_05: 332.771,
        VC_05: 0.83107,
        QE_05: 361.496,
        VE_05: 0.84633,
        QA_06: 210.074,
        VA_06: 0.71144,
        QC_06: 260.486,
        VC_06: 0.76733,
        QE_06: 293.281,
        VE_06: 0.78979,
        QA_07: 124.568,
        VA_07: 0.61377,
        QC_07: 185.139,
        VC_07: 0.69123,
        QE_07: 212.915,
        VE_07: 0.71451,
        QA_08: 86.043,
        VA_08: 0.5593,
        QC_08: 195.141,
        VC_08: 0.70208,
        QE_08: 216.645,
        VE_08: 0.71828,
        QA_09: 77.643,
        VA_09: 0.54598,
        QC_09: 182.442,
        VC_09: 0.68826,
        QE_09: 172.756,
        VE_09: 0.67185,
        QA_10: 106.878,
        VA_10: 0.58991,
        QC_10: 229.671,
        VC_10: 0.73765,
        QE_10: 174.235,
        VE_10: 0.6735,
        QA_11: 286.54,
        VA_11: 0.78366,
        QC_11: 285.99,
        VC_11: 0.79067,
        QE_11: 219.654,
        VE_11: 0.72129,
        QA_12: 447.187,
        VA_12: 0.91038,
        QC_12: 340.958,
        VC_12: 0.83786,
        QE_12: 318.654,
        VE_12: 0.81148,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.289359117595893, 35.525749611523679, 0.0],
          [-81.28627818426736, 35.524968678191613, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288433.0,
        COMID: 9735714,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.167,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015287061166059999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059744.0,
        ToNode: 250059746.0,
        Hydroseq: 250004884.0,
        LevelPathI: 250004717.0,
        Pathlength: 372.388,
        TerminalPa: 250002604.0,
        ArbolateSu: 7923.146,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004902.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004823.0,
        FromMeas: 61.78503,
        ToMeas: 79.15064,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1494,
        TotDASqKM: 10619.8425,
        DivDASqKM: 0.2106,
        Tidal: 0,
        TOTMA: 0.0109208987255,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9057.0,
        MAXELEVSMO: 9527.0,
        MINELEVSMO: 9163.0,
        SLOPE: 0.0217964,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.16699999570846599,
        QA_MA: 0.069,
        VA_MA: 0.58067,
        QC_MA: 0.069,
        VC_MA: 0.58067,
        QE_MA: 0.069,
        VE_MA: 0.58067,
        QA_01: 0.15,
        VA_01: 0.71963,
        QC_01: 0.15,
        VC_01: 0.71963,
        QE_01: 0.15,
        VE_01: 0.71963,
        QA_02: 0.167,
        VA_02: 0.74375,
        QC_02: 0.167,
        VC_02: 0.74375,
        QE_02: 0.167,
        VE_02: 0.74375,
        QA_03: 0.184,
        VA_03: 0.76675,
        QC_03: 0.184,
        VC_03: 0.76675,
        QE_03: 0.184,
        VE_03: 0.76675,
        QA_04: 0.096,
        VA_04: 0.63286,
        QC_04: 0.096,
        VC_04: 0.63286,
        QE_04: 0.096,
        VE_04: 0.63286,
        QA_05: 0.053,
        VA_05: 0.54508,
        QC_05: 0.053,
        VC_05: 0.54508,
        QE_05: 0.053,
        VE_05: 0.54508,
        QA_06: 0.036,
        VA_06: 0.50114,
        QC_06: 0.036,
        VC_06: 0.50114,
        QE_06: 0.036,
        VE_06: 0.50114,
        QA_07: 0.028,
        VA_07: 0.47706,
        QC_07: 0.028,
        VC_07: 0.47706,
        QE_07: 0.028,
        VE_07: 0.47706,
        QA_08: 0.022,
        VA_08: 0.45678,
        QC_08: 0.022,
        VC_08: 0.45678,
        QE_08: 0.022,
        VE_08: 0.45678,
        QA_09: 0.016,
        VA_09: 0.4337,
        QC_09: 0.016,
        VC_09: 0.4337,
        QE_09: 0.016,
        VE_09: 0.4337,
        QA_10: 0.014,
        VA_10: 0.42512,
        QC_10: 0.014,
        VC_10: 0.42512,
        QE_10: 0.014,
        VE_10: 0.42512,
        QA_11: 0.011,
        VA_11: 0.41109,
        QC_11: 0.011,
        VC_11: 0.41109,
        QE_11: 0.011,
        VE_11: 0.41109,
        QA_12: 0.052,
        VA_12: 0.5427,
        QC_12: 0.052,
        VC_12: 0.5427,
        QE_12: 0.052,
        VE_12: 0.5427,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884299584891323, 34.576785679663317, 0.0],
          [-80.883773184892107, 34.575365479665493, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287264.0,
        COMID: 9734698,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.043,
        REACHCODE: "03050103000518",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00047229045601,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059483.0,
        ToNode: 250094175.0,
        Hydroseq: 250005322.0,
        LevelPathI: 250005304.0,
        Pathlength: 393.472,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.274,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005335.0,
        DnLevelPat: 250005304.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005304.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9368.3619,
        DivDASqKM: 0.045,
        Tidal: 0,
        TOTMA: 0.0044696983888100001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12718.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.043000001460313998,
        QA_MA: 0.017,
        VA_MA: 0.36531,
        QC_MA: 0.017,
        VC_MA: 0.36531,
        QE_MA: 0.017,
        VE_MA: 0.36531,
        QA_01: 0.038,
        VA_01: 0.39563,
        QC_01: 0.038,
        VC_01: 0.39563,
        QE_01: 0.038,
        VE_01: 0.39563,
        QA_02: 0.04,
        VA_02: 0.39804,
        QC_02: 0.04,
        VC_02: 0.39804,
        QE_02: 0.04,
        VE_02: 0.39804,
        QA_03: 0.043,
        VA_03: 0.40155,
        QC_03: 0.043,
        VC_03: 0.40155,
        QE_03: 0.043,
        VE_03: 0.40155,
        QA_04: 0.024,
        VA_04: 0.37674,
        QC_04: 0.024,
        VC_04: 0.37674,
        QE_04: 0.024,
        VE_04: 0.37674,
        QA_05: 0.013,
        VA_05: 0.35775,
        QC_05: 0.013,
        VC_05: 0.35775,
        QE_05: 0.013,
        VE_05: 0.35775,
        QA_06: 0.009,
        VA_06: 0.34899,
        QC_06: 0.009,
        VC_06: 0.34899,
        QE_06: 0.009,
        VE_06: 0.34899,
        QA_07: 0.006,
        VA_07: 0.34113,
        QC_07: 0.006,
        VC_07: 0.34113,
        QE_07: 0.006,
        VE_07: 0.34113,
        QA_08: 0.004,
        VA_08: 0.33479,
        QC_08: 0.004,
        VC_08: 0.33479,
        QE_08: 0.004,
        VE_08: 0.33479,
        QA_09: 0.003,
        VA_09: 0.33105,
        QC_09: 0.003,
        VC_09: 0.33105,
        QE_09: 0.003,
        VE_09: 0.33105,
        QA_10: 0.003,
        VA_10: 0.33105,
        QC_10: 0.003,
        VC_10: 0.33105,
        QE_10: 0.003,
        VE_10: 0.33105,
        QA_11: 0.003,
        VA_11: 0.33105,
        QC_11: 0.003,
        VC_11: 0.33105,
        QE_11: 0.003,
        VE_11: 0.33105,
        QA_12: 0.019,
        VA_12: 0.36877,
        QC_12: 0.019,
        VC_12: 0.36877,
        QE_12: 0.019,
        VE_12: 0.36877,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875655318238117, 34.734678612751566, 0.0],
          [-80.875183584905528, 34.734655679418267, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288434.0,
        COMID: 9735798,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.103,
        REACHCODE: "03050103001298",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010396945142509999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059752.0,
        ToNode: 250059753.0,
        Hydroseq: 250004717.0,
        LevelPathI: 250004717.0,
        Pathlength: 371.798,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.671,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004730.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004457.0,
        FromMeas: 0.0,
        ToMeas: 10.81391,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 10619.9595,
        DivDASqKM: 0.3258,
        Tidal: 0,
        TOTMA: 0.0098501185649900007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8745.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 0.107,
        VA_MA: 0.39707,
        QC_MA: 0.107,
        VC_MA: 0.39707,
        QE_MA: 0.107,
        VE_MA: 0.39707,
        QA_01: 0.232,
        VA_01: 0.44214,
        QC_01: 0.232,
        VC_01: 0.44214,
        QE_01: 0.232,
        VE_01: 0.44214,
        QA_02: 0.258,
        VA_02: 0.44991,
        QC_02: 0.258,
        VC_02: 0.44991,
        QE_02: 0.258,
        VE_02: 0.44991,
        QA_03: 0.284,
        VA_03: 0.45731,
        QC_03: 0.284,
        VC_03: 0.45731,
        QE_03: 0.284,
        VE_03: 0.45731,
        QA_04: 0.148,
        VA_04: 0.41374,
        QC_04: 0.148,
        VC_04: 0.41374,
        QE_04: 0.148,
        VE_04: 0.41374,
        QA_05: 0.083,
        VA_05: 0.38588,
        QC_05: 0.083,
        VC_05: 0.38588,
        QE_05: 0.083,
        VE_05: 0.38588,
        QA_06: 0.056,
        VA_06: 0.37127,
        QC_06: 0.056,
        VC_06: 0.37127,
        QE_06: 0.056,
        VE_06: 0.37127,
        QA_07: 0.043,
        VA_07: 0.36304,
        QC_07: 0.043,
        VC_07: 0.36304,
        QE_07: 0.043,
        VE_07: 0.36304,
        QA_08: 0.035,
        VA_08: 0.35738,
        QC_08: 0.035,
        VC_08: 0.35738,
        QE_08: 0.035,
        VE_08: 0.35738,
        QA_09: 0.026,
        VA_09: 0.35023,
        QC_09: 0.026,
        VC_09: 0.35023,
        QE_09: 0.026,
        VE_09: 0.35023,
        QA_10: 0.021,
        VA_10: 0.34574,
        QC_10: 0.021,
        VC_10: 0.34574,
        QE_10: 0.021,
        VE_10: 0.34574,
        QA_11: 0.017,
        VA_11: 0.34178,
        QC_11: 0.017,
        VC_11: 0.34178,
        QE_11: 0.017,
        VE_11: 0.34178,
        QA_12: 0.08,
        VA_12: 0.38438,
        QC_12: 0.08,
        VC_12: 0.38438,
        QE_12: 0.08,
        VE_12: 0.38438,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880616118230435, 34.571906879670848, 0.0],
          [-80.879813118231652, 34.571311413005162, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287265.0,
        COMID: 9734704,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.056,
        REACHCODE: "03050103000519",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00052129321777,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059486.0,
        ToNode: 250094177.0,
        Hydroseq: 250005307.0,
        LevelPathI: 250005292.0,
        Pathlength: 393.317,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.494,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005292.0,
        UpHydroseq: 250005334.0,
        DnLevelPat: 250005292.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005292.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0144,
        TotDASqKM: 9368.3727,
        DivDASqKM: 0.0144,
        Tidal: 0,
        TOTMA: 0.0060428259051099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12793.0,
        MAXELEVSMO: 12793.0,
        MINELEVSMO: 12793.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.056000001728534997,
        QA_MA: 0.005,
        VA_MA: 0.3519,
        QC_MA: 0.005,
        VC_MA: 0.3519,
        QE_MA: 0.005,
        VE_MA: 0.3519,
        QA_01: 0.012,
        VA_01: 0.37765,
        QC_01: 0.012,
        VC_01: 0.37765,
        QE_01: 0.012,
        VE_01: 0.37765,
        QA_02: 0.012,
        VA_02: 0.37765,
        QC_02: 0.012,
        VC_02: 0.37765,
        QE_02: 0.012,
        VE_02: 0.37765,
        QA_03: 0.013,
        VA_03: 0.38066,
        QC_03: 0.013,
        VC_03: 0.38066,
        QE_03: 0.013,
        VE_03: 0.38066,
        QA_04: 0.007,
        VA_04: 0.36041,
        QC_04: 0.007,
        VC_04: 0.36041,
        QE_04: 0.007,
        VE_04: 0.36041,
        QA_05: 0.004,
        VA_05: 0.34704,
        QC_05: 0.004,
        VC_05: 0.34704,
        QE_05: 0.004,
        VE_05: 0.34704,
        QA_06: 0.002,
        VA_06: 0.33514,
        QC_06: 0.002,
        VC_06: 0.33514,
        QE_06: 0.002,
        VE_06: 0.33514,
        QA_07: 0.001,
        VA_07: 0.3269,
        QC_07: 0.001,
        VC_07: 0.3269,
        QE_07: 0.001,
        VE_07: 0.3269,
        QA_08: 0.001,
        VA_08: 0.3269,
        QC_08: 0.001,
        VC_08: 0.3269,
        QE_08: 0.001,
        VE_08: 0.3269,
        QA_09: 0.001,
        VA_09: 0.3269,
        QC_09: 0.001,
        VC_09: 0.3269,
        QE_09: 0.001,
        VE_09: 0.3269,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.006,
        VA_12: 0.35632,
        QC_12: 0.006,
        VC_12: 0.35632,
        QE_12: 0.006,
        VE_12: 0.35632,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875572184904911, 34.733853879419428, 0.0],
          [-80.875433518238481, 34.733395812753599, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288476.0,
        COMID: 9735904,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.365,
        REACHCODE: "03050103001323",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00381552223437,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059781.0,
        ToNode: 250060061.0,
        Hydroseq: 250019854.0,
        LevelPathI: 250019511.0,
        Pathlength: 370.629,
        TerminalPa: 250002604.0,
        ArbolateSu: 261.664,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250019511.0,
        UpHydroseq: 250020198.0,
        DnLevelPat: 250019511.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019511.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0873,
        TotDASqKM: 517.6332,
        DivDASqKM: 0.0873,
        Tidal: 0,
        TOTMA: 0.0370797251257,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8616.0,
        MAXELEVSMO: 8617.0,
        MINELEVSMO: 8617.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.365000009536743,
        QA_MA: 0.028,
        VA_MA: 0.37379,
        QC_MA: 0.028,
        VC_MA: 0.37379,
        QE_MA: 0.028,
        VE_MA: 0.37379,
        QA_01: 0.061,
        VA_01: 0.40728,
        QC_01: 0.061,
        VC_01: 0.40728,
        QE_01: 0.061,
        VE_01: 0.40728,
        QA_02: 0.069,
        VA_02: 0.41397,
        QC_02: 0.069,
        VC_02: 0.41397,
        QE_02: 0.069,
        VE_02: 0.41397,
        QA_03: 0.076,
        VA_03: 0.41953,
        QC_03: 0.076,
        VC_03: 0.41953,
        QE_03: 0.076,
        VE_03: 0.41953,
        QA_04: 0.039,
        VA_04: 0.38638,
        QC_04: 0.039,
        VC_04: 0.38638,
        QE_04: 0.039,
        VE_04: 0.38638,
        QA_05: 0.022,
        VA_05: 0.36593,
        QC_05: 0.022,
        VC_05: 0.36593,
        QE_05: 0.022,
        VE_05: 0.36593,
        QA_06: 0.015,
        VA_06: 0.35534,
        QC_06: 0.015,
        VC_06: 0.35534,
        QE_06: 0.015,
        VE_06: 0.35534,
        QA_07: 0.011,
        VA_07: 0.34821,
        QC_07: 0.011,
        VC_07: 0.34821,
        QE_07: 0.011,
        VE_07: 0.34821,
        QA_08: 0.009,
        VA_08: 0.34419,
        QC_08: 0.009,
        VC_08: 0.34419,
        QE_08: 0.009,
        VE_08: 0.34419,
        QA_09: 0.007,
        VA_09: 0.33972,
        QC_09: 0.007,
        VC_09: 0.33972,
        QE_09: 0.007,
        VE_09: 0.33972,
        QA_10: 0.005,
        VA_10: 0.33459,
        QC_10: 0.005,
        VC_10: 0.33459,
        QE_10: 0.005,
        VE_10: 0.33459,
        QA_11: 0.004,
        VA_11: 0.33166,
        QC_11: 0.004,
        VC_11: 0.33166,
        QE_11: 0.004,
        VE_11: 0.33166,
        QA_12: 0.019,
        VA_12: 0.36162,
        QC_12: 0.019,
        VC_12: 0.36162,
        QE_12: 0.019,
        VE_12: 0.36162,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.90055071819944, 34.558389013025248, 0.0],
          [-80.897310718204494, 34.556809213027577, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288477.0,
        COMID: 9734444,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.093,
        REACHCODE: "03050103001324",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00082880009799,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059389.0,
        ToNode: 250059392.0,
        Hydroseq: 250005479.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.219,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.377,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005489.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 250005467.0,
        DnDrainCou: 2,
        DnHydroseq: 250005468.0,
        FromMeas: 0.0,
        ToMeas: 36.22453,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0648,
        TotDASqKM: 9333.909,
        DivDASqKM: 1.0701,
        Tidal: 0,
        TOTMA: 0.0060981865065900002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13032.0,
        MAXELEVSMO: 13052.0,
        MINELEVSMO: 13035.0,
        SLOPE: 0.00182795,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.093000002205371995,
        QA_MA: 0.421,
        VA_MA: 0.5791,
        QC_MA: 0.421,
        VC_MA: 0.5791,
        QE_MA: 0.421,
        VE_MA: 0.5791,
        QA_01: 0.92,
        VA_01: 0.71838,
        QC_01: 0.92,
        VC_01: 0.71838,
        QE_01: 0.92,
        VE_01: 0.71838,
        QA_02: 0.963,
        VA_02: 0.72845,
        QC_02: 0.963,
        VC_02: 0.72845,
        QE_02: 0.963,
        VE_02: 0.72845,
        QA_03: 1.036,
        VA_03: 0.74507,
        QC_03: 1.036,
        VC_03: 0.74507,
        QE_03: 1.036,
        VE_03: 0.74507,
        QA_04: 0.604,
        VA_04: 0.63629,
        QC_04: 0.604,
        VC_04: 0.63629,
        QE_04: 0.604,
        VE_04: 0.63629,
        QA_05: 0.33,
        VA_05: 0.54626,
        QC_05: 0.33,
        VC_05: 0.54626,
        QE_05: 0.33,
        VE_05: 0.54626,
        QA_06: 0.215,
        VA_06: 0.49786,
        QC_06: 0.215,
        VC_06: 0.49786,
        QE_06: 0.215,
        VE_06: 0.49786,
        QA_07: 0.158,
        VA_07: 0.46927,
        QC_07: 0.158,
        VC_07: 0.46927,
        QE_07: 0.158,
        VE_07: 0.46927,
        QA_08: 0.115,
        VA_08: 0.4443,
        QC_08: 0.115,
        VC_08: 0.4443,
        QE_08: 0.115,
        VE_08: 0.4443,
        QA_09: 0.086,
        VA_09: 0.42486,
        QC_09: 0.086,
        VC_09: 0.42486,
        QE_09: 0.086,
        VE_09: 0.42486,
        QA_10: 0.071,
        VA_10: 0.41359,
        QC_10: 0.071,
        VC_10: 0.41359,
        QE_10: 0.071,
        VE_10: 0.41359,
        QA_11: 0.071,
        VA_11: 0.41359,
        QC_11: 0.071,
        VC_11: 0.41359,
        QE_11: 0.071,
        VE_11: 0.41359,
        QA_12: 0.488,
        VA_12: 0.60118,
        QC_12: 0.488,
        VC_12: 0.60118,
        QE_12: 0.488,
        VE_12: 0.60118,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877294184902269, 34.775342012688441, 0.0],
          [-80.877377518235448, 34.774517412689647, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286227.0,
        COMID: 9745678,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.31,
        REACHCODE: "03050102000054",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.012552241875803,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060332.0,
        ToNode: 250060334.0,
        Hydroseq: 250015144.0,
        LevelPathI: 250011553.0,
        Pathlength: 537.094,
        TerminalPa: 250002604.0,
        ArbolateSu: 556.752,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015297.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015003.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.1745,
        TotDASqKM: 580.1508,
        DivDASqKM: 580.1508,
        Tidal: 0,
        TOTMA: 0.033214403319699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23661.0,
        MAXELEVSMO: 23981.0,
        MINELEVSMO: 23737.0,
        SLOPE: 0.00186259,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.309999942779541,
        QA_MA: 333.136,
        VA_MA: 1.49761,
        QC_MA: 322.029,
        VC_MA: 1.49435,
        QE_MA: 333.323,
        VE_MA: 1.49767,
        QA_01: 582.594,
        VA_01: 1.90854,
        QC_01: 447.133,
        VC_01: 1.72015,
        QE_01: 450.034,
        VE_01: 1.7032,
        QA_02: 609.003,
        VA_02: 1.94665,
        QC_02: 498.19,
        VC_02: 1.80357,
        QE_02: 494.65,
        VE_02: 1.775,
        QA_03: 655.638,
        VA_03: 2.01211,
        QC_03: 538.943,
        VC_03: 1.86732,
        QE_03: 555.798,
        VE_03: 1.86863,
        QA_04: 481.947,
        VA_04: 1.75524,
        QC_04: 438.28,
        VC_04: 1.70523,
        QE_04: 473.156,
        VE_04: 1.7408,
        QA_05: 357.853,
        VA_05: 1.54368,
        QC_05: 326.143,
        VC_05: 1.50237,
        QE_05: 354.868,
        VE_05: 1.53789,
        QA_06: 205.733,
        VA_06: 1.22909,
        QC_06: 255.176,
        VC_06: 1.35651,
        QE_06: 287.971,
        VE_06: 1.4088,
        QA_07: 121.927,
        VA_07: 1.00577,
        QC_07: 181.307,
        VC_07: 1.18256,
        QE_07: 209.083,
        VE_07: 1.23678,
        QA_08: 84.112,
        VA_08: 0.88099,
        QC_08: 190.674,
        VC_08: 1.20626,
        QE_08: 212.178,
        VE_08: 1.24405,
        QA_09: 76.102,
        VA_09: 0.85135,
        QC_09: 179.243,
        VC_09: 1.17726,
        QE_09: 169.557,
        VE_09: 1.13902,
        QA_10: 105.558,
        VA_10: 0.95438,
        QC_10: 227.402,
        VC_10: 1.2943,
        QE_10: 171.965,
        VE_10: 1.14527,
        QA_11: 282.275,
        VA_11: 1.39747,
        QC_11: 282.738,
        VC_11: 1.41518,
        QE_11: 216.403,
        VE_11: 1.2539,
        QA_12: 437.126,
        VA_12: 1.68216,
        QC_12: 333.971,
        VC_12: 1.5175,
        QE_12: 311.667,
        VE_12: 1.45599,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.285741917601626, 35.580094878106024, 0.0],
          [-81.289419384262487, 35.575356611446637, 0.0],
          [-81.289450317595822, 35.572677011450878, 0.0],
          [-81.292255917591433, 35.570251278121304, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288478.0,
        COMID: 9736790,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.055,
        REACHCODE: "03050103001324",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00049708626512600003,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060080.0,
        ToNode: 250059389.0,
        Hydroseq: 250005489.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.312,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.284,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005502.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005479.0,
        FromMeas: 36.22453,
        ToMeas: 57.92445,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0576,
        TotDASqKM: 9333.8442,
        DivDASqKM: 1.0053,
        Tidal: 0,
        TOTMA: 0.00344574033405,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13052.0,
        MAXELEVSMO: 13072.0,
        MINELEVSMO: 13052.0,
        SLOPE: 0.00363636,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054999999701976998,
        QA_MA: 0.396,
        VA_MA: 0.60611,
        QC_MA: 0.396,
        VC_MA: 0.60611,
        QE_MA: 0.396,
        VE_MA: 0.60611,
        QA_01: 0.865,
        VA_01: 0.75919,
        QC_01: 0.865,
        VC_01: 0.75919,
        QE_01: 0.865,
        VE_01: 0.75919,
        QA_02: 0.905,
        VA_02: 0.77014,
        QC_02: 0.905,
        VC_02: 0.77014,
        QE_02: 0.905,
        VE_02: 0.77014,
        QA_03: 0.973,
        VA_03: 0.78825,
        QC_03: 0.973,
        VC_03: 0.78825,
        QE_03: 0.973,
        VE_03: 0.78825,
        QA_04: 0.567,
        VA_04: 0.66862,
        QC_04: 0.567,
        VC_04: 0.66862,
        QE_04: 0.567,
        VE_04: 0.66862,
        QA_05: 0.31,
        VA_05: 0.56981,
        QC_05: 0.31,
        VC_05: 0.56981,
        QE_05: 0.31,
        VE_05: 0.56981,
        QA_06: 0.202,
        VA_06: 0.51663,
        QC_06: 0.202,
        VC_06: 0.51663,
        QE_06: 0.202,
        VE_06: 0.51663,
        QA_07: 0.148,
        VA_07: 0.48493,
        QC_07: 0.148,
        VC_07: 0.48493,
        QE_07: 0.148,
        VE_07: 0.48493,
        QA_08: 0.108,
        VA_08: 0.45773,
        QC_08: 0.108,
        VC_08: 0.45773,
        QE_08: 0.108,
        VE_08: 0.45773,
        QA_09: 0.081,
        VA_09: 0.43657,
        QC_09: 0.081,
        VC_09: 0.43657,
        QE_09: 0.081,
        VE_09: 0.43657,
        QA_10: 0.067,
        VA_10: 0.42429,
        QC_10: 0.067,
        VC_10: 0.42429,
        QE_10: 0.067,
        VE_10: 0.42429,
        QA_11: 0.067,
        VA_11: 0.42429,
        QC_11: 0.067,
        VC_11: 0.42429,
        QE_11: 0.067,
        VE_11: 0.42429,
        QA_12: 0.458,
        VA_12: 0.63001,
        QC_12: 0.458,
        VC_12: 0.63001,
        QE_12: 0.458,
        VE_12: 0.63001,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877237918235664, 34.775834679354261, 0.0],
          [-80.877294184902269, 34.775342012688441, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286228.0,
        COMID: 9745648,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.402,
        REACHCODE: "03050102000055",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013605357942621001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060324.0,
        ToNode: 250060332.0,
        Hydroseq: 250015297.0,
        LevelPathI: 250011553.0,
        Pathlength: 538.404,
        TerminalPa: 250002604.0,
        ArbolateSu: 553.15,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015461.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015144.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8073,
        TotDASqKM: 577.2312,
        DivDASqKM: 577.2312,
        Tidal: 0,
        TOTMA: 0.064464131194000004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23651.0,
        MAXELEVSMO: 23981.0,
        MINELEVSMO: 23981.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.401999950408936,
        QA_MA: 331.773,
        VA_MA: 0.82581,
        QC_MA: 320.762,
        VC_MA: 0.8244,
        QE_MA: 332.056,
        VE_MA: 0.82585,
        QA_01: 579.964,
        VA_01: 1.00445,
        QC_01: 445.167,
        VC_01: 0.92249,
        QE_01: 448.068,
        VE_01: 0.91509,
        QA_02: 606.255,
        VA_02: 1.02103,
        QC_02: 496.003,
        VC_02: 0.95878,
        QE_02: 492.463,
        VE_02: 0.9463,
        QA_03: 652.655,
        VA_03: 1.04949,
        QC_03: 536.572,
        VC_03: 0.98651,
        QE_03: 553.426,
        VE_03: 0.98708,
        QA_04: 479.948,
        VA_04: 0.93788,
        QC_04: 436.503,
        VC_04: 0.91611,
        QE_04: 471.379,
        VE_04: 0.93165,
        QA_05: 356.508,
        VA_05: 0.84595,
        QC_05: 324.963,
        VC_05: 0.82797,
        QE_05: 353.688,
        VE_05: 0.84348,
        QA_06: 204.948,
        VA_06: 0.70903,
        QC_06: 254.216,
        VC_06: 0.76446,
        QE_06: 287.011,
        VE_06: 0.7873,
        QA_07: 121.456,
        VA_07: 0.61185,
        QC_07: 180.623,
        VC_07: 0.68877,
        QE_07: 208.399,
        VE_07: 0.71245,
        QA_08: 83.758,
        VA_08: 0.55751,
        QC_08: 189.857,
        VC_08: 0.69898,
        QE_08: 211.361,
        VE_08: 0.71549,
        QA_09: 75.827,
        VA_09: 0.54469,
        QC_09: 178.671,
        VC_09: 0.68658,
        QE_09: 168.985,
        VE_09: 0.66988,
        QA_10: 105.322,
        VA_10: 0.58973,
        QC_10: 226.997,
        VC_10: 0.73785,
        QE_10: 171.56,
        VE_10: 0.6728,
        QA_11: 281.51,
        VA_11: 0.78259,
        QC_11: 282.154,
        VC_11: 0.79043,
        QE_11: 215.818,
        VE_11: 0.72002,
        QA_12: 435.342,
        VA_12: 0.90611,
        QC_12: 332.731,
        VC_12: 0.83453,
        QE_12: 310.427,
        VE_12: 0.80767,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.279818384277405, 35.587258878094815, 0.0],
          [-81.283097917605687, 35.587971278093676, 0.0],
          [-81.282063384273897, 35.58545121143095, 0.0],
          [-81.285741917601626, 35.580094878106024, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288471.0,
        COMID: 9736218,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.378,
        REACHCODE: "03050103001320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.003829857530199,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059869.0,
        ToNode: 250059874.0,
        Hydroseq: 250006797.0,
        LevelPathI: 250003258.0,
        Pathlength: 429.274,
        TerminalPa: 250002604.0,
        ArbolateSu: 5950.747,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006827.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006712.0,
        FromMeas: 14.71798,
        ToMeas: 26.38832,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4554,
        TotDASqKM: 7901.2881,
        DivDASqKM: 7901.2881,
        Tidal: 0,
        TOTMA: 0.0114959991871,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14726.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14792.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37799999117851302,
        QA_MA: 4101.207,
        VA_MA: 1.24452,
        QC_MA: 4101.207,
        VC_MA: 1.24452,
        QE_MA: 4326.652,
        VE_MA: 1.24858,
        QA_01: 7294.272,
        VA_01: 1.57932,
        QC_01: 7294.272,
        VC_01: 1.57932,
        QE_01: 5873.262,
        VE_01: 1.41424,
        QA_02: 7719.521,
        VA_02: 1.61814,
        QC_02: 7719.521,
        VC_02: 1.61814,
        QE_02: 6047.314,
        VE_02: 1.43152,
        QA_03: 8514.239,
        VA_03: 1.68809,
        QC_03: 8514.239,
        VC_03: 1.68809,
        QE_03: 6144.929,
        VE_03: 1.44111,
        QA_04: 6041.172,
        VA_04: 1.45827,
        QC_04: 6041.172,
        VC_04: 1.45827,
        QE_04: 5357.769,
        VE_04: 1.36159,
        QA_05: 4251.601,
        VA_05: 1.26259,
        QC_05: 4251.601,
        VC_05: 1.26259,
        QE_05: 4470.703,
        VE_05: 1.26508,
        QA_06: 2490.492,
        VA_06: 1.02669,
        QC_06: 2490.492,
        VC_06: 1.02669,
        QE_06: 4167.412,
        VE_06: 1.23005,
        QA_07: 1515.993,
        VA_07: 0.86025,
        QC_07: 1515.993,
        VC_07: 0.86025,
        QE_07: 3141.83,
        VE_07: 1.10167,
        QA_08: 1073.198,
        VA_08: 0.76776,
        QC_08: 1073.198,
        VC_08: 0.76776,
        QE_08: 3078.06,
        VE_08: 1.09308,
        QA_09: 992.068,
        VA_09: 0.74899,
        QC_09: 992.068,
        VC_09: 0.74899,
        QE_09: 2571.296,
        VE_09: 1.02159,
        QA_10: 1117.317,
        VA_10: 0.7777,
        QC_10: 1117.317,
        VC_10: 0.7777,
        QE_10: 3399.618,
        VE_10: 1.13559,
        QA_11: 2987.54,
        VA_11: 1.09956,
        QC_11: 2987.54,
        VC_11: 1.09956,
        QE_11: 3609.078,
        VE_11: 1.16227,
        QA_12: 5245.035,
        VA_12: 1.37514,
        QC_12: 5245.035,
        VC_12: 1.37514,
        QE_12: 4405.313,
        VE_12: 1.25762,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.959924984773977, 34.963192879063513, 0.0],
          [-80.957104384778347, 34.961335012399786, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286229.0,
        COMID: 9745598,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 1.43,
        REACHCODE: "03050102000056",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.013811013639302001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060305.0,
        ToNode: 250060324.0,
        Hydroseq: 250015461.0,
        LevelPathI: 250011553.0,
        Pathlength: 539.806,
        TerminalPa: 250002604.0,
        ArbolateSu: 549.486,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015618.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015297.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6516,
        TotDASqKM: 573.2172,
        DivDASqKM: 573.2172,
        Tidal: 0,
        TOTMA: 0.065812139067300005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23750.0,
        MAXELEVSMO: 23981.0,
        MINELEVSMO: 23981.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.429999947547913,
        QA_MA: 329.904,
        VA_MA: 0.82504,
        QC_MA: 319.026,
        VC_MA: 0.82364,
        QE_MA: 330.319,
        VE_MA: 0.82509,
        QA_01: 576.374,
        VA_01: 1.0032,
        QC_01: 442.483,
        VC_01: 0.92138,
        QE_01: 445.384,
        VE_01: 0.91396,
        QA_02: 602.477,
        VA_02: 1.01974,
        QC_02: 492.996,
        VC_02: 0.9576,
        QE_02: 489.456,
        VE_02: 0.94507,
        QA_03: 648.579,
        VA_03: 1.04814,
        QC_03: 533.331,
        VC_03: 0.98528,
        QE_03: 550.186,
        VE_03: 0.98587,
        QA_04: 477.196,
        VA_04: 0.93691,
        QC_04: 434.057,
        VC_04: 0.91515,
        QE_04: 468.933,
        VE_04: 0.93075,
        QA_05: 354.663,
        VA_05: 0.84528,
        QC_05: 323.344,
        VC_05: 0.82733,
        QE_05: 352.069,
        VE_05: 0.84288,
        QA_06: 203.868,
        VA_06: 0.70851,
        QC_06: 252.895,
        VC_06: 0.76384,
        QE_06: 285.691,
        VE_06: 0.78676,
        QA_07: 120.809,
        VA_07: 0.61145,
        QC_07: 179.683,
        VC_07: 0.68825,
        QE_07: 207.459,
        VE_07: 0.71201,
        QA_08: 83.273,
        VA_08: 0.55711,
        QC_08: 188.734,
        VC_08: 0.69829,
        QE_08: 210.238,
        VE_08: 0.71487,
        QA_09: 75.45,
        VA_09: 0.54442,
        QC_09: 177.885,
        VC_09: 0.68623,
        QE_09: 168.199,
        VE_09: 0.66946,
        QA_10: 104.998,
        VA_10: 0.58969,
        QC_10: 226.439,
        VC_10: 0.73789,
        QE_10: 171.002,
        VE_10: 0.67265,
        QA_11: 280.484,
        VA_11: 0.78238,
        QC_11: 281.369,
        VC_11: 0.79039,
        QE_11: 215.034,
        VE_11: 0.71977,
        QA_12: 432.902,
        VA_12: 0.90522,
        QC_12: 331.034,
        VC_12: 0.83384,
        QE_12: 308.73,
        VE_12: 0.80687,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.285305917602216, 35.594362678083769, 0.0],
          [-81.28533898426889, 35.589622011424524, 0.0],
          [-81.282337717606822, 35.590810611422683, 0.0],
          [-81.279815584277458, 35.589663478091097, 0.0],
          [-81.279818384277405, 35.587258878094815, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288472.0,
        COMID: 9736222,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.474,
        REACHCODE: "03050103001320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0051385294959000001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059874.0,
        ToNode: 250059875.0,
        Hydroseq: 250006712.0,
        LevelPathI: 250003258.0,
        Pathlength: 428.8,
        TerminalPa: 250002604.0,
        ArbolateSu: 5951.641,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006797.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006687.0,
        FromMeas: 0.0,
        ToMeas: 14.71798,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4581,
        TotDASqKM: 7901.8263,
        DivDASqKM: 7901.8263,
        Tidal: 0,
        TOTMA: 0.0063474393929899998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14391.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14554.0,
        SLOPE: 0.00502109,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.47400000691413902,
        QA_MA: 4101.433,
        VA_MA: 2.82471,
        QC_MA: 4101.433,
        VC_MA: 2.82471,
        QE_MA: 4326.879,
        VE_MA: 2.83564,
        QA_01: 7294.752,
        VA_01: 3.72469,
        QC_01: 7294.752,
        VC_01: 3.72469,
        QE_01: 5873.739,
        VE_01: 3.28097,
        QA_02: 7720.027,
        VA_02: 3.82904,
        QC_02: 7720.027,
        VC_02: 3.82904,
        QE_02: 6047.817,
        VE_02: 3.32743,
        QA_03: 8514.782,
        VA_03: 4.01707,
        QC_03: 8514.782,
        VC_03: 4.01707,
        QE_03: 6145.468,
        VE_03: 3.35322,
        QA_04: 6041.498,
        VA_04: 3.39929,
        QC_04: 6041.498,
        VC_04: 3.39929,
        QE_04: 5358.094,
        VE_04: 3.13942,
        QA_05: 4251.782,
        VA_05: 2.87328,
        QC_05: 4251.782,
        VC_05: 2.87328,
        QE_05: 4470.886,
        VE_05: 2.87996,
        QA_06: 2490.601,
        VA_06: 2.23918,
        QC_06: 2490.601,
        VC_06: 2.23918,
        QE_06: 4167.524,
        VE_06: 2.78578,
        QA_07: 1516.072,
        VA_07: 1.7918,
        QC_07: 1516.072,
        VC_07: 1.7918,
        QE_07: 3141.913,
        VE_07: 2.4407,
        QA_08: 1073.256,
        VA_08: 1.5432,
        QC_08: 1073.256,
        VC_08: 1.5432,
        QE_08: 3078.123,
        VE_08: 2.4176,
        QA_09: 992.119,
        VA_09: 1.49272,
        QC_09: 992.119,
        VC_09: 1.49272,
        QE_09: 2571.351,
        VE_09: 2.22545,
        QA_10: 1117.353,
        VA_10: 1.56989,
        QC_10: 1117.353,
        VC_10: 1.56989,
        QE_10: 3399.66,
        VE_10: 2.53186,
        QA_11: 2987.605,
        VA_11: 2.43503,
        QC_11: 2987.605,
        VC_11: 2.43503,
        QE_11: 3609.145,
        VE_11: 2.6036,
        QA_12: 5245.31,
        VA_12: 3.17584,
        QC_12: 5245.31,
        VC_12: 3.17584,
        QE_12: 4405.588,
        VE_12: 2.85995,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.957104384778347, 34.961335012399786, 0.0],
          [-80.952164718119377, 34.960017812401816, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286230.0,
        COMID: 9745538,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.356,
        REACHCODE: "03050102000057",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.003261667348297,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060283.0,
        ToNode: 250060305.0,
        Hydroseq: 250015618.0,
        LevelPathI: 250011553.0,
        Pathlength: 541.236,
        TerminalPa: 250002604.0,
        ArbolateSu: 546.267,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015779.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250015461.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1251,
        TotDASqKM: 571.7799,
        DivDASqKM: 571.7799,
        Tidal: 0,
        TOTMA: 0.016389365147900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23856.0,
        MAXELEVSMO: 23981.0,
        MINELEVSMO: 23981.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.356000006198883,
        QA_MA: 329.234,
        VA_MA: 0.82476,
        QC_MA: 318.403,
        VC_MA: 0.82336,
        QE_MA: 329.696,
        VE_MA: 0.82482,
        QA_01: 575.09,
        VA_01: 1.00275,
        QC_01: 441.524,
        VC_01: 0.92098,
        QE_01: 444.425,
        VE_01: 0.91355,
        QA_02: 601.125,
        VA_02: 1.01927,
        QC_02: 491.92,
        VC_02: 0.95717,
        QE_02: 488.379,
        VE_02: 0.94463,
        QA_03: 647.114,
        VA_03: 1.04765,
        QC_03: 532.167,
        VC_03: 0.98484,
        QE_03: 549.021,
        VE_03: 0.98543,
        QA_04: 476.21,
        VA_04: 0.93656,
        QC_04: 433.181,
        VC_04: 0.91481,
        QE_04: 468.057,
        VE_04: 0.93043,
        QA_05: 353.998,
        VA_05: 0.84503,
        QC_05: 322.761,
        VC_05: 0.82709,
        QE_05: 351.486,
        VE_05: 0.84267,
        QA_06: 203.482,
        VA_06: 0.70833,
        QC_06: 252.423,
        VC_06: 0.76362,
        QE_06: 285.218,
        VE_06: 0.78657,
        QA_07: 120.577,
        VA_07: 0.6113,
        QC_07: 179.346,
        VC_07: 0.68807,
        QE_07: 207.122,
        VE_07: 0.71186,
        QA_08: 83.099,
        VA_08: 0.55697,
        QC_08: 188.332,
        VC_08: 0.69805,
        QE_08: 209.836,
        VE_08: 0.71465,
        QA_09: 75.314,
        VA_09: 0.54432,
        QC_09: 177.604,
        VC_09: 0.6861,
        QE_09: 167.918,
        VE_09: 0.66932,
        QA_10: 104.882,
        VA_10: 0.58968,
        QC_10: 226.239,
        VC_10: 0.7379,
        QE_10: 170.802,
        VE_10: 0.67259,
        QA_11: 280.11,
        VA_11: 0.7823,
        QC_11: 281.083,
        VC_11: 0.79037,
        QE_11: 214.747,
        VE_11: 0.71968,
        QA_12: 432.028,
        VA_12: 0.90491,
        QC_12: 330.426,
        VC_12: 0.8336,
        QE_12: 308.122,
        VE_12: 0.80659,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.284124917604061, 35.597385011412428, 0.0],
          [-81.285305917602216, 35.594362678083769, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288473.0,
        COMID: 9734272,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.135,
        REACHCODE: "03050103001321",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001252357393039,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059331.0,
        ToNode: 250094181.0,
        Hydroseq: 250005790.0,
        LevelPathI: 250005769.0,
        Pathlength: 400.82,
        TerminalPa: 250002604.0,
        ArbolateSu: 6816.304,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005769.0,
        UpHydroseq: 250005836.0,
        DnLevelPat: 250005769.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005769.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0342,
        TotDASqKM: 9187.1523,
        DivDASqKM: 0.0558,
        Tidal: 0,
        TOTMA: 0.0139150724856,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13628.0,
        MAXELEVSMO: 13649.0,
        MINELEVSMO: 13649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.135000005364418,
        QA_MA: 0.022,
        VA_MA: 0.3684,
        QC_MA: 0.022,
        VC_MA: 0.3684,
        QE_MA: 0.022,
        VE_MA: 0.3684,
        QA_01: 0.048,
        VA_01: 0.3992,
        QC_01: 0.048,
        VC_01: 0.3992,
        QE_01: 0.048,
        VE_01: 0.3992,
        QA_02: 0.05,
        VA_02: 0.40119,
        QC_02: 0.05,
        VC_02: 0.40119,
        QE_02: 0.05,
        VE_02: 0.40119,
        QA_03: 0.054,
        VA_03: 0.40506,
        QC_03: 0.054,
        VC_03: 0.40506,
        QE_03: 0.054,
        VE_03: 0.40506,
        QA_04: 0.031,
        VA_04: 0.38038,
        QC_04: 0.031,
        VC_04: 0.38038,
        QE_04: 0.031,
        VE_04: 0.38038,
        QA_05: 0.017,
        VA_05: 0.36072,
        QC_05: 0.017,
        VC_05: 0.36072,
        QE_05: 0.017,
        VE_05: 0.36072,
        QA_06: 0.011,
        VA_06: 0.34992,
        QC_06: 0.011,
        VC_06: 0.34992,
        QE_06: 0.011,
        VE_06: 0.34992,
        QA_07: 0.008,
        VA_07: 0.34346,
        QC_07: 0.008,
        VC_07: 0.34346,
        QE_07: 0.008,
        VE_07: 0.34346,
        QA_08: 0.006,
        VA_08: 0.33849,
        QC_08: 0.006,
        VC_08: 0.33849,
        QE_08: 0.006,
        VE_08: 0.33849,
        QA_09: 0.004,
        VA_09: 0.33266,
        QC_09: 0.004,
        VC_09: 0.33266,
        QE_09: 0.004,
        VE_09: 0.33266,
        QA_10: 0.003,
        VA_10: 0.32922,
        QC_10: 0.003,
        VC_10: 0.32922,
        QE_10: 0.003,
        VE_10: 0.32922,
        QA_11: 0.003,
        VA_11: 0.32922,
        QC_11: 0.003,
        VC_11: 0.32922,
        QE_11: 0.003,
        VE_11: 0.32922,
        QA_12: 0.025,
        VA_12: 0.37261,
        QC_12: 0.025,
        VC_12: 0.37261,
        QE_12: 0.025,
        VE_12: 0.37261,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883653718225673, 34.796667212655393, 0.0],
          [-80.8830981848932, 34.795544812656999, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286223.0,
        COMID: 9745788,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.123,
        REACHCODE: "03050102000050",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.020197107511831999,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060355.0,
        ToNode: 250060358.0,
        Hydroseq: 250014568.0,
        LevelPathI: 250011553.0,
        Pathlength: 528.012,
        TerminalPa: 250002604.0,
        ArbolateSu: 573.569,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014705.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014428.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.0485,
        TotDASqKM: 596.412,
        DivDASqKM: 596.412,
        Tidal: 0,
        TOTMA: 0.059438620869600001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23274.0,
        MAXELEVSMO: 23461.0,
        MINELEVSMO: 23289.0,
        SLOPE: 0.00081017,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.122999906539917,
        QA_MA: 340.711,
        VA_MA: 1.35638,
        QC_MA: 329.064,
        VC_MA: 1.35343,
        QE_MA: 340.357,
        VE_MA: 1.35629,
        QA_01: 597.242,
        VA_01: 1.72026,
        QC_01: 458.077,
        VC_01: 1.55409,
        QE_01: 460.978,
        VE_01: 1.53944,
        QA_02: 624.298,
        VA_02: 1.75387,
        QC_02: 510.357,
        VC_02: 1.62767,
        QE_02: 506.817,
        VE_02: 1.60299,
        QA_03: 672.196,
        VA_03: 1.81173,
        QC_03: 552.1,
        VC_03: 1.68391,
        QE_03: 568.954,
        VE_03: 1.68499,
        QA_04: 492.949,
        VA_04: 1.58346,
        QC_04: 448.055,
        VC_04: 1.53954,
        QE_04: 482.93,
        VE_04: 1.57023,
        QA_05: 365.361,
        VA_05: 1.39598,
        QC_05: 332.728,
        VC_05: 1.35959,
        QE_05: 361.453,
        VE_05: 1.39029,
        QA_06: 210.047,
        VA_06: 1.11899,
        QC_06: 260.453,
        VC_06: 1.2314,
        QE_06: 293.248,
        VE_06: 1.27659,
        QA_07: 124.551,
        VA_07: 0.92256,
        QC_07: 185.114,
        VC_07: 1.07835,
        QE_07: 212.89,
        VE_07: 1.12518,
        QA_08: 86.031,
        VA_08: 0.813,
        QC_08: 195.114,
        VC_08: 1.10016,
        QE_08: 216.618,
        VE_08: 1.13275,
        QA_09: 77.633,
        VA_09: 0.78622,
        QC_09: 182.422,
        VC_09: 1.07238,
        QE_09: 172.736,
        VE_09: 1.03938,
        QA_10: 106.87,
        VA_10: 0.8746,
        QC_10: 229.657,
        VC_10: 1.17175,
        QE_10: 174.22,
        VE_10: 1.04271,
        QA_11: 286.514,
        VA_11: 1.26427,
        QC_11: 285.97,
        VC_11: 1.27837,
        QE_11: 219.635,
        VE_11: 1.13882,
        QA_12: 447.122,
        VA_12: 1.51908,
        QC_12: 340.913,
        VC_12: 1.37324,
        QE_12: 318.609,
        VE_12: 1.32018,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.288194384264443, 35.540887411500194, 0.0],
          [-81.291193117593139, 35.540454278167431, 0.0],
          [-81.293467584256291, 35.535623478175069, 0.0],
          [-81.289359117595893, 35.525749611523679, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286224.0,
        COMID: 9745756,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 3.071,
        REACHCODE: "03050102000051",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.031359455587114997,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060347.0,
        ToNode: 250060355.0,
        Hydroseq: 250014705.0,
        LevelPathI: 250011553.0,
        Pathlength: 530.135,
        TerminalPa: 250002604.0,
        ArbolateSu: 569.112,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014853.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014568.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.5821,
        TotDASqKM: 592.3611,
        DivDASqKM: 592.3611,
        Tidal: 0,
        TOTMA: 0.091251639333500001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23374.0,
        MAXELEVSMO: 23615.0,
        MINELEVSMO: 23461.0,
        SLOPE: 0.00050146,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.0710000991821289,
        QA_MA: 338.833,
        VA_MA: 1.27799,
        QC_MA: 327.32,
        VC_MA: 1.27528,
        QE_MA: 338.614,
        VE_MA: 1.27794,
        QA_01: 593.593,
        VA_01: 1.61424,
        QC_01: 455.351,
        VC_01: 1.46054,
        QE_01: 458.252,
        VE_01: 1.44692,
        QA_02: 620.496,
        VA_02: 1.64534,
        QC_02: 507.333,
        VC_02: 1.52861,
        QE_02: 503.793,
        VE_02: 1.50567,
        QA_03: 668.086,
        VA_03: 1.69885,
        QC_03: 548.835,
        VC_03: 1.58064,
        QE_03: 565.689,
        VE_03: 1.58165,
        QA_04: 490.23,
        VA_04: 1.4881,
        QC_04: 445.64,
        VC_04: 1.44743,
        QE_04: 480.516,
        VE_04: 1.47597,
        QA_05: 363.505,
        VA_05: 1.31487,
        QC_05: 331.1,
        VC_05: 1.2812,
        QE_05: 359.825,
        VE_05: 1.30973,
        QA_06: 208.988,
        VA_06: 1.05856,
        QC_06: 259.158,
        VC_06: 1.16253,
        QE_06: 291.953,
        VE_06: 1.20454,
        QA_07: 123.897,
        VA_07: 0.8767,
        QC_07: 184.166,
        VC_07: 1.02084,
        QE_07: 211.942,
        VE_07: 1.06439,
        QA_08: 85.571,
        VA_08: 0.7753,
        QC_08: 194.05,
        VC_08: 1.0409,
        QE_08: 215.554,
        VE_08: 1.0712,
        QA_09: 77.251,
        VA_09: 0.75062,
        QC_09: 181.63,
        VC_09: 1.01562,
        QE_09: 171.944,
        VE_09: 0.98492,
        QA_10: 106.543,
        VA_10: 0.83294,
        QC_10: 229.096,
        VC_10: 1.1084,
        QE_10: 173.659,
        VE_10: 0.98849,
        QA_11: 285.484,
        VA_11: 1.19368,
        QC_11: 285.186,
        VC_11: 1.20706,
        QE_11: 218.851,
        VE_11: 1.07738,
        QA_12: 444.647,
        VA_12: 1.42852,
        QC_12: 339.195,
        VC_12: 1.29375,
        QE_12: 316.891,
        VE_12: 1.2444,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.292582984257649, 35.551563078150309, 0.0],
          [-81.298832717581206, 35.551246478150752, 0.0],
          [-81.299397584247004, 35.546506011491317, 0.0],
          [-81.294241784255064, 35.546159211491954, 0.0],
          [-81.296178784251993, 35.54256467816424, 0.0],
          [-81.290265784261237, 35.543064611496845, 0.0],
          [-81.288194384264443, 35.540887411500194, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288483.0,
        COMID: 9736540,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.17,
        REACHCODE: "03050103001806",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0017549388211719999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060019.0,
        ToNode: 250060023.0,
        Hydroseq: 250005208.0,
        LevelPathI: 250003258.0,
        Pathlength: 387.945,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.707,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005217.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005199.0,
        DnDrainCou: 2,
        DnHydroseq: 250005198.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1323,
        TotDASqKM: 9815.2083,
        DivDASqKM: 9815.2083,
        Tidal: 0,
        TOTMA: 0.0049676077148599999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12641.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.17000000178813901,
        QA_MA: 4868.038,
        VA_MA: 1.28873,
        QC_MA: 4868.038,
        VC_MA: 1.28873,
        QE_MA: 5570.071,
        VE_MA: 1.29949,
        QA_01: 8950.315,
        VA_01: 1.66301,
        QC_01: 8950.315,
        VC_01: 1.66301,
        QE_01: 7514.062,
        VE_01: 1.47025,
        QA_02: 9455.995,
        VA_02: 1.70312,
        QC_02: 9455.995,
        VC_02: 1.70312,
        QE_02: 7643.943,
        VE_02: 1.48087,
        QA_03: 10376.712,
        VA_03: 1.77366,
        QC_03: 10376.712,
        VC_03: 1.77366,
        QE_03: 8139.665,
        VE_03: 1.52065,
        QA_04: 7129.863,
        VA_04: 1.50893,
        QC_04: 7129.863,
        VC_04: 1.50893,
        QE_04: 6908.307,
        VE_04: 1.41953,
        QA_05: 4860.861,
        VA_05: 1.28796,
        QC_05: 4860.861,
        VC_05: 1.28796,
        QE_05: 5846.473,
        VE_05: 1.3253,
        QA_06: 2871.484,
        VA_06: 1.0491,
        QC_06: 2871.484,
        VC_06: 1.0491,
        QE_06: 5111.279,
        VE_06: 1.25527,
        QA_07: 1790.784,
        VA_07: 0.88483,
        QC_07: 1790.784,
        VC_07: 0.88483,
        QE_07: 3883.151,
        VE_07: 1.12671,
        QA_08: 1275.018,
        VA_08: 0.78969,
        QC_08: 1275.018,
        VC_08: 0.78969,
        QE_08: 3613.897,
        VE_08: 1.09607,
        QA_09: 1159.178,
        VA_09: 0.76596,
        QC_09: 1159.178,
        VC_09: 0.76596,
        QE_09: 3304.415,
        VE_09: 1.0595,
        QA_10: 1246.428,
        VA_10: 0.78393,
        QC_10: 1246.428,
        VC_10: 0.78393,
        QE_10: 4491.135,
        VE_10: 1.19242,
        QA_11: 3176.407,
        VA_11: 1.08987,
        QC_11: 3176.407,
        VC_11: 1.08987,
        QE_11: 4553.635,
        VE_11: 1.19893,
        QA_12: 6156.736,
        VA_12: 1.41894,
        QC_12: 6156.736,
        VC_12: 1.41894,
        QE_12: 5865.025,
        VE_12: 1.32702,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.863865918256408, 34.692772679483198, 0.0],
          [-80.865378984920767, 34.691883612817946, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286225.0,
        COMID: 9745736,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 3.373,
        REACHCODE: "03050102000052",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.034190821963074998,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060343.0,
        ToNode: 250060347.0,
        Hydroseq: 250014853.0,
        LevelPathI: 250011553.0,
        Pathlength: 533.206,
        TerminalPa: 250002604.0,
        ArbolateSu: 564.226,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015003.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014705.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 4.1247,
        TotDASqKM: 588.9348,
        DivDASqKM: 588.9348,
        Tidal: 0,
        TOTMA: 0.10527766793,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23465.0,
        MAXELEVSMO: 23728.0,
        MINELEVSMO: 23615.0,
        SLOPE: 0.00033501,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 3.372999906539917,
        QA_MA: 337.232,
        VA_MA: 1.21663,
        QC_MA: 325.834,
        VC_MA: 1.2141,
        QE_MA: 337.127,
        VE_MA: 1.21661,
        QA_01: 590.504,
        VA_01: 1.53128,
        QC_01: 453.044,
        VC_01: 1.38734,
        QE_01: 455.945,
        VE_01: 1.37453,
        QA_02: 617.271,
        VA_02: 1.56041,
        QC_02: 504.768,
        VC_02: 1.45109,
        QE_02: 501.228,
        VE_02: 1.4295,
        QA_03: 664.589,
        VA_03: 1.61049,
        QC_03: 546.057,
        VC_03: 1.4998,
        QE_03: 562.911,
        VE_03: 1.50077,
        QA_04: 487.915,
        VA_04: 1.41344,
        QC_04: 443.583,
        VC_04: 1.37531,
        QE_04: 478.459,
        VE_04: 1.40216,
        QA_05: 361.915,
        VA_05: 1.25134,
        QC_05: 329.706,
        VC_05: 1.2198,
        QE_05: 358.431,
        VE_05: 1.24664,
        QA_06: 208.071,
        VA_06: 1.0112,
        QC_06: 258.036,
        VC_06: 1.10858,
        QE_06: 290.831,
        VE_06: 1.14809,
        QA_07: 123.345,
        VA_07: 0.84081,
        QC_07: 183.364,
        VC_07: 0.97582,
        QE_07: 211.14,
        VE_07: 1.01679,
        QA_08: 85.161,
        VA_08: 0.74574,
        QC_08: 193.101,
        VC_08: 0.99441,
        QE_08: 214.606,
        VE_08: 1.02294,
        QA_09: 76.929,
        VA_09: 0.72275,
        QC_09: 180.961,
        VC_09: 0.97116,
        QE_09: 171.275,
        VE_09: 0.94229,
        QA_10: 106.266,
        VA_10: 0.8003,
        QC_10: 228.621,
        VC_10: 1.05877,
        QE_10: 173.184,
        VE_10: 0.94603,
        QA_11: 284.577,
        VA_11: 1.13834,
        QC_11: 284.494,
        VC_11: 1.15115,
        QE_11: 218.159,
        VE_11: 1.0292,
        QA_12: 442.521,
        VA_12: 1.3576,
        QC_12: 337.719,
        VC_12: 1.2315,
        QE_12: 315.415,
        VE_12: 1.18506,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.28889511759661, 35.567409011459063, 0.0],
          [-81.292879584257207, 35.563220678132154, 0.0],
          [-81.29133911759277, 35.562143211467117, 0.0],
          [-81.292379517591257, 35.558891811472165, 0.0],
          [-81.291540584259224, 35.556875678142035, 0.0],
          [-81.284284184270518, 35.554694811478782, 0.0],
          [-81.284230184270598, 35.552839678148246, 0.0],
          [-81.286054184267755, 35.550688211484953, 0.0],
          [-81.292582984257649, 35.551563078150309, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288484.0,
        COMID: 9736532,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.505,
        REACHCODE: "03050103001807",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0050848615358299997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060018.0,
        ToNode: 250060019.0,
        Hydroseq: 250005217.0,
        LevelPathI: 250003258.0,
        Pathlength: 388.115,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.537,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005225.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005209.0,
        DnDrainCou: 2,
        DnHydroseq: 250005208.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5391,
        TotDASqKM: 9815.076,
        DivDASqKM: 9815.076,
        Tidal: 0,
        TOTMA: 0.0147568302994,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12634.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.50499999523162797,
        QA_MA: 4867.988,
        VA_MA: 1.28873,
        QC_MA: 4867.988,
        VC_MA: 1.28873,
        QE_MA: 5570.021,
        VE_MA: 1.29948,
        QA_01: 8950.204,
        VA_01: 1.663,
        QC_01: 8950.204,
        VC_01: 1.663,
        QE_01: 7513.952,
        VE_01: 1.47024,
        QA_02: 9455.879,
        VA_02: 1.70312,
        QC_02: 9455.879,
        VC_02: 1.70312,
        QE_02: 7643.827,
        VE_02: 1.48086,
        QA_03: 10376.587,
        VA_03: 1.77366,
        QC_03: 10376.587,
        VC_03: 1.77366,
        QE_03: 8139.54,
        VE_03: 1.52064,
        QA_04: 7129.792,
        VA_04: 1.50893,
        QC_04: 7129.792,
        VC_04: 1.50893,
        QE_04: 6908.236,
        VE_04: 1.41953,
        QA_05: 4860.822,
        VA_05: 1.28796,
        QC_05: 4860.822,
        VC_05: 1.28796,
        QE_05: 5846.434,
        VE_05: 1.3253,
        QA_06: 2871.46,
        VA_06: 1.0491,
        QC_06: 2871.46,
        VC_06: 1.0491,
        QE_06: 5111.254,
        VE_06: 1.25527,
        QA_07: 1790.766,
        VA_07: 0.88483,
        QC_07: 1790.766,
        VC_07: 0.88483,
        QE_07: 3883.133,
        VE_07: 1.12671,
        QA_08: 1275.004,
        VA_08: 0.78969,
        QC_08: 1275.004,
        VC_08: 0.78969,
        QE_08: 3613.883,
        VE_08: 1.09607,
        QA_09: 1159.167,
        VA_09: 0.76595,
        QC_09: 1159.167,
        VC_09: 0.76595,
        QE_09: 3304.404,
        VE_09: 1.0595,
        QA_10: 1246.419,
        VA_10: 0.78393,
        QC_10: 1246.419,
        VC_10: 0.78393,
        QE_10: 4491.125,
        VE_10: 1.19242,
        QA_11: 3176.4,
        VA_11: 1.08988,
        QC_11: 3176.4,
        VC_11: 1.08988,
        QE_11: 4553.628,
        VE_11: 1.19893,
        QA_12: 6156.683,
        VA_12: 1.41893,
        QC_12: 6156.683,
        VC_12: 1.41893,
        QE_12: 5864.972,
        VE_12: 1.32701,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.859925784929089, 34.695789879478639, 0.0],
          [-80.863865918256408, 34.692772679483198, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286226.0,
        COMID: 9745694,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 0.515,
        REACHCODE: "03050102000053",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0051758157564549996,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060334.0,
        ToNode: 250060343.0,
        Hydroseq: 250015003.0,
        LevelPathI: 250011553.0,
        Pathlength: 536.579,
        TerminalPa: 250002604.0,
        ArbolateSu: 558.932,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250015144.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250014853.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.558,
        TotDASqKM: 582.3351,
        DivDASqKM: 582.3351,
        Tidal: 0,
        TOTMA: 0.017377026921500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 23652.0,
        MAXELEVSMO: 23737.0,
        MINELEVSMO: 23728.0,
        SLOPE: 0.00017475,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.51499998569488503,
        QA_MA: 334.159,
        VA_MA: 1.12537,
        QC_MA: 322.979,
        VC_MA: 1.12312,
        QE_MA: 334.273,
        VE_MA: 1.12539,
        QA_01: 584.559,
        VA_01: 1.40784,
        QC_01: 448.601,
        VC_01: 1.27841,
        QE_01: 451.502,
        VE_01: 1.26679,
        QA_02: 611.059,
        VA_02: 1.43403,
        QC_02: 499.826,
        VC_02: 1.33573,
        QE_02: 496.286,
        VE_02: 1.31615,
        QA_03: 657.867,
        VA_03: 1.47903,
        QC_03: 540.715,
        VC_03: 1.37953,
        QE_03: 557.569,
        VE_03: 1.38042,
        QA_04: 483.438,
        VA_04: 1.30237,
        QC_04: 439.604,
        VC_04: 1.26803,
        QE_04: 474.48,
        VE_04: 1.29239,
        QA_05: 358.87,
        VA_05: 1.15691,
        QC_05: 327.035,
        VC_05: 1.12854,
        QE_05: 355.76,
        VE_05: 1.15287,
        QA_06: 206.32,
        VA_06: 0.94082,
        QC_06: 255.895,
        VC_06: 1.02837,
        QE_06: 288.69,
        VE_06: 1.0642,
        QA_07: 122.28,
        VA_07: 0.78743,
        QC_07: 181.818,
        VC_07: 0.90888,
        QE_07: 209.594,
        VE_07: 0.94603,
        QA_08: 84.376,
        VA_08: 0.70177,
        QC_08: 191.285,
        VC_08: 0.92529,
        QE_08: 212.789,
        VE_08: 0.95117,
        QA_09: 76.308,
        VA_09: 0.68133,
        QC_09: 179.67,
        VC_09: 0.9051,
        QE_09: 169.984,
        VE_09: 0.87891,
        QA_10: 105.734,
        VA_10: 0.75184,
        QC_10: 227.705,
        VC_10: 0.9851,
        QE_10: 172.268,
        VE_10: 0.88297,
        QA_11: 282.858,
        VA_11: 1.05617,
        QC_11: 283.183,
        VC_11: 1.06817,
        QE_11: 216.848,
        VE_11: 0.95765,
        QA_12: 438.465,
        VA_12: 1.25214,
        QC_12: 334.902,
        VC_12: 1.13895,
        QE_12: 312.598,
        VE_12: 1.09682,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.292255917591433, 35.570251278121304, 0.0],
          [-81.28889511759661, 35.567409011459063, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288485.0,
        COMID: 9736544,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.18,
        REACHCODE: "03050103001808",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0018647931520519999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060023.0,
        ToNode: 250060026.0,
        Hydroseq: 250005198.0,
        LevelPathI: 250003258.0,
        Pathlength: 387.765,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.887,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005208.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005151.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.027,
        TotDASqKM: 9815.2353,
        DivDASqKM: 9815.2353,
        Tidal: 0,
        TOTMA: 0.00525982008706,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12647.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18000000715255701,
        QA_MA: 4868.048,
        VA_MA: 1.28873,
        QC_MA: 4868.048,
        VC_MA: 1.28873,
        QE_MA: 5570.081,
        VE_MA: 1.29949,
        QA_01: 8950.337,
        VA_01: 1.66301,
        QC_01: 8950.337,
        VC_01: 1.66301,
        QE_01: 7514.084,
        VE_01: 1.47025,
        QA_02: 9456.019,
        VA_02: 1.70313,
        QC_02: 9456.019,
        VC_02: 1.70313,
        QE_02: 7643.967,
        VE_02: 1.48087,
        QA_03: 10376.738,
        VA_03: 1.77366,
        QC_03: 10376.738,
        VC_03: 1.77366,
        QE_03: 8139.69,
        VE_03: 1.52065,
        QA_04: 7129.878,
        VA_04: 1.50893,
        QC_04: 7129.878,
        VC_04: 1.50893,
        QE_04: 6908.322,
        VE_04: 1.41953,
        QA_05: 4860.869,
        VA_05: 1.28796,
        QC_05: 4860.869,
        VC_05: 1.28796,
        QE_05: 5846.481,
        VE_05: 1.3253,
        QA_06: 2871.49,
        VA_06: 1.0491,
        QC_06: 2871.49,
        VC_06: 1.0491,
        QE_06: 5111.284,
        VE_06: 1.25527,
        QA_07: 1790.788,
        VA_07: 0.88483,
        QC_07: 1790.788,
        VC_07: 0.88483,
        QE_07: 3883.154,
        VE_07: 1.12671,
        QA_08: 1275.021,
        VA_08: 0.78969,
        QC_08: 1275.021,
        VC_08: 0.78969,
        QE_08: 3613.9,
        VE_08: 1.09607,
        QA_09: 1159.18,
        VA_09: 0.76596,
        QC_09: 1159.18,
        VC_09: 0.76596,
        QE_09: 3304.418,
        VE_09: 1.0595,
        QA_10: 1246.43,
        VA_10: 0.78393,
        QC_10: 1246.43,
        VC_10: 0.78393,
        QE_10: 4491.137,
        VE_10: 1.19242,
        QA_11: 3176.409,
        VA_11: 1.08987,
        QC_11: 3176.409,
        VC_11: 1.08987,
        QE_11: 4553.636,
        VE_11: 1.19893,
        QA_12: 6156.747,
        VA_12: 1.41894,
        QC_12: 6156.747,
        VC_12: 1.41894,
        QE_12: 5865.036,
        VE_12: 1.32702,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865378984920767, 34.691883612817946, 0.0],
          [-80.866986718251553, 34.690938812819525, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288486.0,
        COMID: 9736548,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.148,
        REACHCODE: "03050103001809",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001535547766768,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060026.0,
        ToNode: 250060028.0,
        Hydroseq: 250005151.0,
        LevelPathI: 250003258.0,
        Pathlength: 387.617,
        TerminalPa: 250002604.0,
        ArbolateSu: 7510.865,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005198.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005140.0,
        FromMeas: 73.08913,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0288,
        TotDASqKM: 9815.8059,
        DivDASqKM: 9815.8059,
        Tidal: 0,
        TOTMA: 0.0043247075643200001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12645.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 4868.264,
        VA_MA: 1.28874,
        QC_MA: 4868.264,
        VC_MA: 1.28874,
        QE_MA: 5570.298,
        VE_MA: 1.2995,
        QA_01: 8950.813,
        VA_01: 1.66303,
        QC_01: 8950.813,
        VC_01: 1.66303,
        QE_01: 7514.559,
        VE_01: 1.47028,
        QA_02: 9456.525,
        VA_02: 1.70315,
        QC_02: 9456.525,
        VC_02: 1.70315,
        QE_02: 7644.473,
        VE_02: 1.4809,
        QA_03: 10377.283,
        VA_03: 1.77369,
        QC_03: 10377.283,
        VC_03: 1.77369,
        QE_03: 8140.233,
        VE_03: 1.52068,
        QA_04: 7130.185,
        VA_04: 1.50895,
        QC_04: 7130.185,
        VC_04: 1.50895,
        QE_04: 6908.628,
        VE_04: 1.41955,
        QA_05: 4861.038,
        VA_05: 1.28797,
        QC_05: 4861.038,
        VC_05: 1.28797,
        QE_05: 5846.65,
        VE_05: 1.32531,
        QA_06: 2871.597,
        VA_06: 1.0491,
        QC_06: 2871.597,
        VC_06: 1.0491,
        QE_06: 5111.392,
        VE_06: 1.25527,
        QA_07: 1790.865,
        VA_07: 0.88484,
        QC_07: 1790.865,
        VC_07: 0.88484,
        QE_07: 3883.232,
        VE_07: 1.12671,
        QA_08: 1275.083,
        VA_08: 0.7897,
        QC_08: 1275.083,
        VC_08: 0.7897,
        QE_08: 3613.963,
        VE_08: 1.09607,
        QA_09: 1159.226,
        VA_09: 0.76596,
        QC_09: 1159.226,
        VC_09: 0.76596,
        QE_09: 3304.465,
        VE_09: 1.0595,
        QA_10: 1246.468,
        VA_10: 0.78393,
        QC_10: 1246.468,
        VC_10: 0.78393,
        QE_10: 4491.177,
        VE_10: 1.19241,
        QA_11: 3176.439,
        VA_11: 1.08987,
        QC_11: 3176.439,
        VC_11: 1.08987,
        QE_11: 4553.668,
        VE_11: 1.19892,
        QA_12: 6156.979,
        VA_12: 1.41895,
        QC_12: 6156.979,
        VC_12: 1.41895,
        QE_12: 5865.268,
        VE_12: 1.32703,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.866986718251553, 34.690938812819525, 0.0],
          [-80.86831058491623, 34.690160812820693, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288479.0,
        COMID: 9740438,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.107,
        REACHCODE: "03050103001324",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00096530081766800003,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060139.0,
        ToNode: 250060080.0,
        Hydroseq: 250005502.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.367,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.229,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005512.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005489.0,
        FromMeas: 57.92445,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0198,
        TotDASqKM: 9333.7866,
        DivDASqKM: 0.9477,
        Tidal: 0,
        TOTMA: 0.0065393226534000001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13072.0,
        MAXELEVSMO: 13130.0,
        MINELEVSMO: 13072.0,
        SLOPE: 0.00542056,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.10700000077485999,
        QA_MA: 0.373,
        VA_MA: 0.62133,
        QC_MA: 0.373,
        VC_MA: 0.62133,
        QE_MA: 0.373,
        VE_MA: 0.62133,
        QA_01: 0.815,
        VA_01: 0.78231,
        QC_01: 0.815,
        VC_01: 0.78231,
        QE_01: 0.815,
        VE_01: 0.78231,
        QA_02: 0.853,
        VA_02: 0.79392,
        QC_02: 0.853,
        VC_02: 0.79392,
        QE_02: 0.853,
        VE_02: 0.79392,
        QA_03: 0.917,
        VA_03: 0.81293,
        QC_03: 0.917,
        VC_03: 0.81293,
        QE_03: 0.917,
        VE_03: 0.81293,
        QA_04: 0.535,
        VA_04: 0.68739,
        QC_04: 0.535,
        VC_04: 0.68739,
        QE_04: 0.535,
        VE_04: 0.68739,
        QA_05: 0.293,
        VA_05: 0.58368,
        QC_05: 0.293,
        VC_05: 0.58368,
        QE_05: 0.293,
        VE_05: 0.58368,
        QA_06: 0.191,
        VA_06: 0.52772,
        QC_06: 0.191,
        VC_06: 0.52772,
        QE_06: 0.191,
        VE_06: 0.52772,
        QA_07: 0.14,
        VA_07: 0.49437,
        QC_07: 0.14,
        VC_07: 0.49437,
        QE_07: 0.14,
        VE_07: 0.49437,
        QA_08: 0.101,
        VA_08: 0.46477,
        QC_08: 0.101,
        VC_08: 0.46477,
        QE_08: 0.101,
        VE_08: 0.46477,
        QA_09: 0.076,
        VA_09: 0.44285,
        QC_09: 0.076,
        VC_09: 0.44285,
        QE_09: 0.076,
        VE_09: 0.44285,
        QA_10: 0.063,
        VA_10: 0.4301,
        QC_10: 0.063,
        VC_10: 0.4301,
        QE_10: 0.063,
        VE_10: 0.4301,
        QA_11: 0.063,
        VA_11: 0.4301,
        QC_11: 0.063,
        VC_11: 0.4301,
        QE_11: 0.063,
        VE_11: 0.4301,
        QA_12: 0.432,
        VA_12: 0.6467,
        QC_12: 0.432,
        VC_12: 0.6467,
        QE_12: 0.432,
        VE_12: 0.6467,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877079718235791, 34.776786812686282, 0.0],
          [-80.877237918235664, 34.775834679354261, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288482.0,
        COMID: 25122789,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.609,
        REACHCODE: "03050103001805",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 120049442,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0058172865141530002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250093422.0,
        ToNode: 250093423.0,
        Hydroseq: 250007106.0,
        LevelPathI: 250003258.0,
        Pathlength: 434.942,
        TerminalPa: 250002604.0,
        ArbolateSu: 5913.381,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250007134.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250007079.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3699,
        TotDASqKM: 7832.0061,
        DivDASqKM: 7832.0061,
        Tidal: 0,
        TOTMA: 0.018540934776900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14987.0,
        MAXELEVSMO: 15153.0,
        MINELEVSMO: 15153.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.60900002717971802,
        QA_MA: 4071.481,
        VA_MA: 1.24274,
        QC_MA: 4071.481,
        VC_MA: 1.24274,
        QE_MA: 4321.502,
        VE_MA: 1.24726,
        QA_01: 7231.864,
        VA_01: 1.57602,
        QC_01: 7231.864,
        VC_01: 1.57602,
        QE_01: 7391.532,
        VE_01: 1.55687,
        QA_02: 7653.887,
        VA_02: 1.61478,
        QC_02: 7653.887,
        VC_02: 1.61478,
        QE_02: 7666.691,
        VE_02: 1.58134,
        QA_03: 8443.074,
        VA_03: 1.68466,
        QC_03: 8443.074,
        VC_03: 1.68466,
        QE_03: 8663.041,
        VE_03: 1.66666,
        QA_04: 5998.472,
        VA_04: 1.4562,
        QC_04: 5998.472,
        VC_04: 1.4562,
        QE_04: 6164.015,
        VE_04: 1.4421,
        QA_05: 4228.025,
        VA_05: 1.26165,
        QC_05: 4228.025,
        VC_05: 1.26165,
        QE_05: 4547.395,
        VE_05: 1.27301,
        QA_06: 2475.594,
        VA_06: 1.02581,
        QC_06: 2475.594,
        VC_06: 1.02581,
        QE_06: 2824.511,
        VE_06: 1.05748,
        QA_07: 1505.746,
        VA_07: 0.85934,
        QC_07: 1505.746,
        VC_07: 0.85934,
        QE_07: 1762.158,
        VE_07: 0.89147,
        QA_08: 1065.746,
        VA_08: 0.76697,
        QC_08: 1065.746,
        VC_08: 0.76697,
        QE_08: 1476.726,
        VE_08: 0.83925,
        QA_09: 985.548,
        VA_09: 0.74831,
        QC_09: 985.548,
        VC_09: 0.74831,
        QE_09: 1106.565,
        VE_09: 0.76383,
        QA_10: 1112.659,
        VA_10: 0.77758,
        QC_10: 1112.659,
        VC_10: 0.77758,
        QE_10: 1388.445,
        VE_10: 0.82216,
        QA_11: 2976.634,
        VA_11: 1.09958,
        QC_11: 2976.634,
        VC_11: 1.09958,
        QE_11: 3139.157,
        VE_11: 1.10069,
        QA_12: 5208.245,
        VA_12: 1.37325,
        QC_12: 5208.245,
        VC_12: 1.37325,
        QE_12: 5208.966,
        VE_12: 1.34515,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.996298118050845, 34.995715812346305, 0.0],
          [-80.992851184722838, 34.991063279020238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288459.0,
        COMID: 9734438,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.024,
        REACHCODE: "03050103001313",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00022237755665900001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059387.0,
        ToNode: 250094145.0,
        Hydroseq: 250005490.0,
        LevelPathI: 250005476.0,
        Pathlength: 398.314,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.26,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005476.0,
        UpHydroseq: 250005499.0,
        DnLevelPat: 250005476.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005476.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9333.7668,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0138903284921,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13130.0,
        MINELEVSMO: 13130.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.024000000208616,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876766784903111, 34.775800212687784, 0.0],
          [-80.876683518236405, 34.775594012687975, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288460.0,
        COMID: 9736424,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.105,
        REACHCODE: "03050103001314",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0010178340603800001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059969.0,
        ToNode: 250059972.0,
        Hydroseq: 250005669.0,
        LevelPathI: 250003258.0,
        Pathlength: 399.492,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.27,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005682.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005654.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0162,
        TotDASqKM: 9191.0457,
        DivDASqKM: 9191.0457,
        Tidal: 0,
        TOTMA: 0.0023348100767500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13473.0,
        MAXELEVSMO: 13474.0,
        MINELEVSMO: 13473.0,
        SLOPE: 9.523e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.104999996721745,
        QA_MA: 4622.784,
        VA_MA: 1.69176,
        QC_MA: 4622.784,
        VC_MA: 1.69176,
        QE_MA: 5324.541,
        VE_MA: 1.70769,
        QA_01: 8409.465,
        VA_01: 2.20914,
        QC_01: 8409.465,
        VC_01: 2.20914,
        QE_01: 6974.392,
        VE_01: 1.92333,
        QA_02: 8893.179,
        VA_02: 2.26644,
        QC_02: 8893.179,
        VC_02: 2.26644,
        QE_02: 7082.349,
        VE_02: 1.93655,
        QA_03: 9774.769,
        VA_03: 2.36722,
        QC_03: 9774.769,
        VC_03: 2.36722,
        QE_03: 7539.575,
        VE_03: 1.99155,
        QA_04: 6786.27,
        VA_04: 2.00456,
        QC_04: 6786.27,
        VC_04: 2.00456,
        QE_04: 6565.166,
        VE_04: 1.8723,
        QA_05: 4668.732,
        VA_05: 1.69904,
        QC_05: 4668.732,
        VC_05: 1.69904,
        QE_05: 5654.316,
        VE_05: 1.75306,
        QA_06: 2748.595,
        VA_06: 1.35804,
        QC_06: 2748.595,
        VC_06: 1.35804,
        QE_06: 4987.23,
        VE_06: 1.65989,
        QA_07: 1699.459,
        VA_07: 1.12154,
        QC_07: 1699.459,
        VC_07: 1.12154,
        QE_07: 3790.777,
        VE_07: 1.4767,
        QA_08: 1207.883,
        VA_08: 0.9867,
        QC_08: 1207.883,
        VC_08: 0.9867,
        QE_08: 3545.311,
        VE_08: 1.4359,
        QA_09: 1107.838,
        VA_09: 0.95626,
        QC_09: 1107.838,
        VC_09: 0.95626,
        QE_09: 3251.902,
        VE_09: 1.38535,
        QA_10: 1204.468,
        VA_10: 0.98568,
        QC_10: 1204.468,
        VC_10: 0.98568,
        QE_10: 4447.248,
        VE_10: 1.58011,
        QA_11: 3130.382,
        VA_11: 1.43309,
        QC_11: 3130.382,
        VC_11: 1.43309,
        QE_11: 4506.611,
        VE_11: 1.58909,
        QA_12: 5873.863,
        VA_12: 1.87938,
        QC_12: 5873.863,
        VC_12: 1.87938,
        QE_12: 5582.696,
        VE_12: 1.74331,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87596338490431, 34.786767679337288, 0.0],
          [-80.875286118238648, 34.78600787933857, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288461.0,
        COMID: 9740450,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.178,
        REACHCODE: "03050103001315",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001612878630863,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060134.0,
        ToNode: 250059977.0,
        Hydroseq: 250005564.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.532,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.2,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005577.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005545.0,
        DnDrainCou: 2,
        DnHydroseq: 250005547.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0225,
        TotDASqKM: 9332.8389,
        DivDASqKM: 9332.8389,
        Tidal: 0,
        TOTMA: 0.0023232707576500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13157.0,
        MAXELEVSMO: 13239.0,
        MINELEVSMO: 13157.0,
        SLOPE: 0.00460674,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.178000003099442,
        QA_MA: 4679.929,
        VA_MA: 2.88005,
        QC_MA: 4679.929,
        VC_MA: 2.88005,
        QE_MA: 5381.75,
        VE_MA: 2.90932,
        QA_01: 8534.531,
        VA_01: 3.84651,
        QC_01: 8534.531,
        VC_01: 3.84651,
        QE_01: 7099.19,
        VE_01: 3.3214,
        QA_02: 9023.068,
        VA_02: 3.95265,
        QC_02: 9023.068,
        VC_02: 3.95265,
        QE_02: 7211.96,
        VE_02: 3.34672,
        QA_03: 9913.492,
        VA_03: 4.13939,
        QC_03: 9913.492,
        VC_03: 4.13939,
        QE_03: 7677.877,
        VE_03: 3.44941,
        QA_04: 6866.307,
        VA_04: 3.46061,
        QC_04: 6866.307,
        VC_04: 3.46061,
        QE_04: 6645.101,
        VE_04: 3.21747,
        QA_05: 4713.32,
        VA_05: 2.88977,
        QC_05: 4713.32,
        VC_05: 2.88977,
        QE_05: 5698.911,
        VE_05: 2.98962,
        QA_06: 2777.191,
        VA_06: 2.25766,
        QC_06: 2777.191,
        VC_06: 2.25766,
        QE_06: 5016.09,
        VE_06: 2.81393,
        QA_07: 1720.43,
        VA_07: 1.82,
        QC_07: 1720.43,
        VC_07: 1.82,
        QE_07: 3811.986,
        VE_07: 2.4741,
        QA_08: 1223.134,
        VA_08: 1.56954,
        QC_08: 1223.134,
        VC_08: 1.56954,
        QE_08: 3560.892,
        VE_08: 2.39714,
        QA_09: 1120.159,
        VA_09: 1.512,
        QC_09: 1120.159,
        VC_09: 1.512,
        QE_09: 3264.49,
        VE_09: 2.30294,
        QA_10: 1214.0,
        VA_10: 1.56453,
        QC_10: 1214.0,
        VC_10: 1.56453,
        QE_10: 4457.218,
        VE_10: 2.6616,
        QA_11: 3143.357,
        VA_11: 2.39016,
        QC_11: 3143.357,
        VC_11: 2.39016,
        QE_11: 4519.813,
        VE_11: 2.67909,
        QA_12: 5942.062,
        VA_12: 3.22768,
        QC_12: 5942.062,
        VC_12: 3.22768,
        QE_12: 5650.772,
        VE_12: 2.97757,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87613231823741, 34.779191679349083, 0.0],
          [-80.875767584904622, 34.777650279351576, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288462.0,
        COMID: 9736436,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.136,
        REACHCODE: "03050103001316",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0012149630667360001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059975.0,
        ToNode: 250060144.0,
        Hydroseq: 250005598.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.817,
        TerminalPa: 250002604.0,
        ArbolateSu: 6990.592,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005610.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005587.0,
        FromMeas: 18.09665,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.027,
        TotDASqKM: 9332.1198,
        DivDASqKM: 9332.1198,
        Tidal: 0,
        TOTMA: 0.00203217516737,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13258.0,
        MAXELEVSMO: 13296.0,
        MINELEVSMO: 13272.0,
        SLOPE: 0.0017647,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13600000739097601,
        QA_MA: 4679.646,
        VA_MA: 2.51613,
        QC_MA: 4679.646,
        VC_MA: 2.51613,
        QE_MA: 5381.466,
        VE_MA: 2.54126,
        QA_01: 8533.909,
        VA_01: 3.3458,
        QC_01: 8533.909,
        VC_01: 3.3458,
        QE_01: 7098.569,
        VE_01: 2.89497,
        QA_02: 9022.42,
        VA_02: 3.43692,
        QC_02: 9022.42,
        VC_02: 3.43692,
        QE_02: 7211.313,
        VE_02: 2.91671,
        QA_03: 9912.796,
        VA_03: 3.59724,
        QC_03: 9912.796,
        VC_03: 3.59724,
        QE_03: 7677.183,
        VE_03: 3.00487,
        QA_04: 6865.901,
        VA_04: 3.01453,
        QC_04: 6865.901,
        VC_04: 3.01453,
        QE_04: 6644.695,
        VE_04: 2.80579,
        QA_05: 4713.098,
        VA_05: 2.52449,
        QC_05: 4713.098,
        VC_05: 2.52449,
        QE_05: 5698.688,
        VE_05: 2.61021,
        QA_06: 2777.046,
        VA_06: 1.98182,
        QC_06: 2777.046,
        VC_06: 1.98182,
        QE_06: 5015.944,
        VE_06: 2.4594,
        QA_07: 1720.323,
        VA_07: 1.60609,
        QC_07: 1720.323,
        VC_07: 1.60609,
        QE_07: 3811.878,
        VE_07: 2.16765,
        QA_08: 1223.057,
        VA_08: 1.39107,
        QC_08: 1223.057,
        VC_08: 1.39107,
        QE_08: 3560.813,
        VE_08: 2.10159,
        QA_09: 1120.101,
        VA_09: 1.34168,
        QC_09: 1120.101,
        VC_09: 1.34168,
        QE_09: 3264.43,
        VE_09: 2.02072,
        QA_10: 1213.952,
        VA_10: 1.38678,
        QC_10: 1213.952,
        VC_10: 1.38678,
        QE_10: 4457.167,
        VE_10: 2.32864,
        QA_11: 3143.309,
        VA_11: 2.09561,
        QC_11: 3143.309,
        VC_11: 2.09561,
        QE_11: 4519.763,
        VE_11: 2.34366,
        QA_12: 5941.733,
        VA_12: 2.81457,
        QC_12: 5941.733,
        VC_12: 2.81457,
        QE_12: 5650.444,
        VE_12: 2.59984,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876039518237462, 34.781352612679143, 0.0],
          [-80.876111984904014, 34.780139812680886, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288455.0,
        COMID: 9740412,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.172,
        REACHCODE: "03050103001308",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001734341316376,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059969.0,
        ToNode: 250060128.0,
        Hydroseq: 250005668.0,
        LevelPathI: 250005620.0,
        Pathlength: 399.505,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.337,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005682.0,
        DnLevelPat: 250005620.0,
        DnMinorHyd: 250005652.0,
        DnDrainCou: 2,
        DnHydroseq: 250005653.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0243,
        TotDASqKM: 9191.0538,
        DivDASqKM: 0.0243,
        Tidal: 0,
        TOTMA: 0.018258651068400002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13475.0,
        MAXELEVSMO: 13474.0,
        MINELEVSMO: 13475.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.021999999880790998,
        QA_MA: 0.009,
        VA_MA: 0.35771,
        QC_MA: 0.009,
        VC_MA: 0.35771,
        QE_MA: 0.009,
        VE_MA: 0.35771,
        QA_01: 0.021,
        VA_01: 0.38574,
        QC_01: 0.021,
        VC_01: 0.38574,
        QE_01: 0.021,
        VE_01: 0.38574,
        QA_02: 0.021,
        VA_02: 0.38574,
        QC_02: 0.021,
        VC_02: 0.38574,
        QE_02: 0.021,
        VE_02: 0.38574,
        QA_03: 0.023,
        VA_03: 0.38957,
        QC_03: 0.023,
        VC_03: 0.38957,
        QE_03: 0.023,
        VE_03: 0.38957,
        QA_04: 0.013,
        VA_04: 0.36835,
        QC_04: 0.013,
        VC_04: 0.36835,
        QE_04: 0.013,
        VE_04: 0.36835,
        QA_05: 0.007,
        VA_05: 0.35155,
        QC_05: 0.007,
        VC_05: 0.35155,
        QE_05: 0.007,
        VE_05: 0.35155,
        QA_06: 0.004,
        VA_06: 0.34046,
        QC_06: 0.004,
        VC_06: 0.34046,
        QE_06: 0.004,
        VE_06: 0.34046,
        QA_07: 0.003,
        VA_07: 0.33591,
        QC_07: 0.003,
        VC_07: 0.33591,
        QE_07: 0.003,
        VE_07: 0.33591,
        QA_08: 0.002,
        VA_08: 0.33058,
        QC_08: 0.002,
        VC_08: 0.33058,
        QE_08: 0.002,
        VE_08: 0.33058,
        QA_09: 0.001,
        VA_09: 0.32375,
        QC_09: 0.001,
        VC_09: 0.32375,
        QE_09: 0.001,
        VE_09: 0.32375,
        QA_10: 0.001,
        VA_10: 0.32375,
        QC_10: 0.001,
        VC_10: 0.32375,
        QE_10: 0.001,
        VE_10: 0.32375,
        QA_11: 0.001,
        VA_11: 0.32375,
        QC_11: 0.001,
        VC_11: 0.32375,
        QE_11: 0.001,
        VE_11: 0.32375,
        QA_12: 0.011,
        VA_12: 0.36326,
        QC_12: 0.011,
        VC_12: 0.36326,
        QE_12: 0.011,
        VE_12: 0.36326,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87596338490431, 34.786767679337288, 0.0],
          [-80.877267984902289, 34.785808279338937, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288456.0,
        COMID: 9740424,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.03,
        REACHCODE: "03050103001309",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000288547781057,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060134.0,
        ToNode: 250060133.0,
        Hydroseq: 250005565.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.928,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.052,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005577.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005546.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9332.8164,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0173629100761,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13239.0,
        MINELEVSMO: 13239.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.029999999329448,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87613231823741, 34.779191679349083, 0.0],
          [-80.876306984903806, 34.779421212682053, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288457.0,
        COMID: 9740432,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.157,
        REACHCODE: "03050103001310",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001601017135369,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059977.0,
        ToNode: 250060139.0,
        Hydroseq: 250005545.0,
        LevelPathI: 250005545.0,
        Pathlength: 398.474,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.357,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005564.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 250005499.0,
        DnDrainCou: 2,
        DnHydroseq: 250005502.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9332.8389,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0193317285685,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13157.0,
        MINELEVSMO: 13130.0,
        SLOPE: 0.0135,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.019999999552965001,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875767584904622, 34.777650279351576, 0.0],
          [-80.877079718235791, 34.776786812686282, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288458.0,
        COMID: 9740436,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.094,
        REACHCODE: "03050103001311",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00087407239593900005,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060138.0,
        ToNode: 250060139.0,
        Hydroseq: 250005512.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.474,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.787,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005522.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 250005499.0,
        DnDrainCou: 2,
        DnHydroseq: 250005502.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8622,
        TotDASqKM: 9333.7443,
        DivDASqKM: 0.9279,
        Tidal: 0,
        TOTMA: 0.0059188675839700004,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13130.0,
        MAXELEVSMO: 13166.0,
        MINELEVSMO: 13130.0,
        SLOPE: 0.00382978,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.093999996781349002,
        QA_MA: 0.365,
        VA_MA: 0.60306,
        QC_MA: 0.365,
        VC_MA: 0.60306,
        QE_MA: 0.365,
        VE_MA: 0.60306,
        QA_01: 0.798,
        VA_01: 0.75479,
        QC_01: 0.798,
        VC_01: 0.75479,
        QE_01: 0.798,
        VE_01: 0.75479,
        QA_02: 0.835,
        VA_02: 0.76567,
        QC_02: 0.835,
        VC_02: 0.76567,
        QE_02: 0.835,
        VE_02: 0.76567,
        QA_03: 0.898,
        VA_03: 0.78367,
        QC_03: 0.898,
        VC_03: 0.78367,
        QE_03: 0.898,
        VE_03: 0.78367,
        QA_04: 0.523,
        VA_04: 0.66508,
        QC_04: 0.523,
        VC_04: 0.66508,
        QE_04: 0.523,
        VE_04: 0.66508,
        QA_05: 0.286,
        VA_05: 0.56727,
        QC_05: 0.286,
        VC_05: 0.56727,
        QE_05: 0.286,
        VE_05: 0.56727,
        QA_06: 0.187,
        VA_06: 0.51498,
        QC_06: 0.187,
        VC_06: 0.51498,
        QE_06: 0.187,
        VE_06: 0.51498,
        QA_07: 0.137,
        VA_07: 0.48352,
        QC_07: 0.137,
        VC_07: 0.48352,
        QE_07: 0.137,
        VE_07: 0.48352,
        QA_08: 0.099,
        VA_08: 0.45577,
        QC_08: 0.099,
        VC_08: 0.45577,
        QE_08: 0.099,
        VE_08: 0.45577,
        QA_09: 0.074,
        VA_09: 0.43467,
        QC_09: 0.074,
        VC_09: 0.43467,
        QE_09: 0.074,
        VE_09: 0.43467,
        QA_10: 0.062,
        VA_10: 0.42334,
        QC_10: 0.062,
        VC_10: 0.42334,
        QE_10: 0.062,
        VE_10: 0.42334,
        QA_11: 0.062,
        VA_11: 0.42334,
        QC_11: 0.062,
        VC_11: 0.42334,
        QE_11: 0.062,
        VE_11: 0.42334,
        QA_12: 0.423,
        VA_12: 0.62707,
        QC_12: 0.423,
        VC_12: 0.62707,
        QE_12: 0.423,
        VE_12: 0.62707,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877450984901998, 34.777539279351629, 0.0],
          [-80.877079718235791, 34.776786812686282, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288467.0,
        COMID: 9736420,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.365,
        REACHCODE: "03050103001319",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0035143599923940002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059967.0,
        ToNode: 250060142.0,
        Hydroseq: 250005697.0,
        LevelPathI: 250003258.0,
        Pathlength: 399.66,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.102,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005738.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005682.0,
        FromMeas: 14.60722,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6732,
        TotDASqKM: 9191.0277,
        DivDASqKM: 9191.0277,
        Tidal: 0,
        TOTMA: 0.0050540152913599997,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13468.0,
        MAXELEVSMO: 13587.0,
        MINELEVSMO: 13474.0,
        SLOPE: 0.00309589,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.365000009536743,
        QA_MA: 4622.776,
        VA_MA: 2.71468,
        QC_MA: 4622.776,
        VC_MA: 2.71468,
        QE_MA: 5324.534,
        VE_MA: 2.74238,
        QA_01: 8409.449,
        VA_01: 3.61463,
        QC_01: 8409.449,
        VC_01: 3.61463,
        QE_01: 6974.376,
        VE_01: 3.11747,
        QA_02: 8893.163,
        VA_02: 3.71429,
        QC_02: 8893.163,
        VC_02: 3.71429,
        QE_02: 7082.332,
        VE_02: 3.14048,
        QA_03: 9774.752,
        VA_03: 3.8896,
        QC_03: 9774.752,
        VC_03: 3.8896,
        QE_03: 7539.558,
        VE_03: 3.23614,
        QA_04: 6786.259,
        VA_04: 3.25878,
        QC_04: 6786.259,
        VC_04: 3.25878,
        QE_04: 6565.156,
        VE_04: 3.02871,
        QA_05: 4668.727,
        VA_05: 2.72735,
        QC_05: 4668.727,
        VC_05: 2.72735,
        QE_05: 5654.311,
        VE_05: 2.8213,
        QA_06: 2748.591,
        VA_06: 2.13418,
        QC_06: 2748.591,
        VC_06: 2.13418,
        QE_06: 4987.227,
        VE_06: 2.65925,
        QA_07: 1699.456,
        VA_07: 1.72281,
        QC_07: 1699.456,
        VC_07: 1.72281,
        QE_07: 3790.774,
        VE_07: 2.3406,
        QA_08: 1207.881,
        VA_08: 1.48827,
        QC_08: 1207.881,
        VC_08: 1.48827,
        QE_08: 3545.309,
        VE_08: 2.26963,
        QA_09: 1107.836,
        VA_09: 1.43533,
        QC_09: 1107.836,
        VC_09: 1.43533,
        QE_09: 3251.9,
        VE_09: 2.18169,
        QA_10: 1204.467,
        VA_10: 1.4865,
        QC_10: 1204.467,
        VC_10: 1.4865,
        QE_10: 4447.246,
        VE_10: 2.52046,
        QA_11: 3130.381,
        VA_11: 2.26474,
        QC_11: 3130.381,
        VC_11: 2.26474,
        QE_11: 4506.61,
        VE_11: 2.53609,
        QA_12: 5873.854,
        VA_12: 3.04103,
        QC_12: 5873.854,
        VC_12: 3.04103,
        QE_12: 5582.688,
        VE_12: 2.80435,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87813991823424, 34.789934479332317, 0.0],
          [-80.876179718237267, 34.787284812669895, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288468.0,
        COMID: 9740444,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.063,
        REACHCODE: "03050103001319",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00057939103273100005,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060142.0,
        ToNode: 250059969.0,
        Hydroseq: 250005682.0,
        LevelPathI: 250003258.0,
        Pathlength: 399.597,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.165,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005697.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005668.0,
        DnDrainCou: 2,
        DnHydroseq: 250005669.0,
        FromMeas: 0.0,
        ToMeas: 14.60722,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9191.0295,
        DivDASqKM: 9191.0295,
        Tidal: 0,
        TOTMA: 0.0018598577321600001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13474.0,
        MAXELEVSMO: 13474.0,
        MINELEVSMO: 13474.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.063000001013279003,
        QA_MA: 4622.777,
        VA_MA: 1.27514,
        QC_MA: 4622.777,
        VC_MA: 1.27514,
        QE_MA: 5324.535,
        VE_MA: 1.28627,
        QA_01: 8409.451,
        VA_01: 1.63671,
        QC_01: 8409.451,
        VC_01: 1.63671,
        QE_01: 6974.378,
        VE_01: 1.43697,
        QA_02: 8893.165,
        VA_02: 1.67674,
        QC_02: 8893.165,
        VC_02: 1.67674,
        QE_02: 7082.334,
        VE_02: 1.44621,
        QA_03: 9774.754,
        VA_03: 1.74717,
        QC_03: 9774.754,
        VC_03: 1.74717,
        QE_03: 7539.559,
        VE_03: 1.48464,
        QA_04: 6786.261,
        VA_04: 1.49374,
        QC_04: 6786.261,
        VC_04: 1.49374,
        QE_04: 6565.157,
        VE_04: 1.40131,
        QA_05: 4668.727,
        VA_05: 1.28023,
        QC_05: 4668.727,
        VC_05: 1.28023,
        QE_05: 5654.311,
        VE_05: 1.31798,
        QA_06: 2748.591,
        VA_06: 1.04192,
        QC_06: 2748.591,
        VC_06: 1.04192,
        QE_06: 4987.227,
        VE_06: 1.25287,
        QA_07: 1699.457,
        VA_07: 0.87664,
        QC_07: 1699.457,
        VC_07: 0.87664,
        QE_07: 3790.775,
        VE_07: 1.12485,
        QA_08: 1207.881,
        VA_08: 0.78242,
        QC_08: 1207.881,
        VC_08: 0.78242,
        QE_08: 3545.31,
        VE_08: 1.09634,
        QA_09: 1107.837,
        VA_09: 0.76115,
        QC_09: 1107.837,
        VC_09: 0.76115,
        QE_09: 3251.901,
        VE_09: 1.06101,
        QA_10: 1204.467,
        VA_10: 0.7817,
        QC_10: 1204.467,
        VC_10: 0.7817,
        QE_10: 4447.247,
        VE_10: 1.19711,
        QA_11: 3130.381,
        VA_11: 1.09437,
        QC_11: 3130.381,
        VC_11: 1.09437,
        QE_11: 4506.61,
        VE_11: 1.20339,
        QA_12: 5873.855,
        VA_12: 1.40625,
        QC_12: 5873.855,
        VC_12: 1.40625,
        QE_12: 5582.689,
        VE_12: 1.31117,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876179718237267, 34.787284812669895, 0.0],
          [-80.87596338490431, 34.786767679337288, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288469.0,
        COMID: 9736204,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.199,
        REACHCODE: "03050103001320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011778962824437999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059866.0,
        ToNode: 250059870.0,
        Hydroseq: 250006853.0,
        LevelPathI: 250003258.0,
        Pathlength: 430.851,
        TerminalPa: 250002604.0,
        ArbolateSu: 5946.504,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006899.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006827.0,
        FromMeas: 63.10612,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.7209,
        TotDASqKM: 7897.5333,
        DivDASqKM: 7897.5333,
        Tidal: 0,
        TOTMA: 0.0194157066288,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14680.0,
        MAXELEVSMO: 14947.0,
        MINELEVSMO: 14792.0,
        SLOPE: 0.00129274,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1990000009536741,
        QA_MA: 4099.619,
        VA_MA: 2.33616,
        QC_MA: 4099.619,
        VC_MA: 2.33616,
        QE_MA: 4325.055,
        VE_MA: 2.34497,
        QA_01: 7290.892,
        VA_01: 3.06128,
        QC_01: 7290.892,
        VC_01: 3.06128,
        QE_01: 5869.901,
        VE_01: 2.70354,
        QA_02: 7715.987,
        VA_02: 3.14537,
        QC_02: 7715.987,
        VC_02: 3.14537,
        QE_02: 6043.803,
        VE_02: 2.74097,
        QA_03: 8510.445,
        VA_03: 3.29691,
        QC_03: 8510.445,
        VC_03: 3.29691,
        QE_03: 6141.164,
        VE_03: 2.7617,
        QA_04: 6038.901,
        VA_04: 2.7992,
        QC_04: 6038.901,
        VC_04: 2.7992,
        QE_04: 5355.5,
        VE_04: 2.5897,
        QA_05: 4250.339,
        VA_05: 2.37541,
        QC_05: 4250.339,
        VC_05: 2.37541,
        QE_05: 4469.428,
        VE_05: 2.38079,
        QA_06: 2489.689,
        VA_06: 1.86438,
        QC_06: 2489.689,
        VC_06: 1.86438,
        QE_06: 4166.58,
        VE_06: 2.305,
        QA_07: 1515.438,
        VA_07: 1.50381,
        QC_07: 1515.438,
        VC_07: 1.50381,
        QE_07: 3141.248,
        VE_07: 2.0269,
        QA_08: 1072.794,
        VA_08: 1.30347,
        QC_08: 1072.794,
        VC_08: 1.30347,
        QE_08: 3077.627,
        VE_08: 2.00833,
        QA_09: 991.715,
        VA_09: 1.2628,
        QC_09: 991.715,
        VC_09: 1.2628,
        QE_09: 2570.915,
        VE_09: 1.85346,
        QA_10: 1117.065,
        VA_10: 1.32507,
        QC_10: 1117.065,
        VC_10: 1.32507,
        QE_10: 3399.324,
        VE_10: 2.10048,
        QA_11: 2987.034,
        VA_11: 2.02237,
        QC_11: 2987.034,
        VC_11: 2.02237,
        QE_11: 3608.555,
        VE_11: 2.15823,
        QA_12: 5243.087,
        VA_12: 2.61914,
        QC_12: 5243.087,
        VC_12: 2.61914,
        QE_12: 4403.37,
        VE_12: 2.36447,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.964736918099845, 34.981328012368579, 0.0],
          [-80.956700784779002, 34.973162479047971, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288470.0,
        COMID: 9736212,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.199,
        REACHCODE: "03050103001320",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011064773909162001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059870.0,
        ToNode: 250059869.0,
        Hydroseq: 250006827.0,
        LevelPathI: 250003258.0,
        Pathlength: 429.652,
        TerminalPa: 250002604.0,
        ArbolateSu: 5950.369,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006853.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250006798.0,
        DnDrainCou: 2,
        DnHydroseq: 250006797.0,
        FromMeas: 26.38832,
        ToMeas: 63.10612,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.2393,
        TotDASqKM: 7900.8327,
        DivDASqKM: 7900.8327,
        Tidal: 0,
        TOTMA: 0.036465115750999998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14675.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14792.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.1990000009536741,
        QA_MA: 4101.015,
        VA_MA: 1.2445,
        QC_MA: 4101.015,
        VC_MA: 1.2445,
        QE_MA: 4326.459,
        VE_MA: 1.24857,
        QA_01: 7293.862,
        VA_01: 1.5793,
        QC_01: 7293.862,
        VC_01: 1.5793,
        QE_01: 5872.855,
        VE_01: 1.41421,
        QA_02: 7719.092,
        VA_02: 1.61812,
        QC_02: 7719.092,
        VC_02: 1.61812,
        QE_02: 6046.888,
        VE_02: 1.43149,
        QA_03: 8513.779,
        VA_03: 1.68807,
        QC_03: 8513.779,
        VC_03: 1.68807,
        QE_03: 6144.473,
        VE_03: 1.44108,
        QA_04: 6040.897,
        VA_04: 1.45826,
        QC_04: 6040.897,
        VC_04: 1.45826,
        QE_04: 5357.493,
        VE_04: 1.36157,
        QA_05: 4251.448,
        VA_05: 1.26258,
        QC_05: 4251.448,
        VC_05: 1.26258,
        QE_05: 4470.548,
        VE_05: 1.26507,
        QA_06: 2490.396,
        VA_06: 1.02669,
        QC_06: 2490.396,
        VC_06: 1.02669,
        QE_06: 4167.312,
        VE_06: 1.23005,
        QA_07: 1515.925,
        VA_07: 0.86024,
        QC_07: 1515.925,
        VC_07: 0.86024,
        QE_07: 3141.759,
        VE_07: 1.10167,
        QA_08: 1073.149,
        VA_08: 0.76776,
        QC_08: 1073.149,
        VC_08: 0.76776,
        QE_08: 3078.008,
        VE_08: 1.09308,
        QA_09: 992.025,
        VA_09: 0.74898,
        QC_09: 992.025,
        VC_09: 0.74898,
        QE_09: 2571.25,
        VE_09: 1.02159,
        QA_10: 1117.286,
        VA_10: 0.7777,
        QC_10: 1117.286,
        VC_10: 0.7777,
        QE_10: 3399.583,
        VE_10: 1.13559,
        QA_11: 2987.48,
        VA_11: 1.09956,
        QC_11: 2987.48,
        VC_11: 1.09956,
        QE_11: 3609.015,
        VE_11: 1.16227,
        QA_12: 5244.799,
        VA_12: 1.37513,
        QC_12: 5244.799,
        VC_12: 1.37513,
        QE_12: 4405.078,
        VE_12: 1.25761,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.956700784779002, 34.973162479047971, 0.0],
          [-80.960123184773693, 34.96729147905711, 0.0],
          [-80.959924984773977, 34.963192879063513, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287429.0,
        COMID: 9735378,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.159,
        REACHCODE: "03050103000639",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015446830150270001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059703.0,
        ToNode: 250094163.0,
        Hydroseq: 250005021.0,
        LevelPathI: 250004991.0,
        Pathlength: 375.776,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.701,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004991.0,
        UpHydroseq: 250005030.0,
        DnLevelPat: 250004991.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004991.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0423,
        TotDASqKM: 9867.1671,
        DivDASqKM: 0.0423,
        Tidal: 0,
        TOTMA: 0.016591527155000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10798.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158999994397163,
        QA_MA: 0.014,
        VA_MA: 0.3639,
        QC_MA: 0.014,
        VC_MA: 0.3639,
        QE_MA: 0.014,
        VE_MA: 0.3639,
        QA_01: 0.03,
        VA_01: 0.3916,
        QC_01: 0.03,
        VC_01: 0.3916,
        QE_01: 0.03,
        VE_01: 0.3916,
        QA_02: 0.033,
        VA_02: 0.39592,
        QC_02: 0.033,
        VC_02: 0.39592,
        QE_02: 0.033,
        VE_02: 0.39592,
        QA_03: 0.037,
        VA_03: 0.4014,
        QC_03: 0.037,
        VC_03: 0.4014,
        QE_03: 0.037,
        VE_03: 0.4014,
        QA_04: 0.019,
        VA_04: 0.37368,
        QC_04: 0.019,
        VC_04: 0.37368,
        QE_04: 0.019,
        VE_04: 0.37368,
        QA_05: 0.01,
        VA_05: 0.35482,
        QC_05: 0.01,
        VC_05: 0.35482,
        QE_05: 0.01,
        VE_05: 0.35482,
        QA_06: 0.007,
        VA_06: 0.34681,
        QC_06: 0.007,
        VC_06: 0.34681,
        QE_06: 0.007,
        VE_06: 0.34681,
        QA_07: 0.005,
        VA_07: 0.34052,
        QC_07: 0.005,
        VC_07: 0.34052,
        QE_07: 0.005,
        VE_07: 0.34052,
        QA_08: 0.004,
        VA_08: 0.33693,
        QC_08: 0.004,
        VC_08: 0.33693,
        QE_08: 0.004,
        VE_08: 0.33693,
        QA_09: 0.003,
        VA_09: 0.33289,
        QC_09: 0.003,
        VC_09: 0.33289,
        QE_09: 0.003,
        VE_09: 0.33289,
        QA_10: 0.002,
        VA_10: 0.32814,
        QC_10: 0.002,
        VC_10: 0.32814,
        QE_10: 0.002,
        VE_10: 0.32814,
        QA_11: 0.002,
        VA_11: 0.32814,
        QC_11: 0.002,
        VC_11: 0.32814,
        QE_11: 0.002,
        VE_11: 0.32814,
        QA_12: 0.01,
        VA_12: 0.35482,
        QC_12: 0.01,
        VC_12: 0.35482,
        QE_12: 0.01,
        VE_12: 0.35482,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.893165518210935, 34.597354412964762, 0.0],
          [-80.893857718209915, 34.596300679633089, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288463.0,
        COMID: 9740454,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.03,
        REACHCODE: "03050103001316",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000269921872989,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060144.0,
        ToNode: 250059976.0,
        Hydroseq: 250005587.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.787,
        TerminalPa: 250002604.0,
        ArbolateSu: 6990.622,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005598.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005574.0,
        DnDrainCou: 2,
        DnHydroseq: 250005577.0,
        FromMeas: 0.0,
        ToMeas: 18.09665,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9332.1225,
        DivDASqKM: 9332.1225,
        Tidal: 0,
        TOTMA: 0.00088354457748800002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13272.0,
        MAXELEVSMO: 13272.0,
        MINELEVSMO: 13272.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.029999999329448,
        QA_MA: 4679.647,
        VA_MA: 1.27829,
        QC_MA: 4679.647,
        VC_MA: 1.27829,
        QE_MA: 5381.467,
        VE_MA: 1.28933,
        QA_01: 8533.911,
        VA_01: 1.64278,
        QC_01: 8533.911,
        VC_01: 1.64278,
        QE_01: 7098.572,
        VE_01: 1.44473,
        QA_02: 9022.422,
        VA_02: 1.68281,
        QC_02: 9022.422,
        VC_02: 1.68281,
        QE_02: 7211.316,
        VE_02: 1.45427,
        QA_03: 9912.799,
        VA_03: 1.75324,
        QC_03: 9912.799,
        VC_03: 1.75324,
        QE_03: 7677.185,
        VE_03: 1.493,
        QA_04: 6865.903,
        VA_04: 1.49725,
        QC_04: 6865.903,
        VC_04: 1.49725,
        QE_04: 6644.697,
        VE_04: 1.40555,
        QA_05: 4713.099,
        VA_05: 1.28197,
        QC_05: 4713.099,
        VC_05: 1.28197,
        QE_05: 5698.689,
        VE_05: 1.31962,
        QA_06: 2777.047,
        VA_06: 1.04356,
        QC_06: 2777.047,
        VC_06: 1.04356,
        QE_06: 5015.944,
        VE_06: 1.25337,
        QA_07: 1720.324,
        VA_07: 0.87849,
        QC_07: 1720.324,
        VC_07: 0.87849,
        QE_07: 3811.879,
        VE_07: 1.1252,
        QA_08: 1223.057,
        VA_08: 0.78403,
        QC_08: 1223.057,
        VC_08: 0.78403,
        QE_08: 3560.814,
        VE_08: 1.09618,
        QA_09: 1120.101,
        VA_09: 0.76233,
        QC_09: 1120.101,
        VC_09: 0.76233,
        QE_09: 3264.431,
        VE_09: 1.06065,
        QA_10: 1213.952,
        VA_10: 0.78214,
        QC_10: 1213.952,
        VC_10: 0.78214,
        QE_10: 4457.167,
        VE_10: 1.19593,
        QA_11: 3143.309,
        VA_11: 1.09355,
        QC_11: 3143.309,
        VC_11: 1.09355,
        QE_11: 4519.764,
        VE_11: 1.20252,
        QA_12: 5941.735,
        VA_12: 1.4094,
        QC_12: 5941.735,
        VC_12: 1.4094,
        QE_12: 5650.445,
        VE_12: 1.31507,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876111984904014, 34.780139812680886, 0.0],
          [-80.876164318237343, 34.779875012681373, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288464.0,
        COMID: 9736430,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.318,
        REACHCODE: "03050103001318",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.002844842672693,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059972.0,
        ToNode: 250059973.0,
        Hydroseq: 250005654.0,
        LevelPathI: 250003258.0,
        Pathlength: 399.174,
        TerminalPa: 250002604.0,
        ArbolateSu: 6986.839,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005669.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005637.0,
        FromMeas: 41.10813,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0459,
        TotDASqKM: 9330.0687,
        DivDASqKM: 9330.0687,
        Tidal: 0,
        TOTMA: 0.0043398288780299998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13366.0,
        MAXELEVSMO: 13473.0,
        MINELEVSMO: 13366.0,
        SLOPE: 0.00336477,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.317999988794327,
        QA_MA: 4678.838,
        VA_MA: 2.75459,
        QC_MA: 4678.838,
        VC_MA: 2.75459,
        QE_MA: 5380.657,
        VE_MA: 2.78244,
        QA_01: 8532.132,
        VA_01: 3.67382,
        QC_01: 8532.132,
        VC_01: 3.67382,
        QE_01: 7096.797,
        VE_01: 3.17421,
        QA_02: 9020.572,
        VA_02: 3.77478,
        QC_02: 9020.572,
        VC_02: 3.77478,
        QE_02: 7209.469,
        VE_02: 3.19828,
        QA_03: 9910.811,
        VA_03: 3.95242,
        QC_03: 9910.811,
        VC_03: 3.95242,
        QE_03: 7675.203,
        VE_03: 3.29596,
        QA_04: 6864.755,
        VA_04: 3.30684,
        QC_04: 6864.755,
        VC_04: 3.30684,
        QE_04: 6643.55,
        VE_04: 3.07552,
        QA_05: 4712.464,
        VA_05: 2.76391,
        QC_05: 4712.464,
        VC_05: 2.76391,
        QE_05: 5698.054,
        VE_05: 2.85889,
        QA_06: 2776.632,
        VA_06: 2.16259,
        QC_06: 2776.632,
        VC_06: 2.16259,
        QE_06: 5015.526,
        VE_06: 2.69182,
        QA_07: 1720.02,
        VA_07: 1.74625,
        QC_07: 1720.02,
        VC_07: 1.74625,
        QE_07: 3811.572,
        VE_07: 2.36855,
        QA_08: 1222.836,
        VA_08: 1.50801,
        QC_08: 1222.836,
        VC_08: 1.50801,
        QE_08: 3560.588,
        VE_08: 2.29537,
        QA_09: 1119.936,
        VA_09: 1.4533,
        QC_09: 1119.936,
        VC_09: 1.4533,
        QE_09: 3264.261,
        VE_09: 2.20577,
        QA_10: 1213.814,
        VA_10: 1.5033,
        QC_10: 1213.814,
        VC_10: 1.5033,
        QE_10: 4457.023,
        VE_10: 2.547,
        QA_11: 3143.171,
        VA_11: 2.28878,
        QC_11: 3143.171,
        VC_11: 2.28878,
        QE_11: 4519.622,
        VE_11: 2.56364,
        QA_12: 5940.812,
        VA_12: 3.08529,
        QC_12: 5940.812,
        VC_12: 3.08529,
        QE_12: 5649.524,
        VE_12: 2.84734,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875286118238648, 34.78600787933857, 0.0],
          [-80.87534658490523, 34.783163679343033, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288465.0,
        COMID: 9736432,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.067,
        REACHCODE: "03050103001318",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00065038060895899997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059973.0,
        ToNode: 250059974.0,
        Hydroseq: 250005637.0,
        LevelPathI: 250003258.0,
        Pathlength: 399.107,
        TerminalPa: 250002604.0,
        ArbolateSu: 6989.111,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005654.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005610.0,
        FromMeas: 28.62457,
        ToMeas: 41.10813,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9331.8057,
        DivDASqKM: 9331.8057,
        Tidal: 0,
        TOTMA: 0.0010768107414800001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13332.0,
        MAXELEVSMO: 13366.0,
        MINELEVSMO: 13359.0,
        SLOPE: 0.00104477,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.067000001668929998,
        QA_MA: 4679.521,
        VA_MA: 2.33957,
        QC_MA: 4679.521,
        VC_MA: 2.33957,
        QE_MA: 5381.341,
        VE_MA: 2.36269,
        QA_01: 8533.634,
        VA_01: 3.10288,
        QC_01: 8533.634,
        VC_01: 3.10288,
        QE_01: 7098.295,
        VE_01: 2.6881,
        QA_02: 9022.137,
        VA_02: 3.18672,
        QC_02: 9022.137,
        VC_02: 3.18672,
        QE_02: 7211.031,
        VE_02: 2.70809,
        QA_03: 9912.492,
        VA_03: 3.33421,
        QC_03: 9912.492,
        VC_03: 3.33421,
        QE_03: 7676.88,
        VE_03: 2.7892,
        QA_04: 6865.724,
        VA_04: 2.79811,
        QC_04: 6865.724,
        VC_04: 2.79811,
        QE_04: 6644.518,
        VE_04: 2.60607,
        QA_05: 4713.001,
        VA_05: 2.34727,
        QC_05: 4713.001,
        VC_05: 2.34727,
        QE_05: 5698.591,
        VE_05: 2.42613,
        QA_06: 2776.983,
        VA_06: 1.84799,
        QC_06: 2776.983,
        VC_06: 1.84799,
        QE_06: 5015.88,
        VE_06: 2.28739,
        QA_07: 1720.277,
        VA_07: 1.50231,
        QC_07: 1720.277,
        VC_07: 1.50231,
        QE_07: 3811.831,
        VE_07: 2.01897,
        QA_08: 1223.023,
        VA_08: 1.30448,
        QC_08: 1223.023,
        VC_08: 1.30448,
        QE_08: 3560.779,
        VE_08: 1.95819,
        QA_09: 1120.076,
        VA_09: 1.25904,
        QC_09: 1120.076,
        VC_09: 1.25904,
        QE_09: 3264.404,
        VE_09: 1.88379,
        QA_10: 1213.931,
        VA_10: 1.30054,
        QC_10: 1213.931,
        VC_10: 1.30054,
        QE_10: 4457.145,
        VE_10: 2.16709,
        QA_11: 3143.288,
        VA_11: 1.95269,
        QC_11: 3143.288,
        VC_11: 1.95269,
        QE_11: 4519.742,
        VE_11: 2.18091,
        QA_12: 5941.59,
        VA_12: 2.61414,
        QC_12: 5941.59,
        VC_12: 2.61414,
        QE_12: 5650.3,
        VE_12: 2.41658,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87534658490523, 34.783163679343033, 0.0],
          [-80.875777584904611, 34.782676612677108, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288466.0,
        COMID: 9736434,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.154,
        REACHCODE: "03050103001318",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0014066582320109999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059974.0,
        ToNode: 250059975.0,
        Hydroseq: 250005610.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.953,
        TerminalPa: 250002604.0,
        ArbolateSu: 6989.835,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005637.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005598.0,
        FromMeas: 0.0,
        ToMeas: 28.62457,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0369,
        TotDASqKM: 9331.9065,
        DivDASqKM: 9331.9065,
        Tidal: 0,
        TOTMA: 0.0020442398804599998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13276.0,
        MAXELEVSMO: 13359.0,
        MINELEVSMO: 13296.0,
        SLOPE: 0.0040909,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.153999999165535,
        QA_MA: 4679.561,
        VA_MA: 2.83189,
        QC_MA: 4679.561,
        VC_MA: 2.83189,
        QE_MA: 5381.381,
        VE_MA: 2.86062,
        QA_01: 8533.723,
        VA_01: 3.78022,
        QC_01: 8533.723,
        VC_01: 3.78022,
        QE_01: 7098.383,
        VE_01: 3.26491,
        QA_02: 9022.228,
        VA_02: 3.88438,
        QC_02: 9022.228,
        VC_02: 3.88438,
        QE_02: 7211.122,
        VE_02: 3.28975,
        QA_03: 9912.59,
        VA_03: 4.06763,
        QC_03: 9912.59,
        VC_03: 4.06763,
        QE_03: 7676.977,
        VE_03: 3.39052,
        QA_04: 6865.781,
        VA_04: 3.40158,
        QC_04: 6865.781,
        VC_04: 3.40158,
        QE_04: 6644.575,
        VE_04: 3.16299,
        QA_05: 4713.032,
        VA_05: 2.84146,
        QC_05: 4713.032,
        VC_05: 2.84146,
        QE_05: 5698.622,
        VE_05: 2.93944,
        QA_06: 2777.003,
        VA_06: 2.22117,
        QC_06: 2777.003,
        VC_06: 2.22117,
        QE_06: 5015.9,
        VE_06: 2.76706,
        QA_07: 1720.292,
        VA_07: 1.79169,
        QC_07: 1720.292,
        VC_07: 1.79169,
        QE_07: 3811.847,
        VE_07: 2.43358,
        QA_08: 1223.034,
        VA_08: 1.54592,
        QC_08: 1223.034,
        VC_08: 1.54592,
        QE_08: 3560.79,
        VE_08: 2.35807,
        QA_09: 1120.084,
        VA_09: 1.48947,
        QC_09: 1120.084,
        VC_09: 1.48947,
        QE_09: 3264.413,
        VE_09: 2.26564,
        QA_10: 1213.938,
        VA_10: 1.54102,
        QC_10: 1213.938,
        VC_10: 1.54102,
        QE_10: 4457.152,
        VE_10: 2.6176,
        QA_11: 3143.294,
        VA_11: 2.35124,
        QC_11: 3143.294,
        VC_11: 2.35124,
        QE_11: 4519.749,
        VE_11: 2.63477,
        QA_12: 5941.636,
        VA_12: 3.17302,
        QC_12: 5941.636,
        VC_12: 3.17302,
        QE_12: 5650.346,
        VE_12: 2.92758,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875777584904611, 34.782676612677108, 0.0],
          [-80.876039518237462, 34.781352612679143, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287426.0,
        COMID: 9735330,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.162,
        REACHCODE: "03050103000637",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015411115157879999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250059686.0,
        ToNode: 250059699.0,
        Hydroseq: 250016509.0,
        LevelPathI: 250016509.0,
        Pathlength: 376.817,
        TerminalPa: 250002604.0,
        ArbolateSu: 365.133,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016716.0,
        DnLevelPat: 250015946.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250016310.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0315,
        TotDASqKM: 744.8193,
        DivDASqKM: 0.0315,
        Tidal: 0,
        TOTMA: 0.017083437524199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10865.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.019999999552965001,
        QA_MA: 0.01,
        VA_MA: 0.36009,
        QC_MA: 0.01,
        VC_MA: 0.36009,
        QE_MA: 0.01,
        VE_MA: 0.36009,
        QA_01: 0.023,
        VA_01: 0.38885,
        QC_01: 0.023,
        VC_01: 0.38885,
        QE_01: 0.023,
        VE_01: 0.38885,
        QA_02: 0.025,
        VA_02: 0.3925,
        QC_02: 0.025,
        VC_02: 0.3925,
        QE_02: 0.025,
        VE_02: 0.3925,
        QA_03: 0.027,
        VA_03: 0.39601,
        QC_03: 0.027,
        VC_03: 0.39601,
        QE_03: 0.027,
        VE_03: 0.39601,
        QA_04: 0.014,
        VA_04: 0.37021,
        QC_04: 0.014,
        VC_04: 0.37021,
        QE_04: 0.014,
        VE_04: 0.37021,
        QA_05: 0.008,
        VA_05: 0.35432,
        QC_05: 0.008,
        VC_05: 0.35432,
        QE_05: 0.008,
        VE_05: 0.35432,
        QA_06: 0.005,
        VA_06: 0.34417,
        QC_06: 0.005,
        VC_06: 0.34417,
        QE_06: 0.005,
        VE_06: 0.34417,
        QA_07: 0.004,
        VA_07: 0.34018,
        QC_07: 0.004,
        VC_07: 0.34018,
        QE_07: 0.004,
        VE_07: 0.34018,
        QA_08: 0.003,
        VA_08: 0.33567,
        QC_08: 0.003,
        VC_08: 0.33567,
        QE_08: 0.003,
        VE_08: 0.33567,
        QA_09: 0.002,
        VA_09: 0.33039,
        QC_09: 0.002,
        VC_09: 0.33039,
        QE_09: 0.002,
        VE_09: 0.33039,
        QA_10: 0.002,
        VA_10: 0.33039,
        QC_10: 0.002,
        VC_10: 0.33039,
        QE_10: 0.002,
        VE_10: 0.33039,
        QA_11: 0.001,
        VA_11: 0.32361,
        QC_11: 0.001,
        VC_11: 0.32361,
        QE_11: 0.001,
        VE_11: 0.32361,
        QA_12: 0.008,
        VA_12: 0.35432,
        QC_12: 0.008,
        VC_12: 0.35432,
        QE_12: 0.008,
        VE_12: 0.35432,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.898763718202247, 34.607569479615449, 0.0],
          [-80.898375384869553, 34.606378479617433, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 286377.0,
        COMID: 9747200,
        FDATE: "1999/10/12 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "995144",
        GNIS_NAME: "South Fork Catawba River",
        LENGTHKM: 2.746,
        REACHCODE: "03050102000193",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.026879242112269001,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 5,
        FromNode: 250060410.0,
        ToNode: 250060411.0,
        Hydroseq: 250013925.0,
        LevelPathI: 250011553.0,
        Pathlength: 516.299,
        TerminalPa: 250002604.0,
        ArbolateSu: 699.672,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250011553.0,
        UpHydroseq: 250014035.0,
        DnLevelPat: 250011553.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013803.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.4281,
        TotDASqKM: 772.4439,
        DivDASqKM: 772.4439,
        Tidal: 0,
        TOTMA: 0.077507374007900004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 22679.0,
        MAXELEVSMO: 22884.0,
        MINELEVSMO: 22737.0,
        SLOPE: 0.00053532,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.7460000514984131,
        QA_MA: 426.171,
        VA_MA: 1.34669,
        QC_MA: 408.044,
        VC_MA: 1.34304,
        QE_MA: 419.337,
        VE_MA: 1.34533,
        QA_01: 759.862,
        VA_01: 1.7199,
        QC_01: 579.138,
        VC_01: 1.55447,
        QE_01: 582.039,
        VE_01: 1.54252,
        QA_02: 793.423,
        VA_02: 1.75266,
        QC_02: 644.39,
        VC_02: 1.62715,
        QE_02: 640.849,
        VE_02: 1.60724,
        QA_03: 855.849,
        VA_03: 1.81193,
        QC_03: 697.358,
        VC_03: 1.68364,
        QE_03: 714.213,
        VE_03: 1.68419,
        QA_04: 615.974,
        VA_04: 1.571,
        QC_04: 557.048,
        VC_04: 1.529,
        QE_04: 591.924,
        VE_04: 1.55361,
        QA_05: 451.871,
        VA_05: 1.37948,
        QC_05: 408.253,
        VC_05: 1.34332,
        QE_05: 436.978,
        VE_05: 1.36827,
        QA_06: 259.052,
        VA_06: 1.10551,
        QC_06: 320.295,
        VC_06: 1.21821,
        QE_06: 353.09,
        VE_06: 1.25484,
        QA_07: 154.746,
        VA_07: 0.91471,
        QC_07: 228.8,
        VC_07: 1.06938,
        QE_07: 256.576,
        VE_07: 1.10724,
        QA_08: 107.192,
        VA_08: 0.80731,
        QC_08: 244.178,
        VC_08: 1.09613,
        QE_08: 265.682,
        VE_08: 1.12217,
        QA_09: 94.2,
        VA_09: 0.77422,
        QC_09: 216.394,
        VC_09: 1.04718,
        QE_09: 206.708,
        VE_09: 1.02063,
        QA_10: 121.07,
        VA_10: 0.84062,
        QC_10: 253.724,
        VC_10: 1.11233,
        QE_10: 198.288,
        VE_10: 1.00508,
        QA_11: 342.273,
        VA_11: 1.23259,
        QC_11: 327.504,
        VC_11: 1.22903,
        QE_11: 261.169,
        VE_11: 1.1148,
        QA_12: 561.406,
        VA_12: 1.51032,
        QC_12: 419.354,
        VC_12: 1.35817,
        QE_12: 397.05,
        VE_12: 1.31569,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.283697517604764, 35.480703611593526, 0.0],
          [-81.283559317604897, 35.479077278262764, 0.0],
          [-81.275192984284615, 35.474444478269845, 0.0],
          [-81.273940917619882, 35.468053611613186, 0.0],
          [-81.271868584289791, 35.468510078279053, 0.0],
          [-81.270687184291603, 35.472952278272146, 0.0],
          [-81.269259317627132, 35.473088478272075, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287459.0,
        COMID: 9734452,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.173,
        REACHCODE: "03050103000656",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001563918093762,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059392.0,
        ToNode: 250059397.0,
        Hydroseq: 250005467.0,
        LevelPathI: 250005467.0,
        Pathlength: 398.032,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.55,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005479.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005457.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 9333.9297,
        DivDASqKM: 0.0207,
        Tidal: 0,
        TOTMA: 0.0184483537026,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13035.0,
        MAXELEVSMO: 13035.0,
        MINELEVSMO: 13035.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.023000000044703001,
        QA_MA: 0.008,
        VA_MA: 0.35609,
        QC_MA: 0.008,
        VC_MA: 0.35609,
        QE_MA: 0.008,
        VE_MA: 0.35609,
        QA_01: 0.017,
        VA_01: 0.37957,
        QC_01: 0.017,
        VC_01: 0.37957,
        QE_01: 0.017,
        VE_01: 0.37957,
        QA_02: 0.018,
        VA_02: 0.38177,
        QC_02: 0.018,
        VC_02: 0.38177,
        QE_02: 0.018,
        VE_02: 0.38177,
        QA_03: 0.02,
        VA_03: 0.38599,
        QC_03: 0.02,
        VC_03: 0.38599,
        QE_03: 0.02,
        VE_03: 0.38599,
        QA_04: 0.011,
        VA_04: 0.36488,
        QC_04: 0.011,
        VC_04: 0.36488,
        QE_04: 0.011,
        VE_04: 0.36488,
        QA_05: 0.006,
        VA_05: 0.34934,
        QC_05: 0.006,
        VC_05: 0.34934,
        QE_05: 0.006,
        VE_05: 0.34934,
        QA_06: 0.004,
        VA_06: 0.3414,
        QC_06: 0.004,
        VC_06: 0.3414,
        QE_06: 0.004,
        VE_06: 0.3414,
        QA_07: 0.003,
        VA_07: 0.33673,
        QC_07: 0.003,
        VC_07: 0.33673,
        QE_07: 0.003,
        VE_07: 0.33673,
        QA_08: 0.002,
        VA_08: 0.33124,
        QC_08: 0.002,
        VC_08: 0.33124,
        QE_08: 0.002,
        VE_08: 0.33124,
        QA_09: 0.001,
        VA_09: 0.3242,
        QC_09: 0.001,
        VC_09: 0.3242,
        QE_09: 0.001,
        VE_09: 0.3242,
        QA_10: 0.001,
        VA_10: 0.3242,
        QC_10: 0.001,
        VC_10: 0.3242,
        QE_10: 0.001,
        VE_10: 0.3242,
        QA_11: 0.001,
        VA_11: 0.3242,
        QC_11: 0.001,
        VC_11: 0.3242,
        QE_11: 0.001,
        VE_11: 0.3242,
        QA_12: 0.009,
        VA_12: 0.35917,
        QC_12: 0.009,
        VC_12: 0.35917,
        QE_12: 0.009,
        VE_12: 0.35917,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877377518235448, 34.774517412689647, 0.0],
          [-80.877294184902269, 34.773028479358686, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287460.0,
        COMID: 9734410,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.15,
        REACHCODE: "03050103000659",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001370407555199,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059376.0,
        ToNode: 250060136.0,
        Hydroseq: 250005536.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.65,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.33,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005546.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005522.0,
        FromMeas: 22.7807,
        ToMeas: 64.30247,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0279,
        TotDASqKM: 9332.8623,
        DivDASqKM: 0.0459,
        Tidal: 0,
        TOTMA: 0.012645759522499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13188.0,
        MAXELEVSMO: 13233.0,
        MINELEVSMO: 13188.0,
        SLOPE: 0.003,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15000000596046401,
        QA_MA: 0.018,
        VA_MA: 0.45042,
        QC_MA: 0.018,
        VC_MA: 0.45042,
        QE_MA: 0.018,
        VE_MA: 0.45042,
        QA_01: 0.039,
        VA_01: 0.52253,
        QC_01: 0.039,
        VC_01: 0.52253,
        QE_01: 0.039,
        VE_01: 0.52253,
        QA_02: 0.041,
        VA_02: 0.52829,
        QC_02: 0.041,
        VC_02: 0.52829,
        QE_02: 0.041,
        VE_02: 0.52829,
        QA_03: 0.044,
        VA_03: 0.53669,
        QC_03: 0.044,
        VC_03: 0.53669,
        QE_03: 0.044,
        VE_03: 0.53669,
        QA_04: 0.025,
        VA_04: 0.47749,
        QC_04: 0.025,
        VC_04: 0.47749,
        QE_04: 0.025,
        VE_04: 0.47749,
        QA_05: 0.014,
        VA_05: 0.43268,
        QC_05: 0.014,
        VC_05: 0.43268,
        QE_05: 0.014,
        VE_05: 0.43268,
        QA_06: 0.009,
        VA_06: 0.40669,
        QC_06: 0.009,
        VC_06: 0.40669,
        QE_06: 0.009,
        VE_06: 0.40669,
        QA_07: 0.006,
        VA_07: 0.38765,
        QC_07: 0.006,
        VC_07: 0.38765,
        QE_07: 0.006,
        VE_07: 0.38765,
        QA_08: 0.004,
        VA_08: 0.37229,
        QC_08: 0.004,
        VC_08: 0.37229,
        QE_08: 0.004,
        VE_08: 0.37229,
        QA_09: 0.003,
        VA_09: 0.36324,
        QC_09: 0.003,
        VC_09: 0.36324,
        QE_09: 0.003,
        VE_09: 0.36324,
        QA_10: 0.003,
        VA_10: 0.36324,
        QC_10: 0.003,
        VC_10: 0.36324,
        QE_10: 0.003,
        VE_10: 0.36324,
        QA_11: 0.003,
        VA_11: 0.36324,
        QC_11: 0.003,
        VC_11: 0.36324,
        QE_11: 0.003,
        VE_11: 0.36324,
        QA_12: 0.02,
        VA_12: 0.4586,
        QC_12: 0.02,
        VC_12: 0.4586,
        QE_12: 0.02,
        VE_12: 0.4586,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877544318235167, 34.779465212682112, 0.0],
          [-80.877849718234756, 34.778182412683982, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287461.0,
        COMID: 9740418,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.128,
        REACHCODE: "03050103000659",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0013538942681920001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060133.0,
        ToNode: 250059376.0,
        Hydroseq: 250005546.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.8,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.18,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005565.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005536.0,
        FromMeas: 64.30247,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.018,
        TotDASqKM: 9332.8344,
        DivDASqKM: 0.018,
        Tidal: 0,
        TOTMA: 0.0123488412972,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13233.0,
        MAXELEVSMO: 13239.0,
        MINELEVSMO: 13233.0,
        SLOPE: 0.00046875,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.12800000607967399,
        QA_MA: 0.007,
        VA_MA: 0.3936,
        QC_MA: 0.007,
        VC_MA: 0.3936,
        QE_MA: 0.007,
        VE_MA: 0.3936,
        QA_01: 0.015,
        VA_01: 0.43611,
        QC_01: 0.015,
        VC_01: 0.43611,
        QE_01: 0.015,
        VE_01: 0.43611,
        QA_02: 0.016,
        VA_02: 0.44057,
        QC_02: 0.016,
        VC_02: 0.44057,
        QE_02: 0.016,
        VE_02: 0.44057,
        QA_03: 0.017,
        VA_03: 0.44489,
        QC_03: 0.017,
        VC_03: 0.44489,
        QE_03: 0.017,
        VE_03: 0.44489,
        QA_04: 0.01,
        VA_04: 0.41137,
        QC_04: 0.01,
        VC_04: 0.41137,
        QE_04: 0.01,
        VE_04: 0.41137,
        QA_05: 0.005,
        VA_05: 0.37966,
        QC_05: 0.005,
        VC_05: 0.37966,
        QE_05: 0.005,
        VE_05: 0.37966,
        QA_06: 0.003,
        VA_06: 0.36273,
        QC_06: 0.003,
        VC_06: 0.36273,
        QE_06: 0.003,
        VE_06: 0.36273,
        QA_07: 0.002,
        VA_07: 0.3522,
        QC_07: 0.002,
        VC_07: 0.3522,
        QE_07: 0.002,
        VE_07: 0.3522,
        QA_08: 0.001,
        VA_08: 0.33871,
        QC_08: 0.001,
        VC_08: 0.33871,
        QE_08: 0.001,
        VE_08: 0.33871,
        QA_09: 0.001,
        VA_09: 0.33871,
        QC_09: 0.001,
        VC_09: 0.33871,
        QE_09: 0.001,
        VE_09: 0.33871,
        QA_10: 0.001,
        VA_10: 0.33871,
        QC_10: 0.001,
        VC_10: 0.33871,
        QE_10: 0.001,
        VE_10: 0.33871,
        QA_11: 0.001,
        VA_11: 0.33871,
        QC_11: 0.001,
        VC_11: 0.33871,
        QE_11: 0.001,
        VE_11: 0.33871,
        QA_12: 0.008,
        VA_12: 0.39987,
        QC_12: 0.008,
        VC_12: 0.39987,
        QE_12: 0.008,
        VE_12: 0.39987,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876306984903806, 34.779421212682053, 0.0],
          [-80.877544318235167, 34.779465212682112, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287462.0,
        COMID: 9740428,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.082,
        REACHCODE: "03050103000659",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00077771738694300003,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060136.0,
        ToNode: 250060138.0,
        Hydroseq: 250005522.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.568,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.412,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005536.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005512.0,
        FromMeas: 0.0,
        ToMeas: 22.7807,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 9332.8713,
        DivDASqKM: 0.0549,
        Tidal: 0,
        TOTMA: 0.0068662159500599996,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13150.0,
        MAXELEVSMO: 13188.0,
        MINELEVSMO: 13166.0,
        SLOPE: 0.00268292,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.082000002264977001,
        QA_MA: 0.021,
        VA_MA: 0.45349,
        QC_MA: 0.021,
        VC_MA: 0.45349,
        QE_MA: 0.021,
        VE_MA: 0.45349,
        QA_01: 0.047,
        VA_01: 0.53095,
        QC_01: 0.047,
        VC_01: 0.53095,
        QE_01: 0.047,
        VE_01: 0.53095,
        QA_02: 0.049,
        VA_02: 0.53593,
        QC_02: 0.049,
        VC_02: 0.53593,
        QE_02: 0.049,
        VE_02: 0.53593,
        QA_03: 0.053,
        VA_03: 0.54561,
        QC_03: 0.053,
        VC_03: 0.54561,
        QE_03: 0.053,
        VE_03: 0.54561,
        QA_04: 0.031,
        VA_04: 0.48682,
        QC_04: 0.031,
        VC_04: 0.48682,
        QE_04: 0.031,
        VE_04: 0.48682,
        QA_05: 0.016,
        VA_05: 0.43398,
        QC_05: 0.016,
        VC_05: 0.43398,
        QE_05: 0.016,
        VE_05: 0.43398,
        QA_06: 0.011,
        VA_06: 0.41132,
        QC_06: 0.011,
        VC_06: 0.41132,
        QE_06: 0.011,
        VE_06: 0.41132,
        QA_07: 0.008,
        VA_07: 0.39531,
        QC_07: 0.008,
        VC_07: 0.39531,
        QE_07: 0.008,
        VE_07: 0.39531,
        QA_08: 0.005,
        VA_08: 0.37611,
        QC_08: 0.005,
        VC_08: 0.37611,
        QE_08: 0.005,
        VE_08: 0.37611,
        QA_09: 0.004,
        VA_09: 0.36854,
        QC_09: 0.004,
        VC_09: 0.36854,
        QE_09: 0.004,
        VE_09: 0.36854,
        QA_10: 0.003,
        VA_10: 0.36002,
        QC_10: 0.003,
        VC_10: 0.36002,
        QE_10: 0.003,
        VE_10: 0.36002,
        QA_11: 0.003,
        VA_11: 0.36002,
        QC_11: 0.003,
        VC_11: 0.36002,
        QE_11: 0.003,
        VE_11: 0.36002,
        QA_12: 0.025,
        VA_12: 0.46756,
        QC_12: 0.025,
        VC_12: 0.46756,
        QE_12: 0.025,
        VE_12: 0.46756,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877849718234756, 34.778182412683982, 0.0],
          [-80.877450984901998, 34.777539279351629, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287456.0,
        COMID: 9734462,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.122,
        REACHCODE: "03050103000653",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010998614365980001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059397.0,
        ToNode: 250093426.0,
        Hydroseq: 250005457.0,
        LevelPathI: 250005443.0,
        Pathlength: 397.91,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.859,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005468.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005443.0,
        FromMeas: 77.06078,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0117,
        TotDASqKM: 9333.963,
        DivDASqKM: 1.1241,
        Tidal: 0,
        TOTMA: 0.00715878980213,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12950.0,
        MAXELEVSMO: 13035.0,
        MINELEVSMO: 12950.0,
        SLOPE: 0.00696721,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.122000001370907,
        QA_MA: 0.443,
        VA_MA: 0.64713,
        QC_MA: 0.443,
        VC_MA: 0.64713,
        QE_MA: 0.443,
        VE_MA: 0.64713,
        QA_01: 0.967,
        VA_01: 0.82112,
        QC_01: 0.967,
        VC_01: 0.82112,
        QE_01: 0.967,
        VE_01: 0.82112,
        QA_02: 1.013,
        VA_02: 0.83393,
        QC_02: 1.013,
        VC_02: 0.83393,
        QE_02: 1.013,
        VE_02: 0.83393,
        QA_03: 1.088,
        VA_03: 0.85424,
        QC_03: 1.088,
        VC_03: 0.85424,
        QE_03: 1.088,
        VE_03: 0.85424,
        QA_04: 0.634,
        VA_04: 0.71816,
        QC_04: 0.634,
        VC_04: 0.71816,
        QE_04: 0.634,
        VE_04: 0.71816,
        QA_05: 0.347,
        VA_05: 0.60593,
        QC_05: 0.347,
        VC_05: 0.60593,
        QE_05: 0.347,
        VE_05: 0.60593,
        QA_06: 0.226,
        VA_06: 0.54534,
        QC_06: 0.226,
        VC_06: 0.54534,
        QE_06: 0.226,
        VE_06: 0.54534,
        QA_07: 0.166,
        VA_07: 0.50953,
        QC_07: 0.166,
        VC_07: 0.50953,
        QE_07: 0.166,
        VE_07: 0.50953,
        QA_08: 0.12,
        VA_08: 0.4777,
        QC_08: 0.12,
        VC_08: 0.4777,
        QE_08: 0.12,
        VE_08: 0.4777,
        QA_09: 0.09,
        VA_09: 0.45371,
        QC_09: 0.09,
        VC_09: 0.45371,
        QE_09: 0.09,
        VE_09: 0.45371,
        QA_10: 0.075,
        VA_10: 0.4403,
        QC_10: 0.075,
        VC_10: 0.4403,
        QE_10: 0.075,
        VE_10: 0.4403,
        QA_11: 0.075,
        VA_11: 0.4403,
        QC_11: 0.075,
        VC_11: 0.4403,
        QE_11: 0.075,
        VE_11: 0.4403,
        QA_12: 0.513,
        VA_12: 0.67457,
        QC_12: 0.513,
        VC_12: 0.67457,
        QE_12: 0.513,
        VE_12: 0.67457,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877294184902269, 34.773028479358686, 0.0],
          [-80.877210918235733, 34.771951879360415, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287457.0,
        COMID: 25122799,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.409,
        REACHCODE: "03050103000653",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0038902083005100002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250093426.0,
        ToNode: 250059982.0,
        Hydroseq: 250005443.0,
        LevelPathI: 250005443.0,
        Pathlength: 397.501,
        TerminalPa: 250002604.0,
        ArbolateSu: 6993.268,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005457.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005433.0,
        FromMeas: 0.0,
        ToMeas: 77.06078,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4311,
        TotDASqKM: 9334.3941,
        DivDASqKM: 1.5552,
        Tidal: 0,
        TOTMA: 0.023937775267899999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12766.0,
        MAXELEVSMO: 12950.0,
        MINELEVSMO: 12766.0,
        SLOPE: 0.00449877,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.40900000929832497,
        QA_MA: 0.612,
        VA_MA: 0.6488,
        QC_MA: 0.612,
        VC_MA: 0.6488,
        QE_MA: 0.612,
        VE_MA: 0.6488,
        QA_01: 1.339,
        VA_01: 0.82428,
        QC_01: 1.339,
        VC_01: 0.82428,
        QE_01: 1.339,
        VE_01: 0.82428,
        QA_02: 1.401,
        VA_02: 0.83683,
        QC_02: 1.401,
        VC_02: 0.83683,
        QE_02: 1.401,
        VE_02: 0.83683,
        QA_03: 1.506,
        VA_03: 0.8575,
        QC_03: 1.506,
        VC_03: 0.8575,
        QE_03: 1.506,
        VE_03: 0.8575,
        QA_04: 0.878,
        VA_04: 0.72071,
        QC_04: 0.878,
        VC_04: 0.72071,
        QE_04: 0.878,
        VE_04: 0.72071,
        QA_05: 0.48,
        VA_05: 0.6076,
        QC_05: 0.48,
        VC_05: 0.6076,
        QE_05: 0.48,
        VE_05: 0.6076,
        QA_06: 0.313,
        VA_06: 0.54683,
        QC_06: 0.313,
        VC_06: 0.54683,
        QE_06: 0.313,
        VE_06: 0.54683,
        QA_07: 0.23,
        VA_07: 0.51084,
        QC_07: 0.23,
        VC_07: 0.51084,
        QE_07: 0.23,
        VE_07: 0.51084,
        QA_08: 0.167,
        VA_08: 0.47919,
        QC_08: 0.167,
        VC_08: 0.47919,
        QE_08: 0.167,
        VE_08: 0.47919,
        QA_09: 0.125,
        VA_09: 0.45484,
        QC_09: 0.125,
        VC_09: 0.45484,
        QE_09: 0.125,
        VE_09: 0.45484,
        QA_10: 0.104,
        VA_10: 0.44122,
        QC_10: 0.104,
        VC_10: 0.44122,
        QE_10: 0.104,
        VE_10: 0.44122,
        QA_11: 0.104,
        VA_11: 0.44122,
        QC_11: 0.104,
        VC_11: 0.44122,
        QE_11: 0.104,
        VE_11: 0.44122,
        QA_12: 0.704,
        VA_12: 0.67508,
        QC_12: 0.704,
        VC_12: 0.67508,
        QE_12: 0.704,
        VE_12: 0.67508,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877210918235733, 34.771951879360415, 0.0],
          [-80.875322584905291, 34.768900279365141, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287458.0,
        COMID: 9734450,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.187,
        REACHCODE: "03050103000654",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0017445717359769999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059392.0,
        ToNode: 250059397.0,
        Hydroseq: 250005468.0,
        LevelPathI: 250005443.0,
        Pathlength: 398.032,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.564,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005443.0,
        UpHydroseq: 250005479.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005457.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0216,
        TotDASqKM: 9333.9306,
        DivDASqKM: 1.0917,
        Tidal: 0,
        TOTMA: 0.0166215499967,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12953.0,
        MAXELEVSMO: 13035.0,
        MINELEVSMO: 13035.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.187000006437302,
        QA_MA: 0.43,
        VA_MA: 0.42721,
        QC_MA: 0.43,
        VC_MA: 0.42721,
        QE_MA: 0.43,
        VE_MA: 0.42721,
        QA_01: 0.939,
        VA_01: 0.48828,
        QC_01: 0.939,
        VC_01: 0.48828,
        QE_01: 0.939,
        VE_01: 0.48828,
        QA_02: 0.983,
        VA_02: 0.4927,
        QC_02: 0.983,
        VC_02: 0.4927,
        QE_02: 0.983,
        VE_02: 0.4927,
        QA_03: 1.057,
        VA_03: 0.49995,
        QC_03: 1.057,
        VC_03: 0.49995,
        QE_03: 1.057,
        VE_03: 0.49995,
        QA_04: 0.616,
        VA_04: 0.4522,
        QC_04: 0.616,
        VC_04: 0.4522,
        QE_04: 0.616,
        VE_04: 0.4522,
        QA_05: 0.337,
        VA_05: 0.41279,
        QC_05: 0.337,
        VC_05: 0.41279,
        QE_05: 0.337,
        VE_05: 0.41279,
        QA_06: 0.22,
        VA_06: 0.39163,
        QC_06: 0.22,
        VC_06: 0.39163,
        QE_06: 0.22,
        VE_06: 0.39163,
        QA_07: 0.161,
        VA_07: 0.37891,
        QC_07: 0.161,
        VC_07: 0.37891,
        QE_07: 0.161,
        VE_07: 0.37891,
        QA_08: 0.117,
        VA_08: 0.36792,
        QC_08: 0.117,
        VC_08: 0.36792,
        QE_08: 0.117,
        VE_08: 0.36792,
        QA_09: 0.088,
        VA_09: 0.35956,
        QC_09: 0.088,
        VC_09: 0.35956,
        QE_09: 0.088,
        VE_09: 0.35956,
        QA_10: 0.073,
        VA_10: 0.35473,
        QC_10: 0.073,
        VC_10: 0.35473,
        QE_10: 0.073,
        VE_10: 0.35473,
        QA_11: 0.073,
        VA_11: 0.35473,
        QC_11: 0.073,
        VC_11: 0.35473,
        QE_11: 0.073,
        VE_11: 0.35473,
        QA_12: 0.498,
        VA_12: 0.43684,
        QC_12: 0.498,
        VC_12: 0.43684,
        QE_12: 0.498,
        VE_12: 0.43684,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877377518235448, 34.774517412689647, 0.0],
          [-80.877294184902269, 34.773028479358686, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287435.0,
        COMID: 9734938,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.102,
        REACHCODE: "03050103000643",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001046394365121,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059561.0,
        ToNode: 250060025.0,
        Hydroseq: 250005170.0,
        LevelPathI: 250005161.0,
        Pathlength: 387.916,
        TerminalPa: 250002604.0,
        ArbolateSu: 7510.386,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005161.0,
        UpHydroseq: 250005180.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005161.0,
        FromMeas: 53.62666,
        ToMeas: 90.18402,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0432,
        TotDASqKM: 9815.7033,
        DivDASqKM: 0.495,
        Tidal: 0,
        TOTMA: 0.0095134571671499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12640.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.10199999809265101,
        QA_MA: 0.187,
        VA_MA: 0.40713,
        QC_MA: 0.187,
        VC_MA: 0.40713,
        QE_MA: 0.187,
        VE_MA: 0.40713,
        QA_01: 0.412,
        VA_01: 0.45859,
        QC_01: 0.412,
        VC_01: 0.45859,
        QE_01: 0.412,
        VE_01: 0.45859,
        QA_02: 0.439,
        VA_02: 0.46373,
        QC_02: 0.439,
        VC_02: 0.46373,
        QE_02: 0.439,
        VE_02: 0.46373,
        QA_03: 0.472,
        VA_03: 0.46983,
        QC_03: 0.472,
        VC_03: 0.46983,
        QE_03: 0.472,
        VE_03: 0.46983,
        QA_04: 0.266,
        VA_04: 0.42745,
        QC_04: 0.266,
        VC_04: 0.42745,
        QE_04: 0.266,
        VE_04: 0.42745,
        QA_05: 0.146,
        VA_05: 0.39497,
        QC_05: 0.146,
        VC_05: 0.39497,
        QE_05: 0.146,
        VE_05: 0.39497,
        QA_06: 0.093,
        VA_06: 0.37653,
        QC_06: 0.093,
        VC_06: 0.37653,
        QE_06: 0.093,
        VE_06: 0.37653,
        QA_07: 0.066,
        VA_07: 0.36519,
        QC_07: 0.066,
        VC_07: 0.36519,
        QE_07: 0.066,
        VE_07: 0.36519,
        QA_08: 0.053,
        VA_08: 0.35894,
        QC_08: 0.053,
        VC_08: 0.35894,
        QE_08: 0.053,
        VE_08: 0.35894,
        QA_09: 0.039,
        VA_09: 0.35134,
        QC_09: 0.039,
        VC_09: 0.35134,
        QE_09: 0.039,
        VE_09: 0.35134,
        QA_10: 0.033,
        VA_10: 0.3477,
        QC_10: 0.033,
        VC_10: 0.3477,
        QE_10: 0.033,
        VE_10: 0.3477,
        QA_11: 0.026,
        VA_11: 0.34302,
        QC_11: 0.026,
        VC_11: 0.34302,
        QE_11: 0.026,
        VE_11: 0.34302,
        QA_12: 0.201,
        VA_12: 0.41099,
        QC_12: 0.201,
        VC_12: 0.41099,
        QE_12: 0.201,
        VE_12: 0.41099,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.866116384919565, 34.692806279483307, 0.0],
          [-80.86694838491826, 34.692279612817288, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287436.0,
        COMID: 9736538,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.027,
        REACHCODE: "03050103000643",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00029022087389400002,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060021.0,
        ToNode: 250059561.0,
        Hydroseq: 250005180.0,
        LevelPathI: 250005161.0,
        Pathlength: 388.018,
        TerminalPa: 250002604.0,
        ArbolateSu: 7510.284,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005161.0,
        UpHydroseq: 250005189.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005170.0,
        FromMeas: 90.18402,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9815.6601,
        DivDASqKM: 0.4518,
        Tidal: 0,
        TOTMA: 0.0034509004597599998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.027000000700354999,
        QA_MA: 0.171,
        VA_MA: 0.2971,
        QC_MA: 0.171,
        VC_MA: 0.2971,
        QE_MA: 0.171,
        VE_MA: 0.2971,
        QA_01: 0.376,
        VA_01: 0.41846,
        QC_01: 0.376,
        VC_01: 0.41846,
        QE_01: 0.376,
        VE_01: 0.41846,
        QA_02: 0.4,
        VA_02: 0.43034,
        QC_02: 0.4,
        VC_02: 0.43034,
        QE_02: 0.4,
        VE_02: 0.43034,
        QA_03: 0.431,
        VA_03: 0.4452,
        QC_03: 0.431,
        VC_03: 0.4452,
        QE_03: 0.431,
        VE_03: 0.4452,
        QA_04: 0.242,
        VA_04: 0.34436,
        QC_04: 0.242,
        VC_04: 0.34436,
        QE_04: 0.242,
        VE_04: 0.34436,
        QA_05: 0.133,
        VA_05: 0.26797,
        QC_05: 0.133,
        VC_05: 0.26797,
        QE_05: 0.133,
        VE_05: 0.26797,
        QA_06: 0.085,
        VA_06: 0.22487,
        QC_06: 0.085,
        VC_06: 0.22487,
        QE_06: 0.085,
        VE_06: 0.22487,
        QA_07: 0.06,
        VA_07: 0.1978,
        QC_07: 0.06,
        VC_07: 0.1978,
        QE_07: 0.06,
        VE_07: 0.1978,
        QA_08: 0.048,
        VA_08: 0.18292,
        QC_08: 0.048,
        VC_08: 0.18292,
        QE_08: 0.048,
        VE_08: 0.18292,
        QA_09: 0.036,
        VA_09: 0.16619,
        QC_09: 0.036,
        VC_09: 0.16619,
        QE_09: 0.036,
        VE_09: 0.16619,
        QA_10: 0.03,
        VA_10: 0.15684,
        QC_10: 0.03,
        VC_10: 0.15684,
        QE_10: 0.03,
        VE_10: 0.15684,
        QA_11: 0.024,
        VA_11: 0.14657,
        QC_11: 0.024,
        VC_11: 0.14657,
        QE_11: 0.024,
        VE_11: 0.14657,
        QA_12: 0.184,
        VA_12: 0.30635,
        QC_12: 0.184,
        VC_12: 0.30635,
        QE_12: 0.184,
        VE_12: 0.30635,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865847584920004, 34.69289547948307, 0.0],
          [-80.866116384919565, 34.692806279483307, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287437.0,
        COMID: 9736546,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.151,
        REACHCODE: "03050103000643",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00135410037422,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060025.0,
        ToNode: 250060026.0,
        Hydroseq: 250005161.0,
        LevelPathI: 250005161.0,
        Pathlength: 387.765,
        TerminalPa: 250002604.0,
        ArbolateSu: 7510.537,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005161.0,
        UpHydroseq: 250005170.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005151.0,
        FromMeas: 0.0,
        ToMeas: 53.62666,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0468,
        TotDASqKM: 9815.7501,
        DivDASqKM: 0.5418,
        Tidal: 0,
        TOTMA: 0.0140120603123,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12644.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15099999308586101,
        QA_MA: 0.205,
        VA_MA: 0.40921,
        QC_MA: 0.205,
        VC_MA: 0.40921,
        QE_MA: 0.205,
        VE_MA: 0.40921,
        QA_01: 0.451,
        VA_01: 0.46164,
        QC_01: 0.451,
        VC_01: 0.46164,
        QE_01: 0.451,
        VE_01: 0.46164,
        QA_02: 0.48,
        VA_02: 0.46679,
        QC_02: 0.48,
        VC_02: 0.46679,
        QE_02: 0.48,
        VE_02: 0.46679,
        QA_03: 0.517,
        VA_03: 0.47316,
        QC_03: 0.517,
        VC_03: 0.47316,
        QE_03: 0.517,
        VE_03: 0.47316,
        QA_04: 0.291,
        VA_04: 0.42983,
        QC_04: 0.291,
        VC_04: 0.42983,
        QE_04: 0.291,
        VE_04: 0.42983,
        QA_05: 0.16,
        VA_05: 0.39678,
        QC_05: 0.16,
        VC_05: 0.39678,
        QE_05: 0.16,
        VE_05: 0.39678,
        QA_06: 0.101,
        VA_06: 0.37762,
        QC_06: 0.101,
        VC_06: 0.37762,
        QE_06: 0.101,
        VE_06: 0.37762,
        QA_07: 0.072,
        VA_07: 0.36624,
        QC_07: 0.072,
        VC_07: 0.36624,
        QE_07: 0.072,
        VE_07: 0.36624,
        QA_08: 0.058,
        VA_08: 0.35996,
        QC_08: 0.058,
        VC_08: 0.35996,
        QE_08: 0.058,
        VE_08: 0.35996,
        QA_09: 0.043,
        VA_09: 0.35238,
        QC_09: 0.043,
        VC_09: 0.35238,
        QE_09: 0.043,
        VE_09: 0.35238,
        QA_10: 0.036,
        VA_10: 0.34842,
        QC_10: 0.036,
        VC_10: 0.34842,
        QE_10: 0.036,
        VE_10: 0.34842,
        QA_11: 0.029,
        VA_11: 0.34408,
        QC_11: 0.029,
        VC_11: 0.34408,
        QE_11: 0.029,
        VE_11: 0.34408,
        QA_12: 0.22,
        VA_12: 0.41307,
        QC_12: 0.22,
        VC_12: 0.41307,
        QE_12: 0.22,
        VE_12: 0.41307,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.86694838491826, 34.692279612817288, 0.0],
          [-80.866986718251553, 34.690938812819525, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287438.0,
        COMID: 9734932,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.186,
        REACHCODE: "03050103000644",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00184732222894,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059558.0,
        ToNode: 250060020.0,
        Hydroseq: 250005200.0,
        LevelPathI: 250005161.0,
        Pathlength: 388.073,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.932,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005161.0,
        UpHydroseq: 250005209.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005189.0,
        FromMeas: 6.53872,
        ToMeas: 50.71082,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.4176,
        TotDASqKM: 9815.5233,
        DivDASqKM: 0.4473,
        Tidal: 0,
        TOTMA: 0.015726130236800001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12637.0,
        MAXELEVSMO: 12651.0,
        MINELEVSMO: 12649.0,
        SLOPE: 0.00010752,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18600000441074399,
        QA_MA: 0.169,
        VA_MA: 0.44912,
        QC_MA: 0.169,
        VC_MA: 0.44912,
        QE_MA: 0.169,
        VE_MA: 0.44912,
        QA_01: 0.372,
        VA_01: 0.52236,
        QC_01: 0.372,
        VC_01: 0.52236,
        QE_01: 0.372,
        VE_01: 0.52236,
        QA_02: 0.396,
        VA_02: 0.52958,
        QC_02: 0.396,
        VC_02: 0.52958,
        QE_02: 0.396,
        VE_02: 0.52958,
        QA_03: 0.426,
        VA_03: 0.53832,
        QC_03: 0.426,
        VC_03: 0.53832,
        QE_03: 0.426,
        VE_03: 0.53832,
        QA_04: 0.24,
        VA_04: 0.47793,
        QC_04: 0.24,
        VC_04: 0.47793,
        QE_04: 0.24,
        VE_04: 0.47793,
        QA_05: 0.132,
        VA_05: 0.43182,
        QC_05: 0.132,
        VC_05: 0.43182,
        QE_05: 0.132,
        VE_05: 0.43182,
        QA_06: 0.084,
        VA_06: 0.40548,
        QC_06: 0.084,
        VC_06: 0.40548,
        QE_06: 0.084,
        VE_06: 0.40548,
        QA_07: 0.06,
        VA_07: 0.3896,
        QC_07: 0.06,
        VC_07: 0.3896,
        QE_07: 0.06,
        VE_07: 0.3896,
        QA_08: 0.048,
        VA_08: 0.38052,
        QC_08: 0.048,
        VC_08: 0.38052,
        QE_08: 0.048,
        VE_08: 0.38052,
        QA_09: 0.036,
        VA_09: 0.3703,
        QC_09: 0.036,
        VC_09: 0.3703,
        QE_09: 0.036,
        VE_09: 0.3703,
        QA_10: 0.03,
        VA_10: 0.36459,
        QC_10: 0.03,
        VC_10: 0.36459,
        QE_10: 0.03,
        VE_10: 0.36459,
        QA_11: 0.024,
        VA_11: 0.35831,
        QC_11: 0.024,
        VC_11: 0.35831,
        QE_11: 0.024,
        VE_11: 0.35831,
        QA_12: 0.182,
        VA_12: 0.45477,
        QC_12: 0.182,
        VC_12: 0.45477,
        QE_12: 0.182,
        VE_12: 0.45477,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.864424518255476, 34.694295212814268, 0.0],
          [-80.865755918253456, 34.693127012816035, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288419.0,
        COMID: 9735468,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.141,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001310536865112,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059711.0,
        ToNode: 250059713.0,
        Hydroseq: 250004808.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.671,
        TerminalPa: 250002604.0,
        ArbolateSu: 7553.741,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004955.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004785.0,
        DnDrainCou: 2,
        DnHydroseq: 250004787.0,
        FromMeas: 74.48074,
        ToMeas: 82.1783,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0333,
        TotDASqKM: 9867.4182,
        DivDASqKM: 0.1764,
        Tidal: 0,
        TOTMA: 0.010813185319,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10123.0,
        MAXELEVSMO: 10160.0,
        MINELEVSMO: 10123.0,
        SLOPE: 0.00262411,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14100000262260401,
        QA_MA: 0.058,
        VA_MA: 0.49515,
        QC_MA: 0.058,
        VC_MA: 0.49515,
        QE_MA: 0.058,
        VE_MA: 0.49515,
        QA_01: 0.125,
        VA_01: 0.58916,
        QC_01: 0.125,
        VC_01: 0.58916,
        QE_01: 0.125,
        VE_01: 0.58916,
        QA_02: 0.139,
        VA_02: 0.60545,
        QC_02: 0.139,
        VC_02: 0.60545,
        QE_02: 0.139,
        VE_02: 0.60545,
        QA_03: 0.156,
        VA_03: 0.62421,
        QC_03: 0.156,
        VC_03: 0.62421,
        QE_03: 0.156,
        VE_03: 0.62421,
        QA_04: 0.08,
        VA_04: 0.52992,
        QC_04: 0.08,
        VC_04: 0.52992,
        QE_04: 0.08,
        VE_04: 0.52992,
        QA_05: 0.045,
        VA_05: 0.4716,
        QC_05: 0.045,
        VC_05: 0.4716,
        QE_05: 0.045,
        VE_05: 0.4716,
        QA_06: 0.03,
        VA_06: 0.43999,
        QC_06: 0.03,
        VC_06: 0.43999,
        QE_06: 0.03,
        VE_06: 0.43999,
        QA_07: 0.023,
        VA_07: 0.42267,
        QC_07: 0.023,
        VC_07: 0.42267,
        QE_07: 0.023,
        VE_07: 0.42267,
        QA_08: 0.018,
        VA_08: 0.40872,
        QC_08: 0.018,
        VC_08: 0.40872,
        QE_08: 0.018,
        VE_08: 0.40872,
        QA_09: 0.014,
        VA_09: 0.39619,
        QC_09: 0.014,
        VC_09: 0.39619,
        QE_09: 0.014,
        VE_09: 0.39619,
        QA_10: 0.011,
        VA_10: 0.38564,
        QC_10: 0.011,
        VC_10: 0.38564,
        QE_10: 0.011,
        VE_10: 0.38564,
        QA_11: 0.009,
        VA_11: 0.37783,
        QC_11: 0.009,
        VC_11: 0.37783,
        QE_11: 0.009,
        VE_11: 0.37783,
        QA_12: 0.045,
        VA_12: 0.4716,
        QC_12: 0.045,
        VC_12: 0.4716,
        QE_12: 0.045,
        VE_12: 0.4716,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88900971821738, 34.592819612971823, 0.0],
          [-80.888372518218375, 34.591674412973532, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288420.0,
        COMID: 9735590,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.138,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001464072796558,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059730.0,
        ToNode: 250059729.0,
        Hydroseq: 250004616.0,
        LevelPathI: 250004457.0,
        Pathlength: 373.316,
        TerminalPa: 250002604.0,
        ArbolateSu: 7559.606,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004731.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004604.0,
        DnDrainCou: 2,
        DnHydroseq: 250004605.0,
        FromMeas: 0.0,
        ToMeas: 7.62895,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.027,
        TotDASqKM: 9868.3155,
        DivDASqKM: 0.5868,
        Tidal: 0,
        TOTMA: 0.012782296868699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9597.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13799999654293099,
        QA_MA: 0.194,
        VA_MA: 0.40996,
        QC_MA: 0.194,
        VC_MA: 0.40996,
        QE_MA: 0.194,
        VE_MA: 0.40996,
        QA_01: 0.418,
        VA_01: 0.46108,
        QC_01: 0.418,
        VC_01: 0.46108,
        QE_01: 0.418,
        VE_01: 0.46108,
        QA_02: 0.465,
        VA_02: 0.46996,
        QC_02: 0.465,
        VC_02: 0.46996,
        QE_02: 0.465,
        VE_02: 0.46996,
        QA_03: 0.518,
        VA_03: 0.4795,
        QC_03: 0.518,
        VC_03: 0.4795,
        QE_03: 0.518,
        VE_03: 0.4795,
        QA_04: 0.268,
        VA_04: 0.42898,
        QC_04: 0.268,
        VC_04: 0.42898,
        QE_04: 0.268,
        VE_04: 0.42898,
        QA_05: 0.149,
        VA_05: 0.39668,
        QC_05: 0.149,
        VC_05: 0.39668,
        QE_05: 0.149,
        VE_05: 0.39668,
        QA_06: 0.102,
        VA_06: 0.38059,
        QC_06: 0.102,
        VC_06: 0.38059,
        QE_06: 0.102,
        VE_06: 0.38059,
        QA_07: 0.078,
        VA_07: 0.371,
        QC_07: 0.078,
        VC_07: 0.371,
        QE_07: 0.078,
        VE_07: 0.371,
        QA_08: 0.063,
        VA_08: 0.36429,
        QC_08: 0.063,
        VC_08: 0.36429,
        QE_08: 0.063,
        VE_08: 0.36429,
        QA_09: 0.047,
        VA_09: 0.35624,
        QC_09: 0.047,
        VC_09: 0.35624,
        QE_09: 0.047,
        VE_09: 0.35624,
        QA_10: 0.039,
        VA_10: 0.35172,
        QC_10: 0.039,
        VC_10: 0.35172,
        QE_10: 0.039,
        VE_10: 0.35172,
        QA_11: 0.031,
        VA_11: 0.34675,
        QC_11: 0.031,
        VC_11: 0.34675,
        QE_11: 0.031,
        VE_11: 0.34675,
        QA_12: 0.149,
        VA_12: 0.39668,
        QC_12: 0.149,
        VC_12: 0.39668,
        QE_12: 0.149,
        VE_12: 0.39668,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882859784893654, 34.583474479652921, 0.0],
          [-80.881502784895645, 34.582924879653781, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288421.0,
        COMID: 9735530,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.145,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0013066712627799999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059717.0,
        ToNode: 250059725.0,
        Hydroseq: 250004746.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.097,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.315,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004766.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004728.0,
        DnDrainCou: 2,
        DnHydroseq: 250004731.0,
        FromMeas: 42.88784,
        ToMeas: 50.82151,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 9867.5289,
        DivDASqKM: 0.2871,
        Tidal: 0,
        TOTMA: 0.0139538234456,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9671.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.144999995827675,
        QA_MA: 0.095,
        VA_MA: 0.39459,
        QC_MA: 0.095,
        VC_MA: 0.39459,
        QE_MA: 0.095,
        VE_MA: 0.39459,
        QA_01: 0.204,
        VA_01: 0.43773,
        QC_01: 0.204,
        VC_01: 0.43773,
        QE_01: 0.204,
        VE_01: 0.43773,
        QA_02: 0.227,
        VA_02: 0.44528,
        QC_02: 0.227,
        VC_02: 0.44528,
        QE_02: 0.227,
        VE_02: 0.44528,
        QA_03: 0.254,
        VA_03: 0.45369,
        QC_03: 0.254,
        VC_03: 0.45369,
        QE_03: 0.254,
        VE_03: 0.45369,
        QA_04: 0.131,
        VA_04: 0.41062,
        QC_04: 0.131,
        VC_04: 0.41062,
        QE_04: 0.131,
        VE_04: 0.41062,
        QA_05: 0.073,
        VA_05: 0.38333,
        QC_05: 0.073,
        VC_05: 0.38333,
        QE_05: 0.073,
        VE_05: 0.38333,
        QA_06: 0.05,
        VA_06: 0.36969,
        QC_06: 0.05,
        VC_06: 0.36969,
        QE_06: 0.05,
        VE_06: 0.36969,
        QA_07: 0.038,
        VA_07: 0.36138,
        QC_07: 0.038,
        VC_07: 0.36138,
        QE_07: 0.038,
        VE_07: 0.36138,
        QA_08: 0.03,
        VA_08: 0.35513,
        QC_08: 0.03,
        VC_08: 0.35513,
        QE_08: 0.03,
        VE_08: 0.35513,
        QA_09: 0.023,
        VA_09: 0.34898,
        QC_09: 0.023,
        VC_09: 0.34898,
        QE_09: 0.023,
        VE_09: 0.34898,
        QA_10: 0.019,
        VA_10: 0.34506,
        QC_10: 0.019,
        VC_10: 0.34506,
        QE_10: 0.019,
        VE_10: 0.34506,
        QA_11: 0.015,
        VA_11: 0.34074,
        QC_11: 0.015,
        VC_11: 0.34074,
        QE_11: 0.015,
        VE_11: 0.34074,
        QA_12: 0.073,
        VA_12: 0.38333,
        QC_12: 0.073,
        VC_12: 0.38333,
        QE_12: 0.073,
        VE_12: 0.38333,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884826784890549, 34.589201012977412, 0.0],
          [-80.884604984890927, 34.587918212979275, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288422.0,
        COMID: 9735426,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.173,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001664170004714,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059708.0,
        ToNode: 250059709.0,
        Hydroseq: 250005038.0,
        LevelPathI: 250005038.0,
        Pathlength: 374.963,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.8,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250005038.0,
        UpHydroseq: 250005050.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004955.0,
        FromMeas: 90.48725,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0369,
        TotDASqKM: 9867.1347,
        DivDASqKM: 0.0594,
        Tidal: 0,
        TOTMA: 0.0128413985769,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10337.0,
        MAXELEVSMO: 10714.0,
        MINELEVSMO: 10337.0,
        SLOPE: 0.0217919,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.172999992966652,
        QA_MA: 0.019,
        VA_MA: 0.51157,
        QC_MA: 0.019,
        VC_MA: 0.51157,
        QE_MA: 0.019,
        VE_MA: 0.51157,
        QA_01: 0.042,
        VA_01: 0.61799,
        QC_01: 0.042,
        VC_01: 0.61799,
        QE_01: 0.042,
        VE_01: 0.61799,
        QA_02: 0.047,
        VA_02: 0.63704,
        QC_02: 0.047,
        VC_02: 0.63704,
        QE_02: 0.047,
        VE_02: 0.63704,
        QA_03: 0.052,
        VA_03: 0.65517,
        QC_03: 0.052,
        VC_03: 0.65517,
        QE_03: 0.052,
        VE_03: 0.65517,
        QA_04: 0.027,
        VA_04: 0.55324,
        QC_04: 0.027,
        VC_04: 0.55324,
        QE_04: 0.027,
        VE_04: 0.55324,
        QA_05: 0.015,
        VA_05: 0.4876,
        QC_05: 0.015,
        VC_05: 0.4876,
        QE_05: 0.015,
        VE_05: 0.4876,
        QA_06: 0.01,
        VA_06: 0.45289,
        QC_06: 0.01,
        VC_06: 0.45289,
        QE_06: 0.01,
        VE_06: 0.45289,
        QA_07: 0.007,
        VA_07: 0.42795,
        QC_07: 0.007,
        VC_07: 0.42795,
        QE_07: 0.007,
        VE_07: 0.42795,
        QA_08: 0.006,
        VA_08: 0.41856,
        QC_08: 0.006,
        VC_08: 0.41856,
        QE_08: 0.006,
        VE_08: 0.41856,
        QA_09: 0.004,
        VA_09: 0.39722,
        QC_09: 0.004,
        VC_09: 0.39722,
        QE_09: 0.004,
        VE_09: 0.39722,
        QA_10: 0.003,
        VA_10: 0.38463,
        QC_10: 0.003,
        VC_10: 0.38463,
        QE_10: 0.003,
        VE_10: 0.38463,
        QA_11: 0.003,
        VA_11: 0.38463,
        QC_11: 0.003,
        VC_11: 0.38463,
        QE_11: 0.003,
        VE_11: 0.38463,
        QA_12: 0.015,
        VA_12: 0.4876,
        QC_12: 0.015,
        VC_12: 0.4876,
        QE_12: 0.015,
        VE_12: 0.4876,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.891198384880695, 34.595018279634985, 0.0],
          [-80.890200918215555, 34.593758612970305, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288416.0,
        COMID: 9735574,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.643,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0060964342912520003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059725.0,
        ToNode: 250059730.0,
        Hydroseq: 250004731.0,
        LevelPathI: 250004457.0,
        Pathlength: 373.454,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.958,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004746.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004616.0,
        FromMeas: 7.62895,
        ToMeas: 42.88784,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2286,
        TotDASqKM: 9867.7575,
        DivDASqKM: 0.5157,
        Tidal: 0,
        TOTMA: 0.059995667561700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9619.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.64300000667571999,
        QA_MA: 0.17,
        VA_MA: 0.40697,
        QC_MA: 0.17,
        VC_MA: 0.40697,
        QE_MA: 0.17,
        VE_MA: 0.40697,
        QA_01: 0.367,
        VA_01: 0.45673,
        QC_01: 0.367,
        VC_01: 0.45673,
        QE_01: 0.367,
        VE_01: 0.45673,
        QA_02: 0.409,
        VA_02: 0.46552,
        QC_02: 0.409,
        VC_02: 0.46552,
        QE_02: 0.409,
        VE_02: 0.46552,
        QA_03: 0.455,
        VA_03: 0.47466,
        QC_03: 0.455,
        VC_03: 0.47466,
        QE_03: 0.455,
        VE_03: 0.47466,
        QA_04: 0.235,
        VA_04: 0.42546,
        QC_04: 0.235,
        VC_04: 0.42546,
        QE_04: 0.235,
        VE_04: 0.42546,
        QA_05: 0.131,
        VA_05: 0.39423,
        QC_05: 0.131,
        VC_05: 0.39423,
        QE_05: 0.131,
        VE_05: 0.39423,
        QA_06: 0.09,
        VA_06: 0.37872,
        QC_06: 0.09,
        VC_06: 0.37872,
        QE_06: 0.09,
        VE_06: 0.37872,
        QA_07: 0.069,
        VA_07: 0.36946,
        QC_07: 0.069,
        VC_07: 0.36946,
        QE_07: 0.069,
        VE_07: 0.36946,
        QA_08: 0.055,
        VA_08: 0.36253,
        QC_08: 0.055,
        VC_08: 0.36253,
        QE_08: 0.055,
        VE_08: 0.36253,
        QA_09: 0.041,
        VA_09: 0.35471,
        QC_09: 0.041,
        VC_09: 0.35471,
        QE_09: 0.041,
        VE_09: 0.35471,
        QA_10: 0.034,
        VA_10: 0.35033,
        QC_10: 0.034,
        VC_10: 0.35033,
        QE_10: 0.034,
        VE_10: 0.35033,
        QA_11: 0.027,
        VA_11: 0.3455,
        QC_11: 0.027,
        VC_11: 0.3455,
        QE_11: 0.027,
        VE_11: 0.3455,
        QA_12: 0.131,
        VA_12: 0.39423,
        QC_12: 0.131,
        VC_12: 0.39423,
        QE_12: 0.131,
        VE_12: 0.39423,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884604984890927, 34.587918212979275, 0.0],
          [-80.884715518224084, 34.58372627965241, 0.0],
          [-80.882859784893654, 34.583474479652921, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287439.0,
        COMID: 9736534,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.209,
        REACHCODE: "03050103000644",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001948853107698,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060019.0,
        ToNode: 250059558.0,
        Hydroseq: 250005209.0,
        LevelPathI: 250005161.0,
        Pathlength: 388.259,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.746,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005217.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005200.0,
        FromMeas: 50.71082,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 9815.1057,
        DivDASqKM: 0.0297,
        Tidal: 0,
        TOTMA: 0.022042804809500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12651.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12651.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.059000000357628,
        QA_MA: 0.011,
        VA_MA: 0.36004,
        QC_MA: 0.011,
        VC_MA: 0.36004,
        QE_MA: 0.011,
        VE_MA: 0.36004,
        QA_01: 0.024,
        VA_01: 0.38655,
        QC_01: 0.024,
        VC_01: 0.38655,
        QE_01: 0.024,
        VE_01: 0.38655,
        QA_02: 0.026,
        VA_02: 0.38995,
        QC_02: 0.026,
        VC_02: 0.38995,
        QE_02: 0.026,
        VE_02: 0.38995,
        QA_03: 0.028,
        VA_03: 0.39322,
        QC_03: 0.028,
        VC_03: 0.39322,
        QE_03: 0.028,
        VE_03: 0.39322,
        QA_04: 0.015,
        VA_04: 0.36929,
        QC_04: 0.015,
        VC_04: 0.36929,
        QE_04: 0.015,
        VE_04: 0.36929,
        QA_05: 0.008,
        VA_05: 0.35201,
        QC_05: 0.008,
        VC_05: 0.35201,
        QE_05: 0.008,
        VE_05: 0.35201,
        QA_06: 0.005,
        VA_06: 0.34237,
        QC_06: 0.005,
        VC_06: 0.34237,
        QE_06: 0.005,
        VE_06: 0.34237,
        QA_07: 0.003,
        VA_07: 0.3343,
        QC_07: 0.003,
        VC_07: 0.3343,
        QE_07: 0.003,
        VE_07: 0.3343,
        QA_08: 0.003,
        VA_08: 0.3343,
        QC_08: 0.003,
        VC_08: 0.3343,
        QE_08: 0.003,
        VE_08: 0.3343,
        QA_09: 0.002,
        VA_09: 0.32928,
        QC_09: 0.002,
        VC_09: 0.32928,
        QE_09: 0.002,
        VE_09: 0.32928,
        QA_10: 0.001,
        VA_10: 0.32285,
        QC_10: 0.001,
        VC_10: 0.32285,
        QE_10: 0.001,
        VE_10: 0.32285,
        QA_11: 0.001,
        VA_11: 0.32285,
        QC_11: 0.001,
        VC_11: 0.32285,
        QE_11: 0.001,
        VE_11: 0.32285,
        QA_12: 0.011,
        VA_12: 0.36004,
        QC_12: 0.011,
        VC_12: 0.36004,
        QE_12: 0.011,
        VE_12: 0.36004,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.863865918256408, 34.692772679483198, 0.0],
          [-80.864424518255476, 34.694295212814268, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288417.0,
        COMID: 9735502,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.267,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0027229741734469998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059713.0,
        ToNode: 250059715.0,
        Hydroseq: 250004787.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.404,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.008,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004808.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004762.0,
        DnDrainCou: 2,
        DnHydroseq: 250004766.0,
        FromMeas: 59.74054,
        ToMeas: 74.48074,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0594,
        TotDASqKM: 9867.4776,
        DivDASqKM: 0.2358,
        Tidal: 0,
        TOTMA: 0.019990745815299998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10055.0,
        MAXELEVSMO: 10123.0,
        MINELEVSMO: 10055.0,
        SLOPE: 0.00254681,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.26699998974800099,
        QA_MA: 0.078,
        VA_MA: 0.50717,
        QC_MA: 0.078,
        VC_MA: 0.50717,
        QE_MA: 0.078,
        VE_MA: 0.50717,
        QA_01: 0.168,
        VA_01: 0.60715,
        QC_01: 0.168,
        VC_01: 0.60715,
        QE_01: 0.168,
        VE_01: 0.60715,
        QA_02: 0.187,
        VA_02: 0.62464,
        QC_02: 0.187,
        VC_02: 0.62464,
        QE_02: 0.187,
        VE_02: 0.62464,
        QA_03: 0.209,
        VA_03: 0.64388,
        QC_03: 0.209,
        VC_03: 0.64388,
        QE_03: 0.209,
        VE_03: 0.64388,
        QA_04: 0.107,
        VA_04: 0.54351,
        QC_04: 0.107,
        VC_04: 0.54351,
        QE_04: 0.107,
        VE_04: 0.54351,
        QA_05: 0.06,
        VA_05: 0.48132,
        QC_05: 0.06,
        VC_05: 0.48132,
        QE_05: 0.06,
        VE_05: 0.48132,
        QA_06: 0.041,
        VA_06: 0.44967,
        QC_06: 0.041,
        VC_06: 0.44967,
        QE_06: 0.041,
        VE_06: 0.44967,
        QA_07: 0.031,
        VA_07: 0.43018,
        QC_07: 0.031,
        VC_07: 0.43018,
        QE_07: 0.031,
        VE_07: 0.43018,
        QA_08: 0.025,
        VA_08: 0.41703,
        QC_08: 0.025,
        VC_08: 0.41703,
        QE_08: 0.025,
        VE_08: 0.41703,
        QA_09: 0.019,
        VA_09: 0.4023,
        QC_09: 0.019,
        VC_09: 0.4023,
        QE_09: 0.019,
        VE_09: 0.4023,
        QA_10: 0.015,
        VA_10: 0.39122,
        QC_10: 0.015,
        VC_10: 0.39122,
        QE_10: 0.015,
        VE_10: 0.39122,
        QA_11: 0.012,
        VA_11: 0.38196,
        QC_11: 0.012,
        VC_11: 0.38196,
        QE_11: 0.012,
        VE_11: 0.38196,
        QA_12: 0.06,
        VA_12: 0.48132,
        QC_12: 0.06,
        VC_12: 0.48132,
        QE_12: 0.06,
        VE_12: 0.48132,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888372518218375, 34.591674412973532, 0.0],
          [-80.886156384888466, 34.590162812975848, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287440.0,
        COMID: 9736536,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.028,
        REACHCODE: "03050103000644",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00025546213487099998,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060020.0,
        ToNode: 250060021.0,
        Hydroseq: 250005189.0,
        LevelPathI: 250005161.0,
        Pathlength: 388.045,
        TerminalPa: 250002604.0,
        ArbolateSu: 7509.96,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005161.0,
        UpHydroseq: 250005200.0,
        DnLevelPat: 250005161.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005180.0,
        FromMeas: 0.0,
        ToMeas: 6.53872,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9815.5278,
        DivDASqKM: 0.4518,
        Tidal: 0,
        TOTMA: 0.0026246240878899998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12640.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.028000000864266999,
        QA_MA: 0.171,
        VA_MA: 0.4051,
        QC_MA: 0.171,
        VC_MA: 0.4051,
        QE_MA: 0.171,
        VE_MA: 0.4051,
        QA_01: 0.376,
        VA_01: 0.45533,
        QC_01: 0.376,
        VC_01: 0.45533,
        QE_01: 0.376,
        VE_01: 0.45533,
        QA_02: 0.4,
        VA_02: 0.46024,
        QC_02: 0.4,
        VC_02: 0.46024,
        QE_02: 0.4,
        VE_02: 0.46024,
        QA_03: 0.431,
        VA_03: 0.46638,
        QC_03: 0.431,
        VC_03: 0.46638,
        QE_03: 0.431,
        VE_03: 0.46638,
        QA_04: 0.242,
        VA_04: 0.42468,
        QC_04: 0.242,
        VC_04: 0.42468,
        QE_04: 0.242,
        VE_04: 0.42468,
        QA_05: 0.133,
        VA_05: 0.39301,
        QC_05: 0.133,
        VC_05: 0.39301,
        QE_05: 0.133,
        VE_05: 0.39301,
        QA_06: 0.085,
        VA_06: 0.37511,
        QC_06: 0.085,
        VC_06: 0.37511,
        QE_06: 0.085,
        VE_06: 0.37511,
        QA_07: 0.06,
        VA_07: 0.36384,
        QC_07: 0.06,
        VC_07: 0.36384,
        QE_07: 0.06,
        VE_07: 0.36384,
        QA_08: 0.048,
        VA_08: 0.35765,
        QC_08: 0.048,
        VC_08: 0.35765,
        QE_08: 0.048,
        VE_08: 0.35765,
        QA_09: 0.036,
        VA_09: 0.35067,
        QC_09: 0.036,
        VC_09: 0.35067,
        QE_09: 0.036,
        VE_09: 0.35067,
        QA_10: 0.03,
        VA_10: 0.34677,
        QC_10: 0.03,
        VC_10: 0.34677,
        QE_10: 0.03,
        VE_10: 0.34677,
        QA_11: 0.024,
        VA_11: 0.34248,
        QC_11: 0.024,
        VC_11: 0.34248,
        QE_11: 0.024,
        VE_11: 0.34248,
        QA_12: 0.184,
        VA_12: 0.40893,
        QC_12: 0.184,
        VC_12: 0.40893,
        QE_12: 0.184,
        VE_12: 0.40893,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865755918253456, 34.693127012816035, 0.0],
          [-80.865847584920004, 34.69289547948307, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288418.0,
        COMID: 9735456,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.151,
        REACHCODE: "03050103001295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015167987449889999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059709.0,
        ToNode: 250059711.0,
        Hydroseq: 250004955.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.812,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.62,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004967.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004808.0,
        FromMeas: 82.1783,
        ToMeas: 90.48725,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 9867.2184,
        DivDASqKM: 0.1431,
        Tidal: 0,
        TOTMA: 0.010733774874899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10160.0,
        MAXELEVSMO: 10337.0,
        MINELEVSMO: 10160.0,
        SLOPE: 0.01172185,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15099999308586101,
        QA_MA: 0.047,
        VA_MA: 0.53419,
        QC_MA: 0.047,
        VC_MA: 0.53419,
        QE_MA: 0.047,
        VE_MA: 0.53419,
        QA_01: 0.101,
        VA_01: 0.64733,
        QC_01: 0.101,
        VC_01: 0.64733,
        QE_01: 0.101,
        VE_01: 0.64733,
        QA_02: 0.113,
        VA_02: 0.66815,
        QC_02: 0.113,
        VC_02: 0.66815,
        QE_02: 0.113,
        VE_02: 0.66815,
        QA_03: 0.126,
        VA_03: 0.68957,
        QC_03: 0.126,
        VC_03: 0.68957,
        QE_03: 0.126,
        VE_03: 0.68957,
        QA_04: 0.065,
        VA_04: 0.57661,
        QC_04: 0.065,
        VC_04: 0.57661,
        QE_04: 0.065,
        VE_04: 0.57661,
        QA_05: 0.036,
        VA_05: 0.50438,
        QC_05: 0.036,
        VC_05: 0.50438,
        QE_05: 0.036,
        VE_05: 0.50438,
        QA_06: 0.025,
        VA_06: 0.46988,
        QC_06: 0.025,
        VC_06: 0.46988,
        QE_06: 0.025,
        VE_06: 0.46988,
        QA_07: 0.019,
        VA_07: 0.44798,
        QC_07: 0.019,
        VC_07: 0.44798,
        QE_07: 0.019,
        VE_07: 0.44798,
        QA_08: 0.015,
        VA_08: 0.43151,
        QC_08: 0.015,
        VC_08: 0.43151,
        QE_08: 0.015,
        VE_08: 0.43151,
        QA_09: 0.011,
        VA_09: 0.41282,
        QC_09: 0.011,
        VC_09: 0.41282,
        QE_09: 0.011,
        VE_09: 0.41282,
        QA_10: 0.009,
        VA_10: 0.40226,
        QC_10: 0.009,
        VC_10: 0.40226,
        QE_10: 0.009,
        VE_10: 0.40226,
        QA_11: 0.007,
        VA_11: 0.39053,
        QC_11: 0.007,
        VC_11: 0.39053,
        QE_11: 0.007,
        VE_11: 0.39053,
        QA_12: 0.036,
        VA_12: 0.50438,
        QC_12: 0.036,
        VC_12: 0.50438,
        QE_12: 0.036,
        VE_12: 0.50438,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890200918215555, 34.593758612970305, 0.0],
          [-80.88900971821738, 34.592819612971823, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288404.0,
        COMID: 9734696,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.038,
        REACHCODE: "03050103001285",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000418697843177,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059482.0,
        ToNode: 250059483.0,
        Hydroseq: 250005335.0,
        LevelPathI: 250005304.0,
        Pathlength: 393.515,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.076,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005347.0,
        DnLevelPat: 250005304.0,
        DnMinorHyd: 250005321.0,
        DnDrainCou: 2,
        DnHydroseq: 250005322.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9368.3529,
        DivDASqKM: 0.036,
        Tidal: 0,
        TOTMA: 0.0039832219694099997,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12697.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.037999998778104997,
        QA_MA: 0.013,
        VA_MA: 0.36226,
        QC_MA: 0.013,
        VC_MA: 0.36226,
        QE_MA: 0.013,
        VE_MA: 0.36226,
        QA_01: 0.03,
        VA_01: 0.39238,
        QC_01: 0.03,
        VC_01: 0.39238,
        QE_01: 0.03,
        VE_01: 0.39238,
        QA_02: 0.032,
        VA_02: 0.39531,
        QC_02: 0.032,
        VC_02: 0.39531,
        QE_02: 0.032,
        VE_02: 0.39531,
        QA_03: 0.034,
        VA_03: 0.39815,
        QC_03: 0.034,
        VC_03: 0.39815,
        QE_03: 0.034,
        VE_03: 0.39815,
        QA_04: 0.019,
        VA_04: 0.37429,
        QC_04: 0.019,
        VC_04: 0.37429,
        QE_04: 0.019,
        VE_04: 0.37429,
        QA_05: 0.011,
        VA_05: 0.35769,
        QC_05: 0.011,
        VC_05: 0.35769,
        QE_05: 0.011,
        VE_05: 0.35769,
        QA_06: 0.007,
        VA_06: 0.34717,
        QC_06: 0.007,
        VC_06: 0.34717,
        QE_06: 0.007,
        VE_06: 0.34717,
        QA_07: 0.004,
        VA_07: 0.3372,
        QC_07: 0.004,
        VC_07: 0.3372,
        QE_07: 0.004,
        VE_07: 0.3372,
        QA_08: 0.003,
        VA_08: 0.33312,
        QC_08: 0.003,
        VC_08: 0.33312,
        QE_08: 0.003,
        VE_08: 0.33312,
        QA_09: 0.002,
        VA_09: 0.32833,
        QC_09: 0.002,
        VC_09: 0.32833,
        QE_09: 0.002,
        VE_09: 0.32833,
        QA_10: 0.002,
        VA_10: 0.32833,
        QC_10: 0.002,
        VC_10: 0.32833,
        QE_10: 0.002,
        VE_10: 0.32833,
        QA_11: 0.002,
        VA_11: 0.32833,
        QC_11: 0.002,
        VC_11: 0.32833,
        QE_11: 0.002,
        VE_11: 0.32833,
        QA_12: 0.015,
        VA_12: 0.36652,
        QC_12: 0.015,
        VC_12: 0.36652,
        QE_12: 0.015,
        VE_12: 0.36652,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876071518237438, 34.734724279418117, 0.0],
          [-80.875655318238117, 34.734678612751566, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288405.0,
        COMID: 9734700,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.093,
        REACHCODE: "03050103001286",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00083941662102000002,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059483.0,
        ToNode: 250059486.0,
        Hydroseq: 250005321.0,
        LevelPathI: 250005321.0,
        Pathlength: 393.373,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.324,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005335.0,
        DnLevelPat: 250005292.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005307.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9368.3583,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.011451278595199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12793.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.093000002205371995,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875655318238117, 34.734678612751566, 0.0],
          [-80.875572184904911, 34.733853879419428, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288406.0,
        COMID: 9734702,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.114,
        REACHCODE: "03050103001287",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001080781065694,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059482.0,
        ToNode: 250059486.0,
        Hydroseq: 250005334.0,
        LevelPathI: 250005292.0,
        Pathlength: 393.373,
        TerminalPa: 250002604.0,
        ArbolateSu: 7030.152,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005304.0,
        UpHydroseq: 250005347.0,
        DnLevelPat: 250005292.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005307.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9368.3466,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.014037050855699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12793.0,
        MINELEVSMO: 12793.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.11400000005960501,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876071518237438, 34.734724279418117, 0.0],
          [-80.875572184904911, 34.733853879419428, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288571.0,
        COMID: 9757124,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.073,
        REACHCODE: "03050103017532",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00066432480962399999,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062064.0,
        ToNode: 250062067.0,
        Hydroseq: 250004935.0,
        LevelPathI: 250004919.0,
        Pathlength: 375.328,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.308,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004936.0,
        UpHydroseq: 250004952.0,
        DnLevelPat: 250004919.0,
        DnMinorHyd: 250004918.0,
        DnDrainCou: 2,
        DnHydroseq: 250004919.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0089886376918100006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10213.0,
        MINELEVSMO: 10213.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.072999998927116005,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887569584886307, 34.594308679636129, 0.0],
          [-80.887763384886, 34.593690279636974, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288572.0,
        COMID: 9757126,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.011,
        REACHCODE: "03050103017533",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000119892785233,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062067.0,
        ToNode: 250062069.0,
        Hydroseq: 250004919.0,
        LevelPathI: 250004919.0,
        Pathlength: 375.317,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.319,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004919.0,
        UpHydroseq: 250004935.0,
        DnLevelPat: 250004901.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004901.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0013544522675,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10213.0,
        MINELEVSMO: 10213.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.010999999940395,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887763384886, 34.593690279636974, 0.0],
          [-80.887874184885845, 34.593644479637135, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288573.0,
        COMID: 9757128,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.28,
        REACHCODE: "03050103017534",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0025865107663690001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062053.0,
        ToNode: 250062069.0,
        Hydroseq: 250004998.0,
        LevelPathI: 250004901.0,
        Pathlength: 375.317,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.818,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250005002.0,
        UpHydroseq: 250005018.0,
        DnLevelPat: 250004901.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004901.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.1014,
        DivDASqKM: 0.0099,
        Tidal: 0,
        TOTMA: 0.030564152979899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10213.0,
        MAXELEVSMO: 10213.0,
        MINELEVSMO: 10213.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.129999995231628,
        QA_MA: 0.003,
        VA_MA: 0.34787,
        QC_MA: 0.003,
        VC_MA: 0.34787,
        QE_MA: 0.003,
        VE_MA: 0.34787,
        QA_01: 0.007,
        VA_01: 0.37031,
        QC_01: 0.007,
        VC_01: 0.37031,
        QE_01: 0.007,
        VE_01: 0.37031,
        QA_02: 0.007,
        VA_02: 0.37031,
        QC_02: 0.007,
        VC_02: 0.37031,
        QE_02: 0.007,
        VE_02: 0.37031,
        QA_03: 0.008,
        VA_03: 0.37485,
        QC_03: 0.008,
        VC_03: 0.37485,
        QE_03: 0.008,
        VE_03: 0.37485,
        QA_04: 0.004,
        VA_04: 0.35439,
        QC_04: 0.004,
        VC_04: 0.35439,
        QE_04: 0.004,
        VE_04: 0.35439,
        QA_05: 0.002,
        VA_05: 0.34022,
        QC_05: 0.002,
        VC_05: 0.34022,
        QE_05: 0.002,
        VE_05: 0.34022,
        QA_06: 0.001,
        VA_06: 0.33042,
        QC_06: 0.001,
        VC_06: 0.33042,
        QE_06: 0.001,
        VE_06: 0.33042,
        QA_07: 0.001,
        VA_07: 0.33042,
        QC_07: 0.001,
        VC_07: 0.33042,
        QE_07: 0.001,
        VE_07: 0.33042,
        QA_08: 0.001,
        VA_08: 0.33042,
        QC_08: 0.001,
        VC_08: 0.33042,
        QE_08: 0.001,
        VE_08: 0.33042,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.34022,
        QC_12: 0.002,
        VC_12: 0.34022,
        QE_12: 0.002,
        VE_12: 0.34022,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888926984884222, 34.595980679633385, 0.0],
          [-80.887874184885845, 34.593644479637135, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288574.0,
        COMID: 9757130,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.027,
        REACHCODE: "03050103017535",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00029177350379299999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062069.0,
        ToNode: 250062071.0,
        Hydroseq: 250004901.0,
        LevelPathI: 250004901.0,
        Pathlength: 375.29,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.626,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004901.0,
        UpHydroseq: 250004998.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004883.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9867.231,
        DivDASqKM: 0.0117,
        Tidal: 0,
        TOTMA: 0.0023323684576099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10168.0,
        MAXELEVSMO: 10213.0,
        MINELEVSMO: 10169.0,
        SLOPE: 0.01629629,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.027000000700354999,
        QA_MA: 0.003,
        VA_MA: 0.43958,
        QC_MA: 0.003,
        VC_MA: 0.43958,
        QE_MA: 0.003,
        VE_MA: 0.43958,
        QA_01: 0.008,
        VA_01: 0.52924,
        QC_01: 0.008,
        VC_01: 0.52924,
        QE_01: 0.008,
        VE_01: 0.52924,
        QA_02: 0.009,
        VA_02: 0.5435,
        QC_02: 0.009,
        VC_02: 0.5435,
        QE_02: 0.009,
        VE_02: 0.5435,
        QA_03: 0.01,
        VA_03: 0.55703,
        QC_03: 0.01,
        VC_03: 0.55703,
        QE_03: 0.01,
        VE_03: 0.55703,
        QA_04: 0.005,
        VA_04: 0.48046,
        QC_04: 0.005,
        VC_04: 0.48046,
        QE_04: 0.005,
        VE_04: 0.48046,
        QA_05: 0.002,
        VA_05: 0.41417,
        QC_05: 0.002,
        VC_05: 0.41417,
        QE_05: 0.002,
        VE_05: 0.41417,
        QA_06: 0.002,
        VA_06: 0.41417,
        QC_06: 0.002,
        VC_06: 0.41417,
        QE_06: 0.002,
        VE_06: 0.41417,
        QA_07: 0.001,
        VA_07: 0.3816,
        QC_07: 0.001,
        VC_07: 0.3816,
        QE_07: 0.001,
        VE_07: 0.3816,
        QA_08: 0.001,
        VA_08: 0.3816,
        QC_08: 0.001,
        VC_08: 0.3816,
        QE_08: 0.001,
        VE_08: 0.3816,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.41417,
        QC_12: 0.002,
        VC_12: 0.41417,
        QE_12: 0.002,
        VE_12: 0.41417,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887874184885845, 34.593644479637135, 0.0],
          [-80.888151184885373, 34.593552812970472, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288567.0,
        COMID: 9757116,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.131,
        REACHCODE: "03050103017528",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001203043831192,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062058.0,
        ToNode: 250062064.0,
        Hydroseq: 250004952.0,
        LevelPathI: 250004936.0,
        Pathlength: 375.16,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.235,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004968.0,
        DnLevelPat: 250004936.0,
        DnMinorHyd: 250004935.0,
        DnDrainCou: 2,
        DnHydroseq: 250004936.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0161302949355,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10438.0,
        MINELEVSMO: 10438.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.13099999725818601,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887985118218921, 34.595408079634353, 0.0],
          [-80.887569584886307, 34.594308679636129, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288568.0,
        COMID: 9757118,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.157,
        REACHCODE: "03050103017529",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001452795647621,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062063.0,
        ToNode: 250062079.0,
        Hydroseq: 250004937.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.094,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.602,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004953.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0522,
        TotDASqKM: 9867.3372,
        DivDASqKM: 0.2457,
        Tidal: 0,
        TOTMA: 0.010924888717700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10323.0,
        MAXELEVSMO: 10438.0,
        MINELEVSMO: 10323.0,
        SLOPE: 0.00732484,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15700000524520899,
        QA_MA: 0.081,
        VA_MA: 0.5457,
        QC_MA: 0.081,
        VC_MA: 0.5457,
        QE_MA: 0.081,
        VE_MA: 0.5457,
        QA_01: 0.175,
        VA_01: 0.66563,
        QC_01: 0.175,
        VC_01: 0.66563,
        QE_01: 0.175,
        VE_01: 0.66563,
        QA_02: 0.194,
        VA_02: 0.68573,
        QC_02: 0.194,
        VC_02: 0.68573,
        QE_02: 0.194,
        VE_02: 0.68573,
        QA_03: 0.218,
        VA_03: 0.70984,
        QC_03: 0.218,
        VC_03: 0.70984,
        QE_03: 0.218,
        VE_03: 0.70984,
        QA_04: 0.112,
        VA_04: 0.59026,
        QC_04: 0.112,
        VC_04: 0.59026,
        QE_04: 0.112,
        VE_04: 0.59026,
        QA_05: 0.062,
        VA_05: 0.5143,
        QC_05: 0.062,
        VC_05: 0.5143,
        QE_05: 0.062,
        VE_05: 0.5143,
        QA_06: 0.042,
        VA_06: 0.47583,
        QC_06: 0.042,
        VC_06: 0.47583,
        QE_06: 0.042,
        VE_06: 0.47583,
        QA_07: 0.033,
        VA_07: 0.4557,
        QC_07: 0.033,
        VC_07: 0.4557,
        QE_07: 0.033,
        VE_07: 0.4557,
        QA_08: 0.026,
        VA_08: 0.43819,
        QC_08: 0.026,
        VC_08: 0.43819,
        QE_08: 0.026,
        VE_08: 0.43819,
        QA_09: 0.019,
        VA_09: 0.41827,
        QC_09: 0.019,
        VC_09: 0.41827,
        QE_09: 0.019,
        VE_09: 0.41827,
        QA_10: 0.016,
        VA_10: 0.40869,
        QC_10: 0.016,
        VC_10: 0.40869,
        QE_10: 0.016,
        VE_10: 0.40869,
        QA_11: 0.013,
        VA_11: 0.39822,
        QC_11: 0.013,
        VC_11: 0.39822,
        QE_11: 0.013,
        VE_11: 0.39822,
        QA_12: 0.062,
        VA_12: 0.5143,
        QC_12: 0.062,
        VC_12: 0.5143,
        QE_12: 0.062,
        VE_12: 0.5143,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887569584886307, 34.595339479634561, 0.0],
          [-80.886932384887302, 34.59403387963647, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288569.0,
        COMID: 9757120,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.066,
        REACHCODE: "03050103017530",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00069393002424199995,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062064.0,
        ToNode: 250062079.0,
        Hydroseq: 250004936.0,
        LevelPathI: 250004936.0,
        Pathlength: 375.094,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.301,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004936.0,
        UpHydroseq: 250004952.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004917.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.2229,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0073828940335899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10301.0,
        MAXELEVSMO: 10323.0,
        MINELEVSMO: 10323.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.065999999642372006,
        QA_MA: 0.001,
        VA_MA: 0.33946,
        QC_MA: 0.001,
        VC_MA: 0.33946,
        QE_MA: 0.001,
        VE_MA: 0.33946,
        QA_01: 0.002,
        VA_01: 0.35329,
        QC_01: 0.002,
        VC_01: 0.35329,
        QE_01: 0.002,
        VE_01: 0.35329,
        QA_02: 0.002,
        VA_02: 0.35329,
        QC_02: 0.002,
        VC_02: 0.35329,
        QE_02: 0.002,
        VE_02: 0.35329,
        QA_03: 0.003,
        VA_03: 0.36408,
        QC_03: 0.003,
        VC_03: 0.36408,
        QE_03: 0.003,
        VE_03: 0.36408,
        QA_04: 0.001,
        VA_04: 0.33946,
        QC_04: 0.001,
        VC_04: 0.33946,
        QE_04: 0.001,
        VE_04: 0.33946,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887569584886307, 34.594308679636129, 0.0],
          [-80.886932384887302, 34.59403387963647, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288570.0,
        COMID: 9757122,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.088,
        REACHCODE: "03050103017531",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00078668013983299999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062065.0,
        ToNode: 250059709.0,
        Hydroseq: 250004967.0,
        LevelPathI: 250004457.0,
        Pathlength: 374.963,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.911,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004981.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004955.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.1239,
        DivDASqKM: 0.0486,
        Tidal: 0,
        TOTMA: 0.0078081976778000004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10223.0,
        MAXELEVSMO: 10346.0,
        MINELEVSMO: 10337.0,
        SLOPE: 0.00102272,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.087999999523162994,
        QA_MA: 0.016,
        VA_MA: 0.42796,
        QC_MA: 0.016,
        VC_MA: 0.42796,
        QE_MA: 0.016,
        VE_MA: 0.42796,
        QA_01: 0.034,
        VA_01: 0.48681,
        QC_01: 0.034,
        VC_01: 0.48681,
        QE_01: 0.034,
        VE_01: 0.48681,
        QA_02: 0.038,
        VA_02: 0.49767,
        QC_02: 0.038,
        VC_02: 0.49767,
        QE_02: 0.038,
        VE_02: 0.49767,
        QA_03: 0.043,
        VA_03: 0.51051,
        QC_03: 0.043,
        VC_03: 0.51051,
        QE_03: 0.043,
        VE_03: 0.51051,
        QA_04: 0.022,
        VA_04: 0.44999,
        QC_04: 0.022,
        VC_04: 0.44999,
        QE_04: 0.022,
        VE_04: 0.44999,
        QA_05: 0.012,
        VA_05: 0.41102,
        QC_05: 0.012,
        VC_05: 0.41102,
        QE_05: 0.012,
        VE_05: 0.41102,
        QA_06: 0.008,
        VA_06: 0.39114,
        QC_06: 0.008,
        VC_06: 0.39114,
        QE_06: 0.008,
        VE_06: 0.39114,
        QA_07: 0.006,
        VA_07: 0.37942,
        QC_07: 0.006,
        VC_07: 0.37942,
        QE_07: 0.006,
        VE_07: 0.37942,
        QA_08: 0.005,
        VA_08: 0.37287,
        QC_08: 0.005,
        VC_08: 0.37287,
        QE_08: 0.005,
        VE_08: 0.37287,
        QA_09: 0.003,
        VA_09: 0.35755,
        QC_09: 0.003,
        VC_09: 0.35755,
        QE_09: 0.003,
        VE_09: 0.35755,
        QA_10: 0.003,
        VA_10: 0.35755,
        QC_10: 0.003,
        VC_10: 0.35755,
        QE_10: 0.003,
        VE_10: 0.35755,
        QA_11: 0.002,
        VA_11: 0.34803,
        QC_11: 0.002,
        VC_11: 0.34803,
        QE_11: 0.002,
        VE_11: 0.34803,
        QA_12: 0.012,
        VA_12: 0.41102,
        QC_12: 0.012,
        VC_12: 0.41102,
        QE_12: 0.012,
        VE_12: 0.41102,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890090318215641, 34.594537479635683, 0.0],
          [-80.890200918215555, 34.593758612970305, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288579.0,
        COMID: 9757140,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.04,
        REACHCODE: "03050103017540",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00042911861616900003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062074.0,
        ToNode: 250062076.0,
        Hydroseq: 250004846.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.192,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.759,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004862.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004820.0,
        DnDrainCou: 2,
        DnHydroseq: 250004821.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.2868,
        DivDASqKM: 0.0387,
        Tidal: 0,
        TOTMA: 0.0041900893060899998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10062.0,
        MAXELEVSMO: 10062.0,
        MINELEVSMO: 10062.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.039999999105930002,
        QA_MA: 0.012,
        VA_MA: 0.3625,
        QC_MA: 0.012,
        VC_MA: 0.3625,
        QE_MA: 0.012,
        VE_MA: 0.3625,
        QA_01: 0.027,
        VA_01: 0.39161,
        QC_01: 0.027,
        VC_01: 0.39161,
        QE_01: 0.027,
        VE_01: 0.39161,
        QA_02: 0.03,
        VA_02: 0.3964,
        QC_02: 0.03,
        VC_02: 0.3964,
        QE_02: 0.03,
        VE_02: 0.3964,
        QA_03: 0.034,
        VA_03: 0.40245,
        QC_03: 0.034,
        VC_03: 0.40245,
        QE_03: 0.034,
        VE_03: 0.40245,
        QA_04: 0.017,
        VA_04: 0.37349,
        QC_04: 0.017,
        VC_04: 0.37349,
        QE_04: 0.017,
        VE_04: 0.37349,
        QA_05: 0.009,
        VA_05: 0.35483,
        QC_05: 0.009,
        VC_05: 0.35483,
        QE_05: 0.009,
        VE_05: 0.35483,
        QA_06: 0.006,
        VA_06: 0.34584,
        QC_06: 0.006,
        VC_06: 0.34584,
        QE_06: 0.006,
        VE_06: 0.34584,
        QA_07: 0.005,
        VA_07: 0.34238,
        QC_07: 0.005,
        VC_07: 0.34238,
        QE_07: 0.005,
        VE_07: 0.34238,
        QA_08: 0.004,
        VA_08: 0.33858,
        QC_08: 0.004,
        VC_08: 0.33858,
        QE_08: 0.004,
        VE_08: 0.33858,
        QA_09: 0.003,
        VA_09: 0.3343,
        QC_09: 0.003,
        VC_09: 0.3343,
        QE_09: 0.003,
        VE_09: 0.3343,
        QA_10: 0.002,
        VA_10: 0.32929,
        QC_10: 0.002,
        VC_10: 0.32929,
        QE_10: 0.002,
        VE_10: 0.32929,
        QA_11: 0.002,
        VA_11: 0.32929,
        QC_11: 0.002,
        VC_11: 0.32929,
        QE_11: 0.002,
        VE_11: 0.32929,
        QA_12: 0.009,
        VA_12: 0.35483,
        QC_12: 0.009,
        VC_12: 0.35483,
        QE_12: 0.009,
        VE_12: 0.35483,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888428118218272, 34.593117479637897, 0.0],
          [-80.888843584884356, 34.593186079637917, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288580.0,
        COMID: 9757142,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.016,
        REACHCODE: "03050103017541",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00015930868685000001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062074.0,
        ToNode: 250062083.0,
        Hydroseq: 250004845.0,
        LevelPathI: 250004845.0,
        Pathlength: 375.158,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.735,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004862.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004805.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2832,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0019701124933399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10062.0,
        MINELEVSMO: 10047.0,
        SLOPE: 0.009375,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.016000000759958999,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888428118218272, 34.593117479637897, 0.0],
          [-80.888538918218117, 34.593003012971394, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288581.0,
        COMID: 9757144,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.034,
        REACHCODE: "03050103017542",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00035543660743999998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062076.0,
        ToNode: 250062083.0,
        Hydroseq: 250004821.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.158,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.793,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004846.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004805.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2868,
        DivDASqKM: 0.0387,
        Tidal: 0,
        TOTMA: 0.00286357487127,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10062.0,
        MINELEVSMO: 10047.0,
        SLOPE: 0.00441176,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.034000001847744002,
        QA_MA: 0.012,
        VA_MA: 0.45086,
        QC_MA: 0.012,
        VC_MA: 0.45086,
        QE_MA: 0.012,
        VE_MA: 0.45086,
        QA_01: 0.027,
        VA_01: 0.52754,
        QC_01: 0.027,
        VC_01: 0.52754,
        QE_01: 0.027,
        VE_01: 0.52754,
        QA_02: 0.03,
        VA_02: 0.54015,
        QC_02: 0.03,
        VC_02: 0.54015,
        QE_02: 0.03,
        VE_02: 0.54015,
        QA_03: 0.034,
        VA_03: 0.55608,
        QC_03: 0.034,
        VC_03: 0.55608,
        QE_03: 0.034,
        VE_03: 0.55608,
        QA_04: 0.017,
        VA_04: 0.47981,
        QC_04: 0.017,
        VC_04: 0.47981,
        QE_04: 0.017,
        VE_04: 0.47981,
        QA_05: 0.009,
        VA_05: 0.43068,
        QC_05: 0.009,
        VC_05: 0.43068,
        QE_05: 0.009,
        VE_05: 0.43068,
        QA_06: 0.006,
        VA_06: 0.40699,
        QC_06: 0.006,
        VC_06: 0.40699,
        QE_06: 0.006,
        VE_06: 0.40699,
        QA_07: 0.005,
        VA_07: 0.39789,
        QC_07: 0.005,
        VC_07: 0.39789,
        QE_07: 0.005,
        VE_07: 0.39789,
        QA_08: 0.004,
        VA_08: 0.38789,
        QC_08: 0.004,
        VC_08: 0.38789,
        QE_08: 0.004,
        VE_08: 0.38789,
        QA_09: 0.003,
        VA_09: 0.37663,
        QC_09: 0.003,
        VC_09: 0.37663,
        QE_09: 0.003,
        VE_09: 0.37663,
        QA_10: 0.002,
        VA_10: 0.36341,
        QC_10: 0.002,
        VC_10: 0.36341,
        QE_10: 0.002,
        VE_10: 0.36341,
        QA_11: 0.002,
        VA_11: 0.36341,
        QC_11: 0.002,
        VC_11: 0.36341,
        QE_11: 0.002,
        VE_11: 0.36341,
        QA_12: 0.009,
        VA_12: 0.43068,
        QC_12: 0.009,
        VC_12: 0.43068,
        QE_12: 0.009,
        VE_12: 0.43068,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888843584884356, 34.593186079637917, 0.0],
          [-80.888538918218117, 34.593003012971394, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288582.0,
        COMID: 9757146,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.103,
        REACHCODE: "03050103017543",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00095156148049600005,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062067.0,
        ToNode: 250062081.0,
        Hydroseq: 250004918.0,
        LevelPathI: 250004899.0,
        Pathlength: 374.993,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.411,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004919.0,
        UpHydroseq: 250004935.0,
        DnLevelPat: 250004899.0,
        DnMinorHyd: 250004898.0,
        DnDrainCou: 2,
        DnHydroseq: 250004899.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.012682598588200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10048.0,
        MINELEVSMO: 10048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887763384886, 34.593690279636974, 0.0],
          [-80.887347784886515, 34.592842812971639, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288575.0,
        COMID: 9757132,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.125,
        REACHCODE: "03050103017536",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001233411092964,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062070.0,
        ToNode: 250062071.0,
        Hydroseq: 250004966.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.29,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.221,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004999.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004883.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0081,
        TotDASqKM: 9867.1311,
        DivDASqKM: 0.0171,
        Tidal: 0,
        TOTMA: 0.0106377984046,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10169.0,
        MAXELEVSMO: 10319.0,
        MINELEVSMO: 10169.0,
        SLOPE: 0.012,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.125,
        QA_MA: 0.005,
        VA_MA: 0.4462,
        QC_MA: 0.005,
        VC_MA: 0.4462,
        QE_MA: 0.005,
        VE_MA: 0.4462,
        QA_01: 0.012,
        VA_01: 0.52775,
        QC_01: 0.012,
        VC_01: 0.52775,
        QE_01: 0.012,
        VE_01: 0.52775,
        QA_02: 0.013,
        VA_02: 0.53728,
        QC_02: 0.013,
        VC_02: 0.53728,
        QE_02: 0.013,
        VE_02: 0.53728,
        QA_03: 0.015,
        VA_03: 0.55535,
        QC_03: 0.015,
        VC_03: 0.55535,
        QE_03: 0.015,
        VE_03: 0.55535,
        QA_04: 0.007,
        VA_04: 0.47315,
        QC_04: 0.007,
        VC_04: 0.47315,
        QE_04: 0.007,
        VE_04: 0.47315,
        QA_05: 0.004,
        VA_05: 0.4308,
        QC_05: 0.004,
        VC_05: 0.4308,
        QE_05: 0.004,
        VE_05: 0.4308,
        QA_06: 0.002,
        VA_06: 0.39311,
        QC_06: 0.002,
        VC_06: 0.39311,
        QE_06: 0.002,
        VE_06: 0.39311,
        QA_07: 0.002,
        VA_07: 0.39311,
        QC_07: 0.002,
        VC_07: 0.39311,
        QE_07: 0.002,
        VE_07: 0.39311,
        QA_08: 0.001,
        VA_08: 0.36702,
        QC_08: 0.001,
        VC_08: 0.36702,
        QE_08: 0.001,
        VE_08: 0.36702,
        QA_09: 0.001,
        VA_09: 0.36702,
        QC_09: 0.001,
        VC_09: 0.36702,
        QE_09: 0.001,
        VE_09: 0.36702,
        QA_10: 0.001,
        VA_10: 0.36702,
        QC_10: 0.001,
        VC_10: 0.36702,
        QE_10: 0.001,
        VE_10: 0.36702,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.004,
        VA_12: 0.4308,
        QC_12: 0.004,
        VC_12: 0.4308,
        QE_12: 0.004,
        VE_12: 0.4308,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.889037584884022, 34.594400079636046, 0.0],
          [-80.888151184885373, 34.593552812970472, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288576.0,
        COMID: 9757134,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.035,
        REACHCODE: "03050103017537",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00033331024874899999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062071.0,
        ToNode: 250062073.0,
        Hydroseq: 250004883.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.255,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.378,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004966.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004862.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9867.2733,
        DivDASqKM: 0.0315,
        Tidal: 0,
        TOTMA: 0.00278427114246,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10089.0,
        MAXELEVSMO: 10169.0,
        MINELEVSMO: 10109.0,
        SLOPE: 0.01714285,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.035000000149012001,
        QA_MA: 0.01,
        VA_MA: 0.47734,
        QC_MA: 0.01,
        VC_MA: 0.47734,
        QE_MA: 0.01,
        VE_MA: 0.47734,
        QA_01: 0.022,
        VA_01: 0.56518,
        QC_01: 0.022,
        VC_01: 0.56518,
        QE_01: 0.022,
        VE_01: 0.56518,
        QA_02: 0.024,
        VA_02: 0.57732,
        QC_02: 0.024,
        VC_02: 0.57732,
        QE_02: 0.024,
        VE_02: 0.57732,
        QA_03: 0.027,
        VA_03: 0.59468,
        QC_03: 0.027,
        VC_03: 0.59468,
        QE_03: 0.027,
        VE_03: 0.59468,
        QA_04: 0.014,
        VA_04: 0.51039,
        QC_04: 0.014,
        VC_04: 0.51039,
        QE_04: 0.014,
        VE_04: 0.51039,
        QA_05: 0.008,
        VA_05: 0.45846,
        QC_05: 0.008,
        VC_05: 0.45846,
        QE_05: 0.008,
        VE_05: 0.45846,
        QA_06: 0.005,
        VA_06: 0.42531,
        QC_06: 0.005,
        VC_06: 0.42531,
        QE_06: 0.005,
        VE_06: 0.42531,
        QA_07: 0.004,
        VA_07: 0.41225,
        QC_07: 0.004,
        VC_07: 0.41225,
        QE_07: 0.004,
        VE_07: 0.41225,
        QA_08: 0.003,
        VA_08: 0.39754,
        QC_08: 0.003,
        VC_08: 0.39754,
        QE_08: 0.003,
        VE_08: 0.39754,
        QA_09: 0.002,
        VA_09: 0.38027,
        QC_09: 0.002,
        VC_09: 0.38027,
        QE_09: 0.002,
        VE_09: 0.38027,
        QA_10: 0.002,
        VA_10: 0.38027,
        QC_10: 0.002,
        VC_10: 0.38027,
        QE_10: 0.002,
        VE_10: 0.38027,
        QA_11: 0.001,
        VA_11: 0.35813,
        QC_11: 0.001,
        VC_11: 0.35813,
        QE_11: 0.001,
        VE_11: 0.35813,
        QA_12: 0.008,
        VA_12: 0.45846,
        QC_12: 0.008,
        VC_12: 0.45846,
        QE_12: 0.008,
        VE_12: 0.45846,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888151184885373, 34.593552812970472, 0.0],
          [-80.888317318218355, 34.593300812970938, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288577.0,
        COMID: 9757136,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.213,
        REACHCODE: "03050103017538",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0021616830086880002,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062065.0,
        ToNode: 250062073.0,
        Hydroseq: 250004965.0,
        LevelPathI: 250004965.0,
        Pathlength: 375.255,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.036,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004981.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004862.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.1239,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.019101128675500002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10109.0,
        MAXELEVSMO: 10346.0,
        MINELEVSMO: 10109.0,
        SLOPE: 0.03761904,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.063000001013279003,
        QA_MA: 0.001,
        VA_MA: 0.42344,
        QC_MA: 0.001,
        VC_MA: 0.42344,
        QE_MA: 0.001,
        VE_MA: 0.42344,
        QA_01: 0.002,
        VA_01: 0.47463,
        QC_01: 0.002,
        VC_01: 0.47463,
        QE_01: 0.002,
        VE_01: 0.47463,
        QA_02: 0.002,
        VA_02: 0.47463,
        QC_02: 0.002,
        VC_02: 0.47463,
        QE_02: 0.002,
        VE_02: 0.47463,
        QA_03: 0.003,
        VA_03: 0.51457,
        QC_03: 0.003,
        VC_03: 0.51457,
        QE_03: 0.003,
        VE_03: 0.51457,
        QA_04: 0.001,
        VA_04: 0.42344,
        QC_04: 0.001,
        VC_04: 0.42344,
        QE_04: 0.001,
        VE_04: 0.42344,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890090318215641, 34.594537479635683, 0.0],
          [-80.888317318218355, 34.593300812970938, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288578.0,
        COMID: 9757138,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.023,
        REACHCODE: "03050103017539",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000214214264189,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062073.0,
        ToNode: 250062074.0,
        Hydroseq: 250004862.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.232,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.719,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004883.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004845.0,
        DnDrainCou: 2,
        DnHydroseq: 250004846.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2832,
        DivDASqKM: 0.0351,
        Tidal: 0,
        TOTMA: 0.0017959156936000001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10109.0,
        MINELEVSMO: 10062.0,
        SLOPE: 0.02043478,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.023000000044703001,
        QA_MA: 0.011,
        VA_MA: 0.48631,
        QC_MA: 0.011,
        VC_MA: 0.48631,
        QE_MA: 0.011,
        VE_MA: 0.48631,
        QA_01: 0.025,
        VA_01: 0.58353,
        QC_01: 0.025,
        VC_01: 0.58353,
        QE_01: 0.025,
        VE_01: 0.58353,
        QA_02: 0.027,
        VA_02: 0.595,
        QC_02: 0.027,
        VC_02: 0.595,
        QE_02: 0.027,
        VE_02: 0.595,
        QA_03: 0.031,
        VA_03: 0.61682,
        QC_03: 0.031,
        VC_03: 0.61682,
        QE_03: 0.031,
        VE_03: 0.61682,
        QA_04: 0.016,
        VA_04: 0.52547,
        QC_04: 0.016,
        VC_04: 0.52547,
        QE_04: 0.016,
        VE_04: 0.52547,
        QA_05: 0.008,
        VA_05: 0.45863,
        QC_05: 0.008,
        VC_05: 0.45863,
        QE_05: 0.008,
        VE_05: 0.45863,
        QA_06: 0.006,
        VA_06: 0.43735,
        QC_06: 0.006,
        VC_06: 0.43735,
        QE_06: 0.006,
        VE_06: 0.43735,
        QA_07: 0.004,
        VA_07: 0.41237,
        QC_07: 0.004,
        VC_07: 0.41237,
        QE_07: 0.004,
        VE_07: 0.41237,
        QA_08: 0.003,
        VA_08: 0.39764,
        QC_08: 0.003,
        VC_08: 0.39764,
        QE_08: 0.003,
        VE_08: 0.39764,
        QA_09: 0.002,
        VA_09: 0.38035,
        QC_09: 0.002,
        VC_09: 0.38035,
        QE_09: 0.002,
        VE_09: 0.38035,
        QA_10: 0.002,
        VA_10: 0.38035,
        QC_10: 0.002,
        VC_10: 0.38035,
        QE_10: 0.002,
        VE_10: 0.38035,
        QA_11: 0.001,
        VA_11: 0.35819,
        QC_11: 0.001,
        VC_11: 0.35819,
        QE_11: 0.001,
        VE_11: 0.35819,
        QA_12: 0.008,
        VA_12: 0.45863,
        QC_12: 0.008,
        VC_12: 0.45863,
        QE_12: 0.008,
        VE_12: 0.45863,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888317318218355, 34.593300812970938, 0.0],
          [-80.888428118218272, 34.593117479637897, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288555.0,
        COMID: 9757092,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.075,
        REACHCODE: "03050103017516",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00078562460205400004,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062050.0,
        ToNode: 250062057.0,
        Hydroseq: 250005019.0,
        LevelPathI: 250004457.0,
        Pathlength: 375.295,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.579,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005036.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004999.0,
        DnDrainCou: 2,
        DnHydroseq: 250005000.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9867.0933,
        DivDASqKM: 0.018,
        Tidal: 0,
        TOTMA: 0.0080594037469399991,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10488.0,
        MAXELEVSMO: 10488.0,
        MINELEVSMO: 10488.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.075000002980232003,
        QA_MA: 0.005,
        VA_MA: 0.35337,
        QC_MA: 0.005,
        VC_MA: 0.35337,
        QE_MA: 0.005,
        VE_MA: 0.35337,
        QA_01: 0.012,
        VA_01: 0.37999,
        QC_01: 0.012,
        VC_01: 0.37999,
        QE_01: 0.012,
        VE_01: 0.37999,
        QA_02: 0.014,
        VA_02: 0.38609,
        QC_02: 0.014,
        VC_02: 0.38609,
        QE_02: 0.014,
        VE_02: 0.38609,
        QA_03: 0.015,
        VA_03: 0.38899,
        QC_03: 0.015,
        VC_03: 0.38899,
        QE_03: 0.015,
        VE_03: 0.38899,
        QA_04: 0.008,
        VA_04: 0.36612,
        QC_04: 0.008,
        VC_04: 0.36612,
        QE_04: 0.008,
        VE_04: 0.36612,
        QA_05: 0.004,
        VA_05: 0.34834,
        QC_05: 0.004,
        VC_05: 0.34834,
        QE_05: 0.004,
        VE_05: 0.34834,
        QA_06: 0.003,
        VA_06: 0.34268,
        QC_06: 0.003,
        VC_06: 0.34268,
        QE_06: 0.003,
        VE_06: 0.34268,
        QA_07: 0.002,
        VA_07: 0.33604,
        QC_07: 0.002,
        VC_07: 0.33604,
        QE_07: 0.002,
        VE_07: 0.33604,
        QA_08: 0.001,
        VA_08: 0.32753,
        QC_08: 0.001,
        VC_08: 0.32753,
        QE_08: 0.001,
        VE_08: 0.32753,
        QA_09: 0.001,
        VA_09: 0.32753,
        QC_09: 0.001,
        VC_09: 0.32753,
        QE_09: 0.001,
        VE_09: 0.32753,
        QA_10: 0.001,
        VA_10: 0.32753,
        QC_10: 0.001,
        VC_10: 0.32753,
        QE_10: 0.001,
        VE_10: 0.32753,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.004,
        VA_12: 0.34834,
        QC_12: 0.004,
        VC_12: 0.34834,
        QE_12: 0.004,
        VE_12: 0.34834,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.889176384883797, 34.596209679633148, 0.0],
          [-80.88986891821601, 34.596003479633509, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288556.0,
        COMID: 9757094,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.034,
        REACHCODE: "03050103017517",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000338587299938,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062050.0,
        ToNode: 250062053.0,
        Hydroseq: 250005018.0,
        LevelPathI: 250005002.0,
        Pathlength: 375.408,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.538,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005036.0,
        DnLevelPat: 250005002.0,
        DnMinorHyd: 250004998.0,
        DnDrainCou: 2,
        DnHydroseq: 250005002.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.0915,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0041864890770099998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10488.0,
        MINELEVSMO: 10488.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.034000001847744002,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.889176384883797, 34.596209679633148, 0.0],
          [-80.888926984884222, 34.595980679633385, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288557.0,
        COMID: 9757096,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.082,
        REACHCODE: "03050103017518",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00073338619388400004,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062052.0,
        ToNode: 250062055.0,
        Hydroseq: 250005003.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.377,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.587,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250005020.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004982.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9867.1914,
        DivDASqKM: 0.1161,
        Tidal: 0,
        TOTMA: 0.00619900511884,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10462.0,
        MAXELEVSMO: 10512.0,
        MINELEVSMO: 10462.0,
        SLOPE: 0.00609756,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.082000002264977001,
        QA_MA: 0.038,
        VA_MA: 0.5023,
        QC_MA: 0.038,
        VC_MA: 0.5023,
        QE_MA: 0.038,
        VE_MA: 0.5023,
        QA_01: 0.082,
        VA_01: 0.60011,
        QC_01: 0.082,
        VC_01: 0.60011,
        QE_01: 0.082,
        VE_01: 0.60011,
        QA_02: 0.092,
        VA_02: 0.61849,
        QC_02: 0.092,
        VC_02: 0.61849,
        QE_02: 0.092,
        VE_02: 0.61849,
        QA_03: 0.103,
        VA_03: 0.63766,
        QC_03: 0.103,
        VC_03: 0.63766,
        QE_03: 0.103,
        VE_03: 0.63766,
        QA_04: 0.053,
        VA_04: 0.53977,
        QC_04: 0.053,
        VC_04: 0.53977,
        QE_04: 0.053,
        VE_04: 0.53977,
        QA_05: 0.029,
        VA_05: 0.47637,
        QC_05: 0.029,
        VC_05: 0.47637,
        QE_05: 0.029,
        VE_05: 0.47637,
        QA_06: 0.02,
        VA_06: 0.4463,
        QC_06: 0.02,
        VC_06: 0.4463,
        QE_06: 0.02,
        VE_06: 0.4463,
        QA_07: 0.015,
        VA_07: 0.42676,
        QC_07: 0.015,
        VC_07: 0.42676,
        QE_07: 0.015,
        VE_07: 0.42676,
        QA_08: 0.012,
        VA_08: 0.41353,
        QC_08: 0.012,
        VC_08: 0.41353,
        QE_08: 0.012,
        VE_08: 0.41353,
        QA_09: 0.009,
        VA_09: 0.39864,
        QC_09: 0.009,
        VC_09: 0.39864,
        QE_09: 0.009,
        VE_09: 0.39864,
        QA_10: 0.007,
        VA_10: 0.38736,
        QC_10: 0.007,
        VC_10: 0.38736,
        QE_10: 0.007,
        VE_10: 0.38736,
        QA_11: 0.006,
        VA_11: 0.38116,
        QC_11: 0.006,
        VC_11: 0.38116,
        QE_11: 0.006,
        VE_11: 0.38116,
        QA_12: 0.029,
        VA_12: 0.47637,
        QC_12: 0.029,
        VC_12: 0.47637,
        QE_12: 0.029,
        VE_12: 0.47637,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888566984884733, 34.596667879632378, 0.0],
          [-80.888594584884686, 34.595935012966891, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288558.0,
        COMID: 9757098,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.031,
        REACHCODE: "03050103017519",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000335522285579,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062053.0,
        ToNode: 250062055.0,
        Hydroseq: 250005002.0,
        LevelPathI: 250005002.0,
        Pathlength: 375.377,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.569,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250005002.0,
        UpHydroseq: 250005018.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004982.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9867.0942,
        DivDASqKM: 0.0027,
        Tidal: 0,
        TOTMA: 0.00381709271216,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10449.0,
        MAXELEVSMO: 10462.0,
        MINELEVSMO: 10462.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.030999999493361002,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.001,
        VA_01: 0.30839,
        QC_01: 0.001,
        VC_01: 0.30839,
        QE_01: 0.001,
        VE_01: 0.30839,
        QA_02: 0.002,
        VA_02: 0.30839,
        QC_02: 0.002,
        VC_02: 0.30839,
        QE_02: 0.002,
        VE_02: 0.30839,
        QA_03: 0.002,
        VA_03: 0.30839,
        QC_03: 0.002,
        VC_03: 0.30839,
        QE_03: 0.002,
        VE_03: 0.30839,
        QA_04: 0.001,
        VA_04: 0.30839,
        QC_04: 0.001,
        VC_04: 0.30839,
        QE_04: 0.001,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888926984884222, 34.595980679633385, 0.0],
          [-80.888594584884686, 34.595935012966891, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285325.0,
        COMID: 9753012,
        FDATE: "2008/12/10 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1014092",
        GNIS_NAME: "Old Catawba River",
        LENGTHKM: 0.826,
        REACHCODE: "03050101001615",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0084962217997249997,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 0,
        FromNode: 250061195.0,
        ToNode: 250061206.0,
        Hydroseq: 250014159.0,
        LevelPathI: 250014159.0,
        Pathlength: 654.122,
        TerminalPa: 250002604.0,
        ArbolateSu: 1154.393,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250014293.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013802.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.288,
        TotDASqKM: 1288.2321,
        DivDASqKM: 0.288,
        Tidal: 0,
        TOTMA: 0.066737812292900003,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31955.0,
        MAXELEVSMO: 32075.0,
        MINELEVSMO: 32048.0,
        SLOPE: 0.0003994,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.67599999904632602,
        QA_MA: 0.159,
        VA_MA: 0.46998,
        QC_MA: 0.159,
        VC_MA: 0.46998,
        QE_MA: 0.159,
        VE_MA: 0.46998,
        QA_01: 0.278,
        VA_01: 0.5258,
        QC_01: 0.278,
        VC_01: 0.5258,
        QE_01: 0.278,
        VE_01: 0.5258,
        QA_02: 0.298,
        VA_02: 0.53397,
        QC_02: 0.298,
        VC_02: 0.53397,
        QE_02: 0.298,
        VE_02: 0.53397,
        QA_03: 0.32,
        VA_03: 0.54266,
        QC_03: 0.32,
        VC_03: 0.54266,
        QE_03: 0.32,
        VE_03: 0.54266,
        QA_04: 0.24,
        VA_04: 0.50947,
        QC_04: 0.24,
        VC_04: 0.50947,
        QE_04: 0.24,
        VE_04: 0.50947,
        QA_05: 0.178,
        VA_05: 0.47997,
        QC_05: 0.178,
        VC_05: 0.47997,
        QE_05: 0.178,
        VE_05: 0.47997,
        QA_06: 0.1,
        VA_06: 0.43471,
        QC_06: 0.1,
        VC_06: 0.43471,
        QE_06: 0.1,
        VE_06: 0.43471,
        QA_07: 0.058,
        VA_07: 0.40298,
        QC_07: 0.058,
        VC_07: 0.40298,
        QE_07: 0.058,
        VE_07: 0.40298,
        QA_08: 0.038,
        VA_08: 0.38396,
        QC_08: 0.038,
        VC_08: 0.38396,
        QE_08: 0.038,
        VE_08: 0.38396,
        QA_09: 0.03,
        VA_09: 0.37505,
        QC_09: 0.03,
        VC_09: 0.37505,
        QE_09: 0.03,
        VE_09: 0.37505,
        QA_10: 0.023,
        VA_10: 0.36627,
        QC_10: 0.023,
        VC_10: 0.36627,
        QE_10: 0.023,
        VE_10: 0.36627,
        QA_11: 0.135,
        VA_11: 0.45654,
        QC_11: 0.135,
        VC_11: 0.45654,
        QE_11: 0.135,
        VE_11: 0.45654,
        QA_12: 0.209,
        VA_12: 0.49524,
        QC_12: 0.209,
        VC_12: 0.49524,
        QE_12: 0.209,
        VE_12: 0.49524,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.832616583419394, 35.732362077869595, 0.0],
          [-81.829781383423722, 35.729178077874565, 0.0],
          [-81.825906316763167, 35.728054611209586, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288553.0,
        COMID: 9757088,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.062,
        REACHCODE: "03050103017514",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00054993334864299995,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062048.0,
        ToNode: 250062052.0,
        Hydroseq: 250005020.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.459,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.505,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250005037.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 250005001.0,
        DnDrainCou: 2,
        DnHydroseq: 250005003.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9867.1851,
        DivDASqKM: 0.1098,
        Tidal: 0,
        TOTMA: 0.0047085187156299998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10512.0,
        MAXELEVSMO: 10550.0,
        MINELEVSMO: 10512.0,
        SLOPE: 0.00612903,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.061999998986720997,
        QA_MA: 0.036,
        VA_MA: 0.50001,
        QC_MA: 0.036,
        VC_MA: 0.50001,
        QE_MA: 0.036,
        VE_MA: 0.50001,
        QA_01: 0.078,
        VA_01: 0.59729,
        QC_01: 0.078,
        VC_01: 0.59729,
        QE_01: 0.078,
        VE_01: 0.59729,
        QA_02: 0.087,
        VA_02: 0.61454,
        QC_02: 0.087,
        VC_02: 0.61454,
        QE_02: 0.087,
        VE_02: 0.61454,
        QA_03: 0.097,
        VA_03: 0.63275,
        QC_03: 0.097,
        VC_03: 0.63275,
        QE_03: 0.097,
        VE_03: 0.63275,
        QA_04: 0.05,
        VA_04: 0.53653,
        QC_04: 0.05,
        VC_04: 0.53653,
        QE_04: 0.05,
        VE_04: 0.53653,
        QA_05: 0.028,
        VA_05: 0.47607,
        QC_05: 0.028,
        VC_05: 0.47607,
        QE_05: 0.028,
        VE_05: 0.47607,
        QA_06: 0.019,
        VA_06: 0.44487,
        QC_06: 0.019,
        VC_06: 0.44487,
        QE_06: 0.019,
        VE_06: 0.44487,
        QA_07: 0.014,
        VA_07: 0.42444,
        QC_07: 0.014,
        VC_07: 0.42444,
        QE_07: 0.014,
        VE_07: 0.42444,
        QA_08: 0.011,
        VA_08: 0.41049,
        QC_08: 0.011,
        VC_08: 0.41049,
        QE_08: 0.011,
        VE_08: 0.41049,
        QA_09: 0.008,
        VA_09: 0.39461,
        QC_09: 0.008,
        VC_09: 0.39461,
        QE_09: 0.008,
        VE_09: 0.39461,
        QA_10: 0.007,
        VA_10: 0.38871,
        QC_10: 0.007,
        VC_10: 0.38871,
        QE_10: 0.007,
        VE_10: 0.38871,
        QA_11: 0.005,
        VA_11: 0.37556,
        QC_11: 0.005,
        VC_11: 0.37556,
        QE_11: 0.005,
        VE_11: 0.37556,
        QA_12: 0.028,
        VA_12: 0.47607,
        QC_12: 0.028,
        VC_12: 0.47607,
        QE_12: 0.028,
        VE_12: 0.47607,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888567118218077, 34.597217812964857, 0.0],
          [-80.888566984884733, 34.596667879632378, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288563.0,
        COMID: 9757108,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.04,
        REACHCODE: "03050103017524",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00043507801176300001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062058.0,
        ToNode: 250062063.0,
        Hydroseq: 250004953.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.251,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.144,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004968.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004937.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9867.2238,
        DivDASqKM: 0.1323,
        Tidal: 0,
        TOTMA: 0.0039912428354499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10438.0,
        MAXELEVSMO: 10438.0,
        MINELEVSMO: 10438.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.039999999105930002,
        QA_MA: 0.044,
        VA_MA: 0.38056,
        QC_MA: 0.044,
        VC_MA: 0.38056,
        QE_MA: 0.044,
        VE_MA: 0.38056,
        QA_01: 0.094,
        VA_01: 0.41639,
        QC_01: 0.094,
        VC_01: 0.41639,
        QE_01: 0.094,
        VE_01: 0.41639,
        QA_02: 0.104,
        VA_02: 0.42234,
        QC_02: 0.104,
        VC_02: 0.42234,
        QE_02: 0.104,
        VE_02: 0.42234,
        QA_03: 0.117,
        VA_03: 0.4297,
        QC_03: 0.117,
        VC_03: 0.4297,
        QE_03: 0.117,
        VE_03: 0.4297,
        QA_04: 0.06,
        VA_04: 0.39348,
        QC_04: 0.06,
        VC_04: 0.39348,
        QE_04: 0.06,
        VE_04: 0.39348,
        QA_05: 0.033,
        VA_05: 0.37034,
        QC_05: 0.033,
        VC_05: 0.37034,
        QE_05: 0.033,
        VE_05: 0.37034,
        QA_06: 0.023,
        VA_06: 0.35953,
        QC_06: 0.023,
        VC_06: 0.35953,
        QE_06: 0.023,
        VE_06: 0.35953,
        QA_07: 0.017,
        VA_07: 0.35195,
        QC_07: 0.017,
        VC_07: 0.35195,
        QE_07: 0.017,
        VE_07: 0.35195,
        QA_08: 0.014,
        VA_08: 0.34768,
        QC_08: 0.014,
        VC_08: 0.34768,
        QE_08: 0.014,
        VE_08: 0.34768,
        QA_09: 0.01,
        VA_09: 0.34125,
        QC_09: 0.01,
        VC_09: 0.34125,
        QE_09: 0.01,
        VE_09: 0.34125,
        QA_10: 0.008,
        VA_10: 0.33758,
        QC_10: 0.008,
        VC_10: 0.33758,
        QE_10: 0.008,
        VE_10: 0.33758,
        QA_11: 0.007,
        VA_11: 0.33558,
        QC_11: 0.007,
        VC_11: 0.33558,
        QE_11: 0.007,
        VE_11: 0.33558,
        QA_12: 0.033,
        VA_12: 0.37034,
        QC_12: 0.033,
        VC_12: 0.37034,
        QE_12: 0.033,
        VE_12: 0.37034,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887985118218921, 34.595408079634353, 0.0],
          [-80.887569584886307, 34.595339479634561, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288564.0,
        COMID: 9757110,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.105,
        REACHCODE: "03050103017525",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00099340076801800008,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062059.0,
        ToNode: 250062065.0,
        Hydroseq: 250004981.0,
        LevelPathI: 250004457.0,
        Pathlength: 375.051,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.823,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005000.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004965.0,
        DnDrainCou: 2,
        DnHydroseq: 250004967.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9867.1203,
        DivDASqKM: 0.045,
        Tidal: 0,
        TOTMA: 0.0086279144377199996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10346.0,
        MAXELEVSMO: 10406.0,
        MINELEVSMO: 10346.0,
        SLOPE: 0.00571428,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.104999996721745,
        QA_MA: 0.014,
        VA_MA: 0.46212,
        QC_MA: 0.014,
        VC_MA: 0.46212,
        QE_MA: 0.014,
        VE_MA: 0.46212,
        QA_01: 0.032,
        VA_01: 0.54684,
        QC_01: 0.032,
        VC_01: 0.54684,
        QE_01: 0.032,
        VE_01: 0.54684,
        QA_02: 0.035,
        VA_02: 0.55846,
        QC_02: 0.035,
        VC_02: 0.55846,
        QE_02: 0.035,
        VE_02: 0.55846,
        QA_03: 0.039,
        VA_03: 0.57325,
        QC_03: 0.039,
        VC_03: 0.57325,
        QE_03: 0.039,
        VE_03: 0.57325,
        QA_04: 0.02,
        VA_04: 0.49418,
        QC_04: 0.02,
        VC_04: 0.49418,
        QE_04: 0.02,
        VE_04: 0.49418,
        QA_05: 0.011,
        VA_05: 0.44364,
        QC_05: 0.011,
        VC_05: 0.44364,
        QE_05: 0.011,
        VE_05: 0.44364,
        QA_06: 0.007,
        VA_06: 0.41478,
        QC_06: 0.007,
        VC_06: 0.41478,
        QE_06: 0.007,
        VE_06: 0.41478,
        QA_07: 0.006,
        VA_07: 0.40642,
        QC_07: 0.006,
        VC_07: 0.40642,
        QE_07: 0.006,
        VE_07: 0.40642,
        QA_08: 0.004,
        VA_08: 0.38743,
        QC_08: 0.004,
        VC_08: 0.38743,
        QE_08: 0.004,
        VE_08: 0.38743,
        QA_09: 0.003,
        VA_09: 0.37624,
        QC_09: 0.003,
        VC_09: 0.37624,
        QE_09: 0.003,
        VE_09: 0.37624,
        QA_10: 0.003,
        VA_10: 0.37624,
        QC_10: 0.003,
        VC_10: 0.37624,
        QE_10: 0.003,
        VE_10: 0.37624,
        QA_11: 0.002,
        VA_11: 0.36309,
        QC_11: 0.002,
        VC_11: 0.36309,
        QE_11: 0.002,
        VE_11: 0.36309,
        QA_12: 0.011,
        VA_12: 0.44364,
        QC_12: 0.011,
        VC_12: 0.44364,
        QE_12: 0.011,
        VE_12: 0.44364,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890644384881469, 34.595362012967769, 0.0],
          [-80.890090318215641, 34.594537479635683, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288565.0,
        COMID: 9757112,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.195,
        REACHCODE: "03050103017526",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018061026163310001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062057.0,
        ToNode: 250062070.0,
        Hydroseq: 250004999.0,
        LevelPathI: 250004607.0,
        Pathlength: 375.415,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.774,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005019.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004966.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 9867.1023,
        DivDASqKM: 0.009,
        Tidal: 0,
        TOTMA: 0.0165349322038,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10319.0,
        MAXELEVSMO: 10488.0,
        MINELEVSMO: 10319.0,
        SLOPE: 0.03755555,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.045000001788139003,
        QA_MA: 0.002,
        VA_MA: 0.44782,
        QC_MA: 0.002,
        VC_MA: 0.44782,
        QE_MA: 0.002,
        VE_MA: 0.44782,
        QA_01: 0.006,
        VA_01: 0.55826,
        QC_01: 0.006,
        VC_01: 0.55826,
        QE_01: 0.006,
        VE_01: 0.55826,
        QA_02: 0.007,
        VA_02: 0.57957,
        QC_02: 0.007,
        VC_02: 0.57957,
        QE_02: 0.007,
        VE_02: 0.57957,
        QA_03: 0.007,
        VA_03: 0.57957,
        QC_03: 0.007,
        VC_03: 0.57957,
        QE_03: 0.007,
        VE_03: 0.57957,
        QA_04: 0.004,
        VA_04: 0.50986,
        QC_04: 0.004,
        VC_04: 0.50986,
        QE_04: 0.004,
        VE_04: 0.50986,
        QA_05: 0.002,
        VA_05: 0.44782,
        QC_05: 0.002,
        VC_05: 0.44782,
        QE_05: 0.002,
        VE_05: 0.44782,
        QA_06: 0.001,
        VA_06: 0.40489,
        QC_06: 0.001,
        VC_06: 0.40489,
        QE_06: 0.001,
        VE_06: 0.40489,
        QA_07: 0.001,
        VA_07: 0.40489,
        QC_07: 0.001,
        VC_07: 0.40489,
        QE_07: 0.001,
        VE_07: 0.40489,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.44782,
        QC_12: 0.002,
        VC_12: 0.44782,
        QE_12: 0.002,
        VE_12: 0.44782,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88986891821601, 34.596003479633509, 0.0],
          [-80.889037584884022, 34.594400079636046, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288566.0,
        COMID: 9757114,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.183,
        REACHCODE: "03050103017527",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0018870533570029999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062059.0,
        ToNode: 250062070.0,
        Hydroseq: 250004980.0,
        LevelPathI: 250004980.0,
        Pathlength: 375.415,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.901,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005000.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004966.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.114,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0225331603668,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10406.0,
        MINELEVSMO: 10319.0,
        SLOPE: 0.02636363,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.032999999821186003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890644384881469, 34.595362012967769, 0.0],
          [-80.889037584884022, 34.594400079636046, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288559.0,
        COMID: 9757100,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.104,
        REACHCODE: "03050103017520",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00097065744082300005,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062052.0,
        ToNode: 250062056.0,
        Hydroseq: 250005001.0,
        LevelPathI: 250005001.0,
        Pathlength: 375.344,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.609,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250005020.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004968.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.1851,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0128057308627,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10512.0,
        MINELEVSMO: 10438.0,
        SLOPE: 0.00711538,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.104000002145767,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888566984884733, 34.596667879632378, 0.0],
          [-80.88826218488515, 34.595820412967157, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288560.0,
        COMID: 9757102,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.033,
        REACHCODE: "03050103017521",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00035160051141900001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062055.0,
        ToNode: 250062056.0,
        Hydroseq: 250004982.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.344,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.947,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250005003.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004968.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9867.2148,
        DivDASqKM: 0.1233,
        Tidal: 0,
        TOTMA: 0.0024529678072300002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10400.0,
        MAXELEVSMO: 10462.0,
        MINELEVSMO: 10438.0,
        SLOPE: 0.00727272,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.032999999821186003,
        QA_MA: 0.041,
        VA_MA: 0.51085,
        QC_MA: 0.041,
        VC_MA: 0.51085,
        QE_MA: 0.041,
        VE_MA: 0.51085,
        QA_01: 0.087,
        VA_01: 0.61026,
        QC_01: 0.087,
        VC_01: 0.61026,
        QE_01: 0.087,
        VE_01: 0.61026,
        QA_02: 0.097,
        VA_02: 0.62822,
        QC_02: 0.097,
        VC_02: 0.62822,
        QE_02: 0.097,
        VE_02: 0.62822,
        QA_03: 0.109,
        VA_03: 0.64865,
        QC_03: 0.109,
        VC_03: 0.64865,
        QE_03: 0.109,
        VE_03: 0.64865,
        QA_04: 0.056,
        VA_04: 0.5473,
        QC_04: 0.056,
        VC_04: 0.5473,
        QE_04: 0.056,
        VE_04: 0.5473,
        QA_05: 0.031,
        VA_05: 0.48291,
        QC_05: 0.031,
        VC_05: 0.48291,
        QE_05: 0.031,
        VE_05: 0.48291,
        QA_06: 0.021,
        VA_06: 0.45031,
        QC_06: 0.021,
        VC_06: 0.45031,
        QE_06: 0.021,
        VE_06: 0.45031,
        QA_07: 0.016,
        VA_07: 0.43123,
        QC_07: 0.016,
        VC_07: 0.43123,
        QE_07: 0.016,
        VE_07: 0.43123,
        QA_08: 0.013,
        VA_08: 0.4184,
        QC_08: 0.013,
        VC_08: 0.4184,
        QE_08: 0.013,
        VE_08: 0.4184,
        QA_09: 0.009,
        VA_09: 0.39889,
        QC_09: 0.009,
        VC_09: 0.39889,
        QE_09: 0.009,
        VE_09: 0.39889,
        QA_10: 0.008,
        VA_10: 0.3934,
        QC_10: 0.008,
        VC_10: 0.3934,
        QE_10: 0.008,
        VE_10: 0.3934,
        QA_11: 0.006,
        VA_11: 0.38136,
        QC_11: 0.006,
        VC_11: 0.38136,
        QE_11: 0.006,
        VE_11: 0.38136,
        QA_12: 0.031,
        VA_12: 0.48291,
        QC_12: 0.031,
        VC_12: 0.48291,
        QE_12: 0.031,
        VE_12: 0.48291,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888594584884686, 34.595935012966891, 0.0],
          [-80.88826218488515, 34.595820412967157, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288561.0,
        COMID: 9757104,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.053,
        REACHCODE: "03050103017522",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000496774309799,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062056.0,
        ToNode: 250062058.0,
        Hydroseq: 250004968.0,
        LevelPathI: 250004844.0,
        Pathlength: 375.291,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.104,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004982.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 250004952.0,
        DnDrainCou: 2,
        DnHydroseq: 250004953.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.1278,
        Tidal: 0,
        TOTMA: 0.0052973054618100004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10392.0,
        MAXELEVSMO: 10438.0,
        MINELEVSMO: 10438.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052999999374151001,
        QA_MA: 0.042,
        VA_MA: 0.37992,
        QC_MA: 0.042,
        VC_MA: 0.37992,
        QE_MA: 0.042,
        VE_MA: 0.37992,
        QA_01: 0.091,
        VA_01: 0.41623,
        QC_01: 0.091,
        VC_01: 0.41623,
        QE_01: 0.091,
        VE_01: 0.41623,
        QA_02: 0.101,
        VA_02: 0.42237,
        QC_02: 0.101,
        VC_02: 0.42237,
        QE_02: 0.101,
        VE_02: 0.42237,
        QA_03: 0.113,
        VA_03: 0.42937,
        QC_03: 0.113,
        VC_03: 0.42937,
        QE_03: 0.113,
        VE_03: 0.42937,
        QA_04: 0.058,
        VA_04: 0.39329,
        QC_04: 0.058,
        VC_04: 0.39329,
        QE_04: 0.058,
        VE_04: 0.39329,
        QA_05: 0.032,
        VA_05: 0.3703,
        QC_05: 0.032,
        VC_05: 0.3703,
        QE_05: 0.032,
        VE_05: 0.3703,
        QA_06: 0.022,
        VA_06: 0.35913,
        QC_06: 0.022,
        VC_06: 0.35913,
        QE_06: 0.022,
        VE_06: 0.35913,
        QA_07: 0.017,
        VA_07: 0.35264,
        QC_07: 0.017,
        VC_07: 0.35264,
        QE_07: 0.017,
        VE_07: 0.35264,
        QA_08: 0.013,
        VA_08: 0.34676,
        QC_08: 0.013,
        VC_08: 0.34676,
        QE_08: 0.013,
        VE_08: 0.34676,
        QA_09: 0.01,
        VA_09: 0.34177,
        QC_09: 0.01,
        VC_09: 0.34177,
        QE_09: 0.01,
        VE_09: 0.34177,
        QA_10: 0.008,
        VA_10: 0.33804,
        QC_10: 0.008,
        VC_10: 0.33804,
        QE_10: 0.008,
        VE_10: 0.33804,
        QA_11: 0.006,
        VA_11: 0.33384,
        QC_11: 0.006,
        VC_11: 0.33384,
        QE_11: 0.006,
        VE_11: 0.33384,
        QA_12: 0.032,
        VA_12: 0.3703,
        QC_12: 0.032,
        VC_12: 0.3703,
        QE_12: 0.032,
        VE_12: 0.3703,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88826218488515, 34.595820412967157, 0.0],
          [-80.887985118218921, 34.595408079634353, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288562.0,
        COMID: 9757106,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.139,
        REACHCODE: "03050103017523",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001382891890186,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062057.0,
        ToNode: 250062059.0,
        Hydroseq: 250005000.0,
        LevelPathI: 250004457.0,
        Pathlength: 375.156,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.718,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250005019.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 250004980.0,
        DnDrainCou: 2,
        DnHydroseq: 250004981.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 9867.114,
        DivDASqKM: 0.0387,
        Tidal: 0,
        TOTMA: 0.0115345349354,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10406.0,
        MAXELEVSMO: 10488.0,
        MINELEVSMO: 10406.0,
        SLOPE: 0.00589928,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.138999998569489,
        QA_MA: 0.012,
        VA_MA: 0.4576,
        QC_MA: 0.012,
        VC_MA: 0.4576,
        QE_MA: 0.012,
        VE_MA: 0.4576,
        QA_01: 0.027,
        VA_01: 0.5379,
        QC_01: 0.027,
        VC_01: 0.5379,
        QE_01: 0.027,
        VE_01: 0.5379,
        QA_02: 0.03,
        VA_02: 0.55111,
        QC_02: 0.03,
        VC_02: 0.55111,
        QE_02: 0.03,
        VE_02: 0.55111,
        QA_03: 0.034,
        VA_03: 0.56779,
        QC_03: 0.034,
        VC_03: 0.56779,
        QE_03: 0.034,
        VE_03: 0.56779,
        QA_04: 0.017,
        VA_04: 0.48791,
        QC_04: 0.017,
        VC_04: 0.48791,
        QE_04: 0.017,
        VE_04: 0.48791,
        QA_05: 0.009,
        VA_05: 0.43646,
        QC_05: 0.009,
        VC_05: 0.43646,
        QE_05: 0.009,
        VE_05: 0.43646,
        QA_06: 0.006,
        VA_06: 0.41165,
        QC_06: 0.006,
        VC_06: 0.41165,
        QE_06: 0.006,
        VE_06: 0.41165,
        QA_07: 0.005,
        VA_07: 0.40213,
        QC_07: 0.005,
        VC_07: 0.40213,
        QE_07: 0.005,
        VE_07: 0.40213,
        QA_08: 0.004,
        VA_08: 0.39165,
        QC_08: 0.004,
        VC_08: 0.39165,
        QE_08: 0.004,
        VE_08: 0.39165,
        QA_09: 0.003,
        VA_09: 0.37986,
        QC_09: 0.003,
        VC_09: 0.37986,
        QE_09: 0.003,
        VE_09: 0.37986,
        QA_10: 0.002,
        VA_10: 0.36601,
        QC_10: 0.002,
        VC_10: 0.36601,
        QE_10: 0.002,
        VE_10: 0.36601,
        QA_11: 0.002,
        VA_11: 0.36601,
        QC_11: 0.002,
        VC_11: 0.36601,
        QE_11: 0.002,
        VE_11: 0.36601,
        QA_12: 0.009,
        VA_12: 0.43646,
        QC_12: 0.009,
        VC_12: 0.43646,
        QE_12: 0.009,
        VE_12: 0.43646,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88986891821601, 34.596003479633509, 0.0],
          [-80.890644384881469, 34.595362012967769, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285328.0,
        COMID: 9752948,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.186,
        REACHCODE: "03050101001618",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001690912334877,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 0,
        FromNode: 250061176.0,
        ToNode: 250061173.0,
        Hydroseq: 250013483.0,
        LevelPathI: 250013483.0,
        Pathlength: 650.411,
        TerminalPa: 250002604.0,
        ArbolateSu: 1167.86,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013586.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013381.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 1300.3308,
        DivDASqKM: 0.0063,
        Tidal: 0,
        TOTMA: 0.020453851935699999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31652.0,
        MAXELEVSMO: 31652.0,
        MINELEVSMO: 31652.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.035999998450279,
        QA_MA: 0.003,
        VA_MA: 0.34531,
        QC_MA: 0.003,
        VC_MA: 0.34531,
        QE_MA: 0.003,
        VE_MA: 0.34531,
        QA_01: 0.006,
        VA_01: 0.36173,
        QC_01: 0.006,
        VC_01: 0.36173,
        QE_01: 0.006,
        VE_01: 0.36173,
        QA_02: 0.006,
        VA_02: 0.36173,
        QC_02: 0.006,
        VC_02: 0.36173,
        QE_02: 0.006,
        VE_02: 0.36173,
        QA_03: 0.007,
        VA_03: 0.36628,
        QC_03: 0.007,
        VC_03: 0.36628,
        QE_03: 0.007,
        VE_03: 0.36628,
        QA_04: 0.005,
        VA_04: 0.35681,
        QC_04: 0.005,
        VC_04: 0.35681,
        QE_04: 0.005,
        VE_04: 0.35681,
        QA_05: 0.003,
        VA_05: 0.34531,
        QC_05: 0.003,
        VC_05: 0.34531,
        QE_05: 0.003,
        VE_05: 0.34531,
        QA_06: 0.002,
        VA_06: 0.33815,
        QC_06: 0.002,
        VC_06: 0.33815,
        QE_06: 0.002,
        VE_06: 0.33815,
        QA_07: 0.001,
        VA_07: 0.32899,
        QC_07: 0.001,
        VC_07: 0.32899,
        QE_07: 0.001,
        VE_07: 0.32899,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.003,
        VA_11: 0.34531,
        QC_11: 0.003,
        VC_11: 0.34531,
        QE_11: 0.003,
        VE_11: 0.34531,
        QA_12: 0.004,
        VA_12: 0.3514,
        QC_12: 0.004,
        VC_12: 0.3514,
        QE_12: 0.004,
        VE_12: 0.3514,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.814046583448203, 35.739706411191548, 0.0],
          [-81.813792583448617, 35.741309277855812, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288603.0,
        COMID: 9757188,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.038,
        REACHCODE: "03050103017564",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00038097368759099998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062098.0,
        ToNode: 250062101.0,
        Hydroseq: 250004703.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.549,
        TerminalPa: 250002604.0,
        ArbolateSu: 7556.694,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004716.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004686.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9867.7638,
        DivDASqKM: 0.0981,
        Tidal: 0,
        TOTMA: 0.00317462431663,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9815.0,
        MAXELEVSMO: 9864.0,
        MINELEVSMO: 9859.0,
        SLOPE: 0.00131578,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.037999998778104997,
        QA_MA: 0.032,
        VA_MA: 0.45453,
        QC_MA: 0.032,
        VC_MA: 0.45453,
        QE_MA: 0.032,
        VE_MA: 0.45453,
        QA_01: 0.069,
        VA_01: 0.52816,
        QC_01: 0.069,
        VC_01: 0.52816,
        QE_01: 0.069,
        VE_01: 0.52816,
        QA_02: 0.077,
        VA_02: 0.54135,
        QC_02: 0.077,
        VC_02: 0.54135,
        QE_02: 0.077,
        VE_02: 0.54135,
        QA_03: 0.087,
        VA_03: 0.55695,
        QC_03: 0.087,
        VC_03: 0.55695,
        QE_03: 0.087,
        VE_03: 0.55695,
        QA_04: 0.044,
        VA_04: 0.48146,
        QC_04: 0.044,
        VC_04: 0.48146,
        QE_04: 0.044,
        VE_04: 0.48146,
        QA_05: 0.025,
        VA_05: 0.43658,
        QC_05: 0.025,
        VC_05: 0.43658,
        QE_05: 0.025,
        VE_05: 0.43658,
        QA_06: 0.017,
        VA_06: 0.41284,
        QC_06: 0.017,
        VC_06: 0.41284,
        QE_06: 0.017,
        VE_06: 0.41284,
        QA_07: 0.013,
        VA_07: 0.39897,
        QC_07: 0.013,
        VC_07: 0.39897,
        QE_07: 0.013,
        VE_07: 0.39897,
        QA_08: 0.01,
        VA_08: 0.38719,
        QC_08: 0.01,
        VC_08: 0.38719,
        QE_08: 0.01,
        VE_08: 0.38719,
        QA_09: 0.007,
        VA_09: 0.3736,
        QC_09: 0.007,
        VC_09: 0.3736,
        QE_09: 0.007,
        VE_09: 0.3736,
        QA_10: 0.006,
        VA_10: 0.36847,
        QC_10: 0.006,
        VC_10: 0.36847,
        QE_10: 0.006,
        VE_10: 0.36847,
        QA_11: 0.005,
        VA_11: 0.36293,
        QC_11: 0.005,
        VC_11: 0.36293,
        QE_11: 0.005,
        VE_11: 0.36293,
        QA_12: 0.025,
        VA_12: 0.43658,
        QC_12: 0.025,
        VC_12: 0.43658,
        QE_12: 0.025,
        VE_12: 0.43658,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884134384891638, 34.590117279642641, 0.0],
          [-80.883829918225445, 34.590346279642233, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 285329.0,
        COMID: 9752934,
        FDATE: "1999/10/08 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.461,
        REACHCODE: "03050101001619",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0048397361118330002,
        StreamLeve: 3,
        StreamOrde: 5,
        StreamCalc: 0,
        FromNode: 250061170.0,
        ToNode: 250061420.0,
        Hydroseq: 250013279.0,
        LevelPathI: 250013279.0,
        Pathlength: 648.347,
        TerminalPa: 250002604.0,
        ArbolateSu: 1170.172,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250013381.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250013182.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.9072,
        TotDASqKM: 1303.3143,
        DivDASqKM: 0.9072,
        Tidal: 0,
        TOTMA: 0.028533206791,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 31229.0,
        MAXELEVSMO: 31377.0,
        MINELEVSMO: 31248.0,
        SLOPE: 0.0041479,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.31099998950958302,
        QA_MA: 0.5,
        VA_MA: 0.61351,
        QC_MA: 0.5,
        VC_MA: 0.61351,
        QE_MA: 0.5,
        VE_MA: 0.61351,
        QA_01: 0.888,
        VA_01: 0.72231,
        QC_01: 0.888,
        VC_01: 0.72231,
        QE_01: 0.888,
        VE_01: 0.72231,
        QA_02: 0.937,
        VA_02: 0.73429,
        QC_02: 0.937,
        VC_02: 0.73429,
        QE_02: 0.937,
        VE_02: 0.73429,
        QA_03: 1.0,
        VA_03: 0.74926,
        QC_03: 1.0,
        VC_03: 0.74926,
        QE_03: 1.0,
        VE_03: 0.74926,
        QA_04: 0.753,
        VA_04: 0.68761,
        QC_04: 0.753,
        VC_04: 0.68761,
        QE_04: 0.753,
        VE_04: 0.68761,
        QA_05: 0.558,
        VA_05: 0.63181,
        QC_05: 0.558,
        VC_05: 0.63181,
        QE_05: 0.558,
        VE_05: 0.63181,
        QA_06: 0.314,
        VA_06: 0.54672,
        QC_06: 0.314,
        VC_06: 0.54672,
        QE_06: 0.314,
        VE_06: 0.54672,
        QA_07: 0.182,
        VA_07: 0.4868,
        QC_07: 0.182,
        VC_07: 0.4868,
        QE_07: 0.182,
        VE_07: 0.4868,
        QA_08: 0.121,
        VA_08: 0.45203,
        QC_08: 0.121,
        VC_08: 0.45203,
        QE_08: 0.121,
        VE_08: 0.45203,
        QA_09: 0.097,
        VA_09: 0.43612,
        QC_09: 0.097,
        VC_09: 0.43612,
        QE_09: 0.097,
        VE_09: 0.43612,
        QA_10: 0.073,
        VA_10: 0.41822,
        QC_10: 0.073,
        VC_10: 0.41822,
        QE_10: 0.073,
        VE_10: 0.41822,
        QA_11: 0.433,
        VA_11: 0.59106,
        QC_11: 0.433,
        VC_11: 0.59106,
        QE_11: 0.433,
        VE_11: 0.59106,
        QA_12: 0.656,
        VA_12: 0.66083,
        QC_12: 0.656,
        VC_12: 0.66083,
        QE_12: 0.656,
        VE_12: 0.66083,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-81.797779383473426, 35.743911811184951, 0.0],
          [-81.795083516811019, 35.742994077853098, 0.0],
          [-81.793565516813374, 35.744138277851334, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288604.0,
        COMID: 9757190,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.103,
        REACHCODE: "03050103017565",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001051133506911,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062099.0,
        ToNode: 250062098.0,
        Hydroseq: 250004843.0,
        LevelPathI: 250004843.0,
        Pathlength: 374.587,
        TerminalPa: 250002604.0,
        ArbolateSu: 7553.046,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004843.0,
        UpHydroseq: 250004858.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004702.0,
        DnDrainCou: 2,
        DnHydroseq: 250004703.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 9867.4632,
        DivDASqKM: 0.0072,
        Tidal: 0,
        TOTMA: 0.011342361681399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9864.0,
        MAXELEVSMO: 9864.0,
        MINELEVSMO: 9864.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 0.002,
        VA_MA: 0.34483,
        QC_MA: 0.002,
        VC_MA: 0.34483,
        QE_MA: 0.002,
        VE_MA: 0.34483,
        QA_01: 0.005,
        VA_01: 0.36767,
        QC_01: 0.005,
        VC_01: 0.36767,
        QE_01: 0.005,
        VE_01: 0.36767,
        QA_02: 0.005,
        VA_02: 0.36767,
        QC_02: 0.005,
        VC_02: 0.36767,
        QE_02: 0.005,
        VE_02: 0.36767,
        QA_03: 0.006,
        VA_03: 0.37369,
        QC_03: 0.006,
        VC_03: 0.37369,
        QE_03: 0.006,
        VE_03: 0.37369,
        QA_04: 0.003,
        VA_04: 0.35358,
        QC_04: 0.003,
        VC_04: 0.35358,
        QE_04: 0.003,
        VE_04: 0.35358,
        QA_05: 0.001,
        VA_05: 0.33361,
        QC_05: 0.001,
        VC_05: 0.33361,
        QE_05: 0.001,
        VE_05: 0.33361,
        QA_06: 0.001,
        VA_06: 0.33361,
        QC_06: 0.001,
        VC_06: 0.33361,
        QE_06: 0.001,
        VE_06: 0.33361,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.001,
        VA_12: 0.33361,
        QC_12: 0.001,
        VC_12: 0.33361,
        QE_12: 0.001,
        VE_12: 0.33361,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884965518223623, 34.590644079641834, 0.0],
          [-80.884134384891638, 34.590117279642641, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288605.0,
        COMID: 9757192,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.133,
        REACHCODE: "03050103017566",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0014524137195780001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062100.0,
        ToNode: 250062098.0,
        Hydroseq: 250004716.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.587,
        TerminalPa: 250002604.0,
        ArbolateSu: 7555.295,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004729.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004702.0,
        DnDrainCou: 2,
        DnHydroseq: 250004703.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.528,
        DivDASqKM: 0.0891,
        Tidal: 0,
        TOTMA: 0.010462745347899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9816.0,
        MAXELEVSMO: 9925.0,
        MINELEVSMO: 9864.0,
        SLOPE: 0.00458646,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13300000131130199,
        QA_MA: 0.029,
        VA_MA: 0.4827,
        QC_MA: 0.029,
        VC_MA: 0.4827,
        QE_MA: 0.029,
        VE_MA: 0.4827,
        QA_01: 0.063,
        VA_01: 0.57156,
        QC_01: 0.063,
        VC_01: 0.57156,
        QE_01: 0.063,
        VE_01: 0.57156,
        QA_02: 0.07,
        VA_02: 0.58671,
        QC_02: 0.07,
        VC_02: 0.58671,
        QE_02: 0.07,
        VE_02: 0.58671,
        QA_03: 0.079,
        VA_03: 0.60517,
        QC_03: 0.079,
        VC_03: 0.60517,
        QE_03: 0.079,
        VE_03: 0.60517,
        QA_04: 0.04,
        VA_04: 0.51516,
        QC_04: 0.04,
        VC_04: 0.51516,
        QE_04: 0.04,
        VE_04: 0.51516,
        QA_05: 0.022,
        VA_05: 0.45892,
        QC_05: 0.022,
        VC_05: 0.45892,
        QE_05: 0.022,
        VE_05: 0.45892,
        QA_06: 0.015,
        VA_06: 0.43122,
        QC_06: 0.015,
        VC_06: 0.43122,
        QE_06: 0.015,
        VE_06: 0.43122,
        QA_07: 0.011,
        VA_07: 0.41257,
        QC_07: 0.011,
        VC_07: 0.41257,
        QE_07: 0.011,
        VE_07: 0.41257,
        QA_08: 0.009,
        VA_08: 0.40204,
        QC_08: 0.009,
        VC_08: 0.40204,
        QE_08: 0.009,
        VE_08: 0.40204,
        QA_09: 0.007,
        VA_09: 0.39034,
        QC_09: 0.007,
        VC_09: 0.39034,
        QE_09: 0.007,
        VE_09: 0.39034,
        QA_10: 0.005,
        VA_10: 0.37693,
        QC_10: 0.005,
        VC_10: 0.37693,
        QE_10: 0.005,
        VE_10: 0.37693,
        QA_11: 0.004,
        VA_11: 0.36927,
        QC_11: 0.004,
        VC_11: 0.36927,
        QE_11: 0.004,
        VE_11: 0.36927,
        QA_12: 0.022,
        VA_12: 0.45892,
        QC_12: 0.022,
        VC_12: 0.45892,
        QE_12: 0.022,
        VE_12: 0.45892,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8855747848894, 34.590208812975732, 0.0],
          [-80.884134384891638, 34.590117279642641, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288606.0,
        COMID: 9757194,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.103,
        REACHCODE: "03050103017567",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001046453087048,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062101.0,
        ToNode: 250062102.0,
        Hydroseq: 250004686.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.446,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.124,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004703.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004674.0,
        DnDrainCou: 2,
        DnHydroseq: 250004675.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0567,
        TotDASqKM: 9867.9204,
        DivDASqKM: 0.6093,
        Tidal: 0,
        TOTMA: 0.0063327772843700001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9752.0,
        MAXELEVSMO: 9859.0,
        MINELEVSMO: 9752.0,
        SLOPE: 0.01038834,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 0.202,
        VA_MA: 0.61761,
        QC_MA: 0.202,
        VC_MA: 0.61761,
        QE_MA: 0.202,
        VE_MA: 0.61761,
        QA_01: 0.434,
        VA_01: 0.77251,
        QC_01: 0.434,
        VC_01: 0.77251,
        QE_01: 0.434,
        VE_01: 0.77251,
        QA_02: 0.483,
        VA_02: 0.79963,
        QC_02: 0.483,
        VC_02: 0.79963,
        QE_02: 0.483,
        VE_02: 0.79963,
        QA_03: 0.54,
        VA_03: 0.82961,
        QC_03: 0.54,
        VC_03: 0.82961,
        QE_03: 0.54,
        VE_03: 0.82961,
        QA_04: 0.278,
        VA_04: 0.67475,
        QC_04: 0.278,
        VC_04: 0.67475,
        QE_04: 0.278,
        VE_04: 0.67475,
        QA_05: 0.155,
        VA_05: 0.57704,
        QC_05: 0.155,
        VC_05: 0.57704,
        QE_05: 0.155,
        VE_05: 0.57704,
        QA_06: 0.106,
        VA_06: 0.52795,
        QC_06: 0.106,
        VC_06: 0.52795,
        QE_06: 0.106,
        VE_06: 0.52795,
        QA_07: 0.081,
        VA_07: 0.49873,
        QC_07: 0.081,
        VC_07: 0.49873,
        QE_07: 0.081,
        VE_07: 0.49873,
        QA_08: 0.065,
        VA_08: 0.47774,
        QC_08: 0.065,
        VC_08: 0.47774,
        QE_08: 0.065,
        VE_08: 0.47774,
        QA_09: 0.049,
        VA_09: 0.45414,
        QC_09: 0.049,
        VC_09: 0.45414,
        QE_09: 0.049,
        VE_09: 0.45414,
        QA_10: 0.04,
        VA_10: 0.43925,
        QC_10: 0.04,
        VC_10: 0.43925,
        QE_10: 0.04,
        VE_10: 0.43925,
        QA_11: 0.032,
        VA_11: 0.42463,
        QC_11: 0.032,
        VC_11: 0.42463,
        QE_11: 0.032,
        VE_11: 0.42463,
        QA_12: 0.155,
        VA_12: 0.57704,
        QC_12: 0.155,
        VC_12: 0.57704,
        QE_12: 0.155,
        VE_12: 0.57704,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883829918225445, 34.590346279642233, 0.0],
          [-80.882971184893449, 34.589773679643145, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288599.0,
        COMID: 9757180,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.092,
        REACHCODE: "03050103017560",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00095704607125499997,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062091.0,
        ToNode: 250062099.0,
        Hydroseq: 250004858.0,
        LevelPathI: 250004843.0,
        Pathlength: 374.69,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.777,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004860.0,
        UpHydroseq: 250004881.0,
        DnLevelPat: 250004843.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004843.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2292,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0113281463207,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9982.0,
        MINELEVSMO: 9864.0,
        SLOPE: 0.01282608,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.092000000178814004,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885796518222321, 34.591102079640962, 0.0],
          [-80.884965518223623, 34.590644079641834, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288600.0,
        COMID: 9757182,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.103,
        REACHCODE: "03050103017561",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010016068395709999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062095.0,
        ToNode: 250062101.0,
        Hydroseq: 250004844.0,
        LevelPathI: 250004844.0,
        Pathlength: 374.549,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.847,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004861.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004686.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0954,
        TotDASqKM: 9867.546,
        DivDASqKM: 0.4545,
        Tidal: 0,
        TOTMA: 0.0071515571011199998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9859.0,
        MAXELEVSMO: 9891.0,
        MINELEVSMO: 9859.0,
        SLOPE: 0.00310679,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.103000000119209,
        QA_MA: 0.15,
        VA_MA: 0.5469,
        QC_MA: 0.15,
        VC_MA: 0.5469,
        QE_MA: 0.15,
        VE_MA: 0.5469,
        QA_01: 0.323,
        VA_01: 0.6668,
        QC_01: 0.323,
        VC_01: 0.6668,
        QE_01: 0.323,
        VE_01: 0.6668,
        QA_02: 0.36,
        VA_02: 0.68805,
        QC_02: 0.36,
        VC_02: 0.68805,
        QE_02: 0.36,
        VE_02: 0.68805,
        QA_03: 0.403,
        VA_03: 0.71149,
        QC_03: 0.403,
        VC_03: 0.71149,
        QE_03: 0.403,
        VE_03: 0.71149,
        QA_04: 0.207,
        VA_04: 0.59139,
        QC_04: 0.207,
        VC_04: 0.59139,
        QE_04: 0.207,
        VE_04: 0.59139,
        QA_05: 0.116,
        VA_05: 0.51647,
        QC_05: 0.116,
        VC_05: 0.51647,
        QE_05: 0.116,
        VE_05: 0.51647,
        QA_06: 0.079,
        VA_06: 0.47807,
        QC_06: 0.079,
        VC_06: 0.47807,
        QE_06: 0.079,
        VE_06: 0.47807,
        QA_07: 0.061,
        VA_07: 0.4563,
        QC_07: 0.061,
        VC_07: 0.4563,
        QE_07: 0.061,
        VE_07: 0.4563,
        QA_08: 0.048,
        VA_08: 0.43863,
        QC_08: 0.048,
        VC_08: 0.43863,
        QE_08: 0.048,
        VE_08: 0.43863,
        QA_09: 0.036,
        VA_09: 0.42018,
        QC_09: 0.036,
        VC_09: 0.42018,
        QE_09: 0.036,
        VE_09: 0.42018,
        QA_10: 0.03,
        VA_10: 0.40986,
        QC_10: 0.03,
        VC_10: 0.40986,
        QE_10: 0.03,
        VE_10: 0.40986,
        QA_11: 0.024,
        VA_11: 0.39852,
        QC_11: 0.024,
        VC_11: 0.39852,
        QE_11: 0.024,
        VE_11: 0.39852,
        QA_12: 0.116,
        VA_12: 0.51647,
        QC_12: 0.116,
        VC_12: 0.51647,
        QE_12: 0.116,
        VE_12: 0.51647,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884494718224403, 34.591079279641065, 0.0],
          [-80.883829918225445, 34.590346279642233, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288601.0,
        COMID: 9757184,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.08,
        REACHCODE: "03050103017562",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00077775159012599996,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062096.0,
        ToNode: 250062100.0,
        Hydroseq: 250004729.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.72,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.842,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004745.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004716.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0054,
        TotDASqKM: 9867.4587,
        DivDASqKM: 0.0792,
        Tidal: 0,
        TOTMA: 0.0062794607911100002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9925.0,
        MAXELEVSMO: 9970.0,
        MINELEVSMO: 9925.0,
        SLOPE: 0.005625,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.079999998211861004,
        QA_MA: 0.026,
        VA_MA: 0.48377,
        QC_MA: 0.026,
        VC_MA: 0.48377,
        QE_MA: 0.026,
        VE_MA: 0.48377,
        QA_01: 0.056,
        VA_01: 0.57197,
        QC_01: 0.056,
        VC_01: 0.57197,
        QE_01: 0.056,
        VE_01: 0.57197,
        QA_02: 0.062,
        VA_02: 0.58661,
        QC_02: 0.062,
        VC_02: 0.58661,
        QE_02: 0.062,
        VE_02: 0.58661,
        QA_03: 0.07,
        VA_03: 0.60512,
        QC_03: 0.07,
        VC_03: 0.60512,
        QE_03: 0.07,
        VE_03: 0.60512,
        QA_04: 0.036,
        VA_04: 0.51685,
        QC_04: 0.036,
        VC_04: 0.51685,
        QE_04: 0.036,
        VE_04: 0.51685,
        QA_05: 0.02,
        VA_05: 0.46096,
        QC_05: 0.02,
        VC_05: 0.46096,
        QE_05: 0.02,
        VE_05: 0.46096,
        QA_06: 0.013,
        VA_06: 0.42976,
        QC_06: 0.013,
        VC_06: 0.42976,
        QE_06: 0.013,
        VE_06: 0.42976,
        QA_07: 0.01,
        VA_07: 0.41398,
        QC_07: 0.01,
        VC_07: 0.41398,
        QE_07: 0.01,
        VE_07: 0.41398,
        QA_08: 0.008,
        VA_08: 0.40218,
        QC_08: 0.008,
        VC_08: 0.40218,
        QE_08: 0.008,
        VE_08: 0.40218,
        QA_09: 0.006,
        VA_09: 0.38889,
        QC_09: 0.006,
        VC_09: 0.38889,
        QE_09: 0.006,
        VE_09: 0.38889,
        QA_10: 0.005,
        VA_10: 0.38147,
        QC_10: 0.005,
        VC_10: 0.38147,
        QE_10: 0.005,
        VE_10: 0.38147,
        QA_11: 0.004,
        VA_11: 0.3733,
        QC_11: 0.004,
        VC_11: 0.3733,
        QE_11: 0.004,
        VE_11: 0.3733,
        QA_12: 0.02,
        VA_12: 0.46096,
        QC_12: 0.02,
        VC_12: 0.46096,
        QE_12: 0.02,
        VE_12: 0.46096,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886101118221859, 34.590781412974934, 0.0],
          [-80.8855747848894, 34.590208812975732, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288602.0,
        COMID: 9757186,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.053,
        REACHCODE: "03050103017563",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00058341628268599995,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059715.0,
        ToNode: 250062100.0,
        Hydroseq: 250004762.0,
        LevelPathI: 250004762.0,
        Pathlength: 374.72,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.061,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004787.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004716.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.4776,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0065259972471499996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10055.0,
        MINELEVSMO: 9925.0,
        SLOPE: 0.0245283,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.052999999374151001,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886156384888466, 34.590162812975848, 0.0],
          [-80.8855747848894, 34.590208812975732, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288611.0,
        COMID: 9757204,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.148,
        REACHCODE: "03050103017572",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0014518608191940001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062106.0,
        ToNode: 250062107.0,
        Hydroseq: 250004661.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.223,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.507,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004675.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004646.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.9384,
        DivDASqKM: 0.6273,
        Tidal: 0,
        TOTMA: 0.0136552567787,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9738.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9748.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 0.208,
        VA_MA: 0.41156,
        QC_MA: 0.208,
        VC_MA: 0.41156,
        QE_MA: 0.208,
        VE_MA: 0.41156,
        QA_01: 0.447,
        VA_01: 0.46326,
        QC_01: 0.447,
        VC_01: 0.46326,
        QE_01: 0.447,
        VE_01: 0.46326,
        QA_02: 0.497,
        VA_02: 0.47223,
        QC_02: 0.497,
        VC_02: 0.47223,
        QE_02: 0.497,
        VE_02: 0.47223,
        QA_03: 0.556,
        VA_03: 0.48228,
        QC_03: 0.556,
        VC_03: 0.48228,
        QE_03: 0.556,
        VE_03: 0.48228,
        QA_04: 0.286,
        VA_04: 0.43056,
        QC_04: 0.286,
        VC_04: 0.43056,
        QE_04: 0.286,
        VE_04: 0.43056,
        QA_05: 0.16,
        VA_05: 0.39814,
        QC_05: 0.16,
        VC_05: 0.39814,
        QE_05: 0.16,
        VE_05: 0.39814,
        QA_06: 0.109,
        VA_06: 0.38159,
        QC_06: 0.109,
        VC_06: 0.38159,
        QE_06: 0.109,
        VE_06: 0.38159,
        QA_07: 0.084,
        VA_07: 0.37213,
        QC_07: 0.084,
        VC_07: 0.37213,
        QE_07: 0.084,
        VE_07: 0.37213,
        QA_08: 0.067,
        VA_08: 0.36492,
        QC_08: 0.067,
        VC_08: 0.36492,
        QE_08: 0.067,
        VE_08: 0.36492,
        QA_09: 0.05,
        VA_09: 0.35679,
        QC_09: 0.05,
        VC_09: 0.35679,
        QE_09: 0.05,
        VE_09: 0.35679,
        QA_10: 0.042,
        VA_10: 0.35251,
        QC_10: 0.042,
        VC_10: 0.35251,
        QE_10: 0.042,
        VE_10: 0.35251,
        QA_11: 0.033,
        VA_11: 0.3472,
        QC_11: 0.033,
        VC_11: 0.3472,
        QE_11: 0.033,
        VE_11: 0.3472,
        QA_12: 0.16,
        VA_12: 0.39814,
        QC_12: 0.16,
        VC_12: 0.39814,
        QE_12: 0.16,
        VE_12: 0.39814,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883054184893297, 34.589109412977507, 0.0],
          [-80.883635784892363, 34.58867427964492, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288612.0,
        COMID: 9757206,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.242,
        REACHCODE: "03050103017573",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002292022911068,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062107.0,
        ToNode: 250062109.0,
        Hydroseq: 250004646.0,
        LevelPathI: 250004607.0,
        Pathlength: 373.981,
        TerminalPa: 250002604.0,
        ArbolateSu: 7558.055,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004661.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004635.0,
        DnDrainCou: 2,
        DnHydroseq: 250004636.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0261,
        TotDASqKM: 9867.9951,
        DivDASqKM: 0.6534,
        Tidal: 0,
        TOTMA: 0.022277308521299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9678.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9748.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.24199999868869801,
        QA_MA: 0.216,
        VA_MA: 0.4125,
        QC_MA: 0.216,
        VC_MA: 0.4125,
        QE_MA: 0.216,
        VE_MA: 0.4125,
        QA_01: 0.465,
        VA_01: 0.46482,
        QC_01: 0.465,
        VC_01: 0.46482,
        QE_01: 0.465,
        VE_01: 0.46482,
        QA_02: 0.518,
        VA_02: 0.47405,
        QC_02: 0.518,
        VC_02: 0.47405,
        QE_02: 0.518,
        VE_02: 0.47405,
        QA_03: 0.579,
        VA_03: 0.48414,
        QC_03: 0.579,
        VC_03: 0.48414,
        QE_03: 0.579,
        VE_03: 0.48414,
        QA_04: 0.298,
        VA_04: 0.4319,
        QC_04: 0.298,
        VC_04: 0.4319,
        QE_04: 0.298,
        VE_04: 0.4319,
        QA_05: 0.166,
        VA_05: 0.39892,
        QC_05: 0.166,
        VC_05: 0.39892,
        QE_05: 0.166,
        VE_05: 0.39892,
        QA_06: 0.114,
        VA_06: 0.38254,
        QC_06: 0.114,
        VC_06: 0.38254,
        QE_06: 0.114,
        VE_06: 0.38254,
        QA_07: 0.087,
        VA_07: 0.37263,
        QC_07: 0.087,
        VC_07: 0.37263,
        QE_07: 0.087,
        VE_07: 0.37263,
        QA_08: 0.07,
        VA_08: 0.36562,
        QC_08: 0.07,
        VC_08: 0.36562,
        QE_08: 0.07,
        VE_08: 0.36562,
        QA_09: 0.052,
        VA_09: 0.35727,
        QC_09: 0.052,
        VC_09: 0.35727,
        QE_09: 0.052,
        VE_09: 0.35727,
        QA_10: 0.043,
        VA_10: 0.35258,
        QC_10: 0.043,
        VC_10: 0.35258,
        QE_10: 0.043,
        VE_10: 0.35258,
        QA_11: 0.035,
        VA_11: 0.348,
        QC_11: 0.035,
        VC_11: 0.348,
        QE_11: 0.035,
        VE_11: 0.348,
        QA_12: 0.166,
        VA_12: 0.39892,
        QC_12: 0.166,
        VC_12: 0.39892,
        QE_12: 0.166,
        VE_12: 0.39892,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883635784892363, 34.58867427964492, 0.0],
          [-80.882970784893473, 34.586818812981051, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288613.0,
        COMID: 9757208,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.303,
        REACHCODE: "03050103017574",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0028480268577140001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062106.0,
        ToNode: 250062114.0,
        Hydroseq: 250004660.0,
        LevelPathI: 250004660.0,
        Pathlength: 373.953,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.662,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004675.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004625.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.9384,
        DivDASqKM: 0.0099,
        Tidal: 0,
        TOTMA: 0.033074780029299998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9748.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9748.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.152999997138977,
        QA_MA: 0.003,
        VA_MA: 0.34787,
        QC_MA: 0.003,
        VC_MA: 0.34787,
        QE_MA: 0.003,
        VE_MA: 0.34787,
        QA_01: 0.007,
        VA_01: 0.37031,
        QC_01: 0.007,
        VC_01: 0.37031,
        QE_01: 0.007,
        VE_01: 0.37031,
        QA_02: 0.007,
        VA_02: 0.37031,
        QC_02: 0.007,
        VC_02: 0.37031,
        QE_02: 0.007,
        VE_02: 0.37031,
        QA_03: 0.008,
        VA_03: 0.37485,
        QC_03: 0.008,
        VC_03: 0.37485,
        QE_03: 0.008,
        VE_03: 0.37485,
        QA_04: 0.004,
        VA_04: 0.35439,
        QC_04: 0.004,
        VC_04: 0.35439,
        QE_04: 0.004,
        VE_04: 0.35439,
        QA_05: 0.002,
        VA_05: 0.34022,
        QC_05: 0.002,
        VC_05: 0.34022,
        QE_05: 0.002,
        VE_05: 0.34022,
        QA_06: 0.001,
        VA_06: 0.33042,
        QC_06: 0.001,
        VC_06: 0.33042,
        QE_06: 0.001,
        VE_06: 0.33042,
        QA_07: 0.001,
        VA_07: 0.33042,
        QC_07: 0.001,
        VC_07: 0.33042,
        QE_07: 0.001,
        VE_07: 0.33042,
        QA_08: 0.001,
        VA_08: 0.33042,
        QC_08: 0.001,
        VC_08: 0.33042,
        QE_08: 0.001,
        VE_08: 0.33042,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.34022,
        QC_12: 0.002,
        VC_12: 0.34022,
        QE_12: 0.002,
        VE_12: 0.34022,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883054184893297, 34.589109412977507, 0.0],
          [-80.882666184893935, 34.586796012981097, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288614.0,
        COMID: 9757210,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.028,
        REACHCODE: "03050103017575",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00030545212344399998,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062109.0,
        ToNode: 250062114.0,
        Hydroseq: 250004636.0,
        LevelPathI: 250004607.0,
        Pathlength: 373.953,
        TerminalPa: 250002604.0,
        ArbolateSu: 7558.083,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004646.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004625.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9867.9969,
        DivDASqKM: 0.6552,
        Tidal: 0,
        TOTMA: 0.0025770401328300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9720.0,
        MAXELEVSMO: 9748.0,
        MINELEVSMO: 9748.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.028000000864266999,
        QA_MA: 0.217,
        VA_MA: 0.41258,
        QC_MA: 0.217,
        VC_MA: 0.41258,
        QE_MA: 0.217,
        VE_MA: 0.41258,
        QA_01: 0.467,
        VA_01: 0.46492,
        QC_01: 0.467,
        VC_01: 0.46492,
        QE_01: 0.467,
        VE_01: 0.46492,
        QA_02: 0.519,
        VA_02: 0.47394,
        QC_02: 0.519,
        VC_02: 0.47394,
        QE_02: 0.519,
        VE_02: 0.47394,
        QA_03: 0.581,
        VA_03: 0.48417,
        QC_03: 0.581,
        VC_03: 0.48417,
        QE_03: 0.581,
        VE_03: 0.48417,
        QA_04: 0.299,
        VA_04: 0.43192,
        QC_04: 0.299,
        VC_04: 0.43192,
        QE_04: 0.299,
        VE_04: 0.43192,
        QA_05: 0.167,
        VA_05: 0.39906,
        QC_05: 0.167,
        VC_05: 0.39906,
        QE_05: 0.167,
        VE_05: 0.39906,
        QA_06: 0.114,
        VA_06: 0.38242,
        QC_06: 0.114,
        VC_06: 0.38242,
        QE_06: 0.114,
        VE_06: 0.38242,
        QA_07: 0.088,
        VA_07: 0.37291,
        QC_07: 0.088,
        VC_07: 0.37291,
        QE_07: 0.088,
        VE_07: 0.37291,
        QA_08: 0.07,
        VA_08: 0.36553,
        QC_08: 0.07,
        VC_08: 0.36553,
        QE_08: 0.07,
        VE_08: 0.36553,
        QA_09: 0.052,
        VA_09: 0.35719,
        QC_09: 0.052,
        VC_09: 0.35719,
        QE_09: 0.052,
        VE_09: 0.35719,
        QA_10: 0.044,
        VA_10: 0.35304,
        QC_10: 0.044,
        VC_10: 0.35304,
        QE_10: 0.044,
        VE_10: 0.35304,
        QA_11: 0.035,
        VA_11: 0.34793,
        QC_11: 0.035,
        VC_11: 0.34793,
        QE_11: 0.035,
        VE_11: 0.34793,
        QA_12: 0.167,
        VA_12: 0.39906,
        QC_12: 0.167,
        VC_12: 0.39906,
        QE_12: 0.167,
        VE_12: 0.39906,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882970784893473, 34.586818812981051, 0.0],
          [-80.882666184893935, 34.586796012981097, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288607.0,
        COMID: 9757196,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.075,
        REACHCODE: "03050103017568",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00066943200031999996,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062102.0,
        ToNode: 250062106.0,
        Hydroseq: 250004675.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.371,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.199,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004686.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004660.0,
        DnDrainCou: 2,
        DnHydroseq: 250004661.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.924,
        DivDASqKM: 0.6129,
        Tidal: 0,
        TOTMA: 0.0056789796447700002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9741.0,
        MAXELEVSMO: 9752.0,
        MINELEVSMO: 9748.0,
        SLOPE: 0.00053333,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.075000002980232003,
        QA_MA: 0.203,
        VA_MA: 0.50149,
        QC_MA: 0.203,
        VC_MA: 0.50149,
        QE_MA: 0.203,
        VE_MA: 0.50149,
        QA_01: 0.436,
        VA_01: 0.59817,
        QC_01: 0.436,
        VC_01: 0.59817,
        QE_01: 0.436,
        VE_01: 0.59817,
        QA_02: 0.486,
        VA_02: 0.61536,
        QC_02: 0.486,
        VC_02: 0.61536,
        QE_02: 0.486,
        VE_02: 0.61536,
        QA_03: 0.543,
        VA_03: 0.63398,
        QC_03: 0.543,
        VC_03: 0.63398,
        QE_03: 0.543,
        VE_03: 0.63398,
        QA_04: 0.28,
        VA_04: 0.53744,
        QC_04: 0.28,
        VC_04: 0.53744,
        QE_04: 0.28,
        VE_04: 0.53744,
        QA_05: 0.156,
        VA_05: 0.47629,
        QC_05: 0.156,
        VC_05: 0.47629,
        QE_05: 0.156,
        VE_05: 0.47629,
        QA_06: 0.107,
        VA_06: 0.44583,
        QC_06: 0.107,
        VC_06: 0.44583,
        QE_06: 0.107,
        VE_06: 0.44583,
        QA_07: 0.082,
        VA_07: 0.42772,
        QC_07: 0.082,
        VC_07: 0.42772,
        QE_07: 0.082,
        VE_07: 0.42772,
        QA_08: 0.065,
        VA_08: 0.41387,
        QC_08: 0.065,
        VC_08: 0.41387,
        QE_08: 0.065,
        VE_08: 0.41387,
        QA_09: 0.049,
        VA_09: 0.39917,
        QC_09: 0.049,
        VC_09: 0.39917,
        QE_09: 0.049,
        VE_09: 0.39917,
        QA_10: 0.041,
        VA_10: 0.39097,
        QC_10: 0.041,
        VC_10: 0.39097,
        QE_10: 0.041,
        VE_10: 0.39097,
        QA_11: 0.032,
        VA_11: 0.38079,
        QC_11: 0.032,
        VC_11: 0.38079,
        QE_11: 0.032,
        VE_11: 0.38079,
        QA_12: 0.156,
        VA_12: 0.47629,
        QC_12: 0.156,
        VC_12: 0.47629,
        QE_12: 0.156,
        VE_12: 0.47629,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882971184893449, 34.589773679643145, 0.0],
          [-80.883054184893297, 34.589109412977507, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288608.0,
        COMID: 9757198,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.16,
        REACHCODE: "03050103017569",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015815786500439999,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062098.0,
        ToNode: 250062106.0,
        Hydroseq: 250004702.0,
        LevelPathI: 250004702.0,
        Pathlength: 374.371,
        TerminalPa: 250002604.0,
        ArbolateSu: 7556.816,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004716.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 250004660.0,
        DnDrainCou: 2,
        DnHydroseq: 250004661.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9867.7665,
        DivDASqKM: 0.0045,
        Tidal: 0,
        TOTMA: 0.013941325135,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9745.0,
        MAXELEVSMO: 9864.0,
        MINELEVSMO: 9748.0,
        SLOPE: 0.058,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.019999999552965001,
        QA_MA: 0.001,
        VA_MA: 0.4358,
        QC_MA: 0.001,
        VC_MA: 0.4358,
        QE_MA: 0.001,
        VE_MA: 0.4358,
        QA_01: 0.003,
        VA_01: 0.53672,
        QC_01: 0.003,
        VC_01: 0.53672,
        QE_01: 0.003,
        VE_01: 0.53672,
        QA_02: 0.003,
        VA_02: 0.53672,
        QC_02: 0.003,
        VC_02: 0.53672,
        QE_02: 0.003,
        VE_02: 0.53672,
        QA_03: 0.003,
        VA_03: 0.53672,
        QC_03: 0.003,
        VC_03: 0.53672,
        QE_03: 0.003,
        VE_03: 0.53672,
        QA_04: 0.002,
        VA_04: 0.49249,
        QC_04: 0.002,
        VC_04: 0.49249,
        QE_04: 0.002,
        VE_04: 0.49249,
        QA_05: 0.001,
        VA_05: 0.4358,
        QC_05: 0.001,
        VC_05: 0.4358,
        QE_05: 0.001,
        VE_05: 0.4358,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.001,
        VA_12: 0.4358,
        QC_12: 0.001,
        VC_12: 0.4358,
        QE_12: 0.001,
        VE_12: 0.4358,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884134384891638, 34.590117279642641, 0.0],
          [-80.883054184893297, 34.589109412977507, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288609.0,
        COMID: 9757200,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.144,
        REACHCODE: "03050103017570",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001466880782226,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059717.0,
        ToNode: 250062107.0,
        Hydroseq: 250004744.0,
        LevelPathI: 250004744.0,
        Pathlength: 374.223,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.314,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004766.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004646.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.5082,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.017731010834599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9951.0,
        MINELEVSMO: 9748.0,
        SLOPE: 0.01409722,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.143999993801117,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.884826784890549, 34.589201012977412, 0.0],
          [-80.883635784892363, 34.58867427964492, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288610.0,
        COMID: 9757202,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.241,
        REACHCODE: "03050103017571",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0024483496182219999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062102.0,
        ToNode: 250062112.0,
        Hydroseq: 250004674.0,
        LevelPathI: 250004659.0,
        Pathlength: 374.016,
        TerminalPa: 250002604.0,
        ArbolateSu: 7557.365,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004686.0,
        DnLevelPat: 250004659.0,
        DnMinorHyd: 250004658.0,
        DnDrainCou: 2,
        DnHydroseq: 250004659.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0576,
        TotDASqKM: 9867.978,
        DivDASqKM: 0.0576,
        Tidal: 0,
        TOTMA: 0.0209851567403,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9741.0,
        MAXELEVSMO: 9752.0,
        MINELEVSMO: 9741.0,
        SLOPE: 0.00120879,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.090999998152255998,
        QA_MA: 0.019,
        VA_MA: 0.43609,
        QC_MA: 0.019,
        VC_MA: 0.43609,
        QE_MA: 0.019,
        VE_MA: 0.43609,
        QA_01: 0.041,
        VA_01: 0.5005,
        QC_01: 0.041,
        VC_01: 0.5005,
        QE_01: 0.041,
        VE_01: 0.5005,
        QA_02: 0.045,
        VA_02: 0.51024,
        QC_02: 0.045,
        VC_02: 0.51024,
        QE_02: 0.045,
        VE_02: 0.51024,
        QA_03: 0.051,
        VA_03: 0.52411,
        QC_03: 0.051,
        VC_03: 0.52411,
        QE_03: 0.051,
        VE_03: 0.52411,
        QA_04: 0.026,
        VA_04: 0.45923,
        QC_04: 0.026,
        VC_04: 0.45923,
        QE_04: 0.026,
        VE_04: 0.45923,
        QA_05: 0.014,
        VA_05: 0.41697,
        QC_05: 0.014,
        VC_05: 0.41697,
        QE_05: 0.014,
        VE_05: 0.41697,
        QA_06: 0.01,
        VA_06: 0.39921,
        QC_06: 0.01,
        VC_06: 0.39921,
        QE_06: 0.01,
        VE_06: 0.39921,
        QA_07: 0.007,
        VA_07: 0.38354,
        QC_07: 0.007,
        VC_07: 0.38354,
        QE_07: 0.007,
        VE_07: 0.38354,
        QA_08: 0.006,
        VA_08: 0.37763,
        QC_08: 0.006,
        VC_08: 0.37763,
        QE_08: 0.006,
        VE_08: 0.37763,
        QA_09: 0.004,
        VA_09: 0.36422,
        QC_09: 0.004,
        VC_09: 0.36422,
        QE_09: 0.004,
        VE_09: 0.36422,
        QA_10: 0.003,
        VA_10: 0.35631,
        QC_10: 0.003,
        VC_10: 0.35631,
        QE_10: 0.003,
        VE_10: 0.35631,
        QA_11: 0.003,
        VA_11: 0.35631,
        QC_11: 0.003,
        VC_11: 0.35631,
        QE_11: 0.003,
        VE_11: 0.35631,
        QA_12: 0.014,
        VA_12: 0.41697,
        QC_12: 0.014,
        VC_12: 0.41697,
        QE_12: 0.014,
        VE_12: 0.41697,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882971184893449, 34.589773679643145, 0.0],
          [-80.880976784896518, 34.588422412978559, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288587.0,
        COMID: 9757156,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.102,
        REACHCODE: "03050103017548",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00093219070947499997,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062081.0,
        ToNode: 250062086.0,
        Hydroseq: 250004898.0,
        LevelPathI: 250004860.0,
        Pathlength: 375.017,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.513,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004899.0,
        UpHydroseq: 250004918.0,
        DnLevelPat: 250004860.0,
        DnMinorHyd: 250004880.0,
        DnDrainCou: 2,
        DnHydroseq: 250004881.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0125594663136,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9982.0,
        MINELEVSMO: 9982.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.10199999809265101,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887347784886515, 34.592842812971639, 0.0],
          [-80.887126118220237, 34.591972412973121, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288588.0,
        COMID: 9757158,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.189,
        REACHCODE: "03050103017549",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001783265965295,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062083.0,
        ToNode: 250062088.0,
        Hydroseq: 250004805.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.969,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.998,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004821.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004763.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0126,
        TotDASqKM: 9867.2994,
        DivDASqKM: 0.0513,
        Tidal: 0,
        TOTMA: 0.019585834490900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10047.0,
        MAXELEVSMO: 10047.0,
        MINELEVSMO: 10047.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18899999558925601,
        QA_MA: 0.017,
        VA_MA: 0.36643,
        QC_MA: 0.017,
        VC_MA: 0.36643,
        QE_MA: 0.017,
        VE_MA: 0.36643,
        QA_01: 0.036,
        VA_01: 0.39483,
        QC_01: 0.036,
        VC_01: 0.39483,
        QE_01: 0.036,
        VE_01: 0.39483,
        QA_02: 0.04,
        VA_02: 0.39981,
        QC_02: 0.04,
        VC_02: 0.39981,
        QE_02: 0.04,
        VE_02: 0.39981,
        QA_03: 0.045,
        VA_03: 0.40571,
        QC_03: 0.045,
        VC_03: 0.40571,
        QE_03: 0.045,
        VE_03: 0.40571,
        QA_04: 0.023,
        VA_04: 0.37653,
        QC_04: 0.023,
        VC_04: 0.37653,
        QE_04: 0.023,
        VE_04: 0.37653,
        QA_05: 0.013,
        VA_05: 0.35872,
        QC_05: 0.013,
        VC_05: 0.35872,
        QE_05: 0.013,
        VE_05: 0.35872,
        QA_06: 0.008,
        VA_06: 0.34728,
        QC_06: 0.008,
        VC_06: 0.34728,
        QE_06: 0.008,
        VE_06: 0.34728,
        QA_07: 0.006,
        VA_07: 0.34177,
        QC_07: 0.006,
        VC_07: 0.34177,
        QE_07: 0.006,
        VE_07: 0.34177,
        QA_08: 0.005,
        VA_08: 0.33869,
        QC_08: 0.005,
        VC_08: 0.33869,
        QE_08: 0.005,
        VE_08: 0.33869,
        QA_09: 0.004,
        VA_09: 0.33531,
        QC_09: 0.004,
        VC_09: 0.33531,
        QE_09: 0.004,
        VE_09: 0.33531,
        QA_10: 0.003,
        VA_10: 0.33149,
        QC_10: 0.003,
        VC_10: 0.33149,
        QE_10: 0.003,
        VE_10: 0.33149,
        QA_11: 0.002,
        VA_11: 0.32702,
        QC_11: 0.002,
        VC_11: 0.32702,
        QE_11: 0.002,
        VE_11: 0.32702,
        QA_12: 0.013,
        VA_12: 0.35872,
        QC_12: 0.013,
        VC_12: 0.35872,
        QE_12: 0.013,
        VE_12: 0.35872,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888538918218117, 34.593003012971394, 0.0],
          [-80.88767998488612, 34.591537012973731, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288589.0,
        COMID: 9757160,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.067,
        REACHCODE: "03050103017550",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00072207707623400002,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059713.0,
        ToNode: 250062088.0,
        Hydroseq: 250004785.0,
        LevelPathI: 250004785.0,
        Pathlength: 374.969,
        TerminalPa: 250002604.0,
        ArbolateSu: 7553.808,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004808.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004763.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.4182,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.00824984587951,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10123.0,
        MINELEVSMO: 10047.0,
        SLOPE: 0.01134328,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.067000001668929998,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888372518218375, 34.591674412973532, 0.0],
          [-80.88767998488612, 34.591537012973731, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288590.0,
        COMID: 9757162,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.132,
        REACHCODE: "03050103017551",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0012795101049790001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062085.0,
        ToNode: 250062090.0,
        Hydroseq: 250004882.0,
        LevelPathI: 250004844.0,
        Pathlength: 374.705,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.52,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004900.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 250004859.0,
        DnDrainCou: 2,
        DnHydroseq: 250004861.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0108,
        TotDASqKM: 9867.4461,
        DivDASqKM: 0.3546,
        Tidal: 0,
        TOTMA: 0.0086088115163600008,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9908.0,
        MAXELEVSMO: 10048.0,
        MINELEVSMO: 9908.0,
        SLOPE: 0.01060606,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.13199999928474401,
        QA_MA: 0.117,
        VA_MA: 0.58224,
        QC_MA: 0.117,
        VC_MA: 0.58224,
        QE_MA: 0.117,
        VE_MA: 0.58224,
        QA_01: 0.252,
        VA_01: 0.71997,
        QC_01: 0.252,
        VC_01: 0.71997,
        QE_01: 0.252,
        VE_01: 0.71997,
        QA_02: 0.281,
        VA_02: 0.74447,
        QC_02: 0.281,
        VC_02: 0.74447,
        QE_02: 0.281,
        VE_02: 0.74447,
        QA_03: 0.314,
        VA_03: 0.77096,
        QC_03: 0.314,
        VC_03: 0.77096,
        QE_03: 0.314,
        VE_03: 0.77096,
        QA_04: 0.162,
        VA_04: 0.6339,
        QC_04: 0.162,
        VC_04: 0.6339,
        QE_04: 0.162,
        VE_04: 0.6339,
        QA_05: 0.09,
        VA_05: 0.54663,
        QC_05: 0.09,
        VC_05: 0.54663,
        QE_05: 0.09,
        VE_05: 0.54663,
        QA_06: 0.061,
        VA_06: 0.50217,
        QC_06: 0.061,
        VC_06: 0.50217,
        QE_06: 0.061,
        VE_06: 0.50217,
        QA_07: 0.047,
        VA_07: 0.47712,
        QC_07: 0.047,
        VC_07: 0.47712,
        QE_07: 0.047,
        VE_07: 0.47712,
        QA_08: 0.038,
        VA_08: 0.45911,
        QC_08: 0.038,
        VC_08: 0.45911,
        QE_08: 0.038,
        VE_08: 0.45911,
        QA_09: 0.028,
        VA_09: 0.43655,
        QC_09: 0.028,
        VC_09: 0.43655,
        QE_09: 0.028,
        VE_09: 0.43655,
        QA_10: 0.023,
        VA_10: 0.42384,
        QC_10: 0.023,
        VC_10: 0.42384,
        QE_10: 0.023,
        VE_10: 0.42384,
        QA_11: 0.019,
        VA_11: 0.4127,
        QC_11: 0.019,
        VC_11: 0.4127,
        QE_11: 0.019,
        VE_11: 0.4127,
        QA_12: 0.09,
        VA_12: 0.54663,
        QC_12: 0.09,
        VC_12: 0.54663,
        QE_12: 0.09,
        VE_12: 0.54663,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885907318222166, 34.592087012972854, 0.0],
          [-80.885076384890169, 34.591147879641028, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288583.0,
        COMID: 9757148,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.044,
        REACHCODE: "03050103017544",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00040236563186299998,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062076.0,
        ToNode: 250059711.0,
        Hydroseq: 250004820.0,
        LevelPathI: 250004820.0,
        Pathlength: 374.812,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.803,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004846.0,
        DnLevelPat: 250004457.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004808.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2868,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0054178090699899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10062.0,
        MINELEVSMO: 10160.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.043999999761580998,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888843584884356, 34.593186079637917, 0.0],
          [-80.88900971821738, 34.592819612971823, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288584.0,
        COMID: 9757150,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.232,
        REACHCODE: "03050103017545",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0022023040436990001,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062079.0,
        ToNode: 250062080.0,
        Hydroseq: 250004917.0,
        LevelPathI: 250004844.0,
        Pathlength: 374.862,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.031,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004937.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 250004897.0,
        DnDrainCou: 2,
        DnHydroseq: 250004900.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0801,
        TotDASqKM: 9867.4209,
        DivDASqKM: 0.3294,
        Tidal: 0,
        TOTMA: 0.0151236248467,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10039.0,
        MAXELEVSMO: 10323.0,
        MINELEVSMO: 10048.0,
        SLOPE: 0.01185344,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.23199999332428001,
        QA_MA: 0.109,
        VA_MA: 0.58251,
        QC_MA: 0.109,
        VC_MA: 0.58251,
        QE_MA: 0.109,
        VE_MA: 0.58251,
        QA_01: 0.234,
        VA_01: 0.71965,
        QC_01: 0.234,
        VC_01: 0.71965,
        QE_01: 0.234,
        VE_01: 0.71965,
        QA_02: 0.261,
        VA_02: 0.7442,
        QC_02: 0.261,
        VC_02: 0.7442,
        QE_02: 0.261,
        VE_02: 0.7442,
        QA_03: 0.292,
        VA_03: 0.77097,
        QC_03: 0.292,
        VC_03: 0.77097,
        QE_03: 0.292,
        VE_03: 0.77097,
        QA_04: 0.15,
        VA_04: 0.63315,
        QC_04: 0.15,
        VC_04: 0.63315,
        QE_04: 0.15,
        VE_04: 0.63315,
        QA_05: 0.084,
        VA_05: 0.54709,
        QC_05: 0.084,
        VC_05: 0.54709,
        QE_05: 0.084,
        VE_05: 0.54709,
        QA_06: 0.057,
        VA_06: 0.50267,
        QC_06: 0.057,
        VC_06: 0.50267,
        QE_06: 0.057,
        VE_06: 0.50267,
        QA_07: 0.044,
        VA_07: 0.47772,
        QC_07: 0.044,
        VC_07: 0.47772,
        QE_07: 0.044,
        VE_07: 0.47772,
        QA_08: 0.035,
        VA_08: 0.45835,
        QC_08: 0.035,
        VC_08: 0.45835,
        QE_08: 0.035,
        VE_08: 0.45835,
        QA_09: 0.026,
        VA_09: 0.43645,
        QC_09: 0.026,
        VC_09: 0.43645,
        QE_09: 0.026,
        VE_09: 0.43645,
        QA_10: 0.022,
        VA_10: 0.42558,
        QC_10: 0.022,
        VC_10: 0.42558,
        QE_10: 0.022,
        VE_10: 0.42558,
        QA_11: 0.017,
        VA_11: 0.41059,
        QC_11: 0.017,
        VC_11: 0.41059,
        QE_11: 0.017,
        VE_11: 0.41059,
        QA_12: 0.084,
        VA_12: 0.54709,
        QC_12: 0.084,
        VC_12: 0.54709,
        QE_12: 0.084,
        VE_12: 0.54709,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886932384887302, 34.59403387963647, 0.0],
          [-80.885713518222474, 34.592247412972597, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288585.0,
        COMID: 9757152,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.025,
        REACHCODE: "03050103017546",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00025156828058899998,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062080.0,
        ToNode: 250062085.0,
        Hydroseq: 250004900.0,
        LevelPathI: 250004844.0,
        Pathlength: 374.837,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.056,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004917.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004882.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.4209,
        DivDASqKM: 0.3294,
        Tidal: 0,
        TOTMA: 0.0023891207846399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10048.0,
        MINELEVSMO: 10048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.025000000372528999,
        QA_MA: 0.109,
        VA_MA: 0.39735,
        QC_MA: 0.109,
        VC_MA: 0.39735,
        QE_MA: 0.109,
        VE_MA: 0.39735,
        QA_01: 0.234,
        VA_01: 0.44186,
        QC_01: 0.234,
        VC_01: 0.44186,
        QE_01: 0.234,
        VE_01: 0.44186,
        QA_02: 0.261,
        VA_02: 0.44983,
        QC_02: 0.261,
        VC_02: 0.44983,
        QE_02: 0.261,
        VE_02: 0.44983,
        QA_03: 0.292,
        VA_03: 0.45851,
        QC_03: 0.292,
        VC_03: 0.45851,
        QE_03: 0.292,
        VE_03: 0.45851,
        QA_04: 0.15,
        VA_04: 0.41379,
        QC_04: 0.15,
        VC_04: 0.41379,
        QE_04: 0.15,
        VE_04: 0.41379,
        QA_05: 0.084,
        VA_05: 0.38586,
        QC_05: 0.084,
        VC_05: 0.38586,
        QE_05: 0.084,
        VE_05: 0.38586,
        QA_06: 0.057,
        VA_06: 0.37144,
        QC_06: 0.057,
        VC_06: 0.37144,
        QE_06: 0.057,
        VE_06: 0.37144,
        QA_07: 0.044,
        VA_07: 0.36334,
        QC_07: 0.044,
        VC_07: 0.36334,
        QE_07: 0.044,
        VE_07: 0.36334,
        QA_08: 0.035,
        VA_08: 0.35706,
        QC_08: 0.035,
        VC_08: 0.35706,
        QE_08: 0.035,
        VE_08: 0.35706,
        QA_09: 0.026,
        VA_09: 0.34995,
        QC_09: 0.026,
        VC_09: 0.34995,
        QE_09: 0.026,
        VE_09: 0.34995,
        QA_10: 0.022,
        VA_10: 0.34642,
        QC_10: 0.022,
        VC_10: 0.34642,
        QE_10: 0.022,
        VE_10: 0.34642,
        QA_11: 0.017,
        VA_11: 0.34156,
        QC_11: 0.017,
        VC_11: 0.34156,
        QE_11: 0.017,
        VE_11: 0.34156,
        QA_12: 0.084,
        VA_12: 0.38586,
        QC_12: 0.084,
        VC_12: 0.38586,
        QE_12: 0.084,
        VE_12: 0.38586,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885713518222474, 34.592247412972597, 0.0],
          [-80.885907318222166, 34.592087012972854, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288586.0,
        COMID: 9757154,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.156,
        REACHCODE: "03050103017547",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001627127297701,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062081.0,
        ToNode: 250062085.0,
        Hydroseq: 250004899.0,
        LevelPathI: 250004899.0,
        Pathlength: 374.837,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.567,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004899.0,
        UpHydroseq: 250004918.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004882.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0144,
        TotDASqKM: 9867.2337,
        DivDASqKM: 0.0144,
        Tidal: 0,
        TOTMA: 0.0168709814625,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10048.0,
        MAXELEVSMO: 10048.0,
        MINELEVSMO: 10048.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.15600000321865101,
        QA_MA: 0.004,
        VA_MA: 0.35112,
        QC_MA: 0.004,
        VC_MA: 0.35112,
        QE_MA: 0.004,
        VE_MA: 0.35112,
        QA_01: 0.01,
        VA_01: 0.3779,
        QC_01: 0.01,
        VC_01: 0.3779,
        QE_01: 0.01,
        VE_01: 0.3779,
        QA_02: 0.011,
        VA_02: 0.38151,
        QC_02: 0.011,
        VC_02: 0.38151,
        QE_02: 0.011,
        VE_02: 0.38151,
        QA_03: 0.012,
        VA_03: 0.38496,
        QC_03: 0.012,
        VC_03: 0.38496,
        QE_03: 0.012,
        VE_03: 0.38496,
        QA_04: 0.006,
        VA_04: 0.36138,
        QC_04: 0.006,
        VC_04: 0.36138,
        QE_04: 0.006,
        VE_04: 0.36138,
        QA_05: 0.003,
        VA_05: 0.34507,
        QC_05: 0.003,
        VC_05: 0.34507,
        QE_05: 0.003,
        VE_05: 0.34507,
        QA_06: 0.002,
        VA_06: 0.33796,
        QC_06: 0.002,
        VC_06: 0.33796,
        QE_06: 0.002,
        VE_06: 0.33796,
        QA_07: 0.001,
        VA_07: 0.32886,
        QC_07: 0.001,
        VC_07: 0.32886,
        QE_07: 0.001,
        VE_07: 0.32886,
        QA_08: 0.001,
        VA_08: 0.32886,
        QC_08: 0.001,
        VC_08: 0.32886,
        QE_08: 0.001,
        VE_08: 0.32886,
        QA_09: 0.001,
        VA_09: 0.32886,
        QC_09: 0.001,
        VC_09: 0.32886,
        QE_09: 0.001,
        VE_09: 0.32886,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.003,
        VA_12: 0.34507,
        QC_12: 0.003,
        VC_12: 0.34507,
        QE_12: 0.003,
        VE_12: 0.34507,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887347784886515, 34.592842812971639, 0.0],
          [-80.885907318222166, 34.592087012972854, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288595.0,
        COMID: 9757172,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.053,
        REACHCODE: "03050103017556",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00058569793414299997,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062090.0,
        ToNode: 250062095.0,
        Hydroseq: 250004861.0,
        LevelPathI: 250004844.0,
        Pathlength: 374.652,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.573,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004882.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004844.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0045,
        TotDASqKM: 9867.4506,
        DivDASqKM: 0.3591,
        Tidal: 0,
        TOTMA: 0.0037578465364299998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9891.0,
        MAXELEVSMO: 9908.0,
        MINELEVSMO: 9891.0,
        SLOPE: 0.00320754,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.052999999374151001,
        QA_MA: 0.119,
        VA_MA: 0.53556,
        QC_MA: 0.119,
        VC_MA: 0.53556,
        QE_MA: 0.119,
        VE_MA: 0.53556,
        QA_01: 0.255,
        VA_01: 0.64888,
        QC_01: 0.255,
        VC_01: 0.64888,
        QE_01: 0.255,
        VE_01: 0.64888,
        QA_02: 0.284,
        VA_02: 0.66892,
        QC_02: 0.284,
        VC_02: 0.66892,
        QE_02: 0.284,
        VE_02: 0.66892,
        QA_03: 0.318,
        VA_03: 0.69123,
        QC_03: 0.318,
        VC_03: 0.69123,
        QE_03: 0.318,
        VE_03: 0.69123,
        QA_04: 0.164,
        VA_04: 0.57774,
        QC_04: 0.164,
        VC_04: 0.57774,
        QE_04: 0.164,
        VE_04: 0.57774,
        QA_05: 0.091,
        VA_05: 0.5054,
        QC_05: 0.091,
        VC_05: 0.5054,
        QE_05: 0.091,
        VE_05: 0.5054,
        QA_06: 0.062,
        VA_06: 0.46908,
        QC_06: 0.062,
        VC_06: 0.46908,
        QE_06: 0.062,
        VE_06: 0.46908,
        QA_07: 0.048,
        VA_07: 0.44866,
        QC_07: 0.048,
        VC_07: 0.44866,
        QE_07: 0.048,
        VE_07: 0.44866,
        QA_08: 0.038,
        VA_08: 0.4323,
        QC_08: 0.038,
        VC_08: 0.4323,
        QE_08: 0.038,
        VE_08: 0.4323,
        QA_09: 0.028,
        VA_09: 0.41375,
        QC_09: 0.028,
        VC_09: 0.41375,
        QE_09: 0.028,
        VE_09: 0.41375,
        QA_10: 0.024,
        VA_10: 0.40547,
        QC_10: 0.024,
        VC_10: 0.40547,
        QE_10: 0.024,
        VE_10: 0.40547,
        QA_11: 0.019,
        VA_11: 0.39414,
        QC_11: 0.019,
        VC_11: 0.39414,
        QE_11: 0.019,
        VE_11: 0.39414,
        QA_12: 0.091,
        VA_12: 0.5054,
        QC_12: 0.091,
        VC_12: 0.5054,
        QE_12: 0.091,
        VE_12: 0.5054,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885076384890169, 34.591147879641028, 0.0],
          [-80.884494718224403, 34.591079279641065, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288596.0,
        COMID: 9757174,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.045,
        REACHCODE: "03050103017557",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000442276237684,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062091.0,
        ToNode: 250062096.0,
        Hydroseq: 250004860.0,
        LevelPathI: 250004860.0,
        Pathlength: 374.8,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.73,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004860.0,
        UpHydroseq: 250004881.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004729.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9867.2319,
        DivDASqKM: 0.0126,
        Tidal: 0,
        TOTMA: 0.0041656003052900001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9936.0,
        MAXELEVSMO: 9982.0,
        MINELEVSMO: 9970.0,
        SLOPE: 0.00266666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.045000001788139003,
        QA_MA: 0.004,
        VA_MA: 0.41021,
        QC_MA: 0.004,
        VC_MA: 0.41021,
        QE_MA: 0.004,
        VE_MA: 0.41021,
        QA_01: 0.008,
        VA_01: 0.45551,
        QC_01: 0.008,
        VC_01: 0.45551,
        QE_01: 0.008,
        VE_01: 0.45551,
        QA_02: 0.009,
        VA_02: 0.46501,
        QC_02: 0.009,
        VC_02: 0.46501,
        QE_02: 0.009,
        VE_02: 0.46501,
        QA_03: 0.011,
        VA_03: 0.48262,
        QC_03: 0.011,
        VC_03: 0.48262,
        QE_03: 0.011,
        VE_03: 0.48262,
        QA_04: 0.005,
        VA_04: 0.42302,
        QC_04: 0.005,
        VC_04: 0.42302,
        QE_04: 0.005,
        VE_04: 0.42302,
        QA_05: 0.003,
        VA_05: 0.39579,
        QC_05: 0.003,
        VC_05: 0.39579,
        QE_05: 0.003,
        VE_05: 0.39579,
        QA_06: 0.002,
        VA_06: 0.37886,
        QC_06: 0.002,
        VC_06: 0.37886,
        QE_06: 0.002,
        VE_06: 0.37886,
        QA_07: 0.001,
        VA_07: 0.35716,
        QC_07: 0.001,
        VC_07: 0.35716,
        QE_07: 0.001,
        VE_07: 0.35716,
        QA_08: 0.001,
        VA_08: 0.35716,
        QC_08: 0.001,
        VC_08: 0.35716,
        QE_08: 0.001,
        VE_08: 0.35716,
        QA_09: 0.001,
        VA_09: 0.35716,
        QC_09: 0.001,
        VC_09: 0.35716,
        QE_09: 0.001,
        VE_09: 0.35716,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.003,
        VA_12: 0.39579,
        QC_12: 0.003,
        VC_12: 0.39579,
        QE_12: 0.003,
        VE_12: 0.39579,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885796518222321, 34.591102079640962, 0.0],
          [-80.886101118221859, 34.590781412974934, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288597.0,
        COMID: 9757176,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.065,
        REACHCODE: "03050103017558",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00067021298022800001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062092.0,
        ToNode: 250062096.0,
        Hydroseq: 250004745.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.8,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.545,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004763.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004729.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9867.4407,
        DivDASqKM: 0.0612,
        Tidal: 0,
        TOTMA: 0.0066933085109600002,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9970.0,
        MAXELEVSMO: 9970.0,
        MINELEVSMO: 9970.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064999997615814001,
        QA_MA: 0.02,
        VA_MA: 0.36876,
        QC_MA: 0.02,
        VC_MA: 0.36876,
        QE_MA: 0.02,
        VE_MA: 0.36876,
        QA_01: 0.043,
        VA_01: 0.39903,
        QC_01: 0.043,
        VC_01: 0.39903,
        QE_01: 0.043,
        VE_01: 0.39903,
        QA_02: 0.048,
        VA_02: 0.40449,
        QC_02: 0.048,
        VC_02: 0.40449,
        QE_02: 0.048,
        VE_02: 0.40449,
        QA_03: 0.054,
        VA_03: 0.41069,
        QC_03: 0.054,
        VC_03: 0.41069,
        QE_03: 0.054,
        VE_03: 0.41069,
        QA_04: 0.027,
        VA_04: 0.37919,
        QC_04: 0.027,
        VC_04: 0.37919,
        QE_04: 0.027,
        VE_04: 0.37919,
        QA_05: 0.015,
        VA_05: 0.36021,
        QC_05: 0.015,
        VC_05: 0.36021,
        QE_05: 0.015,
        VE_05: 0.36021,
        QA_06: 0.01,
        VA_06: 0.35017,
        QC_06: 0.01,
        VC_06: 0.35017,
        QE_06: 0.01,
        VE_06: 0.35017,
        QA_07: 0.008,
        VA_07: 0.3455,
        QC_07: 0.008,
        VC_07: 0.3455,
        QE_07: 0.008,
        VE_07: 0.3455,
        QA_08: 0.006,
        VA_08: 0.34024,
        QC_08: 0.006,
        VC_08: 0.34024,
        QE_08: 0.006,
        VE_08: 0.34024,
        QA_09: 0.004,
        VA_09: 0.33407,
        QC_09: 0.004,
        VC_09: 0.33407,
        QE_09: 0.004,
        VE_09: 0.33407,
        QA_10: 0.004,
        VA_10: 0.33407,
        QC_10: 0.004,
        VC_10: 0.33407,
        QE_10: 0.004,
        VE_10: 0.33407,
        QA_11: 0.003,
        VA_11: 0.33044,
        QC_11: 0.003,
        VC_11: 0.33044,
        QE_11: 0.003,
        VE_11: 0.33044,
        QA_12: 0.015,
        VA_12: 0.36021,
        QC_12: 0.015,
        VC_12: 0.36021,
        QE_12: 0.015,
        VE_12: 0.36021,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886682784887626, 34.591102012974488, 0.0],
          [-80.886101118221859, 34.590781412974934, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288598.0,
        COMID: 9757178,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.057,
        REACHCODE: "03050103017559",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00051585449202200002,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062090.0,
        ToNode: 250062099.0,
        Hydroseq: 250004859.0,
        LevelPathI: 250004859.0,
        Pathlength: 374.69,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.577,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004882.0,
        DnLevelPat: 250004843.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004843.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.4461,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.00701852542783,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9908.0,
        MINELEVSMO: 9864.0,
        SLOPE: 0.00771929,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.057000000029802003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885076384890169, 34.591147879641028, 0.0],
          [-80.884965518223623, 34.590644079641834, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288591.0,
        COMID: 9757164,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.172,
        REACHCODE: "03050103017552",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001731219509193,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062086.0,
        ToNode: 250062091.0,
        Hydroseq: 250004881.0,
        LevelPathI: 250004860.0,
        Pathlength: 374.845,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.685,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004860.0,
        UpHydroseq: 250004898.0,
        DnLevelPat: 250004860.0,
        DnMinorHyd: 250004858.0,
        DnDrainCou: 2,
        DnHydroseq: 250004860.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.2292,
        DivDASqKM: 0.0099,
        Tidal: 0,
        TOTMA: 0.018775123102499999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9982.0,
        MAXELEVSMO: 9982.0,
        MINELEVSMO: 9982.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.17200000584125499,
        QA_MA: 0.003,
        VA_MA: 0.34787,
        QC_MA: 0.003,
        VC_MA: 0.34787,
        QE_MA: 0.003,
        VE_MA: 0.34787,
        QA_01: 0.007,
        VA_01: 0.37031,
        QC_01: 0.007,
        VC_01: 0.37031,
        QE_01: 0.007,
        VE_01: 0.37031,
        QA_02: 0.007,
        VA_02: 0.37031,
        QC_02: 0.007,
        VC_02: 0.37031,
        QE_02: 0.007,
        VE_02: 0.37031,
        QA_03: 0.008,
        VA_03: 0.37485,
        QC_03: 0.008,
        VC_03: 0.37485,
        QE_03: 0.008,
        VE_03: 0.37485,
        QA_04: 0.004,
        VA_04: 0.35439,
        QC_04: 0.004,
        VC_04: 0.35439,
        QE_04: 0.004,
        VE_04: 0.35439,
        QA_05: 0.002,
        VA_05: 0.34022,
        QC_05: 0.002,
        VC_05: 0.34022,
        QE_05: 0.002,
        VE_05: 0.34022,
        QA_06: 0.001,
        VA_06: 0.33042,
        QC_06: 0.001,
        VC_06: 0.33042,
        QE_06: 0.001,
        VE_06: 0.33042,
        QA_07: 0.001,
        VA_07: 0.33042,
        QC_07: 0.001,
        VC_07: 0.33042,
        QE_07: 0.001,
        VE_07: 0.33042,
        QA_08: 0.001,
        VA_08: 0.33042,
        QC_08: 0.001,
        VC_08: 0.33042,
        QE_08: 0.001,
        VE_08: 0.33042,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.34022,
        QC_12: 0.002,
        VC_12: 0.34022,
        QE_12: 0.002,
        VE_12: 0.34022,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887126118220237, 34.591972412973121, 0.0],
          [-80.885796518222321, 34.591102079640962, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288592.0,
        COMID: 9757166,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.124,
        REACHCODE: "03050103017553",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001202390372674,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062086.0,
        ToNode: 250062092.0,
        Hydroseq: 250004880.0,
        LevelPathI: 250004880.0,
        Pathlength: 374.865,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.637,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004860.0,
        UpHydroseq: 250004898.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004745.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.2193,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.015268370848599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9982.0,
        MINELEVSMO: 9970.0,
        SLOPE: 0.00096774,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.12399999797344199,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.887126118220237, 34.591972412973121, 0.0],
          [-80.886682784887626, 34.591102012974488, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288593.0,
        COMID: 9757168,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.104,
        REACHCODE: "03050103017554",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010951807713929999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062088.0,
        ToNode: 250062092.0,
        Hydroseq: 250004763.0,
        LevelPathI: 250004607.0,
        Pathlength: 374.865,
        TerminalPa: 250002604.0,
        ArbolateSu: 7554.151,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004607.0,
        UpHydroseq: 250004805.0,
        DnLevelPat: 250004607.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004745.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0063,
        TotDASqKM: 9867.4371,
        DivDASqKM: 0.0576,
        Tidal: 0,
        TOTMA: 0.0082490690995900007,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9958.0,
        MAXELEVSMO: 10047.0,
        MINELEVSMO: 9970.0,
        SLOPE: 0.00740384,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.104000002145767,
        QA_MA: 0.019,
        VA_MA: 0.47874,
        QC_MA: 0.019,
        VC_MA: 0.47874,
        QE_MA: 0.019,
        VE_MA: 0.47874,
        QA_01: 0.041,
        VA_01: 0.56467,
        QC_01: 0.041,
        VC_01: 0.56467,
        QE_01: 0.041,
        VE_01: 0.56467,
        QA_02: 0.045,
        VA_02: 0.57765,
        QC_02: 0.045,
        VC_02: 0.57765,
        QE_02: 0.045,
        VE_02: 0.57765,
        QA_03: 0.051,
        VA_03: 0.59616,
        QC_03: 0.051,
        VC_03: 0.59616,
        QE_03: 0.051,
        VE_03: 0.59616,
        QA_04: 0.026,
        VA_04: 0.50961,
        QC_04: 0.026,
        VC_04: 0.50961,
        QE_04: 0.026,
        VE_04: 0.50961,
        QA_05: 0.014,
        VA_05: 0.45324,
        QC_05: 0.014,
        VC_05: 0.45324,
        QE_05: 0.014,
        VE_05: 0.45324,
        QA_06: 0.01,
        VA_06: 0.42954,
        QC_06: 0.01,
        VC_06: 0.42954,
        QE_06: 0.01,
        VE_06: 0.42954,
        QA_07: 0.007,
        VA_07: 0.40864,
        QC_07: 0.007,
        VC_07: 0.40864,
        QE_07: 0.007,
        VE_07: 0.40864,
        QA_08: 0.006,
        VA_08: 0.40076,
        QC_08: 0.006,
        VC_08: 0.40076,
        QE_08: 0.006,
        VE_08: 0.40076,
        QA_09: 0.004,
        VA_09: 0.38287,
        QC_09: 0.004,
        VC_09: 0.38287,
        QE_09: 0.004,
        VE_09: 0.38287,
        QA_10: 0.003,
        VA_10: 0.37232,
        QC_10: 0.003,
        VC_10: 0.37232,
        QE_10: 0.003,
        VE_10: 0.37232,
        QA_11: 0.003,
        VA_11: 0.37232,
        QC_11: 0.003,
        VC_11: 0.37232,
        QE_11: 0.003,
        VE_11: 0.37232,
        QA_12: 0.014,
        VA_12: 0.45324,
        QC_12: 0.014,
        VC_12: 0.45324,
        QE_12: 0.014,
        VE_12: 0.45324,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88767998488612, 34.591537012973731, 0.0],
          [-80.886682784887626, 34.591102012974488, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288594.0,
        COMID: 9757170,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.171,
        REACHCODE: "03050103017555",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001688196942165,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062080.0,
        ToNode: 250062095.0,
        Hydroseq: 250004897.0,
        LevelPathI: 250004897.0,
        Pathlength: 374.652,
        TerminalPa: 250002604.0,
        ArbolateSu: 7552.202,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004844.0,
        UpHydroseq: 250004917.0,
        DnLevelPat: 250004844.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004844.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.4209,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.021055576742199999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10048.0,
        MINELEVSMO: 9891.0,
        SLOPE: 0.0747619,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.020999999716878,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885713518222474, 34.592247412972597, 0.0],
          [-80.884494718224403, 34.591079279641065, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287540.0,
        COMID: 9733406,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.002,
        REACHCODE: "03050103000702",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.010551296151433,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059046.0,
        ToNode: 250059879.0,
        Hydroseq: 250006404.0,
        LevelPathI: 250006378.0,
        Pathlength: 421.839,
        TerminalPa: 250002604.0,
        ArbolateSu: 6021.155,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250006378.0,
        UpHydroseq: 250006429.0,
        DnLevelPat: 250006378.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006378.0,
        FromMeas: 8.28769,
        ToMeas: 90.42595,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.8874,
        TotDASqKM: 7979.0607,
        DivDASqKM: 0.8874,
        Tidal: 0,
        TOTMA: 0.072711797847800005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14414.0,
        MAXELEVSMO: 14473.0,
        MINELEVSMO: 14418.0,
        SLOPE: 0.0005489,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.001999974250793,
        QA_MA: 0.364,
        VA_MA: 0.52328,
        QC_MA: 0.364,
        VC_MA: 0.52328,
        QE_MA: 0.364,
        VE_MA: 0.52328,
        QA_01: 0.787,
        VA_01: 0.63201,
        QC_01: 0.787,
        VC_01: 0.63201,
        QE_01: 0.787,
        VE_01: 0.63201,
        QA_02: 0.823,
        VA_02: 0.63979,
        QC_02: 0.823,
        VC_02: 0.63979,
        QE_02: 0.823,
        VE_02: 0.63979,
        QA_03: 0.87,
        VA_03: 0.6497,
        QC_03: 0.87,
        VC_03: 0.6497,
        QE_03: 0.87,
        VE_03: 0.6497,
        QA_04: 0.524,
        VA_04: 0.56915,
        QC_04: 0.524,
        VC_04: 0.56915,
        QE_04: 0.524,
        VE_04: 0.56915,
        QA_05: 0.286,
        VA_05: 0.49745,
        QC_05: 0.286,
        VC_05: 0.49745,
        QE_05: 0.286,
        VE_05: 0.49745,
        QA_06: 0.178,
        VA_06: 0.45537,
        QC_06: 0.178,
        VC_06: 0.45537,
        QE_06: 0.178,
        VE_06: 0.45537,
        QA_07: 0.131,
        VA_07: 0.43328,
        QC_07: 0.131,
        VC_07: 0.43328,
        QE_07: 0.131,
        VE_07: 0.43328,
        QA_08: 0.095,
        VA_08: 0.41369,
        QC_08: 0.095,
        VC_08: 0.41369,
        QE_08: 0.095,
        VE_08: 0.41369,
        QA_09: 0.083,
        VA_09: 0.40641,
        QC_09: 0.083,
        VC_09: 0.40641,
        QE_09: 0.083,
        VE_09: 0.40641,
        QA_10: 0.059,
        VA_10: 0.39016,
        QC_10: 0.059,
        VC_10: 0.39016,
        QE_10: 0.059,
        VE_10: 0.39016,
        QA_11: 0.094,
        VA_11: 0.4131,
        QC_11: 0.094,
        VC_11: 0.4131,
        QE_11: 0.094,
        VE_11: 0.4131,
        QA_12: 0.441,
        VA_12: 0.54633,
        QC_12: 0.441,
        VC_12: 0.54633,
        QE_12: 0.441,
        VE_12: 0.54633,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.89104038488091, 34.944087812426517, 0.0],
          [-80.888008318218965, 34.943011612428222, 0.0],
          [-80.881582918228844, 34.946219012423228, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287541.0,
        COMID: 9736232,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.102,
        REACHCODE: "03050103000702",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000962084154323,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059879.0,
        ToNode: 250059883.0,
        Hydroseq: 250006378.0,
        LevelPathI: 250006378.0,
        Pathlength: 421.737,
        TerminalPa: 250002604.0,
        ArbolateSu: 6021.257,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250006378.0,
        UpHydroseq: 250006404.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006357.0,
        FromMeas: 0.0,
        ToMeas: 8.28769,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0207,
        TotDASqKM: 7979.0814,
        DivDASqKM: 0.9081,
        Tidal: 0,
        TOTMA: 0.0091636828174700008,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14309.0,
        MAXELEVSMO: 14418.0,
        MINELEVSMO: 14418.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.10199999809265101,
        QA_MA: 0.373,
        VA_MA: 0.42267,
        QC_MA: 0.373,
        VC_MA: 0.42267,
        QE_MA: 0.373,
        VE_MA: 0.42267,
        QA_01: 0.805,
        VA_01: 0.48033,
        QC_01: 0.805,
        VC_01: 0.48033,
        QE_01: 0.805,
        VE_01: 0.48033,
        QA_02: 0.842,
        VA_02: 0.48449,
        QC_02: 0.842,
        VC_02: 0.48449,
        QE_02: 0.842,
        VE_02: 0.48449,
        QA_03: 0.891,
        VA_03: 0.48985,
        QC_03: 0.891,
        VC_03: 0.48985,
        QE_03: 0.891,
        VE_03: 0.48985,
        QA_04: 0.537,
        VA_04: 0.44707,
        QC_04: 0.537,
        VC_04: 0.44707,
        QE_04: 0.537,
        VE_04: 0.44707,
        QA_05: 0.293,
        VA_05: 0.40892,
        QC_05: 0.293,
        VC_05: 0.40892,
        QE_05: 0.293,
        VE_05: 0.40892,
        QA_06: 0.183,
        VA_06: 0.38669,
        QC_06: 0.183,
        VC_06: 0.38669,
        QE_06: 0.183,
        VE_06: 0.38669,
        QA_07: 0.134,
        VA_07: 0.37475,
        QC_07: 0.134,
        VC_07: 0.37475,
        QE_07: 0.134,
        VE_07: 0.37475,
        QA_08: 0.097,
        VA_08: 0.36429,
        QC_08: 0.097,
        VC_08: 0.36429,
        QE_08: 0.097,
        VE_08: 0.36429,
        QA_09: 0.085,
        VA_09: 0.3605,
        QC_09: 0.085,
        VC_09: 0.3605,
        QE_09: 0.085,
        VE_09: 0.3605,
        QA_10: 0.061,
        VA_10: 0.35208,
        QC_10: 0.061,
        VC_10: 0.35208,
        QE_10: 0.061,
        VE_10: 0.35208,
        QA_11: 0.096,
        VA_11: 0.36398,
        QC_11: 0.096,
        VC_11: 0.36398,
        QE_11: 0.096,
        VE_11: 0.36398,
        QA_12: 0.451,
        VA_12: 0.4348,
        QC_12: 0.451,
        VC_12: 0.4348,
        QE_12: 0.451,
        VE_12: 0.4348,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881582918228844, 34.946219012423228, 0.0],
          [-80.88110678489636, 34.947008479088652, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287542.0,
        COMID: 9736264,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.118,
        REACHCODE: "03050103000702",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0010836312572389999,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059890.0,
        ToNode: 250059046.0,
        Hydroseq: 250006429.0,
        LevelPathI: 250006378.0,
        Pathlength: 422.841,
        TerminalPa: 250002604.0,
        ArbolateSu: 6020.153,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006458.0,
        DnLevelPat: 250006378.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006404.0,
        FromMeas: 90.42595,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 7978.1733,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0682941149064,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 14473.0,
        MINELEVSMO: 14473.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.118000000715256,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.891383184880397, 34.9450840124249, 0.0],
          [-80.89104038488091, 34.944087812426517, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288491.0,
        COMID: 9736448,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.473,
        REACHCODE: "03050103001811",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00428099552994,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059981.0,
        ToNode: 250059982.0,
        Hydroseq: 250005466.0,
        LevelPathI: 250003258.0,
        Pathlength: 397.501,
        TerminalPa: 250002604.0,
        ArbolateSu: 6994.839,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005523.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005433.0,
        FromMeas: 0.0,
        ToMeas: 45.82412,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1908,
        TotDASqKM: 9335.0151,
        DivDASqKM: 9334.0872,
        Tidal: 0,
        TOTMA: 0.0064054520502100001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12761.0,
        MAXELEVSMO: 12934.0,
        MINELEVSMO: 12766.0,
        SLOPE: 0.00355179,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.47299998998642001,
        QA_MA: 4680.422,
        VA_MA: 2.77595,
        QC_MA: 4680.422,
        VC_MA: 2.77595,
        QE_MA: 5382.242,
        VE_MA: 2.80403,
        QA_01: 8535.617,
        VA_01: 3.70332,
        QC_01: 8535.617,
        VC_01: 3.70332,
        QE_01: 7100.273,
        VE_01: 3.19952,
        QA_02: 9024.192,
        VA_02: 3.80516,
        QC_02: 9024.192,
        VC_02: 3.80516,
        QE_02: 7213.082,
        VE_02: 3.22382,
        QA_03: 9914.701,
        VA_03: 3.98434,
        QC_03: 9914.701,
        VC_03: 3.98434,
        QE_03: 7679.082,
        VE_03: 3.32236,
        QA_04: 6867.008,
        VA_04: 3.333,
        QC_04: 6867.008,
        VC_04: 3.333,
        QE_04: 6645.801,
        VE_04: 3.09973,
        QA_05: 4713.706,
        VA_05: 2.78525,
        QC_05: 4713.706,
        VC_05: 2.78525,
        QE_05: 5699.297,
        VE_05: 2.88105,
        QA_06: 2777.443,
        VA_06: 2.17873,
        QC_06: 2777.443,
        VC_06: 2.17873,
        QE_06: 5016.344,
        VE_06: 2.71245,
        QA_07: 1720.614,
        VA_07: 1.75881,
        QC_07: 1720.614,
        VC_07: 1.75881,
        QE_07: 3812.173,
        VE_07: 2.38638,
        QA_08: 1223.268,
        VA_08: 1.51849,
        QC_08: 1223.268,
        VC_08: 1.51849,
        QE_08: 3561.03,
        VE_08: 2.31253,
        QA_09: 1120.26,
        VA_09: 1.46327,
        QC_09: 1120.26,
        VC_09: 1.46327,
        QE_09: 3264.593,
        VE_09: 2.22213,
        QA_10: 1214.084,
        VA_10: 1.51366,
        QC_10: 1214.084,
        VC_10: 1.51366,
        QE_10: 4457.305,
        VE_10: 2.56626,
        QA_11: 3143.441,
        VA_11: 2.3058,
        QC_11: 3143.441,
        VC_11: 2.3058,
        QE_11: 4519.899,
        VE_11: 2.58304,
        QA_12: 5942.627,
        VA_12: 3.10949,
        QC_12: 5942.627,
        VC_12: 3.10949,
        QE_12: 5651.336,
        VE_12: 2.86953,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.874673984906337, 34.773026012692014, 0.0],
          [-80.875322584905291, 34.768900279365141, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288492.0,
        COMID: 9736442,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.164,
        REACHCODE: "03050103001811",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0015669516457490001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059978.0,
        ToNode: 250059979.0,
        Hydroseq: 250005535.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.21,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.522,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005547.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005523.0,
        FromMeas: 68.69982,
        ToMeas: 84.65313,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0171,
        TotDASqKM: 9332.8929,
        DivDASqKM: 9332.8929,
        Tidal: 0,
        TOTMA: 0.0022630880893,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13010.0,
        MAXELEVSMO: 13070.0,
        MINELEVSMO: 13019.0,
        SLOPE: 0.00310975,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.164000004529953,
        QA_MA: 4679.951,
        VA_MA: 2.72428,
        QC_MA: 4679.951,
        VC_MA: 2.72428,
        QE_MA: 5381.771,
        VE_MA: 2.75178,
        QA_01: 8534.578,
        VA_01: 3.6322,
        QC_01: 8534.578,
        VC_01: 3.6322,
        QE_01: 7099.237,
        VE_01: 3.1389,
        QA_02: 9023.116,
        VA_02: 3.73191,
        QC_02: 9023.116,
        VC_02: 3.73191,
        QE_02: 7212.008,
        VE_02: 3.16269,
        QA_03: 9913.545,
        VA_03: 3.90735,
        QC_03: 9913.545,
        VC_03: 3.90735,
        QE_03: 7677.929,
        VE_03: 3.25916,
        QA_04: 6866.338,
        VA_04: 3.26968,
        QC_04: 6866.338,
        VC_04: 3.26968,
        QE_04: 6645.131,
        VE_04: 3.04127,
        QA_05: 4713.337,
        VA_05: 2.73342,
        QC_05: 4713.337,
        VC_05: 2.73342,
        QE_05: 5698.927,
        VE_05: 2.82721,
        QA_06: 2777.202,
        VA_06: 2.13959,
        QC_06: 2777.202,
        VC_06: 2.13959,
        QE_06: 5016.101,
        VE_06: 2.66217,
        QA_07: 1720.438,
        VA_07: 1.72844,
        QC_07: 1720.438,
        VC_07: 1.72844,
        QE_07: 3811.994,
        VE_07: 2.34292,
        QA_08: 1223.14,
        VA_08: 1.49315,
        QC_08: 1223.14,
        VC_08: 1.49315,
        QE_08: 3560.898,
        VE_08: 2.27062,
        QA_09: 1120.164,
        VA_09: 1.43909,
        QC_09: 1120.164,
        VC_09: 1.43909,
        QE_09: 3264.494,
        VE_09: 2.18212,
        QA_10: 1214.004,
        VA_10: 1.48844,
        QC_10: 1214.004,
        VC_10: 1.48844,
        QE_10: 4457.221,
        VE_10: 2.51906,
        QA_11: 3143.361,
        VA_11: 2.26406,
        QC_11: 3143.361,
        VC_11: 2.26406,
        QE_11: 4519.817,
        VE_11: 2.5355,
        QA_12: 5942.087,
        VA_12: 3.05085,
        QC_12: 5942.087,
        VC_12: 3.05085,
        QE_12: 5650.796,
        VE_12: 2.81589,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875277984905324, 34.776339279353579, 0.0],
          [-80.87443845157334, 34.775093612688806, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288493.0,
        COMID: 9736444,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.236,
        REACHCODE: "03050103001811",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0021328321274029999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059979.0,
        ToNode: 250059981.0,
        Hydroseq: 250005523.0,
        LevelPathI: 250003258.0,
        Pathlength: 397.974,
        TerminalPa: 250002604.0,
        ArbolateSu: 6992.966,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005535.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005466.0,
        FromMeas: 45.82412,
        ToMeas: 68.69982,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0297,
        TotDASqKM: 9333.8487,
        DivDASqKM: 9333.8487,
        Tidal: 0,
        TOTMA: 0.00318966448769,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12934.0,
        MAXELEVSMO: 13019.0,
        MINELEVSMO: 12934.0,
        SLOPE: 0.00360169,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.236000001430511,
        QA_MA: 4680.328,
        VA_MA: 2.78141,
        QC_MA: 4680.328,
        VC_MA: 2.78141,
        QE_MA: 5382.148,
        VE_MA: 2.80956,
        QA_01: 8535.41,
        VA_01: 3.71084,
        QC_01: 8535.41,
        VC_01: 3.71084,
        QE_01: 7100.067,
        VE_01: 3.20591,
        QA_02: 9023.977,
        VA_02: 3.8129,
        QC_02: 9023.977,
        VC_02: 3.8129,
        QE_02: 7212.867,
        VE_02: 3.23026,
        QA_03: 9914.47,
        VA_03: 3.99248,
        QC_03: 9914.47,
        VC_03: 3.99248,
        QE_03: 7678.851,
        VE_03: 3.32902,
        QA_04: 6866.873,
        VA_04: 3.3397,
        QC_04: 6866.873,
        VC_04: 3.3397,
        QE_04: 6645.666,
        VE_04: 3.10591,
        QA_05: 4713.632,
        VA_05: 2.79074,
        QC_05: 4713.632,
        VC_05: 2.79074,
        QE_05: 5699.223,
        VE_05: 2.88675,
        QA_06: 2777.395,
        VA_06: 2.18288,
        QC_06: 2777.395,
        VC_06: 2.18288,
        QE_06: 5016.296,
        VE_06: 2.71779,
        QA_07: 1720.579,
        VA_07: 1.76202,
        QC_07: 1720.579,
        VC_07: 1.76202,
        QE_07: 3812.137,
        VE_07: 2.39099,
        QA_08: 1223.243,
        VA_08: 1.52117,
        QC_08: 1223.243,
        VC_08: 1.52117,
        QE_08: 3561.003,
        VE_08: 2.31698,
        QA_09: 1120.241,
        VA_09: 1.46583,
        QC_09: 1120.241,
        VC_09: 1.46583,
        QE_09: 3264.573,
        VE_09: 2.22639,
        QA_10: 1214.068,
        VA_10: 1.51633,
        QC_10: 1214.068,
        VC_10: 1.51633,
        QE_10: 4457.289,
        VE_10: 2.57127,
        QA_11: 3143.425,
        VA_11: 2.31025,
        QC_11: 3143.425,
        VC_11: 2.31025,
        QE_11: 4519.882,
        VE_11: 2.58809,
        QA_12: 5942.52,
        VA_12: 3.1157,
        QC_12: 5942.52,
        VC_12: 3.1157,
        QE_12: 5651.228,
        VE_12: 2.8752,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87443845157334, 34.775093612688806, 0.0],
          [-80.874673984906337, 34.773026012692014, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288494.0,
        COMID: 9736302,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.371,
        REACHCODE: "03050103001812",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0033496025471840001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059912.0,
        ToNode: 250059913.0,
        Hydroseq: 250006172.0,
        LevelPathI: 250003258.0,
        Pathlength: 417.828,
        TerminalPa: 250002604.0,
        ArbolateSu: 6404.495,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006192.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006153.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1125,
        TotDASqKM: 8704.2744,
        DivDASqKM: 8704.2744,
        Tidal: 0,
        TOTMA: 0.011098225823500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14116.0,
        MAXELEVSMO: 14209.0,
        MINELEVSMO: 14209.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.37099999189376798,
        QA_MA: 4422.418,
        VA_MA: 1.26391,
        QC_MA: 4422.418,
        VC_MA: 1.26391,
        QE_MA: 4745.459,
        VE_MA: 1.26938,
        QA_01: 7975.842,
        VA_01: 1.61527,
        QC_01: 7975.842,
        VC_01: 1.61527,
        QE_01: 6685.316,
        VE_01: 1.46119,
        QA_02: 8444.065,
        VA_02: 1.65547,
        QC_02: 8444.065,
        VC_02: 1.65547,
        QE_02: 6853.782,
        VE_02: 1.47653,
        QA_03: 9294.188,
        VA_03: 1.72586,
        QC_03: 9294.188,
        VC_03: 1.72586,
        QE_03: 7009.714,
        VE_03: 1.49057,
        QA_04: 6504.733,
        VA_04: 1.48118,
        QC_04: 6504.733,
        VC_04: 1.48118,
        QE_04: 5853.719,
        VE_04: 1.38268,
        QA_05: 4511.628,
        VA_05: 1.2741,
        QC_05: 4511.628,
        VC_05: 1.2741,
        QE_05: 4810.854,
        VE_05: 1.27639,
        QA_06: 2650.423,
        VA_06: 1.03646,
        QC_06: 2650.423,
        VC_06: 1.03646,
        QE_06: 4415.107,
        VE_06: 1.23326,
        QA_07: 1627.485,
        VA_07: 0.87035,
        QC_07: 1627.485,
        VC_07: 0.87035,
        QE_07: 3372.448,
        VE_07: 1.10999,
        QA_08: 1155.525,
        VA_08: 0.77691,
        QC_08: 1155.525,
        VC_08: 0.77691,
        QE_08: 3292.461,
        VE_08: 1.09983,
        QA_09: 1062.936,
        VA_09: 0.75659,
        QC_09: 1062.936,
        VC_09: 0.75659,
        QE_09: 2733.396,
        VE_09: 1.02537,
        QA_10: 1171.299,
        VA_10: 0.7803,
        QC_10: 1171.299,
        VC_10: 0.7803,
        QE_10: 3583.33,
        VE_10: 1.13622,
        QA_11: 3071.192,
        VA_11: 1.09572,
        QC_11: 3071.192,
        VC_11: 1.09572,
        QE_11: 3834.383,
        VE_11: 1.16653,
        QA_12: 5629.786,
        VA_12: 1.39458,
        QC_12: 5629.786,
        VC_12: 1.39458,
        QE_12: 4846.643,
        VE_12: 1.28021,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865340718254174, 34.92418301245732, 0.0],
          [-80.865480384920602, 34.920942079129077, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288487.0,
        COMID: 9736552,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.404,
        REACHCODE: "03050103001809",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0041329198959799999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060028.0,
        ToNode: 250060031.0,
        Hydroseq: 250005140.0,
        LevelPathI: 250003258.0,
        Pathlength: 387.213,
        TerminalPa: 250002604.0,
        ArbolateSu: 7513.288,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005151.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005129.0,
        FromMeas: 0.0,
        ToMeas: 73.08913,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.5607,
        TotDASqKM: 9817.4754,
        DivDASqKM: 9817.4754,
        Tidal: 0,
        TOTMA: 0.011805010541500001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12640.0,
        MAXELEVSMO: 12649.0,
        MINELEVSMO: 12649.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.404000014066696,
        QA_MA: 4868.89,
        VA_MA: 1.28878,
        QC_MA: 4868.89,
        VC_MA: 1.28878,
        QE_MA: 5570.924,
        VE_MA: 1.29953,
        QA_01: 8952.191,
        VA_01: 1.6631,
        QC_01: 8952.191,
        VC_01: 1.6631,
        QE_01: 7515.934,
        VE_01: 1.47036,
        QA_02: 9457.987,
        VA_02: 1.70322,
        QC_02: 9457.987,
        VC_02: 1.70322,
        QE_02: 7645.931,
        VE_02: 1.48099,
        QA_03: 10378.863,
        VA_03: 1.77376,
        QC_03: 10378.863,
        VC_03: 1.77376,
        QE_03: 8141.809,
        VE_03: 1.52078,
        QA_04: 7131.07,
        VA_04: 1.50899,
        QC_04: 7131.07,
        VC_04: 1.50899,
        QE_04: 6909.512,
        VE_04: 1.4196,
        QA_05: 4861.532,
        VA_05: 1.28799,
        QC_05: 4861.532,
        VC_05: 1.28799,
        QE_05: 5847.144,
        VE_05: 1.32533,
        QA_06: 2871.911,
        VA_06: 1.04912,
        QC_06: 2871.911,
        VC_06: 1.04912,
        QE_06: 5111.71,
        VE_06: 1.25528,
        QA_07: 1791.089,
        VA_07: 0.88486,
        QC_07: 1791.089,
        VC_07: 0.88486,
        QE_07: 3883.459,
        VE_07: 1.12671,
        QA_08: 1275.262,
        VA_08: 0.78972,
        QC_08: 1275.262,
        VC_08: 0.78972,
        QE_08: 3614.146,
        VE_08: 1.09607,
        QA_09: 1159.361,
        VA_09: 0.76597,
        QC_09: 1159.361,
        VC_09: 0.76597,
        QE_09: 3304.603,
        VE_09: 1.0595,
        QA_10: 1246.58,
        VA_10: 0.78394,
        QC_10: 1246.58,
        VC_10: 0.78394,
        QE_10: 4491.294,
        VE_10: 1.1924,
        QA_11: 3176.529,
        VA_11: 1.08986,
        QC_11: 3176.529,
        VC_11: 1.08986,
        QE_11: 4553.761,
        VE_11: 1.19891,
        QA_12: 6157.639,
        VA_12: 1.41897,
        QC_12: 6157.639,
        VC_12: 1.41897,
        QE_12: 5865.926,
        VE_12: 1.32706,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.86831058491623, 34.690160812820693, 0.0],
          [-80.871767184910823, 34.68789521282423, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288488.0,
        COMID: 9736450,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.182,
        REACHCODE: "03050103001810",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001715058537506,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059982.0,
        ToNode: 250059988.0,
        Hydroseq: 250005433.0,
        LevelPathI: 250003258.0,
        Pathlength: 397.319,
        TerminalPa: 250002604.0,
        ArbolateSu: 6996.167,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005466.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005423.0,
        FromMeas: 87.34548,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0702,
        TotDASqKM: 9335.7126,
        DivDASqKM: 9335.7126,
        Tidal: 0,
        TOTMA: 0.0027090599132000002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12733.0,
        MAXELEVSMO: 12766.0,
        MINELEVSMO: 12733.0,
        SLOPE: 0.00181318,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.181999996304512,
        QA_MA: 4681.062,
        VA_MA: 2.52584,
        QC_MA: 4681.062,
        VC_MA: 2.52584,
        QE_MA: 5382.883,
        VE_MA: 2.55108,
        QA_01: 8537.016,
        VA_01: 3.35927,
        QC_01: 8537.016,
        VC_01: 3.35927,
        QE_01: 7101.669,
        VE_01: 2.90659,
        QA_02: 9025.657,
        VA_02: 3.45078,
        QC_02: 9025.657,
        VC_02: 3.45078,
        QE_02: 7214.543,
        VE_02: 2.92843,
        QA_03: 9916.274,
        VA_03: 3.6118,
        QC_03: 9916.274,
        VC_03: 3.6118,
        QE_03: 7680.65,
        VE_03: 3.017,
        QA_04: 6867.926,
        VA_04: 3.02643,
        QC_04: 6867.926,
        VC_04: 3.02643,
        QE_04: 6646.717,
        VE_04: 2.81683,
        QA_05: 4714.209,
        VA_05: 2.53417,
        QC_05: 4714.209,
        VC_05: 2.53417,
        QE_05: 5699.8,
        VE_05: 2.62025,
        QA_06: 2777.771,
        VA_06: 1.98915,
        QC_06: 2777.771,
        VC_06: 1.98915,
        QE_06: 5016.675,
        VE_06: 2.46872,
        QA_07: 1720.855,
        VA_07: 1.6118,
        QC_07: 1720.855,
        VC_07: 1.6118,
        QE_07: 3812.416,
        VE_07: 2.1757,
        QA_08: 1223.443,
        VA_08: 1.39584,
        QC_08: 1223.443,
        VC_08: 1.39584,
        QE_08: 3561.208,
        VE_08: 2.10932,
        QA_09: 1120.391,
        VA_09: 1.3462,
        QC_09: 1120.391,
        VC_09: 1.3462,
        QE_09: 3264.727,
        VE_09: 2.02809,
        QA_10: 1214.193,
        VA_10: 1.39147,
        QC_10: 1214.193,
        VC_10: 1.39147,
        QE_10: 4457.42,
        VE_10: 2.3373,
        QA_11: 3143.55,
        VA_11: 2.10325,
        QC_11: 3143.55,
        VC_11: 2.10325,
        QE_11: 4520.011,
        VE_11: 2.35238,
        QA_12: 5943.363,
        VA_12: 2.82556,
        QC_12: 5943.363,
        VC_12: 2.82556,
        QE_12: 5652.07,
        VE_12: 2.60995,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875322584905291, 34.768900279365141, 0.0],
          [-80.87443538490669, 34.767476612700591, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287575.0,
        COMID: 9736186,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.102,
        REACHCODE: "03050103000722",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00094565426524499999,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059859.0,
        ToNode: 250058965.0,
        Hydroseq: 250006945.0,
        LevelPathI: 250006921.0,
        Pathlength: 432.786,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.286,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006966.0,
        DnLevelPat: 250006921.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006921.0,
        FromMeas: 63.18739,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 7886.0718,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.059033894474299999,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 15091.0,
        MINELEVSMO: 14982.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.10199999809265101,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.96775511809517, 34.983889879031381, 0.0],
          [-80.967581118095438, 34.984733079030093, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288489.0,
        COMID: 9736464,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.255,
        REACHCODE: "03050103001810",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011649236780469001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059988.0,
        ToNode: 250059989.0,
        Hydroseq: 250005423.0,
        LevelPathI: 250003258.0,
        Pathlength: 396.064,
        TerminalPa: 250002604.0,
        ArbolateSu: 6997.422,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005433.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005413.0,
        FromMeas: 0.0,
        ToMeas: 87.34548,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.1006,
        TotDASqKM: 9337.8132,
        DivDASqKM: 9337.8132,
        Tidal: 0,
        TOTMA: 0.0369581757543,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12672.0,
        MAXELEVSMO: 12733.0,
        MINELEVSMO: 12732.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.254999995231628,
        QA_MA: 4681.888,
        VA_MA: 1.27842,
        QC_MA: 4681.888,
        VC_MA: 1.27842,
        QE_MA: 5383.71,
        VE_MA: 1.28945,
        QA_01: 8538.824,
        VA_01: 1.64302,
        QC_01: 8538.824,
        VC_01: 1.64302,
        QE_01: 7103.474,
        VE_01: 1.44503,
        QA_02: 9027.549,
        VA_02: 1.68306,
        QC_02: 9027.549,
        VC_02: 1.68306,
        QE_02: 7216.431,
        VE_02: 1.45459,
        QA_03: 9918.308,
        VA_03: 1.75349,
        QC_03: 9918.308,
        VC_03: 1.75349,
        QE_03: 7682.677,
        VE_03: 1.49334,
        QA_04: 6869.106,
        VA_04: 1.4974,
        QC_04: 6869.106,
        VC_04: 1.4974,
        QE_04: 6647.896,
        VE_04: 1.40572,
        QA_05: 4714.858,
        VA_05: 1.28204,
        QC_05: 4714.858,
        VC_05: 1.28204,
        QE_05: 5700.449,
        VE_05: 1.31969,
        QA_06: 2778.194,
        VA_06: 1.04363,
        QC_06: 2778.194,
        VC_06: 1.04363,
        QE_06: 5017.102,
        VE_06: 1.25339,
        QA_07: 1721.165,
        VA_07: 0.87857,
        QC_07: 1721.165,
        VC_07: 0.87857,
        QE_07: 3812.73,
        VE_07: 1.12522,
        QA_08: 1223.669,
        VA_08: 0.7841,
        QC_08: 1223.669,
        VC_08: 0.7841,
        QE_08: 3561.439,
        VE_08: 1.09617,
        QA_09: 1120.561,
        VA_09: 0.76237,
        QC_09: 1120.561,
        VC_09: 0.76237,
        QE_09: 3264.9,
        VE_09: 1.06063,
        QA_10: 1214.335,
        VA_10: 0.78217,
        QC_10: 1214.335,
        VC_10: 0.78217,
        QE_10: 4457.567,
        VE_10: 1.19588,
        QA_11: 3143.691,
        VA_11: 1.0935,
        QC_11: 3143.691,
        VC_11: 1.0935,
        QE_11: 4520.155,
        VE_11: 1.20248,
        QA_12: 5944.296,
        VA_12: 1.40952,
        QC_12: 5944.296,
        VC_12: 1.40952,
        QE_12: 5653.002,
        VE_12: 1.31521,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87443538490669, 34.767476612700591, 0.0],
          [-80.87470651823952, 34.762708279374692, 0.0],
          [-80.878305118234039, 34.757201279383196, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288490.0,
        COMID: 9736440,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.158,
        REACHCODE: "03050103001811",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0014610655947299999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059977.0,
        ToNode: 250059978.0,
        Hydroseq: 250005547.0,
        LevelPathI: 250003258.0,
        Pathlength: 398.374,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.358,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005564.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005535.0,
        FromMeas: 84.65313,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0369,
        TotDASqKM: 9332.8758,
        DivDASqKM: 9332.8758,
        Tidal: 0,
        TOTMA: 0.0020105238143600002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13070.0,
        MAXELEVSMO: 13157.0,
        MINELEVSMO: 13070.0,
        SLOPE: 0.00550632,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158000007271767,
        QA_MA: 4679.944,
        VA_MA: 2.95403,
        QC_MA: 4679.944,
        VC_MA: 2.95403,
        QE_MA: 5381.764,
        VE_MA: 2.98414,
        QA_01: 8534.564,
        VA_01: 3.9483,
        QC_01: 8534.564,
        VC_01: 3.9483,
        QE_01: 7099.223,
        VE_01: 3.40808,
        QA_02: 9023.101,
        VA_02: 4.05749,
        QC_02: 9023.101,
        VC_02: 4.05749,
        QE_02: 7211.993,
        VE_02: 3.43413,
        QA_03: 9913.528,
        VA_03: 4.2496,
        QC_03: 9913.528,
        VC_03: 4.2496,
        QE_03: 7677.912,
        VE_03: 3.53978,
        QA_04: 6866.328,
        VA_04: 3.55129,
        QC_04: 6866.328,
        VC_04: 3.55129,
        QE_04: 6645.121,
        VE_04: 3.30117,
        QA_05: 4713.332,
        VA_05: 2.96404,
        QC_05: 4713.332,
        VC_05: 2.96404,
        QE_05: 5698.922,
        VE_05: 3.06675,
        QA_06: 2777.199,
        VA_06: 2.31373,
        QC_06: 2777.199,
        VC_06: 2.31373,
        QE_06: 5016.098,
        VE_06: 2.88601,
        QA_07: 1720.435,
        VA_07: 1.86349,
        QC_07: 1720.435,
        VC_07: 1.86349,
        QE_07: 3811.992,
        VE_07: 2.5364,
        QA_08: 1223.138,
        VA_08: 1.60582,
        QC_08: 1223.138,
        VC_08: 1.60582,
        QE_08: 3560.896,
        VE_08: 2.45723,
        QA_09: 1120.162,
        VA_09: 1.54662,
        QC_09: 1120.162,
        VC_09: 1.54662,
        QE_09: 3264.493,
        VE_09: 2.36032,
        QA_10: 1214.003,
        VA_10: 1.60066,
        QC_10: 1214.003,
        VC_10: 1.60066,
        QE_10: 4457.22,
        VE_10: 2.7293,
        QA_11: 3143.36,
        VA_11: 2.45005,
        QC_11: 3143.36,
        VC_11: 2.45005,
        QE_11: 4519.815,
        VE_11: 2.74729,
        QA_12: 5942.079,
        VA_12: 3.31166,
        QC_12: 5942.079,
        VC_12: 3.31166,
        QE_12: 5650.788,
        VE_12: 3.05436,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875767584904622, 34.777650279351576, 0.0],
          [-80.875277984905324, 34.776339279353579, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287588.0,
        COMID: 9733304,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.242,
        REACHCODE: "03050103000731",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002375841918289,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059008.0,
        ToNode: 250059871.0,
        Hydroseq: 250006771.0,
        LevelPathI: 250006741.0,
        Pathlength: 429.345,
        TerminalPa: 250002604.0,
        ArbolateSu: 5950.718,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250006741.0,
        UpHydroseq: 250006798.0,
        DnLevelPat: 250006741.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006741.0,
        FromMeas: 16.84975,
        ToMeas: 74.40866,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0783,
        TotDASqKM: 7900.911,
        DivDASqKM: 0.0783,
        Tidal: 0,
        TOTMA: 0.024607406183099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14785.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14792.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.24199999868869801,
        QA_MA: 0.032,
        VA_MA: 0.37344,
        QC_MA: 0.032,
        VC_MA: 0.37344,
        QE_MA: 0.032,
        VE_MA: 0.37344,
        QA_01: 0.069,
        VA_01: 0.40622,
        QC_01: 0.069,
        VC_01: 0.40622,
        QE_01: 0.069,
        VE_01: 0.40622,
        QA_02: 0.073,
        VA_02: 0.40919,
        QC_02: 0.073,
        VC_02: 0.40919,
        QE_02: 0.073,
        VE_02: 0.40919,
        QA_03: 0.078,
        VA_03: 0.4128,
        QC_03: 0.078,
        VC_03: 0.4128,
        QE_03: 0.078,
        VE_03: 0.4128,
        QA_04: 0.047,
        VA_04: 0.38817,
        QC_04: 0.047,
        VC_04: 0.38817,
        QE_04: 0.047,
        VE_04: 0.38817,
        QA_05: 0.026,
        VA_05: 0.36665,
        QC_05: 0.026,
        VC_05: 0.36665,
        QE_05: 0.026,
        VE_05: 0.36665,
        QA_06: 0.015,
        VA_06: 0.3519,
        QC_06: 0.015,
        VC_06: 0.3519,
        QE_06: 0.015,
        VE_06: 0.3519,
        QA_07: 0.011,
        VA_07: 0.34529,
        QC_07: 0.011,
        VC_07: 0.34529,
        QE_07: 0.011,
        VE_07: 0.34529,
        QA_08: 0.008,
        VA_08: 0.33955,
        QC_08: 0.008,
        VC_08: 0.33955,
        QE_08: 0.008,
        VE_08: 0.33955,
        QA_09: 0.007,
        VA_09: 0.33742,
        QC_09: 0.007,
        VC_09: 0.33742,
        QE_09: 0.007,
        VE_09: 0.33742,
        QA_10: 0.005,
        VA_10: 0.33267,
        QC_10: 0.005,
        VC_10: 0.33267,
        QE_10: 0.005,
        VE_10: 0.33267,
        QA_11: 0.009,
        VA_11: 0.34156,
        QC_11: 0.009,
        VC_11: 0.34156,
        QE_11: 0.009,
        VE_11: 0.34156,
        QA_12: 0.04,
        VA_12: 0.38163,
        QC_12: 0.04,
        VC_12: 0.38163,
        QE_12: 0.04,
        VE_12: 0.38163,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.958932584775539, 34.9635976790629, 0.0],
          [-80.957289384778107, 34.961949612398769, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287589.0,
        COMID: 9736216,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.071,
        REACHCODE: "03050103000731",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00064701238185199998,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059871.0,
        ToNode: 250059874.0,
        Hydroseq: 250006741.0,
        LevelPathI: 250006741.0,
        Pathlength: 429.274,
        TerminalPa: 250002604.0,
        ArbolateSu: 5950.789,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250006741.0,
        UpHydroseq: 250006771.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006712.0,
        FromMeas: 0.0,
        ToMeas: 16.84975,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 7900.9128,
        DivDASqKM: 0.0801,
        Tidal: 0,
        TOTMA: 0.0072119967269000003,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14792.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14792.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.071000002324580994,
        QA_MA: 0.033,
        VA_MA: 0.37383,
        QC_MA: 0.033,
        VC_MA: 0.37383,
        QE_MA: 0.033,
        VE_MA: 0.37383,
        QA_01: 0.071,
        VA_01: 0.40668,
        QC_01: 0.071,
        VC_01: 0.40668,
        QE_01: 0.071,
        VE_01: 0.40668,
        QA_02: 0.075,
        VA_02: 0.40958,
        QC_02: 0.075,
        VC_02: 0.40958,
        QE_02: 0.075,
        VE_02: 0.40958,
        QA_03: 0.08,
        VA_03: 0.41311,
        QC_03: 0.08,
        VC_03: 0.41311,
        QE_03: 0.08,
        VE_03: 0.41311,
        QA_04: 0.048,
        VA_04: 0.38823,
        QC_04: 0.048,
        VC_04: 0.38823,
        QE_04: 0.048,
        VE_04: 0.38823,
        QA_05: 0.026,
        VA_05: 0.36605,
        QC_05: 0.026,
        VC_05: 0.36605,
        QE_05: 0.026,
        VE_05: 0.36605,
        QA_06: 0.016,
        VA_06: 0.35294,
        QC_06: 0.016,
        VC_06: 0.35294,
        QE_06: 0.016,
        VE_06: 0.35294,
        QA_07: 0.011,
        VA_07: 0.3449,
        QC_07: 0.011,
        VC_07: 0.3449,
        QE_07: 0.011,
        VE_07: 0.3449,
        QA_08: 0.008,
        VA_08: 0.33922,
        QC_08: 0.008,
        VC_08: 0.33922,
        QE_08: 0.008,
        VE_08: 0.33922,
        QA_09: 0.007,
        VA_09: 0.33711,
        QC_09: 0.007,
        VC_09: 0.33711,
        QE_09: 0.007,
        VE_09: 0.33711,
        QA_10: 0.005,
        VA_10: 0.33241,
        QC_10: 0.005,
        VC_10: 0.33241,
        QE_10: 0.005,
        VE_10: 0.33241,
        QA_11: 0.009,
        VA_11: 0.34121,
        QC_11: 0.009,
        VC_11: 0.34121,
        QE_11: 0.009,
        VE_11: 0.34121,
        QA_12: 0.04,
        VA_12: 0.38086,
        QC_12: 0.04,
        VC_12: 0.38086,
        QE_12: 0.04,
        VE_12: 0.38086,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.957289384778107, 34.961949612398769, 0.0],
          [-80.957104384778347, 34.961335012399786, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288495.0,
        COMID: 9736292,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.158,
        REACHCODE: "03050103001813",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0014257068629130001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250059907.0,
        ToNode: 250059912.0,
        Hydroseq: 250006192.0,
        LevelPathI: 250003258.0,
        Pathlength: 418.199,
        TerminalPa: 250002604.0,
        ArbolateSu: 6400.86,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006215.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006172.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0351,
        TotDASqKM: 8701.5924,
        DivDASqKM: 8701.5924,
        Tidal: 0,
        TOTMA: 0.0047266918786300002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 14093.0,
        MAXELEVSMO: 14209.0,
        MINELEVSMO: 14209.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.158000007271767,
        QA_MA: 4421.324,
        VA_MA: 1.26385,
        QC_MA: 4421.324,
        VC_MA: 1.26385,
        QE_MA: 4744.359,
        VE_MA: 1.26932,
        QA_01: 7973.462,
        VA_01: 1.61515,
        QC_01: 7973.462,
        VC_01: 1.61515,
        QE_01: 6682.949,
        VE_01: 1.46104,
        QA_02: 8441.607,
        VA_02: 1.65535,
        QC_02: 8441.607,
        VC_02: 1.65535,
        QE_02: 6851.341,
        VE_02: 1.47638,
        QA_03: 9291.556,
        VA_03: 1.72574,
        QC_03: 9291.556,
        VC_03: 1.72574,
        QE_03: 7007.102,
        VE_03: 1.4904,
        QA_04: 6503.169,
        VA_04: 1.4811,
        QC_04: 6503.169,
        VC_04: 1.4811,
        QE_04: 5852.157,
        VE_04: 1.38259,
        QA_05: 4510.762,
        VA_05: 1.27406,
        QC_05: 4510.762,
        VC_05: 1.27406,
        QE_05: 4809.98,
        VE_05: 1.27635,
        QA_06: 2649.883,
        VA_06: 1.03643,
        QC_06: 2649.883,
        VC_06: 1.03643,
        QE_06: 4414.546,
        VE_06: 1.23325,
        QA_07: 1627.088,
        VA_07: 0.87032,
        QC_07: 1627.088,
        VC_07: 0.87032,
        QE_07: 3372.032,
        VE_07: 1.10998,
        QA_08: 1155.236,
        VA_08: 0.77688,
        QC_08: 1155.236,
        VC_08: 0.77688,
        QE_08: 3292.152,
        VE_08: 1.09984,
        QA_09: 1062.684,
        VA_09: 0.75656,
        QC_09: 1062.684,
        VC_09: 0.75656,
        QE_09: 2733.124,
        VE_09: 1.02537,
        QA_10: 1171.118,
        VA_10: 0.78029,
        QC_10: 1171.118,
        VC_10: 0.78029,
        QE_10: 3583.12,
        VE_10: 1.13625,
        QA_11: 3070.926,
        VA_11: 1.09573,
        QC_11: 3070.926,
        VC_11: 1.09573,
        QE_11: 3834.105,
        VE_11: 1.16655,
        QA_12: 5628.482,
        VA_12: 1.39452,
        QC_12: 5628.482,
        VC_12: 1.39452,
        QE_12: 4845.342,
        VE_12: 1.28013,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.865568584920439, 34.92557061245526, 0.0],
          [-80.865340718254174, 34.92418301245732, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287590.0,
        COMID: 9736210,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.107,
        REACHCODE: "03050103000731",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0011200027106330001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059869.0,
        ToNode: 250059008.0,
        Hydroseq: 250006798.0,
        LevelPathI: 250006741.0,
        Pathlength: 429.587,
        TerminalPa: 250002604.0,
        ArbolateSu: 5950.476,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250006827.0,
        DnLevelPat: 250006741.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006771.0,
        FromMeas: 74.40866,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 7900.8327,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0619277144374,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 14792.0,
        MINELEVSMO: 14675.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.10700000077485999,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.959924984773977, 34.963192879063513, 0.0],
          [-80.958932584775539, 34.9635976790629, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288539.0,
        COMID: 9736806,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.026,
        REACHCODE: "03050103017303",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.019125169815086,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060088.0,
        ToNode: 250060089.0,
        Hydroseq: 250004969.0,
        LevelPathI: 250003258.0,
        Pathlength: 373.352,
        TerminalPa: 250002604.0,
        ArbolateSu: 7922.476,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004983.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250004954.0,
        DnDrainCou: 2,
        DnHydroseq: 250004945.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 3.3021,
        TotDASqKM: 10619.6139,
        DivDASqKM: 10619.6139,
        Tidal: 0,
        TOTMA: 0.50113023465999995,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10785.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.026000022888184,
        QA_MA: 5194.995,
        VA_MA: -9998.0,
        QC_MA: 5194.995,
        VC_MA: -9998.0,
        QE_MA: 5897.383,
        VE_MA: -9998.0,
        QA_01: 9655.73,
        VA_01: -9998.0,
        QC_01: 9655.73,
        VC_01: -9998.0,
        QE_01: 8217.956,
        VE_01: -9998.0,
        QA_02: 10198.159,
        VA_02: -9998.0,
        QC_02: 10198.159,
        VC_02: -9998.0,
        QE_02: 8384.534,
        VE_02: -9998.0,
        QA_03: 11175.779,
        VA_03: -9998.0,
        QC_03: 11175.779,
        VC_03: -9998.0,
        QE_03: 8936.343,
        VE_03: -9998.0,
        QA_04: 7598.364,
        VA_04: -9998.0,
        QC_04: 7598.364,
        VC_04: -9998.0,
        QE_04: 7376.226,
        VE_04: -9998.0,
        QA_05: 5120.945,
        VA_05: -9998.0,
        QC_05: 5120.945,
        VC_05: -9998.0,
        QE_05: 6106.593,
        VE_05: -9998.0,
        QA_06: 3037.125,
        VA_06: -9998.0,
        QC_06: 3037.125,
        VC_06: -9998.0,
        QE_06: 5278.412,
        VE_06: -9998.0,
        QA_07: 1906.151,
        VA_07: -9998.0,
        QC_07: 1906.151,
        VC_07: -9998.0,
        QE_07: 3999.869,
        VE_07: -9998.0,
        QA_08: 1361.542,
        VA_08: -9998.0,
        QC_08: 1361.542,
        VC_08: -9998.0,
        QE_08: 3702.289,
        VE_08: -9998.0,
        QA_09: 1231.383,
        VA_09: -9998.0,
        QC_09: 1231.383,
        VC_09: -9998.0,
        QE_09: 3378.133,
        VE_09: -9998.0,
        QA_10: 1300.578,
        VA_10: -9998.0,
        QC_10: 1300.578,
        VC_10: -9998.0,
        QE_10: 4547.769,
        VE_10: -9998.0,
        QA_11: 3253.168,
        VA_11: -9998.0,
        QC_11: 3253.168,
        VC_11: -9998.0,
        QE_11: 4631.683,
        VE_11: -9998.0,
        QA_12: 6536.747,
        VA_12: -9998.0,
        QC_12: 6536.747,
        VC_12: -9998.0,
        QE_12: 6244.333,
        VE_12: -9998.0,
        LakeFract: 0.11997818222,
        SurfArea: 1477020.5635,
        RAreaHLoad: 0.10236884116,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.895023984874683, 34.594149079636395, 0.0],
          [-80.891038318214271, 34.588535412978338, 0.0],
          [-80.891332918213834, 34.584498679651347, 0.0],
          [-80.889459718216699, 34.580450812990989, 0.0],
          [-80.886826584887444, 34.578460079660772, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288540.0,
        COMID: 9736626,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.223,
        REACHCODE: "03050103017304",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0021405521300129999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060063.0,
        ToNode: 250060065.0,
        Hydroseq: 250019187.0,
        LevelPathI: 250019187.0,
        Pathlength: 370.369,
        TerminalPa: 250002604.0,
        ArbolateSu: 262.283,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250020199.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004786.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0477,
        TotDASqKM: 517.9887,
        DivDASqKM: 517.9887,
        Tidal: 0,
        TOTMA: 0.05515895514,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8612.0,
        MAXELEVSMO: 8617.0,
        MINELEVSMO: 8612.0,
        SLOPE: 0.00022421,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.22300000488758101,
        QA_MA: 199.049,
        VA_MA: -9998.0,
        QC_MA: 196.296,
        VC_MA: -9998.0,
        QE_MA: 191.252,
        VE_MA: -9998.0,
        QA_01: 435.697,
        VA_01: -9998.0,
        QC_01: 336.947,
        VC_01: -9998.0,
        QE_01: 383.626,
        VE_01: -9998.0,
        QA_02: 465.218,
        VA_02: -9998.0,
        QC_02: 383.372,
        VC_02: -9998.0,
        QE_02: 358.542,
        VE_02: -9998.0,
        QA_03: 500.047,
        VA_03: -9998.0,
        QC_03: 414.738,
        VC_03: -9998.0,
        QE_03: 407.223,
        VE_03: -9998.0,
        QA_04: 285.818,
        VA_04: -9998.0,
        QC_04: 263.027,
        VC_04: -9998.0,
        QE_04: 234.55,
        VE_04: -9998.0,
        QA_05: 158.347,
        VA_05: -9998.0,
        QC_05: 148.786,
        VC_05: -9998.0,
        QE_05: 118.436,
        VE_05: -9998.0,
        QA_06: 102.234,
        VA_06: -9998.0,
        QC_06: 128.027,
        VC_06: -9998.0,
        QE_06: 109.115,
        VE_06: -9998.0,
        QA_07: 69.645,
        VA_07: -9998.0,
        QC_07: 104.959,
        VC_07: -9998.0,
        QE_07: 85.823,
        VE_07: -9998.0,
        QA_08: 55.716,
        VA_08: -9998.0,
        QC_08: 125.265,
        VC_08: -9998.0,
        QE_08: 77.836,
        VE_08: -9998.0,
        QA_09: 42.91,
        VA_09: -9998.0,
        QC_09: 108.079,
        VC_09: -9998.0,
        QE_09: 96.721,
        VE_09: -9998.0,
        QA_10: 34.822,
        VA_10: -9998.0,
        QC_10: 93.768,
        VC_10: -9998.0,
        QE_10: 163.782,
        VE_10: -9998.0,
        QA_11: 32.132,
        VA_11: -9998.0,
        QC_11: 53.909,
        VC_11: -9998.0,
        QE_11: 120.249,
        VE_11: -9998.0,
        QA_12: 207.491,
        VA_12: -9998.0,
        QC_12: 169.543,
        VC_12: -9998.0,
        QE_12: 171.293,
        VE_12: -9998.0,
        LakeFract: 0.00042819509,
        SurfArea: 5271.3996946500001,
        RAreaHLoad: 0.01126764646,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.896947318205093, 34.55684081302752, 0.0],
          [-80.895607184873825, 34.555171679696798, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288542.0,
        COMID: 9736622,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.037,
        REACHCODE: "03050103017306",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00038940595894500002,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060061.0,
        ToNode: 250060063.0,
        Hydroseq: 250019511.0,
        LevelPathI: 250019511.0,
        Pathlength: 370.592,
        TerminalPa: 250002604.0,
        ArbolateSu: 261.701,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019511.0,
        UpHydroseq: 250019854.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019187.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 517.6332,
        DivDASqKM: 0.0873,
        Tidal: 0,
        TOTMA: 0.0091491968,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8617.0,
        MINELEVSMO: 8617.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.037000000476836999,
        QA_MA: 0.028,
        VA_MA: -9998.0,
        QC_MA: 0.028,
        VC_MA: -9998.0,
        QE_MA: 0.028,
        VE_MA: -9998.0,
        QA_01: 0.061,
        VA_01: -9998.0,
        QC_01: 0.061,
        VC_01: -9998.0,
        QE_01: 0.061,
        VE_01: -9998.0,
        QA_02: 0.069,
        VA_02: -9998.0,
        QC_02: 0.069,
        VC_02: -9998.0,
        QE_02: 0.069,
        VE_02: -9998.0,
        QA_03: 0.076,
        VA_03: -9998.0,
        QC_03: 0.076,
        VC_03: -9998.0,
        QE_03: 0.076,
        VE_03: -9998.0,
        QA_04: 0.039,
        VA_04: -9998.0,
        QC_04: 0.039,
        VC_04: -9998.0,
        QE_04: 0.039,
        VE_04: -9998.0,
        QA_05: 0.022,
        VA_05: -9998.0,
        QC_05: 0.022,
        VC_05: -9998.0,
        QE_05: 0.022,
        VE_05: -9998.0,
        QA_06: 0.015,
        VA_06: -9998.0,
        QC_06: 0.015,
        VC_06: -9998.0,
        QE_06: 0.015,
        VE_06: -9998.0,
        QA_07: 0.011,
        VA_07: -9998.0,
        QC_07: 0.011,
        VC_07: -9998.0,
        QE_07: 0.011,
        VE_07: -9998.0,
        QA_08: 0.009,
        VA_08: -9998.0,
        QC_08: 0.009,
        VC_08: -9998.0,
        QE_08: 0.009,
        VE_08: -9998.0,
        QA_09: 0.007,
        VA_09: -9998.0,
        QC_09: 0.007,
        VC_09: -9998.0,
        QE_09: 0.007,
        VE_09: -9998.0,
        QA_10: 0.005,
        VA_10: -9998.0,
        QC_10: 0.005,
        VC_10: -9998.0,
        QE_10: 0.005,
        VE_10: -9998.0,
        QA_11: 0.004,
        VA_11: -9998.0,
        QC_11: 0.004,
        VC_11: -9998.0,
        QE_11: 0.004,
        VE_11: -9998.0,
        QA_12: 0.019,
        VA_12: -9998.0,
        QC_12: 0.019,
        VC_12: -9998.0,
        QE_12: 0.019,
        VE_12: -9998.0,
        LakeFract: 1.04e-8,
        SurfArea: 0.1280317269,
        RAreaHLoad: 0.00186896062,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.897310718204494, 34.556809213027577, 0.0],
          [-80.896947318205093, 34.55684081302752, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288535.0,
        COMID: 9740458,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.234,
        REACHCODE: "03050103017299",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0022380511691840002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060145.0,
        ToNode: 250060070.0,
        Hydroseq: 250004260.0,
        LevelPathI: 250003258.0,
        Pathlength: 367.415,
        TerminalPa: 250002604.0,
        ArbolateSu: 8342.973,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004764.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004252.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2448,
        TotDASqKM: 11273.5458,
        DivDASqKM: 11273.5458,
        Tidal: 0,
        TOTMA: 0.05787980004,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 6805.0,
        MAXELEVSMO: 8603.0,
        MINELEVSMO: 6880.0,
        SLOPE: 0.07363247,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.23399999737739599,
        QA_MA: 5442.051,
        VA_MA: -9998.0,
        QC_MA: 5442.051,
        VC_MA: -9998.0,
        QE_MA: 6139.684,
        VE_MA: -9998.0,
        QA_01: 10196.532,
        VA_01: -9998.0,
        QC_01: 10196.532,
        VC_01: -9998.0,
        QE_01: 8804.202,
        VE_01: -9998.0,
        QA_02: 10776.814,
        VA_02: -9998.0,
        QC_02: 10776.814,
        VC_02: -9998.0,
        QE_02: 8937.081,
        VE_02: -9998.0,
        QA_03: 11799.467,
        VA_03: -9998.0,
        QC_03: 11799.467,
        VC_03: -9998.0,
        QE_03: 9550.575,
        VE_03: -9998.0,
        QA_04: 7951.463,
        VA_04: -9998.0,
        QC_04: 7951.463,
        VC_04: -9998.0,
        QE_04: 7700.375,
        VE_04: -9998.0,
        QA_05: 5316.989,
        VA_05: -9998.0,
        QC_05: 5316.989,
        VC_05: -9998.0,
        QE_05: 6272.316,
        VE_05: -9998.0,
        QA_06: 3164.147,
        VA_06: -9998.0,
        QC_06: 3164.147,
        VC_06: -9998.0,
        QE_06: 5387.736,
        VE_06: -9998.0,
        QA_07: 1994.479,
        VA_07: -9998.0,
        QC_07: 1994.479,
        VC_07: -9998.0,
        QE_07: 4070.159,
        VE_07: -9998.0,
        QA_08: 1431.88,
        VA_08: -9998.0,
        QC_08: 1431.88,
        VC_08: -9998.0,
        QE_08: 3726.717,
        VE_08: -9998.0,
        QA_09: 1285.26,
        VA_09: -9998.0,
        QC_09: 1285.26,
        VC_09: -9998.0,
        QE_09: 3421.881,
        VE_09: -9998.0,
        QA_10: 1344.54,
        VA_10: -9998.0,
        QC_10: 1344.54,
        VC_10: -9998.0,
        QE_10: 4663.763,
        VE_10: -9998.0,
        QA_11: 3292.64,
        VA_11: -9998.0,
        QC_11: 3292.64,
        VC_11: -9998.0,
        QE_11: 4738.542,
        VE_11: -9998.0,
        QA_12: 6787.945,
        VA_12: -9998.0,
        QC_12: 6787.945,
        VC_12: -9998.0,
        QE_12: 6496.71,
        VE_12: -9998.0,
        LakeFract: 0.0144288737,
        SurfArea: 177630.155489,
        RAreaHLoad: 0.01182344957,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.878041318234352, 34.543495279714932, 0.0],
          [-80.876756584903092, 34.54173527971767, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288548.0,
        COMID: 9736600,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 4.733,
        REACHCODE: "03050103017312",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.044247811550068003,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060051.0,
        ToNode: 250060053.0,
        Hydroseq: 250005085.0,
        LevelPathI: 250003258.0,
        Pathlength: 378.152,
        TerminalPa: 250002604.0,
        ArbolateSu: 7537.3,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005095.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005074.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 9.8874,
        TotDASqKM: 9848.6838,
        DivDASqKM: 9848.6838,
        Tidal: 0,
        TOTMA: 1.1707055284500001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12636.0,
        MAXELEVSMO: 12642.0,
        MINELEVSMO: 12642.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 4.7329998016357422,
        QA_MA: 4880.305,
        VA_MA: -9998.0,
        QC_MA: 4880.305,
        VC_MA: -9998.0,
        QE_MA: 5582.353,
        VE_MA: -9998.0,
        QA_01: 8977.156,
        VA_01: -9998.0,
        QC_01: 8977.156,
        VC_01: -9998.0,
        QE_01: 7540.84,
        VE_01: -9998.0,
        QA_02: 9484.793,
        VA_02: -9998.0,
        QC_02: 9484.793,
        VC_02: -9998.0,
        QE_02: 7672.676,
        VE_02: -9998.0,
        QA_03: 10407.948,
        VA_03: -9998.0,
        QC_03: 10407.948,
        VC_03: -9998.0,
        QE_03: 8170.8,
        VE_03: -9998.0,
        QA_04: 7147.195,
        VA_04: -9998.0,
        QC_04: 7147.195,
        VC_04: -9998.0,
        QE_04: 6925.615,
        VE_04: -9998.0,
        QA_05: 4870.506,
        VA_05: -9998.0,
        QC_05: 4870.506,
        VC_05: -9998.0,
        QE_05: 5856.12,
        VE_05: -9998.0,
        QA_06: 2877.74,
        VA_06: -9998.0,
        QC_06: 2877.74,
        VC_06: -9998.0,
        QE_06: 5117.596,
        VE_06: -9998.0,
        QA_07: 1795.285,
        VA_07: -9998.0,
        QC_07: 1795.285,
        VC_07: -9998.0,
        QE_07: 3887.708,
        VE_07: -9998.0,
        QA_08: 1278.619,
        VA_08: -9998.0,
        QC_08: 1278.619,
        VC_08: -9998.0,
        QE_08: 3617.576,
        VE_08: -9998.0,
        QA_09: 1161.878,
        VA_09: -9998.0,
        QC_09: 1161.878,
        VC_09: -9998.0,
        QE_09: 3307.179,
        VE_09: -9998.0,
        QA_10: 1248.678,
        VA_10: -9998.0,
        QC_10: 1248.678,
        VC_10: -9998.0,
        QE_10: 4493.489,
        VE_10: -9998.0,
        QA_11: 3178.207,
        VA_11: -9998.0,
        QC_11: 3178.207,
        VC_11: -9998.0,
        QE_11: 4555.489,
        VE_11: -9998.0,
        QA_12: 6169.069,
        VA_12: -9998.0,
        QC_12: 6169.069,
        VC_12: -9998.0,
        QE_12: 5877.328,
        VE_12: -9998.0,
        LakeFract: 0.26531225032,
        SurfArea: 3266190.9208800001,
        RAreaHLoad: 0.23914695222,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88758371821956, 34.655186079541579, 0.0],
          [-80.888765384884437, 34.65004287954963, 0.0],
          [-80.894514784875525, 34.642914279560614, 0.0],
          [-80.897600118204082, 34.63149927957835, 0.0],
          [-80.890952318214318, 34.623705279590354, 0.0],
          [-80.888219584885292, 34.617236412933835, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288549.0,
        COMID: 9736582,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.512,
        REACHCODE: "03050103017313",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.013720001313564999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060042.0,
        ToNode: 250060051.0,
        Hydroseq: 250005095.0,
        LevelPathI: 250003258.0,
        Pathlength: 382.885,
        TerminalPa: 250002604.0,
        ArbolateSu: 7526.196,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005106.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005085.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.8432,
        TotDASqKM: 9832.3173,
        DivDASqKM: 9832.3173,
        Tidal: 0,
        TOTMA: 0.3739925542,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12639.0,
        MAXELEVSMO: 12642.0,
        MINELEVSMO: 12642.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.5119999647140501,
        QA_MA: 4874.441,
        VA_MA: -9998.0,
        QC_MA: 4874.441,
        VC_MA: -9998.0,
        QE_MA: 5576.481,
        VE_MA: -9998.0,
        QA_01: 8964.367,
        VA_01: -9998.0,
        QC_01: 8964.367,
        VC_01: -9998.0,
        QE_01: 7528.082,
        VE_01: -9998.0,
        QA_02: 9470.957,
        VA_02: -9998.0,
        QC_02: 9470.957,
        VC_02: -9998.0,
        QE_02: 7658.872,
        VE_02: -9998.0,
        QA_03: 10392.884,
        VA_03: -9998.0,
        QC_03: 10392.884,
        VC_03: -9998.0,
        QE_03: 8155.785,
        VE_03: -9998.0,
        QA_04: 7138.931,
        VA_04: -9998.0,
        QC_04: 7138.931,
        VC_04: -9998.0,
        QE_04: 6917.363,
        VE_04: -9998.0,
        QA_05: 4865.904,
        VA_05: -9998.0,
        QC_05: 4865.904,
        VC_05: -9998.0,
        QE_05: 5851.517,
        VE_05: -9998.0,
        QA_06: 2874.705,
        VA_06: -9998.0,
        QC_06: 2874.705,
        VC_06: -9998.0,
        QE_06: 5114.531,
        VE_06: -9998.0,
        QA_07: 1793.085,
        VA_07: -9998.0,
        QC_07: 1793.085,
        VC_07: -9998.0,
        QE_07: 3885.48,
        VE_07: -9998.0,
        QA_08: 1276.859,
        VA_08: -9998.0,
        QC_08: 1276.859,
        VC_08: -9998.0,
        QE_08: 3615.777,
        VE_08: -9998.0,
        QA_09: 1160.558,
        VA_09: -9998.0,
        QC_09: 1160.558,
        VC_09: -9998.0,
        QE_09: 3305.828,
        VE_09: -9998.0,
        QA_10: 1247.578,
        VA_10: -9998.0,
        QC_10: 1247.578,
        VC_10: -9998.0,
        QE_10: 4492.338,
        VE_10: -9998.0,
        QA_11: 3177.327,
        VA_11: -9998.0,
        QC_11: 3177.327,
        VC_11: -9998.0,
        QE_11: 4554.583,
        VE_11: -9998.0,
        QA_12: 6163.489,
        VA_12: -9998.0,
        QC_12: 6163.489,
        VC_12: -9998.0,
        QE_12: 5871.762,
        VE_12: -9998.0,
        LakeFract: 0.08466726307,
        SurfArea: 1042316.91376,
        RAreaHLoad: 0.07639767415,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888007918218932, 34.668280812854562, 0.0],
          [-80.88758371821956, 34.655186079541579, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288543.0,
        COMID: 9736620,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.359,
        REACHCODE: "03050103017307",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0036580454247590001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060060.0,
        ToNode: 250060063.0,
        Hydroseq: 250020199.0,
        LevelPathI: 250019187.0,
        Pathlength: 370.592,
        TerminalPa: 250002604.0,
        ArbolateSu: 261.588,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250020555.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019187.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3078,
        TotDASqKM: 517.8537,
        DivDASqKM: 517.8537,
        Tidal: 0,
        TOTMA: 0.08879849693,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8617.0,
        MAXELEVSMO: 8617.0,
        MINELEVSMO: 8617.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.35899999737739602,
        QA_MA: 199.004,
        VA_MA: -9998.0,
        QC_MA: 196.254,
        VC_MA: -9998.0,
        QE_MA: 191.21,
        VE_MA: -9998.0,
        QA_01: 435.601,
        VA_01: -9998.0,
        QC_01: 336.875,
        VC_01: -9998.0,
        QE_01: 383.554,
        VE_01: -9998.0,
        QA_02: 465.111,
        VA_02: -9998.0,
        QC_02: 383.286,
        VC_02: -9998.0,
        QE_02: 358.456,
        VE_02: -9998.0,
        QA_03: 499.929,
        VA_03: -9998.0,
        QC_03: 414.643,
        VC_03: -9998.0,
        QE_03: 407.129,
        VE_03: -9998.0,
        QA_04: 285.756,
        VA_04: -9998.0,
        QC_04: 262.971,
        VC_04: -9998.0,
        QE_04: 234.494,
        VE_04: -9998.0,
        QA_05: 158.313,
        VA_05: -9998.0,
        QC_05: 148.755,
        VC_05: -9998.0,
        QE_05: 118.405,
        VE_05: -9998.0,
        QA_06: 102.21,
        VA_06: -9998.0,
        QC_06: 127.998,
        VC_06: -9998.0,
        QE_06: 109.086,
        VE_06: -9998.0,
        QA_07: 69.626,
        VA_07: -9998.0,
        QC_07: 104.932,
        VC_07: -9998.0,
        QE_07: 85.796,
        VE_07: -9998.0,
        QA_08: 55.701,
        VA_08: -9998.0,
        QC_08: 125.232,
        VC_08: -9998.0,
        QE_08: 77.803,
        VE_08: -9998.0,
        QA_09: 42.899,
        VA_09: -9998.0,
        QC_09: 108.055,
        VC_09: -9998.0,
        QE_09: 96.697,
        VE_09: -9998.0,
        QA_10: 34.813,
        VA_10: -9998.0,
        QC_10: 93.748,
        VC_10: -9998.0,
        QE_10: 163.763,
        VE_10: -9998.0,
        QA_11: 32.124,
        VA_11: -9998.0,
        QC_11: 53.9,
        VC_11: -9998.0,
        QE_11: 120.239,
        VE_11: -9998.0,
        QA_12: 207.46,
        VA_12: -9998.0,
        QC_12: 169.52,
        VC_12: -9998.0,
        QE_12: 171.27,
        VE_12: -9998.0,
        LakeFract: 0.00068918508,
        SurfArea: 8484.3803796699995,
        RAreaHLoad: 0.01813939491,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.899914318200445, 34.558722079691393, 0.0],
          [-80.896947318205093, 34.55684081302752, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287574.0,
        COMID: 9733184,
        FDATE: "2001/06/18 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.174,
        REACHCODE: "03050103000722",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001880831273294,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250058965.0,
        ToNode: 250058969.0,
        Hydroseq: 250006921.0,
        LevelPathI: 250006921.0,
        Pathlength: 432.612,
        TerminalPa: 250002604.0,
        ArbolateSu: 5936.46,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250006921.0,
        UpHydroseq: 250006945.0,
        DnLevelPat: 250006875.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250006900.0,
        FromMeas: 0.0,
        ToMeas: 63.18739,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0405,
        TotDASqKM: 7886.1123,
        DivDASqKM: 0.0405,
        Tidal: 0,
        TOTMA: 0.0181308573407,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 15142.0,
        MAXELEVSMO: 15142.0,
        MINELEVSMO: 15142.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.17399999499321001,
        QA_MA: 0.017,
        VA_MA: 0.36442,
        QC_MA: 0.017,
        VC_MA: 0.36442,
        QE_MA: 0.017,
        VE_MA: 0.36442,
        QA_01: 0.036,
        VA_01: 0.39185,
        QC_01: 0.036,
        VC_01: 0.39185,
        QE_01: 0.036,
        VE_01: 0.39185,
        QA_02: 0.038,
        VA_02: 0.39428,
        QC_02: 0.038,
        VC_02: 0.39428,
        QE_02: 0.038,
        VE_02: 0.39428,
        QA_03: 0.041,
        VA_03: 0.39781,
        QC_03: 0.041,
        VC_03: 0.39781,
        QE_03: 0.041,
        VE_03: 0.39781,
        QA_04: 0.024,
        VA_04: 0.37568,
        QC_04: 0.024,
        VC_04: 0.37568,
        QE_04: 0.024,
        VE_04: 0.37568,
        QA_05: 0.013,
        VA_05: 0.35698,
        QC_05: 0.013,
        VC_05: 0.35698,
        QE_05: 0.013,
        VE_05: 0.35698,
        QA_06: 0.008,
        VA_06: 0.34594,
        QC_06: 0.008,
        VC_06: 0.34594,
        QE_06: 0.008,
        VE_06: 0.34594,
        QA_07: 0.005,
        VA_07: 0.33765,
        QC_07: 0.005,
        VC_07: 0.33765,
        QE_07: 0.005,
        VE_07: 0.33765,
        QA_08: 0.004,
        VA_08: 0.33438,
        QC_08: 0.004,
        VC_08: 0.33438,
        QE_08: 0.004,
        VE_08: 0.33438,
        QA_09: 0.003,
        VA_09: 0.3307,
        QC_09: 0.003,
        VC_09: 0.3307,
        QE_09: 0.003,
        VE_09: 0.3307,
        QA_10: 0.002,
        VA_10: 0.32638,
        QC_10: 0.002,
        VC_10: 0.32638,
        QE_10: 0.002,
        VE_10: 0.32638,
        QA_11: 0.005,
        VA_11: 0.33765,
        QC_11: 0.005,
        VC_11: 0.33765,
        QE_11: 0.005,
        VE_11: 0.33765,
        QA_12: 0.021,
        VA_12: 0.37107,
        QC_12: 0.021,
        VC_12: 0.37107,
        QE_12: 0.021,
        VE_12: 0.37107,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.967581118095438, 34.984733079030093, 0.0],
          [-80.965772584764863, 34.985169812362813, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288544.0,
        COMID: 9736618,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.019,
        REACHCODE: "03050103017308",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.018894824974471001,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060059.0,
        ToNode: 250060062.0,
        Hydroseq: 250004822.0,
        LevelPathI: 250003258.0,
        Pathlength: 370.975,
        TerminalPa: 250002604.0,
        ArbolateSu: 7925.589,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004910.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004806.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 2.142,
        TotDASqKM: 10621.8963,
        DivDASqKM: 10621.854,
        Tidal: 0,
        TOTMA: 0.49939878763000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 9533.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 9533.0,
        SLOPE: 0.00721149,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.0190000534057622,
        QA_MA: 5195.731,
        VA_MA: -9998.0,
        QC_MA: 5195.731,
        VC_MA: -9998.0,
        QE_MA: 5898.12,
        VE_MA: -9998.0,
        QA_01: 9657.326,
        VA_01: -9998.0,
        QC_01: 9657.326,
        VC_01: -9998.0,
        QE_01: 8219.548,
        VE_01: -9998.0,
        QA_02: 10199.936,
        VA_02: -9998.0,
        QC_02: 10199.936,
        VC_02: -9998.0,
        QE_02: 8386.307,
        VE_02: -9998.0,
        QA_03: 11177.737,
        VA_03: -9998.0,
        QC_03: 11177.737,
        VC_03: -9998.0,
        QE_03: 8938.294,
        VE_03: -9998.0,
        QA_04: 7599.388,
        VA_04: -9998.0,
        QC_04: 7599.388,
        VC_04: -9998.0,
        QE_04: 7377.248,
        VE_04: -9998.0,
        QA_05: 5121.517,
        VA_05: -9998.0,
        QC_05: 5121.517,
        VC_05: -9998.0,
        QE_05: 6107.165,
        VE_05: -9998.0,
        QA_06: 3037.516,
        VA_06: -9998.0,
        QC_06: 3037.516,
        VC_06: -9998.0,
        QE_06: 5278.808,
        VE_06: -9998.0,
        QA_07: 1906.452,
        VA_07: -9998.0,
        QC_07: 1906.452,
        VC_07: -9998.0,
        QE_07: 4000.174,
        VE_07: -9998.0,
        QA_08: 1361.783,
        VA_08: -9998.0,
        QC_08: 1361.783,
        VC_08: -9998.0,
        QE_08: 3702.535,
        VE_08: -9998.0,
        QA_09: 1231.564,
        VA_09: -9998.0,
        QC_09: 1231.564,
        VC_09: -9998.0,
        QE_09: 3378.318,
        VE_09: -9998.0,
        QA_10: 1300.729,
        VA_10: -9998.0,
        QC_10: 1300.729,
        VC_10: -9998.0,
        QE_10: 4547.926,
        VE_10: -9998.0,
        QA_11: 3253.288,
        VA_11: -9998.0,
        QC_11: 3253.288,
        VC_11: -9998.0,
        QE_11: 4631.807,
        VE_11: -9998.0,
        QA_12: 6537.274,
        VA_12: -9998.0,
        QC_12: 6537.274,
        VC_12: -9998.0,
        QE_12: 6244.858,
        VE_12: -9998.0,
        LakeFract: 0.11957858948,
        SurfArea: 1472101.2799800001,
        RAreaHLoad: 0.10201514822,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.885625651555983, 34.575421279665306, 0.0],
          [-80.884751518223879, 34.570463279673106, 0.0],
          [-80.886797718220805, 34.565160879681287, 0.0],
          [-80.891648184879955, 34.559331746356975, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288545.0,
        COMID: 9736612,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.364,
        REACHCODE: "03050103017309",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0033333302760910001,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060056.0,
        ToNode: 250060088.0,
        Hydroseq: 250015946.0,
        LevelPathI: 250015946.0,
        Pathlength: 375.378,
        TerminalPa: 250002604.0,
        ArbolateSu: 369.173,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250015946.0,
        UpHydroseq: 250016110.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004969.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.3294,
        TotDASqKM: 749.0529,
        DivDASqKM: 749.0529,
        Tidal: 0,
        TOTMA: 0.09003524458000001,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10801.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.36399999260902399,
        QA_MA: 307.181,
        VA_MA: -9998.0,
        QC_MA: 297.876,
        VC_MA: -9998.0,
        QE_MA: 297.876,
        VE_MA: -9998.0,
        QA_01: 662.29,
        VA_01: -9998.0,
        QC_01: 506.593,
        VC_01: -9998.0,
        QE_01: 506.593,
        VE_01: -9998.0,
        QA_02: 695.466,
        VA_02: -9998.0,
        QC_02: 566.865,
        VC_02: -9998.0,
        QE_02: 566.865,
        VE_02: -9998.0,
        QA_03: 748.209,
        VA_03: -9998.0,
        QC_03: 612.362,
        VC_03: -9998.0,
        QE_03: 612.362,
        VE_03: -9998.0,
        QA_04: 440.7,
        VA_04: -9998.0,
        QC_04: 401.586,
        VC_04: -9998.0,
        QE_04: 401.586,
        VE_04: -9998.0,
        QA_05: 244.571,
        VA_05: -9998.0,
        QC_05: 226.096,
        VC_05: -9998.0,
        QE_05: 226.096,
        VE_05: -9998.0,
        QA_06: 155.499,
        VA_06: -9998.0,
        QC_06: 193.613,
        VC_06: -9998.0,
        QE_06: 193.613,
        VE_06: -9998.0,
        QA_07: 107.925,
        VA_07: -9998.0,
        QC_07: 160.954,
        VC_07: -9998.0,
        QE_07: 160.954,
        VE_07: -9998.0,
        QA_08: 80.569,
        VA_08: -9998.0,
        QC_08: 182.486,
        VC_08: -9998.0,
        QE_08: 182.486,
        VE_08: -9998.0,
        QA_09: 67.74,
        VA_09: -9998.0,
        QC_09: 161.737,
        VC_09: -9998.0,
        QE_09: 161.737,
        VE_09: -9998.0,
        QA_10: 50.429,
        VA_10: -9998.0,
        QC_10: 126.045,
        VC_10: -9998.0,
        QE_10: 126.045,
        VE_10: -9998.0,
        QA_11: 73.784,
        VA_11: -9998.0,
        QC_11: 101.623,
        VC_11: -9998.0,
        QE_11: 101.623,
        VE_11: -9998.0,
        QA_12: 361.329,
        VA_12: -9998.0,
        QC_12: 280.842,
        VC_12: -9998.0,
        QE_12: 280.842,
        VE_12: -9998.0,
        LakeFract: 0.00108118527,
        SurfArea: 13310.1939635,
        RAreaHLoad: 0.01839203268,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.895603118207191, 34.597216612964928, 0.0],
          [-80.895023984874683, 34.594149079636395, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288546.0,
        COMID: 9736610,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.29,
        REACHCODE: "03050103017310",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0029127597879600002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060055.0,
        ToNode: 250060088.0,
        Hydroseq: 250004983.0,
        LevelPathI: 250003258.0,
        Pathlength: 375.378,
        TerminalPa: 250002604.0,
        ArbolateSu: 7551.277,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005029.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004969.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0558,
        TotDASqKM: 9867.2589,
        DivDASqKM: 9867.2589,
        Tidal: 0,
        TOTMA: 0.07173137614,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10805.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.28999999165535001,
        QA_MA: 4886.721,
        VA_MA: -9998.0,
        QC_MA: 4886.721,
        VC_MA: -9998.0,
        QE_MA: 5588.777,
        VE_MA: -9998.0,
        QA_01: 8991.087,
        VA_01: -9998.0,
        QC_01: 8991.087,
        VC_01: -9998.0,
        QE_01: 7554.736,
        VE_01: -9998.0,
        QA_02: 9500.073,
        VA_02: -9998.0,
        QC_02: 9500.073,
        VC_02: -9998.0,
        QE_02: 7687.92,
        VE_02: -9998.0,
        QA_03: 10424.661,
        VA_03: -9998.0,
        QC_03: 10424.661,
        VC_03: -9998.0,
        QE_03: 8187.459,
        VE_03: -9998.0,
        QA_04: 7156.155,
        VA_04: -9998.0,
        QC_04: 7156.155,
        VC_04: -9998.0,
        QE_04: 6934.561,
        VE_04: -9998.0,
        QA_05: 4875.53,
        VA_05: -9998.0,
        QC_05: 4875.53,
        VC_05: -9998.0,
        QE_05: 5861.144,
        VE_05: -9998.0,
        QA_06: 2881.048,
        VA_06: -9998.0,
        QC_06: 2881.048,
        VC_06: -9998.0,
        QE_06: 5120.939,
        VE_06: -9998.0,
        QA_07: 1797.782,
        VA_07: -9998.0,
        QC_07: 1797.782,
        VC_07: -9998.0,
        QE_07: 3890.236,
        VE_07: -9998.0,
        QA_08: 1280.617,
        VA_08: -9998.0,
        QC_08: 1280.617,
        VC_08: -9998.0,
        QE_08: 3619.617,
        VE_08: -9998.0,
        QA_09: 1163.377,
        VA_09: -9998.0,
        QC_09: 1163.377,
        VC_09: -9998.0,
        QE_09: 3308.712,
        VE_09: -9998.0,
        QA_10: 1249.927,
        VA_10: -9998.0,
        QC_10: 1249.927,
        VC_10: -9998.0,
        QE_10: 4494.795,
        VE_10: -9998.0,
        QA_11: 3179.206,
        VA_11: -9998.0,
        QC_11: 3179.206,
        VC_11: -9998.0,
        QE_11: 4556.518,
        VE_11: -9998.0,
        QA_12: 6174.58,
        VA_12: -9998.0,
        QC_12: 6174.58,
        VC_12: -9998.0,
        QE_12: 5882.823,
        VE_12: -9998.0,
        LakeFract: 0.01627489825,
        SurfArea: 200356.08924299999,
        RAreaHLoad: 0.01465299306,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.892741918211584, 34.595959146300231, 0.0],
          [-80.895023984874683, 34.594149079636395, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288525.0,
        COMID: 9736604,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 1.847,
        REACHCODE: "03050103017289",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.016606013078065002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060053.0,
        ToNode: 250060086.0,
        Hydroseq: 250005074.0,
        LevelPathI: 250003258.0,
        Pathlength: 376.305,
        TerminalPa: 250002604.0,
        ArbolateSu: 7549.94,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005085.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005063.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 6.5871,
        TotDASqKM: 9866.88,
        DivDASqKM: 9866.88,
        Tidal: 0,
        TOTMA: 0.4568546611,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10790.0,
        MAXELEVSMO: 12642.0,
        MINELEVSMO: 10989.0,
        SLOPE: 0.00894964,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.8470000028610229,
        QA_MA: 4886.594,
        VA_MA: -9998.0,
        QC_MA: 4886.594,
        VC_MA: -9998.0,
        QE_MA: 5588.65,
        VE_MA: -9998.0,
        QA_01: 8990.815,
        VA_01: -9998.0,
        QC_01: 8990.815,
        VC_01: -9998.0,
        QE_01: 7554.464,
        VE_01: -9998.0,
        QA_02: 9499.772,
        VA_02: -9998.0,
        QC_02: 9499.772,
        VC_02: -9998.0,
        QE_02: 7687.62,
        VE_02: -9998.0,
        QA_03: 10424.325,
        VA_03: -9998.0,
        QC_03: 10424.325,
        VC_03: -9998.0,
        QE_03: 8187.124,
        VE_03: -9998.0,
        QA_04: 7155.982,
        VA_04: -9998.0,
        QC_04: 7155.982,
        VC_04: -9998.0,
        QE_04: 6934.388,
        VE_04: -9998.0,
        QA_05: 4875.433,
        VA_05: -9998.0,
        QC_05: 4875.433,
        VC_05: -9998.0,
        QE_05: 5861.048,
        VE_05: -9998.0,
        QA_06: 2880.982,
        VA_06: -9998.0,
        QC_06: 2880.982,
        VC_06: -9998.0,
        QE_06: 5120.872,
        VE_06: -9998.0,
        QA_07: 1797.732,
        VA_07: -9998.0,
        QC_07: 1797.732,
        VC_07: -9998.0,
        QE_07: 3890.185,
        VE_07: -9998.0,
        QA_08: 1280.576,
        VA_08: -9998.0,
        QC_08: 1280.576,
        VC_08: -9998.0,
        QE_08: 3619.575,
        VE_08: -9998.0,
        QA_09: 1163.346,
        VA_09: -9998.0,
        QC_09: 1163.346,
        VC_09: -9998.0,
        QE_09: 3308.681,
        VE_09: -9998.0,
        QA_10: 1249.902,
        VA_10: -9998.0,
        QC_10: 1249.902,
        VC_10: -9998.0,
        QE_10: 4494.768,
        VE_10: -9998.0,
        QA_11: 3179.186,
        VA_11: -9998.0,
        QC_11: 3179.186,
        VC_11: -9998.0,
        QE_11: 4556.497,
        VE_11: -9998.0,
        QA_12: 6174.483,
        VA_12: -9998.0,
        QC_12: 6174.483,
        VC_12: -9998.0,
        QE_12: 5882.727,
        VE_12: -9998.0,
        LakeFract: 0.10365191029,
        SurfArea: 1276032.0260900001,
        RAreaHLoad: 0.09332440751,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.888219584885292, 34.617236412933835, 0.0],
          [-80.890154118215605, 34.600905746292426, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288522.0,
        COMID: 9736574,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.189,
        REACHCODE: "03050103017286",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001942442656269,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060038.0,
        ToNode: 250060042.0,
        Hydroseq: 250005106.0,
        LevelPathI: 250003258.0,
        Pathlength: 384.397,
        TerminalPa: 250002604.0,
        ArbolateSu: 7524.684,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005119.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005095.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.9908,
        TotDASqKM: 9830.4741,
        DivDASqKM: 9830.4741,
        Tidal: 0,
        TOTMA: 0.04674906929,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12641.0,
        MAXELEVSMO: 12642.0,
        MINELEVSMO: 12642.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18899999558925601,
        QA_MA: 4873.765,
        VA_MA: -9998.0,
        QC_MA: 4873.765,
        VC_MA: -9998.0,
        QE_MA: 5575.805,
        VE_MA: -9998.0,
        QA_01: 8962.894,
        VA_01: -9998.0,
        QC_01: 8962.894,
        VC_01: -9998.0,
        QE_01: 7526.612,
        VE_01: -9998.0,
        QA_02: 9469.374,
        VA_02: -9998.0,
        QC_02: 9469.374,
        VC_02: -9998.0,
        QE_02: 7657.293,
        VE_02: -9998.0,
        QA_03: 10391.165,
        VA_03: -9998.0,
        QC_03: 10391.165,
        VC_03: -9998.0,
        QE_03: 8154.072,
        VE_03: -9998.0,
        QA_04: 7137.981,
        VA_04: -9998.0,
        QC_04: 7137.981,
        VC_04: -9998.0,
        QE_04: 6916.414,
        VE_04: -9998.0,
        QA_05: 4865.375,
        VA_05: -9998.0,
        QC_05: 4865.375,
        VC_05: -9998.0,
        QE_05: 5850.988,
        VE_05: -9998.0,
        QA_06: 2874.358,
        VA_06: -9998.0,
        QC_06: 2874.358,
        VC_06: -9998.0,
        QE_06: 5114.181,
        VE_06: -9998.0,
        QA_07: 1792.837,
        VA_07: -9998.0,
        QC_07: 1792.837,
        VC_07: -9998.0,
        QE_07: 3885.229,
        VE_07: -9998.0,
        QA_08: 1276.661,
        VA_08: -9998.0,
        QC_08: 1276.661,
        VC_08: -9998.0,
        QE_08: 3615.575,
        VE_08: -9998.0,
        QA_09: 1160.409,
        VA_09: -9998.0,
        QC_09: 1160.409,
        VC_09: -9998.0,
        QE_09: 3305.676,
        VE_09: -9998.0,
        QA_10: 1247.454,
        VA_10: -9998.0,
        QC_10: 1247.454,
        VC_10: -9998.0,
        QE_10: 4492.208,
        VE_10: -9998.0,
        QA_11: 3177.228,
        VA_11: -9998.0,
        QC_11: 3177.228,
        VC_11: -9998.0,
        QE_11: 4554.48,
        VE_11: -9998.0,
        QA_12: 6162.811,
        VA_12: -9998.0,
        QC_12: 6162.811,
        VC_12: -9998.0,
        QE_12: 5871.086,
        VE_12: -9998.0,
        LakeFract: 0.01058212493,
        SurfArea: 130273.820106,
        RAreaHLoad: 0.00954970927,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.886362718221449, 34.669313479519644, 0.0],
          [-80.888007918218932, 34.668280812854562, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288531.0,
        COMID: 9736636,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.523,
        REACHCODE: "03050103017295",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0057199273989029997,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060067.0,
        ToNode: 250060145.0,
        Hydroseq: 250004764.0,
        LevelPathI: 250003258.0,
        Pathlength: 367.649,
        TerminalPa: 250002604.0,
        ArbolateSu: 8202.595,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004786.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004260.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.2061,
        TotDASqKM: 11154.9519,
        DivDASqKM: 11154.9096,
        Tidal: 0,
        TOTMA: 0.1293638266,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8603.0,
        MAXELEVSMO: 8603.0,
        MINELEVSMO: 8603.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.52300000190734897,
        QA_MA: 5399.765,
        VA_MA: -9998.0,
        QC_MA: 5399.765,
        VC_MA: -9998.0,
        QE_MA: 6097.346,
        VE_MA: -9998.0,
        QA_01: 10103.827,
        VA_01: -9998.0,
        QC_01: 10103.827,
        VC_01: -9998.0,
        QE_01: 8711.722,
        VE_01: -9998.0,
        QA_02: 10677.173,
        VA_02: -9998.0,
        QC_02: 10677.173,
        VC_02: -9998.0,
        QE_02: 8837.672,
        VE_02: -9998.0,
        QA_03: 11691.042,
        VA_03: -9998.0,
        QC_03: 11691.042,
        VC_03: -9998.0,
        QE_03: 9442.502,
        VE_03: -9998.0,
        QA_04: 7892.133,
        VA_04: -9998.0,
        QC_04: 7892.133,
        VC_04: -9998.0,
        QE_04: 7641.13,
        VE_04: -9998.0,
        QA_05: 5283.734,
        VA_05: -9998.0,
        QC_05: 5283.734,
        VC_05: -9998.0,
        QE_05: 6239.055,
        VE_05: -9998.0,
        QA_06: 3142.384,
        VA_06: -9998.0,
        QC_06: 3142.384,
        VC_06: -9998.0,
        QE_06: 5365.752,
        VE_06: -9998.0,
        QA_07: 1978.123,
        VA_07: -9998.0,
        QC_07: 1978.123,
        VC_07: -9998.0,
        QE_07: 4053.604,
        VE_07: -9998.0,
        QA_08: 1419.119,
        VA_08: -9998.0,
        QC_08: 1419.119,
        VC_08: -9998.0,
        QE_08: 3713.681,
        VE_08: -9998.0,
        QA_09: 1275.689,
        VA_09: -9998.0,
        QC_09: 1275.689,
        VC_09: -9998.0,
        QE_09: 3412.087,
        VE_09: -9998.0,
        QA_10: 1336.564,
        VA_10: -9998.0,
        QC_10: 1336.564,
        VC_10: -9998.0,
        QE_10: 4655.421,
        VE_10: -9998.0,
        QA_11: 3286.23,
        VA_11: -9998.0,
        QC_11: 3286.23,
        VC_11: -9998.0,
        QE_11: 4731.942,
        VE_11: -9998.0,
        QA_12: 6748.434,
        VA_12: -9998.0,
        QC_12: 6748.434,
        VC_12: -9998.0,
        QE_12: 6457.303,
        VE_12: -9998.0,
        LakeFract: 0.03202680016,
        SurfArea: 394273.70496900001,
        RAreaHLoad: 0.02642591506,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883704384892269, 34.542750279716188, 0.0],
          [-80.878041318234352, 34.543495279714932, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288533.0,
        COMID: 9736632,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 2.197,
        REACHCODE: "03050103017297",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.021624373347094002,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060065.0,
        ToNode: 250060067.0,
        Hydroseq: 250004786.0,
        LevelPathI: 250003258.0,
        Pathlength: 368.172,
        TerminalPa: 250002604.0,
        ArbolateSu: 8192.919,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250004806.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004764.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.7901,
        TotDASqKM: 11143.4796,
        DivDASqKM: 11143.4373,
        Tidal: 0,
        TOTMA: 0.54342701163999996,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8599.0,
        MAXELEVSMO: 8612.0,
        MINELEVSMO: 8603.0,
        SLOPE: 4.096e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 2.1970000267028809,
        QA_MA: 5395.961,
        VA_MA: -9998.0,
        QC_MA: 5395.961,
        VC_MA: -9998.0,
        QE_MA: 6093.537,
        VE_MA: -9998.0,
        QA_01: 10095.584,
        VA_01: -9998.0,
        QC_01: 10095.584,
        VC_01: -9998.0,
        QE_01: 8703.5,
        VE_01: -9998.0,
        QA_02: 10668.005,
        VA_02: -9998.0,
        QC_02: 10668.005,
        VC_02: -9998.0,
        QE_02: 8828.527,
        VE_02: -9998.0,
        QA_03: 11680.925,
        VA_03: -9998.0,
        QC_03: 11680.925,
        VC_03: -9998.0,
        QE_03: 9432.419,
        VE_03: -9998.0,
        QA_04: 7886.85,
        VA_04: -9998.0,
        QC_04: 7886.85,
        VC_04: -9998.0,
        QE_04: 7635.855,
        VE_04: -9998.0,
        QA_05: 5280.783,
        VA_05: -9998.0,
        QC_05: 5280.783,
        VC_05: -9998.0,
        QE_05: 6236.104,
        VE_05: -9998.0,
        QA_06: 3140.378,
        VA_06: -9998.0,
        QC_06: 3140.378,
        VC_06: -9998.0,
        QE_06: 5363.726,
        VE_06: -9998.0,
        QA_07: 1976.58,
        VA_07: -9998.0,
        QC_07: 1976.58,
        VC_07: -9998.0,
        QE_07: 4052.042,
        VE_07: -9998.0,
        QA_08: 1417.885,
        VA_08: -9998.0,
        QC_08: 1417.885,
        VC_08: -9998.0,
        QE_08: 3712.42,
        VE_08: -9998.0,
        QA_09: 1274.764,
        VA_09: -9998.0,
        QC_09: 1274.764,
        VC_09: -9998.0,
        QE_09: 3411.14,
        VE_09: -9998.0,
        QA_10: 1335.793,
        VA_10: -9998.0,
        QC_10: 1335.793,
        VC_10: -9998.0,
        QE_10: 4654.615,
        VE_10: -9998.0,
        QA_11: 3285.613,
        VA_11: -9998.0,
        QC_11: 3285.613,
        VC_11: -9998.0,
        QE_11: 4731.306,
        VE_11: -9998.0,
        QA_12: 6745.618,
        VA_12: -9998.0,
        QC_12: 6745.618,
        VC_12: -9998.0,
        QE_12: 6454.496,
        VE_12: -9998.0,
        LakeFract: 0.13445302341000001,
        SurfArea: 1655216.6129399999,
        RAreaHLoad: 0.11100905431,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.895607184873825, 34.555171679696798, 0.0],
          [-80.891625984879965, 34.551663613035601, 0.0],
          [-80.88656311822109, 34.551953479701922, 0.0],
          [-80.887040384887086, 34.546989613042854, 0.0],
          [-80.883704384892269, 34.542750279716188, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288527.0,
        COMID: 9736802,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: "1010097",
        GNIS_NAME: "Catawba River",
        LENGTHKM: 0.302,
        REACHCODE: "03050103017291",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0027081719258799999,
        StreamLeve: 2,
        StreamOrde: 6,
        StreamCalc: 6,
        FromNode: 250060086.0,
        ToNode: 250060054.0,
        Hydroseq: 250005063.0,
        LevelPathI: 250003258.0,
        Pathlength: 376.003,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.242,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005074.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 4,
        DnHydroseq: 250005051.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1953,
        TotDASqKM: 9867.0753,
        DivDASqKM: 9867.0753,
        Tidal: 0,
        TOTMA: 0.07469957103,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10790.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.30199998617172202,
        QA_MA: 4886.659,
        VA_MA: -9998.0,
        QC_MA: 4886.659,
        VC_MA: -9998.0,
        QE_MA: 5588.715,
        VE_MA: -9998.0,
        QA_01: 8990.954,
        VA_01: -9998.0,
        QC_01: 8990.954,
        VC_01: -9998.0,
        QE_01: 7554.603,
        VE_01: -9998.0,
        QA_02: 9499.927,
        VA_02: -9998.0,
        QC_02: 9499.927,
        VC_02: -9998.0,
        QE_02: 7687.774,
        VE_02: -9998.0,
        QA_03: 10424.498,
        VA_03: -9998.0,
        QC_03: 10424.498,
        VC_03: -9998.0,
        QE_03: 8187.297,
        VE_03: -9998.0,
        QA_04: 7156.071,
        VA_04: -9998.0,
        QC_04: 7156.071,
        VC_04: -9998.0,
        QE_04: 6934.477,
        VE_04: -9998.0,
        QA_05: 4875.483,
        VA_05: -9998.0,
        QC_05: 4875.483,
        VC_05: -9998.0,
        QE_05: 5861.098,
        VE_05: -9998.0,
        QA_06: 2881.016,
        VA_06: -9998.0,
        QC_06: 2881.016,
        VC_06: -9998.0,
        QE_06: 5120.907,
        VE_06: -9998.0,
        QA_07: 1797.758,
        VA_07: -9998.0,
        QC_07: 1797.758,
        VC_07: -9998.0,
        QE_07: 3890.211,
        VE_07: -9998.0,
        QA_08: 1280.597,
        VA_08: -9998.0,
        QC_08: 1280.597,
        VC_08: -9998.0,
        QE_08: 3619.597,
        VE_08: -9998.0,
        QA_09: 1163.362,
        VA_09: -9998.0,
        QC_09: 1163.362,
        VC_09: -9998.0,
        QE_09: 3308.697,
        VE_09: -9998.0,
        QA_10: 1249.915,
        VA_10: -9998.0,
        QC_10: 1249.915,
        VC_10: -9998.0,
        QE_10: 4494.782,
        VE_10: -9998.0,
        QA_11: 3179.197,
        VA_11: -9998.0,
        QC_11: 3179.197,
        VC_11: -9998.0,
        QE_11: 4556.507,
        VE_11: -9998.0,
        QA_12: 6174.533,
        VA_12: -9998.0,
        QC_12: 6174.533,
        VC_12: -9998.0,
        QE_12: 5882.776,
        VE_12: -9998.0,
        LakeFract: 0.0169481543,
        SurfArea: 208644.37142899999,
        RAreaHLoad: 0.01525932381,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.890154118215605, 34.600905746292426, 0.0],
          [-80.890363718215269, 34.598233212963407, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288528.0,
        COMID: 9736800,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 1.084,
        REACHCODE: "03050103017292",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.011392763992657999,
        StreamLeve: 3,
        StreamOrde: 4,
        StreamCalc: 4,
        FromNode: 250060085.0,
        ToNode: 250060060.0,
        Hydroseq: 250020555.0,
        LevelPathI: 250019187.0,
        Pathlength: 370.951,
        TerminalPa: 250002604.0,
        ArbolateSu: 261.229,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250020924.0,
        DnLevelPat: 250019187.0,
        DnMinorHyd: 250020198.0,
        DnDrainCou: 2,
        DnHydroseq: 250020199.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 1.188,
        TotDASqKM: 517.5459,
        DivDASqKM: 517.5459,
        Tidal: 0,
        TOTMA: 0.26812693730999998,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8591.0,
        MAXELEVSMO: 8703.0,
        MINELEVSMO: 8617.0,
        SLOPE: 0.00079335,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 1.0839999914169309,
        QA_MA: 198.903,
        VA_MA: -9998.0,
        QC_MA: 196.158,
        VC_MA: -9998.0,
        QE_MA: 191.114,
        VE_MA: -9998.0,
        QA_01: 435.383,
        VA_01: -9998.0,
        QC_01: 336.711,
        VC_01: -9998.0,
        QE_01: 383.39,
        VE_01: -9998.0,
        QA_02: 464.867,
        VA_02: -9998.0,
        QC_02: 383.09,
        VC_02: -9998.0,
        QE_02: 358.261,
        VE_02: -9998.0,
        QA_03: 499.66,
        VA_03: -9998.0,
        QC_03: 414.428,
        VC_03: -9998.0,
        QE_03: 406.913,
        VE_03: -9998.0,
        QA_04: 285.616,
        VA_04: -9998.0,
        QC_04: 262.845,
        VC_04: -9998.0,
        QE_04: 234.368,
        VE_04: -9998.0,
        QA_05: 158.234,
        VA_05: -9998.0,
        QC_05: 148.683,
        VC_05: -9998.0,
        QE_05: 118.334,
        VE_05: -9998.0,
        QA_06: 102.156,
        VA_06: -9998.0,
        QC_06: 127.931,
        VC_06: -9998.0,
        QE_06: 109.019,
        VE_06: -9998.0,
        QA_07: 69.585,
        VA_07: -9998.0,
        QC_07: 104.871,
        VC_07: -9998.0,
        QE_07: 85.735,
        VE_07: -9998.0,
        QA_08: 55.668,
        VA_08: -9998.0,
        QC_08: 125.156,
        VC_08: -9998.0,
        QE_08: 77.727,
        VE_08: -9998.0,
        QA_09: 42.874,
        VA_09: -9998.0,
        QC_09: 108.0,
        VC_09: -9998.0,
        QE_09: 96.642,
        VE_09: -9998.0,
        QA_10: 34.793,
        VA_10: -9998.0,
        QC_10: 93.704,
        VC_10: -9998.0,
        QE_10: 163.718,
        VE_10: -9998.0,
        QA_11: 32.108,
        VA_11: -9998.0,
        QC_11: 53.878,
        VC_11: -9998.0,
        QE_11: 120.218,
        VE_11: -9998.0,
        QA_12: 207.389,
        VA_12: -9998.0,
        QC_12: 169.468,
        VC_12: -9998.0,
        QE_12: 171.217,
        VE_12: -9998.0,
        LakeFract: 0.0020799593,
        SurfArea: 25605.844333500001,
        RAreaHLoad: 0.05477187756,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.910047118184764, 34.557287013026951, 0.0],
          [-80.905719984858081, 34.556224479695231, 0.0],
          [-80.90344571819503, 34.558458613025152, 0.0],
          [-80.899914318200445, 34.558722079691393, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288529.0,
        COMID: 9736798,
        FDATE: "2009/01/29 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.07,
        REACHCODE: "03050103017293",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00072706449844899999,
        StreamLeve: 4,
        StreamOrde: 4,
        StreamCalc: 0,
        FromNode: 250060060.0,
        ToNode: 250059781.0,
        Hydroseq: 250020198.0,
        LevelPathI: 250019511.0,
        Pathlength: 370.994,
        TerminalPa: 250002604.0,
        ArbolateSu: 261.299,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250019187.0,
        UpHydroseq: 250020555.0,
        DnLevelPat: 250019511.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250019854.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 517.5459,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8617.0,
        MINELEVSMO: 8591.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.070000000298023002,
        QA_MA: 0.0,
        VA_MA: -9998.0,
        QC_MA: 0.0,
        VC_MA: -9998.0,
        QE_MA: 0.0,
        VE_MA: -9998.0,
        QA_01: 0.0,
        VA_01: -9998.0,
        QC_01: 0.0,
        VC_01: -9998.0,
        QE_01: 0.0,
        VE_01: -9998.0,
        QA_02: 0.0,
        VA_02: -9998.0,
        QC_02: 0.0,
        VC_02: -9998.0,
        QE_02: 0.0,
        VE_02: -9998.0,
        QA_03: 0.0,
        VA_03: -9998.0,
        QC_03: 0.0,
        VC_03: -9998.0,
        QE_03: 0.0,
        VE_03: -9998.0,
        QA_04: 0.0,
        VA_04: -9998.0,
        QC_04: 0.0,
        VC_04: -9998.0,
        QE_04: 0.0,
        VE_04: -9998.0,
        QA_05: 0.0,
        VA_05: -9998.0,
        QC_05: 0.0,
        VC_05: -9998.0,
        QE_05: 0.0,
        VE_05: -9998.0,
        QA_06: 0.0,
        VA_06: -9998.0,
        QC_06: 0.0,
        VC_06: -9998.0,
        QE_06: 0.0,
        VE_06: -9998.0,
        QA_07: 0.0,
        VA_07: -9998.0,
        QC_07: 0.0,
        VC_07: -9998.0,
        QE_07: 0.0,
        VE_07: -9998.0,
        QA_08: 0.0,
        VA_08: -9998.0,
        QC_08: 0.0,
        VC_08: -9998.0,
        QE_08: 0.0,
        VE_08: -9998.0,
        QA_09: 0.0,
        VA_09: -9998.0,
        QC_09: 0.0,
        VC_09: -9998.0,
        QE_09: 0.0,
        VE_09: -9998.0,
        QA_10: 0.0,
        VA_10: -9998.0,
        QC_10: 0.0,
        VC_10: -9998.0,
        QE_10: 0.0,
        VE_10: -9998.0,
        QA_11: 0.0,
        VA_11: -9998.0,
        QC_11: 0.0,
        VC_11: -9998.0,
        QE_11: 0.0,
        VE_11: -9998.0,
        QA_12: 0.0,
        VA_12: -9998.0,
        QC_12: 0.0,
        VC_12: -9998.0,
        QE_12: 0.0,
        VE_12: -9998.0,
        LakeFract: -9998.0,
        SurfArea: -9998.0,
        RAreaHLoad: -9998.0,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.899914318200445, 34.558722079691393, 0.0],
          [-80.90055071819944, 34.558389013025248, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287467.0,
        COMID: 9740416,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.148,
        REACHCODE: "03050103000661",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0015094509127700001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060132.0,
        ToNode: 250059976.0,
        Hydroseq: 250005608.0,
        LevelPathI: 250005608.0,
        Pathlength: 398.787,
        TerminalPa: 250002604.0,
        ArbolateSu: 6823.085,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005608.0,
        UpHydroseq: 250005624.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 250005574.0,
        DnDrainCou: 2,
        DnHydroseq: 250005577.0,
        FromMeas: 0.0,
        ToMeas: 46.03565,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0108,
        TotDASqKM: 9191.9115,
        DivDASqKM: 0.6885,
        Tidal: 0,
        TOTMA: 0.0135335873425,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13246.0,
        MAXELEVSMO: 13272.0,
        MINELEVSMO: 13272.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.14800000190734899,
        QA_MA: 0.271,
        VA_MA: 0.41526,
        QC_MA: 0.271,
        VC_MA: 0.41526,
        QE_MA: 0.271,
        VE_MA: 0.41526,
        QA_01: 0.595,
        VA_01: 0.47065,
        QC_01: 0.595,
        VC_01: 0.47065,
        QE_01: 0.595,
        VE_01: 0.47065,
        QA_02: 0.62,
        VA_02: 0.47424,
        QC_02: 0.62,
        VC_02: 0.47424,
        QE_02: 0.62,
        VE_02: 0.47424,
        QA_03: 0.666,
        VA_03: 0.48066,
        QC_03: 0.666,
        VC_03: 0.48066,
        QE_03: 0.666,
        VE_03: 0.48066,
        QA_04: 0.388,
        VA_04: 0.4377,
        QC_04: 0.388,
        VC_04: 0.4377,
        QE_04: 0.388,
        VE_04: 0.4377,
        QA_05: 0.212,
        VA_05: 0.4022,
        QC_05: 0.212,
        VC_05: 0.4022,
        QE_05: 0.212,
        VE_05: 0.4022,
        QA_06: 0.138,
        VA_06: 0.38307,
        QC_06: 0.138,
        VC_06: 0.38307,
        QE_06: 0.138,
        VE_06: 0.38307,
        QA_07: 0.101,
        VA_07: 0.37167,
        QC_07: 0.101,
        VC_07: 0.37167,
        QE_07: 0.101,
        VE_07: 0.37167,
        QA_08: 0.074,
        VA_08: 0.36203,
        QC_08: 0.074,
        VC_08: 0.36203,
        QE_08: 0.074,
        VE_08: 0.36203,
        QA_09: 0.055,
        VA_09: 0.35421,
        QC_09: 0.055,
        VC_09: 0.35421,
        QE_09: 0.055,
        VE_09: 0.35421,
        QA_10: 0.046,
        VA_10: 0.35007,
        QC_10: 0.046,
        VC_10: 0.35007,
        QE_10: 0.046,
        VE_10: 0.35007,
        QA_11: 0.046,
        VA_11: 0.35007,
        QC_11: 0.046,
        VC_11: 0.35007,
        QE_11: 0.046,
        VE_11: 0.35007,
        QA_12: 0.314,
        VA_12: 0.42395,
        QC_12: 0.314,
        VC_12: 0.42395,
        QE_12: 0.314,
        VE_12: 0.42395,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877405584902093, 34.780725012680136, 0.0],
          [-80.876164318237343, 34.779875012681373, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288701.0,
        COMID: 9757384,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.18,
        REACHCODE: "03050103017662",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0016515481262560001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062196.0,
        ToNode: 250094155.0,
        Hydroseq: 250004760.0,
        LevelPathI: 250004736.0,
        Pathlength: 371.758,
        TerminalPa: 250002604.0,
        ArbolateSu: 7925.023,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004782.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004736.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0117,
        TotDASqKM: 10619.9595,
        DivDASqKM: 0.0603,
        Tidal: 0,
        TOTMA: 0.013528389686,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8634.0,
        MAXELEVSMO: 8951.0,
        MINELEVSMO: 8634.0,
        SLOPE: 0.01761111,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.18000000715255701,
        QA_MA: 0.019,
        VA_MA: 0.50524,
        QC_MA: 0.019,
        VC_MA: 0.50524,
        QE_MA: 0.019,
        VE_MA: 0.50524,
        QA_01: 0.042,
        VA_01: 0.60835,
        QC_01: 0.042,
        VC_01: 0.60835,
        QE_01: 0.042,
        VE_01: 0.60835,
        QA_02: 0.047,
        VA_02: 0.62681,
        QC_02: 0.047,
        VC_02: 0.62681,
        QE_02: 0.047,
        VE_02: 0.62681,
        QA_03: 0.052,
        VA_03: 0.64437,
        QC_03: 0.052,
        VC_03: 0.64437,
        QE_03: 0.052,
        VE_03: 0.64437,
        QA_04: 0.027,
        VA_04: 0.54562,
        QC_04: 0.027,
        VC_04: 0.54562,
        QE_04: 0.027,
        VE_04: 0.54562,
        QA_05: 0.015,
        VA_05: 0.48202,
        QC_05: 0.015,
        VC_05: 0.48202,
        QE_05: 0.015,
        VE_05: 0.48202,
        QA_06: 0.01,
        VA_06: 0.44839,
        QC_06: 0.01,
        VC_06: 0.44839,
        QE_06: 0.01,
        VE_06: 0.44839,
        QA_07: 0.008,
        VA_07: 0.43274,
        QC_07: 0.008,
        VC_07: 0.43274,
        QE_07: 0.008,
        VE_07: 0.43274,
        QA_08: 0.006,
        VA_08: 0.41513,
        QC_08: 0.006,
        VC_08: 0.41513,
        QE_08: 0.006,
        VE_08: 0.41513,
        QA_09: 0.004,
        VA_09: 0.39445,
        QC_09: 0.004,
        VC_09: 0.39445,
        QE_09: 0.004,
        VE_09: 0.39445,
        QA_10: 0.004,
        VA_10: 0.39445,
        QC_10: 0.004,
        VC_10: 0.39445,
        QE_10: 0.004,
        VE_10: 0.39445,
        QA_11: 0.003,
        VA_11: 0.38226,
        QC_11: 0.003,
        VC_11: 0.38226,
        QE_11: 0.003,
        VE_11: 0.38226,
        QA_12: 0.014,
        VA_12: 0.47577,
        QC_12: 0.014,
        VC_12: 0.47577,
        QE_12: 0.014,
        VE_12: 0.47577,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882000718228255, 34.572135879670441, 0.0],
          [-80.881335984895998, 34.570624013006238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287468.0,
        COMID: 9734370,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.352,
        REACHCODE: "03050103000662",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0031690059087380001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059360.0,
        ToNode: 250059362.0,
        Hydroseq: 250005638.0,
        LevelPathI: 250005609.0,
        Pathlength: 399.149,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.762,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005609.0,
        UpHydroseq: 250005652.0,
        DnLevelPat: 250005609.0,
        DnMinorHyd: 250005624.0,
        DnDrainCou: 2,
        DnHydroseq: 250005625.0,
        FromMeas: 0.0,
        ToMeas: 82.73874,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1692,
        TotDASqKM: 9191.223,
        DivDASqKM: 0.1692,
        Tidal: 0,
        TOTMA: 0.026445048102200001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13349.0,
        MAXELEVSMO: 13475.0,
        MINELEVSMO: 13349.0,
        SLOPE: 0.00357954,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.35199999809265098,
        QA_MA: 0.066,
        VA_MA: 0.50544,
        QC_MA: 0.066,
        VC_MA: 0.50544,
        QE_MA: 0.066,
        VE_MA: 0.50544,
        QA_01: 0.147,
        VA_01: 0.60986,
        QC_01: 0.147,
        VC_01: 0.60986,
        QE_01: 0.147,
        VE_01: 0.60986,
        QA_02: 0.152,
        VA_02: 0.61526,
        QC_02: 0.152,
        VC_02: 0.61526,
        QE_02: 0.152,
        VE_02: 0.61526,
        QA_03: 0.163,
        VA_03: 0.62686,
        QC_03: 0.163,
        VC_03: 0.62686,
        QE_03: 0.163,
        VE_03: 0.62686,
        QA_04: 0.095,
        VA_04: 0.54748,
        QC_04: 0.095,
        VC_04: 0.54748,
        QE_04: 0.095,
        VE_04: 0.54748,
        QA_05: 0.052,
        VA_05: 0.48201,
        QC_05: 0.052,
        VC_05: 0.48201,
        QE_05: 0.052,
        VE_05: 0.48201,
        QA_06: 0.034,
        VA_06: 0.44694,
        QC_06: 0.034,
        VC_06: 0.44694,
        QE_06: 0.034,
        VE_06: 0.44694,
        QA_07: 0.025,
        VA_07: 0.42607,
        QC_07: 0.025,
        VC_07: 0.42607,
        QE_07: 0.025,
        VE_07: 0.42607,
        QA_08: 0.018,
        VA_08: 0.40723,
        QC_08: 0.018,
        VC_08: 0.40723,
        QE_08: 0.018,
        VE_08: 0.40723,
        QA_09: 0.013,
        VA_09: 0.39155,
        QC_09: 0.013,
        VC_09: 0.39155,
        QE_09: 0.013,
        VE_09: 0.39155,
        QA_10: 0.011,
        VA_10: 0.38449,
        QC_10: 0.011,
        VC_10: 0.38449,
        QE_10: 0.011,
        VE_10: 0.38449,
        QA_11: 0.011,
        VA_11: 0.38449,
        QC_11: 0.011,
        VC_11: 0.38449,
        QE_11: 0.011,
        VE_11: 0.38449,
        QA_12: 0.077,
        VA_12: 0.52224,
        QC_12: 0.077,
        VC_12: 0.52224,
        QE_12: 0.077,
        VE_12: 0.52224,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87779431823482, 34.78532907933959, 0.0],
          [-80.877738584901465, 34.782236812677695, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288702.0,
        COMID: 9757386,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.048,
        REACHCODE: "03050103017663",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000434940613354,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062193.0,
        ToNode: 250062203.0,
        Hydroseq: 250004700.0,
        LevelPathI: 250004700.0,
        Pathlength: 371.752,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.716,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004701.0,
        UpHydroseq: 250004715.0,
        DnLevelPat: 250004668.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004685.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.9523,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0059103372506599996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8656.0,
        SLOPE: 0.040625,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.048000000417232999,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880616118230435, 34.571013613005562, 0.0],
          [-80.880754518230219, 34.570601279672935, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287469.0,
        COMID: 9740408,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.073,
        REACHCODE: "03050103000662",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000722990463859,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060128.0,
        ToNode: 250059360.0,
        Hydroseq: 250005652.0,
        LevelPathI: 250005609.0,
        Pathlength: 399.501,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.41,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005620.0,
        UpHydroseq: 250005668.0,
        DnLevelPat: 250005609.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005638.0,
        FromMeas: 82.73874,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9191.0538,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0422497481753,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13475.0,
        MINELEVSMO: 13475.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.072999998927116005,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877267984902289, 34.785808279338937, 0.0],
          [-80.87779431823482, 34.78532907933959, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288695.0,
        COMID: 9757372,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.1,
        REACHCODE: "03050103017656",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00089326666528699995,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059752.0,
        ToNode: 250062193.0,
        Hydroseq: 250004715.0,
        LevelPathI: 250004701.0,
        Pathlength: 371.822,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.668,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004730.0,
        DnLevelPat: 250004701.0,
        DnMinorHyd: 250004700.0,
        DnDrainCou: 2,
        DnHydroseq: 250004701.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.9523,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.012313202682,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.10000000149011599,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880616118230435, 34.571906879670848, 0.0],
          [-80.880616118230435, 34.571013613005562, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287470.0,
        COMID: 9734374,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.074,
        REACHCODE: "03050103000663",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00079129753541300001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059362.0,
        ToNode: 250060131.0,
        Hydroseq: 250005625.0,
        LevelPathI: 250005609.0,
        Pathlength: 399.075,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.836,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005609.0,
        UpHydroseq: 250005638.0,
        DnLevelPat: 250005609.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005609.0,
        FromMeas: 62.26013,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9191.2266,
        DivDASqKM: 0.1728,
        Tidal: 0,
        TOTMA: 0.0053054503812299996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13281.0,
        MAXELEVSMO: 13349.0,
        MINELEVSMO: 13296.0,
        SLOPE: 0.00716216,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.074000000953673997,
        QA_MA: 0.068,
        VA_MA: 0.52964,
        QC_MA: 0.068,
        VC_MA: 0.52964,
        QE_MA: 0.068,
        VE_MA: 0.52964,
        QA_01: 0.151,
        VA_01: 0.64634,
        QC_01: 0.151,
        VC_01: 0.64634,
        QE_01: 0.151,
        VE_01: 0.64634,
        QA_02: 0.155,
        VA_02: 0.65106,
        QC_02: 0.155,
        VC_02: 0.65106,
        QE_02: 0.155,
        VE_02: 0.65106,
        QA_03: 0.167,
        VA_03: 0.6649,
        QC_03: 0.167,
        VC_03: 0.6649,
        QE_03: 0.167,
        VE_03: 0.6649,
        QA_04: 0.097,
        VA_04: 0.57556,
        QC_04: 0.097,
        VC_04: 0.57556,
        QE_04: 0.097,
        VE_04: 0.57556,
        QA_05: 0.053,
        VA_05: 0.50221,
        QC_05: 0.053,
        VC_05: 0.50221,
        QE_05: 0.053,
        VE_05: 0.50221,
        QA_06: 0.034,
        VA_06: 0.46151,
        QC_06: 0.034,
        VC_06: 0.46151,
        QE_06: 0.034,
        VE_06: 0.46151,
        QA_07: 0.025,
        VA_07: 0.43844,
        QC_07: 0.025,
        VC_07: 0.43844,
        QE_07: 0.025,
        VE_07: 0.43844,
        QA_08: 0.018,
        VA_08: 0.41763,
        QC_08: 0.018,
        VC_08: 0.41763,
        QE_08: 0.018,
        VE_08: 0.41763,
        QA_09: 0.013,
        VA_09: 0.40029,
        QC_09: 0.013,
        VC_09: 0.40029,
        QE_09: 0.013,
        VE_09: 0.40029,
        QA_10: 0.011,
        VA_10: 0.39249,
        QC_10: 0.011,
        VC_10: 0.39249,
        QE_10: 0.011,
        VE_10: 0.39249,
        QA_11: 0.011,
        VA_11: 0.39249,
        QC_11: 0.011,
        VC_11: 0.39249,
        QE_11: 0.011,
        VE_11: 0.39249,
        QA_12: 0.078,
        VA_12: 0.54636,
        QC_12: 0.078,
        VC_12: 0.54636,
        QE_12: 0.078,
        VE_12: 0.54636,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877738584901465, 34.782236812677695, 0.0],
          [-80.876988984902653, 34.782007812678103, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287463.0,
        COMID: 9734412,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.12,
        REACHCODE: "03050103000660",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001093501914445,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059377.0,
        ToNode: 250060135.0,
        Hydroseq: 250005550.0,
        LevelPathI: 250005534.0,
        Pathlength: 398.633,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.238,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250005534.0,
        UpHydroseq: 250005561.0,
        DnLevelPat: 250005534.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005534.0,
        FromMeas: 23.1238,
        ToMeas: 65.62982,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 9332.8254,
        DivDASqKM: 0.009,
        Tidal: 0,
        TOTMA: 0.010998870647099999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13174.0,
        MAXELEVSMO: 13239.0,
        MINELEVSMO: 13174.0,
        SLOPE: 0.00541666,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.119999997317791,
        QA_MA: 0.003,
        VA_MA: 0.41429,
        QC_MA: 0.003,
        VC_MA: 0.41429,
        QE_MA: 0.003,
        VE_MA: 0.41429,
        QA_01: 0.007,
        VA_01: 0.47446,
        QC_01: 0.007,
        VC_01: 0.47446,
        QE_01: 0.007,
        VE_01: 0.47446,
        QA_02: 0.008,
        VA_02: 0.48667,
        QC_02: 0.008,
        VC_02: 0.48667,
        QE_02: 0.008,
        VE_02: 0.48667,
        QA_03: 0.008,
        VA_03: 0.48667,
        QC_03: 0.008,
        VC_03: 0.48667,
        QE_03: 0.008,
        VE_03: 0.48667,
        QA_04: 0.005,
        VA_04: 0.44729,
        QC_04: 0.005,
        VC_04: 0.44729,
        QE_04: 0.005,
        VE_04: 0.44729,
        QA_05: 0.002,
        VA_05: 0.39378,
        QC_05: 0.002,
        VC_05: 0.39378,
        QE_05: 0.002,
        VE_05: 0.39378,
        QA_06: 0.001,
        VA_06: 0.36749,
        QC_06: 0.001,
        VC_06: 0.36749,
        QE_06: 0.001,
        VE_06: 0.36749,
        QA_07: 0.001,
        VA_07: 0.36749,
        QC_07: 0.001,
        VC_07: 0.36749,
        QE_07: 0.001,
        VE_07: 0.36749,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.004,
        VA_12: 0.43177,
        QC_12: 0.004,
        VC_12: 0.43177,
        QE_12: 0.004,
        VE_12: 0.43177,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877155518235668, 34.77914447934927, 0.0],
          [-80.877322118235497, 34.778113679350838, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287464.0,
        COMID: 25122797,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.096,
        REACHCODE: "03050103000660",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.001044563564357,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060134.0,
        ToNode: 250059377.0,
        Hydroseq: 250005561.0,
        LevelPathI: 250005534.0,
        Pathlength: 398.753,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.118,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005577.0,
        DnLevelPat: 250005534.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005550.0,
        FromMeas: 65.62982,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9332.8164,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0555613139684,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 13239.0,
        MINELEVSMO: 13239.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.096000000834464999,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.87613231823741, 34.779191679349083, 0.0],
          [-80.877155518235668, 34.77914447934927, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288698.0,
        COMID: 9757378,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.086,
        REACHCODE: "03050103017659",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00089575202809600001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062193.0,
        ToNode: 250059756.0,
        Hydroseq: 250004701.0,
        LevelPathI: 250004701.0,
        Pathlength: 371.736,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.754,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004701.0,
        UpHydroseq: 250004715.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004304.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 10619.9559,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0096201350286599999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8705.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.086000002920627996,
        QA_MA: 0.001,
        VA_MA: 0.33946,
        QC_MA: 0.001,
        VC_MA: 0.33946,
        QE_MA: 0.001,
        VE_MA: 0.33946,
        QA_01: 0.002,
        VA_01: 0.35329,
        QC_01: 0.002,
        VC_01: 0.35329,
        QE_01: 0.002,
        VE_01: 0.35329,
        QA_02: 0.002,
        VA_02: 0.35329,
        QC_02: 0.002,
        VC_02: 0.35329,
        QE_02: 0.002,
        VE_02: 0.35329,
        QA_03: 0.003,
        VA_03: 0.36408,
        QC_03: 0.003,
        VC_03: 0.36408,
        QE_03: 0.003,
        VE_03: 0.36408,
        QA_04: 0.001,
        VA_04: 0.33946,
        QC_04: 0.001,
        VC_04: 0.33946,
        QE_04: 0.001,
        VE_04: 0.33946,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880616118230435, 34.571013613005562, 0.0],
          [-80.879868384898273, 34.570761613005857, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287465.0,
        COMID: 9740426,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.065,
        REACHCODE: "03050103000660",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00058959207395,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060135.0,
        ToNode: 250060138.0,
        Hydroseq: 250005534.0,
        LevelPathI: 250005534.0,
        Pathlength: 398.568,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.303,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005534.0,
        UpHydroseq: 250005550.0,
        DnLevelPat: 250005443.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005512.0,
        FromMeas: 0.0,
        ToMeas: 23.1238,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 9332.8272,
        DivDASqKM: 0.0108,
        Tidal: 0,
        TOTMA: 0.0062267576036299996,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13166.0,
        MAXELEVSMO: 13174.0,
        MINELEVSMO: 13166.0,
        SLOPE: 0.00123076,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.064999997615814001,
        QA_MA: 0.004,
        VA_MA: 0.39639,
        QC_MA: 0.004,
        VC_MA: 0.39639,
        QE_MA: 0.004,
        VE_MA: 0.39639,
        QA_01: 0.009,
        VA_01: 0.44375,
        QC_01: 0.009,
        VC_01: 0.44375,
        QE_01: 0.009,
        VE_01: 0.44375,
        QA_02: 0.009,
        VA_02: 0.44375,
        QC_02: 0.009,
        VC_02: 0.44375,
        QE_02: 0.009,
        VE_02: 0.44375,
        QA_03: 0.01,
        VA_03: 0.45154,
        QC_03: 0.01,
        VC_03: 0.45154,
        QE_03: 0.01,
        VE_03: 0.45154,
        QA_04: 0.006,
        VA_04: 0.41753,
        QC_04: 0.006,
        VC_04: 0.41753,
        QE_04: 0.006,
        VE_04: 0.41753,
        QA_05: 0.003,
        VA_05: 0.38392,
        QC_05: 0.003,
        VC_05: 0.38392,
        QE_05: 0.003,
        VE_05: 0.38392,
        QA_06: 0.002,
        VA_06: 0.36929,
        QC_06: 0.002,
        VC_06: 0.36929,
        QE_06: 0.002,
        VE_06: 0.36929,
        QA_07: 0.001,
        VA_07: 0.35054,
        QC_07: 0.001,
        VC_07: 0.35054,
        QE_07: 0.001,
        VE_07: 0.35054,
        QA_08: 0.001,
        VA_08: 0.35054,
        QC_08: 0.001,
        VC_08: 0.35054,
        QE_08: 0.001,
        VE_08: 0.35054,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.004,
        VA_12: 0.39639,
        QC_12: 0.004,
        VC_12: 0.39639,
        QE_12: 0.004,
        VE_12: 0.39639,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877322118235497, 34.778113679350838, 0.0],
          [-80.877450984901998, 34.777539279351629, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287466.0,
        COMID: 9734384,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.175,
        REACHCODE: "03050103000661",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0015873705883410001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059362.0,
        ToNode: 250060132.0,
        Hydroseq: 250005624.0,
        LevelPathI: 250005608.0,
        Pathlength: 398.935,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.937,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005609.0,
        UpHydroseq: 250005638.0,
        DnLevelPat: 250005608.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005608.0,
        FromMeas: 46.03565,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.6777,
        TotDASqKM: 9191.9007,
        DivDASqKM: 0.6777,
        Tidal: 0,
        TOTMA: 0.0096261495143500006,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13267.0,
        MAXELEVSMO: 13349.0,
        MINELEVSMO: 13272.0,
        SLOPE: 0.0308,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.025000000372528999,
        QA_MA: 0.267,
        VA_MA: 0.69033,
        QC_MA: 0.267,
        VC_MA: 0.69033,
        QE_MA: 0.267,
        VE_MA: 0.69033,
        QA_01: 0.586,
        VA_01: 0.88818,
        QC_01: 0.586,
        VC_01: 0.88818,
        QE_01: 0.586,
        VE_01: 0.88818,
        QA_02: 0.61,
        VA_02: 0.90067,
        QC_02: 0.61,
        VC_02: 0.90067,
        QE_02: 0.61,
        VE_02: 0.90067,
        QA_03: 0.656,
        VA_03: 0.92399,
        QC_03: 0.656,
        VC_03: 0.92399,
        QE_03: 0.656,
        VE_03: 0.92399,
        QA_04: 0.382,
        VA_04: 0.77034,
        QC_04: 0.382,
        VC_04: 0.77034,
        QE_04: 0.382,
        VE_04: 0.77034,
        QA_05: 0.209,
        VA_05: 0.64376,
        QC_05: 0.209,
        VC_05: 0.64376,
        QE_05: 0.209,
        VE_05: 0.64376,
        QA_06: 0.136,
        VA_06: 0.57534,
        QC_06: 0.136,
        VC_06: 0.57534,
        QE_06: 0.136,
        VE_06: 0.57534,
        QA_07: 0.1,
        VA_07: 0.53513,
        QC_07: 0.1,
        VC_07: 0.53513,
        QE_07: 0.1,
        VE_07: 0.53513,
        QA_08: 0.072,
        VA_08: 0.49883,
        QC_08: 0.072,
        VC_08: 0.49883,
        QE_08: 0.072,
        VE_08: 0.49883,
        QA_09: 0.054,
        VA_09: 0.47185,
        QC_09: 0.054,
        VC_09: 0.47185,
        QE_09: 0.054,
        VE_09: 0.47185,
        QA_10: 0.045,
        VA_10: 0.45677,
        QC_10: 0.045,
        VC_10: 0.45677,
        QE_10: 0.045,
        VE_10: 0.45677,
        QA_11: 0.045,
        VA_11: 0.45677,
        QC_11: 0.045,
        VC_11: 0.45677,
        QE_11: 0.045,
        VE_11: 0.45677,
        QA_12: 0.309,
        VA_12: 0.72114,
        QC_12: 0.309,
        VC_12: 0.72114,
        QE_12: 0.309,
        VE_12: 0.72114,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877738584901465, 34.782236812677695, 0.0],
          [-80.877405584902093, 34.780725012680136, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288708.0,
        COMID: 9757398,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.044,
        REACHCODE: "03050103017669",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00039044811246000003,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062203.0,
        ToNode: 250094154.0,
        Hydroseq: 250004685.0,
        LevelPathI: 250004668.0,
        Pathlength: 371.708,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.986,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004668.0,
        UpHydroseq: 250004759.0,
        DnLevelPat: 250004668.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004668.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.9595,
        DivDASqKM: 0.0072,
        Tidal: 0,
        TOTMA: 0.010491009287300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8656.0,
        MINELEVSMO: 8656.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.043999999761580998,
        QA_MA: 0.002,
        VA_MA: 0.15926,
        QC_MA: 0.002,
        VC_MA: 0.15926,
        QE_MA: 0.002,
        VE_MA: 0.15926,
        QA_01: 0.005,
        VA_01: 0.21851,
        QC_01: 0.005,
        VC_01: 0.21851,
        QE_01: 0.005,
        VE_01: 0.21851,
        QA_02: 0.005,
        VA_02: 0.21851,
        QC_02: 0.005,
        VC_02: 0.21851,
        QE_02: 0.005,
        VE_02: 0.21851,
        QA_03: 0.006,
        VA_03: 0.23418,
        QC_03: 0.006,
        VC_03: 0.23418,
        QE_03: 0.006,
        VE_03: 0.23418,
        QA_04: 0.003,
        VA_04: 0.18195,
        QC_04: 0.003,
        VC_04: 0.18195,
        QE_04: 0.003,
        VE_04: 0.18195,
        QA_05: 0.001,
        VA_05: 0.13025,
        QC_05: 0.001,
        VC_05: 0.13025,
        QE_05: 0.001,
        VE_05: 0.13025,
        QA_06: 0.001,
        VA_06: 0.13025,
        QC_06: 0.001,
        VC_06: 0.13025,
        QE_06: 0.001,
        VE_06: 0.13025,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.001,
        VA_12: 0.13025,
        QC_12: 0.001,
        VC_12: 0.13025,
        QE_12: 0.001,
        VE_12: 0.13025,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.880754518230219, 34.570601279672935, 0.0],
          [-80.880782184896759, 34.570211813006722, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288710.0,
        COMID: 166740235,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.367,
        REACHCODE: "03050103017671",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0037639517280230001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059976.0,
        ToNode: 250094140.0,
        Hydroseq: 250005574.0,
        LevelPathI: 250005531.0,
        Pathlength: 398.606,
        TerminalPa: 250002604.0,
        ArbolateSu: 6991.312,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005587.0,
        DnLevelPat: 250005531.0,
        DnMinorHyd: 250005559.0,
        DnDrainCou: 2,
        DnHydroseq: 250005560.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1674,
        TotDASqKM: 9332.9784,
        DivDASqKM: 0.1674,
        Tidal: 0,
        TOTMA: 0.026296775127900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13108.0,
        MAXELEVSMO: 13272.0,
        MINELEVSMO: 13108.0,
        SLOPE: 0.0075576,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.216999992728233,
        QA_MA: 0.066,
        VA_MA: 0.52995,
        QC_MA: 0.066,
        VC_MA: 0.52995,
        QE_MA: 0.066,
        VE_MA: 0.52995,
        QA_01: 0.146,
        VA_01: 0.64613,
        QC_01: 0.146,
        VC_01: 0.64613,
        QE_01: 0.146,
        VE_01: 0.64613,
        QA_02: 0.15,
        VA_02: 0.65101,
        QC_02: 0.15,
        VC_02: 0.65101,
        QE_02: 0.15,
        VE_02: 0.65101,
        QA_03: 0.162,
        VA_03: 0.6653,
        QC_03: 0.162,
        VC_03: 0.6653,
        QE_03: 0.162,
        VE_03: 0.6653,
        QA_04: 0.094,
        VA_04: 0.57571,
        QC_04: 0.094,
        VC_04: 0.57571,
        QE_04: 0.094,
        VE_04: 0.57571,
        QA_05: 0.051,
        VA_05: 0.5016,
        QC_05: 0.051,
        VC_05: 0.5016,
        QE_05: 0.051,
        VE_05: 0.5016,
        QA_06: 0.033,
        VA_06: 0.46172,
        QC_06: 0.033,
        VC_06: 0.46172,
        QE_06: 0.033,
        VE_06: 0.46172,
        QA_07: 0.024,
        VA_07: 0.43787,
        QC_07: 0.024,
        VC_07: 0.43787,
        QE_07: 0.024,
        VE_07: 0.43787,
        QA_08: 0.018,
        VA_08: 0.41953,
        QC_08: 0.018,
        VC_08: 0.41953,
        QE_08: 0.018,
        VE_08: 0.41953,
        QA_09: 0.013,
        VA_09: 0.40189,
        QC_09: 0.013,
        VC_09: 0.40189,
        QE_09: 0.013,
        VE_09: 0.40189,
        QA_10: 0.011,
        VA_10: 0.39395,
        QC_10: 0.011,
        VC_10: 0.39395,
        QE_10: 0.011,
        VE_10: 0.39395,
        QA_11: 0.011,
        VA_11: 0.39395,
        QC_11: 0.011,
        VC_11: 0.39395,
        QE_11: 0.011,
        VE_11: 0.39395,
        QA_12: 0.076,
        VA_12: 0.54718,
        QC_12: 0.076,
        VC_12: 0.54718,
        QE_12: 0.076,
        VE_12: 0.54718,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876164318237343, 34.779875012681373, 0.0],
          [-80.873055984908831, 34.778029746017637, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288703.0,
        COMID: 9757388,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.226,
        REACHCODE: "03050103017664",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.002106245656024,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062185.0,
        ToNode: 250062203.0,
        Hydroseq: 250004759.0,
        LevelPathI: 250004668.0,
        Pathlength: 371.752,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.301,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004743.0,
        UpHydroseq: 250004783.0,
        DnLevelPat: 250004668.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004685.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0072,
        TotDASqKM: 10619.9064,
        DivDASqKM: 0.0072,
        Tidal: 0,
        TOTMA: 0.024887123227299999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8656.0,
        MAXELEVSMO: 8656.0,
        MINELEVSMO: 8656.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.075999997556209994,
        QA_MA: 0.002,
        VA_MA: 0.34483,
        QC_MA: 0.002,
        VC_MA: 0.34483,
        QE_MA: 0.002,
        VE_MA: 0.34483,
        QA_01: 0.005,
        VA_01: 0.36767,
        QC_01: 0.005,
        VC_01: 0.36767,
        QE_01: 0.005,
        VE_01: 0.36767,
        QA_02: 0.005,
        VA_02: 0.36767,
        QC_02: 0.005,
        VC_02: 0.36767,
        QE_02: 0.005,
        VE_02: 0.36767,
        QA_03: 0.006,
        VA_03: 0.37369,
        QC_03: 0.006,
        VC_03: 0.37369,
        QE_03: 0.006,
        VE_03: 0.37369,
        QA_04: 0.003,
        VA_04: 0.35358,
        QC_04: 0.003,
        VC_04: 0.35358,
        QE_04: 0.003,
        VE_04: 0.35358,
        QA_05: 0.001,
        VA_05: 0.33361,
        QC_05: 0.001,
        VC_05: 0.33361,
        QE_05: 0.001,
        VE_05: 0.33361,
        QA_06: 0.001,
        VA_06: 0.33361,
        QC_06: 0.001,
        VC_06: 0.33361,
        QE_06: 0.001,
        VE_06: 0.33361,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.001,
        VA_12: 0.33361,
        QC_12: 0.001,
        VC_12: 0.33361,
        QE_12: 0.001,
        VE_12: 0.33361,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881751518228612, 34.572456613003283, 0.0],
          [-80.880754518230219, 34.570601279672935, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287471.0,
        COMID: 9740414,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.122,
        REACHCODE: "03050103000663",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0012399961957,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060131.0,
        ToNode: 250059975.0,
        Hydroseq: 250005609.0,
        LevelPathI: 250005609.0,
        Pathlength: 398.953,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.958,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005609.0,
        UpHydroseq: 250005625.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005598.0,
        FromMeas: 0.0,
        ToMeas: 62.26013,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 9191.2401,
        DivDASqKM: 0.1863,
        Tidal: 0,
        TOTMA: 0.011954962825900001,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13296.0,
        MAXELEVSMO: 13296.0,
        MINELEVSMO: 13296.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.122000001370907,
        QA_MA: 0.073,
        VA_MA: 0.38751,
        QC_MA: 0.073,
        VC_MA: 0.38751,
        QE_MA: 0.073,
        VE_MA: 0.38751,
        QA_01: 0.162,
        VA_01: 0.4292,
        QC_01: 0.162,
        VC_01: 0.4292,
        QE_01: 0.162,
        VE_01: 0.4292,
        QA_02: 0.167,
        VA_02: 0.43117,
        QC_02: 0.167,
        VC_02: 0.43117,
        QE_02: 0.167,
        VE_02: 0.43117,
        QA_03: 0.18,
        VA_03: 0.43615,
        QC_03: 0.18,
        VC_03: 0.43615,
        QE_03: 0.18,
        VE_03: 0.43615,
        QA_04: 0.105,
        VA_04: 0.40435,
        QC_04: 0.105,
        VC_04: 0.40435,
        QE_04: 0.105,
        VE_04: 0.40435,
        QA_05: 0.057,
        VA_05: 0.37777,
        QC_05: 0.057,
        VC_05: 0.37777,
        QE_05: 0.057,
        VE_05: 0.37777,
        QA_06: 0.037,
        VA_06: 0.36354,
        QC_06: 0.037,
        VC_06: 0.36354,
        QE_06: 0.037,
        VE_06: 0.36354,
        QA_07: 0.027,
        VA_07: 0.35505,
        QC_07: 0.027,
        VC_07: 0.35505,
        QE_07: 0.027,
        VE_07: 0.35505,
        QA_08: 0.02,
        VA_08: 0.34817,
        QC_08: 0.02,
        VC_08: 0.34817,
        QE_08: 0.02,
        VE_08: 0.34817,
        QA_09: 0.015,
        VA_09: 0.34254,
        QC_09: 0.015,
        VC_09: 0.34254,
        QE_09: 0.015,
        VE_09: 0.34254,
        QA_10: 0.012,
        VA_10: 0.33872,
        QC_10: 0.012,
        VC_10: 0.33872,
        QE_10: 0.012,
        VE_10: 0.33872,
        QA_11: 0.012,
        VA_11: 0.33872,
        QC_11: 0.012,
        VC_11: 0.33872,
        QE_11: 0.012,
        VE_11: 0.33872,
        QA_12: 0.085,
        VA_12: 0.39417,
        QC_12: 0.085,
        VC_12: 0.39417,
        QE_12: 0.085,
        VE_12: 0.39417,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876988984902653, 34.782007812678103, 0.0],
          [-80.876039518237462, 34.781352612679143, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287472.0,
        COMID: 9734348,
        FDATE: "1999/10/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.202,
        REACHCODE: "03050103000664",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001826579516115,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059355.0,
        ToNode: 250093424.0,
        Hydroseq: 250005639.0,
        LevelPathI: 250005620.0,
        Pathlength: 399.272,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.57,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005620.0,
        UpHydroseq: 250005653.0,
        DnLevelPat: 250005620.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005620.0,
        FromMeas: 41.50996,
        ToMeas: 92.10834,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0135,
        TotDASqKM: 9191.0709,
        DivDASqKM: 0.0414,
        Tidal: 0,
        TOTMA: 0.017859514015400001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13423.0,
        MAXELEVSMO: 13449.0,
        MINELEVSMO: 13423.0,
        SLOPE: 0.00128712,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.202000007033348,
        QA_MA: 0.016,
        VA_MA: 0.42949,
        QC_MA: 0.016,
        VC_MA: 0.42949,
        QE_MA: 0.016,
        VE_MA: 0.42949,
        QA_01: 0.036,
        VA_01: 0.49467,
        QC_01: 0.036,
        VC_01: 0.49467,
        QE_01: 0.036,
        VE_01: 0.49467,
        QA_02: 0.037,
        VA_02: 0.4974,
        QC_02: 0.037,
        VC_02: 0.4974,
        QE_02: 0.037,
        VE_02: 0.4974,
        QA_03: 0.04,
        VA_03: 0.50538,
        QC_03: 0.04,
        VC_03: 0.50538,
        QE_03: 0.04,
        VE_03: 0.50538,
        QA_04: 0.023,
        VA_04: 0.45523,
        QC_04: 0.023,
        VC_04: 0.45523,
        QE_04: 0.023,
        VE_04: 0.45523,
        QA_05: 0.012,
        VA_05: 0.41234,
        QC_05: 0.012,
        VC_05: 0.41234,
        QE_05: 0.012,
        VE_05: 0.41234,
        QA_06: 0.008,
        VA_06: 0.3922,
        QC_06: 0.008,
        VC_06: 0.3922,
        QE_06: 0.008,
        VE_06: 0.3922,
        QA_07: 0.006,
        VA_07: 0.38033,
        QC_07: 0.006,
        VC_07: 0.38033,
        QE_07: 0.006,
        VE_07: 0.38033,
        QA_08: 0.004,
        VA_08: 0.36639,
        QC_08: 0.004,
        VC_08: 0.36639,
        QE_08: 0.004,
        VE_08: 0.36639,
        QA_09: 0.003,
        VA_09: 0.35818,
        QC_09: 0.003,
        VC_09: 0.35818,
        QE_09: 0.003,
        VE_09: 0.35818,
        QA_10: 0.002,
        VA_10: 0.34853,
        QC_10: 0.002,
        VC_10: 0.34853,
        QE_10: 0.002,
        VE_10: 0.34853,
        QA_11: 0.002,
        VA_11: 0.34853,
        QC_11: 0.002,
        VC_11: 0.34853,
        QE_11: 0.002,
        VE_11: 0.34853,
        QA_12: 0.018,
        VA_12: 0.43731,
        QC_12: 0.018,
        VC_12: 0.43731,
        QE_12: 0.018,
        VE_12: 0.43731,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877183518235768, 34.785535212672585, 0.0],
          [-80.876989184902698, 34.783771479342022, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287473.0,
        COMID: 9740406,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.031,
        REACHCODE: "03050103000664",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00028583215708899998,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250060128.0,
        ToNode: 250059355.0,
        Hydroseq: 250005653.0,
        LevelPathI: 250005620.0,
        Pathlength: 399.474,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.368,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005620.0,
        UpHydroseq: 250005668.0,
        DnLevelPat: 250005620.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005639.0,
        FromMeas: 92.10834,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 9191.0574,
        DivDASqKM: 0.0279,
        Tidal: 0,
        TOTMA: 0.0025722815844699998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13449.0,
        MAXELEVSMO: 13475.0,
        MINELEVSMO: 13449.0,
        SLOPE: 0.00838709,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.030999999493361002,
        QA_MA: 0.011,
        VA_MA: 0.45763,
        QC_MA: 0.011,
        VC_MA: 0.45763,
        QE_MA: 0.011,
        VE_MA: 0.45763,
        QA_01: 0.024,
        VA_01: 0.53423,
        QC_01: 0.024,
        VC_01: 0.53423,
        QE_01: 0.024,
        VE_01: 0.53423,
        QA_02: 0.025,
        VA_02: 0.53918,
        QC_02: 0.025,
        VC_02: 0.53918,
        QE_02: 0.025,
        VE_02: 0.53918,
        QA_03: 0.027,
        VA_03: 0.54881,
        QC_03: 0.027,
        VC_03: 0.54881,
        QE_03: 0.027,
        VE_03: 0.54881,
        QA_04: 0.015,
        VA_04: 0.48435,
        QC_04: 0.015,
        VC_04: 0.48435,
        QE_04: 0.015,
        VE_04: 0.48435,
        QA_05: 0.008,
        VA_05: 0.43441,
        QC_05: 0.008,
        VC_05: 0.43441,
        QE_05: 0.008,
        VE_05: 0.43441,
        QA_06: 0.005,
        VA_06: 0.40658,
        QC_06: 0.005,
        VC_06: 0.40658,
        QE_06: 0.005,
        VE_06: 0.40658,
        QA_07: 0.004,
        VA_07: 0.39561,
        QC_07: 0.004,
        VC_07: 0.39561,
        QE_07: 0.004,
        VE_07: 0.39561,
        QA_08: 0.003,
        VA_08: 0.38325,
        QC_08: 0.003,
        VC_08: 0.38325,
        QE_08: 0.003,
        VE_08: 0.38325,
        QA_09: 0.002,
        VA_09: 0.36875,
        QC_09: 0.002,
        VC_09: 0.36875,
        QE_09: 0.002,
        VE_09: 0.36875,
        QA_10: 0.001,
        VA_10: 0.35017,
        QC_10: 0.001,
        VC_10: 0.35017,
        QE_10: 0.001,
        VE_10: 0.35017,
        QA_11: 0.001,
        VA_11: 0.35017,
        QC_11: 0.001,
        VC_11: 0.35017,
        QE_11: 0.001,
        VE_11: 0.35017,
        QA_12: 0.012,
        VA_12: 0.46469,
        QC_12: 0.012,
        VC_12: 0.46469,
        QE_12: 0.012,
        VE_12: 0.46469,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.877267984902289, 34.785808279338937, 0.0],
          [-80.877183518235768, 34.785535212672585, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288683.0,
        COMID: 9757348,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.039,
        REACHCODE: "03050103017644",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00040393345917300001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059751.0,
        ToNode: 250062189.0,
        Hydroseq: 250004468.0,
        LevelPathI: 250004468.0,
        Pathlength: 371.998,
        TerminalPa: 250002604.0,
        ArbolateSu: 7671.371,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004457.0,
        UpHydroseq: 250004483.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004338.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9975.7467,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0048021490734999998,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8938.0,
        MINELEVSMO: 8851.0,
        SLOPE: 0.02230769,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.039000000804663003,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.879037584899493, 34.572937813002625, 0.0],
          [-80.878677584900117, 34.572754613002928, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 287474.0,
        COMID: 25122795,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.165,
        REACHCODE: "03050103000664",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0016330056856420001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250093424.0,
        ToNode: 250059974.0,
        Hydroseq: 250005620.0,
        LevelPathI: 250005620.0,
        Pathlength: 399.107,
        TerminalPa: 250002604.0,
        ArbolateSu: 6822.735,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250005620.0,
        UpHydroseq: 250005639.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005610.0,
        FromMeas: 0.0,
        ToMeas: 41.50996,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0225,
        TotDASqKM: 9191.0934,
        DivDASqKM: 0.0639,
        Tidal: 0,
        TOTMA: 0.0133878062062,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 13359.0,
        MAXELEVSMO: 13423.0,
        MINELEVSMO: 13359.0,
        SLOPE: 0.00387878,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.16500000655651101,
        QA_MA: 0.025,
        VA_MA: 0.468,
        QC_MA: 0.025,
        VC_MA: 0.468,
        QE_MA: 0.025,
        VE_MA: 0.468,
        QA_01: 0.055,
        VA_01: 0.55099,
        QC_01: 0.055,
        VC_01: 0.55099,
        QE_01: 0.055,
        VE_01: 0.55099,
        QA_02: 0.057,
        VA_02: 0.55563,
        QC_02: 0.057,
        VC_02: 0.55563,
        QE_02: 0.057,
        VE_02: 0.55563,
        QA_03: 0.061,
        VA_03: 0.5647,
        QC_03: 0.061,
        VC_03: 0.5647,
        QE_03: 0.061,
        VE_03: 0.5647,
        QA_04: 0.036,
        VA_04: 0.5021,
        QC_04: 0.036,
        VC_04: 0.5021,
        QE_04: 0.036,
        VE_04: 0.5021,
        QA_05: 0.019,
        VA_05: 0.44636,
        QC_05: 0.019,
        VC_05: 0.44636,
        QE_05: 0.019,
        VE_05: 0.44636,
        QA_06: 0.012,
        VA_06: 0.41648,
        QC_06: 0.012,
        VC_06: 0.41648,
        QE_06: 0.012,
        VE_06: 0.41648,
        QA_07: 0.009,
        VA_07: 0.40117,
        QC_07: 0.009,
        VC_07: 0.40117,
        QE_07: 0.009,
        VE_07: 0.40117,
        QA_08: 0.006,
        VA_08: 0.3832,
        QC_08: 0.006,
        VC_08: 0.3832,
        QE_08: 0.006,
        VE_08: 0.3832,
        QA_09: 0.005,
        VA_09: 0.3763,
        QC_09: 0.005,
        VC_09: 0.3763,
        QE_09: 0.005,
        VE_09: 0.3763,
        QA_10: 0.004,
        VA_10: 0.36871,
        QC_10: 0.004,
        VC_10: 0.36871,
        QE_10: 0.004,
        VE_10: 0.36871,
        QA_11: 0.004,
        VA_11: 0.36871,
        QC_11: 0.004,
        VC_11: 0.36871,
        QE_11: 0.004,
        VE_11: 0.36871,
        QA_12: 0.029,
        VA_12: 0.48109,
        QC_12: 0.029,
        VC_12: 0.48109,
        QE_12: 0.029,
        VE_12: 0.48109,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.876989184902698, 34.783771479342022, 0.0],
          [-80.875777584904611, 34.782676612677108, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288684.0,
        COMID: 9757350,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.078,
        REACHCODE: "03050103017645",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00070947222847399997,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062169.0,
        ToNode: 250062187.0,
        Hydroseq: 250004801.0,
        LevelPathI: 250004781.0,
        Pathlength: 372.051,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.069,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004819.0,
        DnLevelPat: 250004781.0,
        DnMinorHyd: 250004780.0,
        DnDrainCou: 2,
        DnHydroseq: 250004781.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.7417,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.009604298147,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9082.0,
        MINELEVSMO: 9082.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.078000001609325006,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.883246918226291, 34.573395679668636, 0.0],
          [-80.882997718226648, 34.572731413002941, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288679.0,
        COMID: 9757340,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.041,
        REACHCODE: "03050103017640",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00044831065054199998,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062174.0,
        ToNode: 250059749.0,
        Hydroseq: 250004784.0,
        LevelPathI: 250004784.0,
        Pathlength: 372.063,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.045,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004784.0,
        UpHydroseq: 250004802.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004765.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0018,
        TotDASqKM: 10619.901,
        DivDASqKM: 0.0018,
        Tidal: 0,
        TOTMA: 0.0050484131638399996,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8885.0,
        MAXELEVSMO: 8885.0,
        MINELEVSMO: 8885.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.041000001132488001,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.001,
        VA_01: 0.30839,
        QC_01: 0.001,
        VC_01: 0.30839,
        QE_01: 0.001,
        VE_01: 0.30839,
        QA_02: 0.001,
        VA_02: 0.30839,
        QC_02: 0.001,
        VC_02: 0.30839,
        QE_02: 0.001,
        VE_02: 0.30839,
        QA_03: 0.001,
        VA_03: 0.30839,
        QC_03: 0.001,
        VC_03: 0.30839,
        QE_03: 0.001,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882083718228102, 34.573029213002542, 0.0],
          [-80.881640718228766, 34.57309801300238, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288680.0,
        COMID: 9757342,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.116,
        REACHCODE: "03050103017641",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0010971300442659999,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250059747.0,
        ToNode: 250062174.0,
        Hydroseq: 250004802.0,
        LevelPathI: 250004784.0,
        Pathlength: 372.104,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.004,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004717.0,
        UpHydroseq: 250004823.0,
        DnLevelPat: 250004784.0,
        DnMinorHyd: 250004783.0,
        DnDrainCou: 2,
        DnHydroseq: 250004784.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.8992,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.014283314487300001,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 9081.0,
        MINELEVSMO: 9081.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.11599999666214,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882720718227176, 34.573922479667772, 0.0],
          [-80.882083718228102, 34.573029213002542, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288691.0,
        COMID: 9757364,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.17,
        REACHCODE: "03050103017652",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.0016104193797290001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062186.0,
        ToNode: 250062196.0,
        Hydroseq: 250004782.0,
        LevelPathI: 250004736.0,
        Pathlength: 371.938,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.652,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004736.0,
        UpHydroseq: 250004803.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004760.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 1,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.009,
        TotDASqKM: 10619.9361,
        DivDASqKM: 0.0369,
        Tidal: 0,
        TOTMA: 0.017827059593399999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8888.0,
        MAXELEVSMO: 8951.0,
        MINELEVSMO: 8951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.17000000178813901,
        QA_MA: 0.012,
        VA_MA: 0.36211,
        QC_MA: 0.012,
        VC_MA: 0.36211,
        QE_MA: 0.012,
        VE_MA: 0.36211,
        QA_01: 0.026,
        VA_01: 0.38939,
        QC_01: 0.026,
        VC_01: 0.38939,
        QE_01: 0.026,
        VE_01: 0.38939,
        QA_02: 0.029,
        VA_02: 0.39422,
        QC_02: 0.029,
        VC_02: 0.39422,
        QE_02: 0.029,
        VE_02: 0.39422,
        QA_03: 0.032,
        VA_03: 0.39883,
        QC_03: 0.032,
        VC_03: 0.39883,
        QE_03: 0.032,
        VE_03: 0.39883,
        QA_04: 0.016,
        VA_04: 0.37098,
        QC_04: 0.016,
        VC_04: 0.37098,
        QE_04: 0.016,
        VE_04: 0.37098,
        QA_05: 0.009,
        VA_05: 0.3545,
        QC_05: 0.009,
        VC_05: 0.3545,
        QE_05: 0.009,
        VE_05: 0.3545,
        QA_06: 0.006,
        VA_06: 0.34557,
        QC_06: 0.006,
        VC_06: 0.34557,
        QE_06: 0.006,
        VE_06: 0.34557,
        QA_07: 0.004,
        VA_07: 0.33837,
        QC_07: 0.004,
        VC_07: 0.33837,
        QE_07: 0.004,
        VE_07: 0.33837,
        QA_08: 0.003,
        VA_08: 0.33412,
        QC_08: 0.003,
        VC_08: 0.33412,
        QE_08: 0.003,
        VE_08: 0.33412,
        QA_09: 0.002,
        VA_09: 0.32914,
        QC_09: 0.002,
        VC_09: 0.32914,
        QE_09: 0.002,
        VE_09: 0.32914,
        QA_10: 0.002,
        VA_10: 0.32914,
        QC_10: 0.002,
        VC_10: 0.32914,
        QE_10: 0.002,
        VE_10: 0.32914,
        QA_11: 0.001,
        VA_11: 0.32275,
        QC_11: 0.001,
        VC_11: 0.32275,
        QE_11: 0.001,
        VE_11: 0.32275,
        QA_12: 0.008,
        VA_12: 0.35171,
        QC_12: 0.008,
        VC_12: 0.35171,
        QE_12: 0.008,
        VE_12: 0.35171,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.8829147182268, 34.573441479668475, 0.0],
          [-80.882000718228255, 34.572135879670441, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288692.0,
        COMID: 9757366,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.113,
        REACHCODE: "03050103017653",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.001161342767164,
        StreamLeve: 5,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062187.0,
        ToNode: 250062196.0,
        Hydroseq: 250004781.0,
        LevelPathI: 250004781.0,
        Pathlength: 371.938,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.182,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250004781.0,
        UpHydroseq: 250004801.0,
        DnLevelPat: 250004736.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004760.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0117,
        TotDASqKM: 10619.7534,
        DivDASqKM: 0.0117,
        Tidal: 0,
        TOTMA: 0.0122994621241,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8951.0,
        MAXELEVSMO: 8951.0,
        MINELEVSMO: 8951.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.112999998033047,
        QA_MA: 0.003,
        VA_MA: 0.34887,
        QC_MA: 0.003,
        VC_MA: 0.34887,
        QE_MA: 0.003,
        VE_MA: 0.34887,
        QA_01: 0.008,
        VA_01: 0.37653,
        QC_01: 0.008,
        VC_01: 0.37653,
        QE_01: 0.008,
        VE_01: 0.37653,
        QA_02: 0.009,
        VA_02: 0.38092,
        QC_02: 0.009,
        VC_02: 0.38092,
        QE_02: 0.009,
        VE_02: 0.38092,
        QA_03: 0.01,
        VA_03: 0.3851,
        QC_03: 0.01,
        VC_03: 0.3851,
        QE_03: 0.01,
        VE_03: 0.3851,
        QA_04: 0.005,
        VA_04: 0.36148,
        QC_04: 0.005,
        VC_04: 0.36148,
        QE_04: 0.005,
        VE_04: 0.36148,
        QA_05: 0.002,
        VA_05: 0.34103,
        QC_05: 0.002,
        VC_05: 0.34103,
        QE_05: 0.002,
        VE_05: 0.34103,
        QA_06: 0.002,
        VA_06: 0.34103,
        QC_06: 0.002,
        VC_06: 0.34103,
        QE_06: 0.002,
        VE_06: 0.34103,
        QA_07: 0.001,
        VA_07: 0.33098,
        QC_07: 0.001,
        VC_07: 0.33098,
        QE_07: 0.001,
        VE_07: 0.33098,
        QA_08: 0.001,
        VA_08: 0.33098,
        QC_08: 0.001,
        VC_08: 0.33098,
        QE_08: 0.001,
        VE_08: 0.33098,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.002,
        VA_12: 0.34103,
        QC_12: 0.002,
        VC_12: 0.34103,
        QE_12: 0.002,
        VE_12: 0.34103,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882997718226648, 34.572731413002941, 0.0],
          [-80.882000718228255, 34.572135879670441, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288693.0,
        COMID: 9757368,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.083,
        REACHCODE: "03050103017654",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.000842967458197,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062184.0,
        ToNode: 250059752.0,
        Hydroseq: 250004742.0,
        LevelPathI: 250004742.0,
        Pathlength: 371.901,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.202,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004743.0,
        UpHydroseq: 250004761.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 250004715.0,
        DnDrainCou: 2,
        DnHydroseq: 250004717.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.9028,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0102199576848,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.082999996840953993,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881308384895988, 34.572387879670146, 0.0],
          [-80.880616118230435, 34.571906879670848, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288688.0,
        COMID: 9757358,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.071,
        REACHCODE: "03050103017649",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00066198761228100005,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062174.0,
        ToNode: 250062185.0,
        Hydroseq: 250004783.0,
        LevelPathI: 250004743.0,
        Pathlength: 372.037,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.075,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004784.0,
        UpHydroseq: 250004802.0,
        DnLevelPat: 250004743.0,
        DnMinorHyd: 250004759.0,
        DnDrainCou: 2,
        DnHydroseq: 250004761.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.8992,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0087423740601800005,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.071000002324580994,
        QA_MA: 0.0,
        VA_MA: 0.30839,
        QC_MA: 0.0,
        VC_MA: 0.30839,
        QE_MA: 0.0,
        VE_MA: 0.30839,
        QA_01: 0.0,
        VA_01: 0.30839,
        QC_01: 0.0,
        VC_01: 0.30839,
        QE_01: 0.0,
        VE_01: 0.30839,
        QA_02: 0.0,
        VA_02: 0.30839,
        QC_02: 0.0,
        VC_02: 0.30839,
        QE_02: 0.0,
        VE_02: 0.30839,
        QA_03: 0.0,
        VA_03: 0.30839,
        QC_03: 0.0,
        VC_03: 0.30839,
        QE_03: 0.0,
        VE_03: 0.30839,
        QA_04: 0.0,
        VA_04: 0.30839,
        QC_04: 0.0,
        VC_04: 0.30839,
        QE_04: 0.0,
        VE_04: 0.30839,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.882083718228102, 34.573029213002542, 0.0],
          [-80.881751518228612, 34.572456613003283, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288689.0,
        COMID: 9757360,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.028,
        REACHCODE: "03050103017650",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00030466666612499998,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062184.0,
        ToNode: 250059750.0,
        Hydroseq: 250004743.0,
        LevelPathI: 250004743.0,
        Pathlength: 371.965,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.147,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 5,
        UpLevelPat: 250004743.0,
        UpHydroseq: 250004761.0,
        DnLevelPat: 250004717.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004730.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 10619.9028,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0031321369763899999,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.028000000864266999,
        QA_MA: 0.001,
        VA_MA: 0.33946,
        QC_MA: 0.001,
        VC_MA: 0.33946,
        QE_MA: 0.001,
        VE_MA: 0.33946,
        QA_01: 0.002,
        VA_01: 0.35329,
        QC_01: 0.002,
        VC_01: 0.35329,
        QE_01: 0.002,
        VE_01: 0.35329,
        QA_02: 0.002,
        VA_02: 0.35329,
        QC_02: 0.002,
        VC_02: 0.35329,
        QE_02: 0.002,
        VE_02: 0.35329,
        QA_03: 0.003,
        VA_03: 0.36408,
        QC_03: 0.003,
        VC_03: 0.36408,
        QE_03: 0.003,
        VE_03: 0.36408,
        QA_04: 0.001,
        VA_04: 0.33946,
        QC_04: 0.001,
        VC_04: 0.33946,
        QE_04: 0.001,
        VE_04: 0.33946,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881308384895988, 34.572387879670146, 0.0],
          [-80.881003718229863, 34.572387879670146, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288690.0,
        COMID: 9757362,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.044,
        REACHCODE: "03050103017651",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 0,
        FTYPE: "StreamRiver",
        FCODE: 46006,
        Shape_Leng: 0.00047145575418299998,
        StreamLeve: 6,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250062185.0,
        ToNode: 250062184.0,
        Hydroseq: 250004761.0,
        LevelPathI: 250004743.0,
        Pathlength: 371.993,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.119,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 6,
        UpLevelPat: 250004743.0,
        UpHydroseq: 250004783.0,
        DnLevelPat: 250004743.0,
        DnMinorHyd: 250004742.0,
        DnDrainCou: 2,
        DnHydroseq: 250004743.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0036,
        TotDASqKM: 10619.9028,
        DivDASqKM: 0.0036,
        Tidal: 0,
        TOTMA: 0.0049219293557300004,
        WBAreaType: null,
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8787.0,
        MAXELEVSMO: 8851.0,
        MINELEVSMO: 8851.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.043999999761580998,
        QA_MA: 0.001,
        VA_MA: 0.33946,
        QC_MA: 0.001,
        VC_MA: 0.33946,
        QE_MA: 0.001,
        VE_MA: 0.33946,
        QA_01: 0.002,
        VA_01: 0.35329,
        QC_01: 0.002,
        VC_01: 0.35329,
        QE_01: 0.002,
        VE_01: 0.35329,
        QA_02: 0.002,
        VA_02: 0.35329,
        QC_02: 0.002,
        VC_02: 0.35329,
        QE_02: 0.002,
        VE_02: 0.35329,
        QA_03: 0.003,
        VA_03: 0.36408,
        QC_03: 0.003,
        VC_03: 0.36408,
        QE_03: 0.003,
        VE_03: 0.36408,
        QA_04: 0.001,
        VA_04: 0.33946,
        QC_04: 0.001,
        VC_04: 0.33946,
        QE_04: 0.001,
        VE_04: 0.33946,
        QA_05: 0.0,
        VA_05: 0.30839,
        QC_05: 0.0,
        VC_05: 0.30839,
        QE_05: 0.0,
        VE_05: 0.30839,
        QA_06: 0.0,
        VA_06: 0.30839,
        QC_06: 0.0,
        VC_06: 0.30839,
        QE_06: 0.0,
        VE_06: 0.30839,
        QA_07: 0.0,
        VA_07: 0.30839,
        QC_07: 0.0,
        VC_07: 0.30839,
        QE_07: 0.0,
        VE_07: 0.30839,
        QA_08: 0.0,
        VA_08: 0.30839,
        QC_08: 0.0,
        VC_08: 0.30839,
        QE_08: 0.0,
        VE_08: 0.30839,
        QA_09: 0.0,
        VA_09: 0.30839,
        QC_09: 0.0,
        VC_09: 0.30839,
        QE_09: 0.0,
        VE_09: 0.30839,
        QA_10: 0.0,
        VA_10: 0.30839,
        QC_10: 0.0,
        VC_10: 0.30839,
        QE_10: 0.0,
        VE_10: 0.30839,
        QA_11: 0.0,
        VA_11: 0.30839,
        QC_11: 0.0,
        VC_11: 0.30839,
        QE_11: 0.0,
        VE_11: 0.30839,
        QA_12: 0.0,
        VA_12: 0.30839,
        QC_12: 0.0,
        VC_12: 0.30839,
        QE_12: 0.0,
        VE_12: 0.30839,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.881751518228612, 34.572456613003283, 0.0],
          [-80.881308384895988, 34.572387879670146, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288733.0,
        COMID: 166740258,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.143,
        REACHCODE: "03050103017694",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0015654371605040001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094162.0,
        ToNode: 250059703.0,
        Hydroseq: 250005030.0,
        LevelPathI: 250004991.0,
        Pathlength: 375.935,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.542,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250003258.0,
        UpHydroseq: 250005051.0,
        DnLevelPat: 250004991.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005021.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9867.1248,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.0,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10801.0,
        SLOPE: -9998.0,
        ELEVFIXED: "1",
        HWTYPE: null,
        SLOPELENKM: 0.14300000667571999,
        QA_MA: 0.0,
        VA_MA: -9998.0,
        QC_MA: 0.0,
        VC_MA: -9998.0,
        QE_MA: 0.0,
        VE_MA: -9998.0,
        QA_01: 0.0,
        VA_01: -9998.0,
        QC_01: 0.0,
        VC_01: -9998.0,
        QE_01: 0.0,
        VE_01: -9998.0,
        QA_02: 0.0,
        VA_02: -9998.0,
        QC_02: 0.0,
        VC_02: -9998.0,
        QE_02: 0.0,
        VE_02: -9998.0,
        QA_03: 0.0,
        VA_03: -9998.0,
        QC_03: 0.0,
        VC_03: -9998.0,
        QE_03: 0.0,
        VE_03: -9998.0,
        QA_04: 0.0,
        VA_04: -9998.0,
        QC_04: 0.0,
        VC_04: -9998.0,
        QE_04: 0.0,
        VE_04: -9998.0,
        QA_05: 0.0,
        VA_05: -9998.0,
        QC_05: 0.0,
        VC_05: -9998.0,
        QE_05: 0.0,
        VE_05: -9998.0,
        QA_06: 0.0,
        VA_06: -9998.0,
        QC_06: 0.0,
        VC_06: -9998.0,
        QE_06: 0.0,
        VE_06: -9998.0,
        QA_07: 0.0,
        VA_07: -9998.0,
        QC_07: 0.0,
        VC_07: -9998.0,
        QE_07: 0.0,
        VE_07: -9998.0,
        QA_08: 0.0,
        VA_08: -9998.0,
        QC_08: 0.0,
        VC_08: -9998.0,
        QE_08: 0.0,
        VE_08: -9998.0,
        QA_09: 0.0,
        VA_09: -9998.0,
        QC_09: 0.0,
        VC_09: -9998.0,
        QE_09: 0.0,
        VE_09: -9998.0,
        QA_10: 0.0,
        VA_10: -9998.0,
        QC_10: 0.0,
        VC_10: -9998.0,
        QE_10: 0.0,
        VE_10: -9998.0,
        QA_11: 0.0,
        VA_11: -9998.0,
        QC_11: 0.0,
        VC_11: -9998.0,
        QE_11: 0.0,
        VE_11: -9998.0,
        QA_12: 0.0,
        VA_12: -9998.0,
        QC_12: 0.0,
        VC_12: -9998.0,
        QE_12: 0.0,
        VE_12: -9998.0,
        LakeFract: -9998.0,
        SurfArea: -9998.0,
        RAreaHLoad: -9998.0,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.891603118213368, 34.597256946298216, 0.0],
          [-80.893165518210935, 34.597354412964762, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288734.0,
        COMID: 166740259,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.108,
        REACHCODE: "03050103017695",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0011668995902510001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094163.0,
        ToNode: 250060055.0,
        Hydroseq: 250004991.0,
        LevelPathI: 250004991.0,
        Pathlength: 375.668,
        TerminalPa: 250002604.0,
        ArbolateSu: 7550.809,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 2,
        UpLevelPat: 250004991.0,
        UpHydroseq: 250005021.0,
        DnLevelPat: 250003258.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004983.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0099,
        TotDASqKM: 9867.177,
        DivDASqKM: 0.0522,
        Tidal: 0,
        TOTMA: 0.02671896659,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 10800.0,
        MAXELEVSMO: 10989.0,
        MINELEVSMO: 10989.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.108000002801418,
        QA_MA: 0.017,
        VA_MA: -9998.0,
        QC_MA: 0.017,
        VC_MA: -9998.0,
        QE_MA: 0.017,
        VE_MA: -9998.0,
        QA_01: 0.037,
        VA_01: -9998.0,
        QC_01: 0.037,
        VC_01: -9998.0,
        QE_01: 0.037,
        VE_01: -9998.0,
        QA_02: 0.041,
        VA_02: -9998.0,
        QC_02: 0.041,
        VC_02: -9998.0,
        QE_02: 0.041,
        VE_02: -9998.0,
        QA_03: 0.046,
        VA_03: -9998.0,
        QC_03: 0.046,
        VC_03: -9998.0,
        QE_03: 0.046,
        VE_03: -9998.0,
        QA_04: 0.023,
        VA_04: -9998.0,
        QC_04: 0.023,
        VC_04: -9998.0,
        QE_04: 0.023,
        VE_04: -9998.0,
        QA_05: 0.013,
        VA_05: -9998.0,
        QC_05: 0.013,
        VC_05: -9998.0,
        QE_05: 0.013,
        VE_05: -9998.0,
        QA_06: 0.009,
        VA_06: -9998.0,
        QC_06: 0.009,
        VC_06: -9998.0,
        QE_06: 0.009,
        VE_06: -9998.0,
        QA_07: 0.007,
        VA_07: -9998.0,
        QC_07: 0.007,
        VC_07: -9998.0,
        QE_07: 0.007,
        VE_07: -9998.0,
        QA_08: 0.005,
        VA_08: -9998.0,
        QC_08: 0.005,
        VC_08: -9998.0,
        QE_08: 0.005,
        VE_08: -9998.0,
        QA_09: 0.004,
        VA_09: -9998.0,
        QC_09: 0.004,
        VC_09: -9998.0,
        QE_09: 0.004,
        VE_09: -9998.0,
        QA_10: 0.003,
        VA_10: -9998.0,
        QC_10: 0.003,
        VC_10: -9998.0,
        QE_10: 0.003,
        VE_10: -9998.0,
        QA_11: 0.002,
        VA_11: -9998.0,
        QC_11: 0.002,
        VC_11: -9998.0,
        QE_11: 0.002,
        VE_11: -9998.0,
        QA_12: 0.013,
        VA_12: -9998.0,
        QC_12: 0.013,
        VC_12: -9998.0,
        QE_12: 0.013,
        VE_12: -9998.0,
        LakeFract: 1.844e-8,
        SurfArea: 0.22701010039,
        RAreaHLoad: 0.00545804156,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.893857718209915, 34.596300679633089, 0.0],
          [-80.892741918211584, 34.595959146300231, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288727.0,
        COMID: 166740252,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.321,
        REACHCODE: "03050103017688",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9736024,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.0031154494298080001,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094156.0,
        ToNode: 250060091.0,
        Hydroseq: 250004753.0,
        LevelPathI: 250004753.0,
        Pathlength: 371.455,
        TerminalPa: 250002604.0,
        ArbolateSu: 7924.671,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250004753.0,
        UpHydroseq: 250004780.0,
        DnLevelPat: 250004270.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250004280.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.1017,
        TotDASqKM: 10619.8596,
        DivDASqKM: 0.1179,
        Tidal: 0,
        TOTMA: 0.07940076737,
        WBAreaType: "LakePond",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 8605.0,
        MAXELEVSMO: 9114.0,
        MINELEVSMO: 8626.0,
        SLOPE: 0.01520249,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.32100000977516202,
        QA_MA: 0.038,
        VA_MA: -9998.0,
        QC_MA: 0.038,
        VC_MA: -9998.0,
        QE_MA: 0.038,
        VE_MA: -9998.0,
        QA_01: 0.084,
        VA_01: -9998.0,
        QC_01: 0.084,
        VC_01: -9998.0,
        QE_01: 0.084,
        VE_01: -9998.0,
        QA_02: 0.093,
        VA_02: -9998.0,
        QC_02: 0.093,
        VC_02: -9998.0,
        QE_02: 0.093,
        VE_02: -9998.0,
        QA_03: 0.103,
        VA_03: -9998.0,
        QC_03: 0.103,
        VC_03: -9998.0,
        QE_03: 0.103,
        VE_03: -9998.0,
        QA_04: 0.053,
        VA_04: -9998.0,
        QC_04: 0.053,
        VC_04: -9998.0,
        QE_04: 0.053,
        VE_04: -9998.0,
        QA_05: 0.03,
        VA_05: -9998.0,
        QC_05: 0.03,
        VC_05: -9998.0,
        QE_05: 0.03,
        VE_05: -9998.0,
        QA_06: 0.02,
        VA_06: -9998.0,
        QC_06: 0.02,
        VC_06: -9998.0,
        QE_06: 0.02,
        VE_06: -9998.0,
        QA_07: 0.015,
        VA_07: -9998.0,
        QC_07: 0.015,
        VC_07: -9998.0,
        QE_07: 0.015,
        VE_07: -9998.0,
        QA_08: 0.012,
        VA_08: -9998.0,
        QC_08: 0.012,
        VC_08: -9998.0,
        QE_08: 0.012,
        VE_08: -9998.0,
        QA_09: 0.009,
        VA_09: -9998.0,
        QC_09: 0.009,
        VC_09: -9998.0,
        QE_09: 0.009,
        VE_09: -9998.0,
        QA_10: 0.007,
        VA_10: -9998.0,
        QC_10: 0.007,
        VC_10: -9998.0,
        QE_10: 0.007,
        VE_10: -9998.0,
        QA_11: 0.006,
        VA_11: -9998.0,
        QC_11: 0.006,
        VC_11: -9998.0,
        QE_11: 0.006,
        VE_11: -9998.0,
        QA_12: 0.028,
        VA_12: -9998.0,
        QC_12: 0.028,
        VC_12: -9998.0,
        QE_12: 0.028,
        VE_12: -9998.0,
        LakeFract: 1.2249e-7,
        SurfArea: 1.5079429065800001,
        RAreaHLoad: 0.01621966503,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.88175131822868, 34.570486613006381, 0.0],
          [-80.879978184898107, 34.568257679676549, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288739.0,
        COMID: 166740264,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.055,
        REACHCODE: "03050103017700",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00060028100736099997,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094168.0,
        ToNode: 250094173.0,
        Hydroseq: 250005357.0,
        LevelPathI: 250005357.0,
        Pathlength: 393.624,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.933,
        Divergence: 1,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005357.0,
        UpHydroseq: 250005367.0,
        DnLevelPat: 250005331.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005331.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9368.3169,
        DivDASqKM: 0.0351,
        Tidal: 0,
        TOTMA: 0.00576837450662,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12739.0,
        MINELEVSMO: 12739.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054999999701976998,
        QA_MA: 0.013,
        VA_MA: 0.36206,
        QC_MA: 0.013,
        VC_MA: 0.36206,
        QE_MA: 0.013,
        VE_MA: 0.36206,
        QA_01: 0.029,
        VA_01: 0.39057,
        QC_01: 0.029,
        VC_01: 0.39057,
        QE_01: 0.029,
        VE_01: 0.39057,
        QA_02: 0.031,
        VA_02: 0.39353,
        QC_02: 0.031,
        VC_02: 0.39353,
        QE_02: 0.031,
        VE_02: 0.39353,
        QA_03: 0.033,
        VA_03: 0.39641,
        QC_03: 0.033,
        VC_03: 0.39641,
        QE_03: 0.033,
        VE_03: 0.39641,
        QA_04: 0.019,
        VA_04: 0.37404,
        QC_04: 0.019,
        VC_04: 0.37404,
        QE_04: 0.019,
        VE_04: 0.37404,
        QA_05: 0.01,
        VA_05: 0.35508,
        QC_05: 0.01,
        VC_05: 0.35508,
        QE_05: 0.01,
        VE_05: 0.35508,
        QA_06: 0.007,
        VA_06: 0.34703,
        QC_06: 0.007,
        VC_06: 0.34703,
        QE_06: 0.007,
        VE_06: 0.34703,
        QA_07: 0.004,
        VA_07: 0.33709,
        QC_07: 0.004,
        VC_07: 0.33709,
        QE_07: 0.004,
        VE_07: 0.33709,
        QA_08: 0.003,
        VA_08: 0.33303,
        QC_08: 0.003,
        VC_08: 0.33303,
        QE_08: 0.003,
        VE_08: 0.33303,
        QA_09: 0.002,
        VA_09: 0.32826,
        QC_09: 0.002,
        VC_09: 0.32826,
        QE_09: 0.002,
        VE_09: 0.32826,
        QA_10: 0.002,
        VA_10: 0.32826,
        QC_10: 0.002,
        VC_10: 0.32826,
        QE_10: 0.002,
        VE_10: 0.32826,
        QA_11: 0.002,
        VA_11: 0.32826,
        QC_11: 0.002,
        VC_11: 0.32826,
        QE_11: 0.002,
        VE_11: 0.32826,
        QA_12: 0.015,
        VA_12: 0.3663,
        QC_12: 0.015,
        VC_12: 0.3663,
        QE_12: 0.015,
        VE_12: 0.3663,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875884651571141, 34.736032879416086, 0.0],
          [-80.875287784905368, 34.735968946082949, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288740.0,
        COMID: 166740265,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.015,
        REACHCODE: "03050103017701",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00012991142251900001,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094169.0,
        ToNode: 250094173.0,
        Hydroseq: 250005343.0,
        LevelPathI: 250005331.0,
        Pathlength: 393.624,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.927,
        Divergence: 0,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005331.0,
        UpHydroseq: 250005360.0,
        DnLevelPat: 250005331.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005331.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0027,
        TotDASqKM: 9368.3079,
        DivDASqKM: 0.0945,
        Tidal: 0,
        TOTMA: 0.00151514980195,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: 12739.0,
        MAXELEVSMO: 12739.0,
        MINELEVSMO: 12739.0,
        SLOPE: 1.0000000000000001e-5,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.014999999664724,
        QA_MA: 0.036,
        VA_MA: 0.37593,
        QC_MA: 0.036,
        VC_MA: 0.37593,
        QE_MA: 0.036,
        VE_MA: 0.37593,
        QA_01: 0.08,
        VA_01: 0.4116,
        QC_01: 0.08,
        VC_01: 0.4116,
        QE_01: 0.08,
        VE_01: 0.4116,
        QA_02: 0.085,
        VA_02: 0.41497,
        QC_02: 0.085,
        VC_02: 0.41497,
        QE_02: 0.085,
        VE_02: 0.41497,
        QA_03: 0.091,
        VA_03: 0.4189,
        QC_03: 0.091,
        VC_03: 0.4189,
        QE_03: 0.091,
        VE_03: 0.4189,
        QA_04: 0.052,
        VA_04: 0.39049,
        QC_04: 0.052,
        VC_04: 0.39049,
        QE_04: 0.052,
        VE_04: 0.39049,
        QA_05: 0.029,
        VA_05: 0.3686,
        QC_05: 0.029,
        VC_05: 0.3686,
        QE_05: 0.029,
        VE_05: 0.3686,
        QA_06: 0.019,
        VA_06: 0.35649,
        QC_06: 0.019,
        VC_06: 0.35649,
        QE_06: 0.019,
        VE_06: 0.35649,
        QA_07: 0.012,
        VA_07: 0.34608,
        QC_07: 0.012,
        VC_07: 0.34608,
        QE_07: 0.012,
        VE_07: 0.34608,
        QA_08: 0.01,
        VA_08: 0.3426,
        QC_08: 0.01,
        VC_08: 0.3426,
        QE_08: 0.01,
        VE_08: 0.3426,
        QA_09: 0.007,
        VA_09: 0.3367,
        QC_09: 0.007,
        VC_09: 0.3367,
        QE_09: 0.007,
        VE_09: 0.3367,
        QA_10: 0.006,
        VA_10: 0.33447,
        QC_10: 0.006,
        VC_10: 0.33447,
        QE_10: 0.006,
        VE_10: 0.33447,
        QA_11: 0.006,
        VA_11: 0.33447,
        QC_11: 0.006,
        VC_11: 0.33447,
        QE_11: 0.006,
        VE_11: 0.33447,
        QA_12: 0.04,
        VA_12: 0.37982,
        QC_12: 0.04,
        VC_12: 0.37982,
        QE_12: 0.04,
        VE_12: 0.37982,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: "03b",
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875294584905305, 34.736098679415988, 0.0],
          [-80.875287784905368, 34.735968946082949, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288741.0,
        COMID: 166740266,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.068,
        REACHCODE: "03050103017702",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.00061948015390500002,
        StreamLeve: 4,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094168.0,
        ToNode: 250059481.0,
        Hydroseq: 250005356.0,
        LevelPathI: 250005346.0,
        Pathlength: 393.602,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.946,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 4,
        UpLevelPat: 250005357.0,
        UpHydroseq: 250005367.0,
        DnLevelPat: 250005346.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005346.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9368.3169,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.039355932524300002,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.068000003695488004,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875884651571141, 34.736032879416086, 0.0],
          [-80.875655318238117, 34.735457412750293, 0.0],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 288742.0,
        COMID: 166740267,
        FDATE: "2008/11/07 00:00:00",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        LENGTHKM: 0.054,
        REACHCODE: "03050103017703",
        FLOWDIR: "With Digitized",
        WBAREACOMI: 9757426,
        FTYPE: "ArtificialPath",
        FCODE: 55800,
        Shape_Leng: 0.000527892483954,
        StreamLeve: 3,
        StreamOrde: 6,
        StreamCalc: 0,
        FromNode: 250094168.0,
        ToNode: 250059480.0,
        Hydroseq: 250005355.0,
        LevelPathI: 250005304.0,
        Pathlength: 393.659,
        TerminalPa: 250002604.0,
        ArbolateSu: 7029.932,
        Divergence: 2,
        StartFlag: 0,
        TerminalFl: 0,
        DnLevel: 3,
        UpLevelPat: 250005357.0,
        UpHydroseq: 250005367.0,
        DnLevelPat: 250005304.0,
        DnMinorHyd: 0.0,
        DnDrainCou: 1,
        DnHydroseq: 250005347.0,
        FromMeas: 0.0,
        ToMeas: 100.0,
        RtnDiv: 0,
        VPUIn: 0,
        VPUOut: 0,
        AreaSqKM: 0.0,
        TotDASqKM: 9368.3169,
        DivDASqKM: 0.0,
        Tidal: 0,
        TOTMA: 0.031253239646199998,
        WBAreaType: "StreamRiver",
        HWNodeSqKM: null,
        MAXELEVRAW: -9998.0,
        MINELEVRAW: -9998.0,
        MAXELEVSMO: 12732.0,
        MINELEVSMO: 12732.0,
        SLOPE: -9998.0,
        ELEVFIXED: "0",
        HWTYPE: null,
        SLOPELENKM: 0.054000001400709,
        QA_MA: 0.0,
        VA_MA: 0.06561,
        QC_MA: 0.0,
        VC_MA: 0.06561,
        QE_MA: 0.0,
        VE_MA: 0.06561,
        QA_01: 0.0,
        VA_01: 0.06561,
        QC_01: 0.0,
        VC_01: 0.06561,
        QE_01: 0.0,
        VE_01: 0.06561,
        QA_02: 0.0,
        VA_02: 0.06561,
        QC_02: 0.0,
        VC_02: 0.06561,
        QE_02: 0.0,
        VE_02: 0.06561,
        QA_03: 0.0,
        VA_03: 0.06561,
        QC_03: 0.0,
        VC_03: 0.06561,
        QE_03: 0.0,
        VE_03: 0.06561,
        QA_04: 0.0,
        VA_04: 0.06561,
        QC_04: 0.0,
        VC_04: 0.06561,
        QE_04: 0.0,
        VE_04: 0.06561,
        QA_05: 0.0,
        VA_05: 0.06561,
        QC_05: 0.0,
        VC_05: 0.06561,
        QE_05: 0.0,
        VE_05: 0.06561,
        QA_06: 0.0,
        VA_06: 0.06561,
        QC_06: 0.0,
        VC_06: 0.06561,
        QE_06: 0.0,
        VE_06: 0.06561,
        QA_07: 0.0,
        VA_07: 0.06561,
        QC_07: 0.0,
        VC_07: 0.06561,
        QE_07: 0.0,
        VE_07: 0.06561,
        QA_08: 0.0,
        VA_08: 0.06561,
        QC_08: 0.0,
        VC_08: 0.06561,
        QE_08: 0.0,
        VE_08: 0.06561,
        QA_09: 0.0,
        VA_09: 0.06561,
        QC_09: 0.0,
        VC_09: 0.06561,
        QE_09: 0.0,
        VE_09: 0.06561,
        QA_10: 0.0,
        VA_10: 0.06561,
        QC_10: 0.0,
        VC_10: 0.06561,
        QE_10: 0.0,
        VE_10: 0.06561,
        QA_11: 0.0,
        VA_11: 0.06561,
        QC_11: 0.0,
        VC_11: 0.06561,
        QE_11: 0.0,
        VE_11: 0.06561,
        QA_12: 0.0,
        VA_12: 0.06561,
        QC_12: 0.0,
        VC_12: 0.06561,
        QE_12: 0.0,
        VE_12: 0.06561,
        LakeFract: null,
        SurfArea: null,
        RAreaHLoad: null,
        RPUID: null,
        VPUID: "03N",
        Enabled: 1,
      },
      geometry: {
        type: "LineString",
        coordinates: [
          [-80.875884651571141, 34.736032879416086, 0.0],
          [-80.876237918237123, 34.735640612749989, 0.0],
        ],
      },
    },
  ],
};
