export const starsTuck = {
"type": "FeatureCollection",
"name": "tuck_stars",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "WOLF_TUC", "DisplayName": "Wolf Creek", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.262350716532495, "LON": -82.979909458043437}, "geometry": { "type": "Point", "coordinates": [ -82.997335097269172, 35.221898126160212 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "TANA_TUC", "DisplayName": "Tanasee", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.20783110002786, "LON": -82.979909458043437 }, "geometry": { "type": "Point", "coordinates": [ -82.999795953550134, 35.213375160504263 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "BEAR_TUC", "DisplayName": "Bear Creek (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.226562211987996, "LON": -83.039917114878762 }, "geometry": { "type": "Point", "coordinates": [ -83.05447497969503, 35.235762950572365 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "CEDA_TUC", "DisplayName": "Cedar Cliff (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.247072211956166, "LON": -83.08589018147407}, "geometry": { "type": "Point", "coordinates": [ -83.095149132696349, 35.253609160349626 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "GLEN_TUC", "DisplayName": "Glenville", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.155628612098099, "LON": -83.149063981376017 }, "geometry": { "type": "Point", "coordinates": [ -83.151393703589051, 35.17687245900369 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Tuck", "SUBBASIN_ID": "THOR_TUC", "DisplayName": "Thorpe (Local)", "TabGroup": "Main-Res Inflows", "Point_Type": "Main", "LAT": 35.214321530564128, "LON": -83.129189360377552 }, "geometry": { "type": "Point", "coordinates": [ -83.12361403736071, 35.237918700688411 ] } }
]
}