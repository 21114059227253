export const squaresNant = {
"type": "FeatureCollection",
"name": "nant_squares",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "SILV_NAN", "DisplayName": "Silvermine Creek", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.311677988608103, "LON": -83.609264872376627 }, "geometry": { "type": "Point", "coordinates": [ -83.591589183955307, 35.329316087316791 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "OUTD_NAN", "DisplayName": "Outdoor Center (Local)", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.329634846425101, "LON": -83.6337121607299}, "geometry": { "type": "Point", "coordinates": [ -83.59831152306424, 35.332597229024714 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "HEWI_NAN", "DisplayName": "Hewitt (Local)", "TabGroup": "Evaluate", "Point_Type": "Eval No Gage", "LAT": 35.295451912267438 , "LON": -83.67178935320932}, "geometry": { "type": "Point", "coordinates": [ -83.652170263782352, 35.304587482737432 ] } },
{ "type": "Feature", "properties": { "BASIN_NAME": "Nant", "SUBBASIN_ID": "RAIN_NAN", "DisplayName": "Rainbow Springs", "TabGroup": "Evaluate", "Point_Type": "Eval", "LAT": 35.08234944299339, "LON": -83.56621168315273 }, "geometry": { "type": "Point", "coordinates": [ -83.61879490047194, 35.127978529785508 ] } }
]
}
